"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TopTenHeldMessagesChartComponent = void 0;
const am4core = require("@amcharts/amcharts4/core");
const core_1 = require("@angular/core");
const heldQueueAction = require("../../../actions/held-queue.actions");
const moment = require("moment");
class TopTenHeldMessagesChartComponent {
    constructor(translate, store) {
        this.translate = translate;
        this.store = store;
        this.clickedBar = new core_1.EventEmitter();
        this.chartConfiguration = {
            maxZoomLevel: 1,
            series: [
                {
                    type: 'ColumnSeries',
                    tooltipY: 0,
                    tooltipText: `{categoryY} \n${this.translate.instant('$I18N_MESSAGE_CENTER_HELD_MESSAGES_OVERVIEW.MESSAGES_HELD')}: [bold]{valueX}[/]`,
                    tooltip: {
                        getFillFromObject: false,
                        background: {
                            fill: '#fff',
                            fillOpacity: 1,
                            stroke: '#f2f2f2',
                            strokeWidth: 2
                        },
                        label: {
                            fill: '#000'
                        }
                    },
                    columns: {
                        fill: '#004ba0',
                        height: am4core.percent(35),
                        strokeOpacity: 0,
                        cursorOverStyle: am4core.MouseCursorStyle.pointer
                    },
                    dataFields: {
                        valueX: 'numberOfItems',
                        categoryY: 'policyInfo'
                    },
                    interactionsEnabled: true,
                    events: {
                        hit: (event) => {
                            const heldMessage = event.target.dataItem.component.tooltipDataItem.dataContext;
                            this.store.dispatch(new heldQueueAction.HeldQueueRequestAction({
                                search: {
                                    start: moment()
                                        .subtract(30, 'days')
                                        .toDate(),
                                    end: moment().toDate(),
                                    searchBy: [{ fieldName: 'policyInfo', value: heldMessage.policyInfo }],
                                    filterBy: []
                                }
                            }));
                            this.barClick(heldMessage.policyInfo);
                        }
                    }
                }
            ],
            xAxes: [
                {
                    type: 'ValueAxis',
                    cursorTooltipEnabled: false,
                    renderer: {
                        grid: {
                            disabled: false
                        },
                        minGridDistance: 70
                    }
                }
            ],
            yAxes: [
                {
                    type: 'CategoryAxis',
                    cursorTooltipEnabled: false,
                    renderer: {
                        labels: {
                            truncate: true,
                            maxWidth: 250
                        },
                        grid: {
                            disabled: true,
                            template: {
                                location: 0
                            }
                        },
                        minGridDistance: 23
                    },
                    dataFields: {
                        category: 'policyInfo'
                    }
                }
            ]
        };
    }
    barClick(heldReason) {
        // Emit event to inform Layout component that a bar was clicked
        this.clickedBar.emit({ field: 'heldReason', value: heldReason });
    }
}
exports.TopTenHeldMessagesChartComponent = TopTenHeldMessagesChartComponent;
