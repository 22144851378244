"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RetentionComponent = void 0;
class RetentionComponent {
    constructor() {
        this.auditsColumns = ['type', 'token', 'address'];
        this.litigationHoldInfoColumns = ['legalHoldDescription', 'expiryDate'];
        this.smartTagsColumns = ['description'];
    }
    get litigationHold() {
        return this.retentionInfo.litigationHoldInfo && this.retentionInfo.litigationHoldInfo.length
            ? '$I18N_MESSAGE_CENTER_MESSAGE_INFO_RETENTION.VALUE_ACTIVE'
            : '$I18N_MESSAGE_CENTER_MESSAGE_INFO_RETENTION.VALUE_NONE';
    }
}
exports.RetentionComponent = RetentionComponent;
