"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
/**
 * Created by psambandam on 14/07/2016.
 */
(function () {
    'use strict';
    angular.module('modalErrorReport.service', [])
        .factory('modalErrorReportService', ['$uibModal', function ($uibModal) {
            let modalInstance = {};
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            let tabsCtrl;
            let tabTitle;
            let tabId;
            /* -------------------------------------------------------- *\
             - interface
             \* -------------------------------------------------------- */
            return {
                openErrorModal,
                closeErrorModal,
                closeLegacyErrorTab
            };
            /* -------------------------------------------------------- *\
             - initialisation
             \* -------------------------------------------------------- */
            /* -------------------------------------------------------- *\
             - implementation
             \* -------------------------------------------------------- */
            function setTabCtrl(tab) {
                tabsCtrl = tab;
            }
            function setTabTitle(title) {
                tabTitle = title;
            }
            function setTabId(id) {
                tabId = id;
            }
            // -- closeErrorModal --  //
            function closeErrorModal() {
                modalInstance.close();
            }
            // -- closeLegacyErrorTab -- //
            function closeLegacyErrorTab() {
                //close the error tab, not the selected tab
                return modalInstance.opened.then(function () {
                    tabsCtrl.getTabById(tabId).close();
                });
            }
            // -- openErrorModal -- //
            function openErrorModal(tabsCtrlParam) {
                modalInstance = $uibModal.open({
                    templateUrl: 'components/modal-error-report/modal-error-report.tpl.html',
                    size: 250,
                    backdrop: 'static',
                    keyboard: false,
                    controller: angular.noop,
                    resolve: {
                        tabsCtrl() {
                            setTabCtrl(tabsCtrlParam);
                            return tabsCtrlParam;
                        },
                        tabTitle() {
                            setTabTitle(tabsCtrlParam.getSelectedTab().title);
                            return tabsCtrlParam.getSelectedTab().title;
                        },
                        tabId() {
                            setTabId(tabsCtrlParam.getSelectedTab().id);
                            return tabsCtrlParam.getSelectedTab().id;
                        }
                    }
                });
            }
        }]);
}());
