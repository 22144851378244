"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducer = void 0;
const actions_1 = require("../actions");
const initialState = {
    isUpdatingStatus: false,
    statusUpdated: false,
    isLoadingBackup: false,
    isCreatingBackup: false,
    isUpdatingBackup: false,
    isDeletingBackup: false,
    openingEditBackup: false,
    backupDeleted: false,
    errorLoadingBackupsTable: false,
    backupDetails: null
};
function reducer(state = initialState, action) {
    switch (action.type) {
        case actions_1.BackupActions.CHANGE_BACKUP_STATUS: {
            return Object.assign(Object.assign({}, state), { isUpdatingStatus: true, statusUpdated: false });
        }
        case actions_1.BackupActions.CHANGE_BACKUP_STATUS_SUCCESS: {
            return Object.assign(Object.assign({}, state), { isUpdatingStatus: false, statusUpdated: true });
        }
        case actions_1.BackupActions.CHANGE_BACKUP_STATUS_FAILURE: {
            return Object.assign(Object.assign({}, state), { isUpdatingStatus: false, statusUpdated: false });
        }
        case actions_1.BackupActions.CREATE_BACKUP: {
            return Object.assign(Object.assign({}, state), { isCreatingBackup: true });
        }
        case actions_1.BackupActions.CREATE_BACKUP_SUCCESS: {
            return Object.assign(Object.assign({}, state), { isCreatingBackup: false });
        }
        case actions_1.BackupActions.CREATE_BACKUP_FAILURE: {
            return Object.assign(Object.assign({}, state), { isCreatingBackup: false });
        }
        case actions_1.BackupActions.UPDATE_BACKUP: {
            return Object.assign(Object.assign({}, state), { isUpdatingBackup: true });
        }
        case actions_1.BackupActions.UPDATE_BACKUP_SUCCESS: {
            return Object.assign(Object.assign({}, state), { isUpdatingBackup: false });
        }
        case actions_1.BackupActions.UPDATE_BACKUP_FAILURE: {
            return Object.assign(Object.assign({}, state), { isUpdatingBackup: false });
        }
        case actions_1.BackupActions.DELETE_BACKUP: {
            return Object.assign(Object.assign({}, state), { isDeletingBackup: true, backupDeleted: false });
        }
        case actions_1.BackupActions.DELETE_BACKUP_SUCCESS: {
            return Object.assign(Object.assign({}, state), { isDeletingBackup: false, backupDeleted: true });
        }
        case actions_1.BackupActions.DELETE_BACKUP_FAILURE: {
            return Object.assign(Object.assign({}, state), { isDeletingBackup: false, backupDeleted: false });
        }
        case actions_1.BackupActions.LOAD_BACKUPS_TABLE_SUCCESS: {
            return Object.assign(Object.assign({}, state), { errorLoadingBackupsTable: false });
        }
        case actions_1.BackupActions.LOAD_BACKUPS_TABLE_FAILURE: {
            return Object.assign(Object.assign({}, state), { errorLoadingBackupsTable: true });
        }
        case actions_1.BackupActions.OPEN_EDIT_BACKUP: {
            return Object.assign(Object.assign({}, state), { openingEditBackup: true });
        }
        case actions_1.BackupActions.OPEN_EDIT_BACKUP_SUCCESS: {
            return Object.assign(Object.assign({}, state), { openingEditBackup: false });
        }
        case actions_1.BackupActions.OPEN_EDIT_BACKUP_FAILURE: {
            return Object.assign(Object.assign({}, state), { openingEditBackup: false });
        }
        case actions_1.BackupActions.LOAD_BACKUP_DETAILS: {
            return Object.assign(Object.assign({}, state), { backupDetails: null, isLoadingBackup: true });
        }
        case actions_1.BackupActions.LOAD_BACKUP_DETAILS_SUCCESS: {
            return Object.assign(Object.assign({}, state), { backupDetails: action.backup, isLoadingBackup: false });
        }
        case actions_1.BackupActions.LOAD_BACKUP_DETAILS_FAILURE: {
            return Object.assign(Object.assign({}, state), { isLoadingBackup: false });
        }
        default: {
            return state;
        }
    }
}
exports.reducer = reducer;
