"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgActivityReportComponent = void 0;
const core_1 = require("@angular/core");
const table_store_1 = require("@mimecast-ui/table-store");
const sidebar_actions_1 = require("app-new/swg/activity-report/actions/sidebar.actions");
const swg_activity_report_static_value_1 = require("./swg-activity-report.static-value");
const export_actions_1 = require("../../actions/export.actions");
const models_1 = require("../../../models/models");
class SwgActivityReportComponent extends table_store_1.TableBaseComponent {
    constructor(store, tabService, archive, translateService, swgService) {
        super(store, 'SwgActivityReportList');
        this.store = store;
        this.tabService = tabService;
        this.archive = archive;
        this.translateService = translateService;
        this.swgService = swgService;
        this.onShowNotification = new core_1.EventEmitter();
        this.searchFilters = [];
        this.applicationVisibilitySearchFilters = [];
        this.dateRangePickerConfig = swg_activity_report_static_value_1.swgActivityReportDateRangePickerConfig;
        this.filterSearchColumnCount = 4;
        this.searchOptionsSearchValue = '';
        this.columnsToStartHidden = [...swg_activity_report_static_value_1.columnsToStartHidden];
        this._reasonFilters = [...swg_activity_report_static_value_1.reasonFilters];
        this.columnsAlwaysVisible = swg_activity_report_static_value_1.columnsAlwaysVisible;
        this._actionFilters = [...swg_activity_report_static_value_1.actionFilters];
        this._eventFilters = [...swg_activity_report_static_value_1.eventFilters];
        const tab = this.tabService.getSelectedTab();
        tab.onRefresh = () => {
            this.runFilters();
        };
    }
    ngOnInit() {
        this.columns = swg_activity_report_static_value_1.columnsList;
        this.filterSearchOptions = swg_activity_report_static_value_1.filterSearchOptions.map(x => {
            return Object.assign({}, x);
        });
        this.setDateRangeByParams();
        if (this.params && this.params.action === models_1.ParamsAction.SEARCH) {
            this.applySearchAndLoadActivityReport();
            this.showNotification();
        }
        else if (this.params && this.params.action === models_1.ParamsAction.FILTER) {
            this.applyFilterAndLoadActivityReport();
            this.showNotification();
        }
        else {
            this.dispatchLoadAction(this.getFilterDate());
        }
        if (!this.hasTTPURLPolicySupport) {
            this._reasonFilters = this._reasonFilters.filter(x => ['ttpAdvSimCheck', 'ttpManagedUrls'].indexOf(x) === -1);
        }
        if (!this.hasHybridProxySupport) {
            this._reasonFilters = this._reasonFilters.filter(x => x !== 'extendedProxy');
        }
        if (!this.hasSwgFull) {
            this.columns = this.columns.filter(x => ['username', 'discoveryMethod', 'privateIp', 'deviceName'].indexOf(x) === -1);
        }
        if (!this.hasApplicationVisibility) {
            this.columns = this.columns.filter(x => ['appName', 'appCategory'].indexOf(x) === -1);
            this._reasonFilters = this._reasonFilters.filter(x => ['appControl'].indexOf(x) === -1);
        }
        if (!this.hasPrivateIpSupport) {
            this.columns = this.columns.filter(x => ['deviceName', 'privateIp', 'publicIp'].indexOf(x) === -1);
        }
        else {
            this.columns = this.columns.filter(x => ['sourceIp'].indexOf(x) === -1);
        }
        this.columnsListForExport = [...this.columns].filter(x => x !== 'columnActions');
        if (!this.hasDirectIPFiltersEnabled) {
            this._eventFilters = this._eventFilters.filter(event => event !== 'ip');
            this._actionFilters = this._actionFilters.filter(action => action !== 'ipblock');
            this._reasonFilters = this._reasonFilters.filter(reason => reason !== 'directipprotection');
        }
        if (!this.hasDirectIpProtectionModeSwitchEnabled) {
            this._actionFilters = this._actionFilters.filter(action => action !== 'ipmonitoring');
        }
    }
    ngOnChanges(changes) {
        const change = changes['categories'];
        if (change && !change.isFirstChange()) {
            if (this.hasApplicationVisibility) {
                this.applicationVisibilitySetup();
            }
            else {
                this.update();
            }
        }
        const applicationChange = changes['applications'];
        if (applicationChange && !applicationChange.isFirstChange()) {
            this.applicationVisibilitySetup();
        }
    }
    update() {
        const _discoveryMethodFilters = this.hasSupervisedUserSupport
            ? swg_activity_report_static_value_1.discoveryMethodFilters
            : swg_activity_report_static_value_1.discoveryMethodFilters.filter(x => x !== 'supervisedUser');
        this.searchFilters = [
            {
                name: 'action',
                filters: this.hasBrowserIsolationEnabled
                    ? this._actionFilters
                    : this._actionFilters.filter(x => x !== 'isolate')
            },
            {
                name: 'contextType',
                filters: _discoveryMethodFilters
            },
            {
                name: 'category',
                filters: []
            },
            {
                name: 'event',
                filters: this._eventFilters
            },
            {
                name: 'actionReason',
                filters: this._reasonFilters
            }
        ];
        if (this.categories) {
            const sortedCategories = this.categories.map((item) => item.name).sort();
            const categorySection = this.searchFilters.find(x => x.name === 'category');
            if (categorySection) {
                categorySection.filters = sortedCategories;
            }
        }
        if (!this.hasSwgFull) {
            this.searchFilters = this.searchFilters.filter(x => x.name !== 'contextType');
        }
        if (!this.hasSwgFull) {
            this.filterSearchOptions = this.filterSearchOptions.filter(x => ['username', 'discoveryMethod', 'privateIp', 'deviceName'].indexOf(x.value) === -1);
        }
        if (!this.hasPrivateIpSupport) {
            this.filterSearchOptions = this.filterSearchOptions.filter(x => ['deviceName', 'privateIp', 'publicIp'].indexOf(x.value) === -1);
        }
        else {
            this.filterSearchOptions = this.filterSearchOptions.filter(x => ['sourceIp'].indexOf(x.value) === -1);
        }
    }
    onRowClick(row) {
        super.onRowClick(row);
        this.store.dispatch(new sidebar_actions_1.SwgActivityReportSidebarOpen());
    }
    onExportClicked() {
        if (!this.currentFilters) {
            this.filtersDateEntry = this.dateRangePickerConfig.dateRanges[0];
            this.dispatchFilterAction(this.getFilterDate(), {
                pageSize: this.currentPagination.pageSize,
                pageNumber: undefined,
                pageToken: undefined
            });
        }
        this.store.dispatch(new export_actions_1.SwgExportActivityReportAction(this.columnsListForExport));
    }
    onSearch(filter) {
        this.filterSearch = { searchBy: { fieldName: filter.filterBy, value: filter.search } };
        this.runFilters();
    }
    onSearchClear() {
        this.filterSearch = null;
        this.runFilters();
    }
    onDateRangeFilterChange(value) {
        if (!value.range) {
            return;
        }
        this.filtersDateEntry = value;
        this.runFilters();
    }
    getFilterDate() {
        const selectedDateRange = this.filtersDateEntry.rangeFn();
        return this.transformDateRangeFilter(selectedDateRange, this.filtersDateEntry.id);
    }
    onBundleFilterChange(filters) {
        if (Object.keys(filters).length) {
            this.filters = { filterBy: filters };
        }
        else {
            this.filters = {};
            this.resetSelectedFilters();
        }
        if (this.filters.filterBy &&
            this.filters.filterBy.event &&
            this.filters.filterBy.event.indexOf('https') !== -1) {
            this.filters.filterBy.event.push('httpsUntrusted');
        }
        this.runFilters();
    }
    runFilters() {
        const payload = Object.assign(Object.assign(Object.assign({}, this.filterSearch), this.getFilterDate()), this.filters);
        this.onFilterChange(payload);
    }
    transformDateRangeFilter(selectedDateRange, dateRangeId) {
        if (dateRangeId === 'USER_DEFINED') {
            const maxAllowDates = this.dateRangePickerConfig.dateRanges
                .find((range) => range.id === '$I18N_TABLE_FILTER_PLUGIN_DATE_RANGE.DROPDOWN_VALUES.ALL_TIME')
                .rangeFn();
            if (selectedDateRange.start.isBefore(maxAllowDates.start)) {
                selectedDateRange.start = maxAllowDates.start;
            }
            if (selectedDateRange.end.isAfter(maxAllowDates.end)) {
                selectedDateRange.end = maxAllowDates.end;
            }
        }
        return {
            start: selectedDateRange.start.format(),
            end: selectedDateRange.end.format()
        };
    }
    getTranslateKey(key, container) {
        const keys = [
            '$18N_SWG_REPORTING',
            'TABLE',
            'FILTERS',
            'COLUMN_VALUE_FILTER_FIELD',
            container && `${container.toUpperCase()}_FILTERS`,
            key && key.toUpperCase()
        ];
        return keys.filter(n => n).join('.');
    }
    applicationVisibilitySetup() {
        const _discoveryMethodFilters = this.hasSupervisedUserSupport
            ? swg_activity_report_static_value_1.discoveryMethodFilters
            : swg_activity_report_static_value_1.discoveryMethodFilters.filter(x => x !== 'supervisedUser');
        const _actionFilters = this.hasBrowserIsolationEnabled
            ? this._actionFilters
            : this._actionFilters.filter(x => x !== 'isolate');
        this.applicationVisibilitySearchFilters = [
            {
                name: 'action',
                displayName: 'Action',
                filters: _actionFilters.map(item => ({
                    name: item,
                    displayName: this.translateService.instant(this.getTranslateKey(item, 'action'))
                }))
            },
            {
                name: 'contextType',
                displayName: 'Discovery Method',
                filters: _discoveryMethodFilters.map(item => ({
                    name: item,
                    displayName: this.translateService.instant(this.getTranslateKey(item, 'contextType'))
                }))
            },
            {
                name: 'category',
                displayName: 'Category',
                filters: []
            },
            {
                name: 'event',
                displayName: 'Event',
                filters: this._eventFilters.map(item => ({
                    name: item,
                    displayName: this.translateService.instant(this.getTranslateKey(item, 'event'))
                }))
            },
            {
                name: 'actionReason',
                displayName: 'Reason',
                filters: this._reasonFilters.map(item => ({
                    name: item,
                    displayName: this.translateService.instant(this.getTranslateKey(item, 'actionReason'))
                }))
            },
            {
                name: 'appCode',
                displayName: 'Application Name',
                filters: []
            },
            {
                name: 'appCategory',
                displayName: 'Application Category',
                filters: []
            }
        ];
        if (this.categories) {
            const categories = this.categories.map((item) => ({
                name: item.name,
                displayName: item.shortDescription
            }));
            categories.sort((filter1, filter2) => {
                return filter1.displayName > filter2.displayName ? 1 : -1;
            });
            const categorySection = this.applicationVisibilitySearchFilters.find((x) => x.name === 'category');
            if (categorySection) {
                categorySection.filters = categories;
            }
        }
        if (this.applications) {
            const applications = this.applications.map((item) => ({
                name: item.code,
                displayName: item.name,
                isSelected: this.isFilterSelected(item.code)
            }));
            applications.sort((filter1, filter2) => {
                return filter1.displayName > filter2.displayName ? 1 : -1;
            });
            const applicationSection = this.applicationVisibilitySearchFilters.find((x) => x.name === 'appCode');
            if (applicationSection) {
                applicationSection.filters = applications;
            }
            // get the app categories
            const distinctAppCategories = this.swgService.getDistinctSortedCategoriesFromApps(this.applications);
            const appCategories = distinctAppCategories.map((cat) => {
                const category = this.categories && this.categories.some(item => item.name === cat)
                    ? this.categories.find(item => item.name === cat)
                    : null;
                return {
                    name: cat,
                    displayName: category ? category.shortDescription : '',
                    isSelected: this.isFilterSelected(category ? category.name : '')
                };
            });
            appCategories.sort((filter1, filter2) => {
                return filter1.displayName > filter2.displayName ? 1 : -1;
            });
            const appCategorySection = this.applicationVisibilitySearchFilters.find((x) => x.name === 'appCategory');
            if (appCategorySection) {
                appCategorySection.filters = appCategories;
            }
        }
        if (!this.hasSwgFull) {
            this.applicationVisibilitySearchFilters = this.applicationVisibilitySearchFilters.filter((x) => x.name !== 'contextType');
            this.filterSearchOptions = this.filterSearchOptions.filter(x => ['username', 'discoveryMethod', 'privateIp', 'deviceName'].indexOf(x.value) === -1);
        }
        if (!this.hasPrivateIpSupport) {
            this.filterSearchOptions = this.filterSearchOptions.filter(x => ['deviceName', 'privateIp', 'publicIp'].indexOf(x.value) === -1);
        }
        else {
            this.filterSearchOptions = this.filterSearchOptions.filter(x => ['sourceIp'].indexOf(x.value) === -1);
        }
    }
    applySearchAndLoadActivityReport() {
        let option = null;
        this.filterSearchOptions.map(item => {
            if (this.translateService.instant(item.label) === this.params.id) {
                item.isSelected = true;
                option = item;
            }
            else {
                item.isSelected = false;
            }
        });
        this.searchOptionsSearchValue = this.params.value;
        this.onSearch({
            filterBy: option ? option.value : this.filterSearchOptions[0].value,
            search: this.params.value
        });
    }
    applyFilterAndLoadActivityReport() {
        const filterItems = {};
        filterItems[this.params.id] = [this.params.value];
        this.onBundleFilterChange(filterItems);
    }
    setDateRangeByParams() {
        if (this.params && this.params.dateRange) {
            this.filtersDateEntry = this.dateRangePickerConfig.dateRanges.find(x => x.id === this.params.dateRange.id);
            this.dateRangePickerConfig.initialDateRange = this.dateRangePickerConfig.dateRanges.findIndex(x => x.id === this.params.dateRange.id);
        }
        else {
            this.filtersDateEntry = this.dateRangePickerConfig.dateRanges[0];
            this.dateRangePickerConfig.initialDateRange = 0;
        }
    }
    isFilterSelected(code) {
        if (this.params && this.params.action === models_1.ParamsAction.FILTER) {
            return this.params.value === code;
        }
        else {
            return false;
        }
    }
    showNotification() {
        let msg = '';
        let filterName = '';
        let filterValue = '';
        if (this.params.id === 'appCode') {
            filterName = 'Application';
            filterValue =
                this.applications && this.applications.some(app => app.code === this.params.value)
                    ? this.applications.find(app => app.code === this.params.value).name
                    : '';
        }
        else if (this.params.id === 'appCategory') {
            filterName = 'Application Category';
            filterValue =
                this.categories && this.categories.some(cat => cat.name === this.params.value)
                    ? this.categories.find(cat => cat.name === this.params.value).shortDescription
                    : '';
        }
        else {
            filterName = this.params.id;
            filterValue = this.params.value;
        }
        this.translateService
            .get('$18N_SWG_REPORTING.FILTEREDBY_NOTIFICATION', {
            filterName,
            filterValue
        })
            .subscribe((response) => {
            msg = response;
        });
        this.onShowNotification.emit(msg);
    }
    resetSelectedFilters() {
        if (this.params && this.params.action === models_1.ParamsAction.FILTER) {
            this.params.value = '';
        }
        if (this.hasApplicationVisibility) {
            this.applicationVisibilitySearchFilters.forEach(section => {
                section.filters.filter(item => (item.isSelected = false));
            });
        }
    }
    showMultipleUsersInUsersColumn(row) {
        return !row.username && row.discoveryMethod.includes('Multiple Users');
    }
}
exports.SwgActivityReportComponent = SwgActivityReportComponent;
