"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./page-list-filters-directive"); //layout.page-list-filters.directive
// html and css 
require("./page-list-filters.less");
require("./page-list-filters.tpl.html");
(function () {
    'use strict';
    /* ======================================================== *\

     = layout / page-list-filters

     \* ======================================================== */
    angular.module('layout.page-list-filters', ['layout.page-list-filters.directive']);
}());
