"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArchiveApiService = void 0;
const http_1 = require("@angular/common/http");
const operators_1 = require("rxjs/operators");
class ArchiveApiService {
    constructor(http) {
        this.http = http;
    }
    /**
     * return a url with a temporal session id, this makes allows the url be
     * injected in the dom to render or download if is done in less that 1 to
     * 3 min of this endpoint responds
     *
     * @param apiFileUrl e.g. '/api/tracking/get-file/123213aSDSA234ASDA?forceDownload=true'
     * @returns
     */
    getFileUrl(apiFileUrl) {
        return this.http
            .post('/download/get-file-link', { url: apiFileUrl })
            .pipe(operators_1.map((r) => r.body));
    }
    /**
     * injects an iframe in the dom to force a download , the url must have session params to work
     *
     * @param fileUrl
     */
    injectIframeToDownloadFile(fileUrl) {
        const ifrm = document.createElement('iframe');
        ifrm.style.display = 'none';
        document.body.appendChild(ifrm);
        ifrm.src = fileUrl;
    }
    /**
     * trigger a download of the file targeted by the api url, this will add the rights session parameters automatically
     *
     * @param apiFileUrl  e.g. '/api/tracking/get-file/123213aSDSA234ASDA?forceDownload=true'
     */
    triggerDownload(apiFileUrl) {
        this.getFileUrl(apiFileUrl)
            .pipe(operators_1.first())
            .subscribe(file => this.injectIframeToDownloadFile(file));
    }
    /**
     * upload file so it can be attach to emails or other services
     *
     * @param file
     * @returns
     */
    uploadFile(file) {
        const extension = file.name.split('.').pop();
        const formData = new FormData();
        formData.append('file', file, file.name);
        return this.http
            .request('POST', '/u/rest/api/archive/upload-file', {
            body: formData,
            reportProgress: true,
            observe: 'events'
        })
            .pipe(operators_1.map((event) => {
            const attachment = {
                filename: file.name,
                size: file.size,
                contentType: file.type,
                extension
            };
            if (event.type === http_1.HttpEventType.UploadProgress) {
                const progress = Math.round((99 * event.loaded) / event.total);
                attachment.progress = progress;
            }
            else if (event instanceof http_1.HttpResponse) {
                // @ts-ignore
                const response = event;
                attachment.progress = 100;
                attachment.id = response.body.id || response.body.body.id;
            }
            return attachment;
        }));
    }
}
exports.ArchiveApiService = ArchiveApiService;
