"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BiPolicyDetailsStepComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const operators_1 = require("rxjs/operators");
class BiPolicyDetailsStepComponent {
    constructor(fb) {
        this.fb = fb;
        this.isEditFlow = false;
        this.updatePolicyDesc = new core_1.EventEmitter();
    }
    ngOnInit() {
        this.policyPage = this.fb.group({
            name: [
                { value: this.policy.policyDesc.description, disabled: this.disableEdit },
                forms_1.Validators.required
            ],
            description: [{ value: this.policy.narrative, disabled: this.disableEdit }]
        });
        this.subscription = this.policyPage.valueChanges.pipe(operators_1.debounceTime(200)).subscribe(() => {
            this.updateSettings();
        });
    }
    updateSettings() {
        const policyEmit = {
            policyDesc: {
                description: this.policyPage.get('name').value
            },
            narrative: this.policyPage.get('description').value
        };
        this.updatePolicyDesc.emit(policyEmit);
        return false;
    }
    isValid() {
        return this.policyPage.valid;
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
exports.BiPolicyDetailsStepComponent = BiPolicyDetailsStepComponent;
