<mc-layout-dashboard-simple
  keyTitle="{{pageTitle()}}"
  [keyDescription]="pageDescription()"
  extendClass=""
  showFavourite="false"
>
  <mc-help-container>
    <mc-help-link url="https://community.mimecast.com/docs/DOC-2981"></mc-help-link>
  </mc-help-container>
  <mc-body-container>
    <mc-tab-group>
      <mc-tab
        name="{{ (showMsaDeviceSummary? '$I18N_REMEDIATION_PAGES.INCIDENT_VIEW.TABS.SUMMARY' : '$I18N_REMEDIATION_PAGES.INCIDENT_VIEW.TABS.SUMMARY_MSA_DISABLED')  | translate }}">
        <div class="tab-container">
          <mc-remediation-incident-summary [incidentSummary]="!(incidentSummaryLoadingError$ | async) && incidentSummary$ | async"
                                           [isIncidentSummaryLoaded]="isIncidentSummaryLoaded$ | async"
                                           [failureSummary]="incidentFailureSummary$ | async">
          </mc-remediation-incident-summary>
        </div>
      </mc-tab>
      <mc-tab name="{{ '$I18N_REMEDIATION_PAGES.INCIDENT_VIEW.TABS.MESSAGES' | translate }}">
        <div class="tab-container"><mc-remediation-incident-messages></mc-remediation-incident-messages></div>
      </mc-tab>
      <mc-tab name="{{ '$I18N_REMEDIATION_PAGES.INCIDENT_VIEW.TABS.DEVICE_SUMMARY' | translate }}" *ngIf="showMsaDeviceSummary">
        <div class="tab-container">
          <mc-remediation-incident-device-summary
            [incidentSummary]="!(incidentSummaryLoadingError$ | async) && incidentSummary$ | async"
            [incidentId]="incidentCode" >
          </mc-remediation-incident-device-summary>
        </div>
      </mc-tab>
    </mc-tab-group>
  </mc-body-container>
</mc-layout-dashboard-simple>
