"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyncAndRecoverOverviewEffect = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const syncRecoverOverviewActions = require("../actions/sync-and-recover-overview.action");
const sync_and_recover_overview_modal_component_1 = require("../components/sync-and-recover-overview-modal/sync-and-recover-overview-modal.component");
const { SYNC_RECOVER_OVERVIEW_OPEN_MODAL, SYNC_RECOVER_OVERVIEW_CLOSE_MODAL, SYNC_RECOVER_OVERVIEW_LOAD_CHART_DATA } = syncRecoverOverviewActions.SyncAndRecoverOverviewActions;
const MODAL_CLOSED = false;
const MODAL_CONFIG = {};
MODAL_CONFIG.size = 'xlg';
MODAL_CONFIG.hasBackdrop = true;
MODAL_CONFIG.disableClose = false;
MODAL_CONFIG.role = 'dialog';
class SyncAndRecoverOverviewEffect {
    constructor(actions$, modal$, syncAndRecoverOverviewApiService) {
        this.actions$ = actions$;
        this.modal$ = modal$;
        this.syncAndRecoverOverviewApiService = syncAndRecoverOverviewApiService;
        this.openModal$ = this.actions$.pipe(effects_1.ofType(SYNC_RECOVER_OVERVIEW_OPEN_MODAL), operators_1.switchMap(() => {
            this.modalInstance = this.modal$.open(sync_and_recover_overview_modal_component_1.SyncAndRecoverOverviewModalComponent, MODAL_CONFIG);
            return this.modalInstance
                .afterClose()
                .pipe(operators_1.map(() => new syncRecoverOverviewActions.SyncAndRecoverOverviewSetModalOpen(MODAL_CLOSED)));
        }));
        this.closeModal$ = this.actions$.pipe(effects_1.ofType(SYNC_RECOVER_OVERVIEW_CLOSE_MODAL), operators_1.filter(() => !!this.modalInstance), operators_1.tap(() => {
            this.modalInstance.close();
            this.modalInstance = undefined;
        }));
        this.loadChartData$ = this.actions$.pipe(effects_1.ofType(SYNC_RECOVER_OVERVIEW_LOAD_CHART_DATA), operators_1.switchMap(() => {
            return this.syncAndRecoverOverviewApiService.loadChartData().pipe(operators_1.map((response) => {
                if (response.hasErrors) {
                    return new syncRecoverOverviewActions.SyncAndRecoverOverviewLoadChartDataFailed(response.fail[0]);
                }
                const stats = response.first || {};
                return new syncRecoverOverviewActions.SyncAndRecoverOverviewLoadChartDataSuccess(stats);
            }), operators_1.catchError(error => rxjs_1.of(new syncRecoverOverviewActions.SyncAndRecoverOverviewLoadChartDataFailed(error.fail))));
        }));
    }
}
__decorate([
    effects_1.Effect()
], SyncAndRecoverOverviewEffect.prototype, "openModal$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], SyncAndRecoverOverviewEffect.prototype, "closeModal$", void 0);
__decorate([
    effects_1.Effect()
], SyncAndRecoverOverviewEffect.prototype, "loadChartData$", void 0);
exports.SyncAndRecoverOverviewEffect = SyncAndRecoverOverviewEffect;
