"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RuleConfig = void 0;
class RuleConfig {
    constructor(name, description, messageRoute, scheduleType, lexiconId, samplingPercentage, searchGroupId, state, reviewQueueId, id) {
        this.name = name;
        this.description = description;
        this.messageRoute = messageRoute;
        this.scheduleType = scheduleType;
        this.lexiconId = lexiconId;
        this.samplingPercentage = samplingPercentage;
        this.searchGroupId = searchGroupId;
        this.state = state;
        this.reviewQueueId = reviewQueueId;
        this.id = id;
    }
}
exports.RuleConfig = RuleConfig;
