'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./mc-dropdown-header-footer-directive"); //mc-dropdown-header-footer.directive
require("./mc-dropdown-row-directive"); //mc-dropdown-row.directive
require("./pre-defined-rows/mc-dropdown-simple-label-row/mc-dropdown-simple-label-row-directive"); //mc-dropdown-simple-label-row.directive
require("./pre-defined-rows/mc-dropdown-multiselect-row/mc-dropdown-multiselect-row-directive"); //mc-dropdown-multiselect-row.directive
/**
 * Created by iaggarwal on 03/03/2017.
 */
angular.module('mc-dropdown.directive', ['mc-dropdown-header-footer.directive', 'mc-dropdown-row.directive', 'mc-dropdown-simple-label-row.directive', 'mc-dropdown-multiselect-row.directive'])
    .directive('mcDropdown', [function () {
        return {
            restrict: 'AE',
            templateUrl: 'components/mc-dropdown/mc-dropdown.tpl.html',
            transclude: true,
            replace: true,
            scope: {
                label: '=?filterLabel',
                iconLabel: '@?iconLabel',
                placementClasses: '@placementClasses',
                buttonTypeClasses: '@buttonTypeClasses',
                dropdownPositionClass: '@dropdownPositionClass',
                toggleButtonStylingClasses: '@toggleButtonStylingClasses',
                hasCaret: '@hasCaret'
            },
            controller: ['$scope', function ($scope) {
                    const self = this;
                    self.buttonTypeClasses = $scope.buttonTypeClasses || 'btn-white';
                    self.placementClasses = $scope.placementClasses;
                    self.autoClose = 'always';
                    self.isOpen = false;
                    self.hasCaret = typeof $scope.hasCaret === 'boolean' ? $scope.hasCaret : $scope.hasCaret === 'true';
                    self.toggleButtonStylingClasses = $scope.toggleButtonStylingClasses ? $scope.toggleButtonStylingClasses : 'btn-white';
                    if ($scope.iconLabel) {
                        self.iconLabel = $scope.iconLabel;
                    }
                }],
            controllerAs: 'mcDropdownCtrl'
        };
    }]);
