"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDetailPageTotal = exports.getMetadata = exports.getRequestPage = exports.getPagination = exports.getSelectedRow = exports.getSearchRequest = exports.getTotalCount = exports.getItems = exports.isLoading = exports.reducer = exports.initialState = void 0;
const actions = require("../../actions");
const commonActions = require("../../../common/actions");
exports.initialState = {
    items: [],
    totalCount: 0,
    searchRequest: {},
    isLoading: false,
    paginatorMetadata: { next: undefined, previous: undefined, pageSize: 50, totalRows: 0 },
    requestedPage: undefined,
    selectedPageSize: 50,
    selectedRow: undefined
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.PROCESSING_MESSAGE_REQUEST:
            return Object.assign(Object.assign({}, state), { items: [], totalCount: 0, searchRequest: mergeSearchRequest(state.searchRequest, action.payload.search), isLoading: true, requestedPage: action.payload.page, selectedRow: undefined });
        case actions.PROCESSING_MESSAGE_REJECT_EMAILS:
            return Object.assign(Object.assign({}, state), { isLoading: true, items: [], totalCount: 0, requestedPage: undefined, selectedRow: undefined });
        case actions.PROCESSING_MESSAGE_EARLY_BOUNCE_EMAILS:
            return Object.assign(Object.assign({}, state), { isLoading: true, items: [], totalCount: 0, requestedPage: undefined, selectedRow: undefined });
        case actions.PROCESSING_MESSAGE_SUCCESS:
            return Object.assign(Object.assign({}, state), { items: action.payload.items, totalCount: (action.payload.page && action.payload.page['totalCount']) || 0, isLoading: false, paginatorMetadata: Object.assign(Object.assign({}, action.payload.page), { totalRows: (action.payload.page && action.payload.page['totalCount']) || 0 }) });
        case actions.PROCESSING_MESSAGE_FAIL:
            return Object.assign(Object.assign({}, state), { isLoading: false });
        case actions.PROCESSING_MESSAGE_SELECT_ROW:
            return Object.assign(Object.assign({}, state), { selectedRow: action.payload });
        case actions.PROCESSING_MESSAGE_PAGINATION:
            return Object.assign(Object.assign({}, state), { isLoading: true, paginatorMetadata: Object.assign(Object.assign({}, state.paginatorMetadata), { pageSize: action.payload.pageSize, next: undefined, previous: undefined }), requestedPage: action.payload, selectedPageSize: action.payload.pageSize || state.selectedPageSize });
        case actions.PROCESSING_MESSAGE_ASIDE_PAGINATION:
            return Object.assign(Object.assign({}, state), { selectedRow: state.items[action.payload] });
        case commonActions.CLEAR_SELECTED_ROW:
            return Object.assign(Object.assign({}, state), { selectedRow: undefined });
        case actions.PROCESSING_MESSAGE_RETRY_EMAILS:
            return Object.assign(Object.assign({}, state), { isLoading: true, items: [], totalCount: 0, requestedPage: undefined, selectedRow: undefined });
        case actions.PROCESSING_MESSAGE_REFRESH:
            return Object.assign(Object.assign({}, state), { isLoading: true });
        default:
            return state;
    }
}
exports.reducer = reducer;
function mergeSearchRequest(prev, next) {
    const merged = {
        start: next.start || prev.start,
        end: next.end || prev.end
    };
    if (!!next.filterBy) {
        if (next.filterBy.length > 0) {
            merged.filterBy = [...next.filterBy];
        }
    }
    else if (!!prev.filterBy) {
        merged.filterBy = [...prev.filterBy];
    }
    if (!!next.searchBy) {
        if (next.searchBy.length > 0) {
            merged.searchBy = [...next.searchBy];
        }
    }
    else if (!!prev.searchBy) {
        merged.searchBy = [...prev.searchBy];
    }
    return merged;
}
const isLoading = (state) => state.isLoading;
exports.isLoading = isLoading;
const getItems = (state) => {
    return [...state.items];
};
exports.getItems = getItems;
const getTotalCount = (state) => {
    return state.totalCount;
};
exports.getTotalCount = getTotalCount;
const getSearchRequest = (state) => {
    return Object.assign(Object.assign(Object.assign({}, state.searchRequest), (!state.searchRequest.start
        ? { start: new Date(new Date().setDate(new Date().getDate() - 2)) }
        : {})), (!state.searchRequest.end ? { end: new Date() } : {}));
};
exports.getSearchRequest = getSearchRequest;
const getSelectedRow = (state) => {
    return state.selectedRow;
};
exports.getSelectedRow = getSelectedRow;
const getPagination = (state) => {
    return Object.assign({}, state.paginatorMetadata);
};
exports.getPagination = getPagination;
const getRequestPage = (state) => {
    if (!!state.requestedPage) {
        return Object.assign({}, state.requestedPage);
    }
    else {
        return {
            pageNumber: 0,
            pageSize: state.selectedPageSize,
            pageToken: undefined
        };
    }
};
exports.getRequestPage = getRequestPage;
const getMetadata = (state) => {
    return {
        pagination: Object.assign({}, state.paginatorMetadata)
    };
};
exports.getMetadata = getMetadata;
const getDetailPageTotal = (state) => state.items.length;
exports.getDetailPageTotal = getDetailPageTotal;
