"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupervisionFindGroupsResetStore = exports.SupervisionFindGroupsFail = exports.SupervisionFindGroupsNotSet = exports.SupervisionFindGroupsLDAPSuccess = exports.SupervisionFindGroupsCloudSuccess = exports.SupervisionFindGroups = exports.FIND_GROUPS_RESET_STORE = exports.FIND_GROUPS_NOT_SET = exports.FIND_GROUPS_FAIL = exports.FIND_GROUPS_LDAP_SUCCESS = exports.FIND_GROUPS_CLOUD_SUCCESS = exports.FIND_GROUPS = void 0;
exports.FIND_GROUPS = '[Supervision] Find Groups';
exports.FIND_GROUPS_CLOUD_SUCCESS = '[Supervision] Find Groups success cloud';
exports.FIND_GROUPS_LDAP_SUCCESS = '[Supervision] Find Groups success ldap';
exports.FIND_GROUPS_FAIL = '[Supervision] Find Groups fail';
exports.FIND_GROUPS_NOT_SET = '[Supervision] Find Groups not set';
exports.FIND_GROUPS_RESET_STORE = '[Supervision] Find Groups reset store';
class SupervisionFindGroups {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.FIND_GROUPS;
    }
}
exports.SupervisionFindGroups = SupervisionFindGroups;
class SupervisionFindGroupsCloudSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.FIND_GROUPS_CLOUD_SUCCESS;
    }
}
exports.SupervisionFindGroupsCloudSuccess = SupervisionFindGroupsCloudSuccess;
class SupervisionFindGroupsLDAPSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.FIND_GROUPS_LDAP_SUCCESS;
    }
}
exports.SupervisionFindGroupsLDAPSuccess = SupervisionFindGroupsLDAPSuccess;
class SupervisionFindGroupsNotSet {
    constructor() {
        this.type = exports.FIND_GROUPS_NOT_SET;
    }
}
exports.SupervisionFindGroupsNotSet = SupervisionFindGroupsNotSet;
class SupervisionFindGroupsFail {
    constructor() {
        this.type = exports.FIND_GROUPS_FAIL;
    }
}
exports.SupervisionFindGroupsFail = SupervisionFindGroupsFail;
class SupervisionFindGroupsResetStore {
    constructor() {
        this.type = exports.FIND_GROUPS_RESET_STORE;
    }
}
exports.SupervisionFindGroupsResetStore = SupervisionFindGroupsResetStore;
