<mc-layout-aside-tabs
  keyTitle="$I18N_SWG_THREAT_EVENT_DETAILS.ASIDE_TITLE"
  showClose="true"
  (closeAside)="closeAside()"
  [isLoading]="isLoading$ | async">

  <mc-extra-container *ngIf="threatEvent$ | async as threatEvent">
    <mc-swg-threat-event-panel-header
      [threatEvent]="threatEvent$ | async"
      [paginatorData]="paginatorData$ | async"
      [hasActionButtonsEnabled]="hasActionButtonsEnabled() | async"
      (selectRow)="navigate($event)"
      (clickDownload)="download()"
      (clickManualQuarantine)="manualQuarantine(threatEvent.id)"
      (clickRelease)="release(threatEvent.id)"
      (clickReportFalsePositive)="reportFalsePositive(threatEvent.id)"
    ></mc-swg-threat-event-panel-header>
  </mc-extra-container>

  <mc-tab-group themeLight="true" resetActiveTabToFirst="true" *ngIf="threatEvent$ | async">
    <mc-tab name="$I18N_SWG_THREAT_EVENT_DETAILS.TAB_SUMMARY">
      <mc-swg-threat-event-summary [threatEvent]="threatEvent$ | async" *ngIf='!(isLoading$ | async)'></mc-swg-threat-event-summary>
    </mc-tab>
    <mc-tab name="$I18N_SWG_THREAT_EVENT_DETAILS.TAB_THREAT_ANALYSIS">
      <mc-swg-threat-event-analysis [threatEvent]="threatEvent$ | async"></mc-swg-threat-event-analysis>
    </mc-tab>
  </mc-tab-group>

  <mc-extra-container *ngIf="!(isLoading$ | async) && !(threatEvent$ | async)">
    <div class="threat-event-not-found">
      <i class="fal fa-exclamation-circle threat-event-not-found__icon"></i>
      <div class="threat-event-not-found-message">
        {{ '$I18N_SWG_THREAT_EVENT_DETAILS.NOT_FOUND_MESSAGE' | translate }}
      </div>
    </div>
  </mc-extra-container>

</mc-layout-aside-tabs>

