"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RuleDetailsComponent = void 0;
const forms_1 = require("@angular/forms");
const rule_service_1 = require("../../../../../services/rule.service");
const step_base_component_1 = require("../../../step.base.component");
const validators_1 = require("app-new/api-applications/validators");
class RuleDetailsComponent extends step_base_component_1.StepBaseDirective {
    constructor(store, fb) {
        super(store, fb, 1, rule_service_1.RuleService.WizardId);
        this.store = store;
        this.fb = fb;
        this.form = this.fb.group({
            name: [
                '',
                [
                    forms_1.Validators.required,
                    forms_1.Validators.minLength(3),
                    forms_1.Validators.maxLength(60),
                    validators_1.validateNotEmpty,
                    forms_1.Validators.pattern(/^[^\s]+(\s+[^\s]+)*$/)
                ]
            ],
            description: [
                '',
                [
                    forms_1.Validators.required,
                    forms_1.Validators.minLength(3),
                    forms_1.Validators.maxLength(1024),
                    validators_1.validateNotEmpty,
                    forms_1.Validators.pattern(/^[^\s]+(\s+[^\s]+)*$/)
                ]
            ]
        });
    }
    ngOnInit() {
        super.ngOnInit();
        this.form.patchValue({ name: this.name, description: this.description });
    }
}
exports.RuleDetailsComponent = RuleDetailsComponent;
