"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RemediationIncidentComponent = void 0;
const incidentSummaryAction = require("../../actions/incidents/incident-summary.action");
const reducers = require("../../reducers");
class RemediationIncidentComponent {
    constructor(stateService, translate, swgService, store, tabService) {
        this.stateService = stateService;
        this.translate = translate;
        this.swgService = swgService;
        this.store = store;
        this.tabService = tabService;
    }
    ngOnInit() {
        this.incidentCode = this.stateService.$state.params.incidentCode;
        this.incidentId = this.stateService.$state.params.incidentId;
        this.showMsaDeviceSummary =
            this.swgService.hasMSAEnabled() && this.stateService.$state.params.showMsaDeviceSummary;
        const request = {
            id: this.incidentId
        };
        this.store.dispatch(new incidentSummaryAction.RemediationIncidentSummaryGetAction(request));
        this.loadFailureSummary(request);
        this.incidentSummary$ = this.store.select(reducers.incidentSummary);
        this.incidentFailureSummary$ = this.store.select(reducers.incidentFailureSummary);
        this.incidentSummaryLoadingError$ = this.store.select(reducers.incidentSummaryLoadingError);
        this.isIncidentSummaryLoaded$ = this.store.select(reducers.isIncidentSummaryLoaded);
        this.incidentSummarySubscription$ = this.incidentSummary$.subscribe((incidentSummary) => {
            if (incidentSummary) {
                this.type = incidentSummary.type;
                this.fileHash = incidentSummary.searchCriteria.fileHash;
            }
        });
        this.tabService.getSelectedTab().onRefresh = () => {
            this.incidentMessagesComponent.onPaginationChange(this.incidentMessagesComponent.currentPagination);
            if (this.showMsaDeviceSummary) {
                this.incidentDeviceSummaryComponent.onPaginationChange(this.incidentDeviceSummaryComponent.currentPagination);
            }
            this.store.dispatch(new incidentSummaryAction.RemediationIncidentSummaryGetAction(request));
            this.loadFailureSummary(request);
        };
    }
    ngOnDestroy() {
        this.incidentSummarySubscription$.unsubscribe();
        this.store.dispatch(new incidentSummaryAction.RemediationIncidentSummaryResetAction());
    }
    pageTitle() {
        return this.translate.instant('$I18N_REMEDIATION_PAGES.INCIDENT_VIEW.PAGE_TITLE', {
            incidentCode: this.incidentCode
        });
    }
    pageDescription() {
        if (this.type) {
            return this.translate.instant('$I18N_REMEDIATION_PAGES.INCIDENT_VIEW.PAGE_DESCRIPTION.' + this.type.toUpperCase());
        }
        else {
            return this.translate.instant('$I18N_REMEDIATION_PAGES.INCIDENT_VIEW.LOADING');
        }
    }
    loadFailureSummary(request) {
        this.store.dispatch(new incidentSummaryAction.GetIncidentFailureSummaryAction(request));
    }
}
exports.RemediationIncidentComponent = RemediationIncidentComponent;
