<mc-layout-list-extra-container
        keyTitle="$I18N_IDENTITY_DASHBOARD.PAGE_TITLE"
        keyDescription="$I18N_IDENTITY_DASHBOARD.PAGE_DESCRIPTION">
    <mc-help-container>
        <mc-help-link url="https://community.mimecast.com"></mc-help-link>
    </mc-help-container>
    <mc-body-container>
        <mc-tab-group (tabChanged)="onTabChange($event)">
            <mc-tab name="{{ identityDashboardTabs[0].label | translate }}">
                <mc-identity-apps-list></mc-identity-apps-list>
            </mc-tab>
            <mc-tab name="{{ identityDashboardTabs[1].label | translate }}">
                <mc-identity-policy-list></mc-identity-policy-list>
            </mc-tab>
        </mc-tab-group>
    </mc-body-container>
</mc-layout-list-extra-container>


