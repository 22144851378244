"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
angular.module('services.services.server-connections-list-guard', [])
    .factory('serverConnectionListGuard', ['$q', 'serverConnectionService', function ($q, serverConnectionService) {
        const self = this;
        self.serverConnectionsResponse = [];
        self.getServerConnections = () => {
            return serverConnectionService.getServerConnection().then(function (response) {
                self.serverConnectionsResponse = response.all;
                return response.all;
            }, function (error) {
                // @ts-ignore
                serverConnectionService.getError().showErrorNotification(response);
                return $q.reject(error);
            }, function () {
                serverConnectionService.getError().showErrorNotification();
            });
        };
        return self;
    }]);
