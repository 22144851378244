"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IncidentDeviceSummaryComponent = void 0;
const table_store_1 = require("@mimecast-ui/table-store");
const remediate_messages_action_1 = require("../../../actions/remediate-messages/remediate-messages.action");
const moment = require("moment");
class IncidentDeviceSummaryComponent extends table_store_1.TableBaseComponent {
    constructor(store) {
        super(store, 'SwgRemediationDeviceList');
        this.store = store;
        this.incidentPayload = {};
        this.searchBy = {};
        this.filters = {};
        this.columns = [
            'deviceUser',
            'machineName',
            'remediatedFrom',
            'filename',
            'fileHash',
            'remediatedOn',
            'remediationResult',
            'right-column'
        ];
        this.searchFilters = [
            { name: 'status', filters: ['notfound', 'failed', 'success'] }
        ];
        this.filterSearchOptions = [
            {
                label: '$18N_SWG_REMEDIATION_DEVICE_SUMMARY.TABLE.ALL',
                value: 'all'
            },
            {
                label: '$18N_SWG_REMEDIATION_DEVICE_SUMMARY.TABLE.COLUMNS.DEVICEUSER',
                value: 'user'
            },
            {
                label: '$18N_SWG_REMEDIATION_DEVICE_SUMMARY.TABLE.COLUMNS.MACHINENAME',
                value: 'device'
            }
        ];
        this.progressMessage = 'progress';
        this.canCancel = false;
    }
    ngOnInit() {
        this.incidentPayload = { incidentId: this.incidentId };
        this.dispatchLoadAction(this.incidentPayload);
    }
    ngOnChanges(changes) {
        const change = changes['incidentSummary'];
        if (change && !change.isFirstChange()) {
            this.updateDeviceRemediationProgressMessage();
        }
    }
    updateDeviceRemediationProgressMessage() {
        this.canCancel = this.incidentSummary.fileRemediationCanBeCancelled;
        if (this.incidentSummary.fileRemediationCancelled) {
            this.canCancel = false;
            this.progressMessage = 'cancel';
            return;
        }
        if (moment(this.incidentSummary.fileRemediationExpiryTime).isBefore(moment())) {
            this.progressMessage = 'expire';
            this.canCancel = false;
        }
    }
    onSearchIncidents(data) {
        this.searchBy = { searchBy: { fieldName: data.filterBy, value: data.search } };
        this.runSearch();
    }
    onSearchClear() {
        this.searchBy = {};
        this.runSearch();
    }
    onBundleFilterChange(filters) {
        if (Object.keys(filters).length) {
            const filterBy = filters.status.map((status) => ({
                fieldName: 'result',
                value: status
            }));
            this.filters = { filterBy };
        }
        else {
            this.filters = {};
        }
        this.runSearch();
    }
    onOpenCancelRemediationModal() {
        this.store.dispatch(new remediate_messages_action_1.OpenCancelDeviceRemediatonPopup());
    }
    runSearch(pagination) {
        this.dispatchFilterAction(Object.assign(Object.assign(Object.assign({}, this.incidentPayload), this.searchBy), this.filters), pagination);
    }
    onPaginationChange(pagination) {
        this.runSearch(pagination);
    }
}
exports.IncidentDeviceSummaryComponent = IncidentDeviceSummaryComponent;
