"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.getIsContentOpening = exports.getHasCreateError = exports.getFail = exports.getSnapshotDetails = exports.getIsRequesting = exports.getSnapshotState = exports.getExchangeState = exports.reducers = void 0;
const fromSnapshot = require("./snapshot");
const store_1 = require("@ngrx/store");
exports.reducers = {
    snapshot: fromSnapshot.reducer
};
exports.getExchangeState = store_1.createFeatureSelector('sync-recover-exchange');
const ɵ0 = (state) => state.snapshot;
exports.ɵ0 = ɵ0;
exports.getSnapshotState = store_1.createSelector(exports.getExchangeState, ɵ0);
exports.getIsRequesting = store_1.createSelector(exports.getSnapshotState, fromSnapshot.getIsRequestingCreate);
exports.getSnapshotDetails = store_1.createSelector(exports.getSnapshotState, fromSnapshot.getCreateSnapshotDetails);
exports.getFail = store_1.createSelector(exports.getSnapshotState, fromSnapshot.getCreateFail);
exports.getHasCreateError = store_1.createSelector(exports.getSnapshotState, fromSnapshot.getHasCreateError);
exports.getIsContentOpening = store_1.createSelector(exports.getSnapshotState, fromSnapshot.getIsContentOpening);
