"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgPolicyLoadProtectedApplicationsFailure = exports.SwgPolicyLoadProtectedApplicationsSuccess = exports.SwgPolicyLoadProtectedApplications = exports.SwgPolicyClearAdvancedFileTypes = exports.SwgPolicyLoadApplicationsFailure = exports.SwgPolicyLoadApplicationsSuccess = exports.SwgPolicyLoadApplications = exports.SWG_POLICY_LOAD_PROTECTED_APPLICATIONS_FAILURE = exports.SWG_POLICY_LOAD_PROTECTED_APPLICATIONS_SUCCESS = exports.SWG_POLICY_LOAD_PROTECTED_APPLICATIONS = exports.SWG_POLICY_CLEAR_APPLICATIONS = exports.SWG_POLICY_LOAD_APPLICATIONS_FAILURE = exports.SWG_POLICY_LOAD_APPLICATIONS_SUCCESS = exports.SWG_POLICY_LOAD_APPLICATIONS = void 0;
exports.SWG_POLICY_LOAD_APPLICATIONS = '[Policy] Swg policy load applications';
exports.SWG_POLICY_LOAD_APPLICATIONS_SUCCESS = '[Policy] Swg policy load applications success';
exports.SWG_POLICY_LOAD_APPLICATIONS_FAILURE = '[Policy] Swg policy load applications failure';
exports.SWG_POLICY_CLEAR_APPLICATIONS = '[Policy] Swg policy clear applications';
exports.SWG_POLICY_LOAD_PROTECTED_APPLICATIONS = '[Policy] Swg policy load protected applications';
exports.SWG_POLICY_LOAD_PROTECTED_APPLICATIONS_SUCCESS = '[Policy] Swg policy load protected applications success';
exports.SWG_POLICY_LOAD_PROTECTED_APPLICATIONS_FAILURE = '[Policy] Swg policy load protected applications failure';
class SwgPolicyLoadApplications {
    constructor() {
        this.type = exports.SWG_POLICY_LOAD_APPLICATIONS;
    }
}
exports.SwgPolicyLoadApplications = SwgPolicyLoadApplications;
class SwgPolicyLoadApplicationsSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SWG_POLICY_LOAD_APPLICATIONS_SUCCESS;
    }
}
exports.SwgPolicyLoadApplicationsSuccess = SwgPolicyLoadApplicationsSuccess;
class SwgPolicyLoadApplicationsFailure {
    constructor() {
        this.type = exports.SWG_POLICY_LOAD_APPLICATIONS_FAILURE;
    }
}
exports.SwgPolicyLoadApplicationsFailure = SwgPolicyLoadApplicationsFailure;
class SwgPolicyClearAdvancedFileTypes {
    constructor() {
        this.type = exports.SWG_POLICY_CLEAR_APPLICATIONS;
    }
}
exports.SwgPolicyClearAdvancedFileTypes = SwgPolicyClearAdvancedFileTypes;
class SwgPolicyLoadProtectedApplications {
    constructor() {
        this.type = exports.SWG_POLICY_LOAD_PROTECTED_APPLICATIONS;
    }
}
exports.SwgPolicyLoadProtectedApplications = SwgPolicyLoadProtectedApplications;
class SwgPolicyLoadProtectedApplicationsSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SWG_POLICY_LOAD_PROTECTED_APPLICATIONS_SUCCESS;
    }
}
exports.SwgPolicyLoadProtectedApplicationsSuccess = SwgPolicyLoadProtectedApplicationsSuccess;
class SwgPolicyLoadProtectedApplicationsFailure {
    constructor() {
        this.type = exports.SWG_POLICY_LOAD_PROTECTED_APPLICATIONS_FAILURE;
    }
}
exports.SwgPolicyLoadProtectedApplicationsFailure = SwgPolicyLoadProtectedApplicationsFailure;
