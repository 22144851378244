"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.logsPageConfigData = void 0;
const components_1 = require("@mimecast-ui/components");
exports.logsPageConfigData = {
    tableColumns: {
        senderAddress: { exportValue: 'from', exportDisplayOrder: 0 },
        recipientAddress: { exportValue: 'to', exportDisplayOrder: 2 },
        subject: { exportValue: 'subject', exportDisplayOrder: 4 },
        definition: { exportValue: 'definition', exportDisplayOrder: 6 },
        hits: { exportValue: 'hits', exportDisplayOrder: 8 },
        taggedMalicious: { exportValue: 'tagged', exportDisplayOrder: 1 },
        senderIpAddress: { exportValue: 'senderip', exportDisplayOrder: 3 },
        action: { exportValue: 'action', exportDisplayOrder: 5 },
        eventTime: { exportValue: 'datetime', exportDisplayOrder: 7 },
        'row-actions': {}
    },
    tableColumnsAlwaysVisible: ['senderAddress', 'eventTime'],
    filterSections: {
        identifiers: {
            exportPrefix: 'indicators',
            filters: {
                similar_internal_domain: { exportValue: '2' },
                similar_monitored_external_domain: { exportValue: '5,6,8,9' },
                newly_observed_domain: { exportValue: '1' },
                custom_name_list: { exportValue: '10' },
                internal_user_name: { exportValue: '2' },
                reply_address_mismatch: { exportValue: '3' },
                targeted_threat_dictionary: { exportValue: '4' }
            }
        },
        actions: {
            exportPrefix: 'action',
            filters: {
                none: { exportValue: '0' },
                hold: { exportValue: '1' },
                bounce: { exportValue: '2' }
            }
        }
    },
    exportSetup: {
        columnNamePrefix: '$I18N_TTP_IMPERSONATION_LOGS_TABLE.COLUMNS',
        exportUid: 'ttpiplogs'
    },
    dateRangeConfig: components_1.dateRangeConfig
};
