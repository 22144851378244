"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ1 = exports.ɵ0 = exports.getValidatedConsent = exports.isConsentValidationFailed = exports.isValidatingConsent = exports.getFormResets = exports.getConsentConfig = exports.getProviders = exports.getProducts = exports.isLoadingConsentConfig = exports.isLoadingProviders = exports.getAsidePagination = exports.selectedRow = exports.getConnectorUsage = exports.loadingConnectorUsage = exports.loadingConnectorDetails = exports.getConnectorDetails = exports.isLoadingProducts = exports.isDeletingConnector = exports.getStepData = exports.isStepValid = exports.getStepById = exports.isCurrentStepValid = exports.getCurrentStepState = exports.getCurrentStep = exports.getWizardById = exports.getState = exports.getWizardState = exports.getConnectorsState = exports.reducers = void 0;
const store_1 = require("@ngrx/store");
const wizardReducer = require("./wizard.reducer");
const connectorsReducer = require("./connectors.reducer");
exports.reducers = {
    wizard: wizardReducer.reducer,
    connectors: connectorsReducer.reducer
};
exports.getConnectorsState = store_1.createFeatureSelector('connectors');
const ɵ0 = (state) => state.wizard;
exports.ɵ0 = ɵ0;
exports.getWizardState = store_1.createSelector(exports.getConnectorsState, ɵ0);
const ɵ1 = (state) => state.connectors;
exports.ɵ1 = ɵ1;
exports.getState = store_1.createSelector(exports.getConnectorsState, ɵ1);
const getWizardById = (wizardId) => store_1.createSelector(exports.getWizardState, wizardReducer.getWizardById(wizardId));
exports.getWizardById = getWizardById;
const getCurrentStep = (wizardId) => store_1.createSelector(exports.getWizardById(wizardId), wizardReducer.getCurrentStep);
exports.getCurrentStep = getCurrentStep;
const getCurrentStepState = (wizardId) => store_1.createSelector(exports.getWizardById(wizardId), wizardReducer.getCurrentStepState);
exports.getCurrentStepState = getCurrentStepState;
const isCurrentStepValid = (wizardId) => store_1.createSelector(exports.getCurrentStepState(wizardId), wizardReducer.isStepValid);
exports.isCurrentStepValid = isCurrentStepValid;
const getStepById = (wizardId, stepId) => store_1.createSelector(exports.getWizardById(wizardId), wizardReducer.getStepById(stepId));
exports.getStepById = getStepById;
const isStepValid = (wizardId, stepId) => store_1.createSelector(exports.getStepById(wizardId, stepId), wizardReducer.isStepValid);
exports.isStepValid = isStepValid;
const getStepData = (wizardId, stepId) => store_1.createSelector(exports.getStepById(wizardId, stepId), wizardReducer.getStepData);
exports.getStepData = getStepData;
exports.isDeletingConnector = store_1.createSelector(exports.getState, connectorsReducer.isDeletingConnector);
exports.isLoadingProducts = store_1.createSelector(exports.getState, connectorsReducer.isLoadingProducts);
exports.getConnectorDetails = store_1.createSelector(exports.getState, connectorsReducer.connectorDetails);
exports.loadingConnectorDetails = store_1.createSelector(exports.getState, connectorsReducer.loadingConnectorDetails);
exports.loadingConnectorUsage = store_1.createSelector(exports.getState, connectorsReducer.loadingConnectorUsage);
exports.getConnectorUsage = store_1.createSelector(exports.getState, connectorsReducer.getConnectorUsage);
exports.selectedRow = store_1.createSelector(exports.getState, connectorsReducer.selectedRow);
exports.getAsidePagination = store_1.createSelector(exports.getState, connectorsReducer.getAsidePagination);
exports.isLoadingProviders = store_1.createSelector(exports.getState, connectorsReducer.isLoadingProviders);
exports.isLoadingConsentConfig = store_1.createSelector(exports.getState, connectorsReducer.isLoadingConsentConfig);
exports.getProducts = store_1.createSelector(exports.getState, connectorsReducer.getProducts);
exports.getProviders = store_1.createSelector(exports.getState, connectorsReducer.getProviders);
exports.getConsentConfig = store_1.createSelector(exports.getState, connectorsReducer.getConsentConfig);
exports.getFormResets = store_1.createSelector(exports.getState, connectorsReducer.getFormResets);
exports.isValidatingConsent = store_1.createSelector(exports.getState, connectorsReducer.isValidatingConsent);
exports.isConsentValidationFailed = store_1.createSelector(exports.getState, connectorsReducer.isConsentValidationFailed);
exports.getValidatedConsent = store_1.createSelector(exports.getState, connectorsReducer.getValidatedConsent);
