<mc-layout-aside-extra-pagination-container
  keyTitle="$I18N_ENDPOINTS_LIST.PAGE_HEADER"
  showClose="true"
  [hasNextInView]="hasNextInView$ | async"
  [hasPreviousInView]="hasPreviousInView$ | async"
  [indexInView]="indexInView$ | async"
  [currenPagination]="currentPagination$ | async"
  [filtersMetaData]="filtersMetaData$ | async"
  [isLoading]="false"
  (goToNextPage)="getNext()"
  (goToPrevPage)="getPrev()"
  (closeAside)="close()"
>
  <div row-actions>
    <div class="mc-aside-header">{{ '$18N_SWG_REPORTING.TABLE.DETAILS' | translate }}</div>
  </div>

  <mc-body-container class ="mc-sidebar-body-container">
    <mc-tab-group class="mc-aside-body mc-sidebar-body">
      <mc-tab name="{{ '$I18N_ENDPOINTS_LIST.SIDEBAR.DETAILS_TAB.TAB_TITLE' | translate }}">
        <mc-swg-endpoints-sidebar-details-component
          [row]="selectedRow$ | async"
        ></mc-swg-endpoints-sidebar-details-component>
      </mc-tab>
      <mc-tab *ngIf="hasMultiUsersSupport" name="{{ '$I18N_ENDPOINTS_LIST.SIDEBAR.SERVER_USERS_TAB.TAB_TITLE' | translate }}">
        <mc-swg-endpoints-sidebar-server-users-component
        [row]="selectedRow$ | async"></mc-swg-endpoints-sidebar-server-users-component>
      </mc-tab>
    </mc-tab-group>
  </mc-body-container>
</mc-layout-aside-extra-pagination-container>
