"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WizardModule = void 0;
const queueSteps = require("./queue/steps");
const wizardSteps = require("./lexicon/steps");
const ruleSteps = require("./rule/steps");
const cancel_confirmation_modal_component_1 = require("./cancel-confirmation-modal/cancel-confirmation-modal.component");
const lexicon_component_1 = require("./lexicon/lexicon.component");
const queue_component_1 = require("./queue/queue.component");
const select_reviewers_component_1 = require("../asides/select-reviewers/select-reviewers.component");
const select_lexicon_component_1 = require("../asides/select-lexicon/select-lexicon.component");
const select_queue_component_1 = require("../asides/select-queue/select-queue.component");
const rule_component_1 = require("./rule/rule.component");
const DECLARATIONS = [
    lexicon_component_1.LexiconComponent,
    queue_component_1.QueueComponent,
    rule_component_1.RuleComponent,
    cancel_confirmation_modal_component_1.CancelConfirmationModalComponent,
    wizardSteps.steps,
    queueSteps.steps,
    ruleSteps.steps,
    select_reviewers_component_1.SelectReviewersComponent,
    select_lexicon_component_1.SelectLexiconComponent,
    select_queue_component_1.SelectQueueComponent
];
class WizardModule {
}
exports.WizardModule = WizardModule;
