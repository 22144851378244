"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const _ = require("lodash");
;
(function () {
    'use strict';
    /* ======================================================== *\

     = Archive Tasks List - controller

     \* ======================================================== */
    angular.module('services.services.backup.archive-task-list.controller', [])
        .controller('ArchiveTaskListController', ['windowService', 'backupService', 'modalActionService', 'ngProgressFactory', 'archiveTaskListService', 'adconBackupService', 'syncAndRecoverOverviewModalService', function (windowService, backupService, modalActionService, ngProgressFactory, archiveTaskListService, adconBackupService, syncAndRecoverOverviewModalService) {
            const self = this;
            const ngProgress = ngProgressFactory.createInstance();
            /* -------------------------------------------------------- *\
             - interface
             \* -------------------------------------------------------- */
            self.inIframe = windowService.inIframe();
            self.archiveTasks = [];
            self.isLoadingData = true;
            self.taskStatusOptions = backupService.constants.STATUS;
            self.closeActionModal = archiveTaskListService.closeActionModal;
            self.resumeBackupTask = archiveTaskListService.resumeBackupTask;
            self.pauseBackupTask = archiveTaskListService.pauseBackupTask;
            self.deleteArchiveTaskAndCloseModal = archiveTaskListService.deleteArchiveTaskAndCloseModal;
            self.serverType = backupService.constants.SERVER_TYPE;
            self.status = archiveTaskListService.ARCHIVE_TASK_STATUS;
            self.hasPermission = adconBackupService.CONSTANTS.HAS_PERMISSIONS;
            self.showDeleteAlert = showDeleteAlert;
            self.showPauseAlert = showPauseAlert;
            self.showResumeAlert = showResumeAlert;
            self.getStatusStyleAndMessage = getStatusStyleAndMessage;
            self.openOverviewModal = openOverviewModal;
            self.getModalOpenStatus = getModalOpenStatus;
            /* -------------------------------------------------------- *\
             - initialisation
             \* -------------------------------------------------------- */
            activate();
            /* -------------------------------------------------------- *\
             - implementation
             \* -------------------------------------------------------- */
            // == activate ========
            function activate() {
                ngProgress.start();
                self.isLoadingData = true;
                return backupService.getBackupTask().then(function (response) {
                    if (response.any) {
                        self.archiveTasks = _.sortBy(response.all, function (task) {
                            return task.description.toLowerCase();
                        });
                    }
                    else {
                        backupService.getError().showErrorNotification(response);
                    }
                    ngProgress.complete();
                    self.isLoadingData = false;
                }, function () {
                    ngProgress.complete();
                    backupService.getError().showErrorNotification();
                });
            }
            // == showPauseAlert ========
            function showPauseAlert(id) {
                modalActionService.openActionModal(archiveTaskListService.getPauseJson(id, self.pauseBackupTask)).then(function () {
                    activate();
                });
            }
            // == showDeleteAlert ========
            function showDeleteAlert(id, name) {
                modalActionService.openActionModal(archiveTaskListService.getDeleteConfirmationJson(id, name, self.deleteArchiveTaskAndCloseModal)).then(function () {
                    self.archiveTasks = [];
                    activate();
                });
            }
            // == showResumeAlert ========
            function showResumeAlert(id) {
                modalActionService.openActionModal(archiveTaskListService.getResumeJson(id, self.resumeBackupTask)).then(function () {
                    activate();
                });
            }
            function getStatusStyleAndMessage(archiveTask) {
                return adconBackupService.getStatusStyleAndMessage(archiveTask.detailedStatus, archiveTask.status);
            }
            function openOverviewModal() {
                syncAndRecoverOverviewModalService.openModal();
            }
            function getModalOpenStatus() {
                return syncAndRecoverOverviewModalService.isModalOpen;
            }
        }]);
}());
