"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListComponent = void 0;
const table_store_1 = require("@mimecast-ui/table-store");
class ListComponent extends table_store_1.TableBaseComponent {
    constructor(store) {
        super(store, 'ExchangeExportQueue');
        this.store = store;
        // TODO: Check with PM and API for available fields. The ones in wireframes do not match API response
        // TODO: API should expose a field to query with type of task - Restore or Export
        this.columnList = [
            'mailbox',
            'recoveryId',
            'recoveryType',
            'created',
            'started',
            'exportSettings',
            'status'
        ];
    }
}
exports.ListComponent = ListComponent;
