"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiIntegrationNotificationComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const validator_service_1 = require("app-new/components/form-validators/email/validator.service");
require("./notification.component.scss");
const group_selector_component_1 = require("app-new/components/group-selector/group-selector.component");
class ApiIntegrationNotificationComponent {
    constructor(formBuilder, asideService) {
        this.formBuilder = formBuilder;
        this.asideService = asideService;
        this.onDeleteEmail = new core_1.EventEmitter();
        this.onVerifyAndAddEmail = new core_1.EventEmitter();
        this.onDeleteGroup = new core_1.EventEmitter();
        this.onAddGroup = new core_1.EventEmitter();
        this.maxNumberOfGroups = 5;
        this.isAddGroupBtnDisabled = false;
    }
    ngOnChanges(changes) {
        /* first check if groupsList object is in the changes object because
        onNgChanges triggers often, on every single change made to any part of the component. If triggered
        becase there is a change to ony of those lists, then check the limit */
        if (changes.groupsList) {
            this.isAddGroupBtnDisabled = changes.groupsList.currentValue.length >= this.maxNumberOfGroups;
        }
    }
    ngOnInit() {
        this.form = this.formBuilder.group({
            email: new forms_1.FormControl('', validator_service_1.EmailValidator.validAndNotRequired)
        });
    }
    openSidebar() {
        const asideConfig = {};
        const aside = this.asideService.open(group_selector_component_1.GroupSelectorComponent, asideConfig);
        aside.beforeClose().subscribe((selectedGroup) => {
            if (selectedGroup &&
                this.groupsList.map(group => group.description).indexOf(selectedGroup.description) === -1) {
                this.onAddGroup.emit(selectedGroup);
            }
        });
    }
    deleteGroup(selectedGroup) {
        this.onDeleteGroup.emit(selectedGroup);
    }
    addEmail(email) {
        this.onVerifyAndAddEmail.emit(email);
    }
    deleteEmail(email) {
        this.onDeleteEmail.emit(email);
    }
}
exports.ApiIntegrationNotificationComponent = ApiIntegrationNotificationComponent;
