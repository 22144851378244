"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CyberGraphPolicySummaryStepComponentHelper = void 0;
const cybergraph_applies_to_settings_step_models_1 = require("../cybergraph-applies-to-settings-step/cybergraph-applies-to-settings-step.models");
const cybergraph_applies_to_settings_step_component_static_values_1 = require("../cybergraph-applies-to-settings-step/cybergraph-applies-to-settings-step.component.static-values");
class CyberGraphPolicySummaryStepComponentHelper {
    constructor(translateService) {
        this.translateService = translateService;
    }
    // Applies To
    getAddressesBasedOnLabel(appliesToStep) {
        return this.getSelectItemLabelForEnum(appliesToStep, cybergraph_applies_to_settings_step_models_1.AddressesBasedOnOptions, cybergraph_applies_to_settings_step_component_static_values_1.addressesBasedOnOptions, 'addressesBasedOn');
    }
    getAppliesFromToLabel(appliesToStep, fieldName, isAppliesFrom = true) {
        const label = this.getSelectItemLabelForEnum(appliesToStep, cybergraph_applies_to_settings_step_models_1.AppliesFromToOptions, cybergraph_applies_to_settings_step_component_static_values_1.appliesFromToOptions, fieldName);
        let value = '';
        const selectedOption = appliesToStep[fieldName];
        const isAddressField = selectedOption === cybergraph_applies_to_settings_step_models_1.AppliesFromToOptions.EMAIL_ADDRESS;
        const isDomainField = selectedOption === cybergraph_applies_to_settings_step_models_1.AppliesFromToOptions.DOMAIN;
        const isAddressGroupField = selectedOption === cybergraph_applies_to_settings_step_models_1.AppliesFromToOptions.ADDRESS_GROUP;
        if (isAddressField) {
            value = this.getAppliesFromToAddressValue(isAppliesFrom, appliesToStep);
        }
        else if (isDomainField) {
            value = this.getAppliesFromToDomainValue(isAppliesFrom, appliesToStep);
        }
        else if (isAddressGroupField) {
            value = this.getAppliesFromToAddressGroupDescriptionValue(isAppliesFrom, appliesToStep);
            value = `\n${value}`;
        }
        if (value) {
            value = `: ${value}`;
        }
        return label + value;
    }
    getAppliesFromToAddressValue(isAppliesFrom, step) {
        return isAppliesFrom ? step.appliesFromAddress : step.appliesToAddress;
    }
    getAppliesFromToDomainValue(isAppliesFrom, step) {
        return isAppliesFrom ? step.appliesFromDomain : step.appliesToDomain;
    }
    getAppliesFromToAttributeLabel(isAppliesFrom, step) {
        return isAppliesFrom ? step.fromWhereAttribute.label : step.toWhereAttribute.label;
    }
    getAppliesFromToAddressGroupDescriptionValue(isAppliesFrom, step) {
        return isAppliesFrom
            ? step.appliesFromAddressGroup && step.appliesFromAddressGroup.description
            : step.appliesToAddressGroup && step.appliesToAddressGroup.description;
    }
    getSelectItemLabelForEnum(step, enumValue, selectItemOptions, stepField) {
        const key = Object.keys(enumValue).find(enumKey => {
            return enumValue[enumKey] === step[stepField];
        });
        const option = selectItemOptions.find(selectItemOption => {
            return selectItemOption.value === enumValue[key];
        });
        return option ? this.translate(option.label) : '';
    }
    translate(value) {
        return this.translateService.instant(value);
    }
}
exports.CyberGraphPolicySummaryStepComponentHelper = CyberGraphPolicySummaryStepComponentHelper;
