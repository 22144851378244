"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgEndpointsListPurgeModalContainerComponent = void 0;
const reducers_1 = require("../../reducers");
class SwgEndpointsListPurgeModalContainerComponent {
    constructor(store) {
        this.store = store;
    }
    ngOnInit() {
        this.endpointsCount$ = this.store.select(reducers_1.getPurgeEndpointsListCount);
    }
}
exports.SwgEndpointsListPurgeModalContainerComponent = SwgEndpointsListPurgeModalContainerComponent;
