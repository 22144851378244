"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IdentityAppliesToStepComponent = void 0;
const forms_1 = require("@angular/forms");
const group_selector_component_1 = require("../../../../components/group-selector/group-selector.component");
const rxjs_1 = require("rxjs");
const policy_wizard_1 = require("../../../reducers/policy-wizard");
const stepActions = require("../../../actions/policy-wizard/applies-to-step.actions");
const wizardActions = require("../../../actions/policy-wizard/wizard.actions");
const operators_1 = require("rxjs/operators");
class IdentityAppliesToStepComponent {
    constructor(formBuilder, asideService, store, translateService) {
        this.formBuilder = formBuilder;
        this.asideService = asideService;
        this.store = store;
        this.translateService = translateService;
        this.groupsListCount = 0;
    }
    ngOnInit() {
        this.selectGroupsLabel$ = new rxjs_1.BehaviorSubject(this.translateService.instant('$I18N_IDENTITY_CREATE_POLICY_APPLIES_TO_STEP.ELEMENTS.GROUPS.SELECT_GROUPS_LABEL'));
        this.policyAppliesToForm = this.formBuilder.group({
            appliesToType: ['everyone', [forms_1.Validators.required]]
        });
        this.groupsList$ = this.store.select(policy_wizard_1.getGroups);
        this.groupsListSubscription = this.groupsList$.subscribe(groups => {
            this.groupsListCount = groups.length;
            const selectGroupsLabel = groups.length === 0
                ? '$I18N_IDENTITY_CREATE_POLICY_APPLIES_TO_STEP.ELEMENTS.GROUPS.SELECT_GROUPS_LABEL'
                : '$I18N_IDENTITY_CREATE_POLICY_APPLIES_TO_STEP.ELEMENTS.GROUPS.SELECT_MORE_GROUPS_LABEL';
            this.selectGroupsLabel$.next(this.translateService.instant(selectGroupsLabel));
            this.dispatchValid();
        });
        this.valueChangesSubscription = this.policyAppliesToForm.valueChanges.subscribe(value => {
            const appliedToEveryone = value.appliesToType === 'everyone';
            this.store.dispatch(new stepActions.SaveAppliesToEveryoneAction(appliedToEveryone));
            this.dispatchValid();
        });
        this.store
            .select(policy_wizard_1.getAppliesToStepState)
            .pipe(operators_1.first())
            .subscribe(state => {
            this.policyAppliesToForm.patchValue({
                appliesToType: state.appliedToEveryone ? 'everyone' : 'groups'
            }, { emitEvent: false });
            this.dispatchValid();
        });
    }
    dispatchValid() {
        const appliesTo = this.policyAppliesToForm.get('appliesToType').value;
        const isValid = appliesTo === 'everyone' || this.groupsListCount > 0;
        this.store.dispatch(new wizardActions.UpdateIsValidAction(5, isValid));
    }
    openSidebar() {
        const asideConfig = {};
        const aside = this.asideService.open(group_selector_component_1.GroupSelectorComponent, asideConfig);
        aside.beforeClose().subscribe(selectedGroup => {
            if (selectedGroup) {
                this.store.dispatch(new stepActions.AddGroupCortexIntegrationNotificationsAction(selectedGroup));
            }
        });
    }
    onDeleteGroup(row) {
        this.store.dispatch(new stepActions.RemoveGroupAction(row));
    }
    ngOnDestroy() {
        this.valueChangesSubscription.unsubscribe();
        this.groupsListSubscription.unsubscribe();
    }
}
exports.IdentityAppliesToStepComponent = IdentityAppliesToStepComponent;
