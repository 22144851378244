<mc-template-frame>
  <mc-feedback-badge status="info" text="$I18N_AWARENESS_TEMPLATE_SETUP_USER_CHALLENGE_PAGE_EMAIL_SECURITY_TRAINING"></mc-feedback-badge>

  <mc-template-title-sub-title [title]="title" [subTitle]="subTitle"></mc-template-title-sub-title>

  <div class="mc-bottom-items-container" *ngFor="let item of itemsToShowAtTheBottom" [attr.data-test]="item.qaGroupMessage">
    <div class="mc-bottom-item-title" [translate]="item.title"></div>
    <div class="mc-bottom-item-description" [translate]="item.description"></div>
  </div>

  <div class="mc-buttons-container">
    <button class="btn btn-primary" translate="$I18N_AWARENESS_TEMPLATE_SETUP_USER_CHALLENGE_PAGE_ITS_SAFE_BUTTON" data-test="SAFE_BUTTON"></button>
    <button class="btn btn-primary" translate="$I18N_AWARENESS_TEMPLATE_SETUP_USER_CHALLENGE_PAGE_ITS_HARMFUL_BUTTON" data-test="HARMFUL_BUTTON"></button>
  </div>
</mc-template-frame>
