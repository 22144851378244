"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DirectorySyncTestStepComponent = void 0;
const core_1 = require("@angular/core");
class DirectorySyncTestStepComponent {
    constructor() {
        this.isCreateIntegrationButtonEnabled = new core_1.EventEmitter();
    }
    createIntegrationButtonEnabled(isEnabled) {
        this.isCreateIntegrationButtonEnabled.emit(isEnabled);
    }
}
exports.DirectorySyncTestStepComponent = DirectorySyncTestStepComponent;
