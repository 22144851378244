"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefinitionDeleteCancelAction = exports.DefinitionDeleteFailAction = exports.DefinitionDeleteSucessAction = exports.DefinitionDeleteAction = exports.DefinitionDeleteAttemptAction = exports.DefinitionStatusChangeAction = exports.GetDuplicateDefinitionDetailsSuccessAction = exports.GetDefinitionDetailsSuccessAction = exports.OpenDefinitionDetailsSidePanelAction = exports.AfterCloseDefinitionDetailsSidePanelAction = exports.CloseDefinitionDetailsSidePanelAction = exports.ClearDuplicateDetailsAction = exports.ClearDefinitionDetailsAction = exports.GetDuplicateDefinitionDetailsAction = exports.GetDefinitionDetailsAction = exports.DefinitionRequestAction = exports.ApDefinitionNavigateToListPageAction = exports.DEFINITION_DELETE_FAIL = exports.DEFINITION_DELETE_SUCCESS = exports.DEFINITION_DELETE = exports.DEFINITION_DELETE_CANCEL = exports.DEFINITION_DELETE_ATTEMPT = exports.ApdefinitionCreateTypesEnum = exports.DEFINITION_STATUS_CHANGE = exports.AFTER_CLOSE_DEFINITION_DETAILS_SIDE_PANEL = exports.OPEN_DEFINITION_DETAILS_SIDE_PANEL = exports.CLOSE_DEFINITION_DETAILS_SIDE_PANEL = exports.GET_DUPLICATE_DEFINITION_DETAILS_SUCCESS = exports.GET_DEFINITION_DETAILS_SUCCESS = exports.CLEAR_DUPLICATE_DATA = exports.CLEAR_DEFINITION_DETAILS = exports.GET_DUPLICATE_DEFINITION_DETAILS = exports.GET_DEFINITION_DETAILS = exports.DEFINITION_REQUEST = void 0;
exports.DEFINITION_REQUEST = '[Services / Attachment Protection / Definitions] Request Get Definitions';
exports.GET_DEFINITION_DETAILS = '[Services / Attachment Protection / Definitions] Request Get Definition Details';
exports.GET_DUPLICATE_DEFINITION_DETAILS = '[Services / Attachment Protection / Definitions] Request Get Duplicate Definition Details';
exports.CLEAR_DEFINITION_DETAILS = '[Services / Attachment Protection / Definitions] Clear Definition Details State';
exports.CLEAR_DUPLICATE_DATA = '[Services / Attachment Protection / Definitions] Clear Duplicate Details State';
exports.GET_DEFINITION_DETAILS_SUCCESS = '[Services / Attachment Protection / Definitions] Request Get Definition Details - SUCCESS';
exports.GET_DUPLICATE_DEFINITION_DETAILS_SUCCESS = '[Services / Attachment Protection / Definitions] Request Get Duplicate Definition Details - SUCCESS';
exports.CLOSE_DEFINITION_DETAILS_SIDE_PANEL = '[Services / Attachment Protection / Definitions] Close Definition Details Side Panel';
exports.OPEN_DEFINITION_DETAILS_SIDE_PANEL = '[Services / Attachment Protection / Definitions] Open Definition Details Side Panel';
exports.AFTER_CLOSE_DEFINITION_DETAILS_SIDE_PANEL = '[Services / Attachment Protection / Definitions] After Close Definition Details Side Panel';
exports.DEFINITION_STATUS_CHANGE = '[Services / Attachment Protection / Definitions] Definition Status change in the Definition Details Side Panel';
var ApdefinitionCreateTypesEnum;
(function (ApdefinitionCreateTypesEnum) {
    ApdefinitionCreateTypesEnum["NAVIGATE_TO_ATTACHMENT_PROTECTION_LIST_PAGE"] = "[Ap Definition] Navigate to List Page";
})(ApdefinitionCreateTypesEnum = exports.ApdefinitionCreateTypesEnum || (exports.ApdefinitionCreateTypesEnum = {}));
exports.DEFINITION_DELETE_ATTEMPT = '[Services / Attachment Protection / Definition] Delete Definition Attempt';
exports.DEFINITION_DELETE_CANCEL = '[Services / Attachment Protection / Definition] Delete Definition Canceled';
exports.DEFINITION_DELETE = '[Services / Attachment Protection / Definition] Delete Definition';
exports.DEFINITION_DELETE_SUCCESS = '[Services / Attachment Protection / Definition] Delete Definition Success';
exports.DEFINITION_DELETE_FAIL = '[Services / Attachment Protection / Definition] Delete Definition Fail';
class ApDefinitionNavigateToListPageAction {
    constructor() {
        this.type = ApdefinitionCreateTypesEnum.NAVIGATE_TO_ATTACHMENT_PROTECTION_LIST_PAGE;
    }
}
exports.ApDefinitionNavigateToListPageAction = ApDefinitionNavigateToListPageAction;
class DefinitionRequestAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.DEFINITION_REQUEST;
    }
}
exports.DefinitionRequestAction = DefinitionRequestAction;
class GetDefinitionDetailsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_DEFINITION_DETAILS;
    }
}
exports.GetDefinitionDetailsAction = GetDefinitionDetailsAction;
class GetDuplicateDefinitionDetailsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_DUPLICATE_DEFINITION_DETAILS;
    }
}
exports.GetDuplicateDefinitionDetailsAction = GetDuplicateDefinitionDetailsAction;
class ClearDefinitionDetailsAction {
    constructor() {
        this.type = exports.CLEAR_DEFINITION_DETAILS;
    }
}
exports.ClearDefinitionDetailsAction = ClearDefinitionDetailsAction;
class ClearDuplicateDetailsAction {
    constructor() {
        this.type = exports.CLEAR_DUPLICATE_DATA;
    }
}
exports.ClearDuplicateDetailsAction = ClearDuplicateDetailsAction;
class CloseDefinitionDetailsSidePanelAction {
    constructor() {
        this.type = exports.CLOSE_DEFINITION_DETAILS_SIDE_PANEL;
    }
}
exports.CloseDefinitionDetailsSidePanelAction = CloseDefinitionDetailsSidePanelAction;
class AfterCloseDefinitionDetailsSidePanelAction {
    constructor() {
        this.type = exports.AFTER_CLOSE_DEFINITION_DETAILS_SIDE_PANEL;
    }
}
exports.AfterCloseDefinitionDetailsSidePanelAction = AfterCloseDefinitionDetailsSidePanelAction;
class OpenDefinitionDetailsSidePanelAction {
    constructor() {
        this.type = exports.OPEN_DEFINITION_DETAILS_SIDE_PANEL;
    }
}
exports.OpenDefinitionDetailsSidePanelAction = OpenDefinitionDetailsSidePanelAction;
class GetDefinitionDetailsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_DEFINITION_DETAILS_SUCCESS;
    }
}
exports.GetDefinitionDetailsSuccessAction = GetDefinitionDetailsSuccessAction;
class GetDuplicateDefinitionDetailsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_DUPLICATE_DEFINITION_DETAILS_SUCCESS;
    }
}
exports.GetDuplicateDefinitionDetailsSuccessAction = GetDuplicateDefinitionDetailsSuccessAction;
class DefinitionStatusChangeAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.DEFINITION_STATUS_CHANGE;
    }
}
exports.DefinitionStatusChangeAction = DefinitionStatusChangeAction;
class DefinitionDeleteAttemptAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.DEFINITION_DELETE_ATTEMPT;
    }
}
exports.DefinitionDeleteAttemptAction = DefinitionDeleteAttemptAction;
class DefinitionDeleteAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.DEFINITION_DELETE;
    }
}
exports.DefinitionDeleteAction = DefinitionDeleteAction;
class DefinitionDeleteSucessAction {
    constructor() {
        this.type = exports.DEFINITION_DELETE_SUCCESS;
    }
}
exports.DefinitionDeleteSucessAction = DefinitionDeleteSucessAction;
class DefinitionDeleteFailAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.DEFINITION_DELETE_FAIL;
    }
}
exports.DefinitionDeleteFailAction = DefinitionDeleteFailAction;
class DefinitionDeleteCancelAction {
    constructor() {
        this.type = exports.DEFINITION_DELETE_CANCEL;
    }
}
exports.DefinitionDeleteCancelAction = DefinitionDeleteCancelAction;
