"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MepLogSidebarContainerComponent = void 0;
const actions = require("../../actions/sidebar.actions");
const selectors_1 = require("../../selectors");
class MepLogSidebarContainerComponent {
    constructor(store) {
        this.store = store;
    }
    ngOnInit() {
        this.item$ = this.store.select(selectors_1.getMepLogItem);
    }
    closeSidebar() {
        this.store.dispatch(new actions.MepLogSidebarClose());
    }
}
exports.MepLogSidebarContainerComponent = MepLogSidebarContainerComponent;
