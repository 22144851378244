<mc-modal-header
  headerTitle='{{ title | translate }}'>
</mc-modal-header>

<mc-modal-footer>
  <button type='button' class='btn btn-text' (click)='cancel()'>
        {{ '$I18N_SWG_THREAT_EVENT_CONFIRMATION_MODAL.CANCEL' | translate }}
  </button>
  <mc-live-button [typeClass]='["btn-primary"]'
                  [content]='"$I18N_SWG_THREAT_EVENT_CONFIRMATION_MODAL.CONFIRM" | translate'
                  [isLoading]='isLoading'
                  (mcClick)='confirm()'
  ></mc-live-button>
</mc-modal-footer>
