<!-- DETAILS -->
<mc-collapsable-panel data-unit-test="details-panel"
  header="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.SUMMARY.LABELS.DETAILS_SECTION" [isExpanded]="true">
  <div class="mc-aside-panel-section">
    <div class="mc-detailed-list mc-aside-panel-sub-section mc-aside-panel-first-item">
      <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.POLICY_NAME"
        [value]="policyDetails.policy.name">
      </mc-value-field>
      <mc-value-field *ngIf="showDescription()"
        label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.POLICY_DESCRIPTION"
        value="{{ policyDetails.policy.description }}">
      </mc-value-field>
      <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.BYPASS_POLICY"
        [value]="getEnabledDisabledLabel(isBypass())">
      </mc-value-field>
      <mc-value-field *ngIf="isBypass()"
        label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.BYPASS_APPLICATION"
        [value]="sidePanelHelper.getBypassApplicationLabel(policyDetails)">
      </mc-value-field>
      <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.POLICY_OVERRIDE"
        [value]="getEnabledDisabledLabel(policyDetails.policy.override)">
      </mc-value-field>
      <mc-value-field data-unit-test="policy-enforcment" *ngIf="isAAAOrFAAUser"
        label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.POLICY_ENFORCEMENT"
        [value]="getEnabledDisabledLabel(policyDetails.policy.enforced)">
      </mc-value-field>
      <ng-container *ngIf="sidePanelHelper.isDateRange(policyDetails); else continuous">
        <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.POLICY_DURATION"
        value="{{ '$I18N_SERVICES_URL_PROTECTION_POLICIES_SIDE_PANEL.LABELS.ROW_LABELS.FROM' | translate }}:
           {{ policyDetails.policy.fromDate | mcDatePlusWeekday}}"></mc-value-field>
        <mc-value-field value="{{ '$I18N_SERVICES_URL_PROTECTION_POLICIES_SIDE_PANEL.LABELS.ROW_LABELS.TO' | translate }}:
        {{ sidePanelHelper.handleToDateTransformation(policyDetails.policy.toDate)}}"></mc-value-field>
      </ng-container>
      <ng-template #continuous>
        <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.POLICY_DURATION"
          value="{{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.DURATION_VALUES.CONTINUOUS' | translate}}">
        </mc-value-field>
      </ng-template>
    </div>
  </div>
</mc-collapsable-panel>
<hr class="mc-group-separator" />

<!-- INTBOUND -->
<mc-collapsable-panel data-unit-test="inbound-panel" *ngIf="!isBypass()"
  header="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.SUMMARY.LABELS.INBOUND_SECTION" [isExpanded]="true">
  <div class="mc-aside-panel-section">
    <div class="mc-detailed-list mc-aside-panel-first-item">
      <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.CHECK_INBOUND_EMAIL"
        [value]="getEnabledDisabledLabel(policyDetails.checkInbound)">
      </mc-value-field>
    </div>
    <div *ngIf="policyDetails.checkInbound">
      <div class="mc-detailed-list">
        <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.REWRITE_MODE.LABEL"
          [value]="sidePanelHelper.getInboundRewriteModeLabel(policyDetails)">
        </mc-value-field>
        <mc-value-field
          label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.URL_CATEGORY_SCANNING.LABEL"
          [value]="sidePanelHelper.getInboundUrlCategoryScanningLabel(policyDetails)">
        </mc-value-field>
        <mc-value-field
          label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.MALICIOUS_URL_DETECTION_ACTION.LABEL"
          [value]="sidePanelHelper.getInboundMaliciousDetectActionLabel(policyDetails)">
        </mc-value-field>
        <mc-value-field *ngIf="showInboundBrowserIsolation()"
          label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.BROWSER_ISOLATION"
          [value]="getEnabledDisabledLabel(policyDetails.browserIsolation)">
        </mc-value-field>
        <mc-value-field
          label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.MESSAGE_SUBJECT_PROTECTION.LABEL"
          [value]="sidePanelHelper.getInboundMessageSubjectProtectionLabel(policyDetails)">
        </mc-value-field>
        <mc-value-field
          label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.CREATE_MISSING_HTML_BODY"
          [value]="getEnabledDisabledLabel(policyDetails.createHtml)">
        </mc-value-field>
        <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.FORCE_SECURE_CONNECTION"
          [value]="getEnabledDisabledLabel(!policyDetails.doNotForceSecure)">
        </mc-value-field>
        <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.IGNORE_SIGNED_MESSAGES"
          [value]="getEnabledDisabledLabel(policyDetails.ignoreSignedMessages)">
        </mc-value-field>
        <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.DISPLAY_URL_DOMAIN"
          [value]="getEnabledDisabledLabel(policyDetails.displayDestinationDomain)">
        </mc-value-field>
        <mc-value-field
          label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.FILE_PROTOCOL_URL_HANDLING.LABEL"
          [value]="sidePanelHelper.getInboundFileProtocolUrlHandelingLabel(policyDetails)">
        </mc-value-field>
        <mc-value-field
          label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.STRIP_EXTERNAL_SOURCE_MODE"
          [value]="getEnabledDisabledLabel(policyDetails.stripExternalSource)">
        </mc-value-field>
      </div>
      <div class="mc-detailed-list">
        <div class="mc-aside-panel-section-header">
          {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.SECTION_TITLES.URL_AND_ATTACHMENTS' | translate }}
        </div>
        <mc-value-field
          label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.BLOCK_URL_WITH_DANGEROUS_FILE"
          [value]="getEnabledDisabledLabel(policyDetails.blockDangerousExtension)">
        </mc-value-field>
        <mc-value-field
          label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.REWRITE_URLS_IN_ATTACHMENTS"
          [value]="getEnabledDisabledLabel(inboundRewriteURLsInAttachments)">
        </mc-value-field>
        <mc-value-list *ngIf="inboundRewriteURLsInAttachments"
          listLabel="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.ATTACHMENT_PARTS_TO_REWRITE' | translate }}">
          <div *ngIf="policyDetails.attachmentRewriteHtml">
            {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.HTML' | translate }}</div>
          <div *ngIf="policyDetails.attachmentRewriteText">
            {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.TEXT' | translate }}</div>
          <div *ngIf="policyDetails.attachmentRewriteCalendar">
            {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.CALENDAR' | translate }}</div>
        </mc-value-list>
        <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.URL_FILE_DOWNLOAD.LABEL"
          [value]="sidePanelHelper.getUrlFileDownloadLabel(policyDetails)">
        </mc-value-field>
        <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.SCAN_URL_IN_ATTACHMENT"
          [value]="getEnabledDisabledLabel(policyDetails.scanLinkAttachments)">
        </mc-value-field>
      </div>
      <div class="mc-detailed-list">
        <div class="mc-aside-panel-section-header">
          {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.SECTION_TITLES.ADV_SIMILARITY_CHECKS' | translate }}
        </div>
        <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.CHECK_FOR_SIMILAR_URLS"
          [value]="getEnabledDisabledLabel(inboundCheckForSimilarURLs)">
        </mc-value-field>
        <ng-container *ngIf="inboundCheckForSimilarURLs">
          <mc-value-list
            listLabel="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.DOMAIN_TO_CHECK_AGAIN' | translate }}">
            <div *ngIf="policyDetails.checkAgainstInternalDomains">
              {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.YOUR_INTERNAL_DOMAINS' | translate }}
            </div>
            <div *ngIf="policyDetails.checkAgainstMimecastExternalDomains">
              {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.MIMECAST_MONITORED_EXTERNAL_DOMAINS' | translate }}
            </div>
            <div *ngIf="policyDetails.checkAgainstCustomExternalDomains">
              {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.CUSTOM_MONITORED_EXTERNAL_DOMAINS' | translate }}
            </div>
          </mc-value-list>
          <mc-value-field
            label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.SIMILAR_URL_DETECTION.LABEL"
            [value]="sidePanelHelper.getSimilarUrlDetection(policyDetails)">
          </mc-value-field>
        </ng-container>
      </div>
      <div class="mc-detailed-list" *ngIf="!isAAAOrFAAUser" data-unit-test="inbound-notifications">
        <div class="mc-aside-panel-section-header">
          {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.SECTION_TITLES.NOTIFICATIONS' | translate }}
        </div>
        <mc-value-field
          *ngIf="policyDetails.notifyGroupDescription"
          label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.ADMINISTRATORS"
          [value]="policyDetails.notifyGroupDescription">
        </mc-value-field>
        <mc-value-field
          label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.NOTIFICATION_URL_FORMAT.LABEL"
          [value]="sidePanelHelper.getNotificationUrlFormatLabel(policyDetails)">
        </mc-value-field>
      </div>
    </div>
  </div>
</mc-collapsable-panel>
<hr *ngIf="!isBypass()" class="mc-group-separator" />

<ng-container *ngIf="(hasIEPPackage || isAAAOrFAAUser) && !isBypass()">
  <!-- OUTBOUND -->
  <mc-collapsable-panel data-unit-test="outbound-panel" [isExpanded]="true"
    header="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.SUMMARY.LABELS.OUTBOUND_SECTION">
    <div class="mc-aside-panel-section">
      <div class="mc-detailed-list mc-aside-panel-first-item">
        <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.CHECK_OUTBOUND_EMAIL"
          [value]="getEnabledDisabledLabel(policyDetails.checkOutbound)">
        </mc-value-field>
      </div>
      <div *ngIf="policyDetails.checkOutbound">
        <div class="mc-detailed-list">
          <mc-value-field
            label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.URL_SCANNING_MODE.LABEL"
            [value]="sidePanelHelper.getOutboundUrlScanningModeLabel(policyDetails)">
          </mc-value-field>
          <mc-value-field
            label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.URL_CATEGORY_SCANNING_MODE.LABEL"
            [value]="sidePanelHelper.getUrlCategoryScanningModeLabel(policyDetails)">
          </mc-value-field>
          <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.GATEWAY_ACTION.LABEL"
            [value]="sidePanelHelper.getGatewayActionLabel(policyDetails)">
          </mc-value-field>
          <mc-value-field
            label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.GATEWAY_FALLBACK_ACTION.LABEL"
            [value]="sidePanelHelper.getGatewayFallbackActionLabel(policyDetails)">
          </mc-value-field>
          <mc-value-field
            label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.SENDER_MAILBOX_ACTION.LABEL"
            [value]="sidePanelHelper.getSenderMailboxActionLabel(policyDetails)">
          </mc-value-field>
          <mc-value-field
            label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.SENDER_MAILBOX_FALLBACK_ACTION.LABEL"
            [value]="sidePanelHelper.getSenderMailboxFallbackActionLabel(policyDetails)">
          </mc-value-field>
        </div>
        <div class="mc-detailed-list">
          <div class="mc-aside-panel-section-header">
            {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.SECTION_TITLES.URL_AND_ATTACHMENTS' | translate }}
          </div>
          <mc-value-field
            label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.BLOCK_URLS_WITH_DANGEROUS_FILE_EXTENSIONS"
            [value]="getEnabledDisabledLabel(policyDetails.outboundBlockDangerousExtension)">
          </mc-value-field>
          <mc-value-field
            label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.URL_FILE_DOWNLOAD.LABEL"
            [value]="sidePanelHelper.getOutboundUrlFileDownloadLabel(policyDetails.outboundFileSandboxed)">
          </mc-value-field>
          <mc-value-field
            label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.SCAN_URLS_IN_ATTACHMENTS"
            [value]="getEnabledDisabledLabel(outboundScanURLsInAttachments)">
          </mc-value-field>
          <mc-value-list *ngIf="outboundScanURLsInAttachments"
            listLabel="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.ATTACHMENT_PARTS' | translate }}">
            <div *ngIf="policyDetails.outboundAttachmentRewriteHtml">
              {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.HTML' | translate }}</div>
            <div *ngIf="policyDetails.outboundAttachmentRewriteText">
              {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.TEXT' | translate }}</div>
            <div *ngIf="policyDetails.outboundAttachmentRewriteCalendar">
              {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.CALENDAR' | translate }}</div>
            <div *ngIf="policyDetails.outboundScanLinkAttachments ">
              {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.OTHER' | translate }}</div>
          </mc-value-list>
        </div>
        <div class="mc-detailed-list">
          <div class="mc-aside-panel-section-header">
            {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.SECTION_TITLES.ADV_SIMILARITY_CHECKS' | translate }}
          </div>
          <mc-value-field
            label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.CHECK_FOR_SIMILAR_URLS"
            [value]="getEnabledDisabledLabel(outboundCheckForSimilarURLs)">
          </mc-value-field>
          <mc-value-list *ngIf="outboundCheckForSimilarURLs"
            listLabel="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.DOMAINS_TO_CHECK_AGAINST' | translate }}">
            <div *ngIf="policyDetails.outboundCheckAgainstInternalDomains">
              {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.YOUR_INTERNAL_DOMAINS' | translate }}
            </div>
            <div *ngIf="policyDetails.outboundCheckAgainstMimecastExternalDomains">
              {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.MIMECAST_MONITORED_EXTERNAL_DOMAINS' | translate }}
            </div>
            <div *ngIf="policyDetails.outboundCheckAgainstCustomExternalDomains">
              {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.CUSTOM_MONITORED_EXTERNAL_DOMAINS' | translate }}
            </div>
          </mc-value-list>
        </div>
        <div class="mc-detailed-list">
          <div class="mc-aside-panel-section-header">
            {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.SECTION_TITLES.NOTIFICATIONS' | translate }}
          </div>
          <mc-value-field
            data-unit-test="outbound-administrators"
            *ngIf="policyDetails.outboundNotifyGroupDescription && !isAAAOrFAAUser"
            label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.ADMINISTRATORS"
            [value]="policyDetails.outboundNotifyGroupDescription">
          </mc-value-field>
          <mc-value-list
            listLabel="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.USERS' | translate }}">
            <div *ngIf="policyDetails.outboundInternalSender">
              {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.INTERNAL_SENDERS' | translate }}
            </div>
          </mc-value-list>
        </div>
      </div>
    </div>
  </mc-collapsable-panel>
  <hr class="mc-group-separator" />

  <!-- JOURNAL -->
  <mc-collapsable-panel data-unit-test="journal-panel" [isExpanded]="true"
    header="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.SUMMARY.LABELS.JOURNAL_SECTION">
    <div class="mc-aside-panel-section">
      <div class="mc-detailed-list mc-aside-panel-first-item">
        <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.CHECK_INTERNAL_EMAIL"
          [value]="getEnabledDisabledLabel(policyDetails.checkJournal)">
        </mc-value-field>
      </div>
      <div *ngIf="policyDetails.checkJournal">
        <div class="mc-detailed-list">
          <mc-value-field
            label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.URL_SCANNING_MODE.LABEL"
            [value]="sidePanelHelper.getJournalUrlScanningModeLabel(policyDetails)">
          </mc-value-field>
          <mc-value-field
            label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.URL_CATEGORY_SCANNING_MODE.LABEL"
            [value]="sidePanelHelper.getJournalUrlCategoryScanningModeLabel(policyDetails)">
          </mc-value-field>
          <mc-value-field
            label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.SENDER_MAILBOX_ACTION.LABEL"
            [value]="sidePanelHelper.getJournalSenderMailboxActionLabel(policyDetails)">
          </mc-value-field>
          <mc-value-field
            label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.SENDER_MAILBOX_FALLBACK_ACTION.LABEL"
            [value]="sidePanelHelper.getJournalSenderMailboxFallbackActionLabel(policyDetails)">
          </mc-value-field>
        </div>
        <div class="mc-detailed-list">
          <div class="mc-aside-panel-section-header">
            {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.SECTION_TITLES.URL_AND_ATTACHMENTS' | translate }}
          </div>
          <mc-value-field
            label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.BLOCK_URLS_WITH_DANGEROUS_FILE_EXTENSIONS"
            [value]="getEnabledDisabledLabel(policyDetails.journalBlockDangerousExtension)">
          </mc-value-field>
          <mc-value-field
            label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.URL_FILE_DOWNLOAD.LABEL"
            [value]="sidePanelHelper.getJournalUrlFileDownloadLabel(policyDetails.journalFileSandboxed)">
        </mc-value-field>
          <mc-value-field
            label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.SCAN_URLS_IN_ATTACHMENTS"
            [value]="getEnabledDisabledLabel(journalScanURLsInAttachments)">
          </mc-value-field>
          <mc-value-list *ngIf="journalScanURLsInAttachments"
            listLabel="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.ATTACHMENT_PARTS' | translate }}">
            <div *ngIf="policyDetails.journalAttachmentRewriteHtml">
              {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.HTML' | translate }}</div>
            <div *ngIf="policyDetails.journalAttachmentRewriteText">
              {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.TEXT' | translate }}</div>
            <div *ngIf="policyDetails.journalAttachmentRewriteCalendar">
              {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.CALENDAR' | translate }}</div>
            <div *ngIf="policyDetails.journalScanLinkAttachments">
              {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.OTHER' | translate }}</div>
          </mc-value-list>
        </div>
        <div class="mc-detailed-list">
          <div class="mc-aside-panel-section-header">
            {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.SECTION_TITLES.ADV_SIMILARITY_CHECKS' | translate }}
          </div>
          <mc-value-field
            label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.CHECK_FOR_SIMILAR_URLS"
            [value]="getEnabledDisabledLabel(journalCheckForSimilarURLs)">
          </mc-value-field>
          <mc-value-list *ngIf="journalCheckForSimilarURLs"
            listLabel="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.DOMAINS_TO_CHECK_AGAINST' | translate }}">
            <div *ngIf="policyDetails.journalCheckAgainstInternalDomains">
              {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.YOUR_INTERNAL_DOMAINS' | translate }}
            </div>
            <div *ngIf="policyDetails.journalCheckAgainstMimecastExternalDomains">
              {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.MIMECAST_MONITORED_EXTERNAL_DOMAINS' | translate }}
            </div>
            <div *ngIf="policyDetails.journalCheckAgainstCustomExternalDomains">
              {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.CUSTOM_MONITORED_EXTERNAL_DOMAINS' | translate }}
            </div>
          </mc-value-list>
        </div>
        <div class="mc-detailed-list">
          <div class="mc-aside-panel-section-header">
            {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.SECTION_TITLES.NOTIFICATIONS' | translate }}
          </div>
          <mc-value-field
            data-unit-test="journal-administrators"
            *ngIf="policyDetails.journalNotifyGroupDescription && !isAAAOrFAAUser"
            label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.ADMINISTRATORS"
            [value]="policyDetails.journalNotifyGroupDescription">
          </mc-value-field>
          <mc-value-list
            listLabel="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.USERS' | translate }}">
            <div *ngIf="policyDetails.journalInternalSender">
              {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.INTERNAL_SENDERS' | translate }}
            </div>
            <div *ngIf="policyDetails.journalInternalRecipient">
              {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.INTERNAL_RECIPIENTS' | translate }}
            </div>
          </mc-value-list>
        </div>
      </div>
    </div>
  </mc-collapsable-panel>
  <hr class="mc-group-separator" />
</ng-container>

<!-- USER AWARENESS -->
<mc-collapsable-panel data-unit-test="user-awareness-panel" *ngIf="!isBypass()"
  header="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.SUMMARY.LABELS.USER_AWARENESS_SECTION" [isExpanded]="true">
  <div class="mc-detailed-list mc-summary-step-first-step-item">
    <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.USER_AWARENESS_SETTINGS.LABELS.SHOW"
      [value]="getEnabledDisabledLabel(policyDetails.userAwareness)"></mc-value-field>
  </div>
  <div class="mc-detailed-list" *ngIf="policyDetails.userAwareness">
    <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.USER_AWARENESS_SETTINGS.LABELS.PERCENTAGE"
      [value]="policyDetails.challengePercentage"></mc-value-field>
    <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.USER_AWARENESS_SETTINGS.LABELS.DYNAMIC_ADJUSTMENT"
      [value]="getEnabledDisabledLabel(policyDetails.dynamicAdjustment)"></mc-value-field>
    <mc-value-field data-unit-test="awareness-custom-page-set" 
      *ngIf="!isAAAOrFAAUser" label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.USER_AWARENESS_SETTINGS.LABELS.CUSTOM_PAGE_SET"
      [value]="getEnabledDisabledLabel(!!policyDetails.userAwarenessPageSetId)"></mc-value-field>
    <mc-value-field *ngIf="!!policyDetails.userAwarenessPageSetDescription"
      label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.USER_AWARENESS_SETTINGS.LABELS.PAGE_SET"
      [value]="policyDetails.userAwarenessPageSetDescription">
    </mc-value-field>
  </div>
</mc-collapsable-panel>
<hr *ngIf="!isBypass()" class="mc-group-separator" />

<!-- APPLIES TO -->
<mc-collapsable-panel data-unit-test="applies-to-panel"
  header="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.SUMMARY.LABELS.APPLIES_TO_SECTION" [isExpanded]="true">
  <div class="mc-aside-panel-section">
    <div class="mc-detailed-list" *ngFor="let rule of policyDetails.rules; index as i;">
      <div class="mc-aside-panel-section-header">
        {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.SECTION_TITLE' | translate }}
        <span *ngIf="policyDetails.rules.length > 1">{{ i+1 }}</span>
      </div>
      <mc-value-field
        label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.ADDRESSES_BASED_ON.LABEL"
        [value]="sidePanelHelper.getAddressesBasedOnLabel(rule)">
      </mc-value-field>
      <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.APPLIES.LABEL_FROM"
        [value]="sidePanelHelper.getAppliesFromToLabel(rule.from, 'type')">
      </mc-value-field>
      <ng-container *ngIf="helper.showAppliesToAddresAttributeDetails(rule.from.type)">
        <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.IS_EQUAL_TO"
          [value]="rule.from.attribute.value">
        </mc-value-field>
      </ng-container>
      <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.APPLIES.LABEL_TO"
        [value]="sidePanelHelper.getAppliesFromToLabel(rule.to, 'type')">
      </mc-value-field>
      <ng-container *ngIf="helper.showAppliesToAddresAttributeDetails(rule.to.type)">
        <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.IS_EQUAL_TO"
          [value]="rule.to.attribute.value">
        </mc-value-field>
      </ng-container>
      <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.BIDIRECTIONAL"
        [value]="getEnabledDisabledLabel(rule.bidirectional)">
      </mc-value-field>
      <mc-value-list *ngIf="rule.conditions"
        listLabel="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.IP_RANGE_TITLE' | translate }}"
        [listItems]="rule.conditions.sourceIPs"
        [showMoreLessTriggerValue] = "showMoreLessTriggerValue">
      </mc-value-list>
    </div>
  </div>
</mc-collapsable-panel>
