"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyncReportModalComponent = void 0;
const core_1 = require("@angular/core");
const actions = require("../../actions");
const moment = require("moment");
class SyncReportModalComponent {
    constructor(overlayRef, translateService, domSanitizer, data, store) {
        this.overlayRef = overlayRef;
        this.translateService = translateService;
        this.domSanitizer = domSanitizer;
        this.data = data;
        this.store = store;
        this.dateFormat = 'D MMMM YYYY - HH:mm';
    }
    report() {
        const summary = this.data.reportSummary ? this.data.reportSummary.replace(/\n/g, '<br/>') : '-';
        return this.domSanitizer.sanitize(core_1.SecurityContext.HTML, summary) || '-';
    }
    getHeader() {
        const reportDate = this.data.reportTime
            ? moment(this.data.reportTime).format(this.dateFormat)
            : '-';
        return this.translateService.instant('$I18N_DIRECTORY_SYNC_SYNC_REPORT_MODAL.HEADER', {
            date: reportDate
        });
    }
    download() {
        this.overlayRef.close();
        this.store.dispatch(new actions.SynchronizationFullReportAction(this.data.id));
    }
    close() {
        this.overlayRef.close();
    }
}
exports.SyncReportModalComponent = SyncReportModalComponent;
