<div class="template-preview" [ngSwitch]="preview">

  <mc-user-challenge-page *ngSwitchCase="'userTemplate'" [page]="template.userTemplate"></mc-user-challenge-page>
  <mc-safe-link-identified-page *ngSwitchCase="'safeLinkIdentified'" [page]="template.safeLinkIdentified"></mc-safe-link-identified-page>
  <mc-safe-link-misidentified-page *ngSwitchCase="'safeLinkMisidentified'" [page]="template.safeLinkMisidentified"></mc-safe-link-misidentified-page>
  <mc-harmful-link-identified-page *ngSwitchCase="'harmfulLinkIndentified'" [page]="template.harmfulLinkIndentified"></mc-harmful-link-identified-page>
  <mc-harmful-link-misidentified-page *ngSwitchCase="'harmfulLinkMisindentifiedWarning'" [page]="template.harmfulLinkMisindentifiedWarning"></mc-harmful-link-misidentified-page>
  <mc-harmful-link-misidentified-block-page *ngSwitchCase="'harmfulLinkMisindentifiedBlock'"
    [page]="template.harmfulLinkMisindentifiedBlock"></mc-harmful-link-misidentified-block-page>

  <div class="preview-wrapper" *ngSwitchCase="'mimecastSafetyTips'">
    <mc-template-safety-tips-preview title="Default Safety Tips" [safetyTips]="mimecastSafetyTips"></mc-template-safety-tips-preview>
  </div>

  <div class="preview-wrapper" *ngSwitchCase="'mimecastSafetyTipsAndCustomSafetyTips'">
    <mc-template-safety-tips-preview title="Custom Safety Tips" [safetyTips]="customSafetyTips"></mc-template-safety-tips-preview>
    <mc-template-safety-tips-preview title="Default Safety Tips" [safetyTips]="mimecastSafetyTips"></mc-template-safety-tips-preview>
  </div>

  <div class="preview-wrapper" *ngSwitchCase="'customSafetyTips'">
    <mc-template-safety-tips-preview title="Custom Safety Tips" [safetyTips]="customSafetyTips"></mc-template-safety-tips-preview>
  </div>

  <div class="preview-wrapper" *ngSwitchDefault>
    <mc-template-default-preview></mc-template-default-preview>
  </div>

</div>
