<mc-layout-list-extra-container
  keyTitle="$I18N_SECURE_MESSAGING_PAGE_CONTAINER.HEADER.TITLE"
  keyDescription="$I18N_SECURE_MESSAGING_PAGE_CONTAINER.HEADER.SUB_TITLE"
>
  <mc-body-container>
    <mc-messaging-search-box (onSearch)="search($event)"></mc-messaging-search-box>
    <mc-logs-table [hidden]="!hasSearched"></mc-logs-table>

  </mc-body-container>

</mc-layout-list-extra-container>
