<div class="mc-supervision-wizard-step-container">
  <mc-wizard-step-header label="$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.CONTENT_STEP.PRIMARY_DESCRIPTION">
  </mc-wizard-step-header>

  <form class="form-horizontal" mcDefaultLabelClass="col-sm-3" mcDefaultControlContainerClass="col-sm-9"
    [formGroup]="form">

    <div>
      <mc-field label="{{'$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.CONTENT_STEP.FIELD_TYPE.LABEL' | translate }}">
        <div>
          <div *ngIf="!isEditMode">
            <div class="radio panel-half-padding-bottom" data-test="advanced-search">
              <label>
                <input type="radio" name="searchType" [value]="lexiconSearchType.ADVANCED" formControlName="searchType"
                  (click)="disableBasicFields()" />
                {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.CONTENT_STEP.FIELD_TYPE.ADVANCED.LABEL' | translate }}
                <div>
                  {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.CONTENT_STEP.FIELD_TYPE.ADVANCED.DESCRIPTION' | translate }}
                </div>
              </label>
            </div>
            <div class="radio panel-half-padding-bottom" data-test="basic-search" (click)="disableAdvancedFields()">
              <label class="text-bold">
                <input type="radio" name="searchType" [value]="lexiconSearchType.BASIC" formControlName="searchType" />
                {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.CONTENT_STEP.FIELD_TYPE.BASIC.LABEL' | translate }}
                <div>
                  {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.CONTENT_STEP.FIELD_TYPE.BASIC.DESCRIPTION' | translate }}
                </div>
              </label>
            </div>
          </div>

          <div class="panel-half-padding-bottom" *ngIf="isEditMode">
            <input type="hidden" name="searchType" formControlName="searchType"/>
            <label class="mc-lexicon-update-info">
              {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON_SEARCH.' + form.value.searchType | uppercase | translate }}
            </label>
            <p class="mc-lexicon-info-text">
              <i class="fas fa-info-circle"></i>
              <span>
                {{
                '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON_SEARCH.UPDATE_INFO' | translate
              }}
              </span>
            </p>
          </div>
        </div>
      </mc-field>

      <mc-text-area-field *ngIf="isAdvancedSearchType() || isEditingAdvancedSearch()"
        label="{{'$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.CONTENT_STEP.FIELD_QUERY' | translate}}"
        placeholder="{{'$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.CONTENT_STEP.FIELD_QUERY' | translate}}"
        formControlName="query" errorPrefix="$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.VALIDATION.QUERY"
        [helpPopoverContent]="searchTypePopover"
        popoverPlacement="right">
      </mc-text-area-field>

      <ng-container *ngIf="isBasicSearchType() || isEditingBasicSearch()">
        <mc-text-area-field label="{{'$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.CONTENT_STEP.FIELD_WORDS' | translate}}"
          placeholder="{{'$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.CONTENT_STEP.FIELD_WORDS' | translate}}"
          formControlName="words" errorPrefix="$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.VALIDATION.WORDS">
        </mc-text-area-field>

        <mc-text-area-field
          label="{{'$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.CONTENT_STEP.FIELD_PHRASES' | translate}}"
          placeholder="{{'$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.CONTENT_STEP.FIELD_PHRASES' | translate}}"
          formControlName="phrases" errorPrefix="$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.VALIDATION.PHRASES">
        </mc-text-area-field>
      </ng-container>
    </div>

  </form>
  <mc-errors *ngIf="!form.valid" [errors]="form.errors"
    formPrefix="$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.VALIDATION.FORM">
  </mc-errors>
</div>

<ng-template #searchTypePopover>
  <div [innerHTML]="'$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.CONTENT_STEP.FIELD_TYPE.POPOVER' | translate"></div>
</ng-template>
