"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RemediationType = exports.ExportsEndPoints = void 0;
var ExportsEndPoints;
(function (ExportsEndPoints) {
    ExportsEndPoints["logs"] = "export-logs";
    ExportsEndPoints["incidents"] = "export-incidents";
    ExportsEndPoints["search"] = "export-search";
    ExportsEndPoints["incidentEmails"] = "export-incident-emails";
})(ExportsEndPoints = exports.ExportsEndPoints || (exports.ExportsEndPoints = {}));
var RemediationType;
(function (RemediationType) {
    RemediationType[RemediationType["IEP"] = 0] = "IEP";
    RemediationType[RemediationType["MEP"] = 1] = "MEP";
})(RemediationType = exports.RemediationType || (exports.RemediationType = {}));
