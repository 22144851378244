"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnabledDisabledLabel = exports.SHOW_MORE_LESS_TRIGGER_VALUE = exports.APPLIES_TO_WITHOUT_IEP_STEP_NUMBER = exports.USER_AWARENESS_WITHOUT_IEP_STEP_NUMBER = exports.APPLIES_TO_WITH_BYPASS_STEP_NUMBER = exports.APPLIES_TO_STEP_NUMBER = exports.USER_AWARENESS_STEP_NUMBER = exports.JOURNAL_STEP_NUMBER = exports.OUTBOUND_STEP_NUMBER = exports.INBOUND_STEP_NUMBER = exports.DETAILS_STEP_NUMBER = void 0;
exports.DETAILS_STEP_NUMBER = 1;
exports.INBOUND_STEP_NUMBER = 2;
exports.OUTBOUND_STEP_NUMBER = 3;
exports.JOURNAL_STEP_NUMBER = 4;
exports.USER_AWARENESS_STEP_NUMBER = 5;
exports.APPLIES_TO_STEP_NUMBER = 6;
exports.APPLIES_TO_WITH_BYPASS_STEP_NUMBER = 2;
exports.USER_AWARENESS_WITHOUT_IEP_STEP_NUMBER = 3;
exports.APPLIES_TO_WITHOUT_IEP_STEP_NUMBER = 4;
exports.SHOW_MORE_LESS_TRIGGER_VALUE = 5;
var EnabledDisabledLabel;
(function (EnabledDisabledLabel) {
    EnabledDisabledLabel["ENABLED"] = "$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.SUMMARY.LABELS.ENABLED";
    EnabledDisabledLabel["DISABLED"] = "$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.SUMMARY.LABELS.DISABLED";
})(EnabledDisabledLabel = exports.EnabledDisabledLabel || (exports.EnabledDisabledLabel = {}));
