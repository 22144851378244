"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListComponent = void 0;
const core_1 = require("@angular/core");
const table_store_1 = require("@mimecast-ui/table-store");
class ListComponent extends table_store_1.TableBaseComponent {
    constructor(tableStoreStore) {
        super(tableStoreStore, 'monitoredExternalDomain');
        this.columnList = ['left-column', 'domainName', 'comment', 'row-actions'];
        this.externalTableSearchCount = 0;
        this.search = new core_1.EventEmitter();
        this.selectAll = new core_1.EventEmitter();
        this.unSelectAll = new core_1.EventEmitter();
        this.selectSingleItem = new core_1.EventEmitter();
        this.deleteItems = new core_1.EventEmitter();
        this.addDomains = new core_1.EventEmitter();
        this.rowClick = new core_1.EventEmitter();
        this.deleteItem = new core_1.EventEmitter();
        this.setupSubscriptions();
    }
    setupSubscriptions() {
        this.tableDataSubscription = this.tableData$.subscribe((data) => {
            this.tableData = data;
        });
    }
    onSearchDomains(data) {
        this.dispatchFilterAction({ domainName: data.searchQuery });
    }
    onSelectingSingleItem(data) {
        data.event.stopPropagation();
        this.selectSingleItem.emit({
            // @ts-ignore
            isSelection: data.event.target.checked,
            selectedItem: data.domain
        });
    }
    onSelectAll(event) {
        // @ts-ignore
        if (event.target.checked) {
            this.selectAll.emit(this.tableData);
        }
        else {
            this.unSelectAll.emit();
        }
    }
    ngOnDestroy() {
        this.tableDataSubscription.unsubscribe();
        super.ngOnDestroy();
    }
    checkboxBodyCellClick(event) {
        event.stopPropagation();
    }
    onPaginationChange(pagination) {
        this.unSelectAll.emit();
        super.onPaginationChange(pagination);
    }
}
exports.ListComponent = ListComponent;
