"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const moment = require("moment");
const _ = require("lodash");
require("./audit-logs-service"); //audit-logs.service
require("./slides/audit-log-view.service"); //audit-log-view.service
/**
 * Created by pdesai on 13/02/2017.
 */
(function () {
    'use strict';
    angular.module('auditLogs.administration.controller', ['audit-logs.service', 'audit-log-view.service'])
        .controller('AuditLogsAdministrationController', [
        '$scope',
        '$filter',
        '$uibModal',
        '$translate',
        '$stateParams',
        'auditLogsService',
        'dateRangeModalService',
        'adconCommonsService',
        'ngProgressFactory',
        'exportLogsAsideService',
        'auditLogViewService',
        'auditService',
        function ($scope, $filter, $uibModal, $translate, $stateParams, auditLogsService, dateRangeModalService, adconCommonsService, ngProgressFactory, exportLogsAsideService, auditLogViewService, auditService) {
            const ngProgress = ngProgressFactory.createInstance();
            ngProgress.start();
            const self = this;
            let currentSearchColumn;
            let currentSearchText = '';
            let currentPage = 0;
            let currentSelectedLogIndex = null;
            let currentSelectedPageSize = null;
            let currentFilters;
            let currentSelectedCategories = null;
            let selectedFilterOptions = [];
            let lastAppliedFilterOptions = selectedFilterOptions;
            const SEARCHES = {
                ALL: {
                    id: 'ALL',
                    key: 'all'
                },
                USER: {
                    id: 'USER',
                    key: 'user'
                },
                TYPE: {
                    id: 'TYPE',
                    key: 'type'
                },
                DETAILS: {
                    id: 'DETAILS',
                    key: 'details'
                }
            };
            const initialFiltersNotActive = ($stateParams.filters) ? $stateParams.filters : [auditService.constants.CATEGORIES.authentication_logs.code, auditService.constants.CATEGORIES.case_review_logs.code];
            self.auditService = auditService;
            self.noAuditLogsText = '';
            self.adconCommonsService = adconCommonsService;
            self.dropdownItemTypes = angular.copy(adconCommonsService.CONSTANTS.DROPDOWN_ITEM_TYPES);
            self.allFiltersActive = false;
            self.initialLoading = true;
            self.paginationNextDisabled = true;
            self.paginationPreviousDisabled = true;
            self.columns = auditLogsService.getColumns();
            self.columnsForExport = auditLogsService.getColumnsForExport();
            self.maxEndDate = moment();
            self.searchDropdownOptions = getSearchDropdownOptions();
            self.pageSizeDropdownOptions = {
                I0: {
                    type: self.dropdownItemTypes.LABEL,
                    value: $translate.instant('$I18N_ADMINISTRATION_AUDIT_LOGS_RESULTS_PER_PAGE')
                }
            };
            _.extend(self.pageSizeDropdownOptions, adconCommonsService.getDropdownOptions().pageSizeOptions);
            self.CONSTANTS = {
                DATE_RANGES_KEYS: {
                    TODAY: 'TODAY',
                    ALL_TIME: 'ALL_TIME',
                    ONE_WEEK: 'ONE_WEEK',
                    ONE_MONTH: 'ONE_MONTH',
                    THREE_MONTHS: 'THREE_MONTHS',
                    SIX_MONTHS: 'SIX_MONTHS',
                    ONE_YEAR: 'ONE_YEAR',
                    TWO_YEARS: 'TWO_YEARS',
                    CUSTOM: 'CUSTOM'
                }
            };
            self.dateRangePickerLabel = $stateParams.periodDateRanges ? adconCommonsService.formatMcUtcDate($stateParams.periodDateRanges.from, adconCommonsService.CONSTANTS.UI_DATE_FORMAT) + ' / ' + adconCommonsService.formatMcUtcDate($stateParams.periodDateRanges.to, adconCommonsService.CONSTANTS.UI_DATE_FORMAT) : '$I18N_ADMINISTRATION_AUDIT_LOGS_1_WEEK';
            getDateRanges();
            getCategories();
            $scope.$watch(function () {
                return (self.payloadDates);
            }, function (oldValue, newValue) {
                if (!angular.equals(newValue, oldValue)) {
                    getAuditLogs();
                }
            }, true);
            self.searchClear = function () {
                currentSearchText = '';
                getAuditLogs();
            };
            self.search = function (text) {
                currentSearchText = text;
                getAuditLogs();
            };
            self.searchSelect = function (id) {
                self.searchActiveText = self.searchDropdownOptions[id].key;
                currentSearchColumn = id;
            };
            self.pageSizeSelect = function (id, getLogs) {
                if ((currentSelectedPageSize !== null) && (currentSelectedPageSize !== id)) {
                    self.pageSizeDropdownOptions[currentSelectedPageSize].active = false;
                }
                currentSelectedPageSize = id;
                self.pageSizeDropdownOptions[currentSelectedPageSize].active = true;
                self.pageSizeActiveText = self.pageSizeDropdownOptions[currentSelectedPageSize].value;
                if (getLogs) {
                    getAuditLogs();
                }
            };
            self.paginationNext = function () {
                getAuditLogs(true);
            };
            self.paginationPrevious = function () {
                getAuditLogs(false);
            };
            self.export = function () {
                const exportSearchDictionary = {};
                exportSearchDictionary[SEARCHES.ALL.id] = 'all';
                exportSearchDictionary[SEARCHES.USER.id] = 'emailaddr';
                exportSearchDictionary[SEARCHES.TYPE.id] = 'auditdesc';
                exportSearchDictionary[SEARCHES.DETAILS.id] = 'information';
                const exportUID = auditService.constants.EXPORT_UID;
                const from = moment(self.payloadDates.from).valueOf();
                const to = moment(self.payloadDates.to).valueOf();
                const columns = self.columnsForExport;
                const filters = (currentFilters.length > 0) ? auditService.constants.EXPORT_FILTERS_PREFIX + ':' + currentFilters.join(',') : null;
                const query = currentSearchText ? (currentSearchText + ':' + exportSearchDictionary[currentSearchColumn]) : null;
                exportLogsAsideService.openExportAside(exportUID, from, to, columns, filters, query);
            };
            self.selectLog = function (index) {
                if (currentSelectedLogIndex === null) {
                    currentSelectedLogIndex = index;
                    self.auditLogsToShow[currentSelectedLogIndex].selected = true;
                }
                else if (currentSelectedLogIndex !== index) {
                    self.auditLogsToShow[currentSelectedLogIndex].selected = false;
                    currentSelectedLogIndex = index;
                    self.auditLogsToShow[currentSelectedLogIndex].selected = true;
                }
                const logView = auditLogViewService.openLogView(self.auditLogsToShow[currentSelectedLogIndex]);
                logView.catch(function () {
                    self.auditLogsToShow[currentSelectedLogIndex].selected = false;
                    currentSelectedLogIndex = null;
                });
            };
            self.meatballsOpen = function (event) {
                event.stopPropagation();
            };
            self.filterOptionSelect = function (index) {
                const indexPos = selectedFilterOptions.indexOf(index);
                if (indexPos === -1) {
                    selectedFilterOptions.push(index);
                    self.filterDropdownOptions[index].active = true;
                }
                else {
                    selectedFilterOptions.splice(indexPos, 1);
                    self.filterDropdownOptions[index].active = false;
                }
            };
            self.applyFilterSelection = function (getLogs) {
                if (selectedFilterOptions.length === 0) {
                    self.filterActiveText = '$I18N_ADMINISTRATION_AUDIT_LOGS_NONE_SELECTED';
                }
                else if (selectedFilterOptions.length === self.filterDropdownOptions.length) {
                    self.filterActiveText = '$I18N_ADMINISTRATION_AUDIT_LOGS_ALL_SELECTED';
                }
                else {
                    self.filterActiveText = selectedFilterOptions.length.toString() + ' ' + $translate.instant('$I18N_ADMINISTRATION_AUDIT_LOGS_SELECTED');
                }
                currentFilters = (selectedFilterOptions.length === 0) ? null : selectedFilterOptions.map(function (currentValue) {
                    return self.filterDropdownOptions[currentValue].id;
                });
                currentSelectedCategories = (selectedFilterOptions.length === 0) ? null : selectedFilterOptions.map(function (currentValue) {
                    return self.filterDropdownOptions[currentValue].key;
                });
                if (getLogs) {
                    getAuditLogs();
                }
            };
            self.cancelFilterSelection = function () {
                if (!angular.equals(selectedFilterOptions, lastAppliedFilterOptions)) {
                    self.filterDropdownOptions.forEach(function (item, index) {
                        item.active = (lastAppliedFilterOptions.indexOf(index) !== -1);
                    });
                    selectedFilterOptions = angular.copy(lastAppliedFilterOptions);
                }
            };
            self.selectAllFilters = function () {
                self.allFiltersActive = !self.allFiltersActive;
                selectedFilterOptions.length = 0;
                self.filterDropdownOptions.forEach(function (item, index) {
                    item.active = self.allFiltersActive;
                    if (self.allFiltersActive) {
                        selectedFilterOptions.push(index);
                    }
                });
            };
            function getAuditLogs(paginationNext) {
                let getLogs = true;
                const search = _.find(SEARCHES, { 'id': currentSearchColumn });
                const payload = {
                    meta: {
                        pagination: {
                            pageSize: self.pageSizeDropdownOptions[currentSelectedPageSize].key
                        }
                    },
                    data: [{
                            searchField: search.key,
                            startDateTime: moment(self.payloadDates.from).format(adconCommonsService.CONSTANTS.API_DATE_FORMAT),
                            endDateTime: moment(self.payloadDates.to).format(adconCommonsService.CONSTANTS.API_DATE_FORMAT)
                        }]
                };
                if (currentSearchText) {
                    payload.data[0].query = currentSearchText;
                }
                if (currentSelectedCategories) {
                    payload.data[0].categories = currentSelectedCategories;
                }
                if (angular.isDefined(paginationNext)) {
                    getLogs = false;
                    if (paginationNext && (self.currentPaginationData.next)) {
                        payload.meta.pagination.pageToken = self.currentPaginationData.next;
                        getLogs = true;
                    }
                    else if (self.currentPaginationData.previous) {
                        payload.meta.pagination.pageToken = self.currentPaginationData.previous;
                        getLogs = true;
                    }
                }
                if (getLogs) {
                    self.isDataLoading = true;
                    auditService.getAuditLogs(payload).then(function (response) {
                        self.isDataLoading = false;
                        if (!response.err) {
                            if (angular.isUndefined(paginationNext)) {
                                self.initialLoading = false;
                                currentPage = 1;
                            }
                            else {
                                currentPage += paginationNext ? 1 : -1;
                            }
                            self.auditLogs = response.all;
                            self.auditLogsToShow = self.auditLogs;
                            lastAppliedFilterOptions = angular.copy(selectedFilterOptions);
                            if (self.auditLogsToShow.length === 0) {
                                if (currentSearchText) {
                                    self.noAuditLogsText = '$I18N_ADMINISTRATION_AUDIT_LOGS_NO_AUDIT_LOGS_FOUND';
                                }
                                else {
                                    self.noAuditLogsText = '$I18N_ADMINISTRATION_AUDIT_LOGS_NO_AUDIT_LOGS';
                                }
                            }
                            self.currentPaginationData = response.raw.meta.pagination;
                            self.paginationNextDisabled = !self.currentPaginationData.next;
                            self.paginationPreviousDisabled = !self.currentPaginationData.previous;
                            setPaginationItemsText();
                        }
                        else {
                            auditService.getError().showErrorNotification(response);
                        }
                    }, function () {
                        self.isDataLoading = false;
                        auditService.getError().showErrorNotification();
                    });
                }
            }
            function setPaginationItemsText() {
                let firstItem;
                let lastItem;
                if (self.auditLogsToShow.length) {
                    firstItem = 1 + ((currentPage - 1) * self.pageSizeDropdownOptions[currentSelectedPageSize].key);
                    lastItem = firstItem + self.auditLogsToShow.length - 1;
                    self.paginationLabel = $translate.instant('$I18N_ADMINISTRATION_COMMONS_PAGINATION_SEPARATOR', {
                        start: firstItem,
                        end: lastItem
                    });
                }
                else {
                    self.paginationLabel = '';
                }
            }
            function getCategories() {
                let i;
                let categories;
                let option;
                self.filterDropdownOptions = [];
                self.filterActiveText = $translate.instant('$I18N_ADMINISTRATION_AUDIT_LOGS_NONE_SELECTED');
                auditService.getCategories().then(function (response) {
                    ngProgress.complete();
                    if (!response.err) {
                        categories = response.first.categories;
                        categories = $filter('orderBy')(categories, 'name');
                        for (i = 0; i < categories.length; i += 1) {
                            if (auditService.constants.CATEGORIES[categories[i].code]) {
                                option = {
                                    type: self.dropdownItemTypes.ITEM,
                                    key: categories[i].code,
                                    name: $translate.instant(auditService.constants.CATEGORIES[categories[i].code].name),
                                    id: categories[i].id
                                };
                                self.filterDropdownOptions.push(option);
                                if (initialFiltersNotActive.indexOf(categories[i].code) !== -1) {
                                    option.active = false;
                                }
                                else {
                                    option.active = true;
                                    selectedFilterOptions.push(self.filterDropdownOptions.length - 1);
                                }
                            }
                        }
                        activate();
                    }
                    else {
                        auditService.getError().showErrorNotification(response);
                    }
                }, function () {
                    ngProgress.complete();
                    auditService.getError().showErrorNotification();
                });
            }
            function getSearchDropdownOptions() {
                const searchDropdownOptions = {};
                searchDropdownOptions['LABEL'] = {
                    type: adconCommonsService.CONSTANTS.DROPDOWN_ITEM_TYPES.LABEL,
                    value: $translate.instant('$I18N_ADMINISTRATION_AUDIT_LOGS_SEARCH_BY')
                };
                searchDropdownOptions[SEARCHES.ALL.id] = {
                    type: adconCommonsService.CONSTANTS.DROPDOWN_ITEM_TYPES.ITEM,
                    key: SEARCHES.ALL.key,
                    value: $translate.instant('$I18N_ADMINISTRATION_AUDIT_LOGS_SEARCH_ALL'),
                    active: true
                };
                searchDropdownOptions[SEARCHES.USER.id] = {
                    type: adconCommonsService.CONSTANTS.DROPDOWN_ITEM_TYPES.ITEM,
                    key: SEARCHES.USER.key,
                    value: $translate.instant('$I18N_ADMINISTRATION_AUDIT_LOGS_SEARCH_USER'),
                    active: false
                };
                searchDropdownOptions[SEARCHES.TYPE.id] = {
                    type: adconCommonsService.CONSTANTS.DROPDOWN_ITEM_TYPES.ITEM,
                    key: SEARCHES.TYPE.key,
                    value: $translate.instant('$I18N_ADMINISTRATION_AUDIT_LOGS_SEARCH_TYPE'),
                    active: false
                };
                searchDropdownOptions[SEARCHES.DETAILS.id] = {
                    type: adconCommonsService.CONSTANTS.DROPDOWN_ITEM_TYPES.ITEM,
                    key: SEARCHES.DETAILS.key,
                    value: $translate.instant('$I18N_ADMINISTRATION_AUDIT_LOGS_SEARCH_DETAILS'),
                    active: false
                };
                return searchDropdownOptions;
            }
            function getDateRanges() {
                self.dateRanges = {
                    ALL_TIME: {
                        type: self.dropdownItemTypes.ITEM,
                        label: '$I18N_ADMINISTRATION_AUDIT_LOGS_ALL_TIME',
                        dates: {
                            from: moment(new Date(0)),
                            to: moment().endOf('day')
                        }
                    },
                    ONE_WEEK: {
                        type: self.dropdownItemTypes.ITEM,
                        label: '$I18N_ADMINISTRATION_AUDIT_LOGS_1_WEEK',
                        dates: {
                            from: moment().subtract(6, 'days').startOf('day'),
                            to: moment().endOf('day')
                        }
                    },
                    ONE_MONTH: {
                        type: self.dropdownItemTypes.ITEM,
                        label: '$I18N_ADMINISTRATION_AUDIT_LOGS_1_MONTH',
                        dates: {
                            from: moment().subtract(30, 'days').startOf('day'),
                            to: moment().endOf('day')
                        }
                    },
                    THREE_MONTHS: {
                        type: self.dropdownItemTypes.ITEM,
                        label: '$I18N_ADMINISTRATION_AUDIT_LOGS_3_MONTHS',
                        dates: {
                            from: moment().subtract(90, 'days').startOf('day'),
                            to: moment().endOf('day')
                        }
                    },
                    SIX_MONTHS: {
                        type: self.dropdownItemTypes.ITEM,
                        label: '$I18N_ADMINISTRATION_AUDIT_LOGS_6_MONTHS',
                        dates: {
                            from: moment().subtract(180, 'days').startOf('day'),
                            to: moment().endOf('day')
                        }
                    },
                    ONE_YEAR: {
                        type: self.dropdownItemTypes.ITEM,
                        label: '$I18N_ADMINISTRATION_AUDIT_LOGS_1_YEAR',
                        dates: {
                            from: moment().subtract(365, 'days').startOf('day'),
                            to: moment().endOf('day')
                        }
                    },
                    TWO_YEARS: {
                        type: self.dropdownItemTypes.ITEM,
                        label: '$I18N_ADMINISTRATION_AUDIT_LOGS_2_YEARS',
                        dates: {
                            from: moment().subtract(730, 'days').startOf('day'),
                            to: moment().endOf('day')
                        }
                    },
                    CUSTOM: {
                        type: self.dropdownItemTypes.ITEM,
                        label: '$I18N_ADMINISTRATION_COMMONS_DATE_RANGE_OPTIONS_BETWEEN_DATES',
                        custom: true
                    }
                };
            }
            function activate() {
                self.pageSizeSelect(adconCommonsService.CONSTANTS.PAGE_SIZES_KEYS.I50, false);
                self.searchSelect(SEARCHES.ALL.id);
                self.applyFilterSelection(false);
                if ($stateParams.periodDateRanges) {
                    setInitialDateRange($stateParams.periodDateRanges.from, $stateParams.periodDateRanges.to);
                    self.dateRanges.CUSTOM.active = true;
                }
                else {
                    setInitialDateRange(self.dateRanges[self.CONSTANTS.DATE_RANGES_KEYS.ONE_WEEK].dates.from, self.dateRanges[self.CONSTANTS.DATE_RANGES_KEYS.ONE_WEEK].dates.to);
                    self.dateRanges.ONE_WEEK.active = true;
                }
            }
            function setInitialDateRange(from, to) {
                self.payloadDates = {
                    from,
                    to
                };
            }
            $scope.tab.onRefresh = self.refresh = function () {
                getAuditLogs();
            };
        }
    ]);
}());
