"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ5 = exports.ɵ4 = exports.ɵ3 = exports.ɵ2 = exports.ɵ1 = exports.ɵ0 = exports.getSwgBlockPageError = exports.getSwgBlockPageSaving = exports.getSwgBlockPageLoading = exports.getSwgBlockPageDetails = exports.getSwgBlockPageUpdatedSettings = exports.getSwgBlockPageSettings = exports.getSwgBlockPageSettingsState = exports.reducers = void 0;
const blockSettings = require("./settings.reducers");
const store_1 = require("@ngrx/store");
exports.reducers = {
    settings: blockSettings.reducer
};
exports.getSwgBlockPageSettingsState = store_1.createFeatureSelector('swgBlockPageSettings');
const ɵ0 = state => state.settings;
exports.ɵ0 = ɵ0;
exports.getSwgBlockPageSettings = store_1.createSelector(exports.getSwgBlockPageSettingsState, ɵ0);
const ɵ1 = state => state.updatedSettings;
exports.ɵ1 = ɵ1;
exports.getSwgBlockPageUpdatedSettings = store_1.createSelector(exports.getSwgBlockPageSettings, ɵ1);
const ɵ2 = state => state.settings;
exports.ɵ2 = ɵ2;
exports.getSwgBlockPageDetails = store_1.createSelector(exports.getSwgBlockPageSettings, ɵ2);
const ɵ3 = state => state.isLoading;
exports.ɵ3 = ɵ3;
exports.getSwgBlockPageLoading = store_1.createSelector(exports.getSwgBlockPageSettings, ɵ3);
const ɵ4 = state => state.isSaving;
exports.ɵ4 = ɵ4;
exports.getSwgBlockPageSaving = store_1.createSelector(exports.getSwgBlockPageSettings, ɵ4);
const ɵ5 = state => state.error;
exports.ɵ5 = ɵ5;
exports.getSwgBlockPageError = store_1.createSelector(exports.getSwgBlockPageSettings, ɵ5);
