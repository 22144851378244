<div class="mc-directory-sync-wizard-container">
  <mc-wizard-step-header [label]="'$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.HEADER' | translate: { integrationType: integrationType() }"></mc-wizard-step-header>
  <form
    class="form-horizontal"
    mcDefaultLabelClass="col-sm-3"
    mcDefaultControlContainerClass="col-sm-6"
    [formGroup]="form"
  >
    <div class="panel-half-padding-bottom">
      <label class="text-bold">
        <input type="radio"
               name="connectorSelection"
               formControlName="connectorSelection"
               [value]="'existing'"
               [attr.disabled]="hasConnectors ? null : ''"
               data-test="existing-connector"/>
        {{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.AZURE.STD.EXISTING_CONNECTOR' | translate }}
      </label>
    </div>

    <div class="panel-half-padding-bottom">
      <label class="text-bold">
        <input type="radio"
               name="connectorSelection"
               formControlName="connectorSelection"
               [value]="'new'"
               data-test="new-connector"/>
        {{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.AZURE.STD.NEW_CONNECTOR' | translate }}
      </label>
    </div>

    <ng-container *ngIf="isExistingConnector()">
      <mc-field
        label="{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.AZURE.STD.CONNECTOR' | translate }}"
        errorPrefix="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.AZURE.STD.CONNECTOR_ERROR"
        useTranslation="true"
      >
        <mc-select
          formControlName="connector"
          [options]="connectorTypeOptions"
          [defaultValue]="form.value.connector"
          [extendClass]="hasConnectors ? '' : 'disabled'"
          data-test="connector-dropdown"
        ></mc-select>
      </mc-field>
    </ng-container>

    <ng-container *ngIf="isNewConnector()">
      <mc-text-field formControlName="connectorName"
                     label="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.AZURE.STD.CONNECTOR_NAME"
                     errorPrefix="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.AZURE.STD.CONNECTOR_NAME_ERROR"
                     required
                     [maxLength]="60"
      >
      </mc-text-field>

      <mc-text-area-field
        label="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.AZURE.STD.CONNECTOR_DESCRIPTION"
        errorPrefix="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.AZURE.STD.CONNECTOR_DESCRIPTION_ERROR"
        formControlName="connectorDescription"
        [maxLength]="200"
      >
      </mc-text-area-field>

      <div class="form-group mc-field">
        <label class="control-label col-sm-3">
          {{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.AZURE.STD.PERMISSIONS' | translate }}
        </label>
        <div class="col-sm-6">
          <p>
            {{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.AZURE.STD.PERMISSIONS_DISCLAIMER' | translate }}
          </p>
          <button class="btn btn-primary"
                  [disabled]="!isConnectorNameValid() || (permissionGranted$ | async)"
                  (click)="loadConsentConfig()">
            {{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.AZURE.STD.LOGIN' | translate }}
          </button>

          <div class="consent">
            <p *ngIf="permissionGranted$ | async">
              <span class="fas fa-check-circle"></span>
              <strong>{{ '$I18N_ACCESS_PROVIDER_MS.PERMISSIONS_GRANTED' | translate }}</strong>
            </p>
            <p *ngIf="permissionNotGranted$ | async">
              <span class="fas fa-exclamation-circle"></span>
              <strong>{{ '$I18N_ACCESS_PROVIDER_MS.ERRORS.' + errorCode | uppercase | translate: { provider: 'Azure Standard' } }}</strong>
              <strong *ngIf="isTechnicalSupportError()">
                <a [href]="supportLink$ | async" data-test="support-link" target="_blank" rel="noopener noreferrer">{{ '$I18N_ACCESS_PROVIDER_MS.ERRORS.CONTACT_SUPPORT' | translate }}</a>.
              </strong>
            </p>
          </div>
        </div>
      </div>

    </ng-container>
  </form>
</div>
