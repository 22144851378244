"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteAttributeFailure = exports.DeleteAttributeSuccess = exports.DeleteAttribute = exports.ConfirmDeleteAttribute = exports.SaveAttributeFailure = exports.SaveAttributeSuccess = exports.UpdateAttribute = exports.SaveNewAttributeSuccess = exports.SaveAttribute = exports.OpenCreateAttribute = exports.GetAttributeDetailsFailure = exports.GetAttributeDetailsSuccess = exports.GetAttributeDetails = exports.DELETE_ATTRIBUTE_FAILURE = exports.DELETE_ATTRIBUTE_SUCCESS = exports.DELETE_ATTRIBUTE = exports.DELETE_ATTRIBUTE_CONFIRM = exports.SAVE_ATTRIBUTE_FAILURE = exports.SAVE_ATTRIBUTE_SUCCESS = exports.UPDATE_ATTRIBUTE = exports.SAVE_NEW_ATTRIBUTE_SUCCESS = exports.SAVE_ATTRIBUTE = exports.OPEN_CREATE_ATTRIBUTE = exports.GET_ATTRIBUTE_DETAILS_FAILURE = exports.GET_ATTRIBUTE_DETAILS_SUCCESS = exports.GET_ATTRIBUTE_DETAILS = exports.PREFIX = void 0;
exports.PREFIX = '[Directory Attributes ] Attribute Details';
exports.GET_ATTRIBUTE_DETAILS = '[Directory Attributes ] Attribute Details get';
exports.GET_ATTRIBUTE_DETAILS_SUCCESS = '[Directory Attributes ] Attribute Details loaded';
exports.GET_ATTRIBUTE_DETAILS_FAILURE = '[Directory Attributes ] Attribute Details failed to load';
exports.OPEN_CREATE_ATTRIBUTE = '[Directory Attributes ] Attribute Details open create';
exports.SAVE_ATTRIBUTE = '[Directory Attributes ] Attribute Details save';
exports.SAVE_NEW_ATTRIBUTE_SUCCESS = '[Directory Attributes ] Attribute Details saved new attribute';
exports.UPDATE_ATTRIBUTE = '[Directory Attributes ] Attribute Details update';
exports.SAVE_ATTRIBUTE_SUCCESS = '[Directory Attributes ] Attribute Details saved';
exports.SAVE_ATTRIBUTE_FAILURE = '[Directory Attributes ] Attribute Details failed to save';
exports.DELETE_ATTRIBUTE_CONFIRM = '[Directory Attributes ] Attribute Details delete confirm';
exports.DELETE_ATTRIBUTE = '[Directory Attributes ] Attribute Details delete';
exports.DELETE_ATTRIBUTE_SUCCESS = '[Directory Attributes ] Attribute Details deleted';
exports.DELETE_ATTRIBUTE_FAILURE = '[Directory Attributes ] Attribute Details failed to delete';
class GetAttributeDetails {
    constructor(row) {
        this.row = row;
        this.type = exports.GET_ATTRIBUTE_DETAILS;
    }
}
exports.GetAttributeDetails = GetAttributeDetails;
class GetAttributeDetailsSuccess {
    constructor(attribute) {
        this.attribute = attribute;
        this.type = exports.GET_ATTRIBUTE_DETAILS_SUCCESS;
    }
}
exports.GetAttributeDetailsSuccess = GetAttributeDetailsSuccess;
class GetAttributeDetailsFailure {
    constructor(error) {
        this.error = error;
        this.type = exports.GET_ATTRIBUTE_DETAILS_FAILURE;
    }
}
exports.GetAttributeDetailsFailure = GetAttributeDetailsFailure;
class OpenCreateAttribute {
    constructor() {
        this.type = exports.OPEN_CREATE_ATTRIBUTE;
    }
}
exports.OpenCreateAttribute = OpenCreateAttribute;
class SaveAttribute {
    constructor(attribute) {
        this.attribute = attribute;
        this.type = exports.SAVE_ATTRIBUTE;
    }
}
exports.SaveAttribute = SaveAttribute;
class SaveNewAttributeSuccess {
    constructor(attribute) {
        this.attribute = attribute;
        this.type = exports.SAVE_NEW_ATTRIBUTE_SUCCESS;
    }
}
exports.SaveNewAttributeSuccess = SaveNewAttributeSuccess;
class UpdateAttribute {
    constructor(attribute) {
        this.attribute = attribute;
        this.type = exports.UPDATE_ATTRIBUTE;
    }
}
exports.UpdateAttribute = UpdateAttribute;
class SaveAttributeSuccess {
    constructor(attribute) {
        this.attribute = attribute;
        this.type = exports.SAVE_ATTRIBUTE_SUCCESS;
    }
}
exports.SaveAttributeSuccess = SaveAttributeSuccess;
class SaveAttributeFailure {
    constructor(error) {
        this.error = error;
        this.type = exports.SAVE_ATTRIBUTE_FAILURE;
    }
}
exports.SaveAttributeFailure = SaveAttributeFailure;
class ConfirmDeleteAttribute {
    constructor(attribute) {
        this.attribute = attribute;
        this.type = exports.DELETE_ATTRIBUTE_CONFIRM;
    }
}
exports.ConfirmDeleteAttribute = ConfirmDeleteAttribute;
class DeleteAttribute {
    constructor(attribute) {
        this.attribute = attribute;
        this.type = exports.DELETE_ATTRIBUTE;
    }
}
exports.DeleteAttribute = DeleteAttribute;
class DeleteAttributeSuccess {
    constructor(attribute) {
        this.attribute = attribute;
        this.type = exports.DELETE_ATTRIBUTE_SUCCESS;
    }
}
exports.DeleteAttributeSuccess = DeleteAttributeSuccess;
class DeleteAttributeFailure {
    constructor(attribute) {
        this.attribute = attribute;
        this.type = exports.DELETE_ATTRIBUTE_FAILURE;
    }
}
exports.DeleteAttributeFailure = DeleteAttributeFailure;
