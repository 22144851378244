"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tableSectionSearch = exports.tableSectionFilters = exports.tableColumnsToIgnore = exports.tableColumnsStartHidden = exports.tableColumnsAlwaysVisible = exports.exportColumnList = exports.tableColumnList = exports.as = void 0;
exports.as = '';
exports.tableColumnList = [
    'fromEnv',
    'fromHdr',
    'toAddress',
    'subject',
    'deliveryAttempt',
    'remoteIp',
    'hasAttachment',
    'size',
    'route',
    'info',
    'spamScore',
    'detectionLevel',
    'description',
    'right-column'
];
exports.exportColumnList = [
    'fromEnv',
    'fromHdr',
    'to',
    'subject',
    'sent',
    'senderIP',
    'attachments',
    'size',
    'route',
    'info',
    'spamScore',
    'spamDetection',
    'bounceType'
];
exports.tableColumnsAlwaysVisible = ['toAddress', 'info', 'deliveryAttempt'];
exports.tableColumnsStartHidden = ['fromEnv', 'hasAttachment', 'spamScore', 'detectionLevel'];
exports.tableColumnsToIgnore = [];
exports.tableSectionFilters = [
    {
        name: 'bounce',
        filters: ['hard', 'soft']
    },
    {
        name: 'route',
        filters: ['inbound', 'outbound', 'internal']
    },
    {
        name: 'attachments',
        filters: ['true', 'false']
    }
];
exports.tableSectionSearch = {
    field: 'search',
    header: 'header',
    options: ['from', 'to', 'subject', 'remoteIp']
};
