"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./modal-error-report-service"); //modalErrorReport.service
require("./modal-error-report-controller"); //modalErrorReport.controller
// html and css 
require("./modal-error-report.tpl.html");
/**
 * Created by psambandam on 14/07/2016.
 */
angular.module('modalErrorReport', ['modalErrorReport.service', 'modalErrorReport.controller']);
