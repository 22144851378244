"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApPolicyOptionsEffects = void 0;
const effects_1 = require("@ngrx/effects");
const operators_1 = require("rxjs/operators");
const rxjs_1 = require("rxjs");
const actions = require("../../actions/policy.actions");
const ap_policy_details_aside_component_1 = require("../../components/sidepanel/ap-policy-details-aside.component");
const ap_policy_delete_confirmation_component_1 = require("../../components/delete/ap-policy-delete-confirmation.component");
class ApPolicyOptionsEffects {
    constructor(actions$, asideService, policiesApApiService, notificationService, translateService, modalService, store) {
        this.actions$ = actions$;
        this.asideService = asideService;
        this.policiesApApiService = policiesApApiService;
        this.notificationService = notificationService;
        this.translateService = translateService;
        this.modalService = modalService;
        this.store = store;
        this.openPolicyDetailsSidebar$ = this.actions$.pipe(effects_1.ofType(actions.ApPolicyCreateTypesEnum.OPEN_AP_POLICY_SIDEBAR), operators_1.tap(() => {
            this.sidepanelInstance = this.asideService.open(ap_policy_details_aside_component_1.ApPolicyDetailsAsideComponent, {
                position: 'right',
                size: 'lg',
                hasBackdrop: true
            });
            this.sidepanelInstance
                .beforeClose()
                .pipe(operators_1.take(1))
                .subscribe(() => {
                this.store.dispatch(new actions.ApCloseAppAsideAction());
            });
            this.sidepanelInstance
                .afterClose()
                .pipe(operators_1.take(1))
                .subscribe(() => {
                this.sidepanelInstance = undefined;
            });
        }));
        this.ApOpenPolicyDeleteAlert = this.actions$.pipe(effects_1.ofType(actions.ApPolicyCreateTypesEnum.AP_POLICY_DELETE_MODAL_OPEN), operators_1.map((action) => action.payload), operators_1.switchMap(() => {
            const aside = this.modalService.open(ap_policy_delete_confirmation_component_1.ApDeletePolicyConfirmationComponent, {
                size: 'md',
                hasBackdrop: true
            });
            this.deleteAlertModalInstance = aside;
            return rxjs_1.of(new actions.ApCloseAppAsideAction());
        }));
        this.apClosePolicyDeleteAlert = this.actions$.pipe(effects_1.ofType(actions.ApPolicyCreateTypesEnum.AP_POLICY_DELETE_MODAL_CLOSE), operators_1.filter(() => !!this.deleteAlertModalInstance), operators_1.tap(() => {
            this.deleteAlertModalInstance.close();
            this.deleteAlertModalInstance = undefined;
        }));
        this.closeSidePanel$ = this.actions$.pipe(effects_1.ofType(actions.ApPolicyCreateTypesEnum.CLOSE_AP_POLICY_SIDEBAR), operators_1.filter(() => !!this.sidepanelInstance), operators_1.tap(() => {
            this.sidepanelInstance.close();
            this.sidepanelInstance = undefined;
        }));
        this.loadPolicyDetails$ = this.actions$.pipe(effects_1.ofType(actions.ApPolicyCreateTypesEnum.LOAD_AP_POLICY_DETAILS), operators_1.switchMap((action) => {
            return this.policiesApApiService.getPolicyDetails(action.payload.id).pipe(operators_1.map((response) => {
                return new actions.ApLoadPolicyDetailsSuccessAction(response);
            }), operators_1.catchError(() => {
                this.notificationService.error({
                    msg: this.translateService.instant('$I18N_AP_GENERIC_ERROR')
                });
                return rxjs_1.of(new actions.APLoadPolicyDetailsFailAction());
            }));
        }));
    }
}
__decorate([
    effects_1.Effect({ dispatch: false })
], ApPolicyOptionsEffects.prototype, "openPolicyDetailsSidebar$", void 0);
__decorate([
    effects_1.Effect()
], ApPolicyOptionsEffects.prototype, "ApOpenPolicyDeleteAlert", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], ApPolicyOptionsEffects.prototype, "apClosePolicyDeleteAlert", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], ApPolicyOptionsEffects.prototype, "closeSidePanel$", void 0);
__decorate([
    effects_1.Effect()
], ApPolicyOptionsEffects.prototype, "loadPolicyDetails$", void 0);
exports.ApPolicyOptionsEffects = ApPolicyOptionsEffects;
