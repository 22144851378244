"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgThreatEventsReportFalsePositiveAction = exports.SwgThreatEventsReportFalsePositiveConfirmationAction = exports.SwgThreatEventsReleaseAction = exports.SwgThreatEventsReleaseConfirmationAction = exports.SwgDownloadThreatEventFormSubmit = exports.SwgDownloadThreatEventModalClose = exports.SwgDownloadThreatEventModalOpen = exports.SwgThreatEventsManualQuarantineAction = exports.SwgThreatEventsManualQuarantineConfirmationAction = exports.SwgThreatEventsSidebarCloseAction = exports.SwgThreatEventsSidebarOpenAction = exports.SwgLoadThreatEventDetailsFailureAction = exports.SwgLoadThreatEventDetailsSuccessAction = exports.SwgLoadThreatEventDetailsAction = exports.SwgThreatEventDetailsActionsEnum = void 0;
var SwgThreatEventDetailsActionsEnum;
(function (SwgThreatEventDetailsActionsEnum) {
    SwgThreatEventDetailsActionsEnum["LOAD_THREAT_EVENT_DETAILS"] = "[SwgThreatEvents] Load Details";
    SwgThreatEventDetailsActionsEnum["LOAD_THREAT_EVENT_DETAILS_SUCCESS"] = "[SwgThreatEvents] Load Details Success";
    SwgThreatEventDetailsActionsEnum["LOAD_THREAT_EVENT_DETAILS_FAILURE"] = "[SwgThreatEvents] Load Details Failure";
    SwgThreatEventDetailsActionsEnum["THREAT_EVENT_SIDEBAR_OPEN"] = "[SwgThreatEvents] Details Panel Open";
    SwgThreatEventDetailsActionsEnum["THREAT_EVENT_SIDEBAR_CLOSE"] = "[SwgThreatEvents] Details Panel Close";
    SwgThreatEventDetailsActionsEnum["MANUAL_QUARANTINE_CONFIRMATION"] = "[SwgThreatEvents] Manual Quarantine Confirmation";
    SwgThreatEventDetailsActionsEnum["MANUAL_QUARANTINE"] = "[SwgThreatEvents] Manual Quarantine";
    SwgThreatEventDetailsActionsEnum["RELEASE_CONFIRMATION"] = "[SwgThreatEvents] Release Confirmation";
    SwgThreatEventDetailsActionsEnum["RELEASE"] = "[SwgThreatEvents] Release";
    SwgThreatEventDetailsActionsEnum["THREAT_EVENT_REPORT_FALSE_POSITIVE_CONFIRMATION"] = "[SwgThreatEvents] Report False Positive Confirmation";
    SwgThreatEventDetailsActionsEnum["THREAT_EVENT_REPORT_FALSE_POSITIVE"] = "[SwgThreatEvents] Report False Positive";
    SwgThreatEventDetailsActionsEnum["DOWNLOAD_MODAL_OPEN"] = "[SwgThreatEvents] Download Modal Open";
    SwgThreatEventDetailsActionsEnum["DOWNLOAD_MODAL_CLOSE"] = "[SwgThreatEvents] Download Modal Close";
    SwgThreatEventDetailsActionsEnum["DOWNLOAD_FORM_SUBMIT"] = "[SwgThreatEvents] Download Form Submit";
})(SwgThreatEventDetailsActionsEnum = exports.SwgThreatEventDetailsActionsEnum || (exports.SwgThreatEventDetailsActionsEnum = {}));
class SwgLoadThreatEventDetailsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgThreatEventDetailsActionsEnum.LOAD_THREAT_EVENT_DETAILS;
    }
}
exports.SwgLoadThreatEventDetailsAction = SwgLoadThreatEventDetailsAction;
class SwgLoadThreatEventDetailsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgThreatEventDetailsActionsEnum.LOAD_THREAT_EVENT_DETAILS_SUCCESS;
    }
}
exports.SwgLoadThreatEventDetailsSuccessAction = SwgLoadThreatEventDetailsSuccessAction;
class SwgLoadThreatEventDetailsFailureAction {
    constructor() {
        this.type = SwgThreatEventDetailsActionsEnum.LOAD_THREAT_EVENT_DETAILS_FAILURE;
    }
}
exports.SwgLoadThreatEventDetailsFailureAction = SwgLoadThreatEventDetailsFailureAction;
class SwgThreatEventsSidebarOpenAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgThreatEventDetailsActionsEnum.THREAT_EVENT_SIDEBAR_OPEN;
    }
}
exports.SwgThreatEventsSidebarOpenAction = SwgThreatEventsSidebarOpenAction;
class SwgThreatEventsSidebarCloseAction {
    constructor() {
        this.type = SwgThreatEventDetailsActionsEnum.THREAT_EVENT_SIDEBAR_CLOSE;
    }
}
exports.SwgThreatEventsSidebarCloseAction = SwgThreatEventsSidebarCloseAction;
class SwgThreatEventsManualQuarantineConfirmationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgThreatEventDetailsActionsEnum.MANUAL_QUARANTINE_CONFIRMATION;
    }
}
exports.SwgThreatEventsManualQuarantineConfirmationAction = SwgThreatEventsManualQuarantineConfirmationAction;
class SwgThreatEventsManualQuarantineAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgThreatEventDetailsActionsEnum.MANUAL_QUARANTINE;
    }
}
exports.SwgThreatEventsManualQuarantineAction = SwgThreatEventsManualQuarantineAction;
class SwgDownloadThreatEventModalOpen {
    constructor() {
        this.type = SwgThreatEventDetailsActionsEnum.DOWNLOAD_MODAL_OPEN;
    }
}
exports.SwgDownloadThreatEventModalOpen = SwgDownloadThreatEventModalOpen;
class SwgDownloadThreatEventModalClose {
    constructor() {
        this.type = SwgThreatEventDetailsActionsEnum.DOWNLOAD_MODAL_CLOSE;
    }
}
exports.SwgDownloadThreatEventModalClose = SwgDownloadThreatEventModalClose;
class SwgDownloadThreatEventFormSubmit {
    constructor(id, password) {
        this.id = id;
        this.password = password;
        this.type = SwgThreatEventDetailsActionsEnum.DOWNLOAD_FORM_SUBMIT;
    }
}
exports.SwgDownloadThreatEventFormSubmit = SwgDownloadThreatEventFormSubmit;
class SwgThreatEventsReleaseConfirmationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgThreatEventDetailsActionsEnum.RELEASE_CONFIRMATION;
    }
}
exports.SwgThreatEventsReleaseConfirmationAction = SwgThreatEventsReleaseConfirmationAction;
class SwgThreatEventsReleaseAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgThreatEventDetailsActionsEnum.RELEASE;
    }
}
exports.SwgThreatEventsReleaseAction = SwgThreatEventsReleaseAction;
class SwgThreatEventsReportFalsePositiveConfirmationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgThreatEventDetailsActionsEnum.THREAT_EVENT_REPORT_FALSE_POSITIVE_CONFIRMATION;
    }
}
exports.SwgThreatEventsReportFalsePositiveConfirmationAction = SwgThreatEventsReportFalsePositiveConfirmationAction;
class SwgThreatEventsReportFalsePositiveAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgThreatEventDetailsActionsEnum.THREAT_EVENT_REPORT_FALSE_POSITIVE;
    }
}
exports.SwgThreatEventsReportFalsePositiveAction = SwgThreatEventsReportFalsePositiveAction;
