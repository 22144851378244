"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageProcessingExportAction = exports.MessageProcessingRetryEmailsFailAction = exports.MessageProcessingRetryEmailsSuccessAction = exports.MessageProcessingRetryEmailsAction = exports.MessageProcessingEarlyBounceEmailsFailAction = exports.MessageProcessingEarlyBounceEmailsSuccessAction = exports.MessageProcessingEarlyBounceEmailsAction = exports.MessageProcessingRejectEmailsFailAction = exports.MessageProcessingRejectEmailsSuccessAction = exports.MessageProcessingRejectEmailsAction = exports.MessageProcessingFailAction = exports.MessageProcessingSuccessAction = exports.MessageProcessingRequestAction = exports.MessageProcessingPaginationAction = exports.MessageProcessingSelectRowAction = exports.MessageProcessingAsidePaginationAction = exports.MessageProcessingCloseDetailAction = exports.MessageProcessingRefreshAction = exports.PROCESSING_MESSAGE_EXPORT = exports.PROCESSING_MESSAGE_EARLY_BOUNCE_EMAILS_FAIL = exports.PROCESSING_MESSAGE_EARLY_BOUNCE_EMAILS_SUCCESS = exports.PROCESSING_MESSAGE_EARLY_BOUNCE_EMAILS = exports.PROCESSING_MESSAGE_RETRY_EMAILS_FAIL = exports.PROCESSING_MESSAGE_RETRY_EMAILS_SUCCESS = exports.PROCESSING_MESSAGE_RETRY_EMAILS = exports.PROCESSING_MESSAGE_REJECT_EMAILS_FAIL = exports.PROCESSING_MESSAGE_REJECT_EMAILS_SUCCESS = exports.PROCESSING_MESSAGE_REJECT_EMAILS = exports.PROCESSING_MESSAGE_FAIL = exports.PROCESSING_MESSAGE_SUCCESS = exports.PROCESSING_MESSAGE_REQUEST = exports.PROCESSING_MESSAGE_PAGINATION = exports.PROCESSING_MESSAGE_SELECT_ROW = exports.PROCESSING_MESSAGE_ASIDE_PAGINATION = exports.PROCESSING_MESSAGE_CLOSE_DETAIL = exports.PROCESSING_MESSAGE_REFRESH = void 0;
exports.PROCESSING_MESSAGE_REFRESH = '[Message Center /  Message Processing]  Message Processing Refresh';
exports.PROCESSING_MESSAGE_CLOSE_DETAIL = '[Message Center /  Message Processing]  Message Processing Close Message Detail';
exports.PROCESSING_MESSAGE_ASIDE_PAGINATION = '[Message Center /  Message Processing]  Message Processing Aside Pagination';
exports.PROCESSING_MESSAGE_SELECT_ROW = '[Message Center /  Message Processing]  Message Processing Select Row';
exports.PROCESSING_MESSAGE_PAGINATION = '[Message Center / Message Processing] Message Processing Pagination';
exports.PROCESSING_MESSAGE_REQUEST = '[Message Center / Message Processing] Request Get Message Processing';
exports.PROCESSING_MESSAGE_SUCCESS = '[Message Center / Message Processing] Get Message Processing Success';
exports.PROCESSING_MESSAGE_FAIL = '[Message Center / Message Processing] Get Message Processing Fail';
exports.PROCESSING_MESSAGE_REJECT_EMAILS = '[Message Center / Message Processing] Reject Message Processing Emails';
exports.PROCESSING_MESSAGE_REJECT_EMAILS_SUCCESS = '[Message Center / Message Processing] Reject Message Processing Emails Success';
exports.PROCESSING_MESSAGE_REJECT_EMAILS_FAIL = '[Message Center / Message Processing] Reject Message Processing Emails Fail';
exports.PROCESSING_MESSAGE_RETRY_EMAILS = '[Message Center / Message Processing] Retry Message Processing Emails';
exports.PROCESSING_MESSAGE_RETRY_EMAILS_SUCCESS = '[Message Center / Message Processing] Retry Message Processing Emails Success';
exports.PROCESSING_MESSAGE_RETRY_EMAILS_FAIL = '[Message Center / Message Processing] Retry Message Processing Emails Fail';
exports.PROCESSING_MESSAGE_EARLY_BOUNCE_EMAILS = '[Message Center / Message Processing] Early Bounce Message Processing Emails';
exports.PROCESSING_MESSAGE_EARLY_BOUNCE_EMAILS_SUCCESS = '[Message Center / Message Processing] Early Bounce Message Processing Emails Success';
exports.PROCESSING_MESSAGE_EARLY_BOUNCE_EMAILS_FAIL = '[Message Center / Message Processing] Early Bounce Message Processing Emails Fail';
exports.PROCESSING_MESSAGE_EXPORT = '[Message Center / Message Processing] Export Message Processing Emails';
class MessageProcessingRefreshAction {
    constructor() {
        this.type = exports.PROCESSING_MESSAGE_REFRESH;
    }
}
exports.MessageProcessingRefreshAction = MessageProcessingRefreshAction;
class MessageProcessingCloseDetailAction {
    constructor() {
        this.type = exports.PROCESSING_MESSAGE_CLOSE_DETAIL;
    }
}
exports.MessageProcessingCloseDetailAction = MessageProcessingCloseDetailAction;
class MessageProcessingAsidePaginationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.PROCESSING_MESSAGE_ASIDE_PAGINATION;
    }
}
exports.MessageProcessingAsidePaginationAction = MessageProcessingAsidePaginationAction;
class MessageProcessingSelectRowAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.PROCESSING_MESSAGE_SELECT_ROW;
    }
}
exports.MessageProcessingSelectRowAction = MessageProcessingSelectRowAction;
class MessageProcessingPaginationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.PROCESSING_MESSAGE_PAGINATION;
    }
}
exports.MessageProcessingPaginationAction = MessageProcessingPaginationAction;
class MessageProcessingRequestAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.PROCESSING_MESSAGE_REQUEST;
    }
}
exports.MessageProcessingRequestAction = MessageProcessingRequestAction;
class MessageProcessingSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.PROCESSING_MESSAGE_SUCCESS;
    }
}
exports.MessageProcessingSuccessAction = MessageProcessingSuccessAction;
class MessageProcessingFailAction {
    constructor() {
        this.type = exports.PROCESSING_MESSAGE_FAIL;
    }
}
exports.MessageProcessingFailAction = MessageProcessingFailAction;
class MessageProcessingRejectEmailsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.PROCESSING_MESSAGE_REJECT_EMAILS;
    }
}
exports.MessageProcessingRejectEmailsAction = MessageProcessingRejectEmailsAction;
class MessageProcessingRejectEmailsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.PROCESSING_MESSAGE_REJECT_EMAILS_SUCCESS;
    }
}
exports.MessageProcessingRejectEmailsSuccessAction = MessageProcessingRejectEmailsSuccessAction;
class MessageProcessingRejectEmailsFailAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.PROCESSING_MESSAGE_REJECT_EMAILS_FAIL;
    }
}
exports.MessageProcessingRejectEmailsFailAction = MessageProcessingRejectEmailsFailAction;
class MessageProcessingEarlyBounceEmailsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.PROCESSING_MESSAGE_EARLY_BOUNCE_EMAILS;
    }
}
exports.MessageProcessingEarlyBounceEmailsAction = MessageProcessingEarlyBounceEmailsAction;
class MessageProcessingEarlyBounceEmailsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.PROCESSING_MESSAGE_EARLY_BOUNCE_EMAILS_SUCCESS;
    }
}
exports.MessageProcessingEarlyBounceEmailsSuccessAction = MessageProcessingEarlyBounceEmailsSuccessAction;
class MessageProcessingEarlyBounceEmailsFailAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.PROCESSING_MESSAGE_EARLY_BOUNCE_EMAILS_FAIL;
    }
}
exports.MessageProcessingEarlyBounceEmailsFailAction = MessageProcessingEarlyBounceEmailsFailAction;
class MessageProcessingRetryEmailsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.PROCESSING_MESSAGE_RETRY_EMAILS;
    }
}
exports.MessageProcessingRetryEmailsAction = MessageProcessingRetryEmailsAction;
class MessageProcessingRetryEmailsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.PROCESSING_MESSAGE_RETRY_EMAILS_SUCCESS;
    }
}
exports.MessageProcessingRetryEmailsSuccessAction = MessageProcessingRetryEmailsSuccessAction;
class MessageProcessingRetryEmailsFailAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.PROCESSING_MESSAGE_RETRY_EMAILS_FAIL;
    }
}
exports.MessageProcessingRetryEmailsFailAction = MessageProcessingRetryEmailsFailAction;
class MessageProcessingExportAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.PROCESSING_MESSAGE_EXPORT;
    }
}
exports.MessageProcessingExportAction = MessageProcessingExportAction;
