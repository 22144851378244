<div class="mc-detailed-list mc-swg-policy-summary-block">
  <div class="row mc-detailed-list-row no-padding-top" *ngIf="hasSwgFull">
    <div class="mc-sidepanel-label mc-detailed-list-label">
      {{ '$18N_SWG_REPORTING.TABLE.COLUMNS.USERNAME' | translate }}
    </div>
    <div *ngIf="showMultipleUsersInUsersField(row); else usualUserName" class="mc-detailed-list-column">
      {{'$18N_SWG_REPORTING.MULTIPLE_USERS' | translate}}
    </div>
    <ng-template #usualUserName>
      <div class="mc-detailed-list-column">{{ row?.username }}</div>
    </ng-template>
  </div>

  <div class="row mc-detailed-list-row no-padding-top">
    <div class="mc-sidepanel-label mc-detailed-list-label">
      {{ '$18N_SWG_REPORTING.TABLE.COLUMNS.DISCOVERYMETHOD' | translate }}
    </div>
    <div class="mc-detailed-list-column">{{ row?.discoveryMethod }}</div>
  </div>

  <div class="row mc-detailed-list-row no-padding-top" *ngIf="hasPrivateIpSupport">
    <div class="mc-sidepanel-label mc-detailed-list-label">
      {{ '$18N_SWG_REPORTING.TABLE.COLUMNS.DEVICENAME' | translate }}
    </div>
    <div class="mc-detailed-list-column">{{ row?.deviceName }}</div>
  </div>

  <div class="row mc-detailed-list-row no-padding-top" *ngIf="!hasPrivateIpSupport">
    <div class="mc-sidepanel-label mc-detailed-list-label">
      {{ '$18N_SWG_REPORTING.TABLE.COLUMNS.SOURCEIP' | translate }}
    </div>
    <div class="mc-detailed-list-column">{{ row?.sourceIp }}</div>
  </div>

  <div class="row mc-detailed-list-row no-padding-top" *ngIf="hasPrivateIpSupport">
    <div class="mc-sidepanel-label mc-detailed-list-label">
      {{ '$18N_SWG_REPORTING.TABLE.COLUMNS.PUBLICIP' | translate }}
    </div>
    <div class="mc-detailed-list-column">{{ row?.publicIp }}</div>
  </div>

  <div class="row mc-detailed-list-row no-padding-top" *ngIf="hasPrivateIpSupport">
    <div class="mc-sidepanel-label mc-detailed-list-label">
      {{ '$18N_SWG_REPORTING.TABLE.COLUMNS.PRIVATEIP' | translate }}
    </div>
    <div class="mc-detailed-list-column">{{ row?.privateIp }}</div>
  </div>

  <div class="row mc-detailed-list-row no-padding-top">
    <div class="mc-sidepanel-label mc-detailed-list-label">
      {{ '$18N_SWG_REPORTING.TABLE.COLUMNS.REQUEST' | translate }}
    </div>
    <div class="mc-detailed-list-column">{{ row?.request }}</div>
  </div>

  <div class="row mc-detailed-list-row no-padding-top">
    <div class="mc-sidepanel-label mc-detailed-list-label">
      {{ '$18N_SWG_REPORTING.TABLE.COLUMNS.CATEGORY' | translate }}
    </div>
    <div class="mc-detailed-list-column">{{ row?.category | mcSwgCategoryNameTranslate }}</div>
  </div>

  <div
    class="row mc-detailed-list-row no-padding-top"
    *ngIf="hasApplicationVisibility && row.appName"
  >
    <div class="mc-sidepanel-label mc-detailed-list-label">
      {{ '$18N_SWG_REPORTING.TABLE.COLUMNS.APPNAME' | translate }}
    </div>
    <div class="mc-detailed-list-column">{{ row?.appName }}</div>
  </div>

  <div
    class="row mc-detailed-list-row no-padding-top"
    *ngIf="hasApplicationVisibility && row.appName"
  >
    <div class="mc-sidepanel-label mc-detailed-list-label">
      {{ '$18N_SWG_REPORTING.TABLE.COLUMNS.APPCATEGORY' | translate }}
    </div>
    <div class="mc-detailed-list-column">{{ row?.appCategory | mcSwgCategoryNameTranslate }}</div>
  </div>

  <div class="row mc-detailed-list-row no-padding-top">
    <div class="mc-sidepanel-label mc-detailed-list-label">
      {{ '$18N_SWG_REPORTING.TABLE.COLUMNS.ACTION' | translate }}
    </div>
    <div class="mc-detailed-list-column">{{ row?.action }}</div>
  </div>

  <div class="row mc-detailed-list-row no-padding-top">
    <div class="mc-sidepanel-label mc-detailed-list-label">
      {{ '$18N_SWG_REPORTING.TABLE.COLUMNS.REQUESTEVENT' | translate }}
    </div>
    <div class="mc-detailed-list-column">{{ row?.requestEvent }}</div>
  </div>

  <div class="row mc-detailed-list-row no-padding-top">
    <div class="mc-sidepanel-label mc-detailed-list-label">
      {{ '$18N_SWG_REPORTING.TABLE.COLUMNS.REQUESTTYPE' | translate }}
    </div>
    <div class="mc-detailed-list-column">{{ row?.requestType }}</div>
  </div>

  <div class="row mc-detailed-list-row no-padding-top">
    <div class="mc-sidepanel-label mc-detailed-list-label">
      {{ '$18N_SWG_REPORTING.TABLE.COLUMNS.ACTIONREASON' | translate }}
    </div>
    <div class="mc-detailed-list-column">{{ row?.actionReason }}</div>
  </div>

  <div class="row mc-detailed-list-row no-padding-top">
    <div class="mc-sidepanel-label mc-detailed-list-label">
      {{ '$18N_SWG_REPORTING.TABLE.COLUMNS.POLICYNAME' | translate }}
    </div>
    <div class="mc-detailed-list-column">{{ row?.policyName }}</div>
  </div>

  <div class="row mc-detailed-list-row no-padding-top">
    <div class="mc-sidepanel-label mc-detailed-list-label">
      {{ '$18N_SWG_REPORTING.TABLE.COLUMNS.DATETIME' | translate }}
    </div>
    <div class="mc-detailed-list-column">{{ row?.dateTime | mcDate }}</div>
  </div>
</div>
