"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiGatewayKeyGenWizardComponent = void 0;
const core_1 = require("@angular/core");
const operators_1 = require("rxjs/operators");
const models_1 = require("../../../../models");
class ApiGatewayKeyGenWizardComponent {
    constructor() {
        this.onAccountUpdated = new core_1.EventEmitter();
        this.onAuthenticationUpdated = new core_1.EventEmitter();
        this.onVerificationUpdated = new core_1.EventEmitter();
        this.onMoveNext = new core_1.EventEmitter();
        this.onMovePrevious = new core_1.EventEmitter();
        this.onCancel = new core_1.EventEmitter();
        this.onComplete = new core_1.EventEmitter();
        this.onKeyCopiedToClipboard = new core_1.EventEmitter();
        this.hasNextStep = true;
        this.hasPreviousStep = false;
        this.isLastStep = false;
        this.KeyGenState = models_1.KeyGenState;
    }
    ngOnInit() { }
    ngAfterViewInit() {
        if (!!this.wizard) {
            this.wizard.hasNextStep$.subscribe(value => (this.hasNextStep = value));
            this.wizard.hasPreviousStep$.subscribe(value => (this.hasPreviousStep = value));
            this.wizard.isLastStep$.subscribe(value => (this.isLastStep = value));
            this.wizardStep$.pipe(operators_1.tap(step => this.wizard.showStep(step))).subscribe();
        }
    }
    updateAccount(account) {
        this.onAccountUpdated.emit(account);
    }
    updateAuthentication(authentication) {
        this.onAuthenticationUpdated.emit(authentication);
    }
    updateVerification(verification) {
        this.onVerificationUpdated.emit(verification);
    }
    nextStep() {
        if (!!this.wizard) {
            this.onMoveNext.emit();
        }
    }
    previousStep() {
        if (!!this.wizard) {
            this.onMovePrevious.emit();
        }
    }
    get isStepValid() {
        if (this.isBusy) {
            return false;
        }
        if (!!this.wizard) {
            switch (this.wizard.currentStep) {
                case models_1.KeyGenState.account:
                    return !!this.accountStep && this.accountStep.isValid();
                case models_1.KeyGenState.authentication:
                    return !!this.authenticationStep && this.authenticationStep.isValid();
                case models_1.KeyGenState.verification:
                    return !!this.verificationStep && this.verificationStep.isValid();
                default:
                    return true;
            }
        }
        else {
            return false;
        }
    }
    changeStep() {
        // you have to listen to this event or the underlying wizard doesn't work :(
    }
    copiedToClipbard(msg) {
        this.onKeyCopiedToClipboard.emit(msg);
    }
    complete() {
        this.onComplete.emit();
    }
    cancel() {
        this.onCancel.emit();
    }
}
exports.ApiGatewayKeyGenWizardComponent = ApiGatewayKeyGenWizardComponent;
