"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateUrlProtectionPolicyWizardComponent = exports.PolicyWizardControls = void 0;
const policy_wizard_models_1 = require("../../models/policy-wizard.models");
const actions = require("../../actions/policies-create.actions");
const editActions = require("../../actions/policies-edit.actions");
const reducers = require("../../reducers");
const operators_1 = require("rxjs/operators");
const confirmation_modal_component_1 = require("../confirmation-modal/confirmation-modal.component");
const policies_model_1 = require("../../models/policies.model");
var PolicyWizardControls;
(function (PolicyWizardControls) {
    PolicyWizardControls[PolicyWizardControls["EDIT"] = 0] = "EDIT";
    PolicyWizardControls[PolicyWizardControls["BACK"] = 1] = "BACK";
    PolicyWizardControls[PolicyWizardControls["NEXT"] = 2] = "NEXT";
})(PolicyWizardControls = exports.PolicyWizardControls || (exports.PolicyWizardControls = {}));
class CreateUrlProtectionPolicyWizardComponent {
    constructor(store, accountPackageService, modalService) {
        this.store = store;
        this.accountPackageService = accountPackageService;
        this.modalService = modalService;
        this.loading = false;
        this.currentStep = 1;
        this.bypassFlowIsDisabled = false;
        this.isEditMode = false;
        this.isDuplicateMode = false;
        this.addressAttributeValid = true;
        this.canNotifyAboutAppliesToStepEveryoneValue = false;
        this.navigation = {
            edit: PolicyWizardControls.EDIT,
            back: PolicyWizardControls.BACK,
            next: PolicyWizardControls.NEXT
        };
        this.shouldCallNotificationModal = true;
        this.appliesToFromEveryoneModalWasOpened = false;
        this.isAAAOrFAAUser = accountPackageService.isAAAOrFAAUser();
        this.getAddressAttributeOptions(!this.isAAAOrFAAUser);
        this.checkCustomPageOptionsForAAAOrFAAUser();
        this.hasBrowserIsolation = accountPackageService.hasBrowserIsolationEnabled();
        this.bypassFlow$ = this.store.select(reducers.getBypassUrlProtection);
        this.bypassFlowStoreSubscription = this.bypassFlow$.subscribe(value => {
            // value is inverted because component logic is oposite of buttons logic in policy details step
            this.bypassFlowIsDisabled = !value;
        });
        this.fromToWhereAttributes$ = this.store.select(reducers.getFromToWhereAttributes);
        this.customPageSetOptions$ = this.store.select(reducers.getUserAwarenessCustomPageSetOptions);
        this.hasIEPPackage = this.accountPackageService.hasInternalEmailProtectAccountPackage();
        this.fromToWhereAttributes$.subscribe(attributes => {
            this.addressAttributesLength = attributes.fromToWhereAttributes.length;
        });
    }
    checkCustomPageOptionsForAAAOrFAAUser() {
        if (!this.isAAAOrFAAUser) {
            this.getCustomPageSetOptions();
        }
    }
    getPageTitle() {
        if (policy_wizard_models_1.PolicyWizardFlowType.DUPLICATE === this.wizardFlowType) {
            return '$I18N_URL_PROTECTION_POLICY_WIZARD.PAGE_TITLE.DUPLICATE_POLICY';
        }
        else if (policy_wizard_models_1.PolicyWizardFlowType.EDIT === this.wizardFlowType) {
            return '$I18N_URL_PROTECTION_POLICY_WIZARD.PAGE_TITLE.EDIT_POLICY';
        }
        else {
            return '$I18N_URL_PROTECTION_POLICY_WIZARD.PAGE_TITLE.CREATE_POLICY';
        }
    }
    loadPolicyData() {
        this.detailsStepData$ = this.store.select(reducers.getDetailsStepData).pipe(operators_1.first());
        this.inboundStepData$ = this.store.select(reducers.getInboundStepData).pipe(operators_1.first());
        this.outboundStepData$ = this.store.select(reducers.getOutboundStepData).pipe(operators_1.first());
        this.journalStepData$ = this.store.select(reducers.getJournalStepData).pipe(operators_1.first());
        this.userAwarenessStepData$ = this.store
            .select(reducers.getUserAwarenessStepData)
            .pipe(operators_1.first());
        this.appliesToStepData$ = this.store.select(reducers.getAppliesToStepData).pipe(operators_1.first());
        this.summaryData$ = this.store.select(reducers.getSummaryStepData).pipe(operators_1.first());
    }
    getCustomPageSetOptions() {
        this.store.dispatch(new actions.GetCustomPageSetOptionsAction());
    }
    getAddressAttributeOptions(shouldGet) {
        if (shouldGet) {
            this.store.dispatch(new actions.GetAddressAttributeOptionsAction());
        }
    }
    isStepValid() {
        if (this.currentStep === 1) {
            return this.urlProtectionPolicyDetailsStepPage.isValid();
        }
        if ((this.currentStep === 6 && this.bypassFlowIsDisabled) ||
            (this.currentStep === 2 && !this.bypassFlowIsDisabled)) {
            return (this.urlProtectionAppliesToSettingsStepComponent.isValid() && this.addressAttributeValid);
        }
        return true;
    }
    goToStep(event) {
        this.currentStep = event;
        if (this.focusToTopElement) {
            this.focusToTopElement.nativeElement.focus();
        }
    }
    editStep(stepNumber) {
        this.wizard.showStep(stepNumber);
    }
    ngOnChanges(changes) {
        const change = changes['wizardFlowType'];
        if (change && change.previousValue !== change.currentValue) {
            if (change.currentValue) {
                this.isEditMode = policy_wizard_models_1.PolicyWizardFlowType.EDIT === this.wizardFlowType;
                this.isDuplicateMode = policy_wizard_models_1.PolicyWizardFlowType.DUPLICATE === this.wizardFlowType;
                this.loadPolicyData();
            }
        }
    }
    ngOnDestroy() {
        this.bypassFlowStoreSubscription.unsubscribe();
    }
    createPolicy() {
        if (!this.isStepValid()) {
            return;
        }
        this.store.dispatch(new actions.CreatePolicyAction());
    }
    onCancel() {
        this.store.dispatch(new actions.CancelCreatePolicyAction());
    }
    editPolicy() {
        if (!this.isStepValid()) {
            return;
        }
        if (this.bypassFlowIsDisabled) {
            this.store.dispatch(new editActions.EditDefinitionPolicyAction());
        }
        else {
            this.store.dispatch(new editActions.EditPolicyAction());
        }
    }
    onAddressAttributeSelection(isSelected) {
        if (isSelected) {
            this.addressAttributeValid = !!this.addressAttributesLength;
        }
        else {
            this.addressAttributeValid = true;
        }
    }
    notifyAboutRuleEveryoneValue(method) {
        this.store
            .select(reducers.rulesContainEveryoneVale)
            .pipe(operators_1.first())
            .subscribe(ruleHasEveryoneSelected => {
            this.handleEveryoneValueModal(ruleHasEveryoneSelected, method);
        });
    }
    handleEveryoneValueModal(ruleHasEveryoneSelected, method) {
        if (ruleHasEveryoneSelected && !this.appliesToFromEveryoneModalWasOpened) {
            this.modalService
                .open(confirmation_modal_component_1.ConfirmationModalComponent, {
                data: {
                    type: policies_model_1.CONFIRMATION_DIALOG_TYPES.FROM_TO_EVERYONE,
                    wizardFlowType: null
                }
            })
                .afterClose()
                .subscribe(() => {
                this.handleEveryoneValueModalAfterClose(method);
            });
        }
        else {
            this.navigate(method);
        }
    }
    handleEveryoneValueModalAfterClose(method) {
        this.appliesToFromEveryoneModalWasOpened = true;
        this.navigate(method);
    }
    handleWizardNavigationClick(method) {
        if ((this.currentStep === 6 && this.bypassFlowIsDisabled) ||
            (this.currentStep === 2 && !this.bypassFlowIsDisabled)) {
            this.notifyAboutRuleEveryoneValue(method);
        }
        else {
            this.navigate(method);
        }
    }
    navigate(method) {
        if (method === this.navigation.edit) {
            this.editPolicy();
        }
        else if (method === this.navigation.back) {
            this.wizard.previousStep();
        }
        else if (method === this.navigation.next) {
            this.wizard.nextStep();
        }
    }
}
exports.CreateUrlProtectionPolicyWizardComponent = CreateUrlProtectionPolicyWizardComponent;
