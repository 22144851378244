"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListComponent = void 0;
const kb_urls_1 = require("../../../core/constants/kb-urls");
const store_1 = require("@ngrx/store");
const table_store_1 = require("@mimecast-ui/table-store");
const rxjs_1 = require("rxjs");
const list_impl_service_1 = require("./service/list.impl.service");
const list_static_values_1 = require("./list.static-values");
const date_range_config_1 = require("../../../message-center/common/components/table-search-configs/date-range-config");
const moment = require("moment");
const listAction = require("../actions/list.action");
const commonActions = require("../../common/actions");
const exportAction = require("../actions/export.action");
const reducers = require("../reducers");
class ListComponent extends table_store_1.TableBaseComponent {
    constructor(store, tabService, capabilitiesService, emailRoutePipe, spamDetectionPipe) {
        super(store, list_impl_service_1.ListImplService.TABLE_ID);
        this.store = store;
        this.tabService = tabService;
        this.capabilitiesService = capabilitiesService;
        this.emailRoutePipe = emailRoutePipe;
        this.spamDetectionPipe = spamDetectionPipe;
        this.externalTableSearchCount = 0;
        this.PREFIX_KEY = '$18N_BOUNCES_LIST';
        this.KB_URL = kb_urls_1.KBUrls.MESSAGE_CENTER_BOUNCES;
        this.externalTableSearchCount$ = new rxjs_1.BehaviorSubject(this.externalTableSearchCount);
        this.columnList = [...list_static_values_1.tableColumnList];
        this.exportColumnList = [...list_static_values_1.exportColumnList];
        this.columnsAlwaysVisible = [...list_static_values_1.tableColumnsAlwaysVisible];
        this.columnsToStartHidden = [...list_static_values_1.tableColumnsStartHidden];
        this.columnsToIgnore = [...list_static_values_1.tableColumnsToIgnore];
        this.filterSections = [...list_static_values_1.tableSectionFilters];
        this.dateRangePickerConfig = date_range_config_1.DateRangeConfig.config;
        this.searchSections = list_static_values_1.tableSectionSearch;
        this.bounces = [];
        this.searchFilter = {
            searchBy: { fieldName: 'all', value: '' },
            admin: true
        };
        this.canSubmitReport = false;
        this.DEFAULT_SEARCH = { selectValue: 'all', searchQuery: '' };
        this.filterBounceOptions = {};
        this.filterDateRange = {
            start: moment()
                .subtract(1, 'day')
                .format(),
            end: moment().format()
        };
        const tab = this.tabService.getSelectedTab();
        tab.onRefresh = () => {
            this.onFilterChange(this.getSearchPayloadUpdatedTime());
        };
        this.capabilitiesService.ifCapability('!Permission.SERVICE_DELIVERY_DENY_REPORT_AS_SUBMISSIONS', () => {
            this.canSubmitReport = true;
        });
    }
    ngOnInit() {
        // @ts-ignore
        this.tableDataSubscription = this.tableData$.subscribe((bounces) => {
            this.bounces = (bounces || []).map((bounce) => {
                return Object.assign(Object.assign({}, bounce), { route: this.emailRoutePipe.transform((bounce.route || '').toLowerCase()), detectionLevel: this.spamDetectionPipe.transform(bounce.detectionLevel) });
            });
        });
        this.onSearch(this.DEFAULT_SEARCH);
        this.selectedRow$ = this.store.pipe(store_1.select(reducers.getSelectedRow));
        this.capabilitiesService
            .hasCapability('Temporary.MessageCenter.SpamScore')
            .subscribe(isActive => {
            if (isActive === false) {
                const columnListFiltered = this.columnList.filter(function (value) {
                    return value !== 'spamScore' && value !== 'detectionLevel';
                });
                this.columnList = columnListFiltered;
                const exportColumnListFiltered = this.exportColumnList.filter(function (value) {
                    return value !== 'spamScore' && value !== 'spamDetection';
                });
                this.exportColumnList = exportColumnListFiltered;
            }
        });
    }
    onSearch(filter) {
        let search;
        if (filter && filter.searchQuery) {
            search = { fieldName: filter.selectValue, value: filter.searchQuery };
            this.searchFilter.searchBy = search;
        }
        else {
            search = {
                fieldName: this.DEFAULT_SEARCH.selectValue,
                value: this.DEFAULT_SEARCH.searchQuery
            };
            this.searchFilter.searchBy = search;
        }
        this.runFilterAndResetPaginator();
    }
    onColumnFilterChanged(filters) {
        const params = [];
        for (const field of Object.keys(filters)) {
            for (const filterValue of filters[field]) {
                const param = {
                    fieldName: field,
                    value: filterValue
                };
                params.push(param);
            }
        }
        if (filters) {
            this.filterBounceOptions = { filterBy: params };
        }
        this.onFilterChange(this.getSearchPayload());
    }
    onDateFilterChange(date) {
        if (!!date.range) {
            // @ts-ignore
            this.filterDateRange.start = date.range.start.format();
            // @ts-ignore
            this.filterDateRange.end = date.range.end.format();
            this.onFilterChange(this.getSearchPayload());
        }
    }
    onRowClick(row) {
        this.store.dispatch(new listAction.GetBouncedMessageInfo(row));
    }
    reportAs({ reportType, id }) {
        this.store.dispatch(new commonActions.ReportMessageOpenModalAction(reportType, id));
    }
    onExport() {
        this.store.dispatch(new exportAction.ExportAction(this.exportColumnList, this.getSearchPayload()));
    }
    getSearchPayload() {
        // @ts-ignore
        const searchPayload = Object.assign(Object.assign(Object.assign({}, this.searchFilter), this.filterDateRange), this.filterBounceOptions);
        return searchPayload;
    }
    getSearchPayloadUpdatedTime() {
        const searchPayload = Object.assign(Object.assign(Object.assign({}, this.searchFilter), this.updateDataRangeTime(this.filterDateRange)), this.filterBounceOptions);
        return searchPayload;
    }
    updateDataRangeTime(range) {
        let start = moment(range.start);
        let end = moment(range.end);
        const currentTime = moment();
        const time = {
            hour: currentTime.get('hour'),
            minute: currentTime.get('minute'),
            second: currentTime.get('second')
        };
        start = start.set(time);
        end = end.set(time);
        range.start = moment(start)
            .format()
            .toString();
        range.end = moment(end)
            .format()
            .toString();
        return range;
    }
    runFilterAndResetPaginator() {
        this.onFilterChange(this.getSearchPayload());
        this.externalTableSearchCount$.next(++this.externalTableSearchCount);
    }
    ngOnDestroy() {
        this.tableDataSubscription.unsubscribe();
        super.ngOnDestroy();
    }
}
exports.ListComponent = ListComponent;
