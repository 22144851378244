"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoggedOutModalComponent = void 0;
class LoggedOutModalComponent {
    constructor(overlayRef) {
        this.overlayRef = overlayRef;
    }
    close() {
        this.overlayRef.close(false);
    }
}
exports.LoggedOutModalComponent = LoggedOutModalComponent;
