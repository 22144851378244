"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const reducers = require("../../../../../core/reducers");
const notificationAction = require("../../../../notification/actions/notification.actions");
const actions = require("../../actions/export.actions");
const export_component_1 = require("../../component/export.component");
class ExportEffects {
    constructor(actions$, asideService, store, legacyAdconService) {
        this.actions$ = actions$;
        this.asideService = asideService;
        this.store = store;
        this.legacyAdconService = legacyAdconService;
        this.exportShow$ = this.actions$.pipe(effects_1.ofType(actions.EXPORT_SHOW), operators_1.switchMap((action) => {
            this.asideInstance = this.asideService.open(export_component_1.ExportComponent, {
                position: 'right',
                size: 'md',
                hasBackdrop: true,
                data: action.logsPageConfig
            });
            return rxjs_1.EMPTY;
        }));
        this.exportEmail$ = this.actions$.pipe(effects_1.ofType(actions.EXPORT_EMAIL), operators_1.withLatestFrom(this.store.select(reducers.getLegacySession), this.store.select(reducers.getUserProfile)), operators_1.switchMap(([action, session, user]) => {
            const exportEmailPayloadData = Object.assign(Object.assign({}, action.exportPayloadData), { SysAccountSessionId: session.SysAccountSessionId, SysWindow: 'connect', logon: user.emailAddress, accountcode: session.accountCode, sid: session.sid });
            return this.legacyAdconService
                .postSubmit('/mimecast/exportdata', exportEmailPayloadData)
                .pipe(operators_1.mergeMap(() => [
                new actions.ExportEmailSuccessAction(),
                new notificationAction.NotificationShowAction({
                    type: 'success',
                    config: { msg: "Your export has started. We'll send the file when it's ready." }
                })
            ]), operators_1.catchError(sessionData => rxjs_1.of(new actions.ExportEmailFailAction(), new notificationAction.NotificationShowAction({
                type: 'error',
                config: { msg: sessionData.message }
            }))));
        }));
        this.exportEmailSuccess$ = this.actions$.pipe(effects_1.ofType(actions.EXPORT_EMAIL_SUCCESS), operators_1.tap(() => this.asideInstance.close()));
    }
}
__decorate([
    effects_1.Effect({ dispatch: false })
], ExportEffects.prototype, "exportShow$", void 0);
__decorate([
    effects_1.Effect()
], ExportEffects.prototype, "exportEmail$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], ExportEffects.prototype, "exportEmailSuccess$", void 0);
exports.ExportEffects = ExportEffects;
