<mc-layout-modal-simple
  keyTitle="$I18N_MESSAGE_CENTER_REJECT_NARRATIVE_MESSAGE_MODAL.TITLE"
  keyDescription="$I18N_MESSAGE_CENTER_REJECT_NARRATIVE_MESSAGE_MODAL.TEXT_DESCRIPTION"
>
  <mc-body-container>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <mc-field label="$I18N_MESSAGE_CENTER_REJECT_NARRATIVE_MESSAGE_MODAL.FIELDS.REASON">
        <mc-select formControlName="reason"
           [options]="reasons"
           [useTranslation]="true"
        >
        </mc-select>
      </mc-field>

      <label for="rejectComment"
        >{{ '$I18N_MESSAGE_CENTER_REJECT_NARRATIVE_MESSAGE_MODAL.FIELDS.COMMENTS' | translate
        }}<span class="required">*</span></label
      >
      <textarea
        name="rejectComment"
        class="form-control mc-report-message-textarea"
        rows="3"
        #rejectNarrativeMessageComment
        type="text"
        formControlName="rejectNarrativeMessageComment"
      ></textarea>
      <div class="checkbox">
        <label
          ><input type="checkbox" value="" formControlName="notify" />
          <span
            translate="$I18N_MESSAGE_CENTER_REJECT_NARRATIVE_MESSAGE_MODAL.FIELDS.NOTIFY_SENDER"
          ></span>
        </label>
      </div>
      <button type="submit" hidden="true"></button>
    </form>
  </mc-body-container>
  <mc-footer-container>
    <button type="button" class="btn btn-secondary" (click)="close()">
      {{ '$I18N_MESSAGE_CENTER_REJECT_NARRATIVE_MESSAGE_MODAL.CANCEL_BUTTON' | translate }}
    </button>
    <button type="button" [disabled]="!form.valid || !canSubmit" class="btn btn-primary" (click)="onSubmit()">
      {{ '$I18N_MESSAGE_CENTER_REJECT_NARRATIVE_MESSAGE_MODAL.SUBMIT_BUTTON' | translate }}
    </button>
  </mc-footer-container>
</mc-layout-modal-simple>
