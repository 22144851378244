'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const _ = require("lodash");
/**
 * Created by psambandam on 23/02/16.
 */
angular.module('mega.menu.service', [])
    .factory('megaMenuService', ['$q', 'accountService', 'capabilitiesService', function ($q, accountService, capabilitiesService) {
        let menuGroups;
        const d = $q.defer();
        let data = {};
        return {
            getMenuGroups,
            setMenuGroups,
            searchMenu,
            onReady,
            findSearchMenuByState,
            getData
        };
        function findSearchMenuByState(stateName, stateParams) {
            const state = { name: stateName };
            state.params = angular.isDefined(stateParams) ? stateParams : {};
            let menuItem;
            let groupItem;
            _.forEach(menuGroups, function (menuGroup) {
                groupItem = menuGroup;
                menuItem = _.find(menuGroup.menuItems, function (menuItemParam) {
                    return (state.name + (state.params.uid || '')) === (menuItemParam.state.name + (menuItemParam.state.params && menuItemParam.state.params.uid ? menuItemParam.state.params.uid : ''));
                });
                return !menuItem;
            });
            return menuItem && { group: groupItem, item: menuItem, parent: menuGroups.parent };
        }
        function onReady(callback) {
            getMenuGroups().then(function () {
                callback();
            });
        }
        function getMenuGroups() {
            return d.promise;
        }
        function setMenuGroups(_menuGroups) {
            menuGroups = _menuGroups;
            d.resolve(menuGroups);
        }
        function searchMenu(term) {
            term = term.toLowerCase();
            const matches = [];
            _.forEach(menuGroups, function (menuGroup) {
                const groupMatched = menuGroup.label && menuGroup.label.toLowerCase().indexOf(term) != -1;
                _.forEach(menuGroup.menuItems, function (menuItem) {
                    const itemMatched = groupMatched ||
                        menuItem.label && menuItem.label.toLowerCase().indexOf(term) != -1 ||
                        menuItem.subLabel && menuItem.subLabel.toLowerCase().indexOf(term) != -1 ||
                        menuItem.description && menuItem.description.toLowerCase().indexOf(term) != -1 ||
                        menuItem.searchKeys && menuItem.searchKeys.toLowerCase().indexOf(term) != -1;
                    //if mc-cap is not defined take caps as "true", if mc-cap defined, evaluate caps.
                    if (itemMatched && ((menuItem.caps) ? hasCap(menuItem.caps) : true)) {
                        const item = _.clone(menuItem);
                        item.groupLabel = menuGroup.label;
                        matches.push(item);
                    }
                });
            });
            return matches;
        }
        function hasCap(cap) {
            return (capabilitiesService.areCapabilitiesLoaded() && angular.isDefined(cap) && capabilitiesService.evalCapabilitiesExpression(cap));
        }
        function getData() {
            return accountService.getAccount().then(function (response) {
                data = response.first;
                return data;
            });
        }
    }]);
