"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TabGroupComponent = void 0;
const core_1 = require("@angular/core");
class TabGroupComponent {
    constructor(cdr) {
        this.cdr = cdr;
        this._initialActiveTabIndex = 0;
        this.themeLight = false;
        this.resetActiveTabToFirst = false;
        this.tabChange = new core_1.EventEmitter();
    }
    set initialActiveTabIndex(value) {
        const valueNumber = Number(value || 0);
        if (isNaN(valueNumber)) {
            throw new Error(`mc-tab-group incorrect value used for activeTabIndex: ${value}`);
        }
        this._initialActiveTabIndex = valueNumber;
    }
    get initialActiveTabIndex() {
        return this._initialActiveTabIndex;
    }
    ngAfterContentInit() {
        this.selectInitialActiveTabIndex();
        this.tabsSubscription = this.tabs.changes.subscribe(() => {
            const newActiveTab = this.resetActiveTabToFirst
                ? this.tabs.first
                : this.getActiveTab() || this.tabs.first;
            this.resetActiveTabToFirst = false;
            this.selectTab(newActiveTab);
            this.cdr.markForCheck();
        });
    }
    selectInitialActiveTabIndex() {
        if (this.tabs.length &&
            this._initialActiveTabIndex >= 0 &&
            this._initialActiveTabIndex < this.tabs.length) {
            const tab = this.tabs.find((item, index) => index === this._initialActiveTabIndex);
            if (!tab.disabled) {
                this.selectTab(tab);
            }
            else if (this._initialActiveTabIndex + 1 < this.tabs.length) {
                this._initialActiveTabIndex++;
                this.selectInitialActiveTabIndex();
            }
        }
        else {
            throw new Error(`mc-tab-group requires one or more mc-tab children and the initial active tab index must be in the valid index range.
        Number of mc-tab children: ${this.tabs.length}, initial active tab index: ${this._initialActiveTabIndex}.`);
        }
    }
    getActiveTab() {
        return this.tabs.find(tab => tab.active);
    }
    selectTab(tabToSelect) {
        if (tabToSelect && !tabToSelect.disabled) {
            this.tabs.toArray().forEach(tab => tab.setActive(false));
            tabToSelect.setActive(true);
            this.tabChange.emit(tabToSelect);
            this.cdr.markForCheck();
        }
    }
    ngOnDestroy() {
        this.tabsSubscription.unsubscribe();
    }
}
exports.TabGroupComponent = TabGroupComponent;
