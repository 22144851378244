"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgPolicyEditSideBarContainerComponent = void 0;
const categories = require("app-new/swg/policies/actions/categories.actions");
const sidebar_actions_1 = require("app-new/swg/policies/actions/sidebar.actions");
const policy_1 = require("app-new/swg/policies/models/policy");
const reducers = require("../../reducers");
const policyActions = require("../../actions/policy.actions");
const sidebar = require("../../actions/sidebar.actions");
const operators_1 = require("rxjs/operators");
const notification_actions_1 = require("../../../../components/notification/actions/notification.actions");
class SwgPolicyEditSideBarContainerComponent {
    constructor($state, store, swgService, capabilitiesService) {
        this.$state = $state;
        this.store = store;
        this.swgService = swgService;
        this.capabilitiesService = capabilitiesService;
        this.hasBrowserIsolationEnabled = false;
    }
    ngOnInit() {
        this.policy$ = this.store.select(reducers.getSwgPolicy);
        this.webCategoryRulesSections$ = this.store.select(reducers.getWebCategoryRulesSections);
        this.categorySectionsLoading$ = this.store.select(reducers.getCategorySectionLoading);
        this.loadingForEdit$ = this.store.select(reducers.getLoadingForEdit);
        this.applications$ = this.store.select(reducers.getApplications);
        this.hasBrowserIsolationEnabled = this.swgService.hasBrowserIsolationEnabled();
        this.policy$.pipe(operators_1.first()).subscribe(val => {
            if (val.policyDesc.policyType !== policy_1.SwgPolicyType.BROWSER_ISOLATION &&
                val.policyDesc.policyType !== policy_1.SwgPolicyType.CLOUD_PROTECT_POLICY) {
                this.store.dispatch(new categories.SwgPolicyLoadCategorySections());
            }
            if (val.policyDesc.policyType === policy_1.SwgPolicyType.CLOUD_PROTECT_POLICY) {
                return this.store.dispatch(new policyActions.CLPPolicyCheckForConnector(val.policyDesc, policy_1.PolicyAction.DETAILS));
            }
            return this.store.dispatch(new policyActions.SwgLoadPolicyToEdit(val.policyDesc));
        });
        this.hasPolicyEditPermission$ = this.capabilitiesService.hasCapability('Permission.SWG_CONFIGURE_POLICIES_EDIT');
        this.hasBiPolicyEditPermission$ = this.capabilitiesService.hasCapability('Permission.BI_CONFIGURE_POLICIES_EDIT');
        this.hasSwgHybrid$ = this.capabilitiesService.hasCapability('SWG.Hybrid.Proxy');
        this.hasSwgHybridCaps = this.swgService.hasHybridProxySupport();
        this.hasAppPolicyRemoveAllowEnabled = this.swgService.hasAppControlPolicyAllowRemoved();
        this.protectedApplications$ = this.store.select(reducers.getProtectedApplications);
    }
    deletePolicy($event) {
        this.store.dispatch(new sidebar.SwgDetailDeleteConfirmModelOpen($event.policyDesc));
    }
    editPolicy() {
        this.store.dispatch(new sidebar.SwgDetailEditSidebarClose());
        this.store.dispatch(new sidebar_actions_1.SwgPolicyNavigateToEditPageAction(policy_1.SwgPolicyWizardFlowType.EDIT));
    }
    duplicatePolicy() {
        this.store.dispatch(new sidebar.SwgDetailEditSidebarClose());
        this.store.dispatch(new sidebar_actions_1.SwgPolicyNavigateToEditPageAction(policy_1.SwgPolicyWizardFlowType.DUPLIATE));
    }
    close() {
        this.store.dispatch(new sidebar.SwgDetailEditSidebarClose());
    }
    showErrorNotification(msg) {
        this.store.dispatch(new notification_actions_1.NotificationShowAction({
            type: 'error',
            config: {
                msg
            }
        }));
    }
    onDisablePolicy(swgPolicyDesc) {
        this.store.dispatch(new sidebar.SwgDetailDisableConfirmModelOpen(swgPolicyDesc));
    }
}
exports.SwgPolicyEditSideBarContainerComponent = SwgPolicyEditSideBarContainerComponent;
