"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogDetailsService = void 0;
const operators_1 = require("rxjs/operators");
class LogDetailsService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    getLogDetails(id) {
        return this.httpClient
            .post('/api/ttp/impersonation/get-log', { id })
            .pipe(operators_1.map(response => response.first));
    }
}
exports.LogDetailsService = LogDetailsService;
