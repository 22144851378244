"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PurgeMessagesConfirmModalComponent = void 0;
const components_1 = require("@mimecast-ui/components");
const reducers_1 = require("../../reducers");
const forms_1 = require("@angular/forms");
class PurgeMessagesConfirmModalComponent {
    constructor(overlayRef, fb, store) {
        this.overlayRef = overlayRef;
        this.fb = fb;
        this.store = store;
        this.form = this.fb.group({
            reason: [null, [components_1.minLengthTrimmed(6), forms_1.Validators.required, forms_1.Validators.maxLength(255)]]
        });
    }
    ngOnInit() {
        this.purgeMessagesCount$ = this.store.select(reducers_1.getPurgeMessagesCount);
    }
    onPurge() {
        if (this.form.valid) {
            this.overlayRef.close({ reason: this.form.value.reason, submitted: true });
        }
    }
    close() {
        this.overlayRef.close('cancel');
    }
}
exports.PurgeMessagesConfirmModalComponent = PurgeMessagesConfirmModalComponent;
