"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AffectedUsersByUserSidebarComponent = void 0;
const forms_1 = require("@angular/forms");
const operators_1 = require("rxjs/operators");
const reducers = require("../reducers");
const applies_to_users_actions_1 = require("../actions/applies-to-users.actions");
const user_location_sidebar_actions_1 = require("../actions/user-location-sidebar.actions");
class AffectedUsersByUserSidebarComponent {
    constructor(store, fb, overlayData) {
        this.store = store;
        this.fb = fb;
        this.overlayData = overlayData;
        this.placeholder = '$I18N_SWG_CREATE_POLICY_POLICY_USER_SEARCHBOX_PLACEHOLDER';
        this.paginator = {
            pageToken: null,
            pageSize: 20,
            pageNumber: 0
        };
        this.separator = ' - ';
        this.pageIterator = 0;
        this.searchQuery = null;
        this.destroy = false;
        this._selectedUser = {};
    }
    ngOnInit() {
        this.fetchUsers();
        this.users$ = this.store.select(reducers.getUsers);
        this.pagination$ = this.store.select(reducers.getUsersPagination);
        this.apiIsProcessing$ = this.store.select(reducers.getUsersLoading);
        this.usersForm = this.fb.group({
            searchQuery: ['', forms_1.Validators.required]
        });
        this.usersFormSubscription = this.usersForm.valueChanges
            .pipe(operators_1.takeWhile(() => !this.destroy))
            .subscribe((newValue) => {
            this.searchQuery = newValue.searchQuery === '' ? null : newValue.searchQuery;
            if (this.searchQuery) {
                this.pageIterator = 0;
                this.store.dispatch(new applies_to_users_actions_1.LoadAppliesToUsersPaginationReset());
                const payload = {
                    meta: { pagination: this.paginator },
                    // @ts-ignore
                    data: this.searchQuery
                };
                this.store.dispatch(new applies_to_users_actions_1.LoadAppliesToUsers(payload));
            }
            else {
                this.fetchUsers();
            }
        });
    }
    fetchUsers() {
        this.store.dispatch(new applies_to_users_actions_1.LoadAppliesToUsers({
            meta: { pagination: this.paginator },
            data: null
        }));
    }
    selectUser(row) {
        this._selectedUser = row;
        this.store.dispatch(this.overlayData.action(row));
    }
    close() {
        this.store.dispatch(new user_location_sidebar_actions_1.UserLocationAppliesToClose());
    }
    onPaginate(paginatorData) {
        const pageIndex = paginatorData.direction === 1
            ? (this.pageIterator += this.paginator.pageSize)
            : (this.pageIterator -= this.paginator.pageSize);
        const paginatorOutput = {
            pageToken: paginatorData.token,
            pageSize: this.paginator.pageSize,
            pageNumber: pageIndex
        };
        const payload = {
            meta: { pagination: paginatorOutput },
            // @ts-ignore
            data: this.searchQuery
        };
        this.store.dispatch(new applies_to_users_actions_1.LoadAppliesToUsersPagination(payload));
    }
    ngOnDestroy() {
        this.store.dispatch(new applies_to_users_actions_1.ClearAppliesToUsers());
        this.destroy = true;
        this.pageIterator = 0;
    }
}
exports.AffectedUsersByUserSidebarComponent = AffectedUsersByUserSidebarComponent;
