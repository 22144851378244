<mc-layout-aside-extra-container
  keyTitle="$I18N_CONNECTOR_DETAILS.TITLE"
  showClose="true"
  (closeAside)="closeAside()"
  [isLoading]="loadingDetails$ | async"
>
  <mc-extra-container>
    <div class="mc-aside-header">
      <span class="mc-aside-sub-header">{{ (connectorDetails$ | async)?.name }}</span>

      <mc-paginator
        class="mc-subject-inline-paginator"
        *ngIf="pagination$ | async"
        [start]="(pagination$ | async).currentIndex + 1"
        [end]="(pagination$ | async).pageSize"
        [isLoading]="loadingDetails$ | async"
        [previousToken]="(pagination$ | async).prevRow"
        [nextToken]="(pagination$ | async).nextRow"
        (paginate)="showMessage($event)"
      >
      </mc-paginator>
    </div>
    <div class="mc-aside-button-wrapper" *mcCapabilities="'Permission.CONNECTORS_EDIT || Permission.SERVER_CONNECTIONS_EDIT'">
      <button class="btn btn-primary" (click)="editConnector()">
        {{ '$I18N_CONNECTOR_DETAILS.EDIT' | translate }}
      </button>
      <button class="btn btn-secondary" (click)="deleteConnector()">
        {{ '$I18N_CONNECTOR_DETAILS.DELETE' | translate }}
      </button>
    </div>
  </mc-extra-container>

  <mc-body-container>
    <div class="mc-detailed-list mc-detailed-list-three-columns">
      <div class="row mc-detailed-list-row">
        <div class="col-sm-3 mc-detailed-list-label">
          {{ '$I18N_CONNECTOR_DETAILS.NAME' | translate }}
        </div>
        <div class="mc-detailed-list-column">{{ (connectorDetails$ | async)?.name }}</div>
      </div>
      <div class="row mc-detailed-list-row">
        <div class="col-sm-3 mc-detailed-list-label">
          {{ '$I18N_CONNECTOR_DETAILS.DESCRIPTION' | translate }}
        </div>
        <div class="mc-detailed-list-column">{{ (connectorDetails$ | async)?.description }}</div>
      </div>
      <div class="row mc-detailed-list-row">
        <div class="col-sm-3 mc-detailed-list-label">
          {{ '$I18N_CONNECTOR_DETAILS.STATUS' | translate }}
        </div>
        <div class="mc-detailed-list-column">
          <mc-connector-status
            [connectorStatus]="(connectorDetails$ | async)?.status"
          ></mc-connector-status>
          <span>
            {{
              '$I18N_CONNECTORS_TABLE.CONNECTION_STATUS.' + (connectorDetails$ | async)?.status
                | uppercase
                | translate
            }}
          </span>
          <div *ngIf="(connectorDetails$ | async)?.statusCheckErrors.length">
            <span [innerHTML]="'$I18N_CONNECTOR_DETAILS.' + (connectorDetails$ | async)?.statusCheckErrors[0]
                | uppercase
                | translate: { provider: translatedProvider }"></span>
            <a
              [href]="supportLink$ | async"
              target="_blank"
              rel="noopener noreferrer"
              >{{ '$I18N_CONNECTOR_DETAILS.CUSTOMER_SERVICE' | translate }}</a
            >.
          </div>
        </div>
      </div>
      <div class="row mc-detailed-list-row">
        <div class="col-sm-3 mc-detailed-list-label">
          {{ '$I18N_CONNECTOR_DETAILS.PRODUCT' | translate }}
        </div>
        <div class="mc-detailed-list-column">{{ (connectorDetails$ | async)?.product?.name }}</div>
      </div>
      <div class="row mc-detailed-list-row">
        <div class="col-sm-3 mc-detailed-list-label">
          {{ '$I18N_CONNECTOR_DETAILS.PROVIDER' | translate }}
        </div>
        <div class="mc-detailed-list-column">
          {{ translatedProvider }}
        </div>
      </div>
      <div class="row mc-detailed-list-row">
        <div class="col-sm-3 mc-detailed-list-label">
          {{ '$I18N_CONNECTOR_DETAILS.CREATED_BY' | translate }}
        </div>
        <div class="mc-detailed-list-column">{{ (connectorDetails$ | async)?.createdBy }}</div>
      </div>
      <div class="row mc-detailed-list-row">
        <div class="col-sm-3 mc-detailed-list-label">
          {{ '$I18N_CONNECTOR_DETAILS.DATE_CREATED' | translate }}
        </div>
        <div class="mc-detailed-list-column">{{ (connectorDetails$ | async)?.created }}</div>
      </div>
    </div>
  </mc-body-container>
</mc-layout-aside-extra-container>
