"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchWaitingExperienceEffects = void 0;
const effects_1 = require("@ngrx/effects");
const operators_1 = require("rxjs/operators");
const searchAction = require("../../actions/search/search.action");
const waiting_experience_modal_component_1 = require("../../components/waiting-experience-modal/waiting-experience-modal.component");
class SearchWaitingExperienceEffects {
    constructor(actions$, modalService) {
        this.actions$ = actions$;
        this.modalService = modalService;
        this.searchWaitingExperienceOpen = this.actions$.pipe(effects_1.ofType(searchAction.REMEDIATION_SEARCH_RUN), operators_1.tap((action) => {
            this.loadingModalInstance = this.modalService.open(waiting_experience_modal_component_1.WaitingExperienceModalComponent, {
                data: { searchWithoutHashPermission: action.hasSearchWithoutHashPermission },
                disableClose: true,
                lightBackdrop: true,
                size: 'md',
                hasBackdrop: true
            });
        }));
        this.searchWaitingExperienceClose = this.actions$.pipe(effects_1.ofType(searchAction.REMEDIATION_SEARCH_SUCCESS, searchAction.REMEDIATION_SEARCH_FAILURE), operators_1.tap(() => {
            this.loadingModalInstance.close();
        }));
    }
}
__decorate([
    effects_1.Effect({ dispatch: false })
], SearchWaitingExperienceEffects.prototype, "searchWaitingExperienceOpen", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], SearchWaitingExperienceEffects.prototype, "searchWaitingExperienceClose", void 0);
exports.SearchWaitingExperienceEffects = SearchWaitingExperienceEffects;
