<div class="mc-api-application-step-block">
  <mc-wizard-step-header [label]="'$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.AUTHORIZATION.HEADER'">
  </mc-wizard-step-header>

  <div class="row form-group panel-padding-left">
    <div class="panel-padding-bottom">
      <strong>{{
        '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.AUTHORIZATION.STEP_01' | translate
      }}</strong>
    </div>
    <div class="panel-padding-bottom">
      {{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.AUTHORIZATION.STEP_02' | translate }}
    </div>
    <div class="panel-padding-bottom">
      <mc-inline-notification
        [notification]="{
          status: 'info',
          boxed: false,
          msg: '$I18N_API_INTEGRATION_CORTEX_WIZARD.MANDATORY_NEW_WINDOW' | translate
        }"
      >
      </mc-inline-notification>
    </div>
    <div class="panel-padding-top" *ngIf="showAuthorisationWarning">
      <mc-inline-notification
        [notification]="{
          status: 'error',
          boxed: true,
          msg:
            '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.AUTHORIZATION.INTEGRATION_NOT_AUTHORISED_ERROR_MESSAGE'
            | translate
        }"
      >
      </mc-inline-notification>
    </div>
  </div>
</div>
