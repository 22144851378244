"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiIntegrationSelectComponent = void 0;
const reducers = require("app-new/api-applications/reducers");
const actions = require("app-new/api-applications/actions");
class ApiIntegrationSelectComponent {
    constructor(store) {
        this.store = store;
    }
    ngOnInit() {
        this.configuredProviders$ = this.store.select(reducers.getIntegrationCreateConfiguredProviders);
        this.selectedProvider$ = this.store.select(reducers.getIntegrationCreateSelectedProvider);
    }
    providerSelected(provider) {
        this.store.dispatch(new actions.SelectIntegrationProviderAction({ provider }));
    }
    cancel() {
        this.store.dispatch(new actions.CancelCreateIntegrationAction());
    }
    gotoNextStep(provider) {
        if (provider === 'cortex') {
            this.store.dispatch(new actions.StartIntegrationCreateCortexAction());
        }
        else if (provider === 'wildfire') {
            this.store.dispatch(new actions.StartIntegrationWildfireAction({ runChecks: true }));
        }
        else if (provider === 'crowdstrike') {
            this.store.dispatch(new actions.StartIntegrationCrowdstrikeAction());
        }
        else if (provider === 'scim_okta') {
            this.store.dispatch(new actions.StartIntegrationOktaAction());
        }
        else if (provider === 'okta_evidence_based_control') {
            this.store.dispatch(new actions.StartIntegrationOktaEvidenceBasedControlAction());
        }
    }
}
exports.ApiIntegrationSelectComponent = ApiIntegrationSelectComponent;
