<div class="mc-device-group-summary-step">
  <mc-wizard-step-header [label]="getTitle()">
  </mc-wizard-step-header>

  <div class="mc-detailed-list">
    <div class="row mc-detailed-list-row no-padding-top">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_DEVICE_GROUPS_LIST.WIZARD.STEPS.SUMMARY.NAME' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ deviceGroup.name }}</div>
    </div>

    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_DEVICE_GROUPS_LIST.WIZARD.STEPS.SUMMARY.DESCRIPTION' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{ deviceGroup.description ? deviceGroup.description : '-'  }}
      </div>
    </div>

    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_DEVICE_GROUPS_LIST.WIZARD.STEPS.SUMMARY.DEVICE_COUNT' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ deviceCount }}</div>
    </div>
  </div>
</div>

