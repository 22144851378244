"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
class FolderHelper {
    static createDescription(folders, rootFolderId) {
        if (!folders) {
            return '';
        }
        const desc = 'Root / ';
        const selectedFolder = folders.find(folder => folder.reference === rootFolderId);
        if (!selectedFolder) {
            return '';
        }
        const parentFolder = folders.find(folder => folder.reference === selectedFolder.parent);
        return !parentFolder
            ? desc.concat(selectedFolder.description)
            : desc.concat(parentFolder.description, ' / ', selectedFolder.description);
    }
    static findFolder(node, id) {
        if (node.id === id) {
            return node;
        }
        if (node.folders && node.folders.length > 0) {
            return node.folders.find((folder) => !!this.findFolder(folder, id));
        }
        return null;
    }
    static findChildren(folders, parentId) {
        const children = folders.filter(f => f.parentId === parentId);
        if (children.length === 0) {
            return null;
        }
        return children.map(f => (Object.assign(Object.assign({}, f), { folders: this.findChildren(folders, f.id) })));
    }
    static arrayToTree(folders) {
        const rootFolder = folders[0];
        return Object.assign(Object.assign({}, rootFolder), { folders: this.findChildren(folders, rootFolder.id) });
    }
    static flatTree(rootFolder) {
        if (!rootFolder) {
            return [];
        }
        if (!rootFolder.folders) {
            return [rootFolder];
        }
        return _.flatten([rootFolder, _.flatten(rootFolder.folders)]);
    }
    static uniqueFolderList(existingFolders, newFolders) {
        let unique = [...existingFolders, ...newFolders];
        if (existingFolders && existingFolders[0] && existingFolders[0].folders) {
            unique = [...unique, ...existingFolders[0].folders];
        }
        for (let i = 0; i < unique.length; ++i) {
            for (let j = i + 1; j < unique.length; ++j) {
                if (unique[i].parentId === unique[j].parentId &&
                    unique[i].description === unique[j].description) {
                    unique.splice(j--, 1);
                }
            }
        }
        [...unique].forEach(function (folder, i) {
            if (folder && folder.description === '' && folder.isNewFolderEntry) {
                unique.splice(i, 1); // removing empty folder
                unique.splice(1, 0, folder); // adding empty folder to 2nd position
            }
            if (!folder) {
                unique.splice(i, 1);
            }
        });
        return unique;
    }
    static createFolderStructureForRequest(existingFolders, id, description) {
        const rootFolder = {
            reference: existingFolders.id,
            description: existingFolders.description,
            parent: null
        };
        const folderStructure = [rootFolder];
        if (rootFolder.reference !== id) {
            const selectedFolder = existingFolders.folders.find(folder => folder.id === id && folder.description === description);
            if (selectedFolder) {
                folderStructure.push({
                    reference: selectedFolder.id,
                    description: selectedFolder.description,
                    parent: selectedFolder.parentId
                });
            }
        }
        return folderStructure;
    }
    static createFolderStructureFromResponse(folders) {
        if (!folders) {
            return [];
        }
        return folders.map(folder => {
            return { reference: folder.id, description: folder.description, parent: folder.parentId };
        });
    }
}
exports.default = FolderHelper;
