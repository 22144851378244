"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SaveDescriptionAction = exports.SaveNameAction = exports.SAVE_DESCRIPTION = exports.SAVE_NAME = void 0;
exports.SAVE_NAME = '[IdentityPolicyWizard DetailsStep] Save Name';
exports.SAVE_DESCRIPTION = '[IdentityPolicyWizard DetailsStep] Save Description';
class SaveNameAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SAVE_NAME;
    }
}
exports.SaveNameAction = SaveNameAction;
class SaveDescriptionAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SAVE_DESCRIPTION;
    }
}
exports.SaveDescriptionAction = SaveDescriptionAction;
