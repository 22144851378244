"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImpersonationLogResultPipe = void 0;
class ImpersonationLogResultPipe {
    constructor(translateService) {
        this.translateService = translateService;
    }
    transform(value) {
        return this.getResultText(value);
    }
    getResultText(impersonationResult) {
        switch (impersonationResult.impersonationDomainSource) {
            case 'similar_internal_domain':
            case 'advanced_similar_internal_domain':
                return this.getSimilarInternalDomainText(impersonationResult);
            case 'custom_external_domain':
            case 'similar_monitored_external_domain':
            case 'advanced_custom_external_domain':
                return this.getSimilarCustomMonitoredExternalDomainText(impersonationResult);
            case 'mimecast_external_domain':
            case 'advanced_mimecast_external_domain':
                return this.getSimilarMimecastMonitoredExternalDomainText(impersonationResult);
            case 'internal_user_name':
                return this.getInternalUserNameText(impersonationResult);
            case 'custom_name_list':
                return this.getCustomUserNameText(impersonationResult);
            case 'reply_address_mismatch':
                return this.getReplyAddressMismatchText(impersonationResult);
            case 'newly_observed_domain':
                return this.getNewlyObservedDomainText(impersonationResult);
            case 'targeted_threat_dictionary':
                return this.getTargetedThreatText(impersonationResult);
            default:
                return '';
        }
    }
    getSimilarInternalDomainText(impersonationResult) {
        this.prefix = this.translateService.instant('$I18N_IMPERSONATION_LOG_RESULT.SIMILAR_DOMAIN_PREFIX');
        this.main = this.translateService.instant('$I18N_IMPERSONATION_LOG_RESULT.SIMILAR_INTERNAL_DOMAIN_MAIN');
        return `${this.prefix} ${impersonationResult.stringSimilarToDomain} ${this.main} ${impersonationResult.similarDomain}.`;
    }
    getSimilarCustomMonitoredExternalDomainText(impersonationResult) {
        this.prefix = this.translateService.instant('$I18N_IMPERSONATION_LOG_RESULT.SIMILAR_DOMAIN_PREFIX');
        this.main = this.translateService.instant('$I18N_IMPERSONATION_LOG_RESULT.SIMILAR_CUSTOM_MONITORED_EXTERNAL_DOMAIN_MAIN');
        return `${this.prefix} ${impersonationResult.stringSimilarToDomain} ${this.main} ${impersonationResult.similarDomain}.`;
    }
    getSimilarMimecastMonitoredExternalDomainText(impersonationResult) {
        this.prefix = this.translateService.instant('$I18N_IMPERSONATION_LOG_RESULT.SIMILAR_DOMAIN_PREFIX');
        this.main = this.translateService.instant('$I18N_IMPERSONATION_LOG_RESULT.SIMILAR_MIMECAST_MONITORED_EXTERNAL_DOMAIN_MAIN');
        return `${this.prefix} ${impersonationResult.stringSimilarToDomain} ${this.main} ${impersonationResult.similarDomain}.`;
    }
    getInternalUserNameText(impersonationResult) {
        this.prefix = this.translateService.instant('$I18N_IMPERSONATION_LOG_RESULT.INTERNAL_USER_NAME_PREFIX');
        const similarDomain = impersonationResult.similarDomain
            .replace('<', '&lt;')
            .replace('>', '&gt;');
        if (impersonationResult.enhancedResults === undefined ||
            impersonationResult.enhancedResults.length === 0) {
            return `${this.prefix} ${similarDomain}.`;
        }
        else {
            return this.getEnhancedResults(`${this.prefix} ${similarDomain}.`, impersonationResult);
        }
    }
    getCustomUserNameText(impersonationResult) {
        this.prefix = this.translateService.instant('$I18N_IMPERSONATION_LOG_RESULT.CUSTOM_NAME_LIST_PREFIX');
        if (impersonationResult.enhancedResults === undefined ||
            impersonationResult.enhancedResults.length === 0) {
            return `${this.prefix}.`;
        }
        else {
            return this.getEnhancedResults(`${this.prefix}.`, impersonationResult);
        }
    }
    getEnhancedResults(prefix, impersonationResult) {
        this.result = `${prefix}`;
        const baseKeyPath = '$I18N_IMPERSONATION_LOG_RESULT.ENHANCED_RESULTS_KEYS.';
        const baseValuePath = '$I18N_IMPERSONATION_LOG_RESULT.ENHANCED_RESULTS_KEY_VALUES.';
        impersonationResult.enhancedResults.forEach(key => {
            const domainSource = this.translateService.instant(baseKeyPath + `${key.impersonationDomainSource}`.toUpperCase());
            const checkerResult = this.translateService.instant(baseValuePath + `${key.checkerResult}`.replace(/\s/g, '').toUpperCase());
            this.line = `<b> ${domainSource}</b>: ${checkerResult}`;
            this.result = `${this.result}\n${this.line}`;
        });
        return this.result;
    }
    getReplyAddressMismatchText(impersonationResult) {
        this.prefix = this.translateService.instant('$I18N_IMPERSONATION_LOG_RESULT.REPLY_ADDRESS_MISMATCH_PREFIX');
        this.main = this.translateService.instant('$I18N_IMPERSONATION_LOG_RESULT.REPLY_ADDRESS_MISMATCH_MAIN');
        return `${this.prefix} ${impersonationResult.similarDomain} ${this.main} ${impersonationResult.stringSimilarToDomain}.`;
    }
    getNewlyObservedDomainText(impersonationResult) {
        this.main = this.translateService.instant('$I18N_IMPERSONATION_LOG_RESULT.NEWLY_OBSERVED_DOMAIN_MAIN');
        return `${impersonationResult.similarDomain} ${this.main}.`;
    }
    getTargetedThreatText(impersonationResult) {
        if (impersonationResult.stringSimilarToDomain.split(',').length > 1) {
            this.prefix = this.translateService.instant('$I18N_IMPERSONATION_LOG_RESULT.TARGETED_THREAT_DICTIONARY_PREFIX_MULTIPLE_TERMS');
        }
        else {
            this.prefix = this.translateService.instant('$I18N_IMPERSONATION_LOG_RESULT.TARGETED_THREAT_DICTIONARY_PREFIX_SINGLE_TERM');
        }
        return `${this.prefix} ${this.constructTermsText(impersonationResult.stringSimilarToDomain)}.`;
    }
    constructTermsText(terms) {
        const termsItems = terms.split(',');
        let termsText = '';
        termsItems.forEach((term, index) => {
            if (index > 0) {
                if (index === termsItems.length - 1) {
                    termsText += ` ${this.translateService.instant('$I18N_IMPERSONATION_LOG_RESULT.AND')} `;
                }
                else {
                    termsText += ', ';
                }
            }
            termsText += `\'${term.trim()}\'`;
        });
        return termsText;
    }
}
exports.ImpersonationLogResultPipe = ImpersonationLogResultPipe;
