"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DashboardSettingsEffect = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const dashboard_settings_factory_1 = require("../factories/dashboard-settings.factory");
const notification_actions_1 = require("app-new/components/notification/actions/notification.actions");
const dashboard_settings_actions_1 = require("../actions/dashboard-settings.actions");
const queue_type_enum_1 = require("../types/queue-type.enum");
class DashboardSettingsEffect {
    constructor(actions$, dashboardService) {
        this.actions$ = actions$;
        this.dashboardService = dashboardService;
        this.getDashboardSettings$ = this.actions$.pipe(effects_1.ofType(dashboard_settings_actions_1.DashboardSettingsActions.GET_DASHBOARD_SETTINGS), operators_1.mergeMap((action) => {
            return this.dashboardService.getDashboardSettings(action.queueType).pipe(operators_1.map(response => {
                if (response.hasErrors) {
                    return new dashboard_settings_actions_1.GetDashboardSettingsFailAction(response.fail[0]);
                }
                const settings = response.all.length === 0
                    ? undefined
                    : dashboard_settings_factory_1.DashboardSettingsFactory.BUILD_SETTINGS(response.first);
                return new dashboard_settings_actions_1.GetDashboardSettingsSuccessAction(settings);
            }), operators_1.catchError(response => {
                return rxjs_1.of(new dashboard_settings_actions_1.GetDashboardSettingsFailAction(response.fail[0]));
            }));
        }));
        this.getDashboardSettingsFail$ = this.actions$.pipe(effects_1.ofType(dashboard_settings_actions_1.DashboardSettingsActions.GET_DASHBOARD_SETTINGS_FAIL), operators_1.map((action) => action.payload && action.payload.errors), operators_1.switchMap((errors) => {
            if (!errors) {
                return rxjs_1.EMPTY;
            }
            return errors.map(error => new notification_actions_1.NotificationShowFailAction(error.message));
        }));
        this.setDashboardSettings$ = this.actions$.pipe(effects_1.ofType(dashboard_settings_actions_1.DashboardSettingsActions.SET_DASHBOARD_SETTINGS), operators_1.switchMap((action) => {
            const settings = this.toApiSettings(action.settings);
            return this.dashboardService.setDashboardSettings(settings).pipe(operators_1.map(response => {
                if (response.hasErrors) {
                    return new dashboard_settings_actions_1.SetDashboardSettingsFailAction(response.fail[0]);
                }
                const newSettings = response.all.length === 0
                    ? undefined
                    : dashboard_settings_factory_1.DashboardSettingsFactory.BUILD_SETTINGS(response.first);
                return new dashboard_settings_actions_1.SetDashboardSettingsSuccessAction(newSettings);
            }), operators_1.catchError(response => {
                return rxjs_1.of(new dashboard_settings_actions_1.SetDashboardSettingsFailAction(response.fail[0]));
            }));
        }));
        this.setDashboardSettingsFail$ = this.actions$.pipe(effects_1.ofType(dashboard_settings_actions_1.DashboardSettingsActions.SET_DASHBOARD_SETTINGS_FAIL), operators_1.map((action) => action.payload && action.payload.errors), operators_1.switchMap((errors) => {
            if (!errors || errors.length === 0) {
                return rxjs_1.EMPTY;
            }
            return rxjs_1.of(new notification_actions_1.NotificationShowFailAction('$I18N_ARCHIVE_SUPERVISION_DASHBOARD_SETTINGS_ASIDE.NOTIFICATION.FAILURE'));
        }));
        this.setDashboardSettingsSuccess$ = this.actions$.pipe(effects_1.ofType(dashboard_settings_actions_1.DashboardSettingsActions.SET_DASHBOARD_SETTINGS_SUCCESS), operators_1.map(() => {
            return new notification_actions_1.NotificationShowSuccessAction('$I18N_ARCHIVE_SUPERVISION_DASHBOARD_SETTINGS_ASIDE.NOTIFICATION.SUCCESS');
        }));
    }
    toApiSettings(settings) {
        if (!settings) {
            return null;
        }
        return {
            pendingPercentages: {
                greenToYellow: settings.pendingPercentages[0],
                yellowToRed: settings.pendingPercentages[1]
            },
            oldestQueueMessages: {
                greenToYellow: settings.oldestQueueMessages[0],
                yellowToRed: settings.oldestQueueMessages[1]
            },
            queueType: settings.queueType === queue_type_enum_1.QueueType.REVIEW ? 'review' : 'escalation',
            queues: [...settings.queues]
        };
    }
}
__decorate([
    effects_1.Effect()
], DashboardSettingsEffect.prototype, "getDashboardSettings$", void 0);
__decorate([
    effects_1.Effect()
], DashboardSettingsEffect.prototype, "getDashboardSettingsFail$", void 0);
__decorate([
    effects_1.Effect()
], DashboardSettingsEffect.prototype, "setDashboardSettings$", void 0);
__decorate([
    effects_1.Effect()
], DashboardSettingsEffect.prototype, "setDashboardSettingsFail$", void 0);
__decorate([
    effects_1.Effect()
], DashboardSettingsEffect.prototype, "setDashboardSettingsSuccess$", void 0);
exports.DashboardSettingsEffect = DashboardSettingsEffect;
