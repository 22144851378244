"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoadPolicyFailAction = exports.LoadPolicySuccessAction = exports.LoadPolicyAction = exports.CancelModalYesAction = exports.CancelModalNoAction = exports.CancelModalOpenAction = exports.UpdateFailAction = exports.UpdateSuccessAction = exports.UpdateAction = exports.CreateFailAction = exports.CreateSuccessAction = exports.CreateAction = exports.UpdateIsValidAction = exports.UpdateCurrentStep = exports.ResetAction = exports.LOAD_POLICY_FAIL = exports.LOAD_POLICY_SUCCESS = exports.LOAD_POLICY = exports.CANCEL_CONFIRMATION_MODAL_YES_RESPONSE = exports.CANCEL_CONFIRMATION_MODAL_NO_RESPONSE = exports.CANCEL_CONFIRMATION_MODAL_OPEN = exports.UPDATE_FAIL = exports.UPDATE_SUCCESS = exports.UPDATE = exports.CREATE_FAIL = exports.CREATE_SUCCESS = exports.CREATE = exports.UPDATE_IS_VALID = exports.UPDATE_CURRENT_STEP = exports.RESET = void 0;
exports.RESET = '[IdentityPolicyWizard] Reset';
exports.UPDATE_CURRENT_STEP = '[IdentityPolicyWizard] UpdateCurrentStep';
exports.UPDATE_IS_VALID = '[IdentityPolicyWizard] UpdateIsValid';
exports.CREATE = '[IdentityPolicyWizard] Create';
exports.CREATE_SUCCESS = '[IdentityPolicyWizard] Create Success';
exports.CREATE_FAIL = '[IdentityPolicyWizard] Create Fail';
exports.UPDATE = '[IdentityPolicyWizard] Update';
exports.UPDATE_SUCCESS = '[IdentityPolicyWizard] Update Success';
exports.UPDATE_FAIL = '[IdentityPolicyWizard] Update Fail';
exports.CANCEL_CONFIRMATION_MODAL_OPEN = '[IdentityPolicies] Show Policy Cancel Confirmation';
exports.CANCEL_CONFIRMATION_MODAL_NO_RESPONSE = '[IdentityPolicies] Cancel Policy No Response';
exports.CANCEL_CONFIRMATION_MODAL_YES_RESPONSE = '[IdentityPolicies] Cancel Policy Yes Response';
exports.LOAD_POLICY = '[IdentityPolicyWizard] Load Policy';
exports.LOAD_POLICY_SUCCESS = '[IdentityPolicyWizard] Load Policy Success';
exports.LOAD_POLICY_FAIL = '[IdentityPolicyWizard] Load Policy Fail';
class ResetAction {
    constructor() {
        this.type = exports.RESET;
    }
}
exports.ResetAction = ResetAction;
class UpdateCurrentStep {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.UPDATE_CURRENT_STEP;
    }
}
exports.UpdateCurrentStep = UpdateCurrentStep;
class UpdateIsValidAction {
    constructor(step, valid) {
        this.step = step;
        this.valid = valid;
        this.type = exports.UPDATE_IS_VALID;
    }
}
exports.UpdateIsValidAction = UpdateIsValidAction;
class CreateAction {
    constructor() {
        this.type = exports.CREATE;
    }
}
exports.CreateAction = CreateAction;
class CreateSuccessAction {
    constructor() {
        this.type = exports.CREATE_SUCCESS;
    }
}
exports.CreateSuccessAction = CreateSuccessAction;
class CreateFailAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.CREATE_FAIL;
    }
}
exports.CreateFailAction = CreateFailAction;
class UpdateAction {
    constructor() {
        this.type = exports.UPDATE;
    }
}
exports.UpdateAction = UpdateAction;
class UpdateSuccessAction {
    constructor() {
        this.type = exports.UPDATE_SUCCESS;
    }
}
exports.UpdateSuccessAction = UpdateSuccessAction;
class UpdateFailAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.UPDATE_FAIL;
    }
}
exports.UpdateFailAction = UpdateFailAction;
class CancelModalOpenAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.CANCEL_CONFIRMATION_MODAL_OPEN;
    }
}
exports.CancelModalOpenAction = CancelModalOpenAction;
class CancelModalNoAction {
    constructor() {
        this.type = exports.CANCEL_CONFIRMATION_MODAL_NO_RESPONSE;
    }
}
exports.CancelModalNoAction = CancelModalNoAction;
class CancelModalYesAction {
    constructor() {
        this.type = exports.CANCEL_CONFIRMATION_MODAL_YES_RESPONSE;
    }
}
exports.CancelModalYesAction = CancelModalYesAction;
class LoadPolicyAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOAD_POLICY;
    }
}
exports.LoadPolicyAction = LoadPolicyAction;
class LoadPolicySuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOAD_POLICY_SUCCESS;
    }
}
exports.LoadPolicySuccessAction = LoadPolicySuccessAction;
class LoadPolicyFailAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOAD_POLICY_FAIL;
    }
}
exports.LoadPolicyFailAction = LoadPolicyFailAction;
