"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BackupApiService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
class BackupApiService {
    constructor(http) {
        this.http = http;
    }
    getOneDriveBackups(payload, pagination) {
        return this.http
            .post('/api/onedrive/backup/find-backup-configs', {
            meta: { pagination },
            data: [payload]
        })
            .pipe(operators_1.map((response) => response), operators_1.catchError((response) => rxjs_1.of(response)));
    }
    createBackup(payload) {
        return this.http
            .post('/api/onedrive/backup/create-backup-config', {
            data: [payload]
        })
            .pipe(operators_1.map((response) => response), operators_1.catchError((response) => rxjs_1.of(response)));
    }
    updateBackup(backupId, backup) {
        return this.http
            .post('/api/onedrive/backup/update-backup-config', Object.assign({ id: backupId }, backup))
            .pipe(operators_1.map((response) => response), operators_1.catchError((response) => rxjs_1.of(response)));
    }
    changeBackupStatus(payload) {
        return this.http
            .post('/api/onedrive/backup/toggle-backup-config', {
            data: [payload]
        })
            .pipe(operators_1.map((response) => response), operators_1.catchError((response) => rxjs_1.of(response)));
    }
    deleteBackup(id) {
        return this.http
            .post('/api/onedrive/backup/delete-backup-config', {
            data: [{ id }]
        })
            .pipe(operators_1.map((response) => response), operators_1.catchError((response) => rxjs_1.of(response)));
    }
    getBackup(backupId) {
        return this.http.post('/api/onedrive/backup/get-backup-config', { id: backupId }).pipe(operators_1.map((response) => response), operators_1.catchError((response) => rxjs_1.of(response)));
    }
}
exports.BackupApiService = BackupApiService;
