"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApDeletePolicyFailure = exports.ApDeletePolicySuccess = exports.ApDeletePolicy = exports.UpdatePolicyRule = exports.CreateApFailAction = exports.UpdateApFailAction = exports.UpdateApSuccessAction = exports.UpdatePolicyId = exports.ApUpdatePolicyAction = exports.CreateApSuccessAction = exports.CreateApPolicyAction = exports.RemoveAppliesToGroupAction = exports.AppliesToSelectGroup = exports.RemoveJournalGroupAction = exports.RemoveAdministratorGroupAction = exports.JournalSelectGroup = exports.AdministratorSelectGroup = exports.ApPolicyAdministratorClose = exports.ApPolicyAdministratorOpen = exports.ApDeleteModalCloseAction = exports.ApDeleteModalOpenAction = exports.CancelModalOpenAction = exports.ClearIpAddressesAction = exports.CancelModalYesAction = exports.CancelModalNoAction = exports.RemoveIpAddressAction = exports.PopulateIpAddressAction = exports.AddIpAddressAction = exports.ApPopulateAttributes = exports.ApGetListOfAttributes = exports.ApUpdatePolicyAppliesToStep = exports.ApUpdatePolicySummaryStep = exports.ApUpdatePolicyJournalStep = exports.ApUpdatePolicyInboundStep = exports.APLoadPolicyDetailsFailAction = exports.ApLoadPolicyDetailsSuccessAction = exports.ApLoadPolicyDetailsAction = exports.ApCloseAppAsideAction = exports.ApViewAppAsideAction = exports.ApUpdatePolicyDetailsStep = exports.ApPolicyNavigateToListPageAction = exports.ApClearPolicyAction = exports.ApCreatePolicyAction = exports.RemoveOutboundGroupAction = exports.OutboundSelectGroup = exports.ApUpdatePolicyOutboundStep = exports.ApPolicyEditWizardFlowType = exports.ApPolicyCreateWizardFlowType = exports.ApPolicyNavigateToPolicyWizard = exports.ApPolicyCreateTypesEnum = void 0;
var ApPolicyCreateTypesEnum;
(function (ApPolicyCreateTypesEnum) {
    ApPolicyCreateTypesEnum["NAVIGATE_TO_ATTACHMENT_PROTECTION_POLICY_WIZARD"] = "[Ap Policy] Navigate to Wizard page";
    ApPolicyCreateTypesEnum["ADD_IP_ADDRESS_AP"] = "ADD_IP_ADDRESS_AP";
    ApPolicyCreateTypesEnum["CLEAR_IP_ADDRESSES_AP"] = "CLEAR_IP_ADDRESSES_AP";
    ApPolicyCreateTypesEnum["POPULATE_IP_ADDRESS_AP"] = "[Ap Policy] Populate Ip Address";
    ApPolicyCreateTypesEnum["CANCEL_AP_CONFIRMATION_MODAL_NO_RESPONSE"] = "[Ap Policy] continue with creating policy";
    ApPolicyCreateTypesEnum["AP_UPDATE_POLICY_APPLIES_TO_STEP"] = "[Ap Policy] Update Applies settings page value";
    ApPolicyCreateTypesEnum["CANCEL_AP_CONFIRMATION_MODAL_YES_RESPONSE"] = "[Ap Policy] cancel policy";
    ApPolicyCreateTypesEnum["REMOVE_IP_ADDRESS_AP"] = "[AP Policy] Remove Ip address Ap";
    ApPolicyCreateTypesEnum["AP_UPDATE_POLICY_OUTBOUND_STEP"] = "[Ap Policy] Update Outbound settings page value";
    ApPolicyCreateTypesEnum["ADD_ADMINISTRATOR_OUTBOUND_GROUP"] = "[AP Policy] Add Outbound Group";
    ApPolicyCreateTypesEnum["REMOVE_ADMINISTRATOR_OUTBOUND_GROUP"] = "[AP Policy] Remove Outbound Group";
    ApPolicyCreateTypesEnum["ATTACHMENT_PROTECTION_CREATE_POLICY"] = "[Ap Policy] Create Ap Policy";
    ApPolicyCreateTypesEnum["ATTACHMENT_PROTECTION_UPDATE_POLICY"] = "[Ap Policy] Update Ap Policy";
    ApPolicyCreateTypesEnum["CREATE_ATTACHMENT_PROTECTION_POLICY"] = "[Ap Policy]  Submit Policy details";
    ApPolicyCreateTypesEnum["AP_CANCEL_CONFIRMATION_MODAL_OPEN"] = "[Ap Policy] Show cancel modal";
    ApPolicyCreateTypesEnum["ATTACHMENT_PROTECTION_CLEAR_POLICY"] = "[Ap Policy] Reset Wizard";
    ApPolicyCreateTypesEnum["NAVIGATE_TO_ATTACHMENT_PROTECTION_LIST_PAGE"] = "[Ap Policy] Navigate to List Page";
    ApPolicyCreateTypesEnum["AP_UPDATE_POLICY_DETAILS_STEP"] = "[Ap Policy] Update details page value";
    ApPolicyCreateTypesEnum["AP_UPDATE_POLICY_INBOUND_STEP"] = "[Ap Policy] Update Inbound settings page value";
    ApPolicyCreateTypesEnum["AP_UPDATE_POLICY_JOURNAL_STEP"] = "[Ap Policy] Update Journal settings page value";
    ApPolicyCreateTypesEnum["AP_UPDATE_POLICY_SUMMARY_STEP"] = "[Ap Policy] Update Summary page value";
    ApPolicyCreateTypesEnum["AP_ATTRIBUTE_APPLIES_TO_STEP"] = "[Ap Policy] Get list of address attributes";
    ApPolicyCreateTypesEnum["AP_ATTRIBUTE_APPLIES_TO_STEP_POPULATE"] = "[Ap Policy] populate data";
    ApPolicyCreateTypesEnum["AP_POLICY_ADMINISTRATOR_OPEN"] = "[Ap Policy] open sidepanel for select group Adminstrators";
    ApPolicyCreateTypesEnum["AP_POLICY_ADMINISTRATOR_CLOSE"] = "[Ap Policy] close sidepanel for select group Adminstrators";
    ApPolicyCreateTypesEnum["ADD_ADMINISTRATOR_GROUP"] = "[AP Policy] Add Group";
    ApPolicyCreateTypesEnum["ADD_ADMINISTRATOR_JOURNAL_GROUP"] = "[AP Policy] Add Journal Group";
    ApPolicyCreateTypesEnum["AP_POLICY_DELETE_MODAL_OPEN"] = "[AP Policy] Open delete confirmation modal";
    ApPolicyCreateTypesEnum["AP_POLICY_DELETE_MODAL_CLOSE"] = "[AP Policy] Close delete confirmation modal";
    ApPolicyCreateTypesEnum["REMOVE_ADMINISTRATOR_GROUP"] = "[AP Policy] Remove Group";
    ApPolicyCreateTypesEnum["REMOVE_JOURNAL_GROUP"] = "[AP Policy] Remove Journal Group";
    ApPolicyCreateTypesEnum["LOAD_ID_POLICY_DETAILS"] = "[AP Policy] Load policyId to Edit policy";
    ApPolicyCreateTypesEnum["NAVIGATE_TO_CREATE_ATTACHMENT_PROTECTION_POLICY"] = "[Ap Policy] navigate to create a policy";
    ApPolicyCreateTypesEnum["NAVIGATE_TO_EDIT_ATTACHMENT_PROTECTION_POLICY"] = "[Ap Policy] navigate to edit a policy";
    ApPolicyCreateTypesEnum["OPEN_AP_POLICY_SIDEBAR"] = "[AP Policy] Open side panel";
    ApPolicyCreateTypesEnum["CLOSE_AP_POLICY_SIDEBAR"] = "[AP Policy] Close side panel";
    ApPolicyCreateTypesEnum["LOAD_AP_POLICY_DETAILS"] = "[AP Policy] Load details for side panel";
    ApPolicyCreateTypesEnum["LOAD_AP_POLICY_DETAILS_SUCCESS"] = "[AP Policy] Load details for side panel success";
    ApPolicyCreateTypesEnum["LOAD_AP_POLICY_DETAILS_FAIL"] = "[AP Policy] Load details for side panel fail";
    ApPolicyCreateTypesEnum["CREATE_ATTACHMENT_PROTECTION_POLICY_SUCCESS"] = "[AP Policyp] Create Attachment Protection policy success";
    ApPolicyCreateTypesEnum["CREATE_ATTACHMENT_PROTECTION_POLICY_FAIL"] = "[AP Policyp] Create Attachment Protection policy fail";
    ApPolicyCreateTypesEnum["UPDATE_ATTACHMENT_PROTECTION_POLICY_SUCCESS"] = "[AP Policyp] Update Attachment Protection policy success";
    ApPolicyCreateTypesEnum["UPDATE_ATTACHMENT_PROTECTION_POLICY_FAIL"] = "[AP Policyp] Update Attachment Protection policy fail";
    ApPolicyCreateTypesEnum["UPDATE_POLICY_RULE"] = "[AP Policy] AP Update Policy rule";
    ApPolicyCreateTypesEnum["AP_POLICY_DELETE"] = "[AP Policy] AP Delete Policy";
    ApPolicyCreateTypesEnum["AP_POLICY_DELETE_SUCCESS"] = "[AP Policy] AP Delete Policy Success";
    ApPolicyCreateTypesEnum["AP_POLICY_DELETE_FAIL"] = "[AP Policy] AP Delete Policy Fail";
    ApPolicyCreateTypesEnum["ADD_APPLIES_TO_GROUP"] = "[Ap Policy] open sidepanel for select group Applies to";
    ApPolicyCreateTypesEnum["REMOVE_APPLIES_TO_GROUP"] = "[Ap Policy] close sidepanel for select group Applies to";
})(ApPolicyCreateTypesEnum = exports.ApPolicyCreateTypesEnum || (exports.ApPolicyCreateTypesEnum = {}));
class ApPolicyNavigateToPolicyWizard {
    constructor(payload) {
        this.payload = payload;
        this.type = ApPolicyCreateTypesEnum.NAVIGATE_TO_ATTACHMENT_PROTECTION_POLICY_WIZARD;
    }
}
exports.ApPolicyNavigateToPolicyWizard = ApPolicyNavigateToPolicyWizard;
class ApPolicyCreateWizardFlowType {
    constructor() {
        this.type = ApPolicyCreateTypesEnum.NAVIGATE_TO_CREATE_ATTACHMENT_PROTECTION_POLICY;
    }
}
exports.ApPolicyCreateWizardFlowType = ApPolicyCreateWizardFlowType;
class ApPolicyEditWizardFlowType {
    constructor() {
        this.type = ApPolicyCreateTypesEnum.NAVIGATE_TO_EDIT_ATTACHMENT_PROTECTION_POLICY;
    }
}
exports.ApPolicyEditWizardFlowType = ApPolicyEditWizardFlowType;
class ApUpdatePolicyOutboundStep {
    constructor(payload) {
        this.payload = payload;
        this.type = ApPolicyCreateTypesEnum.AP_UPDATE_POLICY_OUTBOUND_STEP;
    }
}
exports.ApUpdatePolicyOutboundStep = ApUpdatePolicyOutboundStep;
class OutboundSelectGroup {
    constructor(payload) {
        this.payload = payload;
        this.type = ApPolicyCreateTypesEnum.ADD_ADMINISTRATOR_OUTBOUND_GROUP;
    }
}
exports.OutboundSelectGroup = OutboundSelectGroup;
class RemoveOutboundGroupAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApPolicyCreateTypesEnum.REMOVE_ADMINISTRATOR_OUTBOUND_GROUP;
    }
}
exports.RemoveOutboundGroupAction = RemoveOutboundGroupAction;
class ApCreatePolicyAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApPolicyCreateTypesEnum.ATTACHMENT_PROTECTION_CREATE_POLICY;
    }
}
exports.ApCreatePolicyAction = ApCreatePolicyAction;
class ApClearPolicyAction {
    constructor() {
        this.type = ApPolicyCreateTypesEnum.ATTACHMENT_PROTECTION_CLEAR_POLICY;
    }
}
exports.ApClearPolicyAction = ApClearPolicyAction;
class ApPolicyNavigateToListPageAction {
    constructor() {
        this.type = ApPolicyCreateTypesEnum.NAVIGATE_TO_ATTACHMENT_PROTECTION_LIST_PAGE;
    }
}
exports.ApPolicyNavigateToListPageAction = ApPolicyNavigateToListPageAction;
class ApUpdatePolicyDetailsStep {
    constructor(payload) {
        this.payload = payload;
        this.type = ApPolicyCreateTypesEnum.AP_UPDATE_POLICY_DETAILS_STEP;
    }
}
exports.ApUpdatePolicyDetailsStep = ApUpdatePolicyDetailsStep;
class ApViewAppAsideAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApPolicyCreateTypesEnum.OPEN_AP_POLICY_SIDEBAR;
    }
}
exports.ApViewAppAsideAction = ApViewAppAsideAction;
class ApCloseAppAsideAction {
    constructor() {
        this.type = ApPolicyCreateTypesEnum.CLOSE_AP_POLICY_SIDEBAR;
    }
}
exports.ApCloseAppAsideAction = ApCloseAppAsideAction;
class ApLoadPolicyDetailsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApPolicyCreateTypesEnum.LOAD_AP_POLICY_DETAILS;
    }
}
exports.ApLoadPolicyDetailsAction = ApLoadPolicyDetailsAction;
class ApLoadPolicyDetailsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApPolicyCreateTypesEnum.LOAD_AP_POLICY_DETAILS_SUCCESS;
    }
}
exports.ApLoadPolicyDetailsSuccessAction = ApLoadPolicyDetailsSuccessAction;
class APLoadPolicyDetailsFailAction {
    constructor() {
        this.type = ApPolicyCreateTypesEnum.LOAD_AP_POLICY_DETAILS_FAIL;
    }
}
exports.APLoadPolicyDetailsFailAction = APLoadPolicyDetailsFailAction;
class ApUpdatePolicyInboundStep {
    constructor(payload) {
        this.payload = payload;
        this.type = ApPolicyCreateTypesEnum.AP_UPDATE_POLICY_INBOUND_STEP;
    }
}
exports.ApUpdatePolicyInboundStep = ApUpdatePolicyInboundStep;
class ApUpdatePolicyJournalStep {
    constructor(payload) {
        this.payload = payload;
        this.type = ApPolicyCreateTypesEnum.AP_UPDATE_POLICY_JOURNAL_STEP;
    }
}
exports.ApUpdatePolicyJournalStep = ApUpdatePolicyJournalStep;
class ApUpdatePolicySummaryStep {
    constructor(payload) {
        this.payload = payload;
        this.type = ApPolicyCreateTypesEnum.AP_UPDATE_POLICY_SUMMARY_STEP;
    }
}
exports.ApUpdatePolicySummaryStep = ApUpdatePolicySummaryStep;
class ApUpdatePolicyAppliesToStep {
    constructor(payload) {
        this.payload = payload;
        this.type = ApPolicyCreateTypesEnum.AP_UPDATE_POLICY_APPLIES_TO_STEP;
    }
}
exports.ApUpdatePolicyAppliesToStep = ApUpdatePolicyAppliesToStep;
class ApGetListOfAttributes {
    constructor() {
        this.type = ApPolicyCreateTypesEnum.AP_ATTRIBUTE_APPLIES_TO_STEP;
    }
}
exports.ApGetListOfAttributes = ApGetListOfAttributes;
class ApPopulateAttributes {
    constructor(payload) {
        this.payload = payload;
        this.type = ApPolicyCreateTypesEnum.AP_ATTRIBUTE_APPLIES_TO_STEP_POPULATE;
    }
}
exports.ApPopulateAttributes = ApPopulateAttributes;
class AddIpAddressAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApPolicyCreateTypesEnum.ADD_IP_ADDRESS_AP;
    }
}
exports.AddIpAddressAction = AddIpAddressAction;
class PopulateIpAddressAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApPolicyCreateTypesEnum.POPULATE_IP_ADDRESS_AP;
    }
}
exports.PopulateIpAddressAction = PopulateIpAddressAction;
class RemoveIpAddressAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApPolicyCreateTypesEnum.REMOVE_IP_ADDRESS_AP;
    }
}
exports.RemoveIpAddressAction = RemoveIpAddressAction;
class CancelModalNoAction {
    constructor() {
        this.type = ApPolicyCreateTypesEnum.CANCEL_AP_CONFIRMATION_MODAL_NO_RESPONSE;
    }
}
exports.CancelModalNoAction = CancelModalNoAction;
class CancelModalYesAction {
    constructor() {
        this.type = ApPolicyCreateTypesEnum.CANCEL_AP_CONFIRMATION_MODAL_YES_RESPONSE;
    }
}
exports.CancelModalYesAction = CancelModalYesAction;
class ClearIpAddressesAction {
    constructor() {
        this.type = ApPolicyCreateTypesEnum.CLEAR_IP_ADDRESSES_AP;
    }
}
exports.ClearIpAddressesAction = ClearIpAddressesAction;
class CancelModalOpenAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApPolicyCreateTypesEnum.AP_CANCEL_CONFIRMATION_MODAL_OPEN;
    }
}
exports.CancelModalOpenAction = CancelModalOpenAction;
class ApDeleteModalOpenAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApPolicyCreateTypesEnum.AP_POLICY_DELETE_MODAL_OPEN;
    }
}
exports.ApDeleteModalOpenAction = ApDeleteModalOpenAction;
class ApDeleteModalCloseAction {
    constructor() {
        this.type = ApPolicyCreateTypesEnum.AP_POLICY_DELETE_MODAL_CLOSE;
    }
}
exports.ApDeleteModalCloseAction = ApDeleteModalCloseAction;
class ApPolicyAdministratorOpen {
    constructor(payload) {
        this.payload = payload;
        this.type = ApPolicyCreateTypesEnum.AP_POLICY_ADMINISTRATOR_OPEN;
    }
}
exports.ApPolicyAdministratorOpen = ApPolicyAdministratorOpen;
class ApPolicyAdministratorClose {
    constructor() {
        this.type = ApPolicyCreateTypesEnum.AP_POLICY_ADMINISTRATOR_CLOSE;
    }
}
exports.ApPolicyAdministratorClose = ApPolicyAdministratorClose;
class AdministratorSelectGroup {
    constructor(payload) {
        this.payload = payload;
        this.type = ApPolicyCreateTypesEnum.ADD_ADMINISTRATOR_GROUP;
    }
}
exports.AdministratorSelectGroup = AdministratorSelectGroup;
class JournalSelectGroup {
    constructor(payload) {
        this.payload = payload;
        this.type = ApPolicyCreateTypesEnum.ADD_ADMINISTRATOR_JOURNAL_GROUP;
    }
}
exports.JournalSelectGroup = JournalSelectGroup;
class RemoveAdministratorGroupAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApPolicyCreateTypesEnum.REMOVE_ADMINISTRATOR_GROUP;
    }
}
exports.RemoveAdministratorGroupAction = RemoveAdministratorGroupAction;
class RemoveJournalGroupAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApPolicyCreateTypesEnum.REMOVE_JOURNAL_GROUP;
    }
}
exports.RemoveJournalGroupAction = RemoveJournalGroupAction;
class AppliesToSelectGroup {
    constructor(payload, value, index) {
        this.payload = payload;
        this.value = value;
        this.index = index;
        this.type = ApPolicyCreateTypesEnum.ADD_APPLIES_TO_GROUP;
    }
}
exports.AppliesToSelectGroup = AppliesToSelectGroup;
class RemoveAppliesToGroupAction {
    constructor(payload, value) {
        this.payload = payload;
        this.value = value;
        this.type = ApPolicyCreateTypesEnum.REMOVE_APPLIES_TO_GROUP;
    }
}
exports.RemoveAppliesToGroupAction = RemoveAppliesToGroupAction;
class CreateApPolicyAction {
    constructor() {
        this.type = ApPolicyCreateTypesEnum.CREATE_ATTACHMENT_PROTECTION_POLICY;
    }
}
exports.CreateApPolicyAction = CreateApPolicyAction;
class CreateApSuccessAction {
    constructor() {
        this.type = ApPolicyCreateTypesEnum.CREATE_ATTACHMENT_PROTECTION_POLICY_SUCCESS;
    }
}
exports.CreateApSuccessAction = CreateApSuccessAction;
class ApUpdatePolicyAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApPolicyCreateTypesEnum.ATTACHMENT_PROTECTION_UPDATE_POLICY;
    }
}
exports.ApUpdatePolicyAction = ApUpdatePolicyAction;
class UpdatePolicyId {
    constructor(payload) {
        this.payload = payload;
        this.type = ApPolicyCreateTypesEnum.LOAD_ID_POLICY_DETAILS;
    }
}
exports.UpdatePolicyId = UpdatePolicyId;
class UpdateApSuccessAction {
    constructor() {
        this.type = ApPolicyCreateTypesEnum.UPDATE_ATTACHMENT_PROTECTION_POLICY_SUCCESS;
    }
}
exports.UpdateApSuccessAction = UpdateApSuccessAction;
class UpdateApFailAction {
    constructor() {
        this.type = ApPolicyCreateTypesEnum.UPDATE_ATTACHMENT_PROTECTION_POLICY_FAIL;
    }
}
exports.UpdateApFailAction = UpdateApFailAction;
class CreateApFailAction {
    constructor() {
        this.type = ApPolicyCreateTypesEnum.CREATE_ATTACHMENT_PROTECTION_POLICY_FAIL;
    }
}
exports.CreateApFailAction = CreateApFailAction;
class UpdatePolicyRule {
    constructor(payload) {
        this.payload = payload;
        this.type = ApPolicyCreateTypesEnum.UPDATE_POLICY_RULE;
    }
}
exports.UpdatePolicyRule = UpdatePolicyRule;
class ApDeletePolicy {
    constructor(payload) {
        this.payload = payload;
        this.type = ApPolicyCreateTypesEnum.AP_POLICY_DELETE;
    }
}
exports.ApDeletePolicy = ApDeletePolicy;
class ApDeletePolicySuccess {
    constructor() {
        this.type = ApPolicyCreateTypesEnum.AP_POLICY_DELETE_SUCCESS;
    }
}
exports.ApDeletePolicySuccess = ApDeletePolicySuccess;
class ApDeletePolicyFailure {
    constructor() {
        this.type = ApPolicyCreateTypesEnum.AP_POLICY_DELETE_FAIL;
    }
}
exports.ApDeletePolicyFailure = ApDeletePolicyFailure;
