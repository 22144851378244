"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getErrorPrerequisite = exports.getSendApiInUpdateCall = exports.getWildfireIntegration = exports.isVerifyApiKeyButtonDisabled = exports.isApiAndRegionVerified = exports.hasCreatedIntegration = exports.isBusy = exports.reducer = exports.initialState = void 0;
const actions = require("app-new/api-applications/actions");
exports.initialState = {
    isBusy: false,
    wildfireIntegration: {
        enabled: false,
        acceptedSharingInfo: false,
        remediationRequired: false,
        region: '',
        notificationGroups: {},
        notificationAddresses: [],
        indicators: {
            ATTACHMENT: {
                indicator: 'ATTACHMENT',
                enabled: true
            },
            MALICIOUS_ATTACHMENT: {
                indicator: 'MALICIOUS_ATTACHMENT',
                enabled: true
            }
        }
    },
    errorPrerequisite: undefined,
    createdIntegration: false,
    isApiAndRegionVerified: false,
    isVerifyApiKeyButtonDisabled: false,
    sendApiInUpdateCall: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.IntegrationCortexActionTypes.GetWildfireIntegrationComplete:
            return Object.assign(Object.assign({}, state), { isBusy: false, wildfireIntegration: action.payload, createdIntegration: true, isApiAndRegionVerified: true, sendApiInUpdateCall: false });
        case actions.IntegrationWildfireActionTypes.StartVerifyApiAndRegion:
            return Object.assign(Object.assign({}, state), { isApiAndRegionVerified: false, isVerifyApiKeyButtonDisabled: true });
        case actions.IntegrationWildfireActionTypes.StartVerifyApiAndRegionSuccess:
            return Object.assign(Object.assign({}, state), { isApiAndRegionVerified: true, isVerifyApiKeyButtonDisabled: false, sendApiInUpdateCall: true, wildfireIntegration: Object.assign(Object.assign({}, state.wildfireIntegration), { apiKey: action.payload.apiKey, region: action.payload.region }) });
        case actions.IntegrationWildfireActionTypes.StartVerifyApiAndRegionFail:
            return Object.assign(Object.assign({}, state), { isApiAndRegionVerified: false, isVerifyApiKeyButtonDisabled: false });
        case actions.IntegrationWildfireActionTypes.SwitchWildfireIntegrationStatus:
            return Object.assign(Object.assign({}, state), { wildfireIntegration: Object.assign(Object.assign({}, state.wildfireIntegration), { enabled: action.payload }) });
        case actions.IntegrationWildfireActionTypes.SwitchWildfireAttachmentIndicator:
            return Object.assign(Object.assign({}, state), { wildfireIntegration: Object.assign(Object.assign({}, state.wildfireIntegration), { indicators: {
                        ATTACHMENT: Object.assign(Object.assign({}, state.wildfireIntegration.indicators.ATTACHMENT), { enabled: action.payload }),
                        MALICIOUS_ATTACHMENT: state.wildfireIntegration.indicators.MALICIOUS_ATTACHMENT
                    } }) });
        case actions.IntegrationWildfireActionTypes.SwitchWildfireMaliciousAttachmentIndicator:
            return Object.assign(Object.assign({}, state), { wildfireIntegration: Object.assign(Object.assign({}, state.wildfireIntegration), { indicators: {
                        MALICIOUS_ATTACHMENT: Object.assign(Object.assign({}, state.wildfireIntegration.indicators.MALICIOUS_ATTACHMENT), { indicator: 'MALICIOUS_ATTACHMENT', enabled: action.payload }),
                        ATTACHMENT: state.wildfireIntegration.indicators.ATTACHMENT
                    } }) });
        case actions.IntegrationWildfireActionTypes.SwitchWildfireAcceptSharingInfoDisclaimer:
            return Object.assign(Object.assign({}, state), { wildfireIntegration: Object.assign(Object.assign({}, state.wildfireIntegration), { acceptedSharingInfo: action.payload }) });
        case actions.IntegrationWildfireActionTypes.SwitchRemediation:
            return Object.assign(Object.assign({}, state), { wildfireIntegration: Object.assign(Object.assign({}, state.wildfireIntegration), { remediationRequired: action.payload }) });
        case actions.IntegrationWildfireActionTypes.AddGroupWildfireIntegrationNotifications:
            const id = action.payload.id;
            const description = action.payload.description;
            const newAddedNotificationGroups = Object.assign({}, state.wildfireIntegration.notificationGroups, { [id]: description });
            return Object.assign(Object.assign({}, state), { wildfireIntegration: Object.assign(Object.assign({}, state.wildfireIntegration), { notificationGroups: newAddedNotificationGroups }) });
        case actions.IntegrationWildfireActionTypes.DeleteGroupWildfireIntegrationNotifications:
            const newDeletedNotificationGroups = Object.assign({}, state.wildfireIntegration.notificationGroups);
            delete newDeletedNotificationGroups[action.payload.id];
            return Object.assign(Object.assign({}, state), { wildfireIntegration: Object.assign(Object.assign({}, state.wildfireIntegration), { notificationGroups: newDeletedNotificationGroups }) });
        case actions.IntegrationWildfireActionTypes
            .VerifyAndAddEmailWildfireIntegrationNotificationsSuccess:
            return Object.assign(Object.assign({}, state), { wildfireIntegration: Object.assign(Object.assign({}, state.wildfireIntegration), { notificationAddresses: [action.payload].concat(state.wildfireIntegration.notificationAddresses) }) });
        case actions.IntegrationWildfireActionTypes.DeleteEmailWildfireIntegrationNotifications:
            return Object.assign(Object.assign({}, state), { wildfireIntegration: Object.assign(Object.assign({}, state.wildfireIntegration), { notificationAddresses: state.wildfireIntegration.notificationAddresses.filter((email) => email !== action.payload) }) });
        case actions.IntegrationWildfireActionTypes.CheckWildfirePrerequisitesSuccess:
            return Object.assign(Object.assign({}, state), { errorPrerequisite: null });
        case actions.IntegrationWildfireActionTypes.CheckWildfirePrerequisitesFail:
            return Object.assign(Object.assign({}, state), { errorPrerequisite: action.payload });
        case actions.IntegrationWildfireActionTypes.ChangeRegion:
            return Object.assign(Object.assign({}, state), { wildfireIntegration: Object.assign(Object.assign({}, state.wildfireIntegration), { region: action.payload }) });
        default:
            return state;
    }
}
exports.reducer = reducer;
const isBusy = (state) => state.isBusy;
exports.isBusy = isBusy;
const hasCreatedIntegration = (state) => state.createdIntegration;
exports.hasCreatedIntegration = hasCreatedIntegration;
const isApiAndRegionVerified = (state) => state.isApiAndRegionVerified;
exports.isApiAndRegionVerified = isApiAndRegionVerified;
const isVerifyApiKeyButtonDisabled = (state) => state.isVerifyApiKeyButtonDisabled;
exports.isVerifyApiKeyButtonDisabled = isVerifyApiKeyButtonDisabled;
const getWildfireIntegration = (state) => state.wildfireIntegration;
exports.getWildfireIntegration = getWildfireIntegration;
const getSendApiInUpdateCall = (state) => state.sendApiInUpdateCall;
exports.getSendApiInUpdateCall = getSendApiInUpdateCall;
const getErrorPrerequisite = (state) => state.errorPrerequisite;
exports.getErrorPrerequisite = getErrorPrerequisite;
