<mc-modal-header
  headerTitle="{{ '$I18N_API_APPLICATIONS_NEW_FEATURES_MODAL.HEADER' | translate }}">
</mc-modal-header>

<mc-modal-body>
  <p>{{ '$I18N_API_APPLICATIONS_NEW_FEATURES_MODAL.MESSAGE_LINE_01' | translate }}</p>
  <p>{{ '$I18N_API_APPLICATIONS_NEW_FEATURES_MODAL.MESSAGE_LINE_02' | translate }}</p>
</mc-modal-body>

<mc-modal-footer>
  <button type="button" class="btn btn-primary" (click)="close()">
    {{ '$I18N_API_APPLICATIONS_NEW_FEATURES_MODAL.OK' | translate }}
  </button>
</mc-modal-footer>
