"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SliderComponent = void 0;
const forms_1 = require("@angular/forms");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
class SliderComponent {
    constructor(cdRef) {
        this.cdRef = cdRef;
        this.numberOfHandles = 1;
        this.destroy$ = new rxjs_1.Subject();
        this.propogateChange = () => { };
        this.sliderInput = new forms_1.FormGroup({
            sliderControl: new forms_1.FormControl([20, 80])
        });
        this.sliderInput.valueChanges.pipe(operators_1.takeUntil(this.destroy$)).subscribe(val => {
            this.value = val.sliderControl;
            this.propogateChange(val.sliderControl);
            this.cdRef.detectChanges();
        });
    }
    ngOnInit() {
        this.buildOptions();
    }
    writeValue(value) {
        this.value = value;
        this.sliderInput.patchValue({ sliderControl: value });
    }
    registerOnChange(fn) {
        this.propogateChange = fn;
    }
    registerOnTouched() { }
    ngAfterContentChecked() { }
    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
    buildOptions() {
        let options = SliderComponent.DEFAULT_OPTIONS;
        if (this.metadata) {
            if (this.metadata.minValue) {
                options = Object.assign(Object.assign({}, options), { floor: this.metadata.minValue, minLimit: this.metadata.minValue + 1 });
            }
            if (this.metadata.maxValue) {
                options = Object.assign(Object.assign({}, options), { ceil: this.metadata.maxValue, maxLimit: this.metadata.maxValue - 1 });
            }
            if (this.metadata.translate) {
                options = Object.assign(Object.assign({}, options), { translate: this.metadata.translate });
            }
        }
        this.options = Object.assign({}, options);
    }
}
exports.SliderComponent = SliderComponent;
SliderComponent.DEFAULT_OPTIONS = {
    floor: 0,
    ceil: 100,
    minRange: 1,
    minLimit: 1,
    maxLimit: 99,
    showOuterSelectionBars: true,
    animate: false,
    noSwitching: true
};
