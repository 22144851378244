"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchMessagesWidgetComponent = void 0;
const searchAction = require("../../actions/search/search.action");
const reducers = require("../../reducers");
const remediate_messages_action_1 = require("../../actions/remediate-messages/remediate-messages.action");
class SearchMessagesWidgetComponent {
    constructor(store) {
        this.store = store;
        this.fileSizeLimit = 200 * 1024 * 1024; // 200Mb
        this.inlineForm = false;
    }
    ngOnInit() {
        this.isSearchInProgress$ = this.store.select(reducers.searchInProgress);
        this.isRemediationEnabled$ = this.store.select(reducers.isRemediationEnabled);
        this.formValues$ = this.store.select(reducers.getSearchFormPayload);
        this.getSearchTabIndex$ = this.store.select(reducers.getSearchTabIndex);
    }
    runSearch(formValue, searchTabIndex, searchByAttachmentSha) {
        this.store.dispatch(new remediate_messages_action_1.SetSelectedTableRows([]));
        this.store.dispatch(new searchAction.RemediationSearchRunAction(formValue, searchTabIndex, searchByAttachmentSha, this.hasSearchWithoutHashPermission));
    }
    updateFileHash(hash) {
        const formValue = Object.assign(Object.assign({}, this.searchByDataComponent.form.value), { fileHash: hash });
        this.store.dispatch(new searchAction.RemediationSearchUpdateFormValue(formValue));
    }
}
exports.SearchMessagesWidgetComponent = SearchMessagesWidgetComponent;
