'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const _ = require("lodash");
angular.module('chart-data-switcher.directive', [])
    .directive('mcChartDataSwitcher', [function () {
        return {
            restrict: 'E',
            templateUrl: 'components/chart-data-switcher/chart-data-switcher.tpl.html',
            scope: {
                chartSeries: '='
            },
            link(scope) {
                scope.elementClicked = elementClicked;
                function elementClicked(element) {
                    _.forEach(scope.chartSeries.dataSets, function (item) {
                        item.active = false;
                    });
                    element.active = !element.active;
                }
            }
        };
    }]);
