"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChannelType = exports.SiemChannelWizardFlowType = void 0;
var SiemChannelWizardFlowType;
(function (SiemChannelWizardFlowType) {
    SiemChannelWizardFlowType["CREATE"] = "create";
    SiemChannelWizardFlowType["EDIT"] = "edit";
})(SiemChannelWizardFlowType = exports.SiemChannelWizardFlowType || (exports.SiemChannelWizardFlowType = {}));
var ChannelType;
(function (ChannelType) {
    ChannelType["SIEM"] = "SIEM";
    ChannelType["THREAT"] = "Threat";
})(ChannelType = exports.ChannelType || (exports.ChannelType = {}));
