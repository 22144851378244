"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgApplicationSanctionComponent = void 0;
const core_1 = require("@angular/core");
class SwgApplicationSanctionComponent {
    constructor() {
        this.webApplicationSanction = new core_1.EventEmitter();
        this.dateRangeChangeSanction = new core_1.EventEmitter();
        this.onShowNotificationSanction = new core_1.EventEmitter();
    }
}
exports.SwgApplicationSanctionComponent = SwgApplicationSanctionComponent;
