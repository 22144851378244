
    <div class="mc-frame" [class.mc-frame-hidden]="isHidden">
      <span [innerHtml]="iframeHtml" #frame></span>
      <mc-spinner *ngIf="isLoading$ | async"></mc-spinner>
      <form method="POST" [target]="frameName" enctype="application/x-www-form-urlencoded" #form>
        <input
          type="hidden"
          *ngFor="let field of fields$ | async"
          [name]="field.name"
          [value]="field.value"
        />
      </form>
    </div>
  