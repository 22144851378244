"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectorsMigrationComponent = void 0;
const reducers_1 = require("./reducers");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const congratulations_component_1 = require("./components/congratulations/congratulations.component");
class ConnectorsMigrationComponent {
    constructor(store, translate, modal, cookieService, capabilitiesService) {
        this.store = store;
        this.translate = translate;
        this.modal = modal;
        this.cookieService = cookieService;
        this.capabilitiesService = capabilitiesService;
        this.connectorsToMigrate$ = this.store.select(reducers_1.getConnectorsToMigrate);
        this.azureStandardIntegrationsToMigrate$ = this.store.select(reducers_1.getAzureStandardIntegrationsToMigrate);
        this.destroy$ = new rxjs_1.Subject();
        this.isAzureStandardHecate = true;
    }
    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
    ngOnInit() {
        this.migrationStatus$ = this.store.select(reducers_1.getMigrationStatus);
        rxjs_1.combineLatest([this.connectorsToMigrate$, this.azureStandardIntegrationsToMigrate$])
            .pipe(operators_1.delay(200), operators_1.distinctUntilChanged(), operators_1.takeUntil(this.destroy$))
            .subscribe(combined => {
            const [connectorsToMigrate, azureStandardIntegrationsToMigrate] = combined;
            if (connectorsToMigrate === 0 &&
                azureStandardIntegrationsToMigrate === 0 &&
                this.cookieService.get('migrationStatusH23') === 'inprogress') {
                const modalConfig = {};
                modalConfig.size = 'md';
                modalConfig.hasBackdrop = true;
                modalConfig.disableClose = true;
                this.congratulationsModal = this.modal.open(congratulations_component_1.CongratulationsComponent, modalConfig);
            }
        });
        this.capabilitiesService
            .hasCapability('DirSync.Azure.Standard.Hecate')
            .pipe(operators_1.take(1))
            .subscribe(useNew => {
            this.isAzureStandardHecate = useNew;
        });
        this.translate
            .get(this.isAzureStandardHecate
            ? '$I18N_CONNECTORS_MIGRATE.NOTIFICATION.AZURE_STANDARD_HECATE'
            : '$I18N_CONNECTORS_MIGRATE.NOTIFICATION.GENERIC')
            .pipe(operators_1.take(1), operators_1.tap(translated => {
            this.notification = {
                msg: translated,
                status: this.isAzureStandardHecate ? 'warning' : 'info',
                boxed: true,
                actions: [
                    {
                        isAutoClose: true,
                        type: 'link',
                        label: this.translate.instant('$I18N_CONNECTORS_MIGRATE.TOAST.LEARN_MORE'),
                        callback: () => {
                            window.open('https://community.mimecast.com/s/article/Migrating-Connectors', '_blank', 'noopener noreferrer');
                        }
                    }
                ]
            };
        }))
            .subscribe();
    }
}
exports.ConnectorsMigrationComponent = ConnectorsMigrationComponent;
