"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmptyPolicyListComponent = void 0;
const core_1 = require("@angular/core");
class EmptyPolicyListComponent {
    // @ts-ignore
    constructor(translateService) {
        this.translateService = translateService;
        // @ts-ignore
        this.createPolicy = new core_1.EventEmitter();
    }
    createNewPolicy() {
        this.createPolicy.emit();
    }
}
exports.EmptyPolicyListComponent = EmptyPolicyListComponent;
