<mc-modal-header></mc-modal-header>

<mc-modal-body>
  <span>
    {{ '$I18N_CREATE_CONNECTOR_MODAL.BODY_1' | translate }}
    <a href="{{ dirSyncUrl }}" (click)="close()" target="_self" rel="noopener noreferrer">
    <u>{{ '$I18N_CREATE_CONNECTOR_MODAL.BODY_2' | translate }}</u>
    </a>
    {{ '$I18N_CREATE_CONNECTOR_MODAL.BODY_3' | translate }}
  </span>
</mc-modal-body>

<mc-modal-footer>
  <button type="button" class="btn btn-secondary " (click)="close()">
    {{ '$I18N_CREATE_CONNECTOR_MODAL.CLOSE' | translate }}
  </button>
</mc-modal-footer>
