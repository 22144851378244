"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyncAndRecoverOverviewLoadChartDataFailed = exports.SyncAndRecoverOverviewLoadChartDataSuccess = exports.SyncAndRecoverOverviewLoadChartData = exports.SyncAndRecoverOverviewSetModalOpen = exports.SyncAndRecoverOverviewCloseModal = exports.SyncAndRecoverOverviewOpenModal = exports.SyncAndRecoverOverviewActions = void 0;
var SyncAndRecoverOverviewActions;
(function (SyncAndRecoverOverviewActions) {
    SyncAndRecoverOverviewActions["SYNC_RECOVER_OVERVIEW_OPEN_MODAL"] = "[Sync and Recover Overview] Open Overview Modal";
    SyncAndRecoverOverviewActions["SYNC_RECOVER_OVERVIEW_CLOSE_MODAL"] = "[Sync and Recover Overview] Close Overview Modal";
    SyncAndRecoverOverviewActions["SYNC_RECOVER_OVERVIEW_SET_MODAL_OPEN_STATUS"] = "[Sync and Recover Overview] Set Modal Open Status";
    SyncAndRecoverOverviewActions["SYNC_RECOVER_OVERVIEW_LOAD_CHART_DATA"] = "[Sync and Recover Overview] Load Chart Data";
    SyncAndRecoverOverviewActions["SYNC_RECOVER_OVERVIEW_LOAD_CHART_DATA_SUCCESS"] = "[Sync and Recover Overview] Load Chart Data Success";
    SyncAndRecoverOverviewActions["SYNC_RECOVER_OVERVIEW_LOAD_CHART_DATA_FAILED"] = "[Sync and Recover Overview] Load Chart Data Failed";
})(SyncAndRecoverOverviewActions = exports.SyncAndRecoverOverviewActions || (exports.SyncAndRecoverOverviewActions = {}));
class SyncAndRecoverOverviewOpenModal {
    constructor() {
        this.type = SyncAndRecoverOverviewActions.SYNC_RECOVER_OVERVIEW_OPEN_MODAL;
    }
}
exports.SyncAndRecoverOverviewOpenModal = SyncAndRecoverOverviewOpenModal;
class SyncAndRecoverOverviewCloseModal {
    constructor() {
        this.type = SyncAndRecoverOverviewActions.SYNC_RECOVER_OVERVIEW_CLOSE_MODAL;
    }
}
exports.SyncAndRecoverOverviewCloseModal = SyncAndRecoverOverviewCloseModal;
class SyncAndRecoverOverviewSetModalOpen {
    constructor(payload) {
        this.payload = payload;
        this.type = SyncAndRecoverOverviewActions.SYNC_RECOVER_OVERVIEW_SET_MODAL_OPEN_STATUS;
    }
}
exports.SyncAndRecoverOverviewSetModalOpen = SyncAndRecoverOverviewSetModalOpen;
class SyncAndRecoverOverviewLoadChartData {
    constructor() {
        this.type = SyncAndRecoverOverviewActions.SYNC_RECOVER_OVERVIEW_LOAD_CHART_DATA;
    }
}
exports.SyncAndRecoverOverviewLoadChartData = SyncAndRecoverOverviewLoadChartData;
class SyncAndRecoverOverviewLoadChartDataSuccess {
    constructor(chartData) {
        this.chartData = chartData;
        this.type = SyncAndRecoverOverviewActions.SYNC_RECOVER_OVERVIEW_LOAD_CHART_DATA_SUCCESS;
    }
}
exports.SyncAndRecoverOverviewLoadChartDataSuccess = SyncAndRecoverOverviewLoadChartDataSuccess;
class SyncAndRecoverOverviewLoadChartDataFailed {
    constructor(payload) {
        this.payload = payload;
        this.type = SyncAndRecoverOverviewActions.SYNC_RECOVER_OVERVIEW_LOAD_CHART_DATA_FAILED;
    }
}
exports.SyncAndRecoverOverviewLoadChartDataFailed = SyncAndRecoverOverviewLoadChartDataFailed;
