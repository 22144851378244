"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HeldQueueTableComponent = void 0;
const core_1 = require("@angular/core");
const held_queue_table_static_values_1 = require("./held-queue-table.static-values");
class HeldQueueTableComponent {
    constructor(capabilitiesService, heldQueueService, heldGroupPipe, emailRoutePipe, spamDetectionPipe) {
        this.capabilitiesService = capabilitiesService;
        this.heldQueueService = heldQueueService;
        this.heldGroupPipe = heldGroupPipe;
        this.emailRoutePipe = emailRoutePipe;
        this.spamDetectionPipe = spamDetectionPipe;
        this.columns = [...held_queue_table_static_values_1.tableColumns];
        this.exports = [...held_queue_table_static_values_1.exportColumns];
        this.filterSections = held_queue_table_static_values_1.tableSectionFilters;
        this.columnsToStartHidden = [...held_queue_table_static_values_1.tableColumnsStartHidden];
        this.columnsAlwaysVisible = [...held_queue_table_static_values_1.tableColumnsAlwaysVisible];
        this.columnsToIgnore = held_queue_table_static_values_1.tableColumnsToIgnore;
        this.selectedRows = [];
        this.isContentAdmin = false;
        this.rowData = [];
        this.onFilterChanged = new core_1.EventEmitter();
        this.onPaginatorChanged = new core_1.EventEmitter();
        this.onRowClicked = new core_1.EventEmitter();
        this.onMessagesReleased = new core_1.EventEmitter();
        this.onMessagesReleasedToSandbox = new core_1.EventEmitter();
        this.onPermittedForRecipient = new core_1.EventEmitter();
        this.onMessagesRejected = new core_1.EventEmitter();
        this.onMessagesRejectedWithNotification = new core_1.EventEmitter();
        this.onMessagesRejectedWithNarrative = new core_1.EventEmitter();
        this.onBlockedForRecipient = new core_1.EventEmitter();
        this.onMessagesReportedAsSpam = new core_1.EventEmitter();
        this.onMessagesReportedAsMalware = new core_1.EventEmitter();
        this.onMessagesReportedAsPhishing = new core_1.EventEmitter();
        this.onResultsExported = new core_1.EventEmitter();
        this.reportMessage = new core_1.EventEmitter();
        this.externalTableSearchCountFilters = 0;
        this.isRowEnabled = () => {
            return true;
            /* eslint-disable-next-line */
        };
        this.heldQueueTableSubscription = this.heldQueueService.heldQueueSearchByReasonValues$.subscribe(() => {
            this.externalTableSearchCountFilters = this.externalTableSearchCountFilters + 1;
        });
        this.heldQueueTableSubscription = this.heldQueueService.heldQueueSearchByGroupValues$.subscribe((heldGroup) => {
            const filters = {
                heldGroup: [heldGroup]
            };
            this.filter.setFilterValue(filters);
        });
    }
    set rows(data) {
        this.rowData = (data || []).map(msg => {
            return Object.assign(Object.assign({}, msg), { route: this.emailRoutePipe.transform(msg.route), heldGroup: this.heldGroupPipe.transform(msg.heldGroup), detectionLevel: this.spamDetectionPipe.transform(msg.detectionLevel) });
        });
    }
    get rows() {
        return this.rowData;
    }
    ngOnInit() {
        this.capabilitiesService.ifCapability('Permission.SERVICE_DELIVERY_READ', () => {
            const group = this.filterSections.find(section => section.name === 'heldGroup');
            if (!!group) {
                group.filters.push('cluster');
            }
        });
        this.capabilitiesService
            .hasCapability('Temporary.MessageCenter.SpamScore')
            .subscribe(isActive => {
            if (isActive === false) {
                const columnsFiltered = this.columns.filter(value => value !== 'spamScore' && value !== 'detectionLevel');
                this.columns = columnsFiltered;
                const exportsFiltered = this.exports.filter(value => value !== 'spamScore' && value !== 'spamDetection');
                this.exports = exportsFiltered;
            }
        });
    }
    isSelectedRow(canSelectMultiple = true) {
        // MAT-6185: Report dropdown should only be enabled for a single row selection.
        // API for message-finder/report-message only handles a single item passed in the request payload.
        // error message from api response: "Payload does not meet max Request items requirement: 1"
        return canSelectMultiple ? this.selectedRows.length > 0 : this.selectedRows.length === 1;
    }
    changeFilter(filters) {
        this.onFilterChanged.emit(this.toHoldQueueFilter(filters));
    }
    toHoldQueueFilter(filters) {
        const filterby = [];
        filterby.push(...this.toFiltersBy('route', filters.route));
        filterby.push(...this.toFiltersBy('heldGroup', filters.heldGroup));
        filterby.push(...this.toFiltersBy('attachments', filters.attachments));
        return { filterBy: filterby };
    }
    toFiltersBy(fieldName, filters) {
        if (!!filters && filters.length > 0) {
            return filters.map(value => {
                return { fieldName, value };
            });
        }
        else {
            return [];
        }
    }
    changePaginator(pagination) {
        this.onPaginatorChanged.emit(pagination);
    }
    clickRow(row) {
        this.onRowClicked.emit(row);
    }
    selectRows(rows) {
        this.selectedRows = rows;
    }
    releaseMessages(messages) {
        this.onMessagesReleased.emit(messages || this.selectedRows);
    }
    releaseMessagesToSandbox(messages) {
        this.onMessagesReleasedToSandbox.emit(messages || this.selectedRows);
    }
    permitForRecipient(messages) {
        this.onPermittedForRecipient.emit(messages || this.selectedRows);
    }
    rejectMessages(messages) {
        this.onMessagesRejected.emit(messages || this.selectedRows);
    }
    RejectMessagesWithNotification(messages) {
        this.onMessagesRejectedWithNotification.emit(messages || this.selectedRows);
    }
    RejectMessagesWithNarrative(messages) {
        this.onMessagesRejectedWithNarrative.emit(messages || this.selectedRows);
    }
    blockForRecipient(messages) {
        this.onBlockedForRecipient.emit(messages || this.selectedRows);
    }
    reportMessageAsSpam(messages) {
        this.onMessagesReportedAsSpam.emit(messages || this.selectedRows);
    }
    reportMessageAsMalware(messages) {
        this.onMessagesReportedAsMalware.emit(messages || this.selectedRows);
    }
    reportMessageAsPhishing(messages) {
        this.onMessagesReportedAsPhishing.emit(messages || this.selectedRows);
    }
    exportResults() {
        this.onResultsExported.emit(this.exports);
    }
    reportEmail(report) {
        this.reportMessage.emit(report);
    }
    ngOnDestroy() {
        this.heldQueueTableSubscription.unsubscribe();
    }
}
exports.HeldQueueTableComponent = HeldQueueTableComponent;
