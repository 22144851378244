"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MepLogSidebarExternalRecipientsListItemComponent = void 0;
class MepLogSidebarExternalRecipientsListItemComponent {
    set flaggedRecipient(value) {
        this._flaggedRecipient = value;
        if (this._flaggedRecipient) {
            this.getSimilarContactDetails();
        }
    }
    get flaggedRecipient() {
        return this._flaggedRecipient;
    }
    getSimilarContactDetails() {
        this.similarContactDetails = {
            similarContactDetails: this.formatSimilarContactDetails(this.flaggedRecipient)
        };
    }
    formatSimilarContactDetails(flaggedToAddress) {
        let similarAddress = `${flaggedToAddress.similarContactAddress}`;
        if (!!flaggedToAddress.similarContactName && !!flaggedToAddress.similarContactName.trim()) {
            similarAddress = `${similarAddress} (${flaggedToAddress.similarContactName})`;
        }
        return similarAddress;
    }
}
exports.MepLogSidebarExternalRecipientsListItemComponent = MepLogSidebarExternalRecipientsListItemComponent;
