"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
(function () {
    'use strict';
    /* ======================================================== *\

     = layout / dashboard simple / directive

     \* ======================================================== */
    document.createElement('mc-layout-dashboard-simple');
    angular.module('layout.dashboard-simple.directive', [])
        .directive('mcLayoutDashboardSimple', ['$translate', function ($translate) {
            return {
                restrict: 'E',
                templateUrl: 'components/layout/dashboard-simple/dashboard-simple.tpl.html',
                scope: {
                    mainContainerClass: '@mainContainerClass',
                    chartsContainerClass: '@chartsContainerClass',
                    headerTitle: '@headerTitle',
                    headerDescription: '@headerDescription',
                    headerFavouriteIsVisible: '=headerFavouriteIsVisible',
                    headerKbLink: '@headerKbLink',
                    headerKbDescKey: '@headerKbDescKey'
                },
                transclude: {
                    headerSideContainer: '?headerSideContainer',
                    chartsContainer: 'chartsContainer'
                },
                link(scope, element, attrs, controller, transcludeFn) {
                    scope.headerKbTranslatedDesc = $translate.instant(scope.headerKbDescKey);
                    scope.headerSideContainer = transcludeFn.isSlotFilled('headerSideContainer');
                }
            };
        }]);
}());
