"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mepPolicyDescriptionValidator = exports.mepPolicyNameValidator = void 0;
function mepPolicyNameValidator(validLengths) {
    return (control) => {
        const value = control.value.trim();
        if (value.length === 0) {
            return { required: true };
        }
        else if (value.length < validLengths.min) {
            return { minLength: true };
        }
        else if (value.length > validLengths.max) {
            return { maxLength: true };
        }
        return null;
    };
}
exports.mepPolicyNameValidator = mepPolicyNameValidator;
function mepPolicyDescriptionValidator(validLengths) {
    return (control) => {
        if (control.value) {
            const value = control.value.trim(); // Trimming leading and trailing spaces.
            if (value.length === 0) {
                return null;
            }
            else if (value.length < validLengths.min) {
                return { minLength: true };
            }
            else if (value.length > validLengths.max) {
                return { maxLength: true };
            }
        }
        return null;
    };
}
exports.mepPolicyDescriptionValidator = mepPolicyDescriptionValidator;
