"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
(function () {
    'use strict';
    document.createElement('mc-layout-page-wizard-step');
    angular.module('layout.page-wizard-step.directive', [])
        .directive('mcLayoutPageWizardStep', [function () {
            return {
                restrict: 'E',
                templateUrl: 'components/layout/page-wizard-step/page-wizard-step.tpl.html',
                scope: {
                    mainContainerClass: '@mainContainerClass',
                    headerTitleKey: '@headerTitleKey',
                    headerDescriptionKey: '@headerDescriptionKey',
                    bottomDescriptionKey: '@bottomDescriptionKey'
                },
                transclude: {
                    mainContainer: 'mainContainer',
                    bottomContainer: '?bottomContainer'
                }
                // TODO: Extended Layout depending if right side container is define or not ????? - Confirm with UI/UX
                // TODO: Hide completely the bottom container depending if is define or not
            };
        }]);
}());
