<mc-modal-header headerTitle="{{'$I18N_SERVICES_CREATE_DEFINITION.CONFIRMATION_MODAL.TITLE' | translate}}">
</mc-modal-header>
<mc-modal-body>
  <p>{{'$I18N_SERVICES_CREATE_DEFINITION.CONFIRMATION_MODAL.DESCRIPTION' | translate}}</p>
</mc-modal-body>
<mc-modal-footer>
  <button type="button" class="btn btn-default"
    (click)="onCancel()">{{'$I18N_SERVICES_CREATE_DEFINITION.CONFIRMATION_MODAL.CANCEL_BUTTON' | translate}}</button>
  <button type="button" class="btn btn-primary"
    (click)="onEnable()">{{'$I18N_SERVICES_CREATE_DEFINITION.CONFIRMATION_MODAL.ENABLE_BUTTON' | translate}}</button>
</mc-modal-footer>