"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeferredExportAction = exports.RejectedExportAction = exports.EXPORT_DEFERRED = exports.EXPORT_REJECTED = void 0;
exports.EXPORT_REJECTED = '[Rejected] Export';
exports.EXPORT_DEFERRED = '[Defferd] Export';
class RejectedExportAction {
    constructor(payload, filter) {
        this.payload = payload;
        this.filter = filter;
        this.type = exports.EXPORT_REJECTED;
    }
}
exports.RejectedExportAction = RejectedExportAction;
class DeferredExportAction {
    constructor(payload, filter) {
        this.payload = payload;
        this.filter = filter;
        this.type = exports.EXPORT_DEFERRED;
    }
}
exports.DeferredExportAction = DeferredExportAction;
