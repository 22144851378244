"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrackingSearchService = void 0;
const operators_1 = require("rxjs/operators");
class TrackingSearchService {
    constructor(http, ts) {
        this.http = http;
        this.ts = ts;
    }
    getMessages(payload) {
        const serverPayload = this.transformToServerPayload(payload);
        return this.http.post('/api/message-finder/search', serverPayload).pipe(operators_1.map((response) => response.first), operators_1.map((first) => first.trackedEmails), operators_1.map((trackedEmails) => this.mapToFrontEndFormat(trackedEmails)));
    }
    mapToFrontEndFormat(trackedEmails) {
        return trackedEmails.map((email) => {
            return Object.assign(Object.assign({}, email), { fromHdr: this.getFromHeaderFrontEndFormat(email), subject: email.subject || this.ts.instant('$I18N_MESSAGE_CENTER_SUBJECT_NOT_AVAILABLE'), info: email.info.replace(/^(Message\sHold\sApplied\s-\s)/, '') });
        });
    }
    transformToServerPayload(payload) {
        if (!payload || Object.keys(payload).length === 0) {
            return {};
        }
        const transformed = this.getTransformedAfterAttachments(payload);
        const transformedWithoutAdvancedIfEmpty = this.removeAdvancedIfNeeded(transformed);
        return transformedWithoutAdvancedIfEmpty;
    }
    getFromHeaderFrontEndFormat(email) {
        return email.fromHdr && email.fromHdr.emailAddress ? email.fromHdr : Object.assign({}, email.fromEnv);
    }
    removeAdvancedIfNeeded(transformedAttachments) {
        let hasValuesInsideAdvanced = false;
        if (!transformedAttachments.advancedTrackAndTraceOptions) {
            return transformedAttachments;
        }
        Object.keys(transformedAttachments.advancedTrackAndTraceOptions).forEach(propertyName => {
            hasValuesInsideAdvanced =
                !!transformedAttachments.advancedTrackAndTraceOptions[propertyName] ||
                    hasValuesInsideAdvanced;
        });
        if (!hasValuesInsideAdvanced) {
            delete transformedAttachments.advancedTrackAndTraceOptions;
        }
        return transformedAttachments;
    }
    getTransformedAfterAttachments(payload) {
        return Object.assign(Object.assign({}, payload), { status: this.parseStatusProperty(payload), attachments: this.parseAttachmentsProperty(payload) });
    }
    parseStatusProperty(payload) {
        const key = '_____';
        const allStatus = payload && payload.status;
        if (allStatus && allStatus.length) {
            const filtered = allStatus.filter(item => item.includes(key));
            const finalStatus = allStatus.filter(item => !item.includes(key));
            filtered.forEach(doubleItem => {
                const afterSplit = doubleItem.split(key);
                afterSplit.forEach(singleItem => {
                    finalStatus.push(singleItem);
                });
            });
            return finalStatus;
        }
        return allStatus;
    }
    parseAttachmentsProperty(payload) {
        const attachmentsArray = (payload && payload && payload.attachments) || [];
        const attachmentsArrayLowerCase = attachmentsArray.map(value => value.toLowerCase());
        const attachmentsArrayLength = attachmentsArrayLowerCase.length;
        return attachmentsArrayLength === 1 ? attachmentsArrayLowerCase[0] === 'true' : undefined;
    }
}
exports.TrackingSearchService = TrackingSearchService;
