"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiIntegrationOktaWizardComponent = void 0;
const core_1 = require("@angular/core");
class ApiIntegrationOktaWizardComponent {
    constructor(store) {
        this.store = store;
        this.onCancel = new core_1.EventEmitter();
        this.onCreateOktaIntegration = new core_1.EventEmitter();
        this.onUpdateOktaIntegration = new core_1.EventEmitter();
        this.onSwitchOktaIntegrationStatus = new core_1.EventEmitter();
        this.onSwitchRotateToken = new core_1.EventEmitter();
        this.onOktaCellSelect = new core_1.EventEmitter();
        this.onEditOktaIntegrationDetails = new core_1.EventEmitter();
        this.onAddEmail = new core_1.EventEmitter();
        this.onDeleteEmail = new core_1.EventEmitter();
        this.DETAILS_STEP = 1;
        this.SETTINGS_STEP = 2;
        this.CONTACTS_STEP = 3;
        this.SUMMARY_STEP = 4;
    }
    wizardTitle() {
        return `$I18N_API_INTEGRATION_OKTA_WIZARD.TITLE_${this.hasCreatedIntegration ? 'EDIT' : 'CREATE'}`;
    }
    showNext() {
        return this.wizard.currentStep !== this.SUMMARY_STEP;
    }
    showPrevious() {
        return this.wizard.currentStep !== this.DETAILS_STEP;
    }
    showCreate() {
        return this.wizard.currentStep === this.SUMMARY_STEP && !this.hasCreatedIntegration;
    }
    showUpdate() {
        if (!!this.wizard) {
            return this.wizard.currentStep === this.SUMMARY_STEP && this.hasCreatedIntegration;
        }
    }
    switchOktaIntegrationStatus(status) {
        this.onSwitchOktaIntegrationStatus.emit(status);
    }
    switchRotateToken(rotate) {
        this.onSwitchRotateToken.emit(rotate);
    }
    selectOktaCell(selectedOktaCell) {
        this.onOktaCellSelect.emit(selectedOktaCell);
    }
    editOktaIntegrationDetails(details) {
        this.onEditOktaIntegrationDetails.emit(details);
    }
    create() {
        this.onCreateOktaIntegration.emit();
    }
    cancel() {
        this.onCancel.emit();
    }
    addEmail(email) {
        this.onAddEmail.emit(email);
    }
    deleteEmail(email) {
        this.onDeleteEmail.emit(email);
    }
    update() {
        this.onUpdateOktaIntegration.emit();
    }
    changeStep() {
        // you have to listen to this event or the underlying wizard doesn't work :(
    }
    gotoPreviousStep() {
        if (!!this.wizard) {
            this.wizard.previousStep();
        }
    }
    gotoNextStep() {
        if (!!this.wizard) {
            this.wizard.nextStep();
        }
    }
    isStepValid() {
        if (!this.isBusy && !!this.wizard) {
            switch (this.wizard.currentStep) {
                case this.DETAILS_STEP:
                    return this.detailsStep.isValid();
                case this.SETTINGS_STEP:
                    return this.settingsStep.isValid();
                case this.CONTACTS_STEP:
                    return this.contactsStep.isValid();
                case this.SUMMARY_STEP:
                    return this.summaryStep.isValid();
                default:
                    return true;
            }
        }
        return false;
    }
}
exports.ApiIntegrationOktaWizardComponent = ApiIntegrationOktaWizardComponent;
