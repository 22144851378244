"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CyberGraphPoliciesPageComponent = void 0;
const listActions = require("../actions/cybergraph-policies-list.actions");
const createActions = require("../actions/cybergraph-policies-create.actions");
class CyberGraphPoliciesPageComponent {
    constructor(store, detailsStore) {
        this.store = store;
        this.detailsStore = detailsStore;
    }
    search(search) {
        this.store.dispatch(new listActions.PoliciesRequestAction({ search }));
    }
    rowClicked(policy) {
        this.detailsStore.dispatch(new listActions.OpenPolicyDetailsSidePanelAction(policy));
    }
    createNewPolicy() {
        this.store.dispatch(new createActions.OpenCreatePolicyPage());
    }
    deletePolicy(policy) {
        this.store.dispatch(new listActions.PolicyDeleteAttemptAction(policy));
    }
    editPolicy(policy) {
        this.store.dispatch(new listActions.EditPolicyFromListView(policy));
    }
}
exports.CyberGraphPoliciesPageComponent = CyberGraphPoliciesPageComponent;
