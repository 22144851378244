"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OverviewTabComponent = void 0;
const searchAction = require("../../actions/search/search.action");
class OverviewTabComponent {
    constructor(store) {
        this.store = store;
    }
    ngOnInit() {
        this.store.dispatch(new searchAction.RemediationSearchResetAction());
    }
}
exports.OverviewTabComponent = OverviewTabComponent;
