"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tableSectionFilters = exports.tableSectionSearch = exports.tableColumnsStartHidden = exports.tableColumnsToIgnore = exports.tableColumnsAlwaysVisible = exports.tableColumns = void 0;
exports.tableColumns = [
    'url',
    'matchType',
    'comment',
    'overrideType',
    'urlRewriting',
    'userAwareness',
    'right-column'
];
exports.tableColumnsAlwaysVisible = ['url', 'matchType', 'overrideType'];
exports.tableColumnsToIgnore = ['select'];
exports.tableColumnsStartHidden = ['comment', 'urlRewriting', 'userAwareness'];
exports.tableSectionSearch = {
    field: 'search',
    header: 'header',
    options: ['onlyDomain', 'domainOrComments', 'url']
};
exports.tableSectionFilters = [
    {
        name: 'overrideType',
        filters: ['permitted', 'blocked']
    }
];
