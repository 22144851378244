"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.notifications = void 0;
const actions = require("../../actions");
exports.notifications = {
    [actions.RELEASE_HELD_EMAIL_SUCCESS]: {
        message: '$I18N_MESSAGE_CENTER_MESSAGE_INFO_EFFECTS.RELEASE_HELD_EMAIL_SUCCESS',
        type: 'success'
    },
    [actions.RELEASE_HELD_EMAIL_FAIL]: {
        message: '$I18N_MESSAGE_CENTER_MESSAGE_INFO_EFFECTS.RELEASE_HELD_EMAIL_FAIL',
        type: 'error'
    },
    [actions.RELEASE_HELD_EMAIL_TO_SANDBOX_SUCCESS]: {
        message: '$I18N_MESSAGE_CENTER_MESSAGE_INFO_EFFECTS.RELEASE_HELD_EMAIL_TO_SANDBOX_SUCCESS',
        type: 'success'
    },
    [actions.RELEASE_HELD_EMAIL_TO_SANDBOX_FAIL]: {
        message: '$I18N_MESSAGE_CENTER_MESSAGE_INFO_EFFECTS.RELEASE_HELD_EMAIL_TO_SANDBOX_FAIL',
        type: 'error'
    },
    [actions.PERMIT_SENDER_FOR_RECIPIENT_SUCCESS]: {
        message: '$I18N_MESSAGE_CENTER_MESSAGE_INFO_EFFECTS.PERMIT_SENDER_FOR_RECIPIENT_SUCCESS',
        type: 'success'
    },
    [actions.PERMIT_SENDER_FOR_RECIPIENT_FAIL]: {
        message: '$I18N_MESSAGE_CENTER_MESSAGE_INFO_EFFECTS.PERMIT_SENDER_FOR_RECIPIENT_FAIL',
        type: 'error'
    },
    [actions.BLOCK_SENDER_FOR_RECIPIENT_SUCCESS]: {
        message: '$I18N_MESSAGE_CENTER_MESSAGE_INFO_EFFECTS.BLOCK_SENDER_FOR_RECIPIENT_SUCCESS',
        type: 'success'
    },
    [actions.BLOCK_SENDER_FOR_RECIPIENT_FAIL]: {
        message: '$I18N_MESSAGE_CENTER_MESSAGE_INFO_EFFECTS.BLOCK_SENDER_FOR_RECIPIENT_FAIL',
        type: 'error'
    },
    [actions.REJECT_HELD_EMAIL_SUCCESS]: {
        message: '$I18N_MESSAGE_CENTER_MESSAGE_INFO_EFFECTS.REJECT_HELD_EMAIL_SUCCESS',
        type: 'success'
    },
    [actions.REJECT_HELD_EMAIL_FAIL]: {
        message: '$I18N_MESSAGE_CENTER_MESSAGE_INFO_EFFECTS.REJECT_HELD_EMAIL_FAIL',
        type: 'error'
    },
    [actions.REJECT_HELD_EMAIL_WITH_NOTIFICATION_SUCCESS]: {
        message: '$I18N_MESSAGE_CENTER_MESSAGE_INFO_EFFECTS.REJECT_HELD_EMAIL_WITH_NOTIFICATION_SUCCESS',
        type: 'success'
    },
    [actions.REJECT_HELD_EMAIL_WITH_NOTIFICATION_FAIL]: {
        message: '$I18N_MESSAGE_CENTER_MESSAGE_INFO_EFFECTS.REJECT_HELD_EMAIL_WITH_NOTIFICATION_FAIL',
        type: 'error'
    },
    [actions.RETRY_DELIVERY_SUCCESS]: {
        message: '$I18N_MESSAGE_CENTER_MESSAGE_INFO_EFFECTS.RETRY_DELIVERY_SUCCESS',
        type: 'success'
    },
    [actions.RETRY_DELIVERY_FAIL]: {
        message: '$I18N_MESSAGE_CENTER_MESSAGE_INFO_EFFECTS.RETRY_DELIVERY_FAIL',
        type: 'error'
    },
    [actions.REJECT_QUEUED_EMAIL_SUCCESS]: {
        message: '$I18N_MESSAGE_CENTER_MESSAGE_INFO_EFFECTS.REJECT_QUEUED_EMAIL_SUCCESS',
        type: 'success'
    },
    [actions.REJECT_QUEUED_EMAIL_FAIL]: {
        message: '$I18N_MESSAGE_CENTER_MESSAGE_INFO_EFFECTS.REJECT_QUEUED_EMAIL_FAIL',
        type: 'error'
    },
    [actions.BOUNCE_QUEUED_EMAIL_SUCCESS]: {
        message: '$I18N_MESSAGE_CENTER_MESSAGE_INFO_EFFECTS.BOUNCE_QUEUED_EMAIL_SUCCESS',
        type: 'success'
    },
    [actions.BOUNCE_QUEUED_EMAIL_FAIL]: {
        message: '$I18N_MESSAGE_CENTER_MESSAGE_INFO_EFFECTS.BOUNCE_QUEUED_EMAIL_FAIL',
        type: 'error'
    },
    [actions.RECALCULATE_DELIVERY_ROUTE_SUCCESS]: {
        message: '$I18N_MESSAGE_CENTER_MESSAGE_INFO_EFFECTS.RECALCULATE_DELIVERY_ROUTE_SUCCESS',
        type: 'success'
    },
    [actions.RECALCULATE_DELIVERY_ROUTE_FAIL]: {
        message: '$I18N_MESSAGE_CENTER_MESSAGE_INFO_EFFECTS.RECALCULATE_DELIVERY_ROUTE_FAIL',
        type: 'error'
    },
    [actions.COPY_TO_CLIPBOARD_SUCCESS]: {
        message: '$I18N_MESSAGE_CENTER_MESSAGE_INFO_EFFECTS.COPY_TO_CLIPBOARD_SUCCESS',
        type: 'success'
    }
};
