"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewRuleComponent = void 0;
const rule_details_actions_1 = require("app-new/archive/supervision/actions/rule.details.actions");
const selectors = require("app-new/archive/supervision/reducers/index");
const state_enum_1 = require("app-new/archive/supervision/types/state.enum");
const rule_actions_1 = require("app-new/archive/supervision/actions/rule.actions");
const ruleListActions = require("app-new/archive/supervision/actions/rule.list.actions");
const rule_delete_notification_modal_component_1 = require("app-new/archive/supervision/components/rule-delete-notification-modal/rule-delete-notification-modal.component");
class ViewRuleComponent {
    constructor(capabilitiesService, store, tableContent, modalService, overlayRef) {
        this.capabilitiesService = capabilitiesService;
        this.store = store;
        this.tableContent = tableContent;
        this.modalService = modalService;
        this.overlayRef = overlayRef;
        this.canDeleteRule = 'Temporary.Supervision.Delete && Permission.SUPERVISION_EDIT';
    }
    ngOnInit() {
        this.hasEditPermission$ = this.capabilitiesService.hasCapability('Permission.SUPERVISION_EDIT');
        this.ruleDetails$ = this.store.select(selectors.getRuleDetails);
        this.ruleLoading$ = this.store.select(selectors.getRuleDetailsLoading);
        this.paginationInfo$ = this.store.select(selectors.getRuleDetailsPagination);
        this.hasDeleteCapability$ = this.capabilitiesService.evalCapabilitiesExpression(this.canDeleteRule);
    }
    ngOnDestroy() {
        this.store.dispatch(new rule_details_actions_1.CleanRuleDetailsAction());
    }
    loadDetails(rule) {
        this.store.dispatch(new rule_details_actions_1.GetRuleDetailsAction(rule));
        this.store.dispatch(new rule_details_actions_1.UpdateRuleDetailsPaginationInfoAction(rule, this.overlayRef.componentInstance.tableContent));
    }
    edit(rule) {
        this.store.dispatch(new ruleListActions.OpenEditRuleAction(rule.id));
    }
    close() {
        if (this.overlayRef) {
            this.overlayRef.close();
        }
    }
    enableDisable(rule) {
        this.store.dispatch(new rule_actions_1.SetRuleStateAction(rule.id, rule.state === state_enum_1.RuleState.ACTIVE ? state_enum_1.RuleState.INACTIVE : state_enum_1.RuleState.ACTIVE));
    }
    deleteRule(selectedRule) {
        const modal = this.modalService.open(rule_delete_notification_modal_component_1.RuleDeleteNotificationModalComponent, {
            size: 'lg',
            hasBackdrop: true,
            disableClose: true,
            data: {
                ruleName: selectedRule.name,
                lexiconName: selectedRule.lexicon && selectedRule.lexicon.name,
                queueName: selectedRule.reviewQueue.name
            }
        });
        modal.afterClose().subscribe(confirmed => {
            if (confirmed) {
                this.store.dispatch(new ruleListActions.DeleteRuleAction({ id: selectedRule.id }));
            }
        });
    }
}
exports.ViewRuleComponent = ViewRuleComponent;
