"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BackupListImpl = void 0;
const table_store_1 = require("@mimecast-ui/table-store");
const notification_actions_1 = require("app-new/components/notification/actions/notification.actions");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const actions_1 = require("../actions");
class BackupListImpl {
    constructor(backupApiService, store, translateService) {
        this.backupApiService = backupApiService;
        this.store = store;
        this.translateService = translateService;
    }
    getData(payload = {}, pagination = table_store_1.defaultPaginationOption) {
        return this.backupApiService.getOneDriveBackups(payload, pagination).pipe(operators_1.map((response) => {
            if (response.hasErrors) {
                const errorTranslation = this.translateService.instant('$I18N_ONEDRIVE_BACKUP_LIST.NOTIFICATION.LOADING_LIST.' +
                    response.fail[0].errors[0].code.toUpperCase());
                this.store.dispatch(new notification_actions_1.NotificationShowFailAction(errorTranslation));
                this.store.dispatch(new actions_1.LoadBackupsFailureAction());
                return {
                    hasErrors: response.hasErrors,
                    data: response.all,
                    failures: response.fail,
                    meta: { pagination: { pageSize: 0 } }
                };
            }
            else {
                this.store.dispatch(new actions_1.LoadBackupsSuccessAction());
                const RETURN_DATA = {
                    hasErrors: response.hasErrors,
                    data: response.all && response.all[0].backups,
                    failures: response.fail,
                    meta: response.meta
                };
                return RETURN_DATA;
            }
        }), operators_1.catchError((response) => {
            const RETURN_DATA = {
                hasErrors: response.hasErrors,
                data: response.all,
                failures: response.fail,
                meta: response.meta
            };
            return rxjs_1.of(RETURN_DATA);
        }));
    }
    filter(payload, pagination) {
        return this.getData(payload, pagination);
    }
    openItem(row) {
        this.store.dispatch(new actions_1.LoadBackupDetailsAction(row.id));
        return rxjs_1.of();
    }
}
exports.BackupListImpl = BackupListImpl;
BackupListImpl.ONE_DRIVE_BACKUP_LIST_STORE_ID = 'OneDriveBackupList';
