"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoadBackupDetailsFailureAction = exports.LoadBackupDetailsSuccessAction = exports.LoadBackupDetailsAction = exports.OpenEditBackupFailureAction = exports.OpenEditBackupSuccessAction = exports.OpenEditBackupAction = exports.LoadBackupsFailureAction = exports.LoadBackupsSuccessAction = exports.DeleteBackupFailureAction = exports.DeleteBackupSuccessAction = exports.DeleteBackupAction = exports.ChangeBackupStatusFailureAction = exports.ChangeBackupStatusSuccessAction = exports.ChangeBackupStatusAction = exports.UpdateBackupFailureAction = exports.UpdateBackupSuccessAction = exports.UpdateBackupAction = exports.CreateBackupFailureAction = exports.CreateBackupSuccessAction = exports.CreateBackupAction = exports.BackupActions = void 0;
var BackupActions;
(function (BackupActions) {
    BackupActions["CREATE_BACKUP"] = "CREATE_BACKUP";
    BackupActions["CREATE_BACKUP_SUCCESS"] = "CREATE_BACKUP_SUCCESS";
    BackupActions["CREATE_BACKUP_FAILURE"] = "CREATE_BACKUP_FAILURE";
    BackupActions["UPDATE_BACKUP"] = "UPDATE_BACKUP";
    BackupActions["UPDATE_BACKUP_SUCCESS"] = "UPDATE_BACKUP_SUCCESS";
    BackupActions["UPDATE_BACKUP_FAILURE"] = "UPDATE_BACKUP_FAILURE";
    BackupActions["CHANGE_BACKUP_STATUS"] = "CHANGE_BACKUP_STATUS";
    BackupActions["CHANGE_BACKUP_STATUS_SUCCESS"] = "CHANGE_BACKUP_STATUS_SUCCESS";
    BackupActions["CHANGE_BACKUP_STATUS_FAILURE"] = "CHANGE_BACKUP_STATUS_FAILURE";
    BackupActions["DELETE_BACKUP"] = "DELETE_BACKUP";
    BackupActions["DELETE_BACKUP_SUCCESS"] = "DELETE_BACKUP_SUCESS";
    BackupActions["DELETE_BACKUP_FAILURE"] = "DELETE_BACKUP_FAILURE";
    BackupActions["LOAD_BACKUPS_TABLE_SUCCESS"] = "LOAD_BACKUPS_TABLE_SUCCESS";
    BackupActions["LOAD_BACKUPS_TABLE_FAILURE"] = "LOAD_BACKUPS_TABLE_FAILURE";
    BackupActions["OPEN_EDIT_BACKUP"] = "OPEN_EDIT_BACKUP";
    BackupActions["OPEN_EDIT_BACKUP_SUCCESS"] = "OPEN_EDIT_BACKUP_SUCCESS";
    BackupActions["OPEN_EDIT_BACKUP_FAILURE"] = "OPEN_EDIT_BACKUP_FAILURE";
    BackupActions["LOAD_BACKUP_DETAILS"] = "LOAD_BACKUP_DETAILS";
    BackupActions["LOAD_BACKUP_DETAILS_SUCCESS"] = "LOAD_BACKUP_DETAILS_SUCCESS";
    BackupActions["LOAD_BACKUP_DETAILS_FAILURE"] = "LOAD_BACKUP_DETAILS_FAILURE";
})(BackupActions = exports.BackupActions || (exports.BackupActions = {}));
class CreateBackupAction {
    constructor(payload) {
        this.payload = payload;
        this.type = BackupActions.CREATE_BACKUP;
    }
}
exports.CreateBackupAction = CreateBackupAction;
class CreateBackupSuccessAction {
    constructor() {
        this.type = BackupActions.CREATE_BACKUP_SUCCESS;
    }
}
exports.CreateBackupSuccessAction = CreateBackupSuccessAction;
class CreateBackupFailureAction {
    constructor() {
        this.type = BackupActions.CREATE_BACKUP_FAILURE;
    }
}
exports.CreateBackupFailureAction = CreateBackupFailureAction;
class UpdateBackupAction {
    constructor(backupId, backup, returnActions = []) {
        this.backupId = backupId;
        this.backup = backup;
        this.returnActions = returnActions;
        this.type = BackupActions.UPDATE_BACKUP;
    }
}
exports.UpdateBackupAction = UpdateBackupAction;
class UpdateBackupSuccessAction {
    constructor() {
        this.type = BackupActions.UPDATE_BACKUP_SUCCESS;
    }
}
exports.UpdateBackupSuccessAction = UpdateBackupSuccessAction;
class UpdateBackupFailureAction {
    constructor() {
        this.type = BackupActions.UPDATE_BACKUP_FAILURE;
    }
}
exports.UpdateBackupFailureAction = UpdateBackupFailureAction;
class ChangeBackupStatusAction {
    constructor(payload) {
        this.payload = payload;
        this.type = BackupActions.CHANGE_BACKUP_STATUS;
    }
}
exports.ChangeBackupStatusAction = ChangeBackupStatusAction;
class ChangeBackupStatusSuccessAction {
    constructor() {
        this.type = BackupActions.CHANGE_BACKUP_STATUS_SUCCESS;
    }
}
exports.ChangeBackupStatusSuccessAction = ChangeBackupStatusSuccessAction;
class ChangeBackupStatusFailureAction {
    constructor() {
        this.type = BackupActions.CHANGE_BACKUP_STATUS_FAILURE;
    }
}
exports.ChangeBackupStatusFailureAction = ChangeBackupStatusFailureAction;
class DeleteBackupAction {
    constructor(payload) {
        this.payload = payload;
        this.type = BackupActions.DELETE_BACKUP;
    }
}
exports.DeleteBackupAction = DeleteBackupAction;
class DeleteBackupSuccessAction {
    constructor() {
        this.type = BackupActions.DELETE_BACKUP_SUCCESS;
    }
}
exports.DeleteBackupSuccessAction = DeleteBackupSuccessAction;
class DeleteBackupFailureAction {
    constructor() {
        this.type = BackupActions.DELETE_BACKUP_FAILURE;
    }
}
exports.DeleteBackupFailureAction = DeleteBackupFailureAction;
class LoadBackupsSuccessAction {
    constructor() {
        this.type = BackupActions.LOAD_BACKUPS_TABLE_SUCCESS;
    }
}
exports.LoadBackupsSuccessAction = LoadBackupsSuccessAction;
class LoadBackupsFailureAction {
    constructor() {
        this.type = BackupActions.LOAD_BACKUPS_TABLE_FAILURE;
    }
}
exports.LoadBackupsFailureAction = LoadBackupsFailureAction;
class OpenEditBackupAction {
    constructor(backupId, returnActions = []) {
        this.backupId = backupId;
        this.returnActions = returnActions;
        this.type = BackupActions.OPEN_EDIT_BACKUP;
    }
}
exports.OpenEditBackupAction = OpenEditBackupAction;
class OpenEditBackupSuccessAction {
    constructor() {
        this.type = BackupActions.OPEN_EDIT_BACKUP_SUCCESS;
    }
}
exports.OpenEditBackupSuccessAction = OpenEditBackupSuccessAction;
class OpenEditBackupFailureAction {
    constructor() {
        this.type = BackupActions.OPEN_EDIT_BACKUP_FAILURE;
    }
}
exports.OpenEditBackupFailureAction = OpenEditBackupFailureAction;
class LoadBackupDetailsAction {
    constructor(backupId) {
        this.backupId = backupId;
        this.type = BackupActions.LOAD_BACKUP_DETAILS;
    }
}
exports.LoadBackupDetailsAction = LoadBackupDetailsAction;
class LoadBackupDetailsSuccessAction {
    constructor(backup) {
        this.backup = backup;
        this.type = BackupActions.LOAD_BACKUP_DETAILS_SUCCESS;
    }
}
exports.LoadBackupDetailsSuccessAction = LoadBackupDetailsSuccessAction;
class LoadBackupDetailsFailureAction {
    constructor() {
        this.type = BackupActions.LOAD_BACKUP_DETAILS_FAILURE;
    }
}
exports.LoadBackupDetailsFailureAction = LoadBackupDetailsFailureAction;
