"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgActivityReportSidebarContainerComponent = void 0;
const table_store_1 = require("@mimecast-ui/table-store");
const sidebar_actions_1 = require("app-new/swg/activity-report/actions/sidebar.actions");
class SwgActivityReportSidebarContainerComponent extends table_store_1.TableRowDetailBaseComponent {
    constructor(store, swgService) {
        super(store, 'SwgActivityReportList');
        this.store = store;
        this.swgService = swgService;
        this.hasSwgFull = this.swgService.hasWebSecurityAccountPackage();
        this.hasPrivateIpSupport = this.swgService.hasPrivateIpReportSupport();
        this.hasApplicationVisibility = this.swgService.hasApplicationVisibilityEnabled();
    }
    close() {
        this.store.dispatch(new sidebar_actions_1.SwgActivityReportSidebarClose());
    }
}
exports.SwgActivityReportSidebarContainerComponent = SwgActivityReportSidebarContainerComponent;
