"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomDomainBlockDetails = void 0;
const custom_domain_block_1 = require("./custom-domain-block");
class CustomDomainBlockDetails extends custom_domain_block_1.CustomDomainBlock {
    constructor(definitionId, policyId, name, description, action, notify, configurationId, lastUpdated, enabled, appliesTo, notifyGroupId, notifyGroupDescription, redirectToType, redirectToUser, redirectToGroupId, redirectToGroupDescription) {
        super(definitionId, policyId, name, description, action, notify, configurationId, lastUpdated, enabled);
        this.definitionId = definitionId;
        this.policyId = policyId;
        this.name = name;
        this.description = description;
        this.action = action;
        this.notify = notify;
        this.configurationId = configurationId;
        this.lastUpdated = lastUpdated;
        this.enabled = enabled;
        this.appliesTo = appliesTo;
        this.notifyGroupId = notifyGroupId;
        this.notifyGroupDescription = notifyGroupDescription;
        this.redirectToType = redirectToType;
        this.redirectToUser = redirectToUser;
        this.redirectToGroupId = redirectToGroupId;
        this.redirectToGroupDescription = redirectToGroupDescription;
    }
}
exports.CustomDomainBlockDetails = CustomDomainBlockDetails;
