"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./area-simple/area-simple"); //layout.area-simple
require("./board-simple/board-simple"); //layout.board-simple
require("./list-simple/list-simple"); //layout.list-simple
require("./list-filters/list-filters"); //layout.list-filters
require("./list-extra-column/list-extra-column"); //layout.list-extra-column
require("./page-simple/page-simple"); //layout.page-simple
require("./page-list-filters/page-list-filters"); //layout.page-list-filters
require("./page-wizard/page-wizard"); //layout.page-wizard
require("./side-simple/side-simple"); //layout.side-simple
require("./page-simple-summary/page-simple-summary"); //layout.page-simple-summary
require("./dashboard-simple/dashboard-simple"); //layout.dashboard-simple
// html and css 
require("./layout.less");
require("./side/side.tpl.html");
(function () {
    'use strict';
    /* ======================================================== *\

     = layout
     = jcarradinha

     \* ======================================================== */
    angular.module('layout', [
        'layout.area-simple',
        'layout.board-simple',
        'layout.list-simple',
        'layout.list-filters',
        'layout.list-extra-column',
        'layout.page-simple',
        'layout.page-list-filters',
        'layout.page-wizard',
        'layout.side-simple',
        'layout.page-simple-summary',
        'layout.dashboard-simple'
    ]);
}());
