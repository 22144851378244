"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgDashboardContainerComponent = void 0;
const swgDashboardReducers = require("../reducers");
const swg_dashboard_actions_1 = require("../actions/swg-dashboard.actions");
class SwgDashboardContainerComponent {
    constructor(store) {
        this.store = store;
        this.store.dispatch(new swg_dashboard_actions_1.SwgLoadActivityChart());
        this.store.dispatch(new swg_dashboard_actions_1.SwgLoadTopDomainsChart());
        this.store.dispatch(new swg_dashboard_actions_1.SwgLoadTopCategoriesChart());
        this.store.dispatch(new swg_dashboard_actions_1.SwgLoadTopBlockedDomainsChart());
        this.store.dispatch(new swg_dashboard_actions_1.SwgLoadTopBlockedCategoriesChart());
        this.activityChart$ = this.store.select(swgDashboardReducers.getActivityChartData);
        this.isActivityChartLoading$ = this.store.select(swgDashboardReducers.getActivityChartLoading);
        this.activityChartTotalRequests$ = this.store.select(swgDashboardReducers.getActivityChartTotalRequests);
        this.topDomainsChart$ = this.store.select(swgDashboardReducers.getTopDomainsChartData);
        this.isTopDomainsChartLoading$ = this.store.select(swgDashboardReducers.getTopDomainsChartLoading);
        this.topDomainsChartTotalRequests$ = this.store.select(swgDashboardReducers.getTopDomainsChartTotalRequests);
        this.topCategoriesChart$ = this.store.select(swgDashboardReducers.getTopCategoriesChartData);
        this.isTopCategoriesChartLoading$ = this.store.select(swgDashboardReducers.getTopCategoriesChartLoading);
        this.topCategoriesChartTotalRequests$ = this.store.select(swgDashboardReducers.getTopCategoriesChartTotalRequests);
        this.topBlockedDomainsChart$ = this.store.select(swgDashboardReducers.getTopBlockedDomainsChartData);
        this.isTopBlockedDomainsChartLoading$ = this.store.select(swgDashboardReducers.getTopBlockedDomainsChartLoading);
        this.topBlockedDomainsChartTotalRequests$ = this.store.select(swgDashboardReducers.getTopBlockedDomainsChartTotalRequests);
        this.topBlockedCategoriesChart$ = this.store.select(swgDashboardReducers.getTopBlockedCategoriesChartData);
        this.isTopBlockedCategoriesChartLoading$ = this.store.select(swgDashboardReducers.getTopBlockedCategoriesChartLoading);
        this.topBlockedCategoriesChartTotalRequests$ = this.store.select(swgDashboardReducers.getTopBlockedCategoriesChartTotalRequests);
        this.activityChartAllowedRequests$ = this.store.select(swgDashboardReducers.getActivityChartAllowedRequests);
        this.activityChartBlockedRequests$ = this.store.select(swgDashboardReducers.getActivityChartBlockedRequests);
    }
}
exports.SwgDashboardContainerComponent = SwgDashboardContainerComponent;
