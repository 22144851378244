"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SummaryByGroupFailAction = exports.SummaryByGroupSuccessAction = exports.SummaryByGroupRequestAction = exports.SUMMARY_BY_GROUP_FAIL = exports.SUMMARY_BY_GROUP_SUCCESS = exports.SUMMARY_BY_GROUP_REQUEST = void 0;
exports.SUMMARY_BY_GROUP_REQUEST = '[Held Messages / Overview / Messages Held by Group] Request Get Held Message Summary By Group';
exports.SUMMARY_BY_GROUP_SUCCESS = '[Held Messages / Overview / Messages Held by Group] Request Get Held Message Summary By Group Success';
exports.SUMMARY_BY_GROUP_FAIL = '[Held Messages / Overview / Messages Held by Group] Request Get Held Message Summary By Group Fail';
class SummaryByGroupRequestAction {
    constructor() {
        this.type = exports.SUMMARY_BY_GROUP_REQUEST;
    }
}
exports.SummaryByGroupRequestAction = SummaryByGroupRequestAction;
class SummaryByGroupSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SUMMARY_BY_GROUP_SUCCESS;
    }
}
exports.SummaryByGroupSuccessAction = SummaryByGroupSuccessAction;
class SummaryByGroupFailAction {
    constructor() {
        this.type = exports.SUMMARY_BY_GROUP_FAIL;
    }
}
exports.SummaryByGroupFailAction = SummaryByGroupFailAction;
