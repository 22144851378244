"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RemediationGetGroupResetStore = exports.RemediationGetGroupFail = exports.RemediationGetGroupNotSet = exports.RemediationGetGroupSuccess = exports.RemediationGetGroup = exports.GET_GROUP_RESET_STORE = exports.GET_GROUP_NOT_SET = exports.GET_GROUP_FAIL = exports.GET_GROUP_SUCCESS = exports.GET_GROUP = void 0;
exports.GET_GROUP = '[Remediation] Retrieve group';
exports.GET_GROUP_SUCCESS = '[Remediation] Retrieve group success';
exports.GET_GROUP_FAIL = '[Remediation] Retrieve group fail';
exports.GET_GROUP_NOT_SET = '[Remediation] Retrieve group not set';
exports.GET_GROUP_RESET_STORE = '[Remediation] Retrieve group reset store';
class RemediationGetGroup {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_GROUP;
    }
}
exports.RemediationGetGroup = RemediationGetGroup;
class RemediationGetGroupSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_GROUP_SUCCESS;
    }
}
exports.RemediationGetGroupSuccess = RemediationGetGroupSuccess;
class RemediationGetGroupNotSet {
    constructor() {
        this.type = exports.GET_GROUP_NOT_SET;
    }
}
exports.RemediationGetGroupNotSet = RemediationGetGroupNotSet;
class RemediationGetGroupFail {
    constructor() {
        this.type = exports.GET_GROUP_FAIL;
    }
}
exports.RemediationGetGroupFail = RemediationGetGroupFail;
class RemediationGetGroupResetStore {
    constructor() {
        this.type = exports.GET_GROUP_RESET_STORE;
    }
}
exports.RemediationGetGroupResetStore = RemediationGetGroupResetStore;
