"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectQueueComponent = void 0;
const operators_1 = require("rxjs/operators");
const rule_actions_1 = require("app-new/archive/supervision/actions/rule.actions");
const selectors = require("app-new/archive/supervision/reducers/index");
class SelectQueueComponent {
    constructor(store) {
        this.store = store;
    }
    ngOnInit() {
        this.queueLoading$ = this.store.select(selectors.getRuleLoading);
        this.allQueues$ = this.store.select(selectors.getRuleQueues);
        this.selectedQueue$ = this.store.select(selectors.getSelectedRuleQueue);
        this.matchingQueues$ = this.allQueues$;
    }
    ngOnDestroy() {
        this.store.dispatch(new rule_actions_1.CloseSelectQueueSidePanelAction());
    }
    matchQueues(query) {
        this.matchingQueues$ = this.allQueues$.pipe(operators_1.map(queues => {
            if (queues) {
                return queues.filter(q => q.name.toLocaleLowerCase().includes(query.toLocaleLowerCase()));
            }
            else {
                return [];
            }
        }));
    }
    addQueue(selectedQueue) {
        this.store.dispatch(new rule_actions_1.AddSelectedQueueAction(selectedQueue));
    }
}
exports.SelectQueueComponent = SelectQueueComponent;
