<form class="form-horizontal"
      mcDefaultLabelClass="col-sm-4"
      mcDefaultControlContainerClass="col-sm-8"
      [formGroup]="appAsideForm">
    <mc-collapsable-panel header="$I18N_IDENTITY_APP_DETAILS.DETAILS.HEADER" [isExpanded]="true" class="mc-details">
        <div class="mc-detailed-list mc-detailed-list-two-columns" *ngIf="!isEditMode">
            <div class="row mc-detailed-list-row">
                <div class="col-sm-4 mc-detailed-list-label">{{'$I18N_IDENTITY_APP_DETAILS.DETAILS.NAME' | translate}}
                </div>
                <div class="mc-detailed-list-column mc-name">{{ app.name }}</div>
            </div>
        </div>

        <div *ngIf="isEditMode">
            <mc-text-field class="mc-form-name"
                           label="$I18N_IDENTITY_APP_DETAILS.DETAILS.NAME"
                           errorPrefix="$I18N_IDENTITY_APP_DETAILS.VALIDATION.APP_NAME"
                           formControlName="name" required>
            </mc-text-field>
        </div>
    </mc-collapsable-panel>

    <hr/>

    <mc-collapsable-panel header="$I18N_IDENTITY_APP_DETAILS.PROVIDER_SETTINGS.HEADER" [isExpanded]="true">
        <mc-inline-notification *ngIf="!app.acsUrl" [notification]="providerSettingsInlineNotification"
                                class="mc-identity-provider-settings-notification"></mc-inline-notification>

        <div class="mc-detailed-list">
            <div class="row mc-detailed-list-row">
                <div class="col-sm-4 mc-detailed-list-label">
                    {{'$I18N_IDENTITY_APP_DETAILS.PROVIDER_SETTINGS.X509_CERTIFICATE' | translate}}
                </div>
                <div class="mc-detailed-list-column mc-identity-detailed-list-text mc-x509-certificate">
                    {{ app.certificates[0].certificate }}
                </div>
                <a ngxClipboard
                   class="pull-right cursor-pointer text-normal mc-identity-detailed-list-copy"
                   [cbContent]="app.certificates[0].certificate"
                   (cbOnSuccess)="onClipboardSuccess('$I18N_IDENTITY_APP_DETAILS.PROVIDER_SETTINGS.X509_CERTIFICATE')">
                    <i class="far fa-copy"></i>
                </a>
            </div>
            <div class="row mc-detailed-list-row">
                <div class="col-sm-4 mc-detailed-list-label">
                    {{'$I18N_IDENTITY_APP_DETAILS.PROVIDER_SETTINGS.ISSUER_URL' | translate}}
                </div>
                <div class="mc-detailed-list-column mc-identity-detailed-list-text mc-idp-issuer-url">
                    {{ app.idpIssuerUrl }}
                </div>
                <a ngxClipboard
                   class="pull-right cursor-pointer text-normal mc-identity-detailed-list-copy"
                   [cbContent]="app.idpIssuerUrl"
                   (cbOnSuccess)="onClipboardSuccess('$I18N_IDENTITY_APP_DETAILS.PROVIDER_SETTINGS.ISSUER_URL')">
                    <i class="far fa-copy"></i>
                </a>
            </div>
            <div class="row mc-detailed-list-row">
                <div class="col-sm-4 mc-detailed-list-label">
                    {{'$I18N_IDENTITY_APP_DETAILS.PROVIDER_SETTINGS.LOGIN_URL' | translate}}
                </div>
                <div class="mc-detailed-list-column mc-identity-detailed-list-text mc-idp-login-url">
                    {{ app.idpLoginUrl }}
                </div>
                <a ngxClipboard
                   class="pull-right cursor-pointer text-normal mc-identity-detailed-list-copy"
                   [cbContent]="app.idpLoginUrl"
                   (cbOnSuccess)="onClipboardSuccess('$I18N_IDENTITY_APP_DETAILS.PROVIDER_SETTINGS.LOGIN_URL')">
                    <i class="far fa-copy"></i>
                </a>
            </div>
            <div class="row mc-detailed-list-row">
                <div class="col-sm-4 mc-detailed-list-label">
                    {{'$I18N_IDENTITY_APP_DETAILS.PROVIDER_SETTINGS.LOGOUT_URL' | translate}}
                </div>
                <div class="mc-detailed-list-column mc-identity-detailed-list-text mc-idp-logout-url">
                    {{ app.idpLogoutUrl }}
                </div>
                <a ngxClipboard
                   class="pull-right cursor-pointer text-normal mc-identity-detailed-list-copy"
                   [cbContent]="app.idpLogoutUrl"
                   (cbOnSuccess)="onClipboardSuccess('$I18N_IDENTITY_APP_DETAILS.PROVIDER_SETTINGS.LOGOUT_URL')">
                    <i class="far fa-copy"></i>
                </a>
            </div>
            <div class="row mc-detailed-list-row">
                <div class="col-sm-4 mc-detailed-list-label">
                    {{'$I18N_IDENTITY_APP_DETAILS.PROVIDER_SETTINGS.FEDERATION_METADATA_URL' | translate}}
                </div>
                <div class="mc-detailed-list-column mc-identity-detailed-list-text mc-idp-metadata-url">
                    {{ app.idpMetadataUrl }}
                </div>
                <a ngxClipboard
                   class="pull-right cursor-pointer text-normal mc-identity-detailed-list-copy"
                   [cbContent]="app.idpMetadataUrl"
                   (cbOnSuccess)="onClipboardSuccess('$I18N_IDENTITY_APP_DETAILS.PROVIDER_SETTINGS.FEDERATION_METADATA_URL')">
                    <i class="far fa-copy"></i>
                </a>
            </div>
        </div>
    </mc-collapsable-panel>

    <hr/>

    <mc-collapsable-panel header="$I18N_IDENTITY_APP_DETAILS.SERVICE_PROVIDER_SETTINGS.HEADER" [isExpanded]="true">
        <ng-container *ngIf="!app.acsUrl">
            <mc-inline-notification *ngIf="isEditMode" [notification]="spSettingsInlineNotification"
                                    class="mc-service-provider-settings-notification"></mc-inline-notification>
            <mc-inline-notification *ngIf="!isEditMode" [notification]="spSettingsInlineNotificationWithAction"
                                    class="mc-service-provider-settings-notification-action"></mc-inline-notification>
        </ng-container>

        <div *ngIf="!isEditMode && app.acsUrl" class="mc-detailed-list mc-detailed-list-two-columns">
            <div class="row mc-detailed-list-row">
                <div class="col-sm-4 mc-detailed-list-label">
                    {{'$I18N_IDENTITY_APP_DETAILS.SERVICE_PROVIDER_SETTINGS.ASSERTION_CONSUMER_SERVICE_URL' |
                    translate}}
                </div>
                <div class="mc-detailed-list-column mc-assertion-consumer-service-url">{{ app.acsUrl }}</div>
            </div>
            <div class="mc-detailed-list mc-detailed-list-two-columns" *ngIf="app.logoutUrl">
                <div class="row mc-detailed-list-row">
                    <div class="col-sm-4 mc-detailed-list-label">
                        {{'$I18N_IDENTITY_APP_DETAILS.SERVICE_PROVIDER_SETTINGS.SINGLE_LOGOUT_URL' | translate}}
                    </div>
                    <div class="mc-detailed-list-column mc-single-logout-url">{{ app.logoutUrl }}</div>
                </div>
            </div>
        </div>

        <div *ngIf="isEditMode">
            <mc-text-field class="mc-form-acs-url"
                           label="$I18N_IDENTITY_APP_DETAILS.SERVICE_PROVIDER_SETTINGS.ASSERTION_CONSUMER_SERVICE_URL"
                           errorPrefix="$I18N_IDENTITY_APP_DETAILS.VALIDATION.ACS_URL"
                           formControlName="acsUrl"
                           required>
            </mc-text-field>

            <mc-text-field class="mc-form-logoutUrl"
                           label="$I18N_IDENTITY_APP_DETAILS.SERVICE_PROVIDER_SETTINGS.SINGLE_LOGOUT_URL"
                           formControlName="logoutUrl">
            </mc-text-field>
        </div>
    </mc-collapsable-panel>
</form>
