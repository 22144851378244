<div class="wrapper">

  <form [formGroup]="formContent" mcErrorPrefix="$I18N_ARCHIVE_VIEWER">

    <div class="row">

      <div class="col-xs-11 col-xs-offset-0">

        <!-- keywords -->
        <div class="col-xs-3 col-xs-offset-0 no-padding-left">
          <mc-text-field label="$I18N_ARCHIVE_VIEWER_SEARCH_TAB_CONTENT_KEYWORDS" formControlName="keywords" data-test="search_by_content_keywords">
          </mc-text-field>
        </div>

        <!-- from -->
        <div class="col-xs-3 col-xs-offset-0">
          <mc-text-field label="$I18N_ARCHIVE_VIEWER_SEARCH_TAB_CONTENT_FROM" formControlName="from"
                         placeholder="$I18N_ARCHIVE_VIEWER_SEARCH_TAB_CONTENT_PLACEHOLDER" data-test="search_by_content_from">
          </mc-text-field>
        </div>

        <!-- to -->
        <div class="col-xs-3 col-xs-offset-0">
          <mc-text-field label="$I18N_ARCHIVE_VIEWER_SEARCH_TAB_CONTENT_TO" formControlName="to"
                         placeholder="$I18N_ARCHIVE_VIEWER_SEARCH_TAB_CONTENT_PLACEHOLDER" data-test="search_by_content_to">
          </mc-text-field>
        </div>

        <!-- date range - calendar -->
        <div class="col-xs-3 col-xs-offset-0" data-test="search_by_content_data_range">
          <mc-date-range-picker
            label="$I18N_ARCHIVE_VIEWER_SEARCH_TAB_CONTENT_RANGE"
            formControlName="dateRange"
            [config]="dateRangePickerConfig">
          </mc-date-range-picker>
        </div>

      </div>

      <div class="col-xs-1 col-xs-offset-0 pull-right panel-double-margin-top">
        <mc-live-button typeClass="btn btn-primary" content="Search"
                        type="submit" (mcClick)="formContentSearch()" data-test="search_by_content_btn"
                        [mcDisabled]="!formContent.valid || !reasonValid"
        >
        </mc-live-button>
      </div>

      <div class="col-xs-11 col-xs-offset-0">

        <!-- files -->
        <!-- input-dropdown -->
        <mc-input-dropdown (valueChanges)="inputDropdownUpdate($event)"
                           class="col-xs-3 col-xs-offset-0 no-padding-left"
                           data-test="search_by_content_input_dropdown"
                           [options]="options"
                           placeholder="{{ '$I18N_ARCHIVE_VIEWER_SEARCH_TAB_CONTENT_FILES_NAME' | translate }}">
        </mc-input-dropdown>

        <!-- route -->
        <mc-field label="$I18N_ARCHIVE_VIEWER_SEARCH_TAB_CONTENT_ROUTE" class="col-xs-3 col-xs-offset-0" data-test="search_by_content_route">
          <select class="form-control" name="route" formControlName="route">
            <option selected value="any">{{ '$I18N_ARCHIVE_VIEWER_SEARCH_TAB_CONTENT_ROUTE_ALL' | translate }}
            </option>
            <option value="inbound">{{ '$I18N_ARCHIVE_VIEWER_SEARCH_TAB_CONTENT_ROUTE_INBOUND' | translate }}
            </option>
            <option value="outbound">{{ '$I18N_ARCHIVE_VIEWER_SEARCH_TAB_CONTENT_ROUTE_OUTBOUND' | translate }}
            </option>
            <option value="internal">{{ '$I18N_ARCHIVE_VIEWER_SEARCH_TAB_CONTENT_ROUTE_INTERNAL' | translate }}
            </option>
          </select>
        </mc-field>

        <!-- include -->
        <div class="col-xs-3 col-xs-offset-0" data-test="search_by_content_include">
          <label>
            <span translate="$I18N_ARCHIVE_VIEWER_SEARCH_TAB_CONTENT_INCLUDE"></span>
            <div class="checkbox">
              <label><input type="checkbox" value="" formControlName="expiredMessages">
                <span translate="$I18N_ARCHIVE_VIEWER_SEARCH_TAB_CONTENT_MESSAGE"></span>
              </label>
            </div>
          </label>
        </div>

      </div>

      <div class="col-xs-1 col-xs-offset-0 pull-right panel-double-margin-top">
      </div>

    </div>

  </form>

</div>
