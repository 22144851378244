"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiIntegrationCortexWizardModule = void 0;
const _01_activation_1 = require("./steps/01.activation");
const _02a_spyware_settings_1 = require("./steps/02a.spyware-settings");
const _02b_wildfire_settings_1 = require("./steps/02b.wildfire-settings");
const _03_notifications_1 = require("./steps/03.notifications");
const _04_authorisation_1 = require("./steps/04.authorisation");
const _05_summary_1 = require("./steps/05.summary");
const cortex_wizard_component_1 = require("./cortex-wizard.component");
const COMPONENTS = [
    _01_activation_1.ApiIntegrationCortexActivationStepComponent,
    _02a_spyware_settings_1.ApiIntegrationCortexSpywareSettingsStepComponent,
    _02b_wildfire_settings_1.ApiIntegrationCortexWildfireSettingsStepComponent,
    _03_notifications_1.ApiIntegrationCortexNotificationsStepComponent,
    _04_authorisation_1.ApiIntegrationCortexAuthorisationStepComponent,
    _05_summary_1.ApiIntegrationCortexSummaryStepComponent,
    cortex_wizard_component_1.ApiIntegrationCortexWizardComponent
];
class ApiIntegrationCortexWizardModule {
}
exports.ApiIntegrationCortexWizardModule = ApiIntegrationCortexWizardModule;
