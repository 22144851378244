"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccordionComponent = void 0;
class AccordionComponent {
    constructor() {
        this.subscriptions = [];
    }
    ngAfterContentInit() {
        this._accordions = this.accordions;
        this.removeSubscriptions();
        this.addSubscriptions();
        this.toogleAccordion(this.accordions.first);
        this.accordions.changes.subscribe(rex => {
            this._accordions = rex;
            this.removeSubscriptions();
            this.addSubscriptions();
        });
    }
    addSubscriptions() {
        this._accordions.forEach((a) => {
            const subscription = a.toggleAccordion.subscribe(() => {
                this.toogleAccordion(a);
            });
            this.subscriptions.push(subscription);
        });
    }
    removeSubscriptions() {
        this.subscriptions.forEach((sub) => {
            sub.unsubscribe();
        });
    }
    toogleAccordion(accordion) {
        if (!accordion.active) {
            this.accordions.forEach(a => {
                a.active = false;
                a.accordionClass = 'accordion-closed';
            });
        }
        // set active accordion
        accordion.active = !accordion.active;
    }
    ngOnDestroy() {
        this.removeSubscriptions();
    }
}
exports.AccordionComponent = AccordionComponent;
