"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiCrowdstrikeIntegrationAsideViewComponent = void 0;
const core_1 = require("@angular/core");
class ApiCrowdstrikeIntegrationAsideViewComponent {
    constructor(overlayRef) {
        this.overlayRef = overlayRef;
        this.onCloseClicked = new core_1.EventEmitter();
        this.onEditClicked = new core_1.EventEmitter();
        this.onEnableClicked = new core_1.EventEmitter();
        this.onDisableClicked = new core_1.EventEmitter();
        this.onViewActivityLogClicked = new core_1.EventEmitter();
    }
    get crowdstrikeGroupsList() {
        if (this.crowdstrikeIntegration) {
            return Object.values(this.crowdstrikeIntegration.notificationGroups);
        }
        return [];
    }
    get crowdstrikeEmailList() {
        if (this.crowdstrikeIntegration) {
            return this.crowdstrikeIntegration.notificationAddresses;
        }
        return [];
    }
    openCrowdstrikeActivityLogPage() {
        if (this.crowdstrikeIntegration) {
            this.overlayRef.close();
            this.onViewActivityLogClicked.emit(this.crowdstrikeIntegration.id);
        }
    }
    displayAction() {
        return (this.crowdstrikeIntegration.submitMaliciousHashes &&
            this.crowdstrikeIntegration.iocOutboundAction &&
            this.crowdstrikeIntegration.iocOutboundAction !== '');
    }
}
exports.ApiCrowdstrikeIntegrationAsideViewComponent = ApiCrowdstrikeIntegrationAsideViewComponent;
