"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FindGroupsEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const findGroupsAction = require("../actions/find-groups.actions");
class FindGroupsEffects {
    constructor(actions$, selectGroupsServices, notificationService, translation) {
        this.actions$ = actions$;
        this.selectGroupsServices = selectGroupsServices;
        this.notificationService = notificationService;
        this.translation = translation;
        this.findGroups$ = effects_1.createEffect(() => this.actions$.pipe(effects_1.ofType(findGroupsAction.FIND_GROUPS), operators_1.switchMap((action) => {
            const successFn = action.payload['source'] === 'cloud'
                ? findGroupsAction.FindGroupsCloudSuccess
                : findGroupsAction.FindGroupsLDAPSuccess;
            return this.findGroups(action.payload, successFn);
        })));
    }
    findGroups(config, successFn) {
        return this.selectGroupsServices.findGroups(config).pipe(operators_1.map((response) => new successFn(response)), operators_1.catchError(() => {
            return rxjs_1.of(new findGroupsAction.FindGroupsFail()).pipe(operators_1.tap(() => {
                this.notificationService.error({
                    duration: 0,
                    msg: this.translation.instant('$I18N_COMMON_TEXT_LINE_GLOBAL_ERROR_MESSAGE')
                });
            }));
        }));
    }
}
exports.FindGroupsEffects = FindGroupsEffects;
