<div class="mc-list-panel sfc-widget-layout">
  <div class="mc-list-panel-heading">
    <span>{{title}}</span>

    <div class="sfc-graph-layout-export" *ngIf="shouldExport">
      <mc-list-dropdown-button
        disableCaret="true"
        inline="true"
        align="right"
        class="sfc-graph-layout-export__button"
      >
        <mc-dropdown-item
          [label]="format.label"
          (click)="onExport(format)"
          *ngFor="let format of exportFormats"
        ></mc-dropdown-item>
      </mc-list-dropdown-button>
    </div>
  </div>
  <div class="sfc-widget-layout-content">
    <ng-content></ng-content>
  </div>
</div>
