"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PoliciesPageComponent = void 0;
const listActions = require("../actions/policies-list.actions");
const editActions = require("../actions/policies-edit.actions");
const createActions = require("../actions/policies-create.actions");
const reducer = require("../reducers/index");
const policy_wizard_models_1 = require("../models/policy-wizard.models");
class PoliciesPageComponent {
    constructor(store, detailsStore, accountPackageService) {
        this.store = store;
        this.detailsStore = detailsStore;
        this.accountPackageService = accountPackageService;
        this.accessedPolicyFromMeatball = false;
        this.hasIEPPackage = this.accountPackageService.hasInternalEmailProtectAccountPackage();
        this.isAAAOrFAAUser = this.accountPackageService.isAAAOrFAAUser();
    }
    ngOnInit() {
        this.startSubscriptions();
    }
    startSubscriptions() {
        this.policyDetailsFullDataSubscription = this.detailsStore
            .select(reducer.getSelectedRowFullData)
            .subscribe((value) => {
            if (!value || !this.accessedPolicyFromMeatball) {
                return;
            }
            this.store.dispatch(new editActions.OpenCreatePolicyPageInEditMode(value));
            this.accessedPolicyFromMeatball = false;
        });
    }
    search(search) {
        this.store.dispatch(new listActions.PoliciesRequestAction({ search }));
    }
    rowClicked() {
        this.detailsStore.dispatch(new listActions.OpenPolicyDetailsSidePanelAction());
    }
    createNewPolicy() {
        this.store.dispatch(new createActions.OpenCreatePolicyPage());
    }
    duplicatePolicy(policy) {
        this.accessedPolicyFromMeatball = true;
        this.store.dispatch(new createActions.SetPolicyWizardFlowTypeAction(policy_wizard_models_1.PolicyWizardFlowType.DUPLICATE));
        this.store.dispatch(new listActions.GetPolicyDetailsAction({ id: policy.id }));
    }
    editPolicy(policy) {
        this.accessedPolicyFromMeatball = true;
        this.store.dispatch(new createActions.SetPolicyWizardFlowTypeAction(policy_wizard_models_1.PolicyWizardFlowType.EDIT));
        this.store.dispatch(new listActions.GetPolicyDetailsAction({ id: policy.id }));
    }
    deletePolicy(policy) {
        this.store.dispatch(new listActions.PolicyDeleteAttemptAction(policy.id));
    }
    ngOnDestroy() {
        if (this.policyDetailsFullDataSubscription) {
            this.policyDetailsFullDataSubscription.unsubscribe();
        }
    }
}
exports.PoliciesPageComponent = PoliciesPageComponent;
