<mc-wizard-step-header [label]="'$I18N_SWG_POLICY_CLP_ACTIONS_TITLE'"></mc-wizard-step-header>
<div class="mc-clp-actions">
  <form class="panel-padding-bottom" [formGroup]="clpActionsForm">
    <div class="form-group">
      <div class="radio panel-half-padding-bottom">
        <label class="text-bold">
          <input type="radio" name="action" class="mc-radio-control" value="{{ClpPolicyActions.DONT_SCAN}}"
                 formControlName="action" data-test="clpActionDontScan"/>
          {{ '$I18N_SWG_POLICY_CLP_ACTIONS_DONT_SCAN' | translate }}
          <div> {{'$I18N_SWG_POLICY_CLP_ACTIONS_DONT_SCAN_DESC' | translate}} </div>
        </label>
      </div>

      <div class="radio panel-half-padding-bottom">
        <label class="text-bold">
          <input type="radio" name="action" class="mc-radio-control" value="{{ClpPolicyActions.LOG_ONLY}}"
                 formControlName="action" data-test="clpActionLogOnly"/>
          {{ '$I18N_SWG_POLICY_CLP_ACTIONS_LOG_ONLY' | translate }}
          <div>  {{'$I18N_SWG_POLICY_CLP_ACTIONS_LOG_ONLY_DESC' | translate}} </div>
        </label>
      </div>

      <div class="radio panel-half-padding-bottom">
        <label class="text-bold">
          <input type="radio" name="action" class="mc-radio-control" value="{{ClpPolicyActions.LOG_AND_REPLY}}"
                 formControlName="action" data-test="clpActionLogAndReply" />
          {{ '$I18N_SWG_POLICY_CLP_ACTIONS_LOG_AND_REPLY' | translate }}
          <div> {{'$I18N_SWG_POLICY_CLP_ACTIONS_LOG_AND_REPLY_DESC' | translate}} </div>
        </label>
      </div>


      <div class="radio panel-half-padding-bottom" *ngIf="hasBlockAndQuarantineAction()">
        <label class="text-bold">
          <input type="radio" name="action" class="mc-radio-control" value="{{ClpPolicyActions.BLOCK_MALICIOUS}}"
                 formControlName="action" data-test="clpActionBlockAndQuarantine"/>
          {{ '$I18N_SWG_POLICY_CLP_ACTIONS_BLOCK_MALICIOUS' | translate }}
          <div> {{'$I18N_SWG_POLICY_CLP_ACTIONS_BLOCK_MALICIOUS_DESC' | translate}} </div>
        </label>
      </div>

    </div>
  </form>
</div>

