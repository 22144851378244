"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DownloadFileCompleteAction = exports.DownloadFileAction = exports.GetTokenForExportFailAction = exports.GetTokenForExportSuccessAction = exports.GetTokenForExportAction = exports.OpenPanelForExportAction = exports.DOWNLOAD_FILE_COMPLETE = exports.DOWNLOAD_FILE = exports.GET_TOKEN_FOR_EXPORT_FAIL = exports.GET_TOKEN_FOR_EXPORT_SUCCESS = exports.GET_TOKEN_FOR_EXPORT = exports.OPEN_PANEL_FOR_EXPORT = exports.PREFIX = void 0;
exports.PREFIX = '[Remediation]';
exports.OPEN_PANEL_FOR_EXPORT = '[Remediation] Open export panel';
exports.GET_TOKEN_FOR_EXPORT = '[Remediation] Retrieve export token';
exports.GET_TOKEN_FOR_EXPORT_SUCCESS = '[Remediation] Retrieve export token success';
exports.GET_TOKEN_FOR_EXPORT_FAIL = '[Remediation] Retrieve export token fail';
exports.DOWNLOAD_FILE = '[Remediation] download file';
exports.DOWNLOAD_FILE_COMPLETE = '[Remediation] download file complete';
class OpenPanelForExportAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.OPEN_PANEL_FOR_EXPORT;
    }
}
exports.OpenPanelForExportAction = OpenPanelForExportAction;
class GetTokenForExportAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_TOKEN_FOR_EXPORT;
    }
}
exports.GetTokenForExportAction = GetTokenForExportAction;
class GetTokenForExportSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_TOKEN_FOR_EXPORT_SUCCESS;
    }
}
exports.GetTokenForExportSuccessAction = GetTokenForExportSuccessAction;
class GetTokenForExportFailAction {
    constructor() {
        this.type = exports.GET_TOKEN_FOR_EXPORT_FAIL;
    }
}
exports.GetTokenForExportFailAction = GetTokenForExportFailAction;
class DownloadFileAction {
    constructor(token) {
        this.token = token;
        this.type = exports.DOWNLOAD_FILE;
    }
}
exports.DownloadFileAction = DownloadFileAction;
class DownloadFileCompleteAction {
    constructor(token) {
        this.token = token;
        this.type = exports.DOWNLOAD_FILE_COMPLETE;
    }
}
exports.DownloadFileCompleteAction = DownloadFileCompleteAction;
