"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const exportActions = require("../../actions/export/export.actions");
const export_detail_component_1 = require("../../components/export-detail/export-detail.component");
const reducers = require("../../reducers");
class ExportEffects {
    constructor(actions$, asideService, remediationApiService, notificationService, translation, exportService, store) {
        this.actions$ = actions$;
        this.asideService = asideService;
        this.remediationApiService = remediationApiService;
        this.notificationService = notificationService;
        this.translation = translation;
        this.exportService = exportService;
        this.store = store;
        this.columnNameMap = {
            fromHdr: 'from_header',
            code: 'incident_id',
            incidentCode: 'incident_id',
            actionTime: 'action_time',
            messageId: 'message_id'
        };
        this.openExportPanel$ = this.actions$.pipe(effects_1.ofType(exportActions.OPEN_PANEL_FOR_EXPORT), operators_1.tap(() => {
            this.asideInstance = this.asideService.open(export_detail_component_1.ExportDetailComponent, {
                position: 'right',
                size: 'md',
                hasBackdrop: true
            });
            this.asideInstance.afterClose().subscribe(() => {
                this.asideInstance = undefined;
            });
        }));
        this.loadExportData$ = this.actions$.pipe(effects_1.ofType(exportActions.GET_TOKEN_FOR_EXPORT), operators_1.withLatestFrom(this.store.select(reducers.getSearchFormPayload)), operators_1.switchMap(([action, searchPayload]) => {
            const request = Object.assign(Object.assign(Object.assign({}, action.payload), searchPayload), { exportColumns: [] });
            // @ts-ignore
            for (const columnName of action.payload.exportColumns) {
                if (this.columnNameMap[columnName]) {
                    request.exportColumns.push(this.columnNameMap[columnName]);
                }
                else {
                    request.exportColumns.push(columnName);
                }
            }
            return this.remediationApiService.getExportData(request).pipe(operators_1.map((response) => {
                return new exportActions.GetTokenForExportSuccessAction(response);
            }), operators_1.catchError(() => {
                this.notificationService.error({
                    msg: this.translation.instant('$I18N_COMMON_TEXT_LINE_GLOBAL_ERROR_MESSAGE')
                });
                return rxjs_1.of(new exportActions.GetTokenForExportFailAction());
            }));
        }));
        this.downloadFile$ = this.actions$.pipe(effects_1.ofType(exportActions.GET_TOKEN_FOR_EXPORT_SUCCESS), operators_1.switchMap((action) => {
            // @ts-ignore
            return this.remediationApiService.getFile(action.payload.id).pipe(operators_1.map((response) => {
                this.exportService.injectIframeToDownloadFile(response);
                this.asideInstance.close();
            }), operators_1.catchError(() => {
                this.notificationService.error({
                    msg: this.translation.instant('$I18N_COMMON_TEXT_LINE_GLOBAL_ERROR_MESSAGE')
                });
                return rxjs_1.of(new exportActions.GetTokenForExportFailAction());
            }));
        }));
    }
}
__decorate([
    effects_1.Effect({ dispatch: false })
], ExportEffects.prototype, "openExportPanel$", void 0);
__decorate([
    effects_1.Effect()
], ExportEffects.prototype, "loadExportData$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], ExportEffects.prototype, "downloadFile$", void 0);
exports.ExportEffects = ExportEffects;
