"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LegacyMessagesEffects = void 0;
const effects_1 = require("@ngrx/effects");
const operators_1 = require("rxjs/operators");
const actions = require("../actions");
const legacy_adcon_1 = require("../services/legacy-adcon");
const messages = require("./legacy.messages");
const commonActions = require("../../message-center/common/actions");
const dirSyncActions = require("../../directory-sync/actions");
/**
 * The adcon3.5 messages effects
 */
class LegacyMessagesEffects {
    constructor(legacyService, windowService, stateService) {
        this.legacyService = legacyService;
        this.windowService = windowService;
        this.stateService = stateService;
        this.disableAdcon3 = this.windowService.disableAdcon3;
        /**
         * this is necessary to make the session time out timer restart when clicks inside the adcon3.5 iframes
         *
         * @type {Observable<any>}
         */
        this.adconIframeFixClick$ = this.legacyService.messages$.pipe(legacy_adcon_1.ofName(messages.IFRAME_BODY_CLICK), operators_1.tap(() => this.windowService.document.body.click()));
        this.adconIframeSwicthAccount$ = this.legacyService.messages$.pipe(legacy_adcon_1.ofName(messages.SWITCH_ACCOUNT), operators_1.map((message) => new actions.legacy.SwitchAccountAction(message.data)));
        /**
         * logout in cases adcon3.5 sends messages with errors
         *
         * @type {Observable<LogoutAction>}
         */
        this.adconIframeLogoutOnError$ = this.legacyService.messages$.pipe(legacy_adcon_1.ofName(messages.ADCON_ACTION), operators_1.filter(message => message.data && message.data.data !== 'logout'), operators_1.filter(() => !this.disableAdcon3), operators_1.map(() => {
            // NOTE in case someone stumble with this you might wonder why the weird line above call logout
            // if adcon-action is diferent from logout this is because adcon-action represents and action
            // parameter in the url of adcon3.5, it represents many different things, but as far as I know
            // is mainly use for different errors that adcon 3.5 produce, generally logout should never be called
            // because this is handled by our app so is ignored, also avoids calling logout again becasue if
            // an error message in adcon3.5 the server side session gets corrupted so we have to logout
            // TODO should we add some paraemeter to show a error message in the login or something
            // right now it will simply take you to login page without explanation same as before
            return new actions.session.LogoutAction();
        }));
        this.adconIframeOpenState$ = this.legacyService.messages$.pipe(legacy_adcon_1.ofName(messages.OPEN_TAB), operators_1.tap((message) => {
            const state = message.data;
            if (!state || !state.name || !this.stateService.$state.get(state.name)) {
                this.stateService.$state.go('administration-dashboard');
            }
            else {
                this.stateService.$state.go(state.name, state.params);
            }
        }));
        this.openAsidePanel$ = this.legacyService.messages$.pipe(legacy_adcon_1.ofName(messages.OPEN_PANEL), operators_1.map((message) => {
            if (message.data.type && message.data.type === 'directory-sync') {
                return new dirSyncActions.DirectoryConnectionAction(message.data.payload);
            }
            else {
                return new commonActions.ShowMessageInfoAction({ id: message.data.payload }, [], false);
            }
        }));
    }
}
__decorate([
    effects_1.Effect({ dispatch: false })
], LegacyMessagesEffects.prototype, "adconIframeFixClick$", void 0);
__decorate([
    effects_1.Effect()
], LegacyMessagesEffects.prototype, "adconIframeSwicthAccount$", void 0);
__decorate([
    effects_1.Effect()
], LegacyMessagesEffects.prototype, "adconIframeLogoutOnError$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], LegacyMessagesEffects.prototype, "adconIframeOpenState$", void 0);
__decorate([
    effects_1.Effect()
], LegacyMessagesEffects.prototype, "openAsidePanel$", void 0);
exports.LegacyMessagesEffects = LegacyMessagesEffects;
