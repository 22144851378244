"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgNavigateToDeviceGroupListAction = exports.SwgLoadDeviceGroupToEditSuccessAction = exports.SwgLoadDeviceGroupToEditAction = exports.SwgCreateDeviceGroupFailureAction = exports.SwgCreateDeviceGroupSuccessAction = exports.SwgCreateDeviceGroupAction = exports.SwgFilterDevicesInGroupCompletedAction = exports.SwgFilterDevicesInGroupAction = exports.SwgPageDevicesInGroupCompletedAction = exports.SwgPageDevicesInGroupAction = exports.SwgRemoveDevicesFromGroupAction = exports.SwgAddDevicesInGroupAction = exports.SwgUpdateDeviceGroupDetailsAction = exports.SwgNavigateToDeviceGroupWizardAction = exports.SwgClearDeviceGroupAction = exports.SwgClearGroupIdAction = exports.SwgSetGroupIdAction = exports.SwgGetGroupDevicesListFailureAction = exports.SwgGetGroupDevicesListSuccessAction = exports.SwgGetGroupDevicesListAction = exports.SwgDeviceGroupsListActions = void 0;
var SwgDeviceGroupsListActions;
(function (SwgDeviceGroupsListActions) {
    SwgDeviceGroupsListActions["GET_GROUP_DEVICES_LIST"] = "[swgDeviceGroups] Get Group Devices List";
    SwgDeviceGroupsListActions["GET_GROUP_DEVICES_LIST_SUCCESS"] = "[swgDeviceGroups] Get Group Devices List Success";
    SwgDeviceGroupsListActions["GET_GROUP_DEVICES_LIST_FAILURE"] = "[swgDeviceGroups] Get Group Devices List Failure";
    SwgDeviceGroupsListActions["SET_GROUP_ID"] = "[swgDeviceGroups] Set Group Id";
    SwgDeviceGroupsListActions["CLEAR_GROUP_ID"] = "[swgDeviceGroups] Clear Group Id";
    SwgDeviceGroupsListActions["CLEAR_DEVICE_GROUP"] = "[swgDeviceGroups] Clear Device Group";
    SwgDeviceGroupsListActions["NAVIGATE_TO_DEVICE_GROUP_WIZARD"] = "[swgDeviceGroups] Navigate to Device Group Wizard";
    SwgDeviceGroupsListActions["UPDATE_DEVICE_GROUP_DETAILS"] = "[swgDeviceGroups] Update Device Group Descr";
    SwgDeviceGroupsListActions["ADD_DEVICES_IN_GROUP"] = "[swgDeviceGroups] Add Devices in Group";
    SwgDeviceGroupsListActions["REMOVE_DEVICES_FROM_GROUP"] = "[swgDeviceGroups] Remove Devices from Group";
    SwgDeviceGroupsListActions["PAGE_DEVICES_IN_GROUP"] = "[swgDeviceGroups] Page Devices In Group";
    SwgDeviceGroupsListActions["PAGE_DEVICES_IN_GROUP_COMPLETED"] = "[swgDeviceGroups] Page Devices In Group Completed";
    SwgDeviceGroupsListActions["FILTER_DEVICES_IN_GROUP"] = "[swgDeviceGroups] Filter Devices In Group";
    SwgDeviceGroupsListActions["FILTER_DEVICES_IN_GROUP_COMPLETED"] = "[swgDeviceGroups] Filter Devices In Group Completed";
    SwgDeviceGroupsListActions["CREATE_DEVICE_GROUP"] = "[swgDeviceGroups] Create Device Group";
    SwgDeviceGroupsListActions["CREATE_DEVICE_GROUP_SUCCESS"] = "[swgDeviceGroups] Create Device Group Success";
    SwgDeviceGroupsListActions["CREATE_DEVICE_GROUP_FAILURE"] = "[swgDeviceGroups] Create Device Group Failure";
    SwgDeviceGroupsListActions["LOAD_DEVICE_GROUP_TO_EDIT"] = "[swgDeviceGroups] Load Device Group To Edit";
    SwgDeviceGroupsListActions["LOAD_DEVICE_GROUP_TO_EDIT_SUCCESS"] = "[swgDeviceGroups] Load Device Group To Edit Success";
    SwgDeviceGroupsListActions["NAVIGATE_TO_DEVICE_GROUP_LIST"] = "[swgDeviceGroups] Navigate to Device Group List";
})(SwgDeviceGroupsListActions = exports.SwgDeviceGroupsListActions || (exports.SwgDeviceGroupsListActions = {}));
class SwgGetGroupDevicesListAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgDeviceGroupsListActions.GET_GROUP_DEVICES_LIST;
    }
}
exports.SwgGetGroupDevicesListAction = SwgGetGroupDevicesListAction;
class SwgGetGroupDevicesListSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgDeviceGroupsListActions.GET_GROUP_DEVICES_LIST_SUCCESS;
    }
}
exports.SwgGetGroupDevicesListSuccessAction = SwgGetGroupDevicesListSuccessAction;
class SwgGetGroupDevicesListFailureAction {
    constructor() {
        this.type = SwgDeviceGroupsListActions.GET_GROUP_DEVICES_LIST_FAILURE;
    }
}
exports.SwgGetGroupDevicesListFailureAction = SwgGetGroupDevicesListFailureAction;
class SwgSetGroupIdAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgDeviceGroupsListActions.SET_GROUP_ID;
    }
}
exports.SwgSetGroupIdAction = SwgSetGroupIdAction;
class SwgClearGroupIdAction {
    constructor() {
        this.type = SwgDeviceGroupsListActions.CLEAR_GROUP_ID;
    }
}
exports.SwgClearGroupIdAction = SwgClearGroupIdAction;
class SwgClearDeviceGroupAction {
    constructor() {
        this.type = SwgDeviceGroupsListActions.CLEAR_DEVICE_GROUP;
    }
}
exports.SwgClearDeviceGroupAction = SwgClearDeviceGroupAction;
class SwgNavigateToDeviceGroupWizardAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgDeviceGroupsListActions.NAVIGATE_TO_DEVICE_GROUP_WIZARD;
    }
}
exports.SwgNavigateToDeviceGroupWizardAction = SwgNavigateToDeviceGroupWizardAction;
class SwgUpdateDeviceGroupDetailsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgDeviceGroupsListActions.UPDATE_DEVICE_GROUP_DETAILS;
    }
}
exports.SwgUpdateDeviceGroupDetailsAction = SwgUpdateDeviceGroupDetailsAction;
class SwgAddDevicesInGroupAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgDeviceGroupsListActions.ADD_DEVICES_IN_GROUP;
    }
}
exports.SwgAddDevicesInGroupAction = SwgAddDevicesInGroupAction;
class SwgRemoveDevicesFromGroupAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgDeviceGroupsListActions.REMOVE_DEVICES_FROM_GROUP;
    }
}
exports.SwgRemoveDevicesFromGroupAction = SwgRemoveDevicesFromGroupAction;
class SwgPageDevicesInGroupAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgDeviceGroupsListActions.PAGE_DEVICES_IN_GROUP;
    }
}
exports.SwgPageDevicesInGroupAction = SwgPageDevicesInGroupAction;
class SwgPageDevicesInGroupCompletedAction {
    constructor() {
        this.type = SwgDeviceGroupsListActions.PAGE_DEVICES_IN_GROUP_COMPLETED;
    }
}
exports.SwgPageDevicesInGroupCompletedAction = SwgPageDevicesInGroupCompletedAction;
class SwgFilterDevicesInGroupAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgDeviceGroupsListActions.FILTER_DEVICES_IN_GROUP;
    }
}
exports.SwgFilterDevicesInGroupAction = SwgFilterDevicesInGroupAction;
class SwgFilterDevicesInGroupCompletedAction {
    constructor() {
        this.type = SwgDeviceGroupsListActions.FILTER_DEVICES_IN_GROUP_COMPLETED;
    }
}
exports.SwgFilterDevicesInGroupCompletedAction = SwgFilterDevicesInGroupCompletedAction;
class SwgCreateDeviceGroupAction {
    constructor() {
        this.type = SwgDeviceGroupsListActions.CREATE_DEVICE_GROUP;
    }
}
exports.SwgCreateDeviceGroupAction = SwgCreateDeviceGroupAction;
class SwgCreateDeviceGroupSuccessAction {
    constructor() {
        this.type = SwgDeviceGroupsListActions.CREATE_DEVICE_GROUP_SUCCESS;
    }
}
exports.SwgCreateDeviceGroupSuccessAction = SwgCreateDeviceGroupSuccessAction;
class SwgCreateDeviceGroupFailureAction {
    constructor() {
        this.type = SwgDeviceGroupsListActions.CREATE_DEVICE_GROUP_FAILURE;
    }
}
exports.SwgCreateDeviceGroupFailureAction = SwgCreateDeviceGroupFailureAction;
class SwgLoadDeviceGroupToEditAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgDeviceGroupsListActions.LOAD_DEVICE_GROUP_TO_EDIT;
    }
}
exports.SwgLoadDeviceGroupToEditAction = SwgLoadDeviceGroupToEditAction;
class SwgLoadDeviceGroupToEditSuccessAction {
    constructor() {
        this.type = SwgDeviceGroupsListActions.LOAD_DEVICE_GROUP_TO_EDIT_SUCCESS;
    }
}
exports.SwgLoadDeviceGroupToEditSuccessAction = SwgLoadDeviceGroupToEditSuccessAction;
class SwgNavigateToDeviceGroupListAction {
    constructor() {
        this.type = SwgDeviceGroupsListActions.NAVIGATE_TO_DEVICE_GROUP_LIST;
    }
}
exports.SwgNavigateToDeviceGroupListAction = SwgNavigateToDeviceGroupListAction;
