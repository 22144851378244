<tree-root class="mc-tree-root" [focused]="true" [nodes]="nodes" [options]="options" (activate)="onActivate($event)"
           (toggleExpanded)="onItemExpanded($event)" (select)="onSelect($event)" (deselect)="onDeselect($event)" (initialized)="onTreeLoad()">

  <ng-container *ngIf="!customNodeTemplate">
    <ng-template #treeNodeWrapperTemplate let-node let-index="index">
      <div class="node-wrapper" [style.padding-left]="node.getNodePadding()">
        <tree-node-expander [node]="node"></tree-node-expander>
        <tree-node-checkbox *ngIf="node.options.useCheckbox" [node]="node" (click)="onCheckboxClick(node)">
        </tree-node-checkbox>
        <div class="node-content-wrapper col-sm-12" [class.node-content-wrapper-active]="node.isActive"
          [class.node-content-wrapper-focused]="node.isFocused"
          [class.node-content-wrapper-highlighted]="node.data.isHighlighted"
          (dblclick)="node.mouseAction('dblClick', $event)" (contextmenu)="node.mouseAction('contextMenu', $event)"
          (treeDrop)="node.onDrop($event)" [treeAllowDrop]="node.allowDrop" [treeDrag]="node"
          [treeDragEnabled]="node.allowDrag()">

          <div (mouseenter)="onHover(node)" *ngIf="!node.data.isNewFolderEntry" (mouseleave)="onUnHover()">
            <tree-node-content [node]="node" [index]="index" (click)="node.mouseAction('click', $event)">
            </tree-node-content>
            <span *ngIf="node.data.id === hoveredFolderId && isRootDirectory(node.data.id)"> - <a
                (click)="createPlaceholderOktaFolder(node)" data-test="create-new-folder-link">
              {{ '$I18N_API_INTEGRATION_OKTA_GROUP_SELECTOR.CREATE_NEW_FOLDER' | translate }}
            </a></span>
          </div>

          <div *ngIf="node.data.isNewFolderEntry">
            <form class="form-horizontal new-okta-folder" [formGroup]="form" (ngSubmit)="addNewFolder(node)">
                <mc-text-field
                  formControlName="folderName"
                  errorPrefix="$I18N_API_INTEGRATION_OKTA_GROUP_SELECTOR.VALIDATION_NAME"
                  placeholder="$I18N_API_INTEGRATION_OKTA_GROUP_SELECTOR.NEW_FOLDER_NAME_PLACEHOLDER"
                  data-test="new-folder-input"
                  required
                >
                </mc-text-field>

                <button type="button" (click)="addNewFolder(node)" class="btn btn-primary btn-md"
                        data-test="create-button" [disabled]="!form.valid">
                  {{ addButtonLabel }}
                </button>
                <button type="button"  (click)="cancelInput()" class="btn btn-default btn-md" data-test="cancel-button">
                  {{ cancelButtonLabel }}
                </button>
            </form>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>

  <ng-container *ngIf="customNodeTemplate">
    <ng-template #treeNodeTemplate let-node let-index="index">
      <i class="mc-tree-node-icon fas fa-tag" aria-hidden="true"></i>
      <span class="mc-tree-node-title">{{ node.data.name }}</span>
      <span class="mc-tree-node-label" [ngClass]="{
              'mc-risk-high': node.data.risk === 'High Risk',
              'mc-risk-med': node.data.risk === 'Medium Risk',
              'mc-risk-low': node.data.risk === 'Low Risk',
              'mc-risk-negligible': node.data.risk === 'Very Low Risk'
            }">
        {{ node.data.risk }}</span>
    </ng-template>
  </ng-container>


  <ng-template #loadingTemplate>
    <span *ngIf="showSpinner">
      <mc-spinner></mc-spinner>
      {{ loadingMessage }}
    </span>
  </ng-template>
</tree-root>
