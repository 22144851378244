"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTemplateSafetyTipsOrPreview = exports.getMimecastDefaultSafetyTipsOrPreview = exports.getMimecastDefaultSafetyTips = exports.isTemplateSaved = exports.isTemplateLoading = exports.getTemplatePreviewTip = exports.getTemplateSelectedTip = exports.getTemplateForForm = exports.getTemplateSafetyTips = exports.getTemplate = exports.safetyTipReducer = exports.reducer = exports.initialState = exports.TipStatus = exports.Status = void 0;
const actions = require("../actions");
// TODO add LOAD_FAIL and SAVE_FAIL and handle in effects
var Status;
(function (Status) {
    Status[Status["LOADING"] = 10] = "LOADING";
    Status[Status["LOAD_SUCCESS"] = 11] = "LOAD_SUCCESS";
    Status[Status["SAVING"] = 20] = "SAVING";
    Status[Status["SAVE_SUCCESS"] = 21] = "SAVE_SUCCESS";
    Status[Status["STORE_TEMPLATE_UPDATE"] = 30] = "STORE_TEMPLATE_UPDATE";
})(Status = exports.Status || (exports.Status = {}));
var TipStatus;
(function (TipStatus) {
    TipStatus[TipStatus["CREATING"] = 1] = "CREATING";
    TipStatus[TipStatus["EDITING"] = 2] = "EDITING";
})(TipStatus = exports.TipStatus || (exports.TipStatus = {}));
exports.initialState = {
    mimecastSafetyTips: []
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.template.STORE_UPDATE: {
            const id = action.payload.template.id;
            return Object.assign(Object.assign({}, state), { [id]: Object.assign(Object.assign({}, state[id]), { status: Status.STORE_TEMPLATE_UPDATE, value: action.payload.template }) });
        }
        case actions.template.LOAD: {
            return Object.assign(Object.assign({}, state), { [action.payload.id]: Object.assign(Object.assign({}, state[action.payload.id]), { status: Status.LOADING }) });
        }
        case actions.template.LOAD_SUCCESS: {
            return Object.assign(Object.assign({}, state), { [action.payload.id]: Object.assign(Object.assign({}, state[action.payload.id]), { status: Status.LOAD_SUCCESS, value: action.payload }) });
        }
        case actions.template.SAVE: {
            return Object.assign(Object.assign({}, state), { [action.payload.id]: Object.assign(Object.assign({}, state[action.payload.id]), { status: Status.SAVING }) });
        }
        case actions.template.SAVE_SUCCESS: {
            return Object.assign(Object.assign({}, state), { [action.payload.id]: Object.assign(Object.assign({}, state[action.payload.id]), { status: Status.SAVE_SUCCESS }) });
        }
        case actions.template.CLEAN_STORE: {
            return cleanStore(state, action);
        }
        case actions.safetyTips.CREATE:
        case actions.safetyTips.EDIT:
        case actions.safetyTips.SAVE:
        case actions.safetyTips.PREVIEW:
        case actions.safetyTips.DELETE: {
            return Object.assign(Object.assign({}, state), { [action.payload.templateId]: safetyTipReducer(state[action.payload.templateId], action) });
        }
        case actions.safetyTips.MIMECAST_SAFETY_TIPS_LOAD_SUCCESS: {
            return Object.assign(Object.assign({}, state), { mimecastSafetyTips: action.payload });
        }
        default:
            return state;
    }
}
exports.reducer = reducer;
function safetyTipReducer(state, action) {
    switch (action.type) {
        case actions.safetyTips.CREATE: {
            return Object.assign(Object.assign({}, state), { previewTip: {
                    tip: undefined,
                    index: undefined,
                    isDefaultTip: false,
                    status: TipStatus.CREATING
                } });
        }
        case actions.safetyTips.EDIT: {
            return Object.assign(Object.assign({}, state), { previewTip: {
                    tip: state.value.safetyTips[action.payload.index],
                    index: action.payload.index,
                    isDefaultTip: false,
                    status: TipStatus.EDITING
                } });
        }
        case actions.safetyTips.SAVE: {
            const oldTemplate = state.value;
            const newTemplate = Object.assign(Object.assign({}, oldTemplate), { safetyTips: [...(oldTemplate.safetyTips || [])] });
            let index = action.payload.index;
            if (action.payload.index != null) {
                newTemplate.safetyTips[action.payload.index] = action.payload.tip;
            }
            else {
                newTemplate.safetyTips.push(action.payload.tip);
                index = newTemplate.safetyTips.length - 1;
            }
            return Object.assign(Object.assign({}, state), { value: newTemplate, status: Status.LOAD_SUCCESS, previewTip: {
                    tip: action.payload.tip,
                    index,
                    isDefaultTip: false,
                    status: undefined
                } });
        }
        case actions.safetyTips.DELETE: {
            const oldTemplate = state.value;
            const newTemplate = Object.assign(Object.assign({}, oldTemplate), { safetyTips: [...(oldTemplate.safetyTips || [])] });
            newTemplate.safetyTips.splice(action.payload.index, 1);
            return Object.assign(Object.assign({}, state), { value: newTemplate, status: Status.LOAD_SUCCESS, previewTip: undefined });
        }
        case actions.safetyTips.PREVIEW: {
            const previewTip = Object.assign(Object.assign({}, ((state && state.previewTip) || {})), action.payload);
            delete previewTip.templateId;
            return Object.assign(Object.assign({}, state), { previewTip: previewTip.tip != null ? previewTip : undefined });
        }
        default:
            return state;
    }
}
exports.safetyTipReducer = safetyTipReducer;
function cleanStore(state, action) {
    const newState = Object.assign({}, state);
    delete newState[action.payload.id];
    return newState;
}
function getTemplate(id) {
    return (state) => {
        const entity = state[id];
        return entity && entity.value;
    };
}
exports.getTemplate = getTemplate;
function getTemplateSafetyTips(id) {
    return (state) => {
        const entity = state[id];
        return (entity && entity.value && entity.value.safetyTips) || undefined;
    };
}
exports.getTemplateSafetyTips = getTemplateSafetyTips;
function getTemplateForForm(id) {
    return (state) => {
        const entity = state[id];
        return ((entity && entity.status && entity.status !== Status.STORE_TEMPLATE_UPDATE && entity.value) ||
            undefined);
    };
}
exports.getTemplateForForm = getTemplateForForm;
function getTemplateSelectedTip(id) {
    return (state) => {
        const entity = state[id];
        return ((entity &&
            entity.value &&
            entity.previewTip &&
            entity.value.safetyTips[entity.previewTip.index]) ||
            undefined);
    };
}
exports.getTemplateSelectedTip = getTemplateSelectedTip;
/**
 * returns tip been previewed, similar to getTemplateSelectedTip, but this one has any client edition
 *
 * @param id
 * @returns
 */
function getTemplatePreviewTip(id) {
    return (state) => {
        const entity = state[id];
        return entity && entity.previewTip;
    };
}
exports.getTemplatePreviewTip = getTemplatePreviewTip;
function isTemplateLoading(id) {
    return (state) => {
        const entity = state[id];
        return entity && entity.status === Status.LOADING;
    };
}
exports.isTemplateLoading = isTemplateLoading;
function isTemplateSaved(id) {
    return (state) => {
        const entity = state[id];
        return entity && entity.status === Status.SAVING;
    };
}
exports.isTemplateSaved = isTemplateSaved;
function getMimecastDefaultSafetyTips(state) {
    return state.mimecastSafetyTips;
}
exports.getMimecastDefaultSafetyTips = getMimecastDefaultSafetyTips;
function getMimecastDefaultSafetyTipsOrPreview(id) {
    return (state) => {
        const preview = getTemplatePreviewTip(id)(state);
        const tips = getMimecastDefaultSafetyTips(state);
        return preview && preview.tip && preview.isDefaultTip ? [preview.tip] : !preview && tips;
    };
}
exports.getMimecastDefaultSafetyTipsOrPreview = getMimecastDefaultSafetyTipsOrPreview;
function getTemplateSafetyTipsOrPreview(id) {
    return (state) => {
        const preview = getTemplatePreviewTip(id)(state);
        const template = getTemplate(id)(state);
        return preview && preview.tip && !preview.isDefaultTip
            ? [preview.tip]
            : !preview && template && template.safetyTips;
    };
}
exports.getTemplateSafetyTipsOrPreview = getTemplateSafetyTipsOrPreview;
