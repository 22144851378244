'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./url-protection.controller"); //services.services.url-protection.controller
require("app/highcharts/highcharts"); //mc-highcharts
require("mimecast-web-components/src/app/services/ttp/url/url"); //api.ttp.url
require("./charts/url-protection-charts-service"); //url-protection-charts.service
require("./charts/url-protection-chart-data-formatter-service"); //url-protection-chart-data-formatter.service
require("app/dashboards/utils/dashboard-commons-service"); //dashboard-commons.service
require("app/components/chart-data-switcher/chart-data-switcher"); //chart-data-switcher
require("mimecast-web-components/src/app/components/nice-scroll/nice-scroll"); //scroll
// html and css
require("./url-protection-dashboard.tpl.html");
require("./less/url-protection-variables.less");
require("./less/url-protection.less");
angular.module('services.services.url-protection', [
    'services.services.url-protection.controller',
    'mc-highcharts',
    'api.ttp.url',
    'url-protection-charts.service',
    'url-protection-chart-data-formatter.service',
    'dashboard-commons.service',
    'chart-data-switcher',
    'scroll'
])
    .config(['$stateProvider', function ($stateProvider) {
        $stateProvider
            .state('url-protection', {
            url: '/services/services/url-protection',
            data: {
                tabId: 'url-protection-tabid',
                tabTitle: '$I18N_ADMINISTRATION_MENU_URL_PROTECTION_BREADCRUMBS_LABEL_03',
                tabHide: false,
                tabReload: 'true'
            },
            views: {
                'main': {
                    templateUrl: 'services/services/url-protection/url-protection-dashboard.tpl.html'
                }
            }
        });
    }]);
