<div class="mc-clp-app-select-step-container">
  <mc-wizard-step-header [label]="getLabel()">
    <ng-container *ngIf="!isDisabled()">
      {{ '$I18N_SWG_CREATE_POLICY_CLP_CLOUD_APP_SELECT_DESC' | translate }}
      <a (click)="createConnector()" class="btn-link cursor-pointer">
        {{ '$I18N_SWG_CREATE_POLICY_CLP_CREATE_A_CONNECTOR' | translate }}</a>
      {{ '$I18N_SWG_CREATE_POLICY_CLP_CLOUD_APP_SELECT_DESC_REMAINING' | translate }}
    </ng-container>
    <ng-container *ngIf="isDisabled()">
      {{'$I18N_SWG_EDIT_POLICY_CLP_CLOUD_APP_BODY' | translate}}
    </ng-container>
  </mc-wizard-step-header>

  <form novalidate
        [formGroup]="form"
        class="form-horizontal"
        mcDefaultLabelClass="col-sm-3"
        mcDefaultControlContainerClass="col-sm-9"
  >
    <div class="row">
      <div class="col-xs-1"><label>{{'$I18N_SWG_CREATE_POLICY_CLP_APP' | translate}}</label></div>

      <div class="col-xs-11 no-padding-left">
        <mc-provider-select
          name="provider"
          formControlName="provider"
          [label]="''"
          [configuredProviders]="providers"></mc-provider-select>
      </div>

    </div>

  </form>
</div>
