<mc-collapsable-panel header="$I18N_MESSAGE_CENTER_MESSAGE_INFO_SPAM_DETAILS_INFO.TITLE" [isExpanded]="true">
  <div class="mc-detailed-list">
    <div class="row mc-detailed-list-row">
      <mc-tooltip class="col-xs-4 mc-detailed-list-label"
        labelText="{{'$I18N_MESSAGE_CENTER_MESSAGE_INFO_SPAM_DETAILS_INFO.LABEL_SPAM_SCORE' | translate}}"
        placement="top"
        popoverContent="{{ '$I18N_MESSAGE_CENTER_MESSAGE_INFO_SPAM_DETAILS_INFO.POPOVER_SPAM_SCORE_INFO' | translate }}">
      </mc-tooltip>
      <span *ngIf="!!spamInfo; else emptyValue">
        <div class="mc-detailed-list-column">{{ spamInfo.spamScore >= 0 ?  spamInfo.spamScore : '-' }}</div>
      </span>
    </div>
    <div class="row mc-detailed-list-row">
      <mc-tooltip class="col-xs-4 mc-detailed-list-label"
        labelText="{{'$I18N_MESSAGE_CENTER_MESSAGE_INFO_SPAM_DETAILS_INFO.LABEL_THREAT_CATEGORY' | translate}}"
        placement="top"
        popoverContent="{{ '$I18N_MESSAGE_CENTER_MESSAGE_INFO_SPAM_DETAILS_INFO.POPOVER_THREAT_CATEGORY_INFO' | translate }}"
        linkText="{{ '$I18N_MESSAGE_CENTER_MESSAGE_INFO_SPAM_DETAILS_INFO.THREAT_CATEGORY_LINK_TEXT' | translate }}"
        linkUrl="https://community.mimecast.com/s/article/Message-Insight-Email-Categorisation">
      </mc-tooltip>
      <span *ngIf="!!spamInfo && !!spamInfo.spamProcessingDetail; else emptyValue">
        <div *ngIf="!!spamInfo.spamProcessingDetail.verdict; else noneValue" class="mc-detailed-list-column">
          {{ (spamInfo.spamProcessingDetail.verdict.decision | titlecase) || '-' }}</div>
      </span>
    </div>
    <div class="row mc-detailed-list-row">
      <mc-tooltip class="col-xs-4 mc-detailed-list-label"
        labelText="{{'$I18N_MESSAGE_CENTER_MESSAGE_INFO_SPAM_DETAILS_INFO.LABEL_DETECTION_LEVEL' | translate}}"
        placement="top"
        popoverContent="{{ '$I18N_MESSAGE_CENTER_MESSAGE_INFO_SPAM_DETAILS_INFO.POPOVER_DETECTION_LEVEL_INFO' | translate }}"
        linkText="{{ '$I18N_MESSAGE_CENTER_MESSAGE_INFO_SPAM_DETAILS_INFO.DETECTION_LEVEL_LINK_TEXT' | translate }}"
        linkUrl="https://community.mimecast.com/docs/DOC-1435"></mc-tooltip>
      <span *ngIf="!!spamInfo && !!spamInfo.detectionLevel; else emptyValue">
        <div *mcCapabilities="'!Temporary.Message.Centre.Enable.Auto.Allow'" class="mc-detailed-list-column">{{ (spamInfo.detectionLevel | mcSpamDetectionDisplay) || '-' }}</div>
        <div *mcCapabilities="'Temporary.Message.Centre.Enable.Auto.Allow'"  class="mc-detailed-list-column">{{spamScanPolicyStatus()?
          ('$I18N_MESSAGE_CENTER_MESSAGE_INFO_SPAM_DETAILS_INFO.LABEL_AUTO_ALLOW'| translate) :
          ((spamInfo.detectionLevel | mcSpamDetectionDisplay) || '-') }}</div>
      </span>
    </div>
  </div>
</mc-collapsable-panel>

<ng-template #emptyValue>
  <span>
    <div class="mc-detailed-list-column">-</div>
  </span>
</ng-template>

<ng-template #noneValue>
  <span>
    <div class="mc-detailed-list-column">None</div>
  </span>
</ng-template>
