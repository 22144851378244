<mc-layout-aside-extra-pagination-container
  keyTitle="$I18N_LOG_SIDEBAR.TITLE"
  showClose="true"
  [hasNextInView]="hasNextInView$ | async"
  [hasPreviousInView]="hasPreviousInView$ | async"
  [indexInView]="indexInView$ | async"
  [currenPagination]="currentPagination$ | async"
  [filtersMetaData]="filtersMetaData$ | async"
  [isLoading]="false"
  (goToNextPage)="getNext()"
  (goToPrevPage)="getPrev()"
  (closeAside)="close.emit()"
>
  <header row-actions>
    <mc-mep-log-sidebar-header [item]="item"></mc-mep-log-sidebar-header>
  </header>

  <mc-body-container>
    <section
      class="mc-detailed-list mc-mep-log-summary-block"
      data-test="mepLogSidebarDetails"
    >
      <h2 class="mc-aside-header mc-mep-sidebar-section-title">
        {{ '$I18N_LOG_SIDEBAR.MESSAGE_OVERVIEW.TITLE' | translate }}
      </h2>
      <mc-mep-log-sidebar-details [item]="item"></mc-mep-log-sidebar-details>
    </section>
    <hr>
    <section>
      <h2 class="mc-aside-header mc-mep-sidebar-section-title">
        {{ '$I18N_LOG_SIDEBAR.EXTERNAL_RECIPIENTS.TITLE' | translate }}
      </h2>
      <mc-mep-log-sidebar-external-recipients-list [item]="item"></mc-mep-log-sidebar-external-recipients-list>
    </section>
    <hr>
    <section>
      <h2 class="mc-aside-header mc-mep-sidebar-section-title">
        {{ '$I18N_LOG_SIDEBAR.INTERNAL_RECIPIENTS.TITLE' | translate }}
      </h2>
      <mc-mep-log-sidebar-internal-recipients-list [internalAddresses]="item.internalAddresses"></mc-mep-log-sidebar-internal-recipients-list>
    </section>
    <hr>
    <section class="mc-detailed-list">
      <h2 class="mc-aside-header mc-mep-sidebar-section-title">
        {{ '$I18N_LOG_SIDEBAR.ASSOCIATED_POLICY.TITLE' | translate }}
      </h2>
      <mc-mep-log-sidebar-associated-policy [name]="item.policyName"></mc-mep-log-sidebar-associated-policy>
    </section>
  </mc-body-container>
</mc-layout-aside-extra-pagination-container>
