"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getActivityChartBlockedRequests = exports.getActivityChartAllowedRequests = exports.getTopBlockedCategoriesChartTotalRequests = exports.isTopBlockedCategoriesChartLoading = exports.getTopBlockedCategoriesChartData = exports.getTopBlockedDomainsChartTotalRequests = exports.isTopBlockedDomainsChartLoading = exports.getTopBlockedDomainsChartData = exports.getTopCategoriesChartTotalRequests = exports.isTopCategoriesChartLoading = exports.getTopCategoriesChartData = exports.getTopDomainsChartTotalRequests = exports.isTopDomainsChartLoading = exports.getTopDomainsChartData = exports.getActivityChartTotalRequests = exports.isActivityChartLoading = exports.getActivityChartData = exports.reducer = exports.initialState = void 0;
const swg_dashboard_actions_1 = require("../actions/swg-dashboard.actions");
exports.initialState = {
    activityChart: [],
    isActivityChartLoading: false,
    activityChartTotalRequests: 0,
    topDomainsChart: [],
    isTopDomainsChartLoading: false,
    topDomainsChartTotalRequests: 0,
    topCategoriesChart: [],
    isTopCategoriesChartLoading: false,
    topCategoriesChartTotalRequests: 0,
    topBlockedDomainsChart: [],
    isTopBlockedDomainsChartLoading: false,
    topBlockedDomainsChartTotalRequests: 0,
    topBlockedCategoriesChart: [],
    isTopBlockedCategoriesChartLoading: false,
    topBlockedCategoriesChartTotalRequests: 0,
    activityChartAllowedRequests: 0,
    activityChartBlockedRequests: 0
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case swg_dashboard_actions_1.SwgDashboardActionsEnum.LOAD_ACTIVITYCHART:
            return Object.assign(Object.assign({}, state), { isActivityChartLoading: true, activityChart: [] });
        case swg_dashboard_actions_1.SwgDashboardActionsEnum.LOAD_ACTIVITYCHART_SUCCESS:
            return Object.assign(Object.assign({}, state), { isActivityChartLoading: false, activityChart: action.payload.activityChartData, activityChartTotalRequests: action.payload.totalCount, activityChartAllowedRequests: action.payload.allowedCount, activityChartBlockedRequests: action.payload.blockedCount });
        case swg_dashboard_actions_1.SwgDashboardActionsEnum.LOAD_ACTIVITYCHART_FAILURE:
            return Object.assign(Object.assign({}, state), { isActivityChartLoading: false, activityChart: [], activityChartTotalRequests: 0, activityChartAllowedRequests: 0, activityChartBlockedRequests: 0 });
        case swg_dashboard_actions_1.SwgDashboardActionsEnum.LOAD_TOPDOMAINSCHART:
            return Object.assign(Object.assign({}, state), { isTopDomainsChartLoading: true, topDomainsChart: [] });
        case swg_dashboard_actions_1.SwgDashboardActionsEnum.LOAD_TOPDOMAINSCHART_SUCCESS:
            return Object.assign(Object.assign({}, state), { isTopDomainsChartLoading: false, topDomainsChart: action.payload.list, topDomainsChartTotalRequests: action.payload.totalCount });
        case swg_dashboard_actions_1.SwgDashboardActionsEnum.LOAD_TOPDOMAINSCHART_FAILURE:
            return Object.assign(Object.assign({}, state), { isTopDomainsChartLoading: false, topDomainsChart: [] });
        case swg_dashboard_actions_1.SwgDashboardActionsEnum.LOAD_TOPCATEGORIESCHART:
            return Object.assign(Object.assign({}, state), { isTopCategoriesChartLoading: true, topCategoriesChart: [] });
        case swg_dashboard_actions_1.SwgDashboardActionsEnum.LOAD_TOPCATEGORIESCHART_SUCCESS:
            return Object.assign(Object.assign({}, state), { isTopCategoriesChartLoading: false, topCategoriesChart: action.payload.list, topCategoriesChartTotalRequests: action.payload.totalCount });
        case swg_dashboard_actions_1.SwgDashboardActionsEnum.LOAD_TOPCATEGORIESCHART_FAILURE:
            return Object.assign(Object.assign({}, state), { isTopCategoriesChartLoading: false, topCategoriesChart: [] });
        case swg_dashboard_actions_1.SwgDashboardActionsEnum.LOAD_TOPBLOCKEDDOMAINSCHART:
            return Object.assign(Object.assign({}, state), { isTopBlockedDomainsChartLoading: true, topBlockedDomainsChart: [] });
        case swg_dashboard_actions_1.SwgDashboardActionsEnum.LOAD_TOPBLOCKEDDOMAINSCHART_SUCCESS:
            return Object.assign(Object.assign({}, state), { isTopBlockedDomainsChartLoading: false, topBlockedDomainsChart: action.payload.list, topBlockedDomainsChartTotalRequests: action.payload.totalCount });
        case swg_dashboard_actions_1.SwgDashboardActionsEnum.LOAD_TOPBLOCKEDDOMAINSCHART_FAILURE:
            return Object.assign(Object.assign({}, state), { isTopBlockedDomainsChartLoading: false, topBlockedDomainsChart: [] });
        case swg_dashboard_actions_1.SwgDashboardActionsEnum.LOAD_TOPBLOCKEDCATEGORIESCHART:
            return Object.assign(Object.assign({}, state), { isTopBlockedCategoriesChartLoading: true, topBlockedCategoriesChart: [] });
        case swg_dashboard_actions_1.SwgDashboardActionsEnum.LOAD_TOPBLOCKEDCATEGORIESCHART_SUCCESS:
            return Object.assign(Object.assign({}, state), { isTopBlockedCategoriesChartLoading: false, topBlockedCategoriesChart: action.payload.list, topBlockedCategoriesChartTotalRequests: action.payload.totalCount });
        case swg_dashboard_actions_1.SwgDashboardActionsEnum.LOAD_TOPBLOCKEDCATEGORIESCHART_FAILURE:
            return Object.assign(Object.assign({}, state), { isTopBlockedCategoriesChartLoading: false, topBlockedCategoriesChart: [] });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getActivityChartData = (state) => state.activityChart;
exports.getActivityChartData = getActivityChartData;
const isActivityChartLoading = (state) => state.isActivityChartLoading;
exports.isActivityChartLoading = isActivityChartLoading;
const getActivityChartTotalRequests = (state) => state.activityChartTotalRequests;
exports.getActivityChartTotalRequests = getActivityChartTotalRequests;
const getTopDomainsChartData = (state) => state.topDomainsChart;
exports.getTopDomainsChartData = getTopDomainsChartData;
const isTopDomainsChartLoading = (state) => state.isTopDomainsChartLoading;
exports.isTopDomainsChartLoading = isTopDomainsChartLoading;
const getTopDomainsChartTotalRequests = (state) => state.topDomainsChartTotalRequests;
exports.getTopDomainsChartTotalRequests = getTopDomainsChartTotalRequests;
const getTopCategoriesChartData = (state) => state.topCategoriesChart;
exports.getTopCategoriesChartData = getTopCategoriesChartData;
const isTopCategoriesChartLoading = (state) => state.isTopCategoriesChartLoading;
exports.isTopCategoriesChartLoading = isTopCategoriesChartLoading;
const getTopCategoriesChartTotalRequests = (state) => state.topCategoriesChartTotalRequests;
exports.getTopCategoriesChartTotalRequests = getTopCategoriesChartTotalRequests;
const getTopBlockedDomainsChartData = (state) => state.topBlockedDomainsChart;
exports.getTopBlockedDomainsChartData = getTopBlockedDomainsChartData;
const isTopBlockedDomainsChartLoading = (state) => state.isTopBlockedDomainsChartLoading;
exports.isTopBlockedDomainsChartLoading = isTopBlockedDomainsChartLoading;
const getTopBlockedDomainsChartTotalRequests = (state) => state.topBlockedDomainsChartTotalRequests;
exports.getTopBlockedDomainsChartTotalRequests = getTopBlockedDomainsChartTotalRequests;
const getTopBlockedCategoriesChartData = (state) => state.topBlockedCategoriesChart;
exports.getTopBlockedCategoriesChartData = getTopBlockedCategoriesChartData;
const isTopBlockedCategoriesChartLoading = (state) => state.isTopBlockedCategoriesChartLoading;
exports.isTopBlockedCategoriesChartLoading = isTopBlockedCategoriesChartLoading;
const getTopBlockedCategoriesChartTotalRequests = (state) => state.topBlockedCategoriesChartTotalRequests;
exports.getTopBlockedCategoriesChartTotalRequests = getTopBlockedCategoriesChartTotalRequests;
const getActivityChartAllowedRequests = (state) => state.activityChartAllowedRequests;
exports.getActivityChartAllowedRequests = getActivityChartAllowedRequests;
const getActivityChartBlockedRequests = (state) => state.activityChartBlockedRequests;
exports.getActivityChartBlockedRequests = getActivityChartBlockedRequests;
