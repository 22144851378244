"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApPolicyListImplService = void 0;
const table_store_1 = require("@mimecast-ui/table-store");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
class ApPolicyListImplService {
    constructor(http, translateService) {
        this.http = http;
        this.translateService = translateService;
    }
    getData(payload, pagination = table_store_1.defaultPaginationOption) {
        return this.http
            .post('/api/policy/attachmentprotect/get-policy-with-targets-list', Object.assign(Object.assign({}, payload), { meta: { pagination } }))
            .pipe(operators_1.map((response) => {
            const RETURN_DATA = {
                hasErrors: response.hasErrors,
                data: this.mapAPIResponse(response.first.definitions),
                failures: response.fail,
                meta: response.meta
            };
            return RETURN_DATA;
        }), operators_1.catchError((response) => {
            const RETURN_DATA = {
                hasErrors: response.hasErrors,
                data: response.all,
                failures: response.fail,
                meta: response.meta
            };
            return rxjs_1.of(RETURN_DATA);
        }));
    }
    filter(query, pagination) {
        const payload = {
            searchField: 'description',
            query: query && query.policyName ? query.policyName : null
        };
        return this.getData(payload, pagination);
    }
    openItem() {
        return rxjs_1.of([]);
    }
    mapAPIResponse(data) {
        const response = data.map((policyPayload) => {
            const isEternalEnabled = policyPayload.policies[0].policy.toEternal
                ? this.translateService.instant('$I18N_DATE_RANGE_PERPETUAL')
                : this.translateService.instant('$I18N_DATE_RANGE');
            const isPolicyEnabled = policyPayload.policies[0].policy.enabled
                ? this.translateService.instant('$I18N_POLICY_ENABLED')
                : this.translateService.instant('$I18N_POLICY_DISABLED');
            // @ts-ignore
            const apPolicy = {
                rules: policyPayload.conflict
                    ? this.translateService.instant('$I18N_CONFIGURATION_OPTION_-')
                    : policyPayload.policies.length,
                description: policyPayload.description,
                action: policyPayload.conflict
                    ? this.translateService.instant('$I18N_CONFIGURATION_OPTION_-')
                    : policyPayload.action,
                status: policyPayload.conflict
                    ? this.translateService.instant('$I18N_POLICY_CONFLICT')
                    : isPolicyEnabled,
                dateRange: policyPayload.conflict
                    ? this.translateService.instant('$I18N_CONFIGURATION_OPTION_-')
                    : isEternalEnabled,
                checkInbound: policyPayload.checkInbound,
                checkOutbound: policyPayload.checkOutbound,
                checkJournal: policyPayload.checkJournal,
                id: policyPayload.id,
                policies: policyPayload.policies
            };
            return apPolicy;
        });
        return response;
    }
}
exports.ApPolicyListImplService = ApPolicyListImplService;
