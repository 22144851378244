"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WildfireIntegrationService = void 0;
const operators_1 = require("rxjs/operators");
const common_1 = require("app-new/api-applications/services/common");
const BASE_URL = '/api/integration/wildfire';
const INTERNAL_EMAIL_PROTECT_ACCOUNT_PACKAGE = '[1064]';
class WildfireIntegrationService {
    constructor(http, coreService, capabilitesService) {
        this.http = http;
        this.coreService = coreService;
        this.capabilitesService = capabilitesService;
        this.hasMaliciousAttachmentCapability = false;
        this.capabilitesService
            .hasCapability('Temporary.Services.ApiApplications.Wildfire.Integration.SubmitMaliciousAttachment')
            .subscribe(isActive => {
            if (isActive) {
                this.hasMaliciousAttachmentCapability = true;
            }
        });
    }
    acceptSharingInfo() {
        return this.http.post(`${BASE_URL}/accept-sharing-info`, {}).pipe(common_1.firstResult());
    }
    createWildfireIntegration(integration) {
        return this.http
            .post(`${BASE_URL}/create-integration`, integration)
            .pipe(common_1.firstResult());
    }
    updateWildfireIntegration(integration) {
        return this.http
            .post(`${BASE_URL}/update-integration`, integration)
            .pipe(common_1.firstResult());
    }
    getWildfireIntegration(id) {
        return this.http
            .post(`${BASE_URL}/get-integration`, { id })
            .pipe(common_1.firstResult());
    }
    checkWildfireIntegrationPrerequisites() {
        return this.http.post(`${BASE_URL}/check-prerequisites`, null).pipe(common_1.firstResult());
    }
    verifyApiAndRegionAction(apiKey, region) {
        return this.http
            .post(`${BASE_URL}/verify`, { apiKey, region })
            .pipe(common_1.firstResult());
    }
    hasInternalEmailProtectAccountPackage() {
        let hasInternalEmailProtectPackage;
        this.coreService
            .getAccount()
            .pipe(operators_1.first())
            .subscribe(account => {
            hasInternalEmailProtectPackage = !!account.packages.filter(pkg => pkg.indexOf(INTERNAL_EMAIL_PROTECT_ACCOUNT_PACKAGE) >= 0)[0];
        });
        return hasInternalEmailProtectPackage;
    }
    buildUpdateRequest(configuration, sendApiKey) {
        const request = {
            id: configuration.id,
            notificationGroups: Object.keys(configuration.notificationGroups),
            region: configuration.region,
            remediationRequired: configuration.remediationRequired,
            notificationAddresses: configuration.notificationAddresses,
            indicators: [
                {
                    indicator: configuration.indicators.ATTACHMENT.indicator,
                    enabled: configuration.indicators.ATTACHMENT.enabled
                }
            ],
            enabled: configuration.enabled
        };
        if (sendApiKey) {
            request['apiKey'] = configuration.apiKey;
        }
        if (this.hasMaliciousAttachmentCapability) {
            request.indicators.push({
                indicator: configuration.indicators.MALICIOUS_ATTACHMENT.indicator,
                enabled: configuration.indicators.MALICIOUS_ATTACHMENT.enabled
            });
        }
        return request;
    }
    buildCreateRequest(configuration) {
        const request = {
            apiKey: configuration.apiKey,
            notificationGroups: Object.keys(configuration.notificationGroups),
            region: configuration.region,
            remediationRequired: configuration.remediationRequired,
            notificationAddresses: configuration.notificationAddresses,
            indicators: [
                {
                    indicator: configuration.indicators.ATTACHMENT.indicator,
                    enabled: configuration.indicators.ATTACHMENT.enabled
                }
            ],
            enabled: configuration.enabled
        };
        if (this.hasMaliciousAttachmentCapability) {
            request.indicators.push({
                indicator: configuration.indicators.MALICIOUS_ATTACHMENT.indicator,
                enabled: configuration.indicators.MALICIOUS_ATTACHMENT.enabled
            });
        }
        return request;
    }
}
exports.WildfireIntegrationService = WildfireIntegrationService;
