<div class="mc-onedrive-backup-wizard-details">
    <mc-wizard-step-header 
        [label]="subTitle"
    >
    </mc-wizard-step-header>
    <form
        [formGroup]="form"
        class="form-horizontal"
        mcDefaultLabelClass="col-sm-3"
        mcDefaultControlContainerClass="col-sm-9"
    >
        <mc-text-field
            formControlName="name"    
            label="$I18N_ONEDRIVE_BACKUP_WIZARD.STEPS.DETAILS.FORM.NAME_LABEL"
            required
            useTranslation="true"            
            errorPrefix="$I18N_ONEDRIVE_BACKUP_WIZARD.STEPS.DETAILS.FORM.NAME_ERROR"
        ></mc-text-field>
        <mc-field
            label="$I18N_ONEDRIVE_BACKUP_WIZARD.STEPS.DETAILS.FORM.GROUP_LABEL" 
            helpPopoverContent="{{ '$I18N_ONEDRIVE_BACKUP_WIZARD.STEPS.DETAILS.FORM.GROUP_TOOLTIP' | translate}}"
            useTranslation="true"
            [help]="groupHelpText"
            required
        >
            <input formControlName="group" type="hidden"/>
            <button 
                type="button" 
                class="btn btn-secondary" 
                data-test="select-reviewer-btn" 
                (click)="selectGroup()"
            >
                {{ '$I18N_ONEDRIVE_BACKUP_WIZARD.STEPS.DETAILS.FORM.GROUP_BUTTON_TEXT' | translate }}
            </button>
            <mc-removable-list 
                [data]="selectedGroup" 
                (removedItem)="removeGroup()"
            ></mc-removable-list>
        </mc-field>
        <mc-field 
            label="$I18N_ONEDRIVE_BACKUP_WIZARD.STEPS.DETAILS.FORM.CONNECTOR_LABEL"
            helpPopoverContent="{{ '$I18N_ONEDRIVE_BACKUP_WIZARD.STEPS.DETAILS.FORM.CONNECTOR_TOOLTIP' | translate}}"
            useTranslation="true"
            required
            errorPrefix="$I18N_ONEDRIVE_BACKUP_WIZARD.STEPS.DETAILS.FORM.CONNECTOR_ERROR"
        >
            <mc-select
                formControlName="connector"
                required
                [options]="connectors"
                [defaultValue]="defaultSelectedConnector"
            ></mc-select>
        </mc-field> 
        
    </form>
</div>