"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccessProviderMsComponent = void 0;
const core_1 = require("@angular/core");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const model_1 = require("../../../model");
const connectorActions = require("../../../actions/connector.actions");
const connector_actions_1 = require("../../../actions/connector.actions");
const reducers_1 = require("../../../reducers");
class AccessProviderMsComponent {
    constructor(store, windowService, translateService, featureFlagService, coreService) {
        this.store = store;
        this.windowService = windowService;
        this.translateService = translateService;
        this.featureFlagService = featureFlagService;
        this.coreService = coreService;
        this.update = new core_1.EventEmitter();
        this.destroy$ = new rxjs_1.Subject();
        this.PROVIDER_MS = 'ms';
        this.COMPONENT_I18N_PREFIX = '$I18N_ACCESS_PROVIDER_MS';
        this.SIGN_IN_I18N_PREFIX = `${this.COMPONENT_I18N_PREFIX}.SIGN_IN`;
        this.STEP_TITLE_I18N_PREFIX = `${this.COMPONENT_I18N_PREFIX}.TITLE`;
        this.permissonGranted$ = new rxjs_1.BehaviorSubject(false);
        this.permissonNotGranted$ = new rxjs_1.BehaviorSubject(false);
    }
    set provider(provider) {
        if (provider) {
            this._provider = provider;
            this.providerConsentStepTitle = `${this.STEP_TITLE_I18N_PREFIX}.${provider.type.toUpperCase()}`;
            this.providerName = this.getProviderName(provider.type);
        }
    }
    get provider() {
        return this._provider;
    }
    ngOnInit() {
        const ready$ = rxjs_1.from(this.featureFlagService.isReady());
        this.supportLink$ = ready$.pipe(operators_1.switchMap(() => this.coreService.getAccountSwitchSession().pipe(operators_1.take(1))), operators_1.map(switchedSession => {
            const hasConfigEnabled = this.featureFlagService.getBooleanFlag('rollout-zendesk-contact-support');
            const encodedAccountCode = switchedSession && switchedSession.accountcode
                ? `?ac=${encodeURIComponent(btoa(switchedSession.accountcode))}`
                : '';
            return hasConfigEnabled
                ? `/apps#/sso/support${encodedAccountCode}`
                : '/apps#sso/mcentral?goto=/s/contactsupport';
        }));
        this.authCallbackHandler = this.handleAuthCallback.bind(this);
        this.windowService.addEventListener('message', this.authCallbackHandler);
        this.store
            .select(reducers_1.getConsentConfig)
            .pipe(operators_1.skip(1), operators_1.takeUntil(this.destroy$))
            .subscribe(config => {
            if (config) {
                this.handleConsentConfig(config);
                this.store.dispatch(new connector_actions_1.ClearConsentConfigAction());
            }
        });
        this.store
            .select(reducers_1.getFormResets)
            .pipe(operators_1.takeUntil(this.destroy$))
            .subscribe(formResets => {
            if (formResets.consent) {
                this.resetConsentState();
            }
        });
    }
    ngOnDestroy() {
        this.windowService.removeEventListener('message', this.authCallbackHandler);
        this.destroy$.next(true);
        this.destroy$.complete();
    }
    loadConsentConfig() {
        this.dispatchConsentConfig();
        this.resetConsentState();
        // Need to open OAuth flow window immediately in response to
        // user action. Else subsequent programmatic opening would be
        // vulnerable to browser popup blockers.
        this.openOAuthWindow();
    }
    dispatchConsentConfig() {
        this.providedState = '' + Math.floor(Math.random() * 90000) + 10000;
        const request = {
            id: this.provider.id,
            domain: window.location.origin,
            path: '/administration/app',
            stateParameters: {
                provider: this.PROVIDER_MS,
                clientState: this.providedState
            }
        };
        this.store.dispatch(new connectorActions.GetConsentConfigAction(request));
    }
    resetConsentState() {
        this.permissonGranted$.next(false);
        this.permissonNotGranted$.next(false);
        this.errorCode = undefined;
    }
    openOAuthWindow() {
        const x = screen.width / 2 - 500 / 2;
        const y = screen.height / 2 - 800 / 2;
        const name = this.translateService.instant(this.getSignInMessageKey(this.provider.type));
        const specs = 'width=500,height=800,left=' + x + ',top=' + y;
        this.authWindow = this.windowService.open('', name, specs);
    }
    handleConsentConfig(config) {
        if (config.redirectUrl) {
            this.authWindow.location.href = config.redirectUrl;
        }
        else {
            this.permissonNotGranted$.next(true);
            this.errorCode = model_1.ConsentErrorCode.CONSENT_CONFIG;
            // Close on api error response.
            this.authWindow.close();
        }
    }
    handleAuthCallback(event) {
        if (!this.isValidMessage(event)) {
            return;
        }
        const { authToken, errorCode } = event.data;
        this.permissonGranted$.next(!!authToken);
        this.permissonNotGranted$.next(!!errorCode);
        this.errorCode = errorCode;
        if (authToken) {
            this.update.emit(authToken);
        }
    }
    /*
     * Security validation:
     * - confirm sender is also AdCon component via origin.
     * - confirm the received state param is equal to
     *   provided state param created upon opening of popup.
     */
    isValidMessage(event) {
        const { state } = event.data;
        return event.origin === window.location.origin && state && state === this.providedState;
    }
    getSignInMessageKey(type) {
        switch (type) {
            case 'one_drive':
                return `${this.SIGN_IN_I18N_PREFIX}.ONE_DRIVE`;
            case 'ms_teams':
                return `${this.SIGN_IN_I18N_PREFIX}}.MS_TEAMS`;
            default:
                return `${this.SIGN_IN_I18N_PREFIX}}.GENERIC`;
        }
    }
    getProviderName(type) {
        return this.translateService.instant(`$I18N_CONNECTORS.PROVIDERS.${type.toUpperCase()}`);
    }
    isTechnicalSupportError() {
        return (this.errorCode &&
            (this.errorCode === model_1.ConsentErrorCode.CONSENT_CONFIG ||
                this.errorCode === model_1.ConsentErrorCode.UNKNOWN));
    }
}
exports.AccessProviderMsComponent = AccessProviderMsComponent;
