"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileUploadService = exports.MAX_CHUNK_SIZE = void 0;
const core_1 = require("@angular/core");
const CryptoJS = require("crypto-js");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
function getSha256(wordArray) {
    return CryptoJS.SHA256(wordArray).toString(CryptoJS.enc.Hex);
}
exports.MAX_CHUNK_SIZE = new core_1.InjectionToken('Max size of chunks to upload file with in bytes, defaults to 10MB', { factory: () => 10 * 1024 * 1024 });
class FileUploadService {
    constructor(uploadHelper, maxChunkSize) {
        this.uploadHelper = uploadHelper;
        this.maxChunkSize = maxChunkSize;
    }
    uploadFile(file) {
        const attachment = {
            filename: file.name,
            extension: file.name.split('.').pop(),
            size: file.size,
            contentType: file.type,
            progress: 0
        };
        const notifier = new rxjs_1.Subject();
        this.uploadHelper.splitFileIntoChunks(file, this.maxChunkSize).then(chunks => {
            notifier.next(attachment);
            const sha256Hasher = CryptoJS.algo.SHA256.create();
            const md5Hasher = CryptoJS.algo.MD5.create();
            const wordArrays = chunks.map(c => {
                return CryptoJS.lib.WordArray.create(c.data);
            });
            const hashed = [];
            wordArrays.forEach((wordArray, i) => {
                sha256Hasher.update(wordArray);
                md5Hasher.update(wordArray);
                hashed.push(Object.assign(Object.assign({}, chunks[i]), { sha256: getSha256(wordArray) }));
            });
            const fileSha256 = sha256Hasher.finalize().toString(CryptoJS.enc.Hex);
            const fileMd5 = md5Hasher.finalize().toString(CryptoJS.enc.Hex);
            attachment.md5 = fileMd5;
            attachment.sha256 = fileSha256;
            notifier.next(attachment);
            const uploads = hashed.map((h, i) => {
                return this.uploadHelper.uploadChunk(fileSha256, h).pipe(operators_1.tap(() => {
                    attachment.progress = (i / chunks.length) * 100;
                    notifier.next(attachment);
                }));
            });
            const summary = {
                fileSha256,
                fileSize: file.size,
                listOfChunksSha256: hashed.map(h => h.sha256)
            };
            // upload each chunk, one after the other
            rxjs_1.concat(...uploads)
                .pipe(
            // wait until they are all complete, before commiting and confirming chunks
            operators_1.bufferCount(chunks.length), operators_1.mergeMap(() => {
                return this.uploadHelper.commitChunks(summary);
            }), operators_1.tap(uploadId => {
                attachment.id = uploadId;
                notifier.next(attachment);
            }), operators_1.mergeMap(() => {
                return this.uploadHelper.confirmChunks(summary);
            }), operators_1.tap(() => {
                attachment.progress = 100;
                notifier.next(attachment);
            }))
                .subscribe();
            // TODO: link the above observable to the notifier, so that the unsubscription of the latter
            // cancels the former. this becomes import to kill large uploads.
        });
        return notifier;
    }
}
exports.FileUploadService = FileUploadService;
