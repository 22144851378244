"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LayoutAsideTabsComponent = void 0;
const base_1 = require("../base");
class LayoutAsideTabsComponent extends base_1.LayoutAsideDirective {
    constructor(window, locationService) {
        super();
        this.window = window;
        this.locationService = locationService;
        this.hasTabs = true;
        this.origin = this.locationService.protocol + '//' + this.locationService.host;
    }
    ngOnInit() {
        this.window.postMessageToParent({
            action: 'AsideModalOpened'
        }, this.origin);
    }
    ngOnDestroy() {
        this.window.postMessageToParent({
            action: 'AsideModalClosed'
        }, this.origin);
    }
}
exports.LayoutAsideTabsComponent = LayoutAsideTabsComponent;
