<mc-layout-modal-simple
  [keyTitle]="'$I18N_MESSAGE_CENTER_REPORT_MESSAGE_MODAL.TITLE.' + actionType | uppercase "
  [keyDescription]="'$I18N_MESSAGE_CENTER_REPORT_MESSAGE_MODAL.TEXT_DESCRIPTION.' + actionType | uppercase">
  <mc-body-container>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <textarea
        class="form-control mc-report-message-textarea"
        rows="3"
        #reportMessageInput
        type="text"
        [placeholder]="'$I18N_MESSAGE_CENTER_REPORT_MESSAGE_MODAL.INPUT_PLACEHOLDER' | translate"
        formControlName="reportMessageComment"></textarea>
      <button type="submit" hidden="true"></button>
    </form>
  </mc-body-container>
  <mc-footer-container>
    <button type="button" class="btn btn-secondary" (click)="close()">
      {{ '$I18N_MESSAGE_CENTER_REPORT_MESSAGE_MODAL.CANCEL_BUTTON' | translate }}
    </button>
    <button type="button" [disabled]="!form.valid" class="btn btn-primary" (click)="onSubmit()">
      {{ '$I18N_MESSAGE_CENTER_REPORT_MESSAGE_MODAL.SUBMIT_BUTTON' | translate }}
    </button>
  </mc-footer-container>
</mc-layout-modal-simple>
