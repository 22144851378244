"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValueListComponent = void 0;
class ValueListComponent {
    constructor() {
        this.DEFAULT_MORE_LESS_TRIGGER_VALUE = 0;
        this.isExpanded = false;
        this.showMoreLessTriggerValue = this.DEFAULT_MORE_LESS_TRIGGER_VALUE;
        this.showLessLabel = '$I18N_VALUE_LIST_COMPONENT_SHOW_LESS';
        this.showMoreLabel = '$I18N_VALUE_LIST_COMPONENT_SHOW_MORE';
    }
    invertIsExpanded() {
        this.isExpanded = !this.isExpanded;
    }
    showMoreLess() {
        return (this.showMoreLessTriggerValue > 0 && this.listItems.length > this.showMoreLessTriggerValue);
    }
}
exports.ValueListComponent = ValueListComponent;
