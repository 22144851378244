"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgExceptionsListApiService = void 0;
class SwgExceptionsListApiService {
    constructor(http) {
        this.http = http;
    }
    getExceptionsList(config) {
        return this.http.post('/api/swg/get-account-web-bypass', config);
    }
    createException(config) {
        return this.http.post('/api/swg/create-web-bypass', config);
    }
    updateException(config) {
        return this.http.post('/api/swg/update-web-bypass', config);
    }
    deleteException(id) {
        return this.http.post('/api/swg/delete-web-bypass', { id });
    }
}
exports.SwgExceptionsListApiService = SwgExceptionsListApiService;
