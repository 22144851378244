"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tableSectionSearch = exports.tableSectionFilters = exports.tableColumnsStartHidden = exports.tableColumnsAlwaysVisible = exports.exportColumns = exports.tableColumns = void 0;
exports.tableColumns = [
    'status',
    'spamScore',
    'detectionLevel',
    'operator',
    'fromHdr',
    'fromEnv',
    'to',
    'subject',
    'route',
    'released',
    'size',
    'right-column'
];
exports.exportColumns = [
    'status',
    'spamScore',
    'spamDetection',
    'operator',
    'fromHdr',
    'fromEnv',
    'to',
    'subject',
    'route',
    'released',
    'size'
];
exports.tableColumnsAlwaysVisible = ['status', 'operator'];
exports.tableColumnsStartHidden = ['fromEnv', 'route', 'size', 'spamScore', 'detectionLevel'];
exports.tableSectionFilters = [
    {
        name: 'status',
        filters: ['released', 'rejected']
    },
    {
        name: 'route',
        filters: ['internal', 'inbound', 'outbound']
    }
];
exports.tableSectionSearch = {
    field: 'search',
    header: 'header',
    options: ['operator', 'from', 'to', 'subject']
};
