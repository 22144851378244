"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasGenericError = exports.areStatsLoading = exports.getIncidentStats = exports.reducer = exports.initialState = void 0;
const incidentStatsAction = require("../../actions/incident-stats/incident-stats.action");
exports.initialState = {
    incidentCount: {
        restore: 0,
        automatic: 0,
        manual: 0,
        notify_only: 0,
        mep_clawback: 0
    },
    genericError: false,
    isLoading: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case incidentStatsAction.GET_INCIDENTS_STATS:
            return Object.assign(Object.assign({}, state), { genericError: false, isLoading: true });
        case incidentStatsAction.GET_INCIDENTS_STATS_SUCCESS:
            return Object.assign(Object.assign({}, state), { incidentCount: action.payload, genericError: false, isLoading: false });
        case incidentStatsAction.GET_INCIDENTS_STATS_FAILURE:
            return Object.assign(Object.assign({}, state), { genericError: true, isLoading: false });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getIncidentStats = (state) => {
    return state.incidentCount;
};
exports.getIncidentStats = getIncidentStats;
const areStatsLoading = (state) => state.isLoading;
exports.areStatsLoading = areStatsLoading;
const hasGenericError = (state) => state.genericError;
exports.hasGenericError = hasGenericError;
