<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="mc-search-form">
    <ng-container>
      <mc-text-field label="$I18N_REMEDIATION_SEARCH_MESSAGES_BY_DATA.FROM_LABEL"
                     placeholder="$I18N_REMEDIATION_SEARCH_MESSAGES_BY_DATA.FROM_TO_LABEL_PLACEHOLDER"
                     errorPrefix="$I18N_REMEDIATION_SEARCH_MESSAGES_BY_DATA.ERRORS"
                     formControlName="from">
      </mc-text-field>
      <mc-text-field *ngIf="!hasSearchWithoutPermission"
                     label="$I18N_REMEDIATION_SEARCH_MESSAGES_BY_DATA.TO_LABEL"
                     placeholder="$I18N_REMEDIATION_SEARCH_MESSAGES_BY_DATA.FROM_TO_LABEL_PLACEHOLDER"
                     errorPrefix="$I18N_REMEDIATION_SEARCH_MESSAGES_BY_DATA.ERRORS"
                     formControlName="to">
      </mc-text-field>
      <mc-text-field *ngIf="hasSearchWithoutPermission"
                     class="mc-remediation-search-subject"
                     label="$I18N_REMEDIATION_SEARCH_MESSAGES_BY_DATA.SUBJECT_LABEL"
                     errorPrefix="$I18N_REMEDIATION_SEARCH_MESSAGES_BY_DATA.ERRORS"
                     formControlName="subject">
      </mc-text-field>
      <mc-text-field ng-class="hasSearchWithoutPermission? 'mc-advanced-search-file-hash' : 'mc-search-file-hash'"
                     label="$I18N_REMEDIATION_SEARCH_MESSAGES_BY_DATA.FILE_HASH_LABEL"
                     placeholder="$I18N_REMEDIATION_SEARCH_MESSAGES_BY_DATA.FILE_HASH_PLACEHOLDER"
                     errorPrefix="$I18N_REMEDIATION_SEARCH_MESSAGES_BY_DATA.ERRORS"
                     [required]="!hasSearchWithoutPermission"
                     formControlName="fileHash">
      </mc-text-field>
      <mc-text-field *ngIf="hasSearchWithoutPermission"
                     class="mc-remediation-search-url"
                     label="$I18N_REMEDIATION_SEARCH_MESSAGES_BY_DATA.URL_LABEL"
                     errorPrefix="$I18N_REMEDIATION_SEARCH_MESSAGES_BY_DATA.ERRORS"
                     formControlName="url">
      </mc-text-field>
      <mc-date-range-picker #dateRangeSelect
                            *ngIf="hasSearchWithoutPermission"
                            label="$I18N_REMEDIATION_SEARCH_MESSAGES_BY_DATA.DATE_RANGE_LABEL"
                            [config]="dateRangeConfig"
                            formControlName="dateRange">
      </mc-date-range-picker>
    </ng-container>

    <button *ngIf="!hasSearchWithoutPermission"
            class="btn btn-primary mc-search-submit"
            [disabled]="form.invalid || isSearchInProgress || !isRemediationEnabled || checkEmptyValues()"
            type="submit">
      {{ "$I18N_REMEDIATION_SEARCH_MESSAGES_BY_DATA.SUBMIT_BUTTON" | translate }}
    </button>

    <ng-template #urlFilterNotEnabled>
      <mc-text-field ng-class="hasSearchWithoutPermission? 'mc-advanced-search-file-hash' : 'mc-search-file-hash'"
                     label="$I18N_REMEDIATION_SEARCH_MESSAGES_BY_DATA.FILE_HASH_LABEL"
                     placeholder="$I18N_REMEDIATION_SEARCH_MESSAGES_BY_DATA.FILE_HASH_PLACEHOLDER"
                     errorPrefix="$I18N_REMEDIATION_SEARCH_MESSAGES_BY_DATA.ERRORS"
                     formControlName="fileHash" [required]="!hasSearchWithoutPermission">
      </mc-text-field>
      <mc-text-field  label="$I18N_REMEDIATION_SEARCH_MESSAGES_BY_DATA.FROM_LABEL"
                      placeholder="$I18N_REMEDIATION_SEARCH_MESSAGES_BY_DATA.FROM_TO_LABEL_PLACEHOLDER"
                      errorPrefix="$I18N_REMEDIATION_SEARCH_MESSAGES_BY_DATA.ERRORS"
                      formControlName="from">
      </mc-text-field>
      <mc-text-field *ngIf="hasSearchWithoutPermission" label="$I18N_REMEDIATION_SEARCH_MESSAGES_BY_DATA.SUBJECT_LABEL"
                     errorPrefix="$I18N_REMEDIATION_SEARCH_MESSAGES_BY_DATA.ERRORS" formControlName="subject">
      </mc-text-field>
      <mc-text-field *ngIf="!hasSearchWithoutPermission" label="$I18N_REMEDIATION_SEARCH_MESSAGES_BY_DATA.TO_LABEL"
                     placeholder="$I18N_REMEDIATION_SEARCH_MESSAGES_BY_DATA.FROM_TO_LABEL_PLACEHOLDER"
                     errorPrefix="$I18N_REMEDIATION_SEARCH_MESSAGES_BY_DATA.ERRORS"
                     formControlName="to">
      </mc-text-field>
      <mc-date-range-picker #dateRangeSelect *ngIf="hasSearchWithoutPermission"
                            label="$I18N_REMEDIATION_SEARCH_MESSAGES_BY_DATA.DATE_RANGE_LABEL"
                            formControlName="dateRange"
                            [config]="dateRangeConfig">
      </mc-date-range-picker>
    </ng-template>
  </div>
  <button *ngIf="hasSearchWithoutPermission"
          class="btn btn-primary mc-advanced-search-submit"
          [disabled]="form.invalid || isSearchInProgress || !isRemediationEnabled || checkEmptyValues()"
          type="submit">
    {{ "$I18N_REMEDIATION_SEARCH_MESSAGES_BY_DATA.SUBMIT_BUTTON" | translate }}
  </button>
</form>
