"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgThreatEventListContainerComponent = void 0;
const swg_threat_events_reducer_1 = require("../../reducers/swg-threat-events.reducer");
const swg_threat_events_actions_1 = require("../../actions/swg-threat-events.actions");
const swg_application_dashboard_static_value_1 = require("../../../list/component/dashboard/swg-application-dashboard.static-value");
const swg_threat_event_details_actions_1 = require("../../actions/swg-threat-event-details.actions");
class SwgThreatEventListContainerComponent {
    constructor(store, stateService) {
        this.store = store;
        this.stateService = stateService;
    }
    static transformDateRangeFilter(dateRange) {
        return {
            start: dateRange.start.format(),
            end: dateRange.end.format()
        };
    }
    ngOnInit() {
        this.loadData(swg_application_dashboard_static_value_1.dateRangePickerConfig.dateRanges[0]);
        this.threatEvents$ = this.store.select(swg_threat_events_reducer_1.selectThreatEvents);
        this.metaData$ = this.store.select(swg_threat_events_reducer_1.selectPagingMetadata);
        this.isLoading$ = this.store.select(swg_threat_events_reducer_1.selectIsLoading);
        this.graphData$ = this.store.select(swg_threat_events_reducer_1.selectGraphData);
        this.isGraphDataLoading$ = this.store.select(swg_threat_events_reducer_1.selectIsGraphDataLoading);
        const id = this.stateService.$state.params.id;
        if (!!id) {
            this.openEvent(id);
        }
    }
    loadData(dateRange) {
        const simpleDateRange = SwgThreatEventListContainerComponent.transformDateRangeFilter(dateRange.rangeFn());
        this.store.dispatch(new swg_threat_events_actions_1.SwgLoadThreatEventsAction(simpleDateRange));
        this.store.dispatch(new swg_threat_events_actions_1.SwgLoadThreatEventsGraphAction(simpleDateRange));
    }
    changePage(page) {
        this.store.dispatch(new swg_threat_events_actions_1.SwgPaginateThreatEventsAction(page));
    }
    openEvent(id) {
        this.store.dispatch(new swg_threat_event_details_actions_1.SwgThreatEventsSidebarOpenAction(id));
    }
}
exports.SwgThreatEventListContainerComponent = SwgThreatEventListContainerComponent;
