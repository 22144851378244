<div class="panel panel-default mc-tq-panel">
  <div class="panel-heading">
    {{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.TOP_QUEUES.MESSAGE_STATUS' | translate }}
  </div>

  <table class="table" *ngIf="hasQueues(); else nodata">
    <thead>
      <tr>
        <th class="name-col header-col">
          {{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.TOP_QUEUES.NAME' | translate }}
        </th>
        <th class="total-count-col header-col text-right">
          {{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.TOP_QUEUES.CURRENT_TOTAL' | translate }}
        </th>
        <th class="total-bar-col sr-only"></th>
        <th class="new-seven-days-col header-col text-right">
          {{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.TOP_QUEUES.NEW_PAST_7_DAYS' | translate }}
        </th>
        <th class="pending-count-col header-col text-right">
          {{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.TOP_QUEUES.PENDING' | translate }}
        </th>
        <th class="pending-percent-col header-col text-right">
          {{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.TOP_QUEUES.PENDING_PERCENT' | translate }}
        </th>
        <th class="oldest-pending-col header-col text-right">
          {{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.TOP_QUEUES.OLDEST_PENDING' | translate }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let queueDetails of queues.invalid">
        <td class="ellipsis-col" title="{{ queueDetails.name }}">
          {{ queueDetails.name }}
        </td>
        <td class="partial-data-msg" colspan="6">
          <i class="fas fa-info-circle"></i>
          <span>
            {{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.TOP_QUEUES.PARTIAL_DATA_MSG' | translate }}
          </span>
        </td>
      </tr>
      <tr *ngFor="let queueDetails of queues.valid">
        <td class="ellipsis-col" title="{{ queueDetails.name }}">
          {{ queueDetails.name }}
        </td>
        <td class="text-right">
          {{ queueDetails.totalCount | number | mcZeroToDash }}
        </td>
        <td class="mc-queue-indicator">
          <span
            [className]="isReviewQueue() ? 'rq-total-bar-indicator' : 'eq-total-bar-indicator'"
            [ngStyle]="{ 'width.%': calculateIndicatorWidth(queueDetails) }"
          >
          </span>
        </td>
        <td class="text-right">
          {{ queueDetails.newInLastSevenDays | number | mcZeroToDash }}
        </td>
        <td class="text-right">
          {{ queueDetails.pendingCount | number | mcZeroToDash }}
        </td>
        <td class="text-right">
          <span class="pending-percent">
            {{ pendingPercentageFormatted(queueDetails.percentageOfPendingMessages) }}
          </span>
          <span
            class="threshold-indicator-circle"
            [ngStyle]="{
              'background-color': pendingPercentageColor(queueDetails.percentageOfPendingMessages)
            }"
          >
          </span>
        </td>
        <td class="text-right">
          <span class="oldest-pending">
            {{ oldestPendingFormattedDuration(queueDetails.oldestPendingHours) }}
          </span>
          <span
            class="threshold-indicator-circle"
            [ngStyle]="{ 'background-color': oldestPendingColor(queueDetails.oldestPendingHours) }"
          >
          </span>
        </td>
      </tr>
      <tr class="totals-row">
        <td>
          {{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.TOP_QUEUES.TOTAL' | translate }}
        </td>
        <td class="text-right">
          {{ queues.totalCount | number }}
        </td>
        <td class="mc-queue-indicator"></td>
        <td class="text-right">
          {{ queues.newInLastSevenDays | number }}
        </td>
        <td class="text-right">
          {{ queues.pendingCount | number }}
        </td>
        <td class="total-pending-percent text-right">{{ queues.pendingPercentage }}%</td>
        <td></td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #nodata>
  <mc-empty-results
    [keyTitle]="
      isReviewQueue()
        ? '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.TOP_QUEUES.NO_DATA.NO_REVIEW_QUEUES'
        : '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.TOP_QUEUES.NO_DATA.NO_ESCALATION_QUEUES'
    "
    class="no-data"
  >
    <mc-empty-body>
      <div class="panel-half-padding-bottom">
        {{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.TOP_QUEUES.NO_DATA.PREFIX_TEXT' | translate }}
        <span class="link" (click)="openTab()">
          <ng-container
            *ngIf="isReviewQueue(); then reviewQueueLinkText; else escalationQueueLinkText"
          ></ng-container>
        </span>
        <ng-container
          *ngIf="isReviewQueue(); then reviewQueueAction; else escalationQueueAction"
        ></ng-container>
      </div>
    </mc-empty-body>
  </mc-empty-results>
</ng-template>

<ng-template #reviewQueueLinkText>{{
  '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.TOP_QUEUES.NO_DATA.REVIEW_QUEUE_LINK_TEXT' | translate
}}</ng-template>
<ng-template #escalationQueueLinkText>{{
  '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.TOP_QUEUES.NO_DATA.ESCALATION_QUEUE_LINK_TEXT' | translate
}}</ng-template>

<ng-template #reviewQueueAction>{{
  '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.TOP_QUEUES.NO_DATA.REVIEW_QUEUE_ACTION_TEXT' | translate
}}</ng-template>
<ng-template #escalationQueueAction>{{
  '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.TOP_QUEUES.NO_DATA.ESCALATION_QUEUE_ACTION_TEXT' | translate
}}</ng-template>
