"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BiPolicySideBarComponent = void 0;
const core_1 = require("@angular/core");
class BiPolicySideBarComponent {
    constructor() {
        this.deletePolicy = new core_1.EventEmitter();
        this.editPolicy = new core_1.EventEmitter();
        this.duplicatePolicy = new core_1.EventEmitter();
        this.close = new core_1.EventEmitter();
    }
}
exports.BiPolicySideBarComponent = BiPolicySideBarComponent;
