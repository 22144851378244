"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SummaryByReasonService = void 0;
const operators_1 = require("rxjs/operators");
class SummaryByReasonService {
    constructor(http) {
        this.http = http;
    }
    getSummaryDetail() {
        return this.http.post('/api/gateway/get-hold-summary-list', undefined).pipe(operators_1.map((response) => {
            return response.all;
        }));
    }
}
exports.SummaryByReasonService = SummaryByReasonService;
