"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OktaEventFormNames = exports.OktaEventTypes = void 0;
var OktaEventTypes;
(function (OktaEventTypes) {
    OktaEventTypes["DATA_LEAK_PREVENTION"] = "dlp";
    OktaEventTypes["ATTACHMENT_PROTECT"] = "ttp_attachment";
    OktaEventTypes["URL_PROTECT"] = "ttp_url";
})(OktaEventTypes = exports.OktaEventTypes || (exports.OktaEventTypes = {}));
exports.OktaEventFormNames = {
    [OktaEventTypes.DATA_LEAK_PREVENTION]: 'dataLeakPrevention',
    [OktaEventTypes.ATTACHMENT_PROTECT]: 'attachmentProtect',
    [OktaEventTypes.URL_PROTECT]: 'urlProtect'
};
