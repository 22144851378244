"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DomainsSettingsComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const table_store_1 = require("@mimecast-ui/table-store");
const chameleon_domain_validator_1 = require("../../../validators/chameleon-domain/chameleon-domain.validator");
const max_length_validator_1 = require("../../../validators/max-length/max-length.validator");
const maximum_entries_per_line_validator_1 = require("../../../validators/maximum-entries-per-line/maximum-entries-per-line.validator");
const one_domain_per_line_validator_1 = require("../../../validators/one-domain-per-line/one-domain-per-line.validator");
const TABLE_ID = 'monitoredExternalDomain';
class DomainsSettingsComponent {
    constructor(fb, tableStore) {
        this.fb = fb;
        this.tableStore = tableStore;
        this.showValidations = new core_1.EventEmitter();
        this.cancel = new core_1.EventEmitter();
        this.setupForm();
        this.setupSubscriptions();
    }
    setupForm() {
        this.form = this.fb.group({
            domains: [
                '',
                [forms_1.Validators.required, maximum_entries_per_line_validator_1.MaxEntriesPerLineValidator(1000), one_domain_per_line_validator_1.OneDomainPerLineValidator()]
            ],
            comment: ['', [max_length_validator_1.MaxLengthValidator(50)]]
        });
    }
    setupSubscriptions() {
        this.tableStoreDataSubscription = this.tableStore
            .select(table_store_1.tableReducer.getTableData(TABLE_ID))
            .subscribe(value => (this.tableData = value));
    }
    ngOnDestroy() {
        this.tableStoreDataSubscription.unsubscribe();
    }
    onNext() {
        this.showValidations.emit(this.getMonitoredDomainFormValues(this.form.value));
    }
    getMonitoredDomainFormValues(formValue) {
        const values = {
            validDomains: [],
            inValidDomains: [],
            comment: formValue.comment
        };
        one_domain_per_line_validator_1.getDomainsList(formValue.domains.trim()).forEach(domain => {
            if (chameleon_domain_validator_1.isValidDomain(domain)) {
                values.validDomains.push(domain);
            }
            else {
                values.inValidDomains.push(domain);
            }
        });
        return values;
    }
}
exports.DomainsSettingsComponent = DomainsSettingsComponent;
