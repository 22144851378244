"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeviceGroupDeleteConfirmationComponent = void 0;
const reducers = require("../../../reducers/");
const device_groups_sidebar_actions_1 = require("../../../actions/device-groups-sidebar.actions");
class DeviceGroupDeleteConfirmationComponent {
    constructor(store$) {
        this.store$ = store$;
    }
    ngOnInit() {
        this.deviceGroup$ = this.store$.select(reducers.getDeviceGroup);
        this.isDeleting$ = this.store$.select(reducers.getIsDeviceGroupDeleting);
    }
    onDelete(deviceGroup) {
        this.store$.dispatch(new device_groups_sidebar_actions_1.SwgDeviceGroupDelete(deviceGroup.id));
    }
    onCancel() {
        this.store$.dispatch(new device_groups_sidebar_actions_1.SwgDeviceGroupDeleteConfirmationModalClose());
    }
}
exports.DeviceGroupDeleteConfirmationComponent = DeviceGroupDeleteConfirmationComponent;
