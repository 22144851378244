<ng-container *ngIf="(messageInfo$ | async) as messageInfo">
  <mc-layout-aside-tabs
    keyTitle="$I18N_MESSAGE_CENTER_MESSAGE_INFO.ASIDE_TITLE"
    showClose="true"
    [hasTabs]="showInfoInTabs(messageInfo)"
    (closeAside)="closeAside()"
    *ngIf="(selectedMessage$ | async) as selectedMessage"
    [isLoading]="isSidePanelLoading$ | async"
  >
   <mc-extra-container *ngIf="isValidMessage(messageInfo)">
      <mc-toolbar
        [messageInfo]="messageInfo"
        [selectedEmail]="selectedEmail$ | async"
        [selectedDeliveredMessage]="selectedDeliveryMessage$ | async"
        [paginatorData]="paginatorData$ | async"
        [isLoading]="isSidePanelLoading$ | async"
        (earlyBounce)="earlyBounce()"
        (selectRow)="selectRow($event)"
        (selectEmail)="selectEmail($event)"
        (recalculateDeliveryRoute)="recalculateDeliveryRoute()"
        (release)="release()"
        (releaseToSandbox)="releaseToSandbox()"
        (permitForRecipient)="permitForRecipient(messageInfo)"
        (reject)="reject()"
        (rejectNotifySender)="rejectNotifySender()"
        (rejectWithNarrative)="rejectWithNarrative()"
        (blockForRecipient)="blockForRecipient(messageInfo)"
        (printRecipient)="printRecipient(messageInfo)"
        (retry)="retry()"
        (rejectQueued)="rejectQueued()"
        (forward)="forward()"
        (reportAs)="reportAs($event)"
      >
      </mc-toolbar>
    </mc-extra-container>

    <mc-tab-group
      *ngIf="showInfoInTabs(messageInfo); else showInfoNotInTabs"
      themeLight="true"
      resetActiveTabToFirst="true"
    >
      <mc-tab
        name="$I18N_MESSAGE_CENTER_MESSAGE_INFO.TAB_LABEL_DETAILS"
        *ngIf="showDetails(messageInfo)"
      >
        <mc-details
          *ngIf="(trackingAdminPreferences$ | async) as adminPrefs"
          [queueInfo]="messageInfo.queueInfo"
          [messageStatus]="messageInfo.status"
          [selectedMessageInfo]="selectedMessage?.messageInfo"
          [notices]="messageInfo.queueInfo.notices"
          [spamInfo]="messageInfo.spamInfo"
          [notificationDetailsDismissForever]="adminPrefs?.notificationDetailsDismissForever"
          (dismissForever)="dismissForever(adminPrefs)"
        >
        </mc-details>
      </mc-tab>
      <mc-tab name="$I18N_MESSAGE_CENTER_MESSAGE_INFO.TAB_LABEL_MESSAGE"
       *ngIf="!canShowDetailsTabOnly">
        <mc-message
          #message
          [contentAdministratorView]="(userAccount$ | async)?.contentAdministratorDefaultView"
          [forceLoadContent]="forceLoadContent$ | async"
          [selectedMessageInfo]="selectedMessage?.messageInfo"
          [archived]="messageInfo.status === 'archived'"
          [messageStatus]="messageInfo.status"
          [spamInfo]="messageInfo.spamInfo"
          [retentionInfo]="messageInfo.retentionInfo"
          (loadContentRequest)="loadContentRequest(messageInfo.id)"
          (downloadFile)="downloadFile($event)"
        >
        </mc-message>
      </mc-tab>

      <ng-container *mcCapabilities="'Temporary.MessageCenter.SpamScore || Temporary.MessageCenter.SpamScore.Beta'">
        <mc-tab name="$I18N_MESSAGE_CENTER_MESSAGE_INFO.TAB_LABEL_ANALYSIS" *ngIf="showAnalysisTab(messageInfo)">
          <mc-threat-information-tab
            [messageType] = "messageType"
            [spamInfo]="messageInfo.spamInfo"
            [deliveredMessage]="messageInfo.deliveredMessage"
            [messageInfoRoute] = "messageInfo.recipientInfo?.messageInfo?.route"
          >
          </mc-threat-information-tab>
        </mc-tab>
      </ng-container>

      <mc-tab name="$I18N_MESSAGE_CENTER_MESSAGE_INFO.TAB_LABEL_HEADER" *ngIf="!canShowDetailsTabOnly">
        <mc-header
          [header]="selectedMessage?.messageInfo?.transmissionInfo"
          (copiedToClipboard)="copiedToClipboard()"
        >
        </mc-header>
      </mc-tab>
      <mc-tab
        name="$I18N_MESSAGE_CENTER_MESSAGE_INFO.TAB_LABEL_TRANSMISSION_DATA"
        *ngIf="!canShowDetailsTabOnly">
        <mc-transmission-data
          [messageStatus]="messageInfo.status"
          [recipientInfo]="messageInfo.recipientInfo"
          [deliveredMessage]="messageInfo.deliveredMessage"
          [selectedEmail]="selectedEmail$ | async"
          [selectedDeliveredMessage]="selectedMessage"
          [queueInfo]="messageInfo.queueInfo"
          (selectEmail)="selectEmail($event)"
          (compareViews)="compareViews($event)"
        >
        </mc-transmission-data>
      </mc-tab>
      <mc-tab
        name="$I18N_MESSAGE_CENTER_MESSAGE_INFO.TAB_LABEL_POLICIES"
        *ngIf="showPolicyTab(messageInfo) && !canShowDetailsTabOnly">
        <mc-policies
          [messageInfo]="selectedMessage.messageInfo"
          [policyInfo]="(selectedDeliveryMessage$ | async)?.policyInfo"
          [archived]="messageInfo.status === 'archived'"
        >
        </mc-policies>
      </mc-tab>
    </mc-tab-group>
  </mc-layout-aside-tabs>

  <ng-template #showInfoNotInTabs>
    <div *ngIf="isValidMessage(messageInfo); else notValidmsgInfo">
      <mc-details
        class="mc-details-no-tabs-overflow"
        *ngIf="(trackingAdminPreferences$ | async) as adminPrefs"
        [queueInfo]="messageInfo.queueInfo"
        [messageStatus]="messageInfo.status"
        [selectedMessageInfo]="(selectedMessage$ | async)?.messageInfo"
        [spamInfo]="messageInfo.spamInfo"
        [notificationDetailsDismissForever]="adminPrefs?.notificationDetailsDismissForever"
        (dismissForever)="dismissForever(adminPrefs)"
      >
      </mc-details>
    </div>
  </ng-template>

  <ng-template #notValidmsgInfo>
      <div class="mc-aside-error-container">
          <i class="mc-icon-warning-outline"></i>
          <div>
              {{ '$I18N_MESSAGE_CENTER_MESSAGE_INFO.LABEL_ERROR_NODATA' | translate}}
          </div>
      </div>
    </ng-template>
</ng-container>
