"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiIntegrationWildfireComponent = void 0;
const reducers = require("app-new/api-applications/reducers");
const actions = require("app-new/api-applications/actions");
const operators_1 = require("rxjs/operators");
class ApiIntegrationWildfireComponent {
    constructor(store, modalService, translateService) {
        this.store = store;
        this.modalService = modalService;
        this.translateService = translateService;
        this.cancelModalTitle = '$I18N_API_APPLICATIONS.MODALS.CANCEL_MODAL.CONTENT.INITIATE_MODE';
    }
    ngOnInit() {
        this.wildfireIntegration$ = this.store.select(reducers.getIntegrationWildfireIntegration);
        this.wildfirePrerequisitesError$ = this.store.select(reducers.getIntegrationWildfireErrorPrerequisite);
        this.hasCreatedIntegration$ = this.store.select(reducers.hasIntegrationWildfireCreatedIntegration);
        this.isApiAndRegionVerified$ = this.store.select(reducers.isIntegrationWildfireApiAndRegionVerified);
        this.isVerifyApiKeyButtonDisabled$ = this.store.select(reducers.isIntegrationWildfireVerifyApiKeyButtonDisabled);
        this.hasCreatedIntegration$.subscribe(hasIntegration => {
            if (hasIntegration === true) {
                this.cancelModalTitle = '$I18N_API_APPLICATIONS.MODALS.CANCEL_MODAL.CONTENT.EDIT_MODE';
            }
        });
    }
    createWildfireIntegration() {
        this.store.dispatch(new actions.CreateWildfireIntegrationAction());
    }
    updateWildfireIntegration() {
        this.store.dispatch(new actions.UpdateWildfireIntegrationAction());
    }
    switchWildfireIntegrationStatus(status) {
        this.store.dispatch(new actions.SwitchWildfireIntegrationStatusAction(status));
    }
    switchAttachmentIndicator(enabledIndicator) {
        this.store.dispatch(new actions.SwitchWildfireAttachmentIndicatorAction(enabledIndicator));
    }
    switchMaliciousAttachmentIndicator(enabledIndicator) {
        this.store.dispatch(new actions.SwitchWildfireMaliciousAttachmentIndicatorAction(enabledIndicator));
    }
    verifyApiAndRegion(apiAndRegionConfig) {
        this.store.dispatch(new actions.StartVerifyApiAndRegionAction(apiAndRegionConfig));
    }
    switchRemediation(remediationRequired) {
        this.store.dispatch(new actions.SwitchRemediationAction(remediationRequired));
    }
    switchAcceptSharingInfo(acceptSharingInfo) {
        this.store.dispatch(new actions.SwitchWildfireAcceptSharingInfoAction(acceptSharingInfo));
    }
    verifyAndAddEmail(email) {
        this.store.dispatch(new actions.VerifyAndAddEmailWildfireIntegrationNotificationsAction(email));
    }
    deleteEmail(email) {
        this.store.dispatch(new actions.DeleteEmailWildfireIntegrationNotificationsAction(email));
    }
    addGroup(selectedGroup) {
        this.store.dispatch(new actions.AddGroupWildfireIntegrationNotificationsAction(selectedGroup));
    }
    deleteGroup(selectedGroup) {
        this.store.dispatch(new actions.DeleteGroupWildfireIntegrationNotificationsAction(selectedGroup));
    }
    changeRegion(newRegion) {
        this.store.dispatch(new actions.ChangeWildfireRegionAction(newRegion));
    }
    cancel() {
        this.modalService
            .open({
            content: this.cancelModalTitle,
            hideHeader: true,
            buttons: [
                {
                    title: this.translateService.instant('$I18N_API_APPLICATIONS.MODALS.CANCEL_MODAL.BUTTONS.NO'),
                    type: 'default',
                    id: 'no'
                },
                {
                    title: this.translateService.instant('$I18N_API_APPLICATIONS.MODALS.CANCEL_MODAL.BUTTONS.YES'),
                    type: 'primary',
                    id: 'yes'
                }
            ]
        })
            .pipe(operators_1.first(), operators_1.filter((result) => result === 'yes'), operators_1.tap(() => this.store.dispatch(new actions.CancelWildfireIntegrationWidgetAction())))
            .subscribe();
    }
}
exports.ApiIntegrationWildfireComponent = ApiIntegrationWildfireComponent;
