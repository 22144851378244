"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewPolicyModule = void 0;
const view_policy_component_1 = require("./view-policy/view-policy.component");
const view_policy_details_component_1 = require("./view-policy-details/view-policy-details.component");
const simple_detail_component_1 = require("./view-policy-details/simple-detail/simple-detail.component");
const DECLARATIONS = [view_policy_component_1.ViewPolicyComponent, view_policy_details_component_1.ViewPolicyDetailsComponent, simple_detail_component_1.SimpleDetailComponent];
class ViewPolicyModule {
}
exports.ViewPolicyModule = ViewPolicyModule;
