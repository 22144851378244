"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterSearchOptions = exports.columns = void 0;
exports.columns = ['description', 'host', 'action'];
exports.filterSearchOptions = [
    {
        label: '$I18N_SWG_EXCEPTIONS_LIST.TABLE.SEARCH.ALL',
        value: 'all'
    },
    {
        label: '$I18N_SWG_EXCEPTIONS_LIST.TABLE.SEARCH.NAME',
        value: 'description'
    },
    {
        label: '$I18N_SWG_EXCEPTIONS_LIST.TABLE.SEARCH.DOMAIN',
        value: 'host'
    },
    {
        label: '$I18N_SWG_EXCEPTIONS_LIST.TABLE.SEARCH.IP',
        value: 'ipAddress'
    }
];
