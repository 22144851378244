"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgCfpScansByFileTypesFailureAction = exports.SwgCfpScansByFileTypesSuccessAction = exports.SwgCfpScansByFileTypesAction = exports.SwgCfpVerdictsByDateFailureAction = exports.SwgCfpVerdictsByDateSuccessAction = exports.SwgCfpVerdictsByDateAction = exports.SwgCfpVerdictsByAppFailureAction = exports.SwgCfpVerdictsByAppSuccessAction = exports.SwgCfpVerdictsByAppAction = exports.SwgCfpDataEnum = void 0;
var SwgCfpDataEnum;
(function (SwgCfpDataEnum) {
    SwgCfpDataEnum["VERDICTS_BY_APP"] = "[SwgCfpData] Verdicts by App";
    SwgCfpDataEnum["VERDICTS_BY_APP_SUCCESS"] = "[SwgCfpData] Verdicts by App Success";
    SwgCfpDataEnum["VERDICTS_BY_APP_FAILURE"] = "[SwgCfpData] Verdicts by App Failure";
    SwgCfpDataEnum["VERDICTS_BY_DATE"] = "[SwgCfpData] Verdicts by Date";
    SwgCfpDataEnum["VERDICTS_BY_DATE_SUCCESS"] = "[SwgCfpData] Verdicts by Date Success";
    SwgCfpDataEnum["VERDICTS_BY_DATE_FAILURE"] = "[SwgCfpData] Verdicts by Date Failure";
    SwgCfpDataEnum["SCANS_BY_FILETYPES"] = "[SwgCfpData] Scans by FileTypes";
    SwgCfpDataEnum["SCANS_BY_FILETYPES_SUCCESS"] = "[SwgCfpData] Scans by FileTypes Success";
    SwgCfpDataEnum["SCANS_BY_FILETYPES_FAILURE"] = "[SwgCfpData] Scans by FileTypes Failure";
})(SwgCfpDataEnum = exports.SwgCfpDataEnum || (exports.SwgCfpDataEnum = {}));
class SwgCfpVerdictsByAppAction {
    constructor(dateRange) {
        this.dateRange = dateRange;
        this.type = SwgCfpDataEnum.VERDICTS_BY_APP;
    }
}
exports.SwgCfpVerdictsByAppAction = SwgCfpVerdictsByAppAction;
class SwgCfpVerdictsByAppSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgCfpDataEnum.VERDICTS_BY_APP_SUCCESS;
    }
}
exports.SwgCfpVerdictsByAppSuccessAction = SwgCfpVerdictsByAppSuccessAction;
class SwgCfpVerdictsByAppFailureAction {
    constructor() {
        this.type = SwgCfpDataEnum.VERDICTS_BY_APP_FAILURE;
    }
}
exports.SwgCfpVerdictsByAppFailureAction = SwgCfpVerdictsByAppFailureAction;
class SwgCfpVerdictsByDateAction {
    constructor(dateRange) {
        this.dateRange = dateRange;
        this.type = SwgCfpDataEnum.VERDICTS_BY_DATE;
    }
}
exports.SwgCfpVerdictsByDateAction = SwgCfpVerdictsByDateAction;
class SwgCfpVerdictsByDateSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgCfpDataEnum.VERDICTS_BY_DATE_SUCCESS;
    }
}
exports.SwgCfpVerdictsByDateSuccessAction = SwgCfpVerdictsByDateSuccessAction;
class SwgCfpVerdictsByDateFailureAction {
    constructor() {
        this.type = SwgCfpDataEnum.VERDICTS_BY_DATE_FAILURE;
    }
}
exports.SwgCfpVerdictsByDateFailureAction = SwgCfpVerdictsByDateFailureAction;
class SwgCfpScansByFileTypesAction {
    constructor(dateRange) {
        this.dateRange = dateRange;
        this.type = SwgCfpDataEnum.SCANS_BY_FILETYPES;
    }
}
exports.SwgCfpScansByFileTypesAction = SwgCfpScansByFileTypesAction;
class SwgCfpScansByFileTypesSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgCfpDataEnum.SCANS_BY_FILETYPES_SUCCESS;
    }
}
exports.SwgCfpScansByFileTypesSuccessAction = SwgCfpScansByFileTypesSuccessAction;
class SwgCfpScansByFileTypesFailureAction {
    constructor() {
        this.type = SwgCfpDataEnum.SCANS_BY_FILETYPES_FAILURE;
    }
}
exports.SwgCfpScansByFileTypesFailureAction = SwgCfpScansByFileTypesFailureAction;
