"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuditApiService = void 0;
const i0 = require("@angular/core");
const i1 = require("@angular/common/http");
class AuditApiService {
    constructor(http) {
        this.http = http;
    }
    logAudit(auditLog) {
        return this.http.post('/api/audit/log-event', {
            eventInfo: auditLog.eventInfo,
            auditType: auditLog.auditType
        });
    }
}
exports.AuditApiService = AuditApiService;
AuditApiService.ɵprov = i0.ɵɵdefineInjectable({ factory: function AuditApiService_Factory() { return new AuditApiService(i0.ɵɵinject(i1.HttpClient)); }, token: AuditApiService, providedIn: "root" });
