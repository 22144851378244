"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgDeviceGroupsListImplService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const table_store_1 = require("@mimecast-ui/table-store");
class SwgDeviceGroupsListImplService {
    constructor(deviceGroupsListApiService) {
        this.deviceGroupsListApiService = deviceGroupsListApiService;
    }
    getData(payload, pagination = table_store_1.defaultPaginationOption) {
        const config = Object.assign(Object.assign({}, payload), { meta: { pagination } });
        return this.deviceGroupsListApiService.getDeviceGroupsList(config).pipe(operators_1.map((response) => {
            return {
                hasErrors: response.hasErrors,
                data: response.first.deviceGroups,
                failures: response.fail,
                meta: response.meta
            };
        }), operators_1.catchError((response) => {
            const RETURN_DATA = {
                hasErrors: response.hasErrors,
                data: response.first.logs,
                failures: response.fail,
                meta: response.meta
            };
            return rxjs_1.of(RETURN_DATA);
        }));
    }
    filter(query, pagination) {
        return this.getData(query, pagination);
    }
    openItem() {
        return rxjs_1.of([]);
    }
}
exports.SwgDeviceGroupsListImplService = SwgDeviceGroupsListImplService;
