"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiemChannelApiService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
class SiemChannelApiService {
    constructor(http) {
        this.http = http;
    }
    deleteChannel(id) {
        return this.http
            .post('/api/siem/delete-channel', { id })
            .pipe(operators_1.map(res => res.first), operators_1.catchError(error => rxjs_1.throwError(error)));
    }
    getProductsFromMetadataJSON() {
        return this.http
            .get('/administration/assets/static/products_definition.json')
            .pipe(operators_1.map((res) => res));
    }
    createChannel(siemChannel) {
        return this.http.post('/api/siem/create-channel', siemChannel).pipe(operators_1.map(res => res.first));
    }
    updateChannel(siemChannel) {
        return this.http.post('/api/siem/update-channel', siemChannel).pipe(operators_1.map(res => res.first));
    }
}
exports.SiemChannelApiService = SiemChannelApiService;
