"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.getServerConnection = exports.isLoading = exports.getOutboundAdministratorGroup = exports.getJournalAdministratorGroup = exports.getAdministratorGroups = exports.getEditDefinitionId = exports.reducer = exports.initialState = void 0;
const create_definition_actions_1 = require("../actions/create-definition.actions");
const ɵ0 = {
    id: '',
    action: '',
    description: '',
    notifyGroup: undefined,
    outboundNotifyGroup: undefined,
    journalNotifyGroup: undefined,
    checkInbound: true,
    internalForward: true,
    sandboxEncryptionPortalEnabled: false,
    preEmptiveFailureAction: '',
    ignoreSignedMessages: false,
    safeDocFormat: '',
    safeSpreadsheetFormat: '',
    firstSheetOnly: true,
    notifyOnMalicious: false,
    notifyExternalRecipient: false,
    notifyInternalSender: false,
    notifyInternalRecipient: false,
    notifyExternalSender: false,
    checkOutbound: false,
    outboundFallbackRemediationAction: 'none',
    outboundRemediationAction: 'none',
    outboundFallbackAction: 'hold',
    outboundAction: 'hold',
    outboundNotifyOnMalicious: false,
    journalNotifyOnMalicious: false,
    outboundNotifyInternalSender: false,
    checkJournal: false,
    journalFallbackRemediationAction: 'none',
    journalRemediationAction: 'none',
    journalNotifyInternalSender: false,
    journalNotifyInternalRecipient: false
};
exports.ɵ0 = ɵ0;
exports.initialState = {
    isLoading: false,
    data: ɵ0,
    serverDetails: []
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case create_definition_actions_1.ApdefinitionCreateTypesEnum.NAVIGATE_TO_ATTACHMENT_PROTECTION_CREATE_DEFINITION:
            return Object.assign({}, exports.initialState);
        case create_definition_actions_1.CREATE_DEFINITION_SAVE_AND_EXIT:
        case create_definition_actions_1.CREATE_DEFINITION_SAVE:
            return Object.assign(Object.assign({}, state), { data: Object.assign(Object.assign({}, action.payload), { id: state.data.id, notifyGroup: state.data.notifyGroup, outboundNotifyGroup: state.data.outboundNotifyGroup, journalNotifyGroup: state.data.journalNotifyGroup }) });
        case create_definition_actions_1.LOAD_ID_DEFINITION_DETAILS:
            return Object.assign(Object.assign({}, state), { data: Object.assign(Object.assign({}, state.data), { id: action.payload }) });
        case create_definition_actions_1.GET_SERVER_CONNECTION_SUCCESS:
            return Object.assign(Object.assign({}, state), { serverDetails: action.payload });
        case create_definition_actions_1.ApDefinitionCreateTypesEnum.ADD_ADMINISTRATOR_GROUP:
            return Object.assign(Object.assign({}, state), { data: Object.assign(Object.assign({}, state.data), { notifyGroup: action.payload }) });
        case create_definition_actions_1.ApDefinitionCreateTypesEnum.REMOVE_ADMINISTRATOR_GROUP:
            return Object.assign(Object.assign({}, state), { data: Object.assign(Object.assign({}, state.data), { notifyGroup: undefined }) });
        case create_definition_actions_1.ApDefinitionCreateTypesEnum.ADD_ADMINISTRATOR_OUTBOUND_GROUP:
            return Object.assign(Object.assign({}, state), { data: Object.assign(Object.assign({}, state.data), { outboundNotifyGroup: action.payload }) });
        case create_definition_actions_1.ApDefinitionCreateTypesEnum.REMOVE_ADMINISTRATOR_OUTBOUND_GROUP:
            return Object.assign(Object.assign({}, state), { data: Object.assign(Object.assign({}, state.data), { outboundNotifyGroup: undefined }) });
        case create_definition_actions_1.ApDefinitionCreateTypesEnum.ADD_ADMINISTRATOR_JOURNAL_GROUP:
            return Object.assign(Object.assign({}, state), { data: Object.assign(Object.assign({}, state.data), { journalNotifyGroup: action.payload }) });
        case create_definition_actions_1.ApDefinitionCreateTypesEnum.REMOVE_JOURNAL_GROUP:
            return Object.assign(Object.assign({}, state), { data: Object.assign(Object.assign({}, state.data), { journalNotifyGroup: undefined }) });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getEditDefinitionId = (state) => state.data.id;
exports.getEditDefinitionId = getEditDefinitionId;
const getAdministratorGroups = (state) => state.data.notifyGroup;
exports.getAdministratorGroups = getAdministratorGroups;
const getJournalAdministratorGroup = (state) => state.data.journalNotifyGroup;
exports.getJournalAdministratorGroup = getJournalAdministratorGroup;
const getOutboundAdministratorGroup = (state) => state.data.outboundNotifyGroup;
exports.getOutboundAdministratorGroup = getOutboundAdministratorGroup;
const isLoading = (state) => state.isLoading;
exports.isLoading = isLoading;
const getServerConnection = (state) => state.serverDetails;
exports.getServerConnection = getServerConnection;
