<div class="modal-header">
  <h4 class="no-margin">{{'MODAL_KEEPALIVE_TITLE' | translate}}</h4>
</div>

<div class="modal-body">
  <span>{{'MODAL_KEEPALIVE_DESCRIPTION' | translate}}</span>
  <br/>
  <span>{{'MODAL_KEEPALIVE_TIME_LEFT' | translate}}</span>&nbsp;<span class="text-bold" data-test="timeLeft">{{ timeLeft$ | async }}</span>
</div>

<div class="modal-footer">
  <button (click)="cancel()" data-test="cancel" type="submit" class="btn btn-primary">{{'MODAL_KEEPALIVE_RESUME_BTN' | translate}}</button>
  <button (click)="logout()" data-test="logout" type="submit" class="btn btn-default">{{'MODAL_KEEPALIVE_LOGOUT_BTN' | translate}}</button>
</div>

