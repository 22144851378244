"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgPolicySummaryComponent = void 0;
const forms_1 = require("@angular/forms");
const operators_1 = require("rxjs/operators");
const policy_1 = require("app-new/swg/policies/models/policy");
const policy_actions_1 = require("../../actions/policy.actions");
const models_1 = require("../../../models/models");
class SwgPolicySummaryComponent {
    constructor(fb, store) {
        this.fb = fb;
        this.store = store;
        this.isSidebar = false;
        this.hasAppPolicyRemoveAllowEnabled = false;
        this.hasBrowserIsolationEnabled = false;
        this._blockedDomains = [];
        this._allowedDomains = [];
        this._protectedApplicationsList = [];
        this._blockedApplications = [];
        this._allowedApplicatins = [];
        this._blockedCategories = [];
        this._allowedCategories = [];
        this._enabled = true;
        this.swgPolicyType = policy_1.SwgPolicyType;
    }
    ngOnInit() {
        this._enabledForm = this.fb.group({
            status: [this._enabled, forms_1.Validators.required]
        });
        this._allCategories = this.webCategoryRulesSections
            // @ts-ignore
            .flatMap(x => x.rules)
            // @ts-ignore
            .map(x => {
            return { category: x.name, action: x.value };
        });
        this.update();
        this._enabledSubscription = this._enabledForm.valueChanges
            .pipe(operators_1.debounceTime(200))
            .subscribe(value => {
            this.setEnabledStatus(value['status']);
        });
    }
    setEnabledStatus(status) {
        this.store.dispatch(new policy_actions_1.SwgUpdatePolicyEnabledStatus(status));
    }
    ngOnChanges(changes) {
        const change = changes['policy'];
        if (change && !change.isFirstChange()) {
            this.update();
        }
    }
    update() {
        this._allowedDomains = [];
        this._blockedDomains = [];
        if (this.policy.domains) {
            for (const domain of this.policy.domains) {
                if (domain.action === policy_1.SwgUnScannableEnum.ALLOW) {
                    this._allowedDomains.push(domain.value);
                }
                if (domain.action === policy_1.SwgUnScannableEnum.BLOCK) {
                    this._blockedDomains.push(domain.value);
                }
            }
        }
        if (this.policy.policyDesc.policyType === policy_1.SwgPolicyType.WEB_CATEGORY_FILTERING) {
            const blockedCats = [
                ...this.policy.webCategoryOtherRules,
                ...this.policy.webCategorySecurityRules
            ];
            this._blockedCategories = [];
            this._allowedCategories = [];
            this._allCategories.forEach(x => {
                const blockedCat = blockedCats.find(blockedCategory => blockedCategory.category === x.category);
                if (blockedCat) {
                    // @ts-ignore
                    this._blockedCategories.push(x);
                }
                else {
                    // @ts-ignore
                    this._allowedCategories.push(x);
                }
            });
        }
        if (this.policy.policyDesc.policyType === policy_1.SwgPolicyType.WEB_ADVANCED_SECURITY) {
            this._protectedApplicationsList = this.policy.protectedApplications
                .map(app => {
                return this.protectedApplications.find((pApp) => app.category === pApp.code)
                    .name;
            })
                .sort((x, y) => (x.toLowerCase() > y.toLowerCase() ? 1 : -1));
        }
        if (this.policy.policyDesc.policyType === policy_1.SwgPolicyType.WEB_APPLICATION_CONTROL) {
            this._blockedApplications = [];
            this._allowedApplicatins = [];
            this.applications.forEach(app => {
                const foundApp = this.policy.webApplicationRules.find(x => x.applicationCode === app.code);
                if (!foundApp) {
                    return;
                }
                if (foundApp.action === models_1.ApplicationStatus.BLOCK) {
                    this._blockedApplications.push(app.name);
                }
                else {
                    this._allowedApplicatins.push(app.name);
                }
            });
            this._blockedApplications.sort((x, y) => (x.toLowerCase() > y.toLowerCase() ? 1 : -1));
            this._allowedApplicatins.sort((x, y) => (x.toLowerCase() > y.toLowerCase() ? 1 : -1));
        }
    }
    isBiPolicy() {
        return this.policy.policyDesc.policyType === policy_1.SwgPolicyType.BROWSER_ISOLATION;
    }
    showOverride() {
        return !!this.policy.affectedUsers.filter(target => target.condition === 'web_device' || target.condition === 'web_device_group').length;
    }
    ngOnDestroy() {
        this._enabledSubscription.unsubscribe();
    }
}
exports.SwgPolicySummaryComponent = SwgPolicySummaryComponent;
