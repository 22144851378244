"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDescription = exports.getName = exports.reducer = exports.initialState = void 0;
const actions = require("../../actions/policy-wizard/details-step.actions");
exports.initialState = {
    name: '',
    description: ''
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.SAVE_NAME:
            return Object.assign(Object.assign({}, state), { name: action.payload });
        case actions.SAVE_DESCRIPTION:
            return Object.assign(Object.assign({}, state), { description: action.payload });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getName = (state) => state.name;
exports.getName = getName;
const getDescription = (state) => state.description;
exports.getDescription = getDescription;
