"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MSA_AGENT_VERSION = exports.DEVICE = void 0;
var DEVICE;
(function (DEVICE) {
    DEVICE["WINDOWS_SERVER"] = "server";
    DEVICE["WINDOWS"] = "win";
    DEVICE["MAC"] = "mac";
    DEVICE["IOS"] = "ios";
    DEVICE["ANDROID"] = "android";
    DEVICE["WINDOW8"] = "windows 8.1";
    DEVICE["WINDOWS_SERVER_2016_2019"] = "2016/2019";
    DEVICE["WINDOWS_SERVER_R2"] = "r2";
})(DEVICE = exports.DEVICE || (exports.DEVICE = {}));
var MSA_AGENT_VERSION;
(function (MSA_AGENT_VERSION) {
    MSA_AGENT_VERSION["WINDOWS"] = "1.4";
    MSA_AGENT_VERSION["MAC"] = "1.2";
})(MSA_AGENT_VERSION = exports.MSA_AGENT_VERSION || (exports.MSA_AGENT_VERSION = {}));
