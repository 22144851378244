"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BulkDeliveryExportAction = exports.BulkRecalculateRouteFailAction = exports.BulkRecalculateRouteSuccessAction = exports.BulkRecalculateRouteAction = exports.BulkDeliveryRetryEmailsFailAction = exports.BulkDeliveryRetryEmailsSuccessAction = exports.BulkDeliveryRetryEmailsAction = exports.BulkDeliveryEarlyBounceEmailsFailAction = exports.BulkDeliveryEarlyBounceEmailsSuccessAction = exports.BulkDeliveryEarlyBounceEmailsAction = exports.BulkDeliveryRejectEmailsFailAction = exports.BulkDeliveryRejectEmailsSuccessAction = exports.BulkDeliveryRejectEmailsAction = exports.BulkDeliveryFailAction = exports.BulkDeliverySuccessAction = exports.BulkDeliveryRequestAction = exports.BulkDeliveryPaginationAction = exports.BulkDeliverySelectRowAction = exports.BulkDeliveryAsidePaginationAction = exports.BulkDeliveryCloseDetailAction = exports.BulkDeliveryRefreshAction = exports.BULK_DELIVERY_EXPORT = exports.BULK_DELIVERY_EARLY_BOUNCE_EMAILS_FAIL = exports.BULK_DELIVERY_EARLY_BOUNCE_EMAILS_SUCCESS = exports.BULK_DELIVERY_EARLY_BOUNCE_EMAILS = exports.BULK_MESSAGE_RECALCULATE_ROUTE_FAIL = exports.BULK_MESSAGE_RECALCULATE_ROUTE_SUCCESS = exports.BULK_MESSAGE_RECALCULATE_ROUTE = exports.BULK_DELIVERY_RETRY_EMAILS_FAIL = exports.BULK_DELIVERY_RETRY_EMAILS_SUCCESS = exports.BULK_DELIVERY_RETRY_EMAILS = exports.BULK_DELIVERY_REJECT_EMAILS_FAIL = exports.BULK_DELIVERY_REJECT_EMAILS_SUCCESS = exports.BULK_DELIVERY_REJECT_EMAILS = exports.BULK_DELIVERY_FAIL = exports.BULK_DELIVERY_SUCCESS = exports.BULK_DELIVERY_REQUEST = exports.BULK_DELIVERY_PAGINATION = exports.BULK_DELIVERY_SELECT_ROW = exports.BULK_DELIVERY_ASIDE_PAGINATION = exports.BULK_DELIVERY_CLOSE_DETAIL = exports.BULK_DELIVERY_REFRESH = void 0;
exports.BULK_DELIVERY_REFRESH = '[Message Center /  Bulk Delivery]  Bulk Delivery Refresh';
exports.BULK_DELIVERY_CLOSE_DETAIL = '[Message Center /  Bulk Delivery]  Bulk Delivery Close Message Detail';
exports.BULK_DELIVERY_ASIDE_PAGINATION = '[Message Center /  Bulk Delivery]  Bulk Delivery Aside Pagination';
exports.BULK_DELIVERY_SELECT_ROW = '[Message Center /  Bulk Delivery]  Bulk Delivery Select Row';
exports.BULK_DELIVERY_PAGINATION = '[Message Center / Bulk Delivery] Bulk Delivery Pagination';
exports.BULK_DELIVERY_REQUEST = '[Message Center / Bulk Delivery] Request Get Bulk Delivery';
exports.BULK_DELIVERY_SUCCESS = '[Message Center / Bulk Delivery] Get Bulk Delivery Success';
exports.BULK_DELIVERY_FAIL = '[Message Center / Bulk Delivery] Get Bulk Delivery Fail';
exports.BULK_DELIVERY_REJECT_EMAILS = '[Message Center / Bulk Delivery] Reject Bulk Delivery Emails';
exports.BULK_DELIVERY_REJECT_EMAILS_SUCCESS = '[Message Center / Bulk Delivery] Reject Bulk Delivery Emails Success';
exports.BULK_DELIVERY_REJECT_EMAILS_FAIL = '[Message Center / Bulk Delivery] Reject Bulk Delivery Emails Fail';
exports.BULK_DELIVERY_RETRY_EMAILS = '[Message Center / Bulk Delivery] Retry Bulk Delivery Emails';
exports.BULK_DELIVERY_RETRY_EMAILS_SUCCESS = '[Message Center / Bulk Delivery] Retry Bulk Delivery Emails Success';
exports.BULK_DELIVERY_RETRY_EMAILS_FAIL = '[Message Center / Bulk Delivery] Retry Bulk Delivery Emails Fail';
exports.BULK_MESSAGE_RECALCULATE_ROUTE = '[Message Center / Bulk Delivery] Recalculate Delivery Route';
exports.BULK_MESSAGE_RECALCULATE_ROUTE_SUCCESS = '[Message Center / Bulk Delivery] Recalculate Delivery Route Success';
exports.BULK_MESSAGE_RECALCULATE_ROUTE_FAIL = '[Message Center / Bulk Delivery] Recalculate Delivery Route Fail';
exports.BULK_DELIVERY_EARLY_BOUNCE_EMAILS = '[Message Center / Bulk Delivery] Early Bounce Bulk Delivery Emails';
exports.BULK_DELIVERY_EARLY_BOUNCE_EMAILS_SUCCESS = '[Message Center / Bulk Delivery] Early Bounce Bulk Delivery Emails Success';
exports.BULK_DELIVERY_EARLY_BOUNCE_EMAILS_FAIL = '[Message Center / Bulk Delivery] Early Bounce Bulk Delivery Emails Fail';
exports.BULK_DELIVERY_EXPORT = '[Message Center / Bulk Delivery] Export Bulk Delivery Emails';
class BulkDeliveryRefreshAction {
    constructor() {
        this.type = exports.BULK_DELIVERY_REFRESH;
    }
}
exports.BulkDeliveryRefreshAction = BulkDeliveryRefreshAction;
class BulkDeliveryCloseDetailAction {
    constructor() {
        this.type = exports.BULK_DELIVERY_CLOSE_DETAIL;
    }
}
exports.BulkDeliveryCloseDetailAction = BulkDeliveryCloseDetailAction;
class BulkDeliveryAsidePaginationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.BULK_DELIVERY_ASIDE_PAGINATION;
    }
}
exports.BulkDeliveryAsidePaginationAction = BulkDeliveryAsidePaginationAction;
class BulkDeliverySelectRowAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.BULK_DELIVERY_SELECT_ROW;
    }
}
exports.BulkDeliverySelectRowAction = BulkDeliverySelectRowAction;
class BulkDeliveryPaginationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.BULK_DELIVERY_PAGINATION;
    }
}
exports.BulkDeliveryPaginationAction = BulkDeliveryPaginationAction;
class BulkDeliveryRequestAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.BULK_DELIVERY_REQUEST;
    }
}
exports.BulkDeliveryRequestAction = BulkDeliveryRequestAction;
class BulkDeliverySuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.BULK_DELIVERY_SUCCESS;
    }
}
exports.BulkDeliverySuccessAction = BulkDeliverySuccessAction;
class BulkDeliveryFailAction {
    constructor() {
        this.type = exports.BULK_DELIVERY_FAIL;
    }
}
exports.BulkDeliveryFailAction = BulkDeliveryFailAction;
class BulkDeliveryRejectEmailsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.BULK_DELIVERY_REJECT_EMAILS;
    }
}
exports.BulkDeliveryRejectEmailsAction = BulkDeliveryRejectEmailsAction;
class BulkDeliveryRejectEmailsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.BULK_DELIVERY_REJECT_EMAILS_SUCCESS;
    }
}
exports.BulkDeliveryRejectEmailsSuccessAction = BulkDeliveryRejectEmailsSuccessAction;
class BulkDeliveryRejectEmailsFailAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.BULK_DELIVERY_REJECT_EMAILS_FAIL;
    }
}
exports.BulkDeliveryRejectEmailsFailAction = BulkDeliveryRejectEmailsFailAction;
class BulkDeliveryEarlyBounceEmailsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.BULK_DELIVERY_EARLY_BOUNCE_EMAILS;
    }
}
exports.BulkDeliveryEarlyBounceEmailsAction = BulkDeliveryEarlyBounceEmailsAction;
class BulkDeliveryEarlyBounceEmailsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.BULK_DELIVERY_EARLY_BOUNCE_EMAILS_SUCCESS;
    }
}
exports.BulkDeliveryEarlyBounceEmailsSuccessAction = BulkDeliveryEarlyBounceEmailsSuccessAction;
class BulkDeliveryEarlyBounceEmailsFailAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.BULK_DELIVERY_EARLY_BOUNCE_EMAILS_FAIL;
    }
}
exports.BulkDeliveryEarlyBounceEmailsFailAction = BulkDeliveryEarlyBounceEmailsFailAction;
class BulkDeliveryRetryEmailsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.BULK_DELIVERY_RETRY_EMAILS;
    }
}
exports.BulkDeliveryRetryEmailsAction = BulkDeliveryRetryEmailsAction;
class BulkDeliveryRetryEmailsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.BULK_DELIVERY_RETRY_EMAILS_SUCCESS;
    }
}
exports.BulkDeliveryRetryEmailsSuccessAction = BulkDeliveryRetryEmailsSuccessAction;
class BulkDeliveryRetryEmailsFailAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.BULK_DELIVERY_RETRY_EMAILS_FAIL;
    }
}
exports.BulkDeliveryRetryEmailsFailAction = BulkDeliveryRetryEmailsFailAction;
class BulkRecalculateRouteAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.BULK_MESSAGE_RECALCULATE_ROUTE;
    }
}
exports.BulkRecalculateRouteAction = BulkRecalculateRouteAction;
class BulkRecalculateRouteSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.BULK_MESSAGE_RECALCULATE_ROUTE_SUCCESS;
    }
}
exports.BulkRecalculateRouteSuccessAction = BulkRecalculateRouteSuccessAction;
class BulkRecalculateRouteFailAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.BULK_MESSAGE_RECALCULATE_ROUTE_FAIL;
    }
}
exports.BulkRecalculateRouteFailAction = BulkRecalculateRouteFailAction;
class BulkDeliveryExportAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.BULK_DELIVERY_EXPORT;
    }
}
exports.BulkDeliveryExportAction = BulkDeliveryExportAction;
