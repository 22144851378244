"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.ɵ1 = exports.tzKeys = exports.filterSearchOptions = exports.columns = void 0;
const moment = require("moment-timezone");
exports.columns = ['description', 'ipRange', 'timeZone', 'action'];
exports.filterSearchOptions = [
    {
        label: '$I18N_SWG_LOCATIONS_LIST.TABLE.SEARCH_ALL',
        value: 'all'
    },
    {
        label: '$I18N_SWG_LOCATIONS_LIST.TABLE.LOCATION_NAME',
        value: 'description'
    },
    {
        label: '$I18N_SWG_LOCATIONS_LIST.TABLE.IP',
        value: 'ipAddress'
    }
];
const ɵ1 = timezone => ['Etc/GMT+0', 'Etc/GMT-0', 'EST', 'HST', 'MST'].indexOf(timezone) === -1, ɵ0 = timezone => ({
    label: timezone + ', (UTC ' + moment.tz(timezone).format('Z') + ')',
    value: timezone
});
exports.ɵ1 = ɵ1;
exports.ɵ0 = ɵ0;
exports.tzKeys = moment.tz
    .names()
    .filter(ɵ1)
    .map(ɵ0);
