<div class="mc-url-protection-policy-block">
  <mc-wizard-step-header label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.STEP_TITLE">
    <span class="mc-wizard-subtitle">
      {{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.STEP_SUBTITLE' | translate}}
    </span>
  </mc-wizard-step-header>
  <form class="form-horizontal" mcDefaultLabelClass="col-sm-3" mcDefaultControlContainerClass="col-sm-9"
    [formGroup]="urlProtectionPolicyAppliesToSettingsForm">
    <ng-container formArrayName="rules">
      <div *ngFor="let rule of rules.controls; index as i">
        <ng-container [formGroupName]="i">
          <div class="mc-applies-to-section-title">
            <span class="mc-title">
              {{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.SECTION_TITLE' | translate}}
              <ng-container *ngIf="rules.controls.length > 1">
                {{i + 1}}
              </ng-container>
            </span>
            <ng-container *ngIf="rules.controls.length > 1">
              <span class="mc-delete" (click)="removeRule(i)" data-unit-test="remove-rule-button">
                {{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.SECTION_DELETE' | translate}}
              </span>
            </ng-container>
          </div>
          <div>
            <mc-field
              label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.ADDRESSES_BASED_ON.LABEL"
              popoverPlacement="top" data-unit-test="addresses-based-on" data-cy-test="addresses-based-on"
              helpPopoverContent="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.TOOLTIPS.ADDRESSES_BASED_ON' | translate }}">
              <mc-select formControlName="addressesBasedOn" [options]="addressesBasedOnOptions" [useTranslation]="true">
              </mc-select>
            </mc-field>
            <mc-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.APPLIES.LABEL_FROM"
              data-unit-test="applies-from" data-cy-test="applies-from">
              <mc-select
                formControlName="appliesFrom"
                [options]="appliesFromToOptions.from"
                [useTranslation]="true"
                (selectChange)="onAppliesFromToSelectChange($event, i)"
              >
              </mc-select>
            </mc-field>
            <ng-container *ngIf="showAppliesAdditionalField(rule.get(fieldName.APPLIES_FROM), attributesFieldType)">
              <mc-field
                label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.WHERE_ATTRIBUTE"
                data-unit-test="where_attribute"
                data-cy-test="where_attribute_from"
                errorPrefix="$I18N_URL_PROTECTION_POLICY_WIZARD.VALIDATION.INVALID_FIELD">
                <mc-select
                  formControlName="fromWhereAttribute"
                  [useTranslation]="true"
                  [options]="whereAttributes">
                </mc-select>
              </mc-field>
              <mc-text-field
                label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.IS_EQUAL_TO"
                data-unit-test="applies-from-is-equal-to"
                data-cy-test="applies-from-is-equal-to"
                errorPrefix="$I18N_URL_PROTECTION_POLICY_WIZARD.VALIDATION.INVALID_FIELD"
                formControlName="appliesFromIsEqualTo"
              ></mc-text-field>
            </ng-container>
            <ng-container *ngIf="showAppliesAdditionalField(rule.get(fieldName.APPLIES_FROM), emailAddressFieldType)">
              <mc-text-field
                label=" "
                errorPrefix="$I18N_URL_PROTECTION_POLICY_WIZARD.VALIDATION.POLICY_EMAIL_NAME"
                placeholder="$I18N_URL_PROTECTION_POLICY_WIZARD.EMAIL_ADDRESS_PLACEHOLDER"
                data-unit-test="fromAddress"
                data-cy-test="fromAddress"
                formControlName="appliesFromAddress"
              ></mc-text-field>
            </ng-container>
            <ng-container *ngIf="showAppliesAdditionalField(rule.get(fieldName.APPLIES_FROM), domainFieldType)">
              <mc-text-field
                label=" "
                errorPrefix="$I18N_URL_PROTECTION_POLICY_WIZARD.VALIDATION.DOMAIN_FIELD"
                placeholder="$I18N_URL_PROTECTION_POLICY_WIZARD.DOMAIN_PLACEHOLDER"
                data-unit-test="fromDomain"
                data-cy-test="fromDomain"
                formControlName="appliesFromDomain"
              ></mc-text-field>
            </ng-container>
            <ng-container *ngIf="showAppliesAdditionalField(rule.get(fieldName.APPLIES_FROM), groupFieldType)">
              <div class="form-group mc-field">
                <label class="control-label col-sm-3"></label>
                <div class="col-sm-9">
                  <button (click)="openSidebar(fieldName.APPLIES_FROM_ADDRESS_GROUP, i)" type="button"
                    class="btn btn-secondary">{{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.SELECT_GROUP' | translate}}</button>
                  <ng-container *ngIf="rule.get(fieldName.APPLIES_FROM_ADDRESS_GROUP).value">
                    <div class="mc-selected-group" data-unit-test="from-selected-group">
                      <div>{{ rule.get(fieldName.APPLIES_FROM_ADDRESS_GROUP).value.description }}
                      </div>
                      <div class="mc-delete-group">
                        <i class="far fa-trash-alt" aria-hidden="true"
                          (click)="onDeleteGroup(fieldName.APPLIES_FROM_ADDRESS_GROUP, i)"></i>
                      </div>
                    </div>
                    <hr class="mc-group-separator" />
                  </ng-container>
                </div>
              </div>
            </ng-container>
            <mc-text-field *ngIf="showDependecy()"
              label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.DEPENDENCY"
              placeholder="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.DEPENDENCY_PLACEHOLDER"
              data-unit-test="applies-from-dependency" formControlName="appliesFromDependency"></mc-text-field>
            <mc-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.APPLIES.LABEL_TO"
              data-unit-test="applies-to">
              <mc-select
                formControlName="appliesTo"
                [options]="appliesFromToOptions.to"
                [useTranslation]="true"
                (selectChange)="onAppliesFromToSelectChange($event, i, false)"
              >
              </mc-select>
            </mc-field>
            <ng-container *ngIf="showAppliesAdditionalField(rule.get(fieldName.APPLIES_TO), emailAddressFieldType)">
              <mc-text-field
                label=" "
                errorPrefix="$I18N_URL_PROTECTION_POLICY_WIZARD.VALIDATION.POLICY_EMAIL_NAME"
                placeholder="$I18N_URL_PROTECTION_POLICY_WIZARD.EMAIL_ADDRESS_PLACEHOLDER"
                data-unit-test="toAddress"
                data-cy-test="toAddress"
                formControlName="appliesToAddress"
              ></mc-text-field>
            </ng-container>
            <ng-container *ngIf="showAppliesAdditionalField(rule.get(fieldName.APPLIES_TO), domainFieldType)">
              <mc-text-field
                label=" "
                errorPrefix="$I18N_URL_PROTECTION_POLICY_WIZARD.VALIDATION.DOMAIN_FIELD"
                placeholder="$I18N_URL_PROTECTION_POLICY_WIZARD.DOMAIN_PLACEHOLDER"
                data-unit-test="toDomain"
                data-cy-test="toDomain"
                formControlName="appliesToDomain"
              ></mc-text-field>
            </ng-container>
            <ng-container *ngIf="showAppliesAdditionalField(rule.get(fieldName.APPLIES_TO), groupFieldType)">
              <div class="form-group mc-field">
                <label class="control-label col-sm-3"></label>
                <div class="col-sm-9">
                  <button
                    (click)="openSidebar(fieldName.APPLIES_TO_ADDRESS_GROUP, i)"
                    type="button"
                    class="btn btn-secondary">
                    {{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.SELECT_GROUP' | translate}}
                  </button>
                  <ng-container *ngIf="rule.get(fieldName.APPLIES_TO_ADDRESS_GROUP).value">
                    <div class="mc-selected-group" data-unit-test="to-selected-group">
                      <div>{{ rule.get(fieldName.APPLIES_TO_ADDRESS_GROUP).value.description }}
                      </div>
                      <div class="mc-delete-group">
                        <i class="far fa-trash-alt" aria-hidden="true"
                          (click)="onDeleteGroup(fieldName.APPLIES_TO_ADDRESS_GROUP, i)"></i>
                      </div>
                    </div>
                    <hr class="mc-group-separator" />
                  </ng-container>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="showAppliesAdditionalField(rule.get(fieldName.APPLIES_TO), attributesFieldType)">
              <mc-field
                label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.WHERE_ATTRIBUTE"
                errorPrefix="$I18N_URL_PROTECTION_POLICY_WIZARD.VALIDATION.INVALID_FIELD"
                data-unit-test="to_attribute" data-cy-test="where_attribute_to">
                <mc-select
                  formControlName="toWhereAttribute"
                  [useTranslation]="true"
                  [options]="whereAttributes">
                </mc-select>
              </mc-field>
              <mc-text-field
                label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.IS_EQUAL_TO"
                errorPrefix="$I18N_URL_PROTECTION_POLICY_WIZARD.VALIDATION.INVALID_FIELD"
                data-unit-test="applies-to-is-equal-to"
                data-cy-test="applies-to-is-equal-to"
                formControlName="appliesToIsEqualTo"
              ></mc-text-field>
            </ng-container>
            <mc-text-field *ngIf="showDependecy()"
              label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.DEPENDENCY"
              placeholder="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.DEPENDENCY_PLACEHOLDER"
              data-unit-test="applies-to-dependency" formControlName="appliesToDependency"></mc-text-field>
            <mc-collapsable-panel extendClass="mc-adv-options"
              showMoreLabel="{{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.SHOW_ADVANCED_OPTIONS' | translate}}"
              showLessLabel="{{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.HIDE_ADVANCED_OPTIONS' | translate}}"
              [isExpanded]="ipRanges[i]?.length > 0">
              <mc-field class="mc-custom-switch-form-field"
                label="{{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.BIDIRECTIONAL' | translate}}"
                popoverPlacement="top" class="mc-custom-label" data-unit-test="bidirectional-control" data-cy-test="bidirectional-control"
                helpPopoverContent="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.TOOLTIPS.BIDIRECTIONAL' | translate }}">
                <mc-switch formControlName="bidirectional" role="switch" showLabel="true"></mc-switch>
              </mc-field>
              <mc-sub-section
                title="{{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.IP_RANGE_TITLE' | translate}}"
                description="{{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.IP_RANGE_DESCRIPTION' | translate}}">
                <div class="mc-ip-control">
                    <div class="ip-control">
                      <mc-text-field
                        errorPrefix="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.ERROR"
                        placeholder="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.IP_PLACEHOLDER"
                        formControlName="ipRange" data-unit-test="range">
                      </mc-text-field>
                    </div>
                    <div>
                      <button
                        class="btn btn-secondary"
                        (click)="addNewRange(rule.get(fieldName.IP_RANGE), i)"
                        [disabled]="!rule.get(fieldName.IP_RANGE).value || rule.get(fieldName.IP_RANGE).invalid">
                        {{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.ADD' | translate}}
                      </button>
                    </div>
                </div>
                <div *ngIf="ipRanges[i]?.length > 0">
                  <div class="mc-ip-header mc-range-item">
                    <span>{{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.IP' | translate}}</span>
                  </div>
                  <ng-container *ngFor="let range of ipRanges[i]; index as j">
                    <div class="mc-range-item">
                      <span>{{range}}</span> <i class="far fa-trash-alt" (click)="removeRange(i, j)"></i>
                    </div>
                  </ng-container>
                </div>
              </mc-sub-section>
            </mc-collapsable-panel>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <button class="btn btn-secondary" (click)="addNewRule()">
      {{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.ADD_RULE' | translate}}
    </button>
  </form>
</div>
