"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefinitionAsideComponent = void 0;
const core_1 = require("@angular/core");
const table_store_1 = require("@mimecast-ui/table-store");
const listActions = require("../../actions/definition.actions");
const createActions = require("../../actions/create-definition.actions");
const reducer = require("../../reducers/index");
class DefinitionAsideComponent extends table_store_1.TableRowDetailBaseComponent {
    constructor(store, detailsStore, fb, changeDetectorRef, accountPackageService) {
        super(store, 'definitionsListTable');
        this.detailsStore = detailsStore;
        this.fb = fb;
        this.changeDetectorRef = changeDetectorRef;
        this.accountPackageService = accountPackageService;
        this.canModifyPolicy = 'Permission.POLICIES_EDIT';
        this.closeAside = new core_1.EventEmitter();
        this.disabled = true;
        this.disableGetDefinition = false;
        this.detailsDefinitionForm = this.fb.group({
            enableDisableDefinition: [true]
        });
        this.hasIEPPackage = this.accountPackageService.hasInternalEmailProtectAccountPackage();
        this.isAAAOrFAAUser = this.accountPackageService.isAAAOrFAAUser();
        this.hasBrowserIsolation = this.accountPackageService.hasBrowserIsolationEnabled();
    }
    ngOnInit() {
        this.startSubsciptions();
    }
    startSubsciptions() {
        this.rowSubscription = this.selectedRow$.subscribe(row => {
            if (!row) {
                return;
            }
            this.selectedRow = row;
        });
        this.indexInViewSubscribtion = this.indexInView$.subscribe(() => {
            if (!this.selectedRow.id) {
                return;
            }
            if (!this.disableGetDefinition) {
                this.store.dispatch(new listActions.ClearDefinitionDetailsAction());
                this.store.dispatch(new listActions.GetDefinitionDetailsAction({ id: this.selectedRow.id }));
            }
            else {
                this.disableGetDefinition = false;
            }
        });
        this.definitionDetailsFullData$ = this.detailsStore.select(reducer.getSelectedRowFullData);
        this.definitionDetailsFullDataSubscription = this.definitionDetailsFullData$.subscribe(value => {
            if (value) {
                this.definitionDetails = Object.assign({}, value);
                this.changeDetectorRef.markForCheck();
                this.disabled = false;
            }
        });
    }
    onDelete(definition) {
        this.store.dispatch(new listActions.DefinitionDeleteAttemptAction(definition.id));
        this.store.dispatch(new listActions.CloseDefinitionDetailsSidePanelAction());
    }
    onClose() {
        this.store.dispatch(new listActions.CloseDefinitionDetailsSidePanelAction());
    }
    onEdit() {
        this.store.dispatch(new createActions.ApDefinitionNavigateToCreateDefinition());
        this.store.dispatch(new listActions.CloseDefinitionDetailsSidePanelAction());
    }
    onDuplicate(definition) {
        this.disableGetDefinition = true;
        this.store.dispatch(new listActions.ClearDefinitionDetailsAction());
        this.store.dispatch(new listActions.GetDuplicateDefinitionDetailsAction({ id: definition.id }));
        this.store.dispatch(new createActions.ApDefinitionNavigateToCreateDefinition());
        this.store.dispatch(new listActions.CloseDefinitionDetailsSidePanelAction());
    }
    ngOnDestroy() {
        this.rowSubscription.unsubscribe();
        this.definitionDetailsFullDataSubscription.unsubscribe();
        this.indexInViewSubscribtion.unsubscribe();
    }
}
exports.DefinitionAsideComponent = DefinitionAsideComponent;
