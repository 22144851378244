<div class="mc-detailed-list">
  <mc-collapsable-panel
    header="$I18N_MESSAGE_CENTER_RELEASE_LOG_DETAIL.RELEASE_PROPERTIES.TITLE"
    [isExpanded]="true">
    <mc-value-field
      label="$I18N_MESSAGE_CENTER_RELEASE_LOG_DETAIL.RELEASE_PROPERTIES.STATUS"
      [value]="releaseLog.status">
    </mc-value-field>
    <mc-value-field
      label="$I18N_MESSAGE_CENTER_RELEASE_LOG_DETAIL.RELEASE_PROPERTIES.OPERATOR"
      [value]="releaseLog.operator?.emailAddress">
    </mc-value-field>
    <mc-value-field
      label="$I18N_MESSAGE_CENTER_RELEASE_LOG_DETAIL.RELEASE_PROPERTIES.HOLD_REASON"
      [value]="releaseLog.heldReason">
    </mc-value-field>
    <mc-value-field
      label="$I18N_MESSAGE_CENTER_RELEASE_LOG_DETAIL.RELEASE_PROPERTIES.EVENT_TIME"
      [value]="releaseLog.released | mcDatePlusWeekday">
    </mc-value-field>
    <mc-value-field
      label="$I18N_MESSAGE_CENTER_RELEASE_LOG_DETAIL.RELEASE_PROPERTIES.INFORMATION"
      [value]="releaseLog.messageInfo">
    </mc-value-field>
  </mc-collapsable-panel>
</div>
<hr />
<div class="mc-detailed-list">
  <mc-collapsable-panel
    header="$I18N_MESSAGE_CENTER_RELEASE_LOG_DETAIL.MESSAGE_INFORMATION.TITLE"
    [isExpanded]="true">
    <mc-value-field
      label="$I18N_MESSAGE_CENTER_RELEASE_LOG_DETAIL.MESSAGE_INFORMATION.FROM_HEADER"
      [value]="releaseLog.fromHdr?.emailAddress">
    </mc-value-field>
    <mc-value-field
      label="$I18N_MESSAGE_CENTER_RELEASE_LOG_DETAIL.MESSAGE_INFORMATION.FROM_ENVELOPE"
      [value]="releaseLog.fromEnv?.emailAddress">
    </mc-value-field>
    <mc-emails-field
      label="$I18N_MESSAGE_CENTER_RELEASE_LOG_DETAIL.MESSAGE_INFORMATION.TO"
      [emails]="toAddresses(releaseLog.to)">
    </mc-emails-field>
    <mc-value-field
      label="$I18N_MESSAGE_CENTER_RELEASE_LOG_DETAIL.MESSAGE_INFORMATION.SUBJECT"
      [value]="releaseLog.subject">
    </mc-value-field>
    <mc-value-field
      label="$I18N_MESSAGE_CENTER_RELEASE_LOG_DETAIL.MESSAGE_INFORMATION.MESSAGE_SIZE"
      [value]="releaseLog.size">
    </mc-value-field>
  </mc-collapsable-panel>
</div>

<div *ngIf="showSpamDetails">
  <hr />
  <mc-spam-details-info
      [spamInfo]="releaseLog">
  </mc-spam-details-info>
</div>

<ng-template #spamScorePopover>
  <div>
      <p>{{ '$I18N_MESSAGE_CENTER_RELEASE_LOG_DETAIL.POPOVER.SPAM_SCORE_INFO' | translate}}</p>
  </div>
</ng-template>

<ng-template #spamDetectionPopover>
  <div>
      <p>{{ '$I18N_MESSAGE_CENTER_RELEASE_LOG_DETAIL.POPOVER.SPAM_DETECTION_INFO' | translate}}</p>
      <a href="https://community.mimecast.com/docs/DOC-1435">{{ '$I18N_MESSAGE_CENTER_RELEASE_LOG_DETAIL.SPAM_INFORMATION.LEARN_MORE' | translate }}</a>
  </div>
</ng-template>
