'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const _ = require("lodash");
require("angular-ui-bootstrap"); //ui.bootstrap.modal
// html and css
require("./thumbnails.tpl.html");
/**
 * Created by cfeudo on 02/04/2014.
 */
document.createElement('mc-thumbnail');
angular.module('thumbnails', ['ui.bootstrap.modal'])
    .directive('mcThumbnail', [function () {
        return {
            restrict: 'E',
            scope: { items: '=mcItems', canDownload: '=', callbackToView: '&' },
            templateUrl: 'services/services/backup/components/thumbnails/thumbnails.tpl.html',
            link(scope, element, attr) {
                scope.isAttachCollapsed = false;
                if (angular.isDefined(attr.expanded)) {
                    if (attr.expanded.toLowerCase() === 'true') {
                        scope.isAttachCollapsed = true;
                    }
                }
                scope.countAttachmentsItems = function () {
                    let count = 0;
                    let sum = 0;
                    _.forEach(scope.items, function (item) {
                        count++;
                        sum += item.size;
                    });
                    return { count, totalSize: sum };
                };
                scope.getIcon = function (contentType) {
                    const icon = 'far fa-file';
                    // Image
                    if (contentType.indexOf('image') == 0) {
                        return 'far fa-file-image';
                    }
                    // Audio
                    if (contentType.indexOf('audio') == 0) {
                        return 'far fa-file-audio';
                    }
                    // Video
                    if (contentType.indexOf('video') == 0) {
                        return 'far fa-file-video';
                    }
                    // Calendar
                    if (contentType.indexOf('calendar') == 0) {
                        return 'far fa-calendar-alt';
                    }
                    // Pdf
                    if (contentType == 'application/pdf') {
                        return 'far fa-file-pdf';
                    }
                    // Email Messages
                    if (contentType == 'message/rfc822') {
                        return 'far fa-envelope';
                    }
                    // Compressed
                    if (contentType.indexOf('x-rar-compressed') == 0 || contentType == 'application/zip' || contentType == 'application/x-rar-compressed') {
                        return 'far fa-file-archive';
                    }
                    //Office
                    if (contentType == 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || contentType == 'application/vnd.ms-powerpoint') {
                        return 'far fa-file-powerpoint';
                    }
                    if (contentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || contentType == 'application/vnd.ms-excel') {
                        return 'far fa-file-excel';
                    }
                    if (contentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || contentType == 'application/msword') {
                        return 'far fa-file-word ';
                    }
                    return icon;
                };
                scope.canBeViewed = function (contentType) {
                    return contentType.indexOf('message/rfc822') == 0;
                };
                scope.view = function (item) {
                    scope.callbackToView({ email: { id: item.id } });
                };
            }
        };
    }]);
