"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DecodeUrlService = void 0;
const operators_1 = require("rxjs/operators");
class DecodeUrlService {
    constructor(http) {
        this.http = http;
    }
    decodeUrl(url) {
        return this.http
            .post('/api/ttp/url/relaxed-decode-url', { url })
            .pipe(operators_1.map((response) => response.first));
    }
}
exports.DecodeUrlService = DecodeUrlService;
