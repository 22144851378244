<div class="no-connectors">
  <img src="/administration/resources/assets/plug-regular.4a94dabfdc4e2df607a561236c42b29a.svg" width="50" height="50" alt="Connector icon" />
  <div class="title">{{ '$I18N_NO_CONNECTORS.TITLE' | translate }}</div>
  <p class="help-text">{{ '$I18N_NO_CONNECTORS.HELP_TEXT' | translate }}</p>
  <button 
    *mcCapabilities="'Permission.CONNECTORS_EDIT || Permission.SERVER_CONNECTIONS_EDIT'"
    class="btn btn-primary mc-create-button" 
    (click)="createConnector()">
      {{ '$I18N_NO_CONNECTORS.CREATE_NEW_CONNECTOR' | translate }}
  </button>
</div>
