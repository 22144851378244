<mc-wizard-step-header
  compact="true"
  label="$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.USER_GROUPS.HEADER"
  xmlns="http://www.w3.org/1999/html">
</mc-wizard-step-header>

<p>
  {{ "$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.USER_GROUPS.SUBHEADER" | translate }}
</p>

<div class="row mc-detailed-list-row  panel-margin-top">
  <div class="col-xs-3 mc-detailed-list-label">
    <label class="user-group-label">
      {{"$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.USER_GROUPS.SELECT_USER_GROUPS_LABEL" | translate}}
    </label>
  </div>
  <div class=" col-xs-9 mc-detailed-list-column">
    <button
      type="button"
      class="btn btn-default"
      (click)="openSidebar()"
      [disabled]="moreThanFourGroupsSelected"
    >
      {{"$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.USER_GROUPS.SELECT_USER_GROUPS_BTN" | translate}}
      <span>{{ '$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.USER_GROUPS.MAX_NUMBER_OF_GROUPS' | translate }}</span>
    </button>
    <div *ngIf="moreThanFiveGroupsSelected">
      <br/>
      <mc-inline-notification
        [notification]="{
        status: 'error',
        boxed: true,
        msg: '$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.USER_GROUPS.WARNING' | translate
      }"
      >
      </mc-inline-notification>
    </div>
    <div class="panel-half-margin-top">
      <mc-removable-list
        [data]="userGroups"
        (removeItem)="removeSelected($event)"
      ></mc-removable-list>
    </div>
  </div>
</div>
