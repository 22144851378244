<mc-table
  tableId="swg/threat-events-table"
  translatePrefix="$I18N_SWG_THREAT_EVENTS_TABLE"
  [data]="threatEvents"
  [isLoading]="isLoading"
  [columns]="columns"
  (rowClick)="onRowClicked.emit($event)">

  <mc-filters
    [metadata]="metaData"
    [pageSizeOptions]="[20, 50, 100]"
    [showPagination]="true"
    (paginatorChange)="onPaginationChange.emit($event)">
    <mc-filters-bundle-date-range
      [config]="dateRangeConfig"
      (filtersChange)="onDateRangeFilterChange($event)"
    ></mc-filters-bundle-date-range>
  </mc-filters>

  <mc-column key="apiProvider">
    <mc-body-cell *mcBodyCellDef="let row">
      <ng-container *ngIf="row.apiProvider">
        <mc-provider-icon [providerType]="row.apiProvider"></mc-provider-icon>
        {{ '$I18N_CONNECTORS.PROVIDERS.' + row.apiProvider.toUpperCase() | translate }}
      </ng-container>
      <ng-container *ngIf="!row.apiProvider">
        {{ '$I18N_SWG_THREAT_EVENTS_TABLE.NOT_AVAILABLE' | translate }}
      </ng-container>
    </mc-body-cell>
  </mc-column>

  <mc-column key="fileTypes">
    <mc-body-cell *mcBodyCellDef="let row">
      {{ row.fileTypes?.join(', ') }}
      <span *ngIf="!row.fileTypes?.length">{{ '$I18N_SWG_THREAT_EVENTS_TABLE.NOT_AVAILABLE' | translate }}</span>
    </mc-body-cell>
  </mc-column>

  <mc-column key="fileOwner">
    <mc-body-cell *mcBodyCellDef="let row">
      <span *ngIf="row.fileOwner">{{ row.fileOwner }}</span>
      <span *ngIf="!row.fileOwner">{{ '$I18N_SWG_THREAT_EVENTS_TABLE.NOT_AVAILABLE' | translate }}</span>
    </mc-body-cell>
  </mc-column>

  <mc-column key="status">
    <mc-body-cell *mcBodyCellDef="let row">
      <mc-swg-threat-event-status [actions]="row.actions" [showIcon]="true"></mc-swg-threat-event-status>
    </mc-body-cell>
  </mc-column>

  <mc-column key="timestamp">
    <mc-body-cell *mcBodyCellDef="let row">
      {{ row.timestamp | date: dateFormat }}
      <span *ngIf="!row.timestamp">{{ '$I18N_SWG_THREAT_EVENTS_TABLE.NOT_AVAILABLE' | translate }}</span>
    </mc-body-cell>
  </mc-column>

  <mc-empty-results keyTitle="$I18N_SWG_THREAT_EVENTS_TABLE.NO_RESULTS_FOUND"></mc-empty-results>

  <div class="mc-above-table">
    <mc-swg-applications-chart-with-control
      [chartData]="graphData"
      [loading]="isGraphDataLoading"
      [chartType]="'multi-line'"
      [chartName]="'malicious_file_by_date'"
      [noDataMessage]="'$I18N_SWG_APPLICATIONS_DASHBOARD_CHART_NO_DATA'"
    ></mc-swg-applications-chart-with-control>
  </div>
</mc-table>
