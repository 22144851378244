"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransmissionInformationComponent = void 0;
const moment = require("moment");
const base_transmission_component_1 = require("../base-component/base-transmission.component");
class TransmissionInformationComponent extends base_transmission_component_1.BaseTransmissionDirective {
    get smtpTotalTimeReceived() {
        return moment(this.recipientInfo && this.recipientInfo.txInfo && this.recipientInfo.txInfo.smtpEndTime).diff(moment(this.recipientInfo && this.recipientInfo.txInfo && this.recipientInfo.txInfo.smtpStartTime));
    }
    get smtpTotalTimeDelivery() {
        if (this.isDeliveredDataAvailable() &&
            this.isValidOrZero(this.selectedDeliveredMessage.txInfo.smtpEndTime) &&
            this.isValidOrZero(this.selectedDeliveredMessage.txInfo.smtpStartTime)) {
            return moment(this.selectedDeliveredMessage.txInfo.smtpEndTime).diff(moment(this.selectedDeliveredMessage.txInfo.smtpStartTime));
        }
        return null;
    }
    get smtpTotalTimeReceivedArchived() {
        if (this.isRecipientDataAvailableArchived() &&
            this.isValidOrZero(this.recipientInfo.recipientMetaInfo.transmissionEnd) &&
            this.isValidOrZero(this.recipientInfo.recipientMetaInfo.transmissionStart)) {
            return moment(this.recipientInfo.recipientMetaInfo.transmissionEnd).diff(moment(this.recipientInfo.recipientMetaInfo.transmissionStart));
        }
        return null;
    }
    get smtpTotalTimeDeliveryArchived() {
        if (this.isDeliveredDataAvailableArchived() &&
            this.isValidOrZero(this.selectedDeliveredMessage.deliveryMetaInfo.transmissionEnd) &&
            this.isValidOrZero(this.selectedDeliveredMessage.deliveryMetaInfo.transmissionStart)) {
            return moment(this.selectedDeliveredMessage.deliveryMetaInfo.transmissionEnd).diff(moment(this.selectedDeliveredMessage.deliveryMetaInfo.transmissionStart));
        }
        return null;
    }
    get transmissionInformationReceivedActualSize() {
        if (this.isRecipientDataAvailableArchived() &&
            this.recipientInfo.recipientMetaInfo.components) {
            return this.recipientInfo.recipientMetaInfo.components
                .map(c => c.size)
                .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        }
        return null;
    }
    get transmissionInformationDeliveryActualSize() {
        if (this.isDeliveredDataAvailableArchived() &&
            this.selectedDeliveredMessage.deliveryMetaInfo.components) {
            return this.selectedDeliveredMessage.deliveryMetaInfo.components
                .map(c => c.size)
                .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        }
        return null;
    }
    isRecipientDataAvailable() {
        return Boolean(this.recipientInfo && this.recipientInfo.txInfo && this.recipientInfo.recipientMetaInfo);
    }
    isDeliveredDataAvailable() {
        return Boolean(this.selectedDeliveredMessage && this.selectedDeliveredMessage.txInfo);
    }
    isRecipientDataAvailableArchived() {
        return Boolean(this.recipientInfo && this.recipientInfo.recipientMetaInfo);
    }
    isDeliveredDataAvailableArchived() {
        return Boolean(this.selectedDeliveredMessage && this.selectedDeliveredMessage.deliveryMetaInfo);
    }
    isValidOrZero(val) {
        return val !== undefined && val !== null && val !== '';
    }
}
exports.TransmissionInformationComponent = TransmissionInformationComponent;
