<div class="btn-group" role="group">
  <button type="button"
          class="btn btn-secondary"
          translate="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TOP_HEADER_RECIPIENT.LABEL_PERMIT_FOR_RECIPIENT"
          (click)="permitForRecipient.emit()"
          [disabled]="selectedEmail?.type === 'receipt'">
  </button>
  <button type="button"
          class="btn btn-secondary"
          translate="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TOP_HEADER_RECIPIENT.LABEL_BLOCK_FOR_RECIPIENT"
          (click)="blockForRecipient.emit()"
          [disabled]="selectedEmail?.type === 'receipt'">
  </button>
</div>
