"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPurgeConfirmReason = exports.getFormRequest = exports.getSearchResponseCount = exports.reducer = exports.initialState = void 0;
const actions = require("../actions");
exports.initialState = {
    formRequest: undefined,
    searchResponseCount: 0,
    reason: ''
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.PURGE_MESSAGES_COUNT_REQUEST:
            return Object.assign(Object.assign({}, state), { formRequest: action.formValue });
        case actions.PURGE_MESSAGES_COUNT_SUCCESS:
            return Object.assign(Object.assign({}, state), { searchResponseCount: action.payload.count });
        case actions.PURGE_MESSAGES_CONFIRM_PURGE:
            return Object.assign(Object.assign({}, state), { reason: action.reason });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getSearchResponseCount = (state) => state.searchResponseCount;
exports.getSearchResponseCount = getSearchResponseCount;
const getFormRequest = (state) => state.formRequest;
exports.getFormRequest = getFormRequest;
const getPurgeConfirmReason = (state) => state.reason;
exports.getPurgeConfirmReason = getPurgeConfirmReason;
