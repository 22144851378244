"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getHistoryCount = exports.reducers = void 0;
const store_1 = require("@ngrx/store");
const legacy = require("./legacy-page.reducer");
exports.reducers = legacy.reducer;
const getLegacyPagesState = store_1.createFeatureSelector('legacyPages');
function getHistoryCount(tabId) {
    return store_1.createSelector(getLegacyPagesState, legacy.getHistoryCount(tabId));
}
exports.getHistoryCount = getHistoryCount;
