"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ5 = exports.ɵ4 = exports.ɵ3 = exports.ɵ2 = exports.ɵ1 = exports.ɵ0 = exports.getGroupsDisplayText = exports.getGroups = exports.getAppliedToEveryone = exports.getAppliesToStepState = exports.getExpirationTime = exports.getSessionTimeoutStepState = exports.getIpAddressesDisplayText = exports.getIpAddresses = exports.getPermissionType = exports.getIpAddressesStepState = exports.getRequestSecondFactorFirst = exports.getSecondFactorOption = exports.getMimeAuthEnabled = exports.getCloudAuthentication = exports.getAlternateDomainSuffix = exports.getCasServer = exports.getDomain = exports.getAuthenticationMethodsStepState = exports.getDescription = exports.getName = exports.getDetailsStepState = exports.getApiResponsePending = exports.getPolicy = exports.getCanContinue = exports.getIsLoading = exports.getWizardMode = exports.getPolicyWizardState = exports.getPolicyWizardFeatureState = exports.metaReducers = exports.reducers = void 0;
const wizardReducer = require("./wizard.reducer");
const detailsStepReducer = require("./details-step.reducer");
const authenticationMethodsStepReducer = require("./authentication-methods-step.reducer");
const ipAddressesStepReducer = require("./ip-addresses-step.reducer");
const appliesToStepReducer = require("./applies-to-step.reducer");
const sessionTimeoutStepReducer = require("./session-timeout-step.reducer");
const metaReducer = require("./meta.reducers");
const store_1 = require("@ngrx/store");
exports.reducers = {
    wizard: wizardReducer.reducer,
    detailsStep: detailsStepReducer.reducer,
    authenticationMethodsStep: authenticationMethodsStepReducer.reducer,
    ipAddressesStep: ipAddressesStepReducer.reducer,
    appliesToStep: appliesToStepReducer.reducer,
    sessionTimeoutStep: sessionTimeoutStepReducer.reducer
};
exports.metaReducers = metaReducer.reducers;
exports.getPolicyWizardFeatureState = store_1.createFeatureSelector('identityPolicyWizard');
const ɵ0 = (state) => state.wizard;
exports.ɵ0 = ɵ0;
// Policy Wizard
exports.getPolicyWizardState = store_1.createSelector(exports.getPolicyWizardFeatureState, ɵ0);
exports.getWizardMode = store_1.createSelector(exports.getPolicyWizardState, wizardReducer.getMode);
exports.getIsLoading = store_1.createSelector(exports.getPolicyWizardState, wizardReducer.getIsLoading);
exports.getCanContinue = store_1.createSelector(exports.getPolicyWizardState, wizardReducer.canContinue);
exports.getPolicy = store_1.createSelector(exports.getPolicyWizardState, wizardReducer.getPolicy);
exports.getApiResponsePending = store_1.createSelector(exports.getPolicyWizardState, wizardReducer.getApiResponsePending);
const ɵ1 = (state) => state.detailsStep;
exports.ɵ1 = ɵ1;
// Policy Details Step
exports.getDetailsStepState = store_1.createSelector(exports.getPolicyWizardFeatureState, ɵ1);
exports.getName = store_1.createSelector(exports.getDetailsStepState, detailsStepReducer.getName);
exports.getDescription = store_1.createSelector(exports.getDetailsStepState, detailsStepReducer.getDescription);
const ɵ2 = (state) => state.authenticationMethodsStep;
exports.ɵ2 = ɵ2;
// Authentication Methods Step
exports.getAuthenticationMethodsStepState = store_1.createSelector(exports.getPolicyWizardFeatureState, ɵ2);
exports.getDomain = store_1.createSelector(exports.getAuthenticationMethodsStepState, authenticationMethodsStepReducer.getDomain);
exports.getCasServer = store_1.createSelector(exports.getAuthenticationMethodsStepState, authenticationMethodsStepReducer.getCasServer);
exports.getAlternateDomainSuffix = store_1.createSelector(exports.getAuthenticationMethodsStepState, authenticationMethodsStepReducer.getAlternateDomainSuffix);
exports.getCloudAuthentication = store_1.createSelector(exports.getAuthenticationMethodsStepState, authenticationMethodsStepReducer.getCloudAuthentication);
exports.getMimeAuthEnabled = store_1.createSelector(exports.getAuthenticationMethodsStepState, authenticationMethodsStepReducer.getMimeAuthEnabled);
exports.getSecondFactorOption = store_1.createSelector(exports.getAuthenticationMethodsStepState, authenticationMethodsStepReducer.getSecondFactorOption);
exports.getRequestSecondFactorFirst = store_1.createSelector(exports.getAuthenticationMethodsStepState, authenticationMethodsStepReducer.getRequestSecondFactorFirst);
const ɵ3 = (state) => state.ipAddressesStep;
exports.ɵ3 = ɵ3;
// IP Addresses Step
exports.getIpAddressesStepState = store_1.createSelector(exports.getPolicyWizardFeatureState, ɵ3);
exports.getPermissionType = store_1.createSelector(exports.getIpAddressesStepState, ipAddressesStepReducer.getPermissionType);
exports.getIpAddresses = store_1.createSelector(exports.getIpAddressesStepState, ipAddressesStepReducer.getIpAddresses);
exports.getIpAddressesDisplayText = store_1.createSelector(exports.getIpAddressesStepState, ipAddressesStepReducer.getIpAddressesDisplayText);
const ɵ4 = (state) => state.sessionTimeoutStep;
exports.ɵ4 = ɵ4;
// Session Timeout Step
exports.getSessionTimeoutStepState = store_1.createSelector(exports.getPolicyWizardFeatureState, ɵ4);
exports.getExpirationTime = store_1.createSelector(exports.getSessionTimeoutStepState, sessionTimeoutStepReducer.getExpirationTime);
const ɵ5 = (state) => state.appliesToStep;
exports.ɵ5 = ɵ5;
// Applies To Step
exports.getAppliesToStepState = store_1.createSelector(exports.getPolicyWizardFeatureState, ɵ5);
exports.getAppliedToEveryone = store_1.createSelector(exports.getAppliesToStepState, appliesToStepReducer.getAppliedToEveryone);
exports.getGroups = store_1.createSelector(exports.getAppliesToStepState, appliesToStepReducer.getGroups);
exports.getGroupsDisplayText = store_1.createSelector(exports.getAppliesToStepState, appliesToStepReducer.getGroupsDisplayText);
