"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const actions = require("../actions");
const reducers = require("../reducers");
const moment = require("moment/moment");
class UserEffects {
    constructor(actions$, store, userInfoService, adminPreferencesApi, preferencesModalService, locationService, coreService, cookieService) {
        this.actions$ = actions$;
        this.store = store;
        this.userInfoService = userInfoService;
        this.adminPreferencesApi = adminPreferencesApi;
        this.preferencesModalService = preferencesModalService;
        this.locationService = locationService;
        this.coreService = coreService;
        this.cookieService = cookieService;
        this.sessionLoad$ = effects_1.createEffect(() => {
            return rxjs_1.zip(this.actions$.pipe(effects_1.ofType(actions.session.LOAD_SUCCESS), operators_1.first()), this.actions$.pipe(effects_1.ofType(actions.legacy.STORE_SWITCH_ACCOUNT_SESSION), operators_1.first())).pipe(operators_1.map(() => new actions.user.UserInfoLoadAction()));
        });
        this.userInfoLoad$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(actions.user.USER_INFO_LOAD), operators_1.mergeMap(() => {
                return this.userInfoService.getUserInfo().pipe(operators_1.map((userInfo) => {
                    return new actions.user.UserInfoLoadSuccessAction(userInfo);
                }));
            }));
        });
        this.userInfoLoaded$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(actions.user.USER_INFO_LOAD_SUCCESS), operators_1.map((action) => action.payload), operators_1.mergeMap((userInfo) => {
                return [
                    new actions.user.ProfileLoadSuccessAction(userInfo.profile),
                    new actions.user.CapabilitiesLoadSuccessAction(userInfo.capabilities),
                    new actions.user.PreferencesLoadSuccessAction(userInfo.preferences),
                    new actions.user.AccountLoadSuccessAction(userInfo.account),
                    new actions.user.AdminPreferencesLoadSuccessAction(userInfo.adminPreferences),
                    new actions.user.VerifyAdminAccessLoadSuccessAction(userInfo.adminAccess),
                    new actions.user.VerifyFederatedAccessLoadSuccessAction(userInfo.federatedAdminAccess),
                    new actions.user.MigratedPolicyData(userInfo.migratedPolicy),
                    new actions.pendo.PendoGetInformationAction(),
                    new actions.googleanalytics.GetInformationAction(),
                    new actions.user.CapsOverrideLoadSuccessAction(userInfo.capsOverride),
                    new actions.user.ConfigExtendedLoadSuccessAction(userInfo.configExtended)
                ];
            }));
        });
        this.adminPreferencesLoadSuccess$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(actions.user.ADMIN_PREFERENCES_LOAD_SUCCESS), operators_1.map((action) => {
                return action.payload && action.payload.earlyAccess;
            }), operators_1.filter((earlyAccess) => !earlyAccess && earlyAccess !== false), operators_1.map(() => {
                return new actions.user.PreferencesSaveAction({ earlyAccess: true }, true);
            }));
        });
        this.preferencesSave$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(actions.user.PREFERENCES_SAVE), operators_1.map((action) => action), operators_1.withLatestFrom(this.store.select(reducers.getAdminPreferences)), operators_1.mergeMap(([action, currentAdminPreferences]) => {
                // merge old and new admin prefs - this way we just send the property we care about
                const newAdminPrefs = Object.assign(Object.assign({}, currentAdminPreferences), action.payload);
                return this.adminPreferencesApi.savePreferences(newAdminPrefs).pipe(operators_1.map((result) => {
                    return new actions.user.PreferencesSaveSuccessAction(result.preferences, action.refreshPageOnSuccess);
                }), operators_1.catchError(() => [new actions.user.PreferencesSaveFailAction()]));
            }));
        });
        this.preferencesSaveSuccess$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(actions.user.PREFERENCES_SAVE_SUCCESS), operators_1.filter((action) => action.refreshPageOnSuccess), 
            // TODO: when menu mega menu is remade uncomment next line and delete locationService.reload()
            operators_1.map(() => new actions.user.CapabilitiesReloadAction()), operators_1.tap(() => {
                this.preferencesModalService.close('saved');
                this.locationService.reload();
            }));
        }, { dispatch: false });
        this.preferencesCancel$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(actions.user.PREFERENCES_CANCEL), operators_1.tap(() => this.preferencesModalService.close('cancel')));
        }, { dispatch: false });
        this.reloadCapabilities$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(actions.user.CAPABILITIES_RELOAD), operators_1.withLatestFrom(this.store.select(reducers.getAccount), this.store.select(reducers.getAdminPreferences), this.store.select(reducers.getFederatedAdminAccess), this.store.select(reducers.getCapsOverride)), operators_1.mergeMap(([, account, adminPreferences, federatedAdminAccess, capsOverride]) => {
                return this.userInfoService
                    .reloadCapabilities(account, adminPreferences && adminPreferences.earlyAccess, federatedAdminAccess, capsOverride)
                    .pipe(operators_1.map(caps => new actions.user.CapabilitiesLoadSuccessAction(caps)));
            }));
        });
        this.verifyAdminAccess$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(actions.user.VERIFY_ADMIN_ACCESS_LOAD_SUCCESS), operators_1.withLatestFrom(this.store.select(reducers.getAdminAccess)), operators_1.tap(([, adminAccess]) => {
                if (!adminAccess) {
                    this.cookieService.set('insufficient_roles', 'true', moment()
                        .add(1, 'minute')
                        .toDate(), '/', '', true);
                    this.coreService.logout(true);
                }
            }));
        }, { dispatch: false });
    }
}
exports.UserEffects = UserEffects;
