<div class="mc-ap-policy-details-block mc-ap-policy-steps-container">

  <mc-wizard-step-header class="mc-ap-wizard-step-header"
                         label="$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.$I18N_AP_CREATE_POLICY_INBOUND_STEPS">
  </mc-wizard-step-header>

  <form class="form-horizontal"
        mcDefaultLabelClass="col-sm-4"
        mcDefaultControlContainerClass="col-sm-8"
        [formGroup]="apInboundSettingsForm">
    <div
      class="mc-text-thick">{{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.CONFIGURATION.LABEL' | translate}}</div>
    <div
      class="mc-ap-policy-notification-text">{{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.CONFIGURATION.DESCRIPTION' | translate}}</div>
    <mc-field
      label="{{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.CONFIGURATION.DELIVERYMETHOD' | translate }}">
      <div>
        <div class="radio panel-half-padding-bottom" *ngFor="let option of configurationOptions">
          <label>
            <input
              type="radio"
              required
              name="action"
              formControlName="action"
              [value]="option.id"
            />
            {{ option.label | translate }}
          </label>
          <div class="mc-group-selector-desc">
            {{option.description | translate}}</div>
        </div>
      </div>
    </mc-field>
    <div
      class="mc-text-thick mc-ap-policy-inbound-text">{{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.GENERALSETTINGSLABEL' | translate}}</div>
    <mc-field
      *ngIf="apInboundSettingsForm.get('action').value !== 'safedoc_only' && apInboundSettingsForm.get('action').value !== 'safedoc_and_link'"
      label="$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.PREEMPTIVE.LABEL"
      helpPopoverContent="{{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.PREEMPTIVE.HELP_POPOVER' | translate }}">
      <div class="form-control-children">
        <mc-select formControlName="preEmptiveFailureAction"
                   [options]="preEmptiveFailureActionOptions"
                   class="mc-timeout-control">
        </mc-select>
      </div>
    </mc-field>
    <mc-switch-field *ngIf="apInboundSettingsForm.get('action').value !== 'sandbox'"
                     label="$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.SIGNEDMESSGAES.LABEL"
                     helpPopoverContent="{{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.SIGNEDMESSGAES.HELP_POPOVER' | translate }}"
                     formControlName="ignoreSignedMessages">
    </mc-switch-field>
    <mc-switch-field
      label="$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.INTERNALATTACHMENTS.LABEL"
      helpPopoverContent="{{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.INTERNALATTACHMENTS.HELP_POPOVER' | translate }}"
      formControlName="internalForward">
    </mc-switch-field>
    <mc-switch-field
      *ngIf="apInboundSettingsForm.get('action').value !== 'safedoc_only' && (hasRemoveActiveContent$ | async)"
      label="$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.REMOVE_ACTIVE_CONTENT.LABEL"
      helpPopoverContent="{{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.REMOVE_ACTIVE_CONTENT.HELP_POPOVER' | translate }}"
      formControlName="removeActiveContent">
    </mc-switch-field>
    <div *ngIf="apInboundSettingsForm.get('action').value !== 'sandbox'">
      <div
        class="mc-text-thick">{{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.SAFEFILELABEL' | translate}}</div>
      <div
        class="mc-ap-policy-inbound-text">{{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.SAFEFILEDESCRIPTION' | translate}}</div>
      <mc-field label="$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.DOCUMENTFORMAT.LABEL"
                helpPopoverContent="{{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.DOCUMENTFORMAT.HELP_POPOVER' | translate }}">
        <div class="form-control-children">
          <mc-select formControlName="safeDocFormat"
                     [options]="documentFormatOptions"
                     class="mc-timeout-control"></mc-select>
        </div>
      </mc-field>
      <mc-field label="$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.SPREADSHEETFORMAT.LABEL"
                helpPopoverContent="{{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.SPREADSHEETFORMAT.HELP_POPOVER' | translate }}">
        <div class="form-control-children">
          <mc-select formControlName="safeSpreadsheetFormat"
                     [options]="SpreadsheetsFormatOptions"
                     class="mc-timeout-control"></mc-select>
        </div>
      </mc-field>
    </div>
    <div *ngIf="apInboundSettingsForm.get('action').value !== 'safedoc_only'">
      <div
        class="mc-text-thick">{{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.NOTIFICATIONSLABEL' | translate}}</div>
      <div
        class="mc-ap-policy-notification-text">{{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.NOTIFICATIONSDESCRIPTION' | translate}}</div>
      <div *ngIf="apInboundSettingsForm.get('action').value !== 'safedoc_and_link'">
        <mc-field label="$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.USERNOTIFICATION.LABEL">
          <div>
            <mc-checkbox class="mc-ap-checkbox"
                         label="$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.USERNOTIFICATION.OPTIONS.INTERNALSENDER"
                         formControlName="notifyInternalSender"
            >
            </mc-checkbox>
            <mc-checkbox class="mc-ap-checkbox"
                         label="$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.USERNOTIFICATION.OPTIONS.INTERNALRECIPIENT"
                         formControlName="notifyInternalRecipient"
            >
            </mc-checkbox>
            <mc-checkbox class="mc-ap-checkbox"
                         label="$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.USERNOTIFICATION.OPTIONS.EXTERNALSENDER"
                         formControlName="notifyExternalSender"
            >
            </mc-checkbox>
          </div>
        </mc-field>
      </div>
      <div class="mc-select-group-field">
        <label class="mc-select-label">
          {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.ADMINISTRATORS.LABEL' | translate}}
        </label>
        <button class="btn btn-secondary mc-select-notification"
                (click)="openSidebar()">{{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.ADMINISTRATORS.SELECT_GROUP_BUTTON'| translate }}
        </button>
      </div>
      <div class="mc-selected-group" *ngIf="selectGroupsLength">
        <div class="mc-selected-group-display">
          <div class="mc-groups-list">{{ selectGroupsLength.description | translate }}
          </div>
          <div class="mc-delete-group">
            <i class="far fa-trash-alt" aria-hidden="true" (click)="onDeleteGroup(selectGroupsLength)"></i>
          </div>
        </div>
        <hr class="mc-group-separator"/>
      </div>
    </div>
  </form>
</div>

