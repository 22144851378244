"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrimPipe = void 0;
// eslint-disable-next-line
class TrimPipe {
    transform(value) {
        if (!value) {
            return '';
        }
        return value.trim();
    }
}
exports.TrimPipe = TrimPipe;
