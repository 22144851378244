"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRedirectGroup = exports.getRedirectEmail = exports.getProfileGroup = exports.getNotifyGroups = exports.getDetailsPaginationInfo = exports.isLoading = exports.getSelectedRow = exports.reducer = exports.initialState = void 0;
const actions = require("../actions");
exports.initialState = {
    selectedRow: undefined,
    isLoading: false,
    detailsPaginationInfo: undefined,
    notifyGroups: [],
    profileGroup: undefined,
    redirectEmail: undefined,
    redirectGroup: undefined
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.GET_POLICY:
            return Object.assign(Object.assign({}, state), { isLoading: true });
        case actions.GET_POLICY_SUCCESS:
            return Object.assign(Object.assign({}, state), { selectedRow: action.payload, isLoading: false });
        case actions.CLEAN_POLICY:
            return Object.assign(Object.assign({}, state), { selectedRow: undefined, detailsPaginationInfo: undefined, isLoading: false });
        case actions.SET_DETAILS_PAGINATION_INFO:
            return Object.assign(Object.assign({}, state), { detailsPaginationInfo: {
                    previousEntity: action.previous ? action.previous : undefined,
                    currentEntity: action.current ? action.current : undefined,
                    nextEntity: action.next ? action.next : undefined,
                    currentEntityIndex: action.currentIndex,
                    totalNumberEntities: action.totalNumber
                } });
        case actions.DELETE_POLICY:
            return Object.assign(Object.assign({}, state), { isLoading: true });
        case actions.DELETE_POLICY_SUCCESS:
        case actions.DELETE_POLICY_FAIL:
            return Object.assign(Object.assign({}, state), { selectedRow: undefined, detailsPaginationInfo: undefined, isLoading: false });
        case actions.ADD_NOTIFY_GROUP:
            return Object.assign(Object.assign({}, state), { notifyGroups: [action.payload] });
        case actions.ADD_PROFILE_GROUP:
            return Object.assign(Object.assign({}, state), { profileGroup: action.payload });
        case actions.ADD_REDIRECT_EMAIL:
            return Object.assign(Object.assign({}, state), { redirectEmail: action.payload });
        case actions.ADD_REDIRECT_GROUP:
            return Object.assign(Object.assign({}, state), { redirectGroup: action.payload });
        case actions.REMOVE_NOTIFY_GROUP:
            return Object.assign(Object.assign({}, state), { notifyGroups: [] });
        case actions.REMOVE_REDIRECT_EMAIL:
            return Object.assign(Object.assign({}, state), { redirectEmail: undefined });
        case actions.REMOVE_PROFILE_GROUP:
            return Object.assign(Object.assign({}, state), { profileGroup: undefined });
        case actions.REMOVE_REDIRECT_GROUP:
            return Object.assign(Object.assign({}, state), { redirectGroup: undefined });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getSelectedRow = (state) => {
    return state.selectedRow;
};
exports.getSelectedRow = getSelectedRow;
const isLoading = (state) => {
    return state.isLoading;
};
exports.isLoading = isLoading;
const getDetailsPaginationInfo = (state) => {
    return state.detailsPaginationInfo;
};
exports.getDetailsPaginationInfo = getDetailsPaginationInfo;
const getNotifyGroups = (state) => {
    return state.notifyGroups;
};
exports.getNotifyGroups = getNotifyGroups;
const getProfileGroup = (state) => {
    return state.profileGroup;
};
exports.getProfileGroup = getProfileGroup;
const getRedirectEmail = (state) => {
    return state.redirectEmail;
};
exports.getRedirectEmail = getRedirectEmail;
const getRedirectGroup = (state) => {
    return state.redirectGroup;
};
exports.getRedirectGroup = getRedirectGroup;
