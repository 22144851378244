"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UrlProtectionPolicySummaryStepComponent = void 0;
const core_1 = require("@angular/core");
const reducers = require("../../../reducers");
const actions = require("../../../actions/policies-create.actions");
const url_protection_inbound_settings_step_models_1 = require("../url-protection-inbound-settings-step/url-protection-inbound-settings-step.models");
const statics = require("./url-protection-policy-summary-step.component.static-values");
const url_protection_policy_summary_step_component_helper_1 = require("./url-protection-policy-summary-step.component.helper");
const formFieldNames = {
    policyStatusEnabled: 'policyStatusEnabled'
};
class UrlProtectionPolicySummaryStepComponent {
    constructor(fb, store, translateService) {
        this.fb = fb;
        this.store = store;
        this.translateService = translateService;
        this.statics = statics;
        this.showMoreLessTriggerValue = statics.SHOW_MORE_LESS_TRIGGER_VALUE;
        this.onEditStep = new core_1.EventEmitter();
        this.helper = new url_protection_policy_summary_step_component_helper_1.UrlProtectionPolicySummaryStepComponentHelper(this.translateService);
        this.urlProtectionPolicySummaryForm = this.fb.group({
            policyStatusEnabled: [true]
        });
        this.createObservables();
        this.startSubscriptions();
    }
    createObservables() {
        this.policyDetailsSetting$ = this.store.select(reducers.getDetailsStepData);
        this.policyInboundSetting$ = this.store.select(reducers.getInboundStepData);
        this.policyOutboundSetting$ = this.store.select(reducers.getOutboundStepData);
        this.policyJournalSetting$ = this.store.select(reducers.getJournalStepData);
        this.policyUserAwarenessSetting$ = this.store.select(reducers.getUserAwarenessStepData);
        this.policyUserAwarenessConfig$ = this.store.select(reducers.getUserAwarenessStepConfig);
        this.policyAppliesToConfig$ = this.store.select(reducers.getFromToWhereAttributes);
        this.policyAppliesToSetting$ = this.store.select(reducers.getAppliesToStepData);
    }
    ngOnChanges(changes) {
        const change = changes['policySummaryData'];
        if (change && change.previousValue !== change.currentValue) {
            if (change.currentValue) {
                this.updatePolicyStatus(change.currentValue.policyStatusEnabled);
            }
        }
    }
    startSubscriptions() {
        this.policyDetailsSettingSubscription = this.policyDetailsSetting$.subscribe(value => {
            this.detailsStep = Object.assign({}, value);
        });
        this.policyInboundSettingSubscription = this.policyInboundSetting$.subscribe(value => {
            this.inboundStep = value;
            this.showFileProtocolUrlHandling =
                value.urlCategoryScanningMode === url_protection_inbound_settings_step_models_1.UrlCategoryScanningOptions.AGGRESSIVE;
        });
        this.policyOutboundSettingSubscription = this.policyOutboundSetting$.subscribe(value => {
            this.outboundStep = value;
        });
        this.policyJournalSettingSubscription = this.policyJournalSetting$.subscribe(value => {
            this.journalStep = value;
        });
        this.policyUserAwarenessSettingSubscription = this.policyUserAwarenessSetting$.subscribe(value => {
            this.userAwarenessStep = value;
        });
        this.policyUserAwarenessConfigSubscription = this.policyUserAwarenessConfig$.subscribe(value => {
            this.userAwarenessStepPageSetOptions = value;
        });
        this.policyAppliesToConfigSubscription = this.policyAppliesToConfig$.subscribe(value => {
            this.appliesToAddressAttributesOptions = value;
        });
        this.policyAppliesToSettingSubscription = this.policyAppliesToSetting$.subscribe(value => {
            this.appliesToStep = value;
        });
        this.policyStatusValueChangeSubscription = this.urlProtectionPolicySummaryForm
            .get(formFieldNames.policyStatusEnabled)
            .valueChanges.subscribe(value => {
            this.store.dispatch(new actions.SetPolicyStatusEnabled(value));
        });
    }
    updatePolicyStatus(status) {
        this.urlProtectionPolicySummaryForm.get(formFieldNames.policyStatusEnabled).patchValue(status);
    }
    showDescription() {
        return this.detailsStep.description && this.detailsStep.description.length > 0;
    }
    isBypass() {
        return this.detailsStep.bypassPolicy;
    }
    showInboundBrowserIsolation() {
        return (this.inboundStep.maliciousUrlDetectionAction === url_protection_inbound_settings_step_models_1.MaliciousUrlDetectionActionOptions.BLOCK &&
            this.hasBrowserIsolation);
    }
    getEnabledDisabledLabel(value) {
        return this.translate(value ? statics.EnabledDisabledLabel.ENABLED : statics.EnabledDisabledLabel.DISABLED);
    }
    goToUserAwarenessStep() {
        this.editStep(this.hasIEPPackage
            ? statics.USER_AWARENESS_STEP_NUMBER
            : statics.USER_AWARENESS_WITHOUT_IEP_STEP_NUMBER);
    }
    goToAppliesStep() {
        let stepIndex = statics.APPLIES_TO_STEP_NUMBER;
        if (this.isBypass()) {
            stepIndex = statics.APPLIES_TO_WITH_BYPASS_STEP_NUMBER;
        }
        else if (!this.hasIEPPackage) {
            stepIndex = statics.APPLIES_TO_WITHOUT_IEP_STEP_NUMBER;
        }
        this.editStep(stepIndex);
    }
    editStep(stepNumber) {
        this.onEditStep.emit(stepNumber);
    }
    translate(value) {
        return this.translateService.instant(value);
    }
    ngOnDestroy() {
        this.policyDetailsSettingSubscription.unsubscribe();
        this.policyInboundSettingSubscription.unsubscribe();
        this.policyOutboundSettingSubscription.unsubscribe();
        this.policyJournalSettingSubscription.unsubscribe();
        this.policyUserAwarenessSettingSubscription.unsubscribe();
        this.policyUserAwarenessConfigSubscription.unsubscribe();
        this.policyAppliesToConfigSubscription.unsubscribe();
        this.policyAppliesToSettingSubscription.unsubscribe();
        this.policyStatusValueChangeSubscription.unsubscribe();
    }
}
exports.UrlProtectionPolicySummaryStepComponent = UrlProtectionPolicySummaryStepComponent;
