<div 
    class="mc-onedrive-backup-wizard-excluded-files" 
    [ngClass]="{'mc-onedrive-form-errors' : !formValid}"
>
    <mc-wizard-step-header 
    label="$I18N_ONEDRIVE_BACKUP_WIZARD.STEPS.EXCLUDED_FILES.SUBTITLE">
    </mc-wizard-step-header>
    <form
        [formGroup]="form"
        class="form-horizontal"
        mcDefaultLabelClass="col-sm-3"
        mcDefaultControlContainerClass="col-sm-9"
    >    
       <mc-field 
            label="$I18N_ONEDRIVE_BACKUP_WIZARD.STEPS.EXCLUDED_FILES.FORM.FILE_EXTENSIONS_LABEL"
            helpPopoverContent="{{ '$I18N_ONEDRIVE_BACKUP_WIZARD.STEPS.EXCLUDED_FILES.FORM.FILE_EXTENSIONS_TOOLTIP' | translate}}"
            useTranslation="true"
        >
            <mc-tag-selector
                formControlName="fileExtensions"
                [startingTags]="excludedExtensions"
                (onTagsChange)="onTagsChange($event)"
                (onInputChange)="onInputChange($event)"
                [maxTags]="maxTags"
                [maxLength]="maxLength"
                [validInput]="formValid"
                placeholder="{{ '$I18N_ONEDRIVE_BACKUP_WIZARD.STEPS.EXCLUDED_FILES.FORM.FILE_EXTENSIONS_PLACEHOLDER' | translate}}"
                newTagLabel="{{ '$I18N_ONEDRIVE_BACKUP_WIZARD.STEPS.EXCLUDED_FILES.FORM.FILE_EXTENSION_NEW_TAG_LABEL' | translate}}"
            ></mc-tag-selector>
            <mc-errors *ngIf="!formValid" [errors]="formErrors"
            formPrefix="$I18N_ONEDRIVE_BACKUP_WIZARD.STEPS.EXCLUDED_FILES.FORM.VALIDATION">
            </mc-errors>
            <span class="mc-text feature-title-extra">
                <i class="fas fa-info-circle"
                ></i>
                <span translate="$I18N_ONEDRIVE_BACKUP_WIZARD.STEPS.EXCLUDED_FILES.FORM.FILE_EXTENSIONS_INFO"></span>
            </span>
        </mc-field>
    </form>
</div>