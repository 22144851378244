<mc-table [isLoading]="false" [columns]="columns" [data]="data">

  <mc-column key="validity">
    <mc-header-cell *mcHeaderCellDef></mc-header-cell>
    <mc-body-cell *mcBodyCellDef="let row">
      <i [ngClass]="isValid ? 'far fa-check text-success':'far fa-times text-danger'" aria-hidden="true"></i>
    </mc-body-cell>
  </mc-column>

  <mc-column key="domainName">
    <mc-header-cell *mcHeaderCellDef></mc-header-cell>
  </mc-column>

  <mc-column key="errorMessage">
    <mc-header-cell *mcHeaderCellDef></mc-header-cell>
    <mc-body-cell *mcBodyCellDef>
      <span *ngIf="!isValid"
        class="text-danger">{{"$I18N_MONITORED_DOMAINS_VALIDATION_TABLE.ERROR.INVALID_DOMAIN" | translate}}</span>
    </mc-body-cell>
  </mc-column>

  <mc-column key="delete-column">
    <mc-header-cell *mcHeaderCellDef></mc-header-cell>
    <mc-body-cell *mcBodyCellDef="let row">
      <i role="button" class="far fa-trash-alt" aria-hidden="true"
        (click)="removeItem.emit({ domainName:row.domainName })"></i>
    </mc-body-cell>
  </mc-column>

</mc-table>