"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubmitMaliciousUrlDescPipe = void 0;
class SubmitMaliciousUrlDescPipe {
    constructor(translateService) {
        this.translateService = translateService;
    }
    transform(submitUrlEnabled, iocOutboundAction, iocOutbountSeverity, iocOutboundExpiration) {
        if (submitUrlEnabled && iocOutboundAction && iocOutbountSeverity && iocOutboundExpiration) {
            return (this.transformSubmitUrlFlag(submitUrlEnabled) +
                ', ' +
                this.transformOutboundAction(iocOutboundAction) +
                ', ' +
                this.transformOutboundSeverity(iocOutbountSeverity) +
                ', ' +
                this.transformOutboundExpiration(iocOutboundExpiration));
        }
        return this.transformSubmitUrlFlag(submitUrlEnabled);
    }
    transformSubmitUrlFlag(submitUrlEnabled) {
        if (submitUrlEnabled) {
            return this.translateService.instant('$I18N_CROWDSTRIKE_INTEGRATION_SUBMIT_MALICIOUS_URL_PIPE.FLAG.ENABLED');
        }
        return this.translateService.instant('$I18N_CROWDSTRIKE_INTEGRATION_SUBMIT_MALICIOUS_URL_PIPE.FLAG.DISABLED');
    }
    transformOutboundAction(iocOutboundAction) {
        if (iocOutboundAction) {
            return this.translateService.instant('$I18N_CROWDSTRIKE_INTEGRATION_SUBMIT_MALICIOUS_URL_PIPE.ACTION.' + iocOutboundAction);
        }
        return this.translateService.instant('$I18N_CROWDSTRIKE_INTEGRATION_SUBMIT_MALICIOUS_URL_PIPE.ACTION.OTHER');
    }
    transformOutboundSeverity(iocOutbountSeverity) {
        if (iocOutbountSeverity) {
            return this.translateService.instant('$I18N_CROWDSTRIKE_INTEGRATION_SUBMIT_MALICIOUS_URL_PIPE.SEVERITY.' + iocOutbountSeverity);
        }
        return this.translateService.instant('$I18N_CROWDSTRIKE_INTEGRATION_SUBMIT_MALICIOUS_URL_PIPE.SEVERITY.OTHER');
    }
    transformOutboundExpiration(iocOutboundExpiration) {
        if (iocOutboundExpiration) {
            return this.translateService.instant('$I18N_CROWDSTRIKE_INTEGRATION_SUBMIT_MALICIOUS_URL_PIPE.EXPIRATION.' +
                iocOutboundExpiration);
        }
        return this.translateService.instant('$I18N_CROWDSTRIKE_INTEGRATION_SUBMIT_MALICIOUS_URL_PIPE.EXPIRATION.NO_EXPIRATION');
    }
}
exports.SubmitMaliciousUrlDescPipe = SubmitMaliciousUrlDescPipe;
