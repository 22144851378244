<mc-api-gateway-v3-wizard
  [applicationIdentifier]="applicationIdentifier$ | async"
  [applicationDetails]="applicationDetails$ | async"
  [applicationSettings]="applicationSettings$ | async"
  [applicationStatus]="applicationStatus$ | async"
  [application]="application$ | async"
  [mode]="mode$ | async"
  [applicationNameDisabled]="applicationNameDisabled$ | async"
  (onApplicationAcceptTermsUpdated)="updateAcceptTerms($event)"
  (onApplicationDetailsUpdated)="updateDetails($event)"
  (onApplicationSettingsUpdated)="updateSettings($event)"
  (onApplicationStatusUpdated)="updateStatus($event)"
  (onApplicationSaved)="save($event)"
  (onCancel)="close()"
>
</mc-api-gateway-v3-wizard>
