"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MepEnableConfirmationModalComponent = void 0;
const reducers = require("../../../reducers");
const policy_actions_1 = require("../../../actions/policy.actions");
const sidebar_actions_1 = require("../../../actions/sidebar.actions");
class MepEnableConfirmationModalComponent {
    constructor(store) {
        this.store = store;
        this.header = '';
        this.actionButton = '';
    }
    ngOnInit() {
        this.policy$ = this.store.select(reducers.getMepPolicy);
        this.updating$ = this.store.select(reducers.getLoadingForEdit);
        this.getHeader();
    }
    onDisable() {
        this.store.dispatch(new policy_actions_1.MepSaveStatusPolicy());
    }
    getHeader() {
        this.policy$.subscribe(data => {
            if (data.enabled) {
                this.header = '$I18N_MEP_POLICY_ENABLE_CONFIRM_HEADER';
                this.actionButton = '$I18N_COMMON_TEXT_ENABLE';
            }
            else {
                this.header = '$I18N_MEP_POLICY_DISABLE_CONFIRM_HEADER';
                this.actionButton = '$I18N_COMMON_BTN_DISABLE';
            }
        });
    }
    onCancel() {
        this.store.dispatch(new sidebar_actions_1.MepPolicyEnableConfirmModalClose(true));
    }
}
exports.MepEnableConfirmationModalComponent = MepEnableConfirmationModalComponent;
