"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewImSyncTaskComponent = void 0;
const core_1 = require("@angular/core");
const im_task_1 = require("../../model/im-task");
const moment = require("moment");
class ViewImSyncTaskComponent {
    constructor(overlayRef, translateService) {
        this.overlayRef = overlayRef;
        this.translateService = translateService;
        this.updateTask = new core_1.EventEmitter();
    }
    onClose() {
        this.overlayRef.close();
    }
    getSyncTime() {
        if (this.executionSummary) {
            const oneMinute = 1;
            const oneHourInMinutes = 60;
            const oneDayInMinutes = 1440;
            const lastExecutionStart = moment(this.executionSummary.lastExecutionStart);
            const lastExecutionEnd = moment(this.executionSummary.lastExecutionEnd);
            const syncTime = moment.duration(lastExecutionEnd.diff(lastExecutionStart));
            if (syncTime.asMinutes() > oneHourInMinutes && syncTime.asMinutes() < oneDayInMinutes) {
                return syncTime.hours() + 'h ' + syncTime.minutes() + 'm';
            }
            else if (syncTime.asMinutes() > oneMinute && syncTime.asMinutes() <= oneHourInMinutes) {
                return syncTime.minutes() + 'm';
            }
            else if (syncTime.asMinutes() >= oneDayInMinutes) {
                return syncTime.days() + 'd ' + syncTime.hours() + 'h ' + syncTime.minutes() + 'm';
            }
            else if (syncTime.asMinutes() < oneMinute) {
                return this.translateService.instant('$I18N_SERVICES_IM_SYNC_COMPONENTS_IM_SYNC_TABLE.VIEW_TASK.LESS_THAN_A_MINUTE');
            }
        }
    }
    updateImSyncTask() {
        this.onClose();
        this.updateTask.emit();
    }
    isTaskLegacy(taskType) {
        return taskType === im_task_1.ImTaskType.LEGACY_INGESTION;
    }
}
exports.ViewImSyncTaskComponent = ViewImSyncTaskComponent;
