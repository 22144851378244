"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOneDriveConnectors = exports.isLoadingConnectors = exports.reducer = void 0;
const connector_actions_1 = require("../actions/connector.actions");
const initialState = {
    isLoadingConnectors: false,
    oneDriveConnectors: []
};
function reducer(state = initialState, action) {
    switch (action.type) {
        case connector_actions_1.ConnectorActions.LOAD_ONEDRIVE_CONNECTORS:
            return Object.assign(Object.assign({}, state), { isLoadingConnectors: true });
        case connector_actions_1.ConnectorActions.LOAD_ONEDRIVE_CONNECTORS_SUCCESS:
            return Object.assign(Object.assign({}, state), { isLoadingConnectors: false, oneDriveConnectors: action.payload });
        case connector_actions_1.ConnectorActions.LOAD_ONEDRIVE_CONNECTORS_FAIL:
            return Object.assign(Object.assign({}, state), { isLoadingConnectors: false });
        default:
            return state;
    }
}
exports.reducer = reducer;
const isLoadingConnectors = (state) => state && state.isLoadingConnectors;
exports.isLoadingConnectors = isLoadingConnectors;
const getOneDriveConnectors = (state) => state && state.oneDriveConnectors;
exports.getOneDriveConnectors = getOneDriveConnectors;
