"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TopApplicationsEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const application_actions_1 = require("../actions/application.actions");
const notification_actions_1 = require("../../../components/notification/actions/notification.actions");
const applications_actions_1 = require("../../policies/actions/applications.actions");
const reducers = require("../reducers");
class TopApplicationsEffects {
    constructor(actions$, store, translationService, swgPolicyService) {
        this.actions$ = actions$;
        this.store = store;
        this.translationService = translationService;
        this.swgPolicyService = swgPolicyService;
        this.resultAction = this.actions$.pipe(effects_1.ofType(application_actions_1.SwgLoadTopApplicationsActionsEnum.GET_CHART_INFO), operators_1.switchMap((action) => {
            const topApplicatons$ = this.actions$.pipe(effects_1.ofType(application_actions_1.SwgLoadTopApplicationsActionsEnum.LOAD_TOP_APPLICATIONS_SUCCESS), operators_1.filter((t) => t.correlationId === action.correlationId), operators_1.first());
            const topBlockedApplications$ = this.actions$.pipe(effects_1.ofType(application_actions_1.SwgLoadTopApplicationsActionsEnum.LOAD_TOP_BLOCKED_APPLICATIONS_SUCCESS), operators_1.filter((t) => t.correlationId === action.correlationId), operators_1.first());
            const applicationStatus$ = this.actions$.pipe(effects_1.ofType(application_actions_1.SwgLoadTopApplicationsActionsEnum.LOAD_APPLICATION_STATUS_SUCCESS), operators_1.filter((t) => t.correlationId === action.correlationId), operators_1.first());
            const allApplications$ = this.actions$.pipe(effects_1.ofType(applications_actions_1.SWG_POLICY_LOAD_APPLICATIONS_SUCCESS));
            return rxjs_1.zip(topApplicatons$, topBlockedApplications$, applicationStatus$, allApplications$).pipe(operators_1.map((zipped) => {
                const topApplications = zipped[0].payload.topApplications;
                const topBlockedApplications = zipped[1].payload.topApplications;
                const applicationStatus = zipped[2].payload.applicationStatus.filter(x => x);
                const applications = zipped[3].payload;
                const data = {};
                const allApplications = this.getApplicationWithStatusAndCount(applications, topApplications, applicationStatus);
                const applicationCategories = [];
                allApplications.forEach(x => {
                    applicationCategories.push(...x.category);
                });
                const applicationCategoriesSet = Array.from(new Set(applicationCategories)).sort();
                const sortByCountAndName = (item1, item2) => item1.count === item2.count
                    ? item1.code > item2.code
                        ? -1
                        : 1
                    : item1.count > item2.count
                        ? -1
                        : 1;
                const reverseSortedArray = (item1, item2) => item1.count > item2.count ? 1 : -1;
                const allSanctionedApps = allApplications
                    .filter(app => app.status === 'sanctioned')
                    .filter(app => app.count && app.count > 0)
                    .sort(sortByCountAndName);
                const sanctionedAppsCount = applicationStatus.filter(app => app.status === 'sanctioned')
                    .length;
                const topSanctionedApps = allSanctionedApps.slice(0, 10).sort(reverseSortedArray);
                const allMonitoredApps = allApplications
                    .filter(app => app.status === 'monitored')
                    .filter(app => app.count && app.count > 0)
                    .sort(sortByCountAndName);
                const monitoredAppsCount = applicationStatus.filter(app => app.status === 'monitored')
                    .length;
                const topMonitoredApps = allMonitoredApps.slice(0, 10).sort(reverseSortedArray);
                const allNonSanctionedApps = allApplications
                    .filter(app => app.status !== 'monitored' && app.status !== 'sanctioned')
                    .filter(app => app.count && app.count > 0)
                    .sort(sortByCountAndName);
                const nonSanctionedAppsCount = allApplications.length - (sanctionedAppsCount + monitoredAppsCount);
                const topNonSanctionedApps = allNonSanctionedApps.slice(0, 10).sort(reverseSortedArray);
                // get top categories
                // get distinct categories
                const allApplicationsWithCount = allApplications.filter(app => app.count && app.count > 0);
                const allCategoriesNames = allApplicationsWithCount
                    .map(item => item.category[0])
                    .filter((value, index, self) => self.indexOf(value) === index);
                // for each distinct category take sum of count from all applications count
                const allCategoriesCharData = allCategoriesNames.map(rule => {
                    const category = allApplicationsWithCount.filter(app => app.category[0] === rule);
                    const totalCount = category.reduce((prev, curr) => prev + curr.count, 0);
                    return {
                        name: this.translationService.instant(this.getTranslateKey(rule, 'category')),
                        code: rule,
                        count: totalCount
                    };
                });
                allCategoriesCharData.sort((item1, item2) => {
                    return item1.count > item2.count ? -1 : 1;
                });
                const topCategories = allCategoriesCharData
                    .slice(0, 10)
                    .sort((item1, item2) => {
                    return item1.count > item2.count ? 1 : -1;
                });
                // get top blocked applications
                const allBlockedApps = this.getBlockedAppsWithCountAndName(applications, topBlockedApplications);
                const topBlockedApps = allBlockedApps
                    .filter(app => app.count && app.count > 0)
                    .sort(sortByCountAndName)
                    .sort(reverseSortedArray);
                data.allApplications = allApplications;
                data.applicationCategoriesSet = applicationCategoriesSet;
                data.topSanctionedApps = topSanctionedApps;
                data.topNonSanctionedApps = topNonSanctionedApps;
                data.topMonitoredApps = topMonitoredApps;
                data.topBlockedApps = topBlockedApps;
                data.topCategories = topCategories;
                data.sanctionedAppsCount = sanctionedAppsCount;
                data.nonSanctionedAppsCount = nonSanctionedAppsCount;
                data.monitoredAppsCount = monitoredAppsCount;
                return new application_actions_1.SwgLoadChartDataSuccessAction(data);
            }));
        }));
        this.getApplicationChartData$ = this.actions$.pipe(effects_1.ofType(application_actions_1.SwgLoadTopApplicationsActionsEnum.GET_CHART_INFO), operators_1.flatMap((action) => {
            return [
                new application_actions_1.SwgLoadTopApplicationsAction(action.dateRange, action.correlationId),
                new application_actions_1.SwgLoadTopBlockedApplicationsAction(action.dateRange, action.correlationId),
                new application_actions_1.SwgLoadApplicationStatusAction(action.correlationId),
                new applications_actions_1.SwgPolicyLoadApplications()
            ];
        }));
        this.getTopApplications$ = this.actions$.pipe(effects_1.ofType(application_actions_1.SwgLoadTopApplicationsActionsEnum.LOAD_TOP_APPLICATIONS), operators_1.switchMap((action) => {
            return this.swgPolicyService.getTopApplications(action.dateRange).pipe(operators_1.map((response) => {
                return new application_actions_1.SwgLoadTopApplicationsSuccessAction(response, action.correlationId);
            }), operators_1.catchError(error => rxjs_1.merge(rxjs_1.of(this.getNotificationAction(error)), rxjs_1.of(new application_actions_1.SwgLoadTopApplicationsFailureAction(action.correlationId)))));
        }));
        this.getTopBlockedApplications$ = this.actions$.pipe(effects_1.ofType(application_actions_1.SwgLoadTopApplicationsActionsEnum.LOAD_TOP_BLOCKED_APPLICATIONS), operators_1.switchMap((action) => {
            return this.swgPolicyService.getTopBlockedApplications(action.dateRange).pipe(operators_1.map((response) => {
                return new application_actions_1.SwgLoadTopBlockedApplicationsSuccessAction(response, action.correlationId);
            }), operators_1.catchError(error => rxjs_1.merge(rxjs_1.of(this.getNotificationAction(error)), rxjs_1.of(new application_actions_1.SwgLoadTopBlockedApplicationsFailureAction(action.correlationId)))));
        }));
        this.getTopProfiles$ = this.actions$.pipe(effects_1.ofType(application_actions_1.SwgLoadTopApplicationsActionsEnum.LOAD_TOP_PROFILES), operators_1.switchMap((action) => {
            return this.swgPolicyService.getTopProfiles(action.dateRange).pipe(operators_1.map((response) => {
                return new application_actions_1.SwgLoadTopProfilesSuccessAction(response);
            }), operators_1.catchError(error => rxjs_1.merge(rxjs_1.of(this.getNotificationAction(error)), rxjs_1.of(new application_actions_1.SwgLoadTopProfilesFailureAction()))));
        }));
        this.getApplicationStatus$ = this.actions$.pipe(effects_1.ofType(application_actions_1.SwgLoadTopApplicationsActionsEnum.LOAD_APPLICATION_STATUS), operators_1.switchMap((action) => {
            return this.swgPolicyService.getApplicationStatus().pipe(operators_1.map((response) => {
                return new application_actions_1.SwgLoadApplicationStatusSuccessAction(response, action.correlationId);
            }), operators_1.catchError(error => rxjs_1.merge(rxjs_1.of(this.getNotificationAction(error)), rxjs_1.of(new application_actions_1.SwgLoadApplicationStatusFailureAction(action.correlationId)))));
        }));
        this.updateApplicationStatus$ = this.actions$.pipe(effects_1.ofType(application_actions_1.SwgLoadTopApplicationsActionsEnum.UPDATE_APPLICATION_STATUS), operators_1.map((action) => action.payload), operators_1.withLatestFrom(this.store.select(reducers.getAllApplications)), operators_1.switchMap(([data, applications]) => this.swgPolicyService.updateApplicationStatus(data, applications).pipe(operators_1.map(retData => {
            if (retData.length === 0) {
                retData = data.map(x => {
                    return { appCode: x.appCode };
                });
            }
            return new application_actions_1.SwgUpdateApplicationStatusSuccess(retData);
        }), operators_1.catchError(error => rxjs_1.merge(rxjs_1.of(this.getNotificationAction(error)), rxjs_1.of(new application_actions_1.SwgUpdateApplicationStatusFailure()))))));
    }
    getNotificationAction(error) {
        const msg = error.fail
            ? error.fail[0].errors[0].message
            : this.translationService.instant('$I18N_SWG_CREATE_POLICY_SERVER_ERROR');
        return new notification_actions_1.NotificationShowAction({
            type: 'error',
            config: {
                msg
            }
        });
    }
    getTranslateKey(key, container) {
        const keys = [
            '$18N_SWG_REPORTING',
            'TABLE',
            'FILTERS',
            'COLUMN_VALUE_FILTER_FIELD',
            container && `${container.toUpperCase()}_FILTERS`,
            key && key.toUpperCase()
        ];
        return keys.filter(n => n).join('.');
    }
    getApplicationWithStatusAndCount(applications, topApplications, applicationsStatus) {
        let allApplications;
        if (applications !== undefined && applications.length > 0) {
            allApplications = applications.map(x => {
                return Object.assign({}, x);
            });
        }
        // combine top applications
        if (topApplications !== undefined && topApplications.length > 0) {
            allApplications = allApplications.map(rule => {
                const topApplicationSettings = topApplications.find(topApplication => topApplication.appCode === rule.code);
                return Object.assign(Object.assign({}, rule), topApplicationSettings);
                return rule;
            });
        }
        // combine applications status
        if (applicationsStatus !== undefined && applicationsStatus.length > 0) {
            allApplications = allApplications.map(rule => {
                const applicationStatusSettings = applicationsStatus.find(status => status.appCode === rule.code);
                return Object.assign(Object.assign({}, rule), applicationStatusSettings);
                return rule;
            });
        }
        return allApplications;
    }
    getBlockedAppsWithCountAndName(applications, topBlockedApplications) {
        let topBlockedApps;
        if (applications !== undefined && applications.length > 0) {
            topBlockedApps = topBlockedApplications.map(rule => {
                const topApplicationSettings = applications.find(topApplication => topApplication.code === rule.appCode);
                return Object.assign(Object.assign({}, rule), topApplicationSettings);
            });
        }
        return topBlockedApps;
    }
}
__decorate([
    effects_1.Effect()
], TopApplicationsEffects.prototype, "resultAction", void 0);
__decorate([
    effects_1.Effect()
], TopApplicationsEffects.prototype, "getApplicationChartData$", void 0);
__decorate([
    effects_1.Effect()
], TopApplicationsEffects.prototype, "getTopApplications$", void 0);
__decorate([
    effects_1.Effect()
], TopApplicationsEffects.prototype, "getTopBlockedApplications$", void 0);
__decorate([
    effects_1.Effect()
], TopApplicationsEffects.prototype, "getTopProfiles$", void 0);
__decorate([
    effects_1.Effect()
], TopApplicationsEffects.prototype, "getApplicationStatus$", void 0);
__decorate([
    effects_1.Effect()
], TopApplicationsEffects.prototype, "updateApplicationStatus$", void 0);
exports.TopApplicationsEffects = TopApplicationsEffects;
