"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiGatewayApplicationsTableComponent = void 0;
const core_1 = require("@angular/core");
const applications_table_static_values_1 = require("./applications-table.static-values");
class ApiGatewayApplicationsTableComponent {
    constructor() {
        this.columnsAlwaysVisible = applications_table_static_values_1.columnsAlwaysVisible;
        this.columnsStartHidden = applications_table_static_values_1.columnsStartHidden;
        this.onPageChanged = new core_1.EventEmitter();
        this.onSearch = new core_1.EventEmitter();
        this.onViewApplication = new core_1.EventEmitter();
        this.onCreateApplication = new core_1.EventEmitter();
        this.onEditApplication = new core_1.EventEmitter();
        this.onDeleteApplication = new core_1.EventEmitter();
        this.onEnableApplication = new core_1.EventEmitter();
        this.onDisableApplication = new core_1.EventEmitter();
        this.onGenerateKeys = new core_1.EventEmitter();
        this.onGetCredentials = new core_1.EventEmitter();
    }
    ngOnInit() { }
    changePage(page) {
        this.onPageChanged.emit(page);
    }
    search(search) {
        this.onSearch.emit(search);
    }
    viewApplication(app) {
        this.onViewApplication.emit(app);
    }
    createApplication() {
        this.onCreateApplication.emit();
    }
    editApplication(app) {
        this.onEditApplication.emit(app);
    }
    deleteApplication(app) {
        this.onDeleteApplication.emit(app);
    }
    enableApplication(app) {
        this.onEnableApplication.emit(app);
    }
    disableApplication(app) {
        this.onDisableApplication.emit(app);
    }
    generateKeys(app) {
        this.onGenerateKeys.emit(app);
    }
    getCredentials(app) {
        this.onGetCredentials.emit(app);
    }
    isGatewayApplication(app) {
        return !!app.usage && app.usage === 'gateway';
    }
}
exports.ApiGatewayApplicationsTableComponent = ApiGatewayApplicationsTableComponent;
