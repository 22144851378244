"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProgressbarComponent = void 0;
class ProgressbarComponent {
    get progress() {
        if (this.current && this.max && this.current <= this.max) {
            return { width: `${Math.floor((this.current / this.max) * 100)}%` };
        }
        else {
            return { width: 0 };
        }
    }
}
exports.ProgressbarComponent = ProgressbarComponent;
