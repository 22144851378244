<mc-layout-aside-extra-container keyTitle="$I18N_AP_VIEW_POLICY_DETAILS_SIDEPANEL.$I18N_AP_POLICY_DETAILS_SIDEBAR.TITLE"
                                 showClose="true" (closeAside)="close()"
                                 [isLoading]="isLoading$ | async">
  <mc-extra-container>
    <div  *ngIf="policyDetail; let row">
      <h3>{{ policyDetail.description }}</h3>
      <button  type="button" (click)="editDefinition(row)"   class="btn btn-primary" >{{ '$I18N_COMMON_BTN_EDIT' | translate }}
      </button>
      <button type="button" (click)="deleteDefinition(row)" class="btn btn-primary" >{{ '$I18N_COMMON_BTN_DELETE' | translate }}
      </button>
    </div>
  </mc-extra-container>
  <mc-body-container>
    <mc-ap-side-panel-view-details *ngIf="policyDetail"  [hidePolicyConflict]='isPolicyDetailVisible' [policy]="policyDetail"></mc-ap-side-panel-view-details>
  </mc-body-container>
</mc-layout-aside-extra-container>
