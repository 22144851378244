<mc-monitored-external-domain-list
  [isSelectedAll]="(isSelectedAll$ | async)"
  [allowDelete]="(allowDelete$ | async)"
  [selectedRow] ="(selectedRow$ | async)"
  (selectAll) = "onSelectAll($event)"
  (unSelectAll) ="onUnSelectAll()"
  (selectSingleItem) = "onSelectSingleItem($event)"
  (rowClick)="onRowClick($event)"
  (addDomains)="onAddDomains()"
  (deleteItem) = "onDeleteItem($event)"
  (deleteItems) = "onDeleteItems()"
  [isDeleting]="(isDeleting$ | async)"
  [externalTableSearchCount]="externalTableSearchCount$ | async">
</mc-monitored-external-domain-list>
