<div class="report-downloader-form">
  <form
    [formGroup]="form"
    class="form-horizontal"
    mcDefaultLabelClass="col-sm-3"
    mcDefaultControlContainerClass="col-sm-9"
  >
    <mc-date-range-picker
      [label]="dateLabel"
      formControlName="dateRange"
      [config]="dateRangePickerConfig"
      [horizontal]="true"
      [errorPrefix]="errorPrefix"
      [hideTime]="true"
    >
    </mc-date-range-picker>

    <div class="reporttype">
      <mc-field [label]="typeLabel" [errorPrefix]="errorPrefix">
        <mc-select
          formControlName="reportType"
          [options]="reportTypes"
          useTranslation="true"
          class="form-control error"
        >
        </mc-select>
      </mc-field>
    </div>

    <div *ngIf="infoMessage">
      <div class="col-sm-3"></div>
      <div class="col-sm-9">
        <mc-inline-notification [notification]="infoMessage"></mc-inline-notification>
      </div>
    </div>

    <div class="actions">
      <button
        type="button"
        class="btn btn-primary download"
        [disabled]="!form.valid || downloading"
        (click)="confirmDownload()"
      >
        <mc-spinner inverted="true" *ngIf="downloading"></mc-spinner>
        {{ downloadBtnLabel }}
      </button>
    </div>
  </form>
</div>
