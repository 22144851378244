"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ5 = exports.ɵ4 = exports.ɵ3 = exports.ɵ2 = exports.ɵ1 = exports.ɵ0 = exports.getServerConnection = exports.getOutboundAdministratorGroup = exports.getJournalAdministratorGroup = exports.getAdministratorGroups = exports.getEditDefinitionId = exports.getIsDuplicateData = exports.getSelectedRowFullData = exports.getDefinitionStatusChangeAction = exports.getAttachmentProtectionDefinitionSelectedRowFullData = exports.getDefinitionData = exports.selectedRowFullDataReducer = exports.getLoadingState = exports.getCreateDefinition = exports.getDefinitionFeatureState = exports.reducers = void 0;
const store_1 = require("@ngrx/store");
const createDefinition = require("./create-definition.reducer");
const listReducer = require("./definitions.reducer");
exports.reducers = {
    attachmentProtectionCreateDefinition: createDefinition.reducer
};
exports.getDefinitionFeatureState = store_1.createFeatureSelector('attachmentProtectionCreateDefinition');
const ɵ0 = state => state.attachmentProtectionCreateDefinition;
exports.ɵ0 = ɵ0;
exports.getCreateDefinition = store_1.createSelector(exports.getDefinitionFeatureState, ɵ0);
const ɵ1 = state => state.isLoading;
exports.ɵ1 = ɵ1;
exports.getLoadingState = store_1.createSelector(exports.getCreateDefinition, ɵ1);
exports.selectedRowFullDataReducer = {
    attachmentProtectionDefinitionSelectedRowFullData: listReducer.reducer
};
exports.getDefinitionData = store_1.createFeatureSelector('attachmentProtectionDefinitionSelectedRowFullData');
const ɵ2 = state => state.attachmentProtectionDefinitionSelectedRowFullData;
exports.ɵ2 = ɵ2;
exports.getAttachmentProtectionDefinitionSelectedRowFullData = store_1.createSelector(exports.getDefinitionData, ɵ2);
const ɵ3 = state => state.definitionStatusChanged;
exports.ɵ3 = ɵ3;
exports.getDefinitionStatusChangeAction = store_1.createSelector(exports.getAttachmentProtectionDefinitionSelectedRowFullData, ɵ3);
const ɵ4 = state => state.selectedRowFullData;
exports.ɵ4 = ɵ4;
exports.getSelectedRowFullData = store_1.createSelector(exports.getAttachmentProtectionDefinitionSelectedRowFullData, ɵ4);
const ɵ5 = state => state.isDuplicateData;
exports.ɵ5 = ɵ5;
exports.getIsDuplicateData = store_1.createSelector(exports.getAttachmentProtectionDefinitionSelectedRowFullData, ɵ5);
exports.getEditDefinitionId = store_1.createSelector(exports.getCreateDefinition, createDefinition.getEditDefinitionId);
exports.getAdministratorGroups = store_1.createSelector(exports.getCreateDefinition, createDefinition.getAdministratorGroups);
exports.getJournalAdministratorGroup = store_1.createSelector(exports.getCreateDefinition, createDefinition.getJournalAdministratorGroup);
exports.getOutboundAdministratorGroup = store_1.createSelector(exports.getCreateDefinition, createDefinition.getOutboundAdministratorGroup);
exports.getServerConnection = store_1.createSelector(exports.getCreateDefinition, createDefinition.getServerConnection);
