"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const url_tools_page_component_1 = require("./containers/url-tools-page.component");
angular
    .module('services.urlprotect-url-tools', [])
    .controller('UrlToolsPageComponent', [
    '$stateParams',
    '$scope',
    function ($stateParams, $scope) {
        $scope.stateParams = $stateParams;
    }
])
    .directive('mcUrlToolsPage', static_1.downgradeComponent({
    component: url_tools_page_component_1.UrlToolsPageComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider.state('urlprotect-url-tools', {
            url: '/services/targetedthreatprotection-urlprotect-url-tools',
            data: {
                tabTitle: '$I18N_SERVICES_URL_PROTECTION.URL_TOOLS.TAB_TITLE',
                isFixedLayout: false,
                checkProgress: false,
                capabilities: 'Permission.SERVICES_TTP_URL_PROTECT_READ || Permission.SERVICES_TTP_URL_PROTECT_EDIT',
                breadcrumbs: [
                    {
                        label: '$I18N_SERVICES_BREADCRUMBS_FIRST_LEVEL',
                        stateName: '',
                        stateParams: { tabName: 'overview' }
                    },
                    {
                        label: '$I18N_SERVICES_URL_PROTECTION.BREADCRUMBS.SECOND_LEVEL',
                        stateName: '',
                        stateParams: { tabName: 'overview' }
                    },
                    {
                        label: '$I18N_SERVICES_URL_PROTECTION.URL_TOOLS.BREADCRUMBS.THIRD_LEVEL',
                        stateName: '',
                        stateParams: { tabName: 'overview' }
                    }
                ]
            },
            views: {
                main: {
                    template: '<mc-url-tools-page></mc-url-tools-page>',
                    controller: 'UrlToolsPageComponent'
                }
            }
        });
    }
]);
