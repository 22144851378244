"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageProcessingPageComponent = void 0;
const actions = require("../actions");
class MessageProcessingPageComponent {
    constructor(store, storeBulk, storeSystem, tabService) {
        this.store = store;
        this.storeBulk = storeBulk;
        this.storeSystem = storeSystem;
        this.tabService = tabService;
        this.processingCaps = 'Temporary.Services.MessageCenter.Processing';
        this.noProcessingCaps = '!Temporary.Services.MessageCenter.Processing';
        this.systemCaps = 'Temporary.Services.MessageCenter.System';
        const tab = this.tabService.getSelectedTab();
        tab.onRefresh = () => {
            this.store.dispatch(new actions.MessageProcessingRefreshAction());
            this.storeBulk.dispatch(new actions.BulkProcessingRefreshAction());
            this.storeSystem.dispatch(new actions.SystemMessagesRefreshAction());
        };
    }
}
exports.MessageProcessingPageComponent = MessageProcessingPageComponent;
