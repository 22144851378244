<div class="no-content">
  <i
    aria-hidden="true"
    class="fal fa-list-alt"
  ></i>
  <h5>
    {{ '$I18N_SYNC_AND_RECOVER_OVERVIEW_MODAL.NO_DATA.MAIN_TEXT' | translate }}
  </h5>
  <p>
    {{ '$I18N_SYNC_AND_RECOVER_OVERVIEW_MODAL.NO_DATA.SUB_TEXT' | translate }}
  </p>
</div>
