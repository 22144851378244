"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArchiveViewerComponentsModule = void 0;
const common_1 = require("@angular/common");
const forms_1 = require("@angular/forms");
const platform_browser_1 = require("@angular/platform-browser");
const core_1 = require("@ngx-translate/core");
const tabs_1 = require("app-new/components/tabs/");
const components_1 = require("@mimecast-ui/components");
const accordion_module_1 = require("../../components/accordion/accordion.module");
const input_dropdown_module_1 = require("../../components/input-dropdown/input-dropdown.module");
const layouts_1 = require("../../components/layouts");
const live_button_module_1 = require("../../components/live-button/live-button.module");
const search_module_1 = require("../../components/search/search.module");
const spinner_module_1 = require("../../components/spinner/spinner.module");
const archive_viewer_slider_component_1 = require("../containers/archive-viewer-slider/archive-viewer-slider.component");
const archive_viewer_component_1 = require("./archive-viewer/archive-viewer.component");
const download_xml_component_1 = require("./download-xml/download-xml.component");
const search_by_content_tab_wrapper_component_1 = require("./search-by-content-tab-wrapper/search-by-content-tab-wrapper.component");
const search_by_token_tab_wrapper_component_1 = require("./search-by-token-tab-wrapper/search-by-token-tab-wrapper.component");
const shared_module_1 = require("app-new/shared.module");
const MODULES = [
    shared_module_1.SharedModule,
    forms_1.ReactiveFormsModule,
    search_module_1.SearchModule,
    components_1.FieldModule,
    components_1.TextFieldModule,
    components_1.TextAreaFieldModule,
    accordion_module_1.AccordionModule,
    spinner_module_1.SpinnerModule,
    live_button_module_1.LiveButtonModule,
    core_1.TranslateModule,
    tabs_1.TabsModule,
    common_1.CommonModule,
    input_dropdown_module_1.InputDropdownModule,
    components_1.DateRangePickerModule,
    layouts_1.LayoutListTabsModule,
    layouts_1.LayoutListTableModule,
    layouts_1.LayoutAsideExtraContainerModule,
    layouts_1.LayoutAsideSimpleModule,
    platform_browser_1.BrowserModule,
    components_1.ToBytesPipeModule
];
const CUSTOM = [
    archive_viewer_component_1.ArchiveViewerComponent,
    download_xml_component_1.DownloadXmlComponent,
    archive_viewer_slider_component_1.ArchiveViewerSliderComponent,
    search_by_content_tab_wrapper_component_1.SearchByContentTabWrapperComponent,
    search_by_token_tab_wrapper_component_1.SearchByTokenTabWrapperComponent
];
class ArchiveViewerComponentsModule {
}
exports.ArchiveViewerComponentsModule = ArchiveViewerComponentsModule;
