"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplatePreviewsDirective = void 0;
class TemplatePreviewsDirective {
    constructor(translate) {
        this.translate = translate;
    }
    get title() {
        return (this.page && this.page.title) || this.translate.instant(this.defaultTitle);
    }
    get subTitle() {
        return (this.page && this.page.subtitle) || this.translate.instant(this.defaultSubTitle);
    }
}
exports.TemplatePreviewsDirective = TemplatePreviewsDirective;
