"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgPolicySafeSearchStepComponent = void 0;
const core_1 = require("@angular/core");
const operators_1 = require("rxjs/operators");
class SwgPolicySafeSearchStepComponent {
    constructor(fb) {
        this.fb = fb;
        this.updateSafeSearchSettings = new core_1.EventEmitter();
    }
    ngOnInit() {
        this.safeSearchSettingsForm = this.fb.group({
            google: this.safeSearchSettings.google,
            bing: this.safeSearchSettings.bing,
            youTube: this.safeSearchSettings.youTube
        });
        this.safeSearchSubscription = this.safeSearchSettingsForm.valueChanges
            .pipe(operators_1.debounceTime(200))
            .subscribe(() => {
            this.updateSettings();
        });
    }
    updateSettings() {
        this.updateSafeSearchSettings.emit(this.safeSearchSettingsForm.value);
    }
    isValid() {
        return true;
    }
    ngOnDestroy() {
        this.safeSearchSubscription.unsubscribe();
    }
}
exports.SwgPolicySafeSearchStepComponent = SwgPolicySafeSearchStepComponent;
