"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LexiconContentValidator = void 0;
class LexiconContentValidator {
    static validateMaxWords(max) {
        return (wordsControl) => {
            const words = wordsControl.value;
            this.wordsLength = LexiconContentValidator.splitWords(words).length;
            return this.wordsLength > max ? { maxNumber: true } : null;
        };
    }
    static validateMaxPhrases(max) {
        return (phrasesControl) => {
            const phrases = phrasesControl.value;
            this.phrasesLength = LexiconContentValidator.splitPhrases(phrases).length;
            return this.phrasesLength > max ? { maxNumber: true } : null;
        };
    }
    static validateMaxWordsAndPhrases(max, isAdvancedSearchType) {
        return (group) => {
            const words = group.get('words').value;
            const phrases = group.get('phrases').value;
            this.wordsLength = LexiconContentValidator.splitWords(words).length;
            this.phrasesLength = LexiconContentValidator.splitPhrases(phrases).length;
            const isGreaterThanMax = this.wordsLength + this.phrasesLength > max;
            return isGreaterThanMax && !isAdvancedSearchType ? { wordsAndPhrasesMaxNumber: true } : null;
        };
    }
    static splitWords(terms) {
        return LexiconContentValidator.splitSearchTerms(terms, /,|\n| /);
    }
    static splitPhrases(terms) {
        return LexiconContentValidator.splitSearchTerms(terms, /\n/);
    }
    static splitSearchTerms(terms, regex) {
        let splittedTerms = [];
        if (terms) {
            splittedTerms = [...terms.split(regex).filter(Boolean)];
        }
        return splittedTerms;
    }
}
exports.LexiconContentValidator = LexiconContentValidator;
