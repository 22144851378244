"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageFinderApiService = void 0;
const operators_1 = require("rxjs/operators");
const email_service_1 = require("app-new/core/services/email/email.service");
class MessageFinderApiService {
    constructor(http) {
        this.http = http;
    }
    getMessageInfo(id, loadContent) {
        return this.http
            .post('/api/message-finder/get-message-info', {
            id,
            stripImages: false,
            loadContent
        })
            .pipe(operators_1.map(response => response.first));
    }
    releaseHeldEmail(id) {
        return this.http
            .post('/api/message-finder/release-held-email', { id })
            .pipe(operators_1.map(response => response.first));
    }
    releaseHeldEmailToSandbox(id) {
        return this.http
            .post('/api/message-finder/release-held-email-to-sandbox', { id })
            .pipe(operators_1.map(response => response.first));
    }
    permitSenderHeldEmail(id) {
        return this.http
            .post('/api/message-finder/permit-sender-held-email', { id })
            .pipe(operators_1.map(response => response.first));
    }
    permitSenderRejectedEmail(id) {
        return this.http
            .post('/api/message-finder/permit-sender-rejected-email', { id })
            .pipe(operators_1.map(response => response.first));
    }
    rejectHeldEmail(request) {
        return this.http
            .post('/api/message-finder/reject-held-email', request)
            .pipe(operators_1.map(response => response.first));
    }
    rejectQueuedEmail(id) {
        return this.http
            .post('/api/message-finder/reject-queued-email', { id })
            .pipe(operators_1.map(response => response.first));
    }
    blockSenderHeldEmail(id) {
        return this.http
            .post('/api/message-finder/block-sender-held-email', { id })
            .pipe(operators_1.map(response => response.first));
    }
    blockSenderRejectedEmail(id) {
        return this.http
            .post('/api/message-finder/block-sender-rejected-email', { id })
            .pipe(operators_1.map(response => response.first));
    }
    retryDelivery(id) {
        return this.http
            .post('/api/message-finder/retry-delivery', { id })
            .pipe(operators_1.map(response => response.first));
    }
    bounceQueuedEmail(id) {
        return this.http
            .post('/api/message-finder/bounce-queued-email', { id })
            .pipe(operators_1.map(response => response.first));
    }
    recalculateDeliveryRoute(id) {
        return this.http
            .post('/api/message-finder/recalculate-delivery-route', { id })
            .pipe(operators_1.map(response => response.first));
    }
    turboReport(request) {
        return this.http
            .post('/api/archive/report-email', request)
            .pipe(operators_1.map((response) => response.first));
    }
    reportMessage(request) {
        return this.http
            .post('/api/message-finder/report-message', request)
            .pipe(operators_1.map((response) => response.first));
    }
    permitOrBlockSender(requests) {
        return this.http.post('/api/managedsender/permit-or-block-sender', requests).pipe(operators_1.map(response => response.all.map(r => {
            return this.transformPermitOrBlockedSenderResponse(r);
        })));
    }
    transformPermitOrBlockedSenderResponse(response) {
        return {
            id: response.id,
            action: response.type === 'Permit' ? 'permit' : 'block',
            sender: response.sender,
            to: response.to
        };
    }
    forwardEmail(email, userEmail) {
        const forward = email_service_1.transformEmail(email, userEmail);
        const ids = (email.attachments || [])
            .filter(att => !att.progress && att.progress !== 0)
            .map(att => att.id);
        forward.attachments = forward.attachments.filter(att => att.contentType !== 'message/rfc822');
        return this.http
            .post('/api/message-finder/forward-message', Object.assign(Object.assign({}, forward), { ids }))
            .pipe(operators_1.map((response) => response.first));
    }
}
exports.MessageFinderApiService = MessageFinderApiService;
