<mc-wizard-step-header [label]="getLabel()">
  <ng-container *ngIf="!isDisabled()">
    {{ '$I18N_SWG_CREATE_POLICY_CLP_APP_CONNECTOR_DESC_BEFORE_LINK' | translate }}
    <a (click)="createConnector()" class="btn-link cursor-pointer">
      {{ '$I18N_SWG_CREATE_POLICY_CLP_APP_CONNECTOR_DESC_LINK' | translate }}</a>
    {{ '$I18N_SWG_CREATE_POLICY_CLP_APP_CONNECTOR_DESC_AFTER_LINK' | translate }}
  </ng-container>
  <ng-container *ngIf="isDisabled()">
    {{ '$I18N_SWG_EDIT_POLICY_CLP_APP_CONNECTOR_BODY' | translate }}
  </ng-container>
</mc-wizard-step-header>
<form
  class="form-horizontal mc-clp-connectors"
  mcDefaultLabelClass="col-sm-3"
  mcDefaultControlContainerClass="col-sm-9"
  [formGroup]="connectorSelectorPage"
>
  <div *ngFor="let connector of getApplicableConnectors()">
    <div class="radio panel-half-padding-bottom mc-clp-connector">
      <label class="text-bold" [ngClass]="{ 'mc-disabled': isDisabled() }">
        <input type="radio" class="mc-radio-control" formControlName="connectorId" value="{{connector.id}}"/>
        {{connector.name}}
        <div>{{connector.description}}</div>
      </label>
    </div>
  </div>
</form>



