"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MaxEntriesPerLineValidator = void 0;
function isValidNumberOfLines(value, maxLines) {
    const lineItem = value.replace(/\r\n/g, '\n').split('\n');
    const totalNumberOfLines = lineItem.filter(item => {
        return item !== (undefined || null || '');
    }).length;
    return totalNumberOfLines <= maxLines
        ? null
        : {
            totalNumberOfLines,
            extraLines: totalNumberOfLines - maxLines
        };
}
function MaxEntriesPerLineValidator(maxLines) {
    return (control) => {
        const validationResult = isValidNumberOfLines(control.value, maxLines);
        return validationResult ? { INVALID_MAX_LINES: { validationResult } } : null;
    };
}
exports.MaxEntriesPerLineValidator = MaxEntriesPerLineValidator;
