"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MepTooltipComponent = void 0;
class MepTooltipComponent {
    ngOnInit() {
        this.inSidebar = !!this.inSidebar;
        switch (this.tooltipTitle) {
            case 'autoRelease':
                this.tooltipToShow = this.autoReleaseTooltip;
                break;
            case 'matchLevel':
                this.tooltipToShow = this.matchLevelTooltip;
                break;
            default:
                this.tooltipToShow = null;
                break;
        }
    }
}
exports.MepTooltipComponent = MepTooltipComponent;
