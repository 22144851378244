<form class="mc-filters-bundle-column-values-form" [formGroup]="form">
  <ng-container *ngIf="hasAllOption">
    <mc-table-filter-field-single-select-column-values
      class="mc-select-input"
      [translatePrefix]="translatePrefix"
      [filterModel]="sections"
      formControlName="selectValue">
    </mc-table-filter-field-single-select-column-values>  
  </ng-container>
  <ng-container *ngIf="!hasAllOption">
    <mc-table-filter-field-single-select-column-values-no-all-option
      class="mc-select-input"
      [translatePrefix]="translatePrefix"
      [filterModel]="sections"
      formControlName="selectValue">
    </mc-table-filter-field-single-select-column-values-no-all-option>
  </ng-container>
  <mc-table-filter-field-search
    class="mc-search-input"
    [translatePrefix]="translatePrefix"
    [placeHolder]="'$I18N_MESSAGE_CENTER_COMMON_SELECT_AND_SEARCH.SEARCH.PLACEHOLDER'"
    formControlName="searchQuery">
  </mc-table-filter-field-search>
</form>
