"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextBlockDisplayComponent = void 0;
const newlines = new RegExp(/[\r\n]+/, 'g');
class TextBlockDisplayComponent {
    get textlines() {
        if (!!this.textblock) {
            return this.textblock.split(newlines);
        }
        else {
            return [];
        }
    }
}
exports.TextBlockDisplayComponent = TextBlockDisplayComponent;
