"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HIGHLIGHT_SELECTED_ROW = exports.SHOW_MESSAGE_INFO = exports.SELECT_EMAIL = exports.RETRY_DELIVERY_FAIL = exports.RETRY_DELIVERY_SUCCESS = exports.RETRY_DELIVERY = exports.REPORT_MESSAGE_FAIL = exports.REPORT_MESSAGE_SUCCESS = exports.REPORT_MESSAGE = exports.REPORT_MESSAGE_CANCEL = exports.REPORT_MESSAGE_OPEN_MODAL = exports.RELEASE_HELD_EMAIL_FAIL = exports.RELEASE_HELD_EMAIL_SUCCESS = exports.RELEASE_HELD_EMAIL = exports.RELEASE_HELD_EMAIL_TO_SANDBOX_FAIL = exports.RELEASE_HELD_EMAIL_TO_SANDBOX_SUCCESS = exports.RELEASE_HELD_EMAIL_TO_SANDBOX = exports.REJECT_QUEUED_EMAIL_FAIL = exports.REJECT_QUEUED_EMAIL_SUCCESS = exports.REJECT_QUEUED_EMAIL = exports.REJECT_HELD_EMAIL_WITH_NARRATIVE_FAIL = exports.REJECT_HELD_EMAIL_WITH_NARRATIVE_SUCCESS = exports.REJECT_HELD_EMAIL_WITH_NARRATIVE = exports.REJECT_HELD_EMAIL_WITH_NOTIFICATION_FAIL = exports.REJECT_HELD_EMAIL_WITH_NOTIFICATION_SUCCESS = exports.REJECT_HELD_EMAIL_WITH_NOTIFICATION = exports.REJECT_HELD_EMAIL_FAIL = exports.REJECT_HELD_EMAIL_SUCCESS = exports.REJECT_HELD_EMAIL = exports.RECALCULATE_DELIVERY_ROUTE_FAIL = exports.RECALCULATE_DELIVERY_ROUTE_SUCCESS = exports.RECALCULATE_DELIVERY_ROUTE = exports.PERMIT_SENDER_FOR_RECIPIENT_FAIL = exports.PERMIT_SENDER_FOR_RECIPIENT_SUCCESS = exports.PERMIT_SENDER = exports.PERMIT_SENDER_REJECTED_EMAIL = exports.PERMIT_SENDER_HELD_EMAIL = exports.GET_MESSAGE_INFO_FAIL = exports.GET_MESSAGE_INFO_SUCCESS = exports.GET_MESSAGE_INFO = exports.BOUNCE_QUEUED_EMAIL_FAIL = exports.BOUNCE_QUEUED_EMAIL_SUCCESS = exports.BOUNCE_QUEUED_EMAIL = exports.BLOCK_SENDER_FOR_RECIPIENT_FAIL = exports.BLOCK_SENDER_FOR_RECIPIENT_SUCCESS = exports.BLOCK_SENDER = exports.BLOCK_SENDER_EMAIL_FAIL = exports.BLOCK_SENDER_EMAIL_SUCCESS = exports.BLOCK_SENDER_REJECTED_EMAIL = exports.BLOCK_SENDER_HELD_EMAIL = void 0;
exports.ReleaseHeldEmailAction = exports.ReleaseHeldEmailToSandboxFailAction = exports.ReleaseHeldEmailToSandboxSuccessAction = exports.ReleaseHeldEmailToSandboxAction = exports.RejectQueuedEmailFailAction = exports.RejectQueuedEmailSuccessAction = exports.RejectQueuedEmailAction = exports.RejectHeldEmailWithNarrativeFailAction = exports.RejectHeldEmailWithNarrativeSuccessAction = exports.RejectHeldEmailWithNarrativeAction = exports.RejectHeldEmailWithNotificationFailAction = exports.RejectHeldEmailWithNotificationSuccessAction = exports.RejectHeldEmailWithNotificationAction = exports.RejectHeldEmailFailAction = exports.RejectHeldEmailSuccessAction = exports.RejectHeldEmailAction = exports.RecalculateDeliveryRouteFailAction = exports.RecalculateDeliveryRouteSuccessAction = exports.RecalculateDeliveryRouteAction = exports.PermitSenderForRecipientFailAction = exports.PermitSenderForRecipientSuccessAction = exports.PermitSenderAction = exports.PermitSenderRejectedEmailAction = exports.PermitSenderHeldEmailAction = exports.GetMessageInfoFailAction = exports.GetMessageInfoSuccessAction = exports.GetMessageInfoAction = exports.CopyToClipboardSuccessAction = exports.ClearSelectedRowAction = exports.HighlightSelectedRowAction = exports.ShowMessageInfoAction = exports.BounceQueuedEmailFailAction = exports.BounceQueuedEmailSuccessAction = exports.BounceQueuedEmailAction = exports.BlockSenderForRecipientFailAction = exports.BlockSenderForRecipientSuccessAction = exports.BlockSenderAction = exports.BlockSenderEmailFailAction = exports.BlockSenderEmailSuccessAction = exports.BlockSenderRejectedEmailAction = exports.BlockSenderHeldEmailAction = exports.COMPARE_VIEWS = exports.PRINT_MESSAGE_FAIL = exports.PRINT_MESSAGE = exports.SHOW_HTML_UPDATE_FAIL = exports.SHOW_HTML_UPDATE = exports.DOWNLOAD_ALL_FILES = exports.DOWNLOAD_FILE = exports.COPY_TO_CLIPBOARD_SUCCESS = exports.CLEAR_SELECTED_ROW = void 0;
exports.CompareViewsAction = exports.PrintMessageFailAction = exports.PrintMessageAction = exports.ShowHtmlFailAction = exports.ShowHtmlAction = exports.DownloadAllFilesAction = exports.DownloadFileAction = exports.SelectEmailAction = exports.RetryDeliveryFailAction = exports.RetryDeliverySuccessAction = exports.RetryDeliveryAction = exports.ReportMessageAction = exports.ReportMessageOpenModalAction = exports.ReleaseHeldEmailFailAction = exports.ReleaseHeldEmailSuccessAction = void 0;
exports.BLOCK_SENDER_HELD_EMAIL = '[Message Center] Block Sender Held Email';
exports.BLOCK_SENDER_REJECTED_EMAIL = '[Message Center] Block Sender Rejected Email';
exports.BLOCK_SENDER_EMAIL_SUCCESS = '[Message Center] Block Sender Email Success';
exports.BLOCK_SENDER_EMAIL_FAIL = '[Message Center] Block Sender Email Fail';
exports.BLOCK_SENDER = '[Message Center] Block Sender';
exports.BLOCK_SENDER_FOR_RECIPIENT_SUCCESS = '[Message Center] Block Sender For Recipient Success';
exports.BLOCK_SENDER_FOR_RECIPIENT_FAIL = '[Message Center] Block Sender For Recipient Fail';
exports.BOUNCE_QUEUED_EMAIL = '[Message Center] Bounce Queued Email';
exports.BOUNCE_QUEUED_EMAIL_SUCCESS = '[Message Center] Bounce Queued Email Success';
exports.BOUNCE_QUEUED_EMAIL_FAIL = '[Message Center] Bounce Queued Email Fail';
exports.GET_MESSAGE_INFO = '[Message Center] Get Message Info';
exports.GET_MESSAGE_INFO_SUCCESS = '[Message Center] Get Message Info Success';
exports.GET_MESSAGE_INFO_FAIL = '[Message Center] Get Message Info Fail';
exports.PERMIT_SENDER_HELD_EMAIL = '[Message Center] Permit Sender Held Email';
exports.PERMIT_SENDER_REJECTED_EMAIL = '[Message Center] Permit Sender Rejected Email';
exports.PERMIT_SENDER = '[Message Center] Permit Sender';
exports.PERMIT_SENDER_FOR_RECIPIENT_SUCCESS = '[Message Center] Permit Sender For Recipient Success';
exports.PERMIT_SENDER_FOR_RECIPIENT_FAIL = '[Message Center] Permit Sender For Recipient Fail';
exports.RECALCULATE_DELIVERY_ROUTE = '[Message Center] Recalculate Delivery Route';
exports.RECALCULATE_DELIVERY_ROUTE_SUCCESS = '[Message Center] Recalculate Delivery Route Success';
exports.RECALCULATE_DELIVERY_ROUTE_FAIL = '[Message Center] Recalculate Delivery Route Fail';
exports.REJECT_HELD_EMAIL = '[Message Center] Reject Held Email';
exports.REJECT_HELD_EMAIL_SUCCESS = '[Message Center] Reject Held Email Success';
exports.REJECT_HELD_EMAIL_FAIL = '[Message Center] Reject Held Email Fail';
exports.REJECT_HELD_EMAIL_WITH_NOTIFICATION = '[Message Center] Reject Held Email with Notification';
exports.REJECT_HELD_EMAIL_WITH_NOTIFICATION_SUCCESS = '[Message Center] Reject Held Email with Notification Success';
exports.REJECT_HELD_EMAIL_WITH_NOTIFICATION_FAIL = '[Message Center] Reject Held Email with Notification Fail';
exports.REJECT_HELD_EMAIL_WITH_NARRATIVE = '[Message Center] Reject Held Email with Narrative';
exports.REJECT_HELD_EMAIL_WITH_NARRATIVE_SUCCESS = '[Message Center] Reject Held Email with Narrative Success';
exports.REJECT_HELD_EMAIL_WITH_NARRATIVE_FAIL = '[Message Center] Reject Held Email with Narrative Fail';
exports.REJECT_QUEUED_EMAIL = '[Message Center] Reject Queued Email';
exports.REJECT_QUEUED_EMAIL_SUCCESS = '[Message Center] Reject Queued Email Success';
exports.REJECT_QUEUED_EMAIL_FAIL = '[Message Center] Reject Queued Email Fail';
exports.RELEASE_HELD_EMAIL_TO_SANDBOX = '[Message Center] Release Held Email To Sandbox';
exports.RELEASE_HELD_EMAIL_TO_SANDBOX_SUCCESS = '[Message Center] Release Held Email To Sandbox Success';
exports.RELEASE_HELD_EMAIL_TO_SANDBOX_FAIL = '[Message Center] Release Held Email To Sandbox Fail';
exports.RELEASE_HELD_EMAIL = '[Message Center] Release Held Email';
exports.RELEASE_HELD_EMAIL_SUCCESS = '[Message Center] Release Held Email Success';
exports.RELEASE_HELD_EMAIL_FAIL = '[Message Center] Release Held Email Fail';
exports.REPORT_MESSAGE_OPEN_MODAL = '[Message Center] Report Message Open Modal';
exports.REPORT_MESSAGE_CANCEL = '[Message Center] Report Message Cancel';
exports.REPORT_MESSAGE = '[Message Center] Report Message';
exports.REPORT_MESSAGE_SUCCESS = '[Message Center] Report Message Success';
exports.REPORT_MESSAGE_FAIL = '[Message Center] Report Message Fail';
exports.RETRY_DELIVERY = '[Message Center] Retry Delivery';
exports.RETRY_DELIVERY_SUCCESS = '[Message Center] Retry Delivery Success';
exports.RETRY_DELIVERY_FAIL = '[Message Center] Retry Delivery Fail';
exports.SELECT_EMAIL = '[Message Center] Select Email';
exports.SHOW_MESSAGE_INFO = '[Message Center] Show Message Info';
exports.HIGHLIGHT_SELECTED_ROW = '[Message Center] Highlight Selected Row';
exports.CLEAR_SELECTED_ROW = '[Message Center] Clear Selected Row';
exports.COPY_TO_CLIPBOARD_SUCCESS = '[Message Center] Copy to Clipboard Success';
exports.DOWNLOAD_FILE = '[Message Center] Download File';
exports.DOWNLOAD_ALL_FILES = '[Message Center] Download All Files';
exports.SHOW_HTML_UPDATE = '[Message Center] Update showHtml';
exports.SHOW_HTML_UPDATE_FAIL = '[Message Center] Update showHtml fail';
exports.PRINT_MESSAGE = '[Message Center] Print Message';
exports.PRINT_MESSAGE_FAIL = '[Message Center] Print Message fail';
exports.COMPARE_VIEWS = '[Message Center] Compare Views';
class BlockSenderHeldEmailAction {
    constructor() {
        this.type = exports.BLOCK_SENDER_HELD_EMAIL;
    }
}
exports.BlockSenderHeldEmailAction = BlockSenderHeldEmailAction;
class BlockSenderRejectedEmailAction {
    constructor() {
        this.type = exports.BLOCK_SENDER_REJECTED_EMAIL;
    }
}
exports.BlockSenderRejectedEmailAction = BlockSenderRejectedEmailAction;
class BlockSenderEmailSuccessAction {
    constructor() {
        this.type = exports.BLOCK_SENDER_EMAIL_SUCCESS;
    }
}
exports.BlockSenderEmailSuccessAction = BlockSenderEmailSuccessAction;
class BlockSenderEmailFailAction {
    constructor() {
        this.type = exports.BLOCK_SENDER_EMAIL_FAIL;
    }
}
exports.BlockSenderEmailFailAction = BlockSenderEmailFailAction;
class BlockSenderAction {
    constructor() {
        this.type = exports.BLOCK_SENDER;
    }
}
exports.BlockSenderAction = BlockSenderAction;
class BlockSenderForRecipientSuccessAction {
    constructor() {
        this.type = exports.BLOCK_SENDER_FOR_RECIPIENT_SUCCESS;
    }
}
exports.BlockSenderForRecipientSuccessAction = BlockSenderForRecipientSuccessAction;
class BlockSenderForRecipientFailAction {
    constructor() {
        this.type = exports.BLOCK_SENDER_FOR_RECIPIENT_FAIL;
    }
}
exports.BlockSenderForRecipientFailAction = BlockSenderForRecipientFailAction;
class BounceQueuedEmailAction {
    constructor() {
        this.type = exports.BOUNCE_QUEUED_EMAIL;
    }
}
exports.BounceQueuedEmailAction = BounceQueuedEmailAction;
class BounceQueuedEmailSuccessAction {
    constructor() {
        this.type = exports.BOUNCE_QUEUED_EMAIL_SUCCESS;
    }
}
exports.BounceQueuedEmailSuccessAction = BounceQueuedEmailSuccessAction;
class BounceQueuedEmailFailAction {
    constructor() {
        this.type = exports.BOUNCE_QUEUED_EMAIL_FAIL;
    }
}
exports.BounceQueuedEmailFailAction = BounceQueuedEmailFailAction;
class ShowMessageInfoAction {
    constructor(row, list, forceLoadContent) {
        this.row = row;
        this.list = list;
        this.forceLoadContent = forceLoadContent;
        this.type = exports.SHOW_MESSAGE_INFO;
    }
}
exports.ShowMessageInfoAction = ShowMessageInfoAction;
class HighlightSelectedRowAction {
    constructor(row) {
        this.row = row;
        this.type = exports.HIGHLIGHT_SELECTED_ROW;
    }
}
exports.HighlightSelectedRowAction = HighlightSelectedRowAction;
class ClearSelectedRowAction {
    constructor() {
        this.type = exports.CLEAR_SELECTED_ROW;
    }
}
exports.ClearSelectedRowAction = ClearSelectedRowAction;
class CopyToClipboardSuccessAction {
    constructor() {
        this.type = exports.COPY_TO_CLIPBOARD_SUCCESS;
    }
}
exports.CopyToClipboardSuccessAction = CopyToClipboardSuccessAction;
class GetMessageInfoAction {
    constructor(row, forceLoadContent = false) {
        this.row = row;
        this.forceLoadContent = forceLoadContent;
        this.type = exports.GET_MESSAGE_INFO;
    }
}
exports.GetMessageInfoAction = GetMessageInfoAction;
class GetMessageInfoSuccessAction {
    constructor(messageInfo, forceLoadContent) {
        this.messageInfo = messageInfo;
        this.forceLoadContent = forceLoadContent;
        this.type = exports.GET_MESSAGE_INFO_SUCCESS;
    }
}
exports.GetMessageInfoSuccessAction = GetMessageInfoSuccessAction;
class GetMessageInfoFailAction {
    constructor() {
        this.type = exports.GET_MESSAGE_INFO_FAIL;
    }
}
exports.GetMessageInfoFailAction = GetMessageInfoFailAction;
class PermitSenderHeldEmailAction {
    constructor() {
        this.type = exports.PERMIT_SENDER_HELD_EMAIL;
    }
}
exports.PermitSenderHeldEmailAction = PermitSenderHeldEmailAction;
class PermitSenderRejectedEmailAction {
    constructor() {
        this.type = exports.PERMIT_SENDER_REJECTED_EMAIL;
    }
}
exports.PermitSenderRejectedEmailAction = PermitSenderRejectedEmailAction;
class PermitSenderAction {
    constructor() {
        this.type = exports.PERMIT_SENDER;
    }
}
exports.PermitSenderAction = PermitSenderAction;
class PermitSenderForRecipientSuccessAction {
    constructor() {
        this.type = exports.PERMIT_SENDER_FOR_RECIPIENT_SUCCESS;
    }
}
exports.PermitSenderForRecipientSuccessAction = PermitSenderForRecipientSuccessAction;
class PermitSenderForRecipientFailAction {
    constructor() {
        this.type = exports.PERMIT_SENDER_FOR_RECIPIENT_FAIL;
    }
}
exports.PermitSenderForRecipientFailAction = PermitSenderForRecipientFailAction;
class RecalculateDeliveryRouteAction {
    constructor() {
        this.type = exports.RECALCULATE_DELIVERY_ROUTE;
    }
}
exports.RecalculateDeliveryRouteAction = RecalculateDeliveryRouteAction;
class RecalculateDeliveryRouteSuccessAction {
    constructor() {
        this.type = exports.RECALCULATE_DELIVERY_ROUTE_SUCCESS;
    }
}
exports.RecalculateDeliveryRouteSuccessAction = RecalculateDeliveryRouteSuccessAction;
class RecalculateDeliveryRouteFailAction {
    constructor() {
        this.type = exports.RECALCULATE_DELIVERY_ROUTE_FAIL;
    }
}
exports.RecalculateDeliveryRouteFailAction = RecalculateDeliveryRouteFailAction;
class RejectHeldEmailAction {
    constructor() {
        this.type = exports.REJECT_HELD_EMAIL;
    }
}
exports.RejectHeldEmailAction = RejectHeldEmailAction;
class RejectHeldEmailSuccessAction {
    constructor() {
        this.type = exports.REJECT_HELD_EMAIL_SUCCESS;
    }
}
exports.RejectHeldEmailSuccessAction = RejectHeldEmailSuccessAction;
class RejectHeldEmailFailAction {
    constructor() {
        this.type = exports.REJECT_HELD_EMAIL_FAIL;
    }
}
exports.RejectHeldEmailFailAction = RejectHeldEmailFailAction;
class RejectHeldEmailWithNotificationAction {
    constructor(reason, notes = null) {
        this.reason = reason;
        this.notes = notes;
        this.type = exports.REJECT_HELD_EMAIL_WITH_NOTIFICATION;
    }
}
exports.RejectHeldEmailWithNotificationAction = RejectHeldEmailWithNotificationAction;
class RejectHeldEmailWithNotificationSuccessAction {
    constructor() {
        this.type = exports.REJECT_HELD_EMAIL_WITH_NOTIFICATION_SUCCESS;
    }
}
exports.RejectHeldEmailWithNotificationSuccessAction = RejectHeldEmailWithNotificationSuccessAction;
class RejectHeldEmailWithNotificationFailAction {
    constructor() {
        this.type = exports.REJECT_HELD_EMAIL_WITH_NOTIFICATION_FAIL;
    }
}
exports.RejectHeldEmailWithNotificationFailAction = RejectHeldEmailWithNotificationFailAction;
class RejectHeldEmailWithNarrativeAction {
    constructor() {
        this.type = exports.REJECT_HELD_EMAIL_WITH_NARRATIVE;
    }
}
exports.RejectHeldEmailWithNarrativeAction = RejectHeldEmailWithNarrativeAction;
class RejectHeldEmailWithNarrativeSuccessAction {
    constructor() {
        this.type = exports.REJECT_HELD_EMAIL_WITH_NARRATIVE_SUCCESS;
    }
}
exports.RejectHeldEmailWithNarrativeSuccessAction = RejectHeldEmailWithNarrativeSuccessAction;
class RejectHeldEmailWithNarrativeFailAction {
    constructor() {
        this.type = exports.REJECT_HELD_EMAIL_WITH_NARRATIVE_FAIL;
    }
}
exports.RejectHeldEmailWithNarrativeFailAction = RejectHeldEmailWithNarrativeFailAction;
class RejectQueuedEmailAction {
    constructor() {
        this.type = exports.REJECT_QUEUED_EMAIL;
    }
}
exports.RejectQueuedEmailAction = RejectQueuedEmailAction;
class RejectQueuedEmailSuccessAction {
    constructor() {
        this.type = exports.REJECT_QUEUED_EMAIL_SUCCESS;
    }
}
exports.RejectQueuedEmailSuccessAction = RejectQueuedEmailSuccessAction;
class RejectQueuedEmailFailAction {
    constructor() {
        this.type = exports.REJECT_QUEUED_EMAIL_FAIL;
    }
}
exports.RejectQueuedEmailFailAction = RejectQueuedEmailFailAction;
class ReleaseHeldEmailToSandboxAction {
    constructor() {
        this.type = exports.RELEASE_HELD_EMAIL_TO_SANDBOX;
    }
}
exports.ReleaseHeldEmailToSandboxAction = ReleaseHeldEmailToSandboxAction;
class ReleaseHeldEmailToSandboxSuccessAction {
    constructor() {
        this.type = exports.RELEASE_HELD_EMAIL_TO_SANDBOX_SUCCESS;
    }
}
exports.ReleaseHeldEmailToSandboxSuccessAction = ReleaseHeldEmailToSandboxSuccessAction;
class ReleaseHeldEmailToSandboxFailAction {
    constructor() {
        this.type = exports.RELEASE_HELD_EMAIL_TO_SANDBOX_FAIL;
    }
}
exports.ReleaseHeldEmailToSandboxFailAction = ReleaseHeldEmailToSandboxFailAction;
class ReleaseHeldEmailAction {
    constructor() {
        this.type = exports.RELEASE_HELD_EMAIL;
    }
}
exports.ReleaseHeldEmailAction = ReleaseHeldEmailAction;
class ReleaseHeldEmailSuccessAction {
    constructor() {
        this.type = exports.RELEASE_HELD_EMAIL_SUCCESS;
    }
}
exports.ReleaseHeldEmailSuccessAction = ReleaseHeldEmailSuccessAction;
class ReleaseHeldEmailFailAction {
    constructor() {
        this.type = exports.RELEASE_HELD_EMAIL_FAIL;
    }
}
exports.ReleaseHeldEmailFailAction = ReleaseHeldEmailFailAction;
class ReportMessageOpenModalAction {
    constructor(reportMessageType, id) {
        this.reportMessageType = reportMessageType;
        this.id = id;
        this.type = exports.REPORT_MESSAGE_OPEN_MODAL;
    }
}
exports.ReportMessageOpenModalAction = ReportMessageOpenModalAction;
class ReportMessageAction {
    constructor(reportMessageType, id, comment) {
        this.reportMessageType = reportMessageType;
        this.id = id;
        this.comment = comment;
        this.type = exports.REPORT_MESSAGE;
    }
}
exports.ReportMessageAction = ReportMessageAction;
class RetryDeliveryAction {
    constructor() {
        this.type = exports.RETRY_DELIVERY;
    }
}
exports.RetryDeliveryAction = RetryDeliveryAction;
class RetryDeliverySuccessAction {
    constructor() {
        this.type = exports.RETRY_DELIVERY_SUCCESS;
    }
}
exports.RetryDeliverySuccessAction = RetryDeliverySuccessAction;
class RetryDeliveryFailAction {
    constructor() {
        this.type = exports.RETRY_DELIVERY_FAIL;
    }
}
exports.RetryDeliveryFailAction = RetryDeliveryFailAction;
class SelectEmailAction {
    constructor(owner, transmissionType = 'receipt') {
        this.owner = owner;
        this.transmissionType = transmissionType;
        this.type = exports.SELECT_EMAIL;
    }
}
exports.SelectEmailAction = SelectEmailAction;
class DownloadFileAction {
    constructor(id) {
        this.id = id;
        this.type = exports.DOWNLOAD_FILE;
    }
}
exports.DownloadFileAction = DownloadFileAction;
class DownloadAllFilesAction {
    constructor() {
        this.type = exports.DOWNLOAD_ALL_FILES;
    }
}
exports.DownloadAllFilesAction = DownloadAllFilesAction;
class ShowHtmlAction {
    constructor(showHtml$) {
        this.showHtml$ = showHtml$;
        this.type = exports.SHOW_HTML_UPDATE;
    }
}
exports.ShowHtmlAction = ShowHtmlAction;
class ShowHtmlFailAction {
    constructor() {
        this.type = exports.SHOW_HTML_UPDATE_FAIL;
    }
}
exports.ShowHtmlFailAction = ShowHtmlFailAction;
class PrintMessageAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.PRINT_MESSAGE;
    }
}
exports.PrintMessageAction = PrintMessageAction;
class PrintMessageFailAction {
    constructor() {
        this.type = exports.PRINT_MESSAGE_FAIL;
    }
}
exports.PrintMessageFailAction = PrintMessageFailAction;
class CompareViewsAction {
    constructor(on) {
        this.on = on;
        this.type = exports.COMPARE_VIEWS;
    }
}
exports.CompareViewsAction = CompareViewsAction;
