"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AcceptedEmailPageComponent = void 0;
const actions = require("../actions");
const commonActions = require("../../common/actions");
const reducers = require("../reducers");
class AcceptedEmailPageComponent {
    constructor(store, tabService) {
        this.store = store;
        this.tabService = tabService;
        const tab = this.tabService.getSelectedTab();
        tab.onRefresh = () => {
            this.store.dispatch(new actions.GetAcceptedEmailRequestAction({}));
        };
    }
    ngOnInit() {
        this.acceptedEmailList$ = this.store.select(reducers.getAcceptedEmailList);
        this.externalTableSearchCount$ = this.store.select(reducers.externalTableSearchCount);
        this.selectedRow$ = this.store.select(reducers.getSelectedRow);
        this.isLoading$ = this.store.select(reducers.isTableLoading);
        this.metadata$ = this.store.select(reducers.getMetadata);
        this.store.dispatch(new actions.GetAcceptedEmailRequestAction({}));
    }
    onGetEmail(newGetEmailPayload) {
        this.store.dispatch(new actions.GetAcceptedEmailRequestAction(newGetEmailPayload));
    }
    onFiltersChange(newFiltersPayload) {
        this.store.dispatch(new actions.GetAcceptedEmailRequestAction(newFiltersPayload));
    }
    onPaginatorChange(paginatorOutput) {
        this.store.dispatch(new actions.GetAcceptedEmailPaginationAction(paginatorOutput));
    }
    onRowClick(row) {
        this.store.dispatch(new actions.GetAcceptedEmailSelectRowAction(row));
    }
    onExport(tableColumns) {
        this.store.dispatch(new actions.ExportAction(tableColumns));
    }
    onForward(messages) {
        this.store.dispatch(new commonActions.ForwardMessageOpenModalAction(messages));
    }
    onReportMessage({ reportType, id }) {
        this.store.dispatch(new commonActions.ReportMessageOpenModalAction(reportType, id));
    }
    ngOnDestroy() {
        this.store.dispatch(new actions.GetAcceptedEmailResetStateAction());
    }
}
exports.AcceptedEmailPageComponent = AcceptedEmailPageComponent;
