<mc-modal-header
  headerTitle="{{header | translate}}"></mc-modal-header>
<mc-modal-footer  *ngIf="policy$ | async; let row">
  <button type="button" class="btn btn-secondary" (click)="onCancel()">
    {{ 'Cancel'}}
  </button>

  <mc-live-button
    overrideClasses="btn-primary"
    [label]="actionButton | translate"
    [isLoading]="isDeleting$ | async"
    (mcClick)="onDisable(row)">
  </mc-live-button>
</mc-modal-footer>
