"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OverrideTypeDisplayPipe = void 0;
const url_override_types_enum_1 = require("../../enums/url-override-types.enum");
class OverrideTypeDisplayPipe {
    constructor(translate) {
        this.translate = translate;
    }
    transform(input) {
        switch (input) {
            case url_override_types_enum_1.UrlOverrideTypes.PERMIT:
                return this.translate.instant('$I18N_SERVICES_URL_PROTECTION.PERMITTED');
            case url_override_types_enum_1.UrlOverrideTypes.BLOCK:
                return this.translate.instant('$I18N_SERVICES_URL_PROTECTION.BLOCKED');
            default:
                return '-';
        }
    }
}
exports.OverrideTypeDisplayPipe = OverrideTypeDisplayPipe;
