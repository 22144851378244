<mc-modal-header
  headerTitle="$I18N_SWG_CREATE_POLICY_DUPLICATE_WARNING_TITLE"></mc-modal-header>
<mc-modal-body *ngIf="policy$ | async; let row">
  <div>{{'$I18N_SWG_CREATE_POLICY_DUPLICATE_WARNING' | translate}}
  </div>

  <div class="message mc-swg-duplicate-warning">
    <span class="icon fas fa-exclamation-triangle"> </span>
    <span>
      <strong>
        {{'$I18N_SWG_CREATE_POLICY_DUPLICATE_WARNING_DESC' | translate}}</strong>
    </span>
  </div>
</mc-modal-body>
<mc-modal-footer *ngIf="policy$ | async; let row">
  <button type="button" class="btn btn-secondary" (click)="onCancel()">
    {{'$I18N_COMMON_BTN_CANCEL' | translate}}
  </button>

  <button
    type="button"
    class="btn btn-primary"
    (click)="forceDuplicate(row)">
    {{'$I18N_SWG_CREATE_POLICY_DUPLICATE_SAVE_ANYWAY' | translate}}
  </button>
</mc-modal-footer>
