"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgApplicationChartWithControlComponent = void 0;
const swg_application_dashboard_static_value_1 = require("../dashboard/swg-application-dashboard.static-value");
class SwgApplicationChartWithControlComponent {
    constructor() {
        this.exportFormats = swg_application_dashboard_static_value_1.exportFormats;
        this.chartType = 'bar-chart';
    }
    showAppOrLoadingMessage() {
        return this.loading || this.chartData.length === 0;
    }
    exportChart(type, fileName) {
        if (this.chartType === 'pie') {
            this.pieChart.exportChart(type, fileName);
            return;
        }
        if (this.chartType === 'multi-line') {
            this.swgDetectionTimelineComponent.exportChart(type, fileName);
            return;
        }
        this.appsChart.exportChart(type, fileName);
    }
}
exports.SwgApplicationChartWithControlComponent = SwgApplicationChartWithControlComponent;
