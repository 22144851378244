"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CortexEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const notification_actions_1 = require("app-new/components/notification/actions/notification.actions");
const actions = require("../../actions");
const reducers = require("../../reducers");
class CortexEffects {
    constructor(actions$, store, stateService, cortexApiService, translateService, locationService) {
        this.actions$ = actions$;
        this.store = store;
        this.stateService = stateService;
        this.cortexApiService = cortexApiService;
        this.translateService = translateService;
        this.locationService = locationService;
        this.closeCreateIntegrationWizard$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationCortexActionTypes.CloseCreateIntegration, actions.IntegrationCortexActionTypes.CancelCreateIntegration), operators_1.tap(() => {
            this.stateService.$state.go('api-applications-list', { displayTab: 'integrations' });
        }));
        this.createCortexIntegrationVersion1$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationCortexActionTypes.CreateCortexIntegrationVersion1), operators_1.withLatestFrom(this.store.select(reducers.getIntegrationCreateCortexRequestVersion1Data)), 
        // @ts-ignore
        operators_1.switchMap(([action, config]) => {
            const instanceConfig = {
                lakeSerialNumber: action.payload.lakeSerialNumber,
                integrationName: action.payload.integrationName,
                instanceId: action.payload.instanceId,
                region: action.payload.region
            };
            const request = this.cortexApiService.buildCreateRequestVersion1(config, instanceConfig);
            return this.cortexApiService.createIntegrationVersion1(request).pipe(operators_1.map((response) => new actions.CreateCortexIntegrationCompleteVersion1Action(response)), operators_1.catchError(() => rxjs_1.of(new actions.IntegrationFailureAction(), new actions.GetAllIntegrationsAction())));
        }));
        this.updateCortexIntegrationVersion1$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationCortexActionTypes.UpdateCortexIntegrationVersion1), operators_1.withLatestFrom(this.store.select(reducers.getIntegrationUpdateCortexVersion1RequestData)), 
        // @ts-ignore
        operators_1.switchMap(([action, config]) => {
            const hasError = Boolean(action && action.payload && action.payload.hasError);
            const request = this.cortexApiService.buildUpdateRequestVersion1(config, hasError);
            return this.cortexApiService.updateIntegrationVersion1(request).pipe(operators_1.map((response) => new actions.UpdateCortexIntegrationCompleteVersion1Action(response)), operators_1.catchError(() => rxjs_1.of(new actions.ApiApplicationFailureAction('$I18N_API_APPLICATIONS_LIST.NOTIFICATIONS.ERRORS.UPDATE_INTEGRATION'), new actions.GetAllIntegrationsAction())));
        }));
        this.authoriseCortexIntegrationVersion1$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationCortexActionTypes.AuthoriseCortexIntegrationVersion1), operators_1.withLatestFrom(this.store.select(reducers.getIntegrationCreateCortexIntegrationVersion1)), operators_1.tap(([, cortex]) => {
            this.locationService.href = cortex.oAuthUrl;
        }));
        this.getAuthorisedCortexIntegration$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationCortexActionTypes.AuthoriseCortexIntegrationComplete), operators_1.switchMap((action) => [
            new actions.GetIntegrationAction({ id: action.payload.config, type: 'cortex' })
        ]));
        this.verifyAndAddEmailCortexIntegrationNotifications$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationCortexActionTypes.VerifyAndAddEmailCortexIntegrationNotifications), operators_1.switchMap((action) => {
            return this.cortexApiService.isInternalEmail(action.payload.email).pipe(operators_1.map((response) => {
                if (response) {
                    return new actions.VerifyAndAddEmailCortexIntegrationNotificationsSuccessAction({
                        email: action.payload.email
                    });
                }
                return new actions.VerifyAndAddEmailCortexIntegrationNotificationsFailAction();
            }), operators_1.catchError(() => rxjs_1.of(new actions.VerifyAndAddEmailCortexIntegrationNotificationsFailAction())));
        }));
        this.verifyAndAddEmailCortexIntegrationNotificationsFail$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationCortexActionTypes.VerifyAndAddEmailCortexIntegrationNotificationsFail), operators_1.switchMap(() => {
            return [
                new notification_actions_1.NotificationShowAction({
                    type: 'error',
                    config: {
                        msg: this.translateService.instant('$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.NOTIFICATIONS.API_ERROR.EXTERNAL_EMAIL')
                    }
                })
            ];
        }));
        this.getCortexIntegrationVersion1Fail$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationCortexActionTypes.GetIntegrationVersion1Fail), operators_1.switchMap(() => {
            return [
                new notification_actions_1.NotificationShowAction({
                    type: 'error',
                    config: {
                        msg: this.translateService.instant('$I18N_API_INTEGRATION_CORTEX_WIZARD.GET_INTEGRATION_ERROR')
                    }
                }),
                new actions.CloseViewIntegrationAction(),
                new actions.GetAllIntegrationsAction()
            ];
        }));
    }
}
__decorate([
    effects_1.Effect({ dispatch: false })
], CortexEffects.prototype, "closeCreateIntegrationWizard$", void 0);
__decorate([
    effects_1.Effect()
], CortexEffects.prototype, "createCortexIntegrationVersion1$", void 0);
__decorate([
    effects_1.Effect()
], CortexEffects.prototype, "updateCortexIntegrationVersion1$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], CortexEffects.prototype, "authoriseCortexIntegrationVersion1$", void 0);
__decorate([
    effects_1.Effect()
], CortexEffects.prototype, "getAuthorisedCortexIntegration$", void 0);
__decorate([
    effects_1.Effect()
], CortexEffects.prototype, "verifyAndAddEmailCortexIntegrationNotifications$", void 0);
__decorate([
    effects_1.Effect()
], CortexEffects.prototype, "verifyAndAddEmailCortexIntegrationNotificationsFail$", void 0);
__decorate([
    effects_1.Effect()
], CortexEffects.prototype, "getCortexIntegrationVersion1Fail$", void 0);
exports.CortexEffects = CortexEffects;
