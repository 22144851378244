"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgThreatEventFileDownloadModalComponent = void 0;
const swg_threat_event_details_actions_1 = require("../../actions/swg-threat-event-details.actions");
class SwgThreatEventFileDownloadModalComponent {
    constructor(fb, store) {
        this.fb = fb;
        this.store = store;
        this.form = this.fb.group({
            password: '',
            confirmPassword: ''
        });
    }
    ngOnInit() {
        this.s = this.form.valueChanges.subscribe(() => {
            this.errorMessage = null;
        });
    }
    ngOnDestroy() {
        this.s.unsubscribe();
    }
    onSubmit() {
        const password = this.form.get('password').value;
        const confirmPassword = this.form.get('confirmPassword').value;
        if (password.length === 0) {
            this.errorMessage = 'ERROR_PASSWORD_EMTPY';
        }
        else if (password !== confirmPassword) {
            this.errorMessage = 'ERROR_PASSWORD_MATCHING';
        }
        else {
            this.store.dispatch(new swg_threat_event_details_actions_1.SwgDownloadThreatEventFormSubmit(this.eventId, password));
        }
    }
    onClose() {
        this.store.dispatch(new swg_threat_event_details_actions_1.SwgDownloadThreatEventModalClose());
    }
}
exports.SwgThreatEventFileDownloadModalComponent = SwgThreatEventFileDownloadModalComponent;
