"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageDetailsEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const messageDetails = require("../../actions/message-details/message-details.actions");
const asidePaginationAction = require("../../actions/pagination/pagination.actions");
const message_details_component_1 = require("../../components/message-details/message-details.component");
class MessageDetailsEffects {
    constructor(actions$, asideService, remediationApiService, notificationService, translation, store) {
        this.actions$ = actions$;
        this.asideService = asideService;
        this.remediationApiService = remediationApiService;
        this.notificationService = notificationService;
        this.translation = translation;
        this.store = store;
        this.loadMessageDetails$ = this.actions$.pipe(effects_1.ofType(messageDetails.GET_MESSAGE_DETAILS), operators_1.switchMap((action) => this.loadMessageDetails(action)));
        this.loadMessageDetailsWithImages$ = this.actions$.pipe(effects_1.ofType(messageDetails.GET_MESSAGE_DETAILS_WITH_IMAGES), operators_1.switchMap((action) => this.loadMessageDetails(action)));
        this.noEmailReadPermission$ = this.actions$.pipe(effects_1.ofType(messageDetails.GET_MESSAGE_DETAILS_NO_EMAIL_READ_PERMISSION), operators_1.tap(() => {
            this.notificationService.info({
                msg: this.translation.instant('$I18N_REMEDIATION_MESSAGE_DETAILS.NOTIFICATION.NO_EMAIL_READ_PERMISSION')
            });
        }));
    }
    loadMessageDetails(action) {
        if (!this.asideInstance) {
            this.asideInstance = this.asideService.open(message_details_component_1.MessageDetailsComponent, {
                position: 'right',
                size: 'md',
                hasBackdrop: true
            });
            this.asideInstance.beforeClose().subscribe(() => {
                this.store.dispatch(new asidePaginationAction.PaginationSetSelectedRow(null));
            });
            this.asideInstance.afterClose().subscribe(() => {
                this.asideInstance = undefined;
            });
        }
        const config = {
            id: action.row.id,
            stripImages: action.stripImages
        };
        if (action.stripImages) {
            this.store.dispatch(new asidePaginationAction.PaginationSetSelectedRow(action.row));
        }
        return this.remediationApiService.getMessageDetails(config).pipe(operators_1.map((response) => {
            if (action.stripImages) {
                return new messageDetails.GetMessageDetailsSuccessAction(response);
            }
            else {
                return new messageDetails.GetMessageDetailsWithImagesSuccessAction(response);
            }
        }), operators_1.catchError(error => {
            this.notificationService.error({
                msg: this.translation.instant('$I18N_COMMON_TEXT_LINE_GLOBAL_ERROR_MESSAGE')
            });
            return rxjs_1.of(new messageDetails.GetMessageDetailsFailAction(error));
        }));
    }
}
__decorate([
    effects_1.Effect()
], MessageDetailsEffects.prototype, "loadMessageDetails$", void 0);
__decorate([
    effects_1.Effect()
], MessageDetailsEffects.prototype, "loadMessageDetailsWithImages$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], MessageDetailsEffects.prototype, "noEmailReadPermission$", void 0);
exports.MessageDetailsEffects = MessageDetailsEffects;
