'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./favourite.controller"); //favourite.controller
require("./favourite.directive"); //favourite.directive
// html and css 
require("./favourite.tpl.html");
angular.module('favourite', [
    'favourite.controller',
    'favourite.directive'
]);
