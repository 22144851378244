"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckUrlsService = void 0;
const file_parse_actions_1 = require("../../actions/file-parse.actions");
class CheckUrlsService {
    constructor(httpClient, store) {
        this.httpClient = httpClient;
        this.store = store;
    }
    checkUrl(url) {
        return this.httpClient.post('/api/ttp/url/detailed-scan-url', {
            url,
            includeScanResponse: true,
            takeScreenshot: true
        });
    }
    submitUrlScanDetailReport(scanDetailsReport) {
        return this.httpClient.post('/api/ttp/url/report-url', (({ id, categoryCode, comment, uploadScreenshot, reportTo, scanResponses, isMalicious }) => ({
            id,
            categoryCode,
            comment,
            uploadScreenshot,
            reportTo,
            scanResponses,
            isMalicious
        }))(scanDetailsReport));
    }
    readFile(file) {
        const fileReader = new FileReader();
        fileReader.readAsBinaryString(file);
        fileReader.onload = fileLoadedEvent => {
            const textFromFileLoaded = fileLoadedEvent.target['result'];
            if (typeof textFromFileLoaded === 'string') {
                const base64Image = btoa(textFromFileLoaded);
                this.store.dispatch(new file_parse_actions_1.URLReportImageUploadedByUser(base64Image));
            }
        };
    }
}
exports.CheckUrlsService = CheckUrlsService;
