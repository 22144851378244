"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardComponent = void 0;
const core_1 = require("@angular/core");
class CardComponent {
    constructor() {
        this.useTranslation = true;
        this.partnerClick = new core_1.EventEmitter();
        this.optionClick = new core_1.EventEmitter();
    }
}
exports.CardComponent = CardComponent;
