"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LexiconService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const lexicon_1 = require("../model/lexicon/lexicon");
class LexiconService {
    constructor(http) {
        this.http = http;
    }
    static splitWords(terms) {
        return LexiconService.splitSearchTerms(terms, /,|\n| /);
    }
    static splitPhrases(terms) {
        return LexiconService.splitSearchTerms(terms, /\n/);
    }
    static splitSearchTerms(terms, regex) {
        let splittedTerms = [];
        if (terms) {
            splittedTerms = [...terms.split(regex).filter(Boolean)];
        }
        return splittedTerms;
    }
    static getApiErrorTranslateKey(apiErrorCode, isUpdate = false) {
        switch (apiErrorCode.toUpperCase()) {
            case 'ERR_LEXICON_RETRIEVAL_FAILURE':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.NOTIFICATION.ERR_LEXICON_RETRIEVAL_FAILURE';
            case 'ERR_LEXICON_UPDATE_FAILURE':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.NOTIFICATION.ERR_LEXICON_UPDATE_FAILURE';
            case 'ERR_LEXICON_CREATION_FAILURE':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.NOTIFICATION.ERR_LEXICON_CREATION_FAILURE';
            case 'ERR_LEXICON_INVALID_ID':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.NOTIFICATION.ERR_LEXICON_INVALID_ID';
            case 'ERR_LEXICON_ID_NOT_VALID_FOR_USER':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.NOTIFICATION.ERR_LEXICON_ID_NOT_VALID_FOR_USER';
            case 'ERR_LEXICON_INVALID_SIZE_SEARCH_TERMS':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.NOTIFICATION.ERR_LEXICON_INVALID_SIZE_SEARCH_TERMS';
            case 'ERR_LEXICON_EMPTY_SEARCH_TERMS':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.NOTIFICATION.ERR_LEXICON_EMPTY_SEARCH_TERMS';
            case 'ERR_LEXICON_INVALID_REQUEST':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.NOTIFICATION.ERR_LEXICON_INVALID_REQUEST';
            case 'ERR_LEXICON_NOT_FOUND':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.NOTIFICATION.ERR_LEXICON_NOT_FOUND';
            case 'ERR_VALIDATION_OVER_MAX_NAME':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.NOTIFICATION.ERR_VALIDATION_OVER_MAX_NAME';
            case 'ERR_VALIDATION_NULL_NAME':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.NOTIFICATION.ERR_VALIDATION_NULL_NAME';
            case 'ERR_VALIDATION_NULL_SEARCHPARAMS':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.NOTIFICATION.ERR_VALIDATION_NULL_SEARCHPARAMS';
            case 'ERR_VALIDATION_BLANK_NAME':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.NOTIFICATION.ERR_VALIDATION_BLANK_NAME';
            case 'ERR_VALIDATION_OVER_MAX_DESCRIPTION':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.NOTIFICATION.ERR_VALIDATION_OVER_MAX_DESCRIPTION';
            case 'ERR_VALIDATION_BLANK_DESCRIPTION':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.NOTIFICATION.ERR_VALIDATION_BLANK_DESCRIPTION';
            case 'ERR_VALIDATION_OVER_MAX_SEARCHPARAMS_WORDS':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.NOTIFICATION.ERR_VALIDATION_OVER_MAX_WORDS';
            case 'ERR_VALIDATION_OVER_MAX_SEARCHPARAMS_PHRASES':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.NOTIFICATION.ERR_VALIDATION_OVER_MAX_PHRASES';
            case 'ERR_LEXICON_INVALID_SEARCH_TERMS':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.NOTIFICATION.ERR_LEXICON_INVALID_SEARCH_TERMS';
            case 'ERR_MQL_PARSE_SEARCH_TERMS_FAILURE':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.NOTIFICATION.ERR_MQL_PARSE_SEARCH_TERMS_FAILURE';
            case 'ERR_MQL_INVALID_RESTRICTION_SEARCH_TERM':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.NOTIFICATION.ERR_MQL_INVALID_RESTRICTION_SEARCH_TERM';
            case 'ERR_MQL_INVALID_SEARCH_TERMS':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.NOTIFICATION.ERR_MQL_INVALID_SEARCH_TERMS';
            default:
                return isUpdate
                    ? '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.NOTIFICATION.UPDATE_FAILURE'
                    : '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.NOTIFICATION.CREATE_FAILURE';
        }
    }
    createLexicon(data) {
        return this.http.post('/api/supervision/config/create-lexicon', data).pipe(operators_1.map((response) => {
            return response;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    updateLexicon(lexiconId, data) {
        return this.http
            .post('/api/supervision/config/update-lexicon', Object.assign({ id: lexiconId }, data))
            .pipe(operators_1.map((response) => {
            return response;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    deleteLexicon(data) {
        return this.http.post(`${lexicon_1.baseUrl}delete-lexicon`, data).pipe(operators_1.map((response) => {
            return response;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    getLexicon(lexiconId) {
        return this.http.post('/api/supervision/config/get-lexicon', { id: lexiconId }).pipe(operators_1.map((response) => {
            return response;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    validateSearchQuery(searchQuery) {
        const searchKey = 'query';
        return this.http.post(`${lexicon_1.baseUrl}validate`, { data: [{ [searchKey]: searchQuery }] }).pipe(operators_1.map((response) => {
            return response;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
}
exports.LexiconService = LexiconService;
LexiconService.WizardId = 'archive-supervision-wizard-lexicon';
