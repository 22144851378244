<mc-modal-header
  *ngIf="deviceGroup$ | async; let row"
  headerTitle="{{ '$I18N_DEVICE_GROUPS_LIST.DELETE_MODAL.TITLE' | translate }}  {{ row.name }} ?">
</mc-modal-header>

<mc-modal-footer *ngIf="deviceGroup$ | async; let row">
  <div data-test="deviceGroupConfirmationFooterBtns">
    <button
      type="button"
      class="btn btn-secondary"
      data-test="deviceGroupConfirmationCancelBtn"
      (click)="onCancel()">
      {{ '$I18N_COMMON_BTN_CANCEL' | translate }}
    </button>
    <mc-live-button
      overrideClasses="btn btn-danger"
      [label]="'$I18N_COMMON_BTN_DELETE' | translate"
      [isLoading]="isDeleting$ | async"
      (mcClick)="onDelete(row)">
    </mc-live-button>
  </div>
</mc-modal-footer>
