<mc-api-integration-crowdstrike-wizard
  [crowdstrikeIntegration]="crowdstrikeIntegration$ | async"
  [hasCreatedIntegration]="hasCreatedIntegration$ | async"
  (onSwitchCrowdstrikeIntegrationStatus)="switchCrowdstrikeIntegrationStatus($event)"
  (onCreateCrowdstrikeIntegration)="createCrowdstrikeIntegration()"
  (onSwitchMaliciousHashesEnabled)="switchMaliciousHashesEnabled($event)"
  (onSwitchPullMaliciousHashesEnabled)="switchPullMaliciousHashesEnabled($event)"
  (onIocOutboundActionSelected)="iocOutboundActionSelected($event)"
  (onSwitchRemediation)="switchRemediationRequired($event)"
  [isClientIdAndClientSecretVerified]="isClientIdAndClientSecretVerified$ | async"
  [isVerifyApiKeyButtonDisabled]="isVerifyApiKeyButtonDisabled$ | async"
  [isBusy]="isBusy$ | async"
  (onVerifyClientIdAndClientSecret)="verifyClientIdAndClientSecret($event)"
  (onCancel)="cancel()"
  (onUpdateCrowdstrikeIntegration)="updateCrowdstrikeIntegration()"
  (onVerifyAndAddEmail)="verifyAndAddEmail($event)"
  (onDeleteEmail)="deleteEmail($event)"
  (onAddGroup)="addGroup($event)"
  (onDeleteGroup)="deleteGroup($event)"
  (onSwitchMaliciousUrlsEnabled)="switchMaliciosUrls($event)"
  (onIocOutboundUrlActionSelected)="iocOutboundUrlActionSelected($event)"
  (onIocOutboundSeveritySelected)="iocOutboundSeveritySelected($event)"
  (onIocOutboundExpirationSelected)="iocOutboundExpirationSelected($event)"
>
</mc-api-integration-crowdstrike-wizard>