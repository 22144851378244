<div class="mc-api-integration-step-block" xmlns="http://www.w3.org/1999/html">
  <mc-wizard-step-header [label]="'$I18N_API_GATEWAY_V3_WIZARD.CREATE.DISCLAIMER.HEADER'">
  </mc-wizard-step-header>

  <div class="mc-detailed-list mc-api-gateway-v3-disclaimer-block">
    <div class="legal-disclaimer-tile-align-center">
      <span class="legal-disclaimer-tile">
          {{ '$I18N_API_GATEWAY_V3_WIZARD.CREATE.DISCLAIMER.DISCLAIMER_TITLE' | translate }}
      </span>
      <span class="display-inline legal-disclaimer-tile-date">
        {{ '$I18N_API_GATEWAY_V3_WIZARD.CREATE.DISCLAIMER.DISCLAIMER_TITLE_DATE' | translate }} <br><br>
      </span>
    </div>
    <div>
      <span class="legal-disclaimer">
        {{ '$I18N_API_GATEWAY_V3_WIZARD.CREATE.DISCLAIMER.DISCLAIMER_PART_1' | translate }}
      </span>
      <span class="disclaimer-text-bold">
        {{ '$I18N_API_GATEWAY_V3_WIZARD.CREATE.DISCLAIMER.DISCLAIMER_PART_1.1' | translate }}
      </span>
      <span class="legal-disclaimer">
        {{ '$I18N_API_GATEWAY_V3_WIZARD.CREATE.DISCLAIMER.DISCLAIMER_PART_2' | translate }}
      </span>
      <span class="disclaimer-text-bold">
        {{ '$I18N_API_GATEWAY_V3_WIZARD.CREATE.DISCLAIMER.DISCLAIMER_PART_2.1' | translate }}
      </span>
      <span class="legal-disclaimer">
        {{ '$I18N_API_GATEWAY_V3_WIZARD.CREATE.DISCLAIMER.DISCLAIMER_PART_3' | translate }}
      </span>
      <span class="disclaimer-text-bold">
        {{ '$I18N_API_GATEWAY_V3_WIZARD.CREATE.DISCLAIMER.DISCLAIMER_PART_3.1' | translate }}
      </span>
      <span class="legal-disclaimer">
        {{ '$I18N_API_GATEWAY_V3_WIZARD.CREATE.DISCLAIMER.DISCLAIMER_PART_4' | translate }} <br><br>
      </span>
    </div>
    <div>
      <span class="disclaimer-text-bold">
        {{ '$I18N_API_GATEWAY_V3_WIZARD.CREATE.DISCLAIMER.ACCEPT_TERMS_AGREEMENT' | translate }} <br><br>
      </span>
    </div>

    <ol type="1">
      <li>
        <span class="disclaimer-list-start">{{ '$I18N_API_GATEWAY_V3_WIZARD.CREATE.DISCLAIMER.DISCLAIMER_PART_4_LIST2_START' | translate }}</span>
        <span class="legal-disclaimer">{{ '$I18N_API_GATEWAY_V3_WIZARD.CREATE.DISCLAIMER.DISCLAIMER_PART_4_LIST2' | translate }}</span>
        <span class="disclaimer-text-bold">{{ '$I18N_API_GATEWAY_V3_WIZARD.CREATE.DISCLAIMER.DISCLAIMER_PART_4_LIST2.1' | translate }}</span>
        <span class="legal-disclaimer">{{ '$I18N_API_GATEWAY_V3_WIZARD.CREATE.DISCLAIMER.DISCLAIMER_PART_4_LIST2.2' | translate }}</span>
        <span class="disclaimer-text-bold">{{ '$I18N_API_GATEWAY_V3_WIZARD.CREATE.DISCLAIMER.DISCLAIMER_PART_4_LIST2.3' | translate }}</span>
        <span class="legal-disclaimer">{{ '$I18N_API_GATEWAY_V3_WIZARD.CREATE.DISCLAIMER.DISCLAIMER_PART_4_LIST2.4' | translate }}</span>
        <span class="disclaimer-text-bold">{{ '$I18N_API_GATEWAY_V3_WIZARD.CREATE.DISCLAIMER.DISCLAIMER_PART_4_LIST2.5' | translate }}</span>
        <span class="legal-disclaimer">{{ '$I18N_API_GATEWAY_V3_WIZARD.CREATE.DISCLAIMER.DISCLAIMER_PART_4_LIST2.6' | translate }}</span>
      </li>
      <li>
        <span class="disclaimer-list-start">{{ '$I18N_API_GATEWAY_V3_WIZARD.CREATE.DISCLAIMER.DISCLAIMER_PART_4_LIST3_START' | translate }}</span>
        <span class="legal-disclaimer">{{ '$I18N_API_GATEWAY_V3_WIZARD.CREATE.DISCLAIMER.DISCLAIMER_PART_4_LIST3.1' | translate }}</span>
        <span class="disclaimer-text-bold">{{ '$I18N_API_GATEWAY_V3_WIZARD.CREATE.DISCLAIMER.DISCLAIMER_PART_4_LIST3.2' | translate }}</span>
        <span class="legal-disclaimer">{{ '$I18N_API_GATEWAY_V3_WIZARD.CREATE.DISCLAIMER.DISCLAIMER_PART_4_LIST3.3' | translate }}</span>
      </li>
      <li>
        <span class="disclaimer-list-start">{{ '$I18N_API_GATEWAY_V3_WIZARD.CREATE.DISCLAIMER.DISCLAIMER_PART_4_LIST4_START' | translate }}</span>
        <span class="legal-disclaimer">{{ '$I18N_API_GATEWAY_V3_WIZARD.CREATE.DISCLAIMER.DISCLAIMER_PART_4_LIST4' | translate }}</span>
      </li>
      <li>
        <span class="disclaimer-list-start">{{ '$I18N_API_GATEWAY_V3_WIZARD.CREATE.DISCLAIMER.DISCLAIMER_PART_4_LIST5_START' | translate }}</span>
        <span class="legal-disclaimer">{{ '$I18N_API_GATEWAY_V3_WIZARD.CREATE.DISCLAIMER.DISCLAIMER_PART_4_LIST5' | translate }}</span>
        <span class="disclaimer-text-bold">{{ '$I18N_API_GATEWAY_V3_WIZARD.CREATE.DISCLAIMER.DISCLAIMER_PART_4_LIST5.1' | translate }}</span>
        <span class="legal-disclaimer">{{ '$I18N_API_GATEWAY_V3_WIZARD.CREATE.DISCLAIMER.DISCLAIMER_PART_4_LIST5.2' | translate }}</span>
      </li>
      <li>
        <span class="disclaimer-list-start">{{ '$I18N_API_GATEWAY_V3_WIZARD.CREATE.DISCLAIMER.DISCLAIMER_PART_4_LIST6_START' | translate }}</span>
        <span class="legal-disclaimer">{{ '$I18N_API_GATEWAY_V3_WIZARD.CREATE.DISCLAIMER.DISCLAIMER_PART_4_LIST6' | translate }}</span>
      </li>
      <li>
        <span class="disclaimer-list-start">{{ '$I18N_API_GATEWAY_V3_WIZARD.CREATE.DISCLAIMER.DISCLAIMER_PART_4_LIST7_START' | translate }}</span>
        <span class="legal-disclaimer">{{ '$I18N_API_GATEWAY_V3_WIZARD.CREATE.DISCLAIMER.DISCLAIMER_PART_4_LIST7' | translate }}</span>
      </li>
      <li>
        <span class="disclaimer-list-start">{{ '$I18N_API_GATEWAY_V3_WIZARD.CREATE.DISCLAIMER.DISCLAIMER_PART_4_LIST8_START' | translate }}</span>
        <span class="legal-disclaimer">{{ '$I18N_API_GATEWAY_V3_WIZARD.CREATE.DISCLAIMER.DISCLAIMER_PART_4_LIST8' | translate }}</span>
      </li>
    </ol>
    <form
      class="form-horizontal"
      mcDefaultLabelClass="col-sm-2"
      mcDefaultControlContainerClass="col-sm-6"
      [formGroup]="form"
      (ngSubmit)="submit()">

      <mc-checkbox
        formControlName="acceptTerms"
        class="mc-checkbox-disclaimer"
        label="{{ '$I18N_API_GATEWAY_V3_WIZARD.CREATE.DISCLAIMER.ACCEPT_TERMS' | translate }}"
      ></mc-checkbox>
    </form>
  </div>
</div>
