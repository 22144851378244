<mc-layout-detail-wizard
  keyTitle="{{ getTitle() }}"
  keyDescription="{{ getSubTitle() }}"
  extendClass="panel-padding-bottom"
>
  <mc-wizard (changeStep)="goToStep($event)" *ngIf="!isEditFlow()">
    <mc-wizard-step label="$I18N_SIEM_CHANNEL_WIZARD.STEPS.DETAILS.TITLE">
      <mc-siem-channel-details-step
        [channel]="channel"
        [isEditMode]="isEditFlow()"
        [metadataProducts]="metadataProducts"
        (updateChannelDescr)="updateChannelDescr($event)"
      >
      </mc-siem-channel-details-step>
    </mc-wizard-step>
    <mc-wizard-step label="$I18N_SIEM_CHANNEL_WIZARD.STEPS.PRODUCTS.STEP_TITLE">
      <mc-siem-channel-products-step
        [channelProducts]="channel.products"
        [metadataProductsByChannelType]="metadataProductsByChannelType"
        (updateChannelProducts)="updateChannelProducts($event)"
      >
      </mc-siem-channel-products-step>
    </mc-wizard-step>
    <mc-wizard-step *ngFor="let product of subscribedProducts" label="{{product.name}}">
      <mc-siem-channel-product-events-step
        [product]="product"
        (updateProductEvents)="updateProductEventsInStore.emit($event)"
      >
      </mc-siem-channel-product-events-step>
    </mc-wizard-step>

    <mc-wizard-step label="$I18N_SIEM_CHANNEL_WIZARD.STEPS.SUMMARY.STEP_TITLE">
      <mc-siem-channel-summary-step
       [channel]="channel"
      ></mc-siem-channel-summary-step>
    </mc-wizard-step>
  </mc-wizard>

  <mc-tab-group #tabs *ngIf="isEditFlow()" [direction]="'vertical'">
    <mc-tab name="{{ '$I18N_SIEM_CHANNEL_WIZARD.STEPS.DETAILS.TITLE' | translate }}">
      <mc-siem-channel-details-step
        [channel]="channel"
        [isEditMode]="isEditFlow()"
        [metadataProducts]="metadataProducts"
        (updateChannelDescr)="updateChannelDescr($event)"
      >
      </mc-siem-channel-details-step>
    </mc-tab>
    <mc-tab name="{{ '$I18N_SIEM_CHANNEL_WIZARD.STEPS.PRODUCTS.STEP_TITLE' | translate }}">
      <mc-siem-channel-products-step
        [channelProducts]="channel.products"
        [metadataProductsByChannelType]="metadataProductsByChannelType"
        (updateChannelProducts)="updateChannelProducts($event)"
      >
      </mc-siem-channel-products-step>
    </mc-tab>
    <mc-tab *ngFor="let product of subscribedProducts" name="{{ product.name}}">
      <mc-siem-channel-product-events-step
        [product]="product"
        (updateProductEvents)="updateProductEventsInStore.emit($event)"
      >
      </mc-siem-channel-product-events-step>
    </mc-tab>
    <mc-tab name="{{ '$I18N_SIEM_CHANNEL_WIZARD.STEPS.SUMMARY.STEP_TITLE' | translate }}">
      <mc-siem-channel-summary-step
        [channel]="channel"
      ></mc-siem-channel-summary-step>
    </mc-tab>
  </mc-tab-group>
  <mc-footer-container>
    <div class="pull-right no-select mc-button-footer">
      <button class="btn" (click)="cancel()">{{ '$I18N_COMMON_BTN_CANCEL' | translate }}</button>
      <ng-container *ngIf="wizard && !isEditFlow()">
        <button
          class="btn btn-secondary panel-half-margin-right"
          *ngIf="wizard.hasPreviousStep$ | async"
          (click)="wizard.previousStep()"
        >
          {{ '$I18N_COMMON_BTN_PREVIOUS' | translate }}
        </button>
        <button
          class="btn btn-primary"
          [disabled]="!isStepValid()"
          *ngIf="wizard.hasNextStep$ | async"
          (click)="wizard.nextStep()"
        >
          {{ '$I18N_COMMON_BTN_NEXT' | translate }}
        </button>
        <ng-container *ngIf="hasEditPermission">
          <mc-live-button
            *ngIf="wizard.isLastStep$ | async"
            overrideClasses="btn-primary"
            [label]="'$I18N_SIEM_CHANNEL_WIZARD.CREATE_CHANNEL_BUTTON' | translate"
            [isLoading]="apiIsProcessing"
            (mcClick)="createChannel()"
          ></mc-live-button>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="isEditFlow()">
        <ng-container *ngIf="hasEditPermission">
          <button
            *ngIf="!apiIsProcessing"
            class="btn btn-primary"
            [disabled]="!isValidChannel(channel)"
            (click)="createChannel()"
          >
            {{ '$I18N_SIEM_CHANNEL_WIZARD.SAVE_CLOSE_BUTTON' | translate }}
          </button>
          <mc-live-button
            *ngIf="apiIsProcessing"
            overrideClasses="btn-primary"
            [label]="'$I18N_SIEM_CHANNEL_WIZARD.SAVE_CLOSE_BUTTON' | translate"
            [isLoading]="apiIsProcessing"
          ></mc-live-button>
        </ng-container>
      </ng-container>
    </div>
  </mc-footer-container>
</mc-layout-detail-wizard>
