<mc-collapsable-panel header="$I18N_MESSAGE_CENTER_MESSAGE_INFO_MESSAGE_PROPERTIES.TITLE" [isExpanded]="true">
  <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_MESSAGE_PROPERTIES.LABEL_FROM_ENVELOPE"
                  [value]="selectedMessageInfo && selectedMessageInfo.fromEnvelope">
  </mc-value-field>
  <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_MESSAGE_PROPERTIES.LABEL_FROM_HEADER"
                  [value]="selectedMessageInfo && selectedMessageInfo.fromHeader">
  </mc-value-field>
  <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_MESSAGE_PROPERTIES.LABEL_FROM_TO"
                  [value]="selectedMessageInfo && selectedMessageInfo.to">
  </mc-value-field>
  <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_MESSAGE_PROPERTIES.LABEL_SUBJECT"
                  [value]="selectedMessageInfo && selectedMessageInfo.subject">
  </mc-value-field>
  <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_MESSAGE_PROPERTIES.LABEL_TRANSMISSION_SIZE_BYTES"
                  [value]="queueInfo.size">
  </mc-value-field>
  <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_MESSAGE_PROPERTIES.LABEL_ROUTING"
                  [value]="queueInfo.route | mcFirstLetterUppercase">
  </mc-value-field>
  <div *ngIf="messageStatus === 'held'">
    <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_MESSAGE_PROPERTIES.LABEL_HELD_SINCE"
                    [value]="queueInfo.createTime | mcDatePlusWeekday">
    </mc-value-field>
    <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_MESSAGE_PROPERTIES.LABEL_HELD_REASON"
                    [value]="queueInfo.reason">
    </mc-value-field>
    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">{{'$I18N_MESSAGE_CENTER_MESSAGE_INFO_MESSAGE_PROPERTIES.LABEL_HELD_INFORMATION' | translate}}</div>
      <pre class="mc-detailed-list-column" [innerHtml]="queueInfo.info"></pre>
    </div>
  </div>
</mc-collapsable-panel>
