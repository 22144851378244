"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountPackageService = void 0;
const operators_1 = require("rxjs/operators");
const INTERNAL_EMAIL_PROTECT_ACCOUNT_PACKAGE = '[1064]';
const SECURITY_TTP_BROWSER_ISOLATION = '[1091]';
const ADVANCED_ACCOUNT_ADMINISTRATION = 'Advanced Account Administration [1000]';
const FEDERATED_ACCOUNT_ADMINISTRATION = 'Federated Account Administration [1038]';
const MESSAGE_RECOVERY_SERVICE = 'Message Recovery Service (Site) [1031]';
const CLOUD_ARCHIVE_EMAIL = 'Cloud Archive for Email (Site) [1007]';
class AccountPackageService {
    constructor(http, coreService) {
        this.http = http;
        this.coreService = coreService;
    }
    isMessageRecoveryService() {
        let isMessageRecoveryServiceEnabled;
        this.coreService
            .getAccount()
            .pipe(operators_1.first())
            .subscribe(account => {
            isMessageRecoveryServiceEnabled = !!account.packages.filter(pkg => pkg.indexOf(MESSAGE_RECOVERY_SERVICE) >= 0)[0];
        });
        return isMessageRecoveryServiceEnabled;
    }
    isCAEUser() {
        let isCAEUserEnabled;
        this.coreService
            .getAccount()
            .pipe(operators_1.first())
            .subscribe(account => {
            isCAEUserEnabled = !!account.packages.filter(pkg => pkg.indexOf(CLOUD_ARCHIVE_EMAIL) >= 0)[0];
        });
        return isCAEUserEnabled;
    }
    hasInternalEmailProtectAccountPackage() {
        let hasInternalEmailProtectPackage;
        this.coreService
            .getAccount()
            .pipe(operators_1.first())
            .subscribe(account => {
            hasInternalEmailProtectPackage = !!account.packages.filter(pkg => pkg.indexOf(INTERNAL_EMAIL_PROTECT_ACCOUNT_PACKAGE) >= 0)[0];
        });
        return hasInternalEmailProtectPackage;
    }
    hasBrowserIsolationEnabled() {
        let hasBrowserIsolationEnabled;
        this.coreService
            .getAccount()
            .pipe(operators_1.first())
            .subscribe(account => {
            hasBrowserIsolationEnabled = !!account.packages.filter(pkg => pkg.indexOf(SECURITY_TTP_BROWSER_ISOLATION) >= 0)[0];
        });
        return hasBrowserIsolationEnabled;
    }
    isAAAOrFAAUser() {
        let isAAAUser;
        let isFAAUser;
        this.coreService
            .getAccount()
            .pipe(operators_1.first())
            .subscribe(account => {
            isAAAUser = !!account.packages.filter(pkg => pkg.indexOf(ADVANCED_ACCOUNT_ADMINISTRATION) >= 0)[0];
            isFAAUser = !!account.packages.filter(pkg => pkg.indexOf(FEDERATED_ACCOUNT_ADMINISTRATION) >= 0)[0];
        });
        return isAAAUser || isFAAUser;
    }
    browserIsolationRedirect(clickLogUrl) {
        return this.http
            .post('/api/ttp/url/click-log-browser-isolation', { url: clickLogUrl })
            .pipe(operators_1.map((response) => response.first));
    }
}
exports.AccountPackageService = AccountPackageService;
