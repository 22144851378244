"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
(function () {
    'use strict';
    document.createElement('mc-search-box');
    function SearchBoxController($translate, adconCommonsService) {
        const ctrl = this;
        let searchHasBeenTriggerd = false;
        ctrl.minLength = ctrl.minLength ? ctrl.minLength : adconCommonsService.CONSTANTS.FILTER_SEARCH_LIMIT.MIN.VALUE;
        ctrl.warningMessageMin = ctrl.warningMessageMin ? $translate.instant(ctrl.warningMessageMin, { number: adconCommonsService.CONSTANTS.FILTER_SEARCH_LIMIT.MIN.VALUE }) : adconCommonsService.CONSTANTS.FILTER_SEARCH_LIMIT.MIN.TEXT;
        ctrl.maxLength = ctrl.maxLength ? ctrl.maxLength : adconCommonsService.CONSTANTS.FILTER_SEARCH_LIMIT.MAX.VALUE;
        ctrl.warningMessageMax = ctrl.warningMessageMax ? $translate.instant(ctrl.warningMessageMax, { number: adconCommonsService.CONSTANTS.FILTER_SEARCH_LIMIT.MAX.VALUE }) : adconCommonsService.CONSTANTS.FILTER_SEARCH_LIMIT.MAX.TEXT;
        ctrl.translatedPlaceholder = $translate.instant(ctrl.placeholder);
        ctrl.debounceDuration = parseInt(ctrl.debounce, 10) || 200;
        ctrl.submit = function () {
            ctrl.find();
        };
        ctrl.find = function () {
            ctrl.minLength = ctrl.minLength ? ctrl.minLength : adconCommonsService.CONSTANTS.FILTER_SEARCH_LIMIT.MIN.VALUE;
            ctrl.maxLength = ctrl.maxLength ? ctrl.maxLength : adconCommonsService.CONSTANTS.FILTER_SEARCH_LIMIT.MAX.VALUE;
            if (!ctrl.mcDisabled) {
                if (ctrl.searchText.length >= ctrl.minLength && ctrl.searchText.length <= ctrl.maxLength) {
                    ctrl.showWarningMessage = false;
                }
                else {
                    if (ctrl.searchText.length <= ctrl.minLength) {
                        ctrl.translatedWarningMessage = ctrl.warningMessageMin;
                    }
                    if (ctrl.searchText.length >= ctrl.maxLength) {
                        ctrl.translatedWarningMessage = ctrl.warningMessageMax;
                    }
                    ctrl.showWarningMessage = true;
                    return false;
                }
                searchHasBeenTriggerd = true;
                const ret = ctrl.onSearch({ query: ctrl.searchText });
                if (ret) {
                    ctrl.showLoadingIcon = true;
                    ret.then(function () {
                        ctrl.showLoadingIcon = false;
                    });
                }
            }
        };
        ctrl.searchTextChanged = function () {
            ctrl.onChange({ query: ctrl.searchText });
            if (ctrl.searchText.length == 0) {
                ctrl.onClear();
            }
        };
        ctrl.clearInput = function () {
            ctrl.searchText = '';
            ctrl.showWarningMessage = false;
            if (searchHasBeenTriggerd) { // TODO // clear should only trigger when find is invoked firsted
                ctrl.onClear();
                searchHasBeenTriggerd = false;
            }
        };
    }
    angular.module('searchBox.directive', []).component('mcSearchBox', {
        templateUrl: 'components/search-box/search-box.tpl.html',
        controller: ['$translate', 'adconCommonsService', SearchBoxController],
        bindings: {
            searchText: '=',
            onSearch: '&',
            onChange: '&',
            onClear: '&',
            placeholder: '@',
            debounce: '@',
            minLength: '<',
            maxLength: '<',
            mcDisabled: '=',
            warningMaxMessage: '@',
            warningMinMessage: '@'
        }
    });
})();
