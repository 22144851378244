<div class="mc-services-connector-wizard-step-container">
  <mc-wizard-step-header label="$I18N_CREATE_CONNECTOR_WIZARD_GWS_INSTRUCTION_STEP.TITLE">
    <div>
      <div>{{ '$I18N_CREATE_CONNECTOR_WIZARD_GWS_INSTRUCTION_STEP.CONTENT.CONSENT_FLOW_DESC' | translate }}</div>
      <div>{{ '$I18N_CREATE_CONNECTOR_WIZARD_GWS_INSTRUCTION_STEP.CONTENT.FULL_ADMIN_ACCOUNT_DESC' | translate }}</div>
      <div>
        <a target="_blank" href="{{ learnMoreLink }}" rel="noopener noreferrer">
          {{ '$I18N_CREATE_CONNECTOR_WIZARD_GWS_INSTRUCTION_STEP.LABEL.LEARN_MORE' | translate }}
        </a>
      </div>
    </div>
  </mc-wizard-step-header>

  <div>
    <div class="text-bold">
      {{ '$I18N_CREATE_CONNECTOR_WIZARD_GWS_INSTRUCTION_STEP.CONTENT.IDENTIFY_ACCOUNT' | translate }}
    </div>
    <div class="mc-pt-5">
      {{ '$I18N_CREATE_CONNECTOR_WIZARD_GWS_INSTRUCTION_STEP.CONTENT.IDENTIFY_ACCOUNT_PART_1' | translate }}
      <a target="_blank" href="{{ googleAdminPageLink }}" rel="noopener noreferrer">
        {{ '$I18N_CREATE_CONNECTOR_WIZARD_GWS_INSTRUCTION_STEP.LABEL.GOOGLE_ADMIN_CONSOLE' | translate }}
      </a>
      {{ '$I18N_CREATE_CONNECTOR_WIZARD_GWS_INSTRUCTION_STEP.CONTENT.IDENTIFY_ACCOUNT_PART_2' | translate }}
    </div>
  </div>
  <div class="mc-pt-30">
    <div class="text-bold">
      {{ '$I18N_CREATE_CONNECTOR_WIZARD_GWS_INSTRUCTION_STEP.CONTENT.AUTHORIZE_ACCOUNT' | translate }}
    </div>
    <div class="mc-pt-5">
      {{ '$I18N_CREATE_CONNECTOR_WIZARD_GWS_INSTRUCTION_STEP.CONTENT.AUTHORIZE_ACCOUNT_PART_1' | translate }}
      <a target="_blank" href="{{ googleDomainDelegationPageLink }}" rel="noopener noreferrer">
        {{ '$I18N_CREATE_CONNECTOR_WIZARD_GWS_INSTRUCTION_STEP.LABEL.DOMAIN_WIDE_DELEGATION' | translate }}
      </a>
      {{ '$I18N_CREATE_CONNECTOR_WIZARD_GWS_INSTRUCTION_STEP.CONTENT.AUTHORIZE_ACCOUNT_PART_2' | translate }}
    </div>
  </div>
</div>
