'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const operators_1 = require("rxjs/operators");
angular.module('export-logs-aside.service', [])
    .factory('exportLogsAsideService', [
    '$aside',
    'CoreService',
    'userService',
    function ($aside, coreService, userService) {
        const filterExportMap = {};
        const exportUids = {
            AUDIT_LOGS: 'accountauditlogs'
        };
        return {
            openExportAside,
            constants: {
                SYS_WINDOW: {
                    CONNECT: 'connect'
                },
                EXPORT_UID: exportUids,
                FILTER_MAPS: filterExportMap,
                FORMATS: [
                    { key: 'csv', value: 'CSV' },
                    { key: 'xls', value: 'XLS' }
                ],
                EXPORT_ORDER: 'exportOrder'
            }
        };
        function openExportAside(exportUID, from, to, columnsToExport, filters, query, exportFilterMap) {
            $aside.open({
                templateUrl: 'components/export-logs-aside/export-logs-aside.tpl.html',
                placement: 'right',
                size: 'lg',
                backdrop: true,
                windowClass: 'hidden-xs',
                backdropClass: 'hidden-xs',
                controller: angular.noop,
                resolve: {
                    exportUID() {
                        return exportUID;
                    },
                    adconSession() {
                        return coreService.getLegacyAdconSession().pipe(operators_1.first()).toPromise();
                    },
                    user() {
                        return userService.getProfile();
                    },
                    from() {
                        return from;
                    },
                    to() {
                        return to;
                    },
                    columnsToExport() {
                        return columnsToExport;
                    },
                    filters() {
                        return filters;
                    },
                    query() {
                        return query;
                    },
                    exportFilterMap() {
                        return exportFilterMap;
                    }
                }
            });
        }
    }
]);
