"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSelectedQueue = exports.getQueues = exports.getSelectedLexicon = exports.getLexicons = exports.getFailure = exports.getLoading = exports.getSaving = exports.reducer = void 0;
const rule_actions_1 = require("../actions/rule.actions");
const initialState = {
    saving: false,
    loading: false,
    queues: []
};
function reducer(state = initialState, action) {
    switch (action.type) {
        case rule_actions_1.RuleWizardActions.CREATE:
            return Object.assign(Object.assign({}, state), { saving: true });
        case rule_actions_1.RuleWizardActions.CREATE_SUCCESS:
            return Object.assign(Object.assign({}, state), { saving: false, failure: undefined });
        case rule_actions_1.RuleWizardActions.CREATE_FAIL:
            return Object.assign(Object.assign({}, state), { saving: false, failure: Object.assign({}, action.failure) });
        case rule_actions_1.RuleWizardActions.UPDATE:
            return Object.assign(Object.assign({}, state), { saving: true });
        case rule_actions_1.RuleWizardActions.UPDATE_SUCCESS:
            return Object.assign(Object.assign({}, state), { saving: false });
        case rule_actions_1.RuleWizardActions.UPDATE_FAIL:
            return Object.assign(Object.assign({}, state), { saving: false });
        case rule_actions_1.RuleWizardActions.GET_LEXICONS:
            return Object.assign(Object.assign({}, state), { loading: true });
        case rule_actions_1.RuleWizardActions.GET_LEXICONS_SUCCESS:
            return Object.assign(Object.assign({}, state), { loading: false, lexicons: action.payload });
        case rule_actions_1.RuleWizardActions.GET_LEXICONS_FAIL:
            return Object.assign(Object.assign({}, state), { loading: false, failure: action.payload });
        case rule_actions_1.RuleWizardActions.ADD_SELECTED_LEXICON:
            return Object.assign(Object.assign({}, state), { selectedLexicon: action.selectedLexicon });
        case rule_actions_1.RuleWizardActions.CLEAR_SELECTED_LEXICON:
            return Object.assign(Object.assign({}, state), { selectedLexicon: undefined });
        case rule_actions_1.RuleWizardActions.CLOSE_SELECT_LEXICON_PANEL:
            return Object.assign(Object.assign({}, state), { loading: false, lexicons: undefined });
        case rule_actions_1.RuleWizardActions.GET_QUEUES:
            return Object.assign(Object.assign({}, state), { loading: true });
        case rule_actions_1.RuleWizardActions.GET_QUEUES_SUCCESS:
            return Object.assign(Object.assign({}, state), { loading: false, queues: action.payload });
        case rule_actions_1.RuleWizardActions.GET_QUEUES_FAIL:
            return Object.assign(Object.assign({}, state), { loading: false, failure: action.payload });
        case rule_actions_1.RuleWizardActions.ADD_SELECTED_QUEUE:
            return Object.assign(Object.assign({}, state), { selectedQueue: action.selectedQueue });
        case rule_actions_1.RuleWizardActions.CLEAR_SELECTED_QUEUE:
            return Object.assign(Object.assign({}, state), { selectedQueue: undefined });
        case rule_actions_1.RuleWizardActions.CLOSE_SELECT_QUEUE_PANEL:
            return Object.assign(Object.assign({}, state), { loading: false, queues: undefined });
        default:
            return Object.assign({}, state);
    }
}
exports.reducer = reducer;
const getSaving = (state) => state && state.saving;
exports.getSaving = getSaving;
const getLoading = (state) => state && state.loading;
exports.getLoading = getLoading;
const getFailure = (state) => state && state.failure;
exports.getFailure = getFailure;
const getLexicons = (state) => state && state.lexicons;
exports.getLexicons = getLexicons;
const getSelectedLexicon = (state) => state && state.selectedLexicon;
exports.getSelectedLexicon = getSelectedLexicon;
const getQueues = (state) => state && state.queues;
exports.getQueues = getQueues;
const getSelectedQueue = (state) => state && state.selectedQueue;
exports.getSelectedQueue = getSelectedQueue;
