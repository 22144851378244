<div [ngClass]="externalTableSearchCount ? 'mc-tab-group-after-search' : 'mc-tab-group-initial'">
  <mc-tab-group themeLight="true">
    <mc-tab name="$I18N_TRACK_AND_TRACE_TRACKING_MAIN_SEARCH.TABS.SEARCH_BY_DATA">
      <mc-tracking-search-by-data
        (search)="triggerSearch($event)"
        [verticalForm]="!externalTableSearchCount"
        [isUrlSearchEnabled]="isUrlSearchEnabled">
      </mc-tracking-search-by-data>
    </mc-tab>
    <mc-tab name="$I18N_TRACK_AND_TRACE_TRACKING_MAIN_SEARCH.TABS.SEARCH_BY_ID">
      <mc-tracking-search-by-id
        (search)="triggerSearch($event)"
        [verticalForm]="!externalTableSearchCount">
      </mc-tracking-search-by-id>
    </mc-tab>
  </mc-tab-group>
</div>
