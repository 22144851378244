"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgPolicyClpAppSelectStepComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const operators_1 = require("rxjs/operators");
const models_1 = require("../../../../models");
class SwgPolicyClpAppSelectStepComponent {
    constructor(fb, stateService) {
        this.fb = fb;
        this.stateService = stateService;
        this.updateClpSettings = new core_1.EventEmitter();
    }
    ngOnInit() {
        this.form = this.fb.group({
            provider: [
                this.clpSettings.type ? this.findConnector(this.clpSettings.type) : null,
                forms_1.Validators.required
            ]
        });
        this.connectorSelectorPageSubscription = this.form.valueChanges
            .pipe(operators_1.debounceTime(200))
            .subscribe(() => {
            this.select();
        });
    }
    findConnector(type) {
        return this.providers.find(x => x.type === type);
    }
    hasConnectorDefined(type) {
        return !!this.allConnectors.map(x => x.provider).find(x => x === type);
    }
    createConnector() {
        this.stateService.$state.go('services-create-connector-page');
    }
    select() {
        this.updateClpSettings.emit(Object.assign(Object.assign({}, this.clpSettings), { provider: this.form.controls['provider'].value.type }));
    }
    getLabel() {
        return this.isDisabled()
            ? '$I18N_SWG_EDIT_POLICY_CLP_CLOUD_APP_SELECT'
            : '$I18N_SWG_CREATE_POLICY_CLP_CLOUD_APP_SELECT';
    }
    isDisabled() {
        return this.wizardFlowType === models_1.SwgPolicyWizardFlowType.EDIT;
    }
    ngOnDestroy() {
        this.connectorSelectorPageSubscription.unsubscribe();
    }
}
exports.SwgPolicyClpAppSelectStepComponent = SwgPolicyClpAppSelectStepComponent;
