"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DomainAlreadyInListValidator = void 0;
const domain_validator_1 = require("app-new/components/validators/domain/domain.validator");
const operators_1 = require("rxjs/operators");
class DomainAlreadyInListValidator {
    static removeTrailingSlashes(urlStr) {
        return urlStr.replace(/[\/]+$/, '');
    }
    static getOrderedQueryString(url) {
        // WS-1461: Ordering query string parameters.
        // Extract the query string from the URL.
        const query = decodeURIComponent(url.split('?')[1]);
        // Convert the parameters to strings in the format 'key=value'.
        // Objects could also be used here, but strings are easier to sort.
        const params = query.split('&');
        const result = [];
        params.forEach(param => result.push(param));
        // Put the parameters in alphabetical order by key and then by value.
        return result.sort((x, y) => +(x > y) || -(x < y));
    }
    static matchOrderedQueryString(url, urlToCompare) {
        // If the hosts and paths are the same in both URLs, then compare the query strings.
        // If the hosts and paths are different, don't worry about query strings and simply return false.
        // Firstly, convert the host in the URLs to lower case.
        url =
            url.split('/')[0].toLowerCase() +
                (this.pathRegex.test(url) ? url.match(this.pathRegex)[0] : '');
        urlToCompare =
            urlToCompare.split('/')[0].toLowerCase() +
                (this.pathRegex.test(urlToCompare) ? urlToCompare.match(this.pathRegex)[0] : '');
        // Now do the query string comparison if we've entered a query string.
        if (/\?/.test(urlToCompare)) {
            if (url.split('?')[0] === urlToCompare.split('?')[0]) {
                return (JSON.stringify(this.getOrderedQueryString(url)) ===
                    JSON.stringify(this.getOrderedQueryString(urlToCompare)));
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    static matchCaseInsensitiveHost(url, urlToCompare) {
        // Remove any trailing slashes from the URLs.
        url = this.removeTrailingSlashes(url);
        urlToCompare = this.removeTrailingSlashes(urlToCompare);
        // Get the hostnames from the two URLs, do a case-insensitive comparison and check the paths if they're equal.
        if (url.split('/')[0].toLowerCase() === urlToCompare.split('/')[0].toLowerCase()) {
            // If the hostnames match, check the path (i.e. the part after the slash).
            if (this.pathRegex.test(url) && this.pathRegex.test(urlToCompare)) {
                // Both URLs have paths, so check if they're equal or not.
                return url.match(this.pathRegex)[1] === urlToCompare.match(this.pathRegex)[1];
            }
            else if (this.pathRegex.test(url) !== this.pathRegex.test(urlToCompare)) {
                // One URL has a path, and the other doesn't. This therefore isn't a match, so return false.
                return false;
            }
            else {
                // Both hosts match with no paths specified, so return true.
                return true;
            }
        }
        else {
            // The hostnames don't match, so simply return false.
            return false;
        }
    }
    static createValidator(service) {
        return (control) => {
            let enteredUrl = '';
            if (control && control.value) {
                enteredUrl = domain_validator_1.checkIfUrlOrDomain(control.value).value;
                // Remove any trailing slashes.
                this.removeTrailingSlashes(enteredUrl);
            }
            return service.getDomains().pipe(operators_1.mergeMap(x => x), operators_1.find(row => row.value === enteredUrl
                ? true
                : this.matchCaseInsensitiveHost(row.value, enteredUrl)
                    ? true
                    : this.matchOrderedQueryString(row.value, enteredUrl)), operators_1.map(res => res
                ? res.action === 'block'
                    ? { domainAlreadyBlockedInList: true }
                    : { domainAlreadyAddedInList: true }
                : null));
        };
    }
}
exports.DomainAlreadyInListValidator = DomainAlreadyInListValidator;
DomainAlreadyInListValidator.pathRegex = /\/(.*)/;
