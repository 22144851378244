'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const moment = require("moment");
require("angular-ui-bootstrap"); //ui.bootstrap.modal
require("app/components/date-picker-inline/date-picker-inline"); //date.picker.inline.directive
require("app/components/date-time-picker/date-time-picker"); //date-time-picker.directive
angular.module('date.range.modal.controller', ['ui.bootstrap.modal', 'date.picker.inline', 'date-time-picker'])
    .controller('DateRangeModalController', ['$scope', 'dateRangeModalService', function ($scope, dateRangeModalService) {
        const self = this;
        self.startDate = dateRangeModalService.modalParamaters.startDate;
        self.endDate = dateRangeModalService.modalParamaters.endDate;
        self.showTimePicker = dateRangeModalService.modalParamaters.showTimePicker;
        self.minStartDate = dateRangeModalService.modalParamaters.minStartDate;
        self.maxEndDate = dateRangeModalService.modalParamaters.maxEndDate;
        self.titleFrom = 'DATE_RANGE_MODAL_FROM';
        self.titleTo = 'DATE_RANGE_MODAL_TO';
        if (isDateString(self.startDate)) {
            self.startDate = new Date(self.startDate);
        }
        if (isDateString(self.endDate)) {
            self.endDate = new Date(self.endDate);
        }
        if (isDateString(self.minStartDate)) {
            self.minStartDate = new Date(self.minStartDate);
        }
        if (isDateString(self.maxEndDate)) {
            self.maxEndDate = new Date(self.maxEndDate);
        }
        self.submit = function () {
            $scope.$close({ startDate: self.startDate, endDate: self.endDate });
        };
        self.isDisabled = function () {
            return false;
        };
        self.startDateChange = function (date) {
            self.startDate = date;
            alterDate('endDate', 'startDate');
        };
        self.endDateChange = function (date) {
            self.endDate = date;
            alterDate('startDate', 'endDate');
        };
        function alterDate(dateToAlter, otherDate) {
            let date;
            if ((self.startDate.getFullYear() > self.endDate.getFullYear()) ||
                ((self.startDate.getFullYear() === self.endDate.getFullYear()) &&
                    (self.startDate.getMonth() > self.endDate.getMonth())) ||
                ((self.startDate.getFullYear() === self.endDate.getFullYear()) &&
                    (self.startDate.getMonth() === self.endDate.getMonth())) &&
                    (self.startDate.getDate() > self.endDate.getDate())) {
                date = new Date(self[otherDate]);
                date.setHours(self[dateToAlter].getHours());
                date.setMinutes(self[dateToAlter].getMinutes());
                self[dateToAlter] = date;
            }
            else if ((self.startDate.getFullYear() === self.endDate.getFullYear()) &&
                (self.startDate.getMonth() === self.endDate.getMonth()) &&
                (self.startDate.getDate() === self.endDate.getDate()) &&
                ((self.startDate.getHours() > self.endDate.getHours()) ||
                    ((self.startDate.getHours() === self.endDate.getHours()) &&
                        (self.startDate.getMinutes() > self.endDate.getMinutes())))) {
                self[dateToAlter] = new Date(self[otherDate]);
            }
        }
        function isDateString(str) {
            return moment(str, moment.ISO_8601, true).isValid();
        }
    }]);
