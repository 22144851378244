"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ3 = exports.ɵ2 = exports.ɵ1 = exports.ɵ0 = exports.reducer = exports.initialCyberGraphWizardState = void 0;
const cybergraph_policies_create_actions_1 = require("../actions/cybergraph-policies-create.actions");
const cybergraph_policy_wizard_models_1 = require("../models/cybergraph-policy-wizard.models");
const cybergraph_policies_edit_actions_1 = require("../actions/cybergraph-policies-edit.actions");
const cybergraph_policies_model_1 = require("../models/cybergraph-policies.model");
const ɵ0 = {
    name: '',
    description: '',
    policyOverride: false,
    codebreakerStatus: cybergraph_policies_model_1.CyberGraphDynamicBannersEnum.LEARNING,
    silencerStatus: cybergraph_policies_model_1.CyberGraphTrackersEnum.OFF,
    reportingStatus: false
}, ɵ1 = undefined, ɵ2 = undefined, ɵ3 = {
    policyStatusEnabled: true
};
exports.ɵ0 = ɵ0;
exports.ɵ1 = ɵ1;
exports.ɵ2 = ɵ2;
exports.ɵ3 = ɵ3;
exports.initialCyberGraphWizardState = {
    wizardFlowType: cybergraph_policy_wizard_models_1.PolicyWizardFlowType.CREATE,
    apiIsProcessing: false,
    detailsStep: {
        data: ɵ0
    },
    incomingAppliesToStep: {
        data: ɵ1,
        stepConfiguration: {
            fromToWhereAttributes: []
        }
    },
    appliesToStep: {
        data: ɵ2,
        stepConfiguration: {
            fromToWhereAttributes: []
        }
    },
    summaryStep: {
        data: ɵ3
    }
};
function reducer(state = exports.initialCyberGraphWizardState, action) {
    switch (action.type) {
        case cybergraph_policies_create_actions_1.CYBERGRAPH_BEGIN_API_IS_PROCESSING:
            return Object.assign(Object.assign({}, state), { apiIsProcessing: true });
        case cybergraph_policies_create_actions_1.CYBERGRAPH_END_API_IS_PROCESSING:
            return Object.assign(Object.assign({}, state), { apiIsProcessing: true });
        case cybergraph_policies_create_actions_1.CYBERGRAPH_SET_POLICY_STATUS_ENABLED:
            return Object.assign(Object.assign({}, state), { summaryStep: {
                    data: {
                        policyStatusEnabled: action.payload
                    }
                } });
        case cybergraph_policies_create_actions_1.CYBERGRAPH_SAVE_DETAILS_STEP_DATA:
            return Object.assign(Object.assign({}, state), { detailsStep: {
                    data: Object.assign(Object.assign({}, state.detailsStep.data), action.payload)
                } });
        case cybergraph_policies_create_actions_1.CYBERGRAPH_SAVE_APPLIES_TO_STEP_DATA:
            return Object.assign(Object.assign({}, state), { appliesToStep: {
                    data: action.payload.map((rule, index) => {
                        const mappedRule = Object.assign({}, rule);
                        if (state.incomingAppliesToStep.data &&
                            state.incomingAppliesToStep.data[index] &&
                            state.wizardFlowType === cybergraph_policy_wizard_models_1.PolicyWizardFlowType.EDIT) {
                            const ruleId = state.incomingAppliesToStep.data[index].id;
                            if (ruleId) {
                                mappedRule['id'] = ruleId;
                            }
                        }
                        return mappedRule;
                    }),
                    stepConfiguration: Object.assign({}, state.appliesToStep.stepConfiguration)
                } });
        case cybergraph_policies_create_actions_1.GET_ADDRESS_ATTRIBUTE_OPTIONS_SUCCESS:
            return Object.assign(Object.assign({}, state), { appliesToStep: {
                    data: [...state.appliesToStep.data],
                    stepConfiguration: {
                        fromToWhereAttributes: action.payload
                    }
                } });
        case cybergraph_policies_create_actions_1.CYBERGRAPH_CREATE_POLICY_SUCCESS:
            return Object.assign({}, exports.initialCyberGraphWizardState);
        case cybergraph_policies_edit_actions_1.CYBERGRAPH_UPDATE_EDIT_POLICY_WIZARD:
            return Object.assign(Object.assign({}, action.payload), { appliesToStep: Object.assign(Object.assign({}, state.appliesToStep), { data: [...action.payload.appliesToStep.data] }), incomingAppliesToStep: Object.assign(Object.assign({}, state.appliesToStep), { data: [...action.payload.appliesToStep.data] }) });
        case cybergraph_policies_create_actions_1.CYBERGRAPH_RESET_POLICY_WIZARD:
            return Object.assign({}, exports.initialCyberGraphWizardState);
        default:
            return Object.assign({}, state);
    }
}
exports.reducer = reducer;
