<form [formGroup]="formFiles" novalidate>

  <!--<mc-list-dropdown-button id="third" disableCaret="true" label="$I18N_ARCHIVE_VIEWER_SEARCH_TAB_CONTENT_FILES">-->
    <!--<mc-dropdown-item formControlName="type" label="{{'$I18N_ARCHIVE_VIEWER_SEARCH_TAB_CONTENT_FILES_OPTIONAL' | translate }}"></mc-dropdown-item>-->
    <!--<mc-dropdown-item formControlName="type" (click)="option.action(option.label)" *ngFor="let option of options" [label]="option.label">{{option.label}}</mc-dropdown-item>-->
  <!--</mc-list-dropdown-button>-->

  <!-- a better approach: Reactive formControl, no multiple events, select not ul/li -->
  <mc-field label="$I18N_ARCHIVE_VIEWER_SEARCH_TAB_CONTENT_FILES" class="col-sm-4 no-padding">
    <select class="form-control" formControlName="type" class="form-control mc-select-dropdown">
      <option value="{{option.label}}" *ngFor="let option of options">{{option.translate | translate}}</option>
    </select>
  </mc-field>

  <div class="col-sm-8 mc-input-dropdown">
    <input type="text"
           class="form-control mc-select-input"
           formControlName="docs"
           [placeholder]="placeholder" />
  </div>

</form>
