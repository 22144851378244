<div class="mc-services-im-sync-wizard-step-container">
  <mc-wizard-step-header [label]="'$I18N_ARCHIVE_IM_SYNC_WIZARD.IM_SYNC_TASK.CONNECTION_STEP.DESCRIPTION'">
    <p>
      {{ '$I18N_ARCHIVE_IM_SYNC_WIZARD.IM_SYNC_TASK.CONNECTION_STEP.HELP_TEXT' | translate }}
      <ng-container *ngIf="hasConnectors">
        {{ '$I18N_ARCHIVE_IM_SYNC_WIZARD.IM_SYNC_TASK.CONNECTION_STEP.TO' | translate }}
        <a data-test="create-connector-link"
          (click)="createConnector()">{{ '$I18N_ARCHIVE_IM_SYNC_WIZARD.IM_SYNC_TASK.CONNECTION_STEP.CREATE_CONNECTOR' | translate }}</a>
        {{ '$I18N_ARCHIVE_IM_SYNC_WIZARD.IM_SYNC_TASK.CONNECTION_STEP.GO_TO_PAGE' | translate }}
      </ng-container>
    </p>
  </mc-wizard-step-header>

  <form data-test="connector-selection-form" [formGroup]="form" class="form-horizontal" mcDefaultLabelClass="col-sm-3"
    mcDefaultControlContainerClass="col-sm-9" *ngIf="hasConnectors; else noConnectors">
    <mc-field label="{{ '$I18N_ARCHIVE_IM_SYNC_WIZARD.IM_SYNC_TASK.CONNECTION_STEP.FIELD_CONNECTOR' | translate }}"
      useTranslation="true" errorPrefix="$I18N_ARCHIVE_IM_SYNC_WIZARD.IM_SYNC_TASK.CONNECTION_STEP.CONNECTOR_ERROR">
      <mc-select  data-test="connector-selector" (mouseenter)="reloadConnectors()" formControlName="connector" [options]="connectorsList" [defaultValue]="defaultSelectedConnector">
      </mc-select>
    </mc-field>
  </form>


  <ng-template #noConnectors>
    <mc-no-connectors data-test="no-connectors" (onCreateConnector)="createConnector()"></mc-no-connectors>
  </ng-template>
</div>
