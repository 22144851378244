"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IdentityAppCancelModelCloseAction = exports.IdentityAppCancelModelNoAction = exports.IdentityAppCancelModelYesAction = exports.IdentityAppCancelModelOpenAction = exports.IdentityUpdateAppSuccessNotification = exports.IdentityUpdateCloseAppAsideAction = exports.IdentityUpdateAppDetailsFailAction = exports.IdentityUpdateAppDetailsSuccessAction = exports.IdentityUpdateAppDetailsAction = exports.IdentitySwitchAppAsideToViewAction = exports.IdentitySwitchAppAsideToEditAction = exports.IdentityLoadAppDetailsFailAction = exports.IdentityLoadAppDetailsSuccessAction = exports.IdentityLoadAppDetailsAction = exports.IdentityOpenAppAsideAction = exports.CANCEL_MODAL_CLOSE = exports.CANCEL_MODAL_YES_RESPONSE = exports.CANCEL_MODAL_NO_RESPONSE = exports.CANCEL_MODAL_OPEN = exports.UPDATE_CLOSE_APP = exports.UPDATE_FAIL = exports.UPDATE_SUCCESS_NOTIFY = exports.UPDATE_SUCCESS = exports.UPDATE = exports.SWITCH_TO_VIEW = exports.SWITCH_TO_EDIT = exports.LOAD_FAIL = exports.LOAD_SUCCESS = exports.LOAD = exports.OPEN_ASIDE = void 0;
exports.OPEN_ASIDE = '[IdentityAppDetails] Open App Details Aside Panel in View Mode';
exports.LOAD = '[IdentityAppDetails] Load App Details for Aside Panel';
exports.LOAD_SUCCESS = '[IdentityAppDetails] Load App Details for Aside Panel Successful';
exports.LOAD_FAIL = '[IdentityAppDetails] Load App Details for Aside Panel Failure';
exports.SWITCH_TO_EDIT = '[IdentityAppDetails] Switch App Details Panel to Edit Mode';
exports.SWITCH_TO_VIEW = '[IdentityAppDetails] Switch App Details Panel to View Mode';
exports.UPDATE = '[IdentityAppDetails] Update Details for an App';
exports.UPDATE_SUCCESS = '[IdentityAppDetails] Update Details for an App Successful';
exports.UPDATE_SUCCESS_NOTIFY = '[IdentityAppDetails] Show Update Success Notification';
exports.UPDATE_FAIL = '[IdentityAppDetails] Update Details for an App Fail';
exports.UPDATE_CLOSE_APP = '[IdentityAppDetails] Close App Aside after Update';
exports.CANCEL_MODAL_OPEN = '[IdentityAppDetails] Show App Update Cancel Confirmation';
exports.CANCEL_MODAL_NO_RESPONSE = '[IdentityAppDetails] Cancel App Update No Response';
exports.CANCEL_MODAL_YES_RESPONSE = '[IdentityAppDetails] Cancel App Update Yes Response';
exports.CANCEL_MODAL_CLOSE = '[IdentityAppDetails] Close App Update Cancel Confirmation';
class IdentityOpenAppAsideAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.OPEN_ASIDE;
    }
}
exports.IdentityOpenAppAsideAction = IdentityOpenAppAsideAction;
class IdentityLoadAppDetailsAction {
    constructor(appId) {
        this.appId = appId;
        this.type = exports.LOAD;
    }
}
exports.IdentityLoadAppDetailsAction = IdentityLoadAppDetailsAction;
class IdentityLoadAppDetailsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOAD_SUCCESS;
    }
}
exports.IdentityLoadAppDetailsSuccessAction = IdentityLoadAppDetailsSuccessAction;
class IdentityLoadAppDetailsFailAction {
    constructor() {
        this.type = exports.LOAD_FAIL;
    }
}
exports.IdentityLoadAppDetailsFailAction = IdentityLoadAppDetailsFailAction;
class IdentitySwitchAppAsideToEditAction {
    constructor() {
        this.type = exports.SWITCH_TO_EDIT;
    }
}
exports.IdentitySwitchAppAsideToEditAction = IdentitySwitchAppAsideToEditAction;
class IdentitySwitchAppAsideToViewAction {
    constructor() {
        this.type = exports.SWITCH_TO_VIEW;
    }
}
exports.IdentitySwitchAppAsideToViewAction = IdentitySwitchAppAsideToViewAction;
class IdentityUpdateAppDetailsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.UPDATE;
    }
}
exports.IdentityUpdateAppDetailsAction = IdentityUpdateAppDetailsAction;
class IdentityUpdateAppDetailsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.UPDATE_SUCCESS;
    }
}
exports.IdentityUpdateAppDetailsSuccessAction = IdentityUpdateAppDetailsSuccessAction;
class IdentityUpdateAppDetailsFailAction {
    constructor(failMessage) {
        this.failMessage = failMessage;
        this.type = exports.UPDATE_FAIL;
    }
}
exports.IdentityUpdateAppDetailsFailAction = IdentityUpdateAppDetailsFailAction;
class IdentityUpdateCloseAppAsideAction {
    constructor() {
        this.type = exports.UPDATE_CLOSE_APP;
    }
}
exports.IdentityUpdateCloseAppAsideAction = IdentityUpdateCloseAppAsideAction;
class IdentityUpdateAppSuccessNotification {
    constructor() {
        this.type = exports.UPDATE_SUCCESS_NOTIFY;
    }
}
exports.IdentityUpdateAppSuccessNotification = IdentityUpdateAppSuccessNotification;
class IdentityAppCancelModelOpenAction {
    constructor() {
        this.type = exports.CANCEL_MODAL_OPEN;
    }
}
exports.IdentityAppCancelModelOpenAction = IdentityAppCancelModelOpenAction;
class IdentityAppCancelModelYesAction {
    constructor() {
        this.type = exports.CANCEL_MODAL_YES_RESPONSE;
    }
}
exports.IdentityAppCancelModelYesAction = IdentityAppCancelModelYesAction;
class IdentityAppCancelModelNoAction {
    constructor() {
        this.type = exports.CANCEL_MODAL_NO_RESPONSE;
    }
}
exports.IdentityAppCancelModelNoAction = IdentityAppCancelModelNoAction;
class IdentityAppCancelModelCloseAction {
    constructor() {
        this.type = exports.CANCEL_MODAL_CLOSE;
    }
}
exports.IdentityAppCancelModelCloseAction = IdentityAppCancelModelCloseAction;
