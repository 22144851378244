"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchFolderNavigatorComponent = void 0;
const core_1 = require("@angular/core");
class SearchFolderNavigatorComponent {
    constructor() {
        this.onGoLevelUp = new core_1.EventEmitter();
    }
}
exports.SearchFolderNavigatorComponent = SearchFolderNavigatorComponent;
