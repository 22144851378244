"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
(function () {
    'use strict';
    /* ======================================================== *\

      = group-selector - controller

   \* ======================================================== */
    angular.module('backup-restore-group-selector.controller', [])
        .controller('GroupSelectorController', ['$scope', '$q', '$translate', 'directoryService', 'ngProgressFactory', function ($scope, $q, $translate, directoryService, ngProgressFactory) {
            const self = this;
            const ngProgress = ngProgressFactory.createInstance();
            /* -------------------------------------------------------- *\
           - interface
        \* -------------------------------------------------------- */
            self.groupTabs = [];
            self.activeTab = {};
            self.onTabActivated = activateTab;
            self.onNodeExpanded = expandNode;
            self.onNodeSelected = selectNode;
            self.onGroupSearched = searchGroup;
            self.onResultSelected = selectResult;
            self.clearSearch = clearSearch;
            self.isLoading = true;
            self.SOURCE = {
                CLOUD: directoryService.constants.SOURCE.CLOUD,
                LDAP: directoryService.constants.SOURCE.LDAP
            };
            /* -------------------------------------------------------- *\
           - initialisation
        \* -------------------------------------------------------- */
            activate(self.SOURCE.LDAP);
            /* -------------------------------------------------------- *\
           - implementation
        \* -------------------------------------------------------- */
            function activate(selectTab) {
                const qcloud = loadGroups(self.SOURCE.CLOUD);
                const qldap = loadGroups(self.SOURCE.LDAP);
                $q.all([qcloud, qldap]).then(function () {
                    activateTab(selectTab);
                });
            }
            function transformGroupToNode(group) {
                return {
                    id: group.id,
                    title: group.description,
                    name: group.description + (group.folderCount ? ' (' + group.folderCount + ')' : ''),
                    collapse: true,
                    subFolderCount: group.folderCount,
                    nodes: (group.folders && group.folders.length > 0) ? (group.folders.map(transformGroupToNode)) : []
                };
            }
            // -- transformNodes --------
            function transformGroupToNodes(groups) {
                const nodesItems = [];
                angular.forEach(groups, function (group) {
                    nodesItems.push(transformGroupToNode(group));
                });
                return nodesItems;
            }
            // -- loadGroups --------
            function loadGroups(source) {
                return directoryService.getGroup({ source, depth: 1 }).then(function (data) {
                    if (!data.err) {
                        if (data.any) {
                            self.groupTabs[source] = getGroupRoot(source, transformGroupToNodes(data.all));
                        }
                        else {
                            self.groupTabs[source] = getGroupRoot(source);
                        }
                        self.isLoading = false;
                    }
                    else {
                        self.isLoading = false;
                        self.groupTabs[source] = getGroupRoot(source, undefined, directoryService.getError().getFirstKey(data));
                    }
                }, function (err) {
                    self.isLoading = false;
                    self.groupTabs[source] = getGroupRoot(source, undefined, directoryService.getError(err));
                });
            }
            // -- getGroupRoot --------
            function getGroupRoot(source, groups, err) {
                const root = {
                    source,
                    //TODO: make search-component utilise interpolation for placeholder text
                    searchPlaceholder: ((source == self.SOURCE.LDAP) ? $translate.instant('$I18N_COMPONENT_GROUP_SELECTOR_SEARCH_PLACEHOLDER_LDAP') : $translate.instant('$I18N_COMPONENT_GROUP_SELECTOR_SEARCH_PLACEHOLDER_CLOUD')),
                    folders: ((groups != undefined) ? getFolders(groups) : []),
                    search: '',
                    results: [],
                    view: 'tree',
                    error: ''
                };
                if (err) {
                    setError(root, err);
                }
                return root;
            }
            // -- setError --------
            function setError(tab, err) {
                err.showErrorNotification = null;
                tab.error = $translate.instant('$I18N_COMPONENT_GROUP_SELECTOR_ERROR') + (err ? (' ' + $translate.instant(err)) : '');
                tab.view = 'error';
                self.isLoading = false;
                self.isSearching = false;
                ngProgress.complete();
            }
            function getFolders(groups) {
                const folders = [];
                angular.forEach(groups, function (group) {
                    folders.push(group);
                });
                return folders;
            }
            /* -------------------------------------------------------- *\
           - events
        \* -------------------------------------------------------- */
            // -- activateTab --------
            function activateTab(source) {
                self.activeTab = self.groupTabs[source];
            }
            // -- expandNode --------
            function expandNode(item) {
                self.isLoading = true;
                directoryService.getGroup({ source: self.activeTab.source, depth: 1, id: item.id }).then(function (data) {
                    if (!data.err) {
                        item.nodes = transformGroupToNode(data.first).nodes;
                        item.subFolderCount = item.nodes.length;
                        self.isLoading = false;
                    }
                    else {
                        setError(self.activeTab, directoryService.getError().getFirstKey(data));
                    }
                    self.isLoading = false;
                }, function (err) {
                    setError(self.activeTab, directoryService.getError(err));
                });
            }
            // -- selectNode --------
            function selectNode(item) {
                returnGroup({
                    id: item.id,
                    description: item.title,
                    source: self.activeTab.source
                });
            }
            // -- returnGroup --------
            function returnGroup(item) {
                $scope.$close(item);
            }
            // -- searchGroup --------
            function searchGroup() {
                if (self.activeTab.search && self.activeTab.search.length >= 3) {
                    self.isSearching = true;
                    self.activeTab.results = [];
                    self.activeTab.view = 'list';
                    directoryService.findGroups({ query: self.activeTab.search, source: self.activeTab.source }).then(function (data) {
                        if (!data.err) {
                            self.activeTab.results = data.first.folders;
                            self.isSearching = false;
                        }
                        else {
                            setError(self.activeTab, directoryService.getError().getFirstKey(data));
                        }
                    }, function (err) {
                        setError(self.activeTab, directoryService.getError(err));
                    });
                }
            }
            // -- selectResult --------
            function selectResult(item) {
                returnGroup({
                    id: item.id,
                    description: item.description,
                    source: self.activeTab.source
                });
            }
            function clearSearch() {
                self.groupTabs = [];
                activate(self.activeTab.source);
            }
        }]);
}());
