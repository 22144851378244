<mc-wizard-step-header [label]="'$I18N_SWG_POLICY_WEB_LOGGING_TITLE'"> </mc-wizard-step-header>
<div class="mc-logging-policy">
  <form class="panel-padding-bottom" [formGroup]="loggingPageForm">
    <div class="form-group">
      <div class="radio panel-half-padding-bottom">
        <label class="text-bold">
          <input type="radio" name="level" value="all" formControlName="level" />
          {{ '$I18N_SWG_POLICY_WEB_LOGGING_ALL' | translate }}
        </label>
      </div>

      <div class="radio panel-half-padding-bottom">
        <label class="text-bold">
          <input type="radio" name="level" value="none" formControlName="level" />
          {{ '$I18N_SWG_POLICY_WEB_LOGGING_NONE' | translate }}
        </label>
      </div>

      <div class="radio panel-half-padding-bottom">
        <label class="text-bold">
          <input type="radio" name="level" value="security" formControlName="level" />
          {{ '$I18N_SWG_POLICY_WEB_LOGGING_SECURITY' | translate }}
        </label>
      </div>
    </div>
  </form>
</div>
