"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPagination = exports.pageSize = exports.selectedRow = exports.reducer = exports.initialState = void 0;
const paginationAction = require("../../actions/pagination/pagination.actions");
exports.initialState = {
    selectedRow: undefined,
    pagination: undefined
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case paginationAction.PAGINATION_SET_SELECTED_ROW:
            return Object.assign(Object.assign({}, state), { selectedRow: action.row });
        case paginationAction.PAGINATION_SET:
            return Object.assign(Object.assign({}, state), { pagination: action.pagination });
        default:
            return state;
    }
}
exports.reducer = reducer;
const selectedRow = (state) => state.selectedRow;
exports.selectedRow = selectedRow;
const pageSize = (state) => state.pagination.pageSize;
exports.pageSize = pageSize;
const getPagination = (state) => state.pagination;
exports.getPagination = getPagination;
