<mc-tab-group [initialActiveTabIndex]="tabIndex" (tabChange)="onTabChanged($event)">
  <mc-tab name="$I18N_MESSAGE_CENTER_REJECTED_AND_DEFERRED_BREADCRUMBS.REJECTED"
    *mcCapabilities="'Permission.MONITORING_REJECTIONS_READ'; else noRejectedPermissions">
    <mc-rejected-list></mc-rejected-list>
  </mc-tab>
  <ng-template #noRejectedPermissions>
    <mc-tab name="$I18N_MESSAGE_CENTER_REJECTED_AND_DEFERRED_BREADCRUMBS.REJECTED" disabled=true>
      <!-- We are using this template to avoid leaking the rejected-list in the DOM,
        when the user does not have permissions -->
    </mc-tab>
  </ng-template>

  <mc-tab name="$I18N_MESSAGE_CENTER_REJECTED_AND_DEFERRED_BREADCRUMBS.DEFERRED"
    *mcCapabilities="'Permission.MONITORING_CONNECTIONS_READ'; else noDeferredPermissions">
    <mc-deferred-list></mc-deferred-list>
  </mc-tab>
  <ng-template #noDeferredPermissions>
    <mc-tab name="$I18N_MESSAGE_CENTER_REJECTED_AND_DEFERRED_BREADCRUMBS.DEFERRED" disabled=true
      tooltip="{{ ('$I18N_MESSAGE_CENTER_REJECTED_AND_DEFERRED_DISABLED_TAB_TOOLTIP.DEFERRED' | translate) }}">
      <!-- We are using this template to avoid leaking the deferred-list in the DOM,
        when the user does not have permissions -->
    </mc-tab>
  </ng-template>
</mc-tab-group>