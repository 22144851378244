"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateConnectorModalComponent = void 0;
class CreateConnectorModalComponent {
    constructor(overlayRef) {
        this.overlayRef = overlayRef;
    }
    close(confirmed) {
        this.overlayRef.close(confirmed);
    }
}
exports.CreateConnectorModalComponent = CreateConnectorModalComponent;
