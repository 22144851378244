"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PolicyDetailsComponent = void 0;
const policy_1 = require("../../models/policy");
class PolicyDetailsComponent {
    constructor(translateService) {
        this.translateService = translateService;
        this.trustedIpPermission = policy_1.LocationPermission.IP_NO_2FA;
        this.permittedIpPermission = policy_1.LocationPermission.IP_ALLOW_LOGIN;
        this.ewsDomainSetting = policy_1.DelegatedAuthOption.EWS;
        this.ipLocationPermissions = [
            policy_1.LocationPermission.IP_ALLOW_LOGIN,
            policy_1.LocationPermission.IP_NO_2FA
        ];
        this.ipAddresses = null;
        this.policy = null;
    }
    // eslint-disable-next-line @angular-eslint/no-input-rename
    set valuePolicy(value) {
        this.policy = value;
        const locationSettings = this.policy.policyDetails.locationSettings;
        if (this.ipLocationPermissions.includes(locationSettings.permission)) {
            this.ipAddresses = locationSettings.ipValue.join(', ');
        }
        else {
            this.ipAddresses = null;
        }
        switch (value.policyDetails.locationSettings.permission) {
            case policy_1.LocationPermission.IP_NO_2FA:
                this.ipAddressesLabel = this.translateService.instant('$I18N_IDENTITY_POLICY_DETAILS.LOCATION.TRUSTED_IP_ADDRESSES');
                break;
            case policy_1.LocationPermission.IP_ALLOW_LOGIN:
                this.ipAddressesLabel = this.translateService.instant('$I18N_IDENTITY_POLICY_DETAILS.LOCATION.PERMITTED_IP_ADDRESSES');
                break;
            default:
                this.ipAddressesLabel = '';
                break;
        }
        this.mimeAuthEnabled = value.policyDetails.secondFactorOption === policy_1.SecondFactorOption.MIMEAUTH;
        this.displaySecondFactorOption = !this.mimeAuthEnabled;
        this.displayRequestBeforePassword =
            this.displaySecondFactorOption &&
                value.policyDetails.secondFactorOption !== policy_1.SecondFactorOption.NONE;
    }
    formatEnabled(enabled) {
        return enabled
            ? this.translateService.instant('$I18N_IDENTITY_POLICY_DETAILS.ENABLED')
            : this.translateService.instant('$I18N_IDENTITY_POLICY_DETAILS.DISABLED');
    }
}
exports.PolicyDetailsComponent = PolicyDetailsComponent;
