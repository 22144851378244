"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMinLengthFields = exports.MinLength = void 0;
require("reflect-metadata");
const forms_1 = require("@angular/forms");
const MIN_LENGTH_METADATA_KEY = Symbol('MC-MIN-LENGTH-FIELD');
function MinLength(min) {
    return (target, propertyKey) => {
        let minLengthFields = Reflect.getMetadata(MIN_LENGTH_METADATA_KEY, target);
        const minLengthField = {};
        minLengthField[propertyKey] = forms_1.Validators.minLength(min);
        if (minLengthFields) {
            minLengthFields.push(minLengthField);
        }
        else {
            minLengthFields = [minLengthField];
        }
        Reflect.defineMetadata(MIN_LENGTH_METADATA_KEY, minLengthFields, target);
    };
}
exports.MinLength = MinLength;
function getMinLengthFields(origin) {
    const properties = Reflect.getMetadata(MIN_LENGTH_METADATA_KEY, origin);
    let result = {};
    if (properties) {
        properties.forEach(property => (result = Object.assign(Object.assign({}, result), property)));
    }
    return result;
}
exports.getMinLengthFields = getMinLengthFields;
