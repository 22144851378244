<form [formGroup]="form">
  <mc-enable-switch
    [label]="fieldLabel"
    [contentDivClass]="'row mc-detailed-list-row panel-padding-top'"
    [labelClass]="'col-xs-4 mc-detailed-list-label'"
    [switchDivClass]="'mc-detailed-list-column'"
    formControlName="enabled"
    name="enabled"
  ></mc-enable-switch>

  <ng-container *ngIf="value?.enabled">
    <mc-search-okta-groups
      [searchResults]="searchOktaGroupResults"
      [isLoadingOktaGroups]="isLoadingOktaGroups"
      (onSearch)="onSearchOktaGroups.emit($event)"
      (onSelect)="updateOktaGroup($event)"
      formControlName="group"
      name="group"
    ></mc-search-okta-groups>
  </ng-container>
</form>
