"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectLexiconComponent = void 0;
const core_1 = require("@angular/core");
class SelectLexiconComponent {
    constructor(overlayRef) {
        this.overlayRef = overlayRef;
        this.searchQueryChanged = new core_1.EventEmitter();
        this.lexiconSelected = new core_1.EventEmitter();
        this.noLexiconsFound = false;
    }
    ngOnInit() {
        this.searchLexicon.nativeElement.focus();
    }
    ngOnChanges(changes) {
        if (changes.lexicons && changes.lexicons.currentValue) {
            this.noLexiconsFound = changes.lexicons.currentValue.length === 0;
        }
    }
    onSearchChange(event) {
        const searchQuery = event.target.value;
        this.searchQueryChanged.emit(searchQuery);
    }
    onClose() {
        this.overlayRef.close();
    }
    selectLexicon(lexicon) {
        this.onClose();
        this.lexiconSelected.emit(lexicon);
    }
    isLexiconSelected(lexicon) {
        if (!this.selectedLexicon) {
            return false;
        }
        return this.selectedLexicon.id === lexicon.id;
    }
    get sortedLexicons() {
        return this.lexicons ? this.lexicons.slice().sort((a, b) => a.name.localeCompare(b.name)) : [];
    }
}
exports.SelectLexiconComponent = SelectLexiconComponent;
