<div class="mc-directory-sync-wizard-container">
  <mc-wizard-step-header [label]="'$I18N_DIRECTORY_SYNC_WIZARD.STEPS.OPTIONS.HEADER'"></mc-wizard-step-header>
    <form
      class="form-horizontal"
      mcDefaultLabelClass="col-sm-4"
      mcDefaultControlContainerClass="col-sm-6"
      [formGroup]="form"
    >
      <mc-field label="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.OPTIONS.ACKNOWLEDGE_DISABLED_ACCOUNTS"
                popoverPlacement="top"
                class="mc-custom-label"
                helpPopoverContent="{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.OPTIONS.ACKNOWLEDGE_DISABLED_ACCOUNTS_TOOLTIP' | translate }}">
        <mc-switch formControlName="acknowledgeDisabledAccounts" role="switch" showLabel="true"></mc-switch>
      </mc-field>

      <mc-field label="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.OPTIONS.FILTER_EMAIL_DOMAINS"
                popoverPlacement="top"
                class="mc-custom-label"
                [helpPopoverContent]="emailDomainsFilterTooltip">
        <mc-switch formControlName="emailDomainsFilter" role="switch" showLabel="true"></mc-switch>
      </mc-field>

      <mc-text-area-field *ngIf="isEmailDomainsFilterOn()"
                          label="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.OPTIONS.DOMAINS"
                          placeholder="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.OPTIONS.DOMAINS_PLACEHOLDER"
                          formControlName="domains"
                          required
                          errorPrefix="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.OPTIONS.DOMAINS_ERROR"
      >
      </mc-text-area-field>

      <mc-field *ngIf="!isGsuiteIntegration() && !isMSEIntegration() && !isLdapDominoIntegration()" label="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.OPTIONS.INCLUDE_CONTACTS"
                popoverPlacement="top"
                class="mc-custom-label"
                helpPopoverContent="{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.OPTIONS.INCLUDE_CONTACTS_TOOLTIP' | translate }}">
        <mc-switch formControlName="includeContacts" role="switch" showLabel="true"></mc-switch>
      </mc-field>

      <mc-field *ngIf="isAzureIntegration()" label="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.OPTIONS.INCLUDE_GUEST_ACCOUNTS"
                popoverPlacement="top"
                class="mc-custom-label"
                helpPopoverContent="{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.OPTIONS.INCLUDE_GUEST_ACCOUNTS_TOOLTIP' | translate }}">
        <mc-switch formControlName="includeGuestAccounts" role="switch" showLabel="true"></mc-switch>
      </mc-field>

      <mc-field
        label="{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.OPTIONS.MAXIMUM_SYNC_DELETIONS' | translate }}"
        useTranslation="true"
        [helpPopoverContent]="maxSyncDeletionTooltip">
        <mc-select
          formControlName="maxSyncDeletion"
          [options]="maxSyncDeletionOptions"
          [defaultValue]="form.value.maxSyncDeletion"
        ></mc-select>
      </mc-field>

      <mc-field label="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.OPTIONS.DELETE_USERS"
                popoverPlacement="top"
                class="mc-custom-label"
                [helpPopoverContent]="deleteUsersTooltip">
        <mc-switch formControlName="deleteUsers" role="switch" showLabel="true"></mc-switch>
      </mc-field>
    </form>
</div>

<ng-template #deleteUsersTooltip>
  <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.OPTIONS.DELETE_USERS_TOOLTIP_1' | translate }}</span>
  <a href="{{ deleteUsersKbLink }}" target="_blank" rel="noopener noreferrer">
    <u><span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.OPTIONS.DELETE_USERS_TOOLTIP_2' | translate }}</span></u>
  </a>
</ng-template>

<ng-template #emailDomainsFilterTooltip>
  <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.OPTIONS.FILTER_EMAIL_DOMAINS_TOOLTIP_1' | translate }}</span>
  <a href="{{ emailDomainsFilterKbLink }}" target="_blank" rel="noopener noreferrer">
    <u><span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.OPTIONS.FILTER_EMAIL_DOMAINS_TOOLTIP_2' | translate }}</span></u>
  </a>
  <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.OPTIONS.FILTER_EMAIL_DOMAINS_TOOLTIP_3' | translate }}</span>
</ng-template>

<ng-template #maxSyncDeletionTooltip>
  <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.OPTIONS.MAXIMUM_SYNC_DELETIONS_TOOLTIP_1' | translate }}</span>
  <a href="{{ maxSyncDeletionKbLink }}" target="_blank" rel="noopener noreferrer">
    <u><span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.OPTIONS.MAXIMUM_SYNC_DELETIONS_TOOLTIP_2' | translate }}</span></u>
  </a>
</ng-template>
