"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttachmentsListComponent = void 0;
const core_1 = require("@angular/core");
class AttachmentsListComponent {
    constructor() {
        this.remove = new core_1.EventEmitter();
    }
}
exports.AttachmentsListComponent = AttachmentsListComponent;
