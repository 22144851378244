"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CancelNoAction = exports.CancelYesAction = exports.CancelAction = exports.ClearWizardStepsAction = exports.ClearWizardStateAction = exports.RestoreWizardStateAction = exports.SetCurrentStepAction = exports.UpdateStepAction = exports.WizardActions = void 0;
var WizardActions;
(function (WizardActions) {
    WizardActions["SET_CURRENT_STEP"] = "[CreateConnectorWizard] Set Current Step";
    WizardActions["UPDATE_STEP"] = "[CreateConnectorWizard] Update Step";
    WizardActions["RESTORE_WIZARD_STATE"] = "[CreateConnectorWizard] Restore Wizard State";
    WizardActions["CLEAR_WIZARD_STATE"] = "[CreateConnectorWizard] Clear Wizard State";
    WizardActions["CLEAR_WIZARD_STEPS"] = "[CreateConnectorWizard] Clear Wizard Steps";
    WizardActions["CANCEL"] = "[CreateConnectorWizard] Cancel";
    WizardActions["CANCEL_YES"] = "[CreateConnectorWizard] Cancel Yes";
    WizardActions["CANCEL_NO"] = "[CreateConnectorWizard] Cance No";
})(WizardActions = exports.WizardActions || (exports.WizardActions = {}));
class UpdateStepAction {
    constructor(payload) {
        this.payload = payload;
        this.type = WizardActions.UPDATE_STEP;
    }
}
exports.UpdateStepAction = UpdateStepAction;
class SetCurrentStepAction {
    constructor(payload) {
        this.payload = payload;
        this.type = WizardActions.SET_CURRENT_STEP;
    }
}
exports.SetCurrentStepAction = SetCurrentStepAction;
class RestoreWizardStateAction {
    constructor(payload) {
        this.payload = payload;
        this.type = WizardActions.RESTORE_WIZARD_STATE;
    }
}
exports.RestoreWizardStateAction = RestoreWizardStateAction;
class ClearWizardStateAction {
    constructor(payload) {
        this.payload = payload;
        this.type = WizardActions.CLEAR_WIZARD_STATE;
    }
}
exports.ClearWizardStateAction = ClearWizardStateAction;
class ClearWizardStepsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = WizardActions.CLEAR_WIZARD_STEPS;
    }
}
exports.ClearWizardStepsAction = ClearWizardStepsAction;
class CancelAction {
    constructor(payload) {
        this.payload = payload;
        this.type = WizardActions.CANCEL;
    }
}
exports.CancelAction = CancelAction;
class CancelYesAction {
    constructor(payload, modalData) {
        this.payload = payload;
        this.modalData = modalData;
        this.type = WizardActions.CANCEL_YES;
    }
}
exports.CancelYesAction = CancelYesAction;
class CancelNoAction {
    constructor() {
        this.type = WizardActions.CANCEL_NO;
    }
}
exports.CancelNoAction = CancelNoAction;
