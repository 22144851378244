"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TabComponent = void 0;
const rxjs_1 = require("rxjs");
class TabComponent {
    constructor(cdr) {
        this.cdr = cdr;
        this.active$ = new rxjs_1.BehaviorSubject(false);
    }
    get active() {
        return this.active$.value;
    }
    setActive(value) {
        this.active$.next(value);
        this.cdr.detectChanges();
    }
}
exports.TabComponent = TabComponent;
