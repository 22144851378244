"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pageSize = exports.getUsersPagination = exports.apiIsProcessing = exports.getUsers = exports.reducer = exports.initialState = void 0;
const actions = require("../../actions/applies-to-users.actions");
exports.initialState = {
    users: [],
    apiIsProcessing: false,
    pagination: {
        next: undefined,
        previous: undefined,
        pageSize: 20,
        pageNumber: 0
    }
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.LOAD_APPLIES_TO_USERS:
            return Object.assign(Object.assign({}, state), { apiIsProcessing: true });
        case actions.LOAD_APPLIES_TO_USERS_SUCCESS:
            return Object.assign(Object.assign({}, state), { users: action.payload.data['users'] || action.payload.data['internalAddresses'] || [], apiIsProcessing: false, pagination: Object.assign(Object.assign({}, state.pagination), { next: action.payload.meta['pagination'].next, previous: action.payload.meta['pagination'].previous }) });
        case actions.LOAD_APPLIES_TO_USERS_FAILURE:
            return Object.assign(Object.assign({}, state), { apiIsProcessing: false });
        case actions.LOAD_APPLIES_TO_USERS_PAGINATION:
            return Object.assign(Object.assign({}, state), { apiIsProcessing: true, pagination: Object.assign(Object.assign({}, state.pagination), { pageNumber: action.payload.meta.pagination.pageNumber }) });
        case actions.LOAD_APPLIES_TO_USERS_PAGINATION_RESET:
            return Object.assign({}, exports.initialState);
        case actions.CLEAR_APPLIES_TO_USERS:
            return Object.assign({}, exports.initialState);
        default:
            return state;
    }
}
exports.reducer = reducer;
const getUsers = (state) => state.users;
exports.getUsers = getUsers;
const apiIsProcessing = (state) => state.apiIsProcessing;
exports.apiIsProcessing = apiIsProcessing;
const getUsersPagination = (state) => {
    let endIndex = null;
    if (!state.apiIsProcessing) {
        if (state.users.length >= state.pagination.pageSize) {
            endIndex = state.pagination.pageNumber + state.pagination.pageSize;
        }
        else {
            if (state.users.length && state.pagination.pageNumber !== 0) {
                endIndex = state.users.length + state.pagination.pageNumber;
            }
            else {
                endIndex = state.users.length || 0;
            }
        }
    }
    return {
        prevToken: state.pagination.previous,
        nextToken: state.pagination.next,
        startIndex: state.pagination.pageNumber + 1,
        endIndex,
        pageSize: state.pagination.pageSize
    };
};
exports.getUsersPagination = getUsersPagination;
const pageSize = (state) => state.pagination.pageSize;
exports.pageSize = pageSize;
