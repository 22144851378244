"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SetDefaultOktaFolderFailureAction = exports.SetDefaultOktaFolderSuccessAction = exports.SetDefaultOktaFolderAction = exports.RemoveOktaAccessTokenAction = exports.CancelAddNewFolderAction = exports.CreatePlaceholderFolderAction = exports.AddLdapFolderToOktaAction = exports.AddExtractedLdapFoldersAction = exports.DeleteEmailOktaIntegrationAction = exports.AddEmailOktaIntegrationAction = exports.UpdateOktaIntegrationSuccessAction = exports.DeleteOktaIntegrationSuccessAction = exports.DeleteOktaIntegrationAction = exports.UpdateOktaIntegrationAction = exports.OpenOktaIntegrationAction = exports.GetOktaIntegrationCompleteAction = exports.EditOktaIntegrationDetailsAction = exports.SwitchRotateApiTokenAction = exports.SwitchOktaIntegrationStatusAction = exports.SelectOktaCellAction = exports.SelectOktaIntegrationRootFolderAction = exports.CloseOktaIntegrationWidgetWithApiAction = exports.CancelOktaIntegrationWidgetAction = exports.CreateOktaIntegrationSuccessAction = exports.CreateOktaIntegrationAction = exports.IntegrationOktaActionTypes = void 0;
var IntegrationOktaActionTypes;
(function (IntegrationOktaActionTypes) {
    IntegrationOktaActionTypes["CreateOktaIntegration"] = "[ApiApplication/Integration/Okta/Create] Create Okta Integration";
    IntegrationOktaActionTypes["CreateOktaIntegrationSuccess"] = "[ApiApplication/Integration/Okta/Create] Create Okta Integration Success";
    IntegrationOktaActionTypes["CancelOktaIntegrationWidget"] = "[ApiApplication/Integration/Okta/Cancel] Okta Integration Cancel";
    IntegrationOktaActionTypes["CloseOktaIntegrationWidgetWithApi"] = "[ApiApplication/Integration/Okta/Close/API] Okta Integration Close with API";
    IntegrationOktaActionTypes["AddEmailOktaIntegrationContacts"] = "[ApiApplication/Integration/Okta/Email/Add] Add Email Okta Integration";
    IntegrationOktaActionTypes["DeleteEmailOktaIntegrationContacts"] = "[ApiApplication/Integration/Okta/Email/Delete] Delete Email Okta Integration";
    IntegrationOktaActionTypes["SelectOktaIntegrationRootFolder"] = "[ApiApplication/Integration/Okta/SelectFolder] Okta Integration Select Root Folder";
    IntegrationOktaActionTypes["SwitchOktaIntegrationStatus"] = "[ApiApplication/Integration/Okta/Status/Switch] Okta Integration Status Switch";
    IntegrationOktaActionTypes["SwitchRotateApiToken"] = "[ApiApplication/Integration/Okta/API/Token/Switch] Okta Integration API Token Rotate Switch";
    IntegrationOktaActionTypes["EditOktaIntegrationDetails"] = "[ApiApplication/Integration/Okta/Edit/Details] Okta Integration Edit Details";
    IntegrationOktaActionTypes["GetOktaIntegrationComplete"] = "[ApiApplication/Integration/Okta/Get/Integration] Okta Integration Get Integration Completed";
    IntegrationOktaActionTypes["UpdateOktaIntegration"] = "[ApiApplication/Integration/Okta/Update] Update Okta Integration";
    IntegrationOktaActionTypes["UpdateOktaIntegrationSuccess"] = "[ApiApplication/Integration/Okta/Create/Update] Update Okta Integration Success";
    IntegrationOktaActionTypes["DeleteOktaIntegration"] = "[ApiApplication/Integration/Okta/Delete] Delete Okta Integration";
    IntegrationOktaActionTypes["DeleteOktaIntegrationSuccess"] = "[ApiApplication/Integration/Okta/Delete] Delete Okta Integration Success";
    IntegrationOktaActionTypes["OpenOktaIntegration"] = "[ApiApplication/Integration/Okta/Open] Open Okta Integration";
    IntegrationOktaActionTypes["SelectOktaCell"] = "[ApiApplication/Integration/Okta/SelectCell] Select Okta Cell";
    IntegrationOktaActionTypes["AddExtractedLdapFolders"] = "[ApiApplication/Integration/Okta/AddGroupsToOkta] Adds extracted ldap folders from the Group store to Okta";
    IntegrationOktaActionTypes["AddLdapFolderToOkta"] = "[ApiApplication/Integration/Okta/AddGroupsToOkta] Adds ldap folder to Okta";
    IntegrationOktaActionTypes["CreatePlaceholderFolder"] = "[ApiApplication/Integration/Okta/CreatePlaceholderFolder] Create placeholder folder";
    IntegrationOktaActionTypes["CancelAddNewFolder"] = "[ApiApplication/Integration/Okta/CancelAddNewFolder] Cancel add new folder";
    IntegrationOktaActionTypes["RemoveOktaAccessToken"] = "[ApiApplication/Integration/Okta/AccessToken/Delete] Delete Okta Access Token";
    IntegrationOktaActionTypes["SetDefaultOktaFolder"] = "[ApiApplication/Integration/Okta/Create/SetOktaFolder] Set Default Okta Folder";
    IntegrationOktaActionTypes["SetDefaultOktaFolderSuccess"] = "[ApiApplication/Integration/Okta/Create/SetOktaFolder] Success Set Default Okta Folder";
    IntegrationOktaActionTypes["SetDefaultOktaFolderFailure"] = "[ApiApplication/Integration/Okta/Create/SetOktaFolder] Failure Set Default Okta Folder";
})(IntegrationOktaActionTypes = exports.IntegrationOktaActionTypes || (exports.IntegrationOktaActionTypes = {}));
class CreateOktaIntegrationAction {
    constructor() {
        this.type = IntegrationOktaActionTypes.CreateOktaIntegration;
    }
}
exports.CreateOktaIntegrationAction = CreateOktaIntegrationAction;
class CreateOktaIntegrationSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationOktaActionTypes.CreateOktaIntegrationSuccess;
    }
}
exports.CreateOktaIntegrationSuccessAction = CreateOktaIntegrationSuccessAction;
class CancelOktaIntegrationWidgetAction {
    constructor() {
        this.type = IntegrationOktaActionTypes.CancelOktaIntegrationWidget;
    }
}
exports.CancelOktaIntegrationWidgetAction = CancelOktaIntegrationWidgetAction;
class CloseOktaIntegrationWidgetWithApiAction {
    constructor() {
        this.type = IntegrationOktaActionTypes.CloseOktaIntegrationWidgetWithApi;
    }
}
exports.CloseOktaIntegrationWidgetWithApiAction = CloseOktaIntegrationWidgetWithApiAction;
class SelectOktaIntegrationRootFolderAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationOktaActionTypes.SelectOktaIntegrationRootFolder;
    }
}
exports.SelectOktaIntegrationRootFolderAction = SelectOktaIntegrationRootFolderAction;
class SelectOktaCellAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationOktaActionTypes.SelectOktaCell;
    }
}
exports.SelectOktaCellAction = SelectOktaCellAction;
class SwitchOktaIntegrationStatusAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationOktaActionTypes.SwitchOktaIntegrationStatus;
    }
}
exports.SwitchOktaIntegrationStatusAction = SwitchOktaIntegrationStatusAction;
class SwitchRotateApiTokenAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationOktaActionTypes.SwitchRotateApiToken;
    }
}
exports.SwitchRotateApiTokenAction = SwitchRotateApiTokenAction;
class EditOktaIntegrationDetailsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationOktaActionTypes.EditOktaIntegrationDetails;
    }
}
exports.EditOktaIntegrationDetailsAction = EditOktaIntegrationDetailsAction;
class GetOktaIntegrationCompleteAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationOktaActionTypes.GetOktaIntegrationComplete;
    }
}
exports.GetOktaIntegrationCompleteAction = GetOktaIntegrationCompleteAction;
class OpenOktaIntegrationAction {
    constructor() {
        this.type = IntegrationOktaActionTypes.OpenOktaIntegration;
    }
}
exports.OpenOktaIntegrationAction = OpenOktaIntegrationAction;
class UpdateOktaIntegrationAction {
    constructor() {
        this.type = IntegrationOktaActionTypes.UpdateOktaIntegration;
    }
}
exports.UpdateOktaIntegrationAction = UpdateOktaIntegrationAction;
class DeleteOktaIntegrationAction {
    constructor() {
        this.type = IntegrationOktaActionTypes.DeleteOktaIntegration;
    }
}
exports.DeleteOktaIntegrationAction = DeleteOktaIntegrationAction;
class DeleteOktaIntegrationSuccessAction {
    constructor() {
        this.type = IntegrationOktaActionTypes.DeleteOktaIntegrationSuccess;
    }
}
exports.DeleteOktaIntegrationSuccessAction = DeleteOktaIntegrationSuccessAction;
class UpdateOktaIntegrationSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationOktaActionTypes.UpdateOktaIntegrationSuccess;
    }
}
exports.UpdateOktaIntegrationSuccessAction = UpdateOktaIntegrationSuccessAction;
class AddEmailOktaIntegrationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationOktaActionTypes.AddEmailOktaIntegrationContacts;
    }
}
exports.AddEmailOktaIntegrationAction = AddEmailOktaIntegrationAction;
class DeleteEmailOktaIntegrationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationOktaActionTypes.DeleteEmailOktaIntegrationContacts;
    }
}
exports.DeleteEmailOktaIntegrationAction = DeleteEmailOktaIntegrationAction;
class AddExtractedLdapFoldersAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationOktaActionTypes.AddExtractedLdapFolders;
    }
}
exports.AddExtractedLdapFoldersAction = AddExtractedLdapFoldersAction;
class AddLdapFolderToOktaAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationOktaActionTypes.AddLdapFolderToOkta;
    }
}
exports.AddLdapFolderToOktaAction = AddLdapFolderToOktaAction;
class CreatePlaceholderFolderAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationOktaActionTypes.CreatePlaceholderFolder;
    }
}
exports.CreatePlaceholderFolderAction = CreatePlaceholderFolderAction;
class CancelAddNewFolderAction {
    constructor() {
        this.type = IntegrationOktaActionTypes.CancelAddNewFolder;
    }
}
exports.CancelAddNewFolderAction = CancelAddNewFolderAction;
class RemoveOktaAccessTokenAction {
    constructor() {
        this.type = IntegrationOktaActionTypes.RemoveOktaAccessToken;
    }
}
exports.RemoveOktaAccessTokenAction = RemoveOktaAccessTokenAction;
class SetDefaultOktaFolderAction {
    constructor() {
        this.type = IntegrationOktaActionTypes.SetDefaultOktaFolder;
    }
}
exports.SetDefaultOktaFolderAction = SetDefaultOktaFolderAction;
class SetDefaultOktaFolderSuccessAction {
    constructor(scimFolder) {
        this.scimFolder = scimFolder;
        this.type = IntegrationOktaActionTypes.SetDefaultOktaFolderSuccess;
    }
}
exports.SetDefaultOktaFolderSuccessAction = SetDefaultOktaFolderSuccessAction;
class SetDefaultOktaFolderFailureAction {
    constructor() {
        this.type = IntegrationOktaActionTypes.SetDefaultOktaFolderFailure;
    }
}
exports.SetDefaultOktaFolderFailureAction = SetDefaultOktaFolderFailureAction;
