"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const remediation_dashboard_component_1 = require("./containers/remediation-dashboard.component");
require("./pages/search-messages-list/search-messages-list");
require("./pages/incident/incident");
angular
    .module('remediation', ['remediation.search-messages-list', 'remediation.incident-view'])
    .controller('RemediationController', [
    '$stateParams',
    '$scope',
    '$state',
    function ($stateParams, $scope, $state) {
        $scope.stateParams = $stateParams;
        $scope.tab.onRefresh = function () {
            if ($state.current.name === 'remediation') {
                $state.go($state.current.name, {}, { reload: true, inherit: false });
            }
            else {
                $state.reload();
            }
        };
    }
])
    .directive('mcRemediation', static_1.downgradeComponent({
    component: remediation_dashboard_component_1.RemediationDashboardComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider.state('remediation', {
            url: '/remediation',
            params: {
                tabName: null
            },
            data: {
                isFixedLayout: false,
                checkProgress: false,
                tabReload: false,
                capabilities: 'Permission.THREAT_REMEDIATION_READ',
                tabTitle: '$I18N_REMEDIATION_CONTAINER.BREADCRUMB.APP_NAME',
                tabId: 'remediationDashboard'
            },
            views: {
                main: {
                    template: '<mc-remediation></mc-remediation>',
                    controller: 'RemediationController'
                }
            }
        });
    }
]);
