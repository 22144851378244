"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RulesService = void 0;
const rule_factory_1 = require("app-new/archive/supervision/factories/rule.factory");
const status_enum_1 = require("app-new/archive/supervision/types/status.enum");
const table_store_1 = require("@mimecast-ui/table-store");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const state_enum_1 = require("app-new/archive/supervision/types/state.enum");
class RulesService {
    constructor(http) {
        this.http = http;
    }
    getData(payload, pagination = table_store_1.defaultPaginationOption) {
        return this.http
            .post('/api/supervision/config/find-rules', Object.assign(Object.assign({}, payload), { meta: { pagination } }))
            .pipe(operators_1.map((response) => {
            const RETURN_DATA = {
                hasErrors: response.hasErrors,
                data: response.first.rules.map((rule) => {
                    return rule_factory_1.RuleFactory.BUILD_RULE(rule);
                }),
                failures: response.fail,
                meta: response.meta
            };
            return RETURN_DATA;
        }), operators_1.catchError((response) => {
            const RETURN_DATA = {
                hasErrors: response.hasErrors,
                data: response.all,
                failures: response.fail,
                meta: response.meta
            };
            return rxjs_1.of(RETURN_DATA);
        }));
    }
    filter(filter, pagination) {
        return this.getData(filter, pagination);
    }
    getRule(id) {
        return this.http.post('/api/supervision/config/get-rule', { id }).pipe(operators_1.map((response) => response), operators_1.catchError((response) => rxjs_1.of(response)));
    }
    openItem() {
        return rxjs_1.of(undefined);
    }
}
exports.RulesService = RulesService;
RulesService.SUPERVISION_RULES_LIST = 'SupervisionRulesList';
RulesService.STATUS_MAP = {
    [status_enum_1.RuleStatus.ERROR]: '$I18N_ARCHIVE_SUPERVISION_LIST_RULES_TAB.STATUS.ERROR',
    [status_enum_1.RuleStatus.NOT_RAN]: '$I18N_ARCHIVE_SUPERVISION_LIST_RULES_TAB.STATUS.NOT_RAN',
    [status_enum_1.RuleStatus.SUCCESSFUL]: '$I18N_ARCHIVE_SUPERVISION_LIST_RULES_TAB.STATUS.SUCCESSFUL',
    [status_enum_1.RuleStatus.WARNING]: '$I18N_ARCHIVE_SUPERVISION_LIST_RULES_TAB.STATUS.WARNING',
    [status_enum_1.RuleStatus.UNKNOWN]: '$I18N_ARCHIVE_SUPERVISION_LIST_RULES_TAB.STATUS.UNKNOWN',
    [status_enum_1.RuleStatus.QUEUE_FULL]: '$I18N_ARCHIVE_SUPERVISION_LIST_RULES_TAB.STATUS.QUEUE_FULL'
};
RulesService.STATE_MAP = {
    [state_enum_1.RuleState.ACTIVE]: '$I18N_ARCHIVE_SUPERVISION_LIST_RULES_TAB.STATE.ACTIVE',
    [state_enum_1.RuleState.INACTIVE]: '$I18N_ARCHIVE_SUPERVISION_LIST_RULES_TAB.STATE.INACTIVE'
};
