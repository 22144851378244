'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
angular.module('onedrive-aside.controller', [])
    .controller('OneDriveAsideController', ['$scope', function ($scope) {
        const self = this;
        const items = [];
        self.asideActions = {
            closePanel: $scope.$close
        };
        items.push({
            label: '$I18N_ONEDRIVE_BACKUPS.TITLE',
            goTo: 'one-drive-backups'
        });
        items.push({
            label: '$I18N_ONEDRIVE_LIST.TITLE',
            goTo: 'one-drive-list'
        });
        $scope.asideItems = items;
    }]);
