"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AffectedUsersByChannelsSidebarComponent = void 0;
const reducers = require("../reducers");
const clp_channels_actions_1 = require("../actions/clp-channels.actions");
const user_location_sidebar_actions_1 = require("../actions/user-location-sidebar.actions");
class AffectedUsersByChannelsSidebarComponent {
    constructor(store, overlayData) {
        this.store = store;
        this.overlayData = overlayData;
    }
    ngOnInit() {
        this.teams$ = this.store.select(reducers.getTeams);
        this.channels$ = this.store.select(reducers.getChannels);
        this.teamsLoading$ = this.store.select(reducers.getTeamsLoading);
        this.teamsError$ = this.store.select(reducers.getTeamsError);
        this.channelsError$ = this.store.select(reducers.getChannelsError);
        this.channelsLoading$ = this.store.select(reducers.getChannelsLoading);
    }
    searchTeams(data) {
        // @ts-ignore
        const searchText = data.search;
        if (searchText && !!searchText.trim()) {
            this.store.dispatch(new clp_channels_actions_1.LoadNotificationTeams({ searchText }));
        }
        this.selectedChannel = null;
        this.selectedTeam = null;
    }
    searchChannels(data) {
        // @ts-ignore
        const searchText = data.search;
        if (this.selectedTeam && searchText && !!searchText.trim()) {
            this.store.dispatch(new clp_channels_actions_1.LoadNotificationChannels({ teamId: this.selectedTeam.id, searchText }));
        }
    }
    chooseTeam(team) {
        this.selectedTeam = team;
    }
    chooseChannel(channel) {
        this.selectedChannel = channel;
    }
    hasChannelSelected() {
        return this.selectedChannel && this.selectedChannel.id !== null ? true : false;
    }
    hasTeamSelected() {
        return this.selectedTeam && this.selectedTeam.id !== null ? true : false;
    }
    clearTeams() {
        this.selectedTeam = null;
        this.store.dispatch(new clp_channels_actions_1.LoadNotificationTeamsClear());
    }
    clearChannels() {
        this.selectedChannel = null;
        this.store.dispatch(new clp_channels_actions_1.LoadNotificationChannelsClear());
    }
    submit() {
        this.store.dispatch(this.overlayData.action({
            teamId: this.selectedTeam.id,
            teamDisplayName: this.selectedTeam.displayName,
            channelId: this.selectedChannel.id,
            channelDisplayName: this.selectedChannel.displayName
        }));
    }
    close() {
        this.store.dispatch(new user_location_sidebar_actions_1.UserLocationAppliesToClose());
    }
}
exports.AffectedUsersByChannelsSidebarComponent = AffectedUsersByChannelsSidebarComponent;
