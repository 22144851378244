"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allItems = exports.firstItem = exports.isDefined = void 0;
const operators_1 = require("rxjs/operators");
function isDefined() {
    return operators_1.filter(n => !!n);
}
exports.isDefined = isDefined;
function firstItem() {
    return operators_1.map((r) => r.first);
}
exports.firstItem = firstItem;
function allItems() {
    return operators_1.map((r) => r.all);
}
exports.allItems = allItems;
