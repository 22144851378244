"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ID_SEARCH_DATE_RANGE_SWITCH_NAME = exports.MEP_CAPABILITY_NAME = exports.IEP_CAPABILITY_NAME = exports.HIDE_MEP_CAPABILITY_NAME = exports.MEP_INTEGRATION_SWITCH_NAME = exports.commonIncidentTypes = exports.mepCapabilityIncidentTypes = exports.iepCapabilityIncidentTypes = exports.remediationDashboardTabs = exports.RemediationDashboardTabs = void 0;
var RemediationDashboardTabs;
(function (RemediationDashboardTabs) {
    RemediationDashboardTabs[RemediationDashboardTabs["overview"] = 0] = "overview";
    RemediationDashboardTabs[RemediationDashboardTabs["search"] = 1] = "search";
    RemediationDashboardTabs[RemediationDashboardTabs["incidents"] = 2] = "incidents";
    RemediationDashboardTabs[RemediationDashboardTabs["logs"] = 3] = "logs";
    RemediationDashboardTabs[RemediationDashboardTabs["settings"] = 4] = "settings";
})(RemediationDashboardTabs = exports.RemediationDashboardTabs || (exports.RemediationDashboardTabs = {}));
exports.remediationDashboardTabs = [
    {
        url: RemediationDashboardTabs[RemediationDashboardTabs.overview],
        label: '$I18N_REMEDIATION_CONTAINER.TABS.OVERVIEW'
    },
    {
        url: RemediationDashboardTabs[RemediationDashboardTabs.search],
        label: '$I18N_REMEDIATION_CONTAINER.TABS.SEARCH'
    },
    {
        url: RemediationDashboardTabs[RemediationDashboardTabs.incidents],
        label: '$I18N_REMEDIATION_CONTAINER.TABS.INCIDENTS'
    },
    {
        url: RemediationDashboardTabs[RemediationDashboardTabs.logs],
        label: '$I18N_REMEDIATION_CONTAINER.TABS.LOGS'
    },
    {
        url: RemediationDashboardTabs[RemediationDashboardTabs.settings],
        label: '$I18N_REMEDIATION_CONTAINER.TABS.SETTINGS'
    }
];
exports.iepCapabilityIncidentTypes = ['notify_only', 'automatic', 'manual'];
exports.mepCapabilityIncidentTypes = ['mep_clawback'];
exports.commonIncidentTypes = ['restore'];
exports.MEP_INTEGRATION_SWITCH_NAME = 'Temporary.Remediation.MEP.Integration';
exports.HIDE_MEP_CAPABILITY_NAME = 'Hide.MEP.UI.Cyb1.0';
exports.IEP_CAPABILITY_NAME = 'Policy.InsiderThreatManagement';
exports.MEP_CAPABILITY_NAME = 'Misaddressed.Email.Protect.UI';
exports.ID_SEARCH_DATE_RANGE_SWITCH_NAME = 'Temporary.Remediation.Id.Search.Date.Range';
