"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LegacyEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const actions = require("../actions");
const legacy_actions_1 = require("../actions/legacy.actions");
const reducers = require("../reducers");
class LegacyEffects {
    constructor(actions$, store, legacyService, windowService, locationService, sessionService, capabilitiesService, cookieService) {
        this.actions$ = actions$;
        this.store = store;
        this.legacyService = legacyService;
        this.windowService = windowService;
        this.locationService = locationService;
        this.sessionService = sessionService;
        this.capabilitiesService = capabilitiesService;
        this.cookieService = cookieService;
        this.SWITCH_TYPE_SD = 'servicedelivery';
        this.SWITCH_TYPE_FEDERATED_AAA = 'federatedadminaccess_aaa';
        this.SWITCH_TYPE_FEDERATED_FAA = 'federatedadminaccess_faa';
        this.clearOldCookies$ = this.actions$.pipe(effects_1.ofType(actions.app.FIRST_TIME_LOAD), operators_1.tap(() => {
            this.legacyService.deleteAdconUrlCookie();
            this.legacyService.deleteAccountSwitchCookie();
        }));
        this.loadSwitchAndUrlCookies$ = this.actions$.pipe(effects_1.ofType(actions.app.LOAD), operators_1.flatMap(() => this.store.select(reducers.getSession).pipe(operators_1.first())), operators_1.map(session => {
            this.legacyService.loadAdconUrlCookieValue();
            if (session.remoteAccountCode) {
                // if partner portal login type create fake session so useInfo calls sent accountCode meta
                const switchAccountSession = {
                    SysAccountSessionId: '',
                    SysAccountSessionNewSession: true,
                    SysWindow: 'connect',
                    accountcode: session.remoteAccountCode,
                    logon: '',
                    switchtype: 'federatedadminaccess_faa'
                };
                // Delete account switch cookie on app load if it's inside an iframe
                if (this.windowService.inIframe()) {
                    this.legacyService.deleteAccountSwitchCookie();
                }
                return new legacy_actions_1.StoreSwitchAccountSessionAction(switchAccountSession);
            }
            const switchAccountCookieValue = this.legacyService.getSwitchAccountCookieValue();
            return new legacy_actions_1.StoreSwitchAccountSessionAction(switchAccountCookieValue);
        }));
        this.createSwitchAndUrlCookiesOnUnload$ = this.actions$.pipe(effects_1.ofType(actions.app.UNLOAD), operators_1.flatMap(() => this.store.select(reducers.getSwitchAccountSession)), operators_1.tap((accountSwitchSession) => {
            this.legacyService.createTempAdconUrlCookie();
            this.legacyService.createTempSwitchAccountCookie(accountSwitchSession);
        }));
        this.userInfoLoaded$ = this.actions$.pipe(effects_1.ofType(actions.user.USER_INFO_LOAD_SUCCESS), operators_1.map((action) => action.payload), operators_1.withLatestFrom(this.store.select(reducers.getSession), this.store.select(reducers.getSwitchAccountSession)), operators_1.map(([userInfo, session, switchAccountSession]) => {
            this.accountCode = userInfo.account.accountCode;
            const validAccess = userInfo.adminAccess &&
                userInfo.capabilities['Permission.DASHBOARD_READ'] &&
                userInfo.capabilities['Permission.DASHBOARD_READ'].enabled;
            if (!validAccess) {
                return new actions.app.InvalidAccessAction();
            }
            if (session.remoteAccountCode) {
                return new actions.legacy.LoginPartnerAction(userInfo.legacyAdconLoginRequest, session.remoteAccountCode, userInfo.profile.emailAddress);
            }
            if (switchAccountSession) {
                return new actions.legacy.LoginSwitchAccountAction(switchAccountSession);
            }
            return new actions.legacy.LoginAction(userInfo.legacyAdconLoginRequest);
        }));
        this.switchAccount$ = this.actions$.pipe(effects_1.ofType(actions.legacy.SWITCH_ACCOUNT), operators_1.tap((action) => {
            const switchAccountUrl = this.windowService.inIframe()
                ? this.locationService.origin + this.locationService.parentPathname
                : this.locationService.origin + this.locationService.pathname;
            const switchTypesToOpenInNewTab = !this.cookieService.get('cypress-iframe')
                ? [this.SWITCH_TYPE_SD]
                : [];
            // Temporary feature switch to roll out the opening of FAA/AAA switch account in new tab. WF-4284
            this.capabilitiesService
                .hasAccountCodeCapability('Temporary.Roles.FederatedAccess.SwitchAccountInNewTab', this.accountCode)
                .subscribe(isActive => {
                if (isActive) {
                    switchTypesToOpenInNewTab.push(this.SWITCH_TYPE_FEDERATED_AAA, this.SWITCH_TYPE_FEDERATED_FAA);
                }
            });
            if (switchTypesToOpenInNewTab.indexOf(action.payload.switchtype) !== -1) {
                // for the above switchtypes the session is opened in a new tab
                // so we need to create the temp cookies so when the tab open loads them
                this.sessionService.createTempSessionCookie();
                this.legacyService.createTempSwitchAccountCookie(action.payload);
                this.legacyService.createTempAdconUrlCookie();
                const wnd = this.windowService.window.open(switchAccountUrl, '_blank');
                wnd.focus();
            }
            else {
                // a new page will replace current one so app.UNLOAD will be trigger
                // and session cookies will be created so no need to manually call the create
                // cookies
                if (this.windowService.inIframe()) {
                    // Reload parent window's location if switched account is of type FAA/AAA when inside iframe
                    this.locationService.reloadParent();
                }
                else {
                    this.locationService.href = switchAccountUrl;
                }
            }
        }));
        this.legacyAdconLogin$ = this.actions$.pipe(effects_1.ofType(actions.legacy.LOGIN), operators_1.flatMap((action) => {
            return this.legacyService.login(action.payload).pipe(operators_1.map(response => new actions.legacy.LoginSuccessAction(response)), operators_1.catchError(() => rxjs_1.of(new actions.legacy.LoginFailAction(action.payload))));
        }));
        this.legacyAdconSwitchAccountLogin$ = this.actions$.pipe(effects_1.ofType(actions.legacy.LOGIN_SWITCH_ACCOUNT), operators_1.flatMap((action) => {
            return this.legacyService
                .changeAccount(Object.assign(Object.assign({}, action.payload), { url: this.legacyService.adconUrl }))
                .pipe(operators_1.map(response => {
                const sessionData = {
                    SysAccountSessionId: response.newSysAccountSessionId,
                    accountCode: action.payload.accountcode
                };
                if (response.sid) {
                    sessionData.sid = response.sid;
                }
                return new actions.legacy.LoginSuccessAction(sessionData);
            }));
        }));
        this.legacyAdconPartnerLogin$ = this.actions$.pipe(effects_1.ofType(actions.legacy.LOGIN_PARTNER), operators_1.flatMap((action) => {
            return this.legacyService.login(Object.assign(Object.assign({}, action.request), { IsPartnerAuth: true })).pipe(operators_1.flatMap(response => {
                const switchAccountSession = {
                    SysAccountSessionId: response.SysAccountSessionId,
                    SysAccountSessionNewSession: true,
                    SysWindow: 'connect',
                    accountcode: action.selectedPartnerClientAccount,
                    logon: action.logon,
                    switchtype: 'federatedadminaccess_faa'
                };
                return [
                    new actions.legacy.StoreSwitchAccountSessionAction(switchAccountSession),
                    new actions.legacy.LoginSuccessAction(response)
                ];
            }), operators_1.catchError(() => rxjs_1.of(new actions.legacy.LoginPartnerFailAction(action.request, action.selectedPartnerClientAccount, action.logon))));
        }));
        this.pingTimer = rxjs_1.timer(5 * 60 * 1000, 10 * 60 * 1000);
        this.startSessionPing$ = this.actions$.pipe(effects_1.ofType(actions.legacy.START_SESSION_PING), operators_1.flatMap(() => this.pingTimer.pipe(operators_1.takeUntil(this.actions$.pipe(effects_1.ofType(actions.app.LOGOUT), operators_1.first())), operators_1.map(() => new actions.legacy.PingSessionAction()))));
        this.sessionPing$ = this.actions$.pipe(effects_1.ofType(actions.legacy.PING_SESSION), operators_1.flatMap(() => this.store.select(reducers.getLegacySession).pipe(operators_1.first())), operators_1.tap(session => this.legacyService.ping(session).subscribe()));
        this.loginSuccess$ = this.actions$.pipe(effects_1.ofType(actions.legacy.LOGIN_SUCCESS), operators_1.flatMap(() => [
            new actions.legacy.StartSessionPingAction(),
            new actions.app.LoadSuccessAction()
        ]));
        this.loginFail$ = this.actions$.pipe(effects_1.ofType(actions.legacy.LOGIN_FAIL, actions.legacy.LOGIN_PARTNER_FAIL), operators_1.switchMap((action) => {
            // No response from Adcon 3. Could be the server is down or the call timed out for some other reason.
            // Call the login action again to connect to a different box
            if (this.windowService.adconUrls && this.windowService.adconUrls.length) {
                if (action instanceof actions.legacy.LoginFailAction) {
                    return rxjs_1.of(new actions.legacy.LoginAction(action.payload));
                }
                else {
                    return rxjs_1.of(new actions.legacy.LoginPartnerAction(action.request, action.selectedPartnerClientAccount, action.logon));
                }
            }
            else {
                throw new Error('Unable to login to Adcon 3');
            }
        }));
        this.sessionLogout$ = this.actions$.pipe(effects_1.ofType(actions.legacy.LOGOUT), operators_1.flatMap(() => this.store.select(reducers.getLegacySession).pipe(operators_1.first())), operators_1.flatMap(session => this.legacyService.logout(session).pipe(operators_1.map(() => new actions.legacy.LogoutSuccessAction()), operators_1.catchError(() => rxjs_1.of(new actions.legacy.LogoutFailAction())))));
    }
}
__decorate([
    effects_1.Effect({ dispatch: false })
], LegacyEffects.prototype, "clearOldCookies$", void 0);
__decorate([
    effects_1.Effect()
], LegacyEffects.prototype, "loadSwitchAndUrlCookies$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], LegacyEffects.prototype, "createSwitchAndUrlCookiesOnUnload$", void 0);
__decorate([
    effects_1.Effect()
], LegacyEffects.prototype, "userInfoLoaded$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], LegacyEffects.prototype, "switchAccount$", void 0);
__decorate([
    effects_1.Effect()
], LegacyEffects.prototype, "legacyAdconLogin$", void 0);
__decorate([
    effects_1.Effect()
], LegacyEffects.prototype, "legacyAdconSwitchAccountLogin$", void 0);
__decorate([
    effects_1.Effect()
], LegacyEffects.prototype, "legacyAdconPartnerLogin$", void 0);
__decorate([
    effects_1.Effect()
], LegacyEffects.prototype, "startSessionPing$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], LegacyEffects.prototype, "sessionPing$", void 0);
__decorate([
    effects_1.Effect()
], LegacyEffects.prototype, "loginSuccess$", void 0);
__decorate([
    effects_1.Effect()
], LegacyEffects.prototype, "loginFail$", void 0);
__decorate([
    effects_1.Effect()
], LegacyEffects.prototype, "sessionLogout$", void 0);
exports.LegacyEffects = LegacyEffects;
