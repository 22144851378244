"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const coreReducers = require("../../core/reducers");
class SwgService {
    constructor(coreService, capabilitiesService, coreStore) {
        this.coreService = coreService;
        this.capabilitiesService = capabilitiesService;
        this.coreStore = coreStore;
        this.capabilitiesService
            .hasCapability('Internet.Security.DNS')
            .pipe(operators_1.first())
            .subscribe((enabled) => {
            this.hasWsEnabled = enabled;
        });
        this.capabilitiesService
            .hasCapability('Security.Web.BrowserIsolation')
            .pipe(operators_1.first())
            .subscribe(enabled => {
            this.hasBiForWebSecurity = enabled;
        });
        this.capabilitiesService
            .hasCapability('Permission.SWG_ENDPOINT_SETTINGS_EDIT')
            .pipe(operators_1.first())
            .subscribe((enabled) => {
            this.hasWsEndPointSettingsEditEnabled = enabled;
        });
        this.capabilitiesService
            .hasCapability('Permission.SWG_ENDPOINT_SETTINGS_READ')
            .pipe(operators_1.first())
            .subscribe((enabled) => {
            this.hasWsEndPointSettingsReadEnabled = enabled;
        });
        this.capabilitiesService
            .hasCapability('Security.Ttp.BrowserIsolation')
            .pipe(operators_1.first())
            .subscribe((enabled) => {
            this.hasBiForEmail = enabled;
        });
        rxjs_1.combineLatest([
            this.coreStore.select(coreReducers.getAccount),
            this.coreStore.select(coreReducers.getCapsOverride)
        ])
            .pipe(operators_1.filter(([account, caps]) => !!account && !!caps), operators_1.first())
            .subscribe(([account, caps]) => {
            this.hasSwgFullPackage = !!account.packages.filter(pkg => pkg.indexOf('[1072]') >= 0)[0];
            this.hasCLPPackage = !!account.packages.filter(pkg => pkg.indexOf('[1084]') >= 0)[0];
            this.clpIntegrationEnabled = this.hasCapEnabledAtAccountLevel(caps['Temporary.Permission.swg.clp.integration'], account);
            this.privateIpInReportEnabled = this.hasCapEnabledAtAccountLevel(caps['Temporary.Permission.swg.reports.with.privateIp'], account);
            this.applicationPolicyControllableOnlyEnabled = this.hasCapEnabledAtAccountLevel(caps['Temporary.Permission.swg.policy.applications.controllable.apps'], account);
            this.supervisedUserEnabled = this.hasCapEnabledAtAccountLevel(caps['Temporary.Permission.swg.discoverymethod.supervisedUser'], account);
            this.privateIpInEndpointsEnabled = this.hasCapEnabledAtAccountLevel(caps['Temporary.Permission.swg.endpoints.with.privateIp'], account);
            this.hasHybridProxySupportEnabled = this.hasCapEnabledAtAccountLevel(caps['Temporary.Permission.swg.hybrid.proxy'], account);
            this.applicationVisibilityEnabled = this.hasCapEnabledAtAccountLevel(caps['Temporary.Permission.swg.reports.application.visibility'], account);
            this.endpointsDetailsEnabled = this.hasCapEnabledAtAccountLevel(caps['Temporary.Permission.swg.endpoints.details'], account);
            this.hasAppPolicyRemoveAllowEnabled = this.hasCapEnabledAtAccountLevel(caps['Temporary.Permission.swg.policy.appPolicy.remove.allow'], account);
            this.hasMsaRemediationEnabled = this.hasCapEnabledAtAccountLevel(caps['Temporary.Permission.swg.msa.remediation'], account);
            this.hasBIWebCapEnabled = this.hasCapEnabledAtAccountLevel(caps['Temporary.BrowserIsolation.Web'], account);
            this.enableUninstallPassword = this.hasCapEnabledAtAccountLevel(caps['Temporary.Permission.swg.endpoints.enableUninstallPassword'], account);
            this.hasAutoDeviceEnrollmentEnabled = this.hasCapEnabledAtAccountLevel(caps['Temporary.Permission.swg.endpoints.deviceAutoEnrollment'], account);
            this.hasAwBlockPageEnabled = this.hasCapEnabledAtAccountLevel(caps['Temporary.Permission.swg.awBlockPage'], account);
            this.hasDirectIPProtectionEnabled = this.hasCapEnabledAtAccountLevel(caps['Temporary.Permission.swg.endpoints.directIPProtection'], account);
            this.hasAllowInternalIpsEnabled = this.hasCapEnabledAtAccountLevel(caps['Temporary.Permission.swg.endpoints.allowInternalIps'], account);
            this.has5kDomainsLimit = this.hasCapEnabledAtAccountLevel(caps['Temporary.Permission.swg.domains.limit.5ka'], account);
            this.hasIPV6SwitchEnabled = this.hasCapEnabledAtAccountLevel(caps['Temporary.Permission.swg.exceptions.ipv6'], account);
            this.hasDirectIPFiltersSwitchEnabled = this.hasCapEnabledAtAccountLevel(caps['Temporary.Permission.swg.directIPFilters'], account);
            this.hasDirectIPProtectionModeEnabled = this.hasCapEnabledAtAccountLevel(caps['Temporary.Permission.swg.endpoints.directIPProtectionMode'], account);
            this.hasClpBlockAndQuarantineActionEnabled = this.hasCapEnabledAtAccountLevel(caps['Temporary.Permission.swg.clp.actions.blockAndQuarantine'], account);
            this.hasDeviceBasedPoliciesEnabled = this.hasCapEnabledAtAccountLevel(caps['Temporary.Permission.Swg.DeviceBasedPolicies'], account);
            this.hasTuidAttributeEnabled = this.hasCapEnabledAtAccountLevel(caps['Temporary.Permission.Swg.Tuid.Attribute'], account);
            this.hasSilentUpdateEnabled = this.hasCapEnabledAtAccountLevel(caps['Temporary.Permission.Swg.SilentUpdate'], account);
        });
        this.coreService.getUserProfile().subscribe(profile => {
            this.userProfile = profile;
        });
    }
    hasCapEnabledAtAccountLevel(cap, account) {
        return (!!cap &&
            cap.enabled &&
            (!cap.accountCodes || cap.accountCodes.indexOf(account.accountCode) !== -1));
    }
    hasMSAEnabled() {
        return this.hasMsaRemediationEnabled && this.hasWsEnabled;
    }
    hasMSAEditEnabled() {
        return (this.hasMsaRemediationEnabled && this.hasWsEnabled && this.hasWsEndPointSettingsEditEnabled);
    }
    hasMSAReadEnabled() {
        return (this.hasMsaRemediationEnabled && this.hasWsEnabled && this.hasWsEndPointSettingsReadEnabled);
    }
    hasWebSecurityAccountPackage() {
        return this.hasSwgFullPackage;
    }
    hasWebSecCLPPackage() {
        return this.hasCLPPackage;
    }
    hasHybridProxySupport() {
        return this.hasHybridProxySupportEnabled;
    }
    hasPrivateIpReportSupport() {
        return this.privateIpInReportEnabled;
    }
    hasPrivateIpEndpointsSupport() {
        return this.privateIpInEndpointsEnabled;
    }
    hasClpIntegrationEnabled() {
        return this.clpIntegrationEnabled && this.hasWebSecCLPPackage();
    }
    hasSupervisedUserEnabled() {
        return this.supervisedUserEnabled;
    }
    hasApplicationVisibilityEnabled() {
        return this.applicationVisibilityEnabled;
    }
    hasApplicationPolicyControllableOnlyEnabled() {
        return this.applicationPolicyControllableOnlyEnabled;
    }
    hasBrowserIsolationEnabled() {
        return this.hasBiForWebSecurity && this.hasBIWebCapEnabled;
    }
    hasAppControlPolicyAllowRemoved() {
        return this.hasAppPolicyRemoveAllowEnabled;
    }
    hasUninstallPasswordEnabled() {
        return this.enableUninstallPassword;
    }
    hasDeviceAutoEnrollmentSwitchEnabled() {
        return this.hasAutoDeviceEnrollmentEnabled;
    }
    domains5KLimit() {
        return this.has5kDomainsLimit;
    }
    filterList(list, key, doFilter) {
        return doFilter ? list.filter(item => item !== key) : list;
    }
    getDistinctSortedCategoriesFromApps(applications) {
        const distinctAppCategories = [];
        applications.forEach(x => {
            distinctAppCategories.push(...x.category);
        });
        return Array.from(new Set(distinctAppCategories)).sort();
    }
    hasEndpointsDetailsEnabled() {
        return this.endpointsDetailsEnabled;
    }
    getUserProfile() {
        return this.userProfile;
    }
    hasBiForEmailOnly() {
        return this.hasBiForEmail && !this.hasBiForWebSecurity;
    }
    hasAwBlockPageSwitchEnabled() {
        return this.hasAwBlockPageEnabled;
    }
    hasDirectIpProtectionSwitchEnabled() {
        return this.hasDirectIPProtectionEnabled;
    }
    hasAllowInternalIpsSwitchEnabled() {
        return this.hasAllowInternalIpsEnabled;
    }
    hasIPV6Enabled() {
        return this.hasIPV6SwitchEnabled;
    }
    hasDirectIPFiltersEnabled() {
        return this.hasDirectIPFiltersSwitchEnabled;
    }
    hasDirectIpProtectionModeSwitchEnabled() {
        return this.hasDirectIPProtectionModeEnabled;
    }
    hasCLPBlockAndQuarantineActionEnabled() {
        return this.hasClpBlockAndQuarantineActionEnabled;
    }
    hasDeviceBasedPoliciesSwitchEnabled() {
        return this.hasDeviceBasedPoliciesEnabled;
    }
    hasTuidAttributeSwitchEnabled() {
        return this.hasTuidAttributeEnabled;
    }
    hasSilentUpdateSwitchEnabled() {
        return this.hasSilentUpdateEnabled;
    }
}
exports.SwgService = SwgService;
