"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgPolicyDisableConfirmationComponent = void 0;
const policyActions = require("../../../actions/policy.actions");
const sidebar = require("../../../actions/sidebar.actions");
const reducers = require("../../../reducers");
class SwgPolicyDisableConfirmationComponent {
    constructor(store) {
        this.store = store;
        this.header = '';
        this.actionButton = '';
    }
    ngOnInit() {
        this.policy$ = this.store.select(reducers.getSwgPolicy);
        this.isDeleting$ = this.store.select(reducers.getApiIsProcessingDelete);
        this.getHeader();
    }
    onDisable(swgPolicy) {
        const updated = Object.assign(Object.assign({}, swgPolicy), { policyDesc: Object.assign(Object.assign({}, swgPolicy.policyDesc), { enabled: !swgPolicy.policyDesc.enabled }) });
        this.store.dispatch(new policyActions.SwgDisablePolicy(updated));
    }
    getHeader() {
        this.policy$.subscribe(data => {
            if (data.policyDesc.enabled) {
                this.header = '$I18N_SWG_CREATE_POLICY_DISABLE_CONFIRM_HEADER';
                this.actionButton = '$I18N_COMMON_BTN_DISABLE';
            }
            else {
                this.header = '$I18N_SWG_CREATE_POLICY_ENABLE_CONFIRM_HEADER';
                this.actionButton = '$I18N_COMMON_TEXT_ENABLE';
            }
        });
    }
    onCancel() {
        this.store.dispatch(new sidebar.SwgDetailDisableConfirmModelClose());
    }
}
exports.SwgPolicyDisableConfirmationComponent = SwgPolicyDisableConfirmationComponent;
