"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogService = void 0;
const _ = require("lodash");
class LogService {
    constructor(logs, arrRowClicked, niceScroll) {
        this.logs = logs;
        this.arrRowClicked = arrRowClicked;
        this.niceScroll = niceScroll;
    }
    setLogs(logs) {
        this.logs = logs;
    }
    setArrRowClicked(arrRowClicked) {
        this.arrRowClicked = arrRowClicked;
    }
    setNiceScroll(niceScroll) {
        this.niceScroll = niceScroll;
    }
    getLogs() {
        return this.logs;
    }
    getActiveLog(logIndex) {
        return this.logs[logIndex];
    }
    getArrRowClicked() {
        return this.arrRowClicked;
    }
    getHighlightedRow(rowIndex) {
        return this.arrRowClicked[rowIndex];
    }
    highlightRow(rowIndex) {
        this.clearHighlightedRows();
        this.arrRowClicked[rowIndex] = true;
    }
    doScrollDown() {
        this.niceScroll.niceScroll.doScrollTop(this.niceScroll.niceScroll.getScrollTop() + this.niceScroll.rowHeight);
    }
    doScrollUp() {
        this.niceScroll.niceScroll.doScrollTop(this.niceScroll.niceScroll.getScrollTop() - this.niceScroll.rowHeight);
    }
    clearHighlightedRows() {
        _.each(this.arrRowClicked, (item, i) => {
            if (item) {
                this.arrRowClicked[i] = false;
            }
        });
    }
}
exports.LogService = LogService;
