<div class="panel-body" *ngIf="incidentSummary && isIncidentSummaryLoaded">
  <div class="mc-detailed-list">
    <div class="row mc-detailed-list-row">
      <div class="mc-detailed-list-label">
        {{ '$I18N_REMEDIATION_INCIDENT_VIEWER_SUMMARY.LABELS.ID' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ incidentSummary.code }}</div>
    </div>

    <div class="row mc-detailed-list-row">
      <div class="mc-detailed-list-label">
        {{ '$I18N_REMEDIATION_INCIDENT_VIEWER_SUMMARY.LABELS.TYPE' | translate }}
      </div>
      <div class="mc-detailed-list-column capify">{{ getTranslatedTypeName(incidentSummary.type) }}</div>
    </div>

    <div class="row mc-detailed-list-row">
      <div class="mc-detailed-list-label">
        {{ '$I18N_REMEDIATION_INCIDENT_VIEWER_SUMMARY.LABELS.DATE_CREATED' | translate}}
      </div>
      <div class="mc-detailed-list-column">{{ incidentSummary.create | mcDate  }}</div>
    </div>

    <div class="row mc-detailed-list-row" *ngIf="incidentSummary.remediatedBy">
      <div class="mc-detailed-list-label">
        {{ '$I18N_REMEDIATION_INCIDENT_VIEWER_SUMMARY.LABELS.CREATED_BY' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ incidentSummary.remediatedBy }}</div>
    </div>
    <div class="row mc-detailed-list-row">
      <div class="mc-detailed-list-label">
        {{ '$I18N_REMEDIATION_INCIDENT_VIEWER_SUMMARY.LABELS.LAST_UPDATED' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ incidentSummary.modified | mcDate }}</div>
    </div>

    <div class="row mc-detailed-list-row">
      <div class="mc-detailed-list-label">
        {{ '$I18N_REMEDIATION_INCIDENT_VIEWER_SUMMARY.LABELS.SEARCH_DETAILS.LABEL' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        <div class="mc-detailed-list-row" *ngIf="getMessageIds() as messageIds">
          {{ '$I18N_REMEDIATION_INCIDENT_VIEWER_SUMMARY.LABELS.SEARCH_DETAILS.MESSAGE_ID' | translate }}
          <div class="mc-incident-message-ids">
            <span>{{ messageIds[0] }}</span>
            <mc-collapsable-panel *ngIf="messageIds.length > 1">
              <div *ngFor="let messageId of messageIds; let first = first;"><span *ngIf="!first">{{ messageId }}</span></div>
            </mc-collapsable-panel>
          </div>
        </div>
        <div *ngIf="incidentSummary.searchCriteria?.fileHash">
          {{ '$I18N_REMEDIATION_INCIDENT_VIEWER_SUMMARY.LABELS.SEARCH_DETAILS.FILE_HASH' | translate }}
          <span>{{ incidentSummary.searchCriteria?.fileHash }}</span>
        </div>
        <div *ngIf="incidentSummary.searchCriteria?.from">
          {{ '$I18N_REMEDIATION_INCIDENT_VIEWER_SUMMARY.LABELS.SEARCH_DETAILS.FROM' | translate }}
          <span>{{ incidentSummary.searchCriteria?.from }}</span>
        </div>
        <div *ngIf="incidentSummary.searchCriteria?.subject">
          {{ '$I18N_REMEDIATION_INCIDENT_VIEWER_SUMMARY.LABELS.SEARCH_DETAILS.SUBJECT' | translate }}
          <span>{{ incidentSummary.searchCriteria?.subject }}</span>
        </div>
        <div *ngIf="incidentSummary.searchCriteria?.url">
          {{ '$I18N_REMEDIATION_INCIDENT_VIEWER_SUMMARY.LABELS.SEARCH_DETAILS.URL' | translate }}
          <span>{{ incidentSummary.searchCriteria?.url }}</span>
        </div>
        <div *ngIf="incidentSummary.searchCriteria?.start || incidentSummary.searchCriteria?.end">
          {{ '$I18N_REMEDIATION_INCIDENT_VIEWER_SUMMARY.LABELS.SEARCH_DETAILS.DATE' | translate }}
          <span *ngIf="incidentSummary.searchCriteria?.start">{{ incidentSummary.searchCriteria?.start | mcDate }}</span>
          <span *ngIf="(!!incidentSummary.searchCriteria?.start && incidentSummary.searchCriteria?.end)"> / </span>
          <span *ngIf="incidentSummary.searchCriteria?.end">{{ incidentSummary.searchCriteria?.end | mcDate }}</span>
        </div>
      </div>
    </div>
    <div class="row mc-detailed-list-row">
      <div class="mc-detailed-list-label">
        {{ '$I18N_REMEDIATION_INCIDENT_VIEWER_SUMMARY.LABELS.REASON' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ incidentSummary.reason || noValuePlaceHolder }}</div>
    </div>

    <div class="row mc-detailed-list-row">
      <div class="mc-detailed-list-label">
        {{ '$I18N_REMEDIATION_INCIDENT_VIEWER_SUMMARY.LABELS.IDENTIFIED_MESSAGES' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ incidentSummary.identified || noValuePlaceHolder }}</div>
    </div>

    <div class="row mc-detailed-list-row" *ngIf="incidentSummary.successful">
      <div class="mc-detailed-list-label">
        {{ '$I18N_REMEDIATION_INCIDENT_VIEWER_SUMMARY.LABELS.SUCCESSFUL_MESSAGES' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ incidentSummary.successful }}</div>
    </div>

    <div class="row mc-detailed-list-row" *ngIf="incidentSummary.restored">
      <div class="mc-detailed-list-label">
        {{ '$I18N_REMEDIATION_INCIDENT_VIEWER_SUMMARY.LABELS.RESTORED_MESSAGES' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ incidentSummary.restored }}</div>
    </div>

    <div class="row mc-detailed-list-row" *ngIf="incidentSummary.failed">
      <div class="mc-detailed-list-label">
        {{ '$I18N_REMEDIATION_INCIDENT_VIEWER_SUMMARY.LABELS.FAILED_MESSAGES' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ incidentSummary.failed }}</div>
    </div>

    <ng-container *ngIf="getFailureSummary() as failSummary">
      <div class="row mc-detailed-list-row" *ngIf="failSummary.length > 0">
        <div class="mc-detailed-list-label">
          {{ '$I18N_REMEDIATION_INCIDENT_VIEWER_SUMMARY.LABELS.FAILURE_REASON' | translate }}
        </div>
        <div class="mc-detailed-list-column">
          <span *ngFor="let failure of failSummary; last as isLast;">
            {{ '$I18N_REMEDIATION.FAILURE_TYPE.' + failure | uppercase | translate }}
            <label>({{ failureSummary.failureSummary[failure] }})</label><label *ngIf="!isLast">, </label>
          </span>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<mc-widget-placeholder
  *ngIf="!incidentSummary && isIncidentSummaryLoaded"
  label="$I18N_REMEDIATION_INCIDENT_VIEWER_SUMMARY.NO_DATA"
  icon="far fa-list-alt">
</mc-widget-placeholder>

<mc-spinner class="spinner-wrapper" *ngIf="!isIncidentSummaryLoaded"></mc-spinner>



