<mc-empty-results
  keyTitle="$I18N_ARCHIVE_SUPERVISION_DASHBOARD.NO_STATISTICS.TITLE"
  iconClass="far fa-th"
>
  <mc-empty-body>
    <div class="panel-half-padding-bottom">
      {{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.NO_STATISTICS.DESCRIPTION.PREFIX' | translate }}
      <span class="link" data-test="lexicon" (click)="openTab('lexicon')">
        {{
          '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.NO_STATISTICS.DESCRIPTION.LINK_LEXICON' | translate
        }}
      </span>
      {{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.NO_STATISTICS.DESCRIPTION.AND' | translate }}
      <span class="link" data-test="queue" (click)="openTab('queue')">
        {{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.NO_STATISTICS.DESCRIPTION.LINK_QUEUE' | translate }}
      </span>
      {{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.NO_STATISTICS.DESCRIPTION.INFIX' | translate }}
      <span class="link" data-test="rule" (click)="openTab('rule')">
        {{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.NO_STATISTICS.DESCRIPTION.LINK_RULE' | translate }}
      </span>
      {{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.NO_STATISTICS.DESCRIPTION.SUFFIX' | translate }}
    </div>
  </mc-empty-body>
</mc-empty-results>
