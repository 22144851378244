"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HeldMessagesTabsPageComponent = exports.heldMessagesTabs = void 0;
const core_1 = require("@angular/core");
const heldQueueAction = require("../../actions/held-queue.actions");
const moment = require("moment");
var heldMessagesTabs;
(function (heldMessagesTabs) {
    heldMessagesTabs[heldMessagesTabs["overview"] = 0] = "overview";
    heldMessagesTabs[heldMessagesTabs["heldQueue"] = 1] = "heldQueue";
    heldMessagesTabs[heldMessagesTabs["releaseLogs"] = 2] = "releaseLogs";
})(heldMessagesTabs = exports.heldMessagesTabs || (exports.heldMessagesTabs = {}));
class HeldMessagesTabsPageComponent {
    constructor(heldQueueService, store, capabilitiesService) {
        this.heldQueueService = heldQueueService;
        this.store = store;
        this.capabilitiesService = capabilitiesService;
        this.tabChange = new core_1.EventEmitter();
    }
    ngOnInit() {
        this.hasHeldReadPermission$ = this.capabilitiesService.hasCapability('Permission.HELD_READ');
        this.hasHeldReadSummaryPermission$ = this.capabilitiesService.hasCapability('Permission.HELD_SUMMARY_READ');
    }
    onTabChanged(newTab) {
        this.tabChange.emit(newTab.name);
    }
    switchToHeldQueue(heldData) {
        this.tabs.tabClicked(heldMessagesTabs.heldQueue);
        if (heldData['field'] === 'heldGroup') {
            this.heldQueueService.heldQueueSearchByGroup(heldData['value']);
        }
        else if (heldData['field'] === 'heldReason') {
            this.heldQueueService.heldQueueSearchByReason(heldData['value']);
        }
    }
    switchToHeldQueueFromAutocomplete(heldDataAutocomplete) {
        const switchToHeldQueueFromAutocomplete = {
            field: 'heldReason',
            value: heldDataAutocomplete
        };
        this.store.dispatch(new heldQueueAction.HeldQueueRequestAction({
            search: {
                start: moment()
                    .subtract(30, 'days')
                    .toDate(),
                end: moment().toDate(),
                searchBy: [{ fieldName: 'policyInfo', value: heldDataAutocomplete }],
                filterBy: []
            }
        }));
        this.switchToHeldQueue(switchToHeldQueueFromAutocomplete);
    }
}
exports.HeldMessagesTabsPageComponent = HeldMessagesTabsPageComponent;
