"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DirectorySyncAsideComponent = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const test_connection_interface_1 = require("../../models/test-connection.interface");
const confirmation_modal_1 = require("../../modals/confirmation-modal");
const connector_migration_modal_1 = require("app-new/directory-sync/modals/connector-migration-modal");
const actions = require("../../actions");
const reducers = require("../../reducers");
class DirectorySyncAsideComponent {
    constructor(overlayRef, fb, modalService, capabilitiesService, store, data) {
        this.overlayRef = overlayRef;
        this.fb = fb;
        this.modalService = modalService;
        this.capabilitiesService = capabilitiesService;
        this.store = store;
        this.data = data;
        this.summaryIntegration = new rxjs_1.BehaviorSubject(undefined);
        this.summaryIntegration$ = this.summaryIntegration.asObservable();
        this.connectionInfo = {
            servertype: test_connection_interface_1.ServerType[this.data.integration.serverType]
        };
        this.isFirstTimeOnTestTab = true;
        this.delay = 200;
        this.azureLink = 'https://community.mimecast.com/s/article/Synchronizing-User-Attributes-with-Azure-Active-Directory-1699094134';
        this.isAzureStandardHecate = false;
        this.isAzureGCCHecate = false;
        this.integration = this.data.integration;
        this.mseSites = this.data.mseSites;
    }
    ngOnInit() {
        this.selectedRow$ = this.store.select(reducers.getIntegrationsSelectedRow);
        this.enabledForm = this.fb.group({
            isEnabled: this.data.integration.enabled
        });
        this.summaryIntegration.next(Object.assign({}, this.integration));
        this.selectedRow$.subscribe(value => {
            if (value) {
                this.summaryIntegration.next(value);
            }
        });
        this.enabledFormSubscription = this.enabledForm.valueChanges
            .pipe(operators_1.debounceTime(this.delay))
            .subscribe((value) => {
            this.updateStatus(value.isEnabled);
        });
        if (this.overlayRef.getBackdropElement()) {
            this.overlayRef.getBackdropElement().addEventListener('click', () => this.backdropClose());
        }
        this.capabilitiesService
            .hasCapability('DirSync.Azure.Standard.Hecate')
            .pipe(operators_1.take(1))
            .subscribe(isActive => {
            this.isAzureStandardHecate = isActive;
        });
        this.capabilitiesService
            .hasCapability('DirSync.Azure.GCC.Hecate')
            .pipe(operators_1.take(1))
            .subscribe(isActive => {
            this.isAzureGCCHecate = isActive;
        });
    }
    updateStatus(isEnabled) {
        const integration = Object.assign(Object.assign({}, this.summaryIntegration.getValue()), { enabled: isEnabled, domains: [this.summaryIntegration.getValue().domains] });
        if (integration.serverType === 'ad_push') {
            integration.adPushSettings = {
                mseSiteId: this.summaryIntegration.getValue().adPushSettings.mseSiteId
            };
        }
        if (isEnabled === this.summaryIntegration.getValue().enabled) {
            return;
        }
        this.store.dispatch(new actions.UpdateIntegrationStatusAction(integration, this.data.pageSize));
    }
    closeAside() {
        this.overlayRef.close();
        this.store.dispatch(new actions.ClearSearchDirectoryResultsAction());
    }
    editIntegration() {
        if ((this.shouldShowMigrationModal('standard') && this.isAzureStandardHecate) ||
            (this.shouldShowMigrationModal('gov') && this.isAzureGCCHecate)) {
            this.showConnectorMigrationModal();
        }
        else {
            this.store.dispatch(new actions.OpenWizardAction(true, this.integration));
            this.overlayRef.close();
        }
    }
    deleteIntegration() {
        if (!(this.modal && this.modal.getBackdropElement())) {
            this.showConfirmationModal('delete', this.integration);
        }
    }
    shouldShowMigrationModal(typeToCheck) {
        var _a, _b;
        const serverSubtypeMatch = ((_a = this.integration.office365Settings) === null || _a === void 0 ? void 0 : _a.serverSubtype) === typeToCheck;
        const noConnectorId = !((_b = this.integration.office365Settings) === null || _b === void 0 ? void 0 : _b.connectorId);
        return serverSubtypeMatch && noConnectorId;
    }
    onTabChange(event) {
        if (event.name === '$I18N_DIRECTORY_SYNC_ASIDE.TAB.TEST' && this.isFirstTimeOnTestTab) {
            this.setConnectionInfo();
            rxjs_1.of(true)
                .pipe(operators_1.delay(this.delay))
                .subscribe(() => {
                this.integrationTestComponent.retryConnectionTest();
            });
            this.isFirstTimeOnTestTab = false;
        }
    }
    setConnectionInfo() {
        this.connectionInfo = {
            description: this.integration.description,
            info: this.integration.info,
            domains: this.integration.domains,
            connectionId: this.integration.id,
            servertype: test_connection_interface_1.ServerType[this.integration.serverType]
        };
        if (this.isLdapConnection()) {
            this.connectionInfo.ldaphost = this.integration.ldapSettings.hostname;
            this.connectionInfo.ldaphostalt = this.integration.ldapSettings.alternateHostname;
            this.connectionInfo.ldapport = this.integration.ldapSettings.port;
            this.connectionInfo.userdn = this.integration.ldapSettings.userDn;
            this.connectionInfo.rootdn = this.integration.ldapSettings.rootDn;
            this.connectionInfo.encryptionMode = this.integration.ldapSettings.encryptionMode;
        }
        if (this.isAzureConnection()) {
            this.connectionInfo.serversubtype =
                test_connection_interface_1.ServerSubType[this.integration.office365Settings.serverSubtype];
            this.connectionInfo.userdn = this.integration.office365Settings.clientId;
            this.connectionInfo.rootdn = this.integration.office365Settings.tenantDomain;
            this.connectionInfo.connectorId = this.integration.office365Settings.connectorId;
        }
        if (this.isGsuiteConnection()) {
            this.connectionInfo.userdn = this.integration
                .googleDirectorySettings.user;
        }
    }
    isLdapConnection() {
        return (test_connection_interface_1.ServerType[this.integration.serverType] === test_connection_interface_1.ServerType.active_directory ||
            test_connection_interface_1.ServerType[this.integration.serverType] === test_connection_interface_1.ServerType.domino);
    }
    isAzureConnection() {
        return test_connection_interface_1.ServerType[this.integration.serverType] === test_connection_interface_1.ServerType.office_365;
    }
    isGsuiteConnection() {
        return test_connection_interface_1.ServerType[this.integration.serverType] === test_connection_interface_1.ServerType.google_directory;
    }
    isMSEIntegration() {
        return test_connection_interface_1.ServerType[this.integration.serverType] === test_connection_interface_1.ServerType.ad_push;
    }
    backdropClose() {
        // Show modal only if test connection tab has been viewed
        if (this.isFirstTimeOnTestTab) {
            this.closeAside();
        }
        else {
            this.showConfirmationModal('close');
        }
    }
    showConnectorMigrationModal() {
        const modalConfig = {};
        modalConfig.data = {
            sidePanel: this.overlayRef
        };
        this.modal = this.modalService.open(connector_migration_modal_1.ConnectorMigrationModalComponent, modalConfig);
    }
    showConfirmationModal(actionType, integration) {
        const modalConfig = {};
        modalConfig.size = 'lg';
        modalConfig.hasBackdrop = true;
        modalConfig.disableClose = true;
        modalConfig.data = {
            type: actionType,
            sidePanel: this.overlayRef,
            pageSize: this.data.pageSize
        };
        if (!!integration) {
            modalConfig.data['integrationName'] = integration.description;
            modalConfig.data['integrationID'] = integration.id;
        }
        this.modal = this.modalService.open(confirmation_modal_1.ConfirmationModalComponent, modalConfig);
    }
    ngOnDestroy() {
        this.enabledFormSubscription.unsubscribe();
    }
}
exports.DirectorySyncAsideComponent = DirectorySyncAsideComponent;
