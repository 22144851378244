"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const swg_dashboard_container_component_1 = require("./container/swg-dashboard.container.component");
angular
    .module('swgDashboard', [])
    .controller('SwgDashboardController', [
    '$stateParams',
    '$scope',
    function ($stateParams, $scope) {
        $scope.stateParams = $stateParams;
        $scope.showNotification = $stateParams.showNotification;
    }
])
    .directive('mcSwgDashboardContainer', static_1.downgradeComponent({
    component: swg_dashboard_container_component_1.SwgDashboardContainerComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider.state('secure-web-gateway-dashboard', {
            url: '/secure-web-gateway-dashboard',
            params: {
                showNotification: ''
            },
            data: {
                capabilities: 'Permission.SWG_DASHBOARD_READ',
                isFixedLayout: false,
                checkProgress: false,
                tabReload: true,
                tabTitle: '$I18N_MEGA_MENU_GROUP_SECURE_WEB_GATEWAY_DASHBOARD'
            },
            views: {
                main: {
                    template: '<mc-swg-dashboard-container [show-notification]=showNotification></mc-swg-dashboard-container>',
                    controller: 'SwgDashboardController'
                }
            }
        });
    }
]);
