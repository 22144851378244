"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const domain_page_component_1 = require("./containers/domain-list/domain-page.component");
angular
    .module('monitoredExternalDomain', [])
    .directive('mcMonitoredExternalDomainPage', static_1.downgradeComponent({
    component: domain_page_component_1.DomainPageComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider.state('custom-monitored-external-domains', {
            url: '/custom-monitored-external-domains',
            data: {
                isFixedLayout: false,
                checkProgress: false,
                tabReload: true,
                capabilities: 'Temporary.Services.Chameleon',
                tabTitle: 'Custom Monitored External Domains',
                breadcrumbs: ['Services', 'Custom Monitored External Domains']
            },
            views: {
                main: {
                    template: '<mc-monitored-external-domain-page></mc-monitored-external-domain-page>'
                }
            }
        });
    }
]);
