"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgApplicationsComponent = exports.ApplicationControlRouterTabs = exports.applicationControlTabs = void 0;
var applicationControlTabs;
(function (applicationControlTabs) {
    applicationControlTabs[applicationControlTabs["overview"] = 0] = "overview";
    applicationControlTabs[applicationControlTabs["applicationList"] = 1] = "applicationList";
    applicationControlTabs[applicationControlTabs["events"] = 2] = "events";
})(applicationControlTabs = exports.applicationControlTabs || (exports.applicationControlTabs = {}));
var ApplicationControlRouterTabs;
(function (ApplicationControlRouterTabs) {
    ApplicationControlRouterTabs["OVERVIEW"] = "overview";
    ApplicationControlRouterTabs["CLOUD_APPS"] = "applications";
    ApplicationControlRouterTabs["EVENTS"] = "events";
})(ApplicationControlRouterTabs = exports.ApplicationControlRouterTabs || (exports.ApplicationControlRouterTabs = {}));
class SwgApplicationsComponent {
}
exports.SwgApplicationsComponent = SwgApplicationsComponent;
