<div class="mc-api-integration-step-block">
    <mc-wizard-step-header [label]="'$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.OUTBOUND.HEADER'">
    </mc-wizard-step-header>
    
    <br/>
    <br/>
    <div class="mc-detailed-list mc-api-integration-crowdstrike-outbound-block">
      {{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.OUTBOUND.DESCRIPTION' | translate }}
      <form
        class="form-horizontal"
        mcDefaultLabelClass="col-sm-2"
        mcDefaultControlContainerClass="col-sm-6"
        [formGroup]="form"
      >
        <mc-field
            label="{{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.OUTBOUND.DATA_TYPE' | translate }}"
        >
          <mc-checkbox
            formControlName="maliciousHashesEnabledField"
            class="mc-checkbox"
            label="{{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.OUTBOUND.MALICIOUS_HASHES' | translate }}"
            ></mc-checkbox>
        </mc-field>
          <mc-field
            *ngIf="isMaliciousHashesEnabled()" 
            label="{{
              '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.OUTBOUND.ACTION' | translate
            }}"
          >
            <mc-select formControlName="actionField" [options]="actionOptions" [defaultValue]="form.value.actionField">></mc-select>
          </mc-field>
        <div *mcCapabilities="'Temporary.Services.ApiApplications.Crowdstrike.Integration.PushUrl'"
            class="mc-api-integration-crowdstrike-outbound-urls">
          <mc-field>
            <mc-checkbox
              formControlName="maliciousUrlEnabledField"
              class="mc-checkbox"
              label="{{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.OUTBOUND.MALICIOUS_URLS' | translate }}"
              ></mc-checkbox>
          </mc-field>

          <mc-field *ngIf="isMaliciousUrlEnabled()" label="{{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.OUTBOUND.ACTION' | translate }}">
            <mc-select formControlName="iocOutboundUrlActionField" [options]="urlActionOptions" [defaultValue]="form.value.iocOutboundUrlActionField">></mc-select>
          </mc-field>
          <mc-field *ngIf="isMaliciousUrlEnabled()" label="{{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.OUTBOUND.SEVERITY' | translate }}">
            <mc-select formControlName="iocOutboundSeverityField" [options]="severityOptions" [defaultValue]="form.value.iocOutboundSeverityField">></mc-select>
          </mc-field>
          <mc-field *ngIf="isMaliciousUrlEnabled()" label="{{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.OUTBOUND.EXPIRATION' | translate }}">
            <mc-select formControlName="iocOutboundExpirationField" [options]="expirationOptions" [defaultValue]="form.value.iocOutboundExpirationField">></mc-select>
          </mc-field>
        </div>
      </form>
    </div>
  </div>
  