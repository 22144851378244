"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformDateRangeFilter = exports.transformFilters = void 0;
/**
 * Transforms table filters to a valid API payload
 *
 * @param filters
 * @returns
 */
function transformFilters(filters) {
    let selectedFilters = [];
    for (const key in filters) {
        if (filters.hasOwnProperty(key)) {
            selectedFilters = selectedFilters.concat(filters[key].map((val) => ({ fieldName: key, value: val })));
        }
    }
    if (!selectedFilters.length) {
        return undefined;
    }
    return { filterBy: selectedFilters };
}
exports.transformFilters = transformFilters;
/**
 * Transforms table date-range-picker to a valid API payload
 *
 * @param dateRange
 * @returns
 */
function transformDateRangeFilter(dateRange) {
    return {
        start: dateRange.start.format(),
        end: dateRange.end.format()
    };
}
exports.transformDateRangeFilter = transformDateRangeFilter;
