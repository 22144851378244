<mc-modal-header headerTitle="{{headerTitle}}">
</mc-modal-header>

<mc-modal-body>
  <p>{{message}}</p>
</mc-modal-body>

<mc-modal-footer>
  <a target="_blank" class="btn btn-text" data-test="learnMore" rel="noreferer noopener" (click)="learnMore('https://community.mimecast.com/s/article/Migrating-Connectors')">{{learnMoreButtonText}}</a>
  <a target="_self" class="btn btn-primary" data-test="migrateNow" rel="noreferer noopener" (click)="migrateNow('#/services/connectors')">{{migrateNowButtonText}}</a>
</mc-modal-footer>
