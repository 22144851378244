"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IdentityAuthenticationMethodsStepComponent = void 0;
const forms_1 = require("@angular/forms");
const policy_1 = require("../../../models/policy");
const stepActions = require("../../../actions/policy-wizard/authentication-methods-step.actions");
const wizardActions = require("../../../actions/policy-wizard/wizard.actions");
const operators_1 = require("rxjs/operators");
const policy_wizard_1 = require("../../../reducers/policy-wizard");
class IdentityAuthenticationMethodsStepComponent {
    constructor(formBuilder, store) {
        this.formBuilder = formBuilder;
        this.store = store;
        this.domainOptions = [
            {
                value: null,
                label: '$I18N_IDENTITY_CREATE_POLICY_AUTH_METHODS_STEP.ELEMENTS.DOMAIN.SELECT.NO_SELECT'
            },
            {
                value: policy_1.DelegatedAuthOption.LDAP,
                label: '$I18N_IDENTITY_CREATE_POLICY_AUTH_METHODS_STEP.ELEMENTS.DOMAIN.SELECT.LDAP'
            },
            {
                value: policy_1.DelegatedAuthOption.EWS,
                label: '$I18N_IDENTITY_CREATE_POLICY_AUTH_METHODS_STEP.ELEMENTS.DOMAIN.SELECT.EWS_BASIC'
            }
        ];
        this.cloudAuthOptions = [
            {
                value: policy_1.CloudPasswordOption.NEVER_ALLOW,
                label: '$I18N_IDENTITY_CREATE_POLICY_AUTH_METHODS_STEP.ELEMENTS.CLOUD_AUTH.SELECT.NEVER_ALLOW'
            },
            {
                value: policy_1.CloudPasswordOption.ALWAYS_ALLOW,
                label: '$I18N_IDENTITY_CREATE_POLICY_AUTH_METHODS_STEP.ELEMENTS.CLOUD_AUTH.SELECT.ALWAYS_ALLOW'
            },
            {
                value: policy_1.CloudPasswordOption.ALLOW_IN_CON,
                label: '$I18N_IDENTITY_CREATE_POLICY_AUTH_METHODS_STEP.ELEMENTS.CLOUD_AUTH.SELECT.ALLOW_IN_CON'
            }
        ];
        this.secondFactorOptions = [
            {
                value: policy_1.SecondFactorOption.SMS,
                label: '$I18N_IDENTITY_CREATE_POLICY_AUTH_METHODS_STEP.ELEMENTS.SECOND_FACTOR.SELECT.TEXT_MESSAGE'
            },
            {
                value: policy_1.SecondFactorOption.TOTP,
                label: '$I18N_IDENTITY_CREATE_POLICY_AUTH_METHODS_STEP.ELEMENTS.SECOND_FACTOR.SELECT.THIRD_PARTY'
            },
            {
                value: policy_1.SecondFactorOption.NONE,
                label: '$I18N_IDENTITY_CREATE_POLICY_AUTH_METHODS_STEP.ELEMENTS.SECOND_FACTOR.SELECT.NONE'
            }
        ];
    }
    ngOnInit() {
        this.policyAuthMethodsForm = this.formBuilder.group({
            domain: [null, forms_1.Validators.required],
            cloudAuthentication: [this.cloudAuthOptions[0].value, forms_1.Validators.required],
            mimecastAuthenticator: [true, forms_1.Validators.required],
            casServer: [''],
            alternateSuffix: [''],
            secondFactor: [this.secondFactorOptions[0].value, forms_1.Validators.required],
            requestBefore: [true, forms_1.Validators.required]
        });
        this.valueChangesSubscription = this.policyAuthMethodsForm.valueChanges.subscribe(value => {
            this.store.dispatch(new stepActions.SaveDomainAction(value.domain));
            this.store.dispatch(new stepActions.SaveCloudAuthenticationAction(value.cloudAuthentication));
            this.store.dispatch(new stepActions.SaveMimeAuthEnabledAction(value.mimecastAuthenticator));
            this.store.dispatch(new stepActions.SaveCasServerAction(value.casServer));
            this.store.dispatch(new stepActions.SaveAlternateDomainSuffixAction(value.alternateSuffix));
            this.store.dispatch(new stepActions.SaveSecondFactorAction(value.secondFactor));
            this.store.dispatch(new stepActions.SaveRequestBeforePasswordAction(value.requestBefore));
        });
        this.domainFormControlSubscription = this.policyAuthMethodsForm
            .get('domain')
            .valueChanges.subscribe((domainSelected) => {
            const casServerControl = this.policyAuthMethodsForm.get('casServer');
            if (domainSelected === this.domainOptions[2].value) {
                casServerControl.setValidators([forms_1.Validators.required]);
            }
            else {
                casServerControl.clearValidators();
            }
            casServerControl.updateValueAndValidity();
        });
        this.statusChangesSubscription = this.policyAuthMethodsForm.statusChanges.subscribe(() => {
            this.dispatchValid();
        });
        this.store
            .select(policy_wizard_1.getAuthenticationMethodsStepState)
            .pipe(operators_1.first())
            .subscribe(state => {
            const secondFactorOption = state.secondFactorOption === policy_1.SecondFactorOption.MIMEAUTH
                ? policy_1.SecondFactorOption.SMS
                : state.secondFactorOption;
            this.policyAuthMethodsForm.patchValue({
                domain: state.domain,
                cloudAuthentication: state.cloudAuthentication,
                mimecastAuthenticator: state.mimeAuthEnabled,
                casServer: state.casServer,
                alternateSuffix: state.alternateDomainSuffix,
                secondFactor: secondFactorOption,
                requestBefore: state.requestSecondFactorFirst
            }, { emitEvent: false });
            this.dispatchValid();
        });
    }
    dispatchValid() {
        this.store.dispatch(new wizardActions.UpdateIsValidAction(2, this.policyAuthMethodsForm.valid));
    }
    ngOnDestroy() {
        this.valueChangesSubscription.unsubscribe();
        this.domainFormControlSubscription.unsubscribe();
        this.statusChangesSubscription.unsubscribe();
    }
}
exports.IdentityAuthenticationMethodsStepComponent = IdentityAuthenticationMethodsStepComponent;
