"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewQueueConfigComponent = void 0;
const queue_list_actions_1 = require("app-new/archive/supervision/actions/queue.list.actions");
const queue_details_actions_1 = require("app-new/archive/supervision/actions/queue.details.actions");
const selectors = require("app-new/archive/supervision/reducers/index");
const queue_list_actions_2 = require("app-new/archive/supervision/actions/queue.list.actions");
const lodash_1 = require("lodash");
const queueListActions = require("../../../../supervision/actions/queue.list.actions");
const dashboard_settings_1 = require("../../../../supervision/model/supervision/dashboard/settings/dashboard-settings");
const delete_confirmation_modal_component_1 = require("../../../../supervision/components/delete-confirmation-modal/delete-confirmation-modal.component");
const rule_usage_notification_modal_component_1 = require("../../../../supervision/components/rule-usage-notification-modal/rule-usage-notification-modal.component");
class ViewQueueConfigComponent {
    constructor(capabilitiesService, store, tableContent, overlayRef, modalService) {
        this.capabilitiesService = capabilitiesService;
        this.store = store;
        this.tableContent = tableContent;
        this.overlayRef = overlayRef;
        this.modalService = modalService;
        this.canDeleteQueueConfig = 'Temporary.Supervision.Delete && Permission.SUPERVISION_EDIT';
    }
    ngOnInit() {
        this.hasEditPermission$ = this.capabilitiesService.hasCapability('Permission.SUPERVISION_EDIT');
        this.queueDetails$ = this.store.select(selectors.getQueueDetails);
        this.queueLoading$ = this.store.select(selectors.getQueueDetailsLoading);
        this.paginationInfo$ = this.store.select(selectors.getQueueDetailsPagination);
        this.hasDeleteCapability$ = this.capabilitiesService.evalCapabilitiesExpression(this.canDeleteQueueConfig);
    }
    ngOnDestroy() {
        this.store.dispatch(new queue_details_actions_1.CleanQueueDetailsAction());
        this.store.dispatch(new queue_list_actions_1.CloseQueueDetailsSidepanelAction());
    }
    loadDetails(queue) {
        this.store.dispatch(new queue_details_actions_1.GetQueueDetailsAction(queue));
        this.store.dispatch(new queue_details_actions_1.UpdateQueueDetailsPaginationInfoAction(queue, this.tableContent));
    }
    edit(queue) {
        this.store.dispatch(new queue_list_actions_2.OpenEditQueueAction(queue.id));
    }
    close() {
        if (this.overlayRef) {
            this.overlayRef.close();
        }
    }
    deleteQueueConfig(selectedQueue) {
        if (lodash_1.isEmpty(selectedQueue.associatedRules)) {
            this.confirmDeletion(selectedQueue);
        }
        else {
            this.ruleUsageNotification(selectedQueue.name, selectedQueue.associatedRules);
        }
    }
    ruleUsageNotification(queueName, associatedRules) {
        this.modalService.open(rule_usage_notification_modal_component_1.RuleUsageNotificationModalComponent, {
            size: 'lg',
            hasBackdrop: true,
            disableClose: true,
            data: {
                entityName: queueName,
                associatedRules,
                entityDeletionType: dashboard_settings_1.DeletionType.QUEUE
            }
        });
    }
    confirmDeletion(selectedQueue) {
        const modal = this.modalService.open(delete_confirmation_modal_component_1.DeleteConfirmationModalComponent, {
            size: 'lg',
            hasBackdrop: true,
            disableClose: true,
            data: {
                queueType: selectedQueue.type,
                entityName: selectedQueue.name,
                entityDeletionType: dashboard_settings_1.DeletionType.QUEUE,
                escalationQueues: selectedQueue.escalationQueues,
                associatedReviewQueues: selectedQueue.associatedReviewQueues
            }
        });
        modal.afterClose().subscribe(confirmed => {
            if (confirmed) {
                this.store.dispatch(new queueListActions.DeleteQueueAction({ id: selectedQueue.id }));
            }
        });
    }
}
exports.ViewQueueConfigComponent = ViewQueueConfigComponent;
