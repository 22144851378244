"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const logsAction = require("../../actions/logs/logs.action");
class LogEffects {
    constructor(actions$, remediationApiService, notificationService, translateService) {
        this.actions$ = actions$;
        this.remediationApiService = remediationApiService;
        this.notificationService = notificationService;
        this.translateService = translateService;
        this.getLogs$ = this.actions$.pipe(effects_1.ofType(logsAction.GET_LOGS), operators_1.switchMap(() => {
            return this.remediationApiService.findLogs().pipe(operators_1.map((response) => response.first), operators_1.map((response) => response.logs), operators_1.map((response) => new logsAction.RemediationGetLogsSuccessAction(response)), operators_1.catchError(() => this.logsFailAction()));
        }));
    }
    logsFailAction() {
        return rxjs_1.of(new logsAction.RemediationGetLogsFailureAction()).pipe(operators_1.tap(() => {
            this.notificationService.error({
                msg: this.translateService.instant('$I18N_REMEDIATION.ERRORS.GENERIC')
            });
        }));
    }
}
__decorate([
    effects_1.Effect()
], LogEffects.prototype, "getLogs$", void 0);
exports.LogEffects = LogEffects;
