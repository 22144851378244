<div class="mc-api-integration-step-block">
    <mc-wizard-step-header [label]="'$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.INBOUND.HEADER'">
    </mc-wizard-step-header>

    <div class="mc-detailed-list mc-api-integration-crowdstrike-inbound-block">
      <form
        class="form-horizontal"
        mcDefaultLabelClass="col-sm-2"
        mcDefaultControlContainerClass="col-sm-6"
        [formGroup]="form">
        <mc-field label="$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.INBOUND.DATA_TYPE">
          <mc-checkbox
            formControlName="pullMaliciousHashesEnabledField"
            class="mc-checkbox"
            label="$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.INBOUND.FILE_HASHES">
          </mc-checkbox>
        </mc-field>

        <div class="mc-api-integration-crowdstrike-inbound-note">
          {{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.INBOUND.FILE_HASHES_DESCRIPTION' | translate }}
          <br />
          <br />
          <span
            class="mc-api-integration-crowdstrike-inbound-note-title">{{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.INBOUND.NOTE' | translate }}:
          </span>{{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.INBOUND.FILE_HASHES_NOTE' | translate }}
        </div>

        <br />
        <br />
        <mc-field label="$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.INBOUND.ACTIONS">
          <mc-checkbox
            formControlName="remediationEnabledField"
            class="mc-checkbox"
            label="$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.INBOUND.REMEDIATE_MESSAGES">
          </mc-checkbox>
        </mc-field>

        <div class="mc-api-integration-crowdstrike-inbound-note">
          {{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.INBOUND.REMEDIATE_DESCRIPTION' | translate }}
          <br />
          <br />
          <span
            class="mc-api-integration-crowdstrike-inbound-note-title">{{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.INBOUND.NOTE' | translate }}:
          </span>{{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.INBOUND.REMEDIATE_NOTE' | translate }}
        </div>
      </form>
      
    </div>
  </div>
  