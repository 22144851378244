"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DirectorySyncGsuiteSettingsStepComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const operators_1 = require("rxjs/operators");
const validators_1 = require("../../../../../../api-applications/validators");
const directory_sync_integration_interface_1 = require("../../../../../models/directory-sync-integration.interface");
const common_1 = require("../../../../../common/common");
class DirectorySyncGsuiteSettingsStepComponent {
    constructor(fb) {
        this.fb = fb;
        this.delay = 200;
        this.onIntegrationDetailsUpdated = new core_1.EventEmitter();
        this.GSUITE = 'Google Directory';
        this.emailTooltipKbLink = 'https://community.mimecast.com/s/article/Configuring-G-Suite-for-Directory-Synchronization-1869308981';
    }
    ngOnInit() {
        this.form = this.fb.group({
            email: [
                this.integration.email || '',
                [forms_1.Validators.required, validators_1.validateNotEmpty, forms_1.Validators.email]
            ],
            json: this.getJsonKeyValidation()
        });
        this.update();
        this.formSubscription = this.form.valueChanges.pipe(operators_1.debounceTime(this.delay)).subscribe(() => {
            this.update();
        });
    }
    isValidJson(control) {
        let valid = null;
        if (control.value === '' || control.value === directory_sync_integration_interface_1.HiddenValues.json) {
            return null;
        }
        try {
            const json = JSON.parse(control.value);
            common_1.validGSuiteJSONKeys.forEach(key => {
                if (!json.hasOwnProperty(key) || json[key].length === 0) {
                    valid = { valid: false };
                }
            });
        }
        catch (e) {
            valid = { valid: true };
        }
        return valid;
    }
    update() {
        this.onIntegrationDetailsUpdated.emit(this.form.getRawValue());
    }
    isValid() {
        return this.form.status === 'VALID';
    }
    getJsonKeyValidation() {
        return this.isEditMode && !this.integration.hasServerTypeUpdatedOnEdit
            ? [directory_sync_integration_interface_1.HiddenValues.json, [validators_1.validateNotEmpty, this.isValidJson]]
            : ['', [forms_1.Validators.required, validators_1.validateNotEmpty, this.isValidJson]];
    }
    ngOnDestroy() {
        this.formSubscription.unsubscribe();
    }
}
exports.DirectorySyncGsuiteSettingsStepComponent = DirectorySyncGsuiteSettingsStepComponent;
