"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DirectorySyncAsideDirectoryBrowserComponent = void 0;
const common_1 = require("../../common/common");
const forms_1 = require("@angular/forms");
const actions = require("../../actions");
const reducers = require("../../reducers");
class DirectorySyncAsideDirectoryBrowserComponent {
    constructor(fb, translateService, store) {
        this.fb = fb;
        this.translateService = translateService;
        this.store = store;
    }
    ngOnInit() {
        this.searchResults$ = this.store.select(reducers.getSearchDirectoryResults);
        this.isLoading$ = this.store.select(reducers.isSearchDirectoryLoading);
        this.form = this.fb.group({
            name: this.integration.description,
            rootDn: [
                this.integration.ldapSettings.rootDn || '',
                [forms_1.Validators.required, forms_1.Validators.pattern(common_1.ROOT_DN_REGEX)]
            ],
            directoryFilter: ['', [forms_1.Validators.required, forms_1.Validators.pattern(/=/)]],
            returnAttributes: '',
            queryScope: 'object'
        });
    }
    get queryScopeOptions() {
        return [
            {
                value: 'object',
                label: this.translateService.instant('$I18N_DIRECTORY_SYNC_ASIDE_TAB_DIRECTORY_BROWSER.OBJECT_SCOPE')
            },
            {
                value: 'one_level',
                label: this.translateService.instant('$I18N_DIRECTORY_SYNC_ASIDE_TAB_DIRECTORY_BROWSER.ONE_LEVEL_SCOPE')
            },
            {
                value: 'subtree',
                label: this.translateService.instant('$I18N_DIRECTORY_SYNC_ASIDE_TAB_DIRECTORY_BROWSER.SUBTREE_SCOPE')
            }
        ];
    }
    searchDirectory() {
        const searchQuery = {
            filter: this.form.value.directoryFilter,
            connectionId: this.integration.id,
            address: this.integration.ldapSettings.hostname,
            rootDn: this.form.value.rootDn,
            encryptionMode: this.integration.ldapSettings.encryptionMode,
            port: this.integration.ldapSettings.port,
            serverType: this.integration.serverType,
            scope: this.form.value.queryScope,
            userDn: this.integration.ldapSettings.userDn
        };
        if (this.form.value.returnAttributes !== '') {
            searchQuery.attributes = this.form.value.returnAttributes.split(',');
        }
        this.store.dispatch(new actions.SearchDirectoryAction(searchQuery));
    }
    getResults(results) {
        const result = [];
        for (const [key, value] of Object.entries(results)) {
            result.push(...value.map(val => ({ key, value: val })));
        }
        return result;
    }
    checkSearchableItem(value) {
        return value.toLowerCase().startsWith('cn=');
    }
    updateSearchDirectory(query) {
        this.form.get('rootDn').setValue(query);
        this.searchDirectory();
    }
}
exports.DirectorySyncAsideDirectoryBrowserComponent = DirectorySyncAsideDirectoryBrowserComponent;
