"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BulkDeliveryTableComponent = void 0;
const core_1 = require("@angular/core");
const rxjs_1 = require("rxjs");
const bulk_delivery_table_static_values_1 = require("./bulk-delivery-table.static-values");
const date_range_config_1 = require("../../../common/components/table-search-configs/date-range-config");
class BulkDeliveryTableComponent {
    constructor(emailRoutePipe) {
        this.emailRoutePipe = emailRoutePipe;
        this.columns = [...bulk_delivery_table_static_values_1.tableColumns];
        this.filterSections = bulk_delivery_table_static_values_1.tableSectionFilters;
        this.columnsToStartHidden = [...bulk_delivery_table_static_values_1.tableColumnsStartHidden];
        this.columnsAlwaysVisible = [...bulk_delivery_table_static_values_1.tableColumnsAlwaysVisible];
        this.searchSections = bulk_delivery_table_static_values_1.tableSectionSearch;
        this.dateRangePickerConfig = date_range_config_1.DateRangeConfig.config;
        this.columnsToIgnore = bulk_delivery_table_static_values_1.tableColumnsToIgnore;
        this.selectedRows = [];
        this.exportColumns = [...bulk_delivery_table_static_values_1.exportColumns];
        this.rowData = [];
        this.onPaginatorChanged = new core_1.EventEmitter();
        this.onSearchFilterChanged = new core_1.EventEmitter();
        this.onDateFilterChanged = new core_1.EventEmitter();
        this.onColumnFilterChanged = new core_1.EventEmitter();
        this.onRowClicked = new core_1.EventEmitter();
        this.onMessagesRejected = new core_1.EventEmitter();
        this.onMessagesRetried = new core_1.EventEmitter();
        this.onRouteRecalculated = new rxjs_1.Subject();
        this.onMessagesEarlyBounced = new core_1.EventEmitter();
        this.onMessagesExported = new core_1.EventEmitter();
    }
    set rows(data) {
        this.rowData = (data || []).map(msg => {
            return Object.assign(Object.assign({}, msg), { routing: this.emailRoutePipe.transform(msg.routing) });
        });
    }
    get rows() {
        return this.rowData;
    }
    ngOnInit() { }
    clickRow(message) {
        this.onRowClicked.emit(message);
    }
    changePaginator(pagination) {
        this.onPaginatorChanged.emit(pagination);
    }
    changeColumnFilter(filter) {
        this.onColumnFilterChanged.emit(this.toFilterBy(filter));
    }
    toFilterBy(filter) {
        const filterby = [];
        filterby.push(...this.toFilters('route', filter.route));
        filterby.push(...this.toFilters('attachments', filter.attachments));
        return { filterBy: filterby };
    }
    toFilters(fieldName, filters) {
        if (!!filters && filters.length > 0) {
            return filters.map(value => {
                return { fieldName, value };
            });
        }
        else {
            return [];
        }
    }
    changeSearchFilter(filter) {
        this.onSearchFilterChanged.emit(this.toSearchBy(filter));
    }
    toSearchBy(filters) {
        if (!!filters.searchQuery) {
            return {
                searchBy: [{ fieldName: filters.selectValue, value: filters.searchQuery }]
            };
        }
        else {
            return { searchBy: [] };
        }
    }
    isSelectedRow() {
        return this.selectedRows.length > 0 ? true : false;
    }
    changeDateFilter(filter) {
        this.onDateFilterChanged.emit(filter);
    }
    rejectMessages(messages) {
        this.onMessagesRejected.emit(messages || this.selectedRows);
    }
    retryMessages(messages) {
        this.onMessagesRetried.emit(messages || this.selectedRows);
    }
    earlyBounceMessages(messages) {
        this.onMessagesEarlyBounced.emit(messages || this.selectedRows);
    }
    selectRows(rows) {
        this.selectedRows = rows;
    }
    recalculateRoute(messages) {
        this.onRouteRecalculated.next(messages || this.selectedRows);
    }
    exportResults() {
        this.onMessagesExported.emit(this.exportColumns);
    }
}
exports.BulkDeliveryTableComponent = BulkDeliveryTableComponent;
