"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ5 = exports.ɵ4 = exports.ɵ3 = exports.ɵ2 = exports.ɵ1 = exports.ɵ0 = exports.getProvidersLoading = exports.getProviders = exports.getAllConnectorsLoading = exports.getAllConnectors = exports.isProcessing = exports.gethasError = exports.getContents = exports.getFileName = exports.getAdvancedFileTypesLoading = exports.getAdvancedFileTypes = exports.getProtectedApplicationsLoaded = exports.getProtectedApplicationsLoading = exports.getProtectedApplications = exports.getApplicationsLoaded = exports.getApplicationsLoading = exports.getApplications = exports.getCategorySectionLoading = exports.getWebCategoryRulesSections = exports.getLoadingForEdit = exports.getApiIsProcessingDelete = exports.getApiIsProcessing = exports.getLogSettings = exports.getDomains = exports.getShowAffectedUserAddedNotification = exports.getShowDuplicateWarning = exports.getWizardFlowType = exports.getPolicyDesc = exports.getForceDuplicate = exports.getSwgPolicyCopy = exports.getSwgPolicy = exports.getConnectorsState = exports.getParseFileState = exports.getAdvancedFileTypeState = exports.getApplicationsState = exports.getWebCategoryRulesState = exports.getCreatePolicy = exports.getSwgPolicyState = exports.reducers = void 0;
const createPolicy = require("./policy/swg-policy.reducer");
const swgCategories = require("./categories/swg-categories.reducer");
const swgApplications = require("./applications/swg-applications.reducer");
const connectors = require("./connectors/swg-connectors.reducer");
const advancedFileType = require("./advanced-file-types/swg-advanced-file-types.reducer");
const parseFile = require("./parse-file/swg-parse-file.reducer");
const store_1 = require("@ngrx/store");
exports.reducers = {
    createPolicy: createPolicy.reducer,
    webCategoryRules: swgCategories.reducer,
    applications: swgApplications.reducer,
    advancedFileTypes: advancedFileType.reducer,
    parseFile: parseFile.reducer,
    connectors: connectors.reducer
};
exports.getSwgPolicyState = store_1.createFeatureSelector('swgPolicies');
const ɵ0 = state => state.createPolicy;
exports.ɵ0 = ɵ0;
exports.getCreatePolicy = store_1.createSelector(exports.getSwgPolicyState, ɵ0);
const ɵ1 = state => state.webCategoryRules;
exports.ɵ1 = ɵ1;
exports.getWebCategoryRulesState = store_1.createSelector(exports.getSwgPolicyState, ɵ1);
const ɵ2 = state => state.applications;
exports.ɵ2 = ɵ2;
exports.getApplicationsState = store_1.createSelector(exports.getSwgPolicyState, ɵ2);
const ɵ3 = state => state.advancedFileTypes;
exports.ɵ3 = ɵ3;
exports.getAdvancedFileTypeState = store_1.createSelector(exports.getSwgPolicyState, ɵ3);
const ɵ4 = state => state.parseFile;
exports.ɵ4 = ɵ4;
exports.getParseFileState = store_1.createSelector(exports.getSwgPolicyState, ɵ4);
const ɵ5 = state => state.connectors;
exports.ɵ5 = ɵ5;
exports.getConnectorsState = store_1.createSelector(exports.getSwgPolicyState, ɵ5);
exports.getSwgPolicy = store_1.createSelector(exports.getCreatePolicy, createPolicy.getSwgPolicy);
exports.getSwgPolicyCopy = store_1.createSelector(exports.getCreatePolicy, createPolicy.getSwgPolicyCopy);
exports.getForceDuplicate = store_1.createSelector(exports.getCreatePolicy, createPolicy.getForceDuplicate);
exports.getPolicyDesc = store_1.createSelector(exports.getCreatePolicy, createPolicy.getPolicyDesc);
exports.getWizardFlowType = store_1.createSelector(exports.getCreatePolicy, createPolicy.getWizardFlowType);
exports.getShowDuplicateWarning = store_1.createSelector(exports.getCreatePolicy, createPolicy.getShowDuplicateWarning);
exports.getShowAffectedUserAddedNotification = store_1.createSelector(exports.getCreatePolicy, createPolicy.getShowAffectedUserAddedNotification);
exports.getDomains = store_1.createSelector(exports.getCreatePolicy, createPolicy.getDomains);
exports.getLogSettings = store_1.createSelector(exports.getCreatePolicy, createPolicy.getLogSettings);
exports.getApiIsProcessing = store_1.createSelector(exports.getCreatePolicy, createPolicy.getApiIsProcessing);
exports.getApiIsProcessingDelete = store_1.createSelector(exports.getCreatePolicy, createPolicy.getApiIsProcessingDelete);
exports.getLoadingForEdit = store_1.createSelector(exports.getCreatePolicy, createPolicy.getLoadingForEdit);
exports.getWebCategoryRulesSections = store_1.createSelector(exports.getWebCategoryRulesState, swgCategories.getWebCategoryRulesSections);
exports.getCategorySectionLoading = store_1.createSelector(exports.getWebCategoryRulesState, swgCategories.getCategorySectionsLoading);
exports.getApplications = store_1.createSelector(exports.getApplicationsState, swgApplications.getApplications);
exports.getApplicationsLoading = store_1.createSelector(exports.getApplicationsState, swgApplications.getApplicationsLoading);
exports.getApplicationsLoaded = store_1.createSelector(exports.getApplicationsState, swgApplications.getApplicationsLoaded);
exports.getProtectedApplications = store_1.createSelector(exports.getApplicationsState, swgApplications.getProtectedApplications);
exports.getProtectedApplicationsLoading = store_1.createSelector(exports.getApplicationsState, swgApplications.getProtectedApplicationsLoading);
exports.getProtectedApplicationsLoaded = store_1.createSelector(exports.getApplicationsState, swgApplications.getProtectedApplicationsLoaded);
exports.getAdvancedFileTypes = store_1.createSelector(exports.getAdvancedFileTypeState, advancedFileType.getAdvancedFileTypes);
exports.getAdvancedFileTypesLoading = store_1.createSelector(exports.getAdvancedFileTypeState, advancedFileType.getApiIsProcessing);
exports.getFileName = store_1.createSelector(exports.getParseFileState, parseFile.getFileName);
exports.getContents = store_1.createSelector(exports.getParseFileState, parseFile.getContents);
exports.gethasError = store_1.createSelector(exports.getParseFileState, parseFile.gethasError);
exports.isProcessing = store_1.createSelector(exports.getParseFileState, parseFile.isProcessing);
exports.getAllConnectors = store_1.createSelector(exports.getConnectorsState, connectors.getConnectors);
exports.getAllConnectorsLoading = store_1.createSelector(exports.getConnectorsState, connectors.getConnectorsLoading);
exports.getProviders = store_1.createSelector(exports.getConnectorsState, connectors.getProviders);
exports.getProvidersLoading = store_1.createSelector(exports.getConnectorsState, connectors.getProvidersLoading);
