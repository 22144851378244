"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MepSidebarEffects = void 0;
const operators_1 = require("rxjs/operators");
const effects_1 = require("@ngrx/effects");
const actions = require("../../actions/sidebar.actions");
const mep_policy_sidebar_container_component_1 = require("../../containers/sidebar/mep-policy-sidebar.container.component");
const enable_confirmation_modal_component_1 = require("../../components/modals/edit-confirmation-modal/enable-confirmation-modal.component");
const policy_actions_1 = require("../../actions/policy.actions");
const { MEP_POLICY_LIST_SIDEBAR_OPEN, MEP_POLICY_LIST_SIDEBAR_CLOSE, MEP_POLICY_ENABLE_CONFIRM_MODAL_OPEN, MEP_POLICY_ENABLE_CONFIRM_MODAL_CLOSE } = actions.MepPolicyListSidebarActionsEnum;
class MepSidebarEffects {
    constructor(actions$, aside$, store$, modalService) {
        this.actions$ = actions$;
        this.aside$ = aside$;
        this.store$ = store$;
        this.modalService = modalService;
        this.openSidebar$ = this.actions$.pipe(effects_1.ofType(MEP_POLICY_LIST_SIDEBAR_OPEN), operators_1.map((action) => action.payload), operators_1.tap(() => {
            this.sidebarInstance = this.aside$.open(mep_policy_sidebar_container_component_1.MepPolicySidebarContainerComponent, {
                position: 'right',
                size: 'md',
                hasBackdrop: true
            });
        }));
        this.closeSidebar$ = this.actions$.pipe(effects_1.ofType(MEP_POLICY_LIST_SIDEBAR_CLOSE), operators_1.filter(() => !!this.sidebarInstance), operators_1.tap(() => {
            this.sidebarInstance.close();
            this.sidebarInstance = undefined;
        }));
        this.openMepPolicyDisableAlert = this.actions$.pipe(effects_1.ofType(MEP_POLICY_ENABLE_CONFIRM_MODAL_OPEN), operators_1.tap((action) => {
            this.currentPolicyId = action.payload;
            this.disableAlertModalInstance = this.modalService.open(enable_confirmation_modal_component_1.MepEnableConfirmationModalComponent, {
                size: 'md',
                hasBackdrop: true
            });
            this.afterCloseSub = this.disableAlertModalInstance.afterClose().subscribe(() => {
                this.store$.dispatch(new policy_actions_1.MepPolicyLoadForEditing(this.currentPolicyId));
                this.currentPolicyId = null;
                this.afterCloseSub.unsubscribe();
            });
        }));
        this.closeMepPolicyDisableAlert = this.actions$.pipe(effects_1.ofType(MEP_POLICY_ENABLE_CONFIRM_MODAL_CLOSE), operators_1.filter(() => !!this.disableAlertModalInstance), operators_1.tap((action) => {
            if (!action.payload) {
                this.afterCloseSub.unsubscribe();
            }
            this.disableAlertModalInstance.close();
            this.disableAlertModalInstance = undefined;
        }));
    }
}
__decorate([
    effects_1.Effect({ dispatch: false })
], MepSidebarEffects.prototype, "openSidebar$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], MepSidebarEffects.prototype, "closeSidebar$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], MepSidebarEffects.prototype, "openMepPolicyDisableAlert", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], MepSidebarEffects.prototype, "closeMepPolicyDisableAlert", void 0);
exports.MepSidebarEffects = MepSidebarEffects;
