<div class="mc-api-gateway-step-block">
  <mc-wizard-step-header
    [label]="'$I18N_API_GATEWAY_APPLICATIONS_KEYGEN_WIZARD.AUTHENTICATION_STEP.HEADER' | translate"
  >
  </mc-wizard-step-header>

  <form
    class="form-horizontal"
    mcDefaultLabelClass="col-sm-3"
    mcDefaultControlContainerClass="col-sm-9"
    [formGroup]="form"
    (ngSubmit)="submit()"
  >
    <div class="row form-group">
      <div class="form-group mc-field">
        <label class="control-label col-sm-3">
          <span
            translate="$I18N_API_GATEWAY_APPLICATIONS_KEYGEN_WIZARD.FIELDS.ACCOUNT_EMAIL_ADDRESS"
          ></span>
        </label>
        <div class="col-sm-9 panel-half-padding-top">
          <span>{{ account.emailAddress }}</span>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <mc-form-select
        [label]="'$I18N_API_GATEWAY_APPLICATIONS_KEYGEN_WIZARD.FIELDS.AUTHENTICATION_TYPE'"
        [placeholder]="
          '$I18N_API_GATEWAY_APPLICATIONS_KEYGEN_WIZARD.FIELDS.AUTHENTICATION_TYPE_PLACEHOLDER'
        "
        formControlName="authenticationType"
        [options]="authenticationTypes"
        [useTranslation]="true"
      >
      </mc-form-select>
    </div>

    <div class="row form-group no-margin-bottom">
      <div class="form-group mc-field">
        <label class="control-label col-sm-3">
          <span
            translate="$I18N_API_GATEWAY_APPLICATIONS_KEYGEN_WIZARD.FIELDS.AUTHENTICATION_PASSWORD"
          ></span>
        </label>
        <div class="col-sm-9">
          <label>
            <input class="form-control" type="password" formControlName="password" />
          </label>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <div class="form-group mc-field">
        <div class="col-sm-offset-3 col-sm-9">
          <mc-checkbox
            label="$I18N_API_GATEWAY_APPLICATIONS_KEYGEN_WIZARD.FIELDS.AUTHENTICATION_REVOKE"
            formControlName="revokePreviousBindings"
          >
          </mc-checkbox>
        </div>
      </div>
    </div>

    <div class="row form-group" *ngIf="isError">
      <div class="form-group mc-field">
        <div class="col-sm-offset-3 col-sm-9">
          <mc-inline-notification
            [notification]="{ status: 'warning', boxed: true, msg: errorMessage | translate }"
          >
          </mc-inline-notification>
        </div>
      </div>
    </div>
  </form>
</div>
