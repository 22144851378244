<div class="row">
  <div class="col-md-4">
    <mc-remediation-search-messages-widget [hasSearchWithoutHashPermission]="hasSearchEnabled"
                                           [isIdSearchDateRangeEnabled]="isIdSearchDateRangeEnabled">
    </mc-remediation-search-messages-widget>
    <mc-settings-widget [hasSearchTabEnabled]="hasSearchEnabled"></mc-settings-widget>
  </div>
  <div class="col-md-8">
    <mc-remediation-incidents-preview [hasSearchTabEnabled]="hasSearchEnabled"></mc-remediation-incidents-preview>
    <mc-logs-widget [hasSearchTabEnabled]="hasSearchEnabled"></mc-logs-widget>
  </div>
</div>
