"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MepCapsService = void 0;
const operators_1 = require("rxjs/operators");
class MepCapsService {
    constructor(windowService, coreService) {
        this.windowService = windowService;
        this.coreService = coreService;
        const capsOverRide = this.windowService.window.capsOverride;
        this.coreService
            .getAccount()
            .pipe(operators_1.first())
            .subscribe(account => {
            this.isHoldEnabled = this.hasCapEnabledAtAccountLevel(capsOverRide['Temporary.Services.MEP.Actions.Hold'], account);
        });
    }
    hasCapEnabledAtAccountLevel(cap, account) {
        return (!!cap &&
            cap.enabled &&
            (!cap.accountCodes || cap.accountCodes.indexOf(account.accountCode) !== -1));
    }
}
exports.MepCapsService = MepCapsService;
