"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TopReviewerPerformances = void 0;
class TopReviewerPerformances {
    constructor(reviewerPerformances, totalProcessedCount, queueType, hasAssociatedFailedQueues) {
        this.reviewerPerformances = reviewerPerformances;
        this.totalProcessedCount = totalProcessedCount;
        this.queueType = queueType;
        this.hasAssociatedFailedQueues = hasAssociatedFailedQueues;
    }
}
exports.TopReviewerPerformances = TopReviewerPerformances;
