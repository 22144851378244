"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectorComponent = void 0;
const connector_interface_1 = require("../../models/connector.interface");
const connectors_migration_actions_1 = require("../../actions/connectors-migration.actions");
const operators_1 = require("rxjs/operators");
const connector_actions_1 = require("../../../connectors/actions/connector.actions");
const rxjs_1 = require("rxjs");
class ConnectorComponent {
    constructor(stateService, store, capabilitiesService, apiService) {
        this.stateService = stateService;
        this.store = store;
        this.capabilitiesService = capabilitiesService;
        this.apiService = apiService;
        this.canMigrate$ = this.capabilitiesService.hasCapability('Permission.CONNECTORS_EDIT') ||
            this.capabilitiesService.hasCapability('Permission.SERVER_CONNECTIONS_EDIT');
        this.reconnect = connector_interface_1.MigrationStatus.RECONNECT;
    }
    get providerType() {
        return this.connectorData && this.connectorData.connectorType.toLowerCase().replace(' ', '_');
    }
    migrate() {
        var _a;
        this.store.dispatch(new connectors_migration_actions_1.StartMigrationAction(this.connectorData, this.productData));
        if (((_a = this.connectorData) === null || _a === void 0 ? void 0 : _a.existingConnector) === true) {
            this.apiService
                .getConnectorDetails({
                id: this.connectorData.externalConnectionId
            })
                .pipe(operators_1.take(1), operators_1.map(response => {
                if (response.hasErrors) {
                    return new connector_actions_1.GetConnectorDetailsFail();
                }
                this.connectorData = Object.assign(Object.assign({}, this.connectorData), response.first);
                this.navigate();
            }), operators_1.catchError(() => {
                return rxjs_1.of(new connector_actions_1.GetConnectorDetailsFail());
            }))
                .subscribe();
        }
        else {
            this.navigate();
        }
    }
    navigate() {
        this.stateService.$state.go('services-migrate-connector-page', Object.assign({}, this.connectorData));
    }
}
exports.ConnectorComponent = ConnectorComponent;
