<div class="stats-row">
  <h4>{{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.ALL_QUEUES.TITLE' | translate }}</h4>
  <div class="row">
    <div class="col-sm-6 left-panel">
      <mc-doughnut-chart-panel data-test="pending-messages" [data]="getPendingOverviewData"
        title="{{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.ALL_QUEUES.PENDING_MESSAGES' | translate }}"
        noDataLabel="{{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.ALL_QUEUES.NO_MESSAGES' | translate }}">
      </mc-doughnut-chart-panel>
    </div>
    <div class="col-sm-6 right-panel">
      <mc-doughnut-chart-panel data-test="non-compliant-messages" [data]="getNoncompliantOverviewData" title="{{
          '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.ALL_QUEUES.NONCOMPLIANT_MESSAGES' | translate
        }}" noDataLabel="{{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.ALL_QUEUES.NO_MESSAGES' | translate }}">
      </mc-doughnut-chart-panel>
    </div>
  </div>
</div>

<div class="stats-row">
  <div class="row">
    <div class="col-sm-10">
      <h4>{{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.TOP_QUEUES.REVIEW_QUEUES' | translate }}</h4>
    </div>
    <div class="col-sm-2"><a class="btn-link cursor-pointer pull-right"
        (click)="openReviewSettings()">
        <h5>{{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.TOP_QUEUES.DISPLAY_SETTINGS' | translate }}</h5>
      </a>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-8">
      <mc-top-queues-panel data-test="review-queues-status-table" [data]="statistics.topReviewQueues" [dashboardSettings]="reviewSettings"> </mc-top-queues-panel>
    </div>
    <div class="col-sm-4">
      <mc-top-reviewers-panel data-test="review-queues-reviewers-panel" [data]="statistics.topReviewQueuePerformances">
      </mc-top-reviewers-panel>
    </div>
  </div>
</div>

<div class="stats-row">
  <div class="row">
    <div class="col-sm-10">
      <h4>{{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.TOP_QUEUES.ESCALATION_QUEUES' | translate }}</h4>
    </div>
    <div class="col-sm-2"><a class="btn-link cursor-pointer pull-right"
        (click)="openEscalationSettings()">
        <h5>{{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.TOP_QUEUES.DISPLAY_SETTINGS' | translate }}</h5>
      </a>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-8">
      <mc-top-queues-panel data-test="escalation-queues-status-table" [data]="statistics.topEscalationQueues" [dashboardSettings]="escalationSettings"> </mc-top-queues-panel>
    </div>
    <div class="col-sm-4">
      <mc-top-reviewers-panel data-test="escalation-queues-reviewers-panel" [data]="statistics.topEscalationQueuePerformances">
      </mc-top-reviewers-panel>
    </div>
  </div>
</div>