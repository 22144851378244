<mc-modal-header headerTitle="{{ '$I18N_ONEDRIVE_BACKUPS.CREATE_CONNECTOR.HEADER' | translate }}"> </mc-modal-header>

<mc-modal-body>
  <p>{{ '$I18N_ONEDRIVE_BACKUPS.CREATE_CONNECTOR.CONFIRMATION_MSG' | translate }}</p>
</mc-modal-body>

<mc-modal-footer>
  <button type="button" class="btn btn-secondary" (click)="close(false)">
    {{ '$I18N_ONEDRIVE_BACKUPS.CREATE_CONNECTOR.CANCEL' | translate }}
  </button>

  <button type="button" class="btn btn-primary" (click)="close(true)">
    {{ '$I18N_ONEDRIVE_BACKUPS.CREATE_CONNECTOR.CONFIRM' | translate }}
  </button>
</mc-modal-footer>