<mc-layout-modal-simple  keyTitle="{{'$SCAN_DETAILS_REPORT_MODAL.REPORT_MODAL_TITLE' | translate}}">
  <mc-body-container >
    <div class="mc-report-modal-description-margin">{{'$SCAN_DETAILS_REPORT_MODAL.REPORT_MODAL_DESCRIPTION' | translate}}</div>
    <div class="mc-report-modal-heading">{{ '$I18N_SERVICES_URL_PROTECTION_LOGS_URL_CHECK.SCAN_DETAILS_RESULTS.LABEL_RESULTS' | translate }}</div>
    <div class="mc-detailed-list">
       <div class="mc-report-modal-sub-heading">{{ '$I18N_SERVICES_URL_PROTECTION.URL_TOOLS.CHECK_URLS.RELAXED_CATEGORY_LABEL' | translate }}</div>
      <div class="row mc-detailed-list-row">
        <div class="col-sm-4 mc-detailed-list-label">
          {{ '$I18N_SERVICES_URL_PROTECTION.URL_TOOLS.CHECK_URLS.CURRENT_CATEGORY' | translate }}
        </div>
        <div  class="mc-detailed-list-column">
          {{data.scanDetails.relaxed.categories ? data.scanDetails.relaxed.categories.join(',') : '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.NO_SCAN_DETAILS'  | translate}}
        </div>
      </div>
      <div class="row mc-detailed-list-row">
        <div class="col-sm-4 mc-detailed-list-label">
          {{ '$I18N_SERVICES_URL_PROTECTION.URL_TOOLS.CHECK_URLS.ANALYSIS' | translate}}
        </div>
        <span class="fas {{setScanResultIcon(data.scanDetails.relaxed.scanResult)}}
           mc-scan-detail-icon"></span>
        <div class="mc-first-letter" >{{data.scanDetails.relaxed.scanResult? data.scanDetails.relaxed.scanResult: '-' }}
       </div>
      </div>
      <div class="mc-report-modal-sub-heading">{{ '$I18N_SERVICES_URL_PROTECTION.URL_TOOLS.CHECK_URLS.MODERATE_CATEGORY_LABEL' | translate }}</div>

      <div class="row mc-detailed-list-row">
         <div class="col-sm-4 mc-detailed-list-label">
          {{ '$I18N_SERVICES_URL_PROTECTION.URL_TOOLS.CHECK_URLS.CURRENT_CATEGORY' | translate }}
        </div>
        <div  class="mc-detailed-list-column">
          {{data.scanDetails.moderate.categories ? data.scanDetails.moderate.categories.join(',') : '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.NO_SCAN_DETAILS'  | translate}}
        </div>

      </div>
      <div class="row mc-detailed-list-row">
        <div class="col-sm-4 mc-detailed-list-label">
          {{ '$I18N_SERVICES_URL_PROTECTION.URL_TOOLS.CHECK_URLS.ANALYSIS' | translate}}
        </div>
        <span class="fas {{setScanResultIcon(data.scanDetails.moderate.scanResult)}}
           mc-scan-detail-icon"></span>
        <div class="mc-first-letter" >{{data.scanDetails.moderate.scanResult? data.scanDetails.moderate.scanResult: '-' }}
        </div>
      </div>
      <div class="mc-report-modal-sub-heading">{{ '$I18N_SERVICES_URL_PROTECTION.URL_TOOLS.CHECK_URLS.AGGRESSIVE_CATEGORY_LABEL' | translate }}</div>
      <div class="row mc-detailed-list-row">
               <div class="col-sm-4 mc-detailed-list-label">
          {{ '$I18N_SERVICES_URL_PROTECTION.URL_TOOLS.CHECK_URLS.CURRENT_CATEGORY' | translate }}
        </div>
        <div  class="mc-detailed-list-column">
          {{data.scanDetails.aggressive.categories ? data.scanDetails.aggressive.categories.join(',') : '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.NO_SCAN_DETAILS'  | translate}}
        </div>
</div>

      <div class="row mc-detailed-list-row">
        <div class="col-sm-4 mc-detailed-list-label">
          {{ '$I18N_SERVICES_URL_PROTECTION.URL_TOOLS.CHECK_URLS.ANALYSIS' | translate}}
        </div>
        <span class="fas {{setScanResultIcon(data.scanDetails.aggressive.scanResult)}}
          mc-scan-detail-icon"></span>
        <div class="mc-first-letter" >{{data.scanDetails.aggressive.scanResult? data.scanDetails.aggressive.scanResult: '-' }}
        </div>
      </div>
    </div>
    <div class="mc-report-modal-heading">{{ '$SCAN_DETAILS_REPORT_MODAL.PROPOSE_HEADING' | translate }}</div>

    <div class="mc-detailed-list">
        <form [formGroup]="form" (ngSubmit)="submitURLReport()" class="mc-detailed-list" id="UrlReportForm" >
          <div class="row mc-detailed-list-row">
            <div class="required col-sm-4 mc-detailed-list-label">
            {{'$SCAN_DETAILS_REPORT_MODAL.SAFE_OR_MALICIOUS.LABEL' | translate }}
            </div>
            <div  class="mc-detailed-list-column">
                <div class="radio  mc-report-radio-margin" data-test="advanced-search">
                    <label>
                      <input type="radio" name="safeOrMalicious" [value]="safeOrMalicious.SAFE" formControlName="safeOrMalicious"
                              />
                      {{ '$SCAN_DETAILS_REPORT_MODAL.SAFE_OR_MALICIOUS.SAFE' | translate }}
                    </label>
                  </div>
                  <div class="radio" data-test="basic-search" >
                    <label class="text-bold">
                      <input type="radio" name="safeOrMalicious" [value]="safeOrMalicious.MALICIOUS" formControlName="safeOrMalicious" />
                      {{ '$SCAN_DETAILS_REPORT_MODAL.SAFE_OR_MALICIOUS.MALICIOUS' | translate }}
                    </label>
                  </div>
              </div>
          </div>
        <div class="row mc-detailed-list-row">
        <div class="col-sm-4 mc-detailed-list-label">
          {{ '$SCAN_DETAILS_REPORT_MODAL.CATEGORY_FIELD_LABEL' | translate }}
        </div>
        <div  class="mc-detailed-list-column">
          <mc-select-searchable
            [searchPlaceholder]="'$SCAN_DETAILS_REPORT_MODAL.SEARCH_PLACEHOLDER' | translate"
            [overrideLabel]="selectCategoryPlaceholder"
            formControlName="category" class="mc-searchable-inline">
            <ng-template [ngIf]="data?.categories?.securityCategories">
              <mc-option label="$SCAN_DETAILS_REPORT_MODAL.SECURITY_CATEGORY" header></mc-option>
              <mc-option *ngFor="let data of data.categories.securityCategories" [label]="data.name" [value]="data.code" (click)="setCategory(data)"> </mc-option>
            </ng-template>
            <ng-template [ngIf]="data?.categories?.productivityCategories">
              <mc-option   label="$SCAN_DETAILS_REPORT_MODAL.PRODUCTIVITY_CATEGORY" header></mc-option>
              <mc-option *ngFor="let data of data.categories.productivityCategories" [label]="data.name" [value]="data.code" (click)="setCategory(data)"> </mc-option>
            </ng-template>
          </mc-select-searchable>
        </div>
      </div>
        <div class="row mc-detailed-list-row">
          <div class="col-sm-4 mc-detailed-list-label">
            {{ '$SCAN_DETAILS_REPORT_MODAL.REASON_FIELD_LABEL' | translate }}
          </div>
          <div  class="mc-detailed-list-column">
            <mc-text-area-field
              errorPrefix="$SCAN_DETAILS_REPORT_MODAL.VALIDATION.COMMENT"
              formControlName="userComment"
              [setFocus]="false"
              maxLength="300"
            ></mc-text-area-field>

          </div>
        </div>
        <div class="row mc-detailed-list-row">
        <div class="col-sm-4 mc-detailed-list-label">
          {{ '$SCAN_DETAILS_REPORT_MODAL.UPLOAD_FIELD_LABEL' | translate }}
        </div>
        <div  class="mc-detailed-list-column">
          <label hidden for="fileUpload" name="fileUpload"></label>
          <input type="file" class="mc-file-input" id="fileUpload" max-size=30  [accept]="allowedFileExtension" formControlName="uploadedFile"
                 (change)="onFileSelected($event)" #fileUpload/>
           <label hidden for="uploadFileName" name="fileName"></label><input
              type="text"
              readonly
              id="uploadFileName"
              class="mc-filename-input"
              [value]="fileName"
              [placeholder]="'$SCAN_DETAILS_REPORT_MODAL.ALLOWED_FILES' | translate"/>
          <button type="button" class="btn btn-primary " (click)="fileUpload.click()">
              {{ '$SCAN_DETAILS_REPORT_MODAL.UPLOAD_SELECT_BUTTON' | translate }}
            </button>
          </div>

      </div>
          <div *ngIf="allowedSizeExceeded">
            <span class="text-danger mc-file-size-input-validation-error" >
              {{ '$SCAN_DETAILS_REPORT_MODAL.UPLOAD_FILE_EXCEEDS_FILE_SIZE' | translate }}
            </span>
          </div>
        <div class="row mc-detailed-list-row">
          <div class="col-sm-4 mc-detailed-list-label">
            {{ '$SCAN_DETAILS_REPORT_MODAL.REPORT_FIELD_BUTTON' | translate }}
          </div>
          <div  class="mc-detailed-list-column">
          <mc-text-field
            formControlName="reportTo"
            errorPrefix="$SCAN_DETAILS_REPORT_MODAL.VALIDATION.REPORT_TO"
          >
          </mc-text-field>
          </div>
    </div>
      </form>
      </div>
    <div *ngIf="(isRetryEnabled$ | async)" class="text-danger" >{{'$SCAN_DETAILS_REPORT_MODAL.REPORT_SUBMISSION_FAILED' | translate }}</div>
  </mc-body-container>

  <mc-footer-container>

      <button type="button" class="btn btn-secondary" (click)="onCancel()">
        {{ '$I18N_SERVICES_URL_PROTECTION_LOGS_URL_CHECK.SCREENSHOT_MODAL.CLOSE' | translate }}
      </button>
      <button type="submit" class="btn btn-primary" form="UrlReportForm"  [disabled]="!form.valid || (buttonDisable$ | async)">
        <span *ngIf="(isLoading$ | async) === true" ><em class="far fa-spinner fa-pulse panel-half-margin-right"></em></span>
        <span *ngIf="(isLoading$ | async) === false" translate="$SCAN_DETAILS_REPORT_MODAL.REPORT_SUBMIT_BUTTON"></span>

      </button>

   </mc-footer-container>


</mc-layout-modal-simple>
