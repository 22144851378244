"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PolicyStatusChangeAction = exports.GetPolicyDetailsSuccessAction = exports.OpenPolicyDetailsSidePanelAction = exports.AfterClosePolicyDetailsSidePanelAction = exports.ClosePolicyDetailsSidePanelAction = exports.ClearPolicyDetailsAction = exports.GetPolicyDetailsAction = exports.PolicyDeleteCancelAction = exports.PolicyDeleteFailAction = exports.PolicyDeleteSucessAction = exports.PolicyDeleteAction = exports.PolicyDeleteAttemptAction = exports.PoliciesRequestAction = exports.POLICY_STATUS_CHANGE = exports.AFTER_CLOSE_POLICY_DETAILS_SIDE_PANEL = exports.OPEN_POLICY_DETAILS_SIDE_PANEL = exports.CLOSE_POLICY_DETAILS_SIDE_PANEL = exports.GET_POLICY_DETAILS_SUCCESS = exports.CLEAR_POLICY_DETAILS = exports.GET_POLICY_DETAILS = exports.POLICY_DELETE_FAIL = exports.POLICY_DELETE_SUCCESS = exports.POLICY_DELETE = exports.POLICY_DELETE_CANCEL = exports.POLICY_DELETE_ATTEMPT = exports.POLICIES_REQUEST = void 0;
exports.POLICIES_REQUEST = '[Services / Url protection / Policies] Request Get Policies';
exports.POLICY_DELETE_ATTEMPT = '[Services / Url protection / Policies] Delete Policy Attempt';
exports.POLICY_DELETE_CANCEL = '[Services / Url protection / Policies] Delete Policy Canceled';
exports.POLICY_DELETE = '[Services / Url protection / Policies] Delete Policy';
exports.POLICY_DELETE_SUCCESS = '[Services / Url protection / Policies] Delete Policy Success';
exports.POLICY_DELETE_FAIL = '[Services / Url protection / Policies] Delete Policy Fail';
exports.GET_POLICY_DETAILS = '[Services / Url protection / Policies] Request Get Policy Details';
exports.CLEAR_POLICY_DETAILS = '[Services / Url protection / Policies] Clear Policy Details State';
exports.GET_POLICY_DETAILS_SUCCESS = '[Services / Url protection / Policies] Request Get Policy Details - SUCCESS';
exports.CLOSE_POLICY_DETAILS_SIDE_PANEL = '[Services / Url protection / Policies] Close Policy Details Side Panel';
exports.OPEN_POLICY_DETAILS_SIDE_PANEL = '[Services / Url protection / Policies] Open Policy Details Side Panel';
exports.AFTER_CLOSE_POLICY_DETAILS_SIDE_PANEL = '[Services / Url protection / Policies] After Close Policy Details Side Panel';
exports.POLICY_STATUS_CHANGE = '[Services / Url protection / Policies] Policy Status change in the Policy Details Side Panel';
class PoliciesRequestAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.POLICIES_REQUEST;
    }
}
exports.PoliciesRequestAction = PoliciesRequestAction;
class PolicyDeleteAttemptAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.POLICY_DELETE_ATTEMPT;
    }
}
exports.PolicyDeleteAttemptAction = PolicyDeleteAttemptAction;
class PolicyDeleteAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.POLICY_DELETE;
    }
}
exports.PolicyDeleteAction = PolicyDeleteAction;
class PolicyDeleteSucessAction {
    constructor() {
        this.type = exports.POLICY_DELETE_SUCCESS;
    }
}
exports.PolicyDeleteSucessAction = PolicyDeleteSucessAction;
class PolicyDeleteFailAction {
    constructor() {
        this.type = exports.POLICY_DELETE_FAIL;
    }
}
exports.PolicyDeleteFailAction = PolicyDeleteFailAction;
class PolicyDeleteCancelAction {
    constructor() {
        this.type = exports.POLICY_DELETE_CANCEL;
    }
}
exports.PolicyDeleteCancelAction = PolicyDeleteCancelAction;
class GetPolicyDetailsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_POLICY_DETAILS;
    }
}
exports.GetPolicyDetailsAction = GetPolicyDetailsAction;
class ClearPolicyDetailsAction {
    constructor() {
        this.type = exports.CLEAR_POLICY_DETAILS;
    }
}
exports.ClearPolicyDetailsAction = ClearPolicyDetailsAction;
class ClosePolicyDetailsSidePanelAction {
    constructor() {
        this.type = exports.CLOSE_POLICY_DETAILS_SIDE_PANEL;
    }
}
exports.ClosePolicyDetailsSidePanelAction = ClosePolicyDetailsSidePanelAction;
class AfterClosePolicyDetailsSidePanelAction {
    constructor() {
        this.type = exports.AFTER_CLOSE_POLICY_DETAILS_SIDE_PANEL;
    }
}
exports.AfterClosePolicyDetailsSidePanelAction = AfterClosePolicyDetailsSidePanelAction;
class OpenPolicyDetailsSidePanelAction {
    constructor() {
        this.type = exports.OPEN_POLICY_DETAILS_SIDE_PANEL;
    }
}
exports.OpenPolicyDetailsSidePanelAction = OpenPolicyDetailsSidePanelAction;
class GetPolicyDetailsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_POLICY_DETAILS_SUCCESS;
    }
}
exports.GetPolicyDetailsSuccessAction = GetPolicyDetailsSuccessAction;
class PolicyStatusChangeAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.POLICY_STATUS_CHANGE;
    }
}
exports.PolicyStatusChangeAction = PolicyStatusChangeAction;
