"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./audit-log-view.controller"); //audit-log-view.controller
/**
 * Created by pdesai on 17/02/2017.
 */
(function () {
    'use strict';
    angular.module('audit-log-view.service', ['audit-log-view.controller'])
        .factory('auditLogViewService', ['$aside', function ($aside) {
            let asideInstance;
            return {
                openLogView
            };
            function openLogView(logData) {
                asideInstance = $aside.open({
                    templateUrl: 'services/account/audit-logs/slides/audit-log-view.tpl.html',
                    placement: 'right',
                    windowClass: 'mc-audit-log-view hidden-xs',
                    backdrop: true,
                    backdropClass: 'hidden-xs',
                    controller: angular.noop,
                    resolve: {
                        log() {
                            return logData;
                        }
                    }
                });
                return asideInstance.result;
            }
        }]);
}());
