"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgService = void 0;
const highcharts_1 = require("highcharts");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
class SwgService {
    /**
     * Depends on the api service
     */
    constructor(service, translate) {
        this.service = service;
        this.translate = translate;
    }
    /**
     * gets the TopDomains from the server
     */
    getTopDomains(verdict = 'All') {
        // @ts-ignore
        return this.service.getTopDomains(verdict).pipe(operators_1.map(response => response['topDomains']), operators_1.map(this.convertObjectIntoArrayOfKeyVals), operators_1.catchError(this.handleError));
    }
    /**
     * gets the TopCategories from the server
     */
    getTopCategories(verdict = 'All') {
        // @ts-ignore
        return this.service.getTopCategories(verdict).pipe(operators_1.map(response => response['topCategories']), operators_1.map(this.convertObjectIntoArrayOfKeyVals), operators_1.map(res => this.convertCategoryNamesToHumanReadableStrings(res, this.translate)), operators_1.catchError(this.handleError));
    }
    /**
     * gets the TopCategories from the server
     */
    getActivities() {
        // @ts-ignore
        return this.service.getActivities().pipe(operators_1.map(response => response['activities']), operators_1.map(this.buildLineChartData), operators_1.catchError(this.handleError));
    }
    getDashboardNotifications() {
        return this.service.getDashboardNotifications();
    }
    /**
     * Transform object into Array of key values
     */
    convertObjectIntoArrayOfKeyVals(response) {
        let count = 0;
        const ret = response
            ? Object.keys(response).map(key => {
                count = count + response[key];
                return { key, value: response[key] };
            })
            : [];
        return { list: ret, totalCount: count };
    }
    /**
     * Translate Category keys to pass to highcharts
     */
    convertCategoryNamesToHumanReadableStrings(response, translate) {
        // @ts-ignore
        response.list.forEach(l => {
            l.key = translate.instant('$I18N_SWG_HUMANREADBALE_CATEGORY.' + l.key);
        });
        return response;
    }
    handleError() {
        return rxjs_1.of([]);
    }
    /**
     * Transform object into LineChartSeriesData tuple
     */
    buildLineChartData(options) {
        let count = 0;
        let allowedCount = 0;
        let blockedCount = 0;
        const [blockedArray, allowedArray, chartData] = options.reduce((sum, ele) => {
            sum[0].push([ele.date, ele.blockCount]);
            sum[1].push([ele.date, ele.requestCount]);
            sum[2].push({
                date: ele.date,
                allowed: ele.requestCount,
                blocked: ele.blockCount
            });
            count = count + ele.blockCount + ele.requestCount;
            allowedCount = allowedCount + ele.requestCount;
            blockedCount = blockedCount + ele.blockCount;
            return sum;
        }, [[], [], []]);
        // @ts-ignore
        const blockedLineData = {
            name: 'Blocked',
            data: blockedArray,
            color: highcharts_1.getOptions().colors[0]
        };
        // @ts-ignore
        const allowedLineData = {
            name: 'Allowed',
            data: allowedArray,
            color: highcharts_1.getOptions().colors[1]
        };
        return {
            allowedData: allowedLineData,
            blockedData: blockedLineData,
            totalCount: count,
            blockedCount,
            allowedCount,
            activityChartData: chartData
        };
    }
    goToActivityReport() {
        // this.$state.go('secure-web-gateway-activity-report');
    }
}
exports.SwgService = SwgService;
