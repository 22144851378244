"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const moment = require("moment");
const _ = require("lodash");
/**
 * Created by cfeudo on 07/01/2016.
 */
(function () {
    'use strict';
    angular.module('dashboards.administration.controller', [])
        .controller('DashboardAdministrationController', ['$scope', '$q', '$state', 'accountService', 'directoryService', 'journalingService', 'statsService', 'emailService', '$filter', 'DASHBOARD', 'chartconfigService', 'capabilitiesService', '$interval', 'dashboardCommonsService',
        function ($scope, $q, $state, accountService, directoryService, journalingService, statsService, emailService, $filter, DASHBOARD, chartconfigService, capabilitiesService, $interval, dashboardCommonsService) {
            /* -------------------------------------------------------- *\
     - initialisation
     \* -------------------------------------------------------- */
            const self = this;
            const now = moment().format(DASHBOARD.API_DATE_FORMAT);
            const aWeekback = moment().subtract(7, 'days').format(DASHBOARD.API_DATE_FORMAT);
            const past24hours = moment().subtract(1, 'days').format(DASHBOARD.API_DATE_FORMAT);
            const past48hours = moment().subtract(2, 'days').format(DASHBOARD.API_DATE_FORMAT);
            self.$onInit = () => {
                self.rejectionTypes = [];
                self.rejectionsByType = [];
                self.isOutboundEmailQueueEmpty = false;
                self.emailRejectionsDetail = [];
                self.inboundHasNoData = false;
                self.outboundHasNoData = false;
                self.outboundHasErrorText = null;
                self.inboundHasErrorText = null;
                self.emailTrafficHasNoData = false;
                self.emailTrafficHasErrorText = null;
                self.emailTrafficChartConfig = {};
                self.rejectionHasErrorText = null;
                self.rejectionHasError = false;
                self.notificationFeedHasErrorText = null;
                self.recentActivityErrorText = null;
                self.directoryErrorText = null;
                self.journalingErrorText = null;
                self.exchangeErrorText = null;
                self.accountInformation = {};
                self.accountInformationErrorText = null;
                self.allCapsLayout = (capabilitiesService.hasCapability('Archive.Search') &&
                    capabilitiesService.hasCapability('Policy.AutoAllow'));
                self.gatewayCapLayout = capabilitiesService.hasCapability('Policy.AutoAllow');
                self.archiveCapLayout = capabilitiesService.hasCapability('Archive.Search');
                self.atCapLayout = capabilitiesService.hasCapability('Awareness.Training');
                self.wsCapLayout = capabilitiesService.hasCapability('Internet.Security.DNS') ||
                    capabilitiesService.hasCapability('Internet.Security.DNS.Lite');
                self.isATStandalone = self.atCapLayout && !self.gatewayCapLayout && !self.archiveCapLayout;
                self.isWSStandalone = self.wsCapLayout && !self.gatewayCapLayout && !self.archiveCapLayout;
                /* -------------------------------------------------------- *\
       - interface
       \* -------------------------------------------------------- */
                self.getNotifications = getNotifications;
                self.getDirectoryServiceStatus = getDirectoryServiceStatus;
                self.getJournalServiceStatus = getJournalServiceStatus;
                self.getRecentActivityStats = getRecentActivityStats;
                self.getTotalEmailTraffic = getTotalEmailTraffic;
                self.getInboundEmailQueue = getInboundEmailQueue;
                self.getOutboundEmailQueue = getOutboundEmailQueue;
                self.getExchangeServiceStatus = getExchangeServiceStatus;
                self.getEmailrejectionByType = getEmailrejectionByType;
                self.getEmailQueue = getEmailQueue;
                self.getEmailQueueResponse = getEmailQueueResponse;
                /* -------------------------------------------------------- *\
       - implementation
       \* -------------------------------------------------------- */
                const emailQueueResponse = $q.defer();
                self.serviceStatusDetails = populateServiceStatusDetails();
                if (self.allCapsLayout) {
                    self.gatewayCapLayout = false;
                    self.archiveCapLayout = false;
                }
                function getEmailQueue() {
                    if (self.allCapsLayout || self.gatewayCapLayout) {
                        emailService.getEmailQueue(past48hours, now).then(function (response) {
                            if (!response.err) {
                                emailQueueResponse.resolve(response);
                            }
                        });
                    }
                }
                function getNotifications() {
                    return accountService.getDashboardNotifications().then(function (data) {
                        if (!data.err) {
                            return data.first.notifications;
                        }
                        else {
                            self.notificationFeedHasErrorText = accountService.getError().getFirstKey(data);
                        }
                    });
                }
                function getDirectoryServiceStatus() {
                    return directoryService.getStatusSummary().then(function (data) {
                        if (!data.err) {
                            return data;
                        }
                        else {
                            self.directoryErrorText = directoryService.getError().getFirstKey(data);
                        }
                    });
                }
                function getJournalServiceStatus() {
                    return journalingService.getStatusSummary().then(function (data) {
                        if (!data.err) {
                            return data;
                        }
                        else {
                            self.journalingErrorText = journalingService.getError().getFirstKey(data);
                        }
                    });
                }
                function getExchangeServiceStatus() {
                    return accountService.getExchangeServicesStatus().then(function (data) {
                        if (!data.err) {
                            return data;
                        }
                        else {
                            self.exchangeErrorText = accountService.getError().getFirstKey(data);
                        }
                    });
                }
                function getRecentActivityStats() {
                    return statsService.getActivityStats().then(function (data) {
                        if (!data.err) {
                            return data.all;
                        }
                        else {
                            self.recentActivityErrorText = statsService.getError().getFirstKey(data);
                        }
                    });
                }
                function getTotalEmailTraffic() {
                    self.emailTrafficChartConfig = chartconfigService.getEmailTrafficConfig();
                    return emailService.getTotalEmailTraffic(aWeekback, now).then(function (data) {
                        if (!data.err) {
                            data = chartconfigService.getTotalEmailTrafficConfig(data.first.traffic);
                            if (_.isEmpty(data)) {
                                self.emailTrafficHasNoData = true;
                            }
                            self.emailTrafficChartConfig.series[0].data = dashboardCommonsService.getDataForAllHours(data.inboundCount, moment(aWeekback).valueOf(), moment(now).valueOf());
                            self.emailTrafficChartConfig.series[1].data = dashboardCommonsService.getDataForAllHours(data.internalCount, moment(aWeekback).valueOf(), moment(now).valueOf());
                            self.emailTrafficChartConfig.series[2].data = dashboardCommonsService.getDataForAllHours(data.outboundCount, moment(aWeekback).valueOf(), moment(now).valueOf());
                            return self.emailTrafficChartConfig;
                        }
                        else {
                            self.emailTrafficHasNoData = true;
                            self.emailTrafficHasErrorText = emailService.getError().getFirstKey(data);
                        }
                    });
                }
                function getInboundEmailQueue() {
                    self.emailInboundQueueChartConfig = chartconfigService.getEmailQueueChartConfig();
                    return getEmailQueueResponse().then(function (data) {
                        if (!data.err) {
                            self.emailInboundQueueChartConfig.series[0].data = dashboardCommonsService.getDataForAllTenMins(chartconfigService.getEmailQueueConfig(data.first.inboundEmailQueue).queueStats, past48hours, now);
                            if (_.isEmpty(data.first.inboundEmailQueue)) {
                                self.inboundHasNoData = true;
                            }
                            self.emailInboundQueueChartConfig.series[0].name = $filter('translate')('$I18N_COMMON_EMAIL_TRAFFIC_INBOUND_LABEL');
                            return self.emailInboundQueueChartConfig;
                        }
                        else {
                            self.inboundHasNoData = true;
                            self.inboundHasErrorText = emailService.getError().getFirstKey(data);
                        }
                    });
                }
                function getOutboundEmailQueue() {
                    self.emailOutboundQueueChartConfig = chartconfigService.getEmailQueueChartConfig();
                    return getEmailQueueResponse().then(function (data) {
                        if (!data.err) {
                            self.emailOutboundQueueChartConfig.series[0].data = dashboardCommonsService.getDataForAllTenMins(chartconfigService.getEmailQueueConfig(data.first.outboundEmailQueue).queueStats, past48hours, now);
                            if (_.isEmpty(data.first.outboundEmailQueue)) {
                                self.outboundHasNoData = true;
                            }
                            self.emailOutboundQueueChartConfig.series[0].name = $filter('translate')('$I18N_COMMON_EMAIL_TRAFFIC_OUTBOUND_LABEL');
                            return self.emailOutboundQueueChartConfig;
                        }
                        else {
                            self.outboundHasNoData = true;
                            self.outboundHasErrorText = emailService.getError().getFirstKey(data);
                        }
                    });
                }
                function getRejectionDetails() {
                    if (self.allCapsLayout || self.gatewayCapLayout) {
                        return emailService.getEmailrejections(past24hours, now).then(function (response) {
                            if (!response.err) {
                                self.emailRejectionsDetail = response.first;
                                self.emailRejectionsDetail.rejectionsByType['$I18N_DASHBOARD_TOTAL_NUMBER_OF_REJECTIONS_LABEL'] = self.emailRejectionsDetail.totalRejectionsStat;
                                self.rejectionTypes = Object.keys(self.emailRejectionsDetail.rejectionsByType);
                                self.rejectionTypes.reverse();
                            }
                            else {
                                self.rejectionHasError = true;
                                self.rejectionHasErrorText = emailService.getError().getFirstKey(response);
                            }
                        });
                    }
                }
                function getEmailrejectionByType(type) {
                    const defer = $q.defer();
                    const rejections = dashboardCommonsService.getDataForAllHours(self.emailRejectionsDetail.rejectionsByType[type], moment(past24hours).valueOf(), moment(now).valueOf());
                    defer.resolve(chartconfigService.getRejectionChartConfig(rejections));
                    return defer.promise;
                }
                function getAccountInformation() {
                    return accountService.getAccount().then(function (response) {
                        if (!response.err) {
                            self.accountInformation = response.first;
                            self.accountInformation.loggedInTime = moment(self.accountInformation.loggedInTime);
                        }
                        else {
                            self.accountInformationErrorText = accountService.getError().getFirstKey(response);
                        }
                    });
                }
                function populateServiceStatusDetails() {
                    const hasDirSyncRead = capabilitiesService.hasCapability('Permission.DIRECTORY_SYNC_READ');
                    const hasDirSyncReadAAA = capabilitiesService.hasCapability('Permission.EAM_DIRECTORY_SYNC_READ');
                    const isDirSyncRefreshCapsOn = capabilitiesService.hasCapability('Temporary.Services.Directory.Synchronization') && 'directory-sync';
                    const dirSyncRefresh = hasDirSyncRead && isDirSyncRefreshCapsOn;
                    const dirSyncRefreshAAA = hasDirSyncReadAAA && isDirSyncRefreshCapsOn;
                    const dirSyncNormal = hasDirSyncRead && 'legacy({"uid":"directorysync"})';
                    const dirSyncAAA = hasDirSyncReadAAA && 'legacy({"uid":"aaadirectorysync"})';
                    const serviceStatusDetails = {
                        directory: {
                            capExpression: 'Permission.DIRECTORY_SYNC_READ || Permission.EAM_DIRECTORY_SYNC_READ',
                            title: '$I18N_DASHBOARD_DIRECTORY_CONNECTORS_SERVICE_TITLE',
                            link: dirSyncRefresh || dirSyncRefreshAAA || dirSyncNormal || dirSyncAAA,
                            linkLabel: '$I18N_COMMON_SEE_MORE_LABEL'
                        },
                        journal: {
                            capExpression: 'Permission.JOURNALING_READ || Permission.EAM_JOURNALING_READ',
                            title: '$I18N_DASHBOARD_JOURNAL_CONNECTORS_SERVICE_TITLE',
                            link: (capabilitiesService.hasCapability('Permission.DIRECTORY_SYNC_READ') && 'legacy({"uid":"journaling"})') ||
                                (capabilitiesService.hasCapability('Permission.EAM_DIRECTORY_SYNC_READ') && 'legacy({"uid":"aaajournaling"})'),
                            linkLabel: '$I18N_COMMON_SEE_MORE_LABEL'
                        },
                        exchange: {
                            capExpression: 'Permission.EXCHANGE_SERVICES_READ',
                            title: '$I18N_DASHBOARD_EXCHANGE_CONNECTORS_SERVICE_TITLE',
                            link: 'legacy({"uid":"exchangeservices"})',
                            linkLabel: '$I18N_COMMON_SEE_MORE_LABEL'
                        }
                    };
                    return serviceStatusDetails;
                }
                function getEmailQueueResponse() {
                    return emailQueueResponse.promise;
                }
                const reload = $interval(function () {
                    $scope.tabsCtrl.reloadTab();
                }, DASHBOARD.RELOAD_INTERVAL, 1);
                $scope.tab.onDeselect = function () {
                    $interval.cancel(reload);
                };
                $scope.$on('$destroy', function () {
                    $interval.cancel(reload);
                });
                $scope.tab.onRefresh = self.refresh = function () {
                    $state.reload();
                };
                getAccountInformation();
                getRejectionDetails();
                getEmailQueue();
            };
        }]);
}());
