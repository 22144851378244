"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonitoredExternalDomainListImplService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const table_store_1 = require("@mimecast-ui/table-store");
class MonitoredExternalDomainListImplService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    static getDataFromResponse(response) {
        return {
            data: response.all,
            meta: response.meta,
            hasErrors: response.hasErrors,
            failures: response.fail
        };
    }
    getData(payload, pagination = table_store_1.defaultPaginationOption) {
        return this.httpClient
            .post('/api/ttp/url/monitoreddomain/get-monitored-domains', Object.assign(Object.assign({}, payload), { meta: { pagination } }))
            .pipe(operators_1.map((response) => {
            return MonitoredExternalDomainListImplService.getDataFromResponse(response);
        }), operators_1.catchError((error) => {
            return rxjs_1.of(MonitoredExternalDomainListImplService.getDataFromResponse(error.error));
        }));
    }
    filter(query, pagination) {
        return this.getData(query, pagination);
    }
}
exports.MonitoredExternalDomainListImplService = MonitoredExternalDomainListImplService;
