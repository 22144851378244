"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getForwardingEmail = exports.getForwardingMessages = exports.isSendingForward = exports.reducer = exports.initialState = void 0;
const actions = require("../actions");
exports.initialState = {
    isSendingForward: false,
    forwardingMessages: []
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.FORWARD_MESSAGE_OPEN_MODAL:
            return Object.assign(Object.assign({}, state), { isSendingForward: false, forwardingMessages: action.payload });
        case actions.FORWARD_MESSAGE_SEND:
            return Object.assign(Object.assign({}, state), { isSendingForward: true });
        case actions.FORWARD_MESSAGE_SUCCESS:
            return Object.assign(Object.assign({}, state), { isSendingForward: false });
        case actions.FORWARD_MESSAGE_FAIL:
            return Object.assign(Object.assign({}, state), { isSendingForward: false });
        case actions.FORWARD_MESSAGE_RESET:
            return Object.assign({}, exports.initialState);
        default:
            return state;
    }
}
exports.reducer = reducer;
const isSendingForward = (state) => {
    return state.isSendingForward;
};
exports.isSendingForward = isSendingForward;
const getForwardingMessages = (state) => {
    return state.forwardingMessages;
};
exports.getForwardingMessages = getForwardingMessages;
const getForwardingEmail = (state) => {
    const forwardingMessages = exports.getForwardingMessages(state);
    return {
        subject: 'FW: ' + (forwardingMessages.length === 1 ? forwardingMessages[0].subject : ''),
        attachments: forwardingMessages.map(msg => forwardingMessageAttachment(msg))
    };
};
exports.getForwardingEmail = getForwardingEmail;
function forwardingMessageAttachment(message) {
    return {
        id: message.id,
        filename: message.subject + '.eml',
        extension: 'eml',
        contentType: 'message/rfc822',
        size: message.size
    };
}
