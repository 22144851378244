"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgPolicyDetailsStepComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const operators_1 = require("rxjs/operators");
const policy_1 = require("../../../models/policy");
class SwgPolicyDetailsStepComponent {
    constructor(fb) {
        this.fb = fb;
        this.policyType = policy_1.SwgPolicyType;
        this.hasAppPolicyRemoveAllowEnabled = false;
        this.updatePolicyDescType = new core_1.EventEmitter();
        this.enterPressedEvent = new core_1.EventEmitter();
        this.policyPage = this.fb.group({
            description: ['', forms_1.Validators.required],
            policyType: ['domainFiltering', forms_1.Validators.required],
            enabled: [this.policy ? this.policy.policyDesc.enabled : true]
        });
    }
    ngOnInit() {
        this.policyPage.patchValue(this.policy.policyDesc);
        this.policyPageSubscription = this.policyPage.valueChanges
            .pipe(operators_1.debounceTime(200))
            .subscribe(() => {
            this.updateStore();
        });
    }
    getPageTitle(edit) {
        return edit
            ? '$I18N_SWG_CREATE_POLICY_EDIT_POLICY_NAME'
            : '$I18N_SWG_CREATE_POLICY_SET_UP_POLICY_DETAILS';
    }
    onSubmit() {
        if (this.isValid()) {
            this.enterPressedEvent.emit(true);
        }
    }
    updateStore() {
        this.updatePolicyDescType.emit(this.policyPage.value);
    }
    isValid() {
        return this.policyPage.status === 'VALID';
    }
    ngOnDestroy() {
        this.policyPageSubscription.unsubscribe();
    }
}
exports.SwgPolicyDetailsStepComponent = SwgPolicyDetailsStepComponent;
