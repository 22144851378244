"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessagesActionModalEffect = exports.REMEDIATE_MESSAGES_DELAY_TIME = exports.REMEDIATE_MESSAGES_SCHEDULER = void 0;
const core_1 = require("@angular/core");
const effects_1 = require("@ngrx/effects");
const moment = require("moment");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const remediateMessagesAction = require("../../actions/remediate-messages/remediate-messages.action");
const searchAction = require("../../actions/search/search.action");
const messages_action_modal_component_1 = require("../../components/messages-action-modal/messages-action-modal.component");
const reducers = require("../../reducers");
const components_1 = require("@mimecast-ui/components");
exports.REMEDIATE_MESSAGES_SCHEDULER = new core_1.InjectionToken('Remediate Messages Scheduler');
exports.REMEDIATE_MESSAGES_DELAY_TIME = new core_1.InjectionToken('Remediate Messages Delay Time');
class MessagesActionModalEffect {
    constructor(actions$, modalService, notificationService, translateService, remediationApiService, tabChangeService, stateService, store$, scheduler, delayTime) {
        this.actions$ = actions$;
        this.modalService = modalService;
        this.notificationService = notificationService;
        this.translateService = translateService;
        this.remediationApiService = remediationApiService;
        this.tabChangeService = tabChangeService;
        this.stateService = stateService;
        this.store$ = store$;
        this.scheduler = scheduler;
        this.delayTime = delayTime;
        this.remediateSearchMessagesOpenModal = this.actions$.pipe(effects_1.ofType(remediateMessagesAction.REMEDIATE_SEARCH_MESSAGES_OPEN_MODAL), operators_1.withLatestFrom(this.store$.select(reducers.searchTabPermission), this.store$.select(reducers.selectedRowsSearchTable), this.store$.select(reducers.searchMessagesCount), this.store$.select(reducers.getSearchFormPayload), this.store$.select(reducers.getSearchByAttachmentSha), this.store$.select(reducers.getDeviceSettings)), operators_1.map(([action, searchTabPermission, selectedRowsSearchResult, count, searchCriteria, searchByAttachmentSha, deviceSettings]) => {
            const start = moment()
                .startOf('day')
                .subtract(10, 'years')
                .format(components_1.API_DATE_FORMAT);
            const overlayData = {
                idList: !searchTabPermission ? undefined : selectedRowsSearchResult,
                count: !searchTabPermission ? count : selectedRowsSearchResult.length,
                searchCriteria: !searchTabPermission
                    ? Object.assign(Object.assign({}, searchCriteria), { start }) : Object.assign({}, searchCriteria),
                type: action.incidentType,
                searchByAttachmentSha,
                deviceSettings
            };
            this.messagesActionModalInstance = this.modalService.open(messages_action_modal_component_1.MessagesActionModalComponent, {
                data: overlayData,
                size: 'md'
            });
            return action;
        }));
        this.remediateIncidentMessagesOpenModal = this.actions$.pipe(effects_1.ofType(remediateMessagesAction.REMEDIATE_INCIDENT_MESSAGES_OPEN_MODAL), operators_1.withLatestFrom(this.store$.select(reducers.incidentSummary), this.store$.select(reducers.getDeviceSettings)), operators_1.map(([action, incidentSummary, deviceSettings]) => {
            let count;
            let searchCriteria;
            let searchByAttachmentSha;
            // @ts-ignore
            switch (action.incidentType) {
                default:
                case 'remediate':
                    count = incidentSummary.identified;
                    searchCriteria = incidentSummary.searchCriteria;
                    searchByAttachmentSha = true;
                    break;
                case 'restore':
                    count = incidentSummary.successful;
                    searchCriteria = { unremediateCode: incidentSummary.code };
                    searchByAttachmentSha = false;
                    break;
            }
            searchCriteria = Object.assign(Object.assign({}, searchCriteria), { parentIncidentCode: incidentSummary.code });
            this.messagesActionModalInstance = this.modalService.open(messages_action_modal_component_1.MessagesActionModalComponent, {
                data: {
                    // @ts-ignore
                    type: action.incidentType,
                    searchCriteria,
                    count,
                    searchByAttachmentSha,
                    deviceSettings
                },
                size: 'md'
            });
            return action;
        }));
        this.remediateMessages = this.actions$.pipe(effects_1.ofType(remediateMessagesAction.REMEDIATE_MESSAGES), operators_1.withLatestFrom(this.store$.select(reducers.searchTabPermission)), operators_1.switchMap(([action, searchPermission]) => {
            return this.remediationApiService.createIncident(action.payload).pipe(operators_1.mergeMap((response) => [
                new searchAction.RemediationClearSearch(),
                new remediateMessagesAction.RemediateMessagesSuccessAction(response, searchPermission)
            ]), operators_1.catchError(() => this.remediateMessagesFailAction()));
        }));
        this.remediateMessagesSuccess = this.actions$.pipe(effects_1.ofType(remediateMessagesAction.REMEDIATE_MESSAGES_SUCCESS), operators_1.tap((action) => {
            this.stateService.$state.go('remediation', { tabName: 'incidents' });
            if (action.searchTabPermission) {
                this.tabChangeService.setActiveTab(2, 'incidents');
            }
        }), operators_1.delay(this.delayTime || 1000, this.scheduler || rxjs_1.asyncScheduler), operators_1.tap((action) => {
            this.createSuccessNotification(action);
        }));
        this.remediateMessagesCloseModal = this.actions$.pipe(effects_1.ofType(remediateMessagesAction.REMEDIATE_MESSAGES_SUCCESS, remediateMessagesAction.REMEDIATE_MESSAGES_FAILURE), operators_1.tap(() => {
            this.messagesActionModalInstance.close();
        }));
    }
    createSuccessNotification(action) {
        let message;
        switch (action.payload.type) {
            case 'manual':
                message = '$I18N_MESSAGES_MODAL_ACTION.MANUAL_INCIDENT_SUCCESS_MESSAGE';
                break;
            case 'restore':
                message = '$I18N_MESSAGES_MODAL_ACTION.RESTORE_INCIDENT_SUCCESS_MESSAGE';
                break;
            case 'notify_only':
            case 'automatic':
            default:
                message = '$I18N_MESSAGES_MODAL_ACTION.INCIDENT_SUCCESS_MESSAGE';
        }
        const htmlMessage = `${this.translateService.instant(message)}:<br>${action.payload.code}`;
        const actionButton = {
            label: this.translateService.instant('$I18N_MESSAGES_MODAL_ACTION.GO_TO_INCIDENTS_BUTTON'),
            callback: () => {
                this.stateService.$state.go('remediation-incident', {
                    incidentCode: action.payload.code,
                    incidentId: action.payload.id
                });
            },
            isAutoClose: true,
            type: 'button'
        };
        this.notificationService.success({ msg: htmlMessage, actions: [actionButton] });
    }
    remediateMessagesFailAction() {
        return rxjs_1.of(new remediateMessagesAction.RemediateMessagesFailureAction()).pipe(operators_1.tap(() => {
            this.notificationService.error({
                msg: this.translateService.instant('$I18N_REMEDIATION.ERRORS.GENERIC')
            });
        }));
    }
}
__decorate([
    effects_1.Effect({ dispatch: false })
], MessagesActionModalEffect.prototype, "remediateSearchMessagesOpenModal", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], MessagesActionModalEffect.prototype, "remediateIncidentMessagesOpenModal", void 0);
__decorate([
    effects_1.Effect()
], MessagesActionModalEffect.prototype, "remediateMessages", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], MessagesActionModalEffect.prototype, "remediateMessagesSuccess", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], MessagesActionModalEffect.prototype, "remediateMessagesCloseModal", void 0);
exports.MessagesActionModalEffect = MessagesActionModalEffect;
