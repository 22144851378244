
    <mc-wildfire-integration-aside-view
      [integration]="integration"
      [wildfireIntegration]="wildfireIntegration$ | async"
      [enabled]="enabled$ | async"
      (onEnableClicked)="enable()"
      (onDisableClicked)="disable()"
      (onEditClicked)="edit()"
      (onCloseClicked)="close()"
    >
    </mc-wildfire-integration-aside-view>
  