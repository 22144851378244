"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const message_processing_page_component_1 = require("./containers/message-processing-page.component");
angular
    .module('message-center.message-processing', [])
    .controller('MessageProcessingComponent', [
    '$stateParams',
    '$scope',
    function ($stateParams, $scope) {
        $scope.stateParams = $stateParams;
    }
])
    .directive('mcMessageProcessing', static_1.downgradeComponent({
    component: message_processing_page_component_1.MessageProcessingPageComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider.state('message-processing', {
            url: '/message-center/message-processing',
            data: {
                isFixedLayout: false,
                checkProgress: false,
                capabilities: `
            Temporary.Services.MessageCenter.Processing
            && Permission.PROCESSING_READ
            && !AccountType.EnterpriseMaster
            && !AccountType.PartnerMaster
            && !AccountType.EnterpriseGroup
            && !AccountType.PartnerGroup`,
                // tabReload: true,
                breadcrumbs: [
                    {
                        label: '$I18N_MESSAGE_CENTER_BREADCRUMBS_FIRST_LEVEL',
                        stateName: '',
                        stateParams: { tabName: 'overview' }
                    },
                    {
                        label: '$I18N_MESSAGE_CENTER_MESSAGE_PROCESSING_MESSAGES.BREADCRUMBS.SECOND_LEVEL',
                        stateName: '',
                        stateParams: { tabName: 'overview' }
                    }
                ]
            },
            views: {
                main: {
                    template: '<mc-message-processing></mc-message-processing>',
                    controller: 'MessageProcessingComponent'
                }
            }
        });
    }
]);
