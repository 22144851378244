"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UrlProtectionPolicyEffects = void 0;
const effects_1 = require("@ngrx/effects");
const actions = require("../actions/policies-create.actions");
const editActions = require("../actions/policies-edit.actions");
const operators_1 = require("rxjs/operators");
const rxjs_1 = require("rxjs");
const policy_wizard_models_1 = require("../models/policy-wizard.models");
const reducers = require("../reducers");
const notification_actions_1 = require("app-new/components/notification/actions/notification.actions");
const policies_model_1 = require("../models/policies.model");
const confirmation_modal_component_1 = require("../components/confirmation-modal/confirmation-modal.component");
class UrlProtectionPolicyEffects {
    constructor(modalService, stateService, actions$, policiesService, notificationService, translateService, store) {
        this.modalService = modalService;
        this.stateService = stateService;
        this.actions$ = actions$;
        this.policiesService = policiesService;
        this.notificationService = notificationService;
        this.translateService = translateService;
        this.store = store;
        this.goToCreatePage$ = this.actions$.pipe(effects_1.ofType(actions.OPEN_CREATE_POLICY_PAGE), operators_1.tap(() => {
            this.stateService.$state.go('create-a-policy', { isEditFlow: false });
            this.store.dispatch(new actions.ResetPolicyWizardAction());
        }));
        this.goToPoliciesPage$ = this.actions$.pipe(effects_1.ofType(actions.OPEN_POLICIES_PAGE), operators_1.tap(() => {
            this.stateService.$state.go('url-protection-policies');
        }));
        this.goToEditPage$ = this.actions$.pipe(effects_1.ofType(editActions.OPEN_EDIT_POLICY_WIZARD), operators_1.withLatestFrom(this.store.select(reducers.getWizardFlowType)), operators_1.switchMap(([action, wizardFlowType]) => {
            const policyData = action.payload;
            if (policyData) {
                this.stateService.$state.go('create-a-policy', {
                    isEditFlow: true,
                    isDuplicateFlow: policy_wizard_models_1.PolicyWizardFlowType.DUPLICATE === wizardFlowType
                });
            }
            return rxjs_1.of(new editActions.UpdateCreatePolicyPageInEditMode(this.policiesService.mapToWizardState(policyData, {
                isBypass: !!policyData.policy.policyOptions,
                wizardFlowType
            })));
        }));
        this.getAddressAttributeOptions$ = this.actions$.pipe(effects_1.ofType(actions.GET_ADDRESS_ATTRIBUTE_OPTIONS), operators_1.switchMap(() => {
            return this.policiesService.getAddressAttributes().pipe(operators_1.map((response) => {
                return new actions.GetAddressAttributeOptionsSuccessAction(this.policiesService.appliesToAddressAttributeResponseToItems(response));
            }), operators_1.catchError(error => rxjs_1.of(this.getNotificationAction(error))));
        }));
        this.getCustomPageSetOptions$ = this.actions$.pipe(effects_1.ofType(actions.GET_CUSTOM_PAGE_SET_OPTIONS), operators_1.switchMap(() => {
            return this.policiesService.getUserAwarenessTemplates().pipe(operators_1.map((response) => {
                return new actions.GetCustomPageSetOptionsSuccessAction(this.policiesService.userAwarenessTemplatesResponseToItems(response));
            }), operators_1.catchError(() => rxjs_1.of(new actions.GetCustomPageSetOptionsFailAction())));
        }));
        this.getCustomPageSetOptionsFail$ = this.actions$.pipe(effects_1.ofType(actions.GET_CUSTOM_PAGE_SET_OPTIONS_FAIL), operators_1.switchMap(() => {
            return [
                this.notificationService.newNotification({
                    type: 'error',
                    config: {
                        msg: this.translateService.instant('$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.USER_AWARENESS_SETTINGS.FAIL_TO_LOAD_TEMPLATES_MESSAGE')
                    }
                })
            ];
        }));
        this.createPolicy$ = this.actions$.pipe(effects_1.ofType(actions.CREATE_POLICY), operators_1.withLatestFrom(this.store.select(reducers.getCreatePolicy)), operators_1.switchMap(([, state]) => {
            if (state.detailsStep.data.bypassPolicy) {
                return this.policiesService
                    .createUrlPolicyWithBypass(this.policiesService.mapToBypassPolicyRequest(state))
                    .pipe(operators_1.mergeMap(() => this.successActions()), operators_1.catchError(() => this.errorAction()));
            }
            return this.policiesService
                .createUrlPolicyDefinition(this.policiesService.mapToPolicyDefinitionRulesRequest(state))
                .pipe(operators_1.mergeMap(() => this.successActions()), operators_1.catchError(() => this.errorAction()));
        }));
        this.createPolicySuccess$ = this.actions$.pipe(effects_1.ofType(actions.CREATE_POLICY_SUCCESS), operators_1.map(() => new actions.OpenPoliciesPage()));
        this.cancelPolicyConfirmation$ = this.actions$.pipe(effects_1.ofType(actions.CANCEL_CREATE_POLICY_CONFIRMATION), operators_1.withLatestFrom(this.store.select(reducers.getWizardFlowType)), operators_1.switchMap(([, wizardFlowType]) => {
            return this.modalService
                .open(confirmation_modal_component_1.ConfirmationModalComponent, {
                data: {
                    type: policies_model_1.CONFIRMATION_DIALOG_TYPES.CANCEL,
                    wizardFlowType
                }
            })
                .afterClose()
                .pipe(operators_1.map((result) => {
                return result
                    ? new actions.CancelCreatePolicyYesAction()
                    : new actions.CancelCreatePolicyNoAction();
            }));
        }));
        this.showPolicyListPage$ = this.actions$.pipe(effects_1.ofType(actions.CANCEL_CREATE_POLICY_YES_CONFIRMATION), operators_1.switchMap(() => {
            this.store.dispatch(new actions.ResetPolicyWizardAction());
            this.stateService.$state.go('url-protection-policies');
            return rxjs_1.EMPTY;
        }));
        this.editPolicyDefinition$ = this.actions$.pipe(effects_1.ofType(editActions.EDIT_POLICY_DEFINITION), operators_1.withLatestFrom(this.store.select(reducers.getCreatePolicy)), operators_1.switchMap(([, state]) => {
            const policyDefinitionRulesPayload = this.policiesService.mapToPolicyDefinitionRulesRequest(state);
            return this.policiesService.editPolicyDefinitionRules(policyDefinitionRulesPayload).pipe(operators_1.mergeMap(() => this.editSuccessActions()), operators_1.catchError(() => this.editErrorAction()));
        }));
        this.editPolicy$ = this.actions$.pipe(effects_1.ofType(editActions.EDIT_POLICY), operators_1.withLatestFrom(this.store.select(reducers.getCreatePolicy)), operators_1.switchMap(([, state]) => {
            const policyRulesPayload = this.policiesService.mapDetailsAndAppliesToData(state.detailsStep.data, state.appliesToStep.data, state.summaryStep.data);
            return this.policiesService.editPolicyRules(policyRulesPayload).pipe(operators_1.mergeMap(() => this.editSuccessActions()), operators_1.catchError(() => this.editErrorAction()));
        }));
        this.editPolicySuccess$ = this.actions$.pipe(effects_1.ofType(editActions.EDIT_POLICY_SUCCESS), operators_1.map(() => new actions.OpenPoliciesPage()));
        this.editPolicyState$ = this.actions$.pipe(effects_1.ofType(editActions.EDIT_POLICY_STATUS), operators_1.switchMap((action) => {
            const payload = action.payload;
            return this.policiesService.editUrlPolicyDetails(payload).pipe(operators_1.mergeMap(() => this.editSuccessActions()), operators_1.catchError(() => this.editErrorAction()));
        }));
    }
    successActions() {
        return [
            new actions.CreatePolicySuccessAction(),
            this.notificationService.newNotification({
                type: 'success',
                config: {
                    msg: '$I18N_SERVICES_URL_PROTECTION.CREATE_SUCCESS_MSG'
                }
            })
        ];
    }
    errorAction() {
        return [
            new actions.CreatePolicyFailAction(),
            this.notificationService.newNotification({
                type: 'error',
                config: {
                    msg: '$I18N_SERVICES_URL_PROTECTION.CREATE_FAIL_MSG'
                }
            })
        ];
    }
    getNotificationAction(error) {
        const msg = error.fail
            ? error.fail[0].errors[0].message
            : this.translateService.instant('$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.GET_ADDRESS_ATTRIBUTES_SERVER_ERROR');
        return new notification_actions_1.NotificationShowAction({
            type: 'error',
            config: {
                msg
            }
        });
    }
    editSuccessActions() {
        return [
            new editActions.EditPolicySuccessAction(),
            this.notificationService.newNotification({
                type: 'success',
                config: {
                    msg: '$I18N_SERVICES_URL_PROTECTION.EDIT_SUCCESS_MSG'
                }
            })
        ];
    }
    editErrorAction() {
        return [
            new actions.CreatePolicyFailAction(),
            this.notificationService.newNotification({
                type: 'error',
                config: {
                    msg: '$I18N_SERVICES_URL_PROTECTION.EDIT_FAIL_MSG'
                }
            })
        ];
    }
}
__decorate([
    effects_1.Effect({ dispatch: false })
], UrlProtectionPolicyEffects.prototype, "goToCreatePage$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], UrlProtectionPolicyEffects.prototype, "goToPoliciesPage$", void 0);
__decorate([
    effects_1.Effect()
], UrlProtectionPolicyEffects.prototype, "goToEditPage$", void 0);
__decorate([
    effects_1.Effect()
], UrlProtectionPolicyEffects.prototype, "getAddressAttributeOptions$", void 0);
__decorate([
    effects_1.Effect()
], UrlProtectionPolicyEffects.prototype, "getCustomPageSetOptions$", void 0);
__decorate([
    effects_1.Effect()
], UrlProtectionPolicyEffects.prototype, "getCustomPageSetOptionsFail$", void 0);
__decorate([
    effects_1.Effect()
], UrlProtectionPolicyEffects.prototype, "createPolicy$", void 0);
__decorate([
    effects_1.Effect()
], UrlProtectionPolicyEffects.prototype, "createPolicySuccess$", void 0);
__decorate([
    effects_1.Effect()
], UrlProtectionPolicyEffects.prototype, "cancelPolicyConfirmation$", void 0);
__decorate([
    effects_1.Effect()
], UrlProtectionPolicyEffects.prototype, "showPolicyListPage$", void 0);
__decorate([
    effects_1.Effect()
], UrlProtectionPolicyEffects.prototype, "editPolicyDefinition$", void 0);
__decorate([
    effects_1.Effect()
], UrlProtectionPolicyEffects.prototype, "editPolicy$", void 0);
__decorate([
    effects_1.Effect()
], UrlProtectionPolicyEffects.prototype, "editPolicySuccess$", void 0);
__decorate([
    effects_1.Effect()
], UrlProtectionPolicyEffects.prototype, "editPolicyState$", void 0);
exports.UrlProtectionPolicyEffects = UrlProtectionPolicyEffects;
