<mc-layout-modal-simple keyTitle="{{title}}">
  <mc-footer-container>
    <button type="button" class="btn btn-secondary" (click)="onCancel()">
      {{'$I18N_SERVICES_CYBERGRAPH.DELETE_POLICY_CANCEL_BUTTON' | translate}}
    </button>
    <button type="button" class="btn btn-danger"  (click)="onDelete()">
      <i class="far fa-trash-alt"></i>
      <span>{{buttonText}}</span>
    </button>
  </mc-footer-container>
</mc-layout-modal-simple>
