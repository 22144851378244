"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const moment = require("moment");
require("./mailbox-email-export-restore-success-controller"); //backuprestore-mailbox-details.slides-success.controller
/**
 * Created by dgomez on 23/09/2016.
 */
angular.module('backuprestore-mailbox-details.slides-export-restore.service', [
    'backuprestore-mailbox-details.slides-success.controller'
])
    .factory('mailboxEmailExportRestoreService', ['$aside', 'backupService', function ($aside, backupService) {
        return {
            createRecoveryTask
        };
        function createRecoveryTask(recoveryData, dismissFunction) {
            return backupService.createRecoveryTask(prepareRecoveryData(recoveryData)[recoveryData.recoveryType]()).then(function (response) {
                if (!response.err) {
                    dismissFunction();
                    openSuccessAside(recoveryData.recoveryType);
                }
                else {
                    dismissFunction();
                    if (backupService.getError().getFirstCode(response) === backupService.constants.ERROR_CODES.ERR_RECOVERY_TASK_IN_PROGRESS) {
                        backupService.getError().showWarningNotification(response);
                    }
                    else {
                        backupService.getError().showErrorNotification(response);
                    }
                }
            }, function () {
                backupService.getError().showErrorNotification();
            });
        }
        function prepareRecoveryData(recoveryData) {
            const returnedData = {};
            angular.copy(removeBlankProperties(recoveryData), returnedData);
            returnedData.emailViewType = recoveryData.emailViewType.key;
            returnedData.emailsAfter = moment(recoveryData.emailsAfter, 'YYYYMMDDTHHmmssZZ').utc().format();
            returnedData.emailsBefore = moment(recoveryData.emailsBefore, 'YYYYMMDDTHHmmssZZ').utc().format();
            if (!moment(returnedData.emailsAfter).isValid()) {
                delete returnedData.emailsAfter;
            }
            if (!moment(returnedData.emailsBefore).isValid()) {
                delete returnedData.emailsBefore;
            }
            return {
                export() {
                    returnedData.exportSettings.maximumFileSize = parseInt(recoveryData.exportSettings.maximumFileSize, 10);
                    return returnedData;
                },
                restore() {
                    returnedData.restoreSettings.dataSourceId = recoveryData.restoreSettings.dataSourceId.key;
                    return returnedData;
                }
            };
        }
        function removeBlankProperties(recoveryData) {
            for (const key in recoveryData) {
                if (recoveryData.hasOwnProperty(key)) {
                    if (recoveryData[key] === '') {
                        delete recoveryData[key];
                    }
                }
            }
            return recoveryData;
        }
        function openSuccessAside(recoveryType) {
            const recoveryTaskSuccessMessages = {
                export: {
                    headerTitle() {
                        return '$I18N_BACKUP_RESTORE_MAILBOXES_EXPORT_OPTIONS_LABEL';
                    },
                    title() {
                        return 'I18N_BACK_RESTORE_EXPORT_PROCESSING_EXPORT';
                    },
                    descMsg() {
                        return 'I18N_BACK_RESTORE_EXPORT_PROCESSING_DESC_EXPORT';
                    }
                },
                restore: {
                    headerTitle() {
                        return '$I18N_BACKUP_RESTORE_MAILBOXES_RESTORE_OPTIONS_LABEL';
                    },
                    title() {
                        return 'I18N_BACK_RESTORE_EXPORT_PROCESSING_RESTORE';
                    },
                    descMsg() {
                        return 'I18N_BACK_RESTORE_EXPORT_PROCESSING_DESC_RESTORE';
                    }
                }
            };
            $aside.open({
                templateUrl: 'services/services/backup/mailboxes/mailbox-details/slides/mailbox-email-export-restore-success.tpl.html',
                placement: 'right',
                size: 'lg',
                backdrop: true,
                windowClass: 'hidden-xs',
                backdropClass: 'hidden-xs',
                controller: 'MailboxExportRestoreSuccessCtrl as mersCtrl',
                resolve: recoveryTaskSuccessMessages[recoveryType]
            });
        }
    }]);
