<div class="mc-list-panel mc-widget-height">
  <div class="mc-list-panel-heading">
    {{ '$I18N_SERVICES_URL_PROTECTION_DASHBOARD.URLS_CATEGORY.TITLE' | translate }}
  </div>
  <div class="row">
    <div class="col-xs-12">
      <div class="small-toolbar border-bottom">
          <ng-template #loadingTotalRequest>
            <mc-spinner></mc-spinner>
          </ng-template>
          <div class="pull-right">
            <div class="tab-container">
              <span>{{'$I18N_SERVICES_URL_PROTECTION_DASHBOARD.URLS_CATEGORY.INBOUND' | translate}}</span>
            </div>
          </div>
      </div>
    </div>
  </div>
  <div class="mc-list-panel-body">
    <div class="url-catetory-pie-chart border-bottom">
      <ng-content select="[pie-chart]"></ng-content>
    </div>
    <div class="url-catetory-table">
      <ng-content select="[table]"></ng-content>
    </div>
  </div>
</div>