"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GatewayEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const actions = require("../../actions");
class GatewayEffects {
    constructor(actions$, stateService, apiService, featureFlagService) {
        this.actions$ = actions$;
        this.stateService = stateService;
        this.apiService = apiService;
        this.featureFlagService = featureFlagService;
        this.getApiApplicationRoleListAction$ = this.actions$.pipe(effects_1.ofType(actions.GatewayActionTypes.GetGatewayApplicationRoleList), operators_1.switchMap(() => {
            return this.apiService.getApplicationRoleList().pipe(operators_1.map((response) => new actions.GetGatewayApplicationRoleListSuccessAction(response)), operators_1.catchError(() => rxjs_1.of(new actions.ApiApplicationFailureAction('$I18N_API_APPLICATIONS_LIST.NOTIFICATIONS.ERRORS.GENERAL'))));
        }));
        this.getApiApplicationRoleListActionWithHeader$ = this.actions$.pipe(effects_1.ofType(actions.GatewayActionTypes.GetGatewayApplicationRoleListWithHeader), operators_1.switchMap((action) => {
            return this.apiService.getApplicationRoleList(action && action.attachHeader).pipe(operators_1.map((response) => new actions.GetGatewayApplicationRoleListSuccessAction(response)), operators_1.catchError(() => rxjs_1.of(new actions.GetGatewayApplicationRoleListSuccessAction({ roles: [] }))));
        }));
        this.getApiApplicationProductListAction$ = this.actions$.pipe(effects_1.ofType(actions.GatewayActionTypes.GetGatewayApplicationProductList), operators_1.switchMap(() => {
            return this.apiService.getApplicationProductList().pipe(operators_1.map((response) => new actions.GetGatewayApplicationProductListSuccessAction(response)), operators_1.catchError(() => rxjs_1.of(new actions.ApiApplicationFailureAction('$I18N_API_APPLICATIONS_LIST.NOTIFICATIONS.ERRORS.GENERAL'))));
        }));
        this.createApiApplicationV3Action$ = this.actions$.pipe(effects_1.ofType(actions.GatewayActionTypes.CreateGatewayApplication), operators_1.switchMap((action) => {
            return this.apiService.createApplication(action.payload).pipe(operators_1.map((response) => {
                if (!response) {
                    throw new Error();
                }
                if (this.featureFlagService.getBooleanFlag('Adcon.Services.Applications.2.0.client.secret.Regeneration')) {
                    return new actions.GetCredentialsSuccessOnCreateAction(response);
                }
                else {
                    return new actions.CreateGatewayApplicationSuccessAction(response);
                }
            }), operators_1.catchError(() => rxjs_1.of(new actions.ApiApplicationFailureAction('$I18N_API_APPLICATIONS_LIST.NOTIFICATIONS.ERRORS.GENERAL'))));
        }));
        this.createApiApplicationV3Success$ = this.actions$.pipe(effects_1.ofType(actions.GatewayActionTypes.CreateGatewayApplicationSuccess), operators_1.switchMap((action) => {
            return [
                new actions.GetAllApiApplicationsAction(),
                new actions.CloseGatewayApplicationAction(),
                new actions.OpenCreateApiApplicationCompletedAction(action.payload)
            ];
        }));
        this.updateApiApplicationV3Action$ = this.actions$.pipe(effects_1.ofType(actions.GatewayActionTypes.UpdateGatewayApplication), operators_1.switchMap((action) => {
            return this.apiService.updateApplication(action.payload).pipe(operators_1.map((response) => {
                if (!response) {
                    throw new Error();
                }
                return new actions.UpdateGatewayApplicationSuccessAction(response);
            }), operators_1.catchError(() => rxjs_1.of(new actions.ApiApplicationFailureAction('$I18N_API_APPLICATIONS_LIST.NOTIFICATIONS.ERRORS.GENERAL'))));
        }));
        this.updateApiApplicationV3Success$ = this.actions$.pipe(effects_1.ofType(actions.GatewayActionTypes.UpdateGatewayApplicationSuccess), operators_1.switchMap((action) => {
            return [
                new actions.GetAllApiApplicationsAction(),
                new actions.CloseSaveApiApplicationAction(),
                new actions.OpenEditApiApplicationCompletedAction(action.payload)
            ];
        }));
        this.closeApiApplicationV3$ = this.actions$.pipe(effects_1.ofType(actions.GatewayActionTypes.CloseGatewayApplication), operators_1.tap(() => {
            this.stateService.$state.go('api-applications-list');
        }));
    }
}
__decorate([
    effects_1.Effect()
], GatewayEffects.prototype, "getApiApplicationRoleListAction$", void 0);
__decorate([
    effects_1.Effect()
], GatewayEffects.prototype, "getApiApplicationRoleListActionWithHeader$", void 0);
__decorate([
    effects_1.Effect()
], GatewayEffects.prototype, "getApiApplicationProductListAction$", void 0);
__decorate([
    effects_1.Effect()
], GatewayEffects.prototype, "createApiApplicationV3Action$", void 0);
__decorate([
    effects_1.Effect()
], GatewayEffects.prototype, "createApiApplicationV3Success$", void 0);
__decorate([
    effects_1.Effect()
], GatewayEffects.prototype, "updateApiApplicationV3Action$", void 0);
__decorate([
    effects_1.Effect()
], GatewayEffects.prototype, "updateApiApplicationV3Success$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], GatewayEffects.prototype, "closeApiApplicationV3$", void 0);
exports.GatewayEffects = GatewayEffects;
