"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getIsAddAppDisabled = exports.getIsAddAppApiProcessing = exports.getAddedApp = exports.getSelectedApp = exports.reducer = exports.initialState = void 0;
const appsActions = require("../../actions/apps/apps.actions");
exports.initialState = {
    isLoading: false,
    isAddAppDisabled: false,
    selectedApp: null,
    addedApp: null,
    isAddAppApiProcessing: false,
    isDeleteAppApiProcessing: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case appsActions.VIEW_APP_DETAILS:
            return Object.assign(Object.assign({}, state), { selectedApp: action.payload.selectedApp, addedApp: null, isAddAppDisabled: false });
        case appsActions.CLOSE_APP:
            return Object.assign(Object.assign({}, state), { selectedApp: null });
        case appsActions.ADD_APP:
            return Object.assign(Object.assign({}, state), { isAddAppApiProcessing: true });
        case appsActions.ADD_APP_SUCCESS:
            return Object.assign(Object.assign({}, state), { isAddAppApiProcessing: false, isAddAppDisabled: true, addedApp: action.payload });
        case appsActions.ADD_APP_FAILURE:
            return Object.assign(Object.assign({}, state), { isAddAppApiProcessing: false });
        case appsActions.DELETE_APPS:
            return Object.assign(Object.assign({}, state), { isDeleteAppApiProcessing: true });
        case appsActions.DELETE_APPS_FAILURE:
        case appsActions.DELETE_APPS_SUCCESS:
            return Object.assign(Object.assign({}, state), { isDeleteAppApiProcessing: false, selectedApp: null });
        case appsActions.OPEN_DELETE_APP_MODAL:
            return Object.assign(Object.assign({}, state), { selectedApp: action.payload });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getSelectedApp = (state) => {
    return state.selectedApp;
};
exports.getSelectedApp = getSelectedApp;
const getAddedApp = (state) => {
    return state.addedApp;
};
exports.getAddedApp = getAddedApp;
const getIsAddAppApiProcessing = (state) => {
    return state.isAddAppApiProcessing;
};
exports.getIsAddAppApiProcessing = getIsAddAppApiProcessing;
const getIsAddAppDisabled = (state) => {
    return state.isAddAppDisabled;
};
exports.getIsAddAppDisabled = getIsAddAppDisabled;
