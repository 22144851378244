"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewPolicyComponent = void 0;
const core_1 = require("@angular/core");
class ViewPolicyComponent {
    constructor(fb) {
        this.fb = fb;
        this.loading = false;
        this.deleting = false;
        this.onLoadDetails = new core_1.EventEmitter();
        this.onEdit = new core_1.EventEmitter();
        this.onClose = new core_1.EventEmitter();
        this.onEnableDisable = new core_1.EventEmitter();
        this.showDelete = new core_1.EventEmitter();
        this.numberEnableRequests = 0;
    }
    ngOnInit() {
        this.switchForm = this.fb.group({ enabled: false });
        this.subscription = this.switchForm.get('enabled').valueChanges.subscribe((status) => {
            // the first 2 values of the subscription are from receiving the input value, so we ignore them
            if (this.numberEnableRequests >= 2) {
                this.enableDisable(status);
            }
            this.numberEnableRequests++;
        });
    }
    ngOnChanges(changes) {
        if (changes.policyDetails && changes.policyDetails.currentValue) {
            if (this.switchForm) {
                this.switchForm.patchValue({
                    enabled: this.policyDetails.enabled
                });
            }
        }
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
    close() {
        this.onClose.emit();
    }
    edit() {
        this.onEdit.emit(this.paginationInfo.currentEntity);
    }
    loadPolicyDetails(event) {
        const policy = event.token;
        this.numberEnableRequests = 0;
        this.onLoadDetails.emit(policy);
    }
    isEnabled() {
        return this.policyDetails && this.policyDetails.enabled;
    }
    enableDisable(status) {
        this.onEnableDisable.emit(status);
    }
}
exports.ViewPolicyComponent = ViewPolicyComponent;
