<div class="row mc-detailed-list-row">
  <div class="col-xs-4 mc-detailed-list-label">
    {{ '$I18N_API_INTEGRATION_CORTEX_SETTINGS.FIELDS.DESCRIPTION' | translate }}
  </div>
  <div class="mc-detailed-list-column">
    {{ description }}
  </div>
</div>

<div class="row mc-detailed-list-row">
  <div class="col-xs-4 mc-detailed-list-label">
    {{ '$I18N_API_INTEGRATION_CORTEX_SETTINGS.FIELDS.REGION' | translate }}
  </div>
  <div class="mc-detailed-list-column">
    {{ cortex?.region }}
  </div>
</div>

<div class="row mc-detailed-list-row">
  <div class="col-xs-4 mc-detailed-list-label">
    {{ '$I18N_API_INTEGRATION_CORTEX_SETTINGS.FIELDS.LSN' | translate }}
  </div>
  <div class="mc-detailed-list-column">
    {{ cortex?.lakeSerialNumber }}
  </div>
</div>

<div class="row mc-detailed-list-row">
  <div class="col-xs-4 mc-detailed-list-label">
    {{ '$I18N_API_INTEGRATION_CORTEX_SETTINGS.FIELDS.IDENTIFIER' | translate }}
  </div>
  <div class="mc-detailed-list-column">
    {{ getIntegrationIdentifier(cortex?.integrationName) }}
  </div>
</div>
  
<div class="row mc-detailed-list-row panel-padding-top">
  <div class="col-xs-4 mc-detailed-list-label">
    {{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.SUMMARY.SPYWARE' | translate }}
  </div>
  <div class="mc-detailed-list-column">{{ spywareSettings.enabled | mcCortexBoolean }}</div>
</div>

<div *ngIf="spywareSettings.enabled">
  <div class="row mc-detailed-list-row">
    <div class="col-xs-4 mc-detailed-list-label">
      {{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.SUMMARY.SEVERITY_LABEL' | translate }}
    </div>
    <div class="mc-detailed-list-column">{{ spywareSettings.fields.severity | mcCortexSeverity }}</div>
  </div>

  <div class="row mc-detailed-list-row">
    <div class="col-xs-4 mc-detailed-list-label">
      {{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.SUMMARY.ACTION_LABEL' | translate }}
    </div>
    <div class="mc-detailed-list-column">{{ spywareSettings.action | mcCortexAction }}</div>
  </div>

  <div *ngIf="spywareSettings.action === 'BLOCK_OUTBOUND' || spywareSettings.action === 'BLOCK_ALL'" class="row mc-detailed-list-row">
    <div class="col-xs-4 mc-detailed-list-label">
      {{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.SUMMARY.DISABLE_USER_LABEL' | translate }}
    </div>
    <div class="mc-detailed-list-column">{{ spywareSettings.disableUser | mcCortexBoolean }}</div>
  </div>
</div>

<div class="row mc-detailed-list-row panel-padding-top">
  <div class="col-xs-4 mc-detailed-list-label">
    {{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.SUMMARY.WILDFIRE' | translate }}
  </div>
  <div class="mc-detailed-list-column">{{ wildfireSettings.enabled | mcCortexBoolean }}</div>
</div>
<div *ngIf="wildfireSettings.enabled">
  <div class="row mc-detailed-list-row">
    <div class="col-xs-4 mc-detailed-list-label">
      {{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.SUMMARY.SEVERITY_LABEL' | translate }}
    </div>
    <div class="mc-detailed-list-column">{{ wildfireSettings.fields.severity | mcCortexSeverity }}</div>
  </div>

  <div class="row mc-detailed-list-row">
    <div class="col-xs-4 mc-detailed-list-label">
      {{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.SUMMARY.ACTION_LABEL' | translate }}
    </div>
    <div class="mc-detailed-list-column">{{ wildfireSettings.action | mcCortexAction }}</div>
  </div>

  <div *ngIf="wildfireSettings.action === 'BLOCK_OUTBOUND' || wildfireSettings.action === 'BLOCK_ALL'" class="row mc-detailed-list-row">
    <div class="col-xs-4 mc-detailed-list-label">
      {{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.SUMMARY.DISABLE_USER_LABEL' | translate }}
    </div>
    <div class="mc-detailed-list-column">{{ wildfireSettings.disableUser | mcCortexBoolean }}</div>
  </div>
</div>

<div class="row mc-detailed-list-row panel-padding-top">
    <div class="col-xs-4 mc-detailed-list-label">
      {{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.SUMMARY.NOTIFY_LABEL' | translate }}
    </div>

    <div class="mc-detailed-list-column">
      <div class="row mc-detailed-list-row" *ngFor="let group of objectKeys(cortexGroupsList)">
        {{ cortexGroupsList[group] | translate }}
      </div>

      <div class="row mc-detailed-list-row" *ngFor="let email of cortexEmailList">
        {{ email }}
      </div>
    </div>
</div>
