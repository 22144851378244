"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.notifications = void 0;
const actions = require("../../actions");
exports.notifications = {
    [actions.HELD_QUEUE_RELEASE_EMAILS_SUCCESS]: {
        message: '$I18N_MESSAGE_CENTER_HELD_QUEUE_NOTIFICATIONS.RELEASE_EMAILS_SUCCESS',
        type: 'success'
    },
    [actions.HELD_QUEUE_RELEASE_EMAILS_FAIL]: {
        message: '$I18N_MESSAGE_CENTER_HELD_QUEUE_NOTIFICATIONS.RELEASE_EMAILS_FAIL',
        type: 'error'
    },
    [actions.HELD_QUEUE_RELEASE_EMAILS_TO_SANDBOX_SUCCESS]: {
        message: '$I18N_MESSAGE_CENTER_HELD_QUEUE_NOTIFICATIONS.RELEASE_EMAILS_TO_SANDBOX_SUCCESS',
        type: 'success'
    },
    [actions.HELD_QUEUE_RELEASE_EMAILS_TO_SANDBOX_FAIL]: {
        message: '$I18N_MESSAGE_CENTER_HELD_QUEUE_NOTIFICATIONS.RELEASE_EMAILS_TO_SANDBOX_FAIL',
        type: 'error'
    },
    [actions.HELD_QUEUE_PERMIT_SENDERS_SUCCESS]: {
        message: '$I18N_MESSAGE_CENTER_HELD_QUEUE_NOTIFICATIONS.PERMIT_SENDERS_SUCCESS',
        type: 'success'
    },
    [actions.HELD_QUEUE_PERMIT_SENDERS_FAIL]: {
        message: '$I18N_MESSAGE_CENTER_HELD_QUEUE_NOTIFICATIONS.PERMIT_SENDERS_FAIL',
        type: 'error'
    },
    [actions.HELD_QUEUE_REJECT_EMAILS_SUCCESS]: {
        message: '$I18N_MESSAGE_CENTER_HELD_QUEUE_NOTIFICATIONS.REJECT_EMAILS_SUCCESS',
        type: 'success'
    },
    [actions.HELD_QUEUE_REJECT_EMAILS_FAIL]: {
        message: '$I18N_MESSAGE_CENTER_HELD_QUEUE_NOTIFICATIONS.REJECT_EMAILS_FAIL',
        type: 'error'
    },
    [actions.HELD_QUEUE_REJECT_EMAILS_WITH_NOTIFICATION_SUCCESS]: {
        message: '$I18N_MESSAGE_CENTER_HELD_QUEUE_NOTIFICATIONS.REJECT_EMAILS_WITH_NOTIFICATION_SUCCESS',
        type: 'success'
    },
    [actions.HELD_QUEUE_REJECT_EMAILS_WITH_NOTIFICATION_FAIL]: {
        message: '$I18N_MESSAGE_CENTER_HELD_QUEUE_NOTIFICATIONS.REJECT_EMAILS_WITH_NOTIFICATION_FAIL',
        type: 'error'
    },
    [actions.HELD_QUEUE_BLOCK_SENDERS_SUCCESS]: {
        message: '$I18N_MESSAGE_CENTER_HELD_QUEUE_NOTIFICATIONS.BLOCK_SENDERS_SUCCESS',
        type: 'success'
    },
    [actions.HELD_QUEUE_BLOCK_SENDERS_FAIL]: {
        message: '$I18N_MESSAGE_CENTER_HELD_QUEUE_NOTIFICATIONS.BLOCK_SENDERS_FAIL',
        type: 'error'
    },
    [actions.HELD_QUEUE_REPORT_EMAILS_SUCCESS]: {
        message: '$I18N_MESSAGE_CENTER_HELD_QUEUE_NOTIFICATIONS.REPORT_EMAILS_SUCCESS',
        type: 'success'
    },
    [actions.HELD_QUEUE_REPORT_EMAILS_FAIL]: {
        message: '$I18N_MESSAGE_CENTER_HELD_QUEUE_NOTIFICATIONS.REPORT_EMAILS_FAIL',
        type: 'error'
    },
    [actions.HELD_QUEUE_REJECT_EMAILS_WITH_NARRATIVE_WITH_NOTIFICATION_SUCCESS]: {
        message: '$I18N_MESSAGE_CENTER_HELD_QUEUE_NOTIFICATIONS.REJECT_EMAILS_WITH_NARRATIVE_WITH_NOTIFICATION_SUCCESS',
        type: 'success'
    },
    [actions.HELD_QUEUE_REJECT_EMAILS_WITH_NARRATIVE_FAIL]: {
        message: '$I18N_MESSAGE_CENTER_HELD_QUEUE_NOTIFICATIONS.REJECT_EMAILS_WITH_NARRATIVE_FAIL',
        type: 'error'
    }
};
