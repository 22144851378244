"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const static_1 = require("@angular/upgrade/static");
const list_component_1 = require("./list.component");
angular
    .module('sync-recover.onedrive.drive.list', [])
    .controller('OnedriveDrivesController', function () { })
    .directive('mcSyncRecoverOnedriveDriveList', static_1.downgradeComponent({
    component: list_component_1.ListComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider.state('onedrive-list', {
            url: '/sync-recover/onedrive/drives',
            data: {
                capabilities: 'Development.Only && Temporary.Services.OneDrive',
                isFixedLayout: false,
                checkProgress: false,
                tabId: 'sync-and-recover-one-drive',
                tabTitle: '$I18N_SYNC_AND_RECOVER_ONE_DRIVE.TAB_TITLE',
                breadcrumbs: [
                    '$I18N_SYNC_AND_RECOVER_ONE_DRIVE.TAB_TITLE',
                    '$18N_SYNC_RECOVER_ONE_DRIVE_LIST.TITLE'
                ],
                tabReload: false
            },
            views: {
                main: {
                    template: '<mc-sync-recover-onedrive-drive-list></mc-sync-recover-onedrive-drive-list>',
                    controller: 'OnedriveDrivesController'
                },
                aside: {
                    templateUrl: 'services/services/backup/aside/aside.tpl.html'
                }
            }
        });
    }
]);
