<mc-layout-list-table
  class="mc-security-report-container"
  keyTitle="$I18N_SECURITY_REPORT.PAGE_HEADER"
>
  <mc-help-container>
    <mc-help-link url="https://community.mimecast.com/docs/DOC-3222"></mc-help-link>
  </mc-help-container>
  <mc-table
    tableId="swg/malware-report-list-table"
    class="mc-swg-endpoints-list"
    [isLoading]="isLoading$ | async"
    [data]="tableData$ | async"
    [columns]="columns"
    translatePrefix="$I18N_SECURITY_REPORT.TABLE"
  >
    <button class="mc-table-actions btn btn-secondary mc-export-button" (click)="onExportClicked()">
      {{ '$I18N_SECURITY_REPORT.TABLE.ACTIONS.EXPORT' | translate }}
    </button>

    <mc-filters (paginatorChange)="onPaginationChange($event)" [metadata]="metaData$ | async">
      <mc-filter-search
        (search)="onSearch($event)"
        (clear)="onSearchClear()"
        [options]="filterSearchOptions"
      >
      </mc-filter-search>

      <mc-filters-bundle-date-range
        (filtersChange)="onDateRangeFilterChange($event)"
        [config]="dateRangePickerConfig"
      >
      </mc-filters-bundle-date-range>

      <mc-filters-bundle-column-values-sub-categories
        [sections]="searchFilters"
        (filtersChange)="onBundleFilterChange($event)"
      >
      </mc-filters-bundle-column-values-sub-categories>
    </mc-filters>

    <mc-column-date key="dateTime"></mc-column-date>

    <mc-column key="request">
      <mc-body-cell *mcBodyCellDef="let row">
        <span
          *ngIf="row.request"
          class="cursor-default"
          placement="top"
          tooltip="{{ row.request }}"
          container="body"
          containerClass="tooltip-breaker"
        >
          {{ row.request | translate }}
        </span>
      </mc-body-cell>
    </mc-column>

    <mc-column key="discoveryMethod">
      <mc-body-cell *mcBodyCellDef="let row">
        <span *ngIf="row.discoveryMethod">{{ row.discoveryMethod }}</span>
        <span *ngIf="!row.discoveryMethod">-</span>
      </mc-body-cell>
    </mc-column>

    <mc-column key="threat">
      <mc-body-cell *mcBodyCellDef="let row">
        {{
          '$I18N_SECURITY_REPORT.TABLE.FILTERS.COLUMN_VALUE_FILTER_FIELD.REASONTYPE_FILTERS.' +
            row.threat
            | uppercase
            | translate
        }}</mc-body-cell
      >
    </mc-column>

    <mc-column key="details">
      <mc-body-cell *mcBodyCellDef="let row">
        {{
          '$I18N_SECURITY_REPORT.TABLE.FILTERS.COLUMN_VALUE_FILTER_FIELD.CATEGORY_FILTERS.' +
            row.details
            | uppercase
            | translate
        }}
      </mc-body-cell>
    </mc-column>

    <mc-column-actions
      key="action"
      [columnsToStartHidden]="columnsToStartHidden"
      [columnsAlwaysVisible]="columnsAlwaysVisible"
      mcShowColumnConfig
    >
    </mc-column-actions>

    <mc-empty-results keyTitle="$I18N_SECURITY_REPORT.TABLE.NO_DATA"> </mc-empty-results>
  </mc-table>
</mc-layout-list-table>
