"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogsAsideComponent = void 0;
const core_1 = require("@angular/core");
const table_store_1 = require("@mimecast-ui/table-store");
const reducers = require("../../reducers/index");
const logsSidePanelActions = require("../../actions/logs-side-panel.actions");
const url_create_actions_1 = require("../../../managed-urls/actions/url-create.actions");
const reportURLActions = require("../../../actions/report-submit-modal.action");
const url_create_types_enum_1 = require("../../../managed-urls/common/enums/url-create-types.enum");
const url_override_types_enum_1 = require("../../../managed-urls/common/enums/url-override-types.enum");
class LogsAsideComponent extends table_store_1.TableRowDetailBaseComponent {
    constructor(store, logsStore, logsReportStore, capsService, windowService) {
        super(store, 'urlProtectionLogsList');
        this.logsStore = logsStore;
        this.logsReportStore = logsReportStore;
        this.capsService = capsService;
        this.windowService = windowService;
        this.canCheckUrl = false;
        this.isSafariBrowser = false;
        this.canCreateEditAndDeleteCapExpression = 'Permission.POLICIES_EDIT || Permission.SERVICES_TTP_URL_PROTECT_EDIT';
        this.canShowUrlReportingButton = 'Temporary.Services.URL.Reporting.Enabled';
        this.canCheckUrlExpression = 'Permission.SERVICES_TTP_URL_PROTECT_READ || Permission.SERVICES_TTP_URL_PROTECT_EDIT';
        this.closeAside = new core_1.EventEmitter();
    }
    ngOnInit() {
        this.isSafariBrowser = this.windowService.isSafari;
        this.scanDetails$ = this.logsStore.select(reducers.getCheckUrlsScanDetails);
        this.scanDetailsId$ = this.logsStore.select(reducers.getScanDetailsReportId);
        this.scanResponse$ = this.logsStore.select(reducers.getCheckUrlsScanResponse);
        this.reportButtonEnabled$ = this.logsStore.select(reducers.isReportModalButtonEnabled);
        this.categories$ = this.logsReportStore.select(reducers.getURLCategories);
        this.userProfile$ = this.logsReportStore.select(reducers.getUserProfileEmail);
        this.scanDetailsSubscription = this.scanDetails$.subscribe(scanDetails => {
            this.scanDetails = scanDetails;
        });
        this.scanDetailsIdSubscription = this.scanDetailsId$.subscribe(scanDetailsId => {
            this.scanDetailsId = scanDetailsId;
        });
        this.scanResponseSubscription = this.scanResponse$.subscribe(scanResponse => {
            this.scanResponse = scanResponse;
        });
        this.categorySubscription = this.categories$.subscribe(categories => {
            this.categories = categories;
        });
        this.userProfileSubscription = this.userProfile$.subscribe(userEmailAddress => {
            this.userEmailAddress = userEmailAddress;
        });
        this.rowSubscription = this.selectedRow$.subscribe(row => {
            this.selectedRow = row;
        });
        this.reportButtonEnabledSubscription = this.reportButtonEnabled$.subscribe(buttonEnabled => {
            this.reportButtonEnabled = buttonEnabled;
        });
        this.canCheckUrlSubscription = this.capsService
            .evalCapabilitiesExpression(this.canCheckUrlExpression)
            .subscribe(canCheckUrl => {
            if (canCheckUrl) {
                this.canCheckUrl = canCheckUrl;
            }
        });
    }
    showUrlReportingButton() {
        const ReportData = {
            id: this.scanDetailsId,
            scanDetails: this.scanDetails,
            categories: this.categories,
            userEmailAddress: this.userEmailAddress,
            scanResponses: this.scanResponse
        };
        this.store.dispatch(new reportURLActions.URLReportModalSubmitRetryEnabled(false));
        this.store.dispatch(new logsSidePanelActions.ShowUrlReportingModal(ReportData));
    }
    ngOnDestroy() {
        if (this.rowSubscription) {
            this.rowSubscription.unsubscribe();
        }
        if (this.reportButtonEnabledSubscription) {
            this.reportButtonEnabledSubscription.unsubscribe();
        }
        if (this.scanDetailsIdSubscription) {
            this.scanDetailsIdSubscription.unsubscribe();
        }
        if (this.categorySubscription) {
            this.categorySubscription.unsubscribe();
        }
        if (this.scanResponseSubscription) {
            this.scanResponseSubscription.unsubscribe();
        }
        if (this.userProfileSubscription) {
            this.userProfileSubscription.unsubscribe();
        }
        if (this.scanDetailsSubscription) {
            this.scanDetailsSubscription.unsubscribe();
        }
        if (this.canCheckUrlSubscription) {
            this.canCheckUrlSubscription.unsubscribe();
        }
    }
    allowUrl(urlLog) {
        const payload = {
            action: url_override_types_enum_1.UrlOverrideTypes.PERMIT,
            url: urlLog.url
        };
        this.store.dispatch(new url_create_actions_1.CreateManagedUrlAction([payload], url_create_types_enum_1.UrlCreateTypes.ALLOW));
    }
    blockUrl(urlLog) {
        const payload = {
            action: url_override_types_enum_1.UrlOverrideTypes.BLOCK,
            url: urlLog.url
        };
        this.store.dispatch(new url_create_actions_1.CreateManagedUrlAction([payload], url_create_types_enum_1.UrlCreateTypes.BLOCK));
    }
    onClose() {
        this.logsStore.dispatch(new logsSidePanelActions.CloseLogsSidePanelAction());
    }
}
exports.LogsAsideComponent = LogsAsideComponent;
