<label class="col-sm-3 control-label">
  <span>{{ label | translate }}</span>
  <ng-content></ng-content>
</label>

<div class="mc-container-select-field col-sm-9">
  <label>
    <select
      class="mc-native-select btn btn-secondary"
      [formControl]="field"
    >
      <option
        data-test="placeholder"
        *ngIf="!!placeholder"
        [ngValue]="null"
      >
        {{ placeholder | translate }}
      </option>
      <option
        data-test="options"
        *ngFor="let option of options"
        [ngValue]="option.value"
      >
        <ng-container *ngIf="useTranslation; else withoutTranslation">{{ option.label | translate }}</ng-container>
        <ng-template #withoutTranslation>{{ option.label }}</ng-template>
      </option>
    </select>
  </label>
  <em class="far fa-chevron-down mc-select-chevron"></em>
</div>
