"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SettingsPanelComponent = void 0;
const forms_1 = require("@angular/forms");
const _ = require("lodash");
const components_1 = require("@mimecast-ui/components");
const coreReducers = require("../../../core/reducers");
const settingsFormAction = require("../../actions/settings/settings-form.actions");
const reducers = require("../../reducers");
const select_notification_group_component_1 = require("../select-notification-group/select-notification-group.component");
const settings_panel_modal_component_1 = require("./modal/settings-panel-modal.component");
const asideConfig = new components_1.AsideConfig();
asideConfig.position = 'right';
asideConfig.size = 'md';
asideConfig.hasBackdrop = true;
asideConfig.resizable = false;
class SettingsPanelComponent {
    constructor(fb, asideService, modalService, tabChangeService, translate, swgService, coreStore, store) {
        this.fb = fb;
        this.asideService = asideService;
        this.modalService = modalService;
        this.tabChangeService = tabChangeService;
        this.translate = translate;
        this.swgService = swgService;
        this.coreStore = coreStore;
        this.store = store;
        this.isEditable = false;
        this.hasMsaEnabled = false;
        this.swgEndpointSettingsRead = false;
        this.swgEndpointSettingsEdit = false;
        this.subscriptions = [];
        this.options = [
            {
                value: 'not_set',
                label: '$I18N_REMEDIATION_SETTINGS_TAB.SELECT.SELECT_MODE'
            },
            {
                value: 'notify_only',
                label: '$I18N_REMEDIATION_SETTINGS_TAB.SELECT.NOTIFY_ONLY'
            },
            {
                value: 'automatic',
                label: '$I18N_REMEDIATION_SETTINGS_TAB.SELECT.AUTOMATIC'
            }
        ];
        this.selectedNotificationDefault = {
            description: '$I18N_REMEDIATION_SETTINGS_TAB.GROUPS.DEFAULT_MSG'
        };
        this.hasSelectedNotification = false;
        this.hasExcludedNotification = false;
        this.canSave = false;
        const iepRequiredValidator = this.isIEPCapabilityEnabled ? [forms_1.Validators.required] : [];
        const mepRequiredValidator = this.isMEPCapabilitySwitchEnabled ? [forms_1.Validators.required] : [];
        this.form = this.fb.group({
            enable: [false, iepRequiredValidator],
            notifyInternalRecipients: [false, iepRequiredValidator],
            mode: [null, iepRequiredValidator],
            notifyGroup: null,
            excludeGroup: null,
            enableFileRemediation: null,
            enableFileRemediationNotifications: null,
            mepEnable: [false, mepRequiredValidator],
            mepNotifyInternalRecipients: [false, mepRequiredValidator]
        });
    }
    ngOnInit() {
        this.settings$ = this.store.select(reducers.getSettings);
        this.deviceSettings$ = this.store.select(reducers.getDeviceSettings);
        this.isLoaded$ = this.store.select(reducers.areSettingsLoaded);
        this.isUpdating$ = this.store.select(reducers.isUpdatingSettings);
        this.hasMsaEnabled = this.swgService.hasMSAEnabled();
        this.swgEndpointSettingsRead = this.swgService.hasMSAReadEnabled();
        this.swgEndpointSettingsEdit = this.swgService.hasMSAEditEnabled();
        this.hasDeviceSettingsInDB$ = this.store.select(reducers.hasDeviceSettingsInDB);
        const userCapabilitiesSubscription = this.coreStore
            .select(coreReducers.getCapabilities)
            .subscribe((caps) => {
            const editPermissionCapability = 'Permission.THREAT_REMEDIATION_EDIT';
            this.isEditable = caps[editPermissionCapability] && caps[editPermissionCapability].enabled;
        });
        this.readOnlyMessage = {
            msg: this.translate.instant('$I18N_REMEDIATION_SETTINGS_TAB.NOTIFICATION.READ_ONLY'),
            status: 'info'
        };
        const isAdvancedSubscription = this.tabChangeService
            .hasSearchEnabled()
            .subscribe(searchPermisson => {
            this.isSearchEnabled = searchPermisson;
        });
        const settingsSubscription = this.settings$.subscribe(settings => {
            this.settingsFromStore = settings;
            this.setupForm(settings);
            this.form.markAsPristine();
            this.checkCanSave();
        });
        const deviceSettingsSubscription = this.deviceSettings$.subscribe(settings => {
            if (settings) {
                this.form.patchValue({
                    enableFileRemediation: settings.enableFileRemediation,
                    enableFileRemediationNotifications: settings.enableFileRemediationNotifications
                });
            }
        });
        this.settingsFormGroups$ = this.store.select(reducers.getSettingsFormGroups);
        const settingsFormSubscription = this.settingsFormGroups$.subscribe(groups => {
            this.hasSelectedNotification = !_.isEqual(groups.notifyGroup, this.selectedNotificationDefault);
            this.hasExcludedNotification = !_.isEqual(groups.excludeGroup, this.selectedNotificationDefault);
            this.form.get('notifyGroup').setValue(groups.notifyGroup);
            this.form.get('excludeGroup').setValue(groups.excludeGroup);
            if (this.settingsFromStore.notifyGroup) {
                if (!_.isEqual(groups.notifyGroup, this.settingsFromStore.notifyGroup)) {
                    this.form.markAsDirty();
                }
            }
            else if (this.hasSelectedNotification) {
                this.form.markAsDirty();
            }
            if (this.settingsFromStore.excludeGroup) {
                if (!_.isEqual(groups.excludeGroup, this.settingsFromStore.excludeGroup)) {
                    this.form.markAsDirty();
                }
            }
            else if (this.hasExcludedNotification) {
                this.form.markAsDirty();
            }
            this.checkCanSave();
        });
        const isUpdatingSubscription = this.isUpdating$.subscribe(isUpdating => {
            if (!isUpdating) {
                this.form.markAsPristine();
                this.checkCanSave();
            }
        });
        this.subscriptions.push(userCapabilitiesSubscription);
        this.subscriptions.push(settingsSubscription);
        this.subscriptions.push(deviceSettingsSubscription);
        this.subscriptions.push(settingsFormSubscription);
        this.subscriptions.push(isUpdatingSubscription);
        this.subscriptions.push(isAdvancedSubscription);
        const formValueSubscription = this.form.valueChanges.subscribe(settings => {
            if (!settings.enable &&
                (settings.enableFileRemediation || settings.enableFileRemediationNotifications)) {
                this.form.patchValue({
                    enableFileRemediation: false,
                    enableFileRemediationNotifications: false
                });
            }
            this.checkCanSave();
        });
        this.subscriptions.push(formValueSubscription);
        this.tabChangeService.setBlockTabs(false);
        this.registerTabChange();
    }
    setupForm(settings) {
        this.form.patchValue({
            enable: settings.enabled,
            mode: settings.mode,
            notifyInternalRecipients: settings.notifyInternalRecipients,
            mepEnable: settings.mepEnabled,
            mepNotifyInternalRecipients: settings.mepNotifyInternalRecipients
        });
        this.store.dispatch(
        // @ts-ignore
        new settingsFormAction.RemediationSettingsSetExcludeAndNotifyGroup([
            settings.notifyGroup,
            settings.excludeGroup
        ]));
    }
    registerTabChange() {
        this.tabChangeService.createNewTabsChangeSubject();
        this.tabChangeService.tabsChanged.subscribe((value) => {
            if ((value && !this.isSearchEnabled && value.current !== 3 && value.previous === 3) ||
                (value && this.isSearchEnabled && value.current !== 4 && value.previous === 4)) {
                if (this.form.dirty) {
                    this.openCancelModal();
                }
                this.tabHistory = value;
            }
        });
    }
    onOpenGroupSelection(type) {
        asideConfig.data = { type };
        const aside = this.asideService.open(select_notification_group_component_1.SelectNotificationGroupComponent, asideConfig);
        aside.beforeClose().subscribe(results => {
            if (results) {
                if (type === 'notify') {
                    this.store.dispatch(new settingsFormAction.RemediationSettingsSetNotifyGroup(results));
                }
                else {
                    this.store.dispatch(new settingsFormAction.RemediationSettingsSetExcludeGroup(results));
                }
            }
        });
    }
    onSelectChange(selectedMode) {
        this.form.markAsDirty();
        this.form.get('mode').setValue(selectedMode);
        if (selectedMode === 'notify_only') {
            this.form.get('notifyInternalRecipients').setValue(false);
        }
        this.checkCanSave();
    }
    deleteGroup(type) {
        if (type === 'notify') {
            this.store.dispatch(new settingsFormAction.RemediationSettingsSetNotifyGroup(this.selectedNotificationDefault));
        }
        else {
            this.store.dispatch(new settingsFormAction.RemediationSettingsSetExcludeGroup(this.selectedNotificationDefault));
        }
    }
    onSave() {
        this.form.markAsPristine();
        this.checkCanSave();
    }
    onCancel() {
        this.setupForm(this.settingsFromStore);
        if (this.isIEPCapabilityEnabled) {
            this.mcSelect.field.setValue(this.settingsFromStore.mode);
        }
        this.form.markAsPristine();
        this.checkCanSave();
    }
    openSaveModal() {
        const modalConfig = {};
        modalConfig.size = 'sm';
        modalConfig.hasBackdrop = true;
        modalConfig.data = this.form.value;
        const modal = this.modalService.open(settings_panel_modal_component_1.SettingsPanelSaveModalComponent, modalConfig);
        modal.afterClose().subscribe(result => {
            if (result === 'save') {
                this.onSave();
            }
        });
    }
    openCancelModal() {
        const modalConfig = {};
        modalConfig.size = 'sm';
        modalConfig.hasBackdrop = true;
        const modal = this.modalService.open(settings_panel_modal_component_1.SettingsPanelCancelModalComponent, modalConfig);
        modal.afterClose().subscribe(result => {
            this.registerTabChange();
            if (result === 'yes') {
                this.onCancel();
                setTimeout(() => {
                    this.tabNavigation(this.tabHistory.current);
                }, 0);
            }
        });
    }
    checkCanSave() {
        this.canSave = this.isIEPCapabilityEnabled
            ? this.form.dirty && this.hasSelectedNotification && this.form.get('mode').value !== 'not_set'
            : this.form.dirty;
        this.tabChangeService.setBlockTabs(this.form.dirty);
    }
    tabNavigation(tabIndex) {
        this.tabChangeService.setActiveTab(tabIndex);
    }
    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    getModeName(form) {
        return this.options.find(item => item.value === form.value);
    }
}
exports.SettingsPanelComponent = SettingsPanelComponent;
