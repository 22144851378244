"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectorsEffect = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const notification_actions_1 = require("app-new/components/notification/actions/notification.actions");
const connector_wizard_service_1 = require("../services/connector-wizard.service");
const connector_actions_1 = require("../actions/connector.actions");
const wizard_actions_1 = require("../actions/wizard.actions");
const model_1 = require("../model");
const connector_details_component_1 = require("../components/connector-details/connector-details.component");
const gws_provider_static_values_1 = require("../components/wizards/create-connector/provider-steps/gws-provider-steps/gws-provider-static-values");
const create_connector_modal_component_1 = require("../components/create-connector-modal/create-connector-modal.component");
class ConnectorsEffect {
    constructor(actions$, stateService, translateService, apiService, notificationService, asideService, store, modalService, windowService) {
        this.actions$ = actions$;
        this.stateService = stateService;
        this.translateService = translateService;
        this.apiService = apiService;
        this.notificationService = notificationService;
        this.asideService = asideService;
        this.store = store;
        this.modalService = modalService;
        this.windowService = windowService;
        this.openCreateConnectorWizard$ = this.actions$.pipe(effects_1.ofType(connector_actions_1.ConnectorActions.OPEN_CREATE_CONNECTOR_WIZARD), operators_1.tap(() => this.stateService.$state.go('services-create-connector-page')), operators_1.switchMap(() => rxjs_1.EMPTY));
        this.getProducts$ = this.actions$.pipe(effects_1.ofType(connector_actions_1.ConnectorActions.LOAD_PRODUCTS), operators_1.switchMap(() => {
            return this.apiService.getProducts().pipe(operators_1.map((response) => {
                if (response.hasErrors) {
                    return new connector_actions_1.LoadProductsActionFail();
                }
                return new connector_actions_1.LoadProductsActionSuccess(response.first.products);
            }), operators_1.catchError(() => {
                return rxjs_1.of(new connector_actions_1.LoadProductsActionFail());
            }));
        }));
        this.getProviders$ = this.actions$.pipe(effects_1.ofType(connector_actions_1.ConnectorActions.LOAD_PROVIDERS), operators_1.switchMap((action) => {
            return this.apiService.getProviders(action.payload).pipe(operators_1.map((response) => {
                if (response.hasErrors) {
                    return new connector_actions_1.LoadProvidersActionFail();
                }
                return new connector_actions_1.LoadProvidersActionSuccess(response.first.providers);
            }), operators_1.catchError(() => {
                return rxjs_1.of(new connector_actions_1.LoadProvidersActionFail());
            }));
        }));
        this.getConsentConfig$ = this.actions$.pipe(effects_1.ofType(connector_actions_1.ConnectorActions.GET_CONSENT_CONFIG), operators_1.switchMap((action) => {
            return this.apiService.getConsentConfig(action.payload).pipe(operators_1.map((response) => {
                if (response.hasErrors) {
                    this.notificationService.error({
                        msg: this.getNotificationMessage('ERR_CONSENT_REQUEST'),
                        duration: 0
                    });
                    return new connector_actions_1.GetConsentConfigActionFail();
                }
                return new connector_actions_1.GetConsentConfigActionSuccess(response.first);
            }), operators_1.catchError(() => {
                return rxjs_1.of(new connector_actions_1.GetConsentConfigActionFail());
            }));
        }));
        this.create$ = this.actions$.pipe(effects_1.ofType(connector_actions_1.ConnectorActions.CREATE_CONNECTOR), operators_1.switchMap((action) => {
            return this.apiService.createConnector(action.payload).pipe(operators_1.map(response => {
                if (response.hasErrors) {
                    return new connector_actions_1.CreateConnectorActionFail({
                        message: this.getNotificationMessage('CREATE_FAILURE', {
                            provider: this.getProvider(action.payload.provider)
                        })
                    });
                }
                return new connector_actions_1.CreateConnectorActionSuccess(action.payload.provider, this.getNotificationMessage('CREATE_SUCCESS'), response.first, undefined, action.isMigrationFlow);
            }), operators_1.catchError(() => {
                return rxjs_1.of(new connector_actions_1.CreateConnectorActionFail({
                    message: this.getNotificationMessage('CREATE_FAILURE', {
                        provider: this.getProvider(action.payload.provider)
                    })
                }));
            }));
        }));
        this.createSuccess$ = this.actions$.pipe(effects_1.ofType(connector_actions_1.ConnectorActions.CREATE_CONNECTOR_SUCCESS), operators_1.switchMap((action) => {
            if (action.provider === 'azure_standard' && !action.isEditFlow && !action.isMigrationFlow) {
                this.stateService.$state.go('services-connectors-page', {
                    callerWizardId: connector_wizard_service_1.ConnectorWizardService.WizardId,
                    tab: 'cloud'
                });
                return [
                    new notification_actions_1.NotificationShowSuccessAction(action.payload),
                    new connector_actions_1.ShowCreateConnectorSuccessModalAction()
                ];
            }
            if (action.isMigrationFlow) {
                this.stateService.$state.go('services-connectors-page', {
                    callerWizardId: connector_wizard_service_1.ConnectorWizardService.WizardId,
                    tab: 'migrate'
                });
                return [new notification_actions_1.NotificationShowSuccessAction(action.payload)];
            }
            else {
                this.stateService.$state.go('services-connectors-page', {
                    callerWizardId: connector_wizard_service_1.ConnectorWizardService.WizardId,
                    tab: 'cloud'
                });
                return this.getSuccessNotification(action);
            }
        }));
        this.createFail$ = this.actions$.pipe(effects_1.ofType(connector_actions_1.ConnectorActions.CREATE_CONNECTOR_FAIL), operators_1.tap((action) => {
            this.notificationService.error({
                msg: action.payload.message,
                duration: 0
            });
            this.stateService.$state.go('services-connectors-page', {
                callerWizardId: connector_wizard_service_1.ConnectorWizardService.WizardId,
                tab: 'cloud'
            });
        }));
        this.createSuccessModal$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(connector_actions_1.ConnectorActions.SHOW_CREATE_CONNECTOR_SUCCESS_MODAL), operators_1.tap(() => {
                this.createConnectorSuccessModal = this.modalService.open(create_connector_modal_component_1.CreateConnectorModalComponent, {
                    size: 'lg',
                    hasBackdrop: true,
                    disableClose: true
                });
            }));
        }, { dispatch: false });
        this.update$ = this.actions$.pipe(effects_1.ofType(connector_actions_1.ConnectorActions.UPDATE_CONNECTOR), operators_1.switchMap((action) => {
            return this.apiService.updateConnector(action.payload).pipe(operators_1.map(response => {
                if (response.hasErrors &&
                    response.firstFail.errors[0] &&
                    response.firstFail.errors[0].code !== 'err_update_connector_no_changes') {
                    return new connector_actions_1.CreateConnectorActionFail({
                        message: this.getNotificationMessage('UPDATE_FAILURE')
                    });
                }
                return new connector_actions_1.CreateConnectorActionSuccess(action.payload.provider, this.getNotificationMessage('UPDATE_SUCCESS'), response.first, true);
            }), operators_1.catchError(() => {
                return rxjs_1.EMPTY;
            }));
        }));
        this.delete$ = this.actions$.pipe(effects_1.ofType(connector_actions_1.ConnectorActions.DELETE_CONNECTOR), operators_1.switchMap((action) => {
            return this.apiService.deleteConnector(action.payload).pipe(operators_1.map(response => {
                if (response.hasErrors) {
                    return new connector_actions_1.DeleteConnectorActionFail({
                        message: this.getNotificationMessage('DELETE_FAILURE'),
                        failure: response.fail[0]
                    });
                }
                if (this.connectorNotExists(response)) {
                    return new connector_actions_1.DeleteConnectorActionFail({
                        message: this.getNotificationMessage('ERR_DELETE_CONNECTOR_NOT_FOUND')
                    });
                }
                return new connector_actions_1.DeleteConnectorActionSuccess(this.getNotificationMessage('DELETE_SUCCESS'));
            }), operators_1.catchError(response => {
                return rxjs_1.of(new connector_actions_1.DeleteConnectorActionFail({
                    message: this.getNotificationMessage('DELETE_FAILURE'),
                    failure: response.fail[0]
                }));
            }));
        }));
        this.usageCheck$ = this.actions$.pipe(effects_1.ofType(connector_actions_1.ConnectorActions.GET_CONNECTOR_USAGE), operators_1.switchMap((action) => {
            return this.apiService.getConnectorUsage(action.id).pipe(operators_1.map(response => {
                if (response.hasErrors) {
                    return new connector_actions_1.GetConnectorUsageFailAction();
                }
                return new connector_actions_1.GetConnectorUsageSuccessAction(response.first);
            }), operators_1.catchError(() => {
                return rxjs_1.of(new connector_actions_1.GetConnectorUsageFailAction());
            }));
        }));
        this.deleteSuccess$ = this.actions$.pipe(effects_1.ofType(connector_actions_1.ConnectorActions.DELETE_CONNECTOR_SUCCESS), operators_1.switchMap((action) => rxjs_1.of(new notification_actions_1.NotificationShowSuccessAction(action.payload))));
        this.deleteFail$ = this.actions$.pipe(effects_1.ofType(connector_actions_1.ConnectorActions.DELETE_CONNECTOR_FAIL), operators_1.tap((action) => {
            if (!action.payload.failure || !action.payload.failure.errors) {
                this.notificationService.error({
                    msg: action.payload.message,
                    duration: 0
                });
            }
            return action.payload.failure.errors.forEach(error => {
                this.notificationService.error({
                    msg: this.translateService.instant(this.getErrorMessage(error)),
                    duration: 0
                });
            });
        }));
        this.productSelectionUpdated$ = this.actions$.pipe(effects_1.ofType(connector_actions_1.ConnectorActions.PRODUCT_SELECTION_UPDATED), operators_1.switchMap((action) => {
            const { wizardId, hasConsented } = action.payload;
            const stepIds = [
                model_1.ConnectorStep.PROVIDER,
                ...this.insertIf(hasConsented, model_1.ConnectorStep.CONSENT, model_1.ConnectorStep.DETAILS)
            ];
            return [
                new connector_actions_1.ResetStepFormsAction({ stepIds }),
                new wizard_actions_1.ClearWizardStepsAction({ wizardId, stepIds })
            ];
        }));
        this.providerSelectionUpdated$ = this.actions$.pipe(effects_1.ofType(connector_actions_1.ConnectorActions.PROVIDER_SELECTION_UPDATED), operators_1.switchMap((action) => {
            const { wizardId } = action.payload;
            const stepIds = [model_1.ConnectorStep.CONSENT, model_1.ConnectorStep.DETAILS];
            return [
                new connector_actions_1.ResetStepFormsAction({ stepIds }),
                new wizard_actions_1.ClearWizardStepsAction({ wizardId, stepIds })
            ];
        }));
        this.openAside$ = this.actions$.pipe(effects_1.ofType(connector_actions_1.ConnectorActions.GET_CONNECTOR_DETAILS), operators_1.switchMap((action) => {
            return this.apiService.getConnectorDetails(action.payload).pipe(operators_1.map(response => {
                if (response.hasErrors) {
                    return new connector_actions_1.GetConnectorDetailsFail();
                }
                if (!this.asideInstance) {
                    this.asideInstance = this.asideService.open(connector_details_component_1.ConnectorDetailsComponent, {
                        position: 'right',
                        size: 'md',
                        hasBackdrop: true,
                        minWidth: 620,
                        resizable: true
                    });
                    this.asideInstance.afterClose().subscribe(() => {
                        this.asideInstance = undefined;
                        this.store.dispatch(new connector_actions_1.UnsetSelectedRow());
                    });
                }
                return new connector_actions_1.GetConnectorDetailsSuccess(response.first);
            }), operators_1.catchError(() => {
                return rxjs_1.of(new connector_actions_1.GetConnectorDetailsFail());
            }));
        }));
        this.validateConsent$ = this.actions$.pipe(effects_1.ofType(connector_actions_1.ConnectorActions.VALIDATE_CONSENT), operators_1.switchMap((action) => {
            return this.apiService.validateConsent(action.payload).pipe(operators_1.map((response) => {
                if (response.hasErrors) {
                    const code = response.firstFail.errors[0].code;
                    return new connector_actions_1.ValidateConsentActionFail({ errorCode: code });
                }
                return new connector_actions_1.ValidateConsentActionSuccess({ authToken: response.first.authToken });
            }), operators_1.catchError(() => {
                return rxjs_1.of(new connector_actions_1.ValidateConsentActionFail({ errorCode: gws_provider_static_values_1.API_FAILURE_CODE }));
            }));
        }));
    }
    getSuccessNotification(action) {
        const connector = action.connector;
        if ((action.isEditFlow === undefined || !action.isEditFlow) &&
            connector &&
            connector.product &&
            connector.product.description &&
            'OUTLOOK_END_USER_REPORTING' === connector.product.description.toUpperCase()) {
            const htmlMessage = `${action.payload}<br/>${this.translateService.instant('$I18N_CONNECTORS.NOTIFICATION.TRR_NAVIGATE_MESSAGE')}`;
            return [
                new notification_actions_1.NotificationShowAction({
                    type: 'success',
                    config: {
                        msg: htmlMessage,
                        duration: 30000,
                        actions: [
                            {
                                isAutoClose: true,
                                label: this.translateService.instant('$I18N_CONNECTORS_TABLE.ACTIONS.TRR_NAVIGATION'),
                                type: 'button',
                                callback: () => {
                                    this.windowService.replaceParentLocation('/admin/services/threat-reporting');
                                }
                            }
                        ]
                    }
                })
            ];
        }
        else {
            return [new notification_actions_1.NotificationShowSuccessAction(action.payload)];
        }
    }
    connectorNotExists(response) {
        return response.first && !response.first.success;
    }
    getNotificationMessage(notificationkey, dynamicParams) {
        const fullKey = `$I18N_CONNECTORS.NOTIFICATION.${notificationkey}`;
        return this.translateService.instant(fullKey, dynamicParams);
    }
    getErrorMessage(error) {
        return `$I18N_CONNECTORS.NOTIFICATION.${this.prepareErrorCode(error).toUpperCase()}`;
    }
    prepareErrorCode(error) {
        if (error.field) {
            switch (error.code.toLowerCase()) {
                case 'err_validation_null':
                case 'err_validation_blank':
                    switch (error.field.toLowerCase()) {
                        case 'name':
                        case 'description':
                        case 'application':
                            return `err_validation_blank_${error.field}`;
                    }
            }
        }
        return error.code;
    }
    getProvider(providerKey) {
        return this.translateService.instant(`$I18N_CONNECTORS.PROVIDERS.${providerKey.toUpperCase()}`);
    }
    insertIf(condition, ...elements) {
        return condition ? elements : [];
    }
}
__decorate([
    effects_1.Effect()
], ConnectorsEffect.prototype, "openCreateConnectorWizard$", void 0);
__decorate([
    effects_1.Effect()
], ConnectorsEffect.prototype, "getProducts$", void 0);
__decorate([
    effects_1.Effect()
], ConnectorsEffect.prototype, "getProviders$", void 0);
__decorate([
    effects_1.Effect()
], ConnectorsEffect.prototype, "getConsentConfig$", void 0);
__decorate([
    effects_1.Effect()
], ConnectorsEffect.prototype, "create$", void 0);
__decorate([
    effects_1.Effect()
], ConnectorsEffect.prototype, "createSuccess$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], ConnectorsEffect.prototype, "createFail$", void 0);
__decorate([
    effects_1.Effect()
], ConnectorsEffect.prototype, "update$", void 0);
__decorate([
    effects_1.Effect()
], ConnectorsEffect.prototype, "delete$", void 0);
__decorate([
    effects_1.Effect()
], ConnectorsEffect.prototype, "usageCheck$", void 0);
__decorate([
    effects_1.Effect()
], ConnectorsEffect.prototype, "deleteSuccess$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], ConnectorsEffect.prototype, "deleteFail$", void 0);
__decorate([
    effects_1.Effect()
], ConnectorsEffect.prototype, "productSelectionUpdated$", void 0);
__decorate([
    effects_1.Effect()
], ConnectorsEffect.prototype, "providerSelectionUpdated$", void 0);
__decorate([
    effects_1.Effect()
], ConnectorsEffect.prototype, "openAside$", void 0);
__decorate([
    effects_1.Effect()
], ConnectorsEffect.prototype, "validateConsent$", void 0);
exports.ConnectorsEffect = ConnectorsEffect;
