"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.searchByColumnsApiGateway2_0 = exports.searchByColumnsLegacy = exports.columnsStartHidden = exports.columnsAlwaysVisible = exports.apiGatewayV2Columns = exports.apiLegacyColumns = void 0;
exports.apiLegacyColumns = [
    'applicationName',
    'appId',
    'active',
    'service',
    'category',
    'developerEmail',
    'developerName',
    'action'
];
exports.apiGatewayV2Columns = [
    'applicationName',
    'category',
    'developerEmail',
    'developerName',
    'action'
];
exports.columnsAlwaysVisible = ['applicationName'];
exports.columnsStartHidden = ['category', 'service', 'developerEmail'];
exports.searchByColumnsLegacy = {
    field: 'search',
    header: 'header',
    options: ['applicationName', 'appId', 'developerName']
};
exports.searchByColumnsApiGateway2_0 = {
    field: 'search',
    header: 'header',
    options: ['applicationName', 'developerName']
};
