"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgEndpointsInstallComponent = void 0;
const core_1 = require("@angular/core");
const constants_1 = require("../../constants");
class SwgEndpointsInstallComponent {
    constructor(notificationService, translateService) {
        this.notificationService = notificationService;
        this.translateService = translateService;
        this.getAuthKey = new core_1.EventEmitter();
        this.downloadAuthKey = new core_1.EventEmitter();
        this.downloadBinary = new core_1.EventEmitter();
        this.openDeleteKeyModal = new core_1.EventEmitter();
        this.copyAgentAuthKey = new core_1.EventEmitter();
        this.authKeyKbLink = constants_1.ENDOINT_AUTH_KEY_KB_LINK;
        this.endpointInstallKbLink = constants_1.ENDOINT_INSTALL_KB_LINK;
        this.hasRequiredRole = false;
        this.endpointReleaseNotes = constants_1.ENDPOINT_INSTALL_RELEASE_NOTES;
        this.deleteKeyRoles = [
            'Super Administrator',
            'Sys Admin - Product Management Basic',
            'Sys Admin - SD Basic',
            'Sys Admin - SD Full',
            'Partner Administrator',
            'Full Administrator'
        ];
    }
    set authKey(authKey) {
        this.hasAuthKey = !!authKey && authKey.authToken !== '';
        this.authToken = this.hasAuthKey ? authKey.authToken : '';
    }
    ngOnInit() {
        this.readOnlyMessage = {
            msg: this.translateService.instant('$I18N_ENDPOINT_SETTINGS.NOTIFICATION.READ_ONLY'),
            status: 'info'
        };
        this.hasRequiredRole = this.deleteKeyRoles.indexOf(this.userRole) >= 0;
    }
    onGetAuthKey() {
        this.getAuthKey.emit(true);
    }
    copyToClipboardSuccess() {
        this.copyAgentAuthKey.emit();
        this.notificationService.success({
            msg: this.translateService.instant('$I18N_ENDPOINT_SETTINGS.NOTIFICATION.SUCCESS.COPY_TO_CLIPBOARD')
        });
    }
    onDownloadAuthKey() {
        this.downloadAuthKey.emit();
    }
    onOpenDeleteKeyModal() {
        this.openDeleteKeyModal.emit();
    }
    onDownloadBinary(platformCode) {
        this.downloadBinary.emit(platformCode);
    }
}
exports.SwgEndpointsInstallComponent = SwgEndpointsInstallComponent;
