<mc-layout-aside-extra-container
  keyTitle="{{ '$I18N_API_OKTA_INTEGRATION_ASIDE_VIEW.TITLE' | translate }}"
  [isLoading]="false"
  showClose="true"
  (closeAside)="onCloseClicked.emit()"
>
  <mc-extra-container *mcCapabilities="'Permission.API_APPLICATIONS_EDIT'">
    <mc-edit-integration-view
      [integration]="integration"
      [enabled]="enabled"
      (onEditClicked)="onEditClicked.emit()"
      (onDeleteClicked)="onDeleteClicked.emit()"
      (onEnableClicked)="onEnableClicked.emit()"
      (onDisableClicked)="onDisableClicked.emit()"
    >
    </mc-edit-integration-view>
  </mc-extra-container>

  <mc-body-container>
    <section class="mc-detail-section">
      <div class="panel-body no-padding-top">
        <div class="mc-detailed-list">
          <ng-container *mcCapabilities="'Temporary.Services.ApiApplications.Okta.Integration'">
            <div class="row mc-detailed-list-row" *ngIf="enabled && !integration.activity">
              <div class="col-xs-4 mc-detailed-list-label">
                {{ '$I18N_API_OKTA_INTEGRATION_ASIDE_VIEW.FIELDS.STATUS' | translate }}
              </div>
              <div class="mc-detailed-list-column">
                <span class="fas fa-exclamation-triangle text-warning"></span>
                {{ '$I18N_API_OKTA_INTEGRATION_ASIDE_VIEW.AWAITING_STATUS' | translate }}
              </div>
            </div>
          </ng-container>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_OKTA_INTEGRATION_ASIDE_VIEW.FIELDS.INTEGRATION' | translate }}
            </div>
            <div class="mc-detailed-list-column">
              {{ integration.integrationType | mcIntegrationProvider }}
            </div>
          </div>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_OKTA_INTEGRATION_ASIDE_VIEW.FIELDS.IDENTIFIER' | translate }}
            </div>
            <div class="mc-detailed-list-column">
              {{ oktaIntegration.name }}
            </div>
          </div>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_OKTA_INTEGRATION_ASIDE_VIEW.FIELDS.DESCRIPTION' | translate }}
            </div>
            <div class="mc-detailed-list-column">
              {{ oktaIntegration.description }}
            </div>
          </div>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_OKTA_INTEGRATION_ASIDE_VIEW.FIELDS.DIRECTORY_LOCATION' | translate }}
            </div>
            <div class="mc-detailed-list-column">
              {{ description }}
            </div>
          </div>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_OKTA_INTEGRATION_ASIDE_VIEW.FIELDS.OKTA_CELL' | translate }}
            </div>
            <div class="mc-detailed-list-column">
              {{ oktaIntegration?.cell | mcOktaCellProvider }}
            </div>
          </div>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_OKTA_INTEGRATION_ASIDE_VIEW.FIELDS.CREATED' | translate }}
            </div>
            <div class="mc-detailed-list-column">{{ integration.created | mcDatePlusWeekday }}</div>
          </div>

          <div class="row mc-detailed-list-row" *mcCapabilities="'Temporary.Services.ApiApplications.Okta.Integration'">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_OKTA_INTEGRATION_ASIDE_VIEW.FIELDS.ACTIVITY' | translate }}
            </div>

            <div class="mc-detailed-list-column">{{ (integration.activity | mcDatePlusWeekday) || '-' }}</div>
          </div>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_OKTA_INTEGRATION_ASIDE_VIEW.FIELDS.UPDATED' | translate }}
            </div>
            <div class="mc-detailed-list-column">{{ integration.updated | mcDatePlusWeekday }}</div>
          </div>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_OKTA_INTEGRATION_ASIDE_VIEW.FIELDS.RECIPIENTS' | translate }}
            </div>

            <div class="mc-detailed-list-column">
              <div class="mc-detailed-list-row mc-okta-email-list" *ngFor="let email of oktaEmailList">
                {{ email }}
              </div>
            </div>
          </div>

          <ng-container *mcCapabilities="'Permission.API_APPLICATIONS_EDIT'">
            <div class="row mc-detailed-list-row" *ngIf="enabled">
              <div class="col-xs-4 mc-detailed-list-label mc-api-token-label">
                {{ '$I18N_API_OKTA_INTEGRATION_ASIDE_VIEW.FIELDS.TOKEN' | translate }}
              </div>
              <div class="mc-detailed-list-column">
                <button class="btn btn-secondary" (click)="onRotateTokenClicked.emit()">
                  {{ '$I18N_API_OKTA_INTEGRATION_ASIDE_VIEW.ROTATE_TOKEN_BUTTON_LABEL' | translate }}
                </button>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </section>
  </mc-body-container>
</mc-layout-aside-extra-container>
