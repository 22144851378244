"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const accepted_email_page_component_1 = require("./containers/accepted-email-page.component");
angular
    .module('accepted-email', [])
    .controller('AcceptedEmailController', [
    '$stateParams',
    '$scope',
    function ($stateParams, $scope) {
        $scope.stateParams = $stateParams;
    }
])
    .directive('mcAcceptedEmail', static_1.downgradeComponent({
    component: accepted_email_page_component_1.AcceptedEmailPageComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider.state('accepted-email', {
            url: '/message-center/accepted-email',
            data: {
                isFixedLayout: false,
                checkProgress: false,
                capabilities: 'Temporary.Services.MessageCenter.AcceptedEmail && Permission.ACCEPTED_EMAIL_READ',
                breadcrumbs: [
                    {
                        label: '$I18N_MESSAGE_CENTER_BREADCRUMBS_FIRST_LEVEL',
                        stateName: '',
                        stateParams: { tabName: 'overview' }
                    },
                    {
                        label: '$I18N_MESSAGE_CENTER_ACCEPTED_EMAIL_BREADCRUMBS_SECOND_LEVEL',
                        stateName: '',
                        stateParams: { tabName: 'overview' }
                    }
                ]
            },
            views: {
                main: {
                    template: '<mc-accepted-email></mc-accepted-email>',
                    controller: 'AcceptedEmailController'
                }
            }
        });
    }
]);
