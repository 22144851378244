"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CyberGraphPolicyAsideComponent = void 0;
const core_1 = require("@angular/core");
const table_store_1 = require("@mimecast-ui/table-store");
const listActions = require("../../actions/cybergraph-policies-list.actions");
const reducer = require("../../reducers/index");
const actions = require("../../actions/cybergraph-policies-list.actions");
const editActions = require("../../actions/cybergraph-policies-edit.actions");
class CyberGraphPolicyAsideComponent extends table_store_1.TableRowDetailBaseComponent {
    constructor(store, asideFormBuilder, detailsStore) {
        super(store, 'cyberGraphPoliciesListTable');
        this.asideFormBuilder = asideFormBuilder;
        this.detailsStore = detailsStore;
        this.closeAside = new core_1.EventEmitter();
        this.detailsCyberGraphPolicyForm = this.asideFormBuilder.group({
            enableDisablePolicy: [true]
        });
    }
    ngOnInit() {
        this.startSubsciptions();
    }
    startSubsciptions() {
        this.rowSubscription = this.selectedRow$.subscribe(row => {
            if (!row) {
                return;
            }
            this.selectedRow = row;
        });
        this.indexInViewSubscribtion = this.indexInView$.subscribe(() => {
            if (!this.selectedRow.id) {
                return;
            }
            this.store.dispatch(new actions.ClearPolicyDetailsAction());
            this.store.dispatch(new actions.GetPolicyDetailsAction({ id: this.selectedRow.id }));
        });
        this.policyDetailsFullData$ = this.detailsStore.select(reducer.getSelectedRowFullData);
        this.policyDetailsFullDataSubscription = this.policyDetailsFullData$.subscribe(value => {
            if (value) {
                this.policyDetails = value;
                this.detailsCyberGraphPolicyForm
                    .get('enableDisablePolicy')
                    .patchValue(value.policy.enabled);
            }
        });
        this.enableDisablePolicyValueChangeSubscription = this.detailsCyberGraphPolicyForm
            .get('enableDisablePolicy')
            .valueChanges.subscribe(value => {
            if (value !== this.policyDetails.policy.enabled) {
                this.store.dispatch(new editActions.TogglePolicyEnabledAction(Object.assign(Object.assign({}, this.selectedRow), { status: value })));
            }
        });
    }
    ngOnDestroy() {
        this.rowSubscription.unsubscribe();
        this.enableDisablePolicyValueChangeSubscription.unsubscribe();
        this.policyDetailsFullDataSubscription.unsubscribe();
        this.indexInViewSubscribtion.unsubscribe();
    }
    onEdit() {
        this.store.dispatch(new editActions.OpenCreatePolicyPageInEditMode(this.policyDetails));
        this.store.dispatch(new listActions.ClosePolicyDetailsSidePanelAction());
    }
    onDelete() {
        this.store.dispatch(new listActions.PolicyDeleteAttemptAction(this.selectedRow));
        this.store.dispatch(new listActions.ClosePolicyDetailsSidePanelAction());
    }
    onClose() {
        this.store.dispatch(new listActions.ClosePolicyDetailsSidePanelAction());
    }
}
exports.CyberGraphPolicyAsideComponent = CyberGraphPolicyAsideComponent;
