"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducer = exports.initialState = void 0;
const settings_action_1 = require("../actions/settings.action");
exports.initialState = {
    settings: {
        customTitle: '',
        customBody: '',
        customEnabled: false,
        customAwarenessTipsEnabled: false,
        customAwarenessFooter: ''
    },
    updatedSettings: {
        customTitle: '',
        customBody: '',
        customEnabled: false,
        customAwarenessTipsEnabled: false,
        customAwarenessFooter: ''
    },
    error: false,
    isSaving: false,
    isLoading: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case settings_action_1.SwgBlockPageSettingsEnum.SWG_BLOCK_PAGE_GET:
            return Object.assign(Object.assign({}, state), { isLoading: true });
        case settings_action_1.SwgBlockPageSettingsEnum.SWG_BLOCK_PAGE_GET_SUCCESS:
            return Object.assign(Object.assign({}, state), { isLoading: false, settings: action.payload });
        case settings_action_1.SwgBlockPageSettingsEnum.SWG_BLOCK_PAGE_GET_FAILURE:
            return Object.assign(Object.assign({}, state), { isLoading: false, error: true });
        case settings_action_1.SwgBlockPageSettingsEnum.SWG_BLOCK_PAGE_SAVE:
            return Object.assign(Object.assign({}, state), { isSaving: true });
        case settings_action_1.SwgBlockPageSettingsEnum.SWG_BLOCK_PAGE_SAVE_SUCCESS:
            return Object.assign(Object.assign({}, state), { isSaving: false, settings: action.payload });
        case settings_action_1.SwgBlockPageSettingsEnum.SWG_BLOCK_PAGE_SAVE_FAILURE:
            return Object.assign(Object.assign({}, state), { isSaving: false, error: true });
        case settings_action_1.SwgBlockPageSettingsEnum.SWG_BLOCK_PAGE_UPDATE:
            return Object.assign(Object.assign({}, state), { updatedSettings: action.payload });
        default:
            return state;
    }
}
exports.reducer = reducer;
