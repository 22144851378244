"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UrlProtectionInboundSettingsStepComponent = void 0;
const url_protection_inbound_settings_step_component_static_values_1 = require("./url-protection-inbound-settings-step.component.static-values");
const group_selector_component_1 = require("app-new/components/group-selector/group-selector.component");
const actions = require("../../../actions/policies-create.actions");
const reducers = require("../../../reducers");
const policies_create_actions_1 = require("../../../actions/policies-create.actions");
const operators_1 = require("rxjs/operators");
const url_protection_inbound_settings_step_models_1 = require("./url-protection-inbound-settings-step.models");
const confirmation_modal_component_1 = require("../../confirmation-modal/confirmation-modal.component");
const policies_model_1 = require("../../../models/policies.model");
class UrlProtectionInboundSettingsStepComponent {
    constructor(fb, asideService, store, modalService) {
        this.fb = fb;
        this.asideService = asideService;
        this.store = store;
        this.modalService = modalService;
        this.rewriteModeValues = url_protection_inbound_settings_step_component_static_values_1.rewriteModeValues;
        this.urlCategoryScanningValues = url_protection_inbound_settings_step_component_static_values_1.urlCategoryScanningValues;
        this.maliciousUrlDetectionActionValues = url_protection_inbound_settings_step_component_static_values_1.maliciousUrlDetectionActionValues;
        this.messageSubjectProtectionValues = url_protection_inbound_settings_step_component_static_values_1.messageSubjectProtectionValues;
        this.fileProtocolUrlHandlingValues = url_protection_inbound_settings_step_component_static_values_1.fileProtocolUrlHandlingValues;
        this.urlFileDownloadValues = url_protection_inbound_settings_step_component_static_values_1.urlFileDownloadValues;
        this.similarUrlDetectionValues = url_protection_inbound_settings_step_component_static_values_1.similarUrlDetectionValues;
        this.notificationUrlFormatValues = url_protection_inbound_settings_step_component_static_values_1.notificationUrlFormatValues;
        this.showFileProtocolUrlHandling = false;
        this.ignoreSignedMessagesModalOpened = false;
        this.selectedGroupDescription$ = this.store.select(reducers.getInboundAdministratorsSelectedGroupDescription);
        this.urlProtectionPolicyInboundSettingsForm = this.fb.group({
            checkInboundEmail: [true],
            urlRewriteMode: [this.rewriteModeValues[1].value],
            urlCategoryScanningMode: [this.urlCategoryScanningValues[1].value],
            maliciousUrlDetectionAction: [this.maliciousUrlDetectionActionValues[0].value],
            browserIsolation: [true],
            messageSubjectProtection: [this.messageSubjectProtectionValues[0].value],
            createMissingHtmlBody: [true],
            forceSecureConnection: [true],
            ignoreSignedMessage: [false],
            displayUrlDestinationDomain: [true],
            stripExternalSourceMode: [false],
            fileProtocolUrlHandling: [this.fileProtocolUrlHandlingValues[1].value],
            blockUrlsWithDangerousFileExtensions: [true],
            rewriteUrlsInAttachments: [true],
            attachmentPartHtml: [true],
            attachmentPartText: [true],
            attachmentPartCalendar: [true],
            urlFileDownload: [this.urlFileDownloadValues[0].value],
            scanUrlInAttachment: [true],
            checkForSimilarUrls: [true],
            internalDomains: [true],
            mimecastMonitoringExternalDomain: [true],
            customMonitoringExternalDomain: [true],
            stripExternalSource: [false],
            similarUrlDetection: [this.similarUrlDetectionValues[0].value],
            notificationUrlFormat: [this.notificationUrlFormatValues[0].value]
        });
        this.startSubscriptions();
    }
    ngOnInit() {
        this.saveStoreData(this.urlProtectionPolicyInboundSettingsForm.value);
    }
    ngOnDestroy() {
        this.formValueChangeSubscription.unsubscribe();
        this.rewriteUrlsInAttachmentsValueSubscription.unsubscribe();
        this.checkForSimilarUrlsValueSubscription.unsubscribe();
        this.urlCategoryScaningValueSubscription.unsubscribe();
        this.ignoreSignedMessagesSubscription.unsubscribe();
    }
    startSubscriptions() {
        this.formValueChangeSubscription = this.urlProtectionPolicyInboundSettingsForm.valueChanges
            .pipe(operators_1.debounceTime(300))
            .subscribe(data => {
            this.saveStoreData(data);
        });
        this.rewriteUrlsInAttachmentsValueSubscription = this.urlProtectionPolicyInboundSettingsForm
            .get(url_protection_inbound_settings_step_component_static_values_1.formControlNames.REWRITE_URLS_IN_ATTACHMENTS)
            .valueChanges.subscribe(value => {
            url_protection_inbound_settings_step_component_static_values_1.formControlsToUpdateOnRewrite.forEach((control) => {
                this.urlProtectionPolicyInboundSettingsForm.get(control).patchValue(value);
            });
        });
        this.checkForSimilarUrlsValueSubscription = this.urlProtectionPolicyInboundSettingsForm
            .get(url_protection_inbound_settings_step_component_static_values_1.formControlNames.CHECK_FOR_SIMILAR_URLS)
            .valueChanges.subscribe(value => {
            url_protection_inbound_settings_step_component_static_values_1.formControlsToUpdateOnCheckForSimilarUrls.forEach((control) => {
                this.urlProtectionPolicyInboundSettingsForm.get(control).patchValue(value);
            });
            if (value) {
                this.patchSimilarUrlDetection(url_protection_inbound_settings_step_component_static_values_1.similarUrlDetectionIndexValues.BLOCK);
            }
            else {
                this.patchSimilarUrlDetection(url_protection_inbound_settings_step_component_static_values_1.similarUrlDetectionIndexValues.ALLOW);
            }
        });
        this.urlCategoryScaningValueSubscription = this.urlProtectionPolicyInboundSettingsForm
            .get(url_protection_inbound_settings_step_component_static_values_1.formControlNames.URL_CATEGORY_SCANING)
            .valueChanges.subscribe(value => {
            this.showFileProtocolUrlHandling = value === url_protection_inbound_settings_step_models_1.UrlCategoryScanningOptions.AGGRESSIVE;
        });
        this.ignoreSignedMessagesSubscription = this.urlProtectionPolicyInboundSettingsForm
            .get(url_protection_inbound_settings_step_component_static_values_1.formControlNames.IGNORE_SIGNED_MESSAGES)
            .valueChanges.subscribe(value => {
            if (value) {
                this.handleIgnoreSignedMessagesModal();
            }
        });
    }
    handleIgnoreSignedMessagesModal() {
        if (this.skipModalCallInEditAndDuplicateMode) {
            this.skipModalCallInEditAndDuplicateMode = false;
            return;
        }
        if (this.ignoreSignedMessagesModalOpened) {
            return;
        }
        this.modalService
            .open(confirmation_modal_component_1.ConfirmationModalComponent, {
            data: {
                type: policies_model_1.CONFIRMATION_DIALOG_TYPES.SIGNED_MESSAGES,
                wizardFlowType: null
            }
        })
            .afterClose()
            .pipe(operators_1.take(1))
            .subscribe(res => {
            if (!res) {
                this.urlProtectionPolicyInboundSettingsForm
                    .get(url_protection_inbound_settings_step_component_static_values_1.formControlNames.IGNORE_SIGNED_MESSAGES)
                    .patchValue(false);
            }
            this.ignoreSignedMessagesModalOpened = true;
        });
    }
    patchSimilarUrlDetection(index) {
        this.urlProtectionPolicyInboundSettingsForm
            .get(url_protection_inbound_settings_step_component_static_values_1.formControlNames.SIMILAR_URL_DETECTION)
            .patchValue(url_protection_inbound_settings_step_component_static_values_1.similarUrlDetectionValues[index].value);
    }
    saveStoreData(data) {
        this.store.dispatch(new policies_create_actions_1.SaveInboundStepDataAction(data));
    }
    hasBlockUrlAccess() {
        const maliciousUrlDetectionActionValue = this.urlProtectionPolicyInboundSettingsForm.get(url_protection_inbound_settings_step_component_static_values_1.formControlNames.MALICIOUS_URL_DETECTION_ACTION).value === this.maliciousUrlDetectionActionValues[0].value;
        return maliciousUrlDetectionActionValue && this.hasBrowserIsolation;
    }
    isInboundEmail() {
        return this.urlProtectionPolicyInboundSettingsForm.get(url_protection_inbound_settings_step_component_static_values_1.formControlNames.CHECK_INBOUND_EMAIL)
            .value;
    }
    checkForSimilarUrls() {
        return this.urlProtectionPolicyInboundSettingsForm.get(url_protection_inbound_settings_step_component_static_values_1.formControlNames.CHECK_FOR_SIMILAR_URLS)
            .value;
    }
    isRewriteUrlsInAttachments() {
        return this.urlProtectionPolicyInboundSettingsForm.get(url_protection_inbound_settings_step_component_static_values_1.formControlNames.REWRITE_URLS_IN_ATTACHMENTS).value;
    }
    isValid() {
        return true;
    }
    openSidebar() {
        const asideConfig = {};
        const aside = this.asideService.open(group_selector_component_1.GroupSelectorComponent, asideConfig);
        aside.beforeClose().subscribe(selectedGroup => {
            if (selectedGroup) {
                this.store.dispatch(new actions.AdministratorSelectGroup(selectedGroup));
            }
        });
    }
    ngOnChanges(changes) {
        const change = changes['policyInboundData'];
        if (change && change.previousValue !== change.currentValue) {
            if (change.currentValue) {
                if (change.currentValue.ignoreSignedMessage) {
                    this.skipModalCallInEditAndDuplicateMode = true;
                }
                this.urlProtectionPolicyInboundSettingsForm.patchValue(change.currentValue);
            }
        }
    }
    onDeleteGroup() {
        this.store.dispatch(new actions.RemoveAdministratorGroupAction());
    }
}
exports.UrlProtectionInboundSettingsStepComponent = UrlProtectionInboundSettingsStepComponent;
