<mc-layout-detail-wizard keyTitle="{{ getPageTitle() }}" extendClass="panel-padding-bottom">

  <mc-wizard (changeStep)="goToStep($event)">
    <mc-wizard-step label="$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.STEP_LABEL">
      <mc-cyber-graph-policy-details-step [policyDetailsData]="detailsStepData$ | async">
      </mc-cyber-graph-policy-details-step>
    </mc-wizard-step>

    <mc-wizard-step label="$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.STEP_LABEL">
      <mc-cyber-graph-applies-to-settings-step
        [fromToWhereAttributes]="(fromToWhereAttributes$ | async).fromToWhereAttributes"
        [policyAppliesToData]="appliesToStepData$ | async"
        [isEditMode]="isEditMode">
      </mc-cyber-graph-applies-to-settings-step>
    </mc-wizard-step>

    <mc-wizard-step label="$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.SUMMARY.STEP_LABEL">
      <mc-cyber-graph-policy-summary-step *ngIf="currentStep===SUMMARY_STEP" (onEditStep)=editStep($event)>
      </mc-cyber-graph-policy-summary-step>
    </mc-wizard-step>

  </mc-wizard>
  <mc-footer-container>
    <div class="pull-right no-select mc-button-footer">
      <button class="btn btn-subtle" (click)="cancel()">{{ '$I18N_COMMON_BTN_CANCEL' | translate }}</button>
      <ng-container *ngIf="wizard">
        <button class="btn btn-secondary panel-half-margin-right" *ngIf="wizard.hasPreviousStep$ | async"
          (click)="wizard.previousStep()">
          {{ '$I18N_COMMON_BTN_PREVIOUS' | translate }}
        </button>
        <button [ngClass]="isEditMode ? 'btn btn-secondary': 'btn btn-primary'"  [disabled]="!isStepValid()" *ngIf="wizard.hasNextStep$ | async"
          (click)="wizard.nextStep()">
          {{ '$I18N_COMMON_BTN_NEXT' | translate }}
        </button>
        <ng-container *ngIf="!isEditMode">
          <mc-live-button *ngIf="wizard.isLastStep$ | async" overrideClasses="btn-primary"
            [label]="'$I18N_CYBERGRAPH_POLICY_WIZARD.FOOTER.CREATE' | translate"
            (click)="createPolicy()"
            [isLoading]="isApiProcessing"
            ></mc-live-button>
        </ng-container>
        <mc-live-button overrideClasses="btn-primary"
          *ngIf="isEditMode"
          [label]="'$I18N_CYBERGRAPH_POLICY_WIZARD.FOOTER.EDIT' | translate"
          (click)="editPolicy()"
          [isLoading]="isApiProcessing"
        ></mc-live-button>
      </ng-container>
    </div>
  </mc-footer-container>
</mc-layout-detail-wizard>
