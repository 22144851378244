"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListEffects = exports.BLOCK = exports.PERMIT = void 0;
const effects_1 = require("@ngrx/effects");
const listAction = require("../actions/list.action");
const table_store_1 = require("@mimecast-ui/table-store");
const operators_1 = require("rxjs/operators");
const rejected_list_impl_service_1 = require("../containers/rejected/rejected-list.impl.service");
const deferred_list_impl_service_1 = require("../containers/deferred/deferred-list.impl.service");
const rxjs_1 = require("rxjs");
const commonActions = require("../../common/actions");
const moment = require("moment");
exports.PERMIT = 'PERMIT';
exports.BLOCK = 'BLOCK';
class ListEffects {
    constructor(actions$, tableStore) {
        this.actions$ = actions$;
        this.tableStore = tableStore;
        this.loadRejectedMessages$ = this.actions$.pipe(effects_1.ofType(listAction.GET_REJECTED), operators_1.withLatestFrom(this.tableStore.select(table_store_1.tableReducer.getCurrentFilters(rejected_list_impl_service_1.RejectedListImplService.TABLE_ID)), this.tableStore.select(table_store_1.tableReducer.getCurrentPagination(rejected_list_impl_service_1.RejectedListImplService.TABLE_ID))), operators_1.switchMap(([, currentFilters, currentPagination]) => {
            return rxjs_1.of(new table_store_1.LoadAction({
                tableId: rejected_list_impl_service_1.RejectedListImplService.TABLE_ID,
                pagination: { pageToken: '', pageSize: currentPagination.pageSize, pageNumber: 0 },
                // @ts-ignore
                query: this.updateDataRangeTime({ start: currentFilters.start, end: currentFilters.end })
            }));
        }));
        this.loadDeferredMessages$ = this.actions$.pipe(effects_1.ofType(listAction.GET_DEFERRED), operators_1.withLatestFrom(this.tableStore.select(table_store_1.tableReducer.getCurrentFilters(deferred_list_impl_service_1.DeferredListImplService.TABLE_ID)), this.tableStore.select(table_store_1.tableReducer.getCurrentPagination(deferred_list_impl_service_1.DeferredListImplService.TABLE_ID))), operators_1.switchMap(([, currentFilters, currentPagination]) => {
            return rxjs_1.of(new table_store_1.LoadAction({
                tableId: deferred_list_impl_service_1.DeferredListImplService.TABLE_ID,
                pagination: { pageToken: '', pageSize: currentPagination.pageSize, pageNumber: 0 },
                // @ts-ignore
                query: this.updateDataRangeTime({ start: currentFilters.start, end: currentFilters.end })
            }));
        }));
        this.loadRejectedMessageInfo$ = this.actions$.pipe(effects_1.ofType(listAction.GET_REJECTED_INFO), operators_1.withLatestFrom(this.tableStore.select(table_store_1.tableReducer.getTableData(rejected_list_impl_service_1.RejectedListImplService.TABLE_ID))), operators_1.switchMap(([action, list]) => {
            if (action.row) {
                return rxjs_1.of(new commonActions.ShowMessageInfoAction(action.row, list, false));
            }
            else {
                return rxjs_1.EMPTY;
            }
        }));
        this.loadDeferredMessageInfo$ = this.actions$.pipe(effects_1.ofType(listAction.GET_DEFERRED_INFO), operators_1.withLatestFrom(this.tableStore.select(table_store_1.tableReducer.getTableData(deferred_list_impl_service_1.DeferredListImplService.TABLE_ID))), operators_1.switchMap(([action, list]) => {
            if (action.row) {
                return rxjs_1.of(new commonActions.ShowMessageInfoAction(action.row, list, false));
            }
            else {
                return rxjs_1.EMPTY;
            }
        }));
        // @ts-ignore
        this.permitRejectedMessage$ = this.actions$.pipe(effects_1.ofType(listAction.PERMIT_FOR_RECIPIENT_REJECTED), operators_1.withLatestFrom(this.tableStore.select(table_store_1.tableReducer.getTableData(rejected_list_impl_service_1.RejectedListImplService.TABLE_ID))), operators_1.switchMap(([action]) => {
            return this.permitOrBlockMessage(action.row, exports.PERMIT);
        }));
        // @ts-ignore
        this.blockRejectedMessage$ = this.actions$.pipe(effects_1.ofType(listAction.BLOCK_FOR_RECIPIENT_REJECTED), operators_1.withLatestFrom(this.tableStore.select(table_store_1.tableReducer.getTableData(rejected_list_impl_service_1.RejectedListImplService.TABLE_ID))), operators_1.switchMap(([action]) => {
            return this.permitOrBlockMessage(action.row, exports.BLOCK);
        }));
        // @ts-ignore
        this.permitDeferredMessage$ = this.actions$.pipe(effects_1.ofType(listAction.PERMIT_FOR_RECIPIENT_DEFERRED), operators_1.withLatestFrom(this.tableStore.select(table_store_1.tableReducer.getTableData(deferred_list_impl_service_1.DeferredListImplService.TABLE_ID))), operators_1.switchMap(([action]) => {
            return this.permitOrBlockMessage(action.row, exports.PERMIT);
        }));
        // @ts-ignore
        this.blockDeferredMessage$ = this.actions$.pipe(effects_1.ofType(listAction.BLOCK_FOR_RECIPIENT_DEFERRED), operators_1.withLatestFrom(this.tableStore.select(table_store_1.tableReducer.getTableData(deferred_list_impl_service_1.DeferredListImplService.TABLE_ID))), operators_1.switchMap(([action]) => {
            return this.permitOrBlockMessage(action.row, exports.BLOCK);
        }));
    }
    permitOrBlockMessage(message, type) {
        if (message) {
            return [
                new commonActions.GetMessageInfoSuccessAction(
                // @ts-ignore
                message, false),
                type === exports.PERMIT
                    ? new commonActions.PermitSenderRejectedEmailAction()
                    : new commonActions.BlockSenderRejectedEmailAction()
            ];
        }
        else {
            return rxjs_1.EMPTY;
        }
    }
    updateDataRangeTime(range) {
        let start = moment(range.start);
        let end = moment(range.end);
        const currentTime = moment();
        const time = {
            hour: currentTime.get('hour'),
            minute: currentTime.get('minute'),
            second: currentTime.get('second')
        };
        start = start.set(time);
        end = end.set(time);
        range.start = moment(start)
            .format()
            .toString();
        range.end = moment(end)
            .format()
            .toString();
        return range;
    }
}
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "loadRejectedMessages$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "loadDeferredMessages$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "loadRejectedMessageInfo$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "loadDeferredMessageInfo$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "permitRejectedMessage$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "blockRejectedMessage$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "permitDeferredMessage$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "blockDeferredMessage$", void 0);
exports.ListEffects = ListEffects;
