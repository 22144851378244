"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.apiDomainOrUrlAndQueryValidator = exports.checkIfUrlOrDomain = exports.apiDomainOrUrlValidator = exports.stripFirefoxWildcardChar = exports.getCheckedURL = exports.looseDomainValidator = exports.managedUrlValidator = exports.isValidManagedUrl = exports.domainValidator = exports.looseDomainValidation = exports.isValidApiUrlAndQuery = exports.hasValidUrlLength = exports.hasSpaceCharacter = exports.isValidUrl = exports.isValidDomain = exports.API_URL_AND_QUERY_VALIDATOR_REGEX = void 0;
const lodash_1 = require("lodash");
// API uses ApacheUrl validator This is closest regex to use with @Domain Annotation with api
const DOMAIN_REGEXP = /^((?=[a-z0-9-]{1,63}\.)(xn--)?[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63}$/;
// The below reqex is from - https://www.regextester.com/94502
const URL_VALIDATOR_REGEX = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
// eslint-disable-next-line
exports.API_URL_AND_QUERY_VALIDATOR_REGEX = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?(([A-Za-z0-9-_]{1,63}\.)*([A-Za-z0-9-_]{1,63}))(:[0-9]{1,5})?([\/\?].*)?$/;
// eslint-disable-next-line
const isValidDomain = (domain) => DOMAIN_REGEXP.test(lodash_1.trim(domain));
exports.isValidDomain = isValidDomain;
const isValidUrl = (url) => URL_VALIDATOR_REGEX.test(lodash_1.trim(url));
exports.isValidUrl = isValidUrl;
const hasSpaceCharacter = (url) => url.split(' ').length > 1;
exports.hasSpaceCharacter = hasSpaceCharacter;
const hasValidUrlLength = (url) => url.length <= 5000;
exports.hasValidUrlLength = hasValidUrlLength;
const isValidApiUrlAndQuery = (url) => exports.API_URL_AND_QUERY_VALIDATOR_REGEX.test(lodash_1.trim(url));
exports.isValidApiUrlAndQuery = isValidApiUrlAndQuery;
const SCHEMA_MARKER = '://';
const WILDCARD_CHARACTER = '*';
const ENCODED_WILDCARD_CHARACTER = '%2A';
/*
 Loose domain validation as API
 */
const looseDomainValidation = (control) => {
    const LOOSEDOMAINREXGEX = /^([A-z0-9_-]){1,63}$/;
    const parts = control.split('.');
    for (const part of parts) {
        if (!LOOSEDOMAINREXGEX.test(part)) {
            return false;
        }
    }
    return true;
};
exports.looseDomainValidation = looseDomainValidation;
/* Validator */
const domainValidator = (control) => {
    if (control.value.length === 0 || exports.isValidDomain(control.value)) {
        return true;
    }
    else {
        return { INVALID_DOMAIN: { value: control.value } };
    }
};
exports.domainValidator = domainValidator;
const isValidManagedUrl = (url) => {
    if (url.includes(WILDCARD_CHARACTER)) {
        const wildChars = url.split(WILDCARD_CHARACTER);
        if (wildChars.length > 2) {
            return false;
        }
    }
    const schema = getSchema(url);
    if (!schema) {
        return false;
    }
    const parsedUrl = getCheckedURL(url);
    if (!parsedUrl) {
        return false;
    }
    return (isValidDomainWildcardCheck(parsedUrl.hostname) &&
        isValidPathQuery(parsedUrl.pathname) &&
        isValidPathQuery(parsedUrl.search));
};
exports.isValidManagedUrl = isValidManagedUrl;
const managedUrlValidator = (control) => {
    const ua = window.navigator.userAgent;
    const isIe = ua.indexOf('Trident') > 0 || ua.indexOf('MSIE') > 0;
    if (isIe) {
        return true;
    }
    const urls = control.value
        .trim()
        .split('\n')
        .filter((item) => item);
    if (urls.length > 0) {
        for (const url of urls) {
            const validUrl = url.trim();
            if (!exports.hasValidUrlLength(validUrl) ||
                exports.hasSpaceCharacter(validUrl) ||
                !exports.isValidManagedUrl(validUrl)) {
                return { INVALID_API_DOMAIN_URL: { value: control.value } };
            }
        }
    }
    return true;
};
exports.managedUrlValidator = managedUrlValidator;
const looseDomainValidator = (control) => {
    if (control.value.length === 0 || exports.looseDomainValidation(control.value)) {
        return true;
    }
    else {
        return { INVALID_DOMAIN: { value: control.value } };
    }
};
exports.looseDomainValidator = looseDomainValidator;
function isValidDomainWildcardCheck(domain) {
    if (!domain) {
        return false;
    }
    const parsedDomain = decodeURIComponent(domain);
    if (!parsedDomain.includes(WILDCARD_CHARACTER)) {
        return true;
    }
    if (parsedDomain.length > 2) {
        const subStringedDoman = parsedDomain.substring(1, parsedDomain.length);
        return !subStringedDoman.includes(WILDCARD_CHARACTER);
    }
    return false;
}
function isValidPathQuery(path) {
    if (!path) {
        return true;
    }
    const decodedPath = decodeURIComponent(path);
    return !decodedPath.includes(WILDCARD_CHARACTER);
}
function getURL(url) {
    try {
        return new window.URL(url);
    }
    catch (e) {
        return null;
    }
}
function getCheckedURL(url) {
    const ua = window.navigator.userAgent;
    const isFirefox = ua.indexOf('Firefox') > 0;
    if (!isFirefox) {
        return getURL(url);
    }
    return getURL(stripFirefoxWildcardChar(url));
}
exports.getCheckedURL = getCheckedURL;
function stripFirefoxWildcardChar(url) {
    const parsedSchema = url.substring(0, url.indexOf(SCHEMA_MARKER));
    const parsedDomainNoSchema = url.substring(parsedSchema.length + 3, url.length);
    if (parsedDomainNoSchema.startsWith(WILDCARD_CHARACTER)) {
        return url.replace(WILDCARD_CHARACTER, '');
    }
    else if (parsedDomainNoSchema.startsWith(ENCODED_WILDCARD_CHARACTER)) {
        return url.replace(ENCODED_WILDCARD_CHARACTER, '');
    }
    else {
        return url;
    }
}
exports.stripFirefoxWildcardChar = stripFirefoxWildcardChar;
function getSchema(url) {
    if (!url || url.length === 0) {
        return null;
    }
    if (url.includes(SCHEMA_MARKER)) {
        const parsedSchema = url.substring(0, url.indexOf(SCHEMA_MARKER));
        return parsedSchema.includes(WILDCARD_CHARACTER) ? null : parsedSchema;
    }
    return null;
}
const apiDomainOrUrlValidator = (control) => {
    if (control.value.length === 0 || exports.isValidApiUrlAndQuery(control.value)) {
        return true;
    }
    else {
        return { INVALID_API_DOMAIN_URL: { value: control.value } };
    }
};
exports.apiDomainOrUrlValidator = apiDomainOrUrlValidator;
const checkIfUrlOrDomain = (url) => {
    // A quick way to count forward slashes (see https://stackoverflow.com/a/45405368)
    const countForwardSlashes = (urlStr) => lodash_1.countBy(urlStr)['/'] || 0;
    const parsed = exports.API_URL_AND_QUERY_VALIDATOR_REGEX.exec(lodash_1.trim(url));
    const domain = parsed[2];
    const queryBit = parsed[6];
    let removedQueryBit = '';
    if (queryBit) {
        // Truncate text after #
        removedQueryBit = queryBit.split('#')[0];
        // add a slash before first ? if there's no path
        // e.g. example.com?foo=bar => example.com/?foo=bar
        const parts = removedQueryBit.split(/\?(.+)/);
        if (parts.length > 1 && countForwardSlashes(parts[0]) === 0) {
            removedQueryBit = parts[0] + '/?' + parts[1];
        }
        // remove excess \/ with 1 \/
        removedQueryBit = removedQueryBit.split(/\/+/).join('/');
    }
    if (removedQueryBit === '/' ||
        removedQueryBit === '?' ||
        removedQueryBit === '' ||
        removedQueryBit === '/?') {
        return { value: domain, type: 'domain' };
    }
    return { value: domain + removedQueryBit, type: 'url' };
};
exports.checkIfUrlOrDomain = checkIfUrlOrDomain;
const apiDomainOrUrlAndQueryValidator = (control) => {
    if (control.value.length === 0 ||
        exports.looseDomainValidation(control.value) ||
        exports.isValidApiUrlAndQuery(control.value)) {
        return true;
    }
    else {
        return { INVALID_API_DOMAIN_URL: { value: control.value } };
    }
};
exports.apiDomainOrUrlAndQueryValidator = apiDomainOrUrlAndQueryValidator;
