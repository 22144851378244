"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewQueueConfigDetailsComponent = void 0;
const queue_type_enum_1 = require("app-new/archive/supervision/types/queue-type.enum");
class ViewQueueConfigDetailsComponent {
    constructor(translateService) {
        this.translateService = translateService;
        this._sortedEscalationQueues = [];
        this.NO_DEFAULT_QUEUE = '';
        this.DEFAULT_QUEUE_LABEL = this.translateService.instant('$I18N_ARCHIVE_SUPERVISION_QUEUE_DETAILS.DETAILS.DEFAULT_QUEUE');
    }
    ngOnInit() {
        if (this.queueDetails.escalationQueues) {
            this._sortedEscalationQueues = this.queueDetails.escalationQueues
                .slice()
                .sort((a, b) => a.name.localeCompare(b.name));
        }
    }
    // @ts-ignore
    get escalationQueues() {
        return this._sortedEscalationQueues;
    }
    // @ts-ignore
    get escalationQueues() {
        return this.alphabeticalSorting(this.queueDetails.escalationQueues, a => a.name);
    }
    get isReviewQueue() {
        return this.queueDetails ? this.queueDetails.type === queue_type_enum_1.QueueType.REVIEW : false;
    }
    isDefault(escalationQueueId) {
        if (this.noDefaultEscalationQueue()) {
            return this.NO_DEFAULT_QUEUE;
        }
        else {
            const result = escalationQueueId === this.queueDetails.defaultEscalationQueue.id
                ? this.DEFAULT_QUEUE_LABEL
                : this.NO_DEFAULT_QUEUE;
            return result;
        }
    }
    noDefaultEscalationQueue() {
        return !this.queueDetails.defaultEscalationQueue;
    }
    alphabeticalSorting(array, sortBy) {
        return array ? array.slice().sort((a, b) => sortBy(a).localeCompare(sortBy(b))) : [];
    }
    get associatedReviewQueues() {
        return this.alphabeticalSorting(this.queueDetails.associatedReviewQueues, a => a.name);
    }
    get associatedRules() {
        return this.alphabeticalSorting(this.queueDetails.associatedRules, a => a.name);
    }
    get reviewers() {
        return this.alphabeticalSorting(this.queueDetails.reviewers, a => a);
    }
    get labels() {
        return this.alphabeticalSorting(this.queueDetails.labels, a => a);
    }
}
exports.ViewQueueConfigDetailsComponent = ViewQueueConfigDetailsComponent;
