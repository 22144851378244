"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiIntegrationWildfireDisclaimerStepComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const operators_1 = require("rxjs/operators");
class ApiIntegrationWildfireDisclaimerStepComponent {
    constructor(fb) {
        this.fb = fb;
        this.onSwitchAcceptSharingInfo = new core_1.EventEmitter();
        this.form = this.fb.group({
            acceptSharingInfo: new forms_1.FormControl({ value: false, disabled: false })
        });
    }
    ngOnInit() {
        this.formSubscription = this.form.valueChanges.pipe(operators_1.debounceTime(200)).subscribe(() => {
            this.acceptTerms();
        });
    }
    acceptTerms() {
        this.onSwitchAcceptSharingInfo.emit(this.form.value.acceptSharingInfo);
    }
    isValid() {
        return this.form.value.acceptSharingInfo;
    }
    ngOnDestroy() {
        this.formSubscription.unsubscribe();
    }
}
exports.ApiIntegrationWildfireDisclaimerStepComponent = ApiIntegrationWildfireDisclaimerStepComponent;
