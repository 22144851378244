"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MepPolicyAutoReleaseTimeout = exports.MepAffectedUsersEnum = exports.MepPolicyType = void 0;
var MepPolicyType;
(function (MepPolicyType) {
    MepPolicyType["MISDIRECTED_EMAIL_POLICY"] = "MISDIRECTED_EMAIL_POLICY";
})(MepPolicyType = exports.MepPolicyType || (exports.MepPolicyType = {}));
var MepAffectedUsersEnum;
(function (MepAffectedUsersEnum) {
    MepAffectedUsersEnum["EVERYONE"] = "everyone";
    MepAffectedUsersEnum["MULTI"] = "multi";
    MepAffectedUsersEnum["INDIVIDUAL_EMAIL_ADDRESS"] = "individual_email_address";
    MepAffectedUsersEnum["PROFILE_GROUP"] = "profile_group";
    MepAffectedUsersEnum["EXTERNAL_ADDRESSES"] = "external_addresses";
})(MepAffectedUsersEnum = exports.MepAffectedUsersEnum || (exports.MepAffectedUsersEnum = {}));
var MepPolicyAutoReleaseTimeout;
(function (MepPolicyAutoReleaseTimeout) {
    MepPolicyAutoReleaseTimeout[MepPolicyAutoReleaseTimeout["FIFTEEN_MINUTES"] = 15] = "FIFTEEN_MINUTES";
    MepPolicyAutoReleaseTimeout[MepPolicyAutoReleaseTimeout["THIRTY_MINUTES"] = 30] = "THIRTY_MINUTES";
    MepPolicyAutoReleaseTimeout[MepPolicyAutoReleaseTimeout["ONE_HOUR"] = 60] = "ONE_HOUR";
    MepPolicyAutoReleaseTimeout[MepPolicyAutoReleaseTimeout["TWO_HOUR"] = 120] = "TWO_HOUR";
})(MepPolicyAutoReleaseTimeout = exports.MepPolicyAutoReleaseTimeout || (exports.MepPolicyAutoReleaseTimeout = {}));
