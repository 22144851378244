"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ZeroToDashPipe = void 0;
class ZeroToDashPipe {
    transform(input) {
        if (input) {
            return input === '0' ? '-' : input.toString();
        }
        else {
            return '-';
        }
    }
}
exports.ZeroToDashPipe = ZeroToDashPipe;
