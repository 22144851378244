"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewPolicyComponent = void 0;
const selectors = require("../../reducers/index");
const actions = require("../../actions");
const actions_1 = require("app-new/custom-domain-block/actions");
class ViewPolicyComponent {
    constructor(store, tableContent, stateService) {
        this.store = store;
        this.tableContent = tableContent;
        this.stateService = stateService;
    }
    ngOnInit() {
        this.policyDetails$ = this.store.select(selectors.getSelectedRow);
        this.policyLoading$ = this.store.select(selectors.isLoading);
        this.paginationInfo$ = this.store.select(selectors.getDetailsPaginationInfo);
        this.subscription = this.policyDetails$.subscribe(policyDetails => (this.policyDetails = policyDetails));
    }
    ngOnDestroy() {
        this.store.dispatch(new actions_1.CleanPolicyAction());
        this.subscription.unsubscribe();
    }
    loadDetails(customDomainBlock) {
        this.store.dispatch(new actions.GetPolicyAction(customDomainBlock));
    }
    edit() {
        this.close();
        if (this.policyDetails) {
            this.stateService.$state.go('custom-domain-block-policy-wizard', {
                policyDetails: this.policyDetails
            });
        }
    }
    showDelete() {
        this.store.dispatch(new actions.ShowDeleteConfirmationAction(this.policyDetails));
    }
    enableDisable(status) {
        this.store.dispatch(new actions.UpdatePolicyAction({
            id: this.policyDetails.policyId,
            definitionId: this.policyDetails.definitionId,
            policy: {
                enabled: status
            }
        }));
    }
    close() {
        this.store.dispatch(new actions_1.CloseSidePaneAction());
    }
}
exports.ViewPolicyComponent = ViewPolicyComponent;
