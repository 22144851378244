"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgEndpointsPasswordsComponent = void 0;
const core_1 = require("@angular/core");
class SwgEndpointsPasswordsComponent {
    constructor(fb, translateService, notificationService) {
        this.fb = fb;
        this.translateService = translateService;
        this.notificationService = notificationService;
        this.settingsChanged = new core_1.EventEmitter();
        this.getTamperProtectedPassword = new core_1.EventEmitter();
        this.getUninstallPassword = new core_1.EventEmitter();
        this.copyTamperProtectedPassword = new core_1.EventEmitter();
        this.copyUninstallPassword = new core_1.EventEmitter();
        this.viewTamperProtectedPassword = new core_1.EventEmitter();
        this.viewUninstallPassword = new core_1.EventEmitter();
        this.openUninstallPwdConfirmModal = new core_1.EventEmitter();
        this.settingOperationMessage = 'Settings Updating';
        this.isAntiTamperPasswordInputType = true;
        this.isPreviousPasswordInputType = true;
        this.isUninstallPasswordInputType = true;
        this.form = this.fb.group({
            userLogin: [false],
            enforceUserLogin: [false],
            allowTransparentId: [false],
            autoUpdate: [false],
            tamperProtectPassword: '',
            tamperProtectPreviousPassword: '',
            uninstallPassword: '',
            enableUninstallPassword: [false],
            enableAutoEnrolment: [false]
        });
    }
    set settings(settings) {
        this.settingsPrevious = settings;
        this.currentControl = '';
        this.form.markAsPristine();
        if (settings && !this.form.dirty) {
            this.setFormValues(settings);
        }
    }
    set updateSettingsError(error) {
        if (error) {
            this.setFormValues(this.settingsPrevious);
        }
    }
    set tamperProtectedPassword(pwd) {
        this.hasPassword = !!pwd;
        this.password = this.hasPassword ? pwd.antiTamperPassword : '';
        this.previousPassword = this.hasPassword ? pwd.prevAntiTamperPassword : '';
        this.form.patchValue({
            tamperProtectPassword: this.password,
            tamperProtectPreviousPassword: this.previousPassword
        });
    }
    set uninstallPassword(pwd) {
        this.hasUninstallPassword = !!pwd;
        this._uninstallPassword = this.hasUninstallPassword ? pwd.uninstallPassword : '';
        this.form.patchValue({
            uninstallPassword: this._uninstallPassword
        });
    }
    ngOnInit() {
        this.formValuesChangeSubscription = this.form.valueChanges.subscribe((settings) => {
            // we are being optimistic about the settings value changes
            if (this.form.dirty) {
                for (const control in this.form.controls) {
                    if (this.form.controls[control].dirty) {
                        this.currentControl = control;
                        break;
                    }
                }
                if (this.form.controls.enableUninstallPassword.dirty) {
                    this.form.markAsPristine();
                    this.openUninstallPwdConfirmModal.emit(settings);
                }
                else {
                    this.settingsChanged.emit(settings);
                }
            }
        });
        this.readOnlyMessage = {
            msg: this.translateService.instant('$I18N_ENDPOINT_SETTINGS.NOTIFICATION.READ_ONLY'),
            status: 'info'
        };
    }
    ngOnDestroy() {
        this.formValuesChangeSubscription.unsubscribe();
    }
    setFormValues(settings) {
        this.form.patchValue({
            userLogin: settings.userLogin,
            enforceUserLogin: settings.enforceUserLogin,
            allowTransparentId: settings.allowTransparentId,
            autoUpdate: settings.autoUpdate,
            enableUninstallPassword: settings.enableUninstallPassword === undefined ? false : settings.enableUninstallPassword,
            enableAutoEnrolment: settings.enableAutoEnrolment
        });
    }
    onGenerateAntiTamperPassword() {
        this.getTamperProtectedPassword.emit(true);
    }
    onGenerateUninstallPassword() {
        this.getUninstallPassword.emit(true);
    }
    copyAntiTamperPasswordToClipboardSuccess() {
        this.copyTamperProtectedPassword.emit();
        this.notificationService.success({
            msg: this.translateService.instant('$I18N_ENDPOINT_SETTINGS.NOTIFICATION.SUCCESS.COPY_PASSWORD_TO_CLIPBOARD')
        });
    }
    copyUninstallPasswordClipboardSuccess() {
        this.copyUninstallPassword.emit();
        this.notificationService.success({
            msg: this.translateService.instant('$I18N_ENDPOINT_SETTINGS.NOTIFICATION.SUCCESS.COPY_PASSWORD_TO_CLIPBOARD')
        });
    }
    toggleAntiTamperPasswordInputType() {
        this.viewTamperProtectedPassword.emit();
        this.isAntiTamperPasswordInputType = !this.isAntiTamperPasswordInputType;
    }
    toggleUninstallPasswordInputType() {
        this.viewUninstallPassword.emit();
        this.isUninstallPasswordInputType = !this.isUninstallPasswordInputType;
    }
    togglePreviousPasswordInputType() {
        this.isPreviousPasswordInputType = !this.isPreviousPasswordInputType;
    }
    onEnableUninstallPasswordToggle() {
        this.form.patchValue({
            enableUninstallPassword: !this.form.get('enableUninstallPassword').value
        });
    }
}
exports.SwgEndpointsPasswordsComponent = SwgEndpointsPasswordsComponent;
