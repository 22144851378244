"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateSnapshotComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const utilities_1 = require("app-new/components/form-validators/date/utilities");
const validator_service_1 = require("app-new/components/form-validators/date/validator.service");
const validator_service_2 = require("app-new/components/form-validators/email/validator.service");
const moment = require("moment");
const operators_1 = require("rxjs/operators");
const snapshot_1 = require("../../../reducers/snapshot");
class CreateSnapshotComponent {
    constructor(overlayRef, translate, overlayData, fb) {
        this.overlayRef = overlayRef;
        this.translate = translate;
        this.overlayData = overlayData;
        this.fb = fb;
        this.mcCreateSnapshot = new core_1.EventEmitter();
        this.mcGoToSnapshotQueue = new core_1.EventEmitter();
        this.mcGoToSnapshot = new core_1.EventEmitter();
        this.isCreateClicked = false;
        this.hasCreateError = false;
        this.isDuplicateSnapshot = false;
        this.createSnapshotPayload = { mailbox: this.overlayData.mailbox };
        this.inlineNotificationConfig = this.getInfoNotification();
        const min = moment(this.overlayData.initialReplicationDate);
        const max = moment();
        this.startDate = { year: max.year(), month: max.month() + 1, day: max.date() };
        this.minDate = { year: min.year(), month: min.month() + 1, day: min.date() };
        this.maxDate = { year: max.year(), month: max.month() + 1, day: max.date() };
        const dateTimeGroup = this.fb.group({
            date: [this.startDate, [validator_service_1.DateValidator.dateFormat]],
            time: [
                utilities_1.prependZero(max.hours()) + ':' + utilities_1.prependZero(max.minutes()),
                [forms_1.Validators.pattern(/^([01][0-9]|2[0-3]):[0-5][0-9]$/)]
            ]
        }, {
            validator: validator_service_1.DateValidator.boundary(new Date(this.overlayData.initialReplicationDate), new Date(), 'dd MMM y HH:mm')
        });
        this.createSnapshotForm = this.fb.group({
            dateTime: dateTimeGroup,
            notificationEmail: this.fb.array([], validator_service_2.EmailValidator.duplicate)
        });
        this.addEmailField(this.overlayData.user);
        this.onFormValueChange(this.createSnapshotForm.value);
    }
    set snapShotDetails(snapshot) {
        this.snapshot = snapshot;
        if (snapshot) {
            this.clearErrors();
        }
    }
    get snapShotDetails() {
        return this.snapshot;
    }
    set snapshotCreationfail(fail) {
        if (!fail) {
            this.inlineNotificationConfig = this.getInfoNotification();
        }
        else if (fail.errors[0].code.toLocaleUpperCase() === snapshot_1.CreateErrors.ERR_SNAPSHOT_ALREADY_EXISTS) {
            this.inlineNotificationConfig = undefined;
            this.inlineNotificationConfig = this.getDuplicateNotification(fail);
            this.isDuplicateSnapshot = true;
        }
        else {
            this.inlineNotificationConfigError = {
                status: 'error',
                msg: this.translate.instant('$I18N_SYNC_RECOVER.EXCHANGE.CREATE_SNAPSHOT.INLINE_NOTIFICATION.ERROR')
            };
            this.hasCreateError = true;
        }
    }
    get isCreated() {
        return (this.isCreateClicked &&
            !this.isRequesting &&
            !this.hasCreateError &&
            !this.isDuplicateSnapshot);
    }
    get notificationEmailControls() {
        return this.createSnapshotForm.get('notificationEmail');
    }
    get dateTimeGroup() {
        return this.createSnapshotForm.get('dateTime');
    }
    get isThereAnEmptyEmailField() {
        return (this.notificationEmailControls.value.filter((control) => control['email'].trim().length === 0).length > 0);
    }
    ngOnInit() {
        this.createSnapshotForm.valueChanges
            .pipe(operators_1.debounceTime(300))
            .subscribe(newFormValue => this.onFormValueChange(newFormValue));
    }
    onFormValueChange(newFormValue) {
        this.inlineNotificationConfig = this.getInfoNotification();
        this.createSnapshotPayload.date =
            this.createSnapshotForm.get('dateTime').valid &&
                this.convertToOutputDate(newFormValue.dateTime);
        const email = newFormValue.notificationEmail
            .filter((control) => control['email'])
            .map((control) => {
            return { emailAddress: control['email'] };
        });
        if (email) {
            this.createSnapshotPayload.notifyAddresses = email;
        }
    }
    addEmailField(email) {
        this.notificationEmailControls.push(this.fb.group({
            email: new forms_1.FormControl(email || '', validator_service_2.EmailValidator.validAndNotRequired)
        }));
    }
    deleteEmailField(index) {
        this.notificationEmailControls.removeAt(index);
    }
    onClickCreate() {
        if (this.createSnapshotForm.valid) {
            this.isCreateClicked = true;
            this.mcCreateSnapshot.emit(Object.assign({}, this.createSnapshotPayload));
        }
    }
    onClickGoToSnapshotQueue() {
        this.mcGoToSnapshotQueue.emit();
        this.overlayRef.close();
    }
    onClickClose() {
        this.overlayRef.close();
    }
    convertToOutputDate(dateAndTime) {
        const date = dateAndTime['date'];
        const time = dateAndTime['time'].split(':');
        return (new Date(date.year, date.month - 1, date.day, time[0], time[1])
            .toISOString()
            .split('.')[0]
            .replace(/:00$/, ':59') + 'Z');
    }
    getInfoNotification() {
        return {
            status: 'info',
            msg: this.translate.instant('$I18N_SYNC_RECOVER.EXCHANGE.CREATE_SNAPSHOT.INLINE_NOTIFICATION.INFO')
        };
    }
    getDuplicateNotification(fail) {
        const message = fail.key && fail.key.failData
            ? '$I18N_SYNC_RECOVER.EXCHANGE.CREATE_SNAPSHOT.INLINE_NOTIFICATION.WARNING.DESCRIPTION_2'
            : '$I18N_SYNC_RECOVER.EXCHANGE.CREATE_SNAPSHOT.INLINE_NOTIFICATION.WARNING.DESCRIPTION_1';
        return {
            status: 'warning',
            msg: this.translate.instant(message, {
                snapshotId: fail.key && fail.key.failData && fail.key.failData.existingSnapshot,
                username: fail.key && fail.key.failData && fail.key.failData.createdBy.emailAddress
            }),
            actions: [
                {
                    label: this.translate.instant('$I18N_SYNC_RECOVER.EXCHANGE.CREATE_SNAPSHOT.INLINE_NOTIFICATION.WARNING.BUTTON_PRIMARY'),
                    type: 'button',
                    callback: () => {
                        this.onClickGoToSnapshotQueue();
                    }
                },
                {
                    label: this.translate.instant('$I18N_SYNC_RECOVER.EXCHANGE.CREATE_SNAPSHOT.INLINE_NOTIFICATION.WARNING.BUTTON_SECONDARY'),
                    type: 'link',
                    callback: () => {
                        this.inlineNotificationConfig = this.getInfoNotification();
                    }
                }
            ]
        };
    }
    clearErrors() {
        this.hasCreateError = false;
        this.isDuplicateSnapshot = false;
    }
}
exports.CreateSnapshotComponent = CreateSnapshotComponent;
