"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IncidentDeviceSummaryListImplService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const table_store_1 = require("@mimecast-ui/table-store");
class IncidentDeviceSummaryListImplService {
    constructor(http) {
        this.http = http;
    }
    getData(payload, pagination = table_store_1.defaultPaginationOption) {
        return this.http
            .post('/api/swg/get-remediation-results', Object.assign(Object.assign({}, payload), { meta: { pagination } }))
            .pipe(operators_1.map((response) => {
            return {
                hasErrors: response.hasErrors,
                data: response.first.remediationResults,
                failures: response.fail,
                meta: response.meta
            };
        }), operators_1.catchError((response) => {
            return rxjs_1.of({
                hasErrors: response.hasErrors,
                data: response.all,
                failures: response.fail,
                meta: response.meta
            });
        }));
    }
    filter(filterBy, pagination) {
        return this.getData(filterBy, pagination);
    }
    openItem() {
        return null;
    }
}
exports.IncidentDeviceSummaryListImplService = IncidentDeviceSummaryListImplService;
