"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClearIpAddressesAction = exports.RemoveIpAddressAction = exports.AddIpAddressAction = exports.SavePermissionTypeAction = exports.CLEAR_IP_ADDRESSES = exports.REMOVE_IP_ADDRESS = exports.ADD_IP_ADDRESS = exports.SAVE_PERMISSION_TYPE = void 0;
exports.SAVE_PERMISSION_TYPE = '[IdentityPolicyWizard IpAddressesStep] Save Permission Type';
exports.ADD_IP_ADDRESS = '[IdentityPolicyWizard IpAddressesStep] Add Ip Address';
exports.REMOVE_IP_ADDRESS = '[IdentityPolicyWizard IpAddressesStep] Remove Ip Address';
exports.CLEAR_IP_ADDRESSES = '[IdentityPolicyWizard IpAddressesStep] Clear Ip Addresses';
class SavePermissionTypeAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SAVE_PERMISSION_TYPE;
    }
}
exports.SavePermissionTypeAction = SavePermissionTypeAction;
class AddIpAddressAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.ADD_IP_ADDRESS;
    }
}
exports.AddIpAddressAction = AddIpAddressAction;
class RemoveIpAddressAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.REMOVE_IP_ADDRESS;
    }
}
exports.RemoveIpAddressAction = RemoveIpAddressAction;
class ClearIpAddressesAction {
    constructor() {
        this.type = exports.CLEAR_IP_ADDRESSES;
    }
}
exports.ClearIpAddressesAction = ClearIpAddressesAction;
