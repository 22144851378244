"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyncAndRecoverOverviewModalService = void 0;
const store_1 = require("@ngrx/store");
const sync_and_recover_overview_action_1 = require("../actions/sync-and-recover-overview.action");
const selectors_1 = require("../selectors");
const i0 = require("@angular/core");
const i1 = require("@ngrx/store");
class SyncAndRecoverOverviewModalService {
    constructor(store) {
        this.store = store;
        this.store.pipe(store_1.select(selectors_1.isModalOpen)).subscribe(open => (this._isModalOpen = open));
    }
    get isModalOpen() {
        return this._isModalOpen;
    }
    openModal() {
        this.store.dispatch(new sync_and_recover_overview_action_1.SyncAndRecoverOverviewOpenModal());
    }
    closeModal() {
        this.store.dispatch(new sync_and_recover_overview_action_1.SyncAndRecoverOverviewCloseModal());
    }
}
exports.SyncAndRecoverOverviewModalService = SyncAndRecoverOverviewModalService;
SyncAndRecoverOverviewModalService.ɵprov = i0.ɵɵdefineInjectable({ factory: function SyncAndRecoverOverviewModalService_Factory() { return new SyncAndRecoverOverviewModalService(i0.ɵɵinject(i1.Store)); }, token: SyncAndRecoverOverviewModalService, providedIn: "root" });
