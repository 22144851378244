"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MepRemoveAffectedUsersStep = exports.MepEveryoneAffectedUsersStep = exports.MepClearAffectedUsersNotificaiton = exports.MepClearAffectedUsersWarning = exports.MepUpdateAffectedUsersStep = exports.MepUpdateEnabledStatus = exports.MepDetailDeleteConfirmModalClose = exports.MepDetailDeleteConfirmModalOpen = exports.MepSaveStatusPolicyFailure = exports.MepSaveStatusPolicySuccess = exports.MepSaveStatusPolicy = exports.MepDeletePolicyFailure = exports.MepDeletePolicySuccess = exports.MepClearPolicy = exports.MepDeletePolicy = exports.MepSavePolicyFailure = exports.MepSavePolicySuccess = exports.MepSavePolicy = exports.MepPolicyDetailsUpdate = exports.MepEditPolicyFailure = exports.MepEditPolicySuccess = exports.MepEditPolicy = exports.MepPolicyOpenForEditing = exports.MepPolicyLoadForEditingSuccess = exports.MepPolicyLoadForEditingFailure = exports.MepPolicyLoadForEditing = exports.MepPolicyGoToEditPage = exports.MepCreatePolicy = exports.MepGetPolicy = exports.MepPolicyActionsEnums = void 0;
var MepPolicyActionsEnums;
(function (MepPolicyActionsEnums) {
    MepPolicyActionsEnums["MEP_POLICY_GET_POLICY"] = "[MEP Policy] Mep Get Policy";
    MepPolicyActionsEnums["MEP_POLICY_CREATE"] = "[MEP Policy] Mep Create Policy";
    MepPolicyActionsEnums["MEP_CLEAR_POLICY"] = "[MEP Policy] Mep Clear Policy";
    MepPolicyActionsEnums["MEP_POLICY_GO_TO_EDIT_PAGE"] = "[MEP Policy] Mep Go To Edit Page";
    MepPolicyActionsEnums["MEP_POLICY_LOAD_FOR_EDITING"] = "[MEP Policy] Mep Load Policy For Editing";
    MepPolicyActionsEnums["MEP_POLICY_LOAD_FOR_EDITING_FAILURE"] = "[MEP Policy] Mep Load Policy For Editing Failure";
    MepPolicyActionsEnums["MEP_POLICY_LOAD_FOR_EDITING_SUCCESS"] = "[MEP Policy] Mep Load Policy For Editing Success";
    MepPolicyActionsEnums["MEP_POLICY_OPEN_FOR_EDITING"] = "[MEP Policy] Mep Open Policy For Editing";
    MepPolicyActionsEnums["MEP_UPDATE_ENABLED_STATUS"] = "[MEP Policy] Mep Update Enabled status";
    MepPolicyActionsEnums["MEP_UPDATE_AFFECTED_USERS_STEP"] = "[MEP Policy] Mep Update Affected User";
    MepPolicyActionsEnums["MEP_CLEAR_AFFECTED_USERS_WARNING"] = "[MEP Policy] Mep Clear Affected User Warning";
    MepPolicyActionsEnums["MEP_CLEAR_AFFECTED_USERS_NOTIFICATION"] = "[MEP Policy] Mep Clear Affected User Notification";
    MepPolicyActionsEnums["MEP_EVERYONE_AFFECTED_USERS_STEP"] = "[MEP Policy] Mep Everyone Affected User";
    MepPolicyActionsEnums["MEP_REMOVE_AFFECTED_USERS_STEP"] = "[MEP Policy]Remove Affected Users Step";
    MepPolicyActionsEnums["MEP_POLICY_DETAILS_UPDATE"] = "[MEP Policy] Mep Update Policy Details";
    MepPolicyActionsEnums["MEP_POLICY_SAVE"] = "[MEP Policy] Mep Save Policy";
    MepPolicyActionsEnums["MEP_POLICY_SAVE_SUCCESS"] = "[MEP Policy] Mep Save Success Policy";
    MepPolicyActionsEnums["MEP_POLICY_SAVE_FAILURE"] = "[MEP Policy] Mep Save Failure Policy";
    MepPolicyActionsEnums["MEP_POLICY_EDIT_POLICY"] = "[MEP Policy] Mep Edit Policy";
    MepPolicyActionsEnums["MEP_POLICY_EDIT_POLICY_SUCCESS"] = "[MEP Policy] Mep Edit Policy Success";
    MepPolicyActionsEnums["MEP_POLICY_EDIT_POLICY_FAILURE"] = "[MEP Policy] Mep Edit Policy Failure";
    MepPolicyActionsEnums["MEP_POLICY_DELETE"] = "[MEP Policy] Mep Delete Policy";
    MepPolicyActionsEnums["MEP_POLICY_DELETE_SUCCESS"] = "[MEP Policy] Mep Delete Policy Success";
    MepPolicyActionsEnums["MEP_POLICY_DELETE_FAILURE"] = "[MEP Policy] Mep Delete Policy Failure";
    MepPolicyActionsEnums["MEP_POLICY_SAVE_STATUS"] = "[MEP Policy] Mep Save status  Policy";
    MepPolicyActionsEnums["MEP_POLICY_SAVE_STATUS_SUCCESS"] = "[MEP Policy] Mep Save status Policy Success";
    MepPolicyActionsEnums["MEP_POLICY_SAVE_STATUS_FAILURE"] = "[MEP Policy] Mep Save status Policy Failure";
    MepPolicyActionsEnums["MEP_POLICY_DELETE_CONFIRM_MODAL_OPEN"] = "[MEP Policy] MEP Delete Policy Confirm Modal Open";
    MepPolicyActionsEnums["MEP_POLICY_DELETE_CONFIRM_MODAL_CLOSE"] = "[MEP Policy] MEP Delete Policy Confirm Modal Close";
})(MepPolicyActionsEnums = exports.MepPolicyActionsEnums || (exports.MepPolicyActionsEnums = {}));
class MepGetPolicy {
    constructor() {
        this.type = MepPolicyActionsEnums.MEP_POLICY_GET_POLICY;
    }
}
exports.MepGetPolicy = MepGetPolicy;
class MepCreatePolicy {
    constructor() {
        this.type = MepPolicyActionsEnums.MEP_POLICY_CREATE;
    }
}
exports.MepCreatePolicy = MepCreatePolicy;
class MepPolicyGoToEditPage {
    constructor() {
        this.type = MepPolicyActionsEnums.MEP_POLICY_GO_TO_EDIT_PAGE;
    }
}
exports.MepPolicyGoToEditPage = MepPolicyGoToEditPage;
class MepPolicyLoadForEditing {
    constructor(id) {
        this.id = id;
        this.type = MepPolicyActionsEnums.MEP_POLICY_LOAD_FOR_EDITING;
    }
}
exports.MepPolicyLoadForEditing = MepPolicyLoadForEditing;
class MepPolicyLoadForEditingFailure {
    constructor() {
        this.type = MepPolicyActionsEnums.MEP_POLICY_LOAD_FOR_EDITING_FAILURE;
    }
}
exports.MepPolicyLoadForEditingFailure = MepPolicyLoadForEditingFailure;
class MepPolicyLoadForEditingSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = MepPolicyActionsEnums.MEP_POLICY_LOAD_FOR_EDITING_SUCCESS;
    }
}
exports.MepPolicyLoadForEditingSuccess = MepPolicyLoadForEditingSuccess;
class MepPolicyOpenForEditing {
    constructor(payload) {
        this.payload = payload;
        this.type = MepPolicyActionsEnums.MEP_POLICY_OPEN_FOR_EDITING;
    }
}
exports.MepPolicyOpenForEditing = MepPolicyOpenForEditing;
class MepEditPolicy {
    constructor(payload) {
        this.payload = payload;
        this.type = MepPolicyActionsEnums.MEP_POLICY_EDIT_POLICY;
    }
}
exports.MepEditPolicy = MepEditPolicy;
class MepEditPolicySuccess {
    constructor() {
        this.type = MepPolicyActionsEnums.MEP_POLICY_EDIT_POLICY_SUCCESS;
    }
}
exports.MepEditPolicySuccess = MepEditPolicySuccess;
class MepEditPolicyFailure {
    constructor() {
        this.type = MepPolicyActionsEnums.MEP_POLICY_EDIT_POLICY_FAILURE;
    }
}
exports.MepEditPolicyFailure = MepEditPolicyFailure;
class MepPolicyDetailsUpdate {
    constructor(payload) {
        this.payload = payload;
        this.type = MepPolicyActionsEnums.MEP_POLICY_DETAILS_UPDATE;
    }
}
exports.MepPolicyDetailsUpdate = MepPolicyDetailsUpdate;
class MepSavePolicy {
    constructor(payload) {
        this.payload = payload;
        this.type = MepPolicyActionsEnums.MEP_POLICY_SAVE;
    }
}
exports.MepSavePolicy = MepSavePolicy;
class MepSavePolicySuccess {
    constructor() {
        this.type = MepPolicyActionsEnums.MEP_POLICY_SAVE_SUCCESS;
    }
}
exports.MepSavePolicySuccess = MepSavePolicySuccess;
class MepSavePolicyFailure {
    constructor() {
        this.type = MepPolicyActionsEnums.MEP_POLICY_SAVE_FAILURE;
    }
}
exports.MepSavePolicyFailure = MepSavePolicyFailure;
class MepDeletePolicy {
    constructor(id) {
        this.id = id;
        this.type = MepPolicyActionsEnums.MEP_POLICY_DELETE;
    }
}
exports.MepDeletePolicy = MepDeletePolicy;
class MepClearPolicy {
    constructor() {
        this.type = MepPolicyActionsEnums.MEP_CLEAR_POLICY;
    }
}
exports.MepClearPolicy = MepClearPolicy;
class MepDeletePolicySuccess {
    constructor() {
        this.type = MepPolicyActionsEnums.MEP_POLICY_DELETE_SUCCESS;
    }
}
exports.MepDeletePolicySuccess = MepDeletePolicySuccess;
class MepDeletePolicyFailure {
    constructor() {
        this.type = MepPolicyActionsEnums.MEP_POLICY_DELETE_FAILURE;
    }
}
exports.MepDeletePolicyFailure = MepDeletePolicyFailure;
class MepSaveStatusPolicy {
    constructor() {
        this.type = MepPolicyActionsEnums.MEP_POLICY_SAVE_STATUS;
    }
}
exports.MepSaveStatusPolicy = MepSaveStatusPolicy;
class MepSaveStatusPolicySuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = MepPolicyActionsEnums.MEP_POLICY_SAVE_STATUS_SUCCESS;
    }
}
exports.MepSaveStatusPolicySuccess = MepSaveStatusPolicySuccess;
class MepSaveStatusPolicyFailure {
    constructor() {
        this.type = MepPolicyActionsEnums.MEP_POLICY_SAVE_STATUS_FAILURE;
    }
}
exports.MepSaveStatusPolicyFailure = MepSaveStatusPolicyFailure;
class MepDetailDeleteConfirmModalOpen {
    constructor(payload) {
        this.payload = payload;
        this.type = MepPolicyActionsEnums.MEP_POLICY_DELETE_CONFIRM_MODAL_OPEN;
    }
}
exports.MepDetailDeleteConfirmModalOpen = MepDetailDeleteConfirmModalOpen;
class MepDetailDeleteConfirmModalClose {
    constructor() {
        this.type = MepPolicyActionsEnums.MEP_POLICY_DELETE_CONFIRM_MODAL_CLOSE;
    }
}
exports.MepDetailDeleteConfirmModalClose = MepDetailDeleteConfirmModalClose;
class MepUpdateEnabledStatus {
    constructor(payload) {
        this.payload = payload;
        this.type = MepPolicyActionsEnums.MEP_UPDATE_ENABLED_STATUS;
    }
}
exports.MepUpdateEnabledStatus = MepUpdateEnabledStatus;
class MepUpdateAffectedUsersStep {
    constructor(payload) {
        this.payload = payload;
        this.type = MepPolicyActionsEnums.MEP_UPDATE_AFFECTED_USERS_STEP;
    }
}
exports.MepUpdateAffectedUsersStep = MepUpdateAffectedUsersStep;
class MepClearAffectedUsersWarning {
    constructor() {
        this.type = MepPolicyActionsEnums.MEP_CLEAR_AFFECTED_USERS_WARNING;
    }
}
exports.MepClearAffectedUsersWarning = MepClearAffectedUsersWarning;
class MepClearAffectedUsersNotificaiton {
    constructor() {
        this.type = MepPolicyActionsEnums.MEP_CLEAR_AFFECTED_USERS_NOTIFICATION;
    }
}
exports.MepClearAffectedUsersNotificaiton = MepClearAffectedUsersNotificaiton;
class MepEveryoneAffectedUsersStep {
    constructor(payload) {
        this.payload = payload;
        this.type = MepPolicyActionsEnums.MEP_EVERYONE_AFFECTED_USERS_STEP;
    }
}
exports.MepEveryoneAffectedUsersStep = MepEveryoneAffectedUsersStep;
class MepRemoveAffectedUsersStep {
    constructor(payload) {
        this.payload = payload;
        this.type = MepPolicyActionsEnums.MEP_REMOVE_AFFECTED_USERS_STEP;
    }
}
exports.MepRemoveAffectedUsersStep = MepRemoveAffectedUsersStep;
