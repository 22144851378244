"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SFCScannedFileTypesComponent = void 0;
const scanned_file_types_static_value_1 = require("./scanned-file-types.static-value");
class SFCScannedFileTypesComponent {
    constructor() {
        this.CHART_NAME = 'scanned_file_types';
        this.chartConfig = scanned_file_types_static_value_1.pieChartConfig;
    }
    exportChart(format) {
        this.pieChartComponent.exportChart(format, this.CHART_NAME);
    }
}
exports.SFCScannedFileTypesComponent = SFCScannedFileTypesComponent;
