"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ2 = exports.ɵ1 = exports.ɵ0 = exports.MailboxModule = void 0;
const ɵ0 = ($injector) => $injector.get('$state'), ɵ1 = ($injector) => $injector.get('$aside'), ɵ2 = ($injector) => $injector.get('emailViewService');
exports.ɵ0 = ɵ0;
exports.ɵ1 = ɵ1;
exports.ɵ2 = ɵ2;
class MailboxModule {
}
exports.MailboxModule = MailboxModule;
