"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GwsDomainDelegationStepComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const store_1 = require("@ngrx/store");
const step_base_component_1 = require("../../../../step.base.component");
const selectors = require("../../../../../../reducers");
const model_1 = require("../../../../../../model");
const connector_wizard_service_1 = require("../../../../../../services/connector-wizard.service");
const constants = require("../gws-provider-static-values");
const operators_1 = require("rxjs/operators");
const rxjs_1 = require("rxjs");
const connector_actions_1 = require("../../../../../../actions/connector.actions");
class GwsDomainDelegationStepComponent extends step_base_component_1.StepBaseDirective {
    constructor(store, fb, translateService, featureFlagService, coreService) {
        super(store, fb, model_1.ConnectorStep.CONSENT, connector_wizard_service_1.ConnectorWizardService.WizardId);
        this.store = store;
        this.fb = fb;
        this.translateService = translateService;
        this.featureFlagService = featureFlagService;
        this.coreService = coreService;
        this.googleDomainDelegationPageLink = constants.GOOGLE_DOMAIN_DELEGATION_LINK;
        this.navigate = new core_1.EventEmitter();
        this.buildForm();
    }
    ngOnInit() {
        super.ngOnInit();
        const ready$ = rxjs_1.from(this.featureFlagService.isReady());
        this.supportLink$ = ready$.pipe(operators_1.switchMap(() => this.coreService.getAccountSwitchSession().pipe(operators_1.take(1))), operators_1.map(switchedSession => {
            const hasConfigEnabled = this.featureFlagService.getBooleanFlag('rollout-zendesk-contact-support');
            const encodedAccountCode = switchedSession && switchedSession.accountcode
                ? `?ac=${encodeURIComponent(btoa(switchedSession.accountcode))}`
                : '';
            return hasConfigEnabled
                ? `/apps#/sso/support${encodedAccountCode}`
                : '/apps#sso/mcentral?goto=/s/contactsupport';
        }));
        this.isLoadingConsentConfig$ = this.store.pipe(store_1.select(selectors.isLoadingConsentConfig));
        this.isValidatingConsent$ = this.store.pipe(store_1.select(selectors.isValidatingConsent));
        this.isConsentValidationFailed$ = this.store.pipe(store_1.select(selectors.isConsentValidationFailed));
        this.validatedConsent$ = this.store.pipe(store_1.select(selectors.getValidatedConsent));
        this.setConsentConfigResponse();
        this.setValidateConsentApiTrigger();
        this.setValidateConsentApiResponse();
    }
    ngOnDestroy() {
        this.validateConsentApiTrigger.unsubscribe();
        this.consentConfigDataTrigger.unsubscribe();
        this.validatedConsent.unsubscribe();
    }
    getPermissions() {
        return this.permissions;
    }
    getScopeCopyData() {
        return this.permissions ? this.permissions.join(',') : '';
    }
    getConsentValidationMessage() {
        return this.consentValidationFailedMessage;
    }
    setValidateConsentApiResponse() {
        this.validatedConsent = this.validatedConsent$.subscribe(consent => {
            const token = consent.authToken;
            if (consent.authToken) {
                /* To Navigate Connector details step need to provide index of Step number 6 */
                this.navigate.emit(6);
            }
            else if (consent.errorCode) {
                this.isTriggerConsentValidationApi = true;
                this.setTranslatedConsentErrorMessage(consent.errorCode);
            }
            this.form.patchValue({ authToken: token });
        });
    }
    setValidateConsentApiTrigger() {
        this.validateConsentApiTrigger = this.store
            .pipe(store_1.select(selectors.getWizardById(connector_wizard_service_1.ConnectorWizardService.WizardId)))
            .pipe(operators_1.filter(wizard => model_1.ConnectorStep.DETAILS === wizard.currentStep &&
            this.isTriggerConsentValidationApi &&
            this.isNextButtonClicked))
            .subscribe(wizard => {
            /* To Navigate domain delegation step need to provide index of Step number 5 */
            this.navigate.emit(5);
            this.isTriggerConsentValidationApi = false;
            const validateRequest = this.buildValidateConsentRequest(wizard.steps[model_1.ConnectorStep.GWS_CUSTOMER_INFORMATION]);
            this.store.dispatch(new connector_actions_1.ValidateConsentAction(validateRequest));
        });
    }
    setConsentConfigResponse() {
        this.consentConfigDataTrigger = this.store
            .pipe(store_1.select(selectors.getConsentConfig))
            .pipe(operators_1.filter((config) => config && config.contextParameters))
            .subscribe(config => {
            this.isTriggerConsentValidationApi = true;
            this.consentConfig = config;
            this.setConsentParameters();
        });
    }
    setConsentParameters() {
        const contextParameters = this.consentConfig.contextParameters;
        this.permissions = contextParameters[constants.PERMISSIONS];
        this.form.patchValue({
            serviceAccountClientId: contextParameters[constants.CLIENT_ID]
        });
    }
    buildValidateConsentRequest(customerInfoState) {
        return {
            mailbox: customerInfoState.data[constants.MAILBOX],
            customerId: customerInfoState.data[constants.CUSTOMER_ID],
            state: this.consentConfig.contextParameters[constants.STATE]
        };
    }
    setTranslatedConsentErrorMessage(errorCode) {
        this.consentValidationFailedMessage = this.translateService
            .instant('$I18N_CREATE_CONNECTOR_WIZARD_GWS_DOMAIN_DELEGATION_STEP.CONSENT_FAILED.MESSAGE')
            .concat(this.translateService.instant('$I18N_CREATE_CONNECTOR_WIZARD_GWS_DOMAIN_DELEGATION_STEP.CONSENT_FAILED.CODE.'.concat(errorCode.toUpperCase()), { url: this.supportLink$ }));
    }
    buildForm() {
        this.form = this.fb.group({
            serviceAccountClientId: [null, forms_1.Validators.required],
            scopes: [null],
            authToken: [null]
        });
    }
}
exports.GwsDomainDelegationStepComponent = GwsDomainDelegationStepComponent;
