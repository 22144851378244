<div class="mc-customized-block">
  <div class="mc-top-banner"></div>
  <div class="body-background">
    <div class="mc-content-body col-md-offset-2 col-md-8">
      <div class="mc-block-badge mc-faded">
        <mc-feedback-badge
          status="error"
          text="$I18N_SWG_BLOCK_PAGE_BLOCKED_SITE_TITLE"
        ></mc-feedback-badge>
      </div>

        <div class="mc-aw">
          <div class="mc-aw-title">
            {{ '$I18N_SWG_BLOCK_PAGE_SETTINGS_TITLE_PLACEHOLDER' | translate}}
          </div>

          <div class="mc-aw-desc">
            {{ '$I18N_SWG_BLOCK_PAGE_BLOCKED_SITE_REASON' | translate }} <br />
            {{ '$I18N_SWG_BLOCK_PAGE_BLOCKED_SITE' | translate}}
          </div>
        </div>

        <div class="mc-security-tips">
          <div class="mc-security-tips-title">{{ '$I18N_SWG_AW_CUSTOM_PAGE_WEB_SECURITY_TIPS' | translate }}</div>
        </div>

        <div class="mc-aw-footer">
          <div class="mc-aw-desc">
           <p [innerHTML]="getFooterText() | mcLinkify"></p>
          </div>
        </div>
    </div>
  </div>

  <div class="mc-bottom-banner"></div>
</div>

