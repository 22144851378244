<div *ngIf="associatedPolicyDetails && associatedPolicyDetails.name; else noPolicyFound">
  <div class="row mc-detailed-list-row no-padding-top">
    <div class="mc-sidebar-label mc-detailed-list-label">
      {{ '$I18N_LOG_SIDEBAR.ASSOCIATED_POLICY.LABELS.NAME' | translate }}
    </div>
    <div
      class="mc-detailed-list-column"
      data-test="mepLogSidebarAssociatedPolicyName"
    >
      {{ associatedPolicyDetails.name }}
    </div>
  </div>
</div>
<ng-template #noPolicyFound>
  <div class="no-data-found">
    <mc-inline-notification [notification]="{
            status: 'info',
            boxed: true,
            msg: '$I18N_LOG_SIDEBAR.ASSOCIATED_POLICY.NO_POLICY_FOUND' | translate
        }"></mc-inline-notification>
  </div>
</ng-template>
