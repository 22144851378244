"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppDetailsComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const operators_1 = require("rxjs/operators");
class AppDetailsComponent {
    constructor(formBuilder, translateService, notificationService) {
        this.formBuilder = formBuilder;
        this.translateService = translateService;
        this.notificationService = notificationService;
        this.formChangeWaitPeriod = 250;
        this.update = new core_1.EventEmitter();
        this.valid = new core_1.EventEmitter();
        this.edit = new core_1.EventEmitter();
        this.appAsideForm = this.formBuilder.group({
            name: ['', [forms_1.Validators.required, forms_1.Validators.maxLength(100)]],
            acsUrl: ['', forms_1.Validators.required],
            logoutUrl: ''
        });
    }
    // eslint-disable-next-line @angular-eslint/no-input-rename
    set appValue(selectedApp) {
        this.app = selectedApp;
    }
    ngOnInit() {
        this.spSettingsInlineNotification = {
            status: 'warning',
            boxed: true,
            msg: this.translateService.instant('$I18N_IDENTITY_APP_DETAILS.SERVICE_PROVIDER_SETTINGS.INLINE_NOTIFICATION')
        };
        const action = {
            label: this.translateService.instant('$I18N_IDENTITY_APP_DETAILS.BUTTONS.EDIT'),
            type: 'button',
            callback: () => {
                this.edit.emit();
            }
        };
        this.spSettingsInlineNotificationWithAction = {
            status: 'warning',
            boxed: true,
            msg: this.translateService.instant('$I18N_IDENTITY_APP_DETAILS.SERVICE_PROVIDER_SETTINGS.INLINE_NOTIFICATION'),
            actions: [action]
        };
        this.providerSettingsInlineNotification = {
            status: 'warning',
            boxed: true,
            msg: this.translateService.instant('$I18N_IDENTITY_APP_DETAILS.PROVIDER_SETTINGS.INLINE_NOTIFICATION')
        };
        this.appAsideFormSubscription = this.appAsideForm.valueChanges
            .pipe(operators_1.debounceTime(this.formChangeWaitPeriod), operators_1.distinctUntilChanged())
            .subscribe((formValue) => {
            formValue.id = this.app.id;
            this.update.emit(formValue);
            this.valid.emit(this.appAsideForm.valid);
        });
    }
    ngAfterViewInit() {
        this.patchAppDetails();
    }
    ngOnDestroy() {
        if (!!this.appAsideFormSubscription && !this.appAsideFormSubscription.closed) {
            this.appAsideFormSubscription.unsubscribe();
        }
    }
    onClipboardSuccess(formElementName) {
        this.notificationService.success({
            msg: this.translateService.instant(formElementName) + ' copied'
        });
    }
    patchAppDetails() {
        if (!!this.app) {
            this.appAsideForm.patchValue({
                name: this.app.name,
                acsUrl: this.app.acsUrl,
                logoutUrl: this.app.logoutUrl
            });
            this.appAsideForm.markAsPristine();
        }
    }
}
exports.AppDetailsComponent = AppDetailsComponent;
