<div class="mc-threat-information-details">
    <mc-spam-details-info
       [messageType] = "messageType"
        [spamInfo] = "spamInfo"
        [messageInfoRoute] = "messageInfoRoute"
        [deliveredMessage] = "deliveredMessage">
    </mc-spam-details-info>
    <hr/>

    <mc-processing-details
        [processingDetails] = "spamInfo && spamInfo.spamProcessingDetail">
    </mc-processing-details>
    <hr/>

    <mc-threat-analysis
        [processingDetails] = "spamInfo && spamInfo.spamProcessingDetail">
    </mc-threat-analysis>
</div>
