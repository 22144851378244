<mc-layout-aside-extra-container keyTitle="$18N_ATTRIBUTES_DIRECTORIES.ASIDE.TITLE" showClose="true"
                                 [isLoading]="isLoadingAttributeDetails$ | async" (closeAside)="closeAside()">

  <mc-extra-container>
    <div class="mc-aside-header">
      <span class="mc-aside-sub-label">{{ '$18N_ATTRIBUTES_DIRECTORIES.ASIDE.SUBTITLE' | translate }}</span>&nbsp;
      <span class="mc-aside-sub-header">{{ attributeData ? attributeData.prompt : '' }}</span>

      <mc-paginator class="mc-aside-inline-paginator"
                    *ngIf="mode === 'VIEW' && pagination$ | async"
                    [start]="(pagination$ | async).currentIndex + 1"
                    [end]="(pagination$ | async).pageSize"
                    [isLoading]="isLoadingAttributeDetails$ | async"
                    [previousToken]="(pagination$ | async).prevRow"
                    [nextToken]="(pagination$ | async).nextRow"
                    (paginate)="showAttribute($event)">
      </mc-paginator>
    </div>

    <div *ngIf="isEditable" class="mc-container-buttons-area">
      <button class="mc-table-actions btn btn-primary mc-create-button" (click)="toggleMode()" *ngIf="!isEditMode()">
        {{ '$18N_ATTRIBUTES_DIRECTORIES.ASIDE.EDIT' | translate }}
      </button>
      <button class="mc-table-actions btn btn-primary mc-create-button" (click)="saveAttribute()" *ngIf="isEditMode()" [disabled]="isInvalid">
        {{ '$18N_ATTRIBUTES_DIRECTORIES.ASIDE.SAVE' | translate }}
      </button>
      <button class="mc-table-actions btn btn-secondary" (click)="cancel()" *ngIf="isEditMode()">
        {{ '$18N_ATTRIBUTES_DIRECTORIES.ASIDE.CANCEL' | translate }}
      </button>
      <button class="mc-table-actions btn btn-secondary" (click)="deleteAttribute()" *ngIf="!isEditMode()">
        {{ '$18N_ATTRIBUTES_DIRECTORIES.ASIDE.DELETE' | translate }}
      </button>
    </div>

  </mc-extra-container>

  <mc-body-container>
    <mc-detail-view-form [data]="attributeData" [translationKeyPrefix]="PREFIX_KEY" [mode]="mode" (saveData)="updateFormData($event)"></mc-detail-view-form>
  </mc-body-container>
</mc-layout-aside-extra-container>
