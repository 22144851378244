"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tableSectionFiltersBrowserIsolationExtended = exports.tableSectionFiltersExtended = exports.tableSectionFilters = exports.tableColumnsStartHidden = exports.tableColumnsToIgnore = exports.tableSectionSearch = exports.tableColumnsAlwaysVisible = exports.exportColumns = exports.tableColumns = exports.tableSelectColumn = void 0;
exports.tableSelectColumn = 'select';
exports.tableColumns = [
    'url',
    'fromUserEmailAddress',
    'userEmailAddress',
    'subject',
    'route',
    'ttpDefinition',
    'scanResult',
    'actions',
    'adminOverride',
    'userOverride',
    'userAwarenessAction',
    'date',
    'right-column'
];
exports.exportColumns = [
    'url',
    'fromUserEmailAddress',
    'userEmailAddress',
    'subject',
    'route',
    'ttpDefinition',
    'scanResult',
    'actions',
    'adminOverride',
    'userOverride',
    'category',
    'userAwarenessAction',
    'date',
    'creationMethod'
];
exports.tableColumnsAlwaysVisible = ['url', 'scanResult', 'actions', 'date'];
exports.tableSectionSearch = {
    field: 'search',
    header: 'header',
    options: ['from', 'to', 'url']
};
exports.tableColumnsToIgnore = ['select'];
exports.tableColumnsStartHidden = ['subject', 'ttpDefinition', 'route'];
exports.tableSectionFilters = [
    {
        name: 'action',
        displayName: 'VIEW_BY_ACTION',
        filters: [
            {
                name: 'allow',
                displayName: 'INBOUND_ALLOW'
            },
            {
                name: 'warn',
                displayName: 'INBOUND_WARN'
            },
            {
                name: 'block',
                displayName: 'INBOUND_BLOCK'
            },
            {
                name: 'unknown',
                displayName: 'INBOUND_UNKNOWN'
            }
        ]
    },
    {
        name: 'scanresult',
        displayName: 'VIEW_BY_SCAN_RESULT',
        filters: [
            {
                name: 'clean',
                displayName: 'SCAN_RESULT_CLEAN_URLS'
            },
            {
                name: 'suspicious',
                displayName: 'SCAN_RESULT_SUSPICIOUS_URLS'
            },
            {
                name: 'malicious',
                displayName: 'SCAN_RESULT_MALICIOUS_URLS'
            },
            {
                name: 'scan_timeout',
                displayName: 'SCAN_RESULT_SCANNER_TIMEOUT_URLS'
            },
            {
                name: 'invalid_url',
                displayName: 'SCAN_RESULT_INVALID_URLS'
            }
        ]
    },
    {
        name: 'userawareness',
        displayName: 'VIEW_BY_USER_AWARENESS',
        filters: [
            {
                name: 'correctly_continue',
                displayName: 'USER_AWARENESS_CORRECTLY_IDENTIFIED_CONTINUE'
            },
            {
                name: 'incorrectly_continue',
                displayName: 'USER_AWARENESS_INCORRECTLY_IDENTIFIED_CONTINUE'
            },
            {
                name: 'correctly_exit',
                displayName: 'USER_AWARENESS_CORRECTLY_IDENTIFIED_EXIT'
            },
            {
                name: 'incorrectly_exit',
                displayName: 'USER_AWARENESS_INCORRECTLY_IDENTIFIED_EXIT'
            },
            {
                name: 'none',
                displayName: 'USER_AWARENESS_NA'
            }
        ]
    },
    {
        name: 'adminoverride',
        displayName: 'VIEW_BY_ADMIN_OVERRIDE',
        filters: [
            {
                name: 'allow',
                displayName: 'ADMIN_OVERRIDE_ALLOW'
            },
            {
                name: 'block',
                displayName: 'ADMIN_OVERRIDE_BLOCK'
            }
        ]
    },
    {
        name: 'useroverride',
        displayName: 'VIEW_BY_USER_OVERRIDE',
        filters: [
            {
                name: 'continue',
                displayName: 'USER_OVERRIDE_CONTINUE'
            },
            {
                name: 'none',
                displayName: 'USER_OVERRIDE_NONE'
            }
        ]
    }
];
exports.tableSectionFiltersExtended = [
    {
        name: 'action',
        displayName: 'VIEW_BY_ACTION',
        filters: [
            {
                name: 'allow',
                displayName: 'INBOUND_ALLOW'
            },
            {
                name: 'warn',
                displayName: 'INBOUND_WARN'
            },
            {
                name: 'block',
                displayName: 'INBOUND_BLOCK'
            },
            {
                name: 'unknown',
                displayName: 'INBOUND_UNKNOWN'
            },
            {
                name: 'none_none',
                displayName: 'OUTBOUND_NONE_NONE'
            },
            {
                name: 'hold_none',
                displayName: 'OUTBOUND_HOLD_NONE'
            },
            {
                name: 'bounce_none',
                displayName: 'OUTBOUND_BOUNCE_NONE'
            },
            {
                name: 'internal_none',
                displayName: 'JOURNAL_NONE'
            },
            {
                name: 'internal_email',
                displayName: 'JOURNAL_REMOVE_MESSAGE'
            }
        ]
    },
    {
        name: 'routing',
        displayName: 'VIEW_BY_ROUTING',
        filters: [
            {
                name: 'inbound',
                displayName: 'ROUTING_INBOUND'
            },
            {
                name: 'outbound',
                displayName: 'ROUTING_OUTBOUND'
            },
            {
                name: 'internal',
                displayName: 'ROUTING_JOURNAL'
            }
        ]
    },
    {
        name: 'scanresult',
        displayName: 'VIEW_BY_SCAN_RESULT',
        filters: [
            {
                name: 'clean',
                displayName: 'SCAN_RESULT_CLEAN_URLS'
            },
            {
                name: 'suspicious',
                displayName: 'SCAN_RESULT_SUSPICIOUS_URLS'
            },
            {
                name: 'malicious',
                displayName: 'SCAN_RESULT_MALICIOUS_URLS'
            },
            {
                name: 'scan_timeout',
                displayName: 'SCAN_RESULT_SCANNER_TIMEOUT_URLS'
            },
            {
                name: 'invalid_url',
                displayName: 'SCAN_RESULT_INVALID_URLS'
            }
        ]
    },
    {
        name: 'userawareness',
        displayName: 'VIEW_BY_USER_AWARENESS',
        filters: [
            {
                name: 'correctly_continue',
                displayName: 'USER_AWARENESS_CORRECTLY_IDENTIFIED_CONTINUE'
            },
            {
                name: 'incorrectly_continue',
                displayName: 'USER_AWARENESS_INCORRECTLY_IDENTIFIED_CONTINUE'
            },
            {
                name: 'correctly_exit',
                displayName: 'USER_AWARENESS_CORRECTLY_IDENTIFIED_EXIT'
            },
            {
                name: 'incorrectly_exit',
                displayName: 'USER_AWARENESS_INCORRECTLY_IDENTIFIED_EXIT'
            },
            {
                name: 'none',
                displayName: 'USER_AWARENESS_NA'
            }
        ]
    },
    {
        name: 'adminoverride',
        displayName: 'VIEW_BY_ADMIN_OVERRIDE',
        filters: [
            {
                name: 'allow',
                displayName: 'ADMIN_OVERRIDE_ALLOW'
            },
            {
                name: 'block',
                displayName: 'ADMIN_OVERRIDE_BLOCK'
            }
        ]
    },
    {
        name: 'useroverride',
        displayName: 'VIEW_BY_USER_OVERRIDE',
        filters: [
            {
                name: 'continue',
                displayName: 'USER_OVERRIDE_CONTINUE'
            },
            {
                name: 'none',
                displayName: 'USER_OVERRIDE_NONE'
            }
        ]
    }
];
exports.tableSectionFiltersBrowserIsolationExtended = [
    {
        name: 'browser_isolation',
        displayName: 'BROWSER_ISOLATION'
    },
    {
        name: 'browser_isolation_error',
        displayName: 'BROWSER_ISOLATION_ERROR'
    }
];
