"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgPolicySummaryTargetsComponent = void 0;
const models_1 = require("../../models");
class SwgPolicySummaryTargetsComponent {
    constructor() {
        this.locations = [];
        this.users = [];
        this.groups = [];
        this.channels = [];
        this.devices = [];
        this.deviceGroups = [];
    }
    ngOnInit() {
        this.update();
    }
    ngOnChanges(changes) {
        const change = changes['affectedUsers'];
        if (change && !change.isFirstChange()) {
            this.update();
        }
    }
    update() {
        this.locations = [];
        this.users = [];
        this.groups = [];
        this.channels = [];
        this.devices = [];
        this.deviceGroups = [];
        for (const target of this.affectedUsers) {
            if (target.condition === models_1.SwgAffectedUsersEnum.GROUP ||
                target.condition === 'clp_notifications_group') {
                // @ts-ignore
                this.groups.push(target.payload);
            }
            if (target.condition === 'clp_notifications_channel') {
                // @ts-ignore
                this.channels.push(target.teamsChannel);
            }
            if (target.condition === models_1.SwgAffectedUsersEnum.LOCATION) {
                this.locations.push(target.payload);
            }
            if (target.condition === models_1.SwgAffectedUsersEnum.USER ||
                target.condition === 'clp_notifications_user') {
                // @ts-ignore
                this.users.push(target.payload);
            }
            if (target.condition === models_1.SwgAffectedUsersEnum.WEB_DEVICE) {
                this.devices.push(target.device);
            }
            if (target.condition === models_1.SwgAffectedUsersEnum.WEB_DEVICE_GROUP) {
                this.deviceGroups.push(target.deviceGroup);
            }
        }
        // @ts-ignore
        this.users.sort((x, y) => (x.emailAddress > y.emailAddress ? 1 : -1));
        // @ts-ignore
        this.groups.sort((x, y) => (x.description > y.description ? 1 : -1));
        this.locations.sort((x, y) => (x.description > y.description ? 1 : -1));
    }
}
exports.SwgPolicySummaryTargetsComponent = SwgPolicySummaryTargetsComponent;
