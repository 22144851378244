<mc-layout-detail-simple keyTitle="$I18N_SERVICES_CREATE_DEFINITION.HEADER.TITLE"
    keyDescription="$I18N_SERVICES_CREATE_DEFINITION.HEADER.SUB_TITLE">
    <mc-body-container>
        <mc-extra-container>
            <button (click)="createDefinition('save_and_exit')" class="btn btn-primary"
                [disabled]="!apDefinitionForm.valid">
                {{'$I18N_SERVICES_CREATE_DEFINITION_BUTTON.SAVE_AND_EXIT' | translate}}
            </button>
            <button (click)="createDefinition('save_only')" class="btn btn-default"
                [disabled]="!apDefinitionForm.valid">
                {{'$I18N_SERVICES_CREATE_DEFINITION_BUTTON.SAVE' | translate}}
            </button>
            <button class="btn btn-default" (click)="goBackToDefinition()">
                {{'$I18N_SERVICES_CREATE_DEFINITION_BUTTON.CANCEL_BUTTON' | translate}}
            </button>
        </mc-extra-container>
        <div class="mc-def-form-block">
            <form class="form-horizontal" mcDefaultLabelClass="col-sm-4" mcDefaultControlContainerClass="col-sm-8"
                [formGroup]="apDefinitionForm">
                <div class="mc-text-thick mc-ap-def-inbound-text">
                    {{'$I18N_SERVICES_CREATE_DEFINITION.GENERALSETTINGS.LABEL' | translate}}</div>
                <mc-text-field formControlName="description"
                    label="$I18N_SERVICES_CREATE_DEFINITION.DEFINITION_NARRATIVE.LABEL"
                    errorPrefix="$I18N_SERVICES_CREATE_DEFINITION.VALIDATION.DEFINITION_NARRATIVE" required>
                </mc-text-field>
                <hr class="mc-group-separator" />
                <div class="mc-text-thick mc-ap-def-inbound-text">
                    {{'$I18N_SERVICES_CREATE_DEFINITION.INBOUND_SETTINGS.LABEL' | translate}}</div>
                <span>{{"$I18N_SERVICES_CREATE_DEFINITION.INBOUND_SETTINGS.DESCRIPTION" | translate}}</span>
                <mc-field label="$I18N_SERVICES_CREATE_DEFINITION.INBOUND_SETTINGS.ENABLE_INBOUND_CHECK.LABEL"
                    helpPopoverContent="{{'$I18N_SERVICES_CREATE_DEFINITION.INBOUND_SETTINGS.ENABLE_INBOUND_CHECK.HELP_POPOVER' | translate}}">
                    <mc-checkbox formControlName="checkInbound" class="mc-ap-checkbox"></mc-checkbox>
                </mc-field>
                <ng-container *ngIf="isInboundCheck()">
                    <div>
                        <ng-container *ngIf="!hasIEPPackage">
                            <mc-field label="$I18N_SERVICES_CREATE_DEFINITION.INBOUND_SETTINGS.AP_DELIVERY.LABEL"
                                helpPopoverContent="{{'$I18N_SERVICES_CREATE_DEFINITION.INBOUND_SETTINGS.AP_DELIVERY.HELP_POPOVER_PREEXEMPTIVE' | translate}}">
                                <mc-select [options]="configurationOptions" formControlName="action"
                                    [useTranslation]="true">
                                </mc-select>
                            </mc-field>
                        </ng-container>
                        <ng-container *ngIf="hasIEPPackage">
                            <mc-field label="$I18N_SERVICES_CREATE_DEFINITION.INBOUND_SETTINGS.AP_DELIVERY.LABEL"
                                [helpPopoverContent]="deliveryOptionPopoverContent">
                                <mc-select [options]="configurationOptions" formControlName="action"
                                    [useTranslation]="true"></mc-select>
                            </mc-field>
                        </ng-container>
                        <ng-container *ngIf="apDefinitionForm.get('action').value !== 'sandbox'">
                            <mc-field
                                label="$I18N_SERVICES_CREATE_DEFINITION.INBOUND_SETTINGS.AP_DELIVERY.IGNORE_SIGNED_MESSEGES.LABEL"
                                helpPopoverContent="{{'$I18N_SERVICES_CREATE_DEFINITION.INBOUND_SETTINGS.AP_DELIVERY.IGNORE_SIGNED_MESSEGES.HELP_POPOVER' | translate}}">
                                <mc-checkbox formControlName="ignoreSignedMessages" (change)="onChangeValue($event)"
                                    class="mc-ap-checkbox"></mc-checkbox>
                            </mc-field>
                        </ng-container>
                        <ng-container
                            *ngIf="apDefinitionForm.get('action').value !==  'safedoc_and_link' && apDefinitionForm.get('action').value !==  'safedoc_only'">
                            <mc-field
                                label="$I18N_SERVICES_CREATE_DEFINITION.INBOUND_SETTINGS.SANDBOX_FALLBACK_ACTION.LABEL"
                                [helpPopoverContent]="sandboxFallbackAction">
                                <div class="form-control-children"></div>
                                <mc-select [options]="preEmptiveFailureActionOptions"
                                    formControlName="preEmptiveFailureAction"></mc-select>
                            </mc-field>
                        </ng-container>
                        <mc-field
                            label="$I18N_SERVICES_CREATE_DEFINITION.INBOUND_SETTINGS.RELEASE_FORWARDED_INTERNAL_ATTACHMENT.LABEL"
                            helpPopoverContent="{{'$I18N_SERVICES_CREATE_DEFINITION.INBOUND_SETTINGS.RELEASE_FORWARDED_INTERNAL_ATTACHMENT.HELP_POPOVER' | translate}}">
                            <mc-checkbox formControlName="internalForward" class="mc-ap-checkbox"></mc-checkbox>
                        </mc-field>
                        <ng-container *ngIf="(displayStripAndLinkEncAttachments(apDefinitionForm.get('action').value))
                            && (hasStripAndLinkEncAttachments$ | async)">
                            <mc-field
                                    label="$I18N_SERVICES_CREATE_DEFINITION.INBOUND_SETTINGS.STRIP_AND_LINK_ENCRYPTED_ATTACHMENTS.LABEL"
                                    [helpPopoverContent]="stripAndLinkEncAttachmentsPopoverContent">
                                <mc-checkbox formControlName="sandboxEncryptionPortalEnabled" class="mc-ap-checkbox"></mc-checkbox>
                            </mc-field>
                        </ng-container>
                        <ng-container *ngIf="apDefinitionForm.get('action').value === 'safedoc_and_link'">
                            <div class="mc-text-thick mc-ap-def-inbound-text">
                                {{'$I18N_SERVICES_CREATE_DEFINITION.INBOUND_SETTINGS.ADMIN_UNSAFE_NOTIFICATION.LABEL'
                                | translate}}</div>
                            <mc-field
                                label="$I18N_SERVICES_CREATE_DEFINITION.INBOUND_SETTINGS.ADMIN_UNSAFE_NOTIFICATION.ADMIN_NOTIFICATION">
                                <mc-checkbox formControlName="notifyOnMalicious" class="mc-ap-checkbox"></mc-checkbox>
                            </mc-field>
                            <ng-container *ngIf="apDefinitionForm.get('notifyOnMalicious').value === true">
                                <div class="mc-select-group-field-review">
                                    <label class="mc-select-label">
                                        {{'$I18N_SERVICES_CREATE_DEFINITION.COMMON_SETTINGS.ADMINISTRATORS.ADMIN_REVIEW_GROUP'
                                        |
                                        translate}}
                                    </label>
                                    <button class="btn btn-secondary mc-select-notification"
                                        (click)="openSidebar('inbound')">
                                        {{
                                        '$I18N_SERVICES_CREATE_DEFINITION.COMMON_SETTINGS.ADMINISTRATORS.SELECT_GROUP_BUTTON'|
                                        translate }}
                                    </button>
                                </div>
                                <div class="mc-selected-group" *ngIf="selectGroupsLength">
                                    <div class="mc-selected-group-display">
                                        <div class="mc-groups-list">{{ selectGroupsLength.description | translate }}
                                        </div>
                                        <div class="mc-delete-group">
                                            <i class="far fa-trash-alt" aria-hidden="true"
                                                (click)="onDeleteGroup(selectGroupsLength, 'inbound')"></i>
                                        </div>
                                    </div>
                                    <hr class="mc-group-separator" />
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container
                            *ngIf="apDefinitionForm.get('action').value !== 'safedoc_only' && apDefinitionForm.get('action').value !== 'safedoc_and_link'">
                            <div class="mc-text-thick mc-ap-def-inbound-text">
                                {{'$I18N_SERVICES_CREATE_DEFINITION.INBOUND_SETTINGS.BOUNCE_REVIEW_NOTIFICATION.LABEL'
                                | translate}}</div>
                            <span>{{'$I18N_SERVICES_CREATE_DEFINITION.INBOUND_SETTINGS.BOUNCE_REVIEW_NOTIFICATION.DESCRIPTION'
                                | translate}}</span>
                            <mc-field label="$I18N_SERVICES_CREATE_DEFINITION.INBOUND_SETTINGS.NOTIFICATIONS.LABEL"
                                helpPopoverContent="{{'$I18N_SERVICES_CREATE_DEFINITION.INBOUND_SETTINGS.NOTIFICATIONS.HELP_POPOVER' | translate}}">
                                <mc-checkbox formControlName="notifyOnMalicious" class="mc-ap-checkbox"></mc-checkbox>
                            </mc-field>
                            <ng-container *ngIf="apDefinitionForm.get('notifyOnMalicious').value === true">
                                <ng-container *ngIf="!isAAAOrFAAUser">
                                    <div class="mc-select-group-field-review">
                                        <label class="mc-select-label">
                                            {{'$I18N_SERVICES_CREATE_DEFINITION.COMMON_SETTINGS.ADMINISTRATORS.LABEL' |
                                            translate}}
                                        </label>
                                        <button class="btn btn-secondary mc-select-notification"
                                            (click)="openSidebar('inbound')">
                                            {{
                                            '$I18N_SERVICES_CREATE_DEFINITION.COMMON_SETTINGS.ADMINISTRATORS.SELECT_GROUP_BUTTON'|
                                            translate }}
                                        </button>
                                    </div>
                                    <div class="mc-selected-group" *ngIf="selectGroupsLength">
                                        <div class="mc-selected-group-display">
                                            <div class="mc-groups-list">{{ selectGroupsLength.description | translate }}
                                            </div>
                                            <div class="mc-delete-group">
                                                <i class="far fa-trash-alt" aria-hidden="true"
                                                    (click)="onDeleteGroup(selectGroupsLength, 'inbound')"></i>
                                            </div>
                                        </div>
                                        <hr class="mc-group-separator" />
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="apDefinitionForm.get('action').value !== 'safedoc_and_link'">
                                    <mc-field
                                        label="$I18N_SERVICES_CREATE_DEFINITION.INBOUND_SETTINGS.NOTIFICATIONS.INTERNAL_SENDER.LABEL"
                                        helpPopoverContent="{{'$I18N_SERVICES_CREATE_DEFINITION.INBOUND_SETTINGS.NOTIFICATIONS.INTERNAL_SENDER.HELP_POPOVER' | translate}}">
                                        <mc-checkbox formControlName="notifyInternalSender" class="mc-ap-checkbox">
                                        </mc-checkbox>
                                    </mc-field>
                                    <mc-field
                                        label="$I18N_SERVICES_CREATE_DEFINITION.INBOUND_SETTINGS.NOTIFICATIONS.INTERNAL_RECIPIENT.LABEL"
                                        helpPopoverContent="{{'$I18N_SERVICES_CREATE_DEFINITION.INBOUND_SETTINGS.NOTIFICATIONS.INTERNAL_RECIPIENT.HELP_POPOVER' | translate}}">
                                        <mc-checkbox formControlName="notifyInternalRecipient" class="mc-ap-checkbox">
                                        </mc-checkbox>
                                    </mc-field>
                                    <mc-field
                                        label="$I18N_SERVICES_CREATE_DEFINITION.INBOUND_SETTINGS.NOTIFICATIONS.EXTERNAL_SENDER.LABEL"
                                        helpPopoverContent="{{'$I18N_SERVICES_CREATE_DEFINITION.INBOUND_SETTINGS.NOTIFICATIONS.EXTERNAL_SENDER.HELP_POPOVER' | translate}}">
                                        <mc-checkbox formControlName="notifyExternalSender" class="mc-ap-checkbox">
                                        </mc-checkbox>
                                    </mc-field>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="apDefinitionForm.get('action').value !== 'sandbox'">
                            <div class="mc-text-thick mc-ap-def-inbound-text">
                                {{'$I18N_SERVICES_CREATE_DEFINITION.INBOUND_SETTINGS.DOCUMENTS_TRANSCRIPTION.LABEL'
                                | translate}}</div>
                            <mc-field
                                label="$I18N_SERVICES_CREATE_DEFINITION.INBOUND_SETTINGS.DOCUMENTS_TRANSCRIPTION.DEFAULT_TRANSCRIBED.LABEL"
                                helpPopoverContent="{{'$I18N_SERVICES_CREATE_DEFINITION.INBOUND_SETTINGS.DOCUMENTS_TRANSCRIPTION.DEFAULT_TRANSCRIBED.HELP_POPOVER' | translate}}">
                                <mc-select [options]="documentFormatOptions" formControlName="safeDocFormat"
                                    [useTranslation]="true"></mc-select>
                            </mc-field>
                            <mc-field
                                label="$I18N_SERVICES_CREATE_DEFINITION.INBOUND_SETTINGS.DOCUMENTS_TRANSCRIPTION.DEFAULT_SPREADSHEET.LABEL"
                                helpPopoverContent="{{'$I18N_SERVICES_CREATE_DEFINITION.INBOUND_SETTINGS.DOCUMENTS_TRANSCRIPTION.DEFAULT_SPREADSHEET.HELP_POPOVER' | translate}}">
                                <mc-select [options]="SpreadsheetsFormatOptions" formControlName="safeSpreadsheetFormat"
                                    [useTranslation]="true"></mc-select>
                            </mc-field>
                            <ng-container *ngIf="apDefinitionForm.get('safeSpreadsheetFormat').value === 'csv'">
                                <mc-field
                                    label="$I18N_SERVICES_CREATE_DEFINITION.INBOUND_SETTINGS.DOCUMENTS_TRANSCRIPTION.WORKSHEETS.LABEL"
                                    helpPopoverContent="{{'$I18N_SERVICES_CREATE_DEFINITION.INBOUND_SETTINGS.DOCUMENTS_TRANSCRIPTION.WORKSHEETS.HELP_POPOVER' | translate}}">
                                    <mc-select [options]="SpreadsheetsWorksheetsOptions"
                                        formControlName="firstSheetOnly" [useTranslation]="true"></mc-select>
                                </mc-field>
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-container>
                <ng-container *ngIf="(hasIEPPackage || isAAAOrFAAUser)">
                    <hr class="mc-group-separator" />
                    <div class="mc-text-thick mc-ap-def-inbound-text">
                        {{'$I18N_SERVICES_CREATE_DEFINITION.OUTBOUND_SETTINGS.LABEL' | translate}}</div>
                    <span>{{"$I18N_SERVICES_CREATE_DEFINITION.OUTBOUND_SETTINGS.DESCRIPTION" | translate}}</span>
                    <mc-field label="$I18N_SERVICES_CREATE_DEFINITION.OUTBOUND_SETTINGS.ENABLE_OUTBOUND_CHECK.LABEL"
                        helpPopoverContent="{{'$I18N_SERVICES_CREATE_DEFINITION.OUTBOUND_SETTINGS.ENABLE_OUTBOUND_CHECK.HELP_POPOVER' | translate}}">
                        <mc-checkbox formControlName="checkOutbound" class="mc-ap-checkbox"></mc-checkbox>
                    </mc-field>
                    <ng-container *ngIf="isOutboundCheck()">
                        <mc-field label="$I18N_SERVICES_CREATE_DEFINITION.OUTBOUND_SETTINGS.GATEWAY_ACTION.LABEL"
                            [helpPopoverContent]="gatewayActions">
                            <mc-select [options]="gatewayActionOptions" formControlName="outboundAction"
                                [useTranslation]="true"></mc-select>
                        </mc-field>
                        <mc-field
                            label="$I18N_SERVICES_CREATE_DEFINITION.OUTBOUND_SETTINGS.GATEWAY_FALLBACK_ACTION.LABEL"
                            [helpPopoverContent]="gatewayActions">
                            <mc-select [options]="gatewayFallbackActionOptions" formControlName="outboundFallbackAction"
                                [useTranslation]="true"></mc-select>
                        </mc-field>
                        <mc-field label="$I18N_SERVICES_CREATE_DEFINITION.OUTBOUND_SETTINGS.USER_MAILBOX_ACTION.LABEL"
                            [helpPopoverContent]="mailboxActions">
                            <mc-select [options]="userMailboxOptions" formControlName="outboundRemediationAction"
                                [useTranslation]="true"></mc-select>
                        </mc-field>
                        <mc-field
                            label="$I18N_SERVICES_CREATE_DEFINITION.OUTBOUND_SETTINGS.USER_MAILBOX_FALLBACK_ACTION.LABEL"
                            [helpPopoverContent]="mailboxActions">
                            <mc-select [options]="userMailboxFallbackActionOptions"
                                formControlName="outboundFallbackRemediationAction" [useTranslation]="true"></mc-select>
                        </mc-field>
                        <div class="mc-text-thick mc-ap-def-inbound-text">
                            {{'$I18N_SERVICES_CREATE_DEFINITION.OUTBOUND_SETTINGS.NOTIFICATION.LABEL'
                            | translate}}</div>
                        <mc-field label="$I18N_SERVICES_CREATE_DEFINITION.OUTBOUND_SETTINGS.NOTIFICATIONS.LABEL"
                            helpPopoverContent="{{'$I18N_SERVICES_CREATE_DEFINITION.OUTBOUND_SETTINGS.NOTIFICATIONS.HELP_POPOVER' | translate}}">
                            <mc-checkbox formControlName="outboundNotifyOnMalicious" class="mc-ap-checkbox">
                            </mc-checkbox>
                        </mc-field>
                        <ng-container *ngIf="apDefinitionForm.get('outboundNotifyOnMalicious').value === true">
                            <ng-container *ngIf="!isAAAOrFAAUser">
                                <div class="mc-select-group-field-notify">
                                    <label class="mc-select-label">
                                        {{'$I18N_SERVICES_CREATE_DEFINITION.COMMON_SETTINGS.NOTIFY_GROUP.LABEL' |
                                        translate}}
                                    </label>
                                    <button class="btn btn-secondary mc-select-notification"
                                        (click)="openSidebar('outbound')">
                                        {{
                                        '$I18N_SERVICES_CREATE_DEFINITION.COMMON_SETTINGS.NOTIFY_GROUP.SELECT_GROUP_BUTTON'|
                                        translate }}
                                    </button>
                                </div>
                                <div class="mc-selected-group" *ngIf="selectGroupsLengthOutbound">
                                    <div class="mc-selected-group-display">
                                        <div class="mc-groups-list">{{ selectGroupsLengthOutbound.description |
                                            translate }}
                                        </div>
                                        <div class="mc-delete-group">
                                            <i class="far fa-trash-alt" aria-hidden="true"
                                                (click)="onDeleteGroup(selectGroupsLengthOutbound, 'outbound')"></i>
                                        </div>
                                    </div>
                                    <hr class="mc-group-separator" />
                                </div>
                            </ng-container>
                            <mc-field
                                label="$I18N_SERVICES_CREATE_DEFINITION.OUTBOUND_SETTINGS.NOTIFICATIONS.VALUES.INTERNAL_SENDER.LABEL"
                                helpPopoverContent="{{'$I18N_SERVICES_CREATE_DEFINITION.OUTBOUND_SETTINGS.NOTIFICATIONS.VALUES.INTERNAL_SENDER.HELP_POPOVER' | translate}}">
                                <mc-checkbox formControlName="outboundNotifyInternalSender" class="mc-ap-checkbox">
                                </mc-checkbox>
                            </mc-field>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="(hasIEPPackage || isAAAOrFAAUser)">
                    <hr class="mc-group-separator" />
                    <div class="mc-text-thick mc-ap-def-inbound-text">
                        {{'$I18N_SERVICES_CREATE_DEFINITION.JOURNAL_SETTINGS.LABEL' | translate}}</div>
                    <span>{{"$I18N_SERVICES_CREATE_DEFINITION.JOURNAL_SETTINGS.DESCRIPTION" | translate}}</span>
                    <mc-field label="$I18N_SERVICES_CREATE_DEFINITION.JOURNAL_SETTINGS.ENABLE_JOURNAL_CHECK.LABEL"
                        helpPopoverContent="{{'$I18N_SERVICES_CREATE_DEFINITION.JOURNAL_SETTINGS.ENABLE_JOURNAL_CHECK.HELP_POPOVER' | translate}}">
                        <mc-checkbox formControlName="checkJournal" class="mc-ap-checkbox"></mc-checkbox>
                    </mc-field>
                    <ng-container *ngIf="isJournalCheck()">
                        <mc-field label="$I18N_SERVICES_CREATE_DEFINITION.JOURNAL_SETTINGS.USER_MAILBOX_ACTION.LABEL"
                            [helpPopoverContent]="mailboxActions">
                            <mc-select [options]="userMailboxOptions" formControlName="journalRemediationAction"
                                [useTranslation]="true"></mc-select>
                        </mc-field>
                        <mc-field
                            label="$I18N_SERVICES_CREATE_DEFINITION.JOURNAL_SETTINGS.USER_MAILBOX_FALLBACK_ACTION.LABEL"
                            [helpPopoverContent]="mailboxActions">
                            <mc-select [options]="userMailboxFallbackActionOptions"
                                formControlName="journalFallbackRemediationAction" [useTranslation]="true"></mc-select>
                        </mc-field>
                        <div class="mc-text-thick mc-ap-def-inbound-text">
                            {{'$I18N_SERVICES_CREATE_DEFINITION.JOURNAL_SETTINGS.NOTIFICATION.LABEL'
                            | translate}}</div>

                        <mc-field label="$I18N_SERVICES_CREATE_DEFINITION.JOURNAL_SETTINGS.NOTIFICATIONS.LABEL"
                            helpPopoverContent="{{'$I18N_SERVICES_CREATE_DEFINITION.JOURNAL_SETTINGS.NOTIFICATIONS.HELP_POPOVER' | translate}}">
                            <mc-checkbox formControlName="journalNotifyOnMalicious" class="mc-ap-checkbox">
                            </mc-checkbox>
                        </mc-field>
                        <ng-container *ngIf="apDefinitionForm.get('journalNotifyOnMalicious').value === true">
                            <ng-container *ngIf="!isAAAOrFAAUser">
                                <div class="mc-select-group-field-notify">
                                    <label class="mc-select-label">
                                        {{'$I18N_SERVICES_CREATE_DEFINITION.COMMON_SETTINGS.NOTIFY_GROUP.LABEL' |
                                        translate}}
                                    </label>
                                    <button class="btn btn-secondary mc-select-notification"
                                        (click)="openSidebar('journal')">
                                        {{
                                        '$I18N_SERVICES_CREATE_DEFINITION.COMMON_SETTINGS.NOTIFY_GROUP.SELECT_GROUP_BUTTON'|
                                        translate }}
                                    </button>
                                </div>
                                <div class="mc-selected-group" *ngIf="selectGroupsLengthJournal">
                                    <div class="mc-selected-group-display">
                                        <div class="mc-groups-list">{{ selectGroupsLengthJournal.description | translate
                                            }}
                                        </div>
                                        <div class="mc-delete-group">
                                            <i class="far fa-trash-alt" aria-hidden="true"
                                                (click)="onDeleteGroup(selectGroupsLengthJournal, 'journal')"></i>
                                        </div>
                                    </div>
                                    <hr class="mc-group-separator" />
                                </div>
                            </ng-container>
                            <mc-field
                                label="$I18N_SERVICES_CREATE_DEFINITION.JOURNAL_SETTINGS.NOTIFICATIONS.VALUES.INTERNAL_SENDER.LABEL"
                                helpPopoverContent="{{'$I18N_SERVICES_CREATE_DEFINITION.JOURNAL_SETTINGS.NOTIFICATIONS.VALUES.INTERNAL_SENDER.HELP_POPOVER' | translate}}">
                                <mc-checkbox formControlName="journalNotifyInternalSender" class="mc-ap-checkbox">
                                </mc-checkbox>
                            </mc-field>
                            <mc-field
                                label="$I18N_SERVICES_CREATE_DEFINITION.JOURNAL_SETTINGS.NOTIFICATIONS.VALUES.INTERNAL_RECIPIENT.LABEL"
                                helpPopoverContent="{{'$I18N_SERVICES_CREATE_DEFINITION.JOURNAL_SETTINGS.NOTIFICATIONS.VALUES.INTERNAL_RECIPIENT.HELP_POPOVER' | translate}}">
                                <mc-checkbox formControlName="journalNotifyInternalRecipient" class="mc-ap-checkbox">
                                </mc-checkbox>
                            </mc-field>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </form>
        </div>
    </mc-body-container>
</mc-layout-detail-simple>

<ng-template #deliveryOptionPopoverContent>
    <p>{{ '$I18N_SERVICES_CREATE_DEFINITION.DELIVERY_OPTION_POPOVER.TITLE' | translate }}</p>
    <p>
        <strong>{{ '$I18N_SERVICES_CREATE_DEFINITION.DELIVERY_OPTION_POPOVER.SAFE_FILE' | translate
            }}</strong>
        {{ '$I18N_SERVICES_CREATE_DEFINITION.DELIVERY_OPTION_POPOVER.SAFE_FILE_DESCRIPTION' |
        translate }}
    </p>
    <p>
        <strong>{{ '$I18N_SERVICES_CREATE_DEFINITION.DELIVERY_OPTION_POPOVER.SAFE_FILE_WITH_ONDEMAND' | translate
            }}</strong>
        {{ '$I18N_SERVICES_CREATE_DEFINITION.DELIVERY_OPTION_POPOVER.SAFE_FILE_WITH_ONDEMAND_DESCRIPTION' |
        translate }}
    </p>
    <p>
        <strong>{{ '$I18N_SERVICES_CREATE_DEFINITION.DELIVERY_OPTION_POPOVER.PRE_EMPTIVE_SANDBOX' | translate
            }}</strong>
        {{ '$I18N_SERVICES_CREATE_DEFINITION.DELIVERY_OPTION_POPOVER.PRE_EMPTIVE_SANDBOX_DESCRIPTION' |
        translate }}
    </p>
    <p>
        <strong>{{ '$I18N_SERVICES_CREATE_DEFINITION.DELIVERY_OPTION_POPOVER.DYNAMIC_CONFIGURATION' | translate
            }}</strong>
        <span>
            {{ '$I18N_SERVICES_CREATE_DEFINITION.DELIVERY_OPTION_POPOVER.DYNAMIC_CONFIGURATION_DESCRIPTION' |
            translate }}
        </span>
    </p>
</ng-template>

<ng-template #sandboxFallbackAction>
    <p>{{ '$I18N_SERVICES_CREATE_DEFINITION.SANDBOX_FALLBACK_ACTION_POPOVER.TITLE' | translate }}</p>
    <ul>
        <li><strong>{{ '$I18N_SERVICES_CREATE_DEFINITION.SANDBOX_FALLBACK_ACTION_POPOVER.ADMIN_REVIEW' | translate
                }}</strong>
            {{ '$I18N_SERVICES_CREATE_DEFINITION.SANDBOX_FALLBACK_ACTION_POPOVER.ADMIN_REVIEW_DESCRIPTION' |
            translate }}</li>
        <li><strong>{{ '$I18N_SERVICES_CREATE_DEFINITION.SANDBOX_FALLBACK_ACTION_POPOVER.BOUNCE' | translate
                }}</strong>
            {{ '$I18N_SERVICES_CREATE_DEFINITION.SANDBOX_FALLBACK_ACTION_POPOVER.BOUNCE_DESCRIPTION' |
            translate }}</li>
    </ul>
    <p>
        <strong>{{ '$I18N_SERVICES_CREATE_DEFINITION.SANDBOX_FALLBACK_ACTION_POPOVER.DEFAULT_SETTINGS' | translate
            }}</strong>
        <span>
            {{ '$I18N_SERVICES_CREATE_DEFINITION.SANDBOX_FALLBACK_ACTION_POPOVER.DEFAULT_SETTINGS_DESCRIPTION' |
            translate }}
        </span>
    </p>
</ng-template>

<ng-template #gatewayActions>
    <p>{{ '$I18N_SERVICES_CREATE_DEFINITION.GATEWAY_ACTION.TITLE' | translate }}</p>
    <p>
        <strong>{{ '$I18N_SERVICES_CREATE_DEFINITION.GATEWAY_ACTION.NONE' | translate
            }}</strong>
        {{ '$I18N_SERVICES_CREATE_DEFINITION.GATEWAY_ACTION.NONE_DESCRIPTION' |
        translate }}
    </p>
    <p>
        <strong>{{ '$I18N_SERVICES_CREATE_DEFINITION.GATEWAY_ACTION.HOLD' | translate
            }}</strong>
        {{ '$I18N_SERVICES_CREATE_DEFINITION.GATEWAY_ACTION.HOLD_DESCRIPTION' |
        translate }}
    </p>
    <p>
        <strong>{{ '$I18N_SERVICES_CREATE_DEFINITION.GATEWAY_ACTION.BOUNCE' | translate
            }}</strong>
        {{ '$I18N_SERVICES_CREATE_DEFINITION.GATEWAY_ACTION.BOUNCE_DESCRIPTION' |
        translate }}
    </p>
</ng-template>

<ng-template #mailboxActions>
    <p>{{ '$I18N_SERVICES_CREATE_DEFINITION.USERS_MAILBOX_ACTIONS.TITLE' | translate }}</p>
    <p>
        <strong>{{ '$I18N_SERVICES_CREATE_DEFINITION.USERS_MAILBOX_ACTIONS.NONE' | translate
            }}</strong>
        {{ '$I18N_SERVICES_CREATE_DEFINITION.USERS_MAILBOX_ACTIONS.NONE_DESCRIPTION' |
        translate }}
    </p>
    <p>
        <strong>{{ '$I18N_SERVICES_CREATE_DEFINITION.USERS_MAILBOX_ACTIONS.REMOVE_ATTACHMENT' | translate
            }}</strong>
        {{ '$I18N_SERVICES_CREATE_DEFINITION.USERS_MAILBOX_ACTIONS.REMOVE_ATTACHMENT_DESCRIPTION' |
        translate }}
    </p>
    <p>
        <strong>{{ '$I18N_SERVICES_CREATE_DEFINITION.USERS_MAILBOX_ACTIONS.REMOVE_MESSAGE' | translate
            }}</strong>
        {{ '$I18N_SERVICES_CREATE_DEFINITION.USERS_MAILBOX_ACTIONS.REMOVE_MESSAGE_DESCRIPTION' |
        translate }}
    </p>
</ng-template>

<ng-template #stripAndLinkEncAttachmentsPopoverContent>
    <p>{{ '$I18N_SERVICES_CREATE_DEFINITION.INBOUND_SETTINGS.STRIP_AND_LINK_ENCRYPTED_ATTACHMENTS.HELP_POPOVER.PARAGRAPH_1' | translate }}</p>
    <p>{{ '$I18N_SERVICES_CREATE_DEFINITION.INBOUND_SETTINGS.STRIP_AND_LINK_ENCRYPTED_ATTACHMENTS.HELP_POPOVER.PARAGRAPH_2' | translate }}</p>
    <p>{{ '$I18N_SERVICES_CREATE_DEFINITION.INBOUND_SETTINGS.STRIP_AND_LINK_ENCRYPTED_ATTACHMENTS.HELP_POPOVER.PARAGRAPH_3' | translate }}</p>
    <p>{{ '$I18N_SERVICES_CREATE_DEFINITION.INBOUND_SETTINGS.STRIP_AND_LINK_ENCRYPTED_ATTACHMENTS.HELP_POPOVER.PARAGRAPH_4' | translate }}</p>
</ng-template>
