"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IdentityDeleteAppFailureAction = exports.IdentityDeleteAppSuccessAction = exports.IdentityDeleteAppAction = exports.IdentityDeleteModalCloseAction = exports.IdentityDeleteModalOpenAction = exports.IdentityAddAppFailureAction = exports.IdentityAddAppSuccessAction = exports.IdentityAddAppAction = exports.IdentityCloseAppAsideAction = exports.IdentityViewAppAsideAction = exports.DELETE_APPS_FAILURE = exports.DELETE_APPS_SUCCESS = exports.DELETE_APPS = exports.CLOSE_DELETE_APP_MODAL = exports.OPEN_DELETE_APP_MODAL = exports.ADD_APP_FAILURE = exports.ADD_APP_SUCCESS = exports.ADD_APP = exports.CLOSE_APP = exports.VIEW_APP_DETAILS = void 0;
// Create/Read/Update
exports.VIEW_APP_DETAILS = '[IdentityApps] View App Aside Panel';
exports.CLOSE_APP = '[IdentityApps] Close App Aside Panel';
exports.ADD_APP = '[IdentityApps] Add App';
exports.ADD_APP_SUCCESS = '[IdentityApps] Add App Successful';
exports.ADD_APP_FAILURE = '[IdentityApps] Add App Failure';
// Delete
exports.OPEN_DELETE_APP_MODAL = '[IdentityApps] Open Delete App Modal';
exports.CLOSE_DELETE_APP_MODAL = '[IdentityApps] Close Delete App Modal';
exports.DELETE_APPS = '[IdentityApps] Delete Apps';
exports.DELETE_APPS_SUCCESS = '[IdentityApps] Delete Apps Success';
exports.DELETE_APPS_FAILURE = '[IdentityApps] Delete Apps Failure';
class IdentityViewAppAsideAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.VIEW_APP_DETAILS;
    }
}
exports.IdentityViewAppAsideAction = IdentityViewAppAsideAction;
class IdentityCloseAppAsideAction {
    constructor() {
        this.type = exports.CLOSE_APP;
    }
}
exports.IdentityCloseAppAsideAction = IdentityCloseAppAsideAction;
class IdentityAddAppAction {
    constructor() {
        this.type = exports.ADD_APP;
    }
}
exports.IdentityAddAppAction = IdentityAddAppAction;
class IdentityAddAppSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.ADD_APP_SUCCESS;
    }
}
exports.IdentityAddAppSuccessAction = IdentityAddAppSuccessAction;
class IdentityAddAppFailureAction {
    constructor(failMessage) {
        this.failMessage = failMessage;
        this.type = exports.ADD_APP_FAILURE;
    }
}
exports.IdentityAddAppFailureAction = IdentityAddAppFailureAction;
class IdentityDeleteModalOpenAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.OPEN_DELETE_APP_MODAL;
    }
}
exports.IdentityDeleteModalOpenAction = IdentityDeleteModalOpenAction;
class IdentityDeleteModalCloseAction {
    constructor() {
        this.type = exports.CLOSE_DELETE_APP_MODAL;
    }
}
exports.IdentityDeleteModalCloseAction = IdentityDeleteModalCloseAction;
class IdentityDeleteAppAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.DELETE_APPS;
    }
}
exports.IdentityDeleteAppAction = IdentityDeleteAppAction;
class IdentityDeleteAppSuccessAction {
    constructor() {
        this.type = exports.DELETE_APPS_SUCCESS;
    }
}
exports.IdentityDeleteAppSuccessAction = IdentityDeleteAppSuccessAction;
class IdentityDeleteAppFailureAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.DELETE_APPS_FAILURE;
    }
}
exports.IdentityDeleteAppFailureAction = IdentityDeleteAppFailureAction;
