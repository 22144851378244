"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
angular.module('dashboards.administration.maximum-retention-filter', [])
    .filter('maxRetention', ['$translate', function ($translate) {
        const maxRetentionFilter = function (maxRetention) {
            const intLimitToBePermanent = 36160;
            if (maxRetention >= intLimitToBePermanent) {
                return $translate.instant('$I18N_DASHBOARD_LOGON_INFORMATION_MAX_RETENTION_PERPETUAL');
            }
            else
                return maxRetention;
        };
        return maxRetentionFilter;
    }])
    .filter('maxRetentionConfirmed', function () {
    const maxRetentionConfirmed = function (maximumRetention) {
        if (maximumRetention) {
            return '$I18N_COMMON_TEXT_YES';
        }
        else
            return '$I18N_COMMON_TEXT_NO';
    };
    return maxRetentionConfirmed;
});
