"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./modal-max-retention-service");
(function () {
    'use strict';
    angular.module('modalMaxRetention.controller', ['modalMaxRetention.service']).controller('ModalMaxRetentionController', [
        'modalMaxRetentionService',
        'growl',
        'accountService',
        '$translate',
        function (modalMaxRetentionService, growl, accountService, $translate) {
            const self = this;
            self.isReady = false;
            self.confirmationRetention = confirmationRetention;
            self.snoozeRetention = snoozeAndAuditAcknowledgement;
            self.account = modalMaxRetentionService.accountCache;
            self.modalConfig = {
                confirmRetentionPermission: false,
                title: '',
                firstLine: '',
                secondLine: '',
                inlineNotificationOptions: undefined
            };
            self.hasPermissions = modalMaxRetentionService.hasPermission('Permission.SETTINGS_CONFIRM_RETENTION') && needsConfirmation(self.account);
            activate();
            function needsConfirmation(account) {
                const needsConf = (account.minRetentionEnabled && account.minRetention && !account.minRetentionConfirmed)
                    || (account.maxRetention && !account.maxRetentionConfirmed);
                return needsConf;
            }
            function activate() {
                const retentionData = {};
                if (self.account.maxRetention) {
                    retentionData['intMaxRetention'] = self.account.maxRetention;
                    retentionData['maxRetentionConfirmed'] = self.account.maxRetentionConfirmed;
                }
                if (self.account.minRetention) {
                    retentionData['intMinRetention'] = self.account.minRetention;
                    retentionData['minRetentionConfirmed'] = self.account.minRetentionConfirmed;
                }
                retentionData['conflicts'] = self.account.conflictingPolicies;
                modalMaxRetentionService.getModalContent(retentionData).then((modalConfig) => {
                    self.modalConfig = modalConfig;
                    self.isReady = true;
                });
            }
            function confirmationRetention(blnValue) {
                if (angular.isDefined(blnValue)) {
                    modalMaxRetentionService.confirmRetention(blnValue).then((responseMaxRetention) => {
                        if (responseMaxRetention.err) {
                            self.modalConfig.inlineNotificationOptions.messageBoxAlertTypeClass = 'alert-danger';
                            self.modalConfig.inlineNotificationOptions.messageBoxIconClass = 'fas fa-exclamation-triangle';
                            self.modalConfig.inlineNotificationOptions.messageBoxTitle = '$I18N_MODAL_RETENTION_ERROR_MESSAGE_TITLE';
                            self.modalConfig.inlineNotificationOptions.messageBoxVisibility = true;
                            self.modalConfig.inlineNotificationOptions.messageBoxHideCloseButton = false;
                            self.modalConfig.inlineNotificationOptions.messageBoxContent = accountService.getError().getFirstKey(responseMaxRetention);
                        }
                        else {
                            modalMaxRetentionService.snoozeRetention();
                            if (blnValue) {
                                const strMsg = '<strong>' + $translate.instant('$I18N_MODAL_RETENTION_SUCCESS_MESSAGE_FIRST') + '</strong>';
                                growl.success(strMsg, { ttl: 5000 });
                            }
                        }
                    }, () => {
                        accountService.getError().showErrorNotification();
                    });
                }
            }
            function snoozeAndAuditAcknowledgement() {
                modalMaxRetentionService.snoozeRetention();
                modalMaxRetentionService.auditRetentionAction();
            }
        }
    ]);
})();
