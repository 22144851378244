"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserLocationAppliesToClose = exports.UserLocationAppliesToOpen = exports.UserLocationSidebarActionsEnum = void 0;
var UserLocationSidebarActionsEnum;
(function (UserLocationSidebarActionsEnum) {
    UserLocationSidebarActionsEnum["Applies_to_POLICY_APPLIES_TO_OPEN"] = "[Policy] Applies_to applies to open";
    UserLocationSidebarActionsEnum["Applies_to_POLICY_APPLIES_TO_CLOSE"] = "[Policy] Applies_to applies to close";
})(UserLocationSidebarActionsEnum = exports.UserLocationSidebarActionsEnum || (exports.UserLocationSidebarActionsEnum = {}));
class UserLocationAppliesToOpen {
    constructor(payload) {
        this.payload = payload;
        this.type = UserLocationSidebarActionsEnum.Applies_to_POLICY_APPLIES_TO_OPEN;
    }
}
exports.UserLocationAppliesToOpen = UserLocationAppliesToOpen;
class UserLocationAppliesToClose {
    constructor() {
        this.type = UserLocationSidebarActionsEnum.Applies_to_POLICY_APPLIES_TO_CLOSE;
    }
}
exports.UserLocationAppliesToClose = UserLocationAppliesToClose;
