'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const moment = require("moment");
angular.module('isoFromNow.filter', [])
    .filter('isoFromNow', function () {
    return function (dateString) {
        if (dateString != '' && dateString != undefined) {
            return moment(dateString, 'YYYY-MM-DDTHH:mm:ss.SSSSZ').fromNow();
        }
        return undefined;
    };
});
