<mc-modal-header headerTitle="Delete App Confirmation"></mc-modal-header>

<mc-modal-body *ngIf="app$ | async; let app">
  {{ '$I18N_IDENTITY_DELETE_APP_CONFIRMATION' | translate }} <b>{{ app.name }}</b>?
</mc-modal-body>

<mc-modal-footer *ngIf="app$ | async; let app">
  <button type="button"
          class="btn btn-secondary mc-app-cancel"
          (click)="onCancel()">
    {{ 'Cancel'}}
  </button>

  <mc-live-button overrideClasses="btn-primary mc-app-delete"
                  [label]="'$I18N_COMMON_BTN_DELETE' | translate"
                  [isLoading]="isDeleting$ | async"
                  (mcClick)="onDelete(app)">
  </mc-live-button>
</mc-modal-footer>
