<mc-table
  class="mc-application-table"
  translatePrefix="$I18N_SWG_APPLICATIONS.TABLE"
  [scrollable]="true"
  [scrollHeight]="height"
  [showHighlightedRow]="false"
  [sortableColumns]="sortableColumns"
  (sort)="sortData($event)"
  [isLoading]="loading" [data]="applicationsInView" [columns]="columns">


  <button class="mc-table-actions btn btn-primary panel-half-margin-right" *ngIf="type!=='sanction'"
          (click)="controlApplication(_applicationStatus.BLOCK)"> {{ '$I18N_SWG_WEB_APPLICATION_CONTROL_BLOCKED' | translate }}
  </button>

  <button class="mc-table-actions btn btn-primary panel-half-margin-right" *ngIf="type!=='sanction' && !hasAppPolicyRemoveAllowEnabled"
          (click)="controlApplication(_applicationStatus.ALLOW)">
    {{ '$I18N_SWG_WEB_APPLICATION_CONTROL_ALLOWED' | translate }}
  </button>

  <button class="mc-table-actions btn btn-secondary " *ngIf="type!=='sanction'"
          (click)="controlApplication(_applicationStatus.NONE)">
    {{ '$I18N_SWG_WEB_APPLICATION_CONTROL_CLEAR_ACTION' | translate }}
  </button>


  <button class="mc-table-actions btn btn-primary panel-half-margin-right" *ngIf="type==='sanction'"
          [disabled]="!hasEditPermission || apiUpdatingEdit"
          (click)="sanctionApplication(_applicationSanction.MONITOR)"> {{ '$I18N_SWG_APPLICATIONS.MONITOR_APPLICATION' | translate }}
  </button>

  <button class="mc-table-actions btn btn-primary panel-half-margin-right" *ngIf="type==='sanction'"
          [disabled]="!hasEditPermission || apiUpdatingEdit"
          (click)="sanctionApplication(_applicationSanction.SANCTION)"> {{ '$I18N_SWG_APPLICATIONS.SANCTION_APPLICATION' | translate }}
  </button>

  <button class="mc-table-actions btn btn-secondary panel-half-margin-right" *ngIf="type==='sanction'"
          [disabled]="!hasEditPermission || apiUpdatingEdit"
          (click)="sanctionApplication(_applicationSanction.NONE)"> {{ '$I18N_SWG_APPLICATIONS.CLEAR_STATUS' | translate }}
  </button>


  <mc-filters [showPagination]="false">
    <mc-filter-search (search)="onSearch($event)" (clear)="onSearchClear()"></mc-filter-search>

    <mc-filters-bundle-date-range
      *ngIf="type==='sanction'"
      (filtersChange)="onDateRangeFilterChange($event)"
      [config]="dateRangeConfig"
    >
    </mc-filters-bundle-date-range>

    <mc-filters-bundle-column-values-modal
      [sections]="searchFilters"
      (filtersChange)="onBundleFilterChange($event)"
    >
    </mc-filters-bundle-column-values-modal>

  </mc-filters>


  <mc-column key="check-row">
    <mc-header-cell *mcHeaderCellDef="let row">
      <input
        type="checkbox"
        (click)="onSelectAll($event)"
        [checked]="isSelectedAll"
        [disabled]="applicationsCopy.length == 0 || apiUpdatingEdit"
      />
    </mc-header-cell>
    <mc-body-cell *mcBodyCellDef="let row">
      <input
        type="checkbox"
        (click)="onSelectingSingleItem({ event: $event, application: row })"
        [disabled]="apiUpdatingEdit"
        [checked]="row.selected"
      />
    </mc-body-cell>
  </mc-column>

  <mc-column key="count">
    <mc-body-cell *mcBodyCellDef="let row">
      <ng-container *ngIf="row.count">
        {{ row.count.toLocaleString() }}
      </ng-container>
      <ng-container *ngIf="!row.count">0</ng-container>
    </mc-body-cell>
  </mc-column>

  <mc-column key="action">
    <mc-body-cell *mcBodyCellDef="let row">
      <ng-container *ngIf="row.action">
        {{ '$I18N_SWG_APPLICATIONS.' + row.action | uppercase | translate }}
      </ng-container>
    </mc-body-cell>
  </mc-column>

  <mc-column key="status">
    <mc-body-cell *mcBodyCellDef="let row">
      <ng-container *ngIf="row.action">
        {{ '$I18N_SWG_APPLICATIONS.' + row.status | uppercase | translate }}
      </ng-container>
    </mc-body-cell>
  </mc-column>

  <mc-column key="name">
    <mc-body-cell *mcBodyCellDef="let row">
      <i class="fab mc-app-icon" [ngClass]="row.icon"></i> {{row.name}}
    </mc-body-cell>
  </mc-column>

  <mc-column key="lastAccessed">
    <mc-body-cell *mcBodyCellDef="let row">
      <ng-container *ngIf="row.lastAccessed === 0">
        {{ '$I18N_SWG_APPLICATIONS.LASTACCESSED.0'| translate}}
      </ng-container>

      <ng-container *ngIf="row.lastAccessed === 1">
        <ng-container *ngIf="selectedDateRange.id === '48_HOURS'; else messageForOne" >
          {{ '$I18N_SWG_APPLICATIONS.LASTACCESSED.MORE_THAN_24_HOURS' | translate}}
        </ng-container>
        <ng-template #messageForOne>
          {{ '$I18N_SWG_APPLICATIONS.LASTACCESSED.1' | translate}}
        </ng-template>
      </ng-container>

      <ng-container *ngIf="row.lastAccessed === 2">
        <ng-container *ngIf="selectedDateRange.id === '14_DAYS'; else messageForTwo" >
          {{ '$I18N_SWG_APPLICATIONS.LASTACCESSED.MORE_THAN_WEEK' | translate}}
        </ng-container>
        <ng-template #messageForTwo>
          {{ '$I18N_SWG_APPLICATIONS.LASTACCESSED.2' | translate}}
        </ng-template>
      </ng-container>

      <ng-container *ngIf="!row.lastAccessed && row.lastAccessed !== 0">
        {{ '$I18N_SWG_APPLICATIONS.LASTACCESSED.UNDEFINED_' + selectedDateRange.id | uppercase | translate}}
      </ng-container>

    </mc-body-cell>
  </mc-column>

  <mc-column key="category">
    <mc-body-cell *mcBodyCellDef="let row">{{
      row.category | mcSwgCategoryNameTranslate
      }}</mc-body-cell>
  </mc-column>

  <mc-column-actions key="row-actions">
    <mc-row-actions *mcRowActions="let row">
      <li mcRowAction="$I18N_SWG_WEB_APPLICATION_CONTROL_ALLOWED"
          (click)="controlApplicationByRow(row, _applicationStatus.ALLOW)"
          *ngIf="type!=='sanction' && !hasAppPolicyRemoveAllowEnabled"></li>
      <li mcRowAction="$I18N_SWG_WEB_APPLICATION_CONTROL_BLOCKED"
          (click)="controlApplicationByRow(row, _applicationStatus.BLOCK)"
          *ngIf="type!=='sanction'"></li>
      <li mcRowAction="$I18N_SWG_APPLICATIONS.MONITOR_APPLICATION"
          (click)="sanctionApplicationByRow(row, _applicationSanction.MONITOR)"
          *ngIf="type==='sanction'"></li>
      <li mcRowAction="$I18N_SWG_APPLICATIONS.SANCTION_APPLICATION"
          (click)="sanctionApplicationByRow(row, _applicationSanction.SANCTION)"
          *ngIf="type==='sanction'"></li>
      <li mcRowActionSeparator></li>
      <li mcRowAction="$I18N_SWG_WEB_APPLICATION_CONTROL_CLEAR_ACTION"
          (click)="controlApplicationByRow(row, _applicationStatus.NONE)"
          *ngIf="type!=='sanction'"></li>
      <li mcRowAction="$I18N_SWG_APPLICATIONS.CLEAR_STATUS"
          (click)="sanctionApplicationByRow(row, _applicationSanction.NONE)"
          *ngIf="type==='sanction'"></li>
    </mc-row-actions>
  </mc-column-actions>

  <mc-empty-results keyTitle="$I18N_SWG_APPLICATIONS.NO_RESULTS_FOUND">
  </mc-empty-results>
</mc-table>
