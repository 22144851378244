"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.minLengthForSearchField = exports.maxLengthForSearchField = exports.searchByFields = exports.alwaysVisibleColumns = exports.columnsToStartHidden = exports.sections = exports.columnList = void 0;
exports.columnList = [
    'name',
    'group',
    'connector',
    'created',
    'createdBy',
    'lastModified',
    'modifiedBy',
    'status',
    'right-column'
];
exports.sections = [
    {
        name: 'enabled',
        filters: ['true', 'false']
    }
];
exports.columnsToStartHidden = ['modifiedBy'];
exports.alwaysVisibleColumns = ['name'];
const searchByPrefix = '$I18N_ONEDRIVE_BACKUP_LIST.FILTERS.SEARCH_BY_FILTER_FIELD';
exports.searchByFields = [
    {
        label: `${searchByPrefix}.ALL`,
        value: 'all'
    },
    {
        label: `${searchByPrefix}.NAME`,
        value: 'name'
    },
    {
        label: `${searchByPrefix}.CREATED_BY`,
        value: 'createdBy'
    },
    {
        label: `${searchByPrefix}.MODIFIED_BY`,
        value: 'modifiedBy'
    }
];
exports.maxLengthForSearchField = 256;
exports.minLengthForSearchField = 3;
