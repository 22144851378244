"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DirectorySyncAsideSummaryComponent = void 0;
const common_1 = require("../../common/common");
const operators_1 = require("rxjs/operators");
class DirectorySyncAsideSummaryComponent {
    constructor(capabilitiesService) {
        this.capabilitiesService = capabilitiesService;
        this.mseSites = [];
        this.isAzureStandardHecate = false;
        this.isAzureGCCHecate = false;
    }
    ngOnInit() {
        this.capabilitiesService
            .hasCapability('DirSync.Azure.Standard.Hecate')
            .pipe(operators_1.take(1))
            .subscribe(isActive => {
            this.isAzureStandardHecate = isActive;
        });
        this.capabilitiesService
            .hasCapability('DirSync.Azure.GCC.Hecate')
            .pipe(operators_1.take(1))
            .subscribe(isActive => {
            this.isAzureGCCHecate = isActive;
        });
    }
    get serverType() {
        return common_1.getServerTypeKey(this.integration);
    }
    get hasEncryption() {
        return this.integration.ldapSettings && this.integration.ldapSettings.encryptionMode !== 'none';
    }
    isLdapIntegration() {
        return (this.integration.serverType === 'active_directory' || this.integration.serverType === 'domino');
    }
    isLdapDominoIntegration() {
        return this.integration.serverType === 'domino';
    }
    isAzureIntegration() {
        return this.integration.serverType === 'office_365';
    }
    isAzureStandardHecateMigratedIntegration() {
        var _a, _b;
        return (((_a = this.integration.office365Settings) === null || _a === void 0 ? void 0 : _a.serverSubtype) === 'standard' && ((_b = this.integration.office365Settings) === null || _b === void 0 ? void 0 : _b.connectorId) &&
            this.isAzureStandardHecate);
    }
    isAzureGCCHecateMigratedIntegration() {
        var _a, _b;
        return (((_a = this.integration.office365Settings) === null || _a === void 0 ? void 0 : _a.serverSubtype) === 'gov' && ((_b = this.integration.office365Settings) === null || _b === void 0 ? void 0 : _b.connectorId) &&
            this.isAzureGCCHecate);
    }
    isAzureStandardIntegration() {
        var _a;
        return ((_a = this.integration.office365Settings) === null || _a === void 0 ? void 0 : _a.serverSubtype) === 'standard';
    }
    isAzureGCCIntegration() {
        var _a;
        return ((_a = this.integration.office365Settings) === null || _a === void 0 ? void 0 : _a.serverSubtype) === 'gov';
    }
    isGsuiteIntegration() {
        return this.integration.serverType === 'google_directory';
    }
    isMSEIntegration() {
        return this.integration.serverType === 'ad_push';
    }
    isReplicateDiffDomain() {
        return !!(this.integration.adPushSettings.hostname &&
            this.integration.adPushSettings.userDn &&
            this.integration.adPushSettings.rootDn);
    }
    get mseSiteDescription() {
        const mseSiteId = this.integration.adPushSettings && this.integration.adPushSettings.mseSiteId;
        const site = this.mseSites.find(s => s.id === mseSiteId);
        return (site && site.description) || '-';
    }
}
exports.DirectorySyncAsideSummaryComponent = DirectorySyncAsideSummaryComponent;
