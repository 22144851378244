"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogCheckUrlComponent = void 0;
const models_1 = require("../../models");
const reducers = require("../../reducers/index");
const reportReducer = require("../../../reducers/index");
const logs_side_panel_actions_1 = require("app-new/services/url-protection/logs/actions/logs-side-panel.actions");
const log_list_actions_1 = require("../../actions/log-list.actions");
const logs_check_url_actions_1 = require("../../actions/logs-check-url.actions");
const report_submit_modal_action_1 = require("../../../actions/report-submit-modal.action");
const urlLogsReducers = require("../../reducers");
class LogCheckUrlComponent {
    constructor(translateService, store, capabilitiesService, _sanitizer) {
        this.translateService = translateService;
        this.store = store;
        this.capabilitiesService = capabilitiesService;
        this._sanitizer = _sanitizer;
        this.pageRefresh = true;
        this.customManagedUrlsTypes = models_1.CustomerManagedUrls;
        this.relaxed$ = this.store.select(reducers.getCheckUrlsScanRelaxedResults);
        this.moderate$ = this.store.select(reducers.getCheckUrlsScanModerateResults);
        this.aggressive$ = this.store.select(reducers.getCheckUrlsScanAggressiveResults);
        this.scanDetails$ = this.store.select(reducers.getCheckUrlsScanDetails);
        this.reportSubmissionSuccess$ = this.store.select(reportReducer.isReportSubmissionSuccess);
        this.scanResponse$ = this.store.select(reducers.getCheckUrlsScanResponse);
        this.isLoading$ = this.store.select(reducers.getCheckUrlsLoadingState);
        this.managedURLEntry$ = this.store.select(urlLogsReducers.getManagedURL);
        this.showScanDetails$ = this.store.select(reducers.getScanDetailsViewState);
        this.showScanDetails = false;
    }
    ngOnInit() {
        this.store.dispatch(new report_submit_modal_action_1.URLReportModalSubmitSuccessReset());
    }
    ngOnChanges(changes) {
        var _a;
        const rowChange = changes['rowData'].currentValue;
        if (rowChange) {
            this.notification = undefined;
            this.url = rowChange.url;
            const managedBanner = ((_a = this.rowData.tagMap) === null || _a === void 0 ? void 0 : _a.CustomerManagedUrls) ? this.rowData.tagMap.CustomerManagedUrls[this.customManagedUrlsTypes.MANAGEDURLENTRY][0]
                : undefined;
            this.store.dispatch(new log_list_actions_1.ShowMangedURLBannerForCheckTabAction(managedBanner));
            this.managedURLEntry$.subscribe(urlEntry => {
                const params = { managedURLEntry: urlEntry };
                if (params.managedURLEntry) {
                    this.notification = {
                        status: 'info',
                        boxed: true,
                        msg: this.translateService.instant('$I18N_SERVICES_URL_PROTECTION_LOGS_URL_CHECK.MANAGED_URL_SUBMIT_INLINE_NOTIFICATION', params)
                    };
                }
            });
            if (this.pageRefresh) {
                this.pageRefresh = false;
                this.showScanDetails = false;
            }
            const autoAllowPermission$ = this.capabilitiesService.hasCapability('Temporary.Services.URL.Reporting.Enabled');
            autoAllowPermission$.subscribe(autoAllowEnabled => {
                if (!autoAllowEnabled) {
                    this.store.dispatch(new logs_check_url_actions_1.CheckUrlAttemptAction(this.url));
                }
            });
            this.scanResponse$.subscribe(scanResponse => {
                this.scanResponse = scanResponse;
            });
        }
        this.showScanDetails$.subscribe(showScanDetails => {
            if (this.pageRefresh) {
                this.showScanDetails = showScanDetails;
            }
        });
    }
    urlClick(urlLog) {
        this.store.dispatch(new log_list_actions_1.UrlProtectionBrowserIsolationAction(urlLog));
    }
    urlBase64(url) {
        return (this.imagePath = this._sanitizer.bypassSecurityTrustResourceUrl(`data:image/jpg;base64, ${url}`));
    }
    showScreenshotModal(url) {
        this.store.dispatch(new logs_side_panel_actions_1.ShowScreenshotZoomModalAction(this.urlBase64(url)));
    }
    setScanResultIcon(result) {
        let iconClass;
        switch (result) {
            case 'Clean':
            case 'clean':
                iconClass = 'fa-check-circle';
                break;
            case '-':
                iconClass = 'hidden';
                break;
            case 'malicious':
                iconClass = 'fa-exclamation-circle';
                break;
            case 'suspicious':
                iconClass = 'fa-exclamation-triangle';
                break;
            default:
                iconClass = 'fa-times-circle';
        }
        return iconClass;
    }
    showRealTimeScanDetails() {
        this.pageRefresh = true;
        this.store.dispatch(new logs_check_url_actions_1.CheckUrlAttemptAction(this.url));
    }
}
exports.LogCheckUrlComponent = LogCheckUrlComponent;
