"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
(function () {
    'use strict';
    /* ======================================================== *\

     = Archive task list - Service

     \* ======================================================== */
    angular.module('services.services.backup.archive-task-list.service', [])
        .factory('archiveTaskListService', ['backupService', 'modalActionService', 'ngProgressFactory', 'growl', function (backupService, modalActionService, ngProgressFactory, growl) {
            const ngProgress = ngProgressFactory.createInstance();
            return {
                getDeleteConfirmationJson,
                getPauseJson,
                getResumeJson,
                closeActionModal,
                deleteArchiveTaskAndCloseModal,
                pauseBackupTask,
                resumeBackupTask
            };
            function getDeleteConfirmationJson(id, taskName, onDeleteAction) {
                return {
                    title: '$I18N_BACKUP_RESTORE_ARCHIVE_TASK_DELETE_CONFIRMATION_TITLE',
                    content: '$I18N_BACKUP_RESTORE_ARCHIVE_TASK_DELETE_CONFIRMATION_CONTENT',
                    contentParams: { taskName },
                    params: { itemId: id },
                    windowClass: 'mc-sync-recover-exchange-tasks-delete-dialog mc-sync-recover-exchange-task-view-delete-dialog hidden-xs',
                    buttons: [{
                            title: '$I18N_BACKUP_RESTORE_ARCHIVE_TASK_DELETE_CONFIRM_BUTTON_LABEL',
                            action: onDeleteAction,
                            type: modalActionService.BUTTON_TYPE.PRIMARY
                        },
                        {
                            title: '$I18N_BACKUP_RESTORE_ARCHIVE_TASK_DELETE_CANCEL_BUTTON_LABEL',
                            action: closeActionModal,
                            type: modalActionService.BUTTON_TYPE.DEFAULT
                        }]
                };
            }
            //== getPauseJson =========
            function getPauseJson(id, onPauseAction) {
                return {
                    title: '$I18N_BACKUP_RESTORE_ARCHIVE_TASK_PAUSE_CONFIRMATION_TITLE',
                    content: '$I18N_BACKUP_RESTORE_ARCHIVE_TASK_PAUSE_CONFIRMATION_CONTENT',
                    params: { itemId: id },
                    windowClass: 'mc-sync-recover-exchange-tasks-pause-dialog mc-sync-recover-exchange-task-view-pause-dialog hidden-xs',
                    buttons: [{
                            title: '$I18N_BACKUP_RESTORE_ARCHIVE_TASK_PAUSE_CONFIRM_BUTTON_LABEL',
                            action: onPauseAction,
                            type: modalActionService.BUTTON_TYPE.PRIMARY
                        },
                        {
                            title: '$I18N_BACKUP_RESTORE_ARCHIVE_TASK_PAUSE_CANCEL_BUTTON_LABEL',
                            action: closeActionModal,
                            type: modalActionService.BUTTON_TYPE.DEFAULT
                        }]
                };
            }
            //== getResumeJson =========//
            function getResumeJson(id, onResumeAction) {
                return {
                    title: '$I18N_BACKUP_RESTORE_ARCHIVE_TASK_RESUME_CONFIRMATION_TITLE',
                    content: '$I18N_BACKUP_RESTORE_ARCHIVE_TASK_RESUME_CONFIRMATION_CONTENT',
                    params: { itemId: id },
                    windowClass: 'mc-sync-recover-exchange-tasks-play-dialog mc-sync-recover-exchange-task-view-play-dialog hidden-xs',
                    buttons: [{
                            title: '$I18N_BACKUP_RESTORE_ARCHIVE_TASK_RESUME_CONFIRM_BUTTON_LABEL',
                            action: onResumeAction,
                            type: modalActionService.BUTTON_TYPE.PRIMARY
                        },
                        {
                            title: '$I18N_BACKUP_RESTORE_ARCHIVE_TASK_PAUSE_CANCEL_BUTTON_LABEL',
                            action: closeActionModal,
                            type: modalActionService.BUTTON_TYPE.DEFAULT
                        }]
                };
            }
            // == closeActionModal =========
            function closeActionModal(status) {
                modalActionService.closeActionModal(status);
            }
            // == deleteArchiveTaskAndCloseModal =========
            function deleteArchiveTaskAndCloseModal() {
                const id = modalActionService.getModalParams().itemId;
                if (angular.isDefined(id)) {
                    ngProgress.start();
                    backupService.deleteBackupTask(id).then(function (response) {
                        if (!response.err) {
                            closeActionModal(true);
                            ngProgress.complete();
                        }
                        else {
                            ngProgress.complete();
                            backupService.getError().showErrorNotification(response);
                        }
                    }, function () {
                        ngProgress.complete();
                        backupService.getError().showErrorNotification();
                    });
                }
                else {
                    growl.error('$I18N_BACKUP_RESTORE_ARCHIVE_TASK_DELETE_GENERIC_ERROR');
                }
            }
            // == pauseBackupTask =========
            function pauseBackupTask() {
                const id = modalActionService.getModalParams().itemId;
                if (angular.isDefined(id)) {
                    ngProgress.start();
                    backupService.pauseBackupTask(id).then(function (response) {
                        if (!response.err) {
                            closeActionModal();
                            ngProgress.complete();
                        }
                        else {
                            ngProgress.complete();
                            backupService.getError().showErrorNotification(response);
                        }
                    }, function () {
                        ngProgress.complete();
                        backupService.getError().showErrorNotification();
                    });
                }
                else {
                    growl.error('$I18N_BACKUP_RESTORE_ARCHIVE_TASK_DELETE_GENERIC_ERROR');
                }
            }
            // == resumeBackupTask =========
            function resumeBackupTask() {
                const id = modalActionService.getModalParams().itemId;
                if (angular.isDefined(id)) {
                    ngProgress.start();
                    backupService.resumeBackupTask(id).then(function (response) {
                        if (!response.err) {
                            closeActionModal();
                            ngProgress.complete();
                        }
                        else {
                            ngProgress.complete();
                            backupService.getError().showErrorNotification(response);
                        }
                    }, function () {
                        ngProgress.complete();
                        backupService.getError().showErrorNotification();
                    });
                }
            }
        }]);
}());
