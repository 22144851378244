"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PolicyDetailsAsideComponent = void 0;
const policy_details_actions_1 = require("../../actions/policy-details.actions");
const reducers_1 = require("../../reducers");
const operators_1 = require("rxjs/operators");
const policiesActions = require("../../actions/policies.actions");
class PolicyDetailsAsideComponent {
    constructor(store, overlayRef) {
        this.store = store;
        this.overlayRef = overlayRef;
    }
    ngOnInit() {
        this.isLoading$ = this.store.select(reducers_1.getPolicyDetailsIsLoading);
        this.policy$ = this.store.select(reducers_1.getPolicyDetailsPolicy);
        this.store
            .select(reducers_1.getPolicyDetailsPolicyId)
            .pipe(operators_1.first(policyId => policyId && policyId.length > 0))
            .subscribe(policyId => {
            this.store.dispatch(new policy_details_actions_1.IdentityLoadPolicyDetailsAction({ id: policyId }));
        });
    }
    close() {
        this.overlayRef.close();
    }
    editPolicy(row) {
        this.store.dispatch(new policiesActions.IdentityShowEditPolicyWizardAction(row.id));
        this.close();
    }
    deletePolicy(row) {
        this.store.dispatch(new policiesActions.IdentityDeleteModalOpenAction(row));
        this.close();
    }
}
exports.PolicyDetailsAsideComponent = PolicyDetailsAsideComponent;
