"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WizardModule = void 0;
const backup_component_1 = require("./backup/backup.component");
const details_component_1 = require("./backup/steps/details/details.component");
const excluded_files_component_1 = require("./backup/steps/excluded-files/excluded-files.component");
const summary_component_1 = require("./backup/steps/summary/summary.component");
const cancel_confirmation_modal_component_1 = require("./cancel-confirmation-modal/cancel-confirmation-modal.component");
const DECLARATIONS = [
    backup_component_1.BackupWizardComponent,
    cancel_confirmation_modal_component_1.CancelConfirmationModalComponent,
    details_component_1.DetailsComponent,
    excluded_files_component_1.ExcludedFilesStepComponent,
    summary_component_1.SummaryComponent
];
class WizardModule {
}
exports.WizardModule = WizardModule;
