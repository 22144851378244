"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgLocationService = void 0;
class SwgLocationService {
    constructor(swgLocationApiService) {
        this.swgLocationApiService = swgLocationApiService;
    }
    updateEgressIp(webIpRange) {
        if (webIpRange.id) {
            return this.swgLocationApiService.updateEgressIp(webIpRange);
        }
        else {
            return this.swgLocationApiService.createEgressIp(webIpRange);
        }
    }
    deleteEgressIp(id) {
        return this.swgLocationApiService.deleteEgressIp(id);
    }
}
exports.SwgLocationService = SwgLocationService;
