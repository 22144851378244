"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IdentityCancelAppUpdateModalComponent = void 0;
const appDetailsActions = require("../../actions/apps/app-details.actions");
class IdentityCancelAppUpdateModalComponent {
    constructor(store) {
        this.store = store;
    }
    onYesResponse() {
        this.store.dispatch(new appDetailsActions.IdentityAppCancelModelYesAction());
    }
    onNoResponse() {
        this.store.dispatch(new appDetailsActions.IdentityAppCancelModelNoAction());
    }
}
exports.IdentityCancelAppUpdateModalComponent = IdentityCancelAppUpdateModalComponent;
