
    <mc-template-safety-tips-container
      [safetyTipType]="safetyTipType"
      [customSafetyTips]="customSafetyTips$ | async"
      [mimecastSafetyTips]="mimecastSafetyTips$ | async"
      [previewTip]="previewTip$ | async"
      (delete)="deleteTip($event)"
      (edit)="editTip($event)"
      (create)="createTip()"
      (save)="saveTip($event)"
      (cancel)="cancelTip()"
      (previewAll)="previewAll()"
      (previewCustomeTip)="previewTip($event)"
      (previewMimecastTip)="previewMimecastTip($event)"
    >
    </mc-template-safety-tips-container>
  