<div class="mc-supervision-wizard-step-container mc-supervision-wizard-step-container-settings">
  <mc-wizard-step-header
    class="mc-supervision-wizard-step-header"
    label="$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.LEXICON_STEP.DESCRIPTION"
  >
    {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.LEXICON_STEP.DESCRIPTION_DETAILS' | translate }}
  </mc-wizard-step-header>

  <form
    [formGroup]="form"
    class="form-horizontal"
    mcDefaultLabelClass="col-sm-3"
    mcDefaultControlContainerClass="col-sm-9"
  >
    <mc-field
      label="{{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.LEXICON_STEP.FIELD_LEXICON' | translate }}"
    >
      <input type="hidden" formControlName="lexicon" />
      <button type="button" class="btn btn-default" (click)="openSidebar()">
        {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.LEXICON_STEP.SELECT_LEXICON' | translate }}
      </button>

      <div *ngIf="!selectedLexicon">
        <p class="mc-lexicon-info-text">
          <i class="fas fa-info-circle"></i>
          <span>
            {{
              '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.LEXICON_STEP.CONTINUE_WITHOUT_LEXICON'
                | translate
            }}
          </span>
        </p>
      </div>
      <div *ngIf="selectedLexicon">
        <div class="selected-group">
          <span class="groups-list">
            <div class="groups-name">{{ selectedLexicon.name }}</div>
            <i class="far fa-trash-alt" aria-hidden="true" (click)="deleteLexicon()"></i>
          </span>
          <hr class="groups-separator" />
        </div>
      </div>
    </mc-field>
  </form>
</div>
