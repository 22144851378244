"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScreenshotZoomModalComponent = void 0;
class ScreenshotZoomModalComponent {
    constructor(data, overlayRef) {
        this.overlayRef = overlayRef;
        this.data = data;
    }
    onCancel() {
        this.overlayRef.close();
    }
}
exports.ScreenshotZoomModalComponent = ScreenshotZoomModalComponent;
