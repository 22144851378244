"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const static_1 = require("@angular/upgrade/static");
const list_1 = require("./container/list");
const save_1 = require("./container/api-gateway/save");
const keygen_1 = require("./container/api-gateway/keygen");
const cortex_1 = require("./container/integration/cortex");
const wildfire_1 = require("./container/integration/wildfire");
const crowdstrike_1 = require("./container/integration/crowdstrike");
const okta_1 = require("./container/integration/okta");
const okta_evidence_based_control_1 = require("./container/integration/okta-evidence-based-control");
const select_1 = require("./container/integration/select");
const siem_channel_wizard_container_component_1 = require("./container/siem-channels/wizard/siem-channel-wizard.container.component");
const crowdstrike_activity_component_1 = require("./container/integration/crowdstrike/activity/crowdstrike.activity.component");
const gateway_1 = require("./container/api-gateway/gateway");
angular
    .module('api-applications', [])
    .controller('IntegrationController', [
    '$stateParams',
    '$scope',
    function ($stateParams, $scope) {
        $scope.stateParams = $stateParams;
    }
])
    .directive('mcApiApplicationsList', static_1.downgradeComponent({
    component: list_1.ApiApplicationsListComponent
}))
    .directive('mcApiGatewaySave', static_1.downgradeComponent({
    component: save_1.ApiGatewaySaveComponent
}))
    .directive('mcApiGatewayV3', static_1.downgradeComponent({
    component: gateway_1.GatewayComponent
}))
    .directive('mcApiGatewayKeygen', static_1.downgradeComponent({
    component: keygen_1.ApiGatewayKeyGenComponent
}))
    .directive('mcApiIntegrationCreate', static_1.downgradeComponent({
    component: cortex_1.ApiIntegrationCortexComponent
}))
    .directive('mcApiIntegrationWildfire', static_1.downgradeComponent({
    component: wildfire_1.ApiIntegrationWildfireComponent
}))
    .directive('mcApiIntegrationCrowdstrike', static_1.downgradeComponent({
    component: crowdstrike_1.ApiIntegrationCrowdstrikeComponent
}))
    .directive('mcApiIntegrationCrowdstrikeActivity', static_1.downgradeComponent({
    component: crowdstrike_activity_component_1.ApiIntegrationCrowdstrikeActivityComponent
}))
    .directive('mcApiIntegrationOkta', static_1.downgradeComponent({
    component: okta_1.ApiIntegrationOktaComponent
}))
    .directive('mcApiIntegrationOktaEvidenceBasedControl', static_1.downgradeComponent({
    component: okta_evidence_based_control_1.ApiIntegrationOktaEvidenceBasedControlComponent
}))
    .directive('mcApiIntegrationSelect', static_1.downgradeComponent({
    component: select_1.ApiIntegrationSelectComponent
}))
    .directive('mcSiemChannelWizardContainer', static_1.downgradeComponent({
    component: siem_channel_wizard_container_component_1.SiemChannelWizardContainerComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider
            .state('api-applications-list', {
            url: '/api-applications/list?displayTab&showApiModal&hasError',
            data: {
                capabilities: 'Permission.API_APPLICATIONS_READ',
                isFixedLayout: false,
                checkProgress: false,
                tabReload: true,
                tabTitle: '$I18N_API_APPLICATIONS.TAB_TITLE',
                tabId: 'api-applications',
                breadcrumbs: []
            },
            views: {
                main: {
                    template: '<mc-api-applications-list></mc-api-applications-list>'
                }
            }
        })
            .state('api-applications-save', {
            data: {
                capabilities: 'Permission.API_APPLICATIONS_EDIT',
                isFixedLayout: false,
                checkProgress: false,
                tabTitle: '$I18N_API_APPLICATIONS.TAB_TITLE',
                tabId: 'api-applications',
                breadcrumbs: [
                    '$I18N_NAVBAR_MENU_ITEM_SERVICES',
                    '$I18N_API_APPLICATIONS.TAB_TITLE',
                    '$I18N_API_APPLICATIONS.BREADCRUMBS.SAVE_WIZARD'
                ]
            },
            views: {
                main: {
                    template: '<mc-api-gateway-save></mc-api-gateway-save>'
                }
            }
        })
            .state('api-applications-v3', {
            data: {
                capabilities: 'Permission.API_APPLICATIONS_EDIT',
                isFixedLayout: false,
                checkProgress: false,
                tabTitle: '$I18N_API_APPLICATIONS.TAB_TITLE',
                tabId: 'api-applications',
                breadcrumbs: [
                    '$I18N_NAVBAR_MENU_ITEM_SERVICES',
                    '$I18N_API_APPLICATIONS.TAB_TITLE',
                    '$I18N_API_APPLICATIONS.BREADCRUMBS.V3_WIZARD'
                ]
            },
            views: {
                main: {
                    template: '<mc-api-gateway-v3></mc-api-gateway-v3>'
                }
            }
        })
            .state('api-applications-keygen', {
            data: {
                capabilities: 'Permission.API_APPLICATIONS_EDIT',
                isFixedLayout: false,
                checkProgress: false,
                tabTitle: '$I18N_API_APPLICATIONS.TAB_TITLE',
                tabId: 'api-applications',
                breadcrumbs: [
                    '$I18N_NAVBAR_MENU_ITEM_SERVICES',
                    '$I18N_API_APPLICATIONS.TAB_TITLE',
                    '$I18N_API_APPLICATIONS.BREADCRUMBS.KEYGEN_WIZARD'
                ]
            },
            views: {
                main: {
                    template: '<mc-api-gateway-keygen></mc-api-gateway-keygen>'
                }
            }
        })
            .state('api-integration-select', {
            data: {
                capabilities: 'Permission.API_APPLICATIONS_EDIT',
                isFixedLayout: false,
                checkProgress: false,
                tabTitle: '$I18N_API_APPLICATIONS.TAB_TITLE',
                tabId: 'api-applications',
                breadcrumbs: [
                    '$I18N_NAVBAR_MENU_ITEM_SERVICES',
                    '$I18N_API_APPLICATIONS.TAB_TITLE',
                    '$I18N_API_APPLICATIONS.BREADCRUMBS.INTEGRATION_WIZARD'
                ]
            },
            views: {
                main: {
                    controller: 'IntegrationController',
                    template: `
                <mc-api-integration-select>
                </mc-api-integration-select>`
                }
            }
        })
            .state('api-integration-wildfire', {
            data: {
                capabilities: 'Permission.API_APPLICATIONS_EDIT',
                isFixedLayout: false,
                checkProgress: false,
                tabTitle: '$I18N_API_APPLICATIONS.TAB_TITLE',
                tabId: 'api-applications',
                breadcrumbs: [
                    '$I18N_NAVBAR_MENU_ITEM_SERVICES',
                    '$I18N_API_APPLICATIONS.TAB_TITLE',
                    '$I18N_API_APPLICATIONS.BREADCRUMBS.INTEGRATION_WIZARD'
                ]
            },
            views: {
                main: {
                    controller: 'IntegrationController',
                    template: `
                <mc-api-integration-wildfire>
                </mc-api-integration-wildfire>`
                }
            }
        })
            .state('api-integration-crowdstrike', {
            data: {
                capabilities: 'Permission.API_APPLICATIONS_EDIT',
                isFixedLayout: false,
                checkProgress: false,
                tabTitle: '$I18N_API_APPLICATIONS.TAB_TITLE',
                tabId: 'api-applications',
                breadcrumbs: [
                    '$I18N_NAVBAR_MENU_ITEM_SERVICES',
                    '$I18N_API_APPLICATIONS.TAB_TITLE',
                    '$I18N_API_APPLICATIONS.BREADCRUMBS.INTEGRATION_WIZARD'
                ]
            },
            views: {
                main: {
                    controller: 'IntegrationController',
                    template: `
                <mc-api-integration-crowdstrike>
                </mc-api-integration-crowdstrike>`
                }
            }
        })
            .state('api-integration-crowdstrike-activity', {
            url: '/api-applications/crowdstrike/activity/logs/{id}',
            data: {
                capabilities: 'Temporary.Services.ApiApplications.Crowdstrike.Integration.ActivityLogs && Permission.API_APPLICATIONS_READ',
                isFixedLayout: false,
                checkProgress: false,
                tabTitle: '$I18N_API_APPLICATIONS.TAB_TITLE',
                tabId: 'api-applications',
                breadcrumbs: [
                    '$I18N_NAVBAR_MENU_ITEM_SERVICES',
                    {
                        label: '$I18N_API_APPLICATIONS.TAB_TITLE',
                        stateName: 'api-applications-list',
                        stateParams: { displayTab: 'integrations' }
                    },
                    {
                        label: '$I18N_API_APPLICATIONS.BREADCRUMBS.CROWDSTRIKE_LOGS',
                        stateName: 'api-integration-crowdstrike-activity'
                    }
                ]
            },
            views: {
                main: {
                    controller: 'IntegrationController',
                    template: `
                <mc-api-integration-crowdstrike-activity
                [integrationid]="stateParams.id">
                </mc-api-integration-crowdstrike-activity>`
                }
            }
        })
            .state('api-integration-okta', {
            data: {
                capabilities: 'Permission.API_APPLICATIONS_EDIT',
                isFixedLayout: false,
                checkProgress: false,
                tabTitle: '$I18N_API_APPLICATIONS.TAB_TITLE',
                tabId: 'api-applications',
                breadcrumbs: [
                    '$I18N_NAVBAR_MENU_ITEM_SERVICES',
                    '$I18N_API_APPLICATIONS.TAB_TITLE',
                    '$I18N_API_APPLICATIONS.BREADCRUMBS.INTEGRATION_WIZARD'
                ]
            },
            views: {
                main: {
                    controller: 'IntegrationController',
                    template: `
                <mc-api-integration-okta>
                </mc-api-integration-okta>`
                }
            }
        })
            .state('api-integration-okta-edit', {
            data: {
                capabilities: 'Permission.API_APPLICATIONS_EDIT',
                isFixedLayout: false,
                checkProgress: false,
                tabTitle: '$I18N_API_APPLICATIONS.TAB_TITLE',
                tabId: 'api-applications',
                breadcrumbs: [
                    '$I18N_NAVBAR_MENU_ITEM_SERVICES',
                    '$I18N_API_APPLICATIONS.TAB_TITLE',
                    '$I18N_API_APPLICATIONS.BREADCRUMBS.EDIT_INTEGRATION_WIZARD'
                ]
            },
            views: {
                main: {
                    controller: 'IntegrationController',
                    template: `
                <mc-api-integration-okta>
                </mc-api-integration-okta>`
                }
            }
        })
            .state('api-integration-okta-evidence-based-control', {
            data: {
                capabilities: 'Permission.API_APPLICATIONS_EDIT',
                isFixedLayout: false,
                checkProgress: false,
                tabTitle: '$I18N_API_APPLICATIONS.TAB_TITLE',
                tabId: 'api-applications',
                breadcrumbs: [
                    '$I18N_NAVBAR_MENU_ITEM_SERVICES',
                    '$I18N_API_APPLICATIONS.TAB_TITLE',
                    '$I18N_API_APPLICATIONS.BREADCRUMBS.INTEGRATION_WIZARD'
                ]
            },
            views: {
                main: {
                    controller: 'IntegrationController',
                    template: `
                <mc-api-integration-okta-evidence-based-control>
                </mc-api-integration-okta-evidence-based-control>`
                }
            }
        })
            .state('api-integration-okta-evidence-based-control-edit', {
            data: {
                capabilities: 'Permission.API_APPLICATIONS_EDIT',
                isFixedLayout: false,
                checkProgress: false,
                tabTitle: '$I18N_API_APPLICATIONS.TAB_TITLE',
                tabId: 'api-applications',
                breadcrumbs: [
                    '$I18N_NAVBAR_MENU_ITEM_SERVICES',
                    '$I18N_API_APPLICATIONS.TAB_TITLE',
                    '$I18N_API_APPLICATIONS.BREADCRUMBS.EDIT_INTEGRATION_WIZARD'
                ]
            },
            views: {
                main: {
                    controller: 'IntegrationController',
                    template: `
                <mc-api-integration-okta-evidence-based-control>
                </mc-api-integration-okta-evidence-based-control>`
                }
            }
        })
            .state('api-cortex-integration-new', {
            data: {
                capabilities: 'Permission.API_APPLICATIONS_EDIT',
                isFixedLayout: false,
                checkProgress: false,
                tabTitle: '$I18N_API_APPLICATIONS.TAB_TITLE',
                tabId: 'api-applications',
                breadcrumbs: [
                    '$I18N_NAVBAR_MENU_ITEM_SERVICES',
                    '$I18N_API_APPLICATIONS.TAB_TITLE',
                    '$I18N_API_APPLICATIONS.BREADCRUMBS.INTEGRATION_WIZARD'
                ]
            },
            views: {
                main: {
                    controller: 'IntegrationController',
                    template: `
                <mc-api-integration-create
                  [cortex-state]="'new'">
                </mc-api-integration-create>`
                }
            }
        })
            .state('api-cortex-integration-edit', {
            data: {
                capabilities: 'Permission.API_APPLICATIONS_EDIT',
                isFixedLayout: false,
                checkProgress: false,
                tabTitle: '$I18N_API_APPLICATIONS.TAB_TITLE',
                tabId: 'api-applications',
                breadcrumbs: [
                    '$I18N_NAVBAR_MENU_ITEM_SERVICES',
                    '$I18N_API_APPLICATIONS.TAB_TITLE',
                    '$I18N_API_APPLICATIONS.BREADCRUMBS.INTEGRATION_WIZARD'
                ]
            },
            views: {
                main: {
                    controller: 'IntegrationController',
                    template: `
                <mc-api-integration-create
                  [cortex-state]="'edit'">
                </mc-api-integration-create>`
                }
            }
        })
            .state('api-integration-reentry', {
            url: '/api-applications/integration/{provider}/{action}/{config}',
            data: {
                capabilities: 'Permission.API_APPLICATIONS_EDIT',
                isFixedLayout: false,
                checkProgress: false,
                tabTitle: '$I18N_API_APPLICATIONS.TAB_TITLE',
                tabId: 'api-applications',
                breadcrumbs: [
                    '$I18N_NAVBAR_MENU_ITEM_SERVICES',
                    '$I18N_API_APPLICATIONS.TAB_TITLE',
                    '$I18N_API_APPLICATIONS.BREADCRUMBS.INTEGRATION_WIZARD'
                ]
            },
            views: {
                main: {
                    controller: 'IntegrationController',
                    template: `
                <mc-api-integration-create
                  [cortex-state]="stateParams.action"
                  [provider]="stateParams.provider"
                  [config]="stateParams.config">
                </mc-api-integration-create>`
                }
            }
        })
            .state('siem-channel-wizard', {
            data: {
                capabilities: 'Permission.SIEM_CHANNEL_EDIT',
                isFixedLayout: false,
                checkProgress: false,
                tabTitle: '$I18N_API_APPLICATIONS.TAB_TITLE',
                tabId: 'api-applications',
                breadcrumbs: [
                    '$I18N_NAVBAR_MENU_ITEM_SERVICES',
                    '$I18N_API_APPLICATIONS.TAB_TITLE',
                    '$I18N_API_APPLICATIONS.BREADCRUMBS.CHANNELS'
                ]
            },
            views: {
                main: {
                    controller: 'IntegrationController',
                    template: '<mc-siem-channel-wizard-container></mc-siem-channel-wizard-container>'
                }
            }
        });
    }
]);
