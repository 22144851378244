<div *ngIf="status" class="base-status">
  <i
    class="status-indicator"
    tooltip="{{ getToolTipKey() | uppercase | translate }}"
    container="body"
    [isDisabled]="!getToolTipKey()"
    [ngClass]="[getIconClass(), getIconColorClass()]"
  >
</i>
  <ng-container *ngIf="!isConsolidated && !isStatusOnly">
    <span>
      {{ getStatusTranslateKey() | uppercase | translate }}
    </span>
    <span *ngIf="state && state.length">
      {{ ' - ' + (getStateTranslateKey() | uppercase | translate) }}
    </span>
  </ng-container>
  <span *ngIf="isConsolidated && getStatusOrStateTranslateKey()">
    {{ getStatusOrStateTranslateKey() | uppercase | translate }}
  </span>
  <span *ngIf="!isConsolidated && isStatusOnly && getStatusTranslateKey()">
    {{ getStatusTranslateKey() | uppercase | translate }}
  </span>
</div>
