"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.URLReportApiService = void 0;
const operators_1 = require("rxjs/operators");
const common_1 = require("../../../../api-applications/services/common");
class URLReportApiService {
    constructor(http) {
        this.http = http;
    }
    getCategories() {
        return this.http
            .post('/api/ttp/url/get-categories', null)
            .pipe(operators_1.map((response) => response.first));
    }
    getProfile() {
        return this.http.post('/api/user/get-profile', null).pipe(common_1.firstResult());
    }
}
exports.URLReportApiService = URLReportApiService;
