"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailStatusComponent = void 0;
class EmailStatusComponent {
    get isDelivered() {
        return this.isStatus(['delivered']);
    }
    get isProcessing() {
        return this.isStatus(['pending_delivery', 'held']);
    }
    isStatus(values) {
        return values.indexOf(this.status) > -1;
    }
}
exports.EmailStatusComponent = EmailStatusComponent;
