<div *ngIf="!!scanResponses" class="mc-scan-details-padding-left">
  <div class="mc-scan-details-margin" *ngIf="checkScanDetails(scanResponses, scannerTypes.ADVANCEDSIMILARITYCHECKS)">
    <div class="mc-scan-details-label">{{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.ADVANCED_SIMILARITY_CHECK' | translate }}</div>
    <div  class="row mc-detailed-list-row" *ngIf="scanResponses.AdvancedSimilarityChecks[advancedSimilarityChecksTypes.SIMILARDOMAINMATCH]">
      <div class="col-sm-4 mc-scan-detail-subcategory-label" >
        {{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.SIMILAR_DOMAIN_MATCH' | translate }}
       <div  class="mc-detailed-list-column">{{ scanResponses.AdvancedSimilarityChecks[advancedSimilarityChecksTypes.SIMILARDOMAINMATCH] }}</div>
    </div>
    </div>
  </div>
  <div class="mc-scan-details-margin"  *ngIf="checkScanDetails(scanResponses, scannerTypes.AVSCANNING)">
    <div  class="mc-scan-details-label">{{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.AVSCANNING' | translate }}</div>
    <div  class="row mc-detailed-list-row" *ngIf="scanResponses.AvScanning[avScanningTypes.AVSIGNATURENAME]">
      <div class="col-sm-4 mc-scan-detail-subcategory-label">
        {{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.AVSIGNATURE_NAME' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ scanResponses.AvScanning[avScanningTypes.AVSIGNATURENAME] }}</div>
    </div>
    <div  class="row mc-detailed-list-row" *ngIf="scanResponses.AvScanning[avScanningTypes.AVSIGNATURECATEGORYTRIGGER]">
      <div class="col-sm-4 mc-scan-detail-subcategory-label">
        {{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.CATEGORY_TRIGGER' | translate }}
      </div>
      <div  class="mc-detailed-list-column">{{ scanResponses.AvScanning[avScanningTypes.AVSIGNATURECATEGORYTRIGGER] }}</div>
    </div>
    <div  class="row mc-detailed-list-row" *ngIf="scanResponses.AvScanning[avScanningTypes.AVSIGNATUREFILEEXTENSION]">
      <div class="col-sm-4 mc-scan-detail-subcategory-label">
        {{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.FILE_EXTENSION' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ scanResponses.AvScanning[avScanningTypes.AVSIGNATUREFILEEXTENSION] }}</div>
    </div>
  </div>

  <div class="mc-scan-details-margin"  *ngIf="checkScanDetails(scanResponses, scannerTypes.MACHINELEARNING)">
    <div  class="mc-scan-details-label">{{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.MACHINELEARNINGDETECTION' | translate }}</div>
    <div class="row mc-detailed-list-row" *ngIf="scanResponses.MachineLearning[machineLearningTypes.TYPE]">
      <div class="col-sm-4 mc-scan-detail-subcategory-label">
        {{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.TYPE' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ scanResponses.MachineLearning[machineLearningTypes.TYPE] }}</div>
    </div>
    <div class="row mc-detailed-list-row" *ngIf="scanResponses.MachineLearning[machineLearningTypes.ORIGNALURL]">
      <div class="col-sm-4 mc-scan-detail-subcategory-label">{{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.URL_BLOCK' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ scanResponses.MachineLearning[machineLearningTypes.ORIGNALURL] }}</div>
    </div>
    <div class="row mc-detailed-list-row" *ngIf="scanResponses.MachineLearning[machineLearningTypes.BLOCKURL]">
      <div class="col-sm-4 mc-scan-detail-subcategory-label">
        {{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.URL' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ scanResponses.MachineLearning[machineLearningTypes.BLOCKURL] }}</div>
    </div>
  </div>

  <div  class="mc-scan-details-margin"  *ngIf="checkScanDetails(scanResponses, scannerTypes.DANGEROUSFILEEXTENSION)">
    <div  class="mc-scan-details-label">{{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.DANGEROUS_FILE_EXTENTION' | translate }}</div>
    <div   class="row mc-detailed-list-row" *ngIf="scanResponses.DangerousFileExt[dangerousFileExtTypes.DANGEROUSFILEDOWNLOAD]">
      <div class="col-sm-4 mc-scan-detail-subcategory-label">
        {{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.DANGEROUS_EXTENTION_FILE_DOWNLOAD' | translate }}
      </div>

      <div class="mc-detailed-list-column">{{ scanResponses.DangerousFileExt[dangerousFileExtTypes.DANGEROUSFILEDOWNLOAD] }}</div>
    </div>
    <div  class="row mc-detailed-list-row" *ngIf="scanResponses.DangerousFileExt[dangerousFileExtTypes.DANGEROUSFILEMIMEDOWNLOAD]">
    <div class="col-sm-4 mc-scan-detail-subcategory-label">{{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.DANGEROUS_MIME_TYPE_FILE_DOWNLOAD' | translate }}
    </div>
    <div class="mc-detailed-list-column">{{ scanResponses.DangerousFileExt[dangerousFileExtTypes.DANGEROUSFILEMIMEDOWNLOAD] }}</div>
  </div>
    <div  class="row mc-detailed-list-row" *ngIf="scanResponses.DangerousFileExt[dangerousFileExtTypes.BLOCKEDEXTENSIONS]">
      <div class="col-sm-4 mc-scan-detail-subcategory-label">{{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.DANGEROUS_BLOCKED_EXTENSIONS' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ scanResponses.DangerousFileExt[dangerousFileExtTypes.BLOCKEDEXTENSIONS] }}</div>
    </div>
  </div>
  <div class="mc-scan-details-margin"  *ngIf="checkScanDetails(scanResponses, scannerTypes.HTMLCONTENTCHECKS)">
    <div  class="mc-scan-details-label"> {{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.HTML_CONTENT_CHECKS' | translate }}</div>
    <div class="row mc-detailed-list-row" *ngIf="scanResponses.HtmlContentChecks[htmlContentChecksTypes.INNEREXECUTABLE]">
      <div class="col-sm-4 mc-scan-detail-subcategory-label">{{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.INNER_EXECUTABLE' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ scanResponses.HtmlContentChecks[htmlContentChecksTypes.INNEREXECUTABLE] }}</div>
    </div>
  </div>
  <div class="mc-scan-details-margin"  *ngIf="checkScanDetails(scanResponses, scannerTypes.URLREPUTATIONSCAN)">
    <div class="mc-scan-details-label">{{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.URL_REPUTATION_SCAN' | translate }}</div>
    <div  class="row mc-detailed-list-row" *ngIf="scanResponses.UrlReputationScan[urlReputationScanTypes.TYPE]">
      <div class="col-sm-4 mc-scan-detail-subcategory-label">{{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.TYPE' | translate }}</div>
      <div class="mc-detailed-list-column">{{ scanResponses.UrlReputationScan[urlReputationScanTypes.TYPE] }}</div>
    </div>
    <div class="row mc-detailed-list-row" *ngIf="scanResponses.UrlReputationScan[urlReputationScanTypes.URLBlOCK]">
      <div class="col-sm-4 mc-scan-detail-subcategory-label">{{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.URL_BLOCK' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ scanResponses.UrlReputationScan[urlReputationScanTypes.URLBlOCK] }}</div>
    </div>
    <div class="row mc-detailed-list-row" *ngIf="scanResponses.UrlReputationScan[urlReputationScanTypes.URL]">
      <div class="col-sm-4 mc-scan-detail-subcategory-label">
        {{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.URL' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ scanResponses.UrlReputationScan[urlReputationScanTypes.URL] }}</div>
    </div></div>
  <div class="mc-scan-details-margin" *ngIf="checkScanDetails(scanResponses, scannerTypes.CUSTOMERMANAGEDURLS)">
    <div   class="mc-scan-details-label"> {{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.CUSTOM_MANAGED_URLS' | translate }}</div>
    <div   class="row mc-detailed-list-row" *ngIf="scanResponses.CustomerManagedUrls[customManagedUrlsTypes.BLOCKLISTED]">
      <div class="col-sm-4 mc-scan-detail-subcategory-label" >{{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.BLOCKLISTED' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ scanResponses.CustomerManagedUrls[customManagedUrlsTypes.BLOCKLISTED] }}</div>
    </div>
    <div   class="row mc-detailed-list-row" *ngIf="scanResponses.CustomerManagedUrls[customManagedUrlsTypes.MANAGEDURLENTRY]">
      <div class="col-sm-4 mc-scan-detail-subcategory-label" >{{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.MANAGEDURLENTRY' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ scanResponses.CustomerManagedUrls[customManagedUrlsTypes.MANAGEDURLENTRY] }}</div>
    </div>
  </div>
  <div class="mc-scan-details-margin"  *ngIf="checkScanDetails(scanResponses, scannerTypes.SANDBOXING)">
    <div   class="mc-scan-details-label"> {{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.SANDBOXING' | translate }}</div>
    <div  class="row mc-detailed-list-row" *ngIf="scanResponses.Sandboxing[sandBoxingTypes.AVSIGNATURENAME]">
      <div class="col-sm-4 mc-scan-detail-subcategory-label">{{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.SANDBOXING_AV_SIGNATURE' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ scanResponses.Sandboxing[sandBoxingTypes.AVSIGNATURENAME] }}</div>
    </div>
    <div  class="row mc-detailed-list-row" *ngIf="scanResponses.Sandboxing[sandBoxingTypes.MOREINFORMATION]">
      <div class="col-sm-4 mc-scan-detail-subcategory-label">{{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.SANDBOXING_MORE_INFORMATION' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ scanResponses.Sandboxing[sandBoxingTypes.MOREINFORMATION] }}</div>
    </div>
    <div  class="row mc-detailed-list-row" *ngIf="scanResponses.Sandboxing[sandBoxingTypes.FILEEXTENSION]">
      <div class="col-sm-4 mc-scan-detail-subcategory-label">{{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.SANDBOXING_FILE_EXTENSION' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ scanResponses.Sandboxing[sandBoxingTypes.FILEEXTENSION] }}</div>
    </div>
  </div>
  <div class="mc-scan-details-margin"  *ngIf="checkScanDetails(scanResponses, scannerTypes.ADVANCEDPHISHING)">
    <div  class="mc-scan-details-label">  {{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.ADVANCED_PHISHING' | translate }}</div>
    <div  class="row mc-detailed-list-row" *ngIf="scanResponses.AdvancedPhishing[advancedPhishingTypes.CREDENTIALTHEFTEVIDENCE]">
      <div class="col-sm-4 mc-scan-detail-subcategory-label">
        {{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.CREDENTIAL_THEFT_EVIDENCE' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ scanResponses.AdvancedPhishing[advancedPhishingTypes.CREDENTIALTHEFTEVIDENCE] }}</div>
    </div>
    <div class="row mc-detailed-list-row" *ngIf="scanResponses.AdvancedPhishing[advancedPhishingTypes.CREDENTIALTHEFTBRANDS]">
      <div class="col-sm-4 mc-scan-detail-subcategory-label">
        {{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.IDENTIFIED_BRANDS' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ scanResponses.AdvancedPhishing[advancedPhishingTypes.CREDENTIALTHEFTBRANDS] }}</div>
</div>
  </div>
</div>
