<form
  [formGroup]="formGroup"
  (ngSubmit)="doSearch()"
  class="{{
    verticalForm
      ? 'form-horizontal tracking-search-form-vertical'
      : 'tracking-search-form-horizontal'
  }}"
  mcDefaultLabelClass="{{ verticalForm ? 'col-sm-3' : '' }}"
  mcDefaultControlContainerClass="{{ verticalForm ? 'col-sm-9' : '' }}"
  mcErrorPrefix="$I18N_TRACKING_SEARCH_ERROR"
  novalidate
  data-test="tracking_search_by_data">

  <div class="tracking-search-form-content {{ isUrlSearchEnabled ? 'tracking-search-form-two-rows' : 'tracking-search-form-single-row' }}">
    <mc-text-field
      label="$I18N_TRACKING_SEARCH_LABEL_FROM"
      placeholder="$I18N_TRACKING_SEARCH_PLACEHOLDER_EMAIL_DOMAIN"
      formControlName="from"
      errorPrefix="FROM"
      class="{{ !verticalForm ? 'col-xs-2 no-padding-horizontal' : '' }}"
      data-test="tracking_sender">
    </mc-text-field>

    <mc-text-field
      label="$I18N_TRACKING_SEARCH_LABEL_TO"
      placeholder="$I18N_TRACKING_SEARCH_PLACEHOLDER_EMAIL_DOMAIN"
      formControlName="to"
      errorPrefix="TO"
      class="{{ !verticalForm ? 'col-xs-2 no-padding-horizontal' : '' }}"
      data-test="tracking_recipient">
    </mc-text-field>

    <mc-date-range-picker
      label="$I18N_TRACKING_SEARCH_LABEL_DATE_RANGE"
      formControlName="dateRangePicker"
      [config]="dateRangePickerConfig"
      [horizontal]="!verticalForm"
      [useCurrentMoment]="true"
      class="{{ !verticalForm ? 'col-xs-2 no-padding-horizontal' : '' }}"
      data-test="tracking_data_date">
    </mc-date-range-picker>

    <ng-container *ngIf="verticalForm; else collapsibleItems">
      <mc-collapsable-panel>
        <span *ngTemplateOutlet="collapsibleItems"></span>
      </mc-collapsable-panel>
    </ng-container>

    <button
      *ngIf="!isUrlSearchEnabled"
      type="submit"
      class="btn btn-primary {{ verticalForm ? 'pull-right' : 'pull-right form-horizontal-submit-button' }}"
      [disabled]="!formGroup.valid"
      data-test="tracking_data_submit">
      {{ '$I18N_TRACKING_SEARCH_BUTTON_LABEL' | translate }}
    </button>

    <ng-template #collapsibleItems>
      <mc-text-field
        label="$I18N_TRACKING_SEARCH_LABEL_SUBJECT"
        data-test="TRACKING_SUBJECT"
        formControlName="subject"
        errorPrefix="SUBJECT"
        class="{{ !verticalForm ? 'col-xs-2 no-padding-horizontal' : '' }}"
        data-test="tracking_subject">
      </mc-text-field>

      <mc-text-field
        label="$I18N_TRACKING_SEARCH_LABEL_SENDER_IP_ADDRESS"
        class=""
        placeholder="$I18N_TRACKING_SEARCH_PLACEHOLDER_SENDER_IP_ADDRESS"
        formControlName="senderIP"
        errorPrefix="SENDER_IP_ADDRESS"
        class="{{ !verticalForm ? 'col-xs-2 no-padding-horizontal' : '' }}"
        data-test="tracking_ip">
      </mc-text-field>

      <mc-text-field
        *ngIf="isUrlSearchEnabled"
        label="$I18N_TRACKING_SEARCH_LABEL_URL"
        formControlName="url"
        errorPrefix="URL"
        class="mc-form-search-url {{ !verticalForm ? 'col-xs-2 no-padding-horizontal' : '' }}"
        data-test="tracking_url">
      </mc-text-field>
    </ng-template>
  </div>

  <button
    *ngIf="isUrlSearchEnabled"
    type="submit"
    class="btn btn-primary {{ verticalForm ? 'pull-right' : 'pull-right form-horizontal-submit-button' }}"
    [disabled]="!formGroup.valid"
    data-test="tracking_data_submit">
    {{ '$I18N_TRACKING_SEARCH_BUTTON_LABEL' | translate }}
  </button>
</form>
