"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessagePrintService = exports.PRINT_DATE_FORMAT = void 0;
const core_1 = require("@angular/core");
const moment = require("moment");
exports.PRINT_DATE_FORMAT = 'ddd, D MMMM YYYY - HH:mm';
class MessagePrintService {
    constructor(notificationService, translation, _sanitizer) {
        this.notificationService = notificationService;
        this.translation = translation;
        this._sanitizer = _sanitizer;
    }
    printMessage(message) {
        const mailPath = message.messageInfo.recipientInfo.messageInfo;
        const printWindow = window.open('menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes', '_blank');
        printWindow.focus();
        try {
            printWindow.document.write('<html><head><title>Mimecast</title>' +
                '<script>window.onload = function(){window.print();}</script>' +
                '</head><body>');
            printWindow.document.write(`<ul><li>From (Envelope): ${mailPath.fromEnvelope} </li>`);
            printWindow.document.write(`<li>From (Header): ${mailPath.fromHeader} </li>`);
            printWindow.document.write(`<li>To: ${mailPath.to} </li>`);
            mailPath.cc.map((n) => printWindow.document.write(`<li>CC: ${n}</li>`));
            this.sanitized = this._sanitizer.sanitize(core_1.SecurityContext.HTML, mailPath.subject);
            printWindow.document.write(`<li>Subject: ${this.sanitized} </li>`);
            printWindow.document.write(`<li>Sent: ${moment(mailPath.sent).format(exports.PRINT_DATE_FORMAT)}</li>`);
            mailPath.attachments.map(x => printWindow.document.write(`<li>Attachment: ${x.filename} - Extension: ${x.extension} - Size: ${x.size} - Byte(s) </li>`));
            if (!!message.body && !!message.body.content) {
                printWindow.document.write('<hr>');
                printWindow.document.write(message.body.content);
            }
            printWindow.document.close();
            printWindow.focus();
        }
        catch (_a) {
            this.notificationService.warning({
                msg: this.translation.instant('$I18N_MESSAGE_CENTER_SERVICES.PRINT.ERROR')
            });
        }
    }
}
exports.MessagePrintService = MessagePrintService;
