"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyncTasksCardComponent = void 0;
const sync_task_card_static_1 = require("./sync-task-card.static");
class SyncTasksCardComponent {
    constructor(translateService) {
        this.translateService = translateService;
        this.title = '$I18N_SYNC_AND_RECOVER_OVERVIEW_MODAL.SYNC_TASKS_CARD.TITLE';
    }
    set data(value) {
        if (value) {
            this.setChartData(value);
        }
    }
    setChartData(data) {
        this.chartData = {
            text: this.translateService.instant('$I18N_SYNC_AND_RECOVER_OVERVIEW_MODAL.COMMON.TOTAL'),
            value: data.total,
            colors: this.getChartSegColors(),
            series: [
                {
                    name: 'Sync Task Score',
                    categories: [
                        {
                            text: this.getChartSegText(sync_task_card_static_1.SyncTaskChartSegmentTypes.ENABLED),
                            name: sync_task_card_static_1.SyncTaskChartSegmentTypes.ENABLED,
                            value: data.statusBreakdown[sync_task_card_static_1.SyncTaskChartSegmentTypes.ENABLED]
                        },
                        {
                            text: this.getChartSegText(sync_task_card_static_1.SyncTaskChartSegmentTypes.WARNING),
                            name: sync_task_card_static_1.SyncTaskChartSegmentTypes.WARNING,
                            value: data.statusBreakdown[sync_task_card_static_1.SyncTaskChartSegmentTypes.WARNING]
                        },
                        {
                            text: this.getChartSegText(sync_task_card_static_1.SyncTaskChartSegmentTypes.FAILED),
                            name: sync_task_card_static_1.SyncTaskChartSegmentTypes.FAILED,
                            value: data.statusBreakdown[sync_task_card_static_1.SyncTaskChartSegmentTypes.FAILED]
                        },
                        {
                            text: this.getChartSegText(sync_task_card_static_1.SyncTaskChartSegmentTypes.DISABLED),
                            name: sync_task_card_static_1.SyncTaskChartSegmentTypes.DISABLED,
                            value: data.statusBreakdown[sync_task_card_static_1.SyncTaskChartSegmentTypes.DISABLED]
                        }
                    ]
                }
            ]
        };
    }
    getChartSegText(text) {
        return this.translateService.instant(`$I18N_SYNC_AND_RECOVER_OVERVIEW_MODAL.SYNC_TASKS_CARD.CHART_CATEGORIES.${text.toUpperCase()}`);
    }
    getChartSegColors() {
        return Object.values(sync_task_card_static_1.SyncTaskChartColors);
    }
}
exports.SyncTasksCardComponent = SyncTasksCardComponent;
