<mc-layout-list-table [keyTitle]="'$I18N_TTP_IMPERSONATION_LOGS_TITLE'"
                      [keyDescription]="'$I18N_TTP_IMPERSONATION_LOGS_DESCRIPTION'">
  <mc-table tableId="ttp/impersonation/logs/main-table"
            [data]="tableData$ | async"
            [columns]="logsPageConfig.columns"
            [isLoading]="isLoading$ | async"
            [highlightedRow]="highlightedRow$ | async"
            (rowClick)="openItem($event)"
            translatePrefix="$I18N_TTP_IMPERSONATION_LOGS_TABLE">

    <!-- Export -->
    <button class="mc-table-actions btn btn-primary" (click)="setupAndDoExport()" [disabled]="!(tableData$ | async) || !((tableData$ | async).length)">
      {{ "$I18N_TTP_IMPERSONATION_LOGS_TABLE.BUTTON_LABEL_EXPORT" | translate }}
    </button>

    <!-- Filters -->
    <mc-filters [metadata]="metaData$ | async" (paginatorChange)="onPaginationChange($event)">
      <mc-filter-bundle-column-values-search-date-range
        placeholder="$I18N_TTP_IMPERSONATION_LOGS_TABLE.FILTERS.SEARCH_FIELD.PLACEHOLDER"
        [sections]="logsPageConfig.filterSections"
        (filtersChange)="onFilterChange($event)"
        [dateRangeConfig]="logsPageConfig.dateRangeConfig">
      </mc-filter-bundle-column-values-search-date-range>
    </mc-filters>

    <!-- Columns -->
    <mc-column key="taggedMalicious">
      <mc-body-cell *mcBodyCellDef="let row">
        <i class="far" [ngClass]="{'fa-check text-success': row.taggedMalicious, 'fa-times text-danger': !row.taggedMalicious}" aria-hidden="true"></i>
      </mc-body-cell>
    </mc-column>
    <mc-column key="eventTime">
      <mc-body-cell *mcBodyCellDef="let row">{{row.eventTime | mcDate}}</mc-body-cell>
    </mc-column>
    <mc-column key="action">
      <mc-body-cell *mcBodyCellDef="let row">{{row.action | mcImpersonationLogAction}}</mc-body-cell>
    </mc-column>

    <!-- Meatballs -->
    <mc-column-actions key="row-actions" mcShowColumnConfig [columnsAlwaysVisible]="logsPageConfig.columnsAlwaysVisible">
      <mc-row-actions *mcRowActions="let row">
        <li mcRowAction="$I18N_TTP_IMPERSONATION_LOGS_TABLE.ROW_ACTIONS.VIEW" (click)="openItem(row)"></li>
        <ng-container>
          <li mcRowActionSeparator></li>
          <li mcRowAction="$I18N_TTP_IMPERSONATION_LOGS_TABLE.ROW_ACTIONS.PERMIT_SENDER"
              (click)="permitSender.emit({sender: row.senderAddress, to: row.recipientAddress, action: 'permit'})"></li>
          <li mcRowAction="$I18N_TTP_IMPERSONATION_LOGS_TABLE.ROW_ACTIONS.BLOCK_SENDER"
              (click)="blockSender.emit({sender: row.senderAddress, to: row.recipientAddress, action: 'block'})"></li>
          <li mcRowAction="$I18N_TTP_IMPERSONATION_LOGS_TABLE.ROW_ACTIONS.MONITOR_DOMAIN"
              (click)="monitorDomain.emit(row.senderAddress)"></li>
        </ng-container>
      </mc-row-actions>
    </mc-column-actions>

    <!-- Empty search -->
    <mc-empty-results keyTitle="{{'$I18N_TTP_IMPERSONATION_LOGS_RESULTS_EMPTY_TITLE' | translate}}">
    </mc-empty-results>
  </mc-table>
</mc-layout-list-table>
