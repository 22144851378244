"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ3 = exports.ɵ2 = exports.ɵ1 = exports.ɵ0 = exports.getShowAffectedUserAddedNotification = exports.getShowDuplicateWarning = exports.getWizardFlowType = exports.getLoadingForEdit = exports.getApiIsProcessingDelete = exports.getApiIsProcessing = exports.getForceDuplicate = exports.getLogSettings = exports.getDomains = exports.getPolicyDesc = exports.getSwgPolicyCopy = exports.getSwgPolicy = exports.reducer = exports.initialState = void 0;
const models_1 = require("app-new/swg/policies/models");
const policy_actions_1 = require("../../actions/policy.actions");
const sidebar_actions_1 = require("../../actions/sidebar.actions");
const policy_actions_2 = require("../../../../services/browser-isolation/policies/actions/policy.actions");
const clp_1 = require("../../models/clp");
exports.initialState = {
    swgPolicy: {
        policyDesc: {
            description: '',
            enabled: true,
            policyType: models_1.SwgPolicyType.WEB_BLOCK_ALLOW_LIST,
            override: false
        },
        domains: [],
        loggingMode: models_1.SwgLogLevelEnum.ALL,
        safeSearchSettings: { youTube: false, google: false, bing: false },
        proxySettings: {
            enabled: false,
            avScan: false,
            biEnabled: false,
            blockUnscannable: models_1.SwgUnScannableEnum.BLOCK
        },
        protectedApplications: [],
        newDomainsSettings: { checkNod: false },
        ttpManagedUrlSettings: { useManagedUrls: false },
        ttpChameleonSettings: { chameleonEnabled: false, chameleonMode: 'warn' },
        clpPolicySettings: {
            action: clp_1.ClpPolicyActions.NOT_SELECTED,
            notificationList: [],
            connectorName: '',
            scope: {
                type: models_1.SwgClpPolicyScopeType.SPECIFIC_CONNECTOR,
                id: ''
            },
            notifyAdministrator: false
        },
        affectedUsers: [],
        biSettings: {
            clipboardTransferOut: false,
            clipboardTransferIn: false,
            sessionReadOnly: false,
            defaultPolicy: false,
            enterText: false
        },
        everyOne: true,
        webApplicationRules: [],
        webCategorySecurityRules: [],
        webCategoryOtherRules: [],
        narrative: '',
        clpPolicyWithoutConnector: false
    },
    wizardFlowType: models_1.SwgPolicyWizardFlowType.CREATE,
    loadingForEdit: false,
    foreDuplicate: false,
    apiProcessingInProgress: false,
    apiProcessingDeleteInProgress: false,
    swgPolicyCopy: null,
    showAffectedUserAddedNotification: false,
    showDuplicateWarning: false,
    affectedDevicesMap: new Map(),
    affectedDeviceGroupsMap: new Map()
};
const checkForDuplicateUser = (state, action) => {
    return state.swgPolicy.affectedUsers.some(affectedUser => {
        if (affectedUser.condition === models_1.SwgAffectedUsersEnum.USER) {
            if (affectedUser.payload.emailAddress === action.payload.payload.emailAddress) {
                return true;
            }
        }
        if (affectedUser.condition === models_1.SwgAffectedUsersEnum.GROUP) {
            if (affectedUser.payload.id === action.payload.payload.id) {
                return true;
            }
        }
        if (affectedUser.condition === models_1.SwgAffectedUsersEnum.LOCATION) {
            if (affectedUser.payload.ipRange === action.payload.payload.ipRange) {
                return true;
            }
        }
        return false;
    });
};
const ɵ0 = checkForDuplicateUser;
exports.ɵ0 = ɵ0;
const checkForDuplicateChannel = (state, action) => {
    return state.swgPolicy.clpPolicySettings.notificationList.find(n => n.condition === action.payload.condition &&
        n.teamsChannel &&
        action.payload.teamsChannel &&
        n.teamsChannel.channelId === action.payload.teamsChannel.channelId);
};
const ɵ1 = checkForDuplicateChannel;
exports.ɵ1 = ɵ1;
const checkForDuplicateClpGroup = (state, action) => {
    return state.swgPolicy.clpPolicySettings.notificationList.some(affectedUsers => {
        if (affectedUsers.condition === models_1.SwgAffectedUsersEnum.CLP_GROUP &&
            typeof action.payload.payload !== 'undefined') {
            // @ts-ignore
            return affectedUsers.payload.id === action.payload.payload.id;
        }
        return false;
    });
};
const ɵ2 = checkForDuplicateClpGroup;
exports.ɵ2 = ɵ2;
const buildClpNotificationList = (state, action) => {
    return [...state.swgPolicy.clpPolicySettings.notificationList].filter(item => {
        if (item.condition === action.payload.condition) {
            switch (item.condition) {
                case models_1.SwgAffectedUsersEnum.CLP_USER:
                    return item.payload.emailAddress !== action.payload.payload.emailAddress;
                case models_1.SwgAffectedUsersEnum.CLP_GROUP:
                    return item.payload.id !== action.payload.payload.id;
                case models_1.SwgAffectedUsersEnum.CHANNEL:
                    return item.teamsChannel.channelId !== action.payload.teamsChannel.channelId;
                default:
                    return true;
            }
        }
        return true;
    });
};
const ɵ3 = buildClpNotificationList;
exports.ɵ3 = ɵ3;
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case policy_actions_1.SwgPolicyCreateTypesEnum.SWG_CREATE_POLICY:
            return Object.assign(Object.assign({}, state), { apiProcessingInProgress: true });
        case policy_actions_1.SwgPolicyCreateTypesEnum.SWG_CREATE_POLICY_SUCCESS:
            return Object.assign(Object.assign({}, exports.initialState), { apiProcessingInProgress: false });
        case policy_actions_1.SwgPolicyCreateTypesEnum.SWG_CREATE_POLICY_FAIL:
            return Object.assign(Object.assign({}, state), { apiProcessingInProgress: false });
        case policy_actions_1.SwgPolicyCreateTypesEnum.SWG_UPDATE_POLICY_ENABLED_STATUS_STEP:
            return Object.assign(Object.assign({}, state), { swgPolicy: Object.assign(Object.assign({}, state.swgPolicy), { policyDesc: Object.assign(Object.assign({}, state.swgPolicy.policyDesc), { enabled: action.payload }) }) });
        case policy_actions_1.SwgPolicyCreateTypesEnum.SWG_POLICY_FORCE_DUPLICATE:
            return Object.assign(Object.assign({}, state), { foreDuplicate: true });
        case policy_actions_1.SwgPolicyCreateTypesEnum.SWG_UPDATE_POLICY_DETAILS_STEP:
            return Object.assign(Object.assign({}, state), { swgPolicy: Object.assign(Object.assign({}, state.swgPolicy), { policyDesc: Object.assign(Object.assign({}, state.swgPolicy.policyDesc), { description: action.payload.description, policyType: action.payload.policyType, enabled: action.payload.enabled }) }) });
        case policy_actions_1.SwgPolicyCreateTypesEnum.SWG_UPDATE_LOG_LEVEL_STEP:
            return Object.assign(Object.assign({}, state), { swgPolicy: Object.assign(Object.assign({}, state.swgPolicy), { loggingMode: action.payload }) });
        case policy_actions_1.SwgPolicyCreateTypesEnum.SWG_UPDATE_SAFE_SEARCH_SETTINGS_STEP:
            return Object.assign(Object.assign({}, state), { swgPolicy: Object.assign(Object.assign({}, state.swgPolicy), { safeSearchSettings: action.payload }) });
        case policy_actions_1.SwgPolicyCreateTypesEnum.SWG_UPDATE_DNS_PROXY_SETTINGS_STEP:
            return Object.assign(Object.assign({}, state), { swgPolicy: Object.assign(Object.assign({}, state.swgPolicy), { proxySettings: action.payload }) });
        case policy_actions_1.SwgPolicyCreateTypesEnum.SWG_UPDATE_NEW_DOMAINS_SETTINGS_STEP:
            return Object.assign(Object.assign({}, state), { swgPolicy: Object.assign(Object.assign({}, state.swgPolicy), { newDomainsSettings: action.payload }) });
        case policy_actions_1.SwgPolicyCreateTypesEnum.SWG_UPDATE_WEB_APPLICATION_RULE_STEP:
            return Object.assign(Object.assign({}, state), { swgPolicy: Object.assign(Object.assign({}, state.swgPolicy), { webApplicationRules: action.payload }) });
        case policy_actions_1.SwgPolicyCreateTypesEnum.BI_POLICY_UPDATE_SETTINGS:
            return Object.assign(Object.assign({}, state), { swgPolicy: Object.assign(Object.assign({}, state.swgPolicy), { biSettings: action.payload }) });
        case policy_actions_1.SwgPolicyCreateTypesEnum.SWG_UPDATE_CLP_POLICY_SETTINGS_STEP:
            return Object.assign(Object.assign({}, state), { swgPolicy: Object.assign(Object.assign({}, state.swgPolicy), { clpPolicySettings: action.payload }) });
        case policy_actions_1.SwgPolicyCreateTypesEnum.SWG_UPDATE_DOMAINS_STEP:
            return Object.assign(Object.assign({}, state), { swgPolicy: Object.assign(Object.assign({}, state.swgPolicy), { domains: action.payload }) });
        case policy_actions_1.SwgPolicyCreateTypesEnum.SWG_APPEND_DOMAINS_STEP:
            return Object.assign(Object.assign({}, state), { swgPolicy: Object.assign(Object.assign({}, state.swgPolicy), { domains: [...state.swgPolicy.domains, ...action.payload] }) });
        case policy_actions_1.SwgPolicyCreateTypesEnum.SWG_CLEAR_AFFECTED_USERS_WARNING_STEP:
            return Object.assign(Object.assign({}, state), { showDuplicateWarning: false, showAffectedUserAddedNotification: false });
        case policy_actions_1.SwgPolicyCreateTypesEnum.SWG_UPDATE_AFFECTED_USERS_STEP:
            return checkForDuplicateUser(state, action)
                ? Object.assign(Object.assign({}, state), { showDuplicateWarning: true }) : Object.assign(Object.assign({}, state), { swgPolicy: Object.assign(Object.assign({}, state.swgPolicy), { affectedUsers: [...state.swgPolicy.affectedUsers, action.payload], everyOne: false }), showAffectedUserAddedNotification: true });
        case policy_actions_1.SwgPolicyCreateTypesEnum.SWG_UPDATE_CLP_NOTIFICATION_LIST_STEP:
            if (checkForDuplicateChannel(state, action) || checkForDuplicateClpGroup(state, action)) {
                return Object.assign(Object.assign({}, state), { showDuplicateWarning: true });
            }
            return Object.assign(Object.assign({}, state), { swgPolicy: Object.assign(Object.assign({}, state.swgPolicy), { clpPolicySettings: Object.assign(Object.assign({}, state.swgPolicy.clpPolicySettings), { notificationList: [
                            ...state.swgPolicy.clpPolicySettings.notificationList,
                            action.payload
                        ] }) }), showAffectedUserAddedNotification: true });
        case policy_actions_1.SwgPolicyCreateTypesEnum.SWG_REMOVE_FROM_CLP_NOTIFICATION_LIST_STEP:
            return Object.assign(Object.assign({}, state), { swgPolicy: Object.assign(Object.assign({}, state.swgPolicy), { clpPolicySettings: Object.assign(Object.assign({}, state.swgPolicy.clpPolicySettings), { notificationList: buildClpNotificationList(state, action) }) }) });
        case policy_actions_1.SwgPolicyCreateTypesEnum.SWG_REMOVE_AFFECTED_USERS_STEP:
            const newAffectedUsers = [...state.swgPolicy.affectedUsers].filter(affectedUser => {
                switch (affectedUser.condition) {
                    case models_1.SwgAffectedUsersEnum.USER:
                        return affectedUser.payload.emailAddress !== action.payload.payload.emailAddress;
                    case models_1.SwgAffectedUsersEnum.GROUP:
                        return affectedUser.payload.id !== action.payload.payload.id;
                    case models_1.SwgAffectedUsersEnum.LOCATION:
                        return (affectedUser.payload.ipRange !== action.payload.payload.ipRange &&
                            affectedUser.payload.description !== action.payload.payload.description);
                    case models_1.SwgAffectedUsersEnum.WEB_DEVICE:
                        if (action.payload.device) {
                            state.affectedDevicesMap.delete(action.payload.device.id);
                            return (affectedUser.device.id !== action.payload.device.id ||
                                affectedUser.device.description !== action.payload.device.description);
                        }
                        else {
                            return true;
                        }
                    case models_1.SwgAffectedUsersEnum.WEB_DEVICE_GROUP:
                        if (action.payload.deviceGroup) {
                            state.affectedDeviceGroupsMap.delete(action.payload.deviceGroup.id);
                            return (affectedUser.deviceGroup.id !== action.payload.deviceGroup.id ||
                                affectedUser.deviceGroup.description !== action.payload.deviceGroup.description);
                        }
                        else {
                            return true;
                        }
                    default:
                        return false;
                }
            });
            return Object.assign(Object.assign({}, state), { swgPolicy: Object.assign(Object.assign({}, state.swgPolicy), { affectedUsers: newAffectedUsers, everyOne: false }) });
        case policy_actions_1.SwgPolicyCreateTypesEnum.SWG_EVERYONE_AFFECTED_USERS_STEP:
            return Object.assign(Object.assign({}, state), { swgPolicy: Object.assign(Object.assign({}, state.swgPolicy), { everyOne: action.payload }) });
        case policy_actions_1.SwgPolicyCreateTypesEnum.SWG_UPDATE_TTP_MANAGED_URL_SETTINGS_STEP:
            return Object.assign(Object.assign({}, state), { swgPolicy: Object.assign(Object.assign({}, state.swgPolicy), { ttpManagedUrlSettings: action.payload }) });
        case policy_actions_1.SwgPolicyCreateTypesEnum.SWG_UPDATE_TTP_CHAMELEON_SETTINGS_STEP:
            return Object.assign(Object.assign({}, state), { swgPolicy: Object.assign(Object.assign({}, state.swgPolicy), { ttpChameleonSettings: action.payload }) });
        case policy_actions_1.SwgPolicyCreateTypesEnum.SWG_UPDATE_WEB_CATEGORY_SECURITY_STEP:
            return Object.assign(Object.assign({}, state), { swgPolicy: Object.assign(Object.assign({}, state.swgPolicy), { webCategorySecurityRules: action.payload }) });
        case policy_actions_1.SwgPolicyCreateTypesEnum.SWG_UPDATE_WEB_CATEGORY_OTHERS_STEP:
            return Object.assign(Object.assign({}, state), { swgPolicy: Object.assign(Object.assign({}, state.swgPolicy), { webCategoryOtherRules: action.payload }) });
        case policy_actions_1.SwgPolicyCreateTypesEnum.SWG_UPDATE_PROTECTEDAPPLICATIONS_STEP:
            return Object.assign(Object.assign({}, state), { swgPolicy: Object.assign(Object.assign({}, state.swgPolicy), { protectedApplications: action.payload }) });
        case policy_actions_1.SwgPolicyCreateTypesEnum.SWG_UPDATE_POLICY_DESCRIPTION:
            return Object.assign(Object.assign({}, state), { swgPolicy: Object.assign(Object.assign({}, state.swgPolicy), { policyDesc: Object.assign(Object.assign({}, state.swgPolicy.policyDesc), { description: 'Copy of ' + state.swgPolicy.policyDesc.description }) }) });
        case policy_actions_1.SwgPolicyCreateTypesEnum.SWG_POLICY_DELETE:
            return Object.assign(Object.assign({}, state), { apiProcessingDeleteInProgress: true });
        case policy_actions_1.SwgPolicyCreateTypesEnum.SWG_POLICY_DELETE_SUCCESS:
            return Object.assign(Object.assign({}, exports.initialState), { apiProcessingDeleteInProgress: false });
        case policy_actions_1.SwgPolicyCreateTypesEnum.SWG_POLICY_DELETE_FAILURE:
            return Object.assign(Object.assign({}, exports.initialState), { apiProcessingDeleteInProgress: false });
        case sidebar_actions_1.SwgSidebarActionsEnum.SWG_POLICY_NAVIGATE_TO_EDIT_PAGE:
            return Object.assign(Object.assign({}, state), { wizardFlowType: action.payload });
        case sidebar_actions_1.SwgSidebarActionsEnum.SWG_POLICY_DELETE_CONFIRM_MODEL_OPEN:
        case sidebar_actions_1.SwgSidebarActionsEnum.SWG_POLICY_DISABLE_CONFIRM_MODEL_OPEN:
        case sidebar_actions_1.SwgSidebarActionsEnum.SWG_POLICY_EDIT_SIDEBAR_OPEN:
            return Object.assign(Object.assign({}, state), { swgPolicy: Object.assign(Object.assign({}, state.swgPolicy), { policyDesc: action.payload }) });
        case policy_actions_1.SwgPolicyCreateTypesEnum.SWG_LOAD_POLICY_TO_EDIT:
            return Object.assign(Object.assign({}, state), { swgPolicy: Object.assign(Object.assign({}, state.swgPolicy), { policyDesc: action.payload }), loadingForEdit: true });
        case policy_actions_1.SwgPolicyCreateTypesEnum.SWG_LOAD_POLICY_TO_EDIT_SUCCESS:
            if (action.payload.affectedUsers && action.payload.affectedUsers.length) {
                action.payload.affectedUsers.forEach(affectedUser => {
                    if (affectedUser.condition === models_1.SwgAffectedUsersEnum.WEB_DEVICE) {
                        state.affectedDevicesMap.set(affectedUser.device.id, affectedUser.device);
                    }
                    if (affectedUser.condition === models_1.SwgAffectedUsersEnum.WEB_DEVICE_GROUP) {
                        state.affectedDeviceGroupsMap.set(affectedUser.deviceGroup.id, affectedUser.deviceGroup);
                    }
                });
            }
            return Object.assign(Object.assign({}, state), { swgPolicy: Object.assign({}, action.payload), swgPolicyCopy: Object.assign({}, action.payload), loadingForEdit: false });
        case policy_actions_1.SwgPolicyCreateTypesEnum.SWG_LOAD_POLICY_TO_EDIT_FAILURE:
            return Object.assign(Object.assign({}, exports.initialState), { loadingForEdit: false });
        case sidebar_actions_1.SwgSidebarActionsEnum.SWG_POLICY_UNCHANGED_DUPLICATE_WARN_OPEN:
            return Object.assign(Object.assign({}, state), { apiProcessingInProgress: false });
        case sidebar_actions_1.SwgSidebarActionsEnum.SWG_POLICY_UNCHANGED_DUPLICATE_WARN_CLOSE:
            return Object.assign(Object.assign({}, state), { apiProcessingInProgress: false });
        case policy_actions_1.SwgPolicyCreateTypesEnum.SWG_CLEAR_POLICY:
            return Object.assign(Object.assign({}, exports.initialState), { affectedDevicesMap: new Map(), affectedDeviceGroupsMap: new Map() });
        case policy_actions_2.BiPolicyActionsEnums.BI_POLICY_SAVE:
            return Object.assign(Object.assign({}, state), { apiProcessingInProgress: true });
        case policy_actions_2.BiPolicyActionsEnums.BI_POLICY_SAVE_SUCCESS:
            return Object.assign(Object.assign({}, exports.initialState), { apiProcessingInProgress: false });
        case policy_actions_1.SwgPolicyCreateTypesEnum.BI_UPDATE_POLICY_DETAILS_STEP:
            return Object.assign(Object.assign({}, state), { swgPolicy: Object.assign(Object.assign({}, state.swgPolicy), { policyDesc: Object.assign(Object.assign({}, state.swgPolicy.policyDesc), { description: action.payload.policyDesc.description }), narrative: action.payload.narrative }) });
        case policy_actions_1.SwgPolicyCreateTypesEnum.CLP_POLICY_CHECK_FOR_CONNECTOR:
            return Object.assign(Object.assign({}, state), { swgPolicy: Object.assign(Object.assign({}, state.swgPolicy), { policyDesc: action.payload }), loadingForEdit: true });
        case policy_actions_1.SwgPolicyCreateTypesEnum.CLP_POLICY_CHECK_FOR_CONNECTOR_FAILURE:
            return Object.assign(Object.assign({}, state), { swgPolicy: Object.assign(Object.assign({}, state.swgPolicy), { clpPolicyWithoutConnector: false }), loadingForEdit: false });
        case policy_actions_1.SwgPolicyCreateTypesEnum.SWG_UPDATE_AFFECTED_DEVICE_GROUPS:
            const deviceGroups = [...action.payload];
            deviceGroups.forEach((group) => {
                state.affectedDeviceGroupsMap.set(group.id, {
                    id: group.id,
                    description: group.name
                });
            });
            const affectedDeviceGroups = Array.from(state.affectedDeviceGroupsMap.values());
            const deviceGroupsToAdd = affectedDeviceGroups.map(group => ({
                condition: models_1.SwgAffectedUsersEnum.WEB_DEVICE_GROUP,
                deviceGroup: group
            }));
            return Object.assign(Object.assign({}, state), { swgPolicy: Object.assign(Object.assign({}, state.swgPolicy), { affectedUsers: [
                        ...state.swgPolicy.affectedUsers.filter(x => x.condition !== models_1.SwgAffectedUsersEnum.WEB_DEVICE_GROUP),
                        ...deviceGroupsToAdd
                    ], everyOne: false }), showAffectedUserAddedNotification: true });
        case policy_actions_1.SwgPolicyCreateTypesEnum.SWG_UPDATE_AFFECTED_DEVICES:
            const devices = [...action.payload];
            devices.forEach((device) => {
                state.affectedDevicesMap.set(device.deviceId, {
                    id: device.deviceId,
                    description: device.deviceName
                });
            });
            const affectedDevices = Array.from(state.affectedDevicesMap.values());
            const devicesToAdd = affectedDevices.map(device => ({
                condition: models_1.SwgAffectedUsersEnum.WEB_DEVICE,
                device
            }));
            return Object.assign(Object.assign({}, state), { swgPolicy: Object.assign(Object.assign({}, state.swgPolicy), { affectedUsers: [
                        ...state.swgPolicy.affectedUsers.filter(x => x.condition !== models_1.SwgAffectedUsersEnum.WEB_DEVICE),
                        ...devicesToAdd
                    ], everyOne: false }), showAffectedUserAddedNotification: true });
        case policy_actions_1.SwgPolicyCreateTypesEnum.SWG_UPDATE_POLICY_OVERRIDE_STATUS:
            return Object.assign(Object.assign({}, state), { swgPolicy: Object.assign(Object.assign({}, state.swgPolicy), { policyDesc: Object.assign(Object.assign({}, state.swgPolicy.policyDesc), { override: action.payload }) }) });
        case policy_actions_1.SwgPolicyCreateTypesEnum.SWG_CLEAR_AFFECTED_USERS_OTHER_THAN:
            let affectedUsers = [];
            if (action.payload === 'multi') {
                affectedUsers = state.swgPolicy.affectedUsers.filter(target => target.condition !== models_1.SwgAffectedUsersEnum.WEB_DEVICE &&
                    target.condition !== models_1.SwgAffectedUsersEnum.WEB_DEVICE_GROUP);
            }
            if (action.payload === 'deviceBased') {
                affectedUsers = state.swgPolicy.affectedUsers.filter(target => target.condition === models_1.SwgAffectedUsersEnum.WEB_DEVICE ||
                    target.condition === models_1.SwgAffectedUsersEnum.WEB_DEVICE_GROUP);
            }
            state.affectedDevicesMap.clear();
            state.affectedDeviceGroupsMap.clear();
            return Object.assign(Object.assign({}, state), { swgPolicy: Object.assign(Object.assign({}, state.swgPolicy), { affectedUsers: [...affectedUsers], everyOne: false }) });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getSwgPolicy = (state) => state.swgPolicy;
exports.getSwgPolicy = getSwgPolicy;
const getSwgPolicyCopy = (state) => state.swgPolicyCopy;
exports.getSwgPolicyCopy = getSwgPolicyCopy;
const getPolicyDesc = (state) => state.swgPolicy.policyDesc;
exports.getPolicyDesc = getPolicyDesc;
const getDomains = (state) => state.swgPolicy.domains;
exports.getDomains = getDomains;
// @ts-ignore
const getLogSettings = (state) => state.swgPolicy.logSettings;
exports.getLogSettings = getLogSettings;
const getForceDuplicate = (state) => state.foreDuplicate;
exports.getForceDuplicate = getForceDuplicate;
const getApiIsProcessing = (state) => state.apiProcessingInProgress;
exports.getApiIsProcessing = getApiIsProcessing;
const getApiIsProcessingDelete = (state) => state.apiProcessingDeleteInProgress;
exports.getApiIsProcessingDelete = getApiIsProcessingDelete;
const getLoadingForEdit = (state) => state.loadingForEdit;
exports.getLoadingForEdit = getLoadingForEdit;
const getWizardFlowType = (state) => state.wizardFlowType;
exports.getWizardFlowType = getWizardFlowType;
const getShowDuplicateWarning = (state) => state.showDuplicateWarning;
exports.getShowDuplicateWarning = getShowDuplicateWarning;
const getShowAffectedUserAddedNotification = (state) => state.showAffectedUserAddedNotification;
exports.getShowAffectedUserAddedNotification = getShowAffectedUserAddedNotification;
