<mc-modal-header headerTitle="{{'$I18N_CHANGE_CONFIRMATION_MODAL.TITLE' | translate:{ changeType: changeType } }}">
</mc-modal-header>

<mc-modal-body>
  <div class="revoke-permissions-header">
    <span class="fas fa-exclamation-triangle text-warning"></span>
    {{ '$I18N_CHANGE_CONFIRMATION_MODAL.REVOKE_PERMISSIONS_HEADER' | translate }}</div>
  <div>
    <p class="help-text">
      {{ '$I18N_CHANGE_CONFIRMATION_MODAL.CHANGE_MESSAGE.' + messageTranslationKey  
          | translate:{ changeType: changeType, originalProduct: originalProduct, selectedProvider: selectedProvider } }}
    </p>
  </div>
</mc-modal-body>

<mc-modal-footer>
  <button type="button" class="btn btn-secondary" (click)="close(false)">
    {{ '$I18N_CHANGE_CONFIRMATION_MODAL.NO' | translate }}
  </button>

  <button type="button" class="btn btn-danger" (click)="close(true)">
    {{ '$I18N_CHANGE_CONFIRMATION_MODAL.YES' | translate }}
  </button>
</mc-modal-footer>
