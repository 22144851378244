"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteConnectorModalComponent = void 0;
const model_1 = require("../../model");
const reducers_1 = require("../../reducers");
class DeleteConnectorModalComponent {
    constructor(overlayRef, translateService, overlayData, store) {
        this.overlayRef = overlayRef;
        this.translateService = translateService;
        this.overlayData = overlayData;
        this.store = store;
        this.deleteDirSyncUnusedKbLink = 'https://community.mimecast.com/s/article/Mimecast-Connectors-Applications-imported-via-consent-workflow';
        this.connectorName = this.overlayData.connectorName;
        this.provider = this.translateService.instant(`$I18N_CONNECTORS.PROVIDERS.${this.overlayData.provider.toUpperCase()}`);
    }
    ngOnInit() {
        this.loadingUsage$ = this.store.select(reducers_1.loadingConnectorUsage);
        this.connectorUsage$ = this.store.select(reducers_1.getConnectorUsage);
    }
    close(confirmed) {
        this.overlayRef.close(confirmed);
    }
    isAzureStandardProvider() {
        return this.overlayData.provider === model_1.ProviderType.AZURE_STANDARD;
    }
    isAzureGCCProvider() {
        return this.overlayData.provider === model_1.ProviderType.AZURE_GCC;
    }
    connectorIsUsedTitle() {
        return `$I18N_DELETE_CONNECTOR_MODAL.${this.isAzureStandardProvider() || this.isAzureGCCProvider()
            ? 'TITLE_IN_USE_DIR_SYNC'
            : 'TITLE_IN_USE'}`;
    }
    connectorIsUsedDescription() {
        return `$I18N_DELETE_CONNECTOR_MODAL.${this.isAzureStandardProvider() || this.isAzureGCCProvider()
            ? 'DELETE_DIR_SYNC'
            : 'DELETE_POLICIES'}`;
    }
    connectorNotUsedDescription1() {
        return `${this.isAzureStandardProvider() || this.isAzureGCCProvider()
            ? '$I18N_DELETE_CONNECTOR_MODAL.DELETE_DIR_SYNC_UNUSED_1'
            : ''}`;
    }
    connectorNotUsedDescription2() {
        return `${this.isAzureStandardProvider() || this.isAzureGCCProvider()
            ? '$I18N_DELETE_CONNECTOR_MODAL.DELETE_DIR_SYNC_UNUSED_2'
            : ''}`;
    }
}
exports.DeleteConnectorModalComponent = DeleteConnectorModalComponent;
