'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
/*
*
<mc-load-more-results
 on-click-load-more="loadMoreData()" - call back on infinite scroll and onClick 'Show more results'.
 is-loading="{{isLoading}}" - info if data is loading to show spinner on data load
 has-more-results="{{results-list}}"> - info if has more data to load
</mc-load-more-results>
*
*
* */
document.createElement('mc-load-more-results');
angular.module('load-more-results.directive', [])
    .directive('mcLoadMoreResults', [function () {
        return {
            restrict: 'AE',
            templateUrl: 'services/services/backup/components/load-more-results/load-more-results.tpl.html',
            scope: {
                onClickLoadMore: '&onClickLoadMore',
                isLoading: '@isLoading',
                hasMoreResults: '@hasMoreResults'
            },
            link($scope) {
                $scope.onLoadMore = function () {
                    return $scope.onClickLoadMore();
                };
            }
        };
    }]);
