<div class="mc-api-application-step-block">
  <mc-wizard-step-header [label]="'$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.NOTIFICATIONS.HEADER'"></mc-wizard-step-header>

  <mc-api-integration-notification
    [emails]="emails"
    [groupsList]="groupsList"
    (onDeleteEmail)="deleteEmail($event)"
    (onDeleteGroup)="deleteGroup($event)"
    (onAddGroup)="addGroup($event)"
    (onVerifyAndAddEmail)="verifyAndAddEmail($event)"
  >
  </mc-api-integration-notification>
</div>
