"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HealthScoreCardComponent = void 0;
const health_score_card_static_1 = require("./health-score-card.static");
class HealthScoreCardComponent {
    set data(value) {
        if (value) {
            this.setChartData(value);
        }
    }
    setChartData(data) {
        this.chartData = {
            text: '',
            value: data.score,
            colors: this.getHealtScoreColors(data),
            series: [
                {
                    name: 'Health Score',
                    categories: [
                        {
                            value: data.score
                        },
                        {
                            value: 100 - data.score
                        }
                    ]
                }
            ]
        };
    }
    getHealtScoreColors(data) {
        const { criticalToWarning, warningToGood } = data.threshold;
        const score = data.score;
        if (score >= warningToGood) {
            this.labelColor = health_score_card_static_1.HealthScoreCardColors.success;
        }
        else if (score < warningToGood && score >= criticalToWarning) {
            this.labelColor = health_score_card_static_1.HealthScoreCardColors.warning;
        }
        else if (score < criticalToWarning) {
            this.labelColor = health_score_card_static_1.HealthScoreCardColors.critical;
        }
        else {
            this.labelColor = health_score_card_static_1.HealthScoreCardColors.default;
        }
        return [this.labelColor, health_score_card_static_1.HealthScoreCardColors.default];
    }
}
exports.HealthScoreCardComponent = HealthScoreCardComponent;
