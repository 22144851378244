"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClearAppliesToUsers = exports.LoadAppliesToUsersPaginationReset = exports.LoadAppliesToUsersPagination = exports.LoadAppliesToUsersFailure = exports.LoadAppliesToUsersSuccess = exports.LoadAppliesToUsers = exports.CLEAR_APPLIES_TO_USERS = exports.LOAD_APPLIES_TO_USERS_PAGINATION_RESET = exports.LOAD_APPLIES_TO_USERS_PAGINATION = exports.LOAD_APPLIES_TO_USERS_FAILURE = exports.LOAD_APPLIES_TO_USERS_SUCCESS = exports.LOAD_APPLIES_TO_USERS = void 0;
exports.LOAD_APPLIES_TO_USERS = '[Applies_to] load applies to Users';
exports.LOAD_APPLIES_TO_USERS_SUCCESS = '[Applies_to] load applies to Users success';
exports.LOAD_APPLIES_TO_USERS_FAILURE = '[Applies_to] load applies to Users failure';
exports.LOAD_APPLIES_TO_USERS_PAGINATION = '[Applies_to] applies to Users pagination';
exports.LOAD_APPLIES_TO_USERS_PAGINATION_RESET = '[Applies_to] applies to Users pagination reset';
exports.CLEAR_APPLIES_TO_USERS = '[Applies_to] clear applies to Users';
class LoadAppliesToUsers {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOAD_APPLIES_TO_USERS;
    }
}
exports.LoadAppliesToUsers = LoadAppliesToUsers;
class LoadAppliesToUsersSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOAD_APPLIES_TO_USERS_SUCCESS;
    }
}
exports.LoadAppliesToUsersSuccess = LoadAppliesToUsersSuccess;
class LoadAppliesToUsersFailure {
    constructor() {
        this.type = exports.LOAD_APPLIES_TO_USERS_FAILURE;
    }
}
exports.LoadAppliesToUsersFailure = LoadAppliesToUsersFailure;
class LoadAppliesToUsersPagination {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOAD_APPLIES_TO_USERS_PAGINATION;
    }
}
exports.LoadAppliesToUsersPagination = LoadAppliesToUsersPagination;
class LoadAppliesToUsersPaginationReset {
    constructor() {
        this.type = exports.LOAD_APPLIES_TO_USERS_PAGINATION_RESET;
    }
}
exports.LoadAppliesToUsersPaginationReset = LoadAppliesToUsersPaginationReset;
class ClearAppliesToUsers {
    constructor() {
        this.type = exports.CLEAR_APPLIES_TO_USERS;
    }
}
exports.ClearAppliesToUsers = ClearAppliesToUsers;
