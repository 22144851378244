"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportApiPageComponent = void 0;
const store_1 = require("@ngrx/store");
const request_actions_1 = require("../actions/request.actions");
const fromReducers = require("../reducers");
class ExportApiPageComponent {
    constructor(store, overlayRef, data) {
        this.store = store;
        this.overlayRef = overlayRef;
        this.data = data;
    }
    ngOnInit() {
        this.isLoading$ = this.store.pipe(store_1.select(fromReducers.isLoading));
    }
    download(options) {
        this.store.dispatch(new request_actions_1.GetFileIdExportApiAction(this.data.apiExportUrl, this.data.searchPayload, options.columnsToExport, options.fileFormat));
    }
    close() {
        this.overlayRef.close();
    }
}
exports.ExportApiPageComponent = ExportApiPageComponent;
