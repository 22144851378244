"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Contact = void 0;
const contents_actions_1 = require("app-new/components/tree/actions/contents.actions");
const content_type_1 = require("app-new/sync-recover/exchange/containers/snapshot/detail/models/content-type/content-type");
class Contact extends content_type_1.ContentType {
    constructor() {
        super(['name', 'company', 'emailAddress', 'modified']);
        this.CONTENT_TYPE = 'CONTACT';
    }
    getLoadContentsAction(payload) {
        return new contents_actions_1.LoadFolderContactsAction(payload);
    }
    getContentType() {
        return this.CONTENT_TYPE;
    }
    getSearchContentsAction(payload) {
        return new contents_actions_1.LoadSearchFolderContactsAction(payload);
    }
}
exports.Contact = Contact;
