"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasGenericError = exports.isDownloadBinaryDisabled = exports.isAuthDeleting = exports.isAuthLoading = exports.getEndpointsAuth = exports.reducer = exports.initialState = void 0;
const install_actions_1 = require("../actions/install.actions");
exports.initialState = {
    authToken: null,
    isLoading: false,
    isDeleting: false,
    genericError: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case install_actions_1.SwgEndpointsActions.GET_AUTH_KEY:
            return Object.assign(Object.assign({}, state), { isLoading: true, genericError: false });
        case install_actions_1.SwgEndpointsActions.GET_AUTH_KEY_SUCCESS:
            return Object.assign(Object.assign({}, state), { authToken: action.payload, isLoading: false, genericError: false });
        case install_actions_1.SwgEndpointsActions.DELETE_AUTH_KEY:
            return Object.assign(Object.assign({}, state), { isLoading: false, isDeleting: true, genericError: false });
        case install_actions_1.SwgEndpointsActions.DELETE_AUTH_KEY_SUCCESS:
            return Object.assign(Object.assign({}, state), { authToken: null, isDeleting: false, genericError: false });
        case install_actions_1.SwgEndpointsActions.AUTH_KEY_FAILURE:
            return Object.assign(Object.assign({}, state), { isLoading: false, isDeleting: false, genericError: true });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getEndpointsAuth = (state) => state.authToken;
exports.getEndpointsAuth = getEndpointsAuth;
const isAuthLoading = (state) => state.isLoading;
exports.isAuthLoading = isAuthLoading;
const isAuthDeleting = (state) => state.isDeleting;
exports.isAuthDeleting = isAuthDeleting;
const isDownloadBinaryDisabled = (state) => {
    const hasAuthToken = !!state.authToken && !!state.authToken.authToken;
    return !(hasAuthToken || (state.isDeleting && !state.isLoading));
};
exports.isDownloadBinaryDisabled = isDownloadBinaryDisabled;
const hasGenericError = (state) => state.genericError;
exports.hasGenericError = hasGenericError;
