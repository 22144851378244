"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DomainPageComponent = void 0;
const rxjs_1 = require("rxjs");
const external_domain_list_actions_1 = require("../../actions/external-domain-list.actions");
const fromChameleonState = require("../../reducers");
const edit_domain_actions_1 = require("../../actions/edit-domain.actions");
const reducers = require("app-new/ttp/chameleon/monitored-external-domain/reducers");
class DomainPageComponent {
    constructor(store) {
        this.store = store;
        this.externalTableSearchCount = 0;
        this.externalTableSearchCount$ = new rxjs_1.BehaviorSubject(this.externalTableSearchCount);
        this.isSelectedAll$ = this.store.select(fromChameleonState.getIsSelectedAll);
        this.allowDelete$ = this.store.select(fromChameleonState.getAllowDelete);
        this.selectedRow$ = this.store.select(fromChameleonState.getSelectedRow);
        this.isDeleting$ = this.store.select(fromChameleonState.getIsDeleting);
        this.setupSubscriptions();
    }
    ngOnDestroy() {
        this.deleteSuccessSubscription.unsubscribe();
    }
    setupSubscriptions() {
        this.deleteSuccessSubscription = this.store
            .select(reducers.getDeleteSuccess)
            .subscribe(success => {
            if (success) {
                this.externalTableSearchCount += 1;
                this.externalTableSearchCount$.next(this.externalTableSearchCount);
            }
        });
    }
    onSelectAll(selectedItems) {
        this.store.dispatch(new external_domain_list_actions_1.SelectAll(selectedItems));
    }
    onUnSelectAll() {
        this.store.dispatch(new external_domain_list_actions_1.UnSelectAll());
    }
    onSelectSingleItem(eventData) {
        this.store.dispatch(eventData.isSelection
            ? new external_domain_list_actions_1.SelectListItem(eventData.selectedItem)
            : new external_domain_list_actions_1.UnSelectListItem(eventData.selectedItem));
    }
    onAddDomains() {
        this.store.dispatch(new external_domain_list_actions_1.AddDomains());
    }
    onDeleteItem(domain) {
        // Todo - replace this as it relies on first action completing before second
        this.store.dispatch(new external_domain_list_actions_1.SelectListItem(domain));
        this.store.dispatch(new external_domain_list_actions_1.DeleteAlertOpen());
    }
    onDeleteItems() {
        this.store.dispatch(new external_domain_list_actions_1.DeleteAlertOpen());
    }
    onRowClick(row) {
        this.store.dispatch(new edit_domain_actions_1.EditDomainActions(row));
    }
}
exports.DomainPageComponent = DomainPageComponent;
