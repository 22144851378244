<div class="mc-api-integration-step-block">
  <mc-wizard-step-header [label]="'$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.DISCLAIMER.HEADER'">
  </mc-wizard-step-header>

  <div class="mc-detailed-list mc-api-integration-wildfire-disclaimer-block">
    <p class="legal-disclaimer">
        {{ '$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.DISCLAIMER.DISCLAIMER_PART_1' | translate }}<br><br>
        {{ '$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.DISCLAIMER.DISCLAIMER_PART_2' | translate }}<br><br>
        {{ '$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.DISCLAIMER.DISCLAIMER_PART_3' | translate }}<br><br>
        {{ '$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.DISCLAIMER.DISCLAIMER_PART_4' | translate }}
    </p>
    <ol type="1">
      <li>{{ '$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.DISCLAIMER.DISCLAIMER_PART_4_LIST1' | translate }}</li>
      <li>{{ '$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.DISCLAIMER.DISCLAIMER_PART_4_LIST2' | translate }}</li>
      <li>{{ '$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.DISCLAIMER.DISCLAIMER_PART_4_LIST3' | translate }}</li>
    </ol>
    <form 
      class="form-horizontal"
      mcDefaultLabelClass="col-sm-2"
      mcDefaultControlContainerClass="col-sm-6"
      [formGroup]="form">
      
      <mc-checkbox
          formControlName="acceptSharingInfo"
          class="mc-checkbox-disclaimer"
          label="{{ '$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.DISCLAIMER.ACCEPT_TERMS' | translate }}"
          ></mc-checkbox>
    </form>
  </div>
</div>
