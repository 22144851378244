"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const moment = require("moment");
const _ = require("lodash");
const ttp_impersonation_log_service_1 = require("./model/ttp-impersonation-log-service");
(function () {
    'use strict';
    angular
        .module('services.services.monitoring.ttp-impersonation.controller', [])
        .controller('TtpImpersonationController', [
        '$scope',
        '$state',
        '$stateParams',
        '$translate',
        '$aside',
        'ttpImpersonationService',
        'adconCommonsService',
        'exportLogsAsideService',
        'monitoringService',
        'capabilitiesService',
        function ($scope, $state, $stateParams, $translate, $aside, ttpImpersonationService, adconCommonsService, exportLogsAsideService, monitoringService, capabilitiesService) {
            const self = this;
            const identifiers = ttpImpersonationService.constants.IDENTIFIERS;
            const actions = ttpImpersonationService.constants.ACTIONS;
            let paginationActualPage = 1;
            const paginationLabelDict = {
                start: paginationActualPage,
                end: paginationActualPage
            };
            let filterModelPrevious = {};
            let logsService;
            self.filterModel = {
                dropdownLabel: '',
                areAllSelected: false,
                options: {}
            };
            self.filterCommonDropdownTypes = adconCommonsService.CONSTANTS.DROPDOWN_ITEM_TYPES;
            self.maxEndDate = moment();
            self.minStartDate = moment(self.maxEndDate)
                .subtract(30, 'day')
                .toDate();
            self.fixedPayLoad = {};
            self.payload = {
                data: [{}],
                meta: {
                    pagination: {
                        pageSize: self.pageSize
                    }
                }
            };
            self.logs = [];
            self.arrRowClicked = [];
            self.pageSizeOptions = angular.copy(adconCommonsService.getDropdownOptions().pageSizeOptions);
            self.dateRanges = angular.copy(adconCommonsService.getDropdownOptions().dateRanges);
            self.filtersOptions = {
                LABEL01: {
                    type: self.filterCommonDropdownTypes.LABEL,
                    value: $translate.instant('$I18N_TTP_IMPERSONATION_LOGS_FILTER_BY_IDENTIFIERS_TRIGGERED')
                },
                SIMILAR_INTERNAL_DOMAIN: {
                    type: self.filterCommonDropdownTypes.ITEM,
                    key: identifiers.SIMILAR_INTERNAL_DOMAIN,
                    value: $translate.instant('$I18N_TTP_IMPERSONATION_SIMILAR_INTERNAL_DOMAIN'),
                    group: ttpImpersonationService.constants.API_REQUEST_FILTER_FIELDS.IDENTIFIER
                },
                SIMILAR_MONITORED_EXTERNAL_DOMAIN: {
                    type: self.filterCommonDropdownTypes.ITEM,
                    key: identifiers.SIMILAR_MONITORED_EXTERNAL_DOMAIN,
                    value: $translate.instant('$I18N_TTP_IMPERSONATION_SIMILAR_MONITORED_EXTERNAL_DOMAIN'),
                    group: ttpImpersonationService.constants.API_REQUEST_FILTER_FIELDS.IDENTIFIER
                },
                NEWLY_OBSERVED_DOMAIN: {
                    type: self.filterCommonDropdownTypes.ITEM,
                    key: identifiers.NEWLY_OBSERVED_DOMAIN,
                    value: $translate.instant('$I18N_TTP_IMPERSONATION_NEWLY_OBSERVED_DOMAIN'),
                    group: ttpImpersonationService.constants.API_REQUEST_FILTER_FIELDS.IDENTIFIER
                },
                INTERNAL_USER_NAME: {
                    type: self.filterCommonDropdownTypes.ITEM,
                    key: identifiers.INTERNAL_USER_NAME,
                    value: $translate.instant('$I18N_TTP_IMPERSONATION_INTERNAL_USER_NAME'),
                    group: ttpImpersonationService.constants.API_REQUEST_FILTER_FIELDS.IDENTIFIER
                },
                REPLY_TO_ADDRESS_MISMATCH: {
                    type: self.filterCommonDropdownTypes.ITEM,
                    key: identifiers.REPLY_TO_ADDRESS_MISMATCH,
                    value: $translate.instant('$I18N_TTP_IMPERSONATION_REPLY-TO_ADDRESS_MISMATCH'),
                    group: ttpImpersonationService.constants.API_REQUEST_FILTER_FIELDS.IDENTIFIER
                },
                TARGETED_THREAT_DICTIONARY: {
                    type: self.filterCommonDropdownTypes.ITEM,
                    key: identifiers.TARGETED_THREAT_DICTIONARY,
                    value: $translate.instant('$I18N_TTP_IMPERSONATION_TARGETED_THREAT_DICTIONARY'),
                    group: ttpImpersonationService.constants.API_REQUEST_FILTER_FIELDS.IDENTIFIER
                },
                LABEL02: {
                    type: self.filterCommonDropdownTypes.LABEL,
                    value: $translate.instant('$I18N_TTP_IMPERSONATION_LOGS_FILTER_BY_ACTION')
                },
                NONE: {
                    type: self.filterCommonDropdownTypes.ITEM,
                    key: actions.NONE,
                    value: $translate.instant('$I18N_TTP_IMPERSONATION_NONE'),
                    group: ttpImpersonationService.constants.API_REQUEST_FILTER_FIELDS.ACTION
                },
                HOLD: {
                    type: self.filterCommonDropdownTypes.ITEM,
                    key: actions.HOLD,
                    value: $translate.instant('$I18N_TTP_IMPERSONATION_HOLD'),
                    group: ttpImpersonationService.constants.API_REQUEST_FILTER_FIELDS.ACTION
                },
                BOUNCE: {
                    type: self.filterCommonDropdownTypes.ITEM,
                    key: actions.BOUNCE,
                    value: $translate.instant('$I18N_TTP_IMPERSONATION_BOUNCE'),
                    group: ttpImpersonationService.constants.API_REQUEST_FILTER_FIELDS.ACTION
                }
            };
            if (!hasChameleonCapabilities()) {
                delete self.filtersOptions.SIMILAR_MONITORED_EXTERNAL_DOMAIN;
            }
            self.searchFields = {
                ALL: {
                    type: self.filterCommonDropdownTypes.ITEM,
                    key: ttpImpersonationService.constants.SEARCH_FIELD.ALL,
                    value: $translate.instant('$I18N_ADMINISTRATION_MENU_TTP_IMPERSONATION_LOGS_SEARCH_TERM_ALL'),
                    active: true
                },
                LABEL: {
                    type: self.filterCommonDropdownTypes.LABEL,
                    value: $translate.instant('$I18N_ADMINISTRATION_COMMONS_FILTER_OPTIONS_TITLE')
                },
                SENDER_ADDRESS: {
                    type: self.filterCommonDropdownTypes.ITEM,
                    key: ttpImpersonationService.constants.SEARCH_FIELD.SENDER_ADDRESS,
                    value: $translate.instant('$I18N_ADMINISTRATION_MENU_TTP_IMPERSONATION_LOGS_SEARCH_TERM_FROM')
                },
                RECIPIENT_ADDRESS: {
                    type: self.filterCommonDropdownTypes.ITEM,
                    key: ttpImpersonationService.constants.SEARCH_FIELD.RECIPIENT_ADDRESS,
                    value: $translate.instant('$I18N_ADMINISTRATION_MENU_TTP_IMPERSONATION_LOGS_SEARCH_TERM_TO')
                },
                SUBJECT: {
                    type: self.filterCommonDropdownTypes.ITEM,
                    key: ttpImpersonationService.constants.SEARCH_FIELD.SUBJECT,
                    value: $translate.instant('$I18N_ADMINISTRATION_MENU_TTP_IMPERSONATION_LOGS_SEARCH_TERM_SUBJECT')
                },
                DEFINITION: {
                    type: self.filterCommonDropdownTypes.ITEM,
                    key: ttpImpersonationService.constants.SEARCH_FIELD.DEFINITION,
                    value: $translate.instant('$I18N_ADMINISTRATION_MENU_TTP_IMPERSONATION_LOGS_SEARCH_TERM_DEFINITION')
                }
            };
            self.columns = [
                {
                    key: 'from',
                    value: '$I18N_ADMINISTRATION_COMMONS_TABLE_HEADER_LABEL_FROM',
                    exportOrder: 0
                },
                {
                    key: 'tagged',
                    value: '$I18N_TTP_IMPERSONATION_LOGS_TAGGED',
                    exportOrder: 5
                },
                {
                    key: 'to',
                    value: '$I18N_ADMINISTRATION_COMMONS_TABLE_HEADER_LABEL_TO',
                    exportOrder: 1
                },
                {
                    key: 'senderip',
                    value: '$I18N_TTP_IMPERSONATION_LOGS_SENDERIP',
                    exportOrder: 6
                },
                {
                    key: 'subject',
                    value: '$I18N_TTP_IMPERSONATION_LOGS_SUBJECT',
                    exportOrder: 2
                },
                {
                    key: 'action',
                    value: '$I18N_TTP_IMPERSONATION_LOGS_ACTION',
                    exportOrder: 7
                },
                {
                    key: 'definition',
                    value: '$I18N_TTP_IMPERSONATION_LOGS_DEFINITION',
                    exportOrder: 3
                },
                {
                    key: 'identifiersdetected',
                    value: '$I18N_TTP_IMPERSONATION_LOGS_IDENTIFIERS',
                    exportOrder: 8
                },
                {
                    key: 'hits',
                    value: '$I18N_TTP_IMPERSONATION_LOGS_HITS',
                    exportOrder: 4
                },
                {
                    key: 'datetime',
                    value: '$I18N_ADMINISTRATION_COMMONS_TABLE_HEADER_LABEL_DATETIME',
                    exportOrder: 9
                }
            ];
            self.paginationLabel = '';
            self.paginationNextPageResults = false;
            self.paginationPreviousPageResults = false;
            $scope.$watch(function () {
                return self.payload;
            }, function (oldValue, newValue) {
                isPayloadUpdated(oldValue, newValue);
                getImpersonationLogs();
            }, true);
            $scope.tab.onRefresh = self.refresh = function () {
                logsService.setLogs([]);
                getImpersonationLogs();
            };
            self.pageSizeOptionsUpdate = pageSizeOptionsUpdate;
            self.dateRangesUpdate = dateRangesUpdate;
            self.loadMoreLogs = loadMoreLogs;
            self.exportColumns = exportColumns;
            self.openDetailAside = openDetailAside;
            self.paginationNextOnChange = paginationNextOnChange;
            self.paginationPreviousOnChange = paginationPreviousOnChange;
            self.filterModelController = filterModelController;
            self.filterBulkModelUpdate = filterBulkModelUpdate;
            self.applyFilter = applyFilter;
            self.cancelFilter = cancelFilter;
            self.showEmptyContainerMessage = showEmptyContainerMessage;
            self.getHighlightedRow = getHighlightedRow;
            activate();
            function activate() {
                self.payload.data[0].from =
                    self.dateRanges[adconCommonsService.CONSTANTS.DATE_RANGES_KEYS.TODAY].dates.from;
                self.payload.data[0].to =
                    self.dateRanges[adconCommonsService.CONSTANTS.DATE_RANGES_KEYS.TODAY].dates.to;
                self.pageSizeOptionsUpdate(adconCommonsService.CONSTANTS.PAGE_SIZES_KEYS.I50);
                monitoringService.filterOperations.updateFilterLabel(self.filterModel);
                filterModelPrevious = angular.copy(self.filterModel);
            }
            function getImpersonationLogs() {
                let payload = angular.copy(self.payload);
                const pageSize = payload.meta.pagination.pageSize;
                payload.data[0].from = moment(self.payload.data[0].from).format(adconCommonsService.CONSTANTS.API_DATE_FORMAT);
                payload.data[0].to = moment(self.payload.data[0].to).format(adconCommonsService.CONSTANTS.API_DATE_FORMAT);
                payload = createInternalDomainIdentifiersForPayload(payload);
                payload = createExternalDomainIdentifiersForPayload(payload);
                self.isDataLoading = true;
                ttpImpersonationService.getLogs(payload).then(function (response) {
                    if (!response.err) {
                        self.logs = adconCommonsService.humanizeActionFilters(response.first.impersonationLogs, self.filtersOptions, ttpImpersonationService.constants.API_RESPONSE_FILTER_FIELDS.ACTION);
                        if (response.meta && response.meta().pagination) {
                            paginationLabelDict.start =
                                paginationActualPage === 1
                                    ? paginationActualPage
                                    : pageSize * (paginationActualPage - 1) + 1;
                            paginationLabelDict.end =
                                self.logs.length !== pageSize
                                    ? pageSize * (paginationActualPage - 1) + self.logs.length
                                    : pageSize * paginationActualPage;
                            self.paginationLabel =
                                self.logs.length > 0
                                    ? $translate.instant('$I18N_ADMINISTRATION_COMMONS_PAGINATION_SEPARATOR', {
                                        start: paginationLabelDict.start,
                                        end: paginationLabelDict.end
                                    })
                                    : '';
                            self.paginationNextPageResults = response.meta().pagination.next;
                            self.paginationPreviousPageResults = response.meta().pagination.previous;
                        }
                        logsService = new ttp_impersonation_log_service_1.TtpImpersonationLogService(self.logs, []);
                    }
                    else {
                        ttpImpersonationService.getError().showErrorNotification(response);
                    }
                    self.isDataLoading = false;
                }, function () {
                    self.isDataLoading = false;
                    ttpImpersonationService.getError().showErrorNotification();
                });
            }
            function exportColumns() {
                const uid = ttpImpersonationService.constants.EXPORT_UID;
                const from = moment(self.payload.data[0].from).valueOf();
                const to = moment(self.payload.data[0].to).valueOf();
                const columns = self.columns;
                const filters = self.filterModel.options;
                const query = self.payload.data[0].query &&
                    self.payload.data[0].query + ':' + self.payload.data[0].searchField.toLowerCase();
                const filterExportMappings = ttpImpersonationService.constants.EXPORT_REQUEST_FILTER_PARAMETER_FIELDS;
                exportLogsAsideService.openExportAside(uid, from, to, columns, filters, query, filterExportMappings);
            }
            function pageSizeOptionsUpdate(id) {
                self.payload.meta.pagination.pageSize = self.pageSizeOptions[id].key;
                adconCommonsService.setDropdownActiveItem(self.pageSizeOptions, id);
                self.selectedPageSizeOptions = self.pageSizeOptions[id].value;
            }
            function dateRangesUpdate(id) {
                adconCommonsService.setDropdownActiveItem(self.routingOptions, id);
                self.selectedRoutingOptions = self.routingOptions[id].value;
            }
            function isPayloadUpdated(oldPayload, newPayload) {
                if (!_.isEqual(oldPayload.data[0], newPayload.data[0]) ||
                    !_.isEqual(oldPayload.meta.pagination.pageSize, newPayload.meta.pagination.pageSize)) {
                    delete self.payload.meta.pagination.pageToken;
                    paginationActualPage = 1;
                }
            }
            function openDetailAside(row) {
                logsService.highlightRow(row);
                prepareAndSetNiceScroll();
                if (!self.asideInstance) {
                    self.asideInstance = $aside
                        .open({
                        templateUrl: 'services/monitoring/ttp-impersonation/ttp-impersonation-list/slides/ttp-impersonation-details.tpl.html',
                        placement: 'right',
                        size: 'lg',
                        backdrop: true,
                        windowClass: 'hidden-xs',
                        backdropClass: 'hidden-xs',
                        controllers: angular.noop,
                        resolve: {
                            ttpLogIndex() {
                                return row;
                            },
                            ttpLogsService() {
                                return logsService;
                            }
                        }
                    })
                        .result.finally(function () {
                        logsService.setArrRowClicked([]);
                        self.asideInstance = undefined;
                    });
                }
            }
            function loadMoreLogs(pageToken) {
                if (!self.isDataLoading && pageToken) {
                    self.payload.meta.pagination.pageToken = pageToken;
                }
            }
            function paginationNextOnChange() {
                paginationActualPage++;
                loadMoreLogs(self.paginationNextPageResults);
            }
            function paginationPreviousOnChange() {
                paginationActualPage--;
                loadMoreLogs(self.paginationPreviousPageResults);
            }
            function filterModelController(id, value) {
                monitoringService.filterOperations.filterModelController(self.filterModel, id, value);
            }
            function filterBulkModelUpdate() {
                monitoringService.filterOperations.filterBulkModelUpdate(self.filterModel, self.filtersOptions);
            }
            function applyFilter() {
                monitoringService.filterOperations.applyFilter(self.payload.data[0], self.filterModel, self.filtersOptions);
                filterModelPrevious = angular.copy(self.filterModel);
            }
            function cancelFilter() {
                self.filterModel = angular.copy(filterModelPrevious);
            }
            function showEmptyContainerMessage() {
                return self.payload.data[0].query
                    ? '$I18N_TTP_IMPERSONATION_RESULTS_EMPTY_TITLE'
                    : '$I18N_TTP_IMPERSONATION_LIST_EMPTY_TITLE';
            }
            function getHighlightedRow(row) {
                return logsService.getHighlightedRow(row);
            }
            function prepareAndSetNiceScroll() {
                const niceScroll = {
                    // @ts-ignore
                    niceScroll: $('.mc-ttp-impersonation-list').getNiceScroll(0),
                    rowHeight: $('.mc-js-ttp-log-row-id')
                        .eq(0)
                        .outerHeight(true)
                };
                logsService.setNiceScroll(niceScroll);
            }
            function createInternalDomainIdentifiersForPayload(payload) {
                const newPayload = Object.assign({}, payload);
                if (newPayload.data[0].identifiers) {
                    const similarInternalDomainIndex = newPayload.data[0].identifiers.indexOf(identifiers.SIMILAR_INTERNAL_DOMAIN);
                    if (similarInternalDomainIndex !== -1) {
                        newPayload.data[0].identifiers = newPayload.data[0].identifiers.concat([
                            identifiers.ADVANCED_SIMILAR_INTERNAL_DOMAIN
                        ]);
                    }
                }
                return newPayload;
            }
            function createExternalDomainIdentifiersForPayload(payload) {
                const newPayload = Object.assign({}, payload);
                if (newPayload.data[0].identifiers) {
                    const similarMonitoredExternalDomainsIndex = newPayload.data[0].identifiers.indexOf(identifiers.SIMILAR_MONITORED_EXTERNAL_DOMAIN);
                    if (similarMonitoredExternalDomainsIndex !== -1) {
                        newPayload.data[0].identifiers.splice(similarMonitoredExternalDomainsIndex, 1);
                        newPayload.data[0].identifiers = newPayload.data[0].identifiers.concat([
                            identifiers.CUSTOM_EXTERNAL_DOMAIN,
                            identifiers.MIMECAST_EXTERNAL_DOMAIN,
                            identifiers.ADVANCED_CUSTOM_EXTERNAL_DOMAIN,
                            identifiers.ADVANCED_MIMECAST_EXTERNAL_DOMAIN
                        ]);
                    }
                }
                return newPayload;
            }
            function hasChameleonCapabilities() {
                return capabilitiesService.hasCapability('Temporary.Chameleon');
            }
        }
    ]);
})();
