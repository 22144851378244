"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiGatewayAsideComponent = void 0;
const operators_1 = require("rxjs/operators");
const actions = require("../../../actions");
const reducers = require("../../../reducers");
const new_credentials_confirmation_modal_1 = require("../../../components/api-gateway/modals/new-credentials-confirmation-modal");
class ApiGatewayAsideComponent {
    constructor(store, application, notificationService, translateService, modalService, modal, featureFlagService) {
        this.store = store;
        this.application = application;
        this.notificationService = notificationService;
        this.translateService = translateService;
        this.modalService = modalService;
        this.modal = modal;
        this.featureFlagService = featureFlagService;
    }
    ngOnInit() {
        this.featureFlagService.isReady().then(() => {
            this.generateNewKeys = this.featureFlagService.getBooleanFlag('Adcon.Services.Applications.2.0.client.secret.Regeneration');
        });
        this.active$ = this.store.select(reducers.isApiGatewayListApplicationActive(this.application.id));
        this.appKey$ = this.store.select(reducers.getApiGatewayViewKeyForApp(this.application.id));
        this.showKey$ = this.store.select(reducers.isApiGatewayViewShowingKey);
        this.gettingKey$ = this.store.select(reducers.isApiGatewayViewGettingKey);
        if (this.application.usage === 'gateway') {
            this.store.dispatch(new actions.GetGatewayApplicationRoleListWithHeaderAction(true));
            this.store.dispatch(new actions.GetGatewayApplicationProductListAction());
        }
    }
    getKey() {
        this.store.dispatch(new actions.GetApiApplicationKeyAction(this.application));
    }
    showKey() {
        this.store.dispatch(new actions.ShowApiApplicationKeyAction());
    }
    hideKey() {
        this.store.dispatch(new actions.HideApiApplicationKeyAction());
    }
    keyCopied(message) {
        this.notificationService.success({
            msg: this.translateService.instant(message)
        });
    }
    enable() {
        this.store.dispatch(new actions.EnableApiApplicationAction(this.application));
    }
    disable() {
        this.store.dispatch(new actions.ConfirmDisableApiApplicationAction(this.application));
    }
    edit() {
        this.store.dispatch(new actions.CloseViewApiApplicationAction());
        this.store
            .select(reducers.getApiGatewayV3Roles)
            .pipe(operators_1.first())
            .toPromise()
            .then((roles) => {
            if (this.application.usage === 'gateway') {
                if (roles.length) {
                    this.store.dispatch(new actions.OpenEditApiApplicationV3Action(this.application));
                }
                else {
                    this.modalService.open({
                        content: '$I18N_API_APPLICATIONS_LIST.NOTIFICATIONS.ERRORS.API2.0_ERROR',
                        contentParams: {
                            url: 'https://community.mimecast.com/s/article/Email-Security-Cloud-Gateway-Managing-Administrator-Roles'
                        },
                        hideHeader: true
                    });
                }
            }
            else {
                this.store.dispatch(new actions.OpenEditApiApplicationAction(this.application));
            }
        });
    }
    delete() {
        this.store.dispatch(new actions.CloseViewApiApplicationAction());
        this.store.dispatch(new actions.ConfirmDeleteApiApplicationAction(this.application));
    }
    genKeys() {
        this.store.dispatch(new actions.CloseViewApiApplicationAction());
        this.store.dispatch(new actions.OpenKeyGenAction(this.application));
    }
    getCredentials() {
        if (this.generateNewKeys) {
            this.modal.open(new_credentials_confirmation_modal_1.ApiGatewayNewCredentialsConfirmationModalComponent, {
                size: 'xlg',
                hasBackdrop: true,
                disableClose: true,
                data: this.application
            });
        }
        else {
            this.store.dispatch(new actions.GetCredentialsAction(this.application));
        }
    }
    close() {
        this.store.dispatch(new actions.CloseViewApiApplicationAction());
    }
}
exports.ApiGatewayAsideComponent = ApiGatewayAsideComponent;
