"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
(function () {
    'use strict';
    /* ======================================================== *\

     = layout / list-extra-column / directive

     \* ======================================================== */
    document.createElement('mc-layout-list-extra-column');
    angular.module('layout.list-extra-column.directive', [])
        .directive('mcLayoutListExtraColumn', ['$translate', function ($translate) {
            return {
                restrict: 'E',
                templateUrl: 'components/layout/list-extra-column/list-extra-column.tpl.html',
                scope: {
                    mainContainerClass: '@mainContainerClass',
                    headerTitle: '@headerTitle',
                    headerDescription: '@headerDescription',
                    headerFavouriteIsVisible: '=headerFavouriteIsVisible',
                    headerKbLink: '@headerKbLink',
                    headerKbDescKey: '@headerKbDescKey',
                    isLoadingExtraColumn: '=',
                    isLoadingCenterColumn: '=',
                    inIframe: '=inIframe'
                },
                transclude: {
                    headerSideContainer: '?headerSideContainer',
                    paginationContainer: '?paginationContainer',
                    filtersContainer: 'filtersContainer',
                    mainContainer: 'mainContainer',
                    extraColumnContainer: 'extraColumnContainer'
                },
                link(scope, element, attrs, controller, transcludeFn) {
                    scope.headerKbTranslatedDesc = $translate.instant(scope.headerKbDescKey);
                    scope.headerSideContainer = transcludeFn.isSlotFilled('headerSideContainer');
                    scope.paginationContainer = transcludeFn.isSlotFilled('paginationContainer');
                }
            };
        }]);
}());
