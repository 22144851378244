"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgClpApiService = void 0;
const operators_1 = require("rxjs/operators");
class SwgClpApiService {
    constructor(http) {
        this.http = http;
    }
    getTeams(connectorId, searchText) {
        return this.http
            .post('/clp/v1/teams/search', {
            connectorId,
            searchText
        })
            .pipe(operators_1.map(response => response.all));
    }
    getChannels(connectorId, teamId, searchText) {
        return this.http
            .post('/clp/v1/channels/search', {
            connectorId,
            teamId,
            searchText
        })
            .pipe(operators_1.map(response => response.all));
    }
    getVerdictsByApp(dateRange) {
        return this.http
            .post('/clp/v1/verdicts-by-app/get', {
            fromDate: dateRange.start,
            toDate: dateRange.end
        })
            .pipe(operators_1.map(response => response.first));
    }
    getVerdictsByDate(dateRange) {
        return this.http
            .post('/clp/v1/verdicts-by-dates/get', {
            fromDate: dateRange.start,
            toDate: dateRange.end
        })
            .pipe(operators_1.map(response => response.first));
    }
    getScansByFileType(dateRange) {
        return this.http
            .post('/clp/v1/scans-by-file-type/get', {
            fromDate: dateRange.start,
            toDate: dateRange.end
        })
            .pipe(operators_1.map(response => response.first));
    }
}
exports.SwgClpApiService = SwgClpApiService;
