"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApPolicyOutboundSettingsStepComponent = void 0;
const core_1 = require("@angular/core");
const ap_policy_outbound_settings_static_values_1 = require("./ap-policy-outbound-settings-static-values");
const group_selector_component_1 = require("../../../../../../components/group-selector/group-selector.component");
const actions = require("../../../actions/policy.actions");
const operators_1 = require("rxjs/operators");
const reducers = require("../../../reducers");
class ApPolicyOutboundSettingsStepComponent {
    constructor(fb, asideService, store) {
        this.fb = fb;
        this.asideService = asideService;
        this.store = store;
        this.SenderMailboxActionOptions = ap_policy_outbound_settings_static_values_1.SenderMailboxActionOptions;
        this.SenderMailboxFallbackActionOptions = ap_policy_outbound_settings_static_values_1.SenderMailboxFallbackActionOptions;
        this.GatewayActionOptions = ap_policy_outbound_settings_static_values_1.GatewayActionOptions;
        this.GatewayFallbackActionOptions = ap_policy_outbound_settings_static_values_1.GatewayFallbackActionOptions;
        this.updateApPolicyOutboundType = new core_1.EventEmitter();
        this.apOutboundSettingsForm = this.fb.group({
            enableOutboundCheck: [''],
            senderMailboxAction: [''],
            gatewayAction: [''],
            gatewayFallbackAction: [''],
            senderMailboxFallbackAction: [''],
            internalSenderOutbound: ['']
        });
    }
    openSidebar() {
        const asideConfig = {};
        const aside = this.asideService.open(group_selector_component_1.GroupSelectorComponent, asideConfig);
        aside.beforeClose().subscribe(selectedGroup => {
            if (selectedGroup) {
                this.store.dispatch(new actions.OutboundSelectGroup(selectedGroup));
            }
        });
    }
    ngOnInit() {
        this.apOutboundSettingsForm.patchValue(this.policyOutbound);
        this.apOutboundSettingsFormSubscription = this.apOutboundSettingsForm.valueChanges
            .pipe(operators_1.debounceTime(200))
            .subscribe(() => {
            this.updateOutboundStore();
        });
        this.addedOutboundGroup$ = this.store.select(reducers.getOutboundAdministratorGroup);
        this.addedOutboundGroupsSubscription = this.addedOutboundGroup$.subscribe(group => {
            this.selectGroupOutbound = group;
        });
    }
    updateOutboundStore() {
        this.updateApPolicyOutboundType.emit(this.apOutboundSettingsForm.value);
    }
    onDeleteGroup(row) {
        this.store.dispatch(new actions.RemoveOutboundGroupAction(row));
    }
    ngOnDestroy() {
        this.addedOutboundGroupsSubscription.unsubscribe();
        this.apOutboundSettingsFormSubscription.unsubscribe();
    }
}
exports.ApPolicyOutboundSettingsStepComponent = ApPolicyOutboundSettingsStepComponent;
