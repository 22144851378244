"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeviceAction = exports.DeviceListDisplayType = exports.DeviceGroupWizardFlowType = void 0;
var DeviceGroupWizardFlowType;
(function (DeviceGroupWizardFlowType) {
    DeviceGroupWizardFlowType["CREATE"] = "create";
    DeviceGroupWizardFlowType["EDIT"] = "edit";
})(DeviceGroupWizardFlowType = exports.DeviceGroupWizardFlowType || (exports.DeviceGroupWizardFlowType = {}));
var DeviceListDisplayType;
(function (DeviceListDisplayType) {
    DeviceListDisplayType["CREATE"] = "create";
    DeviceListDisplayType["EDIT"] = "edit";
    DeviceListDisplayType["READ"] = "read";
    DeviceListDisplayType["TARGET"] = "target";
})(DeviceListDisplayType = exports.DeviceListDisplayType || (exports.DeviceListDisplayType = {}));
var DeviceAction;
(function (DeviceAction) {
    DeviceAction["ADDED"] = "Added";
    DeviceAction["REMOVE"] = "Removed";
    DeviceAction["PENDING"] = "Pending";
    DeviceAction["INGROUP"] = "In Group";
    DeviceAction["NONE"] = "";
})(DeviceAction = exports.DeviceAction || (exports.DeviceAction = {}));
