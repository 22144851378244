"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MailBoxStatusCardComponent = void 0;
const mailbox_status_card_static_1 = require("./mailbox-status-card.static");
class MailBoxStatusCardComponent {
    constructor(translateService) {
        this.translateService = translateService;
        this.title = '$I18N_SYNC_AND_RECOVER_OVERVIEW_MODAL.MAILBOX_STATUS.TITLE';
    }
    set data(value) {
        if (value) {
            this.setChartData(value);
        }
    }
    setChartData(data) {
        this.chartData = {
            text: this.translateService.instant('$I18N_SYNC_AND_RECOVER_OVERVIEW_MODAL.COMMON.TOTAL'),
            value: data.total,
            colors: this.getChartSegColors(),
            series: [
                {
                    name: 'Mailbox Status',
                    categories: [
                        {
                            text: this.getChartSegText(mailbox_status_card_static_1.MailboxStatusChartSegmentTypes.SUCCESS),
                            name: mailbox_status_card_static_1.MailboxStatusChartSegmentTypes.SUCCESS,
                            value: data.statusBreakdown[mailbox_status_card_static_1.MailboxStatusChartSegmentTypes.SUCCESS]
                        },
                        {
                            text: this.getChartSegText(mailbox_status_card_static_1.MailboxStatusChartSegmentTypes.WARNING),
                            name: mailbox_status_card_static_1.MailboxStatusChartSegmentTypes.WARNING,
                            value: data.statusBreakdown[mailbox_status_card_static_1.MailboxStatusChartSegmentTypes.WARNING]
                        },
                        {
                            text: this.getChartSegText(mailbox_status_card_static_1.MailboxStatusChartSegmentTypes.ERROR),
                            name: mailbox_status_card_static_1.MailboxStatusChartSegmentTypes.ERROR,
                            value: data.statusBreakdown[mailbox_status_card_static_1.MailboxStatusChartSegmentTypes.ERROR]
                        },
                        {
                            text: this.getChartSegText(mailbox_status_card_static_1.MailboxStatusChartSegmentTypes.IN_PROGRESS),
                            name: mailbox_status_card_static_1.MailboxStatusChartSegmentTypes.IN_PROGRESS,
                            value: data.statusBreakdown[mailbox_status_card_static_1.MailboxStatusChartSegmentTypes.IN_PROGRESS]
                        }
                    ]
                }
            ]
        };
    }
    getChartSegText(text) {
        const textUppercase = text !== mailbox_status_card_static_1.MailboxStatusChartSegmentTypes.IN_PROGRESS ? text.toUpperCase() : mailbox_status_card_static_1.IN_PROGRESS_CAPS;
        return this.translateService.instant(`$I18N_SYNC_AND_RECOVER_OVERVIEW_MODAL.MAILBOX_STATUS.CHART_CATEGORIES.${textUppercase}`);
    }
    getChartSegColors() {
        return Object.values(mailbox_status_card_static_1.SyncTaskChartColors);
    }
}
exports.MailBoxStatusCardComponent = MailBoxStatusCardComponent;
