"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AsideRecipientsComponent = void 0;
const rxjs_1 = require("rxjs");
const common_util_1 = require("../../remediation/utils/common-util");
class AsideRecipientsComponent {
    constructor() {
        this.recipients = [];
        this.filteredRecipients = [];
        this.recipientsToDisplay = new rxjs_1.BehaviorSubject([]);
        this.recipientsColumns = ['email', 'status'];
        this.showFailureCode = common_util_1.showFailureCode;
    }
    ngOnInit() {
        this.recipients.length = 0;
        if (this.message && this.message.rcptStatus) {
            for (const recipient in this.message.rcptStatus) {
                if (this.message.rcptStatus.hasOwnProperty(recipient)) {
                    this.recipients.push({
                        email: recipient,
                        status: this.message.rcptStatus[recipient],
                        failureCode: this.message.rcptFailureStatus[recipient]
                    });
                }
            }
        }
        this.recipientsToDisplay.next(this.recipients);
    }
    onSearchRecipient(filter) {
        this.filteredRecipients.length = 0;
        this.filteredRecipients = this.recipients.filter(recipient => {
            return (recipient.email.indexOf(filter.search) !== -1 ||
                recipient.status.indexOf(filter.search) !== -1);
        });
        this.recipientsToDisplay.next(this.filteredRecipients);
    }
    clearSearch() {
        this.filteredRecipients.length = 0;
        this.recipientsToDisplay.next(this.recipients);
    }
}
exports.AsideRecipientsComponent = AsideRecipientsComponent;
