"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupervisionDashboardSettingsAsideContainerComponent = void 0;
const rxjs_1 = require("rxjs");
const reducers_1 = require("app-new/archive/supervision/reducers");
const queue_type_enum_1 = require("app-new/archive/supervision/types/queue-type.enum");
const dashboard_settings_actions_1 = require("../../../actions/dashboard-settings.actions");
const index_1 = require("../../../reducers/index");
const operators_1 = require("rxjs/operators");
const rule_actions_1 = require("app-new/archive/supervision/actions/rule.actions");
const status_enum_1 = require("app-new/archive/supervision/types/status.enum");
class SupervisionDashboardSettingsAsideContainerComponent {
    constructor(store, capabilitiesService, overlayRef) {
        this.store = store;
        this.capabilitiesService = capabilitiesService;
        this.overlayRef = overlayRef;
        this.destroy$ = new rxjs_1.Subject();
        this.QueueType = queue_type_enum_1.QueueType;
    }
    ngOnInit() {
        this.settings$ = this.store.select(reducers_1.getDashboardSettings);
        this.isLoadingDashboardSettings$ = this.store.select(reducers_1.getDashboardSettingsLoading);
        this.isLoadingQueue$ = this.store.select(reducers_1.getRuleLoading);
        this.selectedQueueType$ = this.store.select(reducers_1.getDashboardSettingsSelectedQueueType);
        this.ruleQueues$ = this.store.select(reducers_1.getRuleQueues);
        this.successfullySaved$ = this.store.select(index_1.getDashboardSettingsSuccessfullySaved);
        this.selectedQueueType$.pipe(operators_1.takeUntil(this.destroy$)).subscribe(selectedQueueType => {
            const filters = selectedQueueType === queue_type_enum_1.QueueType.ESCALATION
                ? SupervisionDashboardSettingsAsideContainerComponent.ESCALATION_QUEUE_FILTER
                : SupervisionDashboardSettingsAsideContainerComponent.REVIEW_QUEUE_FILTER;
            this.store.dispatch(new rule_actions_1.GetQueuesAction(filters));
        });
        this.isLoading$ = rxjs_1.combineLatest([this.isLoadingDashboardSettings$, this.isLoadingQueue$]).pipe(operators_1.map(([isLoadingDashboardSettings, isLoadingQueue]) => isLoadingDashboardSettings || isLoadingQueue));
        this.hasEditPermission$ = this.capabilitiesService.hasCapability('Permission.SUPERVISION_EDIT');
        this.queues$ = rxjs_1.combineLatest([this.ruleQueues$, this.selectedQueueType$]).pipe(operators_1.map(([queueConfigs, selectedQueueType]) => queueConfigs.filter(a => (a.status === status_enum_1.QueueStatus.ACTIVE || a.status === status_enum_1.QueueStatus.FULL) &&
            a.type === selectedQueueType)), operators_1.map((queueConfigs) => queueConfigs.map(queueConfig => {
            return { id: queueConfig.id, label: queueConfig.name };
        })), operators_1.takeUntil(this.destroy$));
    }
    onDashboardSettingsUpdated(settings) {
        this.store.dispatch(new dashboard_settings_actions_1.SetDashboardSettingsAction(settings));
        this.successfullySaved$.pipe(operators_1.takeUntil(this.destroy$)).subscribe(updated => {
            if (updated) {
                this.close(true);
            }
        });
    }
    close(refreshRequired = false) {
        this.overlayRef.close(refreshRequired);
    }
    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
}
exports.SupervisionDashboardSettingsAsideContainerComponent = SupervisionDashboardSettingsAsideContainerComponent;
SupervisionDashboardSettingsAsideContainerComponent.REVIEW_QUEUE_FILTER = {
    statuses: [status_enum_1.QueueStatus.ACTIVE, status_enum_1.QueueStatus.FULL],
    queueType: queue_type_enum_1.QueueType.REVIEW
};
SupervisionDashboardSettingsAsideContainerComponent.ESCALATION_QUEUE_FILTER = {
    statuses: [status_enum_1.QueueStatus.ACTIVE, status_enum_1.QueueStatus.FULL],
    queueType: queue_type_enum_1.QueueType.ESCALATION
};
