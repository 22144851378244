"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ8 = exports.ɵ7 = exports.ɵ6 = exports.ɵ5 = exports.ɵ4 = exports.ɵ3 = exports.ɵ2 = exports.ɵ1 = exports.ɵ0 = exports.getDecodeUrlLoadingState = exports.getCheckUrlsErrorState = exports.getCheckUrlsLoadingState = exports.getDecodedUrl = exports.getDecodedUrlStatus = exports.getCheckUrlsScanAggressiveResults = exports.getCheckUrlsScanModerateResults = exports.getCheckUrlsScanRelaxedResults = exports.getCheckUrlsScanResults = exports.DecodeUrlState = exports.CheckUrlsState = exports.decodeReducer = exports.urlToolsReducers = void 0;
const checkUrlsReducer = require("./check-urls/check-urls.reducer");
const decodeUrlReducer = require("./decode-url/decode-url.reducer");
const store_1 = require("@ngrx/store");
exports.urlToolsReducers = {
    checkUrls: checkUrlsReducer.reducer
};
exports.decodeReducer = {
    decodeUrl: decodeUrlReducer.reducer
};
exports.CheckUrlsState = store_1.createFeatureSelector('check-urls');
exports.DecodeUrlState = store_1.createFeatureSelector('decode-urls');
const ɵ0 = state => state && state.scanDetails;
exports.ɵ0 = ɵ0;
exports.getCheckUrlsScanResults = store_1.createSelector(exports.CheckUrlsState, ɵ0);
const ɵ1 = state => state && state.scanDetails.relaxed;
exports.ɵ1 = ɵ1;
exports.getCheckUrlsScanRelaxedResults = store_1.createSelector(exports.CheckUrlsState, ɵ1);
const ɵ2 = state => state && state.scanDetails.moderate;
exports.ɵ2 = ɵ2;
exports.getCheckUrlsScanModerateResults = store_1.createSelector(exports.CheckUrlsState, ɵ2);
const ɵ3 = state => state && state.scanDetails.aggressive;
exports.ɵ3 = ɵ3;
exports.getCheckUrlsScanAggressiveResults = store_1.createSelector(exports.CheckUrlsState, ɵ3);
const ɵ4 = state => state && state.urlDetails.success;
exports.ɵ4 = ɵ4;
exports.getDecodedUrlStatus = store_1.createSelector(exports.DecodeUrlState, ɵ4);
const ɵ5 = state => state && state.urlDetails.url;
exports.ɵ5 = ɵ5;
exports.getDecodedUrl = store_1.createSelector(exports.DecodeUrlState, ɵ5);
const ɵ6 = state => state.loading;
exports.ɵ6 = ɵ6;
exports.getCheckUrlsLoadingState = store_1.createSelector(exports.CheckUrlsState, ɵ6);
const ɵ7 = state => state.hasError;
exports.ɵ7 = ɵ7;
exports.getCheckUrlsErrorState = store_1.createSelector(exports.CheckUrlsState, ɵ7);
const ɵ8 = state => state.loading;
exports.ɵ8 = ɵ8;
exports.getDecodeUrlLoadingState = store_1.createSelector(exports.DecodeUrlState, ɵ8);
