"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SaveSessionTimeoutAction = exports.SAVE_SESSION_TIMEOUT_ACTION = void 0;
exports.SAVE_SESSION_TIMEOUT_ACTION = '[IdentityPolicyWizard SessionTimeoutStep] Save Session Timeout';
class SaveSessionTimeoutAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SAVE_SESSION_TIMEOUT_ACTION;
    }
}
exports.SaveSessionTimeoutAction = SaveSessionTimeoutAction;
