"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScanDetailsComponent = void 0;
const url_protection_log_model_1 = require("app-new/services/url-protection/logs/models/url-protection-log.model");
class ScanDetailsComponent {
    constructor() {
        this.scannerTypes = url_protection_log_model_1.ScanDetailTypes;
        this.advancedSimilarityChecksTypes = url_protection_log_model_1.AdvancedSimilarityChecks;
        this.avScanningTypes = url_protection_log_model_1.AvScanning;
        this.machineLearningTypes = url_protection_log_model_1.MachineLearning;
        this.dangerousFileExtTypes = url_protection_log_model_1.DangerousFileExt;
        this.customManagedUrlsTypes = url_protection_log_model_1.CustomerManagedUrls;
        this.htmlContentChecksTypes = url_protection_log_model_1.HtmlContentChecks;
        this.urlReputationScanTypes = url_protection_log_model_1.UrlReputationScan;
        this.sandBoxingTypes = url_protection_log_model_1.Sandboxing;
        this.advancedPhishingTypes = url_protection_log_model_1.AdvancedPhishing;
    }
    checkScanDetails(tagData, key) {
        return tagData && tagData.hasOwnProperty(key);
    }
}
exports.ScanDetailsComponent = ScanDetailsComponent;
