"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgUrlCheckApiService = void 0;
const operators_1 = require("rxjs/operators");
class SwgUrlCheckApiService {
    constructor(http) {
        this.http = http;
    }
    getUrlClassification(payload) {
        return this.http
            .post('/api/swg/get-url-classification', payload)
            .pipe(operators_1.map(res => res.first));
    }
    reportUrlClassification(payload) {
        return this.http
            .post('/api/swg/report-url-classification', payload)
            .pipe(operators_1.map(res => res.first));
    }
}
exports.SwgUrlCheckApiService = SwgUrlCheckApiService;
