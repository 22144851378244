<div *ngIf="options" [formGroup]="checklistForm" class="mc-checklist-container">
  <div formArrayName="controlOptions"
    *ngFor="let controlOption of checklistForm.get('controlOptions')['controls']; let i = index;">
    <mc-checkbox class="mc-checkbox" [label]="options[i].label" [formControlName]="i"></mc-checkbox>
  </div>
</div>
<br>
<div *ngIf="options" class="panel-margin-right">
  {{this.selectedOptions.length}} {{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD_SETTINGS_ASIDE.OF' | translate}} {{this.options.length}} {{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD_SETTINGS_ASIDE.QUEUE_SELECTED' | translate}}
</div>
