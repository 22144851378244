<div class="select-mailbox-group-panel">
  <div class="panel-default">
    <div class="panel-heading header">
      {{
        '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SETTINGS_STEP.PANEL.SELECT_MONITORING_GROUP'
          | translate
      }}
      <i class="far fa-times pull-right close-panel" (click)="close()"></i>
    </div>
  </div>
  <mc-tab-group [initialActiveTabIndex]="0" (tabChanged)="onTabChange($event)">
    <mc-tab 
      *ngIf="!onlyLocalGroups"
      name="{{
        '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SETTINGS_STEP.PANEL.TABS.ACTIVE_DIRECTORY'
          | translate
      }}"
      data-test="active-directory-tab"
    >
      <div class="tab-panel" *ngIf="(isLDAPReady$ | async); else panelSpinner">
        <div class="search-field-wrapper" *ngIf="ldapGroups">
          <mc-filter-search
            (search)="onSearch($event)"
            (clear)="onClearSearch('ldap')"
            [placeholder]="placeholderActiveDirectoryGroups"
          ></mc-filter-search>
          <mc-errors *ngIf="!searchValid" [errors]="searchErrors"
              formPrefix="$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.VALIDATION.FORM">
          </mc-errors>
        </div>
        <div *ngIf="isLDAPSearching">
          <mc-spinner></mc-spinner>
          {{
            '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SETTINGS_STEP.PANEL.SEARCHING_MSG' | translate
          }}
        </div>
        <div *ngIf="showLDAPNoSearchResults">
          {{
            '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SETTINGS_STEP.PANEL.NO_SEARCH_RESULTS_MSG'
              | translate
          }}
        </div>
        <div [class.shift-tree-on-search]="hasLDAPSearchResults">
          <mc-tree-view
            #ldapTree
            *ngIf="!isLDAPSearching && !showLDAPNoSearchResults"
            [nodes]="ldapGroups"
            (selection)="onItemSelected($event)"
            [options]="options"
            [loadingMessage]="loadingMessage"
            [showSpinner]="showSpinner"
          ></mc-tree-view>
        </div>
      </div>
    </mc-tab>
    <mc-tab
      name="{{
        '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SETTINGS_STEP.PANEL.TABS.LOCAL' | translate
      }}"
      data-test="local-groups-tab"
    >
      <div class="tab-panel" *ngIf="(isCloudReady$ | async); else panelSpinner">
        <div class="search-field-wrapper" *ngIf="ldapGroups">
          <mc-filter-search
            (search)="onSearch($event)"
            (clear)="onClearSearch('cloud')"
            [placeholder]="placeholderLocalGroups"
          ></mc-filter-search>
          <mc-errors *ngIf="!searchValid" [errors]="searchErrors"
              formPrefix="$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.VALIDATION.FORM">
          </mc-errors>
        </div>
        <div *ngIf="isCloudSearching">
          <mc-spinner></mc-spinner>
          {{
            '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SETTINGS_STEP.PANEL.SEARCHING_MSG' | translate
          }}
        </div>
        <div *ngIf="showCloudNoSearchResults">
          {{
            '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SETTINGS_STEP.PANEL.NO_SEARCH_RESULTS_MSG'
              | translate
          }}
        </div>
        <div [class.shift-tree-on-search]="hasCloudSearchResults">
          <mc-tree-view
            #cloudTree
            *ngIf="!isCloudSearching && !showCloudNoSearchResults"
            [nodes]="cloudGroups"
            (selection)="onItemSelected($event)"
            [options]="options"
            [loadingMessage]="loadingMessage"
            [showSpinner]="showSpinner"
          ></mc-tree-view>
        </div>
      </div>
    </mc-tab>
  </mc-tab-group>
</div>
<ng-template #panelSpinner class="parent">
  <mc-spinner
    class="no-content-wrapper spinner-wrapper"
    *ngIf="
      ((!(isLDAPDataLoaded$ | async) && !(hasInitialLDAPData$ | async)) ||
        (!(isCloudDataLoaded$ | async) && !(hasInitialCloudData$ | async))) &&
        !(isGetGroupsLoadingError$ | async);
      else noResultsError
    "
  ></mc-spinner>
</ng-template>
<ng-template #noResultsError class="parent">
  <div class="no-content-wrapper no-results-wrapper">
    <div class="panel-body text-center">
      {{
        '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SETTINGS_STEP.PANEL.NO_SEARCH_RESULTS_MSG'
          | translate
      }}
    </div>
  </div>
</ng-template>
