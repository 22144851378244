"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPatternFields = exports.Pattern = void 0;
require("reflect-metadata");
const forms_1 = require("@angular/forms");
const PATTERN_METADATA_KEY = Symbol('MC-PATTERN-FIELD');
function Pattern(pattern) {
    return (target, propertyKey) => {
        let patternFields = Reflect.getMetadata(PATTERN_METADATA_KEY, target);
        const patternField = {};
        patternField[propertyKey] = forms_1.Validators.pattern(pattern);
        if (patternFields) {
            patternFields.push(patternField);
        }
        else {
            patternFields = [patternField];
        }
        Reflect.defineMetadata(PATTERN_METADATA_KEY, patternFields, target);
    };
}
exports.Pattern = Pattern;
function getPatternFields(origin) {
    const properties = Reflect.getMetadata(PATTERN_METADATA_KEY, origin);
    let result = {};
    if (properties) {
        properties.forEach(property => (result = Object.assign(Object.assign({}, result), property)));
    }
    return result;
}
exports.getPatternFields = getPatternFields;
