"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CortexActionPipe = void 0;
class CortexActionPipe {
    constructor(translateService) {
        this.translateService = translateService;
    }
    transform(input) {
        switch (input) {
            case 'BLOCK_OUTBOUND':
            case 'BLOCK_ALL':
            case 'NOTIFY':
                return this.translateService.instant('$I18N_API_INTEGRATION_CORTEX_ACTION.' + input.toUpperCase());
            default:
                return this.translateService.instant('$I18N_API_INTEGRATION_CORTEX_ACTION.NOTIFY');
        }
    }
}
exports.CortexActionPipe = CortexActionPipe;
