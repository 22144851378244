"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TEXT_POSITION = exports.PROGRESS_BAR_MODES = void 0;
var PROGRESS_BAR_MODES;
(function (PROGRESS_BAR_MODES) {
    PROGRESS_BAR_MODES["DETERMINATE"] = "DETERMINATE";
    PROGRESS_BAR_MODES["INDETERMINATE"] = "INDETERMINATE";
    PROGRESS_BAR_MODES["BUFFER"] = "BUFFER"; // TODO: Ishan - Currently not supported - Implementation needed
})(PROGRESS_BAR_MODES = exports.PROGRESS_BAR_MODES || (exports.PROGRESS_BAR_MODES = {}));
var TEXT_POSITION;
(function (TEXT_POSITION) {
    TEXT_POSITION["TOP"] = "TOP";
    TEXT_POSITION["BOTTOM"] = "BOTTOM";
    TEXT_POSITION["LEFT"] = "LEFT";
    TEXT_POSITION["RIGHT"] = "RIGHT";
})(TEXT_POSITION = exports.TEXT_POSITION || (exports.TEXT_POSITION = {}));
