"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImTasksService = exports.IM_SYNC_SERVICE_ID = void 0;
const operators_1 = require("rxjs/operators");
const table_store_1 = require("@mimecast-ui/table-store");
const rxjs_1 = require("rxjs");
exports.IM_SYNC_SERVICE_ID = 'ImSyncServiceList';
class ImTasksService {
    constructor(http) {
        this.http = http;
    }
    getData(payload, pagination = table_store_1.defaultPaginationOption) {
        return this.http
            .post('/api/instant-messaging/find-tasks', Object.assign(Object.assign({}, payload), { meta: { pagination } }))
            .pipe(operators_1.map((response) => {
            const RETURN_DATA = {
                hasErrors: response.hasErrors,
                data: response.first.tasks,
                failures: response.fail,
                meta: response.meta
            };
            return RETURN_DATA;
        }), operators_1.catchError((response) => {
            const RETURN_DATA = {
                hasErrors: response.hasErrors,
                data: response.all,
                failures: response.fail,
                meta: response.meta
            };
            return rxjs_1.of(RETURN_DATA);
        }));
    }
    filter(filter, pagination) {
        return this.getData(filter, pagination);
    }
    openItem() {
        return rxjs_1.of(undefined);
    }
}
exports.ImTasksService = ImTasksService;
