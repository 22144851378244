<mc-wizard-step-header [label]="getPageTitle(isEditFlow)"></mc-wizard-step-header>
<form class="mc-affected-users" [formGroup]="affectedUsersPage">
  <div class="mc-duplicate-warning" *ngIf="showEveryoneRadioOption">
    <mc-inline-notification  [notification]="policyAffectedUsersWarning"></mc-inline-notification>
  </div>


  <div class="radio no-margin-top no-margin-bottom panel-padding-bottom panel-padding-top" *ngIf="showEveryoneRadioOption">
    <label>
      <input
        class="mc-radio-control"
        type="radio"
        name="condition"
        value="everyone"
        formControlName="condition"
        (change)="setAllAppliesToSelection(true)"
      />
      {{ '$I18N_SWG_CREATE_POLICY_AFFECTED_USERS_EVERYONE' | translate }}
      <div>{{ '$I18N_SWG_CREATE_POLICY_AFFECTED_USERS_EVERYONE_DESC' | translate }}</div>
    </label>
  </div>

  <div class="radio panel-padding-bottom">
    <div *ngIf="showEveryoneRadioOption">
      <label>
        <input
          class="mc-radio-control"
          type="radio"
          name="condition"
          value="multi"
          formControlName="condition"
          (change)="setAllAppliesToSelection(false)"
        />
        {{ getAppliesToOptionsTitle() | translate }}
        <div>
          {{ getAppliesToOptionsDesc() | translate }}
        </div>
      </label>
    </div>
    <div [hidden]="!(affectedUsersPage.value.condition === 'multi')"
         class="{{ showEveryoneRadioOption ? 'panel-padding-left' : '' }}">
      <div *ngIf="show()" class="{{ showEveryoneRadioOption ? 'panel-padding-top' : '' }}">
        <span class="mc-sub-heading">{{'$I18N_SWG_CREATE_POLICY_AFFECTED_USERS_LOCATIONS' | translate}}</span>
        <div *ngIf="affectedUsers.length > 0" class="mc-selected-container">
          <div class="text-muted">
            <div *ngFor="let aff of affectedUsers">
              <div *ngIf="aff.condition === 'location'">
                <div class="mc-selected-trash-container">
                  {{ aff.payload['description'] }} ({{ aff.payload['ipRange'] }})
                  <i
                    class="far fa-trash-alt mc-selected-trash-icon"
                    aria-hidden="true"
                    (click)="removeAppliesTo(aff)"
                  ></i>
                </div>
                <hr class="mc-selected-hr" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <button
            [disabled]="!(affectedUsersPage.value.condition === 'multi')"
            type="submit"
            class="btn btn-default panel-half-margin-top"
            (click)="openSidebar('location')"
          >
            {{ '$I18N_SWG_CREATE_POLICY_AFFECTED_USERS_SELECT_LOCATION' | translate }}
          </button>
        </div>
      </div>

      <div class="{{ showLocationOption ? 'panel-padding-top' : '' }}" *ngIf="hasSwgFull || isBiPolicy">
        <span class="mc-sub-heading">{{'$I18N_SWG_CREATE_POLICY_AFFECTED_USERS_GROUPS' | translate}}</span>
        <div *ngIf="affectedUsers.length > 0" class="mc-selected-container">
          <div class="text-muted">
            <div *ngFor="let aff of affectedUsers">
              <div *ngIf="aff.condition === 'group'">
                <div class="mc-selected-trash-container">
                  {{ aff.payload['description'] }}
                  <i
                    class="far fa-trash-alt mc-selected-trash-icon"
                    aria-hidden="true"
                    (click)="removeAppliesTo(aff)"
                  ></i>
                </div>
                <hr class="mc-selected-hr" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <button
            [disabled]="!(affectedUsersPage.value.condition === 'multi')"
            type="submit"
            class="btn btn-default panel-half-margin-top"
            (click)="openSidebar('group')"
          >
            {{ '$I18N_SWG_CREATE_POLICY_AFFECTED_USERS_SELECT_GROUP' | translate }}
          </button>
        </div>
      </div>

      <div class="panel-padding-top" *ngIf="hasSwgFull || isBiPolicy">
        <span class="mc-sub-heading">{{'$I18N_SWG_CREATE_POLICY_AFFECTED_USERS_USERS' | translate}}</span>
        <div *ngIf="affectedUsers.length > 0" class="mc-selected-container">
          <div class="text-muted">
            <div *ngFor="let aff of affectedUsers">
              <div *ngIf="aff.condition === 'user'">
                <div class="mc-selected-trash-container" *ngIf="aff.condition === 'user'">
                  {{ aff.payload['emailAddress'] }}
                  <i
                    class="far fa-trash-alt mc-selected-trash-icon"
                    aria-hidden="true"
                    (click)="removeAppliesTo(aff)"
                  ></i>
                </div>
                <hr class="mc-selected-hr" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <button
            [disabled]="!(affectedUsersPage.value.condition === 'multi')"
            type="submit"
            class="btn btn-default panel-half-margin-top"
            (click)="openSidebar('user')"
          >
            {{ '$I18N_SWG_CREATE_POLICY_AFFECTED_USERS_SELECT_USER' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="showDeviceBasedOptions()" class="radio panel-padding-bottom">
    <div>
      <label>
        <input
          class="mc-radio-control"
          type="radio"
          name="condition"
          value="deviceBased"
          formControlName="condition"
          (change)="setAllAppliesToSelection(false)"
        />
        {{ '$I18N_SWG_CREATE_POLICY_AFFECTED_DEVICES' | translate }}
        <div>
          {{ '$I18N_SWG_CREATE_POLICY_AFFECTED_DEVICES_DESCRIPTION' | translate }}
        </div>
      </label>
    </div>
    <div [hidden]="!(affectedUsersPage.value.condition === 'deviceBased')"
         class="{{ showEveryoneRadioOption ? 'panel-padding-left' : '' }}">
      <div class="mc-enforce-device-policy"
           placement="bottom"
           [tooltip]="'$I18N_SWG_POLICY_ENFORCE_DEVICE_POLICY_TOOLTIP' | translate">
        <mc-enable-switch
          class="mc-switch-item"
          [label]="'$I18N_SWG_CREATE_POLICY_AFFECTED_ENFORCE_DEVICE_POLICY'"
          formControlName="override"
        >
        </mc-enable-switch>
      </div>
      <div class="{{ showEveryoneRadioOption ? 'panel-padding-top' : '' }} mc-options-padding-left">
        <span class="mc-sub-heading">{{'Devices' | translate}}</span>
        <div *ngIf="affectedUsers.length > 0" class="mc-selected-container">
          <div class="text-muted">
            <div *ngFor="let aff of affectedUsers">
              <div *ngIf="aff.condition === 'web_device'">
                <div class="mc-selected-trash-container">
                  {{ aff.device['description'] }}
                  <i
                    class="far fa-trash-alt mc-selected-trash-icon"
                    aria-hidden="true"
                    (click)="removeAppliesTo(aff)"
                  ></i>
                </div>
                <hr class="mc-selected-hr" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <button
            type="submit"
            class="btn btn-default panel-half-margin-top"
            (click)="openSidebar('web_device')"
          >
            {{ '$I18N_SWG_CREATE_POLICY_AFFECTED_DEVICES_SELECT' | translate }}
          </button>
        </div>
      </div>

      <div class="{{ showLocationOption ? 'panel-padding-top' : '' }} mc-options-padding-left">
        <span class="mc-sub-heading">{{'Device Groups' | translate}}</span>
        <div *ngIf="affectedUsers.length > 0" class="mc-selected-container">
          <div class="text-muted">
            <div *ngFor="let aff of affectedUsers">
              <div *ngIf="aff.condition === 'web_device_group'">
                <div class="mc-selected-trash-container">
                  {{ aff.deviceGroup['description'] }}
                  <i
                    class="far fa-trash-alt mc-selected-trash-icon"
                    aria-hidden="true"
                    (click)="removeAppliesTo(aff)"
                  ></i>
                </div>
                <hr class="mc-selected-hr" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <button
            type="submit"
            class="btn btn-default panel-half-margin-top"
            (click)="openSidebar('web_device_group')"
          >
            {{ '$I18N_SWG_CREATE_POLICY_AFFECTED_DEVICE_GROUPS_SELECT' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
