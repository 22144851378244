"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const Sha = require("jssha");
const uuid = require("uuid/v4");
const common_1 = require("../common");
class LoginService {
    constructor(http, locationService) {
        this.http = http;
        this.locationService = locationService;
    }
    discoverAuthentication(account) {
        return rxjs_1.forkJoin([
            this.http.post('/api/login/discover-authentication', account).pipe(common_1.firstResult()),
            this.http
                .post('/api/user/get-profile', this.toGetProfileRequest(account))
                .pipe(common_1.firstResult())
        ]).pipe(operators_1.switchMap(([discoResponse, internalResponse]) => {
            if (this.verifyProfileExistsAndIsInternal(account, internalResponse)) {
                return rxjs_1.of(discoResponse);
            }
            else {
                throw Error();
            }
        }));
    }
    toGetProfileRequest(account) {
        return {
            emailAddress: account.emailAddress
        };
    }
    verifyProfileExistsAndIsInternal(account, user) {
        return user.emailAddress.toLowerCase() === account.emailAddress.toLowerCase() && !user.external;
    }
    getAuthenticationTypes(authentication) {
        const authTypes = [];
        if (!!authentication.find(auth => auth.toLowerCase() === 'basic-cloud')) {
            authTypes.push('cloud');
        }
        if (!!authentication.find(auth => auth.toLowerCase() === 'basic-ad')) {
            authTypes.push('domain');
        }
        if (authTypes.length === 0) {
            throw Error();
        }
        else {
            return authTypes;
        }
    }
    login(authentication) {
        return this.http
            .post('/u/proxy/api/login/login', { data: [this.toLoginRequest(authentication)] }, this.toAuthorizationHeader(authentication))
            .pipe(common_1.firstResult());
    }
    toLoginRequest(authentication) {
        return {
            tokenType: 'key',
            username: authentication.username,
            revokePreviousBindings: authentication.revokePreviousBindings
        };
    }
    toAuthorizationHeader(auth) {
        const xdate = new Date().toUTCString();
        const xreq = uuid();
        return {
            headers: {
                Authorization: this.toApiType(auth.authenticationType) + ' ' + btoa(auth.username + ':' + auth.password),
                'x-mc-date': xdate,
                'x-mc-req-id': xreq,
                'x-context-route': this.locationService.pathname.split('/')[1],
                'x-mc-api-version': '2017.2.22',
                'x-mc-app-id': auth.appId,
                logoutOnError: 'false',
                overwriteHeaders: 'false'
            }
        };
    }
    toApiType(auth) {
        return auth === 'cloud' ? 'Basic-Cloud' : 'Basic-Ad';
    }
    submitVerificationCode(verify) {
        return this.http
            .post('/u/proxy/api/login/submit-verification-code', this.toVerificationRequest(verify), this.toSignedHeader('/api/login/submit-verification-code', verify))
            .pipe(common_1.firstResult());
    }
    toVerificationRequest(verify) {
        return {
            code: verify.verificationCode
        };
    }
    toSignedHeader(path, signing) {
        const xdate = new Date().toUTCString();
        const xreq = uuid();
        return {
            headers: {
                Authorization: 'MC ' + signing.accessKey + ':' + this.b64Hmac(xdate, xreq, path, signing),
                'x-mc-date': xdate,
                'x-mc-req-id': xreq,
                'x-context-route': this.locationService.pathname.split('/')[1],
                'x-mc-api-version': '2017.2.22',
                'x-mc-app-id': signing.appId,
                logoutOnError: 'false',
                overwriteHeaders: 'false'
            }
        };
    }
    b64Hmac(date, requestId, path, signing) {
        const sha = new Sha('SHA-1', 'TEXT');
        sha.setHMACKey(signing.secretKey, 'B64');
        sha.update(date + ':' + requestId + ':' + path + ':' + signing.appKey);
        return sha.getHMAC('B64');
    }
    extendBinding(signing) {
        return this.http
            .post('/u/proxy/api/login/extend-binding', undefined, this.toSignedHeader('/api/login/extend-binding', signing))
            .pipe(common_1.firstResult());
    }
}
exports.LoginService = LoginService;
