"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.swgCommonErrors = void 0;
exports.swgCommonErrors = {
    err_swg_web_ranges_cannot_be_deleted: '$I18N_ERR_SWG_WEB_RANGES_CANNOT_BE_DELETED',
    err_swg_web_ranges_is_already_assigned: '$I18N_ERR_SWG_WEB_RANGES_IS_ALREADY_ASSIGNED',
    err_swg_web_ranges_retrieval_failure: '$I18N_ERR_SWG_WEB_RANGES_RETRIEVAL_FAILURE',
    err_swg_web_ranges_update_failure: '$I18N_ERR_WEB_RANGES_UPDATE_FAILURE',
    err_swg_web_ranges_deletion_failure: '$I18N_ERR_SWG_WEB_RANGES_DELETION_FAILURE',
    err_swg_web_ranges_creation_failure: '$I18N_ERR_SWG_WEB_RANGES_CREATION_FAILURE',
    err_swg_web_bypass_retrieval_failure: '$I18N_ERR_SWG_WEB_BYPASS_RETRIEVAL_FAILURE',
    err_swg_web_bypass_update_failure: '$I18N_ERR_SWG_WEB_BYPASS_UPDATE_FAILURE',
    err_swg_web_bypass_deletion_failure: '$I18N_ERR_SWG_WEB_BYPASS_DELETION_FAILURE',
    err_swg_web_bypass_creation_failure: '$I18N_ERR_SWG_WEB_BYPASS_CREATION_FAILURE',
    err_invalid_ipv4_address_range: '$I18N_ERR_INVALID_OR_PRIVATE_IP_ALERT',
    err_private_ip_range: '$I18N_ERR_PRIVATE_IP_RANGE',
    err_invalid_mask: '$I18N_ERR_INVALID_MASK',
    err_web_ranges_is_in_use: '$I18N_ERR_WEB_RANGES_IS_IN_USE',
    err_web_ranges_invalid_mask_range: '$I18N_ERR_WEB_RANGES_INVALID_MASK_RANGE',
    err_web_ranges_cannot_be_deleted: '$I18N_ERR_WEB_RANGES_CANNOT_BE_DELETED',
    err_swg_web_ranges_is_in_use: '$I18N_ERR_SWG_WEB_RANGES_IS_IN_USE',
    err_swg_web_ranges_invalid_mask_range: '$I18N_ERR_SWG_WEB_RANGES_INVALID_MASK_RANGE',
    err_validation_invalid_ipv4_address_range: '$I18N_ERR_VALIDATION_INVALID_IPV4_ADDRESS_RANGE',
    err_validation_invalid_mask: '$I18N_ERR_VALIDATION_INVALID_MASK',
    err_validation_reserved_ip_range: '$I18N_ERR_VALIDATION_RESERVED_IP_RANGE',
    err_validation_private_ip_range: '$I18N_ERR_VALIDATION_PRIVATE_IP_RANGE'
};
