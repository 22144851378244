<div class="mc-layout-list-simple">
  <div class="mc-layout-main-container full-height mc-server-connection-list ">

    <div class="mc-layout-header">
      <div class="mc-layout-header-intro col-xs-12 no-padding-left">
        <div class="panel-half-padding-bottom">
          <div class="mc-text feature-title">{{ title | translate }}</div>
        </div>
        <div class="mc-text feature-description">
          {{ subtitle | translate }}
        </div>
      </div>
    </div>

  </div>
</div>




