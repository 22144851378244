"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PendoTtpUrlPackagePoliciesProvider = void 0;
const base_pendo_information_provider_1 = require("../../base-pendo-information.provider");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
class PendoTtpUrlPackagePoliciesProvider extends base_pendo_information_provider_1.BasePendoInformationProvider {
    constructor(service, userInfoApiService, capabilitiesService) {
        super();
        this.service = service;
        this.userInfoApiService = userInfoApiService;
        this.capabilitiesService = capabilitiesService;
        this.TTP_URL_PROTECT_PKG_REF_CODE = '1043';
        this.TTP_URL_PROTECT_POLICY = 20800;
        this.requestActive = {
            packageRefCode: this.TTP_URL_PROTECT_PKG_REF_CODE,
            onlyActivePolicies: true
        };
        this.requestAll = {
            packageRefCode: this.TTP_URL_PROTECT_PKG_REF_CODE,
            onlyActivePolicies: false
        };
    }
    getInformation() {
        let PKG_TTP_URL;
        let URLProtectPoliciesCount = 0;
        let URLProtectActivePoliciesCount = 0;
        return this.capabilitiesService.hasCapability('Permission.POLICIES_READ').pipe(operators_1.switchMap(hasPoliciesReadCapability => {
            let migrated = rxjs_1.of(false);
            if (hasPoliciesReadCapability) {
                migrated = this.userInfoApiService.getMigratedPolicyInfo();
            }
            return rxjs_1.combineLatest([
                this.service.getPackagePolicies(this.requestActive),
                this.service.getPackagePolicies(this.requestAll),
                migrated
            ]);
        }), operators_1.map(([active, all, migrated]) => {
            if (!active.packageRefPolicies[this.TTP_URL_PROTECT_PKG_REF_CODE]) {
                PKG_TTP_URL = false;
            }
            else {
                PKG_TTP_URL = true;
                active.packageRefPolicies[this.TTP_URL_PROTECT_PKG_REF_CODE]
                    .filter(policy => policy.policyType === this.TTP_URL_PROTECT_POLICY)
                    .forEach(policy => (URLProtectActivePoliciesCount += policy.count));
                all.packageRefPolicies[this.TTP_URL_PROTECT_PKG_REF_CODE]
                    .filter(policy => policy.policyType === this.TTP_URL_PROTECT_POLICY)
                    .forEach(policy => (URLProtectPoliciesCount += policy.count));
            }
            const accountPendo = {
                account: {
                    PKG_TTP_URL,
                    URLProtectPoliciesCount,
                    URLProtectActivePoliciesCount
                }
            };
            if (typeof migrated !== 'boolean') {
                accountPendo.account['Adcon_TTP_URL_CustomersPoliciesMigrated'] = migrated.result;
            }
            return accountPendo;
        }));
    }
}
exports.PendoTtpUrlPackagePoliciesProvider = PendoTtpUrlPackagePoliciesProvider;
