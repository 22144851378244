<div style="width: 580px;">
  <mc-wizard-step-header [label]="'$I18N_SIEM_CHANNEL_WIZARD.STEPS.PRODUCTS.TITLE'">
    {{'$I18N_SIEM_CHANNEL_WIZARD.STEPS.PRODUCTS.SUB_TITLE' | translate }}
  </mc-wizard-step-header>

  <div>
    <form
      class="form-horizontal"
      mcDefaultLabelClass="col-sm-3"
      mcDefaultControlContainerClass="col-sm-9"
      [formGroup]="channelProductsPage"
    >
      <div>
        <div *ngFor="let product of productsAvailable">
          <label class="checkbox-inline mc-category-checkbox mc-product-name" for="checkbox1_{{ product.name }}">
            <input
              id="checkbox1_{{ product.name }}"
              type="checkbox"
              formControlName="{{ product.name }}"
            />
            {{ product.name }}
          </label>
          <div class="mc-product-descr">
            <div *ngIf="product.description.length > 240; else fullDescription">
              <span> {{ product.description.substr(0, 240) }}</span>
              <mc-collapsable-panel>
                <span> {{ product.description.substr(240, product.description.length) }}</span>
              </mc-collapsable-panel>
            </div>
            <ng-template #fullDescription>
              <span>{{ product.description }}</span>
            </ng-template>
          </div>
        </div>

      </div>
    </form>
  </div>
</div>
