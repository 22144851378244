"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseGoogleAnalyticsInformationProvider = void 0;
const rxjs_1 = require("rxjs");
class BaseGoogleAnalyticsInformationProvider {
    getInformation() {
        return rxjs_1.of({});
    }
}
exports.BaseGoogleAnalyticsInformationProvider = BaseGoogleAnalyticsInformationProvider;
