"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueueConfigBuilder = void 0;
const queue_config_1 = require("./queue-config");
const queue_config_base_builder_1 = require("./queue-config-base.builder");
class QueueConfigBuilder extends queue_config_base_builder_1.QueueConfigBaseBuilder {
    self() {
        return this;
    }
    build() {
        return new queue_config_1.QueueConfig(this.id, this.name, this.createdBy, this.updatedBy, this.created, this.updated, this.description, this.type, this.printAllowed, this.forwardAllowed, this.status, this.labels, this.reviewerCount);
    }
}
exports.QueueConfigBuilder = QueueConfigBuilder;
