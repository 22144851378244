"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageDeliveryPageComponent = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const actions = require("../actions");
class MessageDeliveryPageComponent {
    constructor(capabilitiesService, userInfoApiService, store, storeBulk, tabService, el) {
        this.capabilitiesService = capabilitiesService;
        this.userInfoApiService = userInfoApiService;
        this.store = store;
        this.storeBulk = storeBulk;
        this.tabService = tabService;
        this.el = el;
        const tab = this.tabService.getSelectedTab();
        tab.onRefresh = () => {
            this.store.dispatch(new actions.MessageDeliveryRefreshAction());
            if (this.hasAccessPermissions$) {
                this.storeBulk.dispatch(new actions.BulkDeliveryRefreshAction());
            }
        };
    }
    ngOnInit() {
        this.hasMonitorBulkCapability$ = this.capabilitiesService.hasCapability('Temporary.Services.MessageCenter.Processing');
        this.hasPurgePermission$ = this.capabilitiesService.evalCapabilitiesExpression('Gateway.Congestion.Mitigation && Permission.DELIVERY_PURGE');
        this.capabilitiesAndPermisions$ = this.userInfoApiService.getCapabilitiesAndPermissions();
        this.hasAccessPermissions$ = rxjs_1.forkJoin(this.hasMonitorBulkCapability$, this.capabilitiesAndPermisions$)
            .pipe(operators_1.map(data => {
            const n1 = data[1].permissions.some(this.itHasTheSameCaps);
            const n2 = [data[0], n1].every(this.isArrayTrue);
            const n3 = this.isOnlyOne();
            return n1 === true && n2 === true && n3 === true ? false : true;
        }))
            .pipe(operators_1.catchError(() => rxjs_1.EMPTY));
    }
    itHasTheSameCaps(e) {
        return e === 'BULK_DELIVERY_READ' || e === 'BULK_DELIVERY_EDIT';
    }
    isArrayTrue(val) {
        return val === true;
    }
    isOnlyOne() {
        const firstTab = this.el.nativeElement.innerHTML.indexOf('<mc-tab>');
        const lastTab = this.el.nativeElement.innerHTML.lastIndexOf('<mc-tab>');
        return firstTab === lastTab ? false : true;
    }
}
exports.MessageDeliveryPageComponent = MessageDeliveryPageComponent;
