<div class="mc-list-panel">
  <div class="mc-list-panel-heading" translate="$I18N_NOTIFICATION_FEED_TITLE"></div>
  <div class="border-bottom small-toolbar">
    <div class="dropdown" *ngIf="!!selectionOptions">
      <button
        class="btn btn-xs no-margin no-padding btn-link dropdown-toggle"
        type="button"
        id="dropdownMenu1"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="true"
      >
        <span>{{ selectionSelected.label }}</span>
        
        <span class="icon-down-open"></span>
      </button>
      <ul class="dropdown-menu">
        <li *ngFor="let item of selectionOptions">
          <a (click)="selectionSelected = item" [textContent]="item.label"
            ><span class="{{ item.icon }}" *ngIf="icon"></span
          ></a>
        </li>
      </ul>
    </div>
  </div>
  <div *ngIf="hasData">
    <div class="mc-message-body-panel  mc-scroll">
      <div
        class="panel-body panel-padding-top panel-padding-bottom"
        *ngFor="
          let message of messages | mcNotificationsPipe: selectionSelected;
          let $index = index
        "
        [ngClass]="{ 'border-bottom': !last }"
      >
        <dl class="no-margin">
          <dt>
            <span
              class="label notification-feed-label-valignment label-default"
              *ngIf="message.noticeType === 'SERVICE_NOTIFICATION'"
              translate="$I18N_NOTIFICATION_FEED_TITLE_SERVICE"
            ></span>
            <span
              class="label label-info notification-feed-label-valignment"
              *ngIf="message.noticeType == 'PRODUCT_NEWS'"
              translate="$I18N_NOTIFICATION_FEED_TITLE_PRODUCT"
            ></span>
            <span *ngIf="message.noticeType == 'SERVICE_NOTIFICATION'">
              <i *ngIf="message.warningLevel === 'GOOD'" class="far fa-check text-success"></i>
              <i
                *ngIf="message.warningLevel === 'WARNING'"
                class="fas fa-exclamation-triangle text-warning"
              ></i>
              <i
                *ngIf="message.warningLevel === 'ERROR'"
                class="fas fa-times-circle text-danger"
              ></i>
            </span>
            <span
              class="text-muted"
              [ngClass]="{
                'text-success': message.warningLevel == 'GOOD',
                'text-warning': message.warningLevel == 'WARNING',
                'text-danger': message.warningLevel == 'ERROR'
              }"
              [textContent]="message.title"
            ></span>
            <span
              class=" small text-muted pull-right text-bold"
              *ngIf="
                $index == 0 || ($index == 1 && (!selectionSelected || selectionSelected.key == ''))
              "
              translate="$I18N_NOTIFICATION_FEED_LATEST_{{ message.noticeType }}_LABEL"
            ></span>
            <span
              class=" small text-muted pull-right text-thin"
              *ngIf="
                (!selectionSelected.key && $index > 1) || (selectionSelected.key && $index > 0)
              "
              [textContent]="message.visibleFrom | date: 'MMMM dd, yyyy'"
            ></span>
          </dt>
          <dd class="small notification-feed-message" [innerHtml]="message.displayMessage"></dd>
        </dl>
      </div>
    </div>
  </div>
  <div class="text-center mc-message-body-panel" *ngIf="!hasData">
    <div class="dashboard-no-data-icon text-muted">
      <div><span translate="$I18N_COMMON_DASHBOARD_NO_DATA_TO_SHOW_LABEL"></span></div>
    </div>
  </div>
</div>
