"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgApplicationDashboardComponent = void 0;
const core_1 = require("@angular/core");
const swg_application_dashboard_static_value_1 = require("./swg-application-dashboard.static-value");
const models_1 = require("../../../../models/models");
class SwgApplicationDashboardComponent {
    constructor(stateService, translateService) {
        this.stateService = stateService;
        this.translateService = translateService;
        this.dateRangeChange = new core_1.EventEmitter();
        this.onDashboardWidgetClick = new core_1.EventEmitter();
        this.sanctionedAppConfig = swg_application_dashboard_static_value_1.sactionedAppsChartConfiguration;
        this.nonSanctionedAppConfig = swg_application_dashboard_static_value_1.nonsactionedAppsChartConfiguration;
        this.monitoredAppConfig = swg_application_dashboard_static_value_1.monitoredAppsChartConfiguration;
        this.categoriesConfig = swg_application_dashboard_static_value_1.categoriesChartConfiguration;
        this.blockedAppConfig = swg_application_dashboard_static_value_1.blockedAppsChartConfiguration;
        this.pieChartConfiguration = swg_application_dashboard_static_value_1.clpScanCountByFileTypeConfig;
        this.userTablecolumns = swg_application_dashboard_static_value_1.userTablecolumns;
        this.deviceTablecolumns = swg_application_dashboard_static_value_1.deviceTablecolumns;
        this.ipTablecolumns = swg_application_dashboard_static_value_1.ipTablecolumns;
        this.dateRangeConfig = swg_application_dashboard_static_value_1.dateRangePickerConfig;
        this.exportFormats = swg_application_dashboard_static_value_1.exportFormats;
        this._applicationSanction = models_1.ApplicationSanction;
        this.appsChartHitEvent = {
            hit: (event) => {
                const params = {
                    action: models_1.ParamsAction.FILTER,
                    id: 'appCode',
                    value: event.target.dataItem.component.tooltipDataItem.dataContext.appCode,
                    dateRange: this.selectedDateRange
                };
                this.stateService.$state.go('secure-web-gateway-activity-report', { paramsObject: params });
            }
        };
        this.categoryChartHitEvent = {
            hit: (event) => {
                const params = {
                    action: models_1.ParamsAction.FILTER,
                    id: 'appCategory',
                    value: event.target.dataItem.component.tooltipDataItem.dataContext.code,
                    dateRange: this.selectedDateRange
                };
                this.stateService.$state.go('secure-web-gateway-activity-report', { paramsObject: params });
            }
        };
    }
    onChange($event) {
        const dateRangeSelected = this.dateRangeConfig.dateRanges.find(dateRange => this.translateService.instant(dateRange.label) === $event.target.value);
        this.selectedDateRange = dateRangeSelected;
        this.dateRangeChange.emit(dateRangeSelected);
    }
    ngOnInit() {
        this.selectedDateRange = this.dateRangeConfig.dateRanges[0];
        this.sanctionedAppConfig.series[0].events = this.appsChartHitEvent;
        this.nonSanctionedAppConfig.series[0].events = this.appsChartHitEvent;
        this.monitoredAppConfig.series[0].events = this.appsChartHitEvent;
        this.blockedAppConfig.series[0].events = this.appsChartHitEvent;
        this.categoriesConfig.series[0].events = this.categoryChartHitEvent;
    }
    onRowClick(event, searchBy) {
        const params = {
            action: models_1.ParamsAction.SEARCH,
            id: searchBy,
            value: event.name,
            dateRange: this.selectedDateRange
        };
        this.stateService.$state.go('secure-web-gateway-activity-report', { paramsObject: params });
    }
    onWidgetClick(appType) {
        const params = {
            action: models_1.ParamsAction.FILTER,
            id: 'status',
            value: appType,
            dateRange: this.selectedDateRange
        };
        this.onDashboardWidgetClick.emit(params);
    }
    convertFileTypesToLabels(fileTypeData) {
        return fileTypeData.map(fileTypeDataItem => {
            // Select the first file type that we find (in practice, we should only find one file type anyway here).
            const selectedFileType = swg_application_dashboard_static_value_1.fileTypes.filter(x => x.type.toLowerCase() === fileTypeDataItem.fileType.toLowerCase())[0];
            return {
                fileType: typeof selectedFileType !== 'undefined' && selectedFileType.label
                    ? selectedFileType.label
                    : 'Other',
                count: fileTypeDataItem.count
            };
        });
    }
}
exports.SwgApplicationDashboardComponent = SwgApplicationDashboardComponent;
