"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgClearAffectedUsersOtherThan = exports.SwgUpdatePolicyOverrideStatus = exports.SwgUpdateAffectedDeviceGroups = exports.SwgUpdateAffectedDevices = exports.CLPPolicyCheckForConnectorFailure = exports.CLPPolicyCheckForConnector = exports.BiUpdatePolicyDetailsStep = exports.SwgDeletePolicyFailure = exports.SwgDeletePolicySuccess = exports.SwgDeletePolicy = exports.SwgDisablePolicy = exports.SwgClearPolicy = exports.SwgLoadPolicyToEditFailure = exports.SwgLoadPolicyToEditSuccess = exports.SwgLoadPolicyToEditCopy = exports.SwgLoadPolicyToProcess = exports.SwgLoadPolicyToEdit = exports.SwgUpdatePolicyDescription = exports.SwgCreatePolicyFailAction = exports.SwgCreatePolicySuccessAction = exports.SwgCreatePolicyAction = exports.SwgPolicyForeceDuplicateAction = exports.SwgEveryoneAffectedUsersStep = exports.SwgFromClpNotificationListStep = exports.SwgRemoveAffectedUsersStep = exports.SwgClearAffectedUsersWarningStep = exports.SwgUpdateClpNotificationListStep = exports.SwgUpdateAffectedUsersStep = exports.SwgUpdateClpPolicySettingsStep = exports.SwgUpdateWebApplicationRuleStep = exports.SwgUpdateWebCategoryOthersStep = exports.SwgUpdateWebCategorySecurityStep = exports.SwgUpdateProtectedApplications = exports.BiSavePolicyUpdateSettings = exports.SwgTtpChameleonSettingsStep = exports.SwgTtpManagedUrlSettingsStep = exports.SwgUpdateNewDomainsSettingsStep = exports.SwgUpdateDNSProxySettingsStep = exports.SwgUpdateSafeSearchSettingsStep = exports.SwgAppendDomainsStep = exports.SwgUpdateLogDetailsStep = exports.SwgUpdateDomainsStep = exports.SwgUpdatePolicyEnabledStatus = exports.SwgUpdatePolicyDetailsStep = exports.SwgPolicyCreateTypesEnum = void 0;
var SwgPolicyCreateTypesEnum;
(function (SwgPolicyCreateTypesEnum) {
    SwgPolicyCreateTypesEnum["SWG_UPDATE_POLICY_DETAILS_STEP"] = "[Swg Policy]Update Policy Details Step";
    SwgPolicyCreateTypesEnum["SWG_UPDATE_POLICY_ENABLED_STATUS_STEP"] = "[Swg Policy]Update Policy Enabled Status Step";
    SwgPolicyCreateTypesEnum["SWG_UPDATE_DOMAINS_STEP"] = "[Swg Policy]Update Domains Step";
    SwgPolicyCreateTypesEnum["SWG_APPEND_DOMAINS_STEP"] = "[Swg Policy]Append Domains Step";
    SwgPolicyCreateTypesEnum["SWG_UPDATE_LOG_LEVEL_STEP"] = "[Swg Policy]Update Log Level Step";
    SwgPolicyCreateTypesEnum["SWG_UPDATE_SAFE_SEARCH_SETTINGS_STEP"] = "[Swg Policy]Update Safe Search Settings Step";
    SwgPolicyCreateTypesEnum["SWG_UPDATE_NEW_DOMAINS_SETTINGS_STEP"] = "[Swg Policy]Update New Domains Settings Step";
    SwgPolicyCreateTypesEnum["SWG_UPDATE_DNS_PROXY_SETTINGS_STEP"] = "[Swg Policy]Update DNS Proxy Settings Step";
    SwgPolicyCreateTypesEnum["SWG_UPDATE_TTP_MANAGED_URL_SETTINGS_STEP"] = "[Swg Policy]Update Ttp Managed Url Step";
    SwgPolicyCreateTypesEnum["SWG_UPDATE_TTP_CHAMELEON_SETTINGS_STEP"] = "[Swg Policy]Update Ttp Chameleon Settings Step";
    SwgPolicyCreateTypesEnum["SWG_UPDATE_PROTECTEDAPPLICATIONS_STEP"] = "[Swg Policy]Update Web ProtectedApplications Step";
    SwgPolicyCreateTypesEnum["SWG_UPDATE_WEB_CATEGORY_SECURITY_STEP"] = "[Swg Policy]Update Web Category Security Step";
    SwgPolicyCreateTypesEnum["SWG_UPDATE_WEB_CATEGORY_OTHERS_STEP"] = "[Swg Policy]Update Web Category Other Step";
    SwgPolicyCreateTypesEnum["SWG_UPDATE_WEB_APPLICATION_RULE_STEP"] = "[Swg Policy]Update Web Application Rule Step ";
    SwgPolicyCreateTypesEnum["SWG_UPDATE_AFFECTED_USERS_STEP"] = "[Swg Policy]Update Affected Users Step";
    SwgPolicyCreateTypesEnum["SWG_UPDATE_CLP_NOTIFICATION_LIST_STEP"] = "[Swg Policy]Update Notification List Step";
    SwgPolicyCreateTypesEnum["SWG_UPDATE_CLP_POLICY_SETTINGS_STEP"] = "[Swg Policy]Update Clp Policy Settings Step";
    SwgPolicyCreateTypesEnum["SWG_REMOVE_AFFECTED_USERS_STEP"] = "[Swg Policy]Remove Affected Users Step";
    SwgPolicyCreateTypesEnum["SWG_REMOVE_FROM_CLP_NOTIFICATION_LIST_STEP"] = "[Swg Policy]Remove From CLp notificaiton Step";
    SwgPolicyCreateTypesEnum["SWG_EVERYONE_AFFECTED_USERS_STEP"] = "[Swg Policy]Everyone Affected Users Step";
    SwgPolicyCreateTypesEnum["SWG_CLEAR_AFFECTED_USERS_WARNING_STEP"] = "[Swg Policy]Everyone Clear warning Users Step";
    SwgPolicyCreateTypesEnum["BI_POLICY_UPDATE_SETTINGS"] = "[Bi Policy] Update Settings";
    SwgPolicyCreateTypesEnum["BI_UPDATE_POLICY_DETAILS_STEP"] = "[Bi Policy]Update Policy Details Step";
    SwgPolicyCreateTypesEnum["SWG_CREATE_POLICY"] = "[Swg Policy]Create";
    SwgPolicyCreateTypesEnum["SWG_CREATE_POLICY_SUCCESS"] = "[POLICY] Create Success";
    SwgPolicyCreateTypesEnum["SWG_CREATE_POLICY_FAIL"] = "[POLICY] Create Fail";
    SwgPolicyCreateTypesEnum["SWG_POLICY_DELETE"] = "[Swg Policy]Swg delete Policy";
    SwgPolicyCreateTypesEnum["SWG_POLICY_DELETE_SUCCESS"] = "[Swg Policy]Swg delete Policy Success";
    SwgPolicyCreateTypesEnum["SWG_POLICY_DELETE_FAILURE"] = "[Swg Policy]Swg delete Policy Failure";
    SwgPolicyCreateTypesEnum["SWG_POLICY_DISABLE"] = "[Swg Policy]Swg disable Policy";
    SwgPolicyCreateTypesEnum["SWG_POLICY_DISABLE_SUCCESS"] = "[Swg Policy]Swg disable Policy Success";
    SwgPolicyCreateTypesEnum["SWG_POLICY_DISABLE_FAILURE"] = "[Swg Policy]Swg disable Policy Failure";
    SwgPolicyCreateTypesEnum["SWG_POLICY_FORCE_DUPLICATE"] = "[Swg Policy]Swg Force Duplicate Policy";
    SwgPolicyCreateTypesEnum["SWG_UPDATE_POLICY_DESCRIPTION"] = "[Swg Policy]Update policy desc";
    SwgPolicyCreateTypesEnum["SWG_LOAD_POLICY_TO_EDIT"] = "[Swg Policy]Load Policy to Edit";
    SwgPolicyCreateTypesEnum["SWG_LOAD_POLICY_TO_PROCESS"] = "[Swg Policy]Load Policy to process";
    SwgPolicyCreateTypesEnum["SWG_LOAD_POLICY_TO_EDIT_COPY"] = "[Swg Policy]Load Policy to edit copy";
    SwgPolicyCreateTypesEnum["SWG_LOAD_POLICY_TO_EDIT_SUCCESS"] = "[Swg Policy]Load Policy to Edit Success";
    SwgPolicyCreateTypesEnum["SWG_LOAD_POLICY_TO_EDIT_FAILURE"] = "[Swg Policy]Load Policy to Edit Failure";
    SwgPolicyCreateTypesEnum["SWG_CLEAR_POLICY"] = "[Swg Policy]Clear Policy";
    SwgPolicyCreateTypesEnum["CLP_POLICY_CHECK_FOR_CONNECTOR"] = "[Swg Policy]CLP Policy Check For Connector";
    SwgPolicyCreateTypesEnum["CLP_POLICY_CHECK_FOR_CONNECTOR_FAILURE"] = "[Swg Policy]CLP Policy Check For Connector Failure";
    SwgPolicyCreateTypesEnum["SWG_UPDATE_AFFECTED_DEVICES"] = "[Swg Policy]Update Affected Devices";
    SwgPolicyCreateTypesEnum["SWG_UPDATE_AFFECTED_DEVICE_GROUPS"] = "[Swg Policy]Update Affected Device Groups";
    SwgPolicyCreateTypesEnum["SWG_UPDATE_POLICY_OVERRIDE_STATUS"] = "[Swg Policy]Update Swg Policy Override Status";
    SwgPolicyCreateTypesEnum["SWG_CLEAR_AFFECTED_USERS_OTHER_THAN"] = "[Swg Policy]Clear Affected Users Other Than Passed Condition";
})(SwgPolicyCreateTypesEnum = exports.SwgPolicyCreateTypesEnum || (exports.SwgPolicyCreateTypesEnum = {}));
class SwgUpdatePolicyDetailsStep {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgPolicyCreateTypesEnum.SWG_UPDATE_POLICY_DETAILS_STEP;
    }
}
exports.SwgUpdatePolicyDetailsStep = SwgUpdatePolicyDetailsStep;
class SwgUpdatePolicyEnabledStatus {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgPolicyCreateTypesEnum.SWG_UPDATE_POLICY_ENABLED_STATUS_STEP;
    }
}
exports.SwgUpdatePolicyEnabledStatus = SwgUpdatePolicyEnabledStatus;
class SwgUpdateDomainsStep {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgPolicyCreateTypesEnum.SWG_UPDATE_DOMAINS_STEP;
    }
}
exports.SwgUpdateDomainsStep = SwgUpdateDomainsStep;
class SwgUpdateLogDetailsStep {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgPolicyCreateTypesEnum.SWG_UPDATE_LOG_LEVEL_STEP;
    }
}
exports.SwgUpdateLogDetailsStep = SwgUpdateLogDetailsStep;
class SwgAppendDomainsStep {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgPolicyCreateTypesEnum.SWG_APPEND_DOMAINS_STEP;
    }
}
exports.SwgAppendDomainsStep = SwgAppendDomainsStep;
class SwgUpdateSafeSearchSettingsStep {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgPolicyCreateTypesEnum.SWG_UPDATE_SAFE_SEARCH_SETTINGS_STEP;
    }
}
exports.SwgUpdateSafeSearchSettingsStep = SwgUpdateSafeSearchSettingsStep;
class SwgUpdateDNSProxySettingsStep {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgPolicyCreateTypesEnum.SWG_UPDATE_DNS_PROXY_SETTINGS_STEP;
    }
}
exports.SwgUpdateDNSProxySettingsStep = SwgUpdateDNSProxySettingsStep;
class SwgUpdateNewDomainsSettingsStep {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgPolicyCreateTypesEnum.SWG_UPDATE_NEW_DOMAINS_SETTINGS_STEP;
    }
}
exports.SwgUpdateNewDomainsSettingsStep = SwgUpdateNewDomainsSettingsStep;
class SwgTtpManagedUrlSettingsStep {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgPolicyCreateTypesEnum.SWG_UPDATE_TTP_MANAGED_URL_SETTINGS_STEP;
    }
}
exports.SwgTtpManagedUrlSettingsStep = SwgTtpManagedUrlSettingsStep;
class SwgTtpChameleonSettingsStep {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgPolicyCreateTypesEnum.SWG_UPDATE_TTP_CHAMELEON_SETTINGS_STEP;
    }
}
exports.SwgTtpChameleonSettingsStep = SwgTtpChameleonSettingsStep;
class BiSavePolicyUpdateSettings {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgPolicyCreateTypesEnum.BI_POLICY_UPDATE_SETTINGS;
    }
}
exports.BiSavePolicyUpdateSettings = BiSavePolicyUpdateSettings;
class SwgUpdateProtectedApplications {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgPolicyCreateTypesEnum.SWG_UPDATE_PROTECTEDAPPLICATIONS_STEP;
    }
}
exports.SwgUpdateProtectedApplications = SwgUpdateProtectedApplications;
class SwgUpdateWebCategorySecurityStep {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgPolicyCreateTypesEnum.SWG_UPDATE_WEB_CATEGORY_SECURITY_STEP;
    }
}
exports.SwgUpdateWebCategorySecurityStep = SwgUpdateWebCategorySecurityStep;
class SwgUpdateWebCategoryOthersStep {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgPolicyCreateTypesEnum.SWG_UPDATE_WEB_CATEGORY_OTHERS_STEP;
    }
}
exports.SwgUpdateWebCategoryOthersStep = SwgUpdateWebCategoryOthersStep;
class SwgUpdateWebApplicationRuleStep {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgPolicyCreateTypesEnum.SWG_UPDATE_WEB_APPLICATION_RULE_STEP;
    }
}
exports.SwgUpdateWebApplicationRuleStep = SwgUpdateWebApplicationRuleStep;
class SwgUpdateClpPolicySettingsStep {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgPolicyCreateTypesEnum.SWG_UPDATE_CLP_POLICY_SETTINGS_STEP;
    }
}
exports.SwgUpdateClpPolicySettingsStep = SwgUpdateClpPolicySettingsStep;
class SwgUpdateAffectedUsersStep {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgPolicyCreateTypesEnum.SWG_UPDATE_AFFECTED_USERS_STEP;
    }
}
exports.SwgUpdateAffectedUsersStep = SwgUpdateAffectedUsersStep;
class SwgUpdateClpNotificationListStep {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgPolicyCreateTypesEnum.SWG_UPDATE_CLP_NOTIFICATION_LIST_STEP;
    }
}
exports.SwgUpdateClpNotificationListStep = SwgUpdateClpNotificationListStep;
class SwgClearAffectedUsersWarningStep {
    constructor() {
        this.type = SwgPolicyCreateTypesEnum.SWG_CLEAR_AFFECTED_USERS_WARNING_STEP;
    }
}
exports.SwgClearAffectedUsersWarningStep = SwgClearAffectedUsersWarningStep;
class SwgRemoveAffectedUsersStep {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgPolicyCreateTypesEnum.SWG_REMOVE_AFFECTED_USERS_STEP;
    }
}
exports.SwgRemoveAffectedUsersStep = SwgRemoveAffectedUsersStep;
class SwgFromClpNotificationListStep {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgPolicyCreateTypesEnum.SWG_REMOVE_FROM_CLP_NOTIFICATION_LIST_STEP;
    }
}
exports.SwgFromClpNotificationListStep = SwgFromClpNotificationListStep;
class SwgEveryoneAffectedUsersStep {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgPolicyCreateTypesEnum.SWG_EVERYONE_AFFECTED_USERS_STEP;
    }
}
exports.SwgEveryoneAffectedUsersStep = SwgEveryoneAffectedUsersStep;
class SwgPolicyForeceDuplicateAction {
    constructor() {
        this.type = SwgPolicyCreateTypesEnum.SWG_POLICY_FORCE_DUPLICATE;
    }
}
exports.SwgPolicyForeceDuplicateAction = SwgPolicyForeceDuplicateAction;
class SwgCreatePolicyAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgPolicyCreateTypesEnum.SWG_CREATE_POLICY;
    }
}
exports.SwgCreatePolicyAction = SwgCreatePolicyAction;
class SwgCreatePolicySuccessAction {
    constructor() {
        this.type = SwgPolicyCreateTypesEnum.SWG_CREATE_POLICY_SUCCESS;
    }
}
exports.SwgCreatePolicySuccessAction = SwgCreatePolicySuccessAction;
class SwgCreatePolicyFailAction {
    constructor() {
        this.type = SwgPolicyCreateTypesEnum.SWG_CREATE_POLICY_FAIL;
    }
}
exports.SwgCreatePolicyFailAction = SwgCreatePolicyFailAction;
class SwgUpdatePolicyDescription {
    constructor() {
        this.type = SwgPolicyCreateTypesEnum.SWG_UPDATE_POLICY_DESCRIPTION;
    }
}
exports.SwgUpdatePolicyDescription = SwgUpdatePolicyDescription;
class SwgLoadPolicyToEdit {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgPolicyCreateTypesEnum.SWG_LOAD_POLICY_TO_EDIT;
    }
}
exports.SwgLoadPolicyToEdit = SwgLoadPolicyToEdit;
class SwgLoadPolicyToProcess {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgPolicyCreateTypesEnum.SWG_LOAD_POLICY_TO_PROCESS;
    }
}
exports.SwgLoadPolicyToProcess = SwgLoadPolicyToProcess;
class SwgLoadPolicyToEditCopy {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgPolicyCreateTypesEnum.SWG_LOAD_POLICY_TO_EDIT_COPY;
    }
}
exports.SwgLoadPolicyToEditCopy = SwgLoadPolicyToEditCopy;
class SwgLoadPolicyToEditSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgPolicyCreateTypesEnum.SWG_LOAD_POLICY_TO_EDIT_SUCCESS;
    }
}
exports.SwgLoadPolicyToEditSuccess = SwgLoadPolicyToEditSuccess;
class SwgLoadPolicyToEditFailure {
    constructor() {
        this.type = SwgPolicyCreateTypesEnum.SWG_LOAD_POLICY_TO_EDIT_FAILURE;
    }
}
exports.SwgLoadPolicyToEditFailure = SwgLoadPolicyToEditFailure;
class SwgClearPolicy {
    constructor() {
        this.type = SwgPolicyCreateTypesEnum.SWG_CLEAR_POLICY;
    }
}
exports.SwgClearPolicy = SwgClearPolicy;
class SwgDisablePolicy {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgPolicyCreateTypesEnum.SWG_POLICY_DISABLE;
    }
}
exports.SwgDisablePolicy = SwgDisablePolicy;
class SwgDeletePolicy {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgPolicyCreateTypesEnum.SWG_POLICY_DELETE;
    }
}
exports.SwgDeletePolicy = SwgDeletePolicy;
class SwgDeletePolicySuccess {
    constructor() {
        this.type = SwgPolicyCreateTypesEnum.SWG_POLICY_DELETE_SUCCESS;
    }
}
exports.SwgDeletePolicySuccess = SwgDeletePolicySuccess;
class SwgDeletePolicyFailure {
    constructor() {
        this.type = SwgPolicyCreateTypesEnum.SWG_POLICY_DELETE_FAILURE;
    }
}
exports.SwgDeletePolicyFailure = SwgDeletePolicyFailure;
class BiUpdatePolicyDetailsStep {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgPolicyCreateTypesEnum.BI_UPDATE_POLICY_DETAILS_STEP;
    }
}
exports.BiUpdatePolicyDetailsStep = BiUpdatePolicyDetailsStep;
class CLPPolicyCheckForConnector {
    constructor(payload, policyAction) {
        this.payload = payload;
        this.policyAction = policyAction;
        this.type = SwgPolicyCreateTypesEnum.CLP_POLICY_CHECK_FOR_CONNECTOR;
    }
}
exports.CLPPolicyCheckForConnector = CLPPolicyCheckForConnector;
class CLPPolicyCheckForConnectorFailure {
    constructor() {
        this.type = SwgPolicyCreateTypesEnum.CLP_POLICY_CHECK_FOR_CONNECTOR_FAILURE;
    }
}
exports.CLPPolicyCheckForConnectorFailure = CLPPolicyCheckForConnectorFailure;
class SwgUpdateAffectedDevices {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgPolicyCreateTypesEnum.SWG_UPDATE_AFFECTED_DEVICES;
    }
}
exports.SwgUpdateAffectedDevices = SwgUpdateAffectedDevices;
class SwgUpdateAffectedDeviceGroups {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgPolicyCreateTypesEnum.SWG_UPDATE_AFFECTED_DEVICE_GROUPS;
    }
}
exports.SwgUpdateAffectedDeviceGroups = SwgUpdateAffectedDeviceGroups;
class SwgUpdatePolicyOverrideStatus {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgPolicyCreateTypesEnum.SWG_UPDATE_POLICY_OVERRIDE_STATUS;
    }
}
exports.SwgUpdatePolicyOverrideStatus = SwgUpdatePolicyOverrideStatus;
class SwgClearAffectedUsersOtherThan {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgPolicyCreateTypesEnum.SWG_CLEAR_AFFECTED_USERS_OTHER_THAN;
    }
}
exports.SwgClearAffectedUsersOtherThan = SwgClearAffectedUsersOtherThan;
