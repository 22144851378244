"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FeatureFlagService = exports.LAUNCH_DARKLY_INIT_FUNCTION = void 0;
const core_1 = require("@angular/core");
const store_1 = require("@ngrx/store");
const core_2 = require("app-new/core");
const launchdarkly_js_client_sdk_1 = require("launchdarkly-js-client-sdk");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const environment_1 = require("../environments/build/environment");
const environment_dev_1 = require("../environments/deploy/environment.dev");
const environment_qa_1 = require("../environments/deploy/environment.qa");
const environment_prod_1 = require("../environments/deploy/environment.prod");
/** Only used for testing to mock the global `import { initialize } from 'launchdarkly-js-client-sdk';` */
exports.LAUNCH_DARKLY_INIT_FUNCTION = new core_1.InjectionToken('LaunchDarklyClientInitFunction', { factory: () => launchdarkly_js_client_sdk_1.initialize });
class FeatureFlagService {
    constructor(ldClientInitFn, store, window) {
        this.ldClientInitFn = ldClientInitFn;
        this.store = store;
        this.window = window;
        this._isReady = false;
        this.readyPromise = new Promise((res, rej) => {
            this.resolveReady = res;
            this.rejectReady = rej;
        });
        this.setup$ = this.store.pipe(store_1.select(core_2.getAccount), operators_1.filter(v => !!v), operators_1.take(1), operators_1.mergeMap(account => {
            const context = this.toLDContext(account);
            const clientId = this.getClientId(account);
            const logger = launchdarkly_js_client_sdk_1.basicLogger({ level: environment_1.environment.production ? 'error' : 'info' });
            this.launchDarklyClient = this.ldClientInitFn(clientId, context, { logger });
            return rxjs_1.from(this.launchDarklyClient.waitForInitialization());
        }), operators_1.tap(() => {
            this._isReady = true;
            this.resolveReady();
        }), operators_1.catchError(ex => {
            this.rejectReady(ex);
            throw ex;
        }), operators_1.share());
    }
    get ready() {
        return this._isReady;
    }
    setup() {
        return this.setup$;
    }
    isReady() {
        return this.readyPromise;
    }
    getBooleanFlag(flag, defaultValue = false) {
        return this.getFlag(flag, defaultValue);
    }
    getFlag(flag, defaultValue) {
        if (!this.launchDarklyClient || !this.ready) {
            return defaultValue;
        }
        const flagState = this.launchDarklyClient.variation(flag, defaultValue);
        return this.getOverriddenFlags(flag, flagState);
    }
    toLDContext(account) {
        return {
            kind: 'multi',
            account: {
                kind: 'account',
                key: account.accountCode,
                code: account.accountCode,
                gridRegion: account.region
            }
        };
    }
    getClientId(account) {
        var _a, _b;
        if (['dev', 'devrh'].includes((_a = account.region) === null || _a === void 0 ? void 0 : _a.toLowerCase())) {
            return environment_dev_1.environment.launchDarklyClientId;
        }
        else if (['qa', 'snd', 'stg', 'lt', 'stgrh'].includes((_b = account.region) === null || _b === void 0 ? void 0 : _b.toLowerCase())) {
            return environment_qa_1.environment.launchDarklyClientId;
        }
        return environment_prod_1.environment.launchDarklyClientId;
    }
    getOverriddenFlags(flag, flagState) {
        var _a;
        try {
            let overrides = {};
            const flags = this.window.sessionStorage.getItem('overridden-flags');
            if (flags) {
                overrides = JSON.parse(flags);
            }
            return (_a = overrides[flag]) !== null && _a !== void 0 ? _a : flagState;
        }
        catch (_b) {
            return flagState;
        }
    }
}
exports.FeatureFlagService = FeatureFlagService;
