"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgEndpointsSettingsTuidAttributeModalComponent = void 0;
const settings_actions_1 = require("../../../../actions/settings.actions");
const endpoints_models_1 = require("../../../../models/endpoints.models");
class SwgEndpointsSettingsTuidAttributeModalComponent {
    constructor(store, overlayRef, settings) {
        this.store = store;
        this.overlayRef = overlayRef;
        this.settings = settings;
        this.tuidAttributes = endpoints_models_1.SwgTUIDAttributeOption;
    }
    onCancel() {
        this.store.dispatch(new settings_actions_1.SwgEndpointsSettingsCancelTUIDAttributeModalAction(this.settings));
        this.overlayRef.close();
    }
    onUpdateSetting() {
        this.store.dispatch(new settings_actions_1.SwgEndpointsSettingsUpdateSettingsAction(this.settings));
    }
}
exports.SwgEndpointsSettingsTuidAttributeModalComponent = SwgEndpointsSettingsTuidAttributeModalComponent;
