"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getQueueDetails = exports.getSelectedReviewers = exports.getReviewers = exports.getEscalationQueues = exports.getFailure = exports.getLoading = exports.getSaving = exports.reducer = void 0;
const queue_actions_1 = require("./../actions/queue.actions");
const initialState = {
    saving: false,
    loading: false,
    escalationQueues: []
};
function reducer(state = initialState, action) {
    switch (action.type) {
        case queue_actions_1.QueueActions.CREATE:
            return Object.assign(Object.assign({}, state), { saving: true });
        case queue_actions_1.QueueActions.CREATE_SUCCESS:
            return Object.assign(Object.assign({}, state), { saving: false });
        case queue_actions_1.QueueActions.CREATE_FAIL:
            return Object.assign(Object.assign({}, state), { saving: false });
        case queue_actions_1.QueueActions.UPDATE:
            return Object.assign(Object.assign({}, state), { saving: true });
        case queue_actions_1.QueueActions.UPDATE_SUCCESS:
            return Object.assign(Object.assign({}, state), { saving: false });
        case queue_actions_1.QueueActions.UPDATE_FAIL:
            return Object.assign(Object.assign({}, state), { saving: false });
        case queue_actions_1.QueueActions.ADD_REVIEWERS:
            return Object.assign(Object.assign({}, state), { saving: true, selectedReviewers: [...action.selectedReviewers] });
        case queue_actions_1.QueueActions.ADD_REVIEWERS_SUCCESS:
            return Object.assign(Object.assign({}, state), { saving: false });
        case queue_actions_1.QueueActions.ADD_REVIEWERS_FAIL:
            return Object.assign(Object.assign({}, state), { saving: false });
        case queue_actions_1.QueueActions.GET_ESCALATIONQUEUES:
            return Object.assign(Object.assign({}, state), { saving: false, loading: true });
        case queue_actions_1.QueueActions.GET_ESCALATIONQUEUES_SUCCESS:
            return Object.assign(Object.assign({}, state), { saving: false, escalationQueues: [...action.paypload], loading: false });
        case queue_actions_1.QueueActions.GET_ESCALATIONQUEUES_FAIL:
            return Object.assign(Object.assign({}, state), { saving: false, escalationQueues: undefined, loading: false });
        case queue_actions_1.QueueActions.GET_REVIEWERS:
            return Object.assign(Object.assign({}, state), { saving: false, loading: true });
        case queue_actions_1.QueueActions.GET_REVIEWERS_SUCCESS:
            return Object.assign(Object.assign({}, state), { saving: false, reviewers: [...action.paypload], loading: false });
        case queue_actions_1.QueueActions.GET_REVIEWERS_PAGINATION_SUCCESS:
            return Object.assign(Object.assign({}, state), { saving: false, reviewers: action.payload.all[0].reviewers, pagination: action.payload.meta.pagination, loading: false });
        case queue_actions_1.QueueActions.GET_REVIEWERS_FAIL:
            return Object.assign(Object.assign({}, state), { saving: false, reviewers: undefined, loading: false });
        case queue_actions_1.QueueActions.CLEAR_DATA:
            return Object.assign(Object.assign({}, state), { saving: false, escalationQueues: undefined, loading: false, reviewers: undefined, selectedReviewers: undefined });
        case queue_actions_1.QueueActions.CLOSE_SELECT_REVIEWERS_PANEL:
            return Object.assign(Object.assign({}, state), { loading: false, reviewers: undefined });
        case queue_actions_1.QueueActions.GET_SELECTED_REVIEWERS:
            return Object.assign(Object.assign({}, state), { loading: false, reviewers: undefined, selectedReviewers: undefined });
        case queue_actions_1.QueueActions.REMOVE_GET_SELECTED_REVIEWERS:
            const selectedReviewers = state.selectedReviewers.filter(reviewer => reviewer.id !== action.reviewer.id);
            return Object.assign(Object.assign({}, state), { loading: false, reviewers: undefined, selectedReviewers: [...selectedReviewers] });
        default:
            return Object.assign({}, state);
    }
}
exports.reducer = reducer;
const getSaving = (state) => state && state.saving;
exports.getSaving = getSaving;
const getLoading = (state) => state && state.loading;
exports.getLoading = getLoading;
const getFailure = (state) => state && state.failure;
exports.getFailure = getFailure;
const getEscalationQueues = (state) => state && state.escalationQueues;
exports.getEscalationQueues = getEscalationQueues;
const getReviewers = (state) => state && state.reviewers;
exports.getReviewers = getReviewers;
const getSelectedReviewers = (state) => state && state.selectedReviewers;
exports.getSelectedReviewers = getSelectedReviewers;
const getQueueDetails = (state) => state && state.queueDetails;
exports.getQueueDetails = getQueueDetails;
