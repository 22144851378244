<div class="mc-ap-policy-outbound-settings-block mc-ap-policy-steps-container">
  <mc-wizard-step-header class="mc-ap-wizard-step-header"
                         label="$I18N_AP_CREATE_POLICY_STEPS.POLICY_OUTBOUND_SETTINGS_STEP_LABEL.$I18N_AP_CREATE_POLICY_OUTBOUND_STEPS">
  </mc-wizard-step-header>
  <form class="form-horizontal"
        mcDefaultLabelClass="col-sm-4"
        mcDefaultControlContainerClass="col-sm-8"
        [formGroup]="apOutboundSettingsForm">
    <mc-switch-field label="$I18N_AP_CREATE_POLICY_STEPS.POLICY_OUTBOUND_SETTINGS_STEP_LABEL.ENABLE_OUTBOUND.LABEL"
                     helpPopoverContent="{{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_OUTBOUND_SETTINGS_STEP_LABEL.ENABLE_OUTBOUND.HELP_POPOVER' | translate }}"
                     formControlName="enableOutboundCheck">
    </mc-switch-field>
    <div *ngIf="apOutboundSettingsForm.get('enableOutboundCheck').value === true">
      <div
        class="mc-text-thick">{{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_OUTBOUND_SETTINGS_STEP_LABEL.LABEL' | translate}}</div>
      <mc-field label="$I18N_AP_CREATE_POLICY_STEPS.POLICY_OUTBOUND_SETTINGS_STEP_LABEL.GATEWAY_ACTIONS.LABEL"
                helpPopoverContent="{{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_OUTBOUND_SETTINGS_STEP_LABEL.GATEWAY_ACTIONS.HELP_POPOVER' | translate }}">
        <div class="form-control-children">
          <mc-select formControlName="gatewayAction"
                     [options]="GatewayActionOptions"
                     class="mc-timeout-control">
          </mc-select>
        </div>
      </mc-field>
      <mc-field label="$I18N_AP_CREATE_POLICY_STEPS.POLICY_OUTBOUND_SETTINGS_STEP_LABEL.GATEWAY_FALLBACK_ACTIONS.LABEL"
                helpPopoverContent="{{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_OUTBOUND_SETTINGS_STEP_LABEL.GATEWAY_FALLBACK_ACTIONS.HELP_POPOVER' | translate }}">
        <div class="form-control-children">
          <mc-select formControlName="gatewayFallbackAction"
                     [options]="GatewayFallbackActionOptions"
                     class="mc-timeout-control">
          </mc-select>
        </div>
      </mc-field>
      <mc-field label="$I18N_AP_CREATE_POLICY_STEPS.POLICY_OUTBOUND_SETTINGS_STEP_LABEL.SENDER_MAILBOX_ACTION.LABEL"
                helpPopoverContent="{{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_OUTBOUND_SETTINGS_STEP_LABEL.SENDER_MAILBOX_ACTION.HELP_POPOVER' | translate }}">
        <div class="form-control-children">
          <mc-select formControlName="senderMailboxAction"
                     [options]="SenderMailboxActionOptions"
                     class="mc-timeout-control">
          </mc-select>
        </div>
      </mc-field>
      <mc-field
        label="$I18N_AP_CREATE_POLICY_STEPS.POLICY_OUTBOUND_SETTINGS_STEP_LABEL.SENDER_MAILBOX_FALLBACK_ACTION.LABEL"
        helpPopoverContent="{{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_OUTBOUND_SETTINGS_STEP_LABEL.SENDER_MAILBOX_FALLBACK_ACTION.HELP_POPOVER' | translate }}">
        <div class="form-control-children">
          <mc-select formControlName="senderMailboxFallbackAction"
                     [options]="SenderMailboxFallbackActionOptions"
                     class="mc-timeout-control">
          </mc-select>
        </div>
      </mc-field>
      <div
        class="mc-text-thick">{{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.NOTIFICATIONSLABEL' | translate}}</div>
      <div
        class="mc-ap-policy-notification-text">{{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.NOTIFICATIONSDESCRIPTION' | translate}}</div>
      <mc-field label="$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.USERNOTIFICATION.LABEL">
        <div>
          <mc-checkbox class="mc-ap-checkbox"
                       label="$I18N_AP_CREATE_POLICY_STEPS.POLICY_OUTBOUND_SETTINGS_STEP_LABEL.INTERNAL_SENDER.LABEL"
                       formControlName="internalSenderOutbound">
          </mc-checkbox>
        </div>
      </mc-field>
      <div class="mc-select-group-field">
        <label
          class="mc-select-label">{{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.ADMINISTRATORS.LABEL' | translate}}</label>
        <button class="btn btn-secondary mc-select-notification"
                (click)="openSidebar()">{{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.ADMINISTRATORS.SELECT_GROUP_BUTTON'| translate }}
        </button>
      </div>
      <div class="mc-selected-group" *ngIf="selectGroupOutbound">
        <div class="mc-selected-group-display">
          <div class="mc-groups-list">{{ selectGroupOutbound.description | translate }}
          </div>
          <div class="mc-delete-group">
            <i class="far fa-trash-alt" aria-hidden="true" (click)="onDeleteGroup(selectGroupOutbound)"></i>
          </div>
        </div>
        <hr class="mc-group-separator"/>
      </div>
    </div>
  </form>
</div>
