<label class="control-label col-sm-3"
       [class.align-top]="labelAlignment == 'top'"
       [class.align-switch]="labelAlignment == 'switch'"
       [attr.role]="helpPopoverContent ? 'button' : null"
       (click)="helpPopoverContent && toggle($event)">
  <span class="mc-label-wrapper">
      <span
        *ngIf="helpPopoverContent"
        #helpPopover="bs-popover"
        class="fas fa-question-circle mc-popover-icon"
        placement="{{ popoverPlacement ? popoverPlacement : 'top' }}"
        container="body"
        triggers=""
        [popover]="helpPopoverContent"
        [outsideClick]="true"
        [containerClass]="helpPopoverContainerClassCombined"
      >
      </span>
      <span class="mc-form-select-label">{{ label | translate }}</span>
      <span class="mc-required-field text-danger" *ngIf="isRequired">*</span>
    </span>
</label>

<div class="mc-container-select-field col-sm-9" [ngClass]="{ 'col-xs-6': shouldTrimOptions }">
  <label>
    <select *ngIf="!shouldTrimOptions" class="mc-native-select btn btn-secondary" [formControl]="field">
      <option *ngIf="!!placeholder" [ngValue]="null">{{ placeholder | translate }}</option>
      <option *ngFor="let option of selectOptions" [ngValue]="option.value">
        <ng-container *ngIf="useTranslation; else withoutTranslation">{{ option.label | translate }}</ng-container>
        <ng-template #withoutTranslation>{{ option.label }}</ng-template>
      </option>
    </select>
    <select *ngIf="shouldTrimOptions" class="mc-native-select btn btn-secondary" [formControl]="field">
      <option *ngIf="!!placeholder" [ngValue]="null">{{ placeholder | translate }}</option>
      <option *ngFor="let option of trimmedOptions" [ngValue]="option.value">
        <ng-container *ngIf="useTranslation; else withoutTranslation">{{ option.label | translate }}</ng-container>
        <ng-template #withoutTranslation>{{ option.label }}</ng-template>
      </option>
    </select>
  </label>
  <em class="far fa-chevron-down mc-select-chevron"></em>
</div>
