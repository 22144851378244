"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MepComponent = void 0;
const sidebarActions = require("./policies/actions/sidebar.actions");
class MepComponent {
    constructor(store, state$, tabService, impl$, capabilitiesService) {
        this.store = store;
        this.state$ = state$;
        this.tabService = tabService;
        this.impl$ = impl$;
        this.capabilitiesService = capabilitiesService;
        const tab = this.tabService.getSelectedTab();
        tab.onRefresh = () => {
            this.mepPolicyListPage.runFilters();
            if (this.mepPolicyLogsPage) {
                this.mepPolicyLogsPage.runFilters();
            }
        };
    }
    ngOnInit() {
        this.isLogPermissionAssigned$ = this.capabilitiesService.hasCapability('Permission.MEP_VIEW_LOGS');
        // If we get here via a route with a 'displayTab' parameter, display that tab.
        this.isLogPermissionAssigned$.subscribe(assigned => {
            switch (this.state$.$state.params.displayTab) {
                case 'logs':
                    this.displayTab = 0;
                    break;
                case 'policies':
                    if (!assigned) {
                        this.displayTab = 0;
                    }
                    else {
                        this.displayTab = 1;
                    }
                    break;
                default:
                    break;
            }
        });
    }
    ngAfterViewInit() {
        this.switchToTabSub = this.impl$.switchToTab$.subscribe(switchToTab => {
            // This subscription should be called whenever the tab is changed via the sidebar (e.g. in the Associated Policy section).
            // This admittedly inelegant workaround is needed as `initialActiveTabIndex` won't be changed if the link in the sidebar
            // is clicked more than once.
            if (switchToTab) {
                // Show the content of the given tab, whilst hiding the content of all other tabs.
                this.tabGroup.tabs.forEach((tab, index) => {
                    tab.showTab(index === ['log', 'policies'].indexOf(switchToTab));
                });
                // Clear the tab state by setting it to null but continuing to display the content.
                // This will then allow us to call this again.
                this.impl$.clearTabState();
            }
        });
    }
    onRowClick(row) {
        this.store.dispatch(new sidebarActions.MepPolicyListSidebarOpen(row));
    }
    ngOnDestroy() {
        if (this.switchToTabSub) {
            this.switchToTabSub.unsubscribe();
        }
    }
}
exports.MepComponent = MepComponent;
