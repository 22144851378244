"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArchiveViewerComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const operators_1 = require("rxjs/operators");
class ArchiveViewerComponent {
    constructor(_fb) {
        this._fb = _fb;
        this.horizontal = false;
        this.formTokenValueChanges = '';
        this.clickSliderToogle = new core_1.EventEmitter();
        this.formReasonValueChanges = new core_1.EventEmitter();
        this.events = [];
    }
    ngOnInit() {
        this.formReason = this._fb.group({
            reason: ['', [forms_1.Validators.required, forms_1.Validators.minLength(3), forms_1.Validators.maxLength(150)]]
        });
        this.formReason.valueChanges
            .pipe(operators_1.debounceTime(300))
            .subscribe((newFormValue) => this.onFormValueChanges(newFormValue));
    }
    onFormValueChanges(newFormValue) {
        this.formReasonValueChanges = newFormValue;
    }
}
exports.ArchiveViewerComponent = ArchiveViewerComponent;
