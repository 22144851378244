"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListComponent = void 0;
const core_1 = require("@angular/core");
const table_store_1 = require("@mimecast-ui/table-store");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const models_1 = require("../../../policies/models/models");
const logs_actions_1 = require("../../actions/logs.actions");
const sidebar_actions_1 = require("../../actions/sidebar.actions");
const models_2 = require("../../models/models");
const tableConfig = require("./list.static-values");
const selectors_1 = require("../../selectors");
class ListComponent extends table_store_1.TableBaseComponent {
    constructor(store, mepAdminLogService, capabilitiesService, mepLogListImplService) {
        super(store, tableConfig.MEP_LOG_TABLE_ID);
        this.store = store;
        this.mepAdminLogService = mepAdminLogService;
        this.capabilitiesService = capabilitiesService;
        this.mepLogListImplService = mepLogListImplService;
        this.rowClick = new core_1.EventEmitter();
        this.destroy$ = new rxjs_1.Subject();
        this.filters = tableConfig.defaultSearchQuery;
        this.columns = tableConfig.columns;
        this.columnsAlwaysVisible = tableConfig.columnsAlwaysVisible;
        this.filterTypes = tableConfig.filterSearchOptions;
        this.affectedUsers = models_1.MepAffectedUsersEnum;
        this.dateRangePickerConfig = tableConfig.mepLogPageDateRangePickerConfig;
        this.searchOptionsSearchValue = '';
        this.filtersDateEntry = this.dateRangePickerConfig.dateRanges[0];
        this.mepLogHeldStatus = models_2.MepLogStatus.HELD;
        this.mepAdminLogActions = models_2.MepAdminLogActions;
    }
    ngOnInit() {
        this.runFilters();
        this.hasMepPolicyEditPermission$ = this.capabilitiesService.hasCapability('Permission.MEP_EDIT');
        this.hasMepLogReleaseDropPermission$ = this.capabilitiesService.hasCapability('Permission.MEP_RELEASE/DROP');
        this.searchFilters = [Object.assign({}, tableConfig.searchFilters)];
        this.hasAdminActionInProgress$ = this.store.select(selectors_1.getMepLogAdminActionProgress);
        this.hasAdminActionIsSuccess$ = this.store.select(selectors_1.getMepLogAdminActionSuccess);
        this.onMepLogAdminAction();
    }
    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
    onSearch(filters) {
        const searchBy = { fieldName: filters.filterBy, value: filters.search };
        this.filters = Object.assign(Object.assign({}, this.filters), { searchBy });
        this.runFilters();
    }
    onSearchClear() {
        this.filters = Object.assign(Object.assign({}, this.filters), { searchBy: null });
        this.runFilters();
    }
    onBundleFilterChange(filters) {
        if (filters.status) {
            const formattedFilters = filters.status.map(filter => {
                return {
                    fieldName: 'status',
                    value: filter
                };
            });
            this.filters = Object.assign(Object.assign({}, this.filters), { filterBy: formattedFilters });
        }
        else {
            this.filters = Object.assign(Object.assign({}, this.filters), { filterBy: null });
        }
        this.runFilters();
    }
    onDateRangeFilterChange(value) {
        this.filtersDateEntry = value;
        this.runFilters();
    }
    onRowClick(row) {
        super.onRowClick(row);
        this.store.dispatch(new sidebar_actions_1.MepLogOpenItemSidebar());
    }
    onAdminAction(row, action) {
        const id = row.id;
        this.mepAdminLogService
            .openLogActionConfirmationModal(action)
            .pipe(operators_1.takeUntil(this.destroy$))
            .subscribe((confirmed) => {
            if (confirmed) {
                this.store.dispatch(new logs_actions_1.MepLogAdminAction({ action, id }));
            }
        });
    }
    onExportClick() {
        this.store.dispatch(new logs_actions_1.MepLogsOpenExportSidebar(tableConfig.exportOptions));
    }
    formatRecipients(recipients, recipientCount) {
        return this.mepLogListImplService.formatRecipients(recipients, recipientCount);
    }
    runFilters() {
        this.filters = Object.assign(Object.assign({}, this.filters), this.getFilterDate());
        this.onFilterChange(this.filters);
    }
    getFilterDate() {
        const selectedDateRange = this.filtersDateEntry.rangeFn();
        return {
            from: tableConfig.replaceMilliSeconds(selectedDateRange['start']),
            to: tableConfig.replaceMilliSeconds(selectedDateRange['end'])
        };
    }
    onMepLogAdminAction() {
        rxjs_1.combineLatest([this.hasAdminActionInProgress$, this.hasAdminActionIsSuccess$])
            .pipe(operators_1.takeUntil(this.destroy$))
            .subscribe(([progress, success]) => !progress && success && this.runFilters());
    }
}
exports.ListComponent = ListComponent;
