<div class="row mc-detailed-list-row">
  <div class="col-xs-3 mc-detailed-list-label">
    {{ listLabel }}
  </div>
  <div [class.mc-show-hidden]="isExpanded" *ngIf="listItems && listItems.length > 0; else content" class="mc-detailed-list-column">
    <div *ngFor="let item of listItems; index as i"  data-unit-test="valueListItems">
      <div [ngClass]="{'mc-items-over-the-limit': i > showMoreLessTriggerValue-1}">{{ item }}</div>
    </div>
    <div *ngIf="showMoreLess()" class="mc-show-more btn-link" (click)="invertIsExpanded()" data-unit-test="moreLess">
      <span data-unit-test="moreLessLabel">{{ (isExpanded ? showLessLabel : showMoreLabel) | translate }}</span>
      <em data-unit-test="moreLessArrow" class="far" [class.fa-angle-up]="isExpanded" [class.fa-angle-down]="!isExpanded"></em>
    </div>
  </div>

  <ng-template #content>
    <div class="mc-detailed-list-column" data-unit-test="contentProvided">
      <ng-content></ng-content>
    </div>
  </ng-template>
</div>