"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShowCreateConnectorSuccessModalAction = exports.ValidateConsentActionFail = exports.ValidateConsentActionSuccess = exports.ValidateConsentAction = exports.ResetStepFormsAction = exports.ProviderSelectionUpdatedAction = exports.ProductSelectionUpdatedAction = exports.ClearConsentConfigAction = exports.GetConsentConfigActionFail = exports.GetConsentConfigActionSuccess = exports.GetConsentConfigAction = exports.LoadProvidersActionFail = exports.LoadProvidersActionSuccess = exports.LoadProvidersAction = exports.LoadProductsActionFail = exports.LoadProductsActionSuccess = exports.LoadProductsAction = exports.DeleteConnectorActionFail = exports.GetConnectorUsageFailAction = exports.GetConnectorUsageSuccessAction = exports.GetConnectorUsageAction = exports.DeleteConnectorActionSuccess = exports.DeleteConnectorAction = exports.CreateConnectorActionFail = exports.CreateConnectorActionSuccess = exports.UpdateConnectorAction = exports.CreateConnectorAction = exports.UnsetSelectedRow = exports.SetAsidePagination = exports.GetConnectorDetailsFail = exports.GetConnectorDetailsSuccess = exports.GetConnectorDetails = exports.OpenCreateConnectorWizardAction = exports.ConnectorActions = void 0;
var ConnectorActions;
(function (ConnectorActions) {
    ConnectorActions["OPEN_CREATE_CONNECTOR_WIZARD"] = "OPEN_CREATE_CONNECTOR_WIZARD";
    ConnectorActions["CREATE_CONNECTOR"] = "CREATE_CONNECTOR";
    ConnectorActions["UPDATE_CONNECTOR"] = "UPDATE_CONNECTOR";
    ConnectorActions["CREATE_CONNECTOR_SUCCESS"] = "CREATE_CONNECTOR_SUCCESS";
    ConnectorActions["CREATE_CONNECTOR_FAIL"] = "CREATE_CONNECTOR_FAIL";
    ConnectorActions["DELETE_CONNECTOR"] = "DELETE_CONNECTOR";
    ConnectorActions["DELETE_CONNECTOR_SUCCESS"] = "DELETE_CONNECTOR_SUCCESS";
    ConnectorActions["DELETE_CONNECTOR_FAIL"] = "DELETE_CONNECTOR_FAIL";
    ConnectorActions["LOAD_PRODUCTS"] = "LOAD_PRODUCTS";
    ConnectorActions["LOAD_PRODUCTS_SUCCESS"] = "LOAD_PRODUCTS_SUCCESS";
    ConnectorActions["LOAD_PRODUCTS_FAILURE"] = "LOAD_PRODUCTS_FAILURE";
    ConnectorActions["LOAD_PROVIDERS"] = "LOAD_PROVIDERS";
    ConnectorActions["LOAD_PROVIDERS_SUCCESS"] = "LOAD_PROVIDERS_SUCCESS";
    ConnectorActions["LOAD_PROVIDERS_FAILURE"] = "LOAD_PROVIDERS_FAILURE";
    ConnectorActions["GET_CONSENT_CONFIG"] = "GET_CONSENT_CONFIG";
    ConnectorActions["GET_CONSENT_CONFIG_SUCCESS"] = "GET_CONSENT_CONFIG_SUCCESS";
    ConnectorActions["GET_CONSENT_CONFIG_FAILURE"] = "GET_CONSENT_CONFIG_FAILURE";
    ConnectorActions["CLEAR_CONSENT_CONFIG"] = "CLEAR_CONSENT_CONFIG";
    ConnectorActions["PRODUCT_SELECTION_UPDATED"] = "PRODUCT_SELECTION_UPDATED";
    ConnectorActions["PROVIDER_SELECTION_UPDATED"] = "PROVIDER_SELECTION_UPDATED";
    ConnectorActions["RESET_STEP_FORMS"] = "RESET_STEP_FORMS";
    ConnectorActions["GET_CONNECTOR_DETAILS"] = "CONNECTOR_DETAILS";
    ConnectorActions["GET_CONNECTOR_DETAILS_SUCCESS"] = "GET_CONNECTOR_DETAILS_SUCCESS";
    ConnectorActions["GET_CONNECTOR_DETAILS_FAIL"] = "GET_CONNECTOR_DETAILS_FAIL";
    ConnectorActions["SET_ASIDE_PAGINATION"] = "SET_ASIDE_PAGINATION";
    ConnectorActions["UNSET_SELECTED_ROW"] = "UNSET_SELECTED_ROW";
    ConnectorActions["GET_CONNECTOR_USAGE"] = "GET_CONNECTOR_USAGE";
    ConnectorActions["GET_CONNECTOR_USAGE_SUCCESS"] = "GET_CONNECTOR_USAGE_SUCCESS";
    ConnectorActions["GET_CONNECTOR_USAGE_FAILURE"] = "GET_CONNECTOR_USAGE_FAILURE";
    ConnectorActions["VALIDATE_CONSENT"] = "VALIDATE_CONSENT";
    ConnectorActions["VALIDATE_CONSENT_SUCCESS"] = "VALIDATE_CONSENT_SUCCESS";
    ConnectorActions["VALIDATE_CONSENT_FAILURE"] = "VALIDATE_CONSENT_FAILURE";
    ConnectorActions["SHOW_CREATE_CONNECTOR_SUCCESS_MODAL"] = "SHOW_CREATE_CONNECTOR_SUCCESS_MODAL";
})(ConnectorActions = exports.ConnectorActions || (exports.ConnectorActions = {}));
class OpenCreateConnectorWizardAction {
    constructor() {
        this.type = ConnectorActions.OPEN_CREATE_CONNECTOR_WIZARD;
    }
}
exports.OpenCreateConnectorWizardAction = OpenCreateConnectorWizardAction;
class GetConnectorDetails {
    constructor(payload) {
        this.payload = payload;
        this.type = ConnectorActions.GET_CONNECTOR_DETAILS;
    }
}
exports.GetConnectorDetails = GetConnectorDetails;
class GetConnectorDetailsSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = ConnectorActions.GET_CONNECTOR_DETAILS_SUCCESS;
    }
}
exports.GetConnectorDetailsSuccess = GetConnectorDetailsSuccess;
class GetConnectorDetailsFail {
    constructor() {
        this.type = ConnectorActions.GET_CONNECTOR_DETAILS_FAIL;
    }
}
exports.GetConnectorDetailsFail = GetConnectorDetailsFail;
class SetAsidePagination {
    constructor(payload) {
        this.payload = payload;
        this.type = ConnectorActions.SET_ASIDE_PAGINATION;
    }
}
exports.SetAsidePagination = SetAsidePagination;
class UnsetSelectedRow {
    constructor() {
        this.type = ConnectorActions.UNSET_SELECTED_ROW;
    }
}
exports.UnsetSelectedRow = UnsetSelectedRow;
class CreateConnectorAction {
    constructor(payload, isMigrationFlow) {
        this.payload = payload;
        this.isMigrationFlow = isMigrationFlow;
        this.type = ConnectorActions.CREATE_CONNECTOR;
    }
}
exports.CreateConnectorAction = CreateConnectorAction;
class UpdateConnectorAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ConnectorActions.UPDATE_CONNECTOR;
    }
}
exports.UpdateConnectorAction = UpdateConnectorAction;
class CreateConnectorActionSuccess {
    constructor(provider, payload, connector, isEditFlow, isMigrationFlow) {
        this.provider = provider;
        this.payload = payload;
        this.connector = connector;
        this.isEditFlow = isEditFlow;
        this.isMigrationFlow = isMigrationFlow;
        this.type = ConnectorActions.CREATE_CONNECTOR_SUCCESS;
    }
}
exports.CreateConnectorActionSuccess = CreateConnectorActionSuccess;
class CreateConnectorActionFail {
    constructor(payload) {
        this.payload = payload;
        this.type = ConnectorActions.CREATE_CONNECTOR_FAIL;
    }
}
exports.CreateConnectorActionFail = CreateConnectorActionFail;
class DeleteConnectorAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ConnectorActions.DELETE_CONNECTOR;
    }
}
exports.DeleteConnectorAction = DeleteConnectorAction;
class DeleteConnectorActionSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = ConnectorActions.DELETE_CONNECTOR_SUCCESS;
    }
}
exports.DeleteConnectorActionSuccess = DeleteConnectorActionSuccess;
class GetConnectorUsageAction {
    constructor(id) {
        this.id = id;
        this.type = ConnectorActions.GET_CONNECTOR_USAGE;
    }
}
exports.GetConnectorUsageAction = GetConnectorUsageAction;
class GetConnectorUsageSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ConnectorActions.GET_CONNECTOR_USAGE_SUCCESS;
    }
}
exports.GetConnectorUsageSuccessAction = GetConnectorUsageSuccessAction;
class GetConnectorUsageFailAction {
    constructor() {
        this.type = ConnectorActions.GET_CONNECTOR_USAGE_FAILURE;
    }
}
exports.GetConnectorUsageFailAction = GetConnectorUsageFailAction;
class DeleteConnectorActionFail {
    constructor(payload) {
        this.payload = payload;
        this.type = ConnectorActions.DELETE_CONNECTOR_FAIL;
    }
}
exports.DeleteConnectorActionFail = DeleteConnectorActionFail;
class LoadProductsAction {
    constructor() {
        this.type = ConnectorActions.LOAD_PRODUCTS;
    }
}
exports.LoadProductsAction = LoadProductsAction;
class LoadProductsActionSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = ConnectorActions.LOAD_PRODUCTS_SUCCESS;
    }
}
exports.LoadProductsActionSuccess = LoadProductsActionSuccess;
class LoadProductsActionFail {
    constructor() {
        this.type = ConnectorActions.LOAD_PRODUCTS_FAILURE;
    }
}
exports.LoadProductsActionFail = LoadProductsActionFail;
class LoadProvidersAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ConnectorActions.LOAD_PROVIDERS;
    }
}
exports.LoadProvidersAction = LoadProvidersAction;
class LoadProvidersActionSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = ConnectorActions.LOAD_PROVIDERS_SUCCESS;
    }
}
exports.LoadProvidersActionSuccess = LoadProvidersActionSuccess;
class LoadProvidersActionFail {
    constructor() {
        this.type = ConnectorActions.LOAD_PROVIDERS_FAILURE;
    }
}
exports.LoadProvidersActionFail = LoadProvidersActionFail;
class GetConsentConfigAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ConnectorActions.GET_CONSENT_CONFIG;
    }
}
exports.GetConsentConfigAction = GetConsentConfigAction;
class GetConsentConfigActionSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = ConnectorActions.GET_CONSENT_CONFIG_SUCCESS;
    }
}
exports.GetConsentConfigActionSuccess = GetConsentConfigActionSuccess;
class GetConsentConfigActionFail {
    constructor() {
        this.type = ConnectorActions.GET_CONSENT_CONFIG_FAILURE;
    }
}
exports.GetConsentConfigActionFail = GetConsentConfigActionFail;
class ClearConsentConfigAction {
    constructor() {
        this.type = ConnectorActions.CLEAR_CONSENT_CONFIG;
    }
}
exports.ClearConsentConfigAction = ClearConsentConfigAction;
class ProductSelectionUpdatedAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ConnectorActions.PRODUCT_SELECTION_UPDATED;
    }
}
exports.ProductSelectionUpdatedAction = ProductSelectionUpdatedAction;
class ProviderSelectionUpdatedAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ConnectorActions.PROVIDER_SELECTION_UPDATED;
    }
}
exports.ProviderSelectionUpdatedAction = ProviderSelectionUpdatedAction;
class ResetStepFormsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ConnectorActions.RESET_STEP_FORMS;
    }
}
exports.ResetStepFormsAction = ResetStepFormsAction;
class ValidateConsentAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ConnectorActions.VALIDATE_CONSENT;
    }
}
exports.ValidateConsentAction = ValidateConsentAction;
class ValidateConsentActionSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = ConnectorActions.VALIDATE_CONSENT_SUCCESS;
    }
}
exports.ValidateConsentActionSuccess = ValidateConsentActionSuccess;
class ValidateConsentActionFail {
    constructor(payload) {
        this.payload = payload;
        this.type = ConnectorActions.VALIDATE_CONSENT_FAILURE;
    }
}
exports.ValidateConsentActionFail = ValidateConsentActionFail;
class ShowCreateConnectorSuccessModalAction {
    constructor() {
        this.type = ConnectorActions.SHOW_CREATE_CONNECTOR_SUCCESS_MODAL;
    }
}
exports.ShowCreateConnectorSuccessModalAction = ShowCreateConnectorSuccessModalAction;
