<div class="mc-layout-aside-simple mc-layout-full-height mc-flex-container mc-flex-column"
  [ngClass]="extendClass">

  <div class="mc-layout-header-aside mc-flex-container">
    <div class="mc-title-item">{{ keyTitle | translate }}</div>
    <div class="mc-wrapper-close-item"
      *ngIf="showClose">
      <a class="mc-close-item"
        (click)="closeAside.emit(true)">
        <i class="far fa-times"></i>
      </a>
    </div>
  </div>

  <mc-loader-full-container [isLoading]="isLoading"
    class="mc-layout-loader-full-container-aside mc-flex-scroll-vertical">
    <div class="mc-layout-body-aside mc-flex-scroll-vertical">
      <ng-content select="mc-body-container"></ng-content>
    </div>

    <div #mcLayoutFooterAreaHtml
      class="mc-layout-footer-container"
      [hidden]="!mcLayoutFooterArea.nativeElement.children.length">
      <ng-content select="mc-footer-container"></ng-content>
    </div>
  </mc-loader-full-container>

</div>
