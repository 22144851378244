"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
(function () {
    'use strict';
    angular
        .module('services.services.monitoring.slides.ttp-impersonation-details.controller', [])
        .controller('TtpImpersonationDetailsController', [
        '$translate',
        'ttpImpersonationService',
        '$scope',
        function ($translate, ttpImpersonationService, $scope) {
            const self = this;
            const { ttpLogIndex, ttpLogsService } = $scope.$parent.$resolve;
            const identifiers = ttpImpersonationService.constants.IDENTIFIERS;
            const actions = ttpImpersonationService.constants.ACTIONS;
            self.index = ttpLogIndex;
            self.ttpLogsService = ttpLogsService;
            self.ttpLog = ttpLogsService.getLogs()[self.index];
            self.filterOptionsLabels = {};
            self.filterOptionsLabels[identifiers.SIMILAR_INTERNAL_DOMAIN] = $translate.instant('$I18N_TTP_IMPERSONATION_SIMILAR_INTERNAL_DOMAIN');
            self.filterOptionsLabels[identifiers.NEWLY_OBSERVED_DOMAIN] = $translate.instant('$I18N_TTP_IMPERSONATION_NEWLY_OBSERVED_DOMAIN');
            self.filterOptionsLabels[identifiers.INTERNAL_USER_NAME] = $translate.instant('$I18N_TTP_IMPERSONATION_INTERNAL_USER_NAME');
            self.filterOptionsLabels[identifiers.REPLY_TO_ADDRESS_MISMATCH] = $translate.instant('$I18N_TTP_IMPERSONATION_REPLY-TO_ADDRESS_MISMATCH');
            self.filterOptionsLabels[identifiers.TARGETED_THREAT_DICTIONARY] = $translate.instant('$I18N_TTP_IMPERSONATION_TARGETED_THREAT_DICTIONARY');
            self.filterOptionsLabels[identifiers.SIMILAR_MONITORED_EXTERNAL_DOMAIN] = $translate.instant('$I18N_TTP_IMPERSONATION_SIMILAR_MONITORED_EXTERNAL_DOMAIN');
            self.filterOptionsLabels[identifiers.CUSTOM_EXTERNAL_DOMAIN] = $translate.instant('$I18N_TTP_IMPERSONATION_CUSTOM_EXTERNAL_DOMAIN');
            self.filterOptionsLabels[identifiers.MIMECAST_EXTERNAL_DOMAIN] = $translate.instant('$I18N_TTP_IMPERSONATION_MIMECAST_EXTERNAL_DOMAIN');
            self.filterOptionsLabels[identifiers.ADVANCED_CUSTOM_EXTERNAL_DOMAIN] = $translate.instant('$I18N_TTP_IMPERSONATION_ADVANCED_CUSTOM_EXTERNAL_DOMAIN');
            self.filterOptionsLabels[identifiers.ADVANCED_MIMECAST_EXTERNAL_DOMAIN] = $translate.instant('$I18N_TTP_IMPERSONATION_ADVANCED_MIMECAST_EXTERNAL_DOMAIN');
            self.filterOptionsLabels[identifiers.ADVANCED_SIMILAR_INTERNAL_DOMAIN] = $translate.instant('$I18N_TTP_IMPERSONATION_ADVANCED_SIMILAR_INTERNAL_DOMAIN');
            self.filterOptionsLabels[actions.NONE] = $translate.instant('$I18N_TTP_IMPERSONATION_NONE');
            self.filterOptionsLabels[actions.HOLD] = $translate.instant('$I18N_TTP_IMPERSONATION_HOLD');
            self.filterOptionsLabels[actions.BOUNCE] = $translate.instant('$I18N_TTP_IMPERSONATION_BOUNCE');
        }
    ]);
})();
