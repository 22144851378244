<div class="row mc-detailed-list-row">
    <div class="col-xs-4 mc-detailed-list-label">
      {{ label }}
    </div>
    <div class="mc-detailed-list-column">
      <div *ngIf="!value && icon !== undefined" class="mc-detailed-list-column-upperValue">
        <i class="fa {{ icon ? 'fa-check-circle' : 'fa-times-circle' }}" *ngIf="!hideIcon"></i>
        {{
          ( '$I18N_SECURE_MESSAGING_VIEW_DETAILS.VALUES.' + (icon ? 'YES' : 'NO' ) ) | translate
        }}
      </div>
      <span *ngIf="value">{{ isEmail ? renderEmails(value) : value }}</span>
      <div *ngIf="isEmail && isOverOneLine(value)">
          <button class="btn-link" (click)="toggle()">
              {{ ('$I18N_SECURE_MESSAGING_VIEW_DETAILS.ACTIONS.' + (showMore ? 'SHOW_LESS' : 'SHOW_MORE')) | translate}}
              <i class="fa {{showMore ? 'fa-chevron-up' : 'fa-chevron-down'}}"></i>
          </button>
      </div>
    </div>
  </div>
