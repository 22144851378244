"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OpenRestoreSuccessAction = exports.OpenRestoreAction = exports.OpenExportSuccessAction = exports.OpenExportAction = exports.OpenCalendarContentSuccessAction = exports.OpenCalendarContentAction = exports.OpenContactContentSuccessAction = exports.OpenContactContentAction = exports.OpenMessageContentSuccessAction = exports.OpenMessageContentAction = exports.ClearStoreAction = exports.GoToSnapshotQueueSuccessAction = exports.GoToSnapshotQueueAction = exports.CreateFailAction = exports.CreateSuccessAction = exports.CreateAction = exports.CLEAR_STORE = exports.OPEN_RESTORE_SUCCESS = exports.OPEN_RESTORE = exports.OPEN_EXPORT_SUCCESS = exports.OPEN_EXPORT = exports.OPEN_CALENDAR_CONTENT_SUCCESS = exports.OPEN_CALENDAR_CONTENT = exports.OPEN_CONTACT_CONTENT_SUCCESS = exports.OPEN_CONTACT_CONTENT = exports.OPEN_MESSAGE_CONTENT_SUCCESS = exports.OPEN_MESSAGE_CONTENT = exports.GO_TO_SNAPSHOT_QUEUE_SUCCESS = exports.GO_TO_SNAPSHOT_QUEUE = exports.CREATE_FAIL = exports.CREATE_SUCCESS = exports.CREATE = void 0;
exports.CREATE = '[Sync Recover Exchange Snapshot] Create';
exports.CREATE_SUCCESS = '[Sync Recover Exchange Snapshot] Create Success';
exports.CREATE_FAIL = '[Sync Recover Exchange Snapshot] Create Fail';
exports.GO_TO_SNAPSHOT_QUEUE = '[Sync Recover Exchange Snapshot] Go to snapshot queue';
exports.GO_TO_SNAPSHOT_QUEUE_SUCCESS = '[Sync Recover Exchange Snapshot] Go to snapshot queue success';
// TODO: Think if these might be exchange level
exports.OPEN_MESSAGE_CONTENT = '[Sync Recover Exchange Snapshot] Open mesaage content';
exports.OPEN_MESSAGE_CONTENT_SUCCESS = '[Sync Recover Exchange Snapshot] Open mesaage content success';
exports.OPEN_CONTACT_CONTENT = '[Sync Recover Exchange Snapshot] Open contact content';
exports.OPEN_CONTACT_CONTENT_SUCCESS = '[Sync Recover Exchange Snapshot] Open contact content success';
exports.OPEN_CALENDAR_CONTENT = '[Sync Recover Exchange Snapshot] Open calendar content';
exports.OPEN_CALENDAR_CONTENT_SUCCESS = '[Sync Recover Exchange Snapshot] Open calendar content success';
exports.OPEN_EXPORT = '[Sync Recover Exchange Snapshot] Open export';
exports.OPEN_EXPORT_SUCCESS = '[Sync Recover Exchange Snapshot] Open export success';
exports.OPEN_RESTORE = '[Sync Recover Exchange Snapshot] Open restore';
exports.OPEN_RESTORE_SUCCESS = '[Sync Recover Exchange Snapshot] Open restore success';
exports.CLEAR_STORE = '[Sync Recover Exchange Snapshot] Clear Store';
/**
 * Load List Actions
 */
class CreateAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.CREATE;
    }
}
exports.CreateAction = CreateAction;
class CreateSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.CREATE_SUCCESS;
    }
}
exports.CreateSuccessAction = CreateSuccessAction;
class CreateFailAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.CREATE_FAIL;
    }
}
exports.CreateFailAction = CreateFailAction;
class GoToSnapshotQueueAction {
    constructor() {
        this.type = exports.GO_TO_SNAPSHOT_QUEUE;
    }
}
exports.GoToSnapshotQueueAction = GoToSnapshotQueueAction;
class GoToSnapshotQueueSuccessAction {
    constructor() {
        this.type = exports.GO_TO_SNAPSHOT_QUEUE_SUCCESS;
    }
}
exports.GoToSnapshotQueueSuccessAction = GoToSnapshotQueueSuccessAction;
class ClearStoreAction {
    constructor() {
        this.type = exports.CLEAR_STORE;
    }
}
exports.ClearStoreAction = ClearStoreAction;
// TODO: Think if these might be exchange level
class OpenMessageContentAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.OPEN_MESSAGE_CONTENT;
    }
}
exports.OpenMessageContentAction = OpenMessageContentAction;
class OpenMessageContentSuccessAction {
    constructor() {
        this.type = exports.OPEN_MESSAGE_CONTENT_SUCCESS;
    }
}
exports.OpenMessageContentSuccessAction = OpenMessageContentSuccessAction;
class OpenContactContentAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.OPEN_CONTACT_CONTENT;
    }
}
exports.OpenContactContentAction = OpenContactContentAction;
class OpenContactContentSuccessAction {
    constructor() {
        this.type = exports.OPEN_CONTACT_CONTENT_SUCCESS;
    }
}
exports.OpenContactContentSuccessAction = OpenContactContentSuccessAction;
class OpenCalendarContentAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.OPEN_CALENDAR_CONTENT;
    }
}
exports.OpenCalendarContentAction = OpenCalendarContentAction;
class OpenCalendarContentSuccessAction {
    constructor() {
        this.type = exports.OPEN_CALENDAR_CONTENT_SUCCESS;
    }
}
exports.OpenCalendarContentSuccessAction = OpenCalendarContentSuccessAction;
class OpenExportAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.OPEN_EXPORT;
    }
}
exports.OpenExportAction = OpenExportAction;
class OpenExportSuccessAction {
    constructor() {
        this.type = exports.OPEN_EXPORT_SUCCESS;
    }
}
exports.OpenExportSuccessAction = OpenExportSuccessAction;
class OpenRestoreAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.OPEN_RESTORE;
    }
}
exports.OpenRestoreAction = OpenRestoreAction;
class OpenRestoreSuccessAction {
    constructor() {
        this.type = exports.OPEN_RESTORE_SUCCESS;
    }
}
exports.OpenRestoreSuccessAction = OpenRestoreSuccessAction;
