<div class="mc-detailed-list">
  <mc-wizard-step-header
    class="mc-supervision-wizard-step-header"
    label="$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SUMMARY_STEP.DESCRIPTION"
  >
  </mc-wizard-step-header>
  <p>{{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SUMMARY_STEP.DETAILS' | translate }}</p>
  <div class="row mc-detailed-list-row">
    <div class="col-xs-2 mc-detailed-list-label">
      {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.DETAILS_STEP.FIELD_NAME' | translate }}
    </div>
    <div class="mc-detailed-list-column">{{ getStepDataById(1).name }}</div>
  </div>
  <div class="row mc-detailed-list-row">
    <div class="col-xs-2 mc-detailed-list-label">
      {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.DETAILS_STEP.FIELD_DESCRIPTION' | translate }}
    </div>
    <div class="mc-detailed-list-column">{{ getStepDataById(1).description }}</div>
  </div>
  <p>{{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SUMMARY_STEP.SETTINGS' | translate }}</p>
  <div class="row mc-detailed-list-row">
    <div class="col-xs-2 mc-detailed-list-label">
      {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SETTINGS_STEP.FIELD_MONITORING_GROUP' | translate }}
    </div>
    <div class="mc-detailed-list-column">{{ getStepDataById(2).searchGroup?.description }}</div>
  </div>
  <div class="row mc-detailed-list-row">
    <div class="col-xs-2 mc-detailed-list-label">
      {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SETTINGS_STEP.FIELD_MESSAGES' | translate }}
    </div>
    <div class="mc-detailed-list-column">{{ messageRoute | translate }}</div>
  </div>
  <div class="row mc-detailed-list-row">
    <div class="col-xs-2 mc-detailed-list-label">
      {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SETTINGS_STEP.FIELD_SCHEDULE' | translate }}
    </div>
    <div class="mc-detailed-list-column">{{ scheduleType | translate }}</div>
  </div>
  <div class="row mc-detailed-list-row">
    <div class="col-xs-2 mc-detailed-list-label">
      {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SETTINGS_STEP.FIELD_SAMPLING' | translate }}
    </div>
    <div class="mc-detailed-list-column">
      {{ samplingPercentage + '%' }}
    </div>
  </div>
  <p>{{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SUMMARY_STEP.LEXICON' | translate }}</p>
  <div class="row mc-detailed-list-row">
    <div class="col-xs-2 mc-detailed-list-label">
      {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.LEXICON_STEP.FIELD_NAME' | translate }}
    </div>
    <div class="mc-detailed-list-column">
      {{ getStepDataById(3).lexicon?.name || getNoLexiconText() }}
    </div>
  </div>
  <p>{{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SUMMARY_STEP.REVIEW_QUEUE' | translate }}</p>
  <div class="row mc-detailed-list-row">
    <div class="col-xs-2 mc-detailed-list-label">
      {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.QUEUE_STEP.FIELD_NAME' | translate }}
    </div>
    <div class="mc-detailed-list-column">{{ getStepDataById(4).reviewQueue?.name }}</div>
  </div>
</div>
