<mc-layout-list-table
  class="mc-exceptions-container"
  keyTitle="$I18N_SWG_EXCEPTIONS_LIST.PAGE_HEADER"
  keyDescription="$I18N_SWG_EXCEPTIONS_LIST.PAGE_DESCRIPTION">
  <mc-help-container>
    <mc-help-link url="https://community.mimecast.com/docs/DOC-3184"></mc-help-link>
  </mc-help-container>
  <mc-table
    class="mc-swg-exceptions-list"
    [isLoading]="isLoading$ | async"
    [data]="tableData$ | async"
    [columns]="columns"
    [showHighlightedRow]="(isEditable$ | async)"
    translatePrefix="$I18N_SWG_EXCEPTIONS_LIST.TABLE"
    (rowClick)="onRowClick($event)">

    <button class="mc-table-actions btn btn-primary"
            *ngIf="(isEditable$ | async)"
            (click)="addException()">
      {{ '$I18N_SWG_EXCEPTIONS_LIST.TABLE.ADD_EXCEPTION_BUTTON' | translate }}
    </button>

    <mc-filters
      (paginatorChange)="onPaginationChange($event)"
      [metadata]="metaData$ | async">

      <mc-filter-search
        (search)="onSearch($event)"
        (clear)="onSearchClear()"
        [options]="filterSearchOptions"
      >
      </mc-filter-search>
    </mc-filters>

    <mc-column key="host">
      <mc-body-cell *mcBodyCellDef="let row">
        <span *ngIf="row.host">{{ row.host }}</span>
        <span *ngIf="row.ipRange">{{ row.ipRange }}</span>
      </mc-body-cell>
    </mc-column>

    <mc-column-actions key="action" mcShowColumnConfig>
      <div *ngIf="(isEditable$ | async)">
        <mc-row-actions *mcRowActions="let row">
          <li mcRowAction="$I18N_COMMON_BTN_EDIT" (click)="editException(row)" *ngIf="(isEditable$ | async)"></li>
          <li mcRowAction="$I18N_COMMON_BTN_DELETE" (click)="deleteException(row)" *ngIf="(isEditable$| async)"></li>
        </mc-row-actions>
      </div>
    </mc-column-actions>

    <mc-empty-results keyTitle="$I18N_SWG_EXCEPTIONS_LIST.TABLE.NO_DATA">
    </mc-empty-results>

  </mc-table>
</mc-layout-list-table>

