"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RuleStatus = exports.QueueStatus = void 0;
var QueueStatus;
(function (QueueStatus) {
    QueueStatus["CREATED"] = "created";
    QueueStatus["ACTIVE"] = "active";
    QueueStatus["CLOSED"] = "closed";
    QueueStatus["ERROR"] = "error";
    QueueStatus["ABANDONED"] = "abandoned";
    QueueStatus["FULL"] = "full";
})(QueueStatus = exports.QueueStatus || (exports.QueueStatus = {}));
var RuleStatus;
(function (RuleStatus) {
    RuleStatus["NOT_RAN"] = "not_ran";
    RuleStatus["SUCCESSFUL"] = "successful";
    RuleStatus["WARNING"] = "warning";
    RuleStatus["UNKNOWN"] = "unknown";
    RuleStatus["ERROR"] = "error";
    RuleStatus["QUEUE_FULL"] = "queue_full";
})(RuleStatus = exports.RuleStatus || (exports.RuleStatus = {}));
