"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dateRange = exports.mockVerdictsByApp = exports.mockScanCountByFileType = exports.mockVerdictsByDateNormalized = exports.mockVerdictsByDate = void 0;
exports.mockVerdictsByDate = {
    '2021-06-16T17:00:00Z': {
        totalScannedFiles: 400,
        detectedScannedFiles: 250
    },
    '2021-06-16T18:00:00Z': {
        totalScannedFiles: 600,
        detectedScannedFiles: 350
    },
    '2021-06-16T21:00:00Z': {
        totalScannedFiles: 900,
        detectedScannedFiles: 600
    },
    '2021-06-17T12:00:00Z': {
        totalScannedFiles: 300,
        detectedScannedFiles: 150
    }
};
exports.mockVerdictsByDateNormalized = [
    {
        date: new Date('2021-06-16T17:00:00Z'),
        totalScannedFiles: 400,
        detectedScannedFiles: 250
    },
    {
        date: new Date('2021-06-16T18:00:00Z'),
        totalScannedFiles: 600,
        detectedScannedFiles: 350
    },
    {
        date: new Date('2021-06-16T19:00:00Z'),
        totalScannedFiles: 0,
        detectedScannedFiles: 0
    },
    {
        date: new Date('2021-06-16T20:00:00Z'),
        totalScannedFiles: 0,
        detectedScannedFiles: 0
    },
    {
        date: new Date('2021-06-16T21:00:00Z'),
        totalScannedFiles: 900,
        detectedScannedFiles: 600
    },
    {
        date: new Date('2021-06-16T22:00:00Z'),
        totalScannedFiles: 0,
        detectedScannedFiles: 0
    }
];
exports.mockScanCountByFileType = [
    {
        fileType: 'pdf',
        count: 10
    },
    {
        fileType: 'xml',
        count: 15
    }
];
exports.mockVerdictsByApp = {
    ms_teams: {
        totalScannedFiles: 300,
        detectedScannedFiles: 150
    },
    onedrive: {
        totalScannedFiles: 55,
        detectedScannedFiles: 44
    }
};
exports.dateRange = {
    start: '2021-06-16T17:00:00Z',
    end: '2021-06-16T22:00:00Z'
};
