"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
(function () {
    'use strict';
    angular.module('mc-checkbox-field.directive', [])
        .directive('mcCheckboxField', [function () {
            return {
                require: 'ngModel',
                restrict: 'EA',
                templateUrl: 'components/mc-checkbox/mc-checkbox.tpl.html',
                scope: {
                    ngModel: '=',
                    label: '@',
                    name: '@',
                    labelClasses: '@',
                    inputControlClasses: '@'
                },
                link() { }
            };
        }]);
})();
