
    <div class="row mc-detailed-list-row">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ label }}
      </div>
      <div class="mc-detailed-list-column">
        <div *ngIf="upperValue" class="mc-detailed-list-column-upperValue">{{ upperValue }}</div>
        <span *ngIf="value">{{ value }}</span>
      </div>
    </div>
  