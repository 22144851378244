<div class="col-md-12 no-padding">
  <form [formGroup]="form" mcErrorPrefix="$I18N_AWARENESS_SETUP_SAFETY_TIP">
    <div class="panel panel-default no-margin-bottom">
      <div class="panel-body no-padding-bottom">
        <mc-text-area-field label="Tip Text" errorPrefix="TEXT" formControlName="openingStatement" placeholder="$I18N_AWARENESS_SETUP_TIP_PLACEHOLDER"></mc-text-area-field>

        <mc-field label="Icon">
          <select class="form-control" formControlName="icon">
            <option *ngFor="let icon of iconsToSelect" [value]="icon.class">{{icon.text}}</option>
          </select>
        </mc-field>

        <div class="form-group pull-right">
          <button type="button" (click)="cancel.emit({index: selectedTipIndex, tip:null})" class="btn btn-default cancel-button"
                  translate="$I18N_AWARENESS_SETUP_TIP_CANCEL"></button>
          <button type="button" (click)="save.emit({ index: selectedTipIndex, tip: form.value })" [disabled]="!form.valid" class="btn btn-primary save-button"
                  translate="$I18N_AWARENESS_SETUP_TIP_ADD"></button>
        </div>
      </div>
    </div>
  </form>
</div>
