

<mc-layout-list-extra-container
    [keyTitle]="backup && backup.name"
    keyDescription="$I18N_ONEDRIVE_BACKUPS.BROWSER.DESCRIPTION">
    <mc-body-container>     
        <ng-container     
            *ngIf="((hasBackupEditPermission$ | async) && !(openingEditBackup$ | async)); else spinner">  
            <button 
                type="button" 
                data-test="backup-edit-button" 
                class="btn btn-primary" 
                (click)="editBackup()"
            >{{'$I18N_ONEDRIVE_BACKUPS.BROWSER.EDIT_LABEL' | translate}}
            </button> 
            <button 
                type="button" 
                data-test="backup-delete-button" 
                class="btn btn-default"
                (click)="deleteBackup()"
            >{{'$I18N_ONEDRIVE_BACKUPS.BROWSER.DELETE_LABEL' | translate}}</button>
            <form [formGroup]="form">        
                <mc-switch data-test="backup-status-toggle" formControlName="status" showLabel="true" onStateLabel="{{'$I18N_ONEDRIVE_BACKUPS.BROWSER.ENABLED_LABEL' | translate}}" offStateLabel="{{'$I18N_ONEDRIVE_BACKUPS.BROWSER.DISABLED_LABEL' | translate}}"></mc-switch>
            </form> 
        </ng-container>
        <mc-tab-group initialActiveTabIndex="0">
            <mc-tab name="$I18N_ONEDRIVE_BACKUPS.BROWSER.SUMMARY.TAB_TITLE">
                <div class="mc-detailed-list">
                    <div class="mc-detailed-list-row row">
                        <div class="mc-detailed-list-label col-xs-2 text-right">{{'$I18N_ONEDRIVE_BACKUPS.BROWSER.SUMMARY.NAME_LABEL'|translate}}</div>
                        <div class="mc-detailed-list-column">{{backup.name}}</div>
                    </div>
                    <div class="mc-detailed-list-row row">
                        <div class="mc-detailed-list-label col-xs-2 text-right">{{'$I18N_ONEDRIVE_BACKUPS.BROWSER.SUMMARY.GROUP_LABEL'|translate}}</div>
                        <div class="mc-detailed-list-column">{{backup.backupGroup.description}}</div>
                    </div>
                    <div class="mc-detailed-list-row row">
                        <div class="mc-detailed-list-label col-xs-2 text-right">{{'$I18N_ONEDRIVE_BACKUPS.BROWSER.SUMMARY.CONNECTOR_LABEL'|translate}}</div>
                        <div class="mc-detailed-list-column">{{backup.connector.name}}</div>
                    </div>
                    <div class="mc-detailed-list-row row">
                        <div class="mc-detailed-list-label col-xs-2 text-right">{{'$I18N_ONEDRIVE_BACKUPS.BROWSER.SUMMARY.CREATED_LABEL'|translate}}</div>
                        <div class="mc-detailed-list-column">{{backup.created | mcDate}}</div>
                    </div>
                    <div class="mc-detailed-list-row row">
                        <div class="mc-detailed-list-label col-xs-2 text-right">{{'$I18N_ONEDRIVE_BACKUPS.BROWSER.SUMMARY.CREATED_BY_LABEL'|translate}}</div>
                        <div class="mc-detailed-list-column">{{backup.createdBy}}</div>
                    </div>
                    <div class="mc-detailed-list-row row">
                        <div class="mc-detailed-list-label col-xs-2 text-right">{{'$I18N_ONEDRIVE_BACKUPS.BROWSER.SUMMARY.LAST_MODIFIED_LABEL'|translate}}</div>
                        <div class="mc-detailed-list-column">{{backup.lastModified | mcDate}}</div>
                    </div>
                    <div class="mc-detailed-list-row row">
                        <div class="mc-detailed-list-label col-xs-2 text-right">{{'$I18N_ONEDRIVE_BACKUPS.BROWSER.SUMMARY.MODIFIED_BY_LABEL'|translate}}</div>
                        <div class="mc-detailed-list-column">{{backup.modifiedBy}}</div>
                    </div>
                    <div class="mc-detailed-list-row row">
                        <div class="mc-detailed-list-label col-xs-2 text-right">{{'$I18N_ONEDRIVE_BACKUPS.BROWSER.SUMMARY.STATUS_LABEL'|translate}}</div>
                        <div class="mc-detailed-list-column">
                            <mc-onedrive-backup-status [status]="backup.enabled ? backupStatus.ENABLE : backupStatus.DISABLE">
                            </mc-onedrive-backup-status>
                        </div>
                    </div>
                    <div class="mc-detailed-list-row row">
                        <div class="mc-detailed-list-label col-xs-2 text-right">{{'$I18N_ONEDRIVE_BACKUPS.BROWSER.SUMMARY.EXCLUDED_FILES_LABEL'|translate}}</div>
                        <div class="mc-detailed-list-column">{{extensions}}</div>
                    </div>
                </div>
            </mc-tab>
        </mc-tab-group>
       
    </mc-body-container>
      
  </mc-layout-list-extra-container>
  <ng-template #spinner>
    <div class="spinner-wrapper">
      <mc-spinner></mc-spinner>
    </div>
  </ng-template>