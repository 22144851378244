"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const malware_report_list_component_1 = require("./malware-report-list.component");
angular
    .module('swgMalwareReport', [])
    .directive('mcSwgMalwareReport', static_1.downgradeComponent({
    component: malware_report_list_component_1.SwgMalwareReportComponent
}))
    .config([
    '$stateProvider',
    ($stateProvider) => {
        const breadcrumbs = [];
        $stateProvider.state('secure-malware-report-list', {
            url: '/secure-web-gateway/malware-report',
            data: {
                capabilities: 'Permission.SWG_SECURITY_REPORT_LIST_READ',
                isFixedLayout: false,
                checkProgress: false,
                tabReload: false,
                tabTitle: '$I18N_SECURITY_REPORT.TAB_TITLE',
                tabId: 'swgMalwareReportList',
                breadcrumbs
            },
            views: {
                main: {
                    template: '<mc-swg-malware-report></mc-swg-malware-report>'
                }
            }
        });
    }
]);
