<mc-collapsable-panel header="$I18N_MESSAGE_CENTER_MESSAGE_INFO_QUEUE_INFORMATION.TITLE" [isExpanded]="true">
  <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_QUEUE_INFORMATION.LABEL_QUEUED_SINCE"
    [value]="queueInfo.createTime | mcDatePlusWeekday">
  </mc-value-field>
  <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_QUEUE_INFORMATION.LABEL_PROCESS_ATTEMPTS"
    [value]="queueInfo.attempts">
  </mc-value-field>
  <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_QUEUE_INFORMATION.LABEL_NEXT_ATTEMPT"
    [value]="queueInfo.nextAttempt | mcDatePlusWeekday">
  </mc-value-field>

  <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_QUEUE_INFORMATION.LABEL_LOCKED_UNTIL"
    [value]="!!queueInfo?.lockedUntil ? (queueInfo?.lockedUntil | mcDatePlusWeekday) : ('$I18N_MESSAGE_CENTER_MESSAGE_INFO_QUEUE_INFORMATION.VALUE_ITEM_NOT_LOCKED' | translate)">
  </mc-value-field>

  <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_QUEUE_INFORMATION.LABEL_CURRENT_STATUS"
    [value]="queueInfo.reason">
  </mc-value-field>
  <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_QUEUE_INFORMATION.LABEL_INFORMATION"
    [value]="queueInfo.info">
  </mc-value-field>
</mc-collapsable-panel>