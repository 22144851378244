"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Reviewer = void 0;
class Reviewer {
    constructor(emailAddress, addressType, domain, name, alias, id, source) {
        this.emailAddress = emailAddress;
        this.addressType = addressType;
        this.domain = domain;
        this.name = name;
        this.alias = alias;
        this.id = id;
        this.source = source;
    }
}
exports.Reviewer = Reviewer;
