"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListUrlsEffects = void 0;
const effects_1 = require("@ngrx/effects");
const table_store_1 = require("@mimecast-ui/table-store");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const reducers = require("../../reducers/index");
const listActions = require("../../actions/url-list.actions");
const url_confirmation_dialog_component_1 = require("../../components/url-confirmation-dialog/url-confirmation-dialog.component");
const TABLE_ID = 'managedUrlsList';
class ListUrlsEffects {
    constructor(actions$, notificationService, store, managedUrlService, translateService, modalService, tableStore) {
        this.actions$ = actions$;
        this.notificationService = notificationService;
        this.store = store;
        this.managedUrlService = managedUrlService;
        this.translateService = translateService;
        this.modalService = modalService;
        this.tableStore = tableStore;
        this.deleteUrlAttempt$ = this.actions$.pipe(effects_1.ofType(listActions.MANAGED_URL_DELETE_ATTEMPT), operators_1.withLatestFrom(this.store.select(reducers.getManagedUrlState)), operators_1.mergeMap(([, data]) => {
            return this.modalService
                .open(url_confirmation_dialog_component_1.UrlConfirmationModalComponent, {
                data: {
                    type: 'DELETE',
                    url: data.selectedRow.domain
                }
            })
                .afterClose()
                .pipe(operators_1.map((result) => {
                return result
                    ? new listActions.ManagedUrlsDeleteAction(data.selectedRow)
                    : new listActions.ManagedUrlDeleteCancelAction();
            }));
        }));
        this.deleteUrl$ = this.actions$.pipe(effects_1.ofType(listActions.MANAGED_URL_DELETE), operators_1.withLatestFrom(this.store.select(reducers.getManagedUrlState)), operators_1.switchMap(([, data]) => {
            return this.managedUrlService.deleteManagedUrl({ id: data.selectedRow.id }).pipe(operators_1.map((payload) => {
                return payload
                    ? new listActions.ManagedUrlDeleteSucessAction()
                    : new listActions.ManagedUrlDeleteFailAction();
            }), operators_1.catchError(() => rxjs_1.of(new listActions.ManagedUrlDeleteFailAction())));
        }));
        this.deleteUrlSuccess$ = this.actions$.pipe(effects_1.ofType(listActions.MANAGED_URL_DELETE_SUCCESS), operators_1.withLatestFrom(this.tableStore.select(table_store_1.tableReducer.getCurrentFilters(TABLE_ID)), this.tableStore.select(table_store_1.tableReducer.getCurrentPagination(TABLE_ID))), operators_1.mergeMap(([, currentFilters, currentPagination]) => {
            return [
                this.notificationService.newNotification({
                    type: 'success',
                    config: {
                        msg: this.translateService.instant('$I18N_SERVICES_URL_PROTECTION_MANAGED_URL_DELETE_URL_SUCCESS_MSG')
                    }
                }),
                new table_store_1.LoadAction({
                    tableId: TABLE_ID,
                    pagination: { pageToken: '', pageSize: currentPagination.pageSize, pageNumber: 0 },
                    query: currentFilters
                })
            ];
        }));
        this.deleteUrlFail$ = this.actions$.pipe(effects_1.ofType(listActions.MANAGED_URL_DELETE_FAIL), operators_1.withLatestFrom(this.store.select(reducers.getManagedUrlState)), operators_1.switchMap(() => {
            return [
                this.notificationService.newNotification({
                    type: 'error',
                    config: {
                        msg: this.translateService.instant('$I18N_SERVICES_URL_PROTECTION_MANAGED_URL_DELETE_URL_ERROR_MSG')
                    }
                })
            ];
        }));
    }
}
__decorate([
    effects_1.Effect()
], ListUrlsEffects.prototype, "deleteUrlAttempt$", void 0);
__decorate([
    effects_1.Effect()
], ListUrlsEffects.prototype, "deleteUrl$", void 0);
__decorate([
    effects_1.Effect()
], ListUrlsEffects.prototype, "deleteUrlSuccess$", void 0);
__decorate([
    effects_1.Effect()
], ListUrlsEffects.prototype, "deleteUrlFail$", void 0);
exports.ListUrlsEffects = ListUrlsEffects;
