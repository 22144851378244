<div class="swg-dns">
  <span class="heading-l">{{ '$I18N_CERTIFICATE_AUTHORITY_HEADING' | translate }}</span>

  <div class="top-padding">
    {{ '$II8N_CERTIFICATE_AUTHORITY_INSTRUCTIONS' | translate }}<br>
  </div>

  <mc-download-button label="{{ '$I18N_CERTIFICATE_DOWNLOAD_LABEL' | translate }}"
                      [downLoadUrl]="certificateDownloadUrl$ | async">
  </mc-download-button>

  <div class="top-padding">
    {{'$II8N_CERTIFICATE_AUTHORITY_VERIFY_MESSAGE' | translate}}
  </div>

  <div class="heading-l highlight">
    {{hashValue}}
  </div>

  <div class="panel-padding-top">
    <a href="{{kbLink}}" target="_blank">{{'$I18N_CERTIFICATE_DOWNLOAD_KB_LABEL' | translate}}</a>
  </div>

</div>
