"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExchangeListImpl = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const table_store_1 = require("@mimecast-ui/table-store");
class ExchangeListImpl {
    constructor(http, $state) {
        this.http = http;
        this.$state = $state;
    }
    // TODO: On implementation of api response TableStoreDataType should be parameterized correctly
    getData(payload, pagination = table_store_1.defaultPaginationOption) {
        return this.http
            .post('/api/backup/get-mailbox-summary', Object.assign(Object.assign({}, payload), { meta: { pagination } }))
            .pipe(operators_1.map((response) => {
            return {
                hasErrors: response.hasErrors,
                data: response.all,
                failures: response.fail,
                meta: response.meta
            };
        }), operators_1.catchError((response) => {
            return rxjs_1.of({
                hasErrors: response.hasErrors,
                data: response.all,
                failures: response.fail,
                meta: response.meta
            });
        }));
    }
    filter(payload) {
        return this.getData(payload);
    }
    openItem(row) {
        return rxjs_1.of(this.$state.go('backup-mailboxdetails', {
            mailboxId: row.mailbox,
            taskId: row.backupTaskSettings.id,
            lastActive: row.lastActive
        }));
    }
}
exports.ExchangeListImpl = ExchangeListImpl;
