"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MepPolicyService = void 0;
const models_1 = require("../policies/models/models");
class MepPolicyService {
    createAPIPayLoad(policy) {
        let affectedUsers;
        if (policy.everyone) {
            affectedUsers = [
                {
                    description: policy.description,
                    from: { type: models_1.MepAffectedUsersEnum.EVERYONE },
                    to: { type: models_1.MepAffectedUsersEnum.EXTERNAL_ADDRESSES }
                }
            ];
            if (policy.rules.length === 1 &&
                policy.rules[0].condition === models_1.MepAffectedUsersEnum.EVERYONE) {
                affectedUsers[0].id = policy.rules[0].id;
            }
        }
        else {
            affectedUsers = policy.rules
                .filter(target => target.condition !== models_1.MepAffectedUsersEnum.EVERYONE)
                .map(target => {
                return this.createTarget(target, policy.description);
            });
        }
        return {
            policy: {
                name: policy.name.trim(),
                description: policy.description ? policy.description.trim() : null,
                enabled: policy.enabled
            },
            matchLevel: policy.matchLevel,
            action: policy.action,
            autoReleaseTimeoutMinutes: policy.autoReleaseTimeoutMinutes,
            rules: affectedUsers
        };
    }
    createTarget(target, desc) {
        const policy = {
            conditions: { sourceIPs: [] },
            description: desc,
            from: {},
            to: { type: models_1.MepAffectedUsersEnum.EXTERNAL_ADDRESSES }
        };
        if (target.id) {
            policy.id = target.id;
        }
        if (target.condition === models_1.MepAffectedUsersEnum.INDIVIDUAL_EMAIL_ADDRESS) {
            policy.from.type = models_1.MepAffectedUsersEnum.INDIVIDUAL_EMAIL_ADDRESS;
            policy.from.emailAddress = target.payload.emailAddress;
        }
        else if (target.condition === models_1.MepAffectedUsersEnum.PROFILE_GROUP) {
            policy.from.type = models_1.MepAffectedUsersEnum.PROFILE_GROUP;
            policy.from.groupId = target.payload.id;
        }
        return policy;
    }
    createUIModel(res) {
        res.rules = res.rules.map(this.createAffectedUserFromTarget) || [];
        res.everyone = res.rules.length === 1 && res.rules[0].condition === 'everyone';
        return res;
    }
    createAffectedUserFromTarget(policyResponse) {
        let ret = {
            condition: models_1.MepAffectedUsersEnum.EVERYONE
        };
        if (policyResponse.from.type === models_1.MepAffectedUsersEnum.INDIVIDUAL_EMAIL_ADDRESS) {
            ret = {
                condition: models_1.MepAffectedUsersEnum.INDIVIDUAL_EMAIL_ADDRESS,
                id: policyResponse.id,
                payload: {
                    emailAddress: policyResponse.from.emailAddress
                }
            };
        }
        else if (policyResponse.from.type === models_1.MepAffectedUsersEnum.PROFILE_GROUP) {
            ret = {
                condition: models_1.MepAffectedUsersEnum.PROFILE_GROUP,
                id: policyResponse.id,
                payload: {
                    description: policyResponse.from.group.description,
                    id: policyResponse.from.group.id
                }
            };
        }
        else if (policyResponse.from.type === models_1.MepAffectedUsersEnum.EVERYONE) {
            ret = {
                condition: models_1.MepAffectedUsersEnum.EVERYONE,
                id: policyResponse.id,
                payload: {
                    description: ''
                }
            };
        }
        return ret;
    }
}
exports.MepPolicyService = MepPolicyService;
