"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginAction = exports.LoginSuccessAction = exports.LoginPartnerFailAction = exports.LoginFailAction = exports.LoginPartnerAction = exports.SwitchAccountAction = exports.StoreSwitchAccountSessionAction = exports.LoginSwitchAccountAction = exports.LogoutSuccessAction = exports.LogoutAction = exports.LogoutFailAction = exports.StartSessionPingAction = exports.PingSessionAction = exports.PING_SESSION = exports.START_SESSION_PING = exports.LOGIN_SWITCH_ACCOUNT = exports.STORE_SWITCH_ACCOUNT_SESSION = exports.SWITCH_ACCOUNT = exports.LOGIN_PARTNER = exports.LOGOUT_FAIL = exports.LOGOUT_SUCCESS = exports.LOGOUT = exports.LOGIN_PARTNER_FAIL = exports.LOGIN_FAIL = exports.LOGIN_SUCCESS = exports.LOGIN = void 0;
exports.LOGIN = '[Legacy] Legacy Adcon Login';
exports.LOGIN_SUCCESS = '[Legacy] Legacy Adcon Login Load Success';
exports.LOGIN_FAIL = '[Legacy] Legacy Adcon Login Load Fail';
exports.LOGIN_PARTNER_FAIL = '[Legacy] Legacy Adcon Partner Portal Login Load Fail';
exports.LOGOUT = '[Legacy] Logout';
exports.LOGOUT_SUCCESS = '[Legacy] Logout Success';
exports.LOGOUT_FAIL = '[Legacy] Logout Fail';
exports.LOGIN_PARTNER = '[Legacy] Partner Login';
exports.SWITCH_ACCOUNT = '[Legacy] Switch Account';
exports.STORE_SWITCH_ACCOUNT_SESSION = '[Legacy] Store Switch Account Session';
exports.LOGIN_SWITCH_ACCOUNT = '[Legacy] Login Switch Account';
exports.START_SESSION_PING = '[Legacy] Start Session Ping';
exports.PING_SESSION = '[Legacy] Ping Session';
class PingSessionAction {
    constructor() {
        this.type = exports.PING_SESSION;
    }
}
exports.PingSessionAction = PingSessionAction;
class StartSessionPingAction {
    constructor() {
        this.type = exports.START_SESSION_PING;
    }
}
exports.StartSessionPingAction = StartSessionPingAction;
class LogoutFailAction {
    constructor() {
        this.type = exports.LOGOUT_FAIL;
    }
}
exports.LogoutFailAction = LogoutFailAction;
class LogoutAction {
    constructor() {
        this.type = exports.LOGOUT;
    }
}
exports.LogoutAction = LogoutAction;
class LogoutSuccessAction {
    constructor() {
        this.type = exports.LOGOUT_SUCCESS;
    }
}
exports.LogoutSuccessAction = LogoutSuccessAction;
class LoginSwitchAccountAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOGIN_SWITCH_ACCOUNT;
    }
}
exports.LoginSwitchAccountAction = LoginSwitchAccountAction;
class StoreSwitchAccountSessionAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.STORE_SWITCH_ACCOUNT_SESSION;
    }
}
exports.StoreSwitchAccountSessionAction = StoreSwitchAccountSessionAction;
class SwitchAccountAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SWITCH_ACCOUNT;
    }
}
exports.SwitchAccountAction = SwitchAccountAction;
class LoginPartnerAction {
    constructor(request, selectedPartnerClientAccount, logon) {
        this.request = request;
        this.selectedPartnerClientAccount = selectedPartnerClientAccount;
        this.logon = logon;
        this.type = exports.LOGIN_PARTNER;
    }
}
exports.LoginPartnerAction = LoginPartnerAction;
class LoginFailAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOGIN_FAIL;
    }
}
exports.LoginFailAction = LoginFailAction;
class LoginPartnerFailAction {
    constructor(request, selectedPartnerClientAccount, logon) {
        this.request = request;
        this.selectedPartnerClientAccount = selectedPartnerClientAccount;
        this.logon = logon;
        this.type = exports.LOGIN_PARTNER_FAIL;
    }
}
exports.LoginPartnerFailAction = LoginPartnerFailAction;
class LoginSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOGIN_SUCCESS;
    }
}
exports.LoginSuccessAction = LoginSuccessAction;
class LoginAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOGIN;
    }
}
exports.LoginAction = LoginAction;
