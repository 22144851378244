"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LexiconEffect = void 0;
const actions = require("../actions/lexicon.actions");
const table_store_1 = require("@mimecast-ui/table-store");
const lexiconListActions = require("app-new/archive/supervision/actions/lexicon.list.actions");
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const notification_actions_1 = require("app-new/components/notification/actions/notification.actions");
const operators_1 = require("rxjs/operators");
const lexicon_details_factory_1 = require("../factories/lexicon-details.factory");
const lexicon_service_1 = require("../services/lexicon.service");
const lexicon_service_2 = require("app-new/archive/supervision/containers/list/services/lexicon.service");
const view_lexicon_component_1 = require("../containers/asides/view-lexicon/view-lexicon.component");
class LexiconEffect {
    constructor(actions$, lexiconService, translateService, stateService, asideService, store) {
        this.actions$ = actions$;
        this.lexiconService = lexiconService;
        this.translateService = translateService;
        this.stateService = stateService;
        this.asideService = asideService;
        this.store = store;
        this.create$ = this.actions$.pipe(effects_1.ofType(actions.LexiconWizardActions.CREATE), operators_1.switchMap((action) => {
            return this.lexiconService.createLexicon(action.lexicon).pipe(operators_1.map(response => {
                if (response.hasErrors) {
                    return new actions.CreateUpdateActionFail(this.getNotificationMessage('CREATE_FAILURE'), response.fail[0]);
                }
                return new actions.CreateUpdateActionSuccess(this.getNotificationMessage('CREATE_SUCCESS'));
            }), operators_1.catchError(response => {
                return rxjs_1.of(new actions.CreateUpdateActionFail(this.getNotificationMessage('CREATE_FAILURE'), response.fail[0]));
            }));
        }));
        this.update$ = this.actions$.pipe(effects_1.ofType(actions.LexiconWizardActions.UPDATE), operators_1.switchMap((action) => {
            return this.lexiconService.updateLexicon(action.lexiconId, action.lexicon).pipe(operators_1.map(response => {
                if (response.hasErrors) {
                    return new actions.CreateUpdateActionFail(this.getNotificationMessage('UPDATE_FAILURE'), response.fail[0], true);
                }
                return new actions.CreateUpdateActionSuccess(this.getNotificationMessage('UPDATE_SUCCESS'));
            }), operators_1.catchError(response => {
                return rxjs_1.of(new actions.CreateUpdateActionFail(this.getNotificationMessage('UPDATE_FAILURE'), response.fail[0], true));
            }));
        }));
        this.validateSearchQuery$ = this.actions$.pipe(effects_1.ofType(actions.LexiconWizardActions.VALIDATE_SEARCH_QUERY), operators_1.switchMap((action) => {
            const { searchQuery } = action.payload;
            return this.lexiconService.validateSearchQuery(searchQuery).pipe(operators_1.switchMap((response) => {
                if (response.hasErrors) {
                    return [
                        new actions.ValidateSearchQueryFailureAction({
                            validationError: this.getValidationParsingError(response.fail[0])
                        })
                    ];
                }
                return [new actions.ValidateSearchQuerySuccessAction()];
            }), operators_1.catchError(error => rxjs_1.of(new actions.ValidateSearchQueryFailureAction(Object.assign({}, error.fail[0])))));
        }));
        this.createUpdateSuccess$ = this.actions$.pipe(effects_1.ofType(actions.LexiconWizardActions.CREATE_UPDATE_SUCCESS), operators_1.map((action) => {
            this.stateService.$state.go('archive-supervision-list', {
                callerWizardId: lexicon_service_1.LexiconService.WizardId
            });
            return new notification_actions_1.NotificationShowSuccessAction(action.message);
        }));
        this.createUpdateFail$ = this.actions$.pipe(effects_1.ofType(actions.LexiconWizardActions.CREATE_UPDATE_FAIL), operators_1.switchMap((action) => {
            if (!action.failure.errors) {
                return [new notification_actions_1.NotificationShowFailAction(action.message)];
            }
            return action.failure.errors.map(error => new notification_actions_1.NotificationShowFailAction(this.translateService.instant(lexicon_service_1.LexiconService.getApiErrorTranslateKey(this.prepareErrorCode(error), action.isUpdate))));
        }));
        this.get$ = this.actions$.pipe(effects_1.ofType(actions.LexiconWizardActions.GET), operators_1.map((action) => action.lexicon), operators_1.switchMap(lexicon => {
            return this.lexiconService.getLexicon(lexicon.id).pipe(operators_1.map(response => {
                if (response.hasErrors) {
                    return new actions.GetActionFail(response.fail[0]);
                }
                const lexiconDetails = lexicon_details_factory_1.LexiconDetailsFactory.BUILD_LEXICON_DETAILS(response.first);
                return new actions.GetActionSuccess(lexiconDetails);
            }), operators_1.catchError(response => {
                return rxjs_1.of(new actions.GetActionFail(response.fail[0]));
            }), operators_1.withLatestFrom(this.store.select(table_store_1.tableReducer.getTableData(lexicon_service_2.LexiconService.SUPERVISION_LEXICON_LIST))), operators_1.switchMap(([action, tableData]) => {
                return [
                    action,
                    this.getPaginationInfo(lexicon, tableData),
                    new table_store_1.SetSelectedRowAction({
                        tableId: lexicon_service_2.LexiconService.SUPERVISION_LEXICON_LIST,
                        item: lexicon
                    })
                ];
            }));
        }));
        this.getSuccess$ = this.actions$.pipe(effects_1.ofType(actions.LexiconWizardActions.GET_SUCCESS), operators_1.switchMap((action) => {
            const lexiconDetails = action.lexiconDetails;
            if (!this.viewAside) {
                this.closeViewAside();
                this.viewAside = this.asideService.open(view_lexicon_component_1.ViewLexiconComponent, {
                    size: 'md',
                    hasBackdrop: true,
                    data: lexiconDetails
                });
            }
            return rxjs_1.EMPTY;
        }));
        this.getFail$ = this.actions$.pipe(effects_1.ofType(actions.LexiconWizardActions.GET_FAIL), operators_1.map((action) => action.payload), operators_1.map((payload) => payload.errors), operators_1.switchMap((errors) => {
            if (!errors) {
                return rxjs_1.EMPTY;
            }
            return errors.map(error => new notification_actions_1.NotificationShowFailAction(this.translateService.instant(lexicon_service_1.LexiconService.getApiErrorTranslateKey(error.code))));
        }));
        this.clean$ = this.actions$.pipe(effects_1.ofType(actions.LexiconWizardActions.CLOSE_LEXICON_DETAILS), operators_1.map(() => {
            this.closeViewAside();
            return new actions.CleanLexiconDetailsAction();
        }));
    }
    getNotificationMessage(notificationkey) {
        const fullKey = `$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.NOTIFICATION.${notificationkey}`;
        return this.translateService.instant(fullKey);
    }
    getValidationParsingError(failure) {
        return failure.errors[0].message;
    }
    closeViewAside() {
        if (!!this.viewAside) {
            this.viewAside.close();
            this.viewAside = undefined;
        }
    }
    getPaginationInfo(currentLexicon, tableData) {
        const currentLexiconIndex = tableData.findIndex(value => value.id === currentLexicon.id);
        const previousLexicon = currentLexiconIndex !== undefined && currentLexiconIndex > 0
            ? tableData[currentLexiconIndex - 1]
            : undefined;
        const nextLexicon = currentLexiconIndex !== undefined && currentLexiconIndex < tableData.length
            ? tableData[currentLexiconIndex + 1]
            : undefined;
        return new lexiconListActions.SetLexiconDetailsPaginatorDataAction(previousLexicon, currentLexicon, nextLexicon, currentLexiconIndex, tableData.length);
    }
    prepareErrorCode(error) {
        if (error.field) {
            switch (error.code.toLowerCase()) {
                case 'err_validation_over_max':
                    switch (error.field.toLowerCase()) {
                        case 'description':
                        case 'name':
                        case 'searchparams.words':
                        case 'searchparams.phrases':
                            return `${error.code}_${error.field.replace(/\./g, '_')}`;
                    }
                    break;
                case 'err_validation_null':
                    switch (error.field.toLowerCase()) {
                        case 'name':
                        case 'searchparams':
                            return `${error.code}_${error.field}`;
                    }
                    break;
                case 'err_validation_blank':
                    switch (error.field.toLowerCase()) {
                        case 'description':
                            return `${error.code}_${error.field}`;
                    }
            }
        }
        return error.code;
    }
}
__decorate([
    effects_1.Effect()
], LexiconEffect.prototype, "create$", void 0);
__decorate([
    effects_1.Effect()
], LexiconEffect.prototype, "update$", void 0);
__decorate([
    effects_1.Effect()
], LexiconEffect.prototype, "validateSearchQuery$", void 0);
__decorate([
    effects_1.Effect()
], LexiconEffect.prototype, "createUpdateSuccess$", void 0);
__decorate([
    effects_1.Effect()
], LexiconEffect.prototype, "createUpdateFail$", void 0);
__decorate([
    effects_1.Effect()
], LexiconEffect.prototype, "get$", void 0);
__decorate([
    effects_1.Effect()
], LexiconEffect.prototype, "getSuccess$", void 0);
__decorate([
    effects_1.Effect()
], LexiconEffect.prototype, "getFail$", void 0);
__decorate([
    effects_1.Effect()
], LexiconEffect.prototype, "clean$", void 0);
exports.LexiconEffect = LexiconEffect;
