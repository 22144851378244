"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GoogleAnalyticsEffects = void 0;
const effects_1 = require("@ngrx/effects");
const operators_1 = require("rxjs/operators");
const actions = require("../actions");
class GoogleAnalyticsEffects {
    constructor(_ngZone, actions$, googleanalyticsService, capabilitiesService) {
        this._ngZone = _ngZone;
        this.actions$ = actions$;
        this.googleanalyticsService = googleanalyticsService;
        this.capabilitiesService = capabilitiesService;
        this.googleAnalyticsInitializeSettings$ = this.actions$.pipe(effects_1.ofType(actions.googleanalytics.INITIALIZE_SETTINGS), operators_1.map((action) => action.payload), operators_1.tap((googleAnalyticsInfo) => {
            if (!!googleAnalyticsInfo) {
                this.capabilitiesService
                    .hasCapability('Temporary.Production.GoogleAnalytics')
                    .subscribe(isActive => {
                    if (isActive === true && typeof gtag === 'function') {
                        this._ngZone.runOutsideAngular(() => {
                            gtag('config', 'UA-82462882-3', {
                                custom_map: {
                                    dimension1: 'AdCon 4 User ID',
                                    dimension2: 'AdCon 4 Account ID',
                                    dimension3: 'AdCon 4 Grid ID'
                                }
                            });
                            gtag('event', 'sending_user_account_and_grid_ID', {
                                'AdCon 4 User ID': googleAnalyticsInfo.visitor.id,
                                'AdCon 4 Account ID': googleAnalyticsInfo.account.id,
                                'AdCon 4 Grid ID': googleAnalyticsInfo.account.gridId
                            });
                        });
                    }
                });
            }
        }));
        this.googleanalyticsGetInformation$ = this.actions$.pipe(effects_1.ofType(actions.googleanalytics.GET_INFORMATION), operators_1.switchMap(() => this.googleanalyticsService
            .getGoogleAnalyticsInfo()
            .pipe(operators_1.map((info) => new actions.googleanalytics.InitializeSettingsAction(info)))));
    }
}
__decorate([
    effects_1.Effect({ dispatch: false })
], GoogleAnalyticsEffects.prototype, "googleAnalyticsInitializeSettings$", void 0);
__decorate([
    effects_1.Effect()
], GoogleAnalyticsEffects.prototype, "googleanalyticsGetInformation$", void 0);
exports.GoogleAnalyticsEffects = GoogleAnalyticsEffects;
