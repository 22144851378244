"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApCreatePolicyService = void 0;
const operators_1 = require("rxjs/operators");
class ApCreatePolicyService {
    constructor(http) {
        this.http = http;
    }
    getApAttributes() {
        const config = {
            meta: { pagination: { pageSize: 500 } }
        };
        return this.http
            .post('/api/attribute/find-attribute-types', config)
            .pipe(operators_1.map(res => res.first));
    }
    createApiPolicy(policy) {
        return this.http
            .post('/api/policy/attachmentprotect/create-policy-with-targets', policy)
            .pipe(operators_1.map(res => res.first));
    }
    updateApiPolicy(policy) {
        return this.http
            .post('/api/policy/attachmentprotect/update-policy-with-targets', policy)
            .pipe(operators_1.map(res => res.first));
    }
    updatePolicy(policy, poliyId) {
        const payload = this.createAPIPayLoad(policy, poliyId);
        return this.updateApiPolicy(payload);
    }
    deleteApPolicy(id) {
        return this.http
            .post('/api/policy/attachmentprotect/delete-policy-with-targets', { id })
            .pipe(operators_1.map(res => res.first));
    }
    createPolicy(policy) {
        const payload = this.createAPIPayLoad(policy);
        return this.createApiPolicy(payload);
    }
    getPolicyDetails(id) {
        return this.http
            .post('/api/policy/attachmentprotect/get-policy-with-targets', {
            id
        })
            .pipe(operators_1.map((response) => response.first));
    }
    createAtrributesList(res) {
        const attributeList = [];
        res.attributeTypes.forEach((attribute) => {
            attributeList.push({
                value: { id: attribute.id, name: attribute.prompt, value: attribute.promptType },
                label: attribute.prompt
            });
        });
        return attributeList;
    }
    createAPIPayLoad(policy, policyId) {
        let groups = null;
        let groupJournal = null;
        let groupOutbound = null;
        let eternal;
        const policyRulesArray = [];
        if (policy.appolicyInbound.groupsInbound) {
            groups = policy.appolicyInbound.groupsInbound.id;
        }
        if (policy.appolicyJournal.notifyGroupJournal) {
            groupJournal = policy.appolicyJournal.notifyGroupJournal.id;
        }
        if (policy.appolicyOutbound.notifyGroupOutbound) {
            groupOutbound = policy.appolicyOutbound.notifyGroupOutbound.id;
        }
        if (policy.appolicyDesc.apDuration) {
            eternal = false;
        }
        else {
            eternal = true;
        }
        const ipAddresses = policy.apPolicyAppliesToStep.ipApAddresses.map(a => a.apIpRange);
        policy.apPolicyAppliesToStep.attachmentPolicyRule.map((policies) => policyRulesArray.push({
            description: policy.appolicyDesc.description + ' Rule',
            bidirectional: policy.apPolicyAppliesToStep.apBidirectional,
            toDate: policy.appolicyDesc.endDate ? policy.appolicyDesc.endDate : undefined,
            toEternal: eternal,
            enabled: policy.appolicySummary.PolicyRuleStatus,
            fromDate: policy.appolicyDesc.startDate ? policy.appolicyDesc.startDate : undefined,
            fromPart: policies.apAddressesBasedOn,
            fromEternal: eternal,
            conditions: {
                sourceIPs: ipAddresses ? ipAddresses : undefined
            },
            override: policy.apPolicyAppliesToStep.override,
            from: {
                emailAddress: policies.apEmailFrom,
                emailDomain: policies.apDomainFrom,
                type: policies.apAppliesFrom,
                attribute: {
                    id: !!policies.apAttributeOptionFrom ? policies.apAttributeOptionFrom.id : '',
                    value: !!policies.apAttributeOptionFrom ? policies.apAttributeOptionFrom.value : '',
                    name: !!policies.apAttributeOptionFrom ? policies.apAttributeOptionFrom.name : ''
                },
                groupId: !!policies.apGroupFrom ? policies.apGroupFrom.id : undefined
            },
            to: {
                emailAddress: policies.apEmailTo,
                emailDomain: policies.apDomainTo,
                type: policies.apAppliesTo,
                attribute: {
                    id: !!policies.apAttributeOptionTo ? policies.apAttributeOptionTo.id : '',
                    value: !!policies.apAttributeOptionTo ? policies.apAttributeOptionTo.value : '',
                    name: !!policies.apAttributeOptionTo ? policies.apAttributeOptionTo.name : ''
                },
                groupId: !!policies.apGroupTo && policies.apGroupTo.id ? policies.apGroupTo.id : undefined
            }
        }));
        const payload = {
            id: policyId ? policyId : undefined,
            description: policy.appolicyDesc.description,
            policies: policyRulesArray,
            journalNotifyInternalRecipient: policy.appolicyJournal.internalRecipientJournal,
            journalNotifyGroup: groupJournal,
            journalFallbackRemediationAction: policy.appolicyJournal.userMailboxFallbackAction,
            checkJournal: policy.appolicyJournal.enableJournalCheck,
            journalNotifyInternalSender: policy.appolicyJournal.internalSenderJournal,
            journalRemediationAction: policy.appolicyJournal.userMailboxAction,
            outboundFallbackRemediationAction: policy.appolicyOutbound.senderMailboxFallbackAction,
            outboundNotifyGroup: groupOutbound,
            outboundRemediationAction: policy.appolicyOutbound.senderMailboxAction,
            checkOutbound: policy.appolicyOutbound.enableOutboundCheck,
            outboundFallbackAction: policy.appolicyOutbound.gatewayFallbackAction,
            outboundAction: policy.appolicyOutbound.gatewayAction,
            outboundNotifyInternalSender: policy.appolicyOutbound.internalSenderOutbound,
            action: policy.appolicyInbound.action,
            internalForward: policy.appolicyInbound.internalForward,
            removeActiveContent: policy.appolicyInbound.removeActiveContent,
            ignoreSignedMessages: policy.appolicyInbound.ignoreSignedMessages,
            preEmptiveFailureAction: policy.appolicyInbound.preEmptiveFailureAction,
            safeSpreadsheetFormat: policy.appolicyInbound.safeSpreadsheetFormat,
            notifyGroup: groups,
            safeDocFormat: policy.appolicyInbound.safeDocFormat,
            notifyInternalRecipient: policy.appolicyInbound.notifyInternalRecipient,
            notifyInternalSender: policy.appolicyInbound.notifyInternalSender,
            notifyExternalSender: policy.appolicyInbound.notifyExternalSender
        };
        return JSON.parse(JSON.stringify(payload));
    }
}
exports.ApCreatePolicyService = ApCreatePolicyService;
