"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isOktaEvidenceBasedControlIntegrationCreatingOrUpdating = exports.getNotificationAddresses = exports.getNotificationGroups = exports.getSearchOktaGroupsResults = exports.getSearchOktaGroupsLoading = exports.getUserGroups = exports.hasCreatedIntegration = exports.getOktaEvidenceBasedControlIntegration = exports.isVerificationInProgress = exports.isVerified = exports.reducer = exports.initialState = void 0;
const actions = require("app-new/api-applications/actions");
exports.initialState = {
    isVerified: false,
    isVerificationInProgress: false,
    createdIntegrationExist: false,
    integration: undefined,
    userGroups: [],
    searchOktaGroups: {
        loading: false,
        results: []
    },
    notificationGroups: {},
    notificationAddresses: [],
    isCreatingOrUpdating: false
};
const CREDENTIALS_VERIFIED = true;
const CREDENTIALS_NOT_VERIFIED = !CREDENTIALS_VERIFIED;
const INTEGRATION_EXIST = true;
const INTEGRATION_NOT_EXIST = !INTEGRATION_EXIST;
const IS_CREATE_OR_UPDATE_IN_PROGRESS = true;
const IS_CREATE_OR_UPDATE_IS_FINISHED = !IS_CREATE_OR_UPDATE_IN_PROGRESS;
const AUTH_FAILED = true;
const AUTH_PASSED = !AUTH_FAILED;
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.IntegrationOktaEvidenceBasedControlActionTypes
            .VerifyEvidenceBasedControlIntegrationApiKey:
            {
                return Object.assign(Object.assign({}, state), { isVerified: CREDENTIALS_NOT_VERIFIED, isVerificationInProgress: true });
            }
        case actions.IntegrationOktaEvidenceBasedControlActionTypes
            .VerifyEvidenceBasedControlIntegrationApiKeySuccess:
            {
                const { baseUrl, clientId, privateKey } = action.payload.credentials;
                return Object.assign(Object.assign({}, state), { isVerificationInProgress: false, isVerified: CREDENTIALS_VERIFIED, integration: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, state.integration), { authenticationFailed: AUTH_PASSED }), (clientId && { clientId })), (baseUrl && { baseUrl })), (privateKey && { privateKey })) });
            }
        case actions.IntegrationOktaEvidenceBasedControlActionTypes
            .VerifyEvidenceBasedControlIntegrationApiKeyFailure:
            return Object.assign(Object.assign({}, state), { isVerificationInProgress: false, isVerified: CREDENTIALS_NOT_VERIFIED });
        case actions.IntegrationOktaEvidenceBasedControlActionTypes
            .CreateOktaEvidenceBasedControlIntegration:
            return Object.assign(Object.assign({}, state), { createdIntegrationExist: INTEGRATION_NOT_EXIST, isCreatingOrUpdating: IS_CREATE_OR_UPDATE_IN_PROGRESS });
        case actions.IntegrationOktaEvidenceBasedControlActionTypes
            .CreateOktaEvidenceBasedControlIntegrationSuccess:
            return Object.assign(Object.assign({}, state), { integration: action.payload, createdIntegrationExist: INTEGRATION_EXIST, isCreatingOrUpdating: IS_CREATE_OR_UPDATE_IS_FINISHED });
        case actions.IntegrationOktaEvidenceBasedControlActionTypes
            .AddUserGroupsOktaEvidenceBasedControlIntegrationEnum:
            return Object.assign(Object.assign({}, state), { userGroups: action.payload });
        case actions.IntegrationOktaEvidenceBasedControlActionTypes
            .CancelOktaEvidenceBasedControlIntegrationWizardEnum:
            return Object.assign({}, exports.initialState);
        case actions.IntegrationOktaEvidenceBasedControlActionTypes
            .SearchForOktaUserGroupsInOktaEvidenceBasedControlIntegration:
            return Object.assign(Object.assign({}, state), { searchOktaGroups: {
                    loading: true,
                    results: []
                } });
        case actions.IntegrationOktaEvidenceBasedControlActionTypes
            .SearchForOktaUserGroupsInOktaEvidenceBasedControlIntegrationSuccess:
            return Object.assign(Object.assign({}, state), { searchOktaGroups: {
                    loading: false,
                    results: action.payload
                } });
        case actions.IntegrationOktaEvidenceBasedControlActionTypes
            .SearchForOktaUserGroupsInOktaEvidenceBasedControlIntegrationFailure:
            return Object.assign(Object.assign({}, state), { searchOktaGroups: Object.assign(Object.assign({}, state.searchOktaGroups), { loading: false }) });
        case actions.IntegrationOktaEvidenceBasedControlActionTypes
            .UpdateEventGroupsInOktaEvidenceBasedControlIntegrationEnum:
            {
                return Object.assign(Object.assign({}, state), { integration: Object.assign(Object.assign({}, state.integration), { events: action.payload }) });
            }
        case actions.IntegrationOktaEvidenceBasedControlActionTypes
            .AddGroupOktaEvidenceBasedControlIntegrationNotifications:
            {
                const { id, description } = action.payload;
                return Object.assign(Object.assign({}, state), { notificationGroups: Object.assign({}, state.notificationGroups, { [id]: description }) });
            }
        case actions.IntegrationOktaEvidenceBasedControlActionTypes
            .DeleteGroupOktaEvidenceBasedControlIntegrationNotifications:
            {
                const newDeletedNotificationGroups = Object.assign({}, state.notificationGroups);
                delete newDeletedNotificationGroups[action.payload.id];
                return Object.assign(Object.assign({}, state), { notificationGroups: newDeletedNotificationGroups });
            }
        case actions.IntegrationOktaEvidenceBasedControlActionTypes
            .VerifyAndAddEmailOktaEvidenceBasedControlIntegrationNotificationsSuccess:
            {
                return Object.assign(Object.assign({}, state), { notificationAddresses: [action.payload].concat(state.notificationAddresses) });
            }
        case actions.IntegrationOktaEvidenceBasedControlActionTypes
            .DeleteEmailOktaEvidenceBasedControlIntegrationNotifications:
            {
                return Object.assign(Object.assign({}, state), { notificationAddresses: state.notificationAddresses.filter((email) => email !== action.payload) });
            }
        case actions.IntegrationOktaEvidenceBasedControlActionTypes
            .GetOktaEvidenceBasedControlIntegrationComplete:
            {
                const { userGroups, notificationAddresses, notificationGroups } = action.payload;
                return Object.assign(Object.assign({}, state), { isVerified: CREDENTIALS_NOT_VERIFIED, createdIntegrationExist: INTEGRATION_EXIST, integration: Object.assign({}, action.payload), userGroups: getUpdatedUserGroups(userGroups), notificationAddresses,
                    notificationGroups });
            }
        case actions.IntegrationOktaEvidenceBasedControlActionTypes
            .UpdateEnabledStatusOktaEvidenceBasedControlIntegration:
            {
                return Object.assign(Object.assign({}, state), { integration: Object.assign(Object.assign({}, state.integration), { enabled: action.payload }) });
            }
        case actions.IntegrationOktaEvidenceBasedControlActionTypes
            .UpdateOktaEvidenceBasedControlIntegration:
            {
                return Object.assign(Object.assign({}, state), { isCreatingOrUpdating: IS_CREATE_OR_UPDATE_IN_PROGRESS });
            }
        case actions.IntegrationOktaEvidenceBasedControlActionTypes
            .UpdateOktaEvidenceBasedControlIntegrationSuccess:
        case actions.IntegrationOktaEvidenceBasedControlActionTypes
            .UpdateOktaEvidenceBasedControlIntegrationFailure:
            {
                return Object.assign(Object.assign({}, state), { isCreatingOrUpdating: IS_CREATE_OR_UPDATE_IS_FINISHED });
            }
        default:
            return state;
    }
}
exports.reducer = reducer;
function getUpdatedUserGroups(userGroups) {
    return Object.entries(userGroups).map(([id, name]) => ({ id, name }));
}
const isVerified = (state) => state.isVerified;
exports.isVerified = isVerified;
const isVerificationInProgress = (state) => state.isVerificationInProgress;
exports.isVerificationInProgress = isVerificationInProgress;
const getOktaEvidenceBasedControlIntegration = (state) => state.integration;
exports.getOktaEvidenceBasedControlIntegration = getOktaEvidenceBasedControlIntegration;
const hasCreatedIntegration = (state) => state.createdIntegrationExist;
exports.hasCreatedIntegration = hasCreatedIntegration;
const getUserGroups = (state) => state.userGroups;
exports.getUserGroups = getUserGroups;
const getSearchOktaGroupsLoading = (state) => state.searchOktaGroups.loading;
exports.getSearchOktaGroupsLoading = getSearchOktaGroupsLoading;
const getSearchOktaGroupsResults = (state) => state.searchOktaGroups.results;
exports.getSearchOktaGroupsResults = getSearchOktaGroupsResults;
const getNotificationGroups = (state) => state.notificationGroups;
exports.getNotificationGroups = getNotificationGroups;
const getNotificationAddresses = (state) => state.notificationAddresses;
exports.getNotificationAddresses = getNotificationAddresses;
const isOktaEvidenceBasedControlIntegrationCreatingOrUpdating = (state) => state.isCreatingOrUpdating;
exports.isOktaEvidenceBasedControlIntegrationCreatingOrUpdating = isOktaEvidenceBasedControlIntegrationCreatingOrUpdating;
