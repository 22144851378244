"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogMessageStatusDictionary = exports.LogActionDictionary = void 0;
var LogActionDictionary;
(function (LogActionDictionary) {
    LogActionDictionary["identified"] = "IDENTIFIED";
    LogActionDictionary["automatic"] = "AUTOMATIC";
    LogActionDictionary["restore"] = "RESTORE";
    LogActionDictionary["remediate"] = "REMEDIATE";
})(LogActionDictionary = exports.LogActionDictionary || (exports.LogActionDictionary = {}));
var LogMessageStatusDictionary;
(function (LogMessageStatusDictionary) {
    LogMessageStatusDictionary["active"] = "ACTIVE";
    LogMessageStatusDictionary["remediated"] = "REMEDIATED";
    LogMessageStatusDictionary["restored"] = "RESTORED";
    LogMessageStatusDictionary["restore_failure"] = "RESTORE_FAILURE";
    LogMessageStatusDictionary["remediate_failure"] = "REMEDIATE_FAILURE";
    LogMessageStatusDictionary["processing"] = "PROCESSING";
    LogMessageStatusDictionary["tagged"] = "TAGGED";
    LogMessageStatusDictionary["excluded"] = "EXCLUDED";
    LogMessageStatusDictionary["held"] = "HELD";
    LogMessageStatusDictionary["pending_delivery"] = "PENDING_DELIVERY";
    LogMessageStatusDictionary["not_found"] = "NOT_FOUND";
})(LogMessageStatusDictionary = exports.LogMessageStatusDictionary || (exports.LogMessageStatusDictionary = {}));
