"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgPolicyTtpManagedUrlsStepComponent = void 0;
const core_1 = require("@angular/core");
const operators_1 = require("rxjs/operators");
class SwgPolicyTtpManagedUrlsStepComponent {
    constructor(fb, stateService) {
        this.fb = fb;
        this.stateService = stateService;
        this.ttpManagedUrlSettings = { useManagedUrls: false };
        this.updateTtpManagedUrlSettings = new core_1.EventEmitter();
    }
    ngOnInit() {
        this.ttpSettingsForm = this.fb.group({
            useManagedUrls: this.ttpManagedUrlSettings.useManagedUrls
        });
        this.ttpFormSubscription = this.ttpSettingsForm.valueChanges
            .pipe(operators_1.debounceTime(200))
            .subscribe(() => {
            this.updateStore();
        });
    }
    getEnableTtpDesc() {
        return this.hasTTP
            ? '$I18N_SWG_CREATE_POLICY_TTP_POLICY_MANAGED_URLS_TTP_TOOLTIP'
            : '$I18N_SWG_CREATE_POLICY_TTP_POLICY_MANAGED_URLS_TOOLTIP';
    }
    updateStore() {
        this.updateTtpManagedUrlSettings.emit(this.ttpSettingsForm.value);
    }
    isValid() {
        return true;
    }
    goToMangedUrlsPage() {
        this.stateService.$state.go('legacy.urlprotect-managedurls');
    }
    ngOnDestroy() {
        this.ttpFormSubscription.unsubscribe();
    }
}
exports.SwgPolicyTtpManagedUrlsStepComponent = SwgPolicyTtpManagedUrlsStepComponent;
