<ng-container *ngIf="data.type === 'CHECK_OPTIONS'">
    <mc-modal-header headerTitle="{{'$I18N_SERVICES_CREATE_DEFINITION.ALERT_MODAL.CHECK_OPTIONS.TITLE' | translate}}">
    </mc-modal-header>
    <mc-modal-body *ngIf="(hasIEPPackage || isAAAOrFAAUser)">
        <p>{{'$I18N_SERVICES_CREATE_DEFINITION.ALERT_MODAL.CHECK_OPTIONS.DESCRIPTION' | translate}}</p>
    </mc-modal-body>
    <mc-modal-body *ngIf="!(hasIEPPackage || isAAAOrFAAUser)">
        <p>{{'$I18N_SERVICES_CREATE_DEFINITION.ALERT_MODAL.CHECK_OPTIONS.INB_DESCRIPTION' | translate}}</p>
    </mc-modal-body>
    <mc-modal-footer>
        <button type="button" class="btn btn-primary"
            (click)="onClickOk()">{{'$I18N_SERVICES_CREATE_DEFINITION.ALERT_MODAL.CHECK_OPTIONS.BUTTON' |
            translate}}</button>
    </mc-modal-footer>
</ng-container>
<ng-container *ngIf="data.type === 'INBOUND_CHECK'">
    <mc-modal-header
        headerTitle="{{'$I18N_SERVICES_CREATE_DEFINITION.ALERT_MODAL.NOTIFICATION_CHECK.TITLE' | translate}}">
    </mc-modal-header>
    <mc-modal-body>
        <p>{{'$I18N_SERVICES_CREATE_DEFINITION.ALERT_MODAL.NOTIFICATION_CHECK.INBOUND_DESCRIPTION' | translate}}</p>
    </mc-modal-body>
    <mc-modal-footer>
        <button type="button" class="btn btn-primary"
            (click)="onClickOk()">{{'$I18N_SERVICES_CREATE_DEFINITION.ALERT_MODAL.NOTIFICATION_CHECK.BUTTON' |
            translate}}</button>
    </mc-modal-footer>
</ng-container>
<ng-container *ngIf="data.type === 'OUTBOUND_CHECK'">
    <mc-modal-header
        headerTitle="{{'$I18N_SERVICES_CREATE_DEFINITION.ALERT_MODAL.NOTIFICATION_CHECK.TITLE' | translate}}">
    </mc-modal-header>
    <mc-modal-body>
        <p>{{'$I18N_SERVICES_CREATE_DEFINITION.ALERT_MODAL.NOTIFICATION_CHECK.OUTBOUND_DESCRIPTION' | translate}}
        </p>
    </mc-modal-body>
    <mc-modal-footer>
        <button type="button" class="btn btn-primary"
            (click)="onClickOk()">{{'$I18N_SERVICES_CREATE_DEFINITION.ALERT_MODAL.NOTIFICATION_CHECK.BUTTON' |
            translate}}</button>
    </mc-modal-footer>
</ng-container>
<ng-container *ngIf="data.type === 'JOURNAL_CHECK'">
    <mc-modal-header
        headerTitle="{{'$I18N_SERVICES_CREATE_DEFINITION.ALERT_MODAL.NOTIFICATION_CHECK.TITLE' | translate}}">
    </mc-modal-header>
    <mc-modal-body>
        <p>{{'$I18N_SERVICES_CREATE_DEFINITION.ALERT_MODAL.NOTIFICATION_CHECK.JOURNAL_DESCRIPTION' | translate}}</p>
    </mc-modal-body>
    <mc-modal-footer>
        <button type="button" class="btn btn-primary"
            (click)="onClickOk()">{{'$I18N_SERVICES_CREATE_DEFINITION.ALERT_MODAL.NOTIFICATION_CHECK.BUTTON' |
            translate}}</button>
    </mc-modal-footer>
</ng-container>
<ng-container *ngIf="data.type === 'ADMIN_REVIEW_GROUP'">
    <mc-modal-header
        headerTitle="{{'$I18N_SERVICES_CREATE_DEFINITION.ALERT_MODAL.NOTIFICATION_CHECK.TITLE' | translate}}">
    </mc-modal-header>
    <mc-modal-body>
        <p>{{'$I18N_SERVICES_CREATE_DEFINITION.ALERT_MODAL.NOTIFICATION_CHECK.ADMIN_DESCRIPTION' | translate}}</p>
    </mc-modal-body>
    <mc-modal-footer>
        <button type="button" class="btn btn-primary"
            (click)="onClickOk()">{{'$I18N_SERVICES_CREATE_DEFINITION.ALERT_MODAL.NOTIFICATION_CHECK.BUTTON' |
            translate}}</button>
    </mc-modal-footer>
</ng-container>
<ng-container *ngIf="data.type === 'SERVER_CONNECTION_CHECK'">
    <mc-modal-header
        headerTitle="{{'$I18N_SERVICES_CREATE_DEFINITION.ALERT_MODAL.NOTIFICATION_CHECK.TITLE' | translate}}">
    </mc-modal-header>
    <mc-modal-body>
        <p>{{'$I18N_SERVICES_CREATE_DEFINITION.ALERT_MODAL.NOTIFICATION_CHECK.SERVER_CONNECTION' | translate}}
            <a [attr.href]="url" target="_blank">
                {{'$I18N_SERVICES_DEFINITION.KNOWLEDGE_BASE' |
                translate}}
            </a>
        </p>
    </mc-modal-body>
    <mc-modal-footer>
        <button type="button" class="btn btn-primary"
            (click)="onClickOk()">{{'$I18N_SERVICES_CREATE_DEFINITION.ALERT_MODAL.NOTIFICATION_CHECK.BUTTON' |
            translate}}</button>
    </mc-modal-footer>
</ng-container>
<ng-container *ngIf="data.type === 'NON_SUPPORTED_OPTION_CHECK'">
    <mc-modal-header
        headerTitle="{{'$I18N_SERVICES_CREATE_DEFINITION.ALERT_MODAL.NOTIFICATION_CHECK.TITLE' | translate}}">
    </mc-modal-header>
    <mc-modal-body>
        <p>{{'$I18N_SERVICES_CREATE_DEFINITION.ALERT_MODAL.NOTIFICATION_CHECK.NON_SUPPORTED_OPTION' | translate}}</p>
    </mc-modal-body>
    <mc-modal-footer>
        <button type="button" class="btn btn-primary"
            (click)="onClickOk()">{{'$I18N_SERVICES_CREATE_DEFINITION.ALERT_MODAL.NOTIFICATION_CHECK.BUTTON' |
            translate}}</button>
    </mc-modal-footer>
</ng-container>