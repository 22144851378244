"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiGatewaySaveWizardComponent = void 0;
const core_1 = require("@angular/core");
class ApiGatewaySaveWizardComponent {
    constructor(translateService) {
        this.translateService = translateService;
        this.onApplicationDetailsUpdated = new core_1.EventEmitter();
        this.onApplicationSettingsUpdated = new core_1.EventEmitter();
        this.onApplicationStatusUpdated = new core_1.EventEmitter();
        this.onApplicationSaved = new core_1.EventEmitter();
        this.onCancel = new core_1.EventEmitter();
        this.hasNextStep = true;
        this.hasPreviousStep = false;
        this.isLastStep = false;
        this.changed = false;
        this.DETAILS_STEP = 1;
        this.SETTINGS_STEP = 2;
        this.SUMMARY_STEP = 3;
    }
    ngOnInit() { }
    ngAfterViewInit() {
        if (this.isCreating && !!this.wizard) {
            this.wizard.hasNextStep$.subscribe(value => (this.hasNextStep = value));
            this.wizard.hasPreviousStep$.subscribe(value => (this.hasPreviousStep = value));
            this.wizard.isLastStep$.subscribe(value => (this.isLastStep = value));
        }
    }
    updateDetails(details) {
        this.changed = true;
        this.onApplicationDetailsUpdated.emit(details);
    }
    updateSettings(settings) {
        this.changed = true;
        this.onApplicationSettingsUpdated.emit(settings);
    }
    updateStatus(status) {
        this.changed = true;
        this.onApplicationStatusUpdated.emit(status);
    }
    save() {
        if (this.isValid() && this.changed) {
            this.onApplicationSaved.emit(this.application);
        }
    }
    cancel() {
        this.onCancel.emit();
    }
    nextStep() {
        if (!!this.wizard) {
            this.wizard.nextStep();
        }
    }
    changeStep() {
        // you have to listen to this event or the underlying wizard doesn't work :(
    }
    gotoStep(step) {
        if (!!this.wizard) {
            this.wizard.showStep(step);
        }
    }
    gotoPreviousStep() {
        if (!!this.wizard) {
            this.wizard.previousStep();
        }
    }
    gotoNextStep() {
        if (!!this.wizard) {
            this.wizard.nextStep();
        }
    }
    get isEditing() {
        return this.mode === 'EDIT';
    }
    get isCreating() {
        return this.mode === 'CREATE';
    }
    get pageTitle() {
        if (this.isEditing) {
            return (this.translateService.instant('$I18N_API_GATEWAY_SAVE_WIZARD.EDIT.TITLE') +
                this.applicationDetails.applicationName);
        }
        else {
            return this.translateService.instant('$I18N_API_GATEWAY_SAVE_WIZARD.CREATE.TITLE');
        }
    }
    isStepValid() {
        if (!this.wizard) {
            return false;
        }
        else {
            switch (this.wizard.currentStep) {
                case this.DETAILS_STEP:
                    return this.applicationDetailsStep.isValid();
                case this.SETTINGS_STEP:
                    return this.applicationSettingsStep.isValid();
                default:
                    return true;
            }
        }
    }
    isValid() {
        if (!!this.applicationDetailsStep && !!this.applicationSettingsStep) {
            return this.applicationDetailsStep.isValid() && this.applicationSettingsStep.isValid();
        }
        else {
            return false;
        }
    }
}
exports.ApiGatewaySaveWizardComponent = ApiGatewaySaveWizardComponent;
