"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PolicyDetailsEffects = void 0;
const effects_1 = require("@ngrx/effects");
const operators_1 = require("rxjs/operators");
const rxjs_1 = require("rxjs");
const actions = require("../actions/policy-details.actions");
const policiesActions = require("../actions/policies.actions");
const policy_details_aside_component_1 = require("../components/policy-details-aside/policy-details-aside.component");
class PolicyDetailsEffects {
    constructor(actions$, asideService, policiesApiService, notificationService, translateService, store) {
        this.actions$ = actions$;
        this.asideService = asideService;
        this.policiesApiService = policiesApiService;
        this.notificationService = notificationService;
        this.translateService = translateService;
        this.store = store;
        this.openPolicyDetailsSidebar$ = this.actions$.pipe(effects_1.ofType(actions.OPEN_POLICY_DETAILS_SIDEBAR), operators_1.tap(() => {
            this.sidepanelInstance = this.asideService.open(policy_details_aside_component_1.PolicyDetailsAsideComponent, {
                position: 'right',
                size: 'lg',
                hasBackdrop: true
            });
            this.sidepanelInstance
                .beforeClose()
                .pipe(operators_1.take(1))
                .subscribe(() => {
                this.store.dispatch(new policiesActions.IdentityClosePolicyAction());
            });
            this.sidepanelInstance
                .afterClose()
                .pipe(operators_1.take(1))
                .subscribe(() => {
                this.sidepanelInstance = undefined;
            });
        }));
        this.loadPolicyDetails$ = this.actions$.pipe(effects_1.ofType(actions.LOAD_POLICY_DETAILS), operators_1.switchMap((action) => {
            return this.policiesApiService.getPolicy(action.payload.id).pipe(operators_1.map((response) => {
                return new actions.IdentityLoadPolicyDetailsSuccessAction(response);
            }), operators_1.catchError(() => {
                this.notificationService.error({
                    msg: this.translateService.instant('$I18N_IDENTITY_GENERIC_ERROR')
                });
                return rxjs_1.of(new actions.IdentityLoadPolicyDetailsFailAction());
            }));
        }));
    }
}
__decorate([
    effects_1.Effect({ dispatch: false })
], PolicyDetailsEffects.prototype, "openPolicyDetailsSidebar$", void 0);
__decorate([
    effects_1.Effect()
], PolicyDetailsEffects.prototype, "loadPolicyDetails$", void 0);
exports.PolicyDetailsEffects = PolicyDetailsEffects;
