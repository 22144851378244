"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationFeedComponent = void 0;
class NotificationFeedComponent {
    constructor(translateService) {
        this.translateService = translateService;
    }
    ngOnInit() {
        this.selectionOptions = null;
        this.selectionSelected = null;
    }
    ngOnChanges(changes) {
        const change = changes['notifications'];
        if (change && !change.isFirstChange()) {
            this.messages = this.rearrangeMessage(this.notifications);
            this.setupSelection(this.messages);
            this.hasData = !!this.messages && this.messages.length ? true : false;
        }
    }
    rearrangeMessage(messages) {
        if (!!messages && messages.length) {
            const snIndex = messages.findIndex(message => message.noticeType === 'SERVICE_NOTIFICATION');
            if (snIndex !== -1) {
                const snMessage = messages.splice(snIndex, 1);
                messages.splice(0, 0, snMessage[0]);
            }
            const pnIndex = messages.findIndex(message => message.noticeType === 'PRODUCT_NEWS');
            if (pnIndex !== -1) {
                const pnMessage = messages.splice(pnIndex, 1);
                messages.splice(1, 0, pnMessage[0]);
            }
        }
        return messages;
    }
    setupSelection(messages) {
        const selection = [];
        selection.push({
            key: this.translateService.instant('$I18N_NOTIFICATION_FEED_SERVICE_TYPE_LABEL'),
            label: this.translateService.instant('$I18N_NOTIFICATION_FEED_TITLE_SERVICE'),
            fn: () => { }
        });
        const index = messages.findIndex(message => message.noticeType === 'PRODUCT_NEWS');
        if (index !== -1) {
            selection.unshift({
                key: this.translateService.instant('$I18N_NOTIFICATION_FEED_PRODUCT_TYPE_LABEL'),
                label: this.translateService.instant('$I18N_NOTIFICATION_FEED_TITLE_PRODUCT'),
                fn: () => { }
            });
            selection.unshift({
                key: '',
                label: this.translateService.instant('$I18N_NOTIFICATION_FEED_SELECTION_TITLE'),
                fn: () => { }
            });
        }
        this.selectionSelected = selection[0];
        this.selectionOptions = selection;
    }
}
exports.NotificationFeedComponent = NotificationFeedComponent;
