"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgLocationDeleteConfirmationComponent = void 0;
const swg_location_crud_actions_1 = require("app-new/swg/locations/actions/swg-location-crud.actions");
const reducers = require("app-new/swg/locations/reducers");
const swg_location_sidebar_actions_1 = require("app-new/swg/locations/actions/swg-location-sidebar.actions");
class SwgLocationDeleteConfirmationComponent {
    constructor(store) {
        this.store = store;
    }
    ngOnInit() {
        this.locationItem$ = this.store.select(reducers.getLocationItem);
        this.isApiProcessing$ = this.store.select(reducers.getIsApiProcessing);
    }
    deleteItem(id) {
        this.store.dispatch(new swg_location_crud_actions_1.SwgLocationDeleteAction(id));
    }
    cancel() {
        this.store.dispatch(new swg_location_sidebar_actions_1.SwgLocationsDeleteSidebarCloseAction());
    }
}
exports.SwgLocationDeleteConfirmationComponent = SwgLocationDeleteConfirmationComponent;
