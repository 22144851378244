"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiIntegrationCreateWizardComponent = void 0;
const core_1 = require("@angular/core");
class ApiIntegrationCreateWizardComponent {
    constructor() {
        this.techPartnersLink = 'https://www.mimecast.com/tech-connect/tech-partners/';
        this.configuredProviders = [];
        this.onProviderSelected = new core_1.EventEmitter();
        this.onGotoNextStep = new core_1.EventEmitter();
        this.onCancel = new core_1.EventEmitter();
    }
    providerState(provider) {
        if (provider === 'wildfire' && this.configuredProviders.includes('wildfire')) {
            return 'disabled';
        }
        if (provider === 'crowdstrike' && this.configuredProviders.includes('crowdstrike')) {
            return 'disabled';
        }
        if (provider === 'okta_evidence_based_control' &&
            this.configuredProviders.includes('okta_evidence_based_control')) {
            return 'disabled';
        }
        if (this.selectedProvider === provider) {
            return 'selected';
        }
        else {
            return 'unselected';
        }
    }
    selectProvider(provider) {
        if (this.selectedProvider !== provider) {
            this.onProviderSelected.emit(provider);
        }
        else {
            this.onProviderSelected.emit('unknown');
        }
    }
    canGotoNext() {
        return !!this.selectedProvider && this.selectedProvider.toString() !== 'unknown';
    }
    gotoNextStep() {
        if (!!this.selectedProvider && this.selectedProvider.toString() !== 'unknown') {
            this.onGotoNextStep.emit(this.selectedProvider);
        }
    }
    cancel() {
        this.onCancel.emit();
    }
}
exports.ApiIntegrationCreateWizardComponent = ApiIntegrationCreateWizardComponent;
