"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDisplayableThreatDetails = exports.getMessageDetails = exports.selectedRowsSearchTable = exports.isRemediateMessagesLoadingError = exports.isRemediateMessagesLoading = exports.remediateMessages = exports.getSearchByAttachmentSha = exports.getSearchTabIndex = exports.searchTabPermission = exports.getSearchFormPayload = exports.getSearchFilterMetadata = exports.searchLoadingError = exports.searchInProgress = exports.isSearchTableLoading = exports.searchMessagesCount = exports.searchMessages = exports.getLogsLoadingError = exports.isLogsTableLoading = exports.getLogs = exports.getIncidentStatsLoadingError = exports.areStatsLoading = exports.getIncidentStats = exports.getIncidentsLoadingError = exports.getIncidentsLoading = exports.getIncidentsPreview = exports.isGetGroupDataLoading = exports.getGroup = exports.isCloudGroupReady = exports.isLDAPGroupReady = exports.isGetGroupsLoadingError = exports.hasInitialLDAPData = exports.hasInitialCloudData = exports.isGetGroupsLDAPDataLoaded = exports.isGetGroupsCloudDataLoaded = exports.getGroups = exports.isFindGroupsLoadingError = exports.isFindGroupsLDAPDataLoaded = exports.isFindGroupsCloudDataLoaded = exports.findGroups = exports.getSettingsExcludeGroup = exports.getSettingsNotifyGroup = exports.getSettingsFormGroups = exports.hasDeviceSettingsInDB = exports.hasGenericError = exports.isUpdatingSettings = exports.areSettingsLoaded = exports.isRemediationEnabled = exports.getDeviceSettings = exports.getSettings = exports.reducers = void 0;
exports.ɵ13 = exports.ɵ12 = exports.ɵ11 = exports.ɵ10 = exports.ɵ9 = exports.ɵ8 = exports.ɵ7 = exports.ɵ6 = exports.ɵ5 = exports.ɵ4 = exports.ɵ3 = exports.ɵ2 = exports.ɵ1 = exports.ɵ0 = exports.getExportDataError = exports.isExportDataLoading = exports.getExportToken = exports.getExportData = exports.getExportConfig = exports.getSelectedRow = exports.getAsidePagination = exports.incidentFailureSummary = exports.isIncidentSummaryLoaded = exports.incidentSummaryLoadingError = exports.incidentSummary = exports.hasGenericErrorOnMessageLoad = exports.isLoadingMessageWithImages = exports.isLoadingMessageDetails = void 0;
const store_1 = require("@ngrx/store");
const exportReducer = require("./export/export.reducer");
const findGroupsReducer = require("app-new/archive/shared/groups/reducers/find-groups.reducer");
const getGroupReducer = require("./groups/get-group.reducer");
const getGroupsReducer = require("./groups/get-groups.reducer");
const incidentStatsReducer = require("./incidents-stats/incidents-stats.reducer");
const incidentSummaryReducer = require("./incidents/incident-summary.reducer");
const incidentsReducer = require("./incidents/incidents.reducer");
const logsReducer = require("./logs/logs.reducer");
const messageDetailsReducer = require("./message-details/message-details.reducer");
const paginationReducer = require("./pagination/pagination.reducer");
const remediateMessagesReducer = require("./remediate-messages/remediate-messages.reducer");
const searchReducer = require("./search/search.reducer");
const settingsFormReducer = require("./settings/settings-form.reducer");
const settingsReducer = require("./settings/settings.reducer");
exports.reducers = {
    settings: settingsReducer.reducer,
    settingsForm: settingsFormReducer.reducer,
    findGroups: findGroupsReducer.reducer,
    getGroups: getGroupsReducer.reducer,
    getGroup: getGroupReducer.reducer,
    incidents: incidentsReducer.reducer,
    incidentStats: incidentStatsReducer.reducer,
    incidentSummary: incidentSummaryReducer.reducer,
    logs: logsReducer.reducer,
    search: searchReducer.reducer,
    remediateMessages: remediateMessagesReducer.reducer,
    messageDetails: messageDetailsReducer.reducer,
    asidePagination: paginationReducer.reducer,
    export: exportReducer.reducer
};
const getRemediationState = store_1.createFeatureSelector('remediation');
const ɵ0 = (state) => state.settings;
exports.ɵ0 = ɵ0;
const getRemediationSettingsState = store_1.createSelector(getRemediationState, ɵ0);
exports.getSettings = store_1.createSelector(getRemediationSettingsState, settingsReducer.getSettings);
exports.getDeviceSettings = store_1.createSelector(getRemediationSettingsState, settingsReducer.getDeviceSettings);
exports.isRemediationEnabled = store_1.createSelector(getRemediationSettingsState, settingsReducer.isRemediationEnabled);
exports.areSettingsLoaded = store_1.createSelector(getRemediationSettingsState, settingsReducer.isLoaded);
exports.isUpdatingSettings = store_1.createSelector(getRemediationSettingsState, settingsReducer.isUpdating);
exports.hasGenericError = store_1.createSelector(getRemediationSettingsState, settingsReducer.hasGenericError);
exports.hasDeviceSettingsInDB = store_1.createSelector(getRemediationSettingsState, settingsReducer.hasDeviceSettingsInDB);
const ɵ1 = (state) => state.settingsForm;
exports.ɵ1 = ɵ1;
const getRemediationSettingsFormState = store_1.createSelector(getRemediationState, ɵ1);
exports.getSettingsFormGroups = store_1.createSelector(getRemediationSettingsFormState, settingsFormReducer.getGroups);
exports.getSettingsNotifyGroup = store_1.createSelector(getRemediationSettingsFormState, settingsFormReducer.getNotifyGroup);
exports.getSettingsExcludeGroup = store_1.createSelector(getRemediationSettingsFormState, settingsFormReducer.getExcludeGroup);
const ɵ2 = (state) => state.findGroups;
exports.ɵ2 = ɵ2;
const getRemediationFindGroupsState = store_1.createSelector(getRemediationState, ɵ2);
exports.findGroups = store_1.createSelector(getRemediationFindGroupsState, findGroupsReducer.findGroups);
exports.isFindGroupsCloudDataLoaded = store_1.createSelector(getRemediationFindGroupsState, findGroupsReducer.isCloudDataLoaded);
exports.isFindGroupsLDAPDataLoaded = store_1.createSelector(getRemediationFindGroupsState, findGroupsReducer.isLDAPDataLoaded);
exports.isFindGroupsLoadingError = store_1.createSelector(getRemediationFindGroupsState, findGroupsReducer.hasGenericError);
const ɵ3 = (state) => state.getGroups;
exports.ɵ3 = ɵ3;
const getRemediationGetGroupsState = store_1.createSelector(getRemediationState, ɵ3);
exports.getGroups = store_1.createSelector(getRemediationGetGroupsState, getGroupsReducer.getGroups);
exports.isGetGroupsCloudDataLoaded = store_1.createSelector(getRemediationGetGroupsState, getGroupsReducer.isCloudDataLoaded);
exports.isGetGroupsLDAPDataLoaded = store_1.createSelector(getRemediationGetGroupsState, getGroupsReducer.isLDAPDataLoaded);
exports.hasInitialCloudData = store_1.createSelector(getRemediationGetGroupsState, getGroupsReducer.hasCloudData);
exports.hasInitialLDAPData = store_1.createSelector(getRemediationGetGroupsState, getGroupsReducer.hasLDAPData);
exports.isGetGroupsLoadingError = store_1.createSelector(getRemediationGetGroupsState, getGroupsReducer.hasGenericError);
exports.isLDAPGroupReady = store_1.createSelector(getRemediationGetGroupsState, getGroupsReducer.isLDAPReady);
exports.isCloudGroupReady = store_1.createSelector(getRemediationGetGroupsState, getGroupsReducer.isCloudReady);
const ɵ4 = (state) => state.getGroup;
exports.ɵ4 = ɵ4;
const getRemediationGetGroupState = store_1.createSelector(getRemediationState, ɵ4);
exports.getGroup = store_1.createSelector(getRemediationGetGroupState, getGroupReducer.getGroup);
exports.isGetGroupDataLoading = store_1.createSelector(getRemediationGetGroupState, getGroupReducer.isDataLoading);
const ɵ5 = (state) => state.incidents;
exports.ɵ5 = ɵ5;
const getIncidentsState = store_1.createSelector(getRemediationState, ɵ5);
exports.getIncidentsPreview = store_1.createSelector(getIncidentsState, incidentsReducer.getIncidentsPreview);
exports.getIncidentsLoading = store_1.createSelector(getIncidentsState, incidentsReducer.isTableLoading);
exports.getIncidentsLoadingError = store_1.createSelector(getIncidentsState, incidentsReducer.hasGenericError);
const ɵ6 = (state) => state.incidentStats;
exports.ɵ6 = ɵ6;
const getIncidentStatsState = store_1.createSelector(getRemediationState, ɵ6);
exports.getIncidentStats = store_1.createSelector(getIncidentStatsState, incidentStatsReducer.getIncidentStats);
exports.areStatsLoading = store_1.createSelector(getIncidentStatsState, incidentStatsReducer.areStatsLoading);
exports.getIncidentStatsLoadingError = store_1.createSelector(getIncidentStatsState, incidentStatsReducer.hasGenericError);
const ɵ7 = (state) => state.logs;
exports.ɵ7 = ɵ7;
const getLogsState = store_1.createSelector(getRemediationState, ɵ7);
exports.getLogs = store_1.createSelector(getLogsState, logsReducer.getLogs);
exports.isLogsTableLoading = store_1.createSelector(getLogsState, logsReducer.isTableLoading);
exports.getLogsLoadingError = store_1.createSelector(getLogsState, logsReducer.hasGenericError);
const ɵ8 = (state) => state.search;
exports.ɵ8 = ɵ8;
const getSearchState = store_1.createSelector(getRemediationState, ɵ8);
exports.searchMessages = store_1.createSelector(getSearchState, searchReducer.searchMessages);
exports.searchMessagesCount = store_1.createSelector(getSearchState, searchReducer.getTotalMessagesCount);
exports.isSearchTableLoading = store_1.createSelector(getSearchState, searchReducer.isTableLoading);
exports.searchInProgress = store_1.createSelector(getSearchState, searchReducer.searchInProgress);
exports.searchLoadingError = store_1.createSelector(getSearchState, searchReducer.hasGenericError);
exports.getSearchFilterMetadata = store_1.createSelector(getSearchState, searchReducer.getFiltersMetadata);
exports.getSearchFormPayload = store_1.createSelector(getSearchState, searchReducer.getSearchFormPayload);
exports.searchTabPermission = store_1.createSelector(getSearchState, searchReducer.searchTabPermission);
exports.getSearchTabIndex = store_1.createSelector(getSearchState, searchReducer.getSearchTabIndex);
exports.getSearchByAttachmentSha = store_1.createSelector(getSearchState, searchReducer.getSearchByAttachmentSha);
const ɵ9 = (state) => state.remediateMessages;
exports.ɵ9 = ɵ9;
const getRemediateMessagesState = store_1.createSelector(getRemediationState, ɵ9);
exports.remediateMessages = store_1.createSelector(getRemediateMessagesState, remediateMessagesReducer.remediateMessages);
exports.isRemediateMessagesLoading = store_1.createSelector(getRemediateMessagesState, remediateMessagesReducer.isLoading);
exports.isRemediateMessagesLoadingError = store_1.createSelector(getRemediateMessagesState, remediateMessagesReducer.hasGenericError);
exports.selectedRowsSearchTable = store_1.createSelector(getRemediateMessagesState, remediateMessagesReducer.selectedRowsSearchTable);
const ɵ10 = (state) => state.messageDetails;
exports.ɵ10 = ɵ10;
const getMessageDetailsState = store_1.createSelector(getRemediationState, ɵ10);
exports.getMessageDetails = store_1.createSelector(getMessageDetailsState, messageDetailsReducer.getMessageDetails);
exports.getDisplayableThreatDetails = store_1.createSelector(getMessageDetailsState, messageDetailsReducer.getDisplayableThreatDetails);
exports.isLoadingMessageDetails = store_1.createSelector(getMessageDetailsState, messageDetailsReducer.isLoadingMessageDetails);
exports.isLoadingMessageWithImages = store_1.createSelector(getMessageDetailsState, messageDetailsReducer.isLoadingMessageWithImages);
exports.hasGenericErrorOnMessageLoad = store_1.createSelector(getMessageDetailsState, messageDetailsReducer.hasGenericError);
const ɵ11 = (state) => state.incidentSummary;
exports.ɵ11 = ɵ11;
const getIncidentSummaryState = store_1.createSelector(getRemediationState, ɵ11);
exports.incidentSummary = store_1.createSelector(getIncidentSummaryState, incidentSummaryReducer.incidentSummary);
exports.incidentSummaryLoadingError = store_1.createSelector(getIncidentSummaryState, incidentSummaryReducer.hasGenericError);
exports.isIncidentSummaryLoaded = store_1.createSelector(getIncidentSummaryState, incidentSummaryReducer.isLoaded || incidentSummaryReducer.isFailureSummaryLoaded);
exports.incidentFailureSummary = store_1.createSelector(getIncidentSummaryState, incidentSummaryReducer.incidentFailureSummary);
const ɵ12 = (state) => state.asidePagination;
exports.ɵ12 = ɵ12;
const asidePaginationState = store_1.createSelector(getRemediationState, ɵ12);
exports.getAsidePagination = store_1.createSelector(asidePaginationState, paginationReducer.getPagination);
exports.getSelectedRow = store_1.createSelector(asidePaginationState, paginationReducer.selectedRow);
const ɵ13 = (state) => state.export;
exports.ɵ13 = ɵ13;
const exportState = store_1.createSelector(getRemediationState, ɵ13);
exports.getExportConfig = store_1.createSelector(exportState, exportReducer.getExportConfig);
exports.getExportData = store_1.createSelector(exportState, exportReducer.getExportData);
exports.getExportToken = store_1.createSelector(exportState, exportReducer.getExportToken);
exports.isExportDataLoading = store_1.createSelector(exportState, exportReducer.isLoading);
exports.getExportDataError = store_1.createSelector(exportState, exportReducer.hasError);
