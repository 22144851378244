"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.columnList = exports.searchFilters = exports.filterSearchOptions = void 0;
exports.filterSearchOptions = [
    {
        label: '$I18N_REMEDIATION_LOGS_TAB.ALL',
        value: 'all'
    },
    {
        label: '$I18N_REMEDIATION_LOGS_TAB.COLUMNS.INCIDENTCODE',
        value: 'code'
    },
    {
        label: '$I18N_REMEDIATION_LOGS_TAB.COLUMNS.TO',
        value: 'to'
    },
    {
        label: '$I18N_REMEDIATION_LOGS_TAB.COLUMNS.FROM',
        value: 'from'
    }
];
exports.searchFilters = [
    { name: 'action', filters: ['identified', 'restore', 'remediate'] },
    {
        name: 'status',
        filters: [
            'active',
            'restored',
            'remediated',
            'restore_failure',
            'remediate_failure',
            'tagged',
            'excluded',
            'held',
            'pending_delivery',
            'not_found'
        ]
    }
];
exports.columnList = [
    'incidentCode',
    'from',
    'to',
    'messageId',
    'action',
    'actionTime',
    'status',
    'column-actions'
];
