"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportMessageModalComponent = void 0;
const forms_1 = require("@angular/forms");
class ReportMessageModalComponent {
    constructor(fb, overlayRef, actionType) {
        this.fb = fb;
        this.overlayRef = overlayRef;
        this.actionType = actionType;
    }
    ngOnInit() {
        this.form = this.fb.group({
            reportMessageComment: ['', [forms_1.Validators.minLength(5), forms_1.Validators.maxLength(256)]]
        });
        setTimeout(() => this.reportMessageInput.nativeElement.focus(), 0);
    }
    onSubmit() {
        if (this.form.valid) {
            this.overlayRef.close({ comment: this.form.value.reportMessageComment, submitted: true });
        }
    }
    close() {
        this.overlayRef.close();
    }
}
exports.ReportMessageModalComponent = ReportMessageModalComponent;
