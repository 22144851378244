"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ2 = exports.ɵ1 = exports.ɵ0 = exports.ContentTypeFactory = void 0;
const message_1 = require("app-new/sync-recover/exchange/containers/snapshot/detail/models/content-type/message");
const calendar_1 = require("app-new/sync-recover/exchange/containers/snapshot/detail/models/content-type/calendar");
const contact_1 = require("app-new/sync-recover/exchange/containers/snapshot/detail/models/content-type/contact");
const ɵ0 = (isRootNode) => new message_1.Message(isRootNode), ɵ1 = () => new calendar_1.Calendar(), ɵ2 = () => new contact_1.Contact();
exports.ɵ0 = ɵ0;
exports.ɵ1 = ɵ1;
exports.ɵ2 = ɵ2;
exports.ContentTypeFactory = {
    MESSAGE: ɵ0,
    CALENDAR: ɵ1,
    CONTACT: ɵ2
};
