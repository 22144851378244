"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ2 = exports.ɵ1 = exports.ɵ0 = exports.getPurgeConfirmReason = exports.getPurgeMessagesCount = exports.getPurgeFormRequest = exports.purgeMessagesReducers = exports.getDetailPageTotal = exports.getMessageDeliveryTotal = exports.getMessageDeliveryMetadata = exports.getMessageDeliveryRequestPage = exports.getMessageDeliveryPagination = exports.getMessageDeliverySelectedRow = exports.getMessageDeliverySearch = exports.getMessageDeliveryTotalCount = exports.getMessageDeliveryItems = exports.isDeliveryMessagesLoading = exports.messageDeliveryReducers = exports.getBulkDetailPageTotal = exports.getBulkDeliveryTotal = exports.getBulkDeliveryMetadata = exports.getBulkDeliveryRequestPage = exports.getBulkDeliveryPagination = exports.getBulkDeliverySelectedRow = exports.getBulkDeliverySearch = exports.getBulkDeliveryTotalCount = exports.getBulkDeliveryItems = exports.isBulkDeliveryLoading = exports.bulkDeliveryReducers = void 0;
const store_1 = require("@ngrx/store");
const fromMessageDelivery = require("./message-delivery.reducer");
const fromBulkDelivery = require("./bulk-delivery.reducer");
const fromPurgeMessages = require("./purge-messages.reducer");
exports.bulkDeliveryReducers = {
    bulkDelivery: fromBulkDelivery.reducer
};
const bulkDeliveryState = store_1.createFeatureSelector('bulk-delivery');
const ɵ0 = (state) => state.bulkDelivery;
exports.ɵ0 = ɵ0;
const getBulkDeliveryState = store_1.createSelector(bulkDeliveryState, ɵ0);
exports.isBulkDeliveryLoading = store_1.createSelector(getBulkDeliveryState, fromBulkDelivery.isLoading);
exports.getBulkDeliveryItems = store_1.createSelector(getBulkDeliveryState, fromBulkDelivery.getItems);
exports.getBulkDeliveryTotalCount = store_1.createSelector(getBulkDeliveryState, fromBulkDelivery.getTotalCount);
exports.getBulkDeliverySearch = store_1.createSelector(getBulkDeliveryState, fromBulkDelivery.getSearchRequest);
exports.getBulkDeliverySelectedRow = store_1.createSelector(getBulkDeliveryState, fromBulkDelivery.getSelectedRow);
exports.getBulkDeliveryPagination = store_1.createSelector(getBulkDeliveryState, fromBulkDelivery.getPagination);
exports.getBulkDeliveryRequestPage = store_1.createSelector(getBulkDeliveryState, fromBulkDelivery.getRequestPage);
exports.getBulkDeliveryMetadata = store_1.createSelector(getBulkDeliveryState, fromBulkDelivery.getMetadata);
exports.getBulkDeliveryTotal = store_1.createSelector(getBulkDeliveryState, fromBulkDelivery.getBulkDetailPageTotal);
exports.getBulkDetailPageTotal = store_1.createSelector(getBulkDeliveryState, fromBulkDelivery.getBulkDetailPageTotal);
exports.messageDeliveryReducers = {
    messageDelivery: fromMessageDelivery.reducer
};
const messageDeliveryState = store_1.createFeatureSelector('message-delivery');
const ɵ1 = (state) => state.messageDelivery;
exports.ɵ1 = ɵ1;
const getMessageDeliveryState = store_1.createSelector(messageDeliveryState, ɵ1);
exports.isDeliveryMessagesLoading = store_1.createSelector(getMessageDeliveryState, fromMessageDelivery.isLoading);
exports.getMessageDeliveryItems = store_1.createSelector(getMessageDeliveryState, fromMessageDelivery.getItems);
exports.getMessageDeliveryTotalCount = store_1.createSelector(getMessageDeliveryState, fromMessageDelivery.getTotalCount);
exports.getMessageDeliverySearch = store_1.createSelector(getMessageDeliveryState, fromMessageDelivery.getSearchRequest);
exports.getMessageDeliverySelectedRow = store_1.createSelector(getMessageDeliveryState, fromMessageDelivery.getSelectedRow);
exports.getMessageDeliveryPagination = store_1.createSelector(getMessageDeliveryState, fromMessageDelivery.getPagination);
exports.getMessageDeliveryRequestPage = store_1.createSelector(getMessageDeliveryState, fromMessageDelivery.getRequestPage);
exports.getMessageDeliveryMetadata = store_1.createSelector(getMessageDeliveryState, fromMessageDelivery.getMetadata);
exports.getMessageDeliveryTotal = store_1.createSelector(getMessageDeliveryState, fromMessageDelivery.getDetailPageTotal);
exports.getDetailPageTotal = store_1.createSelector(getMessageDeliveryState, fromMessageDelivery.getDetailPageTotal);
exports.purgeMessagesReducers = {
    purgeMessages: fromPurgeMessages.reducer
};
const purgeMessagesState = store_1.createFeatureSelector('purge-messages');
const ɵ2 = (state) => state.purgeMessages;
exports.ɵ2 = ɵ2;
const getPurgeMessagesState = store_1.createSelector(purgeMessagesState, ɵ2);
exports.getPurgeFormRequest = store_1.createSelector(getPurgeMessagesState, fromPurgeMessages.getFormRequest);
exports.getPurgeMessagesCount = store_1.createSelector(getPurgeMessagesState, fromPurgeMessages.getSearchResponseCount);
exports.getPurgeConfirmReason = store_1.createSelector(getPurgeMessagesState, fromPurgeMessages.getPurgeConfirmReason);
