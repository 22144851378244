"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const awareness_module_1 = require("./awareness.module");
angular
    .module('awareness', [])
    .controller('AwarenessController', [
    '$stateParams',
    '$scope',
    function ($stateParams, $scope) {
        $scope.stateParams = $stateParams;
    }
])
    .directive('mcTemplateAwarenessSetup', static_1.downgradeComponent({
    component: awareness_module_1.TemplateSetupPageComponent
}))
    .directive('mcTemplateAwarenessList', static_1.downgradeComponent({
    component: awareness_module_1.AwarenessListPageComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider
            .state('awareness-setup', {
            url: '/awareness/setup',
            data: {
                isFixedLayout: false,
                checkProgress: false,
                tabTitle: 'Custom User Awareness Page Sets',
                breadcrumbs: ['Services', 'Custom User Awareness Page Sets']
            },
            views: {
                main: {
                    template: '<mc-template-awareness-setup [tab]="tab"></mc-template-awareness-setup>',
                    controller: 'AwarenessController'
                }
            }
        })
            .state('awareness-setup-edit', {
            url: '/awareness/setup/{id}',
            data: {
                isFixedLayout: false,
                checkProgress: false,
                tabTitle: 'Custom User Awareness Page Sets',
                breadcrumbs: ['Services', 'Custom User Awareness Page Sets']
            },
            views: {
                main: {
                    template: '<mc-template-awareness-setup [template-id]="stateParams.id" [tab]="tab"></mc-template-awareness-setup>',
                    controller: 'AwarenessController'
                }
            }
        })
            .state('awareness-list', {
            url: '/awareness/list',
            data: {
                isFixedLayout: false,
                checkProgress: false,
                tabReload: true,
                tabTitle: 'User Awareness Page Sets',
                breadcrumbs: ['Services', 'User Awareness Page Sets']
            },
            views: {
                main: {
                    template: '<mc-template-awareness-list></mc-template-awareness-list>'
                }
            }
        });
    }
]);
