"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SidePanelEffects = void 0;
const effects_1 = require("@ngrx/effects");
const components_1 = require("@mimecast-ui/components");
const operators_1 = require("rxjs/operators");
const policy_aside_component_1 = require("../../containers/aside/policy-aside.component");
const listActions = require("../../actions/policies-list.actions");
const rxjs_1 = require("rxjs");
const notification_actions_1 = require("app-new/components/notification/actions/notification.actions");
const table_store_1 = require("@mimecast-ui/table-store");
const reducer = require("../../reducers/index");
const url_protection_policies_list_effects_1 = require("../url-protection-policies-list.effects");
class SidePanelEffects {
    constructor(actions$, asideService, policyDetailsService, translateService, store) {
        this.actions$ = actions$;
        this.asideService = asideService;
        this.policyDetailsService = policyDetailsService;
        this.translateService = translateService;
        this.store = store;
        this.openSidePanel$ = this.actions$.pipe(effects_1.ofType(listActions.OPEN_POLICY_DETAILS_SIDE_PANEL), operators_1.switchMap(() => {
            this.sidePanelInstance = this.asideService.open(policy_aside_component_1.PolicyAsideComponent, this.sidePanelConfig);
            return this.sidePanelInstance.afterClose().pipe(operators_1.first());
        }), operators_1.tap(() => {
            this.store.dispatch(new listActions.AfterClosePolicyDetailsSidePanelAction());
        }));
        this.afterCloseSidePanel$ = this.actions$.pipe(effects_1.ofType(listActions.AFTER_CLOSE_POLICY_DETAILS_SIDE_PANEL), operators_1.withLatestFrom(this.store.select(table_store_1.tableReducer.getCurrentFilters(url_protection_policies_list_effects_1.TABLE_ID)), this.store.select(table_store_1.tableReducer.getCurrentPagination(url_protection_policies_list_effects_1.TABLE_ID)), this.store.select(reducer.getPolicyStatusChangeAction)), operators_1.tap(([, currentFilters, currentPagination, status]) => {
            if (status) {
                this.store.dispatch(new listActions.PolicyStatusChangeAction(false));
                this.store.dispatch(new table_store_1.LoadAction({
                    tableId: url_protection_policies_list_effects_1.TABLE_ID,
                    pagination: currentPagination,
                    query: currentFilters
                }));
            }
        }));
        this.getPolicyDetails$ = this.actions$.pipe(effects_1.ofType(listActions.GET_POLICY_DETAILS), operators_1.switchMap((action) => {
            return this.policyDetailsService.getPolicyDetails(action.payload).pipe(operators_1.map((response) => {
                return new listActions.GetPolicyDetailsSuccessAction(response.first);
            }), operators_1.catchError(() => {
                return rxjs_1.of(this.failedToLoadPolicyDetailsNotification);
            }));
        }));
        this.closeSidePanel$ = this.actions$.pipe(effects_1.ofType(listActions.CLOSE_POLICY_DETAILS_SIDE_PANEL), operators_1.tap(() => {
            if (this.sidePanelInstance) {
                this.sidePanelInstance.close();
            }
        }));
        this.failedToLoadPolicyDetailsNotification = new notification_actions_1.NotificationShowAction({
            type: 'error',
            config: {
                msg: this.translateService.instant('$I18N_SERVICES_URL_PROTECTION_POLICIES_SIDE_PANEL.NOTIFICATIONS.FAIL_DETAILS_LOAD')
            }
        });
    }
    get sidePanelConfig() {
        const sidePanelConfig = new components_1.AsideConfig();
        sidePanelConfig.position = 'right';
        sidePanelConfig.size = 'custom';
        sidePanelConfig.hasBackdrop = true;
        sidePanelConfig.minWidth = 620;
        sidePanelConfig.resizable = true;
        return sidePanelConfig;
    }
}
__decorate([
    effects_1.Effect({ dispatch: false })
], SidePanelEffects.prototype, "openSidePanel$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], SidePanelEffects.prototype, "afterCloseSidePanel$", void 0);
__decorate([
    effects_1.Effect()
], SidePanelEffects.prototype, "getPolicyDetails$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], SidePanelEffects.prototype, "closeSidePanel$", void 0);
exports.SidePanelEffects = SidePanelEffects;
