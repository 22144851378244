"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgActivityReportSidebarComponent = void 0;
class SwgActivityReportSidebarComponent {
    constructor(translateService) {
        this.translateService = translateService;
        this.multipleUsersString = this.translateService.instant('$18N_SWG_REPORTING.MULTIPLE_USERS');
    }
    showMultipleUsersInUsersField(row) {
        return !row.username && row.discoveryMethod.includes(this.multipleUsersString);
    }
}
exports.SwgActivityReportSidebarComponent = SwgActivityReportSidebarComponent;
