"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.API_FAILURE_CODE = exports.CLIENT_ID = exports.PERMISSIONS = exports.MAILBOX = exports.CUSTOMER_ID = exports.STATE = exports.LEARN_MORE_LINK = exports.CONTACT_SUPPORT_LINK = exports.GMAIL_SCOPE = exports.GOOGLE_DOMAIN_DELEGATION_LINK = exports.GOOGLE_ADMIN_LINK = exports.PROVIDER_GWS = void 0;
exports.PROVIDER_GWS = 'GWS';
exports.GOOGLE_ADMIN_LINK = 'https://admin.google.com/ac/accountsettings/profile';
exports.GOOGLE_DOMAIN_DELEGATION_LINK = 'https://admin.google.com/ac/owl/domainwidedelegation';
exports.GMAIL_SCOPE = 'https://mail.google.com';
exports.CONTACT_SUPPORT_LINK = window.location.origin.concat('/apps#/sso/support');
exports.LEARN_MORE_LINK = 'https://community.mimecast.com/s/article/Managing-Server-Connections-75041997';
exports.STATE = 'state';
exports.CUSTOMER_ID = 'customerId';
exports.MAILBOX = 'mailbox';
exports.PERMISSIONS = 'permissions';
exports.CLIENT_ID = 'client_id';
exports.API_FAILURE_CODE = 'api_failure';
