"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.searchFilters = exports.sortableColumns = exports.columns = void 0;
exports.columns = ['username', 'type', 'status'];
exports.sortableColumns = ['user', 'type', 'status'];
exports.searchFilters = [
    {
        name: 'filterBy',
        filters: ['unprotected', 'protected', 'disabled']
    }
];
