"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GwsInstructionStepComponent = void 0;
const connector_wizard_service_1 = require("../../../../../../services/connector-wizard.service");
const model_1 = require("../../../../../../model");
const step_base_component_1 = require("../../../../../wizards/step.base.component");
const gws_provider_static_values_1 = require("../gws-provider-static-values");
class GwsInstructionStepComponent extends step_base_component_1.StepBaseDirective {
    constructor(store, fb) {
        super(store, fb, model_1.ConnectorStep.GWS_INSTRUCTIONS, connector_wizard_service_1.ConnectorWizardService.WizardId);
        this.store = store;
        this.fb = fb;
        this.googleAdminPageLink = gws_provider_static_values_1.GOOGLE_ADMIN_LINK;
        this.googleDomainDelegationPageLink = gws_provider_static_values_1.GOOGLE_DOMAIN_DELEGATION_LINK;
        this.learnMoreLink = gws_provider_static_values_1.LEARN_MORE_LINK;
        this.form = this.fb.group({});
    }
    ngOnInit() {
        super.ngOnInit();
    }
}
exports.GwsInstructionStepComponent = GwsInstructionStepComponent;
