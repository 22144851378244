"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LegacyPageComponent = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const core = require("../../core");
const legacy_adcon_interfaces_1 = require("../../core/services/legacy-adcon/legacy-adcon.interfaces");
const actions = require("../actions");
/**
 * Warning the logic in this class althougt very simple is tight to very complicated code
 * in the adcon 3.5 page.
 * The main goal of this class is to make adcon3.5 embemded in an iframe so it looks like
 * is a normal adcon4 page, besides the iframe we also  have to coordinate the history of the
 * iframe with the browser history so it looks like one history and not two independent ones.
 * Also adcon 3.5 is very old app server side rendered app done in a very old way, for example
 * any link or submit in adcon 3.5 is done via post so not long ago adcon did not support at all
 * the back button.
 * To make history work every time an adcon3.5 page is loaded a fake page is push in the history
 * so we can have a hook that notify us when someone hits the back button and allow
 * us to call a special function in adcon3.5 that calls a logic that gets the
 * last page from a in memory list.
 * Most of the logic to do that is all in adcon 3.5, the main thing the adcon 4 logic do is listen
 * to post messages sent from adcon3.5 sent when a new page is loaded or when the back button is press
 * so we can keep a count of how many links inside the user has navigated, remember although adcon 3.5
 * can now use the back button , is not really going back in history is going foward because we block
 * the back button and calls a function that renders the previous page doing a post.
 * The history count tell us how deep they have navigated so when they are navigating back and reach
 * the first adcon 3.5 page, we do a history.go(-historyCount) which jumps all the fake history we created
 * and makes the original page before the first page of adcon3.5 work, this is the most decent way we
 * manage to make it work.
 */
class LegacyPageComponent {
    constructor(store, legacyService, stateService, tabService) {
        this.store = store;
        this.legacyService = legacyService;
        this.stateService = stateService;
        this.tabService = tabService;
    }
    ngOnInit() {
        this.legacySession$ = this.store.select(core.getLegacySession).pipe(core.isDefined(), operators_1.first());
        const tab = this.tabService.getSelectedTab();
        tab.onRefresh = () => {
            this.iframe.postMessage('reload');
        };
        tab.onSelect = () => {
            this.store.dispatch(new actions.ResetCountAction(tab.id));
            this.iframe.postMessage('start-page');
        };
        tab.onClose = () => {
            this.iframe.postMessage('close');
            if (!tab.selected) {
                return;
            }
            // wait for adcon3.5 to send a close message
            return this.legacyService.messages$
                .pipe(legacy_adcon_interfaces_1.ofName('closetab'), operators_1.first(), operators_1.map(() => 'previous'))
                .toPromise();
        };
        this.store.dispatch(new actions.ResetCountAction(tab.id));
        this.store.dispatch(new actions.LoadTabDetailsAction(tab.id));
    }
    ngAfterViewInit() {
        this.legacySession$
            .pipe(operators_1.first(), operators_1.tap(session => {
            const url = this.legacyService.adconUrl + '/mimecast/admin';
            const data = Object.assign(Object.assign({}, this.stateService.$state.params), session);
            this.iframe
                .formSubmit(url, data, 'adcon3.5-new-page', true)
                .pipe(operators_1.first(), operators_1.catchError(() => {
                this.store.dispatch(new actions.ShowLegacyAdconFailModalAction());
                return rxjs_1.EMPTY;
            }))
                .subscribe();
        }))
            .subscribe();
    }
}
exports.LegacyPageComponent = LegacyPageComponent;
