"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSyncRunning = exports.reducer = exports.initialState = void 0;
const actions = require("../actions");
exports.initialState = {
    syncRunning: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.GET_SYNC_STATUS_SUCCESS:
            return Object.assign(Object.assign({}, state), { syncRunning: action.response.syncRunning });
        case actions.GET_SYNC_STATUS_FAIL:
            return exports.initialState;
        default:
            return state;
    }
}
exports.reducer = reducer;
const isSyncRunning = (state) => state.syncRunning;
exports.isSyncRunning = isSyncRunning;
