"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./archive-task-detail.controller");
require("./archive-task-detail.less");
require("./archive-task-detail.tpl.html");
(function () {
    'use strict';
    angular
        .module('services.services.backup.archive-task-detail', [
        'services.services.backup.archive-task-detail.controller'
    ])
        .config([
        '$stateProvider',
        function ($stateProvider) {
            $stateProvider.state('archive-task-detail', {
                url: '/sync-recover/exchange/task/view/:taskId',
                data: {
                    breadcrumbs: [
                        '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_SERVICES',
                        '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ASIDE_MENU_TITLE',
                        '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ARCHIVE_TASK_MENU_LABEL',
                        '$I18N_BACKUP_RESTORE_BREADCRUMBS_TASK_MAILBOXES'
                    ],
                    tabId: 'sync-and-recover-tab',
                    tabTitle: '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ASIDE_MENU_TITLE',
                    asideTitle: '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ASIDE_MENU_TITLE',
                    tabHide: false,
                    tabReload: false
                },
                views: {
                    main: {
                        templateUrl: 'services/services/backup/archive-task/archive-task-detail/archive-task-detail.tpl.html'
                    },
                    aside: {
                        templateUrl: 'services/services/backup/aside/aside.tpl.html'
                    }
                }
            });
        }
    ]);
})();
