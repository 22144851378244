"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPaginationData = exports.getPaginationLoading = exports.isLoading = exports.reducer = exports.initialState = void 0;
const paginationHeldReasonAction = require("../actions/pagination-held-reason.action");
exports.initialState = {
    results: [],
    isLoading: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case paginationHeldReasonAction.PAGINATION_HELD_REASON: {
            return Object.assign(Object.assign({}, state), { error: undefined, isLoading: true });
        }
        // @ts-ignore
        case paginationHeldReasonAction.PAGINATION_HELD_REASON_SUCCESS: {
            return Object.assign(Object.assign({}, state), { 
                // @ts-ignore
                results: action.payload.results, isLoading: false });
        }
        // @ts-ignore
        case paginationHeldReasonAction.PAGINATION_HELD_REASON_FAIL: {
            // @ts-ignore
            return Object.assign(Object.assign({}, state), { isLoading: false, error: action.payload });
        }
        default:
            return state;
    }
}
exports.reducer = reducer;
const isLoading = (state) => state.isLoading;
exports.isLoading = isLoading;
const getPaginationLoading = (state) => state.isLoading;
exports.getPaginationLoading = getPaginationLoading;
const getPaginationData = (state) => {
    if (state.results.length >= 1) {
        const pagination = {
            total: state.results.length
        };
        return pagination;
    }
};
exports.getPaginationData = getPaginationData;
