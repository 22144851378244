"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTotalMessagesCount = exports.getSearchByAttachmentSha = exports.getSearchTabIndex = exports.getSearchFormPayload = exports.hasGenericError = exports.searchInProgress = exports.searchTabPermission = exports.isTableLoading = exports.getFiltersMetadata = exports.searchMessages = exports.reducer = exports.initialState = void 0;
const searchAction = require("../../actions/search/search.action");
const date_ranges_1 = require("../../components/search-messages-widget/date-range-config/date-ranges");
exports.initialState = {
    messages: [],
    searchFormPayload: {
        fileHash: null,
        from: null,
        to: null,
        start: null,
        end: null,
        subject: null,
        dateRange: Object.assign({}, date_ranges_1.dateRangePickerConfig.dateRanges[1]),
        messageId: null,
        url: null
    },
    paginatorMetadata: {
        pageToken: undefined,
        pageSize: 50,
        pageNumber: 0
    },
    searchInProgress: false,
    searchTabPermission: false,
    isLoading: false,
    genericError: false,
    totalMessagesCount: 0,
    searchTabIndex: 0,
    searchByAttachmentSha: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case searchAction.REMEDIATION_SEARCH_RUN:
            return Object.assign(Object.assign({}, state), { searchFormPayload: Object.assign(Object.assign({}, state.searchFormPayload), { from: action.payload.from ? action.payload.from : null, to: action.payload.to ? action.payload.to : null, dateRange: action.payload.dateRange
                        ? Object.assign({}, action.payload.dateRange) : Object.assign({}, date_ranges_1.dateRangePickerConfig.dateRanges[1]), start: action.payload.start ? action.payload.start : null, end: action.payload.end ? action.payload.end : null, fileHash: action.payload.fileHash ? action.payload.fileHash : null, messageId: action.payload.messageId ? action.payload.messageId : null, subject: action.payload.subject ? action.payload.subject : null, url: action.payload.url ? action.payload.url : null }), genericError: false, searchInProgress: true, isLoading: true, searchTabIndex: action.searchTabIndex, searchTabPermission: action.hasSearchWithoutHashPermission, searchByAttachmentSha: action.searchByAttachmentSha });
        case searchAction.REMEDIATION_SEARCH_SUCCESS:
            return Object.assign(Object.assign({}, state), { messages: action.payload, paginatorMetadata: Object.assign(Object.assign({}, state.paginatorMetadata), { pageNumber: 0, pageToken: undefined }), genericError: false, searchInProgress: false, isLoading: false, totalMessagesCount: action.payload.length });
        case searchAction.REMEDIATION_SEARCH_FAILURE:
            return Object.assign(Object.assign({}, state), { genericError: true, searchInProgress: false, isLoading: false });
        case searchAction.REMEDIATE_CLEAR_SEARCH:
            return Object.assign(Object.assign({}, state), { isLoading: true });
        case searchAction.REMEDIATE_REFRESH_SEARCH:
            return Object.assign(Object.assign({}, state), { isLoading: false });
        case searchAction.REMEDIATION_SEARCH_PAGINATION:
            return Object.assign(Object.assign({}, state), { searchInProgress: false, isLoading: true, genericError: false, paginatorMetadata: Object.assign(Object.assign({}, state.paginatorMetadata), action.payload) });
        case searchAction.REMEDIATION_SEARCH_PAGINATION_SUCCESS:
            return Object.assign(Object.assign({}, state), { isLoading: false, searchInProgress: false, genericError: false });
        case searchAction.REMEDIATION_SEARCH_RESET:
            return Object.assign({}, exports.initialState);
        case searchAction.REMEDIATION_SEARCH_UPDATE_FORM_VALUE:
            return Object.assign(Object.assign({}, state), { searchFormPayload: Object.assign({}, action.formValue) });
        default:
            return state;
    }
}
exports.reducer = reducer;
const searchMessages = (state) => {
    const firstElementIndex = state.paginatorMetadata.pageNumber * state.paginatorMetadata.pageSize;
    const lastElementIndex = firstElementIndex + state.paginatorMetadata.pageSize;
    return state.messages.slice(firstElementIndex, lastElementIndex);
};
exports.searchMessages = searchMessages;
const getFiltersMetadata = (state) => {
    const numberOfPages = Math.ceil(state.messages.length / state.paginatorMetadata.pageSize);
    const currentPageNumber = state.paginatorMetadata.pageNumber;
    const isLastPage = currentPageNumber + 1 >= numberOfPages;
    return {
        pagination: {
            previous: currentPageNumber > 0 ? 'previous' : undefined,
            next: !isLastPage ? 'next' : undefined,
            pageSize: exports.searchMessages(state).length,
            totalRows: state.messages.length
        }
    };
};
exports.getFiltersMetadata = getFiltersMetadata;
const isTableLoading = (state) => state.isLoading;
exports.isTableLoading = isTableLoading;
const searchTabPermission = (state) => state.searchTabPermission;
exports.searchTabPermission = searchTabPermission;
const searchInProgress = (state) => state.searchInProgress;
exports.searchInProgress = searchInProgress;
const hasGenericError = (state) => state.genericError;
exports.hasGenericError = hasGenericError;
const getSearchFormPayload = (state) => state.searchFormPayload;
exports.getSearchFormPayload = getSearchFormPayload;
const getSearchTabIndex = (state) => state.searchTabIndex;
exports.getSearchTabIndex = getSearchTabIndex;
const getSearchByAttachmentSha = (state) => state.searchByAttachmentSha;
exports.getSearchByAttachmentSha = getSearchByAttachmentSha;
const getTotalMessagesCount = (state) => state.totalMessagesCount;
exports.getTotalMessagesCount = getTotalMessagesCount;
