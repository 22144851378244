"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
(function () {
    'use strict';
    /* ======================================================== *\

     = layout / area simple / directive
     = jcarradinha

     \* ======================================================== */
    document.createElement('mc-layout-area-simple');
    angular.module('layout.area-simple.directive', [])
        .directive('mcLayoutAreaSimple', [function () {
            return {
                restrict: 'E',
                templateUrl: 'components/layout/area-simple/area-simple.tpl.html',
                scope: {
                    mainContainerClass: '@mainContainerClass'
                },
                transclude: {
                    mainContainer: 'mainContainer'
                }
            };
        }]);
}());
