<div *ngIf="policyDetails">
  <div class="mc-detailed-list">
    <mc-simple-detail
      label="{{ '$I18N_CUSTOM_DOMAIN_BLOCK_VIEW_POLICY.DETAILS.NAME' | translate }}"
      value="{{ policyDetails?.name }}"
    ></mc-simple-detail>

    <mc-simple-detail
      label="{{ '$I18N_CUSTOM_DOMAIN_BLOCK_VIEW_POLICY.DETAILS.DESCRIPTION' | translate }}"
      value="{{ policyDetails.description }}"
    ></mc-simple-detail>

    <mc-simple-detail
      label="{{ '$I18N_CUSTOM_DOMAIN_BLOCK_VIEW_POLICY.DETAILS.ACTION.TITLE' | translate }}"
      upperValue="{{
        '$I18N_CUSTOM_DOMAIN_BLOCK_VIEW_POLICY.DETAILS.ACTION.' +
        getPolicyActionType() | translate
      }}"
      value="{{ policyDetails.redirectToUser || policyDetails.redirectToGroupDescription }}"
    ></mc-simple-detail>

    <mc-simple-detail
      label="{{ '$I18N_CUSTOM_DOMAIN_BLOCK_VIEW_POLICY.DETAILS.NOTIFY' | translate }}"
      value="{{ (policyDetails.notify ? '$I18N_CUSTOM_DOMAIN_BLOCK_VIEW_POLICY.CONTROLS.ENABLED' :
      '$I18N_CUSTOM_DOMAIN_BLOCK_VIEW_POLICY.CONTROLS.DISABLED') | translate }}"
    ></mc-simple-detail>

    <mc-simple-detail *ngIf="policyDetails.notifyGroupDescription"
      label="{{ '$I18N_CUSTOM_DOMAIN_BLOCK_VIEW_POLICY.DETAILS.NOTIFY_TO' | translate }}"
      upperValue="{{ '$I18N_CUSTOM_DOMAIN_BLOCK_VIEW_POLICY.DETAILS.GROUP' | translate }}"      
      value="{{ policyDetails.notifyGroupDescription }}">
    </mc-simple-detail>

    <mc-simple-detail
      label="{{ '$I18N_CUSTOM_DOMAIN_BLOCK_VIEW_POLICY.DETAILS.APPLIES_TO.TITLE' | translate }}"
      upperValue="{{
        '$I18N_CUSTOM_DOMAIN_BLOCK_VIEW_POLICY.DETAILS.APPLIES_TO.' +
          policyDetails.appliesTo?.type?.toUpperCase() | translate
      }}"            
      value="{{ policyDetails.appliesTo?.group?.description }}"></mc-simple-detail>

    <mc-simple-detail
      label="{{ '$I18N_CUSTOM_DOMAIN_BLOCK_VIEW_POLICY.DETAILS.CONFIGURATION_ID' | translate }}"
      value="{{ policyDetails.configurationId }}"></mc-simple-detail>

    <mc-simple-detail
      label="{{ '$I18N_CUSTOM_DOMAIN_BLOCK_VIEW_POLICY.DETAILS.LAST_UPDATED' | translate }}"
      value="{{ policyDetails.lastUpdated | mcDate }}"></mc-simple-detail>

    <div class="enabled-attribute" [ngClass]="{
      'row-enabled': policyDetails.enabled,
      'row-disabled': policyDetails.enabled === false
    }">
      <mc-simple-detail
        label="{{ '$I18N_CUSTOM_DOMAIN_BLOCK_VIEW_POLICY.DETAILS.STATUS' | translate }}"
        value="{{ (policyDetails.enabled ? '$I18N_CUSTOM_DOMAIN_BLOCK_VIEW_POLICY.CONTROLS.ENABLED' :
        '$I18N_CUSTOM_DOMAIN_BLOCK_VIEW_POLICY.CONTROLS.DISABLED') | translate }}"
  
      ></mc-simple-detail>
    </div>
  </div>

</div>

