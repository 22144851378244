"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCrowdstrikeActivityLogs = exports.isCrowdstrikeActivityLogsLoading = exports.getSendClientIdAndSecretInUpdateCall = exports.hasCreatedIntegration = exports.isVerifyClientIdAndClientSecretButtonDisabled = exports.isClientIdAndClientSecretVerified = exports.isBusy = exports.getCrowdstrikeIntegration = exports.reducer = exports.initialState = void 0;
const actions = require("app-new/api-applications/actions");
const emptyCrowdstrikeIntegration = {
    id: '',
    enabled: false,
    clientId: '',
    clientSecret: '',
    baseUrl: '',
    submitMaliciousHashes: false,
    pullMaliciousHashes: false,
    notificationGroups: {},
    notificationAddresses: [],
    iocOutboundAction: '',
    submitMaliciousUrls: false,
    iocOutboundUrlAction: 'DETECT',
    iocOutboundSeverity: 'MEDIUM',
    iocOutboundExpiration: 'DAYS_90'
};
exports.initialState = {
    isBusy: false,
    crowdstrikeIntegration: {
        id: '',
        enabled: false,
        clientId: '',
        clientSecret: '',
        submitMaliciousHashes: false,
        pullMaliciousHashes: false,
        notificationGroups: {},
        notificationAddresses: [],
        iocOutboundAction: 'PREVENT',
        submitMaliciousUrls: false,
        iocOutboundUrlAction: 'DETECT',
        iocOutboundSeverity: 'MEDIUM',
        iocOutboundExpiration: 'DAYS_90'
    },
    isClientIdAndClientSecretVerified: false,
    isVerifyClientIdAndClientSecretButtonDisabled: false,
    createdIntegration: false,
    sendClientIdAndSecretInUpdateCall: false,
    isCrowdstrikeActivityLogsLoading: false,
    crowdstrikeActivityLogs: []
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.IntegrationCrowdstrikeActionTypes.StartVerifyClientIdAndClientSecret:
            return Object.assign(Object.assign({}, state), { isClientIdAndClientSecretVerified: false, isVerifyClientIdAndClientSecretButtonDisabled: true });
        case actions.IntegrationCrowdstrikeActionTypes.StartVerifyClientIdAndClientSecretSuccess:
            return Object.assign(Object.assign({}, state), { isClientIdAndClientSecretVerified: true, isVerifyClientIdAndClientSecretButtonDisabled: false, crowdstrikeIntegration: Object.assign(Object.assign({}, state.crowdstrikeIntegration), { clientId: action.payload.clientId, clientSecret: action.payload.clientSecret, baseUrl: action.payload.baseUrl }), sendClientIdAndSecretInUpdateCall: true });
        case actions.IntegrationCrowdstrikeActionTypes.StartVerifyClientIdAndClientSecretFail:
            return Object.assign(Object.assign({}, state), { isClientIdAndClientSecretVerified: false, isVerifyClientIdAndClientSecretButtonDisabled: false });
        case actions.IntegrationCrowdstrikeActionTypes
            .StartVerifyClientIdAndClientSecretWritePermissionFail:
            return Object.assign(Object.assign({}, state), { isClientIdAndClientSecretVerified: false, isVerifyClientIdAndClientSecretButtonDisabled: false });
        case actions.IntegrationCrowdstrikeActionTypes
            .StartVerifyClientIdAndClientSecretReadPermissionFail:
            return Object.assign(Object.assign({}, state), { isClientIdAndClientSecretVerified: false, isVerifyClientIdAndClientSecretButtonDisabled: false });
        case actions.IntegrationCrowdstrikeActionTypes.SwitchCrowdstrikeIntegrationStatus:
            return Object.assign(Object.assign({}, state), { crowdstrikeIntegration: Object.assign(Object.assign({}, state.crowdstrikeIntegration), { enabled: action.payload }) });
        case actions.IntegrationCrowdstrikeActionTypes.GetCrowdstrikeIntegrationComplete:
            return Object.assign(Object.assign({}, state), { crowdstrikeIntegration: action.payload, isClientIdAndClientSecretVerified: true, isVerifyClientIdAndClientSecretButtonDisabled: false, createdIntegration: true, isBusy: false, sendClientIdAndSecretInUpdateCall: false });
        case actions.IntegrationCrowdstrikeActionTypes.SwitchSubmitMaliciousHashes:
            return Object.assign(Object.assign({}, state), { crowdstrikeIntegration: Object.assign(Object.assign({}, state.crowdstrikeIntegration), { submitMaliciousHashes: action.payload }) });
        case actions.IntegrationCrowdstrikeActionTypes.SelectIocOutboundAction:
            return Object.assign(Object.assign({}, state), { crowdstrikeIntegration: Object.assign(Object.assign({}, state.crowdstrikeIntegration), { iocOutboundAction: action.payload }) });
        case actions.IntegrationCrowdstrikeActionTypes.SwitchPullMaliciousHashes:
            return Object.assign(Object.assign({}, state), { crowdstrikeIntegration: Object.assign(Object.assign({}, state.crowdstrikeIntegration), { pullMaliciousHashes: action.payload, remediationRequired: !action.payload
                        ? false
                        : state.crowdstrikeIntegration.remediationRequired }) });
        case actions.IntegrationCrowdstrikeActionTypes.SwitchCrowdstrikeRemediationRequired:
            return Object.assign(Object.assign({}, state), { crowdstrikeIntegration: Object.assign(Object.assign({}, state.crowdstrikeIntegration), { remediationRequired: action.payload }) });
        case actions.IntegrationCrowdstrikeActionTypes.AddGroupCrowdstrikeIntegrationNotifications:
            const id = action.payload.id;
            const description = action.payload.description;
            const newAddedNotificationGroups = Object.assign({}, state.crowdstrikeIntegration.notificationGroups, { [id]: description });
            return Object.assign(Object.assign({}, state), { crowdstrikeIntegration: Object.assign(Object.assign({}, state.crowdstrikeIntegration), { notificationGroups: newAddedNotificationGroups }) });
        case actions.IntegrationCrowdstrikeActionTypes.DeleteGroupCrowdstrikeIntegrationNotifications:
            const newDeletedNotificationGroups = Object.assign({}, state.crowdstrikeIntegration.notificationGroups);
            delete newDeletedNotificationGroups[action.payload.id];
            return Object.assign(Object.assign({}, state), { crowdstrikeIntegration: Object.assign(Object.assign({}, state.crowdstrikeIntegration), { notificationGroups: newDeletedNotificationGroups }) });
        case actions.IntegrationCrowdstrikeActionTypes
            .VerifyAndAddEmailCrowdstrikeIntegrationNotificationsSuccess:
            return Object.assign(Object.assign({}, state), { crowdstrikeIntegration: Object.assign(Object.assign({}, state.crowdstrikeIntegration), { notificationAddresses: [action.payload].concat(state.crowdstrikeIntegration.notificationAddresses) }) });
        case actions.IntegrationCrowdstrikeActionTypes.DeleteEmailCrowdstrikeIntegrationNotifications:
            return Object.assign(Object.assign({}, state), { crowdstrikeIntegration: Object.assign(Object.assign({}, state.crowdstrikeIntegration), { notificationAddresses: state.crowdstrikeIntegration.notificationAddresses.filter((email) => email !== action.payload) }) });
        case actions.IntegrationCrowdstrikeActionTypes.CancelCrowdstrikeIntegrationWidget:
            return Object.assign(Object.assign({}, state), { crowdstrikeIntegration: Object.assign({}, emptyCrowdstrikeIntegration) });
        case actions.IntegrationCrowdstrikeActionTypes.GetCrowdstrikeActivityLogsComplete:
            return Object.assign(Object.assign({}, state), { crowdstrikeActivityLogs: action.payload, isCrowdstrikeActivityLogsLoading: false });
        case actions.IntegrationCrowdstrikeActionTypes.GetCrowdstrikeActivityLogs:
            return Object.assign(Object.assign({}, state), { isCrowdstrikeActivityLogsLoading: true });
        case actions.IntegrationCrowdstrikeActionTypes.SwitchMaliciousUrls:
            return Object.assign(Object.assign({}, state), { crowdstrikeIntegration: Object.assign(Object.assign({}, state.crowdstrikeIntegration), { submitMaliciousUrls: action.payload, iocOutboundUrlAction: 'DETECT', iocOutboundExpiration: 'DAYS_90', iocOutboundSeverity: 'MEDIUM' }) });
        case actions.IntegrationCrowdstrikeActionTypes.UpdateIocOutboundUrlAction:
            return Object.assign(Object.assign({}, state), { crowdstrikeIntegration: Object.assign(Object.assign({}, state.crowdstrikeIntegration), { iocOutboundUrlAction: action.payload }) });
        case actions.IntegrationCrowdstrikeActionTypes.UpdateIocOutboundSeverity:
            return Object.assign(Object.assign({}, state), { crowdstrikeIntegration: Object.assign(Object.assign({}, state.crowdstrikeIntegration), { iocOutboundSeverity: action.payload }) });
        case actions.IntegrationCrowdstrikeActionTypes.UpdateIocOutboundExpiration:
            return Object.assign(Object.assign({}, state), { crowdstrikeIntegration: Object.assign(Object.assign({}, state.crowdstrikeIntegration), { iocOutboundExpiration: action.payload }) });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getCrowdstrikeIntegration = (state) => state.crowdstrikeIntegration;
exports.getCrowdstrikeIntegration = getCrowdstrikeIntegration;
const isBusy = (state) => state.isBusy;
exports.isBusy = isBusy;
const isClientIdAndClientSecretVerified = (state) => state.isClientIdAndClientSecretVerified;
exports.isClientIdAndClientSecretVerified = isClientIdAndClientSecretVerified;
const isVerifyClientIdAndClientSecretButtonDisabled = (state) => state.isVerifyClientIdAndClientSecretButtonDisabled;
exports.isVerifyClientIdAndClientSecretButtonDisabled = isVerifyClientIdAndClientSecretButtonDisabled;
const hasCreatedIntegration = (state) => state.createdIntegration;
exports.hasCreatedIntegration = hasCreatedIntegration;
const getSendClientIdAndSecretInUpdateCall = (state) => state.sendClientIdAndSecretInUpdateCall;
exports.getSendClientIdAndSecretInUpdateCall = getSendClientIdAndSecretInUpdateCall;
const isCrowdstrikeActivityLogsLoading = (state) => state.isCrowdstrikeActivityLogsLoading;
exports.isCrowdstrikeActivityLogsLoading = isCrowdstrikeActivityLogsLoading;
const getCrowdstrikeActivityLogs = (state) => state.crowdstrikeActivityLogs;
exports.getCrowdstrikeActivityLogs = getCrowdstrikeActivityLogs;
