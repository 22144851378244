"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SystemMessagesTableComponent = void 0;
const core_1 = require("@angular/core");
const system_messages_table_static_values_1 = require("./system-messages-table.static-values");
const date_range_config_1 = require("../../../common/components/table-search-configs/date-range-config");
const rxjs_1 = require("rxjs");
class SystemMessagesTableComponent {
    constructor() {
        this.columns = [...system_messages_table_static_values_1.tableColumns];
        this.filterSections = system_messages_table_static_values_1.tableSectionFilters;
        this.columnsToStartHidden = [...system_messages_table_static_values_1.tableColumnsStartHidden];
        this.columnsAlwaysVisible = [...system_messages_table_static_values_1.tableColumnsAlwaysVisible];
        this.searchSections = system_messages_table_static_values_1.tableSectionSearch;
        this.dateRangePickerConfig = date_range_config_1.DateRangeConfig.config;
        this.columnsToIgnore = system_messages_table_static_values_1.tableColumnsToIgnore;
        this.selectedRows = [];
        this.exportColumns = [...system_messages_table_static_values_1.exportColumns];
        this.onPaginatorChanged = new core_1.EventEmitter();
        this.onSearchFilterChanged = new core_1.EventEmitter();
        this.onDateFilterChanged = new core_1.EventEmitter();
        this.onColumnFilterChanged = new core_1.EventEmitter();
        this.onRouteRecalculated = new rxjs_1.Subject();
        this.onMessagesRejected = new core_1.EventEmitter();
        this.onMessagesRetried = new core_1.EventEmitter();
        this.onMessagesEarlyBounced = new core_1.EventEmitter();
        this.onMessagesExported = new core_1.EventEmitter();
    }
    changePaginator(pagination) {
        this.onPaginatorChanged.emit(pagination);
    }
    changeColumnFilter(filter) {
        this.onColumnFilterChanged.emit(this.toFilterBy(filter));
    }
    toFilterBy(filter) {
        const filterBy = [];
        filterBy.push(...this.toFilters('route', filter.route));
        filterBy.push(...this.toFilters('attachments', filter.attachments));
        return { filterBy };
    }
    toFilters(fieldName, filters) {
        return !!filters && filters.length > 0 ? filters.map(value => ({ fieldName, value })) : [];
    }
    changeSearchFilter(filter) {
        this.onSearchFilterChanged.emit(this.toSearchBy(filter));
    }
    toSearchBy(filters) {
        if (!!filters.searchQuery) {
            return {
                searchBy: [{ fieldName: filters.selectValue, value: filters.searchQuery }]
            };
        }
        else {
            return { searchBy: [] };
        }
    }
    changeDateFilter(filter) {
        this.onDateFilterChanged.emit(filter);
    }
    selectRows(rows) {
        this.selectedRows = rows;
    }
    isSelectedRow() {
        return this.selectedRows.length > 0;
    }
    rejectMessages(messages) {
        this.onMessagesRejected.emit(messages || this.selectedRows);
    }
    retryMessages(messages) {
        this.onMessagesRetried.emit(messages || this.selectedRows);
    }
    earlyBounceMessages(messages) {
        this.onMessagesEarlyBounced.emit(messages || this.selectedRows);
    }
    exportResults() {
        this.onMessagesExported.emit(this.exportColumns);
    }
}
exports.SystemMessagesTableComponent = SystemMessagesTableComponent;
