"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OVERRIDE_STEP = exports.ConnectorStep = void 0;
var ConnectorStep;
(function (ConnectorStep) {
    ConnectorStep[ConnectorStep["PRODUCT"] = 1] = "PRODUCT";
    ConnectorStep[ConnectorStep["PROVIDER"] = 2] = "PROVIDER";
    ConnectorStep[ConnectorStep["CONSENT"] = 3] = "CONSENT";
    ConnectorStep[ConnectorStep["DETAILS"] = 4] = "DETAILS";
    ConnectorStep[ConnectorStep["GWS_INSTRUCTIONS"] = 5] = "GWS_INSTRUCTIONS";
    ConnectorStep[ConnectorStep["GWS_CUSTOMER_INFORMATION"] = 6] = "GWS_CUSTOMER_INFORMATION";
})(ConnectorStep = exports.ConnectorStep || (exports.ConnectorStep = {}));
/* This override step is to match index of wizard step and Provider specific step id */
exports.OVERRIDE_STEP = {
    google_workspace: { 3: 5, 4: 6, 5: 3, 6: 4 }
};
