"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ6 = exports.ɵ5 = exports.ɵ4 = exports.ɵ3 = exports.ɵ2 = exports.ɵ1 = exports.ɵ0 = exports.dirSyncProviders = exports.isProvidersLoading = exports.consentConfig = exports.isConsentConfigLoading = exports.getConnectors = exports.dirSyncProductId = exports.isSyncRunning = exports.isSyncReportAvailable = exports.isSyncReportLoading = exports.isSearchDirectoryLoading = exports.getSearchDirectoryResults = exports.getWizardTestInfo = exports.getWizardIntegration = exports.getAAAGroups = exports.getIntegrationsPagingMetadata = exports.getPagination = exports.getIntegrationsTotalCount = exports.getFilters = exports.getIntegrationsSelectedRow = exports.getDirSyncIntegrations = exports.isIntegrationsEmpty = exports.isIntegrationsLoading = exports.getDescription = exports.getInfo = exports.reducers = void 0;
const store_1 = require("@ngrx/store");
const directorySyncReducer = require("./directory-sync.reducer");
const integrationsReducer = require("./integrations.reducer");
const wizardReducer = require("./directory-sync-wizard.reducer");
const searchDirectoryReducer = require("./search-directory.reducer");
const synchronizationReducer = require("./synchronization.reducer");
const synchronizationStatusReducer = require("./synchronization-status.reducer");
const azureHecateReducer = require("./azure-hecate.reducer");
exports.reducers = {
    connectionInfo: directorySyncReducer.reducer,
    integrationsData: integrationsReducer.reducer,
    integration: wizardReducer.reducer,
    searchDirectory: searchDirectoryReducer.reducer,
    synchronization: synchronizationReducer.reducer,
    synchronizationStatus: synchronizationStatusReducer.reducer,
    azureHecate: azureHecateReducer.reducer
};
const getDirectorySyncState = store_1.createFeatureSelector('directory-sync');
const ɵ0 = (state) => state.connectionInfo;
exports.ɵ0 = ɵ0;
// Test connection
const getInfoState = store_1.createSelector(getDirectorySyncState, ɵ0);
exports.getInfo = store_1.createSelector(getInfoState, directorySyncReducer.getInfo);
exports.getDescription = store_1.createSelector(getInfoState, directorySyncReducer.getDescription);
const ɵ1 = state => state.integrationsData;
exports.ɵ1 = ɵ1;
// Connections table
const getDirSyncIntegrationsState = store_1.createSelector(getDirectorySyncState, ɵ1);
exports.isIntegrationsLoading = store_1.createSelector(getDirSyncIntegrationsState, integrationsReducer.isLoading);
exports.isIntegrationsEmpty = store_1.createSelector(getDirSyncIntegrationsState, integrationsReducer.isEmpty);
exports.getDirSyncIntegrations = store_1.createSelector(getDirSyncIntegrationsState, integrationsReducer.getIntegrations);
exports.getIntegrationsSelectedRow = store_1.createSelector(getDirSyncIntegrationsState, integrationsReducer.getSelectedRow);
exports.getFilters = store_1.createSelector(getDirSyncIntegrationsState, integrationsReducer.getFilters);
exports.getIntegrationsTotalCount = store_1.createSelector(getDirSyncIntegrationsState, integrationsReducer.getTotalCount);
exports.getPagination = store_1.createSelector(getDirSyncIntegrationsState, integrationsReducer.getPagination);
exports.getIntegrationsPagingMetadata = store_1.createSelector(getDirSyncIntegrationsState, integrationsReducer.getPagingMetadata);
exports.getAAAGroups = store_1.createSelector(getDirSyncIntegrationsState, integrationsReducer.getAAAGroups);
const ɵ2 = state => state.integration;
exports.ɵ2 = ɵ2;
// Wizard
const getDirSyncWizardIntegrationState = store_1.createSelector(getDirectorySyncState, ɵ2);
exports.getWizardIntegration = store_1.createSelector(getDirSyncWizardIntegrationState, wizardReducer.integration);
exports.getWizardTestInfo = store_1.createSelector(getDirSyncWizardIntegrationState, wizardReducer.testInfo);
const ɵ3 = state => state.searchDirectory;
exports.ɵ3 = ɵ3;
// Search Directory
const getSearchDirectoryState = store_1.createSelector(getDirectorySyncState, ɵ3);
exports.getSearchDirectoryResults = store_1.createSelector(getSearchDirectoryState, searchDirectoryReducer.searchResults);
exports.isSearchDirectoryLoading = store_1.createSelector(getSearchDirectoryState, searchDirectoryReducer.isLoading);
const ɵ4 = state => state.synchronization;
exports.ɵ4 = ɵ4;
// Synchronization
const getSynchronizationState = store_1.createSelector(getDirectorySyncState, ɵ4);
exports.isSyncReportLoading = store_1.createSelector(getSynchronizationState, synchronizationReducer.isLoading);
exports.isSyncReportAvailable = store_1.createSelector(getSynchronizationState, synchronizationReducer.isSyncReportAvailable);
const ɵ5 = state => state.synchronizationStatus;
exports.ɵ5 = ɵ5;
// Get Synchronization Status
const getSynchronizationStatusState = store_1.createSelector(getDirectorySyncState, ɵ5);
exports.isSyncRunning = store_1.createSelector(getSynchronizationStatusState, synchronizationStatusReducer.isSyncRunning);
const ɵ6 = state => state.azureHecate;
exports.ɵ6 = ɵ6;
// Get Azure Hecate Products and Connectors
const getAzureHecateState = store_1.createSelector(getDirectorySyncState, ɵ6);
exports.dirSyncProductId = store_1.createSelector(getAzureHecateState, azureHecateReducer.dirSyncProductId);
exports.getConnectors = store_1.createSelector(getAzureHecateState, azureHecateReducer.connectors);
exports.isConsentConfigLoading = store_1.createSelector(getAzureHecateState, azureHecateReducer.isConsentConfigLoading);
exports.consentConfig = store_1.createSelector(getAzureHecateState, azureHecateReducer.consentConfig);
exports.isProvidersLoading = store_1.createSelector(getAzureHecateState, azureHecateReducer.isProvidersLoading);
exports.dirSyncProviders = store_1.createSelector(getAzureHecateState, azureHecateReducer.dirSyncProviders);
