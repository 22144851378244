"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const core_interfaces_1 = require("app-new/core/model/core.interfaces");
const _ = require("lodash");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
/* eslint-disable */
angular.module('override-http.service', ['core.service']).factory('httpService', [
    '$http',
    '$q',
    '$cacheFactory',
    'CoreService',
    'mcCookiesService',
    'sessionHandlerService',
    function ($http, $q, $cacheFactory, coreService, mcCookiesService, sessionHandlerService) {
        var defaultCache = undefined;
        activate();
        return mcHttp;
        function activate() {
            defaultCache = $cacheFactory('mimecast.http.cache');
            // @ts-ignore
            createShortMethods('get', 'delete', 'head', 'jsonp');
            // @ts-ignore
            createShortMethodsWithData('post', 'put', 'patch');
            // @ts-ignore
            const toApiFormat = (optionalMap = operators_1.map(m => m)) => rxjs_1.pipe(core_interfaces_1.isDefined(), optionalMap, operators_1.map(p => (Object.assign({}, p))), operators_1.map(v => ({
                data: [v],
                first: v,
                all: [v]
            })), operators_1.first(), operators_1.catchError(errorResponse => errorResponse));
            defaultCache.put(getCacheId('/api/user/get-profile'), coreService
                .getUserProfile()
                .pipe(toApiFormat())
                .toPromise());
            defaultCache.put(getCacheId('/api/user/get-preferences'), coreService
                .getPreferences()
                .pipe(toApiFormat(operators_1.map(p => ({ preferences: p }))))
                .toPromise()); //{emailAddress: emailAddress}
            defaultCache.put(getCacheId('/api/account/get-account'), coreService
                .getAccount()
                .pipe(toApiFormat())
                .toPromise());
        }
        function mcHttp(config) {
            const currentCookie = mcCookiesService.get('mc-sg');
            const currentSession = !!currentCookie && JSON.parse(currentCookie).id;
            const storedSession = sessionHandlerService.getSessionId();
            // Make api calls only if same session id or not loggedin
            if ((!!currentSession && (currentSession === storedSession)) || !storedSession) {
                if (!angular.isDefined(config.cache) || config.cache === false)
                    return cancellableHttp(config);
                var cache = (config.cache === true && defaultCache) ||
                    (angular.isString(config.cache) && $cacheFactory(config.cache)) ||
                    config.cache;
                var id = getCacheId(config.url, config.data);
                var cachedResponse = cache.get(id);
                if (cachedResponse)
                    return cachedResponse;
                var responsePromise = cancellableHttp(config);
                cache.put(id, responsePromise);
                return responsePromise;
            }
        }
        function getCacheId(url, data) {
            return url + (data && JSON.stringify(data)) || '';
        }
        function cancellableHttp(config) {
            var deferredAbort = $q.defer();
            config.timeout = deferredAbort.promise;
            var defer = $q.defer();
            $http(config).then(function (response) {
                defer.resolve(response);
            }, function (error) {
                defer.reject(error);
            });
            var promise = defer.promise;
            promise.cancel = function () {
                defer.reject('canceled');
                deferredAbort.resolve();
            };
            return promise;
        }
        function createShortMethods(names) {
            _.forEach(arguments, function (name) {
                mcHttp[name] = function (url, config) {
                    return mcHttp(_.extend({}, config || {}, {
                        method: name,
                        url: url
                    }));
                };
            });
        }
        function createShortMethodsWithData(name) {
            _.forEach(arguments, function (name) {
                mcHttp[name] = function (url, data, config) {
                    return mcHttp(_.extend({}, config || {}, {
                        method: name,
                        url: url,
                        data: data
                    }));
                };
            });
        }
    }
]);
