"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MepLogSidebarEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const sidebarActions = require("../../actions/sidebar.actions");
const mep_log_sidebar_container_component_1 = require("../../containers/sidebar/mep-log-sidebar.container.component");
const notification_actions_1 = require("../../../../../components/notification/actions/notification.actions");
const { MEP_LOG_OPEN_ITEM_SIDEBAR, MEP_LOG_SIDEBAR_CLOSE, MEP_LOG_LOAD_DETAILS, MEP_LOG_LOAD_DETAILS_FAILURE } = sidebarActions.MepLogSidebarActionsEnums;
class MepLogSidebarEffects {
    constructor(actions$, api$, aside$, translate$) {
        this.actions$ = actions$;
        this.api$ = api$;
        this.aside$ = aside$;
        this.translate$ = translate$;
        this.openSidebar$ = this.actions$.pipe(effects_1.ofType(MEP_LOG_OPEN_ITEM_SIDEBAR), operators_1.map((action) => action.payload), operators_1.tap(() => {
            this.sidebarInstance = this.aside$.open(mep_log_sidebar_container_component_1.MepLogSidebarContainerComponent, {
                position: 'right',
                size: 'md',
                hasBackdrop: true
            });
        }));
        this.closeSidebar$ = this.actions$.pipe(effects_1.ofType(MEP_LOG_SIDEBAR_CLOSE), operators_1.filter(() => !!this.sidebarInstance), operators_1.tap(() => {
            this.sidebarInstance.close();
            this.sidebarInstance = undefined;
        }));
        this.getLogDetails$ = this.actions$.pipe(effects_1.ofType(MEP_LOG_LOAD_DETAILS), operators_1.switchMap((action) => {
            const { id } = action;
            return this.api$.getMepLogDetails(id).pipe(operators_1.map((response) => {
                if (response.hasErrors) {
                    return new sidebarActions.MepLogLoadDetailsFailureAction(response.fail[0]);
                }
                return new sidebarActions.MepLogLoadDetailsSuccessAction(response.first);
            }), operators_1.catchError(response => rxjs_1.of(new sidebarActions.MepLogLoadDetailsFailureAction(response.fail))));
        }));
        this.getLogDetailsFailure$ = this.actions$.pipe(effects_1.ofType(MEP_LOG_LOAD_DETAILS_FAILURE), operators_1.switchMap(() => {
            return [
                new notification_actions_1.NotificationShowFailAction(this.translate$.instant('$I18N_MEP.NOTIFICATIONS.DETAILS.ERROR')),
                new sidebarActions.MepLogSidebarClose()
            ];
        }));
    }
}
__decorate([
    effects_1.Effect({ dispatch: false })
], MepLogSidebarEffects.prototype, "openSidebar$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], MepLogSidebarEffects.prototype, "closeSidebar$", void 0);
__decorate([
    effects_1.Effect()
], MepLogSidebarEffects.prototype, "getLogDetails$", void 0);
__decorate([
    effects_1.Effect()
], MepLogSidebarEffects.prototype, "getLogDetailsFailure$", void 0);
exports.MepLogSidebarEffects = MepLogSidebarEffects;
