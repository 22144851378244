"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LayoutBaseWithFavouritesDirective = void 0;
const layout_base_with_description_component_1 = require("./layout-base-with-description.component");
class LayoutBaseWithFavouritesDirective extends layout_base_with_description_component_1.LayoutBaseWithDescriptionDirective {
    constructor() {
        super(...arguments);
        this.showFavourite = false;
    }
    // eslint-disable-next-line @angular-eslint/no-input-rename
    set valueShowFavourite(value) {
        if (typeof value === 'boolean') {
            this.showFavourite = value;
        }
        else {
            this.showFavourite = value === 'true';
        }
    }
}
exports.LayoutBaseWithFavouritesDirective = LayoutBaseWithFavouritesDirective;
