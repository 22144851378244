"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgDevicesListApiService = void 0;
const rxjs_1 = require("rxjs");
class SwgDevicesListApiService {
    constructor(http) {
        this.http = http;
        this.addDevicesInGroupSubject = new rxjs_1.Subject();
        this.removeDevicesFromGroupSubject = new rxjs_1.Subject();
    }
    getDevicesList(config) {
        return this.http.post('/api/swg/get-endpoint-summaries', config);
    }
}
exports.SwgDevicesListApiService = SwgDevicesListApiService;
