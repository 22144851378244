<div class="mc-ap-policy-details-block mc-ap-policy-steps-container">

  <mc-wizard-step-header class="mc-ap-wizard-step-header"
                         label="$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.HEADER">
  </mc-wizard-step-header>
  <h4>{{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.DETAILS_HEADER.LABEL' | translate }}</h4>
  <div class="mc-detailed-list">
    <form class="form-horizontal"
          mcDefaultLabelClass="col-sm-4"
          mcDefaultControlContainerClass="col-sm-8"
          [formGroup]="apSummaryStatusForm">
      <mc-switch-field
        label="$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.ADVANCEDOPTIONS.POLICYSTATUS"
        formControlName="PolicyRuleStatus">
      </mc-switch-field>
    </form>
    <div class="row mc-detailed-list-row mc-row-display-block">
      <div
        class="col-sm-4 mc-detailed-list-label">{{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.DETAILS_HEADER.NAME_LABEL' | translate}}</div>
      <div class="mc-detailed-list-column">{{ (summaryApPolicyDetails$ | async).description }}</div>
    </div>
    <div class="row mc-detailed-list-row">
      <div class="col-sm-4 mc-detailed-list-label">
        {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.DETAILS_HEADER.DURATION_LABEL' | translate}}
      </div>
      <div *ngIf="((summaryApPolicyDetails$ | async).apDuration && (summaryApPolicyDetails$ | async).apDuration.id) === 'perpetual'" class="mc-detailed-list-column">
        {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.DETAILS_HEADER.PERPETUAL' | translate}}
      </div>
      <div *ngIf="((summaryApPolicyDetails$ | async).apDuration && (summaryApPolicyDetails$ | async).apDuration.id) !== 'perpetual'">
        <div
          class="mc-detailed-list-column">{{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.DETAILS_HEADER.FROM'| translate}}
          {{(summaryApPolicyDetails$ | async).startDate !== '' ? (summaryApPolicyDetails$ | async).startDate : '' }}
        </div>
        <div
          class="mc-detailed-list-column">{{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.DETAILS_HEADER.TO' | translate}}
          {{(summaryApPolicyDetails$ | async).endDate  !== '' ? (summaryApPolicyDetails$ | async).endDate : ''}} </div>
      </div>
    </div>
  </div>
  <h4>{{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.LABEL' | translate }}</h4>
  <h5
    class="mc-sub-header-margin">{{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.CONFIGURATION.LABEL' | translate }} </h5>
  <div class="mc-detailed-list">
    <div class="row mc-detailed-list-row">
      <div class="col-sm-4 mc-detailed-list-label">
        {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.CONFIGURATION.DELIVERYMETHOD' | translate}}
      </div>
      <div class="mc-detailed-list-column">
        <div>{{ '$I18N_CONFIGURATION_OPTION_' + (summaryApPolicyInboundSetting$ | async).action| uppercase | translate }}</div>
        <div>{{ '$I18N_CONFIGURATION_OPTION_DESCRIPTION_' + (summaryApPolicyInboundSetting$ | async).action| uppercase | translate }}</div>
      </div>
    </div>
    <h5
      class="mc-sub-header-margin">{{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.GENERALSETTINGS.LABEL' | translate }} </h5>
    <div
      *ngIf="(summaryApPolicyInboundSetting$ | async).action!== 'safedoc_only' && (summaryApPolicyInboundSetting$ | async).action !== 'safedoc_and_link'"
      class="row mc-detailed-list-row">
      <div class="col-sm-4 mc-detailed-list-label">
        {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.PREEMPTIVE.LABEL' | translate}}
      </div>
      <div class="mc-detailed-list-column">
        {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.PREEMPTIVE.PREEMPTIVE_' + (summaryApPolicyInboundSetting$ | async).preEmptiveFailureAction | uppercase | translate}}
      </div>
    </div>
    <div *ngIf="(summaryApPolicyInboundSetting$ | async).action !== 'sandbox'" class="row mc-detailed-list-row">
      <div class="col-sm-4 mc-detailed-list-label">
        {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.IGNORE_SIGNED_MESSAGES_LABEL' | translate}}
      </div>
      <div class="mc-detailed-list-column">{{((summaryApPolicyInboundSetting$ | async).ignoreSignedMessages ?
        '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.ENABLED' :
        '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.DISABLED') | translate }}</div>
    </div>
    <div class="row mc-detailed-list-row">
      <div class="col-sm-4 mc-detailed-list-label">
        {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.INTERNAL_ATTACHMENTS_LABEL' | translate}}
      </div>
      <div class="mc-detailed-list-column">{{((summaryApPolicyInboundSetting$ | async).internalForward ?
        '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.ENABLED' :
        '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.DISABLED') | translate }}</div>
    </div>
    <div *ngIf="(summaryApPolicyInboundSetting$ | async).action !== 'safedoc_only' && (hasRemoveActiveContent$ | async)"
         class="row mc-detailed-list-row">
      <div class="col-sm-4 mc-detailed-list-label">
        {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.REMOVE_ACTIVE_CONTENT' | translate}}
      </div>
      <div class="mc-detailed-list-column">{{((summaryApPolicyInboundSetting$ | async).removeActiveContent ?
        '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.ENABLED' :
        '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.DISABLED') | translate }}</div>
    </div>
    <div *ngIf="(summaryApPolicyInboundSetting$ | async).action!== 'sandbox'">
      <h5
        class="mc-sub-header-margin">{{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.SAFEFILE.LABEL' | translate }} </h5>
      <div class="row mc-detailed-list-row">
        <div
          class="col-sm-4 mc-detailed-list-label">{{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.DOCUMENTS_LABEL' | translate}}
        </div>
        <div class="mc-detailed-list-column">
          {{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.SAFE_DOC_' + (summaryApPolicyInboundSetting$ | async).safeDocFormat | uppercase | translate}}
        </div>
      </div>
      <div class="row mc-detailed-list-row">
        <div
          class="col-sm-4 mc-detailed-list-label">{{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.SPREADSHEETS_LABEL' | translate}}
        </div>
        <div class="mc-detailed-list-column">
          {{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.SAFE_DOC_' + (summaryApPolicyInboundSetting$ | async).safeSpreadsheetFormat | uppercase | translate}}
        </div>
      </div>
    </div>
    <div *ngIf="(summaryApPolicyInboundSetting$ | async).action !== 'safedoc_only'">
      <h5
        class="mc-sub-header-margin">{{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.NOTIFICATION.LABEL' | translate }} </h5>
      <div class="row mc-detailed-list-row">
        <div
          class="col-sm-4 mc-detailed-list-label">{{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.ADMINISTRATOR_LABEL' | translate}}
        </div>
        <div class="mc-detailed-list-column">
          {{ (summaryApPolicyInboundSetting$ | async).groupsInbound ? (summaryApPolicyInboundSetting$ | async).groupsInbound.description : '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.USER.BLANK' | translate}}
        </div>
      </div>
      <div *ngIf="(summaryApPolicyInboundSetting$ | async).action !== 'safedoc_and_link'">
        <div class="row mc-detailed-list-row">
          <div
            class="col-sm-4 mc-detailed-list-label">{{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.USER.LABEL' | translate}}
          </div>
          <div class="mc-detailed-list-column">
            <div *ngIf="(summaryApPolicyInboundSetting$ | async).notifyInternalSender === true">
              {{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.USER.INTRERNAL_SENDER' | translate}}</div>
            <div *ngIf="(summaryApPolicyInboundSetting$ | async).notifyInternalRecipient === true">
              {{  '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.USER.INTERNAL_RECIPIENT' | translate}}</div>
            <div *ngIf="(summaryApPolicyInboundSetting$ | async).notifyExternalSender === true">
              {{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.USER.EXTERNAL_SENDER' | translate}}</div>
            <div *ngIf="(summaryApPolicyInboundSetting$ | async).notifyExternalSender === false && (summaryApPolicyInboundSetting$ | async).notifyInternalRecipient === false &&
                    (summaryApPolicyInboundSetting$ | async).notifyExternalSender === false">
              {{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.USER.BLANK' | translate}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="hasIEPPackage">
    <div class="mc-detailed-list">
      <h4>{{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.OUTBOUND_SETTINGS.LABEL' | translate }}</h4>
      <div class="row mc-detailed-list-row">
        <div class="col-sm-4 mc-detailed-list-label">
          {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.OUTBOUND_SETTINGS.ENABLE_OUTBOUND_CHECK' | translate}}
        </div>
        <div class="mc-detailed-list-column">{{((summaryApPolicyOutboundSetting$ | async).enableOutboundCheck ?
          '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.ENABLED' :
          '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.DISABLED') | translate }}</div>
      </div>
      <div *ngIf="(summaryApPolicyOutboundSetting$ | async).enableOutboundCheck">
        <h5
          class="mc-sub-header-margin">{{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.OUTBOUND_SETTINGS.ACTION_LABEL' | translate }} </h5>
        <div class="row mc-detailed-list-row">
          <div class="col-sm-4 mc-detailed-list-label">
            {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.OUTBOUND_SETTINGS.GATEWAY_ACTION.LABEL' | translate}}
          </div>
          <div class="mc-detailed-list-column">
            {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.OUTBOUND_SETTINGS.GATEWAY_ACTION.OPTIONS.' + (summaryApPolicyOutboundSetting$ | async).gatewayAction | uppercase | translate}}
          </div>
        </div>
        <div class="row mc-detailed-list-row">
          <div class="col-sm-4 mc-detailed-list-label">
            {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.OUTBOUND_SETTINGS.GATEWAY_FALLBACK_ACTION.LABEL' | translate}}
          </div>
          <div class="mc-detailed-list-column">
            {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.OUTBOUND_SETTINGS.GATEWAY_FALLBACK_ACTION.OPTIONS.' + (summaryApPolicyOutboundSetting$ | async).gatewayFallbackAction | uppercase | translate}}
          </div>
        </div>
        <div class="row mc-detailed-list-row">
          <div class="col-sm-4 mc-detailed-list-label">
            {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.OUTBOUND_SETTINGS.SENDER_MAILBOX_ACTION.LABEL' | translate}}
          </div>
          <div class="mc-detailed-list-column">
            {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.OUTBOUND_SETTINGS.SENDER_MAILBOX_ACTION.OPTIONS.' + (summaryApPolicyOutboundSetting$ | async).senderMailboxAction | uppercase | translate}}
          </div>
        </div>
        <div class="row mc-detailed-list-row">
          <div class="col-sm-4 mc-detailed-list-label">
            {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.OUTBOUND_SETTINGS.SENDER_MAILBOX_FALLBACK_ACTION' | translate}}
          </div>
          <div class="mc-detailed-list-column">
            {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.OUTBOUND_SETTINGS.SENDER_MAILBOX_ACTION.OPTIONS.' + (summaryApPolicyOutboundSetting$ | async).senderMailboxFallbackAction | uppercase | translate}}
          </div>
        </div>
        <h5
          class="mc-sub-header-margin">{{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.OUTBOUND_SETTINGS.NOTIFICATION_LABEL' | translate }} </h5>
        <div class="row mc-detailed-list-row">
          <div
            class="col-sm-4 mc-detailed-list-label">{{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.USER.LABEL' | translate}}
          </div>
          <div class="mc-detailed-list-column">{{((summaryApPolicyOutboundSetting$ | async).internalSenderOutbound ?
            '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.OUTBOUND_SETTINGS.USER.INTERNAL_SENDER_JOURNAL' :
            '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.USER.BLANK') | translate }}</div>
        </div>
        <div class="row mc-detailed-list-row">
          <div
            class="col-sm-4 mc-detailed-list-label">{{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.ADMINISTRATOR_LABEL' | translate}}
          </div>
          <div class="mc-detailed-list-column">{{(summaryApPolicyOutboundSetting$ | async).notifyGroupOutbound ?
            (summaryApPolicyOutboundSetting$ | async).notifyGroupOutbound.description :
            '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.USER.BLANK' | translate}}</div>
        </div>
      </div>
    </div>
    <div class="mc-detailed-list">
      <h4>{{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.JOURNAL_SETTINGS.LABEL' | translate }}</h4>
      <div class="row mc-detailed-list-row">
        <div class="col-sm-4 mc-detailed-list-label">
          {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.JOURNAL_SETTINGS.ENABLE_JOURNAL_CHECK' | translate}}
        </div>
        <div class="mc-detailed-list-column">{{((summaryApPolicyJournalSetting$ | async).enableJournalCheck ?
          '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.ENABLED' :
          '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.DISABLED') | translate }}</div>
      </div>
      <div *ngIf="(summaryApPolicyJournalSetting$ | async).enableJournalCheck">
        <h5
          class="mc-sub-header-margin">{{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.JOURNAL_SETTINGS.ACTION_LABEL' | translate }} </h5>
        <div class="row mc-detailed-list-row">
          <div class="col-sm-4 mc-detailed-list-label">
            {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.JOURNAL_SETTINGS.USER_MAILBOX_ACTION' | translate}}
          </div>
          <div class="mc-detailed-list-column">
            {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.JOURNAL_SETTINGS.SENDER_MAILBOX_ACTION.OPTIONS.' + (summaryApPolicyJournalSetting$ | async).userMailboxAction | uppercase | translate}}
          </div>
        </div>
        <div class="row mc-detailed-list-row">
          <div class="col-sm-4 mc-detailed-list-label">
            {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.JOURNAL_SETTINGS.USER_MAILBOX_FALLBACK_ACTION' | translate}}
          </div>
          <div class="mc-detailed-list-column">
            {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.JOURNAL_SETTINGS.SENDER_MAILBOX_ACTION.OPTIONS.' + (summaryApPolicyJournalSetting$ | async).userMailboxFallbackAction | uppercase | translate}}
          </div>
        </div>
        <h5
          class="mc-sub-header-margin">{{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.JOURNAL_SETTINGS.NOTIFICATION_LABEL' | translate }} </h5>
        <div class="row mc-detailed-list-row">
          <div
            class="col-sm-4 mc-detailed-list-label">{{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.USER.LABEL' | translate}}
          </div>
          <div class="mc-detailed-list-column">
            <div *ngIf="(summaryApPolicyJournalSetting$ | async).internalSenderJournal">
              {{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.JOURNAL_SETTINGS.USER.INTERNAL_SENDER_JOURNAL' | translate}}</div>
            <div *ngIf="(summaryApPolicyJournalSetting$ | async).internalRecipientJournal">
              {{  '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.JOURNAL_SETTINGS.USER.INTERNAL_RECIPIENT_JOURNAL' | translate}}</div>
            <div
              *ngIf="(summaryApPolicyJournalSetting$ | async).internalSenderJournal === false && (summaryApPolicyJournalSetting$ | async).internalRecipientJournal === false">
              {{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.USER.BLANK' | translate}}
            </div>
          </div>
        </div>
        <div class="row mc-detailed-list-row">
          <div
            class="col-sm-4 mc-detailed-list-label">{{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.ADMINISTRATOR_LABEL' | translate}}
          </div>
          <div class="mc-detailed-list-column">{{(summaryApPolicyJournalSetting$ | async).notifyGroupJournal ?
            (summaryApPolicyJournalSetting$ | async).notifyGroupJournal.description :
            '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.USER.BLANK' | translate}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="mc-detailed-list">
    <h4>{{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.APPLIES_T0.LABEL' | translate }}</h4>
    <div *ngFor="let rule of (summaryApPolicyAppliesToSetting$ | async).attachmentPolicyRule; let i = index">
      <h5
        class="mc-sub-header-margin">{{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.APPLIES_T0.SENDER_AND_RECIPIENT_HEADER' | translate }} {{i + 1}} </h5>
      <div class="row mc-detailed-list-row">
        <div
          class="col-sm-4 mc-detailed-list-label">{{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.APPLIES_T0.ADDRESS_BASED_ON_LABEL' | translate}}
        </div>
        <div
          class="mc-detailed-list-column">{{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.APPLIES_T0.' + rule.apAddressesBasedOn| uppercase | translate }}</div>
      </div>
      <div class="row mc-detailed-list-row">
        <div
          class="col-sm-4 mc-detailed-list-label">{{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.APPLIES_T0.FROM_LABEL' | translate}}
        </div>
        <div
          class="mc-detailed-list-column">{{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.APPLIES_T0.' + rule.apAppliesFrom| uppercase | translate }}</div>
      </div>
      <div class="mc-detailed-list-value">
        <div *ngIf="rule.apAppliesFrom === 'email_domain'">{{ rule.apDomainFrom }}</div>
        <div *ngIf="rule.apAppliesFrom === 'individual_email_address'">{{ rule.apEmailFrom }}</div>
        <div *ngIf="rule.apAppliesFrom === 'address_attribute_value'">{{ !!rule.apAttributeOptionFrom ? rule.apAttributeOptionFrom.name : null}}</div>
        <div *ngIf="rule.apAppliesFrom === 'profile_group'">{{ !!rule.apGroupFrom ? rule.apGroupFrom.description: null}}</div>
      </div>
      <div class="row mc-detailed-list-row">
        <div
          class="col-sm-4 mc-detailed-list-label">{{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.APPLIES_T0.TO_LABEL' | translate}}
        </div>

        <div
          class="mc-detailed-list-column">{{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.APPLIES_T0.' + rule.apAppliesTo| uppercase | translate }}</div>
      </div>
      <div class="mc-detailed-list-value">
        <div *ngIf="rule.apAppliesTo === 'individual_email_address'">{{ rule.apEmailTo}} </div>
        <div *ngIf="rule.apAppliesTo === 'address_attribute_value'">{{!!rule.apAttributeOptionTo ? rule.apAttributeOptionTo.name : null }} </div>
        <div *ngIf="rule.apAppliesTo === 'email_domain'">{{ rule.apDomainTo }}</div>
        <div *ngIf="rule.apAppliesTo === 'profile_group'">{{!!rule.apGroupTo ? rule.apGroupTo.description : null}}</div>
      </div>
    </div>
  </div>
  <h5
    class="mc-sub-header-margin">{{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.ADVANCEDOPTIONS.LABEL' | translate }} </h5>
  <div class="mc-detailed-list">
    <div class="row mc-detailed-list-row">
      <div class="col-sm-4 mc-detailed-list-label">
        {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.ADVANCEDOPTIONS.BIDIRECTIONAL' | translate}}
      </div>
      <div class="mc-detailed-list-column">{{((summaryApPolicyAppliesToSetting$ | async).apBidirectional ?
        '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.ENABLED' :
        '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.DISABLED') | translate }}</div>
    </div>
    <div class="row mc-detailed-list-row">
      <div class="col-sm-4 mc-detailed-list-label">
        {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.ADVANCEDOPTIONS.POLICYOVERRIDE' | translate}}
      </div>
      <div class="mc-detailed-list-column">{{((summaryApPolicyAppliesToSetting$ | async).override ?
        '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.ENABLED' :
        '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.DISABLED') | translate }}</div>
    </div>
    <div class="row mc-detailed-list-row">
      <div
        class="col-sm-4 mc-detailed-list-label">{{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.ADVANCEDOPTIONS.SOURCEIPRANGES' | translate}}
      </div>
      <div
        class="mc-detailed-list-column">
        <div *ngFor="let ipAddress of (summaryApPolicyAppliesToSetting$ | async).ipApAddresses">
          <div>{{ipAddress.apIpRange}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
