"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgPolicyBlockPageSettingEffects = void 0;
const effects_1 = require("@ngrx/effects");
const operators_1 = require("rxjs/operators");
const rxjs_1 = require("rxjs");
const settings_action_1 = require("../actions/settings.action");
const notification_actions_1 = require("../../../components/notification/actions/notification.actions");
class SwgPolicyBlockPageSettingEffects {
    constructor(actions$, stateService, translationService, swgBlockPageService) {
        this.actions$ = actions$;
        this.stateService = stateService;
        this.translationService = translationService;
        this.swgBlockPageService = swgBlockPageService;
        this.loadDefaultBlockPageSettings$ = this.actions$.pipe(effects_1.ofType(settings_action_1.SwgBlockPageSettingsEnum.SWG_BLOCK_PAGE_GET), operators_1.switchMap(() => this.swgBlockPageService.getBlockPageSettings().pipe(operators_1.map(res => {
            return new settings_action_1.SwgBlockPageSettingsGetSuccessAction(res.first);
        }), operators_1.catchError(error => rxjs_1.merge(rxjs_1.of(this.getNotificationAction(error)), rxjs_1.of(new settings_action_1.SwgBlockPageSettingsGetFailureAction()))))));
        this.saveBlockPageSettings$ = this.actions$.pipe(effects_1.ofType(settings_action_1.SwgBlockPageSettingsEnum.SWG_BLOCK_PAGE_SAVE), operators_1.map((action) => action.payload), operators_1.switchMap(payload => this.swgBlockPageService.saveBlockPageSettings(payload).pipe(operators_1.mergeMap(response => {
            this.stateService.$state.go('secure-web-gateway-policy-list');
            return [
                new notification_actions_1.NotificationShowAction({
                    type: 'success',
                    config: {
                        msg: this.translationService.instant('$I18N_SWG_BLOCK_PAGE_SAVE_NOTIFICATION_MESSAGE')
                    }
                }),
                new settings_action_1.SwgBlockPageSettingsSaveSuccessAction(response.first)
            ];
        }), operators_1.catchError(error => rxjs_1.merge(rxjs_1.of(this.getNotificationAction(error)), rxjs_1.of(new settings_action_1.SwgBlockPageSettingsSaveFailureAction()))))));
    }
    getNotificationAction(error) {
        const msg = error.fail
            ? error.fail[0].errors[0].message
            : this.translationService.instant('$I18N_SWG_BLOCK_PAGE_SAVE_NOTIFICATION_ERROR');
        return new notification_actions_1.NotificationShowAction({
            type: 'error',
            config: {
                msg
            }
        });
    }
}
__decorate([
    effects_1.Effect()
], SwgPolicyBlockPageSettingEffects.prototype, "loadDefaultBlockPageSettings$", void 0);
__decorate([
    effects_1.Effect()
], SwgPolicyBlockPageSettingEffects.prototype, "saveBlockPageSettings$", void 0);
exports.SwgPolicyBlockPageSettingEffects = SwgPolicyBlockPageSettingEffects;
