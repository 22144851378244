"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TtpImpersonationLogService = void 0;
const log_service_1 = require("../../../../../components/log-detail-navigator/model/log-service");
class TtpImpersonationLogService extends log_service_1.LogService {
    constructor(logs, arrRowClicked, niceScroll) {
        super(logs, arrRowClicked, niceScroll);
    }
}
exports.TtpImpersonationLogService = TtpImpersonationLogService;
