<ng-container *ngIf="isEdit">
    <mc-layout-aside-extra-pagination-container [keyTitle]="sidePanelTitle" [isLoading]="false" [showClose]="true"
        [hasNextInView]="hasNextInView$ | async" [hasPreviousInView]="hasPreviousInView$ | async"
        [indexInView]="indexInView$ | async" [currenPagination]="currentPagination$ | async"
        [filtersMetaData]="filtersMetaData$ | async" [isLoading]="false" (goToNextPage)="getNext()"
        (goToPrevPage)="getPrev()" (closeAside)="onClose()">
        <div row-actions>
            <div class="mc-header-row" *ngIf="isSubtitleVisible">
              <span> {{generateUrl()}}</span>
            </div>
            <ng-container *ngTemplateOutlet="formControls"></ng-container>
        </div>
        <mc-body-container>
            <ng-container *ngTemplateOutlet="formTemplate"></ng-container>
        </mc-body-container>
    </mc-layout-aside-extra-pagination-container>
</ng-container>
<ng-container *ngIf="!isEdit">
    <mc-layout-aside-extra-container [keyTitle]="sidePanelTitle" [isLoading]="false" [showClose]="true"
        (closeAside)="onClose()">
        <mc-extra-container>
            <ng-container *ngTemplateOutlet="formControls"></ng-container>
        </mc-extra-container>
        <mc-body-container>
            <ng-container *ngTemplateOutlet="formTemplate"></ng-container>
        </mc-body-container>
    </mc-layout-aside-extra-container>
</ng-container>

<ng-template #formTemplate>
    <form [formGroup]="form" (ngSubmit)="onSave()" class="mc-detailed-list">
        <div [class.details-mode]="isEditMode && !isEditAllowed">
            <div class="mc-detailed-list-row">
                <div class="mc-detailed-list-label custom-label">
                    <span>{{'$I18N_SERVICES_URL_PROTECTION_MANAGED_URL_OVERRIDE_TYPE' | translate}}</span>
                    <span *ngIf="!isEditMode" class="text-danger">*</span>
                    <mc-tooltip
                        placement="top"
                        popoverContent="{{overrideTypeTooltipText | translate}}">
                    </mc-tooltip>
                </div>
                <div class="mc-detailed-list-column column-margin">
                    <span *ngIf="isEditMode && !isEditAllowed"
                        class="custom-text override-type-text-element">{{overrideTypeValue}}</span>
                    <div *ngIf="!isEditMode || isEditAllowed">
                        <mc-select formControlName="overrideType" [options]="overrideTypes"
                            class="mc-timeout-control override-type-select-element row-element"
                            data-test="cy-override-type-field">
                        </mc-select>
                    </div>
                </div>
            </div>
            <div class="mc-detailed-list-row" *ngIf="isDisableRewritingVisible">
                <div class="mc-detailed-list-label custom-label">
                    <span>{{'$I18N_SERVICES_URL_PROTECTION_MANAGED_URL_DISABLE_REWRITE' | translate}}</span>
                    <mc-tooltip placement="top"
                        popoverContent="{{'$I18N_SERVICES_URL_PROTECTION_MANAGED_URL_DISABLE_REWRITING_TOOLTIP_TEXT' | translate }}">
                    </mc-tooltip>
                </div>
                <div class="mc-detailed-list-column column-margin">
                    <span *ngIf="isEditMode && !isEditAllowed"
                        class="custom-text override-type-text-element">{{rewritingValueText | translate}}</span>
                    <div *ngIf="!isEditMode || isEditAllowed">
                        <mc-switch class="mc-settings-toggle row-element" data-test="cy-disable-rewrite-field"
                            formControlName="disableRewriting" tabindex="0" role="switch" showLabel="true">
                        </mc-switch>
                    </div>
                </div>
            </div>
            <div *ngIf="isUserAwarenessVisible" class="mc-detailed-list-row disable-rewrite-container {{isEditAllowed ? 'long-label' : ''}}">
                <div class="mc-detailed-list-label custom-label">
                    <span>{{'$I18N_SERVICES_URL_PROTECTION_MANAGED_URL_DISABLE_AWARENESS' | translate}}</span>
                    <mc-tooltip placement="top"
                        popoverContent="{{ '$I18N_SERVICES_URL_PROTECTION_MANAGED_URL_DISABLE_AWARENESS_TOOLTIP_TEXT' | translate }}">
                    </mc-tooltip>
                </div>
                <div class="mc-detailed-list-column column-margin">
                    <span *ngIf="isEditMode && !isEditAllowed"
                        class="custom-text override-type-text-element">{{userAwarenessValueText | translate}}</span>
                    <div *ngIf="!isEditMode || isEditAllowed">
                        <mc-switch class="mc-settings-toggle row-element" data-test="cy-disable-awareness-field"
                            formControlName="disableAwareness" tabindex="0" role="switch" showLabel="true">
                        </mc-switch>
                    </div>
                </div>
            </div>
            <!-- log clicks -> visible only for .local accounts -->
            <div *mcCapabilities="'Permission.SERVICE_DELIVERY_READ'" class="mc-detailed-list-row  {{isEditAllowed ? 'long-label' : ''}}">
                <div class="mc-detailed-list-label custom-label">
                    <span>{{'$I18N_SERVICES_URL_PROTECTION_MANAGED_URL_DISABLE_LOG_CLICKS' | translate}}</span>
                    <mc-tooltip placement="top"
                        popoverContent="{{ '$I18N_SERVICES_URL_PROTECTION_MANAGED_URL_DISABLE_LOG_CLICKS_TOOLTIP_TEXT' | translate }}">
                    </mc-tooltip>
                </div>
                <div class="mc-detailed-list-column column-margin">
                    <span *ngIf="isEditMode && !isEditAllowed"
                        class="custom-text override-type-text-element">{{disableLogClicksTextValue | translate}}</span>
                    <div *ngIf="!isEditMode || isEditAllowed">
                        <mc-switch class="mc-settings-toggle row-element" data-test="cy-disable-awareness-field"
                            formControlName="disableLogClick" tabindex="0" role="switch" showLabel="true">
                        </mc-switch>
                    </div>
                </div>
            </div>
            <div class="mc-detailed-list-row">
                <div class="mc-detailed-list-label custom-label">
                    <span>{{'$I18N_SERVICES_URL_PROTECTION_MANAGED_URL_MATCH_TYPE' | translate}}</span>
                    <mc-tooltip placement="top"
                        popoverContent="{{ '$I18N_SERVICES_URL_PROTECTION_MANAGED_URL_MATCH_TYPE_TOOLTIP_TEXT' | translate }}">
                    </mc-tooltip>
                </div>
                <div class="mc-detailed-list-column column-margin">
                    <span *ngIf="isEditMode && !isEditAllowed"
                        class="custom-text override-type-text-element">{{matchTypeValueText}}</span>
                    <div *ngIf="!isEditMode || isEditAllowed">
                        <mc-select formControlName="matchType" [options]="matchTypes"
                            class="mc-timeout-control row-element">
                        </mc-select>
                    </div>
                </div>
            </div>
            <div class="mc-detailed-list-row">
                <div class="mc-detailed-list-label custom-label">
                    <span>{{'$I18N_SERVICES_URL_PROTECTION_MANAGED_URL_URL' | translate}}</span>
                    <span *ngIf="!isEditMode" class="text-danger">*</span>
                    <mc-tooltip placement="top"
                        popoverContent="{{ '$I18N_SERVICES_URL_PROTECTION_MANAGED_URL_URL_TOOLTIP_TEXT' | translate:{type: overrideTypeValue} }}">
                    </mc-tooltip>
                </div>
                <div class="mc-detailed-list-column column-margin">
                    <span *ngIf="isEditMode && !isEditAllowed" class="custom-text override-type-text-element url-form-field">
                        {{urlsValueText}}
                    </span>
                    <div *ngIf="isEditMode && isEditAllowed">
                        <mc-text-field formControlName="urls" required class="row-element urls-input-element"
                            data-test="cy-url-field"
                            errorPrefix="$I18N_SERVICES_URL_PROTECTION_MANAGED_URL_URL_ERROR_VALIDATION_MSG">
                        </mc-text-field>
                    </div>
                    <div *ngIf="!isEditMode">
                        <mc-text-area-field formControlName="urls" required class="row-element urls-text-element"
                            data-test="cy-url-field" placeholder="$I18N_SERVICES_URL_PROTECTION_MANAGED_URL_URL_PLACEHOLDER"
                            errorPrefix="$I18N_SERVICES_URL_PROTECTION_MANAGED_URL_URL_ERROR_VALIDATION_MSG">
                        </mc-text-area-field>
                    </div>
                </div>
            </div>
            <div class="mc-detailed-list-row">
                <span class="mc-detailed-list-label custom-label comment-spacing">
                    <span>{{'$I18N_SERVICES_URL_PROTECTION_MANAGED_URL_COMMENT' | translate}}</span>
                    <span *ngIf="!isEditMode" class="text-danger">*</span>
                </span>
                <div class="mc-detailed-list-column column-margin">
                    <span *ngIf="isEditMode && !isEditAllowed"
                        class="custom-text override-type-text-element">{{commentValueText}}</span>
                    <div *ngIf="!isEditMode || isEditAllowed">
                        <mc-text-area-field formControlName="comment" required data-test="cy-comments-field"
                            placeholder="$I18N_SERVICES_URL_PROTECTION_MANAGED_URL_COMMENT_PLACEHOLDER"
                            errorPrefix="$I18N_SERVICES_URL_PROTECTION_MANAGED_URL_COMMENT_PLACEHOLDER_ERROR_MSG">
                        </mc-text-area-field>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #formControls>
    <ng-container *mcCapabilities="canCreateEditAndDeleteCapExpression">
        <button
            class="btn btn-primary panel-half-margin-right"
            data-test="cy-edit-allow-btn"
            data-unit-test="unit-edit-allow-btn"
            *ngIf="isEditMode && !isEditAllowed" (click)="allowEditing()">
            {{'$I18N_SERVICES_URL_PROTECTION_MANAGED_URL_EDIT_BUTTON' | translate}}
        </button>
    </ng-container>
    <button class="btn btn-primary panel-half-margin-right" (click)="onSave()" data-test="cy-save-and-close-btn"
        [disabled]="!form.valid || (formLoadingState$ | async)" *ngIf="isEditAllowed">
        {{'$I18N_SERVICES_URL_PROTECTION.SAVE' | translate}}
    </button>
    <button class="btn btn-secondary panel-half-margin-right" (click)="cancel.emit()"
        data-test="cy-cancel-sidepanel-btn" *ngIf="isEditAllowed">
        {{'$I18N_MONITORED_DOMAINS_EDIT_DOMAIN.BUTTON_LABEL.CANCEL' | translate}}
    </button>
</ng-template>
