"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RuleUsageNotificationModalComponent = void 0;
class RuleUsageNotificationModalComponent {
    constructor(overlayRef, overlayData) {
        this.overlayRef = overlayRef;
        this.overlayData = overlayData;
        this.entityName = this.overlayData.entityName;
        this.associatedRules = this.overlayData.associatedRules;
        this.entityDeletionType = this.overlayData.entityDeletionType;
    }
    close(confirmed) {
        this.overlayRef.close(confirmed);
    }
}
exports.RuleUsageNotificationModalComponent = RuleUsageNotificationModalComponent;
