
    <div class="mc-chart-no-data" [ngClass]="{ 'mc-container-height': icon !== 'fa-chart-line' }">
      <div class="mc-chart-no-data-img fas fa-3x" [ngClass]="icon"></div>
      <div class="mc-chart-no-data-heading">
        {{ messageHeading | translate }}
      </div>
      <div class="mc-chart-no-data-message">
        {{ this.message + '.MESSAGE_START' | translate }}
        <a class="mc-link cursor-pointer" (click)="clickLink()">
          {{ this.message + '.MESSAGE_LINK' | translate }}
        </a>
        {{ this.message + '.MESSAGE_END' | translate }}
      </div>
    </div>
  