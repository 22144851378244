"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PendoUserInformationProvider = void 0;
const operators_1 = require("rxjs/operators");
const store_1 = require("@ngrx/store");
const reducers_1 = require("../../../../reducers");
const base_pendo_information_provider_1 = require("../../base-pendo-information.provider");
class PendoUserInformationProvider extends base_pendo_information_provider_1.BasePendoInformationProvider {
    constructor(store) {
        super();
        this.store = store;
    }
    getInformation() {
        return this.store.pipe(store_1.select(reducers_1.getUserProfile), operators_1.filter(v => !!v), operators_1.first(), operators_1.map((user) => ({
            visitor: {
                id: user.id,
                email: user.emailAddress,
                full_name: user.name
            }
        })));
    }
}
exports.PendoUserInformationProvider = PendoUserInformationProvider;
