<div>
  <mc-collapsable-panel header="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TRANSMISSION_INFORMATION.TITLE" [isExpanded]="true">
    <div>
      <div *ngIf="isDataSetOne; else archivedTransmissionInformation">
        <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TRANSMISSION_INFORMATION.LABEL_SMTP_START_TIME"
                        [value]="recipientInfo?.txInfo?.smtpStartTime | mcDatePlusWeekday"
                        [showSecondValue]="true"
                        [secondValue]="selectedDeliveredMessage?.txInfo?.smtpStartTime | mcDatePlusWeekday">
        </mc-value-field>
        <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TRANSMISSION_INFORMATION.LABEL_SMTP_END_TIME"
                        [value]="recipientInfo?.txInfo?.smtpEndTime | mcDatePlusWeekday"
                        [showSecondValue]="true"
                        [secondValue]="selectedDeliveredMessage?.txInfo?.smtpEndTime | mcDatePlusWeekday">
        </mc-value-field>
        <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TRANSMISSION_INFORMATION.LABEL_SMTP_TOTAL_TIME"
                        [value]="smtpTotalTimeReceived"
                        [showSecondValue]="true"
                        [secondValue]="smtpTotalTimeDelivery">
        </mc-value-field>
        <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TRANSMISSION_INFORMATION.LABEL_TRANSMISSION_SIZE"
                        [value]="recipientInfo?.txInfo?.transmissionSize | mcToBytes"
                        [showSecondValue]="true"
                        [secondValue]="selectedDeliveredMessage?.txInfo?.transmissionSize | mcToBytes">
        </mc-value-field>
        <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TRANSMISSION_INFORMATION.LABEL_ACTUAL_SIZE"
                        [value]="recipientInfo?.txInfo?.actualSize | mcToBytes"
                        [showSecondValue]="true"
                        [secondValue]="selectedDeliveredMessage?.txInfo?.actualSize | mcToBytes">
        </mc-value-field>
        <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TRANSMISSION_INFORMATION.LABEL_ENCRYPTION_INFORMATION"
                        [value]="recipientInfo?.txInfo?.deliveryType"
                        [showSecondValue]="true"
                        [secondValue]="selectedDeliveredMessage?.txInfo?.deliveryType">
        </mc-value-field>
      </div>
      <ng-template #archivedTransmissionInformation>
        <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TRANSMISSION_INFORMATION.LABEL_SMTP_START_TIME"
                        [value]="recipientInfo?.recipientMetaInfo?.transmissionStart | mcDatePlusWeekday"
                        [showSecondValue]="true"
                        [secondValue]="selectedDeliveredMessage?.deliveryMetaInfo?.transmissionStart | mcDatePlusWeekday">
        </mc-value-field>
        <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TRANSMISSION_INFORMATION.LABEL_SMTP_END_TIME"
                        [value]="recipientInfo?.recipientMetaInfo?.transmissionEnd | mcDatePlusWeekday"
                        [showSecondValue]="true"
                        [secondValue]="selectedDeliveredMessage?.deliveryMetaInfo?.transmissionEnd | mcDatePlusWeekday">
        </mc-value-field>
        <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TRANSMISSION_INFORMATION.LABEL_SMTP_TOTAL_TIME"
                        [value]="smtpTotalTimeReceivedArchived"
                        [showSecondValue]="true"
                        [secondValue]="smtpTotalTimeDeliveryArchived">
        </mc-value-field>
        <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TRANSMISSION_INFORMATION.LABEL_TRANSMISSION_SIZE"
                        [value]="recipientInfo?.recipientMetaInfo?.transmissionSize | mcToBytes"
                        [showSecondValue]="true"
                        [secondValue]="selectedDeliveredMessage?.deliveryMetaInfo?.transmissionSize | mcToBytes">
        </mc-value-field>
        <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TRANSMISSION_INFORMATION.LABEL_ACTUAL_SIZE"
                        [value]="transmissionInformationReceivedActualSize | mcToBytes"
                        [showSecondValue]="true"
                        [secondValue]="transmissionInformationDeliveryActualSize | mcToBytes">
        </mc-value-field>
        <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TRANSMISSION_INFORMATION.LABEL_ENCRYPTION_INFORMATION"
                        [value]="recipientInfo?.recipientMetaInfo?.encryptionInfo"
                        [showSecondValue]="true"
                        [secondValue]="selectedDeliveredMessage?.deliveryMetaInfo?.encryptionInfo">
        </mc-value-field>
      </ng-template>
    </div>
  </mc-collapsable-panel>
</div>
