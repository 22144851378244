"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteRuleActionSuccess = exports.DeleteRuleActionFail = exports.DeleteRuleAction = exports.SetRuleDetailsPaginatorDataAction = exports.OpenEditRuleAction = exports.OpenCreateNewRuleAction = exports.RuleListActions = void 0;
var RuleListActions;
(function (RuleListActions) {
    RuleListActions["OPEN_CREATE_NEW_RULE"] = "[SupervisionRuleList] Open Create New Rule";
    RuleListActions["OPEN_EDIT_RULE"] = "[SupervisionRuleList] Open Edit Rule";
    RuleListActions["SET_RULE_DETAILS_PAGINATOR_DATA"] = "[SupervisionRuleList] Set Rule Details Paginator Data";
    RuleListActions["DELETE_RULE"] = "[SupervisionRuleList] Delete Rule";
    RuleListActions["DELETE_RULE_FAIL"] = "[SupervisionRuleList] Delete Rule Fail";
    RuleListActions["DELETE_RULE_SUCCESS"] = "[SupervisionRuleList] Delete Rule Success";
})(RuleListActions = exports.RuleListActions || (exports.RuleListActions = {}));
class OpenCreateNewRuleAction {
    constructor() {
        this.type = RuleListActions.OPEN_CREATE_NEW_RULE;
    }
}
exports.OpenCreateNewRuleAction = OpenCreateNewRuleAction;
class OpenEditRuleAction {
    constructor(ruleId) {
        this.ruleId = ruleId;
        this.type = RuleListActions.OPEN_EDIT_RULE;
    }
}
exports.OpenEditRuleAction = OpenEditRuleAction;
class SetRuleDetailsPaginatorDataAction {
    constructor(previousRule, currentRule, nextRule, currentRuleIndex, totalNumberRules) {
        this.previousRule = previousRule;
        this.currentRule = currentRule;
        this.nextRule = nextRule;
        this.currentRuleIndex = currentRuleIndex;
        this.totalNumberRules = totalNumberRules;
        this.type = RuleListActions.SET_RULE_DETAILS_PAGINATOR_DATA;
    }
}
exports.SetRuleDetailsPaginatorDataAction = SetRuleDetailsPaginatorDataAction;
class DeleteRuleAction {
    constructor(payload) {
        this.payload = payload;
        this.type = RuleListActions.DELETE_RULE;
    }
}
exports.DeleteRuleAction = DeleteRuleAction;
class DeleteRuleActionFail {
    constructor(payload) {
        this.payload = payload;
        this.type = RuleListActions.DELETE_RULE_FAIL;
    }
}
exports.DeleteRuleActionFail = DeleteRuleActionFail;
class DeleteRuleActionSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = RuleListActions.DELETE_RULE_SUCCESS;
    }
}
exports.DeleteRuleActionSuccess = DeleteRuleActionSuccess;
