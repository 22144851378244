<div
  class="mc-mep-external-recipients-container"
  *ngIf="hasExternalRecipients(); else noExternalRecipients"
>
  <ng-container *ngIf="hasMepLogReleaseDropPermission$ | async">
    <button
      data-test="drop-btn"
      [disabled]="isButtonDisabled"
      class="btn btn-primary btn-sm"
      (click)="onAdminAction(mepAdminLogActions.DROP)"
    >
      {{ '$I18N_LOG_SIDEBAR.EXTERNAL_RECIPIENTS.BUTTONS.DROP' | translate }}
    </button>
    <button
      data-test="release-btn"
      [disabled]="isButtonDisabled"
      class="btn btn-secondary btn-sm"
      (click)="onAdminAction(mepAdminLogActions.RELEASE)"
    >
      {{ '$I18N_LOG_SIDEBAR.EXTERNAL_RECIPIENTS.BUTTONS.RELEASE' | translate }}
    </button>
  </ng-container>

  <div class="mc-mep-external-recipients-table bordered">
    <div
      class="row"
      *ngFor="let recipient of externalRecipients"
    >
      <div class="col-sm-12">
        <mc-mep-log-sidebar-external-recipients-list-item
          [flaggedRecipient]="recipient"
          [status]="recipientStatus"
        ></mc-mep-log-sidebar-external-recipients-list-item>
      </div>
    </div>
  </div>
</div>
<ng-template #noExternalRecipients>
  <div class="no-external-recipients">
    <mc-inline-notification [notification]="{
            status: 'info',
            boxed: true,
            msg: '$I18N_LOG_SIDEBAR.EXTERNAL_RECIPIENTS.NO_DATA' | translate
        }"></mc-inline-notification>
  </div>
</ng-template>
