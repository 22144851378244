<mc-layout-modal-simple keyTitle="{{title}}">
  <mc-body-container *ngIf="hasError">
    <div>
      <div *ngFor="let err of data.errors">
        {{err.key.url}}
      </div>
    </div>
  </mc-body-container>
  <mc-footer-container>
    <button type="button" class="btn btn-secondary" (click)="onCancel()">
      {{'$I18N_SERVICES_URL_PROTECTION_MANAGED_URL_CANCEL_BUTTON' | translate}}
    </button>
    <button type="button" class="btn btn-danger" data-test="cy-delete-override-btn" (click)="onDelete()">
      <i [ngClass]="'far fa-trash-alt'"></i>
      <span>{{buttonText}}</span>
    </button>
  </mc-footer-container>
</mc-layout-modal-simple>