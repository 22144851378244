"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgThreatEventSummaryComponent = void 0;
const models_1 = require("../../../../models/models");
class SwgThreatEventSummaryComponent {
    constructor(translate) {
        this.translate = translate;
        this.notification = {
            msg: this.translate.instant('$I18N_SWG_THREAT_EVENT_SUMMARY.NOTIFICATION'),
            boxed: true,
            status: 'info'
        };
        this.dateFormat = 'dd MMM yyyy - HH:mm';
    }
    getStatusString() {
        return models_1.ThreatEventStatus.LOG_AND_REPLY;
    }
    getValueOrNA(field) {
        const value = field
            .split('.')
            .reduce((o, i) => (o != null && i in o ? o[i] : null), this.threatEvent);
        if (value !== null) {
            return value;
        }
        return this.translate.instant('$I18N_SWG_THREAT_EVENT_SUMMARY.NOT_AVAILABLE');
    }
    hasMalware() {
        return this.threatEvent.threatTypes.indexOf(models_1.ThreatEventType.MALWARE) !== -1;
    }
    hasPhishing() {
        return this.threatEvent.threatTypes.indexOf(models_1.ThreatEventType.PHISHING) !== -1;
    }
}
exports.SwgThreatEventSummaryComponent = SwgThreatEventSummaryComponent;
