"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.LexiconDetailsFactory = void 0;
const lexicon_details_builder_1 = require("../model/lexicon/lexicon-details.builder");
const ɵ0 = (lexicon) => new lexicon_details_builder_1.LexiconDetailsBuilder()
    .withRuleIds(lexicon['associatedRules'])
    .withWords(lexicon['searchTerms']['words'])
    .withPhrases(lexicon['searchTerms']['phrases'])
    .withQuery(lexicon['searchTerms']['query'])
    .withSearchType(lexicon['searchType'])
    .withSearchCriteriaVersionsNumber(lexicon['versions'])
    .withId(lexicon['id'])
    .withName(lexicon['name'])
    .withDescription(lexicon['description'])
    .withCreatedBy(lexicon['createdBy'])
    .withUpdatedBy(lexicon['updatedBy'])
    .withCreated(lexicon['created'])
    .withUpdatedDate(lexicon['updated'])
    .withStatus(lexicon['status'])
    .build();
exports.ɵ0 = ɵ0;
exports.LexiconDetailsFactory = {
    BUILD_LEXICON_DETAILS: ɵ0
};
