"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ4 = exports.ɵ3 = exports.ɵ2 = exports.ɵ1 = exports.ɵ0 = exports.NodeFactory = void 0;
const folder_1 = require("app-new/components/tree/model/folder");
const email_1 = require("app-new/components/tree/model/items/email");
const calendar_1 = require("app-new/components/tree/model/items/calendar");
const contact_1 = require("app-new/components/tree/model/items/contact");
const file_1 = require("app-new/components/tree/model/items/file");
const ɵ0 = (node) => new folder_1.Folder(node['content'], node['contentType'], node['escapedFullPath'], node['fullPath'], node['wellKnownType'], node['subfolderCount'], node['id'], node['name'] || '<>', node['lastChangeId'], node['lastChangeType'], node['selected'], node['modified'], node['parentId'], node['deleted']), ɵ1 = (node) => new email_1.Email(node['subject'], node['internetMessageId'], node['sender'] || node['displayfrom'], node['dateReceived'] || node['receiveddate'], node['recipients'], node['from'] || node['displayfrom'], node['smash'], node['to'] || node['displayto'], node['hasAttachments'] || node['attachmentcount'], node['exchangeThreadId'], node['modified'], node['size'], node['id'], node['name'], node['lastChangeId'], node['lastChangeType'], node['parentId']), ɵ2 = (node) => new calendar_1.Calendar(node['subject'], node['recurring'], node['created'], node['timezone'], node['start'], node['end'], node['duration'], node['organiser'], node['allDay'], node['recipients'], node['hasAttachments'], node['modified'], node['size'], node['id'], node['name'], node['lastChangeId'], node['lastChangeType'], node['parentId']), ɵ3 = (node) => new contact_1.Contact(node['emailAddress'], node['created'], node['company'], node['modified'], node['size'], node['id'], node['fullName'], node['lastChangeType'], node['parentId']), ɵ4 = (node) => new file_1.File(node['extension'], node['fullPath'], node['escapedFullPath'], node['deleted'], node['created'], node['modified'], node['size'], node['id'], node['name'], node['lastChangeId'], node['lastChangeType'], node['parentId']);
exports.ɵ0 = ɵ0;
exports.ɵ1 = ɵ1;
exports.ɵ2 = ɵ2;
exports.ɵ3 = ɵ3;
exports.ɵ4 = ɵ4;
exports.NodeFactory = {
    FOLDER: ɵ0,
    EMAIL: ɵ1,
    CALENDAR: ɵ2,
    CONTACT: ɵ3,
    FILE: ɵ4
};
