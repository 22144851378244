<form
  [formGroup]="formGroup"
  (ngSubmit)="doSearch()"
  class="{{
    verticalForm
      ? 'form-horizontal tracking-search-form-vertical'
      : 'tracking-search-form-horizontal'
  }}"
  mcDefaultLabelClass="{{ verticalForm ? 'col-sm-3' : '' }}"
  mcDefaultControlContainerClass="{{ verticalForm ? 'col-sm-9' : '' }}"
  mcErrorPrefix="$I18N_TRACKING_SEARCH_ERROR"
  novalidate
  data-test="tracking_search_by_id"
>
  <mc-text-field
    label="$I18N_TRACKING_SEARCH_LABEL_SENDER_MESSAGE_ID"
    placeholder="$I18N_TRACKING_SEARCH_PLACEHOLDER_SENDER_MESSAGE_ID"
    formControlName="messageId"
    errorPrefix="MESSAGE_ID"
    class="{{ !verticalForm ? 'col-sm-5 mc-field-margin-right no-padding-horizontal' : '' }}"
    data-test="tracking_id"
  >
  </mc-text-field>

  <mc-date-range-picker
    label="$I18N_TRACKING_SEARCH_LABEL_DATE_RANGE"
    formControlName="dateRangePicker"
    [config]="dateRangePickerConfig"
    [horizontal]="!verticalForm"
    [useCurrentMoment]="true"
    class="{{ !verticalForm ? 'col-sm-2 mc-field-margin-right no-padding-horizontal' : '' }}"
    data-test="tracking_id_date"
  >
  </mc-date-range-picker>

  <button
    type="submit"
    class="btn btn-primary {{
      verticalForm ? 'pull-right' : 'pull-left form-horizontal-submit-button'
    }}"
    [disabled]="!formGroup.valid"
    data-test="tracking_id_submit"
  >
    {{ '$I18N_TRACKING_SEARCH_BUTTON_LABEL' | translate }}
  </button>
</form>
