"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const tracking_page_component_1 = require("./containers/tracking-page.component");
angular
    .module('tracking', [])
    .controller('TrackingController', [
    '$stateParams',
    '$scope',
    function ($stateParams, $scope) {
        $scope.stateParams = $stateParams;
    }
])
    .directive('mcTracking', static_1.downgradeComponent({
    component: tracking_page_component_1.TrackingPageComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider.state('trackingMessage', {
            url: '/message-center/message-tracking',
            data: {
                isFixedLayout: false,
                checkProgress: false,
                breadcrumbs: [
                    {
                        label: '$I18N_MESSAGE_CENTER_BREADCRUMBS_FIRST_LEVEL',
                        stateName: '',
                        stateParams: {}
                    },
                    {
                        label: '$I18N_TRACKING_BREADCRUMBS_SECOND_LEVEL',
                        stateName: '',
                        stateParams: { tabName: 'overview' }
                    }
                ]
            },
            views: {
                main: {
                    template: '<mc-tracking></mc-tracking>',
                    controller: 'TrackingController'
                }
            }
        });
    }
]);
