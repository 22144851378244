"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RemediationGetGroupsFail = exports.RemediationGetGroupsNotSet = exports.RemediationGetGroupsLDAPSuccess = exports.RemediationGetGroupsCloudSuccess = exports.RemediationGetGroupsLDAP = exports.RemediationGetGroupsCloud = exports.GET_GROUPS_NOT_SET = exports.GET_GROUPS_FAIL = exports.GET_GROUPS_LDAP_SUCCESS = exports.GET_GROUPS_CLOUD_SUCCESS = exports.GET_GROUPS_LDAP = exports.GET_GROUPS_CLOUD = void 0;
exports.GET_GROUPS_CLOUD = '[Remediation] Retrieve cloud group';
exports.GET_GROUPS_LDAP = '[Remediation] Retrieve ldap group';
exports.GET_GROUPS_CLOUD_SUCCESS = '[Remediation] Retrieve cloud group success';
exports.GET_GROUPS_LDAP_SUCCESS = '[Remediation] Retrieve ldap group success';
exports.GET_GROUPS_FAIL = '[Remediation] Retrieve group fail';
exports.GET_GROUPS_NOT_SET = '[Remediation] Retrieve group not set';
class RemediationGetGroupsCloud {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_GROUPS_CLOUD;
    }
}
exports.RemediationGetGroupsCloud = RemediationGetGroupsCloud;
class RemediationGetGroupsLDAP {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_GROUPS_LDAP;
    }
}
exports.RemediationGetGroupsLDAP = RemediationGetGroupsLDAP;
class RemediationGetGroupsCloudSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_GROUPS_CLOUD_SUCCESS;
    }
}
exports.RemediationGetGroupsCloudSuccess = RemediationGetGroupsCloudSuccess;
class RemediationGetGroupsLDAPSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_GROUPS_LDAP_SUCCESS;
    }
}
exports.RemediationGetGroupsLDAPSuccess = RemediationGetGroupsLDAPSuccess;
class RemediationGetGroupsNotSet {
    constructor() {
        this.type = exports.GET_GROUPS_NOT_SET;
    }
}
exports.RemediationGetGroupsNotSet = RemediationGetGroupsNotSet;
class RemediationGetGroupsFail {
    constructor() {
        this.type = exports.GET_GROUPS_FAIL;
    }
}
exports.RemediationGetGroupsFail = RemediationGetGroupsFail;
