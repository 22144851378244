"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getManagedUrls = exports.getManagedUrlData = exports.getManagedUrlLoading = exports.getRequestPage = exports.getSearchRequest = exports.managedUrlReducer = exports.initialState = void 0;
const listActions = require("../actions/url-list.actions");
const createActions = require("../actions/url-create.actions");
const updateActions = require("../actions/url-update.actions");
const moment = require("moment");
exports.initialState = {
    items: [],
    totalCount: 0,
    searchRequest: {},
    isLoading: false,
    paginatorMetadata: { next: undefined, previous: undefined, pageSize: 50, totalRows: 0 },
    requestedPage: undefined,
    selectedPageSize: 50,
    selectedRow: undefined,
    errors: null
};
function managedUrlReducer(state = exports.initialState, action) {
    switch (action.type) {
        case createActions.OPEN_ADD_NEW_MANAGED_URL_SIDE_PANEL:
        case createActions.CLEAR_ADD_MANAGED_URL_STATE:
            return Object.assign(Object.assign({}, state), exports.initialState);
        case updateActions.OPEN_MANAGED_URL_DETAILS:
            return Object.assign(Object.assign({}, state), { selectedRow: action.payload });
        case createActions.CREATE_MANAGED_URL:
            return Object.assign(Object.assign({}, state), { items: [...action.payload], selectedRow: Object.assign({}, state.selectedRow), isLoading: true });
        case createActions.CREATE_MANAGED_URL_ATTEMPT:
            return Object.assign(Object.assign({}, state), { items: [...action.payload], selectedRow: Object.assign(Object.assign({}, state.selectedRow), { overrideUrl: true }), errors: action.err, isLoading: true });
        case createActions.CREATE_MANAGED_URL_SUCCESS:
            return Object.assign(Object.assign({}, state), { isLoading: false });
        case createActions.ALLOW_BLOCK_MANAGED_URL_SUCCESS:
            return Object.assign(Object.assign({}, state), { isLoading: false });
        case updateActions.UPDATE_MANAGED_URL:
            return Object.assign(Object.assign({}, state), { selectedRow: Object.assign(Object.assign({}, action.payload), { url: action.payload.url }), isLoading: true });
        case updateActions.UPDATE_MANAGED_URL_ATTEMPT:
            return Object.assign(Object.assign({}, state), { selectedRow: Object.assign(Object.assign({}, action.payload), { overrideUrl: true }), isLoading: true });
        case updateActions.UPDATE_MANAGED_URL_SUCCESS:
            return Object.assign(Object.assign({}, state), { isLoading: false });
        case listActions.MANAGED_URL_DELETE:
            return Object.assign(Object.assign({}, state), { selectedRow: action.payload });
        case listActions.MANAGED_URL_DELETE_ATTEMPT:
            return Object.assign(Object.assign({}, state), { selectedRow: action.payload });
        case listActions.MANAGED_URLS_OVERRIDE_DIALOG_CANCEL:
            return Object.assign(Object.assign({}, state), { isLoading: false, selectedRow: null });
        case updateActions.SELECTED_ROW:
            return Object.assign(Object.assign({}, state), { selectedRow: action.payload });
        default:
            return state;
    }
}
exports.managedUrlReducer = managedUrlReducer;
const getSearchRequest = (state) => {
    return Object.assign(Object.assign(Object.assign({}, state.searchRequest), (!state.searchRequest.start
        ? {
            start: moment()
                .subtract(2, 'days')
                .toDate()
        }
        : {})), (!state.searchRequest.end
        ? {
            end: moment().toDate()
        }
        : {}));
};
exports.getSearchRequest = getSearchRequest;
const getRequestPage = (state) => {
    if (!!state.requestedPage) {
        return Object.assign({}, state.requestedPage);
    }
    else {
        return {
            pageNumber: 0,
            pageSize: state.selectedPageSize,
            pageToken: undefined
        };
    }
};
exports.getRequestPage = getRequestPage;
const getManagedUrlLoading = (state) => state.isLoading;
exports.getManagedUrlLoading = getManagedUrlLoading;
const getManagedUrlData = (state) => state.selectedRow;
exports.getManagedUrlData = getManagedUrlData;
const getManagedUrls = (state) => state.items;
exports.getManagedUrls = getManagedUrls;
