<div class="dashboard-container">
  <div class="content-loading" *ngIf="(loading$ | async)">
    <mc-spinner>
      <!-- DO WE REALLY NEED THIS? -->
    </mc-spinner>
  </div>
  <div class="content-no-data" *ngIf="!(loading$ | async) && !(hasData$ | async)">
    <mc-no-statistics></mc-no-statistics>
  </div>
  <div class="content" *ngIf="!(loading$ | async) && (hasData$ | async)">
    <mc-dashboard-statistics 
      [statistics]="dashboardStatistics$ | async" 
      [reviewSettings]="reviewSettings" 
      [escalationSettings]="escalationSettings"
      (onOpenSettings)="openSettings($event)"> 
    </mc-dashboard-statistics>
  </div>
</div>
