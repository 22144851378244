"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteAttributeFailure = exports.DeleteAttributeSuccess = exports.ConfirmDeleteAttribute = exports.DeleteAttribute = exports.HideAttributeFailureAction = exports.HideAttributeSuccessAction = exports.HideAttributeAction = exports.DELETE_ATTRIBUTE_FAILURE = exports.DELETE_ATTRIBUTE_SUCCESS = exports.DELETE_ATTRIBUTE_CONFIRM = exports.DELETE_ATTRIBUTE = exports.HIDE_ATTRIBUTE_FAILURE = exports.HIDE_ATTRIBUTE_SUCCESS = exports.HIDE_ATTRIBUTE = void 0;
exports.HIDE_ATTRIBUTE = '[Directory Attributes]  Hide attribute';
exports.HIDE_ATTRIBUTE_SUCCESS = '[Directory Attributes]  Hide attribute success';
exports.HIDE_ATTRIBUTE_FAILURE = '[Directory Attributes]  Hide attribute failure';
exports.DELETE_ATTRIBUTE = '[Directory Attributes] delete';
exports.DELETE_ATTRIBUTE_CONFIRM = '[Directory Attributes] delete confirm';
exports.DELETE_ATTRIBUTE_SUCCESS = '[Directory Attributes] deleted';
exports.DELETE_ATTRIBUTE_FAILURE = '[Directory Attributes] failed to delete';
class HideAttributeAction {
    constructor(row) {
        this.row = row;
        this.type = exports.HIDE_ATTRIBUTE;
    }
}
exports.HideAttributeAction = HideAttributeAction;
class HideAttributeSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.HIDE_ATTRIBUTE_SUCCESS;
    }
}
exports.HideAttributeSuccessAction = HideAttributeSuccessAction;
class HideAttributeFailureAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.HIDE_ATTRIBUTE_FAILURE;
    }
}
exports.HideAttributeFailureAction = HideAttributeFailureAction;
class DeleteAttribute {
    constructor(attribute) {
        this.attribute = attribute;
        this.type = exports.DELETE_ATTRIBUTE;
    }
}
exports.DeleteAttribute = DeleteAttribute;
class ConfirmDeleteAttribute {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.DELETE_ATTRIBUTE_CONFIRM;
    }
}
exports.ConfirmDeleteAttribute = ConfirmDeleteAttribute;
class DeleteAttributeSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.DELETE_ATTRIBUTE_SUCCESS;
    }
}
exports.DeleteAttributeSuccess = DeleteAttributeSuccess;
class DeleteAttributeFailure {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.DELETE_ATTRIBUTE_FAILURE;
    }
}
exports.DeleteAttributeFailure = DeleteAttributeFailure;
