<mc-empty-results iconClass="far fa-cloud"
                  keyTitle="{{ '$I18N_IDENTITY_APPS_EMPTY_PLACEHOLDER.TITLE' | translate }}">
    <mc-empty-body>
        <p>{{ '$I18N_IDENTITY_APPS_EMPTY_PLACEHOLDER.DESCRIPTION' | translate }}</p>

        <mc-live-button
                class="mc-table-actions mc-add-app"
                overrideClasses="btn-primary"
                [label]="'$I18N_IDENTITY_APPS_EMPTY_PLACEHOLDER.BUTTONS.ADD_APP' | translate"
                [mcDisabled]="isAddBtnDisabled$ | async"
                (mcClick)="onAddClick()">
        </mc-live-button>
    </mc-empty-body>
</mc-empty-results>
