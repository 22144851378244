"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RequestTransformerInterceptor = void 0;
class RequestTransformerInterceptor {
    constructor(windowService) {
        this.windowService = windowService;
    }
    /**
     * Intercept Function
     */
    intercept(req, next) {
        if (req.body instanceof FormData || req.body instanceof ArrayBuffer) {
            return next.handle(req);
        }
        // TODO: to remove the below Override logic once UIP-2120 is completed
        const apisToSkipOverride = [
            '/u/proxy/api/login/login',
            '/u/proxy/api/login/extend-binding',
            '/u/proxy/api/login/submit-verification-code'
        ];
        let appId = this.windowService.appId;
        if (apisToSkipOverride.indexOf(req.url) !== -1) {
            appId = req.headers.get('x-mc-app-id');
        }
        const newRequest = req.clone({
            body: req.body && req.body.data ? req.body : this.transformRequest(req.body),
            setHeaders: {
                'Content-Type': req.headers.get('Content-Type') || 'application/json',
                'x-mc-app-id': appId
            }
        });
        return next.handle(newRequest);
    }
    transformRequest(requestBody) {
        if (Array.isArray(requestBody)) {
            return { data: requestBody, meta: undefined };
        }
        const newDataObject = this.getNewDataObject(requestBody);
        delete newDataObject.meta;
        return {
            data: requestBody ? [newDataObject] : [],
            meta: requestBody ? requestBody.meta : undefined
        };
    }
    getNewDataObject(requestBody) {
        const typesToAvoidTransformation = ['string', 'boolean', 'number'];
        const typeOfRequestBody = typeof requestBody;
        if (typesToAvoidTransformation.includes(typeOfRequestBody)) {
            return requestBody;
        }
        return Object.assign({}, requestBody);
    }
}
exports.RequestTransformerInterceptor = RequestTransformerInterceptor;
