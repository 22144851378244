"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateImSyncTaskComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const validators_1 = require("app-new/api-applications/validators");
class UpdateImSyncTaskComponent {
    constructor(fb) {
        this.fb = fb;
        this.update = new core_1.EventEmitter();
        this.cancel = new core_1.EventEmitter();
        this.destroy$ = new rxjs_1.Subject();
        this.changeInFormContent = false;
        this.form = this.fb.group({
            name: [
                '',
                [
                    forms_1.Validators.required,
                    forms_1.Validators.minLength(3),
                    forms_1.Validators.maxLength(60),
                    validators_1.validateNotEmpty,
                    forms_1.Validators.pattern(/^[^\s]+(\s+[^\s]+)*$/)
                ]
            ],
            description: [
                '',
                [
                    forms_1.Validators.minLength(3),
                    forms_1.Validators.maxLength(1024),
                    forms_1.Validators.pattern(/^[^\s]+(\s+[^\s]+)*$/)
                ]
            ]
        });
    }
    ngOnChanges(changes) {
        if (changes.imSyncTaskDetails && changes.imSyncTaskDetails.currentValue) {
            this.updateEditForm(this.imSyncTaskDetails);
            this.onFormChange();
        }
    }
    updateEditForm(value) {
        Object.keys(value).forEach(name => {
            if (this.form.controls[name]) {
                this.form.controls[name].patchValue(value[name]);
            }
        });
        this.onFormChange();
    }
    updateImSyncTask() {
        this.update.emit({
            id: this.imSyncTaskDetails.id,
            name: this.form.controls['name'].value,
            description: this.formatDescription()
        });
        this.changeInFormContent = false;
    }
    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
    isEditDisabled() {
        return !this.form.valid || !this.changeInFormContent;
    }
    onFormChange() {
        this.changeInFormContent = false;
        this.form.valueChanges.pipe(operators_1.takeUntil(this.destroy$)).subscribe(() => {
            this.changeInFormContent = true;
        });
    }
    formatDescription() {
        return !!this.form.controls['description'].value
            ? this.form.controls['description'].value
            : null;
    }
}
exports.UpdateImSyncTaskComponent = UpdateImSyncTaskComponent;
