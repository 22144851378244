"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgTUIDAttributeOption = exports.SwgDirectIPProtectionMode = exports.SwgSettingsPasswordType = void 0;
var SwgSettingsPasswordType;
(function (SwgSettingsPasswordType) {
    SwgSettingsPasswordType["ANTI_TAMPER"] = "ANTI_TAMPER";
    SwgSettingsPasswordType["UNINSTALL"] = "UNINSTALL";
})(SwgSettingsPasswordType = exports.SwgSettingsPasswordType || (exports.SwgSettingsPasswordType = {}));
var SwgDirectIPProtectionMode;
(function (SwgDirectIPProtectionMode) {
    SwgDirectIPProtectionMode["ENABLED"] = "enabled";
    SwgDirectIPProtectionMode["DISABLED"] = "disabled";
    SwgDirectIPProtectionMode["MONITORING"] = "monitored";
})(SwgDirectIPProtectionMode = exports.SwgDirectIPProtectionMode || (exports.SwgDirectIPProtectionMode = {}));
var SwgTUIDAttributeOption;
(function (SwgTUIDAttributeOption) {
    SwgTUIDAttributeOption["MAIL"] = "mail";
    SwgTUIDAttributeOption["USER_PRINCIPAL_NAME"] = "userPrincipalName";
})(SwgTUIDAttributeOption = exports.SwgTUIDAttributeOption || (exports.SwgTUIDAttributeOption = {}));
