"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiemChannelSideBarComponent = void 0;
const core_1 = require("@angular/core");
class SiemChannelSideBarComponent {
    constructor() {
        this.deleteChannel = new core_1.EventEmitter();
        this.editChannel = new core_1.EventEmitter();
        this.close = new core_1.EventEmitter();
    }
}
exports.SiemChannelSideBarComponent = SiemChannelSideBarComponent;
