<mc-layout-dashboard-simple>
  <mc-body-container>

    <div class="row">
      <div class="col-xs-12">
        <div class="form-group pull-right">
          <span class="mc-date-range-label">Date Range</span>
          <div class="btn-group">
            <select id="datePickerRanges" (change)="onChange($event)" class="form-control mc-date-range-select">
              <option *ngFor="let dateRange of dateRangeConfig.dateRanges"
                      [ngValue]="dateRange">{{ dateRange.label | translate}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="clpIntegrationEnabled">
      <div class="col-xs-4 panel-half-padding-right">
        <div class="mc-list-panel" data-test="swgApplicationDashboardThreatDetections">
          <div class="mc-list-panel-heading">
            {{ '$I18N_SWG_APPLICATIONS_DASHBOARD.CFP_THREAT_DETECTION' | translate }}
          </div>

          <mc-swg-threat-detections
            [loading]="verdictsDistributedByCloudAppsLoading"
            [verdictsDistributedByCloudApps]="verdictsDistributedByCloudApps"
          ></mc-swg-threat-detections>
        </div>
      </div>

      <div class="col-xs-4 panel-half-padding-right panel-half-padding-left">
        <div class="mc-list-panel" data-test="swgApplicationDashboardDetectionTimeline">
          <div class="mc-list-panel-heading">
            {{ '$I18N_SWG_APPLICATIONS_DASHBOARD.CFP_DETECTION_FILE_TYPES' | translate }}
          </div>

          <mc-swg-applications-chart-with-control
            [chartData]="verdictsByDates"
            [loading]="verdictsByDatesLoading"
            [chartType]="'multi-line'"
            [chartName]="'malicious_file_by_date'"
            [noDataMessage]="'$I18N_SWG_APPLICATIONS_DASHBOARD_CHART_NO_DATA'"
          ></mc-swg-applications-chart-with-control>
        </div>
      </div>

      <div class="col-xs-4 panel-half-padding-left">
        <div class="mc-list-panel" data-test="swgApplicationDashboardScannedFileTypes">
          <div class="mc-list-panel-heading">
            {{ '$I18N_SWG_APPLICATIONS_DASHBOARD.CFP_SCANNED_FILE_TYPES' | translate }}
          </div>
          <mc-swg-applications-chart-with-control
            [chartData]="convertFileTypesToLabels(scanCountByFileType)"
            [config]="pieChartConfiguration"
            [loading]="scanCountByFileTypeLoading"
            [chartType]="'pie'"
            [chartName]="'scanned_files_by_type'"
            [noDataMessage]="'$I18N_SWG_APPLICATIONS_DASHBOARD_CHART_NO_DATA'"
          ></mc-swg-applications-chart-with-control>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-4 panel-half-padding-right">
        <div class="mc-list-panel" data-test="swgApplicationDashboardSanctionedApplications">
          <div class="mc-list-panel-heading">
            {{ '$I18N_SWG_APPLICATIONS_DASHBOARD.SANCTIONED_APPLICATIONS' | translate }}
          </div>
          <div class="mc-list-panel-body text-center">
            <ng-template [ngIf]="!isChartDataLoading" [ngIfElse]="widgetSanctionedAppSpinner">
                <span class="mc-clickable-widget" (click)="onWidgetClick(_applicationSanction.SANCTION)">
                    <span>
                        <i class="fas fa-check-circle mc-sanctioned-app-icon"></i>
                      </span>
                      <span class="mc-panel-numbers">
                        {{sanctionedAppsCount}}
                      </span>
                    </span>
              <p class="mc-widget-description">
                {{ '$I18N_SWG_APPLICATIONS_DASHBOARD.WIDGET_DESC_SANCTIONED_APPS' | translate }}
              </p>
            </ng-template>

            <ng-template #widgetSanctionedAppSpinner>
              <mc-spinner class="mc-widget-spinner"></mc-spinner>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="col-xs-4 panel-half-padding-right panel-half-padding-left">
        <div class="mc-list-panel" data-test="swgApplicationDashboardNonSanctionedApplications">
          <div class="mc-list-panel-heading">
            {{ '$I18N_SWG_APPLICATIONS_DASHBOARD.NONSANCTIONED_APPLICATIONS' | translate }}
          </div>
          <div class="mc-list-panel-body text-center">
            <ng-template [ngIf]="!isChartDataLoading" [ngIfElse]="widgetSanctionedAppSpinner">
                    <span class="mc-clickable-widget" (click)="onWidgetClick(_applicationSanction.NONE)">
                        <span><i class="fas fa-question-circle mc-non-sanctioned-app-icon"></i></span>
                        <span class="mc-panel-numbers">
                          {{nonSanctionedAppsCount}}
                        </span>
                    </span>
              <p class="mc-widget-description">
                {{ '$I18N_SWG_APPLICATIONS_DASHBOARD.WIDGET_DESC_NONSANCTIONED_APPS' | translate }}
              </p>
            </ng-template>
            <ng-template #widgetSanctionedAppSpinner>
              <mc-spinner class="mc-widget-spinner"></mc-spinner>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="col-xs-4 panel-half-padding-left">
        <div class="mc-list-panel" data-test="swgApplicationDashboardMonitoredApplications">
          <div class="mc-list-panel-heading">
            {{ '$I18N_SWG_APPLICATIONS_DASHBOARD.MONITORED_APPLICATIONS' | translate }}
          </div>
          <div class="mc-list-panel-body text-center">
            <ng-template [ngIf]="!isChartDataLoading" [ngIfElse]="widgetSanctionedAppSpinner">
                    <span class="mc-clickable-widget" (click)="onWidgetClick(_applicationSanction.MONITOR)">
                        <span><i class="far fa-search mc-monitored-app-icon"></i></span>
                        <span class="mc-panel-numbers">
                          {{monitoredAppsCount}}
                        </span>
                    </span>
              <p class="mc-widget-description">
                {{ '$I18N_SWG_APPLICATIONS_DASHBOARD.WIDGET_DESC_MONITORED_APPS' | translate }}
              </p>
            </ng-template>
            <ng-template #widgetSanctionedAppSpinner>
              <mc-spinner class="mc-widget-spinner"></mc-spinner>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 panel-half-padding-right">
        <div class="mc-list-panel" data-test="swgApplicationDashboardTop10SanctionedApplications">
          <div class="mc-list-panel-heading">
            {{ '$I18N_SWG_APPLICATIONS_DASHBOARD.TOP10.SANCTIONED_APPLICATIONS' | translate }}
          </div>

          <mc-swg-applications-chart-with-control
            [chartData]="topSanctionedApps"
            [config]="sanctionedAppConfig"
            [loading]="isChartDataLoading"
            [chartName]="'top10_sanctioned_apps'"
            [noDataMessage]="'$I18N_SWG_APPLICATIONS_DASHBOARD_CLOUD_APP_CHART_NO_DATA'"
          ></mc-swg-applications-chart-with-control>

        </div>
      </div>

      <div class="col-xs-4 panel-half-padding-right panel-half-padding-left">
        <div class="mc-list-panel" data-test="swgApplicationDashboardTop10NonSanctionedApplications">
          <div class="mc-list-panel-heading">
            {{ '$I18N_SWG_APPLICATIONS_DASHBOARD.TOP10.NONSANCTIONED_APPLICATIONS' | translate }}
          </div>
          <mc-swg-applications-chart-with-control
            [chartData]="topNonSanctionedApps"
            [config]="nonSanctionedAppConfig"
            [loading]="isChartDataLoading"
            [chartName]="'top10_nonsanctioned_apps'"
            [noDataMessage]="'$I18N_SWG_APPLICATIONS_DASHBOARD_CLOUD_APP_CHART_NO_DATA'"
          ></mc-swg-applications-chart-with-control>

        </div>
      </div>

      <div class="col-xs-4 panel-half-padding-left">
        <div class="mc-list-panel" data-test="swgApplicationDashboardTop10MonitoredApplications">
          <div class="mc-list-panel-heading">
            {{ '$I18N_SWG_APPLICATIONS_DASHBOARD.TOP10.MONITORED_APPLICATIONS' | translate }}
          </div>

          <mc-swg-applications-chart-with-control
            [chartData]="topMonitoredApps"
            [config]="monitoredAppConfig"
            [loading]="isChartDataLoading"
            [chartName]="'top10_monitored_apps'"
            [noDataMessage]="'$I18N_SWG_APPLICATIONS_DASHBOARD_CLOUD_APP_CHART_NO_DATA'"
          ></mc-swg-applications-chart-with-control>

        </div>
      </div>
    </div>

    <div class="row">

      <div class="col-xs-4 panel-half-padding-right">
        <div class="mc-list-panel" data-test="swgApplicationDashboardTop10ApplicationCategories">
          <div class="mc-list-panel-heading">
            {{ '$I18N_SWG_APPLICATIONS_DASHBOARD.TOP10.CATEGORIES' | translate }}
          </div>

          <mc-swg-applications-chart-with-control
            [chartData]="topCategories"
            [config]="categoriesConfig"
            [loading]="isChartDataLoading"
            [chartName]="'top10_categories'"
            [noDataMessage]="'$I18N_SWG_APPLICATIONS_DASHBOARD_CLOUD_APP_CHART_NO_DATA'"
          ></mc-swg-applications-chart-with-control>

        </div>
      </div>

      <div class="col-xs-4 panel-half-padding-right panel-half-padding-left">
        <div class="mc-list-panel" data-test="swgApplicationDashboardTop10BlockedApplications">
          <div class="mc-list-panel-heading">
            {{ '$I18N_SWG_APPLICATIONS_DASHBOARD.TOP10.APPLICATIONS_BLOCKED' | translate }}
          </div>

          <mc-swg-applications-chart-with-control
            [chartData]="topBlockedApps"
            [config]="blockedAppConfig"
            [loading]="isChartDataLoading"
            [chartName]="'top10_blocked_apps'"
            [noDataMessage]="'$I18N_SWG_APPLICATIONS_DASHBOARD_CLOUD_APP_CHART_NO_DATA'"
          ></mc-swg-applications-chart-with-control>

        </div>
      </div>


      <div class="col-xs-4 panel-half-padding-left">
        <div class="mc-list-panel mc-table-panel" data-test="swgApplicationDashboardTop10BlockedUsers">
          <div class="mc-list-panel-heading" *ngIf="hasSwgFull">
            {{ '$I18N_SWG_APPLICATIONS_DASHBOARD.TOP10.BLOCKED_CLOUD_APP_USERS' | translate }}
          </div>
          <div class="mc-list-panel-heading" *ngIf="!hasSwgFull">
            {{ '$I18N_SWG_APPLICATIONS_DASHBOARD.TOP10.BLOCKED_LOCATIONS' | translate }}
          </div>
          <div class="row">
            <div class="col-xs-12">
              <mc-tab-group>
                <mc-tab name="User" *ngIf="hasSwgFull">
                  <div *ngIf="topProfiles !== undefined && topProfiles.users !== undefined && topProfiles.users.length > 0; else noUserData">
                    <mc-table
                      [isLoading]="topProfilesLoading"
                      [data]=" topProfiles !== undefined ? topProfiles.users : []"
                      [columns]="userTablecolumns"
                      translatePrefix="$I18N_SWG_APPLICATIONS_DASHBOARD.TABLE_USER"
                      (rowClick)="onRowClick($event,'User')"
                    >
                      <mc-column key="user">
                        <mc-body-cell *mcBodyCellDef="let row">{{ row.user || '-' }}</mc-body-cell>
                      </mc-column>
                      <mc-column key="count">
                        <mc-body-cell *mcBodyCellDef="let row">{{ row.count || '-' }}</mc-body-cell>
                      </mc-column>
                    </mc-table>
                  </div>

                </mc-tab>
                <mc-tab name="Device" *ngIf="hasSwgFull">
                  <div *ngIf="topProfiles !== undefined && topProfiles.devices !== undefined && topProfiles.devices.length > 0; else noDeviceData">
                    <mc-table
                      [isLoading]="topProfilesLoading"
                      [data]="topProfiles !== undefined ? topProfiles.devices : []"
                      [columns]="deviceTablecolumns"
                      translatePrefix="$I18N_SWG_APPLICATIONS_DASHBOARD.TABLE_DEVICE"
                      (rowClick)="onRowClick($event,'Device Name')"
                    >
                      <mc-column key="device">
                        <mc-body-cell *mcBodyCellDef="let row">{{ row.device || '-' }}</mc-body-cell>
                      </mc-column>
                      <mc-column key="count">
                        <mc-body-cell *mcBodyCellDef="let row">{{ row.count || '-' }}</mc-body-cell>
                      </mc-column>
                    </mc-table>
                  </div>


                </mc-tab>
                <mc-tab name="IP">
                  <div *ngIf="topProfiles !== undefined && topProfiles.ips !== undefined && topProfiles.ips.length > 0; else noIpData">
                    <mc-table
                      [isLoading]="topProfilesLoading"
                      [data]=" topProfiles !== undefined ? topProfiles.ips : []"
                      [columns]="ipTablecolumns"
                      translatePrefix="$I18N_SWG_APPLICATIONS_DASHBOARD.TABLE_IP"
                      (rowClick)="onRowClick($event,'Public IP')"
                    >
                      <mc-column key="ip">
                        <mc-body-cell *mcBodyCellDef="let row">{{ row.ip || '-' }}</mc-body-cell>
                      </mc-column>
                      <mc-column key="count">
                        <mc-body-cell *mcBodyCellDef="let row">{{ row.count || '-' }}</mc-body-cell>
                      </mc-column>

                    </mc-table>
                  </div>

                </mc-tab>
              </mc-tab-group>

            </div>
            <ng-template #noUserData>
              <mc-swg-chart-no-data-message [message]="'$I18N_SWG_APPLICATIONS_DASHBOARD_CLOUD_APP_CHART_NO_DATA'"
                                            [icon]="'fa-users'"
                                            [messageHeading]="'$I18N_SWG_APPLICATIONS_DASHBOARD_CLOUD_APP_NO_DATA_USERS'">

              </mc-swg-chart-no-data-message>
            </ng-template>
            <ng-template #noDeviceData>
              <mc-swg-chart-no-data-message [message]="'$I18N_SWG_APPLICATIONS_DASHBOARD_CLOUD_APP_CHART_NO_DATA'"
                                            [icon]="'fa-laptop'"
                                            [messageHeading]="'$I18N_SWG_APPLICATIONS_DASHBOARD_CLOUD_APP_NO_DATA_DEVICES'">

              </mc-swg-chart-no-data-message>
            </ng-template>
            <ng-template #noIpData>
              <mc-swg-chart-no-data-message [message]="'$I18N_SWG_APPLICATIONS_DASHBOARD_CLOUD_APP_CHART_NO_DATA'"
                                            [icon]="'fa-server'"
                                            [messageHeading]="'$I18N_SWG_APPLICATIONS_DASHBOARD_CLOUD_APP_NO_DATA_IPS'">

              </mc-swg-chart-no-data-message>
            </ng-template>
          </div>
        </div>
      </div>

    </div>
  </mc-body-container>
</mc-layout-dashboard-simple>
