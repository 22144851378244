"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UrlFileDownloadOptions = exports.SenderMailboxFallbackActionOptions = exports.SenderMailboxActionOptions = exports.UrlCategoryScanningModeOptions = exports.UrlScanningModeOptions = void 0;
var UrlScanningModeOptions;
(function (UrlScanningModeOptions) {
    UrlScanningModeOptions["AGGRESSIVE"] = "aggressive";
    UrlScanningModeOptions["MODERATE"] = "moderate";
    UrlScanningModeOptions["RELAXED"] = "relaxed";
})(UrlScanningModeOptions = exports.UrlScanningModeOptions || (exports.UrlScanningModeOptions = {}));
var UrlCategoryScanningModeOptions;
(function (UrlCategoryScanningModeOptions) {
    UrlCategoryScanningModeOptions["AGGRESSIVE"] = "aggressive";
    UrlCategoryScanningModeOptions["MODERATE"] = "moderate";
    UrlCategoryScanningModeOptions["RELAXED"] = "relaxed";
})(UrlCategoryScanningModeOptions = exports.UrlCategoryScanningModeOptions || (exports.UrlCategoryScanningModeOptions = {}));
var SenderMailboxActionOptions;
(function (SenderMailboxActionOptions) {
    SenderMailboxActionOptions["REMOVE"] = "remove";
    SenderMailboxActionOptions["NO_ACTION"] = "none";
})(SenderMailboxActionOptions = exports.SenderMailboxActionOptions || (exports.SenderMailboxActionOptions = {}));
var SenderMailboxFallbackActionOptions;
(function (SenderMailboxFallbackActionOptions) {
    SenderMailboxFallbackActionOptions["REMOVE"] = "remove";
    SenderMailboxFallbackActionOptions["NO_ACTION"] = "none";
})(SenderMailboxFallbackActionOptions = exports.SenderMailboxFallbackActionOptions || (exports.SenderMailboxFallbackActionOptions = {}));
var UrlFileDownloadOptions;
(function (UrlFileDownloadOptions) {
    UrlFileDownloadOptions["CHECK"] = "check";
    UrlFileDownloadOptions["NO_ACTION"] = "none";
})(UrlFileDownloadOptions = exports.UrlFileDownloadOptions || (exports.UrlFileDownloadOptions = {}));
