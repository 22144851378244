"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDetailsPaginationInfo = exports.getruleDetails = exports.getFailure = exports.getLoading = exports.reducer = void 0;
const rule_details_actions_1 = require("../actions/rule.details.actions");
const initialState = {
    loading: false,
    failure: null,
    rule: null,
    ruleDetailsPaginationInfo: null
};
function reducer(state = initialState, action) {
    switch (action.type) {
        case rule_details_actions_1.RuleDetailsActions.GET_RULE_DETAILS:
            return Object.assign(Object.assign({}, state), { loading: true });
        case rule_details_actions_1.RuleDetailsActions.GET_RULE_DETAILS_FAIL:
            return Object.assign(Object.assign({}, state), { loading: false, failure: action.failure ? action.failure : undefined });
        case rule_details_actions_1.RuleDetailsActions.GET_RULE_DETAILS_SUCCESS:
            return Object.assign(Object.assign({}, state), { loading: false, rule: action.rule });
        case rule_details_actions_1.RuleDetailsActions.SET_RULE_DETAILS_PAGINATION_INFO:
            return Object.assign(Object.assign({}, state), { ruleDetailsPaginationInfo: {
                    previousEntity: action.previousRule ? action.previousRule : undefined,
                    currentEntity: action.currentRule ? action.currentRule : undefined,
                    nextEntity: action.nextRule ? action.nextRule : undefined,
                    currentEntityIndex: action.currentRuleIndex,
                    totalNumberEntities: action.totalNumberRules
                } });
        case rule_details_actions_1.RuleDetailsActions.CLEAN_RULE_DETAILS:
            return Object.assign(Object.assign({}, state), { rule: undefined, ruleDetailsPaginationInfo: undefined });
        case rule_details_actions_1.RuleDetailsActions.SET_RULE_DETAILS_STATE:
            return state.rule && state.rule.id === action.ruleId
                ? Object.assign(Object.assign({}, state), { rule: Object.assign(Object.assign({}, state.rule), { state: action.ruleState }) }) : state;
        default:
            return state;
    }
}
exports.reducer = reducer;
const getLoading = (state) => state && state.loading;
exports.getLoading = getLoading;
const getFailure = (state) => state && state.failure;
exports.getFailure = getFailure;
const getruleDetails = (state) => state && state.rule;
exports.getruleDetails = getruleDetails;
const getDetailsPaginationInfo = (state) => state && state.ruleDetailsPaginationInfo;
exports.getDetailsPaginationInfo = getDetailsPaginationInfo;
