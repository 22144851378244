"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDescription = exports.getInfo = exports.reducer = exports.initialState = void 0;
const actions = require("../actions");
exports.initialState = {
    connectionInfo: {}
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.DIRECTORY_CONNECTION:
            return Object.assign(Object.assign({}, state), { connectionInfo: action.data });
        case actions.CLEAR_DIRECTORY_CONNECTION:
            return Object.assign({}, exports.initialState);
        default:
            return state;
    }
}
exports.reducer = reducer;
const getInfo = (state) => {
    return state.connectionInfo;
};
exports.getInfo = getInfo;
const getDescription = (state) => {
    return state.connectionInfo && state.connectionInfo.description;
};
exports.getDescription = getDescription;
