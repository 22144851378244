"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UrlScanGraphComponent = void 0;
const core_1 = require("@angular/core");
const themeable_chart_component_1 = require("../common/themeable-chart.component");
const charts_1 = require("@mimecast-ui/charts");
const am4core = require("@amcharts/amcharts4/core");
const widget_urls_scan_day_mock_1 = require("../../mocks/widget-urls-scan-day.mock");
const urls_scan_graph_static_values_1 = require("./urls-scan-graph.static-values");
class UrlScanGraphComponent extends themeable_chart_component_1.ThemeableChartComponentDirective {
    constructor(translate) {
        super();
        this.translate = translate;
        this.chartSeriesToHide = [];
        this.chartReference = new core_1.EventEmitter();
        this.lightThemeLabelColor = charts_1.chartThemePalette.gray60;
        this.lightThemeGridColor = charts_1.chartThemePalette.gray10;
        this.darkThemeLabelColor = charts_1.chartThemePalette.gray30;
        this.darkThemeGridColor = charts_1.chartThemePalette.gray80;
        this.seriesType = 'LineSeries';
        this.seriesDateX = 'date';
        this.seriesBulletsType = 'CircleBullet';
        this.chartData = widget_urls_scan_day_mock_1.WIDGET_URLS_SCAN_DAY_MOCK_PARSED;
        this.exportFormats = urls_scan_graph_static_values_1.ExportDownloadFormats;
        this.urlsScannedPerDayChart = urls_scan_graph_static_values_1.urlsScannedPerDay;
        this.chartLabelFormat = 'dd MMM';
        this.inboundSeriesConfig = this.prepareInboundSeriesConfig();
        this.maliciousInboundSeriesConfig = this.prepareMaliciousInboundSeriesConfig();
        this.outboundSeriesConfig = this.prepareOutboundSeriesConfig();
        this.maliciousOutboundSeriesConfig = this.prepareMaliciousOutboundSeriesConfig();
        this.journalSeriesConfig = this.prepareJournalSeriesConfig();
        this.maliciousJournalSeriesConfig = this.prepareMaliciousJournalSeriesConfig();
    }
    get timeUnit() {
        return this.selectedPeriod === '24h' ? 'hour' : 'day';
    }
    setXYandLegendConfig() {
        return {
            xAxes: [
                {
                    renderer: {
                        labels: {
                            template: {
                                fill: this.theme === charts_1.AvailableAmChartsThemes.GEN2_DARK
                                    ? this.darkThemeLabelColor
                                    : this.lightThemeLabelColor,
                                paddingTop: '10px',
                                paddingRight: '20px'
                            }
                        },
                        line: {
                            strokeOpacity: 1,
                            strokeWidth: 1.5,
                            stroke: this.theme === charts_1.AvailableAmChartsThemes.GEN2_DARK
                                ? this.darkThemeGridColor
                                : this.lightThemeGridColor
                        },
                        ticks: {
                            template: {
                                disabled: false,
                                strokeOpacity: 1,
                                stroke: this.theme === charts_1.AvailableAmChartsThemes.GEN2_DARK
                                    ? this.darkThemeGridColor
                                    : this.lightThemeGridColor,
                                strokeWidth: 1.5,
                                length: 5,
                                paddingTop: '0'
                            }
                        }
                    },
                    dateFormats: {
                        day: this.chartLabelFormat
                    },
                    periodChangeDateFormats: {
                        day: this.chartLabelFormat,
                        hour: this.chartLabelFormat
                    },
                    type: 'DateAxis',
                    dataFields: {
                        category: 'date'
                    },
                    baseInterval: {
                        timeUnit: this.timeUnit,
                        count: 0.9
                    }
                }
            ],
            yAxes: [
                {
                    title: {
                        text: this.translate.instant('$I18N_SERVICES_URL_PROTECTION_DASHBOARD.URLS_SCANNED_PER_DAY.TITLE'),
                        fill: this.theme === charts_1.AvailableAmChartsThemes.GEN2_DARK
                            ? this.darkThemeLabelColor
                            : this.lightThemeLabelColor,
                        paddingRight: '30px',
                        paddingBottom: '15px'
                    },
                    renderer: {
                        grid: {
                            stroke: this.theme === charts_1.AvailableAmChartsThemes.GEN2_DARK
                                ? this.darkThemeGridColor
                                : this.lightThemeGridColor
                        },
                        line: {
                            strokeOpacity: 1,
                            strokeWidth: 1.5,
                            stroke: this.theme === charts_1.AvailableAmChartsThemes.GEN2_DARK
                                ? this.darkThemeGridColor
                                : this.lightThemeGridColor
                        },
                        baseGrid: {
                            strokeOpacity: 1,
                            stroke: this.theme === charts_1.AvailableAmChartsThemes.GEN2_DARK
                                ? this.darkThemeGridColor
                                : this.lightThemeGridColor,
                            strokeWidth: 1.5,
                            paddingTop: '3px'
                        },
                        labels: {
                            template: {
                                disabled: true
                            }
                        }
                    },
                    strictMinMax: false,
                    maxPrecision: 0,
                    min: 0,
                    type: 'ValueAxis',
                    cursorTooltipEnabled: false,
                    paddingLeft: '0px',
                    paddingRight: 0
                }
            ],
            legend: {
                useDefaultMarker: false,
                paddingTop: 15,
                labels: {
                    template: {
                        fill: this.theme === charts_1.AvailableAmChartsThemes.GEN2_DARK
                            ? this.darkThemeLabelColor
                            : this.lightThemeLabelColor
                    }
                },
                itemContainers: {
                    clickable: true,
                    focusable: true,
                    cursorOverStyle: 'default'
                },
                markers: {
                    disabled: true
                }
            }
        };
    }
    setInboundSeries() {
        return [
            {
                type: this.seriesType,
                name: this.inboundSeriesConfig.name,
                legendSettings: {
                    labelText: `[bold ${this.inboundSeriesConfig.color} font-size: 16px]⚬[/] ${this.inboundSeriesConfig.name}`
                },
                sequencedInterpolation: true,
                stroke: this.inboundSeriesConfig.color,
                strokeWidth: 3,
                fill: this.inboundSeriesConfig.hex,
                fillOpacity: 0.001,
                // @ts-ignore
                adapter: null,
                dataFields: {
                    valueY: urls_scan_graph_static_values_1.yValueKeys.inbound,
                    dateX: this.seriesDateX
                },
                dummyData: {
                    stroke: this.inboundSeriesConfig.color,
                    tooltipLabel: `${this.inboundSeriesConfig.name}:`,
                    score: urls_scan_graph_static_values_1.yValueKeys.inbound
                },
                bullets: [
                    {
                        type: this.seriesBulletsType,
                        stroke: this.inboundSeriesConfig.color,
                        strokeWidth: 1.5,
                        strokeOpacity: 0,
                        fillOpacity: 0,
                        propertyFields: {
                            fillOpacity: 'opacity'
                        },
                        states: {
                            hover: {
                                properties: {
                                    fillOpacity: 1,
                                    strokeOpacity: 1
                                }
                            }
                        }
                    }
                ],
                zIndex: 10
            },
            {
                type: this.seriesType,
                name: this.maliciousInboundSeriesConfig.name,
                legendSettings: {
                    labelText: `[bold ${this.maliciousInboundSeriesConfig.color} font-size: 16px]⚬[/] ${this.maliciousInboundSeriesConfig.name}`
                },
                sequencedInterpolation: true,
                stroke: this.maliciousInboundSeriesConfig.color,
                strokeWidth: 3,
                fill: this.maliciousInboundSeriesConfig.hex,
                fillOpacity: 0.001,
                dataFields: {
                    valueY: urls_scan_graph_static_values_1.yValueKeys.maliciousInbound,
                    dateX: this.seriesDateX
                },
                dummyData: {
                    stroke: this.maliciousInboundSeriesConfig.color,
                    tooltipLabel: `${this.maliciousInboundSeriesConfig.name}:`,
                    score: urls_scan_graph_static_values_1.yValueKeys.maliciousInbound
                },
                bullets: [
                    {
                        type: this.seriesBulletsType,
                        stroke: this.maliciousInboundSeriesConfig.color,
                        strokeWidth: 1.5,
                        strokeOpacity: 0,
                        fillOpacity: 0,
                        propertyFields: {
                            fillOpacity: 'opacity'
                        },
                        states: {
                            hover: {
                                properties: {
                                    fillOpacity: 1,
                                    strokeOpacity: 1
                                }
                            }
                        }
                    }
                ]
            }
        ];
    }
    setOutboundSeries() {
        return [
            {
                type: this.seriesType,
                name: this.outboundSeriesConfig.name,
                legendSettings: {
                    labelText: `[bold ${this.outboundSeriesConfig.color} font-size: 16px]⚬[/] ${this.outboundSeriesConfig.name}`
                },
                sequencedInterpolation: true,
                stroke: this.outboundSeriesConfig.color,
                strokeWidth: 3,
                fill: this.outboundSeriesConfig.hex,
                fillOpacity: 0.001,
                dataFields: {
                    valueY: urls_scan_graph_static_values_1.yValueKeys.outbound,
                    dateX: this.seriesDateX
                },
                dummyData: {
                    stroke: this.outboundSeriesConfig.color,
                    tooltipLabel: `${this.outboundSeriesConfig.name}:`,
                    score: urls_scan_graph_static_values_1.yValueKeys.outbound
                },
                bullets: [
                    {
                        type: this.seriesBulletsType,
                        stroke: this.outboundSeriesConfig.color,
                        strokeWidth: 1.5,
                        strokeOpacity: 0,
                        fillOpacity: 0,
                        propertyFields: {
                            fillOpacity: 'opacity'
                        },
                        states: {
                            hover: {
                                properties: {
                                    fillOpacity: 1,
                                    strokeOpacity: 1
                                }
                            }
                        }
                    }
                ]
            },
            {
                type: this.seriesType,
                name: this.maliciousOutboundSeriesConfig.name,
                legendSettings: {
                    labelText: `[bold ${this.maliciousOutboundSeriesConfig.color} font-size: 16px]⚬[/] ${this.maliciousOutboundSeriesConfig.name}`
                },
                sequencedInterpolation: true,
                stroke: this.maliciousOutboundSeriesConfig.color,
                strokeWidth: 3,
                fill: this.maliciousOutboundSeriesConfig.hex,
                fillOpacity: 0.001,
                dataFields: {
                    valueY: urls_scan_graph_static_values_1.yValueKeys.maliciousOutbound,
                    dateX: this.seriesDateX
                },
                dummyData: {
                    stroke: this.maliciousOutboundSeriesConfig.color,
                    tooltipLabel: `${this.maliciousOutboundSeriesConfig.name}:`,
                    score: urls_scan_graph_static_values_1.yValueKeys.maliciousOutbound
                },
                bullets: [
                    {
                        type: this.seriesBulletsType,
                        stroke: this.maliciousOutboundSeriesConfig.color,
                        strokeWidth: 1.5,
                        strokeOpacity: 0,
                        fillOpacity: 0,
                        propertyFields: {
                            fillOpacity: 'opacity'
                        },
                        states: {
                            hover: {
                                properties: {
                                    fillOpacity: 1,
                                    strokeOpacity: 1
                                }
                            }
                        }
                    }
                ]
            }
        ];
    }
    setJournalSeries() {
        return [
            {
                type: this.seriesType,
                name: this.journalSeriesConfig.name,
                legendSettings: {
                    labelText: `[bold ${this.journalSeriesConfig.color} font-size: 16px]⚬[/] ${this.journalSeriesConfig.name}`
                },
                sequencedInterpolation: true,
                stroke: this.journalSeriesConfig.color,
                strokeWidth: 3,
                fill: this.journalSeriesConfig.hex,
                fillOpacity: 0.001,
                dataFields: {
                    valueY: urls_scan_graph_static_values_1.yValueKeys.journal,
                    dateX: this.seriesDateX
                },
                dummyData: {
                    stroke: this.journalSeriesConfig.color,
                    tooltipLabel: `${this.journalSeriesConfig.name}:`,
                    score: urls_scan_graph_static_values_1.yValueKeys.journal
                },
                bullets: [
                    {
                        type: this.seriesBulletsType,
                        stroke: this.journalSeriesConfig.color,
                        strokeWidth: 1.5,
                        strokeOpacity: 0,
                        fillOpacity: 0,
                        propertyFields: {
                            fillOpacity: 'opacity'
                        },
                        states: {
                            hover: {
                                properties: {
                                    fillOpacity: 1,
                                    strokeOpacity: 1
                                }
                            }
                        }
                    }
                ]
            },
            {
                type: this.seriesType,
                name: this.maliciousJournalSeriesConfig.name,
                legendSettings: {
                    labelText: `[bold ${this.maliciousJournalSeriesConfig.color} font-size: 16px]⚬[/] ${this.maliciousJournalSeriesConfig.name}`
                },
                sequencedInterpolation: true,
                stroke: this.maliciousJournalSeriesConfig.color,
                strokeWidth: 3,
                fill: this.maliciousJournalSeriesConfig.hex,
                fillOpacity: 0.001,
                dataFields: {
                    valueY: urls_scan_graph_static_values_1.yValueKeys.maliciousJournal,
                    dateX: this.seriesDateX
                },
                dummyData: {
                    stroke: this.maliciousJournalSeriesConfig.color,
                    tooltipLabel: `${this.maliciousJournalSeriesConfig.name}:`,
                    score: urls_scan_graph_static_values_1.yValueKeys.maliciousJournal
                },
                bullets: [
                    {
                        type: this.seriesBulletsType,
                        stroke: this.maliciousJournalSeriesConfig.color,
                        strokeWidth: 1.5,
                        strokeOpacity: 0,
                        fillOpacity: 0,
                        propertyFields: {
                            fillOpacity: 'opacity'
                        },
                        states: {
                            hover: {
                                properties: {
                                    fillOpacity: 1,
                                    strokeOpacity: 1
                                }
                            }
                        }
                    }
                ]
            }
        ];
    }
    get config() {
        const chartConfig = Object.assign({ dateFormatter: {
                inputDateFormat: 'yyyy-MM-dd HH:mm'
            }, maxZoomLevel: 1, paddingTop: '3px', paddingBottom: '-13px', series: [...this.setInboundSeries(), ...this.setOutboundSeries(), ...this.setJournalSeries()] }, this.setXYandLegendConfig());
        // Hide the series that don't have any data for the selected period
        if (this.chartSeriesToHide.length > 0) {
            chartConfig.series = chartConfig.series.filter(series => this.chartSeriesToHide.indexOf(series.dataFields.valueY) === -1);
        }
        // Build the tooltip only for the first series. This can be changed later to be built for all the series after upgrading the amChart lib
        // @ts-ignore
        chartConfig.series[0].adapter = {
            tooltipText: () => {
                let text = this.selectedPeriod === '24h'
                    ? "{dateX.formatDate('dd MMM yyyy, HH:mm:ss zzz')}\n"
                    : "{dateX.formatDate('dd MMM yyyy')}\n";
                chartConfig.series.forEach((seriesItem) => {
                    text +=
                        `[bold ${seriesItem.stroke.hex} font-size: 16px]⚬[/] ${seriesItem.dummyData.tooltipLabel} ` +
                            `[bold]{${seriesItem.dummyData.score}}[/]\n`;
                });
                return text;
            }
        };
        return chartConfig;
    }
    ngAfterViewChecked() {
        this.chartReference.emit(this.chartRef);
    }
    prepareInboundSeriesConfig() {
        return {
            name: this.translate.instant('$I18N_SERVICES_URL_PROTECTION_DASHBOARD.URLS_SCANNED_PER_DAY.INBOUND_SERIES_NAME'),
            hex: urls_scan_graph_static_values_1.urlsScanGraphHex.inbound,
            color: am4core.color(urls_scan_graph_static_values_1.urlsScanGraphHex.inbound)
        };
    }
    prepareMaliciousInboundSeriesConfig() {
        return {
            name: this.translate.instant('$I18N_SERVICES_URL_PROTECTION_DASHBOARD.URLS_SCANNED_PER_DAY.MALICIOUS_INBOUND_SERIES_NAME'),
            hex: urls_scan_graph_static_values_1.urlsScanGraphHex.maliciousInbound,
            color: am4core.color(urls_scan_graph_static_values_1.urlsScanGraphHex.maliciousInbound)
        };
    }
    prepareOutboundSeriesConfig() {
        return {
            name: this.translate.instant('$I18N_SERVICES_URL_PROTECTION_DASHBOARD.URLS_SCANNED_PER_DAY.OUTBOUND_SERIES_NAME'),
            hex: urls_scan_graph_static_values_1.urlsScanGraphHex.outbound,
            color: am4core.color(urls_scan_graph_static_values_1.urlsScanGraphHex.outbound)
        };
    }
    prepareMaliciousOutboundSeriesConfig() {
        return {
            name: this.translate.instant('$I18N_SERVICES_URL_PROTECTION_DASHBOARD.URLS_SCANNED_PER_DAY.MALICIOUS_OUTBOUND_SERIES_NAME'),
            hex: urls_scan_graph_static_values_1.urlsScanGraphHex.maliciousOutbound,
            color: am4core.color(urls_scan_graph_static_values_1.urlsScanGraphHex.maliciousOutbound)
        };
    }
    prepareJournalSeriesConfig() {
        return {
            name: this.translate.instant('$I18N_SERVICES_URL_PROTECTION_DASHBOARD.URLS_SCANNED_PER_DAY.JOURNAL_SERIES_NAME'),
            hex: urls_scan_graph_static_values_1.urlsScanGraphHex.journal,
            color: am4core.color(urls_scan_graph_static_values_1.urlsScanGraphHex.journal)
        };
    }
    prepareMaliciousJournalSeriesConfig() {
        return {
            name: this.translate.instant('$I18N_SERVICES_URL_PROTECTION_DASHBOARD.URLS_SCANNED_PER_DAY.MALICIOUS_JOURNAL_SERIES_NAME'),
            hex: urls_scan_graph_static_values_1.urlsScanGraphHex.maliciousJournal,
            color: am4core.color(urls_scan_graph_static_values_1.urlsScanGraphHex.maliciousJournal)
        };
    }
}
exports.UrlScanGraphComponent = UrlScanGraphComponent;
