<div>
  <mc-wizard-step-header
    label="$I18N_ARCHIVE_IM_SYNC_WIZARD.IM_SYNC_TASK.SUMMARY_STEP.DESCRIPTION"
  >
  <p>{{ '$I18N_ARCHIVE_IM_SYNC_WIZARD.IM_SYNC_TASK.SUMMARY_STEP.INFO' | translate }}</p>
  </mc-wizard-step-header>

  <div class="mc-detailed-list">
    <div class="row mc-detailed-list-row">
      <div class="col-xs-2 mc-detailed-list-label">
        {{ '$I18N_ARCHIVE_IM_SYNC_WIZARD.IM_SYNC_TASK.DETAILS_STEP.FIELD_NAME' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ getStepDataById(1).name }}</div>
    </div>

    <div class="row mc-detailed-list-row">
      <div class="col-xs-2 mc-detailed-list-label">
        {{ '$I18N_ARCHIVE_IM_SYNC_WIZARD.IM_SYNC_TASK.DETAILS_STEP.FIELD_DESCRIPTION' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ getStepDataById(1).description }}</div>
    </div>

    <div class="row mc-detailed-list-row">
      <div class="col-xs-2 mc-detailed-list-label">
        {{ '$I18N_ARCHIVE_IM_SYNC_WIZARD.IM_SYNC_TASK.DETAILS_STEP.FIELD_IM_SOURCE' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ '$I18N_ARCHIVE_IM_SYNC_WIZARD.IM_SYNC_TASK.DETAILS_STEP.' + getStepDataById(1).source | uppercase | translate}}</div>
    </div>

    <div class="row mc-detailed-list-row" data-test="connector-field">
      <div class="col-xs-2 mc-detailed-list-label">
        {{ '$I18N_ARCHIVE_IM_SYNC_WIZARD.IM_SYNC_TASK.CONNECTION_STEP.FIELD_CONNECTOR' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ getStepDataById(2).connector.label }}</div>
    </div>
  </div>
</div>
