
    <mc-lexicon-details
      [lexicon]="lexiconDetails$ | async"
      [loading]="lexiconLoading$ | async"
      [paginationInfo]="lexiconDetailsPaginationInfo$ | async"
      (onLoadDetails)="loadDetails($event)"
      (onEdit)="edit($event)"
      (onDelete)="deleteLexicon($event)"
      [hasEditPermissions]="hasEditPermission$ | async"
      [hasDeleteCapability]="hasDeleteCapability$ | async"
    ></mc-lexicon-details>
  