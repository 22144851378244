"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiIntegrationCortexAuthorisationStepComponent = void 0;
const core_1 = require("@angular/core");
class ApiIntegrationCortexAuthorisationStepComponent {
    constructor() {
        this.showAuthorisationWarning = false;
        this.onSubmitted = new core_1.EventEmitter();
    }
    submit() {
        if (this.isValid()) {
            this.onSubmitted.emit();
        }
    }
    isValid() {
        return true;
    }
}
exports.ApiIntegrationCortexAuthorisationStepComponent = ApiIntegrationCortexAuthorisationStepComponent;
