<mc-wizard-step-header [label]="'$I18N_SWG_CREATE_POLICY_TTP_POLICY_CHAMELEON_SETTINGS'">
  {{'$I18N_SWG_CREATE_POLICY_TTP_POLICY_LINK_PREFIX' | translate}}
    <a (click)="goToChameleonPage()" class="btn-link">
      {{'$I18N_SWG_CREATE_POLICY_TTP_POLICY_CHAMELEON_POLICY_LINK' | translate}}</a>.
</mc-wizard-step-header>

<div class="mc-ttp-settings-step">
  <form novalidate [formGroup]='ttpChameleonSettingsForm' class="mc-ttp-settings-form">

    <mc-enable-switch [longDesc]="'$I18N_SWG_CREATE_POLICY_TTP_POLICY_CHAMELEON_TOOLTIP'" formControlName='chameleonEnabled'></mc-enable-switch>

    <div class="row form-group" *ngIf="ttpChameleonSettingsForm.get('chameleonEnabled').value">
      <span class="mc-swg-policy-chameleon-action">{{'$I18N_SWG_CREATE_POLICY_TTP_POLICY_ACTION' | translate}}</span>
      <label>
        <input type="radio" name="chameleonMode" value="block"
               formControlName="chameleonMode">

        <span class="mc-swg-policy-chameleon-choices"> {{'$I18N_SWG_CREATE_POLICY_TTP_POLICY_ACTION_BLOCK' | translate }} </span>
      </label>
      <label>
        <input type="radio" name="chameleonMode" value="warn"
               formControlName="chameleonMode">
        <span class="mc-swg-policy-chameleon-choices">{{'$I18N_SWG_CREATE_POLICY_TTP_POLICY_ACTION_WARN' | translate }} </span>
      </label>
    </div>
  </form>
</div>
