<mc-swg-policy-edit-sidebar
  [policy]="policy$ | async"
  [webCategoryRulesSections]="webCategoryRulesSections$ | async"
  [categorySectionsLoading]="categorySectionsLoading$ | async"
  [applications]="applications$ | async"
  [loadingForEdit]="loadingForEdit$ | async"
  [hasBrowserIsolationEnabled]="hasBrowserIsolationEnabled"
  [hasEditPermission]="hasPolicyEditPermission$ | async"
  [hasSwgHybrid]="(hasSwgHybrid$ | async) && hasSwgHybridCaps"
  [hasAppPolicyRemoveAllowEnabled]="hasAppPolicyRemoveAllowEnabled"
  [protectedApplications]="protectedApplications$ | async"
  [hasBiPolicyEditPermission]="hasBiPolicyEditPermission$ | async"
  (close)="close()"
  (editPolicy)="editPolicy()"
  (deletePolicy)="deletePolicy($event)"
  (duplicatePolicy)="duplicatePolicy()"
  (onShowErrorNotification)="showErrorNotification($event)"
  (onDisablePolicy)="onDisablePolicy($event)"
>
</mc-swg-policy-edit-sidebar>
