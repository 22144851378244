"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BulkProcessingTabComponent = void 0;
const actions = require("../../actions/bulk-processing.action");
const reducers = require("../../reducers/bulk-processing");
class BulkProcessingTabComponent {
    constructor(store) {
        this.store = store;
    }
    ngOnInit() {
        this.items$ = this.store.select(reducers.getbulkProcessingItems);
        this.totalCount$ = this.store.select(reducers.getbulkProcessingTotalCount);
        this.selectedRow$ = this.store.select(reducers.getbulkProcessingSelectedRow);
        this.isLoading$ = this.store.select(reducers.isbulkProcessingLoading);
        this.metadata$ = this.store.select(reducers.getbulkProcessingMetadata);
        this.store.dispatch(new actions.BulkProcessingRequestAction({ search: {} }));
    }
    search(search) {
        this.store.dispatch(new actions.BulkProcessingRequestAction({ search }));
    }
    dateFilterChanged(range) {
        this.store.dispatch(new actions.BulkProcessingRequestAction({ search: Object.assign({}, range.range) }));
    }
    columnFilterChanged(filter) {
        this.store.dispatch(new actions.BulkProcessingRequestAction({ search: filter }));
    }
    paginatorChanged(page) {
        this.store.dispatch(new actions.BulkProcessingPaginationAction(page));
    }
    rowClicked(message) {
        this.store.dispatch(new actions.BulkProcessingSelectRowAction(message));
    }
    messagesRejected(messages) {
        if (messages.length > 0) {
            this.store.dispatch(new actions.BulkProcessingRejectEmailsAction(messages));
        }
    }
    messagesRetried(messages) {
        if (messages.length > 0) {
            this.store.dispatch(new actions.BulkProcessingRetryEmailsAction(messages));
        }
    }
    routeRecalculated(messages) {
        if (messages.length > 0) {
            // @ts-ignore
            this.store.dispatch(new actions.BulkRecalculateRouteAction(messages));
        }
    }
    messagesEarlyBounced(messages) {
        if (messages.length > 0) {
            this.store.dispatch(new actions.BulkProcessingEarlyBounceEmailsAction(messages));
        }
    }
    exportResults(tableColumns) {
        this.store.dispatch(new actions.BulkProcessingExportAction(tableColumns));
    }
}
exports.BulkProcessingTabComponent = BulkProcessingTabComponent;
