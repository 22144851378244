"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportDetailComponent = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const exportActions = require("../../actions/export/export.actions");
const reducers = require("../../reducers");
class ExportDetailComponent {
    constructor(overlayRef, store, fb) {
        this.overlayRef = overlayRef;
        this.store = store;
        this.fb = fb;
        this.canExport$ = new rxjs_1.BehaviorSubject(true);
        this.formatOptions = [
            {
                value: 'xlsx',
                label: 'xlsx'
            },
            {
                value: 'csv',
                label: 'csv'
            }
        ];
    }
    ngOnInit() {
        this.exportConfig$ = this.store.select(reducers.getExportConfig);
        this.exportConfig$.pipe(operators_1.first()).subscribe((payload) => {
            this.exportConfigPayload = payload;
            const groupContent = {};
            payload.columnsToExport.forEach((item) => {
                const key = item.key;
                groupContent[key] = true;
            });
            groupContent['format'] = this.formatOptions[0].value;
            this.form = this.fb.group(groupContent);
        });
        this.isLoading$ = this.store.select(reducers.isExportDataLoading);
        this.form.valueChanges.subscribe(formFields => {
            let canExport = false;
            for (const field in formFields) {
                if (formFields.hasOwnProperty(field) && formFields[field] === true) {
                    canExport = true;
                }
            }
            this.canExport$.next(canExport);
        });
    }
    onDownload() {
        const exportData = Object.assign(Object.assign({ exportColumns: [] }, this.exportConfigPayload.filters), { exportType: this.form.get('format').value, id: this.exportConfigPayload.id, endPoint: this.exportConfigPayload.endPoint });
        Object.keys(this.form.controls).forEach(key => {
            if (key !== 'format' && this.form.get(key).value) {
                exportData.exportColumns.push(key);
            }
        });
        this.store.dispatch(new exportActions.GetTokenForExportAction(exportData));
    }
    onClose() {
        this.overlayRef.close();
    }
    onSelectChange(selection) {
        this.form.get('format').setValue(selection);
    }
}
exports.ExportDetailComponent = ExportDetailComponent;
