"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const moment = require("moment");
angular.module('backuprestore-mailbox-details.slides-restore.controller', [])
    .controller('MailBoxesDetailsRestoreController', ['$scope', '$translate', 'backupService', 'mailSelectorAsideService', 'ngProgressFactory', 'serverConnectionService', 'mailboxEmailExportRestoreService',
    function ($scope, $translate, backupService, mailSelectorAsideService, ngProgressFactory, serverConnectionService, mailboxEmailExportRestoreService) {
        const self = this;
        const { mailbox, viewType, folderPaths, dismissFunction, userEmailAddress, snapshotId } = $scope.$parent.$resolve;
        const ngProgress = ngProgressFactory.createInstance();
        self.formCtrl = null;
        self.restoreDates = null;
        self.connectorsList = null;
        self.restoreTypeOptions = null;
        self.restoreData = null;
        self.checkMailboxInlineNotificationOptions = null;
        self.selectMailboxGroup = selectMailboxGroup;
        self.setTargetMailboxInvalid = setTargetMailboxInvalid;
        self.restoreEmailsDatesHasChanged = restoreEmailsDatesHasChanged;
        self.loadConnectorsList = loadConnectorsList;
        self.createRestoreTask = createRestoreTask;
        self.checkTargetDataSource = checkTargetDataSource;
        self.selectMailboxGroupTooltip = $translate.instant('$I18N_BACKUP_RESTORE_MAILBOXES_EXPORT_RESTORE_TO_TOOLTIP');
        self.restoreDateRangeCheckBoxTooltip = $translate.instant('$I18N_BACKUP_RESTORE_MAILBOXES_RESTORE_DATE_RANGE_CHECKBOX_TOOLTIP');
        initialize();
        function initialize() {
            self.restoreDates = false;
            self.targetMailboxValid = false;
            self.connectorsList = [];
            self.restoreTypeOptions = [
                { key: 'mailbox', value: '$I18N_BACKUP_RESTORE_MAILBOXES_EXPORT_RESTORE_MAILBOOX_MODE' },
                { key: 'original', value: '$I18N_BACKUP_RESTORE_MAILBOXES_EXPORT_RESTORE_ORIGINAL_MODE' }
            ];
            self.restoreData = {
                recoveryType: 'restore',
                mailbox,
                viewType,
                decodedFolderPaths: folderPaths,
                notifyAddress: userEmailAddress
            };
            if (snapshotId) {
                self.restoreData['snapshotId'] = snapshotId;
            }
            self.headerTranslateValues = JSON.stringify({
                mailBox: mailbox
            });
            self.restrictStartDate = moment(0);
            self.restrictEndDate = moment();
            self.ranges = [{ custom: true }];
            self.dates = {};
            self.restoreData.restoreSettings = {};
            self.restoreData.restoreSettings.targetMailbox = mailbox;
            self.checkMailboxInlineNotificationOptions = {};
            loadConnectorsList();
        }
        function setTargetMailboxInvalid() {
            self.checkMailboxInlineNotificationOptions.messageBoxVisibility = false;
            self.targetMailboxValid = false;
        }
        function checkTargetDataSource() {
            backupService.testTargetSource(self.restoreData.restoreSettings.dataSourceId.key, self.restoreData.restoreSettings.targetMailbox).then(function (response) {
                if (response.first.status && (response.first.status.toLowerCase() === backupService.constants.TEST_TARGET_SOURCE.SUCCESS.toLowerCase())) {
                    self.targetMailboxValid = true;
                    notifyTestSuccess();
                }
                else {
                    self.targetMailboxValid = false;
                    notifyTestFailure();
                }
            });
        }
        function notifyTestSuccess() {
            self.checkMailboxInlineNotificationOptions.messageBoxAlertTypeClass = 'alert-success';
            self.checkMailboxInlineNotificationOptions.messageBoxIconClass = 'fa-check';
            self.checkMailboxInlineNotificationOptions.messageBoxTitle = '$I18N_BACKUP_RESTORE_MAILBOX_CHECK_TARGET_MAILBOX_SUCCESS_MESSAGE';
            self.checkMailboxInlineNotificationOptions.messageBoxVisibility = 'true';
            self.checkMailboxInlineNotificationOptions.messageBoxHideCloseButton = false;
        }
        function notifyTestFailure() {
            self.checkMailboxInlineNotificationOptions.messageBoxAlertTypeClass = 'alert-danger';
            self.checkMailboxInlineNotificationOptions.messageBoxIconClass = 'fas fa-exclamation-triangle';
            self.checkMailboxInlineNotificationOptions.messageBoxTitle = '$I18N_BACKUP_RESTORE_MAILBOX_CHECK_TARGET_MAILBOX_FAILURE_MESSAGE';
            self.checkMailboxInlineNotificationOptions.messageBoxVisibility = 'true';
            self.checkMailboxInlineNotificationOptions.messageBoxHideCloseButton = false;
        }
        function selectMailboxGroup() {
            ngProgress.start();
            self.setTargetMailboxInvalid();
            const mailSelectorPromise = mailSelectorAsideService.openMailSelector();
            mailSelectorPromise.then(function (selectedMail) {
                if (angular.isDefined(selectedMail)) {
                    if (self.restoreData.restoreSettings) {
                        self.restoreData.restoreSettings.targetMailbox = selectedMail;
                    }
                    else {
                        self.restoreData.restoreSettings = { targetMailbox: selectedMail };
                    }
                    if (self.checkMailboxInlineNotificationOptions.messageBoxVisibility) {
                        self.checkMailboxInlineNotificationOptions.messageBoxVisibility = false;
                    }
                }
                ngProgress.complete();
            });
            ngProgress.complete();
        }
        function loadConnectorsList() {
            serverConnectionService.getConnector().then(function (connectorResponse) {
                if (!connectorResponse.err) {
                    angular.forEach(connectorResponse.all, function (response) {
                        self.connectorsList.push({ key: response.id, value: response.description });
                    });
                }
                else {
                    serverConnectionService.getError.showErrorNotification(connectorResponse);
                }
            }, function () {
                serverConnectionService.getError.showErrorNotification();
            });
        }
        function restoreEmailsDatesHasChanged() {
            if (!self.restoreDates) {
                if (self.restoreData.emailsAfter) {
                    delete self.restoreData.emailsAfter;
                }
                if (self.restoreData.emailsBefore) {
                    delete self.restoreData.emailsBefore;
                }
            }
        }
        function createRestoreTask() {
            mailboxEmailExportRestoreService.createRecoveryTask(self.restoreData, dismissFunction);
        }
    }]);
