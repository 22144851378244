<div class="mc-supervision-wizard-step-container">
  <mc-wizard-step-header
    class="mc-supervision-wizard-step-header"
    label="$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.DETAILS_STEP.DESCRIPTION"
  ></mc-wizard-step-header>

  <form
    [formGroup]="form"
    class="form-horizontal"
    mcDefaultLabelClass="col-sm-3"
    mcDefaultControlContainerClass="col-sm-9">
    <mc-text-field
      label="{{'$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.DETAILS_STEP.FIELD_NAME' | translate}}"
      placeholder="{{'$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.DETAILS_STEP.FIELD_NAME' | translate}}"
      formControlName="name"
      name="name"
      required
      errorPrefix="$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.VALIDATION.NAME"
    ></mc-text-field>

    <mc-text-area-field
      label="{{'$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.DETAILS_STEP.FIELD_DESCRIPTION' | translate}}"
      placeholder="{{'$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.DETAILS_STEP.FIELD_DESCRIPTION' | translate}}"
      formControlName="description"
      name="description"
      required
      errorPrefix="$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.VALIDATION.DESCRIPTION"
    ></mc-text-area-field>
  </form>
</div>
