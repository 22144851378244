"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImSyncListEffect = void 0;
const effects_1 = require("@ngrx/effects");
const operators_1 = require("rxjs/operators");
const rxjs_1 = require("rxjs");
const im_sync_actions_1 = require("../actions/im-sync.actions");
const im_task_service_1 = require("../wizard/services/im-task.service");
const notification_actions_1 = require("app-new/components/notification/actions/notification.actions");
const table_store_1 = require("@mimecast-ui/table-store");
const TABLE_ID = 'ImSyncServiceList';
class ImSyncListEffect {
    constructor(actions$, stateService, translateService, imTaskService, tableStore) {
        this.actions$ = actions$;
        this.stateService = stateService;
        this.translateService = translateService;
        this.imTaskService = imTaskService;
        this.tableStore = tableStore;
        this.showCreateImSyncWizard$ = this.actions$.pipe(effects_1.ofType(im_sync_actions_1.ImSyncActions.OPEN_CREATE_NEW_IM_SYNC), operators_1.tap(() => this.stateService.$state.go('services-im-sync-create-wizard-im-task')), operators_1.switchMap(() => rxjs_1.EMPTY));
        this.create$ = this.actions$.pipe(effects_1.ofType(im_sync_actions_1.ImSyncActions.CREATE_IM_SYNC_TASK), operators_1.switchMap((action) => {
            return this.imTaskService.createImSyncTask(action.payload).pipe(operators_1.map(response => {
                if (response.hasErrors) {
                    return new im_sync_actions_1.CreateImSyncTaskActionFail({
                        message: this.getNotificationMessage('CREATE_FAILURE'),
                        failure: response.fail[0]
                    });
                }
                return new im_sync_actions_1.CreateImSyncTaskActionSuccess(this.getNotificationMessage('CREATE_SUCCESS'));
            }), operators_1.catchError(response => {
                return rxjs_1.of(new im_sync_actions_1.CreateImSyncTaskActionFail({
                    message: this.getNotificationMessage('CREATE_FAILURE'),
                    failure: response.fail[0]
                }));
            }));
        }));
        this.createSuccess$ = this.actions$.pipe(effects_1.ofType(im_sync_actions_1.ImSyncActions.CREATE_IM_SYNC_TASK_SUCCESS), operators_1.switchMap((action) => rxjs_1.of(new notification_actions_1.NotificationShowSuccessAction(action.payload))), operators_1.tap(() => this.stateService.$state.go('services-im-sync', {
            callerWizardId: im_task_service_1.ImTaskService.WizardId
        })));
        this.updateSuccess$ = this.actions$.pipe(effects_1.ofType(im_sync_actions_1.ImSyncActions.UPDATE_IM_SYNC_TASK_SUCCESS), operators_1.map((action) => action.payload), operators_1.withLatestFrom(this.tableStore.select(table_store_1.tableReducer.getCurrentFilters(TABLE_ID)), this.tableStore.select(table_store_1.tableReducer.getCurrentPagination(TABLE_ID))), operators_1.switchMap(([payload, currentFilters, currentPagination]) => {
            return [
                new notification_actions_1.NotificationShowSuccessAction(payload),
                new table_store_1.LoadAction({
                    tableId: TABLE_ID,
                    pagination: {
                        pageToken: currentPagination.pageToken,
                        pageSize: currentPagination.pageSize,
                        pageNumber: currentPagination.pageNumber
                    },
                    query: currentFilters
                })
            ];
        }));
        this.updateFail$ = this.actions$.pipe(effects_1.ofType(im_sync_actions_1.ImSyncActions.UPDATE_IM_SYNC_TASK_FAIL), operators_1.switchMap((action) => {
            if (!action.payload.failure.errors) {
                return [new notification_actions_1.NotificationShowFailAction(action.payload.message)];
            }
            return action.payload.failure.errors.map(error => new notification_actions_1.NotificationShowFailAction(this.translateService.instant(this.getErrorMessage(error.code))));
        }));
        this.getImTaskDetails$ = this.actions$.pipe(effects_1.ofType(im_sync_actions_1.ImSyncActions.LOAD_IM_SYNC_TASK_DETAILS), operators_1.switchMap((action) => {
            return this.imTaskService.getImTaskDetails(action.payload).pipe(operators_1.map((response) => {
                if (response.hasErrors) {
                    return new im_sync_actions_1.LoadImSyncTaskDetailsFailedAction(response.fail[0]);
                }
                return new im_sync_actions_1.LoadImSyncTaskDetailsSuccessAction(response.first);
            }), operators_1.catchError(response => {
                return rxjs_1.of(new im_sync_actions_1.LoadImSyncTaskDetailsFailedAction(response.fail));
            }));
        }));
        this.update$ = this.actions$.pipe(effects_1.ofType(im_sync_actions_1.ImSyncActions.UPDATE_IM_SYNC_TASK), operators_1.switchMap((action) => {
            return this.imTaskService.updateImSyncTask(action.payload).pipe(operators_1.map(response => {
                if (response.hasErrors) {
                    return new im_sync_actions_1.UpdateImSyncTaskActionFail({
                        message: this.getNotificationMessage('UPDATE_FAILURE'),
                        failure: response.fail[0]
                    });
                }
                return new im_sync_actions_1.UpdateImSyncTaskActionSuccess(this.getNotificationMessage('UPDATE_SUCCESS'));
            }), operators_1.catchError(response => {
                return rxjs_1.of(new im_sync_actions_1.UpdateImSyncTaskActionFail({
                    message: this.getNotificationMessage('UPDATE_FAILURE'),
                    failure: response.fail[0]
                }));
            }));
        }));
        this.createFail$ = this.actions$.pipe(effects_1.ofType(im_sync_actions_1.ImSyncActions.CREATE_IM_SYNC_TASK_FAIL), operators_1.switchMap((action) => {
            if (!action.payload.failure.errors) {
                return [new notification_actions_1.NotificationShowFailAction(action.payload.message)];
            }
            return action.payload.failure.errors.map(error => new notification_actions_1.NotificationShowFailAction(this.translateService.instant(this.getErrorMessage(error.code))));
        }));
        this.openCreateConnectorWizard$ = this.actions$.pipe(effects_1.ofType(im_sync_actions_1.ImSyncActions.OPEN_CREATE_CONNECTOR_WIZARD), operators_1.tap(() => this.stateService.$state.go('services-create-connector-page')), operators_1.switchMap(() => rxjs_1.EMPTY));
        this.getConnectors$ = this.actions$.pipe(effects_1.ofType(im_sync_actions_1.ImSyncActions.LOAD_CONNECTORS), operators_1.switchMap(() => {
            return this.imTaskService.getConnectors().pipe(operators_1.map((response) => {
                if (response.hasErrors) {
                    return new im_sync_actions_1.LoadConnectorsFailedAction(response.fail[0]);
                }
                return new im_sync_actions_1.LoadConnectorsSuccessAction(response.first.connectors);
            }), operators_1.catchError(response => {
                return rxjs_1.of(new im_sync_actions_1.LoadConnectorsFailedAction(response.fail));
            }));
        }));
        this.updateTaskStatus$ = this.actions$.pipe(effects_1.ofType(im_sync_actions_1.ImSyncActions.UPDATE_TASK_STATUS), operators_1.switchMap((action) => {
            return this.imTaskService.updateTaskStatus(action.payload).pipe(operators_1.map(response => {
                if (response.hasErrors) {
                    return new im_sync_actions_1.UpdateTaskStatusActionFail({
                        message: this.getStatusUpdateNotificationMessage('UPDATE_STATUS_FAILURE', action.payload.status),
                        failure: response.fail[0]
                    });
                }
                return new im_sync_actions_1.UpdateTaskStatusActionSuccess(this.getStatusUpdateNotificationMessage('UPDATE_STATUS_SUCCESS', action.payload.status));
            }), operators_1.catchError(response => {
                return rxjs_1.of(new im_sync_actions_1.UpdateTaskStatusActionFail({
                    message: this.getStatusUpdateNotificationMessage('UPDATE_STATUS_FAILURE', action.payload.status),
                    failure: response.fail[0]
                }));
            }));
        }));
        this.updateTaskStatusSuccess$ = this.actions$.pipe(effects_1.ofType(im_sync_actions_1.ImSyncActions.UPDATE_TASK_STATUS_SUCCESS), operators_1.map((action) => action.payload), operators_1.withLatestFrom(this.tableStore.select(table_store_1.tableReducer.getCurrentFilters(TABLE_ID)), this.tableStore.select(table_store_1.tableReducer.getCurrentPagination(TABLE_ID))), operators_1.switchMap(([payload, currentFilters, currentPagination]) => {
            return [
                new notification_actions_1.NotificationShowSuccessAction(payload),
                new table_store_1.LoadAction({
                    tableId: TABLE_ID,
                    pagination: {
                        pageToken: currentPagination.pageToken,
                        pageSize: currentPagination.pageSize,
                        pageNumber: currentPagination.pageNumber
                    },
                    query: currentFilters
                })
            ];
        }));
        this.updateTaskStatusFail$ = this.actions$.pipe(effects_1.ofType(im_sync_actions_1.ImSyncActions.UPDATE_TASK_STATUS_FAIL), operators_1.switchMap((action) => {
            if (!action.payload.failure.errors) {
                return [new notification_actions_1.NotificationShowFailAction(action.payload.message)];
            }
            return action.payload.failure.errors.map(error => new notification_actions_1.NotificationShowFailAction(this.translateService.instant(this.getErrorMessage(error.code))));
        }));
    }
    getNotificationMessage(notificationkey) {
        const fullKey = `$I18N_ARCHIVE_IM_SYNC_WIZARD.IM_SYNC_TASK.NOTIFICATION.${notificationkey}`;
        return this.translateService.instant(fullKey);
    }
    getErrorMessage(errorKey) {
        return `$I18N_ARCHIVE_IM_SYNC_TASK.ERROR.${errorKey.toUpperCase()}`;
    }
    getStatusUpdateNotificationMessage(notificationkey, status) {
        const fullKey = `$I18N_ARCHIVE_IM_SYNC_TASK.NOTIFICATION.${notificationkey}.${status.toUpperCase()}`;
        return this.translateService.instant(fullKey);
    }
}
__decorate([
    effects_1.Effect()
], ImSyncListEffect.prototype, "showCreateImSyncWizard$", void 0);
__decorate([
    effects_1.Effect()
], ImSyncListEffect.prototype, "create$", void 0);
__decorate([
    effects_1.Effect()
], ImSyncListEffect.prototype, "createSuccess$", void 0);
__decorate([
    effects_1.Effect()
], ImSyncListEffect.prototype, "updateSuccess$", void 0);
__decorate([
    effects_1.Effect()
], ImSyncListEffect.prototype, "updateFail$", void 0);
__decorate([
    effects_1.Effect()
], ImSyncListEffect.prototype, "getImTaskDetails$", void 0);
__decorate([
    effects_1.Effect()
], ImSyncListEffect.prototype, "update$", void 0);
__decorate([
    effects_1.Effect()
], ImSyncListEffect.prototype, "createFail$", void 0);
__decorate([
    effects_1.Effect()
], ImSyncListEffect.prototype, "openCreateConnectorWizard$", void 0);
__decorate([
    effects_1.Effect()
], ImSyncListEffect.prototype, "getConnectors$", void 0);
__decorate([
    effects_1.Effect()
], ImSyncListEffect.prototype, "updateTaskStatus$", void 0);
__decorate([
    effects_1.Effect()
], ImSyncListEffect.prototype, "updateTaskStatusSuccess$", void 0);
__decorate([
    effects_1.Effect()
], ImSyncListEffect.prototype, "updateTaskStatusFail$", void 0);
exports.ImSyncListEffect = ImSyncListEffect;
