"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPolicy = exports.getPolicyId = exports.isLoading = exports.reducer = exports.initialState = void 0;
const actions = require("../actions/policy-details.actions");
// @ts-ignore
exports.initialState = {
    isLoading: false,
    policyId: ''
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.OPEN_POLICY_DETAILS_SIDEBAR:
            return Object.assign(Object.assign({}, state), { isLoading: true, policyId: action.payload.id });
        case actions.LOAD_POLICY_DETAILS:
            return Object.assign(Object.assign({}, state), { isLoading: true });
        case actions.LOAD_POLICY_DETAILS_SUCCESS:
            return Object.assign(Object.assign({}, state), { isLoading: false, policy: action.payload });
        case actions.LOAD_POLICY_DETAILS_FAIL:
            return Object.assign(Object.assign({}, state), { isLoading: false });
        default:
            return state;
    }
}
exports.reducer = reducer;
const isLoading = (state) => state.isLoading;
exports.isLoading = isLoading;
const getPolicyId = (state) => state.policyId;
exports.getPolicyId = getPolicyId;
const getPolicy = (state) => state.policy;
exports.getPolicy = getPolicy;
