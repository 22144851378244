<mc-empty-results data-test="no-drives" iconClass="far fa-file" keyTitle="$I18N_ONEDRIVE_LIST.EMPTY_RESULTS.TITLE">
  <mc-empty-body>
    <div>
      {{ '$I18N_ONEDRIVE_LIST.EMPTY_RESULTS.DESCRIPTION.LINE_1.1' | translate }}
      <a class="mc-link" data-test="create-backup-link"
        (click)="createBackup()">{{ '$I18N_ONEDRIVE_LIST.EMPTY_RESULTS.DESCRIPTION.LINE_1.2' | translate }}</a>.
    </div>
    <div>
      {{ '$I18N_ONEDRIVE_LIST.EMPTY_RESULTS.DESCRIPTION.LINE_2.1' | translate }}
    </div>
  </mc-empty-body>
</mc-empty-results>