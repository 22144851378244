"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MepPolicyCreatePageComponent = void 0;
const policyActions = require("../../actions/policy.actions");
const reducers = require("app-new/services/mep/policies/reducers");
const user_location_sidebar_actions_1 = require("../../../../../components/user-location-selector/actions/user-location-sidebar.actions");
class MepPolicyCreatePageComponent {
    constructor(store, stateService, capabilitiesService) {
        this.store = store;
        this.stateService = stateService;
        this.capabilitiesService = capabilitiesService;
    }
    ngOnInit() {
        this.policy$ = this.store.select(reducers.getMepPolicy);
        this.apiIsProcessing$ = this.store.select(reducers.getApiProcessingInProgress);
        this.loadingForEdit$ = this.store.select(reducers.getLoadingForEdit);
        this.hasMepPolicyEditPermission$ = this.capabilitiesService.hasCapability('Permission.MEP_EDIT');
    }
    createMepPolicy(policy) {
        this.store.dispatch(new policyActions.MepSavePolicy(policy));
    }
    editMepPolicySettings(policy) {
        this.store.dispatch(new policyActions.MepEditPolicy(policy));
    }
    openAppliesToSidePanel(value) {
        this.store.dispatch(new user_location_sidebar_actions_1.UserLocationAppliesToClose());
        this.store.dispatch(new user_location_sidebar_actions_1.UserLocationAppliesToOpen(value));
    }
    everyOneAffectedUsers($event) {
        this.store.dispatch(new user_location_sidebar_actions_1.UserLocationAppliesToClose());
        this.store.dispatch(new policyActions.MepEveryoneAffectedUsersStep($event));
    }
    removeAffectedUsers($event) {
        this.store.dispatch(new policyActions.MepRemoveAffectedUsersStep($event));
    }
    resetWizard() {
        this.store.dispatch(new policyActions.MepClearPolicy());
        this.stateService.$state.go('misaddressed-email-protect', { displayTab: 'policies' });
    }
    editMepPolicyAppliesTo() { }
}
exports.MepPolicyCreatePageComponent = MepPolicyCreatePageComponent;
