
    <div *ngIf="safetyTips && safetyTips.length > 0" class="mc-default-only">
      <div class="mc-preview-title">
        <h5>{{ title }}</h5>
      </div>
      <div>
        <div class="panel-margin-bottom" *ngFor="let tip of safetyTips">
          <mc-carousel class="margin-bottom" [disabledNavigation]="true" [tips]="toTips(tip)">
          </mc-carousel>
        </div>
      </div>
    </div>
  