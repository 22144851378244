<div class="panel panel-default">
  <div class="panel-heading">{{ '$I18N_REMEDIATION_INCIDENT_WIDGET.TITLE' | translate }}</div>

  <div *ngIf="!(isLoadingData$ | async) && !(hasError$ | async); else spinner;">
    <mc-report-header *ngIf="(incidentStats$ | async) as incidentStats">
      <mc-report-item label="$I18N_REMEDIATION_INCIDENT_WIDGET.INCIDENT_TYPE.NOTIFY_ONLY" [value]="incidentStats.notify_only"></mc-report-item>
      <mc-report-item label="$I18N_REMEDIATION_INCIDENT_WIDGET.INCIDENT_TYPE.AUTOMATIC" [value]="incidentStats.automatic"></mc-report-item>
      <mc-report-item label="$I18N_REMEDIATION_INCIDENT_WIDGET.INCIDENT_TYPE.MANUAL" [value]="incidentStats.manual"></mc-report-item>
      <mc-report-item label="$I18N_REMEDIATION_INCIDENT_WIDGET.INCIDENT_TYPE.RESTORE" [value]="incidentStats.restore"></mc-report-item>
      <mc-report-item *ngIf="incidentStats.mep_clawback" label="$I18N_REMEDIATION_INCIDENT_WIDGET.INCIDENT_TYPE.MEP_CLAWBACK" [value]="incidentStats.mep_clawback"></mc-report-item>
    </mc-report-header>

    <div *ngIf="(incidents$ | async).length; else noResults">
      <mc-table
        [isLoading]="getIncidentsLoading$ | async"
        [columns]="columns"
        [data]="incidents$ | async"
        (rowClick)="onRowClicked($event)"
        translatePrefix="$I18N_REMEDIATION_INCIDENT_WIDGET">
        <mc-column key="code">
          <mc-body-cell *mcBodyCellDef="let row">{{ row.code }}</mc-body-cell>
        </mc-column>

        <mc-column key="type">
          <mc-body-cell *mcBodyCellDef="let row">{{ getTranslatedTypeName(row) }}</mc-body-cell>
        </mc-column>

        <mc-column-date key="modified"></mc-column-date>

        <mc-column key="identified">
          <mc-body-cell *mcBodyCellDef="let row">{{ row.identified || noValuePlaceHolder }}</mc-body-cell>
        </mc-column>

        <mc-column key="successful">
          <mc-body-cell *mcBodyCellDef="let row">{{ row.successful || noValuePlaceHolder }}</mc-body-cell>
        </mc-column>

        <mc-column key="failed">
          <mc-body-cell *mcBodyCellDef="let row">{{ row.failed || noValuePlaceHolder }}</mc-body-cell>
        </mc-column>

        <mc-column key="restored">
          <mc-body-cell *mcBodyCellDef="let row">{{ row.restored || noValuePlaceHolder }}</mc-body-cell>
        </mc-column>

      </mc-table>

      <div class="text-right">
        <a class="btn btn-link" (click)="openIncidentsTab()">{{ '$I18N_REMEDIATION_INCIDENT_WIDGET.VIEW_ALL' | translate }}</a>
      </div>
    </div>
  </div>
</div>

<ng-template #noResults>
  <mc-widget-placeholder label="$I18N_REMEDIATION_INCIDENT_WIDGET.NO_RESULTS" icon="far fa-list-alt"></mc-widget-placeholder>
</ng-template>

<ng-template #spinner>
  <mc-spinner class="spinner-wrapper" *ngIf="!(hasError$ | async); else genericError"></mc-spinner>
</ng-template>

<ng-template #genericError>
  <div class="spinner-wrapper">
    <mc-inline-notification [notification]="inlineNotificationConfigError"></mc-inline-notification>
  </div>
</ng-template>
