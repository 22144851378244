"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListComponent = void 0;
const table_store_1 = require("@mimecast-ui/table-store");
class ListComponent extends table_store_1.TableBaseComponent {
    constructor(store) {
        super(store, 'OnedriveSnapshotList');
        this.store = store;
        this.title = '$I18N_SYNC_RECOVER_ONE_DRIVE_SIT_LIST_TITLE';
        this.description = '$I18N_SYNC_RECOVER_ONE_DRIVE_SIT_LIST_DESCRIPTION';
        this.columnMap = {
            mailbox: '$I18N_SYNC_RECOVER_LIST_COLUMN_MAILBOX',
            created: '$I18N_SYNC_RECOVER_LIST_COLUMN_CREATED_ON',
            started: '$I18N_SYNC_RECOVER_LIST_COLUMN_STARTED_ON',
            expiry: '$I18N_SYNC_RECOVER_LIST_COLUMN_EXPIRES_ON',
            status: '$I18N_SYNC_RECOVER_LIST_COLUMN_STATUS'
        };
        this.columnList = Object.keys(this.columnMap);
    }
}
exports.ListComponent = ListComponent;
