<div class="mc-api-integration-step-block">
  <mc-wizard-step-header [label]="'$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.ACTIVATION.HEADER'">
  </mc-wizard-step-header>

  <div class="mc-detailed-list mc-api-integration-crowdstrike-activation-block">
    <ol class="list-group no-margin panel-padding-bottom panel-padding-left col-sm-12">
      <li>
        {{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.ACTIVATION.STEP_1' | translate }}
        <a [href]="crowdstrikeLink">{{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.ACTIVATION.STEP_1_LINK' | translate }}</a>.
      </li>
      <li>
        {{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.ACTIVATION.STEP_2' | translate }}
      </li>
      <li>
        {{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.ACTIVATION.STEP_3' | translate }}
      </li>
      <li>
        {{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.ACTIVATION.STEP_4' | translate }}
      </li>
      <li>
        {{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.ACTIVATION.STEP_5' | translate }}
        <ul>
          <li>
            {{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.ACTIVATION.STEP_5_A_NEW_API' | translate }}</li>
        </ul>
      </li>
      <li>
        {{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.ACTIVATION.STEP_6' | translate }}
      </li>
      <li>
        {{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.ACTIVATION.STEP_7' | translate }}
      </li>
    </ol>
    <form
      class="form-horizontal"
      mcDefaultLabelClass="col-sm-2"
      mcDefaultControlContainerClass="col-sm-6"
      [formGroup]="form"
    >
      <mc-field
          label="{{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.ACTIVATION.CLIENT_ID' | translate }}"
        >
        <input
            class="form-control"
            type="text"
            name="clientIdField"
            formControlName="clientIdField"
            aria-label="clientSecretField"
        />
      </mc-field>

      <mc-field *ngIf="showMaskedClientSecret()"
          label="{{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.ACTIVATION.CLIENT_SECRET' | translate }}"
        >
        <input
            class="form-control"
            type="password"
            name="clientSecretField"
            formControlName="clientSecretField"
            aria-label="clientSecretField"
        />
      </mc-field>

      <mc-field
          *ngIf="!showMaskedClientSecret()"
          label="{{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.ACTIVATION.CLIENT_SECRET' | translate }}"
        >
        <input
            class="form-control"
            type="text"
            name="clientSecretField"
            formControlName="clientSecretField"
            aria-label="clientSecretField"
        />
      </mc-field>

      <mc-field
          label="{{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.ACTIVATION.BASE_URL' | translate }}"
        >
        <input
            class="form-control"
            type="url"
            name="baseUrlField"
            formControlName="baseUrlField"
            aria-label="baseUrlField"
        />
      </mc-field>

      <div class="col-sm-offset-2 verify-btn-container">
        <button *ngIf="!isVerified()" [disabled]="isDisabled()" (click)="verify()" type="button" class="btn btn-primary">
          {{ verifyTextButton() }}
        </button>

        <button *ngIf="isVerified()" [disabled]="isVerified()" type="button" class="btn btn-white">
          {{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.ACTIVATION.VERIFIED' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
