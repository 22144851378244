"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApPolicyListComponent = void 0;
const core_1 = require("@angular/core");
const table_store_1 = require("@mimecast-ui/table-store");
const models_1 = require("../../models");
const actions = require("../../actions/policy.actions");
const policy_actions_1 = require("../../actions/policy.actions");
const policy_actions_2 = require("../../actions/policy.actions");
class ApPolicyListComponent extends table_store_1.TableBaseComponent {
    constructor(store) {
        super(store, 'ApPolicyList');
        this.store = store;
        this.columnList = [
            'description',
            'status',
            'dateRange',
            'action',
            'checkInbound',
            'checkOutbound',
            'checkJournal',
            'rules',
            'dropdown-column'
        ];
        this.createNewPolicy = new core_1.EventEmitter();
    }
    ngOnInit() {
        super.ngOnInit();
    }
    goToCreateNewPolicy() {
        this.createNewPolicy.emit();
    }
    getPolicyConfigurationType(type) {
        return '$I18N_CONFIGURATION_OPTION_' + type.toUpperCase();
    }
    deleteDefinition(policy) {
        this.store.dispatch(new actions.ApDeleteModalOpenAction(policy));
    }
    onSearchPolicies(data) {
        this.dispatchFilterAction({ policyName: data.search });
    }
    editDefinition(policyDetail) {
        this.store.dispatch(new policy_actions_2.UpdatePolicyId(policyDetail.id));
        this.store.dispatch(new policy_actions_1.ApPolicyNavigateToPolicyWizard(models_1.ApPolicyWizardFlowType.EDIT));
    }
    onSearchClear() {
        this.dispatchFilterAction({ policyName: undefined });
    }
    viewApPolicyDetails(policy) {
        this.store.dispatch(new actions.ApViewAppAsideAction(policy));
    }
}
exports.ApPolicyListComponent = ApPolicyListComponent;
