"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IntegrationProviderSelectorComponent = void 0;
const core_1 = require("@angular/core");
class IntegrationProviderSelectorComponent {
    constructor() {
        this._state = 'unselected';
        this.onProviderSelected = new core_1.EventEmitter();
    }
    get state() {
        return this._state;
    }
    set state(value) {
        if (value !== this._state) {
            this.changeImageClass(this._state, value);
            this._state = value;
        }
    }
    ngAfterViewInit() {
        if (!!this.container) {
            this.providerImage = this.container.nativeElement.querySelector('img');
            this.providerImage.classList.add(this._state);
        }
    }
    selected() {
        if (this._state !== 'disabled') {
            this.onProviderSelected.emit();
        }
    }
    changeImageClass(previous, current) {
        if (!!this.providerImage) {
            this.providerImage.classList.remove(previous);
            this.providerImage.classList.add(current);
        }
    }
}
exports.IntegrationProviderSelectorComponent = IntegrationProviderSelectorComponent;
