<br/>

<mc-table [data]="tableData$ | async"
          [columns]="columnList"
          [isLoading]="isLoading$ | async"
          (rowClick)="onRowClick($event)"
          [highlightedRow]="selectedRow$ | async"
          translatePrefix="$I18N_DEFERRED_LIST">

  <button class="mc-table-actions btn btn-secondary" (click)="onExport()">
    {{ '$I18N_REJECTED_AND_DEFERRED_LIST.EXPORT_DATA' | translate }}
  </button>
  
 
  <mc-filters [metadata]="metaData$ | async" (paginatorChange)="onPaginationChange($event)"
  [externalTableSearchCount]="externalTableSearchCount$ | async">
    <mc-filter-column-select-and-search
        [sections]="searchSections"
        [placeholder]="'$I18N_REJECTED_AND_DEFERRED_LIST.FILTERS.SEARCH_FIELD.PLACEHOLDER' | translate"
        (filtersChange)="onSearch($event)">
    </mc-filter-column-select-and-search>

    <div class="mcc-deferred-filters-right">
      <mc-filters-bundle-date-range
      [config]="newDateRangePicker"
      (filtersChange)="onDateFilterChange($event)">
      </mc-filters-bundle-date-range>
    </div>
  </mc-filters>

  <mc-column-date key="created"></mc-column-date>

  <mc-column key="spamScore">
    <mc-body-cell *mcBodyCellDef="let row">{{row.spamScore >=0 ?  row.spamScore : '-' }}</mc-body-cell>
  </mc-column>

  <mc-column key="detectionLevel">
    <mc-body-cell *mcBodyCellDef="let row">{{(row.detectionLevel | mcSpamDetectionDisplay) || '-' }}</mc-body-cell>
  </mc-column>

  <mc-column-actions key="right-column" 
    mcShowColumnConfig 
    [columnsAlwaysVisible]="columnsAlwaysVisible"
    [columnsToStartHidden]="columnsToStartHidden"
  >
    <mc-row-actions *mcRowActions="let row">
      <li mcRowAction="$I18N_REJECTED_AND_DEFERRED_LIST.MEATBALLS.VIEW_DETAILS" (click)="onRowClick(row)"></li>

      <ng-container *ngIf="row.manageRecipient">
        <li mcRowActionSeparator></li>
        <li mcRowAction="$I18N_REJECTED_AND_DEFERRED_LIST.MEATBALLS.PERMIT_FOR_RECIPIENT" (click)="permitForRecipient(row)"></li>
        <li mcRowAction="$I18N_REJECTED_AND_DEFERRED_LIST.MEATBALLS.BLOCK_FOR_RECIPIENT" (click)="blockForRecipient(row)"></li>
      </ng-container>

    </mc-row-actions>
  </mc-column-actions>

  <mc-empty-results keyTitle="$I18N_REJECTED_AND_DEFERRED_LIST.ERRORS.NO_TABLE_DATA"></mc-empty-results>

</mc-table>
