"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoadLexiconDetailsFailureAction = exports.LoadLexiconDetailsSuccessAction = exports.LoadLexiconDetailsAction = exports.DeleteLexiconActionSuccess = exports.DeleteLexiconActionFail = exports.DeleteLexiconAction = exports.SetLexiconDetailsPaginatorDataAction = exports.OpenEditLexiconAction = exports.OpenCreateNewLexiconAction = exports.LexiconListActions = void 0;
var LexiconListActions;
(function (LexiconListActions) {
    LexiconListActions["OPEN_CREATE_NEW_LEXICON"] = "[SupervisionLexiconList] Open Create New Lexicon";
    LexiconListActions["SET_LEXICON_DETAILS_PAGINATOR_DATA"] = "[SupervisionLexiconList] Set Lexicon Details Paginator Data";
    LexiconListActions["OPEN_EDIT_LEXICON"] = "[SupervisionLexiconList] Open Edit Lexicon";
    LexiconListActions["DELETE_LEXICON"] = "[SupervisionLexiconList] Delete Lexicon";
    LexiconListActions["DELETE_LEXICON_FAIL"] = "[SupervisionLexiconList] Delete Lexicon Fail";
    LexiconListActions["DELETE_LEXICON_SUCCESS"] = "[SupervisionLexiconList] Delete Lexicon Success";
    LexiconListActions["LOAD_LEXICON_DETAILS"] = "[SupervisionLexiconList] Load Lexicon Details";
    LexiconListActions["LOAD_LEXICON_DETAILS_FAILURE"] = "[SupervisionLexiconList] Load Lexicon Details Fail";
    LexiconListActions["LOAD_LEXICON_DETAILS_SUCCESS"] = "[SupervisionLexiconList] Load Lexicon Details Success";
})(LexiconListActions = exports.LexiconListActions || (exports.LexiconListActions = {}));
class OpenCreateNewLexiconAction {
    constructor() {
        this.type = LexiconListActions.OPEN_CREATE_NEW_LEXICON;
    }
}
exports.OpenCreateNewLexiconAction = OpenCreateNewLexiconAction;
class OpenEditLexiconAction {
    constructor(lexiconId) {
        this.lexiconId = lexiconId;
        this.type = LexiconListActions.OPEN_EDIT_LEXICON;
    }
}
exports.OpenEditLexiconAction = OpenEditLexiconAction;
class SetLexiconDetailsPaginatorDataAction {
    constructor(previousLexicon, currentLexicon, nextLexicon, currentLexiconIndex, totalNumberLexicons) {
        this.previousLexicon = previousLexicon;
        this.currentLexicon = currentLexicon;
        this.nextLexicon = nextLexicon;
        this.currentLexiconIndex = currentLexiconIndex;
        this.totalNumberLexicons = totalNumberLexicons;
        this.type = LexiconListActions.SET_LEXICON_DETAILS_PAGINATOR_DATA;
    }
}
exports.SetLexiconDetailsPaginatorDataAction = SetLexiconDetailsPaginatorDataAction;
class DeleteLexiconAction {
    constructor(payload) {
        this.payload = payload;
        this.type = LexiconListActions.DELETE_LEXICON;
    }
}
exports.DeleteLexiconAction = DeleteLexiconAction;
class DeleteLexiconActionFail {
    constructor(payload) {
        this.payload = payload;
        this.type = LexiconListActions.DELETE_LEXICON_FAIL;
    }
}
exports.DeleteLexiconActionFail = DeleteLexiconActionFail;
class DeleteLexiconActionSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = LexiconListActions.DELETE_LEXICON_SUCCESS;
    }
}
exports.DeleteLexiconActionSuccess = DeleteLexiconActionSuccess;
class LoadLexiconDetailsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = LexiconListActions.LOAD_LEXICON_DETAILS;
    }
}
exports.LoadLexiconDetailsAction = LoadLexiconDetailsAction;
class LoadLexiconDetailsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = LexiconListActions.LOAD_LEXICON_DETAILS_SUCCESS;
    }
}
exports.LoadLexiconDetailsSuccessAction = LoadLexiconDetailsSuccessAction;
class LoadLexiconDetailsFailureAction {
    constructor(payload) {
        this.payload = payload;
        this.type = LexiconListActions.LOAD_LEXICON_DETAILS_FAILURE;
    }
}
exports.LoadLexiconDetailsFailureAction = LoadLexiconDetailsFailureAction;
