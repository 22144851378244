"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuditEventAction = exports.AUDIT_EVENT = void 0;
exports.AUDIT_EVENT = 'App Audit log event';
class AuditEventAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.AUDIT_EVENT;
    }
}
exports.AuditEventAction = AuditEventAction;
