<mc-swg-dashboard
  [showNotification]="showNotification"

  [activityChartData]="activityChart$ | async"
  [isActivityChartLoading]="isActivityChartLoading$ | async"
  [activityChartTotalRequests]="activityChartTotalRequests$ | async"

  [topDomainsChartData]="topDomainsChart$ | async"
  [isTopDomainsChartLoading]="isTopDomainsChartLoading$ | async"
  [topDomainsChartTotalRequests]="topDomainsChartTotalRequests$ | async"

  [topCategoriesChartData]="topCategoriesChart$ | async"
  [isTopCategoriesChartLoading]="isTopCategoriesChartLoading$ | async"
  [topCategoriesChartTotalRequests]="topCategoriesChartTotalRequests$ | async"

  [topBlockedDomainsChartData]="topBlockedDomainsChart$ | async"
  [isTopBlockedDomainsChartLoading]="isTopBlockedDomainsChartLoading$ | async"
  [topBlockedDomainsChartTotalRequests]="topBlockedDomainsChartTotalRequests$ | async"

  [topBlockedCategoriesChartData]="topBlockedCategoriesChart$ | async"
  [isTopBlockedCategoriesChartLoading]="isTopBlockedCategoriesChartLoading$ | async"
  [topBlockedCategoriesChartTotalRequests]="topBlockedCategoriesChartTotalRequests$ | async"
></mc-swg-dashboard>
