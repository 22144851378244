"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeviceGroupsWizardContainerComponent = void 0;
const reducers = require("../../reducers");
const device_groups_list_actions_1 = require("../../actions/device-groups-list.actions");
class DeviceGroupsWizardContainerComponent {
    constructor(store, swgDevicesListApiService) {
        this.store = store;
        this.swgDevicesListApiService = swgDevicesListApiService;
        this.deviceGroup$ = this.store.select(reducers.getDeviceGroup);
        this.deviceGroupWizardFlowType$ = this.store.select(reducers.getDeviceGroupWizardFlowType);
        this.devicesInGroup$ = this.store.select(reducers.getDevicesInGroup);
        this.deviceListTableManualPagingMetadata$ = this.store.select(reducers.getDeviceListTableManualPagingMetadata);
        this.isDeviceListTableLoading$ = this.store.select(reducers.getIsDeviceListTableLoading);
        this.deviceCount$ = this.store.select(reducers.getDeviceCount);
        this.addDevicesSubscription = this.swgDevicesListApiService.addDevicesInGroupSubject.subscribe((devices) => {
            this.store.dispatch(new device_groups_list_actions_1.SwgAddDevicesInGroupAction(devices));
        });
        this.removeDevicesSubscription = this.swgDevicesListApiService.removeDevicesFromGroupSubject.subscribe((devices) => {
            this.store.dispatch(new device_groups_list_actions_1.SwgRemoveDevicesFromGroupAction(devices));
        });
        this.apiProcessingInProgress$ = this.store.select(reducers.getApiProcessingInProgress);
        this.loadingForEdit$ = this.store.select(reducers.getIsLoadingForEdit);
    }
    updateDeviceGroupDescr(deviceGroupDescr) {
        this.store.dispatch(new device_groups_list_actions_1.SwgUpdateDeviceGroupDetailsAction(deviceGroupDescr));
    }
    filterDevicesInGroup(filters) {
        this.store.dispatch(new device_groups_list_actions_1.SwgFilterDevicesInGroupAction(filters));
    }
    onPageChanged(pagination) {
        this.store.dispatch(new device_groups_list_actions_1.SwgPageDevicesInGroupAction(pagination));
    }
    onCreateDeviceGroup() {
        this.store.dispatch(new device_groups_list_actions_1.SwgCreateDeviceGroupAction());
    }
    onCancelWizard() {
        this.store.dispatch(new device_groups_list_actions_1.SwgClearDeviceGroupAction());
        this.store.dispatch(new device_groups_list_actions_1.SwgNavigateToDeviceGroupListAction());
    }
    ngOnDestroy() {
        if (this.removeDevicesSubscription) {
            this.removeDevicesSubscription.unsubscribe();
        }
        if (this.addDevicesSubscription) {
            this.addDevicesSubscription.unsubscribe();
        }
    }
}
exports.DeviceGroupsWizardContainerComponent = DeviceGroupsWizardContainerComponent;
