<div class="mc-bg-gray">
  <span role="button" class="far fa-fw fa-times pull-right" (click)="closeAside()"></span>
  <p>{{ '$I18N_REMEDIATION_MESSAGE_DETAILS.HEADER' | translate }}</p>
</div>

<div class="mc-aside-header">
  <span class="mc-subject-label">{{ '$I18N_REMEDIATION_MESSAGE_DETAILS.SUBJECT' | translate}}</span>
  <span class="mc-subject-header">&nbsp;{{ (messageDetails$ | async).subject | translate}}</span>

  <mc-paginator class="mc-subject-inline-paginator"
                *ngIf="pagination$ | async"
                [start]="(pagination$ | async).currentIndex + 1"
                [end]="(pagination$ | async).pageSize"
                [isLoading]="isLoadingMessageDetails$ | async"
                [previousToken]="(pagination$ | async).prevRow"
                [nextToken]="(pagination$ | async).nextRow"
                (paginate)="showMessage($event)">
  </mc-paginator>
</div>


<mc-tab-group themeLight="true">
  <mc-tab name="{{ '$I18N_REMEDIATION_MESSAGE_DETAILS.TABS.MESSAGE' | translate }}">
    <mc-tab-container *ngIf="!(isLoadingMessageDetails$ | async); else spinner">
      <mc-collapsable-panel header="$I18N_REMEDIATION_MESSAGE_DETAILS.SUMMARY_HEADER" [isExpanded]="true">
        <div class="mc-message-summary-container mc-detailed-list" *ngIf="messageDetails$ | async as messageDetail">
          <div class="row mc-detailed-list-row">
            <label class="col-sm-4 mc-detailed-list-label">
              {{ '$I18N_REMEDIATION_MESSAGE_DETAILS.SUMMARY.FROM_ENVELOPE' | translate }}
            </label>
            <div class="col-sm-8">{{ messageDetail.fromEnvelope }}</div>
          </div>
          <div class="row mc-detailed-list-row">
            <label class="col-sm-4 mc-detailed-list-label">
              {{ '$I18N_REMEDIATION_MESSAGE_DETAILS.SUMMARY.FROM_HEADER' | translate }}
            </label>
            <div class="col-sm-8">{{ messageDetail.fromHeader }}</div>
          </div>
          <div class="row mc-detailed-list-row">
            <label
              class="col-sm-4 mc-detailed-list-label">{{ '$I18N_REMEDIATION_MESSAGE_DETAILS.SUMMARY.TO' | translate }}
            </label>
            <div class="col-sm-8">
              <span>{{ recipientsTo }}</span>
              <mc-collapsable-panel class="mc-recipients" *ngIf="showMoreTo">{{ showMoreTo }}</mc-collapsable-panel>
            </div>
          </div>
          <div class="row mc-detailed-list-row">
            <label
              class="col-sm-4 mc-detailed-list-label">{{ '$I18N_REMEDIATION_MESSAGE_DETAILS.SUMMARY.CC' | translate }}
            </label>
            <div class="col-sm-8" id="ccRecipients">
                <span *ngIf="messageDetail.cc && messageDetail.cc.length > 0; else noEmails">
                  <span>{{ recipientsCC }}</span>
                  <mc-collapsable-panel class="mc-recipients" *ngIf="showMoreCC">{{ showMoreCC }}</mc-collapsable-panel>
                </span>
            </div>
          </div>
          <div class="row mc-detailed-list-row">
            <mc-tooltip class="col-sm-4 text-right"
                        labelText="{{ '$I18N_REMEDIATION_MESSAGE_DETAILS.SUMMARY.OTHER_RECIPIENTS' | translate }}"
                        popoverContent="{{ '$I18N_REMEDIATION_MESSAGE_DETAILS.NOTIFICATION.OTHER_RECIPIENTS_TOOLTIP' | translate }}"
                        placement="top">
            </mc-tooltip>
            <div class="col-sm-8" id="bccRecipients">
                <span *ngIf="messageDetail.bcc && messageDetail.bcc.length > 0; else noEmails">
                  <span>{{ recipientsBcc }}</span>
                  <mc-collapsable-panel class="mc-recipients"
                                        *ngIf="showMoreBcc">{{ showMoreBcc }}</mc-collapsable-panel>
                </span>
            </div>
          </div>
          <div class="row mc-detailed-list-row">
            <label class="col-sm-4 mc-detailed-list-label">
              {{ '$I18N_REMEDIATION_MESSAGE_DETAILS.SUMMARY.SUBJECT' | translate }}
            </label>
            <div class="col-sm-8">{{ messageDetail.subject }}</div>
          </div>
          <div class="row mc-detailed-list-row">
            <label class="col-sm-4 mc-detailed-list-label">
              {{ '$I18N_REMEDIATION_MESSAGE_DETAILS.SUMMARY.SENT' | translate }}
            </label>
            <div class="col-sm-8">{{ messageDetail.sent | mcDate }}</div>
          </div>
          <div class="row mc-detailed-list-row">
            <label class="col-sm-4 mc-detailed-list-label">
              {{ '$I18N_REMEDIATION_MESSAGE_DETAILS.SUMMARY.PROCESSED' | translate }}
            </label>
            <div class="col-sm-8">{{ messageDetail.processed | mcDate }}</div>
          </div>
        </div>
      </mc-collapsable-panel>
      <hr>
      <mc-collapsable-panel class="mc-attachments-panel"
                            header="{{ '$I18N_REMEDIATION_MESSAGE_DETAILS.ATTACHMENTS' | translate:{count: (messageDetails$ | async).attachments.length} }}"
                            [isExpanded]="false">
        <div class="panel-body">
          <mc-table
            [columns]="attachmentsColumns"
            [data]="displayableThreatDetails$ | async"
            [isLoading]="isLoadingMessageDetails$ | async"
            translatePrefix="$I18N_REMEDIATION_MESSAGE_DETAILS"
            class="mc-attachments-container">

            <mc-column key="scan_result">
              <mc-body-cell *mcBodyCellDef="let row">
                <span>-</span>
              </mc-body-cell>
            </mc-column>

            <mc-column key="filename">
              <mc-body-cell *mcBodyCellDef="let row"><a role="button" class="mc-attachment-link" (click)="downloadAttachment(row)">{{row.filename}}</a></mc-body-cell>
            </mc-column>
            <mc-column-size key="size"></mc-column-size>
            <mc-column-actions key="action">
              <mc-row-actions *mcRowActions="let row">
                <li mcRowAction="$I18N_REMEDIATION_MESSAGE_DETAILS.DOWNLOAD" (click)="downloadAttachment(row)"></li>
              </mc-row-actions>
            </mc-column-actions>
          </mc-table>
        </div>
      </mc-collapsable-panel>
      <hr>
      <div>
        <mc-collapsable-panel class="mc-content-urls-panel"
                              header="{{ '$I18N_REMEDIATION_MESSAGE_DETAILS.URLS' | translate:{ count: contentUrls.length } }}"
                              [isExpanded]="false">
          <div class="panel-body">
            <p *ngFor="let contentUrl of (messageDetails$ | async).contentUrls">{{contentUrl}}</p>
          </div>
        </mc-collapsable-panel>
        <hr>
      </div>
      <mc-collapsable-panel class="mc-email-preview-panel" header="$I18N_REMEDIATION_MESSAGE_DETAILS.MESSAGE_BODY" [isExpanded]="true">
        <mc-email-preview></mc-email-preview>
      </mc-collapsable-panel>
    </mc-tab-container>
  </mc-tab>
  <mc-tab name="{{ '$I18N_REMEDIATION_MESSAGE_DETAILS.TABS.HEADER' | translate }}">
    <mc-tab-container *ngIf="!(isLoadingMessageDetails$ | async); else spinner" class="mc-rem-tab-header">
      <mc-collapsable-panel header="$I18N_REMEDIATION_MESSAGE_DETAILS.TABS.HEADER" [isExpanded]="true">
        <div class="panel-body" [innerHtml]="(messageDetails$ | async).transmissionInfo"></div>
      </mc-collapsable-panel>
    </mc-tab-container>
  </mc-tab>
  <mc-tab name="{{ '$I18N_REMEDIATION_MESSAGE_DETAILS.TABS.STATUS' | translate }}">
    <mc-tab-container>
      <mc-aside-recipients [emptyResultsMessage]="statusNotUpdatedNotification"
        class="mc-recipients" translatePrefix="$I18N_REMEDIATION_MESSAGE_DETAILS.RECIPIENTS"
        [message]="(messageDetails$ | async)" *ngIf="!(isLoadingMessageDetails$ | async); else spinner">
      </mc-aside-recipients>
    </mc-tab-container>
  </mc-tab>
</mc-tab-group>

<ng-template #spinner>
  <div class="mc-with-spinner" *ngIf="!(hasGenericError$ | async); else loadError">
    <mc-spinner></mc-spinner>
  </div>
</ng-template>

<ng-template #noEmails>
  <span class="text-muted">-</span>
</ng-template>

<ng-template #loadError>
  <div class="mc-with-spinner">
    <mc-inline-notification [notification]="inlineNotificationConfigError"></mc-inline-notification>
  </div>
</ng-template>
