"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ6 = exports.ɵ5 = exports.ɵ4 = exports.ɵ3 = exports.ɵ2 = exports.ɵ1 = exports.ɵ0 = exports.isChartLoadedFailed = exports.getMailboxStatus = exports.isLoadingChartData = exports.getSyncTaskData = exports.getHealthScore = exports.isModalOpen = void 0;
const store_1 = require("@ngrx/store");
const reducers_1 = require("../reducers");
const ɵ0 = state => state.syncRecoverOverview;
exports.ɵ0 = ɵ0;
const syncRecoverOverviewState = store_1.createSelector(reducers_1.getSyncRecoverOverviewState, ɵ0);
const ɵ1 = state => state.isModalOpen;
exports.ɵ1 = ɵ1;
exports.isModalOpen = store_1.createSelector(syncRecoverOverviewState, ɵ1);
const ɵ2 = state => state.chartData.healthScore;
exports.ɵ2 = ɵ2;
exports.getHealthScore = store_1.createSelector(syncRecoverOverviewState, ɵ2);
const ɵ3 = state => state.chartData.syncTasks;
exports.ɵ3 = ɵ3;
exports.getSyncTaskData = store_1.createSelector(syncRecoverOverviewState, ɵ3);
const ɵ4 = state => state.isLoadingChartData;
exports.ɵ4 = ɵ4;
exports.isLoadingChartData = store_1.createSelector(syncRecoverOverviewState, ɵ4);
const ɵ5 = state => state.chartData.mailboxes;
exports.ɵ5 = ɵ5;
exports.getMailboxStatus = store_1.createSelector(syncRecoverOverviewState, ɵ5);
const ɵ6 = state => state.chartLoadedFailed;
exports.ɵ6 = ɵ6;
exports.isChartLoadedFailed = store_1.createSelector(syncRecoverOverviewState, ɵ6);
