"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PoliciesTableComponent = void 0;
const core_1 = require("@angular/core");
const policies_list_table_static_values_1 = require("./policies-list-table.static-values");
const table_store_1 = require("@mimecast-ui/table-store");
class PoliciesTableComponent extends table_store_1.TableBaseComponent {
    constructor(tableStoreStore, capsService) {
        super(tableStoreStore, 'policiesListTable');
        this.capsService = capsService;
        this.canModifyPolicyExpression = 'Permission.POLICIES_EDIT';
        this.searchSections = policies_list_table_static_values_1.tableSectionSearch;
        this.selectedRows = [];
        this.columnsToStartHidden = [...policies_list_table_static_values_1.tableColumnsStartHidden];
        this.columnsAlwaysVisible = [...policies_list_table_static_values_1.tableColumnsAlwaysVisible];
        this.onPaginatorChanged = new core_1.EventEmitter();
        this.onSearchFilterChanged = new core_1.EventEmitter();
        this.onRowClicked = new core_1.EventEmitter();
        this.onPolicyEdit = new core_1.EventEmitter();
        this.onPolicyDuplicate = new core_1.EventEmitter();
        this.onPolicyDelete = new core_1.EventEmitter();
        this.onCreateNewPolicy = new core_1.EventEmitter();
        this.setupSubscriptions();
    }
    ngOnInit() {
        this.checkIEPPackageAndSetTableColumns();
        super.ngOnInit();
    }
    setupSubscriptions() {
        this.tableDataSubscription = this.tableData$.subscribe((data) => {
            if (data && data.length) {
                this.tableData = data[0]['policies'];
            }
        });
        this.tableMetaDataSubscription = this.metaData$.subscribe(data => {
            if (data && data.pagination) {
                this.metadata = {
                    pagination: Object.assign(Object.assign({}, data.pagination), { totalRows: data.pagination.totalCount })
                };
            }
        });
        this.capsCreateEditDuplicateDeleteSubscription = this.capsService
            .evalCapabilitiesExpression(this.canModifyPolicyExpression)
            .subscribe(canDoActions => {
            if (canDoActions) {
                this.canModifyPolicy = canDoActions;
            }
        });
    }
    changePaginator(pagination) {
        this.onPaginatorChanged.emit(pagination);
        super.onPaginationChange(pagination);
    }
    changeSearchFilter(filter) {
        this.onFilterChange(Object.assign(Object.assign({}, this.currentFilters), { searchBy: {
                fieldName: filter.selectValue,
                value: filter.searchQuery
            } }));
    }
    addPolicy() {
        this.onCreateNewPolicy.emit();
    }
    clickRow(row) {
        this.onRowClick(row);
        this.onRowClicked.emit(row);
    }
    editPolicy(policy) {
        this.onPolicyEdit.emit(policy);
    }
    duplicatePolicy(policy) {
        this.onPolicyDuplicate.emit(policy);
    }
    deletePolicy(policy) {
        this.onPolicyDelete.emit(policy);
    }
    displayStatusIcon(policy) {
        if (policy.status === true) {
            return policies_list_table_static_values_1.icons.circleCheck + ' ' + policies_list_table_static_values_1.icons.succesColor;
        }
        else {
            return policies_list_table_static_values_1.icons.circleCross + ' ' + policies_list_table_static_values_1.icons.mutedColor;
        }
    }
    displayStatusLabel(policy) {
        return policy.status ? policies_list_table_static_values_1.status.enabled : policies_list_table_static_values_1.status.disabled;
    }
    displayBypassIcon(policy) {
        return policy.bypass ? policies_list_table_static_values_1.icons.check : policies_list_table_static_values_1.icons.hidden;
    }
    displayCheckIcon(bypass, policyValue) {
        return !bypass && policyValue ? policies_list_table_static_values_1.icons.check : policies_list_table_static_values_1.icons.hidden;
    }
    displayLabelValue(policy) {
        return policy.bypass ? '-' : '';
    }
    checkIEPPackageAndSetTableColumns() {
        if (this.hasIEPPackage || this.isAAAOrFAAUser) {
            this.columns = policies_list_table_static_values_1.tableColumns;
        }
        else {
            this.columns = policies_list_table_static_values_1.tableColumns.filter(column => {
                return column !== 'outbound' && column !== 'journal';
            });
        }
    }
    ngOnDestroy() {
        this.tableDataSubscription.unsubscribe();
        this.tableMetaDataSubscription.unsubscribe();
        this.capsCreateEditDuplicateDeleteSubscription.unsubscribe();
        super.ngOnDestroy();
    }
}
exports.PoliciesTableComponent = PoliciesTableComponent;
