"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentSearchFailAction = exports.ContentSearchSuccessAction = exports.ContentSearchAction = exports.TokenSearchFailAction = exports.TokenSearchSuccessAction = exports.TokenSearchAction = exports.TokenSearchSliderFailAction = exports.TokenSearchSliderSuccessAction = exports.TokenSearchSliderAction = exports.InvalidTokenAction = exports.INVALID_TOKEN = exports.CONTENT_SEARCH_FAIL = exports.CONTENT_SEARCH_SUCCESS = exports.CONTENT_SEARCH = exports.TOKEN_SEARCH_FAIL = exports.TOKEN_SEARCH_SUCCESS = exports.TOKEN_SEARCH = exports.TOKEN_SEARCH_SLIDER_FAIL = exports.TOKEN_SEARCH_SLIDER_SUCCESS = exports.TOKEN_SEARCH_SLIDER = void 0;
exports.TOKEN_SEARCH_SLIDER = '[Archive Viewer] Slider Search Token';
exports.TOKEN_SEARCH_SLIDER_SUCCESS = '[Archive Viewer] Slider Search Token Results Load Success';
exports.TOKEN_SEARCH_SLIDER_FAIL = '[Archive Viewer] Slider Search Token Results Load Fail';
exports.TOKEN_SEARCH = '[Archive Viewer] Search Token';
exports.TOKEN_SEARCH_SUCCESS = '[Archive Viewer] Search Token Results Load Success';
exports.TOKEN_SEARCH_FAIL = '[Archive Viewer] Search Token Results Load Fail';
exports.CONTENT_SEARCH = '[Archive Viewer] Search Content';
exports.CONTENT_SEARCH_SUCCESS = '[Archive Viewer] Search Content Results Load Success';
exports.CONTENT_SEARCH_FAIL = '[Archive Viewer] Search Content Results Load Fail';
exports.INVALID_TOKEN = '[Archive Viewer] Invalid Token';
class InvalidTokenAction {
    constructor() {
        this.type = exports.INVALID_TOKEN;
    }
}
exports.InvalidTokenAction = InvalidTokenAction;
class TokenSearchSliderAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.TOKEN_SEARCH_SLIDER;
    }
}
exports.TokenSearchSliderAction = TokenSearchSliderAction;
class TokenSearchSliderSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.TOKEN_SEARCH_SLIDER_SUCCESS;
    }
}
exports.TokenSearchSliderSuccessAction = TokenSearchSliderSuccessAction;
class TokenSearchSliderFailAction {
    constructor() {
        this.type = exports.TOKEN_SEARCH_SLIDER_FAIL;
    }
}
exports.TokenSearchSliderFailAction = TokenSearchSliderFailAction;
class TokenSearchAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.TOKEN_SEARCH;
    }
}
exports.TokenSearchAction = TokenSearchAction;
class TokenSearchSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.TOKEN_SEARCH_SUCCESS;
    }
}
exports.TokenSearchSuccessAction = TokenSearchSuccessAction;
class TokenSearchFailAction {
    constructor() {
        this.type = exports.TOKEN_SEARCH_FAIL;
    }
}
exports.TokenSearchFailAction = TokenSearchFailAction;
class ContentSearchAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.CONTENT_SEARCH;
    }
}
exports.ContentSearchAction = ContentSearchAction;
class ContentSearchSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.CONTENT_SEARCH_SUCCESS;
    }
}
exports.ContentSearchSuccessAction = ContentSearchSuccessAction;
class ContentSearchFailAction {
    constructor() {
        this.type = exports.CONTENT_SEARCH_FAIL;
    }
}
exports.ContentSearchFailAction = ContentSearchFailAction;
