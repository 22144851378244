"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgLocationEditSidebarPageComponent = void 0;
const reducers = require("app-new/swg/locations/reducers/index");
const swg_location_crud_actions_1 = require("app-new/swg/locations/actions/swg-location-crud.actions");
const swg_location_sidebar_actions_1 = require("app-new/swg/locations/actions/swg-location-sidebar.actions");
class SwgLocationEditSidebarPageComponent {
    constructor(store) {
        this.store = store;
    }
    ngOnInit() {
        this.locationItem$ = this.store.select(reducers.getLocationItem);
        this.isEditFlow$ = this.store.select(reducers.getIsEditFlow);
        this.isApiProcessing$ = this.store.select(reducers.getIsApiProcessing);
    }
    closeSidebar() {
        this.store.dispatch(new swg_location_sidebar_actions_1.SwgLocationsEditAddSidebarCloseAction());
    }
    editItem(swgItem) {
        this.store.dispatch(new swg_location_crud_actions_1.SwgLocationUpdateAction(swgItem));
    }
}
exports.SwgLocationEditSidebarPageComponent = SwgLocationEditSidebarPageComponent;
