"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IdentityCreatePolicyWizardComponent = void 0;
const rxjs_1 = require("rxjs");
const policy_wizard_1 = require("../../reducers/policy-wizard");
const policy_wizard_models_1 = require("../../models/policy-wizard.models");
const actions = require("../../actions/policy-wizard/wizard.actions");
const policy_1 = require("../../models/policy");
const operators_1 = require("rxjs/operators");
class IdentityCreatePolicyWizardComponent {
    constructor(store, translateService) {
        this.store = store;
        this.translateService = translateService;
        this.saveButtonLabel$ = new rxjs_1.BehaviorSubject(this.translateService.instant('$I18N_IDENTITY_CREATE_POLICY_WIZARD.BUTTONS.CREATE'));
        this.keyTitle$ = new rxjs_1.BehaviorSubject('$I18N_IDENTITY_CREATE_POLICY_WIZARD.CREATE_POLICY_TITLE');
    }
    ngOnInit() {
        this.mode$ = this.store.select(policy_wizard_1.getWizardMode);
        this.isLoading$ = this.store.select(policy_wizard_1.getIsLoading);
        this.canContinue$ = this.store.select(policy_wizard_1.getCanContinue);
        this.apiResponsePending$ = this.store.select(policy_wizard_1.getApiResponsePending);
        this.modeSubscription = this.mode$.pipe(operators_1.filter(mode => !!mode)).subscribe(mode => {
            this.mode = mode;
            if (mode === policy_wizard_models_1.WizardMode.EDIT) {
                this.keyTitle$.next(this.translateService.instant('$I18N_IDENTITY_CREATE_POLICY_WIZARD.EDIT_POLICY_TITLE'));
                this.saveButtonLabel$.next(this.translateService.instant('$I18N_IDENTITY_CREATE_POLICY_WIZARD.SAVE'));
            }
        });
    }
    onStepChange(currentStep) {
        this.store.dispatch(new actions.UpdateCurrentStep(currentStep));
    }
    finish() {
        if (this.mode === policy_wizard_models_1.WizardMode.CREATE) {
            this.store.dispatch(new actions.CreateAction());
        }
        else {
            this.store.dispatch(new actions.UpdateAction());
        }
    }
    cancel() {
        if (this.mode === policy_wizard_models_1.WizardMode.CREATE) {
            this.store.dispatch(new actions.CancelModalOpenAction(policy_1.PolicyCancelModalSource.CREATE_POLICY));
        }
        else {
            this.store.dispatch(new actions.CancelModalOpenAction(policy_1.PolicyCancelModalSource.EDIT_POLICY));
        }
    }
    ngOnDestroy() {
        this.modeSubscription.unsubscribe();
    }
}
exports.IdentityCreatePolicyWizardComponent = IdentityCreatePolicyWizardComponent;
