<mc-modal-header
  headerTitle="{{'$I18N_UPDATE_STATUS_CONFIRMATION_MODAL.TITLE' | translate:{ updateType: updateType } }}">
</mc-modal-header>

<mc-modal-body>
  <div>
    <p data-test="update-status-confirmation-message">
      {{ '$I18N_UPDATE_STATUS_CONFIRMATION_MODAL.MESSAGE.' + updateType | uppercase | translate }}
    </p>
  </div>
</mc-modal-body>

<mc-modal-footer>
  <button data-test="update-cancel-button" type="button" class="btn btn-secondary" (click)="close(false)">
    {{ '$I18N_UPDATE_STATUS_CONFIRMATION_MODAL.CONTROL.CANCEL' | translate }}
  </button>

  <button data-test="update-confirmation-button" type="button" class="btn btn-primary" (click)="close(true)">
    {{ '$I18N_UPDATE_STATUS_CONFIRMATION_MODAL.CONTROL.' + updateType | uppercase | translate }}
  </button>
</mc-modal-footer>
