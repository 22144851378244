"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getGroups = exports.getExcludeGroup = exports.getNotifyGroup = exports.reducer = exports.initialState = void 0;
const settingsFormAction = require("../../actions/settings/settings-form.actions");
exports.initialState = {
    notifyGroup: { description: '$I18N_REMEDIATION_SETTINGS_TAB.GROUPS.DEFAULT_MSG' },
    excludeGroup: { description: '$I18N_REMEDIATION_SETTINGS_TAB.GROUPS.DEFAULT_MSG' }
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case settingsFormAction.SET_NOTIFY_GROUP:
            return Object.assign(Object.assign({}, state), { notifyGroup: action.payload });
        case settingsFormAction.SET_EXCLUDE_GROUP:
            return Object.assign(Object.assign({}, state), { excludeGroup: action.payload });
        case settingsFormAction.SET_EXCLUDE_AND_NOTIFY_GROUP:
            return Object.assign(Object.assign({}, state), { notifyGroup: action.groups[0] || exports.initialState.notifyGroup, excludeGroup: action.groups[1] || exports.initialState.excludeGroup });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getNotifyGroup = (state) => state.notifyGroup;
exports.getNotifyGroup = getNotifyGroup;
const getExcludeGroup = (state) => state.excludeGroup;
exports.getExcludeGroup = getExcludeGroup;
const getGroups = (state) => state;
exports.getGroups = getGroups;
