"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DashboardTabComponent = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const reducers_1 = require("app-new/archive/supervision/reducers");
const statistics_actions_1 = require("app-new/archive/supervision/actions/statistics.actions");
const queue_type_enum_1 = require("app-new/archive/supervision/types/queue-type.enum");
const dashboard_settings_actions_1 = require("app-new/archive/supervision/actions/dashboard-settings.actions");
const components_1 = require("@mimecast-ui/components");
const supervision_dashboard_settings_aside_container_component_1 = require("../../../asides/supervision-dashboard-settings/supervision-dashboard-settings-aside-container.component");
class DashboardTabComponent {
    constructor(store, asideService) {
        this.store = store;
        this.asideService = asideService;
        this.hasData$ = rxjs_1.of(false);
        this.destroy$ = new rxjs_1.Subject();
    }
    ngOnInit() {
        this.loading$ = this.store.select(reducers_1.getDashboardLoading);
        this.dashboardStatistics$ = this.store.select(reducers_1.getDashboardStatistics);
        this.hasData$ = this.dashboardStatistics$.pipe(operators_1.map(stats => stats !== undefined && stats !== null));
        this.store
            .select(reducers_1.getDashboardSettings)
            .pipe(operators_1.takeUntil(this.destroy$))
            .subscribe(settings => {
            if (settings && settings.queueType === queue_type_enum_1.QueueType.REVIEW) {
                this.reviewSettings = settings;
            }
            else if (settings && settings.queueType === queue_type_enum_1.QueueType.ESCALATION) {
                this.escalationSettings = settings;
            }
        });
        this.getSettings(queue_type_enum_1.QueueType.REVIEW);
        this.getSettings(queue_type_enum_1.QueueType.ESCALATION);
        this.getStatistics();
    }
    getStatistics() {
        this.store.dispatch(new statistics_actions_1.GetDashboardStatisticsAction());
    }
    getSettings(queueType) {
        this.store.dispatch(new dashboard_settings_actions_1.GetDashboardSettingsAction(queueType));
    }
    openSettings(queueType) {
        this.getSettings(queueType);
        const asideConfig = new components_1.AsideConfig();
        asideConfig.size = 'md';
        this.closeSettings();
        this.aside = this.asideService.open(supervision_dashboard_settings_aside_container_component_1.SupervisionDashboardSettingsAsideContainerComponent, asideConfig);
        this.aside
            .afterClose()
            .pipe(operators_1.first())
            .subscribe(refreshRequired => {
            if (refreshRequired) {
                this.getSettings(queue_type_enum_1.QueueType.ESCALATION);
                this.getSettings(queue_type_enum_1.QueueType.REVIEW);
                this.getStatistics();
            }
        });
    }
    closeSettings() {
        if (this.aside !== undefined) {
            this.aside.close();
            this.aside = undefined;
        }
    }
    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
}
exports.DashboardTabComponent = DashboardTabComponent;
