"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TreeComponent = void 0;
const core_1 = require("@angular/core");
const folder_1 = require("app-new/components/tree/model/folder");
const email_1 = require("app-new/components/tree/model/items/email");
const file_1 = require("app-new/components/tree/model/items/file");
const view_type_1 = require("app-new/sync-recover/onedrive/containers/drives/detail/view-type");
class TreeComponent {
    constructor(capManager) {
        this.capManager = capManager;
        this.onNodeClicked = new core_1.EventEmitter();
        this.capManager
            .hasOnedriveReadPermission()
            .subscribe(hasReadPermission => (this.hasReadPermission = hasReadPermission));
        this.capManager
            .hasOnedriveEditPermission()
            .subscribe(hasEditPermission => (this.hasEditPermission = hasEditPermission));
        this.capManager
            .hasOnedriveContentViewPermission()
            .subscribe(hasContentViewPermission => (this.hasContentViewPermission = hasContentViewPermission));
        this.capManager
            .hasOnedriveExportAndRestorePermission()
            .subscribe(hasExportAndRestorePermission => (this.hasOneDriveExportAndRestorePermission = hasExportAndRestorePermission));
    }
    nodeIsFolder(node) {
        return node instanceof folder_1.Folder;
    }
    nodeIsEmail(node) {
        return node instanceof email_1.Email;
    }
    nodeIsFile(node) {
        return node instanceof file_1.File;
    }
    folderHasExportRestorePermission(node) {
        return this.nodeIsFolder(node) && this.hasExportAndRestorePermission();
    }
    fileHasContentViewPermission(node) {
        return this.nodeIsFile(node) && this.hasContentViewPermission;
    }
    hasExportAndRestorePermission() {
        return this.hasOneDriveExportAndRestorePermission;
    }
    hasReadEditPermission() {
        return this.hasReadPermission && this.hasEditPermission;
    }
    showDeletedBadge(item) {
        return item.isDeleted() && this.viewTypeIsArchive();
    }
    viewTypeIsArchive() {
        return this.viewType === view_type_1.ViewType.ARCHIVE;
    }
    onFilterChange() {
        // todo: feature to be implemented
    }
}
exports.TreeComponent = TreeComponent;
