<div class="mc-threat-detections mc-list-panel-body" [ngClass]="showAppCounts() ? null : 'mc-threat-detections-without-app-counts'">
  <ng-container *ngIf="!loading; else loadingSpinner">
    <ng-container *ngIf="getAllScannedFiles() === 0; else threatDetections">
      <mc-swg-chart-no-data-message
        [message]="'$I18N_SWG_APPLICATIONS_DASHBOARD_CHART_NO_DATA'"
      ></mc-swg-chart-no-data-message>
    </ng-container>
    <ng-template #threatDetections>
      <div class="row text-center" [ngClass]="showAppCounts() ? 'mc-threat-detections-total' : null">
        <span class="mc-bio-hazard-icon fas fa-biohazard"> </span>
        <span class="mc-bio-hazard-message">
          <span class="mc-detected-total">   {{ (getAllDetectedFiles()) | number:'1.0':'en-US'}}</span>
          <span class="mc-total-scanned">of {{getAllScannedFiles() | number:'1.0':'en-US'}} </span>
        </span>
      </div>

      <div class="mc-provider-items">
        <div class="mc-provider-item" *ngIf="isSupported(msTeams) && verdictsDistributedByCloudApps[msTeams]">
          <div class="mc-provider-details">
            <mc-provider-icon [providerType]="msTeams"></mc-provider-icon>
            <div class="panel-padding-top">
              <span class="mc-detected">   {{verdictsDistributedByCloudApps[msTeams]?.detectedScannedFiles | number:'1.0':'en-US'}} </span> of
              <span class="mc-total-scanned-by-app"> {{verdictsDistributedByCloudApps[msTeams]?.totalScannedFiles}} </span>
            </div>
          </div>
        </div>
        <div class="mc-provider-item" *ngIf="isSupported(box) && verdictsDistributedByCloudApps[box]">
          <div class="mc-provider-details">
            <mc-provider-icon [providerType]="box"></mc-provider-icon>
            <div class="panel-padding-top">
              <span class="mc-detected">   {{verdictsDistributedByCloudApps['box']?.detectedScannedFiles  | number:'1.0':'en-US'}} </span> of
              <span class="mc-total-scanned-by-app"> {{verdictsDistributedByCloudApps['box']?.detectedScannedFiles  }} </span>
            </div>

          </div>
        </div>
        <div class="mc-provider-item" *ngIf="isSupported(oneDrive) && verdictsDistributedByCloudApps[oneDrive]">
          <div class="mc-provider-details">
            <mc-provider-icon [providerType]="oneDrive"></mc-provider-icon>
            <div class="panel-padding-top">
              <span class="mc-detected">   {{verdictsDistributedByCloudApps['one_drive']?.detectedScannedFiles  | number:'1.0':'en-US'}} </span> of
              <span class="mc-total-scanned-by-app"> {{verdictsDistributedByCloudApps['one_drive']?.detectedScannedFiles  }} </span>
            </div>
          </div>
        </div>

      </div>
    </ng-template>
  </ng-container>
  <ng-template #loadingSpinner>
    <mc-spinner class="mc-chart-widget-spinner"></mc-spinner>
  </ng-template>

</div>
