"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SetDashboardSettingsFailAction = exports.SetDashboardSettingsSuccessAction = exports.SetDashboardSettingsAction = exports.GetDashboardSettingsFailAction = exports.GetDashboardSettingsSuccessAction = exports.GetDashboardSettingsAction = exports.DashboardSettingsActions = void 0;
var DashboardSettingsActions;
(function (DashboardSettingsActions) {
    DashboardSettingsActions["GET_DASHBOARD_SETTINGS"] = "[SupervisionSettings] Get Dashboard Settings";
    DashboardSettingsActions["GET_DASHBOARD_SETTINGS_SUCCESS"] = "[SupervisionSettings] Get Dashboard Settings Success";
    DashboardSettingsActions["GET_DASHBOARD_SETTINGS_FAIL"] = "[SupervisionSettings] Get Dashboard Settings Fail";
    DashboardSettingsActions["SET_DASHBOARD_SETTINGS"] = "[SupervisionSettings] Set Dashboard Settings";
    DashboardSettingsActions["SET_DASHBOARD_SETTINGS_SUCCESS"] = "[SupervisionSettings] Set Dashboard Settings Success";
    DashboardSettingsActions["SET_DASHBOARD_SETTINGS_FAIL"] = "[SupervisionSettings] Set Dashboard Settings Fail";
})(DashboardSettingsActions = exports.DashboardSettingsActions || (exports.DashboardSettingsActions = {}));
class GetDashboardSettingsAction {
    constructor(queueType) {
        this.queueType = queueType;
        this.type = DashboardSettingsActions.GET_DASHBOARD_SETTINGS;
    }
}
exports.GetDashboardSettingsAction = GetDashboardSettingsAction;
class GetDashboardSettingsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = DashboardSettingsActions.GET_DASHBOARD_SETTINGS_SUCCESS;
    }
}
exports.GetDashboardSettingsSuccessAction = GetDashboardSettingsSuccessAction;
class GetDashboardSettingsFailAction {
    constructor(payload) {
        this.payload = payload;
        this.type = DashboardSettingsActions.GET_DASHBOARD_SETTINGS_FAIL;
    }
}
exports.GetDashboardSettingsFailAction = GetDashboardSettingsFailAction;
class SetDashboardSettingsAction {
    constructor(settings) {
        this.settings = settings;
        this.type = DashboardSettingsActions.SET_DASHBOARD_SETTINGS;
    }
}
exports.SetDashboardSettingsAction = SetDashboardSettingsAction;
class SetDashboardSettingsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = DashboardSettingsActions.SET_DASHBOARD_SETTINGS_SUCCESS;
    }
}
exports.SetDashboardSettingsSuccessAction = SetDashboardSettingsSuccessAction;
class SetDashboardSettingsFailAction {
    constructor(payload) {
        this.payload = payload;
        this.type = DashboardSettingsActions.SET_DASHBOARD_SETTINGS_FAIL;
    }
}
exports.SetDashboardSettingsFailAction = SetDashboardSettingsFailAction;
