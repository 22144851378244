"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CleanStore = exports.RemoveFolderAction = exports.PushFolderAction = exports.CLEAN_STORE = exports.REMOVE_FOLDER = exports.PUSH_FOLDER = exports.GET_FOLDERS = void 0;
exports.GET_FOLDERS = '[Folders cart] Get Folders';
exports.PUSH_FOLDER = '[Folders cart] Push a folder';
exports.REMOVE_FOLDER = '[Folders cart] Remove a folder';
exports.CLEAN_STORE = '[Folders cart] Clean Store';
class PushFolderAction {
    constructor(folder) {
        this.folder = folder;
        this.type = exports.PUSH_FOLDER;
    }
}
exports.PushFolderAction = PushFolderAction;
class RemoveFolderAction {
    constructor(folder) {
        this.folder = folder;
        this.type = exports.REMOVE_FOLDER;
    }
}
exports.RemoveFolderAction = RemoveFolderAction;
class CleanStore {
    constructor() {
        this.type = exports.CLEAN_STORE;
    }
}
exports.CleanStore = CleanStore;
