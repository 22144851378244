"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminPreferencesApiService = void 0;
const operators_1 = require("rxjs/operators");
class AdminPreferencesApiService {
    constructor(http) {
        this.http = http;
    }
    getPreferences() {
        return this.http
            .post('/api/admin/get-preferences', undefined)
            .pipe(operators_1.map(response => response.first));
    }
    savePreferences(preferences) {
        return this.http
            .post('/api/admin/set-preferences', { preferences })
            .pipe(operators_1.map(response => response.first));
    }
}
exports.AdminPreferencesApiService = AdminPreferencesApiService;
