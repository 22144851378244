<mc-inline-notification *ngIf="!hidePolicyConflict" [notification]="policyConflictInlineNotification"
  class="mc-service-provider-settings-notification-action"></mc-inline-notification>

<div *ngIf="hidePolicyConflict">
  <mc-collapsable-panel header="$I18N_AP_VIEW_POLICY_DETAILS_SIDEPANEL.DETAILS.HEADER" [isExpanded]="true">
  <div class="mc-detailed-list">
    <div class="row mc-detailed-list-row">
      <div class="col-sm-4 mc-detailed-list-label">
        {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.ADVANCEDOPTIONS.POLICYSTATUS' | translate}}
      </div>
      <div class="mc-detailed-list-column">{{(policyDetails.enabled ?
        '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.ENABLED' :
        '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.DISABLED') | translate }}
      </div>
    </div>
    <div class="row mc-detailed-list-row">
      <div
        class="col-sm-4 mc-detailed-list-label">{{'$I18N_AP_VIEW_POLICY_DETAILS_SIDEPANEL.DETAILS.POLICY_NAME' | translate}}
      </div>
      <div class="mc-detailed-list-column">{{policy.description }}</div>
    </div>

    <div class="row mc-detailed-list-row">
      <div
        class="col-sm-4 mc-detailed-list-label">{{'$I18N_AP_VIEW_POLICY_DETAILS_SIDEPANEL.DETAILS.DURATION' | translate}}
      </div>
      <div class="mc-detailed-list-column"
           *ngIf="policyDetails.toEternal">{{ '$I18N_AP_VIEW_POLICY_DETAILS_SIDEPANEL.DETAILS.PERPETUAL' | translate }}</div>
      <div class="mc-detailed-list-column" *ngIf="!policyDetails.toEternal">
        {{ '$I18N_AP_VIEW_POLICY_DETAILS_SIDEPANEL.DETAILS.FROMDATE' | translate }} {{policyDetails.fromDate| mcDate}}
        <div>{{ '$I18N_AP_VIEW_POLICY_DETAILS_SIDEPANEL.DETAILS.TODATE' | translate }} {{policyDetails.toDate | mcDate}}</div>
      </div>
    </div>
  </div>
</mc-collapsable-panel>
<hr class="mc-group-separator"/>
<mc-collapsable-panel header="$I18N_AP_VIEW_POLICY_DETAILS_SIDEPANEL.INBOUNDSETTINGS.HEADER" [isExpanded]="true">
  <h5
    class="mc-sub-header-margin">{{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.CONFIGURATION.LABEL' | translate }}
  </h5>
  <div class="mc-detailed-list">
    <div class="row mc-detailed-list-row">
      <div class="col-sm-4 mc-detailed-list-label">
        {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.CONFIGURATION.DELIVERYMETHOD' | translate}}
      </div>
      <div class="mc-detailed-list-column">
        <div>{{ '$I18N_CONFIGURATION_OPTION_' + policy.action| uppercase | translate }}
        </div>
        <div>{{ '$I18N_CONFIGURATION_OPTION_DESCRIPTION_' + policy.action| uppercase | translate }}
        </div>
      </div>
    </div>
    <h5
      class="mc-sub-header-margin">{{ '$I18N_AP_VIEW_POLICY_DETAILS_SIDEPANEL.INBOUNDSETTINGS.LABEL_GENERAL_SETTINGS' | translate }}</h5>
    <div *ngIf="policy.action !== 'safedoc_only' && policy.action !== 'safedoc_and_link'"
         class="row mc-detailed-list-row">
      <div class="col-sm-4 mc-detailed-list-label">
        {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.PREEMPTIVE.LABEL' | translate}}</div>
      <div class="mc-detailed-list-column">
        {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.PREEMPTIVE.PREEMPTIVE_' + policy.preEmptiveFailureAction | uppercase | translate}}
      </div>
    </div>

    <div *ngIf="policy.action !== 'sandbox'" class="row mc-detailed-list-row">
      <div
        class="col-sm-4 mc-detailed-list-label">{{'$I18N_AP_VIEW_POLICY_DETAILS_SIDEPANEL.INBOUNDSETTINGS.IGNORED_SIGNED_MESSAGES' |
        translate}}
      </div>
      <div class="mc-detailed-list-column">{{(policy.ignoreSignedMessages ?
        '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.ENABLED' :
        '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.DISABLED') | translate }}</div>
    </div>
    <div class="row mc-detailed-list-row">
      <div
        class="col-sm-4 mc-detailed-list-label">{{'$I18N_AP_VIEW_POLICY_DETAILS_SIDEPANEL.INBOUNDSETTINGS.RELEASE_FORWARD_INTERNAL_ATTACHMENTS' |
        translate}}
      </div>
      <div class="mc-detailed-list-column">{{(policy.internalForward ?
        '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.ENABLED' :
        '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.DISABLED') | translate }}</div>
    </div>
    <div *ngIf="policy.action !== 'safedoc_only' && (hasPolicyRemoveActiveContent$ |async)"
         class="row mc-detailed-list-row">
      <div
        class="col-sm-4 mc-detailed-list-label">{{'$I18N_AP_VIEW_POLICY_DETAILS_SIDEPANEL.INBOUNDSETTINGS.REMOVE_ACTIVE_CONTENT' |
        translate}}
      </div>
      <div class="mc-detailed-list-column">{{(policy.removeActiveContent ?
        '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.ENABLED' :
        '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.DISABLED') | translate }}</div>
    </div>
    <div *ngIf="policy.action!== 'sandbox'">
      <h5
        class="mc-sub-header-margin">{{ '$I18N_AP_VIEW_POLICY_DETAILS_SIDEPANEL.INBOUNDSETTINGS.LABEL_SAFE_FILE' | translate }}</h5>
      <div class="row mc-detailed-list-row">
        <div
          class="col-sm-4 mc-detailed-list-label">{{'$I18N_AP_VIEW_POLICY_DETAILS_SIDEPANEL.INBOUNDSETTINGS.DOCUMENTS' |
          translate}}
        </div>
        <div class="mc-detailed-list-column">
          {{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.SAFE_DOC_' + policy.safeDocFormat | uppercase | translate}}</div>
      </div>
      <div class="row mc-detailed-list-row">
        <div class="col-sm-4 mc-detailed-list-label">
          {{'$I18N_AP_VIEW_POLICY_DETAILS_SIDEPANEL.INBOUNDSETTINGS.SPREADSHEETS'| translate}}
        </div>
        <div class="mc-detailed-list-column ">
          {{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.SAFE_DOC_' + policy.safeSpreadsheetFormat | uppercase | translate}}</div>
      </div>
    </div>
    <div *ngIf="policy.action !== 'safedoc_only'">
      <h5
        class="mc-sub-header-margin">{{ '$I18N_AP_VIEW_POLICY_DETAILS_SIDEPANEL.INBOUNDSETTINGS.NOTIFICATION_LABEL' | translate }}</h5>
      <div *ngIf="policy.action !== 'safedoc_and_link'">
        <div class="row mc-detailed-list-row">
          <div
            class="col-sm-4 mc-detailed-list-label">{{'$I18N_AP_VIEW_POLICY_DETAILS_SIDEPANEL.INBOUNDSETTINGS.USERS' |
            translate}}
          </div>
          <div class="mc-detailed-list-column">
            <div *ngIf="policy.notifyInternalSender === true">
              {{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.USER.INTRERNAL_SENDER' | translate}}</div>
            <div *ngIf="policy.notifyInternalRecipient === true">
              {{  '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.USER.INTERNAL_RECIPIENT' | translate}}</div>
            <div *ngIf="policy.notifyExternalSender === true">
              {{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.USER.EXTERNAL_SENDER' | translate}}</div>
            <div
              *ngIf="policy.notifyInternalSender === false && policy.notifyInternalRecipient === false && policy.notifyExternalSender === false">
              {{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.USER.BLANK' | translate}}
            </div>
          </div>
        </div>
      </div>
      <div class="row mc-detailed-list-row">
        <div
          class="col-sm-4 mc-detailed-list-label">{{'$I18N_AP_VIEW_POLICY_DETAILS_SIDEPANEL.INBOUNDSETTINGS.ADMINISTRATORS' |
          translate}}
        </div>
        <div class="mc-detailed-list-column">
          {{policy.notifyGroup && policy.notifyGroup.description ? policy.notifyGroup.description : '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.USER.BLANK' | translate}}
        </div>
      </div>
    </div>
  </div>
</mc-collapsable-panel>
<div *ngIf="hasPolicyIEPService$ | async">
  <hr class="mc-group-separator"/>
  <mc-collapsable-panel header="$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.OUTBOUND_SETTINGS.LABEL"
                        [isExpanded]="true">
    <div class="mc-detailed-list">
      <div class="row mc-detailed-list-row">
        <div class="col-sm-4 mc-detailed-list-label">
          {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.OUTBOUND_SETTINGS.ENABLE_OUTBOUND_CHECK' | translate}}
        </div>
        <div class="mc-detailed-list-column">{{(policy.checkOutbound ?
          '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.ENABLED' :
          '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.DISABLED') | translate }}</div>
      </div>
      <div *ngIf="policy.checkOutbound">
        <h5
          class="mc-sub-header-margin">{{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.OUTBOUND_SETTINGS.ACTION_LABEL' | translate }} </h5>
        <div class="row mc-detailed-list-row">
          <div class="col-sm-4 mc-detailed-list-label">
            {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.OUTBOUND_SETTINGS.GATEWAY_ACTION.LABEL' | translate}}
          </div>
          <div class="mc-detailed-list-column">
            {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.OUTBOUND_SETTINGS.GATEWAY_ACTION.OPTIONS.' + policy.outboundAction | uppercase | translate}}
          </div>
        </div>
        <div class="row mc-detailed-list-row">
          <div class="col-sm-4 mc-detailed-list-label">
            {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.OUTBOUND_SETTINGS.GATEWAY_FALLBACK_ACTION.LABEL' | translate}}
          </div>
          <div class="mc-detailed-list-column">
            {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.OUTBOUND_SETTINGS.GATEWAY_FALLBACK_ACTION.OPTIONS.' + policy.outboundFallbackAction | uppercase | translate}}
          </div>
        </div>
        <div class="row mc-detailed-list-row">
          <div class="col-sm-4 mc-detailed-list-label">
            {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.OUTBOUND_SETTINGS.SENDER_MAILBOX_ACTION.LABEL' | translate}}
          </div>
          <div class="mc-detailed-list-column">
            {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.OUTBOUND_SETTINGS.SENDER_MAILBOX_ACTION.OPTIONS.' + policy.outboundRemediationAction | uppercase | translate}}
          </div>
        </div>
        <div class="row mc-detailed-list-row">
          <div class="col-sm-4 mc-detailed-list-label">
            {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.OUTBOUND_SETTINGS.SENDER_MAILBOX_FALLBACK_ACTION' | translate}}
          </div>
          <div class="mc-detailed-list-column">
            {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.OUTBOUND_SETTINGS.SENDER_MAILBOX_ACTION.OPTIONS.' + policy.outboundFallbackRemediationAction | uppercase | translate}}
          </div>
        </div>
        <h5
          class="mc-sub-header-margin">{{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.OUTBOUND_SETTINGS.NOTIFICATION_LABEL' | translate }} </h5>
        <div class="row mc-detailed-list-row">
          <div
            class="col-sm-4 mc-detailed-list-label">{{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.USER.LABEL' | translate}}
          </div>
          <div class="mc-detailed-list-column">{{(policy.outboundNotifyInternalSender ?
            '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.OUTBOUND_SETTINGS.USER.INTERNAL_SENDER_JOURNAL' :
            '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.USER.BLANK') | translate }}</div>
        </div>
        <div class="row mc-detailed-list-row">
          <div
            class="col-sm-4 mc-detailed-list-label">{{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.ADMINISTRATOR_LABEL' | translate}}
          </div>
          <div class="mc-detailed-list-column">{{policy.outboundNotifyGroup ?
            policy.outboundNotifyGroup.description :
            '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.USER.BLANK' | translate}}</div>
        </div>
      </div>
    </div>
  </mc-collapsable-panel>
 <hr class="mc-group-separator"/>
  <mc-collapsable-panel header="$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.JOURNAL_SETTINGS.LABEL"
                        [isExpanded]="true">
    <div class="mc-detailed-list">
      <div class="row mc-detailed-list-row">
        <div class="col-sm-4 mc-detailed-list-label">
          {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.JOURNAL_SETTINGS.ENABLE_JOURNAL_CHECK' | translate}}
        </div>
        <div class="mc-detailed-list-column">{{(policy.checkJournal ?
          '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.ENABLED' :
          '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.DISABLED')| translate }}</div>
      </div>
      <div *ngIf="policy.checkJournal">
        <h5
          class="mc-sub-header-margin">{{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.JOURNAL_SETTINGS.ACTION_LABEL' | translate }} </h5>
        <div class="row mc-detailed-list-row">
          <div class="col-sm-4 mc-detailed-list-label">
            {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.JOURNAL_SETTINGS.USER_MAILBOX_ACTION' | translate}}
          </div>
          <div class="mc-detailed-list-column">
            {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.JOURNAL_SETTINGS.SENDER_MAILBOX_ACTION.OPTIONS.' + policy.journalRemediationAction | uppercase | translate}}
          </div>
        </div>
        <div class="row mc-detailed-list-row">
          <div class="col-sm-4 mc-detailed-list-label">
            {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.JOURNAL_SETTINGS.USER_MAILBOX_FALLBACK_ACTION' | translate}}
          </div>
          <div class="mc-detailed-list-column">
            {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.JOURNAL_SETTINGS.SENDER_MAILBOX_ACTION.OPTIONS.' + policy.journalFallbackRemediationAction | uppercase | translate}}
          </div>
        </div>
        <h5
          class="mc-sub-header-margin">{{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.JOURNAL_SETTINGS.NOTIFICATION_LABEL' | translate }} </h5>
        <div class="row mc-detailed-list-row">
          <div
            class="col-sm-4 mc-detailed-list-label">{{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.USER.LABEL' | translate}}
          </div>
          <div class="mc-detailed-list-column">
            <div *ngIf="policy.journalNotifyInternalSender">
              {{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.JOURNAL_SETTINGS.USER.INTERNAL_SENDER_JOURNAL' | translate}}</div>
            <div *ngIf="policy.journalNotifyInternalRecipient">
              {{  '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.JOURNAL_SETTINGS.USER.INTERNAL_RECIPIENT_JOURNAL' | translate}}</div>
            <div
              *ngIf="policy.journalNotifyInternalSender === false && policy.journalNotifyInternalRecipient === false">
              {{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.USER.BLANK' | translate}}
            </div>
          </div>
        </div>
        <div class="row mc-detailed-list-row">
          <div
            class="col-sm-4 mc-detailed-list-label">{{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.ADMINISTRATOR_LABEL' | translate}}
          </div>
          <div class="mc-detailed-list-column">
            {{policy.journalNotifyGroup && policy.journalNotifyGroup.description ? policy.journalNotifyGroup.description : '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.USER.BLANK' | translate}}
          </div>
        </div>
      </div>
    </div>
  </mc-collapsable-panel>
</div>
<hr class="mc-group-separator"/>
<mc-collapsable-panel header="$I18N_AP_VIEW_POLICY_DETAILS_SIDEPANEL.APPLIESTO.HEADER" [isExpanded]="true">
  <div class="mc-detailed-list">
    <div *ngFor="let rule of policy.policies; let i = index">
      <h5
        class="mc-sub-header-margin">{{ '$I18N_AP_VIEW_POLICY_DETAILS_SIDEPANEL.INBOUNDSETTINGS.LABEL_SENDER_AND_RECIPIENT' | translate }} {{i + 1}} </h5>
      <div class="row mc-detailed-list-row">
        <div
          class="col-sm-4 mc-detailed-list-label">{{'$I18N_AP_VIEW_POLICY_DETAILS_SIDEPANEL.APPLIESTO.ADDRESS_BASED_ON' |
          translate}}
        </div>
        <div
          class="mc-detailed-list-column">{{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.APPLIES_T0.' + rule.policy.fromPart| uppercase | translate }}</div>
      </div>
      <div class="row mc-detailed-list-row">
        <div class="col-sm-4 mc-detailed-list-label">{{'$I18N_AP_VIEW_POLICY_DETAILS_SIDEPANEL.APPLIESTO.FROM' |
          translate}}
        </div>
        <div
          class="mc-detailed-list-column">{{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.APPLIES_T0.' + rule.policy.from.type| uppercase | translate }}
          <div *ngIf="rule.policy.from.type === 'email_domain'">{{ rule.policy.from.emailDomain }}</div>
          <div *ngIf="rule.policy.from.type === 'individual_email_address'">{{ rule.policy.from.emailAddress }}</div>
          <div
            *ngIf="rule.policy.from.type === 'address_attribute_value'">{{rule.policy.from.attribute ? rule.policy.from.attribute.name : ''}}</div>
          <div *ngIf="rule.policy.from.type === 'profile_group' && rule.policy.from.group.source==='ldap'">
            {{'$I18N_AP_VIEW_POLICY_DETAILS_SIDEPANEL.APPLIESTO.ADDRESSGROUP'| translate}} {{ rule.policy.from.group.description}}</div>
          <div *ngIf="rule.policy.from.type === 'profile_group' && rule.policy.from.group.source==='cloud'">
            {{'$I18N_AP_VIEW_POLICY_DETAILS_SIDEPANEL.APPLIESTO.LOCALGROUP'| translate}} {{ rule.policy.from.group.description}}</div>
        </div>
      </div>
      <div class="row mc-detailed-list-row">
        <div class="col-sm-4 mc-detailed-list-label">{{'$I18N_AP_VIEW_POLICY_DETAILS_SIDEPANEL.APPLIESTO.TO' |
          translate}}
        </div>
        <div
          class="mc-detailed-list-column">{{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.APPLIES_T0.' + rule.policy.to.type| uppercase | translate }}
          <div *ngIf="rule.policy.to.type === 'email_domain'">{{ rule.policy.to.emailDomain }}</div>
          <div *ngIf="rule.policy.to.type === 'individual_email_address'">{{ rule.policy.to.emailAddress }}</div>
          <div
            *ngIf="rule.policy.to.type === 'address_attribute_value'">{{rule.policy.to.attribute ? rule.policy.to.attribute.name : ''}}
          </div>
          <div *ngIf="rule.policy.to.type === 'profile_group' && rule.policy.to.group.source ==='ldap'">
            {{'$I18N_AP_VIEW_POLICY_DETAILS_SIDEPANEL.APPLIESTO.ADDRESSGROUP'| translate}} {{ rule.policy.to.group.description}}
          </div>
          <div *ngIf="rule.policy.to.type === 'profile_group' && rule.policy.to.group.source ==='cloud'">
            {{'$I18N_AP_VIEW_POLICY_DETAILS_SIDEPANEL.APPLIESTO.LOCALGROUP'| translate}} {{ rule.policy.to.group.description}}
          </div>
        </div>
      </div>
    </div>
    <h5
      class="mc-sub-header-margin">{{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.ADVANCEDOPTIONS.LABEL' | translate }}
    </h5>
    <div class="row mc-detailed-list-row">
      <div class="col-sm-4 mc-detailed-list-label">
        {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.ADVANCEDOPTIONS.BIDIRECTIONAL' | translate}}
      </div>
      <div class="mc-detailed-list-column">{{(policyDetails.bidirectional ?
        '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.ENABLED' :
        '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.DISABLED') | translate }}
      </div>
    </div>
    <div class="row mc-detailed-list-row">
      <div class="col-sm-4 mc-detailed-list-label">
        {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.ADVANCEDOPTIONS.POLICYOVERRIDE' | translate}}
      </div>
      <div class="mc-detailed-list-column">{{(policyDetails.override ?
        '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.ENABLED' :
        '$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.DISABLED') | translate }}
      </div>
    </div>
         <div class="row mc-detailed-list-row">
        <div
          class="col-sm-4 mc-detailed-list-label">{{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SUMMARY_STEP_LABEL.INBOUND_SETTINGS.ADVANCEDOPTIONS.SOURCEIPRANGES' | translate}}
        </div>
           <div
             class="mc-detailed-list-column">
           <div *ngFor="let ipAddress of policyDetails.conditions.sourceIPs">
             <div>{{ipAddress}}</div>
      </div>
    </div>
  </div>
  </div>
</mc-collapsable-panel>
<hr class="mc-group-separator"/>
</div>
