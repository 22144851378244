"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModalSearchReasonEffects = void 0;
const effects_1 = require("@ngrx/effects");
const operators_1 = require("rxjs/operators");
const searchActions = require("../../actions/search.actions");
const search_reason_component_1 = require("../../components/main/search-reason/search-reason.component");
const reducers = require("../../reducers");
class ModalSearchReasonEffects {
    constructor(actions$, store$, modalService) {
        this.actions$ = actions$;
        this.store$ = store$;
        this.modalService = modalService;
        this.modalSearchReason$ = this.actions$.pipe(effects_1.ofType(searchActions.SEARCH_PERMISSION_REVOKED), operators_1.withLatestFrom(this.store$.select(reducers.getSearchReason)), operators_1.switchMap(([, previousSearchReason]) => {
            return this.modalService
                .open(search_reason_component_1.TrackingMainSearchReasonComponent, {
                size: 'md',
                hasBackdrop: true,
                data: previousSearchReason
            })
                .afterClose()
                .pipe(operators_1.filter((searchReason) => this.filterSearchReason(searchReason)), operators_1.map((searchReason) => {
                return new searchActions.RequestSearchReasonPermissionAction(searchReason);
            }));
        }));
    }
    filterSearchReason(searchReason) {
        return !!searchReason;
    }
}
__decorate([
    effects_1.Effect()
], ModalSearchReasonEffects.prototype, "modalSearchReason$", void 0);
exports.ModalSearchReasonEffects = ModalSearchReasonEffects;
