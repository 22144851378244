"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogsPageComponent = void 0;
const listActions = require("../actions/log-list.actions");
const logsSidePanelActions = require("../actions/logs-side-panel.actions");
class LogsPageComponent {
    constructor(store, capsService, accountPackageService) {
        this.store = store;
        this.capsService = capsService;
        this.accountPackageService = accountPackageService;
        this.extendedFilter = this.accountPackageService.hasInternalEmailProtectAccountPackage();
        this.browserIsolationExtendedFilter = this.accountPackageService.hasBrowserIsolationEnabled();
    }
    ngOnInit() {
        const autoAllowPermission$ = this.capsService.hasCapability('Temporary.Services.URL.Reporting.Enabled');
        autoAllowPermission$.subscribe(autoAllowEnabled => {
            if (autoAllowEnabled) {
                this.store.dispatch(new logsSidePanelActions.GetURLReportGetCategoriesAction());
                this.store.dispatch(new logsSidePanelActions.GetURLReportGetProfileEmailAction());
            }
        });
    }
    paginatorChanged(page) {
        this.store.dispatch(new listActions.UrlProtectionLogsPaginationAction(page));
    }
    rowClicked() {
        this.store.dispatch(new logsSidePanelActions.OpenLogsSidePanelAction());
    }
    exportResults(tableColumns) {
        this.store.dispatch(new listActions.UrlProtectionLogsExportAction(tableColumns));
    }
}
exports.LogsPageComponent = LogsPageComponent;
