<mc-modal-header
  headerTitle="{{headerTitle}}">
</mc-modal-header>

<mc-modal-body>
  <p>{{message}}</p>
</mc-modal-body>

<mc-modal-footer>
    <button *ngIf="!isUpgrade(); else learnMore" type="button" class="btn btn-primary" (click)="close()">
      {{ '$I18N_DIRECTORY_SYNC_MSE_MESSAGE_MODAL.OK_BUTTON' | translate }}
    </button>
</mc-modal-footer>

<ng-template #learnMore>
  <button type="button" class="btn btn-text" (click)="close()">
    {{ '$I18N_DIRECTORY_SYNC_MSE_MESSAGE_MODAL.CANCEL_BUTTON' | translate }}
  </button>
  <a class="btn btn-primary" href="{{ upgradeKbLink }}" target="_blank" rel="noopener noreferrer">
    {{ '$I18N_DIRECTORY_SYNC_MSE_MESSAGE_MODAL.LEARN_MORE_BUTTON' | translate }}
    <i class="fa fa-external-link" aria-hidden="true"></i>
  </a>
</ng-template>
