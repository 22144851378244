'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
angular.module('export-download-aside.controller', [])
    .controller('ExportDownloadAsideCtrl', ['$scope', 'adconBackupService', function ($scope, adconBackupService) {
        const self = this;
        const { downloadLinks, recoveryTaskId, mailbox } = $scope.$parent.$resolve;
        self.files = [];
        self.downloadTokens = downloadLinks;
        self.downloadEndpoint = '/api/backup/download-file/';
        self.mailbox = mailbox;
        self.hasPermission = adconBackupService.CONSTANTS.HAS_PERMISSIONS;
        self.prepareDownloadToken = prepareDownloadToken;
        function prepareDownloadToken(downloadToken) {
            return recoveryTaskId + '/' + customEncodeURIComponent(downloadToken);
        }
        function customEncodeURIComponent(str) {
            return encodeURIComponent(str).replace(/[!'()*]/g, escape);
        }
    }]);
