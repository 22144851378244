<div class="mc-services-connector-wizard-step-container">
  <mc-wizard-step-header
    label="$I18N_CREATE_CONNECTOR_WIZARD_DETAILS_STEP.DESCRIPTION"
  >
  </mc-wizard-step-header>
  <form novalidate
    [formGroup]="form"
    class="form-horizontal"
    mcDefaultLabelClass="col-sm-3"
    mcDefaultControlContainerClass="col-sm-9"
    *ngIf="(currentStepIndex$ | async) === detailsStep"
  >
    <mc-text-field
      required
      [setFocus]="true"
      label="{{'$I18N_CREATE_CONNECTOR_WIZARD_DETAILS_STEP.FIELD_NAME'}}"
      formControlName="name"
      errorPrefix="$I18N_CREATE_CONNECTOR_WIZARD_DETAILS_STEP.VALIDATION.NAME"
    ></mc-text-field>

    <mc-text-area-field
      label="{{'$I18N_CREATE_CONNECTOR_WIZARD_DETAILS_STEP.FIELD_DESCRIPTION'}}"
      formControlName="description"
      errorPrefix="$I18N_CREATE_CONNECTOR_WIZARD_DETAILS_STEP.VALIDATION.DESCRIPTION"
    ></mc-text-area-field>
  </form>
</div>
