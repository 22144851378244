"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DirectorySyncWizardComponent = void 0;
const rxjs_1 = require("rxjs");
const actions = require("../../actions");
const reducers = require("../../reducers");
const directory_sync_integration_interface_1 = require("../../models/directory-sync-integration.interface");
const operators_1 = require("rxjs/operators");
class DirectorySyncWizardComponent {
    constructor(store, stateService, tabService, capabilitiesService, window, featureFlagService) {
        this.store = store;
        this.stateService = stateService;
        this.tabService = tabService;
        this.capabilitiesService = capabilitiesService;
        this.window = window;
        this.featureFlagService = featureFlagService;
        this.connectors = new rxjs_1.BehaviorSubject(undefined);
        this.connectors$ = this.connectors.asObservable();
        /**
         * Enables redirect to Onboarding if URL query params contain '?onboarding=customer' (Onboarding Phase 2 Enabled)
         */
        this.isOnboardingRedirectEnabled = false;
        this.dirSyncKbLink = 'https://community.mimecast.com/s/article/Directory-Synchronization';
        this.isAzureStandardHecate = false;
        this.isAzureGCCHecate = false;
        this.DETAILS_STEP = 1;
        this.SETTINGS_STEP = 2;
        this.OPTIONS_STEP = 3;
        this.SUMMARY_STEP = 4;
        this.TEST_STEP = 5;
    }
    ngOnInit() {
        this.serverType = 'active_directory';
        this.isEditMode = this.stateService.$state.params.isEditMode;
        this.integration = this.stateService.$state.params.integration || {};
        this.mseSites = this.stateService.$state.params.mseSites || [];
        this.testInfo$ = this.store.select(reducers.getWizardTestInfo);
        this.hasMSE = this.mseSites.length > 0;
        this.azureHecateCapsSubscription = this.capabilitiesService.capabilities
            .pipe(operators_1.take(1))
            .subscribe((caps) => {
            const azureStandardCaps = 'DirSync.Azure.Standard.Hecate';
            this.isAzureStandardHecate = caps[azureStandardCaps] && caps[azureStandardCaps].enabled;
            const azureGccCaps = 'DirSync.Azure.GCC.Hecate';
            this.isAzureGCCHecate = caps[azureGccCaps] && caps[azureGccCaps].enabled;
        });
        if (this.isAzureStandardHecate || this.isAzureGCCHecate) {
            this.store.dispatch(new actions.GetProductsAction());
        }
        if (this.isEditMode) {
            this.updateServerType(this.integration.type);
            this.store.dispatch(new actions.SaveIntegrationDetailsAction(this.integration));
        }
        const tab = this.tabService.getSelectedTab();
        tab.onRefresh = () => {
            this.store.dispatch(new actions.OpenWizardAction(this.isEditMode));
        };
        this.dirSyncProductIdSubscription = this.store
            .select(reducers.dirSyncProductId)
            .subscribe(id => {
            this.dirSyncProductId = id;
            if (this.dirSyncProductId) {
                this.getConnectorsAndProviders(this.dirSyncProductId);
            }
        });
        this.connectorsSubscription = this.store
            .select(reducers.getConnectors)
            .subscribe(connectors => {
            this.connectors.next(connectors);
        });
        this.enableOnboardingRedirect();
    }
    enableOnboardingRedirect() {
        const hasUrlQueryParams = this.window.parent.location.href.includes('onboarding=customer') ||
            this.window.parent.location.href.includes('onboarding=partner');
        this.featureFlagService.isReady().then(() => {
            const hasConfigEnabled = this.featureFlagService.getBooleanFlag('rollout-onboarding-phase-2') ||
                this.featureFlagService.getBooleanFlag('rollout-msp-onboarding');
            this.isOnboardingRedirectEnabled = hasUrlQueryParams && hasConfigEnabled;
        });
    }
    wizardTitle() {
        return `$I18N_DIRECTORY_SYNC_WIZARD.${this.isEditMode ? 'EDIT' : 'CREATE'}_TITLE`;
    }
    showNext() {
        return this.wizard.currentStep !== this.getLastStep();
    }
    showPrevious() {
        return this.wizard.currentStep !== this.DETAILS_STEP;
    }
    showCreate() {
        return this.wizard.currentStep === this.getLastStep() && !this.isEditMode;
    }
    showUpdate() {
        return !!this.wizard && this.wizard.currentStep === this.getLastStep() && this.isEditMode;
    }
    getLastStep() {
        return this.isMSEIntegration() || this.isAzureSTDIntegration() || this.isAzureGCCIntegration()
            ? this.SUMMARY_STEP
            : this.TEST_STEP;
    }
    cancel() {
        const modalType = this.isOnboardingRedirectEnabled
            ? 'onboarding_redirect'
            : this.isEditMode
                ? 'cancel'
                : 'create_cancel';
        this.store.dispatch(new actions.ShowConfirmationModalAction({ type: modalType }));
    }
    updateIntegrationDetails(details) {
        this.integration = this.updateDetails(details);
    }
    updateDetails(details) {
        // Get Connectors list for Azure Standard and GCC
        if ((details.type === 'azure_standard' || details.type === 'azure_gcc') &&
            this.integration.type !== details.type &&
            this.dirSyncProductId) {
            this.store.dispatch(new actions.SaveIntegrationDetailsAction(details));
            this.store.dispatch(new actions.GetConnectorsAction(this.dirSyncProductId));
        }
        // Check if integration type changed on Edit mode. If so, update Integration with the new Detail step data
        if (this.isEditMode && !!details.type && this.integration.type !== details.type) {
            return {
                name: details.name,
                description: details.description,
                type: details.type,
                id: this.integration.id,
                hasServerTypeUpdatedOnEdit: true
            };
        }
        return Object.assign(Object.assign({}, this.integration), details);
    }
    updateServerType(type) {
        this.serverType = type;
    }
    isLdapADIntegration() {
        return this.serverType === 'active_directory';
    }
    isLdapDominoIntegration() {
        return this.serverType === 'domino';
    }
    isAzureIntegration() {
        return this.serverType === 'azure_standard' || this.serverType === 'azure_gcc';
    }
    isAzureGCCIntegration() {
        return this.serverType === 'azure_gcc' && this.isAzureGCCHecate;
    }
    isAzureSTDIntegration() {
        return this.serverType === 'azure_standard' && this.isAzureStandardHecate;
    }
    isGsuiteIntegration() {
        return this.serverType === 'google_directory';
    }
    isMSEIntegration() {
        return this.serverType === 'ad_push';
    }
    getServerType() {
        return this.integration.type === 'azure_standard' || this.integration.type === 'azure_gcc'
            ? 'office_365'
            : this.integration.type;
    }
    getAzureServerSubtype() {
        let serverSubType;
        if (this.isAzureIntegration()) {
            serverSubType = this.integration.type === 'azure_standard' ? 'standard' : 'gov';
        }
        return serverSubType;
    }
    changeStep(step) {
        // you have to listen to this event or the underlying wizard doesn't work :(
        if ((this.isMSEIntegration() || this.isAzureSTDIntegration() || this.isAzureGCCIntegration()) &&
            this.SUMMARY_STEP === step) {
            this.createButtonEnabled = true;
        }
    }
    gotoPreviousStep() {
        if (!!this.wizard) {
            if (this.wizard.currentStep === this.TEST_STEP) {
                this.createButtonEnabled = false;
            }
            this.wizard.previousStep();
        }
    }
    gotoNextStep() {
        if (!!this.wizard) {
            this.store.dispatch(new actions.SaveIntegrationDetailsAction(this.integration, this.wizard.currentStep, this.getServerType(), this.getAzureServerSubtype()));
            this.wizard.nextStep();
        }
    }
    createIntegrationButtonEnabled(isEnabled) {
        this.createButtonEnabled = isEnabled;
    }
    createIntegration() {
        // If Azure Standard or GCC Hecate && choose to create new Connector -> create Connector first then map the ConnectorId to the new Integration
        if ((this.isAzureSTDIntegration() || this.isAzureGCCIntegration()) &&
            this.integration.connectorName !== '') {
            this.store.dispatch(new actions.CreateConnectorAction({
                product: this.dirSyncProductId,
                provider: this.serverType,
                authToken: this.authToken,
                name: this.integration.connectorName
            }));
        }
        else {
            this.store.dispatch(new actions.CreateIntegrationAction(this.getPayload(), this.isOnboardingRedirectEnabled));
        }
    }
    isSettingsStepValid() {
        return this.isLdapADIntegration()
            ? this.ldapSettingsStep.isValid()
            : this.isLdapDominoIntegration()
                ? this.ldapDominoSettingsStep.isValid()
                : this.isAzureIntegration() && !(this.isAzureSTDIntegration() || this.isAzureGCCIntegration())
                    ? this.azureSettingsStep.isValid()
                    : this.isAzureIntegration() && (this.isAzureSTDIntegration() || this.isAzureGCCIntegration())
                        ? this.azureHecateSettingsStep.isValid()
                        : this.isMSEIntegration()
                            ? this.mseSettingsStep.isValid()
                            : this.gsuiteSettingsStep.isValid();
    }
    isStepValid() {
        if (!this.isLoading && !!this.wizard) {
            switch (this.wizard.currentStep) {
                case this.DETAILS_STEP:
                    return this.detailsStep.isValid();
                case this.SETTINGS_STEP:
                    return this.isSettingsStepValid();
                case this.OPTIONS_STEP:
                    return this.optionsStep.isValid();
                default:
                    return true;
            }
        }
        return false;
    }
    updateIntegration() {
        const integration = this.getPayload();
        if (this.integration.id) {
            integration.id = this.integration.id;
        }
        if (this.integration.lastOutcome) {
            integration.status = this.integration.lastOutcome;
        }
        // If Azure Standard or GCC Hecate && choose to create new Connector -> create Connector first then map the ConnectorId to existing Integration
        if ((this.isAzureSTDIntegration() || this.isAzureGCCIntegration()) &&
            this.integration.connectorName !== '') {
            this.store.dispatch(new actions.CreateConnectorAction({
                product: this.dirSyncProductId,
                provider: this.serverType,
                authToken: this.authToken,
                name: this.integration.connectorName
            }, true));
        }
        else {
            this.store.dispatch(new actions.UpdateIntegrationAction(integration));
        }
    }
    getPayload() {
        const integration = {
            description: this.integration.name,
            serverType: this.getServerType(),
            acknowledgeDisabledAccounts: this.integration.acknowledgeDisabledAccounts,
            domains: this.integration.emailDomainsFilter ? [this.integration.domains] : [],
            info: this.integration.description,
            deleteUsers: this.integration.deleteUsers,
            maxUnlink: this.integration.maxSyncDeletion,
            enabled: this.integration.status,
            passwordEncoding: 'base64'
        };
        return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, integration), this.getLdapPayload()), this.getAzurePayload()), this.getGsuitePayload()), this.getMSEPayload());
    }
    getLdapPayload() {
        if (this.isLdapADIntegration() || this.isLdapDominoIntegration()) {
            const integration = {
                ldapSettings: {
                    hostname: this.integration.host,
                    rootDn: this.integration.rootDn,
                    encryptionMode: this.integration.encryptionConn
                        ? this.integration.encryptionMode
                        : 'none',
                    port: this.integration.port,
                    userDn: this.integration.userDn
                }
            };
            if (!this.isLdapDominoIntegration()) {
                integration.syncContacts = this.integration.includeContacts;
            }
            if (this.integration.password && this.integration.password !== directory_sync_integration_interface_1.HiddenValues.key) {
                integration.ldapSettings.password = btoa(this.integration.password);
            }
            if (this.integration.alternateHost) {
                integration.ldapSettings.alternateHostname = this.integration.alternateHost;
            }
            return Object.assign(Object.assign({}, integration), { ldapSettings: Object.assign(Object.assign({}, integration.ldapSettings), this.getDominoPayload()) });
        }
        return {};
    }
    getDominoPayload() {
        if (this.isLdapDominoIntegration()) {
            return {
                foreignDirectorySync: this.integration.foreignDirectorySync
            };
        }
        return {};
    }
    getAzurePayload() {
        if (this.isAzureIntegration()) {
            const integration = {
                syncContacts: this.integration.includeContacts,
                serverType: 'office_365',
                office365Settings: {
                    serverSubtype: this.getAzureServerSubtype(),
                    syncGuestUsers: this.integration.includeGuestAccounts,
                    connectorId: this.integration.connector
                }
            };
            if (this.integration.key && this.integration.key !== directory_sync_integration_interface_1.HiddenValues.key) {
                integration.office365Settings.key = btoa(this.integration.key);
            }
            if (this.integration.appID) {
                integration.office365Settings.clientId = this.integration.appID;
            }
            if (this.integration.tenantDomain) {
                integration.office365Settings.tenantDomain = this.integration.tenantDomain;
            }
            return integration;
        }
        return {};
    }
    getGsuitePayload() {
        if (this.isGsuiteIntegration()) {
            const integration = {
                googleDirectorySettings: {
                    user: this.integration.email
                }
            };
            if (this.integration.json && this.integration.json !== directory_sync_integration_interface_1.HiddenValues.json) {
                integration.googleDirectorySettings.key = btoa(this.integration.json);
            }
            return integration;
        }
        return {};
    }
    getMSEPayload() {
        if (this.isMSEIntegration()) {
            const replicateDomain = this.integration.host && this.integration.userDn && this.integration.rootDn;
            const integration = {
                adPushSettings: {
                    mseSiteId: this.integration.mseSiteId
                }
            };
            if (this.isEditMode && !replicateDomain) {
                integration.adPushSettings.hostname = '';
                integration.adPushSettings.rootDn = '';
                integration.adPushSettings.userDn = '';
                integration.adPushSettings.password = '';
            }
            if (replicateDomain) {
                integration.adPushSettings.hostname = this.integration.host;
                integration.adPushSettings.rootDn = this.integration.rootDn;
                integration.adPushSettings.userDn = this.integration.userDn;
            }
            if (this.integration.password && this.integration.password !== directory_sync_integration_interface_1.HiddenValues.key) {
                integration.adPushSettings.password = btoa(this.integration.password);
            }
            return integration;
        }
        return {};
    }
    getConnectorsAndProviders(id) {
        if (this.isEditMode) {
            this.store.dispatch(new actions.GetConnectorsAction(id));
        }
        this.store.dispatch(new actions.GetProvidersAction(id));
    }
    updateToken(token) {
        this.authToken = token;
    }
    ngOnDestroy() {
        this.dirSyncProductIdSubscription.unsubscribe();
        this.connectorsSubscription.unsubscribe();
        this.azureHecateCapsSubscription.unsubscribe();
    }
}
exports.DirectorySyncWizardComponent = DirectorySyncWizardComponent;
