<div class="mc-swg-endpoint">
  <div *ngIf="!isEditable" class="mc-read-only-message">
    <mc-inline-notification
      [notification]="readOnlyMessage"
    >
    </mc-inline-notification>
  </div>
  <div [ngClass]="!isEditable ? 'mc-sub-heading-not-editable' : 'mc-sub-heading'">
    {{ (!hasAuthKey ? '$I18N_ENDPOINT_SETTINGS.SUB_HEADERS.ENDPOINT.INSTALL_ENDPOINTS' :
                      '$I18N_ENDPOINT_SETTINGS.SUB_HEADERS.ENDPOINT.ACTIVE_ENDPOINTS') | translate }}
  </div>
  <div class="mc-description">
    {{ (!hasAuthKey ? '$I18N_ENDPOINT_SETTINGS.DESCRIPTION.ENDPOINT.AUTHENTICATION_KEY' :
                      '$I18N_ENDPOINT_SETTINGS.DESCRIPTION.ENDPOINT.AUTHENTICATION_KEY_ACTIVE') | translate }}<br>
  </div>
  <div *ngIf="!hasAuthKey" class="mc-auth-section">
    <mc-live-button
      overrideClasses="btn-primary"
      [label]="'$I18N_ENDPOINT_SETTINGS.LABELS.ENDPOINT.BTN_CREATE_KEY' | translate"
      [mcDisabled]="!isEditable || isLoading"
      [isLoading]="isLoading"
      (mcClick)="onGetAuthKey()">
    </mc-live-button>
  </div>
  <div *ngIf="hasAuthKey" class="mc-auth-section">
    <div class="mc-auth-key-container">
      <div class="mc-auth-key">
        {{ authToken }}
      </div>
    </div>
    <div class="mc-auth-key-btns-container">
      <button
        class="btn btn-primary mc-auth-btns" ngxClipboard
        [cbContent]="authToken"
        (cbOnSuccess)="copyToClipboardSuccess()">
        {{ '$I18N_ENDPOINT_SETTINGS.LABELS.ENDPOINT.BTN_COPY_KEY' | translate }}
      </button>
      <button class="btn btn-secondary mc-auth-btns" (click)="onDownloadAuthKey()">
        {{ '$I18N_ENDPOINT_SETTINGS.LABELS.ENDPOINT.BTN_DOWNLOAD_KEY' | translate }}
      </button>
      <button class="btn btn-secondary" (click)="onOpenDeleteKeyModal()" *ngIf="hasRequiredRole || hasPurgePermission">
        {{ '$I18N_ENDPOINT_SETTINGS.LABELS.ENDPOINT.BTN_DELETE_KEY' | translate }}
      </button>
    </div>
  </div>
  <div class="mc-sub-heading">
    {{ '$I18N_ENDPOINT_SETTINGS.SUB_HEADERS.ENDPOINT.DOWNLOAD_ENDPOINTS' | translate }}
    <a href="{{endpointReleaseNotes}}" target="_blank">{{'$I18N_ENDPOINT_SETTINGS.SUB_HEADERS.ENDPOINT.DOWNLOAD_ENDPOINTS_RELEASE_NOTES' | translate}}</a>
  </div>
  <div class="mc-description">
    {{ '$I18N_ENDPOINT_SETTINGS.DESCRIPTION.ENDPOINT.ENDPOINT_INSTALLERS' | translate }}<br>
  </div>
  <span>
    <button
      class="btn btn-secondary mc-download-btns"
      [disabled]="isDownloadBinaryDisabled"
      (click)="onDownloadBinary('mrcw')"
    >
      <i class="fab fa-windows"></i>
      {{ '$I18N_ENDPOINT_SETTINGS.LABELS.ENDPOINT.BTN_DOWNLOAD_PC' | translate }}
    </button>
    <button
      class="btn btn-secondary"
      [disabled]="isDownloadBinaryDisabled"
      (click)="onDownloadBinary('mrcm')"
    >
      <i class="fab fa-apple"></i>
      {{ '$I18N_ENDPOINT_SETTINGS.LABELS.ENDPOINT.BTN_DOWNLOAD_MAC' | translate }}
    </button>
  </span>

  <div class="panel-padding-top">
    <a href="{{endpointInstallKbLink}}" target="_blank">{{'$I18N_ENDPOINT_SETTINGS.DESCRIPTION.ENDPOINT.ENDPOINT_INSTALLERS_HELP_LINK' | translate}}</a>
  </div>
</div>
