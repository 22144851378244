<mc-collapsable-panel header="Message Body" [isExpanded]="true">
  <div *ngIf="askForEmailViewConfirmation || html || text; else noMessageBody">
    <mc-inline-notification
      class="mc-inline-notification-main-email-body"
      *ngIf="askForEmailViewConfirmation; else permissionGrantedToSeeEmail"
      [notification]="contentAdministratorInlineNotification">
    </mc-inline-notification>
    <ng-template #permissionGrantedToSeeEmail>
      <div class="btn-toolbar panel-padding-bottom" role="toolbar">
        <div class="btn-group pull-right">
          <mc-list-dropdown-button align="right" [label]="(showHtml$ | async) ? 'HTML' : 'Plain Text'">
            <mc-dropdown-item label="HTML" (click)="viewHtml(true)"></mc-dropdown-item>
            <mc-dropdown-item label="Plain Text" (click)="viewHtml(false)"></mc-dropdown-item>
          </mc-list-dropdown-button>
        </div>
        <div class="btn-group pull-right" role="group">
          <span class="btn">View</span>
        </div>
      </div>
      <div *ngIf="html && (showHtml$ | async); else showPlainText">
        <mc-email-html-panel [html]="html" [attachments]="attachments" [imageApiUrl]="imageApiUrl"></mc-email-html-panel>
      </div>
    </ng-template>
    <ng-template #showPlainText>
      <div class="panel panel-default">
        <div class="panel-body">
          <div>
            <p>{{text}}</p>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <ng-template #noMessageBody>
    <div class="panel panel-default">
      <div class="panel-body">
        <div>
          <span>No message body available.</span>
        </div>
      </div>
    </div>
  </ng-template>
</mc-collapsable-panel>
