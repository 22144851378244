"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MepAdminLogService = void 0;
const drop_or_release_confirmation_modal_component_1 = require("../logs/components/drop-or-release-confirmation-modal/drop-or-release-confirmation-modal.component");
class MepAdminLogService {
    constructor(modalService) {
        this.modalService = modalService;
    }
    openLogActionConfirmationModal(action) {
        const modalConfig = {};
        modalConfig.size = 'md';
        modalConfig.hasBackdrop = true;
        modalConfig.disableClose = true;
        modalConfig.data = { action };
        return this.modalService
            .open(drop_or_release_confirmation_modal_component_1.DropOrReleaseConfirmationModalComponent, modalConfig)
            .afterClose();
    }
}
exports.MepAdminLogService = MepAdminLogService;
