"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.maxSelectedChecklist = exports.minSelectedChecklist = exports.ChecklistComponent = void 0;
const forms_1 = require("@angular/forms");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
class ChecklistComponent {
    constructor(formBuilder) {
        this.formBuilder = formBuilder;
        this.label = '';
        this.selectedOptions = [];
        this.destroy$ = new rxjs_1.Subject();
    }
    ngOnInit() {
        this.options = this.options || [];
        this.initialiseForm();
        this.listenToOptionChanges();
    }
    writeValue(value) {
        this.unsetAllOptions();
        this.setSelectedOptions(value || []);
    }
    registerOnChange(fn) {
        this.onChanges = fn;
    }
    registerOnTouched(fn) {
        this._onTouched = fn;
    }
    initialiseForm() {
        this.checklistForm = this.formBuilder.group({
            controlOptions: new forms_1.FormArray(this.createOptions())
        });
    }
    createOptions() {
        return this.options.map((option, idx) => new forms_1.FormControl(idx));
    }
    listenToOptionChanges() {
        this.getControlOptionsFormArray().controls.forEach((controlOption, idx) => controlOption.valueChanges.pipe(operators_1.takeUntil(this.destroy$)).subscribe(value => {
            this.propagateChange(idx, value);
        }));
    }
    unsetAllOptions() {
        this.getControlOptionsFormArray().controls.forEach(controlOption => controlOption.patchValue(false));
    }
    setSelectedOptions(selectedOptionIds) {
        selectedOptionIds.forEach(selectedOptionId => {
            const optionsIndex = this.options.findIndex(option => option.id === selectedOptionId);
            // An optionsIndex vale of -1 implies that a previously selected option has been deleted or removed from the options list
            if (optionsIndex !== -1) {
                this.getControlOptionsFormArray()['controls'][optionsIndex].patchValue(true);
            }
        });
    }
    getControlOptionsFormArray() {
        return this.checklistForm.get('controlOptions');
    }
    propagateChange(index, value) {
        const id = this.options[index].id;
        if (value) {
            let filteredOptions = [];
            filteredOptions = this.selectedOptions.filter(option => option !== id);
            this.selectedOptions = [...filteredOptions, id];
        }
        else {
            this.selectedOptions = this.selectedOptions.filter(option => option !== id);
        }
        if (this._onTouched) {
            this._onTouched();
            this.onChanges(this.selectedOptions);
        }
    }
}
exports.ChecklistComponent = ChecklistComponent;
function minSelectedChecklist(min) {
    return (control) => {
        const value = control.value;
        const isMinError = value.length < min;
        return !isMinError ? null : { CHECKLIST_MIN_ERROR: true };
    };
}
exports.minSelectedChecklist = minSelectedChecklist;
function maxSelectedChecklist(max) {
    return (control) => {
        const value = control.value;
        const isMaxError = value.length > max;
        return !isMaxError ? null : { CHECKLIST_MAX_ERROR: true };
    };
}
exports.maxSelectedChecklist = maxSelectedChecklist;
