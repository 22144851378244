<mc-view-rule
  [ruleDetails]="ruleDetails$ | async"
  [loading]="ruleLoading$ | async"
  [paginationInfo]="paginationInfo$ | async"
  (onLoadDetails)="loadDetails($event)"
  (onEdit)="edit($event)"
  (onClose)="close()"
  (onEnableDisable)="enableDisable($event)"
  [hasEditPermissions]="hasEditPermission$ | async"
  (onDelete)="deleteRule($event)"
  [hasDeleteCapability]="hasDeleteCapability$ | async"
></mc-view-rule>
