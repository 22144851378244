"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AwarenessApiService = void 0;
const operators_1 = require("rxjs/operators");
class AwarenessApiService {
    constructor(http) {
        this.http = http;
    }
    getAllTemplates() {
        return this.http.post('/api/ttp/userawareness/get-template', undefined).pipe(operators_1.map(response => {
            return response.all;
        }));
    }
    getTemplate(id) {
        return this.http
            .post('/api/ttp/userawareness/get-template', { id })
            .pipe(operators_1.map(response => {
            return response.first;
        }));
    }
    saveTemplate(template) {
        let url = '/api/ttp/userawareness/create-template';
        if (template.id) {
            url = '/api/ttp/userawareness/update-template';
        }
        return this.http.post(url, template).pipe(operators_1.map(response => {
            return response.first;
        }));
    }
    deleteTemplate(id) {
        return this.http
            .post('/api/ttp/userawareness/delete-template', { id })
            .pipe(operators_1.map(response => {
            return response.first;
        }));
    }
    getMimecastDefaultSafetyTips() {
        return this.http.post('/api/ttp/userawareness/get-mimecast-tips', undefined).pipe(operators_1.map(response => {
            return response.all;
        }));
    }
}
exports.AwarenessApiService = AwarenessApiService;
