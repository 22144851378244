<mc-layout-list-table keyTitle="$18N_SYNC_RECOVER_ONE_DRIVE_LIST.TITLE" keyDescription="$18N_SYNC_RECOVER_ONE_DRIVE_LIST.DESCRIPTION"
  kbUrl="https://community.mimecast.com/docs/DOC-2704" [isDataLoaded]="!(isLoadResponseEmpty$ | async)">

  <mc-extra-container>
    <mc-drive-usage-bar [totalSpace]="totalAllowance$|async" [usedSpace]="totalUsage$|async" [isLoading]="isUsageLoading$|async"></mc-drive-usage-bar>
  </mc-extra-container>
  <mc-table [showHighlightedRow]="false" [data]="tableData$ | async" [columns]="columnList" [isLoading]="isLoading$ | async"
    translatePrefix="$18N_SYNC_RECOVER_ONE_DRIVE_LIST" (rowClick)="openItem($event)">
    <mc-filters [metadata]="metaData$ | async" (paginatorChange)="onPaginationChange($event)">
      <mc-filter-bundle-column-values-and-search [sections]="sections" placeholder="$18N_SYNC_RECOVER_ONE_DRIVE_LIST.FILTERS.SEARCH_FIELD.PLACEHOLDER"
        (filtersChange)="onFilterChange($event)"></mc-filter-bundle-column-values-and-search>
    </mc-filters>
    <mc-column-date key="firstStarted"></mc-column-date>
    <mc-column-date key="lastActive"></mc-column-date>
    <mc-column-size [textAlign]="sizePosition" precision="0" [smallestUnit]="storageUsedUnit" key="storageUsed"></mc-column-size>
    <mc-column key="syncedBy">
      <mc-body-cell *mcBodyCellDef="let row">{{row.backupTaskSettings.description}}</mc-body-cell>
    </mc-column>
    <mc-column key="lastStatus">
      <mc-body-cell *mcBodyCellDef="let row">
        <div class="mc-status-text-container" [ngClass]="getStatusByRow(row).displayClasses">
          <span>{{ getStatusByRow(row).displayValue | translate:{number: row.phaseProgress} }}</span>
        </div>
      </mc-body-cell>
    </mc-column>
  </mc-table>

  <mc-empty-results iconClass="far fa-hdd" keyTitle="$18N_SYNC_RECOVER_ONE_DRIVE_LIST.EMPTY_RESULTS.TITLE">
    <mc-empty-body>
      <div>
        {{ '$18N_SYNC_RECOVER_ONE_DRIVE_LIST.EMPTY_RESULTS.DESCRIPTION.LINE_1.1' | translate }}
        <a class="mc-link" href="">{{ '$18N_SYNC_RECOVER_ONE_DRIVE_LIST.EMPTY_RESULTS.DESCRIPTION.LINE_1.2' | translate }}</a>
        {{ '$18N_SYNC_RECOVER_ONE_DRIVE_LIST.EMPTY_RESULTS.DESCRIPTION.LINE_1.3' | translate }}
      </div>
      <div>
        {{ '$18N_SYNC_RECOVER_ONE_DRIVE_LIST.EMPTY_RESULTS.DESCRIPTION.LINE_2.1' | translate }}
      </div>
    </mc-empty-body>
  </mc-empty-results>
</mc-layout-list-table>
