<div class="mc-services-connector-wizard-step-container" *ngIf="!(isLoadingProducts$ | async); else spinner">
  <mc-wizard-step-header label="$I18N_CREATE_CONNECTOR_WIZARD_PRODUCT_SELECTION_STEP.DESCRIPTION">
  </mc-wizard-step-header>

  <ng-container>
    <form [formGroup]="form" class="form-horizontal" mcDefaultLabelClass="col-sm-3"
      mcDefaultControlContainerClass="col-sm-9" *ngIf="(products$ | async)?.length; else noProducts">
      <div *ngFor="let product of (products$ | async)">
        <div data-test="product" class="mc-product-selection">
          <div class="radio panel-half-padding-bottom">
            <label>
              <input (click)="onProductSelect()" type="radio" formControlName="product" [value]="product"/>
              <span data-test="product-name">{{ product.name }}</span>
              <div data-test="product-description" [innerHTML]="getProductDescription(product.description)">
              </div>
            </label>
          </div>
        </div>
      </div>
    </form>
  </ng-container>
</div>

<ng-template #noProducts>
  <mc-inline-error-notification [errorType]="errorType" (onReload)="loadProducts()">
  </mc-inline-error-notification>
</ng-template>

<ng-template #spinner>
  <div class="spinner-wrapper">
    <mc-spinner></mc-spinner>
  </div>
</ng-template>
