"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pageSize = exports.getLocationsPagination = exports.apiIsProcessing = exports.getLocations = exports.reducer = exports.initialState = void 0;
const actions = require("../../actions/applies-to-locations.actions");
exports.initialState = {
    locations: [],
    apiIsProcessing: false,
    pagination: {
        next: undefined,
        previous: undefined,
        pageSize: 20,
        pageNumber: 0
    }
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.LOAD_APPLIES_TO_LOCATIONS:
            return Object.assign(Object.assign({}, state), { apiIsProcessing: true });
        case actions.LOAD_APPLIES_TO_LOCATIONS_SUCCESS:
            return Object.assign(Object.assign({}, state), { locations: action.payload.data['webIPRanges'] || [], apiIsProcessing: false, pagination: Object.assign(Object.assign({}, state.pagination), { next: action.payload.meta['pagination'].next, previous: action.payload.meta['pagination'].previous }) });
        case actions.LOAD_APPLIES_TO_LOCATIONS_FAILURE:
            return Object.assign(Object.assign({}, state), { apiIsProcessing: false });
        case actions.LOAD_APPLIES_TO_LOCATIONS_PAGINATION:
            return Object.assign(Object.assign({}, state), { apiIsProcessing: true, pagination: Object.assign(Object.assign({}, state.pagination), { pageNumber: action.payload.meta.pagination.pageNumber }) });
        case actions.LOAD_APPLIES_TO_LOCATIONS_PAGINATION_RESET:
            return Object.assign({}, exports.initialState);
        case actions.CLEAR_APPLIES_TO_LOCATIONS:
            return Object.assign({}, exports.initialState);
        default:
            return state;
    }
}
exports.reducer = reducer;
const getLocations = (state) => state.locations;
exports.getLocations = getLocations;
const apiIsProcessing = (state) => state.apiIsProcessing;
exports.apiIsProcessing = apiIsProcessing;
const getLocationsPagination = (state) => {
    let endIndex = null;
    if (!state.apiIsProcessing) {
        if (state.locations.length >= state.pagination.pageSize) {
            endIndex = state.pagination.pageNumber + state.pagination.pageSize;
        }
        else {
            if (state.locations.length && state.pagination.pageNumber !== 0) {
                endIndex = state.locations.length + state.pagination.pageNumber;
            }
            else {
                endIndex = state.locations.length || 0;
            }
        }
    }
    return {
        prevToken: state.pagination.previous,
        nextToken: state.pagination.next,
        startIndex: state.pagination.pageNumber + 1,
        endIndex,
        pageSize: state.pagination.pageSize
    };
};
exports.getLocationsPagination = getLocationsPagination;
const pageSize = (state) => state.pagination.pageSize;
exports.pageSize = pageSize;
