"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CancelNoAction = exports.CancelYesAction = exports.CancelAction = exports.SetCurrentStepAction = exports.UpdateStepAction = exports.WizardActions = void 0;
var WizardActions;
(function (WizardActions) {
    WizardActions["SET_CURRENT_STEP"] = "[OneDriveBackupWizard] Set Current Step";
    WizardActions["UPDATE_STEP"] = "[OneDriveBackupWizard] Update Step";
    WizardActions["CANCEL"] = "[OneDriveBackupWizard] Cancel";
    WizardActions["CANCEL_YES"] = "[OneDriveBackupWizard] Cancel Yes";
    WizardActions["CANCEL_NO"] = "[OneDriveBackupWizard] Cancel No";
})(WizardActions = exports.WizardActions || (exports.WizardActions = {}));
class UpdateStepAction {
    constructor(payload) {
        this.payload = payload;
        this.type = WizardActions.UPDATE_STEP;
    }
}
exports.UpdateStepAction = UpdateStepAction;
class SetCurrentStepAction {
    constructor(payload) {
        this.payload = payload;
        this.type = WizardActions.SET_CURRENT_STEP;
    }
}
exports.SetCurrentStepAction = SetCurrentStepAction;
class CancelAction {
    constructor(payload) {
        this.payload = payload;
        this.type = WizardActions.CANCEL;
    }
}
exports.CancelAction = CancelAction;
class CancelYesAction {
    constructor(payload) {
        this.payload = payload;
        this.type = WizardActions.CANCEL_YES;
    }
}
exports.CancelYesAction = CancelYesAction;
class CancelNoAction {
    constructor() {
        this.type = WizardActions.CANCEL_NO;
    }
}
exports.CancelNoAction = CancelNoAction;
