"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DownloadXmlComponent = void 0;
const core_1 = require("@angular/core");
class DownloadXmlComponent {
    constructor(_fb) {
        this._fb = _fb;
        this.selectedDownload = new core_1.EventEmitter();
        this.selectedPartialDownload = new core_1.EventEmitter();
        this.formTokenToDownload = this._fb.group({});
        this.archivePartial = this._fb.group({
            partialTokens: ['']
        });
        this.callBackSubscription = this.archivePartial.valueChanges.subscribe((data) => {
            this.selectedPartialDownload.emit(data.partialTokens);
        });
    }
    downloadXmlComponent(e, value) {
        this.selectedDownload.emit(value);
    }
    ngOnDestroy() {
        if (this.callBackSubscription) {
            this.callBackSubscription.unsubscribe();
        }
    }
}
exports.DownloadXmlComponent = DownloadXmlComponent;
