"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./mc-r-button-directive"); //predefined-field.rbutton.directive
/**
 * Created by asyed.
 */
(function () {
    'use strict';
    angular.module('predefined-field.rbutton', ['predefined-field.rbutton.directive']);
})();
