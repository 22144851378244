"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./mc-radiobutton-directive"); //predefined-field.radiobutton.directive
/**
 * Created by asyed.
 */
(function () {
    'use strict';
    angular.module('predefined-field.radiobutton', ['predefined-field.radiobutton.directive']);
})();
