"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArchiveViewerService = void 0;
const moment = require("moment");
const operators_1 = require("rxjs/operators");
const components_1 = require("@mimecast-ui/components");
const archive_viewer_service_interface_1 = require("./archive-viewer.service.interface");
class ArchiveViewerService {
    constructor(service) {
        this.service = service;
    }
    getEmailDetail(request) {
        return this.service.getTokenDetail(request);
    }
    searchContent(email, request) {
        const result = this.service.getSearchContent(this.transformQuery(email, request)).pipe(operators_1.map((response) => {
            const res = response.first.items.map((item) => {
                return this.transform(item);
            });
            return { result: res, meta: response.meta };
        }));
        return result;
    }
    getEmailDeliveryPart(id) {
        // @ts-ignore
        return this.service.getDeliveryDetail({ id }).pipe(operators_1.map(m => m.content));
    }
    transformQuery(email, request) {
        const contentQuery = {
            reason: request.reason,
            includeExpiredMessage: request.expiredMessages,
            query: this.getQueryXML(email, request)
        };
        return Object.assign(Object.assign({}, contentQuery), { meta: request.meta });
    }
    transform(item) {
        const email = {
            id: item.messageid,
            token: item.id,
            sender: item.displayfrom,
            recipient: item.displayto,
            subject: item.subject,
            size: item.size,
            receiveDate: moment(item.receiveddate, moment.ISO_8601).format(components_1.API_DATE_FORMAT),
            status: item.status,
            attachmentCount: item.attachmentcount,
            docs: item.docs
        };
        return email;
    }
    getQueryXML(email, request) {
        const museQuery = new archive_viewer_service_interface_1.MuseQuery();
        const dRange = request.dateRange;
        const dRange_period = dRange.id.toLowerCase();
        const dRange_from = dRange.range.start.format(components_1.API_DATE_FORMAT);
        const dRange_to = dRange.range.end.format(components_1.API_DATE_FORMAT);
        const docs = request.docs;
        const pageSize = (request.meta && request.meta.pagination.pageSize) || 100;
        museQuery.text = request.keywords;
        museQuery.date = new archive_viewer_service_interface_1.DateQuery(dRange_period, dRange_from, dRange_to);
        museQuery.from = request.from;
        museQuery.to = request.to;
        museQuery.route = new archive_viewer_service_interface_1.RouteQuery(request.route);
        if (typeof docs === 'undefined') {
            museQuery.docs = new archive_viewer_service_interface_1.DocsQuery('optional', '');
        }
        else {
            museQuery.docs = new archive_viewer_service_interface_1.DocsQuery(docs.type, docs.docs);
        }
        return `<?xml version="1.0" encoding="UTF-8"?>
      <xmlquery
          xmlns:xsd="http://www.w3.org/2001/XMLSchema"
          xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
          <metadata archive="true" query-type="emailarchive" page-size="${pageSize}" sort-on="receiveddate" sort-by="desc">
              <return-fields>
                  <return-field>subject</return-field>
                  <return-field>mtoken</return-field>
                  <return-field>status</return-field>
                  <return-field>receiveddate</return-field>
                  <return-field>displayfrom</return-field>
                  <return-field>displayto</return-field>
                  <return-field>attachmentcount</return-field>
                  <return-field>size</return-field>
                  <return-field>routing</return-field>
                  <return-field>expirydate</return-field>
                  <return-field>messageid</return-field>
                  <return-field>docs</return-field>
              </return-fields>
          </metadata>
          ${museQuery.toString()}
      </xmlquery>
    `;
    }
}
exports.ArchiveViewerService = ArchiveViewerService;
