<div class="mc-supervision-wizard-step-container mc-supervision-wizard-step-container-settings">
  <mc-wizard-step-header
    class="mc-supervision-wizard-step-header"
    label="$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.QUEUE_STEP.DESCRIPTION"
  ></mc-wizard-step-header>

  <form
    [formGroup]="form"
    class="form-horizontal"
    mcDefaultLabelClass="col-sm-3"
    mcDefaultControlContainerClass="col-sm-9"
  >
    <mc-field
      label="{{
        '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.QUEUE_STEP.FIELD_REVIEW_QUEUE' | translate
      }}"
      required
    >
      <input type="hidden" formControlName="reviewQueue" />
      <button type="button" class="btn btn-default" (click)="openSidebar()">
        {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.QUEUE_STEP.SELECT_QUEUE' | translate }}
      </button>
      <div *ngIf="selectedQueue">
        <div class="selected-group">
          <span class="groups-list">
            <div class="groups-name">{{ selectedQueue.name }}</div>
          </span>
        </div>
      </div>
    </mc-field>
  </form>
</div>
