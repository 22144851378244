<div class="mc-api-integration-step-block" mcDefaultLabelClass="col-sm-4" mcDefaultControlContainerClass="col-sm-8">
    <mc-wizard-step-header [label]="'$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.CONTACTS.HEADER'">
    </mc-wizard-step-header>

    <div class="mc-api-integration-okta-contacts-block">
      <div class="col-sm-12 step-content">
        <p>{{'$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.CONTACTS.INFO' | translate }}</p>

      <div class="no-padding-left email-section">
        <mc-api-integration-email [emails]="emails" label="$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.CONTACTS.EMAIL_LABEL"
        btnExtraInfo="$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.CONTACTS.MAX_NUMBER_OF_EMAILS"
         (onDeleteEmail)="deleteEmail($event)" (onVerifyAndAddEmail)="addEmail($event)"></mc-api-integration-email>
      </div>
    </div>
  </div>
</div>
