"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransmissionDataComponent = void 0;
const core_1 = require("@angular/core");
class TransmissionDataComponent {
    constructor() {
        this.selectEmail = new core_1.EventEmitter();
        this.compareViews = new core_1.EventEmitter();
    }
}
exports.TransmissionDataComponent = TransmissionDataComponent;
