"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefinitionsService = void 0;
const http_1 = require("@angular/common/http");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const table_store_1 = require("@mimecast-ui/table-store");
const i0 = require("@angular/core");
const i1 = require("@angular/common/http");
class DefinitionsService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    createApDefinition(request) {
        return this.httpClient.post('/api/policy/attachmentprotect/create-definition', request);
    }
    updateApDefinition(request) {
        return this.httpClient.post('/api/policy/attachmentprotect/update-ttp-definition', request);
    }
    createDefinition(definition) {
        const payload = this.createAPIPayLoad(definition);
        return this.createApDefinition(payload);
    }
    updateDefinition(definition, definitionId) {
        const payload = this.createAPIPayLoad(definition, definitionId);
        return this.updateApDefinition(payload);
    }
    createAPIPayLoad(definition, definitionId) {
        let groups = null;
        let outboundGroup = null;
        let journalGroup = null;
        if (definition.notifyGroup) {
            groups = definition.notifyGroup.id;
        }
        if (definition.outboundNotifyGroup) {
            outboundGroup = definition.outboundNotifyGroup.id;
        }
        if (definition.journalNotifyGroup) {
            journalGroup = definition.journalNotifyGroup.id;
        }
        const payload = {
            id: definitionId ? definitionId : undefined,
            action: definition.action,
            description: definition.description,
            journalNotifyGroup: journalGroup,
            outboundNotifyGroup: outboundGroup,
            notifyGroup: groups,
            safeDocFormat: definition.safeDocFormat,
            safeSpreadsheetFormat: definition.safeSpreadsheetFormat,
            firstSheetOnly: definition.firstSheetOnly,
            notifyOnMalicious: definition.notifyOnMalicious,
            notifyInternalSender: definition.notifyInternalSender,
            notifyInternalRecipient: definition.notifyInternalRecipient,
            notifyExternalSender: definition.notifyExternalSender,
            notifyExternalRecipient: definition.notifyExternalRecipient,
            preEmptiveFailureAction: definition.preEmptiveFailureAction,
            ignoreSignedMessages: definition.ignoreSignedMessages,
            sandboxEncryptionPortalEnabled: definition.sandboxEncryptionPortalEnabled,
            internalForward: definition.internalForward,
            checkInbound: definition.checkInbound,
            checkOutbound: definition.checkOutbound,
            outboundAction: definition.outboundAction,
            outboundFallbackAction: definition.outboundFallbackAction,
            outboundNotifyOnMalicious: definition.outboundNotifyOnMalicious,
            outboundNotifyInternalSender: definition.outboundNotifyInternalSender,
            checkJournal: definition.checkJournal,
            journalRemediationAction: definition.journalRemediationAction,
            journalFallbackRemediationAction: definition.journalFallbackRemediationAction,
            journalNotifyOnMalicious: definition.journalNotifyOnMalicious,
            journalNotifyInternalSender: definition.journalNotifyInternalSender,
            journalNotifyInternalRecipient: definition.journalNotifyInternalRecipient,
            outboundRemediationAction: definition.outboundRemediationAction,
            outboundFallbackRemediationAction: definition.outboundFallbackRemediationAction
        };
        return JSON.parse(JSON.stringify(payload));
    }
    getServerConnection(payload, pagination = table_store_1.defaultPaginationOption) {
        const headers = new http_1.HttpHeaders().set('logoutOnError', 'false');
        return rxjs_1.forkJoin([
            this.httpClient.post('/api/service/connection/get-server-connection', undefined, {
                headers
            }),
            this.httpClient.post('/api/connectors/find-connectors', {
                data: [payload],
                meta: { pagination }
            }, { headers })
        ]).pipe(operators_1.map(([serverList, connectorsList]) => [...serverList.all, ...connectorsList.first.connectors]));
    }
}
exports.DefinitionsService = DefinitionsService;
DefinitionsService.ɵprov = i0.ɵɵdefineInjectable({ factory: function DefinitionsService_Factory() { return new DefinitionsService(i0.ɵɵinject(i1.HttpClient)); }, token: DefinitionsService, providedIn: "root" });
