"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.identityWaitingPeriod = exports.identityDashboardTabs = exports.IdentityDashboardTabNames = exports.columnList = void 0;
exports.columnList = [
    'policyName',
    'domainAuthentication',
    'cloudAuthentication',
    'secondFactor',
    'groups'
];
var IdentityDashboardTabNames;
(function (IdentityDashboardTabNames) {
    IdentityDashboardTabNames[IdentityDashboardTabNames["applications"] = 0] = "applications";
    IdentityDashboardTabNames[IdentityDashboardTabNames["authenticationPolicies"] = 1] = "authenticationPolicies";
})(IdentityDashboardTabNames = exports.IdentityDashboardTabNames || (exports.IdentityDashboardTabNames = {}));
exports.identityDashboardTabs = [
    {
        url: IdentityDashboardTabNames[IdentityDashboardTabNames.applications],
        label: '$I18N_IDENTITY_DASHBOARD.TABS.APPLICATIONS'
    },
    {
        url: IdentityDashboardTabNames[IdentityDashboardTabNames.authenticationPolicies],
        label: '$I18N_IDENTITY_DASHBOARD.TABS.AUTHENTICATION_POLICIES'
    }
];
exports.identityWaitingPeriod = 1500;
