"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WizardBaseDirective = void 0;
const selectors = require("../reducers");
const wizardActions = require("../actions/wizard.actions");
class WizardBaseDirective {
    constructor(wizardId, store, translateService) {
        this.wizardId = wizardId;
        this.store = store;
        this.translateService = translateService;
        if (!wizardId) {
            throw new Error('wizardId is mandatory');
        }
    }
    ngOnInit() {
        this.isCurrentStepValid$ = this.store.select(selectors.isCurrentStepValid(this.wizardId));
        this.wizardData$ = this.store.select(selectors.getWizardById(this.wizardId));
        this.dataSubscription = this.wizardData$.subscribe(data => (this.wizardData = data));
    }
    ngOnDestroy() {
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }
    }
    onCancel() {
        this.store.dispatch(new wizardActions.CancelAction({
            title: this.getCancelConfirmationText(),
            wizardId: this.wizardId
        }));
    }
    onStepChange(stepId) {
        this.store.dispatch(new wizardActions.SetCurrentStepAction({ wizardId: this.wizardId, stepId }));
    }
    onNext() {
        this.wizard.nextStep();
    }
    onPrevious() {
        this.wizard.previousStep();
    }
}
exports.WizardBaseDirective = WizardBaseDirective;
