"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogsPageComponent = void 0;
const core_1 = require("@angular/core");
const logs_page_config_1 = require("app-new/components/logs-page-config/logs-page-config");
const table_store_1 = require("@mimecast-ui/table-store");
const logs_page_config_2 = require("./logs-page.config");
class LogsPageComponent extends table_store_1.TableBaseComponent {
    constructor(tableStore, tabsService) {
        super(tableStore, 'ttpImpersonationLogs');
        this.tabsService = tabsService;
        this.logsPageConfig = new logs_page_config_1.LogsPageConfig(logs_page_config_2.logsPageConfigData);
        this.permitSender = new core_1.EventEmitter();
        this.blockSender = new core_1.EventEmitter();
        this.monitorDomain = new core_1.EventEmitter();
        this.export = new core_1.EventEmitter();
    }
    ngOnInit() {
        this.setupRefresh();
        this.setupSubscriptions();
    }
    ngOnDestroy() {
        this.logDetailsIsVisibleSubscription.unsubscribe();
        super.ngOnDestroy();
    }
    onFilterChange(filter) {
        super.onFilterChange(filter);
        this.searchFilter = filter;
    }
    setupAndDoExport() {
        // When removing 'readonly' on line 27, we would still get a TypeError here so to resolve this issue for now, we are defining a new const
        const logsPageConfig = new logs_page_config_1.LogsPageConfig(logs_page_config_2.logsPageConfigData);
        logsPageConfig.setupExportFilter(this.searchFilter);
        this.export.emit(logsPageConfig);
    }
    setupRefresh() {
        this.tabsService.getSelectedTab().onRefresh = () => {
            super.onFilterChange(this.searchFilter);
        };
    }
    setupSubscriptions() {
        this.logDetailsIsVisibleSubscription = this.logDetailsIsVisible$.subscribe(visible => {
            if (!visible) {
                this.dispatchClearSelectedRow();
            }
        });
    }
}
exports.LogsPageComponent = LogsPageComponent;
