"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetAcceptedEmailResetStateAction = exports.GetAcceptedEmailRefreshAction = exports.GetAcceptedEmailPaginationAction = exports.GetAcceptedEmailPaginationStopLoadingAction = exports.GetAcceptedEmailSelectRowAction = exports.GetAcceptedEmailFailAction = exports.GetAcceptedEmailSuccessAction = exports.GetAcceptedEmailRequestAction = exports.GET_ACCEPTED_EMAIL_RESET_STATE = exports.GET_ACCEPTED_EMAIL_PAGINATION_STOP_LOADING = exports.GET_ACCEPTED_EMAIL_REFRESH = exports.GET_ACCEPTED_EMAIL_PAGINATION = exports.GET_ACCEPTED_EMAIL_SELECT_ROW = exports.GET_ACCEPTED_EMAIL_FAIL = exports.GET_ACCEPTED_EMAIL_SUCCESS = exports.GET_ACCEPTED_EMAIL_REQUEST = void 0;
exports.GET_ACCEPTED_EMAIL_REQUEST = '[Accepted Email] Request Get Accepted Email';
exports.GET_ACCEPTED_EMAIL_SUCCESS = '[Accepted Email] Get Accepted Email Success';
exports.GET_ACCEPTED_EMAIL_FAIL = '[Accepted Email] Get Accepted Email Fail';
exports.GET_ACCEPTED_EMAIL_SELECT_ROW = '[Accepted Email] Get Accepted Email Select Row';
exports.GET_ACCEPTED_EMAIL_PAGINATION = '[Accepted Email] Get Accepted Email Pagination';
exports.GET_ACCEPTED_EMAIL_REFRESH = '[Accepted Email] Get Accepted Email Refresh';
exports.GET_ACCEPTED_EMAIL_PAGINATION_STOP_LOADING = '[Accepted Email] Get Accepted Email Pagination Stop Loading';
exports.GET_ACCEPTED_EMAIL_RESET_STATE = '[Accepted Email] Reset State';
class GetAcceptedEmailRequestAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_ACCEPTED_EMAIL_REQUEST;
    }
}
exports.GetAcceptedEmailRequestAction = GetAcceptedEmailRequestAction;
class GetAcceptedEmailSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_ACCEPTED_EMAIL_SUCCESS;
    }
}
exports.GetAcceptedEmailSuccessAction = GetAcceptedEmailSuccessAction;
class GetAcceptedEmailFailAction {
    constructor() {
        this.type = exports.GET_ACCEPTED_EMAIL_FAIL;
    }
}
exports.GetAcceptedEmailFailAction = GetAcceptedEmailFailAction;
class GetAcceptedEmailSelectRowAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_ACCEPTED_EMAIL_SELECT_ROW;
    }
}
exports.GetAcceptedEmailSelectRowAction = GetAcceptedEmailSelectRowAction;
class GetAcceptedEmailPaginationStopLoadingAction {
    constructor() {
        this.type = exports.GET_ACCEPTED_EMAIL_PAGINATION_STOP_LOADING;
    }
}
exports.GetAcceptedEmailPaginationStopLoadingAction = GetAcceptedEmailPaginationStopLoadingAction;
class GetAcceptedEmailPaginationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_ACCEPTED_EMAIL_PAGINATION;
    }
}
exports.GetAcceptedEmailPaginationAction = GetAcceptedEmailPaginationAction;
class GetAcceptedEmailRefreshAction {
    constructor() {
        this.type = exports.GET_ACCEPTED_EMAIL_REFRESH;
    }
}
exports.GetAcceptedEmailRefreshAction = GetAcceptedEmailRefreshAction;
class GetAcceptedEmailResetStateAction {
    constructor() {
        this.type = exports.GET_ACCEPTED_EMAIL_RESET_STATE;
    }
}
exports.GetAcceptedEmailResetStateAction = GetAcceptedEmailResetStateAction;
