"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogsTabComponent = void 0;
const table_store_1 = require("@mimecast-ui/table-store");
const forms_1 = require("@angular/forms");
const logs_tab_static_value_1 = require("./logs-tab.static-value");
const components_1 = require("@mimecast-ui/components");
const table_filters_transform_1 = require("../../utils/table-filters-transform");
const log_1 = require("../../models/log");
const exportActions = require("../../actions/export/export.actions");
const export_1 = require("../../models/export");
const rxjs_1 = require("rxjs");
const reducers_1 = require("../../reducers");
const operators_1 = require("rxjs/operators");
const common_util_1 = require("../../utils/common-util");
class LogsTabComponent extends table_store_1.TableBaseComponent {
    constructor(store, translateService, exportService) {
        super(store, 'RemediationLogs');
        this.store = store;
        this.translateService = translateService;
        this.exportService = exportService;
        this.externalTableSearchCount = 0;
        this.columnList = logs_tab_static_value_1.columnList;
        this.filterSearchOptions = logs_tab_static_value_1.filterSearchOptions;
        this.searchFilters = logs_tab_static_value_1.searchFilters;
        this.externalTableSearchCount$ = new rxjs_1.BehaviorSubject(this.externalTableSearchCount);
        this.showFailureCode = common_util_1.showFailureCode;
        const formBuilder = new forms_1.FormBuilder();
        this.formGroup = formBuilder.group({
            dateRangePicker: [components_1.defaultDateRangePickerConfig.dateRanges[0], []]
        });
        this.dropdownOptions = [
            {
                label: '$I18N_REMEDIATION_LOGS_TAB.VIEW_INCIDENT',
                action: (row) => this.openItem(row)
            }
        ];
    }
    ngOnInit() {
        super.ngOnInit();
        this.isLoading$ = rxjs_1.combineLatest(this.store.select(reducers_1.isLogsTableLoading), this.isLoading$).pipe(operators_1.map(([refresh, reload]) => refresh || reload));
    }
    onSearch(filters) {
        this.filterSearch = { searchBy: { fieldName: filters.filterBy, value: filters.search } };
        this.runFilterSearchAndResetPaginator();
    }
    onSearchClear() {
        this.filterSearch = undefined;
        this.runFilterSearchAndResetPaginator();
    }
    onBundleFilterChange(filters) {
        this.filters = table_filters_transform_1.transformFilters(filters);
        this.runFilterSearchAndResetPaginator();
    }
    onDateRangeFilterChange(value) {
        if (!value.range) {
            return;
        }
        this.filtersDateRange = table_filters_transform_1.transformDateRangeFilter(value.range);
        this.runFilterSearchAndResetPaginator();
    }
    getTranslatedActionName(key) {
        const translatedKey = log_1.LogActionDictionary[key];
        const translationRoot = '$I18N_REMEDIATION_LOGS_TAB.FILTERS.COLUMN_VALUE_FILTER_FIELD.ACTION_FILTERS.';
        return this.translateService.instant(translationRoot + translatedKey);
    }
    getTranslatedStatusName(key) {
        const translatedKey = log_1.LogMessageStatusDictionary[key];
        const translationRoot = '$I18N_REMEDIATION.STATUS.';
        return this.translateService.instant(translationRoot + translatedKey);
    }
    onExportClicked() {
        const columnMapParams = {
            // columns: copy columnList array but remove last item which is the actions column
            columns: this.columnList.slice(0, this.columnList.length - 1),
            translationKey: '$I18N_REMEDIATION_LOGS_TAB.COLUMNS'
        };
        const columnConfig = {
            columnsToExport: this.exportService.mapExportedColumnsItem(columnMapParams),
            endPoint: export_1.ExportsEndPoints.logs,
            filters: this.currentFilters
        };
        this.store.dispatch(new exportActions.OpenPanelForExportAction(columnConfig));
    }
    runFilterSearchAndResetPaginator() {
        const searchPayload = Object.assign(Object.assign(Object.assign({}, this.filterSearch), this.filtersDateRange), this.filters);
        this.onFilterChange(searchPayload);
        this.externalTableSearchCount$.next(++this.externalTableSearchCount);
    }
}
exports.LogsTabComponent = LogsTabComponent;
