<div class="mc-api-application-step-block-container">
<div class="mc-api-application-step-block">
  <mc-wizard-step-header [label]="'$I18N_API_INTEGRATION_WIZARD.SELECT_INTEGRATION.HEADER'">
  </mc-wizard-step-header>

  <div class="row form-group cortex">
    <mc-integration-provider-selector
      [state]="providerState('cortex')"
      [title]="'$I18N_API_INTEGRATION_WIZARD.SELECT_INTEGRATION.CORTEX.TITLE'"
      [description]="'$I18N_API_INTEGRATION_WIZARD.SELECT_INTEGRATION.CORTEX.DESCRIPTION'"
      (onProviderSelected)="selectProvider('cortex')"
    >
      <img
        src="/administration/resources/assets/cortex.15be0381e5d6696b222a44f97d7d983b.png"
        width="170px"
        height="110px"
        alt="Cortex logo"
      />
    </mc-integration-provider-selector>
  </div>

  <div
    *mcCapabilities="'Temporary.Services.ApiApplications.Wildfire.Integration'"
    class="row form-group wildfire"
  >
    <mc-integration-provider-selector
        [state]="providerState('wildfire')"
        [title]="'$I18N_API_INTEGRATION_WIZARD.SELECT_INTEGRATION.WILDFIRE.TITLE'"
        [description]="'$I18N_API_INTEGRATION_WIZARD.SELECT_INTEGRATION.WILDFIRE.DESCRIPTION'"
        (onProviderSelected)="selectProvider('wildfire')"
      >
      <img
        src="/administration/resources/assets/wildfire.bc00911e7cb2d69dc924230204e576ae.png"
        width="170px"
        height="110px"
        alt="Wildfire logo"
      />
    </mc-integration-provider-selector>
  </div>

  <div
    *mcCapabilities="'Temporary.Services.ApiApplications.Crowdstrike.Integration'"
    class="row form-group crowdstrike"
  >
    <mc-integration-provider-selector
        [state]="providerState('crowdstrike')"
        [title]="'$I18N_API_INTEGRATION_WIZARD.SELECT_INTEGRATION.CROWDSTRIKE.TITLE'"
        [description]="'$I18N_API_INTEGRATION_WIZARD.SELECT_INTEGRATION.CROWDSTRIKE.DESCRIPTION'"
        (onProviderSelected)="selectProvider('crowdstrike')"
      >
      <img
        src="/administration/resources/assets/crowdstrike.e9f08da02a5df0f8895d9a19711bc2e1.jpg"
        width="170px"
        height="110px"
        alt="Crowdstrike logo"
      />
    </mc-integration-provider-selector>
  </div>

  <div
    *mcCapabilities="'Temporary.Services.ApiApplications.Okta.Integration'"
    class="row form-group okta"
  >
    <mc-integration-provider-selector
      [state]="providerState('scim_okta')"
      [title]="'$I18N_API_INTEGRATION_WIZARD.SELECT_INTEGRATION.OKTA.TITLE'"
      [description]="'$I18N_API_INTEGRATION_WIZARD.SELECT_INTEGRATION.OKTA.DESCRIPTION'"
      (onProviderSelected)="selectProvider('scim_okta')"
    >
      <img
        src="/administration/resources/assets/okta.02adb122cd1dd16f14b2637d97996d5b.png"
        width="170px"
        height="110px"
        alt="Okta logo"
      />
    </mc-integration-provider-selector>
  </div>

  <div
    *mcCapabilities="'Temporary.Services.ApiApplications.OktaEvidenceBasedControl.Integration'"
    class="row form-group okta-evidence-based-control"
  >
    <mc-integration-provider-selector
      [state]="providerState('okta_evidence_based_control')"
      [title]="'$I18N_API_INTEGRATION_WIZARD.SELECT_INTEGRATION.OKTA_EVIDENCE_BASED_CONTROL.TITLE'"
      [description]="'$I18N_API_INTEGRATION_WIZARD.SELECT_INTEGRATION.OKTA_EVIDENCE_BASED_CONTROL.DESCRIPTION'"
      (onProviderSelected)="selectProvider('okta_evidence_based_control')"
    >
      <img
        src="/administration/resources/assets/okta.02adb122cd1dd16f14b2637d97996d5b.png"
        width="170px"
        height="110px"
        alt="Okta logo"
      />
    </mc-integration-provider-selector>
  </div>

  <div class="panel-half-padding-left tech-partners-link">
    {{ '$I18N_API_INTEGRATION_WIZARD.TECH_PARTNERS_LINK.DESCRIPTION' | translate }}
    <a
      [href]="techPartnersLink"
      target="_blank"
      rel="noopener noreferrer"
    >
      {{ '$I18N_API_INTEGRATION_WIZARD.TECH_PARTNERS_LINK.TEXT' | translate }}
    </a>
  </div>
</div>
</div>

<mc-footer-container>
  <div class="pull-right no-select mc-button-footer">
    <button class="btn btn-text" (click)="cancel()">
      {{ '$I18N_API_INTEGRATION_WIZARD.BUTTONS.CANCEL' | translate }}
    </button>
    <button
      class="btn btn-primary"
      [disabled]="!canGotoNext()"
      (click)="gotoNextStep()"
    >
      {{ '$I18N_API_INTEGRATION_WIZARD.BUTTONS.NEXT' | translate }}
    </button>
  </div>
</mc-footer-container>
