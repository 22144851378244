"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwitchAccountInterceptor = void 0;
const store_1 = require("@ngrx/store");
const operators_1 = require("rxjs/operators");
const core_interfaces_1 = require("../../model/core.interfaces");
const reducers_1 = require("../../reducers");
class SwitchAccountInterceptor {
    constructor(store) {
        this.store = store;
    }
    intercept(req, next) {
        const isFormData = req.body && req.body instanceof FormData;
        if (this.isApi(req.url) || this.isAuthDownload(req.url)) {
            if (!isFormData) {
                if (req.method === 'GET') {
                    const xMcArg = this.loadConfigData(JSON.parse(req.headers.get('x-mc-arg')));
                    if (xMcArg.meta) {
                        const transformedRequest = req.clone({
                            setHeaders: { 'x-mc-arg': JSON.stringify(xMcArg) }
                        });
                        return next.handle(transformedRequest);
                    }
                    return next.handle(req);
                }
                else {
                    const transformedRequest = req.clone({
                        body: this.loadConfigData(req.body)
                    });
                    return next.handle(transformedRequest);
                }
            }
            else {
                const headers = this.getSwitchAccountAsHeader();
                if (headers) {
                    const transformedRequest = req.clone({
                        setHeaders: headers
                    });
                    return next.handle(transformedRequest);
                }
            }
        }
        return next.handle(req);
    }
    loadConfigData(data) {
        data = data || {};
        const meta = (data && data.meta) || {};
        this.store
            .pipe(store_1.select(reducers_1.getContextualAccountSession), operators_1.first(), core_interfaces_1.isDefined())
            .subscribe((session) => {
            if (session.accountcode) {
                meta.accountCode = session.accountcode;
                data.meta = meta;
            }
        });
        return data;
    }
    getSwitchAccountAsHeader() {
        let switchAccountCode = null;
        this.store
            .pipe(store_1.select(reducers_1.getContextualAccountSession), operators_1.first(), core_interfaces_1.isDefined())
            .subscribe((session) => (switchAccountCode = session.accountcode));
        return switchAccountCode ? { 'mc-switch-account-code': switchAccountCode } : null;
    }
    isApi(url) {
        return (url &&
            (url.toLowerCase().indexOf('/api/') !== -1 || url.toLowerCase().indexOf('/clp/') !== -1));
    }
    isAuthDownload(url) {
        return url && url.toLowerCase().indexOf('/auth/download/') !== -1;
    }
}
exports.SwitchAccountInterceptor = SwitchAccountInterceptor;
