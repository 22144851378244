"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiGatewayKeyGenSummaryStepComponent = void 0;
const core_1 = require("@angular/core");
class ApiGatewayKeyGenSummaryStepComponent {
    constructor(ts) {
        this.ts = ts;
        this.showAccessKey = false;
        this.showSecretKey = false;
        this.onCopiedToClipboard = new core_1.EventEmitter();
        this.SUPPORT_LINK = 'https://community.mimecast.com/s/article/Raising-a-Mimecast-Support-Case-1045768043';
    }
    ngOnInit() {
        this.notification = {
            status: 'warning',
            msg: this.ts.instant('$I18N_API_GATEWAY_APPLICATIONS_KEYGEN_WIZARD.SUMMARY_STEP.NOTIFICATION'),
            boxed: true
        };
    }
    showSecretKeyClicked() {
        this.showSecretKey = true;
        this.showAccessKey = false;
    }
    hideSecretKeyClicked() {
        this.showSecretKey = false;
    }
    showAccessKeyClicked() {
        this.showAccessKey = true;
        this.showSecretKey = false;
    }
    hideAccessKeyClicked() {
        this.showAccessKey = false;
    }
    clipboardSuccess(msg) {
        this.onCopiedToClipboard.emit(msg);
    }
    ngOnDestroy() { }
}
exports.ApiGatewayKeyGenSummaryStepComponent = ApiGatewayKeyGenSummaryStepComponent;
