"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiGatewaySaveComponent = void 0;
const operators_1 = require("rxjs/operators");
const reducers = require("../../../reducers");
const actions = require("../../../actions");
class ApiGatewaySaveComponent {
    constructor(store, stateService) {
        this.store = store;
        this.stateService = stateService;
    }
    ngOnInit() {
        this.application$ = this.store.select(reducers.getApiGatewaySaveApiApplication);
        this.applicationIdentifier$ = this.store.select(reducers.getApiGatewaySaveIdentifier);
        this.applicationDetails$ = this.store.select(reducers.getApiGatewaySaveDetails);
        this.applicationSettings$ = this.store.select(reducers.getApiGatewaySaveSettings);
        this.applicationStatus$ = this.store.select(reducers.getApiGatewaySaveStatus);
        this.applicationNameDisabled$ = this.store.select(reducers.getApiGatewayApplicationNameDisabled);
        this.mode$ = this.store.select(reducers.getApiGatewaySaveMode);
        this.mode$
            .pipe(operators_1.first(), operators_1.tap(mode => (this.editing = mode === 'EDIT')))
            .subscribe();
    }
    updateDetails($event) {
        this.store.dispatch(new actions.InputApiApplicationDetailsAction($event));
    }
    updateSettings($event) {
        this.store.dispatch(new actions.InputApiApplicationSettingsAction($event));
    }
    updateStatus($event) {
        this.store.dispatch(new actions.InputApiApplicationStatusAction($event));
    }
    save($event) {
        if (this.editing) {
            this.store.dispatch(new actions.UpdateApiApplicationAction($event));
        }
        else {
            this.store.dispatch(new actions.CreateApiApplicationAction($event));
        }
    }
    cancel() {
        this.close();
    }
    close() {
        this.stateService.$state.go('api-applications-list');
    }
    ngOnDestroy() {
        this.store.dispatch(new actions.CloseSaveApiApplicationAction());
        this.store.dispatch(new actions.CloseGatewayApplicationAction());
    }
}
exports.ApiGatewaySaveComponent = ApiGatewaySaveComponent;
