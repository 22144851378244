"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IdentityController = void 0;
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const dashboard_page_component_1 = require("./containers/dashboard/dashboard-page.component");
const create_policy_page_component_1 = require("./containers/create-policy/create-policy-page.component");
class IdentityController {
    constructor($stateParams, $scope) {
        this.$stateParams = $stateParams;
        this.$scope = $scope;
        const vm = this;
        vm.$scope.stateParams = vm.$stateParams;
    }
}
exports.IdentityController = IdentityController;
IdentityController.$inject = ['$stateParams', '$scope'];
angular
    .module('identity', [])
    .controller('IdentityController', IdentityController)
    .directive('mcIdentityDashboard', static_1.downgradeComponent({
    component: dashboard_page_component_1.IdentityDashboardPageComponent
}))
    .directive('mcIdentityCreatePolicyPage', static_1.downgradeComponent({
    component: create_policy_page_component_1.IdentityCreatePolicyPageComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        const breadcrumbs = [];
        $stateProvider
            .state('identity-dashboard', {
            url: '/identity-dashboard',
            params: {
                tabName: null
            },
            data: {
                isFixedLayout: false,
                checkProgress: false,
                tabReload: true,
                capabilities: 'Permission.ID_MANAGEMENT_READ',
                tabTitle: '$I18N_MEGA_MENU_GROUP_IDENTITY_LABEL',
                tabId: 'identityDashboard'
            },
            views: {
                main: {
                    template: '<mc-identity-dashboard></mc-identity-dashboard>',
                    controller: 'IdentityController'
                }
            }
        })
            .state('identity-create-policy-page', {
            params: {
                isEditFlow: true
            },
            data: {
                isFixedLayout: false,
                checkProgress: false,
                capabilities: 'Permission.ID_MANAGEMENT_READ',
                tabTitle: '$I18N_IDENTITY_DASHBOARD.PAGE_TITLE',
                breadcrumbs,
                tabId: 'identityDashboard'
            },
            views: {
                main: {
                    template: '<mc-identity-create-policy-page></mc-identity-create-policy-page>',
                    controller: 'IdentityController'
                }
            },
            onEnter: [
                '$stateParams',
                function ($stateParams) {
                    breadcrumbs.length = 0;
                    breadcrumbs.push('$I18N_MEGA_MENU_GROUP_IDENTITY_LABEL');
                    breadcrumbs.push({
                        label: '$I18N_IDENTITY_DASHBOARD.PAGE_TITLE',
                        stateName: 'identity-dashboard',
                        stateParams: { tabName: 'overview' }
                    });
                    breadcrumbs.push({
                        label: '$I18N_IDENTITY_DASHBOARD.TABS.AUTHENTICATION_POLICIES',
                        stateName: 'identity-dashboard',
                        stateParams: { tabName: 'authenticationPolicies' }
                    });
                    breadcrumbs.push({
                        label: !$stateParams.isEditFlow
                            ? '$I18N_IDENTITY_POLICY_WIZARD.CREATE_TAB_NAME'
                            : '$I18N_IDENTITY_POLICY_WIZARD.EDIT_TAB_NAME'
                    });
                }
            ]
        });
    }
]);
