"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const list_component_1 = require("./containers/list.component");
angular
    .module('messagecenter.bounces', [])
    .controller('BouncesController', [
    '$stateParams',
    '$scope',
    function ($stateParams, $scope) {
        $scope.stateParams = $stateParams;
    }
])
    .directive('mcBouncesList', static_1.downgradeComponent({
    component: list_component_1.ListComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider.state('bounces-list', {
            url: '/message-center/bounces',
            data: {
                capabilities: 'Temporary.NewBouncedEnabled && Permission.BOUNCES_READ',
                isFixedLayout: false,
                checkProgress: false,
                tabId: 'messagecenter-bounces-tab',
                tabTitle: '',
                tabReload: false,
                breadcrumbs: [
                    {
                        label: '$18N_BOUNCES_LIST.BREADCRUMBS.FIRST_LEVEL',
                        stateName: '',
                        stateParams: { tabName: 'overview' }
                    },
                    {
                        label: '$18N_BOUNCES_LIST.BREADCRUMBS.SECOND_LEVEL',
                        stateName: '',
                        stateParams: { tabName: 'overview' }
                    }
                ]
            },
            views: {
                main: {
                    template: '<mc-bounces-list></mc-bounces-list>',
                    controller: 'BouncesController'
                }
            }
        });
    }
]);
