<mc-layout-detail-wizard
        [keyTitle]="keyTitle$ | async">
    <mc-wizard #wizard (changeStep)="onStepChange($event)" [ngClass]="{ 'wizard-loading': isLoading$ | async }">
        <mc-wizard-step label="$I18N_IDENTITY_CREATE_POLICY_WIZARD.WIZARD_STEP.POLICY_DETAILS">
          <mc-identity-policy-details-step *ngIf="!(isLoading$ | async)"></mc-identity-policy-details-step>
          <div class="spinner-wrapper">
            <mc-spinner *ngIf="isLoading$ | async"></mc-spinner>
          </div>
        </mc-wizard-step>

        <mc-wizard-step label="$I18N_IDENTITY_CREATE_POLICY_WIZARD.WIZARD_STEP.METHODS">
          <mc-identity-authentication-policies-step *ngIf="!(isLoading$ | async)"></mc-identity-authentication-policies-step>
          <div class="spinner-wrapper">
            <mc-spinner *ngIf="isLoading$ | async"></mc-spinner>
          </div>
        </mc-wizard-step>

        <mc-wizard-step label="$I18N_IDENTITY_CREATE_POLICY_WIZARD.WIZARD_STEP.LOCATION">
          <mc-identity-ip-addresses-step *ngIf="!(isLoading$ | async)"></mc-identity-ip-addresses-step>
          <div class="spinner-wrapper">
            <mc-spinner *ngIf="isLoading$ | async"></mc-spinner>
          </div>
        </mc-wizard-step>

        <mc-wizard-step label="$I18N_IDENTITY_CREATE_POLICY_WIZARD.WIZARD_STEP.SESSION_TIME_OUT">
          <mc-identity-session-timeout-step *ngIf="!(isLoading$ | async)"></mc-identity-session-timeout-step>
          <div class="spinner-wrapper">
            <mc-spinner *ngIf="isLoading$ | async"></mc-spinner>
          </div>
        </mc-wizard-step>

        <mc-wizard-step label="$I18N_IDENTITY_CREATE_POLICY_WIZARD.WIZARD_STEP.APPLIES_TO">
          <mc-identity-applies-to-step *ngIf="!(isLoading$ | async)"></mc-identity-applies-to-step>
          <div class="spinner-wrapper">
            <mc-spinner *ngIf="isLoading$ | async"></mc-spinner>
          </div>
        </mc-wizard-step>

        <mc-wizard-step label="$I18N_IDENTITY_CREATE_POLICY_WIZARD.WIZARD_STEP.POLICY_SUMMARY">
          <mc-identity-policy-summary-step *ngIf="!(isLoading$ | async)"></mc-identity-policy-summary-step>
          <div class="spinner-wrapper">
            <mc-spinner *ngIf="isLoading$ | async"></mc-spinner>
          </div>
        </mc-wizard-step>
    </mc-wizard>

    <mc-footer-container>
        <div class="pull-right no-select mc-button-footer">
            <button class="btn mc-create-policy-cancel" (click)="cancel();">{{'$I18N_COMMON_BTN_CANCEL' | translate}}</button>

            <ng-container *ngIf="wizard">
                <button *ngIf="wizard.hasPreviousStep$ | async"
                        class="btn btn-secondary panel-half-margin-right mc-create-policy-previous"
                        (click)="wizard.previousStep()">{{'$I18N_COMMON_BTN_PREVIOUS' | translate}}</button>

                <button *ngIf="(wizard.hasNextStep$ | async)"
                        class="btn btn-primary mc-create-policy-next"
                        [disabled]="!(canContinue$ | async)"
                        (click)="wizard.nextStep();">{{'$I18N_COMMON_BTN_NEXT' | translate}}</button>

                <mc-live-button
                        class="mc-create-new-policy"
                        *ngIf="wizard.isLastStep$ | async"
                        overrideClasses="btn-primary"
                        [label]="saveButtonLabel$ | async"
                        [isLoading]="apiResponsePending$ | async"
                        (mcClick)="finish()">
                </mc-live-button>
            </ng-container>
        </div>
    </mc-footer-container>

</mc-layout-detail-wizard>
