<mc-layout-modal-simple keyTitle="{{title}}">
  <mc-footer-container>
     <button type="button" class="btn btn-secondary" (click)="onCancel()">
      {{'$I18N_MONITORED_EXTERNAL_DOMAINS_CONFIRMATION_MODAL.BUTTON_LABEL.CANCEL' | translate}}
    </button>
    <button type="button" class="btn btn-danger" (click)="onDelete()">
      <i class="far fa-trash-alt"></i>
      <span>{{'$I18N_MONITORED_EXTERNAL_DOMAINS_CONFIRMATION_MODAL.BUTTON_LABEL.DELETE' | translate}}</span>
    </button>
  </mc-footer-container>
</mc-layout-modal-simple>
