"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgClpService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const moment = require("moment");
class SwgClpService {
    /**
     * Depends on the api service
     */
    constructor(service) {
        this.service = service;
    }
    getTeams(connectorId, searchText) {
        return this.service.getTeams(connectorId, searchText).pipe(operators_1.map(response => response), operators_1.catchError(this.handleError));
    }
    getChannels(connectorId, teamId, searchText) {
        return this.service.getChannels(connectorId, teamId, searchText).pipe(operators_1.map(response => response), operators_1.catchError(this.handleError));
    }
    getScansByFileType(dateRange) {
        // @ts-ignore
        return this.service.getScansByFileType(dateRange).pipe(operators_1.map(response => response['scanCountByFileType']), operators_1.map(x => Object.keys(x).map(a => {
            return { fileType: a, count: x[a] };
        })), operators_1.catchError(this.handleError));
    }
    getVerdictsByApp(dateRange) {
        // @ts-ignore
        return this.service.getVerdictsByApp(dateRange).pipe(operators_1.map(response => response['verdictsDistributedByCloudApps']), operators_1.catchError(this.handleError));
    }
    getVerdictsByDate(dateRange) {
        return this.service.getVerdictsByDate(dateRange).pipe(operators_1.map(response => response['verdictsByDates']), operators_1.map(verdictByDates => {
            if (Object.keys(verdictByDates).length === 0) {
                return [];
            }
            const start = moment(dateRange.start);
            const end = moment(dateRange.end);
            const resolution = end.diff(start, 'day') <= 2 ? 'hours' : 'days';
            const diff = end.diff(start, resolution);
            const normalized = [];
            for (let i = 0; i <= diff; i++) {
                const nextPoint = start
                    .clone()
                    .startOf(resolution)
                    .add(i, resolution);
                let detectedScannedFiles = 0;
                let totalScannedFiles = 0;
                if (verdictByDates) {
                    Object.keys(verdictByDates)
                        .filter(date => moment(date).isSame(nextPoint, resolution))
                        .forEach(date => {
                        totalScannedFiles += verdictByDates[date].totalScannedFiles;
                        detectedScannedFiles += verdictByDates[date].detectedScannedFiles;
                    });
                }
                normalized.push({
                    date: nextPoint.toDate(),
                    detectedScannedFiles,
                    totalScannedFiles
                });
            }
            return normalized.sort((a, b) => moment(a.date).unix() - moment(b.date).unix());
        }), operators_1.catchError(this.handleError));
    }
    handleError() {
        return rxjs_1.of([]);
    }
}
exports.SwgClpService = SwgClpService;
