"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CleanStore = exports.LoadSuccessOneDriveFilesAction = exports.LoadOneDriveFilesAction = exports.LoadFailureSearchFolderContactsAction = exports.LoadSuccessSearchFolderContactsAction = exports.LoadSearchFolderContactsAction = exports.LoadSuccessFolderContactsAction = exports.LoadFolderContactsAction = exports.LoadFailureSearchFolderCalendarsAction = exports.LoadSuccessSearchFolderCalendarsAction = exports.LoadSearchFolderCalendarsAction = exports.LoadSuccessFolderCalendarsAction = exports.LoadFolderCalendarsAction = exports.LoadFailureSearchFolderEmailsAction = exports.LoadSuccessSearchFolderEmailsAction = exports.LoadSearchFolderEmailsAction = exports.LoadSuccessFolderEmailsAction = exports.LoadFolderEmailsAction = exports.CLEAN_STORE = exports.LOAD_SUCCESS_ONE_DRIVE_FILES = exports.LOAD_ONE_DRIVE_FILES = exports.LOAD_FAILURE_EXCHANGE_SEARCH_FOLDER_CONTACTS = exports.LOAD_SUCCESS_EXCHANGE_SEARCH_FOLDER_CONTACTS = exports.LOAD_EXCHANGE_SEARCH_FOLDER_CONTACTS = exports.LOAD_SUCCESS_EXCHANGE_FOLDER_CONTACTS = exports.LOAD_EXCHANGE_FOLDER_CONTACTS = exports.LOAD_FAILURE_EXCHANGE_SEARCH_FOLDER_CALENDARS = exports.LOAD_EXCHANGE_SEARCH_FOLDER_CALENDARS = exports.LOAD_SUCCESS_EXCHANGE_SEARCH_FOLDER_CALENDARS = exports.LOAD_SUCCESS_EXCHANGE_FOLDER_CALENDARS = exports.LOAD_EXCHANGE_FOLDER_CALENDARS = exports.LOAD_FAILURE_EXCHANGE_SEARCH_FOLDER_EMAILS = exports.LOAD_SUCCESS_EXCHANGE_SEARCH_FOLDER_EMAILS = exports.LOAD_EXCHANGE_SEARCH_FOLDER_EMAILS = exports.LOAD_SUCCESS_EXCHANGE_FOLDER_EMAILS = exports.LOAD_EXCHANGE_FOLDER_EMAILS = void 0;
const node_factory_1 = require("app-new/components/tree/model/factories/node.factory");
exports.LOAD_EXCHANGE_FOLDER_EMAILS = '[Tree - Contents] Load Folder Emails';
exports.LOAD_SUCCESS_EXCHANGE_FOLDER_EMAILS = '[Tree - Contents] Folder Emails successfuly loaded';
exports.LOAD_EXCHANGE_SEARCH_FOLDER_EMAILS = '[Tree - Contents] Load Search Folder Emails';
exports.LOAD_SUCCESS_EXCHANGE_SEARCH_FOLDER_EMAILS = '[Tree - Contents] Searched Folder Emails successfuly loaded';
exports.LOAD_FAILURE_EXCHANGE_SEARCH_FOLDER_EMAILS = '[Tree - Contents] Searched Folder Emails load failure';
exports.LOAD_EXCHANGE_FOLDER_CALENDARS = '[Tree - Contents] Load Folder Calendars';
exports.LOAD_SUCCESS_EXCHANGE_FOLDER_CALENDARS = '[Tree - Contents] Folder Calendars successfuly loaded';
exports.LOAD_SUCCESS_EXCHANGE_SEARCH_FOLDER_CALENDARS = '[Tree - Contents] Load Search Folder Calendars';
exports.LOAD_EXCHANGE_SEARCH_FOLDER_CALENDARS = '[Tree - Contents] Searched Folder Calendars successfuly loaded';
exports.LOAD_FAILURE_EXCHANGE_SEARCH_FOLDER_CALENDARS = '[Tree - Contents] Searched Folder Calendars load failure';
exports.LOAD_EXCHANGE_FOLDER_CONTACTS = '[Tree - Contents] Load Folder Contacts';
exports.LOAD_SUCCESS_EXCHANGE_FOLDER_CONTACTS = '[Tree - Contents] Folder Contacts successfuly loaded';
exports.LOAD_EXCHANGE_SEARCH_FOLDER_CONTACTS = '[Tree - Contents] Load Search Folder Contacts';
exports.LOAD_SUCCESS_EXCHANGE_SEARCH_FOLDER_CONTACTS = '[Tree - Contents] Searched Folder Contacts successfuly loaded';
exports.LOAD_FAILURE_EXCHANGE_SEARCH_FOLDER_CONTACTS = '[Tree - Contents] Searched Folder Contacts load failure';
exports.LOAD_ONE_DRIVE_FILES = '[Tree - Contents] Load One Drive Files';
exports.LOAD_SUCCESS_ONE_DRIVE_FILES = '[Tree - Contents] One Drive files successfuly loaded';
exports.CLEAN_STORE = '[Tree - Contents] Clean Store';
class LoadFolderEmailsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOAD_EXCHANGE_FOLDER_EMAILS;
    }
}
exports.LoadFolderEmailsAction = LoadFolderEmailsAction;
class LoadSuccessFolderEmailsAction {
    constructor(folderId, emails, meta) {
        this.folderId = folderId;
        this.meta = meta;
        this.type = exports.LOAD_SUCCESS_EXCHANGE_FOLDER_EMAILS;
        this.emails = emails.map(email => {
            return node_factory_1.NodeFactory.EMAIL(email);
        });
    }
}
exports.LoadSuccessFolderEmailsAction = LoadSuccessFolderEmailsAction;
class LoadSearchFolderEmailsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOAD_EXCHANGE_SEARCH_FOLDER_EMAILS;
    }
}
exports.LoadSearchFolderEmailsAction = LoadSearchFolderEmailsAction;
class LoadSuccessSearchFolderEmailsAction {
    constructor(emails, meta, pageNumber) {
        this.meta = meta;
        this.pageNumber = pageNumber;
        this.type = exports.LOAD_SUCCESS_EXCHANGE_SEARCH_FOLDER_EMAILS;
        this.emails = emails.map(email => {
            return node_factory_1.NodeFactory.EMAIL(email);
        });
    }
}
exports.LoadSuccessSearchFolderEmailsAction = LoadSuccessSearchFolderEmailsAction;
class LoadFailureSearchFolderEmailsAction {
    constructor() {
        this.type = exports.LOAD_FAILURE_EXCHANGE_SEARCH_FOLDER_EMAILS;
    }
}
exports.LoadFailureSearchFolderEmailsAction = LoadFailureSearchFolderEmailsAction;
class LoadFolderCalendarsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOAD_EXCHANGE_FOLDER_CALENDARS;
    }
}
exports.LoadFolderCalendarsAction = LoadFolderCalendarsAction;
class LoadSuccessFolderCalendarsAction {
    constructor(folderId, calendars, meta) {
        this.folderId = folderId;
        this.meta = meta;
        this.type = exports.LOAD_SUCCESS_EXCHANGE_FOLDER_CALENDARS;
        this.calendars = calendars.map(calendar => {
            return node_factory_1.NodeFactory.CALENDAR(calendar);
        });
    }
}
exports.LoadSuccessFolderCalendarsAction = LoadSuccessFolderCalendarsAction;
class LoadSearchFolderCalendarsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOAD_EXCHANGE_SEARCH_FOLDER_CALENDARS;
    }
}
exports.LoadSearchFolderCalendarsAction = LoadSearchFolderCalendarsAction;
class LoadSuccessSearchFolderCalendarsAction {
    constructor(folderId, calendars, meta) {
        this.folderId = folderId;
        this.meta = meta;
        this.type = exports.LOAD_SUCCESS_EXCHANGE_SEARCH_FOLDER_CALENDARS;
        this.calendars = calendars.map(calendar => {
            return node_factory_1.NodeFactory.CALENDAR(calendar);
        });
    }
}
exports.LoadSuccessSearchFolderCalendarsAction = LoadSuccessSearchFolderCalendarsAction;
class LoadFailureSearchFolderCalendarsAction {
    constructor() {
        this.type = exports.LOAD_FAILURE_EXCHANGE_SEARCH_FOLDER_CALENDARS;
    }
}
exports.LoadFailureSearchFolderCalendarsAction = LoadFailureSearchFolderCalendarsAction;
class LoadFolderContactsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOAD_EXCHANGE_FOLDER_CONTACTS;
    }
}
exports.LoadFolderContactsAction = LoadFolderContactsAction;
class LoadSuccessFolderContactsAction {
    constructor(folderId, contacts, meta) {
        this.folderId = folderId;
        this.meta = meta;
        this.type = exports.LOAD_SUCCESS_EXCHANGE_FOLDER_CONTACTS;
        this.contacts = contacts.map(contact => {
            return node_factory_1.NodeFactory.CONTACT(contact);
        });
    }
}
exports.LoadSuccessFolderContactsAction = LoadSuccessFolderContactsAction;
class LoadSearchFolderContactsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOAD_EXCHANGE_SEARCH_FOLDER_CONTACTS;
    }
}
exports.LoadSearchFolderContactsAction = LoadSearchFolderContactsAction;
class LoadSuccessSearchFolderContactsAction {
    constructor(folderId, contacts, meta) {
        this.folderId = folderId;
        this.meta = meta;
        this.type = exports.LOAD_SUCCESS_EXCHANGE_SEARCH_FOLDER_CONTACTS;
        this.contacts = contacts.map(contact => {
            return node_factory_1.NodeFactory.CONTACT(contact);
        });
    }
}
exports.LoadSuccessSearchFolderContactsAction = LoadSuccessSearchFolderContactsAction;
class LoadFailureSearchFolderContactsAction {
    constructor() {
        this.type = exports.LOAD_FAILURE_EXCHANGE_SEARCH_FOLDER_CONTACTS;
    }
}
exports.LoadFailureSearchFolderContactsAction = LoadFailureSearchFolderContactsAction;
class LoadOneDriveFilesAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOAD_ONE_DRIVE_FILES;
    }
}
exports.LoadOneDriveFilesAction = LoadOneDriveFilesAction;
class LoadSuccessOneDriveFilesAction {
    constructor(folders, files, meta) {
        this.meta = meta;
        this.type = exports.LOAD_SUCCESS_ONE_DRIVE_FILES;
        this.contents = [];
        if (folders) {
            folders.map(folder => {
                this.contents = [...this.contents, node_factory_1.NodeFactory.FOLDER(folder)];
            });
        }
        if (files) {
            files.map(file => {
                this.contents = [...this.contents, node_factory_1.NodeFactory.FILE(file)];
            });
        }
    }
}
exports.LoadSuccessOneDriveFilesAction = LoadSuccessOneDriveFilesAction;
class CleanStore {
    constructor() {
        this.type = exports.CLEAN_STORE;
    }
}
exports.CleanStore = CleanStore;
