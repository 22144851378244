<mc-table class="mc-services-url-protection-policies" tableId="services/url-protection/policies" [isLoading]="isLoading$ | async" [highlightedRow]="selectedRow"
  data-test="cy-policies-table" [columns]="columns" (rowClick)="clickRow($event)" [data]="tableData$ | async"
  translatePrefix="$I18N_SERVICES_URL_PROTECTION_POLICIES_TABLE.TABLE">

  <button
    *ngIf="canModifyPolicy"
    class="mc-table-actions btn btn-primary mc-button-margin-right" data-test="cy-add-policy"
    data-unit-test="unit-add-policy-btn" (click)="addPolicy()">
    {{'$I18N_SERVICES_URL_PROTECTION_POLICIES_TABLE.CREATE_NEW_POLICY' | translate }}
  </button>

  <mc-filters [externalTableSearchCount]="externalTableSearchCount" [metadata]="metadata" [showPagination]="true"
    (paginatorChange)="changePaginator($event)">
    <mc-filter-column-select-and-search [sections]="searchSections" [placeholder]="
      '$I18N_SERVICES_URL_PROTECTION_POLICIES_TABLE.TABLE.FILTERS.COLUMN_VALUE_SINGLE_SELECT_FILTER_FIELD.SEARCH.PLACEHOLDER'
        | translate
    " (filtersChange)="changeSearchFilter($event)" [hasAllOption]="true">
    </mc-filter-column-select-and-search>
  </mc-filters>

  <mc-empty-results keyTitle="$I18N_SERVICES_URL_PROTECTION_POLICIES_TABLE.TABLE.EMPTY_RESULTS"
    iconClass="mc-icon-adcon-icon-stable-no-results-2">
  </mc-empty-results>

  <mc-column key="name">
    <mc-body-cell *mcBodyCellDef="let row" class="text-capitalize">{{ row.name }}</mc-body-cell>
  </mc-column>

  <mc-column key="description">
    <mc-body-cell *mcBodyCellDef="let row" class="text-capitalize">{{ row.description }}</mc-body-cell>
  </mc-column>

  <mc-column key="status">
    <mc-body-cell *mcBodyCellDef="let row" class="text-capitalize">
      <i [ngClass]="displayStatusIcon(row)"></i>
      {{displayStatusLabel(row)}}
    </mc-body-cell>
  </mc-column>

  <mc-column key="bypass">
    <mc-body-cell *mcBodyCellDef="let row">
      <i class="text-success" [ngClass]="displayBypassIcon(row)"></i>
    </mc-body-cell>
  </mc-column>

  <mc-column key="duration">
    <mc-body-cell *mcBodyCellDef="let row">{{ row.duration }}</mc-body-cell>
  </mc-column>

  <mc-column key="userAwareness">
    <mc-body-cell *mcBodyCellDef="let row">
      <i class="text-success" [ngClass]="displayCheckIcon(row.bypass, row.userAwareness)"></i>
      {{displayLabelValue(row)}}
    </mc-body-cell>
  </mc-column>

  <mc-column key="inbound">
    <mc-body-cell *mcBodyCellDef="let row">
      <i class="text-success" [ngClass]="displayCheckIcon(row.bypass, row.inbound)"></i>
      {{displayLabelValue(row)}}
    </mc-body-cell>
  </mc-column>

  <mc-column key="outbound">
    <mc-body-cell *mcBodyCellDef="let row">
      <i class="text-success" [ngClass]="displayCheckIcon(row.bypass, row.outbound)"></i>
      {{displayLabelValue(row)}}
    </mc-body-cell>
  </mc-column>


  <mc-column key="journal">
    <mc-body-cell *mcBodyCellDef="let row">
      <i class="text-success" [ngClass]="displayCheckIcon(row.bypass, row.journal)"></i>
      {{displayLabelValue(row)}}
    </mc-body-cell>
  </mc-column>


  <mc-column key="rules">
    <mc-body-cell *mcBodyCellDef="let row">
      {{row.rules}}
    </mc-body-cell>
  </mc-column>

  <mc-column-actions key="right-column" mcShowColumnConfig [columnsAlwaysVisible]="columnsAlwaysVisible">
    <mc-row-actions *mcRowActions="let row">
      <ng-container *ngIf="canModifyPolicy; else details">
        <li data-test="cy-edit-meatball-btn" mcRowAction="$I18N_SERVICES_URL_PROTECTION_POLICIES_TABLE.ACTIONS.EDIT"
          data-unit-test="unit-edit-action-btn" (click)="editPolicy(row)">
        </li>
        <li data-test="cy-duplicate-meatball-btn"
          mcRowAction="$I18N_SERVICES_URL_PROTECTION_POLICIES_TABLE.ACTIONS.DUPLICATE"
          data-unit-test="unit-duplicate-action-btn" (click)="duplicatePolicy(row)">
        </li>
        <li data-test="cy-delete-meatball-btn"
          mcRowAction="$I18N_SERVICES_URL_PROTECTION_POLICIES_TABLE.ACTIONS.DELETE"
          data-unit-test="unit-delete-action-btn" (click)="deletePolicy(row)">
        </li>
      </ng-container>
      <ng-template #details>
        <li mcRowAction="$I18N_SERVICES_URL_PROTECTION_POLICIES_TABLE.ACTIONS.DETAILS"
          data-test="cy-details-meatball-btn" data-unit-test="unit-details-action-btn" (click)="clickRow(row)">
        </li>
      </ng-template>
    </mc-row-actions>
  </mc-column-actions>
</mc-table>
