"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MepLogSidebarDetailsComponent = void 0;
const rxjs_1 = require("rxjs");
class MepLogSidebarDetailsComponent {
    constructor() {
        this.showMore = new rxjs_1.BehaviorSubject(false);
    }
    set item(value) {
        if (value) {
            this.mapLogDetails(value);
        }
    }
    mapLogDetails(logDetails) {
        const { id, fromAddress, toAddresses, internalAddresses, subject, status, messageId, createTime } = logDetails;
        const sender = fromAddress;
        const uniqueRecipientSet = new Set([...toAddresses, ...internalAddresses]);
        const recipients = Array.from(uniqueRecipientSet);
        this.showMore = new rxjs_1.BehaviorSubject(false);
        this.currentItem = {
            id,
            status,
            subject,
            messageId,
            sender,
            recipients,
            lastModified: createTime
        };
    }
}
exports.MepLogSidebarDetailsComponent = MepLogSidebarDetailsComponent;
