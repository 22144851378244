"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AaaDirectorySyncTableComponent = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const tableConfig = require("./aaa-directory-sync-table.static-values");
const reducers = require("../../reducers");
const actions = require("../../actions");
const common_1 = require("../../common/common");
const notification_actions_1 = require("../../../components/notification/actions/notification.actions");
class AaaDirectorySyncTableComponent {
    constructor(store, translate, coreService, translateService) {
        this.store = store;
        this.translate = translate;
        this.coreService = coreService;
        this.translateService = translateService;
        this.columns = tableConfig.columns;
        this.columnsAlwaysVisible = tableConfig.columnsAlwaysVisible;
        this.columnsStartHidden = tableConfig.columnsStartHidden;
        this.filterSections = [];
        this.pageSize = 50;
        this.delay = 200;
        this.searchBy = {};
        this.filters = [];
        this.pageSizeSelected = 50;
        this.groupAccountType = 'enterprise_group';
        this.filtersUpdated = false;
        this.isSyncRunning = new rxjs_1.BehaviorSubject(undefined);
        this.isSyncRunning$ = this.isSyncRunning.asObservable();
        this.isReportRunning = false;
        this.isSyncReportAvailable = false;
        this.lastOutcome = {
            ok: Object.assign(Object.assign({}, common_1.lastOutcome.ok), { classes: 'mc-integration-last-outcome--success' }),
            error: Object.assign(Object.assign({}, common_1.lastOutcome.error), { classes: 'mc-integration-last-outcome--fail' }),
            not_active: Object.assign(Object.assign({}, common_1.lastOutcome.not_active), { classes: 'mc-integration-last-outcome--not-active' })
        };
        this.serverTypeIcons = Object.assign({}, common_1.serverTypeIcons);
        this.searchByFields = tableConfig.searchByFields;
        this.maxLengthForSearchField = tableConfig.maxLengthForSearchField;
        this.minLengthForSearchField = tableConfig.minLengthForSearchField;
    }
    ngOnInit() {
        this.integrations$ = this.store.select(reducers.getDirSyncIntegrations);
        this.isLoading$ = this.store.select(reducers.isIntegrationsLoading);
        this.selectedRow$ = this.store.select(reducers.getIntegrationsSelectedRow);
        this.totalCount$ = this.store.select(reducers.getIntegrationsTotalCount);
        this.pagingMetadata$ = this.store.select(reducers.getIntegrationsPagingMetadata);
        this.pageSizeSubscription = this.pagingMetadata$.subscribe(meta => {
            this.pageSize = meta.pagination.pageSize;
        });
        this.syncRunningSubscription = this.store
            .select(reducers.isSyncRunning)
            .subscribe(isRunning => {
            this.isSyncRunning.next(isRunning);
        });
        this.getAAAGroupsSubscription = this.store.select(reducers.getAAAGroups).subscribe(groups => {
            this.filterByGroup(groups);
        });
        this.coreService
            .getAccount()
            .pipe(operators_1.first())
            .subscribe(account => {
            this.isAAAGroupAccount = account.type === this.groupAccountType;
        });
        this.syncReportSubscription = this.store
            .select(reducers.isSyncReportLoading)
            .subscribe(isReportRunning => {
            this.isReportRunning = isReportRunning;
        });
        this.syncReportAvailableSubscription = this.store
            .select(reducers.isSyncReportAvailable)
            .subscribe(isReportAvailable => {
            this.isSyncReportAvailable = isReportAvailable;
        });
    }
    getServerTypeKey(app) {
        return common_1.getServerTypeKey(app);
    }
    changePage(page) {
        this.pageSizeSelected = page.pageSize;
        this.searchAndFilter(page);
    }
    viewConnection(app) {
        this.store.dispatch(new actions.OpenAsideAction({
            integration: app,
            pageSize: this.pageSize
        }));
    }
    startConnectionSynchronization() {
        this.store.dispatch(new actions.SyncAllIntegrationsAction());
    }
    getSyncButtonLabel() {
        return this.isSyncRunning.getValue()
            ? '$I18N_DIRECTORY_SYNC_TABLE.ACTIONS.SYNCHRONIZING'
            : '$I18N_DIRECTORY_SYNC_TABLE.ACTIONS.SYNCHRONIZE';
    }
    changeColumnFilter(filters) {
        const filterBy = [];
        const { enabled } = filters;
        if (Object.keys(filters).length > 0) {
            filterBy.push(...common_1.mapFilters('lastSyncOutcome', filters.lastSyncOutcomes));
            filterBy.push(...common_1.mapFilters('type', filters.types));
            filterBy.push(...common_1.mapFilters('group', filters.group));
        }
        // Send "enabled" filter when only one option is selected
        if (enabled && enabled.length === 1) {
            filterBy.push({ fieldName: 'enabled', value: enabled[0] === 'enabled' });
        }
        this.filters = filterBy;
        this.searchAndFilter();
    }
    displayToastWhenNoGroupsAvailable() {
        if (this.failToRetrieveAAAGroups) {
            this.store.dispatch(new notification_actions_1.NotificationShowAction({
                type: 'error',
                config: {
                    msg: this.translateService.instant('$I18N_AAA_DIRECTORY_SYNC_TABLE.GET_AAA_GROUPS_FAILURE')
                }
            }));
        }
    }
    searchAndFilter(page) {
        const filtersPayload = {
            search: {
                filterBy: this.filters
            }
        };
        if (Object.keys(this.searchBy).length > 0) {
            filtersPayload.search.searchBy = Object.assign({}, this.searchBy);
        }
        if (!page) {
            page = {
                pageSize: this.pageSizeSelected
            };
        }
        this.store.dispatch(new actions.SaveSearchFiltersAction(filtersPayload, page));
        this.store.dispatch(new actions.GetAllDirectorySyncIntegrationsAction(page, filtersPayload));
    }
    updateFilters() {
        this.filterSections = tableConfig.tableSectionFilters.map(section => {
            return Object.assign(Object.assign({}, section), { displayName: this.translate.instant(section.displayName), filters: section.filters.map(filter => {
                    return Object.assign(Object.assign({}, filter), { displayName: this.translate.instant(filter.displayName) });
                }) });
        });
    }
    filterByGroup(groups) {
        if (groups[0] && groups[0].failToRetrieveGroups) {
            this.failToRetrieveAAAGroups = true;
            tableConfig.tableSectionFilters.shift();
            this.updateFilters();
        }
        if (groups.length && !groups[0].failToRetrieveGroups && !this.filtersUpdated) {
            groups.forEach(group => tableConfig.tableSectionFilters[0].filters.push({
                name: group,
                displayName: group
            }));
            this.updateFilters();
            this.filtersUpdated = true;
        }
    }
    search(query) {
        this.searchBy = {};
        if (!!query.searchBy) {
            this.searchBy = Object.assign({}, query.searchBy);
        }
        this.searchAndFilter();
    }
    getLastSynchronizationReport() {
        this.store.dispatch(new actions.SynchronizationReportAction(true));
    }
    ngOnDestroy() {
        this.syncRunningSubscription.unsubscribe();
        this.pageSizeSubscription.unsubscribe();
        this.getAAAGroupsSubscription.unsubscribe();
        this.syncReportSubscription.unsubscribe();
        this.syncReportAvailableSubscription.unsubscribe();
    }
}
exports.AaaDirectorySyncTableComponent = AaaDirectorySyncTableComponent;
