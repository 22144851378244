"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sourceTranslateLookup = exports.minLengthForSearchField = exports.maxLengthForSearchField = exports.columnsStartHidden = exports.columnsAlwaysVisible = exports.searchByFields = exports.sections = exports.columns = void 0;
exports.columns = [
    'name',
    'description',
    'source',
    'createdBy',
    'created',
    'lastExecutionStart',
    'state',
    'taskType',
    'status',
    'right-column'
];
exports.sections = [
    {
        name: 'state',
        filters: ['unknown', 'success', 'warning', 'fail']
    },
    {
        name: 'source',
        filters: ['ms_teams']
    },
    {
        name: 'task_type',
        filters: ['active', 'legacy_ingestion']
    },
    {
        name: 'task_status',
        filters: ['enabled', 'disabled']
    }
];
const searchByPrefix = '$I18N_SERVICES_IM_SYNC_COMPONENTS_IM_SYNC_TABLE.FILTERS.SEARCH_BY_FILTER_FIELD';
exports.searchByFields = [
    {
        label: `${searchByPrefix}.ALL`,
        value: 'all'
    },
    {
        label: `${searchByPrefix}.NAME`,
        value: 'name'
    },
    {
        label: `${searchByPrefix}.DESCRIPTION`,
        value: 'description'
    },
    {
        label: `${searchByPrefix}.CREATED_BY`,
        value: 'createdBy'
    }
];
exports.columnsAlwaysVisible = ['name'];
exports.columnsStartHidden = ['taskType', 'status', 'description'];
exports.maxLengthForSearchField = 256;
exports.minLengthForSearchField = 3;
exports.sourceTranslateLookup = {
    ms_teams: 'ms_teams',
    skype_for_business: 'skype_for_business'
};
