
    <mc-modal-header
      headerTitle="{{ '$I18N_REMEDIATION_SETTINGS_TAB.LABELS.SAVE_MODAL_CONTENT' | translate }}"
    ></mc-modal-header>
    <mc-modal-footer>
      <button type="button" class="btn btn-secondary" (click)="onClose()">
        {{ '$I18N_REMEDIATION_SETTINGS_TAB.LABELS.CANCEL_BUTTON' | translate }}
      </button>
      <mc-live-button
        overrideClasses="btn-primary"
        [label]="'$I18N_REMEDIATION_SETTINGS_TAB.LABELS.SAVE_BUTTON' | translate"
        [isLoading]="isUpdatingSettings$ | async"
        (mcClick)="onClose(true)"
      >
      </mc-live-button>
    </mc-modal-footer>
  