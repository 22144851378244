"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageDeliveryTabComponent = void 0;
const actions = require("../../actions/message-delivery.action");
const reducers = require("../../reducers");
class MessageDeliveryTabComponent {
    constructor(store) {
        this.store = store;
    }
    ngOnInit() {
        this.items$ = this.store.select(reducers.getMessageDeliveryItems);
        this.totalCount$ = this.store.select(reducers.getMessageDeliveryTotalCount);
        this.selectedRow$ = this.store.select(reducers.getMessageDeliverySelectedRow);
        this.isLoading$ = this.store.select(reducers.isDeliveryMessagesLoading);
        this.metadata$ = this.store.select(reducers.getMessageDeliveryMetadata);
        this.store.dispatch(new actions.MessageDeliveryRequestAction({ search: {} }));
    }
    search(search) {
        this.store.dispatch(new actions.MessageDeliveryRequestAction({ search }));
    }
    dateFilterChanged(range) {
        this.store.dispatch(new actions.MessageDeliveryRequestAction({ search: Object.assign({}, range.range) }));
    }
    columnFilterChanged(filter) {
        this.store.dispatch(new actions.MessageDeliveryRequestAction({ search: filter }));
    }
    paginatorChanged(page) {
        this.store.dispatch(new actions.MessageDeliveryPaginationAction(page));
    }
    rowClicked(message) {
        this.store.dispatch(new actions.MessageDeliverySelectRowAction(message));
    }
    messagesRejected(messages) {
        if (messages.length > 0) {
            this.store.dispatch(new actions.MessageDeliveryRejectEmailsAction(messages));
        }
    }
    messagesRetried(messages) {
        if (messages.length > 0) {
            this.store.dispatch(new actions.MessageDeliveryRetryEmailsAction(messages));
        }
    }
    routeRecalculated(messages) {
        if (messages.length > 0) {
            this.store.dispatch(new actions.MessageRecalculateRouteAction(messages));
        }
    }
    messagesEarlyBounced(messages) {
        if (messages.length > 0) {
            this.store.dispatch(new actions.MessageDeliveryEarlyBounceEmailsAction(messages));
        }
    }
    exportResults(tableColumns) {
        this.store.dispatch(new actions.MessageDeliveryExportAction(tableColumns));
    }
}
exports.MessageDeliveryTabComponent = MessageDeliveryTabComponent;
