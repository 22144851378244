"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SetRuleStateConfirmationComponent = void 0;
class SetRuleStateConfirmationComponent {
    constructor(overlayRef, enable) {
        this.overlayRef = overlayRef;
        this.titleKey = enable
            ? '$I18N_ARCHIVE_SUPERVISION_SET_RULE_STATE.ACTIVATION.TITLE'
            : '$I18N_ARCHIVE_SUPERVISION_SET_RULE_STATE.DEACTIVATION.TITLE';
        this.messageKey = enable
            ? '$I18N_ARCHIVE_SUPERVISION_SET_RULE_STATE.ACTIVATION.MESSAGE'
            : '$I18N_ARCHIVE_SUPERVISION_SET_RULE_STATE.DEACTIVATION.MESSAGE';
        this.actionKey = enable
            ? '$I18N_ARCHIVE_SUPERVISION_SET_RULE_STATE.ACTIVATION.ACTION'
            : '$I18N_ARCHIVE_SUPERVISION_SET_RULE_STATE.DEACTIVATION.ACTION';
    }
    close(confirmed) {
        this.overlayRef.close(confirmed);
    }
}
exports.SetRuleStateConfirmationComponent = SetRuleStateConfirmationComponent;
