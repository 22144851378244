"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MepSidebarDetailsComponent = void 0;
const list_static_values_1 = require("../../list/list.static-values");
class MepSidebarDetailsComponent {
    constructor() {
        this.noAction = list_static_values_1.actions.noAction;
    }
}
exports.MepSidebarDetailsComponent = MepSidebarDetailsComponent;
