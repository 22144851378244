"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./services-controller"); //navbar.services.controller
require("app/components/mega-menu/mega-menu"); //mega.menu
// html and css 
require("./services-menu.tpl.html");
require("./services.tpl.html");
/**
 * Created by cfeudo on 12/11/2015.
 */
angular.module('navbar.services', ['navbar.services.controller', 'mega.menu']);
