"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfirmationModalComponent = exports.CONFIRMATION_DIALOG_TYPES = void 0;
var CONFIRMATION_DIALOG_TYPES;
(function (CONFIRMATION_DIALOG_TYPES) {
    CONFIRMATION_DIALOG_TYPES["DELETE"] = "DELETE";
    CONFIRMATION_DIALOG_TYPES["OVERRIDE"] = "OVERRIDE";
})(CONFIRMATION_DIALOG_TYPES = exports.CONFIRMATION_DIALOG_TYPES || (exports.CONFIRMATION_DIALOG_TYPES = {}));
class ConfirmationModalComponent {
    constructor(overlayRef, data, translate) {
        this.overlayRef = overlayRef;
        this.translate = translate;
        this.data = data;
    }
    ngOnInit() {
        // set title and button text
        this.setModalTexts();
    }
    setModalTexts() {
        this.title = this.translate.instant('$I18N_SERVICES_CYBERGRAPH.DELETE_POLICY_DELETE_CONFIRMATION_TITLE');
        this.buttonText = this.translate.instant('$I18N_SERVICES_CYBERGRAPH.DELETE_POLICY_DELETE_CONFIRMATION_ACTION_BTN');
    }
    onDelete() {
        this.overlayRef.close(true);
    }
    onCancel() {
        this.overlayRef.close(false);
    }
}
exports.ConfirmationModalComponent = ConfirmationModalComponent;
