
    <mc-modal-header
      headerTitle="{{ '$I18N_REMEDIATION_SETTINGS_TAB.LABELS.CANCEL_MODAL_CONTENT' | translate }}"
    >
    </mc-modal-header>
    <mc-modal-footer>
      <button type="button" class="btn btn-secondary" (click)="onClose('no')">
        {{ '$I18N_REMEDIATION_SETTINGS_TAB.LABELS.NO_BUTTON' | translate }}
      </button>
      <button type="button" class="btn btn-primary" (click)="onClose('yes')">
        {{ '$I18N_REMEDIATION_SETTINGS_TAB.LABELS.YES_BUTTON' | translate }}
      </button>
    </mc-modal-footer>
  