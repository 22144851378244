<div class="mc-ap-policy-details-block">
  <mc-wizard-step-header class="mc-ap-wizard-step-header"
                         label="$I18N_AP_CREATE_POLICY_STEPS.TITLE">
  </mc-wizard-step-header>
  <form class="form-horizontal"
        mcDefaultLabelClass="col-sm-3"
        mcDefaultControlContainerClass="col-sm-9"
        [formGroup]="apPolicyDetailsForm">

    <mc-text-field label="$I18N_AP_CREATE_POLICY_STEPS.POLICY_DETAILS_STEP_LABEL.POLICY_NAME"
                   errorPrefix="$I18N_AP_CREATE_POLICY_STEPS.VALIDATION.POLICY_NAME"
                   formControlName="description"
                   [setFocus]="true"
                   required>
    </mc-text-field>
    <mc-date-range-picker label="$I18N_AP_CREATE_POLICY_STEPS.POLICY_DETAILS_STEP_LABEL.DURATION.LABEL"
        formControlName="apDuration" [config]="durationOptions" [useCurrentMoment]="true">
    </mc-date-range-picker>
  </form>
</div>

