"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getToggleFields = exports.IsToggle = void 0;
require("reflect-metadata");
const IS_TOGGLE_METADATA_KEY = Symbol('MC-TOGGLE-FIELD');
function IsToggle() {
    return (target, propertyKey) => {
        let isToggleFields = Reflect.getMetadata(IS_TOGGLE_METADATA_KEY, target);
        if (isToggleFields) {
            isToggleFields.push(propertyKey);
        }
        else {
            isToggleFields = [propertyKey];
        }
        Reflect.defineMetadata(IS_TOGGLE_METADATA_KEY, isToggleFields, target);
    };
}
exports.IsToggle = IsToggle;
function getToggleFields(origin) {
    const properties = Reflect.getMetadata(IS_TOGGLE_METADATA_KEY, origin);
    const result = {};
    if (properties) {
        properties.forEach(key => (result[key] = true));
    }
    return result;
}
exports.getToggleFields = getToggleFields;
