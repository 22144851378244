"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormValueDirective = void 0;
class FormValueDirective {
    constructor(formGroupDirective, formControlDirective) {
        this.control = formGroupDirective || formControlDirective;
    }
    set mcFormValue(val) {
        if (val && this.control) {
            this.control.form.patchValue(val);
        }
    }
}
exports.FormValueDirective = FormValueDirective;
