'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const moment = require("moment");
const _ = require("lodash");
angular.module('adcon-commons.service', [])
    .factory('adconCommonsService', ['$filter', '$translate', function ($filter, $translate) {
        const FILTER_SEARCH_LIMIT = {
            MIN: {
                VALUE: 3,
                KEY: '$I18N_ADMINISTRATION_COMMONS_FILTER_SEARCH_LIMIT_MIN_TEXT',
                TEXT: ''
            },
            MAX: {
                VALUE: 1250,
                KEY: '$I18N_ADMINISTRATION_COMMONS_FILTER_SEARCH_LIMIT_MAX_TEXT',
                TEXT: ''
            }
        };
        const dateToNotAvailable = '1970-01-01T00:00:00+0000';
        const hoursInDay = [];
        const minutesInHour = [];
        const ACTIVE = 'active';
        activate();
        return {
            formatMcUtcDate,
            humanizeActionFilters,
            getDropdownOptions,
            getHoursInDay,
            getMinutesInHour,
            timeFromNow,
            setDropdownActiveItem,
            CONSTANTS: {
                API_DATE_FORMAT: 'YYYY-MM-DDTHH:mm:ssZZ',
                UI_DATE_FORMAT: 'DD MMM YYYY - HH:mm',
                UI_ALTERNATE_DATE_FORMAT: 'DD MMM YYYY',
                ACTIVE,
                ALL: 'ALL',
                DROPDOWN_ITEM_TYPES: {
                    LABEL: 'label',
                    ITEM: 'item',
                    SEPARATOR: 'separator'
                },
                DATE_RANGES_KEYS: {
                    TODAY: 'TODAY',
                    YESTERDAY: 'YESTERDAY',
                    LAST_WEEK: 'LAST_WEEK',
                    LAST_MONTH: 'LAST_MONTH',
                    BETWEEN: 'BETWEEN'
                },
                PAGE_SIZES_KEYS: {
                    I50: 'I50',
                    I100: 'I100',
                    I300: 'I300',
                    I500: 'I500'
                },
                FILTER_SEARCH_LIMIT
            }
        };
        function getDropdownOptions() {
            return {
                dateRanges: {
                    TODAY: {
                        type: this.CONSTANTS.DROPDOWN_ITEM_TYPES.ITEM,
                        active: true,
                        label: '$I18N_ADMINISTRATION_COMMONS_DATE_RANGE_OPTIONS_TODAY',
                        dates: {
                            from: moment().startOf('day'),
                            to: moment().endOf('day')
                        }
                    },
                    YESTERDAY: {
                        type: this.CONSTANTS.DROPDOWN_ITEM_TYPES.ITEM,
                        label: '$I18N_ADMINISTRATION_COMMONS_DATE_RANGE_OPTIONS_YESTERDAY',
                        dates: {
                            from: moment().subtract(1, 'days').startOf('day'),
                            to: moment().subtract(1, 'days').endOf('day')
                        }
                    },
                    LAST_WEEK: {
                        type: this.CONSTANTS.DROPDOWN_ITEM_TYPES.ITEM,
                        label: '$I18N_ADMINISTRATION_COMMONS_DATE_RANGE_OPTIONS_ONE_WEEK',
                        dates: {
                            from: moment().subtract(6, 'days').startOf('day'),
                            to: moment().endOf('day')
                        }
                    },
                    LAST_MONTH: {
                        type: this.CONSTANTS.DROPDOWN_ITEM_TYPES.ITEM,
                        label: '$I18N_ADMINISTRATION_COMMONS_DATE_RANGE_OPTIONS_ONE_MONTH',
                        dates: {
                            from: moment().subtract(30, 'days').startOf('day'),
                            to: moment().endOf('day')
                        }
                    },
                    BETWEEN: {
                        type: this.CONSTANTS.DROPDOWN_ITEM_TYPES.ITEM,
                        label: '$I18N_ADMINISTRATION_COMMONS_DATE_RANGE_OPTIONS_BETWEEN_DATES',
                        custom: true
                    }
                },
                pageSizeOptions: {
                    I50: {
                        type: this.CONSTANTS.DROPDOWN_ITEM_TYPES.ITEM,
                        key: 50,
                        value: $translate.instant('$I18N_ADMINISTRATION_COMMONS_PAGE_SIZE_OPTIONS_I50'),
                        active: true
                    },
                    I100: {
                        type: this.CONSTANTS.DROPDOWN_ITEM_TYPES.ITEM,
                        key: 100,
                        value: $translate.instant('$I18N_ADMINISTRATION_COMMONS_PAGE_SIZE_OPTIONS_I100')
                    },
                    I300: {
                        type: this.CONSTANTS.DROPDOWN_ITEM_TYPES.ITEM,
                        key: 300,
                        value: $translate.instant('$I18N_ADMINISTRATION_COMMONS_PAGE_SIZE_OPTIONS_I300')
                    },
                    I500: {
                        type: this.CONSTANTS.DROPDOWN_ITEM_TYPES.ITEM,
                        key: 500,
                        value: $translate.instant('$I18N_ADMINISTRATION_COMMONS_PAGE_SIZE_OPTIONS_I500')
                    }
                }
            };
        }
        function setDropdownActiveItem(content, key) {
            let activeContent;
            do {
                activeContent = _.findKey(content, ACTIVE);
                if (activeContent) {
                    content[activeContent].active = false;
                }
            } while (activeContent);
            content[key].active = true;
        }
        function formatMcUtcDate(date, format) {
            return date && date !== dateToNotAvailable ? (moment(date)).format(format ? format : this.CONSTANTS.API_DATE_FORMAT) : $translate.instant('$I18N_ADMINISTRATION_COMMONS_DATE_FILTER_EMPTY');
        }
        function humanizeActionFilters(logs, filters, key) {
            let actionFilter;
            _.each(logs, function (log) {
                actionFilter = _.find(filters, function (filter) {
                    return filter.key === log[key];
                });
                log.humanizedFilter = log.humanizedFilter ? (log.humanizedFilter !== {} ? log.humanizedFilter : {}) : {};
                log.humanizedFilter[key] = actionFilter ? actionFilter.value : '';
            });
            return logs;
        }
        function getHoursInDay() {
            return hoursInDay;
        }
        function getMinutesInHour() {
            return minutesInHour;
        }
        function setHoursInDay() {
            let i;
            for (i = 0; i < 24; i += 1) {
                hoursInDay.push((i < 10) ? ('0' + i.toString()) : i.toString());
            }
        }
        function setMinutesInHour() {
            let i;
            for (i = 0; i < 60; i += 1) {
                minutesInHour.push((i < 10) ? ('0' + i.toString()) : i.toString());
            }
        }
        function timeFromNow(date) {
            return moment(date).fromNow();
        }
        function setFilterSearchLimitTranslation() {
            $translate.onReady(() => {
                FILTER_SEARCH_LIMIT.MIN.TEXT = $translate.instant(FILTER_SEARCH_LIMIT.MIN.KEY, { number: FILTER_SEARCH_LIMIT.MIN.VALUE });
                FILTER_SEARCH_LIMIT.MAX.TEXT = $translate.instant(FILTER_SEARCH_LIMIT.MAX.KEY, { number: FILTER_SEARCH_LIMIT.MAX.VALUE });
            });
        }
        function activate() {
            setHoursInDay();
            setMinutesInHour();
            setFilterSearchLimitTranslation();
        }
    }
]);
