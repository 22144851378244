"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfirmationModalComponent = void 0;
class ConfirmationModalComponent {
    constructor(overlayRef) {
        this.overlayRef = overlayRef;
        this.isLoading = false;
    }
    confirm() {
        this.overlayRef.sendResult(true);
        this.isLoading = true;
    }
    cancel() {
        this.overlayRef.sendResult(false);
        this.overlayRef.close();
    }
}
exports.ConfirmationModalComponent = ConfirmationModalComponent;
