"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueueDetailsComponent = void 0;
const forms_1 = require("@angular/forms");
const queue_actions_1 = require("app-new/archive/supervision/actions/queue.actions");
const queue_service_1 = require("../../../services/queue.service");
const queue_type_enum_1 = require("app-new/archive/supervision/types/queue-type.enum");
const step_base_component_1 = require("../../../step.base.component");
const wizard_actions_1 = require("app-new/archive/supervision/actions/wizard.actions");
const validators_1 = require("app-new/api-applications/validators");
const stepIdNumber = 1;
class QueueDetailsComponent extends step_base_component_1.StepBaseDirective {
    constructor(store, fb, translateService) {
        super(store, fb, stepIdNumber, queue_service_1.QueueService.WizardId);
        this.store = store;
        this.fb = fb;
        this.translateService = translateService;
        this.escalationQueues = [];
        this.selectedEscalationQueues = [];
        this.loading = false;
        this.QueueType = queue_type_enum_1.QueueType;
        this.defaultEscalationQueueOptions = [];
        this.form = this.fb.group({
            name: [
                '',
                [
                    forms_1.Validators.required,
                    forms_1.Validators.minLength(3),
                    forms_1.Validators.maxLength(60),
                    validators_1.validateNotEmpty,
                    forms_1.Validators.pattern(/^[^\s]+(\s+[^\s]+)*$/)
                ]
            ],
            description: [
                '',
                [
                    forms_1.Validators.required,
                    forms_1.Validators.minLength(3),
                    forms_1.Validators.maxLength(1024),
                    validators_1.validateNotEmpty,
                    forms_1.Validators.pattern(/^[^\s]+(\s+[^\s]+)*$/)
                ]
            ],
            qtype: [this.type || queue_type_enum_1.QueueType.REVIEW],
            queues: [],
            defaultEscalationQueue: ''
        });
    }
    ngOnInit() {
        super.ngOnInit();
        this.form.patchValue({ name: '', description: '' });
    }
    ngOnChanges(changes) {
        if (changes.escalationQueues && changes.escalationQueues.currentValue) {
            this.escalationQueues = this.escalationQueues
                .slice()
                .sort((a, b) => a.name.localeCompare(b.name));
            this.form.setControl('queues', this.getQueueList());
            this.defaultEscalationQueueOptions = [
                this.noDefaultQueueOption(),
                ...this.getDefaultEscalationQueueOptions()
            ];
        }
    }
    ngAfterViewInit() {
        if (this.isEditMode) {
            this.form.patchValue({
                name: this.name,
                qtype: this.type || queue_type_enum_1.QueueType.REVIEW,
                description: this.description,
                escalationQueues: this.getSelectedQueues(),
                defaultEscalationQueue: this.defaultEscalationQueue
            });
        }
    }
    ngOnDestroy() {
        super.ngOnDestroy();
        this.store.dispatch(new queue_actions_1.ClearQueueWizardDataAction());
    }
    get queues() {
        return this.form.get('queues');
    }
    byLabel(itemOne, itemTwo) {
        return itemOne.label.localeCompare(itemTwo.label);
    }
    getQueueList() {
        const group = {};
        if (this.escalationQueues) {
            this.escalationQueues.forEach(queue => {
                group[queue.id] = [this.isEscalationQueueSelected(queue.id)];
            });
        }
        return this.fb.group(group);
    }
    isEscalationQueueSelected(id) {
        return (this.selectedEscalationQueues &&
            this.selectedEscalationQueues.findIndex((selectedQueue) => selectedQueue.id === id) > -1);
    }
    getSelectedQueues() {
        return this.queues && this.queues.value
            ? this.escalationQueues.filter(queue => this.queues.value[queue.id])
            : [];
    }
    getDefaultEscalationQueueOptions() {
        return this.selectedEscalationQueues
            ? this.selectedEscalationQueues
                .map(queue => {
                return { label: queue.name, value: queue.id };
            })
                .sort(this.byLabel)
            : [];
    }
    handleSelected(event, queueId) {
        if (event.target.checked === true) {
            this.addToDefaultEscalationQueueOptions(queueId);
        }
        else {
            this.removeFromDefaultEscalationQueueOptions(queueId);
            if (this.isDefaultEscalationQueue(queueId)) {
                this.clearDefaultEscalationQueueFormControl();
            }
        }
    }
    addToDefaultEscalationQueueOptions(queueId) {
        const selectedQueue = this.escalationQueues.find(queue => queue.id === queueId);
        const sortedOptions = this.filterNoDefaultAndAppendQueueOption(selectedQueue).sort(this.byLabel);
        this.defaultEscalationQueueOptions = [this.noDefaultQueueOption(), ...sortedOptions];
    }
    removeFromDefaultEscalationQueueOptions(queueId) {
        this.defaultEscalationQueueOptions = [
            ...this.defaultEscalationQueueOptions.filter(option => option.value !== queueId)
        ];
    }
    isDefaultEscalationQueue(queueId) {
        const formDefaultEscalationQueueId = this.form.get('defaultEscalationQueue').value;
        return formDefaultEscalationQueueId ? formDefaultEscalationQueueId === queueId : false;
    }
    filterNoDefaultAndAppendQueueOption(selectedQueue) {
        return [
            ...this.defaultEscalationQueueOptions.filter(option => option.value !== ''),
            { label: selectedQueue.name, value: selectedQueue.id }
        ];
    }
    clearDefaultEscalationQueueFormControl() {
        this.form.patchValue({
            defaultEscalationQueue: ''
        });
    }
    noDefaultQueueOption() {
        return { label: this.noDefaultQueueLabel(), value: '' };
    }
    noDefaultQueueLabel() {
        return this.translateService.instant('$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.DETAILS_STEP.NO_DEFAULT_QUEUE');
    }
    dispatchUpdateStepAction() {
        this.store.dispatch(new wizard_actions_1.UpdateStepAction(queue_service_1.QueueService.WizardId, 1, {
            valid: this.form.valid,
            data: Object.assign(Object.assign({}, this.form.value), { escalationQueues: this.getSelectedQueues() })
        }));
    }
}
exports.QueueDetailsComponent = QueueDetailsComponent;
