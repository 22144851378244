"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UrlProtectionPoliciesListEffects = exports.TABLE_ID = void 0;
const effects_1 = require("@ngrx/effects");
const operators_1 = require("rxjs/operators");
const listActions = require("../actions/policies-list.actions");
const confirmation_modal_component_1 = require("../components/confirmation-modal/confirmation-modal.component");
const policies_model_1 = require("../models/policies.model");
const rxjs_1 = require("rxjs");
const table_store_1 = require("@mimecast-ui/table-store");
exports.TABLE_ID = 'policiesListTable';
class UrlProtectionPoliciesListEffects {
    constructor(modalService, actions$, tableStore, policiesListImplService, notificationService, translateService) {
        this.modalService = modalService;
        this.actions$ = actions$;
        this.tableStore = tableStore;
        this.policiesListImplService = policiesListImplService;
        this.notificationService = notificationService;
        this.translateService = translateService;
        this.deletePolicyAttempt$ = this.actions$.pipe(effects_1.ofType(listActions.POLICY_DELETE_ATTEMPT), operators_1.switchMap((action) => {
            return this.modalService
                .open(confirmation_modal_component_1.ConfirmationModalComponent, {
                data: {
                    type: policies_model_1.CONFIRMATION_DIALOG_TYPES.DELETE,
                    policy: action.payload
                }
            })
                .afterClose()
                .pipe(operators_1.map((result) => {
                return result
                    ? new listActions.PolicyDeleteAction(action.payload)
                    : new listActions.PolicyDeleteCancelAction();
            }));
        }));
        this.deletePolicy$ = this.actions$.pipe(effects_1.ofType(listActions.POLICY_DELETE), operators_1.switchMap((action) => {
            return this.policiesListImplService.deletePolicy({ id: action.payload }).pipe(operators_1.map((payload) => {
                return payload
                    ? new listActions.PolicyDeleteSucessAction()
                    : new listActions.PolicyDeleteFailAction();
            }), operators_1.catchError(() => rxjs_1.of(new listActions.PolicyDeleteFailAction())));
        }));
        this.deletePolicySuccess$ = this.actions$.pipe(effects_1.ofType(listActions.POLICY_DELETE_SUCCESS), operators_1.withLatestFrom(this.tableStore.select(table_store_1.tableReducer.getCurrentFilters(exports.TABLE_ID)), this.tableStore.select(table_store_1.tableReducer.getCurrentPagination(exports.TABLE_ID))), operators_1.mergeMap(([, currentFilters, currentPagination]) => {
            return [
                this.notificationService.newNotification({
                    type: 'success',
                    config: {
                        msg: this.translateService.instant('$I18N_SERVICES_URL_PROTECTION.DELETE_POLICY')
                    }
                }),
                new table_store_1.LoadAction({
                    tableId: exports.TABLE_ID,
                    pagination: { pageToken: '', pageSize: currentPagination.pageSize, pageNumber: 0 },
                    query: currentFilters
                })
            ];
        }));
        this.deletePolicyFail$ = this.actions$.pipe(effects_1.ofType(listActions.POLICY_DELETE_FAIL), operators_1.switchMap(() => {
            return [
                this.notificationService.newNotification({
                    type: 'error',
                    config: {
                        msg: this.translateService.instant('$I18N_SERVICES_URL_PROTECTION.DELETE_POLICY_ERROR_MSG')
                    }
                })
            ];
        }));
    }
}
__decorate([
    effects_1.Effect()
], UrlProtectionPoliciesListEffects.prototype, "deletePolicyAttempt$", void 0);
__decorate([
    effects_1.Effect()
], UrlProtectionPoliciesListEffects.prototype, "deletePolicy$", void 0);
__decorate([
    effects_1.Effect()
], UrlProtectionPoliciesListEffects.prototype, "deletePolicySuccess$", void 0);
__decorate([
    effects_1.Effect()
], UrlProtectionPoliciesListEffects.prototype, "deletePolicyFail$", void 0);
exports.UrlProtectionPoliciesListEffects = UrlProtectionPoliciesListEffects;
