"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getConfigExtended = exports.getCapsOverride = exports.getMigratedPolicyInfo = exports.getCapabilities = exports.getAccount = exports.getFederatedAdminAccess = exports.getAdminAccess = exports.getAdminPreferences = exports.getPreferences = exports.getUserProfile = exports.reducer = exports.initialState = void 0;
const actions = require("../actions");
exports.initialState = {};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.user.PROFILE_LOAD_SUCCESS:
            return Object.assign(Object.assign({}, state), { user: action.payload });
        case actions.user.CAPABILITIES_LOAD_SUCCESS:
            return Object.assign(Object.assign({}, state), { capabilities: action.payload });
        case actions.user.CAPS_OVERRIDE_LOAD_SUCCESS:
            return Object.assign(Object.assign({}, state), { capsOverride: action.payload });
        case actions.user.CONFIG_EXTENDED_LOAD_SUCCESS:
            return Object.assign(Object.assign({}, state), { configExtended: action.payload });
        case actions.user.ACCOUNT_LOAD_SUCCESS:
            return Object.assign(Object.assign({}, state), { account: action.payload });
        case actions.user.PREFERENCES_LOAD_SUCCESS:
            return Object.assign(Object.assign({}, state), { preferences: action.payload });
        case actions.user.VERIFY_FEDERATED_ACCESS_LOAD_SUCCESS:
            return Object.assign(Object.assign({}, state), { federatedAdminAccess: action.payload });
        case actions.user.VERIFY_ADMIN_ACCESS_LOAD_SUCCESS:
            return Object.assign(Object.assign({}, state), { adminAccess: action.payload });
        case actions.user.ADMIN_PREFERENCES_LOAD_SUCCESS:
        case actions.user.PREFERENCES_SAVE_SUCCESS:
            return Object.assign(Object.assign({}, state), { adminPreferences: action.payload });
        case actions.user.MIGRATED_POLICY_DATA:
            return Object.assign(Object.assign({}, state), { migratedPolicy: action.payload });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getUserProfile = (state) => state.user;
exports.getUserProfile = getUserProfile;
const getPreferences = (state) => state.preferences;
exports.getPreferences = getPreferences;
const getAdminPreferences = (state) => state.adminPreferences;
exports.getAdminPreferences = getAdminPreferences;
const getAdminAccess = (state) => state.adminAccess;
exports.getAdminAccess = getAdminAccess;
const getFederatedAdminAccess = (state) => state.federatedAdminAccess;
exports.getFederatedAdminAccess = getFederatedAdminAccess;
const getAccount = (state) => state.account;
exports.getAccount = getAccount;
const getCapabilities = (state) => state.capabilities;
exports.getCapabilities = getCapabilities;
const getMigratedPolicyInfo = (state) => state.migratedPolicy;
exports.getMigratedPolicyInfo = getMigratedPolicyInfo;
const getCapsOverride = (state) => state.capsOverride;
exports.getCapsOverride = getCapsOverride;
const getConfigExtended = (state) => state.configExtended;
exports.getConfigExtended = getConfigExtended;
