"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SidePanelEffects = void 0;
const effects_1 = require("@ngrx/effects");
const components_1 = require("@mimecast-ui/components");
const operators_1 = require("rxjs/operators");
const sidePanelActions = require("../../actions/logs-side-panel.actions");
const logs_aside_component_1 = require("../../containers/aside/logs-aside.component");
const rxjs_1 = require("rxjs");
const report_url_modal_component_1 = require("../../../components/report-url-modal/report-url-modal.component");
const screenshot_zoom_modal_component_1 = require("../../../components/screenshot-zoom-modal/screenshot-zoom-modal.component");
const reportSubmitActions = require("../../../actions/report-submit-modal.action");
const actions = require("../../../actions/file-parse.actions");
class SidePanelEffects {
    constructor(actions$, checkUrlsService, notificationService, translateService, asideService, modalService) {
        this.actions$ = actions$;
        this.checkUrlsService = checkUrlsService;
        this.notificationService = notificationService;
        this.translateService = translateService;
        this.asideService = asideService;
        this.modalService = modalService;
        this.openSidePanel$ = this.actions$.pipe(effects_1.ofType(sidePanelActions.OPEN_LOGS_SIDE_PANEL), operators_1.filter(() => !this.sidePanelInstance), operators_1.switchMap(() => {
            this.sidePanelInstance = this.asideService.open(logs_aside_component_1.LogsAsideComponent, this.sidePanelConfig);
            return this.sidePanelInstance.beforeClose().pipe(operators_1.tap(() => (this.sidePanelInstance = undefined)), operators_1.map(() => new sidePanelActions.ClearSidePanelState()));
        }));
        this.zoomScreenshot$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(sidePanelActions.URL_PROTECTION_SCREENSHOT_OPEN), operators_1.tap((action) => {
                this.screenshotZoomModalInstance = this.modalService.open(screenshot_zoom_modal_component_1.ScreenshotZoomModalComponent, {
                    size: 'md',
                    hasBackdrop: true,
                    width: 900,
                    data: action.payload
                });
            }));
        }, { dispatch: false });
        this.showReportModal$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(sidePanelActions.URL_PROTECTION_REPORT_URL_OPEN), operators_1.tap((action) => {
                this.reportUrlModalInstance = this.modalService.open(report_url_modal_component_1.ReportUrlModalComponent, {
                    size: 'md',
                    hasBackdrop: true,
                    width: 900,
                    data: action.payload
                });
            }));
        }, { dispatch: false });
        this.parseFile$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(actions.URL_POLICY_PARSE_FILE), operators_1.tap(action => {
                return this.checkUrlsService.readFile(action['payload']);
            }));
        }, { dispatch: false });
        this.UrlSubmitReport$ = effects_1.createEffect(() => this.actions$.pipe(effects_1.ofType(reportSubmitActions.URL_SCAN_DETAILS_REPORT_SUBMIT), operators_1.switchMap((action) => {
            return this.checkUrlsService.submitUrlScanDetailReport(action['payload']).pipe(operators_1.map(response => {
                var _a;
                if (((_a = response === null || response === void 0 ? void 0 : response.fail) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                    this.UrlReportError(response);
                }
                this.reportUrlModalInstance.close();
                this.notificationService.success({
                    msg: this.translateService.instant('$SCAN_DETAILS_REPORT_MODAL.REPORT_SUCCESSFULLY_SUBMITTED')
                });
                return new reportSubmitActions.URLReportModalSubmitSuccess();
            }), operators_1.catchError(err => {
                return this.UrlReportError(err);
            }));
        })));
        this.UrlSubmitReportFail$ = effects_1.createEffect(() => this.actions$.pipe(effects_1.ofType(reportSubmitActions.URL_SCAN_DETAILS_REPORT_SUBMIT_RETRY_ENABLED), operators_1.map(() => new reportSubmitActions.URLReportModalSubmitButtonState())));
        this.closeSidePanel$ = this.actions$.pipe(effects_1.ofType(sidePanelActions.CLOSE_LOGS_SIDE_PANEL), operators_1.tap(() => {
            if (this.sidePanelInstance) {
                this.sidePanelInstance.close();
            }
        }));
    }
    get sidePanelConfig() {
        const sidePanelConfig = new components_1.AsideConfig();
        sidePanelConfig.position = 'right';
        sidePanelConfig.size = 'custom';
        sidePanelConfig.hasBackdrop = true;
        sidePanelConfig.minWidth = 620;
        sidePanelConfig.resizable = true;
        return sidePanelConfig;
    }
    reportSubmissionActionFail() {
        this.notificationService.error({
            msg: this.translateService.instant('$SCAN_DETAILS_REPORT_MODAL.REPORT_SUBMISSION_FAILED')
        });
        if (this.sidePanelInstance) {
            this.sidePanelInstance.close();
        }
        this.reportUrlModalInstance.close();
    }
    UrlReportError(err) {
        if (err.fail[0].errors[0].code !== 'err_url_report_temporary_fail') {
            this.reportSubmissionActionFail();
            return rxjs_1.of(new reportSubmitActions.URLReportModalSubmitFailure());
        }
        return rxjs_1.of(new reportSubmitActions.URLReportModalSubmitRetryEnabled(true));
    }
}
__decorate([
    effects_1.Effect()
], SidePanelEffects.prototype, "openSidePanel$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], SidePanelEffects.prototype, "closeSidePanel$", void 0);
exports.SidePanelEffects = SidePanelEffects;
