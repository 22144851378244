"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiIntegrationOktaDetailsStepComponent = void 0;
const core_1 = require("@angular/core");
const operators_1 = require("rxjs/operators");
class ApiIntegrationOktaDetailsStepComponent {
    constructor(fb) {
        this.fb = fb;
        this.onEditOktaIntegrationDetails = new core_1.EventEmitter();
        this.form = this.fb.group({
            name: '',
            description: ''
        });
    }
    ngOnInit() {
        if (!!this.name && !!this.description) {
            this.form.patchValue({
                name: this.name,
                description: this.description
            });
        }
        this.formSubscription = this.form.valueChanges.pipe(operators_1.debounceTime(200)).subscribe(() => {
            this.update();
        });
    }
    update() {
        this.onEditOktaIntegrationDetails.emit({
            name: this.form.value.name,
            description: this.form.value.description
        });
    }
    isValid() {
        return this.form.value.name !== '' && this.form.value.description !== '';
    }
}
exports.ApiIntegrationOktaDetailsStepComponent = ApiIntegrationOktaDetailsStepComponent;
