"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParamsAction = exports.Verdict = exports.ThreatEventStatus = exports.ThreatEventAction = exports.ThreatEventType = exports.ApplicationSanction = exports.ApplicationStatus = void 0;
var ApplicationStatus;
(function (ApplicationStatus) {
    ApplicationStatus["ALLOW"] = "allow";
    ApplicationStatus["BLOCK"] = "block";
    ApplicationStatus["NONE"] = "none";
})(ApplicationStatus = exports.ApplicationStatus || (exports.ApplicationStatus = {}));
var ApplicationSanction;
(function (ApplicationSanction) {
    ApplicationSanction["SANCTION"] = "sanctioned";
    ApplicationSanction["MONITOR"] = "monitored";
    ApplicationSanction["NONE"] = "none";
})(ApplicationSanction = exports.ApplicationSanction || (exports.ApplicationSanction = {}));
var ThreatEventType;
(function (ThreatEventType) {
    ThreatEventType["MALWARE"] = "MALWARE";
    ThreatEventType["PHISHING"] = "PHISHING";
})(ThreatEventType = exports.ThreatEventType || (exports.ThreatEventType = {}));
var ThreatEventAction;
(function (ThreatEventAction) {
    ThreatEventAction["EMAIL_OWNER"] = "EMAIL_OWNER";
    ThreatEventAction["EMAIL_ADMIN_GROUPS"] = "EMAIL_ADMIN_GROUPS";
    ThreatEventAction["TEAMS_NOTIFY_PARTICIPANTS"] = "TEAMS_NOTIFY_PARTICIPANTS";
    ThreatEventAction["TEAMS_NOTIFY_CHANNELS"] = "TEAMS_NOTIFY_CHANNELS";
    ThreatEventAction["QUARANTINED"] = "QUARANTINED";
    ThreatEventAction["MANUAL_QUARANTINE"] = "MANUAL_QUARANTINE";
    ThreatEventAction["ATTEMPT_TO_QUARANTINE"] = "ATTEMPT_TO_QUARANTINE";
    ThreatEventAction["RELEASED"] = "RELEASED";
    ThreatEventAction["NOTIFICATION_FILE"] = "NOTIFICATION_FILE";
    ThreatEventAction["SAFE_FILE"] = "SAFE_FILE";
    ThreatEventAction["FALSE_POSITIVE_REPORT"] = "FALSE_POSITIVE_REPORT";
})(ThreatEventAction = exports.ThreatEventAction || (exports.ThreatEventAction = {}));
var ThreatEventStatus;
(function (ThreatEventStatus) {
    ThreatEventStatus["LOG_AND_REPLY"] = "LOG_AND_REPLY";
})(ThreatEventStatus = exports.ThreatEventStatus || (exports.ThreatEventStatus = {}));
var Verdict;
(function (Verdict) {
    Verdict["INFECTED"] = "infected";
    Verdict["BLOCK"] = "infected";
    Verdict["CLEAN"] = "clean";
    Verdict["SUSPICIOUS"] = "suspicious";
    Verdict["ALLOW"] = "allow";
})(Verdict = exports.Verdict || (exports.Verdict = {}));
var ParamsAction;
(function (ParamsAction) {
    ParamsAction["FILTER"] = "filter";
    ParamsAction["SEARCH"] = "search";
    ParamsAction["NONE"] = "none";
})(ParamsAction = exports.ParamsAction || (exports.ParamsAction = {}));
