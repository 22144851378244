<div class="modal-header">
  <button type="button" class="close" data-test="headerCancelButton" (click)="cancel()"><i
    class="icon-cancel"></i>
  </button>
  <h4 class="no-margin">{{'$I18N_ADMIN_PREFERENCES_MODAL_TITLE' | translate}}</h4>
</div>

<div class="modal-body mc-remove-margin">
  <form [formGroup]="form" [mcFormValue]="adminPreferences$ | async"
        class="form-horizontal"
        mcDefaultLabelClass="col-sm-3" mcDefaultControlContainerClass="col-sm-9">
    <mc-field label="$I18N_ADMIN_PREFERENCES_EARLY_ACCESS"
              help="$I18N_ADMIN_PREFERENCES_EARLY_ACCESS_HELP">
      <mc-switch formControlName="earlyAccess"></mc-switch>
    </mc-field>
    <div class="form-group">
        <div class="col-sm-offset-3 col-sm-9">
          <p class="help-block text-warning">{{'$I18N_ADMIN_PREFERENCES_EARLY_ACCESS_WARNING' | translate}}</p>
        </div>
      </div>

  </form>
</div>

<div class="modal-footer">

  <button type="button" class="btn btn-primary" data-test="saveButton" (click)="save()">
    {{'$I18N_ADMINISTRATION_COMMONS_BTN_LABEL_APPLY' | translate}}
  </button>
  <button type="button" class="btn btn-secondary" data-test="footerCancelButton" (click)="cancel()">
    {{'$I18N_ADMINISTRATION_COMMONS_BTN_LABEL_CANCEL' | translate}}
  </button>

</div>
