<div class="overview-failed-view">
  <i
    aria-hidden="true"
    class="fal fa-list-alt"
  ></i>
  <h5>
    {{ '$I18N_SYNC_AND_RECOVER_OVERVIEW_MODAL.OVERVIEW_FAILED.MAIN_TEXT' | translate }}
  </h5>
  <p>
    {{ '$I18N_SYNC_AND_RECOVER_OVERVIEW_MODAL.OVERVIEW_FAILED.SUB_TEXT' | translate }}
  </p>
</div>
