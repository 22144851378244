"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ManagedSenderDetailsDisplayPipe = void 0;
class ManagedSenderDetailsDisplayPipe {
    constructor(translateService) {
        this.translateService = translateService;
    }
    transform(managedSenderInfo = '') {
        switch (managedSenderInfo.toLowerCase()) {
            case 'unknown':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_MANAGED_SENDER.UNKNOWN');
            case 'autowhite':
                return `<span class="positiveProperty">${this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_MANAGED_SENDER.AUTOWHITE')}</span>`;
            case 'white':
                return `<span class="positiveProperty">${this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_MANAGED_SENDER.WHITE')}</span>`;
            case 'black':
                return `<span class="negativeProperty">${this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_MANAGED_SENDER.BLACK')}</span>`;
            case 'ignored':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_MANAGED_SENDER.IGNORED');
            default:
                return '';
        }
    }
}
exports.ManagedSenderDetailsDisplayPipe = ManagedSenderDetailsDisplayPipe;
