"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApPolicyDetailsAsideComponent = void 0;
const policy_actions_1 = require("../../actions/policy.actions");
const reducers_1 = require("../../reducers");
const operators_1 = require("rxjs/operators");
const policy_list_model_1 = require("../../models/policy-list.model");
class ApPolicyDetailsAsideComponent {
    constructor(store, overlayRef) {
        this.store = store;
        this.overlayRef = overlayRef;
    }
    editDefinition(policyDetail) {
        this.store.dispatch(new policy_actions_1.ApCloseAppAsideAction());
        this.store.dispatch(new policy_actions_1.UpdatePolicyId(policyDetail.id));
        this.store.dispatch(new policy_actions_1.ApPolicyNavigateToPolicyWizard(policy_list_model_1.ApPolicyWizardFlowType.EDIT));
    }
    ngOnInit() {
        this.isLoading$ = this.store.select(reducers_1.getPolicyDetailsIsLoading);
        this.policy = this.store.select(reducers_1.getApPolicyDetails).subscribe(policyDetail => {
            this.policyDetail = policyDetail;
            if (policyDetail && policyDetail.conflict) {
                this.isPolicyDetailVisible = false;
            }
            else {
                this.isPolicyDetailVisible = true;
            }
        });
        this.store
            .select(reducers_1.getPolicyDetailsPolicyId)
            .pipe(operators_1.first(policyId => policyId && policyId.length > 0))
            .subscribe(policyId => {
            this.store.dispatch(new policy_actions_1.ApLoadPolicyDetailsAction({ id: policyId }));
        });
    }
    deleteDefinition(policy) {
        this.store.dispatch(new policy_actions_1.ApDeleteModalOpenAction(policy));
    }
    close() {
        this.overlayRef.close();
    }
    ngOnDestroy() {
        this.policy.unsubscribe();
    }
}
exports.ApPolicyDetailsAsideComponent = ApPolicyDetailsAsideComponent;
