"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SessionTimeoutComponent = void 0;
const core_1 = require("@angular/core");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const LOCAL_STORAGE_KEEP_ALIVE_KEY = 'mimecast-x-tab-keep-alive';
class SessionTimeoutComponent {
    constructor(timeLeftModalService) {
        this.timeLeftModalService = timeLeftModalService;
        this.ONE_MINUTE_IN_MILLISECONDS = 60 * 1000;
        this._isSessionExtended = false;
        this._duration = 60 * this.ONE_MINUTE_IN_MILLISECONDS;
        this.pingEvery = this.ONE_MINUTE_IN_MILLISECONDS;
        this.dialogTimeToLogout = this.ONE_MINUTE_IN_MILLISECONDS;
        this.logout = new core_1.EventEmitter();
        this.ping = new core_1.EventEmitter();
        this.destroyLogout = false;
        this.userAction$ = new rxjs_1.Observable();
        this.timeLeftDialogHidden = true;
        this.destroyStayLogin = false;
        this.stayLoginAction$ = new rxjs_1.Observable();
    }
    get duration() {
        return this._duration;
    }
    set duration(value) {
        this._duration = value;
        this.logoutSubscribe();
    }
    get isSessionExtended() {
        return this._isSessionExtended;
    }
    set isSessionExtended(value) {
        this._isSessionExtended = value;
        if (!!this.extendSession$ && !this.extendSession$.closed) {
            this.extendSession$.unsubscribe();
        }
        this.extendSession$ = this.extendSessionOnStayLogin$().subscribe();
    }
    ngOnInit() {
        this.userAction$ = this.initUserAction$();
        this.logout$ = this.initLogout$().subscribe();
        this.stayLoginAction$ = this.initStayLoginAction$();
        this.extendSession$ = this.extendSessionOnStayLogin$().subscribe();
        this.initPing$().subscribe();
    }
    initUserAction$() {
        return rxjs_1.merge(...['click', 'mousemove', 'keyup', 'mousedown', 'scroll'].map(event => rxjs_1.fromEvent(document, event).pipe(operators_1.tap(() => {
            // if user is doing something make storage events so the other mimecast tabs are aware.
            localStorage.setItem(LOCAL_STORAGE_KEEP_ALIVE_KEY, new Date().getTime().toString());
        }))), this.initStayLoginAction$(), rxjs_1.fromEvent(window, 'load'));
    }
    initStayLoginAction$() {
        return rxjs_1.fromEvent(window, 'storage').pipe(operators_1.filter((e) => e.key === LOCAL_STORAGE_KEEP_ALIVE_KEY));
    }
    initLogout$(logoutTimer = rxjs_1.timer(this.duration - this.dialogTimeToLogout)) {
        return this.userAction$.pipe(operators_1.debounce(() => logoutTimer), operators_1.filter(() => this.timeLeftDialogHidden), operators_1.tap(() => this.showTimeLeftDialog().subscribe()), operators_1.takeWhile(() => !this.destroyLogout), operators_1.takeUntil(this.logout));
    }
    initPing$(pingTimer = rxjs_1.timer(this.ONE_MINUTE_IN_MILLISECONDS, this.pingEvery)) {
        return pingTimer.pipe(operators_1.tap(() => this.ping.emit()), operators_1.takeWhile(() => !this.destroyLogout), operators_1.takeUntil(this.logout));
    }
    extendSessionOnStayLogin$() {
        return this.stayLoginAction$.pipe(operators_1.tap(() => {
            if (event['key'] === 'stayLogin') {
                // close timeout modal when stay login button is clicked on any other tab(i.e, when stayLogin localstorage value changes)
                if (this.timeLeftModalService) {
                    this.timeLeftModalService.close();
                }
                this.timeLeftDialogHidden = true;
                this.logoutSubscribe();
            }
        }), operators_1.takeWhile(() => !this.destroyStayLogin));
    }
    showTimeLeftDialog() {
        this.timeLeftDialogHidden = false;
        return this.timeLeftModalService.open({ timeToLogout: this.dialogTimeToLogout }).pipe(operators_1.tap(response => {
            this.timeLeftDialogHidden = true;
            if (response === 'logout') {
                this.logout.emit();
            }
        }), operators_1.take(1));
    }
    logoutSubscribe() {
        if (!!this.logout$ && !this.logout$.closed) {
            this.logout$.unsubscribe();
        }
        this.logout$ = this.initLogout$().subscribe();
    }
    ngOnDestroy() {
        this.destroyLogout = true;
        this.destroyStayLogin = true;
    }
}
exports.SessionTimeoutComponent = SessionTimeoutComponent;
