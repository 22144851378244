<mc-collapsable-panel header="$I18N_TTP_IMPERSONATION_LOG_DETAILS_ACTIONS_TITLE" [isExpanded]="true">
  <div class="row mc-detailed-list-row">
    <div class="col-xs-4 mc-detailed-list-label">{{ '$I18N_TTP_IMPERSONATION_LOG_DETAILS_ACTIONS_LABEL_ACTION' | translate }}</div>
    <div class="mc-detailed-list-column">{{ logDetails.action | mcImpersonationLogAction }}</div>
  </div>
  <div class="row mc-detailed-list-row">
    <div class="col-xs-4 mc-detailed-list-label">{{ '$I18N_TTP_IMPERSONATION_LOG_DETAILS_ACTIONS_LABEL_TAGGED' | translate }}</div>
    <div class="mc-detailed-list-column">
      <i class="far" [ngClass]="{'fa-check text-success': logDetails.taggedMalicious, 'fa-times text-danger': !logDetails.taggedMalicious}" aria-hidden="true"></i>
    </div>
  </div>
</mc-collapsable-panel>
<hr>
