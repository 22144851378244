"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgThreatEventDetailsEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const swg_threat_event_details_actions_1 = require("../actions/swg-threat-event-details.actions");
const swg_threat_event_details_container_component_1 = require("../containers/threat-event-details-container/swg-threat-event-details-container.component");
const swg_threat_event_details_reducer_1 = require("../reducers/swg-threat-event-details.reducer");
const swg_threat_event_file_download_modal_component_1 = require("../components/threat-event-file-download-modal/swg-threat-event-file-download-modal.component");
const notification_actions_1 = require("../../../../components/notification/actions/notification.actions");
const confirmation_modal_component_1 = require("../components/confirmation-modal/confirmation-modal.component");
const swg_threat_events_actions_1 = require("../actions/swg-threat-events.actions");
class SwgThreatEventDetailsEffects {
    constructor(actions$, store, service, asideService, modalService, stateService, translationService) {
        this.actions$ = actions$;
        this.store = store;
        this.service = service;
        this.asideService = asideService;
        this.modalService = modalService;
        this.stateService = stateService;
        this.translationService = translationService;
        this.getThreatEventDetails$ = this.actions$.pipe(effects_1.ofType(swg_threat_event_details_actions_1.SwgThreatEventDetailsActionsEnum.LOAD_THREAT_EVENT_DETAILS), operators_1.switchMap((action) => {
            this.stateService.$state.go('secure-web-gateway-applications', { tab: 'events', id: action.payload }, { notify: false });
            return this.service.getThreatEventDetails(action.payload).pipe(operators_1.switchMap(threatEvent => [
                new swg_threat_event_details_actions_1.SwgLoadThreatEventDetailsSuccessAction(threatEvent),
                new swg_threat_events_actions_1.SwgUpdateThreatEventAction(threatEvent)
            ]), operators_1.catchError(() => rxjs_1.of(new swg_threat_event_details_actions_1.SwgLoadThreatEventDetailsFailureAction())));
        }));
        this.openSidePanel$ = this.actions$.pipe(effects_1.ofType(swg_threat_event_details_actions_1.SwgThreatEventDetailsActionsEnum.THREAT_EVENT_SIDEBAR_OPEN), operators_1.switchMap((action) => {
            this.sidePanelInstance = this.asideService.open(swg_threat_event_details_container_component_1.SwgThreatEventDetailsContainerComponent, {
                position: 'right',
                size: 'md',
                hasBackdrop: true
            });
            this.onCloseSubscription = this.sidePanelInstance.beforeClose().subscribe(() => {
                this.handleClose();
            });
            return rxjs_1.of(new swg_threat_event_details_actions_1.SwgLoadThreatEventDetailsAction(action.payload));
        }));
        this.closeSidePanel$ = this.actions$.pipe(effects_1.ofType(swg_threat_event_details_actions_1.SwgThreatEventDetailsActionsEnum.THREAT_EVENT_SIDEBAR_CLOSE), operators_1.filter(() => !!this.sidePanelInstance), operators_1.tap(() => {
            this.sidePanelInstance.close();
            this.handleClose();
        }));
        this.manualQuarantineConfirmation$ = this.actions$.pipe(effects_1.ofType(swg_threat_event_details_actions_1.SwgThreatEventDetailsActionsEnum.MANUAL_QUARANTINE_CONFIRMATION), operators_1.switchMap((action) => {
            this.modalConfirmationInstance = this.modalService.open(confirmation_modal_component_1.ConfirmationModalComponent);
            this.modalConfirmationInstance.componentInstance.title =
                '$I18N_SWG_THREAT_EVENT_CONFIRMATION_MODAL.QUARANTINE_TITLE';
            return this.modalConfirmationInstance
                .result()
                .pipe(operators_1.mergeMap((result) => result ? rxjs_1.of(new swg_threat_event_details_actions_1.SwgThreatEventsManualQuarantineAction(action.payload)) : rxjs_1.EMPTY));
        }));
        this.manualQuarantine$ = this.actions$.pipe(effects_1.ofType(swg_threat_event_details_actions_1.SwgThreatEventDetailsActionsEnum.MANUAL_QUARANTINE), operators_1.switchMap((action) => {
            return this.service.manualQuarantine(action.payload).pipe(operators_1.switchMap(() => [
                new swg_threat_event_details_actions_1.SwgLoadThreatEventDetailsAction(action.payload),
                new notification_actions_1.NotificationShowAction({
                    type: 'success',
                    config: {
                        msg: this.translationService.instant('$I18N_SWG_THREAT_EVENT_DETAILS_PANEL_HEADER.QUARANTINE_SUCCESS')
                    }
                })
            ]), operators_1.catchError(error => rxjs_1.of(this.getNotificationAction(error))), operators_1.finalize(() => {
                this.modalConfirmationInstance.close();
            }));
        }));
        this.releaseConfirmation$ = this.actions$.pipe(effects_1.ofType(swg_threat_event_details_actions_1.SwgThreatEventDetailsActionsEnum.RELEASE_CONFIRMATION), operators_1.switchMap((action) => {
            this.modalConfirmationInstance = this.modalService.open(confirmation_modal_component_1.ConfirmationModalComponent);
            this.modalConfirmationInstance.componentInstance.title =
                '$I18N_SWG_THREAT_EVENT_CONFIRMATION_MODAL.RELEASE_TITLE';
            return this.modalConfirmationInstance
                .result()
                .pipe(operators_1.mergeMap((result) => result ? rxjs_1.of(new swg_threat_event_details_actions_1.SwgThreatEventsReleaseAction(action.payload)) : rxjs_1.EMPTY));
        }));
        this.release$ = this.actions$.pipe(effects_1.ofType(swg_threat_event_details_actions_1.SwgThreatEventDetailsActionsEnum.RELEASE), operators_1.switchMap((action) => {
            return this.service.release(action.payload).pipe(operators_1.switchMap(() => [
                new swg_threat_event_details_actions_1.SwgLoadThreatEventDetailsAction(action.payload),
                new notification_actions_1.NotificationShowAction({
                    type: 'success',
                    config: {
                        msg: this.translationService.instant('$I18N_SWG_THREAT_EVENT_DETAILS_PANEL_HEADER.RELEASE_SUCCESS')
                    }
                })
            ]), operators_1.catchError(error => rxjs_1.of(this.getNotificationAction(error))), operators_1.finalize(() => this.modalConfirmationInstance.close()));
        }));
        this.reportFalsePositiveConfirmation$ = this.actions$.pipe(effects_1.ofType(swg_threat_event_details_actions_1.SwgThreatEventDetailsActionsEnum.THREAT_EVENT_REPORT_FALSE_POSITIVE_CONFIRMATION), operators_1.switchMap((action) => {
            this.modalConfirmationInstance = this.modalService.open(confirmation_modal_component_1.ConfirmationModalComponent);
            this.modalConfirmationInstance.componentInstance.title =
                '$I18N_SWG_THREAT_EVENT_CONFIRMATION_MODAL.REPORT_FALSE_POSITIVE_TITLE';
            return this.modalConfirmationInstance.result().pipe(operators_1.mergeMap((result) => {
                return result ? rxjs_1.of(new swg_threat_event_details_actions_1.SwgThreatEventsReportFalsePositiveAction(action.payload)) : rxjs_1.EMPTY;
            }));
        }));
        this.downloadFileModalOpen$ = this.actions$.pipe(effects_1.ofType(swg_threat_event_details_actions_1.SwgThreatEventDetailsActionsEnum.DOWNLOAD_MODAL_OPEN), operators_1.withLatestFrom(this.store.select(swg_threat_event_details_reducer_1.selectOpened)), operators_1.tap(([, event]) => {
            this.downloadFileModalInstance = this.modalService.open(swg_threat_event_file_download_modal_component_1.SwgThreatEventFileDownloadModalComponent, {
                size: 'md',
                hasBackdrop: true
            });
            this.downloadFileModalInstance.componentInstance.eventId = event.id;
        }));
        this.downloadFileModalClose$ = this.actions$.pipe(effects_1.ofType(swg_threat_event_details_actions_1.SwgThreatEventDetailsActionsEnum.DOWNLOAD_MODAL_CLOSE), operators_1.filter(() => !!this.downloadFileModalInstance), operators_1.tap(() => {
            this.downloadFileModalInstance.close();
            this.downloadFileModalInstance = undefined;
        }));
        this.downloadFileFormSubmit$ = this.actions$.pipe(effects_1.ofType(swg_threat_event_details_actions_1.SwgThreatEventDetailsActionsEnum.DOWNLOAD_FORM_SUBMIT), operators_1.switchMap((action) => {
            return this.service.downloadFile(action.id, action.password).pipe(operators_1.switchMap(response => {
                if (response.hasErrors) {
                    return [
                        new swg_threat_event_details_actions_1.SwgDownloadThreatEventModalClose(),
                        this.getNotificationAction(response.fail[0])
                    ];
                }
                const blob = new Blob([response.first.file], { type: 'application/zip' });
                const fileName = response.first.filename + '.zip';
                const objectUrl = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = objectUrl;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(objectUrl);
                return [new swg_threat_event_details_actions_1.SwgDownloadThreatEventModalClose()];
            }), operators_1.catchError(error => rxjs_1.of(this.getNotificationAction(error))));
        }));
        this.reportFalsePositive$ = this.actions$.pipe(effects_1.ofType(swg_threat_event_details_actions_1.SwgThreatEventDetailsActionsEnum.THREAT_EVENT_REPORT_FALSE_POSITIVE), operators_1.switchMap((action) => {
            return this.service.reportFalsePositive(action.payload).pipe(operators_1.switchMap(() => [
                new swg_threat_event_details_actions_1.SwgLoadThreatEventDetailsAction(action.payload),
                new notification_actions_1.NotificationShowAction({
                    type: 'success',
                    config: {
                        msg: this.translationService.instant('$I18N_SWG_THREAT_EVENT_DETAILS_PANEL_HEADER.REPORT_FALSE_POSITIVE_SUCCESS')
                    }
                })
            ]), operators_1.catchError(error => rxjs_1.of(this.getNotificationAction(error))), operators_1.finalize(() => this.modalConfirmationInstance.close()));
        }));
    }
    handleClose() {
        this.stateService.$state.go('secure-web-gateway-applications', { tab: 'events', id: null }, {
            notify: false
        });
        this.onCloseSubscription = undefined;
        this.sidePanelInstance = undefined;
    }
    getNotificationAction(error) {
        const msg = error.fail
            ? error.fail[0].errors[0].message
            : this.translationService.instant('$I18N_SWG_THREAT_EVENTS_TABLE.SERVER_ERROR');
        return new notification_actions_1.NotificationShowAction({ type: 'error', config: { msg } });
    }
}
__decorate([
    effects_1.Effect()
], SwgThreatEventDetailsEffects.prototype, "getThreatEventDetails$", void 0);
__decorate([
    effects_1.Effect()
], SwgThreatEventDetailsEffects.prototype, "openSidePanel$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], SwgThreatEventDetailsEffects.prototype, "closeSidePanel$", void 0);
__decorate([
    effects_1.Effect()
], SwgThreatEventDetailsEffects.prototype, "manualQuarantineConfirmation$", void 0);
__decorate([
    effects_1.Effect()
], SwgThreatEventDetailsEffects.prototype, "manualQuarantine$", void 0);
__decorate([
    effects_1.Effect()
], SwgThreatEventDetailsEffects.prototype, "releaseConfirmation$", void 0);
__decorate([
    effects_1.Effect()
], SwgThreatEventDetailsEffects.prototype, "release$", void 0);
__decorate([
    effects_1.Effect()
], SwgThreatEventDetailsEffects.prototype, "reportFalsePositiveConfirmation$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], SwgThreatEventDetailsEffects.prototype, "downloadFileModalOpen$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], SwgThreatEventDetailsEffects.prototype, "downloadFileModalClose$", void 0);
__decorate([
    effects_1.Effect()
], SwgThreatEventDetailsEffects.prototype, "downloadFileFormSubmit$", void 0);
__decorate([
    effects_1.Effect()
], SwgThreatEventDetailsEffects.prototype, "reportFalsePositive$", void 0);
exports.SwgThreatEventDetailsEffects = SwgThreatEventDetailsEffects;
