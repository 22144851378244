"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isLoading = exports.reducers = void 0;
const store_1 = require("@ngrx/store");
const fromExport = require("./export/export.reducer");
exports.reducers = fromExport.reducer;
/**
 * The createFeatureSelector function selects a piece of state from the root of the state object.
 * This is used for selecting feature states that are loaded eagerly or lazily.
 */
const getExportApiState = store_1.createFeatureSelector('exportApi');
/**
 * Selectors
 */
exports.isLoading = store_1.createSelector(getExportApiState, fromExport.isLoading);
