"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WildfireEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const actions = require("app-new/api-applications/actions");
const reducers = require("app-new/api-applications/reducers");
const notification_actions_1 = require("app-new/components/notification/actions/notification.actions");
class WildfireEffects {
    constructor(actions$, stateService, store, wildfireApiService, translateService, cortexApiService) {
        this.actions$ = actions$;
        this.stateService = stateService;
        this.store = store;
        this.wildfireApiService = wildfireApiService;
        this.translateService = translateService;
        this.cortexApiService = cortexApiService;
        this.createWildfireIntegration$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationWildfireActionTypes.CreateWildfireIntegration), operators_1.withLatestFrom(this.store.select(reducers.getIntegrationWildfireIntegration)), operators_1.switchMap(([, config]) => {
            const request = this.wildfireApiService.buildCreateRequest(config);
            return this.wildfireApiService.createWildfireIntegration(request).pipe(operators_1.switchMap((response) => [
                new notification_actions_1.NotificationShowAction({
                    type: 'success',
                    config: {
                        msg: this.translateService.instant('$I18N_API_INTEGRATION_WILDFIRE_WIZARD.SUCCESSES.CREATE')
                    }
                }),
                new actions.CancelWildfireIntegrationWidgetAction(),
                new actions.CreateWildfireIntegrationSuccessAction(response)
            ]), operators_1.catchError(() => rxjs_1.of(new notification_actions_1.NotificationShowAction({
                type: 'error',
                config: {
                    msg: this.translateService.instant('$I18N_API_INTEGRATION_WILDFIRE_WIZARD.ERRORS.CREATE')
                }
            }), new actions.CancelWildfireIntegrationWidgetAction(), new actions.GetAllIntegrationsAction())));
        }));
        this.updateWildfireIntegration$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationWildfireActionTypes.UpdateWildfireIntegration), operators_1.withLatestFrom(this.store.select(reducers.getIntegrationWildfireIntegration), this.store.select(reducers.getIntegrationWildfireSendApiInUpdateCall)), operators_1.switchMap(([, config, sendApiKey]) => {
            const request = this.wildfireApiService.buildUpdateRequest(config, sendApiKey);
            return this.wildfireApiService.updateWildfireIntegration(request).pipe(operators_1.switchMap((response) => [
                new notification_actions_1.NotificationShowAction({
                    type: 'success',
                    config: {
                        msg: this.translateService.instant('$I18N_API_INTEGRATION_WILDFIRE_WIZARD.SUCCESSES.UPDATE')
                    }
                }),
                new actions.CancelWildfireIntegrationWidgetAction(),
                new actions.UpdateWildfireIntegrationSuccessAction(response)
            ]), operators_1.catchError(() => rxjs_1.of(new notification_actions_1.NotificationShowAction({
                type: 'error',
                config: {
                    msg: this.translateService.instant('$I18N_API_INTEGRATION_WILDFIRE_WIZARD.ERRORS.UPDATE')
                }
            }), new actions.CancelWildfireIntegrationWidgetAction(), new actions.GetAllIntegrationsAction())));
        }));
        this.checkWildfireIntegrationPrerequisites$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationCreateActionTypes.StartIntegrationWildfire), operators_1.switchMap((action) => {
            if (action.payload.runChecks) {
                return this.wildfireApiService.checkWildfireIntegrationPrerequisites().pipe(operators_1.map(() => new actions.CheckWildfirePrerequisitesSuccessAction()), operators_1.catchError(error => {
                    return rxjs_1.of(new actions.CheckWildfirePrerequisitesFailAction(error.firstFail.errors[0].code));
                }));
            }
            else {
                return [new actions.CheckWildfirePrerequisitesSuccessAction()];
            }
        }));
        this.verifyWildfireIntegrationApiAndRegion$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationWildfireActionTypes.StartVerifyApiAndRegion), operators_1.switchMap((action) => {
            if (action.payload.apiKey && action.payload.region) {
                return this.wildfireApiService
                    .verifyApiAndRegionAction(action.payload.apiKey, action.payload.region)
                    .pipe(operators_1.map(() => new actions.StartVerifyApiAndRegionSuccessAction({
                    apiKey: action.payload.apiKey,
                    region: action.payload.region
                })), operators_1.catchError(() => {
                    return rxjs_1.of(new actions.StartVerifyApiAndRegionFailAction());
                }));
            }
            else {
                return [new actions.StartVerifyApiAndRegionFailAction()];
            }
        }));
        this.closeWildfireIntegrationWizard$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationWildfireActionTypes.CancelWildfireIntegrationWidget), operators_1.tap(() => {
            this.stateService.$state.go('api-applications-list', { displayTab: 'integrations' });
        }));
        this.acceptSharingInfo = this.actions$.pipe(effects_1.ofType(actions.IntegrationWildfireActionTypes.SwitchWildfireAcceptSharingInfoDisclaimer), operators_1.switchMap((action) => {
            if (action.payload) {
                return this.wildfireApiService.acceptSharingInfo();
            }
        }));
        this.verifyAndAddEmailWildfireIntegrationNotifications$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationWildfireActionTypes.VerifyAndAddEmailWildfireIntegrationNotifications), operators_1.switchMap((action) => {
            return this.cortexApiService.isInternalEmail(action.payload).pipe(operators_1.map((response) => {
                if (response) {
                    return new actions.VerifyAndAddEmailWildfireIntegrationNotificationsSuccessAction(action.payload);
                }
                return new actions.VerifyAndAddEmailWildfireIntegrationNotificationsFailAction();
            }), operators_1.catchError(() => rxjs_1.of(new actions.VerifyAndAddEmailWildfireIntegrationNotificationsFailAction())));
        }));
        this.verifyAndAddEmailWildfireIntegrationNotificationsFail$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationWildfireActionTypes.VerifyAndAddEmailWildfireIntegrationNotificationsFail), operators_1.switchMap(() => {
            return [
                new notification_actions_1.NotificationShowAction({
                    type: 'error',
                    config: {
                        msg: this.translateService.instant('$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.NOTIFICATIONS.API_ERROR.EXTERNAL_EMAIL')
                    }
                })
            ];
        }));
        this.startVerifyApiAndRegionFail$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationWildfireActionTypes.StartVerifyApiAndRegionFail), operators_1.switchMap(() => {
            return [
                new notification_actions_1.NotificationShowAction({
                    type: 'error',
                    config: {
                        msg: this.translateService.instant('$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.ACTIVATION.VERIFY_API_AND_REGION_FAIL_NOTIFICATION')
                    }
                })
            ];
        }));
        this.startVerifyApiAndRegionSuccess$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationWildfireActionTypes.StartVerifyApiAndRegionSuccess), operators_1.switchMap(() => {
            return [
                new notification_actions_1.NotificationShowAction({
                    type: 'success',
                    config: {
                        msg: this.translateService.instant('$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.ACTIVATION.VERIFY_API_AND_REGION_SUCCESS_NOTIFICATION')
                    }
                })
            ];
        }));
    }
}
__decorate([
    effects_1.Effect()
], WildfireEffects.prototype, "createWildfireIntegration$", void 0);
__decorate([
    effects_1.Effect()
], WildfireEffects.prototype, "updateWildfireIntegration$", void 0);
__decorate([
    effects_1.Effect()
], WildfireEffects.prototype, "checkWildfireIntegrationPrerequisites$", void 0);
__decorate([
    effects_1.Effect()
], WildfireEffects.prototype, "verifyWildfireIntegrationApiAndRegion$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], WildfireEffects.prototype, "closeWildfireIntegrationWizard$", void 0);
__decorate([
    effects_1.Effect()
], WildfireEffects.prototype, "acceptSharingInfo", void 0);
__decorate([
    effects_1.Effect()
], WildfireEffects.prototype, "verifyAndAddEmailWildfireIntegrationNotifications$", void 0);
__decorate([
    effects_1.Effect()
], WildfireEffects.prototype, "verifyAndAddEmailWildfireIntegrationNotificationsFail$", void 0);
__decorate([
    effects_1.Effect()
], WildfireEffects.prototype, "startVerifyApiAndRegionFail$", void 0);
__decorate([
    effects_1.Effect()
], WildfireEffects.prototype, "startVerifyApiAndRegionSuccess$", void 0);
exports.WildfireEffects = WildfireEffects;
