"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListComponent = void 0;
const table_store_1 = require("@mimecast-ui/table-store");
const models_1 = require("../../models/models");
const sidebar_actions_1 = require("../../actions/sidebar.actions");
const models_2 = require("../../../../../swg/policies/models");
const sidebar = require("../../../../../swg/policies/actions/sidebar.actions");
const sidebar_actions_2 = require("../../../../../swg/policies/actions/sidebar.actions");
const policy = require("../../../../../swg/policies/actions/policy.actions");
class ListComponent extends table_store_1.TableBaseComponent {
    constructor(store, stateService, capabilitiesService) {
        super(store, 'BiPolicyList');
        this.store = store;
        this.stateService = stateService;
        this.capabilitiesService = capabilitiesService;
        this.incidentPayload = {};
        this.searchBy = {};
        this.filters = {};
        this.columns = ['name', 'appliesTo', 'lastModified', 'right-column'];
        this.filterSearchOptions = [
            {
                label: '$18N_BI_POLICY_LIST.TABLE.ALL',
                value: 'all'
            },
            {
                label: '$18N_BI_POLICY_LIST.TABLE.COLUMNS.USER',
                value: 'user'
            }
        ];
        this.affectedUsers = models_1.BiAffectedUsersEnum;
        this.hasBiPolicyEditPermission$ = this.capabilitiesService.hasCapability('Permission.BI_CONFIGURE_POLICIES_EDIT');
    }
    ngOnInit() {
        this.incidentPayload = { incidentId: this.incidentId };
        this.dispatchLoadAction(this.incidentPayload);
    }
    onSearchIncidents(data) {
        this.searchBy = { searchBy: { fieldName: 'all', value: data.search } };
        this.runSearch();
    }
    onSearchClear() {
        this.searchBy = {};
        this.runSearch();
    }
    onBundleFilterChange(filters) {
        if (Object.keys(filters).length) {
            const filterBy = filters.status.map((status) => ({
                fieldName: 'result',
                value: status
            }));
            this.filters = { filterBy };
        }
        else {
            this.filters = {};
        }
        this.runSearch();
    }
    runSearch(pagination) {
        this.dispatchFilterAction(Object.assign(Object.assign(Object.assign({}, this.incidentPayload), this.searchBy), this.filters), pagination);
    }
    onPaginationChange(pagination) {
        this.runSearch(pagination);
    }
    goToCreatePolicy() {
        this.store.dispatch(new policy.SwgClearPolicy());
        this.stateService.$state.go('bi-policy-create');
    }
    onRowClick(row) {
        super.onRowClick(row);
        this.store.dispatch(new sidebar.SwgDetailEditSidebarOpen({
            description: row.policyDesc.description,
            policyType: row.policyDesc.policyType,
            id: row.id
        }));
    }
    editPolicy(row) {
        this.store.dispatch(new policy.SwgLoadPolicyToEdit({
            description: row.policyDesc.description,
            policyType: row.policyDesc.policyType,
            id: row.id
        }));
        this.store.dispatch(new sidebar_actions_2.SwgPolicyNavigateToEditPageAction(models_2.SwgPolicyWizardFlowType.EDIT));
    }
    deletePolicy(row) {
        this.store.dispatch(new sidebar_actions_1.BiDetailDeleteConfirmModelOpen(row));
    }
}
exports.ListComponent = ListComponent;
