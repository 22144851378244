"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgEndpointsSettingsDirectIpProtectionModalComponent = void 0;
const settings_actions_1 = require("../../../../actions/settings.actions");
const endpoints_models_1 = require("../../../../models/endpoints.models");
class SwgEndpointsSettingsDirectIpProtectionModalComponent {
    constructor(store, overlayRef, settings) {
        this.store = store;
        this.overlayRef = overlayRef;
        this.settings = settings;
        this.directIpModes = endpoints_models_1.SwgDirectIPProtectionMode;
    }
    onCancel() {
        this.store.dispatch(new settings_actions_1.SwgEndpointsSettingsCancelDirectIpModalAction(this.settings));
        this.overlayRef.close();
    }
    onUpdateSetting() {
        this.store.dispatch(new settings_actions_1.SwgEndpointsSettingsUpdateSettingsAction(this.settings));
    }
}
exports.SwgEndpointsSettingsDirectIpProtectionModalComponent = SwgEndpointsSettingsDirectIpProtectionModalComponent;
