"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RuleService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const search_reducer_1 = require("app-new/archive-viewer/reducers/search.reducer");
const rule_1 = require("../model/rule/rule");
class RuleService {
    constructor(http) {
        this.http = http;
        this.defaultFindGroupsPageSize = 1000;
        this.defaultFilters = [
            {
                fieldName: 'status',
                value: 'active'
            },
            {
                fieldName: 'status',
                value: 'created'
            },
            {
                fieldName: 'type',
                value: 'review'
            }
        ];
    }
    getMessageRoutes() {
        return [
            {
                value: 'all',
                label: '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SETTINGS_STEP.MESSAGES_SENT_AND_RECEIVED'
            },
            { value: 'sent', label: '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SETTINGS_STEP.MESSAGES_SENT' },
            {
                value: 'received',
                label: '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SETTINGS_STEP.MESSAGES_RECEIVED'
            }
        ];
    }
    getScheduleTypes() {
        return [
            {
                value: 'daily',
                label: '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SETTINGS_STEP.SCHEDULE_DAILY'
            },
            {
                value: 'weekly',
                label: '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SETTINGS_STEP.SCHEDULE_WEEKLY'
            }
        ];
    }
    getApiErrorTranslateKey(apiErrorCode, isUpdate = false) {
        switch (apiErrorCode.toUpperCase()) {
            case 'ERR_RULE_RETRIEVAL_FAILURE':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.NOTIFICATION.ERR_RULE_RETRIEVAL_FAILURE';
            case 'ERR_RULE_UPDATE_FAILURE':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.NOTIFICATION.ERR_RULE_UPDATE_FAILURE';
            case 'ERR_RULE_CREATION_FAILURE':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.NOTIFICATION.ERR_RULE_CREATION_FAILURE';
            case 'ERR_RULE_INVALID_ID':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.NOTIFICATION.ERR_RULE_INVALID_ID';
            case 'ERR_RULE_ID_NOT_VALID_FOR_USER':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.NOTIFICATION.ERR_RULE_ID_NOT_VALID_FOR_USER';
            case 'ERR_RULE_INVALID_SIZE_SEARCH_TERMS':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.NOTIFICATION.ERR_RULE_INVALID_SIZE_SEARCH_TERMS';
            case 'ERR_RULE_EMPTY_SEARCH_TERMS':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.NOTIFICATION.ERR_RULE_EMPTY_SEARCH_TERMS';
            case 'ERR_RULE_INVALID_REQUEST':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.NOTIFICATION.ERR_RULE_INVALID_REQUEST';
            case 'ERR_VALIDATION_OUT_OF_RANGE_SAMPLINGPERCENTAGE':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.NOTIFICATION.ERR_VALIDATION_OUT_OF_RANGE_SAMPLINGPERCENTAGE';
            case 'ERR_VALIDATION_NULL_REVIEWQUEUEID':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.NOTIFICATION.ERR_VALIDATION_NULL_REVIEWQUEUEID';
            case 'ERR_VALIDATION_BLANK_REVIEWQUEUEID':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.NOTIFICATION.ERR_VALIDATION_BLANK_REVIEWQUEUEID';
            case 'ERR_VALIDATION_NULL_SEARCHGROUPID':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.NOTIFICATION.ERR_VALIDATION_NULL_SEARCHGROUPID';
            case 'ERR_VALIDATION_BLANK_SEARCHGROUPID':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.NOTIFICATION.ERR_VALIDATION_BLANK_SEARCHGROUPID';
            case 'ERR_VALIDATION_NULL_MESSAGEROUTE':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.NOTIFICATION.ERR_VALIDATION_NULL_MESSAGEROUTE';
            case 'ERR_VALIDATION_BLANK_MESSAGEROUTE':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.NOTIFICATION.ERR_VALIDATION_BLANK_MESSAGEROUTE';
            case 'ERR_VALIDATION_NULL_SCHEDULETYPE':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.NOTIFICATION.ERR_VALIDATION_NULL_SCHEDULETYPE';
            case 'ERR_VALIDATION_BLANK_SCHEDULETYPE':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.NOTIFICATION.ERR_VALIDATION_BLANK_SCHEDULETYPE';
            case 'ERR_RULE_DISABLE_FAILURE':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.NOTIFICATION.ERR_RULE_DISABLE_FAILURE';
            case 'ERR_RULE_SEARCH_FAILURE':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.NOTIFICATION.ERR_RULE_SEARCH_FAILURE';
            case 'ERR_RULE_EXPORT_FAILURE':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.NOTIFICATION.ERR_RULE_EXPORT_FAILURE';
            case 'ERR_RULE_NOT_FOUND':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.NOTIFICATION.ERR_RULE_NOT_FOUND';
            default:
                return isUpdate
                    ? '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.NOTIFICATION.UPDATE_FAILURE'
                    : '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.NOTIFICATION.FAILURE';
        }
    }
    createRule(data) {
        return this.http.post('/api/supervision/config/create-rule', data).pipe(operators_1.map((response) => {
            return response;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    updateRule(ruleId, data) {
        return this.http.post('/api/supervision/config/update-rule', Object.assign(Object.assign({}, data), { id: ruleId })).pipe(operators_1.map((response) => {
            return response;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    getLexicons(pagination = search_reducer_1.defaultPaginationOption) {
        return this.http
            .post('/api/supervision/find-lexicons', {
            meta: { pagination }
        })
            .pipe(operators_1.map((response) => {
            return response;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    getQueues(queueFilters, pagination = search_reducer_1.defaultPaginationOption) {
        const filterBy = queueFilters ? this.getFilterByCriteria(queueFilters) : this.defaultFilters;
        return this.http
            .post('/api/supervision/find-queue-configs', {
            meta: { pagination },
            filterBy
        })
            .pipe(operators_1.map((response) => {
            return response;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    findGroups(config) {
        // set default number of return items from search
        const requestPayload = Object.assign(Object.assign({}, config), { meta: {
                pagination: {
                    pageSize: this.defaultFindGroupsPageSize
                }
            } });
        return this.http.post('/api/directory/find-groups', requestPayload).pipe(operators_1.map((response) => response.first), operators_1.map((response) => response.folders));
    }
    getGroup(config) {
        return this.http.post('/api/directory/get-group', config).pipe(operators_1.map((response) => response.all), operators_1.map((response) => response));
    }
    deleteRule(data) {
        return this.http.post(`${rule_1.baseUrl}delete-rule`, data).pipe(operators_1.map((response) => {
            return response;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    getFilterByCriteria(queueFilters) {
        const filters = queueFilters.statuses.map(status => {
            return {
                fieldName: 'status',
                value: status
            };
        });
        return [...filters, { fieldName: 'type', value: queueFilters.queueType }];
    }
}
exports.RuleService = RuleService;
RuleService.WizardId = 'archive-supervision-wizard-rule';
