<!-- We have all data when we start, that's why we have isLoading=false -->
<mc-layout-aside-extra-container keyTitle="$I18N_EXPORT_API_SIDE_PANEL_TITLE"
  [isLoading]="false" (closeAside)="close.emit()"
  [showClose]="true">
  <mc-extra-container>
    <mc-live-button overrideClasses="btn-primary" [isLoading]="isLoading"
      [label]="'$I18N_EXPORT_API_SIDE_PANEL_DOWNLOAD_BUTTON' | translate"
      [mcDisabled]="!transformedFormValue.columnsToExport.length"
      class="mc-live-button"
      (mcClick)="download.emit(transformedFormValue)">
    </mc-live-button>
    <button class="btn btn-secondary" (click)="close.emit()" [disabled]="isLoading">
      {{ '$I18N_EXPORT_API_SIDE_PANEL_CANCEL_BUTTON' | translate }}
    </button>
  </mc-extra-container>
  <mc-body-container>

    <form class="form-horizontal" [formGroup]="form">
      <div class="form-group">
        <label class="col-sm-4 control-label">
          {{ '$I18N_EXPORT_API_SIDE_PANEL_COLUMNS_TO_INCLUDE_LABEL' | translate }}
        </label>
        <div class="col-sm-8">
          <div class="mc-show-hide-columns-form-content" formGroupName="columnsToExport">
            <mc-checkbox class="mc-checkbox-container" *ngFor="let column of tableColumns" [label]="getTranslateKey(column)" [formControlName]="column"></mc-checkbox>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-4 control-label">
          {{ '$I18N_EXPORT_API_SIDE_PANEL_FILE_FORMAT_LABEL' | translate }}
        </label>
        <div class="col-sm-8">
          <mc-select [useTranslation]="false" [options]="options" formControlName="fileFormat"></mc-select>
        </div>
      </div>
    </form>

  </mc-body-container>
</mc-layout-aside-extra-container>
