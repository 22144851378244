<div class="mc-onedrive-backup-wizard-summary">
    <mc-wizard-step-header
        [label]="subTitle"
    ></mc-wizard-step-header>
    <div class="mc-detailed-list">
        <div class="mc-detailed-list-row row">
            <div class="mc-detailed-list-label col-sm-3">{{'$I18N_ONEDRIVE_BACKUP_WIZARD.STEPS.SUMMARY.NAME'|translate}}</div>
            <div class="mc-detailed-list-column">{{summaryDetails.name}}</div>
        </div>
        <div class="mc-detailed-list-row row">
            <div class="mc-detailed-list-label col-sm-3">{{'$I18N_ONEDRIVE_BACKUP_WIZARD.STEPS.SUMMARY.GROUP'|translate}}</div>
            <div class="mc-detailed-list-column">{{summaryDetails.group}}</div>
        </div>
        <div class="mc-detailed-list-row row">
            <div class="mc-detailed-list-label col-sm-3">{{'$I18N_ONEDRIVE_BACKUP_WIZARD.STEPS.SUMMARY.CONNECTOR'|translate}}</div>
            <div class="mc-detailed-list-column">{{summaryDetails.connector}}</div>
        </div>
        <div class="mc-detailed-list-row row">
            <div class="mc-detailed-list-label col-sm-3">{{'$I18N_ONEDRIVE_BACKUP_WIZARD.STEPS.SUMMARY.EXCLUDED_FILES'|translate}}</div>
            <div class="mc-detailed-list-column">{{summaryDetails.excludedFiles}}</div>
        </div>
    </div>
</div>