<mc-table
  *ngIf="externalTableSearchCount"
  tableId="message-center/message-tracking/main-table"
  [isLoading]="isLoading"
  [highlightedRow]="selectedRow"
  [columns]="columns"
  [data]="rowData"
  (rowClick)="rowClick.emit($event)"
  translatePrefix="$I18N_TRACK_AND_TRACE_TRACKING_MAIN_TABLE.TABLE"
>
  <button class="mc-table-actions btn btn-secondary" (click)="exportResults()">
    {{ '$I18N_TRACK_AND_TRACE_TRACKING_MAIN_TABLE.TABLE.EXPORT_BUTTON' | translate }}
  </button>

  <mc-empty-results
    *ngIf="!errorDataResponse; else errorResponseTemplate"
    iconClass="mc-icon-adcon-icon-stable-no-results-2">
    <mc-empty-body
       data-test="message-tracking-empty-results">
      {{'$I18N_TRACK_AND_TRACE_TRACKING_MAIN_TABLE.TABLE.EMPTY_RESULTS_TITLE'| translate }}
    </mc-empty-body> 
  </mc-empty-results>
  
  <ng-template #errorResponseTemplate>
    <mc-empty-results
      iconClass="far fa-exclamation-circle">
      <mc-empty-body
        data-test="message-tracking-empty-results-error">   
          {{ '$I18N_TRACK_AND_TRACE_TRACKING_MAIN_TABLE.TABLE.ERROR.RETRIEVE_RESULTS_TITLE' | translate }}
      </mc-empty-body>  
    </mc-empty-results>
  </ng-template> 

  <mc-column-email key="fromEnv" emailKey="emailAddress"></mc-column-email>
  <mc-column-email key="fromHdr" emailKey="emailAddress"></mc-column-email>
  <mc-column-email key="to" emailKey="emailAddress"></mc-column-email>
  <mc-column-date key="sent"></mc-column-date>

  <mc-column key="attachments">
    <mc-header-cell *mcHeaderCellDef>
      <i class="far fa-paperclip" aria-hidden="true"></i>
    </mc-header-cell>
    <mc-body-cell *mcBodyCellDef="let row">
      <i *ngIf="row.attachments" class="far fa-paperclip" aria-hidden="true"></i>
    </mc-body-cell>
  </mc-column>

  <mc-column key="spamScore">
    <mc-body-cell *mcBodyCellDef="let row">{{row.spamScore >=0 ?  row.spamScore : '-' }}</mc-body-cell>
  </mc-column>

  <mc-column key="detectionLevel">
    <mc-body-cell *mcBodyCellDef="let row">{{row.detectionLevel  || '-' }}</mc-body-cell>
  </mc-column>

  <mc-filters
    [externalTableSearchCount]="externalTableSearchCount"
    [metadata]="filtersMetadata"
    (paginatorChange)="paginatorChange.emit($event)"
  >
    <mc-filters-bundle-column-values
      [externalTableSearchCount]="externalTableSearchCount"
      [sections]="sections"
      (filtersChange)="filtersChange.emit($event)"
    >
    </mc-filters-bundle-column-values>
  </mc-filters>

  <mc-column-actions
    key="right-column"
    mcShowColumnConfig
    [columnsAlwaysVisible]="columnsAlwaysVisible"
    [columnsToStartHidden]="columnsToStartHidden"
  >
    <mc-row-actions *mcRowActions="let row">
      <li
        mcRowAction="$I18N_TRACK_AND_TRACE_TRACKING_MAIN_TABLE.MEATBALLS.VIEW_DETAILS"
        (click)="rowClick.emit(row)"
      ></li>
      <ng-container *mcCapabilities="'!Permission.SERVICE_DELIVERY_DENY_REPORT_AS_SUBMISSIONS'">
        <ng-container *ngIf="hasStatusAction(row)">
          <li mcRowActionSeparator></li>
          <li
            mcRowActionHeader="$I18N_TRACK_AND_TRACE_TRACKING_MAIN_TABLE.MEATBALLS.REPORT_AS"
          ></li>
          <li
            mcRowAction="$I18N_TRACK_AND_TRACE_TRACKING_MAIN_TABLE.MEATBALLS.SPAM"
            (click)="reportAs.emit({ reportType: 'spam', id: row.id })"
          ></li>
          <li
            mcRowAction="$I18N_TRACK_AND_TRACE_TRACKING_MAIN_TABLE.MEATBALLS.MALWARE"
            (click)="reportAs.emit({ reportType: 'malware', id: row.id })"
          ></li>
          <li
            mcRowAction="$I18N_TRACK_AND_TRACE_TRACKING_MAIN_TABLE.MEATBALLS.PHISHING"
            (click)="reportAs.emit({ reportType: 'phishing', id: row.id })"
          ></li>
        </ng-container>
      </ng-container>
    </mc-row-actions>
  </mc-column-actions>
</mc-table>
