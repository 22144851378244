<mc-layout-aside-extra-pagination-container
  keyTitle="$18N_SWG_REPORTING.TABLE.KEY_TITLE"
  showClose="true"
  [hasNextInView]="hasNextInView$ | async"
  [hasPreviousInView]="hasPreviousInView$ | async"
  [indexInView]="indexInView$ | async"
  [currenPagination]="currentPagination$ | async"
  [filtersMetaData]="filtersMetaData$ | async"
  [isLoading]="false"
  (goToNextPage)="getNext()"
  (goToPrevPage)="getPrev()"
  (closeAside)="close()"
>
  <div row-actions>
    <div class="mc-aside-header">{{ '$18N_SWG_REPORTING.TABLE.DETAILS' | translate }}</div>
  </div>

  <mc-body-container>
    <div>
      <mc-swg-activity-report-details
        [row]="selectedRow$ | async"
        [hasPrivateIpSupport]="hasPrivateIpSupport"
        [hasSwgFull]="hasSwgFull"
        [hasApplicationVisibility]="hasApplicationVisibility"
      ></mc-swg-activity-report-details>
    </div>
  </mc-body-container>
</mc-layout-aside-extra-pagination-container>
