'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
/**
 * Created by cfeudo on 12/11/2015.
 */
angular.module('navbar.services.controller', [])
    .controller('NavBarServicesController', ['$scope', '$q', 'navbarFavouriteService', 'navbarService', 'UserInfoApiService', function ($scope, $q, navbarFavouriteService, navbarService, userInfoApiService) {
        const self = this;
        const menuActions = {};
        // ----- Function exposed to the template ---
        self.getFavouritesPromise = navbarFavouriteService.getFavouritesPromise;
        self.isFavourite = navbarFavouriteService.isFavourite;
        self.favourite = navbarFavouriteService.favourite;
        self.megaMenuEmptyHandler = navbarService.hideServiceMenu;
        $scope.policyInheritance = isPolicyInheritanceEnabled;
        $scope.cybergraphV2Enabled = false;
        // ------------------------------------------
        (function refresh() {
            const menu = angular.element('.topNav > ul > li');
            const mainMenu = [];
            angular.forEach(menu, function (ele) {
                const stored = angular.element(ele).find('a');
                const storedChild = [];
                angular.forEach(stored.parent().find('ul li a'), function (eleC) {
                    const child = angular.element(eleC);
                    const menuItem = {
                        action: child.attr('onclick'),
                        label: child.text(),
                        icon: 'far fa-square'
                    };
                    storedChild.push(menuItem);
                    menuActions[menuItem.label.substr(1)] = menuItem.action && menuItem.action.split("'")[1];
                });
                mainMenu.push({
                    icon: 'far fa-link',
                    label: stored.first().text(),
                    childs: storedChild
                });
            });
            if (mainMenu.length != 0) {
                // @ts-ignore
                window.mainMenu = mainMenu;
                localStorage.setItem('mainMenu', JSON.stringify(mainMenu));
            }
        })();
        function isPolicyInheritanceEnabled() {
            const isFaaOrAaaUser = navbarService.isFaaOrAaaUser();
            const isMailProcessing = navbarService.isMailProcessing();
            if (isFaaOrAaaUser) {
                $q.all([navbarService.getData()]).then(function (data) {
                    $scope.policyInheritance = data[0].policyInheritance;
                }, function (error) {
                    throw error;
                });
            }
            if (isMailProcessing) {
                $scope.policyInheritance = true;
            }
        }
        isPolicyInheritanceEnabled();
        function checkIfCybergraphV2Enabled() {
            self.hideMepUI = navbarService.hasCap('Hide.MEP.UI.Cyb1.0');
            userInfoApiService.getAccount().subscribe((data) => {
                $scope.cybergraphV2Enabled = data.cybergraphV2Enabled || !self.hideMepUI;
            });
        }
        checkIfCybergraphV2Enabled();
    }]);
