"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasErrors = exports.isLoading = exports.getApplications = exports.getCategories = exports.reducer = exports.initialState = void 0;
const swg_activity_report_actions_1 = require("../actions/swg-activity-report.actions");
exports.initialState = {
    categories: null,
    applications: null,
    isLoading: false,
    hasErrors: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case swg_activity_report_actions_1.SwgActivityReportActions.SWG_ACTIVITY_REPORT_GET_CATEGORIES_ACTION:
            return Object.assign(Object.assign({}, state), { isLoading: true });
        case swg_activity_report_actions_1.SwgActivityReportActions.SWG_ACTIVITY_REPORT_GET_CATEGORIES_SUCCESS_ACTION:
            return Object.assign(Object.assign({}, state), { categories: action.payload, isLoading: false });
        case swg_activity_report_actions_1.SwgActivityReportActions.SWG_ACTIVITY_REPORT_GET_CATEGORIES_FAILURE_ACTION:
            return Object.assign(Object.assign({}, state), { isLoading: false, hasErrors: true });
        case swg_activity_report_actions_1.SwgActivityReportActions.SWG_ACTIVITY_REPORT_GET_APPLICATIONS_ACTION:
            return Object.assign(Object.assign({}, state), { isLoading: true });
        case swg_activity_report_actions_1.SwgActivityReportActions.SWG_ACTIVITY_REPORT_GET_APPLICATIONS_SUCCESS_ACTION:
            return Object.assign(Object.assign({}, state), { applications: action.payload, isLoading: false });
        case swg_activity_report_actions_1.SwgActivityReportActions.SWG_ACTIVITY_REPORT_GET_APPLICATIONS_FAILURE_ACTION:
            return Object.assign(Object.assign({}, state), { isLoading: false, hasErrors: true });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getCategories = (state) => state.categories;
exports.getCategories = getCategories;
const getApplications = (state) => state.applications;
exports.getApplications = getApplications;
const isLoading = (state) => state.isLoading;
exports.isLoading = isLoading;
const hasErrors = (state) => state.hasErrors;
exports.hasErrors = hasErrors;
