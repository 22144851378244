<mc-layout-detail-wizard [keyTitle]="wizardTitle">
  <mc-wizard #wizard (changeStep)="onStepChange($event)">
    <mc-wizard-step
      label="{{ '$I18N_ARCHIVE_IM_SYNC_WIZARD.IM_SYNC_TASK.DETAILS_STEP.TITLE' | translate}}">
      <mc-services-im-sync-wizard-task-details 
        [name]="name" [description]="description">
      </mc-services-im-sync-wizard-task-details>
    </mc-wizard-step>

    <mc-wizard-step
      label="{{ '$I18N_ARCHIVE_IM_SYNC_WIZARD.IM_SYNC_TASK.CONNECTION_STEP.TITLE' | translate}}">
      <mc-services-im-sync-wizard-task-connection
        [connectors]="(connectors$ | async)">
      </mc-services-im-sync-wizard-task-connection>
    </mc-wizard-step>

    <mc-wizard-step
      *ngIf="!isEditMode"
      label="{{ '$I18N_ARCHIVE_IM_SYNC_WIZARD.IM_SYNC_TASK.SUMMARY_STEP.TITLE' | translate}}">
      <mc-services-im-sync-wizard-task-summary 
        [wizardData]="wizardData$ | async">
      </mc-services-im-sync-wizard-task-summary>
    </mc-wizard-step>

  </mc-wizard>
  <mc-footer-container>
    <div class="pull-right no-select mc-button-footer">
      <button class="btn mc-create-policy-cancel" (click)="onCancel()">
        {{ '$I18N_ARCHIVE_IM_SYNC_WIZARD.IM_SYNC_TASK.CONTROLS.CANCEL' | translate }}
      </button>

      <ng-container *ngIf="wizard">
        <button
          class="btn btn-secondary"
          *ngIf="(wizard.hasPreviousStep$ | async)"
          (click)="onPrevious()"
        >
          {{ '$I18N_ARCHIVE_IM_SYNC_WIZARD.IM_SYNC_TASK.CONTROLS.PREVIOUS' | translate }}
        </button> 
        <button
          class="btn btn-primary"
          *ngIf="(wizard.hasNextStep$ | async)"
          [disabled]="((wizard.isLastStep$ | async) || 
                      ((wizard.currentStep === 1 || wizard.currentStep === 2) && !(isCurrentStepValid$ | async)))"                            
          (click)="onNext()"
        >
          {{ '$I18N_ARCHIVE_IM_SYNC_WIZARD.IM_SYNC_TASK.CONTROLS.NEXT' | translate }}
        </button>
        <button
          *ngIf="(wizard.isLastStep$ | async) || isEditMode"
          [disabled]="(isEditMode && !(isCurrentStepValid$ | async))"
          class="btn btn-primary"
          (click)="onSave($event)"
        >
          {{ getSaveButtonText() }}
        </button>
      </ng-container>
    </div>
  </mc-footer-container>
</mc-layout-detail-wizard>
