"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DirectorySyncIntegrationTestComponent = void 0;
const core_1 = require("@angular/core");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const test_connection_interface_1 = require("../../models/test-connection.interface");
class DirectorySyncIntegrationTestComponent {
    constructor(directorySyncService, capabilitiesService, featureFlagService, coreService) {
        this.directorySyncService = directorySyncService;
        this.capabilitiesService = capabilitiesService;
        this.featureFlagService = featureFlagService;
        this.coreService = coreService;
        this.isRestartButtonEnabled = false;
        this.showLdapSummary = false;
        this.showAzureSummary = false;
        this.showGsuiteSummary = false;
        this.testFromWizard = false;
        this.isCreateIntegrationButtonEnabled = new core_1.EventEmitter();
    }
    ngOnInit() {
        const ready$ = rxjs_1.from(this.featureFlagService.isReady());
        this.supportLink$ = ready$.pipe(operators_1.switchMap(() => this.coreService.getAccountSwitchSession().pipe(operators_1.take(1))), operators_1.map(switchedSession => {
            const hasConfigEnabled = this.featureFlagService.getBooleanFlag('rollout-zendesk-contact-support');
            const encodedAccountCode = switchedSession && switchedSession.accountcode
                ? `?ac=${encodeURIComponent(btoa(switchedSession.accountcode))}`
                : '';
            return hasConfigEnabled
                ? `/apps#/sso/support${encodedAccountCode}`
                : '/apps#sso/mcentral?goto=/s/contactsupport';
        }));
        this.showConnection();
    }
    ngOnChanges(changes) {
        if (this.testFromWizard) {
            const change = changes.info;
            if (change &&
                change.previousValue &&
                !Object.keys(change.previousValue).length &&
                change.currentValue) {
                this.retryConnectionTest();
            }
            else if (change && change.previousValue && change.currentValue) {
                this.connectionTestChanges(changes);
            }
        }
    }
    connectionTestChanges(changes) {
        const currentIntType = changes.info.currentValue.integrationType;
        const previousIntType = changes.info.previousValue.integrationType;
        if (currentIntType !== previousIntType) {
            if ((currentIntType === 'active_directory' && previousIntType === 'domino') ||
                (currentIntType === 'domino' && previousIntType === 'active_directory') ||
                (currentIntType === 'azure_standard' && previousIntType === 'azure_gcc') ||
                (currentIntType === 'azure_gcc' && previousIntType === 'azure_standard')) {
                this.timedRetryConnectionTest();
            }
            else {
                this.retryConnectionTest();
            }
        }
        else {
            this.timedRetryConnectionTest();
        }
    }
    timedRetryConnectionTest() {
        return rxjs_1.timer(1)
            .pipe(operators_1.tap(() => {
            this.retryConnectionTest();
        }))
            .subscribe();
    }
    showConnection() {
        if (this.isLdapConnection()) {
            this.showLdapSummary = true;
        }
        if (this.isAzureConnection()) {
            this.showAzureSummary = true;
        }
        if (this.isGsuiteConnection()) {
            this.showGsuiteSummary = true;
        }
    }
    retryConnectionTest() {
        this.isRestartButtonEnabled = false;
        this.showLdapSummary = false;
        this.showAzureSummary = false;
        this.showGsuiteSummary = false;
        this.showConnection();
        this.refreshSummary();
    }
    isLdapConnection() {
        return (!!this.info &&
            (Number(this.info.servertype) === test_connection_interface_1.ServerType.active_directory ||
                Number(this.info.servertype) === test_connection_interface_1.ServerType.domino));
    }
    isAzureConnection() {
        return !!this.info && Number(this.info.servertype) === test_connection_interface_1.ServerType.office_365;
    }
    isMigratedAzureStandardConnection() {
        return (!!this.info &&
            Number(this.info.servertype) === test_connection_interface_1.ServerType.office_365 &&
            Number(this.info.serversubtype) === test_connection_interface_1.ServerSubType.standard &&
            !!this.info.connectorId);
    }
    isMigratedGccConnection() {
        return (!!this.info &&
            Number(this.info.servertype) === test_connection_interface_1.ServerType.office_365 &&
            Number(this.info.serversubtype) === test_connection_interface_1.ServerSubType.gov &&
            !!this.info.connectorId);
    }
    isGsuiteConnection() {
        return !!this.info && Number(this.info.servertype) === test_connection_interface_1.ServerType.google_directory;
    }
    getElement() {
        let elem;
        if (this.isLdapConnection()) {
            elem = this.ldapElem;
        }
        if (this.isAzureConnection()) {
            elem = this.azureElem;
        }
        if (this.isGsuiteConnection()) {
            elem = this.gsuiteElem;
        }
        return elem;
    }
    startTest(payload) {
        const elem = this.getElement();
        if (!!elem && this.isPayloadValid(payload.payload)) {
            this.directorySyncService.testConnectionData(payload, elem);
        }
    }
    isPayloadValid(payload) {
        return payload.serverType && payload.description && payload.testType;
    }
    refreshSummary() {
        const elem = this.getElement();
        if (!!elem) {
            elem.nativeElement.restart = true;
        }
    }
    onChangeRestartButtonState(event) {
        this.isRestartButtonEnabled = event.detail;
        this.isCreateIntegrationButtonEnabled.emit(event.detail);
    }
    onChangeCallService(event) {
        this.startTest(event.detail);
    }
    isNewConnectivityEnabled() {
        return this.capabilitiesService.hasCapability('Ldap.Connectivity.New');
    }
    isAzureStandardHecateCapsEnabled() {
        return this.capabilitiesService.hasCapability('DirSync.Azure.Standard.Hecate');
    }
    isAzureGccHecateCapsEnabled() {
        return this.capabilitiesService.hasCapability('DirSync.Azure.GCC.Hecate');
    }
}
exports.DirectorySyncIntegrationTestComponent = DirectorySyncIntegrationTestComponent;
