"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomDomainBlockDetailsBuilder = void 0;
const custom_domain_block_base_builder_1 = require("./custom-domain-block-base.builder");
const custom_domain_block_details_1 = require("./custom-domain-block-details");
class CustomDomainBlockDetailsBuilder extends custom_domain_block_base_builder_1.CustomDomainBlockBaseBuilder {
    withAppliesTo(policyTargetResponse) {
        this.appliesTo = policyTargetResponse;
        return this;
    }
    withNotifyGroupDescription(notifyGroupDescription) {
        this.notifyGroupDescription = notifyGroupDescription;
        return this;
    }
    withNotifyGroupId(notifyGroupId) {
        this.notifyGroupId = notifyGroupId;
        return this;
    }
    withRedirectToType(redirectToType) {
        this.redirectToType = redirectToType;
        return this;
    }
    withRedirectToUser(redirectToUser) {
        this.redirectToUser = redirectToUser;
        return this;
    }
    withRedirectToGroupDescription(redirectToGroupDescription) {
        this.redirectToGroupDescription = redirectToGroupDescription;
        return this;
    }
    withRedirectToGroupId(redirectToGroupId) {
        this.redirectToGroupId = redirectToGroupId;
        return this;
    }
    self() {
        return this;
    }
    build() {
        return new custom_domain_block_details_1.CustomDomainBlockDetails(this.definitionId, this.policyId, this.name, this.description, this.action, this.notify, this.configurationId, this.lastUpdated, this.enabled, this.appliesTo, this.notifyGroupId, this.notifyGroupDescription, this.redirectToType, this.redirectToUser, this.redirectToGroupId, this.redirectToGroupDescription);
    }
}
exports.CustomDomainBlockDetailsBuilder = CustomDomainBlockDetailsBuilder;
