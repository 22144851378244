"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CyberGraphPoliciesTableComponent = void 0;
const core_1 = require("@angular/core");
const cybergraph_policies_model_1 = require("../../models/cybergraph-policies.model");
const cybergraph_policies_list_table_static_values_1 = require("./cybergraph-policies-list-table.static-values");
const table_store_1 = require("@mimecast-ui/table-store");
const statics = require("../steps/cybergraph-policy-summary-step/cybergraph-policy-summary-step.component.static-values");
class CyberGraphPoliciesTableComponent extends table_store_1.TableBaseComponent {
    constructor(tableStoreStore, translateService, userInfoApiService, cdr, featureFlag) {
        super(tableStoreStore, 'cyberGraphPoliciesListTable');
        this.translateService = translateService;
        this.userInfoApiService = userInfoApiService;
        this.cdr = cdr;
        this.featureFlag = featureFlag;
        this.searchSections = cybergraph_policies_list_table_static_values_1.tableSectionSearch;
        this.incidentPayload = {};
        this.searchBy = {};
        this.filters = {};
        this.selectedRows = [];
        this.columns = [];
        this.canEdit = false;
        this.keyDescription = '$I18N_SERVICES_CYBERGRAPH_POLICIES_TABLE.DESCRIPTION';
        this.isReady = false;
        this.onPaginatorChanged = new core_1.EventEmitter();
        this.onSearchFilterChanged = new core_1.EventEmitter();
        this.onRowClicked = new core_1.EventEmitter();
        this.onPolicyDelete = new core_1.EventEmitter();
        this.onCreateNewPolicy = new core_1.EventEmitter();
        this.onPolicyEdit = new core_1.EventEmitter();
        this.featureFlag.isReady().then(() => {
            this.getCybergraphV2enabled();
        });
    }
    ngOnInit() {
        super.ngOnInit();
    }
    getCybergraphV2enabled() {
        this.userInfoApiService.getAccount().subscribe((accountResponse) => {
            const trackingFlagEnabled = this.featureFlag.getBooleanFlag('rollout-cyg-21-image-tracker-ui', false);
            if (!accountResponse.cybergraphV2Enabled || trackingFlagEnabled) {
                this.columns = cybergraph_policies_list_table_static_values_1.tableColumns;
                this.keyDescription = '$I18N_SERVICES_CYBERGRAPH_POLICIES_TABLE.DESCRIPTION';
            }
            else {
                this.columns = cybergraph_policies_list_table_static_values_1.tableColumns.filter(item => item !== 'silencerStatus');
                this.keyDescription = '$I18N_SERVICES_CYBERGRAPH_POLICIES_TABLE.DESCRIPTION_V2';
            }
            this.isReady = true;
            this.cdr.detectChanges();
        }, error => {
            console.error('Error during get-account ' + error);
            this.columns = cybergraph_policies_list_table_static_values_1.tableColumns;
            this.isReady = true;
        });
    }
    setupSubscriptions() {
        this.tableDataSubscription = this.tableData$.subscribe((data) => {
            if (data && data.length) {
                this.tableData = data[0]['policies'];
            }
        });
        this.tableMetaDataSubscription = this.metaData$.subscribe(data => {
            if (data && data.pagination) {
                this.metadata = {
                    pagination: Object.assign(Object.assign({}, data.pagination), { totalRows: data.pagination.totalCount })
                };
            }
        });
    }
    changePaginator(pagination) {
        this.onPaginatorChanged.emit(pagination);
        super.onPaginationChange(pagination);
    }
    changeSearchFilter(filter) {
        this.onFilterChange(Object.assign(Object.assign({}, this.currentFilters), { searchBy: {
                fieldName: filter.selectValue,
                value: filter.searchQuery
            } }));
    }
    addPolicy() {
        this.onCreateNewPolicy.emit();
    }
    clickRow(row) {
        this.onRowClick(row);
        this.onRowClicked.emit(row);
    }
    editPolicy(policy) {
        this.onPolicyEdit.emit(policy);
    }
    deletePolicy(policy) {
        this.onPolicyDelete.emit(policy);
    }
    displayStatusIcon(policy) {
        if (policy.status === true) {
            return `${cybergraph_policies_list_table_static_values_1.icons.circleCheck} ${cybergraph_policies_list_table_static_values_1.icons.succesColor}`;
        }
        else {
            return `${cybergraph_policies_list_table_static_values_1.icons.circleCross} ${cybergraph_policies_list_table_static_values_1.icons.mutedColor}`;
        }
    }
    displayStatusLabel(policy) {
        return this.translate(policy.status
            ? statics.CyberGraphReportingEnumLabel.ENABLED
            : statics.CyberGraphReportingEnumLabel.DISABLED);
    }
    displayCheckIcon(bypass, policyValue) {
        return !bypass && policyValue ? cybergraph_policies_list_table_static_values_1.icons.check : cybergraph_policies_list_table_static_values_1.icons.hidden;
    }
    onSearchIncidents(data) {
        this.searchBy = { searchBy: { fieldName: 'all', value: data.search } };
        this.runSearch();
    }
    getCyberGraphReportingStatusEnumLabel(value) {
        return this.translate(value === cybergraph_policies_model_1.CyberGraphReportingStatusEnum.ON
            ? statics.CyberGraphReportingEnumLabel.ENABLED
            : statics.CyberGraphReportingEnumLabel.DISABLED);
    }
    getCyberGraphTrackersEnumLabel(value) {
        return this.translate(value === cybergraph_policies_model_1.CyberGraphTrackersEnum.ON
            ? statics.CyberGraphTrackerEnumLabel.ENABLED
            : statics.CyberGraphTrackerEnumLabel.DISABLED);
    }
    getCyberGraphDynamicBannerEnumLabel(value) {
        if (value === cybergraph_policies_model_1.CyberGraphDynamicBannersEnum.ON) {
            return this.translate(statics.CyberGraphDynamicBannerEnumLabel.ENABLED);
        }
        else if (value === cybergraph_policies_model_1.CyberGraphDynamicBannersEnum.LEARNING) {
            return this.translate(statics.CyberGraphDynamicBannerEnumLabel.LEARNING);
        }
        return this.translate(statics.CyberGraphDynamicBannerEnumLabel.DISABLED);
    }
    onSearchClear() {
        this.searchBy = {};
        this.runSearch();
    }
    onBundleFilterChange(filters) {
        if (Object.keys(filters).length) {
            const filterBy = filters.status.map((statusVal) => ({
                fieldName: 'result',
                value: statusVal
            }));
            this.filters = { filterBy };
        }
        else {
            this.filters = {};
        }
        this.runSearch();
    }
    runSearch(pagination) {
        this.dispatchFilterAction(Object.assign(Object.assign(Object.assign({}, this.incidentPayload), this.searchBy), this.filters), pagination);
    }
    translate(value) {
        return this.translateService.instant(value);
    }
    ngOnDestroy() {
        var _a, _b;
        (_a = this.tableDataSubscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        (_b = this.tableMetaDataSubscription) === null || _b === void 0 ? void 0 : _b.unsubscribe();
        super.ngOnDestroy();
    }
}
exports.CyberGraphPoliciesTableComponent = CyberGraphPoliciesTableComponent;
