"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const containers_1 = require("../containers");
const states_1 = require("./states");
angular
    .module('message-center.rejected-and-deferred', [])
    .controller('RejectedAndDeferredController', [
    '$stateParams',
    '$scope',
    function ($stateParams, $scope) {
        $scope.stateParams = $stateParams;
    }
])
    .directive('mcRejectedAndDeferred', static_1.downgradeComponent({
    component: containers_1.RejectedAndDeferredPageComponent
}))
    .config(['$stateProvider', states_1.configState]);
