"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const _ = require("lodash");
(function () {
    'use strict';
    angular.module('modalMaxRetention.service', []).factory('modalMaxRetentionService', [
        '$q',
        '$uibModal',
        'accountService',
        'userService',
        'sessionHandlerService',
        'capabilitiesService',
        '$translate',
        function ($q, $uibModal, accountService, userService, sessionHandlerService, capabilitiesService, $translate) {
            const keyMaxRetentionStatus = 'adcon_maxRetentionStatusId';
            const intMaxRetentionStatusId = sessionHandlerService.getSessionId();
            const constPackages = {
                advancedAccountAdministration: 'Advanced Account Administration [1000]',
                federatedAccountAdministration: 'Federated Account Administration [1038]'
            };
            const PERMISSIONS = {
                SETTINGS_CONFIRM_RETENTION: 'Permission.SETTINGS_CONFIRM_RETENTION',
                SERVICE_DELIVERY_READ: 'Permission.SERVICE_DELIVERY_READ'
            };
            let modalInstance;
            const modalContentDefer = $q.defer();
            const MAX_RETENTION_PERMANENT_LIMIT = 36159;
            const RETENTION_CONFIRMATION_TYPES = {
                MIN: 'MIN',
                MAX: 'MAX',
                MAX_AND_MIN: 'MAX_AND_MIN',
                NONE: 'NONE'
            };
            /* -------------------------------------------------------- *\
      - interface
      \* -------------------------------------------------------- */
            return {
                openMaxRetentionModal,
                closeRetentionModal,
                intMaxRetentionStatusId,
                isMinRetentionEnabledAndNotConfirmed,
                isMaxRetentionNotConfirmed,
                getModalContent,
                confirmRetention,
                snoozeRetention,
                auditRetentionAction,
                hasPermission,
                checkAccountAndSetRetentionModalType,
                retentionType: RETENTION_CONFIRMATION_TYPES.NONE,
                accountCache: {},
                CONSTANTS: {
                    MAX_RETENTION_PERMANENT_LIMIT,
                    RETENTION_CONFIRMATION_TYPES
                }
            };
            function closeRetentionModal(statusValue) {
                userService.setPreferencesByKey(keyMaxRetentionStatus, statusValue).then(function (responseSetPreferencesByKey) {
                    if (!responseSetPreferencesByKey.err) {
                        if (modalInstance) {
                            modalInstance.close();
                        }
                    }
                    else {
                        userService.getError().showErrorNotification(responseSetPreferencesByKey);
                    }
                }, function () {
                    userService.getError().showErrorNotification();
                });
            }
            function openMaxRetentionModal() {
                const self = this;
                if (!modalInstance) {
                    accountService.getAccount().then((responseGetAccount) => {
                        if (responseGetAccount.err) {
                            accountService.getError().showErrorNotification(responseGetAccount);
                        }
                        else if (isRetentionDialogueVisible(responseGetAccount.first)) {
                            self.accountCache = responseGetAccount.first;
                            self.checkAccountAndSetRetentionModalType(self.accountCache);
                            userService.getPreferencesByKey(keyMaxRetentionStatus, false).then((responseGetPreferencesByKey) => {
                                if (responseGetPreferencesByKey.err) {
                                    userService.getError().showErrorNotification(responseGetPreferencesByKey);
                                }
                                else if (!angular.equals(intMaxRetentionStatusId, responseGetPreferencesByKey)) {
                                    modalInstance = $uibModal.open({
                                        templateUrl: 'components/modal-max-retention/modal-max-retention.tpl.html',
                                        size: 250,
                                        backdrop: 'static',
                                        keyboard: false
                                    });
                                }
                            }, function () {
                                userService.getError().showErrorNotification();
                            });
                        }
                    }, function () {
                        accountService.getError().showErrorNotification();
                    });
                }
            }
            function checkAccountAndSetRetentionModalType(account) {
                if (isMinRetentionEnabledAndNotConfirmed(account)) {
                    if (isMaxRetentionNotConfirmed(account)) {
                        this.retentionType = RETENTION_CONFIRMATION_TYPES.MAX_AND_MIN;
                    }
                    else {
                        this.retentionType = RETENTION_CONFIRMATION_TYPES.MIN;
                    }
                }
                else if (isMaxRetentionNotConfirmed(account)) {
                    this.retentionType = RETENTION_CONFIRMATION_TYPES.MAX;
                }
                else if (anyUnresolvedMinRetentionConflict(account)) {
                    this.retentionType = RETENTION_CONFIRMATION_TYPES.MIN;
                }
            }
            function isAdvancedAdmin(packages) {
                return _.includes(packages, constPackages.advancedAccountAdministration);
            }
            function isFederatedAdmin(packages) {
                return _.includes(packages, constPackages.federatedAccountAdministration);
            }
            function hasPermission(permission) {
                return capabilitiesService.evalCapabilitiesExpression(permission);
            }
            function isRetentionDialogueVisible(responseGetAccount) {
                return ((isMinRetentionEnabledAndNotConfirmed(responseGetAccount) || isMaxRetentionNotConfirmed(responseGetAccount) || anyUnresolvedMinRetentionConflict(responseGetAccount)) &&
                    (!isAdvancedAdmin(responseGetAccount['packages']) &&
                        !isFederatedAdmin(responseGetAccount['packages']) &&
                        hasPermission(PERMISSIONS.SETTINGS_CONFIRM_RETENTION + '|| !' + PERMISSIONS.SERVICE_DELIVERY_READ)));
            }
            function isMinRetentionEnabledAndNotConfirmed(responseGetAccount) {
                return responseGetAccount['minRetentionEnabled'] && !responseGetAccount['minRetentionConfirmed'];
            }
            function isMaxRetentionNotConfirmed(responseGetAccount) {
                return !responseGetAccount['maxRetentionConfirmed'];
            }
            function anyUnresolvedMinRetentionConflict(responseGetAccount) {
                return responseGetAccount['minRetentionEnabled'] && (responseGetAccount['conflictingPolicies'] && responseGetAccount['conflictingPolicies'].length > 0);
            }
            function getModalContent(retentionLimits) {
                if (hasPermission('Permission.SETTINGS_CONFIRM_RETENTION') && needsConfirmation(retentionLimits)) {
                    return getConfirmationModalContent(retentionLimits, this.retentionType);
                }
                else if (hasPermission('!Permission.SERVICE_DELIVERY_READ')) {
                    return getReadOnlyModalContent(retentionLimits, this.retentionType);
                }
                else {
                    return getNoPermissionModalContent(retentionLimits);
                }
            }
            function needsConfirmation(retentionLimits) {
                const needsConf = (retentionLimits.intMinRetention && !retentionLimits.minRetentionConfirmed)
                    || (retentionLimits.intMaxRetention && !retentionLimits.maxRetentionConfirmed);
                return needsConf;
            }
            function getConfirmationModalContent(retentionLimits, retentionType) {
                $translate.onReady(() => {
                    modalContentDefer.resolve({
                        title: $translate.instant('$I18N_MODAL_' + retentionType + 'RETENTION_TITLE_WITH_PERMISSIONS'),
                        firstLine: getTextBasedOnLimit(retentionLimits, '$I18N_MODAL_' + retentionType + 'RETENTION_WITH_PERMISSIONS_CONTENT_FIRST_PERMANENTLY', '$I18N_MODAL_' + retentionType + 'RETENTION_WITH_PERMISSIONS_CONTENT_FIRST'),
                        secondLine: $translate.instant('$I18N_MODAL_RETENTION_WITH_PERMISSIONS_CONTENT_SECOND', {
                            btnConfirmation: $translate.instant('$I18N_MODAL_RETENTION_CONFIRMATION'),
                            btnCancel: $translate.instant('$I18N_MODAL_RETENTION_CANCEL')
                        }),
                        conflicts: getConflicts(retentionLimits['conflicts']),
                        maxRetentionConfirmed: retentionLimits['maxRetentionConfirmed'],
                        minRetentionConfirmed: retentionLimits['minRetentionConfirmed']
                    });
                });
                return modalContentDefer.promise;
            }
            function getReadOnlyModalContent(retentionLimits, retentionType) {
                $translate.onReady(() => {
                    modalContentDefer.resolve({
                        title: $translate.instant('$I18N_MODAL_' + retentionType + 'RETENTION_TITLE_NO_PERMISSIONS'),
                        firstLine: getTextBasedOnLimit(retentionLimits, '$I18N_MODAL_' + retentionType + 'RETENTION_NO_PERMISSIONS_CONTENT_FIRST_PERMANENTLY', '$I18N_MODAL_' + retentionType + 'RETENTION_NO_PERMISSIONS_CONTENT_FIRST'),
                        secondLine: $translate.instant('$I18N_MODAL_RETENTION_NO_PERMISSIONS_CONTENT_SECOND'),
                        conflicts: getConflicts(retentionLimits['conflicts']),
                        maxRetentionConfirmed: retentionLimits['maxRetentionConfirmed'],
                        minRetentionConfirmed: retentionLimits['minRetentionConfirmed']
                    });
                });
                return modalContentDefer.promise;
            }
            function getNoPermissionModalContent(retentionLimits) {
                $translate.onReady(() => {
                    modalContentDefer.resolve({
                        title: '',
                        firstLine: '',
                        secondLine: '',
                        conflicts: getConflicts(retentionLimits['conflicts']),
                        maxRetentionConfirmed: retentionLimits['maxRetentionConfirmed'],
                        minRetentionConfirmed: retentionLimits['minRetentionConfirmed'],
                        inlineNotificationOptions: {
                            messageBoxAlertTypeClass: 'alert-danger',
                            messageBoxIconClass: 'fas fa-exclamation-triangle',
                            messageBoxTitle: $translate.instant('$I18N_MODAL_RETENTION_ERROR_MESSAGE_TITLE'),
                            messageBoxVisibility: true,
                            messageBoxHideCloseButton: false,
                            messageBoxContent: $translate.instant('$I18N_COMMON_TEXT_LINE_GLOBAL_ERROR_MESSAGE')
                        }
                    });
                });
                return modalContentDefer.promise;
            }
            function getTextBasedOnLimit(limits, keyPermanent, keyVariable) {
                const keyToUse = limits.intMaxRetention > MAX_RETENTION_PERMANENT_LIMIT ? keyPermanent : keyVariable;
                return $translate.instant(keyToUse, limits);
            }
            function getConflicts(conflicts) {
                if (!conflicts || conflicts.length === 0) {
                    return undefined;
                }
                const conflictingPolicies = conflicts.map(conflict => `${conflict.name} (${conflict.count})`)
                    .reduce((acc, value, index) => `${acc}${index > 0 ? ', ' : ''}${value}`, '');
                return {
                    warning: $translate.instant('$I18N_MODAL_RETENTION.WARNING.TITLE'),
                    message: $translate.instant('$I18N_MODAL_RETENTION.WARNING.CONFLICTS', { conflictingPolicies }),
                    consequences: $translate.instant('$I18N_MODAL_RETENTION.WARNING.CONSEQUENCES')
                };
            }
            function confirmRetention(confirm) {
                return accountService
                    .confirmRetention(this.accountCache['accountCode'], confirm, this.retentionType.toLowerCase())
                    .then((responseRetention) => {
                    return responseRetention;
                });
            }
            function snoozeRetention() {
                closeRetentionModal(intMaxRetentionStatusId);
            }
            function auditRetentionAction() {
                accountService.auditRetentionAction(this.accountCache['accountCode']);
            }
        }
    ]);
})();
