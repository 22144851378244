"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateUpdateUrlComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const reducers = require("../../reducers/index");
const domain_validator_1 = require("app-new/components/validators/domain/domain.validator");
const table_store_1 = require("@mimecast-ui/table-store");
class CreateUpdateUrlComponent extends table_store_1.TableRowDetailBaseComponent {
    constructor(fb, translate, capabilitiesService, store) {
        super(store, 'managedUrlsList');
        this.fb = fb;
        this.translate = translate;
        this.capabilitiesService = capabilitiesService;
        this.canCreateEditAndDeleteCapExpression = 'Permission.SERVICES_TTP_URL_PROTECT_EDIT';
        this.saveButtonDisabled = false;
        this.save = new core_1.EventEmitter();
        this.cancel = new core_1.EventEmitter();
        this.closeAside = new core_1.EventEmitter();
        this.overrideTypes = [
            { value: 'permit', label: this.translate.instant('$I18N_SERVICES_URL_PROTECTION.PERMITTED') },
            { value: 'block', label: this.translate.instant('$I18N_SERVICES_URL_PROTECTION.BLOCKED') }
        ];
        this.matchTypes = [
            { value: 'explicit', label: this.translate.instant('$I18N_SERVICES_URL_PROTECTION.EXPLICIT') },
            { value: 'domain', label: this.translate.instant('$I18N_SERVICES_URL_PROTECTION.DOMAIN') }
        ];
        this.form = this.fb.group({
            overrideType: ['permit', [forms_1.Validators.required]],
            disableRewriting: [false],
            disableAwareness: [false],
            disableLogClick: [true],
            matchType: ['explicit'],
            urls: ['', [domain_validator_1.managedUrlValidator]],
            comment: ['', [forms_1.Validators.maxLength(50), forms_1.Validators.required]]
        });
        this.isEditAllowed = this.isEdit ? false : true;
        this.evalCapabilitiesExpressionSubscription = this.capabilitiesService
            .evalCapabilitiesExpression('Permission.SERVICE_DELIVERY_READ')
            .subscribe((isLocal) => {
            this.isLocalAcc = isLocal;
        });
    }
    get isEditMode() {
        return this.isEdit;
    }
    get sidePanelTitle() {
        return this.isEditMode
            ? '$I18N_SERVICES_URL_PROTECTION_MANAGED_URL_EDIT'
            : '$I18N_SERVICES_URL_PROTECTION_MANAGED_URL_CREATE_NEW';
    }
    get overrideTypeValue() {
        return this.form.getRawValue().overrideType || 'permit';
    }
    get overrideTypeTooltipText() {
        return this.overrideTypeValue === 'permit'
            ? '$I18N_SERVICES_URL_PROTECTION_MANAGED_URL_OVERRIDE_TYPE_PERMIT_INFO'
            : '$I18N_SERVICES_URL_PROTECTION_MANAGED_URL_OVERRIDE_TYPE_BLOCK_INFO';
    }
    get isUserAwarenessVisible() {
        if (this.form.getRawValue().overrideType === 'block') {
            return false;
        }
        if (this.form.getRawValue().overrideType === 'permit' &&
            this.form.value.disableRewriting === false) {
            return false;
        }
        return true;
    }
    get userAwarenessValueText() {
        return !this.form.value.disableAwareness
            ? '$I18N_SERVICES_URL_PROTECTION.DISABLED'
            : '$I18N_SERVICES_URL_PROTECTION.ENABLED';
    }
    get isDisableRewritingVisible() {
        return this.form.getRawValue().overrideType === 'block' ? false : true;
    }
    get disableRewritingValue() {
        return this.form.value.disableRewriting || false;
    }
    get rewritingValueText() {
        return !this.disableRewritingValue
            ? '$I18N_SERVICES_URL_PROTECTION.DISABLED'
            : '$I18N_SERVICES_URL_PROTECTION.ENABLED';
    }
    get matchTypeValueText() {
        return this.form.value.matchType;
    }
    get urlsValueText() {
        return this.form.value.urls;
    }
    get commentValueText() {
        return this.form.value.comment;
    }
    get disableLogClicksTextValue() {
        return !this.form.value.disableLogClick
            ? '$I18N_SERVICES_URL_PROTECTION.DISABLED'
            : '$I18N_SERVICES_URL_PROTECTION.ENABLED';
    }
    ngOnInit() {
        this.setEditMode();
        this.setLoadingStateListener();
        if (this.isEdit) {
            this.rowSubscription = this.selectedRow$.subscribe(row => {
                this.selectedRow = row;
                this.setFormValues();
                this.saveInitialUrlState();
                this.saveInitialMatchTypeState();
                this.lockOverrideType();
                this.toggleSubtitleVisibility(true);
            });
        }
    }
    ngOnDestroy() {
        this.clearEditModeState();
        if (this.loadingSubscription) {
            this.loadingSubscription.unsubscribe();
        }
        if (this.rowSubscription) {
            this.rowSubscription.unsubscribe();
        }
        if (this.evalCapabilitiesExpressionSubscription) {
            this.evalCapabilitiesExpressionSubscription.unsubscribe();
        }
    }
    setLoadingStateListener() {
        this.formLoadingState$ = this.store.select(reducers.getManagedUrlLoading);
        this.loadingSubscription = this.formLoadingState$.subscribe(state => {
            if (state) {
                this.form.disable();
            }
            else {
                this.form.enable();
            }
        });
    }
    setEditMode() {
        if (this.sidepanelData.isEditAllowed) {
            this.isEdit = true;
            this.isEditAllowed = true;
            return;
        }
        this.isEdit = this.selectedRow && this.selectedRow.id ? true : false;
        this.isEditAllowed = this.isEdit ? false : true;
    }
    clearEditModeState() {
        this.isEdit = false;
        this.isEditAllowed = true;
        if (this.sidepanelData) {
            this.sidepanelData.isEditAllowed = false;
        }
    }
    generateUrl() {
        if (this.isEdit && this.selectedRow) {
            let url = `${this.selectedRow.scheme}://${this.selectedRow.domain}`;
            if (this.selectedRow.path) {
                url += `${this.selectedRow.path}`;
            }
            if (this.selectedRow.port && this.selectedRow.port !== -1) {
                url += `:${this.selectedRow.port}`;
            }
            if (this.selectedRow.queryString) {
                url += `?${this.selectedRow.queryString}`;
            }
            return url;
        }
        return '';
    }
    // lock override type in edit mode. User is not alowed to change it
    lockOverrideType() {
        if (this.isEdit) {
            this.form.get('overrideType').disable();
        }
    }
    setFormValues() {
        this.form.patchValue({
            overrideType: this.isEdit && this.selectedRow ? this.selectedRow.action : 'permit',
            disableRewriting: this.isEdit && this.selectedRow ? !this.selectedRow.disableRewrite : false,
            disableAwareness: this.isEdit && this.selectedRow ? !this.selectedRow.disableUserAwareness : false,
            disableLogClick: this.isEdit && this.selectedRow ? !this.selectedRow.disableLogClick : false,
            matchType: this.isEdit && this.selectedRow ? this.selectedRow.matchType : 'explicit',
            urls: this.generateUrl(),
            comment: this.isEdit && this.selectedRow ? this.selectedRow.comment : ''
        });
    }
    saveInitialUrlState() {
        if (this.isEdit) {
            this.initialUrlValue = this.generateUrl();
        }
    }
    saveInitialMatchTypeState() {
        if (this.isEdit && this.selectedRow) {
            this.initialMatchTypeValue = this.selectedRow.matchType;
        }
    }
    toggleSubtitleVisibility(visible) {
        this.isSubtitleVisible = visible;
    }
    onSave() {
        // hide title (prevent undefined value until req is finished)
        this.toggleSubtitleVisibility(false);
        // disable save button to prevent user from clicking more then one time
        this.form.disable();
        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }
        // parse data
        const data = {
            url: this.form.value.urls,
            action: this.form.getRawValue().overrideType || 'permit',
            disableRewrite: !this.form.value.disableRewriting || false,
            disableUserAwareness: !this.form.value.disableAwareness || false,
            matchType: this.form.value.matchType || 'domain',
            comment: this.form.value.comment
        };
        if (this.isLocalAcc) {
            data.disableLogClick = !this.form.value.disableLogClick;
        }
        else if (!this.isEditMode) {
            // if normal user is in create mode
            data.disableLogClick = false;
        }
        else {
            // if normal user is in edit mode
            data.disableLogClick = this.selectedRow.disableLogClick;
        }
        // if disableRewrite is checked, remove disableUserAwareness
        if (data.disableRewrite) {
            data.disableUserAwareness = undefined;
        }
        if (data.action === 'block') {
            data.disableRewrite = false;
        }
        // if in edit mode, add id
        if (this.isEditMode) {
            data['id'] = this.selectedRow.id;
        }
        // if in edit mode and url is not changed, removed it (cuz of override conflict on backend)
        if (this.isEditMode) {
            if (this.form.value.urls === this.initialUrlValue &&
                this.form.value.matchType === this.initialMatchTypeValue) {
                delete data.url;
            }
            this.save.emit(data);
            return;
        }
        // in create mode, if user enters multiple urls, parse data and prepare for backend
        const parsedData = this.parseUrl(data);
        this.save.emit(parsedData);
    }
    // parse url and prepare payload for backend
    parseUrl(data) {
        const parsedUrls = data.url.trim().split(/\r?\n/);
        if (parsedUrls.length > 1) {
            return parsedUrls.map(url => {
                return {
                    action: data.action,
                    disableRewrite: data.disableRewrite,
                    disableUserAwareness: data.disableUserAwareness,
                    disableLogClick: data.disableLogClick,
                    matchType: data.matchType,
                    comment: data.comment,
                    url
                };
            });
        }
        return [data];
    }
    onClose() {
        this.closeAside.emit();
    }
    allowEditing() {
        this.isEditAllowed = true;
    }
}
exports.CreateUpdateUrlComponent = CreateUpdateUrlComponent;
