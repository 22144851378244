"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LexiconBuilder = void 0;
const lexicon_1 = require("./lexicon");
const lexicon_base_builder_1 = require("./lexicon-base.builder");
class LexiconBuilder extends lexicon_base_builder_1.LexiconBaseBuilder {
    withVersions(versions) {
        this.versions = versions;
        return this;
    }
    build() {
        return new lexicon_1.Lexicon(this.id, this.name, this.createdBy, this.updatedBy, this.created, this.updated, this.status, this.versions, this.searchType, this.associatedRules);
    }
}
exports.LexiconBuilder = LexiconBuilder;
