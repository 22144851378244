"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.getMepLogAdminActionSuccess = exports.getMepLogAdminActionProgress = void 0;
const store_1 = require("@ngrx/store");
const reducers_1 = require("../../reducers");
const logsState = require("../../reducers/logs/mep-logs.reducer");
const ɵ0 = state => state.mepLog;
exports.ɵ0 = ɵ0;
const mepLogState = store_1.createSelector(reducers_1.getMepLogState, ɵ0);
exports.getMepLogAdminActionProgress = store_1.createSelector(mepLogState, logsState.getInProgress);
exports.getMepLogAdminActionSuccess = store_1.createSelector(mepLogState, logsState.isSuccess);
