"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListComponent = void 0;
const store_1 = require("@ngrx/store");
const table_store_1 = require("@mimecast-ui/table-store");
const kb_urls_1 = require("app-new/core/constants/kb-urls");
const moment = require("moment");
const rxjs_1 = require("rxjs");
const deferralListAction = require("../../../actions/list.action");
const reducers = require("../../../reducers");
const deferred_list_impl_service_1 = require("../deferred-list.impl.service");
const list_static_values_1 = require("./list.static-values");
const exportAction = require("../../../actions/export.action");
const date_ranges_static_1 = require("../../date-ranges.static");
class ListComponent extends table_store_1.TableBaseComponent {
    constructor(capabilitiesService, store) {
        super(store, deferred_list_impl_service_1.DeferredListImplService.TABLE_ID);
        this.capabilitiesService = capabilitiesService;
        this.store = store;
        this.externalTableSearchCount = 0;
        this.PREFIX_KEY = '$I18N_DEFERRED_LIST';
        this.KB_URL = kb_urls_1.KBUrls.MESSAGE_CENTER_DEFERRED;
        this.externalTableSearchCount$ = new rxjs_1.BehaviorSubject(this.externalTableSearchCount);
        this.columnList = [...list_static_values_1.tableColumnList];
        this.exportColumnList = [...list_static_values_1.exportColumnList];
        this.columnsAlwaysVisible = [...list_static_values_1.tableColumnsAlwaysVisible];
        this.columnsToStartHidden = [...list_static_values_1.tableColumnsStartHidden];
        this.searchSections = list_static_values_1.tableSectionSearch;
        this.newDateRangePicker = date_ranges_static_1.dateRangePickerConfig;
        this.filterDateRange = {
            start: moment()
                .subtract(1, 'day')
                .format(),
            end: moment().format()
        };
        this.searchFilter = {
            searchBy: { fieldName: 'all', value: '' }
        };
        this.DEFAULT_SEARCH = { selectValue: 'all', searchQuery: '' };
    }
    ngOnInit() {
        this.tableDataSubscription = this.tableData$.subscribe((deferrals) => {
            this.deferrals = deferrals;
        });
        this.onSearch(this.DEFAULT_SEARCH);
        this.selectedRow$ = this.store.pipe(store_1.select(reducers.getSelectedRow));
        this.capabilitiesService
            .hasCapability('Temporary.MessageCenter.SpamScore')
            .subscribe(isActive => {
            if (isActive === false) {
                const columnListFiltered = this.columnList.filter(value => value !== 'spamScore' && value !== 'detectionLevel');
                this.columnList = columnListFiltered;
                const exportColumnListFiltered = this.exportColumnList.filter(value => value !== 'spamScore' && value !== 'spamDetection');
                this.exportColumnList = exportColumnListFiltered;
            }
        });
    }
    onSearch(filter) {
        let search;
        if (filter && filter.searchQuery) {
            search = { fieldName: filter.selectValue, value: filter.searchQuery };
        }
        else {
            search = {
                fieldName: this.DEFAULT_SEARCH.selectValue,
                value: this.DEFAULT_SEARCH.searchQuery
            };
        }
        this.searchFilter.searchBy = search;
        this.onFilterChange(this.getSearchPayload());
    }
    onDateFilterChange(date) {
        if (!!date.range) {
            // @ts-ignore
            this.filterDateRange.start = date.range.start.format();
            // @ts-ignore
            this.filterDateRange.end = date.range.end.format();
            this.onFilterChange(this.getSearchPayload());
        }
    }
    onRowClick(row) {
        this.store.dispatch(new deferralListAction.GetDeferredActionInfo(row));
    }
    permitForRecipient(row) {
        this.store.dispatch(new deferralListAction.GetDeferredPermitForRecipientAction(row));
    }
    blockForRecipient(row) {
        this.store.dispatch(new deferralListAction.GetDeferredBlockForRecipientAction(row));
    }
    onExport() {
        this.store.dispatch(new exportAction.DeferredExportAction(this.exportColumnList, this.getSearchPayload()));
    }
    getSearchPayload() {
        // @ts-ignore
        const searchPayload = Object.assign(Object.assign({}, this.searchFilter), this.filterDateRange);
        return searchPayload;
    }
    ngOnDestroy() {
        this.tableDataSubscription.unsubscribe();
        super.ngOnDestroy();
    }
}
exports.ListComponent = ListComponent;
