"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupervisionDashboardSettingsComponent = void 0;
const core_1 = require("@angular/core");
const queue_type_enum_1 = require("app-new/archive/supervision/types/queue-type.enum");
const operators_1 = require("rxjs/operators");
const rxjs_1 = require("rxjs");
const checklist_component_1 = require("app-new/components/checklist/checklist.component");
const attributeSuppliedByApi = null;
class SupervisionDashboardSettingsComponent {
    constructor(fb, translateService, cdRef) {
        this.fb = fb;
        this.translateService = translateService;
        this.cdRef = cdRef;
        this.hasEditPermission = false;
        this.queues = [];
        this.emailAddress = '';
        this.onUpdate = new core_1.EventEmitter();
        this.onCancel = new core_1.EventEmitter();
        this.reviewQueueType = queue_type_enum_1.QueueType.REVIEW;
        this.changeInFormContent = false;
        this.destroy$ = new rxjs_1.Subject();
        this.pendingPercentagesMetadata = {
            minValue: SupervisionDashboardSettingsComponent.MIN_PENDING,
            maxValue: SupervisionDashboardSettingsComponent.MAX_PENDING,
            translate: (value) => {
                return value + '%';
            }
        };
        this.oldestQueueMessagesMetadata = {
            minValue: SupervisionDashboardSettingsComponent.MIN_OLDEST,
            maxValue: SupervisionDashboardSettingsComponent.MAX_OLDEST,
            translate: (value) => {
                const label = value > 1
                    ? this.translateService.instant('$I18N_ARCHIVE_SUPERVISION_DASHBOARD_SETTINGS_ASIDE.SLIDER.TOOL_TIP_LABEL.OLDEST_QUEUE_PLURAL')
                    : this.translateService.instant('$I18N_ARCHIVE_SUPERVISION_DASHBOARD_SETTINGS_ASIDE.SLIDER.TOOL_TIP_LABEL.OLDEST_QUEUE_SINGULAR');
                return value + ' ' + label;
            }
        };
        this.numberOfHandles = 2;
        this.intializeForm();
    }
    ngAfterViewInit() {
        this.linkPendingPercentage();
        this.linkOldestQueuePercentage();
    }
    onFormChange() {
        this.form.valueChanges.pipe(operators_1.takeUntil(this.destroy$)).subscribe(() => {
            this.changeInFormContent = true;
        });
    }
    ngOnInit() {
        this.updateDashboardSettingsForm(this.settings);
        this.onFormChange();
    }
    ngOnChanges() {
        this.updateDashboardSettingsForm(this.settings);
    }
    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
    updateDashboardSettingsForm(settings) {
        if (settings) {
            Object.keys(settings).forEach(name => {
                if (this.form.controls[name]) {
                    this.form.controls[name].patchValue(settings[name]);
                }
            });
        }
        this.cdRef.detectChanges();
    }
    onFormSave() {
        this.onUpdate.emit({
            queueType: this.queueType,
            modifiedBy: attributeSuppliedByApi,
            modified: attributeSuppliedByApi,
            queues: this.form.controls['queues'].value,
            pendingPercentages: this.form.controls['pendingPercentages'].value,
            oldestQueueMessages: this.form.controls['oldestQueueMessages'].value
        });
        this.changeInFormContent = false;
    }
    intializeForm() {
        this.form = this.fb.group({
            queues: [
                '',
                [
                    checklist_component_1.minSelectedChecklist(SupervisionDashboardSettingsComponent.MIN_QUEUE_SELECTION),
                    checklist_component_1.maxSelectedChecklist(SupervisionDashboardSettingsComponent.MAX_QUEUE_SELECTION)
                ]
            ],
            pendingPercentages: [''],
            oldestQueueMessages: [''],
            pendingLowerBound: [''],
            pendingUpperBound: [''],
            oldestLowerBound: [''],
            oldestUpperBound: ['']
        });
    }
    linkPendingPercentage() {
        this.form.controls['pendingPercentages'].valueChanges
            .pipe(operators_1.takeUntil(this.destroy$))
            .subscribe(val => {
            const [lowerBound, upperBound] = val;
            this.patchInputIfDifferentAndNotEmpty('pendingLowerBound', lowerBound);
            this.patchInputIfDifferentAndNotEmpty('pendingUpperBound', upperBound);
        });
        this.form.controls['pendingLowerBound'].valueChanges
            .pipe(operators_1.takeUntil(this.destroy$), operators_1.debounceTime(1000))
            .subscribe(val => {
            const numberVal = Number.parseInt(val, 10);
            if (!Number.isNaN(numberVal)) {
                const sliderValues = this.form.controls['pendingPercentages'].value;
                if (!this.isLoading && numberVal) {
                    const maxUpperForLowerBound = Math.min(numberVal, sliderValues[1] - 1);
                    const newVal = Math.max(SupervisionDashboardSettingsComponent.MIN_PENDING + 1, maxUpperForLowerBound);
                    this.patchSliderIfDifferent('pendingPercentages', sliderValues, [newVal, sliderValues[1]], 0);
                    this.patchInputIfDifferentAndNotEmpty('pendingLowerBound', newVal);
                }
                else {
                    this.patchInputIfDifferentAndNotEmpty('pendingLowerBound', sliderValues[0]);
                }
            }
        });
        this.form.controls['pendingUpperBound'].valueChanges
            .pipe(operators_1.takeUntil(this.destroy$), operators_1.debounceTime(1000))
            .subscribe(val => {
            const numberVal = Number.parseInt(val, 10);
            if (!Number.isNaN(numberVal)) {
                const sliderValues = this.form.controls['pendingPercentages'].value;
                if (!this.isLoading && numberVal) {
                    const minLowerForUpperBound = Math.max(numberVal, sliderValues[0] + 1);
                    const newVal = Math.min(SupervisionDashboardSettingsComponent.MAX_PENDING - 1, minLowerForUpperBound);
                    this.patchSliderIfDifferent('pendingPercentages', sliderValues, [sliderValues[0], newVal], 1);
                    this.patchInputIfDifferentAndNotEmpty('pendingUpperBound', newVal);
                }
                else {
                    this.patchInputIfDifferentAndNotEmpty('pendingUpperBound', sliderValues[1]);
                }
            }
        });
    }
    linkOldestQueuePercentage() {
        this.form.controls['oldestQueueMessages'].valueChanges
            .pipe(operators_1.takeUntil(this.destroy$), operators_1.debounceTime(1000))
            .subscribe(val => {
            const [lowerBound, upperBound] = val;
            this.patchInputIfDifferentAndNotEmpty('oldestLowerBound', lowerBound);
            this.patchInputIfDifferentAndNotEmpty('oldestUpperBound', upperBound);
        });
        this.form.controls['oldestLowerBound'].valueChanges
            .pipe(operators_1.takeUntil(this.destroy$), operators_1.debounceTime(1000))
            .subscribe(val => {
            const numberVal = Number.parseInt(val, 10);
            if (!Number.isNaN(numberVal)) {
                const sliderValues = this.form.controls['oldestQueueMessages'].value;
                if (!this.isLoading && numberVal) {
                    const maxUpperForLowerBound = Math.min(numberVal, this.form.controls['oldestUpperBound'].value - 1);
                    const newVal = Math.max(SupervisionDashboardSettingsComponent.MIN_OLDEST + 1, maxUpperForLowerBound);
                    this.patchSliderIfDifferent('oldestQueueMessages', sliderValues, [newVal, sliderValues[1]], 0);
                    this.patchInputIfDifferentAndNotEmpty('oldestLowerBound', newVal);
                }
                else {
                    this.patchInputIfDifferentAndNotEmpty('oldestLowerBound', sliderValues[0]);
                }
            }
        });
        this.form.controls['oldestUpperBound'].valueChanges
            .pipe(operators_1.takeUntil(this.destroy$), operators_1.debounceTime(1000))
            .subscribe(val => {
            const numberVal = Number.parseInt(val, 10);
            if (!Number.isNaN(numberVal)) {
                const sliderValues = this.form.controls['oldestQueueMessages'].value;
                if (!this.isLoading && numberVal) {
                    const minLowerForUpperBound = Math.max(numberVal, this.form.controls['oldestLowerBound'].value + 1);
                    const newVal = Math.min(SupervisionDashboardSettingsComponent.MAX_OLDEST - 1, minLowerForUpperBound);
                    this.patchSliderIfDifferent('oldestQueueMessages', sliderValues, [sliderValues[0], newVal], 1);
                    this.patchInputIfDifferentAndNotEmpty('oldestUpperBound', newVal);
                }
                else {
                    this.patchInputIfDifferentAndNotEmpty('oldestUpperBound', sliderValues[1]);
                }
            }
        });
    }
    patchInputIfNaN(controlName, val, sliderValue) {
        const numberVal = Number.parseInt(val, 10);
        if (Number.isNaN(numberVal)) {
            this.form.controls[controlName].patchValue(sliderValue);
        }
    }
    ignoreInputIfExp(event) {
        const inputCharCode = event.which ? event.which : event.keyCode;
        return inputCharCode === 101 || inputCharCode === 69 ? false : true;
    }
    patchInputIfDifferentAndNotEmpty(controlName, newValue) {
        const currentVal = this.form.controls[controlName].value;
        if (currentVal !== newValue && currentVal !== null) {
            this.form.controls[controlName].patchValue(newValue);
        }
    }
    patchSliderIfDifferent(controlName, oldValues, newValues, index) {
        if (oldValues[index] !== newValues[index] && !isNaN(newValues[index])) {
            this.form.controls[controlName].patchValue(newValues);
        }
    }
}
exports.SupervisionDashboardSettingsComponent = SupervisionDashboardSettingsComponent;
SupervisionDashboardSettingsComponent.MIN_QUEUE_SELECTION = 1;
SupervisionDashboardSettingsComponent.MAX_QUEUE_SELECTION = 10;
SupervisionDashboardSettingsComponent.MIN_PENDING = 0;
SupervisionDashboardSettingsComponent.MAX_PENDING = 100;
SupervisionDashboardSettingsComponent.MIN_OLDEST = 0;
SupervisionDashboardSettingsComponent.MAX_OLDEST = 10;
