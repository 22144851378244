"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThreatIntelDashboardComponent = void 0;
class ThreatIntelDashboardComponent {
    constructor(searchWidgetService, threatIntelTabsService) {
        this.searchWidgetService = searchWidgetService;
        this.threatIntelTabsService = threatIntelTabsService;
        this.activeTab = 0;
        this.searchWidgetSubscription = this.searchWidgetService.searchByDataFormValues$.subscribe(val => this.searchComponent.searchByData(val, true));
    }
    ngOnInit() {
        this.threatIntelTabsService.init(this.tabGroup);
    }
    ngOnDestroy() {
        this.searchWidgetSubscription.unsubscribe();
    }
    onChartInstance() {
        // do something with the instance
    }
}
exports.ThreatIntelDashboardComponent = ThreatIntelDashboardComponent;
