"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgDeviceGroupsSidebarEffects = void 0;
const effects_1 = require("@ngrx/effects");
const operators_1 = require("rxjs/operators");
const device_groups_sidebar_actions_1 = require("../../actions/device-groups-sidebar.actions");
const device_groups_sidebar_container_component_1 = require("../../container/device-groups-sidebar/device-groups-sidebar.container.component");
const notification_actions_1 = require("../../../../components/notification/actions/notification.actions");
const TableStore = require("@mimecast-ui/table-store");
const rxjs_1 = require("rxjs");
const device_group_delete_confirmation_component_1 = require("../../components/device-groups/device-group-delete/device-group-delete-confirmation.component");
class SwgDeviceGroupsSidebarEffects {
    constructor(actions$, asideService, modalService, deviceGroupApiService, translationService) {
        this.actions$ = actions$;
        this.asideService = asideService;
        this.modalService = modalService;
        this.deviceGroupApiService = deviceGroupApiService;
        this.translationService = translationService;
        this.openSidePanel$ = this.actions$.pipe(effects_1.ofType(device_groups_sidebar_actions_1.SwgDeviceGroupsSidebarActionsEnum.SWG_DEVICE_GROUP_SIDEBAR_OPEN), operators_1.tap(() => {
            this.sidePanelInstance = this.asideService.open(device_groups_sidebar_container_component_1.SwgDeviceGroupsSidebarContainerComponent, {
                position: 'right',
                size: 'lg',
                hasBackdrop: true
            });
        }));
        this.closeSidePanel$ = this.actions$.pipe(effects_1.ofType(device_groups_sidebar_actions_1.SwgDeviceGroupsSidebarActionsEnum.SWG_DEVICE_GROUP_SIDEBAR_CLOSE), operators_1.filter(() => !!this.sidePanelInstance), operators_1.tap(() => {
            this.sidePanelInstance.close();
            this.sidePanelInstance = undefined;
        }));
        this.openDeviceGroupDeleteConfirmation$ = this.actions$.pipe(effects_1.ofType(device_groups_sidebar_actions_1.SwgDeviceGroupsSidebarActionsEnum.SWG_DEVICE_GROUP_DELETE_CONFIRMATION_MODAL_OPEN), operators_1.map((action) => action.payload), operators_1.switchMap(() => {
            this.deleteModalInstance = this.modalService.open(device_group_delete_confirmation_component_1.DeviceGroupDeleteConfirmationComponent, {
                size: 'md',
                hasBackdrop: true
            });
            return rxjs_1.of(new device_groups_sidebar_actions_1.SwgDeviceGroupSidebarClose());
        }));
        this.closeDeviceGroupDeleteConfirmation$ = this.actions$.pipe(effects_1.ofType(device_groups_sidebar_actions_1.SwgDeviceGroupsSidebarActionsEnum.SWG_DEVICE_GROUP_DELETE_CONFIRMATION_MODAL_CLOSE), operators_1.filter(() => !!this.deleteModalInstance), operators_1.tap(() => {
            this.deleteModalInstance.close();
            this.deleteModalInstance = undefined;
        }));
        this.deleteDeviceGroup$ = this.actions$.pipe(effects_1.ofType(device_groups_sidebar_actions_1.SwgDeviceGroupsSidebarActionsEnum.SWG_DEVICE_GROUP_DELETE), operators_1.map((action) => action.id), operators_1.switchMap((id) => {
            return this.deviceGroupApiService.deleteDeviceGroup(id).pipe(operators_1.mergeMap(() => {
                return [
                    new notification_actions_1.NotificationShowSuccessAction(this.translationService.instant('$I18N_DEVICE_GROUPS_LIST.DELETE_MODAL.SUCCESS')),
                    new device_groups_sidebar_actions_1.SwgDeviceGroupDeleteSuccess(),
                    new device_groups_sidebar_actions_1.SwgDeviceGroupDeleteConfirmationModalClose(),
                    new TableStore.LoadAction({ tableId: 'SwgDeviceGroupsListTable' })
                ];
            }), operators_1.catchError(error => rxjs_1.merge(rxjs_1.of(this.getNotificationAction(error)), rxjs_1.of(new device_groups_sidebar_actions_1.SwgDeviceGroupDeleteFailure()), rxjs_1.of(new device_groups_sidebar_actions_1.SwgDeviceGroupDeleteConfirmationModalClose()))));
        }));
    }
    getNotificationAction(error) {
        let message = '$I18N_SWG_CREATE_POLICY_SERVER_ERROR';
        if (error.fail && error.fail.length) {
            if (error.fail[0].errors.filter((e) => e.code === 'err_device_group_linked_to_policy')
                .length) {
                message = '$I18N_DEVICE_GROUPS_LIST.DELETE_MODAL.POLICY_ERROR';
            }
            else if (error.fail[0].errors.filter((e) => e.code === 'err_device_group_devices_in_group')
                .length) {
                message = '$I18N_DEVICE_GROUPS_LIST.DELETE_MODAL.ERROR';
            }
            else {
                message = error.fail[0].errors[0].message;
            }
        }
        return new notification_actions_1.NotificationShowAction({
            type: 'error',
            config: {
                msg: message
            }
        });
    }
}
__decorate([
    effects_1.Effect({ dispatch: false })
], SwgDeviceGroupsSidebarEffects.prototype, "openSidePanel$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], SwgDeviceGroupsSidebarEffects.prototype, "closeSidePanel$", void 0);
__decorate([
    effects_1.Effect()
], SwgDeviceGroupsSidebarEffects.prototype, "openDeviceGroupDeleteConfirmation$", void 0);
__decorate([
    effects_1.Effect()
], SwgDeviceGroupsSidebarEffects.prototype, "closeDeviceGroupDeleteConfirmation$", void 0);
__decorate([
    effects_1.Effect()
], SwgDeviceGroupsSidebarEffects.prototype, "deleteDeviceGroup$", void 0);
exports.SwgDeviceGroupsSidebarEffects = SwgDeviceGroupsSidebarEffects;
