"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
(function () {
    'use strict';
    /* ======================================================== *\

     = nav bar / favourites / controller
     = cfeudo

     \* ======================================================== */
    angular.module('navbar.favourites.controller', [])
        .controller('NavBarFavouritesController', ['$filter', 'navbarFavouriteService', 'navbarService',
        function ($filter, navbarFavouriteService, navbarService) {
            const self = this;
            /* -------------------------------------------------------- *\
             - interface
             \* -------------------------------------------------------- */
            self.favourites = navbarFavouriteService.getFavourites;
            self.openMenu = navbarService.openMenu;
            self.removeFavourite = removeFavourite;
            self.maxFavourites = navbarFavouriteService.getMaxOfFavourites;
            // @ts-ignore
            self.isTouch = angular.ISTOUCH;
            /* -------------------------------------------------------- *\
             - initialisation
             \* -------------------------------------------------------- */
            /* -------------------------------------------------------- *\
             - implementation
             \* -------------------------------------------------------- */
            // -- removeFavourite --
            function removeFavourite($event, item) {
                $event.stopPropagation();
                navbarFavouriteService.favourite(item);
            }
        }]);
}());
