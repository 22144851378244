"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateApPolicyWizardComponent = void 0;
const core_1 = require("@angular/core");
const policy_list_model_1 = require("../../models/policy-list.model");
const actions = require("../../actions/policy.actions");
class CreateApPolicyWizardComponent {
    constructor(store, capabilitiesService) {
        this.store = store;
        this.capabilitiesService = capabilitiesService;
        this.resetWizard = new core_1.EventEmitter();
        this.createPolicyEvent = new core_1.EventEmitter();
        this.updateApPolicyEvent = new core_1.EventEmitter();
        this.updateTypApDetailseWizard = new core_1.EventEmitter();
        this.updateSummaryWizard = new core_1.EventEmitter();
        this.updateTypeApInboundWizard = new core_1.EventEmitter();
        this.updateTypeApJournalWizard = new core_1.EventEmitter();
        this.updateTypeApOutboundWizard = new core_1.EventEmitter();
        this.updateTypeApAppliesToWizard = new core_1.EventEmitter();
        this.currentStep = 1;
        this.createApPolicyEvent = new core_1.EventEmitter();
    }
    ngOnInit() {
        this.hasPolicyIEPService$ = this.capabilitiesService.hasCapability('Policy.InsiderThreatManagement');
        this.hasPolicyRemoveActiveContent$ = this.capabilitiesService.hasCapability('Temporary.RemoveActiveContentEnabled');
        this.hasPolicyIEPService$.subscribe(hasIEP => (this.isIEPPackage = hasIEP));
    }
    goToStep(event) {
        this.currentStep = event;
    }
    isStepValid() {
        if (this.currentStep === 1) {
            return this.apPolicyDetailsStepPage.isValid();
        }
        if (this.currentStep === 2) {
            return this.apPolicyInboundStepPage.isValid();
        }
        if ((this.currentStep === 3 && !this.isIEPPackage) ||
            (this.currentStep === 5 && this.isIEPPackage)) {
            return this.apPolicyAppliesToStepPage.isValid();
        }
        return true;
    }
    getPageTitle() {
        if (policy_list_model_1.ApPolicyWizardFlowType.CREATE === this.wizardFlowType) {
            return '$I18N_AP_CREATE_POLICY_ADD_POLICY';
        }
        else if (policy_list_model_1.ApPolicyWizardFlowType.EDIT === this.wizardFlowType) {
            return '$I18N_AP_CREATE_POLICY_EDIT_POLICY';
        }
        else {
            return 'Duplicate Policy';
        }
    }
    isEditFlow() {
        return this.wizardFlowType !== policy_list_model_1.ApPolicyWizardFlowType.CREATE;
    }
    enterPressedEvent() {
        this.wizard.nextStep();
    }
    cancel() {
        this.store.dispatch(new actions.CancelModalOpenAction(policy_list_model_1.ApPolicyCancelModalSource.CREATE_POLICY));
    }
}
exports.CreateApPolicyWizardComponent = CreateApPolicyWizardComponent;
