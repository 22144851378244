"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdditionalFieldsType = exports.ffaUserAppliesFromOptions = exports.ffaUserAppliesToOptions = exports.appliesFromToOptions = exports.addressesBasedOnOptions = exports.noFromToAddressAttributesOption = void 0;
const url_protection_applies_to_settings_step_models_1 = require("./url-protection-applies-to-settings-step.models");
exports.noFromToAddressAttributesOption = [
    {
        value: '',
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.NO_ADDRESS_ATTRIBUTES',
        isSelected: true
    }
];
exports.addressesBasedOnOptions = [
    {
        value: url_protection_applies_to_settings_step_models_1.AddressesBasedOnOptions.ENVELOPE,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.ADDRESSES_BASED_ON.VALUES.ENVELOPE'
    },
    {
        value: url_protection_applies_to_settings_step_models_1.AddressesBasedOnOptions.HEADER,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.ADDRESSES_BASED_ON.VALUES.HEADER'
    },
    {
        value: url_protection_applies_to_settings_step_models_1.AddressesBasedOnOptions.ENVELOPE_HEADER,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.ADDRESSES_BASED_ON.VALUES.ALL'
    }
];
exports.appliesFromToOptions = [
    {
        value: url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.ALL,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.APPLIES.VALUES.ALL'
    },
    {
        value: url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.ALL_INTERNAL,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.APPLIES.VALUES.ALL_INTERNAL'
    },
    {
        value: url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.ALL_EXTERNAL,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.APPLIES.VALUES.ALL_EXTERNAL'
    },
    {
        value: url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.DOMAIN,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.APPLIES.VALUES.DOMAIN'
    },
    {
        value: url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.ADDRESS_GROUP,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.APPLIES.VALUES.ADDRESS_GROUP'
    },
    {
        value: url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.ADDRESS_ATTRIBUTE,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.APPLIES.VALUES.ADDRESS_ATTRIBUTE'
    },
    {
        value: url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.EMAIL_ADDRESS,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.APPLIES.VALUES.EMAIL_ADDRESS'
    }
];
exports.ffaUserAppliesToOptions = [
    {
        value: url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.ALL_INTERNAL,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.APPLIES.VALUES.ALL_INTERNAL'
    },
    {
        value: url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.DOMAIN,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.APPLIES.VALUES.DOMAIN'
    },
    {
        value: url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.ADDRESS_GROUP,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.APPLIES.VALUES.ADDRESS_GROUP'
    }
];
exports.ffaUserAppliesFromOptions = [
    {
        value: url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.ALL,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.APPLIES.VALUES.ALL'
    },
    {
        value: url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.ALL_INTERNAL,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.APPLIES.VALUES.ALL_INTERNAL'
    },
    {
        value: url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.ALL_EXTERNAL,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.APPLIES.VALUES.ALL_EXTERNAL'
    },
    {
        value: url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.DOMAIN,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.APPLIES.VALUES.DOMAIN'
    },
    {
        value: url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.ADDRESS_GROUP,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.APPLIES.VALUES.ADDRESS_GROUP'
    }
];
var AdditionalFieldsType;
(function (AdditionalFieldsType) {
    AdditionalFieldsType[AdditionalFieldsType["domain"] = 0] = "domain";
    AdditionalFieldsType[AdditionalFieldsType["addressGroup"] = 1] = "addressGroup";
    AdditionalFieldsType[AdditionalFieldsType["addressAttribute"] = 2] = "addressAttribute";
    AdditionalFieldsType[AdditionalFieldsType["emailAddress"] = 3] = "emailAddress";
})(AdditionalFieldsType = exports.AdditionalFieldsType || (exports.AdditionalFieldsType = {}));
