"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SummaryStepComponent = void 0;
const core_1 = require("@angular/core");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
class SummaryStepComponent {
    constructor(fb) {
        this.fb = fb;
        this.onUpdateEnabledStatus = new core_1.EventEmitter();
        this.destroy$ = new rxjs_1.Subject();
    }
    ngOnInit() {
        var _a;
        this.form = this.fb.group({
            enabled: [false]
        });
        const enabled = (_a = this.integration) === null || _a === void 0 ? void 0 : _a.enabled;
        this.form.get('enabled').setValue(enabled, { emitEvent: false });
        this.form
            .get('enabled')
            .valueChanges.pipe(operators_1.takeUntil(this.destroy$))
            .subscribe((value) => this.onUpdateEnabledStatus.emit(value));
    }
    ngOnChanges(changes) {
        if (changes['notificationGroups'] && !!changes['notificationGroups'].currentValue) {
            this.notificationGroupsList = Object.values(changes['notificationGroups'].currentValue);
        }
    }
    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
    get header() {
        return `$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.SUMMARY.HEADER_${this.hasCreatedIntegration ? 'EDIT' : 'CREATE'}`;
    }
    get isValid() {
        return true;
    }
}
exports.SummaryStepComponent = SummaryStepComponent;
