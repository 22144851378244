"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isLoading = exports.getMepLogItem = exports.reducer = exports.initialState = void 0;
const sidebarActions = require("../../actions/sidebar.actions");
const models_1 = require("../../models/models");
exports.initialState = {
    mepLogItem: {
        id: '',
        messageId: '',
        fromAddress: '',
        toAddresses: [],
        externalAddresses: [],
        internalAddresses: [],
        subject: '',
        status: models_1.MepLogStatus.NO_ACTION,
        createTime: '',
        policyName: '',
        policyDescription: ''
    },
    loading: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case sidebarActions.MepLogSidebarActionsEnums.MEP_LOG_LOAD_DETAILS:
            return Object.assign(Object.assign({}, state), { loading: true });
        case sidebarActions.MepLogSidebarActionsEnums.MEP_LOG_LOAD_DETAILS_SUCCESS:
            return Object.assign(Object.assign({}, state), { loading: false, mepLogItem: action.payload });
        case sidebarActions.MepLogSidebarActionsEnums.MEP_LOG_LOAD_DETAILS_FAILURE:
            return Object.assign(Object.assign({}, state), { loading: false, mepLogItem: exports.initialState.mepLogItem });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getMepLogItem = (state) => state.mepLogItem;
exports.getMepLogItem = getMepLogItem;
const isLoading = (state) => state.loading;
exports.isLoading = isLoading;
