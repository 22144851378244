"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CoreService = void 0;
const app_actions_1 = require("../../actions/app.actions");
const session_actions_1 = require("../../actions/session.actions");
const core_interfaces_1 = require("../../model/core.interfaces");
const reducers = require("../../reducers");
class CoreService {
    constructor(store) {
        this.store = store;
    }
    getSession() {
        return this.store.select(reducers.getSession);
    }
    isAppLoading() {
        return this.store.select(reducers.isAppLoading);
    }
    isAppLoaded() {
        return this.store.select(reducers.isAppLoaded).pipe(core_interfaces_1.isDefined());
    }
    getLegacyAdconSession() {
        return this.store.select(reducers.getLegacySession);
    }
    getAccountSwitchSession() {
        return this.store.select(reducers.getSwitchAccountSession);
    }
    getContextualAccountSession() {
        return this.store.select(reducers.getContextualAccountSession);
    }
    getUserProfile() {
        return this.store.select(reducers.getUserProfile);
    }
    getPreferences() {
        return this.store.select(reducers.getPreferences);
    }
    getCapabilities() {
        return this.store.select(reducers.getCapabilities);
    }
    getCapsOverride() {
        return this.store.select(reducers.getCapsOverride);
    }
    getConfigExtended() {
        return this.store.select(reducers.getConfigExtended);
    }
    getAccount() {
        return this.store.select(reducers.getAccount);
    }
    logout(invalidAccess = false, loggedOutMsg = false) {
        if (invalidAccess && loggedOutMsg) {
            this.store.dispatch(new app_actions_1.AlreadyLoggedOutAction());
        }
        else if (invalidAccess) {
            this.store.dispatch(new app_actions_1.InvalidAccessAction());
        }
        else {
            this.store.dispatch(new app_actions_1.LogoutAction());
        }
    }
    loadApp() {
        this.store.dispatch(new session_actions_1.GetAdcon3UrlsAction());
    }
    reload() {
        this.store.dispatch(new app_actions_1.PageReloadAction());
    }
    getMigratedPolicyInfo() {
        return this.store.select(reducers.getMigratedPolicyInfo);
    }
}
exports.CoreService = CoreService;
