"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OktaEvidenceBasedControlWizardComponent = void 0;
const core_1 = require("@angular/core");
class OktaEvidenceBasedControlWizardComponent {
    constructor() {
        this.hasCreatedIntegration = false;
        this.isVerificationInProgress = false;
        this.isVerified = false;
        this.onCancel = new core_1.EventEmitter();
        this.onCreateIntegration = new core_1.EventEmitter();
        this.onUpdateIntegration = new core_1.EventEmitter();
        this.onVerifyOktaApiKey = new core_1.EventEmitter();
        this.updateUserGroups = new core_1.EventEmitter();
        this.onSearchOktaGroups = new core_1.EventEmitter();
        this.onUpdateOktaEventGroups = new core_1.EventEmitter();
        this.onAddGroup = new core_1.EventEmitter();
        this.onDeleteGroup = new core_1.EventEmitter();
        this.onVerifyAndAddEmail = new core_1.EventEmitter();
        this.onDeleteEmail = new core_1.EventEmitter();
        this.onUpdateEnabledStatus = new core_1.EventEmitter();
    }
    get wizardTitle() {
        return `$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.TITLE_${this.hasCreatedIntegration ? 'EDIT' : 'CREATE'}`;
    }
    get showNext() {
        return !this.wizard.isLastStep$.getValue();
    }
    get showPrevious() {
        return this.wizard.currentStep !== 1;
    }
    get isStepValid() {
        switch (this.wizard.currentStep) {
            case 1:
                return this.activationStepComponent.isValid;
            case 2:
                return this.userGroupsStepComponent.isValid;
            case 3:
                return this.eventsAndOktaGroupsStepComponent.isValid;
            case 4:
                return this.notificationsStepComponent.isValid;
            case 5:
                return this.summaryStepComponent.isValid;
            default:
                return false;
        }
    }
    get showCreate() {
        var _a;
        return ((_a = this.wizard) === null || _a === void 0 ? void 0 : _a.isLastStep$.getValue()) && !this.hasCreatedIntegration;
    }
    get showUpdate() {
        var _a;
        return ((_a = this.wizard) === null || _a === void 0 ? void 0 : _a.isLastStep$.getValue()) && this.hasCreatedIntegration;
    }
    gotoPreviousStep() {
        if (!!this.wizard) {
            this.wizard.previousStep();
        }
    }
    gotoNextStep() {
        if (!!this.wizard) {
            this.wizard.nextStep();
        }
    }
    create() {
        this.onCreateIntegration.emit();
    }
    cancel() {
        this.onCancel.emit();
    }
    update() {
        this.onUpdateIntegration.emit();
    }
    verifyOktaApiKey(clientIdAndClientSecretAndBaseUrl) {
        this.onVerifyOktaApiKey.emit(clientIdAndClientSecretAndBaseUrl);
    }
}
exports.OktaEvidenceBasedControlWizardComponent = OktaEvidenceBasedControlWizardComponent;
