<mc-layout-aside-extra-container
  keyTitle="{{ '$I18N_API_WILDFIRE_INTEGRATION_ASIDE_VIEW.TITLE' | translate }}"
  [isLoading]="false"
  showClose="true"
  (closeAside)="onCloseClicked.emit()"
>
  <mc-extra-container *mcCapabilities="'Permission.API_APPLICATIONS_EDIT'">
    <mc-edit-integration-view
      [integration]="integration"
      [enabled]="enabled"
      (onEditClicked)="onEditClicked.emit()"
      (onEnableClicked)="onEnableClicked.emit()"
      (onDisableClicked)="onDisableClicked.emit()"
    >
    </mc-edit-integration-view>
  </mc-extra-container>

  <mc-body-container>
    <section class="mc-detail-section">
      <div class="panel-body no-padding-top">
        <div class="mc-detailed-list">
          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_WILDFIRE_INTEGRATION_ASIDE_VIEW.FIELDS.INTEGRATION' | translate }}
            </div>
            <div class="mc-detailed-list-column">
              {{ integration.integrationType | mcIntegrationProvider }}
            </div>
          </div>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_WILDFIRE_INTEGRATION_ASIDE_VIEW.FIELDS.DESCRIPTION.NAME' | translate }}
            </div>
            <div class="mc-detailed-list-column">
              {{ '$I18N_API_WILDFIRE_INTEGRATION_ASIDE_VIEW.FIELDS.DESCRIPTION.VALUE' | translate }}
            </div>
          </div>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_WILDFIRE_INTEGRATION_ASIDE_VIEW.FIELDS.REGION' | translate }}
            </div>
            <div class="mc-detailed-list-column">
              {{ wildfireIntegration?.region | mcRegionProvider }}
            </div>
          </div>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_WILDFIRE_INTEGRATION_ASIDE_VIEW.FIELDS.API_KEY' | translate }}
            </div>
            <div class="mc-detailed-list-column">
              {{ wildfireIntegration?.apiKey | mcApiObfuscator }}
            </div>
          </div>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_WILDFIRE_INTEGRATION_ASIDE_VIEW.FIELDS.ATTACHMENTS' | translate }}
            </div>
            <div class="mc-detailed-list-column">
              {{ (wildfireIntegration?.indicators?.ATTACHMENT?.enabled
              ? '$I18N_API_WILDFIRE_INTEGRATION_ASIDE_VIEW.FIELDS.STATIC.YES'
              : '$I18N_API_WILDFIRE_INTEGRATION_ASIDE_VIEW.FIELDS.STATIC.NO') | translate }}
            </div>
          </div>

          <div class="row mc-detailed-list-row" *mcCapabilities="'Temporary.Services.ApiApplications.Wildfire.Integration.SubmitMaliciousAttachment'">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_WILDFIRE_INTEGRATION_ASIDE_VIEW.FIELDS.MALICIOUS_ATTACHMENTS' | translate }}
            </div>
            <div class="mc-detailed-list-column">
              {{ (wildfireIntegration?.indicators?.MALICIOUS_ATTACHMENT?.enabled
              ? '$I18N_API_WILDFIRE_INTEGRATION_ASIDE_VIEW.FIELDS.STATIC.YES'
              : '$I18N_API_WILDFIRE_INTEGRATION_ASIDE_VIEW.FIELDS.STATIC.NO') | translate }}
            </div>
          </div>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_WILDFIRE_INTEGRATION_ASIDE_VIEW.FIELDS.REMEDIATION' | translate }}
            </div>
            <div class="mc-detailed-list-column">
              {{ (wildfireIntegration?.remediationRequired
              ? '$I18N_API_WILDFIRE_INTEGRATION_ASIDE_VIEW.FIELDS.STATIC.YES'
              : '$I18N_API_WILDFIRE_INTEGRATION_ASIDE_VIEW.FIELDS.STATIC.NO') | translate }}
            </div>
          </div>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_WILDFIRE_INTEGRATION_ASIDE_VIEW.FIELDS.RECIPIENTS' | translate }}
            </div>

            <div class="mc-detailed-list-column">
              <div class="row mc-detailed-list-row" *ngFor="let group of wildfireGroupsList">
                {{ group | translate }}
              </div>

              <div class="row mc-detailed-list-row" *ngFor="let email of wildfireEmailList">
                {{ email }}
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  </mc-body-container>
</mc-layout-aside-extra-container>
