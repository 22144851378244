
  <mc-table
    tableId="swg/endpoints-list-table"
    class="mc-swg-endpoints-list"
    [isLoading]="isLoading$ | async"
    [data]="tableData$ | async"
    [columns]="columns"
    translatePrefix="$I18N_ENDPOINTS_LIST.TABLE"
    (rowClick)="onRowClick($event)"
  >
    <button
      *ngIf="hasEndpointsDetailsEnabled && hasPurgePermission"
      [disabled]="!isSelectedRow()"
      class="mc-table-actions btn btn-primary panel-half-margin-right"
      (click)="purgeEndpoints()"
    >
      {{ '$I18N_ENDPOINTS_LIST.TABLE.ACTIONS.PURGE' | translate }}
    </button>

    <button class="mc-table-actions btn btn-secondary" (click)="onExportClicked()">
      {{ '$I18N_ENDPOINTS_LIST.TABLE.ACTIONS.EXPORT' | translate }}
    </button>

    <mc-filters
      (paginatorChange)="onPaginationChange($event)"
      [metadata]="metaData$ | async"
      [pageSizeOptions]="[50, 100, 300]"
      [showPagination]="true"
    >
      <mc-filter-search
        (search)="hasDeviceBasedPoliciesSwitchEnabled? onSearchNew($event) : onSearch($event)"
        (clear)="hasDeviceBasedPoliciesSwitchEnabled ? onSearchClearNew() : onSearchClear()"
        [placeholder]="'$I18N_ENDPOINTS_LIST.TABLE.SEARCH.PLACEHOLDER' | translate"
      >

      </mc-filter-search>

      <mc-filters-bundle-column-values
        *ngIf="!hasDeviceBasedPoliciesSwitchEnabled"
        #filterBundleComponent
        [sections]="searchFilters"
        [columns]="1"
        (filtersChange)="onBundleFilterChange($event)"
      >
      </mc-filters-bundle-column-values>

      <div *ngIf="hasDeviceBasedPoliciesSwitchEnabled" class="mc-search-count">
        <span *ngIf="tableSearch.searches.length > 3">{{'$I18N_ENDPOINTS_LIST.TABLE.SEARCH.PLACEHOLDER' | translate}}</span>
      </div>

      <mc-filters-bundle-column-values-modal
        *ngIf="hasDeviceBasedPoliciesSwitchEnabled"
        [sections]="tableFilterValues"
        (filtersChange)="onBundleFilterChangeNew($event)"
      >
      </mc-filters-bundle-column-values-modal>


    </mc-filters>

    <mc-column-date key="lastSync"></mc-column-date>
    <mc-column-select key="select" (selectChange)="selectRows($event)"></mc-column-select>

    <mc-column key="status">
      <mc-body-cell *mcBodyCellDef="let row">
        <span class="text-bold" [ngClass]="row.status | mcSwgEndpointsListMapStatusColors">
          {{ row.status | mcSwgEndpointsListMapStatusText | translate }}
        </span>
      </mc-body-cell>
    </mc-column>

    <mc-column key="os">
      <mc-body-cell *mcBodyCellDef="let row">
        <span *ngIf="!hasDeviceBasedPoliciesSwitchEnabled">
          <em class="far mc-os-icon" [ngClass]="row.os | mcSwgEndpointsListMapOsIcon"></em>
          <span *ngIf="!isWindowsDeviceWithLatestMSA(row); else regularOsCaption">
            {{ row.os | mcSwgEndpointsListMapOs }} {{ row | mcSwgEndpointsListMapOsVersion }}
          </span>
          <ng-template #regularOsCaption>
            <span>
               {{ row.os }}
            </span>
          </ng-template>
        </span>
        <span *ngIf="hasDeviceBasedPoliciesSwitchEnabled">
          <em class="far mc-os-icon" [ngClass]="row.os | mcSwgEndpointsListMapOsIcon"></em>
          <span>
            {{ row.os | mcSwgEndpointsListMapOs }} {{ row | mcSwgEndpointMapOsVersion }}
          </span>
        </span>
      </mc-body-cell>
    </mc-column>

    <mc-column key="lastUpdate">
      <mc-body-cell *mcBodyCellDef="let row">
        {{ row.lastUpdate | date : dateFormat : timezoneOffset }}
      </mc-body-cell>
    </mc-column>

    <mc-column key="username">
      <mc-body-cell *mcBodyCellDef="let row">
        <span *ngIf="hasMultiUsersSupport(row); else workStationUserName">
          <ng-container *ngIf="row.userCount === 0">
            {{'$I18N_ENDPOINTS_LIST.SERVER.NO_USER_SESSION' | translate}}
          </ng-container>
          <ng-container *ngIf="row.userCount === 1">
          {{'$I18N_ENDPOINTS_LIST.SERVER.SINGLE_USER' | translate}}
          </ng-container>
          <ng-container *ngIf="row.userCount >= 2">
          {{'$I18N_ENDPOINTS_LIST.SERVER.MULTIPLE_USERS' | translate}}
          </ng-container>
        </span>
        <ng-template #workStationUserName>
            <span>
               {{ row.username }}
            </span>
        </ng-template>
      </mc-body-cell>
    </mc-column>


    <mc-column-actions
      key="action"
      [columnsToStartHidden]="columnsToStartHidden"
      [columnsAlwaysVisible]="columnsAlwaysVisible"
      [columnsToIgnore]="columnsToIgnore"
      mcShowColumnConfig
    ></mc-column-actions>

    <mc-empty-results keyTitle="$I18N_ENDPOINTS_LIST.TABLE.NO_DATA">
      <mc-empty-body>
        {{ '$I18N_ENDPOINTS_LIST.TABLE.NO_DATA_BODY_PART_1' | translate }}
        <button class="btn btn-link mc-empty-body-link" (click)="goToSecurityAgentPage()">
          {{ '$I18N_ENDPOINTS_LIST.TABLE.NO_DATA_BODY_LINK' | translate }}
        </button>
        {{ '$I18N_ENDPOINTS_LIST.TABLE.NO_DATA_BODY_PART_2' | translate }}
      </mc-empty-body>
    </mc-empty-results>
  </mc-table>

