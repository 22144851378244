"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPreviousAndNextSelectedRow = exports.getSearchReason = exports.getSelectedRow = exports.getTrackingSearchPayload = exports.externalTableSearchCount = exports.getFiltersMetadata = exports.getSearchList = exports.isErrorDataResponse = exports.isTableLoading = exports.reducer = exports.initialState = void 0;
const searchAction = require("../../actions/search.actions");
const commonActions = require("../../../common/actions");
exports.initialState = {
    searchList: [],
    externalTableSearchCount: 0,
    paginatorMetadata: {
        pageToken: undefined,
        pageSize: 50,
        pageNumber: 0
    },
    selectedRow: undefined,
    trackingSearchPayload: {},
    trackingSearchFiltersPayload: {},
    searchReason: '',
    isLoading: false,
    errorDataResponse: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case searchAction.REQUEST_SEARCH_PERMISSION:
            return Object.assign(Object.assign({}, state), { trackingSearchPayload: action.payload, trackingSearchFiltersPayload: {} });
        case searchAction.REQUEST_SEARCH_REASON_PERMISSION:
            return Object.assign(Object.assign({}, state), { searchReason: action.searchReason, trackingSearchPayload: Object.assign(Object.assign({}, state.trackingSearchPayload), { searchReason: action.searchReason }) });
        case searchAction.REQUEST_SEARCH_FILTERS_PERMISSION:
            return Object.assign(Object.assign({}, state), { trackingSearchFiltersPayload: action.payload });
        case searchAction.SEARCH_PERMISSION_GRANTED:
            return Object.assign(Object.assign({}, state), { isLoading: true, externalTableSearchCount: Object.keys(state.trackingSearchFiltersPayload).length === 0
                    ? state.externalTableSearchCount + 1
                    : state.externalTableSearchCount });
        case searchAction.SEARCH_SUCCESS:
            return Object.assign(Object.assign({}, state), { searchList: action.payload, isLoading: false, errorDataResponse: false, paginatorMetadata: Object.assign(Object.assign({}, state.paginatorMetadata), { pageNumber: 0, pageToken: undefined }) });
        case searchAction.SEARCH_FAIL:
            return Object.assign(Object.assign({}, state), { searchList: [], isLoading: false, errorDataResponse: true });
        case searchAction.SEARCH_PAGINATION_STOP_LOADING:
            return Object.assign(Object.assign({}, state), { isLoading: false });
        case searchAction.SEARCH_PAGINATION:
            return Object.assign(Object.assign({}, state), { isLoading: true, paginatorMetadata: Object.assign(Object.assign({}, state.paginatorMetadata), action.payload) });
        case searchAction.SEARCH_SELECT_ROW:
            return Object.assign(Object.assign({}, state), { selectedRow: action.payload });
        case commonActions.HIGHLIGHT_SELECTED_ROW:
            return Object.assign(Object.assign({}, state), { selectedRow: action.row });
        case commonActions.CLEAR_SELECTED_ROW:
            return Object.assign(Object.assign({}, state), { selectedRow: undefined });
        case searchAction.RESET_STATE:
            return Object.assign({}, exports.initialState);
        default:
            return state;
    }
}
exports.reducer = reducer;
const isTableLoading = (state) => state.isLoading;
exports.isTableLoading = isTableLoading;
const isErrorDataResponse = (state) => state.errorDataResponse;
exports.isErrorDataResponse = isErrorDataResponse;
const getSearchList = (state) => {
    const firstElementIndex = state.paginatorMetadata.pageNumber * state.paginatorMetadata.pageSize;
    const lastElementIndex = firstElementIndex + state.paginatorMetadata.pageSize;
    return state.searchList.slice(firstElementIndex, lastElementIndex);
};
exports.getSearchList = getSearchList;
const getFiltersMetadata = (state) => {
    const numberOfPages = Math.ceil(state.searchList.length / state.paginatorMetadata.pageSize);
    const currentPageNumber = state.paginatorMetadata.pageNumber;
    const isLastPage = currentPageNumber + 1 >= numberOfPages;
    const filtersMetadata = {
        pagination: {
            previous: currentPageNumber > 0 ? 'previous' : undefined,
            next: !isLastPage ? 'next' : undefined,
            pageSize: exports.getSearchList(state).length,
            totalRows: state.searchList.length
        }
    };
    return filtersMetadata;
};
exports.getFiltersMetadata = getFiltersMetadata;
const externalTableSearchCount = (state) => {
    return state.externalTableSearchCount;
};
exports.externalTableSearchCount = externalTableSearchCount;
const getTrackingSearchPayload = (state) => {
    return Object.assign(Object.assign({}, state.trackingSearchPayload), state.trackingSearchFiltersPayload);
};
exports.getTrackingSearchPayload = getTrackingSearchPayload;
const getSelectedRow = (state) => {
    return state.selectedRow;
};
exports.getSelectedRow = getSelectedRow;
const getSearchReason = (state) => {
    return state.searchReason;
};
exports.getSearchReason = getSearchReason;
const getPreviousAndNextSelectedRow = (state) => {
    const list = exports.getSearchList(state);
    const selectedRow = exports.getSelectedRow(state) || { id: undefined };
    const index = selectedRow && list.findIndex(row => row.id === selectedRow.id);
    const result = {
        currentItemIndex: index + 1,
        pageSize: list.length,
        previous: index >= 0 ? list[index - 1] : undefined,
        next: index >= 0 ? list[index + 1] : undefined
    };
    return result;
};
exports.getPreviousAndNextSelectedRow = getPreviousAndNextSelectedRow;
