"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.canUpdateList = exports.hasGenericError = exports.isTableLoading = exports.getAttributes = exports.reducer = exports.initialState = void 0;
const listAction = require("../../actions/list.action");
exports.initialState = {
    attributes: [],
    isLoading: false,
    genericError: false,
    updateList: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        // Hide Attribute actions
        case listAction.HIDE_ATTRIBUTE:
            return Object.assign(Object.assign({}, state), { genericError: false, isLoading: true, updateList: false });
        case listAction.HIDE_ATTRIBUTE_SUCCESS:
            return Object.assign(Object.assign({}, state), { attributes: action.payload, genericError: false, isLoading: false, updateList: true });
        case listAction.HIDE_ATTRIBUTE_FAILURE:
            return Object.assign(Object.assign({}, state), { genericError: true, isLoading: false, updateList: false });
        case listAction.DELETE_ATTRIBUTE_CONFIRM:
            return Object.assign(Object.assign({}, state), { genericError: false, isLoading: false, updateList: false, attributes: Object.assign(Object.assign({}, state.attributes), action.payload) });
        case listAction.DELETE_ATTRIBUTE:
            return Object.assign(Object.assign({}, state), { genericError: false, isLoading: true, updateList: false, attributes: Object.assign(Object.assign({}, state.attributes), action.attribute) });
        case listAction.DELETE_ATTRIBUTE_SUCCESS:
            return Object.assign(Object.assign({}, state), { genericError: false, isLoading: false, updateList: true });
        case listAction.DELETE_ATTRIBUTE_FAILURE:
            return Object.assign(Object.assign({}, state), { genericError: true, isLoading: false, updateList: false });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getAttributes = (state) => state.attributes;
exports.getAttributes = getAttributes;
const isTableLoading = (state) => state.isLoading;
exports.isTableLoading = isTableLoading;
const hasGenericError = (state) => state.genericError;
exports.hasGenericError = hasGenericError;
const canUpdateList = (state) => state.updateList;
exports.canUpdateList = canUpdateList;
