"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgUrlReportModalEffects = void 0;
const effects_1 = require("@ngrx/effects");
const operators_1 = require("rxjs/operators");
const url_report_action_1 = require("../actions/url-report.action");
const swg_url_classification_report_component_1 = require("app-new/swg/url-check-report/component/report/swg-url-classification-report.component");
const reducers = require("app-new/swg/url-check-report/reducers/index");
class SwgUrlReportModalEffects {
    constructor(actions$, modalService, store) {
        this.actions$ = actions$;
        this.modalService = modalService;
        this.store = store;
        this.openReportUrlModal$ = this.actions$.pipe(effects_1.ofType(url_report_action_1.SwgUrlReportActions.OPEN_REPORT_URL_MODAL), operators_1.withLatestFrom(this.store.select(reducers.getUrlOrDomain)), operators_1.switchMap(([action, urlDomain]) => {
            return this.modalService
                .open(swg_url_classification_report_component_1.SwgUrlClassificationReportComponent, {
                size: 'md',
                hasBackdrop: true,
                data: { category: action.payload, domainUrl: urlDomain }
            })
                .afterClose()
                .pipe(operators_1.filter((catTuple) => !!catTuple), operators_1.map((catTuple) => {
                return new url_report_action_1.SwgReportUrlAction(catTuple);
            }));
        }));
    }
}
__decorate([
    effects_1.Effect()
], SwgUrlReportModalEffects.prototype, "openReportUrlModal$", void 0);
exports.SwgUrlReportModalEffects = SwgUrlReportModalEffects;
