<div [ngClass]="{'mc-list-panel no-border no-margin': isModal}">
  <div [ngClass]="{'mc-list-panel-body': isModal}">
    <div class="mc-detailed-list {{isDetails ? 'mc-details' : 'panel-padding-bottom panel-padding-top'}} {{isModal && 'no-padding'}}">
      <div class="mc-list-panel-row {{isModal ? 'no-padding': 'no-padding-bottom'}}">
        <div class="mc-list-panel no-margin">
          <div class="mc-list-panel-row mc-header mc-custom-row">
            <div>
              <span>{{ '$I18N_SERVICES_URL_PROTECTION.URL_TOOLS.CHECK_URLS.SCAN_CATEGORY_LABEL' | translate }}</span>
              <ng-container *ngIf="!isDetails && !isModal">
                <mc-tooltip placement="top"
                  popoverContent="{{ '$I18N_SERVICES_URL_PROTECTION.URL_TOOLS.CHECK_URLS.SCAN_CATEGORY_TOOLTIP' | translate }}">
                </mc-tooltip>
              </ng-container>
            </div>
            <div>{{ '$I18N_SERVICES_URL_PROTECTION.URL_TOOLS.CHECK_URLS.SCAN_RESULT' | translate }}</div>
          </div>
          <div class="mc-custom-row">
            <div>
              <div class="mc-list-panel-row">
                {{ '$I18N_SERVICES_URL_PROTECTION.URL_TOOLS.CHECK_URLS.RELAXED_CATEGORY_LABEL' | translate}}
              </div>
              <div class="mc-list-panel-row">
                {{ '$I18N_SERVICES_URL_PROTECTION.URL_TOOLS.CHECK_URLS.MODERATE_CATEGORY_LABEL' | translate}}
              </div>
              <div class="mc-list-panel-row">
                {{ '$I18N_SERVICES_URL_PROTECTION.URL_TOOLS.CHECK_URLS.AGGRESSIVE_CATEGORY_LABEL' | translate}}
              </div>
            </div>
            <div class="mc-scan-result">
              <div class="mc-list-panel-row">
                <span data-test="cy-scan-result-relaxed">
                  <mc-spinner *ngIf="isLoading; else relaxedTemp"></mc-spinner>
                  <ng-template #relaxedTemp>
                    <span class="fas {{setScanResultIcon(relaxed)}} panel-half-padding-right"></span>{{relaxed}}
                  </ng-template>
                </span>
              </div>
              <div class="mc-list-panel-row">
                <mc-spinner *ngIf="isLoading; else moderateTemp"></mc-spinner>
                <ng-template #moderateTemp>
                  <span data-test="cy-scan-result-moderate">
                    <span class="fas {{setScanResultIcon(moderate)}} panel-half-padding-right"></span>{{moderate}}
                  </span>
                </ng-template>
              </div>
              <div class="mc-list-panel-row">
                <mc-spinner *ngIf="isLoading; else aggressiveTemp"></mc-spinner>
                <ng-template #aggressiveTemp>
                  <span data-test="cy-scan-result-aggressive">
                    <span class="fas {{setScanResultIcon(aggressive)}} panel-half-padding-right"></span>{{aggressive}}
                  </span>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>