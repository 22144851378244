"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColumnSelectAndSearchBundleComponent = void 0;
const operators_1 = require("rxjs/operators");
const utils_1 = require("../../../utils");
const components_1 = require("@mimecast-ui/components");
class ColumnSelectAndSearchBundleComponent extends components_1.BaseFilterBundleComponent {
    constructor(tableCore, filterCore, fb) {
        super(tableCore, filterCore);
        this.fb = fb;
        this.sections = [];
        this.hasAllOption = true;
        this.previousQuery = '';
    }
    ngOnInit() {
        this.form = this.fb.group({
            selectValue: '',
            searchQuery: ''
        });
        this.subscription = this.form.valueChanges
            .pipe(operators_1.debounceTime(200))
            .subscribe((newValue) => {
            if (!!newValue.searchQuery || !!this.previousQuery) {
                const changedValue = {
                    searchQuery: utils_1.stripNonPrintableChars(newValue.searchQuery),
                    selectValue: newValue.selectValue || 'all'
                };
                this.resetPageNumber();
                this.filtersChange.emit(changedValue);
            }
            this.previousQuery = newValue.searchQuery;
        });
    }
    /**
     * Unsubscribe the valueChange subscription
     */
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
exports.ColumnSelectAndSearchBundleComponent = ColumnSelectAndSearchBundleComponent;
