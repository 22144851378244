"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChameleonService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
class ChameleonService {
    constructor(chameleonApiService) {
        this.chameleonApiService = chameleonApiService;
    }
    deleteCustomExternalDomain(monitoredDomainDeletePayload) {
        // @ts-ignore
        return this.chameleonApiService.deleteCustomExternalDomain(monitoredDomainDeletePayload).pipe(operators_1.map((response) => {
            return {
                deleteSuccessIds: response.all,
                deleteFailIds: response.fail
            };
        }), operators_1.catchError(error => rxjs_1.throwError(error)));
    }
    createMonitoredExternalDomains(monitoredDomainCreationPayload) {
        return this.chameleonApiService
            .createMonitoredExternalDomains(monitoredDomainCreationPayload)
            .pipe(operators_1.map((response) => {
            return {
                createdDomains: response.all,
                failedDomains: response.fail
            };
        }), operators_1.catchError((response) => {
            return rxjs_1.of({
                createdDomains: response.all,
                failedDomains: response.fail
            });
        }));
    }
    updateMonitoredDomain(updateMonitoredDomainPayload) {
        return this.chameleonApiService.updateMonitoredDomain(updateMonitoredDomainPayload).pipe(operators_1.map((response) => response.first), operators_1.catchError(error => rxjs_1.throwError(error)));
    }
}
exports.ChameleonService = ChameleonService;
