"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserMailboxFallbackActionOptions = exports.UserMailboxActionOptions = void 0;
const models_1 = require("../../../models");
exports.UserMailboxActionOptions = [
    {
        value: models_1.UserMailboxActionOption.NONE,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_JOURNAL_SETTINGS_STEP_LABEL.USER_MAILBOX_ACTION.OPTIONS.NONE'
    },
    {
        value: models_1.UserMailboxActionOption.REMOVEMESSAGE,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_JOURNAL_SETTINGS_STEP_LABEL.USER_MAILBOX_ACTION.OPTIONS.REMOVE_MESSAGE'
    },
    {
        value: models_1.UserMailboxActionOption.REMOVEATTACHMENT,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_JOURNAL_SETTINGS_STEP_LABEL.USER_MAILBOX_ACTION.OPTIONS.REMOVE_ATTACHMENT'
    }
];
exports.UserMailboxFallbackActionOptions = [
    {
        value: models_1.UserMailboxFallbackActionOption.NONE,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_JOURNAL_SETTINGS_STEP_LABEL.USER_MAILBOX_FALLBACK_ACTION.OPTIONS.NONE'
    },
    {
        value: models_1.UserMailboxFallbackActionOption.REMOVEMESSAGE,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_JOURNAL_SETTINGS_STEP_LABEL.USER_MAILBOX_FALLBACK_ACTION.OPTIONS.REMOVE_MESSAGE'
    },
    {
        value: models_1.UserMailboxFallbackActionOption.REMOVEATTACHMENT,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_JOURNAL_SETTINGS_STEP_LABEL.USER_MAILBOX_FALLBACK_ACTION.OPTIONS.REMOVE_ATTACHMENT'
    }
];
