"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OneDomainPerLineValidator = exports.getDomainsList = void 0;
const getDomainsList = (domains) => {
    return domains.replace(/\r\n/g, '\n').split('\n');
};
exports.getDomainsList = getDomainsList;
const OneDomainPerLineValidator = () => {
    const moreThanOneDomain = /.[ ]./;
    return (control) => {
        if (control.value) {
            const domainsList = exports.getDomainsList(control.value.trim());
            return domainsList.find(value => moreThanOneDomain.test(value))
                ? { MULTIPLE_DOMAIN_ON_LINE: false }
                : null;
        }
        else {
            return null;
        }
    };
};
exports.OneDomainPerLineValidator = OneDomainPerLineValidator;
