"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ9 = exports.ɵ8 = exports.ɵ7 = exports.ɵ6 = exports.ɵ5 = exports.ɵ4 = exports.ɵ3 = exports.ɵ2 = exports.ɵ1 = exports.ɵ0 = exports.isLoadingBackup = exports.getBackupDetails = exports.hasErrorLoadingBackupsTable = exports.hasBackupDeleted = exports.openingEditBackup = exports.isDeletingBackup = exports.isUpdatingBackup = exports.isCreatingBackup = exports.hasStatusUpdated = exports.isUpdatingStatus = void 0;
const store_1 = require("@ngrx/store");
const reducers_1 = require("../reducers");
const ɵ0 = (state) => state.backups.isUpdatingStatus;
exports.ɵ0 = ɵ0;
exports.isUpdatingStatus = store_1.createSelector(reducers_1.getOneDriveBackupsState, ɵ0);
const ɵ1 = (state) => state.backups.statusUpdated;
exports.ɵ1 = ɵ1;
exports.hasStatusUpdated = store_1.createSelector(reducers_1.getOneDriveBackupsState, ɵ1);
const ɵ2 = (state) => state.backups.isCreatingBackup;
exports.ɵ2 = ɵ2;
exports.isCreatingBackup = store_1.createSelector(reducers_1.getOneDriveBackupsState, ɵ2);
const ɵ3 = (state) => state.backups.isUpdatingBackup;
exports.ɵ3 = ɵ3;
exports.isUpdatingBackup = store_1.createSelector(reducers_1.getOneDriveBackupsState, ɵ3);
const ɵ4 = (state) => state.backups.isDeletingBackup;
exports.ɵ4 = ɵ4;
exports.isDeletingBackup = store_1.createSelector(reducers_1.getOneDriveBackupsState, ɵ4);
const ɵ5 = (state) => state.backups.openingEditBackup;
exports.ɵ5 = ɵ5;
exports.openingEditBackup = store_1.createSelector(reducers_1.getOneDriveBackupsState, ɵ5);
const ɵ6 = (state) => state.backups.backupDeleted;
exports.ɵ6 = ɵ6;
exports.hasBackupDeleted = store_1.createSelector(reducers_1.getOneDriveBackupsState, ɵ6);
const ɵ7 = (state) => state.backups.errorLoadingBackupsTable;
exports.ɵ7 = ɵ7;
exports.hasErrorLoadingBackupsTable = store_1.createSelector(reducers_1.getOneDriveBackupsState, ɵ7);
const ɵ8 = (state) => state.backups.backupDetails;
exports.ɵ8 = ɵ8;
exports.getBackupDetails = store_1.createSelector(reducers_1.getOneDriveBackupsState, ɵ8);
const ɵ9 = (state) => state.backups.isLoadingBackup;
exports.ɵ9 = ɵ9;
exports.isLoadingBackup = store_1.createSelector(reducers_1.getOneDriveBackupsState, ɵ9);
