"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
angular.module('download-file.directive', [])
    .directive('mcDownloadFile', ['backupService', '$timeout', '$window', 'growl', function (backupService, $timeout, $window, growl) {
        return {
            link($scope, $element, $attr) {
                const endpoint = $scope.$eval($attr.mcDownloadFile);
                const downloadId = $scope.$eval($attr.downloadToken);
                function prepareDownloadId(downloadIdParam) {
                    return downloadIdParam.match(/[^?]*/);
                }
                function handleError() {
                    growl.error('$I18N_COMMON_TEXT_LINE_GLOBAL_ERROR_MESSAGE');
                }
                function createIFrame(attachment) {
                    const iframe = document.createElement('iframe');
                    iframe.style.display = 'none';
                    angular.element($element).parent().append(iframe);
                    iframe.src = attachment;
                }
                $element.on('mousedown', function () {
                    backupService.downloadFile(endpoint + prepareDownloadId(downloadId) + '?forceDownload=true').then(function (response) {
                        if (!response.err) {
                            createIFrame($window.location.origin + response.data);
                        }
                        else {
                            handleError();
                        }
                    }, handleError);
                });
            }
        };
    }]);
