"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductSelectionStepComponent = void 0;
const forms_1 = require("@angular/forms");
const store_1 = require("@ngrx/store");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const reducers_1 = require("../../../../../reducers");
const step_base_component_1 = require("../../../step.base.component");
const model_1 = require("../../../../../model");
const connector_wizard_service_1 = require("../../../../../services/connector-wizard.service");
const connector_actions_1 = require("../../../../../actions/connector.actions");
const change_confirmation_modal_component_1 = require("../../../../change-confirmation-modal/change-confirmation-modal.component");
const productSelectionConfig = require("./product-selection.static-values");
class ProductSelectionStepComponent extends step_base_component_1.StepBaseDirective {
    constructor(fb, store, translateService, modalService, stateService) {
        super(store, fb, model_1.ConnectorStep.PRODUCT, connector_wizard_service_1.ConnectorWizardService.WizardId);
        this.fb = fb;
        this.store = store;
        this.translateService = translateService;
        this.modalService = modalService;
        this.stateService = stateService;
        this.errorType = model_1.ErrorType.PRODUCTS;
        this.destroy$ = new rxjs_1.Subject();
        this.form = this.fb.group({
            product: [null, forms_1.Validators.required]
        });
        this.isLoadingProducts$ = this.store.select(reducers_1.isLoadingProducts);
        this.form.controls['product'].valueChanges.pipe(operators_1.takeUntil(this.destroy$)).subscribe(product => {
            this.currentSelection = product;
            this.previousSelection = this.form.value['product'];
        });
        this.store
            .pipe(operators_1.takeUntil(this.destroy$), store_1.select(reducers_1.getStepData(connector_wizard_service_1.ConnectorWizardService.WizardId, model_1.ConnectorStep.PROVIDER)), operators_1.filter((data) => data), operators_1.map(stepData => stepData.provider))
            .subscribe(provider => (this.selectedProvider = provider));
        this.store
            .pipe(operators_1.takeUntil(this.destroy$), store_1.select(reducers_1.getStepData(connector_wizard_service_1.ConnectorWizardService.WizardId, model_1.ConnectorStep.CONSENT)), operators_1.filter((data) => data), operators_1.map(stepData => !!stepData.authToken))
            .subscribe(consent => (this.hasConsented = consent));
    }
    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
    ngOnInit() {
        super.ngOnInit();
        if (this.editFlow !== true) {
            this.products$ = this.store.select(reducers_1.getProducts);
            this.store.dispatch(new connector_actions_1.LoadProductsAction());
        }
        else {
            const product = Object.assign({}, this.stateService.$state.params.product);
            product.description =
                product && product.name && product.name.toUpperCase().replace(/ /g, '_');
            this.products$ = rxjs_1.of([product]);
            this.form.controls['product'].patchValue(product);
            this.form.controls['product'].disable();
        }
    }
    getProductDescription(productDescription) {
        const translatedDescription = this.translateService.instant(`${productSelectionConfig.translationPrefix}.${productDescription}`);
        return translatedDescription.startsWith(productSelectionConfig.I18N_PREFIX)
            ? this.getGenericProductDescription()
            : translatedDescription;
    }
    getGenericProductDescription() {
        return this.translateService.instant(`${productSelectionConfig.translationPrefix}.${productSelectionConfig.noTranslationAvailable}`);
    }
    loadProducts() {
        this.store.dispatch(new connector_actions_1.LoadProductsAction());
    }
    onProductSelect() {
        if (this.selectedProvider) {
            if (this.hasConsented) {
                this.openChangeConfirmationModal();
            }
            else {
                this.dispatchProductSelectionUpdated();
            }
        }
    }
    openChangeConfirmationModal() {
        const data = {
            changeType: model_1.ChangeType.PRODUCT,
            product: this.form.value['product'],
            provider: this.selectedProvider
        };
        const modalConfig = {};
        modalConfig.size = 'md';
        modalConfig.hasBackdrop = true;
        modalConfig.disableClose = true;
        modalConfig.data = data;
        this.modalService
            .open(change_confirmation_modal_component_1.ChangeConfirmationModalComponent, modalConfig)
            .afterClose()
            .pipe(operators_1.takeUntil(this.destroy$))
            .subscribe((changeConfirmed) => {
            if (changeConfirmed) {
                this.dispatchProductSelectionUpdated();
                this.form.controls['product'].patchValue(this.currentSelection);
            }
            else {
                this.form.controls['product'].patchValue(this.previousSelection);
            }
        });
    }
    dispatchProductSelectionUpdated() {
        this.store.dispatch(new connector_actions_1.ProductSelectionUpdatedAction({
            wizardId: this.wizardId,
            hasConsented: this.hasConsented
        }));
    }
}
exports.ProductSelectionStepComponent = ProductSelectionStepComponent;
