"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogsWidgetComponent = void 0;
const reducers = require("../../reducers");
const logs_widget_static_values_1 = require("./logs-widget.static-values");
const common_util_1 = require("../../utils/common-util");
class LogsWidgetComponent {
    constructor(tabs, store, translateService, stateService) {
        this.tabs = tabs;
        this.store = store;
        this.translateService = translateService;
        this.stateService = stateService;
        this.columns = logs_widget_static_values_1.tableColumns;
        this.showFailureCode = common_util_1.showFailureCode;
    }
    ngOnInit() {
        this.logs$ = this.store.select(reducers.getLogs);
        this.isLogsTableLoading$ = this.store.select(reducers.isLogsTableLoading);
        this.getLogsLoadingError$ = this.store.select(reducers.getLogsLoadingError);
        this.inlineNotificationConfigError = {
            status: 'error',
            msg: this.translateService.instant('$I18N_REMEDIATION.ERRORS.GENERIC')
        };
    }
    getTranslatedActionName(key) {
        const translatedKey = logs_widget_static_values_1.IncidentsActionsDictionary[key];
        const translationRoot = '$I18N_REMEDIATION_LOGS_WIDGET.ACTION.';
        return this.translateService.instant(translationRoot + translatedKey);
    }
    getTranslatedStatusName(key) {
        const translatedKey = logs_widget_static_values_1.IncidentsStatusesDictionary[key];
        const translationRoot = '$I18N_REMEDIATION.STATUS.';
        return this.translateService.instant(translationRoot + translatedKey);
    }
    openLogs() {
        if (this.hasSearchTabEnabled) {
            this.tabs.tabClicked(3);
        }
        else {
            this.tabs.tabClicked(2);
        }
    }
    onCellClick(row) {
        this.stateService.$state.go('remediation-incident', {
            incidentCode: row.incidentCode,
            incidentId: row.incidentId
        });
    }
}
exports.LogsWidgetComponent = LogsWidgetComponent;
