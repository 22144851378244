'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const _ = require("lodash");
angular.module('monitoring.service', [])
    .factory('monitoringService', ['adconCommonsService', '$translate', function (adconCommonsService, $translate) {
        return {
            filterOperations: {
                filterModelController,
                filterBulkModelUpdate,
                applyFilter,
                cancelFilter,
                updateFilterLabel
            }
        };
        function filterModelController(filterModel, id, value) {
            if (value.type !== adconCommonsService.CONSTANTS.DROPDOWN_ITEM_TYPES.LABEL) {
                filterModel.options[value.group] = filterModel.options[value.group] || {};
                if (filterModel.options[value.group][value.key]) {
                    delete filterModel.options[value.group][value.key];
                }
                else {
                    filterModel.options[value.group][value.key] = value;
                }
            }
        }
        function filterBulkModelUpdate(filterModel, optionsModel) {
            filterModel.areAllSelected = !filterModel.areAllSelected;
            if (filterModel.areAllSelected) {
                _.forEach(optionsModel, function (value) {
                    if (value.type !== adconCommonsService.CONSTANTS.DROPDOWN_ITEM_TYPES.LABEL) {
                        filterModel.options[value.group] = filterModel.options[value.group] || {};
                        filterModel.options[value.group][value.key] = value;
                    }
                });
            }
            else {
                filterModel.options = {};
            }
        }
        function applyFilter(payload, currentFilterModel, optionsModel) {
            if (!_.isEmpty(currentFilterModel.options)) {
                _.forEach(currentFilterModel.options, function (value, key) {
                    if (!_.isEmpty(value) && Object.keys(value)) {
                        payload[key] = Object.keys(value);
                    }
                    else {
                        delete payload[key];
                    }
                });
            }
            else {
                _.forEach(optionsModel, function (value) {
                    if (value.type !== adconCommonsService.CONSTANTS.DROPDOWN_ITEM_TYPES.LABEL && payload[value.group]) {
                        delete payload[value.group];
                    }
                });
            }
            updateFilterLabel(currentFilterModel, optionsModel);
        }
        function cancelFilter(currentModel, previousModel) {
            currentModel = angular.copy(previousModel);
        }
        function updateFilterLabel(filterModel, optionsModel) {
            const optionModelKeys = !!optionsModel && _.filter(optionsModel, function (option) {
                return option.type !== 'label';
            }) || [];
            let totalNumberOfItems = 0;
            _.forEach(filterModel.options, function (value) {
                totalNumberOfItems += Object.keys(value).length;
            });
            filterModel.dropdownLabel = (totalNumberOfItems === 0 && $translate.instant('$I18N_ADMINISTRATION_AUDIT_LOGS_NONE_SELECTED')) ||
                (totalNumberOfItems === optionModelKeys.length && $translate.instant('$I18N_ADMINISTRATION_AUDIT_LOGS_ALL_SELECTED')) ||
                (totalNumberOfItems + ' ' + $translate.instant('$I18N_ADMINISTRATION_AUDIT_LOGS_SELECTED'));
        }
    }]);
