"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
(function () {
    'use strict';
    /* ======================================================== *\

     = layout / board simple / directive
     = jcarradinha

     \* ======================================================== */
    document.createElement('mc-layout-board-simple');
    angular.module('layout.board-simple.directive', [])
        .directive('mcLayoutBoardSimple', [function () {
            return {
                restrict: 'E',
                templateUrl: 'components/layout/board-simple/board-simple.tpl.html',
                scope: {
                    mainContainerClass: '@mainContainerClass',
                    headerTitle: '@headerTitle',
                    headerDescription: '@headerDescription'
                },
                transclude: {
                    headerSideContainer: '?headerSideContainer',
                    mainContainer: 'mainContainer'
                }
            };
        }]);
}());
