"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LiveButtonComponent = void 0;
const core_1 = require("@angular/core");
const timeout_decorator_1 = require("../../../app/decorators/timeout.decorator");
class LiveButtonComponent {
    constructor() {
        /**
         * Outputs
         */
        this.mcClick = new core_1.EventEmitter();
    }
    /**
     * When the users clicks the button, the component emits mcClick event
     */
    onClick() {
        this.mcClick.emit({ observableCallback: this.observableCallback.bind(this) });
    }
    /**
     * When we receive an observable we should make the spinner visible
     */
    observableCallback(subscription) {
        this.isLoading = true;
        this.handleSubscription(subscription);
    }
    /**
     * The spinner will run for at least 150ms,
     *    even if the observable returns instantly.
     *
     * When the subscription is completed, we should hide the spinner
     */
    handleSubscription(subscription) {
        subscription.add(() => {
            this.isLoading = false;
        });
    }
}
__decorate([
    timeout_decorator_1.Timeout(150)
], LiveButtonComponent.prototype, "handleSubscription", null);
exports.LiveButtonComponent = LiveButtonComponent;
