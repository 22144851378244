"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttributeBuilder = void 0;
const attribute_1 = require("./attribute");
class AttributeBuilder {
    constructor() {
        this.id = undefined;
    }
    withId(id) {
        this.id = id;
        return this;
    }
    withPrompt(prompt) {
        this.prompt = prompt;
        return this;
    }
    withPromptGroup(promptGroup) {
        this.promptGroup = promptGroup;
        return this;
    }
    withPromptType(promptType) {
        this.promptType = promptType;
        return this;
    }
    withPromptOrder(promptOrder) {
        this.promptOrder = promptOrder;
        return this;
    }
    withPromptOptions(promptOptions) {
        this.promptOptions = promptOptions;
        return this;
    }
    withAllowedInTableViews(allowedInTableViews) {
        this.allowedInTableViews = allowedInTableViews;
        return this;
    }
    build() {
        return new attribute_1.Attribute(this.id, this.prompt, this.promptGroup, this.promptType, this.promptOrder, this.promptOptions, this.allowedInTableViews);
    }
}
exports.AttributeBuilder = AttributeBuilder;
