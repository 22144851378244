"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DashboardService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
class DashboardService {
    constructor(http) {
        this.http = http;
    }
    getDashboardStatistics() {
        return this.http.post('/api/supervision/config/get-stats', null).pipe(operators_1.map((response) => {
            return response;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    getDashboardSettings(queueType) {
        return this.http
            .post('/api/supervision/config/get-settings', {
            queueType
        })
            .pipe(operators_1.map((response) => {
            return response;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    setDashboardSettings(settings) {
        return this.http.post('/api/supervision/config/create-settings', Object.assign({}, settings)).pipe(operators_1.map((response) => {
            return response;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
}
exports.DashboardService = DashboardService;
