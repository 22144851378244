"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccordionPanelComponent = void 0;
const core_1 = require("@angular/core");
class AccordionPanelComponent {
    constructor() {
        this.subTitleDisplay = false;
        this.active = false;
        this.error = false;
        this.toggleAccordion = new core_1.EventEmitter();
        this.accordionClass = '';
    }
    onClickHeader() {
        this.toggleAccordion.emit(this.active);
    }
}
exports.AccordionPanelComponent = AccordionPanelComponent;
