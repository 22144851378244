"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.File = void 0;
const item_1 = require("../item");
class File extends item_1.Item {
    constructor(extension, fullPath, escapedFullPath, deleted, created, modified, size, id, name, lastChangeId, lastChangeType, parentId) {
        super(modified, size, id, name, lastChangeId, lastChangeType, parentId, deleted);
        this.extension = extension;
        this.fullPath = fullPath;
        this.escapedFullPath = escapedFullPath;
        this.created = created;
    }
    getExtension() {
        return this.extension;
    }
    getFullPath() {
        return this.fullPath;
    }
    getEscapedFullPath() {
        return this.escapedFullPath;
    }
    getCreated() {
        return this.created;
    }
}
exports.File = File;
