"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const search_messages_list_component_1 = require("../../components/search-messages-list/search-messages-list.component");
angular
    .module('remediation.search-messages-list', [])
    .controller('RemediationSearchMessagesListController', [
    '$stateParams',
    '$scope',
    function ($stateParams, $scope) {
        $scope.stateParams = $stateParams;
    }
])
    .directive('mcRemediationSearchMessagesList', static_1.downgradeComponent({
    component: search_messages_list_component_1.SearchMessagesListComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider.state('remediation.search-messages', {
            // url: '/remediation/search-messages',
            data: {
                isFixedLayout: false,
                checkProgress: false,
                tabReload: true,
                capabilities: 'Permission.THREAT_REMEDIATION_READ',
                tabTitle: '$I18N_REMEDIATION_CONTAINER.BREADCRUMB.APP_NAME',
                breadcrumbs: [
                    '$I18N_MEGA_MENU_GROUP_SERVICES_LABEL',
                    {
                        label: '$I18N_REMEDIATION_CONTAINER.BREADCRUMB.APP_NAME',
                        stateName: 'remediation',
                        stateParams: { tabName: 'overview' }
                    },
                    'I18N_REMEDIATION_SEARCH_MESSAGES.PAGE_NAME'
                ]
            },
            views: {
                main: {
                    template: '<mc-remediation-search-messages-list></mc-remediation-search-messages-list>',
                    controller: 'RemediationSearchMessagesListController'
                }
            }
        });
    }
]);
