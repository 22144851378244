"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiGatewayKeyGenComponent = void 0;
const reducers = require("../../../reducers");
const actions = require("../../../actions");
class ApiGatewayKeyGenComponent {
    constructor(store, notificationService, translateService, stateService) {
        this.store = store;
        this.notificationService = notificationService;
        this.translateService = translateService;
        this.stateService = stateService;
    }
    ngOnInit() {
        this.application$ = this.store.select(reducers.getApiGatewayKeyGenApplication);
        this.wizardStep$ = this.store.select(reducers.getApiGatewayKeyGenWizardStep);
        this.isBusy$ = this.store.select(reducers.isApiGatewayKeyGenBusy);
        this.isError$ = this.store.select(reducers.isApiGatewayKeyGenError);
        this.errorMessage$ = this.store.select(reducers.getApiGatewayKeyGenErrorMessage);
        this.isVerificationApiError$ = this.store.select(reducers.isVerificationApiError);
        this.errorMessageVerificationApi$ = this.store.select(reducers.getErrorMessageVerificationApi);
        this.account$ = this.store.select(reducers.getApiGatewayKeyGenAccount);
        this.availableAuthenticationTypes$ = this.store.select(reducers.getApiGatewayKeyGenAvailableAuthenticationTypes);
        this.isVerificationRequired$ = this.store.select(reducers.isApiGatewayKeyGenVerificationRequired);
        this.verificationNotification$ = this.store.select(reducers.getApiGatewayKeyGenVerificationNotification);
        this.apiKeys$ = this.store.select(reducers.getApiGatewayKeyGenKeys);
    }
    updateAccount(account) {
        this.store.dispatch(new actions.InputAccountAction(account));
    }
    updateAuthentication(auth) {
        this.store.dispatch(new actions.InputAuthenticationAction(auth));
    }
    updateVerification(verification) {
        this.store.dispatch(new actions.InputVerificationAction(verification));
    }
    keyCopiedToClipboard(message) {
        this.notificationService.success({
            msg: this.translateService.instant(message)
        });
    }
    moveNext() {
        this.store.dispatch(new actions.GoToNextStepAction());
    }
    movePrevious() {
        this.store.dispatch(new actions.GoToPreviousStepAction());
    }
    complete() {
        this.close();
    }
    cancel() {
        this.close();
    }
    close() {
        this.stateService.$state.go('api-applications-list');
    }
    ngOnDestroy() {
        this.store.dispatch(new actions.CloseKeyGenWizardAction());
    }
}
exports.ApiGatewayKeyGenComponent = ApiGatewayKeyGenComponent;
