"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectorsApiService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
class ConnectorsApiService {
    constructor(http) {
        this.http = http;
    }
    findConnectors(request) {
        return this.http
            .post('/api/connectors/find-connectors', Object.assign(Object.assign({}, request.payload), { meta: { pagination: request.pagination } }))
            .pipe(operators_1.map((response) => {
            return response;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    createConnector(data) {
        return this.http.post('/api/connectors/create-connector', data).pipe(operators_1.map((response) => {
            return response;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    deleteConnector(data) {
        return this.http.post('/api/connectors/delete-connector', data).pipe(operators_1.map((response) => {
            return response;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    cancelConsented(id) {
        return this.http.post('/api/connectors/cancel-consent', { id }).pipe(operators_1.map((response) => {
            return response;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    getProducts() {
        return this.http.post('/api/connectors/get-products', null).pipe(operators_1.map((response) => {
            return response;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    getProviders(id) {
        return this.http.post('/api/connectors/get-providers', { data: [{ id }] }).pipe(operators_1.map((response) => {
            return response;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    getConsentConfig(data) {
        return this.http.post('/api/connectors/get-consent-config', data).pipe(operators_1.map((response) => {
            return response;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    updateConnector(payload) {
        return this.http.post('/api/connectors/update-connector', payload).pipe(operators_1.map((response) => {
            return response;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    getConnectorUsage(id) {
        return this.http.post('/api/connectors/check-connector-usage', { id }).pipe(operators_1.map((response) => {
            return response;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    getConnectorDetails(connector) {
        return this.http.post('/api/connectors/get-connector-details', { id: connector.id }).pipe(operators_1.map((response) => {
            return response;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    validateConsent(data) {
        return this.http.post('/api/connectors/validate-consent', data).pipe(operators_1.map((response) => {
            return response;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
}
exports.ConnectorsApiService = ConnectorsApiService;
