"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchMessagesEffects = exports.PAGINATED_SEARCH_DELAY_TIME = exports.PAGINATED_SEARCH_SCHEDULER = void 0;
const core_1 = require("@angular/core");
const effects_1 = require("@ngrx/effects");
const components_1 = require("@mimecast-ui/components");
const moment = require("moment");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const searchAction = require("../../actions/search/search.action");
const form_value_transform_1 = require("../../utils/form-value-transform");
const reducers = require("../../reducers");
exports.PAGINATED_SEARCH_SCHEDULER = new core_1.InjectionToken('Paginated Search Scheduler');
exports.PAGINATED_SEARCH_DELAY_TIME = new core_1.InjectionToken('Paginated Search Delay Time');
class SearchMessagesEffects {
    constructor(actions$, tabChangeService, remediationApiService, notificationService, translateService, stateService, scheduler, delayTime, store) {
        this.actions$ = actions$;
        this.tabChangeService = tabChangeService;
        this.remediationApiService = remediationApiService;
        this.notificationService = notificationService;
        this.translateService = translateService;
        this.stateService = stateService;
        this.scheduler = scheduler;
        this.delayTime = delayTime;
        this.store = store;
        this.search = this.actions$.pipe(effects_1.ofType(searchAction.REMEDIATION_SEARCH_RUN), operators_1.switchMap((action) => {
            let request;
            if (!action.hasSearchWithoutHashPermission) {
                request = Object.assign(Object.assign({}, form_value_transform_1.cleanFormPayload(action.payload)), { start: moment()
                        .startOf('day')
                        .subtract(10, 'years')
                        .format(components_1.API_DATE_FORMAT) });
            }
            else {
                request = Object.assign({}, form_value_transform_1.cleanFormPayload(action.payload));
            }
            return this.remediationApiService.search(request).pipe(operators_1.map((response) => new searchAction.RemediationSearchSuccessAction(response, action.hasSearchWithoutHashPermission)), operators_1.catchError(() => this.searchFailAction()));
        }));
        this.searchSuccess = this.actions$.pipe(effects_1.ofType(searchAction.REMEDIATION_SEARCH_SUCCESS), operators_1.tap((action) => {
            if (action.hasSearchAdvancedPermission) {
                this.tabChangeService.setActiveTab(1);
            }
            else {
                this.stateService.$state.go('remediation.search-messages');
            }
        }));
        this.searchPagination$ = this.actions$.pipe(effects_1.ofType(searchAction.REMEDIATION_SEARCH_PAGINATION), operators_1.delay(this.delayTime || 300, this.scheduler || rxjs_1.asyncScheduler), operators_1.map(() => new searchAction.RemediationSearchPaginationSuccessAction()));
        this.refreshSearchResult$ = this.actions$.pipe(effects_1.ofType(searchAction.REMEDIATE_REFRESH_SEARCH_RESULT), operators_1.withLatestFrom(this.store.select(reducers.getSearchFormPayload), this.store.select(reducers.searchTabPermission)), operators_1.filter(([, , hasSearchPermission]) => hasSearchPermission), operators_1.filter(([, searchPayload]) => form_value_transform_1.isNonEmpty(searchPayload.messageId) ||
            form_value_transform_1.isNonEmpty(searchPayload.subject) ||
            form_value_transform_1.isNonEmpty(searchPayload.from) ||
            form_value_transform_1.isNonEmpty(searchPayload.to) ||
            form_value_transform_1.isNonEmpty(searchPayload.fileHash)), operators_1.map(([, searchPayload, hasSearchPermission]) => {
            if (searchPayload.messageId) {
                return new searchAction.RemediationSearchRunAction(searchPayload, 1, false, hasSearchPermission);
            }
            else {
                return new searchAction.RemediationSearchRunAction(searchPayload, 0, true, hasSearchPermission);
            }
        }));
    }
    searchFailAction() {
        return rxjs_1.of(new searchAction.RemediationSearchFailureAction()).pipe(operators_1.tap(() => {
            this.notificationService.error({
                msg: this.translateService.instant('$I18N_REMEDIATION.ERRORS.GENERIC')
            });
        }));
    }
}
__decorate([
    effects_1.Effect()
], SearchMessagesEffects.prototype, "search", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], SearchMessagesEffects.prototype, "searchSuccess", void 0);
__decorate([
    effects_1.Effect()
], SearchMessagesEffects.prototype, "searchPagination$", void 0);
__decorate([
    effects_1.Effect()
], SearchMessagesEffects.prototype, "refreshSearchResult$", void 0);
exports.SearchMessagesEffects = SearchMessagesEffects;
