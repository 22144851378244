<mc-api-integration-tech-partners-list
  [integrations]="integrations$ | async"
  [isOktaIntegrationEnabled]="isOktaIntegrationEnabled"
  [isAnomaliIntegrationEnabled]="isAnomaliIntegrationEnabled"
  [isOktaEvidenceBasedControlIntegrationEnabled]="isOktaEvidenceBasedControlIntegrationEnabled"
  [isMimecastApiV3Enabled]="isMimecastApiV3Enabled"
  (onGenerateKeys)="generateKeys($event)"
  (onCreateIntegration)="createIntegration($event)"
  (onEditIntegration)="editIntegration($event)"
>
</mc-api-integration-tech-partners-list>
