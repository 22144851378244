"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RouteQuery = exports.DocsQuery = exports.DateQuery = exports.MuseQuery = exports.SentQuery = void 0;
const moment = require("moment");
const components_1 = require("@mimecast-ui/components");
class SentQuery {
    constructor(select, value) {
        this.select = select;
        this.value = value;
    }
    toString() {
        return this.select ? `<sent select="${this.select}">${this.value}</sent>` : '';
    }
}
exports.SentQuery = SentQuery;
class MuseQuery {
    toString() {
        return ('<muse>' +
            (this.text && `<text>${this.text}</text>`) +
            (this.from && new SentQuery('from', this.from).toString()) +
            (this.to && new SentQuery('to', this.to).toString()) +
            (this.date && this.date.toString()) +
            (this.docs && this.docs.toString()) +
            (this.route && this.route.toString()) +
            '</muse>');
    }
}
exports.MuseQuery = MuseQuery;
class DateQuery {
    constructor(select = 'last_month', from, to) {
        this.select = select;
        this.from = from;
        this.to = to;
    }
    toString() {
        if (this.select === 'custom') {
            const from = moment(this.from).format(components_1.API_DATE_FORMAT);
            const to = moment(this.to).format(components_1.API_DATE_FORMAT);
            return `<date select="BETWEEN" from="${from}" to="${to}"></date>`;
        }
        return `<date select="${this.select}"></date>`;
    }
}
exports.DateQuery = DateQuery;
class DocsQuery {
    constructor(select = 'optional', value) {
        this.select = select;
        this.value = value;
    }
    toString() {
        return !!this.value
            ? `<docs select="${this.select}">${this.value}</docs>`
            : `<docs select="${this.select}"></docs>`;
    }
}
exports.DocsQuery = DocsQuery;
class RouteQuery {
    constructor(select = 'any') {
        this.select = select;
    }
    toString() {
        return `<route select="${this.select}"></route>`;
    }
}
exports.RouteQuery = RouteQuery;
