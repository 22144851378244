<div class="mc-list-panel mc-widget-height">
  <div class="mc-list-panel-heading">
    <span>{{ '$I18N_SERVICES_URL_PROTECTION.URL_TOOLS.CHECK_URLS.TITLE' | translate }}</span>
  </div>
  <div class="mc-list-panel-body panel-half-padding-top">
    <form [formGroup]="form" (ngSubmit)="onSave()" class="mc-detailed-list">
      <div class="mc-list-panel-row">
        <mc-text-area-field formControlName="url" data-test="cy-check-url-field"
          (keydown.enter)="preventNewRow($event)"
          (keydown.shift.Enter)="preventNewRow($event)"
          placeholder="$I18N_SERVICES_URL_PROTECTION.URL_TOOLS.CHECK_URLS.INPUT_PLACEHOLDER"
          errorPrefix="$I18N_SERVICES_URL_PROTECTION.URL_TOOLS.CHECK_URLS.INPUT_ERROR_MSG"
          required>
        </mc-text-area-field>
        <div class="form-controls">
          <button
            data-test="cy-clear-url-button"
            (click)="onClear()"
            class="btn btn-default panel-margin-right no-border">{{ '$I18N_SERVICES_URL_PROTECTION.URL_TOOLS.CHECK_URLS.CLEAR_BUTTON_LABEL' | translate }}</button>
          <button data-test="cy-check-url-button" class="btn btn-primary" [disabled]="form.invalid">
            {{ '$I18N_SERVICES_URL_PROTECTION.URL_TOOLS.CHECK_URLS.CHECK_BUTTON_LABEL' | translate }}
          </button>
        </div>
      </div>
    </form>
    <mc-check-url-scan-results
      [relaxed]="relaxed$ | async"
      [moderate]="moderate$ | async"
      [aggressive]="aggressive$ | async"
      [isLoading]="isLoading$ | async"
    ></mc-check-url-scan-results>
  </div>
</div>