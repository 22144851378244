<mc-modal-header
  headerTitle="$I18N_SWG_CREATE_POLICY_DELETE_CONFIRM_HEADER"></mc-modal-header>
<mc-modal-body  *ngIf="policy$ | async; let row">
  <div>{{'$I18N_SWG_CREATE_POLICY_DELETE_CONFIRMATION' | translate}} {{row.policyDesc.description }}?
  </div>
</mc-modal-body>
<mc-modal-footer  *ngIf="policy$ | async; let row">
  <button type="button" class="btn btn-secondary" (click)="onCancel()">
    {{ 'Cancel'}}
  </button>

  <mc-live-button
    overrideClasses="btn-primary"
    [label]="'$I18N_COMMON_BTN_DELETE' | translate"
    [isLoading]="isDeleting$ | async"
    (mcClick)="onDelete(row.policyDesc)">
  </mc-live-button>
</mc-modal-footer>
