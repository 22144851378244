'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const _ = require("lodash");
/**
* Created by iaggarwal on 19/05/2017.
*/
angular.module('archive-task-edit-summary.controller', [])
    .controller('ArchiveTaskEditSummaryController', ['archiveTaskEditService', '$stateParams', '$state',
    function (archiveTaskEditService, $stateParams, $state) {
        const self = this;
        self.inlineNotificationOptions = {};
        self.goToArchiveList = goToArchiveList;
        self.getIncludeSensitiveCheckLabel = getIncludeSensitiveCheckLabel;
        activate();
        function activate() {
            self.TIME_DURATION_ENUM = archiveTaskEditService.CONSTANTS.TIME_DURATION_ENUM;
            self.mailboxConetnt = [];
            if (!_.isEmpty($stateParams.archiveTaskResponse)) {
                notifyTaskCreation('$I18N_BACKUP_RESTORE_ARCHIVE_TASK_SUCCESSFUL_CREATE_MESSAGE_TITLE');
                self.createdArchiveTask = $stateParams.archiveTaskResponse;
                if (!!self.createdArchiveTask.includeMail) {
                    self.mailboxConetnt.push(archiveTaskEditService.CONSTANTS.MAILBOX_CONTENT_MAPPING.MAILS.label);
                }
                if (!!self.createdArchiveTask.includeCalendar) {
                    self.mailboxConetnt.push(archiveTaskEditService.CONSTANTS.MAILBOX_CONTENT_MAPPING.CALENDARS.label);
                }
                if (!!self.createdArchiveTask.includeContacts) {
                    self.mailboxConetnt.push(archiveTaskEditService.CONSTANTS.MAILBOX_CONTENT_MAPPING.CONTACTS.label);
                }
                if (!!self.createdArchiveTask.includeNotes) {
                    self.mailboxConetnt.push(archiveTaskEditService.CONSTANTS.MAILBOX_CONTENT_MAPPING.NOTES.label);
                }
                if (!!self.createdArchiveTask.includeTasks) {
                    self.mailboxConetnt.push(archiveTaskEditService.CONSTANTS.MAILBOX_CONTENT_MAPPING.TASKS.label);
                }
            }
            else {
                goToArchiveList();
            }
        }
        function notifyTaskCreation(messageTitle) {
            self.inlineNotificationOptions.messageBoxAlertTypeClass = 'alert-success';
            self.inlineNotificationOptions.messageBoxIconClass = 'fa-check';
            self.inlineNotificationOptions.messageBoxTitle = messageTitle;
            self.inlineNotificationOptions.messageBoxVisibility = true;
            self.inlineNotificationOptions.messageBoxHideCloseButton = true;
            self.inlineNotificationOptions.messageBoxHideTranscludeContainer = true;
        }
        function goToArchiveList() {
            $state.go('archive-task-list');
        }
        function getIncludeSensitiveCheckLabel(includeSensitive) {
            return !!includeSensitive ? '$I18N_BACKUP_RESTORE_ARCHIVE_TASK_SUMMARY_SECURE_EMAILS_INCLUDE_LABEL' : '$I18N_BACKUP_RESTORE_ARCHIVE_TASK_SUMMARY_SECURE_EMAILS_DO_NOT_INCLUDE_LABEL';
        }
    }]);
