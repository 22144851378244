"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HeaderComponent = void 0;
const core_1 = require("@angular/core");
class HeaderComponent {
    constructor() {
        this.permitSender = new core_1.EventEmitter();
        this.blockSender = new core_1.EventEmitter();
        this.monitorDomain = new core_1.EventEmitter();
        this.selectRow = new core_1.EventEmitter();
        this.isLoading = false;
    }
}
exports.HeaderComponent = HeaderComponent;
