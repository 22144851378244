"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tableSectionSearch = exports.tableColumnsToIgnore = exports.tableColumnsStartHidden = exports.tableSectionFilters = exports.tableColumnsAlwaysVisible = exports.exportColumns = exports.tableColumns = exports.tableSelectColumn = void 0;
exports.tableSelectColumn = 'select';
exports.tableColumns = [
    'select',
    'fromHeader',
    'fromEnv',
    'to',
    'subject',
    'routing',
    'info',
    'created',
    'remoteIp',
    'attachment',
    'size',
    'attempts',
    'nextAttempt',
    'right-column'
];
exports.exportColumns = [
    'fromHdr',
    'fromEnv',
    'to',
    'subject',
    'route',
    'info',
    'created',
    'senderIp',
    'attachments',
    'size',
    'attempts',
    'nextAttempt'
];
exports.tableColumnsAlwaysVisible = [
    'fromHeader',
    'to',
    'subject',
    'info',
    'attempts',
    'created',
    'routing'
];
exports.tableSectionFilters = [
    {
        name: 'route',
        filters: ['inbound', 'outbound', 'internal']
    },
    {
        name: 'attachments',
        filters: ['true', 'false']
    }
];
exports.tableColumnsStartHidden = ['fromEnv', 'remoteIp', 'attachment', 'size', 'nextAttempt'];
exports.tableColumnsToIgnore = ['select'];
exports.tableSectionSearch = {
    field: 'search',
    header: 'header',
    options: ['fromAddress', 'toAddress', 'info', 'remoteIp', 'subject']
};
