"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MepSidebarComponent = void 0;
const core_1 = require("@angular/core");
const table_store_1 = require("@mimecast-ui/table-store");
const policy_actions_1 = require("../../actions/policy.actions");
const sidebar_actions_1 = require("../../actions/sidebar.actions");
class MepSidebarComponent extends table_store_1.TableRowDetailBaseComponent {
    constructor(store, capabilities$) {
        super(store, 'MepPolicyList');
        this.store = store;
        this.capabilities$ = capabilities$;
        this.showHeaders = true;
        this.close = new core_1.EventEmitter();
        this.editPolicy = new core_1.EventEmitter();
        this.policyStatusChange = new core_1.EventEmitter();
        this.hasMepPolicyEditPermission$ = this.capabilities$.hasCapability('Permission.MEP_EDIT');
    }
    ngOnInit() {
        this.isLoading = false;
        this.selectedRowSubscription$ = this.selectedRow$.subscribe(policy => {
            if (policy && policy.id) {
                this.store.dispatch(new policy_actions_1.MepPolicyLoadForEditing(policy.id));
            }
        });
    }
    onDeleteClick(row) {
        this.store.dispatch(new policy_actions_1.MepDetailDeleteConfirmModalOpen(row));
    }
    policyStatusChangeClick() {
        this.store.dispatch(new sidebar_actions_1.MepPolicyEnableConfirmModalOpen(this.policy.id));
    }
    onEditClick(row) {
        this.store.dispatch(new sidebar_actions_1.MepPolicyListSidebarClose());
        this.store.dispatch(new policy_actions_1.MepPolicyLoadForEditing(row.id));
        this.store.dispatch(new policy_actions_1.MepPolicyGoToEditPage());
    }
    isDefault() {
        return !!this.policy.defaultPolicy;
    }
    isPolicyEnabled() {
        return this.policy.enabled;
    }
    ngOnDestroy() {
        this.selectedRowSubscription$.unsubscribe();
    }
}
exports.MepSidebarComponent = MepSidebarComponent;
