"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OktaEvidenceBasedControlEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const reducers = require("app-new/api-applications/reducers");
const actions = require("../../actions");
const notification_actions_1 = require("../../../components/notification/actions/notification.actions");
class OktaEvidenceBasedControlEffects {
    constructor(actions$, stateService, oktaService, translateService, store, windowSerivce) {
        this.actions$ = actions$;
        this.stateService = stateService;
        this.oktaService = oktaService;
        this.translateService = translateService;
        this.store = store;
        this.windowSerivce = windowSerivce;
        this.OktaGroupLimit = 200;
        this.verifyOktaEvidenceBasedControlIntegrationApiKey$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(actions.IntegrationOktaEvidenceBasedControlActionTypes
                .VerifyEvidenceBasedControlIntegrationApiKey), operators_1.switchMap((action) => {
                return this.oktaService
                    .verifyOktaEvidenceBasedControlIntegrationApiKey(action.payload)
                    .pipe(operators_1.map(response => {
                    if (response) {
                        return new actions.VerifyEvidenceBasedControlIntegrationApiKeySuccessAction({
                            success: response,
                            credentials: action.payload
                        });
                    }
                    return new actions.VerifyEvidenceBasedControlIntegrationApiKeyFailureAction();
                }), operators_1.catchError(() => rxjs_1.of(new actions.VerifyEvidenceBasedControlIntegrationApiKeyFailureAction())));
            }));
        });
        this.verifyOktaEvidenceBasedControlIntegrationApiKeyFailure$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(actions.IntegrationOktaEvidenceBasedControlActionTypes
                .VerifyEvidenceBasedControlIntegrationApiKeyFailure), operators_1.map(() => {
                return new notification_actions_1.NotificationShowAction({
                    type: 'error',
                    config: {
                        msg: this.translateService.instant('$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.ACTIVATION.VERIFY_CREDENTIALS_FAIL_NOTIFICATION')
                    }
                });
            }));
        });
        this.verifyOktaEvidenceBasedControlIntegrationApiKeySuccess$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(actions.IntegrationOktaEvidenceBasedControlActionTypes
                .VerifyEvidenceBasedControlIntegrationApiKeySuccess), operators_1.map(() => {
                return new notification_actions_1.NotificationShowAction({
                    type: 'success',
                    config: {
                        msg: this.translateService.instant('$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.ACTIVATION.VERIFY_CREDENTIALS_SUCCESS_NOTIFICATION')
                    }
                });
            }));
        });
        this.createOktaEvidenceBasedControlIntegration$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(actions.IntegrationOktaEvidenceBasedControlActionTypes
                .CreateOktaEvidenceBasedControlIntegration), effects_1.concatLatestFrom(() => [
                this.store.select(reducers.getIntegrationOktaEvidenceBasedControlIntegration),
                this.store.select(reducers.getIntegrationOktaEvidenceBasedControlUserGroups),
                this.store.select(reducers.getIntegrationOktaEvidenceBasedControlNotificationGroups),
                this.store.select(reducers.getIntegrationOktaEvidenceBasedControlNotificationAddresses)
            ]), operators_1.switchMap(([, integration, userGroups, notificationGroups, notificationEmails]) => {
                const payload = this.getIntegrationPayload(integration, userGroups, notificationGroups, notificationEmails);
                return this.oktaService.createIntegration(payload).pipe(operators_1.switchMap(response => {
                    if (!response) {
                        const failureActions = [
                            new actions.CreateOktaEvidenceBasedControlIntegrationFailureAction({}),
                            new notification_actions_1.NotificationShowAction({
                                type: 'error',
                                config: {
                                    msg: this.translateService.instant('$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.ERRORS.CREATE')
                                }
                            })
                        ];
                        return failureActions;
                    }
                    const successActions = [
                        new actions.CreateOktaEvidenceBasedControlIntegrationSuccessAction(response),
                        new notification_actions_1.NotificationShowAction({
                            type: 'success',
                            config: {
                                msg: this.translateService.instant('$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.SUCCESSES.CREATE')
                            }
                        })
                    ];
                    return successActions;
                }));
            }));
        });
        this.cancelOrSuccessOktaEvidenceBasedControlIntegrationWizard$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(actions.IntegrationOktaEvidenceBasedControlActionTypes
                .CancelOktaEvidenceBasedControlIntegrationWizardEnum, actions.IntegrationOktaEvidenceBasedControlActionTypes
                .CreateOktaEvidenceBasedControlIntegrationSuccess, actions.IntegrationOktaEvidenceBasedControlActionTypes
                .UpdateOktaEvidenceBasedControlIntegrationSuccess), operators_1.tap(() => {
                this.stateService.$state.go('api-applications-list', {
                    displayTab: 'integrations',
                    showApiModal: undefined
                });
            }));
        }, { dispatch: false });
        this.searchOktaGroups$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(actions.IntegrationOktaEvidenceBasedControlActionTypes
                .SearchForOktaUserGroupsInOktaEvidenceBasedControlIntegration), effects_1.concatLatestFrom(() => this.store.select(reducers.getIntegrationOktaEvidenceBasedControlIntegration)), operators_1.switchMap(([action, integration]) => {
                const { clientId, privateKey, baseUrl } = integration;
                const payload = {
                    searchPrefix: action.payload,
                    clientId,
                    privateKey,
                    baseUrl,
                    limit: this.OktaGroupLimit
                };
                return this.oktaService.searchForOktaGroups(payload).pipe(operators_1.map(response => {
                    if (response) {
                        return new actions.SearchOktaUserGroupsInOktaEvidenceBasedControlIntegrationSuccessAction(response.foundGroups);
                    }
                    return new actions.SearchOktaUserGroupsInOktaEvidenceBasedControlIntegrationFailureAction({});
                }), operators_1.catchError(() => rxjs_1.of(new actions.SearchOktaUserGroupsInOktaEvidenceBasedControlIntegrationFailureAction({}))));
            }));
        });
        this.verifyAndAddEmailOktaEvidenceBasedControlIntegrationNotifications$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(actions.IntegrationOktaEvidenceBasedControlActionTypes
                .VerifyAndAddEmailOktaEvidenceBasedControlIntegrationNotifications), operators_1.switchMap((action) => {
                return this.oktaService.isInternalEmail(action.payload).pipe(operators_1.map((response) => {
                    if (response) {
                        return new actions.VerifyAndAddEmailOktaEvidenceBasedControlIntegrationNotificationsSuccessAction(action.payload);
                    }
                    return new actions.VerifyAndAddEmailOktaEvidenceBasedControlIntegrationNotificationsFailAction();
                }), operators_1.catchError(() => rxjs_1.of(new actions.VerifyAndAddEmailOktaEvidenceBasedControlIntegrationNotificationsFailAction())));
            }));
        });
        this.verifyAndAddEmailOktaEvidenceBasedControlIntegrationNotificationsFail$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(actions.IntegrationOktaEvidenceBasedControlActionTypes
                .VerifyAndAddEmailOktaEvidenceBasedControlIntegrationNotificationsFail), operators_1.switchMap(() => {
                return [
                    new notification_actions_1.NotificationShowAction({
                        type: 'error',
                        config: {
                            msg: this.translateService.instant('$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.NOTIFICATIONS.API_ERROR.EXTERNAL_EMAIL')
                        }
                    })
                ];
            }));
        });
        this.updateOktaEvidenceBasedControlIntegration$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(actions.IntegrationOktaEvidenceBasedControlActionTypes
                .UpdateOktaEvidenceBasedControlIntegration), effects_1.concatLatestFrom(() => [
                this.store.select(reducers.getIntegrationOktaEvidenceBasedControlIntegration),
                this.store.select(reducers.getIntegrationOktaEvidenceBasedControlUserGroups),
                this.store.select(reducers.getIntegrationOktaEvidenceBasedControlNotificationGroups),
                this.store.select(reducers.getIntegrationOktaEvidenceBasedControlNotificationAddresses)
            ]), operators_1.switchMap(([, integration, userGroups, notificationGroups, notificationEmails]) => {
                const payload = this.getIntegrationPayload(integration, userGroups, notificationGroups, notificationEmails);
                return this.oktaService.updateIntegration(payload).pipe(operators_1.switchMap(response => {
                    if (!response) {
                        return [
                            new actions.UpdateOktaEvidenceBasedControlIntegrationFailureAction({}),
                            new notification_actions_1.NotificationShowAction({
                                type: 'error',
                                config: {
                                    msg: this.translateService.instant('$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.ERRORS.UPDATE')
                                }
                            })
                        ];
                    }
                    return [
                        new actions.UpdateOktaEvidenceBasedControlIntegrationSuccessAction(response),
                        new notification_actions_1.NotificationShowAction({
                            type: 'success',
                            config: {
                                msg: this.translateService.instant('$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.SUCCESSES.UPDATE')
                            }
                        })
                    ];
                }));
            }));
        });
    }
    getIntegrationPayload(integration, userGroups, notificationGroups, notificationAddresses) {
        return Object.assign(Object.assign({}, integration), { appId: this.windowSerivce.appId, userGroups: userGroups.map(gp => gp.id), notificationGroups: Object.keys(notificationGroups), notificationAddresses });
    }
}
exports.OktaEvidenceBasedControlEffects = OktaEvidenceBasedControlEffects;
