<div class="mc-directory-sync-wizard-container">
  <mc-wizard-step-header [label]="'$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.HEADER' | translate: { integrationType: integrationType() }"></mc-wizard-step-header>
    <form
      class="form-horizontal"
      mcDefaultLabelClass="col-sm-3"
      mcDefaultControlContainerClass="col-sm-6"
      [formGroup]="form"
    >

      <mc-field *ngIf="isLdapDominoIntegration()" label="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.DOMINO_FOREIGN_DIRECTORY_SYNCHRONIZATION"
                popoverPlacement="top"
                class="mc-custom-label"
                helpPopoverContent="{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.DOMINO_FOREIGN_DIRECTORY_SYNCHRONIZATION_TOOLTIP' | translate }}"
                required>
        <mc-switch formControlName="foreignDirectorySync" role="switch" showLabel="true"></mc-switch>
      </mc-field>

      <mc-text-field formControlName="host"
                     label="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.HOST"
                     placeholder="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.HOST"
                     popoverPlacement="top"
                     [helpPopoverContent]="hostTooltip"
                     errorPrefix="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.HOST_ERROR"
                     required
      >
      </mc-text-field>

      <div class="mc-field-optional">
        <mc-text-field formControlName="alternateHost"
                       label="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.ALTERNATE_HOST"
                       placeholder="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.ALTERNATE_HOST"
                       popoverPlacement="top"
                       helpPopoverContent="{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.ALTERNATE_HOST_TOOLTIP' | translate }}"
        >
        </mc-text-field>
      </div>

      <mc-field label="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.ENCRYPTION_CONNECTION"
                popoverPlacement="top"
                class="mc-custom-label"
                required
                helpPopoverContent="{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.ENCRYPTION_CONNECTION_TOOLTIP' | translate }}">
        <mc-switch formControlName="encryptionConn" role="switch" showLabel="true"></mc-switch>
      </mc-field>

      <mc-field *ngIf="isEncryptionConnectionEnabled()" label="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.ENCRYPTION_MODE"
                popoverPlacement="top"
                required
                helpPopoverContent="{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.ENCRYPTION_MODE_TOOLTIP' | translate }}">
        <mc-select formControlName="encryptionMode"
                   [options]="encryptionModeOptions"
                   [defaultValue]="form.value.encryptionMode"
        >
        </mc-select>
      </mc-field>

      <mc-number-field label="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.PORT"
                       formControlName="port"
                       helpPopoverContent="{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.PORT_TOOLTIP' | translate }}"
                       popoverPlacement="top"
                       errorPrefix="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.PORT_ERROR"
                       required
      >
      </mc-number-field>

      <mc-text-field formControlName="userDn"
                     label="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.USER_DN"
                     placeholder="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.USER_DN"
                     popoverPlacement="top"
                     [helpPopoverContent]="userDnTooltip"
                     errorPrefix="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.USER_DN_ERROR"
                     required
      >
      </mc-text-field>

      <mc-field label="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.PASSWORD"
        popoverPlacement="top"
        helpPopoverContent="{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.PASSWORD_TOOLTIP' | translate }}"
        errorPrefix="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.PASSWORD_ERROR"
        required
      >
        <input class="form-control"
          type="password"
          name="password"
          placeholder="{{'$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.PASSWORD' | translate}}"
          formControlName="password"
          aria-label="password"
        />
      </mc-field>

      <mc-text-field *ngIf="isLdapADIntegration()" formControlName="rootDn"
                     label="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.ROOT_DN"
                     placeholder="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.ROOT_DN"
                     popoverPlacement="top"
                     helpPopoverContent="{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.ROOT_DN_TOOLTIP' | translate }}"
                     errorPrefix="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.ROOT_DN_ERROR"
                     required
      >
      </mc-text-field>

      <div class="mc-field-optional" *ngIf="isLdapDominoIntegration()">
        <mc-text-field formControlName="rootDn"
                       label="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.ROOT_DN"
                       placeholder="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.ROOT_DN"
                       popoverPlacement="top"
                       helpPopoverContent="{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.DOMINO_ROOT_DN_TOOLTIP' | translate }}"
                       errorPrefix="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.ROOT_DN_ERROR"
        >
        </mc-text-field>
      </div>
    </form>
</div>

<ng-template #hostTooltip>
  <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.HOST_TOOLTIP_1' | translate }}</span>
  <a href="{{ hostTooltipKbLink}}" target="_blank" rel="noopener noreferrer">
    <u><span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.HOST_TOOLTIP_2' | translate }}</span></u>
  </a>
  <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.HOST_TOOLTIP_3' | translate }}</span>
</ng-template>

<ng-template #userDnTooltip>
  <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.USER_DN_TOOLTIP_1' | translate }}</span>
  <a href="{{ this.integration.type === 'domino' ? ldapDominoUserDnTooltipKbLink : ldapADUserDnTooltipKbLink }}" target="_blank" rel="noopener noreferrer">
    <u><span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.USER_DN_TOOLTIP_2' | translate }}</span></u>
  </a>
  <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.USER_DN_TOOLTIP_3' | translate }}</span>
</ng-template>
