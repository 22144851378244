'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("mimecast-web-components/src/app/services/ttp/attachment/attachment"); //api.ttp.attachment
require("app/dashboards/utils/dashboard-commons-service"); //dashboard-commons.service
require("app/components/chart-data-switcher/chart-data-switcher"); //chart-data-switcher
require("./attachment-protection-controller"); //services.services.attachment-protection.controller
require("./charts/attachment-protection-charts-service"); //attachment-protection-charts.service
// html and css
require("./attachment-protection-dashboard.tpl.html");
require("./less/attachment-protection.less");
angular.module('services.services.attachment-protection', [
    'api.ttp.attachment',
    'dashboard-commons.service',
    'chart-data-switcher',
    'services.services.attachment-protection.controller',
    'attachment-protection-charts.service'
])
    .config(['$stateProvider', function ($stateProvider) {
        $stateProvider
            .state('attachment-protection', {
            url: '/services/services/attachment-protection',
            data: {
                tabId: 'attachment-protection-tabid',
                tabTitle: '$I18N_ADMINISTRATION_MENU_ATTACHMENT_PROTECTION',
                tabHide: false,
                tabReload: 'true'
            },
            views: {
                'main': {
                    templateUrl: 'services/services/attachment-protection/attachment-protection-dashboard.tpl.html',
                }
            }
        });
    }]);
