<div class="mc-directory-sync-wizard-container">
  <mc-wizard-step-header [label]="'$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.HEADER' | translate: { integrationType: GSUITE }"></mc-wizard-step-header>
    <form
      class="form-horizontal"
      mcDefaultLabelClass="col-sm-3"
      mcDefaultControlContainerClass="col-sm-8"
      [formGroup]="form"
    >
      <mc-text-field formControlName="email"
                     label="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.EMAIL"
                     placeholder="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.EMAIL"
                     popoverPlacement="top"
                     [helpPopoverContent]="emailTooltip"
                     errorPrefix="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.ERROR"
                     required
      >
      </mc-text-field>
      <mc-field label="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.SERVICE_ACCOUNT_JSON"
                popoverPlacement="top"
                helpPopoverContent="{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.SERVICE_ACCOUNT_JSON_TOOLTIP' | translate }}"
                errorPrefix="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.SERVICE_ACCOUNT_JSON_ERROR"
                required
      >
        <textarea class="form-control"
               name="json"
               placeholder="{{'$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.SERVICE_ACCOUNT_JSON' | translate}}"
               formControlName="json"
               aria-label="json"
               rows="3"
        ></textarea>
      </mc-field>
    </form>
</div>

<ng-template #emailTooltip>
  <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.EMAIL_TOOLTIP_1' | translate }}</span>
  <a href="{{ emailTooltipKbLink }}" target="_blank" rel="noopener noreferrer">
    <u><span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.EMAIL_TOOLTIP_2' | translate }}</span></u>
  </a>
  <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.EMAIL_TOOLTIP_3' | translate }}</span>
</ng-template>

