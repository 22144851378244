"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./aside-controller"); //services.services.backup-aside.controller
// html and css 
require("./aside.tpl.html");
(function () {
    'use strict';
    angular.module('services.services.backup-aside', ['services.services.backup-aside.controller']);
}());
