<mc-table 
    [showHighlightedRow]="false" 
    [data]="tableData"
    [columns]="columns"
    [isLoading]="isLoading"
    [translatePrefix]="translatePrefix"
    (rowClick)="openItem($event)"
>
    <button
        *ngIf="hasBackupEditPermission"
        data-test="create-backup-btn"
        class="mc-table-actions btn btn-primary mc-create-button"
        (click)="createBackup()"
    >
        {{ translatePrefix + '.CREATE_NEW_BACKUP' | translate }}
    </button>
    <mc-filters [metadata]="metaData" [showPagination]="true" (paginatorChange)="onPaginationChange.emit($event)">
        <mc-filter-bundle-filter-by-and-search-by
            placeholder="$I18N_ONEDRIVE_BACKUP_LIST.FILTERS.SEARCH_FIELD_PLACEHOLDER"
            [sections]="sections"
            [searchByFields]="searchByFields"
            [minLengthForSearchField]="minLengthForSearchField"
            [maxLengthForSearchField]="maxLengthForSearchField"
            (filtersChange)="handleFilterChange($event)"
      >
      </mc-filter-bundle-filter-by-and-search-by>
    </mc-filters>

    <mc-column-date key="created"></mc-column-date>

    <mc-column-date key="lastModified"></mc-column-date>

    <mc-column key="group">
        <mc-body-cell *mcBodyCellDef="let row">{{row.backupGroup.description}}</mc-body-cell>
    </mc-column>

    <mc-column key="connector">
        <mc-body-cell *mcBodyCellDef="let row">{{row.connector.name}}</mc-body-cell>
    </mc-column>

    <mc-column key="status">
        
        <mc-body-cell *mcBodyCellDef="let row">
            <mc-onedrive-backup-status [status]="row.enabled ? backupStatus.ENABLE : backupStatus.DISABLE">
            </mc-onedrive-backup-status>
        </mc-body-cell>
    </mc-column>

    <mc-column-actions key="right-column" mcShowColumnConfig [columnsToStartHidden]="columnsToStartHidden"
        [columnsAlwaysVisible]="columnsAlwaysVisible">
        <mc-row-actions *mcRowActions="let row">
            <li 
                *ngIf="hasBackupReadPermission" 
                data-test="backup-table-view-meatball" 
                mcRowAction="$I18N_ONEDRIVE_BACKUP_LIST.ACTIONS.VIEW" 
                (click)="openItem(row)"
            ></li>
            <ng-container *ngIf="hasBackupEditPermission">
                <ng-container *ngIf="row.enabled; else enableMeatballTemplate">
                    <li mcRowAction="$I18N_ONEDRIVE_BACKUP_LIST.ACTIONS.DISABLE" data-test="onedrive-backup-status-disable-meatball" (click)="changeStatus(backupStatus.DISABLE, row.id)">
                    </li>
                </ng-container>
                <ng-template #enableMeatballTemplate>
                    <li mcRowAction="$I18N_ONEDRIVE_BACKUP_LIST.ACTIONS.ENABLE" data-test="onedrive-backup-status-enable-meatball" (click)="changeStatus(backupStatus.ENABLE, row.id)">
                    </li>
                </ng-template>
                <li 
                    data-test="onedrive-backup-edit-meatball"
                    mcRowAction="$I18N_ONEDRIVE_BACKUP_LIST.ACTIONS.EDIT"
                    (click)="editItem(row)"
                ></li>                
                <li 
                    data-test="onedrive-backup-delete-meatball"
                    mcRowAction="$I18N_ONEDRIVE_BACKUP_LIST.ACTIONS.DELETE"
                    (click)="deleteItem(row)"
                ></li>
            </ng-container>
        </mc-row-actions>
    </mc-column-actions>
    <mc-empty-results
        *ngIf="errorLoadingTable; else noBackups"
        iconClass="far fa-exclamation-circle"
    >
        <mc-empty-body 
        >
            {{ '$I18N_ONEDRIVE_BACKUP_LIST.EMPTY_TABLE.LINE_1' | translate }}
            <a
                class="mc-empty-table-link"
                data-test="onedrive-empty-backups-link"
                (click)="onReloadTable.emit()"
            >{{
                '$I18N_ONEDRIVE_BACKUP_LIST.EMPTY_TABLE.LINK' | translate
            }}</a
            >
            {{ '$I18N_ONEDRIVE_BACKUP_LIST.EMPTY_TABLE.LINE_2' | translate }}
        </mc-empty-body>  
    </mc-empty-results>
    <ng-template #noBackups>
        <mc-empty-results
          iconClass="fal fa-hdd"
          keyTitle="$I18N_ONEDRIVE_BACKUPS.NO_BACKUPS.TITLE"
        >
        </mc-empty-results>
    </ng-template>      
</mc-table>