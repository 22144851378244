"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpCheckerSearchComponent = void 0;
const core_1 = require("@angular/core");
class IpCheckerSearchComponent {
    constructor() {
        this.onSearch = new core_1.EventEmitter();
        this.ipCheckerSearchPayload = {};
        this.ipList = [
            {
                label: '1.1.1.1:10',
                value: 'filter1'
            },
            {
                label: '1.1.2.21:89',
                value: 'filter2'
            },
            {
                label: '1.1.1.5:21',
                value: 'filter3'
            },
            {
                label: '1.1.1.8:32',
                value: 'filter4'
            },
            {
                label: '1.2.1.15:10',
                value: 'filter5'
            },
            {
                label: '1.1.4.4:80',
                value: 'filter6'
            }
        ];
        this.portRange = [
            {
                label: '25',
                value: '25'
            },
            {
                label: '110',
                value: '110'
            },
            {
                label: '995',
                value: '995'
            },
            {
                label: '389',
                value: '389'
            },
            {
                label: '636',
                value: '636'
            },
            {
                label: '80',
                value: '80'
            },
            {
                label: '443',
                value: '443'
            }
        ];
    }
    collectIPrange(ipRange) {
        this.ipCheckerSearchPayload.ipRange = ipRange;
    }
    onSelectedValue(value) {
        this.ipCheckerSearchPayload.portRange = value;
    }
    search() {
        if (this.ipCheckerSearchPayload.portRange === undefined) {
            this.ipCheckerSearchPayload.portRange = '';
        }
        this.onSearch.emit(this.ipCheckerSearchPayload);
        // call service
    }
}
exports.IpCheckerSearchComponent = IpCheckerSearchComponent;
