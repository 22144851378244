"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./area-simple-directive"); //layout.area-simple.directive
// html and css 
require("./area-simple.less");
require("./area-simple.tpl.html");
(function () {
    'use strict';
    /* ======================================================== *\

     = layout / area simple
     = jcarradinha

     \* ======================================================== */
    angular.module('layout.area-simple', ['layout.area-simple.directive']);
}());
