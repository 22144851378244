"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgLocationApiService = void 0;
const operators_1 = require("rxjs/operators");
class SwgLocationApiService {
    constructor(http) {
        this.http = http;
    }
    getEgressIps(config) {
        return this.http.post('/api/swg/get-egress-ips', config);
    }
    createEgressIp(webIpRange) {
        return this.http.post('/api/swg/create-egress-ip', webIpRange).pipe(operators_1.map(response => {
            return response.first;
        }));
    }
    updateEgressIp(webIpRange) {
        return this.http.post('/api/swg/update-egress-ip', webIpRange).pipe(operators_1.map(response => {
            return response.first;
        }));
    }
    deleteEgressIp(id) {
        return this.http
            .post('/api/swg/delete-egress-ip', { id })
            .pipe(operators_1.map(response => {
            return response.first;
        }));
    }
}
exports.SwgLocationApiService = SwgLocationApiService;
