'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
/**
 * Created by iaggarwal on 03/03/2017.
 */
angular.module('mc-dropdown-row.directive', [])
    .directive('mcDropdownRow', [function () {
        return {
            restrict: 'AE',
            templateUrl: 'components/mc-dropdown/mc-dropdown-row.tpl.html',
            transclude: true,
            replace: true,
            require: ['^mcDropdown'],
            scope: {
                isDropdownOpenOnClick: '@isDropdownOpenOnClick',
                isHeaderRow: '@isHeaderRow',
                isActive: '=isActive'
            },
            link($scope, $element, $attr, controller) {
                const mcDropdownCtrl = controller[0];
                $scope.applyIsOpenOnClick = applyIsOpenOnClick;
                $scope.stopPropagation = stopPropagation;
                mcDropdownCtrl.autoClose = $scope.isDropdownOpenOnClick ? 'outsideClick' : mcDropdownCtrl.autoClose;
                function stopPropagation($event) {
                    $event.stopPropagation();
                }
                function applyIsOpenOnClick() {
                    mcDropdownCtrl.isOpen = $scope.isDropdownOpenOnClick === 'true';
                }
            },
            controller: ['$scope', function ($scope) {
                    const self = this;
                    self.isHeaderRow = $scope.isHeaderRow === 'true';
                    self.isActive = typeof $scope.isActive === 'boolean' ? $scope.isActive : $scope.isActive === 'true';
                }],
            controllerAs: 'mcDropdownRowCtrl'
        };
    }]);
