"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectorsMigrationEffects = exports.DELAY_TIME = exports.SCHEDULER = void 0;
const core_1 = require("@angular/core");
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const connectors_migration_actions_1 = require("../actions/connectors-migration.actions");
const actions = require("../../../core/actions/app.actions");
const reducers_1 = require("../reducers");
const connector_actions_1 = require("../../connectors/actions/connector.actions");
const moment = require("moment");
exports.SCHEDULER = new core_1.InjectionToken('Scheduler');
exports.DELAY_TIME = new core_1.InjectionToken('Delay Time');
class ConnectorsMigrationEffects {
    constructor(actions$, apiService, store, translate, notifications, stateService, capabilitiesService, cookieService, scheduler, delayTime) {
        this.actions$ = actions$;
        this.apiService = apiService;
        this.store = store;
        this.translate = translate;
        this.notifications = notifications;
        this.stateService = stateService;
        this.capabilitiesService = capabilitiesService;
        this.cookieService = cookieService;
        this.scheduler = scheduler;
        this.delayTime = delayTime;
        this.hasPermissionsAndCaps = rxjs_1.combineLatest([
            this.capabilitiesService.hasCapability('Permission.CONNECTORS_READ'),
            this.capabilitiesService.hasCapability('Permission.SERVER_CONNECTIONS_READ'),
            this.capabilitiesService.hasCapability('Temporary.Connectors.Migration')
        ]).pipe(operators_1.switchMap(([hasConnectorRead, hasServerConnectionsRead, migrationEnabled]) => {
            return rxjs_1.of((hasConnectorRead || hasServerConnectionsRead) && migrationEnabled);
        }));
        this.getConnectorMigrationStatus$ = this.actions$.pipe(effects_1.ofType(connectors_migration_actions_1.ConnectorsMigrationActions.GET_CONNECTOR_MIGRATION_STATUS), operators_1.withLatestFrom(this.hasPermissionsAndCaps), operators_1.switchMap(([, hasPermissionsAndCaps]) => {
            if (hasPermissionsAndCaps) {
                return this.apiService.getConnectorMigrationStatus().pipe(operators_1.switchMap((response) => {
                    return [
                        new connectors_migration_actions_1.GetConnectorMigrationStatusSuccess(response.first.migrationStatus),
                        new connectors_migration_actions_1.DisplayToastToMigrateConnectors()
                    ];
                }), operators_1.catchError(() => {
                    return rxjs_1.of(new connectors_migration_actions_1.GetConnectorMigrationStatusFailure());
                }));
            }
            else {
                return rxjs_1.EMPTY;
            }
        }));
        this.migrateAfterConnectorCreation$ = this.actions$.pipe(effects_1.ofType(connector_actions_1.ConnectorActions.CREATE_CONNECTOR_SUCCESS), operators_1.withLatestFrom(this.store.select(reducers_1.getMigratingConnector), this.hasPermissionsAndCaps), operators_1.switchMap(([action, migratingConnector, hasPermissionsAndCaps]) => {
            if (hasPermissionsAndCaps && migratingConnector) {
                const request = {
                    connectorProductId: migratingConnector.connectorProductId,
                    connectorId: action.connector.id,
                    externalConnectionId: migratingConnector.externalConnectionId
                };
                return this.apiService.migrateConnector(request).pipe(operators_1.switchMap(() => {
                    return [new connectors_migration_actions_1.MigrationComplete(), new connectors_migration_actions_1.GetConnectorMigrationStatus()];
                }), operators_1.catchError(() => {
                    return rxjs_1.of(new connectors_migration_actions_1.MigrationFailed());
                }));
            }
            else {
                return rxjs_1.EMPTY;
            }
        }));
        this.connectorsMigrationStatus$ = this.actions$.pipe(effects_1.ofType(actions.LOAD_SUCCESS), operators_1.map(() => {
            return new connectors_migration_actions_1.GetConnectorMigrationStatus();
        }));
        this.displayToastToMigrateConnectors$ = this.actions$.pipe(effects_1.ofType(connectors_migration_actions_1.ConnectorsMigrationActions.DISPLAY_TOAST_TO_MIGRATE), operators_1.withLatestFrom(this.store.select(reducers_1.getConnectorsToMigrate), this.store.select(reducers_1.getAzureStandardIntegrationsToMigrate)), operators_1.delay(this.delayTime || 1000, this.scheduler || rxjs_1.asyncScheduler), operators_1.tap(([, connectorsToMigrate, azureStandardIntegrationsToMigrate]) => {
            if (!this.cookieService.get('migrationStatusH23') &&
                (connectorsToMigrate > 0 || azureStandardIntegrationsToMigrate > 0)) {
                this.cookieService.set('migrationStatusH23', 'inprogress', moment()
                    .add(1, 'year')
                    .toDate(), '/', '', true);
            }
            if (this.stateService.$state.current.name !== 'administration-dashboard' ||
                (connectorsToMigrate === 0 && azureStandardIntegrationsToMigrate === 0)) {
                return;
            }
            const migrateNow = {
                isAutoClose: true,
                type: 'button',
                label: this.translate.instant('$I18N_CONNECTORS_MIGRATE.TOAST.MIGRATE_NOW'),
                callback: () => {
                    this.stateService.$state.go('services-connectors-page');
                }
            };
            const learnMore = {
                isAutoClose: true,
                type: 'link',
                label: this.translate.instant('$I18N_CONNECTORS_MIGRATE.TOAST.LEARN_MORE'),
                callback: () => {
                    window.open('https://community.mimecast.com/s/article/Migrating-Connectors', '_blank', 'noopener noreferrer');
                }
            };
            this.notifications.warning({
                duration: 0,
                msg: this.buildToastMsg(connectorsToMigrate, azureStandardIntegrationsToMigrate),
                actions: [migrateNow, learnMore]
            });
        }));
    }
    buildToastMsg(connectorsToMigrate, azureStandardIntegrationsToMigrate) {
        let msg;
        if (connectorsToMigrate > 0 && azureStandardIntegrationsToMigrate === 0) {
            msg = `${this.translate.instant('$I18N_CONNECTORS_MIGRATE.TOAST.MESSAGE', {
                connectorsToMigrate
            })}`;
        }
        if (connectorsToMigrate > 0 && azureStandardIntegrationsToMigrate > 0) {
            msg = `
        <strong>${this.translate.instant('$I18N_CONNECTORS_MIGRATE.TOAST.ACTION_NEEDED')}</strong><br><br>
        <ul>
            <li>${this.translate.instant('$I18N_CONNECTORS_MIGRATE.TOAST.SERVER_CONNECTION', {
                connectorsToMigrate
            })}</li>
            <li>${this.translate.instant('$I18N_CONNECTORS_MIGRATE.TOAST.AZURE_INTEGRATIONS')}</li>
        </ul>`;
        }
        if (connectorsToMigrate === 0 && azureStandardIntegrationsToMigrate > 0) {
            msg = `
        <strong>${this.translate.instant('$I18N_CONNECTORS_MIGRATE.TOAST.ACTION_NEEDED')}</strong><br><br>
        <ul>
            <li>${this.translate.instant('$I18N_CONNECTORS_MIGRATE.TOAST.AZURE_INTEGRATIONS')}</li>
        </ul>`;
        }
        return msg;
    }
}
__decorate([
    effects_1.Effect()
], ConnectorsMigrationEffects.prototype, "getConnectorMigrationStatus$", void 0);
__decorate([
    effects_1.Effect()
], ConnectorsMigrationEffects.prototype, "migrateAfterConnectorCreation$", void 0);
__decorate([
    effects_1.Effect()
], ConnectorsMigrationEffects.prototype, "connectorsMigrationStatus$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], ConnectorsMigrationEffects.prototype, "displayToastToMigrateConnectors$", void 0);
exports.ConnectorsMigrationEffects = ConnectorsMigrationEffects;
