"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SecureMessagingPageComponent = void 0;
const actions_1 = require("../actions");
class SecureMessagingPageComponent {
    constructor(store, tabService) {
        this.store = store;
        this.tabService = tabService;
        this.hasSearched = false;
        this.MAX_RESULTS = 5000;
        this.tab = this.tabService.getSelectedTab();
        this.tab.onRefresh = () => {
            this.hasSearched = false;
            this.store.dispatch(new actions_1.CleanSearchAction());
            this.searchBox.clear();
        };
    }
    search(searchValues) {
        this.child.dispatchSearchAction(searchValues);
        this.hasSearched = true;
    }
    ngOnDestroy() {
        this.store.dispatch(new actions_1.CleanSearchAction());
    }
}
exports.SecureMessagingPageComponent = SecureMessagingPageComponent;
