"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiGatewayDeleteConfirmationModalComponent = void 0;
class ApiGatewayDeleteConfirmationModalComponent {
    constructor(overlayRef, apiApp) {
        this.overlayRef = overlayRef;
        this.apiApp = apiApp;
    }
    get appName() {
        return this.apiApp.applicationName;
    }
    close() {
        this.overlayRef.close(false);
    }
    delete() {
        this.overlayRef.close(true);
    }
}
exports.ApiGatewayDeleteConfirmationModalComponent = ApiGatewayDeleteConfirmationModalComponent;
