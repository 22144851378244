"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BiDeletePolicyFailure = exports.BiDeletePolicySuccess = exports.BiDeletePolicy = exports.BiSavePolicyFailure = exports.BiSavePolicySuccess = exports.BiSavePolicy = exports.BiPolicyActionsEnums = void 0;
var BiPolicyActionsEnums;
(function (BiPolicyActionsEnums) {
    BiPolicyActionsEnums["BI_POLICY_SAVE"] = "[BI Policy]BI save Policy";
    BiPolicyActionsEnums["BI_POLICY_SAVE_SUCCESS"] = "[BI Policy]BI save success Policy";
    BiPolicyActionsEnums["BI_POLICY_SAVE_FAILURE"] = "[BI Policy]BI save Failure Policy";
    BiPolicyActionsEnums["BI_POLICY_DELETE"] = "[BI Policy]BI delete Policy";
    BiPolicyActionsEnums["BI_POLICY_DELETE_SUCCESS"] = "[BI Policy]BI delete Policy Success";
    BiPolicyActionsEnums["BI_POLICY_DELETE_FAILURE"] = "[BI Policy]BI delete Policy Failure";
})(BiPolicyActionsEnums = exports.BiPolicyActionsEnums || (exports.BiPolicyActionsEnums = {}));
class BiSavePolicy {
    constructor() {
        this.type = BiPolicyActionsEnums.BI_POLICY_SAVE;
    }
}
exports.BiSavePolicy = BiSavePolicy;
class BiSavePolicySuccess {
    constructor() {
        this.type = BiPolicyActionsEnums.BI_POLICY_SAVE_SUCCESS;
    }
}
exports.BiSavePolicySuccess = BiSavePolicySuccess;
class BiSavePolicyFailure {
    constructor() {
        this.type = BiPolicyActionsEnums.BI_POLICY_SAVE_FAILURE;
    }
}
exports.BiSavePolicyFailure = BiSavePolicyFailure;
class BiDeletePolicy {
    constructor(payload) {
        this.payload = payload;
        this.type = BiPolicyActionsEnums.BI_POLICY_DELETE;
    }
}
exports.BiDeletePolicy = BiDeletePolicy;
class BiDeletePolicySuccess {
    constructor() {
        this.type = BiPolicyActionsEnums.BI_POLICY_DELETE_SUCCESS;
    }
}
exports.BiDeletePolicySuccess = BiDeletePolicySuccess;
class BiDeletePolicyFailure {
    constructor() {
        this.type = BiPolicyActionsEnums.BI_POLICY_DELETE_FAILURE;
    }
}
exports.BiDeletePolicyFailure = BiDeletePolicyFailure;
