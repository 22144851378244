"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SaveAppliesToStepDataAction = exports.SaveJournalStepDataAction = exports.SaveOutboundStepDataAction = exports.SaveInboundStepDataAction = exports.SaveDetailsStepDataAction = exports.GetCustomPageSetOptionsFailAction = exports.GetCustomPageSetOptionsSuccessAction = exports.GetCustomPageSetOptionsAction = exports.SetPolicyStatusEnabled = exports.RemoveJournalAdministratorGroupAction = exports.JournalAdministratorSelectGroup = exports.RemoveOutboundAdministratorGroupAction = exports.OutboundAdministratorSelectGroup = exports.RemoveAdministratorGroupAction = exports.AdministratorSelectGroup = exports.CancelCreatePolicyNoAction = exports.CancelCreatePolicyYesAction = exports.CancelCreatePolicyAction = exports.OpenPoliciesPage = exports.OpenCreatePolicyPage = exports.BypassUrlProtectionPolicy = exports.SET_POLICY_WIZARD_FLOW_TYPE = exports.RESET_POLICY_WIZARD = exports.CREATE_POLICY_FAIL = exports.CREATE_POLICY_SUCCESS = exports.CREATE_POLICY = exports.GET_ADDRESS_ATTRIBUTE_OPTIONS_SUCCESS = exports.GET_ADDRESS_ATTRIBUTE_OPTIONS = exports.SAVE_USER_AWARENESS_STEP_DATA = exports.SAVE_APPLIES_TO_STEP_DATA = exports.SAVE_JOURNAL_STEP_DATA = exports.SAVE_OUTBOUND_STEP_DATA = exports.SAVE_INBOUND_STEP_DATA = exports.SAVE_DETAILS_STEP_DATA = exports.GET_CUSTOM_PAGE_SET_OPTIONS_SUCCESS = exports.GET_CUSTOM_PAGE_SET_OPTIONS_FAIL = exports.GET_CUSTOM_PAGE_SET_OPTIONS = exports.SET_POLICY_STATUS_ENABLED = exports.REMOVE_JOURNAL_ADMINISTRATOR_GROUP = exports.ADD_JOURNAL_ADMINISTRATOR_GROUP = exports.REMOVE_OUTBOUND_ADMINISTRATOR_GROUP = exports.ADD_OUTBOUND_ADMINISTRATOR_GROUP = exports.REMOVE_INBOUND_ADMINISTRATOR_GROUP = exports.ADD_INBOUND_ADMINISTRATOR_GROUP = exports.CANCEL_CREATE_POLICY_CONFIRMATION = exports.CANCEL_CREATE_POLICY_NO_CONFIRMATION = exports.CANCEL_CREATE_POLICY_YES_CONFIRMATION = exports.OPEN_POLICIES_PAGE = exports.OPEN_CREATE_POLICY_PAGE = exports.BYPASS_URL_PROTECTION_POLICY = void 0;
exports.SetPolicyWizardFlowTypeAction = exports.ResetPolicyWizardAction = exports.CreatePolicyFailAction = exports.CreatePolicySuccessAction = exports.CreatePolicyAction = exports.GetAddressAttributeOptionsSuccessAction = exports.GetAddressAttributeOptionsAction = exports.SaveUserAwarenessStepDataAction = void 0;
exports.BYPASS_URL_PROTECTION_POLICY = '[Services / Url protection / Policies] Bypass URL protection policy';
exports.OPEN_CREATE_POLICY_PAGE = '[Services / Url protection / Policies] Open create policy page';
exports.OPEN_POLICIES_PAGE = '[Services / Url protection / Policies] Open policies page';
exports.CANCEL_CREATE_POLICY_YES_CONFIRMATION = '[Services / Url protection / Policies] Cancel policy creation YES confirmation';
exports.CANCEL_CREATE_POLICY_NO_CONFIRMATION = '[Services / Url protection / Policies] Cancel policy creation NO confirmation';
exports.CANCEL_CREATE_POLICY_CONFIRMATION = '[Services / Url protection / Policies] Cancel policy creation confirmation';
exports.ADD_INBOUND_ADMINISTRATOR_GROUP = '[Services / Url protection / Policies] Add Group';
exports.REMOVE_INBOUND_ADMINISTRATOR_GROUP = '[Services / Url protection / Policies] Remove Group';
exports.ADD_OUTBOUND_ADMINISTRATOR_GROUP = '[Services / Url protection / Policies] Add Outbound Group';
exports.REMOVE_OUTBOUND_ADMINISTRATOR_GROUP = '[Services / Url protection / Policies] Remove Outbound Group';
exports.ADD_JOURNAL_ADMINISTRATOR_GROUP = '[Services / Url protection / Policies] Add Journal Group';
exports.REMOVE_JOURNAL_ADMINISTRATOR_GROUP = '[Services / Url protection / Policies] Remove Journal Group';
exports.SET_POLICY_STATUS_ENABLED = '[Services / Url protection / Policies / Summary Step] Set policy status enabled';
exports.GET_CUSTOM_PAGE_SET_OPTIONS = '[Services / Url protection / Policies / User awareness step] Get custom page set options';
exports.GET_CUSTOM_PAGE_SET_OPTIONS_FAIL = '[Services / Url protection / Policies / User awareness step] Get custom page set options - FAIL';
exports.GET_CUSTOM_PAGE_SET_OPTIONS_SUCCESS = '[Services / Url protection / Policies / User awareness step] Get custom page set options - SUCCESS';
exports.SAVE_DETAILS_STEP_DATA = '[Services / Url protection / Policies] Save Details step data';
exports.SAVE_INBOUND_STEP_DATA = '[Services / Url protection / Policies] Save Inbound step data';
exports.SAVE_OUTBOUND_STEP_DATA = '[Services / Url protection / Policies] Save Outbound step data';
exports.SAVE_JOURNAL_STEP_DATA = '[Services / Url protection / Policies] Save Journal step data';
exports.SAVE_APPLIES_TO_STEP_DATA = '[Services / Url protection / Policies] Save Applies To step data';
exports.SAVE_USER_AWARENESS_STEP_DATA = '[Services / Url protection / Policies] Save User Awareness step data';
exports.GET_ADDRESS_ATTRIBUTE_OPTIONS = '[Services / Url protection / Policies / Applies To step] Get custom page set options';
exports.GET_ADDRESS_ATTRIBUTE_OPTIONS_SUCCESS = '[Services / Url protection / Policies / Applies To step] Get custom page set options - SUCCESS';
exports.CREATE_POLICY = '[Services / Url protection / Policies] Create policy';
exports.CREATE_POLICY_SUCCESS = '[Services / Url protection / Policies] Create policy - SUCCESS';
exports.CREATE_POLICY_FAIL = '[Services / Url protection / Policies] Create policy - FAIL';
exports.RESET_POLICY_WIZARD = '[Services / Url protection / Policies] Policy Wizard reset data';
exports.SET_POLICY_WIZARD_FLOW_TYPE = '[Services / Url protection / Policies] Set policy wizard flow type';
class BypassUrlProtectionPolicy {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.BYPASS_URL_PROTECTION_POLICY;
    }
}
exports.BypassUrlProtectionPolicy = BypassUrlProtectionPolicy;
class OpenCreatePolicyPage {
    constructor() {
        this.type = exports.OPEN_CREATE_POLICY_PAGE;
    }
}
exports.OpenCreatePolicyPage = OpenCreatePolicyPage;
class OpenPoliciesPage {
    constructor() {
        this.type = exports.OPEN_POLICIES_PAGE;
    }
}
exports.OpenPoliciesPage = OpenPoliciesPage;
class CancelCreatePolicyAction {
    constructor() {
        this.type = exports.CANCEL_CREATE_POLICY_CONFIRMATION;
    }
}
exports.CancelCreatePolicyAction = CancelCreatePolicyAction;
class CancelCreatePolicyYesAction {
    constructor() {
        this.type = exports.CANCEL_CREATE_POLICY_YES_CONFIRMATION;
    }
}
exports.CancelCreatePolicyYesAction = CancelCreatePolicyYesAction;
class CancelCreatePolicyNoAction {
    constructor() {
        this.type = exports.CANCEL_CREATE_POLICY_NO_CONFIRMATION;
    }
}
exports.CancelCreatePolicyNoAction = CancelCreatePolicyNoAction;
class AdministratorSelectGroup {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.ADD_INBOUND_ADMINISTRATOR_GROUP;
    }
}
exports.AdministratorSelectGroup = AdministratorSelectGroup;
class RemoveAdministratorGroupAction {
    constructor() {
        this.type = exports.REMOVE_INBOUND_ADMINISTRATOR_GROUP;
    }
}
exports.RemoveAdministratorGroupAction = RemoveAdministratorGroupAction;
class OutboundAdministratorSelectGroup {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.ADD_OUTBOUND_ADMINISTRATOR_GROUP;
    }
}
exports.OutboundAdministratorSelectGroup = OutboundAdministratorSelectGroup;
class RemoveOutboundAdministratorGroupAction {
    constructor() {
        this.type = exports.REMOVE_OUTBOUND_ADMINISTRATOR_GROUP;
    }
}
exports.RemoveOutboundAdministratorGroupAction = RemoveOutboundAdministratorGroupAction;
class JournalAdministratorSelectGroup {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.ADD_JOURNAL_ADMINISTRATOR_GROUP;
    }
}
exports.JournalAdministratorSelectGroup = JournalAdministratorSelectGroup;
class RemoveJournalAdministratorGroupAction {
    constructor() {
        this.type = exports.REMOVE_JOURNAL_ADMINISTRATOR_GROUP;
    }
}
exports.RemoveJournalAdministratorGroupAction = RemoveJournalAdministratorGroupAction;
class SetPolicyStatusEnabled {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SET_POLICY_STATUS_ENABLED;
    }
}
exports.SetPolicyStatusEnabled = SetPolicyStatusEnabled;
class GetCustomPageSetOptionsAction {
    constructor() {
        this.type = exports.GET_CUSTOM_PAGE_SET_OPTIONS;
    }
}
exports.GetCustomPageSetOptionsAction = GetCustomPageSetOptionsAction;
class GetCustomPageSetOptionsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_CUSTOM_PAGE_SET_OPTIONS_SUCCESS;
    }
}
exports.GetCustomPageSetOptionsSuccessAction = GetCustomPageSetOptionsSuccessAction;
class GetCustomPageSetOptionsFailAction {
    constructor() {
        this.type = exports.GET_CUSTOM_PAGE_SET_OPTIONS_FAIL;
    }
}
exports.GetCustomPageSetOptionsFailAction = GetCustomPageSetOptionsFailAction;
class SaveDetailsStepDataAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SAVE_DETAILS_STEP_DATA;
    }
}
exports.SaveDetailsStepDataAction = SaveDetailsStepDataAction;
class SaveInboundStepDataAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SAVE_INBOUND_STEP_DATA;
    }
}
exports.SaveInboundStepDataAction = SaveInboundStepDataAction;
class SaveOutboundStepDataAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SAVE_OUTBOUND_STEP_DATA;
    }
}
exports.SaveOutboundStepDataAction = SaveOutboundStepDataAction;
class SaveJournalStepDataAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SAVE_JOURNAL_STEP_DATA;
    }
}
exports.SaveJournalStepDataAction = SaveJournalStepDataAction;
class SaveAppliesToStepDataAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SAVE_APPLIES_TO_STEP_DATA;
    }
}
exports.SaveAppliesToStepDataAction = SaveAppliesToStepDataAction;
class SaveUserAwarenessStepDataAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SAVE_USER_AWARENESS_STEP_DATA;
    }
}
exports.SaveUserAwarenessStepDataAction = SaveUserAwarenessStepDataAction;
class GetAddressAttributeOptionsAction {
    constructor() {
        this.type = exports.GET_ADDRESS_ATTRIBUTE_OPTIONS;
    }
}
exports.GetAddressAttributeOptionsAction = GetAddressAttributeOptionsAction;
class GetAddressAttributeOptionsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_ADDRESS_ATTRIBUTE_OPTIONS_SUCCESS;
    }
}
exports.GetAddressAttributeOptionsSuccessAction = GetAddressAttributeOptionsSuccessAction;
class CreatePolicyAction {
    constructor() {
        this.type = exports.CREATE_POLICY;
    }
}
exports.CreatePolicyAction = CreatePolicyAction;
class CreatePolicySuccessAction {
    constructor() {
        this.type = exports.CREATE_POLICY_SUCCESS;
    }
}
exports.CreatePolicySuccessAction = CreatePolicySuccessAction;
class CreatePolicyFailAction {
    constructor() {
        this.type = exports.CREATE_POLICY_FAIL;
    }
}
exports.CreatePolicyFailAction = CreatePolicyFailAction;
class ResetPolicyWizardAction {
    constructor() {
        this.type = exports.RESET_POLICY_WIZARD;
    }
}
exports.ResetPolicyWizardAction = ResetPolicyWizardAction;
class SetPolicyWizardFlowTypeAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SET_POLICY_WIZARD_FLOW_TYPE;
    }
}
exports.SetPolicyWizardFlowTypeAction = SetPolicyWizardFlowTypeAction;
