'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
angular.module('services.services.backup.service', [])
    .factory('adconBackupService', ['backupService', '$filter', '$translate', 'capabilitiesService', function (backupService, $filter, $translate, capabilitiesService) {
        const TASK_STATUS_OPTIONS = {};
        TASK_STATUS_OPTIONS[backupService.constants.LAST_STATUS.SUCCESS] = {
            value: backupService.constants.LAST_STATUS.SUCCESS,
            text: $translate.instant('$I18N_BACKUP_RESTORE_ARCHIVE_TASK_STATUS_SUCCESS_LABEL'),
            cssClass: 'text-success'
        };
        TASK_STATUS_OPTIONS[backupService.constants.LAST_STATUS.WARNING] = {
            value: backupService.constants.LAST_STATUS.WARNING,
            text: $translate.instant('$I18N_BACKUP_RESTORE_ARCHIVE_TASK_STATUS_WARNING_LABEL'),
            cssClass: 'text-warning'
        };
        TASK_STATUS_OPTIONS[backupService.constants.LAST_STATUS.ERROR] = {
            value: backupService.constants.LAST_STATUS.ERROR,
            text: $translate.instant('$I18N_BACKUP_RESTORE_ARCHIVE_TASK_STATUS_ERROR_LABEL'),
            cssClass: 'text-danger'
        };
        TASK_STATUS_OPTIONS[backupService.constants.LAST_STATUS.PENDING] = {
            value: backupService.constants.LAST_STATUS.PENDING,
            text: $translate.instant('$I18N_BACKUP_RESTORE_ARCHIVE_TASK_STATUS_PENDING_LABEL'),
            cssClass: ''
        };
        TASK_STATUS_OPTIONS[backupService.constants.LAST_STATUS.IN_PROGRESS] = {
            value: backupService.constants.LAST_STATUS.IN_PROGRESS,
            text: $translate.instant('$I18N_BACKUP_RESTORE_ARCHIVE_TASK_STATUS_INPROGRESS_LABEL'),
            cssClass: ''
        };
        const HAS_PERMISSIONS = {
            edit: capabilitiesService.evalCapabilitiesExpression(backupService.constants.SYNC_PERMISSIONS.DATA_SYNC_EDIT + '||' + backupService.constants.SYNC_PERMISSIONS.DATA_SYNC_AND_RECOVER_EDIT),
            read: capabilitiesService.evalCapabilitiesExpression(backupService.constants.SYNC_PERMISSIONS.DATA_SYNC_READ + '||' + backupService.constants.SYNC_PERMISSIONS.DATA_SYNC_AND_RECOVER_READ),
            contentView: capabilitiesService.evalCapabilitiesExpression(backupService.constants.SYNC_PERMISSIONS.DATA_SYNC_SYNC_CONTENT_VIEW + '||' + backupService.constants.SYNC_PERMISSIONS.DATA_SYNC_AND_RECOVER_SYNC_CONTENT_VIEW),
            exportAndRestore: capabilitiesService.hasCapability(backupService.constants.SYNC_PERMISSIONS.DATA_SYNC_AND_RECOVER_EXPORT_AND_RECOVER),
            dataSyncAndRecover: capabilitiesService.hasCapability(backupService.constants.SYNC_PERMISSIONS.SYNC_MAILBOX_ARCHIVE_AND_RECOVER)
        };
        return {
            getStatusStyleAndMessage,
            CONSTANTS: {
                TASK_STATUS_OPTIONS,
                HAS_PERMISSIONS
            }
        };
        function getStatusStyleAndMessage(detailedStatus, status) {
            const statuses = {
                ENABLED: {
                    ENABLED: {
                        text: $translate.instant('$I18N_BACKUP_RESTORE_ARCHIVE_TASK_STATUS_ENABLED_LABEL'),
                        cssClass: 'text-success',
                        value: backupService.constants.STATUS.ENABLED
                    },
                    WARNING: {
                        text: $translate.instant('$I18N_BACKUP_RESTORE_ARCHIVE_TASK_STATUS_ENABLED_WARNING_LABEL'),
                        cssClass: 'text-warning',
                        value: backupService.constants.STATUS.ENABLED
                    },
                    MAILBOX_ERROR: {
                        text: $translate.instant('$I18N_BACKUP_RESTORE_ARCHIVE_TASK_STATUS_ENABLED_MAILBOX_ERROR_LABEL'),
                        cssClass: 'text-danger',
                        value: backupService.constants.STATUS.ENABLED
                    }
                },
                DISABLED: {
                    DISABLED: {
                        text: $translate.instant('$I18N_BACKUP_RESTORE_ARCHIVE_TASK_STATUS_DISABLED_LABEL'),
                        cssClass: 'text-muted',
                        value: backupService.constants.STATUS.DISABLED
                    }
                },
                ERROR: {
                    ERROR: {
                        text: $translate.instant('$I18N_BACKUP_RESTORE_ARCHIVE_TASK_STATUS_FAILED_LABEL'),
                        cssClass: 'text-danger',
                        tooltip: $translate.instant('$I18N_BACKUP_RESTORE_ARCHIVE_TASK_STATUS_FAILED_TOOLTIP'),
                        value: backupService.constants.STATUS.ERROR
                    }
                }
            };
            return statuses[status.toUpperCase()][detailedStatus.toUpperCase()];
        }
    }
]);
