"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BackupListTableComponent = void 0;
const core_1 = require("@angular/core");
const _ = require("lodash");
const models_1 = require("../../../common/models");
const tableConfig = require("./backup-list-table-config");
class BackupListTableComponent {
    constructor(cdref) {
        this.cdref = cdref;
        this.translatePrefix = '$I18N_ONEDRIVE_BACKUP_LIST';
        this.errorLoadingTable = false;
        this.onPaginationChange = new core_1.EventEmitter();
        this.onFilterChange = new core_1.EventEmitter();
        this.onCreateBackup = new core_1.EventEmitter();
        this.onStatusChange = new core_1.EventEmitter();
        this.onRowClick = new core_1.EventEmitter();
        this.onEditBackup = new core_1.EventEmitter();
        this.onBackupDelete = new core_1.EventEmitter();
        this.onReloadTable = new core_1.EventEmitter();
        this._metaData = {};
        this.backupStatus = models_1.BackupStatus;
        this.columns = tableConfig.columnList;
        this.columnsToStartHidden = tableConfig.columnsToStartHidden;
        this.columnsAlwaysVisible = tableConfig.alwaysVisibleColumns;
        this.searchByFields = tableConfig.searchByFields;
        this.sections = tableConfig.sections;
        this.maxLengthForSearchField = tableConfig.maxLengthForSearchField;
        this.minLengthForSearchField = tableConfig.minLengthForSearchField;
    }
    set metaData(metaData) {
        if (metaData) {
            this._metaData = Object.assign(Object.assign({}, metaData), { pagination: Object.assign(Object.assign({}, metaData.pagination), { totalRows: metaData.pagination.totalCount }) });
        }
    }
    get metaData() {
        return this._metaData;
    }
    ngAfterViewInit() {
        this.cdref.detectChanges();
    }
    createBackup() {
        this.onCreateBackup.emit();
    }
    openItem(row) {
        if (this.hasBackupReadPermission) {
            this.onRowClick.emit(row);
        }
    }
    changeStatus(status, id) {
        this.onStatusChange.emit({
            enable: status === models_1.BackupStatus.ENABLE,
            id
        });
    }
    editItem(row) {
        if (this.hasBackupEditPermission) {
            this.onEditBackup.emit(row);
        }
    }
    deleteItem(row) {
        if (this.hasBackupEditPermission) {
            this.onBackupDelete.emit(row);
        }
    }
    handleFilterChange(filter) {
        let emitValue = false;
        if (filter.searchBy !== this.previousSearchValue) {
            emitValue = true;
            this.previousSearchValue = filter.searchBy;
        }
        else if (!_.isEqual(filter.filterBy, this.previousFilterByValue)) {
            emitValue = true;
            this.previousFilterByValue = filter.filterBy;
            if (filter.filterBy && filter.filterBy.length === 2) {
                filter.filterBy = undefined;
            }
        }
        if (emitValue) {
            this.onFilterChange.emit(filter);
        }
    }
}
exports.BackupListTableComponent = BackupListTableComponent;
