"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
angular.module('snapshot-in-time.slide.module', [])
    .controller('SnapshotInTimeController', ['$scope', 'dismissFunction', 'isSnapshotInTimeDisabled', function ($scope, dismissFunction, isSnapshotInTimeDisabled) {
        $scope.save = function () {
            console.log('Saving');
        };
        $scope.dismiss = dismissFunction();
        $scope.isDisabled = isSnapshotInTimeDisabled;
    }]);
