<mc-layout-list-extra-container
  keyTitle="$I18N_TRACK_AND_TRACE_TRACKING_MAIN_CONTAINER.HEADER.TITLE"
  keyDescription="$I18N_TRACK_AND_TRACE_TRACKING_MAIN_CONTAINER.HEADER.SUB_TITLE">
  <mc-help-container>
    <mc-help-link url="https://community.mimecast.com/docs/DOC-2947"></mc-help-link>
  </mc-help-container>
  <mc-extra-container>
    <mc-tracking-main-search
      (search)="onSearch($event)"
      [externalTableSearchCount]="externalTableSearchCount$ | async"
      [isUrlSearchEnabled]="isUrlSearchEnabled$ | async">
    </mc-tracking-main-search>
  </mc-extra-container>
  <mc-body-container>
    <mc-tracking-main-table
      [rows]="searchResult$ | async"
      [externalTableSearchCount]="externalTableSearchCount$ | async"
      [filtersMetadata]="filtersMetadata$ | async"
      [selectedRow]="selectedRow$ | async"
      [isLoading]="isLoading$ | async"
      [errorDataResponse]="errorDataResponse$ | async"
      (rowClick)="onRowClick($event)"
      (reportAs)="reportAs($event)"
      (filtersChange)="onFiltersChange($event)"
      (paginatorChange)="onPaginatorChange($event)"
      (export)="onExport($event)">
    </mc-tracking-main-table>
  </mc-body-container>
</mc-layout-list-extra-container>
