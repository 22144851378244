<mc-layout-aside-extra-container keyTitle="$I18N_IDENTITY_POLICY_DETAILS_SIDEBAR.TITLE"
                                 showClose="true" (closeAside)="close()"
                                 [isLoading]="isLoading$ | async">
  <mc-extra-container>
    <div  *ngIf="policy$ | async; let row">
      <h3 class="policyName">{{ (policy$ | async).name }}</h3>

      <button class="btn btn-primary panel-half-margin-right"
              (click)="editPolicy(row)">
        {{'$I18N_COMMON_BTN_EDIT' | translate}}
      </button>

      <button
        mc-caps="Permission.ID_MANAGEMENT_EDIT"
        class="btn btn-secondary panel-half-margin-right"
        (click)="deletePolicy(row)">
        {{'$I18N_COMMON_BTN_DELETE' | translate}}
      </button>
    </div>
  </mc-extra-container>

  <mc-body-container>
    <mc-identity-policy-details *ngIf="policy$ | async" [policy]="policy$ | async"></mc-identity-policy-details>
  </mc-body-container>

</mc-layout-aside-extra-container>
