"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducer = exports.initialState = void 0;
const syncRecoverOverviewActions = require("../actions/sync-and-recover-overview.action");
const LOADING = true;
const LOADING_COMPLETED = !LOADING;
const CHART_LOADED_FAILED = true;
const CHART_LOADED_SUCCESS = !CHART_LOADED_FAILED;
exports.initialState = {
    isModalOpen: false,
    isLoadingChartData: false,
    chartLoadedFailed: false,
    chartData: {
        healthScore: null,
        syncTasks: null,
        mailboxes: null
    }
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case syncRecoverOverviewActions.SyncAndRecoverOverviewActions.SYNC_RECOVER_OVERVIEW_OPEN_MODAL:
            return Object.assign(Object.assign({}, state), { isModalOpen: true });
        case syncRecoverOverviewActions.SyncAndRecoverOverviewActions.SYNC_RECOVER_OVERVIEW_CLOSE_MODAL:
            return Object.assign(Object.assign({}, state), { isModalOpen: false });
        case syncRecoverOverviewActions.SyncAndRecoverOverviewActions
            .SYNC_RECOVER_OVERVIEW_SET_MODAL_OPEN_STATUS:
            return Object.assign(Object.assign({}, state), { isModalOpen: action.payload });
        case syncRecoverOverviewActions.SyncAndRecoverOverviewActions
            .SYNC_RECOVER_OVERVIEW_LOAD_CHART_DATA:
            return Object.assign(Object.assign({}, state), { isLoadingChartData: LOADING });
        case syncRecoverOverviewActions.SyncAndRecoverOverviewActions
            .SYNC_RECOVER_OVERVIEW_LOAD_CHART_DATA_SUCCESS:
            return Object.assign(Object.assign({}, state), { chartData: action.chartData, isLoadingChartData: LOADING_COMPLETED, chartLoadedFailed: CHART_LOADED_SUCCESS });
        case syncRecoverOverviewActions.SyncAndRecoverOverviewActions
            .SYNC_RECOVER_OVERVIEW_LOAD_CHART_DATA_FAILED:
            return Object.assign(Object.assign({}, state), { isLoadingChartData: LOADING_COMPLETED, chartLoadedFailed: CHART_LOADED_FAILED });
        default:
            return state;
    }
}
exports.reducer = reducer;
