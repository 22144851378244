<button 
  *ngIf="hasBackupEditPermission" 
  class="btn btn-primary mc-create-button" 
  data-test="create-backup-btn" 
  (click)="handleBtnClick()">
  {{ '$I18N_ONEDRIVE_BACKUPS.NO_BACKUPS.CREATE_NEW_BACKUP' | translate }}
</button>
<mc-empty-results data-test="no-backups" iconClass="fal fa-hdd" keyTitle="$I18N_ONEDRIVE_BACKUPS.NO_BACKUPS.TITLE">
  <mc-empty-body>
    <div>
      {{ '$I18N_ONEDRIVE_BACKUPS.NO_BACKUPS.HELP_TEXT' | translate }}
      <a 
        *ngIf="hasBackupEditPermission"
        class="mc-link" 
        data-test="create-backup-link"
        (click)="handleBtnClick()">{{ '$I18N_ONEDRIVE_BACKUPS.NO_BACKUPS.CREATE_A_BACKUP' | translate }}</a>.
    </div>
  </mc-empty-body>
</mc-empty-results>
