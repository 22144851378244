"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RightAsidePanelResizedAction = exports.RIGHT_ASIDE_PANEL_RESIZED = void 0;
exports.RIGHT_ASIDE_PANEL_RESIZED = '[UI] Right Aside Panel Resized';
class RightAsidePanelResizedAction {
    constructor(width) {
        this.width = width;
        this.type = exports.RIGHT_ASIDE_PANEL_RESIZED;
    }
}
exports.RightAsidePanelResizedAction = RightAsidePanelResizedAction;
