"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LexiconService = void 0;
const lexicon_factory_1 = require("app-new/archive/supervision/factories/lexicon.factory");
const table_store_1 = require("@mimecast-ui/table-store");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
class LexiconService {
    constructor(http) {
        this.http = http;
    }
    getData(payload, pagination = table_store_1.defaultPaginationOption) {
        return this.http
            .post('/api/supervision/config/find-lexicons', Object.assign(Object.assign({}, payload), { meta: { pagination } }))
            .pipe(operators_1.map((response) => {
            const RETURN_DATA = {
                hasErrors: response.hasErrors,
                data: response.first.lexicons.map((lexicon) => lexicon_factory_1.LexiconFactory.BUILD_LEXICON(lexicon)),
                failures: response.fail,
                meta: response.meta
            };
            return RETURN_DATA;
        }), operators_1.catchError((response) => {
            const RETURN_DATA = {
                hasErrors: response.hasErrors,
                data: response.all,
                failures: response.fail,
                meta: response.meta
            };
            return rxjs_1.of(RETURN_DATA);
        }));
    }
    filter(filter, pagination) {
        return this.getData(filter, pagination);
    }
    openItem() {
        return rxjs_1.of(undefined);
    }
}
exports.LexiconService = LexiconService;
LexiconService.SUPERVISION_LEXICON_LIST = 'SupervisionLexiconList';
