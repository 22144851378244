"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RemediationDeviceSettingsNotSet = exports.RemediationCreateSettings = exports.RemediationUpdateDeviceSettingsFail = exports.RemediationUpdateDeviceSettingsSuccess = exports.RemediationUpdateDeviceSettings = exports.RemediationUpdateSettingsFail = exports.RemediationUpdateSettingsSuccess = exports.RemediationUpdateSettings = exports.RemediationGetDeviceSettingsFail = exports.RemediationGetDeviceSettingsSuccess = exports.RemediationGetDeviceSettings = exports.RemediationGetSettingsFail = exports.RemediationSettingsNotSet = exports.RemediationGetSettingsSuccess = exports.RemediationGetSettings = exports.DEVICE_SETTINGS_NOT_SET = exports.SETTINGS_NOT_SET = exports.GET_DEVICE_SETTINGS_FAIL = exports.GET_DEVICE_SETTINGS_SUCCESS = exports.GET_DEVICE_SETTINGS = exports.GET_SETTINGS_FAIL = exports.GET_SETTINGS_SUCCESS = exports.CREATE_SETTINGS = exports.UPDATE_DEVICE_SETTINGS_FAIL = exports.UPDATE_DEVICE_SETTINGS_SUCCESS = exports.UPDATE_DEVICE_SETTINGS = exports.UPDATE_SETTINGS_FAIL = exports.UPDATE_SETTINGS_SUCCESS = exports.UPDATE_SETTINGS = exports.GET_SETTINGS = void 0;
exports.GET_SETTINGS = '[Remediation] Retrieve settings';
exports.UPDATE_SETTINGS = '[Remediation] Update settings';
exports.UPDATE_SETTINGS_SUCCESS = '[Remediation] Update settings success';
exports.UPDATE_SETTINGS_FAIL = '[Remediation] Update settings fail';
exports.UPDATE_DEVICE_SETTINGS = '[Remediation] Update Device settings';
exports.UPDATE_DEVICE_SETTINGS_SUCCESS = '[Remediation] Update Device settings success';
exports.UPDATE_DEVICE_SETTINGS_FAIL = '[Remediation] Update Device settings fail';
exports.CREATE_SETTINGS = '[Remediation] Create settings';
exports.GET_SETTINGS_SUCCESS = '[Remediation] Retrieved settings success';
exports.GET_SETTINGS_FAIL = '[Remediation] Retrieved settings fail';
exports.GET_DEVICE_SETTINGS = '[Remediation] Retrieve device settings';
exports.GET_DEVICE_SETTINGS_SUCCESS = '[Remediation] Retrieved device settings success';
exports.GET_DEVICE_SETTINGS_FAIL = '[Remediation] Retrieved device settings fail';
exports.SETTINGS_NOT_SET = '[Remediation] Settings not set';
exports.DEVICE_SETTINGS_NOT_SET = '[Remediation] Device Settings not set';
class RemediationGetSettings {
    constructor() {
        this.type = exports.GET_SETTINGS;
    }
}
exports.RemediationGetSettings = RemediationGetSettings;
class RemediationGetSettingsSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_SETTINGS_SUCCESS;
    }
}
exports.RemediationGetSettingsSuccess = RemediationGetSettingsSuccess;
class RemediationSettingsNotSet {
    constructor() {
        this.type = exports.SETTINGS_NOT_SET;
    }
}
exports.RemediationSettingsNotSet = RemediationSettingsNotSet;
class RemediationGetSettingsFail {
    constructor() {
        this.type = exports.GET_SETTINGS_FAIL;
    }
}
exports.RemediationGetSettingsFail = RemediationGetSettingsFail;
class RemediationGetDeviceSettings {
    constructor() {
        this.type = exports.GET_DEVICE_SETTINGS;
    }
}
exports.RemediationGetDeviceSettings = RemediationGetDeviceSettings;
class RemediationGetDeviceSettingsSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_DEVICE_SETTINGS_SUCCESS;
    }
}
exports.RemediationGetDeviceSettingsSuccess = RemediationGetDeviceSettingsSuccess;
class RemediationGetDeviceSettingsFail {
    constructor() {
        this.type = exports.GET_DEVICE_SETTINGS_FAIL;
    }
}
exports.RemediationGetDeviceSettingsFail = RemediationGetDeviceSettingsFail;
class RemediationUpdateSettings {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.UPDATE_SETTINGS;
    }
}
exports.RemediationUpdateSettings = RemediationUpdateSettings;
class RemediationUpdateSettingsSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.UPDATE_SETTINGS_SUCCESS;
    }
}
exports.RemediationUpdateSettingsSuccess = RemediationUpdateSettingsSuccess;
class RemediationUpdateSettingsFail {
    constructor() {
        this.type = exports.UPDATE_SETTINGS_FAIL;
    }
}
exports.RemediationUpdateSettingsFail = RemediationUpdateSettingsFail;
class RemediationUpdateDeviceSettings {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.UPDATE_DEVICE_SETTINGS;
    }
}
exports.RemediationUpdateDeviceSettings = RemediationUpdateDeviceSettings;
class RemediationUpdateDeviceSettingsSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.UPDATE_DEVICE_SETTINGS_SUCCESS;
    }
}
exports.RemediationUpdateDeviceSettingsSuccess = RemediationUpdateDeviceSettingsSuccess;
class RemediationUpdateDeviceSettingsFail {
    constructor() {
        this.type = exports.UPDATE_DEVICE_SETTINGS_FAIL;
    }
}
exports.RemediationUpdateDeviceSettingsFail = RemediationUpdateDeviceSettingsFail;
class RemediationCreateSettings {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.CREATE_SETTINGS;
    }
}
exports.RemediationCreateSettings = RemediationCreateSettings;
class RemediationDeviceSettingsNotSet {
    constructor() {
        this.type = exports.DEVICE_SETTINGS_NOT_SET;
    }
}
exports.RemediationDeviceSettingsNotSet = RemediationDeviceSettingsNotSet;
