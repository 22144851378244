"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GatewayComponent = void 0;
const operators_1 = require("rxjs/operators");
const reducers = require("../../../reducers");
const actions = require("../../../actions");
class GatewayComponent {
    constructor(store, stateService, capabilitiesService) {
        this.store = store;
        this.stateService = stateService;
        this.capabilitiesService = capabilitiesService;
    }
    ngOnInit() {
        this.application$ = this.store.select(reducers.getApiGatewayV3ApiApplication);
        this.applicationIdentifier$ = this.store.select(reducers.getApiGatewayV3Identifier);
        this.applicationAcceptTerms$ = this.store.select(reducers.getApiGatewayV3AcceptTerms);
        this.applicationDetails$ = this.store.select(reducers.getApiGatewayV3Details);
        this.applicationSettings$ = this.store.select(reducers.getApiGatewayV3Settings);
        this.applicationStatus$ = this.store.select(reducers.getApiGatewayV3Status);
        this.applicationNameDisabled$ = this.store.select(reducers.getApiGatewayApplicationV3NameDisabled);
        this.mode$ = this.store.select(reducers.getApiGatewayV3Mode);
        this.mode$
            .pipe(operators_1.first(), operators_1.tap(mode => (this.editing = mode === 'EDIT')))
            .subscribe();
    }
    updateAcceptTerms($event) {
        this.store.dispatch(new actions.InputGatewayApplicationAcceptTermsAction($event));
    }
    updateDetails($event) {
        this.store.dispatch(new actions.InputGatewayApplicationDetailsAction($event));
    }
    updateSettings($event) {
        this.store.dispatch(new actions.InputGatewayApplicationSettingsAction($event));
    }
    updateStatus($event) {
        this.store.dispatch(new actions.InputGatewayApplicationStatusAction($event));
    }
    save($event) {
        if (this.editing) {
            this.store.dispatch(new actions.UpdateGatewayApplicationAction($event));
        }
        else {
            this.capabilitiesService
                .hasCapability('Temporary.Services.ApiApplications.Apigee.Mimecast.All.Categories')
                .subscribe(allowed => {
                if (allowed === true) {
                    this.showCategories = true;
                }
                else {
                    this.showCategories = false;
                }
            });
            const payload = Object.assign(Object.assign(Object.assign({}, $event), (!this.showCategories && { category: 'other' })), { service: false, usage: 'gateway' });
            this.store.dispatch(new actions.CreateGatewayApplicationAction(payload));
        }
    }
    close() {
        this.stateService.$state.go('api-applications-list');
    }
    ngOnDestroy() {
        this.store.dispatch(new actions.CloseGatewayApplicationAction());
    }
}
exports.GatewayComponent = GatewayComponent;
