"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getValidatedConsent = exports.isConsentValidationFailed = exports.isValidatingConsent = exports.getConnectorUsage = exports.loadingConnectorUsage = exports.getAsidePagination = exports.loadingConnectorDetails = exports.connectorDetails = exports.selectedRow = exports.getFormResets = exports.getConsentConfig = exports.getProviders = exports.getProducts = exports.isLoadingConsentConfig = exports.isLoadingProviders = exports.isLoadingProducts = exports.isDeletingConnector = exports.reducer = void 0;
const lodash_1 = require("lodash");
const connector_actions_1 = require("../actions/connector.actions");
const model_1 = require("../model");
const moment = require("moment");
const initialState = {
    isDeletingConnector: false,
    isLoadingProducts: false,
    isLoadingProviders: false,
    isLoadingConsentConfig: false,
    isValidatingConsent: false,
    isConsentValidationFailed: false,
    products: [],
    providers: [],
    consentConfig: null,
    formResets: {
        provider: false,
        consent: false,
        details: false
    },
    selectedRow: null,
    connectorDetails: null,
    loadingConnectorDetails: false,
    asidePagination: null,
    loadingConnectorUsage: false,
    connectorUsage: null,
    validatedConsent: {}
};
function reducer(state = initialState, action) {
    switch (action.type) {
        case connector_actions_1.ConnectorActions.LOAD_PRODUCTS:
            return Object.assign(Object.assign({}, state), { isLoadingProducts: true });
        case connector_actions_1.ConnectorActions.LOAD_PRODUCTS_SUCCESS:
            return Object.assign(Object.assign({}, state), { isLoadingProducts: false, products: sortProducts(action.payload) });
        case connector_actions_1.ConnectorActions.LOAD_PRODUCTS_FAILURE:
            return Object.assign(Object.assign({}, state), { isLoadingProducts: false, products: [] });
        case connector_actions_1.ConnectorActions.LOAD_PROVIDERS:
            return Object.assign(Object.assign({}, state), { isLoadingProviders: true });
        case connector_actions_1.ConnectorActions.LOAD_PROVIDERS_SUCCESS:
            return Object.assign(Object.assign({}, state), { isLoadingProviders: false, providers: action.payload });
        case connector_actions_1.ConnectorActions.LOAD_PROVIDERS_FAILURE:
            return Object.assign(Object.assign({}, state), { isLoadingProviders: false, providers: [] });
        case connector_actions_1.ConnectorActions.GET_CONSENT_CONFIG:
            return Object.assign(Object.assign({}, state), { isLoadingConsentConfig: true, isConsentValidationFailed: false });
        case connector_actions_1.ConnectorActions.GET_CONSENT_CONFIG_SUCCESS:
            return Object.assign(Object.assign({}, state), { isLoadingConsentConfig: false, consentConfig: action.payload });
        case connector_actions_1.ConnectorActions.GET_CONSENT_CONFIG_FAILURE:
            return Object.assign(Object.assign({}, state), { isLoadingConsentConfig: false, consentConfig: {} });
        case connector_actions_1.ConnectorActions.DELETE_CONNECTOR:
            return Object.assign(Object.assign({}, state), { isDeletingConnector: true });
        case connector_actions_1.ConnectorActions.DELETE_CONNECTOR_SUCCESS:
            return Object.assign(Object.assign({}, state), { isDeletingConnector: false });
        case connector_actions_1.ConnectorActions.DELETE_CONNECTOR_FAIL:
            return Object.assign(Object.assign({}, state), { isDeletingConnector: false });
        case connector_actions_1.ConnectorActions.GET_CONNECTOR_USAGE:
            return Object.assign(Object.assign({}, state), { loadingConnectorUsage: true, connectorUsage: null });
        case connector_actions_1.ConnectorActions.GET_CONNECTOR_USAGE_SUCCESS:
            return Object.assign(Object.assign({}, state), { loadingConnectorUsage: false, connectorUsage: action.payload });
        case connector_actions_1.ConnectorActions.GET_CONNECTOR_USAGE_FAILURE:
            return Object.assign(Object.assign({}, state), { loadingConnectorUsage: false, connectorUsage: null });
        case connector_actions_1.ConnectorActions.RESET_STEP_FORMS:
            const { stepIds } = action.payload;
            return Object.assign(Object.assign({}, state), { formResets: Object.assign(Object.assign({}, state.formResets), { provider: containsId(model_1.ConnectorStep.PROVIDER, stepIds) || false, consent: containsId(model_1.ConnectorStep.CONSENT, stepIds) || false, details: containsId(model_1.ConnectorStep.DETAILS, stepIds) || false }) });
        case connector_actions_1.ConnectorActions.GET_CONNECTOR_DETAILS:
            return Object.assign(Object.assign({}, state), { selectedRow: action.payload, connectorDetails: null, loadingConnectorDetails: true });
        case connector_actions_1.ConnectorActions.GET_CONNECTOR_DETAILS_SUCCESS:
            const humanReadableDate = 'DD MMM YYYY - HH:mm';
            const createdDate = moment(action.payload.created).format(humanReadableDate);
            return Object.assign(Object.assign({}, state), { loadingConnectorDetails: false, connectorDetails: Object.assign(Object.assign({}, action.payload), { created: createdDate }) });
        case connector_actions_1.ConnectorActions.GET_CONNECTOR_DETAILS_FAIL:
            return Object.assign(Object.assign({}, state), { loadingConnectorDetails: false, connectorDetails: null });
        case connector_actions_1.ConnectorActions.SET_ASIDE_PAGINATION:
            return Object.assign(Object.assign({}, state), { asidePagination: action.payload });
        case connector_actions_1.ConnectorActions.UNSET_SELECTED_ROW:
            return Object.assign(Object.assign({}, state), { selectedRow: null });
        case connector_actions_1.ConnectorActions.VALIDATE_CONSENT:
            return Object.assign(Object.assign({}, state), { isValidatingConsent: true, isConsentValidationFailed: false, validatedConsent: {} });
        case connector_actions_1.ConnectorActions.VALIDATE_CONSENT_SUCCESS:
            return Object.assign(Object.assign({}, state), { isValidatingConsent: false, validatedConsent: { authToken: action.payload.authToken } });
        case connector_actions_1.ConnectorActions.VALIDATE_CONSENT_FAILURE:
            return Object.assign(Object.assign({}, state), { isValidatingConsent: false, isConsentValidationFailed: true, validatedConsent: { errorCode: action.payload.errorCode } });
        default:
            return state;
    }
}
exports.reducer = reducer;
const isDeletingConnector = (state) => state && state.isDeletingConnector;
exports.isDeletingConnector = isDeletingConnector;
const isLoadingProducts = (state) => state && state.isLoadingProducts;
exports.isLoadingProducts = isLoadingProducts;
const isLoadingProviders = (state) => state && state.isLoadingProviders;
exports.isLoadingProviders = isLoadingProviders;
const isLoadingConsentConfig = (state) => state && state.isLoadingConsentConfig;
exports.isLoadingConsentConfig = isLoadingConsentConfig;
const getProducts = (state) => state && state.products;
exports.getProducts = getProducts;
const getProviders = (state) => state && state.providers;
exports.getProviders = getProviders;
const getConsentConfig = (state) => state && state.consentConfig;
exports.getConsentConfig = getConsentConfig;
const getFormResets = (state) => state && state.formResets;
exports.getFormResets = getFormResets;
const selectedRow = (state) => state && state.selectedRow;
exports.selectedRow = selectedRow;
const connectorDetails = (state) => state && state.connectorDetails;
exports.connectorDetails = connectorDetails;
const loadingConnectorDetails = (state) => state && state.loadingConnectorDetails;
exports.loadingConnectorDetails = loadingConnectorDetails;
const getAsidePagination = (state) => state && state.asidePagination;
exports.getAsidePagination = getAsidePagination;
const loadingConnectorUsage = (state) => state && state.loadingConnectorUsage;
exports.loadingConnectorUsage = loadingConnectorUsage;
const getConnectorUsage = (state) => state && state.connectorUsage;
exports.getConnectorUsage = getConnectorUsage;
const isValidatingConsent = (state) => state && state.isValidatingConsent;
exports.isValidatingConsent = isValidatingConsent;
const isConsentValidationFailed = (state) => state && state.isConsentValidationFailed;
exports.isConsentValidationFailed = isConsentValidationFailed;
const getValidatedConsent = (state) => state && state.validatedConsent;
exports.getValidatedConsent = getValidatedConsent;
function sortProducts(products) {
    return lodash_1.sortBy(products, product => product.name);
}
function containsId(stepId, stepIds) {
    return !!stepIds.find(id => id === stepId);
}
