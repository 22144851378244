"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IdentityCancelPolicyModalComponent = void 0;
const policy_1 = require("../../models/policy");
const policyWizardActions = require("../../actions/policy-wizard/wizard.actions");
class IdentityCancelPolicyModalComponent {
    constructor(store, translateService, cancelSource) {
        this.store = store;
        this.translateService = translateService;
        this.cancelSource = cancelSource;
    }
    getModalTitle() {
        if (this.cancelSource === policy_1.PolicyCancelModalSource.EDIT_POLICY) {
            return this.translateService.instant('$I18N_IDENTITY_CANCEL_EDIT_POLICY_CONFIRMATION');
        }
        else {
            return this.translateService.instant('$I18N_IDENTITY_CANCEL_CREATE_POLICY_CONFIRMATION');
        }
    }
    onYesResponse() {
        this.store.dispatch(new policyWizardActions.CancelModalYesAction());
    }
    onNoResponse() {
        this.store.dispatch(new policyWizardActions.CancelModalNoAction());
    }
}
exports.IdentityCancelPolicyModalComponent = IdentityCancelPolicyModalComponent;
