<div class="mc-services-connector-wizard-step-container">

  <mc-wizard-step-header [label]="providerConsentStepTitle">
  </mc-wizard-step-header>

  <mc-provider-icon [providerType]="provider.type"></mc-provider-icon>

  <p>{{ '$I18N_ACCESS_PROVIDER_MS.DESCRIPTION' | translate: { provider: providerName } }}</p>
  <p>{{ '$I18N_ACCESS_PROVIDER_MS.NOTIFICATION' | translate }}</p>

  <button
    class="btn btn-primary"
    [disabled]="(permissonGranted$ | async) || editFlow"
    (click)="loadConsentConfig()"
  >
    {{ '$I18N_ACCESS_PROVIDER_MS.LOGIN' | translate }}
  </button>


  <div class="consent">
    <p *ngIf="(permissonGranted$ | async) || editFlow">
      <span class="fas fa-check-circle"></span>
      <strong>{{ '$I18N_ACCESS_PROVIDER_MS.PERMISSIONS_GRANTED' | translate }}</strong>
    </p>
    <p *ngIf="permissonNotGranted$ | async">
      <span class="fas fa-exclamation-circle"></span>
      <strong>{{ '$I18N_ACCESS_PROVIDER_MS.ERRORS.' + errorCode | uppercase | translate: { provider: providerName } }}</strong>
      <strong *ngIf="isTechnicalSupportError()">
        <a data-test="technical-support-link"
          [href]="supportLink$ | async" target="_blank" rel="noopener noreferrer">{{ '$I18N_ACCESS_PROVIDER_MS.ERRORS.CONTACT_SUPPORT' | translate }}</a>.
      </strong>
    </p>
  </div>

</div>

