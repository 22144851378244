"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ToolbarComponent = void 0;
const core_1 = require("@angular/core");
const models_1 = require("../../../models");
class ToolbarComponent {
    constructor(capService) {
        this.capService = capService;
        this.selectRow = new core_1.EventEmitter();
        this.release = new core_1.EventEmitter();
        this.releaseToSandbox = new core_1.EventEmitter();
        this.permitForRecipient = new core_1.EventEmitter();
        this.reject = new core_1.EventEmitter();
        this.rejectNotifySender = new core_1.EventEmitter();
        this.rejectWithNarrative = new core_1.EventEmitter();
        this.blockForRecipient = new core_1.EventEmitter();
        this.printRecipient = new core_1.EventEmitter();
        this.retry = new core_1.EventEmitter();
        this.rejectQueued = new core_1.EventEmitter();
        this.earlyBounce = new core_1.EventEmitter();
        this.recalculateDeliveryRoute = new core_1.EventEmitter();
        this.selectEmail = new core_1.EventEmitter();
        this.forward = new core_1.EventEmitter();
        this.reportAs = new core_1.EventEmitter();
        this.messageCenterView = false;
    }
    ngOnInit() {
        this.capService.ifCapability('Permission.MANAGED_SENDERS_EDIT', () => (this.messageCenterView = true));
    }
    get deliveredEmails() {
        return (this.messageInfo &&
            this.messageInfo.deliveredMessage &&
            Object.keys(this.messageInfo.deliveredMessage).map(email => ({
                type: 'delivery',
                owner: email
            })));
    }
    get recipientFromEmail() {
        return (this.messageInfo &&
            this.messageInfo.recipientInfo &&
            this.messageInfo.recipientInfo.messageInfo &&
            this.messageInfo.recipientInfo.messageInfo.fromHeader && {
            type: 'receipt',
            owner: this.messageInfo.recipientInfo.messageInfo.fromHeader
        });
    }
    get selectedEmailLabel() {
        const selectEmailType = this.selectedEmail && this.selectedEmail.type;
        const selectEmailOwner = this.selectedEmail && this.selectedEmail.owner;
        if (selectEmailType && selectEmailOwner) {
            return selectEmailType === 'delivery'
                ? '<strong>Delivered View</strong> - ' + selectEmailOwner
                : '<strong>Received View</strong> - ' + selectEmailOwner;
        }
    }
    get contentViewCap() {
        return models_1.CONTENT_VIEW_CAPS[this.messageInfo.status];
    }
    showViewButton() {
        const validStatuses = [
            'accepted',
            'archived',
            'bounced',
            'held',
            'processing',
            'delivery'
        ];
        return validStatuses.indexOf(this.messageInfo.status) !== -1;
    }
    showRecipientButtons() {
        return ((this.messageInfo.status === 'accepted' &&
            this.messageInfo.recipientInfo &&
            this.messageInfo.recipientInfo.messageInfo &&
            this.messageInfo.recipientInfo.messageInfo.route === 'inbound') ||
            (this.messageInfo.status === 'archived' &&
                this.selectedDeliveredMessage &&
                this.selectedDeliveredMessage.messageInfo &&
                this.selectedDeliveredMessage.messageInfo.route === 'inbound' &&
                this.messageCenterView) ||
            ((this.messageInfo.status === 'deferred' || this.messageInfo.status === 'rejected') &&
                this.messageInfo.queueInfo &&
                this.messageInfo.queueInfo.manageRecipient));
    }
    showReleaseButton() {
        const validStatuses = ['held'];
        return validStatuses.indexOf(this.messageInfo.status) !== -1;
    }
    showRejectButton() {
        const validStatuses = [
            'held',
            'processing',
            'bulk_processing',
            'delivery',
            'bulk_delivery'
        ];
        return validStatuses.indexOf(this.messageInfo.status) !== -1;
    }
    showPendingDeliveryButtons() {
        const validStatuses = [
            'processing',
            'bulk_processing',
            'delivery',
            'bulk_delivery'
        ];
        return validStatuses.indexOf(this.messageInfo.status) !== -1;
    }
    showSelectEmailDropDown() {
        const invalidStatuses = ['rejected', 'deferred'];
        return invalidStatuses.indexOf(this.messageInfo.status) === -1;
    }
    showReportButton() {
        const validStatuses = ['accepted', 'archived', 'bounced', 'held'];
        return validStatuses.indexOf(this.messageInfo.status) !== -1;
    }
    checkThreatIdentifiedHeldReasons() {
        if (!!this.messageInfo && !!this.messageInfo.queueInfo) {
            return this.messageInfo.queueInfo.reason.toLowerCase() === 'threat identified';
        }
    }
    enableReportButton() {
        return this.checkThreatIdentifiedHeldReasons();
    }
    enableRejectButton() {
        return this.checkThreatIdentifiedHeldReasons();
    }
    enableReleaseButton() {
        return this.checkThreatIdentifiedHeldReasons();
    }
    showActionButton() {
        const validStatuses = ['accepted', 'archived', 'bounced', 'held'];
        return validStatuses.indexOf(this.messageInfo.status) !== -1;
    }
    compareFn(value1, value2) {
        return value1 && value2 && value1.type === value2.type && value1.owner === value2.owner;
    }
    trackByFn(index) {
        return index;
    }
}
exports.ToolbarComponent = ToolbarComponent;
