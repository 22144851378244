"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SessionApiService = void 0;
class SessionApiService {
    constructor(http) {
        this.http = http;
    }
    logout() {
        return this.http.post('/api/login/logout', { skipRedirect: true });
    }
}
exports.SessionApiService = SessionApiService;
