"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStepData = exports.isStepValid = exports.getCurrentStepState = exports.getStepById = exports.getCurrentStep = exports.getWizardById = exports.reducer = exports.initialState = void 0;
const wizard_actions_1 = require("../actions/wizard.actions");
exports.initialState = {};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case wizard_actions_1.WizardActions.UPDATE_STEP:
            return updateStep(state, action);
        case wizard_actions_1.WizardActions.SET_CURRENT_STEP:
            return Object.assign(Object.assign({}, state), { [action.payload.wizardId]: Object.assign(Object.assign({}, state[action.payload.wizardId]), { currentStep: action.payload.stepId }) });
        case wizard_actions_1.WizardActions.RESTORE_WIZARD_STATE:
            return Object.assign(Object.assign({}, state), { [action.payload.wizardId]: action.payload.wizardState });
        case wizard_actions_1.WizardActions.CLEAR_WIZARD_STATE:
            return Object.assign(Object.assign({}, state), { [action.payload.wizardId]: undefined });
        case wizard_actions_1.WizardActions.CLEAR_WIZARD_STEPS:
            return clearSteps(state, action);
        default:
            return Object.assign({}, state);
    }
}
exports.reducer = reducer;
function updateStep(state, action) {
    const wizardState = state[action.payload.wizardId] || {};
    const wizardSteps = wizardState.steps || {};
    const newState = Object.assign(Object.assign({}, state), { [action.payload.wizardId]: Object.assign(Object.assign({}, wizardState), { steps: Object.assign(Object.assign({}, wizardSteps), { [action.payload.stepId]: Object.assign(Object.assign({}, wizardSteps[action.payload.stepId]), action.payload.stepState) }) }) });
    return newState;
}
function clearSteps(state, action) {
    const { wizardId, stepIds } = action.payload;
    const wizardState = state[wizardId] || {};
    const wizardSteps = wizardState.steps || {};
    const clearedSteps = stepIds.reduce((acc, stepId) => {
        return Object.assign(Object.assign({}, acc), { [stepId]: {
                valid: false,
                data: {}
            } });
    }, Object.assign({}, wizardSteps));
    const newState = Object.assign(Object.assign({}, state), { [action.payload.wizardId]: Object.assign(Object.assign({}, wizardState), { steps: clearedSteps }) });
    return newState;
}
const getWizardById = (id) => (state) => state[id];
exports.getWizardById = getWizardById;
const getCurrentStep = (state) => state && state.currentStep;
exports.getCurrentStep = getCurrentStep;
const getStepById = (stepId) => (state) => state && state.steps && state.steps[stepId];
exports.getStepById = getStepById;
const getCurrentStepState = (state) => state && state.steps && state.steps[state.currentStep];
exports.getCurrentStepState = getCurrentStepState;
const isStepValid = (state) => state && state.valid;
exports.isStepValid = isStepValid;
const getStepData = (state) => state && state.data;
exports.getStepData = getStepData;
