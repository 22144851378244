"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasGenericError = exports.selectedRowsSearchTable = exports.isLoading = exports.remediateMessages = exports.reducer = exports.initialState = void 0;
const remediateMessagesAction = require("../../actions/remediate-messages/remediate-messages.action");
exports.initialState = {
    isLoading: false,
    genericError: false,
    selectedRowsSearchTable: []
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case remediateMessagesAction.REMEDIATE_MESSAGES:
            return Object.assign(Object.assign({}, state), { isLoading: true, genericError: false });
        case remediateMessagesAction.REMEDIATION_SELECTED_TABLE_ROWS:
            return Object.assign(Object.assign({}, state), { selectedRowsSearchTable: action.payload });
        case remediateMessagesAction.REMEDIATE_MESSAGES_SUCCESS:
            return Object.assign(Object.assign({}, state), { isLoading: false, genericError: false });
        case remediateMessagesAction.REMEDIATE_MESSAGES_FAILURE:
            return Object.assign(Object.assign({}, state), { isLoading: false, genericError: true });
        default:
            return state;
    }
}
exports.reducer = reducer;
const remediateMessages = (state) => state;
exports.remediateMessages = remediateMessages;
const isLoading = (state) => state.isLoading;
exports.isLoading = isLoading;
const selectedRowsSearchTable = (state) => state.selectedRowsSearchTable;
exports.selectedRowsSearchTable = selectedRowsSearchTable;
const hasGenericError = (state) => state.genericError;
exports.hasGenericError = hasGenericError;
