<mc-layout-aside-extra-container
  keyTitle="{{ '$I18N_ARCHIVE_SUPERVISION_SELECT_QUEUE.RULE.SELECT_QUEUE' | translate }}"
  showClose="true"
  [isLoading]="false"
  (closeAside)="onClose()"
>
  <mc-body-container>
    <div class="row">
      <div class="col-sm-12">
        <input
          #searchQueue
          type="text"
          class="form-control search-queue"
          (input)="onSearchChange($event)"
          [placeholder]="
            '$I18N_ARCHIVE_SUPERVISION_SELECT_QUEUE.RULE.SEARCH_PLACEHOLDER' | translate
          "
        />
      </div>

      <div class="col-sm-12">
        <mc-loader-full-container
          [isLoading]="loading"
          class="mc-layout-loader-full-container-aside mc-flex-scroll-vertical"
        >
          <div *ngIf="loading" style="height: 100px;"></div>
        </mc-loader-full-container>

        <div *ngFor="let queue of sortedQueues">
          <button
            class="btn-link"
            [class.selected]="isQueueSelected(queue)"
            (click)="selectQueue(queue)"
          >
            {{ queue.name }}
          </button>
        </div>

        <div *ngIf="noQueuesFound" class="no-queues">
          {{ '$I18N_ARCHIVE_SUPERVISION_SELECT_QUEUE.RULE.NO_QUEUES_FOUND' | translate }}
        </div>
      </div>
    </div>
  </mc-body-container>
</mc-layout-aside-extra-container>
