"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewImSyncTaskContainerComponent = void 0;
const selectors = require("../../reducers/index");
const components_1 = require("@mimecast-ui/components");
const update_im_sync_task_container_component_1 = require("../update-im-sync-task/update-im-sync-task.container.component");
class ViewImSyncTaskContainerComponent {
    constructor(store, asideService) {
        this.store = store;
        this.asideService = asideService;
        this.imTask$ = this.store.select(selectors.getImTaskDetails);
        this.isLoading$ = this.store.select(selectors.getImTaskLoading);
    }
    openUpdateSidebar() {
        const asideConfig = new components_1.AsideConfig();
        asideConfig.size = 'lg';
        asideConfig.position = 'right';
        this.asideService.open(update_im_sync_task_container_component_1.UpdateImSyncTaskContainerComponent, asideConfig);
    }
}
exports.ViewImSyncTaskContainerComponent = ViewImSyncTaskContainerComponent;
