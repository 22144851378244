"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgDeviceGroupsListEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const notification_actions_1 = require("../../../../components/notification/actions/notification.actions");
const device_groups_list_actions_1 = require("../../actions/device-groups-list.actions");
const reducers = require("../../reducers");
class SwgDeviceGroupsListEffects {
    constructor(actions$, store, translationService, deviceGroupsListApiService, stateService) {
        this.actions$ = actions$;
        this.store = store;
        this.translationService = translationService;
        this.deviceGroupsListApiService = deviceGroupsListApiService;
        this.stateService = stateService;
        this.getGroupDevicesList$ = this.actions$.pipe(effects_1.ofType(device_groups_list_actions_1.SwgDeviceGroupsListActions.GET_GROUP_DEVICES_LIST), operators_1.switchMap((action) => {
            return this.deviceGroupsListApiService.getDevicesInGroup(action.payload).pipe(operators_1.map((devices) => {
                return new device_groups_list_actions_1.SwgGetGroupDevicesListSuccessAction(devices);
            }), operators_1.catchError(error => rxjs_1.merge(rxjs_1.of(this.getNotificationAction(error)), rxjs_1.of(new device_groups_list_actions_1.SwgGetGroupDevicesListFailureAction()))));
        }));
        this.navigateToWizard = this.actions$.pipe(effects_1.ofType(device_groups_list_actions_1.SwgDeviceGroupsListActions.NAVIGATE_TO_DEVICE_GROUP_WIZARD), operators_1.tap(() => {
            this.stateService.$state.go('device-group-wizard');
        }));
        this.pageDevicesInGroup$ = this.actions$.pipe(effects_1.ofType(device_groups_list_actions_1.SwgDeviceGroupsListActions.PAGE_DEVICES_IN_GROUP), operators_1.delay(200, rxjs_1.asyncScheduler), operators_1.map(() => new device_groups_list_actions_1.SwgPageDevicesInGroupCompletedAction()));
        this.filterDevicesInGroup$ = this.actions$.pipe(effects_1.ofType(device_groups_list_actions_1.SwgDeviceGroupsListActions.FILTER_DEVICES_IN_GROUP), operators_1.delay(200, rxjs_1.asyncScheduler), operators_1.map(() => new device_groups_list_actions_1.SwgFilterDevicesInGroupCompletedAction()));
        this.createDeviceGroup$ = this.actions$.pipe(effects_1.ofType(device_groups_list_actions_1.SwgDeviceGroupsListActions.CREATE_DEVICE_GROUP), operators_1.withLatestFrom(this.store.select(reducers.getDeviceGroup)), operators_1.switchMap(([, deviceGroup]) => {
            return this.deviceGroupsListApiService.createDeviceGroup(deviceGroup).pipe(operators_1.mergeMap(() => {
                const msg = deviceGroup.id
                    ? '$I18N_DEVICE_GROUPS_LIST.WIZARD.GROUP_EDIT_SUCCESS'
                    : '$I18N_DEVICE_GROUPS_LIST.WIZARD.GROUP_CREATE_SUCCESS';
                this.stateService.$state.go('secure-web-gateway-endpoints-list', {
                    displayTab: 'device-groups-list'
                });
                return [
                    new notification_actions_1.NotificationShowAction({
                        type: 'success',
                        config: {
                            msg
                        }
                    }),
                    new device_groups_list_actions_1.SwgCreateDeviceGroupSuccessAction()
                ];
            }), operators_1.catchError(error => rxjs_1.merge(rxjs_1.of(this.getNotificationAction(error)), rxjs_1.of(new device_groups_list_actions_1.SwgCreateDeviceGroupFailureAction()))));
        }));
        this.navigateToDeviceGroupList = this.actions$.pipe(effects_1.ofType(device_groups_list_actions_1.SwgDeviceGroupsListActions.NAVIGATE_TO_DEVICE_GROUP_LIST), operators_1.tap(() => {
            this.stateService.$state.go('secure-web-gateway-endpoints-list', {
                displayTab: 'device-groups-list'
            });
        }));
        this.loadDeviceGroupToEdit$ = this.actions$.pipe(effects_1.ofType(device_groups_list_actions_1.SwgDeviceGroupsListActions.LOAD_DEVICE_GROUP_TO_EDIT), operators_1.delay(200, rxjs_1.asyncScheduler), operators_1.map(() => new device_groups_list_actions_1.SwgLoadDeviceGroupToEditSuccessAction()));
    }
    getNotificationAction(error) {
        const msg = error.fail
            ? error.fail[0].errors[0].message
            : this.translationService.instant('$I18N_SWG_CREATE_POLICY_SERVER_ERROR');
        return new notification_actions_1.NotificationShowAction({
            type: 'error',
            config: {
                msg
            }
        });
    }
}
__decorate([
    effects_1.Effect()
], SwgDeviceGroupsListEffects.prototype, "getGroupDevicesList$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], SwgDeviceGroupsListEffects.prototype, "navigateToWizard", void 0);
__decorate([
    effects_1.Effect()
], SwgDeviceGroupsListEffects.prototype, "pageDevicesInGroup$", void 0);
__decorate([
    effects_1.Effect()
], SwgDeviceGroupsListEffects.prototype, "filterDevicesInGroup$", void 0);
__decorate([
    effects_1.Effect()
], SwgDeviceGroupsListEffects.prototype, "createDeviceGroup$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], SwgDeviceGroupsListEffects.prototype, "navigateToDeviceGroupList", void 0);
__decorate([
    effects_1.Effect()
], SwgDeviceGroupsListEffects.prototype, "loadDeviceGroupToEdit$", void 0);
exports.SwgDeviceGroupsListEffects = SwgDeviceGroupsListEffects;
