<mc-layout-detail-tabs keyTitle="{{ '$I18N_ONEDRIVE_DRIVE_BROWSER.HEADER.TITLE' | translate:{ driveUserName: getName(), driveUserMail: getMail() } }}"
  keyDescription="$I18N_ONEDRIVE_DRIVE_BROWSER.HEADER.DESCRIPTION" extendClass="mc-onedrive-drives-detail"
  kbUrl="https://community.mimecast.com/docs/DOC-2704">

  <mc-tab-group [initialActiveTabIndex]="getDriveShowTab()" (tabChange)="initializeSelectedTab($event)">

    <mc-tab name="$I18N_ONEDRIVE_DRIVE_BROWSER.TABS.LASTSYNC.LABEL">

      <mc-onedrive-tree *ngIf="getDriveShowContent()" [contents]="driveContents$ | async" [columns]="contentColumns"
        [loadingContents]="driveContentsLoading$ | async" [nodeStack]="nodeStack" [rootFolder]="driveRootFolder$ | async"
        [viewType]="viewType" (onNodeClicked)="nodeClicked($event)">
      </mc-onedrive-tree>

      <mc-empty-results *ngIf="!getDriveShowContent()" iconClass="far fa-hdd" keyTitle="{{ getDriveEmptyResultMessage().title }}">
        <mc-empty-body>{{ getDriveEmptyResultMessage().description | translate }}</mc-empty-body>
      </mc-empty-results>

    </mc-tab>

    <mc-tab name="$I18N_ONEDRIVE_DRIVE_BROWSER.TABS.ARCHIVE.LABEL">

      <mc-onedrive-tree *ngIf="getDriveShowContent()" [contents]="driveContents$ | async" [columns]="contentColumns"
        [loadingContents]="driveContentsLoading$ | async" [nodeStack]="nodeStack" [rootFolder]="driveRootFolder$ | async"
        [viewType]="viewType" (onNodeClicked)="nodeClicked($event)">
      </mc-onedrive-tree>

      <mc-empty-results *ngIf="!getDriveShowContent()" iconClass="far fa-hdd" keyTitle="{{ getDriveEmptyResultMessage().title }}">
        <mc-empty-body>{{ getDriveEmptyResultMessage().description | translate }}</mc-empty-body>
      </mc-empty-results>

    </mc-tab>

    <mc-tab name="$I18N_ONEDRIVE_DRIVE_BROWSER.TABS.DELETED_FILES.LABEL">

      <mc-onedrive-tree *ngIf="getDriveShowContent()" [contents]="driveContents$ | async" [columns]="deletedColumns"
        [loadingContents]="driveContentsLoading$ | async" [nodeStack]="nodeStack" [rootFolder]="driveRootFolder$ | async"
        [viewType]="viewType" (onNodeClicked)="nodeClicked($event)">
      </mc-onedrive-tree>

      <mc-empty-results *ngIf="!getDriveShowContent()" iconClass="far fa-hdd" keyTitle="{{ getDriveEmptyResultMessage().title }}">
        <mc-empty-body>{{ getDriveEmptyResultMessage().description | translate }}</mc-empty-body>
      </mc-empty-results>

    </mc-tab>

    <mc-tab name="$I18N_ONEDRIVE_DRIVE_BROWSER.TABS.SUMMARY.LABEL">

      <div class="panel-body">

        <div class="mc-detailed-list">

          <div class="row mc-detailed-list-row">
            <div class="col-xs-2 mc-detailed-list-label">{{ '$I18N_ONEDRIVE_DRIVE_BROWSER.TABS.SUMMARY.CONTENT.LAST_RESULT' | translate }}</div>
            <div class="mc-detailed-list-column">
              <strong [ngClass]="getLastStatus().displayClasses">{{ getLastStatus().displayValue | translate }}</strong>
              <span *ngIf="getLastStatus().statusMessage"> - {{ getLastStatus().statusMessage | translate }}</span>
            </div>
          </div>
          <div class="row mc-detailed-list-row">
            <div class="col-xs-2 mc-detailed-list-label">{{ '$I18N_ONEDRIVE_DRIVE_BROWSER.TABS.SUMMARY.CONTENT.TASK_NAME' | translate }}</div>
            <div class="mc-detailed-list-column"> {{ getTaskName() }} </div>
          </div>
          <div class="row mc-detailed-list-row">
            <div class="col-xs-2 mc-detailed-list-label">{{ '$I18N_ONEDRIVE_DRIVE_BROWSER.TABS.SUMMARY.CONTENT.NAME' | translate }}</div>
            <div class="mc-detailed-list-column"> {{ getName() }} </div>
          </div>
          <div class="row mc-detailed-list-row">
            <div class="col-xs-2 mc-detailed-list-label">{{ '$I18N_ONEDRIVE_DRIVE_BROWSER.TABS.SUMMARY.CONTENT.DRIVE' | translate }}</div>
            <div class="mc-detailed-list-column"> {{ getDrive() }} </div>
          </div>
          <div class="row mc-detailed-list-row">
            <div class="col-xs-2 mc-detailed-list-label">{{ '$I18N_ONEDRIVE_DRIVE_BROWSER.TABS.SUMMARY.CONTENT.FIRST_SYNC' | translate }}</div>
            <div class="mc-detailed-list-column"> {{ getFirstSync() | mcDate }} </div>
          </div>
          <div class="row mc-detailed-list-row">
            <div class="col-xs-2 mc-detailed-list-label">{{ '$I18N_ONEDRIVE_DRIVE_BROWSER.TABS.SUMMARY.CONTENT.LAST_SYNC' | translate }}</div>
            <div class="mc-detailed-list-column"> {{ getLastSync() | mcDate }} </div>
          </div>
          <div class="row mc-detailed-list-row">
            <div class="col-xs-2 mc-detailed-list-label">{{ '$I18N_ONEDRIVE_DRIVE_BROWSER.TABS.SUMMARY.CONTENT.QUOTA_USED' | translate }}</div>
            <div class="mc-detailed-list-column"> {{ getQuotaUsed() | mcToBytes: sizeConfig }} </div>
          </div>

        </div>

      </div>

    </mc-tab>

  </mc-tab-group>

  <mc-layout-detail-tabs>
