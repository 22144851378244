"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BackupBrowserComponent = void 0;
const models_1 = require("app-new/onedrive/common/models");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const actions_1 = require("../../actions");
const selectors_1 = require("../../selectors");
const delete_backup_modal_component_1 = require("../delete-backup-modal/delete-backup-modal.component");
const update_backup_status_spinner_modal_component_1 = require("../update-backup-status-spinner-modal/update-backup-status-spinner-modal.component");
class BackupBrowserComponent {
    constructor(stateService, fb, store, capabilitiesManagerService, modalService) {
        this.stateService = stateService;
        this.fb = fb;
        this.store = store;
        this.capabilitiesManagerService = capabilitiesManagerService;
        this.modalService = modalService;
        this.backupStatus = models_1.BackupStatus;
        this.updatingStatusSpinner = null;
        this.destroy$ = new rxjs_1.Subject();
        this.hasBackupEditPermission$ = this.capabilitiesManagerService.hasOnedriveBackupEditPermission();
        this.openingEditBackup$ = this.store.select(selectors_1.openingEditBackup);
        this.backup = this.stateService.$state.params.backup;
        this.getExcludedFiles();
        this.form = this.fb.group({ status: [this.backup.enabled] });
    }
    get statusToggle() {
        return this.form.get('status');
    }
    ngOnInit() {
        this.statusToggle.valueChanges.subscribe(status => {
            if (status !== this.backup.enabled) {
                this.changeStatus(status);
            }
        });
    }
    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
    getExcludedFiles() {
        const extArray = this.backup.excludedExtensions;
        this.extensions = extArray.length > 0 ? extArray.map(ext => `[.${ext}]`).join(',') : '-';
    }
    changeStatus(status) {
        this.openChangeStatusSpinner(status);
        this.store.dispatch(new actions_1.ChangeBackupStatusAction({ enable: status, id: this.backup.id }));
        this.store
            .select(selectors_1.isUpdatingStatus)
            .pipe(operators_1.withLatestFrom(this.store.select(selectors_1.hasStatusUpdated)), operators_1.skip(1), operators_1.take(1))
            .subscribe(([UpdatingStatus, StatusUpdated]) => {
            if (!UpdatingStatus) {
                this.closeChangeStatusSpinner();
                if (StatusUpdated) {
                    this.backup = Object.assign(Object.assign({}, this.backup), { enabled: status });
                }
                else {
                    this.statusToggle.setValue(this.backup.enabled);
                }
            }
        });
    }
    editBackup() {
        this.store.dispatch(new actions_1.OpenEditBackupAction(this.backup.id, [new actions_1.LoadBackupDetailsAction(this.backup.id)]));
    }
    deleteBackup() {
        const modalConfig = {};
        modalConfig.size = 'md';
        modalConfig.hasBackdrop = true;
        modalConfig.disableClose = false;
        modalConfig.data = { backupString: this.backup.name };
        this.modalService
            .open(delete_backup_modal_component_1.DeleteBackupModalComponent, modalConfig)
            .afterClose()
            .pipe(operators_1.takeUntil(this.destroy$))
            .subscribe((confirmed) => {
            if (confirmed) {
                this.store.dispatch(new actions_1.DeleteBackupAction(this.backup.id));
            }
        });
    }
    openChangeStatusSpinner(newStatus) {
        const modalConfig = {};
        modalConfig.size = 'md';
        modalConfig.hasBackdrop = true;
        modalConfig.disableClose = true;
        modalConfig.data = { newStatus };
        this.updatingStatusSpinner = this.modalService.open(update_backup_status_spinner_modal_component_1.UpdateBackupStatusSpinnerModalComponent, modalConfig);
    }
    closeChangeStatusSpinner() {
        if (!!this.updatingStatusSpinner) {
            this.updatingStatusSpinner.close();
            this.updatingStatusSpinner = null;
        }
    }
}
exports.BackupBrowserComponent = BackupBrowserComponent;
