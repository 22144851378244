<div class="mc-ap-policy-appliesto-block mc-ap-policy-steps-container">

  <mc-wizard-step-header class="mc-ap-wizard-step-header"
                         label="$I18N_AP_CREATE_POLICY_STEPS.POLICY_APPLIES_TO_STEP_LABEL.STEP_HEADER">
    {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_APPLIES_TO_STEP_LABEL.DESCRIPTION' | translate}}
  </mc-wizard-step-header>
  <form class="form-horizontal"
        mcDefaultLabelClass="col-sm-3"
        mcDefaultControlContainerClass="col-sm-9"
        [formGroup]="apPolicyAppliesToForm">
    <div formArrayName="attachmentPolicyRule"  *ngFor="let control of attachmentPolicyRuleControls.controls; let i = index;">
      <div [formGroupName]="i">
        <div class="row">
          <div class="col-sm-6 mc-text-thick mc-margin-bottom">
            {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_APPLIES_TO_STEP_LABEL.SENDER_RECIPIENT_RULE_LABEL_TEXT' | translate}} {{i + 1}}
          </div>
          <div class="col-sm-6 mc-remove-button" *ngIf="i !== 0" (click)="deleteApRule(i)">
            {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_APPLIES_TO_STEP_LABEL.REMOVE_RULE_TEXT' | translate}}</div>
        </div> <mc-field label="$I18N_AP_CREATE_POLICY_STEPS.POLICY_APPLIES_TO_STEP_LABEL.ADDRESS_BASED_ON_FIELD.LABEL"
                         helpPopoverContent = "{{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_APPLIES_TO_STEP_LABEL.ADDRESS_BASED_ON_FIELD.HELP_POPOVER' | translate}}">
        <div class="form-control-children">
          <mc-select formControlName="apAddressesBasedOn"
                     [options]="addressesBasedOnOptions"
                     class="mc-timeout-control"></mc-select>
        </div>
      </mc-field>
        <mc-field label="$I18N_AP_CREATE_POLICY_STEPS.POLICY_APPLIES_TO_STEP_LABEL.FROM.LABEL">
          <div class="form-control-children">
            <mc-select formControlName="apAppliesFrom"
                       [options]="appliesFromOptions"
                       class="mc-timeout-control"
                       (selectChange)="onAppliesFromSelectChange($event, i)"></mc-select>
          </div>
        </mc-field>
        <div class="mc-domain-field-margin-left">
          <mc-text-field  *ngIf="control.value.apAppliesFrom === appliesFromOptions[3].value"
                          errorPrefix="$I18N_AP_CREATE_POLICY_STEPS.VALIDATION.POLICY_DOMAIN_NAME"
                          placeholder="$I18N_AP_CREATE_POLICY_STEPS.DOMAIN_PLACEHOLDER"
                          formControlName="apDomainFrom"
                          required>
          </mc-text-field>
        </div>
        <div class="mc-domain-field-margin-left">
          <mc-field *ngIf="control.value.apAppliesFrom === appliesFromOptions[5].value">
            <div class="form-control-children">
              <mc-select formControlName="apAttributeOptionFrom"
                         [options]="apAttributeOption"
                         class="mc-timeout-control"></mc-select>
            </div>
          </mc-field>
        </div>
        <div class="mc-domain-field-margin-left">
          <mc-text-field  *ngIf="control.value.apAppliesFrom === appliesFromOptions[6].value"
                          errorPrefix="$I18N_AP_CREATE_POLICY_STEPS.VALIDATION.POLICY_EMAIL_NAME"
                          placeholder="$I18N_AP_CREATE_POLICY_STEPS.EMAIL_ADDRESS_PLACEHOLDER"
                          formControlName="apEmailFrom"
                          required>
          </mc-text-field>

        </div>
        <div class="mc-select-group-margin" *ngIf="control.value.apAppliesFrom === appliesFromOptions[4].value">
          <button class="btn btn-secondary" (click)="openSidebar('from', i)">
            {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.ADMINISTRATORS.SELECT_GROUP_BUTTON' | translate}}
          </button>
          <div class="mc-selected-group" *ngIf="attachmentPolicyRuleControls.at(i).get('apGroupFrom').value">
            <div class="mc-selected-group-display">
              <input  formControlName="apGroupFrom" class="mc-display-none"/>
              <div class="mc-groups-list">
                {{ attachmentPolicyRuleControls.at(i).get('apGroupFrom').value.description}}

              </div>
              <div class="mc-delete-group">
                <i class="far fa-trash-alt"
                   aria-hidden="true"
                   (click)="onDeleteGroup(attachmentPolicyRuleControls.at(i).get('apGroupFrom').value, 'from', i)"
                ></i>
              </div>

            </div>
            <hr class="mc-group-separator"/>
          </div>
        </div>
        <mc-field label="$I18N_AP_CREATE_POLICY_STEPS.POLICY_APPLIES_TO_STEP_LABEL.TO.LABEL">
          <div class="form-control-children">
            <mc-select formControlName="apAppliesTo"
                       [options]="appliesToOptions"
                       class="mc-timeout-control"
                       (selectChange)="onAppliesToSelectChange($event, i)"></mc-select>
          </div>
        </mc-field>
        <div class="mc-domain-field-margin-left">
          <mc-text-field  *ngIf="control.value.apAppliesTo === appliesToOptions[3].value"
                          errorPrefix="$I18N_AP_CREATE_POLICY_STEPS.VALIDATION.POLICY_DOMAIN_NAME"
                          [placeholder]="'$I18N_AP_CREATE_POLICY_STEPS.DOMAIN_PLACEHOLDER' | translate"
                          formControlName="apDomainTo"
                          required>
          </mc-text-field>
        </div>
        <div class="mc-domain-field-margin-left">
          <mc-text-field *ngIf="control.value.apAppliesTo === appliesFromOptions[6].value"
                         errorPrefix="$I18N_AP_CREATE_POLICY_STEPS.VALIDATION.POLICY_EMAIL_NAME"
                         [placeholder]="'$I18N_AP_CREATE_POLICY_STEPS.POLICY_APPLIES_TO_STEP_LABEL.TO.LABEL' | translate"
                         formControlName="apEmailTo"
                         required>
          </mc-text-field>
        </div>
        <div  class="mc-select-group-margin" *ngIf="control.value.apAppliesTo === appliesToOptions[4].value">
          <button class="btn btn-secondary"
                  (click)="openSidebar('to', i)">
            {{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.ADMINISTRATORS.SELECT_GROUP_BUTTON' | translate}}
          </button>

          <div class="mc-selected-group" *ngIf="attachmentPolicyRuleControls.at(i).get('apGroupTo').value">
            <div class="mc-selected-group-display">
              <input  formControlName="apGroupTo" class="mc-display-none"/>
              <div class="mc-groups-list">
                {{ attachmentPolicyRuleControls.at(i).get('apGroupTo').value.description}}
              </div>
              <div class="mc-delete-group">
                <i class="far fa-trash-alt"
                   aria-hidden="true"
                   (click)="onDeleteGroup(attachmentPolicyRuleControls.at(i).get('apGroupTo').value, 'to', i)"
                ></i>
              </div>

            </div>
            <hr class="mc-group-separator"/>
          </div>
        </div>
        <div class="mc-domain-field-margin-left">
          <mc-field *ngIf="control.value.apAppliesTo === appliesFromOptions[5].value" >
            <div class="form-control-children">
              <mc-select formControlName="apAttributeOptionTo"
                         [options]="apAttributeOption"
                         class="mc-timeout-control"></mc-select>
            </div>
          </mc-field>
        </div>
      </div>
    </div>

    <button (click)="addAnotherRule()"
            class="btn btn-secondary panel-half-margin-right mc-margin-top">
      {{ '$I18N_AP_CREATE_POLICY_STEPS.ADD_ANOTHER_RULE_BUTTON_TEXT' | translate }}
    </button>
    <div class="row panel-margin-top">
      <div class="col-xs-6 mc-advanced-options" (click)="toggleAdvancedOptions()">

        <span class="mc-category-filter-select-label mc-text-bold">
          {{ getAdvancedOptionsTitle() | translate }}
          </span>
        <small
          class="mc-collapsable-panel-caret fas"
          [ngClass]="toggle ? 'fa-caret-down' : 'fa-caret-up'"
        ></small>
      </div>
    </div>
    <div *ngIf="!toggle">
      <div class="mc-text-bold mc-margin-bottom">{{'$I18N_AP_CREATE_POLICY_STEPS.ADVANCED_OPTIONS_TEXT' | translate}}</div>

      <mc-switch-field
        label="$I18N_AP_CREATE_POLICY_STEPS.POLICY_OVERRIDE_OPTION.LABEL"
        [showLabel]="true"
        helpPopoverContent="{{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_OVERRIDE_OPTION.HELP_POPOVER' | translate }}"
        formControlName="override">
      </mc-switch-field>

      <mc-switch-field
        label="$I18N_AP_CREATE_POLICY_STEPS.POLICY_BIDIRECTIONAL_OPTION.LABEL"
        [showLabel]="true"
        helpPopoverContent="{{ '$I18N_AP_CREATE_POLICY_STEPS.POLICY_BIDIRECTIONAL_OPTION.HELP_POPOVER' | translate }}"
        formControlName="apBidirectional">
      </mc-switch-field>
      <div class="mc-text-thick mc-margin-top">{{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SOURCEIPCHANGES_OPTION.LABEL' | translate}}</div>
      <div class="mc-margin-bottom">{{'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SOURCEIPCHANGES_OPTION.SOURCE_IP_DESCRIPTION' | translate}}</div>
      <mc-field
        errorPrefix="$I18N_AP_CREATE_POLICY_IP_ADDRESSES_STEP.VALIDATION.IP">
        <div class="mp-ip-step-input">
          <input type="text" class="form-control"
                 (input)="onIpChange($event.target.value)"
                 [placeholder]="'$I18N_AP_CREATE_POLICY_STEPS.POLICY_SOURCEIPCHANGES_OPTION.SOURCE_IP_PLACEHOLDER' | translate"
                 formControlName="apIpRange">

          <button type="submit" [disabled]="addButtonDisabled$ | async"
                  class="btn btn-default mc-ap-policy-app-ip"
                  (click)="addIp()">
            {{ '$I18N_AP_CREATE_POLICY_IP_ADDRESSES_STEP.ADD_BUTTON' | translate}}
          </button>
        </div>
      </mc-field>
    </div>
  </form>
</div>
<div class="mc-ip-step-container" *ngIf="!this.toggle">
  <ng-container *ngIf="(ipAddressesAP$ | async).length > 0">
    <mc-table tableId="ttp/policy" [isLoading]="false"
              [columns]="columns"
              [data] = "ipAddressesAP$ | async"
              translatePrefix="$I18N_AP_CREATE_POLICY_IP_ADDRESSES_STEP.IP_LIST_PREFIX">
      <mc-column key="delete-column">
        <mc-header-cell class="mp-ip-step-table" *mcHeaderCellDef>
                <span (click)="onDeleteAll()">
                    {{ '$I18N_AP_CREATE_POLICY_IP_ADDRESSES_STEP.DELETE_ALL_BUTTON' | translate }}
                </span>
        </mc-header-cell>
        <mc-body-cell class="mp-ip-step-table" *mcBodyCellDef="let row">
          <i (click)="removeIp(row)" class="far fa-trash-alt cursor-pointer"></i>
        </mc-body-cell>
      </mc-column>
    </mc-table>
  </ng-container>
</div>



