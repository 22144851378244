"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
angular.module('secure-messaging-log-detail.controller', [])
    .controller('SecureMessagingLogDetailCtrl', ['$translate', '$scope',
    function ($translate, $scope) {
        const self = this;
        const { smLogIndex, smLogsService } = $scope.$parent.$resolve;
        self.noExpiryLabel = $translate.instant('$I18N_ADMINISTRATION_MENU_SECURE_MESSAGING_LIST_NO_EXPIRY');
        self.index = smLogIndex;
        self.smLogsService = smLogsService;
        self.smLog = smLogsService.getLogs()[self.index];
    }]);
