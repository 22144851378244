"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GroupType = exports.AdvancedFileTypeEnum = exports.BiLogLevelEnum = exports.BiUnScannableEnum = exports.BiPolicyWizardFlowType = exports.BiCategorySectionOptionsEnum = exports.BiCategoryOptionsEnum = exports.BiAffectedUsersEnum = exports.BiPolicyType = void 0;
var BiPolicyType;
(function (BiPolicyType) {
    BiPolicyType["WEB_BLOCK_ALLOW_LIST"] = "WEB_BLOCK_ALLOW_LIST";
    BiPolicyType["WEB_CATEGORY_FILTERING"] = "WEB_CATEGORY_FILTERING";
    BiPolicyType["WEB_ADVANCED_SECURITY"] = "WEB_ADVANCED_SECURITY";
    BiPolicyType["WEB_TTP_POLICY"] = "WEB_TTP_POLICY";
    BiPolicyType["WEB_LOGGING"] = "WEB_LOGGING";
    BiPolicyType["WEB_APPLICATION_CONTROL"] = "WEB_APPLICATION_CONTROL";
    BiPolicyType["BROWSER_ISOLATION"] = "BROWSER_ISOLATION";
})(BiPolicyType = exports.BiPolicyType || (exports.BiPolicyType = {}));
var BiAffectedUsersEnum;
(function (BiAffectedUsersEnum) {
    BiAffectedUsersEnum["EVERYONE"] = "everyone";
    BiAffectedUsersEnum["MULTI"] = "multi";
    BiAffectedUsersEnum["LOCATION"] = "location";
    BiAffectedUsersEnum["USER"] = "user";
    BiAffectedUsersEnum["GROUP"] = "group";
})(BiAffectedUsersEnum = exports.BiAffectedUsersEnum || (exports.BiAffectedUsersEnum = {}));
var BiCategoryOptionsEnum;
(function (BiCategoryOptionsEnum) {
    BiCategoryOptionsEnum["ALLOW"] = "allow";
    BiCategoryOptionsEnum["DISALLOW"] = "disallow";
})(BiCategoryOptionsEnum = exports.BiCategoryOptionsEnum || (exports.BiCategoryOptionsEnum = {}));
var BiCategorySectionOptionsEnum;
(function (BiCategorySectionOptionsEnum) {
    BiCategorySectionOptionsEnum["ALLOWALL"] = "allow-all";
    BiCategorySectionOptionsEnum["DISALLOWALL"] = "disallow-all";
    BiCategorySectionOptionsEnum["CUSTOM"] = "custom";
})(BiCategorySectionOptionsEnum = exports.BiCategorySectionOptionsEnum || (exports.BiCategorySectionOptionsEnum = {}));
var BiPolicyWizardFlowType;
(function (BiPolicyWizardFlowType) {
    BiPolicyWizardFlowType["CREATE"] = "create";
    BiPolicyWizardFlowType["DUPLIATE"] = "duplicate";
    BiPolicyWizardFlowType["EDIT"] = "edit";
})(BiPolicyWizardFlowType = exports.BiPolicyWizardFlowType || (exports.BiPolicyWizardFlowType = {}));
var BiUnScannableEnum;
(function (BiUnScannableEnum) {
    BiUnScannableEnum["ALLOW"] = "allow";
    BiUnScannableEnum["BLOCK"] = "block";
})(BiUnScannableEnum = exports.BiUnScannableEnum || (exports.BiUnScannableEnum = {}));
var BiLogLevelEnum;
(function (BiLogLevelEnum) {
    BiLogLevelEnum["ALL"] = "all";
    BiLogLevelEnum["NONE"] = "none";
    BiLogLevelEnum["SECURITY"] = "security";
})(BiLogLevelEnum = exports.BiLogLevelEnum || (exports.BiLogLevelEnum = {}));
var AdvancedFileTypeEnum;
(function (AdvancedFileTypeEnum) {
    AdvancedFileTypeEnum["ALLOW"] = "ALLOW";
    AdvancedFileTypeEnum["BLOCK"] = "BLOCK";
    AdvancedFileTypeEnum["SCAN"] = "SCAN";
})(AdvancedFileTypeEnum = exports.AdvancedFileTypeEnum || (exports.AdvancedFileTypeEnum = {}));
exports.GroupType = {
    ldap: 'ldap',
    cloud: 'cloud'
};
