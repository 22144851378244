<mc-api-gateway-keygen-wizard
  [application]="application$ | async"
  [wizardStep$]="wizardStep$"
  [isBusy]="isBusy$ | async"
  [isError]="isError$ | async"
  [errorMessage]="errorMessage$ | async"
  [isVerificationApiError]="isVerificationApiError$ | async"
  [errorMessageVerificationApi]="errorMessageVerificationApi$ | async"
  [account]="account$ | async"
  [availableAuthenticationTypes]="availableAuthenticationTypes$ | async"
  [isVerificationRequired]="isVerificationRequired$ | async"
  [verificationNotification]="verificationNotification$ | async"
  [apiKeys]="apiKeys$ | async"
  (onAccountUpdated)="updateAccount($event)"
  (onAuthenticationUpdated)="updateAuthentication($event)"
  (onVerificationUpdated)="updateVerification($event)"
  (onKeyCopiedToClipboard)="keyCopiedToClipboard($event)"
  (onMoveNext)="moveNext()"
  (onMovePrevious)="movePrevious()"
  (onComplete)="complete()"
  (onCancel)="cancel()"
>
</mc-api-gateway-keygen-wizard>
