<mc-layout-detail-wizard
  keyTitle="{{ getPageTitle() }}"
  keyDescription="{{ getPageSubTitle(isEditFlow()) }}"
  extendClass="panel-padding-bottom"
>
  <mc-tab-group *ngIf="isEditFlow()" [direction]="'vertical'">
    <mc-tab name="{{ '$18N_BI_POLICY_CREATE.DETAILS' | translate }}">
      <mc-spinner *ngIf="loadingForEdit"></mc-spinner>
      <mc-bi-policy-details-step
        *ngIf="!loadingForEdit"
        [policy]="swgPolicy"
        [disableEdit]="swgPolicy.biSettings.defaultPolicy"
        [isEditFlow]="isEditFlow()"
        (updatePolicyDesc)="updatePolicyDescWiz.emit($event)">
      </mc-bi-policy-details-step>
    </mc-tab>
    <mc-tab name="{{ '$18N_BI_POLICY_CREATE.SETTINGS' | translate }}">
      <mc-bi-policy-settings-step
        *ngIf="!loadingForEdit"
        [settings]="swgPolicy.biSettings"
                                  (updateSettings)="updatePolicySettingsWiz.emit($event)"></mc-bi-policy-settings-step>
    </mc-tab>
    <mc-tab name="{{ '$18N_BI_POLICY_CREATE.APPLIESTO' | translate }}">
      <mc-swg-policy-affected-users-step
        *ngIf="!loadingForEdit && !swgPolicy.biSettings.defaultPolicy"
        (removeAffectedUsers)="removeAffectedUsersWiz.emit($event)"
        (openAppliesToSidePanel)="openCall($event)"
        [isEditFlow]="false"
        [affectedUsers]="swgPolicy.affectedUsers"
        [everyOne]="false"
        [policyDesc]="swgPolicy.policyDesc"
        [showEveryoneRadioOption]="false"
        [showLocationOption]="!isBiEmailOnlyCustomer"
        [isBiPolicy]="true"
      ></mc-swg-policy-affected-users-step>
      <mc-bi-default-policy-applies-to *ngIf="!loadingForEdit && swgPolicy.biSettings.defaultPolicy">

      </mc-bi-default-policy-applies-to>
    </mc-tab>
    <mc-tab name="{{ '$18N_BI_POLICY_CREATE.SUMMARY' | translate }}">
      <mc-bi-policy-create-step
        *ngIf="!loadingForEdit"
        [swgPolicy]="swgPolicy"></mc-bi-policy-create-step>
    </mc-tab>
  </mc-tab-group>


  <mc-wizard (changeStep)="goToStep($event)" *ngIf="!isEditFlow()">
    <mc-wizard-step label="$18N_BI_POLICY_CREATE.DETAILS">
      <mc-bi-policy-details-step [policy]="swgPolicy"
                                 [disableEdit]="swgPolicy.biSettings.defaultPolicy"
                                 [isEditFlow]="isEditFlow()"
                                 (updatePolicyDesc)="updatePolicyDescWiz.emit($event)"></mc-bi-policy-details-step>
    </mc-wizard-step>
    <mc-wizard-step label="$18N_BI_POLICY_CREATE.SETTINGS">
      <mc-bi-policy-settings-step [settings]="swgPolicy.biSettings"
                                  (updateSettings)="updatePolicySettingsWiz.emit($event)"></mc-bi-policy-settings-step>
    </mc-wizard-step>
    <mc-wizard-step label="$18N_BI_POLICY_CREATE.APPLIESTO">
      <mc-swg-policy-affected-users-step
        (removeAffectedUsers)="removeAffectedUsersWiz.emit($event)"
        (openAppliesToSidePanel)="openCall($event)"
        [isEditFlow]="false"
        [affectedUsers]="swgPolicy.affectedUsers"
        [everyOne]="false"
        [showEveryoneRadioOption]="false"
        [policyDesc]="swgPolicy.policyDesc"
        [showLocationOption]="!isBiEmailOnlyCustomer"
        [isBiPolicy]="true"
      ></mc-swg-policy-affected-users-step>
    </mc-wizard-step>
    <mc-wizard-step label="$18N_BI_POLICY_CREATE.SUMMARY">
      <mc-bi-policy-create-step
        [swgPolicy]="swgPolicy"></mc-bi-policy-create-step>
    </mc-wizard-step>
  </mc-wizard>
  <mc-footer-container>
    <div class="pull-right no-select mc-button-footer">
      <button class="btn" (click)="cancel()">{{ '$I18N_COMMON_BTN_CANCEL' | translate }}</button>

      <ng-container *ngIf="wizard && !isEditFlow()">
        <button
          class="btn btn-secondary panel-half-margin-right"
          *ngIf="wizard.hasPreviousStep$ | async"
          (click)="wizard.previousStep()"
        >
          {{ '$I18N_COMMON_BTN_PREVIOUS' | translate }}
        </button>
        <button
          class="btn btn-primary"
          [disabled]="!isStepValid()"
          *ngIf="wizard.hasNextStep$ | async"
          (click)="wizard.nextStep()"
        >
          {{ '$I18N_COMMON_BTN_NEXT' | translate }}
        </button>
        <ng-container *ngIf="hasBiPolicyEditPermission">
          <mc-live-button
            *ngIf="wizard.isLastStep$ | async"
            overrideClasses="btn-primary"
            [label]="getSaveButton() | translate"
            [isLoading]="apiIsProcessing"
            (mcClick)="createPolicyEvent.emit()"
          ></mc-live-button>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="isEditFlow()">
        <ng-container *ngIf="hasBiPolicyEditPermission">
          <button
            *ngIf="!apiIsProcessing"
            class="btn btn-primary"
            [disabled]="!isValidPolicy(swgPolicy)"
            (click)="createPolicyEvent.emit()"
          >
            {{ '$I18N_SWG_CREATE_POLICY_SAVE_AND_CLOSE' | translate }}
          </button>
          <mc-live-button
            *ngIf="apiIsProcessing"
            overrideClasses="btn-primary"
            [label]="'$I18N_SWG_CREATE_POLICY_SAVE_AND_CLOSE' | translate"
            [isLoading]="apiIsProcessing"
          ></mc-live-button>
        </ng-container>
      </ng-container>
    </div>
  </mc-footer-container>
</mc-layout-detail-wizard>
