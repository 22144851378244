"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./server-connection-list/server-connection-list"); //services.services.server-connections-list
require("./server-connection-edit/server-connection-edit"); //services.services.server-connections-edit
require("mimecast-web-components/src/app/components/form/validators/url-directive"); //validators.url.directive
require("mimecast-web-components/src/app/components/form/predefined-field/url/url"); //predefined-field.url
require("mimecast-web-components/src/app/components/form/select/mc-select"); //predefined-field.select
require("mimecast-web-components/src/app/services/server-connection/server-connection"); //api.server-connection
require("mimecast-web-components/src/app/components/modal-action/modal-action"); //modalAction
require("app/components/layout/list-simple/list-simple"); //layout.list-simple
require("app/components/layout/page-simple/page-simple"); //layout.page-simple
require("app/components/layout/side-simple/side-simple"); //layout.side-simple
// html and css 
require("./less/server-connection.less");
(function () {
    'use strict';
    /* ======================================================== *\

     = ServerConnections

     \* ======================================================== */
    angular.module('services.services.server-connections', [
        'services.services.server-connections-list',
        'services.services.server-connections-edit',
        'validators.url.directive',
        'predefined-field.url',
        'predefined-field.select',
        'api.server-connection',
        'modalAction',
        'layout.list-simple',
        'layout.page-simple',
        'layout.side-simple'
    ]);
}());
