<mc-collapsable-panel
  header="$I18N_MESSAGE_CENTER_MESSAGE_INFO_DELIVERY_FAILURE_NOTICE_SENT_INFO.TITLE"
  [isExpanded]="true"
>
  <mc-value-field
    label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_DELIVERY_FAILURE_NOTICE_SENT_INFO.LABEL_ADDRESSED_NOTICE_TO"
    [value]="getNoticeTo()"
  >
  </mc-value-field>
  <mc-value-field
    label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_DELIVERY_FAILURE_NOTICE_SENT_INFO.LABEL_NOTICE_CREATED"
    [value]="getNoticeCreated() | mcDatePlusWeekday"
  >
  </mc-value-field>
  <mc-value-field
    label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_DELIVERY_FAILURE_NOTICE_SENT_INFO.LABEL_DELIVERY_STATUS"
    [value]="getNoticeStatus() | translate"
  >
  </mc-value-field>
</mc-collapsable-panel>
