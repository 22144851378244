"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiGatewayAsideViewComponent = void 0;
const core_1 = require("@angular/core");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const reducers_1 = require("../../../../reducers");
const actions = require("../../../../actions");
class ApiGatewayAsideViewComponent {
    constructor(fb, store, capabilitiesService, featureFlagService) {
        this.fb = fb;
        this.store = store;
        this.capabilitiesService = capabilitiesService;
        this.featureFlagService = featureFlagService;
        this.onCloseClicked = new core_1.EventEmitter();
        this.onEditClicked = new core_1.EventEmitter();
        this.onDeleteClicked = new core_1.EventEmitter();
        this.onEnableClicked = new core_1.EventEmitter();
        this.onDisableClicked = new core_1.EventEmitter();
        this.onGetKeyClicked = new core_1.EventEmitter();
        this.onShowKeyClicked = new core_1.EventEmitter();
        this.onHideKeyClicked = new core_1.EventEmitter();
        this.onKeyCopied = new core_1.EventEmitter();
        this.onGenKeysClicked = new core_1.EventEmitter();
        this.onGetCredentialsClicked = new core_1.EventEmitter();
        this.roles = new rxjs_1.BehaviorSubject(undefined);
        this.role$ = this.roles.asObservable();
        this.clientId = new rxjs_1.BehaviorSubject(undefined);
        this.isLoading = true;
    }
    ngOnInit() {
        this.featureFlagService.isReady().then(() => {
            if (this.featureFlagService.getBooleanFlag('Adcon.Services.Applications.2.0.client.secret.Regeneration') &&
                this.isGatewayApplication(this.application)) {
                this.getClientId();
                this.showClientId = true;
            }
            else {
                this.isLoading = false;
            }
        });
        this.appEnabledForm = this.fb.group({
            appEnabled: this.application.active
        });
        this.appEnabledFormSubscription = this.appEnabledForm.valueChanges
            .pipe(operators_1.debounceTime(200))
            .subscribe((value) => {
            this.toggleActive(value.appEnabled);
        });
        this.roles.next('');
        this.rolesSubscription = this.store.select(reducers_1.getApiGatewayV3Roles).subscribe(roles => {
            const role = this.getRoleName(roles);
            this.roles.next(role);
        });
        this.capabilitiesService
            .hasCapability('Temporary.Services.ApiApplications.Apigee.Mimecast.All.Products')
            .subscribe(allowed => {
            if (allowed === true) {
                this.allProducts = this.getProducts();
            }
        });
    }
    ngOnDestroy() {
        var _a;
        this.appEnabledFormSubscription.unsubscribe();
        this.rolesSubscription.unsubscribe();
        (_a = this.clientIdSubscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        this.isLoading = false;
        this.clientId = null;
    }
    toggleActive(value) {
        if (value) {
            this.onEnableClicked.emit();
        }
        else {
            this.onDisableClicked.emit();
        }
    }
    clipboardSuccess(message) {
        this.onKeyCopied.emit(message);
    }
    closeClicked() {
        this.onCloseClicked.emit();
    }
    editClicked() {
        this.onEditClicked.emit();
    }
    deleteClicked() {
        this.onDeleteClicked.emit();
    }
    enableClicked() {
        this.onEnableClicked.emit();
    }
    disableClicked() {
        this.onDisableClicked.emit();
    }
    getKeyClicked() {
        this.onGetKeyClicked.emit();
    }
    toggleKeyVisibilityClicked() {
        if (this.showKey) {
            this.onHideKeyClicked.emit();
        }
        else {
            this.onShowKeyClicked.emit();
        }
    }
    genKeysClicked() {
        this.onGenKeysClicked.emit();
    }
    getCredentialsClicked() {
        this.onGetCredentialsClicked.emit();
    }
    getRoleName(roles) {
        const role = roles.find(r => r.value === this.application.roleId);
        return (role && role.label) || '-';
    }
    getProducts() {
        let productLabel = '';
        if (this.application['products']) {
            this.application['products'].forEach((savedProduct, index) => {
                productLabel = productLabel + savedProduct.name;
                if (index !== this.application['products'].length - 1) {
                    productLabel = productLabel + ', ';
                }
            });
        }
        return productLabel;
    }
    getClientId() {
        this.store.dispatch(new actions.GetCredentialsAction(this.application));
        this.clientIdSubscription = this.store
            .select(reducers_1.getApiGatewayV3ClientId)
            .pipe(operators_1.skip(1))
            .subscribe({
            next: clientId => {
                this.clientId.next(clientId);
                this.isLoading = false;
            }
        });
    }
    isGatewayApplication(app) {
        return !!app.usage && app.usage === 'gateway';
    }
}
exports.ApiGatewayAsideViewComponent = ApiGatewayAsideViewComponent;
