"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HeldQueueEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const api_1 = require("app-new/components/export/api");
const notification_actions_1 = require("app-new/components/notification/actions/notification.actions");
const report_message_modal_1 = require("../../../common/components/report-message-modal");
const reject_narrative_message_modal_1 = require("../../../common/components/reject-narrative-message-modal");
const commonActions = require("../../../common/actions");
const actions = require("../../actions");
const reducers = require("../../reducers");
const held_queue_notifications_1 = require("./held-queue.notifications");
class HeldQueueEffects {
    constructor(actions$, service, modalService, store$) {
        this.actions$ = actions$;
        this.service = service;
        this.modalService = modalService;
        this.store$ = store$;
        this.searchSources = [{ key: 'TEST ME' }];
        this.loadItems$ = this.actions$.pipe(effects_1.ofType(actions.HELD_QUEUE_REQUEST, actions.HELD_QUEUE_PAGINATION, actions.HELD_QUEUE_REFRESH, ...Object.keys(held_queue_notifications_1.notifications)), operators_1.withLatestFrom(this.store$.select(reducers.getHoldQueueSearch), this.store$.select(reducers.getHoldQueueRequestPage)), operators_1.switchMap(([, request, pagination]) => {
            return this.service.getItems(request, pagination).pipe(operators_1.map(response => new actions.HeldQueueSuccessAction(response)), operators_1.catchError(() => rxjs_1.of(new actions.HeldQueueFailAction())));
        }));
        this.displayRow$ = this.actions$.pipe(effects_1.ofType(actions.HELD_QUEUE_SELECT_ROW), operators_1.withLatestFrom(this.store$.select(reducers.getHoldQueueItems)), operators_1.switchMap(([action, items]) => {
            if (action.payload) {
                return rxjs_1.of(new commonActions.ShowMessageInfoAction(action.payload, items, false));
            }
            return rxjs_1.EMPTY;
        }));
        this.releaseEmails$ = this.actions$.pipe(effects_1.ofType(actions.HELD_QUEUE_RELEASE_EMAILS), operators_1.switchMap((action) => {
            return this.service.releaseEmails(action.payload).pipe(operators_1.map(() => new actions.HeldQueueReleaseEmailsSuccessAction(action.payload)), operators_1.catchError(() => rxjs_1.of(new actions.HeldQueueReleaseEmailsFailAction(action.payload))));
        }));
        this.releaseEmailsToSandbox$ = this.actions$.pipe(effects_1.ofType(actions.HELD_QUEUE_RELEASE_EMAILS_TO_SANDBOX), operators_1.switchMap((action) => {
            return this.service.releaseEmailsToSandbox(action.payload).pipe(operators_1.map(() => new actions.HeldQueueReleaseEmailsToSandboxSuccessAction(action.payload)), operators_1.catchError(() => rxjs_1.of(new actions.HeldQueueReleaseEmailsToSandboxFailAction(action.payload))));
        }));
        this.permitSenders$ = this.actions$.pipe(effects_1.ofType(actions.HELD_QUEUE_PERMIT_SENDERS), operators_1.switchMap((action) => {
            return this.service.permitSenders(action.payload).pipe(operators_1.map(() => new actions.HeldQueuePermitSendersSuccessAction(action.payload)), operators_1.catchError(() => rxjs_1.of(new actions.HeldQueuePermitSendersFailAction(action.payload))));
        }));
        this.rejectEmails$ = this.actions$.pipe(effects_1.ofType(actions.HELD_QUEUE_REJECT_EMAILS), operators_1.switchMap((action) => {
            return this.service.rejectEmails(action.payload).pipe(operators_1.map(() => new actions.HeldQueueRejectEmailsSuccessAction(action.payload)), operators_1.catchError(() => rxjs_1.of(new actions.HeldQueueRejectEmailsFailAction(action.payload))));
        }));
        this.rejectEmailsWithNotification$ = this.actions$.pipe(effects_1.ofType(actions.HELD_QUEUE_REJECT_EMAILS_WITH_NOTIFICATION), operators_1.switchMap((action) => {
            return this.service.rejectEmailsWithNotification(action.payload).pipe(operators_1.map(() => new actions.HeldQueueRejectEmailsWithNotificationSuccessAction(action.payload)), operators_1.catchError(() => rxjs_1.of(new actions.HeldQueueRejectEmailsWithNotificationFailAction(action.payload))));
        }));
        this.blockSenders$ = this.actions$.pipe(effects_1.ofType(actions.HELD_QUEUE_BLOCK_SENDERS), operators_1.switchMap((action) => {
            return this.service.blockSenders(action.payload).pipe(operators_1.map(() => new actions.HeldQueueBlockSendersSuccessAction(action.payload)), operators_1.catchError(() => rxjs_1.of(new actions.HeldQueuePermitSendersFailAction(action.payload))));
        }));
        this.notifications$ = this.actions$.pipe(effects_1.ofType(...Object.keys(held_queue_notifications_1.notifications)), operators_1.map((action) => {
            const notification = held_queue_notifications_1.notifications[action.type];
            return new notification_actions_1.NotificationShowAction({
                config: { msg: notification.message },
                type: notification.type
            });
        }));
        this.openReportEmailsAsModal$ = this.actions$.pipe(effects_1.ofType(actions.HELD_QUEUE_REPORT_EMAILS_OPEN_MODAL), operators_1.switchMap((action) => {
            return this.modalService
                .open(report_message_modal_1.ReportMessageModalComponent, {
                size: 'md',
                hasBackdrop: true,
                data: action.payload.reportAs
            })
                .afterClose()
                .pipe(operators_1.filter((report) => !!report && report.submitted), operators_1.map(() => {
                return new actions.HeldQueueReportEmailsAction(action.payload);
            }));
        }));
        this.openRejectNarrativeEmailsAsModal$ = this.actions$.pipe(effects_1.ofType(actions.HELD_QUEUE_REJECT_NARRATIVE_EMAILS_OPEN_MODAL), operators_1.switchMap((action) => {
            return this.modalService
                .open(reject_narrative_message_modal_1.RejectNarrativeMessageModalComponent, {
                size: 'md',
                hasBackdrop: true,
                data: action.payload
            })
                .afterClose()
                .pipe(operators_1.filter((reject) => !!reject && reject.submitted), operators_1.map((reject) => {
                return new actions.HeldQueueRejectEmailsWithNarrativeAction(Object.assign(Object.assign({}, action.payload), reject));
            }));
        }));
        this.reportEmails$ = this.actions$.pipe(effects_1.ofType(actions.HELD_QUEUE_REPORT_EMAILS), operators_1.switchMap((action) => {
            return this.service.reportEmails(action.payload).pipe(operators_1.map(() => new actions.HeldQueueReportEmailsSuccessAction(action.payload)), operators_1.catchError(() => rxjs_1.of(new actions.HeldQueueReportEmailsFailAction(action.payload))));
        }));
        this.rejectEmailsWithNarrative$ = this.actions$.pipe(effects_1.ofType(actions.HELD_QUEUE_REJECT_EMAILS_WITH_NARRATIVE), operators_1.switchMap((action) => {
            return this.service.rejectEmailsWithNarrative(action.payload).pipe(operators_1.map(() => {
                const { payload } = action;
                const { notify } = payload;
                if (notify) {
                    return new actions.HeldQueueRejectEmailsWithNarrativeWithNotificationSuccessAction(payload);
                }
                else {
                    return new actions.HeldQueueRejectEmailsWithNarrativeSuccessAction(payload);
                }
            }), operators_1.catchError(() => rxjs_1.of(new actions.HeldQueueRejectEmailsWithNarrativeFailAction(action.payload))));
        }));
        this.export$ = this.actions$.pipe(effects_1.ofType(actions.HELD_QUEUE_EXPORT), operators_1.withLatestFrom(this.store$.select(reducers.getHoldQueueSearch)), operators_1.map(([action, search]) => {
            const apiSearch = this.service.transformToServerRequest(search);
            return new api_1.OpenSidePanelExportApiAction('/api/gateway/export-held-messages', action.payload, apiSearch, '$I18N_MESSAGE_CENTER_HOLD_QUEUE_TABLE');
        }));
    }
}
__decorate([
    effects_1.Effect()
], HeldQueueEffects.prototype, "loadItems$", void 0);
__decorate([
    effects_1.Effect()
], HeldQueueEffects.prototype, "displayRow$", void 0);
__decorate([
    effects_1.Effect()
], HeldQueueEffects.prototype, "releaseEmails$", void 0);
__decorate([
    effects_1.Effect()
], HeldQueueEffects.prototype, "releaseEmailsToSandbox$", void 0);
__decorate([
    effects_1.Effect()
], HeldQueueEffects.prototype, "permitSenders$", void 0);
__decorate([
    effects_1.Effect()
], HeldQueueEffects.prototype, "rejectEmails$", void 0);
__decorate([
    effects_1.Effect()
], HeldQueueEffects.prototype, "rejectEmailsWithNotification$", void 0);
__decorate([
    effects_1.Effect()
], HeldQueueEffects.prototype, "blockSenders$", void 0);
__decorate([
    effects_1.Effect()
], HeldQueueEffects.prototype, "notifications$", void 0);
__decorate([
    effects_1.Effect()
], HeldQueueEffects.prototype, "openReportEmailsAsModal$", void 0);
__decorate([
    effects_1.Effect()
], HeldQueueEffects.prototype, "openRejectNarrativeEmailsAsModal$", void 0);
__decorate([
    effects_1.Effect()
], HeldQueueEffects.prototype, "reportEmails$", void 0);
__decorate([
    effects_1.Effect()
], HeldQueueEffects.prototype, "rejectEmailsWithNarrative$", void 0);
__decorate([
    effects_1.Effect()
], HeldQueueEffects.prototype, "export$", void 0);
exports.HeldQueueEffects = HeldQueueEffects;
