"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const moment = require("moment");
require("bootstrap-datepicker");
/**
 * Created by pdesai on 16/03/2017.
 */
(function () {
    'use strict';
    angular.module('date-time-picker.directive', [])
        .directive('mcDateTimePicker', function () {
        return {
            restrict: 'E',
            templateUrl: 'components/date-time-picker/date-time-picker.tpl.html',
            controllerAs: 'dateTimePickerCtrl',
            bindToController: true,
            scope: {
                title: '<',
                showTimePicker: '<',
                onChange: '&',
                date: '<',
                watchDate: '<',
                minStartDate: '<',
                maxEndDate: '<'
            },
            controller: ['$scope', '$element', 'adconCommonsService', function ($scope, $element, adconCommonsService) {
                    const self = this;
                    self.$onInit = () => {
                        const datePickerElement = angular.element($element[0].querySelector('#datePicker'));
                        let selectedDate = self.date;
                        const datePickerOptions = {
                            todayHighlight: true
                        };
                        let changeDateNoClick = false;
                        noClickDateChange();
                        if (self.watchDate) {
                            $scope.$watch('dateTimePickerCtrl.date', function () {
                                noClickDateChange();
                            });
                        }
                        if (self.minStartDate) {
                            datePickerElement.datepicker('setStartDate', new Date(self.minStartDate.getFullYear(), self.minStartDate.getMonth(), self.minStartDate.getDate()));
                        }
                        if (self.maxEndDate) {
                            datePickerElement.datepicker('setEndDate', new Date(self.maxEndDate.getFullYear(), self.maxEndDate.getMonth(), self.maxEndDate.getDate()));
                        }
                        self.hours = ['Hours'];
                        self.hours = self.hours.concat(adconCommonsService.getHoursInDay());
                        self.minutes = ['Minutes'];
                        self.minutes = self.minutes.concat(adconCommonsService.getMinutesInHour());
                        datePickerElement.datepicker(datePickerOptions).on('changeDate', function (event) {
                            if (!changeDateNoClick) {
                                event.date.setHours(parseInt(selectedDate.getHours(), 10));
                                event.date.setMinutes(parseInt(selectedDate.getMinutes(), 10));
                                selectedDate = event.date;
                                self.onChange({ date: selectedDate });
                            }
                            else {
                                changeDateNoClick = false;
                            }
                        });
                        self.selectedTimeChange = function () {
                            selectedDate.setHours(parseInt(self.selectedHour, 10));
                            selectedDate.setMinutes(parseInt(self.selectedMinute, 10));
                            self.onChange({ date: selectedDate });
                        };
                        function noClickDateChange() {
                            if (self.date && ((self.date instanceof Date) || moment(self.date, moment.ISO_8601, true).isValid())) {
                                changeDateNoClick = true;
                                if (!(self.date instanceof Date)) {
                                    self.date = new Date(self.date);
                                }
                                selectedDate = self.date;
                                datePickerElement.datepicker('setDate', new Date(self.date.getFullYear(), self.date.getMonth(), self.date.getDate()));
                                self.selectedHour = ((self.date.getHours() > 9) ? '' : '0') + self.date.getHours().toString();
                                self.selectedMinute = ((self.date.getMinutes() > 9) ? '' : '0') + self.date.getMinutes().toString();
                            }
                        }
                    };
                }]
        };
    });
}());
