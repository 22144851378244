"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PolicyWizardActionComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const step_base_component_1 = require("app-new/custom-domain-block/containers/wizard/step.base.component");
const custom_domain_block_service_1 = require("app-new/custom-domain-block/services/custom-domain-block.service");
class PolicyWizardActionComponent extends step_base_component_1.StepBaseDirective {
    constructor(store, fb) {
        super(store, fb, 2, custom_domain_block_service_1.CustomDomainBlockService.WIZARD_ID);
        this.store = store;
        this.fb = fb;
        this.openNotifyToSidePanel = new core_1.EventEmitter();
        this.removeGroup = new core_1.EventEmitter();
        this.removeRedirectEmail = new core_1.EventEmitter();
        this.removeRedirectGroup = new core_1.EventEmitter();
        this.form = this.fb.group({
            action: ['drop', [forms_1.Validators.required]],
            notify: false,
            notifications: [],
            redirectToUser: undefined,
            redirectToGroup: undefined
        });
        this.form.patchValue({ action: 'drop', notify: false, notifications: [] });
        this.actionSubscription = this.form.get('action').valueChanges.subscribe((action) => {
            if (action && action === 'redirect_user') {
                this.form.get('redirectToUser').setValidators(forms_1.Validators.required);
                this.form.get('redirectToUser').setValue(undefined);
                this.form.get('redirectToGroup').setValidators(undefined);
                this.form.get('redirectToGroup').setValue(undefined);
            }
            else if (action && action === 'redirect_group') {
                this.form.get('redirectToGroup').setValidators(forms_1.Validators.required);
                this.form.get('redirectToGroup').setValue(undefined);
                this.form.get('redirectToUser').setValidators(undefined);
                this.form.get('redirectToUser').setValue(undefined);
            }
            else {
                this.form.get('redirectToUser').setValidators(undefined);
                this.form.get('redirectToUser').setValue(undefined);
                this.form.get('redirectToGroup').setValidators(undefined);
                this.form.get('redirectToGroup').setValue(undefined);
                this.form.removeControl('notifyGroups');
                // Notify cannot ever be true if is not a redirect option
                this.form.patchValue({ notify: false });
            }
            this.form.updateValueAndValidity();
        });
        this.notifySubscription = this.form.get('notify').valueChanges.subscribe((notify) => {
            if (notify) {
                this.notifyValue = notify;
                this.form.addControl('notifyGroups', this.fb.array(this.notifyGroups || [], [forms_1.Validators.required, forms_1.Validators.minLength(1)]));
            }
            else {
                this.notifyValue = false;
                this.form.removeControl('notifyGroups');
            }
            this.form.updateValueAndValidity();
        });
    }
    ngOnInit() {
        super.ngOnInit();
        this.form.patchValue({
            action: this.action || 'drop',
            notify: this.notify,
            redirectToUser: this.redirectToUser,
            redirectToGroup: this.redirectToGroup
        });
        if (this.notifyGroups && this.notifyGroups.length) {
            this.notifyValue = true;
            this.form.addControl('notifyGroups', this.fb.array(this.notifyGroups, [forms_1.Validators.required, forms_1.Validators.minLength(1)]));
            this.form.updateValueAndValidity();
        }
    }
    ngOnChanges(changes) {
        if (this.notifyValue &&
            changes.notificationsGroups &&
            changes.notificationsGroups.currentValue &&
            changes.notificationsGroups.currentValue !== changes.notificationsGroups.previousValue) {
            this.updateGroups(changes.notificationsGroups.currentValue);
        }
        if (changes.redirectToUser &&
            changes.redirectToUser.currentValue !== changes.redirectToUser.previousValue) {
            this.form.patchValue({
                redirectToUser: changes.redirectToUser.currentValue
            });
        }
        if (changes.redirectToGroup &&
            (changes.redirectToGroup.currentValue && changes.redirectToGroup.currentValue.id) !==
                (changes.redirectToGroup.previousValue && changes.redirectToGroup.previousValue.id)) {
            this.form.patchValue({
                redirectToGroup: changes.redirectToGroup.currentValue
            });
        }
    }
    removeRedirectToUser() {
        this.removeRedirectEmail.emit();
        this.form.get('redirectToUser').setValue(undefined);
        this.form.updateValueAndValidity();
    }
    removeRedirectToGroup() {
        this.removeRedirectGroup.emit();
        this.form.get('redirectToGroup').setValue(undefined);
        this.form.updateValueAndValidity();
    }
    updateGroups(notificationsGroups) {
        this.form.removeControl('notifyGroups');
        this.form.addControl('notifyGroups', this.fb.array(notificationsGroups.map((group) => this.fb.group(group)), [forms_1.Validators.required, forms_1.Validators.minLength(1)]));
    }
    ngOnDestroy() {
        this.notifySubscription.unsubscribe();
        this.actionSubscription.unsubscribe();
    }
}
exports.PolicyWizardActionComponent = PolicyWizardActionComponent;
