<mc-layout-aside-extra-container
  keyTitle="{{ '$I18N_API_CROWDSTRIKE_INTEGRATION_ASIDE_VIEW.TITLE' | translate }}"
  [isLoading]="false"
  showClose="true"
  (closeAside)="onCloseClicked.emit()"
>
  <mc-extra-container *mcCapabilities="'Permission.API_APPLICATIONS_EDIT'">
    <mc-edit-integration-view
      [integration]="integration"
      [enabled]="enabled"
      (onEditClicked)="onEditClicked.emit()"
      (onEnableClicked)="onEnableClicked.emit()"
      (onDisableClicked)="onDisableClicked.emit()"
    >
    </mc-edit-integration-view>
  </mc-extra-container>

  <mc-body-container>
    <section class="mc-detail-section">
      <div class="panel-body no-padding-top">
        <div class="mc-detailed-list">
          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_CROWDSTRIKE_INTEGRATION_ASIDE_VIEW.FIELDS.INTEGRATION' | translate }}
            </div>
            <div class="mc-detailed-list-column">
              {{ integration.integrationType | mcIntegrationProvider }}
            </div>
          </div>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_CROWDSTRIKE_INTEGRATION_ASIDE_VIEW.FIELDS.DESCRIPTION.NAME' | translate }}
            </div>
            <div class="mc-detailed-list-column">
              {{ '$I18N_API_CROWDSTRIKE_INTEGRATION_ASIDE_VIEW.FIELDS.DESCRIPTION.VALUE' | translate }}
            </div>
          </div>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_CROWDSTRIKE_INTEGRATION_ASIDE_VIEW.FIELDS.CLIENT_ID' | translate }}
            </div>
            <div class="mc-detailed-list-column">
              {{ crowdstrikeIntegration?.clientId }}
            </div>
          </div>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_CROWDSTRIKE_INTEGRATION_ASIDE_VIEW.FIELDS.CLIENT_SECRET' | translate }}
            </div>
            <div class="mc-detailed-list-column">
              {{ crowdstrikeIntegration?.clientSecret | mcApiObfuscator }}
            </div>
          </div>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_CROWDSTRIKE_INTEGRATION_ASIDE_VIEW.FIELDS.BASE_URL' | translate }}
            </div>
            <div class="mc-detailed-list-column">
              {{ crowdstrikeIntegration?.baseUrl }}
            </div>
          </div>

          <div class="row mc-detailed-list-row panel-padding-top">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_CROWDSTRIKE_INTEGRATION_ASIDE_VIEW.FIELDS.SUBMIT_MALICIOUS_HASHES' | translate }}
            </div>
            <div class="mc-detailed-list-column">{{ crowdstrikeIntegration?.submitMaliciousHashes | mcSubmitMaliciousHashDesc : crowdstrikeIntegration?.iocOutboundAction  }}</div>
          </div>

          <div class="row mc-detailed-list-row panel-padding-bottom" *mcCapabilities="'Temporary.Services.ApiApplications.Crowdstrike.Integration.PushUrl'">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.SUMMARY.SUBMIT_MALICIOUS_URLS' | translate }}
            </div>
            <div class="mc-detailed-list-column">{{ crowdstrikeIntegration?.submitMaliciousUrls | mcSubmitMaliciousUrlDesc: crowdstrikeIntegration?.iocOutboundUrlAction :
              crowdstrikeIntegration?.iocOutboundSeverity : crowdstrikeIntegration?.iocOutboundExpiration }}</div>
          </div>

          <div class="row mc-detailed-list-row panel-padding-top" *mcCapabilities="'Temporary.Services.ApiApplications.Crowdstrike.Integration.PullMaliciousHashes'">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_CROWDSTRIKE_INTEGRATION_ASIDE_VIEW.FIELDS.PULL_MALICIOUS_HASHES' | translate }}
            </div>
            <div class="mc-detailed-list-column">{{ crowdstrikeIntegration?.pullMaliciousHashes | mcCrowdstrikeBoolean }}</div>
          </div>

          <div class="row mc-detailed-list-row panel-padding-top" *mcCapabilities="'Temporary.Services.ApiApplications.Crowdstrike.Integration.PullMaliciousHashes'">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_CROWDSTRIKE_INTEGRATION_ASIDE_VIEW.FIELDS.REMEDIATION' | translate }}
            </div>
            <div class="mc-detailed-list-column">{{ crowdstrikeIntegration?.remediationRequired | mcCrowdstrikeBoolean }}</div>
          </div>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_CROWDSTRIKE_INTEGRATION_ASIDE_VIEW.FIELDS.CREATED' | translate }}
            </div>
            <div class="mc-detailed-list-column">{{ integration.created | mcDatePlusWeekday }}</div>
          </div>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_CROWDSTRIKE_INTEGRATION_ASIDE_VIEW.FIELDS.RECIPIENTS' | translate }}
            </div>

            <div class="mc-detailed-list-column">
              <div class="mc-detailed-list-row" *ngFor="let group of crowdstrikeGroupsList">
                {{ group | translate }}
              </div>

              <div class="mc-detailed-list-row" *ngFor="let email of crowdstrikeEmailList">
                {{ email }}
              </div>
            </div>
          </div>
          <div class="row mc-detailed-list-row" *mcCapabilities="'Temporary.Services.ApiApplications.Crowdstrike.Integration.ActivityLogs && Permission.API_APPLICATIONS_EDIT'">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_CROWDSTRIKE_INTEGRATION_ASIDE_VIEW.FIELDS.LAST_ACTIVITY' | translate }}
            </div>
            <div class="mc-detailed-list-column"><a class="cursor-pointer no-selection"
              (click)="openCrowdstrikeActivityLogPage()">{{ '$I18N_API_CROWDSTRIKE_INTEGRATION_ASIDE_VIEW.FIELDS.VIEW_LOGS' | translate }}</a></div>
          </div>
        </div>
      </div>
    </section>
  </mc-body-container>
</mc-layout-aside-extra-container>
