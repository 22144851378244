<mc-layout-list-table keyTitle="$I18N_MONITORED_EXTERNAL_DOMAIN_TITLE"
                      keyDescription="$I18N_MONITORED_EXTERNAL_DOMAIN_TITLE_DESCRIPTION">

  <mc-help-container>
  <mc-help-link url="https://community.mimecast.com/docs/DOC-3092"></mc-help-link>
  </mc-help-container>

  <mc-table  [data]="tableData$ | async"
             [columns]="columnList"
             [isLoading]="isLoading$ | async"
             [highlightedRow]="selectedRow"
             (rowClick)="rowClick.emit($event)"
             translatePrefix="$I18N_MONITORED_EXTERNAL_DOMAIN_TABLE_COLUMNS">
    <mc-filters (paginatorChange)="onPaginationChange($event)"
                [metadata]="metaData$ | async"
                [showPagination]="true"
                [externalTableSearchCount]="externalTableSearchCount">
      <mc-filters-bundle-column-values-search-only (filtersChange)="onSearchDomains($event)"
                                                   placeholder="$I18N_MONITORED_EXTERNAL_DOMAIN_LIST.PLACEHOLDER.SEARCH">
      </mc-filters-bundle-column-values-search-only>
    </mc-filters>

    <div class="mc-table-actions">
      <button class="btn btn-primary panel-half-margin-right"
              (click) = "addDomains.emit()"
              *mcCapabilities="'Permission.POLICIES_EDIT'">
        {{'$I18N_MONITORED_EXTERNAL_DOMAIN_ADD_DOMAIN' | translate }}
      </button>
      <mc-live-button [label]="(isDeleting ? '$I18N_MONITORED_EXTERNAL_DOMAIN_DELETING' : '$I18N_MONITORED_EXTERNAL_DOMAIN_DELETE') | translate"
                      overrideClasses="btn-secondary"
                      [mcDisabled]="!allowDelete"
                      [isLoading]="isDeleting"
                      (mcClick)="deleteItems.emit()"
                      [inverted]="false">
      </mc-live-button>
    </div>

    <mc-empty-results keyTitle="$I18N_MONITORED_EXTERNAL_DOMAIN_EMPTY_LIST_TITLE">
      <mc-empty-body>
        {{'$I18N_MONITORED_EXTERNAL_DOMAIN_EMPTY_LIST_BODY' | translate}}
      </mc-empty-body>
    </mc-empty-results>

    <mc-column key="left-column">
      <mc-header-cell *mcHeaderCellDef="let row">
        <input type="checkbox"
               (click)="onSelectAll($event)"
               [checked] ="isSelectedAll"
               [disabled]="(tableData$ | async) && (tableData$ | async).length ==0">
      </mc-header-cell>
      <mc-body-cell *mcBodyCellDef="let row" (click)="checkboxBodyCellClick($event)">
        <input type="checkbox"
               (click)="onSelectingSingleItem({ event:$event, domain: row })"
               [checked]="isSelectedAll">
      </mc-body-cell>
    </mc-column>

    <mc-column-actions key="row-actions">
      <mc-row-actions *mcRowActions="let row">
        <li mcRowAction="$I18N_MONITORED_EXTERNAL_DOMAIN_TABLE_MEATBALLS.EDIT" (click)="rowClick.emit(row)"></li>
        <li mcRowActionSeparator></li>
        <li mcRowAction="$I18N_MONITORED_EXTERNAL_DOMAIN_TABLE_MEATBALLS.DELETE" (click)="deleteItem.emit(row)"></li>
      </mc-row-actions>
    </mc-column-actions>
  </mc-table>

 </mc-layout-list-table>
