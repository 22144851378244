"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiCortexIntegrationAsideViewComponent = void 0;
const core_1 = require("@angular/core");
class ApiCortexIntegrationAsideViewComponent {
    constructor() {
        this.onCloseClicked = new core_1.EventEmitter();
        this.onEditClicked = new core_1.EventEmitter();
        this.onEnableClicked = new core_1.EventEmitter();
        this.onDisableClicked = new core_1.EventEmitter();
    }
}
exports.ApiCortexIntegrationAsideViewComponent = ApiCortexIntegrationAsideViewComponent;
