<div class="mc-detailed-list"
     [class.mc-detailed-list-two-columns]="selectedEmail?.type === 'receipt'"
     [class.mc-detailed-list-is-comparing]="switch.compareViews | async"
     [class.mc-detailed-list-hide-first-column]="!(switch.compareViews | async) && (selectedEmail?.type === 'delivery')">

  <mc-compare-views-toolbar #switch
                            [recipientInfo]="recipientInfo"
                            [deliveredMessage]="deliveredMessage"
                            [selectedEmail]="selectedEmail"
                            (selectEmail)="selectEmail.emit($event)"
                            (compareViews)="compareViews.emit($event)">
  </mc-compare-views-toolbar>

  <mc-envelope-details [recipientInfo]="recipientInfo"
                       [selectedDeliveredMessage]="selectedDeliveredMessage"
                       [queueInfo]="queueInfo"
                       [messageStatus]="messageStatus">
  </mc-envelope-details>

  <hr>
  <mc-remote-server-details [recipientInfo]="recipientInfo"
                            [selectedDeliveredMessage]="selectedDeliveredMessage">
  </mc-remote-server-details>

  <hr>
  <mc-transmission-information [recipientInfo]="recipientInfo"
                               [selectedDeliveredMessage]="selectedDeliveredMessage">
  </mc-transmission-information>

  <div *ngIf="selectedDeliveredMessage?.deliveryMetaInfo?.holdActionAudit">
    <hr/>
    <mc-hold-release-information [holdActionAudit]="selectedDeliveredMessage?.deliveryMetaInfo?.holdActionAudit"></mc-hold-release-information>
  </div>

  <div *ngIf="!(switch.compareViews | async)">
    <hr/>
    <mc-transmission-components [recipientInfo]="recipientInfo"
                                [selectedDeliveredMessage]="selectedDeliveredMessage"
                                [selectedEmail]="selectedEmail">
    </mc-transmission-components>
  </div>
</div>
