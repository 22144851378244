'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const _ = require("lodash");
angular.module('search-box-extended.controller', [])
    .controller('SearchBoxExtendedCtrl', ['$scope', '$translate', 'adconCommonsService', function ($scope, $translate, adconCommonsService) {
        const self = this;
        self.dropdownItemTypes = angular.copy(adconCommonsService.CONSTANTS.DROPDOWN_ITEM_TYPES);
        self.searchTerm = '';
        self.searchField = '';
        self.selectedSearchField = '';
        self.updateSearch = updateSearch;
        self.clearSearch = clearSearch;
        self.updateSearchField = updateSearchField;
        self.searchTermIsNotEmpty = searchTermIsNotEmpty;
        self.searchTermChanged = searchTermChanged;
        activate();
        $scope.$watch(function () {
            return ($scope.ngModel && $scope.ngModel.query);
        }, function (newValue) {
            if (!newValue) {
                self.searchTerm = '';
            }
        });
        function activate() {
            if ($scope.searchFields) {
                self.updateSearchField(adconCommonsService.CONSTANTS.ALL);
            }
        }
        function updateSearch() {
            if (self.searchTerm.length >= adconCommonsService.CONSTANTS.FILTER_SEARCH_LIMIT.MIN.VALUE && self.searchTerm.length <= adconCommonsService.CONSTANTS.FILTER_SEARCH_LIMIT.MAX.VALUE) {
                $scope.onUpdateSearch({ text: self.searchTerm });
                if ($scope.ngModel) {
                    $scope.ngModel.query = self.searchTerm;
                    if ($scope.searchFields) {
                        $scope.ngModel.searchField = self.searchField;
                    }
                }
                self.showWarningMessage = false;
            }
            else {
                if (self.searchTerm.length <= adconCommonsService.CONSTANTS.FILTER_SEARCH_LIMIT.MIN.VALUE) {
                    self.warningMessage = adconCommonsService.CONSTANTS.FILTER_SEARCH_LIMIT.MIN.TEXT;
                }
                if (self.searchTerm.length >= adconCommonsService.CONSTANTS.FILTER_SEARCH_LIMIT.MAX.VALUE) {
                    self.warningMessage = adconCommonsService.CONSTANTS.FILTER_SEARCH_LIMIT.MAX.TEXT;
                }
                self.showWarningMessage = true;
            }
        }
        function clearSearch() {
            self.searchTerm = '';
            $scope.onClearSearch();
            if ($scope.ngModel) {
                if ($scope.ngModel.query) {
                    delete $scope.ngModel.query;
                }
                if ($scope.ngModel.searchField) {
                    delete $scope.ngModel.searchField;
                }
            }
        }
        function updateSearchField(id) {
            self.searchField = $scope.searchFields[id].key;
            setActiveItem($scope.searchFields, id);
            self.selectedSearchField = $scope.searchFields[id].value;
            $scope.onUpdateSearchField({ id });
        }
        function setActiveItem(content, key) {
            const activeItem = _.findKey(content, adconCommonsService.CONSTANTS.ACTIVE);
            content[activeItem].active = false;
            content[key].active = true;
        }
        function searchTermIsNotEmpty() {
            return self.searchTerm.length > 0;
        }
        function searchTermChanged() {
            if (!self.searchTermIsNotEmpty()) {
                self.clearSearch();
            }
        }
    }]);
