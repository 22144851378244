"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrackingPageComponent = void 0;
const rxjs_1 = require("rxjs");
const actions = require("../../common/actions");
const export_actions_1 = require("../actions/export.actions");
const search = require("../actions/search.actions");
const reducers = require("../reducers");
const operators_1 = require("rxjs/operators");
class TrackingPageComponent {
    constructor(store, capabilitiesService) {
        this.store = store;
        this.capabilitiesService = capabilitiesService;
    }
    ngOnInit() {
        this.searchResult$ = this.store.select(reducers.getSearchList);
        this.filtersMetadata$ = this.store.select(reducers.getFiltersMetadata);
        this.externalTableSearchCount$ = this.store.select(reducers.externalTableSearchCount);
        this.selectedRow$ = this.store.select(reducers.getSelectedRow);
        this.isLoading$ = this.store.select(reducers.isTableLoading);
        this.errorDataResponse$ = this.store.select(reducers.isErrorDataResponse);
        this.isUrlSearchEnabled$ = rxjs_1.forkJoin([
            this.capabilitiesService.hasCapability('Temporary.MessageCenter.Tracking.Search.Url.Filter'),
            this.capabilitiesService.hasCapability('Policy.InsiderThreatManagement')
        ]).pipe(operators_1.map(([isSwitchEnabled, isIEPCapabilityAssigned]) => isSwitchEnabled && isIEPCapabilityAssigned));
    }
    onSearch(newSearchPayload) {
        this.store.dispatch(new search.RequestSearchPermissionAction(newSearchPayload));
    }
    onFiltersChange(newFiltersPayload) {
        this.store.dispatch(new search.RequestSearchFiltersPermissionAction(newFiltersPayload));
    }
    onPaginatorChange(paginatorOutput) {
        this.store.dispatch(new search.SearchPaginationAction(paginatorOutput));
    }
    onRowClick(row) {
        const rowMessageType = Object.assign(Object.assign({}, row), { messageType: 'rejectedMessage' });
        this.store.dispatch(new search.SearchSelectRow(rowMessageType));
    }
    onExport(tableColumns) {
        this.store.dispatch(new export_actions_1.ExportAction(tableColumns));
    }
    reportAs({ reportType, id }) {
        this.store.dispatch(new actions.ReportMessageOpenModalAction(reportType, id));
    }
    ngOnDestroy() {
        this.store.dispatch(new search.ResetStateAction());
    }
}
exports.TrackingPageComponent = TrackingPageComponent;
