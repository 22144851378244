"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DecodeUrlEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const decodeUrlActions = require("../../actions/decode-url.actions");
class DecodeUrlEffects {
    constructor(actions$, service, notificationService) {
        this.actions$ = actions$;
        this.service = service;
        this.notificationService = notificationService;
        this.decodeUrl$ = this.actions$.pipe(effects_1.ofType(decodeUrlActions.DECODE_URL_ATTEMPT), operators_1.mergeMap((action) => {
            return this.service.decodeUrl(action.payload).pipe(operators_1.map((response) => {
                return new decodeUrlActions.DecodeUrlSuccessAction(response);
            }), operators_1.catchError((err) => {
                return rxjs_1.of(new decodeUrlActions.DecodeUrlFailAction(err));
            }));
        }));
        this.decodeUrlFail$ = this.actions$.pipe(effects_1.ofType(decodeUrlActions.DECODE_URL_FAIL), operators_1.map((action) => {
            return this.notificationService.newNotification({
                type: 'error',
                config: {
                    msg: action.payload.firstFail.errors[0].message
                }
            });
        }));
    }
}
__decorate([
    effects_1.Effect()
], DecodeUrlEffects.prototype, "decodeUrl$", void 0);
__decorate([
    effects_1.Effect()
], DecodeUrlEffects.prototype, "decodeUrlFail$", void 0);
exports.DecodeUrlEffects = DecodeUrlEffects;
