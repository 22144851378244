"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplatePreviewsModule = exports.COMPONENTS = void 0;
const core_1 = require("@ngx-translate/core");
const shared_module_1 = require("app-new/shared.module");
const components_1 = require("@mimecast-ui/components");
const carousel_module_1 = require("../../../../components/carousel/carousel.module");
const harmful_link_identified_page_component_1 = require("./harmful-link-identified-page/harmful-link-identified-page.component");
const harmful_link_misidentified_block_page_component_1 = require("./harmful-link-misidentified-block-page/harmful-link-misidentified-block-page.component");
const harmful_link_misidentified_page_component_1 = require("./harmful-link-misidentified-page/harmful-link-misidentified-page.component");
const safe_link_identified_page_component_1 = require("./safe-link-identified-page/safe-link-identified-page.component");
const safe_link_misidentified_page_component_1 = require("./safe-link-misidentified-page/safe-link-misidentified-page.component");
const template_frame_component_1 = require("./template-frame/template-frame.component");
const template_title_sub_title_component_1 = require("./template-title-sub-title/template-title-sub-title.component");
const user_challenge_page_component_1 = require("./user-challenge-page/user-challenge-page.component");
exports.COMPONENTS = [
    user_challenge_page_component_1.UserChallengePageComponent,
    template_frame_component_1.TemplateFrameComponent,
    template_title_sub_title_component_1.TemplateTitleSubTitleComponent,
    safe_link_identified_page_component_1.SafeLinkIdentifiedPageComponent,
    safe_link_misidentified_page_component_1.SafeLinkMisidentifiedPageComponent,
    harmful_link_identified_page_component_1.HarmfulLinkIdentifiedPageComponent,
    harmful_link_misidentified_page_component_1.HarmfulLinkMisidentifiedPageComponent,
    harmful_link_misidentified_block_page_component_1.HarmfulLinkMisidentifiedBlockPageComponent
];
const MODULES = [shared_module_1.SharedModule, components_1.FeedbackBadgeModule, core_1.TranslateModule, carousel_module_1.CarouselModule];
class TemplatePreviewsModule {
}
exports.TemplatePreviewsModule = TemplatePreviewsModule;
