"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportAction = exports.EXPORT = void 0;
exports.EXPORT = '[Secure Messaging] Export';
class ExportAction {
    constructor(tableColumns, searchPayload) {
        this.tableColumns = tableColumns;
        this.searchPayload = searchPayload;
        this.type = exports.EXPORT;
    }
}
exports.ExportAction = ExportAction;
