"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgUrlCheckEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const url_check_actions_1 = require("../actions/url-check.actions");
const url_report_action_1 = require("../actions/url-report.action");
const notification_actions_1 = require("app-new/components/notification/actions/notification.actions");
class SwgUrlCheckEffects {
    constructor(actions$, translationService, swgPolicyService) {
        this.actions$ = actions$;
        this.translationService = translationService;
        this.swgPolicyService = swgPolicyService;
        this.urlCheckEffects$ = this.actions$.pipe(effects_1.ofType(url_check_actions_1.SwgUrlClassificationActions.GET_URL_CLASSFICATION), operators_1.map((action) => action.payload), operators_1.switchMap((payload) => {
            return this.swgPolicyService.getUrlClassification(payload).pipe(operators_1.map((response) => new url_check_actions_1.SwgGetUrlClassificationSuccessAction(response)), operators_1.catchError(error => rxjs_1.merge(rxjs_1.of(this.getNotificationAction(error)), rxjs_1.of(new url_check_actions_1.SwgGetUrlClassificationFailureAction(error)))));
        }));
        this.urlReportEffects$ = this.actions$.pipe(effects_1.ofType(url_report_action_1.SwgUrlReportActions.REPORT_URL), operators_1.map((action) => action.payload), operators_1.switchMap((payload) => {
            return this.swgPolicyService.reportUrlClassification(payload).pipe(operators_1.mergeMap(() => {
                return [new url_report_action_1.SwgReportUrlSuccessAction(), this.showSuccessNotification()];
            }), operators_1.catchError(error => rxjs_1.merge(rxjs_1.of(this.getNotificationAction(error)), rxjs_1.of(new url_report_action_1.SwgReportUrlFailureAction()))));
        }));
    }
    getNotificationAction(error) {
        const msg = error.fail
            ? error.fail[0].errors[0].message
            : this.translationService.instant('$I18N_SWG_CREATE_POLICY_SERVER_ERROR');
        return new notification_actions_1.NotificationShowAction({
            type: 'error',
            config: {
                msg
            }
        });
    }
    showSuccessNotification() {
        return new notification_actions_1.NotificationShowAction({
            type: 'success',
            config: {
                msg: 'Message has been submitted'
            }
        });
    }
}
__decorate([
    effects_1.Effect()
], SwgUrlCheckEffects.prototype, "urlCheckEffects$", void 0);
__decorate([
    effects_1.Effect()
], SwgUrlCheckEffects.prototype, "urlReportEffects$", void 0);
exports.SwgUrlCheckEffects = SwgUrlCheckEffects;
