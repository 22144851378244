"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.urlsScannedPerDay = exports.ExportDownloadFormats = exports.yValueKeys = exports.urlsScanGraphHex = void 0;
exports.urlsScanGraphHex = {
    inbound: '#7B1FA2',
    maliciousInbound: '#AE52D4',
    outbound: '#0097A7',
    maliciousOutbound: '#56C8D8',
    journal: '#C2185B',
    maliciousJournal: '#FF94C2'
};
exports.yValueKeys = {
    inbound: 'inbound_clean',
    maliciousInbound: 'inbound_malicious',
    outbound: 'outbound_clean',
    maliciousOutbound: 'outbound_malicious',
    journal: 'journal_clean',
    maliciousJournal: 'journal_malicious'
};
exports.ExportDownloadFormats = {
    png: {
        type: 'png',
        label: 'Download PNG'
    },
    pdf: {
        type: 'pdf',
        label: 'Download PDF'
    },
    svg: {
        type: 'svg',
        label: 'Download SVG'
    },
    jpg: {
        type: 'jpg',
        label: 'Download JPG'
    }
};
exports.urlsScannedPerDay = 'urls_scanned_per_day';
