"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfirmationModalComponent = void 0;
const actions = require("../../actions");
class ConfirmationModalComponent {
    constructor(store, data) {
        this.store = store;
        this.data = data;
    }
    getNumberOfMessages() {
        return this.data.recall.smIDs.length;
    }
    confirm() {
        this.store.dispatch(new actions.RecallYesAction(this.data.recall));
    }
    cancel() {
        this.store.dispatch(new actions.RecallNoAction());
    }
}
exports.ConfirmationModalComponent = ConfirmationModalComponent;
