<div class="mc-tree-view-wrapper-panel">
  <div class="headline">
    <span class="header tags">{{defaultTitle | translate}}</span>
    <span>
      <mc-tooltip class="tags" *ngIf="showTooltip"
        popoverContent="{{ popoverContent | translate }}"
        linkText="{{ linkText | translate }}"
        linkUrl="{{ linkUrl }}"
        placement="right">
      </mc-tooltip>
    </span>
    <div class="collapse-controls pull-right">
      <div class="btn-group">
        <button
            class="btn btn-secondary btn-xs"
            (click)="collapse()"
            [ngClass]="{ 'btn-active' : !isExpanded }">
          {{ '$I18N_MESSAGE_CENTER_MESSAGE_INFO_TREE_VIEW_WRAPPER_PANEL.COLLAPSE' | translate }}
        </button>
        <button
            class="btn btn-secondary btn-xs"
            (click)="expand()"
            [ngClass]="{ 'btn-active' : isExpanded }">
          {{ '$I18N_MESSAGE_CENTER_MESSAGE_INFO_TREE_VIEW_WRAPPER_PANEL.EXPAND' | translate }}
        </button>
      </div>
    </div>
  </div>
  <hr class="top-line">
  <div class="content">
    <ng-content></ng-content>
  </div>
  <hr class="bottom-line">
</div>
