"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClearAppliesToLocations = exports.LoadAppliesToLocationsPaginationReset = exports.LoadAppliesToLocationsPagination = exports.LoadAppliesToLocationsFailure = exports.LoadAppliesToLocationsSuccess = exports.LoadAppliesToLocations = exports.CLEAR_APPLIES_TO_LOCATIONS = exports.LOAD_APPLIES_TO_LOCATIONS_PAGINATION_RESET = exports.LOAD_APPLIES_TO_LOCATIONS_PAGINATION = exports.LOAD_APPLIES_TO_LOCATIONS_FAILURE = exports.LOAD_APPLIES_TO_LOCATIONS_SUCCESS = exports.LOAD_APPLIES_TO_LOCATIONS = void 0;
exports.LOAD_APPLIES_TO_LOCATIONS = '[Applies_to] load applies to Locations';
exports.LOAD_APPLIES_TO_LOCATIONS_SUCCESS = '[Applies_to] load applies to Locations success';
exports.LOAD_APPLIES_TO_LOCATIONS_FAILURE = '[Applies_to] load applies to Locations failure';
exports.LOAD_APPLIES_TO_LOCATIONS_PAGINATION = '[Applies_to] applies to Locations pagination';
exports.LOAD_APPLIES_TO_LOCATIONS_PAGINATION_RESET = '[Applies_to] applies to Locations pagination reset';
exports.CLEAR_APPLIES_TO_LOCATIONS = '[Applies_to] clear applies to Locations';
class LoadAppliesToLocations {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOAD_APPLIES_TO_LOCATIONS;
    }
}
exports.LoadAppliesToLocations = LoadAppliesToLocations;
class LoadAppliesToLocationsSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOAD_APPLIES_TO_LOCATIONS_SUCCESS;
    }
}
exports.LoadAppliesToLocationsSuccess = LoadAppliesToLocationsSuccess;
class LoadAppliesToLocationsFailure {
    constructor() {
        this.type = exports.LOAD_APPLIES_TO_LOCATIONS_FAILURE;
    }
}
exports.LoadAppliesToLocationsFailure = LoadAppliesToLocationsFailure;
class LoadAppliesToLocationsPagination {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOAD_APPLIES_TO_LOCATIONS_PAGINATION;
    }
}
exports.LoadAppliesToLocationsPagination = LoadAppliesToLocationsPagination;
class LoadAppliesToLocationsPaginationReset {
    constructor() {
        this.type = exports.LOAD_APPLIES_TO_LOCATIONS_PAGINATION_RESET;
    }
}
exports.LoadAppliesToLocationsPaginationReset = LoadAppliesToLocationsPaginationReset;
class ClearAppliesToLocations {
    constructor() {
        this.type = exports.CLEAR_APPLIES_TO_LOCATIONS;
    }
}
exports.ClearAppliesToLocations = ClearAppliesToLocations;
