"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PendoDnsSGCompleteInformationProvider = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const base_pendo_information_provider_1 = require("../../base-pendo-information.provider");
class PendoDnsSGCompleteInformationProvider extends base_pendo_information_provider_1.BasePendoInformationProvider {
    constructor(packages, capabilities, swgPolicyService) {
        super();
        this.packages = packages;
        this.capabilities = capabilities;
        this.swgPolicyService = swgPolicyService;
    }
    getInformation() {
        return this.packages.hasAnyPackage(PendoDnsSGCompleteInformationProvider.WEB_SEC_PACKAGES).pipe(operators_1.switchMap((hasPackage) => {
            if (hasPackage) {
                return this.capabilities
                    .hasCapability(PendoDnsSGCompleteInformationProvider.SWG_CONFIGSITE_CAP)
                    .pipe(operators_1.switchMap((hasCapability) => {
                    if (hasCapability) {
                        return this.swgPolicyService.getLocations(null).pipe(operators_1.map(res => res.first), operators_1.map(webRanges => !!webRanges && !!webRanges.webIPRanges && webRanges.webIPRanges.length > 0), operators_1.switchMap(complete => rxjs_1.of({ account: { dnssgComplete: complete } })), operators_1.catchError(() => rxjs_1.of({})));
                    }
                    else {
                        return rxjs_1.of({});
                    }
                }));
            }
            else {
                return rxjs_1.of({});
            }
        }));
    }
}
exports.PendoDnsSGCompleteInformationProvider = PendoDnsSGCompleteInformationProvider;
PendoDnsSGCompleteInformationProvider.WEB_SEC_PACKAGES = ['1080', '1072'];
PendoDnsSGCompleteInformationProvider.SWG_CONFIGSITE_CAP = 'Permission.SWG_CONFIGURE_SITES_READ';
