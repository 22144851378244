"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RuleEffect = void 0;
const actions = require("../actions/rule.actions");
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const notification_actions_1 = require("app-new/components/notification/actions/notification.actions");
const operators_1 = require("rxjs/operators");
const rule_service_1 = require("../services/rule.service");
const lexicon_factory_1 = require("../factories/lexicon.factory");
const queue_configs_factory_1 = require("../factories/queue-configs.factory");
const state_enum_1 = require("../types/state.enum");
const set_rule_state_confirmation_component_1 = require("../components/set-rule-state-confirmation/set-rule-state-confirmation.component");
const rule_config_builder_1 = require("../model/rule/rule-config.builder");
const rule_details_actions_1 = require("../actions/rule.details.actions");
class RuleEffect {
    constructor(actions$, ruleService, translateService, stateService, modalService) {
        this.actions$ = actions$;
        this.ruleService = ruleService;
        this.translateService = translateService;
        this.stateService = stateService;
        this.modalService = modalService;
        this.create$ = this.actions$.pipe(effects_1.ofType(actions.RuleWizardActions.CREATE), operators_1.switchMap((action) => {
            return this.ruleService.createRule(action.paypload).pipe(operators_1.map((response) => {
                if (response.hasErrors) {
                    return new actions.CreateActionFail(this.getNotificationMessage('FAILURE'), response.fail[0]);
                }
                return new actions.CreateActionSuccess(this.getNotificationMessage('SUCCESS'));
            }), operators_1.catchError(response => {
                return rxjs_1.of(new actions.CreateActionFail(this.getNotificationMessage('FAILURE'), response.fail[0]));
            }));
        }));
        this.createSuccess$ = this.actions$.pipe(effects_1.ofType(actions.RuleWizardActions.CREATE_SUCCESS), operators_1.map((action) => {
            this.stateService.$state.go('archive-supervision-list', {
                callerWizardId: rule_service_1.RuleService.WizardId
            });
            return new notification_actions_1.NotificationShowSuccessAction(action.paypload);
        }));
        this.createFail$ = this.actions$.pipe(effects_1.ofType(actions.RuleWizardActions.CREATE_FAIL), operators_1.switchMap((action) => {
            if (!action.failure.errors) {
                return [new notification_actions_1.NotificationShowFailAction(action.message)];
            }
            return action.failure.errors.map(error => new notification_actions_1.NotificationShowFailAction(this.translateService.instant(this.ruleService.getApiErrorTranslateKey(this.prepareErrorCode(error)))));
        }));
        this.update$ = this.actions$.pipe(effects_1.ofType(actions.RuleWizardActions.UPDATE), operators_1.switchMap((action) => {
            return this.ruleService.updateRule(action.ruleId, action.rule).pipe(operators_1.map((response) => {
                if (response.hasErrors) {
                    return new actions.UpdateActionFail(this.getNotificationMessage('UPDATE_FAILURE'), response.fail[0]);
                }
                return new actions.UpdateActionSuccess(this.getNotificationMessage('UPDATE_SUCCESS'));
            }), operators_1.catchError(response => {
                return rxjs_1.of(new actions.UpdateActionFail(this.getNotificationMessage('UPDATE_FAILURE'), response.fail[0]));
            }));
        }));
        this.updateSuccess$ = this.actions$.pipe(effects_1.ofType(actions.RuleWizardActions.UPDATE_SUCCESS), operators_1.map((action) => {
            this.stateService.$state.go('archive-supervision-list', {
                callerWizardId: rule_service_1.RuleService.WizardId
            });
            return new notification_actions_1.NotificationShowSuccessAction(action.message);
        }));
        this.updateFail$ = this.actions$.pipe(effects_1.ofType(actions.RuleWizardActions.UPDATE_FAIL), operators_1.switchMap((action) => {
            if (!action.failure.errors) {
                return [new notification_actions_1.NotificationShowFailAction(action.message)];
            }
            return action.failure.errors.map(error => new notification_actions_1.NotificationShowFailAction(this.translateService.instant(this.ruleService.getApiErrorTranslateKey(this.prepareErrorCode(error), true))));
        }));
        this.getLexicons$ = this.actions$.pipe(effects_1.ofType(actions.RuleWizardActions.GET_LEXICONS), operators_1.switchMap(() => {
            return this.ruleService.getLexicons().pipe(operators_1.map(response => {
                if (response.hasErrors) {
                    return new actions.GetLexiconsFailAction(response.fail[0]);
                }
                const lexicons = response.first.lexicons.map((lexicon) => lexicon_factory_1.LexiconFactory.BUILD_LEXICON(lexicon));
                return new actions.GetLexiconsSuccessAction(lexicons);
            }), operators_1.catchError(response => {
                return rxjs_1.of(new actions.GetLexiconsFailAction(response.fail[0]));
            }));
        }));
        this.getQueues$ = this.actions$.pipe(effects_1.ofType(actions.RuleWizardActions.GET_QUEUES), operators_1.switchMap((action) => {
            return this.ruleService.getQueues(action.filterBy).pipe(operators_1.map(response => {
                if (response.hasErrors) {
                    return new actions.GetQueuesFailAction(response.fail[0]);
                }
                const queues = response.first.queues.map((queue) => queue_configs_factory_1.QueueConfigFactory.BUILD_QUEUE_CONFIG(queue));
                return new actions.GetQueuesSuccessAction(queues);
            }), operators_1.catchError(response => {
                return rxjs_1.of(new actions.GetQueuesFailAction(response.fail[0]));
            }));
        }));
        this.setState$ = this.actions$.pipe(effects_1.ofType(actions.RuleWizardActions.SET_STATE), operators_1.switchMap((action) => {
            return this.modalService
                .open(set_rule_state_confirmation_component_1.SetRuleStateConfirmationComponent, {
                size: 'md',
                hasBackdrop: true,
                data: action.state === state_enum_1.RuleState.ACTIVE
            })
                .afterClose()
                .pipe(operators_1.map(confirmed => (confirmed ? this.setRuleState(action.ruleId, action.state) : rxjs_1.EMPTY)));
        }), operators_1.switchMap(a => a));
        this.setStateSuccess$ = this.actions$.pipe(effects_1.ofType(actions.RuleWizardActions.SET_STATE_SUCCESS), operators_1.map((action) => {
            this.stateService.$state.go('archive-supervision-list', { callerWizardId: rule_service_1.RuleService.WizardId }, { reload: true, inherit: false, notify: true });
            return new notification_actions_1.NotificationShowSuccessAction(action.message);
        }));
        this.setStateFail$ = this.actions$.pipe(effects_1.ofType(actions.RuleWizardActions.SET_STATE_FAIL), operators_1.switchMap((action) => {
            if (!action.failure.errors) {
                return [new notification_actions_1.NotificationShowFailAction(action.message)];
            }
            return action.failure.errors.map(error => new notification_actions_1.NotificationShowFailAction(this.translateService.instant(this.ruleService.getApiErrorTranslateKey(this.prepareErrorCode(error), true))));
        }));
    }
    getNotificationMessage(notificationkey) {
        const fullKey = `$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.NOTIFICATION.${notificationkey}`;
        return this.translateService.instant(fullKey);
    }
    prepareErrorCode(error) {
        if (error.field) {
            switch (error.code.toLowerCase()) {
                case 'err_validation_out_of_range':
                    switch (error.field.toLowerCase()) {
                        case 'samplingpercentage':
                            return `${error.code}_${error.field}`;
                    }
                    break;
                case 'err_validation_null':
                case 'err_validation_blank':
                    switch (error.field.toLowerCase()) {
                        case 'reviewqueueid':
                        case 'searchgroupid':
                        case 'messageroute':
                        case 'scheduletype':
                            return `${error.code}_${error.field}`;
                    }
            }
        }
        return error.code;
    }
    setRuleState(ruleId, ruleState) {
        return this.ruleService
            .updateRule(ruleId, new rule_config_builder_1.RuleConfigBuilder().withState(ruleState).build())
            .pipe(operators_1.switchMap((response) => {
            if (response.hasErrors) {
                return rxjs_1.of(new actions.SetRuleStateFailAction(this.getNotificationMessage(ruleState === state_enum_1.RuleState.ACTIVE ? 'ACTIVATION_FAILURE' : 'DEACTIVATION_FAILURE'), response.fail[0]));
            }
            return rxjs_1.of(new actions.SetRuleStateSuccessAction(this.getNotificationMessage(ruleState === state_enum_1.RuleState.ACTIVE ? 'ACTIVATION_SUCCESS' : 'DEACTIVATION_SUCCESS')), new rule_details_actions_1.SetRuleDetailsStateAction(ruleId, ruleState));
        }), operators_1.catchError(response => {
            return rxjs_1.of(new actions.SetRuleStateFailAction(this.getNotificationMessage(ruleState === state_enum_1.RuleState.ACTIVE ? 'ACTIVATION_FAILURE' : 'DEACTIVATION_FAILURE'), response.fail[0]));
        }));
    }
}
__decorate([
    effects_1.Effect()
], RuleEffect.prototype, "create$", void 0);
__decorate([
    effects_1.Effect()
], RuleEffect.prototype, "createSuccess$", void 0);
__decorate([
    effects_1.Effect()
], RuleEffect.prototype, "createFail$", void 0);
__decorate([
    effects_1.Effect()
], RuleEffect.prototype, "update$", void 0);
__decorate([
    effects_1.Effect()
], RuleEffect.prototype, "updateSuccess$", void 0);
__decorate([
    effects_1.Effect()
], RuleEffect.prototype, "updateFail$", void 0);
__decorate([
    effects_1.Effect()
], RuleEffect.prototype, "getLexicons$", void 0);
__decorate([
    effects_1.Effect()
], RuleEffect.prototype, "getQueues$", void 0);
__decorate([
    effects_1.Effect()
], RuleEffect.prototype, "setState$", void 0);
__decorate([
    effects_1.Effect()
], RuleEffect.prototype, "setStateSuccess$", void 0);
__decorate([
    effects_1.Effect()
], RuleEffect.prototype, "setStateFail$", void 0);
exports.RuleEffect = RuleEffect;
