"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgPolicyLoggingStepComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const operators_1 = require("rxjs/operators");
class SwgPolicyLoggingStepComponent {
    constructor(fb) {
        this.fb = fb;
        this.updateLoggingLevel = new core_1.EventEmitter();
    }
    ngOnInit() {
        this.loggingPageForm = this.fb.group({
            level: [this.loggingMode, forms_1.Validators.required]
        });
        this.loggingPageFormSubscription = this.loggingPageForm.valueChanges
            .pipe(operators_1.debounceTime(200))
            .subscribe(() => {
            this.updateLogSettings();
        });
    }
    isValid() {
        return true;
    }
    updateLogSettings() {
        this.updateLoggingLevel.emit(this.loggingPageForm.controls['level'].value);
        return false;
    }
    ngOnDestroy() {
        this.loggingPageFormSubscription.unsubscribe();
    }
}
exports.SwgPolicyLoggingStepComponent = SwgPolicyLoggingStepComponent;
