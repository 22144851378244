"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ1 = exports.ɵ0 = exports.selectOpenedIsLoading = exports.selectOpened = exports.selectThreatEventDetailsState = exports.reducer = exports.initialState = void 0;
const store_1 = require("@ngrx/store");
const swg_threat_event_details_actions_1 = require("../actions/swg-threat-event-details.actions");
exports.initialState = {
    id: null,
    opened: null,
    openedIsLoading: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case swg_threat_event_details_actions_1.SwgThreatEventDetailsActionsEnum.LOAD_THREAT_EVENT_DETAILS:
        case swg_threat_event_details_actions_1.SwgThreatEventDetailsActionsEnum.THREAT_EVENT_SIDEBAR_OPEN:
            return Object.assign(Object.assign({}, state), { openedIsLoading: true });
        case swg_threat_event_details_actions_1.SwgThreatEventDetailsActionsEnum.LOAD_THREAT_EVENT_DETAILS_SUCCESS:
            return Object.assign(Object.assign({}, state), { openedIsLoading: false, opened: action.payload });
        case swg_threat_event_details_actions_1.SwgThreatEventDetailsActionsEnum.LOAD_THREAT_EVENT_DETAILS_FAILURE:
            return Object.assign(Object.assign({}, state), { openedIsLoading: false, opened: null });
        default:
            return state;
    }
}
exports.reducer = reducer;
exports.selectThreatEventDetailsState = store_1.createFeatureSelector('swgThreatEventDetails');
const ɵ0 = state => state.opened;
exports.ɵ0 = ɵ0;
exports.selectOpened = store_1.createSelector(exports.selectThreatEventDetailsState, ɵ0);
const ɵ1 = state => state.openedIsLoading;
exports.ɵ1 = ɵ1;
exports.selectOpenedIsLoading = store_1.createSelector(exports.selectThreatEventDetailsState, ɵ1);
