"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
angular.module('contact.view.service', [])
    .factory('contactViewService', [function () {
        return {
            PHONE_TYPE: {
                ASSISTANT_PHONE: '$I18N_CONTACT_DETAIL_ASSISTANT_PHONE',
                BUSINESS_FAX: '$I18N_CONTACT_DETAIL_BUSINESS_FAX',
                BUSINESS_PHONE: '$I18N_CONTACT_DETAIL_BUSINESS_PHONE',
                BUSINESS_PHONE_2: '$I18N_CONTACT_DETAIL_BUSINESS_PHONE_2',
                CALLBACK: '$I18N_CONTACT_DETAIL_CALLBACK',
                CAR_PHONE: '$I18N_CONTACT_DETAIL_CAR_PHONE',
                COMPANY_MAIN_PHONE: '$I18N_CONTACT_DETAIL_COMPANY_MAIN_PHONE',
                HOME_FAX: '$I18N_CONTACT_DETAIL_HOME_FAX',
                HOME_PHONE: '$I18N_CONTACT_DETAIL_HOME_PHONE',
                HOME_PHONE_2: '$I18N_CONTACT_DETAIL_HOME_PHONE_2',
                ISDN: '$I18N_CONTACT_DETAIL_ISDN',
                MOBILE_PHONE: '$I18N_CONTACT_DETAIL_MOBILE_PHONE',
                OTHER_FAX: '$I18N_CONTACT_DETAIL_OTHER_FAX',
                OTHER_TELEPHONE: '$I18N_CONTACT_DETAIL_OTHER_TELEPHONE',
                PAGER: '$I18N_CONTACT_DETAIL_PAGER',
                PRIMARY_PHONE: '$I18N_CONTACT_DETAIL_PRIMARY_PHONE',
                RADIO_PHONE: '$I18N_CONTACT_DETAIL_RADIO_PHONE',
                TELEX: '$I18N_CONTACT_DETAIL_TELEX',
                TTY_TDD_PHONE: '$I18N_CONTACT_DETAIL_TTY_TDD_PHONE',
                BUSINESS_MOBILE: '$I18N_CONTACT_DETAIL_BUSINESS_MOBILE',
                IP_PHONE: '$I18N_CONTACT_DETAIL_IP_PHONE',
                MMS: '$I18N_CONTACT_DETAIL_MMS',
                MSN: '$I18N_CONTACT_DETAIL_MSN'
            },
            ADDRESS_TYPE: {
                BUSINESS: '$I18N_CONTACT_DETAIL_BUSINESS_ADDRESS',
                HOME: '$I18N_CONTACT_DETAIL_HOME_ADDRESS',
                OTHER: '$I18N_CONTACT_DETAIL_BUSINESS_OTHER_ADDRESS'
            }
        };
    }]);
