"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DetailComponent = void 0;
require("app/services/services/backup/mailboxes/mailbox-details/slides/mailbox-email-export.tpl.html");
require("app/services/services/backup/mailboxes/mailbox-details/slides/mailbox-email-restore.tpl.html");
const contentActions = require("app-new/components/tree/actions/contents.actions");
const folderActions = require("app-new/components/tree/actions/folders.actions");
const reducers = require("app-new/components/tree/reducers");
class DetailComponent {
    constructor(store, $aside, emailViewService, $state) {
        this.store = store;
        this.$aside = $aside;
        this.emailViewService = emailViewService;
        this.$state = $state;
    }
    ngOnInit() {
        this.store.dispatch(new folderActions.LoadExchangeFoldersAction({
            mailbox: 'aaron@onprem.coreuidev.net',
            viewType: 'archive',
            parentId: 'MTOKEN:eNqrVirIzCwpTsvPSUktykxRslIytDI0NzMwsIxX0lEqSy0qzszPU7IyrAUAHw0NDw'
        }));
        this.folders$ = this.store.select(reducers.getFolders);
        this.rootFolder$ = this.store.select(reducers.getRootFolder);
    }
    getLastSyncFolders(node) {
        this.store.dispatch(new folderActions.LoadExchangeFoldersAction({
            mailbox: 'aaron@onprem.coreuidev.net',
            viewType: 'live',
            parentId: node.getId()
        }));
        this.folders$ = this.store.select(reducers.getFolders);
        if (node.getContentType() === 'CALENDAR') {
            this.store.dispatch(new contentActions.LoadFolderCalendarsAction({
                folderId: node.getId(),
                mailbox: 'aaron@onprem.coreuidev.net',
                viewType: 'live'
            }));
            this.emails$ = this.store.select(reducers.getContents);
        }
        else if (node.getContentType() === 'CONTACT') {
            this.store.dispatch(new contentActions.LoadFolderContactsAction({
                folderId: node.getId(),
                mailbox: 'aaron@onprem.coreuidev.net',
                viewType: 'live'
            }));
            this.emails$ = this.store.select(reducers.getContents);
        }
        else {
            this.store.dispatch(new contentActions.LoadFolderEmailsAction({
                folderId: node.getId(),
                mailbox: 'aaron@onprem.coreuidev.net',
                viewType: 'live'
            }));
            this.emails$ = this.store.select(reducers.getContents);
        }
    }
    getName() {
        return this.$state.params.name || 'I did not get a name';
    }
    getMail() {
        return this.$state.params.mailBox || 'ididnotget@aname.com';
    }
    openExport() {
        const exportHtmlFile = 'services/services/backup/mailboxes/mailbox-details/slides/mailbox-email-export.tpl.html';
        const asideInstance = this.$aside.open({
            templateUrl: exportHtmlFile,
            placement: 'right',
            size: 'lg',
            backdrop: true,
            windowClass: 'hidden-xs',
            backdropClass: 'hidden-xs',
            controller: 'MailBoxesDetailsExportController as exportCtrl',
            resolve: {
                mailbox: () => '',
                viewType: () => '',
                folderPaths: () => () => ['', ''],
                dismissFunction: () => () => asideInstance.close(),
                userEmailAddress: () => 'ishan.aggarwal@gmail.com'
            }
        });
    }
    openRestore() {
        const restoreHtmlFile = 'services/services/backup/mailboxes/mailbox-details/slides/mailbox-email-restore.tpl.html';
        const asideInstance = this.$aside.open({
            templateUrl: restoreHtmlFile,
            placement: 'right',
            size: 'lg',
            backdrop: true,
            windowClass: 'hidden-xs',
            backdropClass: 'hidden-xs',
            controller: 'MailBoxesDetailsRestoreController as restoreCtrl',
            resolve: {
                mailbox: () => '',
                viewType: () => '',
                folderPaths: () => () => ['', ''],
                dismissFunction: () => () => asideInstance.close(),
                userEmailAddress: () => 'ishan.aggarwal@gmail.com'
            }
        });
    }
}
exports.DetailComponent = DetailComponent;
