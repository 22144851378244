'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./search-box-extended.controller"); //search-box-extended.controller
require("./search-box-extended-directive"); //search-box-extended.directive
// html and css 
require("./search-box-extended.less");
require("./search-box-extended.tpl.html");
angular.module('search-box-extended', [
    'search-box-extended.controller',
    'search-box-extended.directive'
]);
