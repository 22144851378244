"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomDomainBlockEffects = void 0;
const effects_1 = require("@ngrx/effects");
const operators_1 = require("rxjs/operators");
const rxjs_1 = require("rxjs");
const table_store_1 = require("@mimecast-ui/table-store");
const actions = require("../actions/configurations-table.action");
const custom_domain_block_service_1 = require("../services/custom-domain-block.service");
const custom_domain_block_details_factory_1 = require("../factory/custom-domain-block-details.factory");
const notification_actions_1 = require("app-new/components/notification/actions/notification.actions");
const user_location_sidebar_actions_1 = require("app-new/components/user-location-selector/actions/user-location-sidebar.actions");
const delete_confirmation_modal_component_1 = require("../components/aside/delete-confirmation-modal/delete-confirmation-modal.component");
const view_policy_component_1 = require("../components/aside/view-policy.component");
class CustomDomainBlockEffects {
    constructor(store, actions$, cdbService, stateService, translateService, modalService, asideService) {
        this.store = store;
        this.actions$ = actions$;
        this.cdbService = cdbService;
        this.stateService = stateService;
        this.translateService = translateService;
        this.modalService = modalService;
        this.asideService = asideService;
        this.showCreatePolicyWizard$ = this.actions$.pipe(effects_1.ofType(actions.OPEN_CREATE_NEW_POLICY), operators_1.switchMap(() => {
            this.stateService.$state.go('custom-domain-block-policy-wizard');
            return rxjs_1.EMPTY;
        }));
        this.openSidePaneAndGetPolicy$ = this.actions$.pipe(effects_1.ofType(actions.OPEN_SIDE_PANE), operators_1.map((action) => {
            this.sidePane = this.asideService.open(view_policy_component_1.ViewPolicyComponent, {
                size: 'md',
                position: 'right'
            });
            return new actions.GetPolicyAction(action.payload);
        }));
        this.getPolicy$ = this.actions$.pipe(effects_1.ofType(actions.GET_POLICY), operators_1.map((action) => action.payload), operators_1.switchMap(policy => {
            return this.cdbService.getPolicyDetails(policy.definitionId).pipe(operators_1.map(response => {
                if (response.hasErrors) {
                    return new actions.GetPolicyFailAction(response.fail[0]);
                }
                const customDomainBlockDetails = custom_domain_block_details_factory_1.CustomDomainBlockDetailsFactory.BUILD_CUSTOM_DOMAIN_BLOCK_DETAILS(response.first.policy);
                return new actions.GetPolicySuccessAction(customDomainBlockDetails);
            }), operators_1.catchError(response => {
                return rxjs_1.of(new actions.GetPolicyFailAction(response.fail[0]));
            }), operators_1.withLatestFrom(this.store.select(table_store_1.tableReducer.getTableData(custom_domain_block_service_1.CustomDomainBlockService.CUSTOM_DOMAIN_BLOCK_TABLE))), operators_1.switchMap(([action, tableData]) => {
                return [
                    action,
                    this.getPaginationInfo(policy, tableData),
                    new table_store_1.SetSelectedRowAction({
                        tableId: custom_domain_block_service_1.CustomDomainBlockService.CUSTOM_DOMAIN_BLOCK_TABLE,
                        item: policy
                    })
                ];
            }));
        }));
        this.create$ = this.actions$.pipe(effects_1.ofType(actions.CREATE_POLICY), operators_1.switchMap((action) => {
            return this.cdbService.createPolicy(action.payload).pipe(operators_1.map(response => {
                if (response.hasErrors) {
                    return new actions.CreateUpdateActionFail(this.getNotificationMessage('CREATE_FAILURE'), response.fail[0]);
                }
                return new actions.CreateUpdateActionSuccess(this.getNotificationMessage('CREATE_SUCCESS'));
            }), operators_1.catchError(response => {
                return rxjs_1.of(new actions.CreateUpdateActionFail(this.getNotificationMessage('CREATE_FAILURE'), response.fail[0]));
            }));
        }));
        this.update$ = this.actions$.pipe(effects_1.ofType(actions.UPDATE_POLICY), operators_1.switchMap((action) => {
            return this.cdbService.updatePolicy(action.payload).pipe(operators_1.mergeMap(response => {
                const responseActions = [];
                if (response.hasErrors) {
                    const err = (response.fail[0].errors[0].code && response.fail[0].errors[0].code.toUpperCase()) ||
                        'UPDATE_FAILURE';
                    responseActions.push(new actions.CreateUpdateActionFail(this.getNotificationMessage(err), response.fail[0]));
                }
                else {
                    responseActions.push(new actions.CreateUpdateActionSuccess(this.getNotificationMessage('UPDATE_SUCCESS')));
                    responseActions.push(new table_store_1.LoadAction({
                        tableId: custom_domain_block_service_1.CustomDomainBlockService.CUSTOM_DOMAIN_BLOCK_TABLE
                    }));
                }
                return responseActions;
            }), operators_1.catchError(response => {
                return rxjs_1.of(new actions.CreateUpdateActionFail(this.getNotificationMessage('CREATE_FAILURE'), response.fail[0]));
            }));
        }));
        this.createUpdateSuccess$ = this.actions$.pipe(effects_1.ofType(actions.CREATE_UPDATE_SUCCESS), operators_1.map((action) => {
            this.stateService.$state.go('custom-domain-block', {
                reload: true
            });
            return new notification_actions_1.NotificationShowSuccessAction(action.message);
        }));
        this.createUpdateFail$ = this.actions$.pipe(effects_1.ofType(actions.CREATE_UPDATE_FAIL), operators_1.switchMap((action) => {
            return [new notification_actions_1.NotificationShowFailAction(action.message)];
        }));
        this.deletePolicy$ = this.actions$.pipe(effects_1.ofType(actions.DELETE_POLICY), operators_1.map((action) => action.definitionId), operators_1.switchMap(definitionId => {
            return this.cdbService.deletePolicy(definitionId).pipe(operators_1.mergeMap(response => {
                if (response.hasErrors) {
                    return this.getDeletePolicyError(response);
                }
                return [
                    new notification_actions_1.NotificationShowAction({
                        type: 'success',
                        config: {
                            msg: this.translateService.instant('$I18N_CUSTOM_DOMAIN_BLOCK_VIEW_POLICY.NOTIFICATION.DELETE_SUCCESS')
                        }
                    }),
                    new actions.DeletePolicySuccessAction(),
                    new table_store_1.LoadAction({
                        tableId: custom_domain_block_service_1.CustomDomainBlockService.CUSTOM_DOMAIN_BLOCK_TABLE
                    })
                ];
            }), operators_1.catchError(response => this.getDeletePolicyError(response)));
        }));
        this.groupAdded$ = this.actions$.pipe(effects_1.ofType(actions.ADD_NOTIFY_GROUP), operators_1.map(() => {
            return new user_location_sidebar_actions_1.UserLocationAppliesToClose();
        }));
        this.groupProfileAdded$ = this.actions$.pipe(effects_1.ofType(actions.ADD_PROFILE_GROUP), operators_1.map(() => {
            return new user_location_sidebar_actions_1.UserLocationAppliesToClose();
        }));
        this.redirectEmailAdded$ = this.actions$.pipe(effects_1.ofType(actions.ADD_REDIRECT_EMAIL), operators_1.map(() => {
            return new user_location_sidebar_actions_1.UserLocationAppliesToClose();
        }));
        this.redirectGroupAdded$ = this.actions$.pipe(effects_1.ofType(actions.ADD_REDIRECT_GROUP), operators_1.map(() => {
            return new user_location_sidebar_actions_1.UserLocationAppliesToClose();
        }));
        this.showDeleteConfirmation$ = this.actions$.pipe(effects_1.ofType(actions.SHOW_DELETE_CONFIRMATION), operators_1.switchMap((action) => {
            this.deleteModalInstance = this.modalService.open(delete_confirmation_modal_component_1.DeleteConfirmationModalComponent, {
                size: 'md',
                hasBackdrop: true,
                disableClose: true,
                data: { policy: action.payload }
            });
            return rxjs_1.EMPTY;
        }));
        this.closeSidePane$ = this.actions$.pipe(effects_1.ofType(actions.CLOSE_SIDE_PANE), operators_1.switchMap(() => {
            this.sidePane.close();
            this.sidePane = undefined;
            return rxjs_1.EMPTY;
        }));
        this.closeDeleteConfirmation$ = this.actions$.pipe(effects_1.ofType(actions.DELETE_NO), operators_1.switchMap(() => {
            this.deleteModalInstance.close();
            return rxjs_1.EMPTY;
        }));
        this.confirmDeleteConfirmation$ = this.actions$.pipe(effects_1.ofType(actions.DELETE_YES), operators_1.map((action) => {
            this.deleteModalInstance.close();
            this.sidePane.close();
            this.sidePane = undefined;
            return new actions.DeletePolicyAction(action.payload);
        }));
    }
    getNotificationMessage(notificationkey) {
        const fullKey = `$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.NOTIFICATION.${notificationkey}`;
        return this.translateService.instant(fullKey);
    }
    getDeletePolicyError(response) {
        return rxjs_1.merge(rxjs_1.of(new notification_actions_1.NotificationShowAction({
            type: 'error',
            config: {
                msg: this.translateService.instant('$I18N_CUSTOM_DOMAIN_BLOCK_VIEW_POLICY.NOTIFICATION.DELETE_FAIL')
            }
        })), rxjs_1.of(new actions.DeletePolicyFailAction(response.fail[0])));
    }
    getPaginationInfo(currentItem, tableData = []) {
        const currentIndex = tableData.findIndex(value => value.definitionId === currentItem.definitionId);
        const previous = currentIndex !== undefined && currentIndex > 0 ? tableData[currentIndex - 1] : undefined;
        const next = currentIndex !== undefined && currentIndex < tableData.length
            ? tableData[currentIndex + 1]
            : undefined;
        return new actions.SetDetailsPaginationInfoAction(previous, currentItem, next, currentIndex, tableData.length);
    }
}
__decorate([
    effects_1.Effect()
], CustomDomainBlockEffects.prototype, "showCreatePolicyWizard$", void 0);
__decorate([
    effects_1.Effect()
], CustomDomainBlockEffects.prototype, "openSidePaneAndGetPolicy$", void 0);
__decorate([
    effects_1.Effect()
], CustomDomainBlockEffects.prototype, "getPolicy$", void 0);
__decorate([
    effects_1.Effect()
], CustomDomainBlockEffects.prototype, "create$", void 0);
__decorate([
    effects_1.Effect()
], CustomDomainBlockEffects.prototype, "update$", void 0);
__decorate([
    effects_1.Effect()
], CustomDomainBlockEffects.prototype, "createUpdateSuccess$", void 0);
__decorate([
    effects_1.Effect()
], CustomDomainBlockEffects.prototype, "createUpdateFail$", void 0);
__decorate([
    effects_1.Effect()
], CustomDomainBlockEffects.prototype, "deletePolicy$", void 0);
__decorate([
    effects_1.Effect()
], CustomDomainBlockEffects.prototype, "groupAdded$", void 0);
__decorate([
    effects_1.Effect()
], CustomDomainBlockEffects.prototype, "groupProfileAdded$", void 0);
__decorate([
    effects_1.Effect()
], CustomDomainBlockEffects.prototype, "redirectEmailAdded$", void 0);
__decorate([
    effects_1.Effect()
], CustomDomainBlockEffects.prototype, "redirectGroupAdded$", void 0);
__decorate([
    effects_1.Effect()
], CustomDomainBlockEffects.prototype, "showDeleteConfirmation$", void 0);
__decorate([
    effects_1.Effect()
], CustomDomainBlockEffects.prototype, "closeSidePane$", void 0);
__decorate([
    effects_1.Effect()
], CustomDomainBlockEffects.prototype, "closeDeleteConfirmation$", void 0);
__decorate([
    effects_1.Effect()
], CustomDomainBlockEffects.prototype, "confirmDeleteConfirmation$", void 0);
exports.CustomDomainBlockEffects = CustomDomainBlockEffects;
