"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PendoTtpAttachmentProtectPackagePoliciesProvider = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const base_pendo_information_provider_1 = require("../../base-pendo-information.provider");
class PendoTtpAttachmentProtectPackagePoliciesProvider extends base_pendo_information_provider_1.BasePendoInformationProvider {
    constructor(service) {
        super();
        this.service = service;
        this.TTP_ATTACHMENT_PROTECT_PKG_REF_CODE = '1056';
        this.TTP_ATTACHMENT_PROTECT_POLICY = 20820;
        this.requestActive = {
            onlyActivePolicies: true,
            packageRefCode: this.TTP_ATTACHMENT_PROTECT_PKG_REF_CODE
        };
        this.requestAll = {
            onlyActivePolicies: false,
            packageRefCode: this.TTP_ATTACHMENT_PROTECT_PKG_REF_CODE
        };
    }
    getInformation() {
        let PKG_TTP_AP;
        let APProtectPoliciesCount = 0;
        let APProtectActivePoliciesCount = 0;
        return rxjs_1.combineLatest([
            this.service.getPackagePolicies(this.requestActive),
            this.service.getPackagePolicies(this.requestAll)
        ]).pipe(operators_1.map(([active, all]) => {
            if (!active.packageRefPolicies[this.TTP_ATTACHMENT_PROTECT_PKG_REF_CODE]) {
                PKG_TTP_AP = false;
            }
            else {
                PKG_TTP_AP = true;
                active.packageRefPolicies[this.TTP_ATTACHMENT_PROTECT_PKG_REF_CODE]
                    .filter(policy => policy.policyType === this.TTP_ATTACHMENT_PROTECT_POLICY)
                    .forEach(policy => (APProtectActivePoliciesCount += policy.count));
                all.packageRefPolicies[this.TTP_ATTACHMENT_PROTECT_PKG_REF_CODE]
                    .filter(policy => policy.policyType === this.TTP_ATTACHMENT_PROTECT_POLICY)
                    .forEach(policy => (APProtectPoliciesCount += policy.count));
            }
            return {
                account: {
                    PKG_TTP_AP,
                    APProtectActivePoliciesCount,
                    APProtectPoliciesCount
                }
            };
        }));
    }
}
exports.PendoTtpAttachmentProtectPackagePoliciesProvider = PendoTtpAttachmentProtectPackagePoliciesProvider;
