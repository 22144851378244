"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./mail-selector-aside.controller"); //mail-selector-aside.controller
/**
 * Created by dgomez on 21/09/2016.
 */
angular.module('mail-selector-aside.service', ['mail-selector-aside.controller'])
    .factory('mailSelectorAsideService', ['$aside', function ($aside) {
        return {
            openMailSelector
        };
        function openMailSelector() {
            const asideInstance = $aside.open({
                templateUrl: 'services/services/backup/components/mail-selector-aside/mail-selector-aside.tpl.html',
                placement: 'right',
                size: 'lg',
                backdrop: true,
                windowClass: 'hidden-xs',
                backdropClass: 'hidden-xs',
                controller: angular.noop,
                resolve: {
                    dismissFunction() {
                        return function (selectedEmail) {
                            asideInstance.close(selectedEmail);
                        };
                    }
                }
            });
            return asideInstance.result;
        }
    }]);
