"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getApiIsProcessing = exports.getAdvancedFileTypes = exports.reducer = exports.initialState = void 0;
const actions = require("../../actions/advanced-file-types.actions");
exports.initialState = {
    advancedFileTypes: [],
    apiIsProcessing: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.SWG_POLICY_LOAD_ADVANCED_FILE_TYPES:
            return Object.assign(Object.assign({}, state), { apiIsProcessing: true });
        case actions.SWG_POLICY_LOAD_ADVANCED_FILE_TYPES_SUCCESS:
            return Object.assign(Object.assign({}, state), { advancedFileTypes: action.payload, apiIsProcessing: false });
        case actions.SWG_POLICY_LOAD_ADVANCED_FILE_TYPES_FAILURE:
            return Object.assign(Object.assign({}, state), { apiIsProcessing: false });
        case actions.SWG_POLICY_CLEAR_ADVANCED_FILE_TYPES:
            return Object.assign({}, exports.initialState);
        default:
            return state;
    }
}
exports.reducer = reducer;
const getAdvancedFileTypes = (state) => state.advancedFileTypes;
exports.getAdvancedFileTypes = getAdvancedFileTypes;
const getApiIsProcessing = (state) => state.apiIsProcessing;
exports.getApiIsProcessing = getApiIsProcessing;
