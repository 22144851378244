"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./list-extra-column-directive"); //layout.list-extra-column.directive
// html and css 
require("./list-extra-column.less");
require("./list-extra-column.tpl.html");
(function () {
    'use strict';
    /* ======================================================== *\

     = layout / list-extra-column

     \* ======================================================== */
    angular.module('layout.list-extra-column', ['layout.list-extra-column.directive']);
}());
