'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("mimecast-web-components/src/app/components/checkbox/checkbox-directive"); //checkbox.directive
require("app/services/account/package-info/package-info.service"); //account.package-info.service
require("./package-info-list.controller"); //account.package-info-list.controller
// html and css
require("./package-info-list-lfs-confirmation.tpl.html");
require("./package-info-list.less");
require("./package-info-list.tpl.html");
/**
 * Created by psambandam on 08/12/2016.
 */
angular.module('account.package-info-list', ['checkbox.directive', 'account.package-info.service', 'account.package-info-list.controller'])
    .constant('BUNDLES_PKGS_CONSTANTS', {
    'PRODUCT_PREFIX': 'Mimecast',
    'LFS_PKG_PREFIX': 'Large File Send'
})
    .config(['$stateProvider', function ($stateProvider) {
        $stateProvider
            .state('account-package-info', {
            url: '/services/account/package-info/',
            data: {
                tabId: 'account-package-info-tabid',
                tabTitle: '$I18N_ACCOUNT_PACKAGE_INFO_HEADER',
                tabHide: false,
                tabReload: 'true'
            },
            views: {
                'main': {
                    templateUrl: 'services/account/package-info/package-info-list/package-info-list.tpl.html',
                }
            }
        });
    }]);
