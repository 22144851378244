"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.appIcons = exports.sanctionListcolumns = exports.columns = void 0;
exports.columns = ['check-row', 'name', 'category', 'status', 'action'];
exports.sanctionListcolumns = [
    'check-row',
    'name',
    'count',
    'category',
    'status',
    'lastAccessed'
];
exports.appIcons = {
    ADOBE_BEHANCE: 'fa-behance',
    AMAZON: 'fa-amazon',
    BANDCAMP: 'fa-bandcamp',
    BITBUCKET: 'fa-bitbucket',
    DROPBOX: 'fa-dropbox',
    DROPBOX__WEB: 'fa-dropbox',
    FACEBOOK: 'fa-facebook-square',
    FLICKR: 'fa-flickr',
    FOURSQUARE: 'fa-foursquare',
    GITHUB: 'fa-github',
    GITLAB: 'fa-gitlab',
    GOOGLE_CALENDAR: 'fa-google',
    GOOGLE_DOCS: 'fa-google',
    GOOGLE_DOCS__UPLOAD: 'fa-google',
    GOOGLE_TALK: 'fa-google',
    GOOGLE_WALLET: 'fa-google-wallet',
    ICLOUD: 'fa-apple',
    INSTAGRAM: 'fa-instagram',
    LAST_FM: 'fa-lastfm',
    LINKEDIN: 'fa-linkedin-in',
    MEETUP: 'fa-meetup',
    OPERA: 'fa-opera',
    PAYPAL: 'fa-paypal',
    PINTEREST: 'fa-pinterest',
    QUORA: 'fa-quora',
    RAVELRY: 'fa-ravelry',
    REDDIT: 'fa-reddit',
    SKYPE: 'fa-skype',
    SLACK: 'fa-slack',
    SLACK__UPLOAD: 'fa-slack',
    SLIDESHARE: 'fa-slideshare',
    SNAPCHAT: 'fa-snapchat-ghost',
    STEAM: 'fa-steam',
    STUMBLEUPON: 'fa-stumbleupon-circle',
    TELEGRAM: 'fa-telegram',
    TRELLO: 'fa-trello',
    TUMBLR: 'fa-tumblr',
    TWITCH_TV: 'fa-twitch',
    VIMEO: 'fa-vimeo',
    VINE: 'fa-vine',
    WHATSAPP_MESSENGER: 'fa-whatsapp',
    WHATSAPP_MESSENGER__WEB: 'fa-whatsapp',
    WIKIPEDIA: 'fa-wikipedia-w',
    WORDPRESS: 'fa-wordpress',
    XING: 'fa-xing',
    YELP: 'fa-yelp',
    YOUTUBE: 'fa-youtube',
    YOUTUBE__HD: 'fa-youtube',
    YOUTUBE_DOWNLOADER: 'fa-youtube',
    AMAZON_WEB_SERVICES_AWS_: 'fa-amazon',
    BITCOIN: 'fa-bitcoin',
    DELICIOUS: 'fa-delicious',
    DROPBOX_PAPER: 'fa-dropbox',
    FACEBOOK_MESSENGER: 'fa-facebook-f',
    FIREFOX: 'fa-firefox',
    GOOGLE_GMAIL: 'fa-google',
    GOOGLE_ACCOUNTS: 'fa-google',
    GOOGLE_ADS: 'fa-google',
    GOOGLE_ANALYTICS: 'fa-google',
    GOOGLE_APP_ENGINE: 'fa-google',
    GOOGLE_APPS: 'fa-google',
    GOOGLE_BOOKS: 'fa-google',
    GOOGLE_CACHE: 'fa-google',
    GOOGLE_CHROME: 'fa-chrome',
    GOOGLE_DRIVE: 'fa-google',
    GOOGLE_EARTH: 'fa-google',
    GOOGLE_FINANCE: 'fa-google',
    GOOGLE_IMAGES: 'fa-google',
    GOOGLE_KEEP: 'fa-google',
    GOOGLE_MAPS: 'fa-google',
    GOOGLE_MAPS_API: 'fa-google',
    GOOGLE_NEWS: 'fa-google',
    GOOGLE_PHOTOS: 'fa-google',
    GOOGLE_PLAY: 'fa-google',
    GOOGLE_PLUS: 'fa-google-plus-g',
    GOOGLE_SAFE_BROWSING: 'fa-google',
    GOOGLE_SEARCH: 'fa-google',
    GOOGLE_SERVICES: 'fa-google',
    GOOGLE_SITES: 'fa-google',
    GOOGLE_STACKDRIVER: 'fa-google',
    GOOGLE_TOOLBAR: 'fa-google',
    GOOGLE_TRANSLATE: 'fa-google',
    GOOGLE_VIDEO: 'fa-google',
    INBOX_BY_GMAIL: 'fa-google',
    ODNOKLASSNIKI: 'fa-odnoklassniki',
    QQ_BROWSER: 'fa-qq',
    QQ_MAIL: 'fa-qq',
    QQ_MUSIC: 'fa-qq',
    TWITTER: 'fa-twitter',
    WEB_QQ: 'fa-qq',
    YAHOO_SEARCH: 'fa-yahoo',
    YAHOO_ACCOUNTS: 'fa-yahoo',
    YAHOO_ADS: 'fa-yahoo',
    YAHOO_CALENDAR: 'fa-yahoo',
    YAHOO_FINANCE: 'fa-yahoo',
    YAHOO_MAIL: 'fa-yahoo',
    YAHOO_MESSENGER: 'fa-yahoo',
    YAHOO_SCREEN: 'fa-yahoo',
    YAHOO_SERVICES: 'fa-yahoo',
    AMAZON_PRIME_VIDEO: 'fa-amazon',
    DIGG: 'fa-digg',
    GOOGLE_VOICE_SEARCH: 'fa-google',
    IMDB: 'fa-imdb',
    SLACK__CHAT: 'fa-slack',
    SOUNDCLOUD: 'fa-soundcloud',
    YAHOO_BOX: 'fa-yahoo',
    YAHOO_GAMES: 'fa-yahoo',
    YOUTUBE__UPLOAD: 'fa-youtube',
    GOOGLE_CLOUD_PLATFORM: 'fa-google',
    YAHOO_MESSENGER__WEB: 'fa-yahoo',
    GOOGLE_FIREBASE: 'fa-google',
    MICROSOFT_TRANSLATOR: 'fa-microsoft',
    MICROSOFT_TEAMS: 'fa-microsoft',
    MICROSOFT_STORE: 'fa-microsoft',
    MICROSOFT_SERVICES: 'fa-microsoft',
    MICROSOFT_POWERAPPS: 'fa-microsoft',
    MICROSOFT_POWER_BI__WEB: 'fa-microsoft',
    MICROSOFT_OUTLOOK: 'fa-microsoft',
    MICROSOFT_ONEDRIVE__WEB: 'fa-microsoft',
    MICROSOFT_OFFICE__UPDATE: 'fa-microsoft',
    MICROSOFT_EDGE: 'fa-microsoft',
    MICROSOFT_DOWNLOAD_MANAGER: 'fa-microsoft',
    MICROSOFT_ACCOUNT: 'fa-microsoft'
};
