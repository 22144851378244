"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ2 = exports.ɵ1 = exports.ɵ0 = exports.getMigratedPolicyInfo = exports.getContextualAccountSession = exports.getSwitchAccountSession = exports.getLegacySession = exports.getAccount = exports.getConfigExtended = exports.getCapsOverride = exports.getFederatedAdminAccess = exports.getAdminAccess = exports.getAdminPreferences = exports.getPreferences = exports.getCapabilities = exports.getUserProfile = exports.isAccessInvalid = exports.isAppLoaded = exports.isAppLoading = exports.getSession = exports.reducers = void 0;
const store_1 = require("@ngrx/store");
const app = require("./app.reducer");
const legacy = require("./legacy.reducer");
const user = require("./user.reducer");
exports.reducers = {
    app: app.reducer,
    user: user.reducer,
    legacy: legacy.reducer
};
const getCoreState = store_1.createFeatureSelector('core');
const ɵ0 = (state) => state.app;
exports.ɵ0 = ɵ0;
const getAppState = store_1.createSelector(getCoreState, ɵ0);
const ɵ1 = (state) => state.legacy;
exports.ɵ1 = ɵ1;
const getLegacyState = store_1.createSelector(getCoreState, ɵ1);
const ɵ2 = (state) => state.user;
exports.ɵ2 = ɵ2;
const geUserState = store_1.createSelector(getCoreState, ɵ2);
exports.getSession = store_1.createSelector(getAppState, app.getSession);
exports.isAppLoading = store_1.createSelector(getAppState, app.isLoading);
exports.isAppLoaded = store_1.createSelector(getAppState, app.isLoaded);
exports.isAccessInvalid = store_1.createSelector(getAppState, app.isAccessInvalid);
exports.getUserProfile = store_1.createSelector(geUserState, user.getUserProfile);
exports.getCapabilities = store_1.createSelector(geUserState, user.getCapabilities);
exports.getPreferences = store_1.createSelector(geUserState, user.getPreferences);
exports.getAdminPreferences = store_1.createSelector(geUserState, user.getAdminPreferences);
exports.getAdminAccess = store_1.createSelector(geUserState, user.getAdminAccess);
exports.getFederatedAdminAccess = store_1.createSelector(geUserState, user.getFederatedAdminAccess);
exports.getCapsOverride = store_1.createSelector(geUserState, user.getCapsOverride);
exports.getConfigExtended = store_1.createSelector(geUserState, user.getConfigExtended);
exports.getAccount = store_1.createSelector(geUserState, user.getAccount);
exports.getLegacySession = store_1.createSelector(getLegacyState, legacy.getLegacySession);
exports.getSwitchAccountSession = store_1.createSelector(getLegacyState, legacy.getSwitchAccountSession);
exports.getContextualAccountSession = store_1.createSelector(getLegacyState, legacy.getContextualAccountSession);
exports.getMigratedPolicyInfo = store_1.createSelector(geUserState, user.getMigratedPolicyInfo);
