"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgPolicyService = void 0;
const policy_1 = require("app-new/swg/policies/models/policy");
const _ = require("lodash");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const domain_validator_1 = require("../../../components/validators/domain/domain.validator");
const models_1 = require("../../models/models");
const models_2 = require("../models");
const policy_2 = require("../models/policy");
const clp_1 = require("../models/clp");
class SwgPolicyService {
    constructor(swgPolicyApiService, capabilitiesService, translateService) {
        this.swgPolicyApiService = swgPolicyApiService;
        this.capabilitiesService = capabilitiesService;
        this.translateService = translateService;
    }
    createTarget(target, policyDesc) {
        const policy = {
            conditions: { sourceIPs: [] },
            description: policyDesc.description,
            from: { type: models_2.SwgAffectedUsersEnum.EVERYONE },
            to: { type: models_2.SwgAffectedUsersEnum.EVERYONE }
        };
        if (target.id) {
            policy.id = target.id;
        }
        if (target.condition === models_2.SwgAffectedUsersEnum.LOCATION) {
            policy.conditions.sourceIPs = [target.payload.ipRange];
        }
        if (target.condition === models_2.SwgAffectedUsersEnum.USER) {
            policy.from.type = 'individual_email_address';
            policy.from.emailAddress = target.payload.emailAddress;
        }
        if (target.condition === models_2.SwgAffectedUsersEnum.GROUP) {
            policy.from.type = 'profile_group';
            policy.from.groupId = target.payload.id;
        }
        if (target.condition === models_2.SwgAffectedUsersEnum.WEB_DEVICE) {
            policy.from.type = models_2.SwgAffectedUsersEnum.WEB_DEVICE;
            policy.from.deviceId = target.device.id;
        }
        if (target.condition === models_2.SwgAffectedUsersEnum.WEB_DEVICE_GROUP) {
            policy.from.type = models_2.SwgAffectedUsersEnum.WEB_DEVICE_GROUP;
            policy.from.deviceGroupId = target.deviceGroup.id;
        }
        return policy;
    }
    createAPIPayLoad(policy, wizardFlowType) {
        const payload = {
            description: policy.policyDesc.description,
            enabled: policy.policyDesc.enabled,
            override: policy.policyDesc.override
        };
        if (wizardFlowType !== models_2.SwgPolicyWizardFlowType.DUPLIATE) {
            payload.id = policy.policyDesc.id;
        }
        if (policy.everyOne) {
            payload.policies = [
                {
                    description: policy.policyDesc.description,
                    from: { type: models_2.SwgAffectedUsersEnum.EVERYONE },
                    to: { type: models_2.SwgAffectedUsersEnum.EVERYONE }
                }
            ];
            if (policy.affectedUsers.length === 1 && policy.affectedUsers[0].condition === 'everyone') {
                payload.policies[0].id = policy.affectedUsers[0].id;
            }
        }
        else {
            payload.policies = policy.affectedUsers
                .filter(target => target.condition !== models_2.SwgAffectedUsersEnum.EVERYONE)
                .map(target => {
                return this.createTarget(target, policy.policyDesc);
            });
        }
        switch (policy.policyDesc.policyType) {
            case models_2.SwgPolicyType.WEB_BLOCK_ALLOW_LIST:
                payload.urls = policy.domains;
                break;
            case models_2.SwgPolicyType.WEB_TTP_POLICY:
                payload.useManagedUrls = policy.ttpManagedUrlSettings.useManagedUrls;
                payload.chameleonEnabled = policy.ttpChameleonSettings.chameleonEnabled;
                payload.chameleonMode = policy.ttpChameleonSettings.chameleonMode;
                break;
            case models_2.SwgPolicyType.WEB_CATEGORY_FILTERING:
                payload.webCategoryRules = [
                    ...policy.webCategorySecurityRules,
                    ...policy.webCategoryOtherRules
                ].filter((x) => x.action === models_2.SwgCategoryOptionsEnum.DISALLOW);
                break;
            case models_2.SwgPolicyType.WEB_LOGGING:
                payload.loggingMode = policy.loggingMode;
                break;
            case models_2.SwgPolicyType.WEB_APPLICATION_CONTROL:
                payload.webApplicationRules = policy.webApplicationRules;
                break;
            case models_2.SwgPolicyType.WEB_ADVANCED_SECURITY:
                payload.protectedApplications = policy.protectedApplications.map(x => {
                    return { applicationCode: x.category };
                });
                payload.google = policy.safeSearchSettings.google;
                payload.bing = policy.safeSearchSettings.bing;
                payload.youTube = policy.safeSearchSettings.youTube;
                payload.enabled = policy.proxySettings.enabled;
                payload.avScan = policy.proxySettings.avScan;
                payload.biEnabled = policy.proxySettings.biEnabled;
                if (policy_2.SwgUnScannableEnum.BLOCK === policy.proxySettings.blockUnscannable) {
                    payload.blockUnscannable = true;
                }
                else {
                    payload.blockUnscannable = false;
                }
                payload.checkNod = policy.newDomainsSettings.checkNod;
                break;
        }
        return payload;
    }
    getTargetAddedNotificationMessage(target, condition) {
        let message = this.translateService.instant('$I18N_SWG_CREATE_POLICY_AFFECTED_USERS_ITEM') || 'Item';
        if (condition === models_2.SwgAffectedUsersEnum.LOCATION) {
            message = target.description + ' (' + target.ipRange + ')';
        }
        else if (condition === models_2.SwgAffectedUsersEnum.USER) {
            message = target.emailAddress;
        }
        else if (condition === models_2.SwgAffectedUsersEnum.GROUP ||
            condition === models_2.SwgAffectedUsersEnum.CLP_GROUP) {
            message = target.description;
        }
        else if (condition === models_2.SwgAffectedUsersEnum.CHANNEL) {
            message = target.channelDisplayName;
        }
        return (message + this.translateService.instant('$I18N_SWG_CREATE_POLICY_AFFECTED_USER_TARGET_ADDED'));
    }
    createAffectedUserFromTarget(policyResponse) {
        let ret;
        if (policyResponse.locations && policyResponse.locations[0]) {
            ret = {
                condition: models_2.SwgAffectedUsersEnum.LOCATION,
                id: policyResponse.id,
                payload: {
                    ipRange: policyResponse.locations[0].ip,
                    description: policyResponse.locations[0].location
                }
            };
        }
        else if (policyResponse.policy.from.type === 'individual_email_address') {
            ret = {
                condition: models_2.SwgAffectedUsersEnum.USER,
                id: policyResponse.id,
                payload: {
                    emailAddress: policyResponse.policy.from.emailAddress
                }
            };
        }
        else if (policyResponse.policy.from.type === 'profile_group') {
            ret = {
                condition: models_2.SwgAffectedUsersEnum.GROUP,
                id: policyResponse.id,
                payload: {
                    description: policyResponse.policy.from.group.description,
                    id: policyResponse.policy.from.group.id
                }
            };
        }
        else if (policyResponse.policy.from.type === models_2.SwgAffectedUsersEnum.WEB_DEVICE) {
            ret = {
                condition: models_2.SwgAffectedUsersEnum.WEB_DEVICE,
                device: {
                    id: policyResponse.policy.from.device.id,
                    description: policyResponse.policy.from.device.description
                }
            };
        }
        else if (policyResponse.policy.from.type === models_2.SwgAffectedUsersEnum.WEB_DEVICE_GROUP) {
            ret = {
                condition: models_2.SwgAffectedUsersEnum.WEB_DEVICE_GROUP,
                deviceGroup: {
                    id: policyResponse.policy.from.deviceGroup.id,
                    description: policyResponse.policy.from.deviceGroup.description
                }
            };
        }
        else if (policyResponse.policy.from.type === models_2.SwgAffectedUsersEnum.EVERYONE) {
            ret = {
                condition: models_2.SwgAffectedUsersEnum.EVERYONE,
                id: policyResponse.id,
                payload: {
                    description: ''
                }
            };
        }
        return ret;
    }
    createUIModelFromAPI(policyResponse, policyDesc, connector) {
        // @ts-ignore
        const swgPolicy = {
            policyDesc,
            affectedUsers: [],
            defaultPolicy: false,
            domains: [],
            biSettings: {
                clipboardTransferIn: false,
                clipboardTransferOut: false,
                sessionReadOnly: false,
                defaultPolicy: false,
                enterText: false
            }
        };
        swgPolicy.affectedUsers =
            (policyResponse.policies && policyResponse.policies.map(this.createAffectedUserFromTarget)) ||
                [];
        if (swgPolicy.affectedUsers &&
            swgPolicy.affectedUsers.length === 1 &&
            swgPolicy.affectedUsers[0].condition === models_2.SwgAffectedUsersEnum.EVERYONE) {
            swgPolicy.everyOne = true;
        }
        switch (policyDesc.policyType) {
            case models_2.SwgPolicyType.WEB_BLOCK_ALLOW_LIST:
                swgPolicy.domains = policyResponse.urls;
                break;
            case models_2.SwgPolicyType.WEB_CATEGORY_FILTERING:
                swgPolicy.webCategoryOtherRules = policyResponse.webCategoryRules;
                swgPolicy.webCategorySecurityRules = policyResponse.webCategoryRules;
                break;
            case models_2.SwgPolicyType.WEB_LOGGING:
                swgPolicy.loggingMode = policyResponse.loggingMode;
                break;
            case models_2.SwgPolicyType.WEB_APPLICATION_CONTROL:
                swgPolicy.webApplicationRules = policyResponse.webApplicationRules;
                break;
            case models_2.SwgPolicyType.WEB_TTP_POLICY:
                swgPolicy.ttpManagedUrlSettings = { useManagedUrls: policyResponse.useManagedUrls };
                swgPolicy.ttpChameleonSettings = {
                    chameleonEnabled: policyResponse.chameleonEnabled,
                    chameleonMode: policyResponse.chameleonMode
                };
                break;
            case models_2.SwgPolicyType.BROWSER_ISOLATION:
                swgPolicy.biSettings = {
                    sessionReadOnly: policyResponse.sessionReadOnly,
                    clipboardTransferIn: policyResponse.clipboardTransferIn,
                    clipboardTransferOut: policyResponse.clipboardTransferOut,
                    defaultPolicy: policyResponse.defaultPolicy,
                    enterText: !policyResponse.sessionReadOnly
                };
                swgPolicy.narrative = policyResponse.narrative;
                break;
            case models_2.SwgPolicyType.WEB_ADVANCED_SECURITY:
                swgPolicy.safeSearchSettings = {
                    google: policyResponse.google,
                    bing: policyResponse.bing,
                    youTube: policyResponse.youTube
                };
                swgPolicy.proxySettings = {
                    enabled: policyResponse.enabled,
                    avScan: policyResponse.avScan,
                    biEnabled: policyResponse.biEnabled,
                    blockUnscannable: policyResponse.blockUnscannable
                        ? policy_2.SwgUnScannableEnum.BLOCK
                        : policy_2.SwgUnScannableEnum.ALLOW
                };
                swgPolicy.newDomainsSettings = {
                    checkNod: policyResponse.checkNod
                };
                swgPolicy.protectedApplications = policyResponse.protectedApplications.map(x => {
                    return { category: x.applicationCode };
                });
                break;
            case models_2.SwgPolicyType.CLOUD_PROTECT_POLICY:
                // @ts-ignore
                const swgClpPolicy = this.mapClpToSwg(policyResponse);
                swgPolicy.everyOne = swgClpPolicy.everyOne;
                swgPolicy.clpPolicySettings = Object.assign({}, swgClpPolicy.clpPolicySettings);
                swgPolicy.affectedUsers = [...swgPolicy.affectedUsers, ...swgClpPolicy.affectedUsers];
                if (swgClpPolicy.clpPolicySettings.scope.type === policy_1.SwgClpPolicyScopeType.SPECIFIC_CLOUD_SERVICE) {
                    swgPolicy.defaultPolicy = true;
                    swgPolicy.clpPolicySettings.provider = swgClpPolicy.clpPolicySettings.scope.id;
                }
                else if (swgClpPolicy.clpPolicySettings.scope.type === policy_1.SwgClpPolicyScopeType.SPECIFIC_CONNECTOR &&
                    connector) {
                    swgPolicy.clpPolicySettings.provider = connector.provider;
                    swgPolicy.clpPolicySettings.connectorName = connector.name;
                    swgPolicy.clpPolicySettings.connectorId = connector.id;
                    swgPolicy.clpPolicySettings.type = connector.provider;
                }
                if (swgClpPolicy.clpPolicySettings.scope.type === policy_1.SwgClpPolicyScopeType.SPECIFIC_CONNECTOR) {
                    swgPolicy.clpPolicyWithoutConnector = !swgPolicy.clpPolicySettings.connectorName;
                }
                break;
        }
        return swgPolicy;
    }
    checkIfEqual(swgPolicy, swgPolicyCopy) {
        if (!_.isEqual(swgPolicy.affectedUsers, swgPolicyCopy.affectedUsers)) {
            return false;
        }
        if (swgPolicy.policyDesc.policyType === models_2.SwgPolicyType.WEB_BLOCK_ALLOW_LIST) {
            return _.isEqual(swgPolicy.domains, swgPolicyCopy.domains);
        }
        else if (swgPolicy.policyDesc.policyType === models_2.SwgPolicyType.WEB_CATEGORY_FILTERING) {
            const duplicatedPolicyCategories = new Set([...swgPolicy.webCategoryOtherRules, ...swgPolicy.webCategorySecurityRules].map(x => x.category));
            const originalPolicyCategories = new Set([...swgPolicyCopy.webCategoryOtherRules, ...swgPolicyCopy.webCategorySecurityRules].map(x => x.category));
            if (duplicatedPolicyCategories.size !== originalPolicyCategories.size) {
                return false;
            }
            const categories = Array.from(duplicatedPolicyCategories);
            let difference = true;
            categories.forEach(category => {
                if (!originalPolicyCategories.has(category)) {
                    difference = false;
                }
            });
            return difference;
        }
        else if (swgPolicy.policyDesc.policyType === models_2.SwgPolicyType.WEB_TTP_POLICY) {
            return _.isEqual(swgPolicy.ttpChameleonSettings, swgPolicyCopy.ttpChameleonSettings);
            _.isEqual(swgPolicy.ttpManagedUrlSettings, swgPolicyCopy.ttpManagedUrlSettings);
        }
        else if (swgPolicy.policyDesc.policyType === models_2.SwgPolicyType.WEB_ADVANCED_SECURITY) {
            return (_.isEqual(swgPolicy.safeSearchSettings, swgPolicyCopy.safeSearchSettings) &&
                _.isEqual(swgPolicy.proxySettings, swgPolicyCopy.proxySettings) &&
                _.isEqual(swgPolicy.newDomainsSettings, swgPolicyCopy.newDomainsSettings) &&
                _.isEqual(swgPolicy.protectedApplications.map(app => app.applicationCode), swgPolicyCopy.protectedApplications.map(app => app.applicationCode)));
        }
    }
    hasPolicyEditPermission() {
        return this.capabilitiesService.hasCapability('Permission.SWG_CONFIGURE_POLICIES_EDIT');
    }
    parseFile(uploadedFileContent) {
        const rows = uploadedFileContent.trim().split('\n');
        const domainArray = [];
        for (const row of rows) {
            // It is possible that a URL contains commas. It is important that we preserve these.
            // If a value in a CSV contains commas, it will be within quotation marks.
            // Therefore, we will look for quotation marks surrounding our URL and retain any commas within it.
            // Note that as of ADCON-2942, we are no longer converting URLs to lower case before importing them.
            const rowTrim = row.trim();
            const getQuotedValue = /^\"(.+)\"/.exec(rowTrim); // Looking for a quoted value at the beginning of the row; we'll assume this is a URL
            const rowCommaSplit = rowTrim.split(',');
            const domain = getQuotedValue ? getQuotedValue[1] : rowCommaSplit[0]; // Using our quoted value if we've found it, with commas
            const action = rowCommaSplit[rowCommaSplit.length - 1].trim().toLowerCase();
            if (domain_validator_1.isValidApiUrlAndQuery(domain) && this.isValidAction(action)) {
                // WS-861: AdCon removes "www." subdomains.
                // This gives the possibility of duplicates if, for example, we have "example.com/test" and "www.example.com/test".
                // Therefore, we need to remove these duplicates so we don't get errors from the API when we save the policy.
                const regexResult = domain_validator_1.API_URL_AND_QUERY_VALIDATOR_REGEX.exec(domain);
                const domainPart = regexResult[2];
                const pathPart = regexResult[6];
                const fullResult = domainPart + pathPart;
                if (domainArray.map(x => x.value).indexOf(fullResult) === -1) {
                    domainArray.push(Object.assign(Object.assign({}, domain_validator_1.checkIfUrlOrDomain(domain)), { action }));
                }
            }
            else {
                throw new Error('invalid file format');
            }
        }
        return domainArray;
    }
    isValidAction(action) {
        return action === 'allow' || action === 'block';
    }
    readFile(fileName) {
        const sub = new rxjs_1.Subject();
        const fileReader = new FileReader();
        fileReader.onload = fileLoadedEvent => {
            const textFromFileLoaded = fileLoadedEvent.target['result'];
            try {
                const domainList = this.parseFile(textFromFileLoaded);
                sub.next(domainList);
            }
            catch (err) {
                sub.error('failed');
            }
        };
        // @ts-ignore
        fileReader.readAsText(fileName, 'UTF-8');
        return sub;
    }
    createClpPolicyPayload(policy, wizardFlowType) {
        const p = {};
        if (models_2.SwgPolicyWizardFlowType.EDIT === wizardFlowType) {
            p.id = policy.policyDesc.id;
        }
        // WS-3080: Only include name and targets for non-default policies, as these are not editable in default policies.
        if (!policy.defaultPolicy) {
            p.name = policy.policyDesc.description;
            if (policy.everyOne) {
                p.targets = [{ type: policy_1.SwgClpPolicyTargetType.EVERYONE }];
            }
            else {
                p.targets = policy.affectedUsers
                    .map(n => {
                    switch (n.condition) {
                        case models_2.SwgAffectedUsersEnum.GROUP:
                            return {
                                type: policy_1.SwgClpPolicyTargetType.PROFILE_GROUP,
                                id: n.payload.id
                            };
                        case models_2.SwgAffectedUsersEnum.USER:
                            return {
                                type: policy_1.SwgClpPolicyTargetType.INDIVIDUAL_EMAIL_ADDRESS,
                                id: n.payload.emailAddress
                            };
                        default:
                            return null;
                    }
                })
                    .filter(n => !!n);
            }
        }
        p.description = '';
        p.enabled = true;
        if (models_2.SwgPolicyWizardFlowType.CREATE === wizardFlowType ||
            models_2.SwgPolicyWizardFlowType.DUPLIATE === wizardFlowType) {
            p.scope = {
                id: policy.clpPolicySettings.connectorId,
                type: policy_1.SwgClpPolicyScopeType.SPECIFIC_CONNECTOR
            };
        }
        p.newFileSkipScan = clp_1.ClpPolicyActions.DONT_SCAN === policy.clpPolicySettings.action;
        p.newFileNotifyUser = !!policy.clpPolicySettings.notifyFileOwner;
        p.newFileNotifyGroup = !!policy.clpPolicySettings.notifyAdministrator;
        p.newFileMsgTeamsConversation =
            [clp_1.ClpPolicyActions.LOG_AND_REPLY, clp_1.ClpPolicyActions.BLOCK_MALICIOUS].indexOf(policy.clpPolicySettings.action) !== -1;
        p.newFileMsgTeamsChannels = !!policy.clpPolicySettings.notifyAdministrator;
        p.newFileQuarantine = clp_1.ClpPolicyActions.BLOCK_MALICIOUS === policy.clpPolicySettings.action;
        p.newFileNotificationFile = false;
        p.newFileSafeFile = false;
        p.notifyGroups = policy.clpPolicySettings.notificationList
            .filter(n => 'clp_notifications_group' === n.condition)
            .map(n => ({ id: n.payload.id }));
        p.notifyTeamsChannels = policy.clpPolicySettings.notificationList
            .filter(n => 'clp_notifications_channel' === n.condition)
            .map(n => n.teamsChannel);
        return p;
    }
    createPolicy(policy, type) {
        if (policy.policyDesc.policyType === models_2.SwgPolicyType.CLOUD_PROTECT_POLICY) {
            const clpPayload = this.createClpPolicyPayload(policy, type);
            let response;
            if (models_2.SwgPolicyWizardFlowType.EDIT === type) {
                response = this.swgPolicyApiService.updateClpPolicy(clpPayload);
            }
            else {
                response = this.swgPolicyApiService.createClpPolicy(clpPayload);
            }
            return response;
        }
        const payload = this.createAPIPayLoad(policy, type);
        payload.policies = payload.policies.map(x => {
            return Object.assign(Object.assign({}, x), { enabled: policy.policyDesc.enabled, override: policy.policyDesc.override });
        });
        switch (policy.policyDesc.policyType) {
            case models_2.SwgPolicyType.WEB_BLOCK_ALLOW_LIST:
                return this.swgPolicyApiService.createBlockDomainPolicy(payload);
            case models_2.SwgPolicyType.WEB_ADVANCED_SECURITY:
                return this.swgPolicyApiService.createAdvancedSecurityPolicy(payload);
            case models_2.SwgPolicyType.WEB_LOGGING:
                return this.swgPolicyApiService.createWebLoggingPolicy(payload);
            case models_2.SwgPolicyType.WEB_CATEGORY_FILTERING:
                return this.swgPolicyApiService.createCategoryFilterPolicy(payload);
            case models_2.SwgPolicyType.WEB_TTP_POLICY:
                return this.swgPolicyApiService.createTTPPolicy(payload);
            case models_2.SwgPolicyType.WEB_APPLICATION_CONTROL:
                return this.swgPolicyApiService.createWebApplicationControlPolicy(payload);
            default:
                // @ts-ignore
                return rxjs_1.of(false);
        }
    }
    deletePolicy(policyDesc) {
        switch (policyDesc.policyType) {
            case models_2.SwgPolicyType.WEB_BLOCK_ALLOW_LIST:
                return this.swgPolicyApiService.deleteBlockDomainPolicy(policyDesc.id);
            case models_2.SwgPolicyType.WEB_ADVANCED_SECURITY:
                return this.swgPolicyApiService.deleteAdvancedSecurityPolicy(policyDesc.id);
            case models_2.SwgPolicyType.WEB_LOGGING:
                return this.swgPolicyApiService.deleteWebloggingPolicy(policyDesc.id);
            case models_2.SwgPolicyType.WEB_CATEGORY_FILTERING:
                return this.swgPolicyApiService.deleteCategoryFilterPolicy(policyDesc.id);
            case models_2.SwgPolicyType.WEB_TTP_POLICY:
                return this.swgPolicyApiService.deleteTtpSettingsPolicy(policyDesc.id);
            case models_2.SwgPolicyType.WEB_APPLICATION_CONTROL:
                return this.swgPolicyApiService.deleteWebapplicationControlPolicy(policyDesc.id);
            case models_2.SwgPolicyType.CLOUD_PROTECT_POLICY:
                return this.swgPolicyApiService.deleteClpPolicy(policyDesc.id);
            default:
                // @ts-ignore
                return rxjs_1.of(false);
        }
    }
    getPolicyDetails(data) {
        switch (data.policyType) {
            case models_2.SwgPolicyType.WEB_BLOCK_ALLOW_LIST:
                return this.swgPolicyApiService.getBlockDomainPolicy(data.id);
            case models_2.SwgPolicyType.WEB_ADVANCED_SECURITY:
                return this.swgPolicyApiService.getAdvancedSecurityPolicy(data.id);
            case models_2.SwgPolicyType.WEB_CATEGORY_FILTERING:
                return this.swgPolicyApiService.getCategoryFilterPolicy(data.id);
            case models_2.SwgPolicyType.WEB_LOGGING:
                return this.swgPolicyApiService.getWebloggingPolicy(data.id);
            case models_2.SwgPolicyType.WEB_TTP_POLICY:
                return this.swgPolicyApiService.getTtpSettingsPolicy(data.id);
            case models_2.SwgPolicyType.WEB_APPLICATION_CONTROL:
                return this.swgPolicyApiService.getWebapplicationControlPolicy(data.id);
            case models_2.SwgPolicyType.BROWSER_ISOLATION:
                return this.swgPolicyApiService.getBrowserIsolationPolicy(data.id);
            case models_2.SwgPolicyType.CLOUD_PROTECT_POLICY:
                return this.swgPolicyApiService.getCloudProtectPolicy(data.id);
            default:
                // @ts-ignore
                return rxjs_1.of(false);
        }
    }
    mapClpToSwg(policyResponse) {
        const swgPolicy = {};
        let action;
        if (policyResponse.newFileQuarantine) {
            action = clp_1.ClpPolicyActions.BLOCK_MALICIOUS;
        }
        else if (!policyResponse.newFileQuarantine && policyResponse.newFileMsgTeamsConversation) {
            action = clp_1.ClpPolicyActions.LOG_AND_REPLY;
        }
        else if (policyResponse.newFileSkipScan) {
            action = clp_1.ClpPolicyActions.DONT_SCAN;
        }
        else {
            action = clp_1.ClpPolicyActions.LOG_ONLY;
        }
        swgPolicy.clpPolicySettings = {
            scope: {
                type: policyResponse.scope.type,
                id: policyResponse.scope.id
            },
            action,
            notifyAdministrator: policyResponse.newFileNotifyGroup,
            notifyFileOwner: policyResponse.newFileNotifyUser,
            notificationList: []
        };
        if (policyResponse.scope.type === 'SPECIFIC_CLOUD_SERVICE') {
            swgPolicy.defaultPolicy = true;
        }
        if (policyResponse.notifyGroups.length > 0) {
            policyResponse.notifyGroups.forEach(group => {
                swgPolicy.clpPolicySettings.notificationList.push({
                    condition: 'clp_notifications_group',
                    payload: {
                        id: group.id,
                        description: group.displayName
                    }
                });
            });
        }
        if (policyResponse.notifyTeamsChannels.length > 0) {
            policyResponse.notifyTeamsChannels.forEach(channel => {
                swgPolicy.clpPolicySettings.notificationList.push({
                    condition: 'clp_notifications_channel',
                    teamsChannel: channel
                });
            });
        }
        swgPolicy.affectedUsers = policyResponse.targets
            .map(target => {
            switch (target.type) {
                case policy_1.SwgClpPolicyTargetType.INDIVIDUAL_EMAIL_ADDRESS:
                    return {
                        condition: models_2.SwgAffectedUsersEnum.USER,
                        payload: {
                            emailAddress: target.id
                        }
                    };
                case policy_1.SwgClpPolicyTargetType.PROFILE_GROUP:
                    return {
                        condition: models_2.SwgAffectedUsersEnum.GROUP,
                        payload: {
                            id: target.id,
                            description: target.displayName
                        }
                    };
                default:
                    return null;
            }
        })
            .filter(el => !!el);
        swgPolicy.everyOne =
            policyResponse.targets.filter(t => t.type === policy_1.SwgClpPolicyTargetType.EVERYONE).length > 0;
        return swgPolicy;
    }
    getApplications() {
        return this.swgPolicyApiService
            .getApplications()
            .pipe(operators_1.map(response => response['applications']));
    }
    getCategorySections() {
        return this.swgPolicyApiService
            .getCategorySections()
            .pipe(operators_1.map(response => response.categories));
    }
    findConnectors() {
        return this.swgPolicyApiService.findConnectors().pipe(operators_1.map(response => response.connectors));
    }
    getLocations(payload) {
        return this.swgPolicyApiService.getLocations(payload ? payload : null).pipe(operators_1.map((response) => {
            return response;
        }), 
        // @ts-ignore
        operators_1.catchError(this.handleError));
    }
    fetchUsers(payload) {
        if (payload && payload.data) {
            return (this.swgPolicyApiService
                // @ts-ignore
                .findUsers({ meta: payload.meta, data: [{ query: payload.data }] })
                .pipe(operators_1.map((response) => {
                return response;
            })));
        }
        else {
            // @ts-ignore
            return this.swgPolicyApiService.getUsers({ meta: payload.meta, data: [] }).pipe(operators_1.map((response) => {
                return response;
            }));
        }
    }
    getAdvancedFileTypes() {
        return this.swgPolicyApiService.getAdvancedFileTypes().pipe(
        // @ts-ignore
        operators_1.map(response => response.fileTypes), 
        // @ts-ignore
        operators_1.catchError(this.handleError));
    }
    getTopApplications(dateRange) {
        return this.swgPolicyApiService.getTopApplications(dateRange);
    }
    addActionToSwgAdvancedFileType(swg) {
        swg.forEach(file => {
            if (!file.action) {
                file.action = policy_2.AdvancedFileTypeEnum.ALLOW;
            }
        });
        return swg;
    }
    sortAndOrderWebCategoryRules(webCategoryRules) {
        const keys = Object.keys(webCategoryRules).sort();
        const webCategoryRuleListByGroup = [];
        let index = keys.indexOf('OTHER');
        if (index > -1) {
            keys.splice(index, 1);
            keys.push('OTHER');
        }
        index = keys.indexOf('UNKNOWN');
        if (index > -1) {
            keys.splice(index, 1);
            keys.push('UNKNOWN');
        }
        keys.forEach(keyName => {
            // @ts-ignore
            webCategoryRuleListByGroup.push({
                key: keyName,
                value: models_2.SwgCategorySectionOptionsEnum.ALLOWALL,
                rules: webCategoryRules[keyName]
            });
        });
        webCategoryRuleListByGroup.forEach(group => {
            group.rules.sort((ruleA, ruleB) => ruleA.name.localeCompare(ruleB.name));
            return group;
        });
        return webCategoryRuleListByGroup;
    }
    addAllowedCategories(webCategoryRules, swgPolicy) {
        const swgCategoryRulesSections = this.groupSwgWebCategorySections(webCategoryRules);
        // @ts-ignore
        const securitySection = this.filterSections(swgCategoryRulesSections, 'SECURITY');
        // @ts-ignore
        const otherSection = this.filterSections(swgCategoryRulesSections, 'NON-SECURITY');
        const securityRules = securitySection.map(group => {
            return {
                // @ts-ignore
                key: group.key,
                // @ts-ignore
                rules: group.rules.map(x => {
                    const apiRule = swgPolicy.webCategorySecurityRules.find(rule => x.name === rule.category);
                    return {
                        category: x.name,
                        action: apiRule ? apiRule.action : models_2.SwgCategoryOptionsEnum.ALLOW
                    };
                })
            };
        });
        const otherRules = otherSection.map(group => {
            return {
                // @ts-ignore
                key: group.key,
                // @ts-ignore
                rules: group.rules.map(x => {
                    const apiRule = swgPolicy.webCategoryOtherRules.find(rule => x.name === rule.category);
                    return {
                        category: x.name,
                        action: apiRule ? apiRule.action : models_2.SwgCategoryOptionsEnum.ALLOW
                    };
                })
            };
        });
        // @ts-ignore
        return Object.assign(Object.assign({}, swgPolicy), { webCategorySecurityRules: securityRules, webCategoryOtherRules: otherRules });
    }
    groupSwgWebCategorySections(webCategoryRules) {
        const webCategoryRuleObj = {};
        webCategoryRules.forEach(rule => {
            const item = { name: rule.name, value: models_2.SwgCategoryOptionsEnum.ALLOW };
            if (webCategoryRuleObj[rule.group]) {
                const items = webCategoryRuleObj[rule.group];
                items.push(item);
                webCategoryRuleObj[rule.group] = items;
            }
            else {
                webCategoryRuleObj[rule.group] = [item];
            }
        });
        return this.sortAndOrderWebCategoryRules(webCategoryRuleObj);
    }
    filterSections(rulesSectionData, section) {
        if (!section) {
            return rulesSectionData;
        }
        if (section === 'SECURITY') {
            // @ts-ignore
            return rulesSectionData.filter(x => x.key === 'SECURITY');
        }
        else {
            // @ts-ignore
            return rulesSectionData.filter(x => x.key !== 'SECURITY');
        }
    }
    addTranslatedCategoryNames(rulesSectionData) {
        const sectionData = rulesSectionData.map(x => Object.assign({}, x));
        sectionData.forEach(section => {
            section.rules = section.rules.map(x => Object.assign({}, x));
            section.rules.forEach(rule => {
                if (rule.displayName) {
                    rule.translatedCategoryName = rule.displayName;
                }
                else {
                    rule.translatedCategoryName = this.translateService.instant('$I18N_SWG_HUMANREADBALE_CATEGORY.' + rule.name);
                }
            });
        });
        return sectionData;
    }
    getCategorySectionsForDisplay(webCategoryRulesSections, userRuleSections, filter) {
        const rules = this.filterSections(
        // @ts-ignore
        webCategoryRulesSections, filter);
        const rulesWithUserSettings = this.updateSwgCategorySectionsWithUserSettings(userRuleSections, rules);
        return this.addTranslatedCategoryNames(rulesWithUserSettings);
    }
    findValue(key, userRuleSections) {
        const found = userRuleSections.find(userRule => userRule.category === key);
        return found ? 'disallow' : 'allow';
    }
    getAppSectionsForDisplay(applications, userRuleSections) {
        const extendedProxyRules = applications.map(app => ({
            name: app.code,
            translatedCategoryName: app.name,
            value: this.findValue(app.code, userRuleSections)
        }));
        return [
            {
                groupId: 1,
                key: 'EXTENDED_PROXY',
                rules: extendedProxyRules.sort((ruleA, ruleB) => ruleA.name.localeCompare(ruleB.name))
            }
        ];
    }
    updateSwgCategorySectionsWithUserSettings(userRules, rulesSectionData) {
        if (userRules && userRules.length === 0) {
            return rulesSectionData;
        }
        const sectionData = rulesSectionData.map(x => Object.assign({}, x));
        sectionData.forEach(section => {
            let hasAllowed = false;
            let hasBlocked = false;
            section.rules = section.rules.map(x => Object.assign({}, x));
            section.rules.forEach(rule => {
                // @ts-ignore
                const userRule = userRules.find(uRule => uRule.category === rule.name);
                if (userRule) {
                    // @ts-ignore
                    rule.value = userRule.action;
                    // @ts-ignore
                    if (userRule.action === models_2.SwgCategoryOptionsEnum.DISALLOW) {
                        hasBlocked = true;
                    }
                    else {
                        hasAllowed = true;
                    }
                }
                else {
                    hasAllowed = true;
                }
            });
            if (hasAllowed) {
                // @ts-ignore
                section.value = models_2.SwgCategorySectionOptionsEnum.ALLOWALL;
            }
            if (hasBlocked) {
                // @ts-ignore
                section.value = models_2.SwgCategorySectionOptionsEnum.DISALLOWALL;
            }
            if (hasAllowed && hasBlocked) {
                // @ts-ignore
                section.value = models_2.SwgCategorySectionOptionsEnum.CUSTOM;
            }
        });
        return sectionData;
    }
    mapAppliesToResponse(response) {
        return {
            data: response.first,
            meta: response.meta,
            failures: response.fail,
            hasErrors: response.hasErrors
        };
    }
    getApplicationStatus() {
        return this.swgPolicyApiService.getApplicationStatus();
    }
    updateApplicationStatus(data, applications) {
        const payload = data
            .map(app => {
            return Object.assign(Object.assign({}, app), { id: applications.find(y => y.code === app.appCode).id });
        })
            .filter(app => {
            if (app.status === models_1.ApplicationSanction.MONITOR ||
                app.status === models_1.ApplicationSanction.SANCTION) {
                return true;
            }
            else {
                return !!app.id;
            }
        });
        return payload.length > 0
            ? this.swgPolicyApiService
                .updateApplicationStatus(payload)
                .pipe(operators_1.map(x => x['applicationStatus']))
            : rxjs_1.of([]);
    }
    getTopBlockedApplications(dateRange) {
        return this.swgPolicyApiService.getTopBlockedApplications(dateRange);
    }
    getTopProfiles(dateRange) {
        return this.swgPolicyApiService.getTopProfiles(dateRange);
    }
    getProtectedApplications() {
        return this.swgPolicyApiService.getProtectedApplications();
    }
}
exports.SwgPolicyService = SwgPolicyService;
