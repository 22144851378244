<mc-create-bi-policy [swgPolicy]="swgPolicy$ | async"
                     [wizardFlowType]="wizardFlowType$ | async"
                     [loadingForEdit]="loadingForEdit$ | async"
                     [apiIsProcessing]="apiIsProcessing$ | async"
                     [isBiEmailOnlyCustomer]="isBiEmailOnlyCustomer"
                     [hasBiPolicyEditPermission]="hasBiPolicyEditPermission$ | async"
                     (updatePolicyDescWiz)="updateBiPolicyDetailsStep($event)"
                     (updatePolicySettingsWiz)="updateSettingsInStore($event)"
                     (createPolicyEvent)="createPolicy()"
                     (removeAffectedUsersWiz)="removeAffectedUsers($event)"
></mc-create-bi-policy>
