<div *ngIf="rowData" class="mc-detailed-list">
  <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">{{'$I18N_SERVICES_URL_PROTECTION_LOGS_DETAILS.LABEL_URL' | translate}}</div>
    <div class="mc-detailed-list-column">
      <mc-browser-isolation-detector [logLink]="rowData" (urlClick)="urlClick($event)"></mc-browser-isolation-detector>
    </div>
  </div>
  <mc-value-field
    label="$I18N_SERVICES_URL_PROTECTION_LOGS_DETAILS.LABEL_CATEGORY"
    [value]="rowData.category"></mc-value-field>
  <mc-value-field
    label="$I18N_SERVICES_URL_PROTECTION_LOGS_DETAILS.LABEL_EMAIL_PARTS"
    [value]="setEmailParts(rowData.emailPartsDescription)"></mc-value-field>
  <mc-value-field
    label="$I18N_SERVICES_URL_PROTECTION_LOGS_DETAILS.LABEL_LOG_CREATION_METHOD"
    [value]="rowData.creationMethod"></mc-value-field>
  <hr>
  <mc-value-field
    label="$I18N_SERVICES_URL_PROTECTION_LOGS_DETAILS.LABEL_ACTION"
    [value]="rowData.actions"></mc-value-field>
  <mc-value-field
    label="$I18N_SERVICES_URL_PROTECTION_LOGS_DETAILS.LABEL_POLICY"
    [value]="rowData.ttpDefinition"></mc-value-field>
  <mc-value-field
    label="$I18N_SERVICES_URL_PROTECTION_LOGS_DETAILS.LABEL_ADMIN_OVERRIDE"
    [value]="rowData.adminOverride"></mc-value-field>
  <mc-value-field
    label="$I18N_SERVICES_URL_PROTECTION_LOGS_DETAILS.LABEL_USER_OVERRIDE"
    [value]="rowData.userOverride"></mc-value-field>
  <mc-value-field
    label="$I18N_SERVICES_URL_PROTECTION_LOGS_DETAILS.LABEL_USER_AWARENESS"
    [value]="rowData.userAwarenessAction"></mc-value-field>
  <hr>
  <div class="row mc-detailed-list-row" data-test="cy-logs-side-panel-scan-result">
    <div
      class="col-xs-3 mc-detailed-list-label">{{ '$I18N_SERVICES_URL_PROTECTION_LOGS_DETAILS.LABEL_SCAN_RESULT' | translate }}</div>
    <div class="mc-detailed-list-column"><i
      [ngClass]="displayScanResultIcon(rowData)"></i> {{ displayScanResultLabel(rowData) }}</div>
  </div>

  <div *ngIf="!!rowData.tagMap || !scanDetailsPermission || scanReportPermission" class="row mc-detailed-list-row">
    <div class="col-xs-3 mc-detailed-list-label">
      {{ '$I18N_SERVICES_URL_PROTECTION_LOGS_DETAILS.LABEL_SCAN_DETAILS' | translate }}
    </div>
    <div *ngIf="!scanDetailsPermission" class="mc-detailed-list-column">{{ getScanDetailsText(rowData) || hasNoScanDetailsButTheftEvidence(rowData)  }}
      <dl *ngIf="getCredentialTheftEvidence(rowData)"
          [ngClass]="{'mc-advanced-phishing-result-div': hasNoScanDetailsButTheftEvidence(rowData)}">
        <dt
          class="mc-advanced-phishing-result-label">{{ '$I18N_SERVICES_URL_PROTECTION_LOGS_DETAILS.LABEL_CREDENTIAL_THEFT_EVIDENCE' | translate }}
        </dt>
        <dd>{{getCredentialTheftEvidence(rowData)}}</dd>
      </dl>
    </div>
    <div *ngIf="!!scanDetailsPermission  || scanReportPermission"  class="mc-detailed-list-column">
      <div *ngIf="rowData.tagMap">
        <div class="mc-scan-details-margin" *ngIf="checkScanDetails(rowData.tagMap, scannerTypes.ADVANCEDSIMILARITYCHECKS)">
          <div class="mc-scan-details-label">{{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.ADVANCED_SIMILARITY_CHECK' | translate }}</div>
          <div  class="row mc-detailed-list-row" *ngIf="rowData.tagMap.AdvancedSimilarityChecks[advancedSimilarityChecksTypes.SIMILARDOMAINMATCH]">
            <div class="col-sm-4 mc-scan-detail-subcategory-label" >
              {{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.SIMILAR_DOMAIN_MATCH' | translate }}
            </div>
              <div  class="mc-detailed-list-column">{{rowData.tagMap.AdvancedSimilarityChecks[advancedSimilarityChecksTypes.SIMILARDOMAINMATCH]}}</div>
            </div></div>
        <div class="mc-scan-details-margin"  *ngIf="checkScanDetails(rowData.tagMap, scannerTypes.AVSCANNING)">
          <div  class="mc-scan-details-label">{{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.AVSCANNING' | translate }}</div>
            <div  class="row mc-detailed-list-row" *ngIf="rowData.tagMap.AvScanning[avScanningTypes.AVSIGNATURENAME]">
              <div class="col-sm-4 mc-scan-detail-subcategory-label">
                {{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.AVSIGNATURE_NAME' | translate }}
              </div>
            <div class="mc-detailed-list-column">{{rowData.tagMap.AvScanning[avScanningTypes.AVSIGNATURENAME]}}</div>
              </div>
                <div  class="row mc-detailed-list-row" *ngIf="rowData.tagMap.AvScanning[avScanningTypes.AVSIGNATURECATEGORYTRIGGER]">
                  <div class="col-sm-4 mc-scan-detail-subcategory-label">
                    {{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.CATEGORY_TRIGGER' | translate }}
                  </div>
                 <div  class="mc-detailed-list-column">{{rowData.tagMap.AvScanning[avScanningTypes.AVSIGNATURECATEGORYTRIGGER]}}</div>
                </div>
          <div  class="row mc-detailed-list-row" *ngIf="rowData.tagMap.AvScanning[avScanningTypes.AVSIGNATUREFILEEXTENSION]">
            <div class="col-sm-4 mc-scan-detail-subcategory-label">
              {{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.FILE_EXTENSION' | translate }}
            </div>
            <div class="mc-detailed-list-column">{{rowData.tagMap.AvScanning[avScanningTypes.AVSIGNATUREFILEEXTENSION]}}</div>
          </div>
          </div>

        <div class="mc-scan-details-margin"  *ngIf="checkScanDetails(rowData.tagMap, scannerTypes.MACHINELEARNING)">
          <div  class="mc-scan-details-label">{{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.MACHINELEARNINGDETECTION' | translate }}</div>
          <div class="row mc-detailed-list-row" *ngIf="rowData.tagMap.MachineLearning[machineLearningTypes.TYPE]">
            <div class="col-sm-4 mc-scan-detail-subcategory-label">
              {{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.TYPE' | translate }}
            </div>
            <div class="mc-detailed-list-column">{{rowData.tagMap.MachineLearning[machineLearningTypes.TYPE]}}</div>
          </div>
          <div class="row mc-detailed-list-row" *ngIf="rowData.tagMap.MachineLearning[machineLearningTypes.ORIGNALURL]">
            <div class="col-sm-4 mc-scan-detail-subcategory-label">{{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.URL_BLOCK' | translate }}
            </div>
            <div class="mc-detailed-list-column">{{rowData.tagMap.MachineLearning[machineLearningTypes.ORIGNALURL]}}</div>
          </div>
          <div class="row mc-detailed-list-row" *ngIf="rowData.tagMap.MachineLearning[machineLearningTypes.BLOCKURL]">
            <div class="col-sm-4 mc-scan-detail-subcategory-label">
              {{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.URL' | translate }}
            </div>
            <div class="mc-detailed-list-column">{{rowData.tagMap.MachineLearning[machineLearningTypes.BLOCKURL]}}</div>
          </div>
        </div>

        <div  class="mc-scan-details-margin"  *ngIf="checkScanDetails(rowData.tagMap, scannerTypes.DANGEROUSFILEEXTENSION)">
            <div  class="mc-scan-details-label">{{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.DANGEROUS_FILE_EXTENTION' | translate }}</div>
          <div   class="row mc-detailed-list-row" *ngIf="rowData.tagMap.DangerousFileExt[dangerousFileExtTypes.DANGEROUSFILEDOWNLOAD]">
              <div class="col-sm-4 mc-scan-detail-subcategory-label">
                {{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.DANGEROUS_EXTENTION_FILE_DOWNLOAD' | translate }}
              </div>

            <div class="mc-detailed-list-column">{{rowData.tagMap.DangerousFileExt[dangerousFileExtTypes.DANGEROUSFILEDOWNLOAD]}}</div>
          </div> <div  class="row mc-detailed-list-row" *ngIf="rowData.tagMap.DangerousFileExt[dangerousFileExtTypes.DANGEROUSFILEMIMEDOWNLOAD]">
            <div class="col-sm-4 mc-scan-detail-subcategory-label">{{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.DANGEROUS_MIME_TYPE_FILE_DOWNLOAD' | translate }}
              </div>
             <div class="mc-detailed-list-column">{{rowData.tagMap.DangerousFileExt[dangerousFileExtTypes.DANGEROUSFILEMIMEDOWNLOAD]}}</div>
            </div> </div>
        <div class="mc-scan-details-margin"  *ngIf="checkScanDetails(rowData.tagMap, scannerTypes.HTMLCONTENTCHECKS)">
          <div  class="mc-scan-details-label"> {{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.HTML_CONTENT_CHECKS' | translate }}</div>
          <div class="row mc-detailed-list-row" *ngIf="rowData.tagMap.HtmlContentChecks[htmlContentChecksTypes.INNEREXECUTABLE]">
            <div class="col-sm-4 mc-scan-detail-subcategory-label">{{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.INNER_EXECUTABLE' | translate }}
            </div>
            <div class="mc-detailed-list-column">{{rowData.tagMap.HtmlContentChecks[htmlContentChecksTypes.INNEREXECUTABLE]}}</div>
          </div>
        </div>
        <div class="mc-scan-details-margin"  *ngIf="checkScanDetails(rowData.tagMap, scannerTypes.URLREPUTATIONSCAN)">
          <div class="mc-scan-details-label">{{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.URL_REPUTATION_SCAN' | translate }}</div>
          <div  class="row mc-detailed-list-row" *ngIf="rowData.tagMap.UrlReputationScan[urlReputationScanTypes.TYPE]">
            <div class="col-sm-4 mc-scan-detail-subcategory-label">{{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.TYPE' | translate }}</div>
              <div class="mc-detailed-list-column">{{rowData.tagMap.UrlReputationScan[urlReputationScanTypes.TYPE]}}</div>
            </div>
        <div class="row mc-detailed-list-row" *ngIf="rowData.tagMap.UrlReputationScan[urlReputationScanTypes.URLBlOCK]">
          <div class="col-sm-4 mc-scan-detail-subcategory-label">{{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.URL_BLOCK' | translate }}
          </div>
          <div class="mc-detailed-list-column">{{rowData.tagMap.UrlReputationScan[urlReputationScanTypes.URLBlOCK]}}</div>
          </div>
          <div class="row mc-detailed-list-row" *ngIf="rowData.tagMap.UrlReputationScan[urlReputationScanTypes.URL]">
            <div class="col-sm-4 mc-scan-detail-subcategory-label">
              {{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.URL' | translate }}
            </div>
            <div class="mc-detailed-list-column">{{rowData.tagMap.UrlReputationScan[urlReputationScanTypes.URL]}}</div>
      </div></div>
        <div class="mc-scan-details-margin" *ngIf="checkScanDetails(rowData.tagMap, scannerTypes.CUSTOMERMANAGEDURLS)">
          <div   class="mc-scan-details-label"> {{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.CUSTOM_MANAGED_URLS' | translate }}</div>
          <div   class="row mc-detailed-list-row" *ngIf="rowData.tagMap.CustomerManagedUrls[customManagedUrlsTypes.BLOCKLISTED]">
            <div class="col-sm-4 mc-scan-detail-subcategory-label" >{{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.BLOCKLISTED' | translate }}
            </div>
        <div class="mc-detailed-list-column">{{rowData.tagMap.CustomerManagedUrls[customManagedUrlsTypes.BLOCKLISTED]}}</div>
        </div>
          <div   class="row mc-detailed-list-row" *ngIf="rowData.tagMap.CustomerManagedUrls[customManagedUrlsTypes.MANAGEDURLENTRY]">
            <div class="col-sm-4 mc-scan-detail-subcategory-label" >{{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.MANAGEDURLENTRY' | translate }}
            </div>
            <div class="mc-detailed-list-column">{{rowData.tagMap.CustomerManagedUrls[customManagedUrlsTypes.MANAGEDURLENTRY]}}</div>
          </div>
        </div>
        <div class="mc-scan-details-margin"  *ngIf="checkScanDetails(rowData.tagMap, scannerTypes.SANDBOXING)">
          <div   class="mc-scan-details-label"> {{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.SANDBOXING' | translate }}</div>
          <div  class="row mc-detailed-list-row" *ngIf="rowData.tagMap.Sandboxing[sandboxingTypes.AVSIGNATURENAME]">
            <div class="col-sm-4 mc-scan-detail-subcategory-label">{{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.SANDBOXING_AV_SIGNATURE' | translate }}
            </div>
            <div class="mc-detailed-list-column">{{rowData.tagMap.Sandboxing[sandboxingTypes.AVSIGNATURENAME]}}</div>
          </div>
          <div  class="row mc-detailed-list-row" *ngIf="rowData.tagMap.Sandboxing[sandboxingTypes.MOREINFORMATION]">
            <div class="col-sm-4 mc-scan-detail-subcategory-label">{{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.SANDBOXING_MORE_INFORMATION' | translate }}
            </div>
            <div class="mc-detailed-list-column">{{rowData.tagMap.Sandboxing[sandboxingTypes.MOREINFORMATION]}}</div>
          </div>
          <div  class="row mc-detailed-list-row" *ngIf="rowData.tagMap.Sandboxing[sandboxingTypes.FILEEXTENSION]">
            <div class="col-sm-4 mc-scan-detail-subcategory-label">{{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.SANDBOXING_FILE_EXTENSION' | translate }}
            </div>
            <div class="mc-detailed-list-column">{{rowData.tagMap.Sandboxing[sandboxingTypes.FILEEXTENSION]}}</div>
          </div>
        </div>
        <div class="mc-scan-details-margin"  *ngIf="checkScanDetails(rowData.tagMap, scannerTypes.ADVANCEDPHISHING)">
          <div  class="mc-scan-details-label">  {{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.ADVANCED_PHISHING' | translate }}</div>
         <div  class="row mc-detailed-list-row" *ngIf="rowData.tagMap.AdvancedPhishing[advancedPhishingTypes.CREDENTIALTHEFTEVIDENCE]">
           <div class="col-sm-4 mc-scan-detail-subcategory-label">
              {{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.CREDENTIAL_THEFT_EVIDENCE' | translate }}
            </div>
              <div class="mc-detailed-list-column">{{rowData.tagMap.AdvancedPhishing[advancedPhishingTypes.CREDENTIALTHEFTEVIDENCE]}}</div>
              </div>
          <div class="row mc-detailed-list-row" *ngIf="rowData.tagMap.AdvancedPhishing[advancedPhishingTypes.CREDENTIALTHEFTBRANDS]">
            <div class="col-sm-4 mc-scan-detail-subcategory-label">
              {{ '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.IDENTIFIED_BRANDS' | translate }}
            </div>
              <div class="mc-detailed-list-column">{{rowData.tagMap.AdvancedPhishing[advancedPhishingTypes.CREDENTIALTHEFTBRANDS]}}</div>
            </div></div>
        </div>
  </div>
  </div>
  <hr>
  <mc-value-field
    label="$I18N_SERVICES_URL_PROTECTION_LOGS_DETAILS.LABEL_FROM"
    [value]="rowData.fromUserEmailAddress"></mc-value-field>
  <mc-value-field
    label="$I18N_SERVICES_URL_PROTECTION_LOGS_DETAILS.LABEL_TO"
    [value]="rowData.userEmailAddress"></mc-value-field>
  <mc-value-field
    label="$I18N_SERVICES_URL_PROTECTION_LOGS_DETAILS.LABEL_SUBJECT"
    [value]="rowData.subject"></mc-value-field>
  <mc-value-field
    class="text-capitalize"
    label="$I18N_SERVICES_URL_PROTECTION_LOGS_DETAILS.LABEL_ROUTE"
    [value]="displayRouteLabel(rowData.route)"></mc-value-field>
  <mc-value-field
    label="$I18N_SERVICES_URL_PROTECTION_LOGS_DETAILS.LABEL_SENDING_IP"
    [value]="rowData.sendingIp"></mc-value-field>
  <mc-value-field
    label="$I18N_SERVICES_URL_PROTECTION_LOGS_DETAILS.LABEL_DATA_TIME"
    value="{{ rowData.date | mcDatePlusWeekday }}"></mc-value-field>
</div>
