"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isDataLoading = exports.getGroup = exports.reducer = exports.initialState = void 0;
const groupAction = require("../actions/get-group.actions");
exports.initialState = {
    group: null,
    source: null,
    isDataLoading: false,
    genericError: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case groupAction.GET_GROUP:
            return Object.assign(Object.assign({}, state), { source: action.payload.source, isDataLoading: true });
        case groupAction.GET_GROUP_SUCCESS:
            return Object.assign(Object.assign({}, state), { group: action.payload, isDataLoading: false });
        case groupAction.GET_GROUP_FAIL:
            return Object.assign(Object.assign({}, state), { isDataLoading: false, genericError: true });
        case groupAction.GET_GROUP_RESET_STORE:
            state = exports.initialState;
            return Object.assign({}, state);
        default:
            return state;
    }
}
exports.reducer = reducer;
const getGroup = (state) => state;
exports.getGroup = getGroup;
const isDataLoading = (state) => state.isDataLoading;
exports.isDataLoading = isDataLoading;
