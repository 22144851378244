"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ1 = exports.ɵ0 = exports.isPasswordLoading = exports.getUninstallPassword = exports.getTamperProtectedPassword = exports.getEndpointSettingsUpdateError = exports.isEndpointSettingsUpdating = exports.isEndpointSettingsLoading = exports.getEndpointSettings = exports.getEndpointsAuthKeyLoadingError = exports.isDownloadBinaryDisabled = exports.isEndpointsAuthKeyDeleting = exports.isEndpointsAuthKeyLoading = exports.getEndpointsAuth = exports.reducers = void 0;
const store_1 = require("@ngrx/store");
const endpointsInstall = require("./install.reducer");
const endpointsSettings = require("./settings.reducer");
exports.reducers = {
    endpointsInstall: endpointsInstall.reducer,
    endpointsSettings: endpointsSettings.reducer
};
const getSwgEndpointsState = store_1.createFeatureSelector('swgEndpoints');
const ɵ0 = state => state.endpointsInstall;
exports.ɵ0 = ɵ0;
const getSwgEndpointsInstallState = store_1.createSelector(getSwgEndpointsState, ɵ0);
exports.getEndpointsAuth = store_1.createSelector(getSwgEndpointsInstallState, endpointsInstall.getEndpointsAuth);
exports.isEndpointsAuthKeyLoading = store_1.createSelector(getSwgEndpointsInstallState, endpointsInstall.isAuthLoading);
exports.isEndpointsAuthKeyDeleting = store_1.createSelector(getSwgEndpointsInstallState, endpointsInstall.isAuthDeleting);
exports.isDownloadBinaryDisabled = store_1.createSelector(getSwgEndpointsInstallState, endpointsInstall.isDownloadBinaryDisabled);
exports.getEndpointsAuthKeyLoadingError = store_1.createSelector(getSwgEndpointsInstallState, endpointsInstall.hasGenericError);
const ɵ1 = state => state.endpointsSettings;
exports.ɵ1 = ɵ1;
const getSwgEndpointsSettings = store_1.createSelector(getSwgEndpointsState, ɵ1);
exports.getEndpointSettings = store_1.createSelector(getSwgEndpointsSettings, endpointsSettings.getEndpointSettings);
exports.isEndpointSettingsLoading = store_1.createSelector(getSwgEndpointsSettings, endpointsSettings.isSettingsLoading);
exports.isEndpointSettingsUpdating = store_1.createSelector(getSwgEndpointsSettings, endpointsSettings.isSettingsUpdating);
exports.getEndpointSettingsUpdateError = store_1.createSelector(getSwgEndpointsSettings, endpointsSettings.hasGenericError);
exports.getTamperProtectedPassword = store_1.createSelector(getSwgEndpointsSettings, endpointsSettings.getTamperProtectedPassword);
exports.getUninstallPassword = store_1.createSelector(getSwgEndpointsSettings, endpointsSettings.getUninstallPassword);
exports.isPasswordLoading = store_1.createSelector(getSwgEndpointsSettings, endpointsSettings.isPasswordLoading);
