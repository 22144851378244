<mc-empty-results iconClass="mc-icon-permitted-allowed"
                  keyTitle="{{ '$I18N_IDENTITY_EMPTY_AUTHENTICATION_POLICIES_TAB.TITLE' | translate }}">
  <mc-empty-body>
      <p>{{ '$I18N_IDENTITY_EMPTY_AUTHENTICATION_POLICIES_TAB.DESCRIPTION' | translate }}</p>

      <button
        mc-caps="Permission.ID_MANAGEMENT_EDIT"
        class="mc-table-actions btn btn-primary mc-policy-create" (click)="createNewPolicy()"
        translate="$I18N_IDENTITY_EMPTY_AUTHENTICATION_POLICIES_TAB.BUTTONS.CREATE_NEW_POLICY">
      </button>
  </mc-empty-body>
</mc-empty-results>
