"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgListContainerComponent = void 0;
const categories = require("app-new/swg/policies/actions/categories.actions");
const policy = require("../../actions/policy.actions");
const sidebar = require("../../actions/sidebar.actions");
const sidebar_actions_1 = require("../../actions/sidebar.actions");
const policy_1 = require("../../models/policy");
const reducers = require("../../reducers");
const applications = require("../../actions/applications.actions");
const applications_actions_1 = require("../../actions/applications.actions");
const advancedFileTypes = require("../../actions/advanced-file-types.actions");
const connectors = require("../../actions/connectors.actions");
class SwgListContainerComponent {
    constructor(store, swgPolicyService, stateService, swgService) {
        this.store = store;
        this.swgPolicyService = swgPolicyService;
        this.stateService = stateService;
        this.swgService = swgService;
    }
    ngOnInit() {
        this.store.dispatch(new categories.SwgPolicyLoadCategorySections());
        this.store.dispatch(new advancedFileTypes.SwgPolicyLoadAdvancedFileTypes());
        this.store.dispatch(new applications.SwgPolicyLoadProtectedApplications());
        this.applicationsLoaded$ = this.store.select(reducers.getApplicationsLoaded);
        this.applicationsLoaded$.subscribe(loaded => {
            if (!loaded) {
                this.store.dispatch(new applications_actions_1.SwgPolicyLoadApplications());
            }
        });
        this.hasEditPermission$ = this.swgPolicyService.hasPolicyEditPermission();
        this.hasClp = this.swgService.hasClpIntegrationEnabled();
        if (this.hasClp) {
            this.store.dispatch(new connectors.SwgPolicyLoadConnectorsSections());
        }
    }
    onRowClick(row) {
        this.store.dispatch(new sidebar.SwgDetailEditSidebarOpen({
            description: row.policyDesc.description,
            policyType: row.policyDesc.policyType,
            id: row.id.split(/.*?---(.*)/)[1],
            enabled: row.policyDesc.enabled,
            override: row.policyDesc.override
        }));
    }
    onCreateNewPolicy() {
        this.store.dispatch(new policy.SwgClearPolicy());
        this.store.dispatch(new sidebar_actions_1.SwgPolicyNavigateToEditPageAction(policy_1.SwgPolicyWizardFlowType.CREATE));
    }
    onGoToBlockPageSettings() {
        this.stateService.$state.go('secure-web-gateway-block-page-settings');
    }
    onEditPolicy(swgPolicy) {
        this.store.dispatch(new policy.SwgLoadPolicyToEdit(swgPolicy.policyDesc));
        this.store.dispatch(new sidebar_actions_1.SwgPolicyNavigateToEditPageAction(policy_1.SwgPolicyWizardFlowType.EDIT));
    }
    onDuplicatePolicy(swgPolicy) {
        this.store.dispatch(new policy.SwgLoadPolicyToEdit(swgPolicy.policyDesc));
        this.store.dispatch(new sidebar_actions_1.SwgPolicyNavigateToEditPageAction(policy_1.SwgPolicyWizardFlowType.DUPLIATE));
    }
    onDeletePolicy(swgPolicy) {
        this.store.dispatch(new sidebar.SwgDetailDeleteConfirmModelOpen(swgPolicy.policyDesc));
    }
    onDisablePolicy(swgPolicy) {
        this.store.dispatch(new policy.SwgLoadPolicyToEdit(swgPolicy.policyDesc));
        this.store.dispatch(new sidebar.SwgDetailDisableConfirmModelOpen(swgPolicy.policyDesc));
    }
    onCheckCLPPolicyForConnector(data) {
        this.store.dispatch(new policy.CLPPolicyCheckForConnector(data.policy.policyDesc, data.policyAction));
    }
}
exports.SwgListContainerComponent = SwgListContainerComponent;
