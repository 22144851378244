"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DirectorySyncTableComponent = void 0;
const directory_sync_table_static_values_1 = require("./directory-sync-table.static-values");
const rxjs_1 = require("rxjs");
const reducers = require("../../reducers");
const actions = require("../../actions");
const common_1 = require("../../common/common");
const operators_1 = require("rxjs/operators");
const connector_migration_modal_1 = require("app-new/directory-sync/modals/connector-migration-modal");
class DirectorySyncTableComponent {
    constructor(store, fb, translate, modalService, capabilitiesService, window, featureFlagService) {
        this.store = store;
        this.fb = fb;
        this.translate = translate;
        this.modalService = modalService;
        this.capabilitiesService = capabilitiesService;
        this.window = window;
        this.featureFlagService = featureFlagService;
        this.columns = directory_sync_table_static_values_1.columns;
        this.columnsAlwaysVisible = directory_sync_table_static_values_1.columnsAlwaysVisible;
        this.columnsStartHidden = directory_sync_table_static_values_1.columnsStartHidden;
        this.filterSections = [];
        this.pageSize = 50;
        this.delay = 200;
        this.searchBy = {};
        this.filters = [];
        this.pageSizeSelected = 50;
        this.isSyncRunning = new rxjs_1.BehaviorSubject(undefined);
        this.isSyncRunning$ = this.isSyncRunning.asObservable();
        this.isReportRunning = false;
        this.isSyncReportAvailable = false;
        this.lastOutcome = {
            ok: Object.assign(Object.assign({}, common_1.lastOutcome.ok), { classes: 'mc-integration-last-outcome--success' }),
            error: Object.assign(Object.assign({}, common_1.lastOutcome.error), { classes: 'mc-integration-last-outcome--fail' }),
            not_active: Object.assign(Object.assign({}, common_1.lastOutcome.not_active), { classes: 'mc-integration-last-outcome--not-active' })
        };
        this.serverTypeIcons = Object.assign({}, common_1.serverTypeIcons);
        this.isAzureStandardHecate = false;
        this.isAzureGCCHecate = false;
        this.isOnboardingRedirectEnabled = false;
    }
    ngOnInit() {
        this.integrations$ = this.store.select(reducers.getDirSyncIntegrations);
        this.isLoading$ = this.store.select(reducers.isIntegrationsLoading);
        this.selectedRow$ = this.store.select(reducers.getIntegrationsSelectedRow);
        this.totalCount$ = this.store.select(reducers.getIntegrationsTotalCount);
        this.pagingMetadata$ = this.store.select(reducers.getIntegrationsPagingMetadata);
        this.pageSizeSubscription = this.pagingMetadata$.subscribe(meta => {
            this.pageSize = meta.pagination.pageSize;
        });
        this.syncRunningSubscription = this.store
            .select(reducers.isSyncRunning)
            .subscribe(isRunning => {
            this.isSyncRunning.next(isRunning);
        });
        this.syncReportSubscription = this.store
            .select(reducers.isSyncReportLoading)
            .subscribe(isReportRunning => {
            this.isReportRunning = isReportRunning;
        });
        this.syncReportAvailableSubscription = this.store
            .select(reducers.isSyncReportAvailable)
            .subscribe(isReportAvailable => {
            this.isSyncReportAvailable = isReportAvailable;
        });
        this.searchForm = this.fb.group({
            name: ''
        });
        this.formSubscription = this.searchForm.valueChanges
            .pipe(operators_1.debounceTime(this.delay))
            .subscribe(searchQuery => {
            this.search(searchQuery.name);
        });
        this.updateFilters();
        this.capabilitiesService
            .hasCapability('DirSync.Azure.Standard.Hecate')
            .pipe(operators_1.take(1))
            .subscribe(isActive => {
            this.isAzureStandardHecate = isActive;
        });
        this.capabilitiesService
            .hasCapability('DirSync.Azure.GCC.Hecate')
            .pipe(operators_1.take(1))
            .subscribe(isActive => {
            this.isAzureGCCHecate = isActive;
        });
        this.showOnboardingBtn();
    }
    getServerTypeKey(app) {
        return common_1.getServerTypeKey(app);
    }
    changePage(page) {
        this.pageSizeSelected = page.pageSize;
        this.searchAndFilter(page);
    }
    search(integrationName) {
        this.searchBy = {};
        if (!!integrationName) {
            this.searchBy = { fieldName: 'name', value: integrationName };
        }
        this.searchAndFilter();
    }
    viewConnection(app) {
        this.store.dispatch(new actions.OpenAsideAction({
            integration: app,
            pageSize: this.pageSize
        }));
    }
    createConnection() {
        this.store.dispatch(new actions.OpenWizardAction(false));
    }
    startConnectionSynchronization() {
        this.store.dispatch(new actions.SyncAllIntegrationsAction());
    }
    getLastSynchronizationReport() {
        this.store.dispatch(new actions.SynchronizationReportAction(true));
    }
    editConnection(integration) {
        if (this.shouldShowMigrationModal(integration, 'standard') && this.isAzureStandardHecate) {
            this.showConnectorMigrationModal();
        }
        else if (this.shouldShowMigrationModal(integration, 'gov') && this.isAzureGCCHecate) {
            this.showConnectorMigrationModal();
        }
        else {
            this.store.dispatch(new actions.OpenWizardAction(true, integration));
        }
    }
    enableConnection(integration) {
        const payload = Object.assign(Object.assign({}, integration), { enabled: true, domains: [integration.domains] });
        if (integration.serverType === 'ad_push') {
            payload.adPushSettings = { mseSiteId: payload.adPushSettings.mseSiteId };
        }
        this.store.dispatch(new actions.UpdateIntegrationStatusAction(payload, this.pageSize));
    }
    disableConnection(integration) {
        const payload = Object.assign(Object.assign({}, integration), { enabled: false, domains: [integration.domains] });
        if (integration.serverType === 'ad_push') {
            payload.adPushSettings = { mseSiteId: payload.adPushSettings.mseSiteId };
        }
        this.store.dispatch(new actions.UpdateIntegrationStatusAction(payload, this.pageSize));
    }
    deleteConnection(integration) {
        this.showConfirmationModal('delete', integration);
    }
    shouldShowMigrationModal(integration, typeToCheck) {
        var _a, _b;
        const serverSubtypeMatch = ((_a = integration.office365Settings) === null || _a === void 0 ? void 0 : _a.serverSubtype) === typeToCheck;
        const noConnectorId = !((_b = integration.office365Settings) === null || _b === void 0 ? void 0 : _b.connectorId);
        return serverSubtypeMatch && noConnectorId;
    }
    showConnectorMigrationModal() {
        this.modal = this.modalService.open(connector_migration_modal_1.ConnectorMigrationModalComponent);
    }
    showConfirmationModal(actionType, integration) {
        const data = {
            type: actionType,
            integrationName: integration.description,
            integrationID: integration.id,
            pageSize: this.pageSize
        };
        this.store.dispatch(new actions.ShowConfirmationModalAction(data));
    }
    getSyncButtonLabel() {
        return this.isSyncRunning.getValue()
            ? '$I18N_DIRECTORY_SYNC_TABLE.ACTIONS.SYNCHRONIZING'
            : '$I18N_DIRECTORY_SYNC_TABLE.ACTIONS.SYNCHRONIZE';
    }
    changeColumnFilter(filters) {
        const filterBy = [];
        const { enabled } = filters;
        if (Object.keys(filters).length > 0) {
            filterBy.push(...common_1.mapFilters('lastSyncOutcome', filters.lastSyncOutcomes));
            filterBy.push(...common_1.mapFilters('type', filters.types));
        }
        // Send "enabled" filter when only one option is selected
        if (enabled && enabled.length === 1) {
            filterBy.push({ fieldName: 'enabled', value: enabled[0] === 'enabled' });
        }
        this.filters = filterBy;
        this.searchAndFilter();
    }
    searchAndFilter(page) {
        const filtersPayload = {
            search: {
                filterBy: this.filters
            }
        };
        if (Object.keys(this.searchBy).length > 0) {
            filtersPayload.search.searchBy = Object.assign({}, this.searchBy);
        }
        if (!page) {
            page = {
                pageSize: this.pageSizeSelected
            };
        }
        this.store.dispatch(new actions.SaveSearchFiltersAction(filtersPayload, page));
        this.store.dispatch(new actions.GetAllDirectorySyncIntegrationsAction(page, filtersPayload));
    }
    updateFilters() {
        this.filterSections = directory_sync_table_static_values_1.tableSectionFilters.map(section => {
            return Object.assign(Object.assign({}, section), { displayName: this.translate.instant(section.displayName), filters: section.filters.map(filter => {
                    return Object.assign(Object.assign({}, filter), { displayName: this.translate.instant(filter.displayName) });
                }) });
        });
    }
    navigateToOnboarding() {
        this.store.dispatch(new actions.RedirectToOnboardingAction());
    }
    showOnboardingBtn() {
        const urlSearchParams = new URLSearchParams(this.window.parent.location.search);
        this.featureFlagService.isReady().then(() => {
            const hasConfigEnabled = this.featureFlagService.getBooleanFlag('rollout-onboarding-phase-2') ||
                this.featureFlagService.getBooleanFlag('rollout-msp-onboarding');
            this.isOnboardingRedirectEnabled = urlSearchParams.has('onboarding') && hasConfigEnabled;
        });
    }
    ngOnDestroy() {
        this.syncRunningSubscription.unsubscribe();
        this.pageSizeSubscription.unsubscribe();
        this.formSubscription.unsubscribe();
        this.syncReportSubscription.unsubscribe();
        this.syncReportAvailableSubscription.unsubscribe();
    }
}
exports.DirectorySyncTableComponent = DirectorySyncTableComponent;
