<form novalidate [formGroup]="groupForm" class="row">
  <div class="col-xs-4">
    <label class="radio-inline">
      <input
        type="radio"
        value="allow-all"
        formControlName="block"
        (click)="allowAll.emit('allow-all')"
      />
      {{ '$I18N_SWG_WEB_CATEGORY_POLICY_ALLOW_ALL' | translate }}
    </label>
  </div>

  <div class="col-xs-4">
    <label class="radio-inline">
      <input
        type="radio"
        value="disallow-all"
        formControlName="block"
        (click)="blockAll.emit('disallow-all')"
      />
      {{ '$I18N_SWG_WEB_CATEGORY_POLICY_BLOCK_ALL' | translate }}
    </label>
  </div>

  <div class="col-xs-4">
    <label class="radio-inline">
      <input type="radio" value="custom" formControlName="block" (click)="custom.emit('custom')" />
      {{ '$I18N_SWG_WEB_CATEGORY_POLICY_CUSTOM' | translate }}
    </label>
  </div>
</form>
