"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CyberGraphPoliciesService = void 0;
const cybergraph_policy_wizard_models_1 = require("../models/cybergraph-policy-wizard.models");
const cybergraph_policies_model_1 = require("../models/cybergraph-policies.model");
const cybergraph_applies_to_settings_step_models_1 = require("../components/steps/cybergraph-applies-to-settings-step/cybergraph-applies-to-settings-step.models");
class CyberGraphPoliciesService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    getAddressAttributes() {
        const config = {
            meta: { pagination: { pageSize: 5000 } }
        };
        return this.httpClient.post('/api/attribute/find-attribute-types', config);
    }
    createCyberGraphPolicyDefinition(request) {
        return this.httpClient.post('/api/policy/cybergraph/create-policy', request);
    }
    editCyberGraphPolicyDetails(request) {
        return this.httpClient.post('/api/policy/cybergraph/update-policy', request);
    }
    editCyberGraphPolicyDefinition(request) {
        return this.httpClient.post('/api/policy/cybergraph/update-policy-definition', request);
    }
    editCyberGraphPolicyRules(request) {
        return this.httpClient.post('/api/policy/cybergraph/update-policy-rules', request);
    }
    appliesToAddressAttributeResponseToItems(response) {
        return response.all[0].attributeTypes.map(item => {
            return {
                value: item.id,
                label: item.prompt
            };
        });
    }
    mapToPolicyDefinitionRequest(policy) {
        const { detailsStep, appliesToStep, summaryStep } = policy;
        const detailsData = detailsStep.data;
        const appliesToData = appliesToStep.data;
        const summaryData = summaryStep.data;
        const requestData = Object.assign({ codebreakerStatus: detailsData.codebreakerStatus, silencerStatus: detailsData.silencerStatus === cybergraph_policies_model_1.CyberGraphTrackersEnum.ON
                ? cybergraph_policies_model_1.CyberGraphTrackersEnum.ON
                : cybergraph_policies_model_1.CyberGraphTrackersEnum.OFF, reportingStatus: detailsData.reportingStatus === true
                ? cybergraph_policies_model_1.CyberGraphReportingStatusEnum.ON
                : cybergraph_policies_model_1.CyberGraphReportingStatusEnum.OFF }, this.mapDetailsAndAppliesToData(detailsData, appliesToData, summaryData));
        if (detailsStep.data.id) {
            requestData['settingsId'] = detailsStep.data.id;
        }
        return requestData;
    }
    mapDetailsToData(detailsData, summaryData) {
        const policy = {
            name: detailsData.name,
            description: detailsData.description,
            enabled: summaryData.policyStatusEnabled,
            override: detailsData.policyOverride
        };
        if (detailsData.id) {
            policy.id = detailsData.id;
        }
        return policy;
    }
    mapRulesToData(appliesToData) {
        return (appliesToData.length &&
            appliesToData.map(rule => {
                const mappedRule = {
                    id: rule.id,
                    bidirectional: rule.bidirectional,
                    fromPart: rule.addressesBasedOn,
                    from: {
                        type: rule.appliesFrom
                    },
                    to: {
                        type: rule.appliesTo
                    },
                    conditions: {
                        sourceIPs: rule.ipRanges
                    }
                };
                if (rule.appliesFrom === cybergraph_applies_to_settings_step_models_1.AppliesFromToOptions.EMAIL_ADDRESS) {
                    mappedRule.from['emailAddress'] = rule.appliesFromAddress;
                }
                if (rule.appliesFrom === cybergraph_applies_to_settings_step_models_1.AppliesFromToOptions.DOMAIN) {
                    mappedRule.from['emailDomain'] = rule.appliesFromDomain;
                }
                if (rule.appliesFrom === cybergraph_applies_to_settings_step_models_1.AppliesFromToOptions.ADDRESS_GROUP) {
                    mappedRule.from['groupId'] =
                        rule.appliesFromAddressGroup && rule.appliesFromAddressGroup.id;
                }
                if (rule.appliesTo === cybergraph_applies_to_settings_step_models_1.AppliesFromToOptions.EMAIL_ADDRESS) {
                    mappedRule.to['emailAddress'] = rule.appliesToAddress;
                }
                if (rule.appliesTo === cybergraph_applies_to_settings_step_models_1.AppliesFromToOptions.DOMAIN) {
                    mappedRule.to['emailDomain'] = rule.appliesToDomain;
                }
                if (rule.appliesTo === cybergraph_applies_to_settings_step_models_1.AppliesFromToOptions.ADDRESS_GROUP) {
                    mappedRule.to['groupId'] = rule.appliesToAddressGroup && rule.appliesToAddressGroup.id;
                }
                if (rule.id) {
                    mappedRule['id'] = rule.id;
                }
                return mappedRule;
            }));
    }
    mapDetailsAndAppliesToData(detailsData, appliesToData, summaryData) {
        const policy = this.mapDetailsToData(detailsData, summaryData);
        return {
            policy,
            rules: this.mapRulesToData(appliesToData)
        };
    }
    mapToWizardState(policyData) {
        return {
            apiIsProcessing: false,
            detailsStep: {
                data: this.mapDetailsRequestToState(policyData)
            },
            incomingAppliesToStep: {
                data: this.mapAppliesToRequestToState(policyData)
            },
            appliesToStep: {
                data: this.mapAppliesToRequestToState(policyData)
            },
            summaryStep: {
                data: {
                    policyStatusEnabled: policyData.policy.enabled
                }
            },
            wizardFlowType: cybergraph_policy_wizard_models_1.PolicyWizardFlowType.EDIT
        };
    }
    mapDetailsRequestToState(details) {
        return {
            id: details.policy.id,
            name: details.policy.name,
            description: details.policy.description,
            policyOverride: details.policy.override,
            codebreakerStatus: details.codebreakerStatus,
            reportingStatus: details.reportingStatus === cybergraph_policies_model_1.CyberGraphReportingStatusEnum.ON ? true : false,
            // No longer support learning for the silencer status, so treat learning as off.
            silencerStatus: details.silencerStatus === cybergraph_policies_model_1.CyberGraphTrackersEnum.ON
                ? cybergraph_policies_model_1.CyberGraphTrackersEnum.ON
                : cybergraph_policies_model_1.CyberGraphTrackersEnum.OFF
        };
    }
    mapAppliesFromToFields(policyTargetRequest, appliesFrom = true) {
        const storeData = {};
        storeData[appliesFrom ? 'appliesFrom' : 'appliesTo'] = policyTargetRequest.type;
        if (policyTargetRequest.attribute) {
            if (appliesFrom) {
                storeData['fromWhereAttribute'] = {
                    value: policyTargetRequest.attribute.id,
                    label: policyTargetRequest.attribute.name,
                    isSelected: true
                };
                storeData['appliesFromIsEqualTo'] = policyTargetRequest.attribute.value;
            }
            else {
                storeData['toWhereAttribute'] = {
                    value: policyTargetRequest.attribute.id,
                    label: policyTargetRequest.attribute.name,
                    isSelected: true
                };
                storeData['appliesToIsEqualTo'] = policyTargetRequest.attribute.value;
            }
        }
        if (policyTargetRequest.emailAddress) {
            if (appliesFrom) {
                storeData['appliesFromAddress'] = policyTargetRequest.emailAddress;
            }
            else {
                storeData['appliesToAddress'] = policyTargetRequest.emailAddress;
            }
        }
        if (policyTargetRequest.emailDomain) {
            if (appliesFrom) {
                storeData['appliesFromDomain'] = policyTargetRequest.emailDomain;
            }
            else {
                storeData['appliesToDomain'] = policyTargetRequest.emailDomain;
            }
        }
        if (policyTargetRequest.group) {
            if (appliesFrom) {
                storeData['appliesFromAddressGroup'] = {
                    id: policyTargetRequest.group.id,
                    description: policyTargetRequest.group.description
                };
            }
            else {
                storeData['appliesToAddressGroup'] = {
                    id: policyTargetRequest.group.id,
                    description: policyTargetRequest.group.description
                };
            }
        }
        return Object.assign({}, storeData);
    }
    mapAppliesToRequestToState(policy) {
        return policy.rules.map(rule => {
            return Object.assign(Object.assign(Object.assign({ id: rule.id, addressesBasedOn: rule.fromPart }, this.mapAppliesFromToFields(rule.from)), this.mapAppliesFromToFields(rule.to, false)), { bidirectional: rule.bidirectional, ipRanges: rule.conditions && rule.conditions.sourceIPs });
        });
    }
}
exports.CyberGraphPoliciesService = CyberGraphPoliciesService;
