<form class="form-horizontal" mcDefaultLabelClass="col-sm-3" mcDefaultControlContainerClass="col-sm-9" [formGroup]="form">
    <mc-field
      class="no-margin-bottom"
      errorPrefix="$I18N_API_INTEGRATION_NOTIFICATION_COMPONENT.NOTIFICATIONS.ERROR.CODE"
      label="{{ label }}"
      required
    >
        <div class="col-sm-9 no-padding-left">
            <input
                [placeholder]="
                    '$I18N_API_INTEGRATION_NOTIFICATION_COMPONENT.NOTIFICATIONS.EMAIL_LABEL' | translate
                "
                class="form-control"
                type="email"
                name="email"
                formControlName="email"
                aria-label="email"
            />
        </div>
        <div class="col-sm-3 mc-add-email">
            <button (click)="addEmail()" type="button" class="btn btn-default btn-sm" [disabled]="!form.valid || form.value.email === '' || isAddEmailBtnDisabled">
                {{ '$I18N_API_INTEGRATION_NOTIFICATION_COMPONENT.NOTIFICATIONS.ADD' | translate }}
                <span>{{ btnExtraInfo | translate }}</span>
            </button>
        </div>
    </mc-field>

    <div class="form-emails mc-field">
        <div class="col-sm-12">
            <div class="selected-emails user col-sm-offset-3" *ngFor="let email of emails">
                <span class="emails-list">
                    <div class="emails-name">
                        {{ email }}
                    </div>
                    <i class="far fa-trash-alt"
                        aria-hidden="true"
                        (click)="deleteEmail(email)"
                    ></i>
                </span>
                <hr class="emails-separator" />
            </div>
        </div>
    </div>
</form>
