
    <mc-layout-list-extra-container
      keyTitle="$I18N_ONEDRIVE_LIST.TITLE"
      keyDescription="$I18N_ONEDRIVE_LIST.DESCRIPTION"
    >
      <mc-body-container>
        <mc-drives-list-table
          *ngIf="(tableData$ | async)?.length || (currentFilters$ | async)"
          [tableData]="tableData$ | async"
          [metaData]="metaData$ | async"
          [isLoading]="isLoading$ | async"
          (onPaginationChange)="onPaginationChange($event)"
          (onFilterChange)="onFilterChange($event)"
          (onRowClick)="openItem($event)"
        >
        </mc-drives-list-table>
        <mc-no-drives
          *ngIf="(tableData$ | async)?.length === 0 && !(currentFilters$ | async)"
          (onCreateBackup)="createBackup()"
        >
        </mc-no-drives>
      </mc-body-container>
    </mc-layout-list-extra-container>
  