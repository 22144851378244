"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OpenApiApplicationAction = exports.ApiTechPartnerListActionTypes = void 0;
var ApiTechPartnerListActionTypes;
(function (ApiTechPartnerListActionTypes) {
    ApiTechPartnerListActionTypes["OpenApiApplication"] = "[ApiApplication/TechPartners/List] Open Create Api Application";
})(ApiTechPartnerListActionTypes = exports.ApiTechPartnerListActionTypes || (exports.ApiTechPartnerListActionTypes = {}));
class OpenApiApplicationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiTechPartnerListActionTypes.OpenApiApplication;
    }
}
exports.OpenApiApplicationAction = OpenApiApplicationAction;
