"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const list_component_1 = require("./list/list.component");
const static_1 = require("@angular/upgrade/static");
const detail_component_1 = require("./detail/detail.component");
const window_1 = require("app-new/components/window");
angular
    .module('sync-recover.exchange.snapshot', [])
    .controller('ExchangeSnapshotController', function () { })
    .factory('windowService', static_1.downgradeInjectable(window_1.WindowService))
    .directive('mcSyncRecoverExchangeSnapshotList', static_1.downgradeComponent({
    component: list_component_1.ListComponent
}))
    .directive('mcExchangeSnapshotDetail', static_1.downgradeComponent({
    component: detail_component_1.DetailComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        const breadcrumbs = ['$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_LIST.BREADCRUMBS.1'];
        $stateProvider
            .state('sync-recover-exchange-sit-list', {
            url: '^/sync-recover/exchange/snapshots',
            parent: 'archive-task-list',
            data: {
                capabilities: 'Sync.Mailbox.ArchiveAndRecover && Permission.DATA_SYNC_AND_RECOVER_READ',
                isFixedLayout: false,
                checkProgress: false,
                tabId: 'sync-and-recover-tab',
                tabTitle: '$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_LIST.TAB_TITLE',
                asideTitle: '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ASIDE_MENU_TITLE',
                breadcrumbs,
                tabReload: false
            },
            views: {
                main: {
                    template: '<mc-sync-recover-exchange-snapshot-list ></mc-sync-recover-exchange-snapshot-list>',
                    controller: 'ExchangeSnapshotController'
                },
                aside: {
                    templateUrl: 'services/services/backup/aside/aside.tpl.html'
                }
            },
            onEnter: [
                'windowService',
                function (windowService) {
                    if (windowService.inIframe()) {
                        breadcrumbs.length = 0;
                    }
                }
            ]
        })
            .state('sync-recover-exchange-snapshot-detail', {
            url: '^/sync-recover/exchange/snapshot/:snapshotId',
            parent: 'archive-task-list',
            params: { snapshot: null },
            data: {
                capabilities: 'Sync.Mailbox.ArchiveAndRecover && Permission.DATA_SYNC_AND_RECOVER_READ',
                tabId: 'snapshot-details-tab',
                tabTitle: '$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_DETAIL.TAB_TITLE',
                breadcrumbs: [
                    '$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_LIST.BREADCRUMBS.1',
                    '$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_DETAIL.BREADCRUMBS.1'
                ],
                tabReload: false
            },
            views: {
                main: {
                    template: '<mc-exchange-snapshot-detail></mc-exchange-snapshot-detail>',
                    controller: 'ExchangeSnapshotController'
                },
                aside: {
                    templateUrl: 'services/services/backup/aside/aside.tpl.html'
                }
            },
            onEnter: [
                '$state',
                '$stateParams',
                function ($state, $stateParams) {
                    if (!$stateParams.snapshot && !$stateParams.snapshotId) {
                        $state.go('sync-recover-exchange-sit-list', $stateParams);
                    }
                }
            ]
        });
    }
]);
