"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgGetUrlClassificationFailureAction = exports.SwgGetUrlClassificationSuccessAction = exports.SwgGetUrlClassificationAction = exports.SwgUrlClassificationActions = void 0;
var SwgUrlClassificationActions;
(function (SwgUrlClassificationActions) {
    SwgUrlClassificationActions["GET_URL_CLASSFICATION"] = "[SwgUrlClassification] Get Classifcations";
    SwgUrlClassificationActions["GET_URL_CLASSFICATION_SUCCESS"] = "[SwgUrlClassification] Get Classifcations Success";
    SwgUrlClassificationActions["GET_URL_CLASSFICATION_FAILURE"] = "[SwgUrlClassification] Get Classifcations Failure";
})(SwgUrlClassificationActions = exports.SwgUrlClassificationActions || (exports.SwgUrlClassificationActions = {}));
class SwgGetUrlClassificationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgUrlClassificationActions.GET_URL_CLASSFICATION;
    }
}
exports.SwgGetUrlClassificationAction = SwgGetUrlClassificationAction;
class SwgGetUrlClassificationSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgUrlClassificationActions.GET_URL_CLASSFICATION_SUCCESS;
    }
}
exports.SwgGetUrlClassificationSuccessAction = SwgGetUrlClassificationSuccessAction;
class SwgGetUrlClassificationFailureAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgUrlClassificationActions.GET_URL_CLASSFICATION_FAILURE;
    }
}
exports.SwgGetUrlClassificationFailureAction = SwgGetUrlClassificationFailureAction;
