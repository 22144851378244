"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEditableFields = exports.Editable = void 0;
require("reflect-metadata");
const EDITABLE_METADATA_KEY = Symbol('MC-EDITABLE-FIELD');
function Editable() {
    return (target, propertyKey) => {
        let editableFields = Reflect.getMetadata(EDITABLE_METADATA_KEY, target);
        if (editableFields) {
            editableFields.push(propertyKey);
        }
        else {
            editableFields = [propertyKey];
        }
        Reflect.defineMetadata(EDITABLE_METADATA_KEY, editableFields, target);
    };
}
exports.Editable = Editable;
function getEditableFields(origin) {
    const properties = Reflect.getMetadata(EDITABLE_METADATA_KEY, origin);
    const result = {};
    if (properties) {
        properties.forEach(key => (result[key] = origin[key]));
    }
    return result;
}
exports.getEditableFields = getEditableFields;
