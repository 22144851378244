"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PermittedSenderDetailsDisplayPipe = void 0;
class PermittedSenderDetailsDisplayPipe {
    constructor(translateService) {
        this.translateService = translateService;
        this.positiveProperty = '<span class="positiveProperty">';
        this.spanProperty = '</span>';
    }
    transform(permittedSenderInfo = '') {
        switch (permittedSenderInfo.toLowerCase()) {
            case 'none':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_PERMITTED_SENDER.NONE');
            case 'whitelist':
                this.permittedSenderText = this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_PERMITTED_SENDER.WHITELIST');
                return `${this.positiveProperty}${this.permittedSenderText}${this.spanProperty}`;
            case 'whitelist_and_spam':
                this.permittedSenderText = this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_PERMITTED_SENDER.WHITELIST_AND_SPAM');
                return `${this.positiveProperty}${this.permittedSenderText}${this.spanProperty}`;
            case 'whitelist_and_greylist':
                this.permittedSenderText = this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_PERMITTED_SENDER.WHITELIST_AND_GREYLIST');
                return `${this.positiveProperty}${this.permittedSenderText}${this.spanProperty}`;
            case 'whitelist_and_rbl':
                this.permittedSenderText = this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_PERMITTED_SENDER.WHITELIST_AND_RBL');
                return `${this.positiveProperty}${this.permittedSenderText}${this.spanProperty}`;
            case 'whitelist_and_spam_and_rbl':
                return `<span class="positiveProperty">${this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_PERMITTED_SENDER.WHITELIST_AND_SPAM_AND_RBL')}</span>`;
            case 'whitelist_and_spam_and_greylist':
                return `<span class="positiveProperty">${this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_PERMITTED_SENDER.WHITELIST_AND_SPAM_AND_GREYLIST')}</span>`;
            case 'whitelist_and_greylist_and_rbl':
                return `<span class="positiveProperty">${this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_PERMITTED_SENDER.WHITELIST_AND_GREYLIST_AND_RBL')}</span>`;
            case 'blacklist':
                return `<span class="negativeProperty">${this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_PERMITTED_SENDER.BLACKLIST')}</span>`;
            case 'ignored':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_PERMITTED_SENDER.IGNORED');
            default:
                return '';
        }
    }
}
exports.PermittedSenderDetailsDisplayPipe = PermittedSenderDetailsDisplayPipe;
