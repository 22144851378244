"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CyberGraphTrackerDesc = exports.CyberGraphDynamicBannerDesc = exports.CyberGraphTrackerEnumLabel = exports.CyberGraphDynamicBannerEnumLabel = exports.CyberGraphReportingEnumLabel = exports.SHOW_MORE_LESS_TRIGGER_VALUE = exports.APPLIES_TO_STEP_NUMBER = exports.DETAILS_STEP_NUMBER = void 0;
exports.DETAILS_STEP_NUMBER = 1;
exports.APPLIES_TO_STEP_NUMBER = 2;
exports.SHOW_MORE_LESS_TRIGGER_VALUE = 5;
var CyberGraphReportingEnumLabel;
(function (CyberGraphReportingEnumLabel) {
    CyberGraphReportingEnumLabel["ENABLED"] = "$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.SUMMARY.LABELS.ENABLED";
    CyberGraphReportingEnumLabel["DISABLED"] = "$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.SUMMARY.LABELS.DISABLED";
})(CyberGraphReportingEnumLabel = exports.CyberGraphReportingEnumLabel || (exports.CyberGraphReportingEnumLabel = {}));
var CyberGraphDynamicBannerEnumLabel;
(function (CyberGraphDynamicBannerEnumLabel) {
    CyberGraphDynamicBannerEnumLabel["ENABLED"] = "$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.CYBERGRAPH_POLICY_PRODUCT_STATUS.ON";
    CyberGraphDynamicBannerEnumLabel["LEARNING"] = "$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.CYBERGRAPH_POLICY_PRODUCT_STATUS.LEARNING";
    CyberGraphDynamicBannerEnumLabel["DISABLED"] = "$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.CYBERGRAPH_POLICY_PRODUCT_STATUS.OFF";
})(CyberGraphDynamicBannerEnumLabel = exports.CyberGraphDynamicBannerEnumLabel || (exports.CyberGraphDynamicBannerEnumLabel = {}));
var CyberGraphTrackerEnumLabel;
(function (CyberGraphTrackerEnumLabel) {
    CyberGraphTrackerEnumLabel["ENABLED"] = "$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.CYBERGRAPH_POLICY_PRODUCT_STATUS.ON";
    CyberGraphTrackerEnumLabel["DISABLED"] = "$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.CYBERGRAPH_POLICY_PRODUCT_STATUS.OFF";
})(CyberGraphTrackerEnumLabel = exports.CyberGraphTrackerEnumLabel || (exports.CyberGraphTrackerEnumLabel = {}));
var CyberGraphDynamicBannerDesc;
(function (CyberGraphDynamicBannerDesc) {
    CyberGraphDynamicBannerDesc["ENABLED"] = "$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.DYNAMIC_BANNER_STATUS_DESCRIPTIONS.ON";
    CyberGraphDynamicBannerDesc["LEARNING"] = "$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.DYNAMIC_BANNER_STATUS_DESCRIPTIONS.LEARNING";
    CyberGraphDynamicBannerDesc["DISABLED"] = "$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.DYNAMIC_BANNER_STATUS_DESCRIPTIONS.OFF";
})(CyberGraphDynamicBannerDesc = exports.CyberGraphDynamicBannerDesc || (exports.CyberGraphDynamicBannerDesc = {}));
var CyberGraphTrackerDesc;
(function (CyberGraphTrackerDesc) {
    CyberGraphTrackerDesc["ENABLED"] = "$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.TRACKER_STATUS_DESCRIPTIONS.ON";
    CyberGraphTrackerDesc["DISABLED"] = "$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.TRACKER_STATUS_DESCRIPTIONS.OFF";
})(CyberGraphTrackerDesc = exports.CyberGraphTrackerDesc || (exports.CyberGraphTrackerDesc = {}));
