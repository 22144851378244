"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./date-time-picker.directive"); //date-time-picker.directive
// html and css 
require("./date-time-picker.less");
require("./date-time-picker.tpl.html");
/**
 * Created by pdesai on 16/03/2017.
 */
(function () {
    'use strict';
    angular.module('date-time-picker', ['date-time-picker.directive']);
}());
