"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("app/services/services/backup/components/download-file/download-file");
require("app/services/services/backup/components/export-download-aside/export-download-aside");
require("./exports-and-restore-controller");
require("./exports-and-restore.less");
require("./exports-and-restore.tpl.html");
(function () {
    'use strict';
    angular
        .module('services.services.backup.exports-and-restore', [
        'services.services.backup.exports-and-restore.controller',
        'export-download-aside',
        'download-file'
    ])
        .config([
        '$stateProvider',
        function ($stateProvider) {
            const breadcrumbs = [
                '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_SERVICES',
                '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ASIDE_MENU_TITLE',
                '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_EXPORT_AND_RESTORE_MENU_TAB'
            ];
            $stateProvider.state('backup-exports-and-restore', {
                url: '/sync-recover/exchange/export-restore-queue',
                data: {
                    capabilities: 'Sync.Mailbox.ArchiveAndRecover && Permission.DATA_SYNC_AND_RECOVER_READ',
                    breadcrumbs,
                    tabId: 'sync-and-recover-tab',
                    asideTitle: '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ASIDE_MENU_TITLE',
                    tabTitle: '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ASIDE_MENU_TITLE',
                    tabHide: false,
                    tabReload: false
                },
                views: {
                    main: {
                        templateUrl: 'services/services/backup/exports-and-restore/exports-and-restore.tpl.html'
                    },
                    aside: {
                        templateUrl: 'services/services/backup/aside/aside.tpl.html'
                    }
                },
                onEnter: [
                    'windowService',
                    function (windowService) {
                        if (windowService.inIframe()) {
                            breadcrumbs.length = 0;
                            breadcrumbs.push('$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_SERVICES', '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ASIDE_MENU_TITLE');
                        }
                    }
                ]
            });
        }
    ]);
})();
