<mc-layout-aside-extra-container keyTitle="$I18N_ARCHIVE_VIEWER_TOKEN_DETAIL"
                        showClose="true"
                        (closeAside)="close()"
                        [isLoading]="isLoading$ | async">

  <mc-extra-container>

    <div *ngIf="sliderToggle$ | async as email">
        <!-- Downloads Token dropdown -->
        <form [formGroup]="archiveViewerSlider">
          <mc-field class="col-xs-3 no-padding-left" data-test="search_by_token_dropdown">
          </mc-field>

          <div class="col-sm-12">
            <mc-live-button *ngIf="email.size"
                            typeClass="btn btn-primary"
                            type="submit"
                            content="{{'$I18N_ARCHIVE_VIEWER_SEARCH_TAB_TOKEN_DOWNLOAD' | translate}} / size = {{ email.size | mcToBytes}}"
                            (mcClick)="downloadXmlComponent($event,'DOWNLOAD')"
                            data-test="search_by_content_btn">
            </mc-live-button>

            <mc-live-button *ngIf="!email.size"
                            typeClass="btn btn-primary"
                            type="submit"
                            content="{{'$I18N_ARCHIVE_VIEWER_SEARCH_TAB_TOKEN_DOWNLOAD_ALL' | translate}}"
                            (mcClick)="downloadXmlComponent($event,'DOWNLOAD_ALL')"
                            data-test="search_by_content_btn">
            </mc-live-button>
          </div>
        </form>
    </div>

  </mc-extra-container>

  <mc-body-container>

    <div *ngIf="sliderToggle$ | async as email">

      <div class="col-xs-12 mc-archive-viewer-slider-body panel-padding-top">

        <!-- TODO: create a re-usable DRY new component: and then import it into: here and download-xml-component -->
        <!-- We repeat this on on the main component: search by token -->
        <form [formGroup]="archivePartial">
          <mc-field>
            <select class="form-control" name="partialTokens" formControlName="partialTokens">
              <optgroup label="Token">
                <option *ngFor="let item of email.deliveryComponents; let i = index">
                  {{ email.deliveryComponents[i].token }}
                </option>
              </optgroup>
              <optgroup label="Attachments">
                <option *ngFor="let item of email.messageComponents; let n = index">
                  {{ email.messageComponents[n].token }}
                </option>
              </optgroup>
            </select>
          </mc-field>
        </form>

        <!-- slider XML Token tree view -->
        <textarea *ngIf="partialXmlTag" class="mc-output-holder" rows="50">{{ partialXmlTag.content }}</textarea>
        <textarea *ngIf="!partialXmlTag && sliderToggle$ | async as email" class="mc-output-holder" rows="50">{{ email.content }}</textarea>

      </div>

    </div>

  </mc-body-container>

</mc-layout-aside-extra-container>
