"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubmitMaliciousHashDescPipe = void 0;
class SubmitMaliciousHashDescPipe {
    constructor(translateService) {
        this.translateService = translateService;
    }
    transform(submitUrlEnabled, iocOutboundAction) {
        if (submitUrlEnabled && iocOutboundAction && iocOutboundAction !== '') {
            return (this.transformSubmitUrlFlag(submitUrlEnabled) +
                ', ' +
                this.transformOutboundAction(iocOutboundAction));
        }
        return this.transformSubmitUrlFlag(submitUrlEnabled);
    }
    transformSubmitUrlFlag(submitUrlEnabled) {
        if (submitUrlEnabled) {
            return this.translateService.instant('$I18N_CROWDSTRIKE_INTEGRATION_SUBMIT_MALICIOUS_HASH_PIPE.FLAG.ENABLED');
        }
        return this.translateService.instant('$I18N_CROWDSTRIKE_INTEGRATION_SUBMIT_MALICIOUS_HASH_PIPE.FLAG.DISABLED');
    }
    transformOutboundAction(iocOutboundAction) {
        if (iocOutboundAction) {
            return this.translateService.instant('$I18N_CROWDSTRIKE_INTEGRATION_SUBMIT_MALICIOUS_HASH_PIPE.ACTION.' + iocOutboundAction);
        }
        return this.translateService.instant('$I18N_CROWDSTRIKE_INTEGRATION_SUBMIT_MALICIOUS_HASH_PIPE.ACTION.OTHER');
    }
}
exports.SubmitMaliciousHashDescPipe = SubmitMaliciousHashDescPipe;
