<mc-layout-list-extra-container
  keyTitle="$I18N_MESSAGE_CENTER_MESSAGE_PROCESSING_MESSAGES.HEADER.TITLE"
  keyDescription="$I18N_MESSAGE_CENTER_MESSAGE_PROCESSING_MESSAGES.HEADER.SUB_TITLE"
>
  <mc-help-container>
    <mc-help-link url="https://community.mimecast.com/docs/DOC-3549"></mc-help-link>
  </mc-help-container>

  <mc-body-container>
    <div *mcCapabilities="processingCaps">
      <mc-tab-group #tabs [initialActiveTabIndex]="0">
        <div class="panel-padding-top">
          <mc-tab
            name="{{
              '$I18N_MESSAGE_CENTER_MESSAGE_PROCESSING_MESSAGES.TABS.PROCESSING' | translate
            }}"
          >
            <mc-message-processing-tab></mc-message-processing-tab>
          </mc-tab>

          <mc-tab
            name="{{ '$I18N_MESSAGE_CENTER_MESSAGE_PROCESSING_MESSAGES.TABS.BULK' | translate }}"
          >
            <mc-bulk-processing-tab></mc-bulk-processing-tab>
          </mc-tab>
          <mc-tab *mcCapabilities="systemCaps"
            name="{{ '$I18N_MESSAGE_CENTER_MESSAGE_PROCESSING_MESSAGES.TABS.SYSTEM' | translate }}">
            <mc-system-messages-tab></mc-system-messages-tab>
          </mc-tab>
        </div>
      </mc-tab-group>
    </div>
    <div *mcCapabilities="noProcessingCaps">
      <mc-message-processing-tab></mc-message-processing-tab>
    </div>
  </mc-body-container>
</mc-layout-list-extra-container>