"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdConResponseTransformerInterceptor = void 0;
const http_1 = require("@angular/common/http");
const operators_1 = require("rxjs/operators");
class AdConResponseTransformerInterceptor {
    constructor(sessionHandlerService) {
        this.sessionHandlerService = sessionHandlerService;
        this.LOGOUT_URL = 'api/login/logout';
    }
    /**
     * Intercept Function
     */
    intercept(req, next) {
        return next.handle(req).pipe(operators_1.map(event => {
            if (req.responseType !== 'arraybuffer' &&
                event instanceof http_1.HttpResponse &&
                req.method === 'POST') {
                return this.sendRightResponse(event);
            }
            return event;
        }));
    }
    /**
     * Transform
     */
    sendRightResponse(resp) {
        const isError = resp.body.fail && resp.body.fail.length && resp.status === 200;
        const newResp = resp.clone({
            body: this.transformResponse(resp)
        });
        if (isError) {
            this.sessionHandlerService.extendSessionCookieExpiry();
            throw newResp.body;
        }
        else {
            if (resp.url.indexOf(this.LOGOUT_URL) < 0) {
                this.sessionHandlerService.extendSessionCookieExpiry();
            }
            return newResp;
        }
    }
    /**
     * Transform the Api Response on success!
     */
    transformResponse(resp) {
        if (resp.body.data || resp.body.fail) {
            return {
                first: resp.body.data && resp.body.data[0] ? resp.body.data[0] : {},
                all: resp.body.data ? resp.body.data : [],
                hasErrors: resp.body.fail.length > 0,
                firstFail: resp.body.fail && resp.body.fail[0] ? resp.body.fail[0] : {},
                allFail: resp.body.fail || [],
                fail: resp.body.fail || [],
                meta: resp.body.meta || {}
            };
        }
        else {
            return resp;
        }
    }
}
exports.AdConResponseTransformerInterceptor = AdConResponseTransformerInterceptor;
