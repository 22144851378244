"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImTaskService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const ConnectorApps = ['ms_teams', 'ms_teams_gcc'];
class ImTaskService {
    constructor(http) {
        this.http = http;
    }
    static splitWords(terms) {
        return ImTaskService.splitSearchTerms(terms, /,|\n| /);
    }
    static splitPhrases(terms) {
        return ImTaskService.splitSearchTerms(terms, /\n/);
    }
    static splitSearchTerms(terms, regex) {
        let splittedTerms = [];
        if (terms) {
            splittedTerms = [...terms.split(regex).filter(Boolean)];
        }
        return splittedTerms;
    }
    createImSyncTask(data) {
        return this.http.post('/api/instant-messaging/create-task', data).pipe(operators_1.map((response) => {
            return response;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    updateImSyncTask(data) {
        return this.http.post('/api/instant-messaging/update-task', { data: [data] }).pipe(operators_1.map((response) => {
            return response;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    getImTaskDetails(id) {
        return this.http.post('/api/instant-messaging/get-task-details', { data: [{ id }] }).pipe(operators_1.map((response) => response), operators_1.catchError((error) => rxjs_1.of(error)));
    }
    getConnectors() {
        return this.http
            .post('/api/connectors/find-connectors', {
            meta: { pagination: { pageSize: 50 } },
            data: [
                {
                    filterBy: ConnectorApps.map(value => ({ fieldName: 'app', value }))
                }
            ]
        })
            .pipe(operators_1.map((response) => {
            return response;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    updateTaskStatus(data) {
        return this.http.post('/api/instant-messaging/update-task', { data: [data] }).pipe(operators_1.map((response) => {
            return response;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
}
exports.ImTaskService = ImTaskService;
ImTaskService.WizardId = 'services-im-sync-create-wizard-im-task';
