"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgDashboardEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const swg_dashboard_actions_1 = require("../actions/swg-dashboard.actions");
const notification_actions_1 = require("../../../components/notification/actions/notification.actions");
class SwgDashboardEffects {
    constructor(actions$, translationService, swgService) {
        this.actions$ = actions$;
        this.translationService = translationService;
        this.swgService = swgService;
        this.getActivityChartData = this.actions$.pipe(effects_1.ofType(swg_dashboard_actions_1.SwgDashboardActionsEnum.LOAD_ACTIVITYCHART), operators_1.switchMap(() => {
            return this.swgService.getActivities().pipe(operators_1.map((response) => {
                return new swg_dashboard_actions_1.SwgLoadActivityChartSuccessAction(response);
            }), operators_1.catchError(error => rxjs_1.merge(rxjs_1.of(this.getNotificationAction(error)), rxjs_1.of(new swg_dashboard_actions_1.SwgLoadActivityChartFailureAction()))));
        }));
        this.getTopDomains = this.actions$.pipe(effects_1.ofType(swg_dashboard_actions_1.SwgDashboardActionsEnum.LOAD_TOPDOMAINSCHART), operators_1.switchMap(() => {
            return this.swgService.getTopDomains('All').pipe(operators_1.map((response) => {
                const sortList = (item1, item2) => item1.value > item2.value ? 1 : -1;
                response.list.sort(sortList);
                return new swg_dashboard_actions_1.SwgLoadTopDomainsChartSuccessAction(response);
            }), operators_1.catchError(error => rxjs_1.merge(rxjs_1.of(this.getNotificationAction(error)), rxjs_1.of(new swg_dashboard_actions_1.SwgLoadTopDomainsChartFailureAction()))));
        }));
        this.getTopCategories = this.actions$.pipe(effects_1.ofType(swg_dashboard_actions_1.SwgDashboardActionsEnum.LOAD_TOPCATEGORIESCHART), operators_1.switchMap(() => {
            return this.swgService.getTopCategories('All').pipe(operators_1.map((response) => {
                const sortList = (item1, item2) => item1.value > item2.value ? 1 : -1;
                response.list.sort(sortList);
                return new swg_dashboard_actions_1.SwgLoadTopCategoriesChartSuccessAction(response);
            }), operators_1.catchError(error => rxjs_1.merge(rxjs_1.of(this.getNotificationAction(error)), rxjs_1.of(new swg_dashboard_actions_1.SwgLoadTopCategoriesChartFailureAction()))));
        }));
        this.getTopBlockedDomains = this.actions$.pipe(effects_1.ofType(swg_dashboard_actions_1.SwgDashboardActionsEnum.LOAD_TOPBLOCKEDDOMAINSCHART), operators_1.switchMap(() => {
            return this.swgService.getTopDomains('Block').pipe(operators_1.map((response) => {
                const sortList = (item1, item2) => item1.value > item2.value ? 1 : -1;
                response.list.sort(sortList);
                return new swg_dashboard_actions_1.SwgLoadTopBlockedDomainsChartSuccessAction(response);
            }), operators_1.catchError(error => rxjs_1.merge(rxjs_1.of(this.getNotificationAction(error)), rxjs_1.of(new swg_dashboard_actions_1.SwgLoadTopBlockedDomainsChartFailureAction()))));
        }));
        this.getTopBlockedCategories = this.actions$.pipe(effects_1.ofType(swg_dashboard_actions_1.SwgDashboardActionsEnum.LOAD_TOPBLOCKEDCATEGORIESCHART), operators_1.switchMap(() => {
            return this.swgService.getTopCategories('Block').pipe(operators_1.map((response) => {
                const sortList = (item1, item2) => item1.value > item2.value ? 1 : -1;
                response.list.sort(sortList);
                return new swg_dashboard_actions_1.SwgLoadTopBlockedCategoriesChartSuccessAction(response);
            }), operators_1.catchError(error => rxjs_1.merge(rxjs_1.of(this.getNotificationAction(error)), rxjs_1.of(new swg_dashboard_actions_1.SwgLoadTopBlockedCategoriesChartFailureAction()))));
        }));
    }
    getNotificationAction(error) {
        const msg = error.fail
            ? error.fail[0].errors[0].message
            : this.translationService.instant('$I18N_SWG_CREATE_POLICY_SERVER_ERROR');
        return new notification_actions_1.NotificationShowAction({
            type: 'error',
            config: {
                msg
            }
        });
    }
}
__decorate([
    effects_1.Effect()
], SwgDashboardEffects.prototype, "getActivityChartData", void 0);
__decorate([
    effects_1.Effect()
], SwgDashboardEffects.prototype, "getTopDomains", void 0);
__decorate([
    effects_1.Effect()
], SwgDashboardEffects.prototype, "getTopCategories", void 0);
__decorate([
    effects_1.Effect()
], SwgDashboardEffects.prototype, "getTopBlockedDomains", void 0);
__decorate([
    effects_1.Effect()
], SwgDashboardEffects.prototype, "getTopBlockedCategories", void 0);
exports.SwgDashboardEffects = SwgDashboardEffects;
