"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoadOneDriveConnectorsActionFail = exports.LoadOneDriveConnectorsActionSuccess = exports.LoadOneDriveConnectorsAction = exports.OpenCreateConnectorWizardAction = exports.ConnectorActions = void 0;
var ConnectorActions;
(function (ConnectorActions) {
    ConnectorActions["OPEN_CREATE_CONNECTOR_WIZARD"] = "OPEN_CREATE_CONNECTOR_WIZARD";
    ConnectorActions["LOAD_ONEDRIVE_CONNECTORS"] = "LOAD_ONEDRIVE_CONNECTORS";
    ConnectorActions["LOAD_ONEDRIVE_CONNECTORS_SUCCESS"] = "LOAD_ONEDRIVE_CONNECTORS_SUCCESS";
    ConnectorActions["LOAD_ONEDRIVE_CONNECTORS_FAIL"] = "CREATE_CLOUD_CONNECTOR_FAIL";
})(ConnectorActions = exports.ConnectorActions || (exports.ConnectorActions = {}));
class OpenCreateConnectorWizardAction {
    constructor() {
        this.type = ConnectorActions.OPEN_CREATE_CONNECTOR_WIZARD;
    }
}
exports.OpenCreateConnectorWizardAction = OpenCreateConnectorWizardAction;
class LoadOneDriveConnectorsAction {
    constructor() {
        this.type = ConnectorActions.LOAD_ONEDRIVE_CONNECTORS;
    }
}
exports.LoadOneDriveConnectorsAction = LoadOneDriveConnectorsAction;
class LoadOneDriveConnectorsActionSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = ConnectorActions.LOAD_ONEDRIVE_CONNECTORS_SUCCESS;
    }
}
exports.LoadOneDriveConnectorsActionSuccess = LoadOneDriveConnectorsActionSuccess;
class LoadOneDriveConnectorsActionFail {
    constructor(payload) {
        this.payload = payload;
        this.type = ConnectorActions.LOAD_ONEDRIVE_CONNECTORS_FAIL;
    }
}
exports.LoadOneDriveConnectorsActionFail = LoadOneDriveConnectorsActionFail;
