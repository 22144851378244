"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationChannelsEffects = void 0;
const effects_1 = require("@ngrx/effects");
const store_1 = require("@ngrx/store");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const clp_channels_actions_1 = require("../actions/clp-channels.actions");
const reducers_1 = require("../../../swg/policies/reducers");
class NotificationChannelsEffects {
    constructor(actions$, store, policyStore$, swgClpService) {
        this.actions$ = actions$;
        this.store = store;
        this.policyStore$ = policyStore$;
        this.swgClpService = swgClpService;
        this.loadNotificationTeams$ = this.actions$.pipe(effects_1.ofType(clp_channels_actions_1.LOAD_NOTIFICATION_TEAMS), operators_1.withLatestFrom(this.policyStore$.pipe(store_1.select(reducers_1.getSwgPolicy))), operators_1.switchMap(([action, swgPolicyState]) => {
            // @ts-ignore
            const { searchText } = action.payload;
            const connectorId = swgPolicyState.clpPolicySettings.connectorId;
            return this.swgClpService.getTeams(connectorId, searchText).pipe(operators_1.map(response => new clp_channels_actions_1.LoadNotificationTeamsSuccess(response)), operators_1.catchError(() => rxjs_1.of(new clp_channels_actions_1.LoadNotificationTeamsFailure())));
        }));
        this.loadNotificationChannels$ = this.actions$.pipe(effects_1.ofType(clp_channels_actions_1.LOAD_NOTIFICATION_CHANNELS), operators_1.withLatestFrom(this.policyStore$.pipe(store_1.select(reducers_1.getSwgPolicy))), operators_1.switchMap(([action, swgPolicyState]) => {
            const { payload: { teamId, searchText } } = action;
            const connectorId = swgPolicyState.clpPolicySettings.connectorId;
            return this.swgClpService.getChannels(connectorId, teamId, searchText).pipe(operators_1.map(response => new clp_channels_actions_1.LoadNotificationChannelsSuccess(response)), operators_1.catchError(() => rxjs_1.of(new clp_channels_actions_1.LoadNotificationChannelsFailure())));
        }));
    }
}
__decorate([
    effects_1.Effect()
], NotificationChannelsEffects.prototype, "loadNotificationTeams$", void 0);
__decorate([
    effects_1.Effect()
], NotificationChannelsEffects.prototype, "loadNotificationChannels$", void 0);
exports.NotificationChannelsEffects = NotificationChannelsEffects;
