<mc-api-integration-okta-evidence-based-control-wizard
  [hasCreatedIntegration]="hasCreatedIntegration$ | async"
  [isVerificationInProgress]="isVerificationInProgress$ | async"
  [isVerified]="isVerified$ | async"
  [userGroups]="userGroups$ | async"
  [searchOktaGroupResults]="searchOktaGroupResults$ | async"
  [notificationGroups]="notificationGroups$ | async"
  [notificationAddresses]="notificationAddresses$ | async"
  [integration]="integration$ | async"
  [isLoadingOktaGroups]="isLoadingOktaGroups$ | async"
  [isOktaEvidenceBasedControlIntegrationCreatingOrUpdating]="isOktaEvidenceBasedControlIntegrationCreatingOrUpdating$ | async"
  [isAuthenticationFailed]="isAuthenticationFailed$ | async"
  (onCreateIntegration)="createIntegration()"
  (onUpdateIntegration)="updateIntegration()"
  (updateUserGroups)="updateUserGroups($event)"
  (onSearchOktaGroups)="searchOktaGroups($event)"
  (onVerifyAndAddEmail)="verifyAndAddEmail($event)"
  (onDeleteEmail)="deleteEmail($event)"
  (onAddGroup)="addGroup($event)"
  (onDeleteGroup)="deleteGroup($event)"
  (onUpdateOktaEventGroups)="updateOktaEventGroups($event)"
  (onCancel)="cancel()"
  (onVerifyOktaApiKey)="verifyOktaApiKey($event)"
  (onUpdateEnabledStatus)="updateEnabledStatus($event)"
>
</mc-api-integration-okta-evidence-based-control-wizard>
