"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
(function () {
    'use strict';
    angular.module('services.services.backup.mailbox-details.service', [])
        .service('mailboxDetailsSerivce', ['archiveFolderService', '$q', '$translate', function (archiveFolderService, $q, $translate) {
            const FOLDER_FETCH_DEPTH = 1;
            const lables = {};
            lables[archiveFolderService.FOLDER_CONTENT_TYPE.MESSAGE] = $translate.instant('$I18N_BACKUP_RESTORE_MAILBOXES_MESSAGE_LABEL');
            lables[archiveFolderService.FOLDER_CONTENT_TYPE.CALENDAR] = $translate.instant('$I18N_BACKUP_RESTORE_MAILBOXES_CALENDARS_LABEL');
            lables[archiveFolderService.FOLDER_CONTENT_TYPE.CONTACT] = $translate.instant('$I18N_BACKUP_RESTORE_MAILBOXES_CONTACTS_LABEL');
            lables[archiveFolderService.FOLDER_CONTENT_TYPE.TASK] = $translate.instant('$I18N_BACKUP_RESTORE_MAILBOXES_TASKS_LABEL');
            lables[archiveFolderService.FOLDER_CONTENT_TYPE.NOTES] = $translate.instant('$I18N_BACKUP_RESTORE_MAILBOXES_NOTES_LABEL');
            return {
                getMailFoldersAndDoTransformation,
                findFoldersAndDoTransformation,
                transformFolderToNode
            };
            function transformFolderToNode(folder, depth) {
                depth--;
                return {
                    id: folder.id,
                    fullPath: folder.fullPath,
                    tooltip: folder.fullPath === '\\' ? undefined : folder.fullPath.replace(/\+/g, ' '),
                    title: folder.name,
                    contentType: folder.contentType,
                    collapse: true,
                    name: folder.name + (folder.subFolders ? ' (' + folder.subFolders.length + ')' : ''),
                    subFolderCount: folder.subfolderCount ? folder.subfolderCount : 0,
                    nodes: (depth > 0 && folder.subfolderCount > 0) ? (folder.subFolders.map(function (sub) {
                        return transformFolderToNode(sub, depth);
                    })) : []
                };
            }
            function findFoldersAndDoTransformation(mailboxId, contentType, viewType, query) {
                //Fetch and transform
                const defer = $q.defer();
                let foldersTree;
                const payLoad = { mailbox: mailboxId, viewType, contentType };
                if (query) {
                    payLoad.query = query;
                }
                archiveFolderService.findMailboxFolders(payLoad).then(function (data) {
                    if (!data.err) {
                        if (data.first.folders.length === 0) {
                            foldersTree = [];
                        }
                        else {
                            const folders = data.first.folders.map(function (folder) {
                                folder.subFolderCount = 0;
                                folder.tooltip = folder.fullPath.replace(/\+/g, ' ');
                                return folder;
                            });
                            const root = [{
                                    name: lables[contentType],
                                    collapse: false,
                                    contentType,
                                    nodes: folders,
                                    fullPath: '\\',
                                    subFolderCount: folders.length
                                }];
                            foldersTree = root;
                        }
                    }
                    else {
                        foldersTree = [];
                    }
                    return defer.resolve(foldersTree);
                }, function () {
                    foldersTree = [];
                    archiveFolderService.getError().showErrorNotification();
                    return defer.resolve(foldersTree);
                });
                return defer.promise;
            }
            function getMailFoldersAndDoTransformation(mailboxId, contentType, viewType, depth) {
                //Fetch and transform
                const defer = $q.defer();
                let foldersTree;
                archiveFolderService.getMailboxFolders({
                    mailbox: mailboxId,
                    depth,
                    contentType,
                    viewType
                }).then(function (data) {
                    if (!data.err) {
                        if (data.first.folders.length === 0) {
                            foldersTree = [];
                        }
                        else {
                            foldersTree = transformFolderToNode(data.first.folders[0] || {}, FOLDER_FETCH_DEPTH + 1); //return parent and first level children
                            foldersTree.collapse = false;
                            foldersTree.name = $translate.instant('$I18N_BACKUP_RESTORE_MAILBOXES_MESSAGE_LABEL');
                            foldersTree = [foldersTree];
                        }
                    }
                    else {
                        foldersTree = [];
                    }
                    return defer.resolve(foldersTree);
                }, function () {
                    foldersTree = [];
                    archiveFolderService.getError().showErrorNotification();
                    return defer.resolve(foldersTree);
                });
                return defer.promise;
            }
        }]);
})();
