"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiemChannelDeleteConfirmationModalClose = exports.SiemChannelDeleteConfirmationModalOpen = exports.SiemChannelDeleteFailure = exports.SiemChannelDeleteSuccess = exports.SiemChannelListDeleteChannel = exports.SiemChannelListActionsEnums = void 0;
var SiemChannelListActionsEnums;
(function (SiemChannelListActionsEnums) {
    SiemChannelListActionsEnums["SIEM_CHANNEL_DELETE"] = "[SIEM] SIEM Channel List delete channel";
    SiemChannelListActionsEnums["SIEM_CHANNEL_DELETE_SUCCESS"] = "[SIEM] SIEM Channel delete successful";
    SiemChannelListActionsEnums["SIEM_CHANNEL_DELETE_FAILURE"] = "[SIEM] SIEM Channel delete failure";
    SiemChannelListActionsEnums["SIEM_CHANNEL_DELETE_CONFIRMATION_MODAL_OPEN"] = "[SIEM] SIEM Channel delete confirmation modal open";
    SiemChannelListActionsEnums["SIEM_CHANNEL_DELETE_CONFIRMATION_MODAL_CLOSE"] = "[SIEM] SIEM Channel delete confirmation modal close";
})(SiemChannelListActionsEnums = exports.SiemChannelListActionsEnums || (exports.SiemChannelListActionsEnums = {}));
class SiemChannelListDeleteChannel {
    constructor(id) {
        this.id = id;
        this.type = SiemChannelListActionsEnums.SIEM_CHANNEL_DELETE;
    }
}
exports.SiemChannelListDeleteChannel = SiemChannelListDeleteChannel;
class SiemChannelDeleteSuccess {
    constructor() {
        this.type = SiemChannelListActionsEnums.SIEM_CHANNEL_DELETE_SUCCESS;
    }
}
exports.SiemChannelDeleteSuccess = SiemChannelDeleteSuccess;
class SiemChannelDeleteFailure {
    constructor() {
        this.type = SiemChannelListActionsEnums.SIEM_CHANNEL_DELETE_FAILURE;
    }
}
exports.SiemChannelDeleteFailure = SiemChannelDeleteFailure;
class SiemChannelDeleteConfirmationModalOpen {
    constructor(payload) {
        this.payload = payload;
        this.type = SiemChannelListActionsEnums.SIEM_CHANNEL_DELETE_CONFIRMATION_MODAL_OPEN;
    }
}
exports.SiemChannelDeleteConfirmationModalOpen = SiemChannelDeleteConfirmationModalOpen;
class SiemChannelDeleteConfirmationModalClose {
    constructor() {
        this.type = SiemChannelListActionsEnums.SIEM_CHANNEL_DELETE_CONFIRMATION_MODAL_CLOSE;
    }
}
exports.SiemChannelDeleteConfirmationModalClose = SiemChannelDeleteConfirmationModalClose;
