"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThreatDetailsContainerComponent = void 0;
const threatDetailsMocks = require("app-new/threat-intel/pages/threat-details/mocks/threat-details.mock");
class ThreatDetailsContainerComponent {
    constructor(stateService) {
        this.stateService = stateService;
        this.threatDetailsSummary = threatDetailsMocks.summary;
        this.threatDetailsFileAnalysis = threatDetailsMocks.analysis;
    }
}
exports.ThreatDetailsContainerComponent = ThreatDetailsContainerComponent;
