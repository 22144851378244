"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgPolicyOptionsEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const advancedFileTypes = require("../../actions/advanced-file-types.actions");
const categories = require("../../actions/categories.actions");
const connectors = require("../../actions/connectors.actions");
const applications = require("../../actions/applications.actions");
const policy_actions_1 = require("../../actions/policy.actions");
const reducers = require("../../reducers");
class SwgPolicyOptionsEffects {
    constructor(actions$, store, swgPolicyService, connectorsApiService) {
        this.actions$ = actions$;
        this.store = store;
        this.swgPolicyService = swgPolicyService;
        this.connectorsApiService = connectorsApiService;
        this.loadApplications = this.actions$.pipe(effects_1.ofType(applications.SWG_POLICY_LOAD_APPLICATIONS), operators_1.withLatestFrom(this.store.select(reducers.getApplicationsLoaded), this.store.select(reducers.getApplications)), operators_1.switchMap(([, loaded, apps]) => {
            if (loaded) {
                return rxjs_1.of(new applications.SwgPolicyLoadApplicationsSuccess(apps));
            }
            return this.swgPolicyService.getApplications().pipe(operators_1.map((response) => new applications.SwgPolicyLoadApplicationsSuccess(response)), operators_1.catchError(() => rxjs_1.of(new applications.SwgPolicyLoadApplicationsFailure())));
        }));
        this.loadCategories = this.actions$.pipe(effects_1.ofType(categories.SWG_POLICY_LOAD_CATEGORY_SECTIONS), operators_1.switchMap(() => {
            return this.swgPolicyService.getCategorySections().pipe(operators_1.map(res => this.swgPolicyService.groupSwgWebCategorySections(res)), operators_1.map((response) => new categories.SwgPolicyLoadCategorySectionsSuccess(response)), operators_1.catchError(() => rxjs_1.of(new categories.SwgPolicyLoadCategorySectionsFailure())));
        }));
        this.loadConnectors = this.actions$.pipe(effects_1.ofType(connectors.SWG_POLICY_LOAD_CONNECTORS_SECTIONS), operators_1.switchMap(() => {
            return this.swgPolicyService.findConnectors().pipe(operators_1.map((response) => new connectors.SwgPolicyLoadConnectorsSectionsSuccess(response)), operators_1.catchError(() => rxjs_1.of(new connectors.SwgPolicyLoadConnectorsSectionsFailure())));
        }));
        this.loadProviders$ = this.actions$.pipe(effects_1.ofType(connectors.SWG_POLICY_LOAD_PROVIDERS_SECTIONS), operators_1.switchMap(() => this.connectorsApiService.getProducts()), operators_1.switchMap((data) => {
            const products = data.first.products;
            let safeCloudProduct;
            if (products) {
                safeCloudProduct = products.find(product => product.description === 'SAFE_CLOUD');
            }
            return this.connectorsApiService.getProviders(safeCloudProduct.id).pipe(operators_1.map(response => {
                return new connectors.SwgPolicyLoadProvidersSectionsSuccess(response.first.providers);
            }), operators_1.catchError(() => rxjs_1.of(new connectors.SwgPolicyLoadProvidersSectionsFailure())));
        }));
        this.appendAllowedCategories = this.actions$.pipe(effects_1.ofType(categories.SWG_POLICY_ADD_ALLOWED_CATEGORIES), operators_1.map((action) => action.payload), operators_1.switchMap((payload) => {
            return this.swgPolicyService.getCategorySections().pipe(operators_1.map(res => new policy_actions_1.SwgLoadPolicyToEditSuccess(this.swgPolicyService.addAllowedCategories(res, payload))), operators_1.catchError(() => rxjs_1.of(new policy_actions_1.SwgLoadPolicyToEditFailure())));
        }));
        this.loadAdvancedSecurityFileTypes = this.actions$.pipe(effects_1.ofType(advancedFileTypes.SWG_POLICY_LOAD_ADVANCED_FILE_TYPES), operators_1.map((action) => action.payload), operators_1.switchMap(() => {
            return this.swgPolicyService.getAdvancedFileTypes().pipe(operators_1.map((res) => this.swgPolicyService.addActionToSwgAdvancedFileType(res)), operators_1.map((response) => new advancedFileTypes.SwgPolicyLoadAdvancedFileTypesSuccess(response)), operators_1.catchError(() => rxjs_1.of(new advancedFileTypes.SwgPolicyLoadAdvancedFileTypesFailure())));
        }));
        this.loadProtectedApplications = this.actions$.pipe(effects_1.ofType(applications.SWG_POLICY_LOAD_PROTECTED_APPLICATIONS), operators_1.switchMap(() => {
            return this.swgPolicyService.getProtectedApplications().pipe(operators_1.map((response) => new applications.SwgPolicyLoadProtectedApplicationsSuccess(response)), operators_1.catchError(() => rxjs_1.of(new applications.SwgPolicyLoadProtectedApplicationsFailure())));
        }));
    }
}
__decorate([
    effects_1.Effect()
], SwgPolicyOptionsEffects.prototype, "loadApplications", void 0);
__decorate([
    effects_1.Effect()
], SwgPolicyOptionsEffects.prototype, "loadCategories", void 0);
__decorate([
    effects_1.Effect()
], SwgPolicyOptionsEffects.prototype, "loadConnectors", void 0);
__decorate([
    effects_1.Effect()
], SwgPolicyOptionsEffects.prototype, "loadProviders$", void 0);
__decorate([
    effects_1.Effect()
], SwgPolicyOptionsEffects.prototype, "appendAllowedCategories", void 0);
__decorate([
    effects_1.Effect()
], SwgPolicyOptionsEffects.prototype, "loadAdvancedSecurityFileTypes", void 0);
__decorate([
    effects_1.Effect()
], SwgPolicyOptionsEffects.prototype, "loadProtectedApplications", void 0);
exports.SwgPolicyOptionsEffects = SwgPolicyOptionsEffects;
