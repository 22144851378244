"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgThreatEventsTableComponent = void 0;
const core_1 = require("@angular/core");
const swg_threat_events_table_static_value_1 = require("./swg-threat-events-table.static-value");
const swg_application_dashboard_static_value_1 = require("../../../list/component/dashboard/swg-application-dashboard.static-value");
class SwgThreatEventsTableComponent {
    constructor() {
        this.isLoading = false;
        this.isGraphDataLoading = false;
        this.onRowClicked = new core_1.EventEmitter();
        this.dateRangeChange = new core_1.EventEmitter();
        this.onPaginationChange = new core_1.EventEmitter();
        this.columns = [...swg_threat_events_table_static_value_1.columns];
        this.sortableColumns = ['filename', 'apiProvider', 'fileTypes', 'fileOwner', 'timestamp'];
        this.dateRangeConfig = swg_application_dashboard_static_value_1.dateRangePickerConfig;
        this.selectedDateRange = this.dateRangeConfig.dateRanges[0];
        this.dateFormat = 'dd MMM yyyy - HH:mm';
    }
    onDateRangeFilterChange(value) {
        if (this.selectedDateRange.id === value.id) {
            return;
        }
        this.selectedDateRange = value;
        if (!value.range) {
            return;
        }
        this.dateRangeChange.emit(value);
    }
}
exports.SwgThreatEventsTableComponent = SwgThreatEventsTableComponent;
