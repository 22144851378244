"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SidePanelEffects = void 0;
const effects_1 = require("@ngrx/effects");
const operators_1 = require("rxjs/operators");
const actions = require("../../actions");
const core = require("app-new/core/reducers");
const ui = require("app-new/core/actions/ui.actions");
const directory_connection_panel_1 = require("../../components/directory-connection-panel");
class SidePanelEffects {
    constructor(actions$, store, asideService, directorySyncService) {
        this.actions$ = actions$;
        this.store = store;
        this.asideService = asideService;
        this.directorySyncService = directorySyncService;
        this.openCloseSidePanel$ = this.actions$.pipe(effects_1.ofType(actions.DIRECTORY_CONNECTION), operators_1.filter(() => !this.asideInstance), operators_1.withLatestFrom(this.store.select(core.getAdminPreferences)), operators_1.tap(([, adminPrefs]) => {
            this.asideInstance = this.asideService.open(directory_connection_panel_1.DirectoryConnectionPanelComponent, Object.assign(Object.assign({ position: 'right', size: 'lg' }, (!!adminPrefs && !!adminPrefs.uiAsidePanelWidth
                ? { width: adminPrefs.uiAsidePanelWidth }
                : {})), { minWidth: 620, hasBackdrop: true, resizable: true, disableClose: true }));
        }), operators_1.tap(() => {
            this.resizeSubscription = this.asideInstance.onResized().subscribe((width) => {
                this.store.dispatch(new ui.RightAsidePanelResizedAction(width));
            });
        }), operators_1.tap(() => {
            this.asideInstance.beforeClose().subscribe(() => {
                this.resizeSubscription.unsubscribe();
                this.asideInstance = undefined;
                this.store.dispatch(new actions.CloseDirectoryConnectionAction());
                this.directorySyncService.cancelTestConnection();
            });
        }));
        this.closeDirectoryConnection$ = this.actions$.pipe(effects_1.ofType(actions.CLOSE_DIRECTORY_CONNECTION), operators_1.switchMap(() => {
            return [new actions.ClearDirectoryConnectionAction()];
        }));
    }
}
__decorate([
    effects_1.Effect({ dispatch: false })
], SidePanelEffects.prototype, "openCloseSidePanel$", void 0);
__decorate([
    effects_1.Effect()
], SidePanelEffects.prototype, "closeDirectoryConnection$", void 0);
exports.SidePanelEffects = SidePanelEffects;
