"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorMessage = exports.BackPageMessage = exports.NewPageMessage = exports.ERROR = exports.BACK_PAGE = exports.NEW_PAGE = void 0;
exports.NEW_PAGE = 'adcon3.5-new-page';
exports.BACK_PAGE = 'adcon3.5-back-page';
exports.ERROR = 'adcon-action-error';
class NewPageMessage {
    constructor() {
        this.name = exports.NEW_PAGE;
    }
}
exports.NewPageMessage = NewPageMessage;
class BackPageMessage {
    constructor(data) {
        this.data = data;
        this.name = exports.BACK_PAGE;
    }
}
exports.BackPageMessage = BackPageMessage;
class ErrorMessage {
    constructor() {
        this.name = exports.ERROR;
    }
}
exports.ErrorMessage = ErrorMessage;
