"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgSidebarEffects = void 0;
const effects_1 = require("@ngrx/effects");
const sidebar_actions_1 = require("app-new/swg/policies/actions/sidebar.actions");
const swg_policy_duplicate_confirm_component_1 = require("app-new/swg/policies/components/sidebar/duplicate-confirm/swg-policy-duplicate-confirm.component");
const swg_policy_edit_sidebar_container_component_1 = require("app-new/swg/policies/containers/sidebar/swg-policy-edit-sidebar.container.component");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const sidebar = require("../../actions/sidebar.actions");
const swg_policy_delete_confirmation_component_1 = require("../../components/sidebar/delete/swg-policy-delete-confirmation.component");
const swg_policy_disable_confirmation_component_1 = require("../../components/sidebar/disable/swg-policy-disable-confirmation.component");
const swg_csv_uploader_container_component_1 = require("../../containers/csv-uploader/swg-csv-uploader-container.component");
const reducers = require("app-new/swg/policies/reducers");
const models_1 = require("../../models");
class SwgSidebarEffects {
    constructor(actions$, store, stateService, modalService, asideService) {
        this.actions$ = actions$;
        this.store = store;
        this.stateService = stateService;
        this.modalService = modalService;
        this.asideService = asideService;
        this.openSidePanel$ = this.actions$.pipe(effects_1.ofType(sidebar_actions_1.SwgSidebarActionsEnum.SWG_POLICY_EDIT_SIDEBAR_OPEN), operators_1.map((action) => action.payload), operators_1.tap(() => {
            // @ts-ignore
            this.sidepanelInstance = this.asideService.open(swg_policy_edit_sidebar_container_component_1.SwgPolicyEditSideBarContainerComponent, {
                position: 'right',
                size: 'md',
                hasBackdrop: true
            });
        }));
        this.closeSidePanel$ = this.actions$.pipe(effects_1.ofType(sidebar_actions_1.SwgSidebarActionsEnum.SWG_POLICY_EDIT_SIDEBAR_CLOSE), operators_1.filter(() => !!this.sidepanelInstance), operators_1.tap(() => {
            this.sidepanelInstance.close();
            this.sidepanelInstance = undefined;
        }));
        this.openDomainsCsvUploader$ = this.actions$.pipe(effects_1.ofType(sidebar_actions_1.SwgSidebarActionsEnum.SWG_POLICY_DOMAINS_CSV_UPLOADER_OPEN), operators_1.map((action) => action.payload), operators_1.tap(() => {
            this.domainUploaderInstance = this.modalService.open(swg_csv_uploader_container_component_1.SwgCsvUploaderContainerComponent, {
                size: 'md',
                hasBackdrop: true
            });
        }));
        this.closeDomainsCsvUploader$ = this.actions$.pipe(effects_1.ofType(sidebar_actions_1.SwgSidebarActionsEnum.SWG_POLICY_DOMAINS_CSV_UPLOADER_CLOSE), operators_1.filter(() => !!this.domainUploaderInstance), operators_1.tap(() => {
            this.domainUploaderInstance.close();
            this.domainUploaderInstance = undefined;
        }));
        this.openSwgPolicyDeleteAlert = this.actions$.pipe(effects_1.ofType(sidebar_actions_1.SwgSidebarActionsEnum.SWG_POLICY_DELETE_CONFIRM_MODEL_OPEN), operators_1.map((action) => action.payload), operators_1.switchMap(() => {
            const aside = this.modalService.open(swg_policy_delete_confirmation_component_1.SwgPolicyDeleteConfirmationComponent, {
                size: 'md',
                hasBackdrop: true
            });
            this.deleteAlertModalInstance = aside;
            return rxjs_1.of(new sidebar.SwgDetailEditSidebarClose());
        }));
        this.closeSwgPolicyDeleteAlert = this.actions$.pipe(effects_1.ofType(sidebar_actions_1.SwgSidebarActionsEnum.SWG_POLICY_DELETE_CONFIRM_MODEL_CLOSE), operators_1.filter(() => !!this.deleteAlertModalInstance), operators_1.tap(() => {
            this.deleteAlertModalInstance.close();
            this.deleteAlertModalInstance = undefined;
        }));
        this.openSwgPolicyDisableAlert = this.actions$.pipe(effects_1.ofType(sidebar_actions_1.SwgSidebarActionsEnum.SWG_POLICY_DISABLE_CONFIRM_MODEL_OPEN), operators_1.map((action) => action.payload), operators_1.switchMap(() => {
            const aside = this.modalService.open(swg_policy_disable_confirmation_component_1.SwgPolicyDisableConfirmationComponent, {
                size: 'md',
                hasBackdrop: true
            });
            this.disableAlertModalInstance = aside;
            return rxjs_1.of(new sidebar.SwgDetailEditSidebarClose());
        }));
        this.closeSwgPolicyDisableAlert = this.actions$.pipe(effects_1.ofType(sidebar_actions_1.SwgSidebarActionsEnum.SWG_POLICY_DISABLE_CONFIRM_MODEL_CLOSE), operators_1.filter(() => !!this.disableAlertModalInstance), operators_1.tap(() => {
            this.disableAlertModalInstance.close();
            this.disableAlertModalInstance = undefined;
        }));
        this.openSwgUnchangedPolicyWarnModal = this.actions$.pipe(effects_1.ofType(sidebar_actions_1.SwgSidebarActionsEnum.SWG_POLICY_UNCHANGED_DUPLICATE_WARN_OPEN), operators_1.map((action) => action.payload), operators_1.tap(() => {
            const aside = this.modalService.open(swg_policy_duplicate_confirm_component_1.SwgPolicyDuplicateConfirmComponent, {
                size: 'md',
                hasBackdrop: true
            });
            this.unchangedPolicyDuplicateWarnInstance = aside;
        }));
        this.closeSwgUnchangedPolicyWarnModal = this.actions$.pipe(effects_1.ofType(sidebar_actions_1.SwgSidebarActionsEnum.SWG_POLICY_UNCHANGED_DUPLICATE_WARN_CLOSE), operators_1.filter(() => !!this.unchangedPolicyDuplicateWarnInstance), operators_1.tap(() => {
            this.unchangedPolicyDuplicateWarnInstance.close();
            this.unchangedPolicyDuplicateWarnInstance = undefined;
        }));
        this.goToListPage = this.actions$.pipe(effects_1.ofType(sidebar_actions_1.SwgSidebarActionsEnum.SWG_POLICY_NAVIGATE_TO_LIST_PAGE), operators_1.tap(() => {
            this.stateService.$state.go('secure-web-gateway-policy-list');
        }));
        this.goToEditPage = this.actions$.pipe(effects_1.ofType(sidebar_actions_1.SwgSidebarActionsEnum.SWG_POLICY_NAVIGATE_TO_EDIT_PAGE), operators_1.withLatestFrom(this.store.select(reducers.getPolicyDesc)), operators_1.tap(([, policyDesc]) => {
            const editPage = policyDesc.policyType === models_1.SwgPolicyType.BROWSER_ISOLATION
                ? 'bi-policy-create'
                : 'secure-web-gateway-policy';
            this.stateService.$state.go(editPage);
        }));
    }
}
__decorate([
    effects_1.Effect({ dispatch: false })
], SwgSidebarEffects.prototype, "openSidePanel$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], SwgSidebarEffects.prototype, "closeSidePanel$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], SwgSidebarEffects.prototype, "openDomainsCsvUploader$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], SwgSidebarEffects.prototype, "closeDomainsCsvUploader$", void 0);
__decorate([
    effects_1.Effect()
], SwgSidebarEffects.prototype, "openSwgPolicyDeleteAlert", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], SwgSidebarEffects.prototype, "closeSwgPolicyDeleteAlert", void 0);
__decorate([
    effects_1.Effect()
], SwgSidebarEffects.prototype, "openSwgPolicyDisableAlert", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], SwgSidebarEffects.prototype, "closeSwgPolicyDisableAlert", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], SwgSidebarEffects.prototype, "openSwgUnchangedPolicyWarnModal", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], SwgSidebarEffects.prototype, "closeSwgUnchangedPolicyWarnModal", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], SwgSidebarEffects.prototype, "goToListPage", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], SwgSidebarEffects.prototype, "goToEditPage", void 0);
exports.SwgSidebarEffects = SwgSidebarEffects;
