<div class="mc-swg-policy-details-block">
  <mc-wizard-step-header
    [label]="'$I18N_SWG_CREATE_POLICY_POLICY_SUMMARY_HEADER'"
  ></mc-wizard-step-header>
  <mc-swg-policy-summary
    [policy]="policy"
    [applications]="applications"
    [hasSwgHybridEnabled]="hasSwgHybridEnabled"
    [hasBrowserIsolationEnabled]="hasBrowserIsolationEnabled"
    [webCategoryRulesSections]="webCategoryRulesSections"
    [hasAppPolicyRemoveAllowEnabled]="hasAppPolicyRemoveAllowEnabled"
    [protectedApplications]="protectedApplications"
  ></mc-swg-policy-summary>
</div>
