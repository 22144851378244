<div>
  <mc-collapsable-panel header="$I18N_MESSAGE_CENTER_MESSAGE_INFO_POLICIES_CONSIDERED.TITLE" [isExpanded]="true">
    <ng-template #policyInfoUnavailableMessage>
      <p class="mc-tracking-info-message">
        <span class="mc-tracking-info-message-icon">
          <i class="fas fa-info-circle"></i>
        </span>
        <span class="mc-tracking-info-message-text">
          {{'$I18N_MESSAGE_CENTER_MESSAGE_INFO_POLICIES_CONSIDERED.UNAVAILABLE_IN_RECEIVED_VIEW' | translate}}
        </span>
      </p>
    </ng-template>
    <mc-table [columns]="columns" [data]="policyInfo"
              *ngIf="policyInfo?.length; else policyInfoUnavailableMessage"
              [isLoading]="false">
      <mc-column key="policyType">
        <mc-header-cell *mcHeaderCellDef>{{'$I18N_MESSAGE_CENTER_MESSAGE_INFO_POLICIES_CONSIDERED.LABEL_POLICY_TYPE' | translate}}
        </mc-header-cell>
        <mc-body-cell *mcBodyCellDef="let row">
          <span>{{ row.policyType }}</span> <span *ngIf="row.inherited">{{ '$I18N_MESSAGE_CENTER_MESSAGE_INFO_POLICIES_CONSIDERED.LABEL_POLICY_INHERITED' | translate:row }}</span>
        </mc-body-cell>
      </mc-column>
      <mc-column key="policyName">
        <mc-header-cell *mcHeaderCellDef>{{'$I18N_MESSAGE_CENTER_MESSAGE_INFO_POLICIES_CONSIDERED.LABEL_POLICY_NAME' | translate}}
        </mc-header-cell>
      </mc-column>
    </mc-table>
  </mc-collapsable-panel>
</div>
