<mc-modal-header data-test="modal-header"
  headerTitle="{{'$I18N_SUPERVISION_LISTS.DELETE_CONFIRMATION.TITLE' | translate:{ entityName: entityName } }}">
</mc-modal-header>

<mc-modal-body>
  <p data-test="listed-queues"
    *ngIf="(escalationQueues && escalationQueues.length > 0) || (associatedReviewQueues && associatedReviewQueues.length > 0)">
    {{'$I18N_SUPERVISION_LISTS.DELETE_CONFIRMATION.LISTED_QUEUES' | translate:{ queueType: queueType, associatedQueueType: associatedQueueType } }}
  </p>

  <div *ngIf="escalationQueues && escalationQueues.length > 0" class="display-settings">
    <p data-test="escalation-queues" *ngFor="let escalationQueue of escalationQueues">
      <strong>{{ escalationQueue.name }}</strong>
    </p>
  </div>

  <div *ngIf="associatedReviewQueues && associatedReviewQueues.length > 0" class="display-settings">
    <p data-test="associated-review-queues" *ngFor="let associatedReviewQueue of associatedReviewQueues">
      <strong>{{ associatedReviewQueue.name }}</strong>
    </p>
  </div>
  
  <p class="delete-message" data-test="delete-message">
    {{'$I18N_SUPERVISION_LISTS.DELETE_CONFIRMATION.MESSAGE' | translate}}
  </p>
</mc-modal-body>

<mc-modal-footer>
  <button data-test="cancel-button" type="button" class="btn btn-secondary" (click)="close(false)">
    {{ '$I18N_COMMON_BTN_CANCEL' | translate }}
  </button>

  <button data-test="delete-button" type="button" class="btn btn-danger" (click)="close(true)">
    {{ '$I18N_COMMON_BTN_DELETE' | translate }}
  </button>
</mc-modal-footer>
