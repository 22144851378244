"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LegacyPageMessagesEffects = void 0;
const effects_1 = require("@ngrx/effects");
const operators_1 = require("rxjs/operators");
const legacy_adcon_1 = require("../../core/services/legacy-adcon");
const actions = require("../actions");
const messages = require("./legacy-pages.messages");
/**
 * transform messages sent from adcon3.5 when a page is loaded
 */
class LegacyPageMessagesEffects {
    constructor(legacyService, tabService) {
        this.legacyService = legacyService;
        this.tabService = tabService;
        this.messageToNewPageAction$ = this.legacyService.messages$.pipe(legacy_adcon_1.ofName(messages.NEW_PAGE), operators_1.map(() => new actions.NewPageAction(this.tabService.getSelectedTab().id)));
        this.messageToBackPageAction$ = this.legacyService.messages$.pipe(legacy_adcon_1.ofName(messages.BACK_PAGE), operators_1.map(message => new actions.BackPageAction(this.tabService.getSelectedTab().id, message.data)));
        this.messageToErrorAction$ = this.legacyService.messages$.pipe(legacy_adcon_1.ofName(messages.ERROR), operators_1.map(() => new actions.ErrorAction()));
        this.message500ToErrorAction$ = this.legacyService.messages$.pipe(operators_1.filter(message => message.status === 500), operators_1.map(() => new actions.ErrorAction()));
    }
}
__decorate([
    effects_1.Effect()
], LegacyPageMessagesEffects.prototype, "messageToNewPageAction$", void 0);
__decorate([
    effects_1.Effect()
], LegacyPageMessagesEffects.prototype, "messageToBackPageAction$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], LegacyPageMessagesEffects.prototype, "messageToErrorAction$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], LegacyPageMessagesEffects.prototype, "message500ToErrorAction$", void 0);
exports.LegacyPageMessagesEffects = LegacyPageMessagesEffects;
