"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ1 = exports.ɵ0 = exports.getConnectors = exports.isLoadingConnectors = exports.getImTaskDetails = exports.getImTaskLoading = exports.getImSync = exports.getStepData = exports.isStepValid = exports.getStepById = exports.isCurrentStepValid = exports.getCurrentStepState = exports.getCurrentStep = exports.getWizardById = exports.getWizardState = exports.getImSyncState = exports.reducers = void 0;
const wizardReducer = require("./wizard.reducer");
const imSyncReducer = require("./im-sync.reducer");
const store_1 = require("@ngrx/store");
exports.reducers = {
    wizard: wizardReducer.wizardReducer,
    imSync: imSyncReducer.imSyncReducer
};
exports.getImSyncState = store_1.createFeatureSelector('im-sync');
const ɵ0 = (state) => state.wizard;
exports.ɵ0 = ɵ0;
exports.getWizardState = store_1.createSelector(exports.getImSyncState, ɵ0);
const getWizardById = (wizardId) => store_1.createSelector(exports.getWizardState, wizardReducer.getWizardById(wizardId));
exports.getWizardById = getWizardById;
const getCurrentStep = (wizardId) => store_1.createSelector(exports.getWizardById(wizardId), wizardReducer.getCurrentStep);
exports.getCurrentStep = getCurrentStep;
const getCurrentStepState = (wizardId) => store_1.createSelector(exports.getWizardById(wizardId), wizardReducer.getCurrentStepState);
exports.getCurrentStepState = getCurrentStepState;
const isCurrentStepValid = (wizardId) => store_1.createSelector(exports.getCurrentStepState(wizardId), wizardReducer.isStepValid);
exports.isCurrentStepValid = isCurrentStepValid;
const getStepById = (wizardId, stepId) => store_1.createSelector(exports.getWizardById(wizardId), wizardReducer.getStepById(stepId));
exports.getStepById = getStepById;
const isStepValid = (wizardId, stepId) => store_1.createSelector(exports.getStepById(wizardId, stepId), wizardReducer.isStepValid);
exports.isStepValid = isStepValid;
const getStepData = (wizardId, stepId) => store_1.createSelector(exports.getStepById(wizardId, stepId), wizardReducer.getStepData);
exports.getStepData = getStepData;
const ɵ1 = (state) => state.imSync;
exports.ɵ1 = ɵ1;
exports.getImSync = store_1.createSelector(exports.getImSyncState, ɵ1);
exports.getImTaskLoading = store_1.createSelector(exports.getImSync, imSyncReducer.getImTaskLoading);
exports.getImTaskDetails = store_1.createSelector(exports.getImSync, imSyncReducer.getImTaskDetails);
exports.isLoadingConnectors = store_1.createSelector(exports.getImSync, imSyncReducer.isLoadingConnectors);
exports.getConnectors = store_1.createSelector(exports.getImSync, imSyncReducer.getConnectors);
