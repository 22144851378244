"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForwardPanelComponent = exports.contentPreservationPolicyTypes = void 0;
const forms_1 = require("@angular/forms");
const store_1 = require("@ngrx/store");
const operators_1 = require("rxjs/operators");
const actions = require("../../actions");
const reducers = require("../../reducers");
var contentPreservationPolicyTypes;
(function (contentPreservationPolicyTypes) {
    contentPreservationPolicyTypes["minutes"] = "Content Preservation (Minutes)";
    contentPreservationPolicyTypes["days"] = "Content Preservation (Days)";
})(contentPreservationPolicyTypes = exports.contentPreservationPolicyTypes || (exports.contentPreservationPolicyTypes = {}));
class ForwardPanelComponent {
    constructor(store, translation) {
        this.store = store;
        this.translation = translation;
        this.form = new forms_1.FormControl();
    }
    ngOnInit() {
        this.forwardingEmail$ = this.store.pipe(store_1.select(reducers.getForwardingEmail), operators_1.first());
        this.isSendingForward$ = this.store.pipe(store_1.select(reducers.isSendingForward));
        this.messageInfo$ = this.store.pipe(store_1.select(reducers.getMessageInfo));
        this.getMessageInfo();
    }
    send() {
        this.store.dispatch(new actions.ForwardMessageSendAction(this.form.value));
    }
    close() {
        this.store.dispatch(new actions.ForwardMessageCancelAction());
    }
    getMessageInfo() {
        this.messageInfo$.subscribe(response => {
            if (response.status ===
                this.translation.instant('$I18N_MESSAGE_CENTER_EMAIL_STATUS.ACCEPTED').toLowerCase()) {
                this.isContentPolicyExpired(response);
            }
            if (response.status ===
                this.translation.instant('$I18N_MESSAGE_CENTER_EMAIL_STATUS.ARCHIVED').toLowerCase()) {
                this.checkForPolicyType(response);
            }
        });
    }
    isContentPolicyExpired(response) {
        const date = new Date();
        const contentExpiration = response.recipientInfo.messageInfo.contentExpiration
            .toString()
            .replace(/-/g, ' ')
            .replace(/\(.*/, '');
        const contentExpirationDate = new Date(contentExpiration);
        const currentTimeAsGmt = new Date(date.getTime() +
            60 * 1000 * (date.getTimezoneOffset() - contentExpirationDate.getTimezoneOffset()));
        this.isContentExpired = currentTimeAsGmt > contentExpirationDate;
    }
    checkForPolicyType(response) {
        for (const email in response.deliveredMessage) {
            if (response.deliveredMessage.hasOwnProperty(email)) {
                const policyType = Object.values(response.deliveredMessage[email].policyInfo).map(type => type.policyType);
                this.isContentExpired = policyType.includes(contentPreservationPolicyTypes.minutes || contentPreservationPolicyTypes.days);
            }
        }
    }
}
exports.ForwardPanelComponent = ForwardPanelComponent;
