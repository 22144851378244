<div class="inline-error-notification">
  <div class="message" data-test="notification-title">
    <span class="fas fa-exclamation-circle text-danger"></span>
    {{ '$I18N_INLINE_ERROR_NOTIFICATION.MESSAGE' | translate:{ errorType: errorType } }}</div>
  <div>
    <p class="help-text">
      {{ '$I18N_INLINE_ERROR_NOTIFICATION.PLEASE' | translate }}
      <a data-test="reload-link" (click)="reload()">{{ '$I18N_INLINE_ERROR_NOTIFICATION.LINKS.TRY_AGAIN' | translate }}</a>.
      {{ '$I18N_INLINE_ERROR_NOTIFICATION.CONTACT' | translate }}
      <a data-test="support-link" [href]="supportLink$ | async" rel="noopener noreferrer"
        target="_blank">{{ '$I18N_INLINE_ERROR_NOTIFICATION.LINKS.SUPPORT' | translate }}</a>.
    </p>
  </div>
</div>
