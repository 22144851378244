"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MigrationGroupComponent = void 0;
const connector_interface_1 = require("../../models/connector.interface");
const rxjs_1 = require("rxjs");
class MigrationGroupComponent {
    constructor(translate, capabilitiesService) {
        this.translate = translate;
        this.capabilitiesService = capabilitiesService;
    }
    set group(group) {
        this.migrationGroup = Object.assign({}, group);
        this.migrationGroup.collapsed =
            this.migratedConnectors >= this.migrationGroup.connectors.length;
    }
    get migratedOfNotMigrated() {
        return this.translate.instant('$I18N_MIGRATION_GROUP.MIGRATED_OF_TOTAL', {
            migrated: this.migratedConnectors,
            total: this.migrationGroup.connectors.length
        });
    }
    get migratedConnectors() {
        return this.migrationGroup.connectors.filter((connector) => connector.status === connector_interface_1.MigrationStatus.CONNECTED).length;
    }
    canDisplayGroup() {
        return this.migrationGroup.productName === 'Directory Synchronization'
            ? this.capabilitiesService.hasCapability('DirSync.Azure.Standard.Hecate')
            : rxjs_1.of(true);
    }
}
exports.MigrationGroupComponent = MigrationGroupComponent;
