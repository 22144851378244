"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OktaEventSwitchComponent = void 0;
const core_1 = require("@angular/core");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
class OktaEventSwitchComponent {
    constructor(fb) {
        this.fb = fb;
        this.onSearchOktaGroups = new core_1.EventEmitter();
        this.destroy$ = new rxjs_1.Subject();
        this.onChange = () => { };
        this.onTouch = () => { };
    }
    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
    ngOnInit() {
        this.form = this.fb.group({
            enabled: [false],
            group: [{}]
        });
        this.form
            .get('enabled')
            .valueChanges.pipe(operators_1.takeUntil(this.destroy$))
            .subscribe(enabled => {
            this.writeValue(Object.assign(Object.assign({}, this.value), { enabled }));
        });
        this.form
            .get('group')
            .valueChanges.pipe(operators_1.takeUntil(this.destroy$))
            .subscribe(group => {
            this.writeValue(Object.assign(Object.assign({}, this.value), { group }));
            this.disableEventIfGroupIsEmpty(group);
        });
    }
    writeValue(obj) {
        this.value = obj;
        this.onChange(obj);
        if (!this._isFormValuePatched) {
            this.patchFormValues(obj);
        }
    }
    registerOnChange(fn) {
        this.onChange = fn;
    }
    registerOnTouched(fn) {
        this.onTouch = fn;
    }
    patchFormValues(obj) {
        ['enabled', 'group'].forEach(field => this.form.get(field).setValue(obj[field], { emitEvent: false }));
        this._isFormValuePatched = true;
    }
    disableEventIfGroupIsEmpty(group) {
        if (!(group === null || group === void 0 ? void 0 : group.id)) {
            this.form.get('enabled').setValue(false, { emitEvent: false });
        }
    }
}
exports.OktaEventSwitchComponent = OktaEventSwitchComponent;
