"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseStatusComponent = void 0;
class BaseStatusComponent {
    constructor() {
        this.translatePrefix = 'STATUS';
        this.isConsolidated = false;
        this.isStatusOnly = true;
        this.isSelected = false;
        this.UNSPECIFIED_COLOR = '';
        this.defaultStatusData = {
            iconClass: 'fas fa-bug',
            iconColorClass: 'icon-mc-gray-40'
        };
        this.errorStatusData = {
            iconClass: 'fas fa-bug',
            iconColorClass: 'icon-mc-red',
            toolTipTranslateKey: 'statusConfigError'
        };
    }
    getUpperCase(string) {
        return string.toUpperCase();
    }
    getStatusOrStateTranslateKey() {
        return this.getTranslateKeyOrUndefinedIfEmptyKey(this.getStatusDataForStatusOrState().translateKey);
    }
    getStatusTranslateKey() {
        return this.getTranslateKeyOrUndefinedIfEmptyKey(this.getStatusDataForStatus().translateKey);
    }
    getStateTranslateKey() {
        return this.getTranslateKeyOrUndefinedIfEmptyKey(this.getStatusDataForState().translateKey);
    }
    getIconClass() {
        return this.getStatusDataForStatusOrState().iconClass;
    }
    getIconColorClass() {
        return this.isSelected && this.getStatusDataForStatusOrState().isSelectedIconColorClass
            ? this.getStatusDataForStatusOrState().isSelectedIconColorClass
            : this.getStatusDataForStatusOrState().iconColorClass;
    }
    getToolTipKey() {
        return this.getTranslateKeyOrUndefinedIfEmptyKey(this.getStatusDataForStatusOrState().toolTipTranslateKey);
    }
    getTranslateKeyOrUndefinedIfEmptyKey(key) {
        return key ? this.translatePrefix + '_' + key : undefined;
    }
    getStatusDataForStatusOrState() {
        if (this.isValidStatusAndStateCombination()) {
            const statusConfig = this.isStateOverridingStatus()
                ? this.statusConfig[this.status][this.state] || this.statusConfig[this.status]
                : this.statusConfig[this.status];
            return Object.assign(Object.assign({}, this.defaultStatusData), statusConfig);
        }
        return this.errorStatusData;
    }
    isValidStatusAndStateCombination() {
        return this.isValidStatus() && this.isValidState();
    }
    isValidStatus() {
        return this.statusConfig[this.status];
    }
    isValidState() {
        if (this.state) {
            return this.isStateOverridingStatus() && this.statusConfig[this.status][this.state];
        }
        return true;
    }
    isStateOverridingStatus() {
        return this.isConsolidated || (!this.isConsolidated && !this.isStatusOnly);
    }
    getStatusDataForStatus() {
        const statusData = this.statusConfig[this.status];
        return Object.assign(Object.assign({}, this.defaultStatusData), statusData);
    }
    getStatusDataForState() {
        const stateData = (this.statusConfig[this.status] || this.defaultStatusData)[this.state];
        return Object.assign(Object.assign({}, this.defaultStatusData), stateData);
    }
}
exports.BaseStatusComponent = BaseStatusComponent;
