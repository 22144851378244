"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MepLogsEffects = void 0;
const effects_1 = require("@ngrx/effects");
const table_store_1 = require("@mimecast-ui/table-store");
const operators_1 = require("rxjs/operators");
const api_1 = require("../../../../../components/export/api");
const logsActions = require("../../actions/logs.actions");
const list_static_values_1 = require("../../components/list/list.static-values");
const notification_actions_1 = require("../../../../../components/notification/actions/notification.actions");
const models_1 = require("../../models/models");
const { MEP_LOGS_OPEN_EXPORT_SIDEBAR, MEP_LOG_ADMIN_LOG_ACTION } = logsActions.MepLogsActionsEnums;
class MepLogsEffects {
    constructor(actions$, api$, translate$, store$) {
        this.actions$ = actions$;
        this.api$ = api$;
        this.translate$ = translate$;
        this.store$ = store$;
        this.export$ = this.actions$.pipe(effects_1.ofType(MEP_LOGS_OPEN_EXPORT_SIDEBAR), operators_1.withLatestFrom(this.store$.select(table_store_1.tableReducer.getCurrentFilters(list_static_values_1.MEP_LOG_TABLE_ID))), operators_1.map(([action, filters]) => {
            return new api_1.OpenSidePanelExportApiAction('/api/mep/export-active-views', action.tableColumns, filters, '$18N_MEP_LOG_EXPORT_LIST');
        }));
        this.logAdminAction$ = this.actions$.pipe(effects_1.ofType(MEP_LOG_ADMIN_LOG_ACTION), operators_1.map((action) => action.payload), operators_1.switchMap((payload) => {
            const { id, action } = payload;
            return this.getAdminActionApi(action, id).pipe(operators_1.switchMap((response) => {
                let actions;
                if (response.hasErrors) {
                    actions = this.handleAdminActionFailures();
                }
                else {
                    actions = this.handleAdminActionSuccess(response.first, action);
                }
                return actions;
            }), operators_1.catchError(() => this.handleAdminActionFailures()));
        }));
    }
    handleAdminActionFailures() {
        return [
            new notification_actions_1.NotificationShowFailAction(this.translate$.instant('$I18N_MEP.NOTIFICATIONS.ADMIN_ACTIONS.ERROR')),
            new logsActions.MepLogAdminActionFailure()
        ];
    }
    handleAdminActionSuccess(success, action) {
        const { status } = success;
        const returnAction = [new logsActions.MepLogAdminActionSuccess(success)];
        if (status === models_1.MessageApiStatus.ALREADY_PROCESSED) {
            returnAction.push(new notification_actions_1.NotificationShowAction({
                type: 'info',
                config: {
                    msg: this.translate$.instant('$I18N_MEP.NOTIFICATIONS.ADMIN_ACTIONS.ALREADY_PROCESSED')
                }
            }));
        }
        else {
            returnAction.push(new notification_actions_1.NotificationShowSuccessAction(this.translate$.instant(`$I18N_MEP.NOTIFICATIONS.ADMIN_ACTIONS.SUCCESS.${action}`)));
        }
        return returnAction;
    }
    getAdminActionApi(action, id) {
        return action === models_1.MepAdminLogActions.DROP
            ? this.api$.dropMessage(id)
            : this.api$.releaseMessage(id);
    }
}
__decorate([
    effects_1.Effect()
], MepLogsEffects.prototype, "export$", void 0);
__decorate([
    effects_1.Effect()
], MepLogsEffects.prototype, "logAdminAction$", void 0);
exports.MepLogsEffects = MepLogsEffects;
