"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LegacyAdconService = exports.SWITCH_ACCOUNT_COOKIE_LIFESPAN = exports.ADCON_URL_COOKIE_NAME = exports.SWITCH_ACCOUNT_COOKIE_NAME = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const core_interfaces_1 = require("../../model/core.interfaces");
const messages = require("../../effects/legacy.messages");
const app_actions_1 = require("../../actions/app.actions");
exports.SWITCH_ACCOUNT_COOKIE_NAME = 'mc-switch-account';
exports.ADCON_URL_COOKIE_NAME = 'mc-adcon-url';
exports.SWITCH_ACCOUNT_COOKIE_LIFESPAN = 2 * 60 * 1000; // 2 minutes
class LegacyAdconService {
    constructor(store, windowService, cookieService, sessionService, coreService) {
        this.store = store;
        this.windowService = windowService;
        this.cookieService = cookieService;
        this.sessionService = sessionService;
        this.coreService = coreService;
        this.adconUrl = this.windowService.adconUrls && this.windowService.adconUrls[0];
        this.disableAdcon3 = this.windowService.disableAdcon3 || false;
        this.messages$ = this.windowService.messages$.pipe(operators_1.takeUntil(this.sessionService.logout$), operators_1.filter((event) => {
            if (event.data === messages.IFRAME_LOGOUT) {
                return this.store.dispatch(new app_actions_1.LogoutAction());
            }
            // Replace the regex special character '.' (used to match any character) with an actual '.'
            const originWithPath = new RegExp(event.origin.replace(/\./g, '\\.') + '/box/[1-9]');
            const isAdcon3Origin = originWithPath.test(this.windowService.document.location.origin + this.adconUrl);
            // We need to identify the request coming from Adcon 3.6
            try {
                return isAdcon3Origin && event.data && JSON.parse(event.data);
            }
            catch (e) {
                return false;
            }
        }), operators_1.map((event) => (event.data && JSON.parse(event.data)) || null), operators_1.map((message) => ((message && { name: message.message, data: message.data, status: message.status }) ||
            null)));
        this.rootAdconIFrameSubject = new rxjs_1.BehaviorSubject(null);
        this.rootAdconIFrame$ = this.rootAdconIFrameSubject.pipe(core_interfaces_1.isDefined());
    }
    set rootAdconIFrame(rootIFrame) {
        this.rootAdconIFrameSubject.next(rootIFrame);
    }
    deleteAccountSwitchCookie() {
        this.cookieService.delete(exports.SWITCH_ACCOUNT_COOKIE_NAME, '/');
    }
    deleteAdconUrlCookie() {
        this.cookieService.delete(exports.ADCON_URL_COOKIE_NAME, '/');
    }
    loadAdconUrlCookieValue() {
        const value = this.cookieService.get(exports.ADCON_URL_COOKIE_NAME);
        if (value) {
            this.adconUrl = this.windowService.adconUrls && this.windowService.adconUrls[0];
        }
    }
    getSwitchAccountCookieValue() {
        const value = this.cookieService.get(exports.SWITCH_ACCOUNT_COOKIE_NAME);
        if (value) {
            const session = JSON.parse(value);
            this.deleteAccountSwitchCookie();
            return session;
        }
    }
    createTempSwitchAccountCookie(session) {
        if (!!session) {
            this.writeTempSwitchAccountCookie(session);
        }
        else {
            this.coreService
                .getAccountSwitchSession()
                .pipe(operators_1.first(), core_interfaces_1.isDefined())
                .subscribe(switchSession => {
                this.writeTempSwitchAccountCookie(switchSession);
            });
        }
    }
    writeTempSwitchAccountCookie(session) {
        const date = new Date();
        date.setTime(date.getTime() + exports.SWITCH_ACCOUNT_COOKIE_LIFESPAN);
        this.cookieService.set(exports.SWITCH_ACCOUNT_COOKIE_NAME, JSON.stringify(session), date, // expiry
        '/', // path
        '', // domain
        true // secure
        );
    }
    createTempAdconUrlCookie() {
        if (this.adconUrl) {
            const date = new Date();
            date.setTime(date.getTime() + 30 * 1000);
            this.cookieService.set(exports.ADCON_URL_COOKIE_NAME, this.adconUrl, date, // expiry
            '/', // path
            '', // domain
            true // secure
            );
        }
    }
    login(legacyAdconLoginRequest) {
        this.adconUrl = this.windowService.adconUrls && this.windowService.adconUrls.shift();
        const pendo = {};
        if (this.disableAdcon3) {
            return rxjs_1.of(Object.assign(Object.assign({}, legacyAdconLoginRequest), { SysAccountSessionId: 'disabled', accountCode: 'disabled', sid: 'disabled', isPendo: false }));
        }
        pendo['isPendo'] = this.sessionService.isAppRunningInPendo();
        return this.rootAdconIFrame$.pipe(operators_1.mergeMap(iframe => iframe.formSubmit(this.adconUrl + '/mimecast/admin', Object.assign(Object.assign({}, legacyAdconLoginRequest), pendo), 'logon-response')));
    }
    logout(legacyAdconSession) {
        const pendo = {};
        if (this.disableAdcon3) {
            return rxjs_1.of(true);
        }
        if (legacyAdconSession) {
            pendo['isPendo'] = this.sessionService.isAppRunningInPendo();
        }
        const data = Object.assign(Object.assign({ action: 'logout' }, legacyAdconSession), pendo);
        return this.rootAdconIFrame$.pipe(operators_1.mergeMap(iframe => iframe.formSubmit(this.adconUrl + '/mimecast/admin', data, 'adcon-action', true)));
    }
    ping(legacyAdconSession) {
        const pendo = {};
        if (this.disableAdcon3) {
            return rxjs_1.of(true);
        }
        if (legacyAdconSession) {
            pendo['isPendo'] = this.sessionService.isAppRunningInPendo();
        }
        return this.rootAdconIFrame$.pipe(operators_1.mergeMap(iframe => iframe
            .formSubmit(this.adconUrl + '/mimecast/keepSessionAlive', Object.assign(Object.assign({}, legacyAdconSession), pendo))
            // ignore errors like timeout he keepSsessionAlive endpoint doent return anything
            // so the formSubmit callback logic is not called
            .pipe(operators_1.catchError(() => rxjs_1.EMPTY))));
    }
    changeAccount(accountSwitchSession) {
        const data = Object.assign({ sid: this.sessionService.sessionId }, accountSwitchSession);
        data.isPendo = this.sessionService.isAppRunningInPendo();
        return this.rootAdconIFrame$.pipe(operators_1.mergeMap(iframe => iframe.formSubmit(this.adconUrl + '/mimecast/changeaccount', data, 'changeaccount-response')));
    }
    postSubmit(url, data, callbackMessage) {
        return this.rootAdconIFrame$.pipe(operators_1.mergeMap(iframe => iframe.formSubmit(this.adconUrl + url, data, callbackMessage)));
    }
}
exports.LegacyAdconService = LegacyAdconService;
