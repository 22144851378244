"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiObfuscatorPipe = void 0;
class ApiObfuscatorPipe {
    transform(input) {
        const PADDING = 4;
        if (input) {
            input = input.trim();
            if (input.length <= PADDING * 2) {
                return new Array(input.length + 1).join('*');
            }
            return `${input.substring(0, PADDING)}${new Array(input.length + 1 - PADDING * 2).join('*')}${input.substring(input.length - PADDING)}`;
        }
        return '';
    }
}
exports.ApiObfuscatorPipe = ApiObfuscatorPipe;
