"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RulesTabComponent = void 0;
const table_store_1 = require("@mimecast-ui/table-store");
const rulesTableConfig = require("./rules-tab.static-values");
const components_1 = require("@mimecast-ui/components");
const rules_service_1 = require("../../services/rules.service");
const rule_details_actions_1 = require("app-new/archive/supervision/actions/rule.details.actions");
const view_rule_component_1 = require("../../../asides/view-rule/view-rule.component");
const ruleListActions = require("../../../../actions/rule.list.actions");
const rule_list_actions_1 = require("../../../../actions/rule.list.actions");
const rule_actions_1 = require("app-new/archive/supervision/actions/rule.actions");
const state_enum_1 = require("app-new/archive/supervision/types/state.enum");
const rule_delete_notification_modal_component_1 = require("../../../../components/rule-delete-notification-modal/rule-delete-notification-modal.component");
class RulesTabComponent extends table_store_1.TableBaseComponent {
    constructor(store, asideService, translateService, modalService) {
        super(store, rules_service_1.RulesService.SUPERVISION_RULES_LIST);
        this.store = store;
        this.asideService = asideService;
        this.translateService = translateService;
        this.modalService = modalService;
        this.sections = rulesTableConfig.filterSections;
        this.columns = rulesTableConfig.columns;
        this.columnsStartHidden = rulesTableConfig.columnsStartHidden;
        this.searchByFields = this.getSearchByFields();
    }
    set hasMonthlyScheduleCapability(hasCapability) {
        if (hasCapability) {
            this.sections = rulesTableConfig.filterSectionsWithMonthlySchedule;
        }
    }
    getStatus(status) {
        return rules_service_1.RulesService.STATUS_MAP[status];
    }
    getState(state) {
        const label = rules_service_1.RulesService.STATE_MAP[state];
        return label;
    }
    isActive(state) {
        return state === state_enum_1.RuleState.ACTIVE;
    }
    getSearchByFields() {
        const prefix = '$I18N_ARCHIVE_SUPERVISION_LIST_RULES_TAB.FILTERS.SEARCH_BY_FILTER_FIELD';
        const filterByOptions = [
            'all',
            'createdBy',
            'groupName',
            'lexiconName',
            'name',
            'queueName'
        ];
        return filterByOptions.map((filterByOption) => {
            return { value: filterByOption, label: [prefix, filterByOption.toUpperCase()].join('.') };
        });
    }
    dispatchFilterAction(filteringOption = this.currentFilters, pagination = this.currentPagination) {
        super.dispatchFilterAction(filteringOption, pagination);
    }
    viewRule(rule) {
        this.store.dispatch(new rule_details_actions_1.GetRuleDetailsAction(rule));
        this.store.dispatch(new rule_details_actions_1.UpdateRuleDetailsPaginationInfoAction(rule, this.tableContent));
        const asideConfig = new components_1.AsideConfig();
        asideConfig.data = this.tableContent;
        asideConfig.size = 'md';
        this.aside = this.asideService.open(view_rule_component_1.ViewRuleComponent, asideConfig);
        this.dispatchSetSelectedRow(rule);
    }
    editRule(rule) {
        const selectedRule = rule;
        this.store.dispatch(new rule_list_actions_1.OpenEditRuleAction(selectedRule.id));
        this.dispatchSetSelectedRow(rule);
    }
    getNoLexiconText() {
        return this.translateService.instant('$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SUMMARY_STEP.NO_LEXICON_SELECTED');
    }
    ngOnInit() {
        super.ngOnInit();
        this.tableDataSubscription = this.tableData$.subscribe(data => (this.tableContent = data));
    }
    ngOnDestroy() {
        super.ngOnDestroy();
        if (this.tableDataSubscription) {
            this.tableDataSubscription.unsubscribe();
        }
    }
    openCreate() {
        this.store.dispatch(new ruleListActions.OpenCreateNewRuleAction());
    }
    disableRule(ruleId) {
        this.store.dispatch(new rule_actions_1.SetRuleStateAction(ruleId, state_enum_1.RuleState.INACTIVE));
    }
    enableRule(ruleId) {
        this.store.dispatch(new rule_actions_1.SetRuleStateAction(ruleId, state_enum_1.RuleState.ACTIVE));
    }
    deleteRule(selectedRule) {
        const modal = this.modalService.open(rule_delete_notification_modal_component_1.RuleDeleteNotificationModalComponent, {
            size: 'lg',
            hasBackdrop: true,
            disableClose: true,
            data: {
                ruleName: selectedRule.name,
                lexiconName: selectedRule.lexicon && selectedRule.lexicon.name,
                queueName: selectedRule.reviewQueue.name
            }
        });
        modal.afterClose().subscribe(confirmed => {
            if (confirmed) {
                this.store.dispatch(new ruleListActions.DeleteRuleAction({ id: selectedRule.id }));
            }
        });
    }
}
exports.RulesTabComponent = RulesTabComponent;
