<mc-swg-domains-csv-uploader
  [content]="content$ | async"
  [hasError]="hasError$ | async"
  [existingDomains]="existingDomains$ | async"
  [fileName]="fileName$ | async"
  [isProcessing]="isProcessing$ | async"
  (readFile)="onReadFile($event)"
  (updateDomains)="onUpdateDomains($event)"
  (cancel)="onCancel()"
>
</mc-swg-domains-csv-uploader>
