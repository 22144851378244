"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ManagedUrlsPageComponent = void 0;
const listActions = require("../actions/url-list.actions");
const updateActions = require("../actions/url-update.actions");
const createActions = require("../actions/url-create.actions");
class ManagedUrlsPageComponent {
    constructor(store) {
        this.store = store;
    }
    search(search) {
        this.store.dispatch(new listActions.ManagedUrlsRequestAction({ search }));
    }
    columnFilterChanged(filter) {
        this.store.dispatch(new listActions.ManagedUrlsRequestAction({ search: filter }));
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    paginatorChanged(page) {
        // todo - in next mr
        // this.store.dispatch(new listActions.ManagedUrlsPaginationAction(page));
    }
    // open side panel (url details form )
    addNewManagedUrl() {
        this.store.dispatch(new createActions.OpenAddNewManagedUrlSidePanelAction());
    }
    // on table row click, open side panel and show url details
    rowClicked(url) {
        this.store.dispatch(new updateActions.OpenManagedUrlDetailsAction(url));
    }
    removeManagedUrl(url) {
        this.store.dispatch(new listActions.ManagedUrlsDeleteAttemptAction(url));
    }
    openSideInEditMode(url) {
        this.store.dispatch(new updateActions.OpenManagedUrlDetailsAction(url, true));
    }
}
exports.ManagedUrlsPageComponent = ManagedUrlsPageComponent;
