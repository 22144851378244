"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchMessagesByDataComponent = void 0;
const forms_1 = require("@angular/forms");
const components_1 = require("@mimecast-ui/components");
const search_messages_base_component_1 = require("../search-messages-base.component");
const moment = require("moment");
const date_ranges_1 = require("../date-range-config/date-ranges");
class SearchMessagesByDataComponent extends search_messages_base_component_1.SearchMessagesBaseDirective {
    constructor(fb, featureFlagService) {
        super(fb);
        this.featureFlagService = featureFlagService;
        this.defaultDateRange = date_ranges_1.dateRangePickerConfig.dateRanges[1];
        this.dateRangeConfig = date_ranges_1.dateRangePickerConfig;
        this.checkEmptyValues = function () {
            if (this.selectField && this.selectField.cdr) {
                this.selectField.cdr.detectChanges();
            }
            if (!!this.form.get('fileHash').value ||
                !!this.form.get('subject').value ||
                !!this.form.get('from').value ||
                !!this.form.get('url').value) {
                return false;
            }
            else {
                return true;
            }
        };
        const minLength = 3;
        const maxLengthEmail = 254;
        this.form = this.fb.group({
            fileHash: [null, [components_1.Sha256Validator]],
            from: [null, [forms_1.Validators.minLength(minLength), forms_1.Validators.maxLength(maxLengthEmail)]],
            subject: [null, [forms_1.Validators.minLength(3), forms_1.Validators.maxLength(200)]],
            to: [null, [forms_1.Validators.minLength(minLength), forms_1.Validators.maxLength(maxLengthEmail)]],
            dateRange: this.defaultDateRange,
            url: [null, [forms_1.Validators.minLength(3)]]
        });
        this.featureFlagService.isReady().then(() => {
            const isDateRangeRestrictionEnabled = featureFlagService.getBooleanFlag('Threat.Remediation.Date.Range.Restriction');
            if (isDateRangeRestrictionEnabled) {
                this.dateRangeConfig = Object.assign(Object.assign({}, date_ranges_1.dateRangePickerConfig), { minDate: moment('2023/07/29', 'YYYY/MM/DD') });
            }
        });
    }
}
exports.SearchMessagesByDataComponent = SearchMessagesByDataComponent;
