"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetGroupResetStore = exports.GetGroupFail = exports.GetGroupNotSet = exports.GetGroupSuccess = exports.GetGroup = exports.GET_GROUP_RESET_STORE = exports.GET_GROUP_NOT_SET = exports.GET_GROUP_FAIL = exports.GET_GROUP_SUCCESS = exports.GET_GROUP = void 0;
exports.GET_GROUP = '[API Integration] Retrieve group';
exports.GET_GROUP_SUCCESS = '[API Integration] Retrieve group success';
exports.GET_GROUP_FAIL = '[API Integration] Retrieve group fail';
exports.GET_GROUP_NOT_SET = '[API Integration] Retrieve group not set';
exports.GET_GROUP_RESET_STORE = '[API Integration] Retrieve group reset store';
class GetGroup {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_GROUP;
    }
}
exports.GetGroup = GetGroup;
class GetGroupSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_GROUP_SUCCESS;
    }
}
exports.GetGroupSuccess = GetGroupSuccess;
class GetGroupNotSet {
    constructor() {
        this.type = exports.GET_GROUP_NOT_SET;
    }
}
exports.GetGroupNotSet = GetGroupNotSet;
class GetGroupFail {
    constructor() {
        this.type = exports.GET_GROUP_FAIL;
    }
}
exports.GetGroupFail = GetGroupFail;
class GetGroupResetStore {
    constructor() {
        this.type = exports.GET_GROUP_RESET_STORE;
    }
}
exports.GetGroupResetStore = GetGroupResetStore;
