"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BiPolicyApiService = void 0;
const operators_1 = require("rxjs/operators");
class BiPolicyApiService {
    constructor(http, swgPolicyService) {
        this.http = http;
        this.swgPolicyService = swgPolicyService;
    }
    deleteBIPolicy(id) {
        return this.http
            .post('/api/policy/browserisolation/delete-policy-with-targets', { id })
            .pipe(operators_1.map(res => res.first));
    }
    createPolicy(swgPolicy) {
        const policy = this.swgPolicyService.createAPIPayLoad(swgPolicy, null);
        policy.description = swgPolicy.policyDesc.description;
        policy.narrative = swgPolicy.narrative;
        policy.sessionReadOnly = !swgPolicy.biSettings.enterText;
        policy.clipboardTransferIn = swgPolicy.biSettings.clipboardTransferIn;
        policy.clipboardTransferOut = swgPolicy.biSettings.clipboardTransferOut;
        let apiUrl = '/api/policy/browserisolation/create-policy-with-targets';
        if (swgPolicy.policyDesc.id) {
            apiUrl = '/api/policy/browserisolation/update-policy-with-targets';
            policy.id = swgPolicy.policyDesc.id;
        }
        return this.http.post(apiUrl, policy).pipe(operators_1.map(res => res.first));
    }
}
exports.BiPolicyApiService = BiPolicyApiService;
