<div class="mc-api-integration-step-block">
  <mc-wizard-step-header [label]="'$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.SUMMARY.HEADER'">
  </mc-wizard-step-header>

  <div class="mc-detailed-list mc-api-integration-summary-block">
    <div class="row mc-detailed-list-row panel-padding-bottom">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.SUMMARY.INTEGRATION_LABEL' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ 'cortex' | mcIntegrationProvider }}</div>
    </div>

    <div class="row mc-detailed-list-row panel-padding-bottom">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.SUMMARY.DESCRIPTION_LABEL' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.SUMMARY.DESCRIPTION_CORTEX' | translate }}
      </div>
    </div>

    <div class="row mc-detailed-list-row panel-padding-top">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.SUMMARY.REGION_LABEL' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ cortexIntegrationVersion1?.region }}</div>
    </div>

    <div class="row mc-detailed-list-row panel-padding-top">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.SUMMARY.LSN_LABEL' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ cortexIntegrationVersion1?.lakeSerialNumber }}</div>
    </div>

    <div class="row mc-detailed-list-row panel-padding-top">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.SUMMARY.IDENTIFIER_LABEL' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ getIntegrationIdentifier(cortexIntegrationVersion1?.integrationName) }}</div>
    </div>

    <div class="row mc-detailed-list-row panel-padding-top">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.SUMMARY.SPYWARE' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ spywareSettings().enabled | mcCortexBoolean }}</div>
    </div>
    <div *ngIf="spywareSettings().enabled">
      <div class="row mc-detailed-list-row panel-padding-bottom">
        <div class="col-xs-4 mc-detailed-list-label">
          {{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.SUMMARY.SEVERITY_LABEL' | translate }}
        </div>
        <div class="mc-detailed-list-column">{{ spywareSettings().fields.severity | mcCortexSeverity }}</div>
      </div>

      <div class="row mc-detailed-list-row panel-padding-bottom">
        <div class="col-xs-4 mc-detailed-list-label">
          {{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.SUMMARY.ACTION_LABEL' | translate }}
        </div>
        <div class="mc-detailed-list-column">{{ spywareSettings().action | mcCortexAction }}</div>
      </div>

      <div *ngIf="spywareSettings().action === 'BLOCK_OUTBOUND' || spywareSettings().action === 'BLOCK_ALL'" class="row mc-detailed-list-row panel-padding-bottom">
        <div class="col-xs-4 mc-detailed-list-label">
          {{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.SUMMARY.DISABLE_USER_LABEL' | translate }}
        </div>
        <div class="mc-detailed-list-column">{{ spywareSettings().disableUser | mcCortexBoolean }}</div>
      </div>
    </div>

    <div class="row mc-detailed-list-row panel-padding-top">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.SUMMARY.WILDFIRE' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ wildfireSettings().enabled | mcCortexBoolean }}</div>
    </div>
    <div *ngIf="wildfireSettings().enabled">
      <div class="row mc-detailed-list-row panel-padding-bottom">
        <div class="col-xs-4 mc-detailed-list-label">
          {{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.SUMMARY.SEVERITY_LABEL' | translate }}
        </div>
        <div class="mc-detailed-list-column">{{ wildfireSettings().fields.severity | mcCortexSeverity }}</div>
      </div>

      <div class="row mc-detailed-list-row panel-padding-bottom">
        <div class="col-xs-4 mc-detailed-list-label">
          {{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.SUMMARY.ACTION_LABEL' | translate }}
        </div>
        <div class="mc-detailed-list-column">{{ wildfireSettings().action | mcCortexAction }}</div>
      </div>

      <div *ngIf="wildfireSettings().action === 'BLOCK_OUTBOUND' || wildfireSettings().action === 'BLOCK_ALL'" class="row mc-detailed-list-row panel-padding-bottom">
        <div class="col-xs-4 mc-detailed-list-label">
          {{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.SUMMARY.DISABLE_USER_LABEL' | translate }}
        </div>
        <div class="mc-detailed-list-column">{{ wildfireSettings().disableUser | mcCortexBoolean }}</div>
      </div>
    </div>

    <div class="row mc-detailed-list-row panel-padding-top">
        <div class="col-xs-4 mc-detailed-list-label">
          {{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.SUMMARY.NOTIFY_LABEL' | translate }}
        </div>

        <div class="mc-detailed-list-column">
          <div class="row mc-detailed-list-row" *ngFor="let group of cortexGroupsList()">
            {{ group | translate }}
          </div>

          <div class="row mc-detailed-list-row" *ngFor="let email of cortexEmailList()">
            {{ email }}
          </div>
        </div>
    </div>

    <form novalidate [formGroup]="form">
      <mc-enable-switch
        [label]="'$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.SUMMARY.STATUS_LABEL'"
        formControlName="integrationStatus"
        (mousedown)="update()"
        contentDivClass="row mc-detailed-list-row panel-padding-top"
        labelClass="col-xs-4 mc-detailed-list-label"
        switchDivClass="mc-detailed-list-column"
      >
      </mc-enable-switch>
    </form>
  </div>
</div>
