"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RuleSettingsComponent = void 0;
const forms_1 = require("@angular/forms");
const operators_1 = require("rxjs/operators");
const rule_service_1 = require("../../../../../services/rule.service");
const step_base_component_1 = require("../../../step.base.component");
const select_mailbox_group_component_1 = require("app-new/archive/shared/groups/asides/select-mailbox-group/select-mailbox-group.component");
const components_1 = require("@mimecast-ui/components");
const set_rule_state_confirmation_component_1 = require("app-new/archive/supervision/components/set-rule-state-confirmation/set-rule-state-confirmation.component");
const settingsConfig = require("./settings.static-values");
class RuleSettingsComponent extends step_base_component_1.StepBaseDirective {
    constructor(store, fb, ruleService, asideService, modalService, cd) {
        super(store, fb, 2, rule_service_1.RuleService.WizardId);
        this.store = store;
        this.fb = fb;
        this.ruleService = ruleService;
        this.asideService = asideService;
        this.modalService = modalService;
        this.cd = cd;
        this.messageRoutes = this.ruleService.getMessageRoutes();
        this.scheduleTypes = this.ruleService.getScheduleTypes();
        this.form = this.fb.group({
            searchGroup: [null, [forms_1.Validators.required]],
            messageRoute: ['all', forms_1.Validators.required],
            scheduleType: ['daily', forms_1.Validators.required],
            samplingPercentage: [
                5,
                [forms_1.Validators.required, forms_1.Validators.max(100), forms_1.Validators.min(1), forms_1.Validators.pattern('[0-9]+')]
            ],
            isRuleEnabled: [true]
        });
    }
    set hasMonthlyScheduleCapability(hasCapability) {
        if (hasCapability) {
            this.scheduleTypes = settingsConfig.scheduleTypes;
        }
    }
    ngOnInit() {
        super.ngOnInit();
        if (this.isEditMode) {
            const group = {
                id: this.searchGroup.id,
                description: this.searchGroup.name
            };
            this.selectedGroup = group;
            this.form.patchValue({
                searchGroup: group,
                messageRoute: this.messageRoute,
                scheduleType: this.scheduleType,
                samplingPercentage: this.samplingPercentage,
                isRuleEnabled: this.isRuleEnabled
            });
        }
    }
    ngOnDestroy() {
        this.unsubscribeConfirmation();
    }
    openSidebar() {
        const asideConfig = new components_1.AsideConfig();
        asideConfig.data = { onlyLocalGroups: false, minCharacters: 1 };
        asideConfig.size = 'md';
        this.aside = this.asideService.open(select_mailbox_group_component_1.SelectMailboxGroupComponent, asideConfig);
        this.aside
            .beforeClose()
            .pipe(operators_1.take(1))
            .subscribe(group => {
            if (group) {
                this.selectedGroup = group;
                this.form.get('searchGroup').setValue(group);
                this.cd.detectChanges();
            }
        });
    }
    openStateChangeConfirmation(event) {
        this.unsubscribeConfirmation();
        event.preventDefault();
        const currentState = this.form.get('isRuleEnabled').value;
        this.confSubscription = this.modalService
            .open(set_rule_state_confirmation_component_1.SetRuleStateConfirmationComponent, {
            size: 'md',
            hasBackdrop: true,
            data: !currentState
        })
            .afterClose()
            .subscribe(confirmed => {
            if (confirmed) {
                this.form.patchValue({ isRuleEnabled: !currentState });
            }
        });
    }
    unsubscribeConfirmation() {
        if (this.confSubscription) {
            this.confSubscription.unsubscribe();
        }
    }
}
exports.RuleSettingsComponent = RuleSettingsComponent;
