"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const swg_applications_component_1 = require("./swg-applications.component");
angular
    .module('swgApplicationsHome', [])
    .directive('mcSwgApplicationsHome', static_1.downgradeComponent({
    component: swg_applications_component_1.SwgApplicationsComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider.state('secure-web-gateway-applications', {
            url: '/secure-web-gateway-applications/:tab/:id',
            data: {
                capabilities: 'Permission.SWG_CONFIGURE_POLICIES_READ',
                isFixedLayout: false,
                checkProgress: false,
                tabReload: true,
                tabTitle: 'Applications'
            },
            views: {
                main: {
                    template: '<mc-swg-applications-home></mc-swg-applications-home>'
                }
            }
        });
    }
]);
