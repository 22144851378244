"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CONTENT_VIEW_CAPS = void 0;
exports.CONTENT_VIEW_CAPS = {
    accepted: 'Permission.ACCEPTED_EMAIL_CONTENT_VIEW',
    archived: 'Permission.SEARCH_SEARCH_CONTENT_VIEW',
    bounced: 'Permission.SEARCH_SEARCH_CONTENT_VIEW',
    held: 'Permission.HELD_QUEUE_CONTENT_VIEW',
    processing: 'Permission.ACCEPTED_EMAIL_CONTENT_VIEW',
    delivery: 'Permission.ACCEPTED_EMAIL_CONTENT_VIEW'
};
