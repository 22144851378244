"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TogglePipe = void 0;
class TogglePipe {
    constructor(translateService) {
        this.translateService = translateService;
    }
    transform(value) {
        return this.translateService.instant(value
            ? '$I18N_ARCHIVE_SUPERVISION_PIPES.TOGGLE.ENABLED'
            : '$I18N_ARCHIVE_SUPERVISION_PIPES.TOGGLE.DISABLED');
    }
}
exports.TogglePipe = TogglePipe;
