
<mc-table
  tableId="swg/devices-list-view-table"
  class="mc-device-list-view-table"
  [isLoading]=" isLoading$ | async"
  [data]="tableData$ | async"
  [columns]="columns"
  translatePrefix="$I18N_DEVICES_LIST.TABLE"
  [showHighlightedRow]="false"
>
  <mc-filters
    #mcFiltersComponent
    (paginatorChange)="onPaginationChange($event)"
    [metadata]=" metaData$ | async"
    [showPagination]="true"
  >
    <mc-filter-search
      #filterSearchComponent
      (search)="onSearch($event)"
      (clear)="onSearchClear()"
      [placeholder]="'$I18N_DEVICES_LIST.TABLE.SEARCH.PLACEHOLDER' | translate"
    >
    </mc-filter-search>

    <div
      class="mc-table-actions panel-margin-left panel-half-margin-top mc-device-count">
      <span *ngIf="displayType === deviceListDisplayType.TARGET">{{ '$I18N_DEVICES_LIST.TABLE.ACTIONS.DEVICE_COUNT' | translate }}</span>
      <span *ngIf="displayType === deviceListDisplayType.TARGET" class="text-bold mc-status-green">
        {{'$I18N_DEVICES_LIST.TABLE.ACTIONS.DEVICE_COUNT' | translate}}
      </span>
    </div>

    <mc-filters-bundle-column-values-modal
      #filterComponent
      [sections]="tableFilterValues"
      (filtersChange)="onBundleFilterChange($event)"
    >
    </mc-filters-bundle-column-values-modal>

  </mc-filters>

  <mc-column key="os">
    <mc-body-cell *mcBodyCellDef="let row">
        <span>
          {{ row.os }}
        </span>
    </mc-body-cell>
  </mc-column>

  <mc-column key="status">
    <mc-body-cell *mcBodyCellDef="let row">
        <span >
          {{ row.status | mcSwgEndpointsListMapStatusText | translate }}
        </span>
    </mc-body-cell>
  </mc-column>

  <mc-column-actions
    key="action"
    [columnsToStartHidden]="columnsToStartHidden"
    [columnsAlwaysVisible]="columnsAlwaysVisible"
    mcShowColumnConfig
  >

  </mc-column-actions>

  <mc-empty-results keyTitle="$I18N_DEVICES_LIST.TABLE.NO_DATA">
    <mc-empty-body>

    </mc-empty-body>
  </mc-empty-results>
</mc-table>

