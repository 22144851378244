<div class="mc-api-gateway-step-block">
  <mc-wizard-step-header [label]="pageTitle">
    <span *ngIf="isEditing">
      {{ pageDescription | translate }}
    </span>
  </mc-wizard-step-header>

  <form
    class="form-horizontal"
    mcDefaultLabelClass="col-sm-3"
    mcDefaultControlContainerClass="col-sm-9"
    [formGroup]="form"
    (ngSubmit)="submit()"
  >
    <div class="row form-group" *ngIf="isCreating">
      <mc-field
        errorPrefix="$I18N_API_GATEWAY_SAVE_WIZARD.VALIDATION.FIELD"
        label="$I18N_API_GATEWAY_SAVE_WIZARD.FIELDS.APPLICATION_NAME"
        required
      >
        <input
          class="form-control application-name-input"
          formControlName="applicationName"
          [readonly]="applicationNameDisabled"
          aria-label="$I18N_API_GATEWAY_SAVE_WIZARD.FIELDS.APPLICATION_NAME"
        />
      </mc-field>
    </div>

    <div class="row form-group" *ngIf="isEditing">
      <div class="form-group mc-field">
        <label class="control-label col-sm-3">
          <span translate="$I18N_API_GATEWAY_SAVE_WIZARD.FIELDS.APPLICATION_NAME"></span>
        </label>
        <div class="col-sm-9 panel-half-padding-top">
          <span>{{ applicationDetails.applicationName }}</span>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <mc-form-select
        [label]="'$I18N_API_GATEWAY_SAVE_WIZARD.FIELDS.CATEGORY'"
        [placeholder]="'$I18N_API_GATEWAY_SAVE_WIZARD.FIELDS.CATEGORY_PLACEHOLDER'"
        formControlName="category"
        [options]="categories"
        [useTranslation]="true"
      >
      </mc-form-select>
    </div>

    <div class="row form-group">
      <div class="form-group mc-field">
        <label class="control-label col-sm-3">
          <span translate="$I18N_API_GATEWAY_SAVE_WIZARD.FIELDS.SERVICE"></span>
        </label>
        <div class="col-sm-9">
          <mc-checkbox
            label="$I18N_API_GATEWAY_SAVE_WIZARD.FIELDS.SERVICE_EXLABEL"
            formControlName="service"
          >
          </mc-checkbox>
        </div>
      </div>
    </div>

    <div class="row form-group panel-half-padding-top">
      <mc-text-area-field
        errorPrefix="$I18N_API_GATEWAY_SAVE_WIZARD.VALIDATION.FIELD"
        label="$I18N_API_GATEWAY_SAVE_WIZARD.FIELDS.DESCRIPTION"
        formControlName="description"
        required
      >
      </mc-text-area-field>
    </div>
  </form>
</div>
