<div class="health-card-container">
  <mc-chart>
    <mc-doughnut-chart
      class="mc-amchart"
      [data]="chartData"
      [labelColor]="labelColor"
    >
    </mc-doughnut-chart>
  </mc-chart>

  <div class="text-container">
    <span>
      {{ '$I18N_SYNC_AND_RECOVER_OVERVIEW_MODAL.HEALTH_SCORE_CARD.TITLE' | translate }}
    </span>
    <p>
      {{ '$I18N_SYNC_AND_RECOVER_OVERVIEW_MODAL.HEALTH_SCORE_CARD.SUB_TEXT' | translate }}
    </p>
  </div>
</div>
