"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EndpointsListEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const endpoints_list_actions_1 = require("../actions/endpoints-list.actions");
const notification_actions_1 = require("../../../components/notification/actions/notification.actions");
const purge_modal_container_component_1 = require("../container/purge-modal/purge-modal.container.component");
class EndpointsListEffects {
    constructor(actions$, translationService, endpointService, modalService) {
        this.actions$ = actions$;
        this.translationService = translationService;
        this.endpointService = endpointService;
        this.modalService = modalService;
        this.purgeEndpointsList$ = this.actions$.pipe(effects_1.ofType(endpoints_list_actions_1.SwgEndpointsListActions.PURGE_ENDPOINTS_LIST), operators_1.switchMap((action) => {
            return this.endpointService.purgeEndpoints(action.payload).pipe(operators_1.mergeMap(() => {
                return [
                    this.getSuccessNotificationAction(action.payload),
                    new endpoints_list_actions_1.SwgPurgeEndpointsListSuccessAction()
                ];
            }), operators_1.catchError(error => rxjs_1.merge(rxjs_1.of(this.getNotificationAction(error)), rxjs_1.of(new endpoints_list_actions_1.SwgPurgeEndpointsListFailureAction()))));
        }));
        this.confirmPurgeEndpointsList$ = this.actions$.pipe(effects_1.ofType(endpoints_list_actions_1.SwgEndpointsListActions.PURGE_ENDPOINTS_LIST_CONFIRM), operators_1.switchMap((action) => {
            const modalConfig = {};
            modalConfig.size = 'md';
            modalConfig.hasBackdrop = true;
            modalConfig.disableClose = true;
            modalConfig.data = action.payload;
            return this.modalService
                .open(purge_modal_container_component_1.SwgEndpointsListPurgeModalContainerComponent, modalConfig)
                .afterClose()
                .pipe(operators_1.map((result) => result ? new endpoints_list_actions_1.SwgPurgeEndpointsListAction(action.payload) : { type: 'no action' }));
        }));
    }
    getNotificationAction(error) {
        const msg = error.fail
            ? error.fail[0].errors[0].message
            : this.translationService.instant('$I18N_SWG_CREATE_POLICY_SERVER_ERROR');
        return new notification_actions_1.NotificationShowAction({
            type: 'error',
            config: {
                msg
            }
        });
    }
    getSuccessNotificationAction(payload) {
        const msg = payload.length > 1
            ? this.translationService.instant('$I18N_ENDPOINTS_LIST.NOTIFICATION.SUCCESS.PURGED_MULTIPLE')
            : this.translationService.instant('$I18N_ENDPOINTS_LIST.NOTIFICATION.SUCCESS.PURGED_SINGLE');
        return new notification_actions_1.NotificationShowAction({
            type: 'success',
            config: {
                msg
            }
        });
    }
}
__decorate([
    effects_1.Effect()
], EndpointsListEffects.prototype, "purgeEndpointsList$", void 0);
__decorate([
    effects_1.Effect()
], EndpointsListEffects.prototype, "confirmPurgeEndpointsList$", void 0);
exports.EndpointsListEffects = EndpointsListEffects;
