"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateBackupStatusSpinnerModalComponent = void 0;
class UpdateBackupStatusSpinnerModalComponent {
    constructor(overlayData) {
        this.overlayData = overlayData;
        this.titleTranslationKey = this.overlayData.newStatus
            ? '$I18N_ONEDRIVE_BACKUPS.CHANGE_BACKUP_STATUS.ENABLE_BACKUP_TITLE'
            : '$I18N_ONEDRIVE_BACKUPS.CHANGE_BACKUP_STATUS.DISABLE_BACKUP_TITLE';
    }
}
exports.UpdateBackupStatusSpinnerModalComponent = UpdateBackupStatusSpinnerModalComponent;
