"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.CustomDomainBlockDetailsFactory = void 0;
const models_1 = require("../models");
const ɵ0 = (cdm) => new models_1.CustomDomainBlockDetailsBuilder()
    .withDefinitionId(cdm['definitionId'])
    .withPolicyId(cdm['policyId'])
    .withName(cdm['name'])
    .withDescription(cdm['description'])
    .withAction(cdm['action'])
    .withNotify(cdm['notify'])
    .withConfigurationId(cdm['configurationId'])
    .withLastUpdated(cdm['lastUpdated'])
    .withEnabled(cdm['enabled'])
    .withAppliesTo(cdm['appliesTo'])
    .withNotifyGroupId(cdm['notifyGroupId'])
    .withNotifyGroupDescription(cdm['notifyGroupDescription'])
    .withRedirectToType(cdm['redirectToType'])
    .withRedirectToUser(cdm['redirectToUser'])
    .withRedirectToGroupId(cdm['redirectToGroupId'])
    .withRedirectToGroupDescription(cdm['redirectToGroupDescription'])
    .build();
exports.ɵ0 = ɵ0;
exports.CustomDomainBlockDetailsFactory = {
    BUILD_CUSTOM_DOMAIN_BLOCK_DETAILS: ɵ0
};
