"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgExceptionsListComponent = void 0;
const operators_1 = require("rxjs/operators");
const table_store_1 = require("@mimecast-ui/table-store");
const swg_exceptions_list_static_values_1 = require("./swg-exceptions-list.static-values");
const swg_exceptions_sidebar_modal_actions_1 = require("../../actions/swg-exceptions-sidebar-modal.actions");
class SwgExceptionsListComponent extends table_store_1.TableBaseComponent {
    constructor(store, capabilitiesService) {
        super(store, 'SwgExceptionsListTable');
        this.store = store;
        this.capabilitiesService = capabilitiesService;
        this.columns = swg_exceptions_list_static_values_1.columns;
        this.filterSearchOptions = swg_exceptions_list_static_values_1.filterSearchOptions;
        this.isEditable$ = this.capabilitiesService.hasCapability('Permission.SWG_BYPASS_HOSTS_EDIT');
    }
    onRowClick(row) {
        this.isEditable$
            .pipe(operators_1.first())
            .subscribe(isEditable => (isEditable ? this.editException(row) : null));
    }
    onSearch(filters) {
        this.filterSearch = { searchField: filters.filterBy, query: filters.search };
        this.runFilters();
    }
    onSearchClear() {
        this.filterSearch = undefined;
        this.runFilters();
    }
    runFilters() {
        const payload = Object.assign({}, this.filterSearch);
        this.onFilterChange(payload);
    }
    addException() {
        this.store.dispatch(new swg_exceptions_sidebar_modal_actions_1.SwgExceptionsSidebarOpenAction());
    }
    editException(row) {
        this.store.dispatch(new swg_exceptions_sidebar_modal_actions_1.SwgExceptionsSidebarOpenAction(row));
    }
    deleteException(row) {
        this.store.dispatch(new swg_exceptions_sidebar_modal_actions_1.SwgExceptionsDeleteConfirmModalOpenAction(row));
    }
}
exports.SwgExceptionsListComponent = SwgExceptionsListComponent;
