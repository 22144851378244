<div class="panel panel-default">
  <div class="panel-heading mc-bg-light">{{ '$I18N_REMEDIATION_LOGS_WIDGET.TITLE' | translate }}</div>
  <div *ngIf="!(isLogsTableLoading$ | async) && !(getLogsLoadingError$ | async); else spinner">
    <div *ngIf="(logs$ | async).length; else noResults">
      <mc-table
        [isLoading]="isLogsTableLoading$ | async"
        [columns]="columns"
        [data]="logs$ | async"
        translatePrefix="$I18N_REMEDIATION_LOGS_WIDGET">
        <mc-column key="incidentCode">
          <mc-body-cell class="link" (click)="onCellClick(row)" *mcBodyCellDef="let row">{{row.incidentCode }}</mc-body-cell>
        </mc-column>

        <mc-column key="to">
          <mc-body-cell *mcBodyCellDef="let row">{{ row.to }}</mc-body-cell>
        </mc-column>

        <mc-column key="from">
          <mc-body-cell *mcBodyCellDef="let row">{{ row.from }}</mc-body-cell>
        </mc-column>

        <mc-column key="action">
          <mc-body-cell *mcBodyCellDef="let row">{{ getTranslatedActionName(row.action) }}</mc-body-cell>
        </mc-column>

        <mc-column key="status">
          <mc-body-cell *mcBodyCellDef="let row">
            {{ getTranslatedStatusName(row.status) }}
            <span *ngIf="showFailureCode(row.status, row.failureCode)">
              ({{ '$I18N_REMEDIATION.FAILURE_TYPE.' + row.failureCode | uppercase | translate }})
            </span>
          </mc-body-cell>
        </mc-column>
      </mc-table>

      <div class="text-right">
        <a class="btn btn-link" (click)="openLogs()">{{ '$I18N_REMEDIATION_LOGS_WIDGET.ALL_LOGS' | translate }}</a>
      </div>
    </div>
  </div>
</div>

<ng-template #noResults>
  <mc-widget-placeholder label="$I18N_REMEDIATION_LOGS_WIDGET.NO_LOGS_ENTRIES" icon="far fa-list-alt"></mc-widget-placeholder>
</ng-template>

<ng-template #spinner>
  <mc-spinner class="spinner-wrapper" *ngIf="!(getLogsLoadingError$ | async); else genericError"></mc-spinner>
</ng-template>

<ng-template #genericError>
  <div class="spinner-wrapper">
    <mc-inline-notification [notification]="inlineNotificationConfigError"></mc-inline-notification>
  </div>
</ng-template>
