"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserInfoApiService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
/**
 * This class provides the http calls to basic api endpoints needed to load adcon, generally this calls
 * will be split in its own service but this particular set of call will be merge in a batch call to the server
 * to make them faster so they will be just one method, reason why they are not separated in services
 */
class UserInfoApiService {
    constructor(http, adminPreferencesApiService) {
        this.http = http;
        this.adminPreferencesApiService = adminPreferencesApiService;
    }
    verifyAccess() {
        return this.http.post('/api/user/verify-access', undefined).pipe(operators_1.map(response => {
            return response.first;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    getCapabilitiesAndPermissions() {
        return this.http.post('/api/user/get-capabilities-and-permissions', undefined).pipe(operators_1.map(response => {
            return response.first;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    getPreferences() {
        return this.http.post('/api/user/get-preferences', undefined).pipe(operators_1.map(response => {
            return response.first;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    getAdminPreferences() {
        return this.adminPreferencesApiService.getPreferences();
    }
    getUserProfile() {
        return this.http.post('/api/user/get-profile', undefined).pipe(operators_1.map(response => {
            return response.first;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    getLegacyAdconAccessToken() {
        return this.http.post('/api/connect/get-adcon-uri', undefined).pipe(operators_1.map(response => {
            return response.first;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    getAccount() {
        return this.http.post('/api/account/get-account', undefined).pipe(operators_1.map(response => {
            return response.first;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    getCapsOverride() {
        return this.http.post('/service/get-caps-overrides', undefined).pipe(operators_1.map(response => {
            return response.first;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    getConfigExtended() {
        return this.http.post('/service/get-config-extended', undefined).pipe(operators_1.map(response => {
            return response.first;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    getMigratedPolicyInfo() {
        return this.http
            .post('/api/policy/urlprotect/verify-migrated-policy', undefined)
            .pipe(operators_1.map(response => {
            return response.first;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
}
exports.UserInfoApiService = UserInfoApiService;
