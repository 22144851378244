<mc-modal-header
  [headerTitle]="'$I18N_SWG_THREAT_EVENT_FILE_DOWNLOAD_MODAL.HEADER_TITLE' | translate"
></mc-modal-header>

<mc-modal-body>
  <form class="panel-padding-bottom" [formGroup]="form">
    <p>{{ '$I18N_SWG_THREAT_EVENT_FILE_DOWNLOAD_MODAL.HELP_TEXT' | translate }}</p>

    <mc-inline-notification
      [notification]="{ status: 'warning', boxed: true, msg: '$I18N_SWG_THREAT_EVENT_FILE_DOWNLOAD_MODAL.DISCLAIMER' | translate }">
    </mc-inline-notification>

    <mc-inline-notification
      *ngIf="errorMessage"
      id="swg-threat-event-download-form-error"
      [notification]="{ status: 'error', msg: '$I18N_SWG_THREAT_EVENT_FILE_DOWNLOAD_MODAL.' + errorMessage | translate }">
    </mc-inline-notification>

    <div class="threat-event-file-password-form mc-detailed-list">
      <div class="row mc-detailed-list-row">
        <div class="col-xs-4 mc-detailed-list-label">
          <label for="threat-event-file-password">
            {{ "$I18N_SWG_THREAT_EVENT_FILE_DOWNLOAD_MODAL.PASSWORD_LABEL" | translate }}
          </label>
        </div>
        <div class="mc-detailed-list-column">
          <input
            id="threat-event-file-password"
            class="form-control"
            type="password"
            formControlName="password"
          />
        </div>
      </div>

      <div class="row mc-detailed-list-row">
        <div class="col-xs-4 mc-detailed-list-label">
          <label for="threat-event-file-confirmPassword">
            {{ "$I18N_SWG_THREAT_EVENT_FILE_DOWNLOAD_MODAL.CONFIRM_PASSWORD_LABEL" | translate }}
          </label>
        </div>
        <div class="mc-detailed-list-column">
          <input
            id="threat-event-file-confirmPassword"
            class="form-control"
            type="password"
            formControlName="confirmPassword"
          />
        </div>
      </div>
    </div>
  </form>
</mc-modal-body>

<mc-modal-footer>
  <button type="button" class="btn btn-secondary" (click)="onClose()">
    {{ '$I18N_SWG_THREAT_EVENT_FILE_DOWNLOAD_MODAL.CANCEL_BUTTON' | translate }}
  </button>

  <button type="button" class="btn btn-primary" (click)="onSubmit()">
    {{ '$I18N_SWG_THREAT_EVENT_FILE_DOWNLOAD_MODAL.SUBMIT_BUTTON' | translate }}
  </button>
</mc-modal-footer>
