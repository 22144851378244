"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProviderSelectComponent = void 0;
const core_1 = require("@angular/core");
const connector_1 = require("../../../model/connector");
class ProviderSelectComponent {
    constructor(cdRef) {
        this.cdRef = cdRef;
        this.onClick = new core_1.EventEmitter();
        this.onChange = () => { };
        this.onTouched = () => { };
    }
    set configuredProviders(configuredProviders) {
        this.selectedProvider = null;
        this._configuredProviders = configuredProviders;
    }
    get configuredProviders() {
        return this.isGwsProviderEnabled
            ? this._configuredProviders
            : this._configuredProviders.filter(provider => connector_1.ProviderType.GOOGLE_WORKSPACE !== provider.type);
    }
    writeValue(value) {
        this.selectedProvider = value;
        this.cdRef.markForCheck();
    }
    registerOnChange(fn) {
        this.onChange = fn;
    }
    registerOnTouched(fn) {
        this.onTouched = fn;
    }
    providerState(provider) {
        if (this.selectedProvider) {
            return this.selectedProvider.type === provider.type ? 'selected' : 'unselected';
        }
        return 'unselected';
    }
    selectProvider(provider) {
        if (this.selectedProvider !== provider) {
            this.selectedProvider = provider;
            this.onChange(provider);
            this.onTouched();
            this.onClick.emit();
        }
    }
}
exports.ProviderSelectComponent = ProviderSelectComponent;
