<div class="col-sm-4">
  <div class="panel-half-padding-left">
    <div #container class="mc-integration-provider" (click)="selected()">
      <ng-content></ng-content>
      <div class="overlay" [ngClass]="state">
        <span class="icon fas fa-check-circle"></span>
      </div>
    </div>
  </div>
</div>
<div class="col-sm-8">
  <div class="panel-half-padding-bottom">
    <strong>{{ title | translate }}</strong>
  </div>
  <div>
    <span>{{ description | translate }}</span>
  </div>
</div>
