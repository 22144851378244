"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SummaryByReasonEffects = void 0;
const effects_1 = require("@ngrx/effects");
const operators_1 = require("rxjs/operators");
const rxjs_1 = require("rxjs");
const summaryByReasonAction = require("../actions/summary-by-reason.action");
class SummaryByReasonEffects {
    constructor(actions$, service, store) {
        this.actions$ = actions$;
        this.service = service;
        this.store = store;
        this.summaryDetail$ = this.actions$.pipe(effects_1.ofType(summaryByReasonAction.SUMMARY_BY_REASON_REQUEST), operators_1.switchMap(() => {
            return this.service.getSummaryDetail().pipe(operators_1.map((results) => {
                return new summaryByReasonAction.SummaryByReasonSuccessAction({ results });
            }), operators_1.catchError((response) => {
                return rxjs_1.of(new summaryByReasonAction.SummaryByReasonFailAction(response.fail));
            }));
        }));
    }
}
__decorate([
    effects_1.Effect()
], SummaryByReasonEffects.prototype, "summaryDetail$", void 0);
exports.SummaryByReasonEffects = SummaryByReasonEffects;
