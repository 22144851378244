<mc-layout-list-extra-container
  keyTitle="$I18N_MESSAGE_CENTER_REJECTED_AND_DEFERRED_CONTAINER.HEADER.TITLE"
  keyDescription="$I18N_MESSAGE_CENTER_REJECTED_AND_DEFERRED_CONTAINER.HEADER.SUB_TITLE">
  <mc-help-container>
    <mc-help-link url="https://community.mimecast.com/s/article/email-security-cloud-gateway-rejected-and-deferred-messages"></mc-help-link>
  </mc-help-container>

  <mc-body-container>
    <mc-rejected-and-deferred-tabs-page>  
    </mc-rejected-and-deferred-tabs-page>
  </mc-body-container>
</mc-layout-list-extra-container>
