"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiCrowdstrikeIntegrationAsideComponent = void 0;
const actions = require("../../../actions");
const reducers = require("../../../reducers");
class ApiCrowdstrikeIntegrationAsideComponent {
    constructor(store, integration) {
        this.store = store;
        this.integration = integration;
    }
    ngOnInit() {
        this.enabled$ = this.store.select(reducers.isIntegrationListApplicationEnabled(this.integration.id));
        this.crowdstrikeIntegration$ = this.store.select(reducers.getIntegrationCrowdstrikeIntegration);
    }
    enable() {
        this.store.dispatch(new actions.EnableIntegrationAction({ integration: this.integration }));
    }
    disable() {
        this.store.dispatch(new actions.DisableIntegrationAction({ integration: this.integration }));
    }
    edit() {
        this.store.dispatch(new actions.CloseViewIntegrationAction());
        this.store.dispatch(new actions.OpenEditIntegrationAction({ integration: this.integration }));
    }
    close() {
        this.store.dispatch(new actions.CloseViewIntegrationAction());
    }
    openCrowdstrikeActivityLogPage(integrationId) {
        this.store.dispatch(new actions.OpenCrowdstrikeActivityLogPageAction(integrationId));
    }
}
exports.ApiCrowdstrikeIntegrationAsideComponent = ApiCrowdstrikeIntegrationAsideComponent;
