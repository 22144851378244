'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
/**
 * Created by cfeudo on 06/10/2015.
 */
angular.module('side.service', [])
    .factory('sideService', [function () {
        let sideTemplate = '';
        return {
            setSideTemplate,
            getSideTemplate
        };
        //--------- implementations ---------
        function setSideTemplate(template) {
            sideTemplate = template;
        }
        function getSideTemplate() {
            return sideTemplate ? sideTemplate : 'layout/side/side.tpl.html';
        }
    }]);
