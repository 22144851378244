"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SessionHandlerService = void 0;
const moment = require("moment");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
class SessionHandlerService {
    constructor(windowService, cookieService, urlParametersService, locationService, coreService) {
        this.windowService = windowService;
        this.cookieService = cookieService;
        this.urlParametersService = urlParametersService;
        this.locationService = locationService;
        this.coreService = coreService;
        /**
         * session object for backward compatiblity must not be undefined,
         * if  session is not active it should be empty object
         *
         * @type {{}}
         */
        this.session = {};
        /**
         * subscribe to this event to know when a logout has happen
         *
         * @type {Subject}
         */
        this.logout$ = new rxjs_1.Subject();
        /**
         * returns true is the first time the app is loaded, no refresh has happen
         *
         * @returns {boolean}
         */
        this.isFirstAppLoad = false;
        /**
         * The expiry value for mc-sg. It should expire in 12 hours
         */
        this.MC_SG_COOKIE_EXPIRY = 12 * 60 * 60 * 1000;
        this.defaultLoginUrl = '/u/login/';
        this.appReload$ = this.windowService.unload$.pipe(operators_1.takeUntil(this.logout$), operators_1.filter(() => !!this.sessionId), operators_1.tap(() => this.createTempSessionCookie()));
        const sessionCookieValue = cookieService.get('mc-sg');
        const extendedCookieValue = cookieService.get('mc-adcon-reload');
        if (sessionCookieValue) {
            this.session = JSON.parse(sessionCookieValue);
        }
        if (!!extendedCookieValue) {
            // extendedCookieValue holds wrong account code when switching to multiple accounts from partner portal
            // so consider retaining session information from mc-sg
            this.session = Object.assign(Object.assign({}, JSON.parse(extendedCookieValue)), this.session);
            cookieService.delete('mc-adcon-reload', '/');
        }
        this.isFirstAppLoad = !this.session.loggedInTime;
        this.session.loggedInTime =
            (this.session.loggedInTime && moment(this.session.loggedInTime).toDate()) || new Date();
        // This visibility change is to handle logout automatically in one tab when the user logged out of a different tab
        // When user logged out of one tab, in a different active session tab they are able to load adcon 3.5 pages until
        // adcon 4 api call is made which will logout the user eventually.
        let hidden;
        let visibilityChange;
        if (typeof document.hidden !== 'undefined') {
            hidden = 'hidden';
            visibilityChange = 'visibilitychange';
            // @ts-ignore
        }
        else if (typeof document.msHidden !== 'undefined') {
            hidden = 'msHidden';
            visibilityChange = 'msvisibilitychange';
            // @ts-ignore
        }
        else if (typeof document.webkitHidden !== 'undefined') {
            hidden = 'webkitHidden';
            visibilityChange = 'webkitvisibilitychange';
        }
        function isSessionCookiePresent() {
            const sessionCookieVal = cookieService.get('mc-sg');
            if (!sessionCookieVal) {
                coreService.logout(true, true); // params: coreService.logout(invalidAccess = false, loggedOutMsg = false);
            }
            else {
                checkIfCookieIsForAnotherUser(sessionCookieVal);
            }
        }
        function checkIfCookieIsForAnotherUser(msSgCookie) {
            return coreService.getSession().subscribe(storedCookie => {
                let parsedCookieValue = JSON.parse(msSgCookie).id;
                if (parsedCookieValue.indexOf('loggedInTime') !== -1) {
                    parsedCookieValue = parsedCookieValue.substring(0, parsedCookieValue.indexOf('loggedInTime'));
                }
                if (parsedCookieValue !== storedCookie.id) {
                    coreService.reload();
                }
            });
        }
        function handleVisibilityChange() {
            if (!document[hidden]) {
                isSessionCookiePresent();
            }
        }
        document.addEventListener(visibilityChange, handleVisibilityChange);
    }
    get sessionId() {
        return this.session && this.session.id;
    }
    get accountSwitchSession() {
        return this.coreService.getAccountSwitchSession();
    }
    get tempSessionId() {
        return this.tempSession && this.tempSession.id;
    }
    isSessionPresent(loginUrl) {
        if (loginUrl) {
            this.defaultLoginUrl = loginUrl;
        }
        if (!this.session || !this.session.id) {
            this.logout(true);
            return false;
        }
        else {
            return true;
        }
    }
    destroySession() {
        this.session = {};
        this.cookieService.delete('mc-sg', '/');
        this.cookieService.delete('mc-adcon-reload', '/');
        if (window !== window.parent && !this.cookieService.get('cypress-iframe')) {
            this.cookieService.delete('mc-switch-account', '/');
        }
    }
    logout(isInvalidSession = false) {
        this.coreService.logout(isInvalidSession);
    }
    redirectToSamlUrl(samlLogoutUrl) {
        this.locationService.href = samlLogoutUrl;
    }
    logoutAndGoToLogin(isInvalidSession = false) {
        this.logout$.next({ logOut: true });
        const params = this.urlParametersService.getUrlParameters();
        let urlToken = params.tkn;
        if (this.cookieService.get('ccm_tkn')) {
            urlToken = this.cookieService.get('ccm_tkn');
        }
        urlToken = urlToken ? 'tkn=' + urlToken : '';
        const link = isInvalidSession &&
            this.locationService.hash &&
            this.locationService.hash.length > 2 &&
            'link=' + this.locationService.hash.substring(2);
        this.destroySession();
        const origin = this.locationService.protocol + '//' + this.locationService.host;
        let firstParam = this.defaultLoginUrl.indexOf('?') === -1;
        let queryString = '';
        if (urlToken) {
            queryString += (firstParam ? '?' : '&') + urlToken;
            firstParam = false;
        }
        if (link) {
            queryString += (firstParam ? '?' : '&') + link;
        }
        // Post a message to parent window when Administration console is inside an iframe
        // If not inside an iframe redirect to MUL
        if (this.windowService.inIframe()) {
            this.windowService.postMessageToParent({
                action: 'LogoutIframeAdcon',
                login: {
                    defaultLoginUrl: this.defaultLoginUrl,
                    queryString
                }
            }, origin);
        }
        else {
            this.locationService.href = origin + this.defaultLoginUrl + queryString;
        }
    }
    createTempSessionCookie() {
        if (this.session && this.session.id) {
            const expire = new Date();
            expire.setTime(expire.getTime() + 60 * 1000); // 60 seconds
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const _a = this.session, { id } = _a, reloadCookie = __rest(_a, ["id"]);
            this.cookieService.set('mc-adcon-reload', JSON.stringify(reloadCookie), expire, // expiry
            '/', // path
            '', // domain
            true // secure
            );
        }
    }
    /**
     * returns time since login
     *
     * @returns miliseconds since logged in
     */
    timeSinceLogin() {
        const timeSince = (this.session &&
            this.session.loggedInTime &&
            Date.now() - this.session.loggedInTime.getTime()) ||
            -1;
        return timeSince;
    }
    inIframe() {
        try {
            return window.self !== window.top;
        }
        catch (e) {
            return true;
        }
    }
    isAppRunningInPendo() {
        try {
            return (this.inIframe() && this.session && this.session.origin && this.session.origin === 'pendo');
        }
        catch (e) {
            return false;
        }
    }
    extendSessionCookieExpiry() {
        const sessionCookieValue = this.cookieService.get('mc-sg');
        let extSession = {};
        if (!!sessionCookieValue) {
            extSession = JSON.parse(sessionCookieValue);
        }
        if (extSession && extSession.id) {
            const expire = new Date();
            expire.setTime(expire.getTime() + this.MC_SG_COOKIE_EXPIRY);
            // Do not set SameSite if the app runs in IFrame inside Pendo in order to access the cookie
            if (!this.isAppRunningInPendo()) {
                this.cookieService.set('mc-sg', JSON.stringify(extSession), expire, // expiry
                '/', // path
                '', // domain
                true, // secure
                'Strict' // sameSite
                );
            }
            else {
                this.cookieService.set('mc-sg', JSON.stringify(extSession), expire, '/', '', true);
            }
        }
    }
}
exports.SessionHandlerService = SessionHandlerService;
