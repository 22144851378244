'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
document.createElement('mc-select-date-picker');
angular.module('select-date-picker.directive', [])
    .directive('mcSelectDatePicker', [function () {
        return {
            restrict: 'AE',
            templateUrl: 'components/select-date-picker/select-date-picker.tpl.html',
            scope: {
                startDate: '=startDate',
                endDate: '=endDate',
                showTimePicker: '=showTimePicker',
                minStartDate: '=minStartDate',
                maxEndDate: '=maxEndDate',
                ranges: '=',
                initLabel: '<'
            },
            controller: 'SelectDatePickerCtrl as sdpCtrl'
        };
    }]);
