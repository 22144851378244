<mc-system-messages-table
  [rows]="items$ | async"
  [selectedRow]="selectedRow$ | async"
  [externalTableSearchCount]="totalCount$ | async"
  [isLoading]="isLoading$ | async"
  [metadata]="metadata$ | async"
  (onPaginatorChanged)="paginatorChanged($event)"
  (onSearchFilterChanged)="search($event)"
  (onDateFilterChanged)="dateFilterChanged($event)"
  (onColumnFilterChanged)="columnFilterChanged($event)"
  (onRouteRecalculated)="routeRecalculated($event)"
  (onMessagesRejected)="messagesRejected($event)"
  (onMessagesRetried)="messagesRetried($event)"
  (onMessagesEarlyBounced)="messagesEarlyBounced($event)"
  (onMessagesExported)="exportResults($event)"
>
</mc-system-messages-table>

  