'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const _ = require("lodash");
const operators_1 = require("rxjs/operators");
const rxjs_1 = require("rxjs");
angular.module('services.services.attachment-protection.controller', ['core.service'])
    .controller('AttachmentProtectionController', [
    'classicAdconHelperService', '$state', '$translate', 'ttpAttachmentService', 'dashboardCommonsService', 'ttpUrlService',
    'attachmentProtectionChartsService', 'CoreService',
    function (classicAdconHelperService, $state, $translate, ttpAttachmentService, dashboardCommonsService, ttpUrlService, attachmentProtectionChartsService, CoreService) {
        const self = this;
        self.coreService = CoreService;
        self.hasCapEnabledAtAccountLevel = hasCapEnabledAtAccountLevel;
        self.hasEarlyAccessCapEnabled = hasEarlyAccessCapEnabled;
        rxjs_1.combineLatest([
            self.coreService.getAccount(),
            self.coreService.getCapsOverride()
        ]).pipe(operators_1.filter(([account, caps]) => !!account && !!caps), operators_1.first())
            .subscribe(([account, caps]) => {
            self.hasNewPolicyWizardEarlyAccessEnabled = self.hasEarlyAccessCapEnabled(caps['Temporary.Attachment.Protection.Wizard'], account);
            self.hasNewPolicyWizardAccountEnabled = self.hasCapEnabledAtAccountLevel(caps['Temporary.Attachment.Protection.Wizard'], account);
        });
        self.ttpAttachmentService = ttpAttachmentService;
        self.attachmentsSafeCountByDayPromise = {};
        self.attachmentsMaliciousCountByDayPromise = {};
        self.attachmentsCleanCountByFileNamePromise = {};
        self.attachmentLogs = {
            inbound: [],
            outbound: [],
            internal: []
        };
        self.unknownLabel = $translate.instant('$I18N_COMMON_EXPORT_CHART_UNKNOWN_LABEL');
        self.clickedURLsTabs = [
            {
                id: 'top-senders',
                label: '$I18N_ADMINISTRATION_MENU_ATTACHMENT_PROTECTION_CHART_TOP_SENDERS_TITLE',
                tpl: ''
            },
            {
                id: 'top-receivers',
                label: '$I18N_ADMINISTRATION_MENU_ATTACHMENT_PROTECTION_CHART_TOP_RECIPIENTS_TITLE',
                tpl: ''
            },
            {
                id: 'unsafe-files',
                label: '$I18N_ADMINISTRATION_MENU_ATTACHMENT_PROTECTION_CHART_UNSAFE_FILES_BY_NAME_TITLE',
                tpl: ''
            }
        ];
        self.clickedURLsTabSelected = self.clickedURLsTabs[0];
        self.urlTypes = [{
                key: ttpUrlService.constants.URL_TYPES.CLEAN,
                value: $translate.instant('$I18N_ADMINISTRATION_DASHBOARD_CLEAN_LABEL')
            }, {
                key: ttpUrlService.constants.URL_TYPES.MALICIOUS,
                value: $translate.instant('$I18N_ADMINISTRATION_DASHBOARD_MALICIOUS_LABEL')
            }];
        self.attachmentsSafeCountsPerDayChartSwitcher = {
            dataSets: [{
                    series: ttpUrlService.constants.ROUTE.ALL,
                    label: '$I18N_ADMINISTRATION_DASHBOARD_ALL_LABEL',
                    active: true
                }, {
                    series: ttpUrlService.constants.ROUTE.INBOUND,
                    label: '$I18N_ADMINISTRATION_DASHBOARD_INBOUND_LABEL'
                }, {
                    series: ttpUrlService.constants.ROUTE.OUTBOUND,
                    label: '$I18N_ADMINISTRATION_DASHBOARD_OUTBOUND_LABEL'
                }, {
                    series: ttpUrlService.constants.ROUTE.INTERNAL,
                    label: '$I18N_ADMINISTRATION_DASHBOARD_INTERNAL_LABEL'
                }]
        };
        self.topSendersChartSwitcher = {
            dataSets: [{
                    series: ttpUrlService.constants.ROUTE.INBOUND,
                    label: '$I18N_ADMINISTRATION_DASHBOARD_INBOUND_LABEL',
                    active: true
                }, {
                    series: ttpUrlService.constants.ROUTE.OUTBOUND,
                    label: '$I18N_ADMINISTRATION_DASHBOARD_OUTBOUND_LABEL'
                }, {
                    series: ttpUrlService.constants.ROUTE.INTERNAL,
                    label: '$I18N_ADMINISTRATION_DASHBOARD_INTERNAL_LABEL'
                }]
        };
        self.topRecipientsChartSwitcher = angular.copy(self.topSendersChartSwitcher);
        self.unsafeFilesByNameChartSwitcher = angular.copy(self.topSendersChartSwitcher);
        self.attachmentsByExtSwitcher = angular.copy(self.topSendersChartSwitcher);
        self.last10UnsafeAttachmentsSwitcher = angular.copy(self.topSendersChartSwitcher);
        self.selectClickedURLsTab = selectClickedURLsTab;
        self.clickedURLsTabIsActive = clickedURLsTabIsActive;
        self.getAttachmentsSafeCountsPerDay = getAttachmentsSafeCountsPerDay;
        self.getAttachmentSafePerType = getAttachmentSafePerType;
        self.getAttachmentPerCategory = getAttachmentPerCategory;
        self.getAttachmentLogs = getAttachmentLogs;
        self.getTopUnsafeSenders = getTopUnsafeSenders;
        self.getTopUnsafeRecipients = getTopUnsafeRecipients;
        self.getTopUnsafeFiles = getTopUnsafeFiles;
        self.openLegacyAttachmentProtectLogs = openLegacyAttachmentProtectLogs;
        self.openLegacyAttachmentLinksAndBlocks = openLegacyAttachmentLinksAndBlocks;
        self.openLegacyHeldMessages = openLegacyHeldMessages;
        self.openLegacyPolicies = openLegacyPolicies;
        self.openLegacyDefinitions = openLegacyDefinitions;
        self.openNewPolicyWizard = openNewPolicyWizard;
        self.openNewDefinitions = openNewDefinitions;
        self.activate = activate;
        self.activate();
        function activate() {
            self.currentDateRange = dashboardCommonsService.getCurrentMonthDateRange();
            self.attachmentsAllCountByDayPromise = ttpAttachmentService.getCounts(preparePayload({
                sortBy: ttpUrlService.constants.SORT_BY.ATTRIBUTE,
                result: ttpUrlService.constants.SCAN_TYPE.ALL
            }));
            self.attachmentsUnsafeCountByDayPromise = ttpAttachmentService.getCounts(preparePayload({
                sortBy: ttpUrlService.constants.SORT_BY.ATTRIBUTE,
                result: ttpUrlService.constants.SCAN_TYPE.UNSAFE
            }));
            self.attachmentsCountByFileNamePromise = ttpAttachmentService.getCounts(preparePayload({
                groupBy: ttpUrlService.constants.GROUP_BY.FILE_EXT
            }));
            self.attachmentsTopSendersPromise = ttpAttachmentService.getCounts(preparePayload({
                groupBy: ttpUrlService.constants.GROUP_BY.SENDER,
                sortBy: ttpUrlService.constants.SORT_BY.COUNT,
                result: ttpUrlService.constants.SCAN_TYPE.UNSAFE
            }));
            self.attachmentsTopReceiversPromise = ttpAttachmentService.getCounts(preparePayload({
                groupBy: ttpUrlService.constants.GROUP_BY.USER,
                sortBy: ttpUrlService.constants.SORT_BY.COUNT,
                result: ttpUrlService.constants.SCAN_TYPE.UNSAFE
            }));
            self.attachmentsTopUnsafeFilesPromise = ttpAttachmentService.getCounts(preparePayload({
                groupBy: ttpUrlService.constants.GROUP_BY.FILE_NAME,
                sortBy: ttpUrlService.constants.SORT_BY.COUNT,
                result: ttpUrlService.constants.SCAN_TYPE.UNSAFE
            }));
            self.getAttachmentLogs(ttpUrlService.constants.ROUTE.INBOUND);
            self.getAttachmentLogs(ttpUrlService.constants.ROUTE.OUTBOUND);
            self.getAttachmentLogs(ttpUrlService.constants.ROUTE.INTERNAL);
        }
        function hasEarlyAccessCapEnabled(cap) {
            return !!cap && cap.enabled && cap.earlyAccess;
        }
        function hasCapEnabledAtAccountLevel(cap, account) {
            return (!!cap && cap.enabled && (!cap.accountCodes || cap.accountCodes.indexOf(account.accountCode) !== -1));
        }
        function getAttachmentsSafeCountsPerDay(activeSeries) {
            const attachmentSafeCountsPerDayConfig = attachmentProtectionChartsService.getAttachmentsPerDayConfig();
            return self.attachmentsUnsafeCountByDayPromise.then(function (maliciousCountResponse) {
                if (!maliciousCountResponse.err) {
                    return self.attachmentsAllCountByDayPromise.then(function (response) {
                        if (!response.err) {
                            const series = {
                                inbound() {
                                    return [{
                                            data: dashboardCommonsService.getDataForAllDays(self.currentDateRange.from, self.currentDateRange.to, response.first.dayCount.routes[0].dayCounts),
                                            name: $translate.instant('$I18N_ADMINISTRATION_MENU_ATTACHMENT_PROTECTION_CHART_ATTACHMENT_COUNT_PER_DAY_LEGEND_ALL_INBOUND'),
                                            showInLegend: true,
                                            color: dashboardCommonsService.getMcChartsTheme().defaultColours.inbound.primary
                                        }, {
                                            data: dashboardCommonsService.getDataForAllDays(self.currentDateRange.from, self.currentDateRange.to, maliciousCountResponse.first.dayCount.routes[0].dayCounts),
                                            name: $translate.instant('$I18N_ADMINISTRATION_MENU_ATTACHMENT_PROTECTION_CHART_ATTACHMENT_COUNT_PER_DAY_LEGEND_UNSAFE_INBOUND'),
                                            showInLegend: true,
                                            color: dashboardCommonsService.getMcChartsTheme().defaultColours.inbound.alt,
                                            lineWidth: 2,
                                            dashStyle: 'dot'
                                        }];
                                },
                                outbound() {
                                    return [{
                                            data: dashboardCommonsService.getDataForAllDays(self.currentDateRange.from, self.currentDateRange.to, response.first.dayCount.routes[1].dayCounts),
                                            name: $translate.instant('$I18N_ADMINISTRATION_MENU_ATTACHMENT_PROTECTION_CHART_ATTACHMENT_COUNT_PER_DAY_LEGEND_ALL_OUTBOUND'),
                                            showInLegend: true,
                                            color: dashboardCommonsService.getMcChartsTheme().defaultColours.outbound.primary
                                        }, {
                                            data: dashboardCommonsService.getDataForAllDays(self.currentDateRange.from, self.currentDateRange.to, maliciousCountResponse.first.dayCount.routes[1].dayCounts),
                                            name: $translate.instant('$I18N_ADMINISTRATION_MENU_ATTACHMENT_PROTECTION_CHART_ATTACHMENT_COUNT_PER_DAY_LEGEND_UNSAFE_OUTBOUND'),
                                            showInLegend: true,
                                            color: dashboardCommonsService.getMcChartsTheme().defaultColours.outbound.alt,
                                            lineWidth: 2,
                                            dashStyle: 'dot'
                                        }];
                                },
                                internal() {
                                    return [{
                                            data: dashboardCommonsService.getDataForAllDays(self.currentDateRange.from, self.currentDateRange.to, response.first.dayCount.routes[2].dayCounts),
                                            name: $translate.instant('$I18N_ADMINISTRATION_MENU_ATTACHMENT_PROTECTION_CHART_ATTACHMENT_COUNT_PER_DAY_LEGEND_ALL_INTERNAL'),
                                            showInLegend: true,
                                            color: dashboardCommonsService.getMcChartsTheme().defaultColours.internal.primary
                                        }, {
                                            data: dashboardCommonsService.getDataForAllDays(self.currentDateRange.from, self.currentDateRange.to, maliciousCountResponse.first.dayCount.routes[2].dayCounts),
                                            name: $translate.instant('$I18N_ADMINISTRATION_MENU_ATTACHMENT_PROTECTION_CHART_ATTACHMENT_COUNT_PER_DAY_LEGEND_UNSAFE_INTERNAL'),
                                            showInLegend: true,
                                            color: dashboardCommonsService.getMcChartsTheme().defaultColours.internal.alt,
                                            lineWidth: 2,
                                            dashStyle: 'dot'
                                        }];
                                },
                                all() {
                                    return dashboardCommonsService.sortArrayByCleanAndMalicious(this.inbound().concat(this.outbound()).concat(this.internal()));
                                }
                            };
                            attachmentSafeCountsPerDayConfig.series = series[activeSeries]();
                            return attachmentSafeCountsPerDayConfig;
                        }
                        else {
                            ttpAttachmentService.getError().showErrorNotification(ttpAttachmentService.getError().getSingleFailObj(response));
                        }
                    }, function () {
                        ttpAttachmentService.getError().showErrorNotification();
                    });
                }
                else {
                    // @ts-ignore
                    ttpAttachmentService.getError().showErrorNotification(ttpAttachmentService.getError().getSingleFailObj(response));
                }
            }, function () {
                ttpAttachmentService.getError().showErrorNotification();
            });
        }
        function getAttachmentSafePerType() {
            const attachmentPerTypeConfig = attachmentProtectionChartsService.getAttachmentsPerCleanMaliciousConfig();
            return self.attachmentsUnsafeCountByDayPromise.then(function (maliciousCountResponse) {
                if (!maliciousCountResponse.err) {
                    return self.attachmentsAllCountByDayPromise.then(function (response) {
                        if (!response.err) {
                            attachmentPerTypeConfig.series[0].data = [{
                                    y: dashboardCommonsService.getUrlCountPerGroup(response.first.dayCount.routes[0].dayCounts),
                                    color: dashboardCommonsService.getMcChartsTheme().defaultColours.inbound.primary
                                }, {
                                    y: dashboardCommonsService.getUrlCountPerGroup(response.first.dayCount.routes[1].dayCounts),
                                    color: dashboardCommonsService.getMcChartsTheme().defaultColours.outbound.primary
                                }, {
                                    y: dashboardCommonsService.getUrlCountPerGroup(response.first.dayCount.routes[2].dayCounts),
                                    color: dashboardCommonsService.getMcChartsTheme().defaultColours.internal.primary
                                }];
                            attachmentPerTypeConfig.series[1].data = [{
                                    y: dashboardCommonsService.getUrlCountPerGroup(maliciousCountResponse.first.dayCount.routes[0].dayCounts),
                                    color: dashboardCommonsService.getMcChartsTheme().defaultColours.inbound.alt
                                }, {
                                    y: dashboardCommonsService.getUrlCountPerGroup(maliciousCountResponse.first.dayCount.routes[1].dayCounts),
                                    color: dashboardCommonsService.getMcChartsTheme().defaultColours.outbound.alt
                                }, {
                                    y: dashboardCommonsService.getUrlCountPerGroup(maliciousCountResponse.first.dayCount.routes[2].dayCounts),
                                    color: dashboardCommonsService.getMcChartsTheme().defaultColours.internal.alt
                                }];
                            attachmentPerTypeConfig.tooltip.formatter = function () {
                                return dashboardCommonsService.getCustomTooltip(attachmentPerTypeConfig, this.x);
                            };
                            return attachmentPerTypeConfig;
                        }
                        else {
                            ttpUrlService.getError().showErrorNotification(response);
                        }
                    }, function () {
                        ttpUrlService.getError().showErrorNotification();
                    });
                }
                else {
                    // @ts-ignore
                    ttpAttachmentService.getError().showErrorNotification(ttpAttachmentService.getError().getSingleFailObj(response));
                }
            }, function () {
                ttpAttachmentService.getError().showErrorNotification();
            });
        }
        function getAttachmentPerCategory(activeSeries) {
            self.attachmentScannedPerCategoryConfig = attachmentProtectionChartsService.getDoughnutChartConfig();
            return self.attachmentsCountByFileNamePromise.then(function (response) {
                if (!response.err) {
                    const series = {
                        inbound: [{
                                name: $translate.instant('$I18N_ADMINISTRATION_DASHBOARD_INBOUND_LABEL'),
                                data: dashboardCommonsService.getUrlCountsPerCategory(response.first.fileExtCount.routes[0].attributeCounts)
                            }],
                        outbound: [{
                                name: $translate.instant('$I18N_ADMINISTRATION_DASHBOARD_OUTBOUND_LABEL'),
                                data: dashboardCommonsService.getUrlCountsPerCategory(response.first.fileExtCount.routes[1].attributeCounts)
                            }],
                        internal: [{
                                name: $translate.instant('$I18N_ADMINISTRATION_DASHBOARD_INTERNAL_LABEL'),
                                data: dashboardCommonsService.getUrlCountsPerCategory(response.first.fileExtCount.routes[2].attributeCounts)
                            }]
                    };
                    self.attachmentScannedPerCategoryConfig.colors = dashboardCommonsService.getMcChartsTheme().colorCharts[activeSeries];
                    self.attachmentScannedPerCategoryConfig.title.text = series[activeSeries][0].name + '<br/>' + $translate.instant('$I18N_ADMINISTRATION_MENU_ATTACHMENT_PROTECTION_CHART_ATTACHMENT_COUNT_TOOLTIP');
                    if (dashboardCommonsService.isDataSetEmpty(series[activeSeries][0])) {
                        self.attachmentScannedPerCategoryConfig.title = '';
                    }
                    self.attachmentScannedPerCategoryConfig.series = series[activeSeries];
                    return self.attachmentScannedPerCategoryConfig;
                }
                else {
                    ttpUrlService.getError().showErrorNotification(response);
                }
            }, function () {
                ttpUrlService.getError().showErrorNotification();
            });
        }
        function getTopUnsafeSenders(activeSeries) {
            const topUnsafeSendersConfig = attachmentProtectionChartsService.getMostUnsafeRecipientsAndFilesConfig();
            return self.attachmentsTopSendersPromise.then(function (response) {
                if (!response.err) {
                    const chartConfigs = {
                        inbound: {
                            xAxis: {
                                categories: dashboardCommonsService.getCategoriesAndSeries(response.first.senderCount.routes[0].attributeCounts, false).categories.slice(0, 10)
                            },
                            series: [{
                                    data: dashboardCommonsService.getCategoriesAndSeries(response.first.senderCount.routes[0].attributeCounts, false).series.slice(0, 10),
                                    showInLegend: false
                                }]
                        },
                        outbound: {
                            xAxis: {
                                categories: dashboardCommonsService.getCategoriesAndSeries(response.first.senderCount.routes[1].attributeCounts, false).categories.slice(0, 10)
                            },
                            series: [{
                                    data: dashboardCommonsService.getCategoriesAndSeries(response.first.senderCount.routes[1].attributeCounts, false).series.slice(0, 10),
                                    showInLegend: false
                                }]
                        },
                        internal: {
                            xAxis: {
                                categories: dashboardCommonsService.getCategoriesAndSeries(response.first.senderCount.routes[2].attributeCounts, false).categories.slice(0, 10)
                            },
                            series: [{
                                    data: dashboardCommonsService.getCategoriesAndSeries(response.first.senderCount.routes[2].attributeCounts, false).series.slice(0, 10),
                                    showInLegend: false
                                }]
                        }
                    };
                    topUnsafeSendersConfig.plotOptions.bar.colors = dashboardCommonsService.getMcChartsTheme().colorCharts[activeSeries];
                    topUnsafeSendersConfig.yAxis.title.text = $translate.instant('$I18N_ADMINISTRATION_MENU_ATTACHMENT_PROTECTION_CHART_TOP_SENDERS_LABEL');
                    return _.merge(topUnsafeSendersConfig, chartConfigs[activeSeries]);
                }
                else {
                    ttpUrlService.getError().showErrorNotification(response);
                }
            }, function () {
                ttpUrlService.getError().showErrorNotification();
            });
        }
        function getTopUnsafeRecipients(activeSeries) {
            const topUnsafeRecipientsConfig = attachmentProtectionChartsService.getMostUnsafeRecipientsAndFilesConfig();
            return self.attachmentsTopReceiversPromise.then(function (response) {
                if (!response.err) {
                    const chartConfigs = {
                        inbound: {
                            xAxis: {
                                categories: dashboardCommonsService.getCategoriesAndSeries(response.first.userCount.routes[0].attributeCounts, false).categories.slice(0, 10)
                            },
                            series: [{
                                    data: dashboardCommonsService.getCategoriesAndSeries(response.first.userCount.routes[0].attributeCounts, false).series.slice(0, 10),
                                    showInLegend: false
                                }]
                        },
                        outbound: {
                            xAxis: {
                                categories: dashboardCommonsService.getCategoriesAndSeries(response.first.userCount.routes[1].attributeCounts, false).categories.slice(0, 10)
                            },
                            series: [{
                                    data: dashboardCommonsService.getCategoriesAndSeries(response.first.userCount.routes[1].attributeCounts, false).series.slice(0, 10),
                                    showInLegend: false
                                }]
                        },
                        internal: {
                            xAxis: {
                                categories: dashboardCommonsService.getCategoriesAndSeries(response.first.userCount.routes[2].attributeCounts, false).categories.slice(0, 10)
                            },
                            series: [{
                                    data: dashboardCommonsService.getCategoriesAndSeries(response.first.userCount.routes[2].attributeCounts, false).series.slice(0, 10),
                                    showInLegend: false
                                }]
                        }
                    };
                    topUnsafeRecipientsConfig.plotOptions.bar.colors = dashboardCommonsService.getMcChartsTheme().colorCharts[activeSeries];
                    topUnsafeRecipientsConfig.yAxis.title.text = $translate.instant('$I18N_ADMINISTRATION_MENU_ATTACHMENT_PROTECTION_CHART_TOP_RECIPIENTS_LABEL');
                    return _.merge(topUnsafeRecipientsConfig, chartConfigs[activeSeries]);
                }
                else {
                    ttpUrlService.getError().showErrorNotification(response);
                }
            }, function () {
                ttpUrlService.getError().showErrorNotification();
            });
        }
        function getTopUnsafeFiles(activeSeries) {
            const topUnsafeFilesConfig = attachmentProtectionChartsService.getMostUnsafeRecipientsAndFilesConfig();
            return self.attachmentsTopUnsafeFilesPromise.then(function (response) {
                if (!response.err) {
                    const chartConfigs = {
                        inbound: {
                            xAxis: {
                                categories: dashboardCommonsService.getCategoriesAndSeries(response.first.fileNameCount.routes[0].attributeCounts, false).categories.slice(0, 10)
                            },
                            series: [{
                                    data: dashboardCommonsService.getCategoriesAndSeries(response.first.fileNameCount.routes[0].attributeCounts, false).series.slice(0, 10),
                                    showInLegend: false
                                }]
                        },
                        outbound: {
                            xAxis: {
                                categories: dashboardCommonsService.getCategoriesAndSeries(response.first.fileNameCount.routes[1].attributeCounts, false).categories.slice(0, 10)
                            },
                            series: [{
                                    data: dashboardCommonsService.getCategoriesAndSeries(response.first.fileNameCount.routes[1].attributeCounts, false).series.slice(0, 10),
                                    showInLegend: false
                                }]
                        },
                        internal: {
                            xAxis: {
                                categories: dashboardCommonsService.getCategoriesAndSeries(response.first.fileNameCount.routes[2].attributeCounts, false).categories.slice(0, 10)
                            },
                            series: [{
                                    data: dashboardCommonsService.getCategoriesAndSeries(response.first.fileNameCount.routes[2].attributeCounts, false).series.slice(0, 10),
                                    showInLegend: false
                                }]
                        }
                    };
                    topUnsafeFilesConfig.plotOptions.bar.colors = dashboardCommonsService.getMcChartsTheme().colorCharts[activeSeries];
                    topUnsafeFilesConfig.yAxis.title.text = $translate.instant('$I18N_ADMINISTRATION_MENU_ATTACHMENT_PROTECTION_CHART_TOP_FILES_LABEL');
                    return _.merge(topUnsafeFilesConfig, chartConfigs[activeSeries]);
                }
                else {
                    ttpUrlService.getError().showErrorNotification(response);
                }
            }, function () {
                ttpUrlService.getError().showErrorNotification();
            });
        }
        function getAttachmentLogs(route) {
            ttpAttachmentService.getLogs({
                from: self.currentDateRange.from,
                to: self.currentDateRange.to,
                route
            }).then(function (response) {
                if (!response.err) {
                    self.attachmentLogs[route] = response.first.attachmentLogs;
                }
                else {
                    ttpUrlService.getError().showErrorNotification(response);
                }
            }, function () {
                ttpUrlService.getError().showErrorNotification();
            });
        }
        function openLegacyAttachmentProtectLogs() {
            $state.go('legacy', { uid: 'targetedthreatprotection-attachmentprotect-monitoring' });
        }
        function openLegacyAttachmentLinksAndBlocks() {
            $state.go('legacy', { uid: 'attachments' });
        }
        function openLegacyHeldMessages() {
            $state.go('legacy', { uid: 'held' });
        }
        function openLegacyPolicies() {
            $state.go('legacy.dashboard-attached-protection-policies');
        }
        function openLegacyDefinitions() {
            $state.go('legacy.dashboard-attached-protection-definitions');
        }
        function openNewPolicyWizard() {
            $state.go('attachment-protection-policy-list');
        }
        function openNewDefinitions() {
            $state.go('attachment-protection-definition-list');
        }
        function preparePayload(payload) {
            const defaultPayload = {
                from: self.currentDateRange.from,
                to: self.currentDateRange.to,
                route: ttpUrlService.constants.ROUTE.ALL,
                sortBy: ttpUrlService.constants.SORT_BY.COUNT,
                sortOrder: ttpUrlService.constants.SORT_ORDER.DESC
            };
            if (payload) {
                return _.merge(defaultPayload, payload);
            }
            return defaultPayload;
        }
        function selectClickedURLsTab(tab) {
            return self.clickedURLsTabSelected = tab;
        }
        function clickedURLsTabIsActive(tab) {
            return self.clickedURLsTabSelected == tab ? 'active' : '';
        }
    }
]);
