"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WaitingExperienceModalComponent = void 0;
class WaitingExperienceModalComponent {
    constructor(overlayRef, searchWithoutHash) {
        this.overlayRef = overlayRef;
        this.searchWithoutHash = searchWithoutHash;
    }
}
exports.WaitingExperienceModalComponent = WaitingExperienceModalComponent;
