"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WizardModule = void 0;
const wizardSteps = require("./im-sync-task/steps");
const cancel_confirmation_modal_component_1 = require("./cancel-confirmation-modal/cancel-confirmation-modal.component");
const im_sync_task_component_1 = require("./im-sync-task/im-sync-task.component");
const DECLARATIONS = [im_sync_task_component_1.ImSyncTaskComponent, cancel_confirmation_modal_component_1.CancelConfirmationModalComponent, wizardSteps.steps];
class WizardModule {
}
exports.WizardModule = WizardModule;
