<mc-layout-aside-simple keyTitle="$I18N_MONITORED_EXTERNAL_DOMAIN_ADD_DOMAIN"
                        [isLoading]="false"
                        [showClose]="true"
                        (closeAside)="cancel.emit()">
  <mc-body-container>
    <div>{{'$I18N_MONITORED_DOMAINS_DOMAINS_VALIDATION.ERRORS_INFORMATION' | translate}}</div>

    <div *ngIf="inValidDomains.length">
      <div class="panel-padding-top formControl">
        {{'$I18N_MONITORED_DOMAINS_DOMAINS_VALIDATION.ERRORS' | translate}} ({{inValidDomains.length}})
      </div>

      <mc-validation-table [isValid]="false"
                           [data]="inValidDomains"
                           (removeItem)="removeInvalidDomain.emit($event)">
      </mc-validation-table>
    </div>

    <div *ngIf="validDomains.length">
      <div class="panel-padding-top">
        {{'$I18N_MONITORED_DOMAINS_DOMAINS_VALIDATION.NEW_DOMAINS' | translate}} ({{validDomains.length}})
      </div>
      <mc-validation-table [isValid]="true"
                           [data]="validDomains"
                           (removeItem)="removeValidDomain.emit($event)">
      </mc-validation-table>
    </div>
  </mc-body-container>

  <mc-footer-container>
    <div class="pull-right">
      <button class="btn btn-link text-normal panel-half-margin-right" (click)="cancel.emit()" [disabled]="isCreatingDomains">
        {{'$I18N_MONITORED_DOMAINS_DOMAINS_VALIDATION.BUTTON_LABEL.CANCEL' | translate}}
      </button>
      <button class="btn btn-secondary panel-half-margin-right" (click)="showDomainsSettings.emit()" [disabled]="isCreatingDomains">
        {{'$I18N_MONITORED_DOMAINS_DOMAINS_VALIDATION.BUTTON_LABEL.BACK' | translate}}
      </button>
      <mc-live-button
        [label]="(isCreatingDomains ? '$I18N_MONITORED_DOMAINS_DOMAINS_VALIDATION.BUTTON_LABEL.ADDING' : '$I18N_MONITORED_DOMAINS_DOMAINS_VALIDATION.BUTTON_LABEL.ADD') | translate"
        overrideClasses="btn-primary"
        [mcDisabled]="!(validDomains.length) || (inValidDomains.length)"
        [isLoading]="isCreatingDomains"
        (mcClick)="createDomains.emit()">
      </mc-live-button>
    </div>
  </mc-footer-container>
</mc-layout-aside-simple>
