"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResetPolicyWizardAction = exports.CreatePolicyFailAction = exports.CreatePolicySuccessAction = exports.CreatePolicyAction = exports.GetAddressAttributeOptionsSuccessAction = exports.GetAddressAttributeOptionsAction = exports.SaveAppliesToStepDataAction = exports.SaveDetailsStepDataAction = exports.SetPolicyStatusEnabled = exports.OpenPoliciesPage = exports.EndAPIProcessing = exports.BeginAPIProcessing = exports.OpenCreatePolicyPage = exports.CYBERGRAPH_RESET_POLICY_WIZARD = exports.CYBERGRAPH_CREATE_POLICY_FAIL = exports.CYBERGRAPH_CREATE_POLICY_SUCCESS = exports.CYBERGRAPH_CREATE_POLICY = exports.GET_ADDRESS_ATTRIBUTE_OPTIONS_SUCCESS = exports.GET_ADDRESS_ATTRIBUTE_OPTIONS = exports.CYBERGRAPH_SAVE_APPLIES_TO_STEP_DATA = exports.CYBERGRAPH_SAVE_DETAILS_STEP_DATA = exports.CYBERGRAPH_SET_POLICY_STATUS_ENABLED = exports.CYBERGRAPH_OPEN_POLICIES_PAGE = exports.CYBERGRAPH_END_API_IS_PROCESSING = exports.CYBERGRAPH_BEGIN_API_IS_PROCESSING = exports.CYBERGRAPH_OPEN_CREATE_POLICY_PAGE = void 0;
exports.CYBERGRAPH_OPEN_CREATE_POLICY_PAGE = '[Services / CyberGraph / Policies] Open create policy page';
exports.CYBERGRAPH_BEGIN_API_IS_PROCESSING = '[Services / CyberGraph / Policies] API processing';
exports.CYBERGRAPH_END_API_IS_PROCESSING = '[Services / CyberGraph / Policies] API finished processing';
exports.CYBERGRAPH_OPEN_POLICIES_PAGE = '[Services / CyberGraph / Policies] Open policies page';
exports.CYBERGRAPH_SET_POLICY_STATUS_ENABLED = '[Services / CyberGraph / Policies / Summary Step] Set policy status enabled';
exports.CYBERGRAPH_SAVE_DETAILS_STEP_DATA = '[Services / CyberGraph / Policies] Save Details step data';
exports.CYBERGRAPH_SAVE_APPLIES_TO_STEP_DATA = '[Services / CyberGraph / Policies] Save Applies To step data';
exports.GET_ADDRESS_ATTRIBUTE_OPTIONS = '[Services / CyberGraph / Policies / Applies To step] Get custom page set options';
exports.GET_ADDRESS_ATTRIBUTE_OPTIONS_SUCCESS = '[Services / CyberGraph / Policies / Applies To step] Get custom page set options - SUCCESS';
exports.CYBERGRAPH_CREATE_POLICY = '[Services / CyberGraph / Policies] Create policy';
exports.CYBERGRAPH_CREATE_POLICY_SUCCESS = '[Services / CyberGraph / Policies] Create policy - SUCCESS';
exports.CYBERGRAPH_CREATE_POLICY_FAIL = '[Services / CyberGraph / Policies] Create policy - FAIL';
exports.CYBERGRAPH_RESET_POLICY_WIZARD = '[Services / CyberGraph / Policies] Policy Wizard reset data';
class OpenCreatePolicyPage {
    constructor() {
        this.type = exports.CYBERGRAPH_OPEN_CREATE_POLICY_PAGE;
    }
}
exports.OpenCreatePolicyPage = OpenCreatePolicyPage;
class BeginAPIProcessing {
    constructor() {
        this.type = exports.CYBERGRAPH_BEGIN_API_IS_PROCESSING;
    }
}
exports.BeginAPIProcessing = BeginAPIProcessing;
class EndAPIProcessing {
    constructor() {
        this.type = exports.CYBERGRAPH_END_API_IS_PROCESSING;
    }
}
exports.EndAPIProcessing = EndAPIProcessing;
class OpenPoliciesPage {
    constructor() {
        this.type = exports.CYBERGRAPH_OPEN_POLICIES_PAGE;
    }
}
exports.OpenPoliciesPage = OpenPoliciesPage;
class SetPolicyStatusEnabled {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.CYBERGRAPH_SET_POLICY_STATUS_ENABLED;
    }
}
exports.SetPolicyStatusEnabled = SetPolicyStatusEnabled;
class SaveDetailsStepDataAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.CYBERGRAPH_SAVE_DETAILS_STEP_DATA;
    }
}
exports.SaveDetailsStepDataAction = SaveDetailsStepDataAction;
class SaveAppliesToStepDataAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.CYBERGRAPH_SAVE_APPLIES_TO_STEP_DATA;
    }
}
exports.SaveAppliesToStepDataAction = SaveAppliesToStepDataAction;
class GetAddressAttributeOptionsAction {
    constructor() {
        this.type = exports.GET_ADDRESS_ATTRIBUTE_OPTIONS;
    }
}
exports.GetAddressAttributeOptionsAction = GetAddressAttributeOptionsAction;
class GetAddressAttributeOptionsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_ADDRESS_ATTRIBUTE_OPTIONS_SUCCESS;
    }
}
exports.GetAddressAttributeOptionsSuccessAction = GetAddressAttributeOptionsSuccessAction;
class CreatePolicyAction {
    constructor() {
        this.type = exports.CYBERGRAPH_CREATE_POLICY;
    }
}
exports.CreatePolicyAction = CreatePolicyAction;
class CreatePolicySuccessAction {
    constructor() {
        this.type = exports.CYBERGRAPH_CREATE_POLICY_SUCCESS;
    }
}
exports.CreatePolicySuccessAction = CreatePolicySuccessAction;
class CreatePolicyFailAction {
    constructor() {
        this.type = exports.CYBERGRAPH_CREATE_POLICY_FAIL;
    }
}
exports.CreatePolicyFailAction = CreatePolicyFailAction;
class ResetPolicyWizardAction {
    constructor() {
        this.type = exports.CYBERGRAPH_RESET_POLICY_WIZARD;
    }
}
exports.ResetPolicyWizardAction = ResetPolicyWizardAction;
