"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiAppsTechPartnersListComponent = void 0;
const operators_1 = require("rxjs/operators");
const actions = require("../../actions");
const reducers = require("../../reducers");
class ApiAppsTechPartnersListComponent {
    constructor(store, translateService, modalService) {
        this.store = store;
        this.translateService = translateService;
        this.modalService = modalService;
    }
    ngOnInit() {
        this.integrations$ = this.store.select(reducers.getIntegrationListApplications);
        this.store.dispatch(new actions.GetGatewayApplicationRoleListWithHeaderAction(true));
    }
    generateKeys(predefinedValues) {
        const apiKeyName = this.translateService.instant(`$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.TECH_PARTNERS.${predefinedValues.id}.TITLE`.toUpperCase());
        this.store
            .select(reducers.getApiGatewayV3Roles)
            .pipe(operators_1.first())
            .toPromise()
            .then((roles) => {
            const applicationCategory = predefinedValues.category;
            if (predefinedValues.id === 'legacycustom') {
                this.store.dispatch(new actions.OpenCreateApiApplicationAction());
            }
            else if (predefinedValues.id === 'apigateway') {
                if (roles.length) {
                    this.store.dispatch(new actions.OpenCreateApiV3ApplicationAction());
                }
                else {
                    this.modalService.open({
                        content: '$I18N_API_APPLICATIONS_LIST.NOTIFICATIONS.ERRORS.API2.0_ERROR',
                        contentParams: {
                            url: 'https://community.mimecast.com/s/article/Email-Security-Cloud-Gateway-Managing-Administrator-Roles'
                        },
                        hideHeader: true
                    });
                }
            }
            else {
                this.store.dispatch(new actions.OpenApiApplicationAction({
                    apiName: apiKeyName,
                    category: applicationCategory,
                    service: predefinedValues.service
                }));
            }
        });
    }
    createIntegration(id) {
        if (id === 'cortex') {
            this.store.dispatch(new actions.StartIntegrationCreateCortexAction());
        }
        else if (id === 'crowdstrike') {
            this.store.dispatch(new actions.StartIntegrationCrowdstrikeAction());
        }
        else if (id === 'wildfire') {
            this.store.dispatch(new actions.StartIntegrationWildfireAction({ runChecks: false }));
        }
        else if (id === 'scim') {
            this.store.dispatch(new actions.StartIntegrationOktaAction());
        }
        else if (id === 'okta_evidence_based_control') {
            this.store.dispatch(new actions.StartIntegrationOktaEvidenceBasedControlAction());
        }
    }
    editIntegration(integration) {
        this.store.dispatch(new actions.OpenEditIntegrationAction({
            integration
        }));
    }
}
exports.ApiAppsTechPartnersListComponent = ApiAppsTechPartnersListComponent;
