"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.getApPolicyDetails = exports.getPolicyDetailsPolicyId = exports.getWizardFlowType = exports.getEditPolicyId = exports.getPolicyDetailsIsLoading = exports.getAppliesFrom = exports.getGroupToIndex = exports.getGroupFromIndex = exports.getAppliesTo = exports.getApPolicyJournal = exports.getApPolicyInbound = exports.getApPolicy = exports.getApPolicyOutbound = exports.getOutboundAdministratorGroup = exports.getJournalAdministratorGroup = exports.getAdministratorGroups = exports.getPolicyAppliesTo = exports.getApAttributes = exports.getApIpAddressDisplayText = exports.getApIpAddressesss = exports.getApPolicyDesc = exports.getDeleteSelectedPolicy = exports.getSelectedPolicy = exports.getCreateApPolicy = exports.getApPolicyFeatureState = exports.reducers = void 0;
const createApPolicy = require("./policy/ap-policy.reducer");
const store_1 = require("@ngrx/store");
exports.reducers = {
    createApPolicy: createApPolicy.reducer
};
exports.getApPolicyFeatureState = store_1.createFeatureSelector('apPolicies');
const ɵ0 = state => state.createApPolicy;
exports.ɵ0 = ɵ0;
exports.getCreateApPolicy = store_1.createSelector(exports.getApPolicyFeatureState, ɵ0);
exports.getSelectedPolicy = store_1.createSelector(exports.getCreateApPolicy, createApPolicy.getSelectedPolicy);
exports.getDeleteSelectedPolicy = store_1.createSelector(exports.getCreateApPolicy, createApPolicy.getDeleteSelectedPolicy);
exports.getApPolicyDesc = store_1.createSelector(exports.getCreateApPolicy, createApPolicy.getApPolicyDesc);
exports.getApIpAddressesss = store_1.createSelector(exports.getCreateApPolicy, createApPolicy.getApIpAddressesss);
exports.getApIpAddressDisplayText = store_1.createSelector(exports.getCreateApPolicy, createApPolicy.getApIpAddressDisplayText);
exports.getApAttributes = store_1.createSelector(exports.getCreateApPolicy, createApPolicy.getApAttributes);
exports.getPolicyAppliesTo = store_1.createSelector(exports.getCreateApPolicy, createApPolicy.getPolicyAppliesTo);
exports.getAdministratorGroups = store_1.createSelector(exports.getCreateApPolicy, createApPolicy.getAdministratorGroups);
exports.getJournalAdministratorGroup = store_1.createSelector(exports.getCreateApPolicy, createApPolicy.getJournalAdministratorGroup);
exports.getOutboundAdministratorGroup = store_1.createSelector(exports.getCreateApPolicy, createApPolicy.getOutboundAdministratorGroup);
exports.getApPolicyOutbound = store_1.createSelector(exports.getCreateApPolicy, createApPolicy.getApPolicyOutbound);
exports.getApPolicy = store_1.createSelector(exports.getCreateApPolicy, createApPolicy.getApPolicy);
exports.getApPolicyInbound = store_1.createSelector(exports.getCreateApPolicy, createApPolicy.getApPolicyInbound);
exports.getApPolicyJournal = store_1.createSelector(exports.getCreateApPolicy, createApPolicy.getApPolicyJournal);
exports.getAppliesTo = store_1.createSelector(exports.getCreateApPolicy, createApPolicy.getAppliesTo);
exports.getGroupFromIndex = store_1.createSelector(exports.getCreateApPolicy, createApPolicy.getGroupFromIndex);
exports.getGroupToIndex = store_1.createSelector(exports.getCreateApPolicy, createApPolicy.getGroupToIndex);
exports.getAppliesFrom = store_1.createSelector(exports.getCreateApPolicy, createApPolicy.getAppliesFrom);
exports.getPolicyDetailsIsLoading = store_1.createSelector(exports.getCreateApPolicy, createApPolicy.isLoading);
exports.getEditPolicyId = store_1.createSelector(exports.getCreateApPolicy, createApPolicy.getEditPolicyId);
exports.getWizardFlowType = store_1.createSelector(exports.getCreateApPolicy, createApPolicy.getWizardFlowType);
exports.getPolicyDetailsPolicyId = store_1.createSelector(exports.getCreateApPolicy, createApPolicy.getPolicyId);
exports.getApPolicyDetails = store_1.createSelector(exports.getCreateApPolicy, createApPolicy.getPolicy);
