"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgBlockPageApiService = void 0;
class SwgBlockPageApiService {
    constructor(http) {
        this.http = http;
    }
    saveBlockPageSettings(settings) {
        return this.http.post('/api/swg/update-block-page-settings', settings);
    }
    getBlockPageSettings() {
        return this.http.post('/api/swg/get-block-page-settings', null);
    }
}
exports.SwgBlockPageApiService = SwgBlockPageApiService;
