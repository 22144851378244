"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CyberGraphPoliciesListImplService = void 0;
const operators_1 = require("rxjs/operators");
const rxjs_1 = require("rxjs");
const table_store_1 = require("@mimecast-ui/table-store");
class CyberGraphPoliciesListImplService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    static getDataFromResponse(response) {
        return {
            data: response.all[0].policies,
            meta: response.meta,
            hasErrors: response.hasErrors,
            failures: response.fail
        };
    }
    getData(payload, pagination = table_store_1.defaultPaginationOption) {
        return this.httpClient
            .post('/api/policy/cybergraph/get-policies', Object.assign(Object.assign({}, payload), { meta: { pagination } }))
            .pipe(operators_1.map((response) => {
            return CyberGraphPoliciesListImplService.getDataFromResponse(response);
        }), operators_1.catchError((error) => {
            return rxjs_1.of(CyberGraphPoliciesListImplService.getDataFromResponse(error.error));
        }));
    }
    deletePolicy(id) {
        return this.httpClient.post('/api/policy/cybergraph/delete-policy', id);
    }
    filter(query, pagination) {
        return this.getData(query, pagination);
    }
}
exports.CyberGraphPoliciesListImplService = CyberGraphPoliciesListImplService;
