"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogBuilder = void 0;
const _1 = require("./");
const log_base_builder_1 = require("./log-base.builder");
class LogBuilder extends log_base_builder_1.LogBaseBuilder {
    self() {
        return this;
    }
    build() {
        return new _1.Log(this.id, this.senderAddress, this.recipientAddress, this.subject, this.dateSent, this.status, this.expiry);
    }
}
exports.LogBuilder = LogBuilder;
