"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GatewaySummaryStepComponent = void 0;
const core_1 = require("@angular/core");
const operators_1 = require("rxjs/operators");
const reducers_1 = require("../../../../../../reducers");
class GatewaySummaryStepComponent {
    constructor(fb, store) {
        this.fb = fb;
        this.store = store;
        this.roles = [];
        this.products = [];
        this.onApplicationStatusUpdated = new core_1.EventEmitter();
        this.onGotoStep = new core_1.EventEmitter();
        this.form = this.fb.group({
            active: ['']
        });
    }
    ngOnInit() {
        this.form.patchValue(this.applicationStatus || {});
        this.formSubscription = this.form.valueChanges.pipe(operators_1.debounceTime(200)).subscribe(() => {
            this.update();
        });
        this.rolesSubscription = this.store.select(reducers_1.getApiGatewayV3Roles).subscribe(roles => {
            this.roles = roles;
        });
        this.productsSubscription = this.store.select(reducers_1.getApiGatewayV3Products).subscribe(products => {
            this.products = products;
        });
    }
    update() {
        this.onApplicationStatusUpdated.emit(this.form.value);
    }
    gotoDetails() {
        this.onGotoStep.emit(2);
    }
    gotoSettings() {
        this.onGotoStep.emit(3);
    }
    ngOnDestroy() {
        this.formSubscription.unsubscribe();
        this.rolesSubscription.unsubscribe();
    }
    get roleName() {
        const role = this.roles.find(r => r.value === this.application.roleId);
        return (role && role.label) || '-';
    }
    get productsName() {
        let products = '';
        if (this.application.gatewayAppProducts) {
            this.products.forEach(product => {
                this.application.gatewayAppProducts.forEach((selectedProduct, index) => {
                    if (selectedProduct === product.value) {
                        products = products + product.label;
                        if (this.application.gatewayAppProducts.length !== index + 1) {
                            products = products + ', ';
                        }
                    }
                });
            });
        }
        return products;
    }
}
exports.GatewaySummaryStepComponent = GatewaySummaryStepComponent;
