"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActivityWaitOverlayComponent = void 0;
class ActivityWaitOverlayComponent {
    constructor(config) {
        this.config = config;
    }
    get title() {
        return this.config.title;
    }
    get description() {
        return this.config.description;
    }
    get hasSources() {
        return !!this.config.sources && this.config.sources.length > 0;
    }
    get sources() {
        return this.config.sources;
    }
}
exports.ActivityWaitOverlayComponent = ActivityWaitOverlayComponent;
