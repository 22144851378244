"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreatePolicyComponent = void 0;
const reducers = require("../../reducers");
const actions = require("../../actions/policy.actions");
const policy_actions_1 = require("../../actions/policy.actions");
class CreatePolicyComponent {
    constructor(store) {
        this.store = store;
    }
    ngOnInit() {
        this.policy$ = this.store.select(reducers.getApPolicy);
        this.wizardFlowType$ = this.store.select(reducers.getWizardFlowType);
        this.store.dispatch(new policy_actions_1.ApGetListOfAttributes());
        this.attributeOptions$ = this.store.select(reducers.getApAttributes);
    }
    createApPolicy($event) {
        this.store.dispatch(new actions.ApCreatePolicyAction($event));
    }
    updateApPolicy($event) {
        this.store.dispatch(new actions.ApUpdatePolicyAction($event));
    }
    updateApPolicyDescType($event) {
        this.store.dispatch(new actions.ApUpdatePolicyDetailsStep($event));
    }
    updateApPolicyInboundType($event) {
        this.store.dispatch(new actions.ApUpdatePolicyInboundStep($event));
    }
    updateApPolicyJournalType($event) {
        this.store.dispatch(new actions.ApUpdatePolicyJournalStep($event));
    }
    updateApPolicyOutboundType($event) {
        this.store.dispatch(new actions.ApUpdatePolicyOutboundStep($event));
    }
    updateApSummaryType($event) {
        this.store.dispatch(new actions.ApUpdatePolicySummaryStep($event));
    }
    updateApPolicyAppliesType($event) {
        this.store.dispatch(new actions.ApUpdatePolicyAppliesToStep($event));
    }
    openAppliesToSidePanel(value) {
        this.store.dispatch(new actions.ApPolicyAdministratorClose());
        this.store.dispatch(new actions.ApPolicyAdministratorOpen(value));
    }
    resetWizard() {
        this.store.dispatch(new actions.ApClearPolicyAction());
        this.store.dispatch(new actions.ApPolicyNavigateToListPageAction());
    }
}
exports.CreatePolicyComponent = CreatePolicyComponent;
