"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IncidentSummaryComponent = void 0;
const incident_1 = require("../../../models/incident");
const constants_1 = require("../../../utils/constants");
class IncidentSummaryComponent {
    constructor(translateService) {
        this.translateService = translateService;
        this.noValuePlaceHolder = constants_1.emptyValueDecorator;
    }
    getTranslatedTypeName(key) {
        const translatedKey = incident_1.IncidentsTypesDictionary[key];
        const translationRoot = '$I18N_REMEDIATION_INCIDENT_VIEWER_SUMMARY.INCIDENT_TYPE.';
        let translatedStr = this.translateService.instant(translationRoot + translatedKey);
        if (this.incidentSummary.removeFromDevice) {
            translatedStr =
                translatedStr +
                    this.translateService.instant('$I18N_REMEDIATION_INCIDENT_TAB.INCIDENT_TYPE.DEVICE');
        }
        return translatedStr;
    }
    getFailureSummary() {
        if (this.failureSummary && this.failureSummary.failureSummary) {
            return Object.keys(this.failureSummary.failureSummary).filter(key => constants_1.NO_ERROR_FAILURE_CODE !== key);
        }
        return undefined;
    }
    getMessageIds() {
        return this.incidentSummary.searchCriteria.messageIds;
    }
}
exports.IncidentSummaryComponent = IncidentSummaryComponent;
