"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiIntegrationCortexComponent = void 0;
const operators_1 = require("rxjs/operators");
const reducers = require("../../../reducers");
const actions = require("../../../actions");
class ApiIntegrationCortexComponent {
    constructor(store, modalService, translateService, capabilitiesService, locationService) {
        this.store = store;
        this.modalService = modalService;
        this.translateService = translateService;
        this.capabilitiesService = capabilitiesService;
        this.locationService = locationService;
        this.hasErrorCapabilityAndUrl = false;
        this.cancelModalTitle = '';
    }
    ngOnInit() {
        this.cortexState$ = this.store.select(reducers.getIntegrationCreateCortexState);
        this.availableProviders$ = this.store.select(reducers.getIntegrationCreateAvailableProviders);
        this.selectedProvider$ = this.store.select(reducers.getIntegrationCreateSelectedProvider);
        this.cortexConfiguration$ = this.store.select(reducers.getIntegrationCreateCortexConfiguration);
        this.cortexCreated$ = this.store.select(reducers.isIntegrationCreateCortexCreated);
        this.cortexUpdated$ = this.store.select(reducers.isIntegrationCreateCortexUpdated);
        this.cortexIntegrationVersion1$ = this.store.select(reducers.getIntegrationCreateCortexIntegrationVersion1);
        this.cortexSpywareSettings$ = this.store.select(reducers.getIntegrationCreateSpywareSettings);
        this.cortexWildfireSettings$ = this.store.select(reducers.getIntegrationCreateWildfireSettings);
        this.isBusy$ = this.store.select(reducers.isIntegrationCreateBusy);
        this.isChanged$ = this.store.select(reducers.isIntegrationChanged);
        this.isReady$ = this.store.select(reducers.getIntegrationCreateCortexIntegrationVersion1).pipe(operators_1.filter(value => !!value), operators_1.map(() => true));
        this.emails$ = this.store.select(reducers.getIntegrationCreateCortexEmails);
        this.groupsList$ = this.store.select(reducers.getGroups);
        this.cortexFresh$ = this.store.select(reducers.isIntegrationCreateCortexFresh);
        if (this.cortexState === 'initiate') {
            this.store.dispatch(new actions.GetCortexIntegrationByExternalIdAndTypeAction({
                externalId: this.getDecodedParamFromUrlLastSegment('instance_id')
            }));
        }
        else if (this.cortexState === 'authorise') {
            this.store.dispatch(new actions.AuthoriseCortexIntegrationCompleteAction({
                provider: this.provider,
                config: this.config
            }));
        }
        this.cortexState$.subscribe(state => {
            if (state === 'edit' || state === 'updated') {
                this.cancelModalTitle = this.translateService.instant('$I18N_API_APPLICATIONS.MODALS.CANCEL_MODAL.CONTENT.EDIT_MODE');
            }
            else {
                this.cancelModalTitle = this.translateService.instant('$I18N_API_APPLICATIONS.MODALS.CANCEL_MODAL.CONTENT.INITIATE_MODE');
            }
        });
        this.capabilitiesService
            .hasCapability('Temporary.Services.ApiApplications.Integration.OnDemandFailRequests')
            .subscribe(isActive => {
            if (isActive === true &&
                this.locationService.hash.includes('hasError=update-integration')) {
                this.hasErrorCapabilityAndUrl = true;
            }
        });
    }
    cancel() {
        this.modalService
            .open({
            content: this.cancelModalTitle,
            hideHeader: true,
            buttons: [
                {
                    title: this.translateService.instant('$I18N_API_APPLICATIONS.MODALS.CANCEL_MODAL.BUTTONS.NO'),
                    type: 'default',
                    id: 'no'
                },
                {
                    title: this.translateService.instant('$I18N_API_APPLICATIONS.MODALS.CANCEL_MODAL.BUTTONS.YES'),
                    type: 'primary',
                    id: 'yes'
                }
            ]
        })
            .pipe(operators_1.first(), operators_1.filter((result) => result === 'yes'), operators_1.tap(() => this.store.dispatch(new actions.CancelCreateIntegrationAction())))
            .subscribe();
    }
    complete() {
        this.store.dispatch(new actions.CloseCreateIntegrationAction());
    }
    providerSelected(provider) {
        this.store.dispatch(new actions.SelectIntegrationProviderAction({ provider }));
    }
    deleteEmail(email) {
        this.store.dispatch(new actions.DeleteEmailCortexIntegrationNotificationsAction({ email }));
    }
    deleteGroup(selectedGroup) {
        this.store.dispatch(new actions.DeleteGroupCortexIntegrationNotificationsAction(selectedGroup));
    }
    addGroup(selectedGroup) {
        this.store.dispatch(new actions.AddGroupCortexIntegrationNotificationsAction(selectedGroup));
    }
    verifyAndAddEmail(email) {
        this.store.dispatch(new actions.VerifyAndAddEmailCortexIntegrationNotificationsAction({ email }));
    }
    updateSpywareSettings(spywareSettings) {
        this.store.dispatch(new actions.UpdateCortexIntegrationSpywareSettingsAction(spywareSettings));
    }
    updateWildfireSettings(wildfireSettings) {
        this.store.dispatch(new actions.UpdateCortexIntegrationWildfireSettingsAction(wildfireSettings));
    }
    createCortexIntegrationVersion1() {
        this.store.dispatch(new actions.CreateCortexIntegrationVersion1Action({
            lakeSerialNumber: this.getDecodedParamFromUrlLastSegment('lsn'),
            integrationName: this.getDecodedParamFromUrlLastSegment('instance_name'),
            instanceId: this.getDecodedParamFromUrlLastSegment('instance_id'),
            region: this.getDecodedParamFromUrlLastSegment('region')
        }));
    }
    updateCortexIntegrationVersion1() {
        if (this.hasErrorCapabilityAndUrl) {
            this.store.dispatch(new actions.UpdateCortexIntegrationVersion1Action({
                hasError: 'update-integration'
            }));
        }
        else {
            this.store.dispatch(new actions.UpdateCortexIntegrationVersion1Action());
        }
    }
    authoriseCortexIntegrationVersion1() {
        this.store.dispatch(new actions.AuthoriseCortexIntegrationVersion1Action());
    }
    integrationStatusUpdated(status) {
        if (!!status) {
            if (status.enabled) {
                this.store.dispatch(new actions.EnableIntegrationAction({
                    integration: { id: status.id, integrationType: 'cortex' }
                }));
            }
            else {
                this.store.dispatch(new actions.DisableIntegrationAction({
                    integration: { id: status.id, integrationType: 'cortex' }
                }));
            }
        }
    }
    getDecodedParamFromUrlLastSegment(givenParam) {
        const urlParts = this.locationService.hash.split('/');
        const lastUrlSegment = urlParts.pop();
        const decodedLastUrlSegment = this.b64DecodeUnicode(lastUrlSegment);
        const decodedSegments = decodedLastUrlSegment.split('&');
        const givenParamAndEqual = `${givenParam}=`;
        return decodedSegments
            .find((param) => param.startsWith(givenParamAndEqual))
            .replace(givenParamAndEqual, '');
    }
    b64DecodeUnicode(str) {
        return decodeURIComponent(atob(str)
            .split('')
            .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
            .join(''));
    }
    ngOnDestroy() { }
}
exports.ApiIntegrationCortexComponent = ApiIntegrationCortexComponent;
