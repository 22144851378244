<mc-wizard-step-header
  compact="true"
  label="$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.ACTIVATION.HEADER"
>
</mc-wizard-step-header>

<ol class="panel-padding-bottom fist-level">
  <li>
    {{ '$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.ACTIVATION.SUBHEADER_LIST_1' | translate }}
  </li>
  <li>
    {{ '$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.ACTIVATION.SUBHEADER_LIST_2' | translate }}
  </li>
  <li>
    {{ '$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.ACTIVATION.SUBHEADER_LIST_3' | translate }}
  </li>
  <li>
    {{ '$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.ACTIVATION.SUBHEADER_LIST_4' | translate }}
  </li>
  <li>
    {{ '$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.ACTIVATION.SUBHEADER_LIST_5' | translate }}
  </li>
  <li>
    {{ '$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.ACTIVATION.SUBHEADER_LIST_6' | translate }}
    <ol class="second-level">
      <li>okta.groups.read</li>
      <li>okta.groups.manage</li>
      <li>okta.users.read</li>
    </ol>
  </li>
  <li>
    {{ '$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.ACTIVATION.SUBHEADER_LIST_7' | translate }}
  </li>
  <li>
    {{ '$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.ACTIVATION.SUBHEADER_LIST_8' | translate }}
  </li>
</ol>

<form
  class="form-horizontal"
  mcDefaultLabelClass="col-sm-4"
  mcDefaultControlContainerClass="col-sm-8"
  [formGroup]="form"
>
  <div *ngIf="hasCreatedIntegration">
    <br/>
    <mc-inline-notification
      [notification]="{
        status: 'error',
        boxed: true,
        msg: '$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.ACTIVATION.WARNING' | translate
      }"
    >
    </mc-inline-notification>
  </div>
  <mc-text-field
    [label]="'$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.ACTIVATION.CLIENT_ID' | translate"
    formControlName="clientId"
    required
    errorPrefix="$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.ACTIVATION.ERRORS"
  >
  </mc-text-field>

  <mc-text-area-field
    class="blur-text-area"
    label="{{'$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.ACTIVATION.PRIVATE_KEY' | translate }}"
    formControlName="privateKey"
    data-test="privateKey"
    required
    errorPrefix="$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.ACTIVATION.ERRORS"
  ></mc-text-area-field>

  <mc-text-field
    [label]="'$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.ACTIVATION.BASE_URL' | translate"
    formControlName="baseUrl"
    required
    errorPrefix="$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.ACTIVATION.ERRORS"
  >
  </mc-text-field>

  <div class="col-sm-offset-4 verify-btn-container">
    <button
      [disabled]="isDisabled"
      (click)="verifyOktaApiKey()"
      type="submit"
      class="btn btn-primary"
    >
      {{ '$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.ACTIVATION.AUTHORIZE' | translate }}
    </button>
  </div>
</form>
