<mc-layout-aside-extra-container
  keyTitle="$I18N_SWG_CREATE_POLICY_POLICY_DETAILS"
  showClose="true"
  [isLoading]="loadingForEdit"
  (closeAside)="close.emit()"
>
  <mc-extra-container>
    <div class="mc-container-actions-area">
      <div [ngClass]="{ 'mc-container-buttons' : hasEditPermission && canDisablePolicy(policy.policyDesc.policyType) }">
        <button class="btn btn-primary panel-half-margin-right" (click)="onEditClick()">
          {{ '$I18N_COMMON_BTN_EDIT' | translate }}
        </button>
        <button class="btn btn-primary panel-half-margin-right" *ngIf="showDuplicateOption()"
                (click)="onDuplicateClick()">
          {{ '$I18N_COMMON_BTN_DUPLICATE' | translate }}
        </button>
        <button class="btn btn-secondary panel-half-margin-right" (click)="onDeleteClick()"
                *ngIf="showDeleteOption()">
          {{ '$I18N_COMMON_BTN_DELETE' | translate }}
        </button>
      </div>
      <div *ngIf="hasEditPermission && canDisablePolicy(policy.policyDesc.policyType)" class="mc-container-switch">
        <form novalidate [formGroup]="enabledForm">
          <mc-enable-switch formControlName="isEnabled"></mc-enable-switch>
        </form>
      </div>
    </div>
  </mc-extra-container>
  <mc-body-container>
    <div>
      <mc-swg-policy-summary
        *ngIf="!categorySectionsLoading && !loadingForEdit"
        [policy]="policy"
        [hasSwgHybridEnabled]="hasSwgHybrid"
        [hasBrowserIsolationEnabled]="hasBrowserIsolationEnabled"
        [webCategoryRulesSections]="webCategoryRulesSections"
        [applications]="applications"
        [hasAppPolicyRemoveAllowEnabled]="hasAppPolicyRemoveAllowEnabled"
        [protectedApplications]="protectedApplications"
        [isSidebar]="true"
      ></mc-swg-policy-summary>
    </div>
  </mc-body-container>
</mc-layout-aside-extra-container>
