"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgPolicyClearAdvancedFileTypes = exports.SwgPolicyLoadAdvancedFileTypesFailure = exports.SwgPolicyLoadAdvancedFileTypesSuccess = exports.SwgPolicyLoadAdvancedFileTypes = exports.SWG_POLICY_CLEAR_ADVANCED_FILE_TYPES = exports.SWG_POLICY_LOAD_ADVANCED_FILE_TYPES_FAILURE = exports.SWG_POLICY_LOAD_ADVANCED_FILE_TYPES_SUCCESS = exports.SWG_POLICY_LOAD_ADVANCED_FILE_TYPES = void 0;
exports.SWG_POLICY_LOAD_ADVANCED_FILE_TYPES = '[Policy] Swg policy load advanced file types';
exports.SWG_POLICY_LOAD_ADVANCED_FILE_TYPES_SUCCESS = '[Policy] Swg policy load advanced file types success';
exports.SWG_POLICY_LOAD_ADVANCED_FILE_TYPES_FAILURE = '[Policy] Swg policy load advanced file types failure';
exports.SWG_POLICY_CLEAR_ADVANCED_FILE_TYPES = '[Policy] Swg policy clear advanced file types';
class SwgPolicyLoadAdvancedFileTypes {
    constructor() {
        this.type = exports.SWG_POLICY_LOAD_ADVANCED_FILE_TYPES;
    }
}
exports.SwgPolicyLoadAdvancedFileTypes = SwgPolicyLoadAdvancedFileTypes;
class SwgPolicyLoadAdvancedFileTypesSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SWG_POLICY_LOAD_ADVANCED_FILE_TYPES_SUCCESS;
    }
}
exports.SwgPolicyLoadAdvancedFileTypesSuccess = SwgPolicyLoadAdvancedFileTypesSuccess;
class SwgPolicyLoadAdvancedFileTypesFailure {
    constructor() {
        this.type = exports.SWG_POLICY_LOAD_ADVANCED_FILE_TYPES_FAILURE;
    }
}
exports.SwgPolicyLoadAdvancedFileTypesFailure = SwgPolicyLoadAdvancedFileTypesFailure;
class SwgPolicyClearAdvancedFileTypes {
    constructor() {
        this.type = exports.SWG_POLICY_CLEAR_ADVANCED_FILE_TYPES;
    }
}
exports.SwgPolicyClearAdvancedFileTypes = SwgPolicyClearAdvancedFileTypes;
