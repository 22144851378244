"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpamDetectionDisplayPipe = void 0;
class SpamDetectionDisplayPipe {
    constructor(translateService) {
        this.translateService = translateService;
    }
    transform(spamDetection = '') {
        switch (spamDetection.toLowerCase()) {
            case 'relaxed':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_SPAM_DETECTION.RELAXED');
            case 'moderate':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_SPAM_DETECTION.MODERATE');
            case 'aggressive':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_SPAM_DETECTION.AGGRESSIVE');
            case 'cluster':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_SPAM_DETECTION.NO_POLICY');
            case 'whitelisted_cluster':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_SPAM_DETECTION.WHITELISTED_CLUSTER');
            case 'outbound':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_SPAM_DETECTION.OUTBOUND');
            default:
                return '';
        }
    }
}
exports.SpamDetectionDisplayPipe = SpamDetectionDisplayPipe;
