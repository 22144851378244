<mc-layout-detail-wizard keyTitle="{{ pageTitle }}">
  <mc-tab-group *ngIf="isEditing" [direction]="'vertical'">
    <mc-tab name="{{ '$I18N_API_GATEWAY_SAVE_WIZARD.EDIT.DETAILS_STEP.TITLE' | translate }}">
      <mc-api-gateway-save-details-step
        [applicationDetails]="applicationDetails"
        [applicationNameDisabled]="applicationNameDisabled"
        [mode]="mode"
        (onApplicationDetailsUpdated)="updateDetails($event)"
        (onSubmitted)="nextStep()"
      >
      </mc-api-gateway-save-details-step>
    </mc-tab>

    <mc-tab name="{{ '$I18N_API_GATEWAY_SAVE_WIZARD.EDIT.SETTINGS_STEP.TITLE' | translate }}">
      <mc-api-gateway-save-settings-step
        [applicationSettings]="applicationSettings"
        [mode]="mode"
        (onApplicationSettingsUpdated)="updateSettings($event)"
        (onSubmitted)="nextStep()"
      >
      </mc-api-gateway-save-settings-step>
    </mc-tab>
  </mc-tab-group>

  <mc-wizard *ngIf="isCreating" (changeStep)="changeStep()">
    <mc-wizard-step label="$I18N_API_GATEWAY_SAVE_WIZARD.CREATE.DETAILS_STEP.TITLE">
      <mc-api-gateway-save-details-step
        [applicationDetails]="applicationDetails"
        [applicationNameDisabled]="applicationNameDisabled"
        [mode]="mode"
        (onApplicationDetailsUpdated)="updateDetails($event)"
        (onSubmitted)="nextStep()"
      >
      </mc-api-gateway-save-details-step>
    </mc-wizard-step>

    <mc-wizard-step label="$I18N_API_GATEWAY_SAVE_WIZARD.CREATE.SETTINGS_STEP.TITLE">
      <mc-api-gateway-save-settings-step
        [applicationSettings]="applicationSettings"
        [mode]="mode"
        (onApplicationSettingsUpdated)="updateSettings($event)"
        (onSubmitted)="nextStep()"
      >
      </mc-api-gateway-save-settings-step>
    </mc-wizard-step>

    <mc-wizard-step label="$I18N_API_GATEWAY_SAVE_WIZARD.CREATE.SUMMARY_STEP.TITLE">
      <mc-api-gateway-save-summary-step
        [applicationStatus]="applicationStatus"
        [application]="application"
        (onApplicationStatusUpdated)="updateStatus($event)"
        (onGotoStep)="gotoStep($event)"
      >
      </mc-api-gateway-save-summary-step>
    </mc-wizard-step>
  </mc-wizard>

  <mc-footer-container>
    <div class="pull-right no-select mc-button-footer">
      <button class="btn" (click)="cancel()">
        {{ '$I18N_API_GATEWAY_SAVE_WIZARD.ACTIONS.CANCEL' | translate }}
      </button>
      <ng-container *ngIf="isCreating">
        <button
          *ngIf="hasPreviousStep"
          class="btn btn-secondary panel-half-margin-right"
          (click)="gotoPreviousStep()"
        >
          {{ '$I18N_API_GATEWAY_SAVE_WIZARD.ACTIONS.BACK' | translate }}
        </button>
        <button
          *ngIf="hasNextStep"
          class="btn btn-primary"
          [disabled]="!isStepValid()"
          (click)="gotoNextStep()"
        >
          {{ '$I18N_API_GATEWAY_SAVE_WIZARD.ACTIONS.NEXT' | translate }}
        </button>
        <mc-live-button
          *ngIf="isLastStep"
          [mcDisabled]="!isValid()"
          typeClass="mc-btn btn btn-primary primary"
          content="{{ '$I18N_API_GATEWAY_SAVE_WIZARD.ACTIONS.ADD' | translate }}"
          (click)="save()"
        >
        </mc-live-button>
      </ng-container>

      <ng-container *ngIf="isEditing">
        <mc-live-button
          typeClass="mc-btn btn btn-primary primary"
          [mcDisabled]="!isValid() || !changed"
          content="{{ '$I18N_API_GATEWAY_SAVE_WIZARD.ACTIONS.UPDATE' | translate }}"
          (click)="save()"
        >
        </mc-live-button>
      </ng-container>
    </div>
  </mc-footer-container>
</mc-layout-detail-wizard>
