"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiIntegrationOktaSettingsStepComponent = void 0;
const core_1 = require("@angular/core");
const okta_group_selector_component_1 = require("../../../okta-group-selector/okta-group-selector.component");
const folder_helper_1 = require("app-new/api-applications/utils/folder-helper");
class ApiIntegrationOktaSettingsStepComponent {
    constructor(fb, asideService, translateService) {
        this.fb = fb;
        this.asideService = asideService;
        this.translateService = translateService;
        this.valuesChanged = false;
        this.onOktaCellSelect = new core_1.EventEmitter();
        this.form = this.fb.group({
            oktaCell: ''
        });
    }
    ngOnInit() {
        if (!!this.cell) {
            this.form.patchValue({
                oktaCell: this.cell
            });
        }
        this.oktaCellFieldSubscription = this.form
            .get('oktaCell')
            .valueChanges.subscribe(oktaCellField => {
            this.onOktaCellSelect.emit(oktaCellField);
        });
    }
    get description() {
        return folder_helper_1.default.createDescription(this.folders, this.rootFolder);
    }
    get cellOptions() {
        return [
            {
                value: '',
                label: this.translateService.instant('$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.SETTINGS.SELECT_THE_CELL')
            },
            {
                value: 'US_CELL_1',
                label: this.translateService.instant('$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.SETTINGS.CELL_OPTIONS.US_CELL_1')
            },
            {
                value: 'US_CELL_2',
                label: this.translateService.instant('$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.SETTINGS.CELL_OPTIONS.US_CELL_2')
            },
            {
                value: 'US_CELL_3',
                label: this.translateService.instant('$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.SETTINGS.CELL_OPTIONS.US_CELL_3')
            },
            {
                value: 'US_CELL_4',
                label: this.translateService.instant('$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.SETTINGS.CELL_OPTIONS.US_CELL_4')
            },
            {
                value: 'US_CELL_5',
                label: this.translateService.instant('$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.SETTINGS.CELL_OPTIONS.US_CELL_5')
            },
            {
                value: 'US_CELL_6',
                label: this.translateService.instant('$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.SETTINGS.CELL_OPTIONS.US_CELL_6')
            },
            {
                value: 'US_CELL_7',
                label: this.translateService.instant('$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.SETTINGS.CELL_OPTIONS.US_CELL_7')
            },
            {
                value: 'APAC_CELL_1',
                label: this.translateService.instant('$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.SETTINGS.CELL_OPTIONS.APAC_CELL_1')
            },
            {
                value: 'US_CELL_10',
                label: this.translateService.instant('$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.SETTINGS.CELL_OPTIONS.US_CELL_10')
            },
            {
                value: 'US_CELL_11',
                label: this.translateService.instant('$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.SETTINGS.CELL_OPTIONS.US_CELL_11')
            },
            {
                value: 'US_CELL_12',
                label: this.translateService.instant('$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.SETTINGS.CELL_OPTIONS.US_CELL_12')
            },
            {
                value: 'EMEA_CELL_1',
                label: this.translateService.instant('$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.SETTINGS.CELL_OPTIONS.EMEA_CELL_1')
            },
            {
                value: 'EMEA_CELL_2',
                label: this.translateService.instant('$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.SETTINGS.CELL_OPTIONS.EMEA_CELL_2')
            },
            {
                value: 'PREVIEW_CELL_1',
                label: this.translateService.instant('$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.SETTINGS.CELL_OPTIONS.PREVIEW_CELL_1')
            },
            {
                value: 'PREVIEW_CELL_2',
                label: this.translateService.instant('$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.SETTINGS.CELL_OPTIONS.PREVIEW_CELL_2')
            },
            {
                value: 'PREVIEW_CELL_3',
                label: this.translateService.instant('$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.SETTINGS.CELL_OPTIONS.PREVIEW_CELL_3')
            }
        ];
    }
    openSidebar() {
        this.asideService.open(okta_group_selector_component_1.OktaGroupSelectorComponent, {});
    }
    update() { }
    isValid() {
        return !!this.rootFolder && this.form.value.oktaCell !== '';
    }
}
exports.ApiIntegrationOktaSettingsStepComponent = ApiIntegrationOktaSettingsStepComponent;
