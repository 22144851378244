<div class="mc-wem-title">
  <mc-spinner></mc-spinner>{{ '$I18N_MESSAGE_CENTER_PURGE_MESSAGES_SEARCH_MODAL.TITLE' | translate }}
</div>
<div class="text-normal panel-margin-top">{{ '$I18N_MESSAGE_CENTER_PURGE_MESSAGES_SEARCH_MODAL.SUBTITLE' | translate }}</div>
<mc-modal-footer class="no-padding-bottom no-padding-right">
  <button
    type='button'
    class="btn btn-secondary no-border" (click)="cancel()">{{
    '$I18N_MESSAGE_CENTER_PURGE_MESSAGES_SEARCH_MODAL.CANCEL' | translate
    }}</button>
</mc-modal-footer>
