"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateUpdateUrlEffects = void 0;
const effects_1 = require("@ngrx/effects");
const table_store_1 = require("@mimecast-ui/table-store");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const reducers = require("../../reducers/index");
const createActions = require("../../actions/url-create.actions");
const logsSideActions = require("../../../logs/actions/logs-side-panel.actions");
const listActions = require("../../actions/url-list.actions");
const updateActions = require("../../actions/url-update.actions");
const url_confirmation_dialog_component_1 = require("../../components/url-confirmation-dialog/url-confirmation-dialog.component");
const url_create_types_enum_1 = require("../../common/enums/url-create-types.enum");
const MANAGED_URLS_TABLE_ID = 'managedUrlsList';
const LOGS_URLS_TABLE_ID = 'urlProtectionLogsList';
class CreateUpdateUrlEffects {
    constructor(actions$, notificationService, store, managedUrlService, translateService, modalService, tableStore) {
        this.actions$ = actions$;
        this.notificationService = notificationService;
        this.store = store;
        this.managedUrlService = managedUrlService;
        this.translateService = translateService;
        this.modalService = modalService;
        this.tableStore = tableStore;
        this.create$ = this.actions$.pipe(effects_1.ofType(createActions.CREATE_MANAGED_URL), operators_1.withLatestFrom(this.store.select(reducers.getManagedUrlState)), operators_1.switchMap(([action, data]) => {
            return this.managedUrlService.createNewManagedUrl(data.items, data.selectedRow).pipe(operators_1.map((payload) => {
                return payload
                    ? action['status'] === url_create_types_enum_1.UrlCreateTypes.CREATE
                        ? new createActions.CreateManagedUrlSuccessAction(payload, action['status'])
                        : new createActions.AllowBlockManagedUrlSuccessAction(payload, action['status'])
                    : new createActions.CreateManagedUrlAttemptAction(payload, action['status']);
            }), operators_1.catchError(err => {
                if (err.fail[0].errors[0].code === 'err_managed_url_exists_code') {
                    return rxjs_1.of(new createActions.CreateManagedUrlAttemptAction(data.items, action['status'], err.fail));
                }
                else {
                    return rxjs_1.of(new createActions.CreateManagedUrlFail(err));
                }
            }));
        }));
        this.createSuccess$ = this.actions$.pipe(effects_1.ofType(createActions.CREATE_MANAGED_URL_SUCCESS), operators_1.withLatestFrom(this.tableStore.select(table_store_1.tableReducer.getCurrentFilters(MANAGED_URLS_TABLE_ID)), this.tableStore.select(table_store_1.tableReducer.getCurrentPagination(MANAGED_URLS_TABLE_ID))), operators_1.mergeMap(([action, currentFilters, currentPagination]) => {
            return [
                new createActions.CloseUrlSidePanelAction(),
                this.notificationService.newNotification({
                    type: 'success',
                    config: {
                        msg: this.chooseNotificationMsg(action['status'])
                    }
                }),
                this.chooseTableToRefresh(action['status'], currentPagination, currentFilters),
                new createActions.ClearAddUrlState()
            ];
        }));
        this.allowBlockUrlSuccess$ = this.actions$.pipe(effects_1.ofType(createActions.ALLOW_BLOCK_MANAGED_URL_SUCCESS), operators_1.withLatestFrom(this.tableStore.select(table_store_1.tableReducer.getCurrentFilters(LOGS_URLS_TABLE_ID)), this.tableStore.select(table_store_1.tableReducer.getCurrentPagination(LOGS_URLS_TABLE_ID))), operators_1.mergeMap(([action, currentFilters, currentPagination]) => {
            return [
                new logsSideActions.CloseLogsSidePanelAction(),
                this.notificationService.newNotification({
                    type: 'success',
                    config: {
                        msg: this.chooseNotificationMsg(action['status'])
                    }
                }),
                new table_store_1.LoadAction({
                    tableId: LOGS_URLS_TABLE_ID,
                    pagination: { pageToken: '', pageSize: currentPagination.pageSize, pageNumber: 0 },
                    query: currentFilters
                }),
                new createActions.ClearAddUrlState()
            ];
        }));
        this.createFail$ = this.actions$.pipe(effects_1.ofType(createActions.CREATE_MANAGED_URL_FAIL), operators_1.withLatestFrom(this.store.select(reducers.getManagedUrlData)), operators_1.switchMap(([action]) => {
            return [
                new createActions.CloseUrlSidePanelAction(),
                this.notificationService.newNotification({
                    type: 'error',
                    config: {
                        msg: action['err']['fail'][0]['errors'][0]['message']
                    }
                })
            ];
        }));
        this.createUrlAttempt$ = this.actions$.pipe(effects_1.ofType(createActions.CREATE_MANAGED_URL_ATTEMPT), operators_1.withLatestFrom(this.store.select(reducers.getManagedUrlState)), operators_1.switchMap(([action, data]) => {
            return this.modalService
                .open(url_confirmation_dialog_component_1.UrlConfirmationModalComponent, {
                data: {
                    type: 'OVERRIDE',
                    errors: [...data.errors]
                }
            })
                .afterClose()
                .pipe(operators_1.map((result) => result
                ? new createActions.CreateManagedUrlAction(data.items, action['status'])
                : new listActions.ManagedUrlOverrideDialogCancel()));
        }));
        this.updateUrlAttempt$ = this.actions$.pipe(effects_1.ofType(updateActions.UPDATE_MANAGED_URL_ATTEMPT), operators_1.withLatestFrom(this.store.select(reducers.getManagedUrlState)), operators_1.switchMap(([, data]) => {
            return this.modalService
                .open(url_confirmation_dialog_component_1.UrlConfirmationModalComponent, {
                data: {
                    type: 'OVERRIDE'
                }
            })
                .afterClose()
                .pipe(operators_1.map((result) => result
                ? new updateActions.UpdateManagedUrlAction(data.selectedRow)
                : new listActions.ManagedUrlOverrideDialogCancel()));
        }));
        this.updateUrl$ = this.actions$.pipe(effects_1.ofType(updateActions.UPDATE_MANAGED_URL), operators_1.withLatestFrom(this.store.select(reducers.getManagedUrlState)), operators_1.switchMap(([, data]) => {
            return this.managedUrlService.updateManagedUrl(data.selectedRow).pipe(operators_1.map((payload) => {
                return payload
                    ? new updateActions.UpdateManagedUrlSuccessAction(payload)
                    : new updateActions.UpdateManagedUrlAttemptAction(payload);
            }), operators_1.catchError(err => {
                if (err.fail[0].errors[0].code === 'err_managed_url_exists_code') {
                    return rxjs_1.of(new updateActions.UpdateManagedUrlAttemptAction(data.selectedRow));
                }
                else {
                    return rxjs_1.of(new updateActions.UpdateManagedUrlFail(err));
                }
            }));
        }));
        this.updateUrlSuccess$ = this.actions$.pipe(effects_1.ofType(updateActions.UPDATE_MANAGED_URL_SUCCESS), operators_1.withLatestFrom(this.tableStore.select(table_store_1.tableReducer.getCurrentFilters(MANAGED_URLS_TABLE_ID)), this.tableStore.select(table_store_1.tableReducer.getCurrentPagination(MANAGED_URLS_TABLE_ID))), operators_1.mergeMap(([, currentFilters, currentPagination]) => {
            return [
                new createActions.CloseUrlSidePanelAction(),
                this.notificationService.newNotification({
                    type: 'success',
                    config: {
                        msg: this.translateService.instant('$I18N_SERVICES_URL_PROTECTION_MANAGED_URL_UPDATE_URL_SUCCESS_MSG')
                    }
                }),
                new table_store_1.LoadAction({
                    tableId: MANAGED_URLS_TABLE_ID,
                    pagination: { pageToken: '', pageSize: currentPagination.pageSize, pageNumber: 0 },
                    query: currentFilters
                })
            ];
        }));
        this.updateUrlFail$ = this.actions$.pipe(effects_1.ofType(updateActions.UPDATE_MANAGED_URL_FAIL), operators_1.mergeMap(() => {
            return [
                new createActions.CloseUrlSidePanelAction(),
                this.notificationService.newNotification({
                    type: 'error',
                    config: {
                        msg: this.translateService.instant('$I18N_SERVICES_URL_PROTECTION_MANAGED_URL_UPDATE_URL_ERROR_MSG')
                    }
                })
            ];
        }));
    }
    chooseNotificationMsg(_type) {
        switch (_type) {
            case url_create_types_enum_1.UrlCreateTypes.CREATE:
                return this.translateService.instant('$I18N_SERVICES_URL_PROTECTION_MANAGED_URL_CREATE_SUCCESS_NOTIFICATION');
            case url_create_types_enum_1.UrlCreateTypes.ALLOW:
                return this.translateService.instant('$I18N_SERVICES_URL_PROTECTION_MANAGED_URL_URL_ALLOW_URL_SUCCESS');
            case url_create_types_enum_1.UrlCreateTypes.BLOCK:
                return this.translateService.instant('$I18N_SERVICES_URL_PROTECTION_MANAGED_URL_URL_BLOCK_URL_SUCCESS');
        }
    }
    chooseClosingSidepanelInstance(type) {
        switch (type) {
            case url_create_types_enum_1.UrlCreateTypes.CREATE:
                return new createActions.CloseUrlSidePanelAction();
            case url_create_types_enum_1.UrlCreateTypes.ALLOW:
            case url_create_types_enum_1.UrlCreateTypes.BLOCK:
                return new logsSideActions.CloseLogsSidePanelAction();
        }
    }
    chooseTableToRefresh(type, currentPagination, currentFilters) {
        switch (type) {
            case url_create_types_enum_1.UrlCreateTypes.CREATE:
                return new table_store_1.LoadAction({
                    tableId: MANAGED_URLS_TABLE_ID,
                    pagination: { pageToken: '', pageSize: currentPagination.pageSize, pageNumber: 0 },
                    query: currentFilters
                });
            case url_create_types_enum_1.UrlCreateTypes.ALLOW:
            case url_create_types_enum_1.UrlCreateTypes.BLOCK:
                return new table_store_1.LoadAction({
                    tableId: LOGS_URLS_TABLE_ID,
                    pagination: { pageToken: '', pageSize: currentPagination.pageSize, pageNumber: 0 },
                    query: currentFilters
                });
        }
    }
    chooseTableForReducer(type) {
        switch (type) {
            case url_create_types_enum_1.UrlCreateTypes.CREATE:
                return MANAGED_URLS_TABLE_ID;
            case url_create_types_enum_1.UrlCreateTypes.ALLOW:
            case url_create_types_enum_1.UrlCreateTypes.BLOCK:
                return LOGS_URLS_TABLE_ID;
        }
    }
}
__decorate([
    effects_1.Effect()
], CreateUpdateUrlEffects.prototype, "create$", void 0);
__decorate([
    effects_1.Effect()
], CreateUpdateUrlEffects.prototype, "createSuccess$", void 0);
__decorate([
    effects_1.Effect()
], CreateUpdateUrlEffects.prototype, "allowBlockUrlSuccess$", void 0);
__decorate([
    effects_1.Effect()
], CreateUpdateUrlEffects.prototype, "createFail$", void 0);
__decorate([
    effects_1.Effect()
], CreateUpdateUrlEffects.prototype, "createUrlAttempt$", void 0);
__decorate([
    effects_1.Effect()
], CreateUpdateUrlEffects.prototype, "updateUrlAttempt$", void 0);
__decorate([
    effects_1.Effect()
], CreateUpdateUrlEffects.prototype, "updateUrl$", void 0);
__decorate([
    effects_1.Effect()
], CreateUpdateUrlEffects.prototype, "updateUrlSuccess$", void 0);
__decorate([
    effects_1.Effect()
], CreateUpdateUrlEffects.prototype, "updateUrlFail$", void 0);
exports.CreateUpdateUrlEffects = CreateUpdateUrlEffects;
