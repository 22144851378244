"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IncidentsTypesDictionary = void 0;
var IncidentsTypesDictionary;
(function (IncidentsTypesDictionary) {
    IncidentsTypesDictionary["notify_only"] = "NOTIFY_ONLY";
    IncidentsTypesDictionary["automatic"] = "AUTOMATIC";
    IncidentsTypesDictionary["manual"] = "MANUAL";
    IncidentsTypesDictionary["mep_clawback"] = "MEP_CLAWBACK";
    IncidentsTypesDictionary["restore"] = "RESTORE";
})(IncidentsTypesDictionary = exports.IncidentsTypesDictionary || (exports.IncidentsTypesDictionary = {}));
