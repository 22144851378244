"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportApiEffects = void 0;
const effects_1 = require("@ngrx/effects");
const notification_actions_1 = require("app-new/components/notification/actions/notification.actions");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const request_actions_1 = require("../../actions/request.actions");
const side_panel_actions_1 = require("../../actions/side-panel.actions");
class ExportApiEffects {
    constructor(actions$, httpClient, archiveApiService) {
        this.actions$ = actions$;
        this.httpClient = httpClient;
        this.archiveApiService = archiveApiService;
        this.getFileId$ = this.actions$.pipe(effects_1.ofType(request_actions_1.ExportApiRequestActionTypes.GetId), operators_1.switchMap((action) => {
            return this.httpClient
                .post(action.apiExportUrl, Object.assign(Object.assign({}, (action.search || {})), { exportType: action.fileFormat, exportColumns: action.columnsToExport }))
                .pipe(operators_1.map((response) => response.first), operators_1.map((first) => new request_actions_1.DownloadExportApiAction(first.id)), operators_1.catchError(() => rxjs_1.merge([
                new request_actions_1.GetFileIdExportApiFailAction(),
                new notification_actions_1.NotificationShowFailAction('$I18N_EXPORT_API_GET_FILE_ID_FAILURE')
            ])));
        }));
        this.download$ = this.actions$.pipe(effects_1.ofType(request_actions_1.ExportApiRequestActionTypes.Download), operators_1.tap((action) => {
            const getFileApiUrl = `/api/file/get-file/${action.id}?forceDownload=true`;
            this.archiveApiService.triggerDownload(getFileApiUrl);
        }), operators_1.mergeMap(() => [
            new notification_actions_1.NotificationShowSuccessAction('$I18N_EXPORT_API_DOWNLOAD_WILL_START_SHORTLY'),
            new side_panel_actions_1.CloseSidePanelExportApiAction()
        ]));
    }
}
__decorate([
    effects_1.Effect()
], ExportApiEffects.prototype, "getFileId$", void 0);
__decorate([
    effects_1.Effect()
], ExportApiEffects.prototype, "download$", void 0);
exports.ExportApiEffects = ExportApiEffects;
