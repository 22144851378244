<div class="panel-padding-top">
<mc-swg-application-table
                          *ngIf="!isApplicationCategorySetDataLoading"
                          [type]="'sanction'"
                          [loading]="loading"
                          [applications]="applications"
                          [applicationCategoriesSet]="applicationCategoriesSet"
                          [hasEditPermission]="hasEditPermission"
                          [apiUpdatingEdit]="apiUpdatingEdit"
                          [hasAppPolicyRemoveAllowEnabled]="hasAppPolicyRemoveAllowEnabled"
                          (dateRangeChange)="dateRangeChangeSanction.emit($event)"
                          (webApplicationRules)="webApplicationSanction.emit($event)"
                          (onShowNotification)="onShowNotificationSanction.emit($event)"></mc-swg-application-table>
</div>
