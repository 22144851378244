"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RemediationRefreshSearchResult = exports.RemediationSearchUpdateFormValue = exports.RemediationSearchResetAction = exports.RemediationSearchPaginationSuccessAction = exports.RemediationSearchPaginationAction = exports.RemediationSearchFailureAction = exports.RemediationSearchShowResultsAction = exports.RemediationRefreshSearch = exports.RemediationClearSearch = exports.RemediationSearchSuccessAction = exports.RemediationSearchRunAction = exports.REMEDIATE_REFRESH_SEARCH_RESULT = exports.REMEDIATE_REFRESH_SEARCH = exports.REMEDIATE_CLEAR_SEARCH = exports.REMEDIATION_SEARCH_UPDATE_FORM_VALUE = exports.REMEDIATION_SEARCH_RESET = exports.REMEDIATION_SEARCH_PAGINATION_SUCCESS = exports.REMEDIATION_SEARCH_PAGINATION = exports.REMEDIATION_SEARCH_SHOW_RESULTS = exports.REMEDIATION_SEARCH_FAILURE = exports.REMEDIATION_SEARCH_SUCCESS = exports.REMEDIATION_SEARCH_RUN = void 0;
exports.REMEDIATION_SEARCH_RUN = '[Remediation] Search Messages';
exports.REMEDIATION_SEARCH_SUCCESS = '[Remediation] Search Success';
exports.REMEDIATION_SEARCH_FAILURE = '[Remediation] Search Failure';
exports.REMEDIATION_SEARCH_SHOW_RESULTS = '[Remediation] Search Show Results List';
exports.REMEDIATION_SEARCH_PAGINATION = '[Remediation] Search Pagination';
exports.REMEDIATION_SEARCH_PAGINATION_SUCCESS = '[Remediation] Search Pagination Success';
exports.REMEDIATION_SEARCH_RESET = '[Remediation] Search Reset';
exports.REMEDIATION_SEARCH_UPDATE_FORM_VALUE = '[Remediation] Search Update Form Values';
exports.REMEDIATE_CLEAR_SEARCH = '[Remediation] Clear Search';
exports.REMEDIATE_REFRESH_SEARCH = '[Remediation] Refresh Search';
exports.REMEDIATE_REFRESH_SEARCH_RESULT = '[Remediation] Refresh Search Result';
class RemediationSearchRunAction {
    constructor(payload, searchTabIndex, searchByAttachmentSha, hasSearchWithoutHashPermission) {
        this.payload = payload;
        this.searchTabIndex = searchTabIndex;
        this.searchByAttachmentSha = searchByAttachmentSha;
        this.hasSearchWithoutHashPermission = hasSearchWithoutHashPermission;
        this.type = exports.REMEDIATION_SEARCH_RUN;
    }
}
exports.RemediationSearchRunAction = RemediationSearchRunAction;
class RemediationSearchSuccessAction {
    constructor(payload, hasSearchAdvancedPermission) {
        this.payload = payload;
        this.hasSearchAdvancedPermission = hasSearchAdvancedPermission;
        this.type = exports.REMEDIATION_SEARCH_SUCCESS;
    }
}
exports.RemediationSearchSuccessAction = RemediationSearchSuccessAction;
class RemediationClearSearch {
    constructor() {
        this.type = exports.REMEDIATE_CLEAR_SEARCH;
    }
}
exports.RemediationClearSearch = RemediationClearSearch;
class RemediationRefreshSearch {
    constructor() {
        this.type = exports.REMEDIATE_REFRESH_SEARCH;
    }
}
exports.RemediationRefreshSearch = RemediationRefreshSearch;
class RemediationSearchShowResultsAction {
    constructor() {
        this.type = exports.REMEDIATION_SEARCH_SHOW_RESULTS;
    }
}
exports.RemediationSearchShowResultsAction = RemediationSearchShowResultsAction;
class RemediationSearchFailureAction {
    constructor() {
        this.type = exports.REMEDIATION_SEARCH_FAILURE;
    }
}
exports.RemediationSearchFailureAction = RemediationSearchFailureAction;
class RemediationSearchPaginationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.REMEDIATION_SEARCH_PAGINATION;
    }
}
exports.RemediationSearchPaginationAction = RemediationSearchPaginationAction;
class RemediationSearchPaginationSuccessAction {
    constructor() {
        this.type = exports.REMEDIATION_SEARCH_PAGINATION_SUCCESS;
    }
}
exports.RemediationSearchPaginationSuccessAction = RemediationSearchPaginationSuccessAction;
class RemediationSearchResetAction {
    constructor() {
        this.type = exports.REMEDIATION_SEARCH_RESET;
    }
}
exports.RemediationSearchResetAction = RemediationSearchResetAction;
class RemediationSearchUpdateFormValue {
    constructor(formValue) {
        this.formValue = formValue;
        this.type = exports.REMEDIATION_SEARCH_UPDATE_FORM_VALUE;
    }
}
exports.RemediationSearchUpdateFormValue = RemediationSearchUpdateFormValue;
class RemediationRefreshSearchResult {
    constructor() {
        this.type = exports.REMEDIATE_REFRESH_SEARCH_RESULT;
    }
}
exports.RemediationRefreshSearchResult = RemediationRefreshSearchResult;
