<mc-collapsable-panel header="$I18N_SWG_THREAT_EVENT_ANTIVIRUS.TITLE" [isExpanded]="true"
                      class="threat-event-antivirus">
  <div class="mc-detailed-list" *ngIf="threatEvent.antivirus && threatEvent.antivirus.verdict !== 'clean'">
    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ "$I18N_SWG_THREAT_EVENT_ANTIVIRUS.DETAILS" | translate }}
      </div>
      <div class="mc-detailed-list-column">
        <div class="threat-event-antivirus__description">
          {{ '$I18N_SWG_THREAT_EVENT_ANTIVIRUS.DESCRIPTION' | translate }}:
        </div>

        <div *ngFor="let malwareName of threatEvent.antivirus.malwareNames"
             class="threat-event-antivirus__item text-danger">
          {{ malwareName }}
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!threatEvent.antivirus">
    {{ '$I18N_SWG_THREAT_EVENT_ANTIVIRUS.NO_DATA' | translate }}
  </ng-container>

  <ng-container *ngIf="threatEvent.antivirus && threatEvent.antivirus.verdict === 'clean'">
    {{ '$I18N_SWG_THREAT_EVENT_ANTIVIRUS.CLEAN' | translate }}
  </ng-container>
</mc-collapsable-panel>
