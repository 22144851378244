<div class="mc-layout-aside-tabs mc-layout-full-height-no-overflow mc-flex-container mc-flex-column"
   [ngClass]="extendClass">
  <div class="mc-layout-header-aside mc-flex-container">
    <div class="mc-title-item">{{ keyTitle | translate }}</div>
    <div class="mc-wrapper-close-item" *ngIf="showClose">
      <a class="mc-close-item"
         (click)="closeAside.emit(true)">
        <i class="far fa-times"></i>
      </a>
    </div>
  </div>

  <mc-loader-full-container [isLoading]="isLoading" class="mc-layout-loader-full-container-aside">
    <div #mcIdExtraContainerArea
     class="mc-layout-extra-container-aside"
     [class.mc-layout-extra-container-aside-with-tabs]="hasTabs"
     [hidden]="!mcExtraContainerArea.nativeElement.children.length">
      <ng-content select="mc-extra-container"></ng-content>
    </div>

    <div class="mc-layout-tabs-container-aside">
      <ng-content select="mc-tab-group"></ng-content>
    </div>
  </mc-loader-full-container>

</div>
