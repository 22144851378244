"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgLocationDeleteFailureAction = exports.SwgLocationDeleteSuccessAction = exports.SwgLocationDeleteAction = exports.SwgLocationUpdateFailureAction = exports.SwgLocationUpdateSuccessAction = exports.SwgLocationUpdateAction = exports.SwgLocationLoadAction = exports.SwgLocationCrudTypes = void 0;
var SwgLocationCrudTypes;
(function (SwgLocationCrudTypes) {
    SwgLocationCrudTypes["SWG_LOCATION_LOAD"] = "[Swg Location Crud] Locations Load";
    SwgLocationCrudTypes["SWG_LOCATION_UPDATE"] = "[Swg Location Crud] Locations Update";
    SwgLocationCrudTypes["SWG_LOCATION_UPDATE_SUCCESS"] = "[Swg Location Crud] Locations Update Success";
    SwgLocationCrudTypes["SWG_LOCATION_UPDATE_FAILURE"] = "[Swg Location Crud] Locations Update Success";
    SwgLocationCrudTypes["SWG_LOCATION_DELETE"] = "[Swg Location Crud] Locations Delete";
    SwgLocationCrudTypes["SWG_LOCATION_DELETE_SUCCESS"] = "[Swg Location Crud] Locations Delete Success";
    SwgLocationCrudTypes["SWG_LOCATION_DELETE_FAILURE"] = "[Swg Location Crud] Locations Delete Success";
})(SwgLocationCrudTypes = exports.SwgLocationCrudTypes || (exports.SwgLocationCrudTypes = {}));
class SwgLocationLoadAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgLocationCrudTypes.SWG_LOCATION_LOAD;
    }
}
exports.SwgLocationLoadAction = SwgLocationLoadAction;
class SwgLocationUpdateAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgLocationCrudTypes.SWG_LOCATION_UPDATE;
    }
}
exports.SwgLocationUpdateAction = SwgLocationUpdateAction;
class SwgLocationUpdateSuccessAction {
    constructor() {
        this.type = SwgLocationCrudTypes.SWG_LOCATION_UPDATE_SUCCESS;
    }
}
exports.SwgLocationUpdateSuccessAction = SwgLocationUpdateSuccessAction;
class SwgLocationUpdateFailureAction {
    constructor() {
        this.type = SwgLocationCrudTypes.SWG_LOCATION_UPDATE_FAILURE;
    }
}
exports.SwgLocationUpdateFailureAction = SwgLocationUpdateFailureAction;
class SwgLocationDeleteAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgLocationCrudTypes.SWG_LOCATION_DELETE;
    }
}
exports.SwgLocationDeleteAction = SwgLocationDeleteAction;
class SwgLocationDeleteSuccessAction {
    constructor() {
        this.type = SwgLocationCrudTypes.SWG_LOCATION_DELETE_SUCCESS;
    }
}
exports.SwgLocationDeleteSuccessAction = SwgLocationDeleteSuccessAction;
class SwgLocationDeleteFailureAction {
    constructor() {
        this.type = SwgLocationCrudTypes.SWG_LOCATION_DELETE_FAILURE;
    }
}
exports.SwgLocationDeleteFailureAction = SwgLocationDeleteFailureAction;
