"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IdentityLoadPolicyDetailsFailAction = exports.IdentityLoadPolicyDetailsSuccessAction = exports.IdentityLoadPolicyDetailsAction = exports.IdentityOpenPolicyDetailsSidebarAction = exports.LOAD_POLICY_DETAILS_FAIL = exports.LOAD_POLICY_DETAILS_SUCCESS = exports.LOAD_POLICY_DETAILS = exports.OPEN_POLICY_DETAILS_SIDEBAR = void 0;
exports.OPEN_POLICY_DETAILS_SIDEBAR = '[Identity] Open Policy Details Sidebar';
exports.LOAD_POLICY_DETAILS = '[Identity] Load Policy Details';
exports.LOAD_POLICY_DETAILS_SUCCESS = '[Identity] Load Policy Details Success';
exports.LOAD_POLICY_DETAILS_FAIL = '[Identity] Load Policy Details Fail';
class IdentityOpenPolicyDetailsSidebarAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.OPEN_POLICY_DETAILS_SIDEBAR;
    }
}
exports.IdentityOpenPolicyDetailsSidebarAction = IdentityOpenPolicyDetailsSidebarAction;
class IdentityLoadPolicyDetailsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOAD_POLICY_DETAILS;
    }
}
exports.IdentityLoadPolicyDetailsAction = IdentityLoadPolicyDetailsAction;
class IdentityLoadPolicyDetailsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOAD_POLICY_DETAILS_SUCCESS;
    }
}
exports.IdentityLoadPolicyDetailsSuccessAction = IdentityLoadPolicyDetailsSuccessAction;
class IdentityLoadPolicyDetailsFailAction {
    constructor() {
        this.type = exports.LOAD_POLICY_DETAILS_FAIL;
    }
}
exports.IdentityLoadPolicyDetailsFailAction = IdentityLoadPolicyDetailsFailAction;
