"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailValidator = void 0;
const forms_1 = require("@angular/forms");
class EmailValidator {
    static validAndNotRequired(c) {
        const isEmpty = c.value === '';
        const isEmailAddressValid = forms_1.Validators.email(c) === null;
        return isEmpty || isEmailAddressValid ? null : { emailNotRequired: true };
    }
    static duplicate(c) {
        const isEmpty = c.length === 0;
        if (isEmpty) {
            return null;
        }
        const values = c.value;
        const filtered = values.filter(value => {
            const keys = Object.keys(value);
            if (keys.length > 1) {
                throw new Error('Every email control should have only one key');
            }
            return value[keys[0]];
        });
        const uniques = new Set(filtered.map(value => JSON.stringify(value)));
        return uniques.size === filtered.length ? null : { duplicateEmails: true };
    }
}
exports.EmailValidator = EmailValidator;
