"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReleaseLogsEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const api_1 = require("app-new/components/export/api");
const actions = require("../../actions");
const commonActions = require("../../../common/actions");
const reducers = require("../../reducers");
const core = require("app-new/core/reducers");
const ui = require("app-new/core/actions/ui.actions");
const release_log_aside_1 = require("../../containers/release-log-aside");
class ReleaseLogsEffects {
    constructor(actions$, service, asideService, store$) {
        this.actions$ = actions$;
        this.service = service;
        this.asideService = asideService;
        this.store$ = store$;
        this.loadItems$ = this.actions$.pipe(effects_1.ofType(actions.RELEASE_LOG_REQUEST, actions.RELEASE_LOG_PAGINATION, actions.RELEASE_LOG_REFRESH), operators_1.withLatestFrom(this.store$.select(reducers.getReleaseLogsSearch), this.store$.select(reducers.getReleaseLogsRequestPage)), operators_1.switchMap(([, request, pagination]) => {
            return this.service.getItems(request, pagination).pipe(operators_1.map(response => new actions.ReleaseLogSuccessAction(response)), operators_1.catchError(() => rxjs_1.of(new actions.ReleaseLogFailAction())));
        }));
        this.displaySelectedRowSidePanel$ = this.actions$.pipe(effects_1.ofType(actions.RELEASE_LOG_SELECT_ROW_ASIDE), operators_1.withLatestFrom(this.store$.select(core.getAdminPreferences)), operators_1.switchMap(([action, adminPrefs]) => {
            this.closeViewAside();
            this.viewAside = this.asideService.open(release_log_aside_1.ReleaseLogAsideComponent, Object.assign(Object.assign({ size: 'lg' }, (!!adminPrefs.uiAsidePanelWidth ? { width: adminPrefs.uiAsidePanelWidth } : {})), { minWidth: 620, resizable: true, hasBackdrop: true, data: action.payload }));
            this.viewAside.onResized().subscribe((width) => {
                this.store$.dispatch(new ui.RightAsidePanelResizedAction(width));
            });
            return this.viewAside.beforeClose().pipe(operators_1.map(() => {
                this.viewAside = undefined;
                return new commonActions.ClearSelectedRowAction();
            }));
        }));
        this.selectedRow$ = this.actions$.pipe(effects_1.ofType(actions.RELEASE_LOG_SELECT_ROW), operators_1.switchMap((action) => [
            new actions.ReleaseLogSelectRowAsideAction(action.payload)
        ]));
        this.closeDetailView$ = this.actions$.pipe(effects_1.ofType(actions.RELEASE_LOG_CLOSE_DETAIL), operators_1.tap(() => {
            this.closeViewAside();
        }));
        this.export$ = this.actions$.pipe(effects_1.ofType(actions.RELEASE_LOG_EXPORT), operators_1.withLatestFrom(this.store$.select(reducers.getReleaseLogsSearch)), operators_1.map(([action, search]) => {
            const apiSearch = this.service.transformToServerRequest(search);
            return new api_1.OpenSidePanelExportApiAction('/api/gateway/export-held-release-logs', action.payload, apiSearch, '$I18N_MESSAGE_CENTER_RELEASE_LOGS_TABLE');
        }));
    }
    closeViewAside() {
        if (!!this.viewAside) {
            this.viewAside.close();
            this.viewAside = undefined;
        }
    }
}
__decorate([
    effects_1.Effect()
], ReleaseLogsEffects.prototype, "loadItems$", void 0);
__decorate([
    effects_1.Effect()
], ReleaseLogsEffects.prototype, "displaySelectedRowSidePanel$", void 0);
__decorate([
    effects_1.Effect()
], ReleaseLogsEffects.prototype, "selectedRow$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], ReleaseLogsEffects.prototype, "closeDetailView$", void 0);
__decorate([
    effects_1.Effect()
], ReleaseLogsEffects.prototype, "export$", void 0);
exports.ReleaseLogsEffects = ReleaseLogsEffects;
