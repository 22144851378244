"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImSyncTaskSummaryComponent = void 0;
class ImSyncTaskSummaryComponent {
    ngOnChanges(changes) {
        if (changes && changes['wizardData'].currentValue) {
            this.stepsData = this.wizardData.steps;
        }
    }
    getStepDataById(id) {
        const step = this.getStepById(id);
        return (step && step.data) || {};
    }
    getStepById(id) {
        return (this.stepsData && this.stepsData[id]) || {};
    }
}
exports.ImSyncTaskSummaryComponent = ImSyncTaskSummaryComponent;
