"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyncTaskChartColors = exports.IN_PROGRESS_CAPS = exports.MailboxStatusChartSegmentTypes = void 0;
var MailboxStatusChartSegmentTypes;
(function (MailboxStatusChartSegmentTypes) {
    MailboxStatusChartSegmentTypes["SUCCESS"] = "success";
    MailboxStatusChartSegmentTypes["WARNING"] = "warning";
    MailboxStatusChartSegmentTypes["ERROR"] = "error";
    MailboxStatusChartSegmentTypes["IN_PROGRESS"] = "inProgress";
})(MailboxStatusChartSegmentTypes = exports.MailboxStatusChartSegmentTypes || (exports.MailboxStatusChartSegmentTypes = {}));
exports.IN_PROGRESS_CAPS = 'IN_PROGRESS';
exports.SyncTaskChartColors = {
    [MailboxStatusChartSegmentTypes.SUCCESS]: '#50B450',
    [MailboxStatusChartSegmentTypes.WARNING]: '#FFCC00',
    [MailboxStatusChartSegmentTypes.ERROR]: '#C62828',
    [MailboxStatusChartSegmentTypes.IN_PROGRESS]: '#3A98D8'
};
