"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GwsCustomerInfoStepComponent = void 0;
const forms_1 = require("@angular/forms");
const store_1 = require("@ngrx/store");
const step_base_component_1 = require("../../../../../wizards/step.base.component");
const reducers_1 = require("../../../../../../reducers");
const model_1 = require("../../../../../../model");
const connector_wizard_service_1 = require("../../../../../../services/connector-wizard.service");
const gws_provider_static_values_1 = require("../gws-provider-static-values");
const operators_1 = require("rxjs/operators");
const connector_actions_1 = require("../../../../../../actions/connector.actions");
class GwsCustomerInfoStepComponent extends step_base_component_1.StepBaseDirective {
    constructor(store, fb) {
        super(store, fb, model_1.ConnectorStep.GWS_CUSTOMER_INFORMATION, connector_wizard_service_1.ConnectorWizardService.WizardId);
        this.store = store;
        this.fb = fb;
        this.googleAdminPageLink = gws_provider_static_values_1.GOOGLE_ADMIN_LINK;
        this.buildForm();
    }
    ngOnInit() {
        super.ngOnInit();
        this.setRequestConsentApiTrigger();
        this.customerIdChanges = this.form.valueChanges.subscribe(() => (this.isTriggerRequestConsentApi = true));
    }
    ngOnDestroy() {
        this.requestConsentApiTrigger.unsubscribe();
        this.customerIdChanges.unsubscribe();
    }
    setRequestConsentApiTrigger() {
        this.requestConsentApiTrigger = this.store
            .pipe(store_1.select(reducers_1.getCurrentStep(connector_wizard_service_1.ConnectorWizardService.WizardId)))
            .pipe(operators_1.filter(() => this.isTriggerRequestConsentApi && this.isNextButtonClicked), operators_1.filter(stepNo => model_1.ConnectorStep.CONSENT === stepNo))
            .subscribe(() => {
            this.isTriggerRequestConsentApi = false;
            this.store.dispatch(new connector_actions_1.GetConsentConfigAction(this.buildConsentConfigRequest()));
        });
    }
    buildForm() {
        this.form = this.fb.group({
            customerId: [null, [forms_1.Validators.required, forms_1.Validators.minLength(3), forms_1.Validators.maxLength(30)]],
            mailbox: [null, [forms_1.Validators.required, forms_1.Validators.email, forms_1.Validators.maxLength(256)]]
        });
    }
    buildConsentConfigRequest() {
        const currentDate = new Date();
        const providedState = Math.floor(currentDate.getMilliseconds() * 9000) + currentDate.getUTCMilliseconds();
        return {
            id: this.provider.id,
            domain: window.location.origin,
            path: '/administration/app',
            stateParameters: {
                provider: gws_provider_static_values_1.PROVIDER_GWS,
                clientState: providedState.toString(),
                customerId: this.form.get(gws_provider_static_values_1.CUSTOMER_ID).value,
                mailbox: this.form.get(gws_provider_static_values_1.MAILBOX).value
            }
        };
    }
}
exports.GwsCustomerInfoStepComponent = GwsCustomerInfoStepComponent;
