"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocationService = void 0;
const i0 = require("@angular/core");
class LocationService {
    constructor(_window) {
        this._window = _window;
    }
    get _location() {
        return this._window.location;
    }
    /**
     * Is a DOMString containing the entire URL.
     * If changed, the associated document navigates to the new page.
     * It can be set from a different origin than the associated document.
     */
    get href() {
        return this._location.href;
    }
    set href(url) {
        this._location.href = url;
    }
    /**
     * Is a DOMString containing the protocol scheme of the URL, including the final ':'.
     */
    get protocol() {
        return this._location.protocol;
    }
    /**
     * Is a DOMString containing the host, that is the hostname, a ':', and the port of the URL.
     */
    get host() {
        return this._location.host;
    }
    /**
     * Is a DOMString containing the domain of the URL.
     */
    get hostname() {
        return this._location.hostname;
    }
    /**
     * Is a DOMString containing the port number of the URL.
     */
    get port() {
        return this._location.port;
    }
    /**
     * Is a DOMString containing an initial '/' followed by the path of the URL.
     */
    get pathname() {
        return this._location.pathname;
    }
    /**
     * Is a DOMString containing an initial '/' followed by the path of the parent URL.
     */
    get parentPathname() {
        return this._window.parent.location.pathname;
    }
    /**
     * Is a DOMString containing a '?' followed by the parameters or "querystring" of the URL.
     * Modern browsers provide URLSearchParams and URL.searchParams to make it easy to parse out the parameters from the querystring.
     */
    get search() {
        return this._location.search;
    }
    /**
     * Is a DOMString containing a '#' followed by the fragment identifier of the URL.
     */
    get hash() {
        return this._location.hash;
    }
    set hash(value) {
        this._location.hash = value;
    }
    /**
     * Returns a DOMString containing the canonical form of the origin of the specific location.
     */
    get origin() {
        return this._location.origin;
    }
    reload(force = false) {
        this._location.reload(force);
    }
    reloadParent(force = false) {
        this._window.parent.location.reload(force);
    }
}
exports.LocationService = LocationService;
LocationService.ɵprov = i0.ɵɵdefineInjectable({ factory: function LocationService_Factory() { return new LocationService(i0.ɵɵinject("WINDOW")); }, token: LocationService, providedIn: "root" });
