"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WidgetUrlsScanDayEffect = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const widgetScanPerDayActions = require("../../actions/widget-urls-scan-day/widget-urls-scan-day.action");
class WidgetUrlsScanDayEffect {
    constructor(actions$, service, notificationService) {
        this.actions$ = actions$;
        this.service = service;
        this.notificationService = notificationService;
        this.widgetScanPerDayAttempt$ = this.actions$.pipe(effects_1.ofType(widgetScanPerDayActions.GET_WIDGET_DATA_ATTEMPT), operators_1.mergeMap(() => {
            return this.service.getWidgetScanPerDayData().pipe(operators_1.map(() => {
                return new widgetScanPerDayActions.GetWidgetDataSuccess();
            }), operators_1.catchError(() => {
                return rxjs_1.of(new widgetScanPerDayActions.GetWidgetDataFail());
            }));
        }));
        this.decodeUrlFaiwidgetScanPerDayFail$ = this.actions$.pipe(effects_1.ofType(widgetScanPerDayActions.GET_WIDGET_DATA_FAIL), operators_1.map(() => {
            return this.notificationService.newNotification({
                type: 'error',
                config: {
                    msg: 'An error occured'
                }
            });
        }));
    }
}
__decorate([
    effects_1.Effect()
], WidgetUrlsScanDayEffect.prototype, "widgetScanPerDayAttempt$", void 0);
__decorate([
    effects_1.Effect()
], WidgetUrlsScanDayEffect.prototype, "decodeUrlFaiwidgetScanPerDayFail$", void 0);
exports.WidgetUrlsScanDayEffect = WidgetUrlsScanDayEffect;
