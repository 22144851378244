<div class="mc-detailed-list">
  <mc-spam-details-info
      [spamInfo]="releaseLog">
  </mc-spam-details-info>
  <hr/>

  <mc-processing-details
      [processingDetails]="releaseLog.spamProcessingDetail">
  </mc-processing-details>
  <hr/>

  <mc-threat-analysis
      [processingDetails]="releaseLog.spamProcessingDetail">
  </mc-threat-analysis>
</div>
