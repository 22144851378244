"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getApiIsProcessingDelete = exports.getBiPolicyDesc = exports.getBiPolicyCopy = exports.getBiPolicy = exports.reducer = exports.initialState = void 0;
const sidebar_actions_1 = require("../actions/sidebar.actions");
const policy_actions_1 = require("../actions/policy.actions");
exports.initialState = {
    biPolicy: {
        id: '',
        affectedUsers: [],
        lastModified: '',
        policyDesc: {
            id: '',
            description: ''
        }
    },
    wizardFlowType: '',
    loadingForEdit: false,
    forceDuplicate: false,
    apiProcessingInProgress: false,
    apiProcessingDeleteInProgress: false,
    biPolicyCopy: null,
    showAffectedUserAddedNotification: false,
    showDuplicateWarning: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case sidebar_actions_1.BiPolicyListSidebarActionsEnum.BI_POLICY_DELETE_CONFIRM_MODEL_OPEN:
        case sidebar_actions_1.BiPolicyListSidebarActionsEnum.BI_POLICY_LIST_SIDEBAR_OPEN:
            return Object.assign(Object.assign({}, state), { biPolicy: action.payload });
        case policy_actions_1.BiPolicyActionsEnums.BI_POLICY_DELETE:
            return Object.assign(Object.assign({}, state), { apiProcessingDeleteInProgress: true });
        case policy_actions_1.BiPolicyActionsEnums.BI_POLICY_DELETE_SUCCESS:
            return Object.assign(Object.assign({}, exports.initialState), { apiProcessingDeleteInProgress: false });
        case policy_actions_1.BiPolicyActionsEnums.BI_POLICY_DELETE_FAILURE:
            return Object.assign(Object.assign({}, exports.initialState), { apiProcessingDeleteInProgress: false });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getBiPolicy = (state) => state.biPolicy;
exports.getBiPolicy = getBiPolicy;
const getBiPolicyCopy = (state) => state.biPolicyCopy;
exports.getBiPolicyCopy = getBiPolicyCopy;
const getBiPolicyDesc = (state) => state.biPolicy.policyDesc;
exports.getBiPolicyDesc = getBiPolicyDesc;
const getApiIsProcessingDelete = (state) => state.apiProcessingDeleteInProgress;
exports.getApiIsProcessingDelete = getApiIsProcessingDelete;
