"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.URLReportModalSubmitSuccessReset = exports.URLReportModalSubmitRetryEnabled = exports.URLReportModalSubmitButtonState = exports.URLReportModalSubmitFailure = exports.URLReportModalSubmitSuccess = exports.URLReportModalSubmitReset = exports.URLReportModalSubmit = exports.URL_REPORT_SUCCESSFUL_RESET = exports.URL_SCAN_DETAILS_REPORT_SUBMIT_RETRY_ENABLED = exports.URL_SCAN_DETAILS_REPORT_SUBMIT_BUTTON_STATE = exports.URL_SCAN_DETAILS_REPORT_SUBMIT_FAILURE = exports.URL_SCAN_DETAILS_REPORT_SUBMIT_SUCCESS = exports.URL_SCAN_DETAILS_REPORT_SUBMIT_RESET = exports.URL_SCAN_DETAILS_REPORT_SUBMIT = void 0;
exports.URL_SCAN_DETAILS_REPORT_SUBMIT = '[Policy] URL report modal submit ';
exports.URL_SCAN_DETAILS_REPORT_SUBMIT_RESET = '[Policy] URL report modal  reset';
exports.URL_SCAN_DETAILS_REPORT_SUBMIT_SUCCESS = '[Policy] URL report modal submit success';
exports.URL_SCAN_DETAILS_REPORT_SUBMIT_FAILURE = '[Policy] URL report modal submit failure';
exports.URL_SCAN_DETAILS_REPORT_SUBMIT_BUTTON_STATE = '[Policy] URL report modal submit button state';
exports.URL_SCAN_DETAILS_REPORT_SUBMIT_RETRY_ENABLED = '[Policy] URL report modal submit button retry enabled';
exports.URL_REPORT_SUCCESSFUL_RESET = '[Policy] URL report modal submit success reset';
class URLReportModalSubmit {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.URL_SCAN_DETAILS_REPORT_SUBMIT;
    }
}
exports.URLReportModalSubmit = URLReportModalSubmit;
class URLReportModalSubmitReset {
    constructor() {
        this.type = exports.URL_SCAN_DETAILS_REPORT_SUBMIT_RESET;
    }
}
exports.URLReportModalSubmitReset = URLReportModalSubmitReset;
class URLReportModalSubmitSuccess {
    constructor() {
        this.type = exports.URL_SCAN_DETAILS_REPORT_SUBMIT_SUCCESS;
    }
}
exports.URLReportModalSubmitSuccess = URLReportModalSubmitSuccess;
class URLReportModalSubmitFailure {
    constructor() {
        this.type = exports.URL_SCAN_DETAILS_REPORT_SUBMIT_FAILURE;
    }
}
exports.URLReportModalSubmitFailure = URLReportModalSubmitFailure;
class URLReportModalSubmitButtonState {
    constructor() {
        this.type = exports.URL_SCAN_DETAILS_REPORT_SUBMIT_BUTTON_STATE;
    }
}
exports.URLReportModalSubmitButtonState = URLReportModalSubmitButtonState;
class URLReportModalSubmitRetryEnabled {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.URL_SCAN_DETAILS_REPORT_SUBMIT_RETRY_ENABLED;
    }
}
exports.URLReportModalSubmitRetryEnabled = URLReportModalSubmitRetryEnabled;
class URLReportModalSubmitSuccessReset {
    constructor() {
        this.type = exports.URL_REPORT_SUCCESSFUL_RESET;
    }
}
exports.URLReportModalSubmitSuccessReset = URLReportModalSubmitSuccessReset;
