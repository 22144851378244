"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.baseUrl = exports.LexiconSearchType = exports.Lexicon = void 0;
const lexicon_base_1 = require("./lexicon-base");
class Lexicon extends lexicon_base_1.LexiconBase {
    constructor(id, name, createdBy, updatedBy, created, updated, status, versions, searchType, associatedRules) {
        super(id, name, null, createdBy, updatedBy, created, updated, status);
        this.id = id;
        this.name = name;
        this.createdBy = createdBy;
        this.updatedBy = updatedBy;
        this.created = created;
        this.updated = updated;
        this.status = status;
        this.versions = versions;
        this.searchType = searchType;
        this.associatedRules = associatedRules;
    }
}
exports.Lexicon = Lexicon;
var LexiconSearchType;
(function (LexiconSearchType) {
    LexiconSearchType["BASIC"] = "basic";
    LexiconSearchType["ADVANCED"] = "advanced";
})(LexiconSearchType = exports.LexiconSearchType || (exports.LexiconSearchType = {}));
exports.baseUrl = '/api/supervision/config/';
