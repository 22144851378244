"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.getApiSavingStatus = exports.getApiProcessingInProgress = exports.getLoadingForEdit = exports.getShowAffectedUserAddedNotification = exports.getShowDuplicateWarning = exports.getMepPolicy = exports.reducer = exports.initialState = void 0;
const sidebar = require("../actions/sidebar.actions");
const models_1 = require("../models/models");
const policy = require("../actions/policy.actions");
const checkForDuplicateRule = (state, action) => {
    return state.mepPolicy.rules.some(affectedUser => {
        if (affectedUser.condition === models_1.MepAffectedUsersEnum.INDIVIDUAL_EMAIL_ADDRESS) {
            if (affectedUser.payload.emailAddress === action.payload.payload.emailAddress) {
                return true;
            }
        }
        if (affectedUser.condition === models_1.MepAffectedUsersEnum.PROFILE_GROUP) {
            if (affectedUser.payload.id === action.payload.payload.id) {
                return true;
            }
        }
        return false;
    });
};
const ɵ0 = checkForDuplicateRule;
exports.ɵ0 = ɵ0;
exports.initialState = {
    mepPolicy: {
        id: '',
        name: '',
        description: '',
        lastUpdated: '',
        defaultPolicy: false,
        action: 'no_action',
        matchLevel: 'low',
        autoReleaseTimeoutMinutes: models_1.MepPolicyAutoReleaseTimeout.THIRTY_MINUTES,
        policyType: models_1.MepPolicyType.MISDIRECTED_EMAIL_POLICY,
        enabled: true,
        everyone: false,
        rules: []
    },
    showDuplicateWarning: false,
    showAffectedUserAddedNotification: false,
    loadingForEdit: false,
    apiSavingStatus: false,
    apiProcessingInProgress: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case policy.MepPolicyActionsEnums.MEP_POLICY_CREATE:
            return Object.assign(Object.assign({}, state), { mepPolicy: exports.initialState.mepPolicy, loadingForEdit: false });
        case policy.MepPolicyActionsEnums.MEP_CLEAR_POLICY:
            return Object.assign({}, exports.initialState);
        case policy.MepPolicyActionsEnums.MEP_POLICY_DETAILS_UPDATE:
            return Object.assign(Object.assign({}, state), { mepPolicy: Object.assign(Object.assign({}, state.mepPolicy), { name: action.payload.name, description: action.payload.description, action: action.payload.action, autoReleaseTimeoutMinutes: action.payload.autoReleaseTimeoutMinutes, matchLevel: action.payload.matchLevel }) });
        case policy.MepPolicyActionsEnums.MEP_UPDATE_ENABLED_STATUS:
            return Object.assign(Object.assign({}, state), { mepPolicy: Object.assign(Object.assign({}, state.mepPolicy), { enabled: action.payload }) });
        case policy.MepPolicyActionsEnums.MEP_CLEAR_AFFECTED_USERS_WARNING:
            return Object.assign(Object.assign({}, state), { showDuplicateWarning: false });
        case policy.MepPolicyActionsEnums.MEP_CLEAR_AFFECTED_USERS_NOTIFICATION:
            return Object.assign(Object.assign({}, state), { showAffectedUserAddedNotification: false });
        case policy.MepPolicyActionsEnums.MEP_EVERYONE_AFFECTED_USERS_STEP:
            return Object.assign(Object.assign({}, state), { mepPolicy: Object.assign(Object.assign({}, state.mepPolicy), { everyone: action.payload }) });
        case policy.MepPolicyActionsEnums.MEP_REMOVE_AFFECTED_USERS_STEP:
            const newAffectedUsers = [...state.mepPolicy.rules].filter(affectedUser => {
                switch (affectedUser.condition) {
                    case models_1.MepAffectedUsersEnum.INDIVIDUAL_EMAIL_ADDRESS:
                        return affectedUser.payload.emailAddress !== action.payload.payload.emailAddress;
                    case models_1.MepAffectedUsersEnum.PROFILE_GROUP:
                        return affectedUser.payload.description !== action.payload.payload.description;
                    default:
                        return false;
                }
            });
            return Object.assign(Object.assign({}, state), { mepPolicy: Object.assign(Object.assign({}, state.mepPolicy), { rules: newAffectedUsers }) });
        case policy.MepPolicyActionsEnums.MEP_UPDATE_AFFECTED_USERS_STEP:
            return checkForDuplicateRule(state, action)
                ? Object.assign(Object.assign({}, state), { showDuplicateWarning: true, showAffectedUserAddedNotification: false }) : Object.assign(Object.assign({}, state), { mepPolicy: Object.assign(Object.assign({}, state.mepPolicy), { rules: [...state.mepPolicy.rules, action.payload], everyone: false }), showAffectedUserAddedNotification: true, showDuplicateWarning: false });
        case policy.MepPolicyActionsEnums.MEP_POLICY_SAVE_STATUS:
            return Object.assign(Object.assign({}, state), { apiSavingStatus: true });
        case policy.MepPolicyActionsEnums.MEP_POLICY_SAVE_STATUS_SUCCESS:
            return Object.assign(Object.assign({}, state), { mepPolicy: Object.assign(Object.assign({}, state.mepPolicy), { lastUpdated: action.payload }), apiSavingStatus: false });
        case policy.MepPolicyActionsEnums.MEP_POLICY_SAVE_STATUS_FAILURE:
            return Object.assign(Object.assign({}, state), { apiSavingStatus: false });
        case sidebar.MepPolicyListSidebarActionsEnum.MEP_POLICY_LIST_SIDEBAR_OPEN:
        case policy.MepPolicyActionsEnums.MEP_POLICY_DELETE_CONFIRM_MODAL_OPEN:
            return Object.assign(Object.assign({}, state), { mepPolicy: action.payload });
        case policy.MepPolicyActionsEnums.MEP_POLICY_LOAD_FOR_EDITING:
        case policy.MepPolicyActionsEnums.MEP_POLICY_DELETE:
            return Object.assign(Object.assign({}, state), { id: action.id, loadingForEdit: true });
        case sidebar.MepPolicyListSidebarActionsEnum.MEP_POLICY_LIST_SIDEBAR_CLOSE:
        case policy.MepPolicyActionsEnums.MEP_POLICY_GET_POLICY:
        case policy.MepPolicyActionsEnums.MEP_POLICY_DELETE_CONFIRM_MODAL_CLOSE:
        case policy.MepPolicyActionsEnums.MEP_POLICY_SAVE_SUCCESS:
        case policy.MepPolicyActionsEnums.MEP_POLICY_SAVE_FAILURE:
        case policy.MepPolicyActionsEnums.MEP_POLICY_EDIT_POLICY_SUCCESS:
        case policy.MepPolicyActionsEnums.MEP_POLICY_LOAD_FOR_EDITING_FAILURE:
        case policy.MepPolicyActionsEnums.MEP_POLICY_EDIT_POLICY_FAILURE:
            return Object.assign(Object.assign({}, state), { apiProcessingInProgress: false, loadingForEdit: false });
        case policy.MepPolicyActionsEnums.MEP_POLICY_LOAD_FOR_EDITING_SUCCESS:
        case policy.MepPolicyActionsEnums.MEP_POLICY_OPEN_FOR_EDITING:
            return Object.assign(Object.assign({}, state), { mepPolicy: action.payload, loadingForEdit: false });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getMepPolicy = (state) => state.mepPolicy;
exports.getMepPolicy = getMepPolicy;
const getShowDuplicateWarning = (state) => state.showDuplicateWarning;
exports.getShowDuplicateWarning = getShowDuplicateWarning;
const getShowAffectedUserAddedNotification = (state) => state.showAffectedUserAddedNotification;
exports.getShowAffectedUserAddedNotification = getShowAffectedUserAddedNotification;
const getLoadingForEdit = (state) => state.loadingForEdit;
exports.getLoadingForEdit = getLoadingForEdit;
const getApiProcessingInProgress = (state) => state.apiProcessingInProgress;
exports.getApiProcessingInProgress = getApiProcessingInProgress;
const getApiSavingStatus = (state) => state.apiSavingStatus;
exports.getApiSavingStatus = getApiSavingStatus;
