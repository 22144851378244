"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Status = void 0;
class Status {
    constructor(key, displayClasses, tooltip, displayValue, icon, statusMessage) {
        this.key = key;
        this.displayClasses = displayClasses;
        this.tooltip = tooltip;
        this.displayValue = displayValue;
        this.icon = icon;
        this.statusMessage = statusMessage;
        this.validateMandatoryFields();
    }
    validateMandatoryFields() {
        if (!this.key) {
            throw new Error('Key is mandatory for status');
        }
        if (!this.displayValue) {
            throw new Error('display value is mandatory for status');
        }
    }
}
exports.Status = Status;
