"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HeldGroupDisplayPipe = void 0;
class HeldGroupDisplayPipe {
    constructor(translateService) {
        this.translateService = translateService;
    }
    transform(heldGroup = '') {
        switch (heldGroup.toLowerCase()) {
            case 'user':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_HELD_GROUP.USER');
            case 'moderator':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_HELD_GROUP.MODERATOR');
            case 'administrator':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_HELD_GROUP.ADMINISTRATOR');
            case 'cluster':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_HELD_GROUP.CLUSTER');
            default:
                return '';
        }
    }
}
exports.HeldGroupDisplayPipe = HeldGroupDisplayPipe;
