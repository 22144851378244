<ng-container *ngIf="canDisplayGroup() | async">
  <div
    (click)="migrationGroup.collapsed = !migrationGroup.collapsed"
    class="mc-migration-group-header"
  >
    <span class="mc-half-flex"
    ><span
      class="fas fa-fw mc-caret"
      [class.fa-caret-right]="migrationGroup.collapsed"
      [class.fa-caret-down]="!migrationGroup.collapsed"
    ></span>
      {{ migrationGroup.productName }}</span
    >
    <span class="mc-half-flex mc-text-right"
    ><ng-container *ngIf="migratedConnectors < migrationGroup.connectors.length; else connected"
    >{{ migratedOfNotMigrated }}
      <mc-progressbar
        [current]="migratedConnectors"
        [max]="migrationGroup.connectors.length"
      ></mc-progressbar></ng-container
    ></span>
  </div>
  <div [class.collapsed]="migrationGroup.collapsed">
    <mc-connector
      [connectorData]="connector"
      [productData]="migrationGroup"
      *ngFor="let connector of migrationGroup.connectors"
    ></mc-connector>
  </div>
</ng-container>

<ng-template #connected>
  <span><i aria-hidden="true" class="fa fa-check-circle text-success"></i> Complete</span>
</ng-template>
