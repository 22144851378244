<mc-layout-list-extra-container>
  <mc-help-container>
    <mc-help-link url="https://community.mimecast.com/s/article/Targeted-Threat-Protection-URL-Protect-Logs-722594593"></mc-help-link>
  </mc-help-container>

  <mc-body-container>
    <mc-logs-list-table
      (onPaginatorChanged)="paginatorChanged($event)"
      [extendedFilter]="extendedFilter"
      [browserIsolationExtendedFilter]="browserIsolationExtendedFilter"
      (onRowClicked)="rowClicked()"
      (onLogsExported)="exportResults($event)"
    ></mc-logs-list-table>
  </mc-body-container>
</mc-layout-list-extra-container>
