"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.getActivityReportApplications = exports.getActivityReportHasErrors = exports.getActivityReportIsLoading = exports.getActivityReportCategories = exports.reducers = void 0;
const store_1 = require("@ngrx/store");
const activityReport = require("./swg-activity-report.reducer");
exports.reducers = {
    activityReport: activityReport.reducer
};
const getSwgActivityReport = store_1.createFeatureSelector('swgActivityReport');
const ɵ0 = state => state.activityReport;
exports.ɵ0 = ɵ0;
const getSwgActivityReportState = store_1.createSelector(getSwgActivityReport, ɵ0);
exports.getActivityReportCategories = store_1.createSelector(getSwgActivityReportState, activityReport.getCategories);
exports.getActivityReportIsLoading = store_1.createSelector(getSwgActivityReportState, activityReport.isLoading);
exports.getActivityReportHasErrors = store_1.createSelector(getSwgActivityReportState, activityReport.hasErrors);
exports.getActivityReportApplications = store_1.createSelector(getSwgActivityReportState, activityReport.getApplications);
