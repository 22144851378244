<mc-layout-detail-wizard [keyTitle]="wizardTitle">
  <mc-wizard #wizard (changeStep)="onStepChange($event)">
    <mc-wizard-step
      label="{{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.DETAILS_STEP.TITLE' | translate}}"
    >
      <mc-archive-supervision-wizard-lexicon-details [name]="name" [description]="description">
      </mc-archive-supervision-wizard-lexicon-details>
    </mc-wizard-step>
    <mc-wizard-step
      label="{{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.CONTENT_STEP.TITLE' | translate}}"
    >
      <mc-archive-supervision-wizard-lexicon-content 
        [validationError]="(validationError$ | async)"
        (onValidateSuccess)="goToLastStep()"
        [words]="words" 
        [query]="query"
        [phrases]="phrases"
        [isEditMode]="isEditMode"
        [searchType]="searchType"
      >
      </mc-archive-supervision-wizard-lexicon-content>
    </mc-wizard-step>
    <mc-wizard-step
      *ngIf="!isEditMode"
      label="{{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.SUMMARY_STEP.TITLE' | translate}}"
    >
      <mc-archive-supervision-wizard-lexicon-summary [wizardData]="wizardData$ | async">
      </mc-archive-supervision-wizard-lexicon-summary>
    </mc-wizard-step>
  </mc-wizard>
  <mc-footer-container>
    <div class="pull-right no-select mc-button-footer">
      <button class="btn mc-create-policy-cancel" (click)="onCancel()">
        {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.CONTROLS.CANCEL' | translate }}
      </button>

      <ng-container *ngIf="wizard">
        <button
          class="btn btn-secondary"
          *ngIf="(wizard.hasPreviousStep$ | async)"
          (click)="onPrevious()"
        >
          {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.CONTROLS.PREVIOUS' | translate }}
        </button>
        <button
          class="btn btn-primary"
          *ngIf="(wizard.currentStep === 2) && isAdvancedSearchType() && !isEditMode"
          [disabled]="wizardData.steps[2].data['query'] === ''"
          (click)="onValidateAndContinue()"
         >
         {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.CONTROLS.VALIDATE_AND_CONTINUE' | translate }}
        </button>
        <button
          class="btn btn-primary"
          [disabled]="(wizard.isLastStep$ | async) || !(isCurrentStepValid$ | async)"
          *ngIf="(wizard.hasNextStep$ | async) && showNextButton()"
          (click)="onNext()"
          data-test="next-btn"
        >
          {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.CONTROLS.NEXT' | translate }}
        </button>
        <button
          *ngIf="(wizard.isLastStep$ | async) || isEditMode"
          [disabled]="!(hasEditPermission$ | async) || (isEditMode && !(isCurrentStepValid$ | async))"
          class="btn btn-primary"
          (click)="onSave($event)"
          data-test="save-btn"
        >
          {{ getSaveButtonText() }}
        </button>
      </ng-container>
    </div>
  </mc-footer-container>
</mc-layout-detail-wizard>
