"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgEndpointsListContainerComponent = void 0;
const swgEndpointsListReducers = require("../reducers");
const endpoints_list_actions_1 = require("../actions/endpoints-list.actions");
const sidebar_actions_1 = require("../actions/sidebar.actions");
const device_groups_sidebar_actions_1 = require("../actions/device-groups-sidebar.actions");
const device_groups_list_actions_1 = require("../actions/device-groups-list.actions");
const device_groups_models_1 = require("../models/device-groups.models");
class SwgEndpointsListContainerComponent {
    constructor(store, swgService, capabilitiesService, stateService) {
        this.store = store;
        this.swgService = swgService;
        this.capabilitiesService = capabilitiesService;
        this.stateService = stateService;
    }
    ngOnInit() {
        switch (this.stateService.$state.params.displayTab) {
            case 'endpoints-list':
                this.displayTab = 0;
                break;
            case 'device-groups-list':
                this.displayTab = 1;
                break;
            default:
                this.displayTab = 0;
                break;
        }
        this.hasEndpointsDetailsEnabled = this.swgService.hasEndpointsDetailsEnabled();
        this.hasDeviceBasedPoliciesSwitchEnabled = this.swgService.hasDeviceBasedPoliciesSwitchEnabled();
        this.hasPurgePermission$ = this.capabilitiesService.hasCapability('Permission.SWG_ENDPOINT_LIST_PURGE/DELETE');
        this.hasEditPermission$ = this.capabilitiesService.hasCapability('Permission.SWG_ENDPOINT_LIST_EDIT');
        this.purgeEndpointsListSubscription = this.store
            .select(swgEndpointsListReducers.getEndpointsListPurged)
            .subscribe((purged) => {
            if (purged) {
                this.endpointsListComponent.reloadEndpointsList();
                this.store.dispatch(new endpoints_list_actions_1.SwgEndpointsListLoadedPostPurgeAction());
            }
        });
    }
    purgeEndpoints(list) {
        this.store.dispatch(new endpoints_list_actions_1.SwgPurgeEndpointsListConfirmAction(list));
    }
    openSidepanel() {
        this.store.dispatch(new sidebar_actions_1.SwgEndpointsListSidebarOpen());
    }
    openDeviceGroupSidePanel() {
        this.store.dispatch(new device_groups_sidebar_actions_1.SwgDeviceGroupSidebarOpen());
    }
    onCreateNewDeviceGroup() {
        this.store.dispatch(new device_groups_list_actions_1.SwgClearDeviceGroupAction());
        this.store.dispatch(new device_groups_list_actions_1.SwgNavigateToDeviceGroupWizardAction(device_groups_models_1.DeviceGroupWizardFlowType.CREATE));
    }
    onDeleteDeviceGroup(deviceGroup) {
        this.store.dispatch(new device_groups_sidebar_actions_1.SwgDeviceGroupDeleteConfirmationModalOpen(deviceGroup));
    }
    onEditDeviceGroup(deviceGroup) {
        this.store.dispatch(new device_groups_list_actions_1.SwgClearDeviceGroupAction());
        this.store.dispatch(new device_groups_list_actions_1.SwgLoadDeviceGroupToEditAction(deviceGroup));
        this.store.dispatch(new device_groups_list_actions_1.SwgNavigateToDeviceGroupWizardAction(device_groups_models_1.DeviceGroupWizardFlowType.EDIT));
    }
    ngOnDestroy() {
        this.purgeEndpointsListSubscription.unsubscribe();
    }
}
exports.SwgEndpointsListContainerComponent = SwgEndpointsListContainerComponent;
