<div
  class="mc-layout-aside-extra-container mc-layout-full-height mc-flex-container mc-flex-column"
  [ngClass]="extendClass"
>
  <div class="mc-layout-header-aside mc-flex-container">
    <div class="mc-title-item">{{ keyTitle | translate }}</div>
    <div class="mc-wrapper-close-item" *ngIf="showClose">
      <a class="mc-close-item" (click)="closeAside.emit(true)">
        <i class="far fa-times"></i>
      </a>
    </div>
  </div>

  <mc-loader-full-container
    [isLoading]="isLoading"
    class="mc-layout-loader-full-container-aside  mc-flex-scroll-vertical"
  >
    <div
      #mcIdExtraContainerArea
      class="mc-layout-extra-container-aside"
      [hidden]="!mcExtraContainerArea.nativeElement.children.length"
    >
      <ng-content select="mc-extra-container"></ng-content>
    </div>

    <div class="mc-layout-body-aside --disabled-mc-flex-scroll-vertical">
      <ng-content select="mc-body-container" class="mc-flex-fill mc-panel-padding"></ng-content>
    </div>
  </mc-loader-full-container>
</div>
