"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgExceptionsDeleteConfirmationComponent = void 0;
const swg_exceptions_actions_1 = require("../../actions/swg-exceptions.actions");
const swg_exceptions_sidebar_modal_actions_1 = require("../../actions/swg-exceptions-sidebar-modal.actions");
const reducers = require("../../reducers");
class SwgExceptionsDeleteConfirmationComponent {
    constructor(store) {
        this.store = store;
    }
    ngOnInit() {
        this.exception$ = this.store.select(reducers.getExceptionItem);
        this.isDeleting$ = this.store.select(reducers.getIsApiProcessing);
    }
    onDelete(row) {
        this.store.dispatch(new swg_exceptions_actions_1.SwgDeleteExceptionAction(row));
    }
    onCancel() {
        this.store.dispatch(new swg_exceptions_sidebar_modal_actions_1.SwgExceptionsDeleteConfirmModalCloseAction());
    }
}
exports.SwgExceptionsDeleteConfirmationComponent = SwgExceptionsDeleteConfirmationComponent;
