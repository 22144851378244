<mc-modal-header
  headerTitle="{{ '$I18N_SWG_URL_REPORT_MODAL_TILE' | translate }} {{
    '$I18N_SWG_HUMANREADBALE_CATEGORY.' + urlCatTuple.category | translate
  }}"
  subTitle="$I18N_SWG_URL_REPORT_MODAL_DESC"
></mc-modal-header>
<mc-modal-body>
  <strong class="breakword">{{ urlCatTuple.domainUrl }}</strong>

  <form [formGroup]="feedBackForm" class="panel-padding-top">
    <mc-text-area-field
      errorPrefix="$I18N_SWG_URL_REPORT_MODAL.VALIDATION"
      placeholder="{{ '$I18N_SWG_URL_REPORT_MODAL_PLACEHOLDER' | translate }}"
      maxLength="256"
      formControlName="feedback"
      [setFocus]="true"
    >
    </mc-text-area-field>
  </form>
</mc-modal-body>
<mc-modal-footer>
  <button type="button" class="btn btn-secondary" (click)="onCancel()">
    {{ '$I18N_COMMON_BTN_CANCEL' | translate }}
  </button>

  <button [disabled]="!feedBackForm.valid" type="button" class="btn btn-primary" (click)="submit()">
    {{ '$I18N_COMMON_BTN_SEND' | translate }}
  </button>
</mc-modal-footer>
