<mc-collapsable-panel header="$I18N_TTP_IMPERSONATION_LOG_DETAILS_GENERAL_INFORMATION_TITLE" [isExpanded]="true">
  <div class="row mc-detailed-list-row">
    <div class="col-xs-4 mc-detailed-list-label">{{ '$I18N_TTP_IMPERSONATION_LOG_DETAILS_GENERAL_INFORMATION_LABEL_FROM' | translate }}</div>
    <div class="mc-detailed-list-column">{{ logDetails.senderAddress }}</div>
  </div>
  <div class="row mc-detailed-list-row">
    <div class="col-xs-4 mc-detailed-list-label">{{ '$I18N_TTP_IMPERSONATION_LOG_DETAILS_GENERAL_INFORMATION_LABEL_TO' | translate }}</div>
    <div class="mc-detailed-list-column">{{ logDetails.recipientAddress }}</div>
  </div>
  <div class="row mc-detailed-list-row">
    <div class="col-xs-4 mc-detailed-list-label">{{ '$I18N_TTP_IMPERSONATION_LOG_DETAILS_GENERAL_INFORMATION_LABEL_SUBJECT' | translate }}</div>
    <div class="mc-detailed-list-column">{{ logDetails.subject }}</div>
  </div>
  <div class="row mc-detailed-list-row">
    <div class="col-xs-4 mc-detailed-list-label">{{ '$I18N_TTP_IMPERSONATION_LOG_DETAILS_GENERAL_INFORMATION_LABEL_DEFINITION' | translate }}</div>
    <div class="mc-detailed-list-column">{{ logDetails.definition }}</div>
  </div>
  <div class="row mc-detailed-list-row">
    <div class="col-xs-4 mc-detailed-list-label">{{ '$I18N_TTP_IMPERSONATION_LOG_DETAILS_GENERAL_INFORMATION_LABEL_HITS' | translate }}</div>
    <div class="mc-detailed-list-column">{{ logDetails.hits }}</div>
  </div>
  <div class="row mc-detailed-list-row">
    <div class="col-xs-4 mc-detailed-list-label">{{ '$I18N_TTP_IMPERSONATION_LOG_DETAILS_GENERAL_INFORMATION_LABEL_SENDER_IP' | translate }}</div>
    <div class="mc-detailed-list-column">{{ logDetails.senderIpAddress }}</div>
  </div>
  <div class="row mc-detailed-list-row">
    <div class="col-xs-4 mc-detailed-list-label">{{ '$I18N_TTP_IMPERSONATION_LOG_DETAILS_GENERAL_INFORMATION_LABEL_DATE_RECEIVED' | translate }}</div>
    <div class="mc-detailed-list-column">{{ logDetails.eventTime  | mcDatePlusWeekday}}</div>
  </div>
</mc-collapsable-panel>
<hr>
