"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateConnectorModalComponent = void 0;
class CreateConnectorModalComponent {
    constructor(overlayRef) {
        this.overlayRef = overlayRef;
        this.dirSyncUrl = '#services/directory-sync';
    }
    close() {
        this.overlayRef.close();
    }
}
exports.CreateConnectorModalComponent = CreateConnectorModalComponent;
