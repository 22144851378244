'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
/**
 * Created by psambandam on 22/03/17.
 */
angular.module('breadcrumb.service', [])
    .factory('breadcrumbService', ['$rootScope', '$injector', function ($rootScope, $injector) {
        return {
            applyBreadCrumbs
        };
        function applyBreadCrumbs(event, toState, toParams) {
            var _a, _b;
            const tabCtrl = event.currentScope.tabsCtrl;
            if (tabCtrl) {
                const navbarService = $injector.get('navbarService');
                const { hideBreadcrumbs = false, breadcrumbs = [] } = (_b = (_a = tabCtrl.getSelectedTab().state) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : {};
                navbarService.findMenuItemByState(toState, toParams).then(function (menu) {
                    if (menu) {
                        tabCtrl.getSelectedTab().title = menu.item.label;
                        tabCtrl.getSelectedTab().breadcrumbs = hideBreadcrumbs ? [] : menu.breadcrumbs.concat(breadcrumbs).filter(Boolean);
                    }
                }, function () {
                    //TODO ask pradeepa why this case
                    // tabCtrl.getSelectedTab().title = '';
                    tabCtrl.getSelectedTab().breadcrumbs = '';
                });
            }
        }
    }]);
