<div class="mc-identity-policy-details-block mc-identity-policy-steps-container">

    <mc-wizard-step-header class="mc-identity-wizard-step-header"
                           label="$I18N_IDENTITY_CREATE_POLICY_STEPS.TITLE">
    </mc-wizard-step-header>

    <form class="form-horizontal"
          mcDefaultLabelClass="col-sm-3"
          mcDefaultControlContainerClass="col-sm-9"
          [formGroup]="policyDetailsForm">

        <mc-text-field label="$I18N_IDENTITY_CREATE_POLICY_STEPS.POLICY_DETAILS_STEP_LABEL.POLICY_NAME"
                       errorPrefix="$I18N_IDENTITY_CREATE_POLICY_STEPS.VALIDATION.POLICY_NAME"
                       formControlName="policyName" required>
        </mc-text-field>

        <mc-text-area-field label="$I18N_IDENTITY_CREATE_POLICY_STEPS.POLICY_DETAILS_STEP_LABEL.DESCRIPTION"
                            errorPrefix="$I18N_IDENTITY_CREATE_POLICY_STEPS.VALIDATION.POLICY_DESCRIPTION"
                            formControlName="policyDescription">
        </mc-text-area-field>

    </form>
</div>

