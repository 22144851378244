"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UrlToolsPageComponent = void 0;
const url_override_types_enum_1 = require("../../managed-urls/common/enums/url-override-types.enum");
const url_create_actions_1 = require("../../managed-urls/actions/url-create.actions");
const decode_url_actions_1 = require("../actions/decode-url.actions");
const url_create_types_enum_1 = require("../../managed-urls/common/enums/url-create-types.enum");
const reducers = require("../reducers/index");
class UrlToolsPageComponent {
    constructor(store, capsService) {
        this.store = store;
        this.capsService = capsService;
        this.canAllowAndBlockCapExpression = 'Permission.SERVICES_TTP_URL_PROTECT_EDIT';
        this.hasPermission$ = this.capsService.evalCapabilitiesExpression(this.canAllowAndBlockCapExpression);
    }
    ngOnInit() {
        this.decodedUrlStatus$ = this.store.select(reducers.getDecodedUrlStatus);
        this.decodedUrl$ = this.store.select(reducers.getDecodedUrl);
    }
    allowUrl(url) {
        const payload = {
            action: url_override_types_enum_1.UrlOverrideTypes.PERMIT,
            url
        };
        this.store.dispatch(new url_create_actions_1.CreateManagedUrlAction([payload], url_create_types_enum_1.UrlCreateTypes.ALLOW));
    }
    blockUrl(url) {
        const payload = {
            action: url_override_types_enum_1.UrlOverrideTypes.BLOCK,
            url
        };
        this.store.dispatch(new url_create_actions_1.CreateManagedUrlAction([payload], url_create_types_enum_1.UrlCreateTypes.BLOCK));
    }
    decodeUrl(url) {
        this.store.dispatch(new decode_url_actions_1.DecodeUrlAttemptAction(url));
    }
}
exports.UrlToolsPageComponent = UrlToolsPageComponent;
