"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppliesToSidebarEffects = void 0;
const effects_1 = require("@ngrx/effects");
const components_1 = require("@mimecast-ui/components");
const operators_1 = require("rxjs/operators");
const affected_users_by_locations_sidebar_component_1 = require("../locations/affected-users-by-locations-sidebar.component");
const affected_users_by_user_sidebar_component_1 = require("../user/affected-users-by-user-sidebar.component");
const group_selector_component_1 = require("../../group-selector/group-selector.component");
const policy_actions_1 = require("../../../swg/policies/actions/policy.actions");
const user_location_sidebar_actions_1 = require("../actions/user-location-sidebar.actions");
const configurations_table_action_1 = require("../../../custom-domain-block/actions/configurations-table.action");
const affected_users_by_channels_sidebar_component_1 = require("../channels/affected-users-by-channels-sidebar.component");
const device_group_target_sidebar_container_component_1 = require("../../../swg/endpoints-list/container/device-group-target-sidebar/device-group-target-sidebar.container.component");
const policy_actions_2 = require("../../../services/mep/policies/actions/policy.actions");
const models_1 = require("../../../services/mep/policies/models/models");
class AppliesToSidebarEffects {
    constructor(actions$, asideService) {
        this.actions$ = actions$;
        this.asideService = asideService;
        this.openAppliesToSidePanel$ = this.actions$.pipe(effects_1.ofType(user_location_sidebar_actions_1.UserLocationSidebarActionsEnum.Applies_to_POLICY_APPLIES_TO_OPEN), operators_1.map((action) => action.payload), operators_1.tap((payload) => {
            const value = typeof payload === 'string' ? payload : payload.type;
            let sidebarComponent;
            const overlayData = new components_1.AsideConfig();
            overlayData.data = {
                dontCloseOnSelect: true
            };
            overlayData.position = 'right';
            overlayData.size = 'md';
            overlayData.hasBackdrop = true;
            switch (value) {
                case 'user':
                    sidebarComponent = affected_users_by_user_sidebar_component_1.AffectedUsersByUserSidebarComponent;
                    overlayData.data['action'] = (x) => new policy_actions_1.SwgUpdateAffectedUsersStep({ condition: 'user', payload: x });
                    break;
                case 'mep_user':
                    sidebarComponent = affected_users_by_user_sidebar_component_1.AffectedUsersByUserSidebarComponent;
                    overlayData.data['action'] = (x) => new policy_actions_2.MepUpdateAffectedUsersStep({
                        condition: models_1.MepAffectedUsersEnum.INDIVIDUAL_EMAIL_ADDRESS,
                        payload: x
                    });
                    break;
                case 'clp_notifications_user':
                    sidebarComponent = affected_users_by_user_sidebar_component_1.AffectedUsersByUserSidebarComponent;
                    overlayData.data['action'] = (x) => new policy_actions_1.SwgUpdateClpNotificationListStep({
                        condition: 'clp_notifications_user',
                        payload: x
                    });
                    break;
                case 'clp_notifications_channel':
                    sidebarComponent = affected_users_by_channels_sidebar_component_1.AffectedUsersByChannelsSidebarComponent;
                    overlayData.data['action'] = (teamsChannel) => new policy_actions_1.SwgUpdateClpNotificationListStep({
                        condition: 'clp_notifications_channel',
                        teamsChannel
                    });
                    overlayData.data['connectorId'] = payload.connectorId;
                    break;
                case 'redirectEmail':
                    sidebarComponent = affected_users_by_user_sidebar_component_1.AffectedUsersByUserSidebarComponent;
                    overlayData.data['action'] = (x) => new configurations_table_action_1.AddRedirectEmailAction(x.emailAddress);
                    break;
                case 'group':
                    sidebarComponent = group_selector_component_1.GroupSelectorComponent;
                    overlayData.data['action'] = (x) => new policy_actions_1.SwgUpdateAffectedUsersStep({ condition: 'group', payload: x });
                    break;
                case 'mep_group':
                    sidebarComponent = group_selector_component_1.GroupSelectorComponent;
                    overlayData.data['action'] = (x) => new policy_actions_2.MepUpdateAffectedUsersStep({
                        condition: models_1.MepAffectedUsersEnum.PROFILE_GROUP,
                        payload: x
                    });
                    break;
                case 'clp_notifications_group':
                    sidebarComponent = group_selector_component_1.GroupSelectorComponent;
                    overlayData.data['action'] = (x) => new policy_actions_1.SwgUpdateClpNotificationListStep({
                        condition: 'clp_notifications_group',
                        payload: x
                    });
                    break;
                case 'policyGroup':
                    sidebarComponent = group_selector_component_1.GroupSelectorComponent;
                    overlayData.data['action'] = (x) => new configurations_table_action_1.AddNotifyGroupAction(x);
                    break;
                case 'profileGroup':
                    sidebarComponent = group_selector_component_1.GroupSelectorComponent;
                    overlayData.data['action'] = (x) => new configurations_table_action_1.AddProfileGroupAction(x);
                    break;
                case 'redirectGroup':
                    sidebarComponent = group_selector_component_1.GroupSelectorComponent;
                    overlayData.data['action'] = (x) => new configurations_table_action_1.AddRedirectGroupAction(x);
                    break;
                case 'web_device':
                    sidebarComponent = device_group_target_sidebar_container_component_1.DeviceGroupTargetSidebarContainerComponent;
                    overlayData.data['condition'] = 'web_device';
                    overlayData.size = 'lg';
                    break;
                case 'web_device_group':
                    sidebarComponent = device_group_target_sidebar_container_component_1.DeviceGroupTargetSidebarContainerComponent;
                    overlayData.data['condition'] = 'web_device_group';
                    overlayData.size = 'lg';
                    break;
                default:
                    sidebarComponent = affected_users_by_locations_sidebar_component_1.AffectedUsersByLocationsSidebarComponent;
                    overlayData.data['action'] = (x) => new policy_actions_1.SwgUpdateAffectedUsersStep({ condition: 'location', payload: x });
            }
            this.appliesToPanelInstance = this.asideService.open(sidebarComponent, overlayData);
        }));
        this.closeAppliesToSidePanel$ = this.actions$.pipe(effects_1.ofType(user_location_sidebar_actions_1.UserLocationSidebarActionsEnum.Applies_to_POLICY_APPLIES_TO_CLOSE), operators_1.filter(() => !!this.appliesToPanelInstance), operators_1.tap(() => {
            this.appliesToPanelInstance.close();
            this.appliesToPanelInstance = undefined;
        }));
    }
}
__decorate([
    effects_1.Effect({ dispatch: false })
], AppliesToSidebarEffects.prototype, "openAppliesToSidePanel$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], AppliesToSidebarEffects.prototype, "closeAppliesToSidePanel$", void 0);
exports.AppliesToSidebarEffects = AppliesToSidebarEffects;
