"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocationPermissionPipe = void 0;
class LocationPermissionPipe {
    constructor(translateService) {
        this.translateService = translateService;
    }
    transform(value) {
        if (value) {
            return this.translateService.instant('$I18N_IDENTITY_PIPES.LOCATION_PERMISSION.' + value);
        }
        return value;
    }
}
exports.LocationPermissionPipe = LocationPermissionPipe;
