"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const moment = require("moment");
require("mimecast-web-components/src/app/components/date-picker/date-picker"); //input.advancedDatePicker
require("./mailbox-email.less");
angular.module('backuprestore-mailbox-details.slides-export.controller', [
    'input.advancedDatePicker'
])
    .controller('MailBoxesDetailsExportController', ['$scope', '$translate', '$aside', 'backupService', 'mailboxEmailExportRestoreService',
    function ($scope, $translate, $aside, backupService, mailboxEmailExportRestoreService) {
        const self = this;
        const { mailbox, viewType, folderPaths, dismissFunction, userEmailAddress, snapshotId, hasUnsupported } = $scope.$parent.$resolve;
        self.formCtrl = null;
        self.hasUnsupported = hasUnsupported;
        self.exportDates = false;
        self.restrictStartDate = moment(0);
        self.restrictEndDate = moment();
        self.exportFormatOptions = [
            { key: 'eml', value: 'I18N_BACK_RESTORE_EXPORT_FORMAT_ZIP' },
            { key: 'pst', value: 'I18N_BACK_RESTORE_EXPORT_FORMAT_PST' }
        ];
        self.exportTypeOptions = [
            { key: 'mailbox', value: '$I18N_BACKUP_RESTORE_MAILBOXES_EXPORT_RESTORE_MAILBOOX_MODE' },
            { key: 'original', value: '$I18N_BACKUP_RESTORE_MAILBOXES_EXPORT_RESTORE_ORIGINAL_MODE' }
        ];
        self.exportDateRangeCheckBoxTooltip = $translate.instant('$I18N_BACKUP_RESTORE_MAILBOXES_EXPORT_DATE_RANGE_CHECKBOX_TOOLTIP');
        self.ranges = [{ custom: true }];
        self.dates = {};
        self.exportData = {
            recoveryType: 'export',
            mailbox,
            viewType,
            decodedFolderPaths: folderPaths,
            notifyAddress: userEmailAddress
        };
        if (snapshotId) {
            self.exportData['snapshotId'] = snapshotId;
        }
        self.exportEmailsDatesHasChanged = exportEmailsDatesHasChanged;
        self.isFormValid = isFormValid;
        self.createExportTask = createExportTask;
        function exportEmailsDatesHasChanged() {
            if (!self.exportDates) {
                if (self.exportData.emailsAfter) {
                    delete self.exportData.emailsAfter;
                }
                if (self.exportData.emailsBefore) {
                    delete self.exportData.emailsBefore;
                }
            }
        }
        function isFormValid() {
            return !(self.formCtrl && self.formCtrl.isInvalid());
        }
        function createExportTask() {
            return mailboxEmailExportRestoreService.createRecoveryTask(self.exportData, dismissFunction);
        }
    }]);
