"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ1 = exports.ɵ0 = exports.getIsLoadingForEdit = exports.getIsDeviceGroupDeleting = exports.getApiProcessingInProgress = exports.getDeviceListTableManualPagingMetadata = exports.getIsDeviceListTableLoading = exports.getDeviceCount = exports.getDevicesInGroup = exports.getDeviceGroup = exports.getDeviceGroupWizardFlowType = exports.getGroupId = exports.getIsGroupDevicesListLoading = exports.getGroupDevicesList = exports.getDeviceGroupsState = exports.getEndpointsListPurged = exports.getPurgeEndpointsListCount = exports.reducers = void 0;
const store_1 = require("@ngrx/store");
const endpointsList = require("./endpoints-list.reducers");
const deviceGroups = require("./device-groups-reducers");
exports.reducers = {
    endpointsList: endpointsList.reducer,
    deviceGroupsState: deviceGroups.reducer
};
const getSwgEndpointsListState = store_1.createFeatureSelector('swgEndpointsList');
const ɵ0 = state => state.endpointsList;
exports.ɵ0 = ɵ0;
const getEndpointsListState = store_1.createSelector(getSwgEndpointsListState, ɵ0);
exports.getPurgeEndpointsListCount = store_1.createSelector(getEndpointsListState, endpointsList.getPurgeEndpointsListCount);
exports.getEndpointsListPurged = store_1.createSelector(getEndpointsListState, endpointsList.getEndpointsListPurged);
const ɵ1 = state => state.deviceGroupsState;
exports.ɵ1 = ɵ1;
// Device Groups
exports.getDeviceGroupsState = store_1.createSelector(getSwgEndpointsListState, ɵ1);
exports.getGroupDevicesList = store_1.createSelector(exports.getDeviceGroupsState, deviceGroups.getGroupDevicesList);
exports.getIsGroupDevicesListLoading = store_1.createSelector(exports.getDeviceGroupsState, deviceGroups.getIsGroupDevicesListLoading);
exports.getGroupId = store_1.createSelector(exports.getDeviceGroupsState, deviceGroups.getGroupId);
exports.getDeviceGroupWizardFlowType = store_1.createSelector(exports.getDeviceGroupsState, deviceGroups.getDeviceGroupWizardFlowType);
exports.getDeviceGroup = store_1.createSelector(exports.getDeviceGroupsState, deviceGroups.getDeviceGroup);
exports.getDevicesInGroup = store_1.createSelector(exports.getDeviceGroupsState, deviceGroups.getDevicesInGroup);
exports.getDeviceCount = store_1.createSelector(exports.getDeviceGroupsState, deviceGroups.getDeviceCount);
exports.getIsDeviceListTableLoading = store_1.createSelector(exports.getDeviceGroupsState, deviceGroups.getIsTableLoading);
exports.getDeviceListTableManualPagingMetadata = store_1.createSelector(exports.getDeviceGroupsState, deviceGroups.getPagingMetadata);
exports.getApiProcessingInProgress = store_1.createSelector(exports.getDeviceGroupsState, deviceGroups.getApiProcessingInProgress);
exports.getIsDeviceGroupDeleting = store_1.createSelector(exports.getDeviceGroupsState, deviceGroups.getIsDeleting);
exports.getIsLoadingForEdit = store_1.createSelector(exports.getDeviceGroupsState, deviceGroups.getIsLoadingForEdit);
