"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiIntegrationCortexWizardComponent = void 0;
const core_1 = require("@angular/core");
const operators_1 = require("rxjs/operators");
const actions = require("app-new/api-applications/actions");
class ApiIntegrationCortexWizardComponent {
    constructor(capabilitiesService, modalService, store, translateService) {
        this.capabilitiesService = capabilitiesService;
        this.modalService = modalService;
        this.store = store;
        this.translateService = translateService;
        this.availableProviders = [];
        this.onProviderSelected = new core_1.EventEmitter();
        this.onDeleteEmail = new core_1.EventEmitter();
        this.onVerifyAndAddEmail = new core_1.EventEmitter();
        this.onUpdateSpywareSettings = new core_1.EventEmitter();
        this.onUpdateWildfireSettings = new core_1.EventEmitter();
        this.onCreateCortexIntegrationVersion1 = new core_1.EventEmitter();
        this.onUpdateCortexIntegrationVersion1 = new core_1.EventEmitter();
        this.onAuthoriseCortexIntegration = new core_1.EventEmitter();
        this.onAuthoriseCortexIntegrationVersion1 = new core_1.EventEmitter();
        this.onIntegrationCompleted = new core_1.EventEmitter();
        this.OnIntegrationStatusUpdated = new core_1.EventEmitter();
        this.onCancel = new core_1.EventEmitter();
        this.onDeleteGroup = new core_1.EventEmitter();
        this.onAddGroup = new core_1.EventEmitter();
        this.hasNextStep = true;
        this.hasPreviousStep = false;
        this.isLastStep = false;
        this.ACTIVATION_STEP = 1;
        this.SPYWARE_SETTINGS_STEP = 2;
        this.WILDFIRE_SETTINGS_STEP = 3;
        this.NOTIFICATIONS_STEP = 4;
        this.AUTHORISATION_STEP = 5;
        this.SUMMARY_STEP = 6;
        this.updateModalConfig = {
            title: this.translateService.instant('$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.MODALS.SAVE_MODAL.TITLE'),
            content: this.translateService.instant('$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.MODALS.SAVE_MODAL.CONTENT'),
            buttons: [
                {
                    title: this.translateService.instant('$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.MODALS.SAVE_MODAL.BUTTONS.CANCEL'),
                    type: 'default',
                    id: 'cancel'
                },
                {
                    title: this.translateService.instant('$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.MODALS.SAVE_MODAL.BUTTONS.SAVE'),
                    type: 'primary',
                    id: 'save'
                }
            ]
        };
    }
    ngAfterViewInit() {
        if (!!this.wizard) {
            this.wizard.hasNextStep$.subscribe(value => (this.hasNextStep = value));
            this.wizard.hasPreviousStep$.subscribe(value => (this.hasPreviousStep = value));
            this.wizard.isLastStep$.subscribe(value => (this.isLastStep = value));
            if (this.cortexState === 'initiate' || this.cortexState === 'edit') {
                setTimeout(() => {
                    this.gotoStep(this.SPYWARE_SETTINGS_STEP);
                }, 0);
            }
            else if (this.cortexState === 'authorise') {
                if (!!this.isReady) {
                    this.isReady
                        .pipe(operators_1.tap(() => {
                        setTimeout(() => {
                            if (!!this.cortexIntegrationVersion1 &&
                                this.cortexIntegrationVersion1.authorised) {
                                this.gotoStep(this.SUMMARY_STEP);
                            }
                            else {
                                this.gotoStep(this.AUTHORISATION_STEP);
                            }
                        }, 0);
                    }))
                        .subscribe();
                }
            }
        }
    }
    wizardTitle() {
        return this.cortexIntegrationState === 'edit' || this.cortexIntegrationState === 'updated'
            ? '$I18N_API_INTEGRATION_CORTEX_WIZARD.TITLE_EDIT'
            : '$I18N_API_INTEGRATION_CORTEX_WIZARD.TITLE_CREATE';
    }
    showCancel() {
        return this.wizard.currentStep !== this.SUMMARY_STEP;
    }
    cancel() {
        this.onCancel.emit();
    }
    nextStep() {
        if (!!this.wizard) {
            this.wizard.nextStep();
        }
    }
    changeStep() {
        // you have to listen to this event or the underlying wizard doesn't work :(
    }
    gotoStep(step) {
        if (!!this.wizard) {
            this.wizard.showStep(step);
        }
    }
    showPrevious() {
        return this.hasPreviousStep;
    }
    canGotoPrevious() {
        return this.hasPreviousStep && this.wizard.currentStep !== this.SPYWARE_SETTINGS_STEP;
    }
    gotoPreviousStep() {
        if (!!this.wizard) {
            this.wizard.previousStep();
        }
    }
    showNext() {
        return (this.hasNextStep &&
            this.wizard.currentStep !== this.AUTHORISATION_STEP &&
            !this.showCreateCortex());
    }
    showCreateCortex() {
        return (this.wizard.currentStep === this.WILDFIRE_SETTINGS_STEP &&
            this.cortexState === 'initiate' &&
            (this.cortexIntegrationState === 'new' || this.cortexIntegrationState === 'initiate'));
    }
    showUpdateCortex() {
        return (this.wizard.currentStep === this.NOTIFICATIONS_STEP &&
            this.cortexIntegrationVersion1 &&
            this.cortexIntegrationVersion1.authorised &&
            (this.cortexState === 'edit' || this.cortexIntegrationState === 'updated'));
    }
    isCortexConfigured() {
        return (this.availableProviders || []).indexOf('cortex') === -1 && this.cortexState !== 'edit';
    }
    canGotoNext() {
        return this.hasNextStep && this.wizard.currentStep !== this.ACTIVATION_STEP;
    }
    gotoNextStep() {
        if (!!this.wizard) {
            if (this.wizard.currentStep === this.NOTIFICATIONS_STEP ||
                this.wizard.currentStep === this.WILDFIRE_SETTINGS_STEP) {
                if (this.cortexState === 'edit' && this.isChanged) {
                    this.showUpdateModal({ goToSummaryStep: false });
                }
                else {
                    this.onUpdateCortexIntegrationVersion1.emit();
                    this.wizard.nextStep();
                }
            }
            else {
                this.wizard.nextStep();
            }
        }
    }
    showUpdateModal(config) {
        this.modalService
            .open(this.updateModalConfig)
            .pipe(operators_1.first(), operators_1.filter((result) => result === 'save'), operators_1.tap(() => {
            if (this.hasErrorCapabilityAndUrl) {
                this.store.dispatch(new actions.UpdateCortexIntegrationVersion1Action({ hasError: 'update-integration' }));
            }
            else {
                this.store.dispatch(new actions.UpdateCortexIntegrationVersion1Action());
            }
            if (config.goToSummaryStep) {
                this.goToSummaryStep();
            }
            else {
                this.wizard.nextStep();
            }
        }))
            .subscribe();
    }
    showAuthorize() {
        return this.hasNextStep && this.wizard.currentStep === this.AUTHORISATION_STEP;
    }
    showComplete() {
        return this.isLastStep;
    }
    isStepValid() {
        if (!this.isBusy && !!this.wizard) {
            switch (this.wizard.currentStep) {
                case this.ACTIVATION_STEP:
                    return this.activationStep.isValid();
                case this.SPYWARE_SETTINGS_STEP:
                    return this.spywareSettingsStep.isValid();
                case this.WILDFIRE_SETTINGS_STEP:
                    return this.wildfireSettingsStep.isValid();
                case this.NOTIFICATIONS_STEP:
                    return this.notificationsStep.isValid();
                case this.AUTHORISATION_STEP:
                    return this.authorisationStep.isValid();
                default:
                    return true;
            }
        }
        return false;
    }
    isValid() {
        return true;
    }
    providerSelected(provider) {
        this.onProviderSelected.emit(provider);
    }
    deleteEmail(email) {
        this.onDeleteEmail.emit(email);
    }
    deleteGroup(group) {
        this.onDeleteGroup.emit(group);
    }
    addGroup(group) {
        this.onAddGroup.emit(group);
    }
    verifyAndAddEmail(email) {
        this.onVerifyAndAddEmail.emit(email);
    }
    updateSpywareSettings(spywareSettings) {
        this.onUpdateSpywareSettings.emit(spywareSettings);
    }
    updateWildfireSettings(wildfireSettings) {
        this.onUpdateWildfireSettings.emit(wildfireSettings);
    }
    createCortexIntegration() {
        if (!!this.cortexCreated) {
            this.cortexCreated
                .pipe(operators_1.filter(value => value), operators_1.tap(() => this.nextStep()))
                .subscribe();
        }
        this.onCreateCortexIntegrationVersion1.emit();
    }
    goToSummaryStep() {
        if (!!this.cortexUpdated) {
            this.cortexUpdated
                .pipe(operators_1.filter(value => value), operators_1.tap(() => this.gotoStep(this.SUMMARY_STEP)))
                .subscribe();
        }
    }
    updateCortexIntegration() {
        if (this.cortexState === 'edit' && this.isChanged) {
            this.showUpdateModal({ goToSummaryStep: true });
        }
        else {
            this.goToSummaryStep();
            this.onUpdateCortexIntegrationVersion1.emit();
        }
    }
    get showAuthorisationWarning() {
        return (this.wizard.currentStep === this.AUTHORISATION_STEP &&
            !!this.cortexIntegrationVersion1 &&
            !this.cortexIntegrationVersion1.authorised &&
            !this.cortexFresh);
    }
    authoriseCortexIntegration() {
        this.onAuthoriseCortexIntegrationVersion1.emit();
    }
    integrationStatusUpdated(status) {
        this.OnIntegrationStatusUpdated.emit(status);
    }
    complete() {
        this.onIntegrationCompleted.emit();
    }
}
exports.ApiIntegrationCortexWizardComponent = ApiIntegrationCortexWizardComponent;
