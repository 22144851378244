"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DirectorySyncLdapSettingsStepComponent = void 0;
const forms_1 = require("@angular/forms");
const operators_1 = require("rxjs/operators");
const validators_1 = require("../../../../../../api-applications/validators");
const common_1 = require("../../../../../common/common");
const ldap_common_settings_step_component_1 = require("../ldap-common/ldap-common-settings-step.component");
class DirectorySyncLdapSettingsStepComponent extends ldap_common_settings_step_component_1.DirectorySyncLdapCommonSettingsStepComponent {
    constructor(fb, translateService) {
        super(fb, translateService);
        this.fb = fb;
        this.translateService = translateService;
    }
    ngOnInit() {
        this.form = this.fb.group({
            host: [this.integration.host || '', [forms_1.Validators.required, validators_1.validateNotEmpty]],
            alternateHost: this.integration.alternateHost || '',
            encryptionConn: this.getEncryptionConnValue(),
            encryptionMode: this.integration.encryptionMode || 'strict',
            port: [this.integration.port || 636, [forms_1.Validators.required]],
            userDn: [this.integration.userDn || '', [forms_1.Validators.required, validators_1.validateNotEmpty]],
            password: common_1.getPasswordValidation(this.isEditMode, this.integration.hasServerTypeUpdatedOnEdit),
            rootDn: [
                this.integration.rootDn || '',
                [forms_1.Validators.required, forms_1.Validators.pattern(common_1.ROOT_DN_REGEX)]
            ]
        });
        this.update();
        this.formSubscription = this.form.valueChanges.pipe(operators_1.debounceTime(this.delay)).subscribe(() => {
            this.update();
        });
    }
    isValid() {
        return this.form.status === 'VALID';
    }
    ngOnDestroy() {
        this.formSubscription.unsubscribe();
    }
}
exports.DirectorySyncLdapSettingsStepComponent = DirectorySyncLdapSettingsStepComponent;
