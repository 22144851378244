"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
/**
 * Created by asyed
 * Pass options as = [ {key: 'ZIP', value: 'I18N_BACK_RESTORE_EXPORT_FORMAT_ZIP'}, { key: 'PST', value: 'I18N_BACK_RESTORE_EXPORT_FORMAT_PST' } ]
 * Setting default value = set ng-model to the chosen 'key' from the passed option
 * inputControlClasses = 'col-sm-8 mc-form-radio-button' // This will override the class defined in mc-form
 */
(function () {
    'use strict';
    angular.module('predefined-field.radiobutton.directive', [])
        .directive('mcRadioButton', [function () {
            return {
                require: ['ngModel', '^form'],
                restrict: 'EA',
                templateUrl: 'services/services/backup/components/radiobutton/mc-radiobutton.tpl.html',
                scope: {
                    ngModel: '=',
                    label: '@',
                    name: '@',
                    labelClasses: '@',
                    inputControlClasses: '@',
                    options: '=',
                    mcRequired: '=',
                    tooltip: '@'
                },
                link($scope, $element, $attrs, ctrls) {
                    const mcform = $element.closest('mc-form');
                    $scope.defaultLabelClass = mcform.attr('default-label-classes');
                    $scope.defaultControlClass = mcform.attr('default-input-control-classes');
                    $scope.showMandatoryIndicator = mcform.attr('show-mandatory-indicator');
                    $scope.calculatedLabelClass = $scope.labelClasses ? $scope.labelClasses : $scope.defaultLabelClass;
                    $scope.calculatedControlClass = $scope.inputControlClasses ? $scope.inputControlClasses : $scope.defaultControlClass;
                    const $ngModel = ctrls[0];
                    $scope.form = ctrls[1];
                    if ($scope.mcRequired) {
                        $ngModel.$setValidity('required', false);
                    }
                    $scope.setValue = function () {
                        $ngModel.$setValidity('required', true);
                    };
                }
            };
        }]);
})();
