"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.storageUsedUnit = exports.sizePosition = exports.sections = exports.columnList = void 0;
const components_1 = require("@mimecast-ui/components");
exports.columnList = [
    'displayName',
    'mailbox',
    'storageUsed',
    'firstStarted',
    'lastActive',
    'lastStatus',
    'syncedBy'
];
exports.sections = [
    {
        name: 'status',
        filters: ['error', 'in_progress', 'pending', 'success', 'warning']
    }
];
exports.sizePosition = components_1.TextAlign.LEFT;
exports.storageUsedUnit = components_1.UnitEnum.KB;
