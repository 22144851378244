"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RejectedAndDeferredPageComponent = void 0;
const actions = require("../actions/list.action");
class RejectedAndDeferredPageComponent {
    constructor(store, tabService) {
        this.store = store;
        this.tabService = tabService;
        const tab = this.tabService.getSelectedTab();
        tab.onRefresh = () => {
            this.store.dispatch(new actions.GetRejectedAction());
            this.store.dispatch(new actions.GetDeferredAction());
        };
    }
    ngOnInit() {
        // get data for overview tab
    }
    ngOnDestroy() {
        // reset state
    }
}
exports.RejectedAndDeferredPageComponent = RejectedAndDeferredPageComponent;
