"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UrlAsideComponent = void 0;
const url_create_types_enum_1 = require("../../common/enums/url-create-types.enum");
const url_update_actions_1 = require("../../actions/url-update.actions");
const url_create_actions_1 = require("../../actions/url-create.actions");
const fromManagedUrl = require("../../reducers");
class UrlAsideComponent {
    constructor(overlayRef, data, store) {
        this.overlayRef = overlayRef;
        this.store = store;
        this.urlDetails$ = this.store.select(fromManagedUrl.getManagedUrlData);
        this.data = data;
    }
    onSave(formValues) {
        if (Array.isArray(formValues)) {
            this.store.dispatch(new url_create_actions_1.CreateManagedUrlAction(formValues, url_create_types_enum_1.UrlCreateTypes.CREATE));
        }
        else {
            this.store.dispatch(new url_update_actions_1.UpdateManagedUrlAction(formValues));
        }
    }
    onCancel() {
        this.data.isEditAllowed = false;
        this.store.dispatch(new url_create_actions_1.CloseUrlSidePanelAction());
    }
    onCloseAside() {
        this.data.isEditAllowed = false;
        this.store.dispatch(new url_create_actions_1.CloseUrlSidePanelAction());
    }
}
exports.UrlAsideComponent = UrlAsideComponent;
