"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Timeout = void 0;
function Timeout(timeInMilliseconds = 0) {
    return function (target, key, descriptor) {
        const methodToCall = descriptor.value;
        descriptor.value = function (...args) {
            const setTimeoutId = setTimeout(() => {
                methodToCall.apply(this, args);
            }, timeInMilliseconds);
            return () => {
                clearTimeout(setTimeoutId);
            };
        };
        return descriptor;
    };
}
exports.Timeout = Timeout;
