"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApplieToStepSimpleChangeSelectors = exports.FieldName = exports.AppliesFromToOptions = exports.AddressesBasedOnOptions = void 0;
var AddressesBasedOnOptions;
(function (AddressesBasedOnOptions) {
    AddressesBasedOnOptions["ENVELOPE"] = "envelope_from";
    AddressesBasedOnOptions["HEADER"] = "header_from";
    AddressesBasedOnOptions["ENVELOPE_HEADER"] = "both";
})(AddressesBasedOnOptions = exports.AddressesBasedOnOptions || (exports.AddressesBasedOnOptions = {}));
var AppliesFromToOptions;
(function (AppliesFromToOptions) {
    AppliesFromToOptions["ALL"] = "everyone";
    AppliesFromToOptions["ALL_INTERNAL"] = "internal_addresses";
    AppliesFromToOptions["ALL_EXTERNAL"] = "external_addresses";
    AppliesFromToOptions["DOMAIN"] = "email_domain";
    AppliesFromToOptions["ADDRESS_GROUP"] = "profile_group";
    AppliesFromToOptions["ADDRESS_ATTRIBUTE"] = "address_attribute_value";
    AppliesFromToOptions["EMAIL_ADDRESS"] = "individual_email_address";
    AppliesFromToOptions["FREE_MAIL_DOMAINS"] = "free_mail_domains";
    AppliesFromToOptions["HEADER_DISPLAY_NAME"] = "header_display_name";
})(AppliesFromToOptions = exports.AppliesFromToOptions || (exports.AppliesFromToOptions = {}));
var FieldName;
(function (FieldName) {
    FieldName["APPLIES_FROM_ADDRESS_GROUP"] = "appliesFromAddressGroup";
    FieldName["APPLIES_TO_ADDRESS_GROUP"] = "appliesToAddressGroup";
    FieldName["APPLIES_TO"] = "appliesTo";
    FieldName["APPLIES_FROM"] = "appliesFrom";
    FieldName["IP_RANGE"] = "ipRange";
    FieldName["APPLIES_TO_IS_EQUAL_TO"] = "appliesToIsEqualTo";
    FieldName["APPLIES_FROM_IS_EQUAL_TO"] = "appliesFromIsEqualTo";
})(FieldName = exports.FieldName || (exports.FieldName = {}));
var ApplieToStepSimpleChangeSelectors;
(function (ApplieToStepSimpleChangeSelectors) {
    ApplieToStepSimpleChangeSelectors["POLICY_APPLIES_TO_DATA"] = "policyAppliesToData";
    ApplieToStepSimpleChangeSelectors["WHERE_ATTRIBUTES"] = "fromToWhereAttributes";
    ApplieToStepSimpleChangeSelectors["IS_AAA_OR_FAA_USER"] = "isAAAorFAAUser";
})(ApplieToStepSimpleChangeSelectors = exports.ApplieToStepSimpleChangeSelectors || (exports.ApplieToStepSimpleChangeSelectors = {}));
