"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatusUpdateType = exports.ImTaskStatus = exports.ImTaskType = void 0;
var ImTaskType;
(function (ImTaskType) {
    ImTaskType["ACTIVE"] = "active";
    ImTaskType["LEGACY_INGESTION"] = "legacy_ingestion";
})(ImTaskType = exports.ImTaskType || (exports.ImTaskType = {}));
var ImTaskStatus;
(function (ImTaskStatus) {
    ImTaskStatus["ENABLED"] = "enabled";
    ImTaskStatus["DISABLED"] = "disabled";
})(ImTaskStatus = exports.ImTaskStatus || (exports.ImTaskStatus = {}));
var StatusUpdateType;
(function (StatusUpdateType) {
    StatusUpdateType["ENABLE"] = "Enable";
    StatusUpdateType["DISABLE"] = "Disable";
})(StatusUpdateType = exports.StatusUpdateType || (exports.StatusUpdateType = {}));
