<div class="sync-recover-overview">
  <mc-modal-header [headerTitle]="'$I18N_SYNC_AND_RECOVER_OVERVIEW_MODAL.TITLE'"></mc-modal-header>

  <mc-modal-body>
    <div class="modal-body">
      <ng-container *ngIf="isLoadingChartData$ | async; else modalContent">
        <div class="spinner-container">
          <mc-spinner></mc-spinner>
          <p>
            {{ '$I18N_SYNC_AND_RECOVER_OVERVIEW_MODAL.LOADING' | translate }}
          </p>
        </div>
      </ng-container>

    </div>
  </mc-modal-body>

  <mc-modal-footer class="footer-extra-padding">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="close()"
    >
      {{ '$I18N_SYNC_AND_RECOVER_OVERVIEW_MODAL.BUTTONS.DISMISS' | translate }}
    </button>
  </mc-modal-footer>
</div>

<ng-template #modalContent>
  <ng-container *ngIf="isChartLoadedSuccess$ | async; else overviewFailedtemplate">
    <ng-container *ngIf="showCharts$ | async; else noDataTemplate">
      <div class="row">
        <mc-sync-recover-health-score [data]="healthScore$ | async"></mc-sync-recover-health-score>
      </div>
      <div class="row">
        <div class="col-md-6">
          <mc-sync-recover-sync-tasks [data]="syncTaskData$ | async"></mc-sync-recover-sync-tasks>
        </div>
        <div class="col-md-6">
          <mc-sync-recover-mailbox-status [data]="mailboxStatus$ | async"></mc-sync-recover-mailbox-status>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #noDataTemplate>
  <mc-no-data-display></mc-no-data-display>
</ng-template>

<ng-template #overviewFailedtemplate>
  <mc-overview-load-failed></mc-overview-load-failed>
</ng-template>
