"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectorsPageComponent = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const table_store_1 = require("@mimecast-ui/table-store");
const connectors_list_impl_service_1 = require("../../services/connectors-list-impl.service");
const connector_actions_1 = require("../../actions/connector.actions");
const reducers_1 = require("../../reducers");
const reducers_2 = require("../../../connectors-migrate/reducers");
class ConnectorsPageComponent extends table_store_1.TableBaseComponent {
    constructor(store, capabilitiesService, cookieService, stateService) {
        super(store, connectors_list_impl_service_1.ConnectorsListImplService.TABLE_ID);
        this.capabilitiesService = capabilitiesService;
        this.cookieService = cookieService;
        this.stateService = stateService;
        this.destroy$ = new rxjs_1.Subject();
        this.displayMigration$ = this.store.select(reducers_2.getConnectorsToMigrate);
        this.azureStandardIntegrationsToMigrate$ = this.store.select(reducers_2.getAzureStandardIntegrationsToMigrate);
        this.isMigrationEnabled$ = this.capabilitiesService.hasCapability('Temporary.Connectors.Migration');
        this.isServerConnectorsDisabled$ = this.capabilitiesService.hasCapability('Temporary.Services.Server.Connectors.Disabled') &&
            this.capabilitiesService.hasCapability('Permission.SERVER_CONNECTIONS_READ');
        this.migrationResolved = new rxjs_1.BehaviorSubject(false);
        this.migrationResolved$ = this.migrationResolved.asObservable();
        this.migrationInProgress = new rxjs_1.BehaviorSubject(false);
        this.migrationInProgress$ = this.migrationInProgress.asObservable();
        const isDeletingConnector$ = this.store.select(reducers_1.isDeletingConnector);
        isDeletingConnector$
            .pipe(operators_1.distinctUntilChanged(), operators_1.skip(1), operators_1.takeUntil(this.destroy$))
            .subscribe(deletingConnector => {
            if (!deletingConnector) {
                this.dispatchFilterAction();
            }
        });
    }
    ngOnDestroy() {
        super.ngOnDestroy();
        this.destroy$.next(true);
        this.destroy$.complete();
    }
    ngOnInit() {
        super.ngOnInit();
        rxjs_1.combineLatest([
            this.isMigrationEnabled$,
            this.displayMigration$,
            this.azureStandardIntegrationsToMigrate$
        ])
            .pipe(operators_1.delay(200), operators_1.distinctUntilChanged(), operators_1.takeUntil(this.destroy$))
            .subscribe(combined => {
            const [isMigrationEnabled, connectorsToMigrate, azureStandardIntegrationsToMigrate] = combined;
            this.migrationInProgress.next((this.cookieService.get('migrationStatusH23') === 'inprogress' ||
                connectorsToMigrate > 0 ||
                azureStandardIntegrationsToMigrate > 0) &&
                isMigrationEnabled);
            this.migrationResolved.next(true);
        });
    }
    ngAfterViewInit() {
        const openTab = this.stateService.$state.params.tab;
        if (openTab) {
            const tabGroupReady = setInterval(() => {
                if (this.tabGroup) {
                    clearInterval(tabGroupReady);
                    const matchingTab = this.tabGroup.tabs.findIndex(tab => tab.name.toLowerCase().indexOf(openTab) !== -1);
                    this.tabGroup.tabClicked(matchingTab);
                }
            }, 100);
        }
    }
    onCreateConnector() {
        this.store.dispatch(new connector_actions_1.OpenCreateConnectorWizardAction());
    }
    onDeleteConnector(connectorId) {
        this.store.dispatch(new connector_actions_1.DeleteConnectorAction({ id: connectorId }));
    }
}
exports.ConnectorsPageComponent = ConnectorsPageComponent;
