"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiGatewayDisplayCredentialsModalComponent = void 0;
const actions = require("../../../../actions");
class ApiGatewayDisplayCredentialsModalComponent {
    constructor(overlayRef, credentials, notificationService, translateService, featureFlagService, store) {
        this.overlayRef = overlayRef;
        this.credentials = credentials;
        this.notificationService = notificationService;
        this.translateService = translateService;
        this.featureFlagService = featureFlagService;
        this.store = store;
        this.showClientId = false;
        this.showClientSecret = false;
        this.displayClientSecretDivAndWarning = false;
    }
    ngOnInit() {
        if (this.credentials.clientSecret) {
            this.displayClientSecretDivAndWarning = true;
        }
        this.notification = {
            status: 'warning',
            msg: this.translateService.instant('$I18N_API_GATEWAY_DISPLAY_CREDENTIALS_MODAL.NOTIFICATION'),
            boxed: true
        };
    }
    get appName() {
        return this.credentials.applicationName;
    }
    get clientId() {
        return this.credentials.clientId ? this.credentials.clientId : this.credentials.clientKey;
    }
    get clientSecret() {
        return this.credentials.clientSecret;
    }
    close() {
        this.overlayRef.close();
        if (this.featureFlagService.getBooleanFlag('Adcon.Services.Applications.2.0.client.secret.Regeneration')) {
            if (this.credentials.appId) {
                this.store.dispatch(new actions.CreateGatewayApplicationSuccessAction(this.credentials));
            }
            else {
                this.store.dispatch(new actions.GetCredentialsAction(this.credentials));
            }
        }
    }
    showClientIdClicked() {
        this.showClientId = true;
        this.showClientSecret = false;
    }
    hideClientIdClicked() {
        this.showClientId = false;
    }
    showClientSecretClicked() {
        this.showClientSecret = true;
        this.showClientId = false;
    }
    hideClientSecretClicked() {
        this.showClientSecret = false;
    }
    keyCopied(message) {
        this.notificationService.success({
            msg: this.translateService.instant(message)
        });
    }
}
exports.ApiGatewayDisplayCredentialsModalComponent = ApiGatewayDisplayCredentialsModalComponent;
