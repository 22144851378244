"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LexiconQueryValidator = void 0;
class LexiconQueryValidator {
    static splitQuery(terms, regex) {
        let splitQuery = [];
        if (terms.match(regex)) {
            splitQuery = [...terms.match(regex)].filter(item => item !== 'AND' && item !== 'OR');
        }
        return splitQuery;
    }
    static splitProximity(terms, regex) {
        const nearRegex = /NEAR\([\s\d]*,{0,1}|\~[\d]+|\(|\)/g;
        let splitProximityQuery = [];
        if (terms.match(regex)) {
            splitProximityQuery = [...terms.match(regex)];
            splitProximityQuery = splitProximityQuery.reduce((arr, prox) => {
                return [...arr, ...prox.replace(nearRegex, '').split(',')];
            }, []);
        }
        return splitProximityQuery;
    }
    static validateMaxQuery(max) {
        const regex = /([\w?']+)|"([^"]*)"/g;
        return (QueryControl) => {
            const Query = QueryControl.value;
            this.queryLength = this.splitQuery(Query, regex).length;
            return this.queryLength > max ? { maxQuery: true } : null;
        };
    }
    static validateMinLengthWords(min) {
        const queryRegex = /([^!"*:?()\s]+)|"([^"]*)"/g;
        const proximityRegex = /(NEAR\([a-z\s\d,]*\))|(\([a-z\s\d,]*\)~\d+)/gi;
        return (QueryControl) => {
            const query = QueryControl.value;
            const proxRemovedQuery = query.replace(proximityRegex, '');
            return [
                ...this.splitQuery(proxRemovedQuery, queryRegex),
                ...this.splitProximity(query, proximityRegex)
            ].some(word => word.replace(/"/g, '').trim().length < min)
                ? { minQueryLength: true }
                : null;
        };
    }
    static validateMaxLengthWords(max) {
        const regex = /("[^"]+"|(\+)).*?|([\w?']+)/g;
        return (QueryControl) => {
            const Query = QueryControl.value;
            return this.splitQuery(Query, regex)
                .filter(value => value.replace(/"(.*?)"/g, ''))
                .some(word => word.trim().length > max)
                ? { maxQueryLength: true }
                : null;
        };
    }
    static validateMaxLengthPhrases(max) {
        const regex = /"(.*?)"/g;
        return (QueryControl) => {
            const Query = QueryControl.value;
            return this.splitQuery(Query, regex).some(word => word.replace(/"/g, '').trim().length > max)
                ? { maxQueryPhraseLength: true }
                : null;
        };
    }
    static validateProximity() {
        return (queryControl) => {
            const regex = /(NEAR\s\()+/gi;
            const query = queryControl.value;
            return regex.test(query) ? { proximityWarning: true } : null;
        };
    }
}
exports.LexiconQueryValidator = LexiconQueryValidator;
