"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AwarenessTemplateListService = void 0;
const operators_1 = require("rxjs/operators");
class AwarenessTemplateListService {
    /**
     * Depends on the api service
     */
    constructor(service) {
        this.service = service;
    }
    /**
     * gets the templates from the server
     */
    getTemplates() {
        return this.service
            .getAllTemplates()
            .pipe(operators_1.map((list) => this.transformServerResponseToClient(list)));
    }
    /**
     * Deletes the template on the server
     * Returns the removed object
     */
    deleteTemplate(id) {
        return this.service.deleteTemplate(id);
    }
    /**
     * Transform server data format to client data format
     */
    transformServerResponseToClient(response) {
        return (response &&
            response.map((serverTemplate) => {
                return {
                    id: serverTemplate.id,
                    name: serverTemplate.name,
                    customTips: !!serverTemplate.safetyTips && serverTemplate.safetyTips.length > 0,
                    mimecastTips: !!serverTemplate.useMimecastTips,
                    customTitles: this.hasCustomTitles(serverTemplate)
                };
            }));
    }
    /**
     * Check if this template has custom titles
     *  - This means check all the sections for title and subtitle
     */
    hasCustomTitles(serverTemplate) {
        return (!this.isEmpty(serverTemplate.harmfulMisidentifiedWarn) ||
            !this.isEmpty(serverTemplate.safeMisidentified) ||
            !this.isEmpty(serverTemplate.harmfulIdentified) ||
            !this.isEmpty(serverTemplate.harmfulMisidentifiedBlock) ||
            !this.isEmpty(serverTemplate.userChallenge));
    }
    /**
     * Check if this section(page) has title/subtitle
     */
    isEmpty(page) {
        return !page || (!page.title && !page.subtitle);
    }
}
exports.AwarenessTemplateListService = AwarenessTemplateListService;
