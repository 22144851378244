<div class="mc-header-first-row">
  <div class="status-info">
    <div class="status-info-span-container">
      <span>Status:</span>
      <span>{{ messageInfo.status | mcEmailStatusDisplay }}</span>
    </div>
  </div>
  <mc-paginator
    *ngIf="paginatorData.currentItemIndex > 0 " 
    [start]="paginatorData?.currentItemIndex"
    [end]="paginatorData?.pageSize"
    [isLoading]="isLoading"
    [previousToken]="paginatorData?.previous"
    [nextToken]="paginatorData?.next"
    (paginate)="selectRow.emit($event.token)"
  >
  </mc-paginator>
</div>

<div class="panel-padding-bottom" *ngIf="showSelectEmailDropDown()">
  <mc-select-searchable
    [value]="selectedEmail"
    [overrideLabel]="selectedEmailLabel"
    [compare]="compareFn"
    data-test="selectEmailDropdown"
  >
    <mc-option
      label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TOOLBAR.LABEL_RECEIVED_VIEW_SENDER"
      header
    ></mc-option>
    <mc-option
      *ngIf="recipientFromEmail"
      [value]="recipientFromEmail"
      [label]="recipientFromEmail.owner"
      (click)="selectEmail.emit({ email: recipientFromEmail.owner, isDelivery: false })"
    >
    </mc-option>
    <mc-option divider></mc-option>
    <mc-option
      label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TOOLBAR.LABEL_DELIVERY_VIEW_RECIPIENT"
      header
    ></mc-option>
    <ng-container *ngFor="let email of deliveredEmails; trackBy: trackByFn">
      <mc-option
        [value]="email"
        [label]="email.owner"
        (click)="selectEmail.emit({ email: email.owner, isDelivery: true })"
      >
      </mc-option>
    </ng-container>
  </mc-select-searchable>
</div>

<div class="second-row">
  <mc-top-header-recipient
    [messageInfo]="messageInfo"
    (permitForRecipient)="permitForRecipient.emit()"
    (blockForRecipient)="blockForRecipient.emit()"
    (printRecipient)="printRecipient.emit()"
    [selectedEmail]="selectedEmail"
    *ngIf="showRecipientButtons()"
  >
  </mc-top-header-recipient>

  <mc-action-list-dropdown-button
    label="Release"
    (defaultAction)="release.emit()"
    *ngIf="showReleaseButton()"
    data-test="releaseDropdown"
    [disabled]="enableReleaseButton()"
  >
    <mc-dropdown-item
      label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TOOLBAR.LABEL_RELEASE"
      (click)="release.emit()"
    >
    </mc-dropdown-item>
    <mc-dropdown-item
      *mcCapabilities="
        'Permission.MONITORING_TTP_ATTACHMENT_PROTECT_READ || Permission.SERVICES_TTP_ATTACHMENT_PROTECT_READ'
      "
      label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TOOLBAR.LABEL_RELEASE_TO_SANDBOX"
      (click)="releaseToSandbox.emit()"
    >
    </mc-dropdown-item>
    <li role="separator" class="divider"></li>
    <mc-dropdown-item
      label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TOP_HEADER_RECIPIENT.LABEL_PERMIT_FOR_RECIPIENT"
      (click)="permitForRecipient.emit()"
    >
    </mc-dropdown-item>
  </mc-action-list-dropdown-button>

  <button
    *ngIf="showPendingDeliveryButtons()"
    class="btn btn-secondary"
    data-test="retry"
    translate="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TOOLBAR.LABEL_RETRY"
    (click)="retry.emit()"
  ></button>

  <div *ngIf="showRejectButton()">
    <mc-action-list-dropdown-button
      label="Reject"
      (defaultAction)="reject.emit()"
      *ngIf="messageInfo.status === 'held'"
      data-test="rejectDropdown"
      [disabled]="enableRejectButton()"
    >
      <mc-dropdown-item
        label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TOOLBAR.LABEL_REJECT"
        (click)="reject.emit()"
      >
      </mc-dropdown-item>
      <mc-dropdown-item
        label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TOOLBAR.LABEL_REJECT_NOTIFY_SENDER"
        (click)="rejectNotifySender.emit()"
      >
      </mc-dropdown-item>
      <mc-dropdown-item
        label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TOOLBAR.LABEL_REJECT_WITH_COMMENTS"
        (click)="rejectWithNarrative.emit()"
      >
      </mc-dropdown-item>
      <li role="separator" class="divider"></li>
      <mc-dropdown-item
        label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TOP_HEADER_RECIPIENT.LABEL_BLOCK_FOR_RECIPIENT"
        (click)="blockForRecipient.emit()"
      >
      </mc-dropdown-item>
    </mc-action-list-dropdown-button>

    <button
      *ngIf="showPendingDeliveryButtons()"
      data-test="rejectQueued"
      class="btn btn-secondary"
      translate="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TOOLBAR.LABEL_REJECT"
      (click)="rejectQueued.emit()"
    ></button>
  </div>

  <button
    *ngIf="showPendingDeliveryButtons()"
    class="btn btn-secondary"
    data-test="earlyBounce"
    translate="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TOOLBAR.LABEL_EARLY_BOUNCE"
    (click)="earlyBounce.emit()"
  ></button>

  <button
    class="btn btn-secondary"
    data-test="recalculateDeliveryRoute"
    translate="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TOOLBAR.LABEL_RECALCULATE_DELIVERY_ROUTE"
    (click)="recalculateDeliveryRoute.emit()"
    *ngIf="showPendingDeliveryButtons()"
  ></button>

  <ng-container *mcCapabilities="'!Permission.SERVICE_DELIVERY_DENY_REPORT_AS_SUBMISSIONS'">
    <div *ngIf="showReportButton()">
      <mc-list-dropdown-button
        data-test="reportAsDropdownCapabilities"
        label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TOOLBAR.LABEL_REPORT"
        [disabled]="enableReportButton()"
      >
        <mc-dropdown-item label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TOOLBAR.LABEL_REPORT_AS" header>
        </mc-dropdown-item>
        <mc-dropdown-item
          label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TOOLBAR.LABEL_SPAM"
          (click)="reportAs.emit({ reportType: 'spam', id: messageInfo.id })"
        >
        </mc-dropdown-item>
        <mc-dropdown-item
          label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TOOLBAR.LABEL_MALWARE"
          (click)="reportAs.emit({ reportType: 'malware', id: messageInfo.id })"
        >
        </mc-dropdown-item>
        <mc-dropdown-item
          label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TOOLBAR.LABEL_PHISHING"
          (click)="reportAs.emit({ reportType: 'phishing', id: messageInfo.id })"
        >
        </mc-dropdown-item>
      </mc-list-dropdown-button>
    </div>
  </ng-container>

  <div *ngIf="showActionButton()">
    <div *mcCapabilities="contentViewCap">
      <mc-list-dropdown-button disableCaret="true" data-test="extraOptionsDropdown">
        <mc-dropdown-item
          label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TOOLBAR.LABEL_FORWARD"
          (click)="forward.emit()"
        >
        </mc-dropdown-item>
        <mc-dropdown-item
          label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TOOLBAR.LABEL_PRINT"
          (click)="printRecipient.emit()"
        >
        </mc-dropdown-item>
      </mc-list-dropdown-button>
    </div>
  </div>
</div>
