"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Message = void 0;
const contents_actions_1 = require("app-new/components/tree/actions/contents.actions");
const content_type_1 = require("app-new/sync-recover/exchange/containers/snapshot/detail/models/content-type/content-type");
class Message extends content_type_1.ContentType {
    constructor(isRootNode) {
        super(isRootNode ? ['from', 'subject', 'dateReceived', 'size'] : []);
        this.CONTENT_TYPE = 'MESSAGE';
    }
    getLoadContentsAction(payload) {
        return new contents_actions_1.LoadFolderEmailsAction(payload);
    }
    getSearchContentsAction(payload) {
        return new contents_actions_1.LoadSearchFolderEmailsAction(payload);
    }
    getContentType() {
        return this.CONTENT_TYPE;
    }
}
exports.Message = Message;
