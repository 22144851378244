"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DashboardSettingsBuilder = void 0;
class DashboardSettingsBuilder {
    withModifiedBy(modifiedBy) {
        this.modifiedBy = modifiedBy;
        return this;
    }
    withModified(modified) {
        this.modified = modified;
        return this;
    }
    withQueues(queues) {
        this.queues = queues;
        return this;
    }
    withPendingPercentages(percentages) {
        this.pendingPercentages = percentages;
        return this;
    }
    withOldestQueueMessages(ages) {
        this.oldestQueueMessages = ages;
        return this;
    }
    withQueueType(queueType) {
        this.queueType = queueType;
        return this;
    }
    build() {
        const settings = {
            modified: this.modified,
            modifiedBy: this.modifiedBy,
            queues: this.queues,
            pendingPercentages: this.pendingPercentages,
            oldestQueueMessages: this.oldestQueueMessages,
            queueType: this.queueType
        };
        return settings;
    }
}
exports.DashboardSettingsBuilder = DashboardSettingsBuilder;
