"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GoogleAnalyticsAccountInformationProvider = void 0;
const operators_1 = require("rxjs/operators");
const store_1 = require("@ngrx/store");
const reducers_1 = require("../../../../reducers");
const base_google_analytics_information_provider_1 = require("../../base-google-analytics-information.provider");
class GoogleAnalyticsAccountInformationProvider extends base_google_analytics_information_provider_1.BaseGoogleAnalyticsInformationProvider {
    constructor(store) {
        super();
        this.store = store;
    }
    getInformation() {
        return this.store.pipe(store_1.select(reducers_1.getAccount), operators_1.filter(v => !!v), operators_1.first(), operators_1.map((account) => ({
            account: {
                id: account.accountCode,
                gridId: account.region
            }
        })));
    }
}
exports.GoogleAnalyticsAccountInformationProvider = GoogleAnalyticsAccountInformationProvider;
