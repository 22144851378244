<mc-layout-list-table extendClass="mc-create-policy">
  <mc-table
    tableId="mep/logs/list"
    [isLoading]="isLoading$ | async"
    [data]="tableData$ | async"
    [columns]="columns"
    translatePrefix="$18N_MEP_LOG_LIST.TABLE"
    (rowClick)="onRowClick($event)"
  >
    <button
      [disabled]="!(tableData$ | async)?.length"
      class="mc-table-actions btn btn-primary panel-half-margin-right"
      (click)="onExportClick()"
    >
      {{ '$18N_MEP_LOG_LIST.TABLE.EXPORT' | translate }}
    </button>
<!--    <mc-filters-->
<!--      (paginatorChange)="onPaginationChange($event)"-->
<!--      [metadata]="metaData$ | async"-->
<!--    >-->
<!--      <mc-filter-bundle-filter-by-and-search-by-->
<!--        (filtersChange)="onBundleFilterChange($event)"-->
<!--        placeholder="$18N_MEP_LOG_LIST.TABLE.SEARCH.PLACEHOLDER"-->
<!--        [sections]="searchFilters"-->
<!--        [searchByFields]="filterTypes"-->
<!--      >-->
<!--      </mc-filter-bundle-filter-by-and-search-by>-->

<!--      <mc-filters-bundle-date-range-->
<!--        (filtersChange)="onDateRangeFilterChange($event)"-->
<!--        [config]="dateRangePickerConfig"-->
<!--      >-->
<!--      </mc-filters-bundle-date-range>-->
<!--    </mc-filters>-->

    <mc-filters (paginatorChange)="onPaginationChange($event)" [metadata]="metaData$ | async">
      <mc-filter-search
        (search)="onSearch($event)"
        (clear)="onSearchClear()"
        [options]="filterTypes"
      >
      </mc-filter-search>


      <mc-filters-bundle-column-values
        [sections]="searchFilters"
        (filtersChange)="onBundleFilterChange($event)">
      </mc-filters-bundle-column-values>

      <mc-filters-bundle-date-range
        (filtersChange)="onDateRangeFilterChange($event)"
        [config]="dateRangePickerConfig"
      >
      </mc-filters-bundle-date-range>
    </mc-filters>

    <mc-column key="to">
      <mc-body-cell *mcBodyCellDef="let row">
        <div class="to">
          <span class="recipient">{{row.toAddress}}</span>
          <span *ngIf="row.toAddressCount > 1">&nbsp;+{{row.toAddressCount - 1}}</span>
        </div>
      </mc-body-cell>
    </mc-column>

    <mc-column key="pmr">
      <mc-body-cell *mcBodyCellDef="let row">
        <div class="pmr">
          <span class="recipient">{{row.flaggedToAddress}}</span>
          <span *ngIf="row.flaggedToAddressCount > 1">
            &nbsp;+{{row.flaggedToAddressCount - 1}}
          </span>
        </div>
      </mc-body-cell>
    </mc-column>

    <mc-column key="subject">
      <mc-body-cell *mcBodyCellDef="let row">
        <p>{{ row.subject.trim() || '-' }}</p>
      </mc-body-cell>
    </mc-column>

    <mc-column key="status">
      <mc-body-cell *mcBodyCellDef="let row">
        <mc-mep-log-status [status]="row.status"></mc-mep-log-status>
      </mc-body-cell>
    </mc-column>

    <mc-column-date key="createTime"></mc-column-date>

    <mc-column-actions
      key="right-column"
      mcShowColumnConfig
      [columnsAlwaysVisible]="columnsAlwaysVisible"
    >
      <mc-row-actions *mcRowActions="let row" [offSet]="150">
        <ng-container *ngIf="hasMepLogReleaseDropPermission$ | async">
          <li
            [class.disabled]="row.status !== mepLogHeldStatus"
            mcRowAction="$18N_MEP_LOG_LIST.TABLE.MEATBALL_OPTIONS.DROP"
            (click)="onAdminAction(row, mepAdminLogActions.DROP)"
          ></li>
          <li
            [class.disabled]="row.status !== mepLogHeldStatus"
            mcRowAction="$18N_MEP_LOG_LIST.TABLE.MEATBALL_OPTIONS.RELEASE"
            (click)="onAdminAction(row, mepAdminLogActions.RELEASE)"
          ></li>
        </ng-container>
        <li mcRowActionSeparator></li>
        <li
          mcRowAction="$I18N_COMMON_BTN_VIEWDETAILS"
          (click)="onRowClick(row)"
        ></li>
      </mc-row-actions>
    </mc-column-actions>
    <mc-empty-results keyTitle="$18N_MEP_LOG_LIST.TABLE.NO_DATA"></mc-empty-results>
  </mc-table>
</mc-layout-list-table>
