<mc-collapsable-panel header="$I18N_MESSAGE_CENTER_MESSAGE_INFO_BOUNCE_PROPERTIES.TITLE" [isExpanded]="true">
  <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_BOUNCE_PROPERTIES.LABEL_BOUNCE_TYPE"
                  [value]="queueInfo.bounceType">
  </mc-value-field>
  <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_BOUNCE_PROPERTIES.LABEL_DESCRIPTION"
                  [value]="queueInfo.reason">
  </mc-value-field>
  <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_BOUNCE_PROPERTIES.LABEL_EVENT_TIME"
                  [value]="queueInfo.createTime | mcDatePlusWeekday">
  </mc-value-field>
  <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_BOUNCE_PROPERTIES.LABEL_FROM_ENVELOPE"
                  [value]="queueInfo.fromEnvelope">
  </mc-value-field>
  <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_BOUNCE_PROPERTIES.LABEL_FROM_HEADER"
                  [value]="queueInfo.fromHeader | mcNoDataAvailableDisplay">
  </mc-value-field>
  <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_BOUNCE_PROPERTIES.LABEL_TO"
                  [value]="queueInfo.to">
  </mc-value-field>
  <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_BOUNCE_PROPERTIES.LABEL_SUBJECT"
                  [value]="queueInfo.subject">
  </mc-value-field>
  <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_BOUNCE_PROPERTIES.LABEL_ACTUAL_SIZE"
                  [value]="queueInfo.size |  mcToBytes  ">
 </mc-value-field>
</mc-collapsable-panel>
