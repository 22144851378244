"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgEndpointsListSidebarEffects = void 0;
const effects_1 = require("@ngrx/effects");
const operators_1 = require("rxjs/operators");
const endpoints_list_sidebar_container_component_1 = require("../container/sidebar/endpoints-list-sidebar.container.component");
const sidebar_actions_1 = require("../actions/sidebar.actions");
class SwgEndpointsListSidebarEffects {
    constructor(actions$, asideService) {
        this.actions$ = actions$;
        this.asideService = asideService;
        this.openSidePanel$ = this.actions$.pipe(effects_1.ofType(sidebar_actions_1.SwgEndpointsListSidebarActionsEnum.SWG_ENDPOINTS_LIST_SIDEBAR_OPEN), operators_1.tap(() => {
            this.sidePanelInstance = this.asideService.open(endpoints_list_sidebar_container_component_1.SwgEndpointsListSidebarContainerComponent, {
                position: 'right',
                size: 'lg',
                hasBackdrop: true
            });
        }));
        this.closeSidePanel$ = this.actions$.pipe(effects_1.ofType(sidebar_actions_1.SwgEndpointsListSidebarActionsEnum.SWG_ENDPOINTS_LIST_SIDEBAR_CLOSE), operators_1.filter(() => !!this.sidePanelInstance), operators_1.tap(() => {
            this.sidePanelInstance.close();
            this.sidePanelInstance = undefined;
        }));
    }
}
__decorate([
    effects_1.Effect({ dispatch: false })
], SwgEndpointsListSidebarEffects.prototype, "openSidePanel$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], SwgEndpointsListSidebarEffects.prototype, "closeSidePanel$", void 0);
exports.SwgEndpointsListSidebarEffects = SwgEndpointsListSidebarEffects;
