"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.getSearchReason = exports.isErrorDataResponse = exports.isTableLoading = exports.getPreviousAndNextSelectedRow = exports.getSelectedRow = exports.getTrackingSearchPayload = exports.externalTableSearchCount = exports.getFiltersMetadata = exports.getSearchList = exports.reducers = void 0;
const store_1 = require("@ngrx/store");
const searchReducer = require("./search/search.reducer");
exports.reducers = {
    search: searchReducer.reducer
};
const getTrackingState = store_1.createFeatureSelector('tracking');
const ɵ0 = (state) => state.search;
exports.ɵ0 = ɵ0;
const getSearchState = store_1.createSelector(getTrackingState, ɵ0);
exports.getSearchList = store_1.createSelector(getSearchState, searchReducer.getSearchList);
exports.getFiltersMetadata = store_1.createSelector(getSearchState, searchReducer.getFiltersMetadata);
exports.externalTableSearchCount = store_1.createSelector(getSearchState, searchReducer.externalTableSearchCount);
exports.getTrackingSearchPayload = store_1.createSelector(getSearchState, searchReducer.getTrackingSearchPayload);
exports.getSelectedRow = store_1.createSelector(getSearchState, searchReducer.getSelectedRow);
exports.getPreviousAndNextSelectedRow = store_1.createSelector(getSearchState, searchReducer.getPreviousAndNextSelectedRow);
exports.isTableLoading = store_1.createSelector(getSearchState, searchReducer.isTableLoading);
exports.isErrorDataResponse = store_1.createSelector(getSearchState, searchReducer.isErrorDataResponse);
exports.getSearchReason = store_1.createSelector(getSearchState, searchReducer.getSearchReason);
