"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogDetailsContainerComponent = void 0;
const reducers = require("../../reducers");
const table_store_1 = require("@mimecast-ui/table-store");
const logDetailsActions = require("../../actions/log-details.actions");
const TABLE_ID = 'ttpImpersonationLogs';
class LogDetailsContainerComponent {
    constructor(store, overlayRef) {
        this.store = store;
        this.overlayRef = overlayRef;
    }
    ngOnInit() {
        this.logDetails$ = this.store.select(reducers.getLogDetails);
        this.logDetailsIsLoading$ = this.store.select(reducers.logDetailsIsLoading);
        this.previousNextRow$ = this.store.select(table_store_1.tableReducer.getPreviousAndNextSelectedRow(TABLE_ID));
        this.permitSenderIsProcessing$ = this.store.select(reducers.permitSenderIsProcessing);
        this.blockSenderIsProcessing$ = this.store.select(reducers.blockSenderIsProcessing);
        this.monitorDomainIsProcessing$ = this.store.select(reducers.monitorDomainIsProcessing);
        this.getSelectedRowSubscription = this.store
            .select(table_store_1.tableReducer.getSelectedRow(TABLE_ID))
            .subscribe(selectedRow => {
            if (selectedRow) {
                this.store.dispatch(new logDetailsActions.GetLogDetailsAction(selectedRow.id));
            }
        });
    }
    ngOnDestroy() {
        this.getSelectedRowSubscription.unsubscribe();
    }
    selectRow(row) {
        this.store.dispatch(new table_store_1.SetSelectedRowAction({ tableId: TABLE_ID, item: row }));
    }
    closeAside() {
        this.overlayRef.close();
    }
    permitSender(instance) {
        this.store.dispatch(new logDetailsActions.PermitSenderAction(instance));
    }
    blockSender(instance) {
        this.store.dispatch(new logDetailsActions.BlockSenderAction(instance));
    }
    monitorDomain(senderAddress) {
        this.store.dispatch(new logDetailsActions.MonitorDomainAction(senderAddress));
    }
}
exports.LogDetailsContainerComponent = LogDetailsContainerComponent;
