"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportsEffects = void 0;
const reports_actions_1 = require("../actions/reports.actions");
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const reports_service_1 = require("../services/reports.service");
const components_1 = require("@mimecast-ui/components");
const notification_actions_1 = require("app-new/components/notification/actions/notification.actions");
class ReportsEffects {
    constructor(actions$, service, fileDownloader, translateService, datePipeService) {
        this.actions$ = actions$;
        this.service = service;
        this.fileDownloader = fileDownloader;
        this.translateService = translateService;
        this.datePipeService = datePipeService;
        this.downloadReport$ = this.actions$.pipe(effects_1.ofType(reports_actions_1.ReportsActions.GET), operators_1.map((action) => action.report), operators_1.switchMap((report) => {
            const start = `${this.dateTransformer.transform(report.startDate)}+0000`;
            const end = `${this.dateTransformer.transform(report.endDate)}+0000`;
            return this.service.getReportContent(report.type, start, end).pipe(operators_1.map((response) => {
                if (response.hasErrors) {
                    return new reports_actions_1.GetFailureAction(response.fail[0]);
                }
                const filename = response.first.filename || report.type;
                this.fileDownloader.downLoadContentAsFile(filename, response.first.content);
                return new reports_actions_1.GetSuccessAction(filename, response.first.content.length);
            }), operators_1.catchError(() => {
                return rxjs_1.of(new reports_actions_1.GetFailureAction());
            }));
        }));
        this.downloadFailed$ = this.actions$.pipe(effects_1.ofType(reports_actions_1.ReportsActions.GET_FAILURE), operators_1.map((action) => action.failure), operators_1.map((failures) => (failures ? failures.errors || [] : [])), operators_1.switchMap((errors) => {
            if (errors.length === 0) {
                return [
                    new notification_actions_1.NotificationShowFailAction(reports_service_1.ReportsService.getApiErrorTranslateKey('UNKNOWN_ERROR'))
                ];
            }
            return errors.map(error => new notification_actions_1.NotificationShowFailAction(reports_service_1.ReportsService.getApiErrorTranslateKey(error.code)));
        }));
        this.downloadSucceeded$ = this.actions$.pipe(effects_1.ofType(reports_actions_1.ReportsActions.GET_SUCCESS), operators_1.map(() => new notification_actions_1.NotificationShowSuccessAction('$I18N_ARCHIVE_SUPERVISION_REPORT_TAB.NOTIFICATION.DOWNLOAD_COMPLETED')));
        this.dateTransformer = new components_1.DatePipe(this.translateService, this.datePipeService);
        this.dateTransformer.format = 'yyyy-MM-ddTHH:mm:ss';
    }
}
__decorate([
    effects_1.Effect()
], ReportsEffects.prototype, "downloadReport$", void 0);
__decorate([
    effects_1.Effect()
], ReportsEffects.prototype, "downloadFailed$", void 0);
__decorate([
    effects_1.Effect()
], ReportsEffects.prototype, "downloadSucceeded$", void 0);
exports.ReportsEffects = ReportsEffects;
