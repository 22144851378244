<mc-layout-list-extra-container>
  <mc-help-container>
    <mc-help-link url="https://community.mimecast.com/s/article/Targeted-Threat-Protection-Decoding-Checking-URLs-1221300005">
    </mc-help-link>
  </mc-help-container>
 
  <mc-body-container>
    <div class="row">
      <div class="col-xs-12 col-sm-6 ">
        <mc-check-urls data-test="cy-check-urls"></mc-check-urls>
      </div>
      <div class="col-xs-12 col-sm-6">
        <mc-decode-url-widget 
          (allowUrl)="allowUrl($event)"
          (blockUrl)="blockUrl($event)"
          (url)="decodeUrl($event)"
          [hasControls]="hasPermission$ | async"
          [urlStatus]="decodedUrlStatus$ | async"
          [urlDecoded]="decodedUrl$ | async">
        </mc-decode-url-widget>
      </div>
    </div>
  </mc-body-container>
</mc-layout-list-extra-container>