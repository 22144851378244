"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExcludedFilesStepComponent = void 0;
const step_base_component_1 = require("../../../step.base.component");
const backup_model_1 = require("../../backup.model");
class ExcludedFilesStepComponent extends step_base_component_1.StepBaseDirective {
    constructor(store, fb) {
        super(store, fb, 2, backup_model_1.wizardID);
        this.store = store;
        this.fb = fb;
        this.maxTags = -1;
        this.maxLength = 255;
        this.currentExtensions = [];
        this.formValid = true;
        this.formErrors = {};
        this.form = this.fb.group({
            fileExtensions: [[]]
        });
    }
    set excludedExtensions(excludedExtensions) {
        this._excludedExtensions = excludedExtensions.map(ext => `.${ext}`);
    }
    get excludedExtensions() {
        return this._excludedExtensions;
    }
    ngOnChanges(changes) {
        if (changes.excludedExtensions) {
            this.form.patchValue({
                fileExtensions: this.excludedExtensions
            });
        }
    }
    get fileExtensions() {
        return this.form.get('fileExtensions');
    }
    splitExtensions(extensions) {
        const splitExtensions = extensions.reduce((prev, curr) => [...prev, ...curr.split(' ')], []);
        return splitExtensions
            .filter(v => v !== '')
            .map((ext) => {
            if (ext.substr(0, 1) !== '.') {
                return `.${ext}`;
            }
            return ext;
        });
    }
    removeDuplicateExtensions(extensions) {
        return Array.from(new Set(extensions));
    }
    onTagsChange(extensions) {
        this.currentExtensions = this.splitExtensions(extensions);
        this.currentExtensions = this.removeDuplicateExtensions(this.currentExtensions);
        this.fileExtensions.setValue(this.currentExtensions);
    }
    onInputChange(input = '') {
        this.formValid = true;
        this.formErrors = {};
        const inputs = this.splitExtensions([input]);
        const validators = [
            this.minLengthValidator,
            this.maxLengthValidator,
            this.singlePeriod,
            this.validExtension
        ];
        let result;
        for (const validator of validators) {
            result = validator(inputs);
            if (result != null) {
                this.formValid = false;
                this.formErrors = result;
                this.dispatchUpdateStepAction();
                break;
            }
        }
        this.dispatchUpdateStepAction();
    }
    minLengthValidator(values) {
        const result = values.find(v => v.length < 3);
        return result ? { minLength: { input: result } } : null;
    }
    maxLengthValidator(values) {
        const result = values.find(v => v.length > 255);
        return result ? { maxLength: { input: result } } : null;
    }
    singlePeriod(values) {
        const result = values.find(v => v.substr(1).includes('.'));
        return result ? { multiplePeriods: { input: result } } : null;
    }
    validExtension(values) {
        const VALID_EXTENSION = /^\.{1}[a-zA-Z0-9]+$/;
        const result = values.find(v => !VALID_EXTENSION.test(v));
        return result ? { invalidExtension: { input: result } } : null;
    }
    getStepState() {
        return {
            valid: this.formValid,
            data: this.form.value
        };
    }
}
exports.ExcludedFilesStepComponent = ExcludedFilesStepComponent;
