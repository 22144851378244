"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiIntegrationOktaWizardModule = void 0;
const okta_wizard_component_1 = require("./okta-wizard.component");
const details_1 = require("./steps/details");
const settings_1 = require("./steps/settings");
const contacts_1 = require("./steps/contacts");
const summary_1 = require("./steps/summary");
const COMPONENTS = [
    okta_wizard_component_1.ApiIntegrationOktaWizardComponent,
    details_1.ApiIntegrationOktaDetailsStepComponent,
    settings_1.ApiIntegrationOktaSettingsStepComponent,
    contacts_1.ApiIntegrationOktaContactsStepComponent,
    summary_1.ApiIntegrationOktaSummaryStepComponent
];
class ApiIntegrationOktaWizardModule {
}
exports.ApiIntegrationOktaWizardModule = ApiIntegrationOktaWizardModule;
