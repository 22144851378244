"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ManagedUrlOverrideDialogCancel = exports.ManagedUrlDeleteCancelAction = exports.ManagedUrlDeleteFailAction = exports.ManagedUrlDeleteSucessAction = exports.ManagedUrlsDeleteAction = exports.ManagedUrlsDeleteAttemptAction = exports.ManagedUrlsFailAction = exports.ManagedUrlsSuccessAction = exports.ManagedUrlsRefreshAction = exports.ManagedUrlsPaginationAction = exports.ManagedUrlsRequestAction = exports.MANAGED_URLS_OVERRIDE_DIALOG_CANCEL = exports.MANAGED_URLS_FAIL = exports.MANAGED_URLS_SUCCESS = exports.MANAGED_URLS_REFRESH = exports.MANAGED_URLS_PAGINATION = exports.MANAGED_URL_DELETE_FAIL = exports.MANAGED_URL_DELETE_SUCCESS = exports.MANAGED_URL_DELETE = exports.MANAGED_URL_DELETE_CANCEL = exports.MANAGED_URL_DELETE_ATTEMPT = exports.MANAGED_URLS_REQUEST = void 0;
exports.MANAGED_URLS_REQUEST = '[Services / Url protection] Request Get Managed Urls';
exports.MANAGED_URL_DELETE_ATTEMPT = '[Services / Url protection] Delete managed url attempt';
exports.MANAGED_URL_DELETE_CANCEL = '[Services / Url protection] Delete canceled';
exports.MANAGED_URL_DELETE = '[Services / Url protection] Delete managed url';
exports.MANAGED_URL_DELETE_SUCCESS = '[Services / Url protection] Delete managed url success';
exports.MANAGED_URL_DELETE_FAIL = '[Services / Url protection] Delete managed url fail';
exports.MANAGED_URLS_PAGINATION = '[Services / Url protection] Pagination Managed Urls ';
exports.MANAGED_URLS_REFRESH = '[Services / Url protection] Refresh managed url list';
exports.MANAGED_URLS_SUCCESS = '[Services / Url protection] Get Managed Urls Success';
exports.MANAGED_URLS_FAIL = '[Services / Url protection] Get Managed Urls Fail';
exports.MANAGED_URLS_OVERRIDE_DIALOG_CANCEL = '[Services / Url protection] Override dialog canceled';
class ManagedUrlsRequestAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.MANAGED_URLS_REQUEST;
    }
}
exports.ManagedUrlsRequestAction = ManagedUrlsRequestAction;
class ManagedUrlsPaginationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.MANAGED_URLS_PAGINATION;
    }
}
exports.ManagedUrlsPaginationAction = ManagedUrlsPaginationAction;
class ManagedUrlsRefreshAction {
    constructor() {
        this.type = exports.MANAGED_URLS_REFRESH;
    }
}
exports.ManagedUrlsRefreshAction = ManagedUrlsRefreshAction;
class ManagedUrlsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.MANAGED_URLS_SUCCESS;
    }
}
exports.ManagedUrlsSuccessAction = ManagedUrlsSuccessAction;
class ManagedUrlsFailAction {
    constructor() {
        this.type = exports.MANAGED_URLS_FAIL;
    }
}
exports.ManagedUrlsFailAction = ManagedUrlsFailAction;
class ManagedUrlsDeleteAttemptAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.MANAGED_URL_DELETE_ATTEMPT;
    }
}
exports.ManagedUrlsDeleteAttemptAction = ManagedUrlsDeleteAttemptAction;
class ManagedUrlsDeleteAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.MANAGED_URL_DELETE;
    }
}
exports.ManagedUrlsDeleteAction = ManagedUrlsDeleteAction;
class ManagedUrlDeleteSucessAction {
    constructor() {
        this.type = exports.MANAGED_URL_DELETE_SUCCESS;
    }
}
exports.ManagedUrlDeleteSucessAction = ManagedUrlDeleteSucessAction;
class ManagedUrlDeleteFailAction {
    constructor() {
        this.type = exports.MANAGED_URL_DELETE_FAIL;
    }
}
exports.ManagedUrlDeleteFailAction = ManagedUrlDeleteFailAction;
class ManagedUrlDeleteCancelAction {
    constructor() {
        this.type = exports.MANAGED_URL_DELETE_CANCEL;
    }
}
exports.ManagedUrlDeleteCancelAction = ManagedUrlDeleteCancelAction;
class ManagedUrlOverrideDialogCancel {
    constructor() {
        this.type = exports.MANAGED_URLS_OVERRIDE_DIALOG_CANCEL;
    }
}
exports.ManagedUrlOverrideDialogCancel = ManagedUrlOverrideDialogCancel;
