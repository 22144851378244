"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DrivesListTableComponent = void 0;
const core_1 = require("@angular/core");
const components_1 = require("@mimecast-ui/components");
const status_factory_1 = require("../../../common/factories/status.factory");
const tableConfig = require("./drives-list-table-config");
class DrivesListTableComponent {
    constructor() {
        this.onPaginationChange = new core_1.EventEmitter();
        this.onFilterChange = new core_1.EventEmitter();
        this.onRowClick = new core_1.EventEmitter();
        this._metaData = {};
        this.sizePosition = components_1.TextAlign.LEFT;
        this.storageUsedUnit = components_1.UnitEnum.KB;
        this.columns = tableConfig.columnList;
        this.sections = tableConfig.sections;
    }
    set metaData(metaData) {
        if (metaData) {
            this._metaData = Object.assign(Object.assign({}, metaData), { pagination: Object.assign(Object.assign({}, metaData.pagination), { totalRows: metaData.pagination.totalCount }) });
        }
    }
    get metaData() {
        return this._metaData;
    }
    openItem(row) {
        this.onRowClick.emit(row);
    }
    getStatusByRow(row) {
        return status_factory_1.StatusFactory[row.lastStatus]();
    }
}
exports.DrivesListTableComponent = DrivesListTableComponent;
