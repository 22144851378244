"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchByContentTabWrapperComponent = void 0;
const components_1 = require("@mimecast-ui/components");
const forms_1 = require("@angular/forms");
const action = require("../../actions/search.action");
class SearchByContentTabWrapperComponent {
    constructor(_fb, store) {
        this._fb = _fb;
        this.store = store;
        this.dateRangePickerConfig = components_1.dateRangePickerDefaultConfig;
        this.options = [
            {
                label: 'optional',
                translate: '$I18N_ARCHIVE_VIEWER_SEARCH_TAB_CONTENT_FILES_OPTIONAL'
            },
            {
                label: 'any',
                translate: '$I18N_ARCHIVE_VIEWER_SEARCH_TAB_CONTENT_FILES_ANY_ATTACHMENT'
            },
            {
                label: 'none',
                translate: '$I18N_ARCHIVE_VIEWER_SEARCH_TAB_CONTENT_FILES_NONE'
            },
            {
                label: 'documents',
                translate: '$I18N_ARCHIVE_VIEWER_SEARCH_TAB_CONTENT_FILES_DOCUMENTS'
            },
            {
                label: 'spreadsheets',
                translate: '$I18N_ARCHIVE_VIEWER_SEARCH_TAB_CONTENT_FILES_SPREADSHEETS'
            },
            {
                label: 'presentations',
                translate: '$I18N_ARCHIVE_VIEWER_SEARCH_TAB_CONTENT_FILES_PRESENTATIONS'
            },
            {
                label: 'text',
                translate: '$I18N_ARCHIVE_VIEWER_SEARCH_TAB_CONTENT_FILES_TEXT_FILES'
            },
            {
                label: 'images',
                translate: '$I18N_ARCHIVE_VIEWER_SEARCH_TAB_CONTENT_FILES_IMAGES_PHOTOS'
            },
            {
                label: 'media',
                translate: '$I18N_ARCHIVE_VIEWER_SEARCH_TAB_CONTENT_FILES_SOUNDS_PHOTOS'
            },
            {
                label: 'zips',
                translate: '$I18N_ARCHIVE_VIEWER_SEARCH_TAB_CONTENT_FILES_COMPRESSED_FILES'
            }
        ];
    }
    formContentSearch() {
        this.searchValues = this.formContent.value;
        if (this.searchValues.periodDateRanges && this.searchValues.periodDateRanges.range) {
            this.searchValues.periodDateRanges = Object.assign({}, this.searchValues.periodDateRanges);
            this.searchValues.periodDateRanges.range = Object.assign({}, this.searchValues.periodDateRanges.range);
        }
        if (this.searchValues) {
            this.searchValues = Object.assign({}, this.searchValues);
        }
        this.store.dispatch(new action.ContentSearchAction(Object.assign(Object.assign({}, this.searchValues), this.formReasonValueChanges)));
    }
    inputDropdownUpdate(e) {
        this.formContent.value.docs = e;
    }
    ngOnInit() {
        this.formContent = this._fb.group({
            keywords: ['', [forms_1.Validators.minLength(1), forms_1.Validators.maxLength(150)]],
            from: ['', [forms_1.Validators.minLength(5), forms_1.Validators.maxLength(150)]],
            to: ['', [forms_1.Validators.minLength(5), forms_1.Validators.maxLength(150)]],
            route: ['any'],
            expiredMessages: [false],
            dateRange: [this.dateRangePickerConfig.dateRanges[1], []]
        });
    }
}
exports.SearchByContentTabWrapperComponent = SearchByContentTabWrapperComponent;
