"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDownloading = exports.reducer = void 0;
const reports_actions_1 = require("../actions/reports.actions");
const initialState = { downloading: false };
function reducer(state = initialState, action) {
    switch (action.type) {
        case reports_actions_1.ReportsActions.GET:
            return Object.assign(Object.assign({}, state), { downloading: true });
        default:
            return Object.assign(Object.assign({}, state), { downloading: false });
    }
}
exports.reducer = reducer;
const getDownloading = (state) => state && state.downloading;
exports.getDownloading = getDownloading;
