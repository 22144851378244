<mc-layout-aside-extra-container
  keyTitle="{{getTitle()}}"
  showClose="true"
  [isLoading]="false"
  (closeAside)="close()"
>
  <mc-body-container>
    <div *ngIf="showDeviceList">
      <mc-swg-devices-list
        [displayType]="deviceListDisplayType.TARGET"
      ></mc-swg-devices-list>
    </div>
    <div *ngIf="!showDeviceList">
      <mc-swg-device-groups-list
        [displayType]="deviceListDisplayType.TARGET"
        [hasEditPermission]="false"
        (addDeviceGroupsToTargets)="addDeviceGroupsToTargets($event)"
      >

      </mc-swg-device-groups-list>
    </div>
  </mc-body-container>
</mc-layout-aside-extra-container>
