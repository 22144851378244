<div class="mc-bi-policy-details-block">
  <mc-wizard-step-header
    [label]="getPageTitle()"
  ></mc-wizard-step-header>

  <div class="mc-detailed-list mc-swg-policy-summary-block">
    <div class="row mc-detailed-list-row no-padding-top">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_SWG_CREATE_POLICY_POLICY_SUMMARY_NAME' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ swgPolicy.policyDesc.description }}</div>
    </div>

    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_BI_POLICY_DETAILS_STEP_DESCRIPTION' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{  swgPolicy.biSettings?.defaultPolicy ? ('$I18N_BI_DEFAULT_POLICY_DESCRIPTION' | translate) : swgPolicy?.narrative }}
      </div>
    </div>



    <ng-container>
      <div class="row mc-detailed-list-row">
        <div class="col-xs-3 mc-detailed-list-label">
          {{ '$I18N_BI_POLICY_SETTINGS_STEP_ENTER_TEXT' | translate }}
        </div>
        <div class="mc-detailed-list-column">
          {{
          (swgPolicy.biSettings.enterText
            ? '$I18N_SWG_CREATE_POLICY_SAFE_SEARCH_ENABLED'
            : '$I18N_SWG_CREATE_POLICY_SAFE_SEARCH_DISABLED') | translate
          }}
        </div>
      </div>
      <div class="row mc-detailed-list-row">
        <div class="col-xs-3 mc-detailed-list-label">
          {{ '$I18N_BI_POLiCY_SETTINGS_STEP_TYPE_FROM_DEVICE_CLIPBOARD' | translate }}
        </div>
        <div class="mc-detailed-list-column">
          {{
          (swgPolicy.biSettings.clipboardTransferIn
            ? '$I18N_SWG_CREATE_POLICY_SAFE_SEARCH_ENABLED'
            : '$I18N_SWG_CREATE_POLICY_SAFE_SEARCH_DISABLED') | translate
          }}
        </div>
      </div>
      <div class="row mc-detailed-list-row">
        <div class="col-xs-3 mc-detailed-list-label">
          {{ '$I18N_BI_POLiCY_SETTINGS_STEP_TYPE_TO_DEVICE_CLIPBOARD' | translate }}
        </div>
        <div class="mc-detailed-list-column">
          {{
          (swgPolicy.biSettings.clipboardTransferOut
            ? '$I18N_SWG_CREATE_POLICY_SAFE_SEARCH_ENABLED'
            : '$I18N_SWG_CREATE_POLICY_SAFE_SEARCH_DISABLED') | translate
          }}
        </div>
      </div>

    </ng-container>



    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_SWG_CREATE_POLICY_POLICY_SUMMARY_AFFECTED_USERS' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        <div *ngIf="swgPolicy.biSettings.defaultPolicy">
          <div>
            {{ '$I18N_SWG_CREATE_POLICY_POLICY_EVERYONE' | translate }}
          </div>
        </div>

        <div *ngIf="!swgPolicy.biSettings.defaultPolicy">

          <div *ngIf="_locations.length > 0" class="panel-padding-bottom">
            <div class="mc-affected-targets-title">
              {{ '$I18N_SWG_CREATE_POLICY_POLICY_LOCATIONS' | translate }}
            </div>
            <div *ngFor="let target of _locations">
              <div data-test="applies_to_target">
                {{ target.description }}&nbsp;({{ target.ipRange }})
              </div>
            </div>
          </div>
          <div *ngIf="_groups.length > 0" class="panel-padding-bottom">
            <div class="mc-affected-targets-title">
              {{ '$I18N_SWG_CREATE_POLICY_POLICY_GROUPS' | translate }}
            </div>
            <div *ngFor="let target of _groups">
              <div data-test="applies_to_target">
                {{ target.description }}
              </div>
            </div>
          </div>
          <div *ngIf="_users.length > 0">
            <div class="mc-affected-targets-title">
              {{ '$I18N_SWG_CREATE_POLICY_POLICY_USERS' | translate }}
            </div>
            <div *ngFor="let target of _users">
              <div data-test="applies_to_target">
                {{ target.emailAddress }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
