"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CrowdstrikeBooleanPipe = void 0;
class CrowdstrikeBooleanPipe {
    constructor(translateService) {
        this.translateService = translateService;
    }
    transform(input) {
        if (input) {
            return this.translateService.instant('$I18N_API_INTEGRATION_CROWDSTRIKE_BOOLEAN.ENABLED');
        }
        return this.translateService.instant('$I18N_API_INTEGRATION_CROWDSTRIKE_BOOLEAN.DISABLED');
    }
}
exports.CrowdstrikeBooleanPipe = CrowdstrikeBooleanPipe;
