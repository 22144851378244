<mc-layout-list-extra-container>
  <mc-extra-container>
    <mc-remediation-search-messages-widget [hasSearchWithoutHashPermission]="hasSearchEnabled"
                                           [isIdSearchDateRangeEnabled]="isIdSearchDateRangeEnabled"
                                           [inlineForm]="true">
    </mc-remediation-search-messages-widget>
  </mc-extra-container>
  <mc-body-container>
    <mc-table
      [isLoading]="isSearchTableLoading$ | async"
      [columns]="getColumns()"
      [data]="messages$ | async"
      translatePrefix="$I18N_REMEDIATION_SEARCH_LIST"
      [highlightedRow]="selectedRow$ | async"
      (rowClick)="onRowClick($event)"
      [preserveSelections]="true">

      <button
        class="mc-table-actions btn btn-primary"
        [disabled]="!tableSelectedRows.length"
        (click)="openRemediateAllModal()"
        *mcCapabilities="'Permission.THREAT_REMEDIATION_EDIT'">
        {{ '$I18N_REMEDIATION_SEARCH_LIST.ACTIONS.REMEDIATE_BTN' | translate }}
      </button>

      <button
        class="mc-table-actions btn btn-secondary"
        [disabled]="!(messages$ | async)?.length"
        (click)="onExportClicked()">
        {{ '$I18N_REMEDIATION_SEARCH_LIST.ACTIONS.EXPORT_BTN' | translate }}
      </button>

      <mc-filters
        [metadata]="filtersMetadata$ | async"
        (paginatorChange)="onPaginatorChange($event)"
        [pageSizeOptions]="[50,100,300]"
        [defaultPageSize]="50"
        [externalTableSearchCount]="externalTableSearchCount$ | async">
      </mc-filters>
      <mc-column-select #rowSelectElement
                        key="select"
                        (selectChange)="selectRows()">
      </mc-column-select>
      <mc-column key="fromHdr">
        <mc-body-cell *mcBodyCellDef="let row">{{ row.fromHdr?.emailAddress }}</mc-body-cell>
      </mc-column>

      <mc-column key="oneToAddress">
        <mc-body-cell *mcBodyCellDef="let row">
          {{ row.oneToAddress?.emailAddress }}{{ row.countToAddresses > 1 ? ', +'+ (row.countToAddresses - 1 | number) : '' }}
        </mc-body-cell>
      </mc-column>

      <mc-column key="subject">
        <mc-body-cell *mcBodyCellDef="let row">{{ row.subject ? row.subject : emptyValueDecorator }}</mc-body-cell>
      </mc-column>

      <mc-column-date key="received"></mc-column-date>

      <mc-column-actions key="action" mcShowColumnConfig [columnsToIgnore]="columnsToIgnore">
        <mc-row-actions *mcRowActions="let row">
          <li mcRowAction="{{option.label}}" (click)="option.action(row)" *ngFor="let option of dropdownOptions"></li>
        </mc-row-actions>
      </mc-column-actions>

      <mc-empty-results keyTitle="$I18N_REMEDIATION_SEARCH_LIST.NO_ENTRIES"></mc-empty-results>

    </mc-table>

  </mc-body-container>
</mc-layout-list-extra-container>
