<mc-table
  tableId="message-center/hold-queue-messages/main-table"
  [isLoading]="isLoading"
  [highlightedRow]="selectedRow"
  [columns]="columns"
  [data]="rows"
  (rowClick)="clickRow($event)"
  translatePrefix="$I18N_MESSAGE_CENTER_HOLD_QUEUE_TABLE.TABLE"
>
  <mc-action-list-dropdown-button
    [disabled]="!isSelectedRow()"
    class="mc-table-actions mc-button-margin-right"
    buttonClass="btn-primary"
    label="$I18N_MESSAGE_CENTER_HOLD_QUEUE_TABLE.ACTIONS.RELEASE"
    (defaultAction)="releaseMessages()"
  >
    <mc-dropdown-item
      label="$I18N_MESSAGE_CENTER_HOLD_QUEUE_TABLE.ACTIONS.RELEASE"
      (click)="releaseMessages()"
    >
    </mc-dropdown-item>
    <mc-dropdown-item
      label="$I18N_MESSAGE_CENTER_HOLD_QUEUE_TABLE.ACTIONS.RELEASE_TO_SANDBOX"
      (click)="releaseMessagesToSandbox()"
    >
    </mc-dropdown-item>
    <li role="separator" class="divider"></li>
    <mc-dropdown-item
      label="$I18N_MESSAGE_CENTER_HOLD_QUEUE_TABLE.ACTIONS.PERMIT_FOR_RECIPIENT"
      (click)="permitForRecipient()"
    >
    </mc-dropdown-item>
  </mc-action-list-dropdown-button>

  <mc-action-list-dropdown-button
    [disabled]="!isSelectedRow()"
    class="mc-table-actions mc-button-margin-right"
    buttonClass="btn-primary"
    label="$I18N_MESSAGE_CENTER_HOLD_QUEUE_TABLE.ACTIONS.REJECT"
    (defaultAction)="rejectMessages()"
  >
    <mc-dropdown-item
      label="$I18N_MESSAGE_CENTER_HOLD_QUEUE_TABLE.ACTIONS.REJECT"
      (click)="rejectMessages()"
    >
    </mc-dropdown-item>
    <mc-dropdown-item
      label="$I18N_MESSAGE_CENTER_HOLD_QUEUE_TABLE.ACTIONS.REJECT_WITH_NOTIFY"
      (click)="RejectMessagesWithNotification()"
    >
    </mc-dropdown-item>
    <mc-dropdown-item
      label="$I18N_MESSAGE_CENTER_HOLD_QUEUE_TABLE.ACTIONS.REJECT_WITH_COMMENTS"
      (click)="RejectMessagesWithNarrative()"
    >
    </mc-dropdown-item>
    <li role="separator" class="divider"></li>
    <mc-dropdown-item
      label="$I18N_MESSAGE_CENTER_HOLD_QUEUE_TABLE.ACTIONS.BLOCK_FOR_RECIPIENT"
      (click)="blockForRecipient()"
    >
    </mc-dropdown-item>
  </mc-action-list-dropdown-button>

  <mc-list-dropdown-button
    [disabled]="!isSelectedRow(false)"
    class="mc-table-actions mc-button-margin-right"
    label="$I18N_MESSAGE_CENTER_HOLD_QUEUE_TABLE.ACTIONS.REPORT"
  >
    <mc-dropdown-item label="$I18N_MESSAGE_CENTER_HOLD_QUEUE_TABLE.ACTIONS.REPORT_AS" header>
    </mc-dropdown-item>
    <mc-dropdown-item
      label="$I18N_MESSAGE_CENTER_HOLD_QUEUE_TABLE.ACTIONS.REPORT_AS_SPAM"
      (click)="reportMessageAsSpam()"
    >
    </mc-dropdown-item>
    <mc-dropdown-item
      label="$I18N_MESSAGE_CENTER_HOLD_QUEUE_TABLE.ACTIONS.REPORT_AS_MALWARE"
      (click)="reportMessageAsMalware()"
    >
    </mc-dropdown-item>
    <mc-dropdown-item
      label="$I18N_MESSAGE_CENTER_HOLD_QUEUE_TABLE.ACTIONS.REPORT_AS_PHISHING"
      (click)="reportMessageAsPhishing()"
    >
    </mc-dropdown-item>
  </mc-list-dropdown-button>

  <button
    class="mc-table-actions btn btn-secondary mc-button-margin-right"
    (click)="exportResults()"
  >
    {{ '$I18N_MESSAGE_CENTER_HOLD_QUEUE_TABLE.ACTIONS.EXPORT' | translate }}
  </button>

  <mc-filters
    [externalTableSearchCount]="externalTableSearchCount"
    [metadata]="metadata"
    [showPagination]="true"
    (paginatorChange)="changePaginator($event)"
  >
    <mc-filters-bundle-column-values
      #tableFilter
      [externalTableSearchCount]="externalTableSearchCountFilters"
      [sections]="filterSections"
      (filtersChange)="changeFilter($event)"
    >
    </mc-filters-bundle-column-values>
  </mc-filters>

  <mc-empty-results
    keyTitle="$I18N_MESSAGE_CENTER_HOLD_QUEUE_TABLE.TABLE.EMPTY_RESULTS"
    iconClass="mc-icon-adcon-icon-stable-no-results-2"
  >
  </mc-empty-results>

  <mc-column-select
    key="select"
    (selectChange)="selectRows($event)"
    [isRowEnabled]="isRowEnabled"
  ></mc-column-select>

  <mc-column-email key="fromHdr" emailKey="emailAddress"></mc-column-email>
  <mc-column-email key="fromEnv" emailKey="emailAddress"></mc-column-email>
  <mc-column-email key="to" emailKey="emailAddress"></mc-column-email>

  <mc-column key="attachments">
    <mc-header-cell *mcHeaderCellDef>
      <i class="far fa-paperclip" aria-hidden="true"></i>
    </mc-header-cell>
    <mc-body-cell *mcBodyCellDef="let row">
      <i *ngIf="row.attachments" class="far fa-paperclip" aria-hidden="true"></i>
    </mc-body-cell>
  </mc-column>

  <mc-column-size key="size"></mc-column-size>
  <mc-column-date key="heldSince"></mc-column-date>

  <mc-column key="spamScore">
    <mc-body-cell *mcBodyCellDef="let row">{{row.spamScore >=0 ?  row.spamScore : '-' }}</mc-body-cell>
  </mc-column>

  <mc-column key="detectionLevel">
    <mc-body-cell *mcBodyCellDef="let row">{{row.detectionLevel  || '-' }}</mc-body-cell>
  </mc-column>

  <mc-column-actions
    key="right-column"
    mcShowColumnConfig
    [columnsAlwaysVisible]="columnsAlwaysVisible"
    [columnsToStartHidden]="columnsToStartHidden"
    [columnsToIgnore]="columnsToIgnore"
  >
    <mc-row-actions *mcRowActions="let row">
      <li
        mcRowAction="$I18N_MESSAGE_CENTER_HOLD_QUEUE_TABLE.ACTIONS.VIEW_DETAILS"
        (click)="clickRow(row)"
      ></li>
      <li mcRowActionSeparator></li>
      <li
        mcRowAction="$I18N_MESSAGE_CENTER_HOLD_QUEUE_TABLE.ACTIONS.RELEASE"
        (click)="releaseMessages([row])"
      ></li>
      <li
        mcRowAction="$I18N_MESSAGE_CENTER_HOLD_QUEUE_TABLE.ACTIONS.RELEASE_TO_SANDBOX"
        (click)="releaseMessagesToSandbox([row])"
      ></li>
      <li
        mcRowAction="$I18N_MESSAGE_CENTER_HOLD_QUEUE_TABLE.ACTIONS.PERMIT_FOR_RECIPIENT"
        (click)="permitForRecipient([row])"
      ></li>
      <li mcRowActionSeparator></li>
      <li
        mcRowAction="$I18N_MESSAGE_CENTER_HOLD_QUEUE_TABLE.ACTIONS.REJECT"
        (click)="rejectMessages([row])"
      ></li>
      <li
        mcRowAction="$I18N_MESSAGE_CENTER_HOLD_QUEUE_TABLE.ACTIONS.REJECT_WITH_NOTIFY"
        (click)="RejectMessagesWithNotification([row])"
      ></li>
      <li
        mcRowAction="$I18N_MESSAGE_CENTER_HOLD_QUEUE_TABLE.ACTIONS.BLOCK_FOR_RECIPIENT"
        (click)="blockForRecipient([row])"
      ></li>
      <li
        mcRowAction="$I18N_MESSAGE_CENTER_HOLD_QUEUE_TABLE.ACTIONS.REJECT_WITH_COMMENTS"
        (click)="RejectMessagesWithNarrative([row])"
      ></li>
      <li mcRowActionSeparator></li>
      <li mcRowActionHeader="$I18N_MESSAGE_CENTER_HOLD_QUEUE_TABLE.ACTIONS.REPORT_AS"></li>
      <li
        mcRowAction="$I18N_MESSAGE_CENTER_HOLD_QUEUE_TABLE.ACTIONS.REPORT_AS_SPAM"
        (click)="reportEmail({ reportType: 'spam', id: row.id })"
      ></li>
      <li
        mcRowAction="$I18N_MESSAGE_CENTER_HOLD_QUEUE_TABLE.ACTIONS.REPORT_AS_MALWARE"
        (click)="reportEmail({ reportType: 'malware', id: row.id })"
      ></li>
      <li
        mcRowAction="$I18N_MESSAGE_CENTER_HOLD_QUEUE_TABLE.ACTIONS.REPORT_AS_PHISHING"
        (click)="reportEmail({ reportType: 'phishing', id: row.id })"
      ></li>
    </mc-row-actions>
  </mc-column-actions>
</mc-table>
