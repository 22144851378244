"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateConnectorWizardComponent = void 0;
const store_1 = require("@ngrx/store");
const wizard_base_component_1 = require("../wizard-base.component");
const connector_wizard_service_1 = require("../../../services/connector-wizard.service");
const connector_actions_1 = require("../../../actions/connector.actions");
const model_1 = require("app-new/services/connectors/model");
const wizardActions = require("../../../actions/wizard.actions");
const reducers_1 = require("../../../reducers");
const operators_1 = require("rxjs/operators");
class CreateConnectorWizardComponent extends wizard_base_component_1.WizardBaseDirective {
    constructor(capabilitiesService, store, translateService, stateService) {
        super(capabilitiesService, connector_wizard_service_1.ConnectorWizardService.WizardId, store, translateService);
        this.capabilitiesService = capabilitiesService;
        this.store = store;
        this.translateService = translateService;
        this.stateService = stateService;
        this.disableCreateButton = false;
        if (stateService.$state && stateService.$state.params.id === null) {
            stateService.$state.go('services-connectors-page', { tab: 'cloud' });
        }
    }
    onSave() {
        this.disableCreateButton = true;
        if (this.editFlow !== true) {
            this.store.dispatch(new connector_actions_1.CreateConnectorAction(this.getCreateConnectorRequest()));
        }
        else {
            this.store.dispatch(new connector_actions_1.UpdateConnectorAction(this.getUpdateConnectorRequest()));
        }
    }
    ngOnInit() {
        super.ngOnInit();
        if (this.editFlow) {
            this.wizardTitle = this.translateService.instant('$I18N_CREATE_CONNECTOR_WIZARD.EDIT_TITLE');
        }
        else {
            this.wizardTitle = this.translateService.instant('$I18N_CREATE_CONNECTOR_WIZARD.CREATE_TITLE');
        }
        this.isGwsProviderEnabled$ = this.capabilitiesService.hasCapability('Temporary.GoogleWorkspace.Connector.Provider');
    }
    ngAfterViewInit() {
        if (this.editFlow) {
            this.store.dispatch(new wizardActions.UpdateStepAction({
                wizardId: connector_wizard_service_1.ConnectorWizardService.WizardId,
                stepId: model_1.ConnectorStep.PRODUCT,
                stepState: {
                    valid: true,
                    data: {
                        product: Object.assign({}, this.stateService.$state.params.product)
                    }
                }
            }));
            this.store.dispatch(new wizardActions.UpdateStepAction({
                wizardId: connector_wizard_service_1.ConnectorWizardService.WizardId,
                stepId: model_1.ConnectorStep.PROVIDER,
                stepState: {
                    valid: true,
                    data: {
                        provider: {
                            type: this.stateService.$state.params.provider
                        }
                    }
                }
            }));
            this.onStepChange(model_1.ConnectorStep.DETAILS);
            this.wizard.showStep(3);
        }
    }
    getProvider() {
        return (this.wizardData &&
            this.wizardData.steps &&
            this.wizardData.steps[2] &&
            this.wizardData.steps[2].data &&
            this.wizardData.steps[2].data['provider']);
    }
    getSelectedProduct() {
        return (this.wizardData &&
            this.wizardData.steps &&
            this.wizardData.steps[1] &&
            this.wizardData.steps[1].data &&
            this.wizardData.steps[1].data['product']);
    }
    getCancelConfirmationText() {
        if (this.editFlow !== true) {
            return this.translateService.instant('$I18N_CREATE_CONNECTOR_WIZARD.CANCEL_CONFIRMATION');
        }
        else {
            return this.translateService.instant('$I18N_CREATE_CONNECTOR_WIZARD.CANCEL_EDIT');
        }
    }
    isGwsProviderSelected() {
        return this.getSelectedProviderType().pipe(operators_1.map(accessProviderType => model_1.ProviderType.GOOGLE_WORKSPACE === accessProviderType));
    }
    isMsProviderSelected() {
        return this.getSelectedProviderType().pipe(operators_1.map(accessProviderType => model_1.ProviderType.ONE_DRIVE === accessProviderType ||
            model_1.ProviderType.MS_TEAMS === accessProviderType ||
            model_1.ProviderType.MS_TEAMS_GCC === accessProviderType ||
            model_1.ProviderType.AZURE_STANDARD === accessProviderType ||
            model_1.ProviderType.AZURE_GCC === accessProviderType ||
            model_1.ProviderType.OFFICE_365 === accessProviderType ||
            model_1.ProviderType.OFFICE_365_GCC === accessProviderType));
    }
    getCreateConnectorRequest() {
        const description = this.wizardData.steps[model_1.ConnectorStep.DETAILS].data['description'].trim();
        return Object.assign({ product: this.wizardData.steps[model_1.ConnectorStep.PRODUCT].data['product'].id, 
            // TODO: API wlll be modifying to return provider object
            // provider: this.wizardData.steps[2].data['provider'].id,
            provider: this.wizardData.steps[model_1.ConnectorStep.PROVIDER].data['provider'].type, authToken: this.wizardData.steps[model_1.ConnectorStep.CONSENT].data['authToken'], name: this.wizardData.steps[model_1.ConnectorStep.DETAILS].data['name'].trim() }, (description && { description }));
    }
    getUpdateConnectorRequest() {
        return {
            connectorId: this.stateService.$state.params.id,
            name: this.wizardData.steps[model_1.ConnectorStep.DETAILS].data['name'].trim(),
            description: this.wizardData.steps[model_1.ConnectorStep.DETAILS].data['description']
                ? this.wizardData.steps[model_1.ConnectorStep.DETAILS].data['description'].trim()
                : null,
            provider: this.wizardData.steps[model_1.ConnectorStep.PROVIDER].data['provider'].type
        };
    }
    getSelectedProviderType() {
        return this.store.pipe(operators_1.filter(() => !this.editFlow), store_1.select(reducers_1.getWizardById(connector_wizard_service_1.ConnectorWizardService.WizardId)), operators_1.filter(wizardData => wizardData && wizardData.currentStep && wizardData.currentStep > model_1.ConnectorStep.PRODUCT), operators_1.map(wizardData => wizardData.steps[model_1.ConnectorStep.PROVIDER].data), operators_1.filter((data) => data && data.provider && data.provider.type), operators_1.map(data => data.provider.type));
    }
}
exports.CreateConnectorWizardComponent = CreateConnectorWizardComponent;
