"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DashboardComponent = void 0;
const dashboard_mocks_1 = require("../../features/dashboard/mocks/dashboard.mocks");
var DashboardTabs;
(function (DashboardTabs) {
    DashboardTabs[DashboardTabs["OVERVIEW"] = 0] = "OVERVIEW";
    DashboardTabs[DashboardTabs["THREAT_EVENTS"] = 1] = "THREAT_EVENTS";
})(DashboardTabs || (DashboardTabs = {}));
class DashboardComponent {
    constructor(stateService) {
        this.stateService = stateService;
        this.tab = 'OVERVIEW';
        this.verdictsByApp = dashboard_mocks_1.mockVerdictsByApp;
        this.timeline = dashboard_mocks_1.mockVerdictsByDateNormalized;
        this.filetype = dashboard_mocks_1.mockScanCountByFileType;
    }
    ngAfterViewInit() {
        this.tabs.tabClicked(DashboardTabs[this.tab]);
    }
    changeTab(tab) {
        if (tab === 0) {
            this.stateService.$state.go('safe-cloud-dashboard-overview');
        }
        else if (tab === 1) {
            this.stateService.$state.go('safe-cloud-dashboard-threat-events');
        }
    }
}
exports.DashboardComponent = DashboardComponent;
