"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.baseUrl = exports.Rule = void 0;
class Rule {
    constructor(id, name, createdBy, updatedBy, created, updated, lastExecuted, description, samplingPercentage, enabled, reviewQueue, scheduleType, messageRoute, lexicon, searchGroup, status, state) {
        this.id = id;
        this.name = name;
        this.createdBy = createdBy;
        this.updatedBy = updatedBy;
        this.created = created;
        this.updated = updated;
        this.lastExecuted = lastExecuted;
        this.description = description;
        this.samplingPercentage = samplingPercentage;
        this.enabled = enabled;
        this.reviewQueue = reviewQueue;
        this.scheduleType = scheduleType;
        this.messageRoute = messageRoute;
        this.lexicon = lexicon;
        this.searchGroup = searchGroup;
        this.status = status;
        this.state = state;
    }
}
exports.Rule = Rule;
exports.baseUrl = '/api/supervision/config/';
