angular.module('services.services.backup.mailboxes.guard', [])
    .factory('mailboxesGuardService', [
    '$q',
    'backupService',
    'ngProgressFactory',
    function ($q, backupService, ngProgressFactory) {
        const self = this;
        self.backupTask = null;
        self.restoreAsideOptions = null;
        self.getBackupTask = (taskId) => {
            self.backupTask = null;
            const ngProgress = ngProgressFactory.createInstance();
            if (!taskId) {
                return null;
            }
            ngProgress.start();
            return backupService.getBackupTask(taskId).then(function (response) {
                ngProgress.complete();
                self.backupTask = response.first;
                return response.first;
            }, function (error) {
                ngProgress.complete();
                backupService.getError().showErrorNotification();
                return $q.reject(error);
            });
        };
        return self;
    }
]);
