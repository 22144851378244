"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const _ = require("lodash");
(function () {
    'use strict';
    /* ======================================================== *\

   = Archive task edit - controller

   \* ======================================================== */
    angular.module('services.services.backup.archive-task-edit.controller', [])
        .controller('ArchiveTaskEditController', ['backupService', '$stateParams', '$aside', 'directoryService', '$state', 'serverConnectionService', 'groupSelectorService', 'ngProgressFactory', 'archiveTaskEditService', '$translate', 'archiveTaskEditGuard', 'helperValidationService', 'capabilitiesService', function (backupService, $stateParams, $aside, directoryService, $state, serverConnectionService, groupSelectorService, ngProgressFactory, archiveTaskEditService, $translate, archiveTaskEditGuard, helperValidationService, capabilitiesService) {
            const self = this;
            const archiveTaskByID = archiveTaskEditGuard.backupTask;
            /* -------------------------------------------------------- *\
     - interface
     \* -------------------------------------------------------- */
            const ngProgress = ngProgressFactory.createInstance();
            self.duration = {};
            self.archiveTask = {};
            self.wizard = {};
            self.connectorsList = [];
            self.serverConnectionSelectedID = null;
            self.formCtrl = null;
            self.inlineNotificationOptions = null;
            self.serverConnectionID = null;
            self.mailboxGroup = {};
            self.createdArchiveTask = {};
            self.notificationEmailsError = false;
            self.archiveTask.includeClasses = [];
            self.notificationEmails = '';
            self.maxLengthArchiveTaskName = 60;
            self.maxLengthValidationErrorMessage = $translate.instant('$I18N_BACKUP_RESTORE_ARCHIVE_TASK_TASK_NAME_LABEL_VALIDATOR', { maxLength: self.maxLengthArchiveTaskName });
            self.archiveTaskDetailsHeaderDescription = '$I18N_BACKUP_RESTORE_ARCHIVE_TASK_EDIT_ARCHIVE_TASK_DETAILS';
            self.hasNotificationEmailsAccess = capabilitiesService.hasCapability('Temporary.Services.ArchiveTask.NotificationEmails');
            /* -------------------------------------------------------- *\
             - initialisation
             \* -------------------------------------------------------- */
            self.showTaskCreationWizard = true;
            self.selectMailboxGroup = selectMailboxGroup;
            self.wizardStepOneNext = wizardStepOneNext;
            self.wizardStepTwoBack = wizardStepTwoBack;
            self.wizardStepTwoNext = wizardStepTwoNext;
            self.wizardStepThreeBack = wizardStepThreeBack;
            self.wizardStepThreeFinish = wizardStepThreeFinish;
            self.validateIPMClass = validateIPMClass;
            self.addDuration = addDuration;
            self.removeDuration = removeDuration;
            self.isFormValid = isFormValid;
            self.isArchiveTaskOptionFormValid = isArchiveTaskOptionFormValid;
            self.returnToList = returnToList;
            self.updateArchiveTask = updateArchiveTask;
            self.includeSensitiveItems = includeSensitiveItems;
            self.exclusionsValid = exclusionsValid;
            self.validateNotificationEmails = validateNotificationEmails;
            activate();
            loadConnectorsList();
            /* -------------------------------------------------------- *\
             - implementation
             \* -------------------------------------------------------- */
            // == validateIPMClass ========
            function validateIPMClass(className) {
                if (!_.isEmpty(className)) {
                    return { response: true };
                }
                else {
                    return {
                        response: false,
                        error: $translate.instant('$I18N_BACKUP_RESTORE_ARCHIVE_TASK_EDIT_MESSAGE_CLASSES_ERROR_LABEL')
                    };
                }
            }
            //=========== get all notification emails =========//
            function getAllNotificationEmails() {
                return !_.isEmpty(self.notificationEmails) ? self.notificationEmails.split(/(?:,| )+/g).map((t) => t.trim()) : [];
            }
            // == addDuration ========
            function addDuration() {
                self.archiveReplicationExclusions.push(self.duration);
                self.duration = {};
            }
            // == removeDuration ========
            function removeDuration(duration) {
                self.archiveReplicationExclusions = _.without(self.archiveReplicationExclusions, duration);
            }
            // == selectMailboxGroup ========
            function selectMailboxGroup() {
                ngProgress.start();
                const groupSelectorPromise = groupSelectorService.openGroupSelector();
                groupSelectorPromise.then(function (selectedGroup) {
                    if (angular.isDefined(selectedGroup)) {
                        self.mailboxGroup = selectedGroup;
                    }
                    ngProgress.complete();
                });
                ngProgress.complete();
            }
            // == wizardStepOneNext ========
            function wizardStepOneNext() {
                this.wizard.ctrl.nextStep();
            }
            // == wizardStepTwoBack ========
            function wizardStepTwoBack() {
                this.wizard.ctrl.prevStep();
            }
            // == wizardStepTwoNext ========
            function wizardStepTwoNext() {
                this.wizard.ctrl.nextStep();
            }
            // == wizardStepThreeBack ========
            function wizardStepThreeBack() {
                this.wizard.ctrl.prevStep();
            }
            // == wizardStepThreeFinish ========
            function wizardStepThreeFinish() {
                ngProgress.start();
                self.archiveTask.serverConnectionId = self.serverConnectionSelectedID;
                self.archiveTask.mailboxGroupId = self.mailboxGroup.id;
                if (self.archiveTask.exclusions) {
                    self.archiveTask.exclusions = removeOffset(self.archiveTask.exclusions);
                }
                if (self.hasNotificationEmailsAccess) {
                    self.archiveTask.notificationEmails = getAllNotificationEmails();
                }
                backupService.createBackupTask(self.archiveTask).then(function (response) {
                    if (!response.err) {
                        $state.go('archive-task-summary', { archiveTaskResponse: response.first });
                    }
                    else {
                        notifyFailure(backupService.getError().getFirstKey(response));
                    }
                    ngProgress.complete();
                }, function () {
                    ngProgress.complete();
                    notifyFailure('$I18N_BACKUP_RESTORE_ARCHIVE_TASK_CREATE_ERROR_MESSAGE');
                });
                self.showTaskCreationWizard = false;
            }
            // == loadConnectorsList ========
            function loadConnectorsList() {
                serverConnectionService.getConnector().then(function (connectorResponse) {
                    if (!connectorResponse.err) {
                        angular.forEach(connectorResponse.all, function (response) {
                            self.connectorsList.push(response);
                        });
                    }
                    else {
                        notifyFailure(serverConnectionService.getError().getFirstKey(connectorResponse));
                    }
                }, function () {
                    notifyFailure('$I18N_BACKUP_RESTORE_ARCHIVE_TASK_LOAD_SERVER_CONNECTION_ERROR_MESSAGE');
                });
            }
            // == isFormValid ========
            function isFormValid() {
                var _a;
                return !(self.formCtrl && self.formCtrl.isInvalid())
                    && angular.isDefined(self.serverConnectionSelectedID) && ((_a = self.serverConnectionSelectedID) === null || _a === void 0 ? void 0 : _a.length) > 0;
            }
            // == isArchiveTaskOptionFormValid ========
            function isArchiveTaskOptionFormValid() {
                return !_.isEmpty(self.mailboxGroup.description) &&
                    (self.archiveTask.includeCalendar ||
                        self.archiveTask.includeContacts ||
                        self.archiveTask.includeMail ||
                        self.archiveTask.includeNotes ||
                        self.archiveTask.includeTasks);
            }
            // == returnToList ========
            function returnToList() {
                $state.go('archive-task-list');
            }
            // == updateArchiveTask ========
            function updateArchiveTask() {
                self.archiveTask.exclusions = (self.applyReplicationExclusions && self.archiveTask.exclusions && removeOffset(self.archiveTask.exclusions)) || [];
                backupService.updateBackupTask(transformArchiveRequestForUpdate(self.archiveTask)).then(function (response) {
                    if (!response.err) {
                        self.createdTask = response.first;
                        returnToList();
                    }
                    else {
                        notifyFailure(backupService.getError().getFirstKey(response));
                    }
                }, function () {
                    notifyFailure('$I18N_BACKUP_RESTORE_ARCHIVE_TASK_UPDATE_ERROR_MESSAGE');
                });
            }
            /* -------------------------------------------------------- *\
             - internal
             \* -------------------------------------------------------- */
            // == transformArchiveRequestForUpdate =========
            function transformArchiveRequestForUpdate(request) {
                request.serverConnectionId = self.serverConnectionSelectedID;
                request.mailboxGroupId = self.mailboxGroup.id;
                if (self.hasNotificationEmailsAccess) {
                    request.notificationEmails = getAllNotificationEmails();
                }
                return _.omit(request, 'serverConnectionSettings', 'mailboxGroup', 'created', 'createdBy', 'error', 'status');
            }
            // == notifyFailure =========
            function notifyFailure(message) {
                self.inlineNotificationOptions.messageBoxAlertTypeClass = 'alert-danger';
                self.inlineNotificationOptions.messageBoxIconClass = 'fas fa-exclamation-triangle';
                self.inlineNotificationOptions.messageBoxTitle = message;
                self.inlineNotificationOptions.messageBoxVisibility = true;
                self.inlineNotificationOptions.messageBoxHideCloseButton = false;
                self.inlineNotificationOptions.messageBoxHideTranscludeContainer = true;
            }
            // == activate =========
            function activate() {
                var _a;
                self.archiveTask.includeSensitive = false;
                self.archiveTask.includeNotes = false;
                self.archiveTask.includeCalendar = false;
                self.archiveTask.includeTasks = false;
                self.archiveTask.includeContacts = false;
                self.archiveTask.includeMail = false;
                self.archiveTaskDetailsHeaderDescription = '$I18N_BACKUP_RESTORE_ARCHIVE_TASK_EDIT_ARCHIVE_TASK_DETAILS_2';
                if (archiveTaskByID) {
                    self.archiveTask = archiveTaskByID;
                    self.serverConnectionSelectedID = self.archiveTask.serverConnectionSettings.id;
                    self.mailboxGroup = self.archiveTask.mailboxGroup;
                    self.disableServerConnectionChange = true;
                    self.notificationEmails = !_.isEmpty(self.archiveTask.notificationEmails) ? (_a = self.archiveTask.notificationEmails) === null || _a === void 0 ? void 0 : _a.join(', ') : '';
                }
                self.applyReplicationExclusions = self.archiveTask.exclusions && self.archiveTask.exclusions.length > 0;
                self.TIME_DURATION_ENUM = archiveTaskEditService.CONSTANTS.TIME_DURATION_ENUM;
                self.inlineNotificationOptions = {
                    messageBoxAlertTypeClass: 'alert-danger',
                    messageBoxIconClass: 'fas fa-exclamation-triangle',
                    messageBoxTitle: '$I18N_BACKUP_RESTORE_ARCHIVE_TASK_EDIT_ERROR_MESSAGE',
                    messageBoxVisibility: false,
                    messageBoxHideCloseButton: false,
                    messageBoxHideTranscludeContainer: true
                };
                self.wizard.steps = [
                    {
                        title: '$I18N_BACKUP_RESTORE_ARCHIVE_TASK_WIZARD_BREADCRUMB_STEP_01',
                        templateUrl: 'services/services/backup/archive-task/archive-task-edit/wizard-steps/wizard-step-01.tpl.html'
                    },
                    {
                        title: '$I18N_BACKUP_RESTORE_ARCHIVE_TASK_WIZARD_BREADCRUMB_STEP_02',
                        templateUrl: 'services/services/backup/archive-task/archive-task-edit/wizard-steps/wizard-step-02.tpl.html'
                    },
                    {
                        title: '$I18N_BACKUP_RESTORE_ARCHIVE_TASK_WIZARD_BREADCRUMB_STEP_03',
                        templateUrl: 'services/services/backup/archive-task/archive-task-edit/wizard-steps/wizard-step-03.tpl.html'
                    }
                ];
            }
            function removeOffset(timeZones) {
                const timeZoneObj = [];
                angular.forEach(timeZones, function (obj) {
                    obj.timeZone = obj.timeZone.match(/[^\s]+/g)[0];
                    timeZoneObj.push(obj);
                });
                return timeZoneObj;
            }
            function includeSensitiveItems() {
                const include = self.archiveTask.includeMail || self.archiveTask.includeCalendar || self.archiveTask.includeTasks;
                if (!include) {
                    self.archiveTask.includeSensitive = false;
                }
                return include;
            }
            function exclusionsValid() {
                if (!self.applyReplicationExclusions) {
                    return true;
                }
                else {
                    return self.archiveTask.exclusions ? self.archiveTask.exclusions.length > 0 : false;
                }
            }
            function validateNotificationEmails() {
                if (_.isEmpty(self.notificationEmails)) {
                    self.notificationEmailsError = false;
                    return;
                }
                self.notificationEmailsError = getAllNotificationEmails().some((e) => !helperValidationService.isAValidEmailPlusCharsLimitCheck(e));
                return;
            }
        }]);
}());
