"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoDataAvailableDisplayPipe = void 0;
class NoDataAvailableDisplayPipe {
    constructor(translateService) {
        this.translateService = translateService;
    }
    transform(value) {
        return !!value
            ? value
            : this.translateService.instant('$I18N_MESSAGE_CENTER_VALUE_NO_DATA_IS_AVAILABLE');
    }
}
exports.NoDataAvailableDisplayPipe = NoDataAvailableDisplayPipe;
