"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProgressBarComponent = void 0;
const progress_bar_models_1 = require("./progress-bar.models");
class ProgressBarComponent {
    constructor() {
        this.mode = progress_bar_models_1.PROGRESS_BAR_MODES.DETERMINATE;
    }
    set max(m) {
        this.maximum = parseInt(String(m), 10);
    }
    set value(v) {
        this.val = parseInt(String(v), 10);
    }
    get percentage() {
        return ((this.val > this.maximum ? this.maximum : this.val) * 100) / this.maximum;
    }
    getStyle() {
        const VAL = ['to right', '#6196d5 ' + this.percentage + '%', '#e5e5e5 ' + '0%'].join(',');
        return {
            background: 'linear-gradient(' + VAL + ')'
        };
    }
}
exports.ProgressBarComponent = ProgressBarComponent;
