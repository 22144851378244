"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const _ = require("lodash");
const moment = require("moment");
/**
 * Created by psambandam on 26/09/2016.
 */
(function () {
    'use strict';
    /* ======================================================== *\

   = email,calendar,contact-view - service
   = aside to display email, calendar and contact detailed view

   \* ======================================================== */
    angular.module('email.view.service', [])
        .factory('emailViewService', ['$aside', 'apiArchiveService', '$q', '$sce', 'ngProgressFactory', 'capabilitiesService', 'adconBackupService', function ($aside, apiArchiveService, $q, $sce, ngProgressFactory, capabilitiesService, adconBackupService) {
            const ngProgress = ngProgressFactory.createInstance();
            let asideInstance;
            const ingestionErrorCodes = [
                'ERR_CALENDAR_UNKNOWN',
                'ERR_CALENDAR_INTERNAL',
                'ERR_CALENDAR_ZERO_SIZE',
                'ERR_CALENDAR_NEW_MESSAGE',
                'ERR_CALENDAR_DATE_MISSING',
                'ERR_CALENDAR_DATE_BEFORE_1970',
                'ERR_CALENDAR_DUPLICATE_MESSAGE',
                'ERR_CALENDAR_DATE_AFTER_BATCH_START',
                'ERR_CALENDAR_DATE_OUTSIDE_RETENTION',
                'ERR_CALENDAR_DATE_AFTER_END_DATE_CUTOFF',
                'ERR_CALENDAR_DATE_BEFORE_START_DATE_CUTOFF'
            ];
            /* -------------------------------------------------------- *\
             - interface
             \* -------------------------------------------------------- */
            return {
                openEmailView,
                getMessage,
                getMessagePart,
                showImages,
                openContactView,
                openCalendarView,
                getCalendar
            };
            /* -------------------------------------------------------- *\
             - implementation
             \* -------------------------------------------------------- */
            // == openEmailView ========
            function openEmailView(email, source, origin) {
                asideInstance = $aside.open({
                    templateUrl: 'services/services/backup/components/email-view/email-view.tpl.html',
                    placement: 'right',
                    windowClass: getCssClasses('email', source, origin),
                    backdropClass: 'hidden-xs',
                    backdrop: true,
                    controller: angular.noop,
                    resolve: {
                        message() {
                            return getMessage(email);
                        },
                        asideInstance() {
                            return asideInstance;
                        },
                        mailbox() {
                            return '';
                        }
                    }
                });
                return asideInstance;
            }
            // == openContactView ========
            function openContactView(contact, mailbox, source, origin) {
                asideInstance = $aside.open({
                    templateUrl: 'services/services/backup/components/email-view/contact-detail/contact-detail.tpl.html',
                    placement: 'right',
                    windowClass: getCssClasses('contact', source, origin),
                    backdropClass: 'hidden-xs',
                    backdrop: true,
                    controller: angular.noop,
                    resolve: {
                        contact() {
                            return contact;
                        },
                        asideInstance() {
                            return asideInstance;
                        },
                        mailbox() {
                            return mailbox;
                        }
                    }
                });
                return asideInstance;
            }
            // == getMessagePart ========
            function getMessagePart(request) {
                return apiArchiveService.getMessagePart(request);
            }
            // == showImages ========
            function showImages(selectedItem) {
                const request = {
                    id: selectedItem.id,
                    context: 'RECEIVED',
                    type: 'HTML',
                    stripImg: false,
                    stripStyles: true,
                    stripDangerous: false,
                    transposeInlineCID: true
                };
                return getMessagePart(request).then(function (response) {
                    if (!response.err) {
                        ngProgress.start();
                        selectedItem.content = $sce.trustAsHtml(response.raw);
                        selectedItem.showImages = true;
                    }
                    else {
                        ngProgress.complete();
                        apiArchiveService.getError().showErrorNotification(response);
                    }
                    ngProgress.complete();
                }, function () {
                    ngProgress.complete();
                    apiArchiveService.getError().showErrorNotification();
                });
            }
            // == getMessage ========
            function getMessage(request) {
                if (!adconBackupService.CONSTANTS.HAS_PERMISSIONS.contentView) {
                    return undefined;
                }
                const getMessageRequest = {
                    id: request.id,
                    viewType: 'mailbox_recovery'
                };
                return apiArchiveService.getMessageDetail(getMessageRequest).then(function (messageInfoData) {
                    const message = {
                        message: {}
                    };
                    if (!messageInfoData.err) {
                        ngProgress.start();
                        message.message = messageInfoData.first;
                        const messagePartPromisses = getMessagePartPromisses(message.message);
                        return $q.all(messagePartPromisses).then(function (response) {
                            if (_.every(response, { err: false })) {
                                ngProgress.start();
                                message.message = getProcessedMessageResponse(message.message, response);
                                ngProgress.complete();
                            }
                            else {
                                ngProgress.complete();
                                message.error = apiArchiveService.getError().getTranslationText(response.fail().first.errors[0].code);
                            }
                            return message;
                        }, function () {
                            ngProgress.complete();
                            message.error = apiArchiveService.getError().showErrorNotification();
                            return message;
                        });
                    }
                    else {
                        ngProgress.complete();
                        message.error = apiArchiveService.getError().getTranslationText(messageInfoData.fail().first.errors[0].code);
                        return message;
                    }
                }, function (error) {
                    ngProgress.complete();
                    return $q.reject(error);
                });
            }
            function openCalendarView(calendar, mailbox, source, origin) {
                asideInstance = $aside.open({
                    templateUrl: 'services/services/backup/components/email-view/email-view.tpl.html',
                    placement: 'right',
                    windowClass: getCssClasses('calendar', source, origin),
                    backdropClass: 'hidden-xs',
                    backdrop: true,
                    controller: angular.noop,
                    resolve: {
                        message() {
                            return getCalendar(calendar, mailbox);
                        },
                        mailbox() {
                            return mailbox;
                        },
                        asideInstance() {
                            return asideInstance;
                        }
                    }
                });
                return asideInstance;
            }
            function getCalendar(request, mailbox) {
                if (!adconBackupService.CONSTANTS.HAS_PERMISSIONS.contentView) {
                    return undefined;
                }
                const getMessageRequest = {
                    id: request.id,
                    mailbox
                };
                return apiArchiveService.getCalendarItemDetail(getMessageRequest).then(function (messageInfoData) {
                    let message = {};
                    if (!messageInfoData.err) {
                        ngProgress.start();
                        message = messageInfoData.first;
                        message.isCalendarItem = true;
                        const messagePartPromisses = getMessagePartPromisses(message);
                        return $q.all(messagePartPromisses).then(function (response) {
                            if (_.every(response, { err: false })) {
                                ngProgress.start();
                                message = getProcessedMessageResponse(message, response);
                                ngProgress.complete();
                            }
                            else {
                                ngProgress.complete();
                                message.error = apiArchiveService.getError().getTranslationText(response.fail().first.errors[0].code);
                            }
                            if (message.duration && !message.allDayEvent) {
                                moment.relativeTimeThreshold('m', 59);
                                message.eventDuration = (message.duration.hours > 0) ? moment.duration(message.duration.hours, 'hours').humanize() + ' ' : '';
                                message.eventDuration += (message.duration.minutes > 0) ? moment.duration(message.duration.minutes, 'minutes').humanize() + ' ' : '';
                                message.eventDuration += (message.duration.seconds > 0) ? moment.duration(message.duration.seconds, 'seconds').humanize() + ' ' : '';
                            }
                            if (message.retrievalCode) {
                                const start = moment(message.start);
                                const end = moment(message.end);
                                const eventDuration = end.diff(start, 'minutes', true);
                                message.retrievedEventDuration = moment.duration(eventDuration, 'minutes').humanize() + ' ';
                                const messageRetrievalCode = message.retrievalCode.toUpperCase();
                                if (ingestionErrorCodes.includes(messageRetrievalCode)) {
                                    const isOutsideRetentionError = messageRetrievalCode === 'ERR_CALENDAR_DATE_OUTSIDE_RETENTION';
                                    message.retrievalCode = isOutsideRetentionError ? '$I18N_ERR_CALENDAR_DATE_OUTSIDE_RETENTION' : '$I18N_ERR_CALENDAR_UNKNOWN';
                                }
                                else {
                                    message.retrievedErrorCode = message.retrievalCode.split('_').slice(-1).pop();
                                }
                            }
                            return { message };
                        }, function () {
                            ngProgress.complete();
                            // @ts-ignore
                            message.error = apiArchiveService.getError().getTranslationText(response.fail().first.errors[0].code);
                            return message;
                        });
                        ngProgress.complete();
                    }
                    else {
                        ngProgress.complete();
                        message.error = apiArchiveService.getError().getTranslationText(messageInfoData.fail().first.errors[0].code);
                        return message;
                    }
                    ngProgress.complete();
                }, function (error) {
                    ngProgress.complete();
                    apiArchiveService.getError().showErrorNotification();
                    return $q.reject(error);
                });
            }
            function getMessagePartPromisses(message) {
                const messagePartPromisses = [];
                if (message.hasHtmlBody === true) {
                    messagePartPromisses.push(getMessagePart({
                        id: message.id,
                        context: 'RECEIVED',
                        type: 'HTML',
                        stripImg: !!message.stripImages,
                        stripDangerous: false,
                        transposeInlineCID: true
                    }));
                }
                if (message.hasTextBody === true) {
                    messagePartPromisses.push(getMessagePart({
                        id: message.id,
                        context: 'RECEIVED',
                        type: 'PLAIN'
                    }));
                }
                return messagePartPromisses;
            }
            function getProcessedMessageResponse(message, response) {
                let htmlBodyResponse;
                let textBodyResponse;
                if (message.hasHtmlBody === true) {
                    htmlBodyResponse = $sce.trustAsHtml(response[0].raw);
                }
                if (message.hasHtmlBody === false && message.hasTextBody === true) {
                    textBodyResponse = response[0].raw;
                }
                else if (message.hasTextBody === true) {
                    textBodyResponse = response[1].raw;
                }
                message.content = htmlBodyResponse;
                message.plainContent = textBodyResponse;
                message.selectedTab = {
                    canPrint: true,
                    printTemplateKey: 'html'
                };
                return message;
            }
            function getCssClasses(type, source, origin) {
                let cssClasses = 'mc-mail-view-side-panel hidden-xs';
                if (source === 'mailbox') {
                    if (origin === 'archive') {
                        cssClasses += ' mc-sync-recover-exchange-mailbox-archive-view-' + type + '-dialog';
                    }
                    else {
                        cssClasses += ' mc-sync-recover-exchange-mailbox-live-view-' + type + '-dialog';
                    }
                }
                else {
                    if (source === 'snapshot') {
                        if (origin === 'archive') {
                            cssClasses += ' mc-sync-recover-exchange-snapshot-archive-view-' + type + '-dialog';
                        }
                        else {
                            cssClasses += ' mc-sync-recover-exchange-snapshot-lastsync-' + type + '-dialog';
                        }
                    }
                }
                return cssClasses;
            }
        }]);
}());
