"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("mimecast-web-components/src/app/services/audit/audit"); //api.audit
require("./audit-logs-controller"); //auditLogs.administration.controller
require("app/components/pagination/pagination"); //pagination
require("mimecast-web-components/src/app/components/mc-date-transformer/mc-server-timezone-date-transform-filter"); //mc-server-timezone-date-transform.filter
// html and css
require("./audit-logs.less");
require("./audit-logs.tpl.html");
require("./slides/audit-log-view.tpl.html");
/**
 * Created by pdesai on 09/02/2017.
 */
(function () {
    'use strict';
    angular.module('auditLogs.administration', [
        'api.audit',
        'auditLogs.administration.controller',
        'pagination',
        'mc-server-timezone-date-transform.filter'
    ])
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('audit-logs', {
                url: '/audit-logs',
                params: {
                    dateRange: null,
                    filters: null
                },
                data: {
                    isFixedLayout: true,
                    trackProgress: true,
                    asideTitle: '$I18N_ADMINISTRATION_MENU_SELECT_DASHBOARD',
                    tabId: 'AuditLogsMain',
                    tabTitle: '$I18N_ADMINISTRATION_MENU_ACCOUNT_ITEM_AUDIT_LOGS',
                    tabHide: false,
                    tabReload: false
                },
                views: {
                    'main': {
                        templateUrl: 'services/account/audit-logs/audit-logs.tpl.html',
                    }
                }
            });
        }]);
}());
