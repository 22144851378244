"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DMARCDetailsDisplayPipe = void 0;
class DMARCDetailsDisplayPipe {
    constructor(translateService) {
        this.translateService = translateService;
    }
    transform(dmarcInfo = '') {
        if (!dmarcInfo.toLowerCase().startsWith('dmarc_')) {
            dmarcInfo = 'dmarc_' + dmarcInfo;
        }
        switch (dmarcInfo.toLowerCase()) {
            case 'dmarc_unrecognized':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_DMARC.UNRECOGNIZED');
            case 'dmarc_error':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_DMARC.DMARC_ERROR');
            case 'dmarc_unknown':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_DMARC.DMARC_UNKNOWN');
            case 'dmarc_allow':
                return `<span class="positiveProperty">${this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_DMARC.DMARC_ALLOW')}</span>`;
            case 'dmarc_bad_policy':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_DMARC.DMARC_BAD_POLICY');
            case 'dmarc_na':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_DMARC.DMARC_NA');
            case 'dmarc_dnsfail':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_DMARC.DMARC_DNSFAIL');
            case 'dmarc_policy_none':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_DMARC.DMARC_POLICY_NONE');
            case 'dmarc_policy_quarantine':
                return `<span class="negativeProperty">${this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_DMARC.DMARC_POLICY_QUARANTINE')}</span>`;
            case 'dmarc_policy_reject':
                return `<span class="negativeProperty">${this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_DMARC.DMARC_POLICY_REJECT')}</span>`;
            case 'dmarc_policy_softfail':
                return `<span class="negativeProperty">${this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_DMARC.DMARC_POLICY_SOFTFAIL')}</span>`;
            case 'dmarc_quarantine':
                return `<span class="negativeProperty">${this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_DMARC.DMARC_QUARANTINE')}</span>`;
            case 'dmarc_reject':
                return `<span class="negativeProperty">${this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_DMARC.DMARC_REJECT')}</span>`;
            case 'dmarc_soft_fail':
                return `<span class="negativeProperty">${this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_DMARC.DMARC_SOFT_FAIL')}</span>`;
            case 'dmarc_policy_allow':
                return `<span class="positiveProperty">${this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_DMARC.DMARC_POLICY_ALLOW')}</span>`;
            default:
                return '';
        }
    }
}
exports.DMARCDetailsDisplayPipe = DMARCDetailsDisplayPipe;
