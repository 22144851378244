"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrackingMainSearchReasonComponent = void 0;
const forms_1 = require("@angular/forms");
class TrackingMainSearchReasonComponent {
    constructor(fb, overlayRef, previousSearchReason) {
        this.fb = fb;
        this.overlayRef = overlayRef;
        this.previousSearchReason = previousSearchReason;
    }
    ngOnInit() {
        this.form = this.fb.group({
            searchReason: [
                this.previousSearchReason,
                [forms_1.Validators.required, forms_1.Validators.minLength(6), forms_1.Validators.maxLength(60)]
            ]
        });
        setTimeout(() => this.searchReasonInput.nativeElement.focus(), 0);
    }
    onSubmit() {
        if (this.form.valid) {
            this.close(this.form.value.searchReason);
        }
    }
    close(closeValue) {
        this.overlayRef.close(closeValue);
    }
}
exports.TrackingMainSearchReasonComponent = TrackingMainSearchReasonComponent;
