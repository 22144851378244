"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RuleBuilder = void 0;
const rule_1 = require("./rule");
class RuleBuilder {
    withId(id) {
        this.id = id;
        return this;
    }
    withName(name) {
        this.name = name;
        return this;
    }
    withCreatedBy(createdBy) {
        this.createdBy = createdBy;
        return this;
    }
    withUpdatedBy(updatedBy) {
        this.updatedBy = updatedBy;
        return this;
    }
    withCreated(created) {
        this.created = created;
        return this;
    }
    withUpdated(updated) {
        this.updated = updated;
        return this;
    }
    withDescription(description) {
        this.description = description;
        return this;
    }
    withSamplingPercentage(samplingPercentage) {
        this.samplingPercentage = samplingPercentage;
        return this;
    }
    withEnabled(enabled) {
        this.enabled = enabled;
        return this;
    }
    withReviewQueue(reviewQueue) {
        this.reviewQueue = reviewQueue;
        return this;
    }
    withScheduleType(scheduleType) {
        // TODO: Does this affect anything in the rules-list page
        // this.scheduleType = ScheduleType[scheduleType];
        this.scheduleType = scheduleType;
        return this;
    }
    withMessageRoute(messageRoute) {
        this.messageRoute = messageRoute;
        return this;
    }
    withLexicon(lexicon) {
        this.lexicon = lexicon;
        return this;
    }
    withSearchGroup(searchGroup) {
        this.searchGroup = searchGroup;
        return this;
    }
    withLastExecuted(lastExecuted) {
        this.lastExecuted = lastExecuted;
        return this;
    }
    withStatus(status) {
        this.status = status;
        return this;
    }
    withState(state) {
        this.state = state;
        return this;
    }
    build() {
        return new rule_1.Rule(this.id, this.name, this.createdBy, this.updatedBy, this.created, this.updated, this.lastExecuted, this.description, this.samplingPercentage, this.enabled, this.reviewQueue, this.scheduleType, this.messageRoute, this.lexicon, this.searchGroup, this.status, this.state);
    }
}
exports.RuleBuilder = RuleBuilder;
