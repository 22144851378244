<div>
  <mc-collapsable-panel header="$I18N_MESSAGE_CENTER_MESSAGE_INFO_SUMMARY.TITLE" [isExpanded]="true">
    <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_SUMMARY.LABEL_FROM_ENVELOPE" [value]="messageInfo?.fromEnvelope"></mc-value-field>
    <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_SUMMARY.LABEL_FROM_HEADER" [value]="messageInfo?.fromHeader"></mc-value-field>
    <mc-emails-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_SUMMARY.LABEL_FROM_TO" [emails]="messageInfo?.to"></mc-emails-field>
    <mc-emails-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_SUMMARY.LABEL_CC" [emails]="messageInfo?.cc"></mc-emails-field>
    <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_SUMMARY.LABEL_SUBJECT" [value]="messageInfo?.subject"></mc-value-field>
    <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_SUMMARY.LABEL_SENT" value="{{ messageInfo?.sent | mcDatePlusWeekday }}"></mc-value-field>
    <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_SUMMARY.LABEL_PROCESSED" value="{{ messageInfo?.processed | mcDatePlusWeekday }}"></mc-value-field>
  </mc-collapsable-panel>
</div>
