"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RemediationFindGroupsResetStore = exports.RemediationFindGroupsFail = exports.RemediationFindGroupsNotSet = exports.RemediationFindGroupsLDAPSuccess = exports.RemediationFindGroupsCloudSuccess = exports.RemediationFindGroups = exports.FIND_GROUPS_RESET_STORE = exports.FIND_GROUPS_NOT_SET = exports.FIND_GROUPS_FAIL = exports.FIND_GROUPS_LDAP_SUCCESS = exports.FIND_GROUPS_CLOUD_SUCCESS = exports.FIND_GROUPS = void 0;
exports.FIND_GROUPS = '[Remediation] Find Groups';
exports.FIND_GROUPS_CLOUD_SUCCESS = '[Remediation] Find Groups success cloud';
exports.FIND_GROUPS_LDAP_SUCCESS = '[Remediation] Find Groups success ldap';
exports.FIND_GROUPS_FAIL = '[Remediation] Find Groups fail';
exports.FIND_GROUPS_NOT_SET = '[Remediation] Find Groups not set';
exports.FIND_GROUPS_RESET_STORE = '[Remediation] Find Groups reset store';
class RemediationFindGroups {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.FIND_GROUPS;
    }
}
exports.RemediationFindGroups = RemediationFindGroups;
class RemediationFindGroupsCloudSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.FIND_GROUPS_CLOUD_SUCCESS;
    }
}
exports.RemediationFindGroupsCloudSuccess = RemediationFindGroupsCloudSuccess;
class RemediationFindGroupsLDAPSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.FIND_GROUPS_LDAP_SUCCESS;
    }
}
exports.RemediationFindGroupsLDAPSuccess = RemediationFindGroupsLDAPSuccess;
class RemediationFindGroupsNotSet {
    constructor() {
        this.type = exports.FIND_GROUPS_NOT_SET;
    }
}
exports.RemediationFindGroupsNotSet = RemediationFindGroupsNotSet;
class RemediationFindGroupsFail {
    constructor() {
        this.type = exports.FIND_GROUPS_FAIL;
    }
}
exports.RemediationFindGroupsFail = RemediationFindGroupsFail;
class RemediationFindGroupsResetStore {
    constructor() {
        this.type = exports.FIND_GROUPS_RESET_STORE;
    }
}
exports.RemediationFindGroupsResetStore = RemediationFindGroupsResetStore;
