'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
angular.module('backuprestore-mailbox-details.slides-success.controller', [])
    .controller('MailboxExportRestoreSuccessCtrl', ['headerTitle', 'title', 'descMsg', function (headerTitle, title, descMsg) {
        const self = this;
        self.headerTitle = headerTitle;
        self.title = title;
        self.descMsg = descMsg;
    }]);
