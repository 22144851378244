"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.searchResults = exports.isLoading = exports.reducer = exports.initialState = void 0;
const actions = require("../actions");
exports.initialState = {
    isLoading: false,
    searchResults: []
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.SEARCH_DIRECTORY:
            return Object.assign(Object.assign({}, state), { isLoading: true, searchResults: [] });
        case actions.SEARCH_DIRECTORY_SUCCESS:
            return Object.assign(Object.assign({}, state), { isLoading: false, searchResults: [...action.payload] });
        case actions.CLEAR_SEARCH_DIRECTORY:
        case actions.SEARCH_DIRECTORY_FAIL:
            return Object.assign(Object.assign({}, state), { isLoading: false, searchResults: [] });
        default:
            return state;
    }
}
exports.reducer = reducer;
const isLoading = (state) => state.isLoading;
exports.isLoading = isLoading;
const searchResults = (state) => state.searchResults;
exports.searchResults = searchResults;
