"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.notifications = void 0;
const actions = require("../actions");
exports.notifications = {
    [actions.MESSAGE_DELIVERY_REJECT_EMAILS_SUCCESS]: {
        message: '$I18N_MESSAGE_CENTER_MESSAGE_DELIVERY_NOTIFICATIONS.REJECT_EMAILS_SUCCESS',
        type: 'success'
    },
    [actions.MESSAGE_DELIVERY_REJECT_EMAILS_FAIL]: {
        message: '$I18N_MESSAGE_CENTER_MESSAGE_DELIVERY_NOTIFICATIONS.REJECT_EMAILS_FAIL',
        type: 'error'
    },
    [actions.MESSAGE_DELIVERY_RETRY_EMAILS_SUCCESS]: {
        message: '$I18N_MESSAGE_CENTER_MESSAGE_DELIVERY_NOTIFICATIONS.RETRY_EMAILS_SUCCESS',
        type: 'success'
    },
    [actions.MESSAGE_DELIVERY_RETRY_EMAILS_FAIL]: {
        message: '$I18N_MESSAGE_CENTER_MESSAGE_DELIVERY_NOTIFICATIONS.RETRY_EMAILS_FAIL',
        type: 'warning'
    },
    [actions.MESSAGE_DELIVERY_RETRY_LOCKED_EMAILS_FAIL]: {
        message: '$I18N_MESSAGE_CENTER_MESSAGE_DELIVERY_NOTIFICATIONS.RETRY_LOCKED_EMAILS_FAIL',
        type: 'warning'
    },
    [actions.MESSAGE_RECALCULATE_ROUTE_SUCCESS]: {
        message: '$I18N_MESSAGE_CENTER_MESSAGE_DELIVERY_NOTIFICATIONS.RECALCULATE_ROUTE_SUCCESS',
        type: 'success'
    },
    [actions.MESSAGE_RECALCULATE_ROUTE_FAIL]: {
        message: '$I18N_MESSAGE_CENTER_MESSAGE_DELIVERY_NOTIFICATIONS.RECALCULATE_ROUTE_FAIL',
        type: 'error'
    },
    [actions.MESSAGE_DELIVERY_EARLY_BOUNCE_EMAILS_SUCCESS]: {
        message: '$I18N_MESSAGE_CENTER_MESSAGE_DELIVERY_NOTIFICATIONS.EARLY_BOUNCE_SUCCESS',
        type: 'success'
    },
    [actions.MESSAGE_DELIVERY_EARLY_BOUNCE_EMAILS_FAIL]: {
        message: '$I18N_MESSAGE_CENTER_MESSAGE_DELIVERY_NOTIFICATIONS.EARLY_BOUNCE_FAIL',
        type: 'error'
    }
};
