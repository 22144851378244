<mc-layout-list-table
  keyTitle="$I18N_SERVICES_CYBERGRAPH_POLICIES_TABLE.TITLE"
  extendClass="mc-create-policy"
  [keyDescription]="keyDescription"
>
  <mc-help-container>
    <mc-help-link url="https://community.mimecast.com/s/">
    </mc-help-link>
  </mc-help-container>
  <mc-table
    *ngIf="isReady"
    tableId="cybergraph/policies/list"
    [isLoading]="isLoading$ | async"
    [data]="tableData$ | async"
    [columns]="columns"
    translatePrefix="$I18N_SERVICES_CYBERGRAPH_POLICIES_TABLE.TABLE"
    (rowClick)="clickRow($event)"
  >
    <button
      class="mc-table-actions btn btn-primary panel-half-margin-right"
      (click)="addPolicy()"
    >
      {{ '$I18N_GENERAL_CREATE_POLICY_CREATE_NEW_POLICY' | translate }}
    </button>
    <mc-filters (paginatorChange)="onPaginationChange($event)">
      <mc-filter-search
        (search)="onSearchIncidents($event)"
        (clear)="onSearchClear()"
      >
      </mc-filter-search>
    </mc-filters>
    <mc-column key="name">
      <mc-body-cell *mcBodyCellDef="let row">
        <div class="mc-status-text-container">
          <span>{{ row.name }}</span>
        </div>
      </mc-body-cell>
    </mc-column>
    <mc-column key="description">
      <mc-body-cell *mcBodyCellDef="let row" class="text-capitalize">{{ row.description }}</mc-body-cell>
    </mc-column>
    <mc-column key="codebreakerStatus">
      <mc-body-cell *mcBodyCellDef="let row" class="text-capitalize">
        <span>{{ getCyberGraphDynamicBannerEnumLabel(row.codebreakerStatus) }}</span>
      </mc-body-cell>
    </mc-column>
    <mc-column key="silencerStatus">
      <mc-body-cell *mcBodyCellDef="let row" class="text-capitalize">
        <span>{{ getCyberGraphTrackersEnumLabel(row.silencerStatus) }}</span>
      </mc-body-cell>
    </mc-column>
    <mc-column key="reportingStatus">
      <mc-body-cell *mcBodyCellDef="let row" class="text-capitalize">
        <div class="mc-status-text-container">
          <span>{{ getCyberGraphReportingStatusEnumLabel(row.reportingStatus) }}</span>
        </div>
      </mc-body-cell>
    </mc-column>
    <mc-column key="status">
      <mc-body-cell *mcBodyCellDef="let row" class="text-capitalize">
        <i [ngClass]="displayStatusIcon(row)"></i>
        {{displayStatusLabel(row)}}
      </mc-body-cell>
    </mc-column>
    <mc-column-date key="createTime"></mc-column-date>
    <mc-column-actions key="right-column" mcShowColumnConfig class="cybergraph-show-cols">
      <mc-row-actions *mcRowActions="let row">
          <li mcRowAction="$I18N_SERVICES_CYBERGRAPH_POLICIES_TABLE.ACTIONS.EDIT"
          data-unit-test="unit-edit-action-btn" (click)="editPolicy(row)">
        </li>
          <li mcRowAction="$I18N_SERVICES_CYBERGRAPH_POLICIES_TABLE.ACTIONS.DETAILS"
             data-unit-test="unit-details-action-btn" (click)="clickRow(row)">
          </li>
          <li  mcRowAction="$I18N_SERVICES_CYBERGRAPH_POLICIES_TABLE.ACTIONS.DELETE"
            data-unit-test="unit-delete-action-btn" (click)="deletePolicy(row)">
          </li>
      </mc-row-actions>
    </mc-column-actions>
    <mc-empty-results keyTitle="$18_GENERAL_POLICY_NO_DATA"></mc-empty-results>
  </mc-table>
</mc-layout-list-table>
