"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.getLoadingForMepLogDetails = exports.getMepLogItem = void 0;
const store_1 = require("@ngrx/store");
const reducers_1 = require("../../reducers");
const mepLogSidebar = require("../../reducers/sidebar/mep-log-sidebar.reducer");
const ɵ0 = state => state.mepLogSidebar;
exports.ɵ0 = ɵ0;
const mepLogSidebarState = store_1.createSelector(reducers_1.getMepLogState, ɵ0);
exports.getMepLogItem = store_1.createSelector(mepLogSidebarState, mepLogSidebar.getMepLogItem);
exports.getLoadingForMepLogDetails = store_1.createSelector(mepLogSidebarState, mepLogSidebar.isLoading);
