"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FindGroupsResetStore = exports.FindGroupsFail = exports.FindGroupsNotSet = exports.FindGroupsLDAPSuccess = exports.FindGroupsCloudSuccess = exports.FindGroups = exports.FIND_GROUPS_RESET_STORE = exports.FIND_GROUPS_NOT_SET = exports.FIND_GROUPS_FAIL = exports.FIND_GROUPS_LDAP_SUCCESS = exports.FIND_GROUPS_CLOUD_SUCCESS = exports.FIND_GROUPS = void 0;
exports.FIND_GROUPS = '[API Integration] Find Groups';
exports.FIND_GROUPS_CLOUD_SUCCESS = '[API Integration] Find Groups success cloud';
exports.FIND_GROUPS_LDAP_SUCCESS = '[API Integration] Find Groups success ldap';
exports.FIND_GROUPS_FAIL = '[API Integration] Find Groups fail';
exports.FIND_GROUPS_NOT_SET = '[API Integration] Find Groups not set';
exports.FIND_GROUPS_RESET_STORE = '[API Integration] Find Groups reset store';
class FindGroups {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.FIND_GROUPS;
    }
}
exports.FindGroups = FindGroups;
class FindGroupsCloudSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.FIND_GROUPS_CLOUD_SUCCESS;
    }
}
exports.FindGroupsCloudSuccess = FindGroupsCloudSuccess;
class FindGroupsLDAPSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.FIND_GROUPS_LDAP_SUCCESS;
    }
}
exports.FindGroupsLDAPSuccess = FindGroupsLDAPSuccess;
class FindGroupsNotSet {
    constructor() {
        this.type = exports.FIND_GROUPS_NOT_SET;
    }
}
exports.FindGroupsNotSet = FindGroupsNotSet;
class FindGroupsFail {
    constructor() {
        this.type = exports.FIND_GROUPS_FAIL;
    }
}
exports.FindGroupsFail = FindGroupsFail;
class FindGroupsResetStore {
    constructor() {
        this.type = exports.FIND_GROUPS_RESET_STORE;
    }
}
exports.FindGroupsResetStore = FindGroupsResetStore;
