"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MepLogStatusComponent = void 0;
const status_static_value_1 = require("./status.static-value");
class MepLogStatusComponent {
    constructor(translate$) {
        this.translate$ = translate$;
        this.validStatuses = Object.keys(status_static_value_1.mepStatusMapping);
        this.statusProperties = {
            label: this.translate$.instant(status_static_value_1.mepStatusMapping.unknown.label),
            colour: status_static_value_1.mepStatusMapping.unknown.colour
        };
    }
    ngOnChanges() {
        if (this.validStatuses.includes(this.status)) {
            this.statusProperties = {
                label: this.translate$.instant(status_static_value_1.mepStatusMapping[this.status].label),
                description: this.description && Object.keys(status_static_value_1.heldStatusMapping).includes(this.description)
                    ? this.translate$.instant(status_static_value_1.heldStatusMapping[this.description])
                    : null,
                colour: status_static_value_1.mepStatusMapping[this.status].colour
            };
        }
        else {
            // If we have received an invalid status string, default to 'Unknown'.
            this.statusProperties = {
                label: this.translate$.instant(status_static_value_1.mepStatusMapping.unknown.label),
                colour: status_static_value_1.mepStatusMapping.unknown.colour
            };
        }
    }
    computeClasses() {
        // If the colour of the circle is white, we actually set the colour to grey with Font Awesome's 'far' class.
        // This will produce a hollow circle with a grey circumference and a white interior.
        // For all other colours, we will use a solid circle of that colour.
        return this.statusProperties.colour === 'white'
            ? 'far grey'
            : `fas ${this.statusProperties.colour}`;
    }
}
exports.MepLogStatusComponent = MepLogStatusComponent;
