"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AwarenessListPageComponent = void 0;
const list = require("../actions/list");
const fromList = require("../reducers");
class AwarenessListPageComponent {
    /**
     * constructor
     */
    constructor(store) {
        this.store = store;
    }
    /**
     * Initialize the listContent$ observable
     * Initialize the isLoading$ observable
     * dispatch action to load the list from the server
     */
    ngOnInit() {
        this.listContent$ = this.store.select(fromList.getListContents);
        this.isLoading$ = this.store.select(fromList.isListLoading);
        this.store.dispatch(new list.LoadAction());
    }
    /**
     * dispatch new toggle sort action
     */
    toggleSort() {
        this.store.dispatch(new list.ToggleOrderAction());
    }
    /**
     * dispatch new search action with the payload
     */
    search(searchTerm) {
        this.store.dispatch(new list.SearchAction(searchTerm));
    }
    /**
     * dispatch new delete action
     */
    deleteTemplate(template) {
        this.store.dispatch(new list.DeleteAction(template));
    }
}
exports.AwarenessListPageComponent = AwarenessListPageComponent;
