"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WizardBaseDirective = void 0;
const selectors = require("app-new/services/im-sync/reducers");
const wizardActions = require("app-new/services/im-sync/actions/wizard.actions");
const rxjs_1 = require("rxjs");
const im_sync_actions_1 = require("../actions/im-sync.actions");
const reducers_1 = require("app-new/services/im-sync/reducers");
const operators_1 = require("rxjs/operators");
class WizardBaseDirective {
    constructor(capabilitiesService, wizardId, store, translateService) {
        this.capabilitiesService = capabilitiesService;
        this.wizardId = wizardId;
        this.store = store;
        this.translateService = translateService;
        this.destroy$ = new rxjs_1.Subject();
        this.connectorsReadPermission = 'Permission.SERVER_CONNECTIONS_READ';
        if (!wizardId) {
            throw new Error('wizardId is mandatory');
        }
    }
    ngOnInit() {
        this.isCurrentStepValid$ = this.store.select(selectors.isCurrentStepValid(this.wizardId));
        this.wizardData$ = this.store.select(selectors.getWizardById(this.wizardId));
        this.wizardData$.pipe(operators_1.takeUntil(this.destroy$)).subscribe(data => (this.wizardData = data));
        this.connectors$ = this.store.select(reducers_1.getConnectors);
        this.capabilitiesService
            .hasCapability(this.connectorsReadPermission)
            .pipe(operators_1.takeUntil(this.destroy$))
            .subscribe(hasConnectorsReadPermission => {
            if (hasConnectorsReadPermission) {
                this.store.dispatch(new im_sync_actions_1.LoadConnectorsAction());
            }
        });
    }
    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
    onCancel() {
        this.store.dispatch(new wizardActions.CancelAction({
            title: this.getCancelConfirmationText(),
            wizardId: this.wizardId
        }));
    }
    onStepChange(stepId) {
        this.store.dispatch(new wizardActions.SetCurrentStepAction({ wizardId: this.wizardId, stepId }));
    }
    onNext() {
        if (this.wizard.currentStep === 1) {
            sessionStorage.setItem('wizardDataObject', JSON.stringify(this.wizardData));
        }
        this.wizard.nextStep();
    }
    onPrevious() {
        this.wizard.previousStep();
    }
}
exports.WizardBaseDirective = WizardBaseDirective;
