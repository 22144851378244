"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidationTableComponent = void 0;
const core_1 = require("@angular/core");
class ValidationTableComponent {
    constructor() {
        this.removeItem = new core_1.EventEmitter();
        this.columns = ['validity', 'domainName', 'errorMessage', 'delete-column'];
    }
}
exports.ValidationTableComponent = ValidationTableComponent;
