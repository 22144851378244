"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiemChannelDetailsStepComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const operators_1 = require("rxjs/operators");
const siem_channels_models_1 = require("../../../../../models/siem-channels.models");
class SiemChannelDetailsStepComponent {
    constructor(fb) {
        this.fb = fb;
        this.channelType = siem_channels_models_1.ChannelType;
        this.availableChannelTypes = [];
        this.updateChannelDescr = new core_1.EventEmitter();
    }
    ngOnInit() {
        if (this.metadataProducts) {
            const types = new Set();
            this.metadataProducts.map(p => {
                p.groups.map(g => types.add(g.type));
            });
            this.availableChannelTypes = Array.from(types.values());
        }
        this.channelPage = this.fb.group({
            description: [this.channel.description],
            name: [this.channel.name, [forms_1.Validators.required, forms_1.Validators.pattern(/[\S]+/)]],
            type: [
                {
                    value: this.availableChannelTypes.length === 1
                        ? this.availableChannelTypes[0]
                        : this.channel.type,
                    disabled: this.isEditMode
                },
                forms_1.Validators.required
            ]
        });
        this.channelPageSubscription = this.channelPage.valueChanges
            .pipe(operators_1.debounceTime(200))
            .subscribe(() => {
            this.updateStore();
        });
    }
    updateStore() {
        if (this.isEditMode) {
            const channelDescr = Object.assign(Object.assign({}, this.channelPage.value), { type: this.channel.type });
            this.updateChannelDescr.emit(channelDescr);
        }
        else {
            this.updateChannelDescr.emit(this.channelPage.value);
        }
    }
    isValid() {
        return this.channelPage.status === 'VALID';
    }
    ngOnDestroy() {
        this.channelPageSubscription.unsubscribe();
    }
}
exports.SiemChannelDetailsStepComponent = SiemChannelDetailsStepComponent;
