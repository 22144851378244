"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PurgeMessagesTabComponent = void 0;
const actions = require("../../actions/purge-messages.action");
class PurgeMessagesTabComponent {
    constructor(store) {
        this.store = store;
    }
    searchPurgeCount(payload) {
        this.store.dispatch(new actions.PurgeMessagesCountRequestAction(payload));
    }
}
exports.PurgeMessagesTabComponent = PurgeMessagesTabComponent;
