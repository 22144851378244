"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgDevicesListImplService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const table_store_1 = require("@mimecast-ui/table-store");
const device_groups_models_1 = require("../../models/device-groups.models");
class SwgDevicesListImplService {
    constructor(devicesListApiService) {
        this.devicesListApiService = devicesListApiService;
        this.devicesInGroup = [];
    }
    getData(payload, pagination = table_store_1.defaultPaginationOption) {
        const config = Object.assign(Object.assign({}, payload), { meta: { pagination } });
        return this.devicesListApiService.getDevicesList(config).pipe(operators_1.map((response) => {
            const endpoints = this.mapEndpoints(response.first.endpointSummaries);
            return {
                hasErrors: response.hasErrors,
                data: endpoints,
                failures: response.fail,
                meta: Object.assign(Object.assign({}, response.meta), { pagination: Object.assign(Object.assign({}, response.meta.pagination), { totalRows: response.meta.pagination.totalCount }) })
            };
        }), operators_1.catchError((response) => {
            const RETURN_DATA = {
                hasErrors: response.hasErrors,
                data: response.first.logs,
                failures: response.fail,
                meta: Object.assign(Object.assign({}, response.meta), { pagination: Object.assign(Object.assign({}, response.meta.pagination), { totalRows: response.meta.pagination.totalCount }) })
            };
            return rxjs_1.of(RETURN_DATA);
        }));
    }
    filter(query, pagination) {
        return this.getData(query, pagination);
    }
    openItem() {
        return rxjs_1.of([]);
    }
    mapEndpoints(endpoints) {
        let devices = [];
        if (endpoints.length) {
            devices = endpoints.map(endpoint => {
                return Object.assign(Object.assign({}, endpoint), { action: endpoint.inGroup ? device_groups_models_1.DeviceAction.INGROUP : undefined });
            });
        }
        return devices;
    }
}
exports.SwgDevicesListImplService = SwgDevicesListImplService;
