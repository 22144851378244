"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppsEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const actions = require("../../actions/apps/apps.actions");
const appDetailsActions = require("../../actions/apps/app-details.actions");
const tableStore = require("@mimecast-ui/table-store");
const notification_actions_1 = require("../../../components/notification/actions/notification.actions");
const delete_app_modal_component_1 = require("../../components/delete-app-modal/delete-app-modal.component");
class AppsEffects {
    constructor(actions$, appsApiService, modalService) {
        this.actions$ = actions$;
        this.appsApiService = appsApiService;
        this.modalService = modalService;
        this.openDetailsAsidePanel$ = this.actions$.pipe(effects_1.ofType(actions.VIEW_APP_DETAILS), operators_1.map((action) => {
            const viewPayload = {
                selectedApp: action.payload.selectedApp,
                mode: action.payload.mode
            };
            return new appDetailsActions.IdentityOpenAppAsideAction(viewPayload);
        }));
        this.create$ = this.actions$.pipe(effects_1.ofType(actions.ADD_APP), operators_1.switchMap(() => {
            return this.appsApiService.createApp().pipe(operators_1.map((createdApp) => {
                return new actions.IdentityAddAppSuccessAction(createdApp);
            }), operators_1.catchError(() => {
                return rxjs_1.of(new actions.IdentityAddAppFailureAction('$I18N_IDENTITY_APP_ADD_ERROR'));
            }));
        }));
        this.createSuccess$ = this.actions$.pipe(effects_1.ofType(actions.ADD_APP_SUCCESS), operators_1.map(() => {
            return new tableStore.LoadAction({ tableId: 'IdentityAppsList' });
        }));
        this.createFailure$ = this.actions$.pipe(effects_1.ofType(actions.ADD_APP_FAILURE), operators_1.map((action) => {
            return new notification_actions_1.NotificationShowFailAction(action.failMessage);
        }));
        this.deleteApps = this.actions$.pipe(effects_1.ofType(actions.DELETE_APPS), operators_1.switchMap((action) => {
            return this.appsApiService.deleteApp([action.payload]).pipe(operators_1.mergeMap(() => [
                new actions.IdentityDeleteAppSuccessAction(),
                new actions.IdentityDeleteModalCloseAction()
            ]), operators_1.catchError(() => {
                return rxjs_1.of(new actions.IdentityDeleteAppFailureAction('$I18N_IDENTITY_APP_DLE_ERROR'));
            }));
        }));
        this.deleteSuccess$ = this.actions$.pipe(effects_1.ofType(actions.DELETE_APPS_SUCCESS), operators_1.map(() => {
            return new tableStore.LoadAction({ tableId: 'IdentityAppsList' });
        }));
        this.openAppDeletionModal$ = this.actions$.pipe(effects_1.ofType(actions.OPEN_DELETE_APP_MODAL), operators_1.map((action) => action.payload), operators_1.switchMap(() => {
            const modalPanel = this.modalService.open(delete_app_modal_component_1.IdentityDeleteAppModalComponent, {
                size: 'md',
                hasBackdrop: true
            });
            this.deleteModalInstance = modalPanel;
            return rxjs_1.EMPTY;
        }));
        this.closeAppDeletionModal$ = this.actions$.pipe(effects_1.ofType(actions.CLOSE_DELETE_APP_MODAL), operators_1.filter(() => !!this.deleteModalInstance), operators_1.switchMap(() => {
            this.deleteModalInstance.close();
            this.deleteModalInstance = undefined;
            return rxjs_1.EMPTY;
        }));
    }
}
__decorate([
    effects_1.Effect()
], AppsEffects.prototype, "openDetailsAsidePanel$", void 0);
__decorate([
    effects_1.Effect()
], AppsEffects.prototype, "create$", void 0);
__decorate([
    effects_1.Effect()
], AppsEffects.prototype, "createSuccess$", void 0);
__decorate([
    effects_1.Effect()
], AppsEffects.prototype, "createFailure$", void 0);
__decorate([
    effects_1.Effect()
], AppsEffects.prototype, "deleteApps", void 0);
__decorate([
    effects_1.Effect()
], AppsEffects.prototype, "deleteSuccess$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], AppsEffects.prototype, "openAppDeletionModal$", void 0);
__decorate([
    effects_1.Effect()
], AppsEffects.prototype, "closeAppDeletionModal$", void 0);
exports.AppsEffects = AppsEffects;
