"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LexiconBase = void 0;
class LexiconBase {
    constructor(id, name, description, createdBy, updatedBy, created, updated, status) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.createdBy = createdBy;
        this.updatedBy = updatedBy;
        this.created = created;
        this.updated = updated;
        this.status = status;
    }
}
exports.LexiconBase = LexiconBase;
