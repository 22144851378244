"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiIntegrationCrowdstrikeOutboundStepComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
class ApiIntegrationCrowdstrikeOutboundStepComponent {
    constructor(fb, crowdstrikeService, translateService, capabilitiesService, coreService) {
        this.fb = fb;
        this.crowdstrikeService = crowdstrikeService;
        this.translateService = translateService;
        this.capabilitiesService = capabilitiesService;
        this.coreService = coreService;
        this.valuesChanged = false;
        this.hasAttachmentProtect = false;
        this.attachmentProtectPackageId = '[1059]';
        this.onSwitchMaliciousHashesEnabled = new core_1.EventEmitter();
        this.onIocOutboundActionSelected = new core_1.EventEmitter();
        this.onIocOutboundUrlActionSelected = new core_1.EventEmitter();
        this.onIocOutboundSeveritySelected = new core_1.EventEmitter();
        this.onIocOutboundExpirationSelected = new core_1.EventEmitter();
        this.onSwitchMaliciousUrlsEnabled = new core_1.EventEmitter();
        this.form = this.fb.group({
            maliciousHashesEnabledField: new forms_1.FormControl({ value: false, disabled: false }),
            actionField: new forms_1.FormControl({ value: 'PREVENT', disabled: false }),
            maliciousUrlEnabledField: new forms_1.FormControl({ value: false, disabled: false }),
            iocOutboundUrlActionField: new forms_1.FormControl({ value: 'DETECT', disabled: false }),
            iocOutboundSeverityField: new forms_1.FormControl({ value: 'MEDIUM', disabled: false }),
            iocOutboundExpirationField: new forms_1.FormControl({ value: 'DAYS_90', disabled: false })
        });
    }
    get actionOptions() {
        return [
            {
                value: 'NO_ACTION',
                label: this.translateService.instant('$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.OUTBOUND.ACTION_OPTIONS.NO_ACTION')
            },
            {
                value: 'PREVENT_NO_UI',
                label: this.translateService.instant('$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.OUTBOUND.ACTION_OPTIONS.PREVENT_NO_UI')
            },
            {
                value: 'PREVENT',
                label: this.translateService.instant('$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.OUTBOUND.ACTION_OPTIONS.PREVENT')
            },
            {
                value: 'DETECT',
                label: this.translateService.instant('$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.OUTBOUND.ACTION_OPTIONS.DETECT')
            }
        ];
    }
    get urlActionOptions() {
        return [
            {
                value: 'NO_ACTION',
                label: this.translateService.instant('$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.OUTBOUND.URL_ACTION_OPTIONS.NO_ACTION')
            },
            {
                value: 'DETECT',
                label: this.translateService.instant('$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.OUTBOUND.URL_ACTION_OPTIONS.DETECT')
            }
        ];
    }
    get severityOptions() {
        return [
            {
                value: 'INFORMATIONAL',
                label: this.translateService.instant('$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.OUTBOUND.SEVERITY_OPTIONS.INFORMATIONAL')
            },
            {
                value: 'LOW',
                label: this.translateService.instant('$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.OUTBOUND.SEVERITY_OPTIONS.LOW')
            },
            {
                value: 'MEDIUM',
                label: this.translateService.instant('$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.OUTBOUND.SEVERITY_OPTIONS.MEDIUM')
            },
            {
                value: 'HIGH',
                label: this.translateService.instant('$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.OUTBOUND.SEVERITY_OPTIONS.HIGH')
            },
            {
                value: 'CRITICAL',
                label: this.translateService.instant('$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.OUTBOUND.SEVERITY_OPTIONS.CRITICAL')
            }
        ];
    }
    get expirationOptions() {
        return [
            {
                value: 'DAYS_7',
                label: this.translateService.instant('$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.OUTBOUND.EXPIRATION_OPTIONS.7_DAYS')
            },
            {
                value: 'DAYS_15',
                label: this.translateService.instant('$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.OUTBOUND.EXPIRATION_OPTIONS.15_DAYS')
            },
            {
                value: 'DAYS_30',
                label: this.translateService.instant('$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.OUTBOUND.EXPIRATION_OPTIONS.30_DAYS')
            },
            {
                value: 'DAYS_90',
                label: this.translateService.instant('$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.OUTBOUND.EXPIRATION_OPTIONS.90_DAYS')
            },
            {
                value: 'NO_EXPIRATION',
                label: this.translateService.instant('$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.OUTBOUND.EXPIRATION_OPTIONS.NO_EXPIRATION')
            }
        ];
    }
    ngOnInit() {
        this.hasAttachmentProtect = this.crowdstrikeService.hasRequiredPackage(this.attachmentProtectPackageId);
        const maliciousHashesEnabledField = this.form.get('maliciousHashesEnabledField');
        if (this.hasAttachmentProtect) {
            maliciousHashesEnabledField.enable();
        }
        else {
            maliciousHashesEnabledField.disable();
        }
        if (!!this.crowdstrikeIntegration) {
            this.form.patchValue({
                maliciousHashesEnabledField: this.crowdstrikeIntegration.submitMaliciousHashes
            });
            if (this.crowdstrikeIntegration.iocOutboundAction &&
                this.crowdstrikeIntegration.iocOutboundAction !== '') {
                this.form.patchValue({
                    actionField: this.crowdstrikeIntegration.iocOutboundAction
                });
            }
            else {
                this.setAction(maliciousHashesEnabledField.value);
            }
            this.form.patchValue({
                maliciousUrlEnabledField: this.crowdstrikeIntegration.submitMaliciousUrls
            });
            if (this.crowdstrikeIntegration.iocOutboundUrlAction) {
                this.form.patchValue({
                    iocOutboundUrlActionField: this.crowdstrikeIntegration.iocOutboundUrlAction
                });
            }
            if (this.crowdstrikeIntegration.iocOutboundSeverity) {
                this.form.patchValue({
                    iocOutboundSeverityField: this.crowdstrikeIntegration.iocOutboundSeverity
                });
            }
            if (this.crowdstrikeIntegration.iocOutboundExpiration) {
                this.form.patchValue({
                    iocOutboundExpirationField: this.crowdstrikeIntegration.iocOutboundExpiration
                });
            }
        }
        this.actionSubscription = this.form.get('actionField').valueChanges.subscribe(newAction => {
            this.onIocOutboundActionSelected.emit(newAction);
        });
        this.maliciousHashesSubscription = this.form
            .get('maliciousHashesEnabledField')
            .valueChanges.subscribe(enabled => {
            this.updateMaliciousHashes(enabled);
        });
        this.maliciousUrlsSubscription = this.form
            .get('maliciousUrlEnabledField')
            .valueChanges.subscribe(enabled => {
            this.updateMaliciosUrls(enabled);
        });
        this.iocOutboundUrlActionSubscription = this.form
            .get('iocOutboundUrlActionField')
            .valueChanges.subscribe(newAction => {
            this.updateIocOutboundUrlAction(newAction);
        });
        this.iocOutboundSeveritySubscription = this.form
            .get('iocOutboundSeverityField')
            .valueChanges.subscribe(newSeverity => {
            this.updateIocOutboundSeverity(newSeverity);
        });
        this.iocOutboundExpirationSubscription = this.form
            .get('iocOutboundExpirationField')
            .valueChanges.subscribe(newExpiration => {
            this.updateIocOutboundExpiration(newExpiration);
        });
    }
    ngOnDestroy() {
        this.actionSubscription.unsubscribe();
        this.maliciousHashesSubscription.unsubscribe();
        this.maliciousUrlsSubscription.unsubscribe();
        this.iocOutboundExpirationSubscription.unsubscribe();
        this.iocOutboundSeveritySubscription.unsubscribe();
        this.iocOutboundUrlActionSubscription.unsubscribe();
    }
    updateMaliciosUrls(maliciousUrlsEnabeld) {
        this.onSwitchMaliciousUrlsEnabled.emit(maliciousUrlsEnabeld);
    }
    updateIocOutboundUrlAction(iocOutboundUrlAction) {
        this.onIocOutboundUrlActionSelected.emit(iocOutboundUrlAction);
    }
    updateIocOutboundSeverity(iocOutboundSevirity) {
        this.onIocOutboundSeveritySelected.emit(iocOutboundSevirity);
    }
    updateIocOutboundExpiration(iocOutboundExpiration) {
        this.onIocOutboundExpirationSelected.emit(iocOutboundExpiration);
    }
    updateMaliciousHashes(maliciousHashesEnabled) {
        this.onSwitchMaliciousHashesEnabled.emit(maliciousHashesEnabled);
        if (maliciousHashesEnabled) {
            this.onIocOutboundActionSelected.emit(this.form.value.actionField);
        }
        else {
            this.onIocOutboundActionSelected.emit('');
        }
    }
    isMaliciousHashesEnabled() {
        return this.form.value.maliciousHashesEnabledField;
    }
    isMaliciousUrlEnabled() {
        return this.form.value.maliciousUrlEnabledField;
    }
    setAction(maliciousHashesEnabled) {
        if (maliciousHashesEnabled) {
            this.onIocOutboundActionSelected.emit(this.form.value.actionField);
        }
        else {
            this.onIocOutboundActionSelected.emit('');
        }
    }
    isValid() {
        return true;
    }
}
exports.ApiIntegrationCrowdstrikeOutboundStepComponent = ApiIntegrationCrowdstrikeOutboundStepComponent;
