"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MalwareListExportEffects = void 0;
const effects_1 = require("@ngrx/effects");
const operators_1 = require("rxjs/operators");
const export_actions_1 = require("../actions/export.actions");
const table_store_1 = require("@mimecast-ui/table-store");
const api_1 = require("../../../components/export/api");
class MalwareListExportEffects {
    constructor(actions$, store$) {
        this.actions$ = actions$;
        this.store$ = store$;
        this.export = this.actions$.pipe(effects_1.ofType(export_actions_1.SwgDownloadMalwareReportListActions.DOWNLOAD_MALWARE_REPORT_LIST), operators_1.withLatestFrom(this.store$.select(table_store_1.tableReducer.getCurrentFilters('SwgMalwareReport'))), operators_1.map(([action, filters]) => {
            return new api_1.OpenSidePanelExportApiAction('/api/swg/export-security-logs', action.tableColumns, filters, '$I18N_SECURITY_REPORT');
        }));
    }
}
__decorate([
    effects_1.Effect()
], MalwareListExportEffects.prototype, "export", void 0);
exports.MalwareListExportEffects = MalwareListExportEffects;
