"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiSessionService = void 0;
const rxjs_1 = require("rxjs");
const i0 = require("@angular/core");
const i1 = require("../session-handler/session-handler.service");
class ApiSessionService {
    constructor(sessionService) {
        this.sessionService = sessionService;
    }
    get sessionToken() {
        return rxjs_1.of(this.sessionService.sessionId || this.sessionService.tempSessionId);
    }
    get applicationId() {
        return rxjs_1.of(undefined);
    }
    get apiRoot() {
        return rxjs_1.of('/auth');
    }
    get contextRoute() {
        return rxjs_1.of('administration');
    }
}
exports.ApiSessionService = ApiSessionService;
ApiSessionService.ɵprov = i0.ɵɵdefineInjectable({ factory: function ApiSessionService_Factory() { return new ApiSessionService(i0.ɵɵinject(i1.SessionHandlerService)); }, token: ApiSessionService, providedIn: "root" });
