"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApStripAndLinkService = void 0;
const models_1 = require("../models");
class ApStripAndLinkService {
    constructor(capabilitiesService) {
        this.capabilitiesService = capabilitiesService;
    }
    isEncAttachmentsSwitchEnabled() {
        return this.capabilitiesService.hasCapability('Temporary.Services.AttachmentProtection.Definitions.StripAndLinkEncAttachments');
    }
    isValidConfigType(configType) {
        return configType === models_1.ApConfigurationType.DYNAMIC || configType === models_1.ApConfigurationType.SANDBOX;
    }
}
exports.ApStripAndLinkService = ApStripAndLinkService;
