"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getConnectors = exports.isLoadingConnectors = exports.getImTaskDetails = exports.getImTaskLoading = exports.imSyncReducer = void 0;
const im_sync_actions_1 = require("../actions/im-sync.actions");
const initialState = {
    isImTaskDetailsLoading: false,
    imTaskDetails: null,
    isLoadingConnectors: false,
    connectors: []
};
function imSyncReducer(state = initialState, action) {
    switch (action.type) {
        case im_sync_actions_1.ImSyncActions.LOAD_IM_SYNC_TASK_DETAILS:
            return Object.assign(Object.assign({}, state), { isImTaskDetailsLoading: true, imTaskDetails: null });
        case im_sync_actions_1.ImSyncActions.LOAD_IM_SYNC_TASK_DETAILS_SUCCESS:
            return Object.assign(Object.assign({}, state), { isImTaskDetailsLoading: false, imTaskDetails: action.payload });
        case im_sync_actions_1.ImSyncActions.LOAD_IM_SYNC_TASK_DETAILS_FAILURE:
            return Object.assign(Object.assign({}, state), { isImTaskDetailsLoading: false, imTaskDetails: null });
        case im_sync_actions_1.ImSyncActions.LOAD_CONNECTORS:
            return Object.assign(Object.assign({}, state), { isLoadingConnectors: true });
        case im_sync_actions_1.ImSyncActions.LOAD_CONNECTORS_SUCCESS:
            return Object.assign(Object.assign({}, state), { isLoadingConnectors: false, connectors: action.payload });
        case im_sync_actions_1.ImSyncActions.LOAD_CONNECTORS_FAILURE:
            return Object.assign(Object.assign({}, state), { isLoadingConnectors: false, connectors: [] });
        default:
            return state;
    }
}
exports.imSyncReducer = imSyncReducer;
const getImTaskLoading = (state) => state && state.isImTaskDetailsLoading;
exports.getImTaskLoading = getImTaskLoading;
const getImTaskDetails = (state) => state && state.imTaskDetails;
exports.getImTaskDetails = getImTaskDetails;
const isLoadingConnectors = (state) => state && state.isLoadingConnectors;
exports.isLoadingConnectors = isLoadingConnectors;
const getConnectors = (state) => state && state.connectors;
exports.getConnectors = getConnectors;
