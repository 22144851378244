"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.oktaOnlyColumn = exports.columnsStartHidden = exports.columnsAlwaysVisible = exports.columns = void 0;
exports.columns = [
    'enabled',
    'integrationType',
    'identifier',
    'description',
    'created',
    'updated',
    'action'
];
exports.columnsAlwaysVisible = ['integrationType'];
exports.columnsStartHidden = [];
exports.oktaOnlyColumn = 'activity';
