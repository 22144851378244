"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const _ = require("lodash");
const moment = require("moment-timezone");
/**
 * Created by asyed
 * Todo: Read days and timezone froms external comps
 * Improve performace
 */
(function () {
    'use strict';
    document.createElement('mc-time-exclusions');
    angular.module('predefined-field.time-exclusions.duration.directive', [])
        .directive('mcTimeExclusions', ['$translate', function ($translate) {
            return {
                restrict: 'E',
                templateUrl: 'services/services/backup/components/time-exclusions/time-exclusions.tpl.html',
                require: 'ngModel',
                transclude: true,
                scope: {
                    ngModel: '=',
                    displayOnly: '=',
                    mcRequired: '='
                },
                link($scope, $ele, $attrs, $ngModel) {
                    const guessedTimeZone = moment.tz.guess();
                    const allTimeZones = moment.tz.names();
                    const index = allTimeZones.indexOf(guessedTimeZone);
                    if (index > 0) {
                        allTimeZones.splice(index, 1);
                        allTimeZones.unshift(guessedTimeZone);
                    }
                    $scope.timezones = allTimeZones.map(function (timezone) {
                        return [timezone, ' (UTC ' + moment.tz(timezone).format('Z') + ')'];
                    });
                    $scope.selectedDay = undefined;
                    $scope.timezone = undefined;
                    $scope.exclusions = [];
                    $scope.timePeriodValueValid = false;
                    $scope.timePeriodValue = {};
                    $scope.addDuration = function () {
                        $scope.exclusions.push($scope.timePeriodValue);
                        $scope.initTimePickers();
                        $ngModel.$setViewValue($scope.exclusions);
                    };
                    $scope.remove = function (exclusion) {
                        //ADCON-663 - fix to remove the right timeZone
                        _.remove($scope.exclusions, exclusion);
                    };
                    $ngModel.$render = function () {
                        $scope.initTimePickers();
                        $scope.exclusions = $scope.ngModel || [];
                        if ($scope.mcRequired && $scope.exclusions.length === 0) {
                            $ngModel.$setValidity('required', false);
                        }
                        else {
                            $ngModel.$setValidity('required', true);
                        }
                    };
                    $scope.$watch('ngModel', function () {
                        $ngModel.$render();
                    });
                    $scope.initTimePickers = function () {
                        const d = new Date();
                        d.setHours(0, 0, 0, 0);
                        $scope.fromTime = d;
                        $scope.toTime = d;
                        $scope.timePeriodValueValid = true;
                        $scope.timePeriodValue = {};
                        // $ngModel.$setValidity('invalid-data', false);
                    };
                    $scope.dayOptions = {
                        'all_days': $translate.instant('$I18N_TIMEDURATION_ALL_DAYS'),
                        'monday': $translate.instant('$I18N_TIMEDURATION_MONDAY'),
                        'tuesday': $translate.instant('$I18N_TIMEDURATION_TUESDAY'),
                        'wednesday': $translate.instant('$I18N_TIMEDURATION_WEDNESDAY'),
                        'thursday': $translate.instant('$I18N_TIMEDURATION_THURSDAY'),
                        'friday': $translate.instant('$I18N_TIMEDURATION_FRIDAY'),
                        'saturday': $translate.instant('$I18N_TIMEDURATION_SATURDAY'),
                        'sunday': $translate.instant('$I18N_TIMEDURATION_SUNDAY')
                    };
                    $scope.changed = function () {
                        const fromTime = $scope.fromTime;
                        const toTime = $scope.toTime;
                        if (fromTime < toTime && typeof $scope.selectedDay !== 'undefined' && typeof $scope.timezone !== 'undefined') {
                            $ngModel.$setValidity('invalid-data', true);
                            $scope.timePeriodValueValid = false;
                        }
                        else {
                            $ngModel.$setValidity('invalid-data', false);
                            $scope.timePeriodValueValid = true;
                        }
                        const from = moment(fromTime).format('HH:mm');
                        const to = moment(toTime).format('HH:mm');
                        $scope.timePeriodValue = {
                            day: $scope.selectedDay,
                            from,
                            to,
                            timeZone: $scope.timezone
                        };
                    };
                    $scope.formatTimeZone = function (timezone) {
                        return timezone + ' (UTC ' + moment.tz(timezone).format('Z') + ')';
                    };
                }
            };
        }]);
}());
