"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RejectNarrativeReasonPipe = void 0;
class RejectNarrativeReasonPipe {
    constructor(translateService) {
        this.translateService = translateService;
    }
    transform(input = '') {
        switch (input.toLowerCase()) {
            case 'inappropriate_communication':
            case 'confidential_information':
            case 'against_email_policies':
            case 'disapproves_of_content':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_REJECT_NARRATIVE_MESSAGE_PIPE.' + input.toUpperCase());
            default:
        }
    }
}
exports.RejectNarrativeReasonPipe = RejectNarrativeReasonPipe;
