<mc-layout-list-extra-container
  keyTitle="$I18N_SFC_THREAT_EVENTS.TITLE"
  keyDescription="$I18N_SFC_THREAT_EVENTS.DESCRIPTION">
  <mc-help-container>
    <mc-help-link url="https://community.mimecast.com/docs/DOC-3640"></mc-help-link>
  </mc-help-container>
  <mc-body-container>
    <mc-tab-group #tabs (tabChanged)="changeTab($event)">

      <mc-tab name="{{ '$I18N_SAFE_CLOUD.OVERVIEW' | translate }}">
        <div class="row">
          <div class="col-xs-4">
            <mc-sfc-widget-layout title="{{ '$I18N_SFC_THREAT_EVENTS.OVERVIEW_TAB.THREAT_DETECTIONS' | translate }}">
              <mc-sfc-threat-detections [data]="verdictsByApp" [loading]="false"></mc-sfc-threat-detections>
            </mc-sfc-widget-layout>
          </div>
          <div class="col-xs-4">
            <mc-sfc-widget-layout title="{{ '$I18N_SFC_THREAT_EVENTS.OVERVIEW_TAB.DETECTION_TIMELINE' | translate }}" [shouldExport]="true">
              <mc-sfc-detection-timeline [data]="timeline" [loading]="false"></mc-sfc-detection-timeline>
            </mc-sfc-widget-layout>
          </div>
          <div class="col-xs-4">
            <mc-sfc-widget-layout title="{{ '$I18N_SFC_THREAT_EVENTS.OVERVIEW_TAB.SCANNED_FILE_TYPES' | translate }}" [shouldExport]="true">
              <mc-sfc-scanned-file-types [data]="filetype" [loading]="false"></mc-sfc-scanned-file-types>
            </mc-sfc-widget-layout>
          </div>
        </div>
      </mc-tab>

      <mc-tab name="{{ '$I18N_SAFE_CLOUD.THREAT_EVENTS' | translate }}">
        Threat Events Container
      </mc-tab>

    </mc-tab-group>
  </mc-body-container>
</mc-layout-list-extra-container>
