"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImSyncTaskConnectionComponent = void 0;
const forms_1 = require("@angular/forms");
const im_task_service_1 = require("../../../services/im-task.service");
const step_base_component_1 = require("../../../step.base.component");
const im_sync_actions_1 = require("app-new/services/im-sync/actions/im-sync.actions");
class ImSyncTaskConnectionComponent extends step_base_component_1.StepBaseDirective {
    constructor(store, fb, translateService) {
        super(store, fb, 2, im_task_service_1.ImTaskService.WizardId);
        this.store = store;
        this.fb = fb;
        this.translateService = translateService;
        this.translatePrefix = '$I18N_ARCHIVE_IM_SYNC_WIZARD.IM_SYNC_TASK.CONNECTION_STEP';
        this.connectorsList = [];
        this.connectorListPlaceholder = {
            label: this.translateService.instant(`${this.translatePrefix}.CONNECTOR_PLACEHOLDER`),
            value: ''
        };
        this.form = this.fb.group({
            connector: ['', forms_1.Validators.required]
        });
    }
    set connectors(connectorList) {
        if (connectorList) {
            this.connectorsList = [
                this.connectorListPlaceholder,
                ...connectorList.map(connector => ({ label: connector.name, value: connector.id }))
            ];
        }
        if (connectorList.length === 1) {
            this.defaultSelectedConnector = connectorList[0].id;
            this.form.get('connector').setValue(this.defaultSelectedConnector);
        }
        this.hasConnectors = connectorList.length > 0;
    }
    getStepState() {
        const formValid = this.form.valid;
        if (formValid) {
            const connectorObjForId = this.connectorsList.find(v => v.value === this.form.get('connector').value);
            return {
                valid: formValid,
                data: Object.assign(Object.assign({}, this.form.value), { connector: connectorObjForId })
            };
        }
        return super.getStepState();
    }
    createConnector() {
        this.isCreatingConnector = true;
        this.store.dispatch(new im_sync_actions_1.OpenCreateConnectorWizardAction());
    }
    reloadConnectors() {
        if (this.isCreatingConnector) {
            this.store.dispatch(new im_sync_actions_1.LoadConnectorsAction());
            this.isCreatingConnector = false;
        }
    }
}
exports.ImSyncTaskConnectionComponent = ImSyncTaskConnectionComponent;
