
    <mc-create-snapshot
      [isRequesting]="isRequesting$ | async"
      [snapShotDetails]="snapshotDetails$ | async"
      [snapshotCreationfail]="snapshotCreationfail$ | async"
      (mcCreateSnapshot)="createSnapshot($event)"
      (mcGoToSnapshotQueue)="goToSnapshotQueue()"
      (mcGoToSnapshot)="goToSnapshot()"
    >
    </mc-create-snapshot>
  