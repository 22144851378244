"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgApplicationControlService = void 0;
const rxjs_1 = require("rxjs");
class SwgApplicationControlService {
    constructor() {
        this.applicationListParamsSubject = new rxjs_1.Subject();
        this.applicationListParams$ = this.applicationListParamsSubject.asObservable();
    }
    applicationListFilterByParams(params) {
        this.applicationListParamsSubject.next(params);
    }
}
exports.SwgApplicationControlService = SwgApplicationControlService;
