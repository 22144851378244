<div *ngIf="reportSubmissionSuccess$ | async"><mc-inline-notification
  [notification]="{
        status: 'info',
        boxed: true,
        msg: '$I18N_SERVICES_URL_PROTECTION_LOGS_URL_CHECK.URL_SUBMIT_INLINE_NOTIFICATION' | translate
      }"
>    </mc-inline-notification>
</div>
<div *ngIf="notification">
  <mc-inline-notification [notification]="notification"></mc-inline-notification>
</div>
<span class="mc-check-url-title">{{ '$I18N_SERVICES_URL_PROTECTION_LOGS_URL_CHECK.LABEL_TITLE' | translate }}</span>
<div *ngIf="rowData" class="mc-detailed-list">
  <div class="row mc-detailed-list-row">
    <div class="col-xs-3 mc-detailed-list-label">
      {{'$I18N_SERVICES_URL_PROTECTION_LOGS_URL_CHECK.LABEL_URL' | translate}}</div>
    <div class="mc-detailed-list-column">
      <mc-browser-isolation-detector [logLink]="rowData" (urlClick)="urlClick($event)"></mc-browser-isolation-detector>
    </div>
  </div>
  <div class="row mc-detailed-list-row" *mcCapabilities="'!Temporary.Services.URL.Reporting.Enabled'" >
    <div class="col-xs-3 mc-detailed-list-label">
      {{'$I18N_SERVICES_URL_PROTECTION_LOGS_URL_CHECK.LABEL_RESULTS' | translate}}</div>
    <div class="mc-detailed-list-column">
      <mc-check-url-scan-results
        [relaxed]="relaxed$ | async"
        [moderate]="moderate$ | async"
        [aggressive]="aggressive$ | async"
        [isLoading]="isLoading$ | async"
        [isDetails]="true"></mc-check-url-scan-results>
    </div>
  </div>
  <div *mcCapabilities="'Temporary.Services.URL.Reporting.Enabled'" >
      <div *ngIf="showScanDetails === true ">
        <div class="mc-detailed-list">
          <h4>{{ '$I18N_SERVICES_URL_PROTECTION_LOGS_URL_CHECK.SCAN_DETAILS_RESULTS.LABEL_RESULTS' | translate }}</h4>

          <div class="row mc-detailed-list-row">
            <div class="col-sm-4 mc-detailed-list-label">
              {{ '$I18N_SERVICES_URL_PROTECTION.URL_TOOLS.CHECK_URLS.RELAXED_CATEGORY_LABEL' | translate}}
            </div>
            <div class="mc-detailed-list-column">
               <span data-test="cy-scan-result-relaxed">
                  <mc-spinner *ngIf="isLoading$ | async; else relaxedTemp"></mc-spinner>
                  <ng-template #relaxedTemp>
                    <span class="fas {{setScanResultIcon(relaxed$ | async)}} panel-half-padding-right"></span>{{relaxed$ | async}}
                  </ng-template>
                </span>
            </div>
          </div>
          <div class="row mc-detailed-list-row">
            <div class="col-sm-4 mc-detailed-list-label">
              {{ '$I18N_SERVICES_URL_PROTECTION.URL_TOOLS.CHECK_URLS.MODERATE_CATEGORY_LABEL' | translate}}
            </div>
            <div class="mc-detailed-list-column">
              <mc-spinner *ngIf="isLoading$ | async; else moderateTemp"></mc-spinner>
              <ng-template #moderateTemp>
                <span class="fas {{setScanResultIcon(moderate$ | async)}} panel-half-padding-right"></span>{{(moderate$ | async)}}
              </ng-template>
            </div>
          </div>
          <div class="row mc-detailed-list-row">
            <div class="col-sm-4 mc-detailed-list-label">
              {{ '$I18N_SERVICES_URL_PROTECTION.URL_TOOLS.CHECK_URLS.AGGRESSIVE_CATEGORY_LABEL' | translate}}
            </div>
            <div class="mc-detailed-list-column">
              <mc-spinner *ngIf="isLoading$ | async; else aggressiveTemp"></mc-spinner>
              <ng-template #aggressiveTemp>
                <span class="fas {{setScanResultIcon(aggressive$ | async)}} panel-half-padding-right"></span>{{aggressive$ | async}}
              </ng-template>
            </div>
          </div>
         </div>
        <div class="mc-detailed-list">
            <h4>{{ '$I18N_SERVICES_URL_PROTECTION_LOGS_URL_CHECK.SCAN_DETAILS_RESULTS.RELAXED_SCAN_DETAILS.LABEL' | translate }}</h4>
            <div class="row mc-detailed-list-row">
              <div class="col-sm-4 mc-detailed-list-label">
                {{'$I18N_SERVICES_URL_PROTECTION_LOGS_URL_CHECK.SCAN_DETAILS_RESULTS.RELAXED_SCAN_DETAILS.SCREENSHOT' | translate}}
              </div>
              <div class="mc-detailed-list-column">
                <div *ngIf="(scanResponse$ | async)?.relaxed?.results[0]?.screenshot !== ''; else
                 noScreenshotFound" (click)="showScreenshotModal(scanResponse?.relaxed?.results[0]?.screenshot)">
                  <img [src]="urlBase64((scanResponse$ | async)?.relaxed?.results[0]?.screenshot)"
                       alt="Screenshot" width="370" height="270" class="mc-screenshot-border">
                </div>
                <ng-template #noScreenshotFound>
                  <div class="mc-text-not-found">
                    {{'$I18N_SERVICES_URL_PROTECTION_LOGS_URL_CHECK.SCAN_DETAILS_RESULTS.MODERATE_SCAN_DETAILS.SCREENSHOT_UNAVAILABLE' | translate}}
                  </div>
                </ng-template>
              </div>
            </div>
            <div class="row mc-detailed-list-row">
              <div class="col-sm-4 mc-detailed-list-label">
                {{'$I18N_SERVICES_URL_PROTECTION_LOGS_URL_CHECK.SCAN_DETAILS_RESULTS.RELAXED_SCAN_DETAILS.ANALYSIS' | translate}}
              </div>
              <span class="fas {{setScanResultIcon((scanDetails$ | async).relaxed.scanResult)}} mc-analysis-icon" ></span>
             <div class="mc-detailed-list-column mc-first-letter mc-analysis-padding">{{(scanDetails$ | async).relaxed.scanResult}}
                </div>
            </div>
          <div class="row mc-detailed-list-row">
            <div class="col-sm-4 mc-detailed-list-label">
              {{ '$I18N_SERVICES_URL_PROTECTION.URL_TOOLS.CHECK_URLS.CURRENT_CATEGORY' | translate }}
            </div>
            <div  class="mc-detailed-list-column">{{(scanDetails$ | async).relaxed.categories &&
            (scanDetails$ | async).relaxed.categories.length !==0?
              (scanDetails$ | async).relaxed.categories.join(',') :
              '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.NO_SCAN_DETAILS'  | translate}}
            </div>
          </div>
            <div class="row mc-detailed-list-row">
              <div class="col-sm-4 mc-detailed-list-label">
                {{'$I18N_SERVICES_URL_PROTECTION_LOGS_URL_CHECK.SCAN_DETAILS_RESULTS.RELAXED_SCAN_DETAILS.DETAILS' | translate}}
              </div>
              <mc-scan-details-tag-map *ngIf="(scanDetails$ | async)?.relaxed?.hasOwnProperty('tagMaps') else noTagMap"
                                       [scanResponses]="(scanDetails$ | async).relaxed.tagMaps[0]" ></mc-scan-details-tag-map>
            </div>
          </div>
        <div class="mc-detailed-list">
        <h4>{{ '$I18N_SERVICES_URL_PROTECTION_LOGS_URL_CHECK.SCAN_DETAILS_RESULTS.MODERATE_SCAN_DETAILS.LABEL' | translate }}</h4>
        <div class="row mc-detailed-list-row">
          <div class="col-sm-4 mc-detailed-list-label">
            {{'$I18N_SERVICES_URL_PROTECTION_LOGS_URL_CHECK.SCAN_DETAILS_RESULTS.MODERATE_SCAN_DETAILS.SCREENSHOT' | translate}}
          </div>
          <div class="mc-detailed-list-column">
            <div *ngIf="(scanResponse$ | async)?.moderate?.results[0]?.screenshot !== ''; else
             noScreenshotFound" (click)="showScreenshotModal(scanResponse?.moderate?.results[0]?.screenshot)">
              <img [src]="urlBase64((scanResponse$ | async)?.moderate?.results[0]?.screenshot)"
                   alt="Screenshot" width="370" height="270" class="mc-screenshot-border">
            </div>
            <ng-template #noScreenshotFound>
              <div class="mc-text-not-found"> {{'$I18N_SERVICES_URL_PROTECTION_LOGS_URL_CHECK.SCAN_DETAILS_RESULTS.MODERATE_SCAN_DETAILS.SCREENSHOT_UNAVAILABLE' | translate}}</div>
            </ng-template>
          </div>
        </div>
        <div class="row mc-detailed-list-row">
          <div class="col-sm-4 mc-detailed-list-label">
            {{'$I18N_SERVICES_URL_PROTECTION_LOGS_URL_CHECK.SCAN_DETAILS_RESULTS.MODERATE_SCAN_DETAILS.ANALYSIS' | translate}}
          </div>
          <span class="fas {{setScanResultIcon((scanDetails$ | async).moderate.scanResult)}} mc-analysis-icon"></span>
          <div class="mc-detailed-list-column mc-first-letter mc-analysis-padding">{{(scanDetails$ | async).moderate.scanResult}}
          </div>
        </div>
          <div class="row mc-detailed-list-row">
            <div class="col-sm-4 mc-detailed-list-label">
              {{ '$I18N_SERVICES_URL_PROTECTION.URL_TOOLS.CHECK_URLS.CURRENT_CATEGORY' | translate }}
            </div>
            <div  class="mc-detailed-list-column">
              {{(scanDetails$ | async).moderate.categories  &&
            (scanDetails$ | async).relaxed.categories.length !==0?
              (scanDetails$ | async).moderate.categories.join(',') :
              '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.NO_SCAN_DETAILS'  | translate}}
            </div>
          </div>
        <div class="row mc-detailed-list-row">
          <div class="col-sm-4 mc-detailed-list-label">
            {{'$I18N_SERVICES_URL_PROTECTION_LOGS_URL_CHECK.SCAN_DETAILS_RESULTS.MODERATE_SCAN_DETAILS.DETAILS' | translate}}
          </div>
          <mc-scan-details-tag-map *ngIf="(scanDetails$ | async).moderate.hasOwnProperty('tagMaps') else noTagMap"
                                   [scanResponses]="(scanDetails$ | async).moderate.tagMaps[0]" ></mc-scan-details-tag-map>
        </div>
          <ng-template #noTagMap>
           <div class="mc-detailed-list-column">{{'$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.NO_SCAN_DETAILS'  | translate}}</div>
          </ng-template>
      </div>
        <div class="mc-detailed-list">
        <h4>{{ '$I18N_SERVICES_URL_PROTECTION_LOGS_URL_CHECK.SCAN_DETAILS_RESULTS.AGGRESSIVE_SCAN_DETAILS.LABEL' | translate }}</h4>
        <div class="row mc-detailed-list-row">
          <div class="col-sm-4 mc-detailed-list-label">
            {{'$I18N_SERVICES_URL_PROTECTION_LOGS_URL_CHECK.SCAN_DETAILS_RESULTS.AGGRESSIVE_SCAN_DETAILS.SCREENSHOT' | translate}}
          </div>
          <div class="mc-detailed-list-column">
            <div *ngIf="(scanResponse$ | async)?.aggressive?.results[0]?.screenshot !== ''; else
            noScreenshotFound" (click)="showScreenshotModal(scanResponse?.aggressive?.results[0]?.screenshot)">
              <img [src]="urlBase64(scanResponse?.aggressive?.results[0]?.screenshot)"
                   alt="Screenshot" width="370" height="270" class="mc-screenshot-border">
            </div>
            <ng-template #noScreenshotFound>
              <div class="mc-text-not-found"> {{'$I18N_SERVICES_URL_PROTECTION_LOGS_URL_CHECK.SCAN_DETAILS_RESULTS.MODERATE_SCAN_DETAILS.SCREENSHOT_UNAVAILABLE' | translate}}</div>
            </ng-template>
          </div>
        </div>
        <div class="row mc-detailed-list-row">
          <div class="col-sm-4 mc-detailed-list-label">
            {{'$I18N_SERVICES_URL_PROTECTION_LOGS_URL_CHECK.SCAN_DETAILS_RESULTS.AGGRESSIVE_SCAN_DETAILS.ANALYSIS' | translate}}
          </div>
          <span class="fas {{setScanResultIcon((scanDetails$ | async).aggressive.scanResult)}} mc-analysis-icon"></span>
          <div class="mc-detailed-list-column mc-first-letter mc-analysis-padding">{{(scanDetails$ | async).aggressive.scanResult }}
          </div>
        </div>
          <div class="row mc-detailed-list-row">
            <div class="col-sm-4 mc-detailed-list-label">
              {{ '$I18N_SERVICES_URL_PROTECTION.URL_TOOLS.CHECK_URLS.CURRENT_CATEGORY' | translate }}
            </div>
            <div  class="mc-detailed-list-column">
              {{(scanDetails$ | async).aggressive.categories  &&
            (scanDetails$ | async).relaxed.categories.length !==0?
              (scanDetails$ | async).aggressive.categories.join(',') :
              '$I18N_SERVICES_URL_PROTECTION_SCAN_DETAILS.NO_SCAN_DETAILS'  | translate}}
            </div>
          </div>
        <div class="row mc-detailed-list-row">
          <div class="col-sm-4 mc-detailed-list-label">
            {{'$I18N_SERVICES_URL_PROTECTION_LOGS_URL_CHECK.SCAN_DETAILS_RESULTS.AGGRESSIVE_SCAN_DETAILS.DETAILS' | translate}}
          </div>
          <mc-scan-details-tag-map *ngIf="(scanDetails$ | async).aggressive.hasOwnProperty('tagMaps') else noTagMap"
                                   [scanResponses]="(scanDetails$ | async).aggressive.tagMaps[0]" ></mc-scan-details-tag-map>
        </div>
      </div>
      </div>
      <button  [disabled]="(isLoading$ | async) === true" *ngIf="showScanDetails === false"
               (click)="showRealTimeScanDetails()" type="button" class="btn btn-default mc-button-margin-left" >
        <span *ngIf="(isLoading$ | async) === true" ><em class="far fa-spinner fa-pulse panel-half-margin-right"></em></span>
        <span *ngIf="(isLoading$ | async) === false" translate="$I18N_SERVICES_URL_PROTECTION_LOGS_URL_CHECK.SCAN_BUTTON"></span>
      </button>
</div>
</div>
