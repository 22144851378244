"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const threat_intel_dashboard_component_1 = require("./containers/threat-intel-dashboard.component");
require("./pages/threat-details/threat-details");
angular
    .module('threat-intel', ['threat-intel.threat-details'])
    .controller('ThreatIntelController', [
    '$stateParams',
    '$scope',
    '$state',
    function ($stateParams, $scope, $state) {
        $scope.stateParams = $stateParams;
        $scope.tab.onRefresh = function () {
            if ($state.current.name === 'threat-intel') {
                $state.go($state.current.name, {}, { reload: true, inherit: false });
            }
            else {
                $state.reload();
            }
        };
    }
])
    .directive('mcThreatIntel', static_1.downgradeComponent({
    component: threat_intel_dashboard_component_1.ThreatIntelDashboardComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider.state('threat-intel', {
            url: '/threat-dashboard',
            params: {
                tabName: null
            },
            data: {
                isFixedLayout: false,
                checkProgress: false,
                tabReload: true,
                capabilities: 'Permission.THREAT_INTELLIGENCE_READ && Threat.Intel.Dashboard && EarlyAccess.Threat.Intel.Dashboard',
                tabTitle: '$I18N_THREAT_INTEL.BREADCRUMB.APP_NAME',
                tabId: 'threatIntelDashboard'
            },
            views: {
                main: {
                    template: '<mc-threat-intel></mc-threat-intel>',
                    controller: 'ThreatIntelController'
                }
            }
        });
    }
]);
