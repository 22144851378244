"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getIpAddressesDisplayText = exports.getIpAddresses = exports.getPermissionType = exports.reducer = exports.initialState = void 0;
const actions = require("../../actions/policy-wizard/ip-addresses-step.actions");
const policy_1 = require("../../models/policy");
exports.initialState = {
    permissionType: policy_1.LocationPermission.NONE,
    ipAddresses: [],
    ipAddressesDisplayText: ''
};
function reducer(state = exports.initialState, action) {
    let ipAddresses = [...state.ipAddresses];
    switch (action.type) {
        case actions.SAVE_PERMISSION_TYPE:
            return Object.assign(Object.assign({}, state), { permissionType: action.payload, ipAddresses: [], ipAddressesDisplayText: '' });
        case actions.ADD_IP_ADDRESS:
            if (!ipAddresses.find(ipAddress => ipAddress.ip === action.payload.ip)) {
                ipAddresses.push(action.payload);
            }
            return Object.assign(Object.assign({}, state), { ipAddresses: [...ipAddresses], ipAddressesDisplayText: ipAddresses.map(ipAddress => ipAddress.ip).join(', ') });
        case actions.REMOVE_IP_ADDRESS:
            ipAddresses = state.ipAddresses.filter(ip => ip !== action.payload);
            return Object.assign(Object.assign({}, state), { ipAddresses: [...ipAddresses], ipAddressesDisplayText: ipAddresses.map(ipAddress => ipAddress.ip).join(', ') });
        case actions.CLEAR_IP_ADDRESSES:
            return Object.assign(Object.assign({}, state), { ipAddresses: [], ipAddressesDisplayText: '' });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getPermissionType = (state) => state.permissionType;
exports.getPermissionType = getPermissionType;
const getIpAddresses = (state) => state.ipAddresses;
exports.getIpAddresses = getIpAddresses;
const getIpAddressesDisplayText = (state) => state.ipAddressesDisplayText;
exports.getIpAddressesDisplayText = getIpAddressesDisplayText;
