<mc-layout-aside-extra-container [keyTitle]="logsPageConfig.exportSetup.title || '$I18N_EXPORT_LEGACY.DEFAULT.TITLE'"
                                 showClose="true"
                                 [isLoading]="false"
                                 (closeAside)="close()">

  <mc-extra-container>
    <div class="panel-padding-bottom">{{(logsPageConfig.exportSetup.subtitle || '$I18N_EXPORT_LEGACY.DEFAULT.SUBTITLE') | translate}}</div>
    <div class="btn-container">
      <button class="btn btn-primary panel-half-margin-right" (click)="doAction()" [disabled]="!formGroup.valid">{{getActionLabel() | translate}}</button>
      <button class="btn btn-secondary" (click)="close()" translate="$I18N_EXPORT_LEGACY.BUTTON_LABEL.CANCEL"></button>
    </div>
  </mc-extra-container>

  <mc-body-container>
    <form [formGroup]="formGroup" class="mc-detailed-list" mcErrorPrefix="$I18N_EXPORT_LEGACY.ERROR">
      <div class="row mc-detailed-list-row form-group" *ngIf="logsPageConfig.exportColumns">
        <div class="col-sm-4 mc-detailed-list-label" translate="$I18N_EXPORT_LEGACY.LABEL.COLUMNS"></div>
        <div class="col-sm-8 mc-export-columns mc-detailed-list-column" formGroupName="columns">
          <mc-checkbox class="mc-checkbox"
                       *ngFor="let column of logsPageConfig.exportColumns"
                       [label]="getColumnLabel(column.name) | translate"
                       [formControlName]="column.exportValue">
          </mc-checkbox>
        </div>
      </div>

      <div class="row mc-detailed-list-row form-group">
        <div class="col-sm-4 mc-detailed-list-label" translate="$I18N_EXPORT_LEGACY.LABEL.FORMAT"></div>
        <div class="col-sm-8 mc-detailed-list-column">
          <mc-select [options]="fileFormats"
                     [defaultValue]="formGroup.get('format').value"
                     (selectChange)="fileFormatChange($event)">
          </mc-select>
        </div>
      </div>

      <div class="row mc-detailed-list-row form-group" *ngIf="logsPageConfig.exportSetup?.action !== 'download'">
        <div class="col-sm-4 mc-detailed-list-label" translate="$I18N_EXPORT_LEGACY.LABEL.EMAIL"></div>
        <div class="col-sm-8 mc-detailed-list-column">
          <mc-text-field placeholder="$I18N_EXPORT_LEGACY.PLACEHOLDER.EMAIL"
                         formControlName="email">
          </mc-text-field>
        </div>
      </div>
    </form>
  </mc-body-container>

</mc-layout-aside-extra-container>
