<mc-table
[data]="data$ | async"
[columns]="columns"
(rowClick)="openItem($event)"
[isLoading]="isLoading$ | async"
translatePrefix="$SECURE_MESSAGING_LOGS.TABLE"
[showHighlightedRow]="true"
[highlightedRow]="highlightedRow$ | async"
tableId="secure-messaging/logs">

<mc-filters
  [metadata]="filtersMetadata$ | async"
  [showPagination]="true"
  (paginatorChange)="onPaginatorChange($event)"
  [externalTableSearchCount]="externalTableSearchCount">
</mc-filters> 
<mc-column-select
  *mcCapabilities="'Permission.SERVICE_SECURE_MESSAGING_EDIT'"
  key="id"
  [isRowSelectable]="isRowSelectable"
  (selectChange)="onSelectChangeMultiple($event)"
  ></mc-column-select>
  
<mc-column key="expiry">
  <mc-body-cell *mcBodyCellDef="let row">
    <span *ngIf="isRecalled(row.status)">-</span>
    <span *ngIf="!isRecalled(row.status)">{{ row.expiry ? (row.expiry | mcDate) : ('$SECURE_MESSAGING_LOGS.TABLE.VALUES.NO_EXPIRY' | translate ) }}</span>
   </mc-body-cell>
</mc-column>

<mc-column key="status">
  <mc-body-cell *mcBodyCellDef="let row">{{ ('$SECURE_MESSAGING_LOGS.TABLE.VALUES.' + row.status.toUpperCase()) | translate}}</mc-body-cell>
</mc-column>

<mc-column key="dateSent">
  <mc-body-cell *mcBodyCellDef="let row">{{
    row.dateSent | mcDate
  }}</mc-body-cell>
</mc-column>

<mc-empty-results
  keyTitle="$SECURE_MESSAGING_LOGS.TABLE.EMPTY_RESULTS"
  iconClass="mc-icon-adcon-icon-stable-no-results-2"
>
</mc-empty-results>

<mc-column-select key="select"></mc-column-select>
<mc-column-actions
  key="right-column"
  mcShowColumnConfig
  [columnsToIgnore]="columnsToIgnore"
  [columnsAlwaysVisible]="columnsAlwaysVisible"
  [columnsToStartHidden]="[]"
></mc-column-actions>

<button
  data-test="messages-recall"
  class="mc-table-actions btn btn-primary"
  (click)="onRecall()"
  [disabled]="!multipleSelectedRows.length"
  *mcCapabilities="'Permission.SERVICE_SECURE_MESSAGING_EDIT'">
  {{ '$SECURE_MESSAGING_LOGS.TABLE.RECALL' | translate }}
</button>

<button
  data-test="messages-export"
  class="mc-table-actions btn btn-secondary"
  (click)="export()"
  [disabled]="!(data$ | async)?.length"
  >
  {{ '$SECURE_MESSAGING_LOGS.TABLE.EXPORT' | translate }}
</button>
</mc-table>