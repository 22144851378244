<div class="mc-detailed-list mc-swg-policy-summary-block">
  <div class="row mc-detailed-list-row no-padding-top">
    <div class="col-xs-3 mc-detailed-list-label">
      {{ '$I18N_SWG_CREATE_POLICY_POLICY_SUMMARY_NAME' | translate }}
    </div>
    <div class="mc-detailed-list-column">{{ policy.policyDesc.description }}</div>
  </div>

  <div class="row mc-detailed-list-row" *ngIf="!isBiPolicy()">
    <div class="col-xs-3 mc-detailed-list-label">
      {{ '$I18N_SWG_CREATE_POLICY_POLICY_SUMMARY_TYPE' | translate }}
    </div>
    <div class="mc-detailed-list-column">
      {{ '$I18N_SWG_CREATE_POLICY_' + policy.policyDesc.policyType | uppercase | translate }}
    </div>
  </div>

  <div class="row mc-detailed-list-row" *ngIf="!isSidebar && policy.policyDesc.policyType !== swgPolicyType.CLOUD_PROTECT_POLICY">
    <div class="col-xs-3 mc-detailed-list-label">
      {{ '$I18N_SWG_CREATE_POLICY_STATUS' | translate }}
    </div>
    <div class="mc-detailed-list-column">
      <form [formGroup]="_enabledForm" data-test="swgCreatePolicyEnabledSwitch">
        <mc-enable-switch
                formControlName="status"
                [value]="this._enabled"
                [compact]="true"
        ></mc-enable-switch>
      </form>
    </div>
  </div>

  <ng-container *ngIf="policy.policyDesc.policyType === 'WEB_BLOCK_ALLOW_LIST'">
    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_SWG_CREATE_POLICY_BLOCKED_PAGE_ACTION_BLOCK' | translate }}
      </div>
      <div class="mc-detailed-list-column" *ngIf="_blockedDomains.length === 0">
        {{ '$I18N_SWG_CREATE_POLICY_POLICY_SUMMARY_ALLOWED_BLOCKED_DOMAINS_NA' | translate }}
      </div>
      <div class="mc-detailed-list-column" *ngIf="_blockedDomains.length > 0">
        <ng-template ngFor let-item [ngForOf]="_blockedDomains">
          <div>{{ item }}</div>
        </ng-template>
      </div>
    </div>
    <div class="row mc-detailed-list-row panel-padding-top">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_SWG_CREATE_POLICY_BLOCKED_PAGE_ACTION_ALLOW' | translate }}
      </div>
      <div class="mc-detailed-list-column" *ngIf="_allowedDomains.length === 0">
        {{ '$I18N_SWG_CREATE_POLICY_POLICY_SUMMARY_ALLOWED_BLOCKED_DOMAINS_NA' | translate }}
      </div>
      <div class="mc-detailed-list-column" *ngIf="_allowedDomains.length > 0">
        <ng-template ngFor let-item [ngForOf]="_allowedDomains">
          <div>{{ item }}</div>
        </ng-template>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="policy.policyDesc.policyType === 'WEB_ADVANCED_SECURITY'">
    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_SWG_CREATE_POLICY_SAFE_SEARCH_GOOGLE' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{
        (policy.safeSearchSettings.google
          ? '$I18N_SWG_CREATE_POLICY_SAFE_SEARCH_ENABLED'
          : '$I18N_SWG_CREATE_POLICY_SAFE_SEARCH_DISABLED') | translate
        }}
      </div>
    </div>
    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_SWG_CREATE_POLICY_SAFE_SEARCH_BING' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{
        (policy.safeSearchSettings.bing
          ? '$I18N_SWG_CREATE_POLICY_SAFE_SEARCH_ENABLED'
          : '$I18N_SWG_CREATE_POLICY_SAFE_SEARCH_DISABLED') | translate
        }}
      </div>
    </div>
    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_SWG_CREATE_POLICY_SAFE_SEARCH_YOUTUBE' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{
        (policy.safeSearchSettings.youTube
          ? '$I18N_SWG_CREATE_POLICY_SAFE_SEARCH_ENABLED'
          : '$I18N_SWG_CREATE_POLICY_SAFE_SEARCH_DISABLED') | translate
        }}
      </div>
    </div>
    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_SWG_CREATE_POLICY_WEB_ADVANCED_SECURITY_NEW_DOMAINS_STEP' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{
        (policy.newDomainsSettings.checkNod
          ? '$I18N_COMMON_TEXT_ENABLED'
          : '$I18N_COMMON_TEXT_DISABLED') | translate
        }}
      </div>
    </div>
    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_SWG_CREATE_POLICY_WEB_ADVANCED_SECURITY_DNS_PROXY_STEP' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{
        (policy.proxySettings.enabled
          ? '$I18N_COMMON_TEXT_ENABLED'
          : '$I18N_COMMON_TEXT_DISABLED') | translate
        }}
      </div>
    </div>
    <div class="row mc-detailed-list-row" *ngIf="policy.proxySettings.enabled">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_SWG_CREATE_POLICY_WEB_ADVANCED_SECURITY_AV_SCAN' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{
        (policy.proxySettings.avScan
          ? '$I18N_COMMON_TEXT_ENABLED'
          : '$I18N_COMMON_TEXT_DISABLED') | translate
        }}
      </div>
    </div>
    <div class="row mc-detailed-list-row"
         *ngIf="hasBrowserIsolationEnabled && policy.proxySettings.enabled && policy.proxySettings.avScan">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_SWG_CREATE_POLICY_WEB_ADVANCED_SECURITY_BI' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{
        (policy.proxySettings.biEnabled
          ? '$I18N_SWG_CREATE_POLICY_SAFE_SEARCH_ENABLED'
          : '$I18N_SWG_CREATE_POLICY_SAFE_SEARCH_DISABLED') | translate
        }}
      </div>
    </div>
    <div
      class="row mc-detailed-list-row"
      *ngIf="policy.proxySettings.enabled && policy.proxySettings.avScan"
    >
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_SWG_CREATE_POLICY_WEB_ADVANCED_SECURITY_UNSCANNABLE' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{
        '$I18N_SWG_CREATE_POLICY_WEB_ADVANCED_SECURITY_ACTION_' +
        policy.proxySettings.blockUnscannable
          | uppercase
          | translate
        }}
      </div>
    </div>
    <div
      class="row mc-detailed-list-row"
      *ngIf="hasSwgHybridEnabled && policy.proxySettings.avScan"
    >
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_SWG_CREATE_POLICY_WEB_ADVANCED_SECURITY_PROXY_EXTENSIONS' | translate }}
      </div>
      <div class="mc-detailed-list-column" *ngIf="_protectedApplicationsList.length === 0">
        {{ '$I18N_SWG_CREATE_POLICY_POLICY_SUMMARY_ALLOWED_BLOCKED_DOMAINS_NA' | translate }}
      </div>
      <div class="mc-detailed-list-column" *ngIf="_protectedApplicationsList.length > 0">
        <ng-template ngFor let-item [ngForOf]=_protectedApplicationsList>
          <div>{{  item }}</div>
        </ng-template>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="policy.policyDesc.policyType === 'WEB_TTP_POLICY'">
    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_SWG_CREATE_POLICY_TTP_POLICY_MANAGED_URLS' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{
        (policy.ttpManagedUrlSettings.useManagedUrls
          ? '$I18N_COMMON_TEXT_ENABLED'
          : '$I18N_COMMON_TEXT_DISABLED') | translate
        }}
      </div>
    </div>
    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_SWG_CREATE_POLICY_TTP_POLICY_CHAMELEON' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{
        (policy.ttpChameleonSettings.chameleonEnabled
          ? '$I18N_COMMON_TEXT_ENABLED'
          : '$I18N_COMMON_TEXT_DISABLED') | translate
        }}
      </div>
    </div>
    <div class="row mc-detailed-list-row" *ngIf="policy.ttpChameleonSettings.chameleonEnabled">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_SWG_CREATE_POLICY_TTP_POLICY_ACTION' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{
        '$I18N_SWG_CREATE_POLICY_TTP_POLICY_ACTION_' + policy.ttpChameleonSettings.chameleonMode
          | uppercase
          | translate
        }}
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="policy.policyDesc.policyType === 'WEB_LOGGING'">
    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_SWG_POLICY_WEB_LOGGING_LEVEL' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{ '$I18N_SWG_POLICY_WEB_LOGGING_' + policy.loggingMode | uppercase | translate }}
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="policy.policyDesc.policyType === 'WEB_APPLICATION_CONTROL'">
    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_SWG_WEB_APPLICATION_CONTROL_BLOCKED_APPLICATIONS' | translate }}
      </div>
      <div class="mc-detailed-list-column" *ngIf="_blockedApplications.length > 0">
        <ng-template ngFor let-item [ngForOf]="_blockedApplications">
          <div>{{ item }}</div>
        </ng-template>
      </div>
    </div>
    <div *ngIf="!hasAppPolicyRemoveAllowEnabled" class="row mc-detailed-list-row panel-padding-top">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_SWG_WEB_APPLICATION_CONTROL_ALLOWED_APPLICATONS' | translate }}
      </div>
      <div class="mc-detailed-list-column" *ngIf="_allowedApplicatins.length > 0">
        <ng-template ngFor let-item [ngForOf]="_allowedApplicatins">
          <div>{{ item }}</div>
        </ng-template>
      </div>
    </div>

  </ng-container>

  <ng-container *ngIf="policy.policyDesc.policyType === 'WEB_CATEGORY_FILTERING'">
    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_SWG_CREATE_POLICY_WEB_ALLOWED_CATEGORIES' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        <ng-template ngFor let-item [ngForOf]="_allowedCategories">
          <div>
            {{ '$I18N_SWG_HUMANREADBALE_CATEGORY.' + item.category | uppercase | translate }}
          </div>
        </ng-template>
      </div>
    </div>
    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_SWG_CREATE_POLICY_WEB_BLOCKED_CATEGORIES' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        <ng-template ngFor let-item [ngForOf]="_blockedCategories">
          <div>
            {{ '$I18N_SWG_HUMANREADBALE_CATEGORY.' + item.category | uppercase | translate }}
          </div>
        </ng-template>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="policy.policyDesc.policyType === 'BROWSER_ISOLATION'">
    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$18N_BI_POLICY_LIST.SIDEBAR.DESCRIPTION' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{ policy.biSettings?.defaultPolicy ? ('$I18N_BI_DEFAULT_POLICY_DESCRIPTION' | translate) : policy?.narrative }}
      </div>
    </div>
    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_BI_POLICY_SETTINGS_STEP_ENTER_TEXT' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{
        (policy.biSettings.enterText
          ? '$I18N_SWG_CREATE_POLICY_SAFE_SEARCH_ENABLED'
          : '$I18N_SWG_CREATE_POLICY_SAFE_SEARCH_DISABLED') | translate
        }}
      </div>
    </div>
    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_BI_POLiCY_SETTINGS_STEP_TYPE_FROM_DEVICE_CLIPBOARD' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{
        (policy.biSettings.clipboardTransferIn
          ? '$I18N_SWG_CREATE_POLICY_SAFE_SEARCH_ENABLED'
          : '$I18N_SWG_CREATE_POLICY_SAFE_SEARCH_DISABLED') | translate
        }}
      </div>
    </div>
    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_BI_POLiCY_SETTINGS_STEP_TYPE_TO_DEVICE_CLIPBOARD' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{
        (policy.biSettings.clipboardTransferOut
          ? '$I18N_SWG_CREATE_POLICY_SAFE_SEARCH_ENABLED'
          : '$I18N_SWG_CREATE_POLICY_SAFE_SEARCH_DISABLED') | translate
        }}
      </div>
    </div>

  </ng-container>

  <ng-container *ngIf="policy.policyDesc.policyType === 'CLOUD_PROTECT_POLICY'">
    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_SWG_CLP_POLICY_SUMMARY.CLOUD_APP' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{ (policy.clpPolicySettings && policy.clpPolicySettings.provider ?
            "$I18N_CONNECTORS.PROVIDERS." + policy.clpPolicySettings.provider?.toUpperCase() :
            '$I18N_SWG_CLP_POLICY_SUMMARY.NOT_AVAILABLE') | translate
        }}
      </div>
    </div>

    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_SWG_CLP_POLICY_SUMMARY.APP_CONNECTOR' | translate }}
      </div>
      <div *ngIf="policy.clpPolicySettings" class="mc-detailed-list-column">
        <span *ngIf="policy.clpPolicySettings?.scope.type === 'SPECIFIC_CONNECTOR'">
          {{ policy.clpPolicySettings.connectorName ? policy.clpPolicySettings.connectorName : ('$I18N_SWG_CLP_POLICY_SUMMARY.NOT_AVAILABLE' | translate )}}
        </span>
        <span *ngIf="policy.clpPolicySettings?.scope.type === 'SPECIFIC_CLOUD_SERVICE'">
          {{ (policy.clpPolicySettings && policy.clpPolicySettings.provider ?
             "$I18N_SWG_CLP_POLICY_SUMMARY.ALL_" + policy.clpPolicySettings.provider.toUpperCase() + "_CONNECTORS" :
              '$I18N_SWG_CLP_POLICY_SUMMARY.NOT_AVAILABLE') | translate
          }}
        </span>
      </div>
      <div *ngIf="!policy.clpPolicySettings" class="mc-detailed-list-column">
        <span>
          {{ '$I18N_SWG_CLP_POLICY_SUMMARY.NOT_AVAILABLE' | translate}}
        </span>

      </div>
    </div>

    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_SWG_CLP_POLICY_SUMMARY.ACTION' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{ (policy.clpPolicySettings && policy.clpPolicySettings.action ?
            '$I18N_SWG_CLP_POLICY_SUMMARY.ACTIONS.' + policy.clpPolicySettings.action.toUpperCase() :
            '$I18N_SWG_CLP_POLICY_SUMMARY.NOT_AVAILABLE') | translate }}
      </div>
    </div>

    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_SWG_CLP_POLICY_SUMMARY.NOTIFY_FILE_OWNER' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{
        (policy.clpPolicySettings?.notifyFileOwner
          ? '$I18N_COMMON_TEXT_ENABLED'
          : '$I18N_COMMON_TEXT_DISABLED') | translate
        }}
      </div>
    </div>

    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_SWG_CLP_POLICY_SUMMARY.NOTIFY_ADMINISTRATOR' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{
        (policy.clpPolicySettings?.notifyAdministrator
          ? '$I18N_COMMON_TEXT_ENABLED'
          : '$I18N_COMMON_TEXT_DISABLED') | translate
        }}
      </div>
    </div>

    <div class="row mc-detailed-list-row" *ngIf="policy.clpPolicySettings && policy.clpPolicySettings.notificationList && policy.clpPolicySettings.notificationList.length > 0">
      <div class="col-xs-3 mc-detailed-list-label"></div>
      <div class="mc-detailed-list-column">
        <mc-swg-policy-summary-targets
          [affectedUsers]="policy.clpPolicySettings?.notificationList"
        ></mc-swg-policy-summary-targets>
      </div>
    </div>




  </ng-container>

  <div *ngIf="showOverride()" class="row mc-detailed-list-row">
    <div class="col-xs-3 mc-detailed-list-label">
      {{ '$I18N_SWG_CREATE_POLICY_AFFECTED_ENFORCE_DEVICE_POLICY' | translate }}
    </div>
    <div class="mc-detailed-list-column">
      {{(policy.policyDesc.override ?
      '$I18N_SWG_CREATE_POLICY_AFFECTED_ENFORCE_DEVICE_POLICY_ENABLED' :
      '$I18N_SWG_CREATE_POLICY_AFFECTED_ENFORCE_DEVICE_POLICY_DISABLED') | translate}}
    </div>
  </div>

  <div class="row mc-detailed-list-row">

    <div class="col-xs-3 mc-detailed-list-label">
      {{ '$I18N_SWG_CREATE_POLICY_POLICY_SUMMARY_AFFECTED_USERS' | translate }}
    </div>
    <div class="mc-detailed-list-column">
      <div *ngIf="policy.everyOne">
        {{ '$I18N_SWG_CREATE_POLICY_POLICY_EVERYONE' | translate }}
      </div>

      <mc-swg-policy-summary-targets
        *ngIf="!policy.everyOne"
        [affectedUsers]="policy.affectedUsers"
      ></mc-swg-policy-summary-targets>
    </div>
  </div>
</div>
