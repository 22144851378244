"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetSuccessAction = exports.GetFailureAction = exports.GetAction = exports.ReportsActions = void 0;
var ReportsActions;
(function (ReportsActions) {
    ReportsActions["GET"] = "[SupervisionReportDownloader] Get";
    ReportsActions["GET_FAILURE"] = "[SupervisionReportDownloader] Get Failure";
    ReportsActions["GET_SUCCESS"] = "[SupervisionReportDownloader] Get Success";
})(ReportsActions = exports.ReportsActions || (exports.ReportsActions = {}));
class GetAction {
    constructor(report) {
        this.report = report;
        this.type = ReportsActions.GET;
    }
}
exports.GetAction = GetAction;
class GetFailureAction {
    constructor(failure) {
        this.failure = failure;
        this.type = ReportsActions.GET_FAILURE;
    }
}
exports.GetFailureAction = GetFailureAction;
class GetSuccessAction {
    constructor(filename, size) {
        this.filename = filename;
        this.size = size;
        this.type = ReportsActions.GET_SUCCESS;
    }
}
exports.GetSuccessAction = GetSuccessAction;
