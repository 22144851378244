"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogsTableComponent = void 0;
const table_store_1 = require("@mimecast-ui/table-store");
const logs_table_static_values_1 = require("./logs-table.static-values");
const services_1 = require("../../services");
const actions_1 = require("../../actions");
const actions_2 = require("../../actions");
const reducers_1 = require("../../reducers/");
class LogsTableComponent extends table_store_1.TableBaseComponent {
    constructor(store, capabilitiesService) {
        super(store, services_1.SecureMessagingService.LOGS_TABLE);
        this.store = store;
        this.externalTableSearchCount = 0;
        this.columnsAlwaysVisible = logs_table_static_values_1.tableColumnsAlwaysVisible;
        this.columnsToIgnore = logs_table_static_values_1.tableColumnsToIgnore;
        this.multipleSelectedRows = [];
        this.searchFilters = logs_table_static_values_1.searchFilters;
        this.searchByFields = [];
        this.hasCapability$ = capabilitiesService
            .hasCapability('Permission.SERVICE_SECURE_MESSAGING_EDIT')
            .subscribe(canEdit => {
            if (canEdit) {
                this.columns = ['id', ...logs_table_static_values_1.tableColumns];
            }
            else {
                this.columns = logs_table_static_values_1.tableColumns;
            }
        });
    }
    ngOnInit() {
        // needs to have an empty ngOnInit to avoid parent class dispatches default loading action
        this.data$ = this.store.select(reducers_1.getMessages);
        this.filtersMetadata$ = this.store.select(reducers_1.getSearchFilterMetadata);
        this.isLoading$ = this.store.select(reducers_1.isLoading);
    }
    dispatchSearchAction(filteringOption = this.currentSearch) {
        this.currentSearch = filteringOption;
        this.store.dispatch(new actions_1.FilterAction(filteringOption));
        ++this.externalTableSearchCount;
    }
    onSelectChangeMultiple(selectedRows) {
        this.multipleSelectedRows = selectedRows;
    }
    openItem(item) {
        this.store.dispatch(new actions_1.OpenAndGetMessageDetailsAction(item));
    }
    onRecall() {
        const smIDs = this.multipleSelectedRows.map((option) => option.id);
        this.store.dispatch(new actions_2.RecallAction({ smIDs }));
    }
    isRowSelectable(row) {
        return row && row.status && row.status.toLowerCase() !== 'recalled';
    }
    isRecalled(status) {
        return status.toLowerCase() === 'recalled';
    }
    export() {
        this.store.dispatch(new actions_2.ExportAction(logs_table_static_values_1.exportTableColumns, this.currentSearch));
    }
    onBundleFilterChange(filters) {
        const { type } = filters;
        let request = Object.assign({}, this.currentSearch);
        if (type && type.length) {
            request = Object.assign(Object.assign({}, request), { recalledOnly: true });
        }
        else {
            delete request.recalledOnly;
        }
        this.dispatchSearchAction(request);
    }
    onPaginatorChange(paginatorOutput) {
        this.store.dispatch(new actions_1.SearchPaginationAction(paginatorOutput));
    }
    ngOnDestroy() {
        this.hasCapability$.unsubscribe();
    }
}
exports.LogsTableComponent = LogsTableComponent;
