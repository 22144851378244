"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgFileParseEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const reducers = require("app-new/core/reducers");
const actions = require("../../actions/file-parse.actions");
class SwgFileParseEffects {
    constructor(actions$, store, swgPolicyService) {
        this.actions$ = actions$;
        this.store = store;
        this.swgPolicyService = swgPolicyService;
        this.parseFile = this.actions$.pipe(effects_1.ofType(actions.SWG_POLICY_PARSE_FILE), operators_1.map((action) => action.payload), operators_1.withLatestFrom(this.store.select(reducers.getCapabilities)), operators_1.switchMap(([payload]) => {
            return this.swgPolicyService.readFile(payload).pipe(operators_1.map((response) => new actions.SwgPolicyParseFileSuccess(response)), operators_1.catchError(() => rxjs_1.of(new actions.SwgPolicyParseFileFailure())));
        }));
    }
}
__decorate([
    effects_1.Effect()
], SwgFileParseEffects.prototype, "parseFile", void 0);
exports.SwgFileParseEffects = SwgFileParseEffects;
