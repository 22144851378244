<div class="email-preview">
  <div class="view-mode" *ngIf="(message$ | async).htmlBody && (message$ | async).textBody">
    <span>{{ '$I18N_REMEDIATION_MESSAGE_DETAILS.VIEW_MODE' | translate}}</span>
    <mc-select
      [options]="options"
      (selectChange)="selectChange($event)">
    </mc-select>
  </div>

  <mc-inline-notification
    class="clear"
    *ngIf="displayImagesNotification && showHtml$ | async"
    [notification]="imagesNotification">
  </mc-inline-notification>
  <div class="email-preview-content" *ngIf="showHtml$ | async; else showPlainText">
    <div [innerHtml]="htmlBody$ | async" *ngIf="!(isLoadingMessageWithImages$ | async); else spinner"></div>
  </div>
</div>

<ng-template #spinner>
  <div class="with-spinner">
    <mc-spinner></mc-spinner>
  </div>
</ng-template>
<ng-template #showPlainText>
  <div class="panel panel-default">
    <div class="panel-body">
      <div>{{(message$ | async).textBody}}</div>
    </div>
  </div>
</ng-template>
