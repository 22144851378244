"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueueListEffect = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const queue_list_actions_1 = require("../actions/queue.list.actions");
const operators_1 = require("rxjs/operators");
const lexicon_actions_1 = require("./../actions/lexicon.actions");
const queue_configs_factory_1 = require("app-new/archive/supervision/factories/queue-configs.factory");
const view_queue_config_component_1 = require("app-new/archive/supervision/containers/asides/view-queue-config/view-queue-config.component");
const table_store_1 = require("@mimecast-ui/table-store");
const notification_actions_1 = require("../../../components/notification/actions/notification.actions");
const TABLE_ID = 'SupervisionQueueConfigList';
class QueueListEffect {
    constructor(actions$, stateService, asideService, translateService, queueConfigsService, tableStore) {
        this.actions$ = actions$;
        this.stateService = stateService;
        this.asideService = asideService;
        this.translateService = translateService;
        this.queueConfigsService = queueConfigsService;
        this.tableStore = tableStore;
        this.showCreateQueueWizard$ = this.actions$.pipe(effects_1.ofType(queue_list_actions_1.QueueListActions.CREATE_NEW_QUEUE), operators_1.switchMap(() => {
            this.stateService.$state.go('archive-supervision-wizard-queue');
            return rxjs_1.EMPTY;
        }));
        this.openSidepanel$ = this.actions$.pipe(effects_1.ofType(queue_list_actions_1.QueueListActions.OPEN_QUEUE_DETAILS_SIDEPANEL), operators_1.switchMap((action) => {
            if (!this.viewAside) {
                this.closeViewAside();
                this.viewAside = this.asideService.open(view_queue_config_component_1.ViewQueueConfigComponent, {
                    size: 'lg',
                    hasBackdrop: true,
                    data: action.tableContent
                });
            }
            return rxjs_1.EMPTY;
        }));
        this.closeSidepanel$ = this.actions$.pipe(effects_1.ofType(queue_list_actions_1.QueueListActions.CLOSE_QUEUE_DETAILS_SIDEPANEL), operators_1.tap(() => this.closeViewAside()));
        this.showEditQueueWizard$ = this.actions$.pipe(effects_1.ofType(queue_list_actions_1.QueueListActions.OPEN_EDIT_QUEUE), operators_1.map((action) => action.queueId), operators_1.switchMap(queueId => {
            return this.queueConfigsService.getQueueConfig(queueId).pipe(operators_1.map(response => {
                if (response.hasErrors) {
                    return { error: response.fail[0], qDetails: undefined };
                }
                const queueDetails = queue_configs_factory_1.QueueConfigFactory.BUILD_QUEUE_CONFIG_DETAILS(response.first);
                return { error: undefined, qDetails: queueDetails };
            }));
        }), operators_1.switchMap((result) => {
            this.stateService.$state.go('archive-supervision-update-wizard-queue', {
                queueDetails: Object.assign({}, result.qDetails)
            });
            if (result.error) {
                return rxjs_1.of(new lexicon_actions_1.GetActionFail(result.error));
            }
            else {
                return rxjs_1.EMPTY;
            }
        }));
        this.delete$ = this.actions$.pipe(effects_1.ofType(queue_list_actions_1.QueueListActions.DELETE_QUEUE), operators_1.switchMap((action) => {
            return this.queueConfigsService.deleteQueue(action.payload).pipe(operators_1.map(response => {
                if (response.hasErrors) {
                    return new queue_list_actions_1.DeleteQueueActionFail({
                        message: this.getNotificationMessage('DELETE_FAILURE'),
                        failure: response.fail[0]
                    });
                }
                return new queue_list_actions_1.DeleteQueueActionSuccess(this.getNotificationMessage('DELETE_SUCCESS'));
            }), operators_1.catchError(response => {
                return rxjs_1.of(new queue_list_actions_1.DeleteQueueActionFail({
                    message: this.getNotificationMessage('DELETE_FAILURE'),
                    failure: response.fail[0]
                }));
            }));
        }));
        this.deleteSuccess$ = this.actions$.pipe(effects_1.ofType(queue_list_actions_1.QueueListActions.DELETE_QUEUE_SUCCESS), operators_1.map((action) => action.payload), operators_1.withLatestFrom(this.tableStore.select(table_store_1.tableReducer.getTableData(TABLE_ID)), this.tableStore.select(table_store_1.tableReducer.getCurrentFilters(TABLE_ID)), this.tableStore.select(table_store_1.tableReducer.getCurrentPagination(TABLE_ID))), operators_1.switchMap(([payload, tableData, currentFilters, currentPagination]) => {
            return [
                new notification_actions_1.NotificationShowSuccessAction(payload),
                new table_store_1.LoadAction({
                    tableId: TABLE_ID,
                    pagination: {
                        pageToken: currentPagination.pageToken,
                        pageSize: currentPagination.pageSize,
                        pageNumber: currentPagination.pageNumber
                    },
                    query: tableData.length === 1 ? undefined : currentFilters
                })
            ];
        }));
        this.deleteFail$ = this.actions$.pipe(effects_1.ofType(queue_list_actions_1.QueueListActions.DELETE_QUEUE_FAIL), operators_1.switchMap((action) => {
            if (!action.payload.failure || !action.payload.failure.errors) {
                return [new notification_actions_1.NotificationShowFailAction(action.payload.message)];
            }
            return action.payload.failure.errors.map(error => new notification_actions_1.NotificationShowFailAction(this.translateService.instant(this.getNotificationMessage(error.code))));
        }));
        this.getQueueConfigDetails$ = this.actions$.pipe(effects_1.ofType(queue_list_actions_1.QueueListActions.LOAD_QUEUE_CONFIG_DETAILS), operators_1.switchMap((action) => {
            return this.queueConfigsService.getQueueConfig(action.payload).pipe(operators_1.map((response) => {
                if (response.hasErrors) {
                    return new queue_list_actions_1.LoadQueueConfigDetailsFailureAction(response.fail[0]);
                }
                return new queue_list_actions_1.LoadQueueConfigDetailsSuccessAction(response.first);
            }), operators_1.catchError(response => {
                return rxjs_1.of(new queue_list_actions_1.LoadQueueConfigDetailsFailureAction(response.fail));
            }));
        }));
    }
    getNotificationMessage(notificationkey) {
        const fullKey = `$I18N_ARCHIVE_SUPERVISION_LIST_QUEUES_TAB.NOTIFICATION.${notificationkey.toUpperCase()}`;
        return this.translateService.instant(fullKey);
    }
    closeViewAside() {
        if (!!this.viewAside) {
            this.viewAside.close();
            this.viewAside = undefined;
        }
    }
}
__decorate([
    effects_1.Effect()
], QueueListEffect.prototype, "showCreateQueueWizard$", void 0);
__decorate([
    effects_1.Effect()
], QueueListEffect.prototype, "openSidepanel$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], QueueListEffect.prototype, "closeSidepanel$", void 0);
__decorate([
    effects_1.Effect()
], QueueListEffect.prototype, "showEditQueueWizard$", void 0);
__decorate([
    effects_1.Effect()
], QueueListEffect.prototype, "delete$", void 0);
__decorate([
    effects_1.Effect()
], QueueListEffect.prototype, "deleteSuccess$", void 0);
__decorate([
    effects_1.Effect()
], QueueListEffect.prototype, "deleteFail$", void 0);
__decorate([
    effects_1.Effect()
], QueueListEffect.prototype, "getQueueConfigDetails$", void 0);
exports.QueueListEffect = QueueListEffect;
