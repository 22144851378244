"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const list_component_1 = require("./containers/list/list.component");
angular
    .module('directories.attributes', [])
    .controller('DirectoriesAttributesController', [
    '$stateParams',
    '$scope',
    function ($stateParams, $scope) {
        $scope.stateParams = $stateParams;
    }
])
    .directive('mcDirectoriesAttributesList', static_1.downgradeComponent({
    component: list_component_1.ListComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider.state('directories-attributes-list', {
            url: '^/directories/attributes',
            data: {
                capabilities: 'Permission.ATTRIBUTES_READ',
                isFixedLayout: false,
                checkProgress: false,
                tabId: 'directories-attributes-tab',
                tabTitle: '$18N_ATTRIBUTES_DIRECTORIES_LIST.TAB_TITLE'
            },
            views: {
                main: {
                    template: '<mc-directories-attributes-list></mc-directories-attributes-list>',
                    controller: 'DirectoriesAttributesController'
                }
            }
        });
    }
]);
