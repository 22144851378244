<mc-table
  [isLoading]="isLoading$ | async"
  [data]="tableData$ | async"
  [columns]="columnList"
  translatePrefix="$I18N_REMEDIATION_LOGS_TAB">

  <mc-filters
    (paginatorChange)="onPaginationChange($event)"
    [metadata]="metaData$ | async"
    [pageSizeOptions]="[50,100,300]"
    [showPagination]="true"
    [externalTableSearchCount]="externalTableSearchCount$ | async">

    <mc-filter-search
      (search)="onSearch($event)"
      (clear)="onSearchClear()"
      [options]="filterSearchOptions">
    </mc-filter-search>

    <mc-filters-bundle-date-range
      (filtersChange)="onDateRangeFilterChange($event)">
    </mc-filters-bundle-date-range>

    <mc-filters-bundle-column-values
      [sections]="searchFilters"
      [columns]="2"
      (filtersChange)="onBundleFilterChange($event)">
    </mc-filters-bundle-column-values>

  </mc-filters>

  <button class="mc-table-actions btn btn-secondary" (click)="onExportClicked()">
    {{ '$I18N_REMEDIATION_LOGS_TAB.ACTIONS.EXPORT' | translate }}
  </button>

  <mc-column key="action">
    <mc-body-cell *mcBodyCellDef="let row">{{ getTranslatedActionName(row.action) }}</mc-body-cell>
  </mc-column>

  <mc-column key="status">
    <mc-body-cell *mcBodyCellDef="let row">
      {{ getTranslatedStatusName(row.status) }}
      <span *ngIf="showFailureCode(row.status, row.failureCode)">
        ({{ '$I18N_REMEDIATION.FAILURE_TYPE.' + row.failureCode | uppercase | translate }})
      </span>
    </mc-body-cell>
  </mc-column>

  <mc-column-date key="actionTime"></mc-column-date>

  <mc-column-actions key="column-actions" mcShowColumnConfig>
    <mc-row-actions *mcRowActions="let row">
      <li mcRowAction="{{option.label}}" (click)="option.action(row)" *ngFor="let option of dropdownOptions"></li>
    </mc-row-actions>
  </mc-column-actions>

  <mc-empty-results keyTitle="$I18N_REMEDIATION_LOGS_TAB.NO_DATA"></mc-empty-results>

</mc-table>
