<mc-modal-header
  headerTitle="{{
    ('$I18N_API_GATEWAY_DISABLE_CONFIRMATION_MODAL.HEADER' | translate)
  }}"
>
</mc-modal-header>

<mc-modal-body>
  <p>{{ '$I18N_API_GATEWAY_DISABLE_CONFIRMATION_MODAL.MESSAGE' | translate }}</p>
</mc-modal-body>

<mc-modal-footer>
  <button type="button" class="btn btn-secondary" (click)="cancel()">
    {{ '$I18N_API_GATEWAY_DISABLE_CONFIRMATION_MODAL.CANCEL' | translate }}
  </button>

  <button type="button" class="btn btn-secondary" (click)="disableAnyway()">
    {{ '$I18N_API_GATEWAY_DISABLE_CONFIRMATION_MODAL.DISABLE_ANYWAY' | translate }}
  </button>

  <mc-live-button
    typeClass="btn btn-primary"
    content="{{ '$I18N_API_GATEWAY_DISABLE_CONFIRMATION_MODAL.DELETE_NOW' | translate }}"
    type="submit"
    (mcClick)="deleteNow()"
  >
  </mc-live-button>
</mc-modal-footer>
