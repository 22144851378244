<div *ngIf="passwordType === 'ANTI_TAMPER'">
  <mc-modal-header
    headerTitle="{{ '$I18N_ENDPOINT_SETTINGS.MODAL.SETTINGS.ANTI_TAMPER.HEADER' | translate }}">
  </mc-modal-header>
  <mc-modal-body>
    <p>{{ '$I18N_ENDPOINT_SETTINGS.MODAL.SETTINGS.ANTI_TAMPER.BODY_CONTENT' | translate }}</p>
  </mc-modal-body>
</div>
<div *ngIf="passwordType === 'UNINSTALL'">
  <mc-modal-header
     headerTitle="{{ '$I18N_ENDPOINT_SETTINGS.MODAL.SETTINGS.UNINSTALL.HEADER' | translate }}">
  </mc-modal-header>
  <mc-modal-body>
    <p>{{ '$I18N_ENDPOINT_SETTINGS.MODAL.SETTINGS.UNINSTALL.BODY_CONTENT' | translate }}</p>
  </mc-modal-body>
</div>
<mc-modal-footer>
  <button type="button" class="btn btn-secondary" (click)="onClose()">
    {{ '$I18N_ENDPOINT_SETTINGS.MODAL.SETTINGS.BTN_CANCEL' | translate }}
  </button>
  <mc-live-button
    overrideClasses="btn-primary"
    [label]="'$I18N_ENDPOINT_SETTINGS.MODAL.SETTINGS.BTN_CREATE' | translate"
    (mcClick)="onGeneratePassword()">
  </mc-live-button>
</mc-modal-footer>
