"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetCrowdstrikeActivityLogsCompleteAction = exports.GetCrowdstrikeActivityLogsAction = exports.OpenCrowdstrikeActivityLogPageAction = exports.DeleteGroupCrowdstrikeIntegrationNotificationsAction = exports.AddGroupCrowdstrikeIntegrationNotificationsAction = exports.DeleteEmailCrowdstrikeIntegrationNotificationsAction = exports.VerifyAndAddEmailCrowdstrikeIntegrationNotificationsFailAction = exports.VerifyAndAddEmailCrowdstrikeIntegrationNotificationsSuccessAction = exports.VerifyAndAddEmailCrowdstrikeIntegrationNotificationsAction = exports.UpdateCrowdstrikeIntegrationSuccessAction = exports.UpdateCrowdstrikeIntegrationAction = exports.GetCrowdstrikeIntegrationCompleteAction = exports.OpenCrowdstrikeIntegrationAction = exports.CreateCrowdstrikeIntegrationSuccessAction = exports.CreateCrowdstrikeIntegrationAction = exports.SwitchCrowdstrikeRemediationRequiredAction = exports.SelectIocOutboundActionAction = exports.UpdateIocOutboundExpirationAction = exports.UpdateIocOutboundSeverityAction = exports.UpdateIocOutboundUrlActionAction = exports.SwitchMaliciousUrlsAction = exports.SwitchSubmitMaliciousHashesAction = exports.SwitchPullMaliciousHashesAction = exports.SwitchCrowdstrikeIntegrationStatusAction = exports.CancelCrowdstrikeIntegrationWidgetAction = exports.StartVerifyClientIdAndClientSecretWritePermissionFailAction = exports.StartVerifyClientIdAndClientSecretReadPermissionFailAction = exports.StartVerifyClientIdAndClientSecretFailAction = exports.StartVerifyClientIdAndClientSecretSuccessAction = exports.StartVerifyClientIdAndClientSecretAction = exports.IntegrationCrowdstrikeActionTypes = void 0;
var IntegrationCrowdstrikeActionTypes;
(function (IntegrationCrowdstrikeActionTypes) {
    IntegrationCrowdstrikeActionTypes["CreateCrowdstrikeIntegration"] = "[ApiApplication/Integration/Crowdstrike/Create] Create Crowdstrike Integration";
    IntegrationCrowdstrikeActionTypes["CreateCrowdstrikeIntegrationSuccess"] = "[ApiApplication/Integration/Crowdstrike/Create/Success] Create Crowdstrike Integration Success";
    IntegrationCrowdstrikeActionTypes["StartVerifyClientIdAndClientSecret"] = "[ApiApplication/Integration/Crowdstrike/Activation] Crowdstrike Integration Activation Verify";
    IntegrationCrowdstrikeActionTypes["StartVerifyClientIdAndClientSecretSuccess"] = "[ApiApplication/Integration/Crowdstrike/Activation]   Crowdstrike Integration Activation Verify Success";
    IntegrationCrowdstrikeActionTypes["StartVerifyClientIdAndClientSecretFail"] = "[ApiApplication/Integration/Crowdstrike/Activation]   Crowdstrike Integration Activation Verify Fail";
    IntegrationCrowdstrikeActionTypes["StartVerifyClientIdAndClientSecretReadPermissionFail"] = "[ApiApplication/Integration/Crowdstrike/Activation]   Crowdstrike Integration Activation Verify Ioc Management Read Fail";
    IntegrationCrowdstrikeActionTypes["StartVerifyClientIdAndClientSecretWritePermissionFail"] = "[ApiApplication/Integration/Crowdstrike/Activation]   Crowdstrike Integration Activation Verify Ioc Management Write Fail";
    IntegrationCrowdstrikeActionTypes["CancelCrowdstrikeIntegrationWidget"] = "[ApiApplication/Integration/Crowdstrike/Cancel] Crowdstrike Integration Cancel";
    IntegrationCrowdstrikeActionTypes["SwitchCrowdstrikeIntegrationStatus"] = "[ApiApplication/Integration/Crowdstrike/Status/Switch] Crowdstrike Integration Status Switch";
    IntegrationCrowdstrikeActionTypes["OpenCrowdstrikeIntegration"] = "[ApiApplication/Integration/Crowdstrike/Open] Open Crowdstrike Integration";
    IntegrationCrowdstrikeActionTypes["GetCrowdstrikeIntegrationComplete"] = "[ApiApplication/Integration/Crowdstrike/Get] Get Crowdstrike Integration Complete";
    IntegrationCrowdstrikeActionTypes["UpdateCrowdstrikeIntegration"] = "[ApiApplication/Integration/Crowdstrike/Update] Update Crowdstrike Integration";
    IntegrationCrowdstrikeActionTypes["UpdateCrowdstrikeIntegrationSuccess"] = "[ApiApplication/Integration/Crowdstrike/Create/Update] Update Crowdstrike Integration Success";
    IntegrationCrowdstrikeActionTypes["VerifyAndAddEmailCrowdstrikeIntegrationNotifications"] = "[ApiApplication/Integration/Crowstrike/Email/Add]   Add Email Crowdstrike Integration";
    IntegrationCrowdstrikeActionTypes["VerifyAndAddEmailCrowdstrikeIntegrationNotificationsSuccess"] = "[ApiApplication/Integration/Crowdstrike/Email/Add/Success]   Add Email Crowdstrike Integration Success";
    IntegrationCrowdstrikeActionTypes["VerifyAndAddEmailCrowdstrikeIntegrationNotificationsFail"] = "[ApiApplication/Integration/Crowdstrike/Email/Add/Fail]   Add Email Crowdstrike Integration Fail";
    IntegrationCrowdstrikeActionTypes["DeleteEmailCrowdstrikeIntegrationNotifications"] = "[ApiApplication/Integration/Crowdstrike/Email/Delete]   Delete Email Crowdstrike Integration";
    IntegrationCrowdstrikeActionTypes["AddGroupCrowdstrikeIntegrationNotifications"] = "[ApiApplication/Integration/Crowdstrike/Group/Add]   Add Group Crowdstrike Integration";
    IntegrationCrowdstrikeActionTypes["DeleteGroupCrowdstrikeIntegrationNotifications"] = "[ApiApplication/Integration/Crowdstrike/Group/Delete]   Delete Group Crowdstrike Integration";
    IntegrationCrowdstrikeActionTypes["SwitchSubmitMaliciousHashes"] = "[ApiApplication/Integration/Crowdstrike/Update] Switch Submit Malicious Hashes";
    IntegrationCrowdstrikeActionTypes["UpdateIocOutboundUrlAction"] = "[ApiApplication/Integration/Crowdstrike/Update] Update IOC Outbound URL Action";
    IntegrationCrowdstrikeActionTypes["UpdateIocOutboundSeverity"] = "[ApiApplication/Integration/Crowdstrike/Update] Update IOC Outbound Severity";
    IntegrationCrowdstrikeActionTypes["UpdateIocOutboundExpiration"] = "[ApiApplication/Integration/Crowdstrike/Update] Update IOC Outbound Expiration";
    IntegrationCrowdstrikeActionTypes["SwitchMaliciousUrls"] = "[ApiApplication/Integration/Crowdstrike/Update] Switch Malicious URLS";
    IntegrationCrowdstrikeActionTypes["SwitchPullMaliciousHashes"] = "[ApiApplication/Integration/Crowdstrike/Update] Switch Pull Malicious Hashes";
    IntegrationCrowdstrikeActionTypes["SelectIocOutboundAction"] = "[ApiApplication/Integration/Crowdstrike/Update] Switch Ioc Outbound Action";
    IntegrationCrowdstrikeActionTypes["SwitchCrowdstrikeRemediationRequired"] = "[ApiApplication/Integration/Crowdstrike/Update] Switch Remediation Required";
    IntegrationCrowdstrikeActionTypes["OpenCrowdstrikeActivityLogPage"] = "[ApiApplication/Integration/Crowdstrike/Crowdstrike/Log] Open CrowdStrike Activity Log Page";
    IntegrationCrowdstrikeActionTypes["GetCrowdstrikeActivityLogs"] = "[ApiApplication/Integration/Crowdstrike/Crowdstrike/Log/Get] Get CrowdStrike Activity Logs";
    IntegrationCrowdstrikeActionTypes["GetCrowdstrikeActivityLogsComplete"] = "[ApiApplication/Integration/Crowdstrike/Crowdstrike/Log/Get] Get CrowdStrike Activity Logs Complete";
})(IntegrationCrowdstrikeActionTypes = exports.IntegrationCrowdstrikeActionTypes || (exports.IntegrationCrowdstrikeActionTypes = {}));
class StartVerifyClientIdAndClientSecretAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationCrowdstrikeActionTypes.StartVerifyClientIdAndClientSecret;
    }
}
exports.StartVerifyClientIdAndClientSecretAction = StartVerifyClientIdAndClientSecretAction;
class StartVerifyClientIdAndClientSecretSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationCrowdstrikeActionTypes.StartVerifyClientIdAndClientSecretSuccess;
    }
}
exports.StartVerifyClientIdAndClientSecretSuccessAction = StartVerifyClientIdAndClientSecretSuccessAction;
class StartVerifyClientIdAndClientSecretFailAction {
    constructor() {
        this.type = IntegrationCrowdstrikeActionTypes.StartVerifyClientIdAndClientSecretFail;
    }
}
exports.StartVerifyClientIdAndClientSecretFailAction = StartVerifyClientIdAndClientSecretFailAction;
class StartVerifyClientIdAndClientSecretReadPermissionFailAction {
    constructor() {
        this.type = IntegrationCrowdstrikeActionTypes.StartVerifyClientIdAndClientSecretReadPermissionFail;
    }
}
exports.StartVerifyClientIdAndClientSecretReadPermissionFailAction = StartVerifyClientIdAndClientSecretReadPermissionFailAction;
class StartVerifyClientIdAndClientSecretWritePermissionFailAction {
    constructor() {
        this.type = IntegrationCrowdstrikeActionTypes.StartVerifyClientIdAndClientSecretWritePermissionFail;
    }
}
exports.StartVerifyClientIdAndClientSecretWritePermissionFailAction = StartVerifyClientIdAndClientSecretWritePermissionFailAction;
class CancelCrowdstrikeIntegrationWidgetAction {
    constructor() {
        this.type = IntegrationCrowdstrikeActionTypes.CancelCrowdstrikeIntegrationWidget;
    }
}
exports.CancelCrowdstrikeIntegrationWidgetAction = CancelCrowdstrikeIntegrationWidgetAction;
class SwitchCrowdstrikeIntegrationStatusAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationCrowdstrikeActionTypes.SwitchCrowdstrikeIntegrationStatus;
    }
}
exports.SwitchCrowdstrikeIntegrationStatusAction = SwitchCrowdstrikeIntegrationStatusAction;
class SwitchPullMaliciousHashesAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationCrowdstrikeActionTypes.SwitchPullMaliciousHashes;
    }
}
exports.SwitchPullMaliciousHashesAction = SwitchPullMaliciousHashesAction;
class SwitchSubmitMaliciousHashesAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationCrowdstrikeActionTypes.SwitchSubmitMaliciousHashes;
    }
}
exports.SwitchSubmitMaliciousHashesAction = SwitchSubmitMaliciousHashesAction;
class SwitchMaliciousUrlsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationCrowdstrikeActionTypes.SwitchMaliciousUrls;
    }
}
exports.SwitchMaliciousUrlsAction = SwitchMaliciousUrlsAction;
class UpdateIocOutboundUrlActionAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationCrowdstrikeActionTypes.UpdateIocOutboundUrlAction;
    }
}
exports.UpdateIocOutboundUrlActionAction = UpdateIocOutboundUrlActionAction;
class UpdateIocOutboundSeverityAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationCrowdstrikeActionTypes.UpdateIocOutboundSeverity;
    }
}
exports.UpdateIocOutboundSeverityAction = UpdateIocOutboundSeverityAction;
class UpdateIocOutboundExpirationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationCrowdstrikeActionTypes.UpdateIocOutboundExpiration;
    }
}
exports.UpdateIocOutboundExpirationAction = UpdateIocOutboundExpirationAction;
class SelectIocOutboundActionAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationCrowdstrikeActionTypes.SelectIocOutboundAction;
    }
}
exports.SelectIocOutboundActionAction = SelectIocOutboundActionAction;
class SwitchCrowdstrikeRemediationRequiredAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationCrowdstrikeActionTypes.SwitchCrowdstrikeRemediationRequired;
    }
}
exports.SwitchCrowdstrikeRemediationRequiredAction = SwitchCrowdstrikeRemediationRequiredAction;
class CreateCrowdstrikeIntegrationAction {
    constructor() {
        this.type = IntegrationCrowdstrikeActionTypes.CreateCrowdstrikeIntegration;
    }
}
exports.CreateCrowdstrikeIntegrationAction = CreateCrowdstrikeIntegrationAction;
class CreateCrowdstrikeIntegrationSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationCrowdstrikeActionTypes.CreateCrowdstrikeIntegrationSuccess;
    }
}
exports.CreateCrowdstrikeIntegrationSuccessAction = CreateCrowdstrikeIntegrationSuccessAction;
class OpenCrowdstrikeIntegrationAction {
    constructor() {
        this.type = IntegrationCrowdstrikeActionTypes.OpenCrowdstrikeIntegration;
    }
}
exports.OpenCrowdstrikeIntegrationAction = OpenCrowdstrikeIntegrationAction;
class GetCrowdstrikeIntegrationCompleteAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationCrowdstrikeActionTypes.GetCrowdstrikeIntegrationComplete;
    }
}
exports.GetCrowdstrikeIntegrationCompleteAction = GetCrowdstrikeIntegrationCompleteAction;
class UpdateCrowdstrikeIntegrationAction {
    constructor() {
        this.type = IntegrationCrowdstrikeActionTypes.UpdateCrowdstrikeIntegration;
    }
}
exports.UpdateCrowdstrikeIntegrationAction = UpdateCrowdstrikeIntegrationAction;
class UpdateCrowdstrikeIntegrationSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationCrowdstrikeActionTypes.UpdateCrowdstrikeIntegrationSuccess;
    }
}
exports.UpdateCrowdstrikeIntegrationSuccessAction = UpdateCrowdstrikeIntegrationSuccessAction;
class VerifyAndAddEmailCrowdstrikeIntegrationNotificationsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationCrowdstrikeActionTypes.VerifyAndAddEmailCrowdstrikeIntegrationNotifications;
    }
}
exports.VerifyAndAddEmailCrowdstrikeIntegrationNotificationsAction = VerifyAndAddEmailCrowdstrikeIntegrationNotificationsAction;
class VerifyAndAddEmailCrowdstrikeIntegrationNotificationsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationCrowdstrikeActionTypes.VerifyAndAddEmailCrowdstrikeIntegrationNotificationsSuccess;
    }
}
exports.VerifyAndAddEmailCrowdstrikeIntegrationNotificationsSuccessAction = VerifyAndAddEmailCrowdstrikeIntegrationNotificationsSuccessAction;
class VerifyAndAddEmailCrowdstrikeIntegrationNotificationsFailAction {
    constructor() {
        this.type = IntegrationCrowdstrikeActionTypes.VerifyAndAddEmailCrowdstrikeIntegrationNotificationsFail;
    }
}
exports.VerifyAndAddEmailCrowdstrikeIntegrationNotificationsFailAction = VerifyAndAddEmailCrowdstrikeIntegrationNotificationsFailAction;
class DeleteEmailCrowdstrikeIntegrationNotificationsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationCrowdstrikeActionTypes.DeleteEmailCrowdstrikeIntegrationNotifications;
    }
}
exports.DeleteEmailCrowdstrikeIntegrationNotificationsAction = DeleteEmailCrowdstrikeIntegrationNotificationsAction;
class AddGroupCrowdstrikeIntegrationNotificationsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationCrowdstrikeActionTypes.AddGroupCrowdstrikeIntegrationNotifications;
    }
}
exports.AddGroupCrowdstrikeIntegrationNotificationsAction = AddGroupCrowdstrikeIntegrationNotificationsAction;
class DeleteGroupCrowdstrikeIntegrationNotificationsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationCrowdstrikeActionTypes.DeleteGroupCrowdstrikeIntegrationNotifications;
    }
}
exports.DeleteGroupCrowdstrikeIntegrationNotificationsAction = DeleteGroupCrowdstrikeIntegrationNotificationsAction;
class OpenCrowdstrikeActivityLogPageAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationCrowdstrikeActionTypes.OpenCrowdstrikeActivityLogPage;
    }
}
exports.OpenCrowdstrikeActivityLogPageAction = OpenCrowdstrikeActivityLogPageAction;
class GetCrowdstrikeActivityLogsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationCrowdstrikeActionTypes.GetCrowdstrikeActivityLogs;
    }
}
exports.GetCrowdstrikeActivityLogsAction = GetCrowdstrikeActivityLogsAction;
class GetCrowdstrikeActivityLogsCompleteAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationCrowdstrikeActionTypes.GetCrowdstrikeActivityLogsComplete;
    }
}
exports.GetCrowdstrikeActivityLogsCompleteAction = GetCrowdstrikeActivityLogsCompleteAction;
