"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AwarenessModule = exports.TemplateSetupPageComponent = exports.AwarenessListPageComponent = void 0;
const awareness_list_page_component_1 = require("./containers/awareness-list-page.component");
Object.defineProperty(exports, "AwarenessListPageComponent", { enumerable: true, get: function () { return awareness_list_page_component_1.AwarenessListPageComponent; } });
const template_setup_page_component_1 = require("./containers/template-setup-page/template-setup-page.component");
Object.defineProperty(exports, "TemplateSetupPageComponent", { enumerable: true, get: function () { return template_setup_page_component_1.TemplateSetupPageComponent; } });
class AwarenessModule {
}
exports.AwarenessModule = AwarenessModule;
