"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./roles-edit/roles-edit"); //account.roles-edit
require("./roles-external-admin-list/roles-external-admin-list"); //account.roles-external-admin-list
require("./roles-list/roles-list"); //account.roles-list
require("./roles-member-list/roles-member-list"); //account.roles-member-list
(function () {
    'use strict';
    angular.module('account.roles', [
        'account.roles-edit',
        'account.roles-external-admin-list',
        'account.roles-list',
        'account.roles-member-list'
    ]);
}());
