"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueueService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const queue_type_enum_1 = require("app-new/archive/supervision/types/queue-type.enum");
const search_reducer_1 = require("app-new/archive-viewer/reducers/search.reducer");
class QueueService {
    constructor(http) {
        this.http = http;
    }
    createQueue(data) {
        return this.http
            .post('/api/supervision/config/create-queue', this.transformToQueueConfig(data))
            .pipe(operators_1.map((response) => {
            return response;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    updateQueue(queueId, data) {
        return this.http.post('/api/supervision/config/update-queue', Object.assign({ id: queueId }, data)).pipe(operators_1.map((response) => {
            return response;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    getEscalationQueue(pageSize) {
        return this.http
            .post('/api/supervision/config/find-queues', {
            meta: { pagination: { pageSize } },
            filterBy: [
                {
                    value: 'escalation',
                    fieldName: 'type'
                }
            ]
        })
            .pipe(operators_1.map((response) => {
            return response;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    getReviewers(filter, pagination = search_reducer_1.defaultPaginationOption) {
        return this.http
            .post('/api/supervision/config/find-reviewers', {
            meta: { pagination },
            searchBy: {
                value: filter || '',
                fieldName: 'email_address'
            }
        })
            .pipe(operators_1.map((response) => {
            return response;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    getReviewersNew(token) {
        return this.http.post('/api/supervision/config/find-reviewers', {
            meta: {
                pagination: {
                    pageToken: token
                }
            }
        });
    }
    getReviewersPaginationNew(token) {
        {
            return this.getReviewersNew(token).pipe(operators_1.map((response) => {
                return response;
            }));
        }
    }
    transformToQueueConfig(wizardData) {
        const newQueue = {
            name: wizardData.steps[1] && wizardData.steps[1].data['name'],
            description: wizardData.steps[1].data['description'],
            queueType: wizardData.steps[1].data['qtype'],
            labels: wizardData.steps[2].data['labels'].map(label => label.name),
            reviewers: wizardData.steps[2].data['reviewers'].map(reviewer => reviewer.reviewer.id)
        };
        newQueue.forwardAllowed = wizardData.steps[2].data['allowForwarding'];
        if (wizardData.steps[1].data['qtype'] === queue_type_enum_1.QueueType.ESCALATION) {
            newQueue.printAllowed = wizardData.steps[2].data['allowPrinting'];
        }
        if (wizardData.steps[1].data['qtype'] === queue_type_enum_1.QueueType.REVIEW) {
            newQueue.escalationQueues = wizardData.steps[1].data['escalationQueues'].map(queue => queue.id);
            newQueue.defaultEscalationQueue = wizardData.steps[1].data['defaultEscalationQueue'];
        }
        return newQueue;
    }
    getApiErrorTranslateKey(apiErrorCode, isUpdate = false) {
        switch (apiErrorCode.toUpperCase()) {
            case 'ERR_QUEUE_RETRIEVAL_FAILURE':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.NOTIFICATION.ERR_QUEUE_RETRIEVAL_FAILURE';
            case 'ERR_QUEUE_UPDATE_FAILURE':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.NOTIFICATION.ERR_QUEUE_UPDATE_FAILURE';
            case 'ERR_QUEUE_CREATION_FAILURE':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.NOTIFICATION.ERR_QUEUE_CREATION_FAILURE';
            case 'ERR_QUEUE_INVALID_ID':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.NOTIFICATION.ERR_QUEUE_INVALID_ID';
            case 'ERR_QUEUE_ID_NOT_VALID_FOR_USER':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.NOTIFICATION.ERR_QUEUE_ID_NOT_VALID_FOR_USER';
            case 'ERR_QUEUE_NOT_FOUND':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.NOTIFICATION.ERR_QUEUE_NOT_FOUND';
            case 'ERR_INVALID_LABEL_SIZE':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.NOTIFICATION.ERR_INVALID_LABEL_SIZE';
            case 'ERR_ESCALATION_QUEUE_NOT_FOUND':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.NOTIFICATION.ERR_ESCALATION_QUEUE_NOT_FOUND';
            case 'ERR_INVALID_PRINT_ALLOWED':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.NOTIFICATION.ERR_INVALID_PRINT_ALLOWED';
            case 'ERR_ESCALATION_QUEUE_MANDATORY_IF_DEFAULT_QUEUE_PRESENT':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.NOTIFICATION.ERR_ESCALATION_QUEUE_MANDATORY_IF_DEFAULT_QUEUE_PRESENT';
            case 'ERR_DEFAULT_ESCALATION_QUEUE_MANDATORY':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.NOTIFICATION.ERR_DEFAULT_ESCALATION_QUEUE_MANDATORY';
            case 'ERR_QUEUE_INVALID_REQUEST':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.NOTIFICATION.ERR_QUEUE_INVALID_REQUEST';
            case 'ERR_QUEUE_NOT_EXPECTED':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.NOTIFICATION.ERR_QUEUE_NOT_EXPECTED';
            case 'ERR_INVALID_DEFAULT_ESCALATION_QUEUE':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.NOTIFICATION.ERR_INVALID_DEFAULT_ESCALATION_QUEUE';
            case 'ERR_QUEUE_CONFIG_ID_NOT_VALID_FOR_USER':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.NOTIFICATION.ERR_QUEUE_CONFIG_ID_NOT_VALID_FOR_USER';
            case 'ERR_TOO_MANY_REVIEWERS':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.NOTIFICATION.ERR_TOO_MANY_REVIEWERS';
            case 'ERR_TOO_MANY_QUEUES':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.NOTIFICATION.ERR_TOO_MANY_QUEUES';
            case 'ERR_VALIDATION_NULL_QUEUETYPE':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.NOTIFICATION.ERR_VALIDATION_NULL_QUEUETYPE';
            case 'ERR_VALIDATION_BLANK_QUEUETYPE':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.NOTIFICATION.ERR_VALIDATION_BLANK_QUEUETYPE';
            case 'ERR_VALIDATION_OVER_MAX_LABELS':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.NOTIFICATION.ERR_VALIDATION_OVER_MAX_LABELS';
            case 'ERR_VALIDATION_OVER_MAX_REVIEWERS':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.NOTIFICATION.ERR_VALIDATION_OVER_MAX_REVIEWERS';
            case 'ERR_VALIDATION_OVER_MAX_ESCALATIONQUEUES':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.NOTIFICATION.ERR_VALIDATION_OVER_MAX_ESCALATIONQUEUES';
            case 'ERR_QUEUE_CLOSURE_FAILURE':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.NOTIFICATION.ERR_QUEUE_CLOSURE_FAILURE';
            case 'ERR_QUEUE_SEARCH_FAILURE':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.NOTIFICATION.ERR_QUEUE_SEARCH_FAILURE';
            case 'ERR_QUEUE_EXPORT_FAILURE':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.NOTIFICATION.ERR_QUEUE_EXPORT_FAILURE';
            case 'ERR_INVALID_MAX_LABEL_SIZE':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.NOTIFICATION.ERR_INVALID_MAX_LABEL_SIZE';
            case 'ERR_INVALID_MIN_LABEL_SIZE':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.NOTIFICATION.ERR_INVALID_MIN_LABEL_SIZE';
            case 'ERR_INVALID_LABEL_DUPLICATES':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.NOTIFICATION.ERR_INVALID_LABEL_DUPLICATES';
            case 'ERR_QUEUE_GETTING_LABELS_FAILURE':
                return '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.NOTIFICATION.ERR_QUEUE_GETTING_LABELS_FAILURE';
            default:
                return isUpdate
                    ? '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.NOTIFICATION.UPDATE_FAILURE'
                    : '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.NOTIFICATION.FAILURE';
        }
    }
    mapAppliesToResponse(response) {
        return {
            data: response.first,
            meta: response.meta,
            failures: response.fail,
            hasErrors: response.hasErrors
        };
    }
}
exports.QueueService = QueueService;
QueueService.WizardId = 'archive-supervision-wizard-queue';
