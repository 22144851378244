"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasErrors = exports.isApiProcessing = exports.isEditing = exports.getExceptionItem = exports.reducer = exports.initialState = void 0;
const swg_exceptions_actions_1 = require("../actions/swg-exceptions.actions");
const swg_exceptions_sidebar_modal_actions_1 = require("../actions/swg-exceptions-sidebar-modal.actions");
exports.initialState = {
    exception: null,
    isEditing: false,
    isApiProcessing: false,
    hasErrors: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case swg_exceptions_sidebar_modal_actions_1.SwgExceptionsSidebarModalActions.SWG_EXCEPTIONS_SIDEBAR_OPEN:
        case swg_exceptions_sidebar_modal_actions_1.SwgExceptionsSidebarModalActions.SWG_EXCEPTIONS_DELETE_CONFIRM_MODAL_OPEN:
            return Object.assign(Object.assign({}, state), { exception: action.payload, isApiProcessing: false, isEditing: !!action.payload, hasErrors: false });
        case swg_exceptions_actions_1.SwgExceptionsActions.SWG_ADD_EXCEPTION:
            return Object.assign(Object.assign({}, state), { isApiProcessing: true, isEditing: false, hasErrors: false });
        case swg_exceptions_actions_1.SwgExceptionsActions.SWG_UPDATE_EXCEPTION:
            return Object.assign(Object.assign({}, state), { exception: action.payload, isApiProcessing: true, isEditing: true, hasErrors: false });
        case swg_exceptions_actions_1.SwgExceptionsActions.SWG_DELETE_EXCEPTION:
            return Object.assign(Object.assign({}, state), { exception: action.payload, isApiProcessing: true, isEditing: false, hasErrors: false });
        case swg_exceptions_actions_1.SwgExceptionsActions.SWG_UPDATE_EXCEPTION_SUCCESS:
            return Object.assign(Object.assign({}, state), { isApiProcessing: false, isEditing: false, hasErrors: false });
        case swg_exceptions_actions_1.SwgExceptionsActions.SWG_UPDATE_EXCEPTION_FAILURE:
            return Object.assign(Object.assign({}, state), { isApiProcessing: false, isEditing: false, hasErrors: true });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getExceptionItem = (state) => state.exception;
exports.getExceptionItem = getExceptionItem;
const isEditing = (state) => state.isEditing;
exports.isEditing = isEditing;
const isApiProcessing = (state) => state.isApiProcessing;
exports.isApiProcessing = isApiProcessing;
const hasErrors = (state) => state.hasErrors;
exports.hasErrors = hasErrors;
