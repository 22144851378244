<mc-layout-aside-extra-container keyTitle="$I18N_IDENTITY_APP_DETAILS_ASIDE_PANEL.TITLE"
                                 showClose="true" (closeAside)="close()"
                                 [isLoading]="isLoading$ | async">
    <mc-extra-container>
        <div *ngIf="selectedApp$ | async; let row">
            <h3 class="mc-panel-title-name">
                {{ (selectedApp$ | async).name }}
            </h3>

            <button *ngIf="!(isEditMode$ | async)"
                    mc-caps="Permission.ID_MANAGEMENT_EDIT"
                    class="btn btn-primary panel-half-margin-right mc-identity-app-edit"
                    (click)="onEdit()">
                {{ '$I18N_IDENTITY_APP_DETAILS_ASIDE_PANEL.BUTTONS.EDIT' | translate }}
            </button>

            <mc-live-button *ngIf="isEditMode$ | async"
                            id="mc-identity-app-update-btn"
                            class="mc-identity-app-update"
                            overrideClasses="btn btn-primary panel-half-margin-right"
                            [label]="'$I18N_IDENTITY_APP_DETAILS_ASIDE_PANEL.BUTTONS.SAVE' | translate"
                            [isLoading]="isUpdateAppApiProcessing$ | async"
                            (mcClick)="updateApp()"
                            [mcDisabled]="!(isDetailsFormValid$ | async) || (isSaveBtnDisabled$ | async)">
            </mc-live-button>

            <!-- COMMENTED UNTIL POST-PREVIEW -->
            <!--<button *ngIf="!(isEditMode$ | async)"-->
            <!--class="btn btn-secondary panel-half-margin-right"-->
            <!--(click)="deleteApp(row)">-->
            <!--{{'$I18N_COMMON_BTN_DELETE' | translate}}-->
            <!--</button>-->

            <button *ngIf="isEditMode$ | async"
                    class="btn mc-app-details-cancel"
                    (click)="cancel();">
                {{'$I18N_COMMON_BTN_CANCEL' | translate}}
            </button>
        </div>
    </mc-extra-container>

    <mc-body-container>
        <mc-identity-app-details
                *ngIf="selectedApp$ | async"
                [app]="selectedApp$ | async"
                [isEditMode]="isEditMode$ | async"
                (update)="onUpdate($event)"
                (valid)="onValid($event)"
                (edit)="onEdit()">
        </mc-identity-app-details>
    </mc-body-container>

</mc-layout-aside-extra-container>
