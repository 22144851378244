<div class="mc-api-gateway-step-block">
  <mc-wizard-step-header
    [label]="'$I18N_API_GATEWAY_V3_WIZARD.CREATE.SUMMARY_STEP.HEADER' | translate"
  >
  </mc-wizard-step-header>

  <div class="mc-detailed-list mc-api-gateway-summary-block">
    <div class="row mc-detailed-list-row panel-padding-bottom">
      <div class="col-xs-4">
        <strong>{{ '$I18N_API_GATEWAY_V3_WIZARD.CREATE.DETAILS_STEP.TITLE' | translate }}</strong>
      </div>
      <div class="mc-detailed-list-column">
        <a class="btn-link cursor-pointer pull-right" (click)="gotoDetails()">{{
          '$I18N_API_GATEWAY_V3_WIZARD.ACTIONS.EDIT' | translate
          }}</a>
      </div>
    </div>

    <div class="row mc-detailed-list-row panel-padding-bottom">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_API_GATEWAY_V3_WIZARD.FIELDS.APPLICATION_NAME' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ application.applicationName }}</div>
    </div>

    <div *mcCapabilities="'Temporary.Services.ApiApplications.Apigee.Mimecast.All.Categories'" class="row mc-detailed-list-row panel-padding-bottom">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_API_GATEWAY_V3_WIZARD.FIELDS.CATEGORY' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ application.category | mcApplicationCategory }}</div>
    </div>

    <div *mcCapabilities="'Temporary.Services.ApiApplications.Apigee.Mimecast.All.Products'" class="row mc-detailed-list-row panel-padding-bottom">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_API_GATEWAY_V3_WIZARD.FIELDS.PRODUCTS' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ productsName }}</div>
    </div>

    <div class="row mc-detailed-list-row panel-padding-bottom">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_API_GATEWAY_V3_WIZARD.FIELDS.APPLICATION_ROLE' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ roleName }}</div>
    </div>

    <div class="row mc-detailed-list-row panel-padding-bottom">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_API_GATEWAY_V3_WIZARD.FIELDS.DESCRIPTION' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        <mc-text-block-display [textblock]="application.description"></mc-text-block-display>
      </div>
    </div>

    <div class="row mc-detailed-list-row panel-padding-bottom panel-padding-top">
      <div class="col-xs-4">
        <strong>{{
          '$I18N_API_GATEWAY_V3_WIZARD.CREATE.SETTINGS_STEP.TITLE' | translate
          }}</strong>
      </div>
      <div class="mc-detailed-list-column">
        <a class="btn-link cursor-pointer pull-right" (click)="gotoSettings()">{{
          '$I18N_API_GATEWAY_V3_WIZARD.ACTIONS.EDIT' | translate
          }}</a>
      </div>
    </div>

    <div class="row mc-detailed-list-row panel-padding-bottom">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_API_GATEWAY_V3_WIZARD.FIELDS.TECHNICAL_POINT_OF_CONTACT' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ application.developerName }}</div>
    </div>

    <div class="row mc-detailed-list-row panel-padding-bottom">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_API_GATEWAY_V3_WIZARD.FIELDS.DEVELOPER_EMAIL' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ application.developerEmail }}</div>
    </div>

  </div>
</div>
