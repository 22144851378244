<div>
  <div *ngIf="currentDeviceType !== deviceType.IOS" class="mc-detailed-list mc-sidepanel-endpoints-details"
       [class.mc-detailed-list-two-columns]="true">
    <mc-collapsable-panel header="$I18N_ENDPOINTS_LIST.SIDEBAR.DETAILS_TAB.MSA_STATUS_SECTION" [isExpanded]="true">
 <div  class="mc-status-section">
   <div *ngIf="!showMSAStatusSectionImages" class="mc-status-section-notification">
     <mc-inline-notification  [notification]="infoBoxNotification"> </mc-inline-notification>
   </div>
   <div *ngIf="showMSAStatusSectionImages">
     <div class="mc-status-box">
       <div class="mc-status-box-image-contents">
         <img src="/administration/resources/assets/dns_server.e33787f966636c76646f54bbfbce6304.png">
         <img [hidden]="!row.protectedDeviceDetail.dnsServerStatus" src="/administration/resources/assets/on.862598e5e80f50b15ea0ca921329c82b.png" class="mc-overlap-image">
         <img [hidden]="row.protectedDeviceDetail.dnsServerStatus" src="/administration/resources/assets/off.9af172492e2aec28470992e500a8e91b.png" class="mc-overlap-image">
       </div>
       <div class="mc-status-box-text-contents">
         <span class="mc-image-text" translate="$I18N_ENDPOINTS_LIST.SIDEBAR.DETAILS_TAB.DNS"></span>
         <span translate="$I18N_ENDPOINTS_LIST.SIDEBAR.DETAILS_TAB.SERVER"></span>
       </div>
     </div>
     <div class="mc-status-box">
       <div class="mc-status-box-image-contents">
         <img src="/administration/resources/assets/anti_tamper_password.1347eda3aef44fe28a3444f547def797.png">
         <img [hidden]="!row.protectedDeviceDetail.antiTamperPasswordStatus" src="/administration/resources/assets/on.862598e5e80f50b15ea0ca921329c82b.png" class="mc-overlap-image">
         <img [hidden]="row.protectedDeviceDetail.antiTamperPasswordStatus" src="/administration/resources/assets/off.9af172492e2aec28470992e500a8e91b.png" class="mc-overlap-image">
       </div>
       <div class="mc-status-box-text-contents">
         <span class="mc-image-text" translate="$I18N_ENDPOINTS_LIST.SIDEBAR.DETAILS_TAB.SYNCHRONIZED"></span>
         <span translate="$I18N_ENDPOINTS_LIST.SIDEBAR.DETAILS_TAB.PASSWORD"></span>
       </div>
     </div>
     <div class="mc-status-box">
       <div class="mc-status-box-image-contents">
         <img src="/administration/resources/assets/policy.1d4990330d5182eeb648ca171456fd8b.png">
         <img [hidden]="!row.protectedDeviceDetail.policyStatus" src="/administration/resources/assets/on.862598e5e80f50b15ea0ca921329c82b.png" class="mc-overlap-image">
         <img [hidden]="row.protectedDeviceDetail.policyStatus" src="/administration/resources/assets/off.9af172492e2aec28470992e500a8e91b.png" class="mc-overlap-image">
       </div>
       <div class="mc-status-box-text-contents">
         <span class="mc-image-text" translate="$I18N_ENDPOINTS_LIST.SIDEBAR.DETAILS_TAB.SYNCHRONIZED"></span>
         <span translate="$I18N_ENDPOINTS_LIST.SIDEBAR.DETAILS_TAB.SETTINGS"></span>
       </div>
     </div>
   </div>

 </div>

    </mc-collapsable-panel>

  </div>
</div>
<hr *ngIf="currentDeviceType !== deviceType.IOS">

<div class="mc-detailed-list mc-sidepanel-endpoints-details"
     [class.mc-detailed-list-two-columns]="true">
  <div>
    <mc-collapsable-panel header="$I18N_ENDPOINTS_LIST.SIDEBAR.DETAILS_TAB.DEVICE_SPECIFICATION_SECTION" [isExpanded]="true">
      <mc-value-field label="$I18N_ENDPOINTS_LIST.TABLE.COLUMNS.DEVICENAME"
                      [value]="row.deviceName">
      </mc-value-field>
      <mc-value-field *ngIf="currentDeviceType !== deviceType.IOS" label="$I18N_ENDPOINTS_LIST.TABLE.COLUMNS.PROCESSOR"
                      [value]="row.protectedDeviceDetail.processor">
      </mc-value-field>
      <mc-value-field *ngIf="currentDeviceType !== deviceType.IOS" label="$I18N_ENDPOINTS_LIST.TABLE.COLUMNS.INSTALLEDRAM"
                      [value]="row.protectedDeviceDetail.installedRam | mcToBytes">
      </mc-value-field>
      <mc-value-field label="$I18N_ENDPOINTS_LIST.TABLE.COLUMNS.DEVICEID"
                      [value]="row.deviceId">
      </mc-value-field>
      <mc-value-field *ngIf="currentDeviceType !== deviceType.IOS && currentDeviceType !== deviceType.MAC" label="$I18N_ENDPOINTS_LIST.TABLE.COLUMNS.OSMODEL"
                      [value]="row.protectedDeviceDetail.osModel">
      </mc-value-field>
    </mc-collapsable-panel>
  </div>

</div>
<hr>

<div class="mc-detailed-list mc-sidepanel-endpoints-details"
     [class.mc-detailed-list-two-columns]="true">
  <div>
    <mc-collapsable-panel header="$I18N_ENDPOINTS_LIST.SIDEBAR.DETAILS_TAB.OS_SPECIFICATION_SECTION" [isExpanded]="true">
      <mc-value-field label="$I18N_ENDPOINTS_LIST.TABLE.COLUMNS.OSEDITION"
        value="{{row.protectedDeviceDetail.osEdition ? row.protectedDeviceDetail.osEdition :  (row.os | mcSwgEndpointsListMapOs) + ' ' + (row | mcSwgEndpointsListMapOsVersion) }}">
      </mc-value-field>

      <mc-value-field *ngIf="currentDeviceType !== deviceType.IOS && currentDeviceType !== deviceType.MAC"
        label="$I18N_ENDPOINTS_LIST.TABLE.COLUMNS.OSINSTALLED"
        value="{{ row.protectedDeviceDetail.osInstalled | date : dateFormat : timezoneOffset}}">
      </mc-value-field>

      <mc-value-field *ngIf="currentDeviceType !== deviceType.IOS" label="$I18N_ENDPOINTS_LIST.TABLE.COLUMNS.OSBUILD"
        [value]="row.protectedDeviceDetail.osBuild">
      </mc-value-field>
    </mc-collapsable-panel>
  </div>

</div>
<hr>

<div *ngIf="currentDeviceType !== deviceType.IOS" class="mc-detailed-list mc-sidepanel-endpoints-details"
     [class.mc-detailed-list-two-columns]="true">
  <div>
    <mc-collapsable-panel header="$I18N_ENDPOINTS_LIST.SIDEBAR.DETAILS_TAB.NETWORK_DETAILS_SECTION" [isExpanded]="true">
      <mc-value-field label="$I18N_ENDPOINTS_LIST.TABLE.COLUMNS.PRIVATEIP"
                      [value]="row.privateIp">
      </mc-value-field>
      <mc-value-field label="$I18N_ENDPOINTS_LIST.TABLE.COLUMNS.SUBNETMASK"
                      [value]="row.protectedDeviceDetail.subnetMask">
      </mc-value-field>

      <mc-value-field label="$I18N_ENDPOINTS_LIST.TABLE.COLUMNS.DEFAULTGATEWAY"
                      [value]="row.protectedDeviceDetail.defaultGateway">
      </mc-value-field>

      <mc-value-field label="$I18N_ENDPOINTS_LIST.TABLE.COLUMNS.MACADDRESS"
                      [value]="row.protectedDeviceDetail.macAddress">
      </mc-value-field>
    </mc-collapsable-panel>
  </div>
  <hr>
</div>

