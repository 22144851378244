"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgPolicyNewDomainsStepComponent = void 0;
const core_1 = require("@angular/core");
const operators_1 = require("rxjs/operators");
class SwgPolicyNewDomainsStepComponent {
    constructor(fb) {
        this.fb = fb;
        this.updateNewDomainsSettings = new core_1.EventEmitter();
    }
    ngOnInit() {
        this.newDomainsSettingsForm = this.fb.group({
            checkNod: this.newDomainsSettings.checkNod
        });
        this.subscription = this.newDomainsSettingsForm.valueChanges
            .pipe(operators_1.debounceTime(200))
            .subscribe(() => {
            this.updateSettings();
        });
    }
    updateSettings() {
        this.updateNewDomainsSettings.emit(this.newDomainsSettingsForm.value);
    }
    isValid() {
        return true;
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
exports.SwgPolicyNewDomainsStepComponent = SwgPolicyNewDomainsStepComponent;
