<mc-layout-list-table keyTitle="$I18N_SWG_LOCATIONS_LIST.PAGE_HEADER"
                      keyDescription="$I18N_SWG_LOCATIONS_LIST.PAGE_DESC">
  <mc-help-container>
    <mc-help-link url="https://community.mimecast.com/s/article/Mimecast-Web-Security-Configuring-Locations-412604715"></mc-help-link>
  </mc-help-container>
  <mc-table
    class="mc-swg-locations-list"
    [isLoading]="isLoading$ | async"
    [data]="tableData$ | async"
    [columns]="columns"
    [showHighlightedRow]="(isEditable$ | async)"
    (rowClick)="onRowClick($event)"
    translatePrefix="$I18N_SWG_LOCATIONS_LIST.TABLE">

    <button class="mc-table-actions btn btn-primary mc-export-button"
            (click)="addLocation()"
            *ngIf="(isEditable$ | async)">
      {{ '$I18N_SWG_LOCATIONS_LIST.ADD_A_LOCATION' | translate }}
    </button>

    <mc-filters
      (paginatorChange)="onPaginationChange($event)"
      [metadata]="metaData$ | async"
      [showPagination]="true">

      <mc-filter-search
        (search)="onSearch($event)"
        (clear)="onSearchClear()"
        [options]="filterSearchOptions"
      >
      </mc-filter-search>
    </mc-filters>

    <mc-column-actions key="action" mcShowColumnConfig>
      <div *ngIf="(isEditable$ | async)">
        <mc-row-actions *mcRowActions="let row">
          <li mcRowAction="$I18N_COMMON_BTN_EDIT" (click)="editLocation(row)" *ngIf="(isEditable$ | async)"></li>
          <li mcRowAction="$I18N_COMMON_BTN_DELETE" (click)="deleteLocation(row)" *ngIf="(isEditable$ | async)"></li>
        </mc-row-actions>
      </div>
    </mc-column-actions>

    <mc-empty-results keyTitle="$I18N_SWG_LOCATIONS_LIST.TABLE.NO_DATA">
      <mc-empty-body *ngIf="(isEditable$ | async)">
        {{'$I18N_SWG_LOCATIONS_LIST.TABLE.NO_DATA_BODY_PART_1' | translate}}
        <button class="btn btn-link mc-empty-body-link" (click)="addLocation()">
          {{'$I18N_SWG_LOCATIONS_LIST.TABLE.NO_DATA_BODY_PART_2' | translate}}
        </button>
        {{'$I18N_SWG_LOCATIONS_LIST.TABLE.NO_DATA_BODY_PART_3' | translate}}
      </mc-empty-body>
      <mc-empty-body *ngIf="!(isEditable$ | async)">
        {{'$I18N_SWG_LOCATIONS_LIST.TABLE.NO_DATA_BODY_READ_ONLY' | translate}}
      </mc-empty-body>
    </mc-empty-results>

  </mc-table>
</mc-layout-list-table>
