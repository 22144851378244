"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DropOrReleaseConfirmationModalComponent = void 0;
class DropOrReleaseConfirmationModalComponent {
    constructor(overlayRef, overlayData) {
        this.overlayRef = overlayRef;
        this.overlayData = overlayData;
    }
    ngOnInit() {
        const action = this.overlayData.action;
        this.messageKey = `$18N_MEP_LOG_ADMIN_ACTION.COMFIRMATION_MODAL.CONFIRM_MESSAGE_${action.toUpperCase()}`;
        this.primaryBtnKey = `$18N_MEP_LOG_ADMIN_ACTION.COMFIRMATION_MODAL.BTN_${action.toUpperCase()}`;
    }
    close(confirmed) {
        this.overlayRef.close(confirmed);
    }
}
exports.DropOrReleaseConfirmationModalComponent = DropOrReleaseConfirmationModalComponent;
