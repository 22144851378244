"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReleaseLogMessageComponent = void 0;
class ReleaseLogMessageComponent {
    toAddresses(addresses) {
        if (!!addresses) {
            return addresses.map(address => address.emailAddress);
        }
        else {
            return [];
        }
    }
}
exports.ReleaseLogMessageComponent = ReleaseLogMessageComponent;
