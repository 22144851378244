<mc-layout-detail-wizard keyTitle="{{ getPageTitle() }}" extendClass="panel-padding-bottom">

  <mc-wizard data-unit-test="wizard" (changeStep)="goToStep($event)">
    <div tabindex="0" #focusToTop></div>
    <mc-wizard-step data-unit-test="details-step" label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.STEP_LABEL">
      <mc-url-protection-policy-details-step [policyDetailsData]="detailsStepData$ | async"
        [isAAAOrFAAUser]="isAAAOrFAAUser" [isEditMode]="isEditMode" [wizardFlowType]="wizardFlowType">
      </mc-url-protection-policy-details-step>
    </mc-wizard-step>

    <ng-container *ngIf="bypassFlowIsDisabled">
      <mc-wizard-step
        data-unit-test="inbound-step"  
        label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.STEP_LABEL">
        <mc-url-protection-inbound-settings-step
          [isAAAOrFAAUser]="isAAAOrFAAUser"
          [policyInboundData]="inboundStepData$ | async"
          [hasBrowserIsolation]="hasBrowserIsolation">
        </mc-url-protection-inbound-settings-step>
      </mc-wizard-step>

      <mc-wizard-step data-unit-test="outbound-step" *ngIf="hasIEPPackage || isAAAOrFAAUser"
        label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.STEP_LABEL">
        <mc-url-protection-outbound-settings-step
          [policyOutboundData]="outboundStepData$ | async"
          [isAAAOrFAAUser]="isAAAOrFAAUser">
        </mc-url-protection-outbound-settings-step>
      </mc-wizard-step>

      <mc-wizard-step data-unit-test="journal-step" *ngIf="hasIEPPackage || isAAAOrFAAUser"
        label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.STEP_LABEL">
        <mc-url-protection-journal-settings-step
          [policyJournalData]="journalStepData$ | async"
          [isAAAOrFAAUser]="isAAAOrFAAUser">
        </mc-url-protection-journal-settings-step>
      </mc-wizard-step>

      <mc-wizard-step data-unit-test="user-awareness-step"
        label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.USER_AWARENESS_SETTINGS.STEP_LABEL">
        <mc-url-protection-policy-user-awareness-settings-step
          [policyUserAwarenessData]="userAwarenessStepData$ | async"
          [isAAAOrFAAUser]="isAAAOrFAAUser">
        </mc-url-protection-policy-user-awareness-settings-step>
      </mc-wizard-step>
    </ng-container>

    <mc-wizard-step data-unit-test="applies-to-step"
      label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.STEP_LABEL">
      <mc-url-protection-applies-to-settings-step
        [fromToWhereAttributes]="(fromToWhereAttributes$ | async).fromToWhereAttributes"
        [policyAppliesToData]="appliesToStepData$ | async" [isAAAorFAAUser]="isAAAOrFAAUser"
        (fromToWhereAttributesSelected)="onAddressAttributeSelection($event)">
      </mc-url-protection-applies-to-settings-step>
    </mc-wizard-step>

    <mc-wizard-step data-unit-test="summary-step" label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.SUMMARY.STEP_LABEL">
      <mc-url-protection-policy-summary-step
        [policySummaryData]="summaryData$ | async"
        [isAAAOrFAAUser]="isAAAOrFAAUser"
        [hasIEPPackage]="hasIEPPackage"
        [hasBrowserIsolation]="hasBrowserIsolation"
        (onEditStep)=editStep($event)>
      </mc-url-protection-policy-summary-step>
    </mc-wizard-step>

  </mc-wizard>
  <mc-footer-container>
    <div class="pull-right no-select mc-button-footer">
      <button class="btn cancel-btn" (click)="onCancel()">{{ '$I18N_COMMON_BTN_CANCEL' | translate }}</button>
      <ng-container *ngIf="wizard">
        <button class="btn btn-secondary panel-half-margin-right" *ngIf="wizard.hasPreviousStep$ | async"
          (click)="handleWizardNavigationClick(navigation.back)">
          {{ '$I18N_COMMON_BTN_PREVIOUS' | translate }}
        </button>
        <button class="btn" [class.btn-primary]="!isEditMode" [class.btn-secondary]="isEditMode"
          [disabled]="!isStepValid()" *ngIf="wizard.hasNextStep$ | async" (click)="handleWizardNavigationClick(navigation.next)">
          {{ '$I18N_COMMON_BTN_NEXT' | translate }}
        </button>
        <ng-container *ngIf="!isEditMode">
          <mc-live-button *ngIf="wizard.isLastStep$ | async" overrideClasses="btn-primary"
            [label]="'$I18N_URL_PROTECTION_POLICY_WIZARD.FOOTER.CREATE' | translate" (click)="createPolicy()"
            [isLoading]="loading"></mc-live-button>
        </ng-container>
        <mc-live-button overrideClasses="btn-primary" *ngIf="isEditMode"
          [label]="'$I18N_URL_PROTECTION_POLICY_WIZARD.FOOTER.EDIT' | translate" (click)="handleWizardNavigationClick(navigation.edit)"
          [isLoading]="loading" [mcDisabled]="!isStepValid()"></mc-live-button>
      </ng-container>
    </div>
  </mc-footer-container>
</mc-layout-detail-wizard>
