"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddDomainsComponent = void 0;
const operators_1 = require("rxjs/operators");
const actions = require("../../../actions/add-domains.actions");
const fromChameleonState = require("../../../reducers");
class AddDomainsComponent {
    constructor(store) {
        this.store = store;
        this.setupObservables();
    }
    static convertArrayToTableData(domainNames$) {
        return domainNames$.pipe(operators_1.map(domainNames => {
            return domainNames.map(domain => {
                return { domainName: domain };
            });
        }));
    }
    setupObservables() {
        this.showDomainValidation$ = this.store.select(fromChameleonState.getShowDomainValidation);
        this.validDomains$ = AddDomainsComponent.convertArrayToTableData(this.store.select(fromChameleonState.getValidDomains));
        this.invalidDomains$ = AddDomainsComponent.convertArrayToTableData(this.store.select(fromChameleonState.getInvalidDomains));
        this.isCreatingDomains$ = this.store.select(fromChameleonState.getIsCreatingDomains);
    }
    onCloseAside() {
        this.store.dispatch(new actions.CloseAside());
    }
    onShowDomainValidation(formValues) {
        this.store.dispatch(new actions.ShowDomainsValidation(formValues));
    }
    onShowDomainsSettings() {
        this.store.dispatch(new actions.ShowDomainsSettings());
    }
    onRemoveInvalidDomain(data) {
        this.store.dispatch(new actions.RemoveInvalidDomain(data.domainName));
    }
    onRemoveValidDomain(data) {
        this.store.dispatch(new actions.RemoveValidDomain(data.domainName));
    }
    onCreateDomains() {
        this.store.dispatch(new actions.CreateDomains());
    }
}
exports.AddDomainsComponent = AddDomainsComponent;
