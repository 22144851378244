<mc-layout-list-extra-container
  keyTitle="$I18N_MESSAGE_CENTER_HELD_MESSAGES_CONTAINER.HEADER.TITLE"
  keyDescription="$I18N_MESSAGE_CENTER_HELD_MESSAGES_CONTAINER.HEADER.SUB_TITLE"
>
  <mc-help-container>
    <mc-help-link url="https://community.mimecast.com/docs/DOC-3379"></mc-help-link>
  </mc-help-container>

  <mc-body-container>
    <mc-held-messages-tabs-page> </mc-held-messages-tabs-page>
  </mc-body-container>
</mc-layout-list-extra-container>
