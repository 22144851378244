"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WizardBaseDirective = void 0;
const rxjs_1 = require("rxjs");
const wizardActions = require("../../actions/wizard.actions");
const selectors = require("../../reducers");
const model_1 = require("../../model");
class WizardBaseDirective {
    constructor(capabilitiesService, wizardId, store, translateService) {
        this.capabilitiesService = capabilitiesService;
        this.wizardId = wizardId;
        this.store = store;
        this.translateService = translateService;
        this.nextNavButtonClicked = new rxjs_1.BehaviorSubject(false);
        if (!wizardId) {
            throw new Error('wizardId is mandatory');
        }
    }
    ngOnInit() {
        this.isCurrentStepValid$ = this.store.select(selectors.isCurrentStepValid(this.wizardId));
        this.wizardData$ = this.store.select(selectors.getWizardById(this.wizardId));
        this.dataSubscription = this.wizardData$.subscribe(data => (this.wizardData = data));
    }
    ngOnDestroy() {
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }
        this.store.dispatch(new wizardActions.ClearWizardStateAction({ wizardId: this.wizardId }));
    }
    onCancel(tab) {
        this.store.dispatch(new wizardActions.CancelAction({
            title: this.getCancelConfirmationText(),
            wizardId: this.wizardId,
            tab,
            editFlow: this.editFlow
        }));
    }
    onStepChange(stepId) {
        if (!this.editFlow && this.wizardData && this.wizardData.steps) {
            const providerState = this.wizardData.steps[model_1.ConnectorStep.PROVIDER];
            if (providerState && providerState.data && providerState.data.provider) {
                const providerStep = model_1.OVERRIDE_STEP[providerState.data.provider.type];
                if (providerStep && providerStep[stepId]) {
                    stepId = providerStep[stepId];
                }
            }
        }
        else if (this.editFlow && stepId === 3) {
            stepId = model_1.ConnectorStep.DETAILS;
        }
        this.store.dispatch(new wizardActions.SetCurrentStepAction({ wizardId: this.wizardId, stepId }));
    }
    onNext() {
        this.nextNavButtonClicked.next(true);
        this.wizard.nextStep();
    }
    onPrevious() {
        this.wizard.previousStep();
    }
    isNextButtonClicked() {
        return this.nextNavButtonClicked.asObservable();
    }
    navigate(stepNo) {
        this.onStepChange(stepNo);
        this.wizard.showStep(stepNo);
    }
}
exports.WizardBaseDirective = WizardBaseDirective;
