"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgActivityReportSidebarClose = exports.SwgActivityReportSidebarOpen = exports.SwgActivityReportSidebarActionsEnum = void 0;
var SwgActivityReportSidebarActionsEnum;
(function (SwgActivityReportSidebarActionsEnum) {
    SwgActivityReportSidebarActionsEnum["SWG_ACTIVITY_REPORT_SIDEBAR_OPEN"] = "[Policy] Swg Activity report sidebar open";
    SwgActivityReportSidebarActionsEnum["SWG_ACTIVITY_REPORT_SIDEBAR_CLOSE"] = "[Policy] Swg Activity report sidebar close";
})(SwgActivityReportSidebarActionsEnum = exports.SwgActivityReportSidebarActionsEnum || (exports.SwgActivityReportSidebarActionsEnum = {}));
class SwgActivityReportSidebarOpen {
    constructor() {
        this.type = SwgActivityReportSidebarActionsEnum.SWG_ACTIVITY_REPORT_SIDEBAR_OPEN;
    }
}
exports.SwgActivityReportSidebarOpen = SwgActivityReportSidebarOpen;
class SwgActivityReportSidebarClose {
    constructor() {
        this.type = SwgActivityReportSidebarActionsEnum.SWG_ACTIVITY_REPORT_SIDEBAR_CLOSE;
    }
}
exports.SwgActivityReportSidebarClose = SwgActivityReportSidebarClose;
