<div class="mc-services-connector-wizard-step-container" *ngIf="!(isLoadingProviders$ | async); else spinner">
  <mc-wizard-step-header label="{{'$I18N_CREATE_CONNECTOR_WIZARD_PROVIDER_SELECTION_STEP.TITLE' | translate:{ product: product?.name } }}">
  </mc-wizard-step-header>

  <ng-container>
    <form novalidate [formGroup]="form" class="form-horizontal" mcDefaultLabelClass="col-sm-3"
      mcDefaultControlContainerClass="col-sm-9" *ngIf="(providers$ | async).length; else noProviders">
      <mc-provider-select (onClick)="onProviderSelect()" name="provider" formControlName="provider"
                          [configuredProviders]="providers$ | async" [isGwsProviderEnabled]="isGwsProviderEnabled">
      </mc-provider-select>
    </form>
  </ng-container>
</div>

<ng-template #noProviders>
  <mc-inline-error-notification [errorType]="errorType" (onReload)="loadProviders()">
  </mc-inline-error-notification>
</ng-template>

<ng-template #spinner>
  <div class="spinner-wrapper">
    <mc-spinner></mc-spinner>
  </div>
</ng-template>
