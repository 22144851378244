"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectedRow = exports.UpdateManagedUrlFail = exports.UpdateManagedUrlSuccessAction = exports.UpdateManagedUrlAttemptAction = exports.UpdateManagedUrlAction = exports.OpenManagedUrlDetailsAction = exports.SELECTED_ROW = exports.UPDATE_MANAGED_URL_FAIL = exports.UPDATE_MANAGED_URL_SUCCESS = exports.UPDATE_MANAGED_URL_ATTEMPT = exports.UPDATE_MANAGED_URL = exports.OPEN_MANAGED_URL_DETAILS = void 0;
exports.OPEN_MANAGED_URL_DETAILS = '[Services / Url protection / Managed URL] Open managed url details';
exports.UPDATE_MANAGED_URL = '[Services / Url protection / Managed URL] Update managed url';
exports.UPDATE_MANAGED_URL_ATTEMPT = '[Services / Url protection / Managed URL] Update managed url attempt';
exports.UPDATE_MANAGED_URL_SUCCESS = '[Services / Url protection / Managed URL] Update managed url success';
exports.UPDATE_MANAGED_URL_FAIL = '[Services / Url protection / Managed URL] Update managed url fail';
exports.SELECTED_ROW = '[Services / Url protection / Managed URL] Selected row';
class OpenManagedUrlDetailsAction {
    constructor(payload, isEditAllowed) {
        this.payload = payload;
        this.isEditAllowed = isEditAllowed;
        this.type = exports.OPEN_MANAGED_URL_DETAILS;
    }
}
exports.OpenManagedUrlDetailsAction = OpenManagedUrlDetailsAction;
class UpdateManagedUrlAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.UPDATE_MANAGED_URL;
    }
}
exports.UpdateManagedUrlAction = UpdateManagedUrlAction;
class UpdateManagedUrlAttemptAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.UPDATE_MANAGED_URL_ATTEMPT;
    }
}
exports.UpdateManagedUrlAttemptAction = UpdateManagedUrlAttemptAction;
class UpdateManagedUrlSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.UPDATE_MANAGED_URL_SUCCESS;
    }
}
exports.UpdateManagedUrlSuccessAction = UpdateManagedUrlSuccessAction;
class UpdateManagedUrlFail {
    constructor(err) {
        this.err = err;
        this.type = exports.UPDATE_MANAGED_URL_FAIL;
    }
}
exports.UpdateManagedUrlFail = UpdateManagedUrlFail;
class SelectedRow {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SELECTED_ROW;
    }
}
exports.SelectedRow = SelectedRow;
