"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgEndpointsListPurgeModalComponent = void 0;
class SwgEndpointsListPurgeModalComponent {
    constructor(overlayRef, translateService) {
        this.overlayRef = overlayRef;
        this.translateService = translateService;
        this.title = '';
        this.message = '';
    }
    ngOnChanges(changes) {
        if (changes.endpointsCount) {
            if (this.endpointsCount === 1) {
                this.title = this.translateService.instant('$I18N_ENDPOINTS_LIST.PURGE_MODAL.PURGE_ONE_TITLE');
                this.message = this.translateService.instant('$I18N_ENDPOINTS_LIST.PURGE_MODAL.PURGE_ONE_MESSAGE');
            }
            else {
                this.translateService
                    .get('$I18N_ENDPOINTS_LIST.PURGE_MODAL.PURGE_ALL_TITLE', {
                    number: this.endpointsCount
                })
                    .subscribe((response) => {
                    this.title = response;
                });
                this.message = this.translateService.instant('$I18N_ENDPOINTS_LIST.PURGE_MODAL.PURGE_ALL_MESSAGE');
            }
        }
    }
    onClose() {
        this.overlayRef.close(false);
    }
    onDelete() {
        this.overlayRef.close(true);
    }
}
exports.SwgEndpointsListPurgeModalComponent = SwgEndpointsListPurgeModalComponent;
