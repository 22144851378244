"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PseudoCapabilitiesService = void 0;
class PseudoCapabilitiesService {
    extendedCapabilities(account) {
        return Object.assign({}, this.accountTypeCapabilities(account));
    }
    accountTypeCapabilities(account) {
        switch (account.type) {
            case 'system': {
                return Object.assign({}, this.enabledCap('AccountType.System'));
            }
            case 'full': {
                return Object.assign({}, this.enabledCap('AccountType.Full'));
            }
            case 'enterprise_master': {
                return Object.assign({}, this.enabledCap('AccountType.EnterpriseMaster'));
            }
            case 'enterprise_group': {
                return Object.assign({}, this.enabledCap('AccountType.EnterpriseGroup'));
            }
            case 'enterprise_mail_processing': {
                return Object.assign({}, this.enabledCap('AccountType.EnterpriseMailProcessing'));
            }
            case 'partner_master': {
                return Object.assign({}, this.enabledCap('AccountType.PartnerMaster'));
            }
            case 'partner_group': {
                return Object.assign({}, this.enabledCap('AccountType.PartnerGroup'));
            }
            case 'partner_mail_processing': {
                return Object.assign({}, this.enabledCap('AccountType.PartnerMailProcessing'));
            }
            case 'sms_gateway': {
                return Object.assign({}, this.enabledCap('AccountType.SmsGateway'));
            }
            case 'poc': {
                return Object.assign({}, this.enabledCap('AccountType.POC'));
            }
        }
    }
    federatedAdminAccessCapabilities(access) {
        if (access) {
            return Object.assign({}, this.enabledCap('FederatedAdmin.Access'));
        }
        else {
            return {};
        }
    }
    enabledCap(key) {
        return { [key]: { enabled: true } };
    }
}
exports.PseudoCapabilitiesService = PseudoCapabilitiesService;
