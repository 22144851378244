"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailRouteDisplayPipe = void 0;
class EmailRouteDisplayPipe {
    constructor(translateService) {
        this.translateService = translateService;
    }
    transform(emailRoute) {
        switch (String(emailRoute).toLowerCase()) {
            case 'inbound':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_EMAIL_ROUTE.INBOUND');
            case 'outbound':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_EMAIL_ROUTE.OUTBOUND');
            case 'internal':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_EMAIL_ROUTE.INTERNAL');
            case 'external':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_EMAIL_ROUTE.EXTERNAL');
            default:
                return '';
        }
    }
}
exports.EmailRouteDisplayPipe = EmailRouteDisplayPipe;
