"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiIntegrationCortexSpywareSettingsStepComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const operators_1 = require("rxjs/operators");
class ApiIntegrationCortexSpywareSettingsStepComponent {
    constructor(fb, translateService) {
        this.fb = fb;
        this.translateService = translateService;
        this.onUpdateSpywareSettings = new core_1.EventEmitter();
        this.form = this.fb.group({
            spywareSeverity: ['medium', [forms_1.Validators.required]],
            action: 'NOTIFY',
            enabled: true,
            disableUser: false
        });
    }
    get spywareSeverities() {
        return [
            {
                value: 'medium',
                label: this.translateService.instant('$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.SPYWARE_SETTINGS.SEVERITIES_OPTIONS.MEDIUM')
            },
            {
                value: 'high',
                label: this.translateService.instant('$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.SPYWARE_SETTINGS.SEVERITIES_OPTIONS.HIGH')
            },
            {
                value: 'critical',
                label: this.translateService.instant('$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.SPYWARE_SETTINGS.SEVERITIES_OPTIONS.CRITICAL')
            }
        ];
    }
    ngOnInit() {
        this.formSetup(this.cortexSpywareSettings);
        this.formSubscription = this.form.valueChanges.pipe(operators_1.debounceTime(200)).subscribe(() => {
            this.update();
        });
    }
    ngOnChanges(changes) {
        const change = changes['cortexSpywareSettings'];
        if (change && JSON.stringify(change.previousValue) !== JSON.stringify(change.currentValue)) {
            if (change.currentValue) {
                this.formSetup(change.currentValue);
            }
        }
    }
    formSetup(cortexSpywareSettings) {
        this.showConfiguration = !!cortexSpywareSettings ? cortexSpywareSettings.enabled : true;
        this.showDisableUser = !!cortexSpywareSettings
            ? cortexSpywareSettings.action === 'BLOCK_OUTBOUND' ||
                cortexSpywareSettings.action === 'BLOCK_ALL'
            : false;
        this.form.patchValue({
            spywareSeverity: !!cortexSpywareSettings ? cortexSpywareSettings.fields.severity : 'medium',
            action: cortexSpywareSettings ? cortexSpywareSettings.action : 'NOTIFY',
            enabled: this.showConfiguration,
            disableUser: cortexSpywareSettings ? cortexSpywareSettings.disableUser : false
        });
    }
    update() {
        this.showDisableUser =
            this.form.value.action === 'BLOCK_OUTBOUND' || this.form.value.action === 'BLOCK_ALL';
        this.showConfiguration = this.form.value.enabled;
        this.onUpdateSpywareSettings.emit({
            severity: this.form.value.spywareSeverity,
            action: this.form.value.action,
            enabled: this.showConfiguration,
            disableUser: this.form.value.disableUser
        });
    }
    isValid() {
        return this.form.status === 'VALID';
    }
    ngOnDestroy() {
        this.formSubscription.unsubscribe();
    }
}
exports.ApiIntegrationCortexSpywareSettingsStepComponent = ApiIntegrationCortexSpywareSettingsStepComponent;
