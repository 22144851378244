"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RemediationDashboardComponent = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const remediation_dashboard_static_values_1 = require("./remediation-dashboard.static-values");
const settingsAction = require("../actions/settings/settings.actions");
const incidentsAction = require("../actions/incidents/incidents.action");
const incidentStatsAction = require("../actions/incident-stats/incident-stats.action");
const logsAction = require("../actions/logs/logs.action");
const searchAction = require("../actions/search/search.action");
class RemediationDashboardComponent {
    constructor(tabChangeService, swgService, stateService, capabilitiesService, store, tabService, userInfoApiService) {
        this.tabChangeService = tabChangeService;
        this.swgService = swgService;
        this.stateService = stateService;
        this.capabilitiesService = capabilitiesService;
        this.store = store;
        this.tabService = tabService;
        this.userInfoApiService = userInfoApiService;
        this.remediationDashboardTabs = remediation_dashboard_static_values_1.remediationDashboardTabs;
        this.isSearchEnabled$ = this.tabChangeService.hasSearchEnabled();
        this.tabChangeService.createNewBlockTabsSubject();
        this.tabChangeService.getBlockTabClicksObservable().subscribe((value) => {
            if (value) {
                this.blockTabClicks = value['isFormDirty'];
            }
        });
    }
    ngOnInit() {
        this.tabService.getSelectedTab().onRefresh = () => {
            this.initRemediationData();
        };
        this.isIEPCapabilityEnabled$ = this.capabilitiesService.hasCapability(remediation_dashboard_static_values_1.IEP_CAPABILITY_NAME);
        this.isMEPCapabilitySwitchEnabled$ = rxjs_1.forkJoin([
            this.capabilitiesService.hasCapability(remediation_dashboard_static_values_1.MEP_CAPABILITY_NAME),
            this.capabilitiesService.hasCapability(remediation_dashboard_static_values_1.MEP_INTEGRATION_SWITCH_NAME),
            this.capabilitiesService.hasCapability(remediation_dashboard_static_values_1.HIDE_MEP_CAPABILITY_NAME),
            this.userInfoApiService.getAccount()
        ]).pipe(operators_1.map(([mepCapAssigned, mepSwitchEnabled, hideMep, account]) => mepCapAssigned && mepSwitchEnabled && (account.cybergraphV2Enabled || !hideMep)));
        this.isSearchTabDisplayed$ = rxjs_1.forkJoin([
            this.isSearchEnabled$,
            this.isIEPCapabilityEnabled$
        ]).pipe(operators_1.map(([isSearchEnabled, iepCapabilityPresent]) => isSearchEnabled && iepCapabilityPresent));
        this.incidentTypeFilterOptions$ = rxjs_1.forkJoin([
            this.capabilitiesService.hasCapability(remediation_dashboard_static_values_1.MEP_INTEGRATION_SWITCH_NAME),
            this.capabilitiesService.hasCapability(remediation_dashboard_static_values_1.MEP_CAPABILITY_NAME),
            this.isIEPCapabilityEnabled$
        ]).pipe(operators_1.map(([isMEPSwitchEnabled, isMEPCapabilityEnabled, isIEPCapabilityEnabled]) => this.getIncidentTypeFilters(isMEPSwitchEnabled, isIEPCapabilityEnabled, isMEPCapabilityEnabled)));
        this.initRemediationData();
        this.isIdSearchDateRangeEnabled$ = this.capabilitiesService.hasCapability(remediation_dashboard_static_values_1.ID_SEARCH_DATE_RANGE_SWITCH_NAME);
    }
    onTabChange(event) {
        this.tabChangeService.setTabHistory(event);
        this.container.scrollTop = 0;
    }
    ngAfterViewInit() {
        this.container = document.getElementsByClassName('mc-layout-dashboard-simple')[0];
        this.isSearchTabDisplayed$.subscribe(isSearchTabVisible => {
            this.hasSearchTab = isSearchTabVisible;
        });
        this.tabChangeService.setTabs(this.tabGroup);
        const position = remediation_dashboard_static_values_1.remediationDashboardTabs
            .map(val => val.url)
            .indexOf(this.stateService.$state.params.tabName);
        if (this.hasSearchTab && position >= 0) {
            this.tabGroup.tabClicked(position);
        }
        else {
            this.tabGroup.tabClicked(0);
        }
    }
    getDesc() {
        return this.swgService.hasMSAEnabled()
            ? '$I18N_REMEDIATION_CONTAINER.PAGE_DESCRIPTION_MSA'
            : '$I18N_REMEDIATION_CONTAINER.PAGE_DESCRIPTION';
    }
    getIncidentTypeFilters(isMEPIntegrationEnabled, isIEPCapabilityPresent, isMEPCapabilityPresent) {
        let filters = [];
        if (isMEPIntegrationEnabled) {
            if (isIEPCapabilityPresent) {
                filters = [...remediation_dashboard_static_values_1.iepCapabilityIncidentTypes];
            }
            if (isMEPCapabilityPresent) {
                filters = filters.concat(remediation_dashboard_static_values_1.mepCapabilityIncidentTypes);
            }
        }
        else {
            filters = [...remediation_dashboard_static_values_1.iepCapabilityIncidentTypes];
        }
        return filters.concat(remediation_dashboard_static_values_1.commonIncidentTypes);
    }
    initRemediationData() {
        this.store.dispatch(new settingsAction.RemediationGetSettings());
        if (this.swgService.hasMSAReadEnabled()) {
            this.store.dispatch(new settingsAction.RemediationGetDeviceSettings());
        }
        this.store.dispatch(new incidentsAction.RemediationGetAllIncidentsAction());
        this.store.dispatch(new incidentStatsAction.RemediationGetIncidentStatsAction());
        this.store.dispatch(new logsAction.RemediationGetLogsAction());
        if (remediation_dashboard_static_values_1.RemediationDashboardTabs.search ===
            remediation_dashboard_static_values_1.RemediationDashboardTabs[this.remediationDashboardTabs[this.tabGroup.activeTabIndex].url]) {
            this.store.dispatch(new searchAction.RemediationRefreshSearchResult());
        }
    }
}
exports.RemediationDashboardComponent = RemediationDashboardComponent;
