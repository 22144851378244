"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ4 = exports.ɵ3 = exports.ɵ2 = exports.ɵ1 = exports.ɵ0 = exports.ulrProtectionPolicyDuration = exports.bypassApplicationOptions = void 0;
const policy_wizard_models_1 = require("../../../models/policy-wizard.models");
const moment = require("moment");
const ɵ0 = policy_wizard_models_1.BypassApplicationOptions.DISABLE_URL_PROTECT, ɵ1 = policy_wizard_models_1.BypassApplicationOptions.TAKE_NO_ACTION;
exports.ɵ0 = ɵ0;
exports.ɵ1 = ɵ1;
exports.bypassApplicationOptions = [
    {
        id: ɵ0,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.BYPASS_APPLICATION_VALUES.DISABLE_URL_PROTECT.LABEL',
        description: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.BYPASS_APPLICATION_VALUES.DISABLE_URL_PROTECT.DESCRIPTION'
    },
    {
        id: ɵ1,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.BYPASS_APPLICATION_VALUES.TAKE_NO_ACTION.LABEL',
        description: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.BYPASS_APPLICATION_VALUES.TAKE_NO_ACTION.DESCRIPTION'
    }
];
const ɵ2 = policy_wizard_models_1.DurationDetailOption.CONTINUOUS, ɵ3 = policy_wizard_models_1.DurationDetailOption.DATE_RANGE, ɵ4 = policy_wizard_models_1.DurationDetailOption.DATE_RANGE;
exports.ɵ2 = ɵ2;
exports.ɵ3 = ɵ3;
exports.ɵ4 = ɵ4;
exports.ulrProtectionPolicyDuration = {
    allowCustomRange: true,
    minDate: moment(),
    maxDate: moment().add(2, 'years'),
    defaultStartDate: moment(),
    defaultEndDate: moment().add(6, 'days'),
    allowFlexibleRange: true,
    dateRanges: [
        {
            id: ɵ2,
            label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.DURATION_VALUES.CONTINUOUS'
        },
        {
            id: ɵ3,
            label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.DURATION_VALUES.DATE_RANGE'
        },
        {
            id: ɵ4,
            label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.DURATION_VALUES.CONTINUOUS_FROM_A_DATE',
            disableEndDate: {
                disable: true,
                valueLabel: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.DURATION_VALUES.CONTINUOUS'
            }
        }
    ]
};
