"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isFailureSummaryLoaded = exports.incidentFailureSummary = exports.isLoaded = exports.hasGenericError = exports.incidentSummary = exports.reducer = exports.initialState = void 0;
const incidentSummaryAction = require("../../actions/incidents/incident-summary.action");
exports.initialState = {
    incidentSummary: null,
    isLoaded: false,
    failureSummary: null,
    isFailureSummaryLoaded: false,
    genericError: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case incidentSummaryAction.REMEDIATION_INCIDENT_SUMMARY_GET:
            return Object.assign(Object.assign({}, state), { genericError: false, isLoaded: false });
        case incidentSummaryAction.REMEDIATION_INCIDENT_SUMMARY_SUCCESS:
            return Object.assign(Object.assign({}, state), { incidentSummary: action.payload, genericError: false, isLoaded: true });
        case incidentSummaryAction.REMEDIATION_INCIDENT_SUMMARY_FAILURE:
            return Object.assign(Object.assign({}, state), { genericError: true, isLoaded: false });
        case incidentSummaryAction.REMEDIATION_INCIDENT_SUMMARY_RESET:
            return Object.assign({}, exports.initialState);
        case incidentSummaryAction.GET_INCIDENT_FAILURE_SUMMARY:
            return Object.assign(Object.assign({}, state), { isFailureSummaryLoaded: false });
        case incidentSummaryAction.GET_INCIDENT_FAILURE_SUMMARY_SUCCESS:
            return Object.assign(Object.assign({}, state), { isFailureSummaryLoaded: true, failureSummary: action.payload });
        case incidentSummaryAction.GET_INCIDENT_FAILURE_SUMMARY_FAILURE:
            return Object.assign(Object.assign({}, state), { isFailureSummaryLoaded: false, genericError: true });
        default:
            return state;
    }
}
exports.reducer = reducer;
const incidentSummary = (state) => state.incidentSummary;
exports.incidentSummary = incidentSummary;
const hasGenericError = (state) => state.genericError;
exports.hasGenericError = hasGenericError;
const isLoaded = (state) => state.isLoaded;
exports.isLoaded = isLoaded;
const incidentFailureSummary = (state) => state.failureSummary;
exports.incidentFailureSummary = incidentFailureSummary;
const isFailureSummaryLoaded = (state) => state.isFailureSummaryLoaded;
exports.isFailureSummaryLoaded = isFailureSummaryLoaded;
