"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HelpLinkComponent = void 0;
class HelpLinkComponent {
    constructor(translateService) {
        this.translateService = translateService;
    }
    ngOnInit() {
        this.tooltipText = this.tooltipText
            ? this.translateService.instant(this.tooltipText)
            : this.translateService.instant('$I18N_HELP_LINK_DEFAULT_TOOLTIP');
        this.tooltipPlacement = this.tooltipPlacement ? this.tooltipPlacement : 'bottom';
    }
    showHelpPage() {
        const win = window.open(this.url, '_blank');
        win.focus();
    }
}
exports.HelpLinkComponent = HelpLinkComponent;
