"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectNotificationGroupComponent = exports.groupType = void 0;
const operators_1 = require("rxjs/operators");
const findGroupsAction = require("../../actions/groups/find-groups.actions");
const getGroupAction = require("../../actions/groups/get-group.actions");
const getGroupsAction = require("../../actions/groups/get-groups.actions");
const reducers = require("../../reducers");
exports.groupType = {
    ldap: 'ldap',
    cloud: 'cloud'
};
class SelectNotificationGroupComponent {
    constructor(overlayRef, translate, store, cdr, overlayData) {
        this.overlayRef = overlayRef;
        this.translate = translate;
        this.store = store;
        this.cdr = cdr;
        this.overlayData = overlayData;
        this.isSearchResults = false;
        this.placeholderActiveDirectoryGroups = this.translate.instant('$I18N_REMEDIATION_CONTAINER.SELECT_NOTIFICATION_GROUP.FILTER_PLACEHOLDER.ACTIVE_DIRECTORY');
        this.placeholderLocalGroups = this.translate.instant('$I18N_REMEDIATION_CONTAINER.SELECT_NOTIFICATION_GROUP.FILTER_PLACEHOLDER.LOCAL');
    }
    ngOnInit() {
        this.options = {
            getChildren: this.getChildren.bind(this),
            displayField: 'description',
            childrenField: 'folders'
        };
        this.isLDAPSearching = false;
        this.isCloudSearching = false;
        this.showLDAPNoSearchResults = false;
        this.showCloudNoSearchResults = false;
        this.showSpinner = true;
        this.activeSource = exports.groupType.ldap;
        this.searchResultMessage = this.translate.instant('$I18N_REMEDIATION_CONTAINER.SELECT_NOTIFICATION_GROUP.SEARCH_RESULT_MSG');
        this.loadingMessage = this.translate.instant('$I18N_REMEDIATION_CONTAINER.SELECT_NOTIFICATION_GROUP.LOADING_MSG');
        this.store.dispatch(new getGroupsAction.RemediationGetGroupsCloud({ depth: 0, id: null, source: 'cloud' }));
        this.store.dispatch(new getGroupsAction.RemediationGetGroupsLDAP({ depth: 0, id: null, source: 'ldap' }));
        this.registerGetGroups(true);
        this.isCloudDataLoaded$ = this.store.select(reducers.isGetGroupsCloudDataLoaded);
        this.isLDAPDataLoaded$ = this.store.select(reducers.isGetGroupsLDAPDataLoaded);
        this.isGetGroupsLoadingError$ = this.store.select(reducers.isGetGroupsLoadingError);
        this.isFindGroupsLoadingError$ = this.store.select(reducers.isFindGroupsLoadingError);
        this.hasInitialCloudData$ = this.store.select(reducers.hasInitialCloudData);
        this.hasInitialLDAPData$ = this.store.select(reducers.hasInitialLDAPData);
        this.isLDAPReady$ = this.store.select(reducers.isLDAPGroupReady);
        this.isCloudReady$ = this.store.select(reducers.isCloudGroupReady);
        this.findGroupsSubscription = this.isFindGroupsLoadingError$.subscribe(value => {
            // if error, show no results message at tree level and hide tree
            if (value) {
                if (this.isLDAPSearching) {
                    this.showLDAPNoSearchResults = true;
                    this.isLDAPSearching = false;
                }
                if (this.isCloudSearching) {
                    this.showCloudNoSearchResults = true;
                    this.isCloudSearching = false;
                }
            }
        });
    }
    ngAfterViewInit() {
        this.cdr.reattach();
    }
    ngOnDestroy() {
        if (this.findGroupsSubscription) {
            this.findGroupsSubscription.unsubscribe();
        }
        if (this.foundGroupsSubscription) {
            this.foundGroupsSubscription.unsubscribe();
        }
        if (this.groupsSubscription) {
            this.groupsSubscription.unsubscribe();
        }
        this.cdr.detach();
    }
    // this method is invoked by the tree component. based on
    // getChildren in the options object set in ngOnInit()
    // It wants a promise to be returned.
    getChildren(node) {
        if (node.isExpanded && node.data.hasChildren) {
            const expandedNode = node;
            // get children
            if (expandedNode.data.hasChildren) {
                this.getNodeChildren(expandedNode.data);
            }
            this.group$ = this.store.select(reducers.getGroup);
            return this.group$
                .pipe(operators_1.map(group => {
                if (group.group && expandedNode && group.group[0].id === expandedNode.data.id) {
                    const currGroup = group.group[0];
                    // populate our flat array of folder items for lookup
                    this.populateFlatArray(currGroup.folders);
                    return this.mapGroupToTreeNode(currGroup.folders);
                }
            }), operators_1.take(2))
                .toPromise();
        }
    }
    onTabChange(event) {
        this.activeSource = event === 0 ? exports.groupType.ldap : exports.groupType.cloud;
    }
    onItemSelected(item) {
        this.selectedItem = this.findFolderItemById(item.id);
        this.close();
    }
    mapGroupToTreeNode(groups, isSearch = false) {
        return groups.map((group) => {
            let children = false;
            if (!isSearch) {
                children = group.folderCount > 0 || group['folders'];
            }
            return Object.assign({ hasChildren: children }, group);
        });
    }
    findFolderItemById(id) {
        return this.allGroups.find((item) => {
            return item.id === id;
        });
    }
    populateFlatArray(collection) {
        collection.forEach((item) => {
            if (!this.findFolderItemById(item.id)) {
                this.allGroups.push(item);
            }
        });
    }
    getNodeChildren(folderItem) {
        const payload = { id: folderItem.id, depth: 1, source: folderItem.source };
        this.store.dispatch(new getGroupAction.RemediationGetGroup(payload));
    }
    onSearch(event) {
        this.isSearchResults = true;
        this.showLDAPNoSearchResults = false;
        this.showCloudNoSearchResults = false;
        this.setTreeRef();
        this.store.dispatch(new findGroupsAction.RemediationFindGroupsResetStore());
        if (this.activeSource === exports.groupType.ldap) {
            this.isLDAPSearching = true;
        }
        else {
            this.isCloudSearching = true;
        }
        this.foundGroups$ = this.store.select(reducers.findGroups);
        this.foundGroupsSubscription = this.foundGroups$.subscribe(data => {
            if (this.ldapTree && data.source === exports.groupType.ldap && data.ldapGroups) {
                if (data.ldapGroups.length) {
                    this.ldapGroups = this.populateSearchResults(data.ldapGroups);
                    this.hasLDAPSearchResults = true;
                }
                else {
                    this.showLDAPNoSearchResults = true;
                    this.hasLDAPSearchResults = false;
                }
                this.isLDAPSearching = false;
            }
            if (this.cloudTree && data.source === exports.groupType.cloud && data.cloudGroups) {
                if (data.cloudGroups.length) {
                    this.cloudGroups = this.populateSearchResults(data.cloudGroups);
                    this.hasCloudSearchResults = true;
                }
                else {
                    this.showCloudNoSearchResults = true;
                    this.hasCloudSearchResults = false;
                }
                this.isCloudSearching = false;
            }
            this.cdr.markForCheck();
        });
        const config = {
            source: this.activeSource,
            query: event.search
        };
        this.store.dispatch(new findGroupsAction.RemediationFindGroups(config));
    }
    onClearSearch(event) {
        if (this.isSearchResults) {
            this.isSearchResults = false;
            this.hasLDAPSearchResults = false;
            this.hasCloudSearchResults = false;
            this.store.dispatch(new findGroupsAction.RemediationFindGroupsResetStore());
            this.registerGetGroups(true, event);
        }
    }
    close(result, $event) {
        if ($event) {
            $event.preventDefault();
        }
        this.overlayRef.close(this.selectedItem);
    }
    registerGetGroups(collapse = false, source = 'all') {
        this.groups$ = this.store.select(reducers.getGroups);
        this.groupsSubscription = this.groups$.subscribe(groups => {
            const canCollapseCloudTree = collapse && this.cloudTree;
            const canCollapseLDAPTree = collapse && this.ldapTree;
            this.showLDAPNoSearchResults = false;
            this.showCloudNoSearchResults = false;
            if (groups.cloudGroups && groups.ldapGroups) {
                this.allGroups = [...groups.cloudGroups, ...groups.ldapGroups];
                switch (source) {
                    case 'cloud':
                        this.cloudGroups = this.mapGroupToTreeNode(groups.cloudGroups);
                        if (canCollapseCloudTree) {
                            this.cloudTree.treeModel.collapseAll();
                        }
                        break;
                    case 'ldap':
                        this.ldapGroups = this.mapGroupToTreeNode(groups.ldapGroups);
                        if (canCollapseLDAPTree) {
                            this.ldapTree.treeModel.collapseAll();
                        }
                        break;
                    default:
                        this.cloudGroups = this.mapGroupToTreeNode(groups.cloudGroups);
                        this.ldapGroups = this.mapGroupToTreeNode(groups.ldapGroups);
                        if (canCollapseCloudTree && canCollapseLDAPTree) {
                            this.cloudTree.treeModel.collapseAll();
                            this.ldapTree.treeModel.collapseAll();
                        }
                }
                this.cdr.markForCheck();
            }
        });
    }
    populateSearchResults(localGroup) {
        this.populateFlatArray(localGroup);
        return this.mapGroupToTreeNode(localGroup, true);
    }
    setTreeRef() {
        if (!this.ldapTree && this.activeSource === exports.groupType.ldap) {
            this.ldapTree = this.ldapTreeView.getTreeRef();
        }
        if (!this.cloudTree && this.activeSource === exports.groupType.cloud) {
            this.cloudTree = this.cloudTreeView.getTreeRef();
        }
    }
}
exports.SelectNotificationGroupComponent = SelectNotificationGroupComponent;
