"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateCyberGraphPolicyPageComponent = void 0;
const createAction = require("../../actions/cybergraph-policies-create.actions");
const reducers = require("../../reducers/index");
class CreateCyberGraphPolicyPageComponent {
    constructor(store) {
        this.store = store;
    }
    ngOnInit() {
        this.wizardFlowType$ = this.store.select(reducers.getCyberGraphWizardFlowType);
        this.isApiProcessing$ = this.store.select(reducers.getApiIsProcessing);
    }
    onCreateNewPolicy() {
        this.store.dispatch(new createAction.OpenCreatePolicyPage());
    }
}
exports.CreateCyberGraphPolicyPageComponent = CreateCyberGraphPolicyPageComponent;
