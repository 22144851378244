<mc-wizard-step-header [label]="'$I18N_SWG_CREATE_POLICY_TTP_POLICY_MANAGED_URLS_SETTINGS'">
  {{ '$I18N_SWG_CREATE_POLICY_TTP_POLICY_LINK_PREFIX' | translate }}
  <a (click)="goToMangedUrlsPage()" class="btn-link">
    {{ '$I18N_SWG_CREATE_POLICY_TTP_POLICY_MANAGED_URLS_POLICY_LINK' | translate }}</a
  >.
</mc-wizard-step-header>

<div class="panel-double-padding-bottom">
  {{ getEnableTtpDesc() | translate }}
  <p class="no-margin">{{ '$I18N_SWG_CREATE_POLICY_TTP_ENABLE_WEB_PROXY' | translate }}</p>
  <p class="no-margin">
    <span>{{ '$I18N_SWG_CREATE_POLICY_BLOCKED_PAGE_VISIT_PAGE_1' | translate }} </span>
    <a
      href="https://community.mimecast.com/docs/DOC-3187#jive_content_id_Configuring_a_Targeted_Threat_Protection_Policy"
      target="_blank"
      class="btn-link"
      translate="$I18N_SWG_CREATE_POLICY_BLOCKED_PAGE_VISIT_PAGE_2"
      rel="noopener noreferrer"
    ></a>
    <span>{{ '$I18N_SWG_CREATE_POLICY_BLOCKED_PAGE_VISIT_PAGE_3' | translate }} </span>
  </p>
</div>

<div class="mc-ttp-settings-step">
  <form novalidate [formGroup]="ttpSettingsForm" class="mc-ttp-settings-form">
    <mc-enable-switch formControlName="useManagedUrls"></mc-enable-switch>
  </form>
</div>
