"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgDevicesListViewApiService = void 0;
class SwgDevicesListViewApiService {
    constructor(http) {
        this.http = http;
    }
    getDeviceList(config) {
        return this.http.post('/api/swg/get-endpoint-summaries', config);
    }
}
exports.SwgDevicesListViewApiService = SwgDevicesListViewApiService;
