<mc-custom-dropdown-button (click)="updateSelections($event.target.tagName)" align="left" label="{{label}}">
  <div> 
    <div class="mc-search-section input-group">
      <input
        class="form-control mc-input mc-search-input"
        type="text"
        autocomplete="off"
        [placeholder]="'Search'"
        [(ngModel)]="searchValue"
        (input)="searchFilterValue()"
      />
      <span class="mc-icon input-group-addon no-select" role="button">
        <i aria-hidden="true" class="far fa-search mc-fa-search"></i>
      </span>
    </div>
    <div class="mc-select-all-section">
      <a
        *ngIf="!allSelected"
        class="mc-select-all"
        (click)="selectUnselectAll(true, $event)"
        >{{ '$I18N_DROPDOWN_SEARCH_CHECKBOXES.SELECT_ALL' | translate }}
        </a>
      <a
        *ngIf="allSelected"
        class="mc-select-all"
        (click)="selectUnselectAll(false, $event)">
        {{ '$I18N_DROPDOWN_SEARCH_CHECKBOXES.CLEAR_ALL' | translate }}
      </a>
    </div>
    <div 
      class="mc-checkboxes-section mc-scroll"
      #scrollingBlock
    >
      <virtual-scroller 
        #scroll 
        [items]="optionItems" 
        [parentScroll]="scrollingBlock">
        <div *ngFor="let option of scroll.viewPortItems; ">
          <div class="checkbox">
            <label class="mc-label">
              <input
                type="checkbox"
                [(ngModel)]="option.isSelected"
                (change)="onChecked(option.label, $event)"
              />
              {{ option.label }}
            </label>
          </div>
        </div>
      </virtual-scroller>
    </div>
    <div class="mc-actions-section">
      <button type="button" class="btn btn-primary mc-apply-btn" (click)="applyValues()">
        {{ '$I18N_DROPDOWN_SEARCH_CHECKBOXES.APPLY' | translate }}
      </button>
    </div>
  </div>
</mc-custom-dropdown-button>

