<mc-layout-detail-wizard [keyTitle]="wizardTitle">
  <mc-wizard #wizard (changeStep)="onStepChange($event)" class="wizard-spinner">
    <mc-wizard-step label="{{ '$I18N_CREATE_CONNECTOR_WIZARD.CONSENT_STEP_TITLE' }}">
      <mc-connector-wizard-consent-step [provider]="provider$ | async">
      </mc-connector-wizard-consent-step>
    </mc-wizard-step>

    <mc-wizard-step label="{{ '$I18N_CREATE_CONNECTOR_WIZARD.DETAILS_STEP_TITLE' }}">
      <mc-connector-wizard-details-step
      ></mc-connector-wizard-details-step>
    </mc-wizard-step>

    <mc-wizard-step label="{{ '$I18N_CREATE_CONNECTOR_WIZARD.SUMMARY_STEP_TITLE' }}">
      <mc-connector-wizard-summary-step [wizardData]="wizardData$ | async">
      </mc-connector-wizard-summary-step>
    </mc-wizard-step>
  </mc-wizard>

  <mc-footer-container>
    <div class="pull-right no-select mc-button-footer">
      <button (click)="onCancel('migrate')" class="btn cancel-btn">
        {{ '$I18N_CONNECTORS_MIGRATE.CANCEL' | translate }}
      </button>

      <ng-container *ngIf="wizard">
        <button
          (click)="onPrevious()"
          *ngIf="wizard.hasPreviousStep$ | async"
          class="btn btn-secondary"
        >
          {{ '$I18N_CONNECTORS_MIGRATE.PREVIOUS' | translate }}
        </button>

        <button
          (click)="onNext()"
          *ngIf="wizard.hasNextStep$ | async"
          [disabled]="!(isCurrentStepValid$ | async)"
          class="btn btn-primary"
          data-test="next-btn"
        >
          {{ '$I18N_CONNECTORS_MIGRATE.NEXT' | translate }}
        </button>
        <button
          (click)="onSave($event)"
          *ngIf="wizard.isLastStep$ | async"
          [disabled]="disableMigrateButton"
          class="btn btn-primary"
          data-test="save-btn"
        >
          {{ '$I18N_CONNECTORS_MIGRATE.MIGRATE' | translate }}
        </button>
      </ng-container>
    </div>
  </mc-footer-container>
</mc-layout-detail-wizard>
