"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateSetupPageComponent = void 0;
const core_1 = require("@angular/core");
const operators_1 = require("rxjs/operators");
const actions = require("../../actions");
const reducer = require("../../reducers");
class TemplateSetupPageComponent {
    constructor(store) {
        this.store = store;
        this.templateId = 0;
        this.click = new core_1.EventEmitter();
        this.title = 'New Page Set';
        this.subtitle = 'Enter a name and customize the web pages and safety tips as required.';
        this.preview = 'default';
    }
    ngOnInit() {
        this.store.dispatch(new actions.template.LoadAction({ id: this.templateId }));
        this.store.dispatch(new actions.safetyTips.MimecastSafetyTipsLoadAction());
        if (this.templateId) {
            this.title = 'Edit User Awareness Page Sets';
            this.subtitle = 'Edit the page set as required and save the changes.';
        }
        this.isSaved$ = this.store.select(reducer.isTemplateSaved(this.templateId));
        this.isLoading$ = this.store.select(reducer.isTemplateLoading(this.templateId));
        this.template$ = this.store.select(reducer.getTemplate(this.templateId));
        this.previewTip$ = this.store.select(reducer.getTemplatePreviewTip(this.templateId));
        this.templateForForm$ = reducer.getTemplateForForm(this.store, this.templateId);
        this.mimecastSafetyTipsOrPreview$ = this.store.select(reducer.getMimecastDefaultSafetyTipsOrPreview(this.templateId));
        this.templateSafetyTipsOrPreview$ = this.store.select(reducer.getTemplateSafetyTipsOrPreview(this.templateId));
        this.templateName$ = this.template$.pipe(operators_1.map(val => (val && val.templateName ? val.templateName : this.title)));
    }
    ngOnDestroy() {
        this.store.dispatch(new actions.template.CleanStoreAction({ id: this.templateId }));
    }
    showPage(value) {
        this.preview = value;
    }
    save() {
        this.store.dispatch(new actions.template.SaveAction({ id: this.templateId }));
    }
    cancel() {
        this.store.dispatch(new actions.template.CancelAction({ id: this.templateId }));
    }
    onFormChanges(value) {
        this.store.dispatch(new actions.template.StoreUpdateAction({ template: value }));
    }
}
exports.TemplateSetupPageComponent = TemplateSetupPageComponent;
