<div *mcCapabilities="'Permission.SEARCH_READ'">
<div class="panel panel-default" mc-hash-calculator-area (onCalculationComplete)="updateFileHash($event)" [fileSizeLimit]="fileSizeLimit">
  <div class="panel-heading" *ngIf="!inlineForm">{{ "$I18N_REMEDIATION_SEARCH_MESSAGES_WIDGET.TITLE" | translate }}</div>
  <mc-tab-group [initialActiveTabIndex]="getSearchTabIndex$ | async" themeLight="true">
    <mc-tab name="{{ '$I18N_REMEDIATION_SEARCH_MESSAGES_WIDGET.TABS.SEARCH_BY_DATA' | translate }}"  [active]="(getSearchTabIndex$ | async) === 0">
      <div class="panel-body">
        <mc-remediation-search-messages-by-data
          [inlineForm]="inlineForm"
          [isRemediationEnabled]="isRemediationEnabled$ | async"
          [isSearchInProgress]="isSearchInProgress$ | async"
          [hasSearchWithoutPermission] = "hasSearchWithoutHashPermission"
          [formValues]="formValues$ | async"
          (search)="runSearch($event, 0, true)">
        </mc-remediation-search-messages-by-data>
      </div>
    </mc-tab>
    <mc-tab name="{{ '$I18N_REMEDIATION_SEARCH_MESSAGES_WIDGET.TABS.SEARCH_BY_ID' | translate }}"  [active]="(getSearchTabIndex$ | async) === 1">
      <div class="panel-body">
        <mc-remediation-search-messages-by-id
          [inlineForm]="inlineForm"
          [isRemediationEnabled]="isRemediationEnabled$ | async"
          [isSearchInProgress]="isSearchInProgress$ | async"
          [isIdSearchDateRangeEnabled]="isIdSearchDateRangeEnabled"
          [formValues]="formValues$ | async"
          (search)="runSearch($event, 1, false)">
        </mc-remediation-search-messages-by-id>
      </div>
    </mc-tab>
  </mc-tab-group>
  </div>
</div>
