<div class="mc-bi-policy-details-step">
  <mc-wizard-step-header
    [label]="policy.biSettings.defaultPolicy && isEditFlow ? '$I18N_BI_DEFAULT_POLICY_DETAILS_STEP_DESC' : '$I18N_BI_POLICY_DETAILS_STEP_DESC'">
  </mc-wizard-step-header>

  <div *ngIf="policy.biSettings.defaultPolicy && isEditFlow" class="mc-detailed-list mc-swg-policy-summary-block">
    <div class="row mc-detailed-list-row no-padding-top mc-policy-details-default-policy-block">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_SWG_CREATE_POLICY_POLICY_SUMMARY_NAME' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ policy.policyDesc.description }}</div>
    </div>

    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_BI_POLICY_DETAILS_STEP_DESCRIPTION' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{  '$I18N_BI_DEFAULT_POLICY_DESCRIPTION' | translate }}
      </div>
    </div>
  </div>
  <div *ngIf="(!policy.biSettings.defaultPolicy && isEditFlow) || !isEditFlow">
    <form
      class="form-horizontal"
      mcDefaultLabelClass="col-sm-3"
      mcDefaultControlContainerClass="col-sm-9"
      [formGroup]="policyPage"
    >
      <div class="panel-padding-bottom mc-policy-name">
        <mc-text-field
          errorPrefix="$I18N_BI_POLICY_DETAILS_STEP_NAME_VALIDATION"
          label="$I18N_BI_POLICY_DETAILS_STEP_NAME"
          formControlName="name"
          [setFocus]="true"
        ></mc-text-field>
      </div>   <div class="panel-padding-bottom mc-policy-name">
      <mc-text-area-field
        errorPrefix="$I18N_BI_POLICY_DETAILS_STEP_DESCRIPTION_VALIDATION"
        label="$I18N_BI_POLICY_DETAILS_STEP_DESCRIPTION"
        formControlName="description"
        [setFocus]="false"
        [maxLength]="200"
      ></mc-text-area-field>
    </div>
    </form>
  </div>

</div>
