"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiemChannelDeleteConfirmationModalComponent = void 0;
const list_actions_1 = require("../../../actions/siem-channels/list.actions");
const reducers = require("../../../reducers/");
class SiemChannelDeleteConfirmationModalComponent {
    constructor(store$) {
        this.store$ = store$;
    }
    ngOnInit() {
        this.channel$ = this.store$.select(reducers.getSiemChannel);
        this.isDeleting$ = this.store$.select(reducers.getIsDeletingStatus);
    }
    onDelete(channel) {
        this.store$.dispatch(new list_actions_1.SiemChannelListDeleteChannel(channel.id));
    }
    onCancel() {
        this.store$.dispatch(new list_actions_1.SiemChannelDeleteConfirmationModalClose());
    }
}
exports.SiemChannelDeleteConfirmationModalComponent = SiemChannelDeleteConfirmationModalComponent;
