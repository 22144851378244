"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WizardEffect = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const wizardActions = require("../actions/wizard.actions");
const cancel_confirmation_modal_component_1 = require("../containers/wizard/cancel-confirmation-modal/cancel-confirmation-modal.component");
class WizardEffect {
    constructor(actions$, stateService, modalService) {
        this.actions$ = actions$;
        this.stateService = stateService;
        this.modalService = modalService;
        this.cancel$ = this.actions$.pipe(effects_1.ofType(wizardActions.WizardActions.CANCEL), operators_1.switchMap((action) => {
            this.cancelModalInstance = this.modalService.open(cancel_confirmation_modal_component_1.CancelConfirmationModalComponent, {
                size: 'md',
                hasBackdrop: true,
                data: { title: action.title, wizardId: action.wizardId }
            });
            return rxjs_1.EMPTY;
        }));
        this.cancelYes$ = this.actions$.pipe(effects_1.ofType(wizardActions.WizardActions.CANCEL_YES), operators_1.switchMap((action) => {
            if (this.cancelModalInstance) {
                this.cancelModalInstance.close();
            }
            this.cancelModalInstance = undefined;
            this.stateService.$state.go('archive-supervision-list', {
                callerWizardId: action.wizardId
            });
            return rxjs_1.EMPTY;
        }));
        this.cancelNo$ = this.actions$.pipe(effects_1.ofType(wizardActions.WizardActions.CANCEL_NO), operators_1.switchMap(() => {
            if (this.cancelModalInstance) {
                this.cancelModalInstance.close();
            }
            this.cancelModalInstance = undefined;
            return rxjs_1.EMPTY;
        }));
    }
}
__decorate([
    effects_1.Effect()
], WizardEffect.prototype, "cancel$", void 0);
__decorate([
    effects_1.Effect()
], WizardEffect.prototype, "cancelYes$", void 0);
__decorate([
    effects_1.Effect()
], WizardEffect.prototype, "cancelNo$", void 0);
exports.WizardEffect = WizardEffect;
