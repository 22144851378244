'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./export-logs-aside.controller"); //export-logs-aside.controller
require("./export-logs-aside.service"); //export-logs-aside.service
// html and css 
require("./export-logs-aside.tpl.html");
angular.module('export-logs-aside', [
    'export-logs-aside.controller',
    'export-logs-aside.service'
]);
