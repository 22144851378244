"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateTaskStatusActionFail = exports.UpdateTaskStatusActionSuccess = exports.UpdateTaskStatusAction = exports.OpenCreateConnectorWizardAction = exports.LoadConnectorsFailedAction = exports.LoadConnectorsSuccessAction = exports.LoadConnectorsAction = exports.LoadImSyncTaskDetailsFailedAction = exports.LoadImSyncTaskDetailsSuccessAction = exports.LoadImSyncTaskDetailsAction = exports.UpdateImSyncTaskActionFail = exports.UpdateImSyncTaskActionSuccess = exports.UpdateImSyncTaskAction = exports.CreateImSyncTaskActionFail = exports.CreateImSyncTaskActionSuccess = exports.CreateImSyncTaskAction = exports.OpenCreateNewImSyncAction = exports.ImSyncActions = void 0;
var ImSyncActions;
(function (ImSyncActions) {
    ImSyncActions["OPEN_CREATE_NEW_IM_SYNC"] = "OPEN_CREATE_NEW_IM_SYNC";
    ImSyncActions["CREATE_IM_SYNC_TASK"] = "CREATE_IM_SYNC_TASK";
    ImSyncActions["CREATE_IM_SYNC_TASK_FAIL"] = "CREATE_IM_SYNC_TASK_FAIL";
    ImSyncActions["CREATE_IM_SYNC_TASK_SUCCESS"] = "CREATE_IM_SYNC_TASK_SUCCESS";
    ImSyncActions["LOAD_IM_SYNC_TASK_DETAILS"] = "LOAD_IM_SYNC_TASK_DETAILS";
    ImSyncActions["LOAD_IM_SYNC_TASK_DETAILS_SUCCESS"] = "LOAD_IM_SYNC_TASK_DETAILS_SUCCESS";
    ImSyncActions["LOAD_IM_SYNC_TASK_DETAILS_FAILURE"] = "LOAD_IM_SYNC_TASK_DETAILS_FAILURE";
    ImSyncActions["UPDATE_IM_SYNC_TASK"] = "UPDATE_IM_SYNC_TASK";
    ImSyncActions["UPDATE_IM_SYNC_TASK_FAIL"] = "UPDATE_IM_SYNC_TASK_FAIL";
    ImSyncActions["UPDATE_IM_SYNC_TASK_SUCCESS"] = "UPDATE_IM_SYNC_TASK_SUCCESS";
    ImSyncActions["LOAD_CONNECTORS"] = "LOAD_CONNECTORS";
    ImSyncActions["LOAD_CONNECTORS_SUCCESS"] = "LOAD_CONNECTORS_SUCCESS";
    ImSyncActions["LOAD_CONNECTORS_FAILURE"] = "LOAD_CONNECTORS_FAILURE";
    ImSyncActions["OPEN_CREATE_CONNECTOR_WIZARD"] = "OPEN_CREATE_CONNECTOR_WIZARD";
    ImSyncActions["UPDATE_TASK_STATUS"] = "UPDATE_TASK_STATUS";
    ImSyncActions["UPDATE_TASK_STATUS_FAIL"] = "UPDATE_TASK_STATUS_FAIL";
    ImSyncActions["UPDATE_TASK_STATUS_SUCCESS"] = "UPDATE_TASK_STATUS_SUCCESS";
})(ImSyncActions = exports.ImSyncActions || (exports.ImSyncActions = {}));
class OpenCreateNewImSyncAction {
    constructor() {
        this.type = ImSyncActions.OPEN_CREATE_NEW_IM_SYNC;
    }
}
exports.OpenCreateNewImSyncAction = OpenCreateNewImSyncAction;
class CreateImSyncTaskAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ImSyncActions.CREATE_IM_SYNC_TASK;
    }
}
exports.CreateImSyncTaskAction = CreateImSyncTaskAction;
class CreateImSyncTaskActionSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = ImSyncActions.CREATE_IM_SYNC_TASK_SUCCESS;
    }
}
exports.CreateImSyncTaskActionSuccess = CreateImSyncTaskActionSuccess;
class CreateImSyncTaskActionFail {
    constructor(payload) {
        this.payload = payload;
        this.type = ImSyncActions.CREATE_IM_SYNC_TASK_FAIL;
    }
}
exports.CreateImSyncTaskActionFail = CreateImSyncTaskActionFail;
class UpdateImSyncTaskAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ImSyncActions.UPDATE_IM_SYNC_TASK;
    }
}
exports.UpdateImSyncTaskAction = UpdateImSyncTaskAction;
class UpdateImSyncTaskActionSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = ImSyncActions.UPDATE_IM_SYNC_TASK_SUCCESS;
    }
}
exports.UpdateImSyncTaskActionSuccess = UpdateImSyncTaskActionSuccess;
class UpdateImSyncTaskActionFail {
    constructor(payload) {
        this.payload = payload;
        this.type = ImSyncActions.UPDATE_IM_SYNC_TASK_FAIL;
    }
}
exports.UpdateImSyncTaskActionFail = UpdateImSyncTaskActionFail;
class LoadImSyncTaskDetailsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ImSyncActions.LOAD_IM_SYNC_TASK_DETAILS;
    }
}
exports.LoadImSyncTaskDetailsAction = LoadImSyncTaskDetailsAction;
class LoadImSyncTaskDetailsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ImSyncActions.LOAD_IM_SYNC_TASK_DETAILS_SUCCESS;
    }
}
exports.LoadImSyncTaskDetailsSuccessAction = LoadImSyncTaskDetailsSuccessAction;
class LoadImSyncTaskDetailsFailedAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ImSyncActions.LOAD_IM_SYNC_TASK_DETAILS_FAILURE;
    }
}
exports.LoadImSyncTaskDetailsFailedAction = LoadImSyncTaskDetailsFailedAction;
class LoadConnectorsAction {
    constructor() {
        this.type = ImSyncActions.LOAD_CONNECTORS;
    }
}
exports.LoadConnectorsAction = LoadConnectorsAction;
class LoadConnectorsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ImSyncActions.LOAD_CONNECTORS_SUCCESS;
    }
}
exports.LoadConnectorsSuccessAction = LoadConnectorsSuccessAction;
class LoadConnectorsFailedAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ImSyncActions.LOAD_CONNECTORS_FAILURE;
    }
}
exports.LoadConnectorsFailedAction = LoadConnectorsFailedAction;
class OpenCreateConnectorWizardAction {
    constructor() {
        this.type = ImSyncActions.OPEN_CREATE_CONNECTOR_WIZARD;
    }
}
exports.OpenCreateConnectorWizardAction = OpenCreateConnectorWizardAction;
class UpdateTaskStatusAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ImSyncActions.UPDATE_TASK_STATUS;
    }
}
exports.UpdateTaskStatusAction = UpdateTaskStatusAction;
class UpdateTaskStatusActionSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = ImSyncActions.UPDATE_TASK_STATUS_SUCCESS;
    }
}
exports.UpdateTaskStatusActionSuccess = UpdateTaskStatusActionSuccess;
class UpdateTaskStatusActionFail {
    constructor(payload) {
        this.payload = payload;
        this.type = ImSyncActions.UPDATE_TASK_STATUS_FAIL;
    }
}
exports.UpdateTaskStatusActionFail = UpdateTaskStatusActionFail;
