"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiIntegrationCrowdstrikeWizardComponent = void 0;
const core_1 = require("@angular/core");
class ApiIntegrationCrowdstrikeWizardComponent {
    constructor(store, capabilitiesService, coreService) {
        this.store = store;
        this.capabilitiesService = capabilitiesService;
        this.coreService = coreService;
        this.onCancel = new core_1.EventEmitter();
        this.onCreateCrowdstrikeIntegration = new core_1.EventEmitter();
        this.onVerifyClientIdAndClientSecret = new core_1.EventEmitter();
        this.onUpdateCrowdstrikeIntegration = new core_1.EventEmitter();
        this.onSwitchCrowdstrikeIntegrationStatus = new core_1.EventEmitter();
        this.onSwitchMaliciousHashesEnabled = new core_1.EventEmitter();
        this.onSwitchPullMaliciousHashesEnabled = new core_1.EventEmitter();
        this.onIocOutboundActionSelected = new core_1.EventEmitter();
        this.onSwitchRemediation = new core_1.EventEmitter();
        this.onAddGroup = new core_1.EventEmitter();
        this.onDeleteGroup = new core_1.EventEmitter();
        this.onVerifyAndAddEmail = new core_1.EventEmitter();
        this.onDeleteEmail = new core_1.EventEmitter();
        this.onIocOutboundUrlActionSelected = new core_1.EventEmitter();
        this.onIocOutboundSeveritySelected = new core_1.EventEmitter();
        this.onIocOutboundExpirationSelected = new core_1.EventEmitter();
        this.onSwitchMaliciousUrlsEnabled = new core_1.EventEmitter();
        this.hasNextStep = true;
        this.hasPreviousStep = false;
        this.isPullMaliciousHashesEnabled = false;
        this.activationStepNumber = 1;
        this.outboundStepNumber = 2;
        this.inboundStepNumber = -1;
        this.notificationStepNumber = 3;
        this.summaryStepNumber = 4;
        this.capabilitiesService
            .hasCapability('Temporary.Services.ApiApplications.Crowdstrike.Integration.PullMaliciousHashes')
            .subscribe(isActive => {
            this.isPullMaliciousHashesEnabled = isActive;
            this.setPullHashesStep();
        });
    }
    setPullHashesStep() {
        if (this.isPullMaliciousHashesEnabled) {
            this.inboundStepNumber = 3;
            this.notificationStepNumber = 4;
            this.summaryStepNumber = 5;
        }
        else {
            this.inboundStepNumber = -1;
            this.notificationStepNumber = 3;
            this.summaryStepNumber = 4;
        }
    }
    ngAfterViewInit() {
        if (!!this.wizard) {
            this.wizard.hasNextStep$.subscribe(value => (this.hasNextStep = value));
            this.wizard.hasPreviousStep$.subscribe(value => (this.hasPreviousStep = value));
            if (this.crowdstrikePrerequisitesError) {
                this.gotoStep(this.summaryStepNumber);
            }
            else {
                this.gotoStep(this.activationStepNumber);
            }
        }
    }
    ngOnChanges(changes) {
        const prerequisiteErrorChange = changes['crowdstrikePrerequisitesError'];
        if (prerequisiteErrorChange && prerequisiteErrorChange.currentValue) {
            this.gotoStep(this.summaryStepNumber);
        }
    }
    wizardTitle() {
        return `$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.TITLE_${this.hasCreatedIntegration ? 'EDIT' : 'CREATE'}`;
    }
    create() {
        this.onCreateCrowdstrikeIntegration.emit();
    }
    cancel() {
        this.onCancel.emit();
    }
    showUpdate() {
        if (!!this.wizard) {
            return this.wizard.currentStep === this.summaryStepNumber && this.hasCreatedIntegration;
        }
    }
    update() {
        this.onUpdateCrowdstrikeIntegration.emit();
    }
    nextStep() {
        if (!!this.wizard) {
            this.wizard.nextStep();
        }
    }
    changeStep() {
        // you have to listen to this event or the underlying wizard doesn't work :(
    }
    gotoStep(step) {
        if (!!this.wizard) {
            this.wizard.showStep(step);
        }
    }
    showPrevious() {
        return this.hasPreviousStep;
    }
    gotoPreviousStep() {
        if (!!this.wizard) {
            this.wizard.previousStep();
        }
    }
    showNext() {
        return this.hasNextStep && this.wizard.currentStep !== this.summaryStepNumber;
    }
    showCreate() {
        return this.wizard.currentStep === this.summaryStepNumber && !this.hasCreatedIntegration;
    }
    gotoNextStep() {
        if (!!this.wizard) {
            this.wizard.nextStep();
        }
    }
    verifyClientIdAndClientSecret(clientIdAndClientSecretConfig) {
        this.onVerifyClientIdAndClientSecret.emit(clientIdAndClientSecretConfig);
    }
    switchCrowdstrikeIntegrationStatus(status) {
        this.onSwitchCrowdstrikeIntegrationStatus.emit(status);
    }
    switchMaliciousHashesEnabled(status) {
        this.onSwitchMaliciousHashesEnabled.emit(status);
    }
    switchPullMaliciousHashesEnabled(status) {
        this.onSwitchPullMaliciousHashesEnabled.emit(status);
    }
    iocOutboundActionSelected(action) {
        this.onIocOutboundActionSelected.emit(action);
    }
    switchRemediationRequired(remediationEnabled) {
        this.onSwitchRemediation.emit(remediationEnabled);
    }
    switchMaliciosUrls(maliciousUrlsEnabeld) {
        this.onSwitchMaliciousUrlsEnabled.emit(maliciousUrlsEnabeld);
    }
    iocOutboundUrlActionSelected(iocOutboundUrlAction) {
        this.onIocOutboundUrlActionSelected.emit(iocOutboundUrlAction);
    }
    iocOutboundSeveritySelected(iocOutboundSevirity) {
        this.onIocOutboundSeveritySelected.emit(iocOutboundSevirity);
    }
    iocOutboundExpirationSelected(iocOutboundExpiration) {
        this.onIocOutboundExpirationSelected.emit(iocOutboundExpiration);
    }
    addGroup(group) {
        this.onAddGroup.emit(group);
    }
    deleteGroup(group) {
        this.onDeleteGroup.emit(group);
    }
    verifyAndAddEmail(email) {
        this.onVerifyAndAddEmail.emit(email);
    }
    deleteEmail(email) {
        this.onDeleteEmail.emit(email);
    }
    isStepValid() {
        if (!this.isBusy && !!this.wizard) {
            switch (this.wizard.currentStep) {
                case this.activationStepNumber:
                    return this.activationStep.isValid();
                case this.outboundStepNumber:
                    return this.outboundStep.isValid();
                case this.notificationStepNumber:
                    return this.notificationStep.isValid();
                case this.summaryStepNumber:
                    return this.summaryStep.isValid();
                case this.inboundStepNumber:
                default:
                    return true;
            }
        }
        return false;
    }
}
exports.ApiIntegrationCrowdstrikeWizardComponent = ApiIntegrationCrowdstrikeWizardComponent;
