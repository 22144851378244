"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.partnersList = void 0;
exports.partnersList = [
    {
        id: 'anomali',
        image: '../resources/tech-partners/anomali.png',
        isInternal: false,
        optionsList: [
            {
                type: 'link',
                target: 'https://community.mimecast.com/s/article/Anomali-ThreatStream',
                label: '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.LEARN_MORE'
            }
        ]
    },
    {
        id: 'apigateway',
        mcCapability: 'Temporary.Services.ApiApplications.MimecastApiV3.Integration',
        image: '../resources/tech-partners/mimecastgateway.png',
        isInternal: false,
        optionsList: [
            {
                type: 'link',
                target: 'https://developer.services.mimecast.com/',
                label: '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.LEARN_MORE'
            }
        ]
    },
    {
        id: 'arcticwolf',
        image: '../resources/tech-partners/arctic-wolf.png',
        isInternal: false,
        category: 'managed_soc_integration',
        service: true,
        optionsList: [
            {
                type: 'link',
                target: 'https://docs.arcticwolf.com/cloud/mimecast_credentials.html',
                label: '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.LEARN_MORE'
            }
        ]
    },
    {
        id: 'sentinel',
        image: '../resources/tech-partners/sentinel.png',
        isInternal: false,
        category: 'siem_integration',
        service: true,
        optionsList: [
            {
                type: 'link',
                target: 'https://www.mimecast.com/tech-connect/tech-partners/microsoft-azure-sentinel/',
                label: '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.LEARN_MORE'
            }
        ]
    },
    {
        id: 'blumira',
        image: '../resources/tech-partners/blumira.png',
        isInternal: false,
        category: 'siem_integration',
        service: true,
        optionsList: [
            {
                type: 'link',
                target: 'https://integrations.mimecast.com/tech-partners/blumira/',
                label: '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.LEARN_MORE'
            }
        ]
    },
    {
        id: 'connectwise',
        image: '../resources/tech-partners/connectwise.png',
        isInternal: false,
        category: 'subscription_management_integration',
        service: true,
        optionsList: [
            {
                type: 'link',
                target: 'https://www.mimecast.com/tech-connect/tech-partners/connectwise/',
                label: '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.LEARN_MORE'
            }
        ]
    },
    {
        id: 'crowdstrike',
        image: '../resources/tech-partners/crowdstrike.png',
        isInternal: true,
        optionsList: [
            {
                type: 'link',
                target: 'https://www.mimecast.com/tech-connect/tech-partners/crowdstrike/',
                label: '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.LEARN_MORE'
            }
        ]
    },
    {
        id: 'humio',
        image: '../resources/tech-partners/humio.png',
        isInternal: false,
        category: 'siem_integration',
        service: true,
        optionsList: [
            {
                type: 'link',
                target: 'https://docs.humio.com/docs/integrations/mimecast/',
                label: '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.LEARN_MORE'
            }
        ]
    },
    {
        id: 'digitalshadows',
        image: '../resources/tech-partners/digitalshadows.png',
        isInternal: false,
        category: 'digital_risk_platform_integration',
        service: true,
        optionsList: [
            {
                type: 'link',
                target: 'https://resources.digitalshadows.com/digital-shadows-integrations/mimecast-and-digital-shadows-integration',
                label: '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.LEARN_MORE'
            }
        ]
    },
    {
        id: 'fluencysecurity',
        image: '../resources/tech-partners/fluencysecurity.png',
        isInternal: false,
        category: 'siem_integration',
        service: true,
        optionsList: [
            {
                type: 'link',
                target: 'https://www.mimecast.com/tech-connect/tech-partners/fluency-security/',
                label: '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.LEARN_MORE'
            }
        ]
    },
    {
        id: 'soar',
        image: '../resources/tech-partners/soar.png',
        isInternal: false,
        category: 'soar_integration',
        service: true,
        optionsList: [
            {
                type: 'link',
                target: 'https://www.mimecast.com/tech-connect/tech-partners/fortisoar/',
                label: '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.LEARN_MORE'
            }
        ]
    },
    {
        id: 'qradar',
        image: '../resources/tech-partners/qradar.png',
        isInternal: false,
        category: 'siem_integration',
        service: true,
        optionsList: [
            {
                type: 'link',
                target: 'https://www.mimecast.com/tech-connect/tech-partners/ibm-qradar/',
                label: '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.LEARN_MORE'
            }
        ]
    },
    {
        id: 'resilient',
        image: '../resources/tech-partners/resilient.png',
        isInternal: false,
        category: 'soar_integration',
        service: true,
        optionsList: [
            {
                type: 'link',
                target: 'https://www.mimecast.com/tech-connect/tech-partners/ibm-resilient/',
                label: '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.LEARN_MORE'
            }
        ]
    },
    {
        id: 'legacycustom',
        image: '../resources/tech-partners/legacycustom.png',
        isInternal: false,
        optionsList: [
            {
                type: 'link',
                target: 'https://community.mimecast.com/s/article/Managing-API-Applications-505230018',
                label: '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.LEARN_MORE'
            }
        ]
    },
    {
        id: 'logrhythm',
        image: '../resources/tech-partners/logrhythm.png',
        isInternal: false,
        category: 'siem_integration',
        service: true,
        optionsList: [
            {
                type: 'link',
                target: 'https://www.mimecast.com/tech-connect/tech-partners/logrhythm/',
                label: '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.LEARN_MORE'
            }
        ]
    },
    {
        id: 'mcafee',
        image: '../resources/tech-partners/mcafee.png',
        isInternal: false,
        category: 'siem_integration',
        service: true,
        optionsList: [
            {
                type: 'link',
                target: 'https://www.mimecast.com/tech-connect/tech-partners/mcafee-esm/',
                label: '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.LEARN_MORE'
            }
        ]
    },
    {
        id: 'netskopecloudte',
        image: '../resources/tech-partners/servicenow.png',
        isInternal: false,
        category: 'casb_integration',
        service: true,
        optionsList: [
            {
                type: 'link',
                target: 'https://www.mimecast.com/tech-connect/tech-partners/netskope/',
                label: '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.LEARN_MORE'
            }
        ]
    },
    {
        id: 'netskopedlp',
        image: '../resources/tech-partners/servicenow.png',
        isInternal: false,
        optionsList: [
            {
                type: 'link',
                target: 'https://www.mimecast.com/tech-connect/tech-partners/netskope/',
                label: '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.LEARN_MORE'
            }
        ]
    },
    {
        id: 'scim',
        mcCapability: 'Temporary.Services.ApiApplications.Okta.Integration',
        image: '../resources/tech-partners/scim.png',
        isInternal: true,
        optionsList: [
            {
                type: 'link',
                target: 'https://www.mimecast.com/tech-connect/tech-partners/Okta-Directory-SCIM/',
                label: '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.LEARN_MORE'
            }
        ]
    },
    {
        id: 'okta_evidence_based_control',
        image: '../resources/tech-partners/oktaevidencebasedcontrol.png',
        isInternal: true,
        optionsList: [
            {
                type: 'link',
                target: 'https://integrations.mimecast.com/tech-partners/evidence-based-controls-okta/',
                label: '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.LEARN_MORE'
            }
        ]
    },
    {
        id: 'cortex',
        image: '../resources/tech-partners/cortex.png',
        isInternal: true,
        optionsList: [
            {
                type: 'link',
                target: 'https://www.mimecast.com/tech-connect/tech-partners/pancortex/',
                label: '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.LEARN_MORE'
            }
        ]
    },
    {
        id: 'xsoar',
        image: '../resources/tech-partners/xsoar.png',
        isInternal: false,
        category: 'soar_integration',
        service: true,
        optionsList: [
            {
                type: 'link',
                target: 'https://www.mimecast.com/tech-connect/tech-partners/demisto/',
                label: '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.LEARN_MORE'
            }
        ]
    },
    {
        id: 'wildfire',
        image: '../resources/tech-partners/wildfire.png',
        isInternal: true,
        optionsList: [
            {
                type: 'link',
                target: 'https://integrations.mimecast.com/tech-partners/wildfire-palo-alto-networks/',
                label: '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.LEARN_MORE'
            }
        ]
    },
    {
        id: 'insightconnect',
        image: '../resources/tech-partners/insightconnect.png',
        isInternal: false,
        category: 'soar_integration',
        service: true,
        optionsList: [
            {
                type: 'link',
                target: 'https://www.mimecast.com/tech-connect/tech-partners/rapid7/',
                label: '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.LEARN_MORE'
            }
        ]
    },
    {
        id: 'insightidr',
        image: '../resources/tech-partners/insightidr.png',
        isInternal: false,
        category: 'siem_integration',
        service: true,
        optionsList: [
            {
                type: 'link',
                target: 'https://www.mimecast.com/tech-connect/tech-partners/rapid7-insightidr/',
                label: '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.LEARN_MORE'
            }
        ]
    },
    {
        id: 'salesforce',
        image: '../resources/tech-partners/salesforce.png',
        isInternal: false,
        category: 'business_intelligence',
        service: true,
        optionsList: [
            {
                type: 'link',
                target: 'https://www.mimecast.com/tech-connect/tech-partners/salesforce/',
                label: '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.LEARN_MORE'
            }
        ]
    },
    {
        id: 'secureworks',
        image: '../resources/tech-partners/secureworks.png',
        isInternal: false,
        category: 'xdr_integration',
        service: true,
        optionsList: [
            {
                type: 'link',
                target: 'https://integrations.mimecast.com/tech-partners/secureworks/',
                label: '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.LEARN_MORE'
            }
        ]
    },
    {
        id: 'sentinelone',
        image: '../resources/tech-partners/sentinelone.png',
        isInternal: false,
        category: 'xdr_integration',
        service: true,
        optionsList: [
            {
                type: 'link',
                target: 'https://integrations.mimecast.com/tech-partners/sentinelone/',
                label: '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.LEARN_MORE'
            }
        ]
    },
    {
        id: 'servicenow',
        image: '../resources/tech-partners/servicenow.png',
        isInternal: false,
        category: 'itsm_integration',
        service: true,
        optionsList: [
            {
                type: 'link',
                target: 'https://integrations.mimecast.com/tech-partners/servicenow/',
                label: '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.LEARN_MORE'
            }
        ]
    },
    {
        id: 'splunk',
        image: '../resources/tech-partners/splunk.png',
        isInternal: false,
        category: 'siem_integration',
        service: true,
        optionsList: [
            {
                type: 'link',
                target: 'https://www.mimecast.com/tech-connect/tech-partners/splunk/',
                label: '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.LEARN_MORE'
            }
        ]
    },
    {
        id: 'splunkphantom',
        image: '../resources/tech-partners/splunkphantom.png',
        isInternal: false,
        category: 'soar_integration',
        service: true,
        optionsList: [
            {
                type: 'link',
                target: 'https://www.mimecast.com/tech-connect/tech-partners/splunkphantom/',
                label: '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.LEARN_MORE'
            }
        ]
    },
    {
        id: 'sumologic',
        image: '../resources/tech-partners/sumologic.png',
        isInternal: false,
        category: 'siem_integration',
        service: true,
        optionsList: [
            {
                type: 'link',
                target: 'https://integrations.mimecast.com/tech-partners/sumologic/',
                label: '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.LEARN_MORE'
            }
        ]
    },
    {
        id: 'swimlane',
        image: '../resources/tech-partners/swimlane.png',
        isInternal: false,
        category: 'soar_integration',
        service: true,
        optionsList: [
            {
                type: 'link',
                target: 'https://www.mimecast.com/tech-connect/tech-partners/swimlane/',
                label: '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.LEARN_MORE'
            }
        ]
    },
    {
        id: 'thetalake',
        image: '../resources/tech-partners/thetalake.png',
        isInternal: false,
        category: 'compliance_integration',
        service: true,
        optionsList: [
            {
                type: 'link',
                target: 'https://integrations.mimecast.com/tech-partners/theta-lake/',
                label: '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.LEARN_MORE'
            }
        ]
    },
    {
        id: 'timetoreply',
        image: '../resources/tech-partners/timetoreply.png',
        isInternal: false,
        category: 'business_intelligence',
        service: true,
        optionsList: [
            {
                type: 'link',
                target: 'https://www.mimecast.com/tech-connect/tech-partners/time-to-reply/',
                label: '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.LEARN_MORE'
            }
        ]
    },
    {
        id: 'exabeam',
        image: '../resources/tech-partners/exabeam.png',
        isInternal: false,
        category: 'xdr_integration',
        service: true,
        optionsList: [
            {
                type: 'link',
                target: 'https://integrations.mimecast.com/tech-partners/exabeam/',
                label: '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.LEARN_MORE'
            }
        ]
    }
];
