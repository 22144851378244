<mc-layout-aside-extra-container
  keyTitle="{{ '$I18N_CUSTOM_DOMAIN_BLOCK_VIEW_POLICY.TITLE' | translate }}"
  showClose="true"
  [isLoading]="false"
  (closeAside)="close()"
>
  <mc-extra-container>
    <div>
      <h3 *ngIf="policyDetails">{{policyDetails.name}}</h3>
        <mc-paginator
        [isLoading]="loading"
        [previousToken]="paginationInfo?.previousEntity"
        [nextToken]="paginationInfo?.nextEntity"
        (paginate)="loadPolicyDetails($event)"
      ></mc-paginator>
    </div>
    <div class="clearfix"></div>
    <div class="mc-container-buttons-area">
      <button class="btn btn-primary" [disabled]="loading" (click)="edit()" *mcCapabilities="'Permission.CUSTOM_DOMAIN_BLOCK_UPLOAD'">
        {{ '$I18N_CUSTOM_DOMAIN_BLOCK_VIEW_POLICY.CONTROLS.EDIT' | translate }}
      </button>
      <button class="btn btn-secondary" [disabled]="loading" (click)="showDelete.emit()" *mcCapabilities="'Permission.CUSTOM_DOMAIN_BLOCK_DELETE'">
        {{ '$I18N_CUSTOM_DOMAIN_BLOCK_VIEW_POLICY.CONTROLS.DELETE' | translate }}
      </button>
      <form [formGroup]="switchForm" *mcCapabilities="'Permission.CUSTOM_DOMAIN_BLOCK_UPLOAD'">
        <mc-switch-field
          onStateLabel="$I18N_CUSTOM_DOMAIN_BLOCK_VIEW_POLICY.CONTROLS.ENABLED"
          offStateLabel="$I18N_CUSTOM_DOMAIN_BLOCK_VIEW_POLICY.CONTROLS.DISABLED"
          formControlName="enabled"
          showLabel="false"
        >
        </mc-switch-field>
      </form>
    </div>
  </mc-extra-container>
  <mc-body-container>
    <mc-loader-full-container
      [isLoading]="loading"
      class="mc-layout-loader-full-container-aside mc-flex-scroll-vertical"
    >
      <div *ngIf="loading" style="height: 100px;"></div>
    </mc-loader-full-container>

    <div *ngIf="!loading && policyDetails">
      <mc-view-policy-details [policyDetails]="policyDetails"></mc-view-policy-details>
    </div>
  </mc-body-container>
</mc-layout-aside-extra-container>
