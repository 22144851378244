<div class="row">

  <div class="col-xs-12" *ngIf="showAppOrLoadingMessage()">
    <div *ngIf="!loading; else loadingSpinner">
      <mc-swg-chart-no-data-message
        [message]="noDataMessage"
      ></mc-swg-chart-no-data-message>
    </div>
    <ng-template #loadingSpinner>
      <mc-spinner class="mc-chart-widget-spinner"></mc-spinner>
    </ng-template>
  </div>

  <div class="col-xs-12" *ngIf="!showAppOrLoadingMessage()">
    <div class="row">
      <div class="col-xs-12 mc-chart-download-buttons">
        <mc-list-dropdown-button
          disableCaret="true"
          inline="true"
          align="right"
          class="mc-chart-controls mc-chart-download-button"
        >
          <mc-dropdown-item
            [label]="format.label"
            (click)="exportChart(format.type, chartName)"
            *ngFor="let format of exportFormats"
          ></mc-dropdown-item>
        </mc-list-dropdown-button>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <ng-container *ngIf="chartType==='bar-chart'">
          <mc-chart class="mc-top-ten-chart">
            <mc-bar-chart [config]="config" [chartData]="chartData" class="mc-amchart"></mc-bar-chart>
          </mc-chart>
        </ng-container>

        <ng-container *ngIf="chartType==='pie'">
          <mc-chart class="mc-top-ten-chart">
            <mc-pie-chart [config]="config" [chartData]="chartData" class="mc-amchart"></mc-pie-chart>
          </mc-chart>
        </ng-container>

        <ng-container *ngIf="chartType==='multi-line'">
          <mc-swg-detection-timeline [chartData]='chartData'></mc-swg-detection-timeline>
        </ng-container>
      </div>
    </div>
  </div>
</div>
