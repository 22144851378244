"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isProcessing = exports.gethasError = exports.getContents = exports.getFileName = exports.reducer = exports.initialState = void 0;
const actions = require("../../actions/file-parse.actions");
// @ts-ignore
exports.initialState = {
    content: [],
    isProcessing: false,
    fileName: { name: '' },
    hasError: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.SWG_POLICY_PARSE_FILE:
            return Object.assign(Object.assign({}, state), { fileName: action.payload, isProcessing: true });
        case actions.SWG_POLICY_PARSE_FILE_SUCCESS:
            return Object.assign(Object.assign({}, state), { content: action.payload, hasError: false, isProcessing: false });
        case actions.SWG_POLICY_PARSE_FILE_FAILURE:
            return Object.assign(Object.assign({}, state), { hasError: true, isProcessing: false, content: [] });
        case actions.SWG_POLICY_PARSE_FILE_RESET:
            return Object.assign({}, exports.initialState);
        default:
            return state;
    }
}
exports.reducer = reducer;
const getFileName = (state) => state.fileName;
exports.getFileName = getFileName;
const getContents = (state) => state.content;
exports.getContents = getContents;
const gethasError = (state) => state.hasError;
exports.gethasError = gethasError;
const isProcessing = (state) => state.isProcessing;
exports.isProcessing = isProcessing;
