<form [formGroup]="form" mcErrorPrefix="$I18N_AWARENESS_SETUP">
  <mc-accordion>
    <mc-accordion-panel title="Page Set Name" (click)="showPage.emit('default')"
      [error]="!form.get('templateName')?.valid">
      <mc-text-field errorPrefix="TEMPLATE_NAME" label="Name" formControlName="templateName"
        placeholder="I18N_TEMPLATE_FORM_PAGE_TEMPLATE_DEFAULT_TITLE" required></mc-text-field>
    </mc-accordion-panel>

    <mc-accordion-panel formGroupName="userTemplate" title="User Challenge Page"
      [error]="!form.get('userTemplate')?.valid" (click)="showPage.emit('userTemplate')" class="accordion-body">
      <mc-text-field errorPrefix="PAGE_TITLE" label="Title" formControlName="title"
        placeholder="$I18N_AWARENESS_TEMPLATE_SETUP_USER_CHALLENGE_PAGE_DEFAULT_TITLE"></mc-text-field>
      <mc-text-area-field errorPrefix="PAGE_SUB_TITLE" label="Body Text" formControlName="subtitle"
        placeholder="$I18N_AWARENESS_TEMPLATE_SETUP_USER_CHALLENGE_PAGE_DEFAULT_SUB_TITLE"></mc-text-area-field>
    </mc-accordion-panel>

    <mc-accordion-panel formGroupName="safeLinkIdentified" title="Safe Link Identified Page"
      [error]="!form.get('safeLinkIdentified')?.valid" (click)="showPage.emit('safeLinkIdentified')"
      class="accordion-body">
      <mc-text-field errorPrefix="PAGE_TITLE" label="Title" formControlName="title"
        placeholder="$I18N_AWARENESS_TEMPLATE_SETUP_SAFE_LINK_IDENTIFIED_PAGE_DEFAULT_TITLE"></mc-text-field>
      <mc-text-area-field errorPrefix="PAGE_SUB_TITLE" label="Body Text" formControlName="subtitle"
        placeholder="$I18N_AWARENESS_TEMPLATE_SETUP_SAFE_LINK_IDENTIFIED_PAGE_DEFAULT_SUB_TITLE"></mc-text-area-field>
    </mc-accordion-panel>

    <mc-accordion-panel formGroupName="safeLinkMisidentified" title="Safe Link Misidentified Page"
      [error]="!form.get('safeLinkMisidentified')?.valid" (click)="showPage.emit('safeLinkMisidentified')"
      class="accordion-body">
      <mc-text-field errorPrefix="PAGE_TITLE" label="Title" formControlName="title"
        placeholder="$I18N_AWARENESS_TEMPLATE_SETUP_SAFE_LINK_MISIDENTIFIED_PAGE_DEFAULT_TITLE"></mc-text-field>
      <mc-text-area-field errorPrefix="PAGE_SUB_TITLE" label="Body Text" formControlName="subtitle"
        placeholder="$I18N_AWARENESS_TEMPLATE_SETUP_SAFE_LINK_MISIDENTIFIED_PAGE_DEFAULT_SUB_TITLE">
      </mc-text-area-field>
    </mc-accordion-panel>

    <mc-accordion-panel formGroupName="harmfulLinkIndentified" title="Harmful Link Identified Page"
      [error]="!form.get('harmfulLinkIndentified')?.valid" (click)="showPage.emit('harmfulLinkIndentified')"
      class="accordion-body">
      <mc-text-field errorPrefix="PAGE_TITLE" label="Title" formControlName="title"
        placeholder="$I18N_AWARENESS_TEMPLATE_SETUP_HARMFUL_LINK_IDENTIFIED_PAGE_DEFAULT_TITLE"></mc-text-field>
      <mc-text-area-field errorPrefix="PAGE_SUB_TITLE" label="Body Text" formControlName="subtitle"
        placeholder="$I18N_AWARENESS_TEMPLATE_SETUP_HARMFUL_LINK_IDENTIFIED_PAGE_DEFAULT_SUB_TITLE">
      </mc-text-area-field>
    </mc-accordion-panel>

    <mc-accordion-panel formGroupName="harmfulLinkMisindentifiedWarning" title="Harmful Link Misidentified Warning Page"
      [error]="!form.get('harmfulLinkMisindentifiedWarning')?.valid"
      (click)="showPage.emit('harmfulLinkMisindentifiedWarning')" class="accordion-body">
      <mc-text-field errorPrefix="PAGE_TITLE" label="Title" formControlName="title"
        placeholder="$I18N_AWARENESS_TEMPLATE_SETUP_HARMFUL_LINK_MISIDENTIFIED_PAGE_DEFAULT_TITLE"></mc-text-field>
      <mc-text-area-field errorPrefix="PAGE_SUB_TITLE" label="Body Text" formControlName="subtitle"
        placeholder="$I18N_AWARENESS_TEMPLATE_SETUP_HARMFUL_LINK_MISIDENTIFIED_PAGE_DEFAULT_SUB_TITLE">
      </mc-text-area-field>
    </mc-accordion-panel>

    <mc-accordion-panel formGroupName="harmfulLinkMisindentifiedBlock" title="Harmful Link Misidentified Block Page"
      [error]="!form.get('harmfulLinkMisindentifiedBlock')?.valid"
      (click)="showPage.emit('harmfulLinkMisindentifiedBlock')" class="accordion-body">
      <mc-text-field errorPrefix="PAGE_TITLE" label="Title" formControlName="title"
        placeholder="$I18N_AWARENESS_TEMPLATE_SETUP_HARMFUL_LINK_MISIDENTIFIED_BLOCK_PAGE_DEFAULT_TITLE">
      </mc-text-field>
      <mc-text-area-field errorPrefix="PAGE_SUB_TITLE" label="Body Text" formControlName="subtitle"
        placeholder="$I18N_AWARENESS_TEMPLATE_SETUP_HARMFUL_LINK_MISIDENTIFIED_BLOCK_PAGE_DEFAULT_SUB_TITLE">
      </mc-text-area-field>
    </mc-accordion-panel>

    <mc-accordion-panel title="Safety Tips"
      subtitle="Select the tips to display on the pages. If you select custom tips, you can add up to 10 of your own."
      (click)="showPage.emit(form.get('safetyTipType').value)" class="accordion-body"
      [error]="form?.getError('customTipsRequired')">
      <mc-field>
        <select class="form-control" formControlName="safetyTipType" (change)="showPage.emit($event.target.value)">
          <option value="mimecastSafetyTips">Default tips</option>
          <option value="mimecastSafetyTipsAndCustomSafetyTips">Default and custom tips</option>
          <option value="customSafetyTips">Custom tips</option>
        </select>
        <mc-errors [errors]="form.errors" *ngIf="!form.valid" formPrefix="$I18N_AWARENESS_SETUP"></mc-errors>
      </mc-field>

      <mc-template-safety-tips [safetyTipType]="form.get('safetyTipType').value" [templateId]="templateId">
      </mc-template-safety-tips>
    </mc-accordion-panel>

  </mc-accordion>
</form>