"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const swg_exceptions_list_component_1 = require("./containers/list/swg-exceptions-list.component");
angular
    .module('swgExceptionsList', [])
    .directive('mcSwgExceptionsList', static_1.downgradeComponent({
    component: swg_exceptions_list_component_1.SwgExceptionsListComponent
}))
    .config([
    '$stateProvider',
    ($stateProvider) => {
        const breadcrumbs = [];
        $stateProvider.state('secure-web-gateway-exceptions-list', {
            url: '/secure-web-gateway/exceptions-list',
            data: {
                capabilities: 'Permission.SWG_BYPASS_HOSTS_READ',
                isFixedLayout: false,
                checkProgress: false,
                tabReload: true,
                tabTitle: '$I18N_SWG_EXCEPTIONS_LIST.TAB_TITLE',
                tabId: 'swgExceptionsList',
                breadcrumbs
            },
            views: {
                main: {
                    template: '<mc-swg-exceptions-list></mc-swg-exceptions-list>'
                }
            }
        });
    }
]);
