"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RejectNarrativeMessageModalComponent = void 0;
const forms_1 = require("@angular/forms");
class RejectNarrativeMessageModalComponent {
    constructor(fb, rejectNarrativeReasonPipe, translate, overlayRef, actionType) {
        this.fb = fb;
        this.rejectNarrativeReasonPipe = rejectNarrativeReasonPipe;
        this.translate = translate;
        this.overlayRef = overlayRef;
        this.actionType = actionType;
        this.canSubmit = false;
        this.reasons = [
            {
                value: 'noop',
                label: this.translate.instant('$I18N_MESSAGE_CENTER_REJECT_NARRATIVE_MESSAGE_MODAL.FIELDS.REASON_PLACEHOLDER')
            },
            {
                value: 'inappropriate_communication',
                label: this.rejectNarrativeReasonPipe.transform('inappropriate_communication')
            },
            {
                value: 'confidential_information',
                label: this.rejectNarrativeReasonPipe.transform('confidential_information')
            },
            {
                value: 'against_email_policies',
                label: this.rejectNarrativeReasonPipe.transform('against_email_policies')
            },
            {
                value: 'disapproves_of_content',
                label: this.rejectNarrativeReasonPipe.transform('disapproves_of_content')
            }
        ];
    }
    ngOnInit() {
        this.form = this.fb.group({
            reason: [this.reasons[0].value, [forms_1.Validators.required]],
            rejectNarrativeMessageComment: [
                '',
                [
                    forms_1.Validators.required,
                    forms_1.Validators.pattern(/[\S]/),
                    forms_1.Validators.minLength(5),
                    forms_1.Validators.maxLength(500)
                ]
            ],
            notify: [false]
        });
        setTimeout(() => this.rejectNarrativeMessageComment.nativeElement.focus(), 0);
        this.form.valueChanges.subscribe(() => {
            this.canSubmit = this.form.get('reason').value !== 'noop';
        });
    }
    onSubmit() {
        if (this.form.valid) {
            this.overlayRef.close({
                reason: this.form.value.reason,
                notes: this.form.value.rejectNarrativeMessageComment,
                notify: this.form.value.notify,
                submitted: true
            });
        }
    }
    close() {
        this.overlayRef.close();
    }
}
exports.RejectNarrativeMessageModalComponent = RejectNarrativeMessageModalComponent;
