"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const _ = require("lodash");
(function () {
    'use strict';
    /* ======================================================== *\

   = ServerConnections Edit - controller

   \* ======================================================== */
    angular
        .module('services.services.server-connections-edit.controller', [])
        .controller('ServerConnectionEditController', [
        'serverConnectionService',
        '$stateParams',
        '$translate',
        '$state',
        'growl',
        'serverConnectionEditService',
        'serverConnectionEditGuard',
        'capabilitiesService',
        function (serverConnectionService, $stateParams, $translate, $state, growl, serverConnectionEditService, serverConnectionEditGuard, capabilitiesService) {
            const self = this;
            self.$onInit = () => {
                /* -------------------------------------------------------- *\
       - interface
       \* -------------------------------------------------------- */
                self.serverConnectionResponse = serverConnectionEditGuard.serverConnectionResponse;
                self.serverConnectionID = null;
                self.serverConnection = {};
                self.serverConnection.ewsSettings = {};
                self.serverConnection.ewsSettings.encryptionMode = {};
                self.serverConnection.office365Settings = {};
                self.serverConnection.selectedSourceType = {};
                self.serverConnection.mailbox = null;
                self.serverConnectionEnum = serverConnectionService.constants.SERVER_TYPE;
                self.serverConnectionOfficeEnvironmentEnum =
                    serverConnectionService.constants.OFFICE_ENVIRONMENT;
                self.inlineNotificationOptions = {};
                self.testConnectionInlineNotificationOptions = {};
                self.isTestSuccessful = false;
                self.testConnection = testConnection;
                self.returnToList = returnToList;
                self.createServerConnectionAndReturnToList = createServerConnectionAndReturnToList;
                self.closeModal = closeModal;
                self.createServerConnectionAndCloseModal = createServerConnectionAndCloseModal;
                self.isFormValid = isFormValid;
                self.setDefaultFormValues = setDefaultFormValues;
                self.isCreateServerConnectionDisabled = isCreateServerConnectionDisabled;
                self.hasServerConnectionEditPermission =
                    serverConnectionEditService.hasServerConnectionEditPermission;
                self.isAside = false;
                /* -------------------------------------------------------- *\
       - initialisation
       \* -------------------------------------------------------- */
                activate();
                /* -------------------------------------------------------- *\
       - internal
       \* -------------------------------------------------------- */
                function activate() {
                    self.maxLengthDescription = 70;
                    self.maxLengthValidationErrorMessage = $translate.instant('$I18N_BACKUP_RESTORE_SERVER_CONNECTION_DESCRIPTION_MAX_LENGTH', { maxLength: self.maxLengthDescription });
                    self.serverConnectionID =
                        angular.isDefined($stateParams.id) && !_.isEmpty($stateParams.id)
                            ? $stateParams.id
                            : undefined;
                    //TODO: to finalise error display instead of growl once error-handler service is finalised.
                    self.inlineNotificationOptions = {
                        messageBoxAlertTypeClass: 'alert-danger',
                        messageBoxIconClass: 'fas fa-exclamation-triangle',
                        messageBoxTitle: '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_GENERIC_INLINE_MESSAGE_LABEL',
                        messageBoxVisibility: false,
                        messageBoxHideCloseButton: false,
                        messageBoxHideTranscludeContainer: true
                    };
                    self.serverConnectionOptions = [
                        {
                            key: self.serverConnectionEnum.OFFICE_365,
                            value: $translate.instant('$I18N_BACKUP_RESTORE_SERVER_CONNECTION_O365_EDIT_LABEL')
                        },
                        {
                            key: self.serverConnectionEnum.EWS,
                            value: $translate.instant('$I18N_BACKUP_RESTORE_SERVER_CONNECTION_EWS_EDIT_LABEL')
                        }
                    ];
                    if (capabilitiesService.hasCapability('Temporary.Services.Server.Connectors.Disabled') &&
                        !$stateParams.id) {
                        self.serverConnectionOptions.shift();
                        self.serverConnectionOptions[0].disabled = true;
                        self.serverConnection.selectedSourceType = self.serverConnectionOptions[0];
                    }
                    self.serverSecurityModeOptions = [
                        {
                            key: serverConnectionService.constants.ENCRYPTION_MODE.STRICT,
                            value: $translate.instant('$I18N_BACKUP_RESTORE_SERVER_CONNECTION_SECURITY_MODE_OPTION_STRICT')
                        },
                        {
                            key: serverConnectionService.constants.ENCRYPTION_MODE.RELAXED,
                            value: $translate.instant('$I18N_BACKUP_RESTORE_SERVER_CONNECTION_SECURITY_MODE_OPTION_RELAXED')
                        }
                    ];
                    self.serverOfficeEnvironmentOptions = [
                        {
                            key: self.serverConnectionOfficeEnvironmentEnum.STANDARD,
                            value: $translate.instant('$I18N_BACKUP_RESTORE_SERVER_CONNECTION_OFFICE_ENVIRONMENT_OPTION_STANDARD')
                        },
                        {
                            key: self.serverConnectionOfficeEnvironmentEnum.GOVERNMENT_COMMUNITY_CLOUD,
                            value: $translate.instant('$I18N_BACKUP_RESTORE_SERVER_CONNECTION_OFFICE_ENVIRONMENT_OPTION_GOVERNMENT_COMMUNITY_CLOUD')
                        }
                    ];
                    self.serverConnection.ewsSettings.encryptionMode = self.serverSecurityModeOptions[0];
                    self.serverConnection.office365Settings.serverSubtype =
                        self.serverOfficeEnvironmentOptions[0];
                    if (self.serverConnectionResponse) {
                        setServerConnection(self.serverConnectionResponse);
                    }
                }
                /* -------------------------------------------------------- *\
       - implementation
       \* -------------------------------------------------------- */
                // == setServerConnection ========
                function setServerConnection(serverConnection) {
                    self.serverConnection = serverConnection;
                    self.serverConnection.selectedSourceType =
                        _.find(self.serverConnectionOptions, function (t) {
                            return t.key == serverConnection.serverType;
                        }) || self.serverConnectionOptions[0];
                    if (self.serverConnection.selectedSourceType.key == self.serverConnectionEnum.EWS) {
                        self.serverConnection.ewsSettings.encryptionMode =
                            _.find(self.serverSecurityModeOptions, function (serverSecurityMode) {
                                return serverSecurityMode.key === serverConnection.ewsSettings.encryptionMode;
                            }) || self.serverSecurityModeOptions[0].key;
                    }
                    else if (self.serverConnection.selectedSourceType.key == self.serverConnectionEnum.OFFICE_365) {
                        self.serverConnection.office365Settings.serverSubtype =
                            _.find(self.serverOfficeEnvironmentOptions, function (serverSubtype) {
                                return serverSubtype.key === serverConnection.office365Settings.serverSubtype;
                            }) || self.office365Settings[0].key;
                    }
                }
                // == testConnection ========
                function testConnection() {
                    if (!self.testConnectionForm.isInvalid()) {
                        self.testConnectionForm.setLock(true);
                        const newServerConnection = buildTestJson(self.serverConnection.mailbox);
                        return serverConnectionService.testServerConnection(newServerConnection).then(function (response) {
                            if (!response.err) {
                                self.testConnectionForm.setLock(false);
                                self.isTestSuccessful = true;
                                notifyTestSuccess();
                            }
                            else {
                                self.testConnectionForm.setLock(false);
                                notifyTestFailure(response);
                            }
                        }, function () {
                            self.testConnectionForm.setLock(false);
                            growl.error('$I18N_BACKUP_RESTORE_SERVER_CONNECTION_TEST_FAILURE_MESSAGE_LABEL', {
                                ttl: 5000
                            });
                        });
                    }
                }
                // == notifyTestSuccess ========
                function notifyTestSuccess() {
                    self.testConnectionInlineNotificationOptions.messageBoxAlertTypeClass = 'alert-success';
                    self.testConnectionInlineNotificationOptions.messageBoxIconClass = 'fa-check';
                    self.testConnectionInlineNotificationOptions.messageBoxTitle =
                        '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_TEST_SUCCESSFUL_MESSAGE_LABEL';
                    self.testConnectionInlineNotificationOptions.messageBoxVisibility = 'true';
                    self.testConnectionInlineNotificationOptions.messageBoxHideCloseButton = false;
                    self.testConnectionInlineNotificationOptions.messageBoxContent = [
                        '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_TEST_SUCCESSFUL_MESSAGE_CONTENT'
                    ];
                }
                // == notifyTestFailure ========
                function notifyTestFailure(response) {
                    self.isTestSuccessful = true;
                    self.testConnectionInlineNotificationOptions.messageBoxVisibility = 'true';
                    self.testConnectionInlineNotificationOptions.messageBoxHideCloseButton = false;
                    self.testConnectionInlineNotificationOptions.messageBoxContent = [];
                    response.fail().first.errors.forEach(error => {
                        const errorModel = serverConnectionEditService.errorMap[self.serverConnection.selectedSourceType.key][error.code];
                        self.isTestSuccessful = false;
                        self.testConnectionInlineNotificationOptions.messageBoxAlertTypeClass = 'alert-danger';
                        self.testConnectionInlineNotificationOptions.messageBoxIconClass =
                            'fas fa-exclamation-triangle';
                        self.testConnectionInlineNotificationOptions.messageBoxTitle = errorModel.title;
                        self.testConnectionInlineNotificationOptions.messageBoxContent.push(errorModel.message);
                    });
                }
                // == returnToList ========
                function returnToList() {
                    $state.go('server-connections');
                }
                // == createServerConnectionAndReturnToList ========
                function createServerConnectionAndReturnToList() {
                    createServerConnection().then(function (id) {
                        if (id) {
                            returnToList();
                        }
                    });
                }
                // == closeModal ========
                function closeModal(id) {
                    serverConnectionEditService.closeServerConnectionModal(id);
                }
                // == createServerConnectionAndCloseModal ========
                function createServerConnectionAndCloseModal() {
                    createServerConnection().then(function (id) {
                        if (id) {
                            closeModal(id);
                        }
                    });
                }
                // == createServerConnection ========
                function createServerConnection() {
                    let serverConnectionPromise;
                    const newServerConnection = buildServerConnection(self.serverConnectionID, self.serverConnection.description);
                    if (self.serverConnectionID) {
                        serverConnectionPromise = serverConnectionService.updateServerConnection(newServerConnection);
                    }
                    else {
                        serverConnectionPromise = serverConnectionService.createServerConnection(newServerConnection);
                    }
                    self.form.setLock(true);
                    return serverConnectionPromise.then(function (response) {
                        if (!response.err) {
                            self.form.setLock(false);
                            return response.first.id;
                        }
                        else {
                            self.form.setLock(false);
                            serverConnectionService.getError().showErrorNotification(response);
                            return '';
                        }
                    }, function () {
                        self.form.setLock(false);
                        serverConnectionService.getError().showErrorNotification();
                        return '';
                    });
                }
                // == buildModelJson ========
                function buildServerConnection(id, description) {
                    const sc = getServerConnectionJson();
                    if (id) {
                        sc.id = id;
                    }
                    if (description) {
                        sc.description = description;
                    }
                    return sc;
                }
                // == buildTestJson ========
                function buildTestJson(mailbox) {
                    const json = getServerConnectionJson();
                    if (mailbox) {
                        json.mailbox = mailbox;
                    }
                    return json;
                }
                function getServerConnectionJson() {
                    const newServerConnection = {
                        serverType: self.serverConnection.selectedSourceType.key
                    };
                    if (self.serverConnection.selectedSourceType.key === self.serverConnectionEnum.EWS) {
                        newServerConnection.ewsSettings = _.pick(self.serverConnection.ewsSettings, [
                            'ewsUrl',
                            'adminUser',
                            'adminPassword'
                        ]);
                        newServerConnection.ewsSettings.encryptionMode =
                            self.serverConnection.ewsSettings.encryptionMode.key;
                    }
                    else if (self.serverConnection.selectedSourceType.key === self.serverConnectionEnum.OFFICE_365) {
                        newServerConnection.office365Settings = _.pick(self.serverConnection.office365Settings, ['clientId', 'tenantDomain']);
                        newServerConnection.office365Settings.serverSubtype =
                            self.serverConnection.office365Settings.serverSubtype.key;
                    }
                    return newServerConnection;
                }
                // == isFormValid ========
                function isFormValid() {
                    if (angular.isDefined(self.form)) {
                        return !self.form.isInvalid();
                    }
                    return false;
                }
                function setDefaultFormValues(newValue) {
                    if (newValue && newValue.key === self.serverConnectionEnum.EWS) {
                        self.serverConnection.ewsSettings = self.serverConnection.ewsSettings || {};
                        self.serverConnection.ewsSettings.encryptionMode =
                            self.serverConnection.ewsSettings.encryptionMode || self.serverSecurityModeOptions[0];
                    }
                    else if (newValue && newValue.key === self.serverConnectionEnum.OFFICE_365) {
                        self.serverConnection.office365Settings = self.serverConnection.office365Settings || {};
                        self.serverConnection.office365Settings.serverSubtype =
                            self.serverConnection.office365Settings.serverSubtype ||
                                self.serverOfficeEnvironmentOptions[0];
                    }
                }
                function isCreateServerConnectionDisabled() {
                    return (!isFormValid() ||
                        !serverConnectionEditService.hasServerConnectionEditPermission() ||
                        !self.isTestSuccessful);
                }
            };
        }
    ]);
})();
