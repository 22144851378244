"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CONFIRMATION_DIALOG_TYPES = void 0;
var CONFIRMATION_DIALOG_TYPES;
(function (CONFIRMATION_DIALOG_TYPES) {
    CONFIRMATION_DIALOG_TYPES["DELETE"] = "DELETE";
    CONFIRMATION_DIALOG_TYPES["CANCEL"] = "CANCEL";
    CONFIRMATION_DIALOG_TYPES["OVERRIDE"] = "OVERRIDE";
    CONFIRMATION_DIALOG_TYPES["SIGNED_MESSAGES"] = "SIGNED_MESSAGES";
    CONFIRMATION_DIALOG_TYPES["FROM_TO_EVERYONE"] = "FROM_TO_EVERYONE";
})(CONFIRMATION_DIALOG_TYPES = exports.CONFIRMATION_DIALOG_TYPES || (exports.CONFIRMATION_DIALOG_TYPES = {}));
