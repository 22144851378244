"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccessProviderComponent = void 0;
const core_1 = require("@angular/core");
const model_1 = require("../../model");
class AccessProviderComponent {
    constructor() {
        this.update = new core_1.EventEmitter();
    }
    isBox() {
        return this.provider.type === model_1.ProviderType.BOX;
    }
    isMs() {
        return (this.provider.type === model_1.ProviderType.ONE_DRIVE ||
            this.provider.type === model_1.ProviderType.MS_TEAMS ||
            this.provider.type === model_1.ProviderType.MS_TEAMS_GCC ||
            this.provider.type === model_1.ProviderType.OFFICE_365 ||
            this.provider.type === model_1.ProviderType.AZURE_STANDARD ||
            this.provider.type === model_1.ProviderType.AZURE_GCC ||
            this.provider.type === model_1.ProviderType.OFFICE_365_GCC);
    }
    updateToken(token) {
        this.update.emit(token);
    }
}
exports.AccessProviderComponent = AccessProviderComponent;
