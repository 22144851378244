"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiGatewaySaveSettingsStepComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const operators_1 = require("rxjs/operators");
const validators_1 = require("app-new/api-applications/validators");
class ApiGatewaySaveSettingsStepComponent {
    constructor(fb) {
        this.fb = fb;
        this.onApplicationSettingsUpdated = new core_1.EventEmitter();
        this.onSubmitted = new core_1.EventEmitter();
        this.form = this.fb.group({
            developerEmail: ['', [forms_1.Validators.required, forms_1.Validators.email, forms_1.Validators.maxLength(128)]],
            developerName: [
                '',
                [forms_1.Validators.required, forms_1.Validators.minLength(3), forms_1.Validators.maxLength(128), validators_1.validateNotEmpty]
            ],
            newsSubscription: [false]
        });
    }
    ngOnInit() {
        this.form.patchValue(this.applicationSettings || {});
        this.formSubscription = this.form.valueChanges.pipe(operators_1.debounceTime(200)).subscribe(() => {
            this.update();
        });
    }
    get pageTitle() {
        return `$I18N_API_GATEWAY_SAVE_WIZARD.${this.mode}.SETTINGS_STEP.HEADER`;
    }
    get pageDescription() {
        return `$I18N_API_GATEWAY_SAVE_WIZARD.${this.mode}.SETTINGS_STEP.DESCRIPTION`;
    }
    submit() {
        if (this.isValid()) {
            this.onSubmitted.emit(true);
        }
    }
    update() {
        this.onApplicationSettingsUpdated.emit(this.form.value);
    }
    isValid() {
        return this.form.status === 'VALID';
    }
    get isEditing() {
        return this.mode === 'EDIT';
    }
    ngOnDestroy() {
        this.formSubscription.unsubscribe();
    }
}
exports.ApiGatewaySaveSettingsStepComponent = ApiGatewaySaveSettingsStepComponent;
