<div class="mc-api-gateway-step-block">
  <mc-wizard-step-header [label]="pageTitle">
        <span *ngIf="isEditing">
      {{ pageDescription | translate }}
    </span>
  </mc-wizard-step-header>

  <form
    class="form-horizontal"
    mcDefaultLabelClass="col-sm-3"
    mcDefaultControlContainerClass="col-sm-9"
    [formGroup]="form"
    (ngSubmit)="submit()"
  >
    <div class="row form-group" placement="top"
         [tooltip]="'$I18N_API_GATEWAY_SAVE_WIZARD.CREATE.SETTINGS_STEP.TPOC_NAME_TOOLTIP' | translate">
      <mc-text-field
        errorPrefix="$I18N_API_GATEWAY_SAVE_WIZARD.VALIDATION.FIELD"
        label="$I18N_API_GATEWAY_SAVE_WIZARD.FIELDS.TECHNICAL_POINT_OF_CONTACT"
        formControlName="developerName"
        required
      >
      </mc-text-field>
    </div>

    <div class="row form-group">
      <mc-text-field
        errorPrefix="$I18N_API_GATEWAY_SAVE_WIZARD.VALIDATION.FIELD"
        label="$I18N_API_GATEWAY_SAVE_WIZARD.FIELDS.DEVELOPER_EMAIL"
        formControlName="developerEmail"
        required
      >
      </mc-text-field>
    </div>

    <div class="row form-group mc-api-gateway-step-settings-opt-in" placement="top"
         [tooltip]="'$I18N_API_GATEWAY_SAVE_WIZARD.FIELDS.OPT_IN_TOOLTIP' | translate">
      <mc-field>
        <mc-checkbox
          formControlName="newsSubscription"
          class="mc-checkbox"
          label="{{ '$I18N_API_GATEWAY_SAVE_WIZARD.FIELDS.OPT_IN' | translate }}">
        </mc-checkbox>
      </mc-field>
    </div>
  </form>
</div>
