"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectorsMigrationApiService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
class ConnectorsMigrationApiService {
    constructor(http) {
        this.http = http;
    }
    getConnectorMigrationStatus() {
        return this.http.post('/api/connectors-migration/get-connector-migration-status', null).pipe(operators_1.map((response) => {
            return response;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    migrateConnector(payload) {
        return this.http.post('/api/connectors-migration/migrate-legacy-connection', payload).pipe(operators_1.map((response) => {
            return response;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
}
exports.ConnectorsMigrationApiService = ConnectorsMigrationApiService;
