"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiEditIntegrationViewComponent = void 0;
const core_1 = require("@angular/core");
const operators_1 = require("rxjs/operators");
class ApiEditIntegrationViewComponent {
    constructor(fb) {
        this.fb = fb;
        this.onEditClicked = new core_1.EventEmitter();
        this.onDeleteClicked = new core_1.EventEmitter();
        this.onEnableClicked = new core_1.EventEmitter();
        this.onDisableClicked = new core_1.EventEmitter();
        this.oktaType = 'scim_okta';
    }
    ngOnInit() {
        this.enabledForm = this.fb.group({
            isEnabled: this.integration.enabled
        });
        this.enabledFormSubscription = this.enabledForm.valueChanges
            .pipe(operators_1.debounceTime(200))
            .subscribe((value) => {
            this.toggleEnabled(value.isEnabled);
        });
    }
    ngOnDestroy() {
        this.enabledFormSubscription.unsubscribe();
    }
    toggleEnabled(value) {
        if (value) {
            this.onEnableClicked.emit();
        }
        else {
            this.onDisableClicked.emit();
        }
    }
    enableClicked() {
        this.onEnableClicked.emit();
    }
    disableClicked() {
        this.onDisableClicked.emit();
    }
    isOkta() {
        return this.integration.integrationType === this.oktaType;
    }
}
exports.ApiEditIntegrationViewComponent = ApiEditIntegrationViewComponent;
