"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApPolicyInboundSettingsStepComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const ap_policy_inbound_static_values_1 = require("./ap-policy-inbound.static-values");
const group_selector_component_1 = require("../../../../../../components/group-selector/group-selector.component");
const reducers = require("../../../reducers");
const actions = require("../../../actions/policy.actions");
const operators_1 = require("rxjs/operators");
class ApPolicyInboundSettingsStepComponent {
    constructor(fb, asideService, store) {
        this.fb = fb;
        this.asideService = asideService;
        this.store = store;
        this.configurationOptions = ap_policy_inbound_static_values_1.configurationOptions;
        this.documentFormatOptions = ap_policy_inbound_static_values_1.documentFormatOptions;
        this.SpreadsheetsFormatOptions = ap_policy_inbound_static_values_1.SpreadsheetsFormatOptions;
        this.UserNotificationOptions = ap_policy_inbound_static_values_1.UserNotificationOptions;
        this.preEmptiveFailureActionOptions = ap_policy_inbound_static_values_1.PreEmptiveFailureActionOptions;
        this.updateApPolicyInboundType = new core_1.EventEmitter();
        this.openAdministratorsSidePanel = new core_1.EventEmitter();
        this.apInboundSettingsForm = this.fb.group({
            internalForward: [''],
            preEmptiveFailureAction: [''],
            ignoreSignedMessages: [''],
            safeDocFormat: [''],
            action: ['configurationOption', forms_1.Validators.required],
            safeSpreadsheetFormat: [''],
            removeActiveContent: [''],
            notifyInternalSender: [''],
            notifyInternalRecipient: [''],
            notifyExternalSender: ['']
        });
    }
    ngOnInit() {
        this.apInboundSettingsForm.patchValue(this.policyInbound);
        this.addedGroupsList$ = this.store.select(reducers.getAdministratorGroups);
        this.addedGroupsListSubscription = this.addedGroupsList$.subscribe(group => {
            this.selectGroupsLength = group;
        });
        this.apInboundSettingsFormSubscription = this.apInboundSettingsForm.valueChanges
            .pipe(operators_1.debounceTime(200))
            .subscribe(() => {
            this.updateInboundStore();
        });
    }
    updateInboundStore() {
        this.updateApPolicyInboundType.emit(this.apInboundSettingsForm.value);
    }
    onDeleteGroup(row) {
        this.store.dispatch(new actions.RemoveAdministratorGroupAction(row));
    }
    openSidebar() {
        const asideConfig = {};
        const aside = this.asideService.open(group_selector_component_1.GroupSelectorComponent, asideConfig);
        aside.beforeClose().subscribe(selectedGroup => {
            if (selectedGroup) {
                this.store.dispatch(new actions.AdministratorSelectGroup(selectedGroup));
            }
        });
    }
    isValid() {
        return this.apInboundSettingsForm.status === 'VALID';
    }
    ngOnDestroy() {
        this.addedGroupsListSubscription.unsubscribe();
    }
    onSubmit() { }
}
exports.ApPolicyInboundSettingsStepComponent = ApPolicyInboundSettingsStepComponent;
