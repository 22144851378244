"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.getApiIsProcessingDelete = exports.getBiPolicyDesc = exports.getBiPolicyCopy = exports.getBiPolicy = exports.getCreatePolicy = exports.getBiPolicyState = exports.reducers = void 0;
const biPolicy = require("../reducers/bi-policy.reducers");
const store_1 = require("@ngrx/store");
exports.reducers = {
    createPolicy: biPolicy.reducer
};
exports.getBiPolicyState = store_1.createFeatureSelector('biPolicies');
const ɵ0 = state => state.createPolicy;
exports.ɵ0 = ɵ0;
exports.getCreatePolicy = store_1.createSelector(exports.getBiPolicyState, ɵ0);
exports.getBiPolicy = store_1.createSelector(exports.getCreatePolicy, biPolicy.getBiPolicy);
exports.getBiPolicyCopy = store_1.createSelector(exports.getCreatePolicy, biPolicy.getBiPolicyCopy);
exports.getBiPolicyDesc = store_1.createSelector(exports.getCreatePolicy, biPolicy.getBiPolicyDesc);
exports.getApiIsProcessingDelete = store_1.createSelector(exports.getCreatePolicy, biPolicy.getApiIsProcessingDelete);
