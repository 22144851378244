"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
angular.module('mimecast.central.service', [])
    .factory('mimecastCentralService', ['$http', 'capabilitiesService', ($http, capabilitiesService) => {
        return {
            searchContent: capabilitiesService.hasCapability('Temp.MimecasterCentral.Salesforce') ? searchSfdc : searchJive
        };
        // JIVE (pre-October 2019)
        function searchJive(query) {
            const httpGetConfig = {
                transformResponse: (rawResponse) => {
                    return rawResponse.replace("throw 'allowIllegalResourceCall is false.';", '');
                }
            };
            const formattedQuery = '/administration/proxy/mc-central-help/search/contents?filter=search('
                + encodeURIComponent(query)
                + '*)&filter=type(document)&sortBy=updatedDesc&collapse=true&fields=type,author,highlightBody,subject,'
                + 'resources,published,updated,contentID,parentPlace,summary.@all,discussion,outcomeCounts,visibleToExternalContributors,'
                + 'uuid,index,promotedResult&count=10&origin=spotlight';
            return $http.get(formattedQuery, httpGetConfig).
                then((response) => {
                if ((response.status === 200) && (!!response.data)) {
                    return angular.fromJson(response.data);
                }
                return response;
            });
        }
        // SFDC (post-October 2019)
        function searchSfdc(query) {
            const httpGetConfig = {
                transformResponse: (rawResponse) => {
                    return rawResponse;
                }
            };
            const formattedQuery = '/administration/proxy/mc-central-sfdc/support/knowledgeArticles?language="en-US"&q="'
                + encodeURIComponent(query)
                + '"';
            return $http.get(formattedQuery, httpGetConfig).
                then((response) => {
                if ((response.status === 200) && (!!response.data)) {
                    const data = angular.fromJson(response.data);
                    return {
                        list: data.articles.map((article) => {
                            return {
                                id: article.id,
                                subject: article.title,
                                updated: article.lastPublishedDate,
                                parentPlace: {
                                    name: (!!article.categoryGroups &&
                                        !!article.categoryGroups[0] &&
                                        !!article.categoryGroups[0].selectedCategories &&
                                        !!article.categoryGroups[0].selectedCategories[0] &&
                                        article.categoryGroups[0].selectedCategories[0].categoryLabel)
                                },
                                resources: {
                                    html: {
                                        ref: 'https://community.mimecast.com/' + article.id
                                    }
                                },
                                highlightBody: article.summary
                            };
                        })
                    };
                }
                return response;
            });
        }
    }]);
