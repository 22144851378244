<div class="mc-api-gateway-step-block">
  <mc-wizard-step-header
    [label]="'$I18N_API_GATEWAY_APPLICATIONS_KEYGEN_WIZARD.ACCOUNT_STEP.HEADER' | translate"
  >
    {{ '$I18N_API_GATEWAY_APPLICATIONS_KEYGEN_WIZARD.ACCOUNT_STEP.DESCRIPTION' | translate }}
    <div *ngIf="isError">
      <br />
      <mc-inline-notification
        [notification]="{ status: 'warning', boxed: true, msg: errorMessage | translate }"
      >
      </mc-inline-notification>
    </div>
  </mc-wizard-step-header>

  <form
    class="form-horizontal"
    mcDefaultLabelClass="col-sm-3"
    mcDefaultControlContainerClass="col-sm-9"
    [formGroup]="form"
    (ngSubmit)="submit()"
  >
    <div class="row form-group">
      <mc-text-field
        errorPrefix="$I18N_API_GATEWAY_APPLICATIONS_KEYGEN_WIZARD.VALIDATION.FIELD"
        label="$I18N_API_GATEWAY_APPLICATIONS_KEYGEN_WIZARD.FIELDS.ACCOUNT_EMAIL_ADDRESS"
        formControlName="emailAddress"
        required
      >
      </mc-text-field>
    </div>
  </form>
</div>
