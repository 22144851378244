"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WizardModule = void 0;
const policySteps = require("./policy/steps");
const policy_component_1 = require("./policy/policy.component");
const steps_1 = require("./policy/steps");
const cancel_confirmation_modal_component_1 = require("./cancel-confirmation-modal/cancel-confirmation-modal.component");
const DECLARATIONS = [
    cancel_confirmation_modal_component_1.CancelConfirmationModalComponent,
    policy_component_1.PolicyWizardComponent,
    steps_1.PolicyWizardDetailsComponent,
    policySteps.steps
];
class WizardModule {
}
exports.WizardModule = WizardModule;
