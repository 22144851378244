"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrackingMainSearchComponent = void 0;
const core_1 = require("@angular/core");
class TrackingMainSearchComponent {
    constructor(stateService, tabService) {
        this.stateService = stateService;
        this.tabService = tabService;
        this.search = new core_1.EventEmitter();
        const tab = this.tabService.getSelectedTab();
        tab.onRefresh = () => {
            if (this.externalTableSearchCount) {
                this.search.emit(this.lastSearch);
            }
            else {
                this.stateService.$state.go('trackingMessage', {}, { reload: true, inherit: false, notify: true });
            }
        };
    }
    triggerSearch(searchPayload) {
        this.search.emit(searchPayload);
        this.lastSearch = searchPayload;
    }
}
exports.TrackingMainSearchComponent = TrackingMainSearchComponent;
