<ng-container>
  <mc-layout-aside-extra-container
    keyTitle="$I18N_DIRECTORY_SYNC_ASIDE.ASIDE_TITLE"
    [isLoading]="false"
    showClose="true"
    (closeAside)="closeAside()">
    <mc-extra-container *mcCapabilities="'Permission.DIRECTORY_SYNC_EDIT && !Permission.EAM_DIRECTORY_SYNC_READ'">

      <div class="mc-container-actions-area">
        <div class="mc-container-buttons">
          <mc-live-button
            typeClass="mc-btn btn btn-primary"
            (mcClick)="editIntegration()"
            content="{{ '$I18N_COMMON_BTN_EDIT' | translate }}"
          >
          </mc-live-button>
          <mc-live-button
            typeClass="mc-btn btn btn-secondary"
            (mcClick)="deleteIntegration()"
            content="{{ '$I18N_COMMON_BTN_DELETE' | translate }}"
          >
          </mc-live-button>
        </div>

        <div class="mc-container-switch">
          <form novalidate [formGroup]="enabledForm">
            <mc-enable-switch formControlName="isEnabled"></mc-enable-switch>
          </form>
        </div>
      </div>
    </mc-extra-container>
    <mc-body-container class="no-padding-horizontal no-padding-top">
      <ng-container *ngIf="isMSEIntegration()">
        <div class="panel-body no-padding-bottom">
          <mc-directory-sync-aside-summary
            [integration]="summaryIntegration$ | async"
            [mseSites]="mseSites"></mc-directory-sync-aside-summary>
        </div>
      </ng-container>
      <ng-container *ngIf="!isMSEIntegration()">
        <mc-tab-group [initialActiveTabIndex]="0" (tabChange)="onTabChange($event)">
          <mc-tab name="$I18N_DIRECTORY_SYNC_ASIDE.TAB.SUMMARY" data-test="mc-directory-sync-summary-tab">
            <div class="panel-body no-padding-bottom">
              <mc-directory-sync-aside-summary [integration]="summaryIntegration$ | async"></mc-directory-sync-aside-summary>
            </div>
          </mc-tab>
          <mc-tab name="$I18N_DIRECTORY_SYNC_ASIDE.TAB.TEST" data-test="mc-directory-sync-test-tab">
            <div class="panel-body no-padding-bottom">
              <button class="btn btn-secondary mc-margin-bottom" [disabled]="!integrationTestComponent?.isRestartButtonEnabled"
                      (click)="integrationTestComponent.retryConnectionTest()"
                      data-test="mc-restart-test-button">
                {{ '$I18N_DIRECTORY_SYNC_CONNECTION_INFO.BUTTON.RESTART_TEST' | translate }}
              </button>
              <mc-directory-sync-integration-test [info]="connectionInfo"></mc-directory-sync-integration-test>
            </div>
          </mc-tab>

          <mc-tab name="$I18N_DIRECTORY_SYNC_ASIDE.TAB.BROWSER" data-test="mc-directory-sync-browser-tab" *ngIf="!isGsuiteConnection()">
            <div class="panel-body no-padding-bottom">
              <ng-container *ngIf="isLdapConnection()">
                <mc-directory-sync-aside-directory-browser [integration]="summaryIntegration$ | async"></mc-directory-sync-aside-directory-browser>
              </ng-container>
              <ng-container *ngIf="isAzureConnection()">
                <p>{{ '$I18N_DIRECTORY_SYNC_ASIDE.DIRECTORY_BROWSER.AZURE.MESSAGE' | translate }}
                  <a href="{{ azureLink }}" target="_blank" rel="noopener noreferrer">
                    {{ '$I18N_DIRECTORY_SYNC_ASIDE.DIRECTORY_BROWSER.AZURE.LINK_TEXT' | translate }}
                  </a>.
                </p>
              </ng-container>
            </div>
          </mc-tab>
        </mc-tab-group>
      </ng-container>
    </mc-body-container>
  </mc-layout-aside-extra-container>
</ng-container>
