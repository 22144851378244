"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailComposeService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
class EmailComposeService {
    constructor(fileUploadService) {
        this.fileUploadService = fileUploadService;
        this._newAttachments$ = new rxjs_1.BehaviorSubject([]);
        this._oldAttachments$ = new rxjs_1.BehaviorSubject([]);
        this.oldAttachments$ = this._oldAttachments$.asObservable();
        this.newAttachments$ = this._newAttachments$.asObservable();
        this.filesUploadingCount$ = this._newAttachments$.pipe(operators_1.map(attachments => attachments.filter(f => f.progress < 100).length), operators_1.distinct());
    }
    get allAttachments() {
        return [...this._oldAttachments$.value, ...this._newAttachments$.value];
    }
    init(oldAttachments) {
        this._oldAttachments$.next(oldAttachments || []);
        this._newAttachments$.next([]);
    }
    removeOld(file) {
        this.remove(file, this._oldAttachments$);
    }
    removeNew(file) {
        this.remove(file, this._newAttachments$);
    }
    upload(file) {
        let position = -1;
        this.fileUploadService
            .uploadFile(file)
            .pipe(operators_1.tap(attachment => {
            if (position > -1) {
                this.insert(attachment, position);
            }
            else {
                position = this.add(attachment);
            }
        }), operators_1.catchError(err => {
            return rxjs_1.of('Error uploading ' + err);
        }))
            .subscribe();
    }
    remove(file, attachments) {
        attachments.next(attachments.value.filter(f => f !== file));
    }
    add(file) {
        const value = [...this._newAttachments$.value, file];
        this._newAttachments$.next(value);
        return value.length - 1;
    }
    insert(file, position) {
        const value = [...this._newAttachments$.value];
        value[position] = file;
        this._newAttachments$.next(value);
        return value.length - 1;
    }
}
exports.EmailComposeService = EmailComposeService;
