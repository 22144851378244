"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
(function () {
    'use strict';
    /* ======================================================== *\

     = dashboards / administration / aside - controller
     = 07/01/2016
     = joao carradinha

     \* ======================================================== */
    angular.module('dashboards-administration-aside.controller', [])
        .controller('DashboardsAdministrationAsideController', ['$scope', function ($scope) {
            const self = this;
            /* -------------------------------------------------------- *\
             - interface
             \* -------------------------------------------------------- */
            self.asideActions = {
                closePanel: $scope.$close
            };
            /* -------------------------------------------------------- *\
             - initialisation
             \* -------------------------------------------------------- */
            const items = [];
            items.push({
                label: '$I18N_SIDE_MENU_ITEM_ATTACHMENT_PROTECT',
                permission: 'Permission.SERVICES_TTP_ATTACHMENT_PROTECT_READ && !Temporary.Services.TTP.AttachmentProtection',
                goTo: "legacy({'uid':'targetedthreatprotection-attachmentprotect'})"
            });
            items.push({
                label: '$I18N_SIDE_MENU_ITEM_ATTACHMENT_PROTECT',
                permission: 'Permission.SERVICES_TTP_ATTACHMENT_PROTECT_READ && Temporary.Services.TTP.AttachmentProtection',
                goTo: 'attachment-protection'
            });
            items.push({
                label: '$I18N_SIDE_MENU_ITEM_LABEL_LARGE_FILE_SEND',
                permission: 'Permission.LARGE_FILE_SEND_READ',
                goTo: "legacy({'uid':'largefilesend'})"
            });
            items.push({
                label: '$I18N_MEGA_MENU_SERVICES_SMS_DASHBOARD_SUB_LABEL',
                permission: 'Permission.SMS_DASHBOARD_READ',
                goTo: "legacy({'uid':'smsdashboard'})"
            });
            items.push({
                label: '$I18N_SIDE_MENU_ITEM_URL_PROTECT',
                permission: 'Permission.SERVICES_TTP_URL_PROTECT_READ && !Temporary.Services.TTP.URLProtection',
                goTo: "legacy({'uid':'targetedthreatprotection-urlprotect'})"
            });
            items.push({
                label: '$I18N_SIDE_MENU_ITEM_URL_PROTECT',
                permission: 'Permission.SERVICES_TTP_URL_PROTECT_READ && Temporary.Services.TTP.URLProtection',
                goTo: 'url-protection'
            });
            items.push({
                label: '$I18N_SIDE_MENU_ITEM_THREAT_REMEDIATION',
                permission: 'Permission.THREAT_REMEDIATION_READ && Threat.Remediation',
                goTo: 'remediation'
            });
            items.push({
                label: '$I18N_SIDE_MENU_ITEM_THREAT_INTEL',
                permission: 'Permission.THREAT_INTELLIGENCE_READ && Threat.Intel.Dashboard && EarlyAccess.Threat.Intel.Dashboard',
                goTo: 'threat-intel'
            });
            items.push({
                label: '$I18N_MEGA_MENU_GROUP_SECURE_WEB_GATEWAY_DASHBOARD_ASIDE',
                permission: 'Permission.SWG_DASHBOARD_READ',
                goTo: 'secure-web-gateway-dashboard'
            });
            $scope.asideItems = items;
        }]);
}());
