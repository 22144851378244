"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgEndpointsSettingsCancelTUIDAttributeModalAction = exports.SwgEndpointsSettingsOpenTUIDAttributeModalAction = exports.SwgEndpointsSettingsCancelAllowLocalIPsModalAction = exports.SwgEndpointsSettingsOpenAllowLocalIPsModalAction = exports.SwgEndpointsSettingsCancelDirectIpModalAction = exports.SwgEndpointsSettingsOpenDirectIpModalAction = exports.SwgEndpointsSettingsCloseEnableUninstallPasswordConfirmModalAction = exports.SwgEndpointsSettingsOpenEnableUninstallPasswordConfirmModalAction = exports.SwgEndpointsSettingsOpenUninstallGeneratePasswordModalAction = exports.SwgEndpointsSettingsOpenAntiTamperGeneratePasswordModalAction = exports.SwgEndpointsSettingsGetUninstallPasswordSuccessAction = exports.SwgEndpointsSettingsGetUninstallPasswordAction = exports.SwgEndpointsSettingsGetTamperProtectedPasswordSuccessAction = exports.SwgEndpointsSettingsGetTamperProtectedPasswordAction = exports.SwgEndpointsSettingsFailureAction = exports.SwgEndpointsSettingsUpdateSettingsSuccessAction = exports.SwgEndpointsSettingsUpdateSettingsAction = exports.SwgEndpointsSettingsGetSettingsSuccessAction = exports.SwgEndpointsSettingsGetSettingsAction = exports.SwgEndpointSettingsActions = void 0;
var SwgEndpointSettingsActions;
(function (SwgEndpointSettingsActions) {
    SwgEndpointSettingsActions["GET_SETTINGS"] = "[SwgEndpointSettings] Get Settings";
    SwgEndpointSettingsActions["GET_SETTINGS_SUCCESS"] = "[SwgEndpointSettings] Get Settings Success";
    SwgEndpointSettingsActions["UPDATE_SETTINGS"] = "[SwgEndpointSettings] Update Settings";
    SwgEndpointSettingsActions["UPDATE_SETTINGS_SUCCESS"] = "[SwgEndpointSettings] Update Settings Success";
    SwgEndpointSettingsActions["SETTINGS_FAILURE"] = "[SwgEndpointSettings] Update Settings Failure";
    SwgEndpointSettingsActions["GET_TAMPER_PROTECTED_PASSWORD"] = "[SwgEndpointSettings] Get Tamper Protected Password";
    SwgEndpointSettingsActions["GET_TAMPER_PROTECTED_PASSWORD_SUCCESS"] = "[SwgEndpointSettings] Get Tamper Protected Password Success";
    SwgEndpointSettingsActions["GET_UNINSTALL_PASSWORD"] = "[SwgEndpointSettings] Get Uninstall Password";
    SwgEndpointSettingsActions["GET_UNINSTALL_PASSWORD_SUCCESS"] = "[SwgEndpointSettings] Get Uninstall Password Success";
    SwgEndpointSettingsActions["OPEN_ANTI_TAMPER_GENERATE_PASSWORD_MODAL"] = "[SwgEndpointSettings] Open Anti-Tamper Generate Password Modal";
    SwgEndpointSettingsActions["OPEN_UNINSTALL_GENERATE_PASSWORD_MODAL"] = "[SwgEndpointSettings] Open Uninstall Generate Password Modal";
    SwgEndpointSettingsActions["OPEN_ENABLE_UNINSTALL_PASSWORD_CONFIRM_MODAL"] = "[SwgEndpointSettings] Open Enable Uninstall Password Confirm Modal";
    SwgEndpointSettingsActions["CLOSE_ENABLE_UNINSTALL_PASSWORD_CONFIRM_MODAL"] = "[SwgEndpointSettings] Close Enable Uninstall Password Confirm Modal";
    SwgEndpointSettingsActions["OPEN_DIRECT_IP_CONFIRM_MODAL"] = "[SwgEndpointSettings] Open Direct IP Modal";
    SwgEndpointSettingsActions["CANCEL_DIRECT_IP_CONFIRM_MODAL"] = "[SwgEndpointSettings] Cancel Direct IP Modal";
    SwgEndpointSettingsActions["OPEN_ALLOW_LOCAL_IPS_CONFIRM_MODAL"] = "[SwgEndpointSettings] Open Allow Local IPs Modal";
    SwgEndpointSettingsActions["CANCEL_ALLOW_LOCAL_IPS_CONFIRM_MODAL"] = "[SwgEndpointSettings] Cancel Allow Local IPs Modal";
    SwgEndpointSettingsActions["OPEN_TUID_ATTRIBUTE_CONFIRM_MODAL"] = "[SwgEndpointSettings] Open TUID Attribute Modal";
    SwgEndpointSettingsActions["CANCEL_TUID_ATTRIBUTE_CONFIRM_MODAL"] = "[SwgEndpointSettings] Cancel TUID Attribute Modal";
})(SwgEndpointSettingsActions = exports.SwgEndpointSettingsActions || (exports.SwgEndpointSettingsActions = {}));
class SwgEndpointsSettingsGetSettingsAction {
    constructor() {
        this.type = SwgEndpointSettingsActions.GET_SETTINGS;
    }
}
exports.SwgEndpointsSettingsGetSettingsAction = SwgEndpointsSettingsGetSettingsAction;
class SwgEndpointsSettingsGetSettingsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgEndpointSettingsActions.GET_SETTINGS_SUCCESS;
    }
}
exports.SwgEndpointsSettingsGetSettingsSuccessAction = SwgEndpointsSettingsGetSettingsSuccessAction;
class SwgEndpointsSettingsUpdateSettingsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgEndpointSettingsActions.UPDATE_SETTINGS;
    }
}
exports.SwgEndpointsSettingsUpdateSettingsAction = SwgEndpointsSettingsUpdateSettingsAction;
class SwgEndpointsSettingsUpdateSettingsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgEndpointSettingsActions.UPDATE_SETTINGS_SUCCESS;
    }
}
exports.SwgEndpointsSettingsUpdateSettingsSuccessAction = SwgEndpointsSettingsUpdateSettingsSuccessAction;
class SwgEndpointsSettingsFailureAction {
    constructor() {
        this.type = SwgEndpointSettingsActions.SETTINGS_FAILURE;
    }
}
exports.SwgEndpointsSettingsFailureAction = SwgEndpointsSettingsFailureAction;
class SwgEndpointsSettingsGetTamperProtectedPasswordAction {
    constructor(payload = false) {
        this.payload = payload;
        this.type = SwgEndpointSettingsActions.GET_TAMPER_PROTECTED_PASSWORD;
    }
}
exports.SwgEndpointsSettingsGetTamperProtectedPasswordAction = SwgEndpointsSettingsGetTamperProtectedPasswordAction;
class SwgEndpointsSettingsGetTamperProtectedPasswordSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgEndpointSettingsActions.GET_TAMPER_PROTECTED_PASSWORD_SUCCESS;
    }
}
exports.SwgEndpointsSettingsGetTamperProtectedPasswordSuccessAction = SwgEndpointsSettingsGetTamperProtectedPasswordSuccessAction;
class SwgEndpointsSettingsGetUninstallPasswordAction {
    constructor(payload = false) {
        this.payload = payload;
        this.type = SwgEndpointSettingsActions.GET_UNINSTALL_PASSWORD;
    }
}
exports.SwgEndpointsSettingsGetUninstallPasswordAction = SwgEndpointsSettingsGetUninstallPasswordAction;
class SwgEndpointsSettingsGetUninstallPasswordSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgEndpointSettingsActions.GET_UNINSTALL_PASSWORD_SUCCESS;
    }
}
exports.SwgEndpointsSettingsGetUninstallPasswordSuccessAction = SwgEndpointsSettingsGetUninstallPasswordSuccessAction;
class SwgEndpointsSettingsOpenAntiTamperGeneratePasswordModalAction {
    constructor() {
        this.type = SwgEndpointSettingsActions.OPEN_ANTI_TAMPER_GENERATE_PASSWORD_MODAL;
    }
}
exports.SwgEndpointsSettingsOpenAntiTamperGeneratePasswordModalAction = SwgEndpointsSettingsOpenAntiTamperGeneratePasswordModalAction;
class SwgEndpointsSettingsOpenUninstallGeneratePasswordModalAction {
    constructor() {
        this.type = SwgEndpointSettingsActions.OPEN_UNINSTALL_GENERATE_PASSWORD_MODAL;
    }
}
exports.SwgEndpointsSettingsOpenUninstallGeneratePasswordModalAction = SwgEndpointsSettingsOpenUninstallGeneratePasswordModalAction;
class SwgEndpointsSettingsOpenEnableUninstallPasswordConfirmModalAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgEndpointSettingsActions.OPEN_ENABLE_UNINSTALL_PASSWORD_CONFIRM_MODAL;
    }
}
exports.SwgEndpointsSettingsOpenEnableUninstallPasswordConfirmModalAction = SwgEndpointsSettingsOpenEnableUninstallPasswordConfirmModalAction;
class SwgEndpointsSettingsCloseEnableUninstallPasswordConfirmModalAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgEndpointSettingsActions.CLOSE_ENABLE_UNINSTALL_PASSWORD_CONFIRM_MODAL;
    }
}
exports.SwgEndpointsSettingsCloseEnableUninstallPasswordConfirmModalAction = SwgEndpointsSettingsCloseEnableUninstallPasswordConfirmModalAction;
class SwgEndpointsSettingsOpenDirectIpModalAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgEndpointSettingsActions.OPEN_DIRECT_IP_CONFIRM_MODAL;
    }
}
exports.SwgEndpointsSettingsOpenDirectIpModalAction = SwgEndpointsSettingsOpenDirectIpModalAction;
class SwgEndpointsSettingsCancelDirectIpModalAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgEndpointSettingsActions.CANCEL_DIRECT_IP_CONFIRM_MODAL;
    }
}
exports.SwgEndpointsSettingsCancelDirectIpModalAction = SwgEndpointsSettingsCancelDirectIpModalAction;
class SwgEndpointsSettingsOpenAllowLocalIPsModalAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgEndpointSettingsActions.OPEN_ALLOW_LOCAL_IPS_CONFIRM_MODAL;
    }
}
exports.SwgEndpointsSettingsOpenAllowLocalIPsModalAction = SwgEndpointsSettingsOpenAllowLocalIPsModalAction;
class SwgEndpointsSettingsCancelAllowLocalIPsModalAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgEndpointSettingsActions.CANCEL_ALLOW_LOCAL_IPS_CONFIRM_MODAL;
    }
}
exports.SwgEndpointsSettingsCancelAllowLocalIPsModalAction = SwgEndpointsSettingsCancelAllowLocalIPsModalAction;
class SwgEndpointsSettingsOpenTUIDAttributeModalAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgEndpointSettingsActions.OPEN_TUID_ATTRIBUTE_CONFIRM_MODAL;
    }
}
exports.SwgEndpointsSettingsOpenTUIDAttributeModalAction = SwgEndpointsSettingsOpenTUIDAttributeModalAction;
class SwgEndpointsSettingsCancelTUIDAttributeModalAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgEndpointSettingsActions.CANCEL_TUID_ATTRIBUTE_CONFIRM_MODAL;
    }
}
exports.SwgEndpointsSettingsCancelTUIDAttributeModalAction = SwgEndpointsSettingsCancelTUIDAttributeModalAction;
