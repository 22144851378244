"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducer = exports.initialState = void 0;
const decode_url_actions_1 = require("../../actions/decode-url.actions");
exports.initialState = {
    loading: false,
    hasError: false,
    urlDetails: {
        success: false,
        url: ''
    }
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case decode_url_actions_1.DECODE_URL_ATTEMPT:
            return Object.assign(Object.assign({}, state), { loading: true, hasError: false });
        case decode_url_actions_1.DECODE_URL_FAIL:
            return Object.assign(Object.assign({}, state), { loading: false, hasError: true });
        case decode_url_actions_1.DECODE_URL_SUCCESS:
            return Object.assign(Object.assign({}, state), { urlDetails: action.payload, loading: false, hasError: false });
        default:
            return Object.assign({}, state);
    }
}
exports.reducer = reducer;
