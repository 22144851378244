<mc-layout-list-extra-container
  keyTitle="$I18N_DIRECTORY_SYNC_PAGE.TITLE"
  keyDescription="$I18N_DIRECTORY_SYNC_PAGE.DESCRIPTION"
>
  <mc-help-container>
    <span *mcCapabilities="'temporary.enable.dir.sync.link'">
      <a class="panel-padding-right" href="{{ getUrlBasedOnAccountType() }}" target="_self">
        {{ '$I18N_DIRECTORY_SYNC_PAGE.SWITCH' | translate }}
      </a>
    </span>
    <mc-help-link url="{{ dirSyncKbLink }}"></mc-help-link>
  </mc-help-container>
  <mc-body-container>
    <ng-container *mcCapabilities="'Permission.DIRECTORY_SYNC_READ'">
      <mc-directory-sync-table></mc-directory-sync-table>
    </ng-container>
    <ng-container *mcCapabilities="'Permission.EAM_DIRECTORY_SYNC_READ'">
      <mc-aaa-directory-sync-table></mc-aaa-directory-sync-table>
    </ng-container>
  </mc-body-container>
</mc-layout-list-extra-container>

