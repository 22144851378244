<div class="mc-container-actions-area">
  <div class="mc-container-buttons">
    <mc-live-button
      typeClass="mc-btn btn btn-primary"
      (mcClick)="onEditClicked.emit()"
      content="{{ '$I18N_COMMON_BTN_EDIT' | translate }}"
    >
    </mc-live-button>
    <mc-live-button *ngIf="isOkta()"
      typeClass="mc-btn btn btn-secondary"
      (mcClick)="onDeleteClicked.emit()"
      content="{{ '$I18N_COMMON_BTN_DELETE' | translate }}"
    >
    </mc-live-button>
  </div>

  <div class="mc-container-switch">
    <form novalidate [formGroup]="enabledForm">
      <mc-enable-switch formControlName="isEnabled"></mc-enable-switch>
    </form>
  </div>
</div>
