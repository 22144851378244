"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateImSyncTaskContainerComponent = void 0;
const rxjs_1 = require("rxjs");
const selectors = require("../../reducers/index");
const im_sync_actions_1 = require("../../actions/im-sync.actions");
class UpdateImSyncTaskContainerComponent {
    constructor(store, overlayRef) {
        this.store = store;
        this.overlayRef = overlayRef;
        this.destroy$ = new rxjs_1.Subject();
        this.imTask$ = this.store.select(selectors.getImTaskDetails);
        this.isLoading$ = this.store.select(selectors.getImTaskLoading);
    }
    close() {
        this.overlayRef.close();
    }
    updateImSyncTask(updatedImSyncTaskDetails) {
        this.store.dispatch(new im_sync_actions_1.UpdateImSyncTaskAction(updatedImSyncTaskDetails));
        this.close();
    }
    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
}
exports.UpdateImSyncTaskContainerComponent = UpdateImSyncTaskContainerComponent;
