<mc-layout-list-table
  keyTitle="$I18N_SWG_POLICIES_LIST_PAGE_TITLE"
  extendClass="mc-create-policy"
  keyDescription="$I18N_SWG_POLICIES_LIST_PAGE_DESC"
>
  <mc-help-container>
    <mc-help-link url="https://community.mimecast.com/docs/DOC-3187"></mc-help-link>
  </mc-help-container>
  <mc-table
    [isLoading]="isLoading$ | async"
    [data]="tableData$ | async"
    [columns]="columnList"
    translatePrefix="$I18N_SWG_POLICIES_LIST"
    (rowClick)="rowClick.emit($event)"
  >
    <button
      class="mc-table-actions btn btn-primary panel-half-margin-right"
      (click)="goToCreatePolicy()"
    >
      {{ '$I18N_SWG_CREATE_POLICY_CREATE_NEW_POLICY' | translate }}
    </button>

    <button
      class="mc-table-actions btn btn-secondary "
      (click)="goToBlockPage()"
      *mcCapabilities="'Temporary.Permission.swg.block.page.settings'"
    >
      {{ '$I18N_SWG_BLOCK_PAGE_TITLE' | translate }}
    </button>
    <mc-filters (paginatorChange)="onPaginationChange($event)" [metadata]="metaData$ | async">
      <mc-filter-search
        class="mc-filter-search"
        (search)="onSearchPolicies($event)"
        (clear)="onSearchClear()"
        [placeholder]="'$I18N_ENDPOINTS_LIST_SEARCH_PLACEHOLDER' | translate"
      >
      </mc-filter-search>
    </mc-filters>
    <mc-column key="description">
      <mc-body-cell *mcBodyCellDef="let row">
        <div class="mc-status-text-container">
          <span>{{ row.policyDesc.description }}</span>
        </div>
      </mc-body-cell>
    </mc-column>
    <mc-column key="policyType">
      <mc-body-cell *mcBodyCellDef="let row">
        <div class="mc-status-text-container">
          <span>{{ getPolicyDesc(row.policyDesc.policyType) | uppercase | translate }}</span>
        </div>
      </mc-body-cell>
    </mc-column>
    <mc-column key="status">
      <mc-body-cell *mcBodyCellDef="let row">
        <mc-enabled-status [enabled]="isPolicyEnabled(row)"></mc-enabled-status>
      </mc-body-cell>
    </mc-column>
    <mc-column-date key="lastModified"></mc-column-date>
    <mc-column key="affectedUsers">
      <mc-body-cell *mcBodyCellDef="let row">
        <div class="mc-status-text-container" *ngFor="let affectedUser of row.affectedUsers">
          <span *ngIf="affectedUser.type === swgAffectedUsersEnum.EVERYONE">{{
            '$I18N_SWG_CREATE_POLICY_AFFECTED_USERS_EVERYONE' | translate
          }}</span>
          <span *ngIf="affectedUser.type === swgAffectedUsersEnum.LOCATION">{{
            affectedUser.description + ' (' + affectedUser.ipRange + ')'
          }}</span>
          <span *ngIf="affectedUser.type === swgAffectedUsersEnum.USER">{{
            affectedUser.emailAddress
          }}</span>
          <span *ngIf="affectedUser.type === swgAffectedUsersEnum.GROUP">{{
            affectedUser.description
          }}</span>
          <span *ngIf="affectedUser.type === swgAffectedUsersEnum.MULTI">{{
            affectedUser.description
          }}</span>
          <span *ngIf="affectedUser.type === swgAffectedUsersEnum.WEB_DEVICE">{{
            affectedUser.device? affectedUser.device.description: ''
            }}</span>
          <span *ngIf="affectedUser.type === swgAffectedUsersEnum.WEB_DEVICE_GROUP">{{
            affectedUser.deviceGroup? affectedUser.deviceGroup.description: ''
            }}</span>
        </div>
      </mc-body-cell>
    </mc-column>

    <mc-column-actions
            key="right-column"
            mcShowColumnConfig
            [columnsAlwaysVisible]="columnsAlwaysVisible"
    >
      <mc-row-actions *mcRowActions="let row">
        <li mcRowAction="$I18N_COMMON_BTN_EDIT" (click)="onEditClick(row)"></li>
        <li mcRowAction="$I18N_COMMON_BTN_DISABLE"
            *ngIf="row.policyDesc.enabled && hasEditPermission && row.policyDesc.policyType !== swgPolicyType.CLOUD_PROTECT_POLICY"
            (click)="onDisable(row)"></li>
        <li mcRowAction="$I18N_COMMON_TEXT_ENABLE"
            *ngIf="!row.policyDesc.enabled && hasEditPermission && row.policyDesc.policyType !== swgPolicyType.CLOUD_PROTECT_POLICY"
            (click)="onDisable(row)"></li>
        <li mcRowAction="$I18N_COMMON_BTN_DUPLICATE"
            *ngIf="!row.clpDefaultPolicy"
            (click)="onDuplicateClick(row)"></li>
        <li
          mcRowAction="$I18N_COMMON_BTN_DELETE"
          *ngIf="hasEditPermission && !row.clpDefaultPolicy"
          (click)="onDeleteClick(row)"
        ></li>
      </mc-row-actions>
    </mc-column-actions>

    <mc-empty-results keyTitle="$I18N_SWG_POLICIES_LIST_NO_DATA">
      <mc-empty-body *ngIf="hasEditPermission">
        {{'$I18N_SWG_POLICIES_LIST_NO_DATA_BODY_PART_1' | translate}}
        <button class="btn btn-link mc-empty-body-link" (click)="goToCreatePolicy()">
          {{'$I18N_SWG_POLICIES_LIST_NO_DATA_BODY_PART_2' | translate}}
        </button>
      </mc-empty-body>
     </mc-empty-results>

  </mc-table>
</mc-layout-list-table>
