"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgEndpointsListMapStatusTextPipe = exports.SwgEndpointsListMapStatusColorsPipe = void 0;
class SwgEndpointsListMapStatusColorsPipe {
    transform(status) {
        return this.mapStatusColors(status);
    }
    mapStatusColors(status) {
        let className;
        switch (status) {
            case 'disabled':
            case 'unprotected':
                className = 'mc-status-red';
                break;
            case 'protected':
                className = 'mc-status-green';
                break;
        }
        return className;
    }
}
exports.SwgEndpointsListMapStatusColorsPipe = SwgEndpointsListMapStatusColorsPipe;
class SwgEndpointsListMapStatusTextPipe {
    transform(status) {
        return this.mapStatusText(status);
    }
    mapStatusText(status) {
        let text = '$I18N_ENDPOINTS_LIST.TABLE.TABLE_CELL.STATUS';
        switch (status) {
            case 'disabled':
                text = `${text}.DISABLED`;
                break;
            case 'no_internet_connection':
                text = `${text}.NO_INTERNET_CONNECTION`;
                break;
            case 'non_authenticated':
                text = `${text}.NON_AUTHENTICATED`;
                break;
            case 'unprotected':
                text = `${text}.UNPROTECTED`;
                break;
            case 'protected':
                text = `${text}.PROTECTED`;
                break;
        }
        return text;
    }
}
exports.SwgEndpointsListMapStatusTextPipe = SwgEndpointsListMapStatusTextPipe;
