"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiIntegrationWildfireActivationStepComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
class ApiIntegrationWildfireActivationStepComponent {
    constructor(fb, translateService) {
        this.fb = fb;
        this.translateService = translateService;
        this.valuesChanged = false;
        this.wildfireLink = 'https://wildfire.paloaltonetworks.com';
        this.verifyText = this.translateService.instant('$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.ACTIVATION.VERIFY');
        this.onVerifyApiAndRegion = new core_1.EventEmitter();
        this.onRegionChange = new core_1.EventEmitter();
        this.form = this.fb.group({
            regionField: ['GLOBAL', [forms_1.Validators.required]],
            apiKeyField: ''
        });
    }
    get regionOptions() {
        return [
            {
                value: 'GLOBAL',
                label: this.translateService.instant('$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.ACTIVATION.REGION_OPTIONS.GLOBAL')
            },
            {
                value: 'EU',
                label: this.translateService.instant('$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.ACTIVATION.REGION_OPTIONS.EU')
            },
            {
                value: 'JP',
                label: this.translateService.instant('$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.ACTIVATION.REGION_OPTIONS.JP')
            },
            {
                value: 'SG',
                label: this.translateService.instant('$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.ACTIVATION.REGION_OPTIONS.SG')
            },
            {
                value: 'UK',
                label: this.translateService.instant('$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.ACTIVATION.REGION_OPTIONS.UK')
            }
        ];
    }
    ngOnInit() {
        if (!!this.region && !!this.apiKey) {
            this.form.patchValue({
                regionField: this.region,
                apiKeyField: this.apiKey
            });
        }
        this.regionFieldSubscription = this.form
            .get('regionField')
            .valueChanges.subscribe(newRegion => {
            this.onRegionChange.emit(newRegion);
        });
        this.apiKeyFieldSubscription = this.form.get('apiKeyField').valueChanges.subscribe(() => {
            this.valuesChanged = true;
        });
    }
    verify() {
        this.onVerifyApiAndRegion.emit({
            apiKey: this.form.value.apiKeyField,
            region: this.form.value.regionField
        });
        this.valuesChanged = false;
    }
    isVerified() {
        return !this.valuesChanged && this.isApiAndRegionVerified;
    }
    isDisabled() {
        return (this.form.value.apiKeyField === '' ||
            this.form.value.regionField === '' ||
            this.isVerifyApiKeyButtonDisabled);
    }
    verifyTextButton() {
        return this.isVerifyApiKeyButtonDisabled
            ? this.translateService.instant('$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.ACTIVATION.VERIFYING')
            : this.translateService.instant('$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.ACTIVATION.VERIFY');
    }
    isValid() {
        return (this.isVerified() && this.form.value.apiKeyField !== '' && this.form.value.regionField !== '');
    }
    ngOnDestroy() {
        this.apiKeyFieldSubscription.unsubscribe();
        this.regionFieldSubscription.unsubscribe();
    }
}
exports.ApiIntegrationWildfireActivationStepComponent = ApiIntegrationWildfireActivationStepComponent;
