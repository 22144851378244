<mc-collapsable-panel header="$I18N_SWG_THREAT_EVENT_DYNAMIC_ANALYSIS.TITLE" [isExpanded]="true"
                      class="threat-event-dynamic-analysis">
  <div class="mc-detailed-list mc-detailed-list-two-columns" *ngIf="threatEvent.dynamicAnalysis">
    <div class="mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_SWG_THREAT_EVENT_DYNAMIC_ANALYSIS.MIME_TYPE' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{ threatEvent.dynamicAnalysis.mimeType }}
      </div>
    </div>

    <div class="mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_SWG_THREAT_EVENT_DYNAMIC_ANALYSIS.CONFIDENCE' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        <b>{{ threatEvent.dynamicAnalysis.confidence }}</b>
      </div>
    </div>

    <div class="mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_SWG_THREAT_EVENT_DYNAMIC_ANALYSIS.DETAILS' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        <pre>{{ threatEvent.dynamicAnalysis.details }}</pre>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!threatEvent.dynamicAnalysis">
    {{ '$I18N_SWG_THREAT_EVENT_DYNAMIC_ANALYSIS.NO_DATA' | translate }}
  </ng-container>
</mc-collapsable-panel>
