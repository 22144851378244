"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ2 = exports.ɵ1 = exports.ɵ0 = exports.getSelectedRow = exports.getIsCreatingDomains = exports.getValidDomainsandComment = exports.getInvalidDomains = exports.getValidDomains = exports.getShowDomainValidation = exports.getEditDomainState = exports.getAddDomainState = exports.getIsDeleting = exports.getDeleteSuccess = exports.getAllSelectedItems = exports.getAllowDelete = exports.getIsSelectedAll = exports.getListState = exports.getChameleonState = exports.reducers = void 0;
const fromList = require("./external-domains-list/external-domain-list.reducer");
const fromAddDomains = require("./add-domains/add-domains.reducer");
const fromEditDomain = require("./edit-domain/edit-domain.reducer");
const store_1 = require("@ngrx/store");
exports.reducers = {
    list: fromList.reducer,
    addDomains: fromAddDomains.reducer,
    editDomain: fromEditDomain.reducer
};
exports.getChameleonState = store_1.createFeatureSelector('chameleon');
const ɵ0 = (state) => state.list;
exports.ɵ0 = ɵ0;
exports.getListState = store_1.createSelector(exports.getChameleonState, ɵ0);
exports.getIsSelectedAll = store_1.createSelector(exports.getListState, fromList.getIsSelectedAll);
exports.getAllowDelete = store_1.createSelector(exports.getListState, fromList.getAllowDelete);
exports.getAllSelectedItems = store_1.createSelector(exports.getListState, fromList.getAllSelectedItems);
exports.getDeleteSuccess = store_1.createSelector(exports.getListState, fromList.getDeleteSuccess);
exports.getIsDeleting = store_1.createSelector(exports.getListState, fromList.getIsDeleting);
const ɵ1 = (state) => state.addDomains;
exports.ɵ1 = ɵ1;
exports.getAddDomainState = store_1.createSelector(exports.getChameleonState, ɵ1);
const ɵ2 = (state) => state.editDomain;
exports.ɵ2 = ɵ2;
exports.getEditDomainState = store_1.createSelector(exports.getChameleonState, ɵ2);
exports.getShowDomainValidation = store_1.createSelector(exports.getAddDomainState, fromAddDomains.getShowDomainValidation);
exports.getValidDomains = store_1.createSelector(exports.getAddDomainState, fromAddDomains.getValidDomains);
exports.getInvalidDomains = store_1.createSelector(exports.getAddDomainState, fromAddDomains.getInvalidDomains);
exports.getValidDomainsandComment = store_1.createSelector(exports.getAddDomainState, fromAddDomains.getValidDomainsandComment);
exports.getIsCreatingDomains = store_1.createSelector(exports.getAddDomainState, fromAddDomains.getIsCreatingDomains);
exports.getSelectedRow = store_1.createSelector(exports.getEditDomainState, fromEditDomain.getSelectedRow);
