"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isLoading = exports.reducer = exports.initialState = void 0;
const request_actions_1 = require("../../actions/request.actions");
exports.initialState = {
    isLoading: false
};
/**
 * Reducer
 */
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case request_actions_1.ExportApiRequestActionTypes.GetId:
            return Object.assign(Object.assign({}, state), { isLoading: true });
        case request_actions_1.ExportApiRequestActionTypes.Download:
        case request_actions_1.ExportApiRequestActionTypes.GetIdFailure:
            return Object.assign(Object.assign({}, state), { isLoading: false });
        default:
            return state;
    }
}
exports.reducer = reducer;
/**
 * Selectors
 */
const isLoading = (state) => state.isLoading;
exports.isLoading = isLoading;
