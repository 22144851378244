<mc-modal-header headerTitle="{{ '$I18N_API_APPLICATIONS_OKTA_EBC_CREDENTIAL_EXPIRED_MODAL.TITLE' | translate }}"></mc-modal-header>

<mc-modal-body>
  <p>
    {{ '$I18N_API_APPLICATIONS_OKTA_EBC_CREDENTIAL_EXPIRED_MODAL.MESSAGE' | translate }}
  </p>
</mc-modal-body>

<mc-modal-footer>
  <button
    type="button"
    class="btn btn-primary"
    (click)="close(true)"
  >
    {{ '$I18N_API_APPLICATIONS_OKTA_EBC_CREDENTIAL_EXPIRED_MODAL.OK_BUTTON' | translate }}
  </button>
</mc-modal-footer>
