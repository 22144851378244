<div class="mc-products-event-step">
  <div>
    <form
      class="form-horizontal"
      mcDefaultLabelClass="col-sm-3"
      mcDefaultControlContainerClass="col-sm-9"
      [formGroup]="channelProductEventsPage"
    >
      <div class="mc-product-group-card">
        <div class="mc-product-group-container" >
          <div class="mc-product-group-container-item">
            <mc-wizard-step-header [label]="productAvailable.name"></mc-wizard-step-header>
          </div>
          <div class="mc-product-enable-disable-link">
            <a *ngIf="AreAllEventsEnabledInProduct()"
               class="mc-enable-disable-link"
               (click)="enableDisableAllEventsInProduct(false)"
            >
              {{ '$I18N_SIEM_CHANNEL_WIZARD.STEPS.PRODUCT_EVENTS.DISABLE_ALL' | translate }}
            </a>
            <a *ngIf="!AreAllEventsEnabledInProduct()"
               class="mc-enable-disable-link"
               (click)="enableDisableAllEventsInProduct(true)"
            >
              {{ '$I18N_SIEM_CHANNEL_WIZARD.STEPS.PRODUCT_EVENTS.ENABLE_ALL' | translate }}
            </a>
          </div>
        </div>
        <div class="mc-product-card-descr">
          <div *ngIf="productAvailable.description.length > 240; else fullDescription">
            <span> {{ productAvailable.description.substr(0, 240) }}</span>
            <mc-collapsable-panel>
              <span> {{ productAvailable.description.substr(240, productAvailable.description.length) }}</span>
            </mc-collapsable-panel>
          </div>
          <ng-template #fullDescription>
            <span>{{ productAvailable.description }}</span>
          </ng-template>
        </div>
      </div>

      <div >
        <div formArrayName="groups" *ngFor="let group of groups().controls; let i = index">
          <div [formGroupName]="i" class="mc-product-group-card  mc-product-group-container">

            <mc-collapsable-panel
              class="mc-product-group-container-item"
              header="{{group.get('name').value}}"
              [isExpanded]="true" >

              <div class="mc-group-card-descr">
                <span>{{group.get('description').value}}</span>
              </div>

              <div formArrayName="events" *ngFor="let event of events(i).controls; let j = index" class="mc-switch-container">
                  <ng-container [formGroupName]="j">
                    <!-- Please keep the 'id' attribute below as it's used for automation testing. -->
                    <mc-enable-switch
                            [hidden]="event.get('hidden').value"
                            (click)="changeEventsWithSameId(event, group.get('name').value, $event)"
                            (keyup.enter)="changeEventsWithSameId(event, group.get('name').value, $event)"
                            (keyup.space)="changeEventsWithSameId(event, group.get('name').value, $event)"
                            class="mc-switch-item"
                            [id]="'card' + i + 'Switch' + j"
                            [label]="event.get('name').value" formControlName="enabled"
                    >
                    </mc-enable-switch>
                  </ng-container>
                </div>

            </mc-collapsable-panel>
            <div>
              <a *ngIf="AreAllEventsEnabledInGroup(i)"
                 class="mc-enable-disable-link"
                 (click)="enableDisableEventsInGroup(i, false, group.get('name').value)"
              >
                {{ '$I18N_SIEM_CHANNEL_WIZARD.STEPS.PRODUCT_EVENTS.DISABLE_ALL' | translate }}
              </a>
              <a *ngIf="!AreAllEventsEnabledInGroup(i)"
                 class="mc-enable-disable-link"
                 (click)="enableDisableEventsInGroup(i, true, group.get('name').value)"
              >
                {{ '$I18N_SIEM_CHANNEL_WIZARD.STEPS.PRODUCT_EVENTS.ENABLE_ALL' | translate }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
