"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseTransmissionDirective = void 0;
class BaseTransmissionDirective {
    set recipientInfo(recipientInfo) {
        this._recipientInfo = recipientInfo;
        this.selectDataSet();
    }
    get recipientInfo() {
        return this._recipientInfo;
    }
    selectDataSet() {
        // API sends the data in txInfo OR recipientMetaData/deliveryMetaData
        if ((this.recipientInfo && this.recipientInfo.txInfo) ||
            (this.selectedDeliveredMessage && this.selectedDeliveredMessage.txInfo)) {
            this.isDataSetOne = true;
        }
        else if ((this.recipientInfo && this.recipientInfo.recipientMetaInfo) ||
            (this.selectedDeliveredMessage && this.selectedDeliveredMessage.deliveryMetaInfo)) {
            this.isDataSetOne = false;
        }
    }
}
exports.BaseTransmissionDirective = BaseTransmissionDirective;
