"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiIntegrationCrowdstrikeActivityComponent = void 0;
const reducers = require("app-new/api-applications/reducers");
const actions = require("app-new/api-applications/actions");
const aside_1 = require("app-new/api-applications/components/integration/aside");
class ApiIntegrationCrowdstrikeActivityComponent {
    constructor(store, asideService) {
        this.store = store;
        this.asideService = asideService;
    }
    ngOnInit() {
        this.store.dispatch(new actions.GetCrowdstrikeActivityLogsAction(this.integrationid));
        this.activityLogs$ = this.store.select(reducers.getIntegrationCrowdstrikeActivityLogs);
        this.isLoading$ = this.store.select(reducers.getIntegrationCrowdstrikeActivityLogsLoading);
    }
    viewActivityLog(activityLog) {
        const asideConfig = { data: activityLog };
        this.asideService.open(aside_1.ApiCrowdstrikeActivityLogsAsideComponent, asideConfig);
    }
}
exports.ApiIntegrationCrowdstrikeActivityComponent = ApiIntegrationCrowdstrikeActivityComponent;
