"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("angular-ui-bootstrap"); // ui.bootstrap modal tootip
require("./navbar-controller"); // navbar.controller
require("./favourites/favourites"); // navbar.favourites
require("./history/history"); // navbar.history
require("./services/services"); // navbar.services
require("./navbar-service"); // navbar.service
require("./search/search"); // navbar.search
require("mimecast-web-components/src/app/components/application-switcher/application-switcher"); // applicationSwitcher
// html and css
require("./navbar.less");
require("./navbar.tpl.html");
require("./account/account.less");
require("./account/account.tpl.html");
require("./applications-switch/applications-switch.tpl.html");
require("./module-name/module-name.tpl.html");
require("./user/user.less");
require("./user/navbar/user.tpl.html");
require("./user/popover/user.tpl.html");
const components_1 = require("@mimecast-ui/components");
const static_1 = require("@angular/upgrade/static");
/**
 * Created by cfeudo on 12/08/2015.
 */
angular.module('navbar', ['ui.bootstrap',
    'navbar.controller',
    'navbar.favourites',
    'navbar.history',
    'navbar.services',
    'navbar.service',
    'navbar.search',
    'applicationSwitcher'
])
    .directive('mcAccountSwitchBar', static_1.downgradeComponent({
    component: components_1.AccountSwitchBarComponent,
    inputs: ['accountName']
}));
