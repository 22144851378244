"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const static_1 = require("@angular/upgrade/static");
const hero_detail_component_1 = require("./hero-detail.component");
angular
    .module('hero', [])
    .directive('heroDetail', static_1.downgradeComponent({
    component: hero_detail_component_1.HeroDetailComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider.state('hero', {
            url: '/hero',
            data: {
                capabilities: 'Development.Only',
                tabTitle: 'Message Tracking',
                breadcrumbs: ['Message Center', 'Message Tracking']
            },
            views: {
                main: {
                    template: '<hero-detail></hero-detail>'
                }
            }
        });
    }
]);
