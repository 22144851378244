"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ1 = exports.ɵ0 = exports.configState = void 0;
const capabilities = '(Permission.MONITORING_REJECTIONS_READ && !AccountType.EnterpriseGroup) || Permission.MONITORING_CONNECTIONS_READ';
const ɵ0 = {
    capabilities
}, ɵ1 = ($state) => {
    $state.go('message-center-rejected-and-deferred-rejected');
};
exports.ɵ0 = ɵ0;
exports.ɵ1 = ɵ1;
const mainState = {
    url: '/message-center/rejected-and-deferred',
    data: ɵ0,
    onEnter: [
        '$state',
        ɵ1
    ]
};
function configState($stateProvider) {
    $stateProvider
        .state('message-center-rejected-and-deferred', mainState)
        .state('message-center-rejected-and-deferred-rejected', createState('rejected', 0))
        .state('message-center-rejected-and-deferred-deferred', createState('deferred', 1));
}
exports.configState = configState;
function createState(url, tabIndex) {
    return {
        url: '/message-center/rejected-and-deferred',
        data: {
            tabIndex,
            isFixedLayout: false,
            checkProgress: false,
            capabilities,
            tabTitle: '$I18N_MESSAGE_CENTER_REJECTED_AND_DEFERRED_ROUTER_TAB_TITLE',
            tabId: 'message-center-rejected-and-deferred-tab',
            breadcrumbs: [
                {
                    label: '$I18N_MESSAGE_CENTER_REJECTED_AND_DEFERRED_BREADCRUMBS.MESSAGE_CENTER'
                },
                {
                    label: '$I18N_MESSAGE_CENTER_REJECTED_AND_DEFERRED_BREADCRUMBS.REJECTED_DEFERRED',
                    stateName: ''
                }
            ]
        },
        views: {
            main: {
                template: '<mc-rejected-and-deferred [tab-index]="tabIndex" (tab-change)="onTabChanged($event)"></mc-rejected-and-deferred>',
                controller: 'RejectedAndDeferredController'
            }
        }
    };
}
