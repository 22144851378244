"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
(function () {
    'use strict';
    /* ======================================================== *\

     = ServerConnections List - controller

     \* ======================================================== */
    angular.module('services.services.server-connections-list.controller', [])
        .controller('ServerConnectionListController', ['serverConnectionService', 'modalActionService', 'growl', '$q', 'ngProgressFactory', 'serverConnectionEditService', 'capabilitiesService', 'serverConnectionListGuard', function (serverConnectionService, modalActionService, growl, $q, ngProgressFactory, serverConnectionEditService, capabilitiesService, serverConnectionListGuard) {
            const self = this;
            const ngProgress = ngProgressFactory.createInstance();
            /* -------------------------------------------------------- *\
         - interface
         \* -------------------------------------------------------- */
            self.isLoadingData = true;
            self.serverConnections = serverConnectionListGuard.serverConnectionsResponse;
            self.showDeleteAlert = showDeleteAlert;
            self.closeActionModal = closeActionModal;
            self.deleteServerConnectionAndCloseModal = deleteServerConnectionAndCloseModal;
            self.serverType = serverConnectionService.constants.SERVER_TYPE;
            //TODO - This code needs to go to WEB COMPONENTS / SERVICES
            self.serverConnectionInUseErrorCode = 'err_server_connection_cannot_be_deleted';
            self.hasServerConnectionEditPermission = serverConnectionEditService.hasServerConnectionEditPermission;
            /* -------------------------------------------------------- *\
         - initialisation
         \* -------------------------------------------------------- */
            if (capabilitiesService.hasCapability('Permission.SERVER_CONNECTIONS_READ')) {
                activate();
            }
            /* -------------------------------------------------------- *\
             - implementation
             \* -------------------------------------------------------- */
            // == activate ========
            function activate() {
                isProgressVisible(true);
                return serverConnectionService.getServerConnection().then(function (response) {
                    if (!response.err) {
                        if (capabilitiesService.hasCapability('Temporary.Services.Server.Connectors.Disabled')) {
                            self.serverConnections = response.all.filter(function (server) {
                                return server.serverType !== 'office_365';
                            });
                        }
                        else {
                            self.serverConnections = response.all;
                        }
                        isProgressVisible(false);
                        return self.serverConnections;
                    }
                    else {
                        isProgressVisible(false);
                        serverConnectionService.getError().showErrorNotification(response);
                    }
                }, function () {
                    isProgressVisible(false);
                    serverConnectionService.getError().showErrorNotification();
                });
            }
            // == showDeleteAlert ========
            function showDeleteAlert(id, name) {
                modalActionService.openActionModal(getDeleteConfirmationJson(id, name)).then(function () {
                    activate();
                });
            }
            // == deleteServerConnectionAndCloseModal =========
            function deleteServerConnectionAndCloseModal() {
                isProgressVisible(true);
                const id = modalActionService.getModalParams().itemId;
                if (angular.isDefined(id)) {
                    serverConnectionService.deleteServerConnection(id).then(function (response) {
                        if (!response.err) {
                            closeActionModal(); //TODO: make this tidy once error handler is refactored
                        }
                        else if (response.fail().first.errors[0].code == self.serverConnectionInUseErrorCode) {
                            showServerConnectionInUseWarning();
                        }
                        else {
                            serverConnectionService.getError().showErrorNotification(response);
                        }
                        isProgressVisible(false);
                    }, function () {
                        isProgressVisible(false);
                        serverConnectionService.getError().showErrorNotification();
                    });
                }
                else {
                    isProgressVisible(false);
                    growl.error('$I18N_BACKUP_RESTORE_SERVER_CONNECTION_DELETE_GENERIC_ERROR');
                }
            }
            // == getDeleteConfirmationJson =========
            function getDeleteConfirmationJson(id, name) {
                return {
                    title: '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_DELETE_CONFIRMATION_TITLE',
                    content: '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_DELETE_CONFIRMATION_CONTENT',
                    contentParams: { serverConnectionDescription: name },
                    params: { itemId: id },
                    buttons: [{
                            title: '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_DELETE_CONFIRM_BUTTON_LABEL',
                            action: self.deleteServerConnectionAndCloseModal,
                            type: modalActionService.BUTTON_TYPE.PRIMARY
                        },
                        {
                            title: '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_DELETE_CANCEL_BUTTON_LABEL',
                            action: self.closeActionModal,
                            type: modalActionService.BUTTON_TYPE.DEFAULT
                        }]
                };
            }
            // == closeActionModal =========
            function closeActionModal() {
                modalActionService.closeActionModal();
            }
            // == showServerConnectionInUseWarning ========
            function showServerConnectionInUseWarning() {
                closeActionModal();
                modalActionService.openActionModal(getServerConnectionInUseWarningJson()).then(function () {
                    activate();
                });
            }
            // == getServerConnectionInUseWarningJson ========
            function getServerConnectionInUseWarningJson() {
                return {
                    title: '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_IN_USE_WARNING_TITLE',
                    content: '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_IN_USE_WARNING_CONTENT',
                    buttons: [
                        {
                            title: '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_IN_USE_WARNING_BUTTON_LABEL',
                            action: self.closeActionModal,
                            type: modalActionService.BUTTON_TYPE.PRIMARY
                        }
                    ]
                };
            }
            function isProgressVisible(blnStatus) {
                if (blnStatus) {
                    ngProgress.start();
                    self.isLoadingData = true;
                }
                else {
                    ngProgress.complete();
                    self.isLoadingData = false;
                }
            }
        }]);
}());
