"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApPolicyAppliesToStepComponent = void 0;
const core_1 = require("@angular/core");
const ap_policy_applies_to_static_values_1 = require("./ap-policy-applies-to.static-values");
const forms_1 = require("@angular/forms");
const rxjs_1 = require("rxjs");
const components_1 = require("@mimecast-ui/components");
const actions = require("../../../actions/policy.actions");
const reducers = require("../../../reducers");
const operators_1 = require("rxjs/operators");
const group_selector_component_1 = require("../../../../../../components/group-selector/group-selector.component");
const domain_validator_1 = require("app-new/components/validators/domain/domain.validator");
class ApPolicyAppliesToStepComponent {
    constructor(fb, asideService, store) {
        this.fb = fb;
        this.asideService = asideService;
        this.store = store;
        this.addButtonDisabled$ = new rxjs_1.BehaviorSubject(true);
        this.addressesBasedOnOptions = ap_policy_applies_to_static_values_1.addressesBasedOnOptions;
        this.appliesFromOptions = ap_policy_applies_to_static_values_1.appliesFromOptions;
        this.ipAddressesCount = 0;
        this.columns = ['apIpRange', 'delete-column'];
        this.apAttributeOption = [];
        this.appliesToOptions = ap_policy_applies_to_static_values_1.appliesToOptions;
        this.updateApPolicyAppliesToType = new core_1.EventEmitter();
        this.toggle = true;
        this.apAttributeOption = [
            { value: { id: 'apPolicyAttribute', value: '', name: '' }, label: '' }
        ];
        this.apPolicyAppliesToForm = this.fb.group({
            attachmentPolicyRule: this.fb.array([]),
            override: [''],
            apBidirectional: [''],
            apIpRange: ['', components_1.IPValidator]
        });
        this.addAnotherRule();
    }
    get attachmentPolicyRuleControls() {
        return this.apPolicyAppliesToForm.get('attachmentPolicyRule');
    }
    ngOnInit() {
        this.attributesSubscription = this.attributeAddressList.subscribe(attributes => {
            this.defaultIndex = 0;
            this.ruleIndex = 0;
            if (attributes.length !== 0) {
                this.setAttributeDefault(attributes, this.defaultIndex, this.ruleIndex, 'both');
            }
        });
        this.addedGroups$ = this.store.select(reducers.getAppliesTo);
        this.addedGroupsFrom$ = this.store.select(reducers.getAppliesFrom);
        this.addedGroupsSubscription = this.addedGroups$.subscribe(groups => {
            if (groups) {
                let selectedToGroup = true;
                this.addedGroupFromSubscription = this.store
                    .select(reducers.getGroupToIndex)
                    .subscribe(toIndex => {
                    if (selectedToGroup) {
                        this.attachmentPolicyRuleControls.at(toIndex).patchValue({ apGroupTo: groups });
                        this.selectedGroupsLength = groups;
                        selectedToGroup = false;
                    }
                });
            }
        });
        this.addedGroupFromSubscription = this.addedGroupsFrom$.subscribe(groups => {
            if (groups) {
                let selectedFromGroup = true;
                this.addedGroupFromSubscription = this.store
                    .select(reducers.getGroupFromIndex)
                    .subscribe(fromIndex => {
                    if (selectedFromGroup) {
                        this.attachmentPolicyRuleControls.at(fromIndex).patchValue({ apGroupFrom: groups });
                        this.selectedGroupFrom = groups;
                        selectedFromGroup = false;
                    }
                });
            }
        });
        if (this.policyAppliesTo.attachmentPolicyRule.length > 1) {
            this.attachmentPolicyRuleControls.removeAt(0);
            this.apPolicyAppliesToForm.patchValue(this.policyAppliesTo);
            this.policyAppliesTo.attachmentPolicyRule.forEach((policyRule, index) => {
                this.attachmentPolicyRuleControls.push(this.fb.group(policyRule));
                this.attributesSubscription = this.attributeAddressList.subscribe(attributes => {
                    if (attributes.length !== 0) {
                        // @ts-ignore
                        const result = attributes[0].map(attribute => attribute.label);
                        if (policyRule.apAppliesTo === 'address_attribute_value') {
                            const indexto = result.indexOf(policyRule.apAttributeOptionTo.name);
                            this.setAttributeDefault(attributes, indexto, index, 'to');
                        }
                        if (policyRule.apAppliesFrom === 'address_attribute_value') {
                            const indexfrom = result.indexOf(policyRule.apAttributeOptionFrom.name);
                            this.setAttributeDefault(attributes, indexfrom, index, 'from');
                        }
                    }
                });
            });
        }
        else {
            this.apPolicyAppliesToForm.patchValue(this.policyAppliesTo);
        }
        this.ipAddressesAP$ = this.store.select(reducers.getApIpAddressesss);
        this.ipAPAddressesSubscription = this.ipAddressesAP$.subscribe(ipAddresses => {
            this.ipAddressesCount = ipAddresses.length;
            this.ipAddressFormControl.updateValueAndValidity();
        });
        this.apPolicyAppliesToFormSubscription = this.apPolicyAppliesToForm.valueChanges
            .pipe(operators_1.debounceTime(200))
            .subscribe(() => {
            this.updateAppliesToStore();
        });
    }
    setAttributeDefault(attributeList, index, ruleIndex, setValue) {
        // @ts-ignore
        this.apAttributeOption = attributeList[0];
        if (setValue === 'to' || setValue === 'both') {
            this.attachmentPolicyRuleControls
                .at(ruleIndex)
                .get('apAttributeOptionTo')
                .patchValue(this.apAttributeOption[index].value);
        }
        if (setValue === 'from' || setValue === 'both') {
            this.attachmentPolicyRuleControls
                .at(ruleIndex)
                .get('apAttributeOptionFrom')
                .patchValue(this.apAttributeOption[index].value);
        }
    }
    onDeleteGroup(row, value, index) {
        if (value === 'from') {
            this.attachmentPolicyRuleControls.at(index).patchValue({ apGroupFrom: '' });
        }
        else if (value === 'to') {
            this.attachmentPolicyRuleControls.at(index).patchValue({ apGroupTo: '' });
        }
    }
    updateAppliesToStore() {
        this.updateApPolicyAppliesToType.emit(this.apPolicyAppliesToForm.value);
    }
    onAppliesFromSelectChange(emailFromSelected, index) {
        const emailControl = this.attachmentPolicyRuleControls.at(index).get('apEmailFrom');
        const domainControl = this.attachmentPolicyRuleControls.at(index).get('apDomainFrom');
        const attributeControl = this.attachmentPolicyRuleControls
            .at(index)
            .get('apAttributeOptionFrom');
        const apGroupControl = this.attachmentPolicyRuleControls.at(index).get('apGroupFrom');
        if (emailFromSelected === this.appliesFromOptions[6].value) {
            emailControl.setValidators([
                forms_1.Validators.required,
                forms_1.Validators.email,
                forms_1.Validators.maxLength(128)
            ]);
        }
        else {
            emailControl.clearValidators();
        }
        if (emailFromSelected === this.appliesFromOptions[3].value) {
            domainControl.setValidators([domain_validator_1.domainValidator]);
        }
        else {
            domainControl.clearValidators();
        }
        if (emailFromSelected === this.appliesFromOptions[5].value) {
            if (attributeControl.value === '') {
                attributeControl.patchValue(this.apAttributeOption[0].value);
            }
            attributeControl.setValidators([forms_1.Validators.required]);
        }
        else {
            attributeControl.clearValidators();
        }
        if (emailFromSelected === this.appliesFromOptions[4].value) {
            apGroupControl.setValidators([forms_1.Validators.required]);
        }
        else {
            apGroupControl.clearValidators();
        }
        apGroupControl.updateValueAndValidity();
        attributeControl.updateValueAndValidity();
        emailControl.updateValueAndValidity();
        domainControl.updateValueAndValidity();
    }
    onAppliesToSelectChange(emailToSelected, index) {
        const emailToControl = this.attachmentPolicyRuleControls.at(index).get('apEmailTo');
        const domainControl = this.attachmentPolicyRuleControls.at(index).get('apDomainTo');
        const attributeControl = this.attachmentPolicyRuleControls.at(index).get('apAttributeOptionTo');
        const apGroupControl = this.attachmentPolicyRuleControls.at(index).get('apGroupTo');
        if (emailToSelected === this.appliesToOptions[6].value) {
            emailToControl.setValidators([
                forms_1.Validators.required,
                forms_1.Validators.email,
                forms_1.Validators.maxLength(128)
            ]);
        }
        else {
            emailToControl.clearValidators();
        }
        if (emailToSelected === this.appliesToOptions[3].value) {
            domainControl.setValidators([domain_validator_1.domainValidator]);
        }
        else {
            domainControl.clearValidators();
        }
        if (emailToSelected === this.appliesToOptions[5].value) {
            if (attributeControl.value === '') {
                attributeControl.patchValue(this.apAttributeOption[0].value);
            }
            attributeControl.setValidators([forms_1.Validators.required]);
        }
        else {
            attributeControl.clearValidators();
        }
        if (emailToSelected === this.appliesToOptions[4].value) {
            apGroupControl.setValidators([forms_1.Validators.required]);
        }
        else {
            apGroupControl.clearValidators();
        }
        domainControl.updateValueAndValidity();
        apGroupControl.updateValueAndValidity();
        attributeControl.updateValueAndValidity();
        emailToControl.updateValueAndValidity();
    }
    addAnotherRule() {
        this.attachmentPolicyRuleControls.push(this.fb.group({
            apAddressesBasedOn: [this.addressesBasedOnOptions[2].value, ''],
            apAppliesFrom: [this.appliesFromOptions[0].value, ''],
            apAppliesTo: [this.appliesToOptions[0].value, ''],
            apEmailFrom: [''],
            apGroupFrom: [''],
            apGroupTo: [''],
            apAttributeOptionTo: '',
            apAttributeOptionFrom: '',
            apDomainFrom: [''],
            apEmailTo: [''],
            apDomainTo: ['']
        }));
        if (this.apAttributeOption.length) {
            const index = this.attachmentPolicyRuleControls.length - 1;
            this.attachmentPolicyRuleControls
                .at(index)
                .get('apAttributeOptionTo')
                .patchValue(this.apAttributeOption[0].value);
            this.attachmentPolicyRuleControls
                .at(index)
                .get('apAttributeOptionFrom')
                .patchValue(this.apAttributeOption[0].value);
        }
    }
    openSidebar(value, index) {
        const asideConfig = {};
        const aside = this.asideService.open(group_selector_component_1.GroupSelectorComponent, asideConfig);
        aside.beforeClose().subscribe(selectedGroup => {
            if (selectedGroup) {
                this.store.dispatch(new actions.AppliesToSelectGroup(selectedGroup, value, index));
            }
        });
    }
    onIpChange(value) {
        this.addButtonDisabled$.next(!value || value.length === 0);
        this.ipAddressFormControl.setErrors({});
    }
    deleteApRule(index) {
        this.attachmentPolicyRuleControls.removeAt(index);
    }
    toggleAdvancedOptions() {
        this.toggle = !this.toggle;
    }
    get ipAddressFormControl() {
        return this.apPolicyAppliesToForm.get('apIpRange');
    }
    removeIp(row) {
        this.store.dispatch(new actions.RemoveIpAddressAction(row));
    }
    onDeleteAll() {
        this.store.dispatch(new actions.ClearIpAddressesAction());
    }
    addIp() {
        this.ipAddressFormControl.updateValueAndValidity();
        if (this.ipAddressFormControl.valid) {
            this.store.dispatch(new actions.AddIpAddressAction({ apIpRange: this.ipAddressFormControl.value }));
            this.ipAddressFormControl.patchValue('');
            this.addButtonDisabled$.next(true);
            this.ipAddressFormControl.markAsPristine();
        }
    }
    getAdvancedOptionsTitle() {
        return this.toggle
            ? '$I18N_AP_CREATE_POLICY_STEPS.SHOW_ADVANCED_OPTIONS_TOGGLE_TEXT'
            : '$I18N_AP_CREATE_POLICY_STEPS.HIDE_ADVANCED_OPTIONS_TOGGLE_TEXT';
    }
    isValid() {
        return this.attachmentPolicyRuleControls.status === 'VALID';
    }
    ngOnDestroy() {
        this.addedGroupsSubscription.unsubscribe();
        this.addedGroupFromSubscription.unsubscribe();
        this.ipAPAddressesSubscription.unsubscribe();
        this.apPolicyAppliesToFormSubscription.unsubscribe();
        this.attributesSubscription.unsubscribe();
    }
}
exports.ApPolicyAppliesToStepComponent = ApPolicyAppliesToStepComponent;
