"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PolicyAffectedUsersEnum = void 0;
var PolicyAffectedUsersEnum;
(function (PolicyAffectedUsersEnum) {
    PolicyAffectedUsersEnum["EVERYONE"] = "everyone";
    PolicyAffectedUsersEnum["MULTI"] = "multi";
    PolicyAffectedUsersEnum["LOCATION"] = "location";
    PolicyAffectedUsersEnum["USER"] = "user";
    PolicyAffectedUsersEnum["GROUP"] = "group";
})(PolicyAffectedUsersEnum = exports.PolicyAffectedUsersEnum || (exports.PolicyAffectedUsersEnum = {}));
