<div class="mc-swg-policy-details-block">
  <mc-wizard-step-header [label]="getPageTitle(isEditFlow)"></mc-wizard-step-header>
  <form
    class="form-horizontal"
    mcDefaultLabelClass="col-sm-3"
    mcDefaultControlContainerClass="col-sm-9"
    [formGroup]="policyPage"
    (ngSubmit)="onSubmit()"
  >
    <div class="panel-padding-bottom mc-policy-name" *ngIf="!policy.defaultPolicy">
      <mc-text-field
        errorPrefix="$I18N_SWG_CREATE_POLICY_NAME_VALIDATION"
        label="$I18N_SWG_CREATE_POLICY_POLICY_NAME"
        formControlName="description"
        [setFocus]="true"
      ></mc-text-field>
    </div>

    <div class="row mc-detailed-list-row no-padding-top mc-policy-details-default-policy-block" *ngIf="policy.defaultPolicy">
      <div class="col-xs-3 mc-detailed-list-label mc-policy-name">
        {{ '$I18N_SWG_CREATE_POLICY_POLICY_SUMMARY_NAME' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ policy.policyDesc.description }}</div>
    </div>

    <div class="row mc-detailed-list-row no-padding-top mc-policy-details-default-policy-block"
         *ngIf="isEditFlow && policy.policyDesc.policyType !== policyType.CLOUD_PROTECT_POLICY">
      <div class="col-xs-3 mc-policy-name">
        {{ '$I18N_SWG_CREATE_POLICY_STATUS' | translate }}
      </div>
      <div class="mc-detailed-list-column col-xs-3 no-padding-left">
        <mc-enable-switch
          formControlName="enabled"
        ></mc-enable-switch>
      </div>
    </div>


    <div class="form-group" *ngIf="!isEditFlow">
      <label class="control-label col-sm-3">{{
        '$I18N_SWG_CREATE_POLICY_POLICY_TYPE' | translate
      }}</label>
      <div class="col-sm-9 mc-policy-types">
        <div class="radio panel-half-padding-bottom">
          <label class="text-bold">
            <input
              type="radio"
              name="policyType"
              value="WEB_BLOCK_ALLOW_LIST"
              formControlName="policyType"
            />
            {{ '$I18N_SWG_CREATE_POLICY_WEB_BLOCK_ALLOW_LIST' | translate }}
            <div>{{ '$I18N_SWG_CREATE_POLICY_WEB_BLOCK_ALLOW_LIST_DESC' | translate }}</div>
          </label>
        </div>
        <div class="radio panel-half-padding-bottom"
             *mcCapabilities="'Temporary.Permission.swg.reports.application.visibility'">
          <label class="text-bold">
            <input
              type="radio"
              name="policyType"
              value="WEB_APPLICATION_CONTROL"
              formControlName="policyType"
            />
            {{ '$I18N_SWG_CREATE_POLICY_WEB_APPLICATION_CONTROL_TITLE' | translate }} <span
            *mcCapabilities="'Temporary.Permission.swg.reports.application.visibility.policy.ea'"
            class="mc-early-access">{{'$I18N_MEGA_MENU_EARLY_ACCESS' | translate}}</span>
            <div *ngIf="hasAppPolicyRemoveAllowEnabled">{{ '$I18N_SWG_CREATE_POLICY_WEB_APPLICATION_CONTROL_NO_ALLOW_DESC' | translate }}</div>
            <div *ngIf="!hasAppPolicyRemoveAllowEnabled">{{ '$I18N_SWG_CREATE_POLICY_WEB_APPLICATION_CONTROL_DESC' | translate }}</div>
          </label>
        </div>
        <div class="radio panel-half-padding-bottom">
          <label>
            <input
              type="radio"
              name="policyType"
              value="WEB_CATEGORY_FILTERING"
              formControlName="policyType"
            />{{ '$I18N_SWG_CREATE_POLICY_WEB_CATEGORY_FILTERING' | translate }}
            <div>{{ '$I18N_SWG_CREATE_POLICY_WEB_CATEGORY_FILTERING_DESC' | translate }}</div>
          </label>
        </div>
        <div
          class="radio panel-half-padding-bottom"
          *mcCapabilities="'Permission.SERVICES_TTP_URL_PROTECT_READ'"
        >
          <label>
            <input
              type="radio"
              name="policyType"
              value="WEB_TTP_POLICY"
              formControlName="policyType"
            />{{ '$I18N_SWG_CREATE_POLICY_WEB_TTP_POLICY' | translate }}
            <div>{{ '$I18N_SWG_CREATE_POLICY_WEB_TTP_POLICY_DESC' | translate }}</div>
          </label>
        </div>
        <div class="radio panel-half-padding-bottom">
          <label>
            <input
              type="radio"
              name="policyType"
              value="WEB_ADVANCED_SECURITY"
              formControlName="policyType"
            />{{ '$I18N_SWG_CREATE_POLICY_WEB_ADVANCED_SECURITY' | translate }}
            <div>{{ '$I18N_SWG_CREATE_POLICY_WEB_ADVANCED_SECURITY_DESC' | translate }}</div>
          </label>
        </div>

        <div class="radio panel-half-padding-bottom">
          <label>
            <input
              type="radio"
              name="policyType"
              value="WEB_LOGGING"
              formControlName="policyType"
            />{{ '$I18N_SWG_CREATE_POLICY_WEB_LOGGING' | translate }}
            <div>{{ '$I18N_SWG_CREATE_POLICY_WEB_LOGGING_DESC' | translate }}</div>
          </label>
        </div>
        <div class="radio panel-half-padding-bottom" *ngIf="hasClp">
          <label>
            <input
              type="radio"
              name="policyType"
              value="CLOUD_PROTECT_POLICY"
              formControlName="policyType"
            />{{ '$I18N_SWG_CREATE_POLICY_CLOUD_PROTECT_POLICY' | translate }}
            <div>{{ '$I18N_SWG_CREATE_POLICY_CLOUD_PROTECT_POLICY_DESC' | translate }}</div>
          </label>
        </div>

      </div>
    </div>
    <div class="form-group" *ngIf="isEditFlow">
      <label class="control-label col-sm-3">{{
        '$I18N_SWG_CREATE_POLICY_POLICY_TYPE' | translate
      }}</label>
      <div class="col-sm-9 panel-half-padding-top">
        <label class="text-muted">{{
          '$I18N_SWG_CREATE_POLICY_' + policy.policyDesc.policyType | translate
        }}</label>
        <div *ngIf="policy.policyDesc.policyType !== policyType.WEB_APPLICATION_CONTROL || (policy.policyDesc.policyType === policyType.WEB_APPLICATION_CONTROL && !hasAppPolicyRemoveAllowEnabled)">{{ '$I18N_SWG_CREATE_POLICY_' + policy.policyDesc.policyType + '_DESC' | translate }}</div>
        <div *ngIf="policy.policyDesc.policyType === policyType.WEB_APPLICATION_CONTROL && hasAppPolicyRemoveAllowEnabled">{{ '$I18N_SWG_CREATE_POLICY_' + policy.policyDesc.policyType + '_NO_ALLOW_DESC' | translate }}</div>
      </div>
    </div>
  </form>
</div>
