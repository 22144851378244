"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectMailboxGroupComponent = exports.groupType = void 0;
const operators_1 = require("rxjs/operators");
const findGroupsAction = require("../../actions/find-groups.actions");
const getGroupAction = require("../../actions/get-group.actions");
const getGroupsAction = require("../../actions/get-groups.actions");
const selectors_1 = require("../../selectors");
exports.groupType = {
    ldap: 'ldap',
    cloud: 'cloud'
};
class SelectMailboxGroupComponent {
    constructor(overlayRef, translate, store, cdr, overlayData) {
        this.overlayRef = overlayRef;
        this.translate = translate;
        this.store = store;
        this.cdr = cdr;
        this.overlayData = overlayData;
        this.isSearchResults = false;
        this.onlyLocalGroups = false;
        this.minCharacterSearch = 1;
        this.searchValid = false;
        this.searchErrors = {};
        this.onlyLocalGroups = this.overlayData.onlyLocalGroups;
        this.minCharacterSearch = this.overlayData.minCharacters ? this.overlayData.minCharacters : 1;
        this.placeholderActiveDirectoryGroups = this.translate.instant('$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SETTINGS_STEP.PANEL.FILTER_PLACEHOLDER.ACTIVE_DIRECTORY');
        this.placeholderLocalGroups = this.translate.instant('$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SETTINGS_STEP.PANEL.FILTER_PLACEHOLDER.LOCAL');
    }
    ngOnInit() {
        this.options = {
            getChildren: this.getChildren.bind(this),
            displayField: 'description',
            childrenField: 'folders'
        };
        this.isLDAPSearching = false;
        this.isCloudSearching = false;
        this.showLDAPNoSearchResults = false;
        this.showCloudNoSearchResults = false;
        this.showSpinner = true;
        this.activeSource = this.onlyLocalGroups ? exports.groupType.cloud : exports.groupType.ldap;
        this.searchResultMessage = this.translate.instant('$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SETTINGS_STEP.PANEL.SEARCH_RESULT_MSG');
        this.loadingMessage = this.translate.instant('$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SETTINGS_STEP.PANEL.LOADING_MSG');
        this.store.dispatch(new getGroupsAction.SupervisionGetGroupsCloud({ depth: 0, id: null, source: 'cloud' }));
        this.store.dispatch(new getGroupsAction.SupervisionGetGroupsLDAP({ depth: 0, id: null, source: 'ldap' }));
        this.registerGetGroups(true);
        this.isCloudDataLoaded$ = this.store.select(selectors_1.isGetGroupsCloudDataLoaded);
        this.isLDAPDataLoaded$ = this.store.select(selectors_1.isGetGroupsLDAPDataLoaded);
        this.isGetGroupsLoadingError$ = this.store.select(selectors_1.isGetGroupsLoadingError);
        this.isFindGroupsLoadingError$ = this.store.select(selectors_1.isFindGroupsLoadingError);
        this.hasInitialCloudData$ = this.store.select(selectors_1.hasInitialCloudData);
        this.hasInitialLDAPData$ = this.store.select(selectors_1.hasInitialLDAPData);
        this.isLDAPReady$ = this.store.select(selectors_1.isLDAPGroupReady);
        this.isCloudReady$ = this.store.select(selectors_1.isCloudGroupReady);
        this.findGroupsSubscription = this.isFindGroupsLoadingError$.subscribe(value => {
            // if error, show no results message at tree level and hide tree
            if (value) {
                if (this.isLDAPSearching) {
                    this.showLDAPNoSearchResults = true;
                    this.isLDAPSearching = false;
                }
                if (this.isCloudSearching) {
                    this.showCloudNoSearchResults = true;
                    this.isCloudSearching = false;
                }
            }
        });
    }
    ngAfterViewInit() {
        this.cdr.reattach();
    }
    ngOnDestroy() {
        if (this.findGroupsSubscription) {
            this.findGroupsSubscription.unsubscribe();
        }
        if (this.foundGroupsSubscription) {
            this.foundGroupsSubscription.unsubscribe();
        }
        if (this.groupsSubscription) {
            this.groupsSubscription.unsubscribe();
        }
        this.cdr.detach();
    }
    // this method is invoked by the tree component. based on
    // getChildren in the options object set in ngOnInit()
    // It wants a promise to be returned.
    getChildren(node) {
        if (node.isExpanded && node.data.hasChildren) {
            const expandedNode = node;
            // get children
            if (expandedNode.data.hasChildren) {
                this.getNodeChildren(expandedNode.data);
            }
            this.group$ = this.store.select(selectors_1.getGroup);
            return this.group$
                .pipe(operators_1.map(group => {
                if (group.group && expandedNode && group.group[0].id === expandedNode.data.id) {
                    const currGroup = group.group[0];
                    // populate our flat array of folder items for lookup
                    this.populateFlatArray(currGroup.folders);
                    return this.mapGroupToTreeNode(currGroup.folders);
                }
            }), operators_1.take(2))
                .toPromise();
        }
    }
    onTabChange(event) {
        this.activeSource = event === 0 ? exports.groupType.ldap : exports.groupType.cloud;
    }
    onItemSelected(item) {
        this.selectedItem = this.findFolderItemById(item.id);
        this.close();
    }
    mapGroupToTreeNode(groups, isSearch = false) {
        return groups.map((group) => {
            let children = false;
            if (!isSearch) {
                children = group.folderCount > 0 || group['folders'];
            }
            return Object.assign({ hasChildren: children }, group);
        });
    }
    findFolderItemById(id) {
        return this.allGroups.find((item) => {
            return item.id === id;
        });
    }
    populateFlatArray(collection) {
        collection.forEach((item) => {
            if (!this.findFolderItemById(item.id)) {
                this.allGroups.push(item);
            }
        });
    }
    getNodeChildren(folderItem) {
        const payload = { id: folderItem.id, depth: 1, source: folderItem.source };
        this.store.dispatch(new getGroupAction.SupervisionGetGroup(payload));
    }
    onSearch(event) {
        this.searchValid = event.search && event.search.length >= this.minCharacterSearch;
        this.searchErrors = {};
        if (this.searchValid) {
            this.isSearchResults = true;
            this.showLDAPNoSearchResults = false;
            this.showCloudNoSearchResults = false;
            this.setTreeRef();
            this.store.dispatch(new findGroupsAction.SupervisionFindGroupsResetStore());
            if (this.activeSource) {
                this.isLDAPSearching = true;
            }
            else {
                this.isCloudSearching = true;
            }
            this.foundGroups$ = this.store.select(selectors_1.findGroups);
            this.foundGroupsSubscription = this.foundGroups$.subscribe(data => {
                if (this.ldapTree && data.ldapGroups) {
                    if (data.ldapGroups.length) {
                        this.ldapGroups = this.populateSearchResults(data.ldapGroups);
                        this.hasLDAPSearchResults = true;
                    }
                    else {
                        this.showLDAPNoSearchResults = true;
                        this.hasLDAPSearchResults = false;
                    }
                    this.isLDAPSearching = false;
                }
                if (this.cloudTree && data.cloudGroups) {
                    if (data.cloudGroups.length) {
                        this.cloudGroups = this.populateSearchResults(data.cloudGroups);
                        this.hasCloudSearchResults = true;
                    }
                    else {
                        this.showCloudNoSearchResults = true;
                        this.hasCloudSearchResults = false;
                    }
                    this.isCloudSearching = false;
                }
                this.cdr.markForCheck();
            });
            const config = {
                source: this.activeSource,
                query: event.search
            };
            this.store.dispatch(new findGroupsAction.SupervisionFindGroups(config));
        }
        else {
            this.searchErrors[`${this.minCharacterSearch}MINLENGTH`] = true;
        }
    }
    onClearSearch(event) {
        if (this.isSearchResults) {
            this.isSearchResults = false;
            this.hasLDAPSearchResults = false;
            this.hasCloudSearchResults = false;
            this.store.dispatch(new findGroupsAction.SupervisionFindGroupsResetStore());
            this.registerGetGroups(true, event);
        }
    }
    close(result, $event) {
        if ($event) {
            $event.preventDefault();
        }
        this.overlayRef.close(this.selectedItem);
    }
    registerGetGroups(collapse = false, source = 'all') {
        this.groups$ = this.store.select(selectors_1.getGroups);
        this.groupsSubscription = this.groups$.subscribe(groups => {
            const canCollapseCloudTree = collapse && this.cloudTree;
            const canCollapseLDAPTree = collapse && this.ldapTree;
            this.showLDAPNoSearchResults = false;
            this.showCloudNoSearchResults = false;
            if (groups && groups.cloudGroups && groups.ldapGroups) {
                this.allGroups = [...groups.cloudGroups, ...groups.ldapGroups];
                switch (source) {
                    case 'cloud':
                        this.cloudGroups = this.mapGroupToTreeNode(groups.cloudGroups);
                        if (canCollapseCloudTree) {
                            this.cloudTree.treeModel.collapseAll();
                        }
                        break;
                    case 'ldap':
                        this.ldapGroups = this.mapGroupToTreeNode(groups.ldapGroups);
                        if (canCollapseLDAPTree) {
                            this.ldapTree.treeModel.collapseAll();
                        }
                        break;
                    default:
                        this.cloudGroups = this.mapGroupToTreeNode(groups.cloudGroups);
                        this.ldapGroups = this.mapGroupToTreeNode(groups.ldapGroups);
                        if (canCollapseCloudTree && canCollapseLDAPTree) {
                            this.cloudTree.treeModel.collapseAll();
                            this.ldapTree.treeModel.collapseAll();
                        }
                }
                this.cdr.markForCheck();
            }
        });
    }
    populateSearchResults(localGroup) {
        this.populateFlatArray(localGroup);
        return this.mapGroupToTreeNode(localGroup, true);
    }
    setTreeRef() {
        if (!this.ldapTree && this.activeSource === exports.groupType.ldap) {
            this.ldapTree = this.ldapTreeView.getTreeRef();
        }
        if (!this.cloudTree && this.activeSource === exports.groupType.cloud) {
            this.cloudTree = this.cloudTreeView.getTreeRef();
        }
    }
}
exports.SelectMailboxGroupComponent = SelectMailboxGroupComponent;
