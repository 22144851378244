"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.flexidator = void 0;
const forms_1 = require("@angular/forms");
function flexidator(errKey, tests, minlen = 0, maxlen = Number.MAX_SAFE_INTEGER - 1) {
    const validator = (control) => {
        if (isPresent(forms_1.Validators.required(control))) {
            return null;
        }
        const value = control.value;
        return performTests(tests, value) &&
            (!value || (value.length >= minlen && value.length <= maxlen))
            ? null
            : { [errKey]: { value: control.value } };
    };
    return validator;
}
exports.flexidator = flexidator;
function isPresent(value) {
    return value !== undefined && value !== null;
}
function performTests(tests, value) {
    if (Array.isArray(tests)) {
        return !tests.find(t => !performTest(t, value));
    }
    else {
        return performTest(tests, value);
    }
}
function performTest(test, value) {
    return test instanceof RegExp ? test.test(value) : test(value);
}
