<span class="provider-icon">
  <img
    *ngIf="isProviderOfType(ProviderType.ONE_DRIVE) || isProviderOfType(ProviderType.ONEDRIVE)"
    src="/administration/resources/assets/logo-onedrive.cc38d63475060727a80874eef3647c2d.svg"
    width="20"
    height="20"
    alt="OneDrive"
  />
  <img
    *ngIf="isProviderOfType(ProviderType.MS_TEAMS)"
    src="/administration/resources/assets/logo-microsoft-teams.acbb65466d82872011f5b41d057744da.svg"
    width="20"
    height="20"
    alt="Microsoft Teams"
  />
  <img
    *ngIf="isProviderOfType(ProviderType.BOX)"
    src="/administration/resources/assets/logo-box.afc9d426bc21dc75bfefacb9ecf9a899.svg"
    width="20"
    height="20"
    alt="Box"
  />
  <img
    *ngIf="isProviderOfType(ProviderType.OFFICE_365)"
    src="/administration/resources/assets/logo-office365.81b201b72237384f15942b262d923ce4.png"
    width="20"
    height="20"
    alt="Office 365"
  />
  <img
    *ngIf="isProviderOfType(ProviderType.EXCHANGE_WEB_SERVICES)"
    src="/administration/resources/assets/logo-microsoft-exchange.c336488338d3dad09f9c13cd9ecd2184.svg"
    width="20"
    height="20"
    alt="Exchange Web Services"
  />
  <img
    *ngIf="isProviderOfType(ProviderType.GOOGLE_WORKSPACE)"
    src="/administration/resources/assets/logo-google-workspace.ffc8eb8f0fc6820428e809f9f1438e47.svg"
    width="20"
    height="20"
    alt="Google Workspace"
  />
  <img
    *ngIf="isProviderOfType(ProviderType.AZURE_STANDARD)"
    src="/administration/resources/assets/logo-azure.baaabdab974c09adee2253169b664678.png"
    width="20"
    height="20"
    alt="Azure Standard"
  />
  <img
    *ngIf="isProviderOfType(ProviderType.AZURE_GCC)"
    src="/administration/resources/assets/logo-azure.baaabdab974c09adee2253169b664678.png"
    width="20"
    height="20"
    alt="Azure GCC"
  />
  <img
    *ngIf="isProviderOfType(ProviderType.OFFICE_365_GCC)"
    src="/administration/resources/assets/logo-office365.81b201b72237384f15942b262d923ce4.png"
    width="20"
    height="20"
    alt="Office 365 GCC"
  />
  <img
    *ngIf="isProviderOfType(ProviderType.MS_TEAMS_GCC)"
    src="/administration/resources/assets/logo-microsoft-teams.acbb65466d82872011f5b41d057744da.svg"
    width="20"
    height="20"
    alt="Microsoft Teams GCC"
  />
</span>
