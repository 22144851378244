<mc-modal-header data-test="modal-header"
  headerTitle="{{'$I18N_SUPERVISION_LISTS.RULE_USAGE_NOTIFICATION.TITLE' | translate:{ entityName: entityName } }}">
</mc-modal-header>

<mc-modal-body>
  <p class="notification-message" data-test="notification-message">
    {{'$I18N_SUPERVISION_LISTS.RULE_USAGE_NOTIFICATION.MESSAGE' | translate:{ entityName: entityName } }}
  </p>
  <div class="associated-rules">
    <p data-test="associated-rules" *ngFor="let rule of associatedRules"> <strong>{{ rule.name }}</strong>
    </p>
  </div>
  <p class="notification-action" data-test="notification-action">
    {{'$I18N_SUPERVISION_LISTS.RULE_USAGE_NOTIFICATION.ACTION' | translate:{ entityDeletionType: entityDeletionType } }}
  </p>
</mc-modal-body>

<mc-modal-footer>
  <button data-test="got-it-button" type="button" class="btn btn-primary" (click)="close(true)">
    {{ '$I18N_SUPERVISION_LISTS.RULE_USAGE_NOTIFICATION.GOT_IT' | translate }}
  </button>
</mc-modal-footer>
