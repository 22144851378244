"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForwardPanelEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const notification_actions_1 = require("app-new/components/notification/actions/notification.actions");
const core = require("app-new/core");
const actions = require("../../actions");
const actions_1 = require("../../actions");
class ForwardPanelEffects {
    constructor(actions$, forwardPanelService, apiService, store) {
        this.actions$ = actions$;
        this.forwardPanelService = forwardPanelService;
        this.apiService = apiService;
        this.store = store;
        this.forward$ = this.actions$.pipe(effects_1.ofType(actions.FORWARD_MESSAGE_OPEN_MODAL), operators_1.tap(() => this.forwardPanelService.open()));
        this.cancel$ = this.actions$.pipe(effects_1.ofType(actions.FORWARD_MESSAGE_CANCEL), operators_1.tap(() => this.forwardPanelService.close('cancel')));
        this.send$ = this.actions$.pipe(effects_1.ofType(actions.FORWARD_MESSAGE_SEND), operators_1.withLatestFrom(this.store.select(core.getUserProfile)), operators_1.exhaustMap(([action, user]) => {
            return this.apiService.forwardEmail(action.payload, user.emailAddress).pipe(operators_1.map(() => new actions.ForwardMessageSuccessAction()), operators_1.catchError(() => rxjs_1.of(new actions.ForwardMessageFailAction())));
        }));
        this.sent$ = this.actions$.pipe(effects_1.ofType(actions.FORWARD_MESSAGE_SUCCESS), operators_1.tap(() => this.forwardPanelService.close('sent')), operators_1.switchMap(() => {
            return [
                new notification_actions_1.NotificationShowAction({
                    config: { msg: '$I18N_MESSAGE_CENTER_FORWARD_PANEL_EFFECTS.SEND_SUCCESS' },
                    type: 'success'
                }),
                new actions_1.ForwardMessageResetAction()
            ];
        }));
        this.sentFail$ = this.actions$.pipe(effects_1.ofType(actions.FORWARD_MESSAGE_FAIL), operators_1.map(() => {
            return new notification_actions_1.NotificationShowAction({
                config: { msg: '$I18N_MESSAGE_CENTER_FORWARD_PANEL_EFFECTS.SEND_FAILED' },
                type: 'error'
            });
        }));
    }
}
__decorate([
    effects_1.Effect({ dispatch: false })
], ForwardPanelEffects.prototype, "forward$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], ForwardPanelEffects.prototype, "cancel$", void 0);
__decorate([
    effects_1.Effect()
], ForwardPanelEffects.prototype, "send$", void 0);
__decorate([
    effects_1.Effect()
], ForwardPanelEffects.prototype, "sent$", void 0);
__decorate([
    effects_1.Effect()
], ForwardPanelEffects.prototype, "sentFail$", void 0);
exports.ForwardPanelEffects = ForwardPanelEffects;
