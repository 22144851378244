"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResetSearchQueryValidationAction = exports.ValidateSearchQueryFailureAction = exports.ValidateSearchQuerySuccessAction = exports.ValidateSearchQueryAction = exports.CleanLexiconDetailsAction = exports.CloseLexiconDetailsAction = exports.GetActionSuccess = exports.GetActionFail = exports.GetAction = exports.CreateUpdateActionSuccess = exports.CreateUpdateActionFail = exports.UpdateAction = exports.CreateAction = exports.LexiconWizardActions = void 0;
var LexiconWizardActions;
(function (LexiconWizardActions) {
    LexiconWizardActions["CREATE"] = "[SupervisionLexiconWizard] Create";
    LexiconWizardActions["UPDATE"] = "[SupervisionLexiconWizard] Update";
    LexiconWizardActions["CREATE_UPDATE_SUCCESS"] = "[SupervisionLexiconWizard] Create/Update Success";
    LexiconWizardActions["CREATE_UPDATE_FAIL"] = "[SupervisionLexiconWizard] Create/Update Fail";
    LexiconWizardActions["GET"] = "[SupervisionLexiconWizard] Get";
    LexiconWizardActions["GET_SUCCESS"] = "[SupervisionLexiconWizard] Get Success";
    LexiconWizardActions["GET_FAIL"] = "[SupervisionLexiconWizard] Get Fail";
    LexiconWizardActions["CLEAN_LEXICON_DETAILS"] = "[SupervisionLexiconWizard] Clean Lexicon Details";
    LexiconWizardActions["CLOSE_LEXICON_DETAILS"] = "[SupervisionLexiconWizard] Close Lexicon Details";
    LexiconWizardActions["VALIDATE_SEARCH_QUERY"] = "[SupervisionLexiconWizard] Validate Search Query";
    LexiconWizardActions["VALIDATE_SEARCH_QUERY_SUCCESS"] = "[SupervisionLexiconWizard] Validate Search Query Success";
    LexiconWizardActions["VALIDATE_SEARCH_QUERY_FAIL"] = "[SupervisionLexiconWizard] Validate Search Query Fail";
    LexiconWizardActions["RESET_SEARCH_QUERY_VALIDATION"] = "[SupervisionLexiconWizard] Reset Search Query Validation";
})(LexiconWizardActions = exports.LexiconWizardActions || (exports.LexiconWizardActions = {}));
class CreateAction {
    constructor(lexicon) {
        this.lexicon = lexicon;
        this.type = LexiconWizardActions.CREATE;
    }
}
exports.CreateAction = CreateAction;
class UpdateAction {
    constructor(lexiconId, lexicon) {
        this.lexiconId = lexiconId;
        this.lexicon = lexicon;
        this.type = LexiconWizardActions.UPDATE;
    }
}
exports.UpdateAction = UpdateAction;
class CreateUpdateActionFail {
    constructor(message, failure, isUpdate = false) {
        this.message = message;
        this.failure = failure;
        this.isUpdate = isUpdate;
        this.type = LexiconWizardActions.CREATE_UPDATE_FAIL;
    }
}
exports.CreateUpdateActionFail = CreateUpdateActionFail;
class CreateUpdateActionSuccess {
    constructor(message) {
        this.message = message;
        this.type = LexiconWizardActions.CREATE_UPDATE_SUCCESS;
    }
}
exports.CreateUpdateActionSuccess = CreateUpdateActionSuccess;
class GetAction {
    constructor(lexicon) {
        this.lexicon = lexicon;
        this.type = LexiconWizardActions.GET;
    }
}
exports.GetAction = GetAction;
class GetActionFail {
    constructor(payload) {
        this.payload = payload;
        this.type = LexiconWizardActions.GET_FAIL;
    }
}
exports.GetActionFail = GetActionFail;
class GetActionSuccess {
    constructor(lexiconDetails) {
        this.lexiconDetails = lexiconDetails;
        this.type = LexiconWizardActions.GET_SUCCESS;
    }
}
exports.GetActionSuccess = GetActionSuccess;
class CloseLexiconDetailsAction {
    constructor() {
        this.type = LexiconWizardActions.CLOSE_LEXICON_DETAILS;
    }
}
exports.CloseLexiconDetailsAction = CloseLexiconDetailsAction;
class CleanLexiconDetailsAction {
    constructor() {
        this.type = LexiconWizardActions.CLEAN_LEXICON_DETAILS;
    }
}
exports.CleanLexiconDetailsAction = CleanLexiconDetailsAction;
class ValidateSearchQueryAction {
    constructor(payload) {
        this.payload = payload;
        this.type = LexiconWizardActions.VALIDATE_SEARCH_QUERY;
    }
}
exports.ValidateSearchQueryAction = ValidateSearchQueryAction;
class ValidateSearchQuerySuccessAction {
    constructor() {
        this.type = LexiconWizardActions.VALIDATE_SEARCH_QUERY_SUCCESS;
    }
}
exports.ValidateSearchQuerySuccessAction = ValidateSearchQuerySuccessAction;
class ValidateSearchQueryFailureAction {
    constructor(payload) {
        this.payload = payload;
        this.type = LexiconWizardActions.VALIDATE_SEARCH_QUERY_FAIL;
    }
}
exports.ValidateSearchQueryFailureAction = ValidateSearchQueryFailureAction;
class ResetSearchQueryValidationAction {
    constructor() {
        this.type = LexiconWizardActions.RESET_SEARCH_QUERY_VALIDATION;
    }
}
exports.ResetSearchQueryValidationAction = ResetSearchQueryValidationAction;
