<div *ngIf="!isVerificationApiError" class="mc-api-gateway-step-block">
  <mc-wizard-step-header
    [label]="'$I18N_API_GATEWAY_APPLICATIONS_KEYGEN_WIZARD.SUMMARY_STEP.HEADER' | translate"
  >
    <br />
    <mc-inline-notification [notification]="notification"></mc-inline-notification>
  </mc-wizard-step-header>

  <div class="mc-detailed-list mc-api-gateway-summary-block">
    <div class="row mc-detailed-list-row panel-padding-bottom">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_API_GATEWAY_APPLICATIONS_KEYGEN_WIZARD.FIELDS.SUMMARY_ACCESS_KEY' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        <div class="row">
          <div class="col-xs-10">
            <span *ngIf="showAccessKey">{{ apiKeys.accessKey }}</span>
            <span *ngIf="!showAccessKey">****** ******</span>
          </div>
          <div class="col-xs-2">
            <a
              ngxClipboard
              class="pull-right cursor-pointer text-normal"
              [cbContent]="apiKeys.accessKey"
              (cbOnSuccess)="
                clipboardSuccess(
                  '$I18N_API_GATEWAY_APPLICATIONS_KEYGEN_WIZARD.ACTIONS.ACCESS_KEY_TO_CLIPBOARD'
                )
              "
            >
              <em class="far fa-copy"></em>
            </a>
            <a
              *ngIf="showAccessKey"
              class="pull-right cursor-pointer panel-half-padding-right text-normal"
              (click)="hideAccessKeyClicked()"
            >
              <em class="far fa-eye-slash"></em>
            </a>
            <a
              *ngIf="!showAccessKey"
              class="pull-right cursor-pointer panel-half-padding-right text-normal"
              (click)="showAccessKeyClicked()"
            >
              <em class="far fa-eye"></em>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="row mc-detailed-list-row panel-padding-bottom">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_API_GATEWAY_APPLICATIONS_KEYGEN_WIZARD.FIELDS.SUMMARY_SECRET_KEY' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        <div class="row">
          <div class="col-xs-10">
            <span *ngIf="showSecretKey">{{ apiKeys.secretKey }}</span>
            <span *ngIf="!showSecretKey">****** ******</span>
          </div>
          <div class="col-xs-2">
            <a
              ngxClipboard
              class="pull-right cursor-pointer text-normal"
              [cbContent]="apiKeys.secretKey"
              (cbOnSuccess)="
                clipboardSuccess(
                  '$I18N_API_GATEWAY_APPLICATIONS_KEYGEN_WIZARD.ACTIONS.SECRET_KEY_TO_CLIPBOARD'
                )
              "
            >
              <em class="far fa-copy"></em>
            </a>
            <a
              *ngIf="showSecretKey"
              class="pull-right cursor-pointer panel-half-padding-right text-normal"
              (click)="hideSecretKeyClicked()"
            >
              <em class="far fa-eye-slash"></em>
            </a>
            <a
              *ngIf="!showSecretKey"
              class="pull-right cursor-pointer panel-half-padding-right text-normal"
              (click)="showSecretKeyClicked()"
            >
              <em class="far fa-eye"></em>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isVerificationApiError">
  <br />
  <mc-inline-notification
    [notification]="{ status: 'error', boxed: true, msg: errorMessageVerificationApi | translate: { url: SUPPORT_LINK } }"
  >
  </mc-inline-notification>
</div>
