"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RuleComponent = void 0;
const ruleActions = require("app-new/archive/supervision/actions/rule.actions");
const index_1 = require("app-new/archive/supervision/reducers/index");
const wizard_base_component_1 = require("../wizard-base.component");
const state_enum_1 = require("app-new/archive/supervision/types/state.enum");
const rule_config_builder_1 = require("app-new/archive/supervision/model/rule/rule-config.builder");
class RuleComponent extends wizard_base_component_1.WizardBaseDirective {
    constructor(capabilitiesService, store, translateService, stateService) {
        super(capabilitiesService, 'archive-supervision-wizard-rule', store, translateService);
        this.capabilitiesService = capabilitiesService;
        this.store = store;
        this.translateService = translateService;
        this.stateService = stateService;
        this.ruleDetails = this.stateService.$state.params.ruleDetails;
    }
    ngOnInit() {
        super.ngOnInit();
        if (this.isEditMode) {
            if (this.ruleDetails.lexicon) {
                this.store.dispatch(new ruleActions.AddSelectedLexiconAction({
                    id: this.ruleDetails.lexicon.id,
                    name: this.ruleDetails.lexicon.name
                }));
            }
            this.store.dispatch(new ruleActions.AddSelectedQueueAction({
                id: this.ruleDetails.reviewQueue.id,
                name: this.ruleDetails.reviewQueue.name
            }));
        }
        this.selectedLexicon$ = this.store.select(index_1.getSelectedRuleLexicon);
        this.selectedQueue$ = this.store.select(index_1.getSelectedRuleQueue);
        this.hasMonthlyScheduleCapability$ = this.capabilitiesService.hasCapability('Temporary.Monthly.Rule.Schedule');
    }
    ngOnDestroy() {
        this.store.dispatch(new ruleActions.ClearSelectedLexiconAction());
        this.store.dispatch(new ruleActions.ClearSelectedQueueAction());
    }
    get isEditMode() {
        return this.ruleDetails !== undefined && this.ruleDetails !== null;
    }
    get name() {
        return this.isEditMode && this.ruleDetails.name ? this.ruleDetails.name : '';
    }
    get description() {
        return this.isEditMode && this.ruleDetails.description ? this.ruleDetails.description : '';
    }
    get searchGroup() {
        return this.isEditMode && this.ruleDetails.searchGroup ? this.ruleDetails.searchGroup : '';
    }
    get messageRoute() {
        return this.isEditMode && this.ruleDetails.messageRoute ? this.ruleDetails.messageRoute : '';
    }
    get scheduleType() {
        return this.isEditMode && this.ruleDetails.scheduleType ? this.ruleDetails.scheduleType : '';
    }
    get isRuleEnabled() {
        return this.isEditMode && this.ruleDetails.state === state_enum_1.RuleState.ACTIVE;
    }
    get samplingPercentage() {
        return this.isEditMode && this.ruleDetails.samplingPercentage
            ? this.ruleDetails.samplingPercentage
            : '';
    }
    get wizardTitle() {
        if (!this.isEditMode) {
            return this.translateService.instant('$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.TAB_CREATE_TITLE');
        }
        return this.translateService.instant('$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.TAB_UPDATE_TITLE');
    }
    onSave() {
        if (!this.isEditMode) {
            this.store.dispatch(new ruleActions.CreateAction(this.createRuleObject()));
        }
        else {
            this.store.dispatch(new ruleActions.UpdateAction(this.ruleDetails.id, this.createRuleObject()));
        }
    }
    getCancelConfirmationText() {
        if (!this.isEditMode) {
            return this.translateService.instant('$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.CANCEL_CREATE_CONFIRMATION');
        }
        return this.translateService.instant('$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.CANCEL_UPDATE_CONFIRMATION');
    }
    getSaveButtonText() {
        if (!this.isEditMode) {
            return this.translateService.instant('$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.CONTROLS.CREATE');
        }
        return this.translateService.instant('$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.CONTROLS.UPDATE_SAVE');
    }
    getLexicons() {
        this.store.dispatch(new ruleActions.GetLexiconsAction());
    }
    getQueues() {
        this.store.dispatch(new ruleActions.GetQueuesAction());
    }
    createRuleObject() {
        const builder = new rule_config_builder_1.RuleConfigBuilder();
        if (this.wizardData.steps[1]) {
            builder
                .withName(this.wizardData.steps[1].data['name'])
                .withDescription(this.wizardData.steps[1].data['description']);
        }
        if (this.wizardData.steps[2]) {
            builder
                .withMessageRoute(this.wizardData.steps[2].data['messageRoute'])
                .withScheduleType(this.wizardData.steps[2].data['scheduleType'])
                .withSamplingPercentage(this.wizardData.steps[2].data['samplingPercentage'])
                .withState(this.wizardData.steps[2].data['isRuleEnabled'] ? state_enum_1.RuleState.ACTIVE : state_enum_1.RuleState.INACTIVE);
            if (this.wizardData.steps[2].data['searchGroup']) {
                builder.withSearchGroupId(this.wizardData.steps[2].data['searchGroup'].id);
            }
        }
        if (this.wizardData.steps[3]) {
            builder.withLexiconId(this.wizardData.steps[3].data['lexicon'] ? this.wizardData.steps[3].data['lexicon'].id : '');
        }
        if (this.wizardData.steps[4] && this.wizardData.steps[4].data['reviewQueue']) {
            builder.withReviewQueueId(this.wizardData.steps[4].data['reviewQueue'].id);
        }
        return builder.build();
    }
}
exports.RuleComponent = RuleComponent;
