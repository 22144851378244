"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RemovableListComponent = void 0;
const core_1 = require("@angular/core");
class RemovableListComponent {
    constructor() {
        this.disableRemoveAction = false;
        this.removedItem = new core_1.EventEmitter();
    }
    removeItem(index) {
        if (!this.disableRemoveAction) {
            this.removedItem.emit(this.data[index].id);
        }
    }
}
exports.RemovableListComponent = RemovableListComponent;
