<mc-layout-aside-extra-container
  keyTitle="{{ '$I18N_ARCHIVE_SUPERVISION_RULE_DETAILS.TITLE' | translate }}"
  showClose="true"
  [isLoading]="false"
  (closeAside)="close()"
>
  <mc-extra-container>
    <div class="mc-container-buttons-area">
      <button class="btn btn-primary" [disabled]="!hasEditPermissions || loading" (click)="edit()">
        {{ '$I18N_ARCHIVE_SUPERVISION_RULE_DETAILS.CONTROLS.EDIT' | translate }}
      </button>
      <button
        class="btn btn-secondary"
        [disabled]="!hasEditPermissions || loading"
        (click)="enableDisable()"
      >
        {{
          (isActive()
            ? '$I18N_ARCHIVE_SUPERVISION_RULE_DETAILS.CONTROLS.DISABLE'
            : '$I18N_ARCHIVE_SUPERVISION_RULE_DETAILS.CONTROLS.ACTIVE') | translate
        }}
      </button>
      <button  *ngIf="hasDeleteCapability" data-test="delete-button" class="btn btn-secondary" (click)="deleteRule(ruleDetails)">
        {{ '$I18N_ARCHIVE_SUPERVISION_RULE_DETAILS.CONTROLS.DELETE' | translate }}
      </button>
      <mc-paginator
        [isLoading]="loading"
        [previousToken]="paginationInfo && paginationInfo.previousEntity"
        [nextToken]="paginationInfo && paginationInfo.nextEntity"
        (paginate)="loadRuleDetails($event)"
      >
      </mc-paginator>
    </div>
  </mc-extra-container>
  <mc-body-container>
    <mc-loader-full-container
      [isLoading]="loading"
      class="mc-layout-loader-full-container-aside mc-flex-scroll-vertical"
    >
      <div *ngIf="loading" style="height: 100px;"></div>
    </mc-loader-full-container>

    <div *ngIf="!loading && ruleDetails">
      <mc-view-rule-details [ruleDetails]="ruleDetails"></mc-view-rule-details>
    </div>
  </mc-body-container>
</mc-layout-aside-extra-container>
