<div class="col-md-6 mc-overview-held-reason-panel-left">
  <mc-layout-wrapper-panel-simple class="mc-overview-page-component" [defaultTitle]="defaultTitle">
    <div class="mc-overview-wrapper">
      <div class="col-md-6 mc-overview-wrapper-autocomplete">
        <mc-autocomplete
          [options]="options$ | async"
          [ngClass]="layoutWrapperInputClass"
          [placeholder]="'$I18N_MESSAGE_CENTER_HELD_MESSAGES_OVERVIEW.SEARCH' | translate"
          (clickIconSearchEmit)="clickIconSearchEmit($event)">
        </mc-autocomplete>
      </div>
      <div class="col-md-6 mc-overview-wrapper-paginator">
        <mc-paginator
          [isLoading]="isPaginationLoading$ | async"
          class="pull-right"
          *ngIf="paginatorDataPaginated$ | async as paginatorData"
          [start]="paginatorData.start"
          [end]="paginatorData.pageSize"
          [separator]="separator"
          [total]="paginatorData.total"
          [previousToken]="paginatorData.prevPage"
          [nextToken]="paginatorData.nextPage"
          (paginate)="onPaginate($event)">
        </mc-paginator>
      </div>
    </div>

    <mc-table
      [isLoading]="isSummaryByReasonLoading$ | async"
      [data]="summaryByReasonDataPaginated$ | async"
      [columns]="columns"
      translatePrefix="$I18N_MESSAGE_CENTER_HELD_MESSAGES_OVERVIEW.TABLE"
      (rowClick)="clickedTableRow($event)">
      <mc-column key="reason">
        <mc-body-cell *mcBodyCellDef="let row">{{ row.policyInfo || '-' }}</mc-body-cell>
      </mc-column>
      <mc-column key="count">
        <mc-body-cell *mcBodyCellDef="let row">{{ row.numberOfItems || '-' }}</mc-body-cell>
      </mc-column>
    </mc-table>
  </mc-layout-wrapper-panel-simple>
</div>

<div class="col-md-6 mc-overview-held-reason-panel-right">
  <mc-layout-wrapper-panel-simple
    class="mc-overview-page-component"
    [defaultTitle]="topTenTitle"
    (clickedBarLayout)="clickedBar($event)">
    <mc-top-ten-held-messages-chart
      [chartData]="topTenHeldReasons$ | async"
      [isLoading$]="isSummaryByReasonLoading$"
      (clickedBar)="clickedBar($event)">
    </mc-top-ten-held-messages-chart>
  </mc-layout-wrapper-panel-simple>

  <mc-layout-wrapper-panel-simple
    class="mc-overview-page-component"
    [defaultTitle]="GroupHeldMessagesTitle"
    (clickedPieLayout)="clickedPie($event)">
    <mc-grouped-held-messages-chart
      [chartData$]="summaryByGroupData$"
      [isLoading$]="isSummaryByGroupLoading$"
      (clickedPieGroup)="clickedPie($event)">
    </mc-grouped-held-messages-chart>
  </mc-layout-wrapper-panel-simple>
</div>