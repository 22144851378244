"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgEndpointsInstallOpenDeleteAuthKeyModalAction = exports.SwgEndpointsInstallBinaryDownloadAction = exports.SwgEndpointsInstallAuthKeyDownloadAction = exports.SwgEndpointsInstallAuthKeyFailureAction = exports.SwgEndpointsInstallDeleteAuthKeySuccessAction = exports.SwgEndpointsInstallDeleteAuthKeyAction = exports.SwgEndpointsInstallGetAuthKeySuccessAction = exports.SwgEndpointsInstallGetAuthKeyAction = exports.SwgEndpointsActions = void 0;
var SwgEndpointsActions;
(function (SwgEndpointsActions) {
    SwgEndpointsActions["GET_AUTH_KEY"] = "[SwgEndpoints] Get Auth Key";
    SwgEndpointsActions["GET_AUTH_KEY_SUCCESS"] = "[SwgEndpoints] Get Auth Key Success";
    SwgEndpointsActions["DELETE_AUTH_KEY"] = "[SwgEndpoints] Delete Auth Key";
    SwgEndpointsActions["DELETE_AUTH_KEY_SUCCESS"] = "[SwgEndpoints] Delete Auth Key Success";
    SwgEndpointsActions["AUTH_KEY_FAILURE"] = "[SwgEndpoints] Auth Key Failure";
    SwgEndpointsActions["AUTH_KEY_DOWNLOAD"] = "[SwgEndpoints] Auth Key Download";
    SwgEndpointsActions["BINARY_DOWNLOAD"] = "[SwgEndpoints] Binary Download";
    SwgEndpointsActions["OPEN_DELETE_AUTH_KEY_MODAL"] = "[SwgEndpoints] Open Delete Ath Key Modal";
})(SwgEndpointsActions = exports.SwgEndpointsActions || (exports.SwgEndpointsActions = {}));
class SwgEndpointsInstallGetAuthKeyAction {
    constructor(payload = false) {
        this.payload = payload;
        this.type = SwgEndpointsActions.GET_AUTH_KEY;
    }
}
exports.SwgEndpointsInstallGetAuthKeyAction = SwgEndpointsInstallGetAuthKeyAction;
class SwgEndpointsInstallGetAuthKeySuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgEndpointsActions.GET_AUTH_KEY_SUCCESS;
    }
}
exports.SwgEndpointsInstallGetAuthKeySuccessAction = SwgEndpointsInstallGetAuthKeySuccessAction;
class SwgEndpointsInstallDeleteAuthKeyAction {
    constructor() {
        this.type = SwgEndpointsActions.DELETE_AUTH_KEY;
    }
}
exports.SwgEndpointsInstallDeleteAuthKeyAction = SwgEndpointsInstallDeleteAuthKeyAction;
class SwgEndpointsInstallDeleteAuthKeySuccessAction {
    constructor() {
        this.type = SwgEndpointsActions.DELETE_AUTH_KEY_SUCCESS;
    }
}
exports.SwgEndpointsInstallDeleteAuthKeySuccessAction = SwgEndpointsInstallDeleteAuthKeySuccessAction;
class SwgEndpointsInstallAuthKeyFailureAction {
    constructor() {
        this.type = SwgEndpointsActions.AUTH_KEY_FAILURE;
    }
}
exports.SwgEndpointsInstallAuthKeyFailureAction = SwgEndpointsInstallAuthKeyFailureAction;
class SwgEndpointsInstallAuthKeyDownloadAction {
    constructor() {
        this.type = SwgEndpointsActions.AUTH_KEY_DOWNLOAD;
    }
}
exports.SwgEndpointsInstallAuthKeyDownloadAction = SwgEndpointsInstallAuthKeyDownloadAction;
class SwgEndpointsInstallBinaryDownloadAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgEndpointsActions.BINARY_DOWNLOAD;
    }
}
exports.SwgEndpointsInstallBinaryDownloadAction = SwgEndpointsInstallBinaryDownloadAction;
class SwgEndpointsInstallOpenDeleteAuthKeyModalAction {
    constructor() {
        this.type = SwgEndpointsActions.OPEN_DELETE_AUTH_KEY_MODAL;
    }
}
exports.SwgEndpointsInstallOpenDeleteAuthKeyModalAction = SwgEndpointsInstallOpenDeleteAuthKeyModalAction;
