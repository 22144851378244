"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const listAction = require("../../actions/list.action");
const operators_1 = require("rxjs/operators");
const list_impl_service_1 = require("../../containers/list/service/list.impl.service");
const notification_actions_1 = require("app-new/components/notification/actions/notification.actions");
const delete_modal_component_1 = require("../../components/delete-modal/delete-modal.component");
const table_store_1 = require("@mimecast-ui/table-store");
class ListEffects {
    constructor(actions$, attributesService, translateService, modalService, tableStore) {
        this.actions$ = actions$;
        this.attributesService = attributesService;
        this.translateService = translateService;
        this.modalService = modalService;
        this.tableStore = tableStore;
        this.KEY_DIRECTORIES_LIST = '$18N_ATTRIBUTES_DIRECTORIES_LIST';
        this.hideAttribute$ = this.actions$.pipe(effects_1.ofType(listAction.HIDE_ATTRIBUTE), operators_1.switchMap((action) => {
            return this.attributesService
                .updateAttribute({ id: action.row.id, promptType: 'hidden_attribute' })
                .pipe(operators_1.map(() => {
                return new listAction.HideAttributeSuccessAction(action.row);
            }), operators_1.catchError(error => rxjs_1.of(new listAction.HideAttributeFailureAction(error))));
        }));
        this.hideAttributeSuccess$ = this.actions$.pipe(effects_1.ofType(listAction.HIDE_ATTRIBUTE_SUCCESS), operators_1.withLatestFrom(this.tableStore.select(table_store_1.tableReducer.getCurrentFilters(list_impl_service_1.ListImplService.STORE_ID)), this.tableStore.select(table_store_1.tableReducer.getCurrentPagination(list_impl_service_1.ListImplService.STORE_ID))), operators_1.switchMap(([, currentFilters, currentPagination]) => {
            return [
                new notification_actions_1.NotificationShowAction({
                    type: 'success',
                    config: {
                        msg: this.translateService.instant(`${this.KEY_DIRECTORIES_LIST}.SUCCESS.DISABLE`)
                    }
                }),
                new table_store_1.LoadAction({
                    tableId: list_impl_service_1.ListImplService.STORE_ID,
                    pagination: { pageToken: '', pageSize: currentPagination.pageSize, pageNumber: 0 },
                    query: currentFilters
                })
            ];
        }));
        this.hideAttributeFailure$ = this.actions$.pipe(effects_1.ofType(listAction.HIDE_ATTRIBUTE_FAILURE), operators_1.switchMap(() => {
            return rxjs_1.of(new notification_actions_1.NotificationShowAction({
                type: 'error',
                config: {
                    msg: this.translateService.instant(`${this.KEY_DIRECTORIES_LIST}.ERRORS.DISABLE`)
                }
            }));
        }));
        this.confirmDeleteAttribute$ = this.actions$.pipe(effects_1.ofType(listAction.DELETE_ATTRIBUTE_CONFIRM), operators_1.switchMap((action) => {
            const modalConfig = {};
            modalConfig.size = 'md';
            modalConfig.hasBackdrop = true;
            modalConfig.disableClose = true;
            modalConfig.data = action.payload;
            return this.modalService
                .open(delete_modal_component_1.AttributeDeleteModalComponent, modalConfig)
                .afterClose()
                .pipe(operators_1.map((result) => result ? new listAction.DeleteAttribute(action.payload) : { type: 'no action' }));
        }));
        this.deleteAttribute$ = this.actions$.pipe(effects_1.ofType(listAction.DELETE_ATTRIBUTE), operators_1.switchMap((action) => {
            return this.attributesService.deleteAttribute(action.attribute).pipe(operators_1.map((response) => {
                return new listAction.DeleteAttributeSuccess(response);
            }), operators_1.catchError(error => rxjs_1.of(new listAction.DeleteAttributeFailure(error))));
        }));
        this.deleteAttributeSuccess$ = this.actions$.pipe(effects_1.ofType(listAction.DELETE_ATTRIBUTE_SUCCESS), operators_1.withLatestFrom(this.tableStore.select(table_store_1.tableReducer.getCurrentFilters(list_impl_service_1.ListImplService.STORE_ID)), this.tableStore.select(table_store_1.tableReducer.getCurrentPagination(list_impl_service_1.ListImplService.STORE_ID))), operators_1.switchMap(([, currentFilters, currentPagination]) => {
            return rxjs_1.of(new table_store_1.LoadAction({
                tableId: list_impl_service_1.ListImplService.STORE_ID,
                pagination: { pageToken: '', pageSize: currentPagination.pageSize, pageNumber: 0 },
                query: currentFilters
            }));
        }));
        this.deleteAttributeFailure$ = this.actions$.pipe(effects_1.ofType(listAction.DELETE_ATTRIBUTE_FAILURE), operators_1.switchMap(() => {
            return rxjs_1.of(new notification_actions_1.NotificationShowAction({
                type: 'error',
                config: {
                    msg: this.translateService.instant(`${this.KEY_DIRECTORIES_LIST}.ERRORS.DELETE`)
                }
            }));
        }));
    }
}
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "hideAttribute$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "hideAttributeSuccess$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "hideAttributeFailure$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "confirmDeleteAttribute$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "deleteAttribute$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "deleteAttributeSuccess$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "deleteAttributeFailure$", void 0);
exports.ListEffects = ListEffects;
