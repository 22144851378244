<ng-container *ngIf="isWizard">
  <section
    class="mc-mep-policy-summary-block"
    data-test="mepCreatePolicyStatus"
  >
    <h2 class="mc-aside-header mc-mep-sidebar-section-title">{{ getStatusSwitchTitle() }}</h2>
    <p>{{ getStatusSwitchDescription() }}</p>
    <mc-mep-sidebar-enable-switch [status]="policy.enabled"></mc-mep-sidebar-enable-switch>
  </section>
  <hr>
</ng-container>

<section
  class="mc-detailed-list mc-mep-policy-summary-block"
  [ngClass]="isWizard ? 'summary-page' : null"
  data-test="mepPolicySidebarDetails"
>
  <h2 class="mc-aside-header mc-mep-sidebar-section-title">
    {{ '$18N_MEP_POLICY_LIST.SIDEBAR.DETAILS.SECTION_TITLE' | translate }}
  </h2>
  <mc-mep-sidebar-details
    [policy]="policy"
    [isSummaryPage]="isWizard"
  ></mc-mep-sidebar-details>
</section>
<hr>
<section
  class="mc-detailed-list mc-mep-policy-summary-block"
  [ngClass]="isWizard ? 'summary-page' : null"
  data-test="mepPolicySidebarAppliesTo"
>
  <h2 class="mc-aside-header mc-mep-sidebar-section-title">
    {{ '$18N_MEP_POLICY_LIST.SIDEBAR.APPLIES_TO.SECTION_TITLE' | translate }}
  </h2>
  <div
    class="row mc-detailed-list-row no-padding-top"
    *ngIf="policy.everyone || (groups.length > 0 || users.length > 0); else selectGroupsOrUsers"
  >
    <label class="col-sm-4 mc-detailed-list-label mc-sidebar-label">
      {{ '$18N_MEP_POLICY_LIST.SIDEBAR.APPLIES_TO.SENDER' | translate }}
    </label>
    <div class="col-sm-8 no-padding-left mc-detailed-list-column">
      <div *ngIf="policy.everyone">
        {{ '$I18N_SWG_CREATE_POLICY_POLICY_EVERYONE' | translate }}
      </div>
      <ng-container *ngIf="!policy.everyone">
        <div
          *ngIf="groups.length > 0"
          class="panel-padding-bottom"
        >
          <div class="mc-affected-targets-title">
            {{ '$I18N_SWG_CREATE_POLICY_POLICY_GROUPS' | translate }}
          </div>
          <div *ngFor="let target of groups">
            <div data-test="applies_to_target">{{ target.description }}</div>
          </div>
        </div>

        <div
          *ngIf="users.length > 0"
          class="panel-padding-bottom"
        >
          <div class="mc-affected-targets-title">
            {{ '$I18N_SWG_CREATE_POLICY_POLICY_USERS' | translate }}
          </div>
          <div *ngFor="let target of users">
            <div data-test="applies_to_target">{{ target.emailAddress }}</div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <ng-template #selectGroupsOrUsers>
    <mc-inline-notification [notification]="{
      status: 'info',
      boxed: true,
      msg: '$18N_MEP_POLICY_LIST.SIDEBAR.APPLIES_TO.SELECT_USERS_OR_GROUPS' | translate
    }"></mc-inline-notification>
  </ng-template>
</section>
