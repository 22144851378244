"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ2 = exports.ɵ1 = exports.ɵ0 = exports.isGetGroupDataLoading = exports.getGroup = exports.isCloudGroupReady = exports.isLDAPGroupReady = exports.isGetGroupsLoadingError = exports.hasInitialLDAPData = exports.hasInitialCloudData = exports.isGetGroupsLDAPDataLoaded = exports.isGetGroupsCloudDataLoaded = exports.getGroups = exports.isFindGroupsLoadingError = exports.isFindGroupsLDAPDataLoaded = exports.isFindGroupsCloudDataLoaded = exports.findGroups = exports.reducers = void 0;
const findGroupsReducer = require("./find-groups.reducer");
const getGroupReducer = require("./get-group.reducer");
const getGroupsReducer = require("./get-groups.reducer");
const store_1 = require("@ngrx/store");
exports.reducers = {
    findGroups: findGroupsReducer.reducer,
    getGroups: getGroupsReducer.reducer,
    getGroup: getGroupReducer.reducer
};
const getGroupSelectorState = store_1.createFeatureSelector('GroupSelector');
const ɵ0 = (state) => state.findGroups;
exports.ɵ0 = ɵ0;
const getFindGroupsState = store_1.createSelector(getGroupSelectorState, ɵ0);
exports.findGroups = store_1.createSelector(getFindGroupsState, findGroupsReducer.findGroups);
exports.isFindGroupsCloudDataLoaded = store_1.createSelector(getFindGroupsState, findGroupsReducer.isCloudDataLoaded);
exports.isFindGroupsLDAPDataLoaded = store_1.createSelector(getFindGroupsState, findGroupsReducer.isLDAPDataLoaded);
exports.isFindGroupsLoadingError = store_1.createSelector(getFindGroupsState, findGroupsReducer.hasGenericError);
const ɵ1 = (state) => state.getGroups;
exports.ɵ1 = ɵ1;
exports.getGroups = store_1.createSelector(getGroupSelectorState, ɵ1);
exports.isGetGroupsCloudDataLoaded = store_1.createSelector(exports.getGroups, getGroupsReducer.isCloudDataLoaded);
exports.isGetGroupsLDAPDataLoaded = store_1.createSelector(exports.getGroups, getGroupsReducer.isLDAPDataLoaded);
exports.hasInitialCloudData = store_1.createSelector(exports.getGroups, getGroupsReducer.hasCloudData);
exports.hasInitialLDAPData = store_1.createSelector(exports.getGroups, getGroupsReducer.hasLDAPData);
exports.isGetGroupsLoadingError = store_1.createSelector(exports.getGroups, getGroupsReducer.hasGenericError);
exports.isLDAPGroupReady = store_1.createSelector(exports.getGroups, getGroupsReducer.isLDAPReady);
exports.isCloudGroupReady = store_1.createSelector(exports.getGroups, getGroupsReducer.isCloudReady);
const ɵ2 = (state) => state.getGroup;
exports.ɵ2 = ɵ2;
exports.getGroup = store_1.createSelector(getGroupSelectorState, ɵ2);
exports.isGetGroupDataLoading = store_1.createSelector(exports.getGroup, getGroupReducer.isDataLoading);
