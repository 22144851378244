<mc-layout-detail-wizard
  keyTitle="{{ getPageTitle() }}"
  extendClass="panel-padding-bottom"
>
  <mc-help-container>
    <mc-help-link url="https://community.mimecast.com/docs/DOC-3187"></mc-help-link>
  </mc-help-container>

  <mc-wizard (changeStep)="goToStep($event)">
    <mc-wizard-step label="$I18N_AP_CREATE_POLICY_DETAILS">
      <mc-ap-policy-details-step [policyDetails]="policy.appolicyDesc"
                                 (updateApPolicyDescType)="updateTypApDetailseWizard.emit($event)">
      </mc-ap-policy-details-step>
    </mc-wizard-step>
    <mc-wizard-step label="$I18N_AP_CREATE_POLICY_WEB_INBOUND_SETTINGS">
      <mc-ap-inbound-settings-details-step [hasRemoveActiveContent$]="hasPolicyRemoveActiveContent$"
                                           [policyInbound]="policy.appolicyInbound"
                                           (updateApPolicyInboundType)="updateTypeApInboundWizard.emit($event)">
      </mc-ap-inbound-settings-details-step>
    </mc-wizard-step>
    <mc-wizard-step *ngIf="isIEPPackage" label="$I18N_AP_CREATE_POLICY_WEB_OUTBOUND_SETTINGS">
      <mc-ap-outbound-settings-details-step [policyOutbound]="policy.appolicyOutbound"
                                            (updateApPolicyOutboundType)="updateTypeApOutboundWizard.emit($event)">
      </mc-ap-outbound-settings-details-step>
    </mc-wizard-step>
    <mc-wizard-step *ngIf="isIEPPackage" label="$I18N_AP_CREATE_POLICY_WEB_JOURNAL_SETTINGS">
      <mc-ap-journal-settings-details-step [policyJournal]="policy.appolicyJournal"
                                           (updateApPolicyJournalType)="updateTypeApJournalWizard.emit($event)">
      </mc-ap-journal-settings-details-step>
    </mc-wizard-step>
    <mc-wizard-step label="$I18N_AP_CREATE_POLICY_WEB_APPLIES_TO">
      <mc-ap-policy-applies-to-step [policyAppliesTo]="policy.apPolicyAppliesToStep"
                                    (updateApPolicyAppliesToType)="updateTypeApAppliesToWizard.emit($event)"
                                    [attributeAddressList]="attributeList">
      </mc-ap-policy-applies-to-step>
    </mc-wizard-step>
    <mc-wizard-step label="$I18N_AP_CREATE_POLICY_WEB_SUMMARY">
      <mc-ap-policy-summary-step [hasRemoveActiveContent$]="hasPolicyRemoveActiveContent$"
                                 [hasIEPPackage]="isIEPPackage" [policySummaryPolicyStatus]="policy.appolicySummary"
                                 (updateApSummaryType)="updateSummaryWizard.emit($event)"></mc-ap-policy-summary-step>
    </mc-wizard-step>
  </mc-wizard>
  <mc-footer-container>
    <div class="pull-right no-select mc-button-footer">
      <button class="btn" (click)="cancel()">{{ '$I18N_COMMON_BTN_CANCEL' | translate }}</button>
      <ng-container *ngIf="wizard">
        <button
          class="btn btn-secondary panel-half-margin-right"
          *ngIf="wizard.hasPreviousStep$ | async"
          (click)="wizard.previousStep()"
        >
          {{ '$I18N_COMMON_BTN_PREVIOUS' | translate }}
        </button>
        <button
          class="btn btn-primary"
          [disabled]="!isStepValid()"
          *ngIf="wizard.hasNextStep$ | async"
          (click)="wizard.nextStep()">
          {{ '$I18N_COMMON_BTN_NEXT' | translate }}
        </button>
        <mc-live-button
          *ngIf="(wizard.isLastStep$ | async) && !isEditFlow()"
          overrideClasses="btn-primary"
          [label]="'$I18N_AP_CREATE_POLICY_ADD_POLICY' | translate"
          (click)="createApPolicyEvent.emit(policy)"
        ></mc-live-button>
        <button *ngIf="isEditFlow()"
                class="btn btn-primary"
                [disabled]="!isStepValid()"
                (click)="updateApPolicyEvent.emit(policy)"
        >{{ '$I18N_AP_CREATE_POLICY_SAVE_AND_CLOSE' | translate}}</button>
      </ng-container>
    </div>
  </mc-footer-container>
</mc-layout-detail-wizard>
