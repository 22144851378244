"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiemChannelDetailsComponent = void 0;
class SiemChannelDetailsComponent {
    constructor(siemService, notificationService, translateService) {
        this.siemService = siemService;
        this.notificationService = notificationService;
        this.translateService = translateService;
        this.isSidebar = false;
    }
    ngOnChanges() {
        this.subscribedChannel = Object.assign(Object.assign({}, this.channel), { products: this.siemService.deepCopyProducts(this.channel.products) });
        this.subscribedChannel.products = this.subscribedChannel.products.filter(product => {
            product.groups = product.groups.filter(group => {
                group.events = group.events.filter(event => event.enabled);
                if (group.events.length) {
                    return group;
                }
            });
            if (product.groups.length) {
                return product;
            }
        });
    }
    copyIdentifierToClipboardSuccess() {
        this.notificationService.success({
            msg: this.translateService.instant('$I18N_SIEM_CHANNELS_LIST_TABLE.IDENTIFIER_COPY_SUCCESS')
        });
    }
}
exports.SiemChannelDetailsComponent = SiemChannelDetailsComponent;
