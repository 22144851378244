"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PieChartComponent = void 0;
const am4core = require("@amcharts/amcharts4/core");
const am4charts = require("@amcharts/amcharts4/charts");
const core_1 = require("@angular/core");
const moment = require("moment");
const charts_1 = require("@mimecast-ui/charts");
const heldQueueAction = require("../../../actions/held-queue.actions");
class PieChartComponent extends charts_1.BaseChartComponent {
    constructor(zone, hostElement, translate, heldGroupDisplayPipe, store) {
        super(zone, hostElement, am4charts.PieChart);
        this.zone = zone;
        this.hostElement = hostElement;
        this.translate = translate;
        this.heldGroupDisplayPipe = heldGroupDisplayPipe;
        this.store = store;
        this.clickedPie = new core_1.EventEmitter();
    }
    ngAfterViewInit() {
        super.ngAfterViewInit();
        this.setupHeldMessagesByGroupChart();
    }
    ngOnDestroy() {
        super.ngOnDestroy();
        if (this.pieChartDataSubscription) {
            this.pieChartDataSubscription.unsubscribe();
        }
    }
    setupHeldMessagesByGroupChart() {
        this.pieChartDataSubscription = this.chartData$.subscribe(data => {
            if (data.length > 0) {
                const heldGroupMap = [];
                const heldGroupData = [];
                data.forEach(value => {
                    const groupName = this.heldGroupDisplayPipe.transform(value.heldGroup);
                    heldGroupMap.push({
                        key: value.heldGroup,
                        name: groupName
                    });
                    heldGroupData.push({
                        heldCount: value.heldCount,
                        heldGroup: groupName
                    });
                });
                const pieSeries = this.chart.series.push(new am4charts.PieSeries());
                pieSeries.data = heldGroupData;
                pieSeries.dataFields.value = 'heldCount';
                pieSeries.dataFields.category = 'heldGroup';
                pieSeries.alignLabels = false;
                pieSeries.labels.template.radius = am4core.percent(8);
                pieSeries.labels.template.text = '{category}: {value.percent.formatNumber("#.0")}%';
                pieSeries.slices.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
                // undo pull out of slices in the pie chart
                pieSeries.slices.template.states.getKey('active').properties.shiftRadius = 0;
                pieSeries.slices.template.events.on('hit', event => {
                    // get group name and dispatch action
                    const heldGroupName = event.target.dataItem.dataContext['heldGroup'];
                    const heldGroupKey = (heldGroupMap.find(group => group.name === heldGroupName) || { key: 'unknown' }).key;
                    this.store.dispatch(new heldQueueAction.HeldQueueRequestAction({
                        search: {
                            start: moment()
                                .subtract(30, 'days')
                                .toDate(),
                            end: moment().toDate(),
                            filterBy: [{ fieldName: 'heldGroup', value: heldGroupKey }],
                            searchBy: []
                        }
                    }));
                    this.pieSliceClick(heldGroupKey);
                }, this);
                // setup tooltip
                pieSeries.slices.template.tooltipText = `{category} \n${this.translate.instant('$I18N_MESSAGE_CENTER_HELD_MESSAGES_OVERVIEW.MESSAGES_HELD')}: [bold]{value}[/]`;
            }
        });
    }
    pieSliceClick(heldGroup) {
        // Emit event to inform Grouped Held messages Chart component that a pie slice was clicked
        this.clickedPie.emit({ field: 'heldGroup', value: heldGroup });
    }
}
exports.PieChartComponent = PieChartComponent;
