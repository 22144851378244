<mc-layout-aside-extra-container [keyTitle]="'$I18N_TTP_IMPERSONATION_LOG_DETAILS_TITLE'"
                                 [showClose]="true"
                                 [isLoading]="logDetailsIsLoading"
                                 (closeAside)="closeAside.emit()">
  <mc-extra-container>
    <mc-ttp-ip-log-details-header [logDetails]="logDetails"
                                  [paginatorData]="previousNextRow"
                                  [permitSenderIsProcessing]="permitSenderIsProcessing"
                                  [blockSenderIsProcessing]="blockSenderIsProcessing"
                                  [monitorDomainIsProcessing]="monitorDomainIsProcessing"
                                  (permitSender)="permitSender.emit($event)"
                                  (blockSender)="blockSender.emit($event)"
                                  (selectRow)="selectRow.emit($event)"
                                  (monitorDomain)="monitorDomain.emit($event)"></mc-ttp-ip-log-details-header>
  </mc-extra-container>

  <mc-body-container>
    <div class="mc-detailed-list">
      <mc-ttp-ip-log-details-general-information [logDetails]="logDetails"></mc-ttp-ip-log-details-general-information>
      <mc-ttp-impersonation-log-details-actions [logDetails]="logDetails"></mc-ttp-impersonation-log-details-actions>
      <mc-ttp-ip-log-details-identifiers [logDetails]="logDetails"></mc-ttp-ip-log-details-identifiers>
    </div>
  </mc-body-container>
</mc-layout-aside-extra-container>
