"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ1 = exports.ɵ0 = exports.getForwardingEmail = exports.getForwardingMessages = exports.isSendingForward = exports.getSelectedForwardingMessage = exports.getPreviousAndNextSelectedRow = exports.getMessageList = exports.isSidePanelLoading = exports.getSelectedEmail = exports.getSelectedDeliveryMessage = exports.getSelectedMessage = exports.getForceLoadContent = exports.getMessageInfo = exports.reducers = void 0;
const store_1 = require("@ngrx/store");
const messageInfoReducer = require("./message-info.reducer");
const forwardReducer = require("./forward.reducer");
exports.reducers = {
    messageInfo: messageInfoReducer.reducer,
    forward: forwardReducer.reducer
};
const getMessageCenterState = store_1.createFeatureSelector('message-center');
const ɵ0 = (state) => state.messageInfo;
exports.ɵ0 = ɵ0;
const getMessageInfoState = store_1.createSelector(getMessageCenterState, ɵ0);
exports.getMessageInfo = store_1.createSelector(getMessageInfoState, messageInfoReducer.getMessageInfo);
exports.getForceLoadContent = store_1.createSelector(getMessageInfoState, messageInfoReducer.getForceLoadContent);
exports.getSelectedMessage = store_1.createSelector(getMessageInfoState, messageInfoReducer.getSelectedMessage);
exports.getSelectedDeliveryMessage = store_1.createSelector(getMessageInfoState, messageInfoReducer.getSelectedDeliveryMessage);
exports.getSelectedEmail = store_1.createSelector(getMessageInfoState, messageInfoReducer.getSelectedEmail);
exports.isSidePanelLoading = store_1.createSelector(getMessageInfoState, messageInfoReducer.isSidePanelLoading);
exports.getMessageList = store_1.createSelector(getMessageInfoState, messageInfoReducer.getMessageList);
exports.getPreviousAndNextSelectedRow = store_1.createSelector(getMessageInfoState, messageInfoReducer.getPreviousAndNextSelectedRow);
exports.getSelectedForwardingMessage = store_1.createSelector(getMessageInfoState, messageInfoReducer.getSelectedForwardingMessage);
const ɵ1 = (state) => state.forward;
exports.ɵ1 = ɵ1;
const getForwardState = store_1.createSelector(getMessageCenterState, ɵ1);
exports.isSendingForward = store_1.createSelector(getForwardState, forwardReducer.isSendingForward);
exports.getForwardingMessages = store_1.createSelector(getForwardState, forwardReducer.getForwardingMessages);
exports.getForwardingEmail = store_1.createSelector(getForwardState, forwardReducer.getForwardingEmail);
