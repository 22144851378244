"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UiEffects = void 0;
const effects_1 = require("@ngrx/effects");
const operators_1 = require("rxjs/operators");
const actions = require("../actions");
class UiEffects {
    constructor(actions$) {
        this.actions$ = actions$;
        this.uiAsidePanelResized$ = this.actions$.pipe(effects_1.ofType(actions.ui.RIGHT_ASIDE_PANEL_RESIZED), operators_1.map((action) => new actions.user.PreferencesSaveAction({ uiAsidePanelWidth: action.width }, false)));
    }
}
__decorate([
    effects_1.Effect()
], UiEffects.prototype, "uiAsidePanelResized$", void 0);
exports.UiEffects = UiEffects;
