<ng-container *ngIf="(releaseLog$ | async) as releaseLog">
  <mc-layout-aside-tabs
    keyTitle="$I18N_MESSAGE_CENTER_RELEASE_LOG_DETAIL.TITLE"
    [isLoading]="false"
    showClose="true"
    [hasTabs]="true"
    (closeAside)="closeClicked()">

    <mc-extra-container>
      <div class="mc-header-row">
        <div class="status-info">
          <div class="status-info-span-container">
            <span>Status:</span>
            <span>{{ releaseLog.status | mcEmailStatusDisplay }}</span>
          </div>
        </div>
        <mc-paginator
            [isLoading]="isPaginationLoading$ | async"
            class="pull-right"
            [start]="start + 1"
            [end]="end$ | async"
            [total]="total$ | async"
            [separator]="separator"
            [previousToken]="currentItemIndex > 0 ? 'whoot!' : null"
            [nextToken]="currentItemIndex + 1 < (total$ | async) ? 'moar!!' : null"
            (paginate)="onPaginate($event)">
        </mc-paginator>
      </div>
    </mc-extra-container>
    <mc-tab-group
        themeLight="true"
        resetActiveTabToFirst="true">

      <mc-tab name="$I18N_MESSAGE_CENTER_RELEASE_LOG_DETAIL.TAB.MESSAGE">
        <mc-release-log-message
            [releaseLog]="releaseLog"
            [showSpamDetails]="showSpamDetails">
        </mc-release-log-message>
      </mc-tab>
      <mc-tab name="$I18N_MESSAGE_CENTER_RELEASE_LOG_DETAIL.TAB.ANALYSIS" *mcCapabilities="'Temporary.MessageCenter.SpamScore || Temporary.MessageCenter.SpamScore.Beta'">
         <mc-release-log-threat-information
             [releaseLog]="releaseLog">
         </mc-release-log-threat-information>
      </mc-tab>

    </mc-tab-group>
  </mc-layout-aside-tabs>
</ng-container>