"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RemoveGroupAction = exports.AddGroupCortexIntegrationNotificationsAction = exports.SaveAppliesToEveryoneAction = exports.REMOVE_GROUP = exports.ADD_GROUP = exports.SAVE_APPLIES_TO_EVERYONE = void 0;
exports.SAVE_APPLIES_TO_EVERYONE = '[IdentityPolicyWizard AppliesToStep] Save Applies To Everyone';
exports.ADD_GROUP = '[IdentityPolicyWizard AppliesToStep] Add Group';
exports.REMOVE_GROUP = '[IdentityPolicyWizard AppliesToStep] Remove Group';
class SaveAppliesToEveryoneAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SAVE_APPLIES_TO_EVERYONE;
    }
}
exports.SaveAppliesToEveryoneAction = SaveAppliesToEveryoneAction;
class AddGroupCortexIntegrationNotificationsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.ADD_GROUP;
    }
}
exports.AddGroupCortexIntegrationNotificationsAction = AddGroupCortexIntegrationNotificationsAction;
class RemoveGroupAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.REMOVE_GROUP;
    }
}
exports.RemoveGroupAction = RemoveGroupAction;
