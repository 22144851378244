"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueueConfigDetailsBuilder = void 0;
const queue_config_details_1 = require("./queue-config-details");
const queue_config_base_builder_1 = require("./queue-config-base.builder");
class QueueConfigDetailsBuilder extends queue_config_base_builder_1.QueueConfigBaseBuilder {
    withDefaultEscalationQueue(defaultEscalationQueue) {
        this.defaultEscalationQueue = defaultEscalationQueue;
        return this;
    }
    withAssociatedReviewQueues(associatedReviewQueues) {
        this.associatedReviewQueues = !associatedReviewQueues ? [] : [...associatedReviewQueues];
        return this;
    }
    withAssociatedRules(associatedRules) {
        this.associatedRules = !associatedRules ? [] : [...associatedRules];
        return this;
    }
    withEscalationQueues(escalationQueues) {
        this.escalationQueues = !escalationQueues ? [] : [...escalationQueues];
        return this;
    }
    withReviewQueues(reviewQueues) {
        this.reviewQueues = !reviewQueues ? [] : [...reviewQueues];
        return this;
    }
    withReviewers(reviewers) {
        this.reviewers = !reviewers ? [] : [...reviewers];
        return this.self();
    }
    self() {
        return this;
    }
    build() {
        return new queue_config_details_1.QueueConfigDetails(this.id, this.name, this.description, this.type, this.createdBy, this.created, this.updatedBy, this.updated, this.status, this.forwardAllowed, this.printAllowed, this.labels, this.reviewers, this.reviewerCount, this.defaultEscalationQueue, this.escalationQueues, this.associatedReviewQueues, this.associatedRules, this.reviewQueues);
    }
}
exports.QueueConfigDetailsBuilder = QueueConfigDetailsBuilder;
