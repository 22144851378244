<div class="mc-ttp-ip-log-details-header-first-row">
  <div class="mc-ttp-ip-log-details-header-title">
    <span class="mc-ttp-ip-log-details-header-title-label">{{ '$I18N_TTP_IMPERSONATION_LOG_DETAILS_HEADER.TITLE_LABEL' | translate }}:&nbsp;</span>
    <span class="mc-ttp-ip-log-details-header-title-info">{{ logDetails.senderAddress }}</span>
  </div>
  <mc-paginator *ngIf="paginatorData"
                [start]="paginatorData?.currentItemIndex"
                [end]="paginatorData.pageSize"
                [isLoading]="isLoading"
                [previousToken]="paginatorData.previous"
                [nextToken]="paginatorData.next"
                (paginate)="selectRow.emit($event.token)">
  </mc-paginator>
</div>
<div class="mc-ttp-ip-log-details-header-second-row">
  <div class="btn-group" role="group">
    <button class="btn btn-secondary"
            (click)="permitSender.emit({sender: logDetails.senderAddress, to: logDetails.recipientAddress, action: 'permit'})"
            tooltip="{{ '$I18N_TTP_IMPERSONATION_LOG_DETAILS_HEADER.BUTTON_TOOLTIP.PERMIT_SENDER' | translate }}"
            placement="bottom"
            [disabled]="permitSenderIsProcessing">
      <mc-spinner *ngIf="permitSenderIsProcessing" [inverted]="false"></mc-spinner>
      {{'$I18N_TTP_IMPERSONATION_LOG_DETAILS_HEADER.BUTTON_LABEL.PERMIT_SENDER' | translate}}
    </button>
    <button class="btn btn-secondary"
            (click)="blockSender.emit({sender: logDetails.senderAddress, to: logDetails.recipientAddress, action: 'block'})"
            tooltip="{{ '$I18N_TTP_IMPERSONATION_LOG_DETAILS_HEADER.BUTTON_TOOLTIP.BLOCK_SENDER' | translate }}"
            placement="bottom"
            [disabled]="blockSenderIsProcessing">
      <mc-spinner *ngIf="blockSenderIsProcessing" [inverted]="false"></mc-spinner>
      {{'$I18N_TTP_IMPERSONATION_LOG_DETAILS_HEADER.BUTTON_LABEL.BLOCK_SENDER' | translate}}
    </button>
  </div>
  <mc-live-button [label]="'$I18N_TTP_IMPERSONATION_LOG_DETAILS_HEADER.BUTTON_LABEL.MONITOR_DOMAIN' | translate"
                  overrideClasses="btn-secondary"
                  [isLoading]="monitorDomainIsProcessing"
                  (mcClick)="monitorDomain.emit(logDetails.senderAddress)"
                  [inverted]="false"
                  tooltip="{{'$I18N_TTP_IMPERSONATION_LOG_DETAILS_HEADER.BUTTON_TOOLTIP.MONITOR_DOMAIN' | translate}}"
                  placement="bottom">
  </mc-live-button>
</div>
