"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CortexIntegrationService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const common_1 = require("../../common");
class CortexIntegrationService {
    constructor(http, decoder) {
        this.http = http;
        this.decoder = decoder;
    }
    getCortexIntegrationVersion1(id, version) {
        return this.http
            .post('/api/integration/cortex/get-integration', { id, version })
            .pipe(common_1.firstResult());
    }
    createIntegrationVersion1(integration) {
        return this.http
            .post('/api/integration/cortex/create-integration', integration)
            .pipe(common_1.firstResult());
    }
    updateIntegrationVersion1(integration) {
        return this.http
            .post('/api/integration/cortex/update-integration', integration)
            .pipe(common_1.firstResult());
    }
    isInternalEmail(emailAddress) {
        return this.http
            .post('/api/user/get-profile', { emailAddress })
            .pipe(operators_1.map((response) => {
            return !response.first.external;
        }), operators_1.catchError(() => {
            return rxjs_1.of(false);
        }));
    }
    buildCreateRequestVersion1(configuration, instanceConfig) {
        const decoded = this.decoder.decodeUrlParameters(atob(configuration.config));
        return {
            version: 1,
            instanceId: decoded.instance_id || instanceConfig.instanceId,
            region: decoded.region || instanceConfig.region,
            triggers: [
                this.buildTriggerVersion1(configuration.cortexSpywareSettings),
                this.buildTriggerVersion1(configuration.cortexWildfireSettings)
            ],
            lakeSerialNumber: instanceConfig.lakeSerialNumber,
            integrationName: instanceConfig.integrationName
        };
    }
    buildUpdateRequestVersion1(configuration, hasError) {
        const config = {
            version: 1,
            id: configuration.cortex.id,
            triggers: [
                this.buildTriggerVersion1(configuration.cortexSpywareSettings),
                this.buildTriggerVersion1(configuration.cortexWildfireSettings)
            ],
            notificationAddresses: configuration.notifications.notificationAddresses,
            notificationGroups: configuration.notifications.notificationGroups
        };
        if (hasError) {
            delete config['id'];
        }
        return config;
    }
    buildTriggerVersion1(cortexSetting) {
        return {
            type: 'panw.threat',
            fields: {
                subtype: cortexSetting.fields.subtype,
                severity: cortexSetting.fields.severity
            },
            action: cortexSetting.action,
            config: cortexSetting.config,
            enabled: cortexSetting.enabled,
            disableUser: cortexSetting.disableUser
        };
    }
}
exports.CortexIntegrationService = CortexIntegrationService;
