"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgChartNoDataMessageComponent = void 0;
class SwgChartNoDataMessageComponent {
    constructor(stateService) {
        this.stateService = stateService;
        this.icon = 'fa-chart-line';
        this.messageHeading = '$I18N_SWG_APPLICATIONS_DASHBOARD_CHART_NO_DATA.HEADING';
    }
    clickLink() {
        this.stateService.$state.go('secure-web-gateway-policy');
    }
}
exports.SwgChartNoDataMessageComponent = SwgChartNoDataMessageComponent;
