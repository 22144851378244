<mc-table
  tableId="api-applications/list"
  [isLoading]="isLoading"
  [highlightedRow]="selectedRow"
  [data]="applications"
  [columns]="columns"
  (rowClick)="viewApplication($event)"
  translatePrefix="$I18N_API_GATEWAY_APPLICATIONS_LIST_TABLE"
>


  <mc-empty-results
    iconClass="far fa-list-alt"
    keyTitle="$I18N_API_GATEWAY_APPLICATIONS_LIST_TABLE.NO_DATA.TITLE"
  >
    <mc-empty-body>
      <div>
        {{ '$I18N_API_GATEWAY_APPLICATIONS_LIST_TABLE.NO_DATA.DESCRIPTION.LINE_1' | translate }}
        <a
          *mcCapabilities="'Permission.API_APPLICATIONS_EDIT'"
          class="mc-empty-table-link"
          (click)="createApplication()"
        >{{
          '$I18N_API_GATEWAY_APPLICATIONS_LIST_TABLE.NO_DATA.DESCRIPTION.LINK_2' | translate
          }}</a
        >
        <span *mcCapabilities="'!Permission.API_APPLICATIONS_EDIT'">{{
          '$I18N_API_GATEWAY_APPLICATIONS_LIST_TABLE.NO_DATA.DESCRIPTION.LINK_2' | translate
          }}</span>
        {{ '$I18N_API_GATEWAY_APPLICATIONS_LIST_TABLE.NO_DATA.DESCRIPTION.LINE_3' | translate }}
      </div>
    </mc-empty-body>
  </mc-empty-results>

  <mc-filters
    [showPagination]="true"
    [externalTableSearchCount]="totalCount"
    [metadata]="pagingMetadata"
    (paginatorChange)="changePage($event)"
  >
    <mc-filter-column-select-and-search
      [sections]="searchByColumns"
      [placeholder]="
        '$I18N_API_GATEWAY_APPLICATIONS_LIST_TABLE.FILTERS.SEARCH.PLACEHOLDER' | translate
      "
      (filtersChange)="search($event)"
    >
    </mc-filter-column-select-and-search>
  </mc-filters>

  <mc-column key="active">
    <mc-body-cell *mcBodyCellDef="let row">
      <span *ngIf="row.active">{{ '$I18N_API_GATEWAY_APPLICATIONS_LIST_TABLE.ENABLE' | translate }}</span>
      <span *ngIf="!row.active">{{ '$I18N_API_GATEWAY_APPLICATIONS_LIST_TABLE.DISABLE' | translate }}</span>
    </mc-body-cell>
  </mc-column>

  <mc-column key="category">
    <mc-body-cell *mcBodyCellDef="let row">{{ row.category | mcApplicationCategory }}</mc-body-cell>
  </mc-column>

  <mc-column key="service">
    <mc-body-cell *mcBodyCellDef="let row">
      <span> {{ isGatewayApplication(row)
        ? ('$I18N_API_GATEWAY_APPLICATIONS_LIST_TABLE.GENERIC_NOT_AVAILABLE' | translate)
        : row.service | mcServiceApplication}}</span>
    </mc-body-cell>
  </mc-column>

  <mc-column key="appId">
    <mc-body-cell *mcBodyCellDef="let row">
      <span>{{ isGatewayApplication(row)
        ? ('$I18N_API_GATEWAY_APPLICATIONS_LIST_TABLE.GENERIC_NOT_AVAILABLE' | translate)
        : row.appId }}</span>
    </mc-body-cell>
  </mc-column>

  <mc-column-actions
    key="action"
    mcShowColumnConfig
    [columnsAlwaysVisible]="columnsAlwaysVisible"
    [columnsToStartHidden]="columnsStartHidden"
  >
    <mc-row-actions *mcRowActions="let row">
      <li
        mcRowAction="$I18N_API_GATEWAY_APPLICATIONS_LIST_TABLE.ACTIONS.VIEW"
        (click)="viewApplication(row)"
      ></li>
      <ng-container *mcCapabilities="'Permission.API_APPLICATIONS_EDIT'">
        <li
          *ngIf="row.active && !isGatewayApplication(row)"
          mcRowAction="$I18N_API_GATEWAY_APPLICATIONS_LIST_TABLE.ACTIONS.DISABLE"
          (click)="disableApplication(row)"
        ></li>
        <li
          *ngIf="!row.active && !isGatewayApplication(row)"
          mcRowAction="$I18N_API_GATEWAY_APPLICATIONS_LIST_TABLE.ACTIONS.ENABLE"
          (click)="enableApplication(row)"
        ></li>
        <li
          mcRowAction="$I18N_API_GATEWAY_APPLICATIONS_LIST_TABLE.ACTIONS.EDIT"
          (click)="editApplication(row)"
        ></li>
        <li
          *ngIf="!row.corsEnabled && row.active && !isGatewayApplication(row)"
          mcRowAction="$I18N_API_GATEWAY_APPLICATIONS_LIST_TABLE.ACTIONS.KEYGEN"
          (click)="generateKeys(row)"
        ></li>
        <li
          *ngIf="!row.corsEnabled && row.active && isGatewayApplication(row)"
          mcRowAction="$I18N_API_GATEWAY_APPLICATIONS_LIST_TABLE.ACTIONS.MANAGE_GATEWAY_APPLICATION_CREDENTIALS"
          (click)="getCredentials(row)"
        ></li>
        <li mcRowActionSeparator></li>
        <li
          mcRowAction="$I18N_API_GATEWAY_APPLICATIONS_LIST_TABLE.ACTIONS.DELETE"
          (click)="deleteApplication(row)"
        ></li>
      </ng-container>
    </mc-row-actions>
  </mc-column-actions>
</mc-table>
