"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.getBulkDetailPageTotal = exports.getbulkProcessingTotal = exports.getbulkProcessingMetadata = exports.getbulkProcessingRequestPage = exports.getbulkProcessingPagination = exports.getbulkProcessingSelectedRow = exports.getbulkProcessingSearch = exports.getbulkProcessingTotalCount = exports.getbulkProcessingItems = exports.isbulkProcessingLoading = exports.BulkProcessingReducers = void 0;
const store_1 = require("@ngrx/store");
const frombulkProcessing = require("./bulk-processing.reducer");
exports.BulkProcessingReducers = {
    bulkProcessing: frombulkProcessing.reducer
};
const bulkProcessingState = store_1.createFeatureSelector('bulk-processing');
const ɵ0 = (state) => state.bulkProcessing;
exports.ɵ0 = ɵ0;
const getbulkProcessingState = store_1.createSelector(bulkProcessingState, ɵ0);
exports.isbulkProcessingLoading = store_1.createSelector(getbulkProcessingState, frombulkProcessing.isLoading);
exports.getbulkProcessingItems = store_1.createSelector(getbulkProcessingState, frombulkProcessing.getItems);
exports.getbulkProcessingTotalCount = store_1.createSelector(getbulkProcessingState, frombulkProcessing.getTotalCount);
exports.getbulkProcessingSearch = store_1.createSelector(getbulkProcessingState, frombulkProcessing.getSearchRequest);
exports.getbulkProcessingSelectedRow = store_1.createSelector(getbulkProcessingState, frombulkProcessing.getSelectedRow);
exports.getbulkProcessingPagination = store_1.createSelector(getbulkProcessingState, frombulkProcessing.getPagination);
exports.getbulkProcessingRequestPage = store_1.createSelector(getbulkProcessingState, frombulkProcessing.getRequestPage);
exports.getbulkProcessingMetadata = store_1.createSelector(getbulkProcessingState, frombulkProcessing.getMetadata);
exports.getbulkProcessingTotal = store_1.createSelector(getbulkProcessingState, frombulkProcessing.getBulkDetailPageTotal);
exports.getBulkDetailPageTotal = store_1.createSelector(getbulkProcessingState, frombulkProcessing.getBulkDetailPageTotal);
