"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BiPolicyCreateStepComponent = void 0;
const models_1 = require("../../../../../../../swg/policies/models");
class BiPolicyCreateStepComponent {
    constructor() {
        this._locations = [];
        this._users = [];
        this._groups = [];
    }
    ngOnChanges(changes) {
        const swgPolicyChange = changes['swgPolicy'];
        if (swgPolicyChange && !swgPolicyChange.isFirstChange()) {
            this.update();
        }
    }
    ngOnInit() {
        this.update();
    }
    update() {
        this._locations = [];
        this._users = [];
        this._groups = [];
        for (const target of this.swgPolicy.affectedUsers) {
            if (target.condition === models_1.SwgAffectedUsersEnum.GROUP) {
                // @ts-ignore
                this._groups.push(target.payload);
            }
            if (target.condition === models_1.SwgAffectedUsersEnum.LOCATION) {
                this._locations.push(target.payload);
            }
            if (target.condition === models_1.SwgAffectedUsersEnum.USER) {
                // @ts-ignore
                this._users.push(target.payload);
            }
        }
    }
    getPageTitle() {
        if (this.swgPolicy.policyDesc.id !== undefined && this.swgPolicy.policyDesc.id) {
            return '$I18N_BI_EDIT_POLICY_SUMMARY_HEADER';
        }
        else {
            return '$I18N_BI_CREATE_POLICY_SUMMARY_HEADER';
        }
    }
}
exports.BiPolicyCreateStepComponent = BiPolicyCreateStepComponent;
