<mc-sfc-graph-layout
  [loading]="loading"
  [noData]="!loading && data.length === 0"
  [noDataMessage]="'$I18N_SFC_CHART_NO_DATA'"
  [chartName]="CHART_NAME">
  <mc-chart class="char-container" *ngIf="multilineChartConfiguration">
    <mc-multi-line-graph-chart
      class="mc-amchart"
      [config]="multilineChartConfiguration"
      [chartData]="data">
    </mc-multi-line-graph-chart>
  </mc-chart>
</mc-sfc-graph-layout>
