"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./aside-controller"); //dashboards-administration-aside.controller
// html and css 
require("./aside.tpl.html");
(function () {
    'use strict';
    /* ======================================================== *\

     = dashboards / administration / aside - module
     = 07/01/2016
     = joao carradinha

     \* ======================================================== */
    angular.module('dashboards-administration-aside', ['dashboards-administration-aside.controller']);
}());
