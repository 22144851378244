"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isOrderAscending = exports.getSearchTerm = exports.isLoading = exports.getListContent = exports.reducer = exports.initialState = void 0;
const list = require("../actions/list");
exports.initialState = {
    listServer: [],
    orderAscending: true,
    searchTerm: '',
    isLoading: false
};
/**
 * Reducer
 */
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case list.LOAD:
        case list.DELETE:
            return Object.assign(Object.assign({}, state), { isLoading: true });
        case list.LOAD_SUCCESS:
            return Object.assign(Object.assign({}, state), { listServer: action.payload, isLoading: false });
        case list.LOAD_FAIL:
        case list.DELETE_FAIL:
            return Object.assign(Object.assign({}, state), { isLoading: false });
        case list.TOGGLE_ORDER:
            return Object.assign(Object.assign({}, state), { orderAscending: !state.orderAscending });
        case list.SEARCH:
            return Object.assign(Object.assign({}, state), { searchTerm: action.payload });
        default:
            return state;
    }
}
exports.reducer = reducer;
/**
 * Selectors
 */
const getListContent = (state) => {
    const filteredList = search(state.listServer, state.searchTerm);
    const orderedAndFilteredList = sort(filteredList, state.orderAscending);
    return orderedAndFilteredList;
};
exports.getListContent = getListContent;
const isLoading = (state) => state.isLoading;
exports.isLoading = isLoading;
const getSearchTerm = (state) => state.searchTerm;
exports.getSearchTerm = getSearchTerm;
const isOrderAscending = (state) => state.orderAscending;
exports.isOrderAscending = isOrderAscending;
/**
 * Helpers
 */
/**
 * Filters templates based on template.name and the given searchTerm
 *    - compares searchTerm with template.name using case insensitive
 */
function search(results, searchTerm = '') {
    const s = searchTerm.toLowerCase().trim();
    return (results &&
        results.filter(template => {
            const name = template.name && template.name.toLowerCase();
            return name && name.includes(s);
        }));
}
/**
 * sorts the given template array in a descending/ascending order
 *    - compares searchTerm with template.name using case insensitive
 */
function sort(results, ascending = true) {
    return (results &&
        [...results].sort((a, b) => {
            const order = ascending ? 1 : -1;
            const aName = a.name && a.name.toLowerCase();
            const bName = b.name && b.name.toLowerCase();
            if (aName < bName) {
                return -1 * order;
            }
            else if (aName > bName) {
                return 1 * order;
            }
            return 0;
        }));
}
