<mc-api-integration-wildfire-wizard
  [wildfireIntegration]="wildfireIntegration$ | async"
  [wildfirePrerequisitesError]="wildfirePrerequisitesError$ | async"
  [hasCreatedIntegration]="hasCreatedIntegration$ | async"
  [isApiAndRegionVerified]="isApiAndRegionVerified$ | async"
  [isVerifyApiKeyButtonDisabled]="isVerifyApiKeyButtonDisabled$ | async"
  (onSwitchWildfireIntegrationStatus)="switchWildfireIntegrationStatus($event)"
  (onSwitchAttachmentIndicator)="switchAttachmentIndicator($event)"
  (onSwitchMaliciousAttachmentIndicator)="switchMaliciousAttachmentIndicator($event)"
  (onVerifyApiAndRegion)="verifyApiAndRegion($event)"
  (onSwitchRemediation)="switchRemediation($event)"
  (onSwitchAcceptSharingInfo)="switchAcceptSharingInfo($event)"
  (onCreateWildfireIntegration)="createWildfireIntegration()"
  (onUpdateWildfireIntegration)="updateWildfireIntegration()"
  (onVerifyAndAddEmail)="verifyAndAddEmail($event)"
  (onDeleteEmail)="deleteEmail($event)"
  (onAddGroup)="addGroup($event)"
  (onDeleteGroup)="deleteGroup($event)"
  (onCancel)="cancel()"
  (onRegionChange)="changeRegion($event)"
>
</mc-api-integration-wildfire-wizard>