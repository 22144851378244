<div class="mc-api-integration-step-block">
  <mc-wizard-step-header [label]="'$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.INDICATORS.HEADER'">
  </mc-wizard-step-header>

  <div class="mc-detailed-list mc-api-integration-wildfire-indicators-block">
    <form novalidate [formGroup]="form">
      <mc-enable-switch
        [label]="'$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.INDICATORS.SAFE_ATTACHMENTS'"
        formControlName="attachmentEnabled"
        contentDivClass="row mc-detailed-list-row panel-padding-top"
        labelClass="col-xs-4 mc-detailed-list-label"
        switchDivClass="mc-detailed-list-column"
      >
      </mc-enable-switch>
      <mc-enable-switch
        *mcCapabilities="'Temporary.Services.ApiApplications.Wildfire.Integration.SubmitMaliciousAttachment'"
        [label]="'$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.INDICATORS.MALICIOUS_ATTACHMENTS'"
        formControlName="maliciousAttachmentEnabled"
        contentDivClass="row mc-detailed-list-row panel-padding-top"
        labelClass="col-xs-4 mc-detailed-list-label"
        switchDivClass="mc-detailed-list-column"
      >
      </mc-enable-switch>
    </form>
  </div>
</div>
