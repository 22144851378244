"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgEndpointsListSidebarClose = exports.SwgEndpointsListSidebarOpen = exports.SwgEndpointsListSidebarActionsEnum = void 0;
var SwgEndpointsListSidebarActionsEnum;
(function (SwgEndpointsListSidebarActionsEnum) {
    SwgEndpointsListSidebarActionsEnum["SWG_ENDPOINTS_LIST_SIDEBAR_OPEN"] = "[SwgEndpoints] Swg endpoints list sidebar open";
    SwgEndpointsListSidebarActionsEnum["SWG_ENDPOINTS_LIST_SIDEBAR_CLOSE"] = "[SwgEndpoints] Swg endpoints list sidebar close";
})(SwgEndpointsListSidebarActionsEnum = exports.SwgEndpointsListSidebarActionsEnum || (exports.SwgEndpointsListSidebarActionsEnum = {}));
class SwgEndpointsListSidebarOpen {
    constructor() {
        this.type = SwgEndpointsListSidebarActionsEnum.SWG_ENDPOINTS_LIST_SIDEBAR_OPEN;
    }
}
exports.SwgEndpointsListSidebarOpen = SwgEndpointsListSidebarOpen;
class SwgEndpointsListSidebarClose {
    constructor() {
        this.type = SwgEndpointsListSidebarActionsEnum.SWG_ENDPOINTS_LIST_SIDEBAR_CLOSE;
    }
}
exports.SwgEndpointsListSidebarClose = SwgEndpointsListSidebarClose;
