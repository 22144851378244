"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewMessageDetailsModule = void 0;
const view_details_component_1 = require("./view-details.component");
const view_message_component_1 = require("./view-message-details/view-message/view-message.component");
const view_message_details_component_1 = require("./view-message-details/view-message-details/view-message-details.component");
const simple_message_detail_component_1 = require("./view-message-details/view-message-details/simple-message-detail/simple-message-detail.component");
const message_status_component_1 = require("./view-message-details/message-status/message-status.component");
const DECLARATIONS = [
    view_details_component_1.ViewDetailsComponent,
    view_message_component_1.ViewMessageComponent,
    view_message_details_component_1.ViewMessageDetailsComponent,
    simple_message_detail_component_1.SimpleMessageDetailComponent,
    message_status_component_1.MessageStatusComponent
];
class ViewMessageDetailsModule {
}
exports.ViewMessageDetailsModule = ViewMessageDetailsModule;
