"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BackupWizardComponent = void 0;
const rxjs_1 = require("rxjs");
const _ = require("lodash");
const actions_1 = require("../../actions");
const reducers_1 = require("../../reducers");
const selectors_1 = require("../../selectors");
const wizard_base_component_1 = require("../wizard-base.component");
const backup_model_1 = require("./backup.model");
const wizardActions = require("../../actions/wizard.actions");
const operators_1 = require("rxjs/operators");
class BackupWizardComponent extends wizard_base_component_1.WizardBaseDirective {
    constructor(translateService, store, stateService) {
        super(backup_model_1.wizardID, store, translateService);
        this.translateService = translateService;
        this.store = store;
        this.stateService = stateService;
        this.backup = this.stateService.$state.params.backup;
        this.returnActions = this.stateService.$state.params.returnActions;
        this.destroy$ = new rxjs_1.Subject();
        this.hasEditModeChanges = false;
    }
    ngOnInit() {
        super.ngOnInit();
        this.isLoadingConnectors$ = this.store.select(reducers_1.isLoadingConnectors);
        this.isCreatingBackup$ = this.store.select(selectors_1.isCreatingBackup);
        this.isUpdatingBackup$ = this.store.select(selectors_1.isUpdatingBackup);
        this.isLoadingBackup$ = this.store.select(selectors_1.isLoadingBackup);
        this.store.dispatch(new actions_1.LoadOneDriveConnectorsAction());
        this.isEditMode = this.backup !== undefined && this.backup !== null;
        this.name = this.isEditMode && this.backup.name ? this.backup.name : '';
        this.group = this.isEditMode && this.backup.backupGroup ? this.backup.backupGroup : null;
        this.connector = this.isEditMode && this.backup.connector ? this.backup.connector : null;
        this.excludedExtensions =
            this.isEditMode && this.backup.excludedExtensions ? this.backup.excludedExtensions : [];
        if (this.isEditMode) {
            this.wizardData$.pipe(operators_1.takeUntil(this.destroy$)).subscribe(wizardData => {
                this.hasEditModeChanges = this.hasStepDataChangedFromBackup(wizardData);
            });
        }
    }
    get wizardTitle() {
        const editOrCreate = this.isEditMode ? 'EDIT' : 'CREATE';
        return this.translateService.instant(`$I18N_ONEDRIVE_BACKUP_WIZARD.${editOrCreate}_TITLE`);
    }
    ngOnDestroy() {
        super.ngOnDestroy();
        this.destroy$.next(true);
        this.destroy$.complete();
    }
    hasStepDataChangedFromBackup(wizardData) {
        let hasStep1Changed = false;
        let hasStep2Changed = false;
        if (!!wizardData && !!wizardData.steps) {
            const steps = wizardData.steps;
            if (steps[1] && steps[1].data) {
                const step1Data = steps[1].data;
                const nameChange = step1Data.name ? step1Data.name !== this.name : false;
                const groupChange = step1Data.group ? step1Data.group.id !== this.group.id : false;
                const connectorChange = step1Data.connector
                    ? step1Data.connector.value !== this.connector.id
                    : false;
                hasStep1Changed = nameChange || groupChange || connectorChange;
            }
            if (steps[2] && steps[2].data) {
                const step2Data = steps[2].data;
                const extensionsChange = step2Data.fileExtensions
                    ? !_.isEqual(step2Data.fileExtensions, this.excludedExtensions.map(v => `.${v}`))
                    : false;
                hasStep2Changed = extensionsChange;
            }
        }
        return hasStep1Changed || hasStep2Changed;
    }
    getCancelConfirmationText() {
        if (this.isEditMode) {
            return this.translateService.instant('$I18N_ONEDRIVE_BACKUP_WIZARD.CANCEL_CONFIRMATION.CONFIRMATION_EDIT_TEXT');
        }
        return this.translateService.instant('$I18N_ONEDRIVE_BACKUP_WIZARD.CANCEL_CONFIRMATION.CONFIRMATION_CREATE_TEXT');
    }
    getSaveButtonText() {
        if (this.isEditMode) {
            return this.translateService.instant('$I18N_ONEDRIVE_BACKUP_WIZARD.CONTROLS.SAVE_AND_CLOSE');
        }
        return this.translateService.instant('$I18N_ONEDRIVE_BACKUP_WIZARD.CONTROLS.CREATE_BACKUP');
    }
    onSave() {
        if (this.isEditMode) {
            this.store.dispatch(new actions_1.UpdateBackupAction(this.backup.id, this.getBackupRequest(), this.returnActions));
        }
        else {
            this.store.dispatch(new actions_1.CreateBackupAction(this.getBackupRequest()));
        }
    }
    onCancel() {
        this.store.dispatch(new wizardActions.CancelAction({
            title: this.getCancelConfirmationText(),
            wizardId: this.wizardId,
            returnActions: this.returnActions
        }));
    }
    getBackupRequest() {
        const stepData = this.wizardData.steps;
        const stepOneData = stepData[1].data;
        const stepTwoData = stepData[2].data;
        return {
            connectorId: stepOneData.connector.value,
            name: stepOneData.name,
            groupId: stepOneData.group.id,
            excludedExtensions: stepTwoData.fileExtensions.map((ext) => {
                return ext.startsWith('.') ? ext.substr(1) : ext;
            })
        };
    }
}
exports.BackupWizardComponent = BackupWizardComponent;
