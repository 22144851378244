"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecallNoAction = exports.RecallYesAction = exports.RecallFailAction = exports.RecallSuccessAction = exports.RecallAction = exports.RECALL_NO = exports.RECALL_YES = exports.RECALL_FAIL = exports.RECALL_SUCCESS = exports.RECALL = void 0;
exports.RECALL = '[Services / secure messaging] recall';
exports.RECALL_SUCCESS = '[Services / secure messaging] recall success';
exports.RECALL_FAIL = '[Services / secure messaging] recall fail';
exports.RECALL_YES = '[Services / secure messaging] recall yes';
exports.RECALL_NO = '[Services / secure messaging] recall no';
class RecallAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.RECALL;
    }
}
exports.RecallAction = RecallAction;
class RecallSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.RECALL_SUCCESS;
    }
}
exports.RecallSuccessAction = RecallSuccessAction;
class RecallFailAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.RECALL_FAIL;
    }
}
exports.RecallFailAction = RecallFailAction;
class RecallYesAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.RECALL_YES;
    }
}
exports.RecallYesAction = RecallYesAction;
class RecallNoAction {
    constructor() {
        this.type = exports.RECALL_NO;
    }
}
exports.RecallNoAction = RecallNoAction;
