<mc-layout-aside-extra-container
  keyTitle="{{ '$I18N_SECURE_MESSAGING_VIEW_DETAILS.TITLE' | translate }}"
  showClose="true"
  [isLoading]="false"
  (closeAside)="close()"
>
  <mc-extra-container>
    <div class="grey-background" *ngIf="messageDetails">
      <h3>
        {{'$I18N_SECURE_MESSAGING_VIEW_DETAILS.DETAILS.SUBJECT' | translate}}: {{messageDetails.subject}}
      </h3>
        <mc-paginator
        [isLoading]="loading"
        [start]="paginationInfo?.currentEntityIndex + 1"
        [end]="paginationInfo?.totalNumberEntities"
        [previousToken]="paginationInfo?.previousEntity"
        [nextToken]="paginationInfo?.nextEntity"
        (paginate)="loadMessageDetails($event)"
      ></mc-paginator>
    </div>
    <div class="clearfix"></div>
    <div>
      <button
        class="mc-table-actions btn btn-primary"
        (click)="onRecall.emit(messageDetails.id)"
        *mcCapabilities="'Permission.SERVICE_SECURE_MESSAGING_EDIT'"
        [disabled]="!messageDetails || messageDetails.ccmRecipientsStatus[0]?.recalled"
        >
        {{ '$SECURE_MESSAGING_LOGS.TABLE.RECALL' | translate }}
      </button>
    </div>
  </mc-extra-container>
  <mc-body-container>
    <mc-loader-full-container
      [isLoading]="loading"
      class="mc-layout-loader-full-container-aside mc-flex-scroll-vertical"
    >
      <div *ngIf="loading" style="height: 100px;"></div>
    </mc-loader-full-container>    

    <mc-tab-group #tabGroup *ngIf="!loading">
      <mc-tab [name]="'$I18N_SECURE_MESSAGING_VIEW_DETAILS.TABS.MESSAGE' | translate">
        <mc-tab-container>
        <div *ngIf="!loading && messageDetails">
          <mc-view-message-details [messageDetails]="messageDetails"></mc-view-message-details>
        </div>    
        </mc-tab-container>
      </mc-tab>
      <mc-tab [name]="'$I18N_SECURE_MESSAGING_VIEW_DETAILS.TABS.STATUS' | translate">
        <mc-tab-container>
          <div *ngIf="!loading && messageDetails">
            <mc-message-status [messageDetails]="messageDetails"></mc-message-status>
          </div>
        </mc-tab-container>
      </mc-tab>
    </mc-tab-group>    
  </mc-body-container>
</mc-layout-aside-extra-container>
