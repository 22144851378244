"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SecureMessagingService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const logs_factory_1 = require("../factory/logs.factory");
class SecureMessagingService {
    constructor(http) {
        this.http = http;
    }
    getData(payload) {
        return this.http
            .post('/api/securemessaging/list-secure-messages', Object.assign({}, payload))
            .pipe(operators_1.map((response) => {
            let RETURN_DATA;
            if (response.hasErrors) {
                RETURN_DATA = {
                    hasErrors: response.hasErrors,
                    data: response.all,
                    failures: response.fail,
                    meta: Object.assign({}, response.meta)
                };
            }
            else {
                RETURN_DATA = {
                    hasErrors: response.hasErrors,
                    data: response.first.results.map((logs) => logs_factory_1.LogsFactory.BUILD_LOG(logs)),
                    failures: response.fail,
                    meta: Object.assign({}, response.meta)
                };
            }
            return RETURN_DATA;
        }), operators_1.catchError((response) => {
            const RETURN_DATA = {
                hasErrors: response.hasErrors,
                data: response.all,
                failures: response.fail,
                meta: Object.assign({}, response.meta)
            };
            return rxjs_1.of(RETURN_DATA);
        }));
    }
    filter(filter) {
        return this.getData(filter);
    }
    recall(request) {
        return this.http.post('/api/securemessaging/recall', request).pipe(operators_1.map((response) => {
            return response;
        }));
    }
    gerMessageDetails(id) {
        return this.http.post('/api/securemessaging/get-detail', { id }).pipe(operators_1.map((response) => {
            return response;
        }));
    }
}
exports.SecureMessagingService = SecureMessagingService;
SecureMessagingService.LOGS_TABLE = 'secure-messaging-logs-table';
