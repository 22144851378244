"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Reviewer = exports.QueueReviewerPerformance = void 0;
class QueueReviewerPerformance {
    constructor(reviewer, processedCount) {
        this.reviewer = reviewer;
        this.processedCount = processedCount;
    }
}
exports.QueueReviewerPerformance = QueueReviewerPerformance;
class Reviewer {
    constructor(displayableName, emailAddress) {
        this.displayableName = displayableName;
        this.emailAddress = emailAddress;
    }
}
exports.Reviewer = Reviewer;
