"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SummaryDetailResultsKeysFailAction = exports.SummaryDetailResultsKeysLoadSuccessAction = exports.SummaryDetailResultsKeysLoadAction = exports.SummaryByReasonFailAction = exports.SummaryByReasonSuccessAction = exports.SummaryByReasonRequestAction = exports.SUMMARY_DETAIL_RESULTS_KEYS_FAIL = exports.SUMMARY_DETAIL_RESULTS_KEYS_SUCCESS = exports.SUMMARY_DETAIL_RESULTS_KEYS_LOAD = exports.SUMMARY_BY_REASON_FAIL = exports.SUMMARY_BY_REASON_SUCCESS = exports.SUMMARY_BY_REASON_REQUEST = void 0;
exports.SUMMARY_BY_REASON_REQUEST = '[Held Messages / Overview / Messages Held by Reason] Request Get Messages By Reason';
exports.SUMMARY_BY_REASON_SUCCESS = '[Held Messages / Overview / Messages Held by Reason] Request Get Messages By Reason Success';
exports.SUMMARY_BY_REASON_FAIL = '[Held Messages / Overview / Messages Held by Reason] Request Get Messages By Reason Fail';
exports.SUMMARY_DETAIL_RESULTS_KEYS_LOAD = '[Summary Detail Results Keys] Load';
exports.SUMMARY_DETAIL_RESULTS_KEYS_SUCCESS = '[Summary Detail Results Keys] Load Success';
exports.SUMMARY_DETAIL_RESULTS_KEYS_FAIL = '[Summary Detail Results Keys] Load Fail';
class SummaryByReasonRequestAction {
    constructor() {
        this.type = exports.SUMMARY_BY_REASON_REQUEST;
    }
}
exports.SummaryByReasonRequestAction = SummaryByReasonRequestAction;
class SummaryByReasonSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SUMMARY_BY_REASON_SUCCESS;
    }
}
exports.SummaryByReasonSuccessAction = SummaryByReasonSuccessAction;
class SummaryByReasonFailAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SUMMARY_BY_REASON_FAIL;
    }
}
exports.SummaryByReasonFailAction = SummaryByReasonFailAction;
class SummaryDetailResultsKeysLoadAction {
    constructor() {
        this.type = exports.SUMMARY_DETAIL_RESULTS_KEYS_LOAD;
    }
}
exports.SummaryDetailResultsKeysLoadAction = SummaryDetailResultsKeysLoadAction;
class SummaryDetailResultsKeysLoadSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SUMMARY_DETAIL_RESULTS_KEYS_SUCCESS;
    }
}
exports.SummaryDetailResultsKeysLoadSuccessAction = SummaryDetailResultsKeysLoadSuccessAction;
class SummaryDetailResultsKeysFailAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SUMMARY_DETAIL_RESULTS_KEYS_FAIL;
    }
}
exports.SummaryDetailResultsKeysFailAction = SummaryDetailResultsKeysFailAction;
