"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgCategoryNameTranslatePipe = void 0;
class SwgCategoryNameTranslatePipe {
    constructor(translate) {
        this.translate = translate;
    }
    transform(value) {
        return !!value
            ? value
                .map(val => val)
                .filter(val => !!val)
                .map(val => this.translate.instant('$I18N_SWG_HUMANREADBALE_CATEGORY.' + val))
                .join(', ')
            : '';
    }
}
exports.SwgCategoryNameTranslatePipe = SwgCategoryNameTranslatePipe;
