"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiemLoadChannelToEdit = exports.SiemCreateChannelFailure = exports.SiemCreateChannelSuccess = exports.SiemCreateChannel = exports.SiemUpdateChannelProductEvents = exports.SiemLoadMetadataProductsByChannelTypeSuccess = exports.SiemLoadMetadataProductsByChannelType = exports.SiemUpdateChannelProducts = exports.SiemChannelNavigateToWizardAction = exports.SiemClearChannel = exports.SiemUpdateChannelDescription = exports.SiemLoadProductsFromMetadataFailure = exports.SiemLoadProductsFromMetadataSuccess = exports.SiemLoadProductsFromMetadata = exports.SiemLoadChannelDetailsFailure = exports.SiemLoadChannelDetailsSuccess = exports.SiemLoadChannelDetails = exports.SiemChannelActionsEnums = void 0;
var SiemChannelActionsEnums;
(function (SiemChannelActionsEnums) {
    SiemChannelActionsEnums["SIEM_LOAD_CHANNEL_DETAILS"] = "[SIEM Channel] SIEM Load Channel Details";
    SiemChannelActionsEnums["SIEM_LOAD_CHANNEL_DETAILS_SUCCESS"] = "[SIEM Channel] SIEM Load Channel DetailsSuccess";
    SiemChannelActionsEnums["SIEM_LOAD_CHANNEL_DETAILS_FAILURE"] = "[SIEM Channel] SIEM Load Channel Details Failure";
    SiemChannelActionsEnums["SIEM_LOAD_PRODUCTS_FROM_METADATA"] = "[SIEM Channel] SIEM Load Products from Metadata";
    SiemChannelActionsEnums["SIEM_LOAD_PRODUCTS_FROM_METADATA_SUCCESS"] = "[SIEM Channel] SIEM Load Products from Metadata Success";
    SiemChannelActionsEnums["SIEM_LOAD_PRODUCTS_FROM_METADATA_FAILURE"] = "[SIEM Channel] SIEM Load Products from Metadata Failure";
    SiemChannelActionsEnums["SIEM_UPDATE_CHANNEL_DESCRIPTION"] = "[SIEM Channel] SIEM Update Channel Description";
    SiemChannelActionsEnums["SIEM_CLEAR_CHANNEL"] = "[SIEM Channel] SIEM Clear Channel";
    SiemChannelActionsEnums["SIEM_CHANNEL_NAVIGATE_TO_WIZARD"] = "[SIEM Channel] SIEM Channel Navigate to Wizard";
    SiemChannelActionsEnums["SIEM_UPDATE_CHANNEL_PRODUCTS"] = "[SIEM Channel] SIEM Update Channel Products";
    SiemChannelActionsEnums["SIEM_LOAD_METADATA_PRODUCTS_BY_CHANNELTYPE"] = "[SIEM Channel] SIEM Load Metadata Products By Channel Type";
    SiemChannelActionsEnums["SIEM_LOAD_METADATA_PRODUCTS_BY_CHANNELTYPE_SUCCESS"] = "[SIEM Channel] SIEM Load Metadata Products By Channel Type Success";
    SiemChannelActionsEnums["SIEM_UPDATE_CHANNEL_PRODUCT_EVENTS"] = "[SIEM Channel] SIEM Update Channel Product Events";
    SiemChannelActionsEnums["SIEM_CREATE_CHANNEL"] = "[SIEM Channel] SIEM Create Channel";
    SiemChannelActionsEnums["SIEM_CREATE_CHANNEL_SUCCESS"] = "[SIEM Channel] SIEM Create Channel Success";
    SiemChannelActionsEnums["SIEM_CREATE_CHANNEL_FAILURE"] = "[SIEM Channel] SIEM Create Channel Failure";
    SiemChannelActionsEnums["SIEM_LOAD_CHANNEL_TO_EDIT"] = "[SIEM Channel] SIEM Load Channel To Edit";
})(SiemChannelActionsEnums = exports.SiemChannelActionsEnums || (exports.SiemChannelActionsEnums = {}));
class SiemLoadChannelDetails {
    constructor(payload) {
        this.payload = payload;
        this.type = SiemChannelActionsEnums.SIEM_LOAD_CHANNEL_DETAILS;
    }
}
exports.SiemLoadChannelDetails = SiemLoadChannelDetails;
class SiemLoadChannelDetailsSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = SiemChannelActionsEnums.SIEM_LOAD_CHANNEL_DETAILS_SUCCESS;
    }
}
exports.SiemLoadChannelDetailsSuccess = SiemLoadChannelDetailsSuccess;
class SiemLoadChannelDetailsFailure {
    constructor() {
        this.type = SiemChannelActionsEnums.SIEM_LOAD_CHANNEL_DETAILS_FAILURE;
    }
}
exports.SiemLoadChannelDetailsFailure = SiemLoadChannelDetailsFailure;
class SiemLoadProductsFromMetadata {
    constructor() {
        this.type = SiemChannelActionsEnums.SIEM_LOAD_PRODUCTS_FROM_METADATA;
    }
}
exports.SiemLoadProductsFromMetadata = SiemLoadProductsFromMetadata;
class SiemLoadProductsFromMetadataSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = SiemChannelActionsEnums.SIEM_LOAD_PRODUCTS_FROM_METADATA_SUCCESS;
    }
}
exports.SiemLoadProductsFromMetadataSuccess = SiemLoadProductsFromMetadataSuccess;
class SiemLoadProductsFromMetadataFailure {
    constructor() {
        this.type = SiemChannelActionsEnums.SIEM_LOAD_PRODUCTS_FROM_METADATA_FAILURE;
    }
}
exports.SiemLoadProductsFromMetadataFailure = SiemLoadProductsFromMetadataFailure;
class SiemUpdateChannelDescription {
    constructor(payload) {
        this.payload = payload;
        this.type = SiemChannelActionsEnums.SIEM_UPDATE_CHANNEL_DESCRIPTION;
    }
}
exports.SiemUpdateChannelDescription = SiemUpdateChannelDescription;
class SiemClearChannel {
    constructor() {
        this.type = SiemChannelActionsEnums.SIEM_CLEAR_CHANNEL;
    }
}
exports.SiemClearChannel = SiemClearChannel;
class SiemChannelNavigateToWizardAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SiemChannelActionsEnums.SIEM_CHANNEL_NAVIGATE_TO_WIZARD;
    }
}
exports.SiemChannelNavigateToWizardAction = SiemChannelNavigateToWizardAction;
class SiemUpdateChannelProducts {
    constructor(payload) {
        this.payload = payload;
        this.type = SiemChannelActionsEnums.SIEM_UPDATE_CHANNEL_PRODUCTS;
    }
}
exports.SiemUpdateChannelProducts = SiemUpdateChannelProducts;
class SiemLoadMetadataProductsByChannelType {
    constructor() {
        this.type = SiemChannelActionsEnums.SIEM_LOAD_METADATA_PRODUCTS_BY_CHANNELTYPE;
    }
}
exports.SiemLoadMetadataProductsByChannelType = SiemLoadMetadataProductsByChannelType;
class SiemLoadMetadataProductsByChannelTypeSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = SiemChannelActionsEnums.SIEM_LOAD_METADATA_PRODUCTS_BY_CHANNELTYPE_SUCCESS;
    }
}
exports.SiemLoadMetadataProductsByChannelTypeSuccess = SiemLoadMetadataProductsByChannelTypeSuccess;
class SiemUpdateChannelProductEvents {
    constructor(payload) {
        this.payload = payload;
        this.type = SiemChannelActionsEnums.SIEM_UPDATE_CHANNEL_PRODUCT_EVENTS;
    }
}
exports.SiemUpdateChannelProductEvents = SiemUpdateChannelProductEvents;
class SiemCreateChannel {
    constructor() {
        this.type = SiemChannelActionsEnums.SIEM_CREATE_CHANNEL;
    }
}
exports.SiemCreateChannel = SiemCreateChannel;
class SiemCreateChannelSuccess {
    constructor() {
        this.type = SiemChannelActionsEnums.SIEM_CREATE_CHANNEL_SUCCESS;
    }
}
exports.SiemCreateChannelSuccess = SiemCreateChannelSuccess;
class SiemCreateChannelFailure {
    constructor() {
        this.type = SiemChannelActionsEnums.SIEM_CREATE_CHANNEL_FAILURE;
    }
}
exports.SiemCreateChannelFailure = SiemCreateChannelFailure;
class SiemLoadChannelToEdit {
    constructor(payload) {
        this.payload = payload;
        this.type = SiemChannelActionsEnums.SIEM_LOAD_CHANNEL_TO_EDIT;
    }
}
exports.SiemLoadChannelToEdit = SiemLoadChannelToEdit;
