"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OnedriveListEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const list = require("../actions/list.actions");
class OnedriveListEffects {
    constructor(listService, actions$) {
        this.listService = listService;
        this.actions$ = actions$;
        this.getUsage$ = this.actions$.pipe(effects_1.ofType(list.LOAD), operators_1.switchMap(() => this.listService.getDriveUsage().pipe(operators_1.map((response) => {
            if (response.hasErrors) {
                return new list.LoadFailAction(response.fail);
            }
            const USAGE = response.first;
            return new list.LoadSuccessAction(USAGE);
        }), operators_1.catchError((response) => {
            return rxjs_1.of(new list.LoadFailAction(response.fail));
        }))));
    }
}
__decorate([
    effects_1.Effect()
], OnedriveListEffects.prototype, "getUsage$", void 0);
exports.OnedriveListEffects = OnedriveListEffects;
