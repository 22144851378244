"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TranslateSourceService = void 0;
class TranslateSourceService {
    constructor(translate) {
        this.translate = translate;
    }
    getLanguageData(lang) {
        return this.translate['retrieveTranslations'](lang).toPromise();
    }
}
exports.TranslateSourceService = TranslateSourceService;
