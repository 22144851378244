"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IdentityTabChangeService = void 0;
const rxjs_1 = require("rxjs");
class IdentityTabChangeService {
    createNewTabsChangeSubject() {
        this.$tabsChanged = new rxjs_1.BehaviorSubject(null);
        this.tabsChanged = this.$tabsChanged.asObservable();
    }
    createNewBlockTabsSubject() {
        this.$blockTabClicks = new rxjs_1.BehaviorSubject(null);
        this.blockTabClicks = this.$blockTabClicks.asObservable();
    }
    setTabs(tabs) {
        this.tabs = tabs;
        this.currentTabIndex = this.tabs.activeTabIndex;
    }
    setActiveTab(index) {
        this.tabs.tabClicked(index);
    }
    setTabHistory(tabIndex) {
        this.previousTabIndex = this.currentTabIndex;
        this.currentTabIndex = tabIndex;
        const tabs = {
            previous: this.previousTabIndex,
            current: this.currentTabIndex
        };
        if (this.$tabsChanged) {
            this.$tabsChanged.next(tabs);
        }
    }
    swapTabIndexes() {
        const tmp = this.previousTabIndex;
        this.previousTabIndex = this.currentTabIndex;
        this.currentTabIndex = tmp;
        return {
            previous: this.previousTabIndex,
            current: this.currentTabIndex
        };
    }
    setBlockTabs(isFormDirty = false) {
        this.$blockTabClicks.next({ isFormDirty });
    }
}
exports.IdentityTabChangeService = IdentityTabChangeService;
