"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.blockMimecastDomain = void 0;
const MIMECAST_REGEX = /^.*(mimecast\.com)(:[0-9]{1,5})?([\/\?].*)?$/;
// @ts-ignore
const isMimecastDomain = (domain) => MIMECAST_REGEX.test(_.trim(domain));
const ɵ0 = isMimecastDomain;
exports.ɵ0 = ɵ0;
const blockMimecastDomain = (control) => {
    if (control.value.length === 0 || !isMimecastDomain(control.value)) {
        return true;
    }
    else {
        return { MIMECAST_DOMAIN_ADD_INVALID: { value: control.value } };
    }
};
exports.blockMimecastDomain = blockMimecastDomain;
