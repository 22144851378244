"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UrlProtectionPolicySidePanelComponentHelper = void 0;
const components_1 = require("@mimecast-ui/components");
const policy_wizard_models_1 = require("../../models/policy-wizard.models");
const url_protection_inbound_settings_step_component_static_values_1 = require("../steps/url-protection-inbound-settings-step/url-protection-inbound-settings-step.component.static-values");
const url_protection_inbound_settings_step_models_1 = require("../steps/url-protection-inbound-settings-step/url-protection-inbound-settings-step.models");
const url_protection_outbound_settings_step_component_static_values_1 = require("../steps/url-protection-outbound-settings-step/url-protection-outbound-settings-step.component.static-values");
const url_protection_outbound_settings_step_models_1 = require("../steps/url-protection-outbound-settings-step/url-protection-outbound-settings-step.models");
const url_protection_journal_settings_step_component_static_values_1 = require("../steps/url-protection-journal-settings-step/url-protection-journal-settings-step.component.static-values");
const url_protection_journal_settings_step_models_1 = require("../steps/url-protection-journal-settings-step/url-protection-journal-settings-step.models");
const url_protection_applies_to_settings_step_models_1 = require("../steps/url-protection-applies-to-settings-step/url-protection-applies-to-settings-step.models");
const url_protection_applies_to_settings_step_component_static_values_1 = require("../steps/url-protection-applies-to-settings-step/url-protection-applies-to-settings-step.component.static-values");
const url_protection_policy_details_step_static_values_1 = require("../steps/url-protection-policy-details-step/url-protection-policy-details-step.static-values");
class UrlProtectionPolicySidePanelComponentHelper {
    constructor(translateService, datePipeService) {
        this.translateService = translateService;
        this.datePipeService = datePipeService;
    }
    // Details step
    getBypassApplicationLabel(policyDetails) {
        const key = Object.keys(policy_wizard_models_1.BypassApplicationOptions).find(enumKey => {
            return policy_wizard_models_1.BypassApplicationOptions[enumKey] === policyDetails.policy.policyOptions;
        });
        return this.translate(url_protection_policy_details_step_static_values_1.bypassApplicationOptions.find(option => option.id === policy_wizard_models_1.BypassApplicationOptions[key]).label);
    }
    isDateRange(policyDetails) {
        return (policyDetails.policy.hasOwnProperty('fromDate') ||
            policyDetails.policy.hasOwnProperty('toDate'));
    }
    handleToDateTransformation(date) {
        if (date) {
            const filterPipe = new components_1.DatePlusWeekdayPipe(this.translateService, this.datePipeService);
            return filterPipe.transform(date);
        }
        return this.translateService.instant('$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.DURATION_VALUES.CONTINUOUS');
    }
    // Inbound
    checkForRewriteURLsFlag(policyDetails) {
        const rewriteFields = [
            policyDetails.attachmentRewriteText,
            policyDetails.attachmentRewriteHtml,
            policyDetails.attachmentRewriteCalendar
        ];
        return rewriteFields.some((field) => field);
    }
    checkForSimilarURLsFlag(policyDetails) {
        const similarURLsFields = [
            policyDetails.checkAgainstInternalDomains,
            policyDetails.checkAgainstMimecastExternalDomains,
            policyDetails.checkAgainstCustomExternalDomains
        ];
        return similarURLsFields.some((field) => field);
    }
    getNotificationUrlFormatLabel(policyDetails) {
        return this.getSelectItemLabelForEnum(policyDetails, url_protection_inbound_settings_step_models_1.NotificationUrlFormatOptions, url_protection_inbound_settings_step_component_static_values_1.notificationUrlFormatValues, 'urlFormatNotification');
    }
    getInboundRewriteModeLabel(policyDetails) {
        return this.getSelectItemLabelForEnum(policyDetails, url_protection_inbound_settings_step_models_1.RewriteModeOptions, url_protection_inbound_settings_step_component_static_values_1.rewriteModeValues, 'rewriteMode');
    }
    getInboundMessageSubjectProtectionLabel(policyDetails) {
        return this.getSelectItemLabelForEnum(policyDetails, url_protection_inbound_settings_step_models_1.MessageSubjectProtectionOptions, url_protection_inbound_settings_step_component_static_values_1.messageSubjectProtectionValues, 'subjectProtection');
    }
    getInboundUrlCategoryScanningLabel(policyDetails) {
        return this.getSelectItemLabelForEnum(policyDetails, url_protection_inbound_settings_step_models_1.UrlCategoryScanningOptions, url_protection_inbound_settings_step_component_static_values_1.urlCategoryScanningValues, 'categoryScanMode');
    }
    getInboundMaliciousDetectActionLabel(policyDetails) {
        return this.getSelectItemLabelForEnum(policyDetails, url_protection_inbound_settings_step_models_1.MaliciousUrlDetectionActionOptions, url_protection_inbound_settings_step_component_static_values_1.maliciousUrlDetectionActionValues, 'action');
    }
    getInboundFileProtocolUrlHandelingLabel(policyDetails) {
        return this.getSelectItemLabelForEnum(policyDetails, url_protection_inbound_settings_step_models_1.FileProtocolUrlHandlingOptions, url_protection_inbound_settings_step_component_static_values_1.fileProtocolUrlHandlingValues, 'fileHandlingAction');
    }
    getUrlFileDownloadLabel(policyDetails) {
        return this.getSelectItemLabelForEnum(policyDetails, url_protection_inbound_settings_step_models_1.UrlFileDownloadOptions, url_protection_inbound_settings_step_component_static_values_1.urlFileDownloadValues, 'fileDownloadAction');
    }
    getSimilarUrlDetection(policyDetails) {
        return this.getSelectItemLabelForEnum(policyDetails, url_protection_inbound_settings_step_models_1.SimilarUrlDetectionOptions, url_protection_inbound_settings_step_component_static_values_1.similarUrlDetectionValues, 'urlDetectionAction');
    }
    // Outbound step
    checkForScanURLsFlag(policyDetails) {
        const scanFields = [
            policyDetails.outboundAttachmentRewriteText,
            policyDetails.outboundAttachmentRewriteHtml,
            policyDetails.outboundAttachmentRewriteCalendar,
            policyDetails.outboundScanLinkAttachments
        ];
        return scanFields.some((field) => field);
    }
    checkForOutboundSimilarURLsFlag(policyDetails) {
        const similarURLsFields = [
            policyDetails.outboundCheckAgainstInternalDomains,
            policyDetails.outboundCheckAgainstMimecastExternalDomains,
            policyDetails.outboundCheckAgainstCustomExternalDomains
        ];
        return similarURLsFields.some((field) => field);
    }
    getOutboundUrlScanningModeLabel(policyDetails) {
        return this.getSelectItemLabelForEnum(policyDetails, url_protection_outbound_settings_step_models_1.UrlScanningModeOptions, url_protection_outbound_settings_step_component_static_values_1.urlScanningModeValues, 'outboundScanMode');
    }
    getOutboundUrlFileDownloadLabel(value) {
        return value
            ? this.translate('$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.URL_FILE_DOWNLOAD.VALUES.CHECK')
            : this.translate('$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.URL_FILE_DOWNLOAD.VALUES.NO_ACTION');
    }
    getUrlCategoryScanningModeLabel(policyDetails) {
        return this.getSelectItemLabelForEnum(policyDetails, url_protection_outbound_settings_step_models_1.UrlCategoryScanningModeOptions, url_protection_outbound_settings_step_component_static_values_1.urlCategoryScanningModeValues, 'outboundCategoryScanMode');
    }
    getGatewayActionLabel(policyDetails) {
        return this.getSelectItemLabelForEnum(policyDetails, url_protection_outbound_settings_step_models_1.GatewayActionOptions, url_protection_outbound_settings_step_component_static_values_1.gatewayActionValues, 'outboundGatewayAction');
    }
    getGatewayFallbackActionLabel(policyDetails) {
        return this.getSelectItemLabelForEnum(policyDetails, url_protection_outbound_settings_step_models_1.GatewayFallbackActionOptions, url_protection_outbound_settings_step_component_static_values_1.gatewayFallbackActionValues, 'outboundGatewayFallbackAction');
    }
    getSenderMailboxActionLabel(policyDetails) {
        return this.getSelectItemLabelForEnum(policyDetails, url_protection_outbound_settings_step_models_1.SenderMailboxActionOptions, url_protection_outbound_settings_step_component_static_values_1.senderMailboxActionValues, 'outboundMailboxAction');
    }
    getSenderMailboxFallbackActionLabel(policyDetails) {
        return this.getSelectItemLabelForEnum(policyDetails, url_protection_outbound_settings_step_models_1.SenderMailboxFallbackActionOptions, url_protection_outbound_settings_step_component_static_values_1.senderMailboxFallbackActionValues, 'outboundMailFallbackAction');
    }
    // Journal step
    checkForJournalScanURLsFlag(policyDetails) {
        const scanFields = [
            policyDetails.journalAttachmentRewriteText,
            policyDetails.journalAttachmentRewriteHtml,
            policyDetails.journalAttachmentRewriteCalendar,
            policyDetails.journalScanLinkAttachments
        ];
        return scanFields.some((field) => field);
    }
    checkForJournalSimilarURLsFlag(policyDetails) {
        const similarURLsFields = [
            policyDetails.journalCheckAgainstInternalDomains,
            policyDetails.journalCheckAgainstMimecastExternalDomains,
            policyDetails.journalCheckAgainstCustomExternalDomains
        ];
        return similarURLsFields.some((field) => field);
    }
    getJournalUrlScanningModeLabel(policyDetails) {
        return this.getSelectItemLabelForEnum(policyDetails, url_protection_journal_settings_step_models_1.UrlScanningModeOptions, url_protection_journal_settings_step_component_static_values_1.urlScanningModeValues, 'journalScanMode');
    }
    getJournalUrlCategoryScanningModeLabel(policyDetails) {
        return this.getSelectItemLabelForEnum(policyDetails, url_protection_journal_settings_step_models_1.UrlCategoryScanningModeOptions, url_protection_journal_settings_step_component_static_values_1.urlCategoryScanningModeValues, 'journalCategoryScanMode');
    }
    getJournalSenderMailboxActionLabel(policyDetails) {
        return this.getSelectItemLabelForEnum(policyDetails, url_protection_journal_settings_step_models_1.SenderMailboxActionOptions, url_protection_journal_settings_step_component_static_values_1.senderMailboxActionValues, 'journalMailboxAction');
    }
    getJournalSenderMailboxFallbackActionLabel(policyDetails) {
        return this.getSelectItemLabelForEnum(policyDetails, url_protection_journal_settings_step_models_1.SenderMailboxFallbackActionOptions, url_protection_journal_settings_step_component_static_values_1.senderMailboxFallbackActionValues, 'journalMailFallbackAction');
    }
    getJournalUrlFileDownloadLabel(value) {
        return value
            ? this.translate('$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.URL_FILE_DOWNLOAD.VALUES.CHECK')
            : this.translate('$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.URL_FILE_DOWNLOAD.VALUES.NO_ACTION');
    }
    // Applies To
    getAddressesBasedOnLabel(rule) {
        return this.getSelectItemLabelForEnum(rule, url_protection_applies_to_settings_step_models_1.AddressesBasedOnOptions, url_protection_applies_to_settings_step_component_static_values_1.addressesBasedOnOptions, 'fromPart');
    }
    getAppliesFromToLabel(target, fieldName) {
        const label = this.getSelectItemLabelForEnum(target, url_protection_applies_to_settings_step_models_1.AppliesFromToOptions, url_protection_applies_to_settings_step_component_static_values_1.appliesFromToOptions, fieldName);
        let value = '';
        const selectedOption = target[fieldName];
        const isAddressField = selectedOption === url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.EMAIL_ADDRESS;
        const isDomainField = selectedOption === url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.DOMAIN;
        const isAttributesField = selectedOption === url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.ADDRESS_ATTRIBUTE;
        const isAddressGroupField = selectedOption === url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.ADDRESS_GROUP;
        if (isAddressField) {
            value = target.emailAddress;
        }
        else if (isDomainField) {
            value = target.emailDomain;
        }
        else if (isAttributesField) {
            value = target.attribute.name;
        }
        else if (isAddressGroupField) {
            value = target.group.description;
            value = `\n${value}`;
        }
        if (value) {
            value = `: ${value}`;
        }
        return label + value;
    }
    getSelectItemLabelForEnum(policyDetails, enumValue, selectItemOptions, field) {
        const key = Object.keys(enumValue).find(enumKey => {
            return enumValue[enumKey] === policyDetails[field];
        });
        const option = selectItemOptions.find(selectItemOption => {
            return selectItemOption.value === enumValue[key];
        });
        return option ? this.translate(option.label) : '';
    }
    translate(value) {
        return this.translateService.instant(value);
    }
}
exports.UrlProtectionPolicySidePanelComponentHelper = UrlProtectionPolicySidePanelComponentHelper;
