"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PackagesService = void 0;
const operators_1 = require("rxjs/operators");
const store_1 = require("@ngrx/store");
const reducers_1 = require("../../reducers");
class PackagesService {
    constructor(store) {
        this.store = store;
    }
    getPackages() {
        return this.store.pipe(store_1.select(reducers_1.getAccount), operators_1.filter(v => !!v), operators_1.first(), operators_1.map((account) => account.packages || []));
    }
    getPackageCodes() {
        return this.getPackages().pipe(operators_1.map((packages) => packages.map(pkge => this.getPackageCode(pkge))));
    }
    getPackageCode(pkge) {
        const open = pkge.indexOf('[');
        const close = pkge.indexOf(']', open);
        if (open > -1 && close > -1 && open < close) {
            return pkge.substring(open + 1, close);
        }
        else {
            return pkge;
        }
    }
    hasAnyPackage(pkgs) {
        return this.getPackageCodes().pipe(operators_1.map(codes => !!pkgs.find(pkge => codes.includes(pkge))));
    }
}
exports.PackagesService = PackagesService;
