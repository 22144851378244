"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportUrlModalComponent = void 0;
const core_1 = require("@angular/core");
const models_1 = require("../../logs/models");
const forms_1 = require("@angular/forms");
const reducers = require("../../reducers");
const file_parse_actions_1 = require("../../actions/file-parse.actions");
const report_submit_modal_action_1 = require("../../actions/report-submit-modal.action");
class ReportUrlModalComponent {
    constructor(fb, capabilitiesService, translateService, store, data, overlayRef) {
        this.fb = fb;
        this.capabilitiesService = capabilitiesService;
        this.translateService = translateService;
        this.store = store;
        this.overlayRef = overlayRef;
        this.allowedSizeExceeded = false;
        this.fileName = '';
        this.safeOrMalicious = models_1.SafeOrMalicious;
        this.remove = new core_1.EventEmitter();
        this.upload = new core_1.EventEmitter();
        this.data = data;
        this.selectCategoryPlaceholder = this.translateService.instant('$SCAN_DETAILS_REPORT_MODAL.SELECT_PLACEHOLDER_TEXT');
        this.capabilitiesService
            .hasAdditionalValuesCapability('Temporary.Services.URL.Reporting.Enabled')
            .subscribe(value => {
            var _a;
            if (value.length) {
                this.allowedFilesize = value[0].allowedSize;
                this.allowedFileExtension = (_a = value[0]) === null || _a === void 0 ? void 0 : _a.allowedExtension;
            }
        });
        this.form = this.fb.group({
            userComment: ['', forms_1.Validators.maxLength(250)],
            uploadedFile: [''],
            reportTo: ['', [forms_1.Validators.email]],
            category: [''],
            safeOrMalicious: ['', forms_1.Validators.required]
        });
    }
    ngOnInit() {
        var _a;
        this.form.get('reportTo').setValue((_a = this.data) === null || _a === void 0 ? void 0 : _a.userEmailAddress);
        this.isRetryEnabled$ = this.store.select(reducers.isRetryEnabled);
        this.buttonDisable$ = this.store.select(reducers.isButtonEnabled);
        this.isLoading$ = this.store.select(reducers.isLoading);
    }
    setScanResultIcon(result) {
        let iconClass;
        switch (result) {
            case 'Clean':
            case 'clean':
                iconClass = 'fa-check-circle';
                break;
            case '-':
            case '':
                iconClass = 'hidden';
                break;
            default:
                iconClass = 'fa-times-circle';
        }
        return iconClass;
    }
    setCategory(data) {
        this.selectCategoryPlaceholder = '';
        this.selectedCategory = data;
    }
    onFileSelected(event) {
        const file = event.target.files[0];
        if (file.size >= this.allowedFilesize) {
            this.allowedSizeExceeded = true;
            return;
        }
        this.allowedSizeExceeded = false;
        if (file) {
            this.fileName = file.name;
            this.store.dispatch(new file_parse_actions_1.URLPolicyParseFile(file));
        }
    }
    submitURLReport() {
        var _a;
        this.store.select(reducers.getUploadedImageBase64).subscribe(base64 => {
            this.uploadScreenshotBase64 = base64;
        });
        const RequestId = {
            id: this.data.id,
            isMalicious: this.form.value.safeOrMalicious === models_1.SafeOrMalicious.MALICIOUS
        };
        const ReportModalRequest = Object.entries({
            categoryCode: (_a = this.selectedCategory) === null || _a === void 0 ? void 0 : _a.code,
            comment: this.form.value.userComment,
            uploadScreenshot: this.uploadScreenshotBase64,
            reportTo: this.form.value.reportTo,
            scanResponses: this.data.scanResponses
        }).reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {});
        this.store.dispatch(new report_submit_modal_action_1.URLReportModalSubmit(Object.assign(Object.assign({}, RequestId), ReportModalRequest)));
    }
    onCancel() {
        this.store.dispatch(new report_submit_modal_action_1.URLReportModalSubmitButtonState());
        this.overlayRef.close();
    }
}
exports.ReportUrlModalComponent = ReportUrlModalComponent;
