"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WIDGET_URLS_SCAN_DAY_MOCK_PARSED = exports.WIDGET_URLS_SCAN_DAY_MOCK_RAW = void 0;
exports.WIDGET_URLS_SCAN_DAY_MOCK_RAW = {
    clickCountsPerDay: {
        routing: [
            {
                route: 'inbound',
                scansPerDay: [
                    {
                        scanResult: 'clean',
                        dayCounts: {
                            '2020-10-15T23:00:00+0000': 2,
                            '2020-10-14T23:00:00+0000': 7,
                            '2020-10-11T23:00:00+0000': 5,
                            '2020-09-30T23:00:00+0000': 4,
                            '2020-09-29T23:00:00+0000': 2
                        }
                    },
                    {
                        scanResult: 'malicious',
                        dayCounts: {
                            '2020-10-18T23:00:00+0000': 6,
                            '2020-10-15T23:00:00+0000': 4,
                            '2020-10-14T23:00:00+0000': 10
                        }
                    }
                ]
            },
            {
                route: 'outbound',
                scansPerDay: [
                    {
                        scanResult: 'clean',
                        dayCounts: {}
                    },
                    {
                        scanResult: 'malicious',
                        dayCounts: {}
                    }
                ]
            },
            {
                route: 'internal',
                scansPerDay: [
                    {
                        scanResult: 'clean',
                        dayCounts: {}
                    },
                    {
                        scanResult: 'malicious',
                        dayCounts: {}
                    }
                ]
            }
        ]
    }
};
exports.WIDGET_URLS_SCAN_DAY_MOCK_PARSED = [
    {
        date: '2020-10-1T00:00:00+0000',
        inbound_clean: 303,
        inbound_malicious: 990,
        outbound_clean: 179,
        outbound_malicious: 463,
        journal_clean: 114,
        journal_malicious: 491
    },
    {
        date: '2020-10-2T00:00:00+0000',
        inbound_clean: 1007,
        inbound_malicious: 1948,
        outbound_clean: 1001,
        outbound_malicious: 533,
        journal_clean: 192,
        journal_malicious: 1135
    },
    {
        date: '2020-10-3T00:00:00+0000',
        inbound_clean: 1268,
        inbound_malicious: 2050,
        outbound_clean: 1721,
        outbound_malicious: 914,
        journal_clean: 629,
        journal_malicious: 1265
    },
    {
        date: '2020-10-4T00:00:00+0000',
        inbound_clean: 1337,
        inbound_malicious: 3006,
        outbound_clean: 2326,
        outbound_malicious: 1018,
        journal_clean: 724,
        journal_malicious: 1422
    },
    {
        date: '2020-10-5T00:00:00+0000',
        inbound_clean: 1675,
        inbound_malicious: 3950,
        outbound_clean: 2449,
        outbound_malicious: 1865,
        journal_clean: 1154,
        journal_malicious: 2331
    },
    {
        date: '2020-10-6T00:00:00+0000',
        inbound_clean: 2071,
        inbound_malicious: 4575,
        outbound_clean: 3240,
        outbound_malicious: 2640,
        journal_clean: 1877,
        journal_malicious: 3131
    },
    {
        date: '2020-10-7T00:00:00+0000',
        inbound_clean: 2785,
        inbound_malicious: 5245,
        outbound_clean: 3403,
        outbound_malicious: 3353,
        journal_clean: 2345,
        journal_malicious: 3401
    },
    {
        date: '2020-10-8T00:00:00+0000',
        inbound_clean: 3276,
        inbound_malicious: 5857,
        outbound_clean: 3565,
        outbound_malicious: 4299,
        journal_clean: 2679,
        journal_malicious: 4372
    },
    {
        date: '2020-10-9T00:00:00+0000',
        inbound_clean: 3972,
        inbound_malicious: 6161,
        outbound_clean: 4050,
        outbound_malicious: 4526,
        journal_clean: 2990,
        journal_malicious: 4894
    },
    {
        date: '2020-10-10T00:00:00+0000',
        inbound_clean: 4245,
        inbound_malicious: 6235,
        outbound_clean: 4744,
        outbound_malicious: 4876,
        journal_clean: 3298,
        journal_malicious: 5852
    },
    {
        date: '2020-10-11T00:00:00+0000',
        inbound_clean: 5019,
        inbound_malicious: 6744,
        outbound_clean: 5271,
        outbound_malicious: 5027,
        journal_clean: 3543,
        journal_malicious: 6755
    },
    {
        date: '2020-10-12T00:00:00+0000',
        inbound_clean: 5862,
        inbound_malicious: 6892,
        outbound_clean: 6051,
        outbound_malicious: 5796,
        journal_clean: 4313,
        journal_malicious: 7065
    },
    {
        date: '2020-10-13T00:00:00+0000',
        inbound_clean: 6208,
        inbound_malicious: 7018,
        outbound_clean: 6258,
        outbound_malicious: 6156,
        journal_clean: 4367,
        journal_malicious: 7986
    },
    {
        date: '2020-10-14T00:00:00+0000',
        inbound_clean: 6318,
        inbound_malicious: 8001,
        outbound_clean: 6369,
        outbound_malicious: 7052,
        journal_clean: 4567,
        journal_malicious: 8129
    },
    {
        date: '2020-10-15T00:00:00+0000',
        inbound_clean: 6417,
        inbound_malicious: 8396,
        outbound_clean: 7158,
        outbound_malicious: 7365,
        journal_clean: 5436,
        journal_malicious: 8174
    },
    {
        date: '2020-10-16T00:00:00+0000',
        inbound_clean: 7340,
        inbound_malicious: 8795,
        outbound_clean: 7208,
        outbound_malicious: 7658,
        journal_clean: 6115,
        journal_malicious: 9060
    },
    {
        date: '2020-10-17T00:00:00+0000',
        inbound_clean: 7653,
        inbound_malicious: 9602,
        outbound_clean: 7356,
        outbound_malicious: 7868,
        journal_clean: 6117,
        journal_malicious: 9192
    },
    {
        date: '2020-10-18T00:00:00+0000',
        inbound_clean: 8546,
        inbound_malicious: 9901,
        outbound_clean: 8093,
        outbound_malicious: 8867,
        journal_clean: 6658,
        journal_malicious: 10123
    },
    {
        date: '2020-10-19T00:00:00+0000',
        inbound_clean: 9000,
        inbound_malicious: 10336,
        outbound_clean: 8852,
        outbound_malicious: 9789,
        journal_clean: 7524,
        journal_malicious: 11045
    },
    {
        date: '2020-10-20T00:00:00+0000',
        inbound_clean: 9933,
        inbound_malicious: 10408,
        outbound_clean: 9691,
        outbound_malicious: 9859,
        journal_clean: 7861,
        journal_malicious: 11370
    },
    {
        date: '2020-10-21T00:00:00+0000',
        inbound_clean: 10495,
        inbound_malicious: 10637,
        outbound_clean: 10241,
        outbound_malicious: 10500,
        journal_clean: 8475,
        journal_malicious: 12049
    },
    {
        date: '2020-10-22T00:00:00+0000',
        inbound_clean: 11457,
        inbound_malicious: 10861,
        outbound_clean: 10620,
        outbound_malicious: 10623,
        journal_clean: 8894,
        journal_malicious: 12254
    },
    {
        date: '2020-10-23T00:00:00+0000',
        inbound_clean: 11680,
        inbound_malicious: 11844,
        outbound_clean: 11575,
        outbound_malicious: 10806,
        journal_clean: 9874,
        journal_malicious: 13015
    },
    {
        date: '2020-10-24T00:00:00+0000',
        inbound_clean: 12241,
        inbound_malicious: 12284,
        outbound_clean: 12393,
        outbound_malicious: 11140,
        journal_clean: 10712,
        journal_malicious: 13871
    },
    {
        date: '2020-10-25T00:00:00+0000',
        inbound_clean: 12895,
        inbound_malicious: 12548,
        outbound_clean: 12904,
        outbound_malicious: 11949,
        journal_clean: 11552,
        journal_malicious: 14546
    },
    {
        date: '2020-10-26T00:00:00+0000',
        inbound_clean: 13743,
        inbound_malicious: 12819,
        outbound_clean: 12981,
        outbound_malicious: 12045,
        journal_clean: 11571,
        journal_malicious: 15219
    },
    {
        date: '2020-10-27T00:00:00+0000',
        inbound_clean: 14583,
        inbound_malicious: 13799,
        outbound_clean: 13394,
        outbound_malicious: 12930,
        journal_clean: 12155,
        journal_malicious: 15732
    },
    {
        date: '2020-10-28T00:00:00+0000',
        inbound_clean: 14660,
        inbound_malicious: 14250,
        outbound_clean: 13661,
        outbound_malicious: 13525,
        journal_clean: 12825,
        journal_malicious: 15988
    },
    {
        date: '2020-10-29T00:00:00+0000',
        inbound_clean: 14989,
        inbound_malicious: 14959,
        outbound_clean: 14201,
        outbound_malicious: 13563,
        journal_clean: 13265,
        journal_malicious: 16445
    },
    {
        date: '2020-10-30T00:00:00+0000',
        inbound_clean: 15762,
        inbound_malicious: 15118,
        outbound_clean: 15168,
        outbound_malicious: 14167,
        journal_clean: 13894,
        journal_malicious: 17341
    }
];
