"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MepLogSidebarComponent = void 0;
const core_1 = require("@angular/core");
const table_store_1 = require("@mimecast-ui/table-store");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const sidebar_actions_1 = require("../../actions/sidebar.actions");
const list_static_values_1 = require("../list/list.static-values");
class MepLogSidebarComponent extends table_store_1.TableRowDetailBaseComponent {
    constructor(store) {
        super(store, list_static_values_1.MEP_LOG_TABLE_ID);
        this.store = store;
        this.close = new core_1.EventEmitter();
        this.destroy$ = new rxjs_1.Subject();
    }
    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
    ngOnInit() {
        this.selectedRow$.pipe(operators_1.takeUntil(this.destroy$)).subscribe((newlySelected) => {
            this.store.dispatch(new sidebar_actions_1.MepLogLoadDetailsAction(newlySelected.id));
        });
    }
}
exports.MepLogSidebarComponent = MepLogSidebarComponent;
