<div [ngClass]="extendClass" class="mc-tab-group">
  <a
    class="mc-tab branding"
    *ngFor="let tab of tabs"
    (click)="selectTab(tab)"
    [class.active]="tab.active"
    [class.disabled]="tab.disabled"
    [class.hidden]="tab.hidden"
    [tooltip]="tab.tooltip"
  >
    {{ tab.name | translate }}
  </a>
</div>
<ng-content></ng-content>
