"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RuleLexiconComponent = void 0;
const core_1 = require("@angular/core");
const components_1 = require("@mimecast-ui/components");
const rule_service_1 = require("../../../../../services/rule.service");
const step_base_component_1 = require("../../../step.base.component");
const select_lexicon_component_1 = require("app-new/archive/supervision/containers/asides/select-lexicon/select-lexicon.component");
const rule_actions_1 = require("app-new/archive/supervision/actions/rule.actions");
class RuleLexiconComponent extends step_base_component_1.StepBaseDirective {
    constructor(store, fb, asideService) {
        super(store, fb, 3, rule_service_1.RuleService.WizardId);
        this.store = store;
        this.fb = fb;
        this.asideService = asideService;
        this.onGetLexicons = new core_1.EventEmitter();
        this.form = this.fb.group({
            lexicon: [null]
        });
    }
    ngOnChanges(changes) {
        if (changes.selectedLexicon && changes.selectedLexicon.currentValue) {
            this.form.get('lexicon').setValue(changes.selectedLexicon.currentValue);
        }
    }
    deleteLexicon() {
        this.form.get('lexicon').setValue(null);
        this.store.dispatch(new rule_actions_1.ClearSelectedLexiconAction());
    }
    openSidebar() {
        this.onGetLexicons.emit();
        const asideConfig = new components_1.AsideConfig();
        asideConfig.size = 'md';
        this.aside = this.asideService.open(select_lexicon_component_1.SelectLexiconComponent, asideConfig);
    }
}
exports.RuleLexiconComponent = RuleLexiconComponent;
