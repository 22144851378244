"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InfoBoxComponent = void 0;
const core_1 = require("@angular/core");
class InfoBoxComponent {
    constructor(translate, fb, session) {
        this.translate = translate;
        this.fb = fb;
        this.session = session;
        this.dismissForever = new core_1.EventEmitter();
        this.infoBoxActionDismiss = {
            label: this.translate.instant('$I18N_MESSAGE_CENTER_INFO_BOX.LABEL_DISMISS'),
            type: 'link',
            callback: () => {
                if (this.form.value.dontShowAgain) {
                    this.dismissForever.emit();
                }
                this.session.showInfoBox = false;
            }
        };
        this.infoBoxActionLearnMore = {
            label: this.translate.instant('$I18N_MESSAGE_CENTER_INFO_BOX.LABEL_LEARN_MORE'),
            type: 'button',
            callback: () => {
                const win = window.open(this.learnMoreUrl, '_blank');
                win.focus();
            }
        };
    }
    ngOnInit() {
        this.form = this.fb.group({
            dontShowAgain: [false]
        });
    }
    ngOnChanges() {
        this.infoBoxNotification = {
            status: 'info',
            msg: this.translate.instant(this.message),
            actions: [this.infoBoxActionLearnMore, this.infoBoxActionDismiss],
            boxed: true
        };
    }
}
exports.InfoBoxComponent = InfoBoxComponent;
