"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CapabilitiesManagerService = void 0;
class CapabilitiesManagerService {
    constructor(capabilitiesService) {
        this.capabilitiesService = capabilitiesService;
    }
    hasOnedriveEditPermission() {
        return this.capabilitiesService.evalCapabilitiesExpression(`${CapabilitiesManagerService.ONE_DRIVE_PERMISSIONS.SYNC_EDIT}
        ||
        ${CapabilitiesManagerService.ONE_DRIVE_PERMISSIONS.SYNC_AND_RECOVER_EDIT}`);
    }
    hasOnedriveReadPermission() {
        return this.capabilitiesService.evalCapabilitiesExpression(`${CapabilitiesManagerService.ONE_DRIVE_PERMISSIONS.SYNC_READ}
        ||
        ${CapabilitiesManagerService.ONE_DRIVE_PERMISSIONS.SYNC_AND_RECOVER_READ}`);
    }
    hasOnedriveContentViewPermission() {
        return this.capabilitiesService.evalCapabilitiesExpression(`${CapabilitiesManagerService.ONE_DRIVE_PERMISSIONS.SYNC_SYNC_CONTENT_VIEW}
        ||
        ${CapabilitiesManagerService.ONE_DRIVE_PERMISSIONS.SYNC_AND_RECOVER_SYNC_CONTENT_VIEW}`);
    }
    hasOnedriveBackupEditPermission() {
        return this.capabilitiesService.evalCapabilitiesExpression(CapabilitiesManagerService.ONE_DRIVE_PERMISSIONS.ONEDRIVE_BACKUP_EDIT);
    }
    hasOnedriveBackupReadPermission() {
        return this.capabilitiesService.evalCapabilitiesExpression(CapabilitiesManagerService.ONE_DRIVE_PERMISSIONS.ONEDRIVE_BACKUP_READ);
    }
    isDevelopmentOnly() {
        return this.capabilitiesService.hasCapability(`${CapabilitiesManagerService.ONE_DRIVE_PERMISSIONS.DEV_ONLY}`);
    }
}
exports.CapabilitiesManagerService = CapabilitiesManagerService;
CapabilitiesManagerService.ONE_DRIVE_PERMISSIONS = {
    SYNC_READ: 'Permission.ONEDRIVE_SYNC_READ',
    SYNC_EDIT: 'Permission.ONEDRIVE_SYNC_EDIT',
    SYNC_SYNC_CONTENT_VIEW: 'Permission.ONEDRIVE_SYNC_SYNC_CONTENT_VIEW',
    SYNC_AND_RECOVER_READ: 'Permission.ONEDRIVE_SYNC_AND_RECOVER_READ',
    SYNC_AND_RECOVER_EDIT: 'Permission.ONEDRIVE_SYNC_AND_RECOVER_EDIT',
    SYNC_AND_RECOVER_SYNC_CONTENT_VIEW: 'Permission.ONEDRIVE_SYNC_AND_RECOVER_SYNC_CONTENT_VIEW',
    DEV_ONLY: 'Development.Only',
    ONEDRIVE_BACKUP_EDIT: 'Permission.ONEDRIVE_BACKUP_EDIT',
    ONEDRIVE_BACKUP_READ: 'Permission.ONEDRIVE_BACKUP_READ'
};
