"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgPolicyCategoryFilterStepComponent = void 0;
const core_1 = require("@angular/core");
const operators_1 = require("rxjs/operators");
const models_1 = require("../../../models");
class SwgPolicyCategoryFilterStepComponent {
    constructor(fb) {
        this.fb = fb;
        this.resetFormOnDisable = true;
        this.hideFilterSearch = false;
        this.hideWizardHeader = false;
        this._disabled = false;
        this.updateWebCategoryRules = new core_1.EventEmitter();
        this.sectionToggle = [];
        this.foundCount = [];
        this.groupStatus = [];
        this.categoryFilter = '';
        this.searching = false;
        this.options = [
            { value: models_1.SwgCategoryOptionsEnum.ALLOW, label: '$I18N_SWG_WEB_CATEGORY_POLICY_ALLOW' },
            { value: models_1.SwgCategoryOptionsEnum.DISALLOW, label: '$I18N_SWG_WEB_CATEGORY_POLICY_BLOCK' }
        ];
        this.destroy = false;
    }
    set disabled(value) {
        this._disabled = value;
        if (this._disabled) {
            if (!this.webCategoryFilterPolicyForm) {
                return;
            }
            if (this.resetFormOnDisable) {
                this.webCategoryFilterPolicyForm.reset();
            }
            this.webCategoryFilterPolicyForm.disable();
        }
        else if (this.webCategoryFilterPolicyForm) {
            this.webCategoryFilterPolicyForm.enable();
        }
    }
    toggleSection(section) {
        this.sectionToggle[section] = !this.sectionToggle[section];
    }
    showSection(section) {
        // This will show the section if it's hidden, but won't hide it if it's already visible
        return !this.sectionToggle[section] ? this.toggleSection(section) : null;
    }
    normaliseSearchQuery(query) {
        // This will normalise search queries by doing the following:
        //  1. Converting the query to all-caps (thus making it case-insensitive).
        //  2. Adding spaces either side of ampersands if there aren't any (see WS-587).
        //  3. Collapsing multiple spaces into one.
        return query
            .toUpperCase()
            .replace(/(\S*)&(\S*)/g, '$1 & $2') // Preserving all non-whitespace characters either side of the ampersand.
            .replace(/\s+/g, ' '); // Collapse multiple whitespace characters to one space.
    }
    ngOnInit() {
        this.userRuleSections = this.webCategoryRuleSections;
        this.filteredWebCategoryRuleSections = this.webCategoryRuleSections;
        this.intializeSectionStatus();
        this.createFormWithControls();
        this.registerSubScriptions();
    }
    intializeSectionStatus() {
        this.filteredWebCategoryRuleSections.forEach(group => {
            this.sectionToggle[group.key] = this.expanded || false;
            this.foundCount[group.key] = 0;
        });
    }
    createFormWithControls() {
        const formControls = {};
        this.userRuleSections.forEach(group => {
            return group.rules.forEach(rule => {
                this.foundCount[rule.name] = 0;
                formControls[rule.name] = [
                    {
                        value: rule.value === models_1.SwgCategoryOptionsEnum.DISALLOW,
                        disabled: this._disabled
                    }
                ];
            });
        });
        this.webCategoryFilterPolicyForm = this.fb.group(formControls);
    }
    registerSubScriptions() {
        this.webCategoryFilterPolicyForm.valueChanges
            .pipe(operators_1.debounceTime(200), operators_1.takeWhile(() => !this.destroy))
            .subscribe(() => this.updateCategorySettings(this.webCategoryFilterPolicyForm.getRawValue()));
        this.webCategoryFilterPolicyForm.updateValueAndValidity();
    }
    updateAllCategoriesInSection(group, action) {
        const patchedValed = {};
        const rulesForGroup = this.webCategoryRuleSections.find(rule => rule.key === group);
        rulesForGroup.rules.forEach(rule => {
            patchedValed[rule.name] = action;
        });
        this.webCategoryFilterPolicyForm.patchValue(patchedValed);
    }
    blockAllInGroup(group) {
        this.updateAllCategoriesInSection(group, false);
    }
    allowAllInGroup(group) {
        this.updateAllCategoriesInSection(group, true);
    }
    updateCategorySettings(val) {
        const userSelection = Object.keys(val)
            .filter(category => val[category])
            .map(rule => {
            return {
                category: rule,
                action: val[rule] ? models_1.SwgCategoryOptionsEnum.DISALLOW : models_1.SwgCategoryOptionsEnum.ALLOW
            };
        });
        this.updateWebCategoryRules.emit(userSelection);
        this.updateCategoryGroupRadio(userSelection);
    }
    updateCategoryGroupRadio(userSelection) {
        this.groupStatus = [];
        const disabledCategories = userSelection.map(x => x.category);
        this.userRuleSections.forEach(group => {
            let hasDisabled = false;
            let hasEnabled = false;
            group.rules.forEach(rule => {
                if (disabledCategories.some(x => x === rule.name)) {
                    hasDisabled = true;
                }
                else {
                    hasEnabled = true;
                }
            });
            if (hasEnabled && hasDisabled) {
                this.groupStatus[group.key] = models_1.SwgCategorySectionOptionsEnum.CUSTOM;
            }
            if (hasDisabled === false) {
                this.groupStatus[group.key] = models_1.SwgCategorySectionOptionsEnum.ALLOWALL;
            }
            if (hasEnabled === false) {
                this.groupStatus[group.key] = models_1.SwgCategorySectionOptionsEnum.DISALLOWALL;
            }
        });
    }
    isValid() {
        return true;
    }
    onSearchClear() {
        this.categoryFilter = '';
        this.searching = false;
        if (!this.expanded) {
            this.closeAllSections();
        }
    }
    closeAllSections() {
        this.filteredWebCategoryRuleSections.forEach(section => {
            this.sectionToggle[section.key] = false;
        });
    }
    openAllSections() {
        this.filteredWebCategoryRuleSections.forEach(section => {
            this.sectionToggle[section.key] = true;
        });
    }
    onSearch(val) {
        this.searching = true;
        this.categoryFilter = val.search;
        this.openAllSections();
        this.userRuleSections.forEach(section => {
            this.foundCount[section.key] = 0;
            const searchTerm = this.normaliseSearchQuery(this.categoryFilter);
            section.rules.forEach(rule => {
                if (rule.name.indexOf(searchTerm) !== -1 ||
                    rule.translatedCategoryName.toUpperCase().indexOf(searchTerm) !== -1) {
                    this.foundCount[section.key]++;
                }
            });
        });
    }
    showThisCategory(catRule) {
        const searchTerm = this.normaliseSearchQuery(this.categoryFilter);
        if (this.categoryFilter === '') {
            return true;
        }
        else {
            const ret = catRule.name.indexOf(searchTerm) >= 0 ||
                catRule.translatedCategoryName.toUpperCase().indexOf(searchTerm) >= 0;
            return ret;
        }
    }
    ngOnDestroy() {
        this.destroy = true;
    }
}
exports.SwgPolicyCategoryFilterStepComponent = SwgPolicyCategoryFilterStepComponent;
