"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const url_protection_page_component_1 = require("./containers/url-protection-page.component");
angular
    .module('services.url-protection-new', [])
    .controller('UrlProtectionPageComponent', [
    '$stateParams',
    '$scope',
    function ($stateParams, $scope) {
        $scope.stateParams = $stateParams;
    }
])
    .directive('mcUrlProtectionPage', static_1.downgradeComponent({
    component: url_protection_page_component_1.UrlProtectionPageComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider.state('url-protection-new', {
            url: '/services/services/url-protection-new',
            data: {
                isFixedLayout: false,
                checkProgress: false,
                capabilities: `Permission.SERVICES_TTP_URL_PROTECT_READ
          && Temporary.Services.TTP.URLProtection
          && Temporary.Services.UrlProtection.New`,
                breadcrumbs: []
            },
            views: {
                main: {
                    template: '<mc-url-protection-page></mc-url-protection-page>',
                    controller: 'UrlProtectionPageComponent'
                }
            }
        });
    }
]);
