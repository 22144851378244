"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ1 = exports.ɵ0 = exports.getUserProfileEmail = exports.getURLCategories = exports.getManagedURL = exports.getScanDetailsViewState = exports.getCheckUrlsLoadingState = exports.isReportModalButtonEnabled = exports.getScanDetailsReportId = exports.getCheckUrlsScanResponse = exports.getCheckUrlsScanDetails = exports.getCheckUrlsScanAggressiveResults = exports.getCheckUrlsScanModerateResults = exports.getCheckUrlsScanRelaxedResults = exports.getUrlProtectionLogsMetadata = exports.getUrlProtectionLogsSelectedRow = exports.getUrlProtectionLogsRequestPage = exports.getUrlProtectionLogsPagination = exports.getUrlProtectionLogsSearch = exports.getUrlProtectionLogsTotalCount = exports.getUrlProtectionLogItems = exports.isUrlProtectionLogsLoading = exports.UrlReportLogsReducers = exports.UrlProtectionLogsReducers = void 0;
const store_1 = require("@ngrx/store");
const urlProtectionLogs = require("./logs.reducer");
const urlReportLogs = require("./url-activity-report.reducer");
exports.UrlProtectionLogsReducers = {
    logs: urlProtectionLogs.reducer
};
exports.UrlReportLogsReducers = {
    UrlLogs: urlReportLogs.reducer
};
const UrlProtectionLogsStateSelector = store_1.createFeatureSelector('url-protection-logs');
const UrlReportLogsStateSelector = store_1.createFeatureSelector('url-protection-reports');
const ɵ0 = state => state;
exports.ɵ0 = ɵ0;
const getUrlProtectionLogsState = store_1.createSelector(UrlProtectionLogsStateSelector, ɵ0);
const ɵ1 = state => state;
exports.ɵ1 = ɵ1;
const getUrlProtectionReportLogsState = store_1.createSelector(UrlReportLogsStateSelector, ɵ1);
exports.isUrlProtectionLogsLoading = store_1.createSelector(getUrlProtectionLogsState, urlProtectionLogs.isLoading);
exports.getUrlProtectionLogItems = store_1.createSelector(getUrlProtectionLogsState, urlProtectionLogs.getItems);
exports.getUrlProtectionLogsTotalCount = store_1.createSelector(getUrlProtectionLogsState, urlProtectionLogs.getTotalCount);
exports.getUrlProtectionLogsSearch = store_1.createSelector(getUrlProtectionLogsState, urlProtectionLogs.getSearchRequest);
exports.getUrlProtectionLogsPagination = store_1.createSelector(getUrlProtectionLogsState, urlProtectionLogs.getPagination);
exports.getUrlProtectionLogsRequestPage = store_1.createSelector(getUrlProtectionLogsState, urlProtectionLogs.getRequestPage);
exports.getUrlProtectionLogsSelectedRow = store_1.createSelector(getUrlProtectionLogsState, urlProtectionLogs.getSelectedRow);
exports.getUrlProtectionLogsMetadata = store_1.createSelector(getUrlProtectionLogsState, urlProtectionLogs.getMetadata);
exports.getCheckUrlsScanRelaxedResults = store_1.createSelector(getUrlProtectionLogsState, urlProtectionLogs.getCheckUrlsScanRelaxedResults);
exports.getCheckUrlsScanModerateResults = store_1.createSelector(getUrlProtectionLogsState, urlProtectionLogs.getCheckUrlsScanModerateResults);
exports.getCheckUrlsScanAggressiveResults = store_1.createSelector(getUrlProtectionLogsState, urlProtectionLogs.getCheckUrlsScanAggressiveResults);
exports.getCheckUrlsScanDetails = store_1.createSelector(getUrlProtectionLogsState, urlProtectionLogs.getCheckUrlsScanDetails);
exports.getCheckUrlsScanResponse = store_1.createSelector(getUrlProtectionLogsState, urlProtectionLogs.getCheckUrlsScanResponse);
exports.getScanDetailsReportId = store_1.createSelector(getUrlProtectionLogsState, urlProtectionLogs.getScanDetailsReportId);
exports.isReportModalButtonEnabled = store_1.createSelector(getUrlProtectionLogsState, urlProtectionLogs.isReportModalButtonEnabled);
exports.getCheckUrlsLoadingState = store_1.createSelector(getUrlProtectionLogsState, urlProtectionLogs.getCheckUrlsLoadingState);
exports.getScanDetailsViewState = store_1.createSelector(getUrlProtectionLogsState, urlProtectionLogs.getScanDetailsViewState);
exports.getManagedURL = store_1.createSelector(getUrlProtectionLogsState, urlProtectionLogs.getManagedURL);
exports.getURLCategories = store_1.createSelector(getUrlProtectionReportLogsState, urlReportLogs.getURLCategories);
exports.getUserProfileEmail = store_1.createSelector(getUrlProtectionReportLogsState, urlReportLogs.getUserProfileEmail);
