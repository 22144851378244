"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getIsDeleting = exports.getDeleteSuccess = exports.getAllSelectedItems = exports.getAllowDelete = exports.getIsSelectedAll = exports.reducer = exports.initialState = void 0;
const actions = require("../../actions/external-domain-list.actions");
exports.initialState = {
    isSelectedAll: false,
    itemSelected: false,
    allowDelete: false,
    selectedItems: [],
    deleteSuccess: false,
    isDeleting: null
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.SELECT_ALL: {
            return Object.assign(Object.assign({}, state), { isSelectedAll: true, allowDelete: true, selectedItems: action.allSelectedItems });
        }
        case actions.SELECT_LIST_ITEM: {
            const selectedItem = action.payLoad;
            const selectedItems = [...state.selectedItems];
            if (!selectedItems.some(item => item.id === selectedItem.id)) {
                selectedItems.push(selectedItem);
            }
            return Object.assign(Object.assign({}, state), { allowDelete: true, selectedItems });
        }
        case actions.UN_SELECT_LIST_ITEM: {
            const selectedItem = action.payLoad;
            const updatedSelectedItems = [...state.selectedItems].filter(item => item.id !== selectedItem.id);
            return Object.assign(Object.assign({}, state), { allowDelete: updatedSelectedItems.length > 0, selectedItems: updatedSelectedItems });
        }
        case actions.DELETE_ALERT_OPEN: {
            return Object.assign(Object.assign({}, state), { isDeleting: false });
        }
        case actions.DELETE_ALERT_CANCEL: {
            return Object.assign(Object.assign({}, state), { isDeleting: null });
        }
        case actions.BULK_DELETE_ITEMS: {
            return Object.assign(Object.assign({}, state), { deleteSuccess: false, isDeleting: true });
        }
        case actions.DELETE_SUCCESS: {
            return Object.assign(Object.assign({}, state), { deleteSuccess: true, isDeleting: false });
        }
        // @ts-ignore
        case actions.DELETE_FAIL: {
            return Object.assign(Object.assign({}, state), { deleteSuccess: false, isDeleting: false });
        }
        case actions.UN_SELECT_ALL:
        case actions.CLEAR_LIST_STATE:
            return exports.initialState;
        default:
            return state;
    }
}
exports.reducer = reducer;
const getIsSelectedAll = (state) => state.isSelectedAll;
exports.getIsSelectedAll = getIsSelectedAll;
const getAllowDelete = (state) => state.allowDelete;
exports.getAllowDelete = getAllowDelete;
const getAllSelectedItems = (state) => state.selectedItems;
exports.getAllSelectedItems = getAllSelectedItems;
const getDeleteSuccess = (state) => state.deleteSuccess;
exports.getDeleteSuccess = getDeleteSuccess;
const getIsDeleting = (state) => state.isDeleting;
exports.getIsDeleting = getIsDeleting;
