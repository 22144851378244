<mc-table
  tableId="directory-sync/table"
  [isLoading]="isLoading$ | async"
  [highlightedRow]="selectedRow$ | async"
  [data]="integrations$ | async"
  [columns]="columns"
  (rowClick)="viewConnection($event)"
  translatePrefix="$I18N_AAA_DIRECTORY_SYNC_TABLE">
  <button *ngIf="!isAAAGroupAccount"
    class="mc-table-actions btn btn-secondary mc-synchronize-button"
    (click)="startConnectionSynchronization()"
    [disabled]="isSyncRunning$ | async">
    <i *ngIf="isSyncRunning$ | async" class="icon-arrows-ccw" aria-hidden="true"></i>
    {{ getSyncButtonLabel() | translate }}
  </button>
  <button *ngIf="isSyncReportAvailable"
          class="mc-table-actions btn btn-text mc-last-sync-report-button"
          (click)="getLastSynchronizationReport()"
  >
    <i *ngIf="isReportRunning" class="icon-arrows-ccw" aria-hidden="true"></i>
    {{ '$I18N_DIRECTORY_SYNC_TABLE.ACTIONS.LAST_SYNC_REPORT' | translate }}
  </button>
  <mc-empty-results
    iconClass="far fa-list-alt"
    keyTitle="$I18N_AAA_DIRECTORY_SYNC_TABLE.NO_DATA.TITLE">
  </mc-empty-results>

  <mc-filters
    [showPagination]="true"
    [metadata]="pagingMetadata$ | async"
    (paginatorChange)="changePage($event)">
    <mc-filter-bundle-filter-by-and-search-by
      placeholder="$I18N_AAA_DIRECTORY_SYNC_TABLE.FILTERS.SEARCH_FIELD_PLACEHOLDER"
      [searchByFields]="searchByFields"
      [minLengthForSearchField]="minLengthForSearchField"
      [maxLengthForSearchField]="maxLengthForSearchField"
      (filtersChange)="search($event)">
    </mc-filter-bundle-filter-by-and-search-by>
    <mc-filters-bundle-column-values-modal [sections]="filterSections"
                                           (filtersChange)="changeColumnFilter($event)"
                                           (click)="displayToastWhenNoGroupsAvailable()">
    </mc-filters-bundle-column-values-modal>
  </mc-filters>

  <mc-column key="group">
    <mc-body-cell *mcBodyCellDef="let row" class="mc-integration-group">
      {{ row.group || 'N/A' }}
    </mc-body-cell>
  </mc-column>

  <mc-column key="account">
    <mc-body-cell *mcBodyCellDef="let row" class="mc-integration-account">
      {{ row.account }}
    </mc-body-cell>
  </mc-column>

  <mc-column key="serverType">
    <mc-body-cell *mcBodyCellDef="let row" class="mc-integration-server-type">
      <span>
        <i class="mc-icon {{serverTypeIcons[row.serverType]}}" aria-hidden="true"></i>{{ getServerTypeKey(row) | translate }}
      </span>
    </mc-body-cell>
  </mc-column>

  <mc-column key="enabled">
    <mc-body-cell *mcBodyCellDef="let row" class="mc-integration-status">
      <span *ngIf="row.enabled" class="mc-integration-status--enable">
        <i class="fas fa-check-circle" aria-hidden="true"></i>{{ '$I18N_AAA_DIRECTORY_SYNC_TABLE.ENABLE' | translate }}
      </span>
      <span *ngIf="!row.enabled" class="mc-integration-status--disable">
        <i class="fas fa-times-circle" aria-hidden="true"></i>{{ '$I18N_AAA_DIRECTORY_SYNC_TABLE.DISABLE' | translate }}
      </span>
    </mc-body-cell>
  </mc-column>

  <mc-column key="lastSync">
    <mc-body-cell *mcBodyCellDef="let row" class="mc-integration-last-sync-date-time">
      {{ (row.lastSync | mcDate) || (lastOutcome.not_active.key | translate) }}
    </mc-body-cell>
  </mc-column>

  <mc-column key="status">
    <mc-body-cell *mcBodyCellDef="let row" class="mc-integration-last-outcome">
      <span class="{{lastOutcome[row.status].classes}}">
        <i class="fas {{lastOutcome[row.status].icon}}" aria-hidden="true"></i>{{ lastOutcome[row.status].key | translate }}
      </span>
    </mc-body-cell>
  </mc-column>

  <mc-column-actions
    key="action"
    mcShowColumnConfig
    [columnsAlwaysVisible]="columnsAlwaysVisible"
    [columnsToStartHidden]="columnsStartHidden">
    <mc-row-actions *mcRowActions="let row">
      <li
        mcRowAction="$I18N_AAA_DIRECTORY_SYNC_TABLE.ACTIONS.VIEW"
        (click)="viewConnection(row)"
      ></li>
    </mc-row-actions>
  </mc-column-actions>
</mc-table>
