"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDropdownFields = exports.IsDropdown = void 0;
require("reflect-metadata");
const IS_DROPDOWN_METADATA_KEY = Symbol('MC-DROPDOWN-FIELD');
function IsDropdown() {
    return (target, propertyKey) => {
        let isDropdownFields = Reflect.getMetadata(IS_DROPDOWN_METADATA_KEY, target);
        if (isDropdownFields) {
            isDropdownFields.push(propertyKey);
        }
        else {
            isDropdownFields = [propertyKey];
        }
        Reflect.defineMetadata(IS_DROPDOWN_METADATA_KEY, isDropdownFields, target);
    };
}
exports.IsDropdown = IsDropdown;
function getDropdownFields(origin) {
    const properties = Reflect.getMetadata(IS_DROPDOWN_METADATA_KEY, origin);
    const result = {};
    if (properties) {
        properties.forEach(key => (result[key] = true));
    }
    return result;
}
exports.getDropdownFields = getDropdownFields;
