"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiIntegrationCrowdstrikeSummaryStepComponent = void 0;
const core_1 = require("@angular/core");
const operators_1 = require("rxjs/operators");
class ApiIntegrationCrowdstrikeSummaryStepComponent {
    constructor(fb, translateService) {
        this.fb = fb;
        this.translateService = translateService;
        this.onSwitchCrowdstrikeIntegrationStatus = new core_1.EventEmitter();
        this.form = this.fb.group({
            integrationStatus: false
        });
    }
    ngOnInit() {
        if (!!this.crowdstrikeIntegration) {
            this.form.patchValue({
                integrationStatus: this.crowdstrikeIntegration.enabled
            });
        }
        this.formSubscription = this.form.valueChanges.pipe(operators_1.debounceTime(200)).subscribe(() => {
            this.update();
        });
    }
    update() {
        this.onSwitchCrowdstrikeIntegrationStatus.emit(this.form.value.integrationStatus);
    }
    isValid() {
        return true;
    }
    get summaryHeader() {
        if (this.crowdstrikePrerequisitesError) {
            return this.translateService.instant('$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.SUMMARY.HEADER_ERROR');
        }
        return this.translateService.instant('$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.SUMMARY.HEADER');
    }
    get errorContent() {
        if (this.crowdstrikePrerequisitesError.toLocaleLowerCase() ===
            'err_api_integration_crowdstrike_already_exists') {
            return this.translateService.instant('$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.SUMMARY.ALREADY_EXISTS');
        }
        return this.translateService.instant('$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.SUMMARY.PACKAGE_MISSING');
    }
    crowdstrikeGroupsList() {
        if (this.crowdstrikeIntegration) {
            return Object.values(this.crowdstrikeIntegration.notificationGroups);
        }
        return [];
    }
    corwdstrikeEmailList() {
        if (this.crowdstrikeIntegration) {
            return this.crowdstrikeIntegration.notificationAddresses;
        }
        return [];
    }
    displayAction() {
        return (this.crowdstrikeIntegration.submitMaliciousHashes &&
            this.crowdstrikeIntegration.iocOutboundAction &&
            this.crowdstrikeIntegration.iocOutboundAction !== '');
    }
}
exports.ApiIntegrationCrowdstrikeSummaryStepComponent = ApiIntegrationCrowdstrikeSummaryStepComponent;
