"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppDetailsEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const appDetailsActions = require("../../actions/apps/app-details.actions");
const appsActions = require("../../actions/apps/apps.actions");
const tableStore = require("@mimecast-ui/table-store");
const operators_1 = require("rxjs/operators");
const app_details_aside_component_1 = require("../../components/app-details-aside/app-details-aside.component");
const notification_actions_1 = require("../../../components/notification/actions/notification.actions");
const reducers_1 = require("../../reducers");
const cancel_app_update_modal_component_1 = require("../../components/cancel-app-update-modal/cancel-app-update-modal.component");
class AppDetailsEffects {
    constructor(action$, appsApiService, modalService, asideService, store) {
        this.action$ = action$;
        this.appsApiService = appsApiService;
        this.modalService = modalService;
        this.asideService = asideService;
        this.store = store;
        this.openAppDetailsAside$ = this.action$.pipe(effects_1.ofType(appDetailsActions.OPEN_ASIDE), operators_1.tap(() => {
            this.appDetailsAsideInstance = this.asideService.open(app_details_aside_component_1.IdentityAppDetailsAsideComponent, {
                position: 'right',
                size: 'md',
                hasBackdrop: true
            });
            this.appDetailsAsideInstance
                .beforeClose()
                .pipe(operators_1.take(1))
                .subscribe(() => {
                this.store.dispatch(new appsActions.IdentityCloseAppAsideAction());
            });
            this.appDetailsAsideInstance
                .afterClose()
                .pipe(operators_1.take(1))
                .subscribe(() => {
                this.appDetailsAsideInstance = undefined;
            });
        }), operators_1.map((action) => {
            return new appDetailsActions.IdentityLoadAppDetailsAction(action.payload.selectedApp.id);
        }));
        this.loadAppDetails$ = this.action$.pipe(effects_1.ofType(appDetailsActions.LOAD), operators_1.switchMap((action) => {
            return this.appsApiService.getAppDetails(action.appId).pipe(operators_1.map((response) => new appDetailsActions.IdentityLoadAppDetailsSuccessAction(response)), operators_1.catchError(() => rxjs_1.of(new notification_actions_1.NotificationShowFailAction('$I18N_IDENTITY_GENERIC_ERROR'), new appDetailsActions.IdentityLoadAppDetailsFailAction())));
        }));
        this.closeIdentityAppDetailsAside$ = this.action$.pipe(effects_1.ofType(appDetailsActions.LOAD_FAIL, appDetailsActions.UPDATE_CLOSE_APP), operators_1.filter(() => !!this.appDetailsAsideInstance), operators_1.switchMap(() => {
            this.appDetailsAsideInstance.close();
            this.appDetailsAsideInstance = undefined;
            return rxjs_1.EMPTY;
        }));
        this.updateApp$ = this.action$.pipe(effects_1.ofType(appDetailsActions.UPDATE), operators_1.switchMap((action) => {
            const updatePayload = {
                id: action.payload.id,
                name: action.payload.name,
                acsUrl: action.payload.acsUrl
            };
            if (!!action.payload.logoutUrl) {
                updatePayload.logoutUrl = action.payload.logoutUrl;
            }
            return this.appsApiService.updateApp(updatePayload).pipe(operators_1.map((updatedApp) => {
                return new appDetailsActions.IdentityUpdateAppDetailsSuccessAction(updatedApp);
            }), operators_1.catchError(response => {
                return rxjs_1.of(new appDetailsActions.IdentityUpdateAppDetailsFailAction(response.fail[0].errors[0].message));
            }));
        }));
        this.updateSuccess$ = this.action$.pipe(effects_1.ofType(appDetailsActions.UPDATE_SUCCESS), operators_1.map(() => {
            return new tableStore.LoadAction({ tableId: 'IdentityAppsList' });
        }));
        this.sequenceOnUpdateSuccess$ = this.action$.pipe(effects_1.ofType(tableStore.LOAD_TABLE_SUCCESS), operators_1.withLatestFrom(this.store.select(reducers_1.getUpdatedApp)), operators_1.mergeMap(([, updatedApp]) => {
            if (!!updatedApp) {
                return [
                    new appDetailsActions.IdentityUpdateCloseAppAsideAction(),
                    new appDetailsActions.IdentityUpdateAppSuccessNotification()
                ];
            }
            else {
                return rxjs_1.EMPTY;
            }
        }));
        this.showUpdateAppSuccessNotification$ = this.action$.pipe(effects_1.ofType(appDetailsActions.UPDATE_SUCCESS_NOTIFY), operators_1.delay(500), operators_1.map(() => {
            return new notification_actions_1.NotificationShowSuccessAction('$I18N_IDENTITY_APPS_LIST.NOTIFICATIONS.APP_UPDATED');
        }));
        this.updateFailure$ = this.action$.pipe(effects_1.ofType(appDetailsActions.UPDATE_FAIL), operators_1.map((action) => {
            return new notification_actions_1.NotificationShowFailAction(action.failMessage);
        }));
        this.showAppUpdateCancelModal$ = this.action$.pipe(effects_1.ofType(appDetailsActions.CANCEL_MODAL_OPEN), operators_1.switchMap(() => {
            this.cancelAppModalInstance = this.modalService.open(cancel_app_update_modal_component_1.IdentityCancelAppUpdateModalComponent, {
                size: 'sm',
                hasBackdrop: true
            });
            return rxjs_1.EMPTY;
        }));
        this.cancelAppUpdateCancel$ = this.action$.pipe(effects_1.ofType(appDetailsActions.CANCEL_MODAL_NO_RESPONSE), operators_1.map(() => {
            return new appDetailsActions.IdentityAppCancelModelCloseAction();
        }));
        this.finishAppUpdateCancel$ = this.action$.pipe(effects_1.ofType(appDetailsActions.CANCEL_MODAL_YES_RESPONSE), operators_1.mergeMap(() => {
            return [
                new appDetailsActions.IdentityAppCancelModelCloseAction(),
                new appDetailsActions.IdentitySwitchAppAsideToViewAction()
            ];
        }));
        this.closeIdentityPolicyCloseModal$ = this.action$.pipe(effects_1.ofType(appDetailsActions.CANCEL_MODAL_CLOSE), operators_1.filter(() => !!this.cancelAppModalInstance), operators_1.switchMap(() => {
            this.cancelAppModalInstance.close();
            this.cancelAppModalInstance = undefined;
            return rxjs_1.EMPTY;
        }));
    }
}
__decorate([
    effects_1.Effect()
], AppDetailsEffects.prototype, "openAppDetailsAside$", void 0);
__decorate([
    effects_1.Effect()
], AppDetailsEffects.prototype, "loadAppDetails$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], AppDetailsEffects.prototype, "closeIdentityAppDetailsAside$", void 0);
__decorate([
    effects_1.Effect()
], AppDetailsEffects.prototype, "updateApp$", void 0);
__decorate([
    effects_1.Effect()
], AppDetailsEffects.prototype, "updateSuccess$", void 0);
__decorate([
    effects_1.Effect()
], AppDetailsEffects.prototype, "sequenceOnUpdateSuccess$", void 0);
__decorate([
    effects_1.Effect()
], AppDetailsEffects.prototype, "showUpdateAppSuccessNotification$", void 0);
__decorate([
    effects_1.Effect()
], AppDetailsEffects.prototype, "updateFailure$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], AppDetailsEffects.prototype, "showAppUpdateCancelModal$", void 0);
__decorate([
    effects_1.Effect()
], AppDetailsEffects.prototype, "cancelAppUpdateCancel$", void 0);
__decorate([
    effects_1.Effect()
], AppDetailsEffects.prototype, "finishAppUpdateCancel$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], AppDetailsEffects.prototype, "closeIdentityPolicyCloseModal$", void 0);
exports.AppDetailsEffects = AppDetailsEffects;
