"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgExceptionsDeleteConfirmModalCloseAction = exports.SwgExceptionsDeleteConfirmModalOpenAction = exports.SwgExceptionsSidebarCloseAction = exports.SwgExceptionsSidebarOpenAction = exports.SwgExceptionsSidebarModalActions = void 0;
var SwgExceptionsSidebarModalActions;
(function (SwgExceptionsSidebarModalActions) {
    SwgExceptionsSidebarModalActions["SWG_EXCEPTIONS_SIDEBAR_OPEN"] = "[SwgExceptions] Open Exceptions Sidebar";
    SwgExceptionsSidebarModalActions["SWG_EXCEPTIONS_SIDEBAR_CLOSE"] = "[SwgExceptions] Close Exceptions Sidebar";
    SwgExceptionsSidebarModalActions["SWG_EXCEPTIONS_DELETE_CONFIRM_MODAL_OPEN"] = "[SwgExceptions] Open Exceptions Delete Confirm Modal";
    SwgExceptionsSidebarModalActions["SWG_EXCEPTIONS_DELETE_CONFIRM_MODAL_CLOSE"] = "[SwgExceptions] Close Exceptions Delete Confirm Modal";
})(SwgExceptionsSidebarModalActions = exports.SwgExceptionsSidebarModalActions || (exports.SwgExceptionsSidebarModalActions = {}));
class SwgExceptionsSidebarOpenAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgExceptionsSidebarModalActions.SWG_EXCEPTIONS_SIDEBAR_OPEN;
    }
}
exports.SwgExceptionsSidebarOpenAction = SwgExceptionsSidebarOpenAction;
class SwgExceptionsSidebarCloseAction {
    constructor() {
        this.type = SwgExceptionsSidebarModalActions.SWG_EXCEPTIONS_SIDEBAR_CLOSE;
    }
}
exports.SwgExceptionsSidebarCloseAction = SwgExceptionsSidebarCloseAction;
class SwgExceptionsDeleteConfirmModalOpenAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgExceptionsSidebarModalActions.SWG_EXCEPTIONS_DELETE_CONFIRM_MODAL_OPEN;
    }
}
exports.SwgExceptionsDeleteConfirmModalOpenAction = SwgExceptionsDeleteConfirmModalOpenAction;
class SwgExceptionsDeleteConfirmModalCloseAction {
    constructor() {
        this.type = SwgExceptionsSidebarModalActions.SWG_EXCEPTIONS_DELETE_CONFIRM_MODAL_CLOSE;
    }
}
exports.SwgExceptionsDeleteConfirmModalCloseAction = SwgExceptionsDeleteConfirmModalCloseAction;
