"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetGatewayApplicationProductListSuccessAction = exports.GetGatewayApplicationProductListAction = exports.GetGatewayApplicationRoleListSuccessAction = exports.GetGatewayApplicationRoleListWithHeaderAction = exports.GetGatewayApplicationRoleListAction = exports.CloseGatewayApplicationAction = exports.UpdateGatewayApplicationSuccessAction = exports.UpdateGatewayApplicationAction = exports.CreateGatewayApplicationSuccessAction = exports.CreateGatewayApplicationAction = exports.InputGatewayApplicationStatusAction = exports.InputGatewayApplicationSettingsAction = exports.InputGatewayApplicationDetailsAction = exports.InputGatewayApplicationAcceptTermsAction = exports.GatewayActionTypes = void 0;
var GatewayActionTypes;
(function (GatewayActionTypes) {
    GatewayActionTypes["InputGatewayApplicationAcceptTerms"] = "[ApiApplication/ApiGateway/Gateway] Input Gateway Application Accept Legal Terms";
    GatewayActionTypes["InputGatewayApplicationDetails"] = "[ApiApplication/ApiGateway/Gateway] Input Gateway Application Details";
    GatewayActionTypes["InputGatewayApplicationSettings"] = "[ApiApplication/ApiGateway/Gateway] Input Gateway Application Settings";
    GatewayActionTypes["InputGatewayApplicationStatus"] = "[ApiApplication/ApiGateway/Gateway] Input Gateway Application Status";
    GatewayActionTypes["CreateGatewayApplication"] = "[ApiApplication/ApiGateway/Gateway] Create Gateway Application";
    GatewayActionTypes["CreateGatewayApplicationSuccess"] = "[ApiApplication/ApiGateway/Gateway] Create Gateway Application Success";
    GatewayActionTypes["UpdateGatewayApplication"] = "[ApiApplication/ApiGateway/Gateway] Update Gateway Application";
    GatewayActionTypes["UpdateGatewayApplicationSuccess"] = "[ApiApplication/ApiGateway/Gateway] Update Gateway Application Success";
    GatewayActionTypes["CloseGatewayApplication"] = "[ApiApplication/ApiGateway/Gateway] Close Gateway Application";
    GatewayActionTypes["GetGatewayApplicationRoleList"] = "[ApiApplication/ApiGateway/Gateway] Get Gateway Application Role List";
    GatewayActionTypes["GetGatewayApplicationRoleListWithHeader"] = "[ApiApplication/ApiGateway/Gateway] Get Gateway Application Role List With Header";
    GatewayActionTypes["GetGatewayApplicationRoleListSuccess"] = "[ApiApplication/ApiGateway/Gateway] Get Gateway Application Role List Success";
    GatewayActionTypes["GetGatewayApplicationProductList"] = "[ApiApplication/ApiGateway/Gateway] Get Gateway Application Product List";
    GatewayActionTypes["GetGatewayApplicationProductListSuccess"] = "[ApiApplication/ApiGateway/Gateway] Get Gateway Application Product List Success";
})(GatewayActionTypes = exports.GatewayActionTypes || (exports.GatewayActionTypes = {}));
class InputGatewayApplicationAcceptTermsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = GatewayActionTypes.InputGatewayApplicationAcceptTerms;
    }
}
exports.InputGatewayApplicationAcceptTermsAction = InputGatewayApplicationAcceptTermsAction;
class InputGatewayApplicationDetailsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = GatewayActionTypes.InputGatewayApplicationDetails;
    }
}
exports.InputGatewayApplicationDetailsAction = InputGatewayApplicationDetailsAction;
class InputGatewayApplicationSettingsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = GatewayActionTypes.InputGatewayApplicationSettings;
    }
}
exports.InputGatewayApplicationSettingsAction = InputGatewayApplicationSettingsAction;
class InputGatewayApplicationStatusAction {
    constructor(payload) {
        this.payload = payload;
        this.type = GatewayActionTypes.InputGatewayApplicationStatus;
    }
}
exports.InputGatewayApplicationStatusAction = InputGatewayApplicationStatusAction;
class CreateGatewayApplicationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = GatewayActionTypes.CreateGatewayApplication;
    }
}
exports.CreateGatewayApplicationAction = CreateGatewayApplicationAction;
class CreateGatewayApplicationSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = GatewayActionTypes.CreateGatewayApplicationSuccess;
    }
}
exports.CreateGatewayApplicationSuccessAction = CreateGatewayApplicationSuccessAction;
class UpdateGatewayApplicationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = GatewayActionTypes.UpdateGatewayApplication;
    }
}
exports.UpdateGatewayApplicationAction = UpdateGatewayApplicationAction;
class UpdateGatewayApplicationSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = GatewayActionTypes.UpdateGatewayApplicationSuccess;
    }
}
exports.UpdateGatewayApplicationSuccessAction = UpdateGatewayApplicationSuccessAction;
class CloseGatewayApplicationAction {
    constructor() {
        this.type = GatewayActionTypes.CloseGatewayApplication;
    }
}
exports.CloseGatewayApplicationAction = CloseGatewayApplicationAction;
class GetGatewayApplicationRoleListAction {
    constructor() {
        this.type = GatewayActionTypes.GetGatewayApplicationRoleList;
    }
}
exports.GetGatewayApplicationRoleListAction = GetGatewayApplicationRoleListAction;
class GetGatewayApplicationRoleListWithHeaderAction {
    constructor(attachHeader = false) {
        this.attachHeader = attachHeader;
        this.type = GatewayActionTypes.GetGatewayApplicationRoleListWithHeader;
    }
}
exports.GetGatewayApplicationRoleListWithHeaderAction = GetGatewayApplicationRoleListWithHeaderAction;
class GetGatewayApplicationRoleListSuccessAction {
    constructor(data) {
        this.data = data;
        this.type = GatewayActionTypes.GetGatewayApplicationRoleListSuccess;
    }
}
exports.GetGatewayApplicationRoleListSuccessAction = GetGatewayApplicationRoleListSuccessAction;
class GetGatewayApplicationProductListAction {
    constructor() {
        this.type = GatewayActionTypes.GetGatewayApplicationProductList;
    }
}
exports.GetGatewayApplicationProductListAction = GetGatewayApplicationProductListAction;
class GetGatewayApplicationProductListSuccessAction {
    constructor(data) {
        this.data = data;
        this.type = GatewayActionTypes.GetGatewayApplicationProductListSuccess;
    }
}
exports.GetGatewayApplicationProductListSuccessAction = GetGatewayApplicationProductListSuccessAction;
