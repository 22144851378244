"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SessionEffects = void 0;
const effects_1 = require("@ngrx/effects");
const operators_1 = require("rxjs/operators");
const appActions = require("../../../core/actions/app.actions");
class SessionEffects {
    constructor(actions$, cookieService) {
        this.actions$ = actions$;
        this.cookieService = cookieService;
        // TODO: set to appActions.LOGOUT_SUCCESS when fix is available
        this.getLogs$ = this.actions$.pipe(effects_1.ofType(appActions.LOGOUT), operators_1.tap(() => {
            this.cookieService.delete('mc-rem-disabled-alert');
            this.cookieService.delete('mc-rem-not-set-alert');
        }));
    }
}
__decorate([
    effects_1.Effect({ dispatch: false })
], SessionEffects.prototype, "getLogs$", void 0);
exports.SessionEffects = SessionEffects;
