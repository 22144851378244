"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDisplayableThreatDetails = exports.hasGenericError = exports.isLoadingMessageWithImages = exports.isLoadingMessageDetails = exports.getMessageDetails = exports.reducer = exports.initialState = void 0;
const messageDetailsAction = require("../../actions/message-details/message-details.actions");
exports.initialState = {
    message: null,
    isLoading: true,
    isLoadingImages: false,
    genericError: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case messageDetailsAction.GET_MESSAGE_DETAILS:
            return Object.assign(Object.assign({}, state), { genericError: false, isLoading: true, isLoadingImages: false, message: {
                    id: action.row.id,
                    subject: action.row.subject
                } });
        case messageDetailsAction.GET_MESSAGE_DETAILS_WITH_IMAGES:
            return Object.assign(Object.assign({}, state), { genericError: false, isLoading: false, isLoadingImages: true });
        case messageDetailsAction.GET_MESSAGE_DETAILS_SUCCESS:
            return Object.assign(Object.assign({}, state), { genericError: false, isLoading: false, isLoadingImages: false, message: Object.assign(Object.assign({}, state.message), action.message) });
        case messageDetailsAction.GET_MESSAGE_DETAILS_WITH_IMAGES_SUCCESS:
            return Object.assign(Object.assign({}, state), { genericError: false, isLoading: false, isLoadingImages: false, message: Object.assign(Object.assign({}, state.message), { htmlBody: action.message.htmlBody }) });
        case messageDetailsAction.GET_MESSAGE_DETAILS_FAIL:
            return Object.assign(Object.assign({}, state), { genericError: true, isLoading: true });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getMessageDetails = (state) => state.message;
exports.getMessageDetails = getMessageDetails;
const isLoadingMessageDetails = (state) => state.isLoading;
exports.isLoadingMessageDetails = isLoadingMessageDetails;
const isLoadingMessageWithImages = (state) => state.isLoadingImages;
exports.isLoadingMessageWithImages = isLoadingMessageWithImages;
const hasGenericError = (state) => state.genericError;
exports.hasGenericError = hasGenericError;
const getDisplayableThreatDetails = (state) => {
    if (!state.message.attachments) {
        return [];
    }
    return state.message.attachments.map(attachment => {
        const currentThreatDetail = state.message.threatDetails.find(detail => attachment.sha256 === detail.hash);
        return Object.assign(Object.assign({}, attachment), currentThreatDetail);
    });
};
exports.getDisplayableThreatDetails = getDisplayableThreatDetails;
