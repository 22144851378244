"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TreeApiService = void 0;
class TreeApiService {
    constructor(httpService) {
        this.httpService = httpService;
    }
    getMailboxFolders(payload) {
        return this.httpService.post('/api/archive/folder/get-mailbox-folders', this.preparePayload(payload));
    }
    getMailboxFoldersPerLevel(payload) {
        return this.httpService.post('/api/archive/folder/get-mailbox-folders-per-level', this.preparePayload(payload));
    }
    findMailboxFolders(payload) {
        return this.httpService.post('/api/archive/folder/find-mailbox-folders', this.preparePayload(payload));
    }
    getMailboxFolderEmails(payload) {
        return this.httpService.post('/api/archive/folder/get-mailbox-folder-emails', this.preparePayload(payload));
    }
    searchMailboxFolderEmails(payload) {
        return this.httpService.post('/api/archive/search', this.preparePayload(payload));
    }
    getMailboxFolderContacts(payload) {
        return this.httpService.post('/api/archive/folder/get-mailbox-folder-contacts', this.preparePayload(payload));
    }
    searchMailboxFolderContacts(payload) {
        return this.httpService.post('/api/archive/folder/find-mailbox-folder-contacts', this.preparePayload(payload));
    }
    getMailboxFolderCalendars(payload) {
        return this.httpService.post('/api/archive/folder/get-mailbox-folder-calendar-items', this.preparePayload(payload));
    }
    searchMailboxFolderCalendars(payload) {
        return this.httpService.post('/api/archive/folder/find-mailbox-folder-calendar-items', this.preparePayload(payload));
    }
    getOneDriveFolders(payload) {
        return this.httpService.post('/api/onedrive/get-folders', this.preparePayload(payload));
    }
    getOneDriveFoldersFiles(payload) {
        return this.httpService.post('/api/onedrive/get-folders-files', this.preparePayload(payload));
    }
    preparePayload(payload) {
        return payload && (!payload['meta'] || !payload['meta'].pagination)
            ? Object.assign(Object.assign({}, payload), { ['meta']: {
                    pagination: {
                        pageSize: 100
                    }
                } }) : payload;
    }
}
exports.TreeApiService = TreeApiService;
