'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const _ = require("lodash");
/**
 * Created by psambandam on 12/02/16.
 */
document.createElement('mc-services-status');
angular.module('services-status.directive', [])
    .directive('mcServicesStatus', ['serviceStatusService', 'capabilitiesService', function (serviceStatusService, capabilitiesService) {
        return {
            restrict: 'E',
            templateUrl: 'components/service-status/service-status.tpl.html',
            scope: {
                mcTitle: '@',
                getStatus: '&',
                viewAllServicesLink: '@',
                viewAllServicesLabel: '@',
                errorMessage: '@',
                capabilities: '@'
            },
            link($scope) {
                $scope.status = 0;
                $scope.isLoading = true;
                $scope.hasError = false;
                $scope.subscriptionError = false;
                if (angular.isDefined($scope.capabilities) && !capabilitiesService.evalCapabilitiesExpression($scope.capabilities)) {
                    $scope.isLoading = false;
                    $scope.subscriptionError = true;
                    return;
                }
                if (angular.isDefined($scope.errorMessage) && !_.isEmpty($scope.errorMessage)) {
                    $scope.hasError = true;
                }
                $scope.getStatus().then(function (data) {
                    $scope.status = serviceStatusService.getServicestatus(data);
                    $scope.isLoading = false;
                });
            }
        };
    }]);
