"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSuccess = exports.getInProgress = exports.reducer = exports.initialState = void 0;
const logsActions = require("../../actions/logs.actions");
exports.initialState = {
    adminAction: {
        action: null,
        inProgress: false,
        success: false
    }
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case logsActions.MepLogsActionsEnums.MEP_LOGS_OPEN_EXPORT_SIDEBAR:
            return Object.assign({}, state);
        case logsActions.MepLogsActionsEnums.MEP_LOG_ADMIN_LOG_ACTION:
            return Object.assign(Object.assign({}, state), { adminAction: Object.assign(Object.assign({}, state.adminAction), { action: action.payload.action, inProgress: true, success: false }) });
        case logsActions.MepLogsActionsEnums.MEP_LOG_ADMIN_LOG_ACTION_SUCCESS:
            return Object.assign(Object.assign({}, state), { adminAction: Object.assign(Object.assign({}, state.adminAction), { success: true, inProgress: false }) });
        case logsActions.MepLogsActionsEnums.MEP_LOG_ADMIN_LOG_ACTION_FAILURE:
            return Object.assign(Object.assign({}, state), { adminAction: Object.assign(Object.assign({}, state.adminAction), { success: false, inProgress: false }) });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getInProgress = (state) => state.adminAction.inProgress;
exports.getInProgress = getInProgress;
const isSuccess = (state) => state.adminAction.success;
exports.isSuccess = isSuccess;
