<mc-collapsable-panel header="{{'$I18N_MESSAGE_CENTER_MESSAGE_ATTACHMENTS.TITLE' | translate}} ({{attachments.length}})"
                      [isExpanded]="true"
                      [class.mc-show-less]="showLess">
  <mc-table translatePrefix="$I18N_MESSAGE_CENTER_MESSAGE_ATTACHMENTS.TABLE"
            [columns]="['filename','size','right-column']"
            [data]="attachments"
            [isLoading]="false">
    <mc-column key="filename"></mc-column>
    <mc-column-size key="size"></mc-column-size>
    <mc-column-actions key="right-column">
      <mc-row-actions *mcRowActions="let row">
        <li mcRowAction="$I18N_MESSAGE_CENTER_MESSAGE_ATTACHMENTS.DOWNLOAD" (click)="downloadFile.emit(row.id)"></li>
      </mc-row-actions>
    </mc-column-actions>
  </mc-table>

  <a *ngIf="showLess && attachments.length > 3"
     class="mc-show-more-show-less-attachments-button"
     (click)="showLess=false"
     data-test="showMore">
    <span>{{ '$I18N_MESSAGE_CENTER_MESSAGE_ATTACHMENTS.SHOW_MORE' | translate }}</span>
    <i class="far fa-angle-down"></i>
  </a>
  <a *ngIf="!showLess"
     class="mc-show-more-show-less-attachments-button"
     (click)="showLess=true"
     data-test="showLess">
    <span>{{ '$I18N_MESSAGE_CENTER_MESSAGE_ATTACHMENTS.SHOW_LESS' | translate }}</span>
    <i class="far fa-angle-up"></i>
  </a>
</mc-collapsable-panel>
