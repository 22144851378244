"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListComponent = void 0;
const core_1 = require("@angular/core");
const definition_list_table_static_values_1 = require("./definition-list-table.static-values");
const table_store_1 = require("@mimecast-ui/table-store");
const createAction = require("../../actions/create-definition.actions");
const listAction = require("../../actions/definition.actions");
class ListComponent extends table_store_1.TableBaseComponent {
    constructor(tableStoreStore, stateService, capsService) {
        super(tableStoreStore, 'definitionsListTable');
        this.stateService = stateService;
        this.capsService = capsService;
        this.canModifyPolicyExpression = 'Permission.POLICIES_EDIT';
        this.searchSections = definition_list_table_static_values_1.tableSectionSearch;
        this.selectedRows = [];
        this.onPaginatorChanged = new core_1.EventEmitter();
        this.onSearchFilterChanged = new core_1.EventEmitter();
        this.onRowClicked = new core_1.EventEmitter();
        this.onDefinitionDelete = new core_1.EventEmitter();
        this.columnsToStartHidden = [...definition_list_table_static_values_1.tableColumnsStartHidden];
        this.columnsAlwaysVisible = [...definition_list_table_static_values_1.tableColumnsAlwaysVisible];
        this.htmlExt = 'HTML';
        this.adminAction = 'Hold';
        this.htmlExtMulti = 'HTML Multi-tab';
        this.setupSubscriptions();
    }
    ngOnInit() {
        this.checkIEPPackageAndSetTableColumns();
        super.ngOnInit();
    }
    setupSubscriptions() {
        this.tableDataSubscription = this.tableData$.subscribe((data) => {
            if (data && data.length) {
                this.tableData = data[0]['definitions'];
            }
        });
        this.tableMetaDataSubscription = this.metaData$.subscribe(data => {
            if (data && data.pagination) {
                this.metadata = {
                    pagination: Object.assign(Object.assign({}, data.pagination), { totalRows: data.pagination.totalCount })
                };
            }
        });
        this.capsCreateEditDuplicateDeleteSubscription = this.capsService
            .evalCapabilitiesExpression(this.canModifyPolicyExpression)
            .subscribe(canDoActions => {
            if (canDoActions) {
                this.canModifyPolicy = canDoActions;
            }
        });
    }
    changePaginator(pagination) {
        this.onPaginatorChanged.emit(pagination);
        super.onPaginationChange(pagination);
    }
    onSearchDescription(data) {
        this.dispatchFilterAction({ description: data.searchQuery });
    }
    getDefinitionConfigurationType(type, inboundVal) {
        return inboundVal ? '$I18N_CONFIGURATION_OPTION_' + type.toUpperCase() : 'N/A';
    }
    checkIEPPackageAndSetTableColumns() {
        if (this.hasIEPPackage || this.isAAAOrFAAUser) {
            this.columns = definition_list_table_static_values_1.columnList.filter(column => {
                return (column !== 'safeSpreadsheetFormat' &&
                    column !== 'safeDocFormat' &&
                    column !== 'preEmptiveFailureAction' &&
                    column !== 'notifyOnMalicious');
            });
        }
        else {
            this.columns = definition_list_table_static_values_1.columnList.filter(column => {
                return column !== 'checkOutbound' && column !== 'checkJournal' && column !== 'checkInbound';
            });
        }
    }
    createDefinition() {
        this.store.dispatch(new listAction.ClearDefinitionDetailsAction());
        this.store.dispatch(new createAction.ApDefinitionNavigateToCreateDefinition());
    }
    editDefinition(row) {
        this.store.dispatch(new listAction.GetDefinitionDetailsAction({ id: row.id }));
        this.store.dispatch(new createAction.ApDefinitionNavigateToCreateDefinition());
    }
    duplicateDefinition(row) {
        this.store.dispatch(new listAction.GetDuplicateDefinitionDetailsAction({ id: row.id }));
        this.store.dispatch(new createAction.ApDefinitionNavigateToCreateDefinition());
    }
    openTrustedSender() {
        this.stateService.$state.go('legacy.attachprotect-view-trusted-senders');
    }
    clickRow(row) {
        this.onRowClick(row);
        this.onRowClicked.emit(row);
    }
    deleteDefinition(definition) {
        this.onDefinitionDelete.emit(definition);
    }
    ngOnDestroy() {
        this.tableDataSubscription.unsubscribe();
        this.tableMetaDataSubscription.unsubscribe();
        this.capsCreateEditDuplicateDeleteSubscription.unsubscribe();
        super.ngOnDestroy();
    }
}
exports.ListComponent = ListComponent;
