
    <mc-layout-list-extra-container
      keyTitle="$I18N_SERVICES_IM_SYNC_DASHBOARD.PAGE_TITLE"
      keyDescription="$I18N_SERVICES_IM_SYNC_DASHBOARD.PAGE_DESCRIPTION"
    >
      <mc-body-container>
        <mc-im-sync-table
          [tableData]="tableData$ | async"
          [metaData]="metaData$ | async"
          [isLoading]="isLoading$ | async"
          (onPaginationChange)="onPaginationChange($event)"
          (onFilterChange)="onFilterChange($event)"
          (onCreateTask)="onCreateImTask()"
          (viewImTask)="viewImTask($event)"
          (updateImTask)="updateImTask($event)"
          (updateImTaskStatus)="updateImTaskStatus($event)"
        >
        </mc-im-sync-table>
      </mc-body-container>
    </mc-layout-list-extra-container>
  