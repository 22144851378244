"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.scheduleTypes = void 0;
exports.scheduleTypes = [
    {
        value: 'daily',
        label: '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SETTINGS_STEP.SCHEDULE_DAILY'
    },
    {
        value: 'weekly',
        label: '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SETTINGS_STEP.SCHEDULE_WEEKLY'
    },
    {
        value: 'monthly',
        label: '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SETTINGS_STEP.SCHEDULE_MONTHLY'
    }
];
