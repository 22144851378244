"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SystemMessagesTabComponent = void 0;
const actions = require("../../actions/system-messages.action");
const reducers = require("../../reducers/system-messages");
class SystemMessagesTabComponent {
    constructor(store) {
        this.store = store;
    }
    ngOnInit() {
        this.items$ = this.store.select(reducers.getSystemMessagesItems);
        this.totalCount$ = this.store.select(reducers.getSystemMessagesTotalCount);
        this.selectedRow$ = this.store.select(reducers.getSystemMessagesSelectedRow);
        this.isLoading$ = this.store.select(reducers.isSystemMessagesLoading);
        this.metadata$ = this.store.select(reducers.getSystemMessagesMetadata);
        this.store.dispatch(new actions.SystemMessagesRequestAction({ search: {} }));
    }
    search(search) {
        this.store.dispatch(new actions.SystemMessagesRequestAction({ search }));
    }
    dateFilterChanged(range) {
        this.store.dispatch(new actions.SystemMessagesRequestAction({ search: Object.assign({}, range.range) }));
    }
    columnFilterChanged(filter) {
        this.store.dispatch(new actions.SystemMessagesRequestAction({ search: filter }));
    }
    paginatorChanged(page) {
        this.store.dispatch(new actions.SystemMessagesPaginationAction(page));
    }
    routeRecalculated(messages) {
        if (messages.length > 0) {
            // @ts-ignore
            this.store.dispatch(new actions.SystemRecalculateRouteAction(messages));
        }
    }
    messagesRejected(messages) {
        if (messages.length > 0) {
            this.store.dispatch(new actions.SystemMessagesRejectEmailsAction(messages));
        }
    }
    messagesRetried(messages) {
        if (messages.length > 0) {
            this.store.dispatch(new actions.SystemMessagesRetryEmailsAction(messages));
        }
    }
    messagesEarlyBounced(messages) {
        if (messages.length > 0) {
            this.store.dispatch(new actions.SystemMessagesEarlyBounceEmailsAction(messages));
        }
    }
    exportResults(tableColumns) {
        this.store.dispatch(new actions.SystemMessagesExportAction(tableColumns));
    }
}
exports.SystemMessagesTabComponent = SystemMessagesTabComponent;
