"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetGroupsEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const getGroupAction = require("../actions/get-group.actions");
const getGroupsAction = require("../actions/get-groups.actions");
class GetGroupsEffects {
    constructor(actions$, selectGroupsServices, notificationService, translation) {
        this.actions$ = actions$;
        this.selectGroupsServices = selectGroupsServices;
        this.notificationService = notificationService;
        this.translation = translation;
        this.getGroupCloud$ = effects_1.createEffect(() => this.actions$.pipe(effects_1.ofType(getGroupsAction.GET_GROUPS_CLOUD), operators_1.switchMap((action) => {
            const config = action.payload;
            const successFn = getGroupsAction.GetGroupsCloudSuccess;
            const failFn = getGroupsAction.GetGroupsFail;
            return this.getGroups(config, successFn, failFn);
        })));
        this.getGroupLDAP$ = effects_1.createEffect(() => this.actions$.pipe(effects_1.ofType(getGroupsAction.GET_GROUPS_LDAP), operators_1.switchMap((action) => {
            const config = action.payload;
            const successFn = getGroupsAction.GetGroupsLDAPSuccess;
            const failFn = getGroupsAction.GetGroupsFail;
            return this.getGroups(config, successFn, failFn);
        })));
        this.getGroup$ = effects_1.createEffect(() => this.actions$.pipe(effects_1.ofType(getGroupAction.GET_GROUP), operators_1.switchMap((action) => {
            const config = action.payload;
            const successFn = getGroupAction.GetGroupSuccess;
            const failFn = getGroupAction.GetGroupFail;
            return this.getGroups(config, successFn, failFn);
        })));
    }
    getGroups(config, successFn, failFn) {
        return this.selectGroupsServices.getGroup(config).pipe(operators_1.map((response) => new successFn(response)), operators_1.catchError(() => {
            return rxjs_1.of(new failFn()).pipe(operators_1.tap(() => {
                this.notificationService.error({
                    duration: 0,
                    msg: this.translation.instant('$I18N_COMMON_TEXT_LINE_GLOBAL_ERROR_MESSAGE')
                });
            }));
        }));
    }
}
exports.GetGroupsEffects = GetGroupsEffects;
