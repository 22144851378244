"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.replaceCidsWithApiLinks = exports.replaceImageLinks = exports.getHtmlWithoutImages = exports.EmailHtmlPanelService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
class EmailHtmlPanelService {
    constructor(archiveService) {
        this.archiveService = archiveService;
        this.html$ = new rxjs_1.BehaviorSubject(null);
    }
    getProcessedHtml(originalHtml, attachments = [], imageApiUrl) {
        this.originalHtml = originalHtml;
        this.attachments = attachments;
        this.imageApiUrl = imageApiUrl;
        const noImagesHtml = getHtmlWithoutImages(originalHtml);
        if (noImagesHtml) {
            this.html$.next({
                content: noImagesHtml,
                isDisplayingImages: false,
                hasImages: true
            });
        }
        else {
            this.html$.next({ content: originalHtml, isDisplayingImages: false, hasImages: false });
        }
        return this.html$;
    }
    displayImages() {
        return replaceCidsWithApiLinks(this.originalHtml, this.attachments, this.imageApiUrl, this.archiveService).pipe(operators_1.first(), operators_1.tap(html => {
            this.html$.next({ content: html, isDisplayingImages: true, hasImages: true });
        }));
    }
    getPrintableHtml() {
        return this.html$.pipe(operators_1.switchMap((content) => {
            if (content.hasImages && content.isDisplayingImages) {
                return replaceCidsWithApiLinks(this.originalHtml, this.attachments, this.imageApiUrl, this.archiveService).pipe(operators_1.first());
            }
            else {
                return rxjs_1.of(content.content);
            }
        }));
    }
}
exports.EmailHtmlPanelService = EmailHtmlPanelService;
/**
 * returns the same html passed as parameter but without any images it containes
 * either by img tag or a css property with a url
 * if images has no images returns null used to hide the display images button
 *
 * @param html
 * @returns
 */
function getHtmlWithoutImages(html) {
    let hasImages = false;
    const imageTagRegex = /<\s*img[^>]+>/gi;
    html = html.replace(imageTagRegex, () => {
        hasImages = true;
        return '';
    });
    const styleTagRegex = /<\s*style[^>]*>([^<]*)<\s*\/\s*style\s*>/gi;
    html = html.replace(styleTagRegex, tag => {
        if (tag.match(/\burl\s*\(/gi)) {
            hasImages = true;
        }
        return '';
    });
    const styleAttributeRegex = /(\s\bstyle\s*=\s*['"])[^'"]+/gi;
    html = html.replace(styleAttributeRegex, (attribute, firstPart) => {
        if (attribute.match(/\burl\s*\(/gi)) {
            hasImages = true;
            return firstPart;
        }
        return attribute;
    });
    return hasImages ? html : null;
}
exports.getHtmlWithoutImages = getHtmlWithoutImages;
/**
 * this will replace all api links to embedded images attached to the email with
 * links to a proxy that have a serialized session token that allows it to render
 * the images, if the html is rendered in less than 3 min, without this the normal api
 * links wont work because they require authorization headers in the get call
 *
 * @param html
 * @param archiveService
 * @param doc
 * @returns
 */
function replaceImageLinks(html, archiveService, doc = document) {
    return archiveService.getFileUrl('/api/fileDownload').pipe(operators_1.map((newFileUrl) => {
        const sessionParams = newFileUrl.split('?')[1];
        const root = doc.createElement('html');
        root.innerHTML = html;
        // @ts-ignore
        root.querySelectorAll('img').forEach((e) => {
            const src = e.getAttribute('src');
            const result = src.match(/https:\/\/[\w\-]+\.mimecast\.(?:com|lan)\/api\/(.+$)/);
            if (result) {
                const newSrc = '/api/' + result[1] + '?' + sessionParams;
                e.setAttribute('src', newSrc);
            }
        });
        // @ts-ignore
        root.querySelectorAll('[style]').forEach((e) => {
            const style = e.getAttribute('style');
            const newStyle = style.replace(/url\s*\(\s*https:\/\/[\w\-]+\.mimecast\.(?:com|lan)\/api\/([^)]+)\)/gi, (match, urlPath) => {
                return 'url(/api/' + urlPath + '?' + sessionParams + ')';
            });
            if (style !== newStyle) {
                e.setAttribute('style', newStyle);
            }
        });
        return root.innerHTML;
    }));
}
exports.replaceImageLinks = replaceImageLinks;
function replaceCidsWithApiLinks(html, attachments, imageApiUrl, archiveService) {
    const cidsMap = attachments.reduce((currMap, file) => {
        if (file.contentId && file.contentType.toLowerCase().indexOf('image') !== -1) {
            // the contentId returned by attachment contains a pair of angle bracket (which is incorrect)
            // This is simply just a workaround of this issue
            const cid = file.contentId.toLowerCase().slice(1, -1);
            currMap[cid] = file;
        }
        return currMap;
    }, {});
    return archiveService.getFileUrl('/api/fileDownload').pipe(operators_1.map((newFileUrl) => {
        const sessionParams = newFileUrl.split('?')[1];
        // find images with cids urls and replace them with api links with tempSessions
        const imageTagRegex = /(<\s*img[^>]+src\s*=["'])cid:([^'"]+)/gi;
        html = html.replace(imageTagRegex, (match, tag, cid) => {
            const newSrc = '/auth/api' + imageApiUrl + '/' + cidsMap[cid.toLowerCase()].id + '?' + sessionParams;
            return tag + newSrc;
        });
        return html;
    }));
}
exports.replaceCidsWithApiLinks = replaceCidsWithApiLinks;
