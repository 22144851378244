"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Node = void 0;
class Node {
    constructor(id, name, lastChangeId, lastChangeType, modified, parentId, deleted) {
        this.id = id;
        this.name = name;
        this.parentId = parentId;
        this.lastChangeId = lastChangeId;
        this.lastChangeType = lastChangeType;
        this.modified = modified;
        this.deleted = deleted;
    }
    getId() {
        return this.id;
    }
    getName() {
        return this.name;
    }
    getLastChangeId() {
        return this.lastChangeId;
    }
    getLastChangeType() {
        return this.lastChangeType;
    }
    getModified() {
        return this.modified;
    }
    getParent() {
        return this.parentId;
    }
    isDeleted() {
        return this.deleted;
    }
}
exports.Node = Node;
