"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateBiPolicyComponent = void 0;
const core_1 = require("@angular/core");
const models_1 = require("../../../../../swg/policies/models");
const user_location_sidebar_actions_1 = require("../../../../../components/user-location-selector/actions/user-location-sidebar.actions");
class CreateBiPolicyComponent {
    constructor(stateService, store, changeDetector) {
        this.stateService = stateService;
        this.store = store;
        this.changeDetector = changeDetector;
        this.currentStep = 1;
        this.isNgInitDone = false;
        this.updatePolicyDescWiz = new core_1.EventEmitter();
        this.updatePolicySettingsWiz = new core_1.EventEmitter();
        this.createPolicyEvent = new core_1.EventEmitter();
        this.openAppliesToSidePanelWiz = new core_1.EventEmitter();
        this.removeAffectedUsersWiz = new core_1.EventEmitter();
    }
    ngAfterViewInit() {
        this.changeDetector.detectChanges();
    }
    ngOnInit() {
        this.isNgInitDone = true;
    }
    goToStep(event) {
        this.currentStep = event;
    }
    cancel() {
        this.stateService.$state.go('bi-policy-list');
    }
    isStepValid() {
        if (this.currentStep === 1) {
            return this.policyDetailsStep.isValid();
        }
        if (this.currentStep === 3) {
            return this.policyAffectedUsersStep.isValid();
        }
        return true;
    }
    getSaveButton() {
        return this.swgPolicy.policyDesc.id
            ? '$I18N_SWG_CREATE_POLICY_SAVE_AND_CLOSE'
            : '$I18N_SWG_CREATE_POLICY_ADD_POLICY';
    }
    openCall(value) {
        this.store.dispatch(new user_location_sidebar_actions_1.UserLocationAppliesToClose());
        this.store.dispatch(new user_location_sidebar_actions_1.UserLocationAppliesToOpen(value));
    }
    isEditFlow() {
        return this.wizardFlowType !== models_1.SwgPolicyWizardFlowType.CREATE;
    }
    hasDescription(policy) {
        return !!policy.policyDesc.description.length;
    }
    hasValidAppliesTo(policy) {
        return (policy.everyOne ||
            policy.affectedUsers.filter(target => target.condition !== models_1.SwgAffectedUsersEnum.EVERYONE)
                .length > 0);
    }
    isValidPolicy(policy) {
        return this.hasDescription(policy) && this.hasValidAppliesTo(policy);
    }
    getPageTitle() {
        if (models_1.SwgPolicyWizardFlowType.CREATE === this.wizardFlowType) {
            return '$I18N_SWG_CREATE_POLICY_CREATE_A_POLICY';
        }
        else if (models_1.SwgPolicyWizardFlowType.EDIT === this.wizardFlowType) {
            return '$18N_BI_POLICY_EDIT';
        }
    }
    getPageSubTitle(edit) {
        return edit ? '$I18N_SWG_EDIT_POLICY_DESC' : '';
    }
}
exports.CreateBiPolicyComponent = CreateBiPolicyComponent;
