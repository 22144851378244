"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Calendar = void 0;
const item_1 = require("app-new/components/tree/model/item");
class Calendar extends item_1.Item {
    constructor(subject, recurring, created, timezone, start, end, duration, organiser, allDay, recipients, hasAttachments, modified, size, id, name, lastChangeId, lastChangeType, parentId) {
        super(modified, size, id, name, lastChangeId, lastChangeType, parentId);
        this.subject = subject;
        this.recurring = recurring;
        this.created = created;
        this.timezone = timezone;
        this.start = start;
        this.end = end;
        this.duration = duration;
        this.organiser = organiser;
        this.allDay = allDay;
        this.recipients = recipients;
        this.hasAttachments = hasAttachments;
    }
    getSubject() {
        return this.subject;
    }
    isRecurring() {
        return this.recurring;
    }
    getCreated() {
        return this.created;
    }
    getTimezone() {
        return this.timezone;
    }
    getStart() {
        return this.start;
    }
    getEnd() {
        return this.end;
    }
    getDuration() {
        return this.duration;
    }
    getOrganiser() {
        return this.organiser;
    }
    isAllDay() {
        return this.allDay;
    }
    getRecipients() {
        return this.recipients;
    }
    isHasAttachments() {
        return this.hasAttachments;
    }
}
exports.Calendar = Calendar;
