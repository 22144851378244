"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForwardMessageResetAction = exports.ForwardMessageFailAction = exports.ForwardMessageSuccessAction = exports.ForwardMessageSendAction = exports.ForwardMessageAttachFileFailAction = exports.ForwardMessageAttachFileSuccessAction = exports.ForwardMessageAttachFileAction = exports.ForwardMessageCancelAction = exports.ForwardMessageOpenModalAction = exports.FORWARD_MESSAGE_RESET = exports.FORWARD_MESSAGE_FAIL = exports.FORWARD_MESSAGE_SUCCESS = exports.FORWARD_MESSAGE_SEND = exports.FORWARD_MESSAGE_ATTACH_FILE_FAIL = exports.FORWARD_MESSAGE_ATTACH_FILE_SUCCESS = exports.FORWARD_MESSAGE_ATTACH_FILE = exports.FORWARD_MESSAGE_CANCEL = exports.FORWARD_MESSAGE_OPEN_MODAL = void 0;
exports.FORWARD_MESSAGE_OPEN_MODAL = '[Message Center] Forward Message Open Modal';
exports.FORWARD_MESSAGE_CANCEL = '[Message Center] Forward Message Cancel';
exports.FORWARD_MESSAGE_ATTACH_FILE = '[Message Center] Forward Message Attach File';
exports.FORWARD_MESSAGE_ATTACH_FILE_SUCCESS = '[Message Center] Forward Message Attach File Success';
exports.FORWARD_MESSAGE_ATTACH_FILE_FAIL = '[Message Center] Forward Message Attach File Fail';
exports.FORWARD_MESSAGE_SEND = '[Message Center] Forward Message Send';
exports.FORWARD_MESSAGE_SUCCESS = '[Message Center] Forward Message Success';
exports.FORWARD_MESSAGE_FAIL = '[Message Center] Forward Message Fail';
exports.FORWARD_MESSAGE_RESET = '[Message Center] Forward Message Reset';
class ForwardMessageOpenModalAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.FORWARD_MESSAGE_OPEN_MODAL;
    }
}
exports.ForwardMessageOpenModalAction = ForwardMessageOpenModalAction;
class ForwardMessageCancelAction {
    constructor() {
        this.type = exports.FORWARD_MESSAGE_CANCEL;
    }
}
exports.ForwardMessageCancelAction = ForwardMessageCancelAction;
class ForwardMessageAttachFileAction {
    constructor() {
        this.type = exports.FORWARD_MESSAGE_ATTACH_FILE;
    }
}
exports.ForwardMessageAttachFileAction = ForwardMessageAttachFileAction;
class ForwardMessageAttachFileSuccessAction {
    constructor() {
        this.type = exports.FORWARD_MESSAGE_ATTACH_FILE_SUCCESS;
    }
}
exports.ForwardMessageAttachFileSuccessAction = ForwardMessageAttachFileSuccessAction;
class ForwardMessageAttachFileFailAction {
    constructor() {
        this.type = exports.FORWARD_MESSAGE_ATTACH_FILE_FAIL;
    }
}
exports.ForwardMessageAttachFileFailAction = ForwardMessageAttachFileFailAction;
class ForwardMessageSendAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.FORWARD_MESSAGE_SEND;
    }
}
exports.ForwardMessageSendAction = ForwardMessageSendAction;
class ForwardMessageSuccessAction {
    constructor() {
        this.type = exports.FORWARD_MESSAGE_SUCCESS;
    }
}
exports.ForwardMessageSuccessAction = ForwardMessageSuccessAction;
class ForwardMessageFailAction {
    constructor() {
        this.type = exports.FORWARD_MESSAGE_FAIL;
    }
}
exports.ForwardMessageFailAction = ForwardMessageFailAction;
class ForwardMessageResetAction {
    constructor() {
        this.type = exports.FORWARD_MESSAGE_RESET;
    }
}
exports.ForwardMessageResetAction = ForwardMessageResetAction;
