"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isAccessInvalid = exports.isLoaded = exports.isLoading = exports.getSession = exports.reducer = exports.initialState = exports.AppStatus = void 0;
const actions = require("../actions");
var AppStatus;
(function (AppStatus) {
    AppStatus[AppStatus["LOADING"] = 0] = "LOADING";
    AppStatus[AppStatus["LOADED"] = 1] = "LOADED";
    AppStatus[AppStatus["INVALID_ACCESS"] = 2] = "INVALID_ACCESS";
})(AppStatus = exports.AppStatus || (exports.AppStatus = {}));
exports.initialState = { status: AppStatus.LOADING };
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.session.LOAD_SUCCESS:
            return Object.assign(Object.assign({}, state), { session: action.payload });
        case actions.session.LOGOUT_SUCCESS:
        case actions.session.LOGOUT_FAIL:
            return Object.assign(Object.assign({}, state), { session: undefined });
        case actions.app.LOAD:
            return Object.assign(Object.assign({}, state), { status: AppStatus.LOADING });
        case actions.app.LOAD_SUCCESS:
            return Object.assign(Object.assign({}, state), { status: AppStatus.LOADED });
        case actions.app.INVALID_ACCESS:
            return Object.assign(Object.assign({}, state), { status: AppStatus.INVALID_ACCESS });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getSession = (state) => state.session;
exports.getSession = getSession;
const isLoading = (state) => state.status === AppStatus.LOADING;
exports.isLoading = isLoading;
const isLoaded = (state) => state.status === AppStatus.LOADED;
exports.isLoaded = isLoaded;
const isAccessInvalid = (state) => state.status === AppStatus.INVALID_ACCESS;
exports.isAccessInvalid = isAccessInvalid;
