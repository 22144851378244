"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const swg_block_page_settings_page_component_1 = require("./container/swg-block-page-settings-page.component");
angular
    .module('swgBlockPageSettings', [])
    .directive('mcSwgBlockPageSettings', static_1.downgradeComponent({
    component: swg_block_page_settings_page_component_1.SwgBlockPageSettingsPageComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider.state('secure-web-gateway-block-page-settings', {
            url: '/secure-web-gateway/block-page-settings',
            data: {
                capabilities: 'Permission.SWG_DASHBOARD_READ',
                isFixedLayout: false,
                breadcrumbs: [
                    {
                        label: '$I18N_MEGA_MENU_GROUP_SECURE_WEB_GATEWAY_LABEL'
                    },
                    {
                        label: '$I18N_MEGA_MENU_GROUP_SECURE_WEB_GATEWAY_CONFIGURE_POLICIES'
                    }
                ],
                checkProgress: false,
                tabReload: true,
                tabId: 'Swg-policies',
                tabTitle: '$I18N_SWG_BLOCK_PAGE_TITLE'
            },
            views: {
                main: {
                    template: '<mc-swg-block-page-settings></mc-swg-block-page-settings>'
                }
            }
        });
    }
]);
