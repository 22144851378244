"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeviceGroupsWizardComponent = void 0;
const core_1 = require("@angular/core");
const device_groups_models_1 = require("../../../models/device-groups.models");
class DeviceGroupsWizardComponent {
    constructor() {
        this.currentStep = 1;
        this.deviceListDisplayType = device_groups_models_1.DeviceListDisplayType;
        this.hasEditPermission = true;
        this.updateDeviceGroupDescr = new core_1.EventEmitter();
        this.filterDevicesInGroup = new core_1.EventEmitter();
        this.onPageChanged = new core_1.EventEmitter();
        this.onCreateDeviceGroup = new core_1.EventEmitter();
        this.onCancelWizard = new core_1.EventEmitter();
    }
    isEditFlow() {
        return this.deviceGroupWizardWizardFlowType !== device_groups_models_1.DeviceGroupWizardFlowType.CREATE;
    }
    goToStep(event) {
        this.currentStep = event;
    }
    getTitle() {
        if (!this.isEditFlow()) {
            return '$I18N_DEVICE_GROUPS_LIST.WIZARD.CREATE_HEADING';
        }
        else {
            return '$I18N_DEVICE_GROUPS_LIST.WIZARD.EDIT_HEADING';
        }
    }
    getSubTitle() {
        if (!this.isEditFlow()) {
            return '$I18N_DEVICE_GROUPS_LIST.WIZARD.CREATE_SUB_HEADING';
        }
        else {
            return '$I18N_DEVICE_GROUPS_LIST.WIZARD.EDIT_SUB_HEADING';
        }
    }
    cancel() {
        this.onCancelWizard.emit();
    }
    isStepValid() {
        if (!!this.wizard) {
            if (this.wizard.currentStep === 1) {
                return this.deviceGroupDetailsStepComponent.isValid();
            }
            if (this.wizard.currentStep === 2) {
                return this.deviceGroupAddDevicesStepComponent.isValid();
            }
        }
        return true;
    }
    isValidDeviceGroup(deviceGroup) {
        if (this.isEditFlow()) {
            return deviceGroup.name;
        }
        return deviceGroup.name && this.devicesInGroup.length;
    }
    createDeviceGroup() {
        this.onCreateDeviceGroup.emit();
    }
}
exports.DeviceGroupsWizardComponent = DeviceGroupsWizardComponent;
