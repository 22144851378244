<div class="mc-api-integration-step-block">
  <mc-wizard-step-header [label]="summaryHeader">
  </mc-wizard-step-header>

  <div *ngIf="wildfirePrerequisitesError" class="mc-detailed-list mc-api-integration-summary-block">
    <div class="row mc-detailed-list-row panel-padding-bottom">
      <div class="col-xs-8">
        {{ errorContent }}
      </div>
    </div>
    <div *ngIf="wildfirePrerequisitesError.toLowerCase() === 'err_api_integration_wildfire_package_missing'" class="row mc-detailed-list-row panel-padding-bottom">
      <div class="col-xs-8">
        {{ '$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.SUMMARY.PACKAGE' | translate }}
      </div>
    </div>
  </div>

  <div *ngIf="!wildfirePrerequisitesError" class="mc-detailed-list mc-api-integration-summary-block">
    <div class="row mc-detailed-list-row panel-padding-bottom">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.SUMMARY.INTEGRATION.NAME' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{ '$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.SUMMARY.INTEGRATION.VALUE' | translate }}
      </div>
    </div>

    <div class="row mc-detailed-list-row panel-padding-bottom">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.SUMMARY.DESCRIPTION.NAME' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{ '$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.SUMMARY.DESCRIPTION.VALUE' | translate }}
      </div>
    </div>

    <div class="row mc-detailed-list-row panel-padding-bottom">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.SUMMARY.REGION' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{ wildfireIntegration?.region | mcRegionProvider }}
      </div>
    </div>

    <div class="row mc-detailed-list-row panel-padding-bottom">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.SUMMARY.API_KEY' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{ wildfireIntegration?.apiKey | mcApiObfuscator }}
      </div>
    </div>

    <div class="row mc-detailed-list-row panel-padding-bottom">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.SUMMARY.ATTACHMENTS' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{ submitAttachments }}
      </div>
    </div>

    <div class="row mc-detailed-list-row panel-padding-bottom"
      *mcCapabilities="'Temporary.Services.ApiApplications.Wildfire.Integration.SubmitMaliciousAttachment'">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.SUMMARY.MALICIOUS_ATTACHMENTS' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{ submitMaliciousAttachments }}
      </div>
    </div>

    <div class="row mc-detailed-list-row panel-padding-bottom">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.SUMMARY.REMEDIATION' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{ remediationRequired }}
      </div>
    </div>

    <div class="row mc-detailed-list-row panel-padding-bottom">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.SUMMARY.RECIPIENTS' | translate }}
      </div>
  
      <div class="mc-detailed-list-column">
        <div *ngFor="let group of wildfireGroupsList()">
          {{ group | translate }}
        </div>
  
        <div *ngFor="let email of wildfireEmailList()">
          {{ email }}
        </div>
      </div>
    </div>

    <form novalidate [formGroup]="form">
      <mc-enable-switch
        [label]="'$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.SUMMARY.STATUS'"
        formControlName="integrationStatus"
        contentDivClass="row mc-detailed-list-row panel-padding-top"
        labelClass="col-xs-4 mc-detailed-list-label"
        switchDivClass="mc-detailed-list-column"
      >
      </mc-enable-switch>
    </form>
  </div>
</div>
