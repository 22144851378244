<div class="mc-detailed-list">
  <mc-summary [messageInfo]="selectedMessageInfo"></mc-summary>
  <div *ngIf="showSpamDetailsInfo()">
    <hr/>
    <mc-spam-details-info
      [spamInfo]="spamInfo"
    >
    </mc-spam-details-info>
  </div>
  <hr/>
  <div *ngIf="selectedMessageInfo?.attachments?.length">
    <mc-attachments [attachments]="selectedMessageInfo?.attachments"
                    (downloadFile)="downloadFile.emit($event)">
    </mc-attachments>
    <hr/>
  </div>
  <mc-email-body #emailBody [html]="selectedMessageInfo?.htmlBody"
                 [contentAdministratorView]="contentAdministratorView"
                 [forceLoadContent]="forceLoadContent"
                 [attachments]="selectedMessageInfo?.attachments"
                 imageApiUrl="/tracking/get-file"
                 [text]="selectedMessageInfo?.textBody"
                 (loadContentRequest)="loadContentRequest.emit($event)"
                 (showHtml)="showHtml($event)">
  </mc-email-body>
  <div *ngIf="archived">
    <hr/>
    <mc-retention [retentionInfo]="retentionInfo"></mc-retention>
  </div>
</div>
