<mc-layout-modal-simple  keyTitle="{{'$I18N_SERVICES_URL_PROTECTION_LOGS_URL_CHECK.SCREENSHOT_MODAL.TITLE' | translate}}">
  <mc-body-container >
    <img [src]="data"  alt="{{ '$I18N_SERVICES_URL_PROTECTION_LOGS_URL_CHECK.SCREENSHOT_MODAL.ALT_TEXT' | translate }}" width="835" height="400" >
  </mc-body-container>
  <mc-footer-container>
    <ng-container >
      <button type="button" class="btn btn-primary " (click)="onCancel()">
        {{ '$I18N_SERVICES_URL_PROTECTION_LOGS_URL_CHECK.SCREENSHOT_MODAL.CLOSE' | translate }}
      </button>
    </ng-container>
  </mc-footer-container>
</mc-layout-modal-simple>

