<mc-layout-modal-simple data-unit-test="confirmation-modal-title" keyTitle="{{title}}">
  <mc-body-container *ngIf="description">
    <p>{{description}}</p>
  </mc-body-container>
  <mc-footer-container>
    <ng-container *ngIf="data.type !== fromToEveryoneButton; else fromToModalButton">
      <button type="button" class="btn btn-secondary" (click)="onCancel()" data-unit-test="confirmation-modal-cancel-button" 
      data-cy-test="confirmation-modal-got-it-button">
        {{(isCancelStyle ? '$I18N_SERVICES_URL_PROTECTION.CANCEL_POLICY_CONFIRMATION_NO_BTN'
        : '$I18N_SERVICES_URL_PROTECTION.DELETE_POLICY_CANCEL_BUTTON') | translate}}
      </button>
      <button data-unit-test="confirmation-modal-confiramtion-button"
      type="button" class="btn" [ngClass]="isCancelStyle ? 'btn-primary': 'btn-danger' " data-test="cy-delete-override-btn" (click)="onDelete()">
        <em data-unit-test="confirmation-modal-confiramtion-button-icon" *ngIf="data.type === deleteButton" class="far fa-trash-alt"></em>
        <span>{{buttonText}}</span>
      </button>
    </ng-container>
  </mc-footer-container>
</mc-layout-modal-simple>

<ng-template #fromToModalButton>
  <button    
    class="btn btn-primary"
    type="button"
    data-unit-test="confirmation-modal-cancel-button"
    data-cy-test="confirmation-modal-got-it-button"
    (click)="onCancel()">{{buttonText}}</button>
</ng-template>
