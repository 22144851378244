"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgThreatEventMaliciousUrlsComponent = void 0;
const models_1 = require("../../../../models/models");
class SwgThreatEventMaliciousUrlsComponent {
    constructor() {
        this.openedIndex = null;
        this.MAX_LINK_SIZE = 75;
    }
    get getUrls() {
        return this.threatEvent.urlScan.urls.map(scan => {
            const arr = scan.url.split('/');
            return {
                domain: arr[0] + '//' + arr[2],
                full: scan.url
            };
        });
    }
    toggleShowUrl(index) {
        this.openedIndex = this.openedIndex !== index ? index : null;
    }
    isClean() {
        return this.threatEvent.urlScan.verdict === models_1.Verdict.CLEAN.toString();
    }
}
exports.SwgThreatEventMaliciousUrlsComponent = SwgThreatEventMaliciousUrlsComponent;
