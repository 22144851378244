"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPagingMetadata = exports.isApplicationEnabled = exports.getTotalCount = exports.getSelectedRow = exports.getIntegrations = exports.isEmpty = exports.isLoading = exports.reducer = exports.initialState = void 0;
const actions = require("../../actions");
exports.initialState = {
    isLoading: false,
    isEmpty: true,
    integrations: [],
    paginatorMetadata: {
        pageToken: undefined,
        pageSize: 50,
        pageNumber: 0
    }
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.IntegrationListActionTypes.GetAllIntegrations:
            return Object.assign(Object.assign({}, state), { isLoading: true, isEmpty: true, integrations: [], selectedRow: undefined });
        case actions.IntegrationListActionTypes.GetAllIntegrationsSuccess:
            return Object.assign(Object.assign({}, state), { isLoading: false, isEmpty: !action.payload.integrations.length, integrations: [...action.payload.integrations].sort(compareIntegrations), paginatorMetadata: Object.assign(Object.assign({}, state.paginatorMetadata), { pageNumber: 0, pageToken: undefined }) });
        case actions.IntegrationListActionTypes.PageIntegrations:
            return Object.assign(Object.assign({}, state), { isLoading: true, paginatorMetadata: Object.assign(Object.assign({}, state.paginatorMetadata), action.payload) });
        case actions.IntegrationListActionTypes.PageIntegrationsCompleted:
            return Object.assign(Object.assign({}, state), { isLoading: false });
        case actions.ApiApplicationActionTypes.ApiApplicationFailure:
            return Object.assign(Object.assign({}, state), { isLoading: false });
        default:
            return state;
    }
}
exports.reducer = reducer;
const isLoading = (state) => state.isLoading;
exports.isLoading = isLoading;
const isEmpty = (state) => state.isEmpty;
exports.isEmpty = isEmpty;
const getIntegrations = (state) => getCurrentPage(state.integrations, state.paginatorMetadata);
exports.getIntegrations = getIntegrations;
const getSelectedRow = (state) => state.selectedRow;
exports.getSelectedRow = getSelectedRow;
const getTotalCount = (state) => state.integrations.length;
exports.getTotalCount = getTotalCount;
const isApplicationEnabled = (id) => (state) => {
    const integration = state.integrations.find(i => i.id === id);
    return !!integration && integration.enabled;
};
exports.isApplicationEnabled = isApplicationEnabled;
const getPagingMetadata = (state) => {
    const numberOfPages = Math.ceil(state.integrations.length / state.paginatorMetadata.pageSize);
    const currentPageNumber = state.paginatorMetadata.pageNumber;
    const isLastPage = currentPageNumber + 1 >= numberOfPages;
    const metadata = {
        pagination: {
            previous: currentPageNumber > 0 ? 'previous' : undefined,
            next: !isLastPage ? 'next' : undefined,
            pageSize: exports.getIntegrations(state).length,
            totalRows: state.integrations.length
        }
    };
    return metadata;
};
exports.getPagingMetadata = getPagingMetadata;
function getCurrentPage(integrations, pagination) {
    const first = pagination.pageNumber * pagination.pageSize;
    const last = first + pagination.pageSize;
    return integrations.slice(first, last);
}
function compareIntegrations(a, b) {
    return a.integrationType > b.integrationType ? 1 : b.integrationType > a.integrationType ? -1 : 0;
}
