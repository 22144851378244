"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PendoTtpImpersonationProtectPackagePoliciesProvider = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const base_pendo_information_provider_1 = require("../../base-pendo-information.provider");
class PendoTtpImpersonationProtectPackagePoliciesProvider extends base_pendo_information_provider_1.BasePendoInformationProvider {
    constructor(service) {
        super();
        this.service = service;
        this.TTP_IP_PROTECT_PKG_REF_CODE = '1060';
        this.TTP_IP_PROTECT_POLICY = 20850;
        this.requestActive = {
            onlyActivePolicies: true,
            packageRefCode: this.TTP_IP_PROTECT_PKG_REF_CODE
        };
        this.requestAll = {
            onlyActivePolicies: false,
            packageRefCode: this.TTP_IP_PROTECT_PKG_REF_CODE
        };
    }
    getInformation() {
        let PKG_TTP_IP;
        let IPProtectPoliciesCount = 0;
        let IPProtectActivePoliciesCount = 0;
        return rxjs_1.combineLatest([
            this.service.getPackagePolicies(this.requestActive),
            this.service.getPackagePolicies(this.requestAll)
        ]).pipe(operators_1.map(([active, all]) => {
            if (!active.packageRefPolicies[this.TTP_IP_PROTECT_PKG_REF_CODE]) {
                PKG_TTP_IP = false;
            }
            else {
                PKG_TTP_IP = true;
                active.packageRefPolicies[this.TTP_IP_PROTECT_PKG_REF_CODE]
                    .filter(policy => policy.policyType === this.TTP_IP_PROTECT_POLICY)
                    .forEach(policy => (IPProtectActivePoliciesCount += policy.count));
                all.packageRefPolicies[this.TTP_IP_PROTECT_PKG_REF_CODE]
                    .filter(policy => policy.policyType === this.TTP_IP_PROTECT_POLICY)
                    .forEach(policy => (IPProtectPoliciesCount += policy.count));
            }
            return {
                account: {
                    PKG_TTP_IP,
                    IPProtectActivePoliciesCount,
                    IPProtectPoliciesCount
                }
            };
        }));
    }
}
exports.PendoTtpImpersonationProtectPackagePoliciesProvider = PendoTtpImpersonationProtectPackagePoliciesProvider;
