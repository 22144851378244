"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgDownloadMalwareReportListFailureAction = exports.SwgDownloadMalwareReportListSuccessAction = exports.SwgDownloadMalwareReportListAction = exports.SwgDownloadMalwareReportListActions = void 0;
var SwgDownloadMalwareReportListActions;
(function (SwgDownloadMalwareReportListActions) {
    SwgDownloadMalwareReportListActions["DOWNLOAD_MALWARE_REPORT_LIST"] = "[SwgMalwareList] Download Malware List";
    SwgDownloadMalwareReportListActions["DOWNLOAD_MALWARE_REPORT_LIST_SUCCESS"] = "[SwgMalwareList] Download Malware List Success";
    SwgDownloadMalwareReportListActions["DOWNLOAD_MALWARE_REPORT_LIST_FAILURE"] = "[SwgMalwareList] Download Malware List Failure";
})(SwgDownloadMalwareReportListActions = exports.SwgDownloadMalwareReportListActions || (exports.SwgDownloadMalwareReportListActions = {}));
class SwgDownloadMalwareReportListAction {
    constructor(tableColumns) {
        this.tableColumns = tableColumns;
        this.type = SwgDownloadMalwareReportListActions.DOWNLOAD_MALWARE_REPORT_LIST;
    }
}
exports.SwgDownloadMalwareReportListAction = SwgDownloadMalwareReportListAction;
class SwgDownloadMalwareReportListSuccessAction {
    constructor() {
        this.type = SwgDownloadMalwareReportListActions.DOWNLOAD_MALWARE_REPORT_LIST_SUCCESS;
    }
}
exports.SwgDownloadMalwareReportListSuccessAction = SwgDownloadMalwareReportListSuccessAction;
class SwgDownloadMalwareReportListFailureAction {
    constructor() {
        this.type = SwgDownloadMalwareReportListActions.DOWNLOAD_MALWARE_REPORT_LIST_FAILURE;
    }
}
exports.SwgDownloadMalwareReportListFailureAction = SwgDownloadMalwareReportListFailureAction;
