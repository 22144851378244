"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchByTokenTabWrapperComponent = void 0;
const action = require("../../actions/search.action");
const forms_1 = require("@angular/forms");
const components_1 = require("@mimecast-ui/components");
class SearchByTokenTabWrapperComponent {
    constructor(_fb, store) {
        this._fb = _fb;
        this.store = store;
        this.searchTermLength = {
            MINLENGTHTRIMMED: 3,
            MINLENGTH: 3,
            MAXLENGTH: 150
        };
        this.tokenValid = false;
        this.store = store;
    }
    formTokenSearch() {
        const tokenStrimmed = { token: this.formToken.value.token.trim() };
        this.store.dispatch(
        // @ts-ignore
        new action.TokenSearchAction(Object.assign(Object.assign({}, this.formReasonValueChanges), tokenStrimmed)));
    }
    ngOnInit() {
        this.formToken = this._fb.group({
            token: [
                '',
                [
                    forms_1.Validators.required,
                    forms_1.Validators.minLength(this.searchTermLength.MINLENGTHTRIMMED),
                    forms_1.Validators.maxLength(this.searchTermLength.MAXLENGTH),
                    components_1.minLengthTrimmed(this.searchTermLength.MINLENGTHTRIMMED)
                ]
            ]
        });
        this.formToken.controls['token'].valueChanges.subscribe(selectedValue => {
            this.tokenValid = selectedValue.length >= 5 ? true : false;
        });
    }
}
exports.SearchByTokenTabWrapperComponent = SearchByTokenTabWrapperComponent;
