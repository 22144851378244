"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiOktaIntegrationAsideComponent = void 0;
const actions = require("../../../actions");
const reducers = require("../../../reducers");
const okta_confirmation_modal_1 = require("../../modals/okta-confirmation-modal");
class ApiOktaIntegrationAsideComponent {
    constructor(store, modalService, integration) {
        this.store = store;
        this.modalService = modalService;
        this.integration = integration;
    }
    ngOnInit() {
        this.enabled$ = this.store.select(reducers.isIntegrationListApplicationEnabled(this.integration.id));
        this.oktaIntegration$ = this.store.select(reducers.getIntegrationOktaIntegration);
    }
    enable() {
        this.store.dispatch(new actions.EnableIntegrationAction({ integration: this.integration }));
    }
    disable() {
        this.store.dispatch(new actions.DisableIntegrationAction({ integration: this.integration }));
    }
    edit() {
        this.store.dispatch(new actions.CloseViewIntegrationAction());
        this.store.dispatch(new actions.OpenEditIntegrationAction({ integration: this.integration }));
    }
    delete() {
        this.store.dispatch(new actions.CloseViewIntegrationAction());
        this.showGenerateTokenModal('delete', this.integration.identifier);
    }
    close() {
        this.store.dispatch(new actions.CloseViewIntegrationAction());
    }
    rotateToken() {
        this.store.dispatch(new actions.CloseViewIntegrationAction());
        this.store.dispatch(new actions.SwitchRotateApiTokenAction(true));
        this.showGenerateTokenModal('generate');
    }
    showGenerateTokenModal(actionType, integrationIdentifier) {
        const modalConfig = {};
        modalConfig.size = 'lg';
        modalConfig.hasBackdrop = true;
        modalConfig.disableClose = true;
        modalConfig.data = { type: actionType, integrationName: integrationIdentifier };
        this.modalService.open(okta_confirmation_modal_1.OktaConfirmationModalComponent, modalConfig);
    }
}
exports.ApiOktaIntegrationAsideComponent = ApiOktaIntegrationAsideComponent;
