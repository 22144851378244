"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomDomainBlockBuilder = void 0;
const custom_domain_block_1 = require("./custom-domain-block");
const custom_domain_block_base_builder_1 = require("./custom-domain-block-base.builder");
class CustomDomainBlockBuilder extends custom_domain_block_base_builder_1.CustomDomainBlockBaseBuilder {
    self() {
        return this;
    }
    build() {
        return new custom_domain_block_1.CustomDomainBlock(this.definitionId, this.policyId, this.name, this.description, this.action, this.notify, this.configurationId, this.lastUpdated, this.enabled);
    }
}
exports.CustomDomainBlockBuilder = CustomDomainBlockBuilder;
