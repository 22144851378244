"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListComponent = void 0;
const table_store_1 = require("@mimecast-ui/table-store");
const status_factory_service_1 = require("app-new/sync-recover/services/status.factory.service");
const snapshot_list_impl_service_1 = require("../services/snapshot-list.impl.service");
class ListComponent extends table_store_1.TableBaseComponent {
    constructor(store, capabilitiesManager, windowService, tabService) {
        super(store, snapshot_list_impl_service_1.SnapShotListImpl.EXCHANGE_MAILBOX_SIT_LIST_STORE_ID);
        this.store = store;
        this.capabilitiesManager = capabilitiesManager;
        this.windowService = windowService;
        this.tabService = tabService;
        this.columnList = ['name', 'mailbox', 'displayId', 'created', 'date', 'expiry', 'status'];
        this.listStatuses = {};
        this.filterModel = {
            field: 'status',
            header: 'header',
            options: [
                status_factory_service_1.STATUS_TYPES.completed,
                status_factory_service_1.STATUS_TYPES.created,
                status_factory_service_1.STATUS_TYPES.error,
                status_factory_service_1.STATUS_TYPES.in_progress,
                status_factory_service_1.STATUS_TYPES.completed_with_errors
            ]
        };
        const tab = this.tabService.getSelectedTab();
        tab.onRefresh = () => {
            this.dispatchFilterAction();
        };
        this.capabilitiesManager
            .hasExchangeSyncAndRecoverReadPermission()
            .subscribe((hasSyncAndRecoverReadPermission) => {
            this.hasSyncAndRecoverReadPermission = hasSyncAndRecoverReadPermission;
        });
    }
    openItem(row) {
        this.clickedRow = row;
        if (this.hasSyncAndRecoverReadPermission) {
            super.openItem(row);
        }
    }
    getStatusByRow(row) {
        return status_factory_service_1.StatusFactory[row.status]();
    }
}
exports.ListComponent = ListComponent;
