"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationUrlFormatOptions = exports.SimilarUrlDetectionOptions = exports.UrlFileDownloadOptions = exports.FileProtocolUrlHandlingOptions = exports.MessageSubjectProtectionOptions = exports.MaliciousUrlDetectionActionOptions = exports.UrlCategoryScanningOptions = exports.RewriteModeOptions = void 0;
var RewriteModeOptions;
(function (RewriteModeOptions) {
    RewriteModeOptions["AGGRESSIVE"] = "aggressive";
    RewriteModeOptions["MODERATE"] = "moderate";
    RewriteModeOptions["RELAXED"] = "relaxed";
})(RewriteModeOptions = exports.RewriteModeOptions || (exports.RewriteModeOptions = {}));
var UrlCategoryScanningOptions;
(function (UrlCategoryScanningOptions) {
    UrlCategoryScanningOptions["AGGRESSIVE"] = "aggressive";
    UrlCategoryScanningOptions["MODERATE"] = "moderate";
    UrlCategoryScanningOptions["RELAXED"] = "relaxed";
})(UrlCategoryScanningOptions = exports.UrlCategoryScanningOptions || (exports.UrlCategoryScanningOptions = {}));
var MaliciousUrlDetectionActionOptions;
(function (MaliciousUrlDetectionActionOptions) {
    MaliciousUrlDetectionActionOptions["BLOCK"] = "block";
    MaliciousUrlDetectionActionOptions["WARN"] = "warn";
    MaliciousUrlDetectionActionOptions["ALLOW"] = "allow";
})(MaliciousUrlDetectionActionOptions = exports.MaliciousUrlDetectionActionOptions || (exports.MaliciousUrlDetectionActionOptions = {}));
var MessageSubjectProtectionOptions;
(function (MessageSubjectProtectionOptions) {
    MessageSubjectProtectionOptions["REWRITE_AND_SCAN"] = "rewrite";
    MessageSubjectProtectionOptions["REMOVE_URLS"] = "remove";
    MessageSubjectProtectionOptions["TAKE_NO_ACTIONS"] = "ignore";
})(MessageSubjectProtectionOptions = exports.MessageSubjectProtectionOptions || (exports.MessageSubjectProtectionOptions = {}));
var FileProtocolUrlHandlingOptions;
(function (FileProtocolUrlHandlingOptions) {
    FileProtocolUrlHandlingOptions["STRIP_THE_URL_SCHEMA"] = "rewrite";
    FileProtocolUrlHandlingOptions["HOLD_THE_MESSAGE_FOR_REVIEW"] = "hold";
    FileProtocolUrlHandlingOptions["TAKE_NO_ACTIONS"] = "none";
})(FileProtocolUrlHandlingOptions = exports.FileProtocolUrlHandlingOptions || (exports.FileProtocolUrlHandlingOptions = {}));
var UrlFileDownloadOptions;
(function (UrlFileDownloadOptions) {
    UrlFileDownloadOptions["CHECK"] = "check_file";
    UrlFileDownloadOptions["BLOCK"] = "block";
    UrlFileDownloadOptions["WARN"] = "warn";
    UrlFileDownloadOptions["ALLOW"] = "none";
})(UrlFileDownloadOptions = exports.UrlFileDownloadOptions || (exports.UrlFileDownloadOptions = {}));
var SimilarUrlDetectionOptions;
(function (SimilarUrlDetectionOptions) {
    SimilarUrlDetectionOptions["BLOCK"] = "block";
    SimilarUrlDetectionOptions["WARN"] = "warn";
    SimilarUrlDetectionOptions["ALLOW"] = "allow";
})(SimilarUrlDetectionOptions = exports.SimilarUrlDetectionOptions || (exports.SimilarUrlDetectionOptions = {}));
var NotificationUrlFormatOptions;
(function (NotificationUrlFormatOptions) {
    NotificationUrlFormatOptions["IN_WEB"] = "url_with_preview";
    NotificationUrlFormatOptions["IN_NOTIFICATION"] = "safe_url";
})(NotificationUrlFormatOptions = exports.NotificationUrlFormatOptions || (exports.NotificationUrlFormatOptions = {}));
