"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./modal-error-report-service"); //modalErrorReport.service
/**
 * Created by psambandam on 14/07/2016.
 */
(function () {
    'use strict';
    angular.module('modalErrorReport.controller', ['modalErrorReport.service'])
        .controller('ModalErrorReportController', ['modalErrorReportService', '$scope', '$filter',
        function (modalErrorReportService, $scope, $filter) {
            const self = this;
            const { tabsCtrl } = $scope.$parent.$resolve;
            self.closeErrorModal = modalErrorReportService.closeErrorModal;
            self.tabsCtrl = tabsCtrl;
            self.title = tabsCtrl.getSelectedTab().title;
            self.id = tabsCtrl.getSelectedTab().id;
            self.modalBodyText = $filter('translate')('$I18N_ERROR_MODAL_BODY_TEXT', { tabTitleText: self.title });
        }]);
}());
