"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SnapshotEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const actions_1 = require("../actions");
class SnapshotEffects {
    constructor(actions$, snapshotService, injector) {
        this.actions$ = actions$;
        this.snapshotService = snapshotService;
        this.injector = injector;
        this.create$ = this.actions$.pipe(effects_1.ofType(actions_1.fromSnapshot.CREATE), operators_1.map((action) => action.payload), operators_1.switchMap((payload) => {
            return this.snapshotService.createSnapshot(payload).pipe(operators_1.map((response) => {
                if (response.hasErrors) {
                    return new actions_1.fromSnapshot.CreateFailAction(response.firstFail);
                }
                else {
                    const SNAPSHOT = response.first;
                    if (SNAPSHOT.status && SNAPSHOT.status.toLocaleUpperCase() === 'ALREADY_EXISTS') {
                        const fail = {
                            errors: [
                                {
                                    code: 'ERR_SNAPSHOT_ALREADY_EXISTS'
                                }
                            ],
                            key: {
                                failData: {
                                    existingSnapshot: SNAPSHOT.displayId,
                                    createdBy: SNAPSHOT.createdBy
                                }
                            }
                        };
                        return new actions_1.fromSnapshot.CreateFailAction(fail);
                    }
                    return new actions_1.fromSnapshot.CreateSuccessAction(response.first);
                }
            }), operators_1.catchError((error) => rxjs_1.of(new actions_1.fromSnapshot.CreateFailAction(error.firstFail))));
        }));
        this.goToSnapshotQueue$ = this.actions$.pipe(effects_1.ofType(actions_1.fromSnapshot.GO_TO_SNAPSHOT_QUEUE), operators_1.map(() => {
            this.$state.go('sync-recover-exchange-sit-list');
            return new actions_1.fromSnapshot.GoToSnapshotQueueSuccessAction();
        }));
        this.openMessageContent$ = this.actions$.pipe(effects_1.ofType(actions_1.fromSnapshot.OPEN_MESSAGE_CONTENT), operators_1.map((action) => action.payload), operators_1.switchMap((payload) => {
            return rxjs_1.from(this.emailViewService
                .openEmailView(payload.item, 'snapshot', payload.origin)
                .opened.then(() => {
                return true;
            })).pipe(operators_1.map(() => {
                return new actions_1.fromSnapshot.OpenMessageContentSuccessAction();
            }));
        }));
        this.openContactContent$ = this.actions$.pipe(effects_1.ofType(actions_1.fromSnapshot.OPEN_CONTACT_CONTENT), operators_1.map((action) => action.payload), operators_1.switchMap((payload) => {
            return rxjs_1.from(this.emailViewService
                .openContactView(payload.item, payload.mailBox, 'snapshot', payload.origin)
                .opened.then(() => {
                return true;
            })).pipe(operators_1.map(() => {
                return new actions_1.fromSnapshot.OpenContactContentSuccessAction();
            }));
        }));
        this.openCalendarContent$ = this.actions$.pipe(effects_1.ofType(actions_1.fromSnapshot.OPEN_CALENDAR_CONTENT), operators_1.map((action) => action.payload), operators_1.switchMap((payload) => {
            return rxjs_1.from(this.emailViewService
                .openCalendarView(payload.item, payload.mailBox, 'snapshot', payload.origin)
                .opened.then(() => {
                return true;
            })).pipe(operators_1.map(() => {
                return new actions_1.fromSnapshot.OpenContactContentSuccessAction();
            }));
        }));
        this.openExport$ = this.actions$.pipe(effects_1.ofType(actions_1.fromSnapshot.OPEN_EXPORT), operators_1.map((action) => action.payload), operators_1.switchMap((payload) => {
            const exportHtmlFile = 'services/services/backup/mailboxes/mailbox-details/slides/mailbox-email-export.tpl.html';
            const asideInstance = this.$aside.open({
                templateUrl: exportHtmlFile,
                placement: 'right',
                size: 'lg',
                backdrop: true,
                windowClass: 'hidden-xs',
                backdropClass: 'hidden-xs',
                controller: () => { },
                resolve: {
                    mailbox: () => payload.mailbox,
                    viewType: () => payload.viewType,
                    folderPaths: () => payload.folderPaths,
                    dismissFunction: () => () => asideInstance.close(),
                    userEmailAddress: () => payload.userEmailAddress,
                    snapshotId: () => payload.snapshotId,
                    hasUnsupported: () => false
                }
            });
            return rxjs_1.of(new actions_1.fromSnapshot.OpenExportSuccessAction());
        }));
        this.openRestore$ = this.actions$.pipe(effects_1.ofType(actions_1.fromSnapshot.OPEN_RESTORE), operators_1.map((action) => action.payload), operators_1.switchMap((payload) => {
            const restoreHtmlFile = 'services/services/backup/mailboxes/mailbox-details/slides/mailbox-email-restore.tpl.html';
            const asideInstance = this.$aside.open({
                templateUrl: restoreHtmlFile,
                placement: 'right',
                size: 'lg',
                backdrop: true,
                windowClass: 'hidden-xs',
                backdropClass: 'hidden-xs',
                controller: () => { },
                resolve: {
                    mailbox: () => payload.mailbox,
                    viewType: () => payload.viewType,
                    folderPaths: () => payload.folderPaths,
                    dismissFunction: () => () => asideInstance.close(),
                    userEmailAddress: () => payload.userEmailAddress,
                    snapshotId: () => payload.snapshotId
                }
            });
            return rxjs_1.of(new actions_1.fromSnapshot.OpenRestoreSuccessAction());
        }));
    }
    get $state() {
        return this.injector.get('$state');
    }
    get $aside() {
        return this.injector.get('$aside');
    }
    get emailViewService() {
        return this.injector.get('emailViewService');
    }
}
__decorate([
    effects_1.Effect()
], SnapshotEffects.prototype, "create$", void 0);
__decorate([
    effects_1.Effect()
], SnapshotEffects.prototype, "goToSnapshotQueue$", void 0);
__decorate([
    effects_1.Effect()
], SnapshotEffects.prototype, "openMessageContent$", void 0);
__decorate([
    effects_1.Effect()
], SnapshotEffects.prototype, "openContactContent$", void 0);
__decorate([
    effects_1.Effect()
], SnapshotEffects.prototype, "openCalendarContent$", void 0);
__decorate([
    effects_1.Effect()
], SnapshotEffects.prototype, "openExport$", void 0);
__decorate([
    effects_1.Effect()
], SnapshotEffects.prototype, "openRestore$", void 0);
exports.SnapshotEffects = SnapshotEffects;
