"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const moment = require("moment");
const operators_1 = require("rxjs/operators");
const core_interfaces_1 = require("../app-new/core/model/core.interfaces");
require("mimecast-web-components/src/app/services/login/login-service");
/* eslint-disable @typescript-eslint/no-var-requires */
const redirectRoutes = require('./legacy-states-redirect.json');
angular
    .module('administration.controllers', ['api.login.service', 'core.service'])
    .controller('AdministrationController', [
    '$rootScope',
    '$translate',
    '$window',
    '$state',
    '$sce',
    'ngProgressFactory',
    'sideService',
    '$aside',
    'navbarService',
    'navbarFavouriteService',
    'navbarHistoryService',
    'accountService',
    'applicationSwitcherService',
    '$cookies',
    'breadcrumbService',
    'modalActionService',
    'CoreService',
    'administrationService',
    'progressTrackerService',
    'capabilitiesService',
    'UserInfoApiService',
    'UserCapabilitiesService',
    '$document',
    '$location',
    'mcnavmenuService',
    'FeatureFlagService',
    'newSessionHandlerService',
    '$uibModalStack',
    'asideService',
    function ($rootScope, $translate, $window, $state, $sce, ngProgressFactory, sideService, $aside, navbarService, navbarFavouriteService, navbarHistoryService, accountService, applicationSwitcherService, $cookies, breadcrumbService, modalActionService, coreService, administrationService, progressTrackerService, capabilitiesService, userInfoApiService, userCapabilitiesService, $document, $location, mcnavmenuService, featureFlagService, newSessionHandlerService, $uibModalStack, asideService) {
        const self = this;
        const ngProgress = ngProgressFactory.createInstance();
        const DEFAULT_INACTIVITY_TIMEOUT = 20; // 20min
        const MINS_TO_MILLIS = 60000;
        self.hasUserMigratedPolicies = false;
        self.inactivityTimeout = DEFAULT_INACTIVITY_TIMEOUT * MINS_TO_MILLIS;
        self.isSwitchedAccount = false;
        self.aside = true;
        self.bc = true;
        self.isSpinning = true;
        self.hideTabs = false;
        self.inIframe = ($window !== $window.parent) && !$cookies.get('cypress-iframe');
        self.hideBreadcrumbs = false;
        // Improved Navigation
        self.improvedNavigationUiSwitch = false;
        self.improvedNavigationEnabled = false;
        self.getValidMenuItems = getValidMenuItems;
        self.onMenuStateChange = onMenuStateChange;
        self.menuItems = [];
        self.menuHomeLink = '/';
        self.menuHomeTitle = 'Home';
        self.mcMenuCollapsed = false;
        coreService.loadApp();
        // ----- Function exposed to the template ---
        // @ts-ignore
        self.ISMOBILE = angular.ISMOBILE;
        self.fxdLayout = fxdLayout;
        self.logout = logout;
        self.getSideTemplate = sideService.getSideTemplate;
        self.openAsideMenu = openAsideMenu;
        self.doReload = doReload;
        self.breadcrumbs = breadcrumbs;
        self.asideTitle = asideTitle;
        // ------------------------------------------
        self.branding = $window.mimecast && $window.mimecast.branding;
        self.branding.disclaimerBodyHtml = $sce.trustAsHtml(self.branding.disclaimerBody);
        self.isTranslationDone = false;
        self.isSwgOnlyAccount = null;
        $rootScope.$on('$translateChangeSuccess', function () {
            self.isTranslationDone = true;
            self.getValidMenuItems();
        });
        $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams) {
            progressTrackerService.statusLegacyChecker(toState, toParams);
            if (typeof gtag === 'function') {
                const activePage = $location.absUrl().replace(/(^\w+:|^)\/\//, '');
                const entity = activePage.indexOf('/l/') === -1 ? 'UA-82462882-3' : 'UA-82462882-2';
                $window.gtag('config', entity, { page_path: activePage });
            }
        });
        // Dismiss all open modals when navigating to new route
        $rootScope.$on('$locationChangeSuccess', function (event, to, from) {
            $uibModalStack.dismissAll();
            if (!to.includes(from)) {
                asideService.dismissAllModals();
            }
        });
        // @ts-ignore
        self.isOld = angular.ISOLD;
        // To track user actions on the page
        self.onUserAction = onUserAction;
        self.onStorageChange = onStorageChange;
        self.userActions = ['mousemove', 'mousedown', 'keyup', 'click', 'scroll'];
        self.userActions.forEach((event) => {
            document.addEventListener(event, self.onUserAction);
        });
        $window.addEventListener('load', self.onUserAction);
        $window.addEventListener('storage', self.onStorageChange);
        // On mc-nav-menu state change event ie., when collapsed or expanded
        $window.addEventListener('load', self.onMenuStateChange);
        $rootScope.$on('$stateChangeStart', (event, to, params) => {
            coreService
                .getMigratedPolicyInfo()
                .pipe(operators_1.first())
                .subscribe((migratedPolicy) => {
                self.hasUserMigratedPolicies = migratedPolicy;
            });
            const allowRedirectionForMigratedPolicies = !self.hasUserMigratedPolicies &&
                (params.uid === 'targetedthreatprotection-urlprotect-policies' ||
                    params.uid === 'targetedthreatprotection-urlprotect-definition');
            /*
            * Here we are redirecting all the pages that are legacy!
            * The file legacy-states-redirect.json contains the uid on the left
            * and the new state on the right. The newParams is optional!
            */
            const redirectionConfig = redirectRoutes[params && params.uid];
            if (!!redirectionConfig && !allowRedirectionForMigratedPolicies) {
                const hasCapability = redirectionConfig.capability !== undefined &&
                    capabilitiesService.evalCapabilitiesExpression(redirectionConfig.capability);
                const enableRouting = redirectionConfig.capabilityEnablesRedirection
                    ? hasCapability
                    : !hasCapability;
                if (to.name.indexOf('legacy') !== -1 && enableRouting) {
                    event.preventDefault();
                    $state.go(redirectionConfig.newState, redirectionConfig.newParams || {});
                }
            }
            // decide the state according to user
            switch (to.name) {
                case 'secure-web-gateway-dashboard': {
                    if (self.isSwgOnlyAccount !== null && self.isSwgOnlyAccount) {
                        params.showNotification = true;
                    }
                    break;
                }
                case 'administration-dashboard': {
                    if (self.isSwgOnlyAccount !== null && self.isSwgOnlyAccount) {
                        event.preventDefault();
                        $state.go('secure-web-gateway-dashboard', params);
                    }
                    break;
                }
                case 'server-connections': {
                    if (capabilitiesService.hasCapability('Temporary.Services.Server.Connectors.Disabled')) {
                        event.preventDefault();
                        $state.go('services-connectors-page', { tab: 'ews' });
                    }
                    break;
                }
                case 'home': {
                    self.goToState(event, to, params);
                    break;
                }
            }
        });
        const stateChangeStartListenerToDestroy = $rootScope.$on('$stateChangeStart', function (event, to, params) {
            event.preventDefault();
            coreService
                .isAppLoaded()
                .pipe(operators_1.first())
                .subscribe(() => {
                self.checkCapabilities();
                featureFlagService.isReady().then(() => {
                    // Redirect to platform ui admin console if the app is not loaded in an iframe and the EAP switch is enabled for the account code
                    if ((!self.inIframe) && !$cookies.get('cypress-iframe') && $location.path() !== '/redirect/service-monitor') {
                        const isRedirectEnabled = featureFlagService.getBooleanFlag('Adcon.Redirect.To.Platform.UI');
                        if (isRedirectEnabled) {
                            const platformUIAppRoot = '/admin';
                            window.location.href = platformUIAppRoot + window.location.hash;
                        }
                    }
                    else if (self.inIframe) {
                        self.hideBreadcrumbs = featureFlagService.getBooleanFlag('rollout-common-nav-framework', false);
                    }
                });
                stateChangeStartListenerToDestroy();
                // @ts-ignore
                $rootScope.$on('$stateChangeSuccess', function (event2, toState, toParams) {
                    breadcrumbService.applyBreadCrumbs(event2, toState, toParams);
                    navbarFavouriteService.setCurrentState(toState.name, toParams);
                    if (toState.name !== 'home') {
                        navbarHistoryService.addToStateHistory(toState, toParams);
                    }
                });
                if (to.name === 'home') {
                    self.goToState(event, to, params);
                }
                else {
                    $state.go(to.name, params);
                }
                coreService
                    .getAccountSwitchSession()
                    .pipe(operators_1.first(), core_interfaces_1.isDefined())
                    .subscribe((switchedAccountSession) => {
                    const storedAdminSessionTimeout = $cookies.get('adminSessionTimeout');
                    self.inactivityTimeout =
                        (storedAdminSessionTimeout && JSON.parse(storedAdminSessionTimeout)) ||
                            DEFAULT_INACTIVITY_TIMEOUT * MINS_TO_MILLIS;
                    self.isSwitchedAccount = true;
                    setPreAppSwitchHooksForDelegateUsers(switchedAccountSession);
                    showCustomerAccountInTitle();
                });
                coreService
                    .getAccount()
                    .pipe(operators_1.first(), core_interfaces_1.isDefined())
                    .subscribe((account) => {
                    // Check remoteAccountCode in mc-sg;  Switching to adcon from partner portal has it.
                    const mcsg = $cookies.get('mc-sg');
                    const isRemoteAccountCode = mcsg && JSON.parse(mcsg).remoteAccountCode ? true : false;
                    // To keep .local accounts in sync
                    if (!self.isSwitchedAccount || isRemoteAccountCode) {
                        self.inactivityTimeout =
                            (account.adminSessionTimeout || DEFAULT_INACTIVITY_TIMEOUT) * MINS_TO_MILLIS;
                        const strictVal = newSessionHandlerService.isAppRunningInPendo() ? '' : 'strict';
                        $cookies.put('adminSessionTimeout', self.inactivityTimeout, { path: '/', secure: true, samesite: strictVal });
                    }
                    // Checks if improved navigation feature switch is enabled for the accountcode
                    const currentAccountCode = account.accountCode || '';
                    self.improvedNavigationUiSwitch = capabilitiesService.hasAccountCodeCapability('Temp.ImprovedNavigation.UI.Switch', currentAccountCode);
                    if ((!self.inIframe) && !$cookies.get('cypress-iframe')) {
                        // only redirect for legacy Adcon.
                        if (account.expressAccount &&
                            account.packages.includes('Awareness Training [1078]') &&
                            !capabilitiesService.hasCapability('Permission.SERVICE_DELIVERY_READ')) {
                            window.location.href = `${window.location.origin}/matfe/#/access`;
                        }
                    }
                });
                // Enables improved navigation if toggle switch is turned on as a user preference
                userInfoApiService.getAdminPreferences().subscribe((data) => {
                    if (self.improvedNavigationUiSwitch) {
                        self.improvedNavigationEnabled =
                            data && data.preferences && data.preferences.improvedNavigation === true;
                    }
                });
                $document.find('#loadingwall').hide();
                if (self.inIframe) {
                    $window.parent.postMessage({ action: 'iframeContentLoaded' }, window.location.origin);
                }
                self.isSpinning = false;
                ngProgress.complete();
            });
        });
        // --------- implementations ---------
        self.checkCapabilities = function () {
            self.hideTabs = capabilitiesService.hasEarlyAccessCapability('Temporary.Hide.Tabs');
            const gatewayCapLayout = capabilitiesService.hasCapability('Policy.AutoAllow');
            const archiveCapLayout = capabilitiesService.hasCapability('Archive.Search');
            const wsCapLayout = capabilitiesService.hasCapability('Internet.Security.DNS') ||
                capabilitiesService.hasCapability('Internet.Security.DNS.Lite');
            self.isSwgOnlyAccount = wsCapLayout && !gatewayCapLayout && !archiveCapLayout;
        };
        //function to decide the state according to user
        self.goToState = function (event, state, params) {
            const urlParams = new URLSearchParams($window.location.search);
            const provider = urlParams.get('provider');
            const feature = urlParams.get('feature');
            if (self.isSwgOnlyAccount !== null && !self.isSwgOnlyAccount && (feature || provider)) {
                event.preventDefault();
                if (feature) {
                    $state.go('services-im-sync-create-wizard-im-task', {
                        admin_consent: urlParams.get('admin_consent'),
                        tenantId: urlParams.get('tenant'),
                        feature: urlParams.get('feature')
                    });
                }
                if (provider) {
                    handleProviderConsent(provider);
                }
            }
            else if (self.isSwgOnlyAccount !== null && self.isSwgOnlyAccount) {
                event.preventDefault();
                if (provider) {
                    handleProviderConsent(provider);
                }
                else {
                    $state.go('secure-web-gateway-dashboard', params);
                }
            }
            else if (self.isSwgOnlyAccount !== null && !self.isSwgOnlyAccount) {
                event.preventDefault();
                $state.go('administration-dashboard', params);
            }
        };
        function handleProviderConsent(provider) {
            if (provider === 'ms') {
                $state.go('services-ms-consent');
            }
        }
        function logout() {
            self.userActions.forEach((event) => {
                document.removeEventListener(event, self.onUserAction);
            });
            $window.removeEventListener('load', self.onUserAction);
            $window.removeEventListener('storage', self.onStorageChange);
            coreService.logout();
        }
        function fxdLayout() {
            const state = $state.get($state.current.name);
            return {
                isIt: state && state.data && state.data.isFixedLayout,
                bckClass: state && state.data && state.data.backgroundClass ? state.data.backgroundClass : ''
            };
        }
        function openAsideMenu() {
            $aside.open({
                templateUrl: 'layout/aside/aside.tpl.html',
                placement: 'left',
                size: 'sm',
                backdrop: true
            });
        }
        function breadcrumbs() {
            const state = $state.get($state.current.name);
            return state && state.data && state.data.breadcrumbs ? state.data.breadcrumbs : [];
        }
        function asideTitle() {
            const state = $state.get($state.current.name);
            return state && state.data && state.data.asideTitle ? state.data.asideTitle : '';
        }
        self.refreshDate = moment();
        function doReload() {
            self.refreshDate = moment();
            return $state.reload();
        }
        self.getAppSessionTimeout = function () {
            return self.inactivityTimeout;
        };
        self.ping = function () {
            /*
            method called by mc-session-timeout component
            ping a non-cached call to keep API session active
            this should call a lighter api enpoint probably something that
            just check session is valid
            also the ADCON 3.5 ping is done by the core module already, maybe
            this logic should be move there too there is no real need for the ping
            to be controlled by the mc-session-timeout
            */
            return accountService.getDashboardNotifications();
        };
        self.isSessionExtended = function (extend) {
            return extend;
        };
        function setPreAppSwitchHooksForDelegateUsers(switchedAccountSessionParams) {
            const delegateAccountCookieName = 'mc-delegate-account';
            applicationSwitcherService.applicationSwitcherOnReady(function () {
                const connectApp = applicationSwitcherService.getAppByKey(applicationSwitcherService.constants.APP_KEYS.CONNECT);
                if (connectApp) {
                    connectApp.addPreSwitchHook(function () {
                        const data = switchedAccountSessionParams;
                        const date = new Date();
                        date.setTime(date.getTime() + 30 * 1000);
                        $cookies.put(delegateAccountCookieName, encodeURIComponent(JSON.stringify(data)), {
                            expires: date.toUTCString(),
                            path: '/',
                            secure: true
                        });
                    });
                }
            });
        }
        function showCustomerAccountInTitle() {
            navbarService.getNavbarMenuReadyPromise().then(function () {
                const user = navbarService.getUserData();
                $window.document.title = $window.document.title + ' - ' + user.companyName;
            });
        }
        // Update last active time on user actions on the page
        function onUserAction() {
            const time = new Date().getTime().toString();
            $window.localStorage.setItem('lastActive', time);
        }
        // close timeout modal in other tabs when staylogin button is clicked in one tab(i.e, when stayLogin localstorage value changes)
        function onStorageChange(event) {
            if (event['key'] === 'stayLogin') {
                self.isSessionExtended(true);
            }
        }
        // Get valid menu items based on permissions and caps
        function getValidMenuItems() {
            self.menuItems = mcnavmenuService.getValidMenuItems();
            self.menuHomeLink = mcnavmenuService.getMenuHomeLink();
            self.menuHomeTitle = mcnavmenuService.getMenuHomeTitle();
        }
        // To move page content according to mc-nav-menu state
        function onMenuStateChange() {
            const mainNavMenu = $document.find('#main-nav-menu');
            const mcMenuElement = mainNavMenu && mainNavMenu[0];
            if (mcMenuElement) {
                const observer = new MutationObserver(function (event) {
                    if (event && event[0] && event[0]['attributeName'] === 'class') {
                        if (mcMenuElement.classList.contains('collapsed')) {
                            self.mcMenuCollapsed = true;
                        }
                        else {
                            self.mcMenuCollapsed = false;
                        }
                    }
                });
                // Observing class attribute on the element
                observer.observe(mcMenuElement, {
                    attributes: true,
                    attributeFilter: ['class'],
                    childList: false,
                    characterData: false
                });
            }
        }
    }
]);
