"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ1 = exports.ɵ0 = exports.getTemplateForForm = exports.getMimecastDefaultSafetyTips = exports.getMimecastDefaultSafetyTipsOrPreview = exports.getTemplateSafetyTipsOrPreview = exports.isTemplateSaved = exports.isTemplateLoading = exports.getTemplatePreviewTip = exports.getTemplateSelectedTip = exports.getTemplateSafetyTips = exports.getTemplate = exports.isListOrderAscending = exports.isListLoading = exports.getListSearchTerm = exports.getListContents = exports.reducers = void 0;
const operators_1 = require("rxjs/operators");
const store_1 = require("@ngrx/store");
const fromList = require("./list.reducer");
const fromTemplate = require("./template.reducer");
exports.reducers = {
    list: fromList.reducer,
    template: fromTemplate.reducer
};
/**
 * The createFeatureSelector function selects a piece of state from the root of the state object.
 * This is used for selecting feature states that are loaded eagerly or lazily.
 */
const getAwarenessState = store_1.createFeatureSelector('awareness');
const ɵ0 = (state) => state.list;
exports.ɵ0 = ɵ0;
/**
 * Get list selectors
 */
const getListEntitiesState = store_1.createSelector(getAwarenessState, ɵ0);
const ɵ1 = (state) => state.template;
exports.ɵ1 = ɵ1;
const getTemplateState = store_1.createSelector(getAwarenessState, ɵ1);
exports.getListContents = store_1.createSelector(getListEntitiesState, fromList.getListContent);
exports.getListSearchTerm = store_1.createSelector(getListEntitiesState, fromList.getSearchTerm);
exports.isListLoading = store_1.createSelector(getListEntitiesState, fromList.isLoading);
exports.isListOrderAscending = store_1.createSelector(getListEntitiesState, fromList.isOrderAscending);
function getTemplate(id) {
    return store_1.createSelector(getTemplateState, fromTemplate.getTemplate(id));
}
exports.getTemplate = getTemplate;
function getTemplateSafetyTips(id) {
    return store_1.createSelector(getTemplateState, fromTemplate.getTemplateSafetyTips(id));
}
exports.getTemplateSafetyTips = getTemplateSafetyTips;
function getTemplateSelectedTip(id) {
    return store_1.createSelector(getTemplateState, fromTemplate.getTemplateSelectedTip(id));
}
exports.getTemplateSelectedTip = getTemplateSelectedTip;
function getTemplatePreviewTip(id) {
    return store_1.createSelector(getTemplateState, fromTemplate.getTemplatePreviewTip(id));
}
exports.getTemplatePreviewTip = getTemplatePreviewTip;
function isTemplateLoading(id) {
    return store_1.createSelector(getTemplateState, fromTemplate.isTemplateLoading(id));
}
exports.isTemplateLoading = isTemplateLoading;
function isTemplateSaved(id) {
    return store_1.createSelector(getTemplateState, fromTemplate.isTemplateSaved(id));
}
exports.isTemplateSaved = isTemplateSaved;
function getTemplateSafetyTipsOrPreview(id) {
    return store_1.createSelector(getTemplateState, fromTemplate.getTemplateSafetyTipsOrPreview(id));
}
exports.getTemplateSafetyTipsOrPreview = getTemplateSafetyTipsOrPreview;
function getMimecastDefaultSafetyTipsOrPreview(id) {
    return store_1.createSelector(getTemplateState, fromTemplate.getMimecastDefaultSafetyTipsOrPreview(id));
}
exports.getMimecastDefaultSafetyTipsOrPreview = getMimecastDefaultSafetyTipsOrPreview;
exports.getMimecastDefaultSafetyTips = store_1.createSelector(getTemplateState, fromTemplate.getMimecastDefaultSafetyTips);
function getTemplateForForm(store, templateId) {
    return store
        .select(store_1.createSelector(getTemplateState, fromTemplate.getTemplateForForm(templateId)))
        .pipe(operators_1.filter(value => !!value));
}
exports.getTemplateForForm = getTemplateForForm;
