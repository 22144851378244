"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.itemsToShowAtTheBottom = void 0;
const bottomTranslations = '$I18N_AWARENESS_TEMPLATE_SETUP_USER_CHALLENGE_PAGE_BOTTOM_TEXT';
exports.itemsToShowAtTheBottom = [
    {
        qaGroupMessage: 'LINK',
        title: `${bottomTranslations}.$I18N_FIRST_TITLE`,
        description: `${bottomTranslations}.$I18N_FIRST_DESCRIPTION`
    },
    {
        qaGroupMessage: 'SUBJECT',
        title: `${bottomTranslations}.$I18N_SECOND_TITLE`,
        description: `${bottomTranslations}.$I18N_SECOND_DESCRIPTION`
    },
    {
        qaGroupMessage: 'SENDER',
        title: `${bottomTranslations}.$I18N_THIRD_TITLE`,
        description: `${bottomTranslations}.$I18N_THIRD_DESCRIPTION`
    },
    {
        qaGroupMessage: 'EMAIL-ADDRESS',
        title: `${bottomTranslations}.$I18N_FOURTH_TITLE`,
        description: `${bottomTranslations}.$I18N_FOURTH_DESCRIPTION`
    }
];
