"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgThreatEventsService = void 0;
const operators_1 = require("rxjs/operators");
class SwgThreatEventsService {
    constructor(http) {
        this.http = http;
    }
    getThreatEvents(dateRange) {
        return this.http
            .post('/clp/v1/events/list', {
            data: [
                {
                    fromDate: dateRange.start,
                    toDate: dateRange.end
                }
            ]
        })
            .pipe(operators_1.map(res => res.first.events));
    }
    getThreatEventDetails(id) {
        return this.http
            .post('/clp/v1/events/get', { data: [{ id }] })
            .pipe(operators_1.map(res => res.first));
    }
    manualQuarantine(id) {
        return this.http
            .post('/clp/v1/events/quarantine', { data: [{ id }] })
            .pipe(operators_1.map(res => res.first));
    }
    release(id) {
        return this.http
            .post('/clp/v1/events/release', { data: [{ id }] })
            .pipe(operators_1.map(res => res.first));
    }
    reportFalsePositive(id) {
        return this.http
            .post('/clp/v1/events/report-false-positive', { data: [{ id }] })
            .pipe(operators_1.map(res => res.first));
    }
    downloadFile(id, password) {
        const options = {
            observe: 'response',
            responseType: 'arraybuffer'
        };
        return this.http
            .post('/clp/v1/events/download-file', {
            data: [
                {
                    id,
                    password
                }
            ]
        }, options)
            .pipe(operators_1.map((response) => {
            const content = response.body;
            const contentHeader = response.headers.get('Content-Disposition');
            const filename = contentHeader.split('filename=')[1];
            try {
                const mcResponse = JSON.parse(content);
                if (mcResponse && mcResponse.fail && mcResponse.fail[0]) {
                    return Object.assign(Object.assign({}, mcResponse), { hasErrors: true });
                }
            }
            catch (ex) {
                // ignore as it is not a McResponse
            }
            return {
                hasErrors: false,
                first: { file: content, filename }
            };
        }));
    }
}
exports.SwgThreatEventsService = SwgThreatEventsService;
