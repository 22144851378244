"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PurgeMessagesEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const actions = require("../actions");
const components_1 = require("@mimecast-ui/components");
const purge_messages_form_static_values_1 = require("../components/purge-messages-form/purge-messages-form.static-values");
const notification_actions_1 = require("app-new/./components/notification/actions/notification.actions");
const reducers_1 = require("../reducers");
class PurgeMessagesEffects {
    constructor(actions$, service, store, translateService) {
        this.actions$ = actions$;
        this.service = service;
        this.store = store;
        this.translateService = translateService;
        this.getCount$ = this.actions$.pipe(effects_1.ofType(actions.PURGE_MESSAGES_COUNT_REQUEST), operators_1.switchMap((action) => {
            const request = this.transformSearchPayload(action.formValue);
            return this.service.getPurgeDeliveryQueueCount(request).pipe(operators_1.map(response => new actions.PurgeMessagesCountSuccessAction(response)), operators_1.catchError(() => this.getCountFailedObservable()));
        }));
        this.purgeQueue$ = this.actions$.pipe(effects_1.ofType(actions.PURGE_MESSAGES_CONFIRM_PURGE), operators_1.withLatestFrom(this.store.select(reducers_1.getPurgeFormRequest)), operators_1.switchMap(([action, form]) => {
            const request = this.transformPurgePayload(form, action.reason);
            return this.service.purgeDeliveryQueue(request).pipe(operators_1.switchMap(() => [
                new notification_actions_1.NotificationShowAction({
                    type: 'info',
                    config: {
                        msg: this.translateService.instant('$I18N_MESSAGE_CENTER_MESSAGE_DELIVERY_NOTIFICATIONS.PURGE_QUEUE_SUCCESS')
                    }
                }),
                new actions.PurgeMessagesConfirmPurgeSuccess()
            ]), operators_1.catchError(() => this.purgeQueueFailedObservable()));
        }));
    }
    purgeQueueFailedObservable() {
        return rxjs_1.merge(rxjs_1.of(new actions.PurgeMessagesConfirmPurgeFail()), rxjs_1.of(new notification_actions_1.NotificationShowFailAction('$I18N_MESSAGE_CENTER_MESSAGE_DELIVERY_NOTIFICATIONS.PURGE_QUEUE_FAIL')));
    }
    getCountFailedObservable() {
        return rxjs_1.merge(rxjs_1.of(new actions.PurgeMessagesCountFailAction()), rxjs_1.of(new notification_actions_1.NotificationShowFailAction('$I18N_MESSAGE_CENTER_MESSAGE_DELIVERY_NOTIFICATIONS.PURGE_COUNT_FAIL')));
    }
    transformSearchPayload(form) {
        return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ attempts: this.extractAttemptCount(form) }, this.formatFromAndTo(form)), this.formatSubject(form)), this.formatPriority(form)), this.formatDateRange(form)), this.formatCompareExpression(form));
    }
    transformPurgePayload(form, reason) {
        return Object.assign(Object.assign({}, this.transformSearchPayload(form)), { reason });
    }
    extractAttemptCount(form) {
        if (form.includeAttempts) {
            return form.attemptCount;
        }
        return 1;
    }
    formatFromAndTo(form) {
        return Object.assign(Object.assign({}, (form.from ? { fromAddress: form.from } : {})), (form.to ? { toAddress: form.to } : {}));
    }
    formatSubject(form) {
        return Object.assign({}, (form.subject ? { subject: form.subject } : {}));
    }
    formatCompareExpression(form) {
        return Object.assign({}, (form.includeAttempts
            ? { compareExpression: form.attemptOperator }
            : { compareExpression: purge_messages_form_static_values_1.attemptOperators[0].value }));
    }
    formatPriority(form) {
        switch (true) {
            case form.priorityLow:
                return { priority: 'low' };
            case form.priorityNotApplied:
                return { priority: 'normal' };
            default:
                return {};
        }
    }
    formatDateRange(form) {
        if (form.dateRange && form.dateRange.rangeFn) {
            return {
                start: form.dateRange.rangeFn().start.format(components_1.API_DATE_FORMAT),
                end: form.dateRange.rangeFn().end.format(components_1.API_DATE_FORMAT)
            };
        }
        return {};
    }
}
__decorate([
    effects_1.Effect()
], PurgeMessagesEffects.prototype, "getCount$", void 0);
__decorate([
    effects_1.Effect()
], PurgeMessagesEffects.prototype, "purgeQueue$", void 0);
exports.PurgeMessagesEffects = PurgeMessagesEffects;
