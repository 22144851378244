"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgPolicyLoadConnectorsSectionsFailure = exports.SwgPolicyLoadConnectorsSectionsSuccess = exports.SwgPolicyLoadConnectorsSections = exports.SwgPolicyLoadProvidersSectionsFailure = exports.SwgPolicyLoadProvidersSectionsSuccess = exports.SwgPolicyLoadProvidersSections = exports.SWG_POLICY_LOAD_CONNECTORS_SECTIONS_FAILURE = exports.SWG_POLICY_LOAD_CONNECTORS_SECTIONS_SUCCESS = exports.SWG_POLICY_LOAD_CONNECTORS_SECTIONS = exports.SWG_POLICY_LOAD_PROVIDERS_SECTIONS_FAILURE = exports.SWG_POLICY_LOAD_PROVIDERS_SECTIONS_SUCCESS = exports.SWG_POLICY_LOAD_PROVIDERS_SECTIONS = void 0;
exports.SWG_POLICY_LOAD_PROVIDERS_SECTIONS = '[Policy] Swg policy load Providers sections';
exports.SWG_POLICY_LOAD_PROVIDERS_SECTIONS_SUCCESS = '[Policy] Swg policy load connectors Providers success';
exports.SWG_POLICY_LOAD_PROVIDERS_SECTIONS_FAILURE = '[Policy] Swg policy load connectors Providers failure';
exports.SWG_POLICY_LOAD_CONNECTORS_SECTIONS = '[Policy] Swg policy load connectors sections';
exports.SWG_POLICY_LOAD_CONNECTORS_SECTIONS_SUCCESS = '[Policy] Swg policy load connectors sections success';
exports.SWG_POLICY_LOAD_CONNECTORS_SECTIONS_FAILURE = '[Policy] Swg policy load connectors sections failure';
class SwgPolicyLoadProvidersSections {
    constructor() {
        this.type = exports.SWG_POLICY_LOAD_PROVIDERS_SECTIONS;
    }
}
exports.SwgPolicyLoadProvidersSections = SwgPolicyLoadProvidersSections;
class SwgPolicyLoadProvidersSectionsSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SWG_POLICY_LOAD_PROVIDERS_SECTIONS_SUCCESS;
    }
}
exports.SwgPolicyLoadProvidersSectionsSuccess = SwgPolicyLoadProvidersSectionsSuccess;
class SwgPolicyLoadProvidersSectionsFailure {
    constructor() {
        this.type = exports.SWG_POLICY_LOAD_PROVIDERS_SECTIONS_FAILURE;
    }
}
exports.SwgPolicyLoadProvidersSectionsFailure = SwgPolicyLoadProvidersSectionsFailure;
class SwgPolicyLoadConnectorsSections {
    constructor() {
        this.type = exports.SWG_POLICY_LOAD_CONNECTORS_SECTIONS;
    }
}
exports.SwgPolicyLoadConnectorsSections = SwgPolicyLoadConnectorsSections;
class SwgPolicyLoadConnectorsSectionsSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SWG_POLICY_LOAD_CONNECTORS_SECTIONS_SUCCESS;
    }
}
exports.SwgPolicyLoadConnectorsSectionsSuccess = SwgPolicyLoadConnectorsSectionsSuccess;
class SwgPolicyLoadConnectorsSectionsFailure {
    constructor() {
        this.type = exports.SWG_POLICY_LOAD_CONNECTORS_SECTIONS_FAILURE;
    }
}
exports.SwgPolicyLoadConnectorsSectionsFailure = SwgPolicyLoadConnectorsSectionsFailure;
