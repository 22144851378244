<mc-table
  tableId="api-integration/list"
  [isLoading]="isLoading"
  [highlightedRow]="selectedRow"
  [data]="integrations"
  [columns]="columns"
  (rowClick)="viewIntegration($event)"
  translatePrefix="$I18N_API_INTEGRATION_LIST_TABLE"
>
  <button
    class="mc-table-actions btn btn-primary mc-create-button"
    *mcCapabilities="'Permission.API_APPLICATIONS_EDIT'"
    (click)="createIntegration()"
  >
    {{ '$I18N_API_INTEGRATION_LIST_TABLE.ACTIONS.CREATE' | translate }}
  </button>

  <mc-empty-results
    iconClass="far fa-list-alt"
    keyTitle="$I18N_API_INTEGRATION_LIST_TABLE.NO_DATA.TITLE"
  >
  </mc-empty-results>

  <mc-filters
    [showPagination]="true"
    [externalTableSearchCount]="totalCount"
    [metadata]="pagingMetadata"
    (paginatorChange)="changePage($event)"
  >
  </mc-filters>

  <mc-column key="enabled">
    <mc-body-cell *mcBodyCellDef="let row">
      <span *ngIf="isOktaIntegrationAndAwaitingFirstRun(row); else allIntegrations">
        {{ '$I18N_API_INTEGRATION_LIST_TABLE.FIELD_VALUES.AWAITING_FIRST_RUN' | translate }}
      </span>
      <ng-template #allIntegrations>
        <span *ngIf="row.enabled">
          {{ '$I18N_API_INTEGRATION_LIST_TABLE.FIELD_VALUES.ENABLED' | translate }}
        </span>
        <span *ngIf="!row.enabled">
          {{ '$I18N_API_INTEGRATION_LIST_TABLE.FIELD_VALUES.DISABLED' | translate }}
        </span>
      </ng-template>
    </mc-body-cell>
  </mc-column>

  <mc-column key="integrationType">
    <mc-body-cell *mcBodyCellDef="let row">{{
      row.integrationType | mcIntegrationProvider
    }}</mc-body-cell>
  </mc-column>

  <mc-column key="identifier">
    <mc-body-cell *mcBodyCellDef="let row">{{ getIntegrationIdentifier(row.identifier) }}</mc-body-cell>
  </mc-column>

  <mc-column key="description">
    <mc-body-cell *mcBodyCellDef="let row">{{ getIntegrationDescription(row) }}</mc-body-cell>
  </mc-column>

  <mc-column-date key="created"></mc-column-date>

  <mc-column-date key="updated" ></mc-column-date>

  <mc-column-date *mcCapabilities="'Temporary.Services.ApiApplications.Okta.Integration'"
                  showDashOnNull="true"
                  key="activity"></mc-column-date>

  <mc-column-actions
    key="action"
    mcShowColumnConfig
    [columnsAlwaysVisible]="columnsAlwaysVisible"
    [columnsToStartHidden]="columnsStartHidden"
  >
    <mc-row-actions *mcRowActions="let row">
      <li
        mcRowAction="$I18N_API_INTEGRATION_LIST_TABLE.ACTIONS.VIEW"
        (click)="viewIntegration(row)"
      ></li>
      <ng-container *mcCapabilities="'Permission.API_APPLICATIONS_READ'">
        <div *mcCapabilities="'Temporary.Services.ApiApplications.Crowdstrike.Integration.ActivityLogs'">
          <li
            *ngIf="row && row.integrationType === 'crowdstrike'"
            mcRowAction="$I18N_API_INTEGRATION_LIST_TABLE.ACTIONS.VIEW_ACTIVITY"
            (click)="openCrowdstrikeActivityLogPage(row)"
          ></li>
        </div>
      </ng-container>  
      <ng-container *mcCapabilities="'Permission.API_APPLICATIONS_EDIT'">
        <li
          mcRowAction="$I18N_API_INTEGRATION_LIST_TABLE.ACTIONS.DISABLE"
          *ngIf="row.enabled"
          (click)="disableIntegration(row)"
        ></li>
        <li
          mcRowAction="$I18N_API_INTEGRATION_LIST_TABLE.ACTIONS.ENABLE"
          *ngIf="!row.enabled"
          (click)="enableIntegration(row)"
        ></li>
        <li
          mcRowAction="$I18N_API_INTEGRATION_LIST_TABLE.ACTIONS.EDIT"
          (click)="editIntegration(row)"
        ></li>
      </ng-container>
    </mc-row-actions>
  </mc-column-actions>
</mc-table>
