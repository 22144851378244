"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("angular-ui-bootstrap");
require("app/services/services/backup/components/archive-task-actions/archive-task-actions");
require("mimecast-web-components/src/app/components/whenScrolled/when-scrolled-directive");
require("./mailbox-details/mailbox-details");
require("./mailbox-details/mailbox-details.service");
require("./mailboxes.guard");
require("./mailbox-status-message-translator.filter");
require("./mailboxes-list/mailboxes-list");
require("./mailboxes-task/mailboxes-task.tpl.html");
require("./mailboxes.less");
(function () {
    'use strict';
    angular
        .module('services.services.backup.mailboxes', [
        'services.services.backup.mailbox-details',
        'services.services.backup.mailboxes-list',
        'services.services.backup.mailbox-details.service',
        'services.services.backup.mailboxes.guard',
        'whenScrolled.directive',
        'ui.bootstrap',
        'archiveTaskActions',
        'mailboxStatusMessageTranslator.filter'
    ])
        .config([
        '$stateProvider',
        function ($stateProvider) {
            $stateProvider.state('backup-mailboxdetails', {
                url: '/sync-recover/exchange/mailbox',
                params: { mailboxId: null, taskId: null, lastActive: null, initialReplicationDate: null },
                data: {
                    breadcrumbs: [
                        '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_SERVICES',
                        '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ASIDE_MENU_TITLE',
                        '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ARCHIVE_MAILBOXES_MENU_LABEL',
                        '$I18N_BACKUP_RESTORE_BREADCRUMBS_MAILBOX_DETAILS'
                    ],
                    tabId: 'back-up-restore-tabid',
                    asideTitle: '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ASIDE_MENU_TITLE',
                    tabTitle: '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ASIDE_MENU_TITLE',
                    tabHide: false,
                    tabReload: false
                },
                views: {
                    main: {
                        templateUrl: 'services/services/backup/mailboxes/mailbox-details/mailboxes-details.tpl.html'
                    },
                    aside: {
                        templateUrl: 'services/services/backup/aside/aside.tpl.html'
                    }
                },
                resolve: {
                    synchronizingTaskResponse: [
                        'mailboxesGuardService',
                        '$stateParams',
                        function (mailboxesGuardService, $stateParams) {
                            return mailboxesGuardService.getBackupTask($stateParams.taskId);
                        }
                    ]
                },
                onEnter: [
                    '$state',
                    '$stateParams',
                    function ($state, $stateParams) {
                        const mailboxId = $stateParams.mailboxId;
                        if (!mailboxId) {
                            $state.go('mailboxes-list', $stateParams);
                        }
                    }
                ]
            });
        }
    ]);
})();
