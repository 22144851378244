"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClearLablesAction = exports.RemoveLablesAction = exports.AddLabelsAction = exports.CloseSelectReviewersSidePanelAction = exports.ClearQueueWizardDataAction = exports.RemoveGetSelectedReviewersAction = exports.GetSelectedReviewersAction = exports.GetReviewersFailAction = exports.GetReviewersPaginationSuccessAction = exports.GetReviewersSuccessAction = exports.GetReviewersAction = exports.GetEscalationQueuesFailAction = exports.GetEscalationQueuesSuccessAction = exports.GetEscalationQueuesAction = exports.GetQueuesByTypeAction = exports.AddReviewersActionFail = exports.AddReviewersActionSuccess = exports.AddReviewersAction = exports.UpdateActionFail = exports.UpdateActionSuccess = exports.UpdateAction = exports.CreateActionSuccess = exports.CreateActionFail = exports.CreateAction = exports.QueueActions = void 0;
var QueueActions;
(function (QueueActions) {
    QueueActions["CREATE"] = "[SupervisionQueue] Create";
    QueueActions["CREATE_SUCCESS"] = "[SupervisionQueue] Create Success";
    QueueActions["CREATE_FAIL"] = "[SupervisionQueue] Create Fail";
    QueueActions["UPDATE"] = "[SupervisionQueue] Update";
    QueueActions["UPDATE_SUCCESS"] = "[SupervisionQueue] Update Success";
    QueueActions["UPDATE_FAIL"] = "[SupervisionQueue] Update Fail";
    QueueActions["ADD_REVIEWERS"] = "[SupervisionQueue] Add Reviewer";
    QueueActions["ADD_REVIEWERS_SUCCESS"] = "[SupervisionQueue] Add Reviewer Success";
    QueueActions["ADD_REVIEWERS_FAIL"] = "[SupervisionQueue] Add Reviewer Fail";
    QueueActions["GET_QUEUES_BYTYPE"] = "[SupervisionQueue] Get Queues by type";
    QueueActions["GET_ESCALATIONQUEUES"] = "[SupervisionQueue] Get Escalation Queues";
    QueueActions["GET_ESCALATIONQUEUES_SUCCESS"] = "[SupervisionQueue] Get Escalation Queues Suceess";
    QueueActions["GET_ESCALATIONQUEUES_FAIL"] = "[SupervisionQueue] Get Escalation Queues Fail";
    QueueActions["GET_REVIEWERS"] = "[SupervisionQueue] Get Reviewers";
    QueueActions["GET_REVIEWERS_SUCCESS"] = "[SupervisionQueue] Get Reviewers Success";
    QueueActions["GET_REVIEWERS_PAGINATION_SUCCESS"] = "[SupervisionQueue] Get Reviewers with Pagination Success";
    QueueActions["GET_REVIEWERS_FAIL"] = "[SupervisionQueue] Get Reviewers Fail";
    QueueActions["GET_SELECTED_REVIEWERS"] = "[SupervisionQueue] Get Selected Reviewers";
    QueueActions["REMOVE_GET_SELECTED_REVIEWERS"] = "[SupervisionQueue]  Remove Get Selected Reviewers";
    QueueActions["CLEAR_DATA"] = "[SupervisionQueueWizard] Clear Data";
    QueueActions["CLOSE_SELECT_REVIEWERS_PANEL"] = "[SupervisionQueueWizard] Close Select Reviewers Side Panel";
    QueueActions["ADD_LABELS"] = "[SupervisionQueueWizard] Add Lables";
    QueueActions["REMOVE_LABELS"] = "[SupervisionQueueWizard] Remove Lables";
    QueueActions["CLEAR_LABELS"] = "[SupervisionQueueWizard] Clear Lables";
})(QueueActions = exports.QueueActions || (exports.QueueActions = {}));
class CreateAction {
    constructor(paypload) {
        this.paypload = paypload;
        this.type = QueueActions.CREATE;
    }
}
exports.CreateAction = CreateAction;
class CreateActionFail {
    constructor(message, failure) {
        this.message = message;
        this.failure = failure;
        this.type = QueueActions.CREATE_FAIL;
    }
}
exports.CreateActionFail = CreateActionFail;
class CreateActionSuccess {
    constructor(paypload) {
        this.paypload = paypload;
        this.type = QueueActions.CREATE_SUCCESS;
    }
}
exports.CreateActionSuccess = CreateActionSuccess;
class UpdateAction {
    constructor(queueId, queue) {
        this.queueId = queueId;
        this.queue = queue;
        this.type = QueueActions.UPDATE;
    }
}
exports.UpdateAction = UpdateAction;
class UpdateActionSuccess {
    constructor(message) {
        this.message = message;
        this.type = QueueActions.UPDATE_SUCCESS;
    }
}
exports.UpdateActionSuccess = UpdateActionSuccess;
class UpdateActionFail {
    constructor(message, failure) {
        this.message = message;
        this.failure = failure;
        this.type = QueueActions.UPDATE_FAIL;
    }
}
exports.UpdateActionFail = UpdateActionFail;
class AddReviewersAction {
    constructor(selectedReviewers) {
        this.selectedReviewers = selectedReviewers;
        this.type = QueueActions.ADD_REVIEWERS;
    }
}
exports.AddReviewersAction = AddReviewersAction;
class AddReviewersActionSuccess {
    constructor() {
        this.type = QueueActions.ADD_REVIEWERS_SUCCESS;
    }
}
exports.AddReviewersActionSuccess = AddReviewersActionSuccess;
class AddReviewersActionFail {
    constructor() {
        this.type = QueueActions.ADD_REVIEWERS_FAIL;
    }
}
exports.AddReviewersActionFail = AddReviewersActionFail;
class GetQueuesByTypeAction {
    constructor() {
        this.type = QueueActions.GET_QUEUES_BYTYPE;
    }
}
exports.GetQueuesByTypeAction = GetQueuesByTypeAction;
class GetEscalationQueuesAction {
    constructor() {
        this.type = QueueActions.GET_ESCALATIONQUEUES;
    }
}
exports.GetEscalationQueuesAction = GetEscalationQueuesAction;
class GetEscalationQueuesSuccessAction {
    constructor(paypload) {
        this.paypload = paypload;
        this.type = QueueActions.GET_ESCALATIONQUEUES_SUCCESS;
    }
}
exports.GetEscalationQueuesSuccessAction = GetEscalationQueuesSuccessAction;
class GetEscalationQueuesFailAction {
    constructor(paypload) {
        this.paypload = paypload;
        this.type = QueueActions.GET_ESCALATIONQUEUES_FAIL;
    }
}
exports.GetEscalationQueuesFailAction = GetEscalationQueuesFailAction;
class GetReviewersAction {
    constructor(filter) {
        this.filter = filter;
        this.type = QueueActions.GET_REVIEWERS;
    }
}
exports.GetReviewersAction = GetReviewersAction;
class GetReviewersSuccessAction {
    constructor(paypload) {
        this.paypload = paypload;
        this.type = QueueActions.GET_REVIEWERS_SUCCESS;
    }
}
exports.GetReviewersSuccessAction = GetReviewersSuccessAction;
class GetReviewersPaginationSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = QueueActions.GET_REVIEWERS_PAGINATION_SUCCESS;
    }
}
exports.GetReviewersPaginationSuccessAction = GetReviewersPaginationSuccessAction;
class GetReviewersFailAction {
    constructor(paypload) {
        this.paypload = paypload;
        this.type = QueueActions.GET_REVIEWERS_FAIL;
    }
}
exports.GetReviewersFailAction = GetReviewersFailAction;
class GetSelectedReviewersAction {
    constructor() {
        this.type = QueueActions.GET_SELECTED_REVIEWERS;
    }
}
exports.GetSelectedReviewersAction = GetSelectedReviewersAction;
class RemoveGetSelectedReviewersAction {
    constructor(reviewer) {
        this.reviewer = reviewer;
        this.type = QueueActions.REMOVE_GET_SELECTED_REVIEWERS;
    }
}
exports.RemoveGetSelectedReviewersAction = RemoveGetSelectedReviewersAction;
class ClearQueueWizardDataAction {
    constructor() {
        this.type = QueueActions.CLEAR_DATA;
    }
}
exports.ClearQueueWizardDataAction = ClearQueueWizardDataAction;
class CloseSelectReviewersSidePanelAction {
    constructor() {
        this.type = QueueActions.CLOSE_SELECT_REVIEWERS_PANEL;
    }
}
exports.CloseSelectReviewersSidePanelAction = CloseSelectReviewersSidePanelAction;
class AddLabelsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = QueueActions.ADD_LABELS;
    }
}
exports.AddLabelsAction = AddLabelsAction;
class RemoveLablesAction {
    constructor(payload) {
        this.payload = payload;
        this.type = QueueActions.REMOVE_LABELS;
    }
}
exports.RemoveLablesAction = RemoveLablesAction;
class ClearLablesAction {
    constructor() {
        this.type = QueueActions.CLEAR_LABELS;
    }
}
exports.ClearLablesAction = ClearLablesAction;
