"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreferencesModalComponent = void 0;
const __1 = require("../../");
const user_actions_1 = require("../../actions/user.actions");
class PreferencesModalComponent {
    constructor(fb, store) {
        this.fb = fb;
        this.store = store;
    }
    ngOnInit() {
        this.form = this.fb.group({ earlyAccess: false });
        this.adminPreferences$ = this.store.select(__1.getAdminPreferences);
    }
    cancel() {
        this.store.dispatch(new user_actions_1.PreferencesCancelAction());
    }
    save() {
        this.store.dispatch(new user_actions_1.PreferencesSaveAction(this.form.value, true));
    }
}
exports.PreferencesModalComponent = PreferencesModalComponent;
