"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SingleIncidentImplService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const table_store_1 = require("@mimecast-ui/table-store");
class SingleIncidentImplService {
    constructor(http, remediationService, stateService) {
        this.http = http;
        this.remediationService = remediationService;
        this.stateService = stateService;
    }
    getData(payload, pagination = table_store_1.defaultPaginationOption) {
        const config = Object.assign(Object.assign({}, payload), { meta: { pagination } });
        return this.remediationService.incidentMessages(config).pipe(operators_1.map((response) => {
            return {
                hasErrors: response.hasErrors,
                data: response.first.entries,
                failures: response.fail,
                meta: response.meta
            };
        }), operators_1.catchError((response) => {
            return rxjs_1.of({
                hasErrors: response.hasErrors,
                data: response.first.entries,
                failures: response.fail,
                meta: response.meta
            });
        }));
    }
    filter(query, pagination) {
        return this.getData(query, pagination);
    }
    openItem(row) {
        return rxjs_1.of(this.stateService.$state.go('remediation-incident', {
            incidentCode: row.incidentCode,
            incidentId: row.id
        }));
    }
}
exports.SingleIncidentImplService = SingleIncidentImplService;
