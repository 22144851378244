'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const _ = require("lodash");
/**
 * Created by psambandam on 08/12/2016.
 */
angular.module('account.package-info-list.controller', [])
    .controller('PackageInfoListController', ['packageInfoService', 'accountService', '$timeout', 'BUNDLES_PKGS_CONSTANTS', '$q', 'capabilitiesService', '$translate', 'provisioningService', 'modalActionService', function (packageInfoService, accountService, $timeout, BUNDLES_PKGS_CONSTANTS, $q, capabilitiesService, $translate, provisioningService, modalActionService) {
        const self = this;
        self.onColumnClick = onColumnClick;
        self.savePackageInfo = savePackageInfo;
        self.undoPackageInfo = undoPackageInfo;
        self.onToggleSwitch = onToggleSwitch;
        self.isBundledPackage = isBundledPackage;
        self.checkPkgAvailability = checkPkgAvailability;
        self.onFilterBundle = onFilterBundle;
        self.onClickPackageInfoCheckbox = onClickPackageInfoCheckbox;
        self.onClickBundle = onClickBundle;
        self.applyFilter = applyFilter;
        self.cancelFilter = cancelFilter;
        self.onClickAll = onClickAll;
        self.platformPackages = [];
        self.updatedPackageInfo = undefined;
        self.bundlesToBeFiltred = {};
        self.numberOfBundlesChecked = 0;
        self.areAllSelected = false;
        self.isLoading = true;
        self.showLFSConfirmation = false;
        self.originalLFSPackageInfo = {};
        let packagesResponse = [];
        let bundleResponse = [];
        self.packageInfoPackageTooltips = packageInfoService.CONSTANTS.PACKAGE_TOOLTIPS;
        const packagesPromise = accountService.getPackage().then(function (response) {
            if (!response.err) {
                return response.first.packageGroups;
            }
            else {
                // @ts-ignore
                accountService.getError().showErrorNotification(error);
                stopLoading();
            }
        }, function () {
            accountService.getError().showErrorNotification();
            stopLoading();
        });
        const bundlePromise = provisioningService.getBundleInfo().then(function (response) {
            if (!response.err) {
                return response.all[0].products;
            }
            else {
                // @ts-ignore
                provisioningService.getError().showErrorNotification(error);
                stopLoading();
            }
        }, function () {
            provisioningService.getError().showErrorNotification();
            stopLoading();
        });
        $q.all([packagesPromise, bundlePromise]).then(function (data) {
            self.isLoading = false;
            packagesResponse = data[0];
            bundleResponse = data[1];
            activate();
        }, function () {
            stopLoading();
        });
        function stopLoading() {
            self.isLoading = false;
            // @ts-ignore
            return $q.reject(error);
        }
        function onColumnClick(column) {
            column.isSelected = !column.isSelected;
            column.bgcolor = '';
            if (column.isSelected) {
                column.bgcolor = packageInfoService.CONSTANTS.COLUMN_HIGHLIGHT_COLOR;
            }
        }
        function savePackageInfo() {
            const data = preparePayload(self.packageGroups);
            if (self.showLFSConfirmation) {
                return modalActionService.openActionModal(getUpdateConfirmationJson(data)).then(function () { });
            }
            else {
                return onUpdate(data);
            }
        }
        function getUpdateConfirmationJson(data) {
            return {
                title: '$I18N_PACKAGE_INFO_LFS_CONFIRMATION_TITLE',
                contentTemplateURL: 'services/account/package-info/package-info-list/package-info-list-lfs-confirmation.tpl.html',
                params: { payload: data },
                buttons: [{
                        title: '$I18N_MODAL_ACTION_OK',
                        action: onUpdate,
                        type: modalActionService.BUTTON_TYPE.PRIMARY
                    },
                    {
                        title: '$I18N_MODAL_USER_CANCEL_BTN',
                        action: closeActionModal,
                        type: modalActionService.BUTTON_TYPE.DEFAULT
                    }]
            };
        }
        function onUpdate(data) {
            data = (angular.isDefined(data) ? (data) : modalActionService.getModalParams().payload);
            return accountService.updatePackage(data).then(function (response) {
                if (!response.err) {
                    self.updatedPackageInfo = response.first.packageGroups;
                    activate(true);
                    self.showLFSConfirmation = false;
                    if (!_.isEmpty(modalActionService.getModalInstance())) {
                        closeActionModal();
                    }
                }
                else {
                    accountService.getError().showErrorNotification(response, { showAPIErrorMessageText: true });
                }
            }, function () {
                accountService.getError().showErrorNotification();
            });
        }
        function closeActionModal() {
            self.showLFSConfirmation = false;
            modalActionService.closeActionModal();
        }
        function preparePayload(packageGroups) {
            const packages = [];
            angular.forEach(self.platformPackages, function (platformPkg) {
                packages.push(platformPkg);
            });
            packageGroups = [...self.mcPlatformItems, ...packageGroups];
            angular.forEach(packageGroups, function (groups) {
                if (groups.hasOwnPackage && !groups.ownPackage.subscribe && packages.indexOf(groups.ownPackage.id) > -1) {
                    packages.splice(packages.indexOf(groups.ownPackage.id), 1);
                }
                angular.forEach(groups.packages, function (pkg) {
                    if (pkg.subscribe) {
                        packages.push(pkg.id);
                    }
                    if (pkg.name.indexOf(BUNDLES_PKGS_CONSTANTS.LFS_PKG_PREFIX) > -1) {
                        const changed = (pkg.subscribe != self.originalLFSPackageInfo[pkg.type]);
                        if (changed && !pkg.subscribe) {
                            self.showLFSConfirmation = true;
                        }
                    }
                });
            });
            return { packages };
        }
        function undoPackageInfo() {
            $timeout(activatePkgChanges);
        }
        function onToggleSwitch(packageGroupModel) {
            if (packageGroupModel.groupName === packageInfoService.CONSTANTS.PLATFORM_PACKAGE_GROUP ||
                packageGroupModel.groupName === packageInfoService.CONSTANTS.PLATFORM_BASIC_PACKAGE_GROUP) {
                handleMCPlatformToggleState(packageGroupModel);
            }
            else {
                packageGroupModel.subscribedPackagesCount = 0;
                if (!packageInfoService.isLegacyPackage(packageGroupModel) && !packageGroupModel.enableGroup) {
                    angular.forEach(packageGroupModel.packages, function (packageModel) {
                        packageModel.subscribe = false;
                    });
                }
                if (packageGroupModel.hasOwnPackage) {
                    packageGroupModel.ownPackage.subscribe = !packageGroupModel.ownPackage.subscribe;
                    if (packageGroupModel.ownPackage.subscribe) {
                        packageGroupModel.subscribedPackagesCount++;
                    }
                    else {
                        packageGroupModel.subscribedPackagesCount--;
                    }
                }
            }
        }
        function isBundledPackage(pkg) {
            return packageInfoService.CONSTANTS.NON_BUNDLED_PACKAGES.indexOf(pkg) === -1;
        }
        function checkPkgAvailability(pkg, column) {
            return column.packages.indexOf(pkg.name) > -1;
        }
        function handleMCPlatformToggleState(packageGroupModel) {
            const mcPlatformRef = self.mcPlatformItems[0];
            const mcPlatformBasicRef = self.mcPlatformItems[1];
            if (packageGroupModel === mcPlatformRef) {
                packageGroupModel.enableGroup = !!packageGroupModel.enableGroup;
                mcPlatformBasicRef.enableGroup = !mcPlatformBasicRef.enableGroup;
                packageGroupModel.ownPackage.subscribe = !packageGroupModel.ownPackage.subscribe;
                mcPlatformBasicRef.ownPackage.subscribe = !mcPlatformRef.ownPackage.subscribe;
            }
            else if (packageGroupModel === mcPlatformBasicRef) {
                packageGroupModel.enableGroup = !!packageGroupModel.enableGroup;
                mcPlatformRef.enableGroup = !mcPlatformRef.enableGroup;
                packageGroupModel.ownPackage.subscribe = !packageGroupModel.ownPackage.subscribe;
                mcPlatformRef.ownPackage.subscribe = !mcPlatformRef.ownPackage.subscribe;
            }
        }
        function activate(isUpdate) {
            self.platformPackages = [];
            packagesResponse = (isUpdate) ? self.updatedPackageInfo : packagesResponse;
            capabilitiesService.getCapabilities().then(function () {
                self.isDisabled = !capabilitiesService.evalCapabilitiesExpression(packageInfoService.CONSTANTS.PACKAGE_INFO_PERMISSIONS.EDIT);
                if (packagesResponse) {
                    self.platform = _.find(packagesResponse, _.matchesProperty('groupName', packageInfoService.CONSTANTS.PLATFORM_PACKAGE_GROUP));
                    _.forEach(self.platform.packages, function (pkg) {
                        self.platformPackages.push(pkg.id);
                    });
                    self.mcPlatformItems = self.platform.packages.filter((item) => {
                        return item.name.indexOf('Platform') !== -1;
                    }).map((item) => {
                        const isBasic = item.name.indexOf('Basic') !== -1;
                        const enableGroup = item.subscribe === true;
                        return {
                            groupName: item.name,
                            isChangable: true,
                            isBasic,
                            enableGroup,
                            hasOwnPackage: true,
                            ownPackage: angular.copy(item)
                        };
                    }).reverse();
                    packagesResponse.shift();
                    activatePkgChanges();
                }
                if (bundleResponse) {
                    self.bundleResponse = angular.copy(bundleResponse);
                    self.bundles = packageInfoService.getFilteredBundles(self.bundleResponse, BUNDLES_PKGS_CONSTANTS.PRODUCT_PREFIX);
                    self.bundles.forEach(function (bundle) {
                        if (bundle.isChecked) {
                            self.numberOfBundlesChecked++;
                        }
                    });
                    self.areAllSelected = self.numberOfBundlesChecked === self.bundles.length;
                }
            }, function () {
                self.isDisabled = true;
            });
        }
        function activatePkgChanges() {
            self.packageGroups = angular.copy(packagesResponse);
            self.packageGroups.forEach(function (packageGroupModel) {
                packageGroupModel.subscribedPackagesCount = 0;
                packageGroupModel.isChangable = !(packageInfoService.isLegacyPackage(packageGroupModel) || packageInfoService.isStandalonePackage(packageGroupModel));
                packageGroupModel.isNonBundledPackage = packageInfoService.CONSTANTS.NON_BUNDLED_PACKAGES.indexOf(packageGroupModel.groupName) > -1;
                self.platform.packages.forEach(function (platformPackageModel) {
                    if (platformPackageModel.name.indexOf(packageGroupModel.groupName) > -1) {
                        packageGroupModel.hasOwnPackage = true;
                        platformPackageModel.displayName = packageGroupModel.groupName;
                        if (platformPackageModel.subscribe) {
                            packageGroupModel.subscribedPackagesCount++;
                        }
                        packageGroupModel.ownPackage = angular.copy(platformPackageModel);
                    }
                });
                packageGroupModel.packages.forEach(function (packageModel) {
                    packageModel.toolTip = $translate.instant(self.packageInfoPackageTooltips[packageModel.name]);
                    if (packageModel.subscribe) {
                        packageGroupModel.subscribedPackagesCount++;
                    }
                    packageModel.displayName = packageModel.name.indexOf('(') > -1 ? packageModel.name.substr(0, packageModel.name.indexOf('(')) : packageModel.name;
                    packageModel.type = packageModel.name.substr(packageModel.name.indexOf('(') + 1, packageModel.name.indexOf(')') - packageModel.name.indexOf('(') - 1);
                    packageModel.subOrder = packageInfoService.CONSTANTS.PACKAGE_SUB_ORDER[packageModel.type];
                    if (packageModel.name.indexOf(BUNDLES_PKGS_CONSTANTS.LFS_PKG_PREFIX) > -1) {
                        self.originalLFSPackageInfo[packageModel.type] = packageModel.subscribe;
                    }
                });
                packageGroupModel.enableGroup = packageGroupModel.subscribedPackagesCount > 0;
            });
        }
        function onFilterBundle() {
            _.remove(self.bundles, function (item) {
                // @ts-ignore
                return bundle.id === item.id;
            });
        }
        function onClickPackageInfoCheckbox(packageGroupModel, packageModel) {
            if (!packageInfoService.isLegacyPackage(packageGroupModel) && !self.isDisabled) {
                if (packageModel.subscribe) {
                    packageGroupModel.subscribedPackagesCount++;
                }
                else {
                    packageGroupModel.subscribedPackagesCount--;
                }
                packageGroupModel.enableGroup = packageGroupModel.subscribedPackagesCount > 0;
            }
        }
        function onClickAll() {
            self.areAllSelected = !self.areAllSelected;
            self.bundles.forEach(function (bundle) {
                self.bundlesToBeFiltred[bundle.name] = bundle;
                bundle.isChecked = self.areAllSelected;
            });
            self.numberOfBundlesChecked = self.areAllSelected ? self.bundles.length : 0;
        }
        function onClickBundle($event, bundle) {
            self.bundlesToBeFiltred[bundle.name] = bundle;
            bundle.isChecked = !bundle.isChecked;
            if (bundle.isChecked) {
                self.numberOfBundlesChecked++;
            }
            else {
                self.numberOfBundlesChecked--;
            }
        }
        function applyFilter() {
            const areAllVisible = self.numberOfBundlesChecked === 0 || self.numberOfBundlesChecked === self.bundles.length;
            if (self.bundlesToBeFiltred) {
                angular.forEach(self.bundles, function (bundle) {
                    bundle.visibility = areAllVisible || bundle.isChecked;
                });
            }
            self.bundlesToBeFiltred = {};
        }
        function cancelFilter() {
            self.bundlesToBeFiltred = {};
        }
    }]);
