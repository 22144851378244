"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SettingsPanelCancelModalComponent = exports.SettingsPanelSaveModalComponent = void 0;
const settingsAction = require("../../../actions/settings/settings.actions");
const reducers = require("../../../reducers");
class SettingsPanelSaveModalComponent {
    constructor(overlayRef, swgService, store, overlayData) {
        this.overlayRef = overlayRef;
        this.swgService = swgService;
        this.store = store;
        this.overlayData = overlayData;
    }
    ngOnInit() {
        this.overlayRef.updateSize(430);
        this.isUpdatingSettings$ = this.store.select(reducers.isUpdatingSettings);
        this.hasDeviceSettingsInDB$ = this.store.select(reducers.hasDeviceSettingsInDB);
    }
    ngOnDestroy() {
        if (this.isUpdatingSettingsSubscription) {
            this.isUpdatingSettingsSubscription.unsubscribe();
        }
        if (this.hasDeviceSettingsInDBSubscription) {
            this.hasDeviceSettingsInDBSubscription.unsubscribe();
        }
    }
    onClose(save) {
        if (save) {
            this.store.dispatch(new settingsAction.RemediationUpdateSettings(this.overlayData));
            this.hasDeviceSettingsInDBSubscription = this.hasDeviceSettingsInDB$.subscribe(flag => {
                if (flag && this.swgService.hasMSAEditEnabled()) {
                    this.store.dispatch(new settingsAction.RemediationUpdateDeviceSettings(this.overlayData));
                }
            });
            this.isUpdatingSettingsSubscription = this.isUpdatingSettings$.subscribe((isUpdatingSettings) => {
                if (!isUpdatingSettings) {
                    this.overlayRef.close('save');
                }
            });
            return;
        }
        this.overlayRef.close();
    }
}
exports.SettingsPanelSaveModalComponent = SettingsPanelSaveModalComponent;
class SettingsPanelCancelModalComponent {
    constructor(overlayRef) {
        this.overlayRef = overlayRef;
    }
    ngOnInit() {
        this.overlayRef.updateSize(430);
    }
    onClose(result, $event) {
        if ($event) {
            $event.preventDefault();
        }
        this.overlayRef.close(result);
    }
}
exports.SettingsPanelCancelModalComponent = SettingsPanelCancelModalComponent;
