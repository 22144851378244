"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiemChannelsTableComponent = void 0;
const core_1 = require("@angular/core");
const table_store_1 = require("@mimecast-ui/table-store");
const operators_1 = require("rxjs/operators");
class SiemChannelsTableComponent extends table_store_1.TableBaseComponent {
    constructor(store, notificationService, translateService) {
        super(store, 'SiemChannelListTable');
        this.store = store;
        this.notificationService = notificationService;
        this.translateService = translateService;
        this.columnList = ['name', 'description', 'type', 'lastModified', 'right-column'];
        this.columnsAlwaysVisible = ['name', 'description'];
        this.sortableColumns = ['name'];
        this.dataInView = [];
        this.rowClick = new core_1.EventEmitter();
        this.createNewChannel = new core_1.EventEmitter();
        this.editChannel = new core_1.EventEmitter();
        this.deleteChannel = new core_1.EventEmitter();
    }
    ngOnInit() {
        super.ngOnInit();
        this.tableData$.pipe(operators_1.takeWhile(() => !this.stopSubscriptions)).subscribe(data => {
            this.dataInView = data;
        });
    }
    onSort(sortData) {
        this.dataInView = this.sortSiemChannels([...this.dataInView], sortData);
    }
    sortSiemChannels(channels, data) {
        const sortingColumn = data.columnName;
        return channels.sort((x, y) => {
            const status = x[sortingColumn] > y[sortingColumn] ? 1 : -1;
            return data.sortDirection === 'asc' ? status : status * -1;
        });
    }
    goToCreateChannel() {
        this.createNewChannel.emit();
    }
    goToEditChannel(row) {
        if (this.metadataProducts.length) {
            this.editChannel.emit(row);
        }
    }
    copyIdentifierToClipboardSuccess() {
        this.notificationService.success({
            msg: this.translateService.instant('$I18N_SIEM_CHANNELS_LIST_TABLE.IDENTIFIER_COPY_SUCCESS')
        });
    }
}
exports.SiemChannelsTableComponent = SiemChannelsTableComponent;
