"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const logs_page_component_1 = require("./containers/logs-page.component");
angular
    .module('services.dashboard-url-protection-logs-new', [])
    .controller('LogsPageComponent', [
    '$stateParams',
    '$scope',
    function ($stateParams, $scope) {
        $scope.stateParams = $stateParams;
    }
])
    .directive('mcLogsPage', static_1.downgradeComponent({
    component: logs_page_component_1.LogsPageComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        const states = [
            {
                state: 'dashboard-url-protection-logs',
                url: '/services/targetedthreatprotection-urlprotect-monitoring',
                breadcrumbs: [
                    {
                        label: '$I18N_SERVICES_BREADCRUMBS_FIRST_LEVEL',
                        stateName: '',
                        stateParams: { tabName: 'overview' }
                    },
                    {
                        label: '$I18N_SERVICES_URL_PROTECTION.BREADCRUMBS.SECOND_LEVEL',
                        stateName: '',
                        stateParams: { tabName: 'overview' }
                    },
                    {
                        label: '$I18N_SERVICES_URL_PROTECTION.BREADCRUMBS.THIRD_LEVEL',
                        stateName: '',
                        stateParams: { tabName: 'overview' }
                    }
                ]
            },
            // "Monitoring > URL Protection Logs" page for ui-platform
            // https://mimecast.jira.com/browse/UIP-5121
            {
                state: 'dashboard-url-protection-logs-monitoring',
                url: '/services/monitoring/targetedthreatprotection-urlprotect-monitoring',
                breadcrumbs: [
                    { label: '$I18N_MONITORING_BREADCRUMBS_FIRST_LEVEL' },
                    { label: '$I18N_MONITORING_URL_PROTECTION_LOGS' }
                ]
            }
        ];
        for (const { state, url, breadcrumbs } of states) {
            $stateProvider.state(state, {
                url,
                data: {
                    tabTitle: '$I18N_SERVICES_URL_PROTECTION.LOGS.TAB_TITLE',
                    isFixedLayout: false,
                    checkProgress: false,
                    tabReload: false,
                    capabilities: `
            (Temporary.Services.UrlProtection.Logs || Temporary.Services.UrlProtection.Logs.Redirect) &&
            (Permission.SERVICES_TTP_URL_PROTECT_READ || Permission.SERVICES_TTP_URL_PROTECT_EDIT ||
            Permission.MONITORING_TTP_URL_PROTECT_READ || Permission.MONITORING_TTP_URL_PROTECT_EDIT)`,
                    breadcrumbs
                },
                views: {
                    main: {
                        template: '<mc-logs-page></mc-logs-page>',
                        controller: 'LogsPageComponent'
                    }
                }
            });
        }
    }
]);
