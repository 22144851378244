"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetGroupsEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const getGroupAction = require("../actions/get-group.actions");
const getGroupsAction = require("../actions/get-groups.actions");
class GetGroupsEffects {
    constructor(actions$, groupsApiService, notificationService, translation) {
        this.actions$ = actions$;
        this.groupsApiService = groupsApiService;
        this.notificationService = notificationService;
        this.translation = translation;
        this.getGroupCloud$ = this.actions$.pipe(effects_1.ofType(getGroupsAction.GET_GROUPS_CLOUD), operators_1.switchMap((action) => {
            const config = action.payload;
            const successFn = getGroupsAction.GetGroupsCloudSuccess;
            const failFn = getGroupsAction.GetGroupsFail;
            return this.getGroups(config, successFn, failFn);
        }));
        this.getGroupLDAP = this.actions$.pipe(effects_1.ofType(getGroupsAction.GET_GROUPS_LDAP), operators_1.switchMap((action) => {
            const config = action.payload;
            const successFn = getGroupsAction.GetGroupsLDAPSuccess;
            const failFn = getGroupsAction.GetGroupsFail;
            return this.getGroups(config, successFn, failFn);
        }));
        this.getGroup$ = this.actions$.pipe(effects_1.ofType(getGroupAction.GET_GROUP), operators_1.switchMap((action) => {
            const config = action.payload;
            const successFn = getGroupAction.GetGroupSuccess;
            const failFn = getGroupAction.GetGroupFail;
            return this.getGroups(config, successFn, failFn);
        }));
    }
    getGroups(config, successFn, failFn) {
        return this.groupsApiService.getGroup(config).pipe(operators_1.map((response) => new successFn(response)), operators_1.catchError(() => {
            return rxjs_1.of(new failFn()).pipe(operators_1.tap(() => {
                this.notificationService.error({
                    duration: 0,
                    msg: this.translation.instant('$I18N_COMMON_TEXT_LINE_GLOBAL_ERROR_MESSAGE')
                });
            }));
        }));
    }
}
__decorate([
    effects_1.Effect()
], GetGroupsEffects.prototype, "getGroupCloud$", void 0);
__decorate([
    effects_1.Effect()
], GetGroupsEffects.prototype, "getGroupLDAP", void 0);
__decorate([
    effects_1.Effect()
], GetGroupsEffects.prototype, "getGroup$", void 0);
exports.GetGroupsEffects = GetGroupsEffects;
