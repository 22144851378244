"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageDeliveryExportAction = exports.MessageRecalculateRouteFailAction = exports.MessageRecalculateRouteSuccessAction = exports.MessageRecalculateRouteAction = exports.MessageDeliveryRetryLockedEmailsFailAction = exports.MessageDeliveryRetryEmailsFailAction = exports.MessageDeliveryRetryEmailsSuccessAction = exports.MessageDeliveryRetryEmailsAction = exports.MessageDeliveryEarlyBounceEmailsFailAction = exports.MessageDeliveryEarlyBounceEmailsSuccessAction = exports.MessageDeliveryEarlyBounceEmailsAction = exports.MessageDeliveryRejectEmailsFailAction = exports.MessageDeliveryRejectEmailsSuccessAction = exports.MessageDeliveryRejectEmailsAction = exports.MessageDeliveryFailAction = exports.MessageDeliverySuccessAction = exports.MessageDeliveryRequestAction = exports.MessageDeliveryPaginationAction = exports.MessageDeliverySelectRowAction = exports.MessageDeliveryAsidePaginationAction = exports.MessageDeliveryCloseDetailAction = exports.MessageDeliveryRefreshAction = exports.MESSAGE_DELIVERY_EXPORT = exports.MESSAGE_DELIVERY_EARLY_BOUNCE_EMAILS_FAIL = exports.MESSAGE_DELIVERY_EARLY_BOUNCE_EMAILS_SUCCESS = exports.MESSAGE_DELIVERY_EARLY_BOUNCE_EMAILS = exports.MESSAGE_RECALCULATE_ROUTE_FAIL = exports.MESSAGE_RECALCULATE_ROUTE_SUCCESS = exports.MESSAGE_RECALCULATE_ROUTE = exports.MESSAGE_DELIVERY_RETRY_LOCKED_EMAILS_FAIL = exports.MESSAGE_DELIVERY_RETRY_EMAILS_FAIL = exports.MESSAGE_DELIVERY_RETRY_EMAILS_SUCCESS = exports.MESSAGE_DELIVERY_RETRY_EMAILS = exports.MESSAGE_DELIVERY_REJECT_EMAILS_FAIL = exports.MESSAGE_DELIVERY_REJECT_EMAILS_SUCCESS = exports.MESSAGE_DELIVERY_REJECT_EMAILS = exports.MESSAGE_DELIVERY_FAIL = exports.MESSAGE_DELIVERY_SUCCESS = exports.MESSAGE_DELIVERY_REQUEST = exports.MESSAGE_DELIVERY_PAGINATION = exports.MESSAGE_DELIVERY_SELECT_ROW = exports.MESSAGE_DELIVERY_ASIDE_PAGINATION = exports.MESSAGE_DELIVERY_CLOSE_DETAIL = exports.MESSAGE_DELIVERY_REFRESH = void 0;
exports.MESSAGE_DELIVERY_REFRESH = '[Message Center /  Message Delivery]  Message Delivery Refresh';
exports.MESSAGE_DELIVERY_CLOSE_DETAIL = '[Message Center /  Message Delivery]  Message Delivery Close Message Detail';
exports.MESSAGE_DELIVERY_ASIDE_PAGINATION = '[Message Center /  Message Delivery]  Message Delivery Aside Pagination';
exports.MESSAGE_DELIVERY_SELECT_ROW = '[Message Center /  Message Delivery]  Message Delivery Select Row';
exports.MESSAGE_DELIVERY_PAGINATION = '[Message Center / Message Delivery ] Message Delivery Pagination';
exports.MESSAGE_DELIVERY_REQUEST = '[Message Center / Message Delivery ] Request Get Message Delivery';
exports.MESSAGE_DELIVERY_SUCCESS = '[Message Center / Message Delivery ] Get Message Delivery Success';
exports.MESSAGE_DELIVERY_FAIL = '[Message Center / Message Delivery ] Get Message Delivery Fail';
exports.MESSAGE_DELIVERY_REJECT_EMAILS = '[Message Center / Message Delivery ] Reject Message Delivery Emails';
exports.MESSAGE_DELIVERY_REJECT_EMAILS_SUCCESS = '[Message Center / Message Delivery ] Reject Message Delivery Emails Success';
exports.MESSAGE_DELIVERY_REJECT_EMAILS_FAIL = '[Message Center / Message Delivery ] Reject Message Delivery Emails Fail';
exports.MESSAGE_DELIVERY_RETRY_EMAILS = '[Message Center / Message Delivery ] Retry Message Delivery Emails';
exports.MESSAGE_DELIVERY_RETRY_EMAILS_SUCCESS = '[Message Center / Message Delivery ] Retry Message Delivery Emails Success';
exports.MESSAGE_DELIVERY_RETRY_EMAILS_FAIL = '[Message Center / Message Delivery ] Retry Message Delivery Emails Fail';
exports.MESSAGE_DELIVERY_RETRY_LOCKED_EMAILS_FAIL = '[Message Center / Message Delivery ] Retry Message Delivery Locked Emails Fail';
exports.MESSAGE_RECALCULATE_ROUTE = '[Message Center / Message Delivery ] Recalculate Delivery Route';
exports.MESSAGE_RECALCULATE_ROUTE_SUCCESS = '[Message Center / Message Delivery ] Recalculate Delivery Route Success';
exports.MESSAGE_RECALCULATE_ROUTE_FAIL = '[Message Center / Message Delivery ] Recalculate Delivery Route Fail';
exports.MESSAGE_DELIVERY_EARLY_BOUNCE_EMAILS = '[Message Center / Message Delivery ] Early Bounce Message Delivery Emails';
exports.MESSAGE_DELIVERY_EARLY_BOUNCE_EMAILS_SUCCESS = '[Message Center / Message Delivery ] Early Bounce Message Delivery Emails Success';
exports.MESSAGE_DELIVERY_EARLY_BOUNCE_EMAILS_FAIL = '[Message Center / Message Delivery ] Early Bounce Message Delivery Emails Fail';
exports.MESSAGE_DELIVERY_EXPORT = '[Message Center / Message Delivery ] Export Message Delivery Emails';
class MessageDeliveryRefreshAction {
    constructor() {
        this.type = exports.MESSAGE_DELIVERY_REFRESH;
    }
}
exports.MessageDeliveryRefreshAction = MessageDeliveryRefreshAction;
class MessageDeliveryCloseDetailAction {
    constructor() {
        this.type = exports.MESSAGE_DELIVERY_CLOSE_DETAIL;
    }
}
exports.MessageDeliveryCloseDetailAction = MessageDeliveryCloseDetailAction;
class MessageDeliveryAsidePaginationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.MESSAGE_DELIVERY_ASIDE_PAGINATION;
    }
}
exports.MessageDeliveryAsidePaginationAction = MessageDeliveryAsidePaginationAction;
class MessageDeliverySelectRowAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.MESSAGE_DELIVERY_SELECT_ROW;
    }
}
exports.MessageDeliverySelectRowAction = MessageDeliverySelectRowAction;
class MessageDeliveryPaginationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.MESSAGE_DELIVERY_PAGINATION;
    }
}
exports.MessageDeliveryPaginationAction = MessageDeliveryPaginationAction;
class MessageDeliveryRequestAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.MESSAGE_DELIVERY_REQUEST;
    }
}
exports.MessageDeliveryRequestAction = MessageDeliveryRequestAction;
class MessageDeliverySuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.MESSAGE_DELIVERY_SUCCESS;
    }
}
exports.MessageDeliverySuccessAction = MessageDeliverySuccessAction;
class MessageDeliveryFailAction {
    constructor() {
        this.type = exports.MESSAGE_DELIVERY_FAIL;
    }
}
exports.MessageDeliveryFailAction = MessageDeliveryFailAction;
class MessageDeliveryRejectEmailsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.MESSAGE_DELIVERY_REJECT_EMAILS;
    }
}
exports.MessageDeliveryRejectEmailsAction = MessageDeliveryRejectEmailsAction;
class MessageDeliveryRejectEmailsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.MESSAGE_DELIVERY_REJECT_EMAILS_SUCCESS;
    }
}
exports.MessageDeliveryRejectEmailsSuccessAction = MessageDeliveryRejectEmailsSuccessAction;
class MessageDeliveryRejectEmailsFailAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.MESSAGE_DELIVERY_REJECT_EMAILS_FAIL;
    }
}
exports.MessageDeliveryRejectEmailsFailAction = MessageDeliveryRejectEmailsFailAction;
class MessageDeliveryEarlyBounceEmailsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.MESSAGE_DELIVERY_EARLY_BOUNCE_EMAILS;
    }
}
exports.MessageDeliveryEarlyBounceEmailsAction = MessageDeliveryEarlyBounceEmailsAction;
class MessageDeliveryEarlyBounceEmailsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.MESSAGE_DELIVERY_EARLY_BOUNCE_EMAILS_SUCCESS;
    }
}
exports.MessageDeliveryEarlyBounceEmailsSuccessAction = MessageDeliveryEarlyBounceEmailsSuccessAction;
class MessageDeliveryEarlyBounceEmailsFailAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.MESSAGE_DELIVERY_EARLY_BOUNCE_EMAILS_FAIL;
    }
}
exports.MessageDeliveryEarlyBounceEmailsFailAction = MessageDeliveryEarlyBounceEmailsFailAction;
class MessageDeliveryRetryEmailsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.MESSAGE_DELIVERY_RETRY_EMAILS;
    }
}
exports.MessageDeliveryRetryEmailsAction = MessageDeliveryRetryEmailsAction;
class MessageDeliveryRetryEmailsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.MESSAGE_DELIVERY_RETRY_EMAILS_SUCCESS;
    }
}
exports.MessageDeliveryRetryEmailsSuccessAction = MessageDeliveryRetryEmailsSuccessAction;
class MessageDeliveryRetryEmailsFailAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.MESSAGE_DELIVERY_RETRY_EMAILS_FAIL;
    }
}
exports.MessageDeliveryRetryEmailsFailAction = MessageDeliveryRetryEmailsFailAction;
class MessageDeliveryRetryLockedEmailsFailAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.MESSAGE_DELIVERY_RETRY_LOCKED_EMAILS_FAIL;
    }
}
exports.MessageDeliveryRetryLockedEmailsFailAction = MessageDeliveryRetryLockedEmailsFailAction;
class MessageRecalculateRouteAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.MESSAGE_RECALCULATE_ROUTE;
    }
}
exports.MessageRecalculateRouteAction = MessageRecalculateRouteAction;
class MessageRecalculateRouteSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.MESSAGE_RECALCULATE_ROUTE_SUCCESS;
    }
}
exports.MessageRecalculateRouteSuccessAction = MessageRecalculateRouteSuccessAction;
class MessageRecalculateRouteFailAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.MESSAGE_RECALCULATE_ROUTE_FAIL;
    }
}
exports.MessageRecalculateRouteFailAction = MessageRecalculateRouteFailAction;
class MessageDeliveryExportAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.MESSAGE_DELIVERY_EXPORT;
    }
}
exports.MessageDeliveryExportAction = MessageDeliveryExportAction;
