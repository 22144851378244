"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListComponent = void 0;
const core_1 = require("@angular/core");
const table_store_1 = require("@mimecast-ui/table-store");
const policy_1 = require("../../models/policy");
class ListComponent extends table_store_1.TableBaseComponent {
    constructor(store) {
        super(store, 'SwgPolicyList');
        this.store = store;
        this.columnList = [
            'description',
            'policyType',
            'affectedUsers',
            'lastModified',
            'status',
            'right-column'
        ];
        this.columnsAlwaysVisible = ['description', 'status'];
        this.swgPolicyType = policy_1.SwgPolicyType;
        this.rowClick = new core_1.EventEmitter();
        this.createNewPolicy = new core_1.EventEmitter();
        this.goToBlockPageSettings = new core_1.EventEmitter();
        this.editPolicy = new core_1.EventEmitter();
        this.deletePolicy = new core_1.EventEmitter();
        this.disablePolicy = new core_1.EventEmitter();
        this.duplicatePolicy = new core_1.EventEmitter();
        this.checkCLPPolicyForConnector = new core_1.EventEmitter();
        // @ts-ignore
        this.swgAffectedUsersEnum = policy_1.SwgAffectedUsersEnum;
    }
    ngOnInit() {
        super.ngOnInit();
    }
    goToCreatePolicy() {
        this.createNewPolicy.emit(true);
    }
    goToBlockPage() {
        this.goToBlockPageSettings.emit(true);
    }
    onSearchPolicies(data) {
        this.dispatchFilterAction({ policyName: data.search });
    }
    onSearchClear() {
        this.dispatchFilterAction({ policyName: undefined });
    }
    getPolicyDesc(type) {
        if (type === policy_1.SwgPolicyType.WEB_BLOCK_ALLOW_LIST) {
            return '$I18N_SWG_CREATE_POLICY_WEB_BLOCK_ALLOW_LIST';
        }
        return '$I18N_SWG_CREATE_POLICY_' + type;
    }
    onEditClick(policy) {
        if (this.isCLPNonDefaultPolicy(policy)) {
            this.checkCLPPolicyForConnector.emit({ policy, policyAction: policy_1.PolicyAction.EDIT });
        }
        else {
            this.editPolicy.emit(policy);
        }
    }
    onDisable(policy) {
        this.disablePolicy.emit(policy);
    }
    onDuplicateClick(policy) {
        if (this.isCLPNonDefaultPolicy(policy)) {
            this.checkCLPPolicyForConnector.emit({
                policy,
                policyAction: policy_1.PolicyAction.DUPLICATE
            });
        }
        else {
            this.duplicatePolicy.emit(policy);
        }
    }
    onDeleteClick(policy) {
        this.deletePolicy.emit(policy);
    }
    isCLPNonDefaultPolicy(policy) {
        return (policy.policyDesc.policyType === policy_1.SwgPolicyType.CLOUD_PROTECT_POLICY &&
            !policy.clpDefaultPolicy);
    }
    isPolicyEnabled(policy) {
        // WS-3430: Always show Cloud File Protect policies as enabled.
        return (policy.policyDesc.enabled ||
            policy.policyDesc.policyType === policy_1.SwgPolicyType.CLOUD_PROTECT_POLICY);
    }
}
exports.ListComponent = ListComponent;
