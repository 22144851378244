"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserInfoService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
/**
 * This class provides the merge calls to basic api endpoints needed to load adcon, generally this calls
 * will be split in its own service but this particular set of call will be merge in a batch call to the
 * server in a not to distant future to make them faster so they will be just one method, reason why they
 * are not separated in services
 */
class UserInfoService {
    constructor(service, sessionService, location, pseudoCaps) {
        this.service = service;
        this.sessionService = sessionService;
        this.location = location;
        this.pseudoCaps = pseudoCaps;
    }
    getUserInfo() {
        // @ts-ignore
        return rxjs_1.combineLatest([
            this.service.verifyAccess(),
            this.service.getCapabilitiesAndPermissions(),
            this.service.getUserProfile(),
            this.service.getAccount(),
            // preferences sometimes returns error for expired or not found preferences
            // in which case we return {}
            this.service.getPreferences().pipe(operators_1.catchError(() => rxjs_1.of({}))),
            this.service.getLegacyAdconAccessToken(),
            this.service.getAdminPreferences().pipe(operators_1.catchError(() => rxjs_1.of({}))),
            this.service.getCapsOverride(),
            this.service.getConfigExtended()
        ]).pipe(
        // switch needed for getMigratedPolicyInfo() call which depends on permissions data
        operators_1.switchMap((userInfoData) => {
            var _a;
            const policiesReadPermission = (_a = userInfoData[1].permissions) === null || _a === void 0 ? void 0 : _a.find((permission) => permission === 'POLICIES_READ');
            if (policiesReadPermission) {
                return this.service.getMigratedPolicyInfo().pipe(operators_1.map((migratedPolicyData) => {
                    return [...userInfoData, migratedPolicyData];
                }));
            }
            else {
                const defaultMigratedPolicyData = { result: false };
                return rxjs_1.of([...userInfoData, defaultMigratedPolicyData]);
            }
        }), operators_1.map(([verifyAccess, capsAndPermissions, userProfile, account, preferences, legacyAccessToken, adminPreferences, capsOverride, configExtended, migratedPolicyData]) => {
            let capabilities = {};
            if (!capsAndPermissions.firstFail) {
                const combinedCapsAndPermissions = Object.assign(Object.assign({}, capsAndPermissions), { capabilities: [...capsAndPermissions.capabilities, ...verifyAccess.capabilities] });
                capabilities = this.getCapabilities(combinedCapsAndPermissions, 
                // @ts-ignore
                account, adminPreferences &&
                    adminPreferences.preferences &&
                    adminPreferences.preferences.earlyAccess, this.isAccessGranted(verifyAccess.federatedAdminAccess), capsOverride);
            }
            return {
                adminAccess: this.isAccessGranted(verifyAccess.adminAccess),
                federatedAdminAccess: this.isAccessGranted(verifyAccess.federatedAdminAccess),
                profile: userProfile,
                capabilities,
                configExtended,
                preferences: preferences.preferences,
                adminPreferences: adminPreferences && adminPreferences.preferences,
                account,
                legacyAdconLoginRequest: this.getLegacyAdconLoginPayload(legacyAccessToken, account.accountCode),
                migratedPolicy: migratedPolicyData.result,
                capsOverride
            };
        }));
    }
    isAccessGranted(access) {
        return !!access && access.toLowerCase() === 'granted';
    }
    getCapabilities(userCaps, account, earlyAccess = false, federatedAdminAccess = false, capsOverride = {}) {
        const caps = {};
        userCaps.capabilities.forEach(cap => {
            if (cap && cap.name) {
                caps[cap.name] = { enabled: cap.enabled };
            }
        });
        userCaps.permissions.forEach(permission => {
            caps['Permission.' + permission] = { enabled: true };
        });
        const additionalCaps = Object.assign(Object.assign(Object.assign({}, capsOverride), this.pseudoCaps.accountTypeCapabilities(account)), this.pseudoCaps.federatedAdminAccessCapabilities(federatedAdminAccess));
        this.mergeCaps(caps, additionalCaps, account.accountCode || '', earlyAccess);
        return caps;
    }
    mergeCaps(caps, moreCaps, accountCode, earlyAccess) {
        if (moreCaps) {
            Object.keys(moreCaps).forEach(key => {
                const cap = moreCaps[key];
                if (!!cap.earlyAccess) {
                    if (!!earlyAccess) {
                        if (!!cap.accountCodes) {
                            if (cap.accountCodes.indexOf(accountCode) !== -1) {
                                this.setCap(caps, key, cap);
                            }
                        }
                        else {
                            this.setCap(caps, key, cap);
                        }
                    }
                }
                else {
                    if (!!cap.accountCodes) {
                        if (cap.accountCodes.indexOf(accountCode) !== -1) {
                            this.setCap(caps, key, cap);
                        }
                    }
                    else {
                        this.setCap(caps, key, cap);
                    }
                }
            });
        }
    }
    setCap(caps, key, value) {
        if (value.enabled) {
            caps[key] = value;
        }
        else {
            delete caps[key];
        }
    }
    getLegacyAdconLoginPayload(legacyAccessToken, userAccountCode) {
        return {
            IsObamaApp: true,
            Date: legacyAccessToken.date,
            ApplicationId: legacyAccessToken.applicationId,
            Authorization: legacyAccessToken.authenticationV1Request,
            Guid: legacyAccessToken.guid,
            AccountCode: userAccountCode,
            Origin: this.location.origin,
            IsPartnerAuth: !!this.sessionService.session.remoteAccountCode,
            sid: this.sessionService.session.id
        };
    }
    reloadCapabilities(account, earlyAccess = false, federatedAdminAccess = false, capsOverride) {
        return this.service.getCapabilitiesAndPermissions().pipe(operators_1.map(userCaps => {
            return this.getCapabilities(userCaps, account, earlyAccess, federatedAdminAccess, capsOverride);
        }));
    }
}
exports.UserInfoService = UserInfoService;
