<div class="panel-padding-top">
  <mc-held-queue-search (onSearch)="search($event)"> </mc-held-queue-search>

  <div class="panel-padding-top">
    <mc-held-queue-table
      [rows]="items$ | async"
      [externalTableSearchCount]="totalCount$ | async"
      [selectedRow]="selectedRow$ | async"
      [isLoading]="isLoading$ | async"
      [metadata]="metadata$ | async"
      (onFilterChanged)="filterChanged($event)"
      (onPaginatorChanged)="paginatorChanged($event)"
      (onRowClicked)="rowClicked($event)"
      (onMessagesReleased)="messagesReleased($event)"
      (onMessagesReleasedToSandbox)="messagesReleasedToSandbox($event)"
      (onPermittedForRecipient)="permittedForRecipient($event)"
      (onMessagesRejected)="messagesRejected($event)"
      (onMessagesRejectedWithNotification)="messagesRejectedWithNotification($event)"
      (onMessagesRejectedWithNarrative)="messagesRejectedWithNarrative($event)"
      (onBlockedForRecipient)="blockedForRecipient($event)"
      (onMessagesReportedAsSpam)="messagesReportedAsSpam($event)"
      (onMessagesReportedAsMalware)="messagesReportedAsMalware($event)"
      (onMessagesReportedAsPhishing)="messagesReportedAsPhishing($event)"
      (reportMessage)="onReportMessage($event)"
      (onResultsExported)="resultsExported($event)"
    >
    </mc-held-queue-table>
  </div>
</div>
