"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./search-box-directive"); //searchBox.directive
// html and css 
require("./search-box.less");
require("./search-box.tpl.html");
/**
 * Created by asyed.
 */
(function () {
    'use strict';
    angular.module('searchBox', ['searchBox.directive']);
})();
