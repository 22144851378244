<mc-layout-detail-wizard keyTitle="{{ wizardTitle() | translate }}">
  <mc-help-container>
    <mc-help-link url="https://community.mimecast.com/docs/DOC-3181"></mc-help-link>
  </mc-help-container>

  <mc-wizard (changeStep)="changeStep()" [ngClass]="{ 'wizard-busy': isBusy }">
    <mc-wizard-step
      label="{{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.ACTIVATION.TITLE' | translate }}"
    >
      <mc-api-integration-create-activation-step [hidden]="isBusy">
      </mc-api-integration-create-activation-step>
      <div class="spinner-wrapper" *ngIf="isBusy">
        <mc-spinner></mc-spinner>
      </div>
    </mc-wizard-step>

    <mc-wizard-step
      label="{{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.SPYWARE_SETTINGS.TITLE' | translate }}"
    >
      <mc-api-integration-create-spyware-settings-step
        [hidden]="isBusy"
        [cortexSpywareSettings]="cortexSpywareSettings"
        (onUpdateSpywareSettings)="updateSpywareSettings($event)"
      >
      </mc-api-integration-create-spyware-settings-step>
      <div class="spinner-wrapper">
        <mc-spinner *ngIf="isBusy"></mc-spinner>
      </div>
    </mc-wizard-step>

    <mc-wizard-step
      label="{{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.WILDFIRE_SETTINGS.TITLE' | translate }}"
    >
      <mc-api-integration-create-wildfire-settings-step
        [hidden]="isBusy"
        [cortexWildfireSettings]="cortexWildfireSettings"
        (onUpdateWildfireSettings)="updateWildfireSettings($event)"
      >
      </mc-api-integration-create-wildfire-settings-step>
      <div class="spinner-wrapper">
        <mc-spinner *ngIf="isBusy"></mc-spinner>
      </div>
    </mc-wizard-step>


    <mc-wizard-step
      label="{{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.NOTIFICATIONS.TITLE' | translate }}"
    >
      <mc-api-integration-create-notifications-step
        [hidden]="isBusy"
        [emails]="emails"
        [groupsList]="groupsList$ | async"
        (onAddGroup)="addGroup($event)"
        (onDeleteGroup)="deleteGroup($event)"
        (onDeleteEmail)="deleteEmail($event)"
        (onVerifyAndAddEmail)="verifyAndAddEmail($event)"
      >
      </mc-api-integration-create-notifications-step>
      <div class="spinner-wrapper">
        <mc-spinner *ngIf="isBusy"></mc-spinner>
      </div>
    </mc-wizard-step>

    <mc-wizard-step
      label="{{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.AUTHORIZATION.TITLE' | translate }}"
    >
      <mc-api-integration-create-authorisation-step
        [hidden]="isBusy"
        [showAuthorisationWarning]="showAuthorisationWarning"
        (onSubmitted)="nextStep()"
      >
      </mc-api-integration-create-authorisation-step>
      <div class="spinner-wrapper">
        <mc-spinner *ngIf="isBusy"></mc-spinner>
      </div>
    </mc-wizard-step>

    <mc-wizard-step
      label="{{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.SUMMARY.TITLE' | translate }}"
    >
      <mc-api-integration-create-summary-step
        [hidden]="isBusy"
        [cortexIntegrationVersion1]="cortexIntegrationVersion1"
        (onIntegrationStatusUpdated)="integrationStatusUpdated($event)"
        (onGotoStep)="gotoStep($event)"
      >
      </mc-api-integration-create-summary-step>
      <div class="spinner-wrapper">
        <mc-spinner *ngIf="isBusy"></mc-spinner>
      </div>
    </mc-wizard-step>
  </mc-wizard>

  <mc-footer-container>
    <div class="pull-right no-select mc-button-footer">
      <button class="btn" *ngIf="showCancel()" (click)="cancel()">
        {{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.BUTTONS.CANCEL' | translate }}
      </button>
      <button
        *ngIf="showPrevious()"
        class="btn btn-secondary panel-half-margin-right"
        [disabled]="isBusy || !canGotoPrevious()"
        (click)="gotoPreviousStep()"
      >
        {{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.BUTTONS.PREVIOUS' | translate }}
      </button>
      <button
        *ngIf="showUpdateCortex()"
        class="btn btn-primary"
        [disabled]="isBusy || !isStepValid()"
        (click)="updateCortexIntegration()"
      >
        {{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.BUTTONS.SKIP_AUTHORIZATION' | translate }}
      </button>
      <button
        *ngIf="showNext()"
        class="btn btn-primary"
        [disabled]="isBusy || !isStepValid() || !canGotoNext()"
        (click)="gotoNextStep()"
      >
        {{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.BUTTONS.NEXT' | translate }}
      </button>
      <button
        *ngIf="showCreateCortex()"
        class="btn btn-primary"
        [disabled]="isBusy || !isStepValid() || isCortexConfigured()"
        (click)="createCortexIntegration()"
      >
        {{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.BUTTONS.NEXT' | translate }}
      </button>
      <button
        *ngIf="showAuthorize()"
        class="btn btn-primary"
        [disabled]="isBusy || !isStepValid()"
        (click)="authoriseCortexIntegration()"
      >
        {{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.BUTTONS.AUTHORIZE' | translate }}
      </button>
      <mc-live-button
        *ngIf="showComplete()"
        [mcDisabled]="isBusy || !isValid()"
        typeClass="mc-btn btn btn-primary primary"
        content="{{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.BUTTONS.FINISH' | translate }}"
        (click)="complete()"
      >
      </mc-live-button>
    </div>
  </mc-footer-container>
</mc-layout-detail-wizard>
