
    <mc-release-log-detail
      [releaseLog$]="releaseLog$"
      (onClosed)="close()"
      (onPagination)="onPaginate($event)"
      [end$]="end$"
      [total$]="total$"
      [currentItemIndex]="currentItemIndex"
      [selectedRowIndex$]="selectedRowIndex$"
      [isPaginationLoading$]="isPaginationLoading$"
    >
    </mc-release-log-detail>
  