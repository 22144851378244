"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
(function () {
    'use strict';
    /* ======================================================== *\

     = layout / page wizard / directive

     \* ======================================================== */
    document.createElement('mc-layout-page-wizard');
    angular.module('layout.page-wizard.directive', [])
        .directive('mcLayoutPageWizard', [function () {
            return {
                restrict: 'E',
                templateUrl: 'components/layout/page-wizard/page-wizard.tpl.html',
                scope: {
                    mainContainerClass: '@mainContainerClass',
                    mainInlineNotification: '=mainInlineNotification'
                },
                transclude: {
                    mainContainer: 'mainContainer',
                    sideContainer: '?sideContainer'
                },
                link(scope, element, attrs, controller, transcludeFn) {
                    scope.sideContainer = transcludeFn.isSlotFilled('sideContainer');
                }
            };
        }]);
}());
