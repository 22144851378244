"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getKeyGenVerificationDetails = exports.getKeyGenKeys = exports.getKeyGenVerificationCode = exports.getKeyGenVerificationNotification = exports.isKeyGenVerificationRequired = exports.getKeyGenAuthentication = exports.getKeyGenAvailableAuthenticationTypes = exports.getKeyGenAccount = exports.getErrorMessageVerificationApi = exports.isVerificationApiError = exports.getKeyGenErrorMessage = exports.isKeyGenError = exports.isKeyGenBusy = exports.getKeyGenWizardStep = exports.getKeyGenApplication = exports.reducer = exports.initialState = void 0;
const actions = require("../../actions");
const models_1 = require("../../models");
exports.initialState = {
    wizardStep: models_1.KeyGenState.account,
    isBusy: false,
    isError: false,
    isVerificationApiError: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.ApiGatewayListActionTypes.OpenKeyGen:
            return Object.assign(Object.assign({}, state), { application: action.payload });
        case actions.ApiGatewayKeyGenActionTypes.InputAccount:
            return Object.assign(Object.assign({}, state), { emailAddress: action.payload.emailAddress });
        case actions.ApiGatewayKeyGenActionTypes.DiscoverAccount:
        case actions.ApiGatewayKeyGenActionTypes.AuthenticateAccount:
        case actions.ApiGatewayKeyGenActionTypes.VerifyAccount:
            return Object.assign(Object.assign({}, state), { isBusy: true, isError: false });
        case actions.ApiGatewayKeyGenActionTypes.AccountDiscoverySuccess:
            return Object.assign(Object.assign({}, state), { wizardStep: models_1.KeyGenState.authentication, isBusy: false, availableAuthenticationTypes: toSelectItems(action.payload) });
        case actions.ApiGatewayKeyGenActionTypes.InputAuthentication:
            return Object.assign(Object.assign({}, state), { authenticationType: action.payload.authenticationType, password: action.payload.password, revokePreviousBindings: action.payload.revokePreviousBindings });
        case actions.ApiGatewayKeyGenActionTypes.VerificationRequired:
            return Object.assign(Object.assign({}, state), { wizardStep: models_1.KeyGenState.verification, isBusy: false, isVerificationRequired: true, verificationNotification: action.payload.key.verificationCodeDeliveryAddress, accessKey: action.payload.key.accessKey, secretKey: action.payload.key.secretKey });
        case actions.ApiGatewayKeyGenActionTypes.GetApplicationKeySuccess:
            return Object.assign(Object.assign({}, state), { application: Object.assign(Object.assign({}, state.application), { appKey: action.payload }) });
        case actions.ApiGatewayKeyGenActionTypes.InputVerification:
            return Object.assign(Object.assign({}, state), { verificationCode: action.payload.verificationCode });
        case actions.ApiGatewayKeyGenActionTypes.AuthenticationSuccess:
            return Object.assign(Object.assign({}, state), { wizardStep: models_1.KeyGenState.summary, isBusy: false, accessKey: action.payload.accessKey, secretKey: action.payload.secretKey });
        case actions.ApiGatewayKeyGenActionTypes.AccountDiscoveryFailure:
        case actions.ApiGatewayKeyGenActionTypes.VerificationFailure:
        case actions.ApiGatewayKeyGenActionTypes.AuthenticationFailure:
            return Object.assign(Object.assign({}, state), { isBusy: false, isError: true, errorMessage: action.payload });
        case actions.ApiGatewayKeyGenActionTypes.VerificationApiFailure:
            return Object.assign(Object.assign({}, state), { isVerificationApiError: true, errorMessageVerificationApi: action.payload });
        case actions.ApiGatewayKeyGenActionTypes.GoToPreviousStep:
            return Object.assign(Object.assign({}, state), { wizardStep: state.wizardStep - 1 });
        case actions.ApiGatewayKeyGenActionTypes.CloseKeyGenWizard:
            return exports.initialState;
        default:
            return state;
    }
}
exports.reducer = reducer;
function toSelectItems(authTypes) {
    return authTypes.map(auth => {
        return {
            value: auth,
            label: '$I18N_API_GATEWAY_APPLICATIONS_KEYGEN_WIZARD.FIELDS.AUTHENTICATION_TYPE_' +
                auth.toUpperCase()
        };
    });
}
const getKeyGenApplication = (state) => state.application;
exports.getKeyGenApplication = getKeyGenApplication;
const getKeyGenWizardStep = (state) => state.wizardStep;
exports.getKeyGenWizardStep = getKeyGenWizardStep;
const isKeyGenBusy = (state) => state.isBusy;
exports.isKeyGenBusy = isKeyGenBusy;
const isKeyGenError = (state) => state.isError;
exports.isKeyGenError = isKeyGenError;
const getKeyGenErrorMessage = (state) => state.errorMessage;
exports.getKeyGenErrorMessage = getKeyGenErrorMessage;
const isVerificationApiError = (state) => state.isVerificationApiError;
exports.isVerificationApiError = isVerificationApiError;
const getErrorMessageVerificationApi = (state) => state.errorMessageVerificationApi;
exports.getErrorMessageVerificationApi = getErrorMessageVerificationApi;
const getKeyGenAccount = (state) => ({
    emailAddress: state.emailAddress
});
exports.getKeyGenAccount = getKeyGenAccount;
const getKeyGenAvailableAuthenticationTypes = (state) => state.availableAuthenticationTypes;
exports.getKeyGenAvailableAuthenticationTypes = getKeyGenAvailableAuthenticationTypes;
const getKeyGenAuthentication = (state) => ({
    username: state.emailAddress,
    authenticationType: state.authenticationType,
    password: state.password,
    appId: state.application && state.application.appId,
    appKey: state.application && state.application.appKey,
    revokePreviousBindings: state.revokePreviousBindings
});
exports.getKeyGenAuthentication = getKeyGenAuthentication;
const isKeyGenVerificationRequired = (state) => state.isVerificationRequired;
exports.isKeyGenVerificationRequired = isKeyGenVerificationRequired;
const getKeyGenVerificationNotification = (state) => state.verificationNotification;
exports.getKeyGenVerificationNotification = getKeyGenVerificationNotification;
const getKeyGenVerificationCode = (state) => state.verificationCode;
exports.getKeyGenVerificationCode = getKeyGenVerificationCode;
const getKeyGenKeys = (state) => ({
    accessKey: state.accessKey,
    secretKey: state.secretKey
});
exports.getKeyGenKeys = getKeyGenKeys;
const getKeyGenVerificationDetails = (state) => ({
    verificationCode: state.verificationCode,
    accessKey: state.accessKey,
    secretKey: state.secretKey,
    appId: state.application && state.application.appId,
    appKey: state.application && state.application.appKey
});
exports.getKeyGenVerificationDetails = getKeyGenVerificationDetails;
