<mc-collapsable-panel header="$I18N_MESSAGE_CENTER_MESSAGE_INFO_HOLD_RELEASE_INFORMATION.TITLE" [isExpanded]="true">
  <div class="panel-padding-top">
    <div translate="$I18N_MESSAGE_CENTER_MESSAGE_INFO_HOLD_RELEASE_INFORMATION.SUBTITLE_MESSAGE_PROPERTIES"
         class="panel-half-padding-bottom tracking-collapsible-panel-subtitle"></div>
    <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_HOLD_RELEASE_INFORMATION.LABEL_EVENT"
                    [showSecondValue]="true"
                    [secondValue]="holdActionAudit.eventType">
    </mc-value-field>
    <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_HOLD_RELEASE_INFORMATION.LABEL_EVENT_TIME"
                    [showSecondValue]="true"
                    [secondValue]="holdActionAudit.eventTime | mcDatePlusWeekday">
    </mc-value-field>
    <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_HOLD_RELEASE_INFORMATION.LABEL_OPERATOR"
                    [showSecondValue]="true"
                    [secondValue]="holdActionAudit.operator">
    </mc-value-field>
    <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_HOLD_RELEASE_INFORMATION.LABEL_INFORMATION"
                    [showSecondValue]="true"
                    [secondValue]="holdActionAudit.info | mcNoDataAvailableDisplay">
    </mc-value-field>
    <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_HOLD_RELEASE_INFORMATION.LABEL_HOLD_REASON"
                    [showSecondValue]="true"
                    [secondValue]="holdActionAudit.heldReason">
    </mc-value-field>
  </div>
  <div class="panel-padding-top" *ngIf="holdActionAudit.eventType === 'Rejection'">
    <div translate="$I18N_MESSAGE_CENTER_MESSAGE_INFO_HOLD_RELEASE_INFORMATION.SUBTITLE_REJECTION_INFORMATION"
         class="panel-half-padding-bottom tracking-collapsible-panel-subtitle"></div>
    <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_HOLD_RELEASE_INFORMATION.LABEL_REJECTION_TYPE"
                    [showSecondValue]="true"
                    [secondValue]="holdActionAudit.rejectionType">
    </mc-value-field>
    <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_HOLD_RELEASE_INFORMATION.LABEL_ALERT_SENT"
                    [showSecondValue]="true"
                    [secondValue]="holdActionAudit.alertSent">
    </mc-value-field>
  </div>
</mc-collapsable-panel>
