"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateSnapshotComponent = void 0;
const fromExchange = require("../../../actions");
const exchangeReducer = require("../../../reducers/");
class CreateSnapshotComponent {
    constructor(store) {
        this.store = store;
    }
    ngOnInit() {
        this.isRequesting$ = this.store.select(exchangeReducer.getIsRequesting);
        this.snapshotDetails$ = this.store.select(exchangeReducer.getSnapshotDetails);
        this.snapshotCreationfail$ = this.store.select(exchangeReducer.getFail);
        this.hasCreateError$ = this.store.select(exchangeReducer.getHasCreateError);
    }
    ngOnDestroy() {
        this.store.dispatch(new fromExchange.fromSnapshot.ClearStoreAction());
    }
    createSnapshot(createSnapshotPayload) {
        this.store.dispatch(new fromExchange.fromSnapshot.CreateAction(createSnapshotPayload));
    }
    goToSnapshotQueue() {
        this.store.dispatch(new fromExchange.fromSnapshot.GoToSnapshotQueueAction());
    }
    goToSnapshot() { }
}
exports.CreateSnapshotComponent = CreateSnapshotComponent;
