"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueueConfigDetails = void 0;
const queue_config_1 = require("./queue-config");
class QueueConfigDetails extends queue_config_1.QueueConfig {
    constructor(id, name, description, type, createdBy, created, updatedBy, updated, status, forwardAllowed, printAllowed, labels, reviewers, reviewerCount, defaultEscalationQueue, escalationQueues, associatedReviewQueues, associatedRules, reviewQueues) {
        super(id, name, createdBy, updatedBy, created, updated, description, type, printAllowed, forwardAllowed, status, labels, reviewerCount);
        this.id = id;
        this.name = name;
        this.description = description;
        this.type = type;
        this.createdBy = createdBy;
        this.created = created;
        this.updatedBy = updatedBy;
        this.updated = updated;
        this.status = status;
        this.forwardAllowed = forwardAllowed;
        this.printAllowed = printAllowed;
        this.labels = labels;
        this.reviewers = reviewers;
        this.reviewerCount = reviewerCount;
        this.defaultEscalationQueue = defaultEscalationQueue;
        this.escalationQueues = escalationQueues;
        this.associatedReviewQueues = associatedReviewQueues;
        this.associatedRules = associatedRules;
        this.reviewQueues = reviewQueues;
    }
}
exports.QueueConfigDetails = QueueConfigDetails;
