"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MepSummaryComponent = void 0;
const models_1 = require("../../../models/models");
class MepSummaryComponent {
    constructor(translateService) {
        this.translateService = translateService;
        this.users = [];
        this.groups = [];
    }
    ngOnInit() {
        this.update();
    }
    ngOnChanges(changes) {
        const change = changes['policy'];
        if (change && !change.isFirstChange()) {
            this.update();
        }
    }
    update() {
        if (this.policy.everyone) {
            this.users = [];
            this.groups = [];
            return;
        }
        this.users = [];
        this.groups = [];
        for (const target of this.policy.rules) {
            if (target.condition === models_1.MepAffectedUsersEnum.PROFILE_GROUP) {
                // @ts-ignore
                this.groups.push(target.payload);
            }
            if (target.condition === models_1.MepAffectedUsersEnum.INDIVIDUAL_EMAIL_ADDRESS) {
                // @ts-ignore
                this.users.push(target.payload);
            }
        }
        // @ts-ignore
        this.users.sort((x, y) => (x.emailAddress > y.emailAddress ? 1 : -1));
        // @ts-ignore
        this.groups.sort((x, y) => (x.description > y.description ? 1 : -1));
    }
    getStatusSwitchTitle() {
        return this.isEditFlow
            ? this.translateService.instant('$I18N_MEP_EDIT_POLICY_POLICY_STATUS.TITLE')
            : this.translateService.instant('$I18N_MEP_CREATE_POLICY_POLICY_STATUS.TITLE');
    }
    getStatusSwitchDescription() {
        return this.isEditFlow
            ? this.translateService.instant('$I18N_MEP_EDIT_POLICY_POLICY_STATUS.DESCRIPTION')
            : this.translateService.instant('$I18N_MEP_CREATE_POLICY_POLICY_STATUS.DESCRIPTION');
    }
}
exports.MepSummaryComponent = MepSummaryComponent;
