"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.systemNotifications = void 0;
const actions = require("../actions/system-messages.action");
exports.systemNotifications = {
    [actions.SYSTEM_MESSAGES_REJECT_EMAILS_SUCCESS]: {
        message: '$I18N_MESSAGE_CENTER_MESSAGE_PROCESSING_NOTIFICATIONS.REJECT_EMAILS_SUCCESS',
        type: 'success'
    },
    [actions.SYSTEM_MESSAGES_REJECT_EMAILS_FAIL]: {
        message: '$I18N_MESSAGE_CENTER_MESSAGE_PROCESSING_NOTIFICATIONS.REJECT_EMAILS_FAIL',
        type: 'error'
    },
    [actions.SYSTEM_MESSAGES_RETRY_EMAILS_SUCCESS]: {
        message: '$I18N_MESSAGE_CENTER_MESSAGE_PROCESSING_NOTIFICATIONS.RETRY_EMAILS_SUCCESS',
        type: 'success'
    },
    [actions.SYSTEM_MESSAGES_RETRY_EMAILS_FAIL]: {
        message: '$I18N_MESSAGE_CENTER_MESSAGE_PROCESSING_NOTIFICATIONS.RETRY_EMAILS_FAIL',
        type: 'error'
    },
    [actions.SYSTEM_MESSAGES_EARLY_BOUNCE_EMAILS_SUCCESS]: {
        message: '$I18N_MESSAGE_CENTER_MESSAGE_PROCESSING_NOTIFICATIONS.EARLY_BOUNCE_SUCCESS',
        type: 'success'
    },
    [actions.SYSTEM_MESSAGES_EARLY_BOUNCE_EMAILS_FAIL]: {
        message: '$I18N_MESSAGE_CENTER_MESSAGE_PROCESSING_NOTIFICATIONS.EARLY_BOUNCE_FAIL',
        type: 'error'
    }
};
