"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgEndpointsListComponent = void 0;
const core_1 = require("@angular/core");
const table_store_1 = require("@mimecast-ui/table-store");
const exportActions = require("../../actions/export.actions");
const endpoints_list_static_values_1 = require("./endpoints-list.static-values");
const endpoints_list_models_1 = require("../../models/endpoints-list.models");
class SwgEndpointsListComponent extends table_store_1.TableBaseComponent {
    constructor(store, stateService, swgService, datePipeService, dataMappingService) {
        super(store, 'SwgEndpointsListTable');
        this.store = store;
        this.stateService = stateService;
        this.swgService = swgService;
        this.datePipeService = datePipeService;
        this.dataMappingService = dataMappingService;
        this.hasDeviceBasedPoliciesSwitchEnabled = false;
        this.purgeEndpointsList = new core_1.EventEmitter();
        this.tableRowClicked = new core_1.EventEmitter();
        this.columns = [...endpoints_list_static_values_1.columns];
        this.tableFilterValues = endpoints_list_static_values_1.tableFilters;
        this.searchFilters = endpoints_list_static_values_1.searchFilters;
        this.columnsAlwaysVisible = endpoints_list_static_values_1.columnsAlwaysVisible;
        this.isSelectedAll = false;
        this.columnsToIgnore = endpoints_list_static_values_1.columnsToIgnore;
        this.exportColumns = endpoints_list_static_values_1.columnsToExport;
        this.additionalExportColumns = endpoints_list_static_values_1.additionalExportColumns;
        this.selectedRows = [];
        this.dateFormat = 'dd MMM yyyy';
        this.timezoneOffset = '';
        this.tableFilters = { filterBy: [] };
        this.tableSearch = { searches: [] };
        this.privateIpEndpointsSupport = this.swgService.hasPrivateIpEndpointsSupport();
        this.columnsToStartHidden = [...endpoints_list_static_values_1.columnsToStartHidden];
        if (!this.privateIpEndpointsSupport) {
            this.columns = this.columns.filter(x => ['privateIp'].indexOf(x) === -1);
        }
        this.timezoneOffset = this.datePipeService.timeZoneOffset;
    }
    ngOnInit() {
        super.ngOnInit();
        if (!this.hasEndpointsDetailsEnabled || !this.hasPurgePermission) {
            this.columns = this.columns.filter(column => ['select'].indexOf(column) === -1);
        }
        if (this.hasEndpointsDetailsEnabled) {
            this.columnsToExport = [...this.exportColumns, ...this.additionalExportColumns];
        }
        else {
            this.columnsToExport = [...this.exportColumns];
        }
    }
    onExportClicked() {
        this.store.dispatch(new exportActions.SwgExportEndpointsListAction(this.columnsToExport.slice(0, -1)));
    }
    onSearch(filters) {
        this.filterSearch = { searchBy: { fieldName: 'deviceName', value: filters.search } };
        this.runFilterSearch();
    }
    onSearchClear() {
        this.filterSearch = undefined;
        this.runFilterSearch();
    }
    onBundleFilterChange(filters) {
        this.filtersStatus = {
            filterBy: filters.filterBy &&
                filters.filterBy.map((val) => ({ fieldName: 'status', value: val }))
        };
        this.runFilterSearch();
    }
    runFilterSearch() {
        const searchPayload = Object.assign(Object.assign({}, this.filterSearch), this.filtersStatus);
        this.onFilterChange(searchPayload);
    }
    onSearchNew(filters) {
        this.tableSearch.searches = this.tableSearch.searches.filter(f => f.fieldName !== 'deviceName');
        this.tableSearch.searches = [
            ...this.tableSearch.searches,
            { fieldName: 'deviceName', value: filters.search }
        ];
        this.runFilterSearchNew();
    }
    onSearchClearNew() {
        this.tableSearch.searches = this.tableSearch.searches.filter(f => f.fieldName !== 'deviceName');
        this.runFilterSearchNew();
    }
    onBundleFilterChangeNew(filters) {
        this.tableFilters = { filterBy: [] };
        this.tableSearch.searches = this.tableSearch.searches.filter(f => f.fieldName !== 'os');
        if (filters.status) {
            this.tableFilters.filterBy = filters.status.map((val) => ({
                fieldName: 'status',
                value: val
            }));
        }
        if (filters.os) {
            filters.os.forEach((os) => {
                this.tableSearch.searches = [...this.tableSearch.searches, { fieldName: 'os', value: os }];
            });
        }
        this.runFilterSearchNew();
    }
    runFilterSearchNew() {
        const search = this.tableSearch.searches.length ? this.tableSearch : undefined;
        const filters = this.tableFilters.filterBy.length ? this.tableFilters : undefined;
        this.onFilterChange(Object.assign(Object.assign({}, search), filters));
    }
    goToSecurityAgentPage() {
        this.stateService.$state.go('secure-web-gateway-endpoints');
    }
    selectRows(rows) {
        this.selectedRows = rows;
    }
    isSelectedRow() {
        return this.selectedRows.length > 0;
    }
    prepareIdListToPurge() {
        return this.selectedRows.map((row) => row.id);
    }
    purgeEndpoints() {
        this.purgeEndpointsList.emit(this.prepareIdListToPurge());
    }
    reloadEndpointsList() {
        this.runFilterSearch();
        this.filterBundleComponent.resetPageNumber();
    }
    onRowClick(row) {
        super.onRowClick(row);
        if (this.hasEndpointsDetailsEnabled) {
            this.tableRowClicked.emit(true);
        }
    }
    isWindowsDeviceWithLatestMSA(row) {
        return (!!row.protectedDeviceDetail &&
            !!row.protectedDeviceDetail.osEdition &&
            row.protectedDeviceDetail.osEdition.toLowerCase().includes(endpoints_list_models_1.DEVICE.WINDOWS));
    }
    isWindowsDevice(row) {
        return !!row.os && row.os.toLowerCase().includes(endpoints_list_models_1.DEVICE.WINDOWS);
    }
    hasMultiUsersSupport(row) {
        return (!!row.protectedDeviceDetail &&
            !!row.protectedDeviceDetail.osEdition &&
            this.dataMappingService.hasMultiUsersSupport(row.protectedDeviceDetail.osEdition));
    }
}
exports.SwgEndpointsListComponent = SwgEndpointsListComponent;
