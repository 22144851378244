<mc-layout-aside-extra-container keyTitle="Forward" showClose="true" [isLoading]='false'
                                 (closeAside)="close()">
  <mc-extra-container>
    <p>{{'$I18N_MESSAGE_CENTER_FORWARD_PANEL.DESCRIPTION' | translate}}</p>
    <div>
      <button class="btn btn-primary panel-margin-left" data-test="send"
              [disabled]="!form.valid || isContentExpired" (click)="send()">
        <i *ngIf="isSendingForward$ | async" class="far fa-spinner fa-pulse fa-fw" data-test="buttonSpinner"></i>
        {{'$I18N_MESSAGE_CENTER_FORWARD_PANEL.FORWARD_BUTTON' | translate}}
      </button>
      <button class="btn btn-secondary panel-margin-left" data-test="cancel" (click)="close()">
        {{'$I18N_ADMINISTRATION_COMMONS_BTN_LABEL_CANCEL' | translate}}
      </button>
    </div>
  </mc-extra-container>
  <mc-body-container>
    <mc-email-compose-form [formControl]="form"
                           [mcFormValue]='forwardingEmail$ | async'></mc-email-compose-form>
  </mc-body-container>
</mc-layout-aside-extra-container>
