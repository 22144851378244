"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MepLogLoadDetailsFailureAction = exports.MepLogLoadDetailsSuccessAction = exports.MepLogLoadDetailsAction = exports.MepLogSidebarClose = exports.MepLogOpenItemSidebar = exports.MepLogSidebarActionsEnums = void 0;
var MepLogSidebarActionsEnums;
(function (MepLogSidebarActionsEnums) {
    MepLogSidebarActionsEnums["MEP_LOG_OPEN_ITEM_SIDEBAR"] = "[MEP Logs] MEP Policy Open Item Sidebar";
    MepLogSidebarActionsEnums["MEP_LOG_SIDEBAR_RELEASE"] = "[MEP Log Sidebar] MEP Release Message";
    MepLogSidebarActionsEnums["MEP_LOG_SIDEBAR_DROP"] = "[MEP Log Sidebar] MEP Drop Message";
    MepLogSidebarActionsEnums["MEP_LOG_SIDEBAR_CLOSE"] = "[MEP Log Sidebar] MEP Sidebar Close";
    MepLogSidebarActionsEnums["MEP_LOG_LOAD_DETAILS"] = "[MEP Log Sidebar] MEP Load Log Details";
    MepLogSidebarActionsEnums["MEP_LOG_LOAD_DETAILS_SUCCESS"] = "[MEP Log Sidebar] MEP Load Log Details Success";
    MepLogSidebarActionsEnums["MEP_LOG_LOAD_DETAILS_FAILURE"] = "[MEP Log Sidebar] MEP Load Log Details Failure";
})(MepLogSidebarActionsEnums = exports.MepLogSidebarActionsEnums || (exports.MepLogSidebarActionsEnums = {}));
class MepLogOpenItemSidebar {
    constructor() {
        this.type = MepLogSidebarActionsEnums.MEP_LOG_OPEN_ITEM_SIDEBAR;
    }
}
exports.MepLogOpenItemSidebar = MepLogOpenItemSidebar;
class MepLogSidebarClose {
    constructor() {
        this.type = MepLogSidebarActionsEnums.MEP_LOG_SIDEBAR_CLOSE;
    }
}
exports.MepLogSidebarClose = MepLogSidebarClose;
class MepLogLoadDetailsAction {
    constructor(id) {
        this.id = id;
        this.type = MepLogSidebarActionsEnums.MEP_LOG_LOAD_DETAILS;
    }
}
exports.MepLogLoadDetailsAction = MepLogLoadDetailsAction;
class MepLogLoadDetailsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = MepLogSidebarActionsEnums.MEP_LOG_LOAD_DETAILS_SUCCESS;
    }
}
exports.MepLogLoadDetailsSuccessAction = MepLogLoadDetailsSuccessAction;
class MepLogLoadDetailsFailureAction {
    constructor(payload) {
        this.payload = payload;
        this.type = MepLogSidebarActionsEnums.MEP_LOG_LOAD_DETAILS_FAILURE;
    }
}
exports.MepLogLoadDetailsFailureAction = MepLogLoadDetailsFailureAction;
