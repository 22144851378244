"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgLocationsListComponent = void 0;
const table_store_1 = require("@mimecast-ui/table-store");
const swg_locations_list_static_values_1 = require("../../swg-locations-list.static-values");
const swg_location_sidebar_actions_1 = require("app-new/swg/locations/actions/swg-location-sidebar.actions");
const operators_1 = require("rxjs/operators");
class SwgLocationsListComponent extends table_store_1.TableBaseComponent {
    constructor(store, capabilitiesService) {
        super(store, 'SwgLocationsListTable');
        this.store = store;
        this.capabilitiesService = capabilitiesService;
        this.columns = swg_locations_list_static_values_1.columns;
        this.filterSearchOptions = swg_locations_list_static_values_1.filterSearchOptions;
        this.isEditable$ = this.capabilitiesService.hasCapability('Permission.SWG_CONFIGURE_SITES_EDIT');
    }
    onRowClick(row) {
        this.isEditable$
            .pipe(operators_1.first())
            .subscribe(isEditable => (isEditable ? this.editLocation(row) : null));
    }
    onSearch(filters) {
        this.filterSearch = { searchField: filters.filterBy, query: filters.search };
        this.runFilterSearch();
    }
    onSearchClear() {
        this.filterSearch = undefined;
        this.runFilterSearch();
    }
    onBundleFilterChange(filters) {
        this.filtersStatus = {
            filterBy: filters.filterBy &&
                filters.filterBy.map((val) => ({ fieldName: 'status', value: val }))
        };
        this.runFilterSearch();
    }
    runFilterSearch() {
        const searchPayload = Object.assign(Object.assign({}, this.filterSearch), this.filtersStatus);
        this.onFilterChange(searchPayload);
    }
    deleteLocation(location) {
        this.store.dispatch(new swg_location_sidebar_actions_1.SwgLocationsDeleteSidebarOpenAction(location));
    }
    editLocation(location) {
        this.store.dispatch(new swg_location_sidebar_actions_1.SwgLocationsEditAddSidebarOpenAction({ swgLocation: location, editFlow: true }));
    }
    addLocation() {
        this.store.dispatch(new swg_location_sidebar_actions_1.SwgLocationsEditAddSidebarOpenAction({ editFlow: false }));
    }
}
exports.SwgLocationsListComponent = SwgLocationsListComponent;
