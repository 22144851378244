"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PurgeMessagesService = void 0;
const operators_1 = require("rxjs/operators");
const moment = require("moment");
const components_1 = require("@mimecast-ui/components");
class PurgeMessagesService {
    constructor(http) {
        this.http = http;
    }
    getPurgeDeliveryQueueCount(request) {
        return this.http
            .post('/api/gateway/purge/get-delivery-queue-count', this.transformToServerRequest(request))
            .pipe(operators_1.map(response => response.first));
    }
    purgeDeliveryQueue(request) {
        return this.http
            .post('/api/gateway/purge/purge-delivery-queue', this.transformToServerRequest(request))
            .pipe(operators_1.map(response => response.first));
    }
    transformToServerRequest(request) {
        const { start, end } = request, remainder = __rest(request, ["start", "end"]);
        return Object.assign(Object.assign(Object.assign({}, remainder), (!!start ? { start: moment(request.start).format(components_1.API_DATE_FORMAT) } : {})), (!!end ? { end: moment(request.end).format(components_1.API_DATE_FORMAT) } : {}));
    }
}
exports.PurgeMessagesService = PurgeMessagesService;
