<mc-table
  tableId="message-center/system-messages-table"
  [isLoading]="isLoading"
  [highlightedRow]="selectedRow"
  [columns]="columns"
  [data]="rows"
  translatePrefix="$I18N_MESSAGE_CENTER_SYSTEM_MESSAGES_TABLE.TABLE">
  <button
    [disabled]="!isSelectedRow()"
    class="mc-table-actions btn btn-primary mc-button-margin-right"
    (click)="retryMessages()"
    *mcCapabilities="'Permission.PROCESSING_EDIT'"
  >
    {{ '$I18N_MESSAGE_CENTER_SYSTEM_MESSAGES_TABLE.ACTIONS.RETRY' | translate }}
  </button>

  <button
    [disabled]="!isSelectedRow()"
    class="mc-table-actions btn btn-primary mc-button-margin-right"
    (click)="rejectMessages()"
    *mcCapabilities="'Permission.PROCESSING_EDIT'"
  >
    {{ '$I18N_MESSAGE_CENTER_SYSTEM_MESSAGES_TABLE.ACTIONS.REJECT' | translate }}
  </button>

  <button
    [disabled]="!isSelectedRow()"
    class="mc-table-actions btn btn-primary mc-button-margin-right"
    (click)="earlyBounceMessages()"
    *mcCapabilities="'Permission.PROCESSING_EDIT'"
  >
    {{ '$I18N_MESSAGE_CENTER_SYSTEM_MESSAGES_TABLE.ACTIONS.EARLY_BOUNCE' | translate }}
  </button>

  <button
    class="mc-table-actions btn btn-secondary mc-button-margin-right"
    (click)="exportResults()"
  >
    {{ '$I18N_MESSAGE_CENTER_SYSTEM_MESSAGES_TABLE.ACTIONS.EXPORT' | translate }}
  </button>

  <mc-filters [externalTableSearchCount]="externalTableSearchCount" [metadata]="metadata" [showPagination]="true"
    (paginatorChange)="changePaginator($event)">

    <mc-filter-column-select-and-search
      [sections]="searchSections"
      [placeholder]="
        '$I18N_MESSAGE_CENTER_SYSTEM_MESSAGES_TABLE.TABLE.COLUMN_VALUE_SINGLE_SELECT_FILTER_FIELD.PLACEHOLDER'
          | translate
      "
      (filtersChange)="changeSearchFilter($event)"
    >
    </mc-filter-column-select-and-search>

    <div class="mc-filters-right">
      <mc-filters-bundle-date-range [config]="dateRangePickerConfig" (filtersChange)="changeDateFilter($event)"
        [useCurrentMoment]="true">
      </mc-filters-bundle-date-range>
      <mc-filters-bundle-column-values [sections]="filterSections" (filtersChange)="changeColumnFilter($event)">
      </mc-filters-bundle-column-values>
    </div>
  </mc-filters>

  <mc-empty-results keyTitle="$I18N_MESSAGE_CENTER_SYSTEM_MESSAGES_TABLE.TABLE.EMPTY_RESULTS"
    iconClass="mc-icon-adcon-icon-stable-no-results-2">
  </mc-empty-results>

  <mc-column-select key="select" (selectChange)="selectRows($event)"></mc-column-select>

  <mc-column-email key="fromEnv" emailKey="emailAddress"></mc-column-email>

  <mc-column-email key="to" emailKey="emailAddress"></mc-column-email>

  <mc-column key="routing">
    <mc-body-cell *mcBodyCellDef="let row">{{ row.routing }}</mc-body-cell>
  </mc-column>

  <mc-column key="messageType">
    <mc-body-cell *mcBodyCellDef="let row">{{ row.type }}</mc-body-cell>
  </mc-column>

  <mc-column key="info">
    <mc-body-cell *mcBodyCellDef="let row">{{ row.info }}</mc-body-cell>
  </mc-column>

  <mc-column-date key="created"></mc-column-date>

  <mc-column key="attempts">
    <mc-body-cell *mcBodyCellDef="let row">{{ row.attempts }}</mc-body-cell>
  </mc-column>

  <mc-column-actions
    key="right-column"
    mcShowColumnConfig
    [columnsAlwaysVisible]="columnsAlwaysVisible"
    [columnsToStartHidden]="columnsToStartHidden"
    [columnsToIgnore]="columnsToIgnore"
  >
  <ng-container *mcCapabilities="'Permission.PROCESSING_EDIT'">
    <mc-row-actions *mcRowActions="let row">
      <li
        mcRowAction="$I18N_MESSAGE_CENTER_SYSTEM_MESSAGES_TABLE.ACTIONS.RETRY"
        (click)="retryMessages([row])"></li>
      <li
        mcRowAction="$I18N_MESSAGE_CENTER_SYSTEM_MESSAGES_TABLE.ACTIONS.REJECT"
        (click)="rejectMessages([row])">
      </li>
      <li
        mcRowAction="$I18N_MESSAGE_CENTER_SYSTEM_MESSAGES_TABLE.ACTIONS.EARLY_BOUNCE"
        (click)="earlyBounceMessages([row])"></li>
    </mc-row-actions>
  </ng-container>
  </mc-column-actions>
</mc-table>