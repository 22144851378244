<mc-layout-list-table keyTitle="$I18N_SYNC_RECOVER_EXCHANGE_TASK_LIST_TITLE"
                      keyDescription="$I18N_SYNC_RECOVER_EXCHANGE_TASK_LIST_DESCRIPTION">

  <!-- TODO: ADD THE EMPTY PLUGIN TO THIS PAGE
      <button translate="$I18N_SYNC_RECOVER_EXCHANGE_TASK_LIST_BUTTON_CREATE_NEW_TASK"></button>
  -->

  <mc-table [data]="tableData$ | async"
            [columns]="columnList">

    <mc-filters [metadata]="metaData$ | async"></mc-filters>
    <mc-column *ngFor="let key of columnList"
               [key]="key">
      <mc-body-cell *mcBodyCellDef="let row"
                    (click)="openItem(row)">{{ row[key] }}</mc-body-cell>
    </mc-column>

  </mc-table>
</mc-layout-list-table>
