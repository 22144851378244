<mc-layout-aside-extra-container keyTitle="{{ queueType === reviewQueueType
  ? '$I18N_ARCHIVE_SUPERVISION_DASHBOARD_SETTINGS_ASIDE.REVIEW_QUEUES.TITLE' 
  : '$I18N_ARCHIVE_SUPERVISION_DASHBOARD_SETTINGS_ASIDE.ESCALATION_QUEUES.TITLE' }}" showClose="true"
[isLoading]="isLoading" (closeAside)="onCancel.emit()"
>
  <mc-extra-container>
    <div class="mc-container-buttons-area">
      <button class="btn btn-primary" [disabled]="!hasEditPermission || isLoading || !form.valid" (click)="onFormSave()">
        {{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD_SETTINGS_ASIDE.ACTIONS.SAVE' | translate }}
      </button>
      <button class="btn btn-secondary" (click)="onCancel.emit()">
        {{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD_SETTINGS_ASIDE.ACTIONS.CANCEL' | translate }}
      </button>
    </div>
  </mc-extra-container>
  <mc-body-container *ngIf="settings">
    <div class="supervision-deashboard-settings">
      <p *ngIf="settings && settings.modified">
        <span>{{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD_SETTINGS_ASIDE.LAST_MODIFIED_BY' | translate }}</span>
        <span> {{ settings.modifiedBy }}</span>
        <span>{{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD_SETTINGS_ASIDE.ON' | translate }}</span>
        <span> {{ settings.modified | date: 'd MMM y - h:mm a'}}</span>
      </p>
      <form
        [formGroup]="form"
        mcDefaultLabelClass="col-sm-4"
        mcDefaultControlContainerClass="col-sm-9"
        mcErrorPrefix="{{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD_SETTINGS_ASIDE.FORM.ERROR' | translate }}"
      >
        <div class="form-group">
          <mc-field 
            class="supervision-dashboard-field"
            label="{{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD_SETTINGS_ASIDE.QUEUES_LABEL' | translate }}"
            useTranslation="true"
            helpPopoverContent="{{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD_SETTINGS_ASIDE.QUEUES_LABEL_HELP' | translate }}"
          >
            <mc-checklist
              *ngIf="!isLoading"
              id="queues"
              formControlName="queues"
              name="queues"
              label="{{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD_SETTINGS_ASIDE.QUEUES_LABEL' | translate }}"
              [options]="queues"
            >
            </mc-checklist>
          </mc-field>  
        </div>

        <div class="row"></div>

        <div class="form-group">
          <mc-field 
            class="supervision-dashboard-field"
            label="{{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD_SETTINGS_ASIDE.PENDING_PERCENTAGES_LABEL' | translate }}"
            useTranslation="true">
            <mc-slider #pendingPercentages
              id="pendingPercentages" 
              formControlName="pendingPercentages" 
              name="pendingPercentages"
              [metadata]="pendingPercentagesMetadata"
            >
            </mc-slider>
          </mc-field>
        </div>

        <div class="row"></div>

        <mc-field 
          class="supervision-dashboard-input"
          labelClass="col-sm-4"
          controlContainerClass="col-sm-2"
          label="{{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD_SETTINGS_ASIDE.LOWER_BOUND_MAXIMUM' | translate }}"
          useTranslation="true">
          <input #pendingLowerBound
            class="form-control"
            type="number"
            maxLength="2" 
            formControlName="pendingLowerBound"
            (blur)="patchInputIfNaN('pendingLowerBound', pendingLowerBound.value, pendingPercentages.value[0])"
            (keypress)="ignoreInputIfExp($event)"
          />
        </mc-field>
        <label>%</label>

        <div class="row"></div>

        <mc-field 
          class="supervision-dashboard-input"
          labelClass="col-sm-4"
          controlContainerClass="col-sm-2"
          label="{{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD_SETTINGS_ASIDE.UPPER_BOUND_MAXIMUM' | translate }}"
          useTranslation="true">
          <input #pendingUpperBound
            class="form-control"
            type="number"
            maxLength="2" 
            formControlName="pendingUpperBound"
            (blur)="patchInputIfNaN('pendingUpperBound', pendingUpperBound.value, pendingPercentages.value[1])"
            (keypress)="ignoreInputIfExp($event)"
          />
        </mc-field>
      <label>%</label>

        <div class="form-group">
          <mc-field class="supervision-dashboard-field"
            label="{{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD_SETTINGS_ASIDE.OLDEST_QUEUE_MESSAGES_LABEL' | translate }}"
            useTranslation="true"
          >
            <mc-slider #oldestQueueMessages
              formControlName="oldestQueueMessages" 
              name="oldestQueueMessages"
              [metadata]="oldestQueueMessagesMetadata"
            >
            </mc-slider>
          </mc-field>
        </div>
        
        <div class="row"></div>

        <mc-field 
          class="supervision-dashboard-input"
          labelClass="col-sm-4"
          controlContainerClass="col-sm-2"
          label="{{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD_SETTINGS_ASIDE.LOWER_BOUND_MAXIMUM' | translate }}"
          useTranslation="true">
          <input #oldestLowerBound
            class="form-control"
            type="number"
            maxLength="2" 
            formControlName="oldestLowerBound"
            (blur)="patchInputIfNaN('oldestLowerBound', oldestLowerBound.value, oldestQueueMessages.value[0])"
            (keypress)="ignoreInputIfExp($event)"
          />
        </mc-field>
        <label>{{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD_SETTINGS_ASIDE.OLDEST_INPUT_POST_LABEL' | translate }}</label>
        
        <div class="row"></div>

        <mc-field 
          class="supervision-dashboard-input"
          labelClass="col-sm-4"
          controlContainerClass="col-sm-2"
          label="{{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD_SETTINGS_ASIDE.UPPER_BOUND_MAXIMUM' | translate }}"
          useTranslation="true">
          <input #oldestUpperBound
            class="form-control"
            type="number"
            maxLength="2" 
            formControlName="oldestUpperBound"
            (blur)="patchInputIfNaN('oldestUpperBound', oldestUpperBound.value, oldestQueueMessages.value[1])"
            (keypress)="ignoreInputIfExp($event)"
          />
        </mc-field>
        <label>{{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD_SETTINGS_ASIDE.OLDEST_INPUT_POST_LABEL' | translate }}</label>
      </form>
    </div>
  </mc-body-container>
</mc-layout-aside-extra-container>
