<div class="tech-partners-list">
  <div class="tech-partners-link">
    {{ '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.LINK.DESCRIPTION' | translate }}
    <a
      [href]="techPartnersLink"
      target="_blank"
      rel="noopener noreferrer"
    >
      {{ '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.LINK.TEXT' | translate }}
    </a>
  </div>
  <div class="cards">
    <ng-container *ngFor="let partner of partnersList">
      <div
        class="cards-item"
      >
        <mc-card
          [class]="partner.id"
          [image]="getLogo(partner.id)"
          *mcCapabilities="'Permission.API_APPLICATIONS_EDIT'"
          title="{{
            '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.TECH_PARTNERS.' + partner.id + '.TITLE' | uppercase | translate
          }}"
          description="{{
            '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.TECH_PARTNERS.' + partner.id + '.DESCRIPTION' | uppercase | translate
          }}"
          [buttonText]="getButtonText(partner.isInternal, partner.id)"
          [useTranslation]="false"
          [optionsList]="partner.optionsList"
          (partnerClick)="onPartnerClicked(partner.isInternal, partner.id, partner.category, partner.service)"
          (optionClick)="onOptionClicked($event)"
          [displayButton]="canCreateApplication(partner.id)"
          [clickable] = "canCreateApplication(partner.id)"
        ></mc-card>
        <mc-card
          [class]="partner.id"
          [image]="getLogo(partner.id)"
          *mcCapabilities="'!Permission.API_APPLICATIONS_EDIT'"
          title="{{
            '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.TECH_PARTNERS.' + partner.id + '.TITLE' | uppercase | translate
          }}"
          description="{{
            '$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.TECH_PARTNERS.' + partner.id + '.DESCRIPTION' | uppercase | translate
          }}"
          [useTranslation]="false"
          [optionsList]="partner.optionsList"
          (optionClick)="onOptionClicked($event)"
          [displayButton]="canCreateApplication(partner.id)"
          [clickable] = "canCreateApplication(partner.id)"
        ></mc-card>
      </div>
      <hr *ngIf="partner.id === 'apigateway'" class="seperator-width">
    </ng-container>
  </div>
</div>
