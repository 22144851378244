<div class="header-container" *ngIf="header">
  <h3 class="header-name">
    {{ header | translate }}
  </h3>
  <mc-help-container *ngIf="activeTab?.helpLink">
    <mc-help-link [url]="activeTab.helpLink"></mc-help-link>
  </mc-help-container>
</div>

<ul class="tabs-nav">
  <ng-container *ngFor="let tab of tabs; trackBy: trackByTabLink">
    <li
      *ngIf="!tab.hidden"
      class="tabs-nav-item"
    >
      <a
        class="tabs-nav-link"
        [ngClass]="{active: activeTab === tab, 'active-child': activeTab === tab && isChildPage}"
        *mcCapabilities="tab.cap"
        (click)="goTo(tab.link)"
      >
        {{ tab.label | translate }}
      </a>
    </li>
  </ng-container>
</ul>
