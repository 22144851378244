"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PieChartModule = void 0;
const am4core = require("@amcharts/amcharts4/core");
class PieChartModule {
    am4themes_colorTheme(target) {
        if (target instanceof am4core.ColorSet) {
            target.list = [am4core.color('#63a4ff'), am4core.color('#004ba0'), am4core.color('#ffa000')];
        }
    }
    constructor() {
        am4core.useTheme(this.am4themes_colorTheme);
    }
}
exports.PieChartModule = PieChartModule;
