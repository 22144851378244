"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasError = exports.isLoading = exports.getExportToken = exports.getExportData = exports.getExportConfig = exports.reducer = exports.initialState = void 0;
const exportActions = require("../../actions/export/export.actions");
exports.initialState = {
    exportTokenRequestConfig: null,
    exportTokenRequestData: null,
    exportToken: null,
    exportDataRequest: null,
    isLoading: true,
    genericError: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case exportActions.OPEN_PANEL_FOR_EXPORT:
            return Object.assign(Object.assign({}, state), { genericError: false, isLoading: false, exportTokenRequestConfig: action.payload });
        case exportActions.GET_TOKEN_FOR_EXPORT:
            return Object.assign(Object.assign({}, state), { genericError: false, isLoading: true, exportTokenRequestData: action.payload });
        case exportActions.GET_TOKEN_FOR_EXPORT_SUCCESS:
            return Object.assign(Object.assign({}, state), { genericError: false, isLoading: true, exportToken: action.payload.id });
        case exportActions.GET_TOKEN_FOR_EXPORT_FAIL:
            return Object.assign(Object.assign({}, state), { genericError: true, isLoading: false });
        case exportActions.DOWNLOAD_FILE_COMPLETE:
            return Object.assign(Object.assign({}, state), { genericError: false, isLoading: false });
        case exportActions.DOWNLOAD_FILE:
            return Object.assign(Object.assign({}, state), { exportToken: null });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getExportConfig = (state) => state.exportTokenRequestConfig;
exports.getExportConfig = getExportConfig;
const getExportData = (state) => state.exportTokenRequestData;
exports.getExportData = getExportData;
const getExportToken = (state) => state.exportToken;
exports.getExportToken = getExportToken;
const isLoading = (state) => state.isLoading;
exports.isLoading = isLoading;
const hasError = (state) => state.genericError;
exports.hasError = hasError;
