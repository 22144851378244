"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BreadcrumbsComponent = void 0;
const core_1 = require("@angular/core");
class BreadcrumbsComponent {
    constructor() {
        this.onFolderClicked = new core_1.EventEmitter();
    }
    popNodes(node) {
        const nodeIndex = this.nodeStack.findIndex(item => item['id'] === node['id']);
        if (nodeIndex !== this.nodeStack.length) {
            this.nodeStack.length = nodeIndex + 1;
        }
    }
    onNodeClicked(node) {
        this.popNodes(node);
        this.onFolderClicked.emit(node);
    }
    reverseOrder(arrayToReverse) {
        if (arrayToReverse) {
            const copyOfArray = arrayToReverse.slice(0);
            return copyOfArray.reverse();
        }
    }
}
exports.BreadcrumbsComponent = BreadcrumbsComponent;
