"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFailure = exports.getTotalAllowance = exports.getTotalUsage = exports.getIsUsageLoading = exports.reducer = exports.initialState = void 0;
const list = require("../actions/list.actions");
exports.initialState = {
    loading: false,
    totalAllowance: 0,
    totalUsed: 0,
    failure: []
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case list.LOAD: {
            return Object.assign(Object.assign({}, state), { loading: true });
        }
        case list.LOAD_SUCCESS: {
            return Object.assign(Object.assign({}, state), { loading: false, totalAllowance: action.payload.totalAllowance, totalUsed: action.payload.totalUsed });
        }
        case list.LOAD_FAIL: {
            return Object.assign(Object.assign({}, state), { loading: false, failure: action.payload });
        }
        default: {
            return state;
        }
    }
}
exports.reducer = reducer;
// Selectors
const getIsUsageLoading = (state) => state.loading;
exports.getIsUsageLoading = getIsUsageLoading;
const getTotalUsage = (state) => state.totalUsed;
exports.getTotalUsage = getTotalUsage;
const getTotalAllowance = (state) => state.totalAllowance;
exports.getTotalAllowance = getTotalAllowance;
const getFailure = (state) => state.failure;
exports.getFailure = getFailure;
