"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DetailComponent = void 0;
const table_store_1 = require("@mimecast-ui/table-store");
const contentActions = require("app-new/components/tree/actions/contents.actions");
const folderCartActions = require("app-new/components/tree/actions/folders-cart.actions");
const folderActions = require("app-new/components/tree/actions/folders.actions");
const reducers = require("app-new/components/tree/reducers");
const actions_1 = require("app-new/sync-recover/exchange/actions");
const reducers_1 = require("app-new/sync-recover/exchange/reducers");
require("app/services/services/backup/mailboxes/mailbox-details/slides/mailbox-email-export.tpl.html");
require("app/services/services/backup/mailboxes/mailbox-details/slides/mailbox-email-restore.tpl.html");
const snapshot_list_impl_service_1 = require("../services/snapshot-list.impl.service");
const content_type_factory_1 = require("./models/content-type/factories/content-type-factory");
const detail_1 = require("./models/detail");
class DetailComponent {
    constructor(coreService, stateService, store, capManager) {
        this.coreService = coreService;
        this.stateService = stateService;
        this.store = store;
        this.capManager = capManager;
        this.viewType = 'live';
        this.nodeStack = [];
        this.contentType = content_type_factory_1.ContentTypeFactory.MESSAGE(null);
        this.currentContentPageNumber = 0;
    }
    ngOnInit() {
        this.hasExportAndRestorePermission$ = this.capManager.hasExchangeExportAndRestorePermission();
        this.hasContentViewPermission$ = this.capManager.hasExchangeContentViewPermission();
        this.capManager.isDevelopmentOnly().subscribe(isDevOnly => (this.isDevOnly = isDevOnly));
        this.snapshot = this.stateService.$state.params.snapshot;
        this.rootFolder$ = this.store.select(reducers.getRootFolder);
        this.folders$ = this.store.select(reducers.getFolders);
        this.foldersLoading$ = this.store.select(reducers.foldersIsLoading);
        this.searchingFolders$ = this.store.select(reducers.foldersIsSearching);
        this.foldersMetadata$ = this.store.select(reducers.getFoldersMetadata);
        this.currentParentFolder$ = this.store.select(reducers.getParentFolder);
        this.contents$ = this.store.select(reducers.getContents);
        this.contentsLoading$ = this.store.select(reducers.contentsIsLoading);
        this.contentsInitialState$ = this.store.select(reducers.contentsIsInitialState);
        this.searchingContents$ = this.store.select(reducers.contentsIsSearching);
        this.contentsMetadata$ = this.store.select(reducers.getContentsMetadata);
        this.contentsPageNumber$ = this.store.select(reducers.getContentsPageNumber);
        this.selectedFoldersCart$ = this.store.select(reducers.getFoldersCart);
        this.isContentOpening$ = this.store.select(reducers_1.getIsContentOpening);
        this.userProfile$ = this.coreService.getUserProfile();
        this.dispatchGetSnapshotSummary();
    }
    getFoldersAndContents(getFoldersPayload) {
        this.contentType = this.createContentType(getFoldersPayload.node);
        this.currentNode = getFoldersPayload.node;
        this.contentsSearchTerm = undefined;
        this.currentContentPageNumber = 0;
        this.dispatchFolders(getFoldersPayload);
        this.dispatchContents(getFoldersPayload);
    }
    dispatchRootFolder(snapshotSummary) {
        this.store.dispatch(new folderActions.LoadExchangeRootFolder({
            mailbox: snapshotSummary.mailbox.emailAddress,
            viewType: this.viewType,
            snapshotId: snapshotSummary.id
        }));
    }
    dispatchFolders(getFoldersPayload) {
        const PAYLOAD = {
            mailbox: getFoldersPayload.snapshotSummary.mailbox.emailAddress,
            viewType: this.viewType,
            snapshotId: getFoldersPayload.snapshotSummary.id,
            contentType: this.contentType
                ? this.contentType.CONTENT_TYPE
                : 'MESSAGE'
        };
        if (getFoldersPayload.node && getFoldersPayload.node.getId()) {
            PAYLOAD.parentId = getFoldersPayload.node.getId();
        }
        if (getFoldersPayload.meta) {
            PAYLOAD.meta = getFoldersPayload.meta;
        }
        this.store.dispatch(new folderActions.LoadExchangeFoldersAction(PAYLOAD));
        // TODO DGOMEZ - REMOVE THIS AFTER MOJEED FINISH TESTING, CLEANUP DISABLED ONLY IN DEV
        if (!this.isDevOnly) {
            this.store.dispatch(new folderCartActions.CleanStore());
        }
    }
    dispatchNextFolders(getFoldersPayload) {
        this.dispatchFolders(getFoldersPayload);
    }
    dispatchPreviousFolders(getFoldersPayload) {
        this.dispatchFolders(getFoldersPayload);
    }
    dispatchContents(getContentPayload) {
        const PAYLOAD = {
            folderId: getContentPayload.node.getId(),
            mailbox: getContentPayload.snapshotSummary.mailbox.emailAddress,
            viewType: this.viewType,
            snapshotId: getContentPayload.snapshotSummary.id
        };
        if (getContentPayload.meta) {
            PAYLOAD.meta = getContentPayload.meta;
        }
        if (getContentPayload.searchTerm) {
            PAYLOAD.query = getContentPayload.searchTerm['searchQuery'];
            PAYLOAD.type = 'mailbox_recovery';
            PAYLOAD.pageNumber = this.currentContentPageNumber;
            PAYLOAD.admin = true;
            this.store.dispatch(this.contentType.getSearchContentsAction(PAYLOAD));
        }
        else {
            this.store.dispatch(this.contentType.getLoadContentsAction(PAYLOAD));
        }
    }
    dispatchNextContents(getContentPayload) {
        this.currentContentPageNumber += 1;
        this.dispatchContents(Object.assign(Object.assign({}, getContentPayload), { searchTerm: this.contentsSearchTerm }));
    }
    dispatchPreviousContents(getContentPayload) {
        this.currentContentPageNumber -= 1;
        this.dispatchContents(Object.assign(Object.assign({}, getContentPayload), { searchTerm: this.contentsSearchTerm }));
    }
    /**
     * TODO - dgomez -
     * Pagination should be applied here
     */
    searchFolders(getFoldersPayload) {
        if (getFoldersPayload.searchTerm && getFoldersPayload.searchTerm['searchQuery']) {
            const PAYLOAD = {
                mailbox: getFoldersPayload.snapshotSummary.mailbox.emailAddress,
                viewType: this.viewType,
                snapshotId: getFoldersPayload.snapshotSummary.id
            };
            if (getFoldersPayload.node && getFoldersPayload.node.getId()) {
                PAYLOAD.parentId = getFoldersPayload.node.getId();
            }
            PAYLOAD.query = getFoldersPayload.searchTerm['searchQuery'];
            this.store.dispatch(new folderActions.FindExchangeFoldersAction(PAYLOAD));
        }
        else {
            this.dispatchCleanupActions();
            this.dispatchRootFolder(getFoldersPayload.snapshotSummary);
            this.dispatchFolders({ snapshotSummary: getFoldersPayload.snapshotSummary });
        }
    }
    searchContents(getContentPayload) {
        this.currentContentPageNumber = 0;
        this.contentsSearchTerm = getContentPayload.searchTerm;
        this.dispatchContents(getContentPayload);
    }
    openExport(payload) {
        this.store.dispatch(new actions_1.fromSnapshot.OpenExportAction({
            folderPaths: this.getFolderPaths(payload.folderCart),
            mailbox: payload.snapshotSummary.mailbox.emailAddress,
            userEmailAddress: payload.userEmailAddress,
            viewType: this.viewType,
            snapshotId: payload.snapshotSummary.id
        }));
    }
    openRestore(payload) {
        this.store.dispatch(new actions_1.fromSnapshot.OpenRestoreAction({
            folderPaths: this.getFolderPaths(payload.folderCart),
            mailbox: payload.snapshotSummary.mailbox.emailAddress,
            userEmailAddress: payload.userEmailAddress,
            viewType: this.viewType,
            snapshotId: payload.snapshotSummary.id
        }));
    }
    updateFoldersCart(folder) {
        if (folder.isSelected()) {
            this.store.dispatch(new folderCartActions.RemoveFolderAction(folder));
        }
        else {
            this.store.dispatch(new folderCartActions.PushFolderAction(folder));
        }
    }
    /**
     * TODO - dgomez -
     * This function should be refactored, remove ifs and checks with magic strings
     */
    initializeSelectedTab(tab) {
        switch (tab.name) {
            case '$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_DETAIL.TABS.LASTSYNC.LABEL': {
                this.viewType = 'live';
                break;
            }
            case '$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_DETAIL.TABS.ARCHIVED.LABEL': {
                this.viewType = 'archive';
                break;
            }
        }
        this.contentType = content_type_factory_1.ContentTypeFactory.MESSAGE(null);
        this.dispatchCleanupActions();
    }
    /**
     * TODO - dgomez -
     * This function should be refactored, remove ifs and checks with magic strings
     */
    openItem(payload) {
        if (this.contentType.getContentType() === 'CALENDAR') {
            this.store.dispatch(new actions_1.fromSnapshot.OpenCalendarContentAction({
                item: payload.item,
                mailBox: payload.snapshotSummary.mailbox.emailAddress,
                origin: this.viewType
            }));
        }
        else if (this.contentType.getContentType() === 'CONTACT') {
            this.store.dispatch(new actions_1.fromSnapshot.OpenContactContentAction({
                item: payload.item,
                mailBox: payload.snapshotSummary.mailbox.emailAddress,
                origin: this.viewType
            }));
        }
        else {
            this.store.dispatch(new actions_1.fromSnapshot.OpenMessageContentAction({
                item: payload.item,
                origin: this.viewType
            }));
        }
    }
    pushNodeToTrace(node) {
        this.nodeStack = [...this.nodeStack, node];
    }
    getFolderPaths(folders) {
        return folders.map(folder => ({
            path: folder.getFullPath(),
            expand: folder.getContentType() === detail_1.ExchangeContentType.MESSAGE
        }));
    }
    dispatchCleanupActions() {
        this.store.dispatch(new folderActions.CleanStore());
        this.store.dispatch(new contentActions.CleanStore());
        // TODO DGOMEZ - REMOVE THIS AFTER MOJEED FINISH TESTING, CLEANUP ENABLED ONLY IN DEV
        if (this.isDevOnly) {
            this.store.dispatch(new folderCartActions.CleanStore());
        }
        this.nodeStack = [];
        this.currentNode = null;
    }
    createContentType(node) {
        return content_type_factory_1.ContentTypeFactory[node.getContentType()](node.getParent());
    }
    dispatchGetSnapshotSummary() {
        if (!this.stateService.$state.params.snapshotId) {
            throw new Error('SnapshotId is mandatory');
        }
        else {
            this.store.dispatch(new table_store_1.LoadSelectedRowAction({
                tableId: snapshot_list_impl_service_1.SnapShotListImpl.EXCHANGE_MAILBOX_SIT_LIST_STORE_ID,
                value: this.stateService.$state.params.snapshotId,
                field: 'query'
            }));
        }
    }
    dispatchLoadSnapshotQueue() {
        this.store.dispatch(new actions_1.fromSnapshot.GoToSnapshotQueueAction());
    }
}
exports.DetailComponent = DetailComponent;
