"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const fromApps = require("../../actions");
class ViewEffects {
    constructor(actions$, apiService) {
        this.actions$ = actions$;
        this.apiService = apiService;
        this.getApiApplicationKey$ = this.actions$.pipe(effects_1.ofType(fromApps.ApiGatewayViewActionTypes.GetApiApplicationKey), operators_1.switchMap((action) => {
            return this.apiService.getAppKey(action.payload.id).pipe(operators_1.map((response) => {
                if (!response) {
                    throw new Error();
                }
                return new fromApps.GetApiApplicationKeySuccessAction(response);
            }), operators_1.catchError(() => rxjs_1.of(new fromApps.ApiApplicationFailureAction('$I18N_API_APPLICATIONS_LIST.NOTIFICATIONS.ERRORS.GENERAL'))));
        }));
    }
}
__decorate([
    effects_1.Effect()
], ViewEffects.prototype, "getApiApplicationKey$", void 0);
exports.ViewEffects = ViewEffects;
