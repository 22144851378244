"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StepBaseDirective = void 0;
const wizardActions = require("../actions/wizard.actions");
const operators_1 = require("rxjs/operators");
const rxjs_1 = require("rxjs");
class StepBaseDirective {
    constructor(store, fb, stepId, wizardId) {
        this.store = store;
        this.fb = fb;
        this.stepId = stepId;
        this.wizardId = wizardId;
        this.destroy$ = new rxjs_1.Subject();
    }
    ngOnInit() {
        this.dispatchUpdateStepAction();
        this.form.valueChanges.pipe(operators_1.takeUntil(this.destroy$), operators_1.debounceTime(150)).subscribe(() => {
            this.dispatchUpdateStepAction();
        });
    }
    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
    getStepState() {
        return {
            valid: this.form.valid,
            data: this.form.value
        };
    }
    dispatchUpdateStepAction() {
        this.store.dispatch(new wizardActions.UpdateStepAction({
            wizardId: this.wizardId,
            stepId: this.stepId,
            stepState: this.getStepState()
        }));
    }
}
exports.StepBaseDirective = StepBaseDirective;
