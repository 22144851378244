"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OnedriveListImpl = void 0;
const table_store_1 = require("@mimecast-ui/table-store");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
class OnedriveListImpl {
    constructor(http, injector) {
        this.http = http;
        this.injector = injector;
    }
    get $state() {
        return this.injector.get('$state');
    }
    getData(payload, pagination = table_store_1.defaultPaginationOption) {
        return this.http
            .post('/api/onedrive/get-onedrive-summary', Object.assign(Object.assign({}, payload), { status: payload && payload.status && payload.status[0], meta: { pagination } }))
            .pipe(operators_1.map((response) => {
            const RETURN_DATA = {
                hasErrors: response.hasErrors,
                data: response.all,
                failures: response.fail,
                meta: response.meta
            };
            return RETURN_DATA;
        }), operators_1.catchError((response) => {
            const RETURN_DATA = {
                hasErrors: response.hasErrors,
                data: response.all,
                failures: response.fail,
                meta: response.meta
            };
            return rxjs_1.of(RETURN_DATA);
        }));
    }
    filter(payload, pagination) {
        const filterPayload = {};
        if (payload) {
            if (payload.columnValues) {
                filterPayload.status = payload.columnValues.status;
            }
            if (payload.searchQuery) {
                filterPayload.query = payload.searchQuery;
            }
        }
        return this.getData(filterPayload, pagination);
    }
    openItem(row) {
        return rxjs_1.of(this.$state.go('one-drive-browser', {
            drive: row
        }));
    }
}
exports.OnedriveListImpl = OnedriveListImpl;
OnedriveListImpl.ONE_DRIVE_DRIVES_LIST_STORE_ID = 'OnedriveDriveList';
