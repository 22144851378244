"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DetailViewFormComponent = void 0;
const core_1 = require("@angular/core");
const editable_decorator_1 = require("app-new/directories/attributes/decorators/editable.decorator");
const is_email_decorator_1 = require("app-new/directories/attributes/decorators/is-email.decorator");
const max_length_decorator_1 = require("app-new/directories/attributes/decorators/max-length.decorator");
const pattern_decorator_1 = require("app-new/directories/attributes/decorators/pattern.decorator");
const required_decorator_1 = require("app-new/directories/attributes/decorators/required.decorator");
const min_length_decorator_1 = require("../../decorators/min-length.decorator");
const dropdown_decorator_1 = require("app-new/directories/attributes/decorators/dropdown.decorator");
const toggle_decorator_1 = require("app-new/directories/attributes/decorators/toggle.decorator");
class DetailViewFormComponent {
    constructor(_fb, translateService) {
        this._fb = _fb;
        this.translateService = translateService;
        this.saveData = new core_1.EventEmitter();
        this.objectKeys = Object.keys;
        this.PREFIX_KEY = '$18N_ATTRIBUTES_DIRECTORIES_FORM';
        this.DROPDOWN_KEY = 'PROMPTTYPE';
        this.DISABLE_ATTRIBUTE_KEY = 'HIDDEN_ATTRIBUTE';
        this.translationRoot = `${this.PREFIX_KEY}.${this.DROPDOWN_KEY}`;
    }
    set mode(mode) {
        this._mode = mode;
        this.updateFormData();
    }
    get mode() {
        return this._mode;
    }
    ngOnInit() {
        this.updateFormData();
    }
    updateFormData() {
        if (this.formGroupConfig) {
            this.formGroup.patchValue(this.data);
        }
        this.getTranslatedDropdownOptions();
        this.editableFields = editable_decorator_1.getEditableFields(this.data);
        this.requiredFields = required_decorator_1.getRequiredFields(this.data);
        this.minLengthFields = min_length_decorator_1.getMinLengthFields(this.data);
        this.maxLengthFields = max_length_decorator_1.getMaxLengthFields(this.data);
        this.isEmailFields = is_email_decorator_1.getIsEmailFields(this.data);
        this.patternFields = pattern_decorator_1.getPatternFields(this.data);
        this.dropdownFields = dropdown_decorator_1.getDropdownFields(this.data);
        this.toggleFields = toggle_decorator_1.getToggleFields(this.data);
        this.formGroupConfig = this.getGroupFormConfiguration();
        this.formGroup = this._fb.group(this.formGroupConfig);
        this.formSubscription$ = this.formGroup.valueChanges.subscribe(attribute => {
            const isInvalid = this.formGroup.invalid
                ? true
                : 'EMPTY_OPTION' === attribute.promptType.toUpperCase();
            this.saveData.emit({ attribute, isInvalid });
        });
    }
    getValue(key) {
        let value;
        if (this.data[key] && key.toUpperCase() === this.DROPDOWN_KEY) {
            value = this.translateService.instant(`${this.translationRoot}.${this.data[key].toUpperCase()}`);
        }
        else {
            value = this.data[key];
        }
        return value;
    }
    getConvertedDropdown(options) {
        const dropdownOptions = [];
        if (this.isEditMode()) {
            if (this.data.promptType.toUpperCase() === this.DISABLE_ATTRIBUTE_KEY) {
                this.formGroup.get('promptType').patchValue('EMPTY_OPTION');
                dropdownOptions.push({
                    value: 'EMPTY_OPTION',
                    label: this.translateService.instant(`${this.translationRoot}.EMPTY_OPTION`)
                });
            }
        }
        for (const key of Object.keys(options)) {
            if (this.isEditMode() &&
                key !== this.DISABLE_ATTRIBUTE_KEY &&
                key.toUpperCase() !== 'EMPTY_OPTION') {
                dropdownOptions.push({
                    value: key.toLowerCase(),
                    label: this.translateService.instant(`${this.translationRoot}.${key.toUpperCase()}`)
                });
            }
        }
        // dropdownResult[this.DROPDOWN_KEY] = dropdownOption; // (more than 1 dropdownlist)
        this.dropdownValues = dropdownOptions;
    }
    getTranslatedDropdownOptions() {
        const options$ = this.translateService.get(this.translationRoot);
        options$.subscribe(options => this.getConvertedDropdown(options));
    }
    getLabelForKey(key) {
        return `${this.PREFIX_KEY}.PROPERTIES.${key.toUpperCase()}`;
    }
    getPopoverTextForKey(key) {
        return this.translateService.instant(`${this.PREFIX_KEY}.POPOVER.${key.toUpperCase()}`);
    }
    isEditMode() {
        return this._mode === 'EDIT';
    }
    isRequired(key) {
        return key in this.requiredFields;
    }
    isDropdown(key) {
        return key in this.dropdownFields;
    }
    isToggle(key) {
        return key in this.toggleFields;
    }
    getGroupFormConfiguration() {
        const validators = {};
        this.objectKeys(this.editableFields).forEach(key => {
            validators[key] = [
                this.data[key],
                [
                    this.requiredFields[key],
                    this.minLengthFields[key],
                    this.maxLengthFields[key],
                    this.isEmailFields[key],
                    this.patternFields[key]
                ].filter(validator => validator !== undefined)
            ];
        });
        return validators;
    }
    ngOnDestroy() {
        this.formSubscription$.unsubscribe();
    }
}
exports.DetailViewFormComponent = DetailViewFormComponent;
