"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgPolicyAffectedUsersStepComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const models_1 = require("../../../models");
class SwgPolicyAffectedUsersStepComponent {
    constructor(fb, swgService, translate) {
        this.fb = fb;
        this.swgService = swgService;
        this.translate = translate;
        this.policyAffectedUsersWarning = {
            status: 'warning',
            msg: this.translate.instant('$I18N_SWG_CREATE_POLICY_AFFECTED_USERS_APPLICATION_WARNING'),
            boxed: true
        };
        this.showEveryoneRadioOption = true;
        this.showLocationOption = true;
        this.isBiPolicy = false;
        this.updateAffectedUsers = new core_1.EventEmitter();
        this.everyOneAffectedUsers = new core_1.EventEmitter();
        this.removeAffectedUsers = new core_1.EventEmitter();
        this.openAppliesToSidePanel = new core_1.EventEmitter();
        this.updatePolicyOverrideStatus = new core_1.EventEmitter();
        this.clearAffectedUsersOtherThan = new core_1.EventEmitter();
        this.hasDeviceBasedPoliciesEnabled = false;
        this.hasSwgFull = this.swgService.hasWebSecurityAccountPackage();
        this.hasDeviceBasedPoliciesEnabled = this.swgService.hasDeviceBasedPoliciesSwitchEnabled();
    }
    set policyOverrideStatus(overrideStatus) {
        if (this.affectedUsersPage) {
            this.affectedUsersPage.markAsPristine();
            if (overrideStatus !== undefined && !this.affectedUsersPage.dirty) {
                this.affectedUsersPage.patchValue({ override: overrideStatus });
            }
        }
    }
    ngOnInit() {
        this.affectedUsersPage = this.fb.group({
            condition: [this.getCondition(), forms_1.Validators.required],
            override: [this.policyDesc.override]
        });
        this.formSubscription = this.affectedUsersPage.valueChanges.subscribe(form => {
            if (form.condition === 'deviceBased' && this.hasDeviceBasedPoliciesEnabled) {
                this.updatePolicyOverrideStatus.emit(form.override);
                this.clearAffectedUsersOtherThan.emit('deviceBased');
            }
            else if (form.condition === 'multi') {
                this.updatePolicyOverrideStatus.emit(false);
                this.clearAffectedUsersOtherThan.emit('multi');
            }
            else {
                this.updatePolicyOverrideStatus.emit(false);
                this.clearAffectedUsersOtherThan.emit('everyone');
            }
        });
    }
    getCondition() {
        if (this.everyOne) {
            return 'everyone';
        }
        else if (this.hasDeviceBasedPoliciesEnabled &&
            this.affectedUsers &&
            this.affectedUsers.length &&
            (this.affectedUsers[0].condition === models_1.SwgAffectedUsersEnum.WEB_DEVICE ||
                this.affectedUsers[0].condition === models_1.SwgAffectedUsersEnum.WEB_DEVICE_GROUP)) {
            return 'deviceBased';
        }
        return 'multi';
    }
    getAppliesToOptionsTitle() {
        if (this.policyDesc.policyType === models_1.SwgPolicyType.CLOUD_PROTECT_POLICY) {
            return '$I18N_SWG_CREATE_POLICY_AFFECTED_USERS_GROUPS_USERS';
        }
        return this.hasSwgFull
            ? '$I18N_SWG_CREATE_POLICY_AFFECTED_USERS_LOCATION_GROUPS_USERS'
            : '$I18N_SWG_CREATE_POLICY_AFFECTED_USERS_LOCATIONS';
    }
    getAppliesToOptionsDesc() {
        if (this.policyDesc.policyType === models_1.SwgPolicyType.CLOUD_PROTECT_POLICY) {
            return '$I18N_SWG_CREATE_POLICY_AFFECTED_USERS_GROUPS_USERS_DESC';
        }
        return this.hasSwgFull
            ? '$I18N_SWG_CREATE_POLICY_AFFECTED_USERS_LOCATION_GROUPS_USERS_DESC'
            : '$I18N_SWG_CREATE_POLICY_AFFECTED_USERS_LOCATIONS_DESC';
    }
    show() {
        if (this.policyDesc.policyType === models_1.SwgPolicyType.CLOUD_PROTECT_POLICY) {
            return false;
        }
        return this.showLocationOption;
    }
    setAllAppliesToSelection(all) {
        this.everyOneAffectedUsers.emit(all);
        return true;
    }
    removeAppliesTo(payload) {
        this.removeAffectedUsers.emit(payload);
        return true;
    }
    getPageTitle(edit) {
        return edit
            ? '$I18N_SWG_CREATE_POLICY_AFFECTED_USERS_EDIT_TITLE'
            : '$I18N_SWG_CREATE_POLICY_AFFECTED_USERS_TITLE';
    }
    isValid() {
        return this.everyOne || this.affectedUsers.length > 0;
    }
    openSidebar(value) {
        this.openAppliesToSidePanel.emit(value);
    }
    showDeviceBasedOptions() {
        if (this.policyDesc.policyType === models_1.SwgPolicyType.CLOUD_PROTECT_POLICY) {
            return false;
        }
        if (this.isBiPolicy) {
            return false;
        }
        return this.hasDeviceBasedPoliciesEnabled;
    }
    ngOnDestroy() {
        if (this.formSubscription) {
            this.formSubscription.unsubscribe();
        }
    }
}
exports.SwgPolicyAffectedUsersStepComponent = SwgPolicyAffectedUsersStepComponent;
