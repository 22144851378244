"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isInitialRequest = exports.isGenericError = exports.isApiProcessing = exports.getUrlOrDomain = exports.getClassification = exports.reducer = exports.initialState = void 0;
const url_check_actions_1 = require("../actions/url-check.actions");
exports.initialState = {
    classification: { urlCategories: [], domainCategories: [] },
    urlOrDomain: '',
    isApiProcessing: false,
    genericError: false,
    initialRequest: true
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case url_check_actions_1.SwgUrlClassificationActions.GET_URL_CLASSFICATION:
            return Object.assign(Object.assign({}, state), { urlOrDomain: action.payload, isApiProcessing: true, initialRequest: false });
        case url_check_actions_1.SwgUrlClassificationActions.GET_URL_CLASSFICATION_SUCCESS:
            return Object.assign(Object.assign({}, state), { classification: action.payload, isApiProcessing: false, genericError: false });
        case url_check_actions_1.SwgUrlClassificationActions.GET_URL_CLASSFICATION_FAILURE:
            return Object.assign(Object.assign({}, state), { isApiProcessing: false, genericError: true });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getClassification = (state) => state.classification;
exports.getClassification = getClassification;
const getUrlOrDomain = (state) => state.urlOrDomain;
exports.getUrlOrDomain = getUrlOrDomain;
const isApiProcessing = (state) => state.isApiProcessing;
exports.isApiProcessing = isApiProcessing;
const isGenericError = (state) => state.genericError;
exports.isGenericError = isGenericError;
const isInitialRequest = (state) => state.initialRequest;
exports.isInitialRequest = isInitialRequest;
