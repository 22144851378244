"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./highcharts-directive"); //mc-highcharts.directive
// html and css 
require("./highcharts.less");
/**
 * Created by cfeudo on 10/01/2016.
 */
(function () {
    'use strict';
    angular.module('mc-highcharts', ['mc-highcharts.directive']);
}());
