'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
require("./mega-menu-group-directive");
require("./mega-menu-item-directive");
require("./mega-menu-service");
const angular = require("angular");
const _ = require("lodash");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
angular.module('mega.menu.directive', ['mega.menu.group.directive', 'mega.menu.item.directive', 'mega.menu.service'])
    .directive('mcMegaMenu', ['$translate', 'megaMenuService', function ($translate, megaMenuService) {
        return {
            restrict: 'AE',
            templateUrl: 'components/mega-menu/mega-menu.tpl.html',
            transclude: true,
            require: 'mcMegaMenu',
            scope: {
                label: '@mcLabel',
                options: '=mcOptions',
                parentLabel: '=mcParentLabel'
            },
            link($scope, $element, $attr, controller, transcludeFn) {
                const transcludeElement = $element.find('.mc-sub-menu-items').first();
                transcludeFn(function (clone) {
                    transcludeElement.empty();
                    transcludeElement.append(clone);
                });
                controller.activate();
            },
            controller: ['$scope', '$element', '$interval', '$timeout', function ($scope, $element, $interval, $timeout) {
                    const megaMenuCtrl = this;
                    megaMenuCtrl.selectedMenuGroup = null;
                    megaMenuCtrl.menuGroups = [];
                    megaMenuCtrl.search = null;
                    megaMenuCtrl.showResults = false;
                    megaMenuCtrl.beforeSearchSelectedMenuGroup = null;
                    megaMenuCtrl.autoSelectedMenu = null;
                    megaMenuCtrl.menuTimeout = undefined;
                    megaMenuCtrl.options = $scope.options;
                    megaMenuCtrl.parentLabel = $scope.parentLabel;
                    megaMenuCtrl.addMenuGroup = addMenuGroup;
                    megaMenuCtrl.select = select;
                    megaMenuCtrl.activate = activate;
                    megaMenuCtrl.onMenuGroupClick = onMenuGroupClick;
                    megaMenuCtrl.onMenuItemClick = onMenuItemClick;
                    megaMenuCtrl.clearResults = clearResults;
                    megaMenuCtrl.searchInputClick = eventStopper;
                    megaMenuCtrl.selectFirstAvailableMenu = selectFirstAvailableMenu;
                    megaMenuCtrl.selectWithDelay = selectWithDelay;
                    let searchInput;
                    let searcher;
                    let isMegamenuEmpty = true;
                    function activate() {
                        const checkGroupsReady = function () {
                            if (!_.isEmpty(megaMenuCtrl.menuGroups)) {
                                const areGroupsReady = _.some(megaMenuCtrl.menuGroups, function (group) {
                                    isMegamenuEmpty = group.isReady && group.numberItemsAfterCaps > 0 ? false : isMegamenuEmpty;
                                    return !group.isReady;
                                });
                                if (!areGroupsReady) {
                                    if (megaMenuCtrl.parentLabel) {
                                        megaMenuCtrl.menuGroups.parent = megaMenuCtrl.parentLabel;
                                    }
                                    megaMenuService.setMenuGroups(megaMenuCtrl.menuGroups);
                                    if (isMegamenuEmpty === true && megaMenuCtrl.options.megaMenuEmptyHandler) {
                                        megaMenuCtrl.options.megaMenuEmptyHandler();
                                    }
                                    $interval.cancel(cancelInterval);
                                }
                            }
                        };
                        searchInput = $element.find('.mega-menu-search-input');
                        megaMenuCtrl.selectedMenuGroup = megaMenuCtrl.selectedMenuGroup || megaMenuCtrl.menuGroups.length > 1 && megaMenuCtrl.menuGroups[0];
                        megaMenuCtrl.select(megaMenuCtrl.selectedMenuGroup);
                        const cancelInterval = $interval(checkGroupsReady, 1000, 1);
                        // Get all distinct key up events from the input and only fire if long enough and distinct
                        const keyup = rxjs_1.fromEvent(searchInput, 'keyup').pipe(operators_1.map(function (e) {
                            // @ts-ignore
                            return e.target.value; // Project the text from the input
                        }), operators_1.filter(function (text) {
                            if (text.length > 2) {
                                showResults(true);
                                return true;
                            }
                            else if (text.length > 0) {
                                showResults(false);
                            }
                            return false;
                        }), operators_1.debounceTime(200 /* Pause for 750ms */), operators_1.distinctUntilChanged()); // Only if the value has changed
                        searcher = keyup.pipe(operators_1.switchMap(searchMenu));
                        searcher.subscribe(renderSearchResults);
                    }
                    function showResults(show) {
                        megaMenuCtrl.showResults = show;
                        if (show) {
                            megaMenuCtrl.beforeSearchSelectedMenuGroup = megaMenuCtrl.selectedMenuGroup;
                            select();
                        }
                        else {
                            if (megaMenuCtrl.beforeSearchSelectedMenuGroup) {
                                select(megaMenuCtrl.beforeSearchSelectedMenuGroup);
                                megaMenuCtrl.beforeSearchSelectedMenuGroup = undefined;
                            }
                            else if (megaMenuCtrl.menuGroups && megaMenuCtrl.menuGroups.length > 0) {
                                select(megaMenuCtrl.menuGroups[0]);
                            }
                        }
                    }
                    function clearResults($event) {
                        searchInput.val('');
                        showResults(false);
                        eventStopper($event);
                    }
                    function renderSearchResults(results) {
                        // $scope.$apply(function () {
                        megaMenuCtrl.searchResults = results;
                        // });
                    }
                    function searchMenu(term) {
                        const matches = megaMenuService.searchMenu(term);
                        megaMenuService.getData().then((res) => {
                            if (res.policyInheritance === false && matches.map((resMap) => {
                                if (resMap.label === $translate.instant('$I18N_MEGA_MENU_GATEWAY_POLICIES_LABEL')) {
                                    matches.pop();
                                }
                            }))
                                return matches;
                        });
                        return [matches];
                    }
                    function addMenuGroup(menuGroup) {
                        megaMenuCtrl.menuGroups.push(menuGroup);
                    }
                    function selectWithDelay(menu, $event) {
                        $timeout.cancel(megaMenuCtrl.menuTimeout);
                        megaMenuCtrl.menuTimeout = $timeout(function () {
                            select(menu, $event);
                        }, 200, true);
                    }
                    function select(menu, $event) {
                        if (!megaMenuCtrl.selectedMenuGroup) {
                            megaMenuCtrl.selectedMenuGroup = menu;
                            if (menu) {
                                menu.select(true);
                            }
                            return;
                        }
                        megaMenuCtrl.selectedMenuGroup = menu;
                        _.forEach(megaMenuCtrl.menuGroups, function (menuGroup) {
                            menuGroup.select(false);
                        });
                        if (menu) {
                            menu.select(true);
                        }
                        eventStopper($event);
                    }
                    function onMenuItemClick($event, menuItem) {
                        if ($scope.options && $scope.options.onMenuItemClick) {
                            $scope.options.onMenuItemClick($event, menuItem);
                        }
                    }
                    function onMenuGroupClick($event, menuItem) {
                        if ($scope.options && $scope.options.onMenuGroupClick) {
                            $scope.options.onMenuGroupClick($event, menuItem);
                        }
                    }
                    function eventStopper($event) {
                        if ($event) {
                            $event.preventDefault();
                            $event.stopPropagation();
                        }
                    }
                    function selectFirstAvailableMenu(menu) {
                        if (!megaMenuCtrl.autoSelectedMenu) {
                            megaMenuCtrl.autoSelectedMenu = true;
                            select(menu);
                        }
                    }
                }],
            controllerAs: 'megaMenuCtrl'
        };
    }]);
