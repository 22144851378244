"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EndpointSettingsEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const settings_actions_1 = require("../actions/settings.actions");
const endpoints_models_1 = require("../models/endpoints.models");
const endpoints_settings_generate_password_modal_component_1 = require("../components/settings/modal/endpoints-settings-generate-password-modal.component");
const endpoints_settings_enable_uninstall_password_modal_component_1 = require("../components/settings/enable-uninstall-password-modal/endpoints-settings-enable-uninstall-password-modal.component");
const endpoints_settings_direct_ip_modal_component_1 = require("../components/settings/modal/direct-ip-protection/endpoints-settings-direct-ip-modal.component");
const endpoints_settings_allow_local_ips_modal_component_1 = require("../components/settings/modal/allow-local-ips/endpoints-settings-allow-local-ips-modal.component");
const endpoints_settings_tuid_attribute_modal_component_1 = require("../components/settings/modal/tuid-attribute/endpoints-settings-tuid-attribute-modal.component");
class EndpointSettingsEffects {
    constructor(actions$, notificationService, translateService, swgEndpointsApiService, modalService) {
        this.actions$ = actions$;
        this.notificationService = notificationService;
        this.translateService = translateService;
        this.swgEndpointsApiService = swgEndpointsApiService;
        this.modalService = modalService;
        this.getEndpointSettings$ = this.actions$.pipe(effects_1.ofType(settings_actions_1.SwgEndpointSettingsActions.GET_SETTINGS), operators_1.switchMap(() => {
            return this.swgEndpointsApiService.getEndpointSettings().pipe(operators_1.map((response) => {
                return new settings_actions_1.SwgEndpointsSettingsGetSettingsSuccessAction(response);
            }), operators_1.catchError(errMsg => {
                const msg = errMsg.firstFail || null;
                // if we do not have endpoint settings we get an error.
                // handle this by calling update settings to create new settings.
                if (msg && msg.errors[0].code) {
                    const settings = {
                        userLogin: true,
                        enforceUserLogin: false,
                        allowTransparentId: true,
                        autoUpdate: false
                    };
                    return rxjs_1.of(new settings_actions_1.SwgEndpointsSettingsUpdateSettingsAction(settings));
                }
                else {
                    return this.endpointSettingsFailAction('$I18N_ENDPOINT_SETTINGS.NOTIFICATION.ERRORS.GET_SETTINGS');
                }
            }));
        }));
        this.updateEndpointSettings$ = this.actions$.pipe(effects_1.ofType(settings_actions_1.SwgEndpointSettingsActions.UPDATE_SETTINGS), operators_1.switchMap((action) => {
            return this.swgEndpointsApiService.updateEndpointSettings(action.payload).pipe(operators_1.map((response) => {
                return new settings_actions_1.SwgEndpointsSettingsUpdateSettingsSuccessAction(response);
            }), operators_1.catchError(() => this.endpointSettingsFailAction('$I18N_ENDPOINT_SETTINGS.NOTIFICATION.ERRORS.UPDATE_SETTINGS')));
        }));
        this.getTamperProtectedPassword$ = this.actions$.pipe(effects_1.ofType(settings_actions_1.SwgEndpointSettingsActions.GET_TAMPER_PROTECTED_PASSWORD), operators_1.switchMap((action) => {
            return this.swgEndpointsApiService.getTamperProtectedPassword(action.payload).pipe(operators_1.map((response) => {
                if (action.payload && !!response.antiTamperPassword) {
                    this.notificationService.success({
                        msg: this.translateService.instant('$I18N_ENDPOINT_SETTINGS.NOTIFICATION.SUCCESS.GENERATE_DISABLE_PASSWORD')
                    });
                }
                return !!response.antiTamperPassword
                    ? new settings_actions_1.SwgEndpointsSettingsGetTamperProtectedPasswordSuccessAction(response)
                    : new settings_actions_1.SwgEndpointsSettingsGetTamperProtectedPasswordAction(true);
            }), operators_1.catchError(errMsg => {
                const msg = errMsg.firstFail || null;
                // if this is the first time hitting get-anti-tamper-details then we get an error.
                // handle this by calling set-anti-tamper if we get this error.
                if (msg && msg.errors[0].code) {
                    return rxjs_1.of(new settings_actions_1.SwgEndpointsSettingsGetTamperProtectedPasswordAction(true));
                }
                else {
                    return this.endpointSettingsFailAction('$I18N_ENDPOINT_SETTINGS.NOTIFICATION.ERRORS.GET_TAMPER_PROTECTED_PASSWORD');
                }
            }));
        }));
        this.getUninstallPassword$ = this.actions$.pipe(effects_1.ofType(settings_actions_1.SwgEndpointSettingsActions.GET_UNINSTALL_PASSWORD), operators_1.switchMap((action) => {
            return this.swgEndpointsApiService.getUninstallPassword(action.payload).pipe(operators_1.map((response) => {
                if (action.payload && !!response.uninstallPassword) {
                    this.notificationService.success({
                        msg: this.translateService.instant('$I18N_ENDPOINT_SETTINGS.NOTIFICATION.SUCCESS.GENERATE_UNINSTALL_PASSWORD')
                    });
                }
                return !!response.uninstallPassword
                    ? new settings_actions_1.SwgEndpointsSettingsGetUninstallPasswordSuccessAction(response)
                    : new settings_actions_1.SwgEndpointsSettingsGetUninstallPasswordAction(true);
            }), operators_1.catchError(errMsg => {
                const msg = errMsg.firstFail || null;
                // if this is the first time hitting get-anti-tamper-details then we get an error.
                // handle this by calling set-anti-tamper if we get this error.
                if (msg && msg.errors[0].code) {
                    return rxjs_1.of(new settings_actions_1.SwgEndpointsSettingsGetUninstallPasswordAction(true));
                }
                else {
                    return this.endpointSettingsFailAction('$I18N_ENDPOINT_SETTINGS.NOTIFICATION.ERRORS.GET_UNINSTALL_PASSWORD');
                }
            }));
        }));
        this.openUninstallGeneratePasswordModal$ = this.actions$.pipe(effects_1.ofType(settings_actions_1.SwgEndpointSettingsActions.OPEN_UNINSTALL_GENERATE_PASSWORD_MODAL), operators_1.tap(() => {
            const modalConfig = {};
            modalConfig.size = 'md';
            modalConfig.hasBackdrop = true;
            modalConfig.disableClose = true;
            modalConfig.data = endpoints_models_1.SwgSettingsPasswordType.UNINSTALL;
            this.generatePasswordModalInstance = this.modalService.open(endpoints_settings_generate_password_modal_component_1.SwgEndpointsSettingsGeneratePasswordModalComponent, modalConfig);
        }));
        this.closeGeneratePasswordModal$ = this.actions$.pipe(effects_1.ofType(settings_actions_1.SwgEndpointSettingsActions.GET_UNINSTALL_PASSWORD_SUCCESS), operators_1.tap(() => {
            this.closeGeneratePasswordModal();
        }));
        this.openEnableUninstallPasswordConfirmModal$ = this.actions$.pipe(effects_1.ofType(settings_actions_1.SwgEndpointSettingsActions.OPEN_ENABLE_UNINSTALL_PASSWORD_CONFIRM_MODAL), operators_1.tap((action) => {
            const modalConfig = {};
            modalConfig.size = 'md';
            modalConfig.hasBackdrop = true;
            modalConfig.disableClose = true;
            modalConfig.data = action.payload;
            this.enableUninstallPasswordModalInstance = this.modalService.open(endpoints_settings_enable_uninstall_password_modal_component_1.SwgEndpointsSettingsEnableUninstallPasswordModalComponent, modalConfig);
        }));
        this.closeEnableUninstallPasswordConfirmModal$ = this.actions$.pipe(effects_1.ofType(settings_actions_1.SwgEndpointSettingsActions.UPDATE_SETTINGS_SUCCESS), operators_1.tap(() => {
            this.closeEnableUninstallPasswordModal();
        }));
        this.openDirectIPConfirmModal$ = this.actions$.pipe(effects_1.ofType(settings_actions_1.SwgEndpointSettingsActions.OPEN_DIRECT_IP_CONFIRM_MODAL), operators_1.tap((action) => {
            const modalConfig = {};
            modalConfig.size = 'md';
            modalConfig.hasBackdrop = true;
            modalConfig.disableClose = true;
            modalConfig.data = action.payload;
            this.directIpConfirmModalInstance = this.modalService.open(endpoints_settings_direct_ip_modal_component_1.SwgEndpointsSettingsDirectIpProtectionModalComponent, modalConfig);
        }));
        this.closeDirectIPConfirmModal$ = this.actions$.pipe(effects_1.ofType(settings_actions_1.SwgEndpointSettingsActions.UPDATE_SETTINGS_SUCCESS), operators_1.tap(() => {
            this.closeDirectIpConfirmModal();
        }));
        this.openAllowLocalIpsConfirmModal$ = this.actions$.pipe(effects_1.ofType(settings_actions_1.SwgEndpointSettingsActions.OPEN_ALLOW_LOCAL_IPS_CONFIRM_MODAL), operators_1.tap((action) => {
            const modalConfig = {};
            modalConfig.size = 'md';
            modalConfig.hasBackdrop = true;
            modalConfig.disableClose = true;
            modalConfig.data = action.payload;
            this.allowLocalIpsConfirmModalInstance = this.modalService.open(endpoints_settings_allow_local_ips_modal_component_1.SwgEndpointsSettingsAllowLocalIpsModalComponent, modalConfig);
        }));
        this.closeAllowLocalIpsConfirmModal$ = this.actions$.pipe(effects_1.ofType(settings_actions_1.SwgEndpointSettingsActions.UPDATE_SETTINGS_SUCCESS), operators_1.tap(() => {
            this.closeAllowLocalIpsConfirmModal();
        }));
        this.openTUIDAttributeConfirmModal$ = this.actions$.pipe(effects_1.ofType(settings_actions_1.SwgEndpointSettingsActions.OPEN_TUID_ATTRIBUTE_CONFIRM_MODAL), operators_1.tap((action) => {
            const modalConfig = {};
            modalConfig.size = 'md';
            modalConfig.hasBackdrop = true;
            modalConfig.disableClose = true;
            modalConfig.data = action.payload;
            this.tuidAttributeConfirmModalInstance = this.modalService.open(endpoints_settings_tuid_attribute_modal_component_1.SwgEndpointsSettingsTuidAttributeModalComponent, modalConfig);
        }));
        this.closeTUIDAttributeConfirmModal$ = this.actions$.pipe(effects_1.ofType(settings_actions_1.SwgEndpointSettingsActions.UPDATE_SETTINGS_SUCCESS), operators_1.tap(() => {
            this.closeTuidAttributeConfirmModal();
        }));
    }
    closeGeneratePasswordModal() {
        if (this.generatePasswordModalInstance) {
            this.generatePasswordModalInstance.close();
        }
        this.generatePasswordModalInstance = null;
    }
    closeEnableUninstallPasswordModal() {
        if (this.enableUninstallPasswordModalInstance) {
            this.enableUninstallPasswordModalInstance.close();
        }
        this.enableUninstallPasswordModalInstance = null;
    }
    closeDirectIpConfirmModal() {
        if (this.directIpConfirmModalInstance) {
            this.directIpConfirmModalInstance.close();
        }
        this.directIpConfirmModalInstance = null;
    }
    closeAllowLocalIpsConfirmModal() {
        if (this.allowLocalIpsConfirmModalInstance) {
            this.allowLocalIpsConfirmModalInstance.close();
        }
        this.allowLocalIpsConfirmModalInstance = null;
    }
    closeTuidAttributeConfirmModal() {
        if (this.tuidAttributeConfirmModalInstance) {
            this.tuidAttributeConfirmModalInstance.close();
        }
        this.tuidAttributeConfirmModalInstance = null;
    }
    endpointSettingsFailAction(errorMessage) {
        return rxjs_1.of(new settings_actions_1.SwgEndpointsSettingsFailureAction()).pipe(operators_1.tap(() => {
            this.notificationService.error({
                msg: this.translateService.instant(errorMessage)
            });
        }));
    }
}
__decorate([
    effects_1.Effect()
], EndpointSettingsEffects.prototype, "getEndpointSettings$", void 0);
__decorate([
    effects_1.Effect()
], EndpointSettingsEffects.prototype, "updateEndpointSettings$", void 0);
__decorate([
    effects_1.Effect()
], EndpointSettingsEffects.prototype, "getTamperProtectedPassword$", void 0);
__decorate([
    effects_1.Effect()
], EndpointSettingsEffects.prototype, "getUninstallPassword$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], EndpointSettingsEffects.prototype, "openUninstallGeneratePasswordModal$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], EndpointSettingsEffects.prototype, "closeGeneratePasswordModal$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], EndpointSettingsEffects.prototype, "openEnableUninstallPasswordConfirmModal$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], EndpointSettingsEffects.prototype, "closeEnableUninstallPasswordConfirmModal$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], EndpointSettingsEffects.prototype, "openDirectIPConfirmModal$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], EndpointSettingsEffects.prototype, "closeDirectIPConfirmModal$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], EndpointSettingsEffects.prototype, "openAllowLocalIpsConfirmModal$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], EndpointSettingsEffects.prototype, "closeAllowLocalIpsConfirmModal$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], EndpointSettingsEffects.prototype, "openTUIDAttributeConfirmModal$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], EndpointSettingsEffects.prototype, "closeTUIDAttributeConfirmModal$", void 0);
exports.EndpointSettingsEffects = EndpointSettingsEffects;
