<div class="mc-url-protection-policy-block">
  <mc-wizard-step-header label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.STEP_TITLE">
  </mc-wizard-step-header>


  <form class="form-horizontal" mcDefaultLabelClass="col-sm-3" mcDefaultControlContainerClass="col-sm-9"
    [formGroup]="urlProtectionPolicyInboundSettingsForm">
    <mc-field class="mc-custom-switch-form-field"
      label="{{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.CHECK_INBOUND_EMAIL' | translate}}"
      popoverPlacement="top"
      class="mc-custom-label"
      data-unit-test="check-inbound-email-control"
      data-cy-test="check-inbound-email-control"
      helpPopoverContent="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.TOOLTIPS.CHECK_INBOUND_EMAIL' | translate }}">
      <mc-switch formControlName="checkInboundEmail" role="switch" showLabel="true"></mc-switch>
    </mc-field>

    <ng-container *ngIf="isInboundEmail()">
      <mc-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.REWRITE_MODE.LABEL"
        popoverPlacement="top"
        data-unit-test="url-rewrite-mode-control" 
        data-cy-test="url-rewrite-mode-control"
        helpPopoverContent="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.TOOLTIPS.REWRITE_MODE' | translate }}">
        <mc-select formControlName="urlRewriteMode" [options]="rewriteModeValues" [useTranslation]="true">
        </mc-select>
      </mc-field>

      <mc-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.URL_CATEGORY_SCANNING.LABEL"
        popoverPlacement="top"
        data-unit-test="url-category-scanning-mode-control"
        data-cy-test="url-category-scanning-mode-control"
        helpPopoverContent="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.TOOLTIPS.URL_CATEGORY_SCANNING' | translate }}">
        <mc-select formControlName="urlCategoryScanningMode" [options]="urlCategoryScanningValues"
          [useTranslation]="true">
        </mc-select>
      </mc-field>

      <mc-field
        label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.MALICIOUS_URL_DETECTION_ACTION.LABEL"
        popoverPlacement="top"
        data-unit-test="malicious-url-detection-action-control"
        data-cy-test="malicious-url-detection-action-control"
        helpPopoverContent="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.TOOLTIPS.MALICIOUS_URL_DETECTION_ACTION' | translate }}">
        <mc-select formControlName="maliciousUrlDetectionAction" [options]="maliciousUrlDetectionActionValues"
          [useTranslation]="true">
        </mc-select>
      </mc-field>

      <mc-field class="mc-custom-switch-form-field" *ngIf="hasBlockUrlAccess()"
        label="{{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.BROWSER_ISOLATION' | translate}}"
        popoverPlacement="top"
        data-unit-test="browser-isolation-control"
        data-cy-test="browser-isolation-control"
        class="mc-custom-label"
        helpPopoverContent="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.TOOLTIPS.BROWSER_ISOLATION' | translate }}">
        <mc-switch formControlName="browserIsolation" role="switch" showLabel="true"></mc-switch>
      </mc-field>

      <mc-field
        label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.MESSAGE_SUBJECT_PROTECTION.LABEL"
        popoverPlacement="top"
        data-unit-test="message-subject-protection-control"
        data-cy-test="message-subject-protection-control"
        helpPopoverContent="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.TOOLTIPS.MESSAGE_SUBJECT_PROTECTION' | translate }}">
        <mc-select formControlName="messageSubjectProtection" [options]="messageSubjectProtectionValues"
          [useTranslation]="true">
        </mc-select>
      </mc-field>

      <mc-collapsable-panel extendClass="mc-adv-options"
        showMoreLabel="{{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.SHOW_ADVANCED_OPTIONS' | translate}}"
        showLessLabel="{{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.HIDE_ADVANCED_OPTIONS' | translate}}"
        [isExpanded]="false">
        <mc-field class="mc-custom-switch-form-field"
          label="{{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.CREATE_MISSING_HTML_BODY' | translate}}"
          popoverPlacement="top"
          class="mc-custom-label"
          data-unit-test="create-missing-html-body-control"
          data-cy-test="create-missing-html-body-control"
          helpPopoverContent="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.TOOLTIPS.CREATE_MISSING_HTML_BODY' | translate }}">
          <mc-switch formControlName="createMissingHtmlBody" role="switch" showLabel="true"></mc-switch>
        </mc-field>
        <mc-field class="mc-custom-switch-form-field"
          label="{{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.FORCE_SECURE_CONNECTION' | translate}}"
          popoverPlacement="top"
          class="mc-custom-label"
          data-unit-test="force-secure-connection-control"
          data-cy-test="force-secure-connection-control"
          helpPopoverContent="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.TOOLTIPS.FORCE_SECURE_CONNECTION' | translate }}">
          <mc-switch formControlName="forceSecureConnection" role="switch" showLabel="true"></mc-switch>
        </mc-field>
        <mc-field class="mc-custom-switch-form-field"
          label="{{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.IGNORE_SIGNED_MESSAGES' | translate}}"
          popoverPlacement="top"
          class="mc-custom-label"
          data-unit-test="ignore-signed-message-control"
          data-cy-test="ignore-signed-message-control"
          helpPopoverContent="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.TOOLTIPS.IGNORE_SIGNED_MESSAGES' | translate }}">
          <mc-switch formControlName="ignoreSignedMessage" role="switch" showLabel="true"></mc-switch>
        </mc-field>
        <mc-field class="mc-custom-switch-form-field"
          label="{{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.DISPLAY_URL_DOMAIN' | translate}}"
          popoverPlacement="top"
          class="mc-custom-label"
          data-unit-test="display-url-destination-domain-control"
          data-cy-test="display-url-destination-domain-control"
          helpPopoverContent="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.TOOLTIPS.DISPLAY_URL_DOMAIN' | translate }}">
          <mc-switch formControlName="displayUrlDestinationDomain" role="switch" showLabel="true"></mc-switch>
        </mc-field>
        <mc-field class="mc-custom-switch-form-field"
          label="{{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.STRIP_EXTERNAL_SOURCE_MODE' | translate}}"
          popoverPlacement="top"
          class="mc-custom-label"
          data-unit-test="strip-external-source-mode-control"
          data-cy-test="strip-external-source-mode-control"
          helpPopoverContent="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.TOOLTIPS.STRIP_EXTERNAL_SOURCE_MODE' | translate }}">
          <mc-switch formControlName="stripExternalSourceMode" role="switch" showLabel="true"></mc-switch>
        </mc-field>
        <mc-field
          *ngIf="showFileProtocolUrlHandling"
          label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.FILE_PROTOCOL_URL_HANDLING.LABEL"
          popoverPlacement="top"
          data-unit-test="file-protocol-url-handling-control"
          data-cy-test="file-protocol-url-handling-control"
          helpPopoverContent="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.TOOLTIPS.FILE_PROTOCOL_URL_HANDLING' | translate }}">
          <mc-select formControlName="fileProtocolUrlHandling" [options]="fileProtocolUrlHandlingValues"
            [useTranslation]="true">
          </mc-select>
        </mc-field>
      </mc-collapsable-panel>
      <mc-sub-section
        title="{{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.SECTION_TITLES.URL_AND_ATTACHMENTS' | translate}}">
        <mc-field class="mc-custom-switch-form-field"
          label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.BLOCK_URL_WITH_DANGEROUS_FILE"
          popoverPlacement="top"
          class="mc-custom-label"
          data-unit-test="block-urls-with-dangerous-file-extensions-control"
          helpPopoverContent="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.TOOLTIPS.BLOCK_URL_WITH_DANGEROUS_FILE' | translate }}">
          <mc-switch formControlName="blockUrlsWithDangerousFileExtensions" role="switch" showLabel="true"></mc-switch>
        </mc-field>
        <mc-field class="mc-custom-switch-form-field"
          label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.REWRITE_URLS_IN_ATTACHMENTS"
          popoverPlacement="top"
          class="mc-custom-label"
          data-unit-test="rewrite-urls-in-attachments-control"
          data-cy-test="rewrite-urls-in-attachments-control"
          helpPopoverContent="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.TOOLTIPS.REWRITE_URLS_IN_ATTACHMENTS' | translate }}">
          <mc-switch formControlName="rewriteUrlsInAttachments" role="switch" showLabel="true"></mc-switch>
        </mc-field>
        <mc-field class="mc-custom-switch-form-field"
          *ngIf="isRewriteUrlsInAttachments()"
          data-unit-test="attachment-parts-to-rewrite-control"
          data-cy-test="attachment-parts-to-rewrite-control"
          label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.ATTACHMENT_PARTS_TO_REWRITE">
          <mc-checkbox label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.HTML"
            formControlName="attachmentPartHtml">
          </mc-checkbox>
          <mc-checkbox label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.TEXT"
            formControlName="attachmentPartText">
          </mc-checkbox>
          <mc-checkbox label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.CALENDAR"
            formControlName="attachmentPartCalendar">
          </mc-checkbox>
        </mc-field>
        <mc-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.URL_FILE_DOWNLOAD.LABEL"
          popoverPlacement="top"
          data-unit-test="url-file-download-control"
          data-cy-test="url-file-download-control"
          helpPopoverContent="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.TOOLTIPS.URL_FILE_DOWNLOAD' | translate }}">
          <mc-select formControlName="urlFileDownload" [options]="urlFileDownloadValues" [useTranslation]="true">
          </mc-select>
        </mc-field>
        <mc-field class="mc-custom-switch-form-field"
          label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.SCAN_URL_IN_ATTACHMENT"
          popoverPlacement="top"
          data-unit-test="scan-url-in-attachment-control"
          data-cy-test="scan-url-in-attachment-control"
          class="mc-custom-label"
          helpPopoverContent="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.TOOLTIPS.SCAN_URL_IN_ATTACHMENT' | translate }}">
          <mc-switch formControlName="scanUrlInAttachment" role="switch" showLabel="true"></mc-switch>
        </mc-field>
      </mc-sub-section>
      <mc-sub-section
        title="{{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.SECTION_TITLES.ADV_SIMILARITY_CHECKS' | translate}}"
        description="{{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.SECTION_DESCRIPTION.ADV_SIMILARITY_CHECKS' | translate}}">
        <mc-field class="mc-custom-switch-form-field"
          class="mc-custom-label"
          data-unit-test="check-for-similar-urls-control"
          data-cy-test="check-for-similar-urls-control"
          label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.CHECK_FOR_SIMILAR_URLS">
          <mc-switch formControlName="checkForSimilarUrls" role="switch" showLabel="true"></mc-switch>
        </mc-field>
        <ng-container *ngIf="checkForSimilarUrls()">
          <mc-field class="mc-custom-switch-form-field"
            data-unit-test="domain-to-check-again-control"
            data-cy-test="domain-to-check-again-control"
            label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.DOMAIN_TO_CHECK_AGAIN">
            <mc-checkbox label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.YOUR_INTERNAL_DOMAINS"
              formControlName="internalDomains">
            </mc-checkbox>
            <mc-checkbox label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.MIMECAST_MONITORED_EXTERNAL_DOMAINS"
              formControlName="mimecastMonitoringExternalDomain">
            </mc-checkbox>
            <mc-checkbox label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.CUSTOM_MONITORED_EXTERNAL_DOMAINS"
              formControlName="customMonitoringExternalDomain">
            </mc-checkbox>
          </mc-field>
          <mc-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.SIMILAR_URL_DETECTION.LABEL"
            popoverPlacement="top"
            data-unit-test="similar-url-detection-control"
            data-cy-test="similar-url-detection-control"
            helpPopoverContent="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.TOOLTIPS.SIMILAR_URL_DETECTION' | translate }}">
            <mc-select formControlName="similarUrlDetection" [options]="similarUrlDetectionValues" [useTranslation]="true">
            </mc-select>
          </mc-field>
        </ng-container>
      </mc-sub-section>
      <ng-container *ngIf="!isAAAOrFAAUser">
        <mc-sub-section
          data-unit-test="notifications-section"
          title="{{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.SECTION_TITLES.NOTIFICATIONS' | translate}}"
          description="{{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.SECTION_DESCRIPTION.NOTIFICATIONS' | translate}}">
          <div
            class="form-group mc-field"
            data-unit-test="administration-control"
          >
            <label class="control-label col-sm-3">
              <span class="mc-label-wrapper">
                <span>{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.ADMINISTRATORS' | translate }}</span>
              </span>
            </label>
            <div class="col-sm-9">
              <button
                (click)="openSidebar()"
                type="button"
                class="btn btn-secondary">{{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.SELECT_GROUP' | translate}}</button>
                <ng-container *ngIf="selectedGroupDescription$ | async">
                  <div class="mc-selected-group" data-unit-test="selected-group" data-cy-test="selected-group">
                    <div>{{ selectedGroupDescription$ | async }}
                    </div>
                    <div class="mc-delete-group">
                      <i class="far fa-trash-alt" aria-hidden="true" (click)="onDeleteGroup()"></i>
                    </div>
                  </div>
                  <hr class="mc-group-separator"/>
                </ng-container>
            </div>
          </div>
          <mc-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.NOTIFICATION_URL_FORMAT.LABEL"
            popoverPlacement="top"
            data-unit-test="notification-url-format-control"
            helpPopoverContent="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.TOOLTIPS.NOTIFICATION_URL_FORMAT' | translate }}">
            <mc-select formControlName="notificationUrlFormat" [options]="notificationUrlFormatValues" [useTranslation]="true">
            </mc-select>
          </mc-field>
        </mc-sub-section>
      </ng-container>
    </ng-container>
  </form>
</div>
