"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefinitionPageComponent = void 0;
const rxjs_1 = require("rxjs");
const listActions = require("../actions/definition.actions");
const reducer = require("../reducers/index");
class DefinitionPageComponent {
    constructor(store, detailsStore, accountPackageService) {
        this.store = store;
        this.detailsStore = detailsStore;
        this.accountPackageService = accountPackageService;
        this.apLink = 'https://community.mimecast.com/s/article/email-security-cloud-gateway-attachment-protection-definitions';
        this.accessedDefinitionFromMeatball = false;
        this.externalTableSearchCount = 0;
        this.externalTableSearchCount$ = new rxjs_1.BehaviorSubject(this.externalTableSearchCount);
        this.hasIEPPackage = this.accountPackageService.hasInternalEmailProtectAccountPackage();
        this.isAAAOrFAAUser = this.accountPackageService.isAAAOrFAAUser();
    }
    ngOnInit() {
        this.startSubscriptions();
    }
    startSubscriptions() {
        this.definitionDetailsFullDataSubscription = this.detailsStore
            .select(reducer.getSelectedRowFullData)
            .subscribe((value) => {
            if (!value || !this.accessedDefinitionFromMeatball) {
                return;
            }
            this.accessedDefinitionFromMeatball = false;
        });
    }
    rowClicked() {
        this.detailsStore.dispatch(new listActions.OpenDefinitionDetailsSidePanelAction());
    }
    deleteDefinition(definition) {
        this.store.dispatch(new listActions.DefinitionDeleteAttemptAction(definition.id));
    }
    ngOnDestroy() {
        this.definitionDetailsFullDataSubscription.unsubscribe();
    }
}
exports.DefinitionPageComponent = DefinitionPageComponent;
