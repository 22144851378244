"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMetadata = exports.getRequestPage = exports.getPagination = exports.getSelectedRow = exports.getSearchRequest = exports.getTotalCount = exports.getItems = exports.isLoading = exports.reducer = exports.initialState = void 0;
const actions = require("../../actions");
const commonActions = require("../../../common/actions");
const moment = require("moment");
exports.initialState = {
    items: [],
    totalCount: 0,
    searchRequest: {},
    isLoading: false,
    paginatorMetadata: { next: undefined, previous: undefined, pageSize: 50, totalRows: 0 },
    requestedPage: undefined,
    selectedPageSize: 50,
    selectedRow: undefined
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.HELD_QUEUE_REQUEST:
            return Object.assign(Object.assign({}, state), { items: [], totalCount: 0, searchRequest: mergeSearchRequest(state.searchRequest, action.payload.search), isLoading: true, requestedPage: action.payload.page, selectedRow: undefined });
        case actions.HELD_QUEUE_RELEASE_EMAILS:
        case actions.HELD_QUEUE_RELEASE_EMAILS_TO_SANDBOX:
        case actions.HELD_QUEUE_PERMIT_SENDERS:
        case actions.HELD_QUEUE_REJECT_EMAILS:
        case actions.HELD_QUEUE_REJECT_EMAILS_WITH_NOTIFICATION:
        case actions.HELD_QUEUE_REJECT_EMAILS_WITH_NARRATIVE:
        case actions.HELD_QUEUE_BLOCK_SENDERS:
        case actions.HELD_QUEUE_REPORT_EMAILS:
            return Object.assign(Object.assign({}, state), { isLoading: true, items: [], totalCount: 0, requestedPage: undefined, selectedRow: undefined });
        case actions.HELD_QUEUE_PAGINATION:
            return Object.assign(Object.assign({}, state), { isLoading: true, paginatorMetadata: Object.assign(Object.assign({}, state.paginatorMetadata), { pageSize: action.payload.pageSize, next: undefined, previous: undefined }), requestedPage: action.payload, selectedPageSize: action.payload.pageSize || state.selectedPageSize });
        case actions.HELD_QUEUE_SUCCESS:
            return Object.assign(Object.assign({}, state), { items: action.payload.items, totalCount: (action.payload.page && action.payload.page['totalCount']) || 0, isLoading: false, paginatorMetadata: Object.assign(Object.assign({}, action.payload.page), { totalRows: (action.payload.page && action.payload.page['totalCount']) || 0 }) });
        case actions.HELD_QUEUE_FAIL:
            return Object.assign(Object.assign({}, state), { isLoading: false });
        case actions.HELD_QUEUE_SELECT_ROW:
            return Object.assign(Object.assign({}, state), { selectedRow: action.payload });
        case actions.HELD_QUEUE_REFRESH:
            // Without any previous Search being performed, the Refresh button will load the messages
            // dated 48 hours ago.
            let startDate = moment()
                .subtract(2, 'days')
                .toDate();
            let endDate = moment().toDate();
            // With a Search already being performed, the date range is available in the State.
            if (state.searchRequest.start && state.searchRequest.end) {
                // Considering 1 hour to be enough for a logged-in session.
                const searchAnchoredInPresent = moment()
                    .subtract(1, 'hour')
                    .isBefore(moment(state.searchRequest.end));
                if (searchAnchoredInPresent) {
                    // For any Date Range that goes backwards from the present time, the Refresh button will retain the time
                    // interval of a previous search.
                    const diff = moment(state.searchRequest.end).diff(state.searchRequest.start, 'milliseconds');
                    startDate = moment()
                        .subtract(diff, 'milliseconds')
                        .toDate();
                    endDate = moment().toDate();
                }
                else {
                    // If the Date Range is custom or older than 1 hour, the Refresh button will simply reload existing data.
                    // Recent messages will not appear in the search results.
                    startDate = state.searchRequest.start;
                    endDate = state.searchRequest.end;
                }
            }
            return Object.assign(Object.assign({}, state), { isLoading: true, searchRequest: Object.assign(Object.assign({}, state.searchRequest), { start: startDate, end: endDate }) });
        case commonActions.HIGHLIGHT_SELECTED_ROW:
            return Object.assign(Object.assign({}, state), { selectedRow: action.row });
        case commonActions.CLEAR_SELECTED_ROW:
            return Object.assign(Object.assign({}, state), { selectedRow: undefined });
        case actions.HELD_QUEUE_RESET_STATE:
            return exports.initialState;
        default:
            return state;
    }
}
exports.reducer = reducer;
function mergeSearchRequest(prev, next) {
    const merged = {
        start: next.start || prev.start,
        end: next.end || prev.end
    };
    if (!!next.filterBy) {
        if (next.filterBy.length > 0) {
            merged.filterBy = [...next.filterBy];
        }
    }
    else if (!!prev.filterBy) {
        merged.filterBy = [...prev.filterBy];
    }
    if (!!next.searchBy) {
        if (next.searchBy.length > 0) {
            merged.searchBy = [...next.searchBy];
        }
    }
    else if (!!prev.searchBy) {
        merged.searchBy = [...prev.searchBy];
    }
    return merged;
}
const isLoading = (state) => state.isLoading;
exports.isLoading = isLoading;
const getItems = (state) => {
    return [...state.items];
};
exports.getItems = getItems;
const getTotalCount = (state) => {
    return state.totalCount;
};
exports.getTotalCount = getTotalCount;
const getSearchRequest = (state) => {
    return Object.assign(Object.assign(Object.assign({}, state.searchRequest), (!state.searchRequest.start
        ? { start: new Date(new Date().setDate(new Date().getDate() - 2)) }
        : {})), (!state.searchRequest.end ? { end: new Date() } : {}));
};
exports.getSearchRequest = getSearchRequest;
const getSelectedRow = (state) => {
    return state.selectedRow;
};
exports.getSelectedRow = getSelectedRow;
const getPagination = (state) => {
    return Object.assign({}, state.paginatorMetadata);
};
exports.getPagination = getPagination;
const getRequestPage = (state) => {
    if (!!state.requestedPage) {
        return Object.assign({}, state.requestedPage);
    }
    else {
        return {
            pageNumber: 0,
            pageSize: state.selectedPageSize,
            pageToken: undefined
        };
    }
};
exports.getRequestPage = getRequestPage;
const getMetadata = (state) => {
    return {
        pagination: Object.assign({}, state.paginatorMetadata)
    };
};
exports.getMetadata = getMetadata;
