<div class="threat-event-panel-header">
  <div class="threat-event-panel-header-title">
    <span>{{ "$I18N_SWG_THREAT_EVENT_DETAILS_PANEL_HEADER.EVENT" | translate }}</span>:&nbsp;
    <span>{{ threatEvent.filename }}</span>
  </div>

  <mc-paginator
    *ngIf="paginatorData.currentItemIndex > 0"
    [isLoading]="false"
    [start]="paginatorData?.currentItemIndex"
    [end]="paginatorData?.pageSize"
    [previousToken]="paginatorData?.previous"
    [nextToken]="paginatorData?.next"
    (paginate)="selectRow.emit($event)"
  ></mc-paginator>
</div>

<div class="threat-event-panel-header-buttons" *ngIf="hasActionButtonsEnabled">
  <button id="swg-threat-event-panel-quarantine-btn" class="btn btn-primary" (click)="clickManualQuarantine.emit()">
    {{ "$I18N_SWG_THREAT_EVENT_DETAILS_PANEL_HEADER.QUARANTINE" | translate }}
  </button>

  <button id="swg-threat-event-panel-release-btn" class="btn btn-primary" (click)="clickRelease.emit()">
    {{ "$I18N_SWG_THREAT_EVENT_DETAILS_PANEL_HEADER.RELEASE" | translate }}
  </button>

  <button id="swg-threat-event-panel-download-btn" class="btn btn-default" (click)="clickDownload.emit()">
    {{ "$I18N_SWG_THREAT_EVENT_DETAILS_PANEL_HEADER.DOWNLOAD" | translate }}
  </button>

  <button id="swg-threat-event-panel-report-fp-btn" class="btn btn-default" (click)="clickReportFalsePositive.emit()">
    {{ "$I18N_SWG_THREAT_EVENT_DETAILS_PANEL_HEADER.REPORT_FALSE_POSITIVE" | translate }}
  </button>
</div>
