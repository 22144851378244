"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DnsApiService = void 0;
const operators_1 = require("rxjs/operators");
class DnsApiService {
    constructor(http) {
        this.http = http;
    }
    getDnsServerIps() {
        return this.http.post('/api/swg/get-dns-ip', '').pipe(operators_1.map(response => {
            // @ts-ignore
            return response.first;
        }));
    }
    getCertificateDownloadUrl() {
        return this.http
            .post('/download/get-file-link', { url: '/api/swg/get-public-ca' })
            .pipe(operators_1.map((response) => response.body));
    }
}
exports.DnsApiService = DnsApiService;
