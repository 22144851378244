"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgUrlCheckPageComponent = void 0;
const reducers = require("../../reducers/index");
const url_check_actions_1 = require("app-new/swg/url-check-report/actions/url-check.actions");
const url_report_action_1 = require("app-new/swg/url-check-report/actions/url-report.action");
class SwgUrlCheckPageComponent {
    constructor(store) {
        this.store = store;
    }
    ngOnInit() {
        this.swgClassifications$ = this.store.select(reducers.getClassification);
        this.isApiProcessing$ = this.store.select(reducers.isApiProcessing);
        this.isGenericError$ = this.store.select(reducers.isGenericError);
        this.isInitialRequest$ = this.store.select(reducers.isInitialRequest);
    }
    checkClassification(urlOrDomain) {
        this.store.dispatch(new url_check_actions_1.SwgGetUrlClassificationAction(urlOrDomain));
    }
    reportClassification(category) {
        this.store.dispatch(new url_report_action_1.SwgOpenReportUrlModalAction(category));
    }
}
exports.SwgUrlCheckPageComponent = SwgUrlCheckPageComponent;
