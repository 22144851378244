"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewRuleComponent = void 0;
const core_1 = require("@angular/core");
const state_enum_1 = require("app-new/archive/supervision/types/state.enum");
class ViewRuleComponent {
    constructor() {
        this.loading = false;
        this.hasEditPermissions = false;
        this.onLoadDetails = new core_1.EventEmitter();
        this.onEdit = new core_1.EventEmitter();
        this.onClose = new core_1.EventEmitter();
        this.onEnableDisable = new core_1.EventEmitter();
        this.onDelete = new core_1.EventEmitter();
    }
    close() {
        this.onClose.emit();
    }
    edit() {
        this.close();
        this.onEdit.emit(this.paginationInfo.currentEntity);
    }
    loadRuleDetails(event) {
        const rule = event.token;
        this.onLoadDetails.emit(rule);
    }
    isActive() {
        return this.ruleDetails && this.ruleDetails.state === state_enum_1.RuleState.ACTIVE;
    }
    enableDisable() {
        this.onEnableDisable.emit(this.ruleDetails);
    }
    deleteRule(selectedRule) {
        this.close();
        this.onDelete.emit(selectedRule);
    }
}
exports.ViewRuleComponent = ViewRuleComponent;
