"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageInfoEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const coreReducers = require("app-new/core/reducers");
const notification_actions_1 = require("app-new/components/notification/actions/notification.actions");
const actions = require("../../actions");
const reducers = require("../../reducers");
const actions_1 = require("../../../held-messages/actions");
const message_info_notifications_1 = require("./message-info.notifications");
class MessageInfoEffects {
    constructor(actions$, store$, apiService, archiveApiService) {
        this.actions$ = actions$;
        this.store$ = store$;
        this.apiService = apiService;
        this.archiveApiService = archiveApiService;
        this.showMessageInfo$ = this.actions$.pipe(effects_1.ofType(actions.SHOW_MESSAGE_INFO), operators_1.map((action) => {
            return new actions.GetMessageInfoAction(action.row);
        }));
        this.getMessageInfo$ = this.actions$.pipe(effects_1.ofType(actions.GET_MESSAGE_INFO), operators_1.withLatestFrom(this.store$.select(coreReducers.getAccount)), operators_1.switchMap(([action, account]) => {
            const loadContent = account.contentAdministratorDefaultView === 'Content' || action.forceLoadContent;
            return this.apiService.getMessageInfo(action.row.id, loadContent).pipe(operators_1.map(response => new actions.GetMessageInfoSuccessAction(response, action.forceLoadContent)), operators_1.catchError(() => {
                return rxjs_1.of(new actions.GetMessageInfoFailAction());
            }));
        }));
        this.releaseHeldEmail$ = this.actions$.pipe(effects_1.ofType(actions.RELEASE_HELD_EMAIL), operators_1.flatMap(() => this.store$.select(reducers.getMessageInfo).pipe(operators_1.first())), operators_1.switchMap(message => {
            return this.apiService.releaseHeldEmail(message.id).pipe(operators_1.map(() => new actions.ReleaseHeldEmailSuccessAction()), operators_1.catchError(() => {
                return rxjs_1.of(new actions.ReleaseHeldEmailFailAction());
            }));
        }));
        this.releaseHeldEmailToSandbox$ = this.actions$.pipe(effects_1.ofType(actions.RELEASE_HELD_EMAIL_TO_SANDBOX), operators_1.flatMap(() => this.store$.select(reducers.getMessageInfo).pipe(operators_1.first())), operators_1.switchMap(message => {
            return this.apiService.releaseHeldEmailToSandbox(message.id).pipe(operators_1.map(() => new actions.ReleaseHeldEmailToSandboxSuccessAction()), operators_1.catchError(() => {
                return rxjs_1.of(new actions.ReleaseHeldEmailToSandboxFailAction());
            }));
        }));
        this.permitSenderHeldEmail$ = this.actions$.pipe(effects_1.ofType(actions.PERMIT_SENDER_HELD_EMAIL), operators_1.flatMap(() => this.store$.select(reducers.getMessageInfo).pipe(operators_1.first())), operators_1.switchMap(messageInfo => {
            return this.apiService.permitSenderHeldEmail(messageInfo.id).pipe(operators_1.map(() => new actions.PermitSenderForRecipientSuccessAction()), operators_1.catchError(() => rxjs_1.of(new actions.PermitSenderForRecipientFailAction())));
        }));
        this.permitSenderRejectedEmail$ = this.actions$.pipe(effects_1.ofType(actions.PERMIT_SENDER_REJECTED_EMAIL), operators_1.flatMap(() => this.store$.select(reducers.getMessageInfo).pipe(operators_1.first())), operators_1.switchMap(messageInfo => {
            return this.apiService.permitSenderRejectedEmail(messageInfo.id).pipe(operators_1.map(() => new actions.PermitSenderForRecipientSuccessAction()), operators_1.catchError(() => rxjs_1.of(new actions.PermitSenderForRecipientFailAction())));
        }));
        this.permitSender$ = this.actions$.pipe(effects_1.ofType(actions.PERMIT_SENDER), operators_1.withLatestFrom(this.store$.select(reducers.getMessageInfo), this.store$.select(reducers.getSelectedEmail)), operators_1.switchMap(([, messageInfo, selectedEmail]) => {
            const instances = [
                {
                    action: 'permit',
                    sender: messageInfo.recipientInfo.messageInfo.fromHeader,
                    to: selectedEmail.owner
                }
            ];
            if (messageInfo.recipientInfo.messageInfo.fromHeader !==
                messageInfo.recipientInfo.messageInfo.fromEnvelope) {
                instances.push({
                    action: 'permit',
                    sender: messageInfo.recipientInfo.messageInfo.fromEnvelope,
                    to: selectedEmail.owner
                });
            }
            return this.apiService.permitOrBlockSender(instances).pipe(operators_1.map(() => new actions.PermitSenderForRecipientSuccessAction()), operators_1.catchError(() => rxjs_1.of(new actions.PermitSenderForRecipientFailAction())));
        }));
        this.rejectHeldEmail$ = this.actions$.pipe(effects_1.ofType(actions.REJECT_HELD_EMAIL), operators_1.flatMap(() => this.store$.select(reducers.getMessageInfo).pipe(operators_1.first())), operators_1.switchMap(message => {
            return this.apiService.rejectHeldEmail({ id: message.id, notify: false }).pipe(operators_1.map(() => new actions.RejectHeldEmailSuccessAction()), operators_1.catchError(() => {
                return rxjs_1.of(new actions.RejectHeldEmailFailAction());
            }));
        }));
        this.rejectHeldEmailWithNarrative$ = this.actions$.pipe(effects_1.ofType(actions.REJECT_HELD_EMAIL_WITH_NARRATIVE), operators_1.flatMap(() => this.store$.select(reducers.getMessageInfo).pipe(operators_1.first())), operators_1.switchMap(message => [
            new actions_1.HeldQueueRejectNarrativeEmailsOpenModalAction({ messages: [message] })
        ]));
        this.rejectHeldEmailWithNotification$ = this.actions$.pipe(effects_1.ofType(actions.REJECT_HELD_EMAIL_WITH_NOTIFICATION), operators_1.withLatestFrom(this.store$.select(reducers.getMessageInfo)), operators_1.switchMap(([action, message]) => {
            return this.apiService
                .rejectHeldEmail({
                id: message.id,
                notify: true,
                reason: action.reason,
                notes: action.notes
            })
                .pipe(operators_1.map(() => new actions.RejectHeldEmailWithNotificationSuccessAction()), operators_1.catchError(() => {
                return rxjs_1.of(new actions.RejectHeldEmailWithNotificationFailAction());
            }));
        }));
        this.blockSenderHeldEmail$ = this.actions$.pipe(effects_1.ofType(actions.BLOCK_SENDER_HELD_EMAIL), operators_1.flatMap(() => this.store$.select(reducers.getMessageInfo).pipe(operators_1.first())), operators_1.switchMap(messageInfo => {
            return this.apiService.blockSenderHeldEmail(messageInfo.id).pipe(operators_1.map(() => new actions.BlockSenderForRecipientSuccessAction()), operators_1.catchError(() => rxjs_1.of(new actions.BlockSenderForRecipientFailAction())));
        }));
        this.blockSenderRejectedEmail$ = this.actions$.pipe(effects_1.ofType(actions.BLOCK_SENDER_REJECTED_EMAIL), operators_1.flatMap(() => this.store$.select(reducers.getMessageInfo).pipe(operators_1.first())), operators_1.switchMap(messageInfo => {
            return this.apiService.blockSenderRejectedEmail(messageInfo.id).pipe(operators_1.map(() => new actions.BlockSenderForRecipientSuccessAction()), operators_1.catchError(() => rxjs_1.of(new actions.BlockSenderForRecipientFailAction())));
        }));
        this.blockSender$ = this.actions$.pipe(effects_1.ofType(actions.BLOCK_SENDER), operators_1.withLatestFrom(this.store$.select(reducers.getMessageInfo), this.store$.select(reducers.getSelectedEmail)), operators_1.switchMap(([, messageInfo, selectedEmail]) => {
            const instances = [
                {
                    action: 'block',
                    sender: messageInfo.recipientInfo.messageInfo.fromHeader,
                    to: selectedEmail.owner
                }
            ];
            if (messageInfo.recipientInfo.messageInfo.fromHeader !==
                messageInfo.recipientInfo.messageInfo.fromEnvelope) {
                instances.push({
                    action: 'block',
                    sender: messageInfo.recipientInfo.messageInfo.fromEnvelope,
                    to: selectedEmail.owner
                });
            }
            return this.apiService.permitOrBlockSender(instances).pipe(operators_1.map(() => new actions.BlockSenderForRecipientSuccessAction()), operators_1.catchError(() => rxjs_1.of(new actions.BlockSenderForRecipientFailAction())));
        }));
        this.retryDelivery$ = this.actions$.pipe(effects_1.ofType(actions.RETRY_DELIVERY), operators_1.flatMap(() => this.store$.select(reducers.getMessageInfo).pipe(operators_1.first())), operators_1.switchMap(message => {
            return this.apiService.retryDelivery(message.id).pipe(operators_1.map(() => new actions.RetryDeliverySuccessAction()), operators_1.catchError(() => {
                return rxjs_1.of(new actions.RetryDeliveryFailAction());
            }));
        }));
        this.rejectQueuedEmail$ = this.actions$.pipe(effects_1.ofType(actions.REJECT_QUEUED_EMAIL), operators_1.flatMap(() => this.store$.select(reducers.getMessageInfo).pipe(operators_1.first())), operators_1.switchMap(message => {
            return this.apiService.rejectQueuedEmail(message.id).pipe(operators_1.map(() => new actions.RejectQueuedEmailSuccessAction()), operators_1.catchError(() => {
                return rxjs_1.of(new actions.RejectQueuedEmailFailAction());
            }));
        }));
        this.bounceQueuedEmail$ = this.actions$.pipe(effects_1.ofType(actions.BOUNCE_QUEUED_EMAIL), operators_1.flatMap(() => this.store$.select(reducers.getMessageInfo).pipe(operators_1.first())), operators_1.switchMap(message => {
            return this.apiService.bounceQueuedEmail(message.id).pipe(operators_1.map(() => new actions.BounceQueuedEmailSuccessAction()), operators_1.catchError(() => rxjs_1.of(new actions.BounceQueuedEmailFailAction())));
        }));
        this.recalculateDeliveryRoute$ = this.actions$.pipe(effects_1.ofType(actions.RECALCULATE_DELIVERY_ROUTE), operators_1.flatMap(() => this.store$.select(reducers.getMessageInfo).pipe(operators_1.first())), operators_1.switchMap(message => {
            return this.apiService.recalculateDeliveryRoute(message.id).pipe(operators_1.map(() => new actions.RecalculateDeliveryRouteSuccessAction()), operators_1.catchError(() => rxjs_1.of(new actions.RecalculateDeliveryRouteFailAction())));
        }));
        this.notifications$ = this.actions$.pipe(effects_1.ofType(...Object.keys(message_info_notifications_1.notifications)), operators_1.map((action) => {
            const notification = message_info_notifications_1.notifications[action.type];
            return new notification_actions_1.NotificationShowAction({
                config: { msg: notification.message },
                type: notification.type
            });
        }));
        this.downloadFile$ = this.actions$.pipe(effects_1.ofType(actions.DOWNLOAD_FILE), operators_1.tap((action) => {
            this.archiveApiService.triggerDownload('/api/message-finder/get-file/' + action.id + '?forceDownload=true');
        }));
    }
}
__decorate([
    effects_1.Effect()
], MessageInfoEffects.prototype, "showMessageInfo$", void 0);
__decorate([
    effects_1.Effect()
], MessageInfoEffects.prototype, "getMessageInfo$", void 0);
__decorate([
    effects_1.Effect()
], MessageInfoEffects.prototype, "releaseHeldEmail$", void 0);
__decorate([
    effects_1.Effect()
], MessageInfoEffects.prototype, "releaseHeldEmailToSandbox$", void 0);
__decorate([
    effects_1.Effect()
], MessageInfoEffects.prototype, "permitSenderHeldEmail$", void 0);
__decorate([
    effects_1.Effect()
], MessageInfoEffects.prototype, "permitSenderRejectedEmail$", void 0);
__decorate([
    effects_1.Effect()
], MessageInfoEffects.prototype, "permitSender$", void 0);
__decorate([
    effects_1.Effect()
], MessageInfoEffects.prototype, "rejectHeldEmail$", void 0);
__decorate([
    effects_1.Effect()
], MessageInfoEffects.prototype, "rejectHeldEmailWithNarrative$", void 0);
__decorate([
    effects_1.Effect()
], MessageInfoEffects.prototype, "rejectHeldEmailWithNotification$", void 0);
__decorate([
    effects_1.Effect()
], MessageInfoEffects.prototype, "blockSenderHeldEmail$", void 0);
__decorate([
    effects_1.Effect()
], MessageInfoEffects.prototype, "blockSenderRejectedEmail$", void 0);
__decorate([
    effects_1.Effect()
], MessageInfoEffects.prototype, "blockSender$", void 0);
__decorate([
    effects_1.Effect()
], MessageInfoEffects.prototype, "retryDelivery$", void 0);
__decorate([
    effects_1.Effect()
], MessageInfoEffects.prototype, "rejectQueuedEmail$", void 0);
__decorate([
    effects_1.Effect()
], MessageInfoEffects.prototype, "bounceQueuedEmail$", void 0);
__decorate([
    effects_1.Effect()
], MessageInfoEffects.prototype, "recalculateDeliveryRoute$", void 0);
__decorate([
    effects_1.Effect()
], MessageInfoEffects.prototype, "notifications$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], MessageInfoEffects.prototype, "downloadFile$", void 0);
exports.MessageInfoEffects = MessageInfoEffects;
