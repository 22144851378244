"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tableSectionSearch = exports.tableColumnsStartHidden = exports.tableColumnsAlwaysVisible = exports.testRowClick = exports.testRowClickMessageType = exports.exportColumnList = exports.tableColumnList = void 0;
exports.tableColumnList = [
    'fromAddress',
    'toAddress',
    'created',
    'ipAddress',
    'info',
    'spamScore',
    'detectionLevel',
    'description',
    'right-column'
];
exports.exportColumnList = [
    'fromEnv',
    'to',
    'sent',
    'senderIP',
    'info',
    'rejectionType',
    'spamScore',
    'spamDetection'
];
exports.testRowClickMessageType = {
    id: 'string',
    created: 'string',
    description: 'string',
    fromAddress: 'string',
    info: 'string',
    ipAddress: 'string',
    remoteEhlo: 'string',
    remoteName: 'string',
    toAddress: 'string',
    toAddressPostCheck: 'string',
    toAddressPreCheck: 'string',
    type: 2,
    manageRecipient: false,
    messageType: 'rejectedMessage'
};
exports.testRowClick = {
    id: 'string',
    created: 'string',
    description: 'string',
    fromAddress: 'string',
    info: 'string',
    ipAddress: 'string',
    remoteEhlo: 'string',
    remoteName: 'string',
    toAddress: 'string',
    toAddressPostCheck: 'string',
    toAddressPreCheck: 'string',
    type: 2,
    manageRecipient: false
};
exports.tableColumnsAlwaysVisible = ['description', 'info', 'created', 'right-column'];
exports.tableColumnsStartHidden = ['spamScore', 'detectionLevel'];
exports.tableSectionSearch = {
    field: 'search',
    header: 'header',
    options: ['type', 'from', 'to', 'info', 'remoteIp']
};
