"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const actions = require("../actions");
const reducers = require("../reducers");
const logged_out_modal_component_1 = require("../modals/logged-out-modal/logged-out-modal.component");
class AppEffects {
    constructor(actions$, service, store, sessionApi, modalService, locationService, seaStarServerService, windowService, cookieService) {
        this.actions$ = actions$;
        this.service = service;
        this.store = store;
        this.sessionApi = sessionApi;
        this.modalService = modalService;
        this.locationService = locationService;
        this.seaStarServerService = seaStarServerService;
        this.windowService = windowService;
        this.cookieService = cookieService;
        this.sessionLoad$ = this.actions$.pipe(effects_1.ofType(actions.session.LOAD), operators_1.flatMap(() => {
            if (this.service.isSessionPresent('/u/login/?gta=administration')) {
                if (this.service.isFirstAppLoad) {
                    return [
                        new actions.session.LoadSuccessAction(this.service.session),
                        new actions.app.FirstTimeLoadAction(),
                        new actions.app.LoadAction()
                    ];
                }
                return [
                    new actions.session.LoadSuccessAction(this.service.session),
                    new actions.app.LoadAction()
                ];
            }
            return [new actions.session.LoadFailAction()];
        }));
        this.loadAdcon3URLs$ = this.actions$.pipe(effects_1.ofType(actions.session.GET_ADCON_3_URLS), operators_1.switchMap(() => {
            return this.seaStarServerService.getAdcon3Boxes().pipe(operators_1.map(urlsString => {
                this.windowService.setAdconUrls(urlsString);
                return new actions.session.LoadAction();
            }));
        }));
        this.sessionLoadFail$ = this.actions$.pipe(effects_1.ofType(actions.session.LOAD_FAIL, actions.app.INVALID_ACCESS), operators_1.flatMap(() => [new actions.legacy.LogoutAction(), new actions.session.LogoutAction()]));
        this.appLogout$ = this.actions$.pipe(effects_1.ofType(actions.app.LOGOUT), operators_1.flatMap(() => [new actions.legacy.LogoutAction(), new actions.session.LogoutAction()]));
        this.appLogoutSuccess$ = this.actions$.pipe(effects_1.ofType(actions.app.LOGOUT), operators_1.zip(this.actions$.pipe(effects_1.ofType(actions.session.LOGOUT_SUCCESS), operators_1.first()), this.actions$.pipe(effects_1.ofType(actions.legacy.LOGOUT_SUCCESS), operators_1.first())), operators_1.flatMap(([, samlLogoutUrlData]) => {
            if (!!samlLogoutUrlData && !!samlLogoutUrlData.payload) {
                this.service.redirectToSamlUrl(samlLogoutUrlData.payload);
            }
            else {
                return this.store.select(reducers.isAccessInvalid).pipe(operators_1.first(), operators_1.tap((invalidAccess) => this.service.logoutAndGoToLogin(invalidAccess)));
            }
        }));
        this.appLogoutFail$ = this.actions$.pipe(effects_1.ofType(actions.session.LOGOUT_FAIL, actions.legacy.LOGOUT_FAIL), operators_1.flatMap(() => this.store.select(reducers.isAccessInvalid).pipe(operators_1.first())), operators_1.tap((invalidAccess) => this.service.logoutAndGoToLogin(invalidAccess)) // TODO what do we do if one of the logouts fails
        );
        this.sessionLogout$ = this.actions$.pipe(effects_1.ofType(actions.session.LOGOUT), operators_1.flatMap(() => this.sessionApi.logout().pipe(operators_1.map(response => {
            return new actions.session.LogoutSuccessAction(response.first.samlLogoutUrl);
        }), operators_1.catchError(() => rxjs_1.of(new actions.session.LogoutFailAction())))));
        this.appReload$ = this.service.appReload$.pipe(operators_1.map(() => new actions.app.UnloadAction()));
        this.userLoggedOutAlready$ = this.actions$.pipe(effects_1.ofType(actions.app.ALREADY_LOGGED_OUT), operators_1.exhaustMap(() => {
            const modalConfig = {};
            modalConfig.size = 'md';
            modalConfig.hasBackdrop = true;
            modalConfig.disableClose = true;
            return this.modalService
                .open(logged_out_modal_component_1.LoggedOutModalComponent, modalConfig)
                .afterClose()
                .pipe(operators_1.flatMap(() => [new actions.session.LogoutAction(), new actions.legacy.LogoutAction()]));
        }));
        this.pageReload$ = this.actions$.pipe(effects_1.ofType(actions.app.PAGE_RELOAD), operators_1.tap(() => this.locationService.reload()));
    }
}
__decorate([
    effects_1.Effect()
], AppEffects.prototype, "sessionLoad$", void 0);
__decorate([
    effects_1.Effect()
], AppEffects.prototype, "loadAdcon3URLs$", void 0);
__decorate([
    effects_1.Effect()
], AppEffects.prototype, "sessionLoadFail$", void 0);
__decorate([
    effects_1.Effect()
], AppEffects.prototype, "appLogout$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], AppEffects.prototype, "appLogoutSuccess$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], AppEffects.prototype, "appLogoutFail$", void 0);
__decorate([
    effects_1.Effect()
], AppEffects.prototype, "sessionLogout$", void 0);
__decorate([
    effects_1.Effect()
], AppEffects.prototype, "appReload$", void 0);
__decorate([
    effects_1.Effect()
], AppEffects.prototype, "userLoggedOutAlready$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], AppEffects.prototype, "pageReload$", void 0);
exports.AppEffects = AppEffects;
