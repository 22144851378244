'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const _ = require("lodash");
const operators_1 = require("rxjs/operators");
angular.module('export-logs-aside.controller', [])
    .controller('ExportLogsAsideCtrl', [
    '$scope', '$rootScope', 'exportLogsAsideService', 'growl', 'apiErrorHandlerService', 'adconCommonsService', 'LegacyAdconService', '$http', 'FeatureFlagService',
    function ($scope, $rootScope, exportLogsAsideService, growl, apiErrorHandlerService, adconCommonsService, legacyAdconService, $http, featureFlagService) {
        const self = this;
        const { exportUID, adconSession, user, from, to, columnsToExport, filters, query, exportFilterMap } = $scope.$parent.$resolve;
        self.formats = exportLogsAsideService.constants.FORMATS;
        self.columns = _.chunk(columnsToExport, columnsToExport.length / 2);
        self.form = {};
        self.enableExport = enableExport;
        self.createExport = createExport;
        self.createExportDataPlatform = createExportDataPlatform;
        self.temporaryDataPlatformAuditExport = featureFlagService.getBooleanFlag('rollout-data-platform-audit-export') && exportUID === 'accountauditlogs';
        clearActiveItems();
        function enableExport() {
            if (self.temporaryDataPlatformAuditExport) {
                return getActiveColumns().length == 0;
            }
            return self.form.isInvalid() || getActiveColumns().length == 0;
        }
        function createExport() {
            const headers = getHeaders();
            const classicSessionId = adconSession.SysAccountSessionId;
            const accountCode = adconSession.accountCode;
            const data = {
                SysAccountSessionId: classicSessionId,
                SysWindow: exportLogsAsideService.constants.SYS_WINDOW.CONNECT,
                logon: user.first.emailAddress,
                accountcode: accountCode,
                format: self.format.key,
                columns: headers,
                recipient: self.recipient,
                exportuid: exportUID,
                fromdate: from,
                todate: to,
                sid: adconSession.sid
            };
            if (query) {
                data['search'] = query;
            }
            if (exportUID === exportLogsAsideService.constants.EXPORT_UID.AUDIT_LOGS) {
                if (filters) {
                    data.filters = filters;
                }
            }
            else {
                if (filters) {
                    // eslint-disable-next-line
                    var exportFilter = '';
                    _.forEach(filters, function (filterModel, key) {
                        exportFilter = exportFilter + (!_.isEmpty(exportFilter) ? '~' : '');
                        exportFilter = exportFilter + exportFilterMap[key].filterFieldLegacy + ':';
                        Object.keys(filterModel).forEach(function (column) {
                            exportFilter = exportFilter + (exportFilterMap[key][column] ? (exportFilterMap[key][column] + ',') : '');
                        });
                        exportFilter = exportFilter.substring(0, exportFilter.length - 1);
                    });
                }
                data.filters = exportFilter;
            }
            return legacyAdconService.postSubmit('/mimecast/exportdata', data, 'exportdata-response')
                .pipe(operators_1.first()).toPromise().then(function (sessionData) {
                if (sessionData.status === 500) {
                    growl.error(sessionData.message, { ttl: apiErrorHandlerService.getErrorService().constants.INT_DEFAULT_TIMEOUT });
                }
                else {
                    growl.success('$I18N_EXPORT_LOGS_ASIDE_EXPORT_REQUESTED', { ttl: apiErrorHandlerService.getErrorService().constants.INT_DEFAULT_TIMEOUT });
                }
                $scope.$close();
            }, function (reason) {
                growl.error(reason, { ttl: apiErrorHandlerService.getErrorService().constants.INT_DEFAULT_TIMEOUT });
                $scope.$close();
            });
        }
        function getFormattedDateTime(date) {
            const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
            return new Date(date).toISOString().replace('Z', `[${timeZone}]`);
        }
        function getSearchTermsFromQuery() {
            const search = {};
            if (query) {
                const [searchValue, fields] = query.split(':');
                const searchFields = {
                    all: [
                        'user', 'details', 'type', 'action'
                    ],
                    emailaddr: [
                        'user'
                    ],
                    information: [
                        'details'
                    ],
                    auditdesc: [
                        'type'
                    ]
                };
                const searchField = searchFields[fields] || searchFields['all'];
                search['searchField'] = searchField;
                search['searchValue'] = searchValue;
            }
            return search;
        }
        function getDataPlatformActiveColumns() {
            const columnLookup = {
                'user': 'user',
                'audittype': 'type',
                'category': 'legacyCategory',
                'details': 'details',
                'datetime': 'timestamp'
            };
            return getActiveColumns().map(column => columnLookup[column.key]);
        }
        function createExportDataPlatform() {
            return $http.post('/auth/api/audit/get-categories').then(function (response) {
                var _a, _b, _c, _d, _e, _f;
                const accountCode = adconSession.accountCode;
                const categories = (_c = (_b = (_a = response === null || response === void 0 ? void 0 : response.raw) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.categories;
                const filterIds = (_f = (_e = (_d = filters === null || filters === void 0 ? void 0 : filters.split(':')) === null || _d === void 0 ? void 0 : _d[1]) === null || _e === void 0 ? void 0 : _e.split(',')) === null || _f === void 0 ? void 0 : _f.map((id) => Number(id));
                const legacyCategory = categories === null || categories === void 0 ? void 0 : categories.reduce((filtered, category) => {
                    if (filterIds === null || filterIds === void 0 ? void 0 : filterIds.includes(category === null || category === void 0 ? void 0 : category.id)) {
                        filtered === null || filtered === void 0 ? void 0 : filtered.push(category === null || category === void 0 ? void 0 : category.name);
                    }
                    return filtered;
                }, []);
                const timestampRangeStartsAt = getFormattedDateTime(from);
                const timestampRangeEndsAt = getFormattedDateTime(to);
                const search = getSearchTermsFromQuery();
                const exportColumn = getDataPlatformActiveColumns();
                const options = Object.assign(Object.assign({}, search), { accountCode,
                    timestampRangeStartsAt,
                    timestampRangeEndsAt,
                    legacyCategory,
                    exportColumn, orderBy: 'timestamp:desc' });
                return $http.post(`/bridge/accounts/${accountCode}/audit/v1/events/export`, options).then(function (exportResponse) {
                    if (!exportResponse.data.url) {
                        growl.error('No results to export', { ttl: apiErrorHandlerService.getErrorService().constants.INT_DEFAULT_TIMEOUT });
                        $scope.$close();
                    }
                    else {
                        const link = document.createElement('a');
                        link.setAttribute('target', '_self');
                        link.setAttribute('href', exportResponse.data.url);
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                        $scope.$close();
                    }
                });
            });
        }
        function getActiveColumns() {
            let columns = [];
            _.forEach(self.columns, function (columnGroup) {
                const activeItems = _.filter(columnGroup, column => !!column[adconCommonsService.CONSTANTS.ACTIVE]);
                columns = columns.concat(activeItems);
            });
            return _.sortBy(columns, exportLogsAsideService.constants.EXPORT_ORDER);
        }
        function getHeaders() {
            const columnKeys = [];
            _.forEach(getActiveColumns(), function (item) {
                columnKeys.push(item.key);
            });
            return columnKeys && (columnKeys.join('~'));
        }
        function clearActiveItems() {
            _.forEach(self.columns, function (columnGroup) {
                const activeItems = _.filter(columnGroup, column => !!column[adconCommonsService.CONSTANTS.ACTIVE]);
                _.forEach(activeItems, function (activeItem) {
                    activeItem.active = false;
                });
            });
        }
    }
]);
