"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiemChannelListEffects = void 0;
const effects_1 = require("@ngrx/effects");
const operators_1 = require("rxjs/operators");
const rxjs_1 = require("rxjs");
const TableStore = require("@mimecast-ui/table-store");
const actions = require("../../actions/siem-channels/list.actions");
const sidebar = require("../../actions/siem-channels/sidebar.actions");
const notification_actions_1 = require("../../../components/notification/actions/notification.actions");
const siem_channel_delete_confirmation_modal_component_1 = require("../../components/modals/siem-channel-delete-confirmation-modal/siem-channel-delete-confirmation-modal.component");
class SiemChannelListEffects {
    constructor(actions$, siemChannelApi$, translate$, modal$) {
        this.actions$ = actions$;
        this.siemChannelApi$ = siemChannelApi$;
        this.translate$ = translate$;
        this.modal$ = modal$;
        this.deleteChannel$ = this.actions$.pipe(effects_1.ofType(actions.SiemChannelListActionsEnums.SIEM_CHANNEL_DELETE), operators_1.map((action) => action.id), operators_1.switchMap((id) => {
            return this.siemChannelApi$.deleteChannel(id).pipe(operators_1.mergeMap(() => {
                return [
                    new notification_actions_1.NotificationShowSuccessAction(this.translate$.instant('$I18N_API_APPLICATIONS.NOTIFICATIONS.DELETE_CHANNEL.SUCCESS')),
                    new actions.SiemChannelDeleteSuccess(),
                    new actions.SiemChannelDeleteConfirmationModalClose(),
                    new TableStore.LoadAction({ tableId: 'SiemChannelListTable' })
                ];
            }), operators_1.catchError(error => rxjs_1.merge(rxjs_1.of(this.getErrorNotificationAction(error)), rxjs_1.of(new actions.SiemChannelDeleteFailure()), rxjs_1.of(new actions.SiemChannelDeleteConfirmationModalClose()))));
        }));
        this.openSiemChannelDeleteAlert = this.actions$.pipe(effects_1.ofType(actions.SiemChannelListActionsEnums.SIEM_CHANNEL_DELETE_CONFIRMATION_MODAL_OPEN), operators_1.map((action) => action.payload), operators_1.switchMap(() => {
            this.deleteAlertModalInstance = this.modal$.open(siem_channel_delete_confirmation_modal_component_1.SiemChannelDeleteConfirmationModalComponent, {
                size: 'md',
                hasBackdrop: true
            });
            return rxjs_1.of(new sidebar.SiemChannelListSidebarClose());
        }));
        this.closeSiemChannelDeleteAlert = this.actions$.pipe(effects_1.ofType(actions.SiemChannelListActionsEnums.SIEM_CHANNEL_DELETE_CONFIRMATION_MODAL_CLOSE), operators_1.filter(() => !!this.deleteAlertModalInstance), operators_1.tap(() => {
            this.deleteAlertModalInstance.close();
            this.deleteAlertModalInstance = undefined;
        }));
    }
    getErrorNotificationAction(error) {
        return new notification_actions_1.NotificationShowFailAction(error.fail
            ? error.fail[0].errors[0].message
            : this.translate$.instant('$I18N_API_APPLICATIONS.NOTIFICATIONS.GENERIC.SERVER_ERROR'));
    }
}
__decorate([
    effects_1.Effect()
], SiemChannelListEffects.prototype, "deleteChannel$", void 0);
__decorate([
    effects_1.Effect()
], SiemChannelListEffects.prototype, "openSiemChannelDeleteAlert", void 0);
__decorate([
    effects_1.Effect()
], SiemChannelListEffects.prototype, "closeSiemChannelDeleteAlert", void 0);
exports.SiemChannelListEffects = SiemChannelListEffects;
