"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgUrlClassificationReportComponent = void 0;
const max_length_validator_1 = require("app-new/ttp/chameleon/monitored-external-domain/validators/max-length/max-length.validator");
class SwgUrlClassificationReportComponent {
    constructor(fb, overlayRef, urlCatTuple) {
        this.fb = fb;
        this.overlayRef = overlayRef;
        this.urlCatTuple = urlCatTuple;
    }
    ngOnInit() {
        this.feedBackForm = this.fb.group({
            feedback: ['', [max_length_validator_1.MaxLengthValidator(255)]]
        });
    }
    onCancel() {
        this.overlayRef.close();
    }
    submit() {
        this.overlayRef.close({
            comments: this.feedBackForm.get('feedback').value,
            domainUrl: this.urlCatTuple.domainUrl
        });
    }
}
exports.SwgUrlClassificationReportComponent = SwgUrlClassificationReportComponent;
