"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ7 = exports.ɵ6 = exports.ɵ5 = exports.ɵ4 = exports.ɵ3 = exports.ɵ2 = exports.ɵ1 = exports.ɵ0 = exports.getAppliesToStepData = exports.getDetailsStepData = exports.getApiIsProcessing = exports.getFromToWhereAttributes = exports.getCyberGraphWizardFlowType = exports.getCreateCyberGraphPolicy = exports.getCyberGraphPolicyFeatureState = exports.reducers = exports.getSelectedRowFullData = exports.getCyberGraphPolicySelectedRowFullData = exports.getPolicyData = exports.selectedRowFullDataReducer = void 0;
const store_1 = require("@ngrx/store");
const policiesWizard = require("./cybergraph-create-policies.reducer");
const listReducer = require("./cybergraph-policies.reducer");
exports.selectedRowFullDataReducer = {
    cyberGraphPolicySelectedRowFullData: listReducer.reducer
};
exports.getPolicyData = store_1.createFeatureSelector('cyberGraphPolicySelectedRowFullData');
const ɵ0 = state => state.cyberGraphPolicySelectedRowFullData;
exports.ɵ0 = ɵ0;
exports.getCyberGraphPolicySelectedRowFullData = store_1.createSelector(exports.getPolicyData, ɵ0);
const ɵ1 = state => state.selectedRowFullData;
exports.ɵ1 = ɵ1;
exports.getSelectedRowFullData = store_1.createSelector(exports.getCyberGraphPolicySelectedRowFullData, ɵ1);
exports.reducers = {
    cyberGraphCreatePolicyWizard: policiesWizard.reducer
};
exports.getCyberGraphPolicyFeatureState = store_1.createFeatureSelector('cyberGraphCreatePolicyWizard');
const ɵ2 = state => state.cyberGraphCreatePolicyWizard;
exports.ɵ2 = ɵ2;
exports.getCreateCyberGraphPolicy = store_1.createSelector(exports.getCyberGraphPolicyFeatureState, ɵ2);
const ɵ3 = state => state.cyberGraphCreatePolicyWizard.wizardFlowType;
exports.ɵ3 = ɵ3;
exports.getCyberGraphWizardFlowType = store_1.createSelector(exports.getCyberGraphPolicyFeatureState, ɵ3);
const ɵ4 = state => state.appliesToStep.stepConfiguration;
exports.ɵ4 = ɵ4;
exports.getFromToWhereAttributes = store_1.createSelector(exports.getCreateCyberGraphPolicy, ɵ4);
const ɵ5 = state => state.apiIsProcessing;
exports.ɵ5 = ɵ5;
exports.getApiIsProcessing = store_1.createSelector(exports.getCreateCyberGraphPolicy, ɵ5);
const ɵ6 = state => state.detailsStep.data;
exports.ɵ6 = ɵ6;
exports.getDetailsStepData = store_1.createSelector(exports.getCreateCyberGraphPolicy, ɵ6);
const ɵ7 = state => state.appliesToStep.data;
exports.ɵ7 = ɵ7;
exports.getAppliesToStepData = store_1.createSelector(exports.getCreateCyberGraphPolicy, ɵ7);
