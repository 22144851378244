"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgThreatEventPanelHeaderComponent = void 0;
const core_1 = require("@angular/core");
class SwgThreatEventPanelHeaderComponent {
    constructor() {
        this.selectRow = new core_1.EventEmitter();
        this.clickManualQuarantine = new core_1.EventEmitter();
        this.clickRelease = new core_1.EventEmitter();
        this.clickDownload = new core_1.EventEmitter();
        this.clickReportFalsePositive = new core_1.EventEmitter();
    }
}
exports.SwgThreatEventPanelHeaderComponent = SwgThreatEventPanelHeaderComponent;
