"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BiPolicyListImplService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const table_store_1 = require("@mimecast-ui/table-store");
const models_1 = require("../models/models");
class BiPolicyListImplService {
    constructor(http) {
        this.http = http;
    }
    getData(payload, pagination = table_store_1.defaultPaginationOption) {
        return this.http
            .post('/api/policy/bias/get-policies-with-targets', Object.assign(Object.assign({}, payload), { meta: { pagination } }))
            .pipe(operators_1.map((response) => {
            const RETURN_DATA = {
                hasErrors: response.hasErrors,
                data: this.mapApiResponse(response.first.definitions),
                failures: response.fail,
                meta: response.meta
            };
            return RETURN_DATA;
        }), operators_1.catchError((response) => {
            const RETURN_DATA = {
                hasErrors: response.hasErrors,
                data: response.all,
                failures: response.fail,
                meta: response.meta
            };
            return rxjs_1.of(RETURN_DATA);
        }));
    }
    filter(filterBy, pagination) {
        return this.getData(filterBy, pagination);
    }
    openItem() {
        return null;
    }
    mapApiResponse(data) {
        const response = data.map((policyPayload) => {
            // @ts-ignore
            const swgPolicy = {
                id: policyPayload.id,
                policyDesc: {
                    id: policyPayload.id,
                    description: policyPayload.description,
                    policyType: policyPayload.policyType
                },
                defaultPolicy: policyPayload.defaultPolicy
            };
            swgPolicy.affectedUsers = [{}];
            if (policyPayload.policies.length > 1) {
                swgPolicy.affectedUsers[0] = {
                    type: models_1.BiAffectedUsersEnum.MULTI
                };
                swgPolicy.lastModified = policyPayload.policies[0].policy.lastUpdated;
                let locationCount = 0;
                let groupCount = 0;
                let userCount = 0;
                for (const policy of policyPayload.policies) {
                    if (policy.policy.conditions.sourceIPs && policy.locations && policy.locations[0]) {
                        locationCount++;
                    }
                    else if (policy.policy.from.type === 'individual_email_address') {
                        userCount++;
                    }
                    else if (policy.policy.from.type === 'profile_group') {
                        groupCount++;
                    }
                }
                const messageArray = [];
                if (locationCount > 0) {
                    messageArray.push('Locations (' + locationCount + ')');
                }
                if (groupCount > 0) {
                    messageArray.push('Groups (' + groupCount + ')');
                }
                if (userCount > 0) {
                    messageArray.push('Users (' + userCount + ')');
                }
                swgPolicy.affectedUsers[0].description = messageArray.join(', ');
            }
            else if (policyPayload.policies.length === 1) {
                const firstPolicy = policyPayload.policies[0];
                swgPolicy.lastModified = firstPolicy.policy.lastUpdated;
                if (firstPolicy.policy.conditions.sourceIPs &&
                    firstPolicy.locations &&
                    firstPolicy.locations[0]) {
                    swgPolicy.affectedUsers[0] = {
                        type: models_1.BiAffectedUsersEnum.LOCATION,
                        description: firstPolicy.locations[0].location,
                        ipRange: firstPolicy.locations[0].ip
                    };
                }
                else if (firstPolicy.policy.from.type === 'individual_email_address') {
                    swgPolicy.affectedUsers[0] = {
                        type: models_1.BiAffectedUsersEnum.USER,
                        emailAddress: firstPolicy.policy.from.emailAddress
                    };
                }
                else if (firstPolicy.policy.from.type === 'profile_group') {
                    swgPolicy.affectedUsers[0] = {
                        type: models_1.BiAffectedUsersEnum.GROUP,
                        description: firstPolicy.policy.from.group.description
                    };
                }
                else if (firstPolicy.policy.from.type === 'everyone') {
                    swgPolicy.affectedUsers[0] = {
                        type: models_1.BiAffectedUsersEnum.EVERYONE
                    };
                    swgPolicy.everyOne = true;
                }
            }
            return swgPolicy;
        });
        return response.sort((pol1) => {
            return pol1.defaultPolicy ? -1 : 1;
        });
    }
}
exports.BiPolicyListImplService = BiPolicyListImplService;
