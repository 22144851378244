"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckUrlsComponent = void 0;
const actions = require("../../actions/check-urls.actions");
const reducers = require("../../reducers");
const domain_validator_1 = require("app-new/components/validators/domain/domain.validator");
class CheckUrlsComponent {
    constructor(fb, store) {
        this.fb = fb;
        this.store = store;
        this.relaxed$ = this.store.select(reducers.getCheckUrlsScanRelaxedResults);
        this.moderate$ = this.store.select(reducers.getCheckUrlsScanModerateResults);
        this.aggressive$ = this.store.select(reducers.getCheckUrlsScanAggressiveResults);
        this.isLoading$ = this.store.select(reducers.getCheckUrlsLoadingState);
        this.hasApiError$ = this.store.select(reducers.getCheckUrlsErrorState);
        this.form = this.fb.group({
            url: ['', this.looseUrlValidation]
        });
    }
    ngOnInit() {
        this.apiErrorSubscription = this.hasApiError$.subscribe(e => {
            this.form.get('url').setErrors({ required: e });
            this.store.dispatch(new actions.CheckUrlClearAction());
        });
    }
    ngOnDestroy() {
        this.apiErrorSubscription.unsubscribe();
        this.store.dispatch(new actions.CheckUrlClearAction());
    }
    onSave() {
        this.store.dispatch(new actions.CheckUrlAttemptAction(this.form.get('url').value));
    }
    onClear() {
        this.form.reset();
        this.store.dispatch(new actions.CheckUrlClearAction());
    }
    preventNewRow(e) {
        e.preventDefault();
    }
    looseUrlValidation(control) {
        const url = control.value || '';
        if (!url || !domain_validator_1.hasValidUrlLength(url) || domain_validator_1.hasSpaceCharacter(url)) {
            return { required: true };
        }
        return null;
    }
}
exports.CheckUrlsComponent = CheckUrlsComponent;
