"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActionModalComponent = void 0;
const action_modal_interfaces_1 = require("./action-modal.interfaces");
class ActionModalComponent {
    constructor(overlayRef, config) {
        this.overlayRef = overlayRef;
        this.config = config;
    }
    ngOnInit() {
        this.config.buttons = this.config.buttons || action_modal_interfaces_1.OK_BUTTON_SET;
    }
    click(button) {
        this.overlayRef.close(button.id);
    }
}
exports.ActionModalComponent = ActionModalComponent;
