"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SFCThreatDetectionsComponent = void 0;
const model_1 = require("../../../services/connectors/model");
class SFCThreatDetectionsComponent {
    constructor() {
        this.msTeams = model_1.ProviderType.MS_TEAMS;
        this.box = model_1.ProviderType.BOX;
        this.oneDrive = model_1.ProviderType.ONE_DRIVE;
        this.CHART_NAME = 'threat_detections';
        this.supportedProviders = [this.msTeams];
    }
    isSupported(provider) {
        return this.supportedProviders.indexOf(provider) >= 0;
    }
    showAppCounts() {
        let showAppCounts = false;
        this.supportedProviders.forEach(provider => {
            if (this.data[provider]) {
                showAppCounts = true;
            }
        });
        return showAppCounts;
    }
    getAllScannedFiles() {
        if (this.data !== null) {
            return (this.getTotalScannedFiles(this.data[this.msTeams]) +
                this.getTotalScannedFiles(this.data[this.box]) +
                this.getTotalScannedFiles(this.data[this.oneDrive]));
        }
        return 0;
    }
    getAllDetectedFiles() {
        if (this.data !== null) {
            return (this.getDetectedScannedFiles(this.data[this.msTeams]) +
                this.getDetectedScannedFiles(this.data[this.box]) +
                this.getDetectedScannedFiles(this.data[this.oneDrive]));
        }
        return 0;
    }
    getTotalScannedFiles(app) {
        if (app) {
            return app.totalScannedFiles;
        }
        return 0;
    }
    getDetectedScannedFiles(app) {
        if (app) {
            return app.detectedScannedFiles;
        }
        return 0;
    }
}
exports.SFCThreatDetectionsComponent = SFCThreatDetectionsComponent;
