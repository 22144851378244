<mc-table
  *ngIf="!(isLoadResponseEmpty$ | async)"
  [data]="tableData$ | async"
  [columns]="columns"
  (rowClick)="viewRule($event)"
  [isLoading]="isLoading$ | async"
  translatePrefix="$I18N_ARCHIVE_SUPERVISION_LIST_RULES_TAB"
  [showHighlightedRow]="true"
  [highlightedRow]="highlightedRow$ | async"
>
  <button
    [disabled]="!hasEditPermission"
    class="mc-table-actions btn btn-primary mc-create-button"
    (click)="openCreate()"
  >
    {{ '$I18N_ARCHIVE_SUPERVISION_LIST_RULES_TAB.BUTTONS.CREATE_NEW' | translate }}
  </button>
  <mc-column-actions
    key="columnActions"
    mcShowColumnConfig
    [columnsToStartHidden]="columnsStartHidden"
  >
    <mc-row-actions *mcRowActions="let row">
      <li mcRowAction="$I18N_SUPERVISION_LISTS.COMMON_LABELS.VIEW" (click)="viewRule(row)"></li>
      <li
        *ngIf="hasEditPermission"
        mcRowAction="$I18N_COMMON_BTN_EDIT"
        (click)="editRule(row)"
      ></li>
      <li
        *ngIf="hasEditPermission && !isActive(row.state)"
        mcRowAction="$I18N_SUPERVISION_LISTS.COMMON_LABELS.ENABLE"
        (click)="enableRule(row.id)"
      ></li>
      <li
        *ngIf="hasEditPermission && isActive(row.state)"
        mcRowAction="$I18N_SUPERVISION_LISTS.COMMON_LABELS.DISABLE"
        (click)="disableRule(row.id)"
      ></li>
      <li
        *ngIf="hasEditPermission && hasSupervisionDeleteCapability"
        mcRowAction="$I18N_SUPERVISION_LISTS.COMMON_LABELS.DELETE"
        (click)="deleteRule(row)"
      ></li>
    </mc-row-actions>
  </mc-column-actions>
  <mc-column-date key="updated"></mc-column-date>
  <mc-column-date key="created"></mc-column-date>
  <mc-column-date key="lastExecuted"></mc-column-date>
  <mc-column key="status">
    <mc-body-cell *mcBodyCellDef="let row">{{ getStatus(row.status) | translate }}</mc-body-cell>
  </mc-column>
  <mc-column key="searchGroup">
    <mc-body-cell *mcBodyCellDef="let row">{{ row.searchGroup.name }}</mc-body-cell>
  </mc-column>
  <mc-column key="reviewQueue">
    <mc-body-cell *mcBodyCellDef="let row">{{ row.reviewQueue.name }}</mc-body-cell>
  </mc-column>
  <mc-column key="lexicon">
    <mc-body-cell *mcBodyCellDef="let row">{{
      row.lexicon?.name || getNoLexiconText()
    }}</mc-body-cell>
  </mc-column>
  <mc-column key="scheduleType">
    <mc-body-cell *mcBodyCellDef="let row" class="capify">{{
      row.scheduleType | translate
    }}</mc-body-cell>
  </mc-column>
  <mc-column key="state">
    <mc-body-cell *mcBodyCellDef="let row">
      <span [className]="isActive(row.state) ? 'active-state' : 'inactive-state'">{{
        getState(row.state) | translate
      }}</span>
    </mc-body-cell>
  </mc-column>
  <mc-filters [metadata]="metaData$ | async" (paginatorChange)="onPaginationChange($event)">
    <mc-filter-bundle-filter-by-and-search-by
      (filtersChange)="onFilterChange($event)"
      placeholder="$I18N_ARCHIVE_SUPERVISION_LIST_RULES_TAB.FILTERS.SEARCH_FIELD.PLACEHOLDER"
      [sections]="sections"
      [searchByFields]="searchByFields"
    >
    </mc-filter-bundle-filter-by-and-search-by>
  </mc-filters>
  <mc-empty-results
    iconClass="far fa-hdd"
    keyTitle="$I18N_ARCHIVE_SUPERVISION_LIST_RULES_TAB.EMPTY_SEARCH.TITLE"
  ></mc-empty-results>
</mc-table>

<mc-empty-results
  *ngIf="!(isLoading$ | async) && (isLoadResponseEmpty$ | async)"
  keyTitle="$I18N_ARCHIVE_SUPERVISION_LIST_RULES_TAB.EMPTY_LIST.TITLE"
  iconClass="far fa-hdd"
>
  <mc-empty-body>
    <div class="panel-half-padding-bottom" *ngIf="preRuleCreationCriteriaMet">
      {{ '$I18N_ARCHIVE_SUPERVISION_LIST_RULES_TAB.EMPTY_LIST.DESCRIPTION' | translate }}
    </div>
    <div class="panel-half-padding-bottom" *ngIf="!preRuleCreationCriteriaMet">
      {{
        '$I18N_ARCHIVE_SUPERVISION_LIST_RULES_TAB.EMPTY_LIST.PRE_RULE_CREATION_CRITERIA' | translate
      }}
    </div>
    <button
      *ngIf="preRuleCreationCriteriaMet"
      class="mc-btn-item btn btn-primary"
      (click)="openCreate()"
    >
      {{ '$I18N_ARCHIVE_SUPERVISION_LIST_RULES_TAB.BUTTONS.CREATE_NEW' | translate }}
    </button>
  </mc-empty-body>
</mc-empty-results>
