"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStepData = exports.isStepValid = exports.getCurrentStepState = exports.getStepById = exports.getCurrentStep = exports.getWizardById = exports.reducer = exports.initialState = void 0;
const wizard_actions_1 = require("../actions/wizard.actions");
exports.initialState = {};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case wizard_actions_1.WizardActions.UPDATE_STEP: // partially covered 2/3
            return updateStep(state, action);
        case wizard_actions_1.WizardActions.SET_CURRENT_STEP:
            return Object.assign(Object.assign({}, state), { [action.wizardId]: Object.assign(Object.assign({}, state[action.wizardId]), { currentStep: action.stepId }) });
        default:
            return Object.assign({}, state);
    }
}
exports.reducer = reducer;
function updateStep(state, action) {
    const wizardState = state[action.wizardId] || {};
    const wizardSsteps = wizardState.steps || {};
    const newState = Object.assign(Object.assign({}, state), { [action.wizardId]: Object.assign(Object.assign({}, wizardState), { currentStep: action.stepId, steps: Object.assign(Object.assign({}, wizardSsteps), { [action.stepId]: Object.assign(Object.assign({}, wizardSsteps[action.stepId]), action.stepState) }) }) });
    return newState;
}
const getWizardById = (id) => (state) => state[id];
exports.getWizardById = getWizardById;
const getCurrentStep = (state) => state && state.currentStep;
exports.getCurrentStep = getCurrentStep;
const getStepById = (stepId) => (state) => state && state.steps && state.steps[stepId];
exports.getStepById = getStepById;
const getCurrentStepState = (state) => state && state.steps && state.steps[state.currentStep];
exports.getCurrentStepState = getCurrentStepState;
const isStepValid = (state) => state && state.valid;
exports.isStepValid = isStepValid;
const getStepData = (state) => state && state.data;
exports.getStepData = getStepData;
