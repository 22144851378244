"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.CustomDomainBlockFactory = void 0;
const custom_domain_block_builder_1 = require("app-new/custom-domain-block/models/custom-domain-block.builder");
const ɵ0 = (cdm) => new custom_domain_block_builder_1.CustomDomainBlockBuilder()
    .withDefinitionId(cdm['definitionId'])
    .withPolicyId(cdm['policyId'])
    .withName(cdm['name'])
    .withDescription(cdm['description'])
    .withAction(cdm['action'])
    .withNotify(cdm['notify'])
    .withConfigurationId(cdm['configurationId'])
    .withLastUpdated(cdm['lastUpdated'])
    .withEnabled(cdm['enabled'])
    .build();
exports.ɵ0 = ɵ0;
exports.CustomDomainBlockFactory = {
    BUILD_CUSTOM_DOMAIN_BLOCK: ɵ0
};
