'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const _ = require("lodash");
/**
 * Created by psambandam on 08/02/16.
 */
document.createElement('mc-notification-feed');
angular.module('notification-feed.directive', [])
    .directive('mcNotificationFeed', ['$filter', function ($filter) {
        return {
            restrict: 'E',
            templateUrl: 'components/notification-feed/notification-feed.tpl.html',
            scope: {
                mcTitle: '@',
                notifications: '&',
                selectionOptionTitle: '@',
                errorMessage: '@'
            },
            link($scope) {
                $scope.messages = [];
                $scope.isLoading = true;
                $scope.hasData = false;
                $scope.hasError = false;
                if (angular.isDefined($scope.errorMessage) && !_.isEmpty($scope.errorMessage)) {
                    $scope.hasError = true;
                }
                $scope.notifications().then(function (messages) {
                    $scope.messages = rearrangeMessage(messages);
                    setupSelection(messages);
                    $scope.isLoading = false;
                    $scope.hasData = _.isEmpty((messages)) ? false : true;
                });
                //re-arrange notification with latest service news followed by latest product news on the top.
                function rearrangeMessage(messages) {
                    if (!_.isEmpty((messages))) {
                        const snIndex = _.findIndex(messages, { noticeType: 'SERVICE_NOTIFICATION' });
                        if (snIndex != -1) {
                            const snMessage = messages.splice(snIndex, 1);
                            messages.splice(0, 0, snMessage[0]);
                        }
                        const pnIndex = _.findIndex(messages, { noticeType: 'PRODUCT_NEWS' });
                        if (pnIndex != -1) {
                            const pnMessage = messages.splice(pnIndex, 1);
                            messages.splice(1, 0, pnMessage[0]);
                        }
                    }
                    return messages;
                }
                function setupSelection(messages) {
                    const selection = [];
                    selection.push({
                        key: $filter('translate')('$I18N_NOTIFICATION_FEED_SERVICE_TYPE_LABEL'),
                        label: $filter('translate')('$I18N_NOTIFICATION_FEED_TITLE_SERVICE'),
                        fn: angular.noop
                    });
                    const index = _.findIndex(messages, { noticeType: 'PRODUCT_NEWS' });
                    if (index !== -1) {
                        selection.unshift({
                            key: $filter('translate')('$I18N_NOTIFICATION_FEED_PRODUCT_TYPE_LABEL'),
                            label: $filter('translate')('$I18N_NOTIFICATION_FEED_TITLE_PRODUCT'),
                            fn: angular.noop
                        });
                        selection.unshift({
                            key: '',
                            label: $filter('translate')('$I18N_NOTIFICATION_FEED_SELECTION_TITLE'),
                            fn: angular.noop
                        });
                    }
                    $scope.selectionSelected = selection[0];
                    $scope.selectionOptions = selection;
                }
            }
        };
    }]);
