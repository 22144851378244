"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SharedModule = void 0;
const anchor_href_directive_1 = require("./components/anchor-href/anchor-href.directive");
const reverse_pipe_1 = require("./components/pipes/reverse/reverse.pipe");
const trim_pipe_1 = require("./components/pipes/trim/trim.pipe");
const SHARED = [anchor_href_directive_1.AnchorHrefDirective, reverse_pipe_1.ReversePipe, trim_pipe_1.TrimPipe];
class SharedModule {
}
exports.SharedModule = SharedModule;
