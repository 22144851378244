"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueueConfigsService = void 0;
const queue_configs_factory_1 = require("app-new/archive/supervision/factories/queue-configs.factory");
const queue_type_enum_1 = require("app-new/archive/supervision/types/queue-type.enum");
const status_enum_1 = require("app-new/archive/supervision/types/status.enum");
const table_store_1 = require("@mimecast-ui/table-store");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const queue_config_1 = require("../../../../supervision/model/queue/queue-config");
class QueueConfigsService {
    constructor(http) {
        this.http = http;
    }
    getData(payload, pagination = table_store_1.defaultPaginationOption) {
        return this.http
            .post('/api/supervision/config/find-queues', Object.assign(Object.assign({}, payload), { meta: { pagination } }))
            .pipe(operators_1.map((response) => {
            const RETURN_DATA = {
                hasErrors: response.hasErrors,
                data: response.first.queues.map((queue) => queue_configs_factory_1.QueueConfigFactory.BUILD_QUEUE_CONFIG(queue)),
                failures: response.fail,
                meta: response.meta
            };
            return RETURN_DATA;
        }), operators_1.catchError((response) => {
            const RETURN_DATA = {
                hasErrors: response.hasErrors,
                data: response.all,
                failures: response.fail,
                meta: response.meta
            };
            return rxjs_1.of(RETURN_DATA);
        }));
    }
    getQueueConfig(queueConfigId) {
        return this.http.post('/api/supervision/config/get-queue', { id: queueConfigId }).pipe(operators_1.map((response) => response), operators_1.catchError((response) => rxjs_1.of(response)));
    }
    filter(filter, pagination) {
        return this.getData(filter, pagination);
    }
    openItem() {
        return rxjs_1.of(undefined);
    }
    deleteQueue(data) {
        return this.http.post(`${queue_config_1.baseUrl}delete-queue`, data).pipe(operators_1.map((response) => {
            return response;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
}
exports.QueueConfigsService = QueueConfigsService;
QueueConfigsService.SUPERVISION_QUEUE_CONFIG_LIST = 'SupervisionQueueConfigList';
QueueConfigsService.STATUS_MAP = {
    [status_enum_1.QueueStatus.ACTIVE]: '$I18N_ARCHIVE_SUPERVISION_LIST_QUEUES_TAB.FILTERS.COLUMN_VALUE_FILTER_FIELD.STATUS_FILTERS.ACTIVE',
    [status_enum_1.QueueStatus.ABANDONED]: '$I18N_ARCHIVE_SUPERVISION_LIST_QUEUES_TAB.FILTERS.COLUMN_VALUE_FILTER_FIELD.STATUS_FILTERS.ABANDONED',
    [status_enum_1.QueueStatus.CLOSED]: '$I18N_ARCHIVE_SUPERVISION_LIST_QUEUES_TAB.FILTERS.COLUMN_VALUE_FILTER_FIELD.STATUS_FILTERS.CLOSED',
    [status_enum_1.QueueStatus.CREATED]: '$I18N_ARCHIVE_SUPERVISION_LIST_QUEUES_TAB.FILTERS.COLUMN_VALUE_FILTER_FIELD.STATUS_FILTERS.CREATED',
    [status_enum_1.QueueStatus.ERROR]: '$I18N_ARCHIVE_SUPERVISION_LIST_QUEUES_TAB.FILTERS.COLUMN_VALUE_FILTER_FIELD.STATUS_FILTERS.ERROR',
    [status_enum_1.QueueStatus.FULL]: '$I18N_ARCHIVE_SUPERVISION_LIST_QUEUES_TAB.FILTERS.COLUMN_VALUE_FILTER_FIELD.STATUS_FILTERS.FULL'
};
QueueConfigsService.QUEUE_TYPE_MAP = {
    [queue_type_enum_1.QueueType.ESCALATION]: '$I18N_ARCHIVE_SUPERVISION_LIST_QUEUES_TAB.FILTERS.COLUMN_VALUE_FILTER_FIELD.TYPE_FILTERS.ESCALATION',
    [queue_type_enum_1.QueueType.REVIEW]: '$I18N_ARCHIVE_SUPERVISION_LIST_QUEUES_TAB.FILTERS.COLUMN_VALUE_FILTER_FIELD.TYPE_FILTERS.REVIEW'
};
