"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MseMessageModalComponent = void 0;
class MseMessageModalComponent {
    constructor(overlayRef, translateService, data) {
        this.overlayRef = overlayRef;
        this.translateService = translateService;
        this.data = data;
        this.upgradeKbLink = 'https://community.mimecast.com/s/article/Installing-Upgrading-the-Mimecast-Synchronization-Engine-206844274';
    }
    close() {
        this.overlayRef.close();
    }
    get headerTitle() {
        return this.translateService.instant(`$I18N_DIRECTORY_SYNC_MSE_MESSAGE_MODAL.${this.data.message}_TITLE`);
    }
    get message() {
        return this.translateService.instant(`$I18N_DIRECTORY_SYNC_MSE_MESSAGE_MODAL.${this.data.message}`);
    }
    isUpgrade() {
        return this.data.message === 'MSE_SYNC_SERVER_UPGRADE';
    }
}
exports.MseMessageModalComponent = MseMessageModalComponent;
