"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApPolicyDetailsComponent = void 0;
class ApPolicyDetailsComponent {
    constructor(capabilitiesService, translateService) {
        this.capabilitiesService = capabilitiesService;
        this.translateService = translateService;
        this.policy = null;
    }
    // eslint-disable-next-line @angular-eslint/no-input-rename
    set valuePolicy(value) {
        this.policy = value;
        this.policyDetails = this.policy.policies[0].policy;
    }
    ngOnInit() {
        this.hasPolicyIEPService$ = this.capabilitiesService.hasCapability('Policy.InsiderThreatManagement');
        this.hasPolicyRemoveActiveContent$ = this.capabilitiesService.hasCapability('Temporary.RemoveActiveContentEnabled');
        this.translateService
            .get([
            '$I18N_CONFLICT_SIDEPANEL_POLICY_HEADER_TEXT',
            '$I18N_CONFLICT_SIDEPANEL_TEXT',
            '$I18N_CONFLICT_SIDEPANEL_COMMON_TEXT',
            '$I18N_CONFLICT_REASON_ONE_HEADER',
            '$I18N_CONFLICT_REASON_ONE_TEXT',
            '$I18N_CONFLICT_REASON_TWO_HEADER',
            '$I18N_CONFLICT_REASON_TWO_TEXT',
            '$I18N_CONFLICT_REASON_NOTE_HEADER',
            '$I18N_CONFLICT_REASON_NOTE_TEXT',
            '$I18N_CONFLICT_LINK_SIDEPANEL_TEXT'
        ])
            .subscribe(list => {
            this.listOfText = list;
        });
        this.policyConflictInlineNotification = {
            status: 'warning',
            boxed: true,
            msg: `<div class="mc-text-thick">${this.listOfText.$I18N_CONFLICT_SIDEPANEL_POLICY_HEADER_TEXT}</div>
<div class="mc-margin-bottom">${this.listOfText.$I18N_CONFLICT_SIDEPANEL_TEXT}</div>
<div class="mc-margin-bottom">${this.listOfText.$I18N_CONFLICT_SIDEPANEL_COMMON_TEXT}</div>
<div class="mc-text-thick">${this.listOfText.$I18N_CONFLICT_REASON_ONE_HEADER}</div>
<div class="mc-margin-bottom"> ${this.listOfText.$I18N_CONFLICT_REASON_ONE_TEXT}</div>
<div class="mc-text-thick">${this.listOfText.$I18N_CONFLICT_REASON_TWO_HEADER}</div>
<div class="mc-margin-bottom">${this.listOfText.$I18N_CONFLICT_REASON_TWO_TEXT}</div>
<div class="mc-text-thick">${this.listOfText.$I18N_CONFLICT_REASON_NOTE_HEADER} </div>
<div class="mc-margin-bottom">${this.listOfText.$I18N_CONFLICT_REASON_NOTE_TEXT}</div>
<a href="">${this.listOfText.$I18N_CONFLICT_LINK_SIDEPANEL_TEXT}</a>`
        };
    }
}
exports.ApPolicyDetailsComponent = ApPolicyDetailsComponent;
