"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateOktaEvidenceBasedControlIntegrationFailureAction = exports.UpdateOktaEvidenceBasedControlIntegrationSuccessAction = exports.UpdateOktaEvidenceBasedControlIntegrationAction = exports.UpdateEnabledStatusOktaEvidenceBasedControlIntegrationAction = exports.GetOktaEvidenceBasedControlIntegrationCompleteAction = exports.DeleteGroupOktaEvidenceBasedControlIntegrationNotificationsAction = exports.AddGroupOktaEvidenceBasedControlIntegrationNotificationsAction = exports.DeleteEmailOktaEvidenceBasedControlIntegrationNotificationsAction = exports.VerifyAndAddEmailOktaEvidenceBasedControlIntegrationNotificationsFailAction = exports.VerifyAndAddEmailOktaEvidenceBasedControlIntegrationNotificationsSuccessAction = exports.VerifyAndAddEmailOktaEvidenceBasedControlIntegrationNotificationsAction = exports.UpdateEventGroupsInOktaEvidenceBasedControlIntegration = exports.SearchOktaUserGroupsInOktaEvidenceBasedControlIntegrationFailureAction = exports.SearchOktaUserGroupsInOktaEvidenceBasedControlIntegrationSuccessAction = exports.SearchOktaUserGroupsInOktaEvidenceBasedControlIntegrationAction = exports.AddUserGroupsOktaEvidenceBasedControlIntegration = exports.CancelOktaEvidenceBasedControlIntegrationWizard = exports.CreateOktaEvidenceBasedControlIntegrationFailureAction = exports.CreateOktaEvidenceBasedControlIntegrationSuccessAction = exports.CreateOktaEvidenceBasedControlIntegrationAction = exports.VerifyEvidenceBasedControlIntegrationApiKeyFailureAction = exports.VerifyEvidenceBasedControlIntegrationApiKeySuccessAction = exports.VerifyEvidenceBasedControlIntegrationApiKeyAction = exports.IntegrationOktaEvidenceBasedControlActionTypes = void 0;
var IntegrationOktaEvidenceBasedControlActionTypes;
(function (IntegrationOktaEvidenceBasedControlActionTypes) {
    IntegrationOktaEvidenceBasedControlActionTypes["VerifyEvidenceBasedControlIntegrationApiKey"] = "[ApiApplication/Integration/Okta Evidence Based Control/Verify API Key] In Progress";
    IntegrationOktaEvidenceBasedControlActionTypes["VerifyEvidenceBasedControlIntegrationApiKeySuccess"] = "[ApiApplication/Integration/Okta Evidence Based Control/Verify API Key] Success";
    IntegrationOktaEvidenceBasedControlActionTypes["VerifyEvidenceBasedControlIntegrationApiKeyFailure"] = "[ApiApplication/Integration/Okta Evidence Based Control/Verify API Key] Error";
    IntegrationOktaEvidenceBasedControlActionTypes["CreateOktaEvidenceBasedControlIntegration"] = "[ApiApplication/Integration/Okta Evidence Based Control/Create] Create Okta Evidence Based Control Integration";
    IntegrationOktaEvidenceBasedControlActionTypes["CreateOktaEvidenceBasedControlIntegrationSuccess"] = "[ApiApplication/Integration/Okta Evidence Based Control/Create] Create Okta Evidence Based Control Integration Success";
    IntegrationOktaEvidenceBasedControlActionTypes["CreateOktaEvidenceBasedControlIntegrationFailure"] = "[ApiApplication/Integration/Okta Evidence Based Control/Create] Create Okta Evidence Based Control Integration Failed";
    IntegrationOktaEvidenceBasedControlActionTypes["CancelOktaEvidenceBasedControlIntegrationWizardEnum"] = "[ApiApplication/Integration/Okta Evidence Based Control/Create] Cancel Okta Evidence Based Control Integration Wizard";
    IntegrationOktaEvidenceBasedControlActionTypes["AddUserGroupsOktaEvidenceBasedControlIntegrationEnum"] = "[ApiApplication/Integration/Okta Evidence Based Control/Create/Edit]\n  Add/Update user groups for Okta Evidence Based Control Integration";
    IntegrationOktaEvidenceBasedControlActionTypes["SearchForOktaUserGroupsInOktaEvidenceBasedControlIntegration"] = "[ApiApplication/Integration/Okta Evidence Based Control/Create/Edit]\n  Search Okta groups In Okta Evidence Based Control Integration";
    IntegrationOktaEvidenceBasedControlActionTypes["SearchForOktaUserGroupsInOktaEvidenceBasedControlIntegrationSuccess"] = "[ApiApplication/Integration/Okta Evidence Based Control/Create/Edit]\n  Search Okta groups In Okta Evidence Based Control Integration Success";
    IntegrationOktaEvidenceBasedControlActionTypes["SearchForOktaUserGroupsInOktaEvidenceBasedControlIntegrationFailure"] = "[ApiApplication/Integration/Okta Evidence Based Control/Create/Edit]\n  Search Okta groups In Okta Evidence Based Control Integration Failure";
    IntegrationOktaEvidenceBasedControlActionTypes["UpdateEventGroupsInOktaEvidenceBasedControlIntegrationEnum"] = "[ApiApplication/Integration/Okta Evidence Based Control/Create/Edit]\n  Update Okta Event Groups In Okta Evidence Based Control Integration";
    IntegrationOktaEvidenceBasedControlActionTypes["VerifyAndAddEmailOktaEvidenceBasedControlIntegrationNotifications"] = "[ApiApplication/Integration/Okta Evidence Based Control/Email/Add] Add Email Okta Evidence Based Control Integration";
    IntegrationOktaEvidenceBasedControlActionTypes["VerifyAndAddEmailOktaEvidenceBasedControlIntegrationNotificationsSuccess"] = "[ApiApplication/Integration/Okta Evidence Based Control/Email/Add/Success]   Add Email Okta Evidence Based Control Integration Success";
    IntegrationOktaEvidenceBasedControlActionTypes["VerifyAndAddEmailOktaEvidenceBasedControlIntegrationNotificationsFail"] = "[ApiApplication/Integration/Okta Evidence Based Control/Email/Add/Fail]   Add Email Okta Evidence Based Control Integration Fail";
    IntegrationOktaEvidenceBasedControlActionTypes["DeleteEmailOktaEvidenceBasedControlIntegrationNotifications"] = "[ApiApplication/Integration/Okta Evidence Based Control/Email/Delete] Delete Email Okta Evidence Based Control Integration";
    IntegrationOktaEvidenceBasedControlActionTypes["AddGroupOktaEvidenceBasedControlIntegrationNotifications"] = "[ApiApplication/Integration/Okta Evidence Based Control/Group/Add] Add Group Okta Evidence Based Control Integration";
    IntegrationOktaEvidenceBasedControlActionTypes["DeleteGroupOktaEvidenceBasedControlIntegrationNotifications"] = "[ApiApplication/Integration/Okta Evidence Based Control/Group/Delete] Delete Group Okta Evidence Based Control Integration";
    IntegrationOktaEvidenceBasedControlActionTypes["GetOktaEvidenceBasedControlIntegrationComplete"] = "[ApiApplication/Integration/Okta Evidence Based Control/Get/Integration] Okta Evidence Based Control Integration Get Integration Completed";
    IntegrationOktaEvidenceBasedControlActionTypes["UpdateEnabledStatusOktaEvidenceBasedControlIntegration"] = "[ApiApplication/Integration/Okta Evidence Based Control/Create/Edit]\n  Update Enabled Status Okta Evidence Based Control Integration";
    IntegrationOktaEvidenceBasedControlActionTypes["UpdateOktaEvidenceBasedControlIntegration"] = "[ApiApplication/Integration/Okta Evidence Based Control/Update] Update Okta Evidence Based Control Integration";
    IntegrationOktaEvidenceBasedControlActionTypes["UpdateOktaEvidenceBasedControlIntegrationSuccess"] = "[ApiApplication/Integration/Okta Evidence Based Control/Update] Update Okta Evidence Based Control Integration Success";
    IntegrationOktaEvidenceBasedControlActionTypes["UpdateOktaEvidenceBasedControlIntegrationFailure"] = "[ApiApplication/Integration/Okta Evidence Based Control/Update] Update Okta Evidence Based Control Integration Failed";
})(IntegrationOktaEvidenceBasedControlActionTypes = exports.IntegrationOktaEvidenceBasedControlActionTypes || (exports.IntegrationOktaEvidenceBasedControlActionTypes = {}));
class VerifyEvidenceBasedControlIntegrationApiKeyAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationOktaEvidenceBasedControlActionTypes.VerifyEvidenceBasedControlIntegrationApiKey;
    }
}
exports.VerifyEvidenceBasedControlIntegrationApiKeyAction = VerifyEvidenceBasedControlIntegrationApiKeyAction;
class VerifyEvidenceBasedControlIntegrationApiKeySuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationOktaEvidenceBasedControlActionTypes.VerifyEvidenceBasedControlIntegrationApiKeySuccess;
    }
}
exports.VerifyEvidenceBasedControlIntegrationApiKeySuccessAction = VerifyEvidenceBasedControlIntegrationApiKeySuccessAction;
class VerifyEvidenceBasedControlIntegrationApiKeyFailureAction {
    constructor() {
        this.type = IntegrationOktaEvidenceBasedControlActionTypes.VerifyEvidenceBasedControlIntegrationApiKeyFailure;
    }
}
exports.VerifyEvidenceBasedControlIntegrationApiKeyFailureAction = VerifyEvidenceBasedControlIntegrationApiKeyFailureAction;
class CreateOktaEvidenceBasedControlIntegrationAction {
    constructor() {
        this.type = IntegrationOktaEvidenceBasedControlActionTypes.CreateOktaEvidenceBasedControlIntegration;
    }
}
exports.CreateOktaEvidenceBasedControlIntegrationAction = CreateOktaEvidenceBasedControlIntegrationAction;
class CreateOktaEvidenceBasedControlIntegrationSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationOktaEvidenceBasedControlActionTypes.CreateOktaEvidenceBasedControlIntegrationSuccess;
    }
}
exports.CreateOktaEvidenceBasedControlIntegrationSuccessAction = CreateOktaEvidenceBasedControlIntegrationSuccessAction;
class CreateOktaEvidenceBasedControlIntegrationFailureAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationOktaEvidenceBasedControlActionTypes.CreateOktaEvidenceBasedControlIntegrationFailure;
    }
}
exports.CreateOktaEvidenceBasedControlIntegrationFailureAction = CreateOktaEvidenceBasedControlIntegrationFailureAction;
class CancelOktaEvidenceBasedControlIntegrationWizard {
    constructor() {
        this.type = IntegrationOktaEvidenceBasedControlActionTypes.CancelOktaEvidenceBasedControlIntegrationWizardEnum;
    }
}
exports.CancelOktaEvidenceBasedControlIntegrationWizard = CancelOktaEvidenceBasedControlIntegrationWizard;
class AddUserGroupsOktaEvidenceBasedControlIntegration {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationOktaEvidenceBasedControlActionTypes.AddUserGroupsOktaEvidenceBasedControlIntegrationEnum;
    }
}
exports.AddUserGroupsOktaEvidenceBasedControlIntegration = AddUserGroupsOktaEvidenceBasedControlIntegration;
class SearchOktaUserGroupsInOktaEvidenceBasedControlIntegrationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationOktaEvidenceBasedControlActionTypes.SearchForOktaUserGroupsInOktaEvidenceBasedControlIntegration;
    }
}
exports.SearchOktaUserGroupsInOktaEvidenceBasedControlIntegrationAction = SearchOktaUserGroupsInOktaEvidenceBasedControlIntegrationAction;
class SearchOktaUserGroupsInOktaEvidenceBasedControlIntegrationSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationOktaEvidenceBasedControlActionTypes.SearchForOktaUserGroupsInOktaEvidenceBasedControlIntegrationSuccess;
    }
}
exports.SearchOktaUserGroupsInOktaEvidenceBasedControlIntegrationSuccessAction = SearchOktaUserGroupsInOktaEvidenceBasedControlIntegrationSuccessAction;
class SearchOktaUserGroupsInOktaEvidenceBasedControlIntegrationFailureAction {
    constructor(fail) {
        this.fail = fail;
        this.type = IntegrationOktaEvidenceBasedControlActionTypes.SearchForOktaUserGroupsInOktaEvidenceBasedControlIntegrationFailure;
    }
}
exports.SearchOktaUserGroupsInOktaEvidenceBasedControlIntegrationFailureAction = SearchOktaUserGroupsInOktaEvidenceBasedControlIntegrationFailureAction;
class UpdateEventGroupsInOktaEvidenceBasedControlIntegration {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationOktaEvidenceBasedControlActionTypes.UpdateEventGroupsInOktaEvidenceBasedControlIntegrationEnum;
    }
}
exports.UpdateEventGroupsInOktaEvidenceBasedControlIntegration = UpdateEventGroupsInOktaEvidenceBasedControlIntegration;
class VerifyAndAddEmailOktaEvidenceBasedControlIntegrationNotificationsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationOktaEvidenceBasedControlActionTypes.VerifyAndAddEmailOktaEvidenceBasedControlIntegrationNotifications;
    }
}
exports.VerifyAndAddEmailOktaEvidenceBasedControlIntegrationNotificationsAction = VerifyAndAddEmailOktaEvidenceBasedControlIntegrationNotificationsAction;
class VerifyAndAddEmailOktaEvidenceBasedControlIntegrationNotificationsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationOktaEvidenceBasedControlActionTypes.VerifyAndAddEmailOktaEvidenceBasedControlIntegrationNotificationsSuccess;
    }
}
exports.VerifyAndAddEmailOktaEvidenceBasedControlIntegrationNotificationsSuccessAction = VerifyAndAddEmailOktaEvidenceBasedControlIntegrationNotificationsSuccessAction;
class VerifyAndAddEmailOktaEvidenceBasedControlIntegrationNotificationsFailAction {
    constructor() {
        this.type = IntegrationOktaEvidenceBasedControlActionTypes.VerifyAndAddEmailOktaEvidenceBasedControlIntegrationNotificationsFail;
    }
}
exports.VerifyAndAddEmailOktaEvidenceBasedControlIntegrationNotificationsFailAction = VerifyAndAddEmailOktaEvidenceBasedControlIntegrationNotificationsFailAction;
class DeleteEmailOktaEvidenceBasedControlIntegrationNotificationsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationOktaEvidenceBasedControlActionTypes.DeleteEmailOktaEvidenceBasedControlIntegrationNotifications;
    }
}
exports.DeleteEmailOktaEvidenceBasedControlIntegrationNotificationsAction = DeleteEmailOktaEvidenceBasedControlIntegrationNotificationsAction;
class AddGroupOktaEvidenceBasedControlIntegrationNotificationsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationOktaEvidenceBasedControlActionTypes.AddGroupOktaEvidenceBasedControlIntegrationNotifications;
    }
}
exports.AddGroupOktaEvidenceBasedControlIntegrationNotificationsAction = AddGroupOktaEvidenceBasedControlIntegrationNotificationsAction;
class DeleteGroupOktaEvidenceBasedControlIntegrationNotificationsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationOktaEvidenceBasedControlActionTypes.DeleteGroupOktaEvidenceBasedControlIntegrationNotifications;
    }
}
exports.DeleteGroupOktaEvidenceBasedControlIntegrationNotificationsAction = DeleteGroupOktaEvidenceBasedControlIntegrationNotificationsAction;
class GetOktaEvidenceBasedControlIntegrationCompleteAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationOktaEvidenceBasedControlActionTypes.GetOktaEvidenceBasedControlIntegrationComplete;
    }
}
exports.GetOktaEvidenceBasedControlIntegrationCompleteAction = GetOktaEvidenceBasedControlIntegrationCompleteAction;
class UpdateEnabledStatusOktaEvidenceBasedControlIntegrationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationOktaEvidenceBasedControlActionTypes.UpdateEnabledStatusOktaEvidenceBasedControlIntegration;
    }
}
exports.UpdateEnabledStatusOktaEvidenceBasedControlIntegrationAction = UpdateEnabledStatusOktaEvidenceBasedControlIntegrationAction;
class UpdateOktaEvidenceBasedControlIntegrationAction {
    constructor() {
        this.type = IntegrationOktaEvidenceBasedControlActionTypes.UpdateOktaEvidenceBasedControlIntegration;
    }
}
exports.UpdateOktaEvidenceBasedControlIntegrationAction = UpdateOktaEvidenceBasedControlIntegrationAction;
class UpdateOktaEvidenceBasedControlIntegrationSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationOktaEvidenceBasedControlActionTypes.UpdateOktaEvidenceBasedControlIntegrationSuccess;
    }
}
exports.UpdateOktaEvidenceBasedControlIntegrationSuccessAction = UpdateOktaEvidenceBasedControlIntegrationSuccessAction;
class UpdateOktaEvidenceBasedControlIntegrationFailureAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationOktaEvidenceBasedControlActionTypes.UpdateOktaEvidenceBasedControlIntegrationFailure;
    }
}
exports.UpdateOktaEvidenceBasedControlIntegrationFailureAction = UpdateOktaEvidenceBasedControlIntegrationFailureAction;
