"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.consentConfig = exports.isConsentConfigLoading = exports.dirSyncProviders = exports.isProvidersLoading = exports.connectors = exports.isConnectorsLoading = exports.dirSyncProductId = exports.isProductsLoading = exports.reducer = exports.initialState = void 0;
const actions = require("../actions");
exports.initialState = {
    isProductsLoading: false,
    isConnectorsLoading: false,
    isProvidersLoading: false,
    isConsentConfigLoading: false,
    dirSyncProductId: undefined,
    dirSyncProviders: [],
    connectors: [],
    consentConfig: {}
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.GET_PRODUCTS:
            return Object.assign(Object.assign({}, state), { isProductsLoading: true });
        case actions.GET_PRODUCTS_SUCCESS:
            return Object.assign(Object.assign({}, state), { isProductsLoading: false, dirSyncProductId: getDirectorySyncProductId(action.payload) });
        case actions.GET_PRODUCTS_FAILURE:
            return Object.assign(Object.assign({}, state), { isProductsLoading: false, dirSyncProductId: undefined });
        case actions.GET_CONNECTORS:
            return Object.assign(Object.assign({}, state), { isConnectorsLoading: true });
        case actions.GET_CONNECTORS_SUCCESS:
            return Object.assign(Object.assign({}, state), { isConnectorsLoading: false, connectors: action.payload });
        case actions.GET_CONNECTORS_FAILURE:
            return Object.assign(Object.assign({}, state), { isConnectorsLoading: false, connectors: [] });
        case actions.GET_PROVIDERS:
            return Object.assign(Object.assign({}, state), { isProvidersLoading: true });
        case actions.GET_PROVIDERS_SUCCESS:
            return Object.assign(Object.assign({}, state), { isProvidersLoading: false, dirSyncProviders: action.payload });
        case actions.GET_PROVIDERS_FAILURE:
            return Object.assign(Object.assign({}, state), { isProvidersLoading: false, dirSyncProviders: [] });
        case actions.GET_CONSENT_CONFIG:
            return Object.assign(Object.assign({}, state), { isConsentConfigLoading: true });
        case actions.GET_CONSENT_CONFIG_SUCCESS:
            return Object.assign(Object.assign({}, state), { isConsentConfigLoading: false, consentConfig: action.payload });
        case actions.GET_CONSENT_CONFIG_FAIL:
            return Object.assign(Object.assign({}, state), { isConsentConfigLoading: false, consentConfig: {} });
        default:
            return state;
    }
}
exports.reducer = reducer;
const isProductsLoading = (state) => state.isProductsLoading;
exports.isProductsLoading = isProductsLoading;
const dirSyncProductId = (state) => state.dirSyncProductId;
exports.dirSyncProductId = dirSyncProductId;
const isConnectorsLoading = (state) => state.isConnectorsLoading;
exports.isConnectorsLoading = isConnectorsLoading;
const connectors = (state) => state.connectors;
exports.connectors = connectors;
const isProvidersLoading = (state) => state.isProvidersLoading;
exports.isProvidersLoading = isProvidersLoading;
const dirSyncProviders = (state) => state.dirSyncProviders;
exports.dirSyncProviders = dirSyncProviders;
const isConsentConfigLoading = (state) => state.isConsentConfigLoading;
exports.isConsentConfigLoading = isConsentConfigLoading;
const consentConfig = (state) => state.consentConfig;
exports.consentConfig = consentConfig;
function getDirectorySyncProductId(products) {
    let id;
    products.forEach(product => {
        if (product.description === 'DIRECTORY_SYNC') {
            id = product.id;
        }
    });
    return id;
}
