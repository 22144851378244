"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DetailComponent = void 0;
const core_1 = require("@angular/core");
const status_factory_service_1 = require("app-new/sync-recover/services/status.factory.service");
class DetailComponent {
    constructor() {
        this.nodeStack = [];
        this.onGetRootFolders = new core_1.EventEmitter();
        this.onGetFolders = new core_1.EventEmitter();
        this.onGetContent = new core_1.EventEmitter();
        this.onTabSelect = new core_1.EventEmitter();
        this.onFolderToggleSelect = new core_1.EventEmitter();
        this.onNodeClicked = new core_1.EventEmitter();
        this.onItemClicked = new core_1.EventEmitter();
        this.onSearchFolders = new core_1.EventEmitter();
        this.onSearchContents = new core_1.EventEmitter();
        this.onPushNodeToTrace = new core_1.EventEmitter();
        this.onGetNextFolders = new core_1.EventEmitter();
        this.onGetPreviousFolders = new core_1.EventEmitter();
        this.onGetNextContents = new core_1.EventEmitter();
        this.onGetPreviousContents = new core_1.EventEmitter();
        this.onOpenExport = new core_1.EventEmitter();
        this.onOpenRestore = new core_1.EventEmitter();
        this.onInvalidSnapshot = new core_1.EventEmitter();
        this.TABS_KEYS = {
            LAST_SYNC: '$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_DETAIL.TABS.LASTSYNC.LABEL',
            ARCHIVED: '$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_DETAIL.TABS.ARCHIVED.LABEL',
            SUMMARY: '$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_DETAIL.TABS.SUMMARY.LABEL'
        };
    }
    ngOnInit() {
        var _a;
        this.isCompletedWithErrors =
            ((_a = this.getSnapshotStatus()) === null || _a === void 0 ? void 0 : _a.key) === status_factory_service_1.STATUS_TYPES.completed_with_errors;
    }
    ngOnChanges(changes) {
        if (changes.contentsMetadata &&
            changes.contentsMetadata.currentValue !== changes.contentsMetadata.previousValue) {
            const META = changes.contentsMetadata.currentValue;
            this.isFirstContentPage = META && META.pagination && !META.pagination['previous'];
        }
        if (changes.foldersMetadata &&
            changes.foldersMetadata.currentValue !== changes.foldersMetadata.previousValue) {
            const META = changes.foldersMetadata.currentValue;
            this.isFirstFolderPage = META && META.pagination && !META.pagination['previous'];
        }
        if (changes.snapshotSummary && changes.snapshotSummary.currentValue) {
            this.contentVisibilityStatus = !(changes.snapshotSummary.currentValue.status === status_factory_service_1.STATUS_TYPES.created ||
                changes.snapshotSummary.currentValue.status === status_factory_service_1.STATUS_TYPES.error ||
                changes.snapshotSummary.currentValue.status === status_factory_service_1.STATUS_TYPES.in_progress);
        }
    }
    getName() {
        return this.snapshotSummary && this.snapshotSummary.mailbox.displayableName;
    }
    getMail() {
        return this.snapshotSummary && this.snapshotSummary.mailbox.emailAddress;
    }
    getSnapshotStatus() {
        return this.snapshotSummary && status_factory_service_1.StatusFactory[this.snapshotSummary.status]();
    }
    getPhaseProgress() {
        return this.snapshotSummary && this.snapshotSummary.phaseProgress;
    }
    getSnapshotStatusMessage() {
        // TODO: iaggarwal - Once CSYNC returns proper message, map it and use it
        return this.snapshotSummary && this.snapshotSummary.statusMessage
            ? '$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_DETAIL.TABS.SUMMARY.CONTENT.REASON_DESCRIPTION'
            : false;
    }
    getSnapshotId() {
        return this.snapshotSummary && this.snapshotSummary.id;
    }
    getSnapshotRecoveryId() {
        return this.snapshotSummary && this.snapshotSummary.displayId;
    }
    getSnapshotDate() {
        return this.snapshotSummary && this.snapshotSummary.date;
    }
    getSnapshotShowContent() {
        return this.contentVisibilityStatus;
    }
    getSnapshotShowTab() {
        return this.contentVisibilityStatus ? 0 : 2;
    }
    getSnapshotEmptyResultMessage() {
        let messageContent;
        switch (this.snapshotSummary && this.snapshotSummary.status) {
            case status_factory_service_1.STATUS_TYPES.created:
                messageContent = {
                    title: '$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_DETAIL.EMPTY_RESULTS.CREATED.TITLE',
                    description: '$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_DETAIL.EMPTY_RESULTS.CREATED.DESCRIPTION'
                };
                break;
            case status_factory_service_1.STATUS_TYPES.error:
                messageContent = {
                    title: '$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_DETAIL.EMPTY_RESULTS.ERROR.TITLE',
                    description: '$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_DETAIL.EMPTY_RESULTS.ERROR.DESCRIPTION'
                };
                break;
            case status_factory_service_1.STATUS_TYPES.in_progress:
                messageContent = {
                    title: '$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_DETAIL.EMPTY_RESULTS.IN_PROGRESS.TITLE',
                    description: '$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_DETAIL.EMPTY_RESULTS.IN_PROGRESS.DESCRIPTION'
                };
                break;
        }
        return messageContent || {};
    }
    isExportAndRestoreDisabled(foldersCart) {
        return !this.hasExportAndRestorePermission || foldersCart.length === 0;
    }
    initializeSelectedTab(tab) {
        this.activeTab = tab.name;
        this.onTabSelect.emit(tab);
        this.onGetRootFolders.emit(this.snapshotSummary);
        this.onGetFolders.emit({ snapshotSummary: this.snapshotSummary });
    }
    getActiveTabClass(activeTabKey) {
        let rtnClass = '';
        if (activeTabKey === this.TABS_KEYS.LAST_SYNC) {
            rtnClass = 'mc-sync-recover-exchange-snapshot-detail-lastsync-view';
        }
        else {
            if (activeTabKey === this.TABS_KEYS.ARCHIVED) {
                rtnClass = 'mc-sync-recover-exchange-snapshot-detail-archived-view';
            }
        }
        return rtnClass;
    }
    dispatchNextFolders() {
        const meta = {};
        if (this.foldersMetadata &&
            this.foldersMetadata.pagination &&
            this.foldersMetadata.pagination['next']) {
            meta.pagination = { pageToken: this.foldersMetadata.pagination['next'] };
            this.onGetNextFolders.emit({
                snapshotSummary: this.snapshotSummary,
                meta,
                node: this.currentNode
            });
        }
    }
    dispatchPreviousFolders() {
        const meta = {};
        if (this.foldersMetadata &&
            this.foldersMetadata.pagination &&
            this.foldersMetadata.pagination['previous']) {
            meta.pagination = { pageToken: this.foldersMetadata.pagination['previous'] };
            this.onGetPreviousFolders.emit({
                snapshotSummary: this.snapshotSummary,
                meta,
                node: this.currentNode
            });
        }
    }
    searchFolders(searchTerm) {
        this.onSearchFolders.emit({
            snapshotSummary: this.snapshotSummary,
            searchTerm
        });
    }
    getFoldersAndContents(node) {
        this.contents = [];
        this.onNodeClicked.emit({
            snapshotSummary: this.snapshotSummary,
            node
        });
    }
    dispatchNextContents() {
        const meta = {};
        if (this.contentsMetadata &&
            this.contentsMetadata.pagination &&
            this.contentsMetadata.pagination['next']) {
            meta['pagination'] = { pageToken: this.contentsMetadata.pagination['next'] };
            this.onGetNextContents.emit({
                snapshotSummary: this.snapshotSummary,
                meta,
                node: this.currentNode
            });
        }
    }
    dispatchPreviousContents() {
        const meta = {};
        if (this.contentsMetadata &&
            this.contentsMetadata.pagination &&
            this.contentsMetadata.pagination['previous']) {
            meta['pagination'] = { pageToken: this.contentsMetadata.pagination['previous'] };
            this.onGetNextContents.emit({
                snapshotSummary: this.snapshotSummary,
                meta,
                node: this.currentNode
            });
        }
    }
    searchContents(searchTerm) {
        this.onSearchContents.emit({
            snapshotSummary: this.snapshotSummary,
            searchTerm,
            node: this.currentNode
        });
    }
    openItem(item) {
        if (this.hasContentViewPermission) {
            this.onItemClicked.emit({ snapshotSummary: this.snapshotSummary, item });
        }
    }
    openExport() {
        this.onOpenExport.emit({
            snapshotSummary: this.snapshotSummary,
            folderCart: this.selectedFoldersCart,
            userEmailAddress: this.userProfile.emailAddress
        });
    }
    openRestore() {
        this.onOpenRestore.emit({
            snapshotSummary: this.snapshotSummary,
            folderCart: this.selectedFoldersCart,
            userEmailAddress: this.userProfile.emailAddress
        });
    }
}
exports.DetailComponent = DetailComponent;
