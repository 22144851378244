"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfigurationsTableComponent = void 0;
const table_store_1 = require("@mimecast-ui/table-store");
const configurations_table_static_values_1 = require("./configurations-table.static-values");
const custom_domain_block_service_1 = require("app-new/custom-domain-block/services/custom-domain-block.service");
const customDomainBlockActions = require("../../actions/configurations-table.action");
class ConfigurationsTableComponent extends table_store_1.TableBaseComponent {
    constructor(store, fb) {
        super(store, custom_domain_block_service_1.CustomDomainBlockService.CUSTOM_DOMAIN_BLOCK_TABLE);
        this.store = store;
        this.fb = fb;
        this.columns = configurations_table_static_values_1.tableColumns;
        this.columnsAlwaysVisible = configurations_table_static_values_1.tableColumnsAlwaysVisible;
        this.searchByFields = this.getSearchByFields();
    }
    dispatchFilterAction(filteringOption = this.currentFilters, pagination = this.currentPagination) {
        super.dispatchFilterAction(filteringOption, pagination);
    }
    openItem(item) {
        this.store.dispatch(new customDomainBlockActions.OpenAndGetPolicyAction(item));
        this.dispatchSetSelectedRow(item);
    }
    ngOnInit() {
        super.ngOnInit();
        this.form = this.fb.group({
            searchQuery: ''
        });
        this.tableDataSubscription = this.tableData$.subscribe(data => (this.tableContent = data));
    }
    newConfiguration() {
        this.store.dispatch(new customDomainBlockActions.OpenCreateNewPolicy());
    }
    getSearchByFields() {
        const prefix = 'I18N_CUSTOM_DOMAIN_BLOCK_CONFIGURATIONS_TABLE.TABLE';
        const filterByOptions = ['all', 'name', 'description'];
        return filterByOptions.map((filterByOption) => {
            return { value: filterByOption, label: [prefix, filterByOption.toUpperCase()].join('.') };
        });
    }
    ngOnDestroy() {
        super.ngOnDestroy();
        if (this.tableDataSubscription) {
            this.tableDataSubscription.unsubscribe();
        }
    }
}
exports.ConfigurationsTableComponent = ConfigurationsTableComponent;
