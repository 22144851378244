"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const static_1 = require("@angular/upgrade/static");
require("./components/group-selector/group-selector"); //backup-restore-group-selector
require("app/components/layout/layout"); //layout
require("app/components/search-box/search-box"); //searchBox
require("mimecast-web-components/src/app/components/file-download-button/file-download-button"); //file.download.button
require("./components/rbutton/mc-r-button"); //predefined-field.rbutton
require("app/components/mc-checkbox/mc-checkbox"); //predefined-field.checkbox
require("./components/radiobutton/mc-radiobutton"); //predefined-field.radiobutton
require("./components/time-exclusions/time-exclusions"); //predefined-field.time-exclusions
require("mimecast-web-components/src/app/components/form/select/mc-select"); //predefined-field.select
require("./exports-and-restore/exports-and-restore"); //services.services.backup.exports-and-restore
require("./mailboxes/mailboxes"); //services.services.backup.mailboxes
require("./archive-task/archive-task"); //services.services.backup.archive-task
require("./search-logs/search-logs"); //services.services.backup.search-logs
require("./aside/aside"); //services.services.backup-aside
require("./backup-service"); //services.services.backup.service
require("./components/email-view/email-view"); //email.view
require("./components/load-more-results/load-more-results"); //load-more-results
// html and css
require("./components/mail-selector-aside/mail-selector-aside.tpl.html");
require("./components/radiobutton/mc-radiobutton.tpl.html");
require("./components/rbutton/mc-r-button.tpl.html");
require("./less/backuprestore.less");
const exchange_sync_and_recover_page_component_1 = require("app-new/sync-recover/exchange/containers/exchange-page-layout/exchange-sync-and-recover-page/exchange-sync-and-recover-page.component");
/**
 * Created by asyed on 12/04/2016.
 */
(function () {
    'use strict';
    angular.module('services.services.backup', [
        'backup-restore-group-selector',
        'layout',
        'searchBox',
        'file.download.button',
        'predefined-field.rbutton',
        'predefined-field.checkbox',
        'predefined-field.radiobutton',
        'predefined-field.time-exclusions',
        'predefined-field.select',
        'services.services.backup.exports-and-restore',
        'services.services.backup.mailboxes',
        'services.services.backup.archive-task',
        'services.services.backup.search-logs',
        'services.services.backup-aside',
        'services.services.backup.service',
        'email.view',
        'load-more-results'
    ])
        .directive('mcExchangeSyncAndRecoverPage', static_1.downgradeComponent({
        component: exchange_sync_and_recover_page_component_1.ExchangeSyncAndRecoverPageComponent,
    }))
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('backup-taskdetails', {
                url: '/backup/taskdetails/:edit/:taskId',
                data: {
                    asideTitle: '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ASIDE_MENU_TITLE',
                    tabId: 'back-up-restore-tabid',
                    tabTitle: 'taskDetails',
                    tabHide: false,
                    tabReload: true
                },
                views: {
                    'main': {
                        templateUrl: 'services/services/backup/task-mailboxes/archive-task-details.tpl.html',
                        controller: 'ArchiveTasksDetailsController as task'
                    },
                    'aside': {
                        templateUrl: 'services/services/backup/aside/aside.tpl.html'
                    }
                }
            });
        }]);
}());
