"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiCrowdstrikeActivityLogsAsideComponent = void 0;
class ApiCrowdstrikeActivityLogsAsideComponent {
    constructor(crowdstrikeActivityLogs, overlayRef) {
        this.crowdstrikeActivityLogs = crowdstrikeActivityLogs;
        this.overlayRef = overlayRef;
        this.crowdstrikeActivityLog = crowdstrikeActivityLogs;
    }
    close() {
        this.overlayRef.close();
    }
}
exports.ApiCrowdstrikeActivityLogsAsideComponent = ApiCrowdstrikeActivityLogsAsideComponent;
