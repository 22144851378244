"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getIsUpdateDisabled = exports.getUpdatedApp = exports.getUpdateAppApiProcessing = exports.getAppDetailsAsidePanelMode = exports.getSelectedAppFromTable = exports.getAppDetailsPanelIsLoading = exports.reducer = exports.initialState = void 0;
const appDetailsActions = require("../../actions/apps/app-details.actions");
const app_aside_1 = require("../../models/apps/app-aside");
exports.initialState = {
    isLoading: false,
    isUpdateAppApiProcessing: false,
    isUpdateDisabled: false,
    selectedAppId: '',
    selectedApp: null,
    updatedApp: null,
    mode: app_aside_1.AppDetailsAsideMode.VIEW
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case appDetailsActions.OPEN_ASIDE:
            return Object.assign(Object.assign({}, state), { isLoading: true, selectedAppId: action.payload.selectedApp.id, selectedApp: null, mode: action.payload.mode });
        case appDetailsActions.LOAD:
            return Object.assign(Object.assign({}, state), { isLoading: true });
        case appDetailsActions.LOAD_SUCCESS:
            return Object.assign(Object.assign({}, state), { isLoading: false, selectedApp: action.payload });
        case appDetailsActions.LOAD_FAIL:
            return Object.assign(Object.assign({}, state), { isLoading: false });
        case appDetailsActions.SWITCH_TO_EDIT:
            return Object.assign(Object.assign({}, state), { mode: app_aside_1.AppDetailsAsideMode.EDIT });
        case appDetailsActions.SWITCH_TO_VIEW:
            return Object.assign(Object.assign({}, state), { mode: app_aside_1.AppDetailsAsideMode.VIEW });
        case appDetailsActions.UPDATE:
            return Object.assign(Object.assign({}, state), { isUpdateAppApiProcessing: true });
        case appDetailsActions.UPDATE_SUCCESS:
            return Object.assign(Object.assign({}, state), { updatedApp: action.payload, isUpdateAppApiProcessing: false, isUpdateDisabled: true });
        case appDetailsActions.UPDATE_FAIL:
            return Object.assign(Object.assign({}, state), { isUpdateAppApiProcessing: false });
        case appDetailsActions.UPDATE_CLOSE_APP:
            return Object.assign(Object.assign({}, state), { updatedApp: null, isUpdateDisabled: false });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getAppDetailsPanelIsLoading = (state) => state.isLoading;
exports.getAppDetailsPanelIsLoading = getAppDetailsPanelIsLoading;
const getSelectedAppFromTable = (state) => state.selectedApp;
exports.getSelectedAppFromTable = getSelectedAppFromTable;
const getAppDetailsAsidePanelMode = (state) => state.mode;
exports.getAppDetailsAsidePanelMode = getAppDetailsAsidePanelMode;
const getUpdateAppApiProcessing = (state) => state.isUpdateAppApiProcessing;
exports.getUpdateAppApiProcessing = getUpdateAppApiProcessing;
const getUpdatedApp = (state) => state.updatedApp;
exports.getUpdatedApp = getUpdatedApp;
const getIsUpdateDisabled = (state) => state.isUpdateDisabled;
exports.getIsUpdateDisabled = getIsUpdateDisabled;
