<div class="mc-form-container">
<form
      [formGroup]="form"
      class="form-horizontal"
      mcErrorPrefix="$I18N_EMAIL_COMPOSE"
      mcDefaultLabelClass="col-sm-3" mcDefaultControlContainerClass="col-sm-9">
  <div class="mc-compose-headers">
    <mc-text-field label="$I18N_EMAIL_COMPOSE_TO" formControlName="to"></mc-text-field>
    <mc-collapsable-panel [isExpanded]="false"
                          extendClass="panel-margin-bottom"
                          showMoreLabel="$I18N_EMAIL_COMPOSE_SHOW_CC_BCC"
                          showLessLabel="$I18N_EMAIL_COMPOSE_HIDE_CC_BCC">
      <mc-text-field label="$I18N_EMAIL_COMPOSE_CC" formControlName="cc"></mc-text-field>
      <mc-text-field label="$I18N_EMAIL_COMPOSE_BCC" formControlName="bcc"></mc-text-field>
    </mc-collapsable-panel>
    <mc-text-field label="$I18N_EMAIL_COMPOSE_SUBJECT" formControlName="subject"></mc-text-field>

    <mc-attachments-list [attachments]="oldAttachments$ | async"
                         (remove)="removeOld($event)"></mc-attachments-list>
    <mc-attachments-upload [attachments]="newAttachments$ | async" (upload)="upload($event)"
                           (remove)="removeNew($event)"></mc-attachments-upload>
  </div>
  <div class="panel panel-default mc-compose-container">
    <div class="panel-body mc-compose-container">
      <textarea formControlName="textBody" class="mc-compose-content"></textarea>
    </div>
  </div>
</form>
</div>
