"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CancelDeviceRemediateModalComponent = void 0;
const remediate_messages_action_1 = require("../../../../actions/remediate-messages/remediate-messages.action");
class CancelDeviceRemediateModalComponent {
    constructor(store, overlayRef) {
        this.store = store;
        this.overlayRef = overlayRef;
    }
    onClose() {
        this.overlayRef.close();
    }
    onDelete() {
        this.store.dispatch(new remediate_messages_action_1.CancelDeviceRemediation());
    }
}
exports.CancelDeviceRemediateModalComponent = CancelDeviceRemediateModalComponent;
