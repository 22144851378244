<div *ngIf="settings.directIpEnabled === true || settings.directIpMode === directIpModes.ENABLED">
  <mc-modal-header
    headerTitle="{{ '$I18N_ENDPOINT_SETTINGS.MODAL.SETTINGS.ENABLE_DIRECT_IP.HEADER' | translate }}">
  </mc-modal-header>
  <mc-modal-body>
    <p>
      {{ '$I18N_ENDPOINT_SETTINGS.MODAL.SETTINGS.ENABLE_DIRECT_IP.BODY_CONTENT' | translate }}
    </p>
    <p>
      {{ '$I18N_ENDPOINT_SETTINGS.MODAL.SETTINGS.ENABLE_DIRECT_IP.BODY_CONTENT_ADDITIONAL' | translate }}
    </p>
  </mc-modal-body>
</div>
<div *ngIf="settings.directIpEnabled === false || settings.directIpMode === directIpModes.DISABLED">
  <mc-modal-header
    headerTitle="{{ '$I18N_ENDPOINT_SETTINGS.MODAL.SETTINGS.DISABLE_DIRECT_IP.HEADER' | translate }}">
  </mc-modal-header>
  <mc-modal-body>
    <p>
      {{ '$I18N_ENDPOINT_SETTINGS.MODAL.SETTINGS.DISABLE_DIRECT_IP.BODY_CONTENT' | translate }}
    </p>
  </mc-modal-body>
</div>
<div *ngIf="settings.directIpMode === directIpModes.MONITORING">
  <mc-modal-header
    headerTitle="{{ '$I18N_ENDPOINT_SETTINGS.MODAL.SETTINGS.MONITORING_DIRECT_IP.HEADER' | translate }}">
  </mc-modal-header>
  <mc-modal-body>
    <p>
      {{ '$I18N_ENDPOINT_SETTINGS.MODAL.SETTINGS.MONITORING_DIRECT_IP.BODY_CONTENT' | translate }}
    </p>
  </mc-modal-body>
</div>
<mc-modal-footer>
  <button type="button" class="btn btn-secondary" (click)="onCancel()">
    {{ '$I18N_ENDPOINT_SETTINGS.MODAL.SETTINGS.BTN_CANCEL' | translate }}
  </button>
  <mc-live-button
    overrideClasses="btn-primary"
    [label]="'$I18N_ENDPOINT_SETTINGS.MODAL.SETTINGS.DISABLE_DIRECT_IP.BTN_OK'  | translate"
    (mcClick)="onUpdateSetting()">
  </mc-live-button>
</mc-modal-footer>
