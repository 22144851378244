"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SaveSuccessAction = exports.CleanStoreAction = exports.CancelAction = exports.SaveAction = exports.LoadAction = exports.LoadSuccessAction = exports.StoreUpdateAction = exports.CANCEL = exports.STORE_UPDATE = exports.CLEAN_STORE = exports.SAVE = exports.LOAD_SUCCESS = exports.SAVE_SUCCESS = exports.LOAD = void 0;
exports.LOAD = '[Awareness Template Setup] Load';
exports.SAVE_SUCCESS = '[Awareness Template Setup] Saved';
exports.LOAD_SUCCESS = '[Awareness Template Setup] Load Success';
exports.SAVE = '[Awareness Template Setup] Save';
exports.CLEAN_STORE = '[Awareness Template Setup] Clean Store';
exports.STORE_UPDATE = '[Awareness Template Setup] Store Update';
exports.CANCEL = '[Awareness Template Setup] Cancel';
class StoreUpdateAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.STORE_UPDATE;
    }
}
exports.StoreUpdateAction = StoreUpdateAction;
class LoadSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOAD_SUCCESS;
    }
}
exports.LoadSuccessAction = LoadSuccessAction;
class LoadAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOAD;
    }
}
exports.LoadAction = LoadAction;
class SaveAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SAVE;
    }
}
exports.SaveAction = SaveAction;
class CancelAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.CANCEL;
    }
}
exports.CancelAction = CancelAction;
class CleanStoreAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.CLEAN_STORE;
    }
}
exports.CleanStoreAction = CleanStoreAction;
class SaveSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SAVE_SUCCESS;
    }
}
exports.SaveSuccessAction = SaveSuccessAction;
