<mc-layout-list-extra-container
  keyTitle="$I18N_MESSAGE_CENTER_MESSAGE_DELIVERY_CONTAINER.HEADER.TITLE"
  keyDescription="$I18N_MESSAGE_CENTER_MESSAGE_DELIVERY_CONTAINER.HEADER.SUB_TITLE"
>
  <mc-help-container>
    <mc-help-link url="https://community.mimecast.com/docs/DOC-3552"></mc-help-link>
  </mc-help-container>

  <mc-body-container>
    <div *ngIf="(hasAccessPermissions$ | async)">
      <mc-tab-group #tabs [initialActiveTabIndex]="0">
        <div class="panel-padding-top">
          <mc-tab
            name="{{
              '$I18N_MESSAGE_CENTER_MESSAGE_DELIVERY_BREADCRUMBS.DELIVERY_QUEUE' | translate
            }}"
          >
            <mc-message-delivery-tab></mc-message-delivery-tab>
          </mc-tab>

          <mc-tab
            name="{{ '$I18N_MESSAGE_CENTER_MESSAGE_DELIVERY_BREADCRUMBS.BULK_QUEUE' | translate }}"
          >
            <mc-bulk-delivery-tab></mc-bulk-delivery-tab>
          </mc-tab>

          <div *ngIf="(hasPurgePermission$ | async)">
            <mc-tab name="{{ '$I18N_MESSAGE_CENTER_MESSAGE_DELIVERY_BREADCRUMBS.PURGE_MESSAGES' | translate }}">
              <mc-purge-messages-tab></mc-purge-messages-tab>
            </mc-tab>
          </div>

        </div>
      </mc-tab-group>
    </div>
    <div *ngIf="(!hasAccessPermissions$ | async)">
      <mc-message-delivery-tab></mc-message-delivery-tab>
    </div>
  </mc-body-container>
</mc-layout-list-extra-container>
