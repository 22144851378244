"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfirmationModalComponent = void 0;
const policies_model_1 = require("../../models/policies.model");
const policy_wizard_models_1 = require("../../models/policy-wizard.models");
class ConfirmationModalComponent {
    constructor(overlayRef, data, translate) {
        this.overlayRef = overlayRef;
        this.translate = translate;
        this.cancelButton = policies_model_1.CONFIRMATION_DIALOG_TYPES.CANCEL;
        this.deleteButton = policies_model_1.CONFIRMATION_DIALOG_TYPES.DELETE;
        this.fromToEveryoneButton = policies_model_1.CONFIRMATION_DIALOG_TYPES.FROM_TO_EVERYONE;
        this.data = data;
        this.isCancelStyle =
            this.data.type === this.cancelButton ||
                this.data.type === policies_model_1.CONFIRMATION_DIALOG_TYPES.SIGNED_MESSAGES;
    }
    ngOnInit() {
        // set title and button text
        this.setModalTexts();
    }
    setModalTexts() {
        if (this.data.type === policies_model_1.CONFIRMATION_DIALOG_TYPES.SIGNED_MESSAGES) {
            this.title = this.translate.instant('$I18N_SERVICES_URL_PROTECTION.IGNORE_SIGNED_MESAGESS_TITLE');
            this.description = this.translate.instant('$I18N_SERVICES_URL_PROTECTION.IGNORE_SIGNED_MESAGESS_DESCRIPTION');
        }
        else if (this.data.type === policies_model_1.CONFIRMATION_DIALOG_TYPES.FROM_TO_EVERYONE) {
            this.title = this.translate.instant('$I18N_SERVICES_URL_PROTECTION.EVERYONE_VALUE_MODAL_TITLE');
            this.description = this.translate.instant('$I18N_SERVICES_URL_PROTECTION.EVERYONE_VALUE_MODAL_DESCRIPTION');
            this.buttonText = this.translate.instant('$I18N_SERVICES_URL_PROTECTION.EVERYONE_VALUE_MODAL_CLOSE_BUTTON');
            return;
        }
        else if (this.data.wizardFlowType === policy_wizard_models_1.PolicyWizardFlowType.CREATE ||
            this.data.wizardFlowType === policy_wizard_models_1.PolicyWizardFlowType.DUPLICATE) {
            this.title = this.translate.instant('$I18N_SERVICES_URL_PROTECTION.CANCEL_CREATE_POLICY_CONFIRMATION_TITLE');
        }
        else if (this.data.wizardFlowType === policy_wizard_models_1.PolicyWizardFlowType.EDIT) {
            this.title = this.translate.instant('$I18N_SERVICES_URL_PROTECTION.CANCEL_EDIT_POLICY_CONFIRMATION_TITLE');
        }
        else {
            this.title = this.translate.instant('$I18N_SERVICES_URL_PROTECTION.DELETE_POLICY_DELETE_CONFIRMATION_TITLE');
        }
        if (this.data.type === policies_model_1.CONFIRMATION_DIALOG_TYPES.CANCEL ||
            this.data.type === policies_model_1.CONFIRMATION_DIALOG_TYPES.SIGNED_MESSAGES) {
            this.buttonText = this.translate.instant('$I18N_SERVICES_URL_PROTECTION.CANCEL_POLICY_YES_BUTTON');
        }
        else {
            this.buttonText = this.translate.instant('$I18N_SERVICES_URL_PROTECTION.DELETE_POLICY_DELETE_CONFIRMATION_ACTION_BTN');
        }
    }
    onDelete() {
        this.overlayRef.close(true);
    }
    onCancel() {
        this.overlayRef.close(false);
    }
}
exports.ConfirmationModalComponent = ConfirmationModalComponent;
