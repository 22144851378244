"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoBackupsComponent = void 0;
const core_1 = require("@angular/core");
class NoBackupsComponent {
    constructor() {
        this.onCreateConnector = new core_1.EventEmitter();
        this.onCreateBackup = new core_1.EventEmitter();
    }
    handleBtnClick() {
        if (this.hasExistingConnector) {
            this.onCreateBackup.emit();
        }
        else {
            this.onCreateConnector.emit();
        }
    }
}
exports.NoBackupsComponent = NoBackupsComponent;
