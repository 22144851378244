"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiGatewayKeyGenVerificationStepComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const operators_1 = require("rxjs/operators");
const validators_1 = require("app-new/api-applications/validators");
class ApiGatewayKeyGenVerificationStepComponent {
    constructor(fb, ts) {
        this.fb = fb;
        this.ts = ts;
        this.onVerificationUpdated = new core_1.EventEmitter();
        this.onSubmitted = new core_1.EventEmitter();
        this.form = this.fb.group({
            verificationCode: [
                '',
                [forms_1.Validators.required, forms_1.Validators.minLength(6), forms_1.Validators.maxLength(8), validators_1.validateNotEmpty]
            ]
        });
    }
    ngOnInit() {
        this.formSubscription = this.form.valueChanges.pipe(operators_1.debounceTime(200)).subscribe(() => {
            this.update();
        });
        this.notification = {
            status: 'info',
            msg: this.ts.instant('$I18N_API_GATEWAY_APPLICATIONS_KEYGEN_WIZARD.VERIFICATION_STEP.NOTIFICATION', {
                notified: this.verificationNotification
            }),
            boxed: true
        };
    }
    submit() {
        if (this.isValid()) {
            this.onSubmitted.emit(true);
        }
    }
    update() {
        this.onVerificationUpdated.emit(this.form.value);
    }
    isValid() {
        return this.form.status === 'VALID';
    }
    ngOnDestroy() {
        this.formSubscription.unsubscribe();
    }
}
exports.ApiGatewayKeyGenVerificationStepComponent = ApiGatewayKeyGenVerificationStepComponent;
