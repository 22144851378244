<div class="form-group">
  <label class="col-sm-3 control-label"
    >{{ '$I18N_EMAIL_COMPOSE_ATTACHED_EMAIL' | translate }} ({{ attachments.length }})</label
  >
  <div class="col-sm-9">
    <div class="mc-files-scroll">
      <ul class="list-unstyled">
        <li
          *ngFor="let file of attachments"
          class="border-bottom panel-half-padding-bottom panel-half-padding-top"
        >
          {{ file.filename }}
          <span
            class="far fa-trash-alt pull-right"
            data-test="remove"
            (click)="remove.emit(file)"
          ></span>
        </li>
      </ul>
    </div>
  </div>
</div>
