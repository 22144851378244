<!-- DETAILS -->
<mc-collapsable-panel *ngIf="policyDetails" header="$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.SUMMARY.LABELS.DETAILS_SECTION" [isExpanded]="true">
  <div class="mc-aside-panel-section">
    <div class="mc-detailed-list mc-aside-panel-sub-section mc-aside-panel-first-item">
      <mc-value-field
        label="$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.LABELS.POLICY_NAME"
        [value]="policyDetails.policy.name">
      </mc-value-field>
      <mc-value-field
        label="$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.LABELS.POLICY_DESCRIPTION"
        [value]="policyDetails.policy.description">
      </mc-value-field>
      <div class="row mc-detailed-list-row">
        <div class="col-xs-3 mc-cybergraph-option-label">
          {{"$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.LABELS.STATUS" | translate}}
        </div>
        <div class="mc-cybergraph-option-value-section">
          <div>
            <em [ngClass]="displayStatusIcon(policyDetails.policy.enabled)"></em>
              {{getEnabledDisabledLabel(policyDetails.policy.enabled)}}
          </div>
        </div>
      </div>
      <div class="row mc-detailed-list-row">
        <div class="col-xs-3 mc-cybergraph-option-label">
          {{"$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.LABELS.CREATED_ON" | translate}}
        </div>
        <div class="mc-cybergraph-option-value-section">
          <div>
            {{policyDetails.policy.createTime | mcDate }}
          </div>
        </div>
      </div>
      <div class="row mc-detailed-list-row">
        <div class="col-xs-3 mc-cybergraph-option-label">
          {{"$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.LABELS.CYBERGRAPH_STATUS" | translate}}
        </div>
        <div class="mc-cybergraph-option-value-section">
          <div class="mc-cybergraph-option-value">
            {{getCyberGraphDynamicBannerEnumLabel(policyDetails.codebreakerStatus)}}
          </div>
          <div>
            {{getCyberGraphDynamicBannerDesc(policyDetails.codebreakerStatus)}}
          </div>
        </div>
      </div>
      <div class="row mc-detailed-list-row" *ngIf="trackerEnabled">
        <div class="col-xs-3 mc-cybergraph-option-label">
          {{"$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.LABELS.SILENCER_STATUS" | translate}}
        </div>
        <div class="mc-cybergraph-option-value-section">
          <div class="mc-cybergraph-option-value">
            {{getCyberGraphTrackersEnumLabel(policyDetails.silencerStatus)}}
          </div>
          <div>
            {{getCyberGraphTrackersDesc(policyDetails.silencerStatus)}}
          </div>
        </div>
      </div>
      <div class="row mc-detailed-list-row">
        <div class="col-xs-3 mc-cybergraph-option-label">
          {{"$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.LABELS.REPORTING_STATUS" | translate}}
        </div>
        <div class="mc-cybergraph-option-value-section">
          <div class="mc-cybergraph-option-value">
            {{getCyberGraphReportingStatusEnumLabel(policyDetails.reportingStatus)}}
          </div>
        </div>
      </div>
    </div>
  </div>
</mc-collapsable-panel>
<hr class="mc-group-separator"/>

<!-- APPLIES TO -->
<mc-collapsable-panel *ngIf="policyDetails" header="$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.SUMMARY.LABELS.APPLIES_TO_SECTION" [isExpanded]="true">
  <div class="mc-aside-panel-section">
    <div class="mc-detailed-list" *ngFor="let rule of policyDetails.rules; index as i;">
      <div class="mc-aside-panel-section-header">
        {{ '$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.SECTION_TITLE' | translate }}
        <span *ngIf="policyDetails.rules.length > 1">{{ i+1 }}</span>
      </div>
      <mc-value-field label="$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.APPLIES.LABEL_FROM"
        [value]="sidePanelHelper.getAppliesFromToLabel(rule.from, 'type')">
      </mc-value-field>
      <mc-value-field label="$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.APPLIES.LABEL_TO"
        [value]="sidePanelHelper.getAppliesFromToLabel(rule.to, 'type')">
      </mc-value-field>
      <mc-value-list *ngIf="rule.conditions"
      listLabel="{{ '$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.IP_RANGE_TITLE' | translate }}"
      [listItems]="rule.conditions.sourceIPs"
      [showMoreLessTriggerValue] = "showMoreLessTriggerValue">
    </mc-value-list>
    </div>
  </div>
</mc-collapsable-panel>
