
<mc-table
  tableId="swg/device-groups-list-table"
  class="mc-swg-endpoints-list"
  [isLoading]="isLoading$ | async"
  [data]="tableDataCopy"
  [columns]="getColumns()"
  translatePrefix="$I18N_DEVICE_GROUPS_LIST.TABLE"
  (rowClick)="onRowClick($event)"
  [showHighlightedRow]="displayType !== deviceGroupDisplayType.TARGET"
>
  <button
    *ngIf="hasEditPermission && displayType !== deviceGroupDisplayType.TARGET"
    id="create-group-button"
    class="mc-table-actions btn btn-primary panel-half-margin-right"
    (click)="onCreateDeviceGroup()"
  >
    {{ '$I18N_DEVICE_GROUPS_LIST.TABLE.ACTIONS.CREATE_DEVICE_GROUP' | translate }}
  </button>

  <button *ngIf="displayType === deviceGroupDisplayType.TARGET"
          class="mc-table-actions btn btn-primary panel-half-margin-right"
          (click)="addDeviceGroups()"> {{ '$I18N_DEVICES_LIST.TABLE.ACTIONS.ADD' | translate }}
  </button>

  <mc-filters
    (paginatorChange)="onPaginationChange($event)"
    [metadata]="metaData$ | async"
    [pageSizeOptions]="[50, 100, 300]"
    [showPagination]="true"
  >
    <mc-filter-search
      (search)="onSearch($event)"
      (clear)="onSearchClear()"
      [placeholder]="'$I18N_DEVICE_GROUPS_LIST.TABLE.SEARCH.PLACEHOLDER' | translate"
    >
    </mc-filter-search>

  </mc-filters>

  <mc-column key="check-row">
    <mc-header-cell *mcHeaderCellDef="let row">
      <label for="headerRowCheckbox">
        <input
          id="headerRowCheckbox"
          type="checkbox"
          (click)="onSelectAll($event)"
          [checked]="isSelectedAll"
          [disabled]="tableDataCopy && tableDataCopy.length === 0"
        />
      </label>

    </mc-header-cell>
    <mc-body-cell *mcBodyCellDef="let row">
      <label for="rowCheckbox">
        <input
          id="rowCheckbox"
          type="checkbox"
          (click)="onSelectingSingleItem({ event: $event, deviceGroup: row })"
          [checked]="row.selected"
        />
      </label>

    </mc-body-cell>
  </mc-column>

  <mc-column key="lastUpdated">
    <mc-body-cell *mcBodyCellDef="let row">
      {{ row.lastUpdated | date : dateFormat : timezoneOffset }}
    </mc-body-cell>
  </mc-column>

  <mc-column-actions
    key="action"
    [columnsAlwaysVisible]="columnsAlwaysVisible"
    [columnsToIgnore]="columnsToIgnore"
    mcShowColumnConfig
  >
    <div id="column-actions" *ngIf="hasEditPermission && displayType !== deviceGroupDisplayType.TARGET">
      <mc-row-actions *mcRowActions="let row">
        <li mcRowAction="$I18N_COMMON_BTN_EDIT"
            (click)="onEditClick(row)"></li>
        <li
          mcRowAction="$I18N_COMMON_BTN_DELETE"
          (click)="onDeleteClick(row)"
        ></li>
      </mc-row-actions>
    </div>

  </mc-column-actions>

  <mc-empty-results keyTitle="$I18N_DEVICE_GROUPS_LIST.TABLE.NO_DATA">
    <mc-empty-body>

    </mc-empty-body>
  </mc-empty-results>
</mc-table>

