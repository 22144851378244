"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RemediationSettingsSetExcludeAndNotifyGroup = exports.RemediationSettingsSetExcludeGroup = exports.RemediationSettingsSetNotifyGroup = exports.SET_EXCLUDE_AND_NOTIFY_GROUP = exports.SET_EXCLUDE_GROUP = exports.SET_NOTIFY_GROUP = void 0;
exports.SET_NOTIFY_GROUP = '[Remediation] Update notifyGroup';
exports.SET_EXCLUDE_GROUP = '[Remediation] Update excludeGroup';
exports.SET_EXCLUDE_AND_NOTIFY_GROUP = '[Remediation] Update exclude and notify group';
class RemediationSettingsSetNotifyGroup {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SET_NOTIFY_GROUP;
    }
}
exports.RemediationSettingsSetNotifyGroup = RemediationSettingsSetNotifyGroup;
class RemediationSettingsSetExcludeGroup {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SET_EXCLUDE_GROUP;
    }
}
exports.RemediationSettingsSetExcludeGroup = RemediationSettingsSetExcludeGroup;
class RemediationSettingsSetExcludeAndNotifyGroup {
    constructor(groups) {
        this.groups = groups;
        this.type = exports.SET_EXCLUDE_AND_NOTIFY_GROUP;
    }
}
exports.RemediationSettingsSetExcludeAndNotifyGroup = RemediationSettingsSetExcludeAndNotifyGroup;
