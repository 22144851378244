"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getHistoryCount = exports.reducer = exports.initialState = void 0;
const actions = require("../actions");
exports.initialState = {};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.RESET_COUNT:
            return Object.assign(Object.assign({}, state), { [action.tabId]: { historyCount: 0 } });
        case actions.NEW_PAGE: {
            /*
             * we do +2 because in other to control the back button in adcon3.5 we had to add a fake page in to history, so is
             * 1 for the current page and 1 for the fake page added using history.push
             */
            const historyCount = getValidHistoryCount(state, action);
            return Object.assign(Object.assign({}, state), { [action.tabId]: { historyCount: historyCount + 2 } });
        }
        case actions.BACK_PAGE: {
            const historyCount = getValidHistoryCount(state, action);
            return Object.assign(Object.assign({}, state), { [action.tabId]: { historyCount: historyCount - 1 } });
        }
        default:
            return state;
    }
}
exports.reducer = reducer;
/**
 * This function is being added because when an adcon3.5 page is being loaded,
 * we only call NEW_PAGE and BACK_PAGE actions after the page is FULLY LOADED.
 *
 * The problem with the previous behavior is that if you go back from an
 * adcon3.5 page to a non-adcon3.5 page, we will try to get a state page that
 * does not exist.
 *
 * Probably the best way to solve the problem, is instead of listening to window.message
 * before triggering NEW_PAGE and BACK_PAGE, to trigger them immediately after navigation.
 *
 * Triggering immediately NEW_PAGE and BACK_PAGE could have some impacts that we are not aware of.
 * That's the reason we are doing this validation function
 *  - otherwise we would change the source
 */
function getValidHistoryCount(state, action) {
    return (state[action.tabId] && state[action.tabId].historyCount) || 0;
}
function getHistoryCount(tabId) {
    return (state) => {
        return state[tabId] && state[tabId].historyCount;
    };
}
exports.getHistoryCount = getHistoryCount;
