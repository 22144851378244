"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectorDetailsStepComponent = void 0;
const forms_1 = require("@angular/forms");
const store_1 = require("@ngrx/store");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const step_base_component_1 = require("../../../step.base.component");
const connector_wizard_service_1 = require("../../../../../services/connector-wizard.service");
const selectors = require("../../../../../reducers");
const reducers_1 = require("../../../../../reducers");
const model_1 = require("../../../../../model");
class ConnectorDetailsStepComponent extends step_base_component_1.StepBaseDirective {
    constructor(store, fb, stateService) {
        super(store, fb, model_1.ConnectorStep.DETAILS, connector_wizard_service_1.ConnectorWizardService.WizardId);
        this.store = store;
        this.fb = fb;
        this.stateService = stateService;
        this.nameValidatorsArray = [
            forms_1.Validators.required,
            forms_1.Validators.maxLength(60),
            forms_1.Validators.pattern(/^(\s)*[^\s]+((\s+[^\s]+)|([^\s][^\s]))+(\s)*$/) // forces a min length of 3, see en.json
        ];
        this.descriptionValidatorsArray = [forms_1.Validators.minLength(3), forms_1.Validators.maxLength(200)];
        this.destroy$ = new rxjs_1.Subject();
        this.detailsStep = model_1.ConnectorStep.DETAILS;
        this.form = this.fb.group({
            name: ['', this.nameValidatorsArray],
            description: ['', this.descriptionValidatorsArray]
        });
        this.store.pipe(operators_1.takeUntil(this.destroy$), store_1.select(reducers_1.getFormResets)).subscribe(formResets => {
            if (formResets.details) {
                this.form.reset();
                this.form.controls['name'].patchValue('');
                this.form.controls['description'].patchValue('');
            }
        });
    }
    ngOnInit() {
        super.ngOnInit();
        if (this.editFlow === true) {
            this.form.controls['name'].patchValue(this.stateService.$state.params.name);
            this.form.controls['description'].patchValue(this.stateService.$state.params.description);
        }
        if (this.stateService.$state.params.existingConnector === true) {
            this.form.controls['name'].patchValue(this.stateService.$state.params.name);
            this.form.controls['description'].patchValue(this.stateService.$state.params.description);
        }
        this.currentStepIndex$ = this.store
            .select(selectors.getCurrentStep(this.wizardId))
            .pipe(operators_1.delay(100));
    }
    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
}
exports.ConnectorDetailsStepComponent = ConnectorDetailsStepComponent;
