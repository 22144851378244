"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LexiconComponent = void 0;
const lexiconActions = require("app-new/archive/supervision/actions/lexicon.actions");
const lexicon_service_1 = require("../../../services/lexicon.service");
const wizard_base_component_1 = require("../wizard-base.component");
const lexicon_1 = require("app-new/archive/supervision/model/lexicon/lexicon");
class LexiconComponent extends wizard_base_component_1.WizardBaseDirective {
    constructor(capabilitiesService, store, translateService, stateService) {
        super(capabilitiesService, lexicon_service_1.LexiconService.WizardId, store, translateService);
        this.capabilitiesService = capabilitiesService;
        this.store = store;
        this.translateService = translateService;
        this.stateService = stateService;
        this.lexiconDetails = this.stateService.$state.params.lexiconDetails;
    }
    get isEditMode() {
        return this.lexiconDetails !== undefined && this.lexiconDetails !== null;
    }
    get name() {
        return this.isEditMode && this.lexiconDetails.name ? this.lexiconDetails.name : '';
    }
    get description() {
        return this.isEditMode && this.lexiconDetails.description
            ? this.lexiconDetails.description
            : '';
    }
    get words() {
        return this.isEditMode &&
            this.lexiconDetails.searchParams &&
            this.lexiconDetails.searchParams.words
            ? this.lexiconDetails.searchParams.words.join('\n')
            : '';
    }
    get phrases() {
        return this.isEditMode &&
            this.lexiconDetails.searchParams &&
            this.lexiconDetails.searchParams.phrases
            ? this.lexiconDetails.searchParams.phrases.join('\n')
            : '';
    }
    get query() {
        return this.isEditMode &&
            this.lexiconDetails.searchParams &&
            this.lexiconDetails.searchParams.query
            ? this.lexiconDetails.searchParams.query
            : '';
    }
    get searchType() {
        return this.isEditMode &&
            this.lexiconDetails.searchParams &&
            this.lexiconDetails.searchParams.searchType
            ? this.lexiconDetails.searchParams.searchType
            : '';
    }
    get wizardTitle() {
        if (!this.isEditMode) {
            return this.translateService.instant('$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.TAB_CREATE_TITLE');
        }
        return this.translateService.instant('$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.TAB_UPDATE_TITLE');
    }
    onSave() {
        if (!this.isEditMode) {
            this.store.dispatch(new lexiconActions.CreateAction(this.getLexiconConfig()));
        }
        else {
            this.store.dispatch(new lexiconActions.UpdateAction(this.lexiconDetails.id, this.getLexiconConfig()));
            if (this.isAdvancedSearchType()) {
                this.store.dispatch(new lexiconActions.ValidateSearchQueryAction({
                    searchQuery: this.formatQuery(this.wizardData.steps[2].data['query'])
                }));
            }
        }
    }
    getCancelConfirmationText() {
        if (!this.isEditMode) {
            return this.translateService.instant('$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.CANCEL_CREATE_CONFIRMATION');
        }
        return this.translateService.instant('$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.CANCEL_UPDATE_CONFIRMATION');
    }
    getSaveButtonText() {
        if (!this.isEditMode) {
            return this.translateService.instant('$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.CONTROLS.CREATE');
        }
        return this.translateService.instant('$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.CONTROLS.UPDATE');
    }
    getLexiconConfig() {
        return {
            name: this.wizardData.steps[1] && this.wizardData.steps[1].data['name'],
            description: this.wizardData.steps[1].data['description'],
            searchParams: {
                query: !this.isSearchTypeBasic()
                    ? this.formatQuery(this.wizardData.steps[2].data['query'])
                    : null,
                searchType: this.wizardData.steps[2].data['searchType']
                    ? this.wizardData.steps[2].data['searchType']
                    : null,
                words: this.isSearchTypeBasic()
                    ? lexicon_service_1.LexiconService.splitWords(this.wizardData.steps[2].data['words'])
                    : null,
                phrases: this.isSearchTypeBasic()
                    ? lexicon_service_1.LexiconService.splitPhrases(this.wizardData.steps[2].data['phrases'])
                    : null
            }
        };
    }
    isSearchTypeBasic() {
        return (this.wizardData.steps[2] &&
            (this.wizardData.steps[2].data['searchType'] === '' ||
                this.wizardData.steps[2].data['searchType'] === lexicon_1.LexiconSearchType.BASIC));
    }
    formatQuery(query) {
        return query.replace(/\n/g, ' ');
    }
    showNextButton() {
        return (this.wizard.currentStep === 1 ||
            (this.wizard.currentStep === 2 && !this.isAdvancedSearchType()));
    }
}
exports.LexiconComponent = LexiconComponent;
