<div class="mc-api-gateway-step-block">
  <mc-wizard-step-header
    [label]="'$I18N_API_GATEWAY_APPLICATIONS_KEYGEN_WIZARD.VERIFICATION_STEP.HEADER' | translate"
  >
    <br />
    <mc-inline-notification [notification]="notification"></mc-inline-notification>
    <div *ngIf="isError">
      <br />
      <mc-inline-notification
        [notification]="{ status: 'warning', boxed: true, msg: errorMessage | translate }"
      >
      </mc-inline-notification>
    </div>
  </mc-wizard-step-header>

  <form
    class="form-horizontal"
    mcDefaultLabelClass="col-sm-3"
    mcDefaultControlContainerClass="col-sm-9"
    [formGroup]="form"
    (ngSubmit)="submit()"
  >
    <div class="row form-group">
      <div class="form-group mc-field">
        <label class="control-label col-sm-3">
          <span
            translate="$I18N_API_GATEWAY_APPLICATIONS_KEYGEN_WIZARD.FIELDS.ACCOUNT_EMAIL_ADDRESS"
          ></span>
        </label>
        <div class="col-sm-9 panel-half-padding-top">
          <span>{{ account.emailAddress }}</span>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <mc-text-field
        errorPrefix="$I18N_API_GATEWAY_APPLICATIONS_KEYGEN_WIZARD.VALIDATION.FIELD"
        label="$I18N_API_GATEWAY_APPLICATIONS_KEYGEN_WIZARD.FIELDS.VERIFICATION_CODE"
        formControlName="verificationCode"
        required
      >
      </mc-text-field>
    </div>
  </form>
</div>
