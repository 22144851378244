"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiemChannelSidebarContainerComponent = void 0;
const reducers = require("../../../reducers");
const sidebar = require("../../../actions/siem-channels/sidebar.actions");
const list = require("../../../actions/siem-channels/list.actions");
const siem_channels_models_1 = require("../../../models/siem-channels.models");
const channelActions = require("../../../actions/siem-channels/channel.actions");
class SiemChannelSidebarContainerComponent {
    constructor(store, siemService) {
        this.store = store;
        this.siemService = siemService;
    }
    ngOnInit() {
        this.channel$ = this.store.select(reducers.getSiemChannel);
        this.channelDetailsLoading$ = this.store.select(reducers.getChannelDetailsLoading);
        this.hasEditPermission$ = this.siemService.hasEditPermission();
        this.hasDeletePermission$ = this.siemService.hasDeletePermission();
        this.metadataProducts$ = this.store.select(reducers.getMetadataProducts);
    }
    deleteChannel(row) {
        this.store.dispatch(new list.SiemChannelDeleteConfirmationModalOpen(row));
    }
    editChannel(channel) {
        this.store.dispatch(new sidebar.SiemChannelListSidebarClose());
        this.store.dispatch(new channelActions.SiemLoadChannelToEdit(channel));
        this.store.dispatch(new channelActions.SiemChannelNavigateToWizardAction(siem_channels_models_1.SiemChannelWizardFlowType.EDIT));
    }
    close() {
        this.store.dispatch(new sidebar.SiemChannelListSidebarClose());
    }
}
exports.SiemChannelSidebarContainerComponent = SiemChannelSidebarContainerComponent;
