"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const emailAction = require("../actions/email.action");
class EmailEffects {
    constructor(actions$, service) {
        this.actions$ = actions$;
        this.service = service;
        // @ts-ignore
        this.email$ = this.actions$.pipe(effects_1.ofType(emailAction.EMAIL_LOAD), operators_1.map((action) => action.payload), operators_1.switchMap((query) => {
            // @ts-ignore
            return this.service.getEmailDetail(query).pipe(operators_1.map((email) => {
                return new emailAction.EmailLoadSuccessAction(email);
            }), operators_1.catchError(() => rxjs_1.of(new emailAction.EmailLoadFailAction())));
        }));
        this.loadEmail$ = this.actions$.pipe(effects_1.ofType(emailAction.DELIVERY_LOAD), operators_1.map((action) => action.id), operators_1.switchMap((id) => {
            return this.service.getEmailDeliveryPart(id).pipe(operators_1.map(content => new emailAction.DeliverySuccessAction(content)), operators_1.catchError(() => rxjs_1.of(new emailAction.DeliveryLoadFailAction())));
        }));
    }
}
__decorate([
    effects_1.Effect()
], EmailEffects.prototype, "email$", void 0);
__decorate([
    effects_1.Effect()
], EmailEffects.prototype, "loadEmail$", void 0);
exports.EmailEffects = EmailEffects;
