<div>
  <mc-modal-header
    headerTitle="{{ '$I18N_ENDPOINT_SETTINGS.MODAL.SETTINGS.DISABLE_ALLOW_LOCAL_IPS.HEADER' | translate }}">
  </mc-modal-header>
  <mc-modal-body>
    <p>
      {{ '$I18N_ENDPOINT_SETTINGS.MODAL.SETTINGS.DISABLE_ALLOW_LOCAL_IPS.BODY_CONTENT' | translate }}
    </p>
    <p>
      {{ '$I18N_ENDPOINT_SETTINGS.MODAL.SETTINGS.DISABLE_ALLOW_LOCAL_IPS.BODY_CONTENT_ADDITIONAL' | translate }}
    </p>
  </mc-modal-body>
</div>
<mc-modal-footer>
  <button type="button" class="btn btn-secondary" (click)="onCancel()">
    {{ '$I18N_ENDPOINT_SETTINGS.MODAL.SETTINGS.BTN_CANCEL' | translate }}
  </button>
  <mc-live-button
    overrideClasses="btn-primary"
    [label]="'$I18N_ENDPOINT_SETTINGS.MODAL.SETTINGS.DISABLE_ALLOW_LOCAL_IPS.BTN_OK'  | translate"
    (mcClick)="onUpdateSetting()">
  </mc-live-button>
</mc-modal-footer>
