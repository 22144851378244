"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApDeletePolicyConfirmationComponent = void 0;
const policyActions = require("../../actions/policy.actions");
const reducers = require("../../reducers");
class ApDeletePolicyConfirmationComponent {
    constructor(store) {
        this.store = store;
    }
    ngOnInit() {
        this.policy$ = this.store.select(reducers.getDeleteSelectedPolicy);
    }
    onDelete(data) {
        this.store.dispatch(new policyActions.ApDeletePolicy(data.id));
    }
    onCancel() {
        this.store.dispatch(new policyActions.ApDeleteModalCloseAction());
    }
}
exports.ApDeletePolicyConfirmationComponent = ApDeletePolicyConfirmationComponent;
