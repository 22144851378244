<mc-layout-list-extra-container
  keyTitle="$I18N_ONEDRIVE_BACKUPS.TITLE"
  keyDescription="$I18N_ONEDRIVE_BACKUPS.DESCRIPTION">
  <mc-body-container
    *ngIf="!((isLoadingConnectors$ | async) 
      || (openingEditBackup$ | async)
      || (isLoadingBackup$ | async)); else spinner">  
    <mc-backup-list-table          
      *ngIf="(tableData$ | async)?.length || (currentFilters$ | async) || (errorLoadingTable$ | async)"
      [tableData]="tableData$ | async"
      [metaData]="metaData$ | async"
      [isLoading]="isLoading$ | async"        
      [hasBackupEditPermission]="hasBackupEditPermission$ | async"
      [hasBackupReadPermission]="hasBackupReadPermission$ | async"
      [errorLoadingTable]="errorLoadingTable$ | async"
      (onPaginationChange)="onPaginationChange($event)"
      (onFilterChange)="onFilterChange($event)"
      (onCreateBackup)="createBackup()"
      (onStatusChange)="changeStatus($event)"
      (onEditBackup)="editBackup($event)"
      (onRowClick)="openItem($event)"
      (onBackupDelete)="deleteBackup($event)"
      (onReloadTable)="reloadTable()"
    ></mc-backup-list-table>
    <mc-no-backups 
      *ngIf="(tableData$ | async)?.length === 0 && !(currentFilters$ | async) && !(errorLoadingTable$ | async)"
      [hasExistingConnector]="hasExistingConnector" 
      [hasBackupEditPermission]="hasBackupEditPermission$ | async"
      (onCreateBackup)="createBackup()"
      (onCreateConnector)="openCreateConnectorModal()">
    </mc-no-backups>
  </mc-body-container>
</mc-layout-list-extra-container>
<ng-template #spinner>
  <div class="spinner-wrapper">
    <mc-spinner></mc-spinner>
  </div>
</ng-template>