<mc-modal-header headerTitle="{{ '$18N_SWG_REMEDIATION_DEVICE_SUMMARY.CANCEL_FROM_DEVICES' | translate }}"></mc-modal-header>
<mc-modal-body>
  <p>{{ '$18N_SWG_REMEDIATION_DEVICE_SUMMARY.CANCEL_FROM_DEVICES_WARN' | translate }}</p>
</mc-modal-body>
<mc-modal-footer>
  <button type="button" class="btn btn-secondary" (click)="onClose()">
    {{ '$I18N_COMMON_TEXT_NO' | translate }}
  </button>
  <mc-live-button
    overrideClasses="btn-primary"
    [label]="'$I18N_COMMON_TEXT_YES' | translate"
    (mcClick)="onDelete()">
  </mc-live-button>
</mc-modal-footer>
