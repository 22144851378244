"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiIntegrationCrowdstrikeActivationStepComponent = void 0;
const core_1 = require("@angular/core");
class ApiIntegrationCrowdstrikeActivationStepComponent {
    constructor(fb, translateService) {
        this.fb = fb;
        this.translateService = translateService;
        this.valuesChanged = false;
        this.verifyText = this.translateService.instant('$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.ACTIVATION.VERIFY');
        this.crowdstrikeLink = 'https://falcon.crowdstrike.com';
        this.onVerifyClientIdAndClientSecret = new core_1.EventEmitter();
        this.form = this.fb.group({
            clientIdField: '',
            clientSecretField: '',
            baseUrlField: ''
        });
    }
    ngOnInit() {
        if (!!this.clientId && !!this.clientSecret && !!this.baseUrl) {
            this.form.patchValue({
                clientIdField: this.clientId,
                clientSecretField: this.clientSecret,
                baseUrlField: this.baseUrl
            });
            this.isEdit = true;
        }
        else {
            this.isEdit = false;
        }
        this.formSubscription = this.form.valueChanges.subscribe(() => {
            this.valuesChanged = true;
        });
    }
    verify() {
        this.onVerifyClientIdAndClientSecret.emit({
            clientId: this.form.value.clientIdField,
            clientSecret: this.form.value.clientSecretField,
            baseUrl: this.form.value.baseUrlField
        });
        this.valuesChanged = false;
    }
    isVerified() {
        return !this.valuesChanged && this.isClientIdAndClientSecretVerified;
    }
    isDisabled() {
        return (this.form.value.clientIdField === '' ||
            this.form.value.clientSecretField === '' ||
            this.form.value.baseUrlField === '' ||
            this.isVerifyApiKeyButtonDisabled);
    }
    verifyTextButton() {
        return this.isVerifyApiKeyButtonDisabled
            ? this.translateService.instant('$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.ACTIVATION.VERIFYING')
            : this.translateService.instant('$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.ACTIVATION.VERIFY');
    }
    isValid() {
        return (this.isVerified() &&
            this.form.value.clientIdField !== '' &&
            this.form.value.clientSecretField !== '' &&
            this.form.value.baseUrlField !== '');
    }
    showMaskedClientSecret() {
        return !this.isEdit || (this.isEdit && !this.form.controls['clientSecretField'].pristine);
    }
    ngOnDestroy() {
        this.formSubscription.unsubscribe();
    }
}
exports.ApiIntegrationCrowdstrikeActivationStepComponent = ApiIntegrationCrowdstrikeActivationStepComponent;
