"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueueSettingsComponent = void 0;
const queueActions = require("app-new/archive/supervision/actions/queue.actions");
const components_1 = require("@mimecast-ui/components");
const forms_1 = require("@angular/forms");
const components_2 = require("@mimecast-ui/components");
const rxjs_1 = require("rxjs");
const queue_service_1 = require("../../../services/queue.service");
const queue_type_enum_1 = require("app-new/archive/supervision/types/queue-type.enum");
const select_reviewers_component_1 = require("app-new/archive/supervision/containers/asides/select-reviewers/select-reviewers.component");
const step_base_component_1 = require("app-new/archive/supervision/containers/wizard/step.base.component");
const validators_1 = require("app-new/api-applications/validators");
class QueueSettingsComponent extends step_base_component_1.StepBaseDirective {
    constructor(store, fb, translateService, asideService) {
        super(store, fb, 2, queue_service_1.QueueService.WizardId);
        this.store = store;
        this.fb = fb;
        this.translateService = translateService;
        this.asideService = asideService;
        this.selectedReviewers = [];
        this.allReviewers = [];
        this.loading = false;
        this.addButtonDisabled$ = new rxjs_1.BehaviorSubject(true);
        this.QueueType = queue_type_enum_1.QueueType;
        this.form = this.fb.group({
            allowForwarding: [true],
            allowPrinting: [true],
            newLabel: [
                '',
                [
                    validators_1.validateNotEmpty,
                    this.validateNonDuplicatedLabel(),
                    components_2.minLengthTrimmed(3),
                    this.maxLengthTrimmed(30),
                    forms_1.Validators.pattern(/^[^\s]+(\s+[^\s]+)*$/)
                ]
            ],
            reviewers: this.fb.array([]),
            labels: this.fb.array([], this.validateMaxLabels(10)),
            selectReviewersPopover: []
        });
    }
    ngOnInit() {
        super.ngOnInit();
        if (this.labels) {
            this.labels.forEach(label => {
                const labelsName = this.fb.group({
                    name: [label]
                });
                this.userLabels.push(labelsName);
            });
        }
    }
    ngOnChanges(changes) {
        if (changes && changes.selectedReviewers && changes.selectedReviewers.currentValue) {
            this.addSelectedReviewers(this.selectedReviewers);
        }
        if (changes) {
            if (changes.allowPrinting && changes.allowPrinting.currentValue !== undefined) {
                this.form.patchValue({ allowPrinting: this.allowPrinting });
            }
            if (changes.allowForwarding && changes.allowForwarding.currentValue !== undefined) {
                this.form.patchValue({ allowForwarding: this.allowForwarding });
            }
        }
    }
    get stepsData() {
        return this.wizardData ? this.wizardData.steps || {} : {};
    }
    openSidebar() {
        this.store.dispatch(new queueActions.GetReviewersAction());
        const asideConfig = new components_1.AsideConfig();
        asideConfig.size = 'md';
        this.aside = this.asideService.open(select_reviewers_component_1.SelectReviewersComponent, asideConfig);
    }
    get userLabels() {
        return this.form.get('labels');
    }
    get newLabelControl() {
        return this.form.get('newLabel');
    }
    addLables() {
        if (this.newLabelControl.valid) {
            const labelsName = this.fb.group({
                name: [this.newLabelControl.value]
            });
            this.userLabels.push(labelsName);
            const labelsArray = this.userLabels.value;
            labelsArray.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
            this.userLabels.patchValue(labelsArray);
            this.newLabelControl.patchValue('');
            this.newLabelControl.markAsPristine();
        }
    }
    areTooManyLabels() {
        return this.userLabels.length >= 10;
    }
    onAddLabelChange(value) {
        this.addButtonDisabled$.next(!value || value.length === 0);
        this.userLabels.setErrors({});
    }
    deleteLabels(i) {
        this.userLabels.removeAt(i);
    }
    get reviewers() {
        return this.form.get('reviewers');
    }
    addReviewers() {
        const reviewersName = this.fb.group({
            name: []
        });
        this.reviewers.push(reviewersName);
    }
    deleteReviewers(i) {
        const reviewer = this.reviewers.value[i].reviewer;
        this.store.dispatch(new queueActions.RemoveGetSelectedReviewersAction(reviewer));
    }
    formatEnabled(enabled) {
        if (enabled) {
            return this.translateService.instant('$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.SETTINGS_STEP.STEP_ENABLE');
        }
        return this.translateService.instant('$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.SETTINGS_STEP.STEP_DISABLE');
    }
    getStepById(id) {
        return (this.stepsData && this.stepsData[id]) || {};
    }
    getStepDataById(id) {
        const step = this.getStepById(id);
        return step.data || {};
    }
    ngOnDestroy() {
        super.ngOnDestroy();
        if (this.aside) {
            this.aside.close();
        }
    }
    addSelectedReviewers(reviewers) {
        if (reviewers) {
            const sortedReviewers = this.sortedReviewers(reviewers);
            this.form.removeControl('reviewers');
            this.form.addControl('reviewers', this.fb.array(sortedReviewers.map(reviewer => this.fb.group({
                reviewer: [Object.assign({}, reviewer)]
            }))));
        }
    }
    getReviewerName(reviewer) {
        if (!reviewer) {
            return '';
        }
        if (reviewer.name && reviewer.emailAddress && reviewer.name !== reviewer.emailAddress) {
            return `${reviewer.name} [${reviewer.emailAddress}]`;
        }
        else {
            return reviewer.emailAddress;
        }
    }
    validateMaxLabels(max) {
        return (labelsControl) => {
            return labelsControl.controls.length > max ? { maxNumber: true } : null;
        };
    }
    validateNonDuplicatedLabel() {
        return (newLabelControl) => {
            const labels = this.form &&
                this.form.controls['labels'].value &&
                this.form.controls['labels'].value.map((l) => l.name.toLowerCase());
            return labels && labels.indexOf(newLabelControl.value.toLowerCase()) >= 0
                ? { duplicated: { label: newLabelControl.value } }
                : null;
        };
    }
    maxLengthTrimmed(maxLengthTrimmed) {
        return function (control) {
            const trimmed = (control.value && control.value.trim()) || '';
            const length = trimmed.length;
            const isValid = length <= maxLengthTrimmed;
            if (isValid || !control.value) {
                return null;
            }
            return {
                maxLengthTrimmed: {
                    currentLength: length,
                    requiredLength: maxLengthTrimmed
                }
            };
        };
    }
    get labelErrors() {
        return this.form.controls['labels'].errors;
    }
    get isNewLabelValid() {
        const newLabelControl = this.form.controls['newLabel'];
        return (newLabelControl.valid &&
            (!newLabelControl.value || newLabelControl.value == null ? 0 : newLabelControl.value.length) >
                0);
    }
    sortedReviewers(reviewers) {
        return reviewers
            ? reviewers
                .slice()
                .sort((a, b) => this.getReviewerName(a).localeCompare(this.getReviewerName(b)))
            : [];
    }
}
exports.QueueSettingsComponent = QueueSettingsComponent;
