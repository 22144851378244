"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRequiredFields = exports.Required = void 0;
require("reflect-metadata");
const forms_1 = require("@angular/forms");
const REQUIRED_METADATA_KEY = Symbol('MC-REQUIRED-FIELD');
function Required() {
    return (target, propertyKey) => {
        let requiredFields = Reflect.getMetadata(REQUIRED_METADATA_KEY, target);
        if (requiredFields) {
            requiredFields.push(propertyKey);
        }
        else {
            requiredFields = [propertyKey];
        }
        Reflect.defineMetadata(REQUIRED_METADATA_KEY, requiredFields, target);
    };
}
exports.Required = Required;
function getRequiredFields(origin) {
    const properties = Reflect.getMetadata(REQUIRED_METADATA_KEY, origin);
    const result = {};
    if (properties) {
        properties.forEach(key => (result[key] = forms_1.Validators.required));
    }
    return result;
}
exports.getRequiredFields = getRequiredFields;
