"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteYesAction = exports.RemoveProfileGroupAction = exports.RemoveNotifyGroupAction = exports.RemoveRedirectGroupAction = exports.RemoveRedirectEmailAction = exports.AddRedirectGroupAction = exports.AddRedirectEmailAction = exports.AddProfileGroupAction = exports.AddNotifyGroupAction = exports.DeletePolicyFailAction = exports.DeletePolicySuccessAction = exports.DeletePolicyAction = exports.CreateUpdateActionSuccess = exports.CreateUpdateActionFail = exports.SetDetailsPaginationInfoAction = exports.UpdatePolicyAction = exports.CreatePolicyAction = exports.CleanPolicyAction = exports.GetPolicyFailAction = exports.GetPolicySuccessAction = exports.OpenAndGetPolicyAction = exports.GetPolicyAction = exports.OpenCreateNewPolicy = exports.CLOSE_SIDE_PANE = exports.OPEN_SIDE_PANE = exports.DELETE_NO = exports.DELETE_YES = exports.SHOW_DELETE_CONFIRMATION = exports.REMOVE_PROFILE_GROUP = exports.REMOVE_NOTIFY_GROUP = exports.ADD_PROFILE_GROUP = exports.REMOVE_REDIRECT_GROUP = exports.ADD_REDIRECT_GROUP = exports.REMOVE_REDIRECT_EMAIL = exports.ADD_REDIRECT_EMAIL = exports.ADD_NOTIFY_GROUP = exports.DELETE_POLICY_FAIL = exports.DELETE_POLICY_SUCCESS = exports.DELETE_POLICY = exports.CREATE_UPDATE_SUCCESS = exports.CREATE_UPDATE_FAIL = exports.OPEN_CREATE_NEW_POLICY = exports.UPDATE_POLICY = exports.CREATE_POLICY = exports.UPDATE_DETAILS_PAGINATION_INFO = exports.SET_DETAILS_PAGINATION_INFO = exports.CLEAN_POLICY = exports.GET_POLICY_FAIL = exports.GET_POLICY_SUCCESS = exports.GET_POLICY = void 0;
exports.CloseSidePaneAction = exports.ShowDeleteConfirmationAction = exports.DeleteNoAction = void 0;
exports.GET_POLICY = '[Custom Domain Block / Configurations table ] get policy';
exports.GET_POLICY_SUCCESS = '[Custom Domain Block / Configurations table ] get policy success';
exports.GET_POLICY_FAIL = '[Custom Domain Block / Configurations table ] get policy fail';
exports.CLEAN_POLICY = '[Custom Domain Block / Configurations table ] clean policy';
exports.SET_DETAILS_PAGINATION_INFO = '[Custom Domain Block / Configurations table ] Set details pagination';
exports.UPDATE_DETAILS_PAGINATION_INFO = '[Custom Domain Block / Configurations table ] Update pagination';
exports.CREATE_POLICY = '[Custom Domain Block / Configurations table ] create policy';
exports.UPDATE_POLICY = '[Custom Domain Block / Configurations table ] update policy';
exports.OPEN_CREATE_NEW_POLICY = '[Custom Domain Block / Configurations table ] open create new policy';
exports.CREATE_UPDATE_FAIL = '[Custom Domain Block / Create/Update ] create fail';
exports.CREATE_UPDATE_SUCCESS = '[Custom Domain Block / Create/Update ] create success';
exports.DELETE_POLICY = '[Custom Domain Block / Configurations table ] Delete Policy';
exports.DELETE_POLICY_SUCCESS = '[Custom Domain Block / Configurations table ] Delete Policy Success';
exports.DELETE_POLICY_FAIL = '[Custom Domain Block / Configurations table ] Delete Policy Fail';
exports.ADD_NOTIFY_GROUP = '[Custom Domain Block / Configurations table ] Add notify user';
exports.ADD_REDIRECT_EMAIL = '[Custom Domain Block / Configurations table ] Add redirect email';
exports.REMOVE_REDIRECT_EMAIL = '[Custom Domain Block / Configurations table ] Remove redirect email';
exports.ADD_REDIRECT_GROUP = '[Custom Domain Block / Configurations table ] Add redirect goup';
exports.REMOVE_REDIRECT_GROUP = '[Custom Domain Block / Configurations table ] Remove redirect group';
exports.ADD_PROFILE_GROUP = '[Custom Domain Block / Configurations table ] Add profile group user';
exports.REMOVE_NOTIFY_GROUP = '[Custom Domain Block / Configurations table ] Remove notify user';
exports.REMOVE_PROFILE_GROUP = '[Custom Domain Block / Configurations table ] Remove profile group';
exports.SHOW_DELETE_CONFIRMATION = '[Custom Domain Block / Configurations table ] Show delete confirmation';
exports.DELETE_YES = '[Custom Domain Block / Configurations table ] Delete policy yes';
exports.DELETE_NO = '[Custom Domain Block / Configurations table ] Delete policy no';
exports.OPEN_SIDE_PANE = '[Custom Domain Block / Configurations table ] Open Side Pane';
exports.CLOSE_SIDE_PANE = '[Custom Domain Block / Configurations table ] Close Side Pane';
class OpenCreateNewPolicy {
    constructor() {
        this.type = exports.OPEN_CREATE_NEW_POLICY;
    }
}
exports.OpenCreateNewPolicy = OpenCreateNewPolicy;
class GetPolicyAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_POLICY;
    }
}
exports.GetPolicyAction = GetPolicyAction;
class OpenAndGetPolicyAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.OPEN_SIDE_PANE;
    }
}
exports.OpenAndGetPolicyAction = OpenAndGetPolicyAction;
class GetPolicySuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_POLICY_SUCCESS;
    }
}
exports.GetPolicySuccessAction = GetPolicySuccessAction;
class GetPolicyFailAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_POLICY_FAIL;
    }
}
exports.GetPolicyFailAction = GetPolicyFailAction;
class CleanPolicyAction {
    constructor() {
        this.type = exports.CLEAN_POLICY;
    }
}
exports.CleanPolicyAction = CleanPolicyAction;
class CreatePolicyAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.CREATE_POLICY;
    }
}
exports.CreatePolicyAction = CreatePolicyAction;
class UpdatePolicyAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.UPDATE_POLICY;
    }
}
exports.UpdatePolicyAction = UpdatePolicyAction;
class SetDetailsPaginationInfoAction {
    constructor(previous, current, next, currentIndex, totalNumber) {
        this.previous = previous;
        this.current = current;
        this.next = next;
        this.currentIndex = currentIndex;
        this.totalNumber = totalNumber;
        this.type = exports.SET_DETAILS_PAGINATION_INFO;
    }
}
exports.SetDetailsPaginationInfoAction = SetDetailsPaginationInfoAction;
class CreateUpdateActionFail {
    constructor(message, failure, isUpdate = false) {
        this.message = message;
        this.failure = failure;
        this.isUpdate = isUpdate;
        this.type = exports.CREATE_UPDATE_FAIL;
    }
}
exports.CreateUpdateActionFail = CreateUpdateActionFail;
class CreateUpdateActionSuccess {
    constructor(message) {
        this.message = message;
        this.type = exports.CREATE_UPDATE_SUCCESS;
    }
}
exports.CreateUpdateActionSuccess = CreateUpdateActionSuccess;
class DeletePolicyAction {
    constructor(definitionId) {
        this.definitionId = definitionId;
        this.type = exports.DELETE_POLICY;
    }
}
exports.DeletePolicyAction = DeletePolicyAction;
class DeletePolicySuccessAction {
    constructor() {
        this.type = exports.DELETE_POLICY_SUCCESS;
    }
}
exports.DeletePolicySuccessAction = DeletePolicySuccessAction;
class DeletePolicyFailAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.DELETE_POLICY_FAIL;
    }
}
exports.DeletePolicyFailAction = DeletePolicyFailAction;
class AddNotifyGroupAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.ADD_NOTIFY_GROUP;
    }
}
exports.AddNotifyGroupAction = AddNotifyGroupAction;
class AddProfileGroupAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.ADD_PROFILE_GROUP;
    }
}
exports.AddProfileGroupAction = AddProfileGroupAction;
class AddRedirectEmailAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.ADD_REDIRECT_EMAIL;
    }
}
exports.AddRedirectEmailAction = AddRedirectEmailAction;
class AddRedirectGroupAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.ADD_REDIRECT_GROUP;
    }
}
exports.AddRedirectGroupAction = AddRedirectGroupAction;
class RemoveRedirectEmailAction {
    constructor() {
        this.type = exports.REMOVE_REDIRECT_EMAIL;
    }
}
exports.RemoveRedirectEmailAction = RemoveRedirectEmailAction;
class RemoveRedirectGroupAction {
    constructor() {
        this.type = exports.REMOVE_REDIRECT_GROUP;
    }
}
exports.RemoveRedirectGroupAction = RemoveRedirectGroupAction;
class RemoveNotifyGroupAction {
    constructor() {
        this.type = exports.REMOVE_NOTIFY_GROUP;
    }
}
exports.RemoveNotifyGroupAction = RemoveNotifyGroupAction;
class RemoveProfileGroupAction {
    constructor() {
        this.type = exports.REMOVE_PROFILE_GROUP;
    }
}
exports.RemoveProfileGroupAction = RemoveProfileGroupAction;
class DeleteYesAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.DELETE_YES;
    }
}
exports.DeleteYesAction = DeleteYesAction;
class DeleteNoAction {
    constructor() {
        this.type = exports.DELETE_NO;
    }
}
exports.DeleteNoAction = DeleteNoAction;
class ShowDeleteConfirmationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SHOW_DELETE_CONFIRMATION;
    }
}
exports.ShowDeleteConfirmationAction = ShowDeleteConfirmationAction;
class CloseSidePaneAction {
    constructor() {
        this.type = exports.CLOSE_SIDE_PANE;
    }
}
exports.CloseSidePaneAction = CloseSidePaneAction;
