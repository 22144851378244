"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgApplicationDashboardContainerComponent = void 0;
const topApplications = require("../../../reducers");
const models_1 = require("../../../../models/models");
const application_actions_1 = require("../../../actions/application.actions");
const uuid = require("uuid/v4");
const swg_applications_component_1 = require("../../../swg-applications.component");
const cfp_action_1 = require("../../../actions/cfp.action");
const swg_application_dashboard_static_value_1 = require("../../component/dashboard/swg-application-dashboard.static-value");
const notification_actions_1 = require("../../../../../components/notification/actions/notification.actions");
class SwgApplicationDashboardContainerComponent {
    constructor(store, stateService, tabService, swgService, swgPolicyService, swgApplicationControlService) {
        this.store = store;
        this.stateService = stateService;
        this.tabService = tabService;
        this.swgService = swgService;
        this.swgPolicyService = swgPolicyService;
        this.swgApplicationControlService = swgApplicationControlService;
        this.dateRangeConfig = swg_application_dashboard_static_value_1.dateRangePickerConfig;
        this.clpIntegrationEnabled = this.swgService.hasClpIntegrationEnabled();
        this.hasClp = this.swgService.hasWebSecCLPPackage();
        this.collectData(this.dateRangeConfig.dateRanges[0]);
        const tab = this.tabService.getSelectedTab();
        tab.onRefresh = () => {
            this.collectData(this.dateRangeSelected ? this.dateRangeSelected : this.dateRangeConfig.dateRanges[0]);
        };
    }
    ngAfterViewInit() {
        const paramTab = this.stateService.$state.params.tab;
        switch (paramTab) {
            case 'events':
                this.tabs.tabClicked(swg_applications_component_1.applicationControlTabs.events);
                break;
            case 'applications':
                this.tabs.tabClicked(swg_applications_component_1.applicationControlTabs.applicationList);
                break;
            default:
                this.switchRouteTab(swg_applications_component_1.ApplicationControlRouterTabs.OVERVIEW);
        }
    }
    dateRangeChange(dateRange) {
        this.dateRangeSelected = dateRange;
        this.collectData(dateRange);
    }
    collectData(dateRangeSelected) {
        const dateRange = this.transformDateRangeFilter(dateRangeSelected.rangeFn());
        this.store.dispatch(new application_actions_1.SwgGetApplicationChartInfoAction(dateRange, uuid()));
        this.store.dispatch(new application_actions_1.SwgLoadTopProfilesAction(dateRange));
        if (this.clpIntegrationEnabled && this.hasClp) {
            this.store.dispatch(new cfp_action_1.SwgCfpVerdictsByAppAction(dateRange));
            this.store.dispatch(new cfp_action_1.SwgCfpVerdictsByDateAction(dateRange));
            this.store.dispatch(new cfp_action_1.SwgCfpScansByFileTypesAction(dateRange));
        }
        this.verdictsByDatesLoading$ = this.store.select(topApplications.getVerdictsByDatesLoading);
        this.verdictsDistributedByCloudAppsLoading$ = this.store.select(topApplications.getLoadingByApps);
        this.scanCountByFileTypeLoading$ = this.store.select(topApplications.getLoadingByFileTypes);
        this.verdictsByDates$ = this.store.select(topApplications.getVerdictsByDates);
        this.verdictsDistributedByCloudApps$ = this.store.select(topApplications.getVerdictsDistributedByCloudApps);
        this.scanCountByFileType$ = this.store.select(topApplications.getScanCountByFileType);
        this.topProfiles$ = this.store.select(topApplications.getTopProfiles);
        this.topProfilesLoading$ = this.store.select(topApplications.isTopProfilesLoading);
        this.isChartDataLoading$ = this.store.select(topApplications.isChartLoading);
        this.isApiUpdatingEdit$ = this.store.select(topApplications.isApiUpdatingEdit);
        this.isApplicationCategorySetDataLoading$ = this.store.select(topApplications.isApplicationCategorySetDataLoading);
        this.hasSwgFull = this.swgService.hasWebSecurityAccountPackage();
        this.topSanctionedApps$ = this.store.select(topApplications.getTopSanctionedApplications);
        this.topNonSanctionedApps$ = this.store.select(topApplications.getTopNonSanctionedApplications);
        this.topMonitoredApps$ = this.store.select(topApplications.getTopMonitoredApplications);
        this.topBlockedApps$ = this.store.select(topApplications.getTopBlockedApplications);
        this.topCategories$ = this.store.select(topApplications.getTopCategories);
        this.sanctionedAppsCount$ = this.store.select(topApplications.getSanctionedAppsCount);
        this.nonSanctionedAppsCount$ = this.store.select(topApplications.getNonSanctionedAppsCount);
        this.monitoredAppsCount$ = this.store.select(topApplications.getMonitoredAppsCount);
        this.allApplications$ = this.store.select(topApplications.getAllApplications);
        this.applicationCategoriesSet$ = this.store.select(topApplications.getApplicationCategoriesSet);
        this.hasEditPermission$ = this.swgPolicyService.hasPolicyEditPermission();
    }
    transformDateRangeFilter(dateRange) {
        return {
            start: dateRange.start.format(),
            end: dateRange.end.format()
        };
    }
    webApplicationSanction(data) {
        this.store.dispatch(new application_actions_1.SwgUpdateApplicationStatus(data));
    }
    onDashboardWidgetClick(params) {
        this.swgApplicationControlService.applicationListFilterByParams(params);
        this.tabs.tabClicked(swg_applications_component_1.applicationControlTabs.applicationList);
    }
    tabClicked(index) {
        switch (index) {
            case 1:
                this.switchRouteTab(swg_applications_component_1.ApplicationControlRouterTabs.CLOUD_APPS);
                break;
            case 2:
                this.switchRouteTab(swg_applications_component_1.ApplicationControlRouterTabs.EVENTS);
                break;
            case 0:
            default:
                this.switchRouteTab(swg_applications_component_1.ApplicationControlRouterTabs.OVERVIEW);
                this.swgApplicationControlService.applicationListFilterByParams({
                    action: models_1.ParamsAction.FILTER,
                    id: ''
                });
                break;
        }
    }
    switchRouteTab(tab) {
        this.stateService.$state.go('secure-web-gateway-applications', { tab }, {
            notify: false
        });
    }
    showNotification(msg) {
        this.store.dispatch(new notification_actions_1.NotificationShowAction({
            type: 'info',
            config: {
                msg
            }
        }));
    }
}
exports.SwgApplicationDashboardContainerComponent = SwgApplicationDashboardContainerComponent;
