'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const _ = require("lodash");
require("mimecast-web-components/src/app/services/user/user"); //api.user
require("mimecast-web-components/src/app/services/session-handler/session-handler"); //session-handler
/**
 * Created by cfeudo on 5/02/2016.
 */
angular.module('user.handler.service', ['api.user', 'session-handler'])
    .factory('userHandlerService', ['$q', 'userService', 'sessionHandlerService', 'administrationService', 'accountService', function ($q, userService, sessionHandlerService, administrationService, accountService) {
        let user = {};
        const deferred = $q.defer();
        return {
            getUser,
            getDelegateAccess
        };
        function getUser() {
            if (_.isEmpty(user)) {
                $q.all([userService.getProfile()]).then(function (responses) {
                    const responseProfile = responses[0];
                    if (!responseProfile.err) {
                        user = responseProfile.first;
                        userService.getCapabilitiesAndPermissions().then(function (responseCaps) {
                            if (!responseCaps.err) {
                                const caps = getCapsPermissionsMap(responseCaps);
                                user.userSettings = {
                                    appCapabilities: {
                                        capabilities: caps
                                    }
                                };
                                accountService.getAccount().then(function (responseGetAccount) {
                                    if (!responseGetAccount.err) {
                                        user.companyName = responseGetAccount.first.accountName;
                                        deferred.resolve(user);
                                    }
                                    else {
                                        rejectHandler(responseGetAccount.fail());
                                    }
                                }, rejectHandler);
                            }
                            else {
                                rejectHandler(responseCaps.fail());
                            }
                        }, rejectHandler);
                    }
                    else {
                        rejectHandler(responseProfile.fail());
                    }
                    // over-riding the 'role' from delegate-access for partner SSO switch accounts instead of get-profile
                    const remoteAccountCode = sessionHandlerService.getSession().remoteAccountCode;
                    if (angular.isDefined(remoteAccountCode)) {
                        getDelegateAccess(remoteAccountCode).then(function (roleName) {
                            user.role = roleName;
                        });
                    }
                }, rejectHandler);
            }
            return deferred.promise;
        }
        function getCapsPermissionsMap(capArray) {
            const caps = {};
            angular.forEach(capArray.first.capabilities, function (cap) {
                if ((!_.isEmpty(cap.name)) && cap.enabled) {
                    caps[cap.name] = { enabled: true };
                }
            });
            angular.forEach(capArray.first.permissions, function (permission) {
                if (permission) {
                    caps['Permission.' + permission] = { enabled: true };
                }
            });
            return caps;
        }
        function rejectHandler(error) {
            deferred.reject(error);
        }
        function getDelegateAccess(accountCode) {
            return userService.getDelegatedAccess().then(function (delegateResponse) {
                if (!delegateResponse.err) {
                    let roleName;
                    angular.forEach(delegateResponse.first.delegateAccess, function (delegate) {
                        if (delegate.accountCode == accountCode) {
                            roleName = delegate.roleName;
                        }
                    });
                    return roleName;
                }
                else {
                    // @ts-ignore
                    userService.getError().showErrorNotification(accountResponse);
                }
            }, function (error) {
                userService.getError().showErrorNotification();
                return error;
            });
        }
    }]);
