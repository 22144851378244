"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HeldMessagesPageComponent = void 0;
const summaryByReasonAction = require("../actions/summary-by-reason.action");
const summaryByGroupAction = require("../actions/summary-by-group.action");
const actions = require("../actions");
class HeldMessagesPageComponent {
    constructor(store, tabService) {
        this.store = store;
        this.tabService = tabService;
        const tab = this.tabService.getSelectedTab();
        tab.onRefresh = () => {
            this.store.dispatch(new actions.HeldQueueRefreshAction());
            this.store.dispatch(new actions.ReleaseLogRefreshAction());
            this.store.dispatch(new summaryByReasonAction.SummaryByReasonRequestAction());
            this.store.dispatch(new summaryByGroupAction.SummaryByGroupRequestAction());
        };
    }
    ngOnInit() {
        // get data for overview tab
    }
    ngOnDestroy() {
        this.store.dispatch(new actions.HeldQueueResetStateAction());
    }
}
exports.HeldMessagesPageComponent = HeldMessagesPageComponent;
