<mc-swg-policy-list-component
  [hasEditPermission]="hasEditPermission$ | async"
  (rowClick)="onRowClick($event)"
  (createNewPolicy)="onCreateNewPolicy()"
  (goToBlockPageSettings)="onGoToBlockPageSettings()"
  (editPolicy)="onEditPolicy($event)"
  (deletePolicy)="onDeletePolicy($event)"
  (disablePolicy)="onDisablePolicy($event)"
  (duplicatePolicy)="onDuplicatePolicy($event)"
  (checkCLPPolicyForConnector)="onCheckCLPPolicyForConnector($event)"
>
</mc-swg-policy-list-component>
