"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiemChannelProductEventsStepComponent = void 0;
const core_1 = require("@angular/core");
const operators_1 = require("rxjs/operators");
const siem_channels_models_1 = require("../../../../../models/siem-channels.models");
class SiemChannelProductEventsStepComponent {
    constructor(fb) {
        this.fb = fb;
        this.channelType = siem_channels_models_1.ChannelType;
        this.isEditMode = false;
        this.updateProductEvents = new core_1.EventEmitter();
    }
    set product(_product) {
        if (_product) {
            this.productAvailable = _product;
            this.createFormWithControls(_product);
        }
    }
    registerValueChanges() {
        this.channelProductEventsPageSubscription = this.channelProductEventsPage.valueChanges
            .pipe(operators_1.debounceTime(200))
            .subscribe((data) => {
            this.updateProductEvents.emit(data);
        });
    }
    createFormWithControls(product) {
        this.channelProductEventsPage = this.fb.group({
            name: [product.name],
            description: [product.description],
            groups: this.fb.array(this.createGroupsForm(product))
        });
        this.registerValueChanges();
    }
    createGroupsForm(product) {
        const groupsFormArray = [];
        product.groups.forEach((group) => {
            const groupFormGroup = this.fb.group({
                name: [group.name],
                description: [group.description],
                type: [group.type],
                events: this.fb.array(this.createEventsForm(group))
            });
            groupsFormArray.push(groupFormGroup);
        });
        return groupsFormArray;
    }
    createEventsForm(group) {
        const eventsFormArray = [];
        group.events.forEach((event) => {
            const eventFormGroup = this.fb.group({
                name: [event.name],
                id: [event.id],
                enabled: [event.enabled],
                hidden: [event.hidden]
            });
            eventsFormArray.push(eventFormGroup);
        });
        return eventsFormArray;
    }
    enableDisableAllEventsInProduct(enable) {
        this.groups().controls.map((group, idx) => {
            this.enableDisableEventsInGroup(idx, enable);
        });
    }
    AreAllEventsEnabledInProduct() {
        let enabled = true;
        this.groups().controls.forEach((group, idx) => {
            if (!this.AreAllEventsEnabledInGroup(idx)) {
                enabled = false;
            }
        });
        return enabled;
    }
    AreAllEventsEnabledInGroup(groupIndex) {
        const events = this.getEventsByGroupIndex(groupIndex).value;
        return events.filter((event) => event.enabled).length === events.length;
    }
    groups() {
        return this.channelProductEventsPage.get('groups');
    }
    events(groupIndex) {
        return this.getEventsByGroupIndex(groupIndex);
    }
    getEventsByGroupIndex(groupIndex) {
        return this.groups()
            .at(groupIndex)
            .get('events');
    }
    enableDisableEventsInGroup(groupIndex, enable, groupName = '') {
        const events = this.getEventsByGroupIndex(groupIndex);
        events.controls.map(event => {
            event.patchValue({ enabled: enable });
            this.changeEventsWithSameId(event, groupName);
        });
    }
    isValid() {
        let eventsEnabledCount = 0;
        this.channelProductEventsPage.value.groups.filter((group) => {
            eventsEnabledCount += group.events.filter(e => e.enabled).length;
        });
        return !!eventsEnabledCount;
    }
    changeEventsWithSameId(eventControl, groupName, _event = null) {
        if (_event === null || _event.target.value) {
            const eventToCheck = eventControl.value;
            this.groups().controls.map((group, idx) => {
                const events = this.getEventsByGroupIndex(idx);
                events.controls.map(event => {
                    if (event.get('id').value === eventToCheck.id &&
                        (event.get('name').value !== eventToCheck.name || group.get('name').value !== groupName)) {
                        event.patchValue({
                            enabled: _event === null ? eventToCheck.enabled : !eventToCheck.enabled
                        });
                    }
                });
            });
        }
    }
    ngOnDestroy() {
        this.channelProductEventsPageSubscription.unsubscribe();
    }
}
exports.SiemChannelProductEventsStepComponent = SiemChannelProductEventsStepComponent;
