"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ManagedUrlsService = void 0;
const _ = require("lodash");
class ManagedUrlsService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    createNewManagedUrl(managedUrlPayload, selectedRow) {
        return this.httpClient.post('/api/ttp/url/create-managed-url', this.parseMultipleUrlsPayload(managedUrlPayload, selectedRow));
    }
    updateManagedUrl(payload) {
        return this.httpClient.post('/api/ttp/url/update-managed-url', payload);
    }
    getAllManagedUrls(request) {
        return this.httpClient.post('/api/ttp/url/get-all-managed-urls', request);
    }
    deleteManagedUrl(id) {
        return this.httpClient.post('/api/ttp/url/delete-managed-url', id);
    }
    parseMultipleUrlsPayload(urls, selectedRow) {
        if (selectedRow.overrideUrl) {
            return _.cloneDeep(urls).map(url => (Object.assign(Object.assign({}, url), { overrideUrl: true })));
        }
        return urls;
    }
}
exports.ManagedUrlsService = ManagedUrlsService;
