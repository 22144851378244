"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ManagedUrlsTableComponent = exports.OVERRIDE_TYPE = void 0;
const core_1 = require("@angular/core");
const url_list_table_static_values_1 = require("./url-list-table.static-values");
const rxjs_1 = require("rxjs");
const table_store_1 = require("@mimecast-ui/table-store");
const effects_1 = require("@ngrx/effects");
var OVERRIDE_TYPE;
(function (OVERRIDE_TYPE) {
    OVERRIDE_TYPE["PERMIT"] = "permit";
    OVERRIDE_TYPE["BLOCK"] = "block";
})(OVERRIDE_TYPE = exports.OVERRIDE_TYPE || (exports.OVERRIDE_TYPE = {}));
class ManagedUrlsTableComponent extends table_store_1.TableBaseComponent {
    constructor(tableStoreStore, userAwaranessDisplayPipe, disableRewriteDisplayPipe, selectedRowActionsSubj, tabService, capsService) {
        super(tableStoreStore, 'managedUrlsList');
        this.userAwaranessDisplayPipe = userAwaranessDisplayPipe;
        this.disableRewriteDisplayPipe = disableRewriteDisplayPipe;
        this.selectedRowActionsSubj = selectedRowActionsSubj;
        this.tabService = tabService;
        this.capsService = capsService;
        this.columns = [...url_list_table_static_values_1.tableColumns];
        this.canCreateEditAndDeleteCapExpression = 'Permission.SERVICES_TTP_URL_PROTECT_EDIT';
        this.hasAllOpt = false;
        this.filterSections = url_list_table_static_values_1.tableSectionFilters;
        this.searchSections = url_list_table_static_values_1.tableSectionSearch;
        this.columnsToStartHidden = [...url_list_table_static_values_1.tableColumnsStartHidden];
        this.columnsAlwaysVisible = [...url_list_table_static_values_1.tableColumnsAlwaysVisible];
        this.selectedRows = [];
        this.canEdit = false;
        this.onPaginatorChanged = new core_1.EventEmitter();
        this.onSearchFilterChanged = new core_1.EventEmitter();
        this.onColumnFilterChanged = new core_1.EventEmitter();
        this.onRowClicked = new core_1.EventEmitter();
        this.onManagedUrlRemove = new core_1.EventEmitter();
        this.onAddNewManagedUrl = new core_1.EventEmitter();
        this.onOpenSideInEdit = new core_1.EventEmitter();
        this.selectedRowSubscription = new rxjs_1.Subscription();
        this.setupSubscriptions();
        const tab = this.tabService.getSelectedTab();
        tab.onRefresh = () => {
            const payload = Object.assign({}, this.currentFilters);
            this.onFilterChange(payload);
        };
    }
    setupSubscriptions() {
        this.capsSubscription = this.capsService
            .evalCapabilitiesExpression(this.canCreateEditAndDeleteCapExpression)
            .subscribe(canEdit => {
            if (canEdit) {
                this.canEdit = canEdit;
            }
        });
        this.tableDataSubscription = this.tableData$.subscribe((data) => {
            this.tableData = data;
        });
        this.tableMetaDataSubscription = this.metaData$.subscribe(data => {
            if (data && data.pagination) {
                this.metadata = {
                    pagination: Object.assign(Object.assign({}, data.pagination), { totalRows: data.pagination.totalCount })
                };
            }
        });
        // on sidenav close, clear table store selected row
        // selected row action is dispatched after sidepanel is closed from update mode, when so, dispatch table store action and deselect row
        this.selectedRowSubscription = this.selectedRowActionsSubj
            .pipe(effects_1.ofType('[Services / Url protection / Managed URL] Selected row'))
            .subscribe(() => {
            this.dispatchClearSelectedRow();
        });
    }
    changePaginator(pagination) {
        this.onPaginatorChanged.emit(pagination);
        super.onPaginationChange(pagination);
    }
    changeSearchFilter(filter) {
        this.onFilterChange(Object.assign(Object.assign({}, this.currentFilters), { searchBy: {
                fieldName: filter.selectValue,
                value: filter.searchQuery
            } }));
    }
    changeColumnFilter(filter) {
        this.onFilterChange(Object.assign(Object.assign({}, this.currentFilters), { filterBy: filter.overrideType }));
    }
    // open managed urls side panel
    addNewManagedUrl() {
        this.onAddNewManagedUrl.emit(true);
    }
    // click on table row
    clickRow(row) {
        this.onRowClick(row);
        this.onRowClicked.emit(row);
    }
    openSideInEditMode(row) {
        this.onOpenSideInEdit.emit(row);
        this.dispatchSetSelectedRow(row);
    }
    removeManagedUrl(managedUrl) {
        this.onManagedUrlRemove.emit(managedUrl);
    }
    displayAwarenessIcon(selectedRow) {
        const state = this.userAwaranessDisplayPipe.transform(selectedRow.disableUserAwareness, selectedRow);
        if (state === '-') {
            return 'hidden';
        }
        return selectedRow.disableUserAwareness ? 'fas fa-times-circle' : 'fas fa-check-circle';
    }
    displayRewritingIcon(selectedRow) {
        const state = this.disableRewriteDisplayPipe.transform(selectedRow.disableRewrite, selectedRow);
        if (state === '-') {
            return 'hidden';
        }
        return selectedRow.disableRewrite ? 'fas fa-times-circle' : 'fas fa-check-circle';
    }
    ngOnDestroy() {
        this.tableDataSubscription.unsubscribe();
        this.tableMetaDataSubscription.unsubscribe();
        this.selectedRowSubscription.unsubscribe();
        this.capsSubscription.unsubscribe();
        super.ngOnDestroy();
    }
}
exports.ManagedUrlsTableComponent = ManagedUrlsTableComponent;
