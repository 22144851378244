<mc-sfc-graph-layout
  [loading]="loading"
  [noData]="getAllScannedFiles() === 0"
  [noDataMessage]="'$I18N_SFC_CHART_NO_DATA'"
  [chartName]="CHART_NAME">
  <div class="mc-threat-detections mc-list-panel-body"
       [ngClass]="showAppCounts() ? null : 'mc-threat-detections-without-app-counts'">
    <div class="row text-center" [ngClass]="showAppCounts() ? 'mc-threat-detections-total' : null">
      <span class="mc-bio-hazard-icon fas fa-biohazard"> </span>
      <span class="mc-bio-hazard-message">
          <span class="mc-detected-total">   {{ (getAllDetectedFiles()) | number:'1.0':'en-US'}}</span>
          <span class="mc-total-scanned">of {{getAllScannedFiles() | number:'1.0':'en-US'}} </span>
        </span>
    </div>

    <div class="mc-provider-items">
      <div class="mc-provider-item" *ngIf="isSupported(msTeams) && data[msTeams]">
        <div class="mc-provider-details">
          <mc-provider-icon [providerType]="msTeams"></mc-provider-icon>
          <div class="panel-padding-top">
          <span
            class="mc-detected">   {{data[msTeams]?.detectedScannedFiles | number:'1.0':'en-US'}} </span>
            of
            <span
              class="mc-total-scanned-by-app"> {{data[msTeams]?.totalScannedFiles}} </span>
          </div>
        </div>
      </div>
      <div class="mc-provider-item" *ngIf="isSupported(box) && data[box]">
        <div class="mc-provider-details">
          <mc-provider-icon [providerType]="box"></mc-provider-icon>
          <div class="panel-padding-top">
          <span
            class="mc-detected">   {{data['box']?.detectedScannedFiles  | number:'1.0':'en-US'}} </span>
            of
            <span
              class="mc-total-scanned-by-app"> {{data['box']?.detectedScannedFiles  }} </span>
          </div>

        </div>
      </div>
      <div class="mc-provider-item" *ngIf="isSupported(oneDrive) && data[oneDrive]">
        <div class="mc-provider-details">
          <mc-provider-icon [providerType]="oneDrive"></mc-provider-icon>
          <div class="panel-padding-top">
          <span
            class="mc-detected">   {{data['one_drive']?.detectedScannedFiles  | number:'1.0':'en-US'}} </span>
            of
            <span
              class="mc-total-scanned-by-app"> {{data['one_drive']?.detectedScannedFiles  }} </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</mc-sfc-graph-layout>
