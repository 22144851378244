<mc-modal-header
  headerTitle="{{('$I18N_API_GATEWAY_DISPLAY_CREDENTIALS_MODAL.HEADER' | translate: { appName: appName })}}"
>
</mc-modal-header>

<mc-modal-body>
  <p class="mc-modal-recommendation-header">{{ '$I18N_API_GATEWAY_DISPLAY_CREDENTIALS_MODAL.MESSAGE' | translate }}</p>
  <mc-inline-notification  *ngIf="displayClientSecretDivAndWarning" [notification]="notification"></mc-inline-notification>
  <div class="mc-detailed-list-row panel-padding-bottom panel-padding-top">
    <div class="row">
      <div class="col-xs-2 mc-detailed-list-label">
        {{ '$I18N_API_GATEWAY_DISPLAY_CREDENTIALS_MODAL.FIELDS.CLIENT_ID' | translate }}
      </div>
      <div class="col-xs-8">
        <span>{{ showClientId ? clientId : '****** ******' }}</span>
      </div>
      <div class="col-xs-2">
        <a
          ngxClipboard
          class="pull-right cursor-pointer text-normal"
          [cbContent]="clientId"
          (cbOnSuccess)="keyCopied('$I18N_API_GATEWAY_DISPLAY_CREDENTIALS_MODAL.ACTIONS.CLIENT_ID_TO_CLIPBOARD')"
        >
          <em class="far fa-copy"></em>
        </a>
        <a
          class="pull-right cursor-pointer panel-half-padding-right text-normal"
          (click)="showClientId ? hideClientIdClicked() : showClientIdClicked()"
        >
          <em [className]=" showClientId ? 'far fa-eye-slash' : 'far fa-eye' "></em>
        </a>
      </div>
    </div>
  </div>

  <div *ngIf="displayClientSecretDivAndWarning" class="mc-detailed-list-row panel-padding-bottom">
    <div class="mc-detailed-list-column">
      <div class="row">
        <div class="col-xs-2 mc-detailed-list-label">
          {{ '$I18N_API_GATEWAY_DISPLAY_CREDENTIALS_MODAL.FIELDS.CLIENT_SECRET' | translate }}
        </div>
        <div class="col-xs-8">
          <span>{{ showClientSecret ? clientSecret :  '****** ******' }}</span>
        </div>
        <div class="col-xs-2">
          <a
            ngxClipboard
            class="pull-right cursor-pointer text-normal"
            [cbContent]="clientSecret"
            (cbOnSuccess)="keyCopied('$I18N_API_GATEWAY_DISPLAY_CREDENTIALS_MODAL.ACTIONS.CLIENT_SECRET_TO_CLIPBOARD')"
          >
            <em class="far fa-copy"></em>
          </a>
          <a
            class="pull-right cursor-pointer panel-half-padding-right text-normal"
            (click)="showClientSecret ? hideClientSecretClicked() : showClientSecretClicked()"
          >
            <em [className]="showClientSecret ? 'far fa-eye-slash' : 'far fa-eye'"></em>
          </a>
        </div>
      </div>
    </div>
  </div>
</mc-modal-body>

<mc-modal-footer>
  <mc-live-button
    typeClass="mc-btn btn btn-primary primary"
    content="{{ '$I18N_API_GATEWAY_DISPLAY_CREDENTIALS_MODAL.ACTIONS.CLOSE' | translate }}"
    (click)="close()"
  >
  </mc-live-button>
</mc-modal-footer>
