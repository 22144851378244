"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const swg_activity_report_container_component_1 = require("app-new/swg/activity-report/containers/list/swg-activity-report-container.component");
angular
    .module('swgActivityReport', [])
    .controller('SwgActivityReportController', [
    '$stateParams',
    '$scope',
    function ($stateParams, $scope) {
        $scope.stateParams = $stateParams;
        $scope.params = $stateParams.paramsObject;
    }
])
    .directive('mcSwgActivityReportContainer', static_1.downgradeComponent({
    component: swg_activity_report_container_component_1.SwgActivityReportContainerComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider.state('secure-web-gateway-activity-report', {
            url: '/secure-web-gateway/activity-report',
            params: {
                paramsObject: null
            },
            data: {
                capabilities: 'Permission.SWG_ACTIVITY_REPORT_READ',
                isFixedLayout: false,
                checkProgress: false,
                tabReload: false,
                tabTitle: 'Activity Report'
            },
            views: {
                main: {
                    template: '<mc-swg-activity-report-container [params]=params></mc-swg-activity-report-container>',
                    controller: 'SwgActivityReportController'
                }
            }
        });
    }
]);
