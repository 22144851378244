"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const static_1 = require("@angular/upgrade/static");
const detail_component_1 = require("./detail.component");
angular
    .module('sync-recover.onedrive.drive.details', [])
    .controller('OnedriveDriveDetailController', function () { })
    .directive('mcSyncRecoverOnedriveDriveDetail', static_1.downgradeComponent({
    component: detail_component_1.DetailComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider.state('onedrive-detail', {
            url: '/sync-recover/onedrive/drive',
            params: { drive: null },
            data: {
                capabilities: 'Development.Only && (Permission.ONEDRIVE_SYNC_READ || Permission.ONEDRIVE_SYNC_AND_RECOVER_READ)',
                isFixedLayout: false,
                checkProgress: false,
                tabId: 'sync-and-recover-one-drive-details',
                tabTitle: '$18N_SYNC_RECOVER_ONEDRIVE_DRIVES_DETAIL.TAB_TITLE',
                breadcrumbs: [
                    '$I18N_SYNC_AND_RECOVER_ONE_DRIVE.TAB_TITLE',
                    '$18N_SYNC_RECOVER_ONEDRIVE_DRIVES_DETAIL.BREADCRUMBS.1'
                ],
                tabReload: false
            },
            views: {
                main: {
                    template: '<mc-sync-recover-onedrive-drive-detail></mc-sync-recover-onedrive-drive-detail>',
                    controller: 'OnedriveDriveDetailController'
                },
                aside: {
                    templateUrl: 'services/services/backup/aside/aside.tpl.html'
                }
            },
            onEnter: [
                '$state',
                '$stateParams',
                function ($state, $stateParams) {
                    if (!$stateParams.drive) {
                        $state.go('onedrive-list', $stateParams);
                    }
                }
            ]
        });
    }
]);
