"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HeldQueueService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const moment = require("moment");
const components_1 = require("@mimecast-ui/components");
class HeldQueueService {
    constructor(http) {
        this.http = http;
        this.heldQueueSearchByReasonValuesSource = new rxjs_1.Subject();
        this.heldQueueSearchByGroupValuesSource = new rxjs_1.Subject();
        this.heldQueueSearchByReasonValues$ = this.heldQueueSearchByReasonValuesSource.asObservable();
        this.heldQueueSearchByGroupValues$ = this.heldQueueSearchByGroupValuesSource.asObservable();
    }
    getItems(request, pagination) {
        return this.http
            .post('/api/gateway/find-held-messages', this.transformToServerRequest(request, pagination))
            .pipe(operators_1.map(response => [response.first, response.meta]), operators_1.map(([first, meta]) => [
            first.heldEmails,
            Object.assign({}, meta['pagination'])
        ]), operators_1.map(([heldMessages, page]) => {
            return {
                items: this.transformToUIModel(heldMessages),
                page
            };
        }));
    }
    transformToServerRequest(request, pagination) {
        const { searchBy, start, end } = request, remainder = __rest(request, ["searchBy", "start", "end"]);
        const apiRequest = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, remainder), (!!searchBy ? { searchBy } : {})), (!!start ? { start: moment(request.start).format(components_1.API_DATE_FORMAT) } : {})), (!!end ? { end: moment(request.end).format(components_1.API_DATE_FORMAT) } : {})), { admin: true });
        if (!!pagination) {
            // @ts-ignore: api interceptor wants like this :(
            apiRequest.meta = { pagination };
        }
        return apiRequest;
    }
    transformToUIModel(heldMessages) {
        return heldMessages.map(message => {
            const { sent, received, heldSince } = message, remainder = __rest(message, ["sent", "received", "heldSince"]);
            return Object.assign(Object.assign({}, remainder), { sent: sent ? sent : undefined, received: received ? received : undefined, heldSince: heldSince ? heldSince : undefined });
        });
    }
    releaseEmails(emails) {
        return this.http
            .post('/api/message-finder/release-held-email', this.toMessageItems(emails))
            .pipe(operators_1.map(response => response.all));
    }
    releaseEmailsToSandbox(emails) {
        return this.http
            .post('/api/message-finder/release-held-email-to-sandbox', this.toMessageItems(emails))
            .pipe(operators_1.map(response => response.all));
    }
    permitSenders(emails) {
        return this.http
            .post('/api/message-finder/permit-sender-held-email', this.toMessageItems(emails))
            .pipe(operators_1.map(response => response.all));
    }
    rejectEmails(emails) {
        return this.http
            .post('/api/message-finder/reject-held-email', emails.map(email => ({ id: email.id, notify: false })))
            .pipe(operators_1.map(response => response.all));
    }
    rejectEmailsWithNotification(emails) {
        return this.http
            .post('/api/message-finder/reject-held-email', emails.map(email => ({ id: email.id, notify: true })))
            .pipe(operators_1.map(response => response.all));
    }
    rejectEmailsWithNarrative(emails) {
        return this.http
            .post('/api/message-finder/reject-held-email', this.toRejectNarrativeEmails(emails))
            .pipe(operators_1.map((response) => response.first));
    }
    blockSenders(emails) {
        return this.http
            .post('/api/message-finder/block-sender-held-email', this.toMessageItems(emails))
            .pipe(operators_1.map(response => response.all));
    }
    toMessageItems(messages) {
        return messages.map(message => ({ id: message.id }));
    }
    reportEmails(emails) {
        return this.http
            .post('/api/message-finder/report-message', this.toReportEmails(emails))
            .pipe(operators_1.map((response) => response.first));
    }
    toReportEmails(emails) {
        return emails.messages.map(email => {
            return {
                id: email.id,
                type: emails.reportAs,
                comment: emails.comment
            };
        });
    }
    toRejectNarrativeEmails(emails) {
        return emails.messages.map(email => {
            return {
                id: email.id,
                reason: emails.reason,
                notes: emails.notes,
                notify: emails.notify
            };
        });
    }
    heldQueueSearchByReason(heldReason) {
        this.heldQueueSearchByReasonValuesSource.next(heldReason);
    }
    heldQueueSearchByGroup(heldGroup) {
        this.heldQueueSearchByGroupValuesSource.next(heldGroup);
    }
}
exports.HeldQueueService = HeldQueueService;
