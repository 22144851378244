<div class="mc-cyber-graph-policy-block">
  <mc-wizard-step-header label="$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.STEP_TITLE">
  </mc-wizard-step-header>

  <form class="form-horizontal" mcDefaultLabelClass="col-sm-3" mcDefaultControlContainerClass="col-sm-9"
    [formGroup]="cyberGraphPolicyDetailsForm">

    <mc-text-field label="$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.LABELS.POLICY_NAME"
      errorPrefix="$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.VALIDATIONS.POLICY_NAME"
      placeholder="$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.PLACEHOLDERS.NAME" formControlName="name"
      data-unit-test="name" [setFocus]="true" required>
    </mc-text-field>
    <mc-text-area-field label="$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.LABELS.POLICY_DESCRIPTION"
      formControlName="description" data-unit-test="description"
      placeholder="$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.PLACEHOLDERS.DESCRIPTION">
    </mc-text-area-field>
    <div class="form-group mc-field">
      <label class="mc-cybergraph-detail-label col-sm-3">
        <span class="mc-label-wrapper">
          {{ '$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.LABELS.CYBERGRAPH_STATUS' | translate }}
        </span>
      </label>
      <div class="col-sm-9">
        <div>
          <label class="mc-cybergraph-option-val">
            <input
              class="mc-cybergraph-option-input"
              type="radio"
              name="codebreakerStatus"
              value="learning"
              formControlName="codebreakerStatus" />
            {{'$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.CYBERGRAPH_POLICY_PRODUCT_STATUS.LEARNING'| translate}}
          </label>
          <p class="mc-cybergraph-option-desc">{{'$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.DYNAMIC_BANNER_STATUS_DESCRIPTIONS.LEARNING' | translate}}</p>
        </div>
        <div>
          <label class="mc-cybergraph-option-val">
            <input
              class="mc-cybergraph-option-input"
              type="radio"
              name="codebreakerStatus"
              value="on"
              formControlName="codebreakerStatus" />
            {{'$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.CYBERGRAPH_POLICY_PRODUCT_STATUS.ON'| translate}}
          </label>
          <p class="mc-cybergraph-option-desc">{{'$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.DYNAMIC_BANNER_STATUS_DESCRIPTIONS.ON' | translate}}</p>
        </div>
        <div>
          <label class="mc-cybergraph-option-val">
            <input
              class="mc-cybergraph-option-input"
              type="radio"
              name="codebreakerStatus"
              value="off"
              formControlName="codebreakerStatus" />
            {{'$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.CYBERGRAPH_POLICY_PRODUCT_STATUS.OFF' | translate}}
          </label>
          <p class="mc-cybergraph-option-desc">{{'$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.DYNAMIC_BANNER_STATUS_DESCRIPTIONS.OFF' | translate}}</p>
        </div>
      </div>
  </div>
    <mc-field *ngIf="reportingSwitchAvailable()" label="{{'$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.LABELS.REPORTING_STATUS' | translate}}"
      popoverPlacement="top" class="mc-custom-label" data-unit-test="reportingStatus"
      helpPopoverContent="{{ '$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.TOOLTIPS.REPORTING_STATUS' | translate }}">
      <mc-switch formControlName="reportingStatus" role="switch" showLabel="true">
      </mc-switch>
    </mc-field>
    <!-- Silencer status -->
    <div class="form-group mc-field" *ngIf="trackerEnabled">
        <label class="mc-cybergraph-detail-label col-sm-3">
          <span class="mc-label-wrapper">
            {{ '$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.LABELS.SILENCER_STATUS' | translate }}
          </span>
        </label>
        <div class="col-sm-9">
          <div>
            <label class="mc-cybergraph-option-val">
              <input
                class="mc-cybergraph-option-input"
                type="radio"
                name="silencerStatus"
                value="on"
                formControlName="silencerStatus" />
              {{'$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.CYBERGRAPH_POLICY_PRODUCT_STATUS.ON'| translate}}
            </label>
            <p class="mc-cybergraph-option-desc">{{'$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.TRACKER_STATUS_DESCRIPTIONS.ON' | translate}}</p>
          </div>
          <div>
            <label class="mc-cybergraph-option-val">
              <input
                class="mc-cybergraph-option-input"
                type="radio"
                name="silencerStatus"
                value="off"
                formControlName="silencerStatus" />
              {{'$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.CYBERGRAPH_POLICY_PRODUCT_STATUS.OFF' | translate}}
            </label>
            <p class="mc-cybergraph-option-desc">{{'$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.TRACKER_STATUS_DESCRIPTIONS.OFF' | translate}}</p>
          </div>
        </div>
    </div>
    <mc-field label="$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.LABELS.POLICY_OVERRIDE"
    popoverPlacement="top" class="mc-custom-label" data-unit-test="policyOverride"
    helpPopoverContent="{{ '$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.TOOLTIPS.POLICY_OVERRIDE' | translate }}">
    <mc-switch formControlName="policyOverride" role="switch" showLabel="true">
    </mc-switch>
  </mc-field>
  </form>

</div>
