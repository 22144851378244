<mc-layout-list-extra-container
  keyTitle="$I18N_APPLICATION_SETTINGS_LIST.PAGE_HEADER"
  keyDescription="$I18N_APPLICATION_SETTINGS_LIST.PAGE_DESCRIPTION"
>
  <mc-help-container>
    <mc-help-link url="https://community.mimecast.com/docs/DOC-1214"></mc-help-link>
  </mc-help-container>

  <mc-body-container>
  </mc-body-container>
</mc-layout-list-extra-container>