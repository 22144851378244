"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateSafetyTipsListComponent = void 0;
const core_1 = require("@angular/core");
class TemplateSafetyTipsListComponent {
    constructor() {
        this.safetyTipMenuButtonId = 'safety-tip-menu-button';
        this.delete = new core_1.EventEmitter();
        this.edit = new core_1.EventEmitter();
        this.preview = new core_1.EventEmitter();
        this.save = new core_1.EventEmitter();
        this.cancel = new core_1.EventEmitter();
        this.showTipForm = false;
        this.block = false;
    }
    safetyTipClick(event, index, tip) {
        /* To prevent menu button click previewing tip */
        if (event.target.id !== this.safetyTipMenuButtonId) {
            if (!this.block) {
                this.preview.emit({ index, tip });
            }
        }
    }
}
exports.TemplateSafetyTipsListComponent = TemplateSafetyTipsListComponent;
