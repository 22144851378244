"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgActivityReportApiService = void 0;
const operators_1 = require("rxjs/operators");
class SwgActivityReportApiService {
    constructor(http) {
        this.http = http;
    }
    getCategoriesWithDeprecated() {
        return this.http
            .post('/api/policy/webcategoryfilter/get-categories', {
            includeDeprecated: true
        })
            .pipe(operators_1.map((response) => response.first.categories));
    }
    getApplicationsWithDeprecated() {
        return this.http
            .post('/api/policy/webapplicationcontrol/get-applications', {
            includeDeprecated: true
        })
            .pipe(operators_1.map((response) => response.first.applications));
    }
}
exports.SwgActivityReportApiService = SwgActivityReportApiService;
