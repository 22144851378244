"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isApiProcessingInProgress = exports.getMetadataProductsByChannelType = exports.isDeleting = exports.getWizardWorkflowType = exports.getMetadataProducts = exports.isChannelDetailsLoading = exports.getSiemChannel = exports.reducer = exports.initialState = void 0;
const sidebar_actions_1 = require("../../actions/siem-channels/sidebar.actions");
const list_actions_1 = require("../../actions/siem-channels/list.actions");
const channel_actions_1 = require("../../actions/siem-channels/channel.actions");
exports.initialState = {
    siemChannel: {
        uniqueId: '',
        name: '',
        description: '',
        url: '',
        type: '',
        lastModified: '',
        id: '',
        products: []
    },
    channelDetailsLoading: false,
    metadataProducts: [],
    wizardWorkflowType: '',
    isDeleting: false,
    metadataProductsByChannelType: [],
    apiProcessingInProgress: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case sidebar_actions_1.SiemChannelListSidebarActionsEnum.SIEM_CHANNEL_LIST_SIDEBAR_OPEN:
            return Object.assign({}, state);
        case channel_actions_1.SiemChannelActionsEnums.SIEM_LOAD_CHANNEL_DETAILS:
            return Object.assign(Object.assign({}, state), { channelDetailsLoading: true, siemChannel: action.payload });
        case channel_actions_1.SiemChannelActionsEnums.SIEM_LOAD_CHANNEL_DETAILS_SUCCESS:
            return Object.assign(Object.assign({}, state), { channelDetailsLoading: false, siemChannel: Object.assign(Object.assign({}, state.siemChannel), { products: action.payload }) });
        case channel_actions_1.SiemChannelActionsEnums.SIEM_LOAD_CHANNEL_DETAILS_FAILURE:
            return Object.assign(Object.assign({}, state), { channelDetailsLoading: false });
        case channel_actions_1.SiemChannelActionsEnums.SIEM_LOAD_PRODUCTS_FROM_METADATA_SUCCESS:
            return Object.assign(Object.assign({}, state), { metadataProducts: action.payload });
        case channel_actions_1.SiemChannelActionsEnums.SIEM_UPDATE_CHANNEL_DESCRIPTION:
            return Object.assign(Object.assign({}, state), { siemChannel: Object.assign(Object.assign({}, state.siemChannel), { name: action.payload.name, description: action.payload.description, products: action.payload.type !== state.siemChannel.type ? [] : state.siemChannel.products, type: action.payload.type }) });
        case channel_actions_1.SiemChannelActionsEnums.SIEM_LOAD_METADATA_PRODUCTS_BY_CHANNELTYPE:
            return Object.assign({}, state);
        case channel_actions_1.SiemChannelActionsEnums.SIEM_LOAD_METADATA_PRODUCTS_BY_CHANNELTYPE_SUCCESS:
            return Object.assign(Object.assign({}, state), { metadataProductsByChannelType: action.payload });
        case list_actions_1.SiemChannelListActionsEnums.SIEM_CHANNEL_DELETE:
            return Object.assign(Object.assign({}, state), { isDeleting: true });
        case list_actions_1.SiemChannelListActionsEnums.SIEM_CHANNEL_DELETE_SUCCESS:
            return Object.assign(Object.assign({}, state), { isDeleting: false });
        case list_actions_1.SiemChannelListActionsEnums.SIEM_CHANNEL_DELETE_FAILURE:
            return Object.assign(Object.assign({}, state), { isDeleting: false });
        case list_actions_1.SiemChannelListActionsEnums.SIEM_CHANNEL_DELETE_CONFIRMATION_MODAL_OPEN:
            return Object.assign(Object.assign({}, state), { siemChannel: Object.assign({}, action.payload) });
        case list_actions_1.SiemChannelListActionsEnums.SIEM_CHANNEL_DELETE_CONFIRMATION_MODAL_CLOSE:
            return Object.assign({}, state);
        case channel_actions_1.SiemChannelActionsEnums.SIEM_CLEAR_CHANNEL:
            return Object.assign(Object.assign({}, exports.initialState), { metadataProducts: state.metadataProducts });
        case channel_actions_1.SiemChannelActionsEnums.SIEM_CHANNEL_NAVIGATE_TO_WIZARD:
            return Object.assign(Object.assign({}, state), { wizardWorkflowType: action.payload });
        case channel_actions_1.SiemChannelActionsEnums.SIEM_UPDATE_CHANNEL_PRODUCTS:
            return Object.assign(Object.assign({}, state), { siemChannel: Object.assign(Object.assign({}, state.siemChannel), { products: action.payload }) });
        case channel_actions_1.SiemChannelActionsEnums.SIEM_UPDATE_CHANNEL_PRODUCT_EVENTS:
            const newProducts = state.siemChannel.products.filter(p => p.name !== action.payload.name);
            newProducts.push(Object.assign(Object.assign({}, action.payload), { enabled: true }));
            return Object.assign(Object.assign({}, state), { siemChannel: Object.assign(Object.assign({}, state.siemChannel), { products: newProducts }) });
        case channel_actions_1.SiemChannelActionsEnums.SIEM_CREATE_CHANNEL:
            return Object.assign(Object.assign({}, state), { apiProcessingInProgress: true });
        case channel_actions_1.SiemChannelActionsEnums.SIEM_CREATE_CHANNEL_SUCCESS:
            return Object.assign(Object.assign({}, state), { apiProcessingInProgress: false });
        case channel_actions_1.SiemChannelActionsEnums.SIEM_CREATE_CHANNEL_FAILURE:
            return Object.assign(Object.assign({}, state), { apiProcessingInProgress: false });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getSiemChannel = (state) => state.siemChannel;
exports.getSiemChannel = getSiemChannel;
const isChannelDetailsLoading = (state) => state.channelDetailsLoading;
exports.isChannelDetailsLoading = isChannelDetailsLoading;
const getMetadataProducts = (state) => state.metadataProducts;
exports.getMetadataProducts = getMetadataProducts;
const getWizardWorkflowType = (state) => state.wizardWorkflowType;
exports.getWizardWorkflowType = getWizardWorkflowType;
const isDeleting = (state) => state.isDeleting;
exports.isDeleting = isDeleting;
const getMetadataProductsByChannelType = (state) => state.metadataProductsByChannelType;
exports.getMetadataProductsByChannelType = getMetadataProductsByChannelType;
const isApiProcessingInProgress = (state) => state.apiProcessingInProgress;
exports.isApiProcessingInProgress = isApiProcessingInProgress;
