"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ6 = exports.ɵ5 = exports.ɵ4 = exports.ɵ3 = exports.ɵ2 = exports.ɵ1 = exports.ɵ0 = exports.getIsUpdateDisabled = exports.getUpdatedApp = exports.getUpdateAppApiProcessing = exports.getAppDetailsAsidePanelMode = exports.getSelectedAppFromTable = exports.getAppDetailsIsLoading = exports.getAppDetailsState = exports.getIsAddAppDisabled = exports.getIsAddAppApiProcessing = exports.getIsDeleteAppApiProcessing = exports.getAddedApp = exports.getSelectedApp = exports.getAppsState = exports.getPolicyDetailsPolicy = exports.getPolicyDetailsPolicyId = exports.getPolicyDetailsIsLoading = exports.getPolicyDetailsState = exports.getNotificationAction = exports.getSelectedPolicy = exports.getPoliciesList = exports.getPoliciesState = exports.getApiIsProcessingDelete = exports.getDeletedPolicy = exports.getIdentityState = exports.reducers = void 0;
const policiesReducer = require("./policies.reducer");
const policyDetailsReducer = require("./policy-details.reducer");
const appsReducer = require("./apps/apps.reducer");
const appDetailsReducer = require("./apps/app-details.reducer");
const store_1 = require("@ngrx/store");
exports.reducers = {
    policies: policiesReducer.reducer,
    policyDetails: policyDetailsReducer.reducer,
    apps: appsReducer.reducer,
    appDetails: appDetailsReducer.reducer
};
exports.getIdentityState = store_1.createFeatureSelector('identity');
const ɵ0 = (state) => state.policies.deletedPolicy;
exports.ɵ0 = ɵ0;
exports.getDeletedPolicy = store_1.createSelector(exports.getIdentityState, ɵ0);
const ɵ1 = (state) => state.policies.apiProcessingDeleteInProgress;
exports.ɵ1 = ɵ1;
exports.getApiIsProcessingDelete = store_1.createSelector(exports.getIdentityState, ɵ1);
const ɵ2 = (state) => state.policies;
exports.ɵ2 = ɵ2;
exports.getPoliciesState = store_1.createSelector(exports.getIdentityState, ɵ2);
exports.getPoliciesList = store_1.createSelector(exports.getPoliciesState, 
// @ts-ignore
policiesReducer.getPoliciesList);
exports.getSelectedPolicy = store_1.createSelector(exports.getPoliciesState, policiesReducer.getSelectedPolicy);
exports.getNotificationAction = store_1.createSelector(exports.getPoliciesState, policiesReducer.getNotificationAction);
const ɵ3 = (state) => state.policyDetails;
exports.ɵ3 = ɵ3;
exports.getPolicyDetailsState = store_1.createSelector(exports.getIdentityState, ɵ3);
exports.getPolicyDetailsIsLoading = store_1.createSelector(exports.getPolicyDetailsState, policyDetailsReducer.isLoading);
exports.getPolicyDetailsPolicyId = store_1.createSelector(exports.getPolicyDetailsState, policyDetailsReducer.getPolicyId);
exports.getPolicyDetailsPolicy = store_1.createSelector(exports.getPolicyDetailsState, policyDetailsReducer.getPolicy);
const ɵ4 = (state) => state.apps;
exports.ɵ4 = ɵ4;
////// selectors related to the Apps tab
exports.getAppsState = store_1.createSelector(exports.getIdentityState, ɵ4);
exports.getSelectedApp = store_1.createSelector(exports.getAppsState, appsReducer.getSelectedApp);
exports.getAddedApp = store_1.createSelector(exports.getAppsState, appsReducer.getAddedApp);
const ɵ5 = (state) => state.apps.isDeleteAppApiProcessing;
exports.ɵ5 = ɵ5;
exports.getIsDeleteAppApiProcessing = store_1.createSelector(exports.getIdentityState, ɵ5);
exports.getIsAddAppApiProcessing = store_1.createSelector(exports.getAppsState, appsReducer.getIsAddAppApiProcessing);
exports.getIsAddAppDisabled = store_1.createSelector(exports.getAppsState, appsReducer.getIsAddAppDisabled);
const ɵ6 = (state) => state.appDetails;
exports.ɵ6 = ɵ6;
////// selectors related to the App Details panel
exports.getAppDetailsState = store_1.createSelector(exports.getIdentityState, ɵ6);
exports.getAppDetailsIsLoading = store_1.createSelector(exports.getAppDetailsState, appDetailsReducer.getAppDetailsPanelIsLoading);
exports.getSelectedAppFromTable = store_1.createSelector(exports.getAppDetailsState, appDetailsReducer.getSelectedAppFromTable);
exports.getAppDetailsAsidePanelMode = store_1.createSelector(exports.getAppDetailsState, appDetailsReducer.getAppDetailsAsidePanelMode);
exports.getUpdateAppApiProcessing = store_1.createSelector(exports.getAppDetailsState, appDetailsReducer.getUpdateAppApiProcessing);
exports.getUpdatedApp = store_1.createSelector(exports.getAppDetailsState, appDetailsReducer.getUpdatedApp);
exports.getIsUpdateDisabled = store_1.createSelector(exports.getAppDetailsState, appDetailsReducer.getIsUpdateDisabled);
