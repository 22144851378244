"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageInfoPanelComponent = void 0;
const store_1 = require("@ngrx/store");
const user_actions_1 = require("app-new/core/actions/user.actions");
const coreReducers = require("app-new/core/reducers");
const operators_1 = require("rxjs/operators");
const actions = require("../../actions/");
const reducers = require("../../reducers");
class MessageInfoPanelComponent {
    constructor(store, messageType, overlayRef) {
        this.store = store;
        this.messageType = messageType;
        this.overlayRef = overlayRef;
        this.canShowDetailsTabOnly = false;
    }
    ngOnInit() {
        this.paginatorData$ = this.store.select(reducers.getPreviousAndNextSelectedRow);
        this.messageInfo$ = this.store.select(reducers.getMessageInfo);
        this.selectedMessage$ = this.store.select(reducers.getSelectedMessage);
        this.selectedDeliveryMessage$ = this.store.select(reducers.getSelectedDeliveryMessage);
        this.selectedEmail$ = this.store.select(reducers.getSelectedEmail);
        this.isSidePanelLoading$ = this.store.select(reducers.isSidePanelLoading);
        this.userAccount$ = this.store.select(coreReducers.getAccount);
        this.trackingAdminPreferences$ = this.store.select(coreReducers.getAdminPreferences).pipe(operators_1.map((allAdminPreferences) => {
            return (allAdminPreferences && allAdminPreferences.trackingPage) || new Object();
        }));
        this.forceLoadContent$ = this.store.select(reducers.getForceLoadContent);
        // Show only details tab for deffered, rejected message status
        this.messageInfo$.subscribe(response => {
            if (response) {
                this.canShowDetailsTabOnly = this.showOnlyDetailsTab(response);
            }
        });
    }
    closeAside() {
        this.overlayRef.close();
    }
    isValidMessage(msgInfo) {
        return !!msgInfo && Object.keys(msgInfo).length > 0;
    }
    dismissForever(allTrackingOptions) {
        this.store.dispatch(new user_actions_1.PreferencesSaveAction({
            trackingPage: Object.assign(Object.assign({}, allTrackingOptions), { notificationDetailsDismissForever: true })
        }));
    }
    selectRow(row) {
        this.store.dispatch(new actions.GetMessageInfoAction(row));
        this.store.dispatch(new actions.HighlightSelectedRowAction(row));
    }
    reportAs({ reportType, id }) {
        this.store.dispatch(new actions.ReportMessageOpenModalAction(reportType, id));
    }
    showInfoInTabs(messageInfo) {
        const showInfoInTabsValidStatuses = [
            'accepted',
            'archived',
            'bounced',
            'delivery',
            'bulk_delivery',
            'held',
            'processing',
            'bulk_processing',
            'deferred',
            'rejected'
        ];
        return showInfoInTabsValidStatuses.indexOf(messageInfo.status) !== -1;
    }
    showOnlyDetailsTab(messageInfo) {
        const showOnlyDetailsTabValidStatuses = ['deferred', 'rejected'];
        return showOnlyDetailsTabValidStatuses.indexOf(messageInfo.status) !== -1;
    }
    showDetails(messageInfo) {
        const showDetailsValidStatuses = [
            'bounced',
            'deferred',
            'delivery',
            'bulk_delivery',
            'held',
            'processing',
            'bulk_processing',
            'rejected'
        ];
        return showDetailsValidStatuses.indexOf(messageInfo.status) !== -1;
    }
    showPolicyTab(messageInfo) {
        const validStatusesForPolicyTab = [
            'accepted',
            'archived',
            'bounced',
            'held'
        ];
        return validStatusesForPolicyTab.indexOf(messageInfo.status) !== -1;
    }
    showAnalysisTab(messageInfo) {
        const validStatusesForAnalysisTab = [
            'accepted',
            'archived',
            'bounced',
            'held',
            'rejected',
            'deferred'
        ];
        return validStatusesForAnalysisTab.indexOf(messageInfo.status) !== -1;
    }
    earlyBounce() {
        this.store.dispatch(new actions.BounceQueuedEmailAction());
    }
    recalculateDeliveryRoute() {
        this.store.dispatch(new actions.RecalculateDeliveryRouteAction());
    }
    selectEmail(event) {
        this.store.dispatch(new actions.SelectEmailAction(event.email, event.isDelivery ? 'delivery' : 'receipt'));
    }
    release() {
        this.store.dispatch(new actions.ReleaseHeldEmailAction());
    }
    releaseToSandbox() {
        this.store.dispatch(new actions.ReleaseHeldEmailToSandboxAction());
    }
    permitForRecipient(messageInfo) {
        if (messageInfo.status === 'held') {
            this.store.dispatch(new actions.PermitSenderHeldEmailAction());
        }
        else if (messageInfo.status === 'rejected' || messageInfo.status === 'deferred') {
            this.store.dispatch(new actions.PermitSenderRejectedEmailAction());
        }
        else if (messageInfo.status === 'accepted' || messageInfo.status === 'archived') {
            this.store.dispatch(new actions.PermitSenderAction());
        }
    }
    reject() {
        this.store.dispatch(new actions.RejectHeldEmailAction());
    }
    rejectNotifySender() {
        this.store.dispatch(new actions.RejectHeldEmailWithNotificationAction('against_email_policies'));
    }
    rejectWithNarrative() {
        this.store.dispatch(new actions.RejectHeldEmailWithNarrativeAction());
    }
    blockForRecipient(messageInfo) {
        if (messageInfo.status === 'held') {
            this.store.dispatch(new actions.BlockSenderHeldEmailAction());
        }
        else if (messageInfo.status === 'rejected' || messageInfo.status === 'deferred') {
            this.store.dispatch(new actions.BlockSenderRejectedEmailAction());
        }
        else if (messageInfo.status === 'accepted' || messageInfo.status === 'archived') {
            this.store.dispatch(new actions.BlockSenderAction());
        }
    }
    printRecipient(messageInfo) {
        this.messageComponent.getPrintableContent().subscribe(data => {
            const printMessage = { body: data, messageInfo };
            this.store.dispatch(new actions.PrintMessageAction(printMessage));
        });
    }
    retry() {
        this.store.dispatch(new actions.RetryDeliveryAction());
    }
    rejectQueued() {
        this.store.dispatch(new actions.RejectQueuedEmailAction());
    }
    downloadFile(id) {
        this.store.dispatch(new actions.DownloadFileAction(id));
    }
    copiedToClipboard() {
        this.store.dispatch(new actions.CopyToClipboardSuccessAction());
    }
    loadContentRequest(id) {
        const row = { id };
        this.store.dispatch(new actions.GetMessageInfoAction(row, true));
    }
    compareViews(on) {
        this.store.dispatch(new actions.CompareViewsAction(on));
    }
    forward() {
        this.store
            .pipe(store_1.select(reducers.getSelectedForwardingMessage), operators_1.take(1))
            .subscribe(fwd => this.store.dispatch(new actions.ForwardMessageOpenModalAction([fwd])));
    }
}
exports.MessageInfoPanelComponent = MessageInfoPanelComponent;
