"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const list = require("../actions/list");
class ListEffects {
    constructor(actions$, listService, translate) {
        this.actions$ = actions$;
        this.listService = listService;
        this.translate = translate;
        this.delete$ = this.actions$.pipe(effects_1.ofType(list.DELETE), operators_1.map((action) => action.payload), operators_1.switchMap((template) => {
            return this.listService.deleteTemplate(template.id).pipe(operators_1.map(() => new list.LoadAction()), operators_1.catchError((error) => {
                /**
                 * This will be deleted in the future when mc-notification is finished!!!
                 */
                const firstError = error.firstFail.errors && error.firstFail.errors[0];
                const messageToShow = firstError.code === 'err_template_not_deleted'
                    ? this.translate.instant(`$I18N_AWARENESS_LIST_EFFECT.${firstError.code}`)
                    : firstError.message;
                window.alert(messageToShow);
                return rxjs_1.of(new list.DeleteFailAction());
            }));
        }));
        this.loadList$ = this.actions$.pipe(effects_1.ofType(list.LOAD), operators_1.switchMap(() => {
            return this.listService.getTemplates().pipe(operators_1.map((listContent) => {
                return new list.LoadSuccessAction(listContent);
            }), operators_1.catchError(() => rxjs_1.of(new list.LoadFailAction())));
        }));
    }
}
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "delete$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "loadList$", void 0);
exports.ListEffects = ListEffects;
