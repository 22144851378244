"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IframeLogout = exports.OpenAsidePanel = exports.AdconActionMessage = exports.SwitchAccountMessage = exports.IFrameBodyClickMessage = exports.OpenTabMessage = exports.ADCON_ACTION = exports.IFRAME_LOGOUT = exports.OPEN_PANEL = exports.SWITCH_ACCOUNT = exports.IFRAME_BODY_CLICK = exports.OPEN_TAB = void 0;
exports.OPEN_TAB = 'adcon-action-open-tab';
exports.IFRAME_BODY_CLICK = 'adcon3.5-unhandled-click';
exports.SWITCH_ACCOUNT = 'switch-account';
exports.OPEN_PANEL = 'open-panel';
exports.IFRAME_LOGOUT = 'logout-from-shell-app';
/**
 * this action represent errors messages from adcon 3.5
 *
 * @type {string}
 */
exports.ADCON_ACTION = 'adcon-action';
class OpenTabMessage {
    constructor(data) {
        this.data = data;
        this.name = exports.OPEN_TAB;
    }
}
exports.OpenTabMessage = OpenTabMessage;
class IFrameBodyClickMessage {
    constructor() {
        this.name = exports.IFRAME_BODY_CLICK;
    }
}
exports.IFrameBodyClickMessage = IFrameBodyClickMessage;
class SwitchAccountMessage {
    constructor(data) {
        this.data = data;
        this.name = exports.SWITCH_ACCOUNT;
    }
}
exports.SwitchAccountMessage = SwitchAccountMessage;
class AdconActionMessage {
    constructor(data) {
        this.data = data;
        this.name = exports.ADCON_ACTION;
    }
}
exports.AdconActionMessage = AdconActionMessage;
class OpenAsidePanel {
    constructor(data) {
        this.data = data;
        this.name = exports.OPEN_PANEL;
    }
}
exports.OpenAsidePanel = OpenAsidePanel;
class IframeLogout {
    constructor(data) {
        this.data = data;
        this.name = exports.IFRAME_LOGOUT;
    }
}
exports.IframeLogout = IframeLogout;
