"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiIntegrationCrowdstrikeWizardModule = void 0;
const activation_1 = require("./steps/activation");
const crowdstrike_wizard_component_1 = require("./crowdstrike-wizard.component");
const notifications_1 = require("./steps/notifications");
const summary_1 = require("./steps/summary");
const outbound_1 = require("./steps/outbound");
const inbound_1 = require("./steps/inbound");
const COMPONENTS = [
    activation_1.ApiIntegrationCrowdstrikeActivationStepComponent,
    crowdstrike_wizard_component_1.ApiIntegrationCrowdstrikeWizardComponent,
    summary_1.ApiIntegrationCrowdstrikeSummaryStepComponent,
    outbound_1.ApiIntegrationCrowdstrikeOutboundStepComponent,
    inbound_1.ApiIntegrationCrowdstrikeInboundStepComponent,
    notifications_1.ApiIntegrationCrowdstrikeNotificationsStepComponent
];
class ApiIntegrationCrowdstrikeWizardModule {
}
exports.ApiIntegrationCrowdstrikeWizardModule = ApiIntegrationCrowdstrikeWizardModule;
