"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreferencesModalService = void 0;
const preferences_modal_component_1 = require("./preferences-modal.component");
class PreferencesModalService {
    constructor(modalService) {
        this.modalService = modalService;
    }
    open() {
        this.currentModalInstance = this.modalService.open(preferences_modal_component_1.PreferencesModalComponent, {
            size: 'md'
        });
        return this.currentModalInstance.afterClose();
    }
    close(message) {
        if (this.currentModalInstance) {
            this.currentModalInstance.close(message);
        }
        this.currentModalInstance = null;
    }
}
exports.PreferencesModalService = PreferencesModalService;
