<mc-layout-aside-extra-container
  keyTitle="{{ '$I18N_ARCHIVE_SUPERVISION.LEXICON.TITLE' | translate }}"
  showClose="true"
  [isLoading]="false"
  (closeAside)="onClose()"
>
  <mc-extra-container>
    <div class="mc-container-buttons-area">
      <button class="btn btn-primary" [disabled]="!hasEditPermissions" (click)="edit()">
        {{ '$I18N_ARCHIVE_SUPERVISION.LEXICON.CONTROLS.EDIT' | translate }}
      </button>
      <button class="btn btn-secondary" (click)="onClose()">
        {{ '$I18N_ARCHIVE_SUPERVISION.LEXICON.CONTROLS.CANCEL' | translate }}
      </button>
      <button data-test="delete-button" *ngIf="hasDeleteCapability" class="btn btn-secondary" (click)="deleteLexicon(lexicon)">
        {{ '$I18N_ARCHIVE_SUPERVISION.LEXICON.CONTROLS.DELETE' | translate }}
      </button>
      <mc-paginator
        [isLoading]="loading"
        [previousToken]="paginationInfo && paginationInfo.previousEntity"
        [nextToken]="paginationInfo && paginationInfo.nextEntity"
        (paginate)="loadLexiconDetails($event)"
      >
      </mc-paginator>
    </div>
  </mc-extra-container>
  <mc-body-container>
    <mc-loader-full-container
      [isLoading]="loading"
      class="mc-layout-loader-full-container-aside mc-flex-scroll-vertical"
    >
      <div *ngIf="loading" style="height: 100px;"></div>
    </mc-loader-full-container>
    <div *ngIf="!loading && lexicon !== undefined">
      <mc-collapsable-panel
        header="{{ '$I18N_ARCHIVE_SUPERVISION.LEXICON.DETAILS.TITLE' | translate }}"
        [isExpanded]="true"
      >
        <div class="mc-detailed-list mc-detailed-list-column">
          <div class="row mc-detailed-list-row">
            <div class="col-xs-3 mc-detailed-list-label">
              {{ '$I18N_ARCHIVE_SUPERVISION.LEXICON.DETAILS.NAME' | translate }}
            </div>
            <div class="mc-detailed-list-column">{{ lexicon.name }}</div>
          </div>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-3 mc-detailed-list-label">
              {{ '$I18N_ARCHIVE_SUPERVISION.LEXICON.DETAILS.DESCRIPTION' | translate }}
            </div>
            <div class="mc-detailed-list-column">{{ lexicon.description }}</div>
          </div>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-3 mc-detailed-list-label">
              {{ '$I18N_ARCHIVE_SUPERVISION.LEXICON.DETAILS.VERSION' | translate }}
            </div>
            <div class="mc-detailed-list-column">{{ lexicon.versions }}</div>
          </div>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-3 mc-detailed-list-label">
              {{ '$I18N_ARCHIVE_SUPERVISION.LEXICON.DETAILS.CREATED_BY' | translate }}
            </div>
            <div class="mc-detailed-list-column">{{ lexicon.createdBy }}</div>
          </div>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-3 mc-detailed-list-label">
              {{ '$I18N_ARCHIVE_SUPERVISION.LEXICON.DETAILS.CREATED_DATE' | translate }}
            </div>
            <div class="mc-detailed-list-column">{{ lexicon.created | mcDate }}</div>
          </div>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-3 mc-detailed-list-label">
              {{ '$I18N_ARCHIVE_SUPERVISION.LEXICON.DETAILS.MODIFIED_DATE' | translate }}
            </div>
            <div class="mc-detailed-list-column">{{ lexicon.updated | mcDate }}</div>
          </div>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-3 mc-detailed-list-label">
              {{ '$I18N_ARCHIVE_SUPERVISION.LEXICON.DETAILS.MODIFIED_BY' | translate }}
            </div>
            <div class="mc-detailed-list-column">{{ lexicon.updatedBy }}</div>
          </div>
        </div>
      </mc-collapsable-panel>

      <mc-collapsable-panel
        header="{{ '$I18N_ARCHIVE_SUPERVISION.LEXICON.LINKED_RULES.TITLE' | translate }}"
        [isExpanded]="true"
      >
        <div class="mc-detailed-list mc-detailed-list-column">
          <div class="row mc-detailed-list-row">
            <div class="col-xs-3 mc-detailed-list-label">
              {{ '$I18N_ARCHIVE_SUPERVISION.LEXICON.LINKED_RULES.RULES' | translate }}
            </div>
            <div class="mc-detailed-list-column">
              <div *ngFor="let rule of associatedRules">{{ rule.name }}</div>
            </div>
          </div>
        </div>
      </mc-collapsable-panel>

      <mc-collapsable-panel
        header="{{ '$I18N_ARCHIVE_SUPERVISION.LEXICON.CONTENT.TITLE' | translate }}"
        [isExpanded]="true"
      >
        <div class="mc-detailed-list mc-detailed-list-column">
          <div class="row mc-detailed-list-row">
            <div class="col-xs-3 mc-detailed-list-label">
              {{ '$I18N_ARCHIVE_SUPERVISION.LEXICON.CONTENT.TYPE.LABEL' | translate }}
            </div>
            <div class="mc-detailed-list-column">
              {{
                '$I18N_ARCHIVE_SUPERVISION.LEXICON.CONTENT.TYPE.' +
                lexicon.searchType | uppercase | translate
              }}
            </div>
          </div>

          <ng-container *ngIf="canViewWordsAndPhrases()">
          <div class="row mc-detailed-list-row">
            <div class="col-xs-3 mc-detailed-list-label">
              {{ '$I18N_ARCHIVE_SUPERVISION.LEXICON.CONTENT.WORDS' | translate }}
            </div>
            <div class="mc-detailed-list-column">
              <div *ngFor="let word of words">{{ word }}</div>
            </div>
          </div>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-3 mc-detailed-list-label">
              {{ '$I18N_ARCHIVE_SUPERVISION.LEXICON.CONTENT.PHRASES' | translate }}
            </div>
            <div class="mc-detailed-list-column">
              <div *ngFor="let phrase of phrases">{{ phrase }}</div>
            </div>
          </div>
         </ng-container>

          <div class="row mc-detailed-list-row" *ngIf="canViewQuery()">
            <div class="col-xs-3 mc-detailed-list-label">
              {{ '$I18N_ARCHIVE_SUPERVISION.LEXICON.CONTENT.QUERY' | translate }}
            </div>
            <div class="mc-detailed-list-column">
              <div>{{ lexicon.searchParams.query }}</div>
            </div>
          </div>
        </div>
      </mc-collapsable-panel>
    </div>
  </mc-body-container>
</mc-layout-aside-extra-container>
