"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LexiconSearchParameters = void 0;
class LexiconSearchParameters {
    constructor(words, phrases, query, searchType) {
        this.words = words;
        this.phrases = phrases;
        this.query = query;
        this.searchType = searchType;
    }
}
exports.LexiconSearchParameters = LexiconSearchParameters;
