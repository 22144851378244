"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SimpleMessageDetailComponent = void 0;
class SimpleMessageDetailComponent {
    constructor() {
        this.MAX_ONE_LINE_LENGTH = 50;
        this.showMore = false;
    }
    isOverOneLine(value) {
        return value.length > this.MAX_ONE_LINE_LENGTH;
    }
    toggle() {
        this.showMore = !this.showMore;
    }
    renderEmails(emails) {
        if (!emails.match(',') || !this.isOverOneLine(emails) || this.showMore) {
            return emails;
        }
        let emailAddreses = '';
        emails.split(', ').forEach((email, index) => {
            if (index === 0) {
                emailAddreses = email;
            }
            else if (emailAddreses.length + email.length <= this.MAX_ONE_LINE_LENGTH) {
                emailAddreses += ', ' + email;
            }
        });
        return emailAddreses;
    }
}
exports.SimpleMessageDetailComponent = SimpleMessageDetailComponent;
