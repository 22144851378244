"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.searchFilters = exports.tableColumnsToIgnore = exports.tableColumnsAlwaysVisible = exports.exportTableColumns = exports.tableColumns = exports.tableSelectColumn = void 0;
exports.tableSelectColumn = 'select';
exports.tableColumns = [
    'senderAddress',
    'recipientAddress',
    'subject',
    'status',
    'expiry',
    'dateSent',
    'right-column'
];
exports.exportTableColumns = ['from', 'to', 'subject', 'status', 'expiryDate', 'dateTime'];
exports.tableColumnsAlwaysVisible = ['senderAddress', 'recipientAddress'];
exports.tableColumnsToIgnore = ['id'];
exports.searchFilters = [
    { name: 'type', filters: ['recalledOnly'] }
];
