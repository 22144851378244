"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasGenericError = exports.isTableLoading = exports.getIncidentsPreview = exports.reducer = exports.initialState = void 0;
const incidentsAction = require("../../actions/incidents/incidents.action");
exports.initialState = {
    incidents: [],
    isLoading: false,
    genericError: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case incidentsAction.GET_ALL_INCIDENTS:
            return Object.assign(Object.assign({}, state), { genericError: false, isLoading: true });
        case incidentsAction.GET_ALL_INCIDENTS_SUCCESS:
            return Object.assign(Object.assign({}, state), { incidents: action.payload, genericError: false, isLoading: false });
        case incidentsAction.GET_ALL_INCIDENTS_FAILURE:
            return Object.assign(Object.assign({}, state), { isLoading: false, genericError: true });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getIncidentsPreview = (state) => {
    return state.incidents.slice(0, 5);
};
exports.getIncidentsPreview = getIncidentsPreview;
const isTableLoading = (state) => state.isLoading;
exports.isTableLoading = isTableLoading;
const hasGenericError = (state) => state.genericError;
exports.hasGenericError = hasGenericError;
