"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterSectionsWithMonthlySchedule = exports.filterSections = exports.columnsStartHidden = exports.columns = void 0;
exports.columns = [
    'name',
    'createdBy',
    'created',
    'updated',
    'lastExecuted',
    'status',
    'scheduleType',
    'searchGroup',
    'lexicon',
    'reviewQueue',
    'state',
    'columnActions'
];
exports.columnsStartHidden = ['created', 'searchGroup', 'lexicon', 'reviewQueue'];
exports.filterSections = [
    {
        name: 'status',
        filters: ['error', 'successful', 'warning', 'queue_full']
    },
    {
        name: 'scheduleType',
        filters: ['daily', 'weekly']
    }
];
exports.filterSectionsWithMonthlySchedule = [
    {
        name: 'status',
        filters: ['error', 'successful', 'warning', 'queue_full']
    },
    {
        name: 'scheduleType',
        filters: ['daily', 'weekly', 'monthly']
    }
];
