"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PageReloadAction = exports.AlreadyLoggedOutAction = exports.FirstTimeLoadAction = exports.UnloadAction = exports.LoadFailAction = exports.LoadSuccessAction = exports.LoadAction = exports.LogoutAction = exports.LogoutSuccessAction = exports.LogoutFailAction = exports.InvalidAccessAction = exports.PAGE_RELOAD = exports.INVALID_ACCESS = exports.ALREADY_LOGGED_OUT = exports.LOGOUT_FAIL = exports.LOGOUT_SUCCESS = exports.LOGOUT = exports.UNLOAD = exports.LOAD_FAIL = exports.LOAD_SUCCESS = exports.LOAD = exports.FIRST_TIME_LOAD = void 0;
exports.FIRST_TIME_LOAD = '[Core] First Time App Load';
exports.LOAD = '[App] Load';
exports.LOAD_SUCCESS = '[App] Load Success';
exports.LOAD_FAIL = '[App] Load Fail';
exports.UNLOAD = '[App] Unload';
exports.LOGOUT = '[App] Logout';
exports.LOGOUT_SUCCESS = '[App] Logout Success';
exports.LOGOUT_FAIL = '[App] Logout Fail';
exports.ALREADY_LOGGED_OUT = '[App] Logged Out';
exports.INVALID_ACCESS = '[App] Invalid Access';
exports.PAGE_RELOAD = '[App] Page Reload';
class InvalidAccessAction {
    constructor() {
        this.type = exports.INVALID_ACCESS;
    }
}
exports.InvalidAccessAction = InvalidAccessAction;
class LogoutFailAction {
    constructor() {
        this.type = exports.LOGOUT_FAIL;
    }
}
exports.LogoutFailAction = LogoutFailAction;
class LogoutSuccessAction {
    constructor() {
        this.type = exports.LOGOUT_SUCCESS;
    }
}
exports.LogoutSuccessAction = LogoutSuccessAction;
class LogoutAction {
    constructor() {
        this.type = exports.LOGOUT;
    }
}
exports.LogoutAction = LogoutAction;
class LoadAction {
    constructor() {
        this.type = exports.LOAD;
    }
}
exports.LoadAction = LoadAction;
class LoadSuccessAction {
    constructor() {
        this.type = exports.LOAD_SUCCESS;
    }
}
exports.LoadSuccessAction = LoadSuccessAction;
class LoadFailAction {
    constructor() {
        this.type = exports.LOAD_FAIL;
    }
}
exports.LoadFailAction = LoadFailAction;
class UnloadAction {
    constructor() {
        this.type = exports.UNLOAD;
    }
}
exports.UnloadAction = UnloadAction;
class FirstTimeLoadAction {
    constructor() {
        this.type = exports.FIRST_TIME_LOAD;
    }
}
exports.FirstTimeLoadAction = FirstTimeLoadAction;
class AlreadyLoggedOutAction {
    constructor() {
        this.type = exports.ALREADY_LOGGED_OUT;
    }
}
exports.AlreadyLoggedOutAction = AlreadyLoggedOutAction;
class PageReloadAction {
    constructor() {
        this.type = exports.PAGE_RELOAD;
    }
}
exports.PageReloadAction = PageReloadAction;
