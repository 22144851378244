"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiIntegrationWildfireWizardModule = void 0;
const activation_1 = require("./steps/activation");
const indicators_1 = require("./steps/indicators");
const remediation_1 = require("./steps/remediation");
const notifications_1 = require("./steps/notifications");
const summary_1 = require("./steps/summary");
const wildfire_wizard_component_1 = require("./wildfire-wizard.component");
const disclaimer_step_component_1 = require("./steps/disclaimer/disclaimer-step.component");
const COMPONENTS = [
    activation_1.ApiIntegrationWildfireActivationStepComponent,
    disclaimer_step_component_1.ApiIntegrationWildfireDisclaimerStepComponent,
    indicators_1.ApiIntegrationWildfireIndicatorsStepComponent,
    summary_1.ApiIntegrationWildfireSummaryStepComponent,
    remediation_1.ApiIntegrationWildfireRemediationStepComponent,
    notifications_1.ApiIntegrationWildfireNotificationsStepComponent,
    wildfire_wizard_component_1.ApiIntegrationWildfireWizardComponent
];
class ApiIntegrationWildfireWizardModule {
}
exports.ApiIntegrationWildfireWizardModule = ApiIntegrationWildfireWizardModule;
