"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FooterContainerComponent = exports.LayoutBaseContainersDirective = void 0;
class LayoutBaseContainersDirective {
}
exports.LayoutBaseContainersDirective = LayoutBaseContainersDirective;
class FooterContainerComponent {
    constructor() {
        this.addClass = true;
    }
}
exports.FooterContainerComponent = FooterContainerComponent;
