'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const _ = require("lodash");
require("app/services/services/backup/components/email-download/email-download-button"); //email.download.button
/**
 * Created by psambandam on 13/10/2016.
 */
angular.module('contact.view.controller', ['email.download.button'])
    .controller('ContactViewController', ['emailViewService', '$scope', 'archiveFolderService', 'apiArchiveService', 'contactViewService', 'capabilitiesService',
    function (emailViewService, $scope, archiveFolderService, apiArchiveService, contactViewService, capabilitiesService) {
        const self = this;
        const { contact, mailbox } = $scope.$parent.$resolve;
        /* -------------------------------------------------------- *\
   - initialisation
   \* -------------------------------------------------------- */
        self.contact = contact;
        // TODO: should be using FOLDER_CONTENT_TYPE instead, check if it will break legacy
        self.contentType = archiveFolderService.CONTENT_TYPES;
        self.mailbox = mailbox;
        self.id = contact.id;
        self.contactDetails = {};
        self.isLoading = true;
        self.PHONE_TYPE = contactViewService.PHONE_TYPE;
        self.ADDRESS_TYPE = contactViewService.ADDRESS_TYPE;
        self.getAddressType = getAddressType;
        self.getPhoneType = getPhoneType;
        self.isPropertyDefined = isPropertyDefined;
        if (!capabilitiesService.evalCapabilitiesExpression('Permission.DATA_SYNC_SYNC_CONTENT_VIEW || Permission.DATA_SYNC_AND_RECOVER_SYNC_CONTENT_VIEW')) {
            self.isLoading = false;
            self.contact = undefined;
            return;
        }
        activate();
        function activate() {
            const contactDetailsPayload = {
                'mailbox': self.mailbox,
                'id': self.id
            };
            apiArchiveService.getContactDetail(contactDetailsPayload).then(function (response) {
                if (!response.err) {
                    self.contactDetails = response.first;
                    self.isLoading = false;
                    deleteUndefinedOrEmptyProperties();
                }
                else {
                    self.isLoading = false;
                    apiArchiveService.getError().showErrorNotification(response);
                }
            }, function () {
                self.isLoading = false;
                apiArchiveService.getError().showErrorNotification();
            });
        }
        function getAddressType(type) {
            return (self.ADDRESS_TYPE[type]) ? self.ADDRESS_TYPE[type] : '';
        }
        function getPhoneType(type) {
            return (self.PHONE_TYPE[type]) ? self.PHONE_TYPE[type] : '';
        }
        function isPropertyDefined(property) {
            return property && property !== '';
        }
        function deleteUndefinedOrEmptyProperties() {
            _.forOwn(self.contactDetails, function (contactValue, contactKey) {
                if (Array.isArray(contactValue)) {
                    const cloneContactValue = angular.copy(contactValue);
                    _.forEach(cloneContactValue, function (item) {
                        const itemIndex = _.findIndex(contactValue, item);
                        _.forOwn(item, function (value, key) {
                            if (!value || value === '') {
                                delete contactValue[itemIndex][key];
                            }
                        });
                        if (_.isEmpty(contactValue[itemIndex]) || Object.keys(contactValue[itemIndex]).length === 1) {
                            contactValue.splice(itemIndex, 1);
                        }
                    });
                }
                else {
                    if (!contactValue || contactValue === '') {
                        delete self.contactDetails[contactKey];
                    }
                }
            });
        }
    }]);
