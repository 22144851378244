'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const moment = require("moment");
/**
 * Created by psambandam on 28/06/2016.
 */
angular.module('administration.service', [])
    .factory('administrationService', ['$q', 'loginService', 'classicAdconHelperService', 'sessionHandlerService', 'userService', 'growl', 'APP_CONSTANTS', function ($q, loginService, classicAdconHelperService, sessionHandlerService, userService, growl, APP_CONSTANTS) {
        const verifyAccessPromise = $q.defer();
        $q.all([userService.verifyAccess(), userService.getCapabilitiesAndPermissions()]).then(function (responseAll) {
            const verifyAccessResponse = responseAll[0];
            const getCapabilitiesAndPermissionsResponse = responseAll[1];
            if (!verifyAccessResponse.err && !getCapabilitiesAndPermissionsResponse.err) {
                if (verifyAccessResponse.data().first.adminAccess &&
                    typeof verifyAccessResponse.data().first.adminAccess === 'string' &&
                    (verifyAccessResponse.data().first.adminAccess.toUpperCase() === userService.constants.ADMIN_ACCESS.GRANTED)) {
                    verifyAccessPromise.resolve(true);
                }
                else {
                    verifyAccessPromise.reject(false);
                }
            }
            else {
                verifyAccessPromise.reject(false);
            }
        }, function () {
            verifyAccessPromise.reject(false);
        });
        return {
            logout,
            getVerifiedAccess,
            getSessionDuration
        };
        function logout(promise) {
            getVerifiedAccess().then(function () {
                $q.all([loginService.logout(), classicAdconHelperService.logout(promise)])['finally'](function () {
                    sessionHandlerService.logOut();
                });
            }, function () {
                loginService.logout().then(function () {
                    sessionHandlerService.logOut();
                });
            });
        }
        function getVerifiedAccess() {
            return verifyAccessPromise.promise;
        }
        function getSessionDuration() {
            return (angular.isDefined(sessionHandlerService.getSession().duration)) ? (moment.duration(sessionHandlerService.getSession().duration).asMinutes()) : APP_CONSTANTS.DEFAULT_SESSION_DURATION;
        }
    }]);
