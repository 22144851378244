"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EndpointInstallEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const install_actions_1 = require("../actions/install.actions");
const endpoints_delete_key_modal_component_1 = require("../components/install/modal/endpoints-delete-key-modal.component");
class EndpointInstallEffects {
    constructor(actions$, notificationService, translateService, swgEndpointsApiService, exportService, modalService) {
        this.actions$ = actions$;
        this.notificationService = notificationService;
        this.translateService = translateService;
        this.swgEndpointsApiService = swgEndpointsApiService;
        this.exportService = exportService;
        this.modalService = modalService;
        this.getEndpointsAuthKey$ = this.actions$.pipe(effects_1.ofType(install_actions_1.SwgEndpointsActions.GET_AUTH_KEY), operators_1.switchMap((action) => {
            return this.swgEndpointsApiService.getEndpointsAuthKey(action.payload).pipe(operators_1.map((response) => {
                return new install_actions_1.SwgEndpointsInstallGetAuthKeySuccessAction(response);
            }), operators_1.catchError(() => this.endpointAuthKeyFailAction('$I18N_ENDPOINT_SETTINGS.NOTIFICATION.ERRORS.GET_AUTH_KEY')));
        }));
        this.deleteEndpointAuthKey$ = this.actions$.pipe(effects_1.ofType(install_actions_1.SwgEndpointsActions.DELETE_AUTH_KEY), operators_1.switchMap(() => {
            return this.swgEndpointsApiService.deleteEndpointAuthKey().pipe(operators_1.map((response) => {
                if (!response) {
                    throw new Error();
                }
                this.notificationService.success({
                    msg: this.translateService.instant('$I18N_ENDPOINT_SETTINGS.NOTIFICATION.SUCCESS.AUTH_KEY_DELETED')
                });
                return new install_actions_1.SwgEndpointsInstallDeleteAuthKeySuccessAction();
            }), operators_1.catchError(() => this.endpointAuthKeyFailAction('$I18N_ENDPOINT_SETTINGS.NOTIFICATION.ERRORS.DELETE_AUTH_KEY')));
        }));
        this.downloadEndpointAuthKey$ = this.actions$.pipe(effects_1.ofType(install_actions_1.SwgEndpointsActions.AUTH_KEY_DOWNLOAD), operators_1.switchMap(() => {
            return this.swgEndpointsApiService.downloadEndpointAuthKey().pipe(operators_1.map((response) => {
                this.exportService.injectIframeToDownloadFile(response);
            }), operators_1.catchError(() => this.endpointAuthKeyFailAction('$I18N_ENDPOINT_SETTINGS.ERRORS.DOWNLOAD_AUTH_KEY')));
        }));
        this.downloadEndpointInstallBinary$ = this.actions$.pipe(effects_1.ofType(install_actions_1.SwgEndpointsActions.BINARY_DOWNLOAD), operators_1.switchMap((action) => {
            return this.swgEndpointsApiService.downloadEndpointInstallBinary(action.payload).pipe(operators_1.map((response) => {
                this.exportService.injectIframeToDownloadFile(response);
            }), operators_1.catchError(() => this.endpointAuthKeyFailAction('$I18N_ENDPOINT_SETTINGS.ERRORS.DOWNLOAD_ENDPOINT_INSTALL_BINARY')));
        }));
        this.openDeleteAuthKeyModal$ = this.actions$.pipe(effects_1.ofType(install_actions_1.SwgEndpointsActions.OPEN_DELETE_AUTH_KEY_MODAL), operators_1.tap(() => {
            const modalConfig = {};
            modalConfig.size = 'md';
            modalConfig.hasBackdrop = true;
            modalConfig.disableClose = true;
            this.deleteAuthKeyModalInstance = this.modalService.open(endpoints_delete_key_modal_component_1.SwgEndpointsDeleteKeyModalComponent, modalConfig);
        }));
        this.closeDeleteAuthKeyModal$ = this.actions$.pipe(effects_1.ofType(install_actions_1.SwgEndpointsActions.DELETE_AUTH_KEY_SUCCESS), operators_1.tap(() => {
            this.closeDeleteAuthKeyModal();
        }));
    }
    closeDeleteAuthKeyModal() {
        if (this.deleteAuthKeyModalInstance) {
            this.deleteAuthKeyModalInstance.close();
        }
        this.deleteAuthKeyModalInstance = null;
    }
    endpointAuthKeyFailAction(errorMessage) {
        return rxjs_1.of(new install_actions_1.SwgEndpointsInstallAuthKeyFailureAction()).pipe(operators_1.tap(() => {
            this.notificationService.error({
                msg: this.translateService.instant(errorMessage)
            });
            this.closeDeleteAuthKeyModal();
        }));
    }
}
__decorate([
    effects_1.Effect()
], EndpointInstallEffects.prototype, "getEndpointsAuthKey$", void 0);
__decorate([
    effects_1.Effect()
], EndpointInstallEffects.prototype, "deleteEndpointAuthKey$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], EndpointInstallEffects.prototype, "downloadEndpointAuthKey$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], EndpointInstallEffects.prototype, "downloadEndpointInstallBinary$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], EndpointInstallEffects.prototype, "openDeleteAuthKeyModal$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], EndpointInstallEffects.prototype, "closeDeleteAuthKeyModal$", void 0);
exports.EndpointInstallEffects = EndpointInstallEffects;
