"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueueDetailsEffect = void 0;
const queue_details_actions_1 = require("../actions/queue.details.actions");
const table_store_1 = require("@mimecast-ui/table-store");
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const queue_configs_service_1 = require("../containers/list/services/queue-configs.service");
const queue_configs_factory_1 = require("../factories/queue-configs.factory");
const notification_actions_1 = require("app-new/components/notification/actions/notification.actions");
class QueueDetailsEffect {
    constructor(actions$, queueService, translateService) {
        this.actions$ = actions$;
        this.queueService = queueService;
        this.translateService = translateService;
        this.getQueueConfig$ = this.actions$.pipe(effects_1.ofType(queue_details_actions_1.QueueDetailsActions.GET_QUEUE_DETAILS), operators_1.map((action) => action.queue), operators_1.switchMap((queueConfig) => {
            return this.queueService.getQueueConfig(queueConfig.id).pipe(operators_1.map(response => {
                if (response.hasErrors) {
                    return new queue_details_actions_1.GetQueueDetailsActionFail(response.fail[0]);
                }
                const queueConfigDetails = queue_configs_factory_1.QueueConfigFactory.BUILD_QUEUE_CONFIG_DETAILS(response.first);
                return new queue_details_actions_1.GetQueueDetailsActionSuccess(queueConfigDetails);
            }), operators_1.catchError(response => {
                return rxjs_1.of(new queue_details_actions_1.GetQueueDetailsActionFail(response.fail[0]));
            }));
        }));
        this.getQueueConfigFail$ = this.actions$.pipe(effects_1.ofType(queue_details_actions_1.QueueDetailsActions.GET_QUEUE_DETAILS_FAIL), operators_1.map((action) => action.failure), operators_1.map((payload) => payload.errors), operators_1.switchMap((errors) => {
            if (!errors) {
                return rxjs_1.EMPTY;
            }
            return errors.map(error => new notification_actions_1.NotificationShowFailAction(this.translateService.instant(error.code)));
        }));
        this.updatePaginationInfo$ = this.actions$.pipe(effects_1.ofType(queue_details_actions_1.QueueDetailsActions.UPDATE_QUEUE_DETAILS_PAGINATION_INFO), operators_1.switchMap((action) => {
            return [
                this.getPaginationInfo(action.currentQueue, action.listQueues),
                new table_store_1.SetSelectedRowAction({
                    tableId: queue_configs_service_1.QueueConfigsService.SUPERVISION_QUEUE_CONFIG_LIST,
                    item: action.currentQueue
                })
            ];
        }));
    }
    getPaginationInfo(currentQueue, tableData) {
        const currentQueueIndex = tableData.findIndex(value => value.id === currentQueue.id);
        const previousQueue = currentQueueIndex !== undefined && currentQueueIndex > 0
            ? tableData[currentQueueIndex - 1]
            : undefined;
        const nextQueue = currentQueueIndex !== undefined && currentQueueIndex < tableData.length
            ? tableData[currentQueueIndex + 1]
            : undefined;
        return new queue_details_actions_1.SetQueueDetailsPaginationInfoAction(previousQueue, currentQueue, nextQueue, currentQueueIndex, tableData.length);
    }
}
__decorate([
    effects_1.Effect()
], QueueDetailsEffect.prototype, "getQueueConfig$", void 0);
__decorate([
    effects_1.Effect()
], QueueDetailsEffect.prototype, "getQueueConfigFail$", void 0);
__decorate([
    effects_1.Effect()
], QueueDetailsEffect.prototype, "updatePaginationInfo$", void 0);
exports.QueueDetailsEffect = QueueDetailsEffect;
