"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectorSummaryStepComponent = void 0;
const model_1 = require("../../../../../model");
class ConnectorSummaryStepComponent {
    ngOnChanges(changes) {
        if (changes && changes['wizardData'].currentValue) {
            this.stepsData = this.wizardData.steps;
        }
    }
    getStepDataById(id) {
        const step = this.getStepById(id);
        return (step && step.data) || {};
    }
    isGwsConnector() {
        const data = this.getStepDataById(model_1.ConnectorStep.PROVIDER);
        return (!this.editFlow &&
            data &&
            data.provider &&
            model_1.ProviderType.GOOGLE_WORKSPACE === data.provider.type);
    }
    isMsConnector() {
        const data = this.getStepDataById(model_1.ConnectorStep.PROVIDER);
        return (!this.editFlow &&
            data &&
            data.provider &&
            (model_1.ProviderType.MS_TEAMS === data.provider.type ||
                model_1.ProviderType.ONE_DRIVE === data.provider.type ||
                model_1.ProviderType.OFFICE_365 === data.provider.type));
    }
    getStepById(id) {
        return (this.stepsData && this.stepsData[id]) || {};
    }
}
exports.ConnectorSummaryStepComponent = ConnectorSummaryStepComponent;
