"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.LogsFactory = void 0;
const log_builder_1 = require("../models/log.builder");
const ɵ0 = (cdm) => new log_builder_1.LogBuilder()
    .withId(cdm['id'])
    .withSenderAddress(cdm.from.emailAddress)
    .withRecipientAddress(cdm.to.emailAddress)
    .withDateSent(cdm['dateReceived'])
    .withSubject(cdm['subject'])
    .withStatus(cdm['status'])
    .withExpiry(cdm['expiryDate'])
    .build();
exports.ɵ0 = ɵ0;
exports.LogsFactory = {
    BUILD_LOG: ɵ0
};
