"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgApiService = void 0;
const operators_1 = require("rxjs/operators");
class SwgApiService {
    constructor(http) {
        this.http = http;
    }
    getTopDomains(verdict = 'All') {
        return this.http
            .post('/api/swg/get-top-domains', { verdict })
            .pipe(operators_1.map(response => {
            return response.first;
        }));
    }
    getTopCategories(verdict = 'All') {
        return this.http
            .post('/api/swg/get-top-categories', { verdict })
            .pipe(operators_1.map(response => {
            return response.first;
        }));
    }
    getActivities() {
        return this.http
            .post('/api/swg/get-activities', { data: [{}] })
            .pipe(operators_1.map(response => {
            return response.first;
        }));
    }
    getDashboardNotifications() {
        return this.http.post('/api/account/get-dashboard-notifications', null).pipe(operators_1.map(response => {
            return response.first.notifications;
        }));
    }
}
exports.SwgApiService = SwgApiService;
