"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpamDetailsInfoComponent = void 0;
const models_1 = require("../../../models");
class SpamDetailsInfoComponent {
    spamScanPolicyStatus() {
        var _a, _b, _c, _d;
        if (((_c = (_b = (_a = this.spamInfo) === null || _a === void 0 ? void 0 : _a.spamProcessingDetail) === null || _b === void 0 ? void 0 : _b.managedSender) === null || _c === void 0 ? void 0 : _c.info) ===
            models_1.autoAllowOptionCondition.managedSenderInfo &&
            ((_d = this.spamInfo) === null || _d === void 0 ? void 0 : _d.spamScore) >= models_1.autoAllowOptionCondition.spamScore &&
            (this.messageType !== models_1.autoAllowOptionCondition.messageType
                ? this.messageInfoRoute === models_1.autoAllowOptionCondition.messageInfoRoute &&
                    this.checkForPolicyType(this.deliveredMessage)
                : true)) {
            return true;
        }
        else {
            return false;
        }
    }
    checkForPolicyType(deliveredMessage) {
        for (const email in deliveredMessage) {
            if (deliveredMessage.hasOwnProperty(email) && deliveredMessage[email].policyInfo) {
                const policyType = Object.values(deliveredMessage[email].policyInfo).map(type => type.policyType);
                if (policyType.includes(models_1.autoAllowPolicyTypes.spamSpanning) &&
                    policyType.includes(models_1.autoAllowPolicyTypes.autoAllow)) {
                    return true;
                }
                else {
                    return false;
                }
                return false;
            }
        }
        return false;
    }
}
exports.SpamDetailsInfoComponent = SpamDetailsInfoComponent;
