<mc-template-frame>
  <mc-feedback-badge
    status="error"
    text="$I18N_AWARENESS_TEMPLATE_SETUP_HARMFUL_LINK_MISIDENTIFIED_BLOCK_PAGE_EMAIL_SECURITY_TRAINING"
  ></mc-feedback-badge>

  <mc-template-title-sub-title [title]="title" [subTitle]="subTitle"></mc-template-title-sub-title>

  <mc-carousel [disabledNavigation]="true" [tips]="tips"></mc-carousel>
</mc-template-frame>

