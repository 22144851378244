"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isApiUpdatingEdit = exports.isChartLoading = exports.isTopProfilesLoading = exports.getTopProfiles = exports.getMonitoredAppsCount = exports.getNonSanctionedAppsCount = exports.getSanctionedAppsCount = exports.getTopCategories = exports.getTopBlockedApplications = exports.getAllBlockedApplications = exports.getTopMonitoredApplications = exports.getTopNonSanctionedApplications = exports.getTopSanctionedApplications = exports.getApplicationStatus = exports.isTopApplicationsLoading = exports.getTopApplications = exports.isApplicationCategorySetDataLoading = exports.applicationCategoriesSet = exports.getAllApplications = exports.reducer = exports.initialState = void 0;
const application_actions_1 = require("../actions/application.actions");
exports.initialState = {
    allApplications: [],
    applicationCategoriesSet: [],
    topApplications: [],
    isTopAppLoading: false,
    topBlockedApplications: [],
    applicationStatus: [],
    topProfiles: {},
    isTopProfilesLoading: false,
    isTopProfileLoaded: false,
    isChartLoading: false,
    isApplicationCategorySetDataLoading: true,
    apiUpdatingEdit: false,
    topCategories: [],
    sanctionedApps: [],
    nonSanctionedApps: [],
    monitoredApps: [],
    blockedApps: [],
    sanctionedAppsCount: 0,
    nonSanctionedAppsCount: 0,
    monitoredAppsCount: 0
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case application_actions_1.SwgLoadTopApplicationsActionsEnum.LOAD_TOP_APPLICATIONS:
            return Object.assign(Object.assign({}, state), { isTopAppLoading: true, topApplications: [] });
        case application_actions_1.SwgLoadTopApplicationsActionsEnum.LOAD_TOP_APPLICATIONS_SUCCESS:
            return Object.assign(Object.assign({}, state), { isTopAppLoading: false, topApplications: action.payload['topApplications'] });
        case application_actions_1.SwgLoadTopApplicationsActionsEnum.LOAD_TOP_APPLICATIONS_FAILURE:
            return Object.assign(Object.assign({}, state), { isTopAppLoading: false, topApplications: [] });
        // application status
        case application_actions_1.SwgLoadTopApplicationsActionsEnum.LOAD_APPLICATION_STATUS:
            return Object.assign(Object.assign({}, state), { applicationStatus: [] });
        case application_actions_1.SwgLoadTopApplicationsActionsEnum.LOAD_APPLICATION_STATUS_SUCCESS:
            return Object.assign(Object.assign({}, state), { applicationStatus: action.payload['applicationStatus'] });
        case application_actions_1.SwgLoadTopApplicationsActionsEnum.LOAD_APPLICATION_STATUS_FAILURE:
            return Object.assign(Object.assign({}, state), { applicationStatus: [] });
        // top bocked applications
        case application_actions_1.SwgLoadTopApplicationsActionsEnum.LOAD_TOP_BLOCKED_APPLICATIONS:
            return Object.assign(Object.assign({}, state), { topBlockedApplications: [] });
        case application_actions_1.SwgLoadTopApplicationsActionsEnum.LOAD_TOP_BLOCKED_APPLICATIONS_SUCCESS:
            return Object.assign(Object.assign({}, state), { topBlockedApplications: action.payload['topApplications'] });
        case application_actions_1.SwgLoadTopApplicationsActionsEnum.LOAD_TOP_BLOCKED_APPLICATIONS_FAILURE:
            return Object.assign(Object.assign({}, state), { topBlockedApplications: [] });
        // top profiles
        case application_actions_1.SwgLoadTopApplicationsActionsEnum.LOAD_TOP_PROFILES:
            return Object.assign(Object.assign({}, state), { isTopProfilesLoading: true, topProfiles: {}, isTopProfileLoaded: false });
        case application_actions_1.SwgLoadTopApplicationsActionsEnum.LOAD_TOP_PROFILES_SUCCESS:
            return Object.assign(Object.assign({}, state), { isTopProfilesLoading: false, topProfiles: action.payload, isTopProfileLoaded: true });
        case application_actions_1.SwgLoadTopApplicationsActionsEnum.LOAD_TOP_PROFILES_FAILURE:
            return Object.assign(Object.assign({}, state), { isTopProfilesLoading: false, topProfiles: {}, isTopProfileLoaded: false });
        case application_actions_1.SwgLoadTopApplicationsActionsEnum.GET_CHART_INFO:
            return Object.assign(Object.assign({}, state), { isChartLoading: true });
        case application_actions_1.SwgLoadTopApplicationsActionsEnum.LOAD_CHART_DATA_SUCCESS:
            // @ts-ignore
            return Object.assign(Object.assign({}, state), { isChartLoading: false, isApplicationCategorySetDataLoading: false, allApplications: action.payload.allApplications, applicationCategoriesSet: action.payload.applicationCategoriesSet, sanctionedApps: action.payload.topSanctionedApps, nonSanctionedApps: action.payload.topNonSanctionedApps, monitoredApps: action.payload.topMonitoredApps, blockedApps: action.payload.topBlockedApps, topCategories: action.payload.topCategories, sanctionedAppsCount: action.payload.sanctionedAppsCount, nonSanctionedAppsCount: action.payload.nonSanctionedAppsCount, monitoredAppsCount: action.payload.monitoredAppsCount });
        case application_actions_1.SwgLoadTopApplicationsActionsEnum.UPDATE_APPLICATION_STATUS:
            return Object.assign(Object.assign({}, state), { apiUpdatingEdit: true });
        case application_actions_1.SwgLoadTopApplicationsActionsEnum.UPDATE_APPLICATION_STATUS_FAILURE:
            return Object.assign(Object.assign({}, state), { apiUpdatingEdit: false });
        case application_actions_1.SwgLoadTopApplicationsActionsEnum.UPDATE_APPLICATION_STATUS_SUCCESS:
            return Object.assign(Object.assign({}, state), { apiUpdatingEdit: false, allApplications: state.allApplications.map(x => {
                    const match = action.payload.find(y => y.appCode === x.code);
                    if (match) {
                        const serverStatus = action.payload.find(y => y.appCode === x.code);
                        return Object.assign(Object.assign({}, x), { id: serverStatus.id, status: serverStatus.status });
                    }
                    return x;
                }) });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getAllApplications = (state) => state.allApplications;
exports.getAllApplications = getAllApplications;
const applicationCategoriesSet = (state) => state.applicationCategoriesSet;
exports.applicationCategoriesSet = applicationCategoriesSet;
const isApplicationCategorySetDataLoading = (state) => state.isApplicationCategorySetDataLoading;
exports.isApplicationCategorySetDataLoading = isApplicationCategorySetDataLoading;
const getTopApplications = (state) => state.topApplications;
exports.getTopApplications = getTopApplications;
const isTopApplicationsLoading = (state) => state.isTopAppLoading;
exports.isTopApplicationsLoading = isTopApplicationsLoading;
const getApplicationStatus = (state) => state.applicationStatus;
exports.getApplicationStatus = getApplicationStatus;
const getTopSanctionedApplications = (state) => state.sanctionedApps;
exports.getTopSanctionedApplications = getTopSanctionedApplications;
const getTopNonSanctionedApplications = (state) => state.nonSanctionedApps;
exports.getTopNonSanctionedApplications = getTopNonSanctionedApplications;
const getTopMonitoredApplications = (state) => state.monitoredApps;
exports.getTopMonitoredApplications = getTopMonitoredApplications;
const getAllBlockedApplications = (state) => state.topBlockedApplications;
exports.getAllBlockedApplications = getAllBlockedApplications;
const getTopBlockedApplications = (state) => state.blockedApps;
exports.getTopBlockedApplications = getTopBlockedApplications;
const getTopCategories = (state) => state.topCategories;
exports.getTopCategories = getTopCategories;
const getSanctionedAppsCount = (state) => state.sanctionedAppsCount;
exports.getSanctionedAppsCount = getSanctionedAppsCount;
const getNonSanctionedAppsCount = (state) => state.nonSanctionedAppsCount;
exports.getNonSanctionedAppsCount = getNonSanctionedAppsCount;
const getMonitoredAppsCount = (state) => state.monitoredAppsCount;
exports.getMonitoredAppsCount = getMonitoredAppsCount;
const getTopProfiles = (state) => state.topProfiles;
exports.getTopProfiles = getTopProfiles;
const isTopProfilesLoading = (state) => state.isTopProfilesLoading;
exports.isTopProfilesLoading = isTopProfilesLoading;
const isChartLoading = (state) => state.isChartLoading;
exports.isChartLoading = isChartLoading;
const isApiUpdatingEdit = (state) => state.apiUpdatingEdit;
exports.isApiUpdatingEdit = isApiUpdatingEdit;
