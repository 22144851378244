"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GreyMailDetailsDisplayPipe = void 0;
class GreyMailDetailsDisplayPipe {
    constructor(translateService) {
        this.translateService = translateService;
    }
    transform(greyMailInfo) {
        switch (greyMailInfo) {
            case true:
                return `<span class="positiveProperty">${this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_GREY_MAIL.TRUE')}</span>`;
            case false:
                return `<span class="negativeProperty">${this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_GREY_MAIL.FALSE')}</span>`;
            default:
                return '';
        }
    }
}
exports.GreyMailDetailsDisplayPipe = GreyMailDetailsDisplayPipe;
