"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IncidentsEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const incidentsAction = require("../../actions/incidents/incidents.action");
const remediate_messages_action_1 = require("../../actions/remediate-messages/remediate-messages.action");
const cancel_device_remediate_modal_component_1 = require("../../components/incident-view/incident-device-summary/modal/cancel-device-remediate-modal.component");
class IncidentsEffects {
    constructor(actions$, remediationApiService, translateService, notificationService, modalService) {
        this.actions$ = actions$;
        this.remediationApiService = remediationApiService;
        this.translateService = translateService;
        this.notificationService = notificationService;
        this.modalService = modalService;
        this.getAllIncidents$ = this.actions$.pipe(effects_1.ofType(incidentsAction.GET_ALL_INCIDENTS), operators_1.switchMap(() => {
            return this.remediationApiService.findIncidents().pipe(operators_1.map((response) => response.first), operators_1.map((response) => response.incidents), operators_1.map((response) => new incidentsAction.RemediationGetAllIncidentsSuccessAction(response)), operators_1.catchError(() => this.incidentFailAction()));
        }));
        this.openDeviceRemediationModal$ = this.actions$.pipe(effects_1.ofType(remediate_messages_action_1.OPEN_CANCEL_DEVICE_REMEDIATION_POPUP), operators_1.tap(() => {
            const modalConfig = {};
            modalConfig.size = 'md';
            modalConfig.hasBackdrop = true;
            modalConfig.disableClose = true;
            this.cancelDeviceRemediationModal = this.modalService.open(cancel_device_remediate_modal_component_1.CancelDeviceRemediateModalComponent, modalConfig);
        }));
        this.closeDeviceRemediationModal$ = this.actions$.pipe(effects_1.ofType(remediate_messages_action_1.CLOSE_CANCEL_DEVICE_REMEDIATION_POPUP), operators_1.tap(() => {
            this.cancelDeviceRemediationModal.close();
            this.cancelDeviceRemediationModal = undefined;
        }));
    }
    incidentFailAction() {
        return rxjs_1.of(new incidentsAction.RemediationGetAllIncidentsFailureAction()).pipe(operators_1.tap(() => {
            this.notificationService.error({
                msg: this.translateService.instant('$I18N_REMEDIATION.ERRORS.GENERIC')
            });
        }));
    }
}
__decorate([
    effects_1.Effect()
], IncidentsEffects.prototype, "getAllIncidents$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], IncidentsEffects.prototype, "openDeviceRemediationModal$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], IncidentsEffects.prototype, "closeDeviceRemediationModal$", void 0);
exports.IncidentsEffects = IncidentsEffects;
