"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ1 = exports.ɵ0 = exports.getPagination = exports.getSelectedEmail = exports.getReason = exports.getQuery = exports.getTokenSliderResults = exports.getTokenResults = exports.getResults = exports.isLoading = exports.reducers = void 0;
const store_1 = require("@ngrx/store");
const fromEmail = require("./email.reducer");
const fromSearch = require("./search.reducer");
exports.reducers = {
    search: fromSearch.reducer,
    email: fromEmail.reducer
};
const getArchiveViewerState = store_1.createFeatureSelector('archiveViewer');
const ɵ0 = (state) => state.search;
exports.ɵ0 = ɵ0;
const getListEntitiesState = store_1.createSelector(getArchiveViewerState, ɵ0);
const ɵ1 = (state) => state.email;
exports.ɵ1 = ɵ1;
const getEmailState = store_1.createSelector(getArchiveViewerState, ɵ1);
exports.isLoading = store_1.createSelector(getListEntitiesState, fromSearch.isLoading);
exports.getResults = store_1.createSelector(getListEntitiesState, fromSearch.getResults);
exports.getTokenResults = store_1.createSelector(getListEntitiesState, fromSearch.getTokenResults);
exports.getTokenSliderResults = store_1.createSelector(getListEntitiesState, fromSearch.getTokenSliderResults);
exports.getQuery = store_1.createSelector(getListEntitiesState, fromSearch.getQuery);
exports.getReason = store_1.createSelector(getListEntitiesState, fromSearch.getReason);
exports.getSelectedEmail = store_1.createSelector(getEmailState, fromEmail.getSelectedEmail);
exports.getPagination = store_1.createSelector(getListEntitiesState, fromSearch.getPagination);
