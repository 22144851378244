<div class="auth-method-panel mc-identity-policy-steps-container">
    <mc-wizard-step-header class="mc-identity-wizard-step-header"
                           label="$I18N_IDENTITY_CREATE_POLICY_AUTH_METHODS_STEP.TITLE">
        {{'$I18N_IDENTITY_CREATE_POLICY_AUTH_METHODS_STEP.DESCRIPTION' | translate}}
    </mc-wizard-step-header>

    <form class="form-horizontal"
          [formGroup]="policyAuthMethodsForm"
          mcDefaultLabelClass="col-sm-3"
          mcDefaultControlContainerClass="col-sm-9">
        <mc-field
                label="$I18N_IDENTITY_CREATE_POLICY_AUTH_METHODS_STEP.ELEMENTS.DOMAIN.LABEL"
                required
                helpPopoverContent="{{ '$I18N_IDENTITY_CREATE_POLICY_AUTH_METHODS_STEP.ELEMENTS.DOMAIN.POPOVER' | translate }}"
                errorPrefix="$I18N_IDENTITY_CREATE_POLICY_AUTH_METHODS_STEP.VALIDATION.DOMAIN">
            <mc-select formControlName="domain" [options]="domainOptions"></mc-select>
        </mc-field>

        <mc-text-field
                *ngIf="policyAuthMethodsForm.get('domain').value === domainOptions[2].value"
                label="$I18N_IDENTITY_CREATE_POLICY_AUTH_METHODS_STEP.ELEMENTS.CAS_SERVER.LABEL"
                placeholder="$I18N_IDENTITY_CREATE_POLICY_AUTH_METHODS_STEP.ELEMENTS.CAS_SERVER.PLACEHOLDER"
                formControlName="casServer"
                required
                helpPopoverContent="{{ '$I18N_IDENTITY_CREATE_POLICY_AUTH_METHODS_STEP.ELEMENTS.CAS_SERVER.POPOVER' | translate }}"
                errorPrefix="$I18N_IDENTITY_CREATE_POLICY_AUTH_METHODS_STEP.VALIDATION.CAS">
        </mc-text-field>

        <mc-text-field
                *ngIf="policyAuthMethodsForm.get('domain').value === domainOptions[2].value"
                label="$I18N_IDENTITY_CREATE_POLICY_AUTH_METHODS_STEP.ELEMENTS.ALTERNATE_SUFFIX.LABEL"
                formControlName="alternateSuffix"
                helpPopoverContent="{{ '$I18N_IDENTITY_CREATE_POLICY_AUTH_METHODS_STEP.ELEMENTS.ALTERNATE_SUFFIX.POPOVER' | translate }}">
        </mc-text-field>

        <mc-field
                label="$I18N_IDENTITY_CREATE_POLICY_AUTH_METHODS_STEP.ELEMENTS.CLOUD_AUTH.LABEL"
                helpPopoverContent="{{ '$I18N_IDENTITY_CREATE_POLICY_AUTH_METHODS_STEP.ELEMENTS.CLOUD_AUTH.POPOVER' | translate }}">
            <mc-select formControlName="cloudAuthentication" [options]="cloudAuthOptions"></mc-select>
        </mc-field>

        <mc-switch-field
                label="$I18N_IDENTITY_CREATE_POLICY_AUTH_METHODS_STEP.ELEMENTS.MC_AUTHENTICATOR.LABEL"
                required
                [showLabel]="true"
                helpPopoverContent="{{ '$I18N_IDENTITY_CREATE_POLICY_AUTH_METHODS_STEP.ELEMENTS.MC_AUTHENTICATOR.POPOVER' | translate }}"
                formControlName="mimecastAuthenticator">
        </mc-switch-field>

        <mc-field
                *ngIf="policyAuthMethodsForm.get('mimecastAuthenticator').value === false"
                label="$I18N_IDENTITY_CREATE_POLICY_AUTH_METHODS_STEP.ELEMENTS.SECOND_FACTOR.LABEL"
                helpPopoverContent="{{ '$I18N_IDENTITY_CREATE_POLICY_AUTH_METHODS_STEP.ELEMENTS.SECOND_FACTOR.POPOVER' | translate }}">
            <mc-select formControlName="secondFactor" [options]="secondFactorOptions"></mc-select>
        </mc-field>

        <mc-switch-field
                *ngIf="policyAuthMethodsForm.get('mimecastAuthenticator').value === false
          && (policyAuthMethodsForm.get('secondFactor').value === secondFactorOptions[0].value
          || policyAuthMethodsForm.get('secondFactor').value === secondFactorOptions[1].value)"
                label="$I18N_IDENTITY_CREATE_POLICY_AUTH_METHODS_STEP.ELEMENTS.REQUEST_BEFORE.LABEL"
                helpPopoverContent="{{ '$I18N_IDENTITY_CREATE_POLICY_AUTH_METHODS_STEP.ELEMENTS.REQUEST_BEFORE.POPOVER' | translate }}"
                [showLabel]="true"
                formControlName="requestBefore">
        </mc-switch-field>

    </form>
</div>
