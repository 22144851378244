"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CancelNoAction = exports.CancelYesAction = exports.CancelAction = exports.SetCurrentStepAction = exports.UpdateStepAction = exports.WizardActions = void 0;
var WizardActions;
(function (WizardActions) {
    WizardActions["SET_CURRENT_STEP"] = "[CustomDomainBlockWizard] Set Current Step";
    WizardActions["UPDATE_STEP"] = "[CustomDomainBlockWizard] Update Step";
    WizardActions["CANCEL"] = "[CustomDomainBlockWizard] Cancel";
    WizardActions["CANCEL_YES"] = "[CustomDomainBlockWizard] Cancel Yes";
    WizardActions["CANCEL_NO"] = "[CustomDomainBlockWizard] Cancel No";
})(WizardActions = exports.WizardActions || (exports.WizardActions = {}));
class UpdateStepAction {
    constructor(wizardId, stepId, stepState) {
        this.wizardId = wizardId;
        this.stepId = stepId;
        this.stepState = stepState;
        this.type = WizardActions.UPDATE_STEP;
    }
}
exports.UpdateStepAction = UpdateStepAction;
class SetCurrentStepAction {
    constructor(wizardId, stepId) {
        this.wizardId = wizardId;
        this.stepId = stepId;
        this.type = WizardActions.SET_CURRENT_STEP;
    }
}
exports.SetCurrentStepAction = SetCurrentStepAction;
class CancelAction {
    constructor(title, wizardId) {
        this.title = title;
        this.wizardId = wizardId;
        this.type = WizardActions.CANCEL;
    }
}
exports.CancelAction = CancelAction;
class CancelYesAction {
    constructor(wizardId) {
        this.wizardId = wizardId;
        this.type = WizardActions.CANCEL_YES;
    }
}
exports.CancelYesAction = CancelYesAction;
class CancelNoAction {
    constructor() {
        this.type = WizardActions.CANCEL_NO;
    }
}
exports.CancelNoAction = CancelNoAction;
