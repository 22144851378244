<div class="mc-api-remediation-step-block">
  <mc-wizard-step-header [label]="'$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.REMEDIATION.HEADER'">
  </mc-wizard-step-header>

  <div class="mc-detailed-list mc-api-remediation-wildfire-remediation-block">
    {{ '$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.REMEDIATION.DESCRIPTION' | translate }}
    <form
      class="form-horizontal panel-padding-top"
      mcDefaultLabelClass="col-sm-3"
      mcDefaultControlContainerClass="col-sm-9"
      novalidate
      [formGroup]="form"
    >
      <mc-field
        label="{{ '$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.REMEDIATION.LABEL' | translate }}"
        helpPopoverContent="{{
          '$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.REMEDIATION.TOOLTIP' | translate
        }}"
        >
        <mc-enable-switch [isDisabled]="!hasInternalEmailProtectPackage" formControlName="remediationRequired"></mc-enable-switch>
      </mc-field>
      <em>{{ '$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.REMEDIATION.INFO_MESSAGE' | translate }}</em>
    </form>
  </div>
</div>
