<mc-layout-aside-extra-container
  keyTitle="$I18N_SIEM_CHANNEL_SIDEBAR.TITLE"
  showClose="true"
  [isLoading]="channelDetailsLoading"
  (closeAside)="close.emit()"
>
  <mc-extra-container *ngIf="hasEditPermission || hasDeletePermission">
    <div>
      <button class="btn btn-primary panel-half-margin-right"
              (click)="editChannel.emit(channel)"
              [disabled]="metadataProducts.length === 0"
              *ngIf="hasEditPermission"
      >
        {{ '$I18N_SIEM_CHANNEL_SIDEBAR.BUTTON_EDIT' | translate }}
      </button>
      <button
        class="btn btn-secondary panel-half-margin-right"
        (click)="deleteChannel.emit(channel)"
        *ngIf="hasDeletePermission"
      >
        {{ '$I18N_SIEM_CHANNEL_SIDEBAR.BUTTON_DELETE' | translate }}
      </button>
    </div>
  </mc-extra-container>
  <mc-body-container class="mc-channel-details-panel">
    <mc-siem-channel-details [channel]="channel" [isSidebar]="true"></mc-siem-channel-details>
  </mc-body-container>
</mc-layout-aside-extra-container>
