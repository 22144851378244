"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgEndpointsListLoadedPostPurgeAction = exports.SwgPurgeEndpointsListConfirmAction = exports.SwgPurgeEndpointsListFailureAction = exports.SwgPurgeEndpointsListSuccessAction = exports.SwgPurgeEndpointsListAction = exports.SwgEndpointsListActions = void 0;
var SwgEndpointsListActions;
(function (SwgEndpointsListActions) {
    SwgEndpointsListActions["PURGE_ENDPOINTS_LIST"] = "[swgEndpointsList] Purge Endpoints List";
    SwgEndpointsListActions["PURGE_ENDPOINTS_LIST_SUCCESS"] = "[swgEndpointsList] Purge Endpoints List Success";
    SwgEndpointsListActions["PURGE_ENDPOINTS_LIST_FAILURE"] = "[swgEndpointsList] Purge Endpoints List Failure";
    SwgEndpointsListActions["PURGE_ENDPOINTS_LIST_CONFIRM"] = "[swgEndpointsList] Purge Endpoints List Confirm";
    SwgEndpointsListActions["ENDPOINTS_LIST_LOADED_POST_PURGE"] = "[swgEndpointsList] Endpoints List Loaded Post Purge";
})(SwgEndpointsListActions = exports.SwgEndpointsListActions || (exports.SwgEndpointsListActions = {}));
class SwgPurgeEndpointsListAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgEndpointsListActions.PURGE_ENDPOINTS_LIST;
    }
}
exports.SwgPurgeEndpointsListAction = SwgPurgeEndpointsListAction;
class SwgPurgeEndpointsListSuccessAction {
    constructor() {
        this.type = SwgEndpointsListActions.PURGE_ENDPOINTS_LIST_SUCCESS;
    }
}
exports.SwgPurgeEndpointsListSuccessAction = SwgPurgeEndpointsListSuccessAction;
class SwgPurgeEndpointsListFailureAction {
    constructor() {
        this.type = SwgEndpointsListActions.PURGE_ENDPOINTS_LIST_FAILURE;
    }
}
exports.SwgPurgeEndpointsListFailureAction = SwgPurgeEndpointsListFailureAction;
class SwgPurgeEndpointsListConfirmAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgEndpointsListActions.PURGE_ENDPOINTS_LIST_CONFIRM;
    }
}
exports.SwgPurgeEndpointsListConfirmAction = SwgPurgeEndpointsListConfirmAction;
class SwgEndpointsListLoadedPostPurgeAction {
    constructor() {
        this.type = SwgEndpointsListActions.ENDPOINTS_LIST_LOADED_POST_PURGE;
    }
}
exports.SwgEndpointsListLoadedPostPurgeAction = SwgEndpointsListLoadedPostPurgeAction;
