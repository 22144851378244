"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServerConnectorsComponent = void 0;
class ServerConnectorsComponent {
    constructor(elementRef, document) {
        this.elementRef = elementRef;
        this.viewOutlet = document.getElementById('serverConnectors');
        this.viewOutlet.classList.remove('hide');
    }
    ngAfterViewInit() {
        this.elementRef.nativeElement.append(this.viewOutlet);
    }
}
exports.ServerConnectorsComponent = ServerConnectorsComponent;
