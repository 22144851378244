"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
/**
 * Created by asyed
 * This is a visual directive to align a button on the right hand side of the mc-form
 */
(function () {
    'use strict';
    angular.module('predefined-field.rbutton.directive', [])
        .directive('mcRButton', [function () {
            return {
                restrict: 'EA',
                templateUrl: 'backuprestore/components/rbutton/mc-r-button.tpl.html',
                scope: {
                    buttonClass: '@',
                    buttonOnClick: '&',
                    buttonText: '@'
                },
                link($scope, $element) {
                    const mcform = $element.closest('mc-form');
                    $scope.defaultLabelClass = mcform.attr('default-label-classes');
                    $scope.defaultControlClass = mcform.attr('default-input-control-classes');
                    $scope.buttonClass = $scope.buttonClass ? $scope.buttonClass : $scope.defaultControlClass;
                }
            };
        }]);
})();
