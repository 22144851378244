<mc-layout-detail-wizard keyTitle="{{ wizardTitle() | translate }}">
  <mc-help-container>
    <mc-help-link url="https://community.mimecast.com/s/article/Crowdstrike-Falcon-Integration"></mc-help-link>
  </mc-help-container>

  <mc-wizard (changeStep)="changeStep()" [ngClass]="{ 'wizard-busy': isBusy }">
    <mc-wizard-step
      label="{{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.ACTIVATION.TITLE' | translate }}"
    >
      <mc-api-integration-crowdstrike-activation-step
        [clientId]="crowdstrikeIntegration?.clientId"
        [clientSecret]="crowdstrikeIntegration?.clientSecret"
        [baseUrl]="crowdstrikeIntegration?.baseUrl"
        [isClientIdAndClientSecretVerified]="isClientIdAndClientSecretVerified"
        [isVerifyApiKeyButtonDisabled]="isVerifyApiKeyButtonDisabled"
        (onVerifyClientIdAndClientSecret)="verifyClientIdAndClientSecret($event)"
      >
      </mc-api-integration-crowdstrike-activation-step>

      <div class="spinner-wrapper" *ngIf="isBusy">
        <mc-spinner></mc-spinner>
      </div>
    </mc-wizard-step>

    <mc-wizard-step
      label="{{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.OUTBOUND.TITLE' | translate }}"
    >
      <mc-api-integration-crowdstrike-outbound-step
        [hidden]="isBusy"
        [crowdstrikeIntegration]="crowdstrikeIntegration"
        (onSwitchMaliciousHashesEnabled)="switchMaliciousHashesEnabled($event)"
        (onIocOutboundActionSelected)="iocOutboundActionSelected($event)"
        (onSwitchMaliciousUrlsEnabled)="switchMaliciosUrls($event)"
        (onIocOutboundUrlActionSelected)="iocOutboundUrlActionSelected($event)"
        (onIocOutboundSeveritySelected)="iocOutboundSeveritySelected($event)"
        (onIocOutboundExpirationSelected)="iocOutboundExpirationSelected($event)"
      >
      </mc-api-integration-crowdstrike-outbound-step>
      <div class="spinner-wrapper" *ngIf="isBusy">
        <mc-spinner></mc-spinner>
      </div>
    </mc-wizard-step>

    <mc-wizard-step
      *mcCapabilities="'Temporary.Services.ApiApplications.Crowdstrike.Integration.PullMaliciousHashes'"
      label="{{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.INBOUND.TITLE' | translate }}"
    >
      <mc-api-integration-crowdstrike-inbound-step
        [hidden]="isBusy"
        [crowdstrikeIntegration]="crowdstrikeIntegration"
        (onSwitchPullMaliciousHashesEnabled)="switchPullMaliciousHashesEnabled($event)"
        (onSwitchRemediation)="switchRemediationRequired($event)"
      >
      </mc-api-integration-crowdstrike-inbound-step>
      <div class="spinner-wrapper" *ngIf="isBusy">
        <mc-spinner></mc-spinner>
      </div>
    </mc-wizard-step>

    <mc-wizard-step
      label="{{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.NOTIFICATIONS.TITLE' | translate }}"
    >
      <mc-api-integration-crowdstrike-notifications-step
        [emails]="crowdstrikeIntegration?.notificationAddresses"
        [groupsMap]="crowdstrikeIntegration?.notificationGroups"
        [hidden]="isBusy"
        (onAddGroup)="addGroup($event)"
        (onDeleteGroup)="deleteGroup($event)"
        (onDeleteEmail)="deleteEmail($event)"
        (onVerifyAndAddEmail)="verifyAndAddEmail($event)"
      >
      </mc-api-integration-crowdstrike-notifications-step>

      <div class="spinner-wrapper" *ngIf="isBusy">
        <mc-spinner></mc-spinner>
      </div>
    </mc-wizard-step>

    <mc-wizard-step
      label="{{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.SUMMARY.TITLE' | translate }}"
    >
      <mc-api-integration-crowdstrike-summary-step
        [crowdstrikeIntegration]="crowdstrikeIntegration"
        [crowdstrikePrerequisitesError]="crowdstrikePrerequisitesError"
        [hidden]="isBusy"
        (onSwitchCrowdstrikeIntegrationStatus)="switchCrowdstrikeIntegrationStatus($event)"
      >
      </mc-api-integration-crowdstrike-summary-step>

      <div class="spinner-wrapper" *ngIf="isBusy">
        <mc-spinner></mc-spinner>
      </div>
    </mc-wizard-step>
  </mc-wizard>

  <mc-footer-container>
    <div class="pull-right no-select mc-button-footer">
      <button class="btn" (click)="cancel()">
        {{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.BUTTONS.CANCEL' | translate }}
      </button>
      <button
        *ngIf="showPrevious()"
        class="btn btn-secondary panel-half-margin-right"
        [disabled]="isBusy"
        (click)="gotoPreviousStep()"
      >
        {{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.BUTTONS.PREVIOUS' | translate }}
      </button>
      <button
        *ngIf="showNext()"
        class="btn btn-primary"
        [disabled]="isBusy || !isStepValid()"
        (click)="gotoNextStep()"
      >
        {{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.BUTTONS.NEXT' | translate }}
      </button>
      <button
        *ngIf="showCreate()"
        class="btn btn-primary"
        [disabled]="isBusy || !isStepValid()"
        (click)="create()"
      >
        {{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.BUTTONS.FINISH' | translate }}
      </button>
      <button
        *ngIf="showUpdate()"
        class="btn btn-primary"
        [disabled]="isBusy || !isStepValid()"
        (click)="update()"
      >
        {{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.BUTTONS.SAVE_AND_CLOSE' | translate }}
      </button>
    </div>
  </mc-footer-container>
</mc-layout-detail-wizard>
