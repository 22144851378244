"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CancelConfirmationModalComponent = void 0;
const wizardActions = require("../../actions/wizard.actions");
class CancelConfirmationModalComponent {
    constructor(store, data) {
        this.store = store;
        this.data = data;
    }
    getModalTitle() {
        return this.data.title;
    }
    onYes() {
        const redirect = this.data.returnActions.length === 0;
        this.data.returnActions.forEach(action => this.store.dispatch(action));
        this.store.dispatch(new wizardActions.CancelYesAction({ title: this.data.wizardId, redirect }));
    }
    onNo() {
        this.store.dispatch(new wizardActions.CancelNoAction());
    }
}
exports.CancelConfirmationModalComponent = CancelConfirmationModalComponent;
