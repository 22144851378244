<mc-layout-list-tabs keyTitle="Hero Page"
                     keyDescription="My Description"
                     extendClass="my-custom-class"
                     kbUrl="http://www.mimecast.com/">
  <mc-extra-container>
    Hello Extra World
  </mc-extra-container>
  <mc-tab-group [initialActiveTabIndex]="0">
    <mc-tab name="Tab 1">
      <h2>This is for Dev Only - Don't Commit anything here</h2>
      <div>
        Hello Tab 1 World of ADCON :D <br><br><br><br><br><br><br>
      </div>
    </mc-tab>
    <mc-tab name="Tab 2">
      <h2>This is for Dev Only - Don't Commit anything here</h2>
      <div>
        Hello Tab 2 of ADCON :D <br><br><br><br><br><br><br>
      </div>
    </mc-tab>
  </mc-tab-group>
</mc-layout-list-tabs>
