"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiOktaIntegrationAsideViewComponent = void 0;
const core_1 = require("@angular/core");
const folder_helper_1 = require("app-new/api-applications/utils/folder-helper");
class ApiOktaIntegrationAsideViewComponent {
    constructor() {
        this.onCloseClicked = new core_1.EventEmitter();
        this.onEditClicked = new core_1.EventEmitter();
        this.onDeleteClicked = new core_1.EventEmitter();
        this.onEnableClicked = new core_1.EventEmitter();
        this.onDisableClicked = new core_1.EventEmitter();
        this.onRotateTokenClicked = new core_1.EventEmitter();
    }
    get description() {
        return folder_helper_1.default.createDescription(this.oktaIntegration.folderList, this.oktaIntegration.rootFolder);
    }
    get oktaEmailList() {
        if (this.oktaIntegration) {
            return this.oktaIntegration.contactAddresses;
        }
        return [];
    }
}
exports.ApiOktaIntegrationAsideViewComponent = ApiOktaIntegrationAsideViewComponent;
