
    <mc-okta-evidence-based-control-integration-aside-view
      [integration]="integration"
      [oktaEvidenceBasedControlIntegration]="oktaEvidenceBasedControlIntegration$ | async"
      [enabled]="enabled$ | async"
      (onEnableClicked)="enable()"
      (onDisableClicked)="disable()"
      (onEditClicked)="edit()"
      (onDeleteClicked)="delete()"
      (onCloseClicked)="close()"
    >
    </mc-okta-evidence-based-control-integration-aside-view>
  