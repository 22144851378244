"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasDeviceSettingsInDB = exports.hasGenericError = exports.isUpdating = exports.isLoaded = exports.isRemediationEnabled = exports.getDeviceSettings = exports.getSettings = exports.reducer = exports.initialState = void 0;
const settingsAction = require("../../actions/settings/settings.actions");
exports.initialState = {
    settings: {
        id: null,
        enabled: false,
        mode: 'not_set',
        notifyGroup: { description: '$I18N_REMEDIATION_SETTINGS_TAB.GROUPS.DEFAULT_MSG' },
        excludeGroup: { description: '$I18N_REMEDIATION_SETTINGS_TAB.GROUPS.DEFAULT_MSG' },
        notifyInternalRecipients: false,
        mepEnabled: false,
        mepNotifyInternalRecipients: false
    },
    deviceSettings: {
        enableFileRemediation: false,
        enableFileRemediationNotifications: false
    },
    isLoaded: false,
    genericError: false,
    isUpdating: false,
    hasDeviceSettingsInDB: true
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case settingsAction.GET_SETTINGS:
            return Object.assign(Object.assign({}, state), { isLoaded: false, genericError: false });
        case settingsAction.GET_SETTINGS_SUCCESS:
            return Object.assign(Object.assign({}, state), { isLoaded: true, genericError: false, settings: Object.assign({}, action.payload) });
        case settingsAction.GET_SETTINGS_FAIL:
            return Object.assign(Object.assign({}, state), { isLoaded: false, genericError: true });
        case settingsAction.GET_DEVICE_SETTINGS:
            return Object.assign(Object.assign({}, state), { isLoaded: false, genericError: false, hasDeviceSettingsInDB: true });
        case settingsAction.GET_DEVICE_SETTINGS_SUCCESS:
            return Object.assign(Object.assign({}, state), { isLoaded: true, genericError: false, deviceSettings: Object.assign({}, action.payload), hasDeviceSettingsInDB: true });
        case settingsAction.GET_DEVICE_SETTINGS_FAIL:
            return Object.assign(Object.assign({}, state), { isLoaded: false, genericError: true, hasDeviceSettingsInDB: true });
        case settingsAction.DEVICE_SETTINGS_NOT_SET:
            return Object.assign(Object.assign({}, state), { isLoaded: true, genericError: false, hasDeviceSettingsInDB: false });
        case settingsAction.UPDATE_SETTINGS:
            return Object.assign(Object.assign({}, state), { isUpdating: true, genericError: false });
        case settingsAction.UPDATE_SETTINGS_SUCCESS:
            return Object.assign(Object.assign({}, state), { isUpdating: false, genericError: false, settings: Object.assign({}, action.payload) });
        case settingsAction.UPDATE_SETTINGS_FAIL:
            return Object.assign(Object.assign({}, state), { isUpdating: false });
        case settingsAction.SETTINGS_NOT_SET:
            return Object.assign(Object.assign({}, state), { isLoaded: true, genericError: false });
        case settingsAction.UPDATE_DEVICE_SETTINGS_SUCCESS:
            return Object.assign(Object.assign({}, state), { isUpdating: false, genericError: false, deviceSettings: Object.assign({}, action.payload), hasDeviceSettingsInDB: true });
        case settingsAction.UPDATE_DEVICE_SETTINGS_FAIL:
            return Object.assign(Object.assign({}, state), { isUpdating: false, hasDeviceSettingsInDB: true });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getSettings = (state) => state.settings;
exports.getSettings = getSettings;
const getDeviceSettings = (state) => state.deviceSettings;
exports.getDeviceSettings = getDeviceSettings;
const isRemediationEnabled = (state) => state.settings.enabled;
exports.isRemediationEnabled = isRemediationEnabled;
const isLoaded = (state) => state.isLoaded;
exports.isLoaded = isLoaded;
const isUpdating = (state) => state.isUpdating;
exports.isUpdating = isUpdating;
const hasGenericError = (state) => state.genericError;
exports.hasGenericError = hasGenericError;
const hasDeviceSettingsInDB = (state) => state.hasDeviceSettingsInDB;
exports.hasDeviceSettingsInDB = hasDeviceSettingsInDB;
