<mc-queue-config-details
  [queueDetails]="queueDetails$ | async"
  [loading]="queueLoading$ | async"
  [paginationInfo]="paginationInfo$ | async"
  (onLoadDetails)="loadDetails($event)"
  (onEdit)="edit($event)"
  (onClose)="close()"
  [hasEditPermissions]="hasEditPermission$ | async"
  [hasDeleteCapability]="hasDeleteCapability$ | async"
  (onDelete)="deleteQueueConfig($event)"
></mc-queue-config-details>
