<mc-table
  tableId="directory-sync/table"
  [isLoading]="isLoading$ | async"
  [highlightedRow]="selectedRow$ | async"
  [data]="integrations$ | async"
  [columns]="columns"
  (rowClick)="viewConnection($event)"
  translatePrefix="$I18N_DIRECTORY_SYNC_TABLE"
>
  <button
    class="mc-table-actions btn btn-primary mc-create-button"
    *mcCapabilities="'Permission.DIRECTORY_SYNC_EDIT'"
    (click)="createConnection()"
  >
    {{ '$I18N_DIRECTORY_SYNC_TABLE.ACTIONS.CREATE' | translate }}
  </button>
  <button
    class="mc-table-actions btn btn-secondary mc-synchronize-button"
    (click)="startConnectionSynchronization()"
    [disabled]="isSyncRunning$ | async"
  >
    <i *ngIf="isSyncRunning$ | async" class="icon-arrows-ccw" aria-hidden="true"></i>
    {{ getSyncButtonLabel() | translate }}
  </button>
  <button *ngIf="isSyncReportAvailable"
    class="mc-table-actions btn btn-text mc-last-sync-report-button"
    (click)="getLastSynchronizationReport()"
  >
    <i *ngIf="isReportRunning" class="icon-arrows-ccw" aria-hidden="true"></i>
    {{ '$I18N_DIRECTORY_SYNC_TABLE.ACTIONS.LAST_SYNC_REPORT' | translate }}
  </button>
  <button *ngIf="isOnboardingRedirectEnabled" (click)="navigateToOnboarding()" class="mc-table-actions btn btn-secondary" data-test="onboarding-btn">{{ '$I18N_DIRECTORY_SYNC_TABLE.ACTIONS.RETURN_TO_ONBOARDING' | translate }}</button>

  <mc-empty-results
    iconClass="far fa-list-alt"
    keyTitle="$I18N_DIRECTORY_SYNC_TABLE.NO_DATA.TITLE"
  >
    <mc-empty-body>
      <div>
        {{ '$I18N_DIRECTORY_SYNC_TABLE.NO_DATA.DESCRIPTION.LINE_1' | translate }}
        <a
          *mcCapabilities="'Permission.DIRECTORY_SYNC_EDIT'"
          class="mc-empty-table-link"
          (click)="createConnection()"
          >{{
            '$I18N_DIRECTORY_SYNC_TABLE.NO_DATA.DESCRIPTION.LINK_2' | translate
          }}</a
        >
        <span *mcCapabilities="'!Permission.DIRECTORY_SYNC_EDIT'">{{
          '$I18N_DIRECTORY_SYNC_TABLE.NO_DATA.DESCRIPTION.LINK_2' | translate
        }}</span>
        {{ '$I18N_DIRECTORY_SYNC_TABLE.NO_DATA.DESCRIPTION.LINE_3' | translate }}
      </div>
    </mc-empty-body>
  </mc-empty-results>

  <mc-filters
    [showPagination]="true"
    [metadata]="pagingMetadata$ | async"
    (paginatorChange)="changePage($event)"
  >
    <form class="mc-filters-bundle-column-values-form" [formGroup]="searchForm">
      <mc-table-filter-field-search
        class="mc-search-input"
        formControlName="name"
        [placeHolder]="'$I18N_DIRECTORY_SYNC_TABLE.SEARCH.PLACEHOLDER'">
      </mc-table-filter-field-search>
    </form>

    <mc-filters-bundle-column-values-modal [sections]="filterSections"
                                           (filtersChange)="changeColumnFilter($event)">
    </mc-filters-bundle-column-values-modal>
  </mc-filters>

  <mc-column key="serverType">
    <mc-body-cell *mcBodyCellDef="let row" class="mc-integration-server-type">
      <span>
        <i class="mc-icon {{serverTypeIcons[row.serverType]}}" aria-hidden="true"></i>{{ getServerTypeKey(row) | translate }}
      </span>
    </mc-body-cell>
  </mc-column>

  <mc-column key="enabled">
    <mc-body-cell *mcBodyCellDef="let row" class="mc-integration-status">
      <span *ngIf="row.enabled" class="mc-integration-status--enable">
        <i class="fas fa-check-circle" aria-hidden="true"></i>{{ '$I18N_DIRECTORY_SYNC_TABLE.ENABLE' | translate }}
      </span>
      <span *ngIf="!row.enabled" class="mc-integration-status--disable">
        <i class="fas fa-times-circle" aria-hidden="true"></i>{{ '$I18N_DIRECTORY_SYNC_TABLE.DISABLE' | translate }}
      </span>
    </mc-body-cell>
  </mc-column>

  <mc-column key="lastSync">
    <mc-body-cell *mcBodyCellDef="let row" class="mc-integration-last-sync-date-time">
      {{ (row.lastSync | mcDate) || (lastOutcome.not_active.key | translate) }}
    </mc-body-cell>
  </mc-column>

  <mc-column key="status">
    <mc-body-cell *mcBodyCellDef="let row" class="mc-integration-last-outcome">
      <span class="{{lastOutcome[row.status].classes}}">
        <i class="fas {{lastOutcome[row.status].icon}}" aria-hidden="true"></i>{{ lastOutcome[row.status].key | translate }}
      </span>
    </mc-body-cell>
  </mc-column>

  <mc-column-actions
    key="action"
    mcShowColumnConfig
    [columnsAlwaysVisible]="columnsAlwaysVisible"
    [columnsToStartHidden]="columnsStartHidden"
  >
    <mc-row-actions *mcRowActions="let row">
      <li
        mcRowAction="$I18N_DIRECTORY_SYNC_TABLE.ACTIONS.VIEW"
        (click)="viewConnection(row)"
      ></li>
      <ng-container *mcCapabilities="'Permission.DIRECTORY_SYNC_EDIT'">
        <li
          mcRowAction="$I18N_DIRECTORY_SYNC_TABLE.ACTIONS.EDIT"
          (click)="editConnection(row)"
        ></li>
        <li
          *ngIf="row.enabled"
          mcRowAction="$I18N_DIRECTORY_SYNC_TABLE.ACTIONS.DISABLE"
          (click)="disableConnection(row)"
        ></li>
        <li
          *ngIf="!row.enabled"
          mcRowAction="$I18N_DIRECTORY_SYNC_TABLE.ACTIONS.ENABLE"
          (click)="enableConnection(row)"
        ></li>
        <li
          mcRowAction="$I18N_DIRECTORY_SYNC_TABLE.ACTIONS.DELETE"
          (click)="deleteConnection(row)"
        ></li>
      </ng-container>
    </mc-row-actions>
  </mc-column-actions>
</mc-table>
