
  <mc-collapsable-panel
    header="{{isSidebar ? '$I18N_SIEM_CHANNEL_DETAILS.SUMMARY_PANEL' : '$I18N_SIEM_CHANNEL_DETAILS.DETAILS_PANEL'}}"
    [isExpanded]="true" >
    <div class="mc-summary-block">
      <div class="mc-detailed-list mc-swg-policy-summary-block">
        <div class="row mc-detailed-list-row no-padding-top">
          <div class="col-xs-4 mc-detailed-list-label">
            {{ '$I18N_SIEM_CHANNEL_DETAILS.NAME' | translate }}
          </div>
          <div class="mc-detailed-list-column">{{ subscribedChannel.name }}</div>
        </div>

        <div class="row mc-detailed-list-row">
          <div class="col-xs-4 mc-detailed-list-label">
            {{ '$I18N_SIEM_CHANNEL_DETAILS.DESCRIPTION' | translate }}
          </div>
          <div class="mc-detailed-list-column">
            {{ subscribedChannel.description }}
          </div>
        </div>

        <div class="row mc-detailed-list-row">
          <div class="col-xs-4 mc-detailed-list-label">
            {{ '$I18N_SIEM_CHANNEL_DETAILS.TYPE' | translate }}
          </div>
          <div class="mc-detailed-list-column">
            {{ subscribedChannel.type }}
          </div>
        </div>
        <div *ngIf="isSidebar" class="row mc-detailed-list-row">
          <div class="col-xs-4 mc-detailed-list-label">
            {{ '$I18N_SIEM_CHANNEL_DETAILS.IDENTIFIER' | translate }}
          </div>
          <div class="mc-detailed-list-column">
            {{ subscribedChannel.uniqueId }}
            <a
              ngxClipboard
              class="pull-right cursor-pointer text-normal"
              data-test="siemChannelSidebarCopyUrlBtn"
              [cbContent]="channel.uniqueId"
              (cbOnSuccess)="copyIdentifierToClipboardSuccess()"
            >
              <em class="far fa-copy"></em>
            </a>
          </div>
        </div>
        <div  *ngIf="isSidebar" class="row mc-detailed-list-row">
          <div class="col-xs-4 mc-detailed-list-label">
            {{ '$I18N_SIEM_CHANNEL_DETAILS.LASTMODIFIED' | translate }}
          </div>
          <div class="mc-detailed-list-column">
            {{ subscribedChannel.lastModified | mcDate }}
          </div>
        </div>
      </div>
    </div>
  </mc-collapsable-panel>

  <div *ngIf="subscribedChannel">
    <div class="mc-events-details-panel mc-detailed-list "
         *ngFor="let product of subscribedChannel.products"
    >
      <mc-collapsable-panel
        header="{{product.name}}"
        [isExpanded]="true" >
        <div *ngFor="let group of product.groups; let isLast = last" [ngClass]="{'mc-group-padding': !isLast }">
          <div *ngFor="let event of group.events; let isFirst = first;">
            <div *ngIf="!event.hidden" class="mc-detailed-list-row">
              <div class="col-xs-4 mc-detailed-list-label">
                <span *ngIf="isFirst">{{group.name}}</span>
              </div>
              <div class="mc-detailed-list-column">
                {{ event.name }}
              </div>
            </div>
          </div>
        </div>
      </mc-collapsable-panel>
    </div>
  </div>
