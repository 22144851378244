<div class="mc-swg-settings">
  <form novalidate [formGroup]="form">
    <div class="mc-settings-overlay" *ngIf="!isEditable || isEndpointSettingsUpdating"></div>
    <div class="mc-section-tamper-protection">
      <div class="mc-sub-heading mc-password-section">
        {{ '$I18N_ENDPOINT_SETTINGS.SUB_HEADERS.SETTINGS.DISABLEMENT_PASSWORD' | translate }}
      </div>
      <div class="mc-description-password">
        {{ '$I18N_ENDPOINT_SETTINGS.DESCRIPTION.SETTINGS.DISABLEMENT' | translate }}
      </div>
      <div *ngIf="!!password" class="mc-password-field-container">
        <div class="mc-password">
          {{ '$I18N_ENDPOINT_SETTINGS.LABELS.SETTINGS.CURRENT_PASSWORD' | translate }}
        </div>
        <input
          class="mc-password-field"
          type="{{ isAntiTamperPasswordInputType ? 'password' : 'text' }}"
          formControlName="tamperProtectPassword"
          [readonly]="true"
        />
        <i
          class="far {{ isAntiTamperPasswordInputType ? 'fa-eye' : 'fa-eye-slash' }}"
          tabindex="0"
          role="button"
          (click)="toggleAntiTamperPasswordInputType()"
          (keyup.enter)="toggleAntiTamperPasswordInputType()"
          (keyup.space)="toggleAntiTamperPasswordInputType()"
        >
        </i>
      </div>
      <div *ngIf="!!previousPassword" class="mc-password-field-container">
        <div class="mc-password">
          {{ '$I18N_ENDPOINT_SETTINGS.LABELS.SETTINGS.PREVIOUS_PASSWORD' | translate }}
        </div>
        <input
          class="mc-password-field"
          type="{{ isPreviousPasswordInputType ? 'password' : 'text' }}"
          formControlName="tamperProtectPreviousPassword"
          [readonly]="true"
        />
        <i
          class="far {{ isPreviousPasswordInputType ? 'fa-eye' : 'fa-eye-slash' }}"
          tabindex="0"
          role="button"
          (click)="togglePreviousPasswordInputType()"
          (keyup.enter)="togglePreviousPasswordInputType()"
          (keyup.space)="togglePreviousPasswordInputType()"
        >
        </i>
      </div>
      <div class="mc-actions-btns-container">
        <button
          class="btn btn-primary"
          ngxClipboard
          [cbContent]="password"
          (cbOnSuccess)="copyAntiTamperPasswordToClipboardSuccess()"
          [disabled]="!hasPassword"
        >
          {{ '$I18N_ENDPOINT_SETTINGS.LABELS.SETTINGS.BTN_COPY_PASSWORD' | translate }}
        </button>
        <mc-live-button
          class="mc-copy-pw-btn"
          overrideClasses="btn-secondary"
          [inverted]="false"
          [label]="'$I18N_ENDPOINT_SETTINGS.LABELS.SETTINGS.BTN_GENERATE_PASSWORD' | translate"
          [mcDisabled]="!isEditable || isPasswordLoading"
          [isLoading]="isPasswordLoading"
          (mcClick)="onGenerateAntiTamperPassword()"
        >
        </mc-live-button>
      </div>
      <div>
        <div class="mc-sub-heading">
          {{ '$I18N_ENDPOINT_SETTINGS.SUB_HEADERS.SETTINGS.UNINSTALLER_PASSWORD' | translate }}
        </div>
        <div class="mc-description-password">
          {{ '$I18N_ENDPOINT_SETTINGS.DESCRIPTION.SETTINGS.UNINSTALLER' | translate }}
        </div>


        <div *ngIf="!!_uninstallPassword" class="mc-password-field-container">
          <div class="mc-uninstall-password-toggle" *ngIf="hasUninstallPasswordEnabled">
            <mc-switch
              [ngClass]="{ 'mc-settings-toggle': isEndpointSettingsUpdating }"
              formControlName="enableUninstallPassword"
              tabindex="0"
              role="switch"
              [attr.aria-checked]="this.form.get('enableUninstallPassword').value"
              (keyup.enter)="this.onEnableUninstallPasswordToggle()"
              (keyup.space)="this.onEnableUninstallPasswordToggle()"
              (click)="this.onEnableUninstallPasswordToggle()"
            >
            </mc-switch>
            <span class="mc-toggle-label" *ngIf="form.get('enableUninstallPassword').value">
              {{ '$I18N_ENDPOINT_SETTINGS.LABELS.SETTINGS.TOGGLE_ENABLED' | translate }}
            </span>
            <span class="mc-toggle-label" *ngIf="!form.get('enableUninstallPassword').value">
              {{ '$I18N_ENDPOINT_SETTINGS.LABELS.SETTINGS.TOGGLE_DISABLED' | translate }}
            </span>
            <span
              class="mc-settings-update-display"
              *ngIf="isEndpointSettingsUpdating && currentControl === 'enableUninstallPassword'"
            >
            <mc-spinner class="mc-settings-spinner"></mc-spinner>{{ settingOperationMessage }}
            </span>
          </div >
          <div class="mc-password">
            {{ '$I18N_ENDPOINT_SETTINGS.LABELS.SETTINGS.CURRENT_PASSWORD' | translate }}
          </div>
          <input
            class="mc-password-field"
            type="{{ isUninstallPasswordInputType ? 'password' : 'text' }}"
            formControlName="uninstallPassword"
            [readonly]="true"
          />
          <i
            class="far {{ isUninstallPasswordInputType ? 'fa-eye' : 'fa-eye-slash' }}"
            tabindex="0"
            role="button"
            (click)="toggleUninstallPasswordInputType()"
            (keyup.enter)="toggleUninstallPasswordInputType()"
            (keyup.space)="toggleUninstallPasswordInputType()"
          >
          </i>
        </div>
        <div class="mc-actions-btns-container">
          <button
            class="btn btn-secondary"
            ngxClipboard
            [cbContent]="_uninstallPassword"
            (cbOnSuccess)="copyUninstallPasswordClipboardSuccess()"
            [disabled]="!hasUninstallPassword"
          >
            {{ '$I18N_ENDPOINT_SETTINGS.LABELS.SETTINGS.BTN_COPY_PASSWORD' | translate }}
          </button>
          <button
            *ngIf="!hasUninstallPasswordEnabled || (hasUninstallPasswordEnabled && form.get('enableUninstallPassword').value) "
            class="btn btn-secondary mc-copy-pw-btn"
            [disabled]="!isEditable || isPasswordLoading"
            (click)="onGenerateUninstallPassword()"
          >
            {{ '$I18N_ENDPOINT_SETTINGS.LABELS.SETTINGS.BTN_GENERATE_PASSWORD' | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
