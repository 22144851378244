<div class="swg-dns">
  <span class="heading-l">{{"$I18N_DNS_SETUP_HEADING" | translate}}</span>


  <div class="top-padding">
    {{"$II8N_DNS_SETUP_INSTRUCTIONS" | translate}} <br>
    <a href="{{kbLink}}" target="_blank"> {{'$I18N_DNS_SETUP_KB_LABEL' | translate}}</a>
  </div>

  <div class="top-padding">
    <div *ngFor="let server of (servers$ | async) ; let i=index">
      <i class="far fa-server" aria-hidden="true"></i>
      Server {{i+1}}:
      <span class="swg-dns">{{server}}</span>
    </div>
  </div>

  <div translate="$II8N_DNS_SETUP_INSTRUCTIONS_FINISHED">
  </div>

  <div class="top-padding">
  <button class="btn btn-primary" (click)="redirectToDns()">{{'$I18N_DNS_SETUP_REDIRECT_LABEL' | translate}}</button>
  </div>
</div>
