"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportsTabComponent = void 0;
const reports_actions_1 = require("app-new/archive/supervision/actions/reports.actions");
const reducers_1 = require("app-new/archive/supervision/reducers");
const moment = require("moment");
const reportConfig = require("./reports-tab.static-values");
class ReportsTabComponent {
    constructor(store) {
        this.store = store;
        this.reportTypes = [
            {
                label: '$I18N_ARCHIVE_SUPERVISION_REPORT_TAB.REPORTS.SELECT_OPTION',
                value: null
            },
            {
                label: '$I18N_ARCHIVE_SUPERVISION_REPORT_TAB.REPORTS.ACTIVITY',
                value: 'queue_activity'
            },
            {
                label: '$I18N_ARCHIVE_SUPERVISION_REPORT_TAB.REPORTS.VIOLATION',
                value: 'violation'
            },
            {
                label: '$I18N_ARCHIVE_SUPERVISION_REPORT_TAB.REPORTS.VIOLATION_COMMENTS',
                value: 'violation_comments'
            }
        ];
        this.dateRanges = [
            {
                id: 'SELECT_OPTION',
                label: '$I18N_ARCHIVE_SUPERVISION_REPORT_TAB.DATE_RANGES.SELECT_OPTION'
            },
            {
                id: 'LAST_TWO_WEEKS',
                label: '$I18N_ARCHIVE_SUPERVISION_REPORT_TAB.DATE_RANGES.PAST_TWO_WEEKS',
                range: this.createDateRange(14)
            },
            {
                id: 'LAST_THREE_WEEKS',
                label: '$I18N_ARCHIVE_SUPERVISION_REPORT_TAB.DATE_RANGES.PAST_THREE_WEEKS',
                range: this.createDateRange(21)
            },
            {
                id: 'LAST_FOUR_WEEKS',
                label: '$I18N_ARCHIVE_SUPERVISION_REPORT_TAB.DATE_RANGES.PAST_FOUR_WEEKS',
                range: this.createDateRange(28)
            }
        ];
        this.dateRangeMaxInterval = reportConfig.defaultInterval;
    }
    ngOnInit() {
        this.downloading$ = this.store.select(reducers_1.getReportDownloading);
        this.setDateRange(this.dateRangeMaxInterval);
    }
    createDateRange(diffInDays) {
        // @ts-ignore
        const range = {
            start: moment()
                .subtract(diffInDays, 'd')
                .startOf('d'),
            end: moment()
                .subtract(1, 'd')
                .endOf('d')
        };
        return range;
    }
    downloadReport(selection) {
        this.store.dispatch(new reports_actions_1.GetAction(selection));
    }
    setDateRange(interval) {
        this.dateRanges.push({
            id: 'CUSTOM',
            label: `$I18N_ARCHIVE_SUPERVISION_REPORT_TAB.DATE_RANGES.${interval}_DAYS_INTERVAL`
        });
    }
}
exports.ReportsTabComponent = ReportsTabComponent;
