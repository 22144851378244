"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetGroupsFail = exports.GetGroupsNotSet = exports.GetGroupsLDAPSuccess = exports.GetGroupsCloudSuccess = exports.GetGroupsLDAP = exports.GetGroupsCloud = exports.GET_GROUPS_NOT_SET = exports.GET_GROUPS_FAIL = exports.GET_GROUPS_LDAP_SUCCESS = exports.GET_GROUPS_CLOUD_SUCCESS = exports.GET_GROUPS_LDAP = exports.GET_GROUPS_CLOUD = void 0;
exports.GET_GROUPS_CLOUD = '[Identity] Retrieve cloud group';
exports.GET_GROUPS_LDAP = '[Identity] Retrieve ldap group';
exports.GET_GROUPS_CLOUD_SUCCESS = '[Identity] Retrieve cloud group success';
exports.GET_GROUPS_LDAP_SUCCESS = '[Identity] Retrieve ldap group success';
exports.GET_GROUPS_FAIL = '[Identity] Retrieve group fail';
exports.GET_GROUPS_NOT_SET = '[Identity] Retrieve group not set';
class GetGroupsCloud {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_GROUPS_CLOUD;
    }
}
exports.GetGroupsCloud = GetGroupsCloud;
class GetGroupsLDAP {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_GROUPS_LDAP;
    }
}
exports.GetGroupsLDAP = GetGroupsLDAP;
class GetGroupsCloudSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_GROUPS_CLOUD_SUCCESS;
    }
}
exports.GetGroupsCloudSuccess = GetGroupsCloudSuccess;
class GetGroupsLDAPSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_GROUPS_LDAP_SUCCESS;
    }
}
exports.GetGroupsLDAPSuccess = GetGroupsLDAPSuccess;
class GetGroupsNotSet {
    constructor() {
        this.type = exports.GET_GROUPS_NOT_SET;
    }
}
exports.GetGroupsNotSet = GetGroupsNotSet;
class GetGroupsFail {
    constructor() {
        this.type = exports.GET_GROUPS_FAIL;
    }
}
exports.GetGroupsFail = GetGroupsFail;
