"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResetStateAction = exports.SearchPaginationAction = exports.SearchPaginationStopLoadingAction = exports.SearchSelectRow = exports.SearchFailAction = exports.SearchSuccessAction = exports.SearchPermissionRevokedAction = exports.SearchPermissionGrantedAction = exports.RequestSearchReasonPermissionAction = exports.RequestSearchFiltersPermissionAction = exports.RequestSearchPermissionAction = exports.RESET_STATE = exports.SEARCH_PAGINATION_STOP_LOADING = exports.SEARCH_PAGINATION = exports.SEARCH_SELECT_ROW = exports.SEARCH_FAIL = exports.SEARCH_SUCCESS = exports.SEARCH_PERMISSION_GRANTED = exports.SEARCH_PERMISSION_REVOKED = exports.REQUEST_SEARCH_REASON_PERMISSION = exports.REQUEST_SEARCH_FILTERS_PERMISSION = exports.REQUEST_SEARCH_PERMISSION = void 0;
exports.REQUEST_SEARCH_PERMISSION = '[Tracking] Request Search Permission';
exports.REQUEST_SEARCH_FILTERS_PERMISSION = '[Tracking] Request Search Filters Permission';
exports.REQUEST_SEARCH_REASON_PERMISSION = '[Tracking] Request Search Reason Permission';
exports.SEARCH_PERMISSION_REVOKED = '[Tracking] Search Permission Revoked';
exports.SEARCH_PERMISSION_GRANTED = '[Tracking] Search Permission Granted';
exports.SEARCH_SUCCESS = '[Tracking] Search Success';
exports.SEARCH_FAIL = '[Tracking] Search Fail';
exports.SEARCH_SELECT_ROW = '[Tracking] Search Select Row';
exports.SEARCH_PAGINATION = '[Tracking] Search Pagination';
exports.SEARCH_PAGINATION_STOP_LOADING = '[Tracking] Search Pagination Stop Loading';
exports.RESET_STATE = '[Tracking] Reset State';
class RequestSearchPermissionAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.REQUEST_SEARCH_PERMISSION;
    }
}
exports.RequestSearchPermissionAction = RequestSearchPermissionAction;
class RequestSearchFiltersPermissionAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.REQUEST_SEARCH_FILTERS_PERMISSION;
    }
}
exports.RequestSearchFiltersPermissionAction = RequestSearchFiltersPermissionAction;
class RequestSearchReasonPermissionAction {
    constructor(searchReason) {
        this.searchReason = searchReason;
        this.type = exports.REQUEST_SEARCH_REASON_PERMISSION;
    }
}
exports.RequestSearchReasonPermissionAction = RequestSearchReasonPermissionAction;
class SearchPermissionGrantedAction {
    constructor() {
        this.type = exports.SEARCH_PERMISSION_GRANTED;
    }
}
exports.SearchPermissionGrantedAction = SearchPermissionGrantedAction;
class SearchPermissionRevokedAction {
    constructor() {
        this.type = exports.SEARCH_PERMISSION_REVOKED;
    }
}
exports.SearchPermissionRevokedAction = SearchPermissionRevokedAction;
class SearchSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SEARCH_SUCCESS;
    }
}
exports.SearchSuccessAction = SearchSuccessAction;
class SearchFailAction {
    constructor() {
        this.type = exports.SEARCH_FAIL;
    }
}
exports.SearchFailAction = SearchFailAction;
class SearchSelectRow {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SEARCH_SELECT_ROW;
    }
}
exports.SearchSelectRow = SearchSelectRow;
class SearchPaginationStopLoadingAction {
    constructor() {
        this.type = exports.SEARCH_PAGINATION_STOP_LOADING;
    }
}
exports.SearchPaginationStopLoadingAction = SearchPaginationStopLoadingAction;
class SearchPaginationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SEARCH_PAGINATION;
    }
}
exports.SearchPaginationAction = SearchPaginationAction;
class ResetStateAction {
    constructor() {
        this.type = exports.RESET_STATE;
    }
}
exports.ResetStateAction = ResetStateAction;
