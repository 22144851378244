<ng-container *ngIf="showLdapSummary">
  <mc-ldap-test-connection-summary
    #ldapElem
    [info]="info"
    [supportLink]="supportLink$ | async"
    [useNewConnectivity]="isNewConnectivityEnabled() | async"
    (loadComplete)="onChangeRestartButtonState($event)"
    (loadNextData)="onChangeCallService($event)">
  </mc-ldap-test-connection-summary>
</ng-container>
<ng-container *ngIf="showAzureSummary">
  <mc-azure-test-connection-summary
    #azureElem
    [info]="info"
    [useAzureStandardHecate]="isMigratedAzureStandardConnection() && (isAzureStandardHecateCapsEnabled() | async)"
    [useAzureGccHecate]="isMigratedGccConnection() && (isAzureGccHecateCapsEnabled() | async)"
    (loadComplete)="onChangeRestartButtonState($event)"
    (loadNextData)="onChangeCallService($event)">
  </mc-azure-test-connection-summary>
</ng-container>
<ng-container *ngIf="showGsuiteSummary">
  <mc-gsuite-test-connection-summary
    #gsuiteElem
    [info]="info"
    (loadComplete)="onChangeRestartButtonState($event)"
    (loadNextData)="onChangeCallService($event)">
  </mc-gsuite-test-connection-summary>
</ng-container>
