"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isShowingKey = exports.isGettingKey = exports.reducer = exports.initialState = void 0;
const actions = require("../../actions");
exports.initialState = {
    gettingKey: false,
    showingKey: false,
    keys: {}
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.ApiGatewayListActionTypes.OpenViewApiApplication:
            return Object.assign(Object.assign({}, state), { gettingKey: false, showingKey: false });
        case actions.ApiGatewayViewActionTypes.GetApiApplicationKey:
            return Object.assign(Object.assign({}, state), { gettingKey: true, showingKey: false });
        case actions.ApiGatewayViewActionTypes.GetApiApplicationKeySuccess:
            return Object.assign(Object.assign({}, state), { gettingKey: false, showingKey: true, keys: Object.assign(Object.assign({}, state.keys), { [action.payload.id]: action.payload.appKey }) });
        case actions.ApiGatewayViewActionTypes.ShowApiApplicationKey:
            return Object.assign(Object.assign({}, state), { showingKey: true });
        case actions.ApiGatewayViewActionTypes.HideApiApplicationKey:
            return Object.assign(Object.assign({}, state), { showingKey: false });
        default:
            return state;
    }
}
exports.reducer = reducer;
const isGettingKey = (state) => state.gettingKey;
exports.isGettingKey = isGettingKey;
const isShowingKey = (state) => state.showingKey;
exports.isShowingKey = isShowingKey;
