"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdconTableColumnsVisibilityPreferencesService = void 0;
const user_actions_1 = require("app-new/core/actions/user.actions");
const reducers = require("app-new/core/reducers");
const operators_1 = require("rxjs/operators");
class AdconTableColumnsVisibilityPreferencesService {
    constructor(store) {
        this.store = store;
    }
    getVisibleColumns(tableId) {
        return this.store.select(reducers.getAdminPreferences).pipe(operators_1.map((allAdminPreferences) => {
            const allTablesPrefs = this.getAllTablesActionsPreferences(allAdminPreferences);
            const tableColumns = (tableId && allTablesPrefs[tableId]) || [];
            return tableColumns;
        }));
    }
    saveVisibleColumns(tableId, columns) {
        if (tableId) {
            this.store
                .select(reducers.getAdminPreferences)
                .pipe(operators_1.first())
                .subscribe((allAdminPreferences) => {
                const allTablesPrefs = this.getAllTablesActionsPreferences(allAdminPreferences);
                allTablesPrefs[tableId] = columns;
                this.store.dispatch(new user_actions_1.PreferencesSaveAction({ tableActionPluginUserColumns: allTablesPrefs }));
            });
        }
    }
    getAllTablesActionsPreferences(allAdminPreferences) {
        const allAdminPreferencesSafe = allAdminPreferences || {};
        return Object.assign({}, allAdminPreferencesSafe.tableActionPluginUserColumns);
    }
}
exports.AdconTableColumnsVisibilityPreferencesService = AdconTableColumnsVisibilityPreferencesService;
