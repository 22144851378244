"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Folder = void 0;
const node_1 = require("./node");
class Folder extends node_1.Node {
    constructor(content, contentType, escapedFullPath, fullPath, wellKnownType, subfolderCount, id, name, lastChangeId, lastChangeType, selected, modified, parentId, deleted) {
        super(id, name, lastChangeId, lastChangeType, modified, parentId, deleted);
        this.content = content;
        this.contentType = contentType;
        this.escapedFullPath = escapedFullPath;
        this.fullPath = fullPath;
        this.wellKnownType = wellKnownType;
        this.subfolderCount = subfolderCount;
        this.selected = selected;
    }
    getContent() {
        return this.content;
    }
    getContentType() {
        return this.contentType;
    }
    getEscapedFullPath() {
        return this.escapedFullPath;
    }
    getFullPath() {
        return this.fullPath;
    }
    getWellKnownType() {
        return this.wellKnownType;
    }
    getSubFolderCount() {
        return this.subfolderCount;
    }
    isSelected() {
        return this.selected;
    }
    export() {
        throw new Error('Method not implemented.');
    }
    restore() {
        throw new Error('Method not implemented.');
    }
    createSnapshot() {
        throw new Error('Method not implemented.');
    }
}
exports.Folder = Folder;
