"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const pipes_1 = require("app-new/api-applications/pipes");
const notification_actions_1 = require("app-new/components/notification/actions/notification.actions");
const actions = require("app-new/api-applications/actions");
const aside_1 = require("app-new/api-applications/components/integration/aside");
class ListEffects {
    constructor(actions$, integrationService, asideService, translateService, stateService, cortexApiService, wildfireApiService, crowdstrikeApiService, oktaApiService, oktaEvidenceBasedControlApiService) {
        this.actions$ = actions$;
        this.integrationService = integrationService;
        this.asideService = asideService;
        this.translateService = translateService;
        this.stateService = stateService;
        this.cortexApiService = cortexApiService;
        this.wildfireApiService = wildfireApiService;
        this.crowdstrikeApiService = crowdstrikeApiService;
        this.oktaApiService = oktaApiService;
        this.oktaEvidenceBasedControlApiService = oktaEvidenceBasedControlApiService;
        this.getAllIntegrations$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationListActionTypes.GetAllIntegrations), operators_1.switchMap((action) => {
            const hasError = action && action.payload && action.payload.hasError ? action.payload.hasError : undefined;
            return this.integrationService.getAllIntegrations(hasError).pipe(operators_1.map((response) => new actions.GetAllIntegrationsSuccessAction({ integrations: response })), operators_1.catchError(() => rxjs_1.of(new actions.ApiApplicationFailureAction('$I18N_API_APPLICATIONS_LIST.NOTIFICATIONS.ERRORS.INTEGRATIONS_LIST'))));
        }));
        this.getIntegrationByExternalIdAndType$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationListActionTypes.GetCortexIntegrationByExternalIdAndType), operators_1.switchMap((action) => {
            return this.integrationService
                .getIntegration(action.payload.externalId, true)
                .pipe(operators_1.map((response) => new actions.GetIntegrationAction({ id: response.id, type: 'cortex' })));
        }));
        this.openViewIntegration$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationListActionTypes.OpenViewIntegration), operators_1.tap((action) => {
            this.closeViewAside();
            if (action.payload.integration.integrationType === 'cortex') {
                this.viewAside = this.asideService.open(aside_1.ApiCortexIntegrationAsideComponent, {
                    size: 'md',
                    hasBackdrop: true,
                    data: action.payload.integration
                });
            }
            else if (action.payload.integration.integrationType === 'crowdstrike') {
                this.viewAside = this.asideService.open(aside_1.ApiCrowdstrikeIntegrationAsideComponent, {
                    size: 'md',
                    hasBackdrop: true,
                    data: action.payload.integration
                });
            }
            else if (action.payload.integration.integrationType === 'scim_okta') {
                this.viewAside = this.asideService.open(aside_1.ApiOktaIntegrationAsideComponent, {
                    size: 'md',
                    hasBackdrop: true,
                    data: action.payload.integration
                });
            }
            else if (action.payload.integration.integrationType === 'okta_evidence_based_control') {
                this.viewAside = this.asideService.open(aside_1.ApiOktaEvidenceBasedControlIntegrationAsideComponent, {
                    size: 'md',
                    hasBackdrop: true,
                    data: action.payload.integration
                });
            }
            else {
                this.viewAside = this.asideService.open(aside_1.ApiWildfireIntegrationAsideComponent, {
                    size: 'md',
                    hasBackdrop: true,
                    data: action.payload.integration
                });
            }
        }), operators_1.map((action) => {
            return new actions.GetIntegrationAction({
                id: action.payload.integration.id,
                type: action.payload.integration.integrationType
            });
        }));
        this.closeViewIntegration$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationListActionTypes.CloseViewIntegration, actions.IntegrationCreateActionTypes.StartIntegrationWildfire), operators_1.tap(() => {
            this.closeViewAside();
        }));
        this.disableIntegration$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationListActionTypes.DisableIntegration), operators_1.switchMap((action) => {
            return this.integrationService.disableIntegration(action.payload.integration.id).pipe(operators_1.map((response) => {
                if (response.success) {
                    return new actions.DisableIntegrationSuccessAction(action.payload);
                }
                return new actions.DisableIntegrationFailAction();
            }));
        }));
        this.disableIntegrationFail$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationListActionTypes.DisableIntegrationFail), operators_1.switchMap(() => {
            return [
                new actions.CloseViewIntegrationAction(),
                new notification_actions_1.NotificationShowAction({
                    type: 'error',
                    config: {
                        msg: this.translateService.instant('$I18N_API_APPLICATIONS_LIST.NOTIFICATIONS.ACTIONS.INTEGRATION_DISABLED_FAIL')
                    }
                })
            ];
        }));
        this.disableIntegrationSuccess$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationListActionTypes.DisableIntegrationSuccess), operators_1.switchMap((action) => {
            return [
                new notification_actions_1.NotificationShowAction({
                    type: 'success',
                    config: {
                        msg: this.translateService.instant(`$I18N_API_APPLICATIONS_LIST.NOTIFICATIONS.ACTIONS.${action.payload.integration.integrationType === 'scim_okta'
                            ? 'OKTA_INTEGRATION_DISABLED'
                            : 'INTEGRATION_DISABLED'}`)
                    }
                }),
                new actions.GetAllIntegrationsAction(),
                new actions.GetIntegrationAction({
                    id: action.payload.integration.id,
                    type: action.payload.integration.integrationType
                })
            ];
        }));
        this.enableIntegration$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationListActionTypes.EnableIntegration), operators_1.switchMap((action) => {
            return this.integrationService.enableIntegration(action.payload.integration.id).pipe(operators_1.map((response) => {
                if (response.success) {
                    return new actions.EnableIntegrationSuccessAction(action.payload);
                }
                return new actions.EnableIntegrationFailAction();
            }));
        }));
        this.enableIntegrationFail$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationListActionTypes.EnableIntegrationFail), operators_1.switchMap(() => {
            return [
                new actions.CloseViewIntegrationAction(),
                new notification_actions_1.NotificationShowAction({
                    type: 'error',
                    config: {
                        msg: this.translateService.instant('$I18N_API_APPLICATIONS_LIST.NOTIFICATIONS.ACTIONS.INTEGRATION_ENABLED_FAIL')
                    }
                })
            ];
        }));
        this.enableIntegrationSuccess$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationListActionTypes.EnableIntegrationSuccess), operators_1.switchMap((action) => {
            return [
                new notification_actions_1.NotificationShowAction({
                    type: 'success',
                    config: {
                        msg: this.translateService.instant(`$I18N_API_APPLICATIONS_LIST.NOTIFICATIONS.ACTIONS.${action.payload.integration.integrationType === 'scim_okta'
                            ? 'OKTA_INTEGRATION_ENABLED'
                            : 'INTEGRATION_ENABLED'}`)
                    }
                }),
                new actions.GetAllIntegrationsAction(),
                new actions.GetIntegrationAction({
                    id: action.payload.integration.id,
                    type: action.payload.integration.integrationType
                })
            ];
        }));
        this.createCortexIntegration$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationListActionTypes.OpenCreateIntegration), operators_1.switchMap(() => [
            new actions.CancelOktaIntegrationWidgetAction(),
            new actions.OpenCreateIntegrationCompletedAction()
        ]));
        this.editCortexIntegration$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationListActionTypes.OpenEditIntegration), operators_1.switchMap((action) => {
            if (action.payload.integration.integrationType === 'wildfire') {
                return this.wildfireApiService
                    .getWildfireIntegration(action.payload.integration.id)
                    .pipe(operators_1.switchMap((response) => [
                    new actions.OpenWildfireIntegrationAction(),
                    new actions.GetWildfireIntegrationCompleteAction(response)
                ]));
            }
            else if (action.payload.integration.integrationType === 'crowdstrike') {
                return this.crowdstrikeApiService
                    .getCrowdstrikeIntegration(action.payload.integration.id)
                    .pipe(operators_1.switchMap((response) => [
                    new actions.GetCrowdstrikeIntegrationCompleteAction(response),
                    new actions.OpenCrowdstrikeIntegrationAction()
                ]));
            }
            else if (action.payload.integration.integrationType === 'scim_okta') {
                return this.oktaApiService
                    .getOktaIntegration(action.payload.integration.id)
                    .pipe(operators_1.switchMap((response) => [
                    new actions.GetOktaIntegrationCompleteAction(response),
                    new actions.OpenOktaIntegrationAction()
                ]));
            }
            else if (action.payload.integration.integrationType === 'okta_evidence_based_control') {
                return this.oktaEvidenceBasedControlApiService
                    .getOktaEvidenceBasedControlIntegration(action.payload.integration.id)
                    .pipe(operators_1.switchMap((response) => [
                    new actions.GetOktaEvidenceBasedControlIntegrationCompleteAction(response),
                    new actions.StartIntegrationOktaEvidenceBasedControlAction()
                ]));
            }
            else {
                return this.cortexApiService
                    .getCortexIntegrationVersion1(action.payload.integration.id, 1)
                    .pipe(operators_1.switchMap((response) => [
                    new actions.GetCortexIntegrationVersion1CompleteAction(response),
                    new actions.OpenEditIntegrationCompletedAction()
                ]));
            }
        }), operators_1.catchError(() => rxjs_1.of(new actions.GetIntegrationVersion1FailAction())));
        this.openCreateIntegrationWizard$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationListActionTypes.OpenCreateIntegrationCompleted), operators_1.tap(() => {
            this.stateService.$state.go('api-integration-select');
        }));
        this.openEditIntegrationWizard$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationListActionTypes.OpenEditIntegrationCompleted), operators_1.tap(() => {
            this.stateService.$state.go('api-cortex-integration-edit');
        }));
        this.openWildfireIntegrationWizard$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationWildfireActionTypes.OpenWildfireIntegration), operators_1.tap(() => {
            this.stateService.$state.go('api-integration-wildfire');
        }));
        this.openCrowdstrikeIntegrationWizard$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationCrowdstrikeActionTypes.OpenCrowdstrikeIntegration), operators_1.tap(() => {
            this.stateService.$state.go('api-integration-crowdstrike');
        }));
        this.openOktaIntegrationWizard$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationOktaActionTypes.OpenOktaIntegration), operators_1.tap(() => {
            this.stateService.$state.go('api-integration-okta-edit');
        }));
        this.getIntegrationDetails$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationCortexActionTypes.GetIntegration), operators_1.switchMap((action) => {
            if (action.payload.type === 'cortex') {
                return this.cortexApiService
                    .getCortexIntegrationVersion1(action.payload.id, 1)
                    .pipe(operators_1.map((response) => new actions.GetCortexIntegrationVersion1CompleteAction(response)));
            }
            else if (action.payload.type === 'crowdstrike') {
                return this.crowdstrikeApiService
                    .getCrowdstrikeIntegration(action.payload.id)
                    .pipe(operators_1.map((response) => new actions.GetCrowdstrikeIntegrationCompleteAction(response)));
            }
            else if (action.payload.type === 'scim_okta') {
                return this.oktaApiService
                    .getOktaIntegration(action.payload.id)
                    .pipe(operators_1.map((response) => new actions.GetOktaIntegrationCompleteAction(response)));
            }
            else if (action.payload.type === 'okta_evidence_based_control') {
                return this.oktaEvidenceBasedControlApiService
                    .getOktaEvidenceBasedControlIntegration(action.payload.id)
                    .pipe(operators_1.map((response) => new actions.GetOktaEvidenceBasedControlIntegrationCompleteAction(response)));
            }
            return this.wildfireApiService
                .getWildfireIntegration(action.payload.id)
                .pipe(operators_1.map((response) => new actions.GetWildfireIntegrationCompleteAction(response)));
        }), operators_1.catchError(() => rxjs_1.of(new actions.GetIntegrationVersion1FailAction())));
        this.pageApiApplications$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationListActionTypes.PageIntegrations), operators_1.delay(500, rxjs_1.asyncScheduler), operators_1.map(() => new actions.PageIntegrationsCompletedAction()));
        this.providerPipe = new pipes_1.IntegrationProviderPipe(this.translateService);
    }
    closeViewAside() {
        if (!!this.viewAside) {
            this.viewAside.close();
            this.viewAside = undefined;
        }
    }
}
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "getAllIntegrations$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "getIntegrationByExternalIdAndType$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "openViewIntegration$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], ListEffects.prototype, "closeViewIntegration$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "disableIntegration$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "disableIntegrationFail$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "disableIntegrationSuccess$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "enableIntegration$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "enableIntegrationFail$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "enableIntegrationSuccess$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "createCortexIntegration$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "editCortexIntegration$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], ListEffects.prototype, "openCreateIntegrationWizard$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], ListEffects.prototype, "openEditIntegrationWizard$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], ListEffects.prototype, "openWildfireIntegrationWizard$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], ListEffects.prototype, "openCrowdstrikeIntegrationWizard$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], ListEffects.prototype, "openOktaIntegrationWizard$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "getIntegrationDetails$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "pageApiApplications$", void 0);
exports.ListEffects = ListEffects;
