"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgPolicyCreateStepComponent = void 0;
class SwgPolicyCreateStepComponent {
    constructor() {
        this.hasBrowserIsolationEnabled = false;
        this.hasAppPolicyRemoveAllowEnabled = false;
    }
}
exports.SwgPolicyCreateStepComponent = SwgPolicyCreateStepComponent;
