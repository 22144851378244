"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueueEffect = void 0;
const actions = require("../actions/queue.actions");
const selectors = require("app-new/archive/supervision/reducers/index");
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const notification_actions_1 = require("app-new/components/notification/actions/notification.actions");
const operators_1 = require("rxjs/operators");
const queue_configs_factory_1 = require("app-new/archive/supervision/factories/queue-configs.factory");
const queue_reviewer_factory_1 = require("app-new/archive/supervision/factories/queue-reviewer.factory");
const queue_service_1 = require("../containers/wizard/services/queue.service");
class QueueEffect {
    constructor(actions$, queueService, translateService, store, stateService) {
        this.actions$ = actions$;
        this.queueService = queueService;
        this.translateService = translateService;
        this.store = store;
        this.stateService = stateService;
        this.create$ = this.actions$.pipe(effects_1.ofType(actions.QueueActions.CREATE), operators_1.withLatestFrom(this.store.select(selectors.getWizardById(queue_service_1.QueueService.WizardId))), operators_1.switchMap(([, wizardState]) => {
            return this.queueService.createQueue(wizardState).pipe(operators_1.map((response) => {
                if (response.hasErrors) {
                    return new actions.CreateActionFail(this.getNotificationMessage('FAILURE'), response.fail[0]);
                }
                return new actions.CreateActionSuccess(this.getNotificationMessage('SUCCESS'));
            }), operators_1.catchError(response => {
                return rxjs_1.of(new actions.CreateActionFail(this.getNotificationMessage('FAILURE'), response.fail[0]));
            }));
        }));
        this.createSuccess$ = this.actions$.pipe(effects_1.ofType(actions.QueueActions.CREATE_SUCCESS), operators_1.map((action) => {
            this.stateService.$state.go('archive-supervision-list', {
                callerWizardId: queue_service_1.QueueService.WizardId
            });
            return new notification_actions_1.NotificationShowSuccessAction(action.paypload);
        }));
        this.createFail$ = this.actions$.pipe(effects_1.ofType(actions.QueueActions.CREATE_FAIL), operators_1.switchMap((action) => {
            if (!action.failure.errors) {
                return [new notification_actions_1.NotificationShowFailAction(action.message)];
            }
            return action.failure.errors.map(error => new notification_actions_1.NotificationShowFailAction(this.translateService.instant(this.queueService.getApiErrorTranslateKey(this.prepareErrorCode(error)))));
        }));
        this.update$ = this.actions$.pipe(effects_1.ofType(actions.QueueActions.UPDATE), operators_1.switchMap((action) => {
            return this.queueService.updateQueue(action.queueId, action.queue).pipe(operators_1.map((response) => {
                if (response.hasErrors) {
                    return new actions.UpdateActionFail(this.getNotificationMessage('FAILURE'), response.fail[0]);
                }
                return new actions.UpdateActionSuccess(this.getNotificationMessage('UPDATE_SUCCESS'));
            }), operators_1.catchError(response => {
                return rxjs_1.of(new actions.UpdateActionFail(this.getNotificationMessage('UPDATE_FAILURE'), response.fail[0]));
            }));
        }));
        this.updateSuccess$ = this.actions$.pipe(effects_1.ofType(actions.QueueActions.UPDATE_SUCCESS), operators_1.map((action) => {
            this.stateService.$state.go('archive-supervision-list', {
                callerWizardId: queue_service_1.QueueService.WizardId
            });
            return new notification_actions_1.NotificationShowSuccessAction(action.message);
        }));
        this.updateFail$ = this.actions$.pipe(effects_1.ofType(actions.QueueActions.UPDATE_FAIL), operators_1.switchMap((action) => {
            if (!action.failure.errors) {
                return [new notification_actions_1.NotificationShowFailAction(action.message)];
            }
            return action.failure.errors.map(error => new notification_actions_1.NotificationShowFailAction(this.translateService.instant(this.queueService.getApiErrorTranslateKey(this.prepareErrorCode(error), true))));
        }));
        this.getEscalationQueues$ = this.actions$.pipe(effects_1.ofType(actions.QueueActions.GET_ESCALATIONQUEUES), operators_1.switchMap(() => {
            return this.queueService.getEscalationQueue(500).pipe(operators_1.map(response => {
                if (response.hasErrors) {
                    return new actions.GetEscalationQueuesFailAction(response.fail[0]);
                }
                const escalationQueues = response.first.queues.map(queue_configs_factory_1.QueueConfigFactory.BUILD_QUEUE_CONFIG);
                return new actions.GetEscalationQueuesSuccessAction(escalationQueues);
            }), operators_1.catchError(response => {
                return rxjs_1.of(new actions.GetEscalationQueuesFailAction(response.fail[0]));
            }));
        }));
        this.getReviewers$ = this.actions$.pipe(effects_1.ofType(actions.QueueActions.GET_REVIEWERS), operators_1.map((action) => action.filter), operators_1.switchMap(filter => {
            return this.queueService.getReviewers(filter).pipe(operators_1.map(response => {
                if (response.hasErrors) {
                    return new actions.GetReviewersFailAction(response.fail[0]);
                }
                const reviewer = response.first.reviewers.map(queue_reviewer_factory_1.QueueReviewerFactory.BUILD_QUEUE_REVIEWER);
                return new actions.GetReviewersSuccessAction(reviewer);
            }), operators_1.catchError(response => {
                return rxjs_1.of(new actions.GetReviewersFailAction(response.fail[0]));
            }));
        }));
    }
    getNotificationMessage(notificationkey) {
        const fullKey = `$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.NOTIFICATION.${notificationkey}`;
        return this.translateService.instant(fullKey);
    }
    prepareErrorCode(error) {
        if (error.field) {
            switch (error.code.toLowerCase()) {
                case 'err_validation_over_max':
                    switch (error.field.toLowerCase()) {
                        case 'labels':
                        case 'reviewers':
                        case 'escalationqueues':
                            return `${error.code}_${error.field}`;
                    }
                    break;
                case 'err_validation_null':
                case 'err_validation_blank':
                    switch (error.field.toLowerCase()) {
                        case 'queuetype':
                            return `${error.code}_${error.field}`;
                    }
            }
        }
        return error.code;
    }
}
__decorate([
    effects_1.Effect()
], QueueEffect.prototype, "create$", void 0);
__decorate([
    effects_1.Effect()
], QueueEffect.prototype, "createSuccess$", void 0);
__decorate([
    effects_1.Effect()
], QueueEffect.prototype, "createFail$", void 0);
__decorate([
    effects_1.Effect()
], QueueEffect.prototype, "update$", void 0);
__decorate([
    effects_1.Effect()
], QueueEffect.prototype, "updateSuccess$", void 0);
__decorate([
    effects_1.Effect()
], QueueEffect.prototype, "updateFail$", void 0);
__decorate([
    effects_1.Effect()
], QueueEffect.prototype, "getEscalationQueues$", void 0);
__decorate([
    effects_1.Effect()
], QueueEffect.prototype, "getReviewers$", void 0);
exports.QueueEffect = QueueEffect;
