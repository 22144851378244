"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.notifications = void 0;
const actions = require("../actions");
exports.notifications = {
    [actions.email.EMAIL_LOAD_FAIL]: { message: 'Message could not be loaded.', type: 'error' },
    [actions.search.CONTENT_SEARCH_FAIL]: {
        message: 'Content search failed. Please try again later.',
        type: 'error'
    },
    [actions.search.TOKEN_SEARCH_FAIL]: {
        message: 'Token search failed. Please try again later.',
        type: 'error'
    },
    [actions.search.INVALID_TOKEN]: {
        message: 'Invalid token ID. Please use a valid token.',
        type: 'error'
    }
};
