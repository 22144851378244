"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateAddSafetyTipComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const validators_1 = require("../../validators/validators");
class TemplateAddSafetyTipComponent {
    constructor(fb) {
        this.fb = fb;
        this.save = new core_1.EventEmitter();
        this.cancel = new core_1.EventEmitter();
        this.valueChanges = new core_1.EventEmitter();
        this.iconsToSelect = [
            { class: 'mc-icon-macros', text: 'Macros & Technology' },
            { class: 'mc-icon-password', text: 'Password Requests' },
            { class: 'mc-icon-sender', text: 'Sender Checking' },
            { class: 'mc-icon-bulkmail', text: 'Bulk Emails' },
            { class: 'mc-icon-links', text: 'Links to Login pages' },
            { class: 'mc-icon-panic', text: 'Panic Emails' },
            { class: 'mc-icon-money', text: 'Money Requests' },
            { class: 'mc-icon-link', text: 'Fake Links' },
            { class: 'mc-icon-requests', text: 'Unsolicited Requests' },
            { class: 'mc-icon-fraud', text: 'Impersonation & Fake identities' }
        ];
        this.form = this.fb.group({
            openingStatement: [
                '',
                [forms_1.Validators.required, validators_1.validateContainsWords, forms_1.Validators.maxLength(300)]
            ],
            icon: [this.iconsToSelect[5], [forms_1.Validators.required]]
        });
        this.form.valueChanges.subscribe(tip => this.valueChanges.emit({ index: this.selectedTipIndex, tip }));
    }
    set tipToEdit(tip) {
        if (tip) {
            this.form.patchValue(tip);
        }
        else {
            this.form.reset({ openingStatement: '', icon: this.iconsToSelect[5].class });
        }
    }
}
exports.TemplateAddSafetyTipComponent = TemplateAddSafetyTipComponent;
