<mc-layout-list-extra-container
  keyTitle="$I18N_CROWDSTRIKE_ACTIVITY_LOGS_PAGE.PAGE_HEADER"
  keyDescription="$I18N_CROWDSTRIKE_ACTIVITY_LOGS_PAGE.PAGE_DESCRIPTION"
>

  <mc-body-container>
    <mc-crowdstrike-activity-logs-table
    [activityLogs]="activityLogs$ | async"
    [isLoading]="isLoading$ | async"
    (onViewActivityLog)= "viewActivityLog($event)"
    >
    </mc-crowdstrike-activity-logs-table>
</mc-body-container>
</mc-layout-list-extra-container>  