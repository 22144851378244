"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeviceGroupDetailsStepComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const operators_1 = require("rxjs/operators");
class DeviceGroupDetailsStepComponent {
    constructor(fb) {
        this.fb = fb;
        this.updateDeviceGroupDescr = new core_1.EventEmitter();
    }
    ngOnInit() {
        this.deviceGroupPage = this.fb.group({
            description: [this.deviceGroup.description],
            name: [this.deviceGroup.name, [forms_1.Validators.required, forms_1.Validators.pattern(/[\S]+/)]]
        });
        this.deviceGroupPageSubscription = this.deviceGroupPage.valueChanges
            .pipe(operators_1.debounceTime(200))
            .subscribe((details) => {
            this.updateDeviceGroupDescr.emit(details);
        });
    }
    isValid() {
        return this.deviceGroupPage.status === 'VALID';
    }
    getHeader() {
        return this.isEditMode
            ? '$I18N_DEVICE_GROUPS_LIST.WIZARD.STEPS.DETAILS.EDIT_HEADER'
            : '$I18N_DEVICE_GROUPS_LIST.WIZARD.STEPS.DETAILS.HEADER';
    }
    ngOnDestroy() {
        this.deviceGroupPageSubscription.unsubscribe();
    }
}
exports.DeviceGroupDetailsStepComponent = DeviceGroupDetailsStepComponent;
