'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const moment = require("moment");
/**
 * Created by psambandam on 16/02/16.
 */
angular.module('recent-activity.service', [])
    .factory('recentActivityService', ['capabilitiesService', function (capabilitiesService) {
        const policyEditParams = {
            dateRange: {
                from: moment().subtract(1, 'days'),
                to: moment().endOf('day')
            },
            filters: [
                'account_logs',
                'archive_service_logs',
                'authentication_logs',
                'branding_logs',
                'continuity_services_logs',
                'journaling_logs',
                'mimecast_access_logs',
                'profile_group_logs',
                'user_account_and_role_logs'
            ]
        };
        const stats = {
            blocked: {
                label: '$I18N_DASHBOARD_ACTIVITY_OVER_24_HOURS_ATTACHMENT_BLOCKED_LABEL',
                goTo: "legacy.dashboard_past24hours({'uid':'attachments'})",
                cap: hasCap('Permission.ATTACHMENT_READ'),
                order: 0
            },
            held: {
                label: '$I18N_DASHBOARD_ACTIVITY_OVER_24_HOURS_HELD_LABEL',
                goTo: "message-center-held-messages({'uid':'held-messages'})",
                cap: hasCap('Permission.HELD_READ'),
                order: 1
            },
            linked: {
                label: '$I18N_DASHBOARD_ACTIVITY_OVER_24_HOURS_ATTACHMENT_LINKED_LABEL',
                goTo: "legacy.dashboard_past24hours({'uid':'attachments'})",
                cap: hasCap('Permission.ATTACHMENT_READ'),
                order: 2
            },
            rejected: {
                label: '$I18N_DASHBOARD_ACTIVITY_OVER_24_HOURS_REJECTED_LABEL',
                goTo: "message-center-rejected-and-deferred({'uid': 'rejected-and-deferred'})",
                cap: hasCap('Permission.MONITORING_REJECTIONS_READ'),
                order: 3
            },
            policyedit: {
                label: '$I18N_DASHBOARD_ACTIVITY_OVER_24_HOURS_POLICY_EDIT_LABEL',
                goTo: 'audit-logs(' + JSON.stringify(policyEditParams) + ')',
                cap: hasCap('Permission.POLICIES_READ'),
                order: 4
            },
            bounce: {
                label: '$I18N_DASHBOARD_ACTIVITY_OVER_24_HOURS_BOUNCED_LABEL',
                goTo: "bounces-list({'uid': 'bounces'})",
                cap: hasCap('Permission.BOUNCES_READ'),
                order: 5
            }
        };
        return {
            stats,
            hasCap
        };
        function hasCap(cap) {
            return (angular.isDefined(cap) && capabilitiesService.evalCapabilitiesExpression(cap));
        }
    }]);
