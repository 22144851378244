"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tableSectionFilters = exports.tableColumnsStartHidden = exports.columnsAlwaysVisible = exports.exportColumns = exports.tableColumns = void 0;
exports.tableColumns = [
    'status',
    'fromEnv',
    'fromHdr',
    'to',
    'subject',
    'sent',
    'senderIP',
    'attachments',
    'route',
    'info',
    'spamScore',
    'detectionLevel',
    'right-column'
];
exports.exportColumns = [
    'status',
    'fromEnv',
    'fromHdr',
    'to',
    'subject',
    'sent',
    'senderIP',
    'attachments',
    'route',
    'info',
    'spamScore',
    'spamDetection'
];
exports.columnsAlwaysVisible = ['to', 'sent', 'info'];
exports.tableColumnsStartHidden = ['fromEnv', 'route', 'spamScore', 'detectionLevel'];
exports.tableSectionFilters = [
    {
        name: 'status',
        filters: [
            'accepted',
            'held',
            'archived',
            'delivery_____processing',
            'bounced',
            'rejected',
            'deferred'
        ]
    },
    {
        name: 'route',
        filters: ['inbound', 'internal', 'outbound']
    },
    {
        name: 'attachments',
        filters: ['true', 'false']
    }
];
