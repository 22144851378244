"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Email = void 0;
const item_1 = require("../item");
class Email extends item_1.Item {
    constructor(subject, internetMessageId, sender, dateReceived, recipients, from, smash, to, hasAttachments, exchangeThreadId, modified, size, id, name, lastChangeId, lastChangeType, parentId) {
        super(modified, size, id, name, lastChangeId, lastChangeId, parentId);
        this.subject = subject;
        this.internetMessageId = internetMessageId;
        this.sender = sender;
        this.dateReceived = dateReceived;
        this.recipients = recipients;
        this.from = from;
        this.smash = smash;
        this.to = to;
        this.hasAttachments = hasAttachments;
        this.exchangeThreadId = exchangeThreadId;
    }
    getSubject() {
        return this.subject;
    }
    getInternetMessageId() {
        return this.internetMessageId;
    }
    getSender() {
        return this.sender;
    }
    getDateReceived() {
        return this.dateReceived;
    }
    getRecipients() {
        return this.recipients;
    }
    getFrom() {
        return this.from;
    }
    getSmash() {
        return this.smash;
    }
    getTo() {
        return this.to;
    }
    isHasAttachments() {
        return this.hasAttachments;
    }
    getExchangeThreadId() {
        return this.exchangeThreadId;
    }
}
exports.Email = Email;
