<div class="mc-detailed-list">
  <mc-wizard-step-header
    class="mc-supervision-wizard-step-header"
    label="$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.SUMMARY_STEP.DESCRIPTION"
  >
  </mc-wizard-step-header>

  <div class="mc-detailed-list">
    <h5>{{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.DETAILS_STEP.TITLE' | translate }}</h5>
    <div class="row mc-detailed-list-row">
      <div class="col-xs-2 mc-detailed-list-label">
        {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.DETAILS_STEP.FIELD_NAME' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ getStepDataById(1).name }}</div>
    </div>

    <div class="row mc-detailed-list-row">
      <div class="col-xs-2 mc-detailed-list-label">
        {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.DETAILS_STEP.FIELD_DESCRIPTION' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ getStepDataById(1).description }}</div>
    </div>
  </div>

  <div class="mc-detailed-list">
    <h5>{{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.CONTENT_STEP.TITLE' | translate }}</h5>
    <div class="row mc-detailed-list-row" *ngIf="isBasicSearchType() || isAdvancedSearchType()">
      <div class="col-xs-2 mc-detailed-list-label">
        {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.CONTENT_STEP.FIELD_TYPE.LABEL' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.SUMMARY_STEP.SEARCH_TYPE.' + getStepDataById(2).searchType | uppercase | translate }}
      </div>
    </div>

    <div *ngIf="isBasicSearchType() || (!isBasicSearchType() && !isAdvancedSearchType())">
      <div class="row mc-detailed-list-row">
        <div class="col-xs-2 mc-detailed-list-label">
          {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.CONTENT_STEP.FIELD_WORDS' | translate }}
        </div>
        <div class="mc-detailed-list-column">
          <div *ngFor="let word of getWords()">{{ word }}</div>
        </div>
      </div>

      <div class="row mc-detailed-list-row">
        <div class="col-xs-2 mc-detailed-list-label">
          {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.CONTENT_STEP.FIELD_PHRASES' | translate }}
        </div>
        <div class="mc-detailed-list-column">
          <div *ngFor="let phrase of getPhrases()">{{ phrase }}</div>
        </div>
      </div>
    </div>

    <div class="row mc-detailed-list-row" *ngIf="isAdvancedSearchType()">
      <div class="col-xs-2 mc-detailed-list-label">
        {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.CONTENT_STEP.FIELD_QUERY' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{ getStepDataById(2).query }}
      </div>
    </div>
  </div>
</div>
