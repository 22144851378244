"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLoadingByFileTypes = exports.getScanCountByFileType = exports.getLoadingByApps = exports.getVerdictsDistributedByCloudApps = exports.getVerdictsByDatesLoading = exports.getVerdictsByDates = exports.reducer = exports.initialState = void 0;
const cfp_action_1 = require("../actions/cfp.action");
exports.initialState = {
    verdictsByDates: [],
    verdictsDistributedByCloudApps: {},
    scanCountByFileType: [],
    loadingByDates: false,
    loadingByApps: false,
    loadingByFileTypes: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case cfp_action_1.SwgCfpDataEnum.VERDICTS_BY_APP:
            return Object.assign(Object.assign({}, state), { loadingByApps: true });
        case cfp_action_1.SwgCfpDataEnum.VERDICTS_BY_APP_SUCCESS:
            return Object.assign(Object.assign({}, state), { verdictsDistributedByCloudApps: action.payload, loadingByApps: false });
        case cfp_action_1.SwgCfpDataEnum.VERDICTS_BY_APP_FAILURE:
            return Object.assign(Object.assign({}, state), { loadingByApps: false });
        case cfp_action_1.SwgCfpDataEnum.VERDICTS_BY_DATE:
            return Object.assign(Object.assign({}, state), { loadingByDates: true });
        case cfp_action_1.SwgCfpDataEnum.VERDICTS_BY_DATE_SUCCESS:
            return Object.assign(Object.assign({}, state), { loadingByDates: false, verdictsByDates: action.payload });
        case cfp_action_1.SwgCfpDataEnum.VERDICTS_BY_DATE_FAILURE:
            return Object.assign(Object.assign({}, state), { loadingByDates: false });
        case cfp_action_1.SwgCfpDataEnum.SCANS_BY_FILETYPES:
            return Object.assign(Object.assign({}, state), { loadingByFileTypes: true });
        case cfp_action_1.SwgCfpDataEnum.SCANS_BY_FILETYPES_SUCCESS:
            return Object.assign(Object.assign({}, state), { scanCountByFileType: action.payload, loadingByFileTypes: false });
        case cfp_action_1.SwgCfpDataEnum.SCANS_BY_FILETYPES_FAILURE:
            return Object.assign(Object.assign({}, state), { loadingByFileTypes: false });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getVerdictsByDates = (state) => state.verdictsByDates;
exports.getVerdictsByDates = getVerdictsByDates;
const getVerdictsByDatesLoading = (state) => state.loadingByDates;
exports.getVerdictsByDatesLoading = getVerdictsByDatesLoading;
const getVerdictsDistributedByCloudApps = (state) => state.verdictsDistributedByCloudApps;
exports.getVerdictsDistributedByCloudApps = getVerdictsDistributedByCloudApps;
const getLoadingByApps = (state) => state.loadingByApps;
exports.getLoadingByApps = getLoadingByApps;
const getScanCountByFileType = (state) => state.scanCountByFileType;
exports.getScanCountByFileType = getScanCountByFileType;
const getLoadingByFileTypes = (state) => state.loadingByFileTypes;
exports.getLoadingByFileTypes = getLoadingByFileTypes;
