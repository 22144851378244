"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./service-status-directive"); //services-status.directive
require("./service-status-service"); //services-status.service
// html and css 
require("./service-status.tpl.html");
/**
 * Created by psambandam on 12/02/16.
 */
angular.module('services-status', ['services-status.directive', 'services-status.service']);
