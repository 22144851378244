<div>
  <mc-collapsable-panel header="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TRANSMISSION_COMPONENTS.TITLE" [isExpanded]="true">
    <div *ngIf="isDataSetOne; else archivedTransmissionComponents" >
        <mc-table [columns]="columns" [data]="components" [isLoading]="false">
          <mc-column key="fileType">
            <mc-header-cell *mcHeaderCellDef>{{'$I18N_MESSAGE_CENTER_MESSAGE_INFO_TRANSMISSION_COMPONENTS.LABEL_COMPONENT_TYPE' | translate}}</mc-header-cell>
          </mc-column>
          <mc-column key="fileName">
            <mc-header-cell *mcHeaderCellDef>{{'$I18N_MESSAGE_CENTER_MESSAGE_INFO_TRANSMISSION_COMPONENTS.LABEL_COMPONENT_NAME' | translate}}</mc-header-cell>
          </mc-column>
          <mc-column key="hash">
            <mc-header-cell *mcHeaderCellDef>{{'$I18N_MESSAGE_CENTER_MESSAGE_INFO_TRANSMISSION_COMPONENTS.LABEL_HASH' | translate}}</mc-header-cell>
          </mc-column>
          <mc-column key="txSize">
            <mc-header-cell *mcHeaderCellDef>{{'$I18N_MESSAGE_CENTER_MESSAGE_INFO_TRANSMISSION_COMPONENTS.LABEL_SIZE' | translate}}</mc-header-cell>
            <mc-body-cell *mcBodyCellDef="let row">{{ row.txSize | mcToBytes }}</mc-body-cell>
          </mc-column>
        </mc-table>
    </div>
    <ng-template #archivedTransmissionComponents>
        <mc-table [columns]="columnsArchived" [data]="components" [isLoading]="false">
        <mc-column key="type">
          <mc-header-cell *mcHeaderCellDef>{{'$I18N_MESSAGE_CENTER_MESSAGE_INFO_TRANSMISSION_COMPONENTS.LABEL_COMPONENT_TYPE' | translate}}</mc-header-cell>
        </mc-column>
        <mc-column key="name">
          <mc-header-cell *mcHeaderCellDef>{{'$I18N_MESSAGE_CENTER_MESSAGE_INFO_TRANSMISSION_COMPONENTS.LABEL_COMPONENT_NAME' | translate}}</mc-header-cell>
        </mc-column>
        <mc-column key="hash">
          <mc-header-cell *mcHeaderCellDef>{{'$I18N_MESSAGE_CENTER_MESSAGE_INFO_TRANSMISSION_COMPONENTS.LABEL_HASH' | translate}}</mc-header-cell>
        </mc-column>
        <mc-column key="size">
          <mc-header-cell *mcHeaderCellDef>{{'$I18N_MESSAGE_CENTER_MESSAGE_INFO_TRANSMISSION_COMPONENTS.LABEL_SIZE' | translate}}</mc-header-cell>
          <mc-body-cell *mcBodyCellDef="let row">{{ row.size | mcToBytes }}</mc-body-cell>
        </mc-column>
      </mc-table>
    </ng-template>
  </mc-collapsable-panel>
</div>
