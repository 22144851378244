"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const static_1 = require("@angular/upgrade/static");
const session_handler_service_1 = require("./session-handler.service");
const operators_1 = require("rxjs/operators");
/* eslint-disable */
'use strict';
angular
    .module('old-session-handler-override.service', [])
    .factory('newSessionHandlerService', static_1.downgradeInjectable(session_handler_service_1.SessionHandlerService))
    // @ts-ignore
    .provider('sessionHandlerService', function () {
    var inheritParentWindowSession = false;
    this.setInheritParentWindowSession = function (value) {
        inheritParentWindowSession = value;
    };
    this.$get = [
        'newSessionHandlerService',
        function (newSessionHandlerService) {
            return {
                getSession: () => {
                    return newSessionHandlerService.session;
                },
                getTempSession: () => {
                    return newSessionHandlerService.tempSession;
                },
                setSession: (session) => {
                    newSessionHandlerService.session = session;
                },
                setTempSession: (session) => {
                    newSessionHandlerService.tempSession = session;
                },
                getSessionId: () => {
                    return newSessionHandlerService.sessionId;
                },
                setSessionId: (id) => {
                    newSessionHandlerService.session.id = id;
                },
                isSessionPresent: (loginUrl) => {
                    return newSessionHandlerService.isSessionPresent(loginUrl);
                },
                addLogoutListener: (listener) => {
                    let subscription = newSessionHandlerService.logout$.subscribe(listener);
                    listener.subscription = subscription;
                },
                removeLogoutListener: (listener) => {
                    let l = listener;
                    l.subscription && l.subscription.unsubscribe(listener);
                },
                logOut: (isInvalidSession = false) => {
                    return newSessionHandlerService.logout(isInvalidSession);
                },
                createTempSessionCookie: () => {
                    return newSessionHandlerService.createTempSessionCookie();
                },
                isJustLoggedIn: () => {
                    return newSessionHandlerService.isFirstAppLoad;
                },
                timeSinceLogin: () => {
                    return newSessionHandlerService.timeSinceLogin();
                },
                extendSessionCookieExpiry: () => {
                    return newSessionHandlerService.extendSessionCookieExpiry();
                },
                isAppRunningInPendo: () => {
                    return newSessionHandlerService.isAppRunningInPendo();
                },
                accountSwitchSession: () => {
                    return newSessionHandlerService.accountSwitchSession.pipe(operators_1.first()).toPromise();
                }
            };
        }
    ];
});
