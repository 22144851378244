<mc-layout-detail-wizard keyTitle="{{ wizardTitle() | translate }}">
  <mc-help-container>
    <mc-help-link url="https://community.mimecast.com/s/article/Okta-Directory-SCIM"></mc-help-link>
  </mc-help-container>

  <mc-wizard (changeStep)="changeStep()" [ngClass]="{ 'wizard-busy': isBusy }">
    <mc-wizard-step
      label="{{ '$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.DETAILS.TITLE' | translate }}"
    >
      <mc-api-integration-okta-details-step
        [name]="oktaIntegration.name"
        [description]="oktaIntegration.description"
        (onEditOktaIntegrationDetails)="editOktaIntegrationDetails($event)"
      >
      </mc-api-integration-okta-details-step>

      <div class="spinner-wrapper" *ngIf="isBusy">
        <mc-spinner></mc-spinner>
      </div>
    </mc-wizard-step>

    <mc-wizard-step
      label="{{ '$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.SETTINGS.TITLE' | translate }}"
    >
      <mc-api-integration-okta-settings-step [rootFolderDescription]="oktaIntegration.rootFolderDescription"
        [rootFolder]="oktaIntegration.rootFolder" (onOktaCellSelect)="selectOktaCell($event)" [cell]="oktaIntegration.cell"
        [folders]= "oktaIntegration.folderList" [isEditMode]="hasCreatedIntegration">
      </mc-api-integration-okta-settings-step>

      <div class="spinner-wrapper" *ngIf="isBusy">
        <mc-spinner></mc-spinner>
      </div>
    </mc-wizard-step>

    <mc-wizard-step
      label="{{ '$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.CONTACTS.TITLE' | translate }}"
    >
      <mc-api-integration-okta-contacts-step
        [emails]="oktaIntegration?.contactAddresses"
        (onAddEmail) = "addEmail($event)"
        (onDeleteEmail) = "deleteEmail($event)">
      </mc-api-integration-okta-contacts-step>

      <div class="spinner-wrapper" *ngIf="isBusy">
        <mc-spinner></mc-spinner>
      </div>
    </mc-wizard-step>

    <mc-wizard-step
      label="{{ '$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.SUMMARY.TITLE' | translate }}"
    >
      <mc-api-integration-okta-summary-step
        [oktaIntegration]="oktaIntegration"
        [hasCreatedIntegration]="hasCreatedIntegration"
        [rotateApiToken]="rotateApiToken"
        (onSwitchOktaIntegrationStatus)="switchOktaIntegrationStatus($event)"
        (onSwitchRotateToken)="switchRotateToken($event)"
      >
      </mc-api-integration-okta-summary-step>

      <div class="spinner-wrapper" *ngIf="isBusy">
        <mc-spinner></mc-spinner>
      </div>
    </mc-wizard-step>
  </mc-wizard>

  <mc-footer-container>
    <div class="pull-right no-select mc-button-footer">
      <button
        class="btn btn-text"
        data-test="cancel-btn"
        (click)="cancel()"
      >
        {{ '$I18N_API_INTEGRATION_OKTA_WIZARD.BUTTONS.CANCEL' | translate }}
      </button>
      <button
        *ngIf="showPrevious()"
        class="btn btn-secondary panel-half-margin-right"
        data-test="previous-btn"
        [disabled]="isBusy"
        (click)="gotoPreviousStep()"
      >
        {{ '$I18N_API_INTEGRATION_OKTA_WIZARD.BUTTONS.PREVIOUS' | translate }}
      </button>
      <button
        *ngIf="showNext()"
        class="btn"
        [ngClass]="hasCreatedIntegration ? 'btn-secondary' : 'btn-primary'"
        data-test="next-btn"
        [disabled]="isBusy || !isStepValid()"
        (click)="gotoNextStep()"
      >
        {{ '$I18N_API_INTEGRATION_OKTA_WIZARD.BUTTONS.NEXT' | translate }}
      </button>
      <button
        *ngIf="showCreate()"
        class="btn btn-primary"
        data-test="create-btn"
        [disabled]="isBusy || !isStepValid()"
        (click)="create()"
      >
        {{ '$I18N_API_INTEGRATION_OKTA_WIZARD.BUTTONS.FINISH' | translate }}
      </button>
      <button
        *ngIf="showUpdate()"
        class="btn btn-primary"
        [disabled]="isBusy || !isStepValid()"
        (click)="update()"
      >
        {{ '$I18N_API_INTEGRATION_OKTA_WIZARD.BUTTONS.SAVE_AND_CLOSE' | translate }}
      </button>
    </div>
  </mc-footer-container>
</mc-layout-detail-wizard>
