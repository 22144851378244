"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListModule = void 0;
const list_component_1 = require("./list.component");
const view_lexicon_component_1 = require("../asides/view-lexicon/view-lexicon.component");
const view_queue_config_component_1 = require("../asides/view-queue-config/view-queue-config.component");
const view_rule_component_1 = require("../asides/view-rule/view-rule.component");
const DECLARATIONS = [
    list_component_1.ListComponent,
    view_lexicon_component_1.ViewLexiconComponent,
    view_queue_config_component_1.ViewQueueConfigComponent,
    view_rule_component_1.ViewRuleComponent
];
class ListModule {
}
exports.ListModule = ListModule;
