<div *ngIf="ruleDetails">
  <mc-collapsable-panel
    header="{{ '$I18N_ARCHIVE_SUPERVISION_RULE_DETAILS.DETAILS.TITLE' | translate }}"
    [isExpanded]="true"
  >
    <div class="mc-detailed-list mc-detailed-list-column">
      <mc-simple-detail
        label="{{ '$I18N_ARCHIVE_SUPERVISION_RULE_DETAILS.DETAILS.NAME' | translate }}"
        value="{{ ruleDetails?.name }}"
      ></mc-simple-detail>

      <mc-simple-detail
        label="{{ '$I18N_ARCHIVE_SUPERVISION_RULE_DETAILS.DETAILS.DESCRIPTION' | translate }}"
        value="{{ ruleDetails.description }}"
      ></mc-simple-detail>

      <div class="row mc-detailed-list-row">
        <div class="col-xs-3 mc-detailed-list-label">
          {{ '$I18N_ARCHIVE_SUPERVISION_RULE_DETAILS.DETAILS.STATE.TITLE' | translate }}
        </div>
        <div class="mc-detailed-list-column">
          <span [className]="isActive(ruleDetails.state) ? 'active-state' : 'inactive-state'">
            {{ getState(ruleDetails.state) | translate }}
          </span>
        </div>
      </div>

      <mc-simple-detail
        *ngIf="ruleDetails.status"
        label="{{ '$I18N_ARCHIVE_SUPERVISION_RULE_DETAILS.DETAILS.STATUS.TITLE' | translate }}"
        value="{{
          '$I18N_ARCHIVE_SUPERVISION_RULE_DETAILS.DETAILS.STATUS.' +
            ruleDetails.status?.toUpperCase() | translate
        }}"
      ></mc-simple-detail>

      <mc-simple-detail
        label="{{ '$I18N_ARCHIVE_SUPERVISION_RULE_DETAILS.DETAILS.LEXICON' | translate }}"
        value="{{ ruleDetails.lexicon?.name || getNoLexiconText() }}"
      ></mc-simple-detail>

      <mc-simple-detail
        label="{{ '$I18N_ARCHIVE_SUPERVISION_RULE_DETAILS.DETAILS.REVIEW_QUEUE' | translate }}"
        value="{{ ruleDetails.reviewQueue?.name }}"
      ></mc-simple-detail>

      <mc-simple-detail
        label="{{ '$I18N_ARCHIVE_SUPERVISION_RULE_DETAILS.DETAILS.CREATED_BY' | translate }}"
        value="{{ ruleDetails.createdBy }}"
      ></mc-simple-detail>

      <mc-simple-detail
        label="{{ '$I18N_ARCHIVE_SUPERVISION_RULE_DETAILS.DETAILS.CREATED_DATE' | translate }}"
        value="{{ ruleDetails.created | mcDate }}"
      ></mc-simple-detail>

      <mc-simple-detail
        label="{{ '$I18N_ARCHIVE_SUPERVISION_RULE_DETAILS.DETAILS.LAST_MODIFIED' | translate }}"
        value="{{ ruleDetails.updated | mcDate }}"
      ></mc-simple-detail>
    </div>
  </mc-collapsable-panel>

  <mc-collapsable-panel
    header="{{ '$I18N_ARCHIVE_SUPERVISION_RULE_DETAILS.SETTINGS.TITLE' | translate }}"
    [isExpanded]="true"
  >
    <div class="mc-detailed-list mc-detailed-list-column">
      <div [ngClass]="{'monitoring-group': isMonitoringGroupMissing(ruleDetails.searchGroup?.name)}" data-test="monitoring-group">
        <mc-simple-detail label="{{ '$I18N_ARCHIVE_SUPERVISION_RULE_DETAILS.SETTINGS.MONITORING_GROUP' | translate }}"
          value="{{ ruleDetails.searchGroup?.name }}"></mc-simple-detail>
      </div>

      <mc-simple-detail
        label="{{ '$I18N_ARCHIVE_SUPERVISION_RULE_DETAILS.SETTINGS.ROUTING.TITLE' | translate }}"
        value="{{
          '$I18N_ARCHIVE_SUPERVISION_RULE_DETAILS.SETTINGS.ROUTING.' +
            ruleDetails.messageRoute?.toUpperCase() | translate
        }}"
      ></mc-simple-detail>

      <mc-simple-detail
        label="{{ '$I18N_ARCHIVE_SUPERVISION_RULE_DETAILS.SETTINGS.SCHEDULE.TITLE' | translate }}"
        value="{{
          '$I18N_ARCHIVE_SUPERVISION_RULE_DETAILS.SETTINGS.SCHEDULE.' +
            ruleDetails.scheduleType?.toUpperCase() | translate
        }}"
      ></mc-simple-detail>

      <mc-simple-detail
        label="{{ '$I18N_ARCHIVE_SUPERVISION_RULE_DETAILS.SETTINGS.SAMPLING' | translate }}"
        value="{{ ruleDetails.samplingPercentage + '%' }}"
      ></mc-simple-detail>

      <span *ngIf="isMonitoringGroupMissing(ruleDetails.searchGroup?.name)" data-test="monitoring-group-error"
      class="monitoring-group-error">{{ '$I18N_ARCHIVE_SUPERVISION_RULE_DETAILS.SETTINGS.MONITORING_GROUP_ERROR_MESSAGE' | translate }}</span>
    </div>
  </mc-collapsable-panel>
</div>

