"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgExceptionsEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const tableStore = require("@mimecast-ui/table-store");
const notification_actions_1 = require("app-new/components/notification/actions/notification.actions");
const swg_exceptions_actions_1 = require("../actions/swg-exceptions.actions");
const swg_exceptions_sidebar_modal_actions_1 = require("../actions/swg-exceptions-sidebar-modal.actions");
const swg_errors_1 = require("app-new/swg/common/swg-errors");
class SwgExceptionsEffects {
    constructor(actions$, notificationService, exceptionsListApiService) {
        this.actions$ = actions$;
        this.notificationService = notificationService;
        this.exceptionsListApiService = exceptionsListApiService;
        this.addExceptionEffect$ = this.actions$.pipe(effects_1.ofType(swg_exceptions_actions_1.SwgExceptionsActions.SWG_ADD_EXCEPTION), operators_1.switchMap((action) => {
            return this.exceptionsListApiService.createException(action.payload).pipe(this.exceptionsSuccessAction('$I18N_SWG_EXCEPTIONS_LIST.NOTIFICATION.EXCEPTION_ADDED'), operators_1.catchError(error => this.exceptionsFailAction(error)));
        }));
        this.editExceptionEffect$ = this.actions$.pipe(effects_1.ofType(swg_exceptions_actions_1.SwgExceptionsActions.SWG_UPDATE_EXCEPTION), operators_1.switchMap((action) => {
            return this.exceptionsListApiService.updateException(action.payload).pipe(this.exceptionsSuccessAction('$I18N_SWG_EXCEPTIONS_LIST.NOTIFICATION.EXCEPTION_UPDATED'), operators_1.catchError(error => this.exceptionsFailAction(error)));
        }));
        this.deleteExceptionEffect$ = this.actions$.pipe(effects_1.ofType(swg_exceptions_actions_1.SwgExceptionsActions.SWG_DELETE_EXCEPTION), operators_1.switchMap((action) => {
            return this.exceptionsListApiService.deleteException(action.payload.id).pipe(this.exceptionsSuccessAction('$I18N_SWG_EXCEPTIONS_LIST.NOTIFICATION.EXCEPTION_DELETED'), operators_1.catchError(error => this.exceptionsFailAction(error)));
        }));
    }
    exceptionsSuccessAction(notificationMessage) {
        return operators_1.mergeMap(() => {
            return [
                new notification_actions_1.NotificationShowSuccessAction(notificationMessage),
                new swg_exceptions_actions_1.SwgUpdateExceptionSuccessAction(),
                new swg_exceptions_sidebar_modal_actions_1.SwgExceptionsSidebarCloseAction(),
                new swg_exceptions_sidebar_modal_actions_1.SwgExceptionsDeleteConfirmModalCloseAction(),
                new tableStore.LoadAction({ tableId: 'SwgExceptionsListTable' })
            ];
        });
    }
    exceptionsFailAction(errorMessage) {
        return rxjs_1.merge(rxjs_1.of(this.notificationService.showFirstNotificationError(errorMessage.firstFail, swg_errors_1.swgCommonErrors)), rxjs_1.of(new swg_exceptions_actions_1.SwgUpdateExceptionFailureAction()), rxjs_1.of(new swg_exceptions_sidebar_modal_actions_1.SwgExceptionsSidebarCloseAction()), rxjs_1.of(new swg_exceptions_sidebar_modal_actions_1.SwgExceptionsDeleteConfirmModalCloseAction()));
    }
}
__decorate([
    effects_1.Effect()
], SwgExceptionsEffects.prototype, "addExceptionEffect$", void 0);
__decorate([
    effects_1.Effect()
], SwgExceptionsEffects.prototype, "editExceptionEffect$", void 0);
__decorate([
    effects_1.Effect()
], SwgExceptionsEffects.prototype, "deleteExceptionEffect$", void 0);
exports.SwgExceptionsEffects = SwgExceptionsEffects;
