"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFailure = exports.getTopTenHeldSummaryByReasons = exports.getSelectedSummaryDetailResultsByKey = exports.getSummaryByReasonResults = exports.isLoading = exports.reducer = exports.initialState = void 0;
const summaryByReasonAction = require("../actions/summary-by-reason.action");
exports.initialState = {
    results: [],
    isLoading: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case summaryByReasonAction.SUMMARY_BY_REASON_REQUEST: {
            return Object.assign(Object.assign({}, state), { error: undefined, isLoading: true });
        }
        case summaryByReasonAction.SUMMARY_BY_REASON_SUCCESS: {
            return Object.assign(Object.assign({}, state), { results: action.payload.results, isLoading: false });
        }
        case summaryByReasonAction.SUMMARY_BY_REASON_FAIL: {
            return Object.assign(Object.assign({}, state), { isLoading: false, error: action.payload });
        }
        default:
            return state;
    }
}
exports.reducer = reducer;
const isLoading = (state) => state.isLoading;
exports.isLoading = isLoading;
const getSummaryByReasonResults = (state) => state.results;
exports.getSummaryByReasonResults = getSummaryByReasonResults;
const getSelectedSummaryDetailResultsByKey = (state) => {
    return [...state.results.map(a => a.policyInfo)];
};
exports.getSelectedSummaryDetailResultsByKey = getSelectedSummaryDetailResultsByKey;
const getTopTenHeldSummaryByReasons = (state) => {
    return [...state.results]
        .sort((hReason1, hReason2) => hReason2.numberOfItems - hReason1.numberOfItems)
        .slice(0, 10)
        .reverse();
};
exports.getTopTenHeldSummaryByReasons = getTopTenHeldSummaryByReasons;
const getFailure = (state) => state.error;
exports.getFailure = getFailure;
