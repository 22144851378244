"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IFrameComponent = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const legacy_adcon_1 = require("../../core/services/legacy-adcon");
class IFrameComponent {
    constructor(legacyService, ref, domSanitizer, windowService) {
        this.legacyService = legacyService;
        this.ref = ref;
        this.domSanitizer = domSanitizer;
        this.windowService = windowService;
        this.isLoading$ = new rxjs_1.BehaviorSubject(true);
        this.isHidden = false;
        this.isRoot = false;
        this.fields$ = new rxjs_1.Subject();
        this.stop$ = new rxjs_1.Subject();
        this.timeoutTimer$ = rxjs_1.timer(60000);
        this.frameName = 'mcFrame' + IFrameComponent.count;
        this.iframeHtml = this.domSanitizer.bypassSecurityTrustHtml(`<iframe name="${this.frameName}" class="mc-iframe-legacy-adcon" tab-index="-1"></iframe>`);
        IFrameComponent.count++;
    }
    set hidden(value) {
        this.isHidden = value === true || value === '';
    }
    set root(value) {
        this.isRoot = value === true || value === '';
    }
    ngAfterViewInit() {
        if (this.isRoot) {
            this.legacyService.rootAdconIFrame = this;
        }
        if (this.url) {
            this.formSubmit(this.url, this.params);
        }
    }
    /**
     * Submit the form inside the component with the provided data and callback message
     *
     * @param action
     * @param data
     * @param callbackMessage
     * @param globalMessage
     * @returns
     */
    formSubmit(action, data, callbackMessage = 'loaded', globalMessage = false) {
        callbackMessage = globalMessage ? callbackMessage : this.frameName + '-' + callbackMessage;
        this.stop$.next(true); // cancel any previous request
        this.isLoading$.next(true);
        this.form.nativeElement.action = action;
        const fields = [];
        if (data) {
            Object.keys(data).forEach(key => fields.push({ name: key, value: data[key] }));
        }
        fields.push({ name: 'callback-message', value: callbackMessage });
        this.fields$.next(fields);
        this.ref.detectChanges();
        this.form.nativeElement.submit();
        const result$ = this.legacyService.messages$.pipe(legacy_adcon_1.ofName(callbackMessage), legacy_adcon_1.toData(), operators_1.first());
        const timeout$ = this.timeoutTimer$.pipe(operators_1.flatMap(() => rxjs_1.throwError(new Error('Timeout url:' + action + ' callback-message:' + callbackMessage))));
        const cancel$ = this.stop$.pipe(operators_1.flatMap(() => rxjs_1.throwError(new Error('Canceled url:' + action + ' callback-message:' + callbackMessage))));
        return rxjs_1.merge(result$, timeout$, cancel$)
            .pipe(operators_1.tap(() => this.isLoading$.next(false)))
            .pipe(operators_1.first());
    }
    /**
     * Send a message to the frame using the window.postMessage
     *
     * @param message
     * @param data
     * @param url
     */
    postMessage(message, data) {
        this.iframe.nativeElement.firstChild.contentWindow.postMessage(JSON.stringify({ message, data }), this.windowService.document.location.origin + this.legacyService.adconUrl);
    }
}
exports.IFrameComponent = IFrameComponent;
IFrameComponent.count = 0;
