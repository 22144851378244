'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
require("./iso-from-now-filter");
require("./search-service");
require("app/components/mega-menu/mega-menu-service");
const angular = require("angular");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
angular.module('navbar.search.controller', ['navbar.search.service', 'mega.menu.service', 'isoFromNow.filter'])
    .controller('NavbarSearchController', ['$scope', '$element', '$translate', 'navbarSearchService', 'megaMenuService', 'navbarService', function ($scope, $element, $translate, navbarSearchService, megaMenuService, navbarService) {
        const navbarSearchCtrl = this;
        navbarSearchCtrl.helpSearchResults = undefined;
        navbarSearchCtrl.menuSearchResults = undefined;
        navbarSearchCtrl.showResults = false;
        navbarSearchCtrl.hel = false;
        navbarSearchCtrl.loadingHelpResults = false;
        navbarSearchCtrl.loadingMenuResults = false;
        navbarSearchCtrl.isDimming = false;
        navbarSearchCtrl.sourceString = undefined;
        navbarSearchCtrl.showResultsIfQuery = showResultsIfQuery;
        navbarSearchCtrl.clearResults = clearResults;
        navbarSearchCtrl.hasCap = navbarService.hasCap;
        navbarSearchCtrl.applyStyleOnBlur = applyStyleOnBlur;
        let searchInput;
        let dropdown;
        activate();
        function activate() {
            searchInput = $element.find('.mc-navbar-search-field')[0];
            dropdown = $element.find('.top-search-results');
            const keyup = rxjs_1.fromEvent(searchInput, 'keyup').pipe(operators_1.map(function (e) {
                // @ts-ignore
                return e.target.value; // Project the text from the input
            }), operators_1.filter(function (text) {
                if (text.length > 2) {
                    showResults(true);
                    return true;
                }
                else if (text.length > 0) {
                    showResults(false);
                }
                return false;
            }), operators_1.debounceTime(200), operators_1.distinctUntilChanged()); // Only if the value has changed
            const helpSearcher = keyup.pipe(operators_1.switchMap(searchHelp));
            helpSearcher.subscribe(renderHelpSearchResults);
            const menuSearcher = keyup.pipe(operators_1.switchMap(searchMenu));
            menuSearcher.subscribe(renderMenuSearchResults);
            const collapseWhenClickOutside = function (e) {
                if (e && e.which === 3)
                    return;
                hideSuggestions();
            };
            $(document)
                .on('click.mimecast.search-suggest-box', collapseWhenClickOutside);
        }
        function showResults(value) {
            navbarSearchCtrl.showResults = value;
            if (value)
                showSuggestions();
            else
                hideSuggestions();
        }
        function showResultsIfQuery(event) {
            if (searchInput.value && searchInput.value.length > 0) {
                showSuggestions();
                event.stopPropagation();
            }
        }
        function searchHelp(query) {
            $scope.$apply(function () {
                navbarSearchCtrl.loadingHelpResults = true;
            });
            const matches = navbarSearchService.search(query);
            return matches;
        }
        function searchMenu(term) {
            $scope.$apply(function () {
                navbarSearchCtrl.loadingMenuResults = true;
            });
            const matches = navbarService.searchMenus(term);
            return [matches];
        }
        function renderMenuSearchResults(results) {
            navbarSearchService.getData().then((res) => {
                if (res.policyInheritance === false && results.map((resParam) => {
                    if (resParam.label === $translate.instant('$I18N_MEGA_MENU_GATEWAY_POLICIES_LABEL')) {
                        results.pop();
                    }
                }))
                    return results;
            });
            navbarSearchCtrl.loadingMenuResults = false;
            $scope.$apply(function () {
                navbarSearchCtrl.menuSearchResults = results;
                navbarSearchCtrl.megaMenuSearchResults = results;
            });
        }
        function renderHelpSearchResults(results) {
            navbarSearchCtrl.loadingHelpResults = false;
            navbarSearchCtrl.sourceString = navbarSearchService.getMimecasterCentralSourceString();
            //  $scope.$apply(function(){
            navbarSearchCtrl.helpSearchResults = results;
            // });
        }
        function hideSuggestions() {
            navbarSearchCtrl.isDimming = false;
            if (dropdown.parent().hasClass('open')) {
                dropdown.parent().removeClass('open');
            }
        }
        function showSuggestions() {
            navbarSearchCtrl.isDimming = true;
            if (!dropdown.parent().hasClass('open')) {
                dropdown.parent().addClass('open');
            }
        }
        function clearResults($event) {
            searchInput.value = '';
            showResults(false);
            eventStopper($event);
            searchInput.parentElement.classList.remove('on-blur');
        }
        function eventStopper($event) {
            if ($event) {
                $event.preventDefault();
                $event.stopPropagation();
            }
        }
        function applyStyleOnBlur() {
            if (searchInput.value.length > 0)
                searchInput.parentElement.classList.add('on-blur');
            else
                searchInput.parentElement.classList.remove('on-blur');
        }
    }]);
