"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isNonEmpty = exports.cleanFormPayload = void 0;
/**
 * Clean form fields removing empty string and null values
 *
 * @param formValue
 * @returns
 */
function cleanFormPayload(formValue) {
    const cleanedFormValue = Object.assign({}, formValue);
    Object.keys(cleanedFormValue).forEach(key => !cleanedFormValue[key] && delete cleanedFormValue[key]);
    return cleanedFormValue;
}
exports.cleanFormPayload = cleanFormPayload;
function isNonEmpty(value) {
    return value !== undefined && value !== null && value.trim().length > 0;
}
exports.isNonEmpty = isNonEmpty;
