<div class="mc-server-users">
  <mc-collapsable-panel header="$I18N_ENDPOINTS_LIST.SIDEBAR.SERVER_USERS_TAB.TAB_TITLE" [isExpanded]="true">
    <div class="mc-server-users-table">
      <mc-table
        translatePrefix="$I18N_ENDPOINTS_LIST.SIDEBAR.SERVER_USERS_TAB.TABLE"
        [scrollable]="isTableScrollable"
        [scrollHeight]="'calc(100vh - 400px)'"
        [showHighlightedRow]="false"
        [sortableColumns]="columns"
        (sort)="onSort($event)"
        [isLoading]="false"
        [data]="dataInView"
        [columns]="columns">

        <mc-filters [showPagination]="false">
          <mc-filter-search #filterSearchComponent (search)="onSearch($event)" (clear)="onSearchClear()"></mc-filter-search>
          <mc-filters-bundle-column-values
            #filterComponent
            [sections]="searchFilters"
            [columns]="1"
            (filtersChange)="onBundleFilterChange($event)"
          >
          </mc-filters-bundle-column-values>
        </mc-filters>

        <mc-column key="status">
          <mc-body-cell *mcBodyCellDef="let row">
        <span class="text-bold" [ngClass]="row.status | mcSwgEndpointsListMapStatusColors">
          {{ row.status | mcSwgEndpointsListMapStatusText | translate }}
        </span>
          </mc-body-cell>
        </mc-column>


        <mc-empty-results keyTitle="$I18N_ENDPOINTS_LIST.SIDEBAR.SERVER_USERS_TAB.TABLE.NO_DATA">
        </mc-empty-results>
      </mc-table>
    </div>
  </mc-collapsable-panel>

</div>

