"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const mep_component_1 = require("./mep.component");
const mep_policy_create_page_component_1 = require("./policies/containers/create/mep-policy-create-page.component");
angular
    .module('mepPolicy', [])
    .directive('mcMep', static_1.downgradeComponent({
    component: mep_component_1.MepComponent
}))
    .directive('mcMepPolicyCreate', static_1.downgradeComponent({
    component: mep_policy_create_page_component_1.MepPolicyCreatePageComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider.state('mep-policy-create', {
            url: '/mep/policy',
            data: {
                capabilities: 'Temporary.Services.MEP && Permission.MEP_READ',
                isFixedLayout: false,
                checkProgress: false,
                tabReload: false,
                tabId: 'mep-policies',
                tabTitle: '$I18N_MEGA_MENU_SERVICES_MISADDRESSED_EMAIL_PROTECT_LABEL',
                breadcrumbs: [
                    {
                        label: '$I18N_MEGA_MENU_GROUP_SERVICES_LABEL'
                    },
                    {
                        label: '$I18N_MEP_CREATE_POLICY_BREADCRUMB'
                    }
                ]
            },
            views: {
                main: {
                    template: '<mc-mep-policy-create></mc-mep-policy-create>'
                }
            }
        });
    }
])
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider.state('misaddressed-email-protect', {
            url: '/mep',
            params: {
                showNotification: '',
                displayTab: 'logs'
            },
            data: {
                capabilities: 'Temporary.Services.MEP && Permission.MEP_READ',
                isFixedLayout: false,
                checkProgress: false,
                tabId: 'mep-policies',
                tabReload: false,
                tabTitle: '$I18N_MEGA_MENU_SERVICES_MISADDRESSED_EMAIL_PROTECT_LABEL'
            },
            views: {
                main: {
                    template: '<mc-mep></mc-mep>'
                }
            }
        });
    }
]);
