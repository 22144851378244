"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MigrateConnectorPageComponent = void 0;
const wizard_base_component_1 = require("../../../connectors/components/wizards/wizard-base.component");
const store_1 = require("@ngrx/store");
const connector_wizard_service_1 = require("../../../connectors/services/connector-wizard.service");
const reducers_1 = require("../../reducers");
const wizardActions = require("../../../connectors/actions/wizard.actions");
const model_1 = require("../../../connectors/model");
const operators_1 = require("rxjs/operators");
const connector_actions_1 = require("../../../connectors/actions/connector.actions");
const reducers_2 = require("../../../connectors/reducers");
class MigrateConnectorPageComponent extends wizard_base_component_1.WizardBaseDirective {
    constructor(capabilitiesService, store, migrationStore, translateService, stateService) {
        super(capabilitiesService, connector_wizard_service_1.ConnectorWizardService.WizardId, store, translateService);
        this.capabilitiesService = capabilitiesService;
        this.store = store;
        this.migrationStore = migrationStore;
        this.translateService = translateService;
        this.stateService = stateService;
        this.disableMigrateButton = false;
        this.startingStepIndex = 2;
        this.wizardTitle = this.translateService.instant('$I18N_CONNECTORS_MIGRATE.CREATE_CONNECTOR');
    }
    getCancelConfirmationText() {
        return this.translateService.instant('$I18N_CONNECTORS_MIGRATE.CANCEL_CONFIRMATION');
    }
    onSave() {
        this.disableMigrateButton = true;
        if (this.stateService.$state.params.existingConnector === true) {
            this.store.dispatch(new connector_actions_1.UpdateConnectorAction(this.getUpdateConnectorRequest()));
        }
        else {
            this.store.dispatch(new connector_actions_1.CreateConnectorAction(this.getCreateConnectorRequest(), true));
        }
    }
    ngOnInit() {
        super.ngOnInit();
        this.connectorToMigrate$ = this.migrationStore.select(reducers_1.getMigratingConnector);
        this.providerDataSubscription = this.connectorToMigrate$
            .pipe(operators_1.filter((connector) => connector))
            .subscribe(connector => {
            this.connectorToMigrate = connector;
            this.dispatchProductSelectionStepAction();
            if (connector.connectorProductId) {
                this.store.dispatch(new connector_actions_1.LoadProvidersAction(connector.connectorProductId));
            }
        });
        this.provider$ = this.store.pipe(store_1.select(reducers_2.getProviders), operators_1.map(providers => {
            const preselectedProvider = providers.filter(provider => provider.type === this.connectorToMigrate.type);
            this.provider = preselectedProvider[0];
            this.dispatchProviderSelectionStepAction();
            return this.provider;
        }));
    }
    ngOnDestroy() {
        this.providerDataSubscription.unsubscribe();
    }
    onStepChange(stepId) {
        super.onStepChange(stepId + this.startingStepIndex);
    }
    dispatchProductSelectionStepAction() {
        this.store.dispatch(new wizardActions.UpdateStepAction({
            wizardId: connector_wizard_service_1.ConnectorWizardService.WizardId,
            stepId: model_1.ConnectorStep.PRODUCT,
            stepState: {
                valid: true,
                data: {
                    product: {
                        id: this.connectorToMigrate.connectorProductId,
                        name: this.connectorToMigrate.productName,
                        description: this.connectorToMigrate.productName.toUpperCase()
                    }
                }
            }
        }));
    }
    dispatchProviderSelectionStepAction() {
        this.store.dispatch(new wizardActions.UpdateStepAction({
            wizardId: connector_wizard_service_1.ConnectorWizardService.WizardId,
            stepId: model_1.ConnectorStep.PROVIDER,
            stepState: {
                valid: true,
                data: {
                    provider: this.provider
                }
            }
        }));
        this.store.dispatch(new wizardActions.SetCurrentStepAction({
            wizardId: connector_wizard_service_1.ConnectorWizardService.WizardId,
            stepId: model_1.ConnectorStep.CONSENT
        }));
    }
    getCreateConnectorRequest() {
        const description = this.wizardData.steps[model_1.ConnectorStep.DETAILS].data['description'].trim();
        return Object.assign({ product: this.wizardData.steps[model_1.ConnectorStep.PRODUCT].data['product'].id, provider: this.wizardData.steps[model_1.ConnectorStep.PROVIDER].data['provider'].type, authToken: this.wizardData.steps[model_1.ConnectorStep.CONSENT].data['authToken'], name: this.wizardData.steps[model_1.ConnectorStep.DETAILS].data['name'].trim() }, (description && { description }));
    }
    getUpdateConnectorRequest() {
        return {
            connectorId: this.stateService.$state.params.externalConnectionId,
            name: this.wizardData.steps[model_1.ConnectorStep.DETAILS].data['name'].trim(),
            // authToken is a hidden field, it's not in API docs as those only list public fields
            authToken: this.wizardData.steps[model_1.ConnectorStep.CONSENT].data['authToken'],
            description: this.wizardData.steps[model_1.ConnectorStep.DETAILS].data['description']
                ? this.wizardData.steps[model_1.ConnectorStep.DETAILS].data['description'].trim()
                : null,
            provider: this.wizardData.steps[model_1.ConnectorStep.PROVIDER].data['provider'].type
        };
    }
}
exports.MigrateConnectorPageComponent = MigrateConnectorPageComponent;
