<mc-layout-detail-wizard keyTitle="{{ wizardTitle() | translate }}">
  <mc-help-container>
    <mc-help-link url="{{ dirSyncKbLink }}"></mc-help-link>
  </mc-help-container>

  <mc-wizard (changeStep)="changeStep($event)" [ngClass]="{ 'wizard-busy': isLoading }">
    <mc-wizard-step label="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.DETAILS.TITLE">
      <mc-directory-sync-details-step
        [name]="integration?.name"
        [description]="integration?.description"
        [type]="integration?.type"
        [hasMSE]="hasMSE"
        (onIntegrationDetailsUpdated)="updateIntegrationDetails($event)"
        (onServerTypeUpdated)="updateServerType($event)">
      </mc-directory-sync-details-step>
      <div class="spinner-wrapper" *ngIf="isLoading">
        <mc-spinner></mc-spinner>
      </div>
    </mc-wizard-step>

    <mc-wizard-step *ngIf="isLdapADIntegration()" label="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.TITLE">
      <mc-directory-sync-ldap-settings-step
        (onIntegrationDetailsUpdated)="updateIntegrationDetails($event)"
        [isEditMode]="isEditMode"
        [integration]="integration">
      </mc-directory-sync-ldap-settings-step>
      <div class="spinner-wrapper" *ngIf="isLoading">
        <mc-spinner></mc-spinner>
      </div>
    </mc-wizard-step>

    <mc-wizard-step *ngIf="isLdapDominoIntegration()" label="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.TITLE">
      <mc-directory-sync-ldap-domino-settings-step
        (onIntegrationDetailsUpdated)="updateIntegrationDetails($event)"
        [isEditMode]="isEditMode"
        [integration]="integration">
      </mc-directory-sync-ldap-domino-settings-step>
      <div class="spinner-wrapper" *ngIf="isLoading">
        <mc-spinner></mc-spinner>
      </div>
    </mc-wizard-step>

    <mc-wizard-step *ngIf="isAzureIntegration()" label="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.TITLE">

      <!-- Hecate settings page -->
      <mc-directory-sync-azure-hecate-settings-step
        *ngIf="isAzureSTDIntegration() || isAzureGCCIntegration()"
        (onIntegrationDetailsUpdated)="updateIntegrationDetails($event)"
        [isEditMode]="isEditMode"
        [integration]="integration"
        [connectors]="connectors$ | async"
        (updateAuthToken)="updateToken($event)">
      </mc-directory-sync-azure-hecate-settings-step>

      <!-- Non-Hecate settings page -->
      <mc-directory-sync-azure-settings-step
        *ngIf="!(isAzureSTDIntegration() || isAzureGCCIntegration())"
        (onIntegrationDetailsUpdated)="updateIntegrationDetails($event)"
        [isEditMode]="isEditMode"
        [integration]="integration">
      </mc-directory-sync-azure-settings-step>

      <div class="spinner-wrapper" *ngIf="isLoading">
        <mc-spinner></mc-spinner>
      </div>
    </mc-wizard-step>

    <mc-wizard-step *ngIf="isGsuiteIntegration()" label="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.TITLE">
      <mc-directory-sync-gsuite-settings-step
        (onIntegrationDetailsUpdated)="updateIntegrationDetails($event)"
        [isEditMode]="isEditMode"
        [integration]="integration">
      </mc-directory-sync-gsuite-settings-step>
      <div class="spinner-wrapper" *ngIf="isLoading">
        <mc-spinner></mc-spinner>
      </div>
    </mc-wizard-step>

    <mc-wizard-step *ngIf="isMSEIntegration()" label="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.TITLE">
      <mc-directory-sync-mse-settings-step
        (onIntegrationDetailsUpdated)="updateIntegrationDetails($event)"
        [isEditMode]="isEditMode"
        [integration]="integration"
        [mseSites]="mseSites">
      </mc-directory-sync-mse-settings-step>
      <div class="spinner-wrapper" *ngIf="isLoading">
        <mc-spinner></mc-spinner>
      </div>
    </mc-wizard-step>

    <mc-wizard-step label="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.OPTIONS.TITLE">
      <mc-directory-sync-options-step
        (onIntegrationDetailsUpdated)="updateIntegrationDetails($event)"
        [integration]="integration">
      </mc-directory-sync-options-step>
      <div class="spinner-wrapper" *ngIf="isLoading">
        <mc-spinner></mc-spinner>
      </div>
    </mc-wizard-step>

    <mc-wizard-step label="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SUMMARY.TITLE">
      <mc-directory-sync-summary-step
        (onIntegrationStatusUpdated)="updateIntegrationDetails($event)"
        [integration]="integration"
        [mseSites]="mseSites"
        [isHecate]="isAzureStandardHecate || isAzureGCCHecate"
        [connectors]="connectors$ | async">
      </mc-directory-sync-summary-step>
      <div class="spinner-wrapper" *ngIf="isLoading">
        <mc-spinner></mc-spinner>
      </div>
    </mc-wizard-step>

    <mc-wizard-step *ngIf="!isMSEIntegration() && !isAzureSTDIntegration() && !isAzureGCCIntegration()" label="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.TEST.TITLE">
      <mc-directory-sync-test-step [integration]="testInfo$ | async"
                                   (isCreateIntegrationButtonEnabled)="createIntegrationButtonEnabled($event)">
      </mc-directory-sync-test-step>
      <div class="spinner-wrapper" *ngIf="isLoading">
        <mc-spinner></mc-spinner>
      </div>
    </mc-wizard-step>
  </mc-wizard>

  <mc-footer-container>
    <div class="pull-right no-select mc-button-footer">
      <button
        class="btn btn-text"
        data-test="cancel-btn"
        (click)="cancel()"
      >
        {{ '$I18N_DIRECTORY_SYNC_WIZARD.BUTTONS.CANCEL' | translate }}
      </button>
      <button
        *ngIf="showPrevious()"
        class="btn btn-secondary panel-half-margin-right"
        data-test="previous-btn"
        [disabled]="isLoading"
        (click)="gotoPreviousStep()"
      >
        {{ '$I18N_DIRECTORY_SYNC_WIZARD.BUTTONS.PREVIOUS' | translate }}
      </button>
      <button
        *ngIf="showNext()"
        class="btn"
        [ngClass]="isEditMode ? 'btn-secondary' : 'btn-primary'"
        data-test="next-btn"
        [disabled]="isLoading || !isStepValid()"
        (click)="gotoNextStep()"
      >
        {{ '$I18N_DIRECTORY_SYNC_WIZARD.BUTTONS.NEXT' | translate }}
      </button>
      <button
        *ngIf="showCreate()"
        class="btn btn-primary"
        data-test="create-btn"
        [disabled]="isLoading || !createButtonEnabled"
        (click)="createIntegration()"
      >
        {{ '$I18N_DIRECTORY_SYNC_WIZARD.BUTTONS.FINISH' | translate }}
      </button>
      <button
        *ngIf="showUpdate()"
        class="btn btn-primary"
        [disabled]="isLoading || !isStepValid()"
        (click)="updateIntegration()"
      >
        {{ '$I18N_DIRECTORY_SYNC_WIZARD.BUTTONS.SAVE_AND_CLOSE' | translate }}
      </button>
    </div>
  </mc-footer-container>
</mc-layout-detail-wizard>
