"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AwarenessComponentsModule = void 0;
const core_1 = require("@ngx-translate/core");
const components_1 = require("@mimecast-ui/components");
const accordion_module_1 = require("../../components/accordion/accordion.module");
const carousel_module_1 = require("../../components/carousel/carousel.module");
const header_module_1 = require("../../components/header/header.module");
const live_button_module_1 = require("../../components/live-button/live-button.module");
const search_module_1 = require("../../components/search/search.module");
const spinner_module_1 = require("../../components/spinner/spinner.module");
const shared_module_1 = require("../../shared.module");
const awareness_list_component_1 = require("./awareness-list/awareness-list.component");
const template_add_safety_tip_component_1 = require("./template-add-safety-tip/template-add-safety-tip.component");
const template_default_preview_component_1 = require("./template-default-preview/template-default-preview.component");
const template_form_component_1 = require("./template-form/template-form.component");
const template_previews_module_1 = require("./template-previews/previews/template-previews.module");
const template_previews_component_1 = require("./template-previews/template-previews.component");
const template_safety_tips_container_component_1 = require("./template-safety-tips-container/template-safety-tips-container.component");
const template_safety_tips_list_component_1 = require("./template-safety-tips-list/template-safety-tips-list.component");
const template_safety_tips_preview_component_1 = require("./template-safety-tips-preview/template-safety-tips-preview.component");
const template_safety_tips_component_1 = require("./template-safety-tips/template-safety-tips.component");
const MODULES = [
    search_module_1.SearchModule,
    components_1.FieldModule,
    components_1.TextFieldModule,
    components_1.TextAreaFieldModule,
    accordion_module_1.AccordionModule,
    spinner_module_1.SpinnerModule,
    header_module_1.HeaderModule,
    live_button_module_1.LiveButtonModule,
    carousel_module_1.CarouselModule,
    core_1.TranslateModule,
    shared_module_1.SharedModule,
    template_previews_module_1.TemplatePreviewsModule
];
const COMPONENTS = [
    awareness_list_component_1.AwarenessListComponent,
    template_form_component_1.TemplateFormComponent,
    template_safety_tips_preview_component_1.TemplateSafetyTipsPreviewComponent,
    template_safety_tips_list_component_1.TemplateSafetyTipsListComponent,
    template_default_preview_component_1.TemplateDefaultPreviewComponent,
    template_add_safety_tip_component_1.TemplateAddSafetyTipComponent,
    template_safety_tips_component_1.TemplateSafetyTipsComponent,
    template_safety_tips_container_component_1.TemplateSafetyTipsContainerComponent,
    template_previews_component_1.TemplatePreviewsComponent
];
class AwarenessComponentsModule {
}
exports.AwarenessComponentsModule = AwarenessComponentsModule;
