"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HideApiApplicationKeyAction = exports.ShowApiApplicationKeyAction = exports.GetApiApplicationKeySuccessAction = exports.GetApiApplicationKeyAction = exports.ApiGatewayViewActionTypes = void 0;
var ApiGatewayViewActionTypes;
(function (ApiGatewayViewActionTypes) {
    ApiGatewayViewActionTypes["GetApiApplicationKey"] = "[ApiApplication/ApiGateway/View] Get Api Application Key";
    ApiGatewayViewActionTypes["GetApiApplicationKeySuccess"] = "[ApiApplication/ApiGateway/View] Get Api Application Key Success";
    ApiGatewayViewActionTypes["ShowApiApplicationKey"] = "[ApiApplication/ApiGateway/View] Show Api Application Key";
    ApiGatewayViewActionTypes["HideApiApplicationKey"] = "[ApiApplication/ApiGateway/View] Hide Api Application Key";
})(ApiGatewayViewActionTypes = exports.ApiGatewayViewActionTypes || (exports.ApiGatewayViewActionTypes = {}));
class GetApiApplicationKeyAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayViewActionTypes.GetApiApplicationKey;
    }
}
exports.GetApiApplicationKeyAction = GetApiApplicationKeyAction;
class GetApiApplicationKeySuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayViewActionTypes.GetApiApplicationKeySuccess;
    }
}
exports.GetApiApplicationKeySuccessAction = GetApiApplicationKeySuccessAction;
class ShowApiApplicationKeyAction {
    constructor() {
        this.type = ApiGatewayViewActionTypes.ShowApiApplicationKey;
    }
}
exports.ShowApiApplicationKeyAction = ShowApiApplicationKeyAction;
class HideApiApplicationKeyAction {
    constructor() {
        this.type = ApiGatewayViewActionTypes.HideApiApplicationKey;
    }
}
exports.HideApiApplicationKeyAction = HideApiApplicationKeyAction;
