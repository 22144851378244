<mc-siem-channel-sidebar-component
  [channel]="channel$ | async"
  [channelDetailsLoading]="channelDetailsLoading$ | async"
  [hasEditPermission]="hasEditPermission$ | async"
  [hasDeletePermission]="hasDeletePermission$ | async"
  [metadataProducts]="metadataProducts$ | async"
  (editChannel)="editChannel($event)"
  (deleteChannel)="deleteChannel($event)"
  (close)="close()">

</mc-siem-channel-sidebar-component>
