"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PolicyWizardDetailsComponent = void 0;
const forms_1 = require("@angular/forms");
const step_base_component_1 = require("../../../step.base.component");
const validators_1 = require("app-new/api-applications/validators");
const custom_domain_block_service_1 = require("app-new/custom-domain-block/services/custom-domain-block.service");
class PolicyWizardDetailsComponent extends step_base_component_1.StepBaseDirective {
    constructor(store, fb) {
        super(store, fb, 1, custom_domain_block_service_1.CustomDomainBlockService.WIZARD_ID);
        this.store = store;
        this.fb = fb;
        this.itHasFinishedLoadingSteps = false;
        this.form = this.fb.group({
            name: [
                '',
                [forms_1.Validators.required, forms_1.Validators.minLength(3), forms_1.Validators.maxLength(60), validators_1.validateNotEmpty]
            ],
            description: [
                '',
                [forms_1.Validators.required, forms_1.Validators.minLength(3), forms_1.Validators.maxLength(512), validators_1.validateNotEmpty]
            ]
        });
    }
    ngOnInit() {
        super.ngOnInit();
        this.form.patchValue({ name: this.name, description: this.description });
    }
    ngOnChanges(changes) {
        if (changes.wizardData.currentValue !== changes.wizardData.previousValue) {
            if (changes.wizardData.currentValue.currentStep === 4 && !this.itHasFinishedLoadingSteps) {
                this.itHasFinishedLoadingSteps = true;
                this.form.updateValueAndValidity();
            }
        }
    }
}
exports.PolicyWizardDetailsComponent = PolicyWizardDetailsComponent;
