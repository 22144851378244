"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiIntegrationOktaContactsStepComponent = void 0;
const core_1 = require("@angular/core");
class ApiIntegrationOktaContactsStepComponent {
    constructor() {
        this.onAddEmail = new core_1.EventEmitter();
        this.onDeleteEmail = new core_1.EventEmitter();
    }
    addEmail(email) {
        this.onAddEmail.emit(email);
    }
    deleteEmail(email) {
        this.onDeleteEmail.emit(email);
    }
    isValid() {
        return this.emails && this.emails.length > 0;
    }
}
exports.ApiIntegrationOktaContactsStepComponent = ApiIntegrationOktaContactsStepComponent;
