"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
(function () {
    'use strict';
    document.createElement('mc-layout-page-simple-summary');
    angular.module('layout.page-simple-summary.directive', [])
        .directive('mcLayoutPageSimpleSummary', [function () {
            return {
                restrict: 'E',
                templateUrl: 'components/layout/page-simple-summary/page-simple-summary.tpl.html',
                scope: {
                    mainContainerClass: '@mainContainerClass',
                    mainInlineNotification: '=mainInlineNotification',
                    headerTitle: '@headerTitle',
                    headerDescription: '@headerDescription',
                    bottomDescription: '@bottomDescription'
                },
                transclude: {
                    mainContainer: 'mainContainer',
                    bottomContainer: '?bottomContainer',
                    sideContainer: '?sideContainer'
                },
                link(scope, element, attrs, controller, transcludeFn) {
                    scope.bottomContainer = transcludeFn.isSlotFilled('bottomContainer');
                    scope.sideContainer = transcludeFn.isSlotFilled('sideContainer');
                }
            };
        }]);
}());
