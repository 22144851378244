"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OktaEvidenceBasedControlIntegrationService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const common_1 = require("../../common");
const BASE_URL = '/api/integration/okta';
class OktaEvidenceBasedControlIntegrationService {
    constructor(http) {
        this.http = http;
    }
    verifyOktaEvidenceBasedControlIntegrationApiKey(payload) {
        return this.http.post(`${BASE_URL}/verify`, payload).pipe(common_1.firstResult());
    }
    searchForOktaGroups(payload) {
        return this.http.post(`${BASE_URL}/find-groups`, payload).pipe(common_1.firstResult());
    }
    isInternalEmail(emailAddress) {
        return this.http
            .post('/api/user/get-profile', { emailAddress })
            .pipe(operators_1.map((response) => {
            return !response.first.external;
        }), operators_1.catchError(() => {
            return rxjs_1.of(false);
        }));
    }
    getOktaEvidenceBasedControlIntegration(id) {
        return this.http
            .post(`${BASE_URL}/get-integration`, { id })
            .pipe(common_1.firstResult());
    }
    createIntegration(payload) {
        return this.http.post(`${BASE_URL}/create-integration`, payload).pipe(common_1.firstResult(), operators_1.catchError(() => rxjs_1.of(null)));
    }
    updateIntegration(payload) {
        return this.http.post(`${BASE_URL}/update-integration`, payload).pipe(common_1.firstResult(), operators_1.catchError(() => rxjs_1.of(null)));
    }
}
exports.OktaEvidenceBasedControlIntegrationService = OktaEvidenceBasedControlIntegrationService;
