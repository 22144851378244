"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationShowFailAction = exports.NotificationShowSuccessAction = exports.NotificationShowAction = exports.NOTIFICATION_SHOW = void 0;
exports.NOTIFICATION_SHOW = '[Notification] Show';
class NotificationShowAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.NOTIFICATION_SHOW;
    }
}
exports.NotificationShowAction = NotificationShowAction;
class NotificationShowSuccessAction extends NotificationShowAction {
    constructor(message) {
        super({
            type: 'success',
            config: {
                msg: message
            }
        });
        this.message = message;
    }
}
exports.NotificationShowSuccessAction = NotificationShowSuccessAction;
class NotificationShowFailAction extends NotificationShowAction {
    constructor(message) {
        super({
            type: 'error',
            config: {
                msg: message
            }
        });
        this.message = message;
    }
}
exports.NotificationShowFailAction = NotificationShowFailAction;
