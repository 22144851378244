'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const moment = require("moment");
const _ = require("lodash");
angular.module('select-date-picker.controller', [])
    .controller('SelectDatePickerCtrl', ['$scope', '$translate', 'dateRangeModalService', 'adconCommonsService',
    function ($scope, $translate, dateRangeModalService, adconCommonsService) {
        const self = this;
        const strFormatToUse = $scope.showTimePicker ? adconCommonsService.CONSTANTS.UI_DATE_FORMAT : adconCommonsService.CONSTANTS.UI_ALTERNATE_DATE_FORMAT;
        const currentCustomDates = {
            startDate: moment().startOf('day'),
            endDate: moment().endOf('day')
        };
        self.dropdownItemTypes = angular.copy(adconCommonsService.CONSTANTS.DROPDOWN_ITEM_TYPES);
        if ($scope.initLabel) {
            self.selectedRangeLabel = $translate.instant($scope.initLabel);
        }
        else {
            self.selectedRangeLabel = $translate.instant($scope.ranges[adconCommonsService.CONSTANTS.DATE_RANGES_KEYS.TODAY].label);
        }
        self.updateDateRange = updateDateRange;
        function updateDateRange(key) {
            const rangeSelected = $scope.ranges[key];
            setActiveRange(key);
            if (rangeSelected.custom) {
                dateRangeModalService.open(currentCustomDates.startDate, currentCustomDates.endDate, $scope.showTimePicker, $scope.minStartDate, $scope.maxEndDate).then(function (range) {
                    self.selectedRangeLabel = adconCommonsService.formatMcUtcDate(range.startDate, strFormatToUse) + ' / ' + adconCommonsService.formatMcUtcDate(range.endDate, strFormatToUse);
                    $scope.startDate = range.startDate;
                    $scope.endDate = range.endDate;
                    currentCustomDates.startDate = range.startDate;
                    currentCustomDates.endDate = range.endDate;
                });
            }
            else {
                $scope.startDate = rangeSelected.dates.from;
                $scope.endDate = rangeSelected.dates.to;
                self.selectedRangeLabel = $translate.instant(rangeSelected.label);
            }
        }
        function setActiveRange(key) {
            const activeRange = _.findKey($scope.ranges, adconCommonsService.CONSTANTS.ACTIVE);
            $scope.ranges[activeRange].active = false;
            $scope.ranges[key].active = true;
        }
    }]);
