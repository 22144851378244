"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.isInitialRequest = exports.isGenericError = exports.isApiProcessing = exports.getUrlOrDomain = exports.getClassification = exports.getSwgUrlCheckReportState = exports.reducers = void 0;
const store_1 = require("@ngrx/store");
const urlCheck = require("./url-check.reducer");
const urlReport = require("./url-report.reducer");
exports.reducers = {
    urlCheck: urlCheck.reducer,
    urlReport: urlReport.reducer
};
exports.getSwgUrlCheckReportState = store_1.createFeatureSelector('swgUrlCheckReport');
const ɵ0 = state => state.urlCheck;
exports.ɵ0 = ɵ0;
const getSwgClassificationState = store_1.createSelector(exports.getSwgUrlCheckReportState, ɵ0);
exports.getClassification = store_1.createSelector(getSwgClassificationState, urlCheck.getClassification);
exports.getUrlOrDomain = store_1.createSelector(getSwgClassificationState, urlCheck.getUrlOrDomain);
exports.isApiProcessing = store_1.createSelector(getSwgClassificationState, urlCheck.isApiProcessing);
exports.isGenericError = store_1.createSelector(getSwgClassificationState, urlCheck.isGenericError);
exports.isInitialRequest = store_1.createSelector(getSwgClassificationState, urlCheck.isInitialRequest);
