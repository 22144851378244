"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getGroupsDisplayText = exports.getGroups = exports.getAppliedToEveryone = exports.reducer = exports.initialState = void 0;
const actions = require("../../actions/policy-wizard/applies-to-step.actions");
exports.initialState = {
    appliedToEveryone: true,
    groups: [],
    groupsDisplayText: ''
};
function reducer(state = exports.initialState, action) {
    let groups = [...state.groups];
    switch (action.type) {
        case actions.SAVE_APPLIES_TO_EVERYONE:
            return Object.assign(Object.assign({}, state), { appliedToEveryone: action.payload, groupsDisplayText: action.payload ? '' : state.groupsDisplayText });
        case actions.ADD_GROUP:
            if (!groups.find(group => group.id === action.payload.id)) {
                groups.push(action.payload);
            }
            return Object.assign(Object.assign({}, state), { groups: [...groups], groupsDisplayText: groups.map(group => group.description).join(', ') });
        case actions.REMOVE_GROUP:
            groups = groups.filter(group => group.id !== action.payload.id);
            return Object.assign(Object.assign({}, state), { groups: [...groups], groupsDisplayText: groups.map(group => group.description).join(', ') });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getAppliedToEveryone = (state) => state.appliedToEveryone;
exports.getAppliedToEveryone = getAppliedToEveryone;
const getGroups = (state) => state.groups;
exports.getGroups = getGroups;
const getGroupsDisplayText = (state) => state.groupsDisplayText;
exports.getGroupsDisplayText = getGroupsDisplayText;
