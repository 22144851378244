<mc-template-frame>
  <mc-feedback-badge
    status="success"
    text="$I18N_AWARENESS_TEMPLATE_SETUP_SAFE_LINK_MISIDENTIFIED_PAGE_SAFE_LINK"
  ></mc-feedback-badge>

  <mc-template-title-sub-title [title]="title" [subTitle]="subTitle"></mc-template-title-sub-title>

  <mc-carousel [disabledNavigation]="true" [tips]="tips"></mc-carousel>

  <div class="mc-buttons-container">
    <button class="btn btn-primary" data-test="CONTINUE_TO_PAGE" translate="$I18N_AWARENESS_TEMPLATE_SETUP_SAFE_LINK_MISIDENTIFIED_PAGE_CONTINUE_BUTTON"></button>
  </div>
</mc-template-frame>
