"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiGatewayService = void 0;
const http_1 = require("@angular/common/http");
const common_1 = require("../common");
class ApiGatewayService {
    constructor(http) {
        this.http = http;
    }
    getAllApplications() {
        return this.http.post('/api/api-gateway/get-application', undefined).pipe(common_1.allResult());
    }
    getApplication(id) {
        return this.http
            .post('/api/api-gateway/get-application', { id })
            .pipe(common_1.firstResult());
    }
    getAppKey(id) {
        return this.http
            .post('/api/api-gateway/get-appkey', { id })
            .pipe(common_1.firstResult());
    }
    createApplication(application) {
        return this.http
            .post('/api/api-gateway/create-application', application)
            .pipe(common_1.firstResult());
    }
    updateApplication(application) {
        return this.http
            .post('/api/api-gateway/update-application', application)
            .pipe(common_1.firstResult());
    }
    deleteApplication(id) {
        return this.http
            .post('/api/api-gateway/delete-application', { id })
            .pipe(common_1.firstResult());
    }
    enableApplication(id) {
        return this.updateApplication({ id, active: true });
    }
    disableApplication(id) {
        return this.updateApplication({ id, active: false });
    }
    getApplicationRoleList(attachHeader) {
        let headers = new http_1.HttpHeaders();
        if (attachHeader) {
            headers = new http_1.HttpHeaders().set('logoutOnError', 'false');
        }
        return this.http
            .post('/api/api-gateway/get-applicable-roles', undefined, { headers })
            .pipe(common_1.firstResult());
    }
    getApplicationProductList() {
        return this.http
            .post('/api/api-gateway/get-products', undefined)
            .pipe(common_1.firstResult());
    }
    getCredentials(id) {
        return this.http
            .post('/api/api-gateway/get-gateway-credentials', { id })
            .pipe(common_1.firstResult());
    }
    getNewCredentials(newCredentialsPayload) {
        return this.http
            .post('/api/api-gateway/reset-gateway-credentials', newCredentialsPayload)
            .pipe(common_1.firstResult());
    }
}
exports.ApiGatewayService = ApiGatewayService;
