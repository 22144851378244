'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const _ = require("lodash");
document.createElement('mc-frame');
angular.module('frame.directive', [])
    .directive('mcFrame', ['$q', '$window', '$interval', '$rootScope', function ($q, $window, $interval, $rootScope) {
        let count = 0;
        return {
            restrict: 'AE',
            template() {
                const frameName = 'mcFrame' + count;
                count++;
                return '<div class="mc-frame">' +
                    '<iframe name="' + frameName + '" tab-index="-1"></iframe>' +
                    '<spinner ng-show="frame.isLoading"></spinner>' +
                    '<form method="post" action="" target="' + frameName + '" enctype="application/x-www-form-urlencoded"></form>' +
                    '</div>';
            },
            link($scope, $element, $attr) {
                const form = angular.element($element.find('form')[0]);
                const iframe = angular.element($element.find('iframe')[0]);
                const mcFrame = new McFrame();
                const url = $attr.url && $scope.$eval($attr.url);
                const params = $attr.params && $scope.$eval($attr.params);
                const actions = $attr.actions && $scope.$eval($attr.actions);
                const hidden = $attr.hidden && $scope.$eval($attr.hidden) || false;
                if (hidden) {
                    $element.attr('class', 'mc-frame-hidden');
                }
                activate();
                function activate() {
                    $scope.frame = mcFrame;
                    iframe.bind('load', function (evt) {
                        mcFrame.isLoading = false;
                        if (actions) {
                            actions.onload(this);
                        }
                        $scope.$apply(function () {
                            mcFrame.onLoad(evt);
                        });
                    });
                    if (url) {
                        mcFrame.post(url, params);
                    }
                }
                function McFrame() {
                    const self = this;
                    let loadingDeferer;
                    let callBackDeferer;
                    let timeoutPromise;
                    self.name = iframe.name;
                    self.onLoad = onLoad;
                    self.post = post;
                    self.update = update;
                    self.postMessage = postMessage;
                    self.isLoading = false;
                    function update(data) {
                        loadingDeferer = $q.defer();
                        if (data) {
                            _.forEach(data, function (val, key) {
                                const element = iframe.contents().find("input[name='" + key + "']");
                                element.val(val);
                            });
                        }
                        form.submit();
                        self.isLoading = true;
                        return loadingDeferer;
                    }
                    function appendInputs(data) {
                        if (data) {
                            _.forEach(data, function (val, key) {
                                const element = angular.element('<input>');
                                element
                                    .attr({ 'type': 'hidden', 'name': key })
                                    .val(val);
                                form.append(element);
                            });
                        }
                    }
                    function post(action, data, callbackMessage, globalMessage) {
                        self.isLoading = true;
                        if (callbackMessage) {
                            return postWithCallback(action, data, callbackMessage, globalMessage);
                        }
                        if (loadingDeferer) {
                            loadingDeferer.reject('Cancel load');
                        }
                        form.attr({ action, 'method': 'POST' })
                            .children()
                            .remove();
                        loadingDeferer = $q.defer();
                        appendInputs(data);
                        form.submit();
                        return loadingDeferer.promise;
                    }
                    function postMessage(message, data, PostMessageUrl) {
                        iframe[0].contentWindow.postMessage(JSON.stringify({ message, data }), PostMessageUrl);
                    }
                    function postWithCallback(action, data, callbackMessage, globalMessage) {
                        if (callBackDeferer) {
                            callBackDeferer.reject('Cancel load');
                        }
                        if (timeoutPromise) {
                            $interval.cancel(timeoutPromise);
                        }
                        const messageName = globalMessage ? callbackMessage : iframe.attr('name') + '-' + callbackMessage;
                        form.attr({ action, 'method': 'POST' })
                            .children()
                            .remove();
                        callBackDeferer = $q.defer();
                        appendInputs(data);
                        const element = angular.element('<input>');
                        element.attr({ 'type': 'hidden', 'name': 'callback-message' }).val(messageName);
                        form.append(element);
                        form.submit();
                        const unregister = $rootScope.$on(messageName, function (event, unregisterData) {
                            callBackDeferer.resolve(unregisterData);
                            callBackDeferer = undefined;
                            unregister();
                        });
                        timeoutPromise = $interval(function () {
                            if (callBackDeferer) {
                                callBackDeferer.reject('interval');
                            }
                            callBackDeferer = undefined;
                            unregister();
                        }, 90 * 1000, 1);
                        $scope.$on('$destroy', function () {
                            unregister();
                            $interval.cancel(timeoutPromise);
                        });
                        return callBackDeferer.promise;
                    }
                    function onLoad() {
                        if (loadingDeferer) {
                            loadingDeferer.resolve(iframe);
                            self.isLoading = false;
                            loadingDeferer = null;
                        }
                    }
                }
            }
        };
    }]);
