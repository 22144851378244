"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasicAppConfigModule = void 0;
class BasicAppConfigModule {
    constructor(parentModule) {
        if (parentModule) {
            throw new Error('BasicAppConfigModule is already loaded. Import it in the AppModule only');
        }
    }
}
exports.BasicAppConfigModule = BasicAppConfigModule;
