"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddDomains = exports.ClearListState = exports.BulkDeleteItems = exports.DeleteFail = exports.DeleteSuccess = exports.DeleteAlertCancel = exports.DeleteAlertOpen = exports.UnSelectListItem = exports.SelectListItem = exports.UnSelectAll = exports.SelectAll = exports.ADD_DOMAINS = exports.CLEAR_LIST_STATE = exports.DELETE_FAIL = exports.DELETE_SUCCESS = exports.BULK_DELETE_ITEMS = exports.DELETE_ALERT_CANCEL = exports.DELETE_ALERT_OPEN = exports.UN_SELECT_LIST_ITEM = exports.SELECT_LIST_ITEM = exports.UN_SELECT_ALL = exports.SELECT_ALL = void 0;
exports.SELECT_ALL = '[External Domain List] SELECT_ALL';
exports.UN_SELECT_ALL = '[External Domain List] UN_SELECT_ALL';
exports.SELECT_LIST_ITEM = '[External Domain List] SELECT_LIST_ITEM';
exports.UN_SELECT_LIST_ITEM = '[External Domain List] UN_SELECT_ITEM';
exports.DELETE_ALERT_OPEN = '[External Domain List] DELETE_ALERT_OPEN';
exports.DELETE_ALERT_CANCEL = '[External Domain List] DELETE_ALERT_CANCEL';
exports.BULK_DELETE_ITEMS = '[External Domain List] BULK_DELETE_ITEMS';
exports.DELETE_SUCCESS = '[External Domain List] DELETE_SUCCESS';
exports.DELETE_FAIL = '[External Domain List] DELETE_FAIL';
exports.CLEAR_LIST_STATE = '[External Domain List] CLEAR_LIST_STATE';
exports.ADD_DOMAINS = '[External Domain List] ADD_DOMAINS';
class SelectAll {
    constructor(allSelectedItems) {
        this.allSelectedItems = allSelectedItems;
        this.type = exports.SELECT_ALL;
    }
}
exports.SelectAll = SelectAll;
class UnSelectAll {
    constructor() {
        this.type = exports.UN_SELECT_ALL;
    }
}
exports.UnSelectAll = UnSelectAll;
class SelectListItem {
    constructor(payLoad) {
        this.payLoad = payLoad;
        this.type = exports.SELECT_LIST_ITEM;
    }
}
exports.SelectListItem = SelectListItem;
class UnSelectListItem {
    constructor(payLoad) {
        this.payLoad = payLoad;
        this.type = exports.UN_SELECT_LIST_ITEM;
    }
}
exports.UnSelectListItem = UnSelectListItem;
class DeleteAlertOpen {
    constructor() {
        this.type = exports.DELETE_ALERT_OPEN;
    }
}
exports.DeleteAlertOpen = DeleteAlertOpen;
class DeleteAlertCancel {
    constructor() {
        this.type = exports.DELETE_ALERT_CANCEL;
    }
}
exports.DeleteAlertCancel = DeleteAlertCancel;
class DeleteSuccess {
    constructor(deletedItems) {
        this.deletedItems = deletedItems;
        this.type = exports.DELETE_SUCCESS;
    }
}
exports.DeleteSuccess = DeleteSuccess;
class DeleteFail {
    constructor() {
        this.type = exports.DELETE_FAIL;
    }
}
exports.DeleteFail = DeleteFail;
class BulkDeleteItems {
    constructor() {
        this.type = exports.BULK_DELETE_ITEMS;
    }
}
exports.BulkDeleteItems = BulkDeleteItems;
class ClearListState {
    constructor() {
        this.type = exports.CLEAR_LIST_STATE;
    }
}
exports.ClearListState = ClearListState;
class AddDomains {
    constructor() {
        this.type = exports.ADD_DOMAINS;
    }
}
exports.AddDomains = AddDomains;
