"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgMalwareReportListApiService = void 0;
class SwgMalwareReportListApiService {
    constructor(http) {
        this.http = http;
    }
    getMalwareReportList(config) {
        return this.http.post('/api/swg/get-security-logs', config);
    }
}
exports.SwgMalwareReportListApiService = SwgMalwareReportListApiService;
