<div class="mc-supervision-wizard-step-container">
  <mc-wizard-step-header
    class="mc-supervision-wizard-step-header"
    label="$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.SETTINGS_STEP.STEP_DESCRIPTION"
  ></mc-wizard-step-header>
  <form
    [formGroup]="form"
    class="form-horizontal"
    mcDefaultLabelClass="col-sm-3"
    mcDefaultControlContainerClass="col-sm-9"
  >
    <div *ngIf="getStepDataById(1).qtype === QueueType.ESCALATION">
      <mc-switch-field
        label="$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.SETTINGS_STEP.ALLOW_PRINTING"
        required
        [showLabel]="true"
        helpPopoverContent="{{
          '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.SETTINGS_STEP.ALLOW_PRINTING_POPOVER' | translate
        }}"
        formControlName="allowPrinting"
        class="mc-supervision-wizard-step-container"
      >
      </mc-switch-field>
    </div>

    <div>
      <mc-switch-field
        label="$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.SETTINGS_STEP.ALLOW_FORWARDING"
        [showLabel]="true"
        helpPopoverContent="{{
          '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.SETTINGS_STEP.ALLOW_FORWARDING_POPOVER'
            | translate
        }}"
        formControlName="allowForwarding"
      >
      </mc-switch-field>
    </div>

    <mc-field
      label="{{
        (getStepDataById(1).qtype === QueueType.ESCALATION
          ? '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.SETTINGS_STEP.ESCALATION_MANAGERS'
          : '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.SETTINGS_STEP.REVIEWERS') | translate
      }}"
      helpPopoverContent="{{
        '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.SETTINGS_STEP.SELECT_REVIEWERS_POPOVER' | translate
      }}"
      labelClass="col-sm-3 reviewersLabel"
    >
      <input type="hidden" formControlName="selectReviewersPopover" />
      <button type="button" class="btn btn-default" (click)="openSidebar()">
        {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.SETTINGS_STEP.SELECT_REVIEWERS' | translate }}
      </button>
      <div formArrayName="reviewers">
        <div class="selected-group" *ngFor="let reviewer of reviewers.controls; let i = index">
          <span class="groups-list">
            <div class="groups-name">{{ getReviewerName(reviewer.value.reviewer) }}</div>
            <i class="far fa-trash-alt" aria-hidden="true" (click)="deleteReviewers(i)"></i>
          </span>
          <hr class="groups-separator" />
        </div>
      </div>
    </mc-field>

    <mc-field
      label="{{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.SETTINGS_STEP.LABELS' | translate }}"
      helpPopoverContent="{{
        '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.SETTINGS_STEP.LABELS_POPOVER' | translate
      }}"
      errorPrefix="$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.VALIDATION.LABEL"
    >
      <div class="form-group labels-input no-padding-left">
        <div class="col-sm-10">
          <input type="text" formControlName="newLabel" class="form-control new-label-input" />
        </div>
        <button
          type="submit"
          class="btn btn-default new-label-button"
          (click)="addLables()"
          [disabled]="!isNewLabelValid || areTooManyLabels()"
        >
          {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.CONTROLS.ADD' | translate }}
        </button>
      </div>
      <mc-errors
        [errors]="labelErrors"
        formPrefix="$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.VALIDATION.LABELS"
      ></mc-errors>
    </mc-field>
    <div class="row negative-margin">
      <div class="col-sm-3"></div>
      <div class="col-sm-9">
        <div formArrayName="labels">
          <div class="selected-group" *ngFor="let label of userLabels.controls; let i = index">
            <span class="groups-list labels">
              <div class="groups-name">{{ label.value.name }}</div>
              <i class="far fa-trash-alt" aria-hidden="true" (click)="deleteLabels(i)"></i>
            </span>
            <hr class="groups-separator" />
          </div>
        </div>
      </div>
    </div>
    <div></div>
  </form>
</div>
