"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppsEmptyPlaceholderComponent = void 0;
const core_1 = require("@angular/core");
const rxjs_1 = require("rxjs");
class AppsEmptyPlaceholderComponent {
    constructor(translateService) {
        this.translateService = translateService;
        this.add = new core_1.EventEmitter();
        this.isAddBtnDisabled$ = new rxjs_1.BehaviorSubject(false);
    }
    onAddClick() {
        this.isAddBtnDisabled$.next(true);
        this.add.emit();
    }
}
exports.AppsEmptyPlaceholderComponent = AppsEmptyPlaceholderComponent;
