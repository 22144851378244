<mc-spinner *ngIf="isLoading$ | async"></mc-spinner>
<div *ngIf="!(isLoading$ | async)" class="home mc-layout-main-container" data-role="page">

  <!--  Header -->
  <div id="header">
    <mc-header [title]="templateName$ | async | trim"
               [subtitle]="subtitle">
    </mc-header>
    <div class="mc-header-container">
      <mc-live-button [mcDisabled]="!formComponent.form?.valid" typeClass="btn-primary" content="Save" (mcClick)="save()"
                      [isLoading]="isSaved$ | async"></mc-live-button>
      <a class="btn btn-default cancel" (click)="cancel()" click role="button">Cancel</a>
    </div>
  </div>

  <div class="mc-content-side-by-side">
    <!-- left panel -->
    <div class="mc-panel mc-left-panel">
      <mc-template-form #formComponent [templateId]="templateId" [value]="templateForForm$ | async"
                        (showPage)="showPage($event)" (valueChanges)="onFormChanges($event)"></mc-template-form>
    </div>
    <!-- right panel -->
    <div class="mc-panel mc-right-panel">
      <mc-template-previews [template]="template$ | async"
                            [preview]="preview"
                            [customSafetyTips]="templateSafetyTipsOrPreview$ | async"
                            [mimecastSafetyTips]="mimecastSafetyTipsOrPreview$ | async"
      >
      </mc-template-previews>
    </div>
  </div>
</div>
