"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LexiconDetails = void 0;
const lexicon_base_1 = require("./lexicon-base");
class LexiconDetails extends lexicon_base_1.LexiconBase {
    constructor(id, name, description, createdBy, updatedBy, created, updated, status, associatedRules, searchParams, versions, searchType) {
        super(id, name, description, createdBy, updatedBy, created, updated, status);
        this.id = id;
        this.name = name;
        this.description = description;
        this.createdBy = createdBy;
        this.updatedBy = updatedBy;
        this.created = created;
        this.updated = updated;
        this.status = status;
        this.associatedRules = associatedRules;
        this.searchParams = searchParams;
        this.versions = versions;
        this.searchType = searchType;
    }
}
exports.LexiconDetails = LexiconDetails;
