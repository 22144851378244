"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CloseAside = exports.ClearAddUrlState = exports.CloseUrlSidePanelAction = exports.CreateManagedUrlFail = exports.AllowBlockManagedUrlSuccessAction = exports.CreateManagedUrlSuccessAction = exports.CreateManagedUrlAttemptAction = exports.CreateManagedUrlAction = exports.OpenAddNewManagedUrlSidePanelAction = exports.ALLOW_BLOCK_MANAGED_URL_SUCCESS = exports.CLOSE_ASIDE = exports.CLEAR_ADD_MANAGED_URL_STATE = exports.CLOSE_MANAGED_URL_SIDE_PENAL = exports.CREATE_MANAGED_URL_FAIL = exports.CREATE_MANAGED_URL_SUCCESS = exports.CREATE_MANAGED_URL_ATTEMPT = exports.CREATE_MANAGED_URL = exports.OPEN_ADD_NEW_MANAGED_URL_SIDE_PANEL = void 0;
exports.OPEN_ADD_NEW_MANAGED_URL_SIDE_PANEL = '[Services / Url protection / Managed URL] Open add new managed url panel';
exports.CREATE_MANAGED_URL = '[Services / Url protection / Managed URL] Create managed url';
exports.CREATE_MANAGED_URL_ATTEMPT = '[Services / Url protection / Managed URL Create managed url attempt]';
exports.CREATE_MANAGED_URL_SUCCESS = '[Services / Url protection / Managed URL] Create managed url success';
exports.CREATE_MANAGED_URL_FAIL = '[Services / Url protection / Managed URL] Create managed url fail';
exports.CLOSE_MANAGED_URL_SIDE_PENAL = '[Services / Url protection / Managed URL] Close side panel';
exports.CLEAR_ADD_MANAGED_URL_STATE = '[Services / Url protection / Managed url] Clear add url state';
exports.CLOSE_ASIDE = '[Services / Url protection / Managed url] Close side panel';
exports.ALLOW_BLOCK_MANAGED_URL_SUCCESS = '[Services / Url protection / Logs] Allow/Block action success';
class OpenAddNewManagedUrlSidePanelAction {
    constructor() {
        this.type = exports.OPEN_ADD_NEW_MANAGED_URL_SIDE_PANEL;
    }
}
exports.OpenAddNewManagedUrlSidePanelAction = OpenAddNewManagedUrlSidePanelAction;
class CreateManagedUrlAction {
    constructor(payload, status) {
        this.payload = payload;
        this.status = status;
        this.type = exports.CREATE_MANAGED_URL;
    }
}
exports.CreateManagedUrlAction = CreateManagedUrlAction;
class CreateManagedUrlAttemptAction {
    constructor(payload, status, err) {
        this.payload = payload;
        this.status = status;
        this.err = err;
        this.type = exports.CREATE_MANAGED_URL_ATTEMPT;
    }
}
exports.CreateManagedUrlAttemptAction = CreateManagedUrlAttemptAction;
class CreateManagedUrlSuccessAction {
    constructor(payload, status) {
        this.payload = payload;
        this.status = status;
        this.type = exports.CREATE_MANAGED_URL_SUCCESS;
    }
}
exports.CreateManagedUrlSuccessAction = CreateManagedUrlSuccessAction;
class AllowBlockManagedUrlSuccessAction {
    constructor(payload, status) {
        this.payload = payload;
        this.status = status;
        this.type = exports.ALLOW_BLOCK_MANAGED_URL_SUCCESS;
    }
}
exports.AllowBlockManagedUrlSuccessAction = AllowBlockManagedUrlSuccessAction;
class CreateManagedUrlFail {
    constructor(err) {
        this.err = err;
        this.type = exports.CREATE_MANAGED_URL_FAIL;
    }
}
exports.CreateManagedUrlFail = CreateManagedUrlFail;
class CloseUrlSidePanelAction {
    constructor() {
        this.type = exports.CLOSE_MANAGED_URL_SIDE_PENAL;
    }
}
exports.CloseUrlSidePanelAction = CloseUrlSidePanelAction;
class ClearAddUrlState {
    constructor() {
        this.type = exports.CLEAR_ADD_MANAGED_URL_STATE;
    }
}
exports.ClearAddUrlState = ClearAddUrlState;
class CloseAside {
    constructor() {
        this.type = exports.CLOSE_ASIDE;
    }
}
exports.CloseAside = CloseAside;
