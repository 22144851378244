<mc-layout-list-extra-container
  keyTitle="$I18N_ACCOUNT_IP_CHECKER_MAIN_CONTAINER.HEADER.TITLE"
  keyDescription="$I18N_ACCOUNT_IP_CHECKER_MAIN_CONTAINER.HEADER.SUB_TITLE">

  <mc-help-container>
    <mc-help-link url="https://community.mimecast.com/docs/"></mc-help-link>
  </mc-help-container>

  <mc-body-container>

    <mc-ipchecker-search (onSearch)="search()"></mc-ipchecker-search>

  </mc-body-container>

</mc-layout-list-extra-container>
