"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserGroupsStepComponent = void 0;
const core_1 = require("@angular/core");
const components_1 = require("@mimecast-ui/components");
const operators_1 = require("rxjs/operators");
const select_user_groups_component_1 = require("../../../../shared/select-user-groups/asides/select-user-groups/select-user-groups.component");
class UserGroupsStepComponent {
    constructor(asideService) {
        this.asideService = asideService;
        this.userGroups = [];
        this.updateUserGroups = new core_1.EventEmitter();
    }
    openSidebar() {
        const asideConfig = new components_1.AsideConfig();
        asideConfig.data = {
            onlyLocalGroups: false,
            minCharacters: 1,
            hasPreselectedGroups: this.userGroups.length > 0
        };
        asideConfig.size = 'md';
        const aside = this.asideService.open(select_user_groups_component_1.SelectUserGroupsComponent, asideConfig);
        aside
            .result()
            .pipe(operators_1.take(1))
            .subscribe(data => {
            this.updateUserGroupsEvent(data.map((d) => ({ id: d.id, name: d.description })));
        });
    }
    removeSelected(id) {
        this.updateUserGroupsEvent(this.userGroups.filter(item => item.id !== id));
    }
    get isValid() {
        return !!this.userGroups.length && !this.moreThanFiveGroupsSelected;
    }
    get moreThanFiveGroupsSelected() {
        return this.userGroups.length > 5;
    }
    get moreThanFourGroupsSelected() {
        return this.userGroups.length > 4;
    }
    updateUserGroupsEvent(userGroups) {
        this.updateUserGroups.emit(userGroups);
    }
}
exports.UserGroupsStepComponent = UserGroupsStepComponent;
