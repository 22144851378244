"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMaxLengthFields = exports.MaxLength = void 0;
require("reflect-metadata");
const forms_1 = require("@angular/forms");
const MAX_LENGTH_METADATA_KEY = Symbol('MC-MAX-LENGTH-FIELD');
function MaxLength(max) {
    return (target, propertyKey) => {
        let maxLengthFields = Reflect.getMetadata(MAX_LENGTH_METADATA_KEY, target);
        const maxLengthField = {};
        maxLengthField[propertyKey] = forms_1.Validators.maxLength(max);
        if (maxLengthFields) {
            maxLengthFields.push(maxLengthField);
        }
        else {
            maxLengthFields = [maxLengthField];
        }
        Reflect.defineMetadata(MAX_LENGTH_METADATA_KEY, maxLengthFields, target);
    };
}
exports.MaxLength = MaxLength;
function getMaxLengthFields(origin) {
    const properties = Reflect.getMetadata(MAX_LENGTH_METADATA_KEY, origin);
    let result = {};
    if (properties) {
        properties.forEach(property => (result = Object.assign(Object.assign({}, result), property)));
    }
    return result;
}
exports.getMaxLengthFields = getMaxLengthFields;
