"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const containers_1 = require("./containers");
angular
    .module('message-center.held-messages', [])
    .controller('HeldMessagesController', [
    '$stateParams',
    '$scope',
    function ($stateParams, $scope) {
        $scope.stateParams = $stateParams;
    }
])
    .directive('mcHeldMessages', static_1.downgradeComponent({
    component: containers_1.HeldMessagesPageComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider.state('message-center-held-messages', {
            url: '/message-center/held-messages',
            data: {
                tabIndex: 0,
                isFixedLayout: false,
                checkProgress: false,
                capabilities: 'Temporary.Services.MessageCenter.HeldMessages && Permission.HELD_READ || Permission.HELD_SUMMARY_READ ',
                tabTitle: '$I18N_MESSAGE_CENTER_HELD_MESSAGES_ROUTER_TAB_TITLE',
                tabId: 'message-center-held-messages-tab-id',
                breadcrumbs: [
                    {
                        label: '$I18N_MESSAGE_CENTER_HELD_MESSAGES_BREADCRUMBS.MESSAGE_CENTER'
                    },
                    {
                        label: '$I18N_MESSAGE_CENTER_HELD_MESSAGES_BREADCRUMBS.HELD_MESSAGES',
                        stateName: ''
                    }
                ]
            },
            views: {
                main: {
                    template: '<mc-held-messages [tab-index]="tabIndex" (tab-change)="onTabChanged($event)"></mc-held-messages>',
                    controller: 'HeldMessagesController'
                }
            }
        });
    }
]);
