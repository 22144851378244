<mc-layout-aside-extra-container
  keyTitle="{{ '$I18N_ARCHIVE_SUPERVISION_SELECT_LEXICON.RULE.SELECT_LEXICON' | translate }}"
  showClose="true"
  [isLoading]="false"
  (closeAside)="onClose()"
>
  <mc-body-container>
    <div class="row">
      <div class="col-sm-12">
        <input
          #searchLexicon
          type="text"
          class="form-control search-lexicon"
          (input)="onSearchChange($event)"
          [placeholder]="
            '$I18N_ARCHIVE_SUPERVISION_SELECT_LEXICON.RULE.SEARCH_PLACEHOLDER' | translate
          "
        />
      </div>

      <div class="col-sm-12">
        <mc-loader-full-container
          [isLoading]="loading"
          class="mc-layout-loader-full-container-aside mc-flex-scroll-vertical"
        >
          <div *ngIf="loading" style="height: 100px;"></div>
        </mc-loader-full-container>

        <div *ngFor="let lexicon of sortedLexicons">
          <button
            class="btn-link"
            [class.selected]="isLexiconSelected(lexicon)"
            (click)="selectLexicon(lexicon)"
          >
            {{ lexicon.name }}
          </button>
        </div>

        <div *ngIf="noLexiconsFound" class="no-lexicons">
          {{ '$I18N_ARCHIVE_SUPERVISION_SELECT_LEXICON.RULE.NO_LEXICONS_FOUND' | translate }}
        </div>
      </div>
    </div>
  </mc-body-container>
</mc-layout-aside-extra-container>
