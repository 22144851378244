"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TopBlockedCategoriesChartConfiguration = exports.TopBlockedDomainsChartConfiguration = exports.TopCategoriesChartConfiguration = exports.TopDomainsChartConfiguration = exports.ActivityChartConfiguration = exports.ExportFormats = void 0;
const am4core = require("@amcharts/amcharts4/core");
exports.ExportFormats = [
    {
        type: 'png',
        label: 'Download PNG'
    },
    {
        type: 'pdf',
        label: 'Download PDF'
    },
    {
        type: 'svg',
        label: 'Download SVG'
    },
    {
        type: 'jpg',
        label: 'Download JPG'
    },
    {
        type: 'print',
        label: 'Print Chart'
    }
];
exports.ActivityChartConfiguration = {
    maxZoomLevel: 1,
    legend: {},
    series: [
        {
            type: 'LineSeries',
            tooltipText: "{dateX.formatDate('EEEE, MMM dd, HH:MM ')} \nAllowed: [bold]{valueY}[/]",
            dataFields: {
                dateX: 'date',
                valueY: 'allowed'
            },
            strokeWidth: 2,
            interactionsEnabled: true,
            name: 'Allowed',
            tensionX: 0.8,
            background: {
                fill: '#fff',
                fillOpacity: 1,
                stroke: '#000000',
                strokeWidth: 2
            },
            label: {
                fill: '#000'
            },
            bullets: [
                {
                    type: 'CircleBullet',
                    circle: {
                        strokeWidth: 2
                    }
                }
            ]
        },
        {
            type: 'LineSeries',
            tooltipText: "{dateX.formatDate('EEEE, MMM dd, HH:MM ')} \nBlocked: [bold]{valueY}[/]",
            dataFields: {
                dateX: 'date',
                valueY: 'blocked'
            },
            strokeWidth: 2,
            interactionsEnabled: true,
            name: 'Blocked',
            tensionX: 0.8,
            background: {
                fill: '#fff',
                fillOpacity: 1,
                stroke: '#f2f2f2',
                strokeWidth: 2
            },
            label: {
                fill: '#000'
            },
            bullets: [
                {
                    type: 'CircleBullet',
                    circle: {
                        strokeWidth: 1
                    }
                }
            ]
        }
    ],
    xAxes: [
        {
            type: 'DateAxis',
            renderer: {
                minGridDistance: 50
            },
            dateFormats: {
                day: 'dd MMM yyyy'
            },
            dataFields: {
                category: 'date'
            }
        }
    ],
    yAxes: [
        {
            type: 'ValueAxis',
            id: 'allowedValueAxis',
            cursorTooltipEnabled: false,
            maxPrecision: 0,
            title: {
                text: 'Requests'
            },
            renderer: {
                labels: {
                    template: {
                        stroke: '#808080',
                        disabled: false
                    }
                },
                line: {
                    strokeOpacity: 1,
                    strokeWidth: 2,
                    stroke: '#f2f2f2'
                },
                grid: {
                    template: {
                        disabled: false
                    }
                }
            },
            numberFormatter: {
                type: 'NumberFormatter',
                numberFormat: '#a',
                bigNumberPrefixes: [
                    { number: 1e6, suffix: 'M' },
                    { number: 1e9, suffix: 'B' }
                ]
            }
        }
    ]
};
exports.TopDomainsChartConfiguration = {
    maxZoomLevel: 1,
    series: [
        {
            type: 'ColumnSeries',
            tooltipY: 0,
            tooltipText: '{categoryY} \nRequests: [bold]{valueX}[/]',
            tooltip: {
                getFillFromObject: false,
                background: {
                    fill: '#fff',
                    fillOpacity: 1,
                    stroke: '#f2f2f2',
                    strokeWidth: 2
                },
                label: {
                    fill: '#000'
                }
            },
            columns: {
                fill: '#3A98D8',
                height: am4core.percent(35),
                strokeOpacity: 0,
                cursorOverStyle: am4core.MouseCursorStyle.pointer
            },
            dataFields: {
                valueX: 'value',
                categoryY: 'key'
            },
            interactionsEnabled: true,
            events: {}
        }
    ],
    xAxes: [
        {
            type: 'ValueAxis',
            maxPrecision: 0,
            min: 0,
            strictMinMax: true,
            cursorTooltipEnabled: false,
            renderer: {
                grid: {
                    disabled: false
                },
                minGridDistance: 50
            },
            title: {
                text: 'Requests'
            },
            numberFormatter: {
                type: 'NumberFormatter',
                numberFormat: '#a',
                bigNumberPrefixes: [
                    { number: 1e3, suffix: 'K' },
                    { number: 1e6, suffix: 'M' },
                    { number: 1e9, suffix: 'B' }
                ]
            }
        }
    ],
    yAxes: [
        {
            type: 'CategoryAxis',
            cursorTooltipEnabled: false,
            title: {
                text: 'Domains',
                rotation: -90,
                align: 'left',
                valign: 'middle',
                paddingLeft: 10,
                dy: 0
            },
            renderer: {
                labels: {
                    truncate: true,
                    wrap: true,
                    maxWidth: 150
                },
                grid: {
                    disabled: true,
                    template: {
                        location: 0
                    }
                },
                minGridDistance: 8
            },
            dataFields: {
                category: 'key'
            }
        }
    ]
};
exports.TopCategoriesChartConfiguration = {
    maxZoomLevel: 1,
    series: [
        {
            type: 'ColumnSeries',
            tooltipY: 0,
            tooltipText: '{categoryY} \nRequests: [bold]{valueX}[/]',
            tooltip: {
                getFillFromObject: false,
                background: {
                    fill: '#fff',
                    fillOpacity: 1,
                    stroke: '#f2f2f2',
                    strokeWidth: 2
                },
                label: {
                    fill: '#000'
                }
            },
            columns: {
                fill: '#3A98D8',
                height: am4core.percent(35),
                strokeOpacity: 0,
                cursorOverStyle: am4core.MouseCursorStyle.pointer
            },
            dataFields: {
                valueX: 'value',
                categoryY: 'key'
            },
            interactionsEnabled: true,
            events: {}
        }
    ],
    xAxes: [
        {
            type: 'ValueAxis',
            maxPrecision: 0,
            min: 0,
            strictMinMax: true,
            cursorTooltipEnabled: false,
            renderer: {
                grid: {
                    disabled: false
                },
                minGridDistance: 50
            },
            title: {
                text: 'Requests'
            },
            numberFormatter: {
                type: 'NumberFormatter',
                numberFormat: '#a',
                bigNumberPrefixes: [
                    { number: 1e3, suffix: 'K' },
                    { number: 1e6, suffix: 'M' },
                    { number: 1e9, suffix: 'B' }
                ]
            }
        }
    ],
    yAxes: [
        {
            type: 'CategoryAxis',
            cursorTooltipEnabled: false,
            title: {
                text: 'Categories',
                rotation: -90,
                align: 'center',
                valign: 'middle',
                dy: 0
            },
            renderer: {
                labels: {
                    truncate: true,
                    wrap: true,
                    maxWidth: 150
                },
                grid: {
                    disabled: true,
                    template: {
                        location: 0
                    }
                },
                minGridDistance: 8
            },
            dataFields: {
                category: 'key'
            }
        }
    ]
};
exports.TopBlockedDomainsChartConfiguration = {
    maxZoomLevel: 1,
    series: [
        {
            type: 'ColumnSeries',
            tooltipY: 0,
            tooltipText: '{categoryY} \nRequests: [bold]{valueX}[/]',
            tooltip: {
                getFillFromObject: false,
                background: {
                    fill: '#fff',
                    fillOpacity: 1,
                    stroke: '#f2f2f2',
                    strokeWidth: 2
                },
                label: {
                    fill: '#000'
                }
            },
            columns: {
                fill: '#3A98D8',
                height: am4core.percent(35),
                strokeOpacity: 0,
                cursorOverStyle: am4core.MouseCursorStyle.pointer
            },
            dataFields: {
                valueX: 'value',
                categoryY: 'key'
            },
            interactionsEnabled: true,
            events: {}
        }
    ],
    xAxes: [
        {
            type: 'ValueAxis',
            maxPrecision: 0,
            min: 0,
            strictMinMax: true,
            cursorTooltipEnabled: false,
            renderer: {
                grid: {
                    disabled: false
                },
                minGridDistance: 50
            },
            title: {
                text: 'Requests'
            },
            numberFormatter: {
                type: 'NumberFormatter',
                numberFormat: '#a',
                bigNumberPrefixes: [
                    { number: 1e3, suffix: 'K' },
                    { number: 1e6, suffix: 'M' },
                    { number: 1e9, suffix: 'B' }
                ]
            }
        }
    ],
    yAxes: [
        {
            type: 'CategoryAxis',
            cursorTooltipEnabled: false,
            title: {
                text: 'Domains',
                rotation: -90,
                align: 'center',
                valign: 'middle',
                dy: 0
            },
            renderer: {
                labels: {
                    truncate: true,
                    wrap: true,
                    maxWidth: 150
                },
                grid: {
                    disabled: true,
                    template: {
                        location: 0
                    }
                },
                minGridDistance: 8
            },
            dataFields: {
                category: 'key'
            }
        }
    ]
};
exports.TopBlockedCategoriesChartConfiguration = {
    maxZoomLevel: 1,
    series: [
        {
            type: 'ColumnSeries',
            tooltipY: 0,
            tooltipText: '{categoryY} \nRequests: [bold]{valueX}[/]',
            tooltip: {
                getFillFromObject: false,
                background: {
                    fill: '#fff',
                    fillOpacity: 1,
                    stroke: '#f2f2f2',
                    strokeWidth: 2
                },
                label: {
                    fill: '#000'
                }
            },
            columns: {
                fill: '#3A98D8',
                height: am4core.percent(35),
                strokeOpacity: 0,
                cursorOverStyle: am4core.MouseCursorStyle.pointer
            },
            dataFields: {
                valueX: 'value',
                categoryY: 'key'
            },
            interactionsEnabled: true,
            events: {}
        }
    ],
    xAxes: [
        {
            type: 'ValueAxis',
            maxPrecision: 0,
            min: 0,
            strictMinMax: true,
            cursorTooltipEnabled: false,
            renderer: {
                grid: {
                    disabled: false
                },
                minGridDistance: 50
            },
            title: {
                text: 'Requests'
            },
            numberFormatter: {
                type: 'NumberFormatter',
                numberFormat: '#a',
                bigNumberPrefixes: [
                    { number: 1e3, suffix: 'K' },
                    { number: 1e6, suffix: 'M' },
                    { number: 1e9, suffix: 'B' }
                ]
            }
        }
    ],
    yAxes: [
        {
            type: 'CategoryAxis',
            cursorTooltipEnabled: false,
            title: {
                text: 'Categories',
                rotation: -90,
                align: 'center',
                valign: 'middle',
                dy: 0
            },
            renderer: {
                labels: {
                    truncate: true,
                    wrap: true,
                    maxWidth: 150
                },
                grid: {
                    disabled: true,
                    template: {
                        location: 0
                    }
                },
                minGridDistance: 8
            },
            dataFields: {
                category: 'key'
            }
        }
    ]
};
