"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetMessageDetailsNoEmailReadPermissionAction = exports.GetMessageDetailsWithImagesSuccessAction = exports.GetMessageDetailsFailAction = exports.GetMessageDetailsSuccessAction = exports.GetMessageDetailsWithImagesAction = exports.GetMessageDetailsAction = exports.GET_MESSAGE_DETAILS_NO_EMAIL_READ_PERMISSION = exports.GET_MESSAGE_DETAILS_WITH_IMAGES_SUCCESS = exports.GET_MESSAGE_DETAILS_FAIL = exports.GET_MESSAGE_DETAILS_SUCCESS = exports.GET_MESSAGE_DETAILS_WITH_IMAGES = exports.GET_MESSAGE_DETAILS = exports.PREFIX = void 0;
exports.PREFIX = '[Remediation] Message details';
exports.GET_MESSAGE_DETAILS = '[Remediation] get';
exports.GET_MESSAGE_DETAILS_WITH_IMAGES = '[Remediation] get with images';
exports.GET_MESSAGE_DETAILS_SUCCESS = '[Remediation] loaded';
exports.GET_MESSAGE_DETAILS_FAIL = '[Remediation] failed to load';
exports.GET_MESSAGE_DETAILS_WITH_IMAGES_SUCCESS = '[Remediation] loaded with images';
exports.GET_MESSAGE_DETAILS_NO_EMAIL_READ_PERMISSION = '[Remediation] no email read permissions';
class GetMessageDetailsAction {
    constructor(row, stripImages = true) {
        this.row = row;
        this.stripImages = stripImages;
        this.type = exports.GET_MESSAGE_DETAILS;
    }
}
exports.GetMessageDetailsAction = GetMessageDetailsAction;
class GetMessageDetailsWithImagesAction {
    constructor(row, stripImages = false) {
        this.row = row;
        this.stripImages = stripImages;
        this.type = exports.GET_MESSAGE_DETAILS_WITH_IMAGES;
    }
}
exports.GetMessageDetailsWithImagesAction = GetMessageDetailsWithImagesAction;
class GetMessageDetailsSuccessAction {
    constructor(message) {
        this.message = message;
        this.type = exports.GET_MESSAGE_DETAILS_SUCCESS;
    }
}
exports.GetMessageDetailsSuccessAction = GetMessageDetailsSuccessAction;
class GetMessageDetailsFailAction {
    constructor(error) {
        this.error = error;
        this.type = exports.GET_MESSAGE_DETAILS_FAIL;
    }
}
exports.GetMessageDetailsFailAction = GetMessageDetailsFailAction;
class GetMessageDetailsWithImagesSuccessAction {
    constructor(message) {
        this.message = message;
        this.type = exports.GET_MESSAGE_DETAILS_WITH_IMAGES_SUCCESS;
    }
}
exports.GetMessageDetailsWithImagesSuccessAction = GetMessageDetailsWithImagesSuccessAction;
class GetMessageDetailsNoEmailReadPermissionAction {
    constructor() {
        this.type = exports.GET_MESSAGE_DETAILS_NO_EMAIL_READ_PERMISSION;
    }
}
exports.GetMessageDetailsNoEmailReadPermissionAction = GetMessageDetailsNoEmailReadPermissionAction;
