  <mc-table
  [data]="tableData$ | async"
  [columns]="columns"
  (rowClick)="openItem($event)"
  [isLoading]="isLoading$ | async"
  translatePrefix="$I18N_CUSTOM_DOMAIN_BLOCK_CONFIGURATIONS_TABLE.TABLE"
  [showHighlightedRow]="true"
  [highlightedRow]="highlightedRow$ | async"
  tableId="custom-domain-block/configurations-table">

  <button
    class="mc-table-actions btn btn-primary"
    *mcCapabilities="'Permission.CUSTOM_DOMAIN_BLOCK_UPLOAD'"
    (click)="newConfiguration()"
  >
    {{ '$I18N_CUSTOM_DOMAIN_BLOCK_CONFIGURATIONS_TABLE.ACTIONS.NEW_CONFIGURATION' | translate }}
  </button>

  <mc-filters
    [metadata]="metaData$ | async"
    [showPagination]="true"
    (paginatorChange)="onPaginationChange($event)"  >
    <mc-filter-bundle-filter-by-and-search-by
      (filtersChange)="onFilterChange($event)"
      placeholder="$I18N_CUSTOM_DOMAIN_BLOCK_CONFIGURATIONS_TABLE.TABLE.PLACEHOLDER"
      [sections]="[]"
      [searchByFields]="searchByFields"
    >
    </mc-filter-bundle-filter-by-and-search-by>
  </mc-filters>

  <mc-column key="action">
    <mc-body-cell *mcBodyCellDef="let row">
      <i class="fas fa-share" *ngIf="row.action === 'redirect'"></i>
      <i class="fas fa-ban" *ngIf="row.action !== 'redirect'"></i>
       {{ '$I18N_CUSTOM_DOMAIN_BLOCK_CONFIGURATIONS_TABLE.TABLE.' + row.action.toUpperCase() | translate }}
    </mc-body-cell>
  </mc-column>


  <mc-column key="notify">
    <mc-body-cell *mcBodyCellDef="let row">
      <span class="row-enabled"  *ngIf="row.notify">{{ '$I18N_CUSTOM_DOMAIN_BLOCK_CONFIGURATIONS_TABLE.TABLE.ENABLED' | translate }}</span>
      <span class="row-disabled" *ngIf="!row.notify">{{ '$I18N_CUSTOM_DOMAIN_BLOCK_CONFIGURATIONS_TABLE.TABLE.DISABLED' | translate }}</span>
    </mc-body-cell>
  </mc-column>


  <mc-column key="lastUpdated">
    <mc-body-cell *mcBodyCellDef="let row">{{
      row.lastUpdated | mcDate
    }}</mc-body-cell>
  </mc-column>


  <mc-column key="status">
    <mc-body-cell *mcBodyCellDef="let row">
      <span class="row-enabled" *ngIf="row.enabled">{{ '$I18N_CUSTOM_DOMAIN_BLOCK_CONFIGURATIONS_TABLE.TABLE.ENABLED' | translate }}</span>
      <span class="row-disabled" *ngIf="!row.enabled">{{ '$I18N_CUSTOM_DOMAIN_BLOCK_CONFIGURATIONS_TABLE.TABLE.DISABLED' | translate }}</span>
    </mc-body-cell>
  </mc-column>

  <mc-empty-results
    keyTitle="$I18N_CUSTOM_DOMAIN_BLOCK_CONFIGURATIONS_TABLE.TABLE.EMPTY_RESULTS"
    iconClass="mc-icon-adcon-icon-stable-no-results-2"
  >
  </mc-empty-results>

  <mc-column-select key="select"></mc-column-select>
  <mc-column-actions
    key="right-column"
    mcShowColumnConfig
    [columnsAlwaysVisible]="columnsAlwaysVisible"
    [columnsToStartHidden]="[]"
  >

  </mc-column-actions>
</mc-table>
