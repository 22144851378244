<div class="session-timeout-panel mc-identity-policy-steps-container">

    <mc-wizard-step-header class="mc-identity-wizard-step-header"
                           label="$I18N_IDENTITY_CREATE_POLICY_SESSION_TIMEOUT_STEP.TITLE">
    </mc-wizard-step-header>

    <form class="form-horizontal"
          [formGroup]="policySessionTimeoutForm"
          mcDefaultLabelClass="col-sm-3"
          mcDefaultControlContainerClass="col-sm-9 mc-field-container"
          mcErrorPrefix="$I18N_IDENTITY_CREATE_POLICY_SESSION_TIMEOUT_STEP.ELEMENTS.TIMEOUT.ERROR">

        <mc-field label="$I18N_IDENTITY_CREATE_POLICY_SESSION_TIMEOUT_STEP.ELEMENTS.TIMEOUT.LABEL"
                  [required]="true">

          <div class="form-control-children">
            <input type="number"
                   formControlName="sessionTimeout"
                   class="form-control mc-timeout-control"/>

            <mc-select formControlName="sessionTimeoutType"
                       [options]="sessionTimeoutOptions"
                       class="mc-timeout-control"></mc-select>
          </div>
        </mc-field>
    </form>
</div>
