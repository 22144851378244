"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImSyncTaskDetailsComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const step_base_component_1 = require("../../../step.base.component");
const validators_1 = require("app-new/api-applications/validators");
const im_task_service_1 = require("../../../services/im-task.service");
class ImSyncTaskDetailsComponent extends step_base_component_1.StepBaseDirective {
    constructor(store, fb, translateService) {
        super(store, fb, 1, im_task_service_1.ImTaskService.WizardId);
        this.store = store;
        this.fb = fb;
        this.translateService = translateService;
        this.onProviderSelected = new core_1.EventEmitter();
        this.sourceOptions = [
            {
                label: this.translateService.instant('$I18N_ARCHIVE_IM_SYNC_WIZARD.IM_SYNC_TASK.DETAILS_STEP.MS_TEAMS'),
                value: 'ms_teams'
            }
        ];
        this.form = this.fb.group({
            name: [
                '',
                [
                    forms_1.Validators.required,
                    forms_1.Validators.minLength(3),
                    forms_1.Validators.maxLength(60),
                    validators_1.validateNotEmpty,
                    forms_1.Validators.pattern(/^[^\s]+(\s+[^\s]+)*$/)
                ]
            ],
            description: [
                '',
                [
                    forms_1.Validators.minLength(3),
                    forms_1.Validators.maxLength(1024),
                    forms_1.Validators.pattern(/^[^\s]+(\s+[^\s]+)*$/)
                ]
            ],
            source: ['']
        });
    }
    ngOnInit() {
        super.ngOnInit();
        this.form.patchValue({ name: this.name, description: this.description, source: 'ms_teams' });
    }
}
exports.ImSyncTaskDetailsComponent = ImSyncTaskDetailsComponent;
