<div class="export-detail-panel">
  <div class="panel-heading header">
    {{'$I18N_REMEDIATION_EXPORT_DETAIL_PANEL.HEADER' | translate}}
    <i class="far fa-times pull-right close-panel" (click)="onClose()"></i>
  </div>
  <div class="btn-container">
    <mc-live-button
      overrideClasses="btn-primary"
      [label]="'$I18N_REMEDIATION_EXPORT_DETAIL_PANEL.BUTTONS.DOWNLOAD' | translate"
      [mcDisabled]="(isLoading$ | async) || !(canExport$ | async)"
      [isLoading]="isLoading$ | async"
      (mcClick)="onDownload()">
    </mc-live-button>
    <button class="btn btn-secondary" (click)="onClose()">
      {{'$I18N_REMEDIATION_EXPORT_DETAIL_PANEL.BUTTONS.CANCEL' | translate}}
    </button>
  </div>
  <div class="panel-body">
    <form [formGroup]="form" class="export-detail-form">
      <div class="mc-detailed-list">
        <div class="row mc-detailed-list-row form-group">
          <div class="col-sm-4 mc-detailed-list-label">
            {{'$I18N_REMEDIATION_EXPORT_DETAIL_PANEL.LABELS.COLUMNS' | translate}}
          </div>
          <div class="col-sm-8 export-form-columns mc-detailed-list-column">
            <mc-checkbox
              class="mc-checkbox"
              *ngFor="let column of (exportConfig$ | async).columnsToExport"
              [label]="column.label"
              [formControlName]="column.key"></mc-checkbox>
          </div>
        </div>
        <div class="row mc-detailed-list-row form-group">
          <div class="col-sm-4 mc-detailed-list-label">
            {{'$I18N_REMEDIATION_EXPORT_DETAIL_PANEL.LABELS.FORMAT' | translate}}
          </div>
          <div class="col-sm-8 export-form-format mc-detailed-list-column">
            <mc-select
              [options]="formatOptions"
              [defaultValue]="form.get('format').value"
              (selectChange)="onSelectChange($event)">
            </mc-select>
          </div>
        </div>
      </div>
    </form>
  </div>

</div>




