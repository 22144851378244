"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const attachmentprotection_policy_list_container_component_1 = require("./containers/list/attachmentprotection-policy-list-container.component");
const create_policy_page_component_1 = require("./containers/create-ap-policy/create-policy-page.component");
angular
    .module('attachmentProtectionPolicy', [])
    .directive('mcAttachmentPolicyList', static_1.downgradeComponent({
    component: attachmentprotection_policy_list_container_component_1.ApPolicyListContainerComponent
}))
    .directive('mcApCreatePolicy', static_1.downgradeComponent({
    component: create_policy_page_component_1.CreatePolicyComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        const wizardBreadcrumbs = [];
        $stateProvider
            .state('attachment-protection-policy-list', {
            url: '/ttp-attachment-policy/policy-list',
            data: {
                capabilities: 'Permission.POLICIES_READ',
                isFixedLayout: false,
                breadcrumbs: [
                    {
                        label: '$I18N_MEGA_MENU_GROUP_ATTACHMENT_PROTECTION_CONFIGURE_SERVICES'
                    },
                    {
                        label: '$I18N_MEGA_MENU_GROUP_ATTACHMENT_PROTECTION_CONFIGURE_ATTACHMENT_PROTECT'
                    },
                    {
                        label: '$I18N_MEGA_MENU_GROUP_ATTACHMENT_PROTECTION_CONFIGURE_POLICIES'
                    }
                ],
                checkProgress: false,
                tabId: 'Attachment Protect Policy',
                tabTitle: '$I18N_MEGA_MENU_SERVICES_TTP_ATTACHMENT_PROTECTION_POLICIES'
            },
            views: {
                main: {
                    template: '<mc-attachment-policy-list></mc-attachment-policy-list>'
                }
            }
        })
            .state('attachment-protection-policy', {
            params: {
                isEditFlow: true
            },
            url: '/ttp-attachment-policy/policy',
            data: {
                capabilities: 'Permission.POLICIES_READ',
                isFixedLayout: false,
                breadcrumbs: wizardBreadcrumbs,
                checkProgress: false,
                tabId: 'Attachment Protect Policy',
                tabTitle: '$I18N_MEGA_MENU_GROUP_ATTACHMENT_PROTECTION_TAB_LABEL'
            },
            views: {
                main: {
                    template: '<mc-ap-create-policy></mc-ap-create-policy>'
                }
            },
            onEnter: [
                '$stateParams',
                function ($stateParams) {
                    wizardBreadcrumbs.length = 0;
                    wizardBreadcrumbs.push('$I18N_MEGA_MENU_GROUP_ATTACHMENT_PROTECTION_CONFIGURE_SERVICES');
                    wizardBreadcrumbs.push({
                        label: '$I18N_MEGA_MENU_GROUP_ATTACHMENT_PROTECTION_CONFIGURE_ATTACHMENT_PROTECT'
                    });
                    wizardBreadcrumbs.push({
                        label: '$I18N_MEGA_MENU_GROUP_ATTACHMENT_PROTECTION_CONFIGURE_POLICIES'
                    });
                    wizardBreadcrumbs.push({
                        label: !$stateParams.isEditFlow
                            ? '$I18N_AP_CREATE_POLICY_ADD_POLICY'
                            : '$I18N_AP_EDIT_POLICY_ADD_POLICY'
                    });
                }
            ]
        });
    }
]);
