<mc-layout-aside-extra-pagination-container
  keyTitle="$18N_MEP_POLICY_LIST.SIDEBAR.POLICY_DETAILS"
  showClose="true"
  [hasNextInView]="hasNextInView$ | async"
  [hasPreviousInView]="hasPreviousInView$ | async"
  [indexInView]="indexInView$ | async"
  [currenPagination]="currentPagination$ | async"
  [filtersMetaData]="filtersMetaData$ | async"
  [isLoading]="false"
  (goToNextPage)="getNext()"
  (goToPrevPage)="getPrev()"
  (closeAside)="close.emit()"
>
  <header row-actions>
    <mc-mep-sidebar-header [policy]="policy"></mc-mep-sidebar-header>
  </header>

  <mc-body-container>

    <ng-container *ngIf="hasMepPolicyEditPermission$ | async">
      <section
        data-test="policy-edit-section"
        class="mc-container-buttons mc-container-actions-area"
      >
        <button
          class="btn btn-primary panel-half-margin-right"
          (click)="onEditClick(policy)"
        >
          {{ '$I18N_COMMON_BTN_EDIT' | translate }}
        </button>
        <button
          class="btn btn-secondary panel-half-margin-right"
          (click)="onDeleteClick(policy)"
          *ngIf="!isDefault()"
        >
          {{ '$I18N_COMMON_BTN_DELETE' | translate }}
        </button>

        <div
          *ngIf="!loadingForEdit &&!apiSavingStatus"
          class="mc-container-switch"
        >
          <mc-mep-sidebar-enable-switch
            [status]="policy.enabled"
            (policyStatusChange)="policyStatusChangeClick($event)"
          ></mc-mep-sidebar-enable-switch>
        </div>
      </section>
      <hr>
    </ng-container>

    <mc-mep-summary
      [policy]="policy"
      *ngIf="!(loadingForEdit || apiSavingStatus)"
    ></mc-mep-summary>
    <div
      *ngIf="loadingForEdit || apiSavingStatus"
      class="mc-container-switch"
    >
      <mc-spinner></mc-spinner>
    </div>
  </mc-body-container>
</mc-layout-aside-extra-pagination-container>
