<div class="applies-to-panel mc-identity-policy-steps-container">
    <mc-wizard-step-header class="mc-identity-wizard-step-header"
                           label="$I18N_IDENTITY_CREATE_POLICY_APPLIES_TO_STEP.TITLE">
        {{'$I18N_IDENTITY_CREATE_POLICY_APPLIES_TO_STEP.DESCRIPTION' | translate}}
    </mc-wizard-step-header>

    <form class="form-horizontal"
          [formGroup]="policyAppliesToForm">
        <div class="form-group">
            <div class="radio no-margin-top no-margin-bottom panel-padding-bottom">
                <label class="radio-label">
                    <input type="radio"
                           name="appliesToType"
                           formControlName="appliesToType"
                           value="everyone">
                    {{'$I18N_IDENTITY_CREATE_POLICY_APPLIES_TO_STEP.ELEMENTS.EVERYONE.LABEL' | translate}}
                    <div class="mc-group-selector-desc">
                        {{'$I18N_IDENTITY_CREATE_POLICY_APPLIES_TO_STEP.ELEMENTS.EVERYONE.DESCRIPTION' | translate}}
                    </div>
                </label>
            </div>

            <div class="radio">
                <label class="radio-label">
                    <input type="radio"
                           name="appliesToType"
                           formControlName="appliesToType"
                           value="groups">
                    {{'$I18N_IDENTITY_CREATE_POLICY_APPLIES_TO_STEP.ELEMENTS.GROUPS.LABEL' | translate}}
                    <div class="mc-group-selector-desc">
                        {{'$I18N_IDENTITY_CREATE_POLICY_APPLIES_TO_STEP.ELEMENTS.GROUPS.DESCRIPTION' | translate}}
                    </div>

                    <div *ngIf="policyAppliesToForm.value.appliesToType === 'groups'">
                      <div class="selected-group"
                           *ngFor="let group of groupsList$ | async">
                           <span class="groups-list">
                             <div class="groups-name">
                               {{ group.description | translate }}
                             </div>
                             <i class="far fa-trash-alt"
                                aria-hidden="true"
                                (click)="onDeleteGroup(group)"
                             ></i>
                           </span>
                           <hr class="groups-separator"/>
                      </div>

                      <button type="submit"
                              id="mc-select-groups"
                              class="btn btn-default btn-select-groups"
                              (click)="openSidebar()">{{ selectGroupsLabel$ | async }}</button>
                    </div>
                </label>
            </div>
        </div>
    </form>
</div>
