"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgDevicesListViewComponent = void 0;
const table_store_1 = require("@mimecast-ui/table-store");
const device_list_view_static_values_1 = require("./device-list-view.static-values");
const device_groups_models_1 = require("../../../models/device-groups.models");
class SwgDevicesListViewComponent extends table_store_1.TableBaseComponent {
    constructor(store) {
        super(store, 'SwgDeviceListViewTable');
        this.store = store;
        this.columnsAlwaysVisible = device_list_view_static_values_1.columnsAlwaysVisible;
        this.columns = device_list_view_static_values_1.columns;
        this.columnsToStartHidden = device_list_view_static_values_1.columnsToStartHidden;
        this.tableFilterValues = device_list_view_static_values_1.tableFilters;
        this.deviceListDisplayType = device_groups_models_1.DeviceListDisplayType;
        this.displayType = this.deviceListDisplayType.READ;
        this.tableFilters = { filterBy: [] };
        this.tableSearch = { searchBy: [] };
        this.tableLoaded = false;
    }
    ngOnInit() {
        if (!this.tableLoaded) {
            super.ngOnInit();
        }
    }
    ngOnChanges(changes) {
        if (changes && changes['deviceGroup'] && changes['deviceGroup'].currentValue) {
            this.tableLoaded = true;
            this.loadPayload = {
                groupId: changes['deviceGroup'].currentValue.id
            };
            this.resetView();
        }
    }
    resetView() {
        this.tableSearch = { searchBy: [] };
        this.tableFilters = { filterBy: [] };
        this.currentPagination = table_store_1.defaultPaginationOption;
        this.mcFiltersComponent.revertPageNavigationChanges();
        this.mcFiltersComponent.revertPageSizeChanges();
        this.deselectAllFilters();
        if (this.tableSearchFilterComponent.searchForm) {
            this.tableSearchFilterComponent.onClearClicked();
        }
        else {
            this.runFilterSearch();
        }
    }
    deselectAllFilters() {
        this.tableFilterComponent.resetFilterSections(this.tableFilterValues);
    }
    onSearch(filters) {
        this.tableSearch.searchBy = this.tableSearch.searchBy.filter(f => f.fieldName !== 'deviceName');
        this.tableSearch.searchBy = [
            ...this.tableSearch.searchBy,
            { fieldName: 'deviceName', value: filters.search }
        ];
        this.runFilterSearch();
    }
    onSearchClear() {
        this.tableSearch.searchBy = this.tableSearch.searchBy.filter(f => f.fieldName !== 'deviceName');
        this.runFilterSearch();
    }
    runFilterSearch() {
        const search = this.tableSearch.searchBy.length ? this.tableSearch : undefined;
        const filters = {
            filterBy: [
                ...this.tableFilters.filterBy,
                {
                    fieldName: 'ingroup',
                    value: true
                }
            ]
        };
        this.onFilterChange(Object.assign(Object.assign(Object.assign({}, this.loadPayload), search), filters));
    }
    onBundleFilterChange(filters) {
        this.tableFilters = { filterBy: [] };
        this.tableSearch.searchBy = this.tableSearch.searchBy.filter(f => f.fieldName !== 'os');
        if (filters.status) {
            this.tableFilters.filterBy = filters.status.map((val) => ({
                fieldName: 'status',
                value: val
            }));
        }
        if (filters.os) {
            filters.os.forEach((os) => {
                this.tableSearch.searchBy = [...this.tableSearch.searchBy, { fieldName: 'os', value: os }];
            });
        }
        this.runFilterSearch();
    }
}
exports.SwgDevicesListViewComponent = SwgDevicesListViewComponent;
