"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildTestInfo = exports.testInfo = exports.integration = exports.reducer = exports.initialState = void 0;
const actions = require("../actions");
const directory_sync_integration_interface_1 = require("../models/directory-sync-integration.interface");
const test_connection_interface_1 = require("../models/test-connection.interface");
exports.initialState = {
    integration: {},
    testInfo: {}
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.SAVE_INTEGRATION_DETAILS:
            let data = Object.assign(Object.assign({}, state), { integration: Object.assign(Object.assign({}, state.integration), action.payload) });
            // if summary step, build test-connection payload
            if (action.step === 4) {
                data = Object.assign(Object.assign({}, data), { testInfo: buildTestInfo(action) });
            }
            return data;
        case actions.CANCEL_CREATE_DIRECTORY_INTEGRATION_WIZARD:
            return exports.initialState;
        default:
            return state;
    }
}
exports.reducer = reducer;
const integration = (state) => state.integration;
exports.integration = integration;
const testInfo = (state) => state.testInfo;
exports.testInfo = testInfo;
function isLdapIntegration(serverType) {
    return serverType === 'active_directory' || serverType === 'domino';
}
function isAzureIntegration(serverType) {
    return serverType === 'azure_standard' || serverType === 'azure_gcc';
}
function isGsuiteIntegration(serverType) {
    return serverType === 'google_directory';
}
function buildTestInfo(action) {
    const info = {
        description: action.payload.name,
        info: action.payload.description,
        servertype: test_connection_interface_1.ServerType[action.serverType],
        domains: action.payload.emailDomainsFilter ? action.payload.domains : '',
        securerelaxed: test_connection_interface_1.EncryptionMode[action.payload.encryptionMode],
        encryptionMode: action.payload.encryptionMode || 'none',
        userEnabledSync: action.payload.acknowledgeDisabledAccounts ? 'on' : 'off',
        integrationType: action.payload.type
    };
    if (action.payload.id) {
        info.connectionId = action.payload.id;
    }
    if (isLdapIntegration(action.payload.type)) {
        info.ldaphost = action.payload.host;
        info.ldaphostalt = action.payload.alternateHost;
        info.ldapport = action.payload.port;
        info.userdn = action.payload.userDn;
        info.rootdn = action.payload.rootDn;
        if (action.payload.password !== '' && action.payload.password !== directory_sync_integration_interface_1.HiddenValues.key) {
            info.password = btoa(action.payload.password);
        }
    }
    if (isAzureIntegration(action.payload.type)) {
        info.serversubtype = test_connection_interface_1.ServerSubType[action.serverSubType];
        info.userdn = action.payload.appID;
        info.rootdn = action.payload.tenantDomain;
        if (action.payload.key !== '' && action.payload.key !== directory_sync_integration_interface_1.HiddenValues.key) {
            info.password = btoa(action.payload.key);
        }
    }
    if (isGsuiteIntegration(action.payload.type)) {
        info.userdn = action.payload.email;
        if (action.payload.json !== '' && action.payload.json !== directory_sync_integration_interface_1.HiddenValues.json) {
            info.jsonkey = btoa(action.payload.json);
        }
    }
    return info;
}
exports.buildTestInfo = buildTestInfo;
