<mc-layout-list-table
  class="mc-dns-activity-container"
  keyTitle="{{ '$18N_SWG_REPORTING.TABLE.KEY_TITLE' | translate }}"
>
  <mc-help-container>
    <mc-help-link url="https://community.mimecast.com/docs/DOC-3182"></mc-help-link>
  </mc-help-container>
  <mc-table
    tableId="swg/activity-report-table"
    [isLoading]="isLoading$ | async"
    [data]="tableData$ | async"
    [columns]="columns"
    translatePrefix="$18N_SWG_REPORTING.TABLE"
    (rowClick)="onRowClick($event)"
  >
    <button class="mc-table-actions btn btn-secondary mc-export-button" (click)="onExportClicked()">
      {{ '$18N_SWG_REPORTING.TABLE.EXPORT_RESULTS' | translate }}
    </button>

    <mc-filters (paginatorChange)="onPaginationChange($event)" [metadata]="metaData$ | async">
      <mc-filter-search
        (search)="onSearch($event)"
        (clear)="onSearchClear()"
        [options]="filterSearchOptions"
        [searchString]="searchOptionsSearchValue"
      >
      </mc-filter-search>

      <mc-filters-bundle-date-range
        (filtersChange)="onDateRangeFilterChange($event)"
        [config]="dateRangePickerConfig"
      >
      </mc-filters-bundle-date-range>

      <mc-filters-bundle-column-values
        *ngIf="!hasApplicationVisibility"
        [sections]="searchFilters"
        [columns]="filterSearchColumnCount"
        (filtersChange)="onBundleFilterChange($event)"
      >
      </mc-filters-bundle-column-values>

      <mc-filters-bundle-column-values-modal
        *ngIf="hasApplicationVisibility"
        [sections]="applicationVisibilitySearchFilters"
        (filtersChange)="onBundleFilterChange($event)"
      >
      </mc-filters-bundle-column-values-modal>
    </mc-filters>

    <mc-column key="discoveryMethod">
      <mc-body-cell *mcBodyCellDef="let row">
        <span *ngIf="row.discoveryMethod">{{ row.discoveryMethod }}</span>
        <span *ngIf="!row.discoveryMethod">-</span>
      </mc-body-cell>
    </mc-column>

    <mc-column key="category">
      <mc-body-cell *mcBodyCellDef="let row">{{
        row.category | mcSwgCategoryNameTranslate
      }}</mc-body-cell>
    </mc-column>

    <mc-column key="appCategory">
        <mc-body-cell *mcBodyCellDef="let row">{{
          row.appCategory | mcSwgCategoryNameTranslate
        }}</mc-body-cell>
      </mc-column>
    <mc-column-date key="dateTime"></mc-column-date>

    <mc-column key="username">
      <mc-body-cell *mcBodyCellDef="let row">
    <span *ngIf="showMultipleUsersInUsersColumn(row); else usualUserName">
        {{'$18N_SWG_REPORTING.MULTIPLE_USERS' | translate}}
    </span>
        <ng-template #usualUserName>
        <span>
           {{ row.username }}
        </span>
        </ng-template>
      </mc-body-cell>
    </mc-column>

    <mc-column-actions
      key="columnActions"
      [columnsToStartHidden]="columnsToStartHidden"
      [columnsAlwaysVisible]="columnsAlwaysVisible"
      mcShowColumnConfig
    >
      <mc-row-actions *mcRowActions="let row">
        <li mcRowAction="$I18N_COMMON_BTN_VIEW" (click)="onRowClick(row)"></li>
      </mc-row-actions>
    </mc-column-actions>

    <mc-empty-results keyTitle="$18N_SWG_REPORTING.TABLE.NO_DATA"></mc-empty-results>
  </mc-table>
</mc-layout-list-table>
