'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const moment = require("moment");
const _ = require("lodash");
require("./slides/search-logs-details.controller"); //services.services.backup.search-logs.details.controller
angular.module('services.services.backup.search-logs.controller', [
    'services.services.backup.search-logs.details.controller'
])
    .controller('SearchLogsController', ['$scope', '$translate', '$aside', 'windowService', 'adconCommonsService', 'apiArchiveService', function ($scope, $translate, $aside, windowService, adconCommonsService, apiArchiveService) {
        const self = this;
        let paginationActualPage = 1;
        const paginationLabelDict = {
            start: paginationActualPage,
            end: paginationActualPage
        };
        self.inIframe = windowService.inIframe();
        self.filterCommonDropdownTypes = adconCommonsService.CONSTANTS.DROPDOWN_ITEM_TYPES;
        //TODO searchContext set by default because the location of this search log page.
        self.payload = {
            data: [{
                    searchContext: apiArchiveService.CONSTANTS.SEARCH_CONTEXT.BACKUP
                }],
            meta: {
                pagination: {
                    pageSize: self.pageSize
                }
            }
        };
        self.selectedData = {};
        self.pageSize = {};
        self.searchLogs = [];
        self.nextPageResult = {};
        self.arrRowClicked = [];
        self.pageSizeOptions = angular.copy(adconCommonsService.getDropdownOptions().pageSizeOptions);
        self.dateRanges = angular.copy(adconCommonsService.getDropdownOptions().dateRanges);
        self.maxEndDate = new Date();
        self.minStartDate = (moment(self.maxEndDate).subtract(6, 'day')).toDate();
        self.paginationLabel = '';
        self.paginationNextPageResults = false;
        self.paginationPreviousPageResults = false;
        $scope.$watch(function () {
            return (self.payload);
        }, function (oldValue, newValue) {
            isPayloadUpdated(oldValue, newValue);
            self.getSearchLogs();
        }, true);
        $scope.tab.onRefresh = self.refresh = function () {
            self.searchLogs = [];
            getSearchLogs();
        };
        self.getSearchLogs = getSearchLogs;
        self.pageSizeOptionsUpdate = pageSizeOptionsUpdate;
        self.getMoreResults = getMoreResults;
        self.updateSearch = updateSearch;
        self.clearSearch = clearSearch;
        self.openDetailsAside = openDetailsAside;
        self.paginationNextOnChange = paginationNextOnChange;
        self.paginationPreviousOnChange = paginationPreviousOnChange;
        self.showEmptyContainerMessage = showEmptyContainerMessage;
        activate();
        function activate() {
            delete self.dateRanges.LAST_MONTH;
            self.payload.data[0].start = self.dateRanges[adconCommonsService.CONSTANTS.DATE_RANGES_KEYS.TODAY].dates.from;
            self.payload.data[0].end = self.dateRanges[adconCommonsService.CONSTANTS.DATE_RANGES_KEYS.TODAY].dates.to;
            self.pageSizeOptionsUpdate(adconCommonsService.CONSTANTS.PAGE_SIZES_KEYS.I50);
        }
        function getSearchLogs() {
            const payload = angular.copy(self.payload);
            const pageSize = payload.meta.pagination.pageSize;
            payload.data[0].start = moment(self.payload.data[0].start).format(adconCommonsService.CONSTANTS.API_DATE_FORMAT);
            payload.data[0].end = moment(self.payload.data[0].end).format(adconCommonsService.CONSTANTS.API_DATE_FORMAT);
            self.isDataLoading = true;
            apiArchiveService.getSearchLogs(payload).then(function (response) {
                if (!response.err) {
                    const emptyFolderConstant = 'na';
                    self.searchLogs = response.first.logs;
                    self.searchLogs.map(function (log) {
                        let searchInfo = {
                            query: '',
                            mailbox: ''
                        };
                        if (log.searchText) {
                            searchInfo = JSON.parse(log.searchText);
                        }
                        if (log.museQuery) {
                            const query = log.museQuery.match(/<text>\((.*)\)<\/text>/);
                            searchInfo.query = query ? query[1] : '';
                            const mailbox = log.museQuery.match(/<mailbox.*>(.*)<\/mailbox>/);
                            searchInfo.mailbox = mailbox ? mailbox[1] : '';
                        }
                        log.processedMuseQueryFullPath = log.searchPath === emptyFolderConstant ? '' : log.searchPath;
                        log.processedMuseQuerySearchTerm = searchInfo.query;
                        log.processedMuseQueryMailbox = searchInfo.mailbox;
                        return log;
                    });
                    if (response.meta && response.meta().pagination) {
                        paginationLabelDict.start = paginationActualPage === 1 ? paginationActualPage : (pageSize * (paginationActualPage - 1)) + 1;
                        paginationLabelDict.end = self.searchLogs.length !== pageSize ? (pageSize * (paginationActualPage - 1)) + self.searchLogs.length : pageSize * paginationActualPage;
                        self.paginationLabel = self.searchLogs.length > 0 ? $translate.instant('$I18N_ADMINISTRATION_COMMONS_PAGINATION_SEPARATOR', {
                            start: paginationLabelDict.start,
                            end: paginationLabelDict.end
                        }) : '';
                        self.paginationNextPageResults = response.meta().pagination.next;
                        self.paginationPreviousPageResults = response.meta().pagination.previous;
                    }
                }
                else {
                    apiArchiveService.getError().showErrorNotification(response);
                }
                self.isDataLoading = false;
            }, function () {
                self.isDataLoading = false;
                apiArchiveService.getError().showErrorNotification();
            });
        }
        function getMoreResults(pageToken) {
            if (!self.isDataLoading && pageToken) {
                self.payload.meta.pagination.pageToken = pageToken;
            }
        }
        function pageSizeOptionsUpdate(id) {
            self.payload.meta.pagination.pageSize = self.pageSizeOptions[id].key;
            adconCommonsService.setDropdownActiveItem(self.pageSizeOptions, id);
            self.selectedPageSizeOptions = self.pageSizeOptions[id].value;
        }
        function updateSearch() {
            self.payload.data[0].query = self.searchTerm;
        }
        function clearSearch() {
            delete self.payload.data[0].query;
        }
        function isPayloadUpdated(oldPayload, newPayload) {
            if (!(_.isEqual(oldPayload.data[0], newPayload.data[0])) || !(_.isEqual(oldPayload.meta.pagination.pageSize, newPayload.meta.pagination.pageSize))) {
                delete self.payload.meta.pagination.pageToken;
                paginationActualPage = 1;
            }
        }
        function paginationNextOnChange() {
            paginationActualPage++;
            getMoreResults(self.paginationNextPageResults);
        }
        function paginationPreviousOnChange() {
            paginationActualPage--;
            getMoreResults(self.paginationPreviousPageResults);
        }
        function openDetailsAside(searchLogItem, row) {
            self.arrRowClicked[row] = true;
            if (!self.asideInstance) {
                self.asideInstance = $aside.open({
                    templateUrl: 'services/services/backup/search-logs/slides/search-logs-details.tpl.html',
                    placement: 'right',
                    size: 'lg',
                    backdrop: true,
                    windowClass: 'hidden-xs',
                    backdropClass: 'hidden-xs',
                    controller: 'SearchLogsDetailsCtrl as slDetailsCtrl',
                    resolve: {
                        searchLogItem() {
                            return searchLogItem.searchLogs[row];
                        }
                    }
                }).result.finally(function () {
                    self.arrRowClicked = [];
                    self.asideInstance = undefined;
                });
            }
        }
        function showEmptyContainerMessage() {
            return self.payload.data[0].query ? '$I18N_ADMINISTRATION_MENU_BACKUP_SEARCH_LOGS_RESULTS_EMPTY_TITLE' : '$I18N_ADMINISTRATION_MENU_BACKUP_SEARCH_LOGS_LIST_EMPTY_TITLE';
        }
    }]);
