
    <mc-crowdstrike-integration-aside-view
      [integration]="integration"
      [crowdstrikeIntegration]="crowdstrikeIntegration$ | async"
      [enabled]="enabled$ | async"
      (onEnableClicked)="enable()"
      (onDisableClicked)="disable()"
      (onEditClicked)="edit()"
      (onCloseClicked)="close()"
      (onViewActivityLogClicked)="openCrowdstrikeActivityLogPage($event)"
    >
    </mc-crowdstrike-integration-aside-view>
  