"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pieChartConfig = exports.fileTypesDisplayNames = void 0;
const am4core = require("@amcharts/amcharts4/core");
exports.fileTypesDisplayNames = [
    {
        type: 'archive',
        label: 'Archive'
    },
    {
        type: 'exeOrScript',
        label: 'Executable/Script'
    },
    {
        type: 'image',
        label: 'Image'
    },
    {
        type: 'OfficeFile',
        label: 'Office'
    },
    {
        type: 'pdf',
        label: 'PDF'
    },
    {
        type: 'video',
        label: 'Video'
    }
];
exports.pieChartConfig = {
    maxZoomLevel: 1,
    depth: 10,
    radius: '85%',
    innerRadius: am4core.percent(60),
    legend: {
        position: 'right',
        scrollable: true,
        useDefaultMarker: true,
        markers: {
            width: 8,
            height: 8
        },
        itemContainers: {
            paddingTop: 10,
            paddingBottom: 10
        }
    },
    series: [
        {
            type: 'PieSeries',
            dataFields: {
                value: 'count',
                category: 'fileType'
            },
            labels: {
                disabled: true
            },
            ticks: {
                disabled: true
            },
            slices: {
                stroke: '#FFFFFF',
                strokeWidth: 0,
                strokeOpacity: 1
            },
            colors: {
                list: ['#63A4FF', '#C48F00', '#AE53D3', '#FA5887', '#56C6D6', '#FFAD42', '#6ABF69']
            }
        }
    ]
};
