"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MepAppliesToStepComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
class MepAppliesToStepComponent {
    constructor(fb) {
        this.fb = fb;
        this.updateAffectedUsers = new core_1.EventEmitter();
        this.everyOneAffectedUsers = new core_1.EventEmitter();
        this.removeAffectedUsers = new core_1.EventEmitter();
        this.openAppliesToSidePanel = new core_1.EventEmitter();
        this.updatePolicyOverrideStatus = new core_1.EventEmitter();
        this.clearAffectedUsersOtherThan = new core_1.EventEmitter();
        this.pageValid = new core_1.EventEmitter();
    }
    ngOnInit() {
        this.affectedUsersPage = this.fb.group({
            condition: [this.everyone ? 'everyone' : 'multi', forms_1.Validators.required]
        });
    }
    setAllAppliesToSelection(all) {
        this.everyOneAffectedUsers.emit(all);
    }
    removeAppliesTo(payload) {
        this.removeAffectedUsers.emit(payload);
    }
    openSidebar(value) {
        this.openAppliesToSidePanel.emit(value);
    }
    ngOnDestroy() {
        if (this.formSubscription) {
            this.formSubscription.unsubscribe();
        }
    }
}
exports.MepAppliesToStepComponent = MepAppliesToStepComponent;
