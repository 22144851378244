<div *ngIf="tokenResults as email" class="container-fluid panel-padding-bottom">

  <!-- search by token: Download Token Dropdown -->
  <form [formGroup]="formTokenToDownload" #xmlContainer>
    <mc-field label="$I18N_ARCHIVE_VIEWER_SEARCH_TAB_TOKEN_DOWNLOAD" class="col-xs-3 col-xs-offset-0"
              data-test="search_by_token_dropdown">
    </mc-field>

    <div class="col-sm-12 panel-padding-bottom no-padding-horizontal">
      <mc-live-button *ngIf="email.size"
                      typeClass="btn btn-primary"
                      type="submit"
                      content="{{'$I18N_ARCHIVE_VIEWER_SEARCH_TAB_TOKEN_DOWNLOAD' | translate}} / size = {{ email.size | mcToBytes}}"
                      (mcClick)="downloadXmlComponent($event,'DOWNLOAD')"
                      data-test="search_by_content_btn">
      </mc-live-button>

      <mc-live-button *ngIf="!email.size"
                      typeClass="btn btn-primary"
                      type="submit"
                      content="{{'$I18N_ARCHIVE_VIEWER_SEARCH_TAB_TOKEN_DOWNLOAD_ALL' | translate}}"
                      (mcClick)="downloadXmlComponent($event,'DOWNLOAD_ALL')"
                      data-test="search_by_content_btn">
      </mc-live-button>
    </div>
  </form>

  <div *ngIf="!email.size" class="col-sm-12 mc-tree-view">
      <!-- search by token -->
      <form [formGroup]="archivePartial">
        <mc-field>
          <select class="form-control" name="partialTokens" formControlName="partialTokens">
            <optgroup label="Token">
              <option *ngFor="let item of email.deliveryComponents; let i = index">
                {{ email.deliveryComponents[i].token }}
              </option>
            </optgroup>
            <optgroup label="Attachments">
              <option *ngFor="let item of email.messageComponents; let n = index">
                {{ email.messageComponents[n].token }}
              </option>
            </optgroup>
          </select>
        </mc-field>
      </form>

      <textarea class="mc-output-holder" rows="20">{{ email.content }}</textarea>
  </div>

</div>
