<div class="mc-services-connector-wizard-step-container">
  <mc-wizard-step-header label="$I18N_CREATE_CONNECTOR_WIZARD_GWS_DOMAIN_DELEGATION_STEP.TITLE">
    <div>
      {{ '$I18N_CREATE_CONNECTOR_WIZARD_GWS_DOMAIN_DELEGATION_STEP.CONTENT.AUTHORIZE_ACCOUNT_PART_1' | translate }}
      <a target="_blank" href="{{ googleDomainDelegationPageLink }}" rel="noopener noreferrer">
        {{ '$I18N_CREATE_CONNECTOR_WIZARD_GWS_INSTRUCTION_STEP.LABEL.DOMAIN_WIDE_DELEGATION' | translate }}
      </a>
      {{ '$I18N_CREATE_CONNECTOR_WIZARD_GWS_DOMAIN_DELEGATION_STEP.CONTENT.AUTHORIZE_ACCOUNT_PART_2' | translate }}
    </div>
  </mc-wizard-step-header>

  <div class="mc-detailed-list" *ngIf="!(isValidatingConsent$ | async); else spinner">
    <ng-container *ngIf="!(isLoadingConsentConfig$ | async); else spinner">
      <div *ngIf="isConsentValidationFailed$ | async">
        <mc-inline-notification [notification]="{ status: 'error', boxed: true, msg: getConsentValidationMessage() }">
        </mc-inline-notification>
      </div>
      <div *ngIf="consentConfig" class="mc-none-selectable">
        <form novalidate [formGroup]="form" class="form-horizontal" mcDefaultLabelClass="col-sm-3"
              mcDefaultControlContainerClass="col-sm-9">
          <mc-field label="$I18N_CREATE_CONNECTOR_WIZARD_GWS_DOMAIN_DELEGATION_STEP.LABEL.CLIENT_ID"
                    fieldId="service-account-client-id">
            <input type="text" id="service-account-client-id" formControlName="serviceAccountClientId"
                     class="form-control mc-client-id" readonly/>
            <div class="mc-pt-5">
              <button class="btn btn-primary" ngxClipboard [cbContent]="form.get('serviceAccountClientId').value">
                <em class="fas fa-copy"></em>
                {{ '$I18N_CREATE_CONNECTOR_WIZARD_GWS_DOMAIN_DELEGATION_STEP.LABEL.COPY_CLIENT_ID' | translate }}
              </button>
            </div>
          </mc-field>
          <mc-field label="$I18N_CREATE_CONNECTOR_WIZARD_GWS_DOMAIN_DELEGATION_STEP.LABEL.OAUTH_SCOPES"
                    fieldId="oauth-scopes">
            <select multiple class="form-control mc-scopes" formControlName="scopes" id="oauth-scopes" readonly>
              <option *ngFor="let scope of getPermissions()" [value]="scope">{{ scope }}</option>
            </select>
            <div class="mc-pt-5">
              <button class="btn btn-primary" ngxClipboard [cbContent]="getScopeCopyData()">
                <em class="fas fa-copy"></em>
                {{ '$I18N_CREATE_CONNECTOR_WIZARD_GWS_DOMAIN_DELEGATION_STEP.LABEL.COPY_SCOPES' | translate }}
              </button>
            </div>
          </mc-field>
        </form>
      </div>
    </ng-container>
  </div>
  <ng-template #spinner>
    <div class="spinner-wrapper mc-pt-60">
      <mc-spinner></mc-spinner>
    </div>
  </ng-template>
</div>
