"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewMessageComponent = void 0;
const core_1 = require("@angular/core");
class ViewMessageComponent {
    constructor() {
        this.loading = false;
        this.onLoadDetails = new core_1.EventEmitter();
        this.onClose = new core_1.EventEmitter();
        this.onRecall = new core_1.EventEmitter();
    }
    close() {
        this.onClose.emit();
    }
    loadMessageDetails(event) {
        const message = event.token;
        this.onLoadDetails.emit(message);
    }
}
exports.ViewMessageComponent = ViewMessageComponent;
