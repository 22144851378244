<mc-tabs-nav
  *ngIf="windowService.inIframe()"
  [tabs]="tabs"
  class="tabs-container"
  header="$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ASIDE_MENU_TITLE">
</mc-tabs-nav>


<div #content [style.max-height]="getMaxHeight(content)" class="content-container">
  <ng-content></ng-content>
</div>
