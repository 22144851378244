"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SenderMailboxFallbackActionOptions = exports.GatewayFallbackActionOptions = exports.GatewayActionOptions = exports.SenderMailboxActionOptions = void 0;
const models_1 = require("../../../models");
exports.SenderMailboxActionOptions = [
    {
        value: models_1.SenderMailboxActionOption.NONE,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_OUTBOUND_SETTINGS_STEP_LABEL.SENDER_MAILBOX_ACTION.OPTIONS.NONE'
    },
    {
        value: models_1.SenderMailboxActionOption.REMOVEMESSAGE,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_OUTBOUND_SETTINGS_STEP_LABEL.SENDER_MAILBOX_ACTION.OPTIONS.REMOVE_MESSAGE'
    },
    {
        value: models_1.SenderMailboxActionOption.REMOVEATTACHMENT,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_OUTBOUND_SETTINGS_STEP_LABEL.SENDER_MAILBOX_ACTION.OPTIONS.REMOVE_ATTACHMENT'
    }
];
exports.GatewayActionOptions = [
    {
        value: models_1.GatewayActionOption.HOLD,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_OUTBOUND_SETTINGS_STEP_LABEL.GATEWAY_ACTIONS.OPTIONS.HOLD_FOR_REVIEW'
    },
    {
        value: models_1.GatewayActionOption.BOUNCE,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_OUTBOUND_SETTINGS_STEP_LABEL.GATEWAY_ACTIONS.OPTIONS.BOUNCE'
    },
    {
        value: models_1.GatewayActionOption.NONE,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_OUTBOUND_SETTINGS_STEP_LABEL.GATEWAY_ACTIONS.OPTIONS.NONE'
    }
];
exports.GatewayFallbackActionOptions = [
    {
        value: models_1.GatewayFallbackActionOption.HOLD,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_OUTBOUND_SETTINGS_STEP_LABEL.GATEWAY_FALLBACK_ACTIONS.OPTIONS.HOLD_FOR_REVIEW'
    },
    {
        value: models_1.GatewayFallbackActionOption.BOUNCE,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_OUTBOUND_SETTINGS_STEP_LABEL.GATEWAY_FALLBACK_ACTIONS.OPTIONS.BOUNCE'
    },
    {
        value: models_1.GatewayFallbackActionOption.NONE,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_OUTBOUND_SETTINGS_STEP_LABEL.GATEWAY_FALLBACK_ACTIONS.OPTIONS.NONE'
    }
];
exports.SenderMailboxFallbackActionOptions = [
    {
        value: models_1.SenderMailboxFallbackActionOption.NONE,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_OUTBOUND_SETTINGS_STEP_LABEL.SENDER_MAILBOX_FALLBACK_ACTION.OPTIONS.NONE'
    },
    {
        value: models_1.SenderMailboxFallbackActionOption.REMOVEMESSAGE,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_OUTBOUND_SETTINGS_STEP_LABEL.SENDER_MAILBOX_FALLBACK_ACTION.OPTIONS.REMOVE_MESSAGE'
    },
    {
        value: models_1.SenderMailboxFallbackActionOption.REMOVEATTACHMENT,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_OUTBOUND_SETTINGS_STEP_LABEL.SENDER_MAILBOX_FALLBACK_ACTION.OPTIONS.REMOVE_ATTACHMENT'
    }
];
