"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectedRow = exports.canUpdateList = exports.hasGenericError = exports.isTableLoading = exports.getRejected = exports.getDeferred = exports.reducer = exports.initialState = void 0;
const listActions = require("../actions/list.action");
const commonActions = require("../../common/actions");
exports.initialState = {
    rejected: [],
    deferred: [],
    isLoading: false,
    genericError: false,
    updateList: false,
    selectedRow: undefined
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case listActions.GET_REJECTED_INFO:
            return Object.assign(Object.assign({}, state), { selectedRow: action.row });
        case listActions.GET_DEFERRED_INFO:
            return Object.assign(Object.assign({}, state), { selectedRow: action.row });
        case commonActions.HIGHLIGHT_SELECTED_ROW:
            return Object.assign(Object.assign({}, state), { selectedRow: action.row });
        case commonActions.CLEAR_SELECTED_ROW:
            return Object.assign(Object.assign({}, state), { selectedRow: undefined });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getDeferred = (state) => state.deferred;
exports.getDeferred = getDeferred;
const getRejected = (state) => state.rejected;
exports.getRejected = getRejected;
const isTableLoading = (state) => state.isLoading;
exports.isTableLoading = isTableLoading;
const hasGenericError = (state) => state.genericError;
exports.hasGenericError = hasGenericError;
const canUpdateList = (state) => state.updateList;
exports.canUpdateList = canUpdateList;
const selectedRow = (state) => state.selectedRow;
exports.selectedRow = selectedRow;
