<mc-table [showHighlightedRow]="false" [data]="contents" [columns]="columns" [isLoading]="loadingContents" translatePrefix="$I18N_ONEDRIVE_DRIVE_BROWSER"
  (rowClick)="onNodeClicked.emit($event)">

  <mc-filters [showPagination]="false">
    <div class="mc-onedrive-view-filter-container">
      <mc-tree-breadcrumbs [nodeStack]="nodeStack" [rootFolder]="rootFolder" [rootFolderName]="'$I18N_ONEDRIVE_TREE_COMPONENT.ROOT_FOLDER_TITLE' | translate"
        (onFolderClicked)="onNodeClicked.emit($event)"></mc-tree-breadcrumbs>
      <div class="mc-onedrive-view-filter-search">
        <mc-filters-bundle-column-values-search-only placeholder="$I18N_ONEDRIVE_DRIVE_BROWSER.FILTERS.SEARCH_FIELD.PLACEHOLDER"
          (filtersChange)="onFilterChange()"></mc-filters-bundle-column-values-search-only>
      </div>
    </div>
  </mc-filters>

  <mc-column key="name">
    <mc-body-cell *mcBodyCellDef="let row">
      <i data-test='tree-row-icon' class="far" [ngClass]="{
        'fa-folder':  nodeIsFolder(row),
        'fa-file': nodeIsFile(row)
      }"></i>
      {{row.name}}
      <span *ngIf="showDeletedBadge(row)" class="label label-default">Deleted</span>
    </mc-body-cell>
  </mc-column>

  <mc-column-size key="size"></mc-column-size>

  <mc-column-date key="modified"></mc-column-date>

  <mc-column-actions key="options">
    <mc-row-actions *mcRowActions="let row" [ngClass]="{'mc-onedrive-row-actions-empty': !fileHasContentViewPermission(row)}">
      <li *ngIf="fileHasContentViewPermission(row)" mcRowAction="$I18N_ONEDRIVE_TREE_COMPONENT.ROW_ACTIONS.VIEW_DETAILS" data-test='view-details'></li>
      <li *ngIf="fileHasContentViewPermission(row)" mcRowAction="$I18N_ONEDRIVE_TREE_COMPONENT.ROW_ACTIONS.DOWNLOAD" data-test='download'></li>
    </mc-row-actions>
  </mc-column-actions>

  <mc-empty-results iconClass="far fa-hdd" keyTitle="$I18N_ONEDRIVE_TREE_COMPONENT.EMPTY_SEARCH.TITLE">
  </mc-empty-results>

</mc-table>
