"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgActivityReportGetApplicationsFailureAction = exports.SwgActivityReportGetApplicationsSuccessAction = exports.SwgActivityReportGetApplicationsAction = exports.SwgActivityReportGetCategoriesFailureAction = exports.SwgActivityReportGetCategoriesSuccessAction = exports.SwgActivityReportGetCategoriesAction = exports.SwgActivityReportActions = void 0;
var SwgActivityReportActions;
(function (SwgActivityReportActions) {
    SwgActivityReportActions["SWG_ACTIVITY_REPORT_GET_CATEGORIES_ACTION"] = "[SwgActivityReport] Get Categories";
    SwgActivityReportActions["SWG_ACTIVITY_REPORT_GET_CATEGORIES_SUCCESS_ACTION"] = "[SwgActivityReport] Get Categories Success";
    SwgActivityReportActions["SWG_ACTIVITY_REPORT_GET_CATEGORIES_FAILURE_ACTION"] = "[SwgActivityReport] Get Categories Failure";
    SwgActivityReportActions["SWG_ACTIVITY_REPORT_GET_APPLICATIONS_ACTION"] = "[SwgActivityReport] Get Applications";
    SwgActivityReportActions["SWG_ACTIVITY_REPORT_GET_APPLICATIONS_SUCCESS_ACTION"] = "[SwgActivityReport] Get Applications Success";
    SwgActivityReportActions["SWG_ACTIVITY_REPORT_GET_APPLICATIONS_FAILURE_ACTION"] = "[SwgActivityReport] Get Applications Failure";
})(SwgActivityReportActions = exports.SwgActivityReportActions || (exports.SwgActivityReportActions = {}));
class SwgActivityReportGetCategoriesAction {
    constructor() {
        this.type = SwgActivityReportActions.SWG_ACTIVITY_REPORT_GET_CATEGORIES_ACTION;
    }
}
exports.SwgActivityReportGetCategoriesAction = SwgActivityReportGetCategoriesAction;
class SwgActivityReportGetCategoriesSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgActivityReportActions.SWG_ACTIVITY_REPORT_GET_CATEGORIES_SUCCESS_ACTION;
    }
}
exports.SwgActivityReportGetCategoriesSuccessAction = SwgActivityReportGetCategoriesSuccessAction;
class SwgActivityReportGetCategoriesFailureAction {
    constructor() {
        this.type = SwgActivityReportActions.SWG_ACTIVITY_REPORT_GET_CATEGORIES_FAILURE_ACTION;
    }
}
exports.SwgActivityReportGetCategoriesFailureAction = SwgActivityReportGetCategoriesFailureAction;
class SwgActivityReportGetApplicationsAction {
    constructor() {
        this.type = SwgActivityReportActions.SWG_ACTIVITY_REPORT_GET_APPLICATIONS_ACTION;
    }
}
exports.SwgActivityReportGetApplicationsAction = SwgActivityReportGetApplicationsAction;
class SwgActivityReportGetApplicationsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgActivityReportActions.SWG_ACTIVITY_REPORT_GET_APPLICATIONS_SUCCESS_ACTION;
    }
}
exports.SwgActivityReportGetApplicationsSuccessAction = SwgActivityReportGetApplicationsSuccessAction;
class SwgActivityReportGetApplicationsFailureAction {
    constructor() {
        this.type = SwgActivityReportActions.SWG_ACTIVITY_REPORT_GET_APPLICATIONS_FAILURE_ACTION;
    }
}
exports.SwgActivityReportGetApplicationsFailureAction = SwgActivityReportGetApplicationsFailureAction;
