"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DirectorySyncSummaryStepComponent = void 0;
const core_1 = require("@angular/core");
const operators_1 = require("rxjs/operators");
class DirectorySyncSummaryStepComponent {
    constructor(fb) {
        this.fb = fb;
        this.delay = 200;
        this.mseSites = [];
        this.onIntegrationStatusUpdated = new core_1.EventEmitter();
    }
    ngOnInit() {
        this.form = this.fb.group({
            status: this.getStatusValue()
        });
        this.update();
        this.formSubscription = this.form.valueChanges.pipe(operators_1.debounceTime(this.delay)).subscribe(() => {
            this.update();
        });
    }
    integrationType() {
        return this.integration.type === 'active_directory'
            ? 'On-Premises Active Directory (LDAP)'
            : this.integration.type === 'azure_standard'
                ? 'Microsoft Azure - Standard'
                : this.integration.type === 'azure_gcc'
                    ? 'Microsoft Azure - GCC HIGH'
                    : this.integration.type === 'google_directory'
                        ? 'Google Directory'
                        : this.integration.type === 'ad_push'
                            ? 'On-Premises Active Directory (Synchronization Engine)'
                            : 'Domino Directory (LDAP)';
    }
    isLdapIntegration() {
        return this.integration.type === 'active_directory' || this.integration.type === 'domino';
    }
    isLdapDominoIntegration() {
        return this.integration.type === 'domino';
    }
    isAzureIntegration() {
        return this.integration.type === 'azure_standard' || this.integration.type === 'azure_gcc';
    }
    isGsuiteIntegration() {
        return this.integration.type === 'google_directory';
    }
    isMSEIntegration() {
        return this.integration.type === 'ad_push';
    }
    isReplicateDiffDomain() {
        return !!(this.integration.host && this.integration.userDn && this.integration.rootDn);
    }
    update() {
        this.onIntegrationStatusUpdated.emit(this.form.getRawValue());
    }
    getStatusValue() {
        // update to nullish coalesce: this.integration.status ?? true;
        return this.integration.status !== null && this.integration.status !== void 0
            ? this.integration.status
            : true;
    }
    get encryptionModeOptions() {
        return this.integration.encryptionConn ? this.integration.encryptionMode : 'none';
    }
    get mseSiteDescription() {
        const site = this.mseSites.find(s => s.id === this.integration.mseSiteId);
        return (site && site.description) || '-';
    }
    get connectorName() {
        const newConnectorName = this.integration.connectorName;
        const existingConnector = this.connectors.find(connector => connector.id === this.integration.connector);
        return newConnectorName || (existingConnector && existingConnector.name);
    }
    ngOnDestroy() {
        this.formSubscription.unsubscribe();
    }
}
exports.DirectorySyncSummaryStepComponent = DirectorySyncSummaryStepComponent;
