"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const swg_dns_component_1 = require("./swg-dns.component");
angular
    .module('swgDnsGateway', [])
    .directive('mcSwgDns', static_1.downgradeComponent({
    component: swg_dns_component_1.SwgDnsComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider.state('secure-web-gateway-dns', {
            url: '/secure-web-gateway-dns',
            data: {
                capabilities: 'Permission.SWG_CERTIFICATE_DNS_SETUP_READ',
                isFixedLayout: false,
                checkProgress: false,
                tabTitle: 'DNS Setup'
            },
            views: {
                main: {
                    template: '<mc-swg-dns></mc-swg-dns>'
                }
            }
        });
    }
]);
