"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PolicyListComponent = void 0;
const table_store_1 = require("@mimecast-ui/table-store");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const policiesActions = require("../../actions/policies.actions");
const policies_actions_1 = require("../../actions/policies.actions");
const reducers_1 = require("../../reducers");
class PolicyListComponent extends table_store_1.TableBaseComponent {
    constructor(translateService, store) {
        super(store, 'IdentityPolicyList');
        this.translateService = translateService;
        this.store = store;
        this.initialLoadingComplete$ = new rxjs_1.BehaviorSubject(false);
        this.columnList = ['name', 'appliedTo', 'lastUpdated', 'policyActions'];
        this.selectedPolicy$ = this.store.select(reducers_1.getSelectedPolicy);
        this.isLoading$.pipe(operators_1.first(isLoading => isLoading === false)).subscribe(() => {
            this.initialLoadingComplete$.next(true);
            this.store
                .select(reducers_1.getNotificationAction)
                .pipe(operators_1.first())
                .subscribe(value => {
                if (value) {
                    this.store.dispatch(value);
                    this.store.dispatch(new policies_actions_1.IdentityClearNotificationAction());
                }
            });
        });
    }
    openPolicyDetails(policy) {
        this.store.dispatch(new policiesActions.IdentityOpenPolicyAction(policy));
    }
    createNewPolicy() {
        this.store.dispatch(new policiesActions.IdentityShowCreatePolicyWizardAction());
    }
    editPolicyClick(row) {
        this.store.dispatch(new policiesActions.IdentityShowEditPolicyWizardAction(row.id));
    }
    deletePolicyClick(row) {
        this.store.dispatch(new policiesActions.IdentityDeleteModalOpenAction(row));
    }
}
exports.PolicyListComponent = PolicyListComponent;
