"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPagingMetadata = exports.getIsLoadingForEdit = exports.getTotalCount = exports.getIsDeleting = exports.getIsTableLoading = exports.getDevicesInGroup = exports.getApiProcessingInProgress = exports.getDeviceCount = exports.getDeviceGroup = exports.getDeviceGroupWizardFlowType = exports.getGroupId = exports.getIsGroupDevicesListLoading = exports.getGroupDevicesList = exports.reducer = exports.initialState = void 0;
const device_groups_list_actions_1 = require("../actions/device-groups-list.actions");
const device_groups_models_1 = require("../models/device-groups.models");
const device_groups_sidebar_actions_1 = require("../actions/device-groups-sidebar.actions");
exports.initialState = {
    devicesList: [],
    isDevicesListLoading: false,
    groupId: '',
    deviceGroup: {
        id: '',
        name: '',
        deviceCount: '',
        description: '',
        lastUpdated: '',
        createTime: '',
        devicesToAdd: [],
        devicesToRemove: []
    },
    wizardFlowType: device_groups_models_1.DeviceGroupWizardFlowType.CREATE,
    devicesToAddMap: new Map(),
    paginatorMetadata: {
        pageToken: undefined,
        pageSize: 50,
        pageNumber: 0
    },
    devicesToAddArray: [],
    isTableLoading: false,
    apiProcessingInProgress: false,
    isDeleting: false,
    deviceCount: 0,
    devicesToRemoveMap: new Map(),
    devicesToRemoveArray: [],
    loadingForEdit: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case device_groups_list_actions_1.SwgDeviceGroupsListActions.GET_GROUP_DEVICES_LIST:
            return Object.assign(Object.assign({}, state), { isDevicesListLoading: true });
        case device_groups_list_actions_1.SwgDeviceGroupsListActions.GET_GROUP_DEVICES_LIST_SUCCESS:
            return Object.assign(Object.assign({}, state), { devicesList: action.payload, isDevicesListLoading: false });
        case device_groups_list_actions_1.SwgDeviceGroupsListActions.GET_GROUP_DEVICES_LIST_FAILURE:
            return Object.assign(Object.assign({}, state), { devicesList: [], isDevicesListLoading: false });
        case device_groups_list_actions_1.SwgDeviceGroupsListActions.SET_GROUP_ID:
            return Object.assign(Object.assign({}, state), { groupId: action.payload });
        case device_groups_list_actions_1.SwgDeviceGroupsListActions.CLEAR_GROUP_ID:
            return Object.assign(Object.assign({}, state), { groupId: '' });
        case device_groups_list_actions_1.SwgDeviceGroupsListActions.CLEAR_DEVICE_GROUP:
            return Object.assign(Object.assign({}, exports.initialState), { devicesToAddMap: new Map(), devicesToRemoveMap: new Map() });
        case device_groups_list_actions_1.SwgDeviceGroupsListActions.NAVIGATE_TO_DEVICE_GROUP_WIZARD:
            return Object.assign(Object.assign({}, state), { wizardFlowType: action.payload });
        case device_groups_list_actions_1.SwgDeviceGroupsListActions.UPDATE_DEVICE_GROUP_DETAILS:
            return Object.assign(Object.assign({}, state), { deviceGroup: Object.assign(Object.assign({}, state.deviceGroup), { name: action.payload.name, description: action.payload.description }) });
        case device_groups_list_actions_1.SwgDeviceGroupsListActions.ADD_DEVICES_IN_GROUP:
            const devicesToAdd = [...action.payload];
            const countBeforeAdd = state.devicesToAddMap.size;
            devicesToAdd.forEach(device => {
                state.devicesToAddMap.set(device.id, device);
                state.devicesToRemoveMap.delete(device.id);
            });
            const countAfterAdd = state.devicesToAddMap.size;
            const devices = Array.from(state.devicesToAddMap.values());
            const count = state.deviceCount + (countAfterAdd - countBeforeAdd);
            return Object.assign(Object.assign({}, state), { devicesToAddArray: devices, devicesToRemoveArray: Array.from(state.devicesToRemoveMap.values()), deviceCount: count });
        case device_groups_list_actions_1.SwgDeviceGroupsListActions.REMOVE_DEVICES_FROM_GROUP:
            const devicesToRemove = [...action.payload];
            devicesToRemove.forEach(device => {
                state.devicesToAddMap.delete(device.id);
                state.devicesToRemoveMap.set(device.id, device);
            });
            const deviceCount = state.deviceCount - devicesToRemove.length;
            return Object.assign(Object.assign({}, state), { devicesToAddArray: Array.from(state.devicesToAddMap.values()), devicesToRemoveArray: Array.from(state.devicesToRemoveMap.values()), deviceCount });
        case device_groups_list_actions_1.SwgDeviceGroupsListActions.FILTER_DEVICES_IN_GROUP:
            return Object.assign(Object.assign({}, state), { filterBy: [...action.payload], isTableLoading: true, paginatorMetadata: Object.assign(Object.assign({}, state.paginatorMetadata), { pageNumber: 0, pageToken: undefined }) });
        case device_groups_list_actions_1.SwgDeviceGroupsListActions.FILTER_DEVICES_IN_GROUP_COMPLETED:
        case device_groups_list_actions_1.SwgDeviceGroupsListActions.PAGE_DEVICES_IN_GROUP_COMPLETED:
            return Object.assign(Object.assign({}, state), { isTableLoading: false });
        case device_groups_list_actions_1.SwgDeviceGroupsListActions.PAGE_DEVICES_IN_GROUP:
            return Object.assign(Object.assign({}, state), { isTableLoading: true, paginatorMetadata: Object.assign(Object.assign({}, state.paginatorMetadata), action.payload) });
        case device_groups_list_actions_1.SwgDeviceGroupsListActions.CREATE_DEVICE_GROUP:
            return Object.assign(Object.assign({}, state), { apiProcessingInProgress: true, deviceGroup: Object.assign(Object.assign({}, state.deviceGroup), { devicesToAdd: Array.from(state.devicesToAddMap.keys()), devicesToRemove: Array.from(state.devicesToRemoveMap.keys()) }) });
        case device_groups_list_actions_1.SwgDeviceGroupsListActions.CREATE_DEVICE_GROUP_SUCCESS:
        case device_groups_list_actions_1.SwgDeviceGroupsListActions.CREATE_DEVICE_GROUP_FAILURE:
            return Object.assign(Object.assign({}, state), { apiProcessingInProgress: false });
        case device_groups_sidebar_actions_1.SwgDeviceGroupsSidebarActionsEnum.SWG_DEVICE_GROUP_DELETE:
            return Object.assign(Object.assign({}, state), { isDeleting: true });
        case device_groups_sidebar_actions_1.SwgDeviceGroupsSidebarActionsEnum.SWG_DEVICE_GROUP_DELETE_SUCCESS:
        case device_groups_sidebar_actions_1.SwgDeviceGroupsSidebarActionsEnum.SWG_DEVICE_GROUP_DELETE_FAILURE:
            return Object.assign(Object.assign({}, state), { isDeleting: false });
        case device_groups_sidebar_actions_1.SwgDeviceGroupsSidebarActionsEnum.SWG_DEVICE_GROUP_DELETE_CONFIRMATION_MODAL_OPEN:
            return Object.assign(Object.assign({}, state), { deviceGroup: Object.assign({}, action.payload) });
        case device_groups_sidebar_actions_1.SwgDeviceGroupsSidebarActionsEnum.SWG_DEVICE_GROUP_DELETE_CONFIRMATION_MODAL_CLOSE:
            return Object.assign({}, state);
        case device_groups_list_actions_1.SwgDeviceGroupsListActions.LOAD_DEVICE_GROUP_TO_EDIT:
            return Object.assign(Object.assign({}, state), { deviceGroup: Object.assign({}, action.payload), deviceCount: +action.payload.deviceCount, loadingForEdit: true });
        case device_groups_list_actions_1.SwgDeviceGroupsListActions.LOAD_DEVICE_GROUP_TO_EDIT_SUCCESS:
            return Object.assign(Object.assign({}, state), { loadingForEdit: false });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getGroupDevicesList = (state) => state.devicesList;
exports.getGroupDevicesList = getGroupDevicesList;
const getIsGroupDevicesListLoading = (state) => state.isDevicesListLoading;
exports.getIsGroupDevicesListLoading = getIsGroupDevicesListLoading;
const getGroupId = (state) => state.groupId;
exports.getGroupId = getGroupId;
const getDeviceGroupWizardFlowType = (state) => state.wizardFlowType;
exports.getDeviceGroupWizardFlowType = getDeviceGroupWizardFlowType;
const getDeviceGroup = (state) => state.deviceGroup;
exports.getDeviceGroup = getDeviceGroup;
const getDeviceCount = (state) => state.deviceCount;
exports.getDeviceCount = getDeviceCount;
const getApiProcessingInProgress = (state) => state.apiProcessingInProgress;
exports.getApiProcessingInProgress = getApiProcessingInProgress;
const getDevicesInGroup = (state) => {
    if (state.filterBy && state.filterBy.length) {
        return getCurrentPage(filterDevices(state.devicesToAddArray, state.filterBy), state.paginatorMetadata);
    }
    if (state.wizardFlowType === device_groups_models_1.DeviceGroupWizardFlowType.EDIT) {
        return [...state.devicesToAddArray, ...state.devicesToRemoveArray];
    }
    return state.devicesToAddArray;
};
exports.getDevicesInGroup = getDevicesInGroup;
const getIsTableLoading = (state) => state.isTableLoading;
exports.getIsTableLoading = getIsTableLoading;
const getIsDeleting = (state) => state.isDeleting;
exports.getIsDeleting = getIsDeleting;
const getTotalCount = (state) => state.devicesToAddArray.length;
exports.getTotalCount = getTotalCount;
const getIsLoadingForEdit = (state) => state.loadingForEdit;
exports.getIsLoadingForEdit = getIsLoadingForEdit;
const getPagingMetadata = (state) => {
    const devices = filterDevices(state.devicesToAddArray, state.filterBy);
    const numberOfPages = Math.ceil(devices.length / state.paginatorMetadata.pageSize);
    const currentPageNumber = state.paginatorMetadata.pageNumber;
    const isLastPage = currentPageNumber + 1 >= numberOfPages;
    return {
        pagination: {
            previous: currentPageNumber > 0 ? 'previous' : undefined,
            next: !isLastPage ? 'next' : undefined,
            pageSize: exports.getDevicesInGroup(state).length,
            totalRows: devices.length
        }
    };
};
exports.getPagingMetadata = getPagingMetadata;
function getCurrentPage(devices, pagination) {
    const first = pagination.pageNumber * pagination.pageSize;
    const last = first + pagination.pageSize;
    return devices.slice(first, last);
}
function filterDevices(devices, filterBy) {
    if (filterBy && filterBy.length) {
        const actionsBy = filterBy
            .filter((x) => x.fieldName === 'action')
            .map((y) => y.value);
        const statusBy = filterBy.filter((x) => x.fieldName === 'status').map((y) => y.value);
        const osBy = filterBy.filter((x) => x.fieldName === 'os').map((y) => y.value);
        const searchBy = filterBy.filter((x) => x.fieldName === 'deviceName');
        const searchValue = searchBy.length ? searchBy[0].value : '';
        return devices.filter(device => applyFilter(device, searchValue, actionsBy, osBy, statusBy));
    }
    else {
        return devices;
    }
}
function applyFilter(device, searchBy, actionsBy, osBy, statusBy) {
    let statusFilterPass = true;
    let osFilterPass = true;
    let actionFilterPass = true;
    let textFilterPass = true;
    if (searchBy.length > 0) {
        textFilterPass = device.deviceName.toLowerCase().indexOf(searchBy.toLowerCase()) > -1;
    }
    if (textFilterPass && actionsBy.length > 0) {
        actionFilterPass = actionsBy.indexOf(device.action.toLowerCase()) > -1;
    }
    if (textFilterPass && statusBy.length > 0) {
        statusFilterPass = statusBy.indexOf(device.status) > -1;
    }
    if (textFilterPass && osBy.length > 0) {
        osFilterPass = device.os.toLowerCase().indexOf(osBy[0].toLowerCase()) > -1;
    }
    return textFilterPass && actionFilterPass && statusFilterPass && osFilterPass;
}
