"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.isGetGroupDataLoading = exports.getGroup = void 0;
const store_1 = require("@ngrx/store");
const _1 = require(".");
const getGroupReducer = require("../reducers/get-group.reducer");
const ɵ0 = (state) => state.getGroup;
exports.ɵ0 = ɵ0;
const getGetGroupState = store_1.createSelector(_1.getArchiveGroupsState, ɵ0);
exports.getGroup = store_1.createSelector(getGetGroupState, getGroupReducer.getGroup);
exports.isGetGroupDataLoading = store_1.createSelector(getGetGroupState, getGroupReducer.isDataLoading);
