"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MepListEffects = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const effects_1 = require("@ngrx/effects");
const TableStore = require("@mimecast-ui/table-store");
const actions = require("../../actions/policy.actions");
const sidebar = require("../../actions/sidebar.actions");
const delete_confirmation_modal_component_1 = require("../../components/modals/delete-confirmation-modal/delete-confirmation-modal.component");
const notification_actions_1 = require("../../../../../components/notification/actions/notification.actions");
const { MEP_POLICY_DELETE, MEP_POLICY_DELETE_CONFIRM_MODAL_CLOSE, MEP_POLICY_DELETE_CONFIRM_MODAL_OPEN } = actions.MepPolicyActionsEnums;
class MepListEffects {
    constructor(actions$, modalService, translate$, api$) {
        this.actions$ = actions$;
        this.modalService = modalService;
        this.translate$ = translate$;
        this.api$ = api$;
        this.deleteChannel$ = this.actions$.pipe(effects_1.ofType(MEP_POLICY_DELETE), operators_1.map((action) => action.id), operators_1.switchMap((id) => {
            return this.api$.deleteMepPolicy(id).pipe(operators_1.mergeMap(() => {
                return [
                    new notification_actions_1.NotificationShowSuccessAction(this.translate$.instant('$I18N_MEP.NOTIFICATIONS.DELETE.SUCCESS')),
                    new actions.MepDeletePolicySuccess(),
                    new actions.MepDetailDeleteConfirmModalClose(),
                    new TableStore.LoadAction({ tableId: 'MepPolicyList' })
                ];
            }), operators_1.catchError(() => rxjs_1.merge(rxjs_1.of(new notification_actions_1.NotificationShowFailAction(this.translate$.instant('$I18N_MEP.NOTIFICATIONS.DELETE.ERROR'))), rxjs_1.of(new actions.MepDeletePolicyFailure()), rxjs_1.of(new actions.MepDetailDeleteConfirmModalClose()))));
        }));
        this.openMepPolicyDeleteAlert = this.actions$.pipe(effects_1.ofType(MEP_POLICY_DELETE_CONFIRM_MODAL_OPEN), operators_1.map((action) => action.payload), operators_1.switchMap(() => {
            this.deleteAlertModalInstance = this.modalService.open(delete_confirmation_modal_component_1.MepDeleteConfirmationModalComponent, {
                size: 'md',
                hasBackdrop: true
            });
            return rxjs_1.of(new sidebar.MepPolicyListSidebarClose());
        }));
        this.closeMepPolicyDeleteAlert = this.actions$.pipe(effects_1.ofType(MEP_POLICY_DELETE_CONFIRM_MODAL_CLOSE), operators_1.filter(() => !!this.deleteAlertModalInstance), operators_1.tap(() => {
            this.deleteAlertModalInstance.close();
            this.deleteAlertModalInstance = undefined;
        }));
    }
}
__decorate([
    effects_1.Effect()
], MepListEffects.prototype, "deleteChannel$", void 0);
__decorate([
    effects_1.Effect()
], MepListEffects.prototype, "openMepPolicyDeleteAlert", void 0);
__decorate([
    effects_1.Effect()
], MepListEffects.prototype, "closeMepPolicyDeleteAlert", void 0);
exports.MepListEffects = MepListEffects;
