"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeviceGroupAddDevicesStepComponent = void 0;
const core_1 = require("@angular/core");
const device_groups_models_1 = require("../../../../../models/device-groups.models");
class DeviceGroupAddDevicesStepComponent {
    constructor() {
        this.deviceListDisplayType = device_groups_models_1.DeviceListDisplayType;
        this.filterDevicesInGroup = new core_1.EventEmitter();
        this.onPageChanged = new core_1.EventEmitter();
    }
    isValid() {
        return this.devicesInGroup.length > 0;
    }
    getStepHeader() {
        return this.displayType === device_groups_models_1.DeviceListDisplayType.CREATE
            ? '$I18N_DEVICE_GROUPS_LIST.WIZARD.STEPS.ADD_DEVICES.HEADER'
            : '$I18N_DEVICE_GROUPS_LIST.WIZARD.STEPS.ADD_DEVICES.EDIT_HEADER';
    }
    getStepSubHeader() {
        return this.displayType === device_groups_models_1.DeviceListDisplayType.CREATE
            ? '$I18N_DEVICE_GROUPS_LIST.WIZARD.STEPS.ADD_DEVICES.SUB_TITLE'
            : '$I18N_DEVICE_GROUPS_LIST.WIZARD.STEPS.ADD_DEVICES.EDIT_SUB_TITLE';
    }
}
exports.DeviceGroupAddDevicesStepComponent = DeviceGroupAddDevicesStepComponent;
