"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IdentityPolicyDetailsStepComponent = void 0;
const forms_1 = require("@angular/forms");
const stepActions = require("../../../actions/policy-wizard/details-step.actions");
const wizardActions = require("../../../actions/policy-wizard/wizard.actions");
const policy_wizard_1 = require("../../../reducers/policy-wizard");
const operators_1 = require("rxjs/operators");
class IdentityPolicyDetailsStepComponent {
    constructor(formBuilder, store) {
        this.formBuilder = formBuilder;
        this.store = store;
        this.policyDetailsForm = this.formBuilder.group({
            policyName: ['', forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.maxLength(100)])],
            policyDescription: ['', forms_1.Validators.maxLength(200)]
        });
    }
    ngOnInit() {
        this.valueChangesSubscription = this.policyDetailsForm.valueChanges.subscribe(value => {
            this.store.dispatch(new stepActions.SaveNameAction(value.policyName));
            this.store.dispatch(new stepActions.SaveDescriptionAction(value.policyDescription));
        });
        this.statusChangesSubscription = this.policyDetailsForm.statusChanges.subscribe(() => {
            this.dispatchValid();
        });
        this.store
            .select(policy_wizard_1.getDetailsStepState)
            .pipe(operators_1.first())
            .subscribe(state => {
            this.policyDetailsForm.patchValue({
                policyName: state.name,
                policyDescription: state.description
            }, { emitEvent: false });
            this.dispatchValid();
        });
    }
    dispatchValid() {
        this.store.dispatch(new wizardActions.UpdateIsValidAction(1, this.policyDetailsForm.valid));
    }
    ngOnDestroy() {
        this.valueChangesSubscription.unsubscribe();
        this.statusChangesSubscription.unsubscribe();
    }
}
exports.IdentityPolicyDetailsStepComponent = IdentityPolicyDetailsStepComponent;
