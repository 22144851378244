"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.heldStatusMapping = exports.mepStatusMapping = void 0;
exports.mepStatusMapping = {
    delivered: {
        label: '$18N_MEP_LOG_LIST.TABLE.FILTERS.COLUMN_VALUE_FILTER_FIELD.STATUS_FILTERS.DELIVERED',
        colour: 'green'
    },
    held: {
        label: '$18N_MEP_LOG_LIST.TABLE.FILTERS.COLUMN_VALUE_FILTER_FIELD.STATUS_FILTERS.HELD',
        colour: 'amber'
    },
    no_action: {
        label: '$18N_MEP_LOG_LIST.TABLE.FILTERS.COLUMN_VALUE_FILTER_FIELD.STATUS_FILTERS.NO_ACTION',
        colour: 'white'
    },
    released_by_user: {
        label: '$18N_MEP_LOG_LIST.TABLE.FILTERS.COLUMN_VALUE_FILTER_FIELD.STATUS_FILTERS.RELEASED_BY_USER',
        colour: 'green'
    },
    dropped_by_user: {
        label: '$18N_MEP_LOG_LIST.TABLE.FILTERS.COLUMN_VALUE_FILTER_FIELD.STATUS_FILTERS.DROPPED_BY_USER',
        colour: 'green'
    },
    released_by_admin: {
        label: '$18N_MEP_LOG_LIST.TABLE.FILTERS.COLUMN_VALUE_FILTER_FIELD.STATUS_FILTERS.RELEASED_BY_ADMIN',
        colour: 'green'
    },
    dropped_by_admin: {
        label: '$18N_MEP_LOG_LIST.TABLE.FILTERS.COLUMN_VALUE_FILTER_FIELD.STATUS_FILTERS.DROPPED_BY_ADMIN',
        colour: 'green'
    },
    auto_released: {
        label: '$18N_MEP_LOG_LIST.TABLE.FILTERS.COLUMN_VALUE_FILTER_FIELD.STATUS_FILTERS.AUTO_RELEASED',
        colour: 'green'
    },
    unknown: {
        label: '$18N_MEP_LOG_LIST.TABLE.FILTERS.COLUMN_VALUE_FILTER_FIELD.STATUS_FILTERS.UNKNOWN',
        colour: 'red'
    }
};
exports.heldStatusMapping = {
    potentiallyMisaddressed: '$18N_MEP_LOG_LIST.TABLE.FILTERS.COLUMN_VALUE_FILTER_FIELD.HELD_STATUSES.POTENTIALLY_MISADDRESSED'
};
