"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrackingSearchByDataComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const date_range_config_1 = require("../../../common/components/table-search-configs/date-range-config");
const utils_1 = require("../../../common/utils");
class TrackingSearchByDataComponent {
    constructor() {
        this.search = new core_1.EventEmitter();
        this.dateRangePickerConfig = date_range_config_1.DateRangeConfig.config;
        const formBuilder = new forms_1.FormBuilder();
        const minLength = 3;
        const maxLengthEmail = 254;
        const maxLengthSubject = 200;
        const ipAddressPattern = /(^(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])){3}$)/;
        this.formGroup = formBuilder.group({
            dateRangePicker: [date_range_config_1.DateRangeConfig.config.dateRanges[1], []],
            from: [null, [forms_1.Validators.minLength(minLength), forms_1.Validators.maxLength(maxLengthEmail)]],
            to: [null, [forms_1.Validators.minLength(minLength), forms_1.Validators.maxLength(maxLengthEmail)]],
            subject: [null, [forms_1.Validators.minLength(minLength), forms_1.Validators.maxLength(maxLengthSubject)]],
            senderIP: [null, forms_1.Validators.pattern(ipAddressPattern)],
            url: [null, forms_1.Validators.minLength(minLength)]
        }, {
            validator: TrackingSearchByDataComponent.formValidator
        });
    }
    static formValidator(formToValidate) {
        const hasValue = formToValidate.value.from ||
            formToValidate.value.to ||
            formToValidate.value.subject ||
            formToValidate.value.senderIP ||
            formToValidate.value.url;
        return hasValue ? null : { invalid: true };
    }
    doSearch() {
        const cleanFormValue = this.getSearchPayloadWithoutNullsAndEmptyStrings();
        this.search.emit(cleanFormValue);
    }
    getSearchPayloadWithoutNullsAndEmptyStrings() {
        const currentFormValue = this.formGroup.value;
        const searchPayload = {
            advancedTrackAndTraceOptions: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, (!!currentFormValue.from ? { from: utils_1.stripNonPrintableChars(currentFormValue.from) } : {})), (!!currentFormValue.to ? { to: utils_1.stripNonPrintableChars(currentFormValue.to) } : {})), (!!currentFormValue.subject
                ? { subject: utils_1.stripNonPrintableChars(currentFormValue.subject) }
                : {})), (!!currentFormValue.senderIP
                ? { senderIP: utils_1.stripNonPrintableChars(currentFormValue.senderIP) }
                : {})), (!!currentFormValue.url ? { url: utils_1.stripNonPrintableChars(currentFormValue.url) } : {})),
            start: currentFormValue.dateRangePicker.rangeFn().start.format(),
            end: currentFormValue.dateRangePicker.rangeFn().end.format()
        };
        Object.keys(searchPayload).forEach(key => !searchPayload[key] && delete searchPayload[key]);
        Object.keys(searchPayload.advancedTrackAndTraceOptions).forEach(key => {
            if (!searchPayload.advancedTrackAndTraceOptions[key]) {
                searchPayload.advancedTrackAndTraceOptions[key] = undefined;
            }
        });
        return searchPayload;
    }
}
exports.TrackingSearchByDataComponent = TrackingSearchByDataComponent;
