<mc-modal-header headerTitle="{{ '$I18N_ENDPOINT_SETTINGS.MODAL.INSTALL.HEADER' | translate }}"></mc-modal-header>
<mc-modal-body>
  <p>{{ '$I18N_ENDPOINT_SETTINGS.MODAL.INSTALL.BODY_CONTENT' | translate }}</p>
</mc-modal-body>
<mc-modal-footer>
  <button type="button" class="btn btn-secondary" (click)="onClose()">
    {{ '$I18N_ENDPOINT_SETTINGS.MODAL.INSTALL.BTN_CANCEL' | translate }}
  </button>
  <mc-live-button
    overrideClasses="btn-primary"
    [label]="'$I18N_ENDPOINT_SETTINGS.MODAL.INSTALL.BTN_DELETE' | translate"
    (mcClick)="onDelete()">
  </mc-live-button>
</mc-modal-footer>
