<mc-layout-detail-wizard keyTitle="{{ wizardTitle | translate }}">
  <mc-wizard [ngClass]="{ 'wizard-busy': isVerificationInProgress || isOktaEvidenceBasedControlIntegrationCreatingOrUpdating }">

    <mc-wizard-step [label]="'$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.ACTIVATION.TITLE' | translate">
      <mc-api-integration-okta-evidence-based-control-activation-step
        [hasCreatedIntegration]="hasCreatedIntegration"
        [integration]="integration"
        [isVerificationInProgress]="isVerificationInProgress"
        [isVerified]="isVerified"
        [isAuthenticationFailed]="isAuthenticationFailed"
        (onVerifyOktaApiKey)="verifyOktaApiKey($event)"
      >
      </mc-api-integration-okta-evidence-based-control-activation-step>
    </mc-wizard-step>

    <mc-wizard-step [label]="'$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.USER_GROUPS.TITLE' | translate">
      <mc-api-integration-okta-evidence-based-control-user-groups-step
        [userGroups]="userGroups"
        (updateUserGroups)="updateUserGroups.emit($event)"
      ></mc-api-integration-okta-evidence-based-control-user-groups-step>

      <div
        *ngIf="isVerificationInProgress || isOktaEvidenceBasedControlIntegrationCreatingOrUpdating"
        class="spinner-wrapper"
      >
        <mc-spinner></mc-spinner>
      </div>
    </mc-wizard-step>

    <mc-wizard-step [label]="'$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.EVENTS_AND_OKTA_GROUPS.TITLE' | translate">
      <mc-api-integration-okta-evidence-based-control-events-and-groups-step
        [integration]="integration"
        [searchOktaGroupResults]="searchOktaGroupResults"
        [isLoadingOktaGroups]="isLoadingOktaGroups"
        (onSearchOktaGroups)="onSearchOktaGroups.emit($event)"
        (onUpdateOktaEventGroups)="onUpdateOktaEventGroups.emit($event)"
      ></mc-api-integration-okta-evidence-based-control-events-and-groups-step>

      <div
        *ngIf="isVerificationInProgress || isOktaEvidenceBasedControlIntegrationCreatingOrUpdating"
        class="spinner-wrapper"
      >
        <mc-spinner></mc-spinner>
      </div>
    </mc-wizard-step>

    <mc-wizard-step [label]="'$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.NOTIFICATIONS.TITLE' | translate">
      <mc-api-integration-okta-evidence-based-control-notifications-step
        [groups]="notificationGroups"
        [emails]="notificationAddresses"
        (onAddGroup)="onAddGroup.emit($event)"
        (onDeleteGroup)="onDeleteGroup.emit($event)"
        (onDeleteEmail)="onDeleteEmail.emit($event)"
        (onVerifyAndAddEmail)="onVerifyAndAddEmail.emit($event)"
      ></mc-api-integration-okta-evidence-based-control-notifications-step>

      <div
        *ngIf="isVerificationInProgress || isOktaEvidenceBasedControlIntegrationCreatingOrUpdating"
        class="spinner-wrapper"
      >
        <mc-spinner></mc-spinner>
      </div>
    </mc-wizard-step>

    <mc-wizard-step [label]="'$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.SUMMARY.TITLE' | translate">
      <mc-api-integration-okta-evidence-based-control-summary-step
        [hasCreatedIntegration]="hasCreatedIntegration"
        [integration]="integration"
        [notificationGroups]="notificationGroups"
        [notificationAddresses]="notificationAddresses"
        (onUpdateEnabledStatus)="onUpdateEnabledStatus.emit($event)"
      ></mc-api-integration-okta-evidence-based-control-summary-step>

      <div
        *ngIf="isVerificationInProgress || isOktaEvidenceBasedControlIntegrationCreatingOrUpdating"
        class="spinner-wrapper"
      >
        <mc-spinner></mc-spinner>
      </div>
    </mc-wizard-step>
  </mc-wizard>

  <mc-footer-container>
    <div class="pull-right no-select mc-button-footer">
      <button
        (click)="cancel()"
        class="btn btn-text"
        data-test="cancel-btn"
      >
        {{ '$I18N_API_INTEGRATION_OKTA_WIZARD.BUTTONS.CANCEL' | translate }}
      </button>
      <button
        (click)="gotoPreviousStep()"
        *ngIf="showPrevious"
        [disabled]="isVerificationInProgress"
        class="btn btn-secondary panel-half-margin-right"
        data-test="previous-btn"
      >
        {{ '$I18N_API_INTEGRATION_OKTA_WIZARD.BUTTONS.PREVIOUS' | translate }}
      </button>
      <button
        (click)="gotoNextStep()"
        *ngIf="showNext"
        [disabled]="isVerificationInProgress || !isStepValid"
        [ngClass]="hasCreatedIntegration ? 'btn-secondary' : 'btn-primary'"
        class="btn"
        data-test="next-btn"
      >
        {{ '$I18N_API_INTEGRATION_OKTA_WIZARD.BUTTONS.NEXT' | translate }}
      </button>
      <button
        (click)="create()"
        *ngIf="showCreate"
        [disabled]="isVerificationInProgress || !isStepValid"
        class="btn btn-primary"
        data-test="create-btn"
      >
        {{ '$I18N_API_INTEGRATION_OKTA_WIZARD.BUTTONS.FINISH' | translate }}
      </button>
      <button
        (click)="update()"
        *ngIf="showUpdate"
        [disabled]="isVerificationInProgress || !isStepValid"
        class="btn btn-primary"
      >
        {{ '$I18N_API_INTEGRATION_OKTA_WIZARD.BUTTONS.SAVE_AND_CLOSE' | translate }}
      </button>
    </div>
  </mc-footer-container>

</mc-layout-detail-wizard>
