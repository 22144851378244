"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChangeWildfireRegionAction = exports.DeleteGroupWildfireIntegrationNotificationsAction = exports.AddGroupWildfireIntegrationNotificationsAction = exports.DeleteEmailWildfireIntegrationNotificationsAction = exports.VerifyAndAddEmailWildfireIntegrationNotificationsFailAction = exports.VerifyAndAddEmailWildfireIntegrationNotificationsSuccessAction = exports.VerifyAndAddEmailWildfireIntegrationNotificationsAction = exports.CheckWildfirePrerequisitesFailAction = exports.CheckWildfirePrerequisitesSuccessAction = exports.CheckWildfirePrerequisitesAction = exports.OpenWildfireIntegrationAction = exports.CancelWildfireIntegrationWidgetAction = exports.SwitchRemediationAction = exports.StartVerifyApiAndRegionFailAction = exports.StartVerifyApiAndRegionSuccessAction = exports.StartVerifyApiAndRegionAction = exports.SwitchWildfireMaliciousAttachmentIndicatorAction = exports.SwitchWildfireAttachmentIndicatorAction = exports.SwitchWildfireAcceptSharingInfoAction = exports.SwitchWildfireIntegrationStatusAction = exports.UpdateWildfireIntegrationSuccessAction = exports.UpdateWildfireIntegrationAction = exports.CreateWildfireIntegrationSuccessAction = exports.CreateWildfireIntegrationAction = exports.StartIntegrationEditWildfireAction = exports.IntegrationWildfireActionTypes = void 0;
var IntegrationWildfireActionTypes;
(function (IntegrationWildfireActionTypes) {
    IntegrationWildfireActionTypes["CreateWildfireIntegration"] = "[ApiApplication/Integration/Wildfire/Create] Create Wildfire Integration";
    IntegrationWildfireActionTypes["CreateWildfireIntegrationSuccess"] = "[ApiApplication/Integration/Wildfire/Create/Success] Create Wildfire Integration Success";
    IntegrationWildfireActionTypes["UpdateWildfireIntegration"] = "[ApiApplication/Integration/Wildfire/Update] Update Wildfire Integration";
    IntegrationWildfireActionTypes["UpdateWildfireIntegrationSuccess"] = "[ApiApplication/Integration/Wildfire/Update/Success] Update Wildfire Integration Success";
    IntegrationWildfireActionTypes["StartIntegrationEditWildfire"] = "[ApiApplication/Integration/Wildfire/Edit/Start] Start Edit Wildfire Integration";
    IntegrationWildfireActionTypes["SwitchWildfireIntegrationStatus"] = "[ApiApplication/Integration/Wildfire/Status/Switch] Wildfire Integration Status Switch";
    IntegrationWildfireActionTypes["SwitchWildfireAttachmentIndicator"] = "[ApiApplication/Integration/Wildfire/Indicators/Attachments/Switch]   Wildfire Integration Attachments Switch";
    IntegrationWildfireActionTypes["SwitchWildfireAcceptSharingInfoDisclaimer"] = "[ApiApplication/Integration/Wildfire/Disclaimer/Switch]   Wildfire Integration Diclaimer Accept Sharing Info Switch";
    IntegrationWildfireActionTypes["SwitchWildfireMaliciousAttachmentIndicator"] = "[ApiApplication/Integration/Wildfire/Indicators/Attachments/Switch]   Wildfire Integration Malicious Attachments Switch";
    IntegrationWildfireActionTypes["StartVerifyApiAndRegion"] = "[ApiApplication/Integration/Wildfire/Activation] Wildfire Integration Activation Verify";
    IntegrationWildfireActionTypes["StartVerifyApiAndRegionSuccess"] = "[ApiApplication/Integration/Wildfire/Activation] Wildfire Integration Activation Verify Success";
    IntegrationWildfireActionTypes["StartVerifyApiAndRegionFail"] = "[ApiApplication/Integration/Wildfire/Activation] Wildfire Integration Activation Verify Fail";
    IntegrationWildfireActionTypes["SwitchRemediation"] = "[ApiApplication/Integration/Wildfire/Indicators/Attachments/Switch]   Wildfire Integration Remediation Switch";
    IntegrationWildfireActionTypes["CancelWildfireIntegrationWidget"] = "[ApiApplication/Integration/Wildfire/Cancel] Wildfire Integration Cancel";
    IntegrationWildfireActionTypes["OpenWildfireIntegration"] = "[ApiApplication/Integration/Wildfire/Open] Open Wildfire Integration";
    IntegrationWildfireActionTypes["CheckWildfirePrerequisites"] = "[ApiApplication/Integration/Wildfire] Check Wildfire Integration Prerequisites";
    IntegrationWildfireActionTypes["CheckWildfirePrerequisitesSuccess"] = "[ApiApplication/Integration/Wildfire/Success] Check Wildfire Integration Prerequisites Success";
    IntegrationWildfireActionTypes["CheckWildfirePrerequisitesFail"] = "[ApiApplication/Integration/Wildfire/Fail] Check Wildfire Integration Prerequisites Fail";
    IntegrationWildfireActionTypes["VerifyAndAddEmailWildfireIntegrationNotifications"] = "[ApiApplication/Integration/Wildfire/Email/Add] Add Email Wildfire Integration";
    IntegrationWildfireActionTypes["VerifyAndAddEmailWildfireIntegrationNotificationsSuccess"] = "[ApiApplication/Integration/Wildfire/Email/Add/Success]   Add Email Wildfire Integration Success";
    IntegrationWildfireActionTypes["VerifyAndAddEmailWildfireIntegrationNotificationsFail"] = "[ApiApplication/Integration/Wildfire/Email/Add/Fail]   Add Email Wildfire Integration Fail";
    IntegrationWildfireActionTypes["DeleteEmailWildfireIntegrationNotifications"] = "[ApiApplication/Integration/Wildfire/Email/Delete] Delete Email Wildfire Integration";
    IntegrationWildfireActionTypes["AddGroupWildfireIntegrationNotifications"] = "[ApiApplication/Integration/Wildfire/Group/Add] Add Group Wildfire Integration";
    IntegrationWildfireActionTypes["DeleteGroupWildfireIntegrationNotifications"] = "[ApiApplication/Integration/Wildfire/Group/Delete] Delete Group Wildfire Integration";
    IntegrationWildfireActionTypes["ChangeRegion"] = "[ApiApplication/Integration/Wildfire/Indicators/Region/Switch] Wildfire Region Switch";
})(IntegrationWildfireActionTypes = exports.IntegrationWildfireActionTypes || (exports.IntegrationWildfireActionTypes = {}));
class StartIntegrationEditWildfireAction {
    constructor() {
        this.type = IntegrationWildfireActionTypes.StartIntegrationEditWildfire;
    }
}
exports.StartIntegrationEditWildfireAction = StartIntegrationEditWildfireAction;
class CreateWildfireIntegrationAction {
    constructor() {
        this.type = IntegrationWildfireActionTypes.CreateWildfireIntegration;
    }
}
exports.CreateWildfireIntegrationAction = CreateWildfireIntegrationAction;
class CreateWildfireIntegrationSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationWildfireActionTypes.CreateWildfireIntegrationSuccess;
    }
}
exports.CreateWildfireIntegrationSuccessAction = CreateWildfireIntegrationSuccessAction;
class UpdateWildfireIntegrationAction {
    constructor() {
        this.type = IntegrationWildfireActionTypes.UpdateWildfireIntegration;
    }
}
exports.UpdateWildfireIntegrationAction = UpdateWildfireIntegrationAction;
class UpdateWildfireIntegrationSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationWildfireActionTypes.UpdateWildfireIntegrationSuccess;
    }
}
exports.UpdateWildfireIntegrationSuccessAction = UpdateWildfireIntegrationSuccessAction;
class SwitchWildfireIntegrationStatusAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationWildfireActionTypes.SwitchWildfireIntegrationStatus;
    }
}
exports.SwitchWildfireIntegrationStatusAction = SwitchWildfireIntegrationStatusAction;
class SwitchWildfireAcceptSharingInfoAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationWildfireActionTypes.SwitchWildfireAcceptSharingInfoDisclaimer;
    }
}
exports.SwitchWildfireAcceptSharingInfoAction = SwitchWildfireAcceptSharingInfoAction;
class SwitchWildfireAttachmentIndicatorAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationWildfireActionTypes.SwitchWildfireAttachmentIndicator;
    }
}
exports.SwitchWildfireAttachmentIndicatorAction = SwitchWildfireAttachmentIndicatorAction;
class SwitchWildfireMaliciousAttachmentIndicatorAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationWildfireActionTypes.SwitchWildfireMaliciousAttachmentIndicator;
    }
}
exports.SwitchWildfireMaliciousAttachmentIndicatorAction = SwitchWildfireMaliciousAttachmentIndicatorAction;
class StartVerifyApiAndRegionAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationWildfireActionTypes.StartVerifyApiAndRegion;
    }
}
exports.StartVerifyApiAndRegionAction = StartVerifyApiAndRegionAction;
class StartVerifyApiAndRegionSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationWildfireActionTypes.StartVerifyApiAndRegionSuccess;
    }
}
exports.StartVerifyApiAndRegionSuccessAction = StartVerifyApiAndRegionSuccessAction;
class StartVerifyApiAndRegionFailAction {
    constructor() {
        this.type = IntegrationWildfireActionTypes.StartVerifyApiAndRegionFail;
    }
}
exports.StartVerifyApiAndRegionFailAction = StartVerifyApiAndRegionFailAction;
class SwitchRemediationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationWildfireActionTypes.SwitchRemediation;
    }
}
exports.SwitchRemediationAction = SwitchRemediationAction;
class CancelWildfireIntegrationWidgetAction {
    constructor() {
        this.type = IntegrationWildfireActionTypes.CancelWildfireIntegrationWidget;
    }
}
exports.CancelWildfireIntegrationWidgetAction = CancelWildfireIntegrationWidgetAction;
class OpenWildfireIntegrationAction {
    constructor() {
        this.type = IntegrationWildfireActionTypes.OpenWildfireIntegration;
    }
}
exports.OpenWildfireIntegrationAction = OpenWildfireIntegrationAction;
class CheckWildfirePrerequisitesAction {
    constructor() {
        this.type = IntegrationWildfireActionTypes.CheckWildfirePrerequisites;
    }
}
exports.CheckWildfirePrerequisitesAction = CheckWildfirePrerequisitesAction;
class CheckWildfirePrerequisitesSuccessAction {
    constructor() {
        this.type = IntegrationWildfireActionTypes.CheckWildfirePrerequisitesSuccess;
    }
}
exports.CheckWildfirePrerequisitesSuccessAction = CheckWildfirePrerequisitesSuccessAction;
class CheckWildfirePrerequisitesFailAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationWildfireActionTypes.CheckWildfirePrerequisitesFail;
    }
}
exports.CheckWildfirePrerequisitesFailAction = CheckWildfirePrerequisitesFailAction;
class VerifyAndAddEmailWildfireIntegrationNotificationsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationWildfireActionTypes.VerifyAndAddEmailWildfireIntegrationNotifications;
    }
}
exports.VerifyAndAddEmailWildfireIntegrationNotificationsAction = VerifyAndAddEmailWildfireIntegrationNotificationsAction;
class VerifyAndAddEmailWildfireIntegrationNotificationsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationWildfireActionTypes.VerifyAndAddEmailWildfireIntegrationNotificationsSuccess;
    }
}
exports.VerifyAndAddEmailWildfireIntegrationNotificationsSuccessAction = VerifyAndAddEmailWildfireIntegrationNotificationsSuccessAction;
class VerifyAndAddEmailWildfireIntegrationNotificationsFailAction {
    constructor() {
        this.type = IntegrationWildfireActionTypes.VerifyAndAddEmailWildfireIntegrationNotificationsFail;
    }
}
exports.VerifyAndAddEmailWildfireIntegrationNotificationsFailAction = VerifyAndAddEmailWildfireIntegrationNotificationsFailAction;
class DeleteEmailWildfireIntegrationNotificationsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationWildfireActionTypes.DeleteEmailWildfireIntegrationNotifications;
    }
}
exports.DeleteEmailWildfireIntegrationNotificationsAction = DeleteEmailWildfireIntegrationNotificationsAction;
class AddGroupWildfireIntegrationNotificationsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationWildfireActionTypes.AddGroupWildfireIntegrationNotifications;
    }
}
exports.AddGroupWildfireIntegrationNotificationsAction = AddGroupWildfireIntegrationNotificationsAction;
class DeleteGroupWildfireIntegrationNotificationsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationWildfireActionTypes.DeleteGroupWildfireIntegrationNotifications;
    }
}
exports.DeleteGroupWildfireIntegrationNotificationsAction = DeleteGroupWildfireIntegrationNotificationsAction;
class ChangeWildfireRegionAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationWildfireActionTypes.ChangeRegion;
    }
}
exports.ChangeWildfireRegionAction = ChangeWildfireRegionAction;
