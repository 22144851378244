"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditPolicyFromListView = exports.UpdatePolicyDetailsEnableStatus = exports.GetPolicyDetailsSuccessAction = exports.OpenPolicyDetailsSidePanelAction = exports.ClosePolicyDetailsSidePanelAction = exports.ClearPolicyDetailsAction = exports.GetPolicyDetailsAction = exports.PolicyDeleteCancelAction = exports.PolicyDeleteFailAction = exports.PolicyDeleteSucessAction = exports.PolicyDeleteAction = exports.PolicyDeleteAttemptAction = exports.PoliciesRequestAction = exports.CYBERGRAPH_EDIT_POLICY_FROM_LIST_VIEW = exports.CYBERGRAPH_UPDATE_POLICY_DETAILS_ENABLE_STATUS = exports.CYBERGRAPH_OPEN_POLICY_DETAILS_SIDE_PANEL = exports.CYBERGRAPH_CLOSE_POLICY_DETAILS_SIDE_PANEL = exports.CYBERGRAPH_GET_POLICY_DETAILS_SUCCESS = exports.CYBERGRAPH_CLEAR_POLICY_DETAILS = exports.CYBERGRAPH_GET_POLICY_DETAILS = exports.CYBERGRAPH_POLICY_DELETE_FAIL = exports.CYBERGRAPH_POLICY_DELETE_SUCCESS = exports.CYBERGRAPH_POLICY_DELETE = exports.CYBERGRAPH_POLICY_DELETE_CANCEL = exports.CYBERGRAPH_POLICY_DELETE_ATTEMPT = exports.CYBERGRAPH_POLICIES_REQUEST = void 0;
exports.CYBERGRAPH_POLICIES_REQUEST = '[Services / CyberGraph / Policies] Request Get Policies';
exports.CYBERGRAPH_POLICY_DELETE_ATTEMPT = '[Services / CyberGraph / Policies] Delete Policy Attempt';
exports.CYBERGRAPH_POLICY_DELETE_CANCEL = '[Services / CyberGraph / Policies] Delete Policy Canceled';
exports.CYBERGRAPH_POLICY_DELETE = '[Services / CyberGraph / Policies] Delete Policy';
exports.CYBERGRAPH_POLICY_DELETE_SUCCESS = '[Services / CyberGraph / Policies] Delete Policy Success';
exports.CYBERGRAPH_POLICY_DELETE_FAIL = '[Services / CyberGraph / Policies] Delete Policy Fail';
exports.CYBERGRAPH_GET_POLICY_DETAILS = '[Services / CyberGraph / Policies] Request Get Policy Details';
exports.CYBERGRAPH_CLEAR_POLICY_DETAILS = '[Services / CyberGraph / Policies] Clear Policy Details State';
exports.CYBERGRAPH_GET_POLICY_DETAILS_SUCCESS = '[Services / CyberGraph / Policies] Request Get Policy Details - SUCCESS';
exports.CYBERGRAPH_CLOSE_POLICY_DETAILS_SIDE_PANEL = '[Services / CyberGraph / Policies] Close Policy Details Side Panel';
exports.CYBERGRAPH_OPEN_POLICY_DETAILS_SIDE_PANEL = '[Services / CyberGraph / Policies] Open Policy Details Side Panel';
exports.CYBERGRAPH_UPDATE_POLICY_DETAILS_ENABLE_STATUS = '[Services / CyberGraph / Policies] Update the enable status for selected policy details item';
exports.CYBERGRAPH_EDIT_POLICY_FROM_LIST_VIEW = '[Services / CyberGraph / Policies] Edit policy from list view';
class PoliciesRequestAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.CYBERGRAPH_POLICIES_REQUEST;
    }
}
exports.PoliciesRequestAction = PoliciesRequestAction;
class PolicyDeleteAttemptAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.CYBERGRAPH_POLICY_DELETE_ATTEMPT;
    }
}
exports.PolicyDeleteAttemptAction = PolicyDeleteAttemptAction;
class PolicyDeleteAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.CYBERGRAPH_POLICY_DELETE;
    }
}
exports.PolicyDeleteAction = PolicyDeleteAction;
class PolicyDeleteSucessAction {
    constructor() {
        this.type = exports.CYBERGRAPH_POLICY_DELETE_SUCCESS;
    }
}
exports.PolicyDeleteSucessAction = PolicyDeleteSucessAction;
class PolicyDeleteFailAction {
    constructor() {
        this.type = exports.CYBERGRAPH_POLICY_DELETE_FAIL;
    }
}
exports.PolicyDeleteFailAction = PolicyDeleteFailAction;
class PolicyDeleteCancelAction {
    constructor() {
        this.type = exports.CYBERGRAPH_POLICY_DELETE_CANCEL;
    }
}
exports.PolicyDeleteCancelAction = PolicyDeleteCancelAction;
class GetPolicyDetailsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.CYBERGRAPH_GET_POLICY_DETAILS;
    }
}
exports.GetPolicyDetailsAction = GetPolicyDetailsAction;
class ClearPolicyDetailsAction {
    constructor() {
        this.type = exports.CYBERGRAPH_CLEAR_POLICY_DETAILS;
    }
}
exports.ClearPolicyDetailsAction = ClearPolicyDetailsAction;
class ClosePolicyDetailsSidePanelAction {
    constructor() {
        this.type = exports.CYBERGRAPH_CLOSE_POLICY_DETAILS_SIDE_PANEL;
    }
}
exports.ClosePolicyDetailsSidePanelAction = ClosePolicyDetailsSidePanelAction;
class OpenPolicyDetailsSidePanelAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.CYBERGRAPH_OPEN_POLICY_DETAILS_SIDE_PANEL;
    }
}
exports.OpenPolicyDetailsSidePanelAction = OpenPolicyDetailsSidePanelAction;
class GetPolicyDetailsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.CYBERGRAPH_GET_POLICY_DETAILS_SUCCESS;
    }
}
exports.GetPolicyDetailsSuccessAction = GetPolicyDetailsSuccessAction;
class UpdatePolicyDetailsEnableStatus {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.CYBERGRAPH_UPDATE_POLICY_DETAILS_ENABLE_STATUS;
    }
}
exports.UpdatePolicyDetailsEnableStatus = UpdatePolicyDetailsEnableStatus;
class EditPolicyFromListView {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.CYBERGRAPH_EDIT_POLICY_FROM_LIST_VIEW;
    }
}
exports.EditPolicyFromListView = EditPolicyFromListView;
