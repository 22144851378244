<div class="mc-add-devices-step">
  <mc-wizard-step-header label="{{getStepHeader()}}">
    {{ getStepSubHeader() | translate }}
  </mc-wizard-step-header>

  <div>
    <mc-swg-devices-list
      [deviceGroup]="deviceGroup"
      [displayType]="displayType"
      [devicesInGroup]="devicesInGroup"
      [devicesListManualPagingData]="devicesListManualPagingData"
      [isDeviceListTableLoading]="isDeviceListTableLoading"
      [deviceCount]="deviceCount"
      (filterDevicesInGroup)="filterDevicesInGroup.emit($event)"
      (onPageChanged)="onPageChanged.emit($event)"
    ></mc-swg-devices-list>
  </div>

</div>
