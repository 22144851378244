"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PolicyGroupsComponent = void 0;
class PolicyGroupsComponent {
    set groups(value) {
        if (value) {
            // @ts-ignore
            this.appliedTo = value.map(group => group.description).join(', ');
        }
        else {
            this.appliedTo = null;
        }
    }
}
exports.PolicyGroupsComponent = PolicyGroupsComponent;
