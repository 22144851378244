<mc-table
  tableId="api-integration/crowdstrike/activity"
  [isLoading]="isLoading"
  [highlightedRow]="selectedRow"
  [data]="activityLogs"
  [columns]="columns"
  (rowClick)="viewActivityLog($event)"
  translatePrefix="$I18N_CROWDSTRIKE_ACTIVITY_LOGS_TABLE"
>

<mc-filters [showPagination]="false"></mc-filters>

  <mc-empty-results
    iconClass="far fa-list-alt"
    keyTitle="$I18N_CROWDSTRIKE_ACTIVITY_LOGS_TABLE.NO_DATA.TITLE"
  >
  </mc-empty-results>

  <mc-column key="detail">
    <mc-body-cell *mcBodyCellDef="let row">{{ row.fileHash }}</mc-body-cell>
  </mc-column>

  <mc-column key="indicatorType">
    <mc-body-cell *mcBodyCellDef="let row">{{ "Indicator:" + row.indicatorType }}</mc-body-cell>
  </mc-column>
  
  <mc-column key="targetPlatform">
    <mc-body-cell *mcBodyCellDef="let row">{{ row.targetedPlatform }}</mc-body-cell> 
  </mc-column>

  <mc-column key="dateTime">
    <mc-body-cell *mcBodyCellDef="let row">{{ row.timestamp | date: 'dd MMM yyyy hh:mm'}}</mc-body-cell> 
  </mc-column>

  <mc-column-actions
  key="action"
  mcShowColumnConfig
  [columnsAlwaysVisible]="columnsAlwaysVisible"
  [columnsToStartHidden]="columnsStartHidden"
>
  <mc-row-actions *mcRowActions="let row">
    <li
      mcRowAction="$I18N_API_INTEGRATION_LIST_TABLE.ACTIONS.VIEW"
      (click)="viewActivityLog(row)"
    ></li>
  </mc-row-actions>
</mc-column-actions>

</mc-table>
