"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetAdcon3UrlsAction = exports.LogoutAction = exports.LoadFailAction = exports.LoadSuccessAction = exports.LoadAction = exports.LogoutSuccessAction = exports.LogoutFailAction = exports.GET_ADCON_3_URLS = exports.LOGOUT_FAIL = exports.LOGOUT_SUCCESS = exports.LOGOUT = exports.LOAD_FAIL = exports.LOAD_SUCCESS = exports.LOAD = void 0;
exports.LOAD = '[Session] Load';
exports.LOAD_SUCCESS = '[Session] Load Success';
exports.LOAD_FAIL = '[Session] Load Fail';
exports.LOGOUT = '[Session] Logout';
exports.LOGOUT_SUCCESS = '[Session] Logout Success';
exports.LOGOUT_FAIL = '[Session] Logout Fail';
exports.GET_ADCON_3_URLS = '[Session] Get Adcon3 URL';
class LogoutFailAction {
    constructor() {
        this.type = exports.LOGOUT_FAIL;
    }
}
exports.LogoutFailAction = LogoutFailAction;
class LogoutSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOGOUT_SUCCESS;
    }
}
exports.LogoutSuccessAction = LogoutSuccessAction;
class LoadAction {
    constructor() {
        this.type = exports.LOAD;
    }
}
exports.LoadAction = LoadAction;
class LoadSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOAD_SUCCESS;
    }
}
exports.LoadSuccessAction = LoadSuccessAction;
class LoadFailAction {
    constructor() {
        this.type = exports.LOAD_FAIL;
    }
}
exports.LoadFailAction = LoadFailAction;
class LogoutAction {
    constructor() {
        this.type = exports.LOGOUT;
    }
}
exports.LogoutAction = LogoutAction;
class GetAdcon3UrlsAction {
    constructor() {
        this.type = exports.GET_ADCON_3_URLS;
    }
}
exports.GetAdcon3UrlsAction = GetAdcon3UrlsAction;
