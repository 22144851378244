"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiIntegrationCrowdstrikeInboundStepComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const operators_1 = require("rxjs/operators");
class ApiIntegrationCrowdstrikeInboundStepComponent {
    constructor(fb, crowdstrikeService) {
        this.fb = fb;
        this.crowdstrikeService = crowdstrikeService;
        this.valuesChanged = false;
        this.hasInternalEmailProtectPackage = false;
        this.hasByotiPackage = false;
        this.internalEmailProtectPackageId = '[1064]';
        this.byotiPackageId = '[1089]';
        this.onSwitchPullMaliciousHashesEnabled = new core_1.EventEmitter();
        this.onSwitchRemediation = new core_1.EventEmitter();
        this.form = this.fb.group({
            pullMaliciousHashesEnabledField: new forms_1.FormControl({
                value: false,
                disabled: !this.hasByotiPackage
            }),
            remediationEnabledField: new forms_1.FormControl({
                value: false,
                disabled: !this.hasInternalEmailProtectPackage
            })
        });
    }
    isRemediationEnabled() {
        return this.crowdstrikeIntegration.pullMaliciousHashes && this.hasInternalEmailProtectPackage;
    }
    isPullHashesEnabled() {
        return this.hasByotiPackage;
    }
    setRemediationEnabled() {
        if (this.isRemediationEnabled()) {
            this.form.get('remediationEnabledField').enable();
        }
        else {
            this.form.get('remediationEnabledField').disable();
        }
    }
    setPullhashesEnabled() {
        if (this.isPullHashesEnabled()) {
            this.form.get('pullMaliciousHashesEnabledField').enable();
        }
        else {
            this.form.get('pullMaliciousHashesEnabledField').disable();
        }
    }
    ngOnInit() {
        this.hasInternalEmailProtectPackage = this.crowdstrikeService.hasRequiredPackage(this.internalEmailProtectPackageId);
        this.hasByotiPackage = this.crowdstrikeService.hasRequiredPackage(this.byotiPackageId);
        this.setRemediationEnabled();
        this.setPullhashesEnabled();
        if (!!this.crowdstrikeIntegration) {
            this.form.patchValue({
                pullMaliciousHashesEnabledField: this.crowdstrikeIntegration.pullMaliciousHashes,
                remediationEnabledField: this.crowdstrikeIntegration.remediationRequired
            });
        }
        this.formSubscription = this.form.valueChanges
            .pipe(operators_1.debounceTime(200))
            .pipe(operators_1.startWith(false), operators_1.pairwise())
            .subscribe(([prev, next]) => {
            if (JSON.stringify(prev) !== JSON.stringify(next)) {
                this.update();
            }
        });
    }
    ngOnChanges(changes) {
        const currentValue = changes.crowdstrikeIntegration.currentValue;
        const previousValue = changes.crowdstrikeIntegration.previousValue;
        if (currentValue &&
            previousValue &&
            (currentValue.pullMaliciousHashes !== previousValue.pullMaliciousHashes ||
                currentValue.remediationRequired !== previousValue.remediationRequired)) {
            this.form.patchValue({
                pullMaliciousHashesEnabledField: !!this.crowdstrikeIntegration.pullMaliciousHashes,
                remediationEnabledField: !!this.crowdstrikeIntegration.remediationRequired
            });
            this.setRemediationEnabled();
        }
    }
    update() {
        this.onSwitchRemediation.emit(!!this.form.value.remediationEnabledField);
        this.onSwitchPullMaliciousHashesEnabled.emit(!!this.form.value.pullMaliciousHashesEnabledField);
    }
    ngOnDestroy() {
        this.formSubscription.unsubscribe();
    }
    isValid() {
        return true;
    }
}
exports.ApiIntegrationCrowdstrikeInboundStepComponent = ApiIntegrationCrowdstrikeInboundStepComponent;
