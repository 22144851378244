"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgPolicyDuplicateConfirmComponent = void 0;
const policy = require("app-new/swg/policies/actions/policy.actions");
const sidebar = require("../../../actions/sidebar.actions");
const reducers = require("../../../reducers");
class SwgPolicyDuplicateConfirmComponent {
    constructor(store) {
        this.store = store;
    }
    ngOnInit() {
        this.policy$ = this.store.select(reducers.getSwgPolicy);
    }
    forceDuplicate(data) {
        this.store.dispatch(new sidebar.SwgPolicyUnchangedDuplicateWarnClose());
        this.store.dispatch(new policy.SwgPolicyForeceDuplicateAction());
        // @ts-ignore
        this.store.dispatch(new policy.SwgCreatePolicyAction(Object.assign({}, data)));
    }
    onCancel() {
        this.store.dispatch(new sidebar.SwgPolicyUnchangedDuplicateWarnClose());
    }
}
exports.SwgPolicyDuplicateConfirmComponent = SwgPolicyDuplicateConfirmComponent;
