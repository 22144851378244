"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DirectorySyncAzureSettingsStepComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const operators_1 = require("rxjs/operators");
const validators_1 = require("../../../../../../api-applications/validators");
const directory_sync_integration_interface_1 = require("../../../../../models/directory-sync-integration.interface");
class DirectorySyncAzureSettingsStepComponent {
    constructor(fb) {
        this.fb = fb;
        this.delay = 200;
        this.onIntegrationDetailsUpdated = new core_1.EventEmitter();
        this.appIdAndKeyTooltipKbLink = 'https://community.mimecast.com/s/article/Configuring-an-Azure-Active-Directory-Application-293891018';
    }
    ngOnInit() {
        this.form = this.fb.group({
            appID: [this.integration.appID || '', [forms_1.Validators.required, validators_1.validateNotEmpty]],
            key: this.getKeyValidation(),
            tenantDomain: [this.integration.tenantDomain || '', [forms_1.Validators.required, validators_1.validateNotEmpty]]
        });
        this.update();
        this.formSubscription = this.form.valueChanges.pipe(operators_1.debounceTime(this.delay)).subscribe(() => {
            this.update();
        });
    }
    ngOnChanges(changes) {
        if (!!changes.integration.previousValue &&
            changes.integration.currentValue.type !== changes.integration.previousValue.type) {
            this.form.reset();
            this.form.markAsPristine();
        }
    }
    update() {
        this.onIntegrationDetailsUpdated.emit(this.form.getRawValue());
    }
    integrationType() {
        return this.integration.type === 'azure_standard'
            ? 'Microsoft Azure - Standard'
            : 'Microsoft Azure - GCC HIGH';
    }
    isValid() {
        return this.form.status === 'VALID';
    }
    getKeyValidation() {
        return this.isEditMode && !this.integration.hasServerTypeUpdatedOnEdit
            ? [directory_sync_integration_interface_1.HiddenValues.key, [validators_1.validateNotEmpty]]
            : ['', [forms_1.Validators.required, validators_1.validateNotEmpty]];
    }
    ngOnDestroy() {
        this.formSubscription.unsubscribe();
    }
}
exports.DirectorySyncAzureSettingsStepComponent = DirectorySyncAzureSettingsStepComponent;
