"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RejectedAndDeferredService = void 0;
const components_1 = require("@mimecast-ui/components");
const moment = require("moment");
class RejectedAndDeferredService {
    constructor(http) {
        this.http = http;
        this.BASE_URL = '/api/gateway';
    }
    getRejections(config) {
        // TODO(tsingh): temporary until API is ready
        const admin = { admin: true };
        config = Object.assign(Object.assign({}, config), admin);
        return this.http.post(`${this.BASE_URL}/get-rejections`, config);
    }
    getDeferrals(config) {
        // TODO(tsingh): temporary until API is ready
        const admin = { admin: true };
        config = Object.assign(Object.assign({}, config), admin);
        return this.http.post(`${this.BASE_URL}/get-connection-attempts`, config);
    }
    transformToServerRequest(appRequest, pagination) {
        const { start, end } = appRequest, remainder = __rest(appRequest, ["start", "end"]);
        const admin = { admin: true };
        const apiRequest = Object.assign(Object.assign(Object.assign(Object.assign({}, remainder), admin), (!!start ? { start: moment(appRequest.start).format(components_1.API_DATE_FORMAT) } : {})), (!!end ? { end: moment(appRequest.end).format(components_1.API_DATE_FORMAT) } : {}));
        if (!!pagination) {
            // @ts-ignore: api interceptor wants like this :(
            apiRequest.meta = { pagination };
        }
        return apiRequest;
    }
}
exports.RejectedAndDeferredService = RejectedAndDeferredService;
