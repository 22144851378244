"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const actions = require("../../actions");
const notification_actions_1 = require("app-new/components/notification/actions/notification.actions");
const delete_confirmation_modal_1 = require("../../components/api-gateway/modals/delete-confirmation-modal");
const disable_confirmation_modal_1 = require("../../components/api-gateway/modals/disable-confirmation-modal");
const aside_1 = require("../../components/api-gateway/aside");
const display_credentials_modal_1 = require("../../components/api-gateway/modals/display-credentials-modal");
class ListEffects {
    constructor(actions$, stateService, translateService, modalService, asideService, apiService, featureFlagService) {
        this.actions$ = actions$;
        this.stateService = stateService;
        this.translateService = translateService;
        this.modalService = modalService;
        this.asideService = asideService;
        this.apiService = apiService;
        this.featureFlagService = featureFlagService;
        this.getAllApiApplications$ = this.actions$.pipe(effects_1.ofType(actions.ApiGatewayListActionTypes.GetAllApiApplications), operators_1.switchMap(() => {
            return this.apiService.getAllApplications().pipe(operators_1.map((response) => new actions.GetAllApiApplicationsSuccessAction(response)), operators_1.catchError(() => rxjs_1.of(new actions.ApiApplicationFailureAction('$I18N_API_APPLICATIONS_LIST.NOTIFICATIONS.ERRORS.GENERAL'))));
        }));
        this.pageApiApplications$ = this.actions$.pipe(effects_1.ofType(actions.ApiGatewayListActionTypes.PageApiApplications), operators_1.delay(500, rxjs_1.asyncScheduler), operators_1.map(() => new actions.PageApiApplicationsCompletedAction()));
        this.openCreateApiApplication$ = this.actions$.pipe(effects_1.ofType(actions.ApiGatewayListActionTypes.OpenCreateApiApplication), operators_1.tap(() => {
            this.stateService.$state.go('api-applications-save');
        }));
        this.openCreateApiApplicationCompleted$ = this.actions$.pipe(effects_1.ofType(actions.ApiGatewayListActionTypes.OpenCreateApiApplicationCompleted), operators_1.switchMap((action) => {
            return [
                new actions.OpenViewApiApplicationAction(action.payload),
                new notification_actions_1.NotificationShowAction({
                    type: 'success',
                    config: {
                        msg: this.translateService.instant('$I18N_API_APPLICATIONS_LIST.NOTIFICATIONS.ACTIONS.APPLICATION_CREATED', { appName: action.payload.applicationName })
                    }
                })
            ];
        }));
        this.openEditApiApplication$ = this.actions$.pipe(effects_1.ofType(actions.ApiGatewayListActionTypes.OpenEditApiApplication), operators_1.tap(() => {
            this.stateService.$state.go('api-applications-save');
        }));
        this.openEditApiApplicationCompleted$ = this.actions$.pipe(effects_1.ofType(actions.ApiGatewayListActionTypes.OpenEditApiApplicationCompleted), operators_1.switchMap((action) => {
            return [
                new actions.OpenViewApiApplicationAction(action.payload),
                new notification_actions_1.NotificationShowAction({
                    type: 'success',
                    config: {
                        msg: this.translateService.instant('$I18N_API_APPLICATIONS_LIST.NOTIFICATIONS.ACTIONS.APPLICATION_UPDATED', { appName: action.payload.applicationName })
                    }
                })
            ];
        }));
        this.openCreateApiApplicationV3$ = this.actions$.pipe(effects_1.ofType(actions.ApiGatewayListActionTypes.OpenCreateApiV3Application), operators_1.tap(() => {
            this.stateService.$state.go('api-applications-v3');
        }));
        this.openEditApiApplicationV3$ = this.actions$.pipe(effects_1.ofType(actions.ApiGatewayListActionTypes.OpenEditApiApplicationV3), operators_1.tap(() => {
            this.stateService.$state.go('api-applications-v3');
        }));
        this.openViewApiApplication$ = this.actions$.pipe(effects_1.ofType(actions.ApiGatewayListActionTypes.OpenViewApiApplication), operators_1.tap((action) => {
            this.closeViewAside();
            this.viewAside = this.asideService.open(aside_1.ApiGatewayAsideComponent, {
                size: 'md',
                hasBackdrop: true,
                data: action.payload
            });
        }));
        this.closeViewApiApplication$ = this.actions$.pipe(effects_1.ofType(actions.ApiGatewayListActionTypes.CloseViewApiApplication), operators_1.tap(() => {
            this.closeViewAside();
        }));
        this.disableApiApplication$ = this.actions$.pipe(effects_1.ofType(actions.ApiGatewayListActionTypes.DisableApiApplication), operators_1.switchMap((action) => {
            return this.apiService.disableApplication(action.payload.id).pipe(operators_1.map((response) => {
                if (!response) {
                    throw new Error();
                }
                return new actions.DisableApiApplicationSuccessAction(response);
            }), operators_1.catchError(() => rxjs_1.of(new actions.ApiApplicationFailureAction('$I18N_API_APPLICATIONS_LIST.NOTIFICATIONS.ERRORS.GENERAL'), new actions.GetAllApiApplicationsAction())));
        }));
        this.disableApiApplicationSuccess$ = this.actions$.pipe(effects_1.ofType(actions.ApiGatewayListActionTypes.DisableApiApplicationSuccess), operators_1.switchMap((action) => {
            return [
                new notification_actions_1.NotificationShowAction({
                    type: 'success',
                    config: {
                        msg: this.translateService.instant('$I18N_API_APPLICATIONS_LIST.NOTIFICATIONS.ACTIONS.APPLICATION_DISABLED', { appName: action.payload.applicationName })
                    }
                }),
                new actions.GetAllApiApplicationsAction()
            ];
        }));
        this.enableApiApplication$ = this.actions$.pipe(effects_1.ofType(actions.ApiGatewayListActionTypes.EnableApiApplication), operators_1.switchMap((action) => {
            return this.apiService.enableApplication(action.payload.id).pipe(operators_1.map((response) => {
                if (!response) {
                    throw new Error();
                }
                return new actions.EnableApiApplicationSuccessAction(response);
            }), operators_1.catchError(() => rxjs_1.of(new actions.ApiApplicationFailureAction('$I18N_API_APPLICATIONS_LIST.NOTIFICATIONS.ERRORS.GENERAL'), new actions.GetAllApiApplicationsAction())));
        }));
        this.enableApiApplicationSuccess$ = this.actions$.pipe(effects_1.ofType(actions.ApiGatewayListActionTypes.EnableApiApplicationSuccess), operators_1.switchMap((action) => {
            return [
                new notification_actions_1.NotificationShowAction({
                    type: 'success',
                    config: {
                        msg: this.translateService.instant('$I18N_API_APPLICATIONS_LIST.NOTIFICATIONS.ACTIONS.APPLICATION_ENABLED', { appName: action.payload.applicationName })
                    }
                }),
                new actions.GetAllApiApplicationsAction()
            ];
        }));
        this.confirmDeleteApiApplication$ = this.actions$.pipe(effects_1.ofType(actions.ApiGatewayListActionTypes.ConfirmDeleteApiApplication), operators_1.switchMap((action) => {
            this.closeViewAside();
            const modalConfig = {};
            modalConfig.size = 'md';
            modalConfig.hasBackdrop = true;
            modalConfig.disableClose = true;
            modalConfig.data = action.payload;
            return this.modalService
                .open(delete_confirmation_modal_1.ApiGatewayDeleteConfirmationModalComponent, modalConfig)
                .afterClose()
                .pipe(operators_1.map((result) => result ? new actions.DeleteApiApplicationAction(action.payload) : { type: 'no action' }));
        }));
        this.confirmDisableApplication$ = this.actions$.pipe(effects_1.ofType(actions.ApiGatewayListActionTypes.ConfirmDisableApiApplication), operators_1.switchMap((action) => {
            this.closeViewAside();
            const modalConfig = {};
            modalConfig.size = 'md';
            modalConfig.hasBackdrop = true;
            modalConfig.disableClose = true;
            modalConfig.data = action.payload;
            return this.modalService
                .open(disable_confirmation_modal_1.ApiGatewayDisableConfirmationModalComponent, modalConfig)
                .afterClose()
                .pipe(operators_1.map((result) => {
                switch (result.state) {
                    case disable_confirmation_modal_1.ApiGatewayDisableConfirmationState.DISABLE:
                        return new actions.DisableApiApplicationAction(action.payload);
                    case disable_confirmation_modal_1.ApiGatewayDisableConfirmationState.DELETE:
                        return new actions.DeleteApiApplicationAction(action.payload);
                    case disable_confirmation_modal_1.ApiGatewayDisableConfirmationState.CANCEL:
                    default:
                        return { type: 'no action' };
                }
            }));
        }));
        this.deleteApiApplication$ = this.actions$.pipe(effects_1.ofType(actions.ApiGatewayListActionTypes.DeleteApiApplication), operators_1.switchMap((action) => {
            return this.apiService.deleteApplication(action.payload.id).pipe(operators_1.map((response) => {
                if (!response) {
                    throw new Error();
                }
                return new actions.DeleteApiApplicationSuccessAction(action.payload);
            }), operators_1.catchError(() => rxjs_1.of(new actions.ApiApplicationFailureAction('$I18N_API_APPLICATIONS_LIST.NOTIFICATIONS.ERRORS.GENERAL'), new actions.GetAllApiApplicationsAction())));
        }));
        this.deleteApiApplicationSuccess$ = this.actions$.pipe(effects_1.ofType(actions.ApiGatewayListActionTypes.DeleteApiApplicationSuccess), operators_1.switchMap((action) => {
            return [
                new notification_actions_1.NotificationShowAction({
                    type: 'success',
                    config: {
                        msg: this.translateService.instant('$I18N_API_APPLICATIONS_LIST.NOTIFICATIONS.ACTIONS.APPLICATION_DELETED', { appName: action.payload.applicationName })
                    }
                }),
                new actions.GetAllApiApplicationsAction()
            ];
        }));
        this.openKeyGen$ = this.actions$.pipe(effects_1.ofType(actions.ApiGatewayListActionTypes.OpenKeyGen), operators_1.tap(() => {
            this.stateService.$state.go('api-applications-keygen');
        }));
        this.openKeyGenCompleted$ = this.actions$.pipe(effects_1.ofType(actions.ApiGatewayListActionTypes.OpenKeyGenCompleted), operators_1.map((action) => {
            return new actions.OpenViewApiApplicationAction(action.payload);
        }));
        this.getCredentials$ = this.actions$.pipe(effects_1.ofType(actions.ApiGatewayListActionTypes.GetCredentials), operators_1.switchMap((action) => {
            return this.apiService.getCredentials(action.payload.id).pipe(operators_1.map((response) => {
                if (!response) {
                    throw new Error();
                }
                response.applicationName = action.payload.applicationName;
                return new actions.GetCredentialsSuccessAction(response);
            }), operators_1.catchError(() => rxjs_1.of(new actions.ApiApplicationFailureAction('$I18N_API_APPLICATIONS_LIST.NOTIFICATIONS.ERRORS.GENERAL'), new actions.GetAllApiApplicationsAction())));
        }));
        this.getCredentialsSuccess$ = this.actions$.pipe(effects_1.ofType(actions.ApiGatewayListActionTypes.GetCredentialsSuccess), operators_1.tap((action) => {
            if (!this.isClientRegeneration()) {
                this.modalService.open(display_credentials_modal_1.ApiGatewayDisplayCredentialsModalComponent, {
                    size: 'xlg',
                    hasBackdrop: true,
                    disableClose: true,
                    data: action.payload
                });
            }
        }));
        this.getCredentialsSuccessOnCreate$ = this.actions$.pipe(effects_1.ofType(actions.ApiGatewayListActionTypes.GetCredentialsSuccessOnCreate), operators_1.tap((action) => {
            this.featureFlagService.isReady().then(() => {
                if (this.isClientRegeneration()) {
                    this.modalService.open(display_credentials_modal_1.ApiGatewayDisplayCredentialsModalComponent, {
                        size: 'xlg',
                        hasBackdrop: true,
                        disableClose: true,
                        data: action.payload
                    });
                }
            });
        }));
        this.getNewCredentials$ = this.actions$.pipe(effects_1.ofType(actions.ApiGatewayListActionTypes.GetNewCredentials), operators_1.switchMap((action) => {
            var _a, _b;
            return this.apiService
                .getNewCredentials({
                id: (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.id,
                products: (_b = action === null || action === void 0 ? void 0 : action.payload) === null || _b === void 0 ? void 0 : _b.products
            })
                .pipe(operators_1.map((response) => {
                if (!response) {
                    throw new Error();
                }
                response.applicationName = action.payload.applicationName;
                return new actions.GetNewCredentialsSuccessAction(response);
            }), operators_1.catchError(() => rxjs_1.of(new actions.ApiApplicationFailureAction('$I18N_API_APPLICATIONS_LIST.NOTIFICATIONS.ERRORS.GENERAL'), new actions.GetAllApiApplicationsAction())));
        }));
        this.getNewCredentialsSuccess$ = this.actions$.pipe(effects_1.ofType(actions.ApiGatewayListActionTypes.GetNewCredentialsSuccess), operators_1.tap((action) => {
            this.modalService.open(display_credentials_modal_1.ApiGatewayDisplayCredentialsModalComponent, {
                size: 'xlg',
                hasBackdrop: true,
                disableClose: true,
                data: action.payload
            });
        }));
    }
    closeViewAside() {
        if (!!this.viewAside) {
            this.viewAside.close();
            this.viewAside = undefined;
        }
    }
    isClientRegeneration() {
        return this.featureFlagService.getBooleanFlag('Adcon.Services.Applications.2.0.client.secret.Regeneration');
    }
}
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "getAllApiApplications$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "pageApiApplications$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], ListEffects.prototype, "openCreateApiApplication$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "openCreateApiApplicationCompleted$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], ListEffects.prototype, "openEditApiApplication$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "openEditApiApplicationCompleted$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], ListEffects.prototype, "openCreateApiApplicationV3$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], ListEffects.prototype, "openEditApiApplicationV3$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], ListEffects.prototype, "openViewApiApplication$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], ListEffects.prototype, "closeViewApiApplication$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "disableApiApplication$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "disableApiApplicationSuccess$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "enableApiApplication$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "enableApiApplicationSuccess$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "confirmDeleteApiApplication$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "confirmDisableApplication$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "deleteApiApplication$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "deleteApiApplicationSuccess$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], ListEffects.prototype, "openKeyGen$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "openKeyGenCompleted$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "getCredentials$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], ListEffects.prototype, "getCredentialsSuccess$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], ListEffects.prototype, "getCredentialsSuccessOnCreate$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "getNewCredentials$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], ListEffects.prototype, "getNewCredentialsSuccess$", void 0);
exports.ListEffects = ListEffects;
