<div *ngIf="currentTip"
     class="carousel panel-padding-top panel-padding-left panel-padding-right panel-double-padding-bottom panel-margin-bottom">
  <div class="row">
    <div class="col-xs-6 carousel-title"> Safety Tips</div>
    <div class="col-xs-6 text-right" [class.disabled]="disabledNavigation">
      <a href="#" class="btn-link panel-half-margin-right" (click)="previous($event)">
        <i class="far fa-angle-left"></i> Previous
      </a>
      <a href="#" class="btn-link panel-half-margin-right" (click)="next($event)">
        Next <i class="far fa-angle-right"></i>
      </a>
    </div>
  </div>
  <div class="panel-margin-top tips">
    <div class="media">
      <div class="media-left text-center">
        <i class="far" [ngClass]="currentTip.icon"></i>
      </div>
      <div class="media-body">
        {{ currentTip.openingStatement }} {{ currentTip.secondaryStatement}}
      </div>
    </div>
  </div>
  <img src="xxxHTMLLINKxxx0.0150407351824195510.037921470935089685xxx" class="phisme" *ngIf="ribbonIcon && currentTip.source === 'pishme'"/>
  <!-- resources/images/phishme_48x12.png -->
</div>
