"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OktaEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const reducers = require("app-new/api-applications/reducers");
const actions = require("app-new/api-applications/actions");
const okta_1 = require("app-new/api-applications/services/integration/okta");
const notification_actions_1 = require("app-new/components/notification/actions/notification.actions");
const uuid_1 = require("uuid");
class OktaEffects {
    constructor(actions$, stateService, store, oktaApiService, translateService, groupsApiService) {
        this.actions$ = actions$;
        this.stateService = stateService;
        this.store = store;
        this.oktaApiService = oktaApiService;
        this.translateService = translateService;
        this.groupsApiService = groupsApiService;
        // more logic to be added here when API is ready
        this.createOktaIntegration$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationOktaActionTypes.CreateOktaIntegration), operators_1.withLatestFrom(this.store.select(reducers.getIntegrationOktaIntegration)), operators_1.switchMap(([, config]) => {
            const request = this.oktaApiService.buildRequest(config, false);
            return this.oktaApiService.createOktaIntegration(request).pipe(operators_1.switchMap((response) => [
                new notification_actions_1.NotificationShowAction({
                    type: 'success',
                    config: {
                        msg: this.translateService.instant('$I18N_API_INTEGRATION_OKTA_WIZARD.SUCCESSES.CREATE')
                    }
                }),
                new actions.CloseOktaIntegrationWidgetWithApiAction(),
                new actions.CreateOktaIntegrationSuccessAction(response)
            ]), operators_1.catchError(() => rxjs_1.of(new notification_actions_1.NotificationShowAction({
                type: 'error',
                config: {
                    msg: this.translateService.instant('$I18N_API_INTEGRATION_OKTA_WIZARD.ERRORS.CREATE')
                }
            }), new actions.CancelOktaIntegrationWidgetAction(), new actions.GetAllIntegrationsAction())));
        }));
        this.closeOktaIntegrationWizard$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationOktaActionTypes.CancelOktaIntegrationWidget, actions.IntegrationOktaActionTypes.RemoveOktaAccessToken), operators_1.tap(() => {
            this.stateService.$state.go('api-applications-list', {
                displayTab: 'integrations',
                showApiModal: undefined
            });
        }));
        this.closeOktaIntegrationWithApiWizard$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationOktaActionTypes.CloseOktaIntegrationWidgetWithApi), operators_1.tap(() => {
            this.stateService.$state.go('api-applications-list', {
                displayTab: 'integrations',
                showApiModal: true
            });
        }));
        this.updateOktaIntegration$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationOktaActionTypes.UpdateOktaIntegration), operators_1.withLatestFrom(this.store.select(reducers.getIntegrationOktaIntegration), this.store.select(reducers.getRotateApiToken)), operators_1.switchMap(([, config, rotateApiToken]) => {
            const request = this.oktaApiService.buildRequest(config, rotateApiToken);
            return this.oktaApiService.updateOktaIntegration(request).pipe(operators_1.switchMap((response) => [
                new notification_actions_1.NotificationShowAction({
                    type: 'success',
                    config: {
                        msg: this.translateService.instant('$I18N_API_INTEGRATION_OKTA_WIZARD.SUCCESSES.UPDATE')
                    }
                }),
                response.accessToken
                    ? new actions.CloseOktaIntegrationWidgetWithApiAction()
                    : new actions.CancelOktaIntegrationWidgetAction(),
                new actions.UpdateOktaIntegrationSuccessAction(response)
            ]), operators_1.catchError(() => rxjs_1.of(new notification_actions_1.NotificationShowAction({
                type: 'error',
                config: {
                    msg: this.translateService.instant('$I18N_API_INTEGRATION_OKTA_WIZARD.ERRORS.UPDATE')
                }
            }), new actions.CancelOktaIntegrationWidgetAction(), new actions.GetAllIntegrationsAction())));
        }));
        this.deleteOktaIntegration$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationOktaActionTypes.DeleteOktaIntegration), operators_1.withLatestFrom(this.store.select(reducers.getIntegrationOktaIntegration)), operators_1.switchMap(([, config]) => {
            const request = this.oktaApiService.buildRequest(config, false);
            return this.oktaApiService.deleteOktaIntegration(request).pipe(operators_1.switchMap(() => [
                new notification_actions_1.NotificationShowAction({
                    type: 'success',
                    config: {
                        msg: this.translateService.instant('$I18N_API_INTEGRATION_OKTA_WIZARD.SUCCESSES.DELETE')
                    }
                }),
                new actions.DeleteOktaIntegrationSuccessAction()
            ]), operators_1.catchError(() => rxjs_1.of(new notification_actions_1.NotificationShowAction({
                type: 'error',
                config: {
                    msg: this.translateService.instant('$I18N_API_INTEGRATION_OKTA_WIZARD.ERRORS.DELETE')
                }
            }), new actions.CancelOktaIntegrationWidgetAction(), new actions.GetAllIntegrationsAction())));
        }));
        this.deleteOktaIntegrationSuccess$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationOktaActionTypes.DeleteOktaIntegrationSuccess), operators_1.tap(() => {
            this.stateService.$state.go('api-applications-list', {
                displayTab: 'integrations',
                showApiModal: undefined
            }, { reload: true, inherit: false, notify: true });
        }));
        this.setDefaultOktaFolderForNewIntegration$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationOktaActionTypes.SetDefaultOktaFolder), operators_1.switchMap(() => {
            return this.groupsApiService
                .findGroups({ source: 'ldap', query: okta_1.defaults.ROOT_FOLDER_DESCRIPTION })
                .pipe(operators_1.map((response) => {
                if (response && response.length > 0) {
                    return new actions.SetDefaultOktaFolderSuccessAction(response[0]);
                }
                return new actions.SetDefaultOktaFolderFailureAction();
            }));
        }));
        this.setDefaultOktaFolderForNewIntegrationSuccess$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationOktaActionTypes.SetDefaultOktaFolderSuccess), operators_1.switchMap((action) => {
            return [
                new actions.AddExtractedLdapFoldersAction([action.scimFolder]),
                new actions.SelectOktaIntegrationRootFolderAction(action.scimFolder)
            ];
        }));
        this.setDefaultOktaFolderForNewIntegrationFailure$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationOktaActionTypes.SetDefaultOktaFolderFailure), operators_1.switchMap(() => {
            const newFolder = {
                id: uuid_1.v4(),
                description: okta_1.defaults.ROOT_FOLDER_DESCRIPTION,
                source: 'ldap',
                parentId: null,
                userCount: 0,
                folderCount: 0,
                folders: null
            };
            return [
                new actions.AddExtractedLdapFoldersAction([newFolder]),
                new actions.SelectOktaIntegrationRootFolderAction(newFolder)
            ];
        }));
    }
}
__decorate([
    effects_1.Effect()
], OktaEffects.prototype, "createOktaIntegration$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], OktaEffects.prototype, "closeOktaIntegrationWizard$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], OktaEffects.prototype, "closeOktaIntegrationWithApiWizard$", void 0);
__decorate([
    effects_1.Effect()
], OktaEffects.prototype, "updateOktaIntegration$", void 0);
__decorate([
    effects_1.Effect()
], OktaEffects.prototype, "deleteOktaIntegration$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], OktaEffects.prototype, "deleteOktaIntegrationSuccess$", void 0);
__decorate([
    effects_1.Effect()
], OktaEffects.prototype, "setDefaultOktaFolderForNewIntegration$", void 0);
__decorate([
    effects_1.Effect()
], OktaEffects.prototype, "setDefaultOktaFolderForNewIntegrationSuccess$", void 0);
__decorate([
    effects_1.Effect()
], OktaEffects.prototype, "setDefaultOktaFolderForNewIntegrationFailure$", void 0);
exports.OktaEffects = OktaEffects;
