"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectorDetailsComponent = void 0;
const store_1 = require("@ngrx/store");
const reducers = require("../../reducers");
const rxjs_1 = require("rxjs");
const connector_actions_1 = require("../../actions/connector.actions");
const operators_1 = require("rxjs/operators");
const delete_connector_modal_component_1 = require("../delete-connector-modal/delete-connector-modal.component");
class ConnectorDetailsComponent {
    constructor(overlayRef, store, stateService, modalService, translate, featureFlagService, coreService) {
        this.overlayRef = overlayRef;
        this.store = store;
        this.stateService = stateService;
        this.modalService = modalService;
        this.translate = translate;
        this.featureFlagService = featureFlagService;
        this.coreService = coreService;
        this.destroy$ = new rxjs_1.Subject();
    }
    ngOnInit() {
        const ready$ = rxjs_1.from(this.featureFlagService.isReady());
        this.supportLink$ = ready$.pipe(operators_1.switchMap(() => this.coreService.getAccountSwitchSession().pipe(operators_1.take(1))), operators_1.map(switchedSession => {
            const hasConfigEnabled = this.featureFlagService.getBooleanFlag('rollout-zendesk-contact-support');
            const encodedAccountCode = switchedSession && switchedSession.accountcode
                ? `?ac=${encodeURIComponent(btoa(switchedSession.accountcode))}`
                : '';
            return hasConfigEnabled
                ? `/apps#/sso/support${encodedAccountCode}`
                : '/apps#sso/mcentral?goto=/s/contactsupport';
        }));
        this.connectorDetails$ = this.store.pipe(store_1.select(reducers.getConnectorDetails));
        this.connectorDetails$.pipe(operators_1.takeUntil(this.destroy$)).subscribe(connectorDetails => {
            this.connectorDetails = connectorDetails;
            this.translatedProvider =
                connectorDetails && connectorDetails.provider
                    ? this.translate.instant(`$I18N_CONNECTORS.PROVIDERS.${connectorDetails.provider.toUpperCase()}`)
                    : '';
        });
        this.loadingDetails$ = this.store.pipe(store_1.select(reducers.loadingConnectorDetails));
        this.pagination$ = this.store.pipe(store_1.select(reducers.getAsidePagination));
    }
    closeAside() {
        this.overlayRef.close();
    }
    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
    showMessage($event) {
        // @ts-ignore
        this.store.dispatch(new connector_actions_1.GetConnectorDetails($event.token));
    }
    editConnector() {
        this.closeAside();
        this.stateService.$state.go('services-edit-connector-page', Object.assign({}, this.connectorDetails));
    }
    deleteConnector() {
        this.store.dispatch(new connector_actions_1.GetConnectorUsageAction(this.connectorDetails.id));
        this.closeAside();
        const data = {
            connectorName: this.connectorDetails.name,
            provider: this.connectorDetails.provider
        };
        const modalConfig = {};
        modalConfig.size = 'md';
        modalConfig.hasBackdrop = true;
        modalConfig.disableClose = true;
        modalConfig.data = data;
        this.modalService
            .open(delete_connector_modal_component_1.DeleteConnectorModalComponent, modalConfig)
            .afterClose()
            .pipe(operators_1.take(1))
            .subscribe((confirmed) => {
            if (confirmed) {
                this.store.dispatch(new connector_actions_1.DeleteConnectorAction({ id: this.connectorDetails.id }));
            }
            else {
                this.store.dispatch(new connector_actions_1.GetConnectorDetails(this.connectorDetails));
            }
        });
    }
}
exports.ConnectorDetailsComponent = ConnectorDetailsComponent;
