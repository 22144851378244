"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEndpointsListPurged = exports.getPurgeEndpointsListCount = exports.reducer = exports.initialState = void 0;
const endpoints_list_actions_1 = require("../actions/endpoints-list.actions");
exports.initialState = {
    purgeEndpointsList: [],
    purgeEndpointsListDone: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case endpoints_list_actions_1.SwgEndpointsListActions.PURGE_ENDPOINTS_LIST:
            return Object.assign(Object.assign({}, state), { purgeEndpointsList: action.payload, purgeEndpointsListDone: false });
        case endpoints_list_actions_1.SwgEndpointsListActions.PURGE_ENDPOINTS_LIST_SUCCESS:
            return Object.assign(Object.assign({}, state), { purgeEndpointsList: [], purgeEndpointsListDone: true });
        case endpoints_list_actions_1.SwgEndpointsListActions.PURGE_ENDPOINTS_LIST_FAILURE:
            return Object.assign(Object.assign({}, state), { purgeEndpointsList: [], purgeEndpointsListDone: false });
        case endpoints_list_actions_1.SwgEndpointsListActions.PURGE_ENDPOINTS_LIST_CONFIRM:
            return Object.assign(Object.assign({}, state), { purgeEndpointsList: action.payload, purgeEndpointsListDone: false });
        case endpoints_list_actions_1.SwgEndpointsListActions.ENDPOINTS_LIST_LOADED_POST_PURGE:
            return Object.assign(Object.assign({}, state), { purgeEndpointsList: [], purgeEndpointsListDone: false });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getPurgeEndpointsListCount = (state) => state.purgeEndpointsList.length;
exports.getPurgeEndpointsListCount = getPurgeEndpointsListCount;
const getEndpointsListPurged = (state) => state.purgeEndpointsListDone;
exports.getEndpointsListPurged = getEndpointsListPurged;
