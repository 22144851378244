'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
angular.module('url-protection-chart-data-formatter.service', [])
    .factory('urlProtectionChartDataFormatterService', ['dashboardCommonsService', function (dashboardCommonsService) {
        return {
            groupUrlClickCountsPerDay,
            groupUrlClickCountsPerUrl,
            groupUrlClickCountsPerUser
        };
        function groupUrlClickCountsPerDay(data) {
            return {
                inboundClean: dashboardCommonsService.getUrlCountPerGroup(data.first.clickCountsPerDay.routing[0].scansPerDay[0].dayCounts),
                inboundMalicious: dashboardCommonsService.getUrlCountPerGroup(data.first.clickCountsPerDay.routing[0].scansPerDay[1].dayCounts),
                outboundClean: dashboardCommonsService.getUrlCountPerGroup(data.first.clickCountsPerDay.routing[1].scansPerDay[0].dayCounts),
                outboundMalicious: dashboardCommonsService.getUrlCountPerGroup(data.first.clickCountsPerDay.routing[1].scansPerDay[1].dayCounts),
                internalClean: dashboardCommonsService.getUrlCountPerGroup(data.first.clickCountsPerDay.routing[2].scansPerDay[0].dayCounts),
                internalMalicious: dashboardCommonsService.getUrlCountPerGroup(data.first.clickCountsPerDay.routing[2].scansPerDay[1].dayCounts)
            };
        }
        function groupUrlClickCountsPerUrl(data) {
            return {
                inboundClean: dashboardCommonsService.getCategoriesAndSeries(data.first.clickCountsPerUrl.routing[0].scansPerAttribute[0].attributeCounts, true),
                inboundMalicious: dashboardCommonsService.getCategoriesAndSeries(data.first.clickCountsPerUrl.routing[0].scansPerAttribute[1].attributeCounts, true),
                outboundClean: dashboardCommonsService.getCategoriesAndSeries(data.first.clickCountsPerUrl.routing[1].scansPerAttribute[0].attributeCounts, true),
                outboundMalicious: dashboardCommonsService.getCategoriesAndSeries(data.first.clickCountsPerUrl.routing[1].scansPerAttribute[1].attributeCounts, true),
                internalClean: dashboardCommonsService.getCategoriesAndSeries(data.first.clickCountsPerUrl.routing[2].scansPerAttribute[0].attributeCounts, true),
                internalMalicious: dashboardCommonsService.getCategoriesAndSeries(data.first.clickCountsPerUrl.routing[2].scansPerAttribute[1].attributeCounts, true)
            };
        }
        function groupUrlClickCountsPerUser(data) {
            return {
                inboundClean: dashboardCommonsService.getCategoriesAndSeries(data.first.clickCountsPerUser.routing[0].scansPerAttribute[0].attributeCounts, false),
                inboundMalicious: dashboardCommonsService.getCategoriesAndSeries(data.first.clickCountsPerUser.routing[0].scansPerAttribute[1].attributeCounts, false),
                outboundClean: dashboardCommonsService.getCategoriesAndSeries(data.first.clickCountsPerUser.routing[1].scansPerAttribute[0].attributeCounts, false),
                outboundMalicious: dashboardCommonsService.getCategoriesAndSeries(data.first.clickCountsPerUser.routing[1].scansPerAttribute[1].attributeCounts, false),
                internalClean: dashboardCommonsService.getCategoriesAndSeries(data.first.clickCountsPerUser.routing[2].scansPerAttribute[0].attributeCounts, false),
                internalMalicious: dashboardCommonsService.getCategoriesAndSeries(data.first.clickCountsPerUser.routing[2].scansPerAttribute[1].attributeCounts, false)
            };
        }
    }]);
