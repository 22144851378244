"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChangeType = exports.ConsentErrorCode = exports.ErrorType = exports.ProviderReleaseState = exports.ProviderType = exports.ConnectorStatus = void 0;
var ConnectorStatus;
(function (ConnectorStatus) {
    ConnectorStatus["CONFIGURED"] = "configured";
    ConnectorStatus["CONNECTED"] = "connected";
    ConnectorStatus["ERROR"] = "error";
    ConnectorStatus["WARNING"] = "warning";
})(ConnectorStatus = exports.ConnectorStatus || (exports.ConnectorStatus = {}));
var ProviderType;
(function (ProviderType) {
    ProviderType["ONE_DRIVE"] = "one_drive";
    ProviderType["ONEDRIVE"] = "onedrive";
    ProviderType["MS_TEAMS"] = "ms_teams";
    ProviderType["MS_TEAMS_GCC"] = "ms_teams_gcc";
    ProviderType["BOX"] = "box";
    ProviderType["OFFICE_365"] = "office_365";
    ProviderType["OFFICE_365_GCC"] = "office_365_gcc";
    ProviderType["EXCHANGE_WEB_SERVICES"] = "exchange_web_services";
    ProviderType["GOOGLE_WORKSPACE"] = "google_workspace";
    ProviderType["AZURE_STANDARD"] = "azure_standard";
    ProviderType["AZURE_GCC"] = "azure_gcc";
})(ProviderType = exports.ProviderType || (exports.ProviderType = {}));
var ProviderReleaseState;
(function (ProviderReleaseState) {
    ProviderReleaseState["GENERAL_AVAILABILITY"] = "general_availability";
})(ProviderReleaseState = exports.ProviderReleaseState || (exports.ProviderReleaseState = {}));
var ErrorType;
(function (ErrorType) {
    ErrorType["PRODUCTS"] = "products";
    ErrorType["PROVIDERS"] = "providers";
})(ErrorType = exports.ErrorType || (exports.ErrorType = {}));
var ConsentErrorCode;
(function (ConsentErrorCode) {
    ConsentErrorCode["UNKNOWN"] = "unknown_error";
    ConsentErrorCode["CONSENT_CONFIG"] = "err_consent_config";
    ConsentErrorCode["INVALID_TOKEN"] = "invalid_token";
    ConsentErrorCode["PERMISSION_NOT_GRANTED"] = "permission_not_granted";
})(ConsentErrorCode = exports.ConsentErrorCode || (exports.ConsentErrorCode = {}));
var ChangeType;
(function (ChangeType) {
    ChangeType["PRODUCT"] = "product";
    ChangeType["PROVIDER"] = "provider";
})(ChangeType = exports.ChangeType || (exports.ChangeType = {}));
