"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccessProviderMsCallbackComponent = void 0;
class AccessProviderMsCallbackComponent {
    constructor(windowService) {
        this.windowService = windowService;
    }
    ngOnInit() {
        const urlParams = new URLSearchParams(window.location.search);
        const queryParams = {
            authToken: urlParams.get('authToken'),
            errorCode: urlParams.get('errorCode'),
            state: urlParams.get('clientState')
        };
        setTimeout(() => {
            this.windowService.close();
        });
        this.windowService.postMessage(queryParams, window.location.origin);
    }
}
exports.AccessProviderMsCallbackComponent = AccessProviderMsCallbackComponent;
