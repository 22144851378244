<div class="panel-padding-top">
    <mc-release-logs-table
      [rows]="items$ | async"
      [externalTableSearchCount]="totalCount$ | async"
      [selectedRow]="selectedRow$ | async"
      [isLoading]="isLoading$ | async"
      [metadata]="metadata$ | async"
      (onSearchFilterChanged)="search($event)"
      (onDateFilterChanged)="dateFilterChanged($event)"
      (onColumnFilterChanged)="columnFilterChanged($event)"
      (onPaginatorChanged)="paginatorChanged($event)"
      (onRowClicked)="rowClicked($event)"
      (onReleaseLogsExported)="resultsExported($event)">
    </mc-release-logs-table>
</div>