"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AffectedUsersByLocationsSidebarComponent = void 0;
const forms_1 = require("@angular/forms");
const reducers = require("../reducers");
const applies_to_locations_actions_1 = require("../actions/applies-to-locations.actions");
const user_location_sidebar_actions_1 = require("../actions/user-location-sidebar.actions");
class AffectedUsersByLocationsSidebarComponent {
    constructor(store, fb, overlayData) {
        this.store = store;
        this.fb = fb;
        this.overlayData = overlayData;
        this.placeholder = '$I18N_ADMINISTRATION_COMMONS_FILTER_SEARCHBOX_PLACEHOLDER';
        this._selectedLocation = { description: '', ipRange: '', timeZone: '' };
        this.paginator = {
            pageToken: null,
            pageSize: 20,
            pageNumber: 0
        };
        this.separator = ' - ';
        this.pageIterator = 0;
        this.searchQuery = null;
    }
    ngOnInit() {
        this.store.dispatch(new applies_to_locations_actions_1.LoadAppliesToLocations({
            meta: { pagination: this.paginator },
            query: null
        }));
        this.locations$ = this.store.select(reducers.getLocations);
        this.pagination$ = this.store.select(reducers.getLocationsPagination);
        this.apiIsProcessing$ = this.store.select(reducers.getLocationsLoading);
        this.locationsForm = this.fb.group({
            searchQuery: ['', forms_1.Validators.required]
        });
        this.locationsFormSubscription = this.locationsForm.valueChanges.subscribe((newValue) => {
            this.searchQuery = newValue.searchQuery === '' ? null : newValue.searchQuery;
            this.pageIterator = 0;
            this.store.dispatch(new applies_to_locations_actions_1.LoadAppliesToLocationsPaginationReset());
            this.store.dispatch(new applies_to_locations_actions_1.LoadAppliesToLocations({
                meta: { pagination: this.paginator },
                query: this.searchQuery
            }));
        });
    }
    selectLocation(row) {
        this._selectedLocation = row;
        this.store.dispatch(this.overlayData.action(row));
    }
    close() {
        this.store.dispatch(new user_location_sidebar_actions_1.UserLocationAppliesToClose());
    }
    onPaginate(paginatorData) {
        const pageIndex = paginatorData.direction === 1
            ? (this.pageIterator += this.paginator.pageSize)
            : (this.pageIterator -= this.paginator.pageSize);
        const paginatorOutput = {
            pageToken: paginatorData.token,
            pageSize: this.paginator.pageSize,
            pageNumber: pageIndex
        };
        const payload = {
            meta: { pagination: paginatorOutput },
            query: this.searchQuery
        };
        this.store.dispatch(new applies_to_locations_actions_1.LoadAppliesToLocationsPagination(payload));
    }
    ngOnDestroy() {
        this.store.dispatch(new applies_to_locations_actions_1.ClearAppliesToLocations());
        this.locationsFormSubscription.unsubscribe();
        this.pageIterator = 0;
    }
}
exports.AffectedUsersByLocationsSidebarComponent = AffectedUsersByLocationsSidebarComponent;
