"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidatingSearchQuery = exports.getValidationError = exports.getLexiconDetails = exports.getFailure = exports.getLoading = exports.getSaving = exports.reducer = void 0;
const lexicon_actions_1 = require("../actions/lexicon.actions");
const initialState = {
    saving: false,
    loading: false,
    isValidatingSearchQuery: false,
    apiValidationError: undefined
};
function reducer(state = initialState, action) {
    switch (action.type) {
        case lexicon_actions_1.LexiconWizardActions.CREATE:
            return Object.assign(Object.assign({}, state), { saving: true });
        case lexicon_actions_1.LexiconWizardActions.UPDATE:
            return Object.assign(Object.assign({}, state), { saving: true });
        case lexicon_actions_1.LexiconWizardActions.CREATE_UPDATE_SUCCESS:
            return Object.assign(Object.assign({}, state), { saving: false, failure: undefined });
        case lexicon_actions_1.LexiconWizardActions.CREATE_UPDATE_FAIL:
            return Object.assign(Object.assign({}, state), { saving: false, failure: Object.assign({}, action.failure) });
        case lexicon_actions_1.LexiconWizardActions.GET:
            return Object.assign(Object.assign({}, state), { loading: true });
        case lexicon_actions_1.LexiconWizardActions.GET_SUCCESS:
            return Object.assign(Object.assign({}, state), { loading: false, lexiconDetails: Object.assign({}, action.lexiconDetails), failure: undefined });
        case lexicon_actions_1.LexiconWizardActions.GET_FAIL:
            return Object.assign(Object.assign({}, state), { loading: false, lexiconDetails: undefined, failure: Object.assign({}, action.payload) });
        case lexicon_actions_1.LexiconWizardActions.CLEAN_LEXICON_DETAILS:
            return Object.assign(Object.assign({}, state), { loading: false, lexiconDetails: undefined, failure: undefined });
        case lexicon_actions_1.LexiconWizardActions.VALIDATE_SEARCH_QUERY:
            return Object.assign(Object.assign({}, state), { isValidatingSearchQuery: true });
        case lexicon_actions_1.LexiconWizardActions.VALIDATE_SEARCH_QUERY_SUCCESS:
            return Object.assign(Object.assign({}, state), { isValidatingSearchQuery: false, apiValidationError: null });
        case lexicon_actions_1.LexiconWizardActions.VALIDATE_SEARCH_QUERY_FAIL:
            return Object.assign(Object.assign({}, state), { isValidatingSearchQuery: false, apiValidationError: action.payload });
        case lexicon_actions_1.LexiconWizardActions.RESET_SEARCH_QUERY_VALIDATION:
            return Object.assign(Object.assign({}, state), { isValidatingSearchQuery: false, apiValidationError: undefined });
        default:
            return Object.assign({}, state);
    }
}
exports.reducer = reducer;
const getSaving = (state) => state && state.saving;
exports.getSaving = getSaving;
const getLoading = (state) => state && state.loading;
exports.getLoading = getLoading;
const getFailure = (state) => state && state.failure;
exports.getFailure = getFailure;
const getLexiconDetails = (state) => state && state.lexiconDetails;
exports.getLexiconDetails = getLexiconDetails;
const getValidationError = (state) => state && state.apiValidationError;
exports.getValidationError = getValidationError;
const isValidatingSearchQuery = (state) => state && state.isValidatingSearchQuery;
exports.isValidatingSearchQuery = isValidatingSearchQuery;
