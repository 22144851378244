<mc-layout-list-table
  keyTitle="$18N_ATTRIBUTES_DIRECTORIES_LIST.TITLE"
  keyDescription="$18N_ATTRIBUTES_DIRECTORIES_LIST.DESCRIPTION"
  [isDataLoaded]="true"
>
  <mc-help-container>
    <mc-help-link url="{{ KB_URL }}"></mc-help-link>
  </mc-help-container>

  <mc-table
    [data]="tableData$ | async"
    [columns]="columnList"
    [isLoading]="isLoading$ | async"
    (rowClick)="onRowClick($event)"
    [highlightedRow]="selectedRow$ | async"
    translatePrefix="$18N_ATTRIBUTES_DIRECTORIES_LIST"
  >
    <button
      *ngIf="isEditable"
      class="mc-table-actions btn btn-primary mc-create-button"
      (click)="onOpenCreateAttribute($event)"
    >
      {{ '$18N_ATTRIBUTES_DIRECTORIES_LIST.ADD_ATTRIBUTE' | translate }}
    </button>

    <mc-filters
      [metadata]="metaData$ | async"
      (paginatorChange)="onPaginationChange($event)"
      [externalTableSearchCount]="externalTableSearchCount$ | async"
    >
      <div>
        <mc-filters-bundle-column-values-search-only
          class="mcc-filter-search-box"
          (filtersChange)="onSearch($event)"
          placeholder="$18N_ATTRIBUTES_DIRECTORIES_LIST.FILTERS.SEARCH_FIELD.PLACEHOLDER"
        >
        </mc-filters-bundle-column-values-search-only>
      </div>
    </mc-filters>

    <mc-column key="status">
      <mc-body-cell *mcBodyCellDef="let row">
        <span>{{ getStatusKey(row.promptType) | translate }}</span>
      </mc-body-cell>
    </mc-column>

    <mc-column key="promptType">
      <mc-body-cell *mcBodyCellDef="let row">{{
        getTranslationKey(row.promptType) | translate
      }}</mc-body-cell>
    </mc-column>

    <mc-column-actions key="column-actions">
      <mc-row-actions *mcRowActions="let row">
        <li
          mcRowAction="$18N_ATTRIBUTES_DIRECTORIES_LIST.CONTEXTUAL_MENU.OPTIONS.EDIT"
          (click)="onRowClick(row)"
        ></li>
        <li
          *ngIf="!isDisabledAttribute(row.promptType)"
          (click)="disableAttribute(row)"
          mcRowAction="$18N_ATTRIBUTES_DIRECTORIES_LIST.CONTEXTUAL_MENU.OPTIONS.DISABLE"
        ></li>
        <li mcRowActionSeparator></li>
        <li
          mcRowAction="$18N_ATTRIBUTES_DIRECTORIES_LIST.CONTEXTUAL_MENU.OPTIONS.DELETE"
          (click)="deleteAttribute(row)"
        ></li>
      </mc-row-actions>
    </mc-column-actions>

    <mc-empty-results
      keyTitle="$18N_ATTRIBUTES_DIRECTORIES_LIST.ERRORS.NO_RESULTS"
    ></mc-empty-results>
  </mc-table>
</mc-layout-list-table>
