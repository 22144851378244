"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.monitorDomainIsProcessing = exports.blockSenderIsProcessing = exports.permitSenderIsProcessing = exports.logDetailsIsLoading = exports.logDetailsIsVisible = exports.getLogDetails = exports.reducers = void 0;
const logDetailsReducer = require("./log-details/log-details.reducer");
const store_1 = require("@ngrx/store");
exports.reducers = {
    logDetails: logDetailsReducer.reducer
};
const getTtpImpersonationLogsState = store_1.createFeatureSelector('ttpImpersonationLogs');
const ɵ0 = (state) => state.logDetails;
exports.ɵ0 = ɵ0;
const getLogDetailsState = store_1.createSelector(getTtpImpersonationLogsState, ɵ0);
exports.getLogDetails = store_1.createSelector(getLogDetailsState, logDetailsReducer.getLogDetails);
exports.logDetailsIsVisible = store_1.createSelector(getLogDetailsState, logDetailsReducer.logDetailsIsVisible);
exports.logDetailsIsLoading = store_1.createSelector(getLogDetailsState, logDetailsReducer.logDetailsIsLoading);
exports.permitSenderIsProcessing = store_1.createSelector(getLogDetailsState, logDetailsReducer.permitSenderIsProcessing);
exports.blockSenderIsProcessing = store_1.createSelector(getLogDetailsState, logDetailsReducer.blockSenderIsProcessing);
exports.monitorDomainIsProcessing = store_1.createSelector(getLogDetailsState, logDetailsReducer.monitorDomainIsProcessing);
