"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgActivityReportContainerComponent = void 0;
const swg_activity_report_actions_1 = require("app-new/swg/activity-report/actions/swg-activity-report.actions");
const reducers_1 = require("app-new/swg/activity-report/reducers");
const notification_actions_1 = require("../../../../components/notification/actions/notification.actions");
class SwgActivityReportContainerComponent {
    constructor(capabilitiesService, swgService, store) {
        this.capabilitiesService = capabilitiesService;
        this.swgService = swgService;
        this.store = store;
    }
    ngOnInit() {
        this.hasTTPURLPolicySupport$ = this.capabilitiesService.hasCapability('Permission.SERVICES_TTP_URL_PROTECT_READ');
        this.supervisedUserEnabled = this.swgService.hasSupervisedUserEnabled();
        this.hasHybridProxySupport = this.swgService.hasHybridProxySupport();
        this.hasSwgHybrid$ = this.capabilitiesService.hasCapability('SWG.Hybrid.Proxy');
        this.hasSwgFull = this.swgService.hasWebSecurityAccountPackage();
        this.hasPrivateIpSupport = this.swgService.hasPrivateIpReportSupport();
        this.hasBrowserIsolationEnabled = this.swgService.hasBrowserIsolationEnabled();
        this.store.dispatch(new swg_activity_report_actions_1.SwgActivityReportGetCategoriesAction());
        this.categories$ = this.store.select(reducers_1.getActivityReportCategories);
        this.isLoading$ = this.store.select(reducers_1.getActivityReportIsLoading);
        this.hasApplicationVisibility = this.swgService.hasApplicationVisibilityEnabled();
        this.store.dispatch(new swg_activity_report_actions_1.SwgActivityReportGetApplicationsAction());
        this.applications$ = this.store.select(reducers_1.getActivityReportApplications);
        this.hasDirectIpFiltersSwitchEnabled = this.swgService.hasDirectIPFiltersEnabled();
        this.hasDirectIpProtectionModeSwitchEnabled = this.swgService.hasDirectIpProtectionModeSwitchEnabled();
    }
    showNotification(msg) {
        this.store.dispatch(new notification_actions_1.NotificationShowAction({
            type: 'info',
            config: {
                msg
            }
        }));
    }
}
exports.SwgActivityReportContainerComponent = SwgActivityReportContainerComponent;
