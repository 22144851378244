<div class="mc-custom-domain-block-wizard-step-container">
  <mc-wizard-step-header
    class="mc-supervision-wizard-step-header"
    label="$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.APPLIES_TO_STEP.DESCRIPTION"
  >
  </mc-wizard-step-header>

  <form
    class="form-horizontal"
    mcDefaultLabelClass="col-sm-3"
    mcDefaultControlContainerClass="col-sm-5"
    [formGroup]="form"
  >
  <div class="form-group">
    <mc-field
      data-test="appliesTo"
      label="{{ '$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.APPLIES_TO_STEP.APPLIES_TO.TITLE' | translate }}">  

    <div class="radio panel-half-padding-bottom" *ngFor="let option of appliesToOptions">
      <label class="text-bold">
        <input 
          type="radio"
          name="appliesTo"
          formControlName="appliesTo"
          [value]="option.toLowerCase()"
        />
        {{
          '$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.APPLIES_TO_STEP.APPLIES_TO.' + option | translate
        }}
        <div *ngIf="option.toLowerCase() === 'profile_group' && form.controls.appliesTo?.value === 'profile_group'">
          <input type="hidden" formControlName="profileGroup" />
          <button
            type="button" 
            id="profileGroup" 
            class="btn btn-default"
            (click)="openNotifyToSidePanel.emit('profileGroup')">
            {{ '$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.ACTIONS_STEP.NOTIFICATIONS.SELECT' | translate }}
            </button>
          <div formArrayName="profileGroup" class="profileGroup" *ngIf="form.controls.profileGroup.value">
            <div class="selected-group">
              <span class="groups-list">
                <div class="groups-name">{{ form.controls.profileGroup.value.description }}</div>
                <i class="far fa-trash-alt" aria-hidden="true" (click)="hideCurrentGroup()"></i>
              </span>
              <hr class="groups-separator" />
            </div>
          </div>  
        </div>
      </label>
    </div>    
    </mc-field>
    </div>  
  </form>
  <mc-errors
    class=""
    [errors]="form.errors"
    *ngIf="!form.valid"
    formPrefix="$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.VALIDATION.FORM"
  ></mc-errors>
</div>
