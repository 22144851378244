'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
/**
 * Created by iaggarwal on 06/03/2017.
 */
angular.module('mc-dropdown-header-footer.directive', [])
    .directive('mcDropdownHeaderFooter', [function () {
        return {
            restrict: 'AE',
            templateUrl: 'components/mc-dropdown/mc-dropdown-header-footer.tpl.html',
            transclude: true,
            require: ['^mcDropdown'],
            replace: true,
            scope: {
                isDropdownOpenOnClick: '@isDropdownOpenOnClick'
            },
            link($scope, $element, $attr, controller) {
                const mcDropdownCtrl = controller[0];
                $scope.applyIsOpenOnClick = applyIsOpenOnClick;
                function applyIsOpenOnClick() {
                    mcDropdownCtrl.isOpen = $scope.isDropdownOpenOnClick === 'true';
                }
            },
            controllerAs: 'mcDropdownHeaderFooterCtrl'
        };
    }]);
