
    <form
      class="navbar-form navbar-left"
      role="search"
      novalidate
      (ngSubmit)="onSubmit()"
      [formGroup]="form"
    >
      <div class="input-group add-on">
        <input
          type="text"
          class="form-control"
          [placeholder]="placeholder"
          formControlName="searchName"
        />
        <div class="input-group-btn">
          <button class="btn btn-white mc-search-box-button" type="submit">
            <i class="glyphicon glyphicon-search"></i>
          </button>
        </div>
      </div>
      <span
        class="far fa-times mc-search-clear"
        *ngIf="this.form.value.searchName.length"
        (click)="clearSearchNameAndSubmit()"
      ></span>
    </form>
  