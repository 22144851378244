"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TreeService = void 0;
class TreeService {
    constructor(treeApiService) {
        this.treeApiService = treeApiService;
    }
    getMailboxFolders(contentType, depth, mailbox, viewType, parentId, meta) {
        const PAYLOAD = {
            contentType,
            depth,
            mailbox,
            viewType
        };
        if (parentId) {
            PAYLOAD['parentId'] = parentId;
        }
        if (meta) {
            PAYLOAD['meta'] = meta;
        }
        return this.treeApiService.getMailboxFolders(PAYLOAD);
    }
    getMailboxFoldersPerLevel(contentType, mailbox, viewType, parentId, snapshotId, skipRootFolder, meta) {
        const PAYLOAD = {
            mailbox,
            viewType
        };
        if (contentType === 'MESSAGE') {
            PAYLOAD['contentType'] = contentType;
        }
        if (parentId) {
            PAYLOAD['parentId'] = parentId;
        }
        if (snapshotId) {
            PAYLOAD['snapshotId'] = snapshotId;
        }
        if (skipRootFolder) {
            PAYLOAD['skipRootFolder'] = skipRootFolder;
        }
        if (meta) {
            PAYLOAD['meta'] = meta;
        }
        return this.treeApiService.getMailboxFoldersPerLevel(PAYLOAD);
    }
    findMailboxFolders(contentType, depth, mailbox, viewType, parentId, snapshotId, meta, query) {
        const PAYLOAD = {
            contentType,
            depth,
            mailbox,
            viewType
        };
        if (parentId) {
            PAYLOAD['parentId'] = parentId;
        }
        if (snapshotId) {
            PAYLOAD['snapshotId'] = snapshotId;
        }
        if (meta) {
            PAYLOAD['meta'] = meta;
        }
        if (query) {
            PAYLOAD['query'] = query;
        }
        return this.treeApiService.findMailboxFolders(PAYLOAD);
    }
    getMailboxContentEmails(folderId, mailbox, viewType, snapshotId, meta) {
        const PAYLOAD = {
            folderId,
            mailbox,
            viewType
        };
        if (snapshotId) {
            PAYLOAD['snapshotId'] = snapshotId;
        }
        if (meta) {
            PAYLOAD['meta'] = meta;
        }
        return this.treeApiService.getMailboxFolderEmails(PAYLOAD);
    }
    searchMailboxFolderEmails(admin, timeZone, type, query, mailbox, folderId, viewType, snapshotId, pageNumber, meta) {
        const PAYLOAD = {
            admin,
            timeZone,
            type,
            viewType,
            snapshotId,
            query: this.prepareMuseQuery(mailbox, folderId, query, pageNumber)
        };
        if (meta) {
            PAYLOAD['meta'] = meta;
        }
        return this.treeApiService.searchMailboxFolderEmails(PAYLOAD);
    }
    searchMailboxFolderCalendars(query, mailbox, folderId, viewType, snapshotId, meta) {
        const PAYLOAD = {
            mailbox,
            folderId,
            viewType,
            query
        };
        if (snapshotId) {
            PAYLOAD['snapshotId'] = snapshotId;
        }
        if (meta) {
            PAYLOAD['meta'] = meta;
        }
        return this.treeApiService.searchMailboxFolderCalendars(PAYLOAD);
    }
    getMailboxContentContacts(folderId, mailbox, viewType, snapshotId, meta) {
        const PAYLOAD = {
            folderId,
            mailbox,
            viewType
        };
        if (snapshotId) {
            PAYLOAD['snapshotId'] = snapshotId;
        }
        if (meta) {
            PAYLOAD['meta'] = meta;
        }
        return this.treeApiService.getMailboxFolderContacts(PAYLOAD);
    }
    searchMailboxFolderContacts(query, mailbox, folderId, viewType, snapshotId, meta) {
        const PAYLOAD = {
            mailbox,
            folderId,
            viewType,
            query
        };
        if (snapshotId) {
            PAYLOAD['snapshotId'] = snapshotId;
        }
        if (meta) {
            PAYLOAD['meta'] = meta;
        }
        return this.treeApiService.searchMailboxFolderContacts(PAYLOAD);
    }
    getMailboxContentCalendars(folderId, mailbox, viewType, snapshotId, meta) {
        const PAYLOAD = {
            folderId,
            mailbox,
            viewType
        };
        if (snapshotId) {
            PAYLOAD['snapshotId'] = snapshotId;
        }
        if (meta) {
            PAYLOAD['meta'] = meta;
        }
        return this.treeApiService.getMailboxFolderCalendars(PAYLOAD);
    }
    getOneDriveFolders(owner, depth, viewType, query, parentId) {
        const PAYLOAD = {
            owner,
            depth,
            viewType
        };
        if (query) {
            PAYLOAD['query'] = query;
        }
        if (parentId) {
            PAYLOAD['parentId'] = parentId;
        }
        return this.treeApiService.getOneDriveFolders(PAYLOAD);
    }
    getOneDriveFoldersFiles(owner, parentId, viewType, sortBy, sortOrder) {
        const PAYLOAD = {
            owner,
            viewType
        };
        if (sortBy) {
            PAYLOAD['sortBy'] = sortBy;
        }
        if (sortOrder) {
            PAYLOAD['sortOrder'] = sortOrder;
        }
        if (parentId) {
            PAYLOAD['parentId'] = parentId;
        }
        return this.treeApiService.getOneDriveFoldersFiles(PAYLOAD);
    }
    prepareMuseQuery(mailbox, folderId, query, pageNumber) {
        const PAGE_SIZE = 100;
        const PAGE_ROW = PAGE_SIZE * pageNumber;
        return ('<?xml version="1.0" encoding="UTF-8"?>' +
            '<XMLQuery trace="muse">' +
            '   <metadata query-type="emailarchive" archive="true" active="false" sort-on="receiveddate" sort-by="desc" ' +
            'page-row="' +
            PAGE_ROW +
            '" page-size="' +
            PAGE_SIZE +
            '">' +
            '       <mailboxes>' +
            '           <mailbox include-delegates="false" include-aliases="false" exchange-folder-id="' +
            folderId +
            '">' +
            mailbox +
            '</mailbox>' +
            '       </mailboxes>' +
            '       <return-fields>' +
            '           <return-field>size</return-field>' +
            '           <return-field>attachmentcount</return-field>' +
            '           <return-field>subject</return-field>' +
            '           <return-field>receiveddate</return-field>' +
            '           <return-field>displayfrom</return-field>' +
            '           <return-field>displayto</return-field>' +
            '           <return-field>id</return-field>' +
            '       </return-fields>' +
            '   </metadata>' +
            '   <muse>' +
            '       <text>(' +
            query +
            ')</text>' +
            '   </muse>' +
            '</XMLQuery>');
    }
}
exports.TreeService = TreeService;
