"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tableColumnsStartHidden = exports.tableColumnsAlwaysVisible = exports.tableSectionSearch = exports.exportColumnList = exports.tableColumnList = void 0;
exports.tableColumnList = [
    'fromAddress',
    'toAddress',
    'created',
    'ipAddress',
    'info',
    'spamScore',
    'detectionLevel',
    'description',
    'right-column'
];
exports.exportColumnList = [
    'fromEnv',
    'to',
    'sent',
    'senderIP',
    'info',
    'deferralType',
    'spamScore',
    'spamDetection'
];
exports.tableSectionSearch = {
    field: 'search',
    header: 'header',
    options: ['type', 'from', 'to', 'info', 'remoteIp']
};
exports.tableColumnsAlwaysVisible = ['description', 'info', 'created', 'right-column'];
exports.tableColumnsStartHidden = ['spamScore', 'detectionLevel'];
