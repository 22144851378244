"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiOktaEvidenceBasedControlIntegrationAsideViewComponent = void 0;
const core_1 = require("@angular/core");
const okta_evidence_based_control_model_1 = require("app-new/api-applications/models/okta-evidence-based-control.model");
class ApiOktaEvidenceBasedControlIntegrationAsideViewComponent {
    constructor(translateService) {
        this.translateService = translateService;
        this.onCloseClicked = new core_1.EventEmitter();
        this.onEditClicked = new core_1.EventEmitter();
        this.onDeleteClicked = new core_1.EventEmitter();
        this.onEnableClicked = new core_1.EventEmitter();
        this.onDisableClicked = new core_1.EventEmitter();
    }
    get oktaEvidenceBasedControlIntegration() {
        return this._oebcIntegration;
    }
    set oktaEvidenceBasedControlIntegration(oebcIntegration) {
        this._oebcIntegration = oebcIntegration;
        this._urlProtect = this.getEventType(okta_evidence_based_control_model_1.OktaEventTypes.URL_PROTECT, oebcIntegration);
        this._attachmentProtect = this.getEventType(okta_evidence_based_control_model_1.OktaEventTypes.ATTACHMENT_PROTECT, oebcIntegration);
        this._dataLeakPrevention = this.getEventType(okta_evidence_based_control_model_1.OktaEventTypes.DATA_LEAK_PREVENTION, oebcIntegration);
        this._notificationGroups = this.getNotificationGroups(oebcIntegration === null || oebcIntegration === void 0 ? void 0 : oebcIntegration.notificationGroups);
    }
    get urlProtect() {
        return this._urlProtect;
    }
    get attachmentProtect() {
        return this._attachmentProtect;
    }
    get dataLeakPrevention() {
        return this._dataLeakPrevention;
    }
    get notificationGroups() {
        return this._notificationGroups;
    }
    getEventType(eventType, oebcIntegration) {
        var _a;
        const event = oebcIntegration === null || oebcIntegration === void 0 ? void 0 : oebcIntegration.events.find(evt => evt.name === eventType);
        return (event === null || event === void 0 ? void 0 : event.enabled) ? (_a = event.group) === null || _a === void 0 ? void 0 : _a.name : this.translateService.instant('$I18N_API_OKTA_EVIDENCE_BASED_CONTROL_INTEGRATION_ASIDE_VIEW.EVENT_TYPE_DISABLED');
    }
    getNotificationGroups(groups) {
        return groups ? Object.entries(groups).map(([, name]) => name) : [];
    }
}
exports.ApiOktaEvidenceBasedControlIntegrationAsideViewComponent = ApiOktaEvidenceBasedControlIntegrationAsideViewComponent;
