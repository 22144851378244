<mc-collapsable-panel header="$I18N_IDENTITY_POLICY_DETAILS.DETAILS.HEADER" [isExpanded]="true">
  <div class="mc-detailed-list mc-detailed-list-two-columns">
    <div class="row mc-detailed-list-row">
      <div class="col-sm-4 mc-detailed-list-label">{{'$I18N_IDENTITY_POLICY_DETAILS.DETAILS.POLICY_NAME' | translate}}
      </div>
      <div class="mc-detailed-list-column">{{ policy.name }}</div>
    </div>

    <div class="row mc-detailed-list-row">
      <div class="col-sm-4 mc-detailed-list-label">{{'$I18N_IDENTITY_POLICY_DETAILS.DETAILS.DESCRIPTION' | translate}}
      </div>
      <div class="mc-detailed-list-column">{{ policy.description }}</div>
    </div>

    <div class="row mc-detailed-list-row">
      <div class="col-sm-4 mc-detailed-list-label">{{'$I18N_IDENTITY_POLICY_DETAILS.DETAILS.LAST_MODIFIED' | translate}}
      </div>
      <div class="mc-detailed-list-column">{{ policy.lastUpdated | mcDate }}</div>
    </div>
  </div>
</mc-collapsable-panel>

<mc-collapsable-panel header="$I18N_IDENTITY_POLICY_DETAILS.AUTHENTICATION_METHODS.HEADER" [isExpanded]="true">
  <div class="mc-detailed-list mc-detailed-list-two-columns">
    <div class="row mc-detailed-list-row">
      <div class="col-sm-4 mc-detailed-list-label">{{'$I18N_IDENTITY_POLICY_DETAILS.AUTHENTICATION_METHODS.DOMAIN' |
        translate}}
      </div>
      <div class="mc-detailed-list-column">{{ policy.policyDetails.delegatedAuthOption | mcIdentityDelegatedAuthOption }}</div>
    </div>
    <div class="row mc-detailed-list-row" *ngIf="policy.policyDetails.delegatedAuthOption === ewsDomainSetting">
      <div class="col-sm-4 mc-detailed-list-label">{{'$I18N_IDENTITY_POLICY_DETAILS.AUTHENTICATION_METHODS.CAS_SERVER' |
        translate}}
      </div>
      <div class="mc-detailed-list-column">{{ policy.policyDetails.ewsSettings.ewsCasEndpoint }}</div>
    </div>
    <div class="row mc-detailed-list-row" *ngIf="policy.policyDetails.delegatedAuthOption === ewsDomainSetting">
      <div class="col-sm-4 mc-detailed-list-label">{{'$I18N_IDENTITY_POLICY_DETAILS.AUTHENTICATION_METHODS.ALTERNATE_DOMAIN_SUFFIX' |
        translate}}
      </div>
      <div class="mc-detailed-list-column">{{ policy.policyDetails.ewsSettings.ewsDomainOverride }}</div>
    </div>
    <div class="row mc-detailed-list-row">
      <div class="col-sm-4 mc-detailed-list-label">
        {{'$I18N_IDENTITY_POLICY_DETAILS.AUTHENTICATION_METHODS.CLOUD_AUTHENTICATION' | translate}}
      </div>
      <div class="mc-detailed-list-column">{{ policy.policyDetails.cloudPasswordOption | mcIdentityCloudPasswordOption }}</div>
    </div>
    <div class="row mc-detailed-list-row">
      <div class="col-sm-4 mc-detailed-list-label">
        {{'$I18N_IDENTITY_POLICY_DETAILS.AUTHENTICATION_METHODS.MIMECAST_AUTHENTICATOR' | translate}}
      </div>
      <div class="mc-detailed-list-column">{{ formatEnabled(mimeAuthEnabled) }}</div>
    </div>
    <div class="row mc-detailed-list-row" *ngIf="displaySecondFactorOption">
      <div class="col-sm-4 mc-detailed-list-label">
        {{'$I18N_IDENTITY_POLICY_DETAILS.AUTHENTICATION_METHODS.SECOND_FACTOR' | translate}}
      </div>
      <div class="mc-detailed-list-column">{{ policy.policyDetails.secondFactorOption | mcIdentitySecondFactorOption }}</div>
    </div>
    <div class="row mc-detailed-list-row" *ngIf="displayRequestBeforePassword">
      <div class="col-sm-4 mc-detailed-list-label">
        {{'$I18N_IDENTITY_POLICY_DETAILS.AUTHENTICATION_METHODS.REQUEST_BEFORE_PASSWORD' | translate}}
      </div>
      <div class="mc-detailed-list-column">{{ formatEnabled(policy.policyDetails.secondFactorFirst) }}</div>
    </div>
  </div>
</mc-collapsable-panel>

<mc-collapsable-panel header="$I18N_IDENTITY_POLICY_DETAILS.LOCATION.HEADER" [isExpanded]="true">
  <div class="mc-detailed-list mc-detailed-list-two-columns">
    <div class="row mc-detailed-list-row">
      <div class="col-sm-4 mc-detailed-list-label">{{'$I18N_IDENTITY_POLICY_DETAILS.LOCATION.PERMISSION_TYPE' |
        translate}}
      </div>
      <div class="mc-detailed-list-column">{{ policy.policyDetails.locationSettings.permission | mcIdentityLocationPermission }}</div>
    </div>
    <div class="row mc-detailed-list-row" *ngIf="ipAddresses">
      <div class="col-sm-4 mc-detailed-list-label">{{ ipAddressesLabel }}</div>
      <div class="mc-detailed-list-column">{{ ipAddresses }}</div>
    </div>
  </div>
</mc-collapsable-panel>

<mc-collapsable-panel header="$I18N_IDENTITY_POLICY_DETAILS.SESSION_TIME_OUT.HEADER" [isExpanded]="true">
  <div class="mc-detailed-list mc-detailed-list-two-columns">
    <div class="row mc-detailed-list-row">
      <div class="col-sm-4 mc-detailed-list-label">{{'$I18N_IDENTITY_POLICY_DETAILS.SESSION_TIME_OUT.EXPIRATION_TIME' |
        translate}}
      </div>
      <div class="mc-detailed-list-column">{{ policy.policyDetails.expirationTime | mcIdentityExpirationSetting }}</div>
    </div>
  </div>
</mc-collapsable-panel>

<mc-collapsable-panel header="$I18N_IDENTITY_POLICY_DETAILS.APPLIES_TO.HEADER" [isExpanded]="true">
  <div class="mc-detailed-list mc-detailed-list-two-columns">
    <div class="row mc-detailed-list-row">
      <div class="col-sm-4 mc-detailed-list-label">{{'$I18N_IDENTITY_POLICY_DETAILS.APPLIES_TO.GROUPS' | translate}}
      </div>
      <div class="mc-detailed-list-column">
        <mc-identity-policy-groups [groups]="policy.groups"></mc-identity-policy-groups>
      </div>
    </div>
  </div>
</mc-collapsable-panel>
