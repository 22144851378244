"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgPolicyClpAppNotificationsStepComponent = void 0;
const core_1 = require("@angular/core");
const operators_1 = require("rxjs/operators");
class SwgPolicyClpAppNotificationsStepComponent {
    constructor(fb) {
        this.fb = fb;
        this.updateClpSettings = new core_1.EventEmitter();
        this.openAppliesToSidePanel = new core_1.EventEmitter();
        this.updateAffectedUsers = new core_1.EventEmitter();
        this.removeAffectedUsers = new core_1.EventEmitter();
    }
    ngOnInit() {
        this.clpNotifySettingsForm = this.fb.group({
            notify_file_owner: this.clpSettings.notifyFileOwner,
            notify_administrator: this.clpSettings.notifyAdministrator
        });
        this.notifySettingsSubscription = this.clpNotifySettingsForm.valueChanges
            .pipe(operators_1.debounceTime(200))
            .subscribe(() => {
            this.updateSettings();
        });
    }
    updateSettings() {
        const notifyFileOwner = this.clpNotifySettingsForm.controls['notify_file_owner'].value;
        const notifyAdministrator = this.clpNotifySettingsForm.controls['notify_administrator'].value;
        this.updateClpSettings.emit(Object.assign(Object.assign({}, this.clpSettings), { notifyFileOwner,
            notifyAdministrator }));
    }
    isValid() {
        return true;
    }
    openSidebar(value) {
        this.openAppliesToSidePanel.emit({ type: value, connectorId: this.clpSettings.connectorId });
    }
    removeUser(user) {
        this.removeAffectedUsers.emit(user);
    }
    ngOnDestroy() {
        this.notifySettingsSubscription.unsubscribe();
    }
}
exports.SwgPolicyClpAppNotificationsStepComponent = SwgPolicyClpAppNotificationsStepComponent;
