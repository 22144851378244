<mc-supervision-dashboard-settings 
  [settings]="settings$ | async" 
  [isLoading]="isLoading$ | async"
  [queueType]="selectedQueueType$ | async"
  [hasEditPermission]="hasEditPermission$ | async"
  [queues]="queues$ | async"
  (onUpdate)="onDashboardSettingsUpdated($event)"
  (onCancel)="close()"
>
</mc-supervision-dashboard-settings>
