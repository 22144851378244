"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RejectedAndDeferredTabsPageComponent = void 0;
const core_1 = require("@angular/core");
class RejectedAndDeferredTabsPageComponent {
    constructor() {
        this.tabChange = new core_1.EventEmitter();
    }
    onTabChanged(newTab) {
        this.tabChange.emit(newTab.name);
    }
}
exports.RejectedAndDeferredTabsPageComponent = RejectedAndDeferredTabsPageComponent;
