<div class="mc-list-panel">
  <div class="mc-list-panel-heading">
    {{ '$I18N_SERVICES_URL_PROTECTION_DASHBOARD.URLS_SCANNED_PER_DAY.TITLE' | translate }}
    <div class="pull-right">
      <mc-list-dropdown-button disableCaret="true" inline="true"
        class="mc-chart-controls mc-chart-download-button">
        <mc-dropdown-item 
        [label]="exportFormats.png.label"
        (click)="urlScanGraph.exportChart(exportFormats.png.type, urlsScannedPerDayChart)"></mc-dropdown-item>
        <mc-dropdown-item 
        [label]="exportFormats.pdf.label"
        (click)="urlScanGraph.exportChart(exportFormats.pdf.type, urlsScannedPerDayChart)"></mc-dropdown-item>
        <mc-dropdown-item 
        [label]="exportFormats.svg.label"
        (click)="urlScanGraph.exportChart(exportFormats.svg.type, urlsScannedPerDayChart)"></mc-dropdown-item>
        <mc-dropdown-item 
        [label]="exportFormats.jpg.label"
        (click)="urlScanGraph.exportChart(exportFormats.jpg.type, urlsScannedPerDayChart)"></mc-dropdown-item>
      </mc-list-dropdown-button>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12">
      <div class="small-toolbar border-bottom">
        <div>
          <span>
            {{ '$I18N_SERVICES_URL_PROTECTION_DASHBOARD.URLS_SCANNED_PER_DAY.LAST_30_DAYS' | translate }}
          </span>
          <ng-template #loadingTotalRequest>
            <mc-spinner></mc-spinner>
          </ng-template>
          <div class="pull-right">

            <div class="tab-container">
              <span>{{'$I18N_SERVICES_URL_PROTECTION_DASHBOARD.URLS_SCANNED_PER_DAY.ALL' | translate}}</span>
              <span>{{'$I18N_SERVICES_URL_PROTECTION_DASHBOARD.URLS_SCANNED_PER_DAY.INBOUND' | translate}}</span>
              <span>{{'$I18N_SERVICES_URL_PROTECTION_DASHBOARD.URLS_SCANNED_PER_DAY.OUTBOUND' | translate}}</span>
              <span>{{'$I18N_SERVICES_URL_PROTECTION_DASHBOARD.URLS_SCANNED_PER_DAY.JOURNAL' | translate}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="mc-top-ten-chart">
        <mc-multi-line-graph-chart
          #urlScanGraph
          [config]="config"
          [chartData]="chartData"
          [disableCursorLineY]="true"
          cursorBehaviour="none"
          [theme]="theme"
          class="mc-amchart">
        </mc-multi-line-graph-chart>
      </div>
    </div>
  </div>
</div>