"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./recent-activity-directive"); //recent-activity.directive
require("./recent-activity-service"); //recent-activity.service
// html and css 
require("./recent-activity.tpl.html");
/**
 * Created by psambandam on 15/02/16.
 */
angular.module('recent-activity', ['recent-activity.directive', 'recent-activity.service']);
