"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DetailsComponent = void 0;
const table_store_1 = require("@mimecast-ui/table-store");
class DetailsComponent extends table_store_1.TableBaseComponent {
    constructor(store) {
        super(store, 'ExchangeMailboxList');
        this.store = store;
        this.columnList = ['displayName', 'mailbox', 'syncedBy', 'firstStarted', 'lastActive', 'lastStatus'];
    }
}
exports.DetailsComponent = DetailsComponent;
