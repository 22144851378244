'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("mimecast-web-components/src/app/services/dlp/dlp"); //api.dlp
require("./data-leak-prevention-list.controller"); //services.monitoring.dlp.list.controller
require("mimecast-web-components/src/app/components/date-range-picker/date-range-picker"); //input.advancedDateRangePicker
require("app/components/select-date-picker/select-date-picker"); //select-date-picker
require("app/components/search-box-extended/search-box-extended"); //search-box-extended
require("app/components/log-detail-navigator/log-detail-navigator");
// html and css
require("./data-leak-prevention-list.less");
require("./data-leak-prevention-list.tpl.html");
require("./slides/data-leak-prevention-log-detail.tpl.html");
angular.module('services.monitoring.dlp.list', [
    'api.dlp',
    'services.monitoring.dlp.list.controller',
    'input.advancedDateRangePicker',
    'select-date-picker',
    'search-box-extended',
    'components.log-detail-navigator'
])
    .config(['$stateProvider', function ($stateProvider) {
        $stateProvider
            .state('data-leak-prevention-list', {
            url: '/services/monitoring/data-leak-prevention',
            data: {
                tabId: 'data-leak-prevention-tabid',
                tabTitle: '$I18N_ADMINISTRATION_MENU_DATA_LEAK_PREVENTION_LIST_MENU_TAB',
                tabHide: false,
                tabReload: false
            },
            views: {
                'main': {
                    templateUrl: 'services/monitoring/data-leak-prevention/data-leak-prevention-list/data-leak-prevention-list.tpl.html',
                }
            }
        });
    }]);
