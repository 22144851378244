<mc-layout-list-extra-container
  keyTitle="$I18N_API_APPLICATIONS_LIST.PAGE_HEADER"
  keyDescription="$I18N_API_APPLICATIONS_LIST.PAGE_DESCRIPTION"
>
  <mc-help-container>
    <mc-help-link url="https://community.mimecast.com/docs/DOC-3181"></mc-help-link>
  </mc-help-container>

  <mc-body-container>
    <mc-tab-group
      #tabs
      [initialActiveTabIndex]="displayTab"
    >
      <div class="panel-padding-top">
        <mc-tab
          name="{{ '$I18N_API_APPLICATIONS_LIST.API_INTEGRATIONS_LIST_HEADER' | translate }}"
          *mcCapabilities="'Temporary.Services.ApiApplications.TechPartners'"
        >
          <mc-api-applications-tech-partners-list
            [isOktaIntegrationEnabled]="isOktaIntegrationSwitchEnabled$ | async"
            [isAnomaliIntegrationEnabled]="isAnomaliIntegrationSwitchEnbled$ | async"
            [isMimecastApiV3Enabled]="isMimecastApiV3SwitchEnabled$ | async"
            [isOktaEvidenceBasedControlIntegrationEnabled]="isOktaEvidenceBasedControlIntegrationEnabled$ | async"
          >
          </mc-api-applications-tech-partners-list>
        </mc-tab>

        <mc-tab *ngFor="let tabName of apiTabs; let tabIndex = index;" name="{{ tabName | translate }}">
          <mc-api-applications-table
            [applications]="tabIndex === 0 ? (legacyApplications$ | async) : (gatewayApplications$ | async)"
            [totalCount]="tabIndex === 0 ? (totalLegacyApplicationsCount$ | async) : (totalGatewayApplicationsCount$ | async)"
            [selectedRow]="selectedApplicationsRow$ | async"
            [isLoading]="isApplicationsLoading$ | async"
            [pagingMetadata]="tabIndex === 0 ? (pagingLegacyApplicationsMetadata$ | async) : (pagingGatewayApplicationsMetadata$ | async)"
            [isOktaIntegrationEnabled]="isOktaIntegrationSwitchEnabled$ | async"
            [columns]="tabIndex === 0 ? apiLegacyColumns : apiGatewayV2Columns"
            [searchByColumns]="tabIndex === 0 ? searchByColumnsLegacy : searchByColumnsApiGateway2_0"
            (onPageChanged)="changeApplicationsPage($event)"
            (onSearch)="tabIndex === 0 ? searchApplicationsForLegacy($event, tabIndex) : searchApplicationsForGateway($event, tabIndex)"
            (onViewApplication)="viewApplication($event)"
            (onCreateApplication)="tabIndex === 0 ? createApplication() : createApplicationForGateway()"
            (onEditApplication)="editApplication($event)"
            (onDeleteApplication)="deleteApplication($event)"
            (onEnableApplication)="enableApplication($event)"
            (onDisableApplication)="disableApplication($event)"
            (onGenerateKeys)="generateApplicationKeys($event)"
            (onGetCredentials)="getApplicationCredentials($event)"
          >
          </mc-api-applications-table>
        </mc-tab>

        <mc-tab
          name="{{ '$I18N_API_APPLICATIONS_LIST.INTEGRATIONS_TAB_HEADER' | translate }}"
          *mcCapabilities="'Temporary.Services.ApiApplications.Integration'"
        >
          <mc-api-integration-table
            [integrations]="integrations$ | async"
            [totalCount]="totalIntegrationsCount$ | async"
            [selectedRow]="selectedIntegrationsRow$ | async"
            [isLoading]="isIntegrationsLoading$ | async"
            [oktaIntegrationAccessToken]="oktaIntegrationAccessToken$ | async"
            [rotateToken]="rotateToken$ | async"
            [pagingMetadata]="pagingIntegrationsMetadata$ | async"
            [showApiModal]="showApiModal"
            [isOktaIntegrationEnabled]="isOktaIntegrationSwitchEnabled$ | async"
            (onPageChanged)="changeIntegrationsPage($event)"
            (onViewIntegration)="viewIntegration($event)"
            (onCreateIntegration)="createIntegration()"
            (onEditIntegration)="editIntegration($event)"
            (onEnableIntegration)="enableIntegration($event)"
            (onDisableIntegration)="disableIntegration($event)"
            (onClearQueryParams)="clearQueryParams()"
            (onViewActivityLogs)="openCrowdstrikeActivityLogPage($event)"
          >
          </mc-api-integration-table>
        </mc-tab>

        <mc-tab
          name="{{ '$I18N_API_APPLICATIONS_LIST.EVENT_CHANNELS_TAB_HEADER' | translate }}"
          *mcCapabilities="'Temporary.Permission.Siem.Channels && Security.Events.Stream && Permission.SIEM_CHANNEL_READ'"
        >
          <mc-siem-channel-list-container></mc-siem-channel-list-container>
        </mc-tab>
      </div>
    </mc-tab-group>
  </mc-body-container>
</mc-layout-list-extra-container>
