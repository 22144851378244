"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageComponent = void 0;
const core_1 = require("@angular/core");
const action = require("../../../actions");
class MessageComponent {
    constructor(store, capabilitiesService) {
        this.store = store;
        this.capabilitiesService = capabilitiesService;
        this.loadContentRequest = new core_1.EventEmitter();
        this.downloadFile = new core_1.EventEmitter();
    }
    ngOnInit() {
        this.capabilitiesService
            .hasCapability('Temporary.MessageCenter.SpamScore')
            .subscribe(isActive => {
            if (isActive === true) {
                this.showSpamDetails = true;
            }
            else {
                this.showSpamDetails = false;
            }
        });
    }
    showHtml(showHtmlFlag) {
        this.store.dispatch(new action.ShowHtmlAction(showHtmlFlag));
    }
    ngOnDestroy() {
        if (this.subscribeStore) {
            this.subscribeStore.unsubscribe();
        }
    }
    getPrintableContent() {
        return this.emailBody.getPrintableContent();
    }
    showSpamDetailsInfo() {
        if (!!this.showSpamDetails) {
            return this.validStatuses(['accepted', 'archived']);
        }
        else {
            return false;
        }
    }
    validStatuses(statuses) {
        return statuses.indexOf(this.messageStatus) !== -1;
    }
}
exports.MessageComponent = MessageComponent;
