"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MepLogSidebarHeaderComponent = void 0;
class MepLogSidebarHeaderComponent {
    constructor() {
        this.maxLength = 40;
    }
    ngOnChanges(changes) {
        if (changes['item'] && changes['item'].currentValue) {
            const { externalAddresses } = changes['item'].currentValue;
            this.headerText = this.buildHeaderText(externalAddresses);
        }
    }
    buildHeaderText(mepFlaggedToAddresses) {
        const misAddressedEmails = mepFlaggedToAddresses
            .filter(flaggedAddress => !!flaggedAddress.similarContactAddress)
            .map(flaggedAddress => flaggedAddress.email);
        const text = misAddressedEmails.join(', ');
        return text.length > this.maxLength ? text.slice(0, this.maxLength) + '…' : text;
    }
}
exports.MepLogSidebarHeaderComponent = MepLogSidebarHeaderComponent;
