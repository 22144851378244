"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchLogsEffect = exports.PAGINATED_SEARCH_DELAY_TIME = exports.PAGINATED_SEARCH_SCHEDULER = void 0;
const services_1 = require("../services");
const actions = require("../actions/search.action");
const index_1 = require("../reducers/index");
const table_store_1 = require("@mimecast-ui/table-store");
const core_1 = require("@angular/core");
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const notification_actions_1 = require("app-new/components/notification/actions/notification.actions");
const view_details_component_1 = require("../components/aside/view-details.component");
exports.PAGINATED_SEARCH_SCHEDULER = new core_1.InjectionToken('Paginated Search Scheduler');
exports.PAGINATED_SEARCH_DELAY_TIME = new core_1.InjectionToken('Paginated Search Delay Time');
class SearchLogsEffect {
    constructor(actions$, secureMessagingService, asideService, translateService, store, scheduler, delayTime) {
        this.actions$ = actions$;
        this.secureMessagingService = secureMessagingService;
        this.asideService = asideService;
        this.translateService = translateService;
        this.store = store;
        this.scheduler = scheduler;
        this.delayTime = delayTime;
        this.search$ = this.actions$.pipe(effects_1.ofType(actions.FILTER), operators_1.map((action) => action.payload), operators_1.switchMap(log => {
            return this.secureMessagingService.getData(log).pipe(operators_1.map((response) => new actions.FilterSuccessAction(response.data)), operators_1.catchError(() => [
                new notification_actions_1.NotificationShowAction({
                    type: 'error',
                    config: {
                        msg: this.translateService.instant('$I18N_SECURE_MESSAGING_MESSAGES.FAILURE')
                    }
                })
            ]));
        }));
        this.OpenAndGetMessageDetails$ = this.actions$.pipe(effects_1.ofType(actions.OPEN_SIDE_PANE), operators_1.map((action) => {
            this.sidePane = this.asideService.open(view_details_component_1.ViewDetailsComponent, {
                size: 'md',
                position: 'right'
            });
            return new actions.GetMessageDetailsAction(action.payload);
        }));
        this.getDetails$ = this.actions$.pipe(effects_1.ofType(actions.GET_MESSAGE_DETAILS), operators_1.map((action) => action.payload), operators_1.switchMap(log => {
            return this.secureMessagingService.gerMessageDetails(log.id).pipe(operators_1.map((response) => new actions.GetMessageDeatilsSuccessAction(response.first)), operators_1.catchError(() => [
                new notification_actions_1.NotificationShowAction({
                    type: 'error',
                    config: {
                        msg: this.translateService.instant('$I18N_SECURE_MESSAGING_MESSAGES.FAILURE')
                    }
                })
            ]), operators_1.withLatestFrom(this.store.select(index_1.getMessages)), operators_1.switchMap(([action, tableData]) => {
                return [
                    action,
                    this.getPaginationInfo(log, tableData),
                    new table_store_1.SetSelectedRowAction({
                        tableId: services_1.SecureMessagingService.LOGS_TABLE,
                        item: log
                    })
                ];
            }));
        }));
        this.closeSidePane$ = this.actions$.pipe(effects_1.ofType(actions.CLOSE_SIDE_PANE), operators_1.tap(() => {
            this.sidePane.close();
            this.sidePane = undefined;
        }), operators_1.map(() => new table_store_1.ClearSelectedRowAction({
            tableId: services_1.SecureMessagingService.LOGS_TABLE
        })));
        this.searchPagination$ = this.actions$.pipe(effects_1.ofType(actions.SEARCH_PAGINATION), operators_1.delay(this.delayTime || 300, this.scheduler || rxjs_1.asyncScheduler), operators_1.map(() => new actions.SearchPaginationSuccessAction()));
    }
    getPaginationInfo(currentItem, tableData = []) {
        const currentIndex = tableData.findIndex(value => value.id === currentItem.id);
        const previous = currentIndex !== undefined && currentIndex > 0 ? tableData[currentIndex - 1] : undefined;
        const next = currentIndex !== undefined && currentIndex < tableData.length
            ? tableData[currentIndex + 1]
            : undefined;
        return new actions.SetDetailsPaginationInfoAction(previous, currentItem, next, currentIndex, tableData.length);
    }
}
__decorate([
    effects_1.Effect()
], SearchLogsEffect.prototype, "search$", void 0);
__decorate([
    effects_1.Effect()
], SearchLogsEffect.prototype, "OpenAndGetMessageDetails$", void 0);
__decorate([
    effects_1.Effect()
], SearchLogsEffect.prototype, "getDetails$", void 0);
__decorate([
    effects_1.Effect()
], SearchLogsEffect.prototype, "closeSidePane$", void 0);
__decorate([
    effects_1.Effect()
], SearchLogsEffect.prototype, "searchPagination$", void 0);
exports.SearchLogsEffect = SearchLogsEffect;
