"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CortexSeverityPipe = void 0;
class CortexSeverityPipe {
    constructor(translateService) {
        this.translateService = translateService;
    }
    transform(input) {
        switch (input) {
            case 'none':
            case 'medium':
            case 'high':
            case 'critical':
                return this.translateService.instant('$I18N_API_INTEGRATION_CORTEX_SEVERITY.' + input.toUpperCase());
            default:
            case 'unknown':
                return this.translateService.instant('$I18N_API_INTEGRATION_CORTEX_SEVERITY.UNKNOWN');
        }
    }
}
exports.CortexSeverityPipe = CortexSeverityPipe;
