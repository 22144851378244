"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocationModule = exports._window = void 0;
function _window() {
    return window;
}
exports._window = _window;
class LocationModule {
}
exports.LocationModule = LocationModule;
