"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.searchFilters = exports.filterSearchOptions = exports.columnsAlwaysVisible = exports.columnsToStartHidden = exports.columns = void 0;
exports.columns = [
    'username',
    'discoveryMethod',
    'deviceName',
    'publicIp',
    'privateIp',
    'request',
    'sourceIp',
    'threat',
    'details',
    'dateTime',
    'action'
];
exports.columnsToStartHidden = ['discoveryMethod', 'deviceName', 'privateIp'];
exports.columnsAlwaysVisible = ['username', 'threat', 'details', 'dateTime'];
exports.filterSearchOptions = [
    {
        label: '$I18N_SECURITY_REPORT.TABLE.SEARCH_ALL',
        value: 'all'
    },
    {
        label: '$I18N_SECURITY_REPORT.TABLE.COLUMNS.USERNAME',
        value: 'username'
    },
    {
        label: '$I18N_SECURITY_REPORT.TABLE.COLUMNS.DEVICENAME',
        value: 'deviceName'
    },
    {
        label: '$I18N_SECURITY_REPORT.TABLE.COLUMNS.PUBLICIP',
        value: 'publicIp'
    },
    {
        label: '$I18N_SECURITY_REPORT.TABLE.COLUMNS.SOURCEIP',
        value: 'sourceIp'
    },
    {
        label: '$I18N_SECURITY_REPORT.TABLE.COLUMNS.PRIVATEIP',
        value: 'privateIp'
    },
    {
        label: '$I18N_SECURITY_REPORT.TABLE.COLUMNS.REQUEST',
        value: 'domainurl'
    }
];
exports.searchFilters = [
    {
        name: 'contextType',
        categories: [
            { name: 'authenticated' },
            { name: 'domainUser' },
            { name: 'localUser' },
            { name: 'multipleUsers' },
            { name: 'networkProtectionOnly' },
            { name: 'noLoggedInUser' },
            { name: 'anonymous' },
            { name: 'supervisedUser' },
            { name: 'unknownDomainUser' }
        ]
    },
    {
        name: 'antivirus',
        categories: [{ name: 'infected' }, { name: 'unscannable' }]
    },
    {
        name: 'newdomain',
        categories: []
    },
    {
        name: 'similarity',
        categories: []
    },
    {
        name: 'securitycategory',
        categories: [
            { name: 'anonymizers' },
            { name: 'attacker_controlled_infrastructure' },
            { name: 'botnets' },
            { name: 'compromised' },
            { name: 'hacking' },
            { name: 'malware' },
            { name: 'phishing' },
            { name: 'potentially_malicious' },
            { name: 'spam' }
        ]
    }
];
