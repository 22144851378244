<div class="mc-api-integration-step-block">
  <mc-wizard-step-header [label]="header" ></mc-wizard-step-header>

  <div class="mc-detailed-list">
    <div class="row mc-detailed-list-row panel-padding-bottom">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.DETAILS.NAME' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{ oktaIntegration.name }}
      </div>
    </div>

    <div class="row mc-detailed-list-row panel-padding-bottom">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.DETAILS.DESCRIPTION' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{ oktaIntegration.description }}
      </div>
    </div>

    <div class="row mc-detailed-list-row panel-padding-bottom">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.SUMMARY.DIRECTORY_LOCATION' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{ description }}
      </div>
    </div>

    <div class="row mc-detailed-list-row panel-padding-bottom">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.SUMMARY.OKTA_CELL' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{ oktaIntegration?.cell | mcOktaCellProvider}}
      </div>
    </div>
    <div class="row mc-detailed-list-row panel-padding-bottom">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.SUMMARY.RECIPIENTS' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        <div class="mc-row-email" *ngFor="let email of oktaEmailList()">
          {{ email }}
        </div>
      </div>
    </div>
    <form novalidate [formGroup]="form">
      <mc-enable-switch
        [label]="'$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.SUMMARY.STATUS'"
        formControlName="integrationStatus"
        contentDivClass="row mc-detailed-list-row panel-padding-top"
        labelClass="col-xs-4 mc-detailed-list-label"
        switchDivClass="mc-detailed-list-column"
      >
      </mc-enable-switch>

      <mc-enable-switch *ngIf="hasCreatedIntegration && oktaIntegration.enabled"
        [label]="'$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.SUMMARY.ROTATE_API_TOKE'"
        [onStateLabel]="'$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.SUMMARY.YES'"
        [offStateLabel]="'$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.SUMMARY.NO'"
        formControlName="rotate"
        contentDivClass="row mc-detailed-list-row panel-padding-top"
        labelClass="col-xs-4 mc-detailed-list-label"
        switchDivClass="mc-detailed-list-column"
        data-test="mc-okta-rotate-switch">

      </mc-enable-switch>
      <div *ngIf="hasCreatedIntegration && form.value.rotate" class="row mc-detailed-list-row panel-padding-bottom">
        <div class="col-xs-offset-4 mc-detailed-list-column">
          <span class="mc-text" data-test="rotate-token-notice">
            <em class="fas fa-info-circle text-info"></em>
            <span translate="$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.SUMMARY.ROTATE_API_TOKEN_INFO"></span>
          </span>
        </div>
      </div>
    </form>
  </div>
</div>
