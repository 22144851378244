"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OktaTreeViewComponent = void 0;
const core_1 = require("@angular/core");
const components_1 = require("@mimecast-ui/components");
const forms_1 = require("@angular/forms");
class OktaTreeViewComponent extends components_1.TreeViewComponent {
    constructor(fb) {
        super();
        this.fb = fb;
        this.createNewFolder = new core_1.EventEmitter();
        this.createPlaceholderFolder = new core_1.EventEmitter();
        this.cancelAddingFolder = new core_1.EventEmitter();
        this.hoveredFolderId = '';
        this.isAfterViewCheckedEnabled = false;
        this.FOLDERS = 'folders';
        this.form = this.fb.group({
            folderName: [
                '',
                [
                    forms_1.Validators.minLength(3),
                    forms_1.Validators.pattern(/^\w[\w\d\.\s\-]*[\w]+$/),
                    this.forbiddenNameValidator,
                    this.itemExistsValidator.bind(this)
                ]
            ]
        });
    }
    ngAfterViewChecked() {
        // TODO - IS-5733 - requires a different implementation due to limitation of dynamic tree component
        //  adding and Tree rendering
        if (this.isAfterViewCheckedEnabled &&
            this.nodes[0][this.FOLDERS].length > 0 &&
            this.nodes[0][this.FOLDERS][0].isNewFolderEntry) {
            this.onTreeLoad();
        }
    }
    onTreeLoad() {
        // TODO - IS-5733 - moving to mimecast-ui - set as optional: default: false
        if (this.getTreeRef().treeModel) {
            this.getTreeRef().treeModel.expandAll();
        }
    }
    onHover(node) {
        this.hoveredFolderId = node.data.id;
    }
    onUnHover() {
        this.hoveredFolderId = '';
    }
    cancelInput() {
        this.cancelAddingFolder.emit();
    }
    addNewFolder(node) {
        if (this.form.valid) {
            this.createNewFolder.emit(Object.assign(Object.assign({}, node.data), { description: this.form.value.folderName }));
        }
    }
    createPlaceholderOktaFolder(node) {
        this.form.reset();
        this.form.markAsPristine();
        this.form.markAsUntouched();
        this.createPlaceholderFolder.emit(node.data.id);
        this.isAfterViewCheckedEnabled = true;
        node.expand();
    }
    isRootDirectory(folderId) {
        return this.nodes.some(group => group['id'] === folderId);
    }
    itemExistsValidator(control) {
        const FOLDERS = 'folders';
        const DESCRIPTION = 'description';
        if (control.value !== undefined &&
            isNaN(control.value) &&
            this.nodes &&
            this.nodes[0][FOLDERS]) {
            for (const node of this.nodes[0][FOLDERS]) {
                if (node[DESCRIPTION] === control.value) {
                    return { itemExists: { value: control.value } };
                }
            }
        }
        return null;
    }
    forbiddenNameValidator(control) {
        const DELETED_ITEMS = 'deleted items';
        if (control.value !== undefined && isNaN(control.value)) {
            const forbidden = DELETED_ITEMS === control.value.toLowerCase();
            return forbidden ? { forbiddenName: { value: control.value } } : null;
        }
        return null;
    }
}
exports.OktaTreeViewComponent = OktaTreeViewComponent;
