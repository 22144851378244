<mc-collapsable-panel header="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TRIPLET_INFORMATION.TITLE" [isExpanded]="true">
  <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TRIPLET_INFORMATION.LABEL_FROM_ADDRESS"
                  [value]="queueInfo.fromHeader">
  </mc-value-field>
  <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TRIPLET_INFORMATION.LABEL_TO_ADDRESS_PRE_CHECKS"
                  [value]="queueInfo.to">
  </mc-value-field>
  <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TRIPLET_INFORMATION.LABEL_TO_ADDRESS_POST_CHECKS"
                  [value]="queueInfo.toAddressPostCheck">
  </mc-value-field>
  <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TRIPLET_INFORMATION.LABEL_IP_ADDRESS"
                  [value]="queueInfo.remoteIp">
  </mc-value-field>
</mc-collapsable-panel>
