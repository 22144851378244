"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnchorHrefDirective = void 0;
// eslint-disable-next-line @angular-eslint/directive-selector
class AnchorHrefDirective {
    constructor(locationService) {
        this.locationService = locationService;
    }
    onClick(event) {
        if (this.href.startsWith('#')) {
            event.preventDefault();
            this.locationService.hash = this.href;
        }
    }
}
exports.AnchorHrefDirective = AnchorHrefDirective;
