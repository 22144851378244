<mc-modal-header
        headerTitle="Delete Policy Confirmation"></mc-modal-header>
<mc-modal-body *ngIf="policy$ | async; let row">
    <div>
        {{ '$I18N_IDENTITY_DELETE_POLICY_CONFIRMATION' | translate }} {{ row.name }}?
    </div>
</mc-modal-body>
<mc-modal-footer  *ngIf="policy$ | async; let row">
    <button type="button" class="btn btn-secondary mc-policy-cancel" (click)="onCancel()">
        {{ 'Cancel'}}
    </button>

    <mc-live-button
            overrideClasses="btn-primary mc-policy-delete"
            [label]="'$I18N_COMMON_BTN_DELETE' | translate"
            [isLoading]="isDeleting$ | async"
            (mcClick)="onDelete(row.id)">
    </mc-live-button>
</mc-modal-footer>