<div class="mc-api-integration-step-block">
    <mc-wizard-step-header [label]="summaryHeader">
    </mc-wizard-step-header>
  
    <div *ngIf="crowdstrikePrerequisitesError" class="mc-detailed-list mc-api-integration-summary-block">
      <div class="row mc-detailed-list-row panel-padding-bottom">
        <div class="col-xs-8">
          {{ errorContent }}
        </div>
      </div>
      <div *ngIf="crowdstrikePrerequisitesError.toLowerCase() === 'err_api_integration_wildfire_package_missing'" class="row mc-detailed-list-row panel-padding-bottom">
        <div class="col-xs-8">
          {{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.SUMMARY.PACKAGE' | translate }}
        </div>
      </div>
    </div>
  
    <div *ngIf="!crowdstrikePrerequisitesError" class="mc-detailed-list mc-api-integration-summary-block">
      <div class="row mc-detailed-list-row panel-padding-bottom">
        <div class="col-xs-4 mc-detailed-list-label">
          {{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.SUMMARY.INTEGRATION.NAME' | translate }}
        </div>
        <div class="mc-detailed-list-column">
          {{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.SUMMARY.INTEGRATION.VALUE' | translate }}
        </div>
      </div>
  
      <div class="row mc-detailed-list-row panel-padding-bottom">
        <div class="col-xs-4 mc-detailed-list-label">
          {{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.SUMMARY.DESCRIPTION.NAME' | translate }}
        </div>
        <div class="mc-detailed-list-column">
          {{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.SUMMARY.DESCRIPTION.VALUE' | translate }}
        </div>
      </div>

      <div class="row mc-detailed-list-row panel-padding-bottom">
        <div class="col-xs-4 mc-detailed-list-label">
          {{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.SUMMARY.CLIENT_ID' | translate }}
        </div>
        <div class="mc-detailed-list-column">
          {{ crowdstrikeIntegration?.clientId }}
        </div>
      </div>

      <div class="row mc-detailed-list-row panel-padding-bottom">
        <div class="col-xs-4 mc-detailed-list-label">
          {{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.SUMMARY.CLIENT_SECRET' | translate }}
        </div>
        <div class="mc-detailed-list-column">
          {{ crowdstrikeIntegration?.clientSecret | mcApiObfuscator }}
        </div>
      </div>

      <div class="row mc-detailed-list-row panel-padding-bottom">
        <div class="col-xs-4 mc-detailed-list-label">
          {{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.SUMMARY.BASE_URL' | translate }}
        </div>
        <div class="mc-detailed-list-column">
          {{ crowdstrikeIntegration?.baseUrl }}
        </div>
      </div>

      <div class="row mc-detailed-list-row panel-padding-bottom">
        <div class="col-xs-4 mc-detailed-list-label">
          {{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.SUMMARY.SUBMIT_MALICIOUS_HASHES' | translate }}
        </div>
        <div class="mc-detailed-list-column">{{ crowdstrikeIntegration?.submitMaliciousHashes | mcSubmitMaliciousHashDesc: crowdstrikeIntegration?.iocOutboundAction }}</div>
      </div>

      <div class="row mc-detailed-list-row panel-padding-bottom" *mcCapabilities="'Temporary.Services.ApiApplications.Crowdstrike.Integration.PushUrl'">
        <div class="col-xs-4 mc-detailed-list-label">
          {{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.SUMMARY.SUBMIT_MALICIOUS_URLS' | translate }}
        </div>
        <div class="mc-detailed-list-column">{{ crowdstrikeIntegration?.submitMaliciousUrls | mcSubmitMaliciousUrlDesc: crowdstrikeIntegration?.iocOutboundUrlAction :
          crowdstrikeIntegration?.iocOutboundSeverity : crowdstrikeIntegration?.iocOutboundExpiration}}</div>
      </div>

      <div class="row mc-detailed-list-row panel-padding-bottom" *mcCapabilities="'Temporary.Services.ApiApplications.Crowdstrike.Integration.PullMaliciousHashes'">
        <div class="col-xs-4 mc-detailed-list-label">
          {{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.SUMMARY.PULL_MALICIOUS_HASHES' | translate }}
        </div>
        <div class="mc-detailed-list-column">
          {{ crowdstrikeIntegration?.pullMaliciousHashes | mcCrowdstrikeBoolean }}
        </div>
      </div>

      <div class="row mc-detailed-list-row panel-padding-bottom" *mcCapabilities="'Temporary.Services.ApiApplications.Crowdstrike.Integration.PullMaliciousHashes'">
        <div class="col-xs-4 mc-detailed-list-label">
          {{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.SUMMARY.REMEDIATION' | translate }}
        </div>
        <div class="mc-detailed-list-column">
          {{ crowdstrikeIntegration?.remediationRequired | mcCrowdstrikeBoolean }}
        </div>
      </div>

      <div class="row mc-detailed-list-row panel-padding-bottom">
        <div class="col-xs-4 mc-detailed-list-label">
          {{ '$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.SUMMARY.RECIPIENTS' | translate }}
        </div>
    
        <div class="mc-detailed-list-column">
          <div *ngFor="let group of crowdstrikeGroupsList()">
            {{ group | translate }}
          </div>
    
          <div *ngFor="let email of corwdstrikeEmailList()">
            {{ email }}
          </div>
        </div>
      </div>
  
      <div class="mc-detailed-list mc-api-integration-summary-block">
          <form novalidate [formGroup]="form">
            <mc-enable-switch
              [label]="'$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.SUMMARY.STATUS'"
              formControlName="integrationStatus"
              contentDivClass="row mc-detailed-list-row panel-padding-top"
              labelClass="col-xs-4 mc-detailed-list-label"
              switchDivClass="mc-detailed-list-column"
            >
            </mc-enable-switch>
          </form>
      </div>
    </div>
  </div>
  