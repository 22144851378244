"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiIntegrationCortexAsideSettingsComponent = void 0;
class ApiIntegrationCortexAsideSettingsComponent {
    constructor(cortex, translateService, capabilitiesService) {
        this.cortex = cortex;
        this.translateService = translateService;
        this.capabilitiesService = capabilitiesService;
        this.DESCRIPTION_PREFIX_KEY = '$I18N_API_INTEGRATION.DESCRIPTIONS';
        this.objectKeys = Object.keys;
    }
    ngOnInit() {
        this.cortex.assign(this.cortexIntegrationVersion1);
        this.setIntegrationDescription();
    }
    ngOnChanges(changes) {
        const change = changes['cortexIntegrationVersion1'];
        if (change && JSON.stringify(change.previousValue) !== JSON.stringify(change.currentValue)) {
            if (change.currentValue) {
                this.cortex.assign(change.currentValue);
                this.setIntegrationDescription();
            }
        }
    }
    setIntegrationDescription() {
        if (this.integrationDescriptionKey) {
            this.description = this.translateService.instant(`${this.DESCRIPTION_PREFIX_KEY}.${this.integrationDescriptionKey.toUpperCase()}`);
        }
        else {
            this.description = this.integrationDescription;
        }
    }
    get spywareSeverity() {
        return this.resolveSeverity('spyware');
    }
    get wildfireSeverity() {
        return this.resolveSeverity('wildfire');
    }
    get spywareSettings() {
        if (this.cortexIntegrationVersion1) {
            return this.resolveSetting(this.cortexIntegrationVersion1.triggers, 'spyware');
        }
        return this.getInitialSetting('spyware', 'medium');
    }
    get wildfireSettings() {
        if (this.cortexIntegrationVersion1) {
            return this.resolveSetting(this.cortexIntegrationVersion1.triggers, 'wildfire');
        }
        return this.getInitialSetting('wildfire', 'high');
    }
    get cortexEmailList() {
        if (this.cortexIntegrationVersion1) {
            return this.cortexIntegrationVersion1.notificationAddresses;
        }
        return [];
    }
    get cortexGroupsList() {
        if (this.cortexIntegrationVersion1) {
            return this.cortexIntegrationVersion1.notificationGroups;
        }
        return {};
    }
    get notifyAction() {
        if (!!this.cortexIntegrationVersion1) {
            return this.cortexIntegrationVersion1.notifyOnly
                ? '$I18N_API_INTEGRATION_CORTEX_SETTINGS.VALUES.ACTIONS_NOTIFY_ONLY'
                : '$I18N_API_INTEGRATION_CORTEX_SETTINGS.VALUES.ACTIONS_BLOCK_AND_NOTIFY';
        }
        else {
            return '';
        }
    }
    resolveSeverity(subtype) {
        if (!!this.cortexIntegrationVersion1) {
            const trigger = this.cortexIntegrationVersion1.triggers.find(t => {
                return t.type === 'panw.threat' && t.fields.subtype === subtype;
            });
            return !!trigger ? trigger.fields.severity : 'none';
        }
        else {
            return 'none';
        }
    }
    resolveSetting(triggers, subtype) {
        const splitSetting = triggers.find(setting => setting.fields.subtype === subtype);
        let returnedSetting = {
            config: '',
            type: 'panw.threat',
            fields: {
                subtype: '',
                severity: ''
            },
            action: '',
            enabled: false,
            disableUser: false
        };
        if (splitSetting) {
            returnedSetting = {
                config: splitSetting.fields.subtype,
                type: 'panw.threat',
                fields: {
                    subtype: splitSetting.fields.subtype,
                    severity: splitSetting.fields.severity
                },
                action: splitSetting.action,
                enabled: splitSetting.enabled,
                disableUser: splitSetting.disableUser
            };
        }
        return returnedSetting;
    }
    getInitialSetting(setting, initialSeverity) {
        return {
            config: setting,
            type: 'panw.threat',
            fields: {
                subtype: setting,
                severity: initialSeverity
            },
            action: 'NOTIFY',
            enabled: true,
            disableUser: false
        };
    }
    getIntegrationIdentifier(name) {
        if (name) {
            return decodeURI(name);
        }
    }
}
exports.ApiIntegrationCortexAsideSettingsComponent = ApiIntegrationCortexAsideSettingsComponent;
