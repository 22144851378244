<div class="mc-custom-domain-block-wizard-step-container">
  <mc-wizard-step-header class="mc-supervision-wizard-step-header"
    label="$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.ACTIONS_STEP.DESCRIPTION">
  </mc-wizard-step-header>

  <form class="form-horizontal" mcDefaultLabelClass="col-sm-3" mcDefaultControlContainerClass="col-sm-9"
    [formGroup]="form">
    <div class="form-group radio-action">
      <mc-field label="{{ '$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.ACTIONS_STEP.ACTION.TITLE' | translate }}" data-test="action">
        <div class="radio panel-half-padding-bottom">
          <label class="text-bold">
            <input type="radio" name="action" formControlName="action" [value]="'drop'" [checked]="form.controls['action']?.value === 'drop'" />
            {{
          '$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.ACTIONS_STEP.ACTION.DROP' | translate
        }}
          </label>
        </div>
        <div *mcCapabilities="'Temporary.Custom.Domain.Block.V2'">
        <div class="radio panel-half-padding-bottom actions">
          <label class="text-bold">
            <input type="radio" name="action" formControlName="action" [value]="'redirect_user'" [checked]="form.controls['action']?.value === 'redirect_user'" />
            {{
          '$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.ACTIONS_STEP.ACTION.REDIRECT_USER' | translate
            }}
            <button
            type="button" 
            *ngIf="form.controls.action?.value === 'redirect_user'"
            id="redirectEmail"
            class="btn btn-default"
            (click)="openNotifyToSidePanel.emit('redirectEmail')">
            {{ '$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.ACTIONS_STEP.ACTION.SELECT_USER' | translate }}
            </button>
            <input type="hidden" formControlName="redirectToUser" name="redirectToUser"  />
            <div class="notifyGroups" *ngIf="form.controls.redirectToUser?.value">
              <div class="selected-group">
                <span class="groups-list">
                  <div class="groups-name">{{ form.controls.redirectToUser?.value }}</div>
                  <i class="far fa-trash-alt" aria-hidden="true" (click)="removeRedirectToUser()"></i>
                </span>
                <hr class="groups-separator" />
              </div>
            </div>    
          </label>
        </div>
        <div class="radio panel-half-padding-bottom actions redirectGroup">
          <label class="text-bold">
            <input type="radio" name="action" formControlName="action" [value]="'redirect_group'" [checked]="form.controls['action']?.value === 'redirect_group'" />
            {{
          '$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.ACTIONS_STEP.ACTION.REDIRECT_GROUP' | translate
            }}
            <button
            type="button" 
            *ngIf="form.controls.action?.value === 'redirect_group'"
            id="redirectGroup"
            class="btn btn-default"
            (click)="openNotifyToSidePanel.emit('redirectGroup')">
            {{ '$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.ACTIONS_STEP.ACTION.SELECT_GROUP' | translate }}
            </button>
            <input type="hidden" formControlName="redirectToGroup" name="redirectToGroup"  />
            <div class="notifyGroups" *ngIf="form.controls.redirectToGroup?.value">
              <div class="selected-group">
                <span class="groups-list">
                  <div class="groups-name">{{ form.controls.redirectToGroup?.value?.description }}</div>
                  <i class="far fa-trash-alt" aria-hidden="true" (click)="removeRedirectToGroup()"></i>
                </span>
                <hr class="groups-separator" />
              </div>
            </div>    
          </label>
        </div>
      </div>
      </mc-field>
    </div>

    <div *ngIf="form.controls['action']?.value !== 'drop'">
      <div *mcCapabilities="'Temporary.Custom.Domain.Block.V2'" class="notify">
        <mc-switch-field label="$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.ACTIONS_STEP.NOTIFICATIONS.TITLE"
        [showLabel]="true" formControlName="notify">
        </mc-switch-field>
  
        <mc-field *ngIf="notifyValue"
          label=" "
          labelClass="col-sm-3 notifyGroupsLabel"
        >
          <input type="hidden" formControlName="notifications" />
          <h3>{{"$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.ACTIONS_STEP.NOTIFY.TITLE" | translate}}</h3>
          <button type="button" id="policyGroups" class="btn btn-default" (click)="openNotifyToSidePanel.emit('policyGroup')">
            {{ '$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.ACTIONS_STEP.NOTIFICATIONS.SELECT' | translate }}
          </button>
          <div formArrayName="notifyGroups" class="notifyGroups" *ngIf="form.controls.notifyGroups?.controls">
            <div class="selected-group" *ngFor="let notifyGroup of form.controls.notifyGroups.controls; let i = index">
              <span class="groups-list">
                <div class="groups-name">{{ notifyGroup.value.description }}</div>
                <i class="far fa-trash-alt" aria-hidden="true" (click)="removeGroup.emit()"></i>
              </span>
              <hr class="groups-separator" />
            </div>
          </div>
  
        </mc-field>    
      </div>
  
    </div>   
  </form>
  <mc-errors class="" [errors]="form.errors" *ngIf="!form.valid"
    formPrefix="$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.VALIDATION.FORM"></mc-errors>
</div>