<div class="mc-directory-sync-wizard-container">
  <mc-wizard-step-header [label]="'$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SUMMARY.HEADER'"></mc-wizard-step-header>
    <form
      class="form-horizontal"
      mcDefaultLabelClass="col-sm-4"
      mcDefaultControlContainerClass="col-sm-8"
      [formGroup]="form"
    >
      <div class="row form-group">
        <div class="form-group mc-field">
          <label class="col-sm-4 control-label">
            <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.DETAILS.NAME' | translate }}</span>
          </label>
          <div class="col-sm-8 panel-half-padding-top">
            <span>{{ integration.name }}</span>
          </div>
        </div>
      </div>

      <div class="row form-group">
        <div class="form-group mc-field">
          <label class="col-sm-4 control-label">
            <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.DETAILS.DESCRIPTION' | translate }}</span>
          </label>
          <div class="col-sm-8 panel-half-padding-top">
            <span>{{ integration.description || '-' }}</span>
          </div>
        </div>
      </div>

      <div class="row form-group">
        <div class="form-group mc-field">
          <label class="col-sm-4 control-label">
            <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.DETAILS.TYPE' | translate }}</span>
          </label>
          <div class="col-sm-8 panel-half-padding-top">
            <span>{{ integrationType() }}</span>
          </div>
        </div>
      </div>

      <div *ngIf="isLdapDominoIntegration()" class="row form-group">
        <div class="form-group mc-field">
          <label class="col-sm-4 control-label">
            <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.DOMINO_FOREIGN_DIRECTORY_SYNCHRONIZATION' | translate }}</span>
          </label>
          <div class="col-sm-8 panel-half-padding-top">
            <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.SWITCH_LABEL.'+integration.foreignDirectorySync | uppercase | translate }}</span>
          </div>
        </div>
      </div>

      <div class="row form-group">
        <div class="form-group mc-field">
          <label class="col-sm-4 mc-switch">
            <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SUMMARY.STATUS' | translate }}</span>
          </label>
          <div class="col-sm-8 panel-half-padding-top">
            <mc-switch formControlName="status" role="switch" showLabel="true"></mc-switch>
          </div>
        </div>
      </div>

      <ng-container *ngIf="isLdapIntegration()">
        <div class="row form-group">
          <div class="form-group mc-field">
            <label class="col-sm-4 control-label">
              <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.HOST' | translate }}</span>
            </label>
            <div class="col-sm-8 panel-half-padding-top">
              <span>{{ integration.host }}</span>
            </div>
          </div>
        </div>

        <div class="row form-group">
          <div class="form-group mc-field">
            <label class="col-sm-4 control-label">
              <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.ALTERNATE_HOST' | translate }}</span>
            </label>
            <div class="col-sm-8 panel-half-padding-top">
              <span>{{ integration.alternateHost || '-' }}</span>
            </div>
          </div>
        </div>

        <div class="row form-group">
          <div class="form-group mc-field">
            <label class="col-sm-4 control-label">
              <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.ENCRYPTION_CONNECTION' | translate }}</span>
            </label>
            <div class="col-sm-8 panel-half-padding-top">
              <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.SWITCH_LABEL.'+integration.encryptionConn | uppercase | translate }}</span>
            </div>
          </div>
        </div>

        <div class="row form-group">
          <div class="form-group mc-field">
            <label class="col-sm-4 control-label">
              <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.ENCRYPTION_MODE' | translate }}</span>
            </label>
            <div class="col-sm-8 panel-half-padding-top">
              <span>{{ encryptionModeOptions | titlecase }}</span>
            </div>
          </div>
        </div>

        <div class="row form-group">
          <div class="form-group mc-field">
            <label class="col-sm-4 control-label">
              <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.PORT' | translate }}</span>
            </label>
            <div class="col-sm-8 panel-half-padding-top">
              <span>{{ integration.port }}</span>
            </div>
          </div>
        </div>

        <div class="row form-group">
          <div class="form-group mc-field">
            <label class="col-sm-4 control-label">
              <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.USER_DN' | translate }}</span>
            </label>
            <div class="col-sm-8 panel-half-padding-top">
              <span>{{ integration.userDn }}</span>
            </div>
          </div>
        </div>

        <div class="row form-group">
          <div class="form-group mc-field">
            <label class="col-sm-4 control-label">
              <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.ROOT_DN' | translate }}</span>
            </label>
            <div class="col-sm-8 panel-half-padding-top">
              <span>{{ integration.rootDn || '-' }}</span>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="isAzureIntegration() && !isHecate">
        <div class="row form-group">
          <div class="form-group mc-field">
            <label class="col-sm-4 control-label">
              <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.APP_ID' | translate }}</span>
            </label>
            <div class="col-sm-8 panel-half-padding-top">
              <span>{{ integration.appID }}</span>
            </div>
          </div>
        </div>

        <div class="row form-group">
          <div class="form-group mc-field">
            <label class="col-sm-4 control-label">
              <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.TENANT_DOMAIN' | translate }}</span>
            </label>
            <div class="col-sm-8 panel-half-padding-top">
              <span>{{ integration.tenantDomain }}</span>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="isAzureIntegration() && isHecate">
        <div class="row form-group">
          <div class="form-group mc-field">
            <label class="col-sm-4 control-label">
              <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SUMMARY.CONNECTOR_NAME' | translate }}</span>
            </label>
            <div class="col-sm-8 panel-half-padding-top">
              <span>{{ connectorName }}</span>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="isGsuiteIntegration()">
        <div class="row form-group">
          <div class="form-group mc-field">
            <label class="col-sm-4 control-label">
              <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.EMAIL' | translate }}</span>
            </label>
            <div class="col-sm-8 panel-half-padding-top">
              <span>{{ integration.email }}</span>
            </div>
          </div>
        </div>

        <div class="row form-group">
          <div class="form-group mc-field">
            <label class="col-sm-4 control-label">
              <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.SERVICE_ACCOUNT_JSON' | translate }}</span>
            </label>
            <div class="col-sm-8 panel-half-padding-top">
              <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.SERVICE_ACCOUNT_JSON_PROVIDED' | translate }}</span>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="isMSEIntegration()">
        <div class="row form-group">
          <div class="form-group mc-field">
            <label class="col-sm-4 control-label">
              <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.SYNC_ENGINE_SITE' | translate }}</span>
            </label>
            <div class="col-sm-8 panel-half-padding-top">
              <span>{{ mseSiteDescription }}</span>
            </div>
          </div>
        </div>

        <ng-container *ngIf="isReplicateDiffDomain()">
          <div class="row form-group">
            <div class="form-group mc-field">
              <label class="col-sm-4 control-label">
                <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.HOST' | translate }}</span>
              </label>
              <div class="col-sm-8 panel-half-padding-top">
                <span>{{ integration.host }}</span>
              </div>
            </div>
          </div>

          <div class="row form-group">
            <div class="form-group mc-field">
              <label class="col-sm-4 control-label">
                <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.MSE_USER' | translate }}</span>
              </label>
              <div class="col-sm-8 panel-half-padding-top">
                <span>{{ integration.userDn }}</span>
              </div>
            </div>
          </div>

          <div class="row form-group">
            <div class="form-group mc-field">
              <label class="col-sm-4 control-label">
                <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.ROOT_DN' | translate }}</span>
              </label>
              <div class="col-sm-8 panel-half-padding-top">
                <span>{{ integration.rootDn || '-' }}</span>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <div class="row form-group">
        <div class="form-group mc-field">
          <label class="col-sm-4 control-label">
            <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.OPTIONS.ACKNOWLEDGE_DISABLED_ACCOUNTS' | translate }}</span>
          </label>
          <div class="col-sm-8 panel-half-padding-top">
            <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.SWITCH_LABEL.'+integration.acknowledgeDisabledAccounts | uppercase | translate }}</span>
          </div>
        </div>
      </div>

      <div class="row form-group">
        <div class="form-group mc-field">
          <label class="col-sm-4 control-label">
            <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.OPTIONS.FILTER_EMAIL_DOMAINS' | translate }}</span>
          </label>
          <div class="col-sm-8 panel-half-padding-top">
            <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.SWITCH_LABEL.'+integration.emailDomainsFilter | uppercase | translate }}</span>
          </div>
        </div>
      </div>

      <div class="row form-group" *ngIf="integration.emailDomainsFilter">
        <div class="form-group mc-field">
          <label class="col-sm-4 control-label">
            <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.OPTIONS.DOMAINS' | translate }}</span>
          </label>
          <div class="col-sm-8 panel-half-padding-top">
            <span>{{ integration.domains }}</span>
          </div>
        </div>
      </div>

      <div *ngIf="!isGsuiteIntegration() && !isMSEIntegration() && !isLdapDominoIntegration()" class="row form-group">
        <div class="form-group mc-field">
          <label class="col-sm-4 control-label">
            <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.OPTIONS.INCLUDE_CONTACTS' | translate }}</span>
          </label>
          <div class="col-sm-8 panel-half-padding-top">
            <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.SWITCH_LABEL.'+integration.includeContacts | uppercase | translate }}</span>
          </div>
        </div>
      </div>

      <div *ngIf="isAzureIntegration()" class="row form-group">
        <div class="form-group mc-field">
          <label class="col-sm-4 control-label">
            <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.OPTIONS.INCLUDE_GUEST_ACCOUNTS' | translate }}</span>
          </label>
          <div class="col-sm-8 panel-half-padding-top">
            <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.SWITCH_LABEL.'+integration.includeGuestAccounts | uppercase | translate }}</span>
          </div>
        </div>
      </div>

      <div class="row form-group">
        <div class="form-group mc-field">
          <label class="col-sm-4 control-label">
            <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.OPTIONS.MAXIMUM_SYNC_DELETIONS' | translate }}</span>
          </label>
          <div class="col-sm-8 panel-half-padding-top">
            <span>{{ integration?.maxSyncDeletion?.split('_').pop() | titlecase }}</span>
          </div>
        </div>
      </div>

      <div class="row form-group">
        <div class="form-group mc-field">
          <label class="col-sm-4 control-label">
            <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.OPTIONS.DELETE_USERS' | translate }}</span>
          </label>
          <div class="col-sm-8 panel-half-padding-top">
            <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.SWITCH_LABEL.'+integration.deleteUsers | uppercase | translate }}</span>
          </div>
        </div>
      </div>
    </form>
</div>
