"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueueDetailsBuilder = void 0;
const queue_details_1 = require("../queue-details");
class QueueDetailsBuilder {
    static build(queueDetailsData) {
        const name = queueDetailsData['name'];
        const totalCount = queueDetailsData['totalCount'];
        const newInLastSevenDays = queueDetailsData['newInLastSevenDays'];
        const pendingCount = queueDetailsData['pendingCount'];
        const percentageOfPendingMessages = QueueDetailsBuilder.getPercentageOfPendingMessages(pendingCount, totalCount);
        const nonCompliantCount = queueDetailsData['nonCompliantCount'];
        const oldestPendingHours = queueDetailsData['oldestPendingHours'];
        const failed = queueDetailsData['failed'];
        return new queue_details_1.QueueDetails(name, totalCount, newInLastSevenDays, pendingCount, percentageOfPendingMessages, nonCompliantCount, oldestPendingHours, failed);
    }
    static getPercentageOfPendingMessages(pendingCount, totalCount) {
        return totalCount > 0 ? Math.round((pendingCount / totalCount) * 100) : 0;
    }
}
exports.QueueDetailsBuilder = QueueDetailsBuilder;
