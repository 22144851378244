<mc-collapsable-panel
  header="{{ '$I18N_ARCHIVE_SUPERVISION_QUEUE_DETAILS.DETAILS.TITLE' | translate }}"
  [isExpanded]="true"
>
  <div class="mc-detailed-list mc-detailed-list-column">
    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_ARCHIVE_SUPERVISION_QUEUE_DETAILS.DETAILS.NAME' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ queueDetails.name }}</div>
    </div>

    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_ARCHIVE_SUPERVISION_QUEUE_DETAILS.DETAILS.DESCRIPTION' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ queueDetails.description }}</div>
    </div>

    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_ARCHIVE_SUPERVISION_QUEUE_DETAILS.DETAILS.TYPE' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ queueDetails.type | mcQueueType }}</div>
    </div>

    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_ARCHIVE_SUPERVISION_QUEUE_DETAILS.DETAILS.CREATED_BY' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ queueDetails.createdBy }}</div>
    </div>

    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_ARCHIVE_SUPERVISION_QUEUE_DETAILS.DETAILS.MODIFIED_DATE' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ queueDetails.updated | mcDate }}</div>
    </div>

    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_ARCHIVE_SUPERVISION_QUEUE_DETAILS.DETAILS.STATUS' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ queueDetails.status | mcQueueStatus }}</div>
    </div>

    <div *ngIf="isReviewQueue" class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_ARCHIVE_SUPERVISION_QUEUE_DETAILS.DETAILS.ESCALATION_QUEUES' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        <div *ngFor="let queue of escalationQueues" data-test="escalationQueue">
          {{ queue.name }} {{ isDefault(queue.id) }}
        </div>
      </div>
    </div>
  </div>
</mc-collapsable-panel>

<mc-collapsable-panel
  *ngIf="!isReviewQueue"
  header="{{
    '$I18N_ARCHIVE_SUPERVISION_QUEUE_DETAILS.ASSOCIATED_REVIEW_QUEUES.TITLE' | translate
  }}"
  [isExpanded]="true"
>
  <div class="mc-detailed-list mc-detailed-list-column">
    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_ARCHIVE_SUPERVISION_QUEUE_DETAILS.ASSOCIATED_REVIEW_QUEUES.QUEUES' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        <div *ngFor="let queue of associatedReviewQueues">{{ queue.name }}</div>
      </div>
    </div>
  </div>
</mc-collapsable-panel>

<mc-collapsable-panel
  *ngIf="isReviewQueue"
  header="{{ '$I18N_ARCHIVE_SUPERVISION_QUEUE_DETAILS.LINKED_RULES.TITLE' | translate }}"
  [isExpanded]="true"
>
  <div class="mc-detailed-list mc-detailed-list-column">
    <div *ngIf="isReviewQueue" class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_ARCHIVE_SUPERVISION_QUEUE_DETAILS.LINKED_RULES.RULES' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        <div *ngFor="let rule of associatedRules">{{ rule.name }}</div>
      </div>
    </div>
  </div>
</mc-collapsable-panel>

<mc-collapsable-panel
  header="{{ '$I18N_ARCHIVE_SUPERVISION_QUEUE_DETAILS.SETTINGS.TITLE' | translate }}"
  [isExpanded]="true"
>
  <div class="mc-detailed-list mc-detailed-list-column">
    <div *ngIf="!isReviewQueue" class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_ARCHIVE_SUPERVISION_QUEUE_DETAILS.SETTINGS.ALLOW_PRINTING' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ queueDetails.printAllowed | mcToggle }}</div>
    </div>

    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_ARCHIVE_SUPERVISION_QUEUE_DETAILS.SETTINGS.ALLOW_FORWARDING' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ queueDetails.forwardAllowed | mcToggle }}</div>
    </div>

    <div class="row mc-detailed-list-row">
      <div *ngIf="!isReviewQueue" class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_ARCHIVE_SUPERVISION_QUEUE_DETAILS.SETTINGS.ESCALATION_MANAGERS' | translate }}
      </div>
      <div *ngIf="isReviewQueue" class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_ARCHIVE_SUPERVISION_QUEUE_DETAILS.SETTINGS.REVIEWERS' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        <div *ngFor="let reviewer of reviewers">{{ reviewer }}</div>
      </div>
    </div>

    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_ARCHIVE_SUPERVISION_QUEUE_DETAILS.SETTINGS.LABELS' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        <div *ngFor="let label of labels">{{ label }}</div>
      </div>
    </div>
  </div>
</mc-collapsable-panel>
