"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const list_component_1 = require("./policies/components/list/list.component");
const bi_policy_create_page_component_1 = require("./policies/containers/create/bi-policy-create-page.component");
angular
    .module('biPolicy', [])
    .directive('mcBiPolicyList', static_1.downgradeComponent({
    component: list_component_1.ListComponent
}))
    .directive('mcBiPolicyCreate', static_1.downgradeComponent({
    component: bi_policy_create_page_component_1.BiPolicyCreatePageComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider.state('bi-policy-create', {
            url: '/bi/policy',
            data: {
                capabilities: 'Permission.BI_CONFIGURE_POLICIES_READ',
                isFixedLayout: false,
                checkProgress: false,
                tabReload: true,
                tabId: 'Swg-policies',
                tabTitle: '$I18N_MEGA_MENU_SERVICES_BROWSER_ISOLATION',
                breadcrumbs: [
                    {
                        label: '$I18N_MEGA_MENU_GROUP_SERVICES_LABEL'
                    },
                    {
                        label: '$I18N_MEGA_MENU_SERVICES_BROWSER_ISOLATION'
                    }
                ]
            },
            views: {
                main: {
                    template: '<mc-bi-policy-create></mc-bi-policy-create>'
                }
            }
        });
    }
])
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider.state('bi-policy-list', {
            url: '/bi/policy-list',
            params: {
                showNotification: ''
            },
            data: {
                capabilities: 'Permission.BI_CONFIGURE_POLICIES_READ',
                isFixedLayout: false,
                checkProgress: false,
                tabReload: true,
                tabId: 'Swg-policies',
                tabTitle: '$I18N_MEGA_MENU_SERVICES_BROWSER_ISOLATION'
            },
            views: {
                main: {
                    template: '<mc-bi-policy-list></mc-bi-policy-list>'
                }
            }
        });
    }
]);
