"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DriveUsageBarComponent = void 0;
class DriveUsageBarComponent {
    constructor(toBytesPipe) {
        this.toBytesPipe = toBytesPipe;
        this.isAvailable = false;
    }
    set totalSpace(bytes) {
        this.maximum = this.parseNumber(bytes);
        this.totalAllowance = this.maximum ? this.toBytesPipe.transform(this.maximum) : '';
    }
    set usedSpace(bytes) {
        this.val = this.parseNumber(bytes);
        this.usage = this.val ? this.toBytesPipe.transform(this.val) : '';
        this.title =
            this.val === 0 ? '$I18N_DRIVE_USAGE_BAR.TITLE_NO_USAGE' : '$I18N_DRIVE_USAGE_BAR.TITLE_USAGE';
    }
    parseNumber(value) {
        const parsedBytes = parseInt(String(value), 10);
        return !isNaN(parsedBytes) ? parsedBytes : undefined;
    }
}
exports.DriveUsageBarComponent = DriveUsageBarComponent;
