"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./modal-max-retention-service"); //modalMaxRetention.service
require("./modal-max-retention-controller"); //modalMaxRetention.controller
// html and css 
require("./modal-max-retention.less");
require("./modal-max-retention.tpl.html");
/**
 * Created by jcarradinha on 16/05/16.
 */
angular.module('modalMaxRetention', ['modalMaxRetention.service', 'modalMaxRetention.controller']);
