"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getIsEmailFields = exports.IsEmail = void 0;
require("reflect-metadata");
const forms_1 = require("@angular/forms");
const IS_EMAIL_METADATA_KEY = Symbol('MC-IS-EMAIL-FIELD');
function IsEmail() {
    return (target, propertyKey) => {
        let isEmailFields = Reflect.getMetadata(IS_EMAIL_METADATA_KEY, target);
        if (isEmailFields) {
            isEmailFields.push(propertyKey);
        }
        else {
            isEmailFields = [propertyKey];
        }
        Reflect.defineMetadata(IS_EMAIL_METADATA_KEY, isEmailFields, target);
    };
}
exports.IsEmail = IsEmail;
function getIsEmailFields(origin) {
    const properties = Reflect.getMetadata(IS_EMAIL_METADATA_KEY, origin);
    const result = {};
    if (properties) {
        properties.forEach(key => (result[key] = forms_1.Validators.email));
    }
    return result;
}
exports.getIsEmailFields = getIsEmailFields;
