"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BiPolicySideBarContainerComponent = void 0;
const reducers = require("../../reducers");
const sidebar = require("../../actions/sidebar.actions");
const rxjs_1 = require("rxjs");
class BiPolicySideBarContainerComponent {
    constructor($state, store, swgService, capabilitiesService) {
        this.$state = $state;
        this.store = store;
        this.swgService = swgService;
        this.capabilitiesService = capabilitiesService;
    }
    ngOnInit() {
        this.policy$ = this.store.select(reducers.getBiPolicy);
        this.loadingForEdit$ = rxjs_1.of(false);
        this.hasPolicyEditPermission$ = this.capabilitiesService.hasCapability('Permission.SWG_CONFIGURE_POLICIES_EDIT');
    }
    deletePolicy($event) {
        this.store.dispatch(new sidebar.BiDetailDeleteConfirmModelOpen($event));
    }
    editPolicy() {
        this.store.dispatch(new sidebar.BiPolicyListSidebarClose());
    }
    duplicatePolicy() {
        this.store.dispatch(new sidebar.BiPolicyListSidebarClose());
    }
    close() {
        this.store.dispatch(new sidebar.BiPolicyListSidebarClose());
    }
}
exports.BiPolicySideBarContainerComponent = BiPolicySideBarContainerComponent;
