<div class="mc-integration-okta-details-step-block">
  <mc-wizard-step-header [label]="'$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.DETAILS.HEADER'">
  </mc-wizard-step-header>

  <div class="mc-detailed-list mc-api-integration-okta-details-block">
    <div class="mc-api-integration-okta-details-block-description">
      {{ '$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.DETAILS.SUBHEADER' | translate }}
    </div>

    <form
      class="form-horizontal"
      mcDefaultLabelClass="col-sm-3"
      mcDefaultControlContainerClass="col-sm-9"
      [formGroup]="form"
    >
      <div class="row form-group">
        <mc-text-field
          label="$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.DETAILS.NAME"
          formControlName="name"
          errorPrefix="$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.DETAILS.VALIDATION_NAME"
          required
        >
        </mc-text-field>
      </div>

      <div class="row form-group panel-half-padding-top">
        <mc-text-area-field
          label="$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.DETAILS.DESCRIPTION"
          formControlName="description"
          errorPrefix="$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.DETAILS.VALIDATION_DESCRIPTION"
          required
        >
        </mc-text-area-field>
      </div>
    </form>
  </div>
</div>
