"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IncidentsStatusesDictionary = exports.IncidentsActionsDictionary = exports.tableColumns = void 0;
exports.tableColumns = ['incidentCode', 'from', 'to', 'action', 'status'];
var IncidentsActionsDictionary;
(function (IncidentsActionsDictionary) {
    IncidentsActionsDictionary["identified"] = "IDENTIFIED";
    IncidentsActionsDictionary["remediate"] = "REMEDIATE";
    IncidentsActionsDictionary["restore"] = "RESTORE";
})(IncidentsActionsDictionary = exports.IncidentsActionsDictionary || (exports.IncidentsActionsDictionary = {}));
var IncidentsStatusesDictionary;
(function (IncidentsStatusesDictionary) {
    IncidentsStatusesDictionary["active"] = "ACTIVE";
    IncidentsStatusesDictionary["remediated"] = "REMEDIATED";
    IncidentsStatusesDictionary["remediate_failure"] = "REMEDIATE_FAILURE";
    IncidentsStatusesDictionary["restored"] = "RESTORED";
    IncidentsStatusesDictionary["restore_failure"] = "RESTORE_FAILURE";
    IncidentsStatusesDictionary["excluded"] = "EXCLUDED";
    IncidentsStatusesDictionary["tagged"] = "TAGGED";
    IncidentsStatusesDictionary["processing"] = "PROCESSING";
    IncidentsStatusesDictionary["held"] = "HELD";
    IncidentsStatusesDictionary["pending_delivery"] = "PENDING_DELIVERY";
    IncidentsStatusesDictionary["not_found"] = "NOT_FOUND";
})(IncidentsStatusesDictionary = exports.IncidentsStatusesDictionary || (exports.IncidentsStatusesDictionary = {}));
