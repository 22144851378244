"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchMessagesBaseDirective = void 0;
const core_1 = require("@angular/core");
const components_1 = require("@mimecast-ui/components");
class SearchMessagesBaseDirective {
    constructor(fb) {
        this.fb = fb;
        this.inlineForm = false;
        this.search = new core_1.EventEmitter();
    }
    ngOnChanges(changes) {
        const change = changes['isRemediationEnabled'];
        if (change && change.previousValue !== change.currentValue) {
            if (change.currentValue) {
                this.form.enable();
                this.form.markAsPristine();
            }
            else {
                this.form.disable();
            }
        }
    }
    set formValues(value) {
        this.form.reset();
        this.form.patchValue({
            dateRange: Object.assign({}, value.dateRange),
            subject: value.subject,
            fileHash: value.fileHash,
            start: value.start,
            end: value.end,
            to: value.to,
            messageId: value.messageId,
            from: value.from,
            url: value.url
        });
        this.form.markAsPristine();
    }
    onSubmit() {
        this.search.emit(this.transformSearchPayload(this.form.value));
    }
    transformSearchPayload(form) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { dateRange } = form, rest = __rest(form, ["dateRange"]);
        const searchRequest = Object.assign(Object.assign({}, rest), this.formatDateRange(form));
        return searchRequest;
    }
    formatDateRange(form) {
        if (form.dateRange && form.dateRange.rangeFn) {
            return {
                start: form.dateRange.rangeFn().start.format(components_1.API_DATE_FORMAT),
                end: form.dateRange.rangeFn().end.format(components_1.API_DATE_FORMAT),
                dateRange: Object.assign({}, form.dateRange)
            };
        }
        return {};
    }
}
exports.SearchMessagesBaseDirective = SearchMessagesBaseDirective;
