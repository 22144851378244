"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getContextualAccountSession = exports.getSwitchAccountSession = exports.getLegacySession = exports.reducer = exports.initialState = void 0;
const actions = require("../actions");
exports.initialState = { originatingSession: true };
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.legacy.LOGIN_SUCCESS:
            return Object.assign(Object.assign({}, state), { legacySession: action.payload });
        case actions.legacy.STORE_SWITCH_ACCOUNT_SESSION:
            return Object.assign(Object.assign({}, state), { switchAccountSession: action.payload, originatingSession: false });
        case actions.legacy.SWITCH_ACCOUNT:
            return Object.assign(Object.assign({}, state), { switchAccountSession: action.payload, originatingSession: action.payload.switchtype === 'servicedelivery' });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getLegacySession = (state) => state.legacySession;
exports.getLegacySession = getLegacySession;
const getSwitchAccountSession = (state) => state.switchAccountSession;
exports.getSwitchAccountSession = getSwitchAccountSession;
const getContextualAccountSession = (state) => !state.originatingSession ? state.switchAccountSession : undefined;
exports.getContextualAccountSession = getContextualAccountSession;
