<mc-modal-header
  headerTitle="{{ (type === 'remediate' ?  '$I18N_MESSAGES_MODAL_ACTION.REMEDIATE.MODAL_TITLE' : '$I18N_MESSAGES_MODAL_ACTION.RESTORE.MODAL_TITLE')
  | translate: {count: count, plural: count > 1? 's' : ''} }}"></mc-modal-header>
<mc-modal-body>
  <p
    class="mc-no-margin-bottom">{{ (type === 'remediate' ? '$I18N_MESSAGES_MODAL_ACTION.REMEDIATE.DESC_1' : '$I18N_MESSAGES_MODAL_ACTION.RESTORE.DESC_1')|translate }}</p>
  <p>
    {{ (type === 'remediate' ? '$I18N_MESSAGES_MODAL_ACTION.REMEDIATE.DESC_2' : '$I18N_MESSAGES_MODAL_ACTION.RESTORE.DESC_2')|translate }}
  </p>
  <p
    *ngIf="type === 'remediate' && showMsaRemediateOptions">{{'$I18N_MESSAGES_MODAL_ACTION.REMEDIATE.DESC_3'|translate }}
  </p>
  <form [formGroup]="form" class="form-vertical">
    <mc-checkbox *ngIf="showMsaRemediateOptions"
                 [label]="'$I18N_MESSAGES_MODAL_ACTION.REMOVE_DOWNLOADED_ATTACHMENTS'"
                 formControlName="removeDownloadedAttachments"></mc-checkbox>
    <div [hidden]="!form.get('removeDownloadedAttachments').value">

      <mc-field label="$I18N_MESSAGES_MODAL_ACTION.REMOVE_FROM" *ngIf="showMsaRemediateOptions">
        <mc-select
          formControlName="removeFrom"
          [options]="removeFromOptions"
        >
        </mc-select>
      </mc-field>
      <mc-field label="$I18N_MESSAGES_MODAL_ACTION.ATTEMPT_REMOVAL_FOR" *ngIf="showMsaRemediateOptions">
        <mc-select
          formControlName="daysToExpire"
          [options]="attemptDuration"
        >
        </mc-select>

      </mc-field>
    </div>
    <mc-text-area-field
      label="$I18N_MESSAGES_MODAL_ACTION.REASON"
      errorPrefix="$I18N_MESSAGES_MODAL_ACTION.ERRORS"
      required
      formControlName="reason">
    </mc-text-area-field>
  </form>

</mc-modal-body>
<mc-modal-footer>
  <button class="btn btn-secondary" type="button" [disabled]="isRemediateMessagesLoading$ | async" (click)="close()">
    {{ '$I18N_MESSAGES_MODAL_ACTION.CANCEL_BUTTON' | translate }}
  </button>
  <mc-live-button
    overrideClasses="btn-primary"
    [label]="(type === 'remediate' ? '$I18N_MESSAGES_MODAL_ACTION.REMEDIATE.SUBMIT_BUTTON' : '$I18N_MESSAGES_MODAL_ACTION.RESTORE.SUBMIT_BUTTON') | translate"
    [mcDisabled]="!form.valid"
    [isLoading]="isRemediateMessagesLoading$ | async"
    (mcClick)="createIncident()">
  </mc-live-button>
</mc-modal-footer>
