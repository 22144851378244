
    <mc-application-aside-view
      [application]="application"
      [active]="active$ | async"
      [appKey]="appKey$ | async"
      [showKey]="showKey$ | async"
      [gettingKey]="gettingKey$ | async"
      (onGetKeyClicked)="getKey()"
      (onShowKeyClicked)="showKey()"
      (onHideKeyClicked)="hideKey()"
      (onKeyCopied)="keyCopied($event)"
      (onEnableClicked)="enable()"
      (onDisableClicked)="disable()"
      (onEditClicked)="edit()"
      (onDeleteClicked)="delete()"
      (onGenKeysClicked)="genKeys()"
      (onGetCredentialsClicked)="getCredentials()"
      (onCloseClicked)="close()"
    >
    </mc-application-aside-view>
  