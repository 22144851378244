"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArchiveViewerSearchPageComponent = void 0;
const components_1 = require("@mimecast-ui/components");
const action = require("../../actions/search.action");
const reducers = require("../../reducers");
const archive_viewer_slider_component_1 = require("../archive-viewer-slider/archive-viewer-slider.component");
class ArchiveViewerSearchPageComponent {
    constructor(store, service, archiveViewerApiService, archiveViewerService, locationService) {
        this.store = store;
        this.service = service;
        this.archiveViewerApiService = archiveViewerApiService;
        this.archiveViewerService = archiveViewerService;
        this.locationService = locationService;
        this.size = 'lg';
        this.hasBackdrop = true;
        this.key = '';
        this.columnList = [
            'sender',
            'recipient',
            'subject',
            'size',
            'receiveDate',
            'id',
            'right-column'
        ];
        this.subscribeStore = store.subscribe(state => {
            var _a, _b;
            if (state.archiveViewer.search.tokenResult) {
                this.key = (_b = (_a = state.archiveViewer.search.tokenResult) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.id;
            }
            if (state.archiveViewer.search.query) {
                this.currentSearch = state.archiveViewer.search.query;
            }
        });
    }
    onRowClick(rowThatIClicked) {
        const searchValues = {
            reason: this.currentSearch.reason,
            token: rowThatIClicked.id,
            dateRange: {
                id: this.currentSearch.dateRange.id,
                label: this.currentSearch.dateRange.label,
                range: {
                    start: {
                        _d: this.currentSearch.dateRange.range.start._d
                    },
                    end: {
                        _d: this.currentSearch.dateRange.range.end._d
                    }
                }
            }
        };
        this.openAside(rowThatIClicked);
        this.store.dispatch(new action.TokenSearchSliderAction(searchValues));
    }
    searchContent(searchValues, meta) {
        if (meta) {
            searchValues = Object.assign(Object.assign({}, searchValues), { meta });
        }
        this.store.dispatch(new action.ContentSearchAction(searchValues));
    }
    searchToken(searchValues) {
        searchValues.dateRange = Object.assign({}, searchValues.dateRange);
        if (searchValues.dateRange.range) {
            searchValues.dateRange.range = Object.assign({}, searchValues.dateRange.range);
        }
        this.store.dispatch(new action.TokenSearchAction(searchValues));
    }
    openAside(data, position = 'right') {
        const config = new components_1.AsideConfig();
        config.data = data;
        config.position = position;
        config.size = this.size;
        config.hasBackdrop = this.hasBackdrop;
        const aside = this.service.open(archive_viewer_slider_component_1.ArchiveViewerSliderComponent, config);
        this.subscribeCallBackSubscription = aside.beforeClose().subscribe(results => {
            this.callbackResult = results;
        });
    }
    selectedDownload(e) {
        if (e.toUpperCase() === 'DOWNLOAD') {
            this.subscribeDownloadSubscription = this.archiveViewerApiService
                // @ts-ignore
                .getDownloadXml(this.key)
                .subscribe((response) => {
                this.archiveViewerApiService.createIFrame(this.locationService.origin + response.body);
            });
        }
        else {
            // Download Token All dropdown
            this.subscribeAllDownloadSubscription = this.archiveViewerApiService
                // @ts-ignore
                .getDownloadAllXml(this.key)
                .subscribe((response) => {
                this.archiveViewerApiService.createIFrame(this.locationService.origin + response.body);
            });
        }
    }
    selectedPartialDownload(tokenSelected) {
        // archivePartial xml download
        let deliveryComponentsArray;
        let messageComponentsArray;
        this.subscribeStorePartialDownload = this.store.subscribe(state => {
            if (state.archiveViewer.search.tokenResult.value) {
                deliveryComponentsArray = state.archiveViewer.search.tokenResult.value.deliveryComponents;
                messageComponentsArray = state.archiveViewer.search.tokenResult.value.messageComponents;
            }
        });
        // @ts-ignore
        deliveryComponentsArray.find((x) => {
            if (x.token === tokenSelected) {
                this.subscribeGetDeliveryDetail = this.archiveViewerApiService
                    // @ts-ignore
                    .getDeliveryDetail(x.id)
                    .subscribe(response => {
                    this.partialXmlTag = response;
                });
            }
        });
        // @ts-ignore
        messageComponentsArray.find((x) => {
            if (x.token === tokenSelected) {
                this.subscribeGetDownloadXmlSubscription = this.archiveViewerApiService
                    // @ts-ignore
                    .getDownloadXml(x.id)
                    .subscribe((response) => {
                    this.archiveViewerApiService.createIFrame(this.locationService.origin + response.body);
                });
            }
        });
    }
    getMessageInfo(e, id) {
        const searchValues = {
            reason: this.currentSearch.reason,
            token: id,
            dateRange: {
                id: this.currentSearch.dateRange.id,
                label: this.currentSearch.dateRange.label,
                range: {
                    start: {
                        _d: this.currentSearch.dateRange.range.start._d
                    },
                    end: {
                        _d: this.currentSearch.dateRange.range.end._d
                    }
                }
            }
        };
        this.store.dispatch(new action.TokenSearchSliderAction(searchValues));
        this.archiveViewerService.getEmailDetail(searchValues).subscribe((res) => {
            if (e.toUpperCase() === 'DOWNLOAD') {
                this.archiveViewerApiService
                    .getDownloadXml(res.id)
                    .subscribe((response) => {
                    this.archiveViewerApiService.createIFrame(this.locationService.origin + response.body);
                });
            }
            else {
                this.archiveViewerApiService
                    .getDownloadAllXml(res.id)
                    .subscribe((response) => {
                    this.archiveViewerApiService.createIFrame(this.locationService.origin + response.body);
                });
            }
        });
    }
    onPaginatorChange(e) {
        const meta = {
            pagination: {
                pageToken: e.pageToken,
                pageNumber: e.pageNumber,
                pageSize: e.pageSize
            }
        };
        this.searchContent(this.currentSearch, meta);
    }
    ngOnInit() {
        this.listContent$ = this.store.select(reducers.getResults);
        this.isLoading$ = this.store.select(reducers.isLoading);
        this.tableData$ = this.store.select(reducers.getResults);
        this.selectedEmail$ = this.store.select(reducers.getSelectedEmail);
        this.tokenResults$ = this.store.select(reducers.getTokenResults);
        this.metadata$ = this.store.select(reducers.getPagination);
    }
    ngOnDestroy() {
        if (this.subscribeCallBackSubscription) {
            this.subscribeCallBackSubscription.unsubscribe();
        }
        if (this.subscribeStore) {
            this.subscribeStore.unsubscribe();
        }
        if (this.subscribeStorePartialDownload) {
            this.subscribeStorePartialDownload.unsubscribe();
        }
        if (this.subscribeGetDeliveryDetail) {
            this.subscribeGetDeliveryDetail.unsubscribe();
        }
        if (this.subscribeGetDownloadXmlSubscription) {
            this.subscribeGetDownloadXmlSubscription.unsubscribe();
        }
        if (this.subscribeDownloadSubscription) {
            this.subscribeDownloadSubscription.unsubscribe();
        }
        if (this.subscribeAllDownloadSubscription) {
            this.subscribeAllDownloadSubscription.unsubscribe();
        }
    }
}
exports.ArchiveViewerSearchPageComponent = ArchiveViewerSearchPageComponent;
