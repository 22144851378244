"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetIncidentFailureSummaryFailureAction = exports.GetIncidentFailureSummarySuccessAction = exports.GetIncidentFailureSummaryAction = exports.RemediationIncidentSummaryResetAction = exports.RemediationIncidentSummaryFailureAction = exports.RemediationIncidentSummaryShowResultsAction = exports.RemediationIncidentSummarySuccessAction = exports.RemediationIncidentSummaryGetAction = exports.GET_INCIDENT_FAILURE_SUMMARY_FAILURE = exports.GET_INCIDENT_FAILURE_SUMMARY_SUCCESS = exports.GET_INCIDENT_FAILURE_SUMMARY = exports.REMEDIATION_INCIDENT_SUMMARY_RESET = exports.REMEDIATION_INCIDENT_SUMMARY_FAILURE = exports.REMEDIATION_INCIDENT_SUMMARY_SHOW_RESULTS = exports.REMEDIATION_INCIDENT_SUMMARY_SUCCESS = exports.REMEDIATION_INCIDENT_SUMMARY_GET = void 0;
exports.REMEDIATION_INCIDENT_SUMMARY_GET = '[Remediation] Incident Summary Get';
exports.REMEDIATION_INCIDENT_SUMMARY_SUCCESS = '[Remediation] Incident Summary Success';
exports.REMEDIATION_INCIDENT_SUMMARY_SHOW_RESULTS = '[Remediation] Incident Summary Results List';
exports.REMEDIATION_INCIDENT_SUMMARY_FAILURE = '[Remediation] Get Incident Summary Failure';
exports.REMEDIATION_INCIDENT_SUMMARY_RESET = '[Remediation] Get Incident Summary Reset';
exports.GET_INCIDENT_FAILURE_SUMMARY = '[Remediation] Get Incident Failure Stats';
exports.GET_INCIDENT_FAILURE_SUMMARY_SUCCESS = '[Remediation] Get Incident Failure Stats Success';
exports.GET_INCIDENT_FAILURE_SUMMARY_FAILURE = '[Remediation] Get Incident Failure Stats Failure';
class RemediationIncidentSummaryGetAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.REMEDIATION_INCIDENT_SUMMARY_GET;
    }
}
exports.RemediationIncidentSummaryGetAction = RemediationIncidentSummaryGetAction;
class RemediationIncidentSummarySuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.REMEDIATION_INCIDENT_SUMMARY_SUCCESS;
    }
}
exports.RemediationIncidentSummarySuccessAction = RemediationIncidentSummarySuccessAction;
class RemediationIncidentSummaryShowResultsAction {
    constructor() {
        this.type = exports.REMEDIATION_INCIDENT_SUMMARY_SHOW_RESULTS;
    }
}
exports.RemediationIncidentSummaryShowResultsAction = RemediationIncidentSummaryShowResultsAction;
class RemediationIncidentSummaryFailureAction {
    constructor() {
        this.type = exports.REMEDIATION_INCIDENT_SUMMARY_FAILURE;
    }
}
exports.RemediationIncidentSummaryFailureAction = RemediationIncidentSummaryFailureAction;
class RemediationIncidentSummaryResetAction {
    constructor() {
        this.type = exports.REMEDIATION_INCIDENT_SUMMARY_RESET;
    }
}
exports.RemediationIncidentSummaryResetAction = RemediationIncidentSummaryResetAction;
class GetIncidentFailureSummaryAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_INCIDENT_FAILURE_SUMMARY;
    }
}
exports.GetIncidentFailureSummaryAction = GetIncidentFailureSummaryAction;
class GetIncidentFailureSummarySuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_INCIDENT_FAILURE_SUMMARY_SUCCESS;
    }
}
exports.GetIncidentFailureSummarySuccessAction = GetIncidentFailureSummarySuccessAction;
class GetIncidentFailureSummaryFailureAction {
    constructor() {
        this.type = exports.GET_INCIDENT_FAILURE_SUMMARY_FAILURE;
    }
}
exports.GetIncidentFailureSummaryFailureAction = GetIncidentFailureSummaryFailureAction;
