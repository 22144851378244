"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdconTimeZoneOffsetService = void 0;
const reducers = require("app-new/core/reducers");
const moment = require("moment-timezone");
const operators_1 = require("rxjs/operators");
const components_1 = require("@mimecast-ui/components");
const i0 = require("@angular/core");
const i1 = require("@ngrx/store");
class AdconTimeZoneOffsetService extends components_1.DatePipeService {
    constructor(store) {
        super();
        this.store = store;
        this.timeZoneOffset = '';
        this.getTimeZoneOffset().subscribe((offset) => {
            this.timeZoneOffset = offset;
        });
    }
    getTimeZoneOffset() {
        return this.store.select(reducers.getUserProfile).pipe(operators_1.filter((userProfile) => !!userProfile), operators_1.map((userProfile) => {
            return moment.tz(userProfile.serverTimeZone).format('Z');
        }));
    }
}
exports.AdconTimeZoneOffsetService = AdconTimeZoneOffsetService;
AdconTimeZoneOffsetService.ɵprov = i0.ɵɵdefineInjectable({ factory: function AdconTimeZoneOffsetService_Factory() { return new AdconTimeZoneOffsetService(i0.ɵɵinject(i1.Store)); }, token: AdconTimeZoneOffsetService, providedIn: "root" });
