"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Vendors
const angular = require("angular");
require("angular-aside"); // ngAside
require("angular-growl-v2"); // angular-growl
require("angular-ui-router"); // ui.router
require("mimecast-ngprogress"); // ngProgress
require("angular-ui-bootstrap"); // ui.bootstrap.modal
require("angular-ui-switch"); // uiSwitch
require("moment");
require("lodash");
require("bootstrap");
require("rxjs");
require("highcharts");
require("bootstrap-datepicker");
require("@angular/compiler");
// WEB-COMPONENTS FEATURES
require("mimecast-web-components/src/app/components/bootstrap-file-input/bootstrap-file-input"); // bootstrap-file-input
require("mimecast-web-components/src/app/components/capabilities/capabilities"); // capabilities
require("mimecast-web-components/src/app/components/checkbox/checkbox-directive"); // checkbox.directive
require("mimecast-web-components/src/app/components/connect/connect-compact");
require("mimecast-web-components/src/app/components/click-disable/click-disable-directive"); // click.disable.directive
require("mimecast-web-components/src/app/components/click-disable/click-disable"); // click.disable
require("mimecast-web-components/src/app/components/dropdowns/dropdown-list/dropdown-list"); // dropdown
require("mimecast-web-components/src/app/components/extended-link-panel/extended-link-panel-directive"); // mc-extended-link-panels
require("mimecast-web-components/src/app/components/field-confirmation/field-confirmation"); // field-confirmation
require("mimecast-web-components/src/app/components/focus/focus-directive"); // focus.directive
require("mimecast-web-components/src/app/components/form/form"); // form
require("mimecast-web-components/src/app/components/form/predefined-field/domain/domain"); // predefined-field.domain
require("mimecast-web-components/src/app/components/form/predefined-field/email/email"); // predefined-field.email
require("mimecast-web-components/src/app/components/form/predefined-field/host-ip/host-ip"); // predefined-field.hostip
require("mimecast-web-components/src/app/components/form/predefined-field/number/number"); // predefined-field.number
require("mimecast-web-components/src/app/components/form/predefined-field/password/password"); // predefined-field.password
require("mimecast-web-components/src/app/components/form/predefined-field/regex/regex"); // predefined-field.regex
require("mimecast-web-components/src/app/components/form/predefined-field/text/text"); // predefined-field.text
require("mimecast-web-components/src/app/components/form/validators/domain-directive"); // validators.domain.directive
require("mimecast-web-components/src/app/components/form/validators/email-directive"); // validators.email.directive
require("mimecast-web-components/src/app/components/form/validators/host-ip-directive"); // validators.hostip.directive
require("mimecast-web-components/src/app/components/form/validators/password-directive"); // validators.password.directive
require("mimecast-web-components/src/app/components/form/validators/text-directive"); // validators.text.directive
require("mimecast-web-components/src/app/components/fromNow/from-now-filter"); // filters.fromNow
require("mimecast-web-components/src/app/components/fullDate/full-date-filter"); // filters.fullDate
require("mimecast-web-components/src/app/components/fullscreen-spinner/fullscreen-spinner"); // mc-fullscreen-spinner
require("mimecast-web-components/src/app/components/helper-validation-service/helper-validation-service"); // helper.validation.service
require("mimecast-web-components/src/app/components/inline-notification/inline-notification"); // inline-notification
require("mimecast-web-components/src/app/components/keep-alive/keep-alive"); // keep.alive
require("mimecast-web-components/src/app/components/live-indicator/live-indicator"); // live.indicator.directive
require("mimecast-web-components/src/app/components/mailto/mailto"); // mailto.directive
require("mimecast-web-components/src/app/components/mc-cookies/cookies"); // mc-cookies
require("mimecast-web-components/src/app/components/mc-day-date-filter/mc-day-date-filter"); // filters.mcDayDateFilter
require("mimecast-web-components/src/app/components/mc-standard-date/mc-standard-date"); // filters.mcStandardDate
require("mimecast-web-components/src/app/components/mc-utc/mc-utc-filter"); // filters.mcUtc
require("mimecast-web-components/src/app/components/mobile-app-alert/mobile-app-alert-directive"); // mobile.app.alert.directive
require("mimecast-web-components/src/app/components/modal-account-support/modal-account-support"); // modalAccountSupport
require("mimecast-web-components/src/app/components/modal-change-password/modal-change-password"); // modalChangePassword
require("mimecast-web-components/src/app/components/modal-feedback/modal-feedback"); // modalFeedback
require("mimecast-web-components/src/app/components/nice-scroll/nice-scroll"); // scroll
require("mimecast-web-components/src/app/components/panel-progress/panel-progress"); // panel-progress
require("mimecast-web-components/src/app/components/password-rules-checker/password-rules-checker"); // password-rules-checker
require("mimecast-web-components/src/app/components/popover/popover"); // popovers
require("mimecast-web-components/src/app/components/rich-panel-list/rich-panel-list"); // rich-panel-list
require("mimecast-web-components/src/app/components/route-tabs/route-tabs"); // route.tabs
require("mimecast-web-components/src/app/components/selectize-select-box/selectize-select-box"); // mc-selectize-select-box
require("mimecast-web-components/src/app/components/selectize/selectize"); // selectize
require("mimecast-web-components/src/app/components/spinner/spinner"); // spinners
require("mimecast-web-components/src/app/components/steps-list/steps-list"); // steps-list
require("mimecast-web-components/src/app/components/to-bytes/to-bytes"); // filters.toBytes
require("mimecast-web-components/src/app/components/track-progress-helper/track-progress-helper"); // track-progress-helper
require("mimecast-web-components/src/app/components/translate-config/translate-config"); // translate.config
require("mimecast-web-components/src/app/components/translate-extend/translate-extend"); // translateExtend
require("mimecast-web-components/src/app/components/useful-link-list/useful-link-list"); // useful-link-list
require("mimecast-web-components/src/app/components/wizard/wizard"); // wizard
require("mimecast-web-components/src/app/services/account/account"); // api.account
require("mimecast-web-components/src/app/services/api-error-handler/api-error-handler"); // api.error.handler
require("mimecast-web-components/src/app/services/api-interceptor/api-interceptor"); // api-interceptor
require("mimecast-web-components/src/app/services/archive/archive"); // api.archive
require("mimecast-web-components/src/app/services/authentication/authentication"); // api.authentication
require("mimecast-web-components/src/app/services/backup/backup"); // api.backup
require("mimecast-web-components/src/app/services/classic-adcon-helper/classic-adcon-helper"); // classic-adcon-helper
require("mimecast-web-components/src/app/services/connect/connect"); // api.connect
require("mimecast-web-components/src/app/services/directory/directory"); // api.directory
require("mimecast-web-components/src/app/services/domain/domain"); // api.domain
require("mimecast-web-components/src/app/services/email/email"); // api.email
require("mimecast-web-components/src/app/services/emailstats/emailstats"); // api.emailstats
require("mimecast-web-components/src/app/services/inbound/inbound"); // api.inbound
require("mimecast-web-components/src/app/services/journaling/journaling"); // api.journaling
require("mimecast-web-components/src/app/services/local-storage/local-storage"); // local-storage
require("mimecast-web-components/src/app/services/login/login"); // api.login
require("mimecast-web-components/src/app/services/managedsender/managedsender"); // api.managedsender
require("mimecast-web-components/src/app/services/outbound/outbound"); // api.outbound
require("mimecast-web-components/src/app/services/provisioning/provisioning"); // api.provisioning
require("mimecast-web-components/src/app/services/secure-messaging/secure-messaging"); // api.secure-messaging
require("mimecast-web-components/src/app/services/service-monitor/service-monitor"); // api.service-monitor
require("mimecast-web-components/src/app/services/session-handler/session-handler"); // session-handler
require("mimecast-web-components/src/app/services/stats/stats"); // api.stats
require("mimecast-web-components/src/app/services/ttp/impersonation/impersonation"); // api.ttp.impersonation
require("mimecast-web-components/src/app/services/user/user"); // api.user
// ADCON FEATURES
require("./access-denied/access-denied"); // access-denied
require("./administration-controller"); // administration.controllers
require("./administration-service"); // administration.service
require("./commons/adcon-commons"); // adcon-commons
require("./components/breadcrumb/breadcrumb-service"); // breadcrumb.service
require("./components/favourite/favourite"); // favourite
require("./components/layout/layout"); // layout
require("./components/layout/side/side-service"); // side.service
require("./components/modal-error-report/modal-error-report"); // modalErrorReport
require("./components/modal-max-retention/modal-max-retention"); // modalMaxRetention
require("./components/notification-feed/notification-feed"); // notification-feed
require("./components/pagination/pagination"); // pagination
require("./components/recent-activity/recent-activity"); // recent-activity
require("./components/service-status/service-status"); // services-status
require("./components/switch-account-interceptor/switch-account-interceptor"); // switchAccountInterceptor.service
require("./components/progress-tracker/progress-tracker");
require("./dashboards/administration/chartconfig-service"); // chartconfig.service
require("./dashboards/administration/dashboard"); // dashboards.administration
require("./legacy/legacy"); // legacy
require("./navbar/navbar"); // navbar
require("./navbar/search/search-popover/search-popover"); // search-popover
require("./navbar/user/user-handler-service"); // user.handler.service
require("./services/account/audit-logs/audit-logs"); // auditLogs.administration
require("./services/account/package-info/package-info"); // account.package-info
require("./services/account/roles/roles"); // account.roles
require("./services/monitoring/data-leak-prevention/data-leak-prevention"); // services.monitoring.dlp
require("./services/monitoring/secure-messaging/secure-messaging"); // services.monitoring.secure-messaging
require("./services/monitoring/ttp-impersonation/ttp-impersonation-list/ttp-impersonation"); // services.services.monitoring.ttp-impersonation
require("./services/services/attachment-protection/attachment-protection"); // services.services.attachment-protection
require("./services/services/backup/backup"); // services.services.backup
require("./services/services/server-connection/server-connection"); // services.services.server-connections
require("./services/services/url-protection/url-protection"); // services.services.url-protection
require("./services/mc-nav-menu/mc-nav-menu.service"); // mc-nav-menu.service
require("app-new/core/core");
const static_1 = require("@angular/upgrade/static");
// ANGULAR
require("../app-new/archive-viewer/archive-viewer");
require("../app-new/awareness/awareness");
require("../app-new/ttp/chameleon/monitored-external-domain/monitored-external-domain");
require("../app-new/components/session-handler/old-session-handler-override.service");
require("../app-new/hero/hero");
require("../app-new/custom-domain-block/custom-domain-block");
require("../app-new/safe-cloud/safe-cloud");
require("../app-new/services/secure-messaging/secure-messaging");
require("../app-new/swg/dashboard/swg-dashboard");
require("../app-new/swg/activity-report/swg-activity-report");
require("../app-new/swg/application-control/swg-applications");
require("../app-new/swg/url-check-report/swg-url-check-report");
require("../app-new/swg/endpoints/endpoints-main");
require("../app-new/swg/endpoints-list/endpoints-list");
require("../app-new/swg/exceptions/swg-exceptions-list");
require("../app-new/swg/dns/swg-dns");
require("../app-new/swg/malware-report/malware-report-list");
require("../app-new/swg/policies/swg-policy");
require("../app-new/services/browser-isolation/bi-policy");
require("../app-new/services/mep/mep-policy");
require("../app-new/services/cybergraph/policies/cybergraph-policies");
require("../app-new/ttp/attachmentprotection/policies/ttp-attachment-protection-policy");
require("../app-new/swg/locations/swg-locations-list");
require("../app-new/swg/block-page/swg-block-page-settings");
require("../app-new/sync-recover/exchange/exchange");
require("../app-new/sync-recover/onedrive/onedrive");
require("../app-new/onedrive/onedrive");
require("../app-new/components/iframe/iframe");
require("../app-new/components/session-timeout/session-timeout");
require("../app-new/components/capabilities/capabilities");
require("../app-new/legacy-pages/legacy-pages");
require("../app-new/remediation/remediation");
require("../app-new/threat-intel/threat-intel");
require("../app-new/identity/identity");
require("./components/override-http/http-service");
require("../app-new/message-center/tracking/tracking");
require("../app-new/message-center/bounces/bounces");
require("../app-new/message-center/accepted-email/accepted-email");
require("../app-new/message-center/held-messages/held-messages");
require("../app-new/message-center/rejected-and-deferred/router");
require("../app-new/message-center/message-delivery/message-delivery");
require("../app-new/message-center/message-processing/message-processing");
require("../app-new/api-applications/api-applications");
require("../app-new/ttp/impersonation/impersonation");
require("../app-new/app-settings/app-settings");
require("../app-new/account/ip-checker/ip-checker");
require("../app-new/directories/attributes/attributes");
require("../app-new/archive/supervision/supervision");
require("../app-new/services/connectors/connectors");
require("../app-new/services/im-sync/im-sync");
require("../app-new/services/url-protection/managed-urls/managed-urls");
require("../app-new/services/url-protection/logs/logs");
require("../app-new/services/url-protection/url-tools/url-tools");
require("../app-new/services/url-protection/url-protection");
require("../app-new/services/url-protection/policies/policies");
require("../app-new/services/attachment-protection/definitions/ttp-definition-routing");
require("../app-new/directory-sync/directory-sync");
require("../app-new/feature-flags/feature-flag");
// html and css
require("./components/date-picker-inline/date-picker-inline-directive.tpl.html");
require("./components/date-picker-inline/less/date-picker-inline.less");
require("./components/timezone/mc-timezone.tpl.html");
require("./dashboards/administration/dashboard.tpl.html");
require("./less/mixins.less");
require("./less/stylesheet.ie.less");
require("./less/stylesheet.less");
require("../app-new/scss/sea-star-shiv.scss");
require("./less/variables.less");
require("./services/account/account.less");
require("./services/monitoring/ttp-impersonation/ttp-impersonation-list/ttp-impersonation-list.tpl.html");
require("./services/monitoring/ttp-impersonation/ttp-impersonation-list/ttp-impersonation.less");
require("./services/monitoring/ttp-impersonation/ttp-impersonation-list/slides/ttp-impersonation-details.tpl.html");
const components_1 = require("@mimecast-ui/components");
require("@mimecast-ui/directory-sync/js/directory-sync-components");
const __NgCli_bootstrap_1 = require("./../app-new/app.module.ngfactory");
const __NgCli_bootstrap_2 = require("@angular/platform-browser");
angular
    .module('administration-app', [
    'api-interceptor',
    'api.authentication',
    'api.connect',
    'api.directory',
    'api.domain',
    'api.email',
    'api.emailstats',
    'api.inbound',
    'api.journaling',
    'api.managedsender',
    'api.outbound',
    'api.user',
    'api.error.handler',
    'api.account',
    'api.stats',
    'api.service-monitor',
    'api.backup',
    'api.archive',
    'api.ttp.impersonation',
    'api.provisioning',
    'api.secure-messaging',
    'api.login',
    'ui.router',
    'access-denied',
    'account.package-info',
    'account.roles',
    'adcon-commons',
    'administration.controllers',
    'administration.service',
    'angular-growl',
    'auditLogs.administration',
    'bootstrap-file-input',
    'breadcrumb.service',
    'capabilities',
    'chartconfig.service',
    'checkbox.directive',
    'classic-adcon-helper',
    'connect.compact',
    'click.disable.directive',
    'click.disable',
    'dashboards.administration',
    'dropdown',
    'favourite',
    'field-confirmation',
    'filters.fromNow',
    'filters.fullDate',
    'filters.mcDayDateFilter',
    'filters.mcStandardDate',
    'filters.mcUtc',
    'filters.toBytes',
    'focus.directive',
    'form',
    'helper.validation.service',
    'inline-notification',
    'keep.alive',
    'layout',
    'legacy',
    'live.indicator.directive',
    'local-storage',
    'mailto.directive',
    'mc-cookies',
    'mc-extended-link-panels',
    'mc-fullscreen-spinner',
    'mc-selectize-select-box',
    'mobile.app.alert.directive',
    'modalAccountSupport',
    'modalChangePassword',
    'modalErrorReport',
    'modalFeedback',
    'modalMaxRetention',
    'navbar',
    'ngAside',
    'ngProgress',
    'notification-feed',
    'old-session-handler-override.service',
    'pagination',
    'panel-progress',
    'password-rules-checker',
    'popovers',
    'predefined-field.domain',
    'predefined-field.email',
    'predefined-field.hostip',
    'predefined-field.number',
    'predefined-field.password',
    'predefined-field.regex',
    'predefined-field.text',
    'progress-tracker',
    'recent-activity',
    'rich-panel-list',
    'route.tabs',
    'scroll',
    'search-popover',
    'selectize',
    'services.connectors',
    'services.im-sync',
    'onedrive',
    'services-status',
    'services.monitoring.dlp',
    'services.monitoring.secure-messaging',
    'services.services.attachment-protection',
    'services.services.backup',
    'services.services.monitoring.ttp-impersonation',
    'services.services.server-connections',
    'services.services.url-protection',
    'side.service',
    'spinners',
    'steps-list',
    'switchAccountInterceptor.service',
    'track-progress-helper',
    'translate.config',
    'translateExtend',
    'useful-link-list',
    'user.handler.service',
    'validators.domain.directive',
    'validators.email.directive',
    'validators.hostip.directive',
    'validators.password.directive',
    'validators.text.directive',
    'wizard',
    'session-handler',
    'hero',
    'awareness',
    'monitoredExternalDomain',
    'archive-viewer',
    'authentication.interceptor',
    'swgDashboard',
    'swgActivityReport',
    'swgApplicationsHome',
    'swgUrlCheckReport',
    'swgEndpoints',
    'swgEndpointsList',
    'swgExceptionsList',
    'swgPolicy',
    'biPolicy',
    'mepPolicy',
    'cyberGraphPolicy',
    'attachmentProtectionPolicy',
    'attachmentProtectionDefinition',
    'swgDnsGateway',
    'sync-recover.exchange',
    'swgMalwareReport',
    'swgLocationsList',
    'swgBlockPageSettings',
    'sync-recover.onedrive',
    'iframe',
    'old-capabilities-override.service',
    'legacy-pages',
    'override-http.service',
    'api-applications',
    'core.service',
    'tracking',
    'messagecenter.bounces',
    'accepted-email',
    'message-center.held-messages',
    'message-center.message-delivery',
    'message-center.rejected-and-deferred',
    'message-center.message-processing',
    'account-ipchecker',
    'session-timeout',
    'remediation',
    'threat-intel',
    'identity',
    'ttp.impersonation',
    'directories.attributes',
    'archive.supervision',
    'app-settings',
    'safe-cloud',
    'services.managed-urls-new',
    'services.dashboard-url-protection-logs-new',
    'services.urlprotect-url-tools',
    'services.url-protection-new',
    'services.policies',
    'services.directory-sync',
    'custom-domain-block',
    'secure-messaging',
    'mcnavmenu.service',
    'feature-flag'
])
    .constant('APP_CONSTANTS', {
    APP_NAME: 'MODULE_NAME',
    FEEDBACK_EMAIL: 'consolefeedback@mimecast.com',
    DEFAULT_SESSION_DURATION: 1799
})
    .config([
    '$stateProvider',
    '$urlRouterProvider',
    '$translateProvider',
    function ($stateProvider, $urlRouterProvider, $translateProvider) {
        // For any unmatched url, redirect to /login
        $urlRouterProvider.otherwise(function ($injector) {
            $injector.get('$state').go('home');
            return true;
        });
        // home State declaration
        $stateProvider.state('home', {
            url: '/home',
            data: { isFixedLayout: false, checkProgress: false },
            onEnter: [
                '$state',
                function ($state) {
                    // todo check the progress and bring the user somewhere over the rainbow
                    // setting load state to home to let the administration-controller decide the landing state based on access permissions.
                    $state.go('home');
                }
            ]
        });
        $translateProvider.useLoader('sharedLoader', { lang: 'en' });
        $translateProvider.preferredLanguage('en');
    }
])
    .config([
    '$animateProvider',
    function ($animateProvider) {
        $animateProvider.classNameFilter(/animate-active/);
    }
])
    .config([
    'sessionHandlerServiceProvider',
    function (sessionHandlerServiceProvider) {
        sessionHandlerServiceProvider.setInheritParentWindowSession(true);
    }
])
    .config([
    '$qProvider',
    function ($qProvider) {
        $qProvider.errorOnUnhandledRejections(false);
    }
])
    .config([
    '$locationProvider',
    function ($locationProvider) {
        $locationProvider.hashPrefix('');
    }
])
    .directive('mcNotificationContainer', static_1.downgradeComponent({
    component: components_1.NotificationContainerComponent
}));
__NgCli_bootstrap_2.platformBrowser().bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory, { preserveWhitespaces: true })
    .then(platformRef => {
    const upgrade = platformRef.injector.get(static_1.UpgradeModule);
    // @ts-ignore
    upgrade.bootstrap(document, ['administration-app']);
});
