"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PendoService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
class PendoService {
    constructor(providers) {
        this.providers = providers;
    }
    getPendoInfo() {
        return rxjs_1.zip(...this.providers.map(p => p.getInformation())).pipe(operators_1.map((infos) => infos.reduce((submit, info) => ({
            visitor: Object.assign(Object.assign({}, submit.visitor), (info.visitor || {})),
            account: Object.assign(Object.assign({}, submit.account), (info.account || {}))
        }), { visitor: {}, account: {} })));
    }
}
exports.PendoService = PendoService;
