"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApplicationCategoryPipe = void 0;
class ApplicationCategoryPipe {
    constructor(translateService) {
        this.translateService = translateService;
    }
    transform(input) {
        switch (input) {
            case 'siem_integration':
            case 'xdr_integration':
            case 'msp_ordering_and_provisioning':
            case 'email_or_archiving':
            case 'business_intelligence':
            case 'process_automation':
            case 'soar_integration':
            case 'itsm_integration':
            case 'casb_integration':
            case 'managed_soc_integration':
            case 'digital_risk_platform_integration':
            case 'subscription_management_integration':
            case 'compliance_integration':
                return this.translateService.instant('$I18N_API_APPLICATIONS_CATEGORY_PIPE.' + input.toUpperCase());
            default:
            case 'other':
                return this.translateService.instant('$I18N_API_APPLICATIONS_CATEGORY_PIPE.OTHER');
        }
    }
}
exports.ApplicationCategoryPipe = ApplicationCategoryPipe;
