"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogsPageContainerComponent = void 0;
const rxjs_1 = require("rxjs");
const reducers = require("../../reducers");
const exportActions = require("app-new/components/export/legacy/actions/export.actions");
const logDetailsActions = require("../../actions/log-details.actions");
class LogsPageContainerComponent {
    constructor(store) {
        this.store = store;
        this.logDetailsIsVisible$ = new rxjs_1.Observable();
        this.logDetailsIsVisible$ = this.store.select(reducers.logDetailsIsVisible);
    }
    permitSender(instance) {
        this.store.dispatch(new logDetailsActions.PermitSenderAction(instance));
    }
    blockSender(instance) {
        this.store.dispatch(new logDetailsActions.BlockSenderAction(instance));
    }
    monitorDomain(senderAddress) {
        this.store.dispatch(new logDetailsActions.MonitorDomainAction(senderAddress));
    }
    export(logsPageConfig) {
        this.store.dispatch(new exportActions.ExportShowAction(logsPageConfig));
    }
}
exports.LogsPageContainerComponent = LogsPageContainerComponent;
