"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DirectorySyncService = void 0;
const rxjs_1 = require("rxjs");
const common_1 = require("../../api-applications/services/common");
const operators_1 = require("rxjs/operators");
const core_1 = require("../../core");
class DirectorySyncService {
    constructor(http, translateService, capabilitiesService, notificationService, coreService, window) {
        this.http = http;
        this.translateService = translateService;
        this.capabilitiesService = capabilitiesService;
        this.notificationService = notificationService;
        this.coreService = coreService;
        this.window = window;
        this.capabilitiesService
            .hasCapability('Permission.EAM_DIRECTORY_SYNC_READ')
            .pipe(operators_1.first())
            .subscribe(enabled => {
            this.isAAAAccount = enabled;
        });
    }
    testConnectionData(data, elem) {
        this.testConnectionSubscription = this.testConnection(data.payload).subscribe(response => {
            this.testConnectionSuccess(response, data.stage, elem);
        }, this.testConnectionError.bind(this));
    }
    testConnection(payload) {
        return this.http
            .post('/api/directory/test-connection', payload)
            .pipe(common_1.firstResult());
    }
    getIntegrations(pagination, searchFilters) {
        const url = this.isAAAAccount
            ? '/api/directory/get-aaa-connection'
            : '/api/directory/get-connection';
        const payload = {
            data: this.filtersPayload(searchFilters),
            meta: {
                pagination: {
                    pageSize: 50
                }
            }
        };
        if (pagination) {
            payload.meta.pagination.pageToken = pagination.pageToken;
            payload.meta.pagination.pageSize = pagination.pageSize;
        }
        return this.http.post(url, payload).pipe(operators_1.map((response) => {
            return {
                data: response.all,
                meta: response.meta
            };
        }));
    }
    createIntegration(payload) {
        return this.http
            .post('/api/directory/create-connection', payload)
            .pipe(common_1.firstResult());
    }
    deleteIntegration(payload) {
        return this.http
            .post('/api/directory/delete-connection', payload)
            .pipe(common_1.firstResult());
    }
    updateIntegration(payload) {
        return this.http
            .post('/api/directory/update-connection', payload)
            .pipe(common_1.firstResult());
    }
    syncAllIntegrations() {
        return this.http.post('/api/directory/request-sync', null).pipe(common_1.firstResult());
    }
    searchDirectory(payload) {
        return this.http
            .post('/api/directory/search-directory', payload)
            .pipe(common_1.firstResult());
    }
    synchronizationReport() {
        return this.http.post('/api/directory/get-sync-info', null).pipe(common_1.firstResult());
    }
    getMSESites() {
        return this.http.post('/api/directory/get-sites', null).pipe(common_1.allResult());
    }
    getSyncStatus() {
        return this.http.post('/api/directory/get-sync-status', null).pipe(common_1.firstResult());
    }
    getAzureHecateConnectors(productId, azureType) {
        return this.http
            .post('/api/connectors/find-connectors', {
            meta: { pagination: { pageSize: 50 } },
            data: [
                {
                    filterBy: [
                        {
                            value: productId,
                            fieldName: 'product'
                        },
                        {
                            value: azureType,
                            fieldName: 'app'
                        }
                    ]
                }
            ]
        })
            .pipe(operators_1.map((response) => {
            return response;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    testConnectionSuccess(response, stage, elem) {
        if (elem.nativeElement) {
            elem.nativeElement.apiResponse = {
                apiResponse: response,
                stage
            };
        }
        else {
            throw new Error('Unable to find element for Directory Sync initialisation');
        }
        return response;
    }
    testConnectionError() {
        this.notificationService.error({
            msg: this.translateService.instant('$I18N_DIRECTORY_SYNC_TABLE.ERROR')
        });
    }
    cancelTestConnection() {
        if (this.testConnectionSubscription) {
            this.testConnectionSubscription.unsubscribe();
        }
    }
    filtersPayload(filters) {
        const search = filters && filters.search;
        const searchBy = search && search.searchBy;
        const filterBy = search && search.filterBy;
        const resultSearchBy = searchBy && searchBy.value ? { searchBy } : {};
        const resultFilterBy = filterBy && filterBy.length > 0 && filterBy[0].fieldName ? { filterBy } : {};
        return resultSearchBy.searchBy || resultFilterBy.filterBy
            ? [Object.assign(Object.assign({}, resultSearchBy), resultFilterBy)]
            : [];
    }
    getOnboardingRedirectUrl() {
        let encodedAccountCode = '';
        this.coreService
            .getAccountSwitchSession()
            .pipe(operators_1.first(), core_1.isDefined())
            .subscribe(switchSession => {
            const accountCode = switchSession.accountcode;
            if (switchSession && accountCode) {
                encodedAccountCode = `?ac=${encodeURIComponent(btoa(accountCode))}`;
            }
        });
        if (this.window.parent.location.href.includes('onboarding=partner')) {
            return `/partner-portal/onboarding${encodedAccountCode}`;
        }
        return `/admin/account/onboarding${encodedAccountCode}`;
    }
}
exports.DirectorySyncService = DirectorySyncService;
