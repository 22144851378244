"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectReviewersComponent = void 0;
const operators_1 = require("rxjs/operators");
const queue_actions_1 = require("app-new/archive/supervision/actions/queue.actions");
const reducers_1 = require("app-new/archive/supervision/reducers");
class SelectReviewersComponent {
    constructor(store) {
        this.store = store;
    }
    ngOnInit() {
        this.reviewerLoading$ = this.store.select(reducers_1.getQueueLoading);
        this.allReviewers$ = this.store.select(reducers_1.getReviewers);
        this.selectedReviewers$ = this.store.select(reducers_1.getSelectedReviewers);
        this.matchingReviewers$ = this.allReviewers$;
    }
    ngOnDestroy() {
        this.store.dispatch(new queue_actions_1.CloseSelectReviewersSidePanelAction());
    }
    addReviewers(selectedReviewers) {
        this.store.dispatch(new queue_actions_1.AddReviewersAction(selectedReviewers));
    }
    matchReviewers(query) {
        this.matchingReviewers$ = this.allReviewers$.pipe(operators_1.map(reviewers => {
            if (reviewers) {
                const queryLowerCase = query.toLocaleLowerCase();
                return reviewers.filter(r => r.name.toLocaleLowerCase().includes(queryLowerCase) ||
                    r.emailAddress.toLocaleLowerCase().includes(queryLowerCase));
            }
            else {
                return [];
            }
        }));
    }
}
exports.SelectReviewersComponent = SelectReviewersComponent;
