"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImSyncTaskComponent = void 0;
const imSyncActions = require("app-new/services/im-sync/actions/im-sync.actions");
const wizard_base_component_1 = require("../wizard-base.component");
const im_task_service_1 = require("../services/im-task.service");
class ImSyncTaskComponent extends wizard_base_component_1.WizardBaseDirective {
    constructor(capabilitiesService, store, translateService, stateService) {
        super(capabilitiesService, im_task_service_1.ImTaskService.WizardId, store, translateService);
        this.capabilitiesService = capabilitiesService;
        this.store = store;
        this.translateService = translateService;
        this.stateService = stateService;
        this.imSyncTaskDetails = this.stateService.$state.params.imSyncTaskDetails;
    }
    get isEditMode() {
        return this.imSyncTaskDetails !== undefined && this.imSyncTaskDetails !== null;
    }
    get name() {
        return this.isEditMode && this.imSyncTaskDetails.name ? this.imSyncTaskDetails.name : '';
    }
    get description() {
        return this.isEditMode && this.imSyncTaskDetails.description
            ? this.imSyncTaskDetails.description
            : '';
    }
    get wizardTitle() {
        if (!this.isEditMode) {
            return this.translateService.instant('$I18N_ARCHIVE_IM_SYNC_WIZARD.IM_SYNC_TASK.TAB_CREATE_TITLE');
        }
        return this.translateService.instant('$I18N_ARCHIVE_IM_SYNC_WIZARD.IM_SYNC_TASK.TAB_UPDATE_TITLE');
    }
    onSave() {
        this.store.dispatch(new imSyncActions.CreateImSyncTaskAction(this.getCreateTaskRequest()));
    }
    getCancelConfirmationText() {
        if (!this.isEditMode) {
            return this.translateService.instant('$I18N_ARCHIVE_IM_SYNC_WIZARD.IM_SYNC_TASK.CANCEL_CREATE_CONFIRMATION');
        }
        return this.translateService.instant('$I18N_ARCHIVE_IM_SYNC_WIZARD.IM_SYNC_TASK.CANCEL_UPDATE_CONFIRMATION');
    }
    getSaveButtonText() {
        if (!this.isEditMode) {
            return this.translateService.instant('$I18N_ARCHIVE_IM_SYNC_WIZARD.IM_SYNC_TASK.CONTROLS.CREATE');
        }
        return this.translateService.instant('$I18N_ARCHIVE_IM_SYNC_WIZARD.IM_SYNC_TASK.CONTROLS.UPDATE');
    }
    getCreateTaskRequest() {
        const connectorId = this.wizardData.steps[2].data['connector'].value;
        return Object.assign({ name: this.wizardData.steps[1] && this.wizardData.steps[1].data['name'], description: this.wizardData.steps[1].data['description']
                ? this.wizardData.steps[1].data['description']
                : null, source: this.wizardData.steps[1].data['source'] }, (connectorId && { connectorId }));
    }
}
exports.ImSyncTaskComponent = ImSyncTaskComponent;
