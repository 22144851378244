"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IdentityDeletePolicyModalComponent = void 0;
const reducers_1 = require("../../reducers");
const policyActions = require("../../actions/policies.actions");
class IdentityDeletePolicyModalComponent {
    constructor(store) {
        this.store = store;
    }
    ngOnInit() {
        this.policy$ = this.store.select(reducers_1.getDeletedPolicy);
        this.isDeleting$ = this.store.select(reducers_1.getApiIsProcessingDelete);
    }
    onDelete(deletedPolicyId) {
        this.store.dispatch(new policyActions.IdentityDeletePolicyAction(deletedPolicyId));
    }
    onCancel() {
        this.store.dispatch(new policyActions.IdentityDeleteModalCloseAction());
    }
}
exports.IdentityDeletePolicyModalComponent = IdentityDeletePolicyModalComponent;
