"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SummaryComponent = void 0;
class SummaryComponent {
    ngOnChanges(changes) {
        if (changes && changes['wizardData'].currentValue) {
            this.summaryDetails = this.convertToSummaryDetails(this.wizardData);
        }
    }
    get subTitle() {
        if (this.isEditMode) {
            return '$I18N_ONEDRIVE_BACKUP_WIZARD.STEPS.SUMMARY.EDIT_SUBTITLE';
        }
        return '$I18N_ONEDRIVE_BACKUP_WIZARD.STEPS.SUMMARY.CREATE_SUBTITLE';
    }
    convertToSummaryDetails(wizardData) {
        const stepData = wizardData.steps;
        const excludedFiles = stepData[2].data.fileExtensions;
        return {
            name: stepData[1].data.name,
            group: stepData[1].data.group && stepData[1].data.group.name,
            connector: stepData[1].data.connector.label,
            excludedFiles: excludedFiles.length === 0 ? '-' : excludedFiles.join(', ')
        };
    }
}
exports.SummaryComponent = SummaryComponent;
