"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTranslationKey = exports.StatusFactory = void 0;
const status_1 = require("../models/status");
exports.StatusFactory = {
    [status_1.STATUS_TYPES.created]: (overrideOptions) => new status_1.Status(status_1.STATUS_TYPES.created, (overrideOptions && overrideOptions.displayClasses) || 'text-gray-40', (overrideOptions && overrideOptions.tooltip) || '', (overrideOptions && overrideOptions.displayValues) || getTranslationKey(status_1.STATUS_TYPES.created), (overrideOptions && overrideOptions.icon) || ''),
    [status_1.STATUS_TYPES.in_progress]: (overrideOptions) => new status_1.Status(status_1.STATUS_TYPES.in_progress, (overrideOptions && overrideOptions.displayClasses) || 'text-gray-40', (overrideOptions && overrideOptions.tooltip) || '', (overrideOptions && overrideOptions.displayValues) ||
        getTranslationKey(status_1.STATUS_TYPES.in_progress), (overrideOptions && overrideOptions.icon) || ''),
    [status_1.STATUS_TYPES.completed]: (overrideOptions) => {
        return new status_1.Status(status_1.STATUS_TYPES.completed, (overrideOptions && overrideOptions.displayClasses) || 'text-success', (overrideOptions && overrideOptions.tooltip) || '', (overrideOptions && overrideOptions.displayValues) ||
            getTranslationKey(status_1.STATUS_TYPES.completed), (overrideOptions && overrideOptions.icon) || '');
    },
    [status_1.STATUS_TYPES.success]: (overrideOptions) => {
        return new status_1.Status(status_1.STATUS_TYPES.success, (overrideOptions && overrideOptions.displayClasses) || 'text-success', (overrideOptions && overrideOptions.tooltip) || '', (overrideOptions && overrideOptions.displayValues) || getTranslationKey(status_1.STATUS_TYPES.success), (overrideOptions && overrideOptions.icon) || '');
    },
    [status_1.STATUS_TYPES.error]: (overrideOptions) => {
        return new status_1.Status(status_1.STATUS_TYPES.error, (overrideOptions && overrideOptions.displayClasses) || 'text-danger', (overrideOptions && overrideOptions.tooltip) || '', (overrideOptions && overrideOptions.displayValues) || getTranslationKey(status_1.STATUS_TYPES.error), (overrideOptions && overrideOptions.icon) || '', (overrideOptions && overrideOptions.statusMessage) || '');
    },
    [status_1.STATUS_TYPES.warning]: (overrideOptions) => {
        return new status_1.Status(status_1.STATUS_TYPES.warning, (overrideOptions && overrideOptions.displayClasses) || 'text-warning', (overrideOptions && overrideOptions.tooltip) || '', (overrideOptions && overrideOptions.displayValues) || getTranslationKey(status_1.STATUS_TYPES.warning), (overrideOptions && overrideOptions.icon) || '');
    },
    [status_1.STATUS_TYPES.pending]: (overrideOptions) => {
        return new status_1.Status(status_1.STATUS_TYPES.pending, (overrideOptions && overrideOptions.displayClasses) || 'text-gray-40', (overrideOptions && overrideOptions.tooltip) || '', (overrideOptions && overrideOptions.displayValues) || getTranslationKey(status_1.STATUS_TYPES.pending), (overrideOptions && overrideOptions.icon) || '');
    }
};
function getTranslationKey(status) {
    return ['$I18N_SYNC_RECOVER_STATUS', status && status.toUpperCase()].join('.');
}
exports.getTranslationKey = getTranslationKey;
