<div>
  <mc-collapsable-panel header="$I18N_MESSAGE_CENTER_MESSAGE_INFO_HEADER.TITLE" [isExpanded]="true">
    <button class="btn btn-secondary"
      ngxClipboard
      [cbContent]="unescapeHeader(header)"
      (cbOnSuccess)="copyToClipboardSuccess()">
      {{ '$I18N_MESSAGE_CENTER_MESSAGE_INFO_HEADER.COPY_TO_CLIPBOARD' | translate }}</button>
    <div data-test="header" class="panel-padding-top" [innerHTML]="header"></div>
  </mc-collapsable-panel>
</div>
