<div>
  <mc-collapsable-panel header="$I18N_MESSAGE_CENTER_MESSAGE_INFO_POLICIES_PRESERVATION.TITLE"
                        [isExpanded]="true">
    <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_POLICIES_PRESERVATION.LABEL_CONTENT_EXPIRATION"
                    [value]="messageInfo?.contentExpiration">
    </mc-value-field>
    <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_POLICIES_PRESERVATION.LABEL_METADATA_EXPIRATION"
                    [value]="messageInfo?.metadataExpiration">
    </mc-value-field>
  </mc-collapsable-panel>
</div>
