"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RemediationGetAllIncidentsFailureAction = exports.RemediationGetAllIncidentsSuccessAction = exports.RemediationGetAllIncidentsAction = exports.GET_ALL_INCIDENTS_FAILURE = exports.GET_ALL_INCIDENTS_SUCCESS = exports.GET_ALL_INCIDENTS = void 0;
exports.GET_ALL_INCIDENTS = '[Remediation] Retrieve incidents';
exports.GET_ALL_INCIDENTS_SUCCESS = '[Remediation] Retrieve incidents success';
exports.GET_ALL_INCIDENTS_FAILURE = '[Remediation] Retrieve incidents failure';
class RemediationGetAllIncidentsAction {
    constructor() {
        this.type = exports.GET_ALL_INCIDENTS;
    }
}
exports.RemediationGetAllIncidentsAction = RemediationGetAllIncidentsAction;
class RemediationGetAllIncidentsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_ALL_INCIDENTS_SUCCESS;
    }
}
exports.RemediationGetAllIncidentsSuccessAction = RemediationGetAllIncidentsSuccessAction;
class RemediationGetAllIncidentsFailureAction {
    constructor() {
        this.type = exports.GET_ALL_INCIDENTS_FAILURE;
    }
}
exports.RemediationGetAllIncidentsFailureAction = RemediationGetAllIncidentsFailureAction;
