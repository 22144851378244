"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectorStatusComponent = void 0;
const connector_1 = require("../../model/connector");
class ConnectorStatusComponent {
    constructor() {
        this.ConnectorStatus = connector_1.ConnectorStatus;
    }
    isStatusOfType(status) {
        return this.connectorStatus === status;
    }
}
exports.ConnectorStatusComponent = ConnectorStatusComponent;
