"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseEmails = exports.transformEmail = exports.transformAttachments = exports.EmailService = void 0;
class EmailService {
    constructor(service) {
        this.service = service;
    }
    send(email, user) {
        return this.service.send(transformEmail(email, user));
    }
}
exports.EmailService = EmailService;
function transformAttachments(attachments) {
    return (attachments &&
        attachments.map((attachment) => {
            const result = {
                id: attachment.id,
                size: attachment.size,
                contentType: attachment.contentType,
                filename: attachment.filename,
                contentId: attachment.contentId
            };
            return result;
        }));
}
exports.transformAttachments = transformAttachments;
function transformEmail(email, userEmail) {
    return {
        from: { emailAddress: userEmail },
        to: parseEmails(email.to),
        cc: parseEmails(email.cc),
        bcc: parseEmails(email.bcc),
        subject: email.subject,
        attachments: transformAttachments(email.attachments),
        plainBody: {
            content: email.textBody
        }
    };
}
exports.transformEmail = transformEmail;
function parseEmails(emails) {
    return emails
        ? emails.split(',').map(unparsedEmail => {
            unparsedEmail = unparsedEmail.trim();
            const email = unparsedEmail.match(/[^@<\s]+@[^@\s>]+/g);
            const names = unparsedEmail.split(/\s+/);
            names.pop();
            const result = {
                emailAddress: email && email[0],
                displayableName: names.length ? names.join(' ').replace(/"/g, '') : undefined
            };
            return result;
        })
        : undefined;
}
exports.parseEmails = parseEmails;
