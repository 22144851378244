"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgDownloadActivityReportFailureAction = exports.SwgDownloadActivityReportSuccessAction = exports.SwgExportActivityReportAction = exports.SwgExportActivityReportActions = void 0;
var SwgExportActivityReportActions;
(function (SwgExportActivityReportActions) {
    SwgExportActivityReportActions["EXPORT_ACTIVITY_REPORT"] = "[SwgEndpointList] Export Activity Report";
    SwgExportActivityReportActions["DOWNLOAD_ACTIVITY_REPORT_SUCCESS"] = "[SwgEndpointList] Download Activity Report Success";
    SwgExportActivityReportActions["DOWNLOAD_ACTIVITY_REPORT_FAILURE"] = "[SwgEndpointList] Download Activity Report Failure";
})(SwgExportActivityReportActions = exports.SwgExportActivityReportActions || (exports.SwgExportActivityReportActions = {}));
class SwgExportActivityReportAction {
    constructor(tableColumns) {
        this.tableColumns = tableColumns;
        this.type = SwgExportActivityReportActions.EXPORT_ACTIVITY_REPORT;
    }
}
exports.SwgExportActivityReportAction = SwgExportActivityReportAction;
class SwgDownloadActivityReportSuccessAction {
    constructor() {
        this.type = SwgExportActivityReportActions.DOWNLOAD_ACTIVITY_REPORT_SUCCESS;
    }
}
exports.SwgDownloadActivityReportSuccessAction = SwgDownloadActivityReportSuccessAction;
class SwgDownloadActivityReportFailureAction {
    constructor() {
        this.type = SwgExportActivityReportActions.DOWNLOAD_ACTIVITY_REPORT_FAILURE;
    }
}
exports.SwgDownloadActivityReportFailureAction = SwgDownloadActivityReportFailureAction;
