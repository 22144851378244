"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MimecastSafetyTipsLoadSuccessAction = exports.MimecastSafetyTipsLoadAction = exports.DeleteAction = exports.SaveAction = exports.EditAction = exports.PreviewAction = exports.CreateAction = exports.CREATE = exports.PREVIEW = exports.MIMECAST_SAFETY_TIPS_LOAD_SUCCESS = exports.MIMECAST_SAFETY_TIPS_LOAD = exports.DELETE = exports.EDIT = exports.SAVE = void 0;
exports.SAVE = '[Awareness Template Setup Tooltip] Save';
exports.EDIT = '[Awareness Template Setup Tooltip] Edit';
exports.DELETE = '[Awareness Template Setup Tooltip] Delete';
exports.MIMECAST_SAFETY_TIPS_LOAD = '[Awareness Template Setup Tooltip] Mimecast Safety Tips Load';
exports.MIMECAST_SAFETY_TIPS_LOAD_SUCCESS = '[Awareness Template Setup Tooltip] Mimecast Safety Tips Load Success';
exports.PREVIEW = '[Awareness Template Setup Tooltip] Preview';
exports.CREATE = '[Awareness Template Setup Tooltip] Create';
class CreateAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.CREATE;
    }
}
exports.CreateAction = CreateAction;
class PreviewAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.PREVIEW;
    }
}
exports.PreviewAction = PreviewAction;
class EditAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.EDIT;
    }
}
exports.EditAction = EditAction;
class SaveAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SAVE;
    }
}
exports.SaveAction = SaveAction;
class DeleteAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.DELETE;
    }
}
exports.DeleteAction = DeleteAction;
class MimecastSafetyTipsLoadAction {
    constructor() {
        this.type = exports.MIMECAST_SAFETY_TIPS_LOAD;
    }
}
exports.MimecastSafetyTipsLoadAction = MimecastSafetyTipsLoadAction;
class MimecastSafetyTipsLoadSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.MIMECAST_SAFETY_TIPS_LOAD_SUCCESS;
    }
}
exports.MimecastSafetyTipsLoadSuccessAction = MimecastSafetyTipsLoadSuccessAction;
