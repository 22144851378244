"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewRuleDetailsComponent = void 0;
const state_enum_1 = require("app-new/archive/supervision/types/state.enum");
class ViewRuleDetailsComponent {
    constructor(translateService) {
        this.translateService = translateService;
    }
    getState(state) {
        return ViewRuleDetailsComponent.STATE_MAP[state];
    }
    isActive(state) {
        return state === state_enum_1.RuleState.ACTIVE;
    }
    getNoLexiconText() {
        return this.translateService.instant('$I18N_ARCHIVE_SUPERVISION_RULE_DETAILS.DETAILS.NO_LEXICON_SELECTED');
    }
    isMonitoringGroupMissing(monitoringGroup) {
        return !monitoringGroup;
    }
}
exports.ViewRuleDetailsComponent = ViewRuleDetailsComponent;
ViewRuleDetailsComponent.STATE_MAP = {
    [state_enum_1.RuleState.ACTIVE]: '$I18N_ARCHIVE_SUPERVISION_RULE_DETAILS.DETAILS.STATE.ACTIVE',
    [state_enum_1.RuleState.INACTIVE]: '$I18N_ARCHIVE_SUPERVISION_RULE_DETAILS.DETAILS.STATE.INACTIVE'
};
