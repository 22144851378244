"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./history-controller"); //navbar.history.controller
require("./history-service"); //navbar.history.service
// html and css 
require("./history.tpl.html");
/**
 * Created by gvilla on 02/03/2016
 */
angular.module('navbar.history', ['navbar.history.controller', 'navbar.history.service']);
