"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DomainAlreadyInListService = void 0;
const reducers = require("../reducers");
class DomainAlreadyInListService {
    constructor(store) {
        this.store = store;
        this.domainsDesc$ = this.store.select(reducers.getDomains);
    }
    getDomains() {
        return this.domainsDesc$;
    }
}
exports.DomainAlreadyInListService = DomainAlreadyInListService;
