"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const threat_details_container_component_1 = require("./containers/threat-details-container.component");
angular
    .module('threat-intel.threat-details', [])
    .controller('ThreatDetailsController', [
    '$stateParams',
    '$scope',
    function ($stateParams, $scope) {
        $scope.stateParams = $stateParams;
    }
])
    .directive('mcThreatDetails', static_1.downgradeComponent({
    component: threat_details_container_component_1.ThreatDetailsContainerComponent
}))
    .config([
    '$stateProvider',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function ($stateProvider) {
        // TODO: temporary disabled for SSW-6239
        // const breadcrumbs: (String | object)[] = [];
        /*$stateProvider.state('threat-details', {
          url: '/threat-dashboard/threat-details/:threatName',
          data: {
            isFixedLayout: false,
            checkProgress: false,
            tabReload: true,
            capabilities:
              'Permission.THREAT_INTELLIGENCE_READ && Threat.Intel.Dashboard && EarlyAccess.Threat.Intel.Dashboard',
            tabTitle: '$I18N_THREAT_INTEL.BREADCRUMB.APP_NAME',
            breadcrumbs: breadcrumbs,
            tabId: 'threatIntelDashboard'
          },
          views: {
            main: {
              template: '<mc-threat-details></mc-threat-details>',
              controller: 'ThreatDetailsController'
            }
          },
          onEnter: [
            '$stateParams',
            ($stateParams: any) => {
              breadcrumbs.length = 0;
              breadcrumbs.push('$I18N_NAVBAR_MENU_ITEM_SERVICES');
              breadcrumbs.push({
                label: '$I18N_THREAT_INTEL.BREADCRUMB.APP_NAME',
                stateName: 'threat-intel',
                stateParams: { tabName: '$I18N_THREAT_INTEL.BREADCRUMB.APP_NAME' }
              });
              breadcrumbs.push({
                label: '$I18N_THREAT_INTEL.BREADCRUMB.THREAT_DETAILS'
              });
            }
          ]
        });*/
    }
]);
