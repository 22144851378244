"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiGatewaySaveSummaryStepComponent = void 0;
const core_1 = require("@angular/core");
const operators_1 = require("rxjs/operators");
class ApiGatewaySaveSummaryStepComponent {
    constructor(fb) {
        this.fb = fb;
        this.onApplicationStatusUpdated = new core_1.EventEmitter();
        this.onGotoStep = new core_1.EventEmitter();
        this.form = this.fb.group({
            active: ['']
        });
    }
    ngOnInit() {
        this.form.patchValue(this.applicationStatus || {});
        this.formSubscription = this.form.valueChanges.pipe(operators_1.debounceTime(200)).subscribe(() => {
            this.update();
        });
    }
    update() {
        this.onApplicationStatusUpdated.emit(this.form.value);
    }
    gotoDetails() {
        this.onGotoStep.emit(1);
    }
    gotoSettings() {
        this.onGotoStep.emit(2);
    }
    ngOnDestroy() {
        this.formSubscription.unsubscribe();
    }
}
exports.ApiGatewaySaveSummaryStepComponent = ApiGatewaySaveSummaryStepComponent;
