<div class="mc-list-panel mc-widget-height">
    <div class="mc-list-panel-heading">
        <span>{{ '$I18N_SERVICES_URL_PROTECTION.URL_TOOLS.DECODE_WIDGET.TITLE' | translate }}</span>
    </div>
    <div class="mc-list-panel-body panel-half-padding-top">
        <form [formGroup]="form" class="mc-detailed-list">
            <div class="mc-list-panel-row">
                <mc-text-area-field 
                    (keydown.enter)="preventNewRow($event)"
                    (keydown.shift.Enter)="preventNewRow($event)"
                    formControlName="rawUrlField" 
                    required
                    class="textarea-field"
                    data-test="cy-url-tools-raw-url-field"
                    placeholder="$I18N_SERVICES_URL_PROTECTION.URL_TOOLS.DECODE_WIDGET.RAW_URL_FIELD_PLACEHOLDER"
                    errorPrefix="$I18N_SERVICES_URL_PROTECTION.URL_TOOLS.DECODE_WIDGET.RAW_URL_FIELD_ERROR_MSG">
                </mc-text-area-field>
            </div>
            <div class="form-controls">
                <button class="btn btn-default panel-margin-right no-border"
                    (click)="clear()">{{'$I18N_SERVICES_URL_PROTECTION.URL_TOOLS.DECODE_WIDGET.CLEAR' | translate}}</button>
                <button class="btn btn-primary" [disabled]="!form.valid"
                    (click)="decode()">{{'$I18N_SERVICES_URL_PROTECTION.URL_TOOLS.DECODE_WIDGET.DECODE' | translate}}</button>
            </div>
            <div class="mc-list-panel-row">
                <mc-text-area-field 
                    formControlName="decodedUrlField" 
                    class="textarea-field"
                    data-test="cy-url-tools-decoded-url-field"
                    placeholder="$I18N_SERVICES_URL_PROTECTION.URL_TOOLS.DECODE_WIDGET.RAW_URL_FIELD_PLACEHOLDER"
                    errorPrefix="$I18N_SERVICES_URL_PROTECTION.URL_TOOLS.DECODE_WIDGET.RAW_URL_FIELD_ERROR_MSG">
                </mc-text-area-field>
            </div>
            <ng-container *ngIf="hasControls">
              <div class="form-controls">
                  <button
                    class="btn panel-margin-right"
                    [disabled]="!urlDecoded"
                    (click)="allow()"
                    data-unit-test="unit-allow-url-action-btn">{{'$I18N_SERVICES_URL_PROTECTION.URL_TOOLS.DECODE_WIDGET.ALLOW' | translate}}</button>
                  <button
                    class="btn"
                    (click)="block()"
                    [disabled]="!urlDecoded"
                    data-unit-test="unit-block-url-action-btn">{{'$I18N_SERVICES_URL_PROTECTION.URL_TOOLS.DECODE_WIDGET.BLOCK' | translate}}</button>
              </div>
            </ng-container>
        </form>
    </div>
</div>