<mc-layout-aside-extra-pagination-container
  keyTitle="$I18N_SERVICES_URL_PROTECTION_POLICIES_SIDE_PANEL.LABELS.POLICY_DETAILS_TITLE" [isLoading]="false"
  [showClose]="true" [hasNextInView]="hasNextInView$ | async" [hasPreviousInView]="hasPreviousInView$ | async"
  [indexInView]="indexInView$ | async" [currenPagination]="currentPagination$ | async"
  [filtersMetaData]="filtersMetaData$ | async" [isLoading]="false" (goToNextPage)="getNext()" (goToPrevPage)="getPrev()"
  (closeAside)="onClose()">

  <div row-actions>
    <div class="mc-header-row">
      <span>{{ selectedRow?.name }}</span>
    </div>
    <ng-container *mcCapabilities="canModifyPolicy" data-unit-test="policy-controls">
      <button class="btn btn-primary panel-half-margin-right" (click)="onEdit()">
        {{ '$I18N_SERVICES_URL_PROTECTION_POLICIES_SIDE_PANEL.LABELS.EDIT' | translate }}
      </button>
      <button class="btn btn-default panel-half-margin-right" (click)="onDuplicate()">
        {{ '$I18N_SERVICES_URL_PROTECTION_POLICIES_SIDE_PANEL.LABELS.DUPLICATE' | translate }}
      </button>
      <button class="btn btn-default panel-half-margin-right" (click)="onDelete(selectedRow)">
        {{ '$I18N_SERVICES_URL_PROTECTION_POLICIES_SIDE_PANEL.LABELS.DELETE' | translate }}
      </button>
      <div class="panel-half-margin-right mc-switch-inline">
        <form [formGroup]="detailsPolicyForm">
          <mc-switch data-unit-test="enable-disable-policy-control" data-cy-test="enable-disable-policy-control" formControlName="enableDisablePolicy" role="switch"
            showLabel="true"></mc-switch>
        </form>
      </div>
    </ng-container>
  </div>
  <mc-body-container>
    <mc-url-protection-side-panel-view-details
      [isAAAOrFAAUser]="isAAAOrFAAUser"
      [hasIEPPackage]="hasIEPPackage"
      [policyDetails]="policyDetails"
      [hasBrowserIsolation]="hasBrowserIsolation"
      *ngIf="policyDetailsFullData$ | async"
      data-unit-test="policy-side-panel-details">
    </mc-url-protection-side-panel-view-details>
  </mc-body-container>
</mc-layout-aside-extra-pagination-container>
