<mc-modal-header headerTitle></mc-modal-header>

<mc-modal-body>
  <h4>{{ messageKey | translate }}</h4>
</mc-modal-body>

<mc-modal-footer>
  <button
    type="button"
    class="btn btn-secondary"
    (click)="close(false)"
  >
    {{ '$I18N_COMMON_BTN_CANCEL' | translate }}
  </button>

  <button
    type="button"
    class="btn btn-primary"
    (click)="close(true)"
  >
    {{ primaryBtnKey | translate }}
  </button>
</mc-modal-footer>
