"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ3 = exports.ɵ2 = exports.ɵ1 = exports.ɵ0 = exports.UserNotificationOptions = exports.SpreadsheetsFormatOptions = exports.documentFormatOptions = exports.PreEmptiveFailureActionOptions = exports.configurationOptions = void 0;
const models_1 = require("../../../models");
const ɵ0 = models_1.ConfigurationDetailOption.PREEXEMPTIVE, ɵ1 = models_1.ConfigurationDetailOption.SAFEFILE, ɵ2 = models_1.ConfigurationDetailOption.SAFEFILEONDEMAND, ɵ3 = models_1.ConfigurationDetailOption.DYNAMICCONFIGURATION;
exports.ɵ0 = ɵ0;
exports.ɵ1 = ɵ1;
exports.ɵ2 = ɵ2;
exports.ɵ3 = ɵ3;
exports.configurationOptions = [
    {
        id: ɵ0,
        description: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_DETAILS_STEP_LABEL.CONFIGURATION.OPTIONS.DESCRIPTIONOPTION3',
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_DETAILS_STEP_LABEL.CONFIGURATION.OPTIONS.HEADEROPTION3'
    },
    {
        id: ɵ1,
        description: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_DETAILS_STEP_LABEL.CONFIGURATION.OPTIONS.DESCRIPTIONOPTION1',
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_DETAILS_STEP_LABEL.CONFIGURATION.OPTIONS.HEADEROPTION1'
    },
    {
        id: ɵ2,
        description: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_DETAILS_STEP_LABEL.CONFIGURATION.OPTIONS.DESCRIPTIONOPTION2',
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_DETAILS_STEP_LABEL.CONFIGURATION.OPTIONS.HEADEROPTION2'
    },
    {
        id: ɵ3,
        description: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_DETAILS_STEP_LABEL.CONFIGURATION.OPTIONS.DESCRIPTIONOPTION4',
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_DETAILS_STEP_LABEL.CONFIGURATION.OPTIONS.HEADEROPTION4'
    }
];
exports.PreEmptiveFailureActionOptions = [
    {
        value: models_1.PreEmptiveFailureActionOption.ADMINHOLD,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.PREEMPTIVE.OPTIONS.ADMIN_HOLD'
    },
    {
        value: models_1.PreEmptiveFailureActionOption.BOUNCE,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.PREEMPTIVE.OPTIONS.BOUNCE'
    }
];
exports.documentFormatOptions = [
    {
        value: models_1.DocumentFormatOption.PDF,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.DOCUMENTFORMAT.OPTIONS.PDF'
    },
    {
        value: models_1.DocumentFormatOption.TIFF,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.DOCUMENTFORMAT.OPTIONS.TIFF'
    },
    {
        value: models_1.DocumentFormatOption.ORIGINALFORMAT,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.DOCUMENTFORMAT.OPTIONS.ORIGINALFORMAT'
    }
];
exports.SpreadsheetsFormatOptions = [
    {
        value: models_1.SpreadsheetsFormatOption.CSV,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.SPREADSHEETFORMAT.OPTIONS.CSV'
    },
    {
        value: models_1.SpreadsheetsFormatOption.PDF,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.SPREADSHEETFORMAT.OPTIONS.PDF'
    },
    {
        value: models_1.SpreadsheetsFormatOption.TIFF,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.SPREADSHEETFORMAT.OPTIONS.TIFF'
    },
    {
        value: models_1.SpreadsheetsFormatOption.ORIGINALFORMAT,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.SPREADSHEETFORMAT.OPTIONS.ORIGINALFORMAT'
    },
    {
        value: models_1.SpreadsheetsFormatOption.HTML,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.SPREADSHEETFORMAT.OPTIONS.HTML'
    },
    {
        value: models_1.SpreadsheetsFormatOption.HTMLMULTITAB,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.SPREADSHEETFORMAT.OPTIONS.HTMLMULTITAB'
    }
];
exports.UserNotificationOptions = [
    {
        value: models_1.UserNotificationOption.INTERNALSENDER,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.USERNOTIFICATION.OPTIONS.INTERNALSENDER'
    },
    {
        value: models_1.UserNotificationOption.INTERNALRECIPIENT,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.USERNOTIFICATION.OPTIONS.INTERNALRECIPIENT'
    },
    {
        value: models_1.UserNotificationOption.EXTERNALRECIPIENT,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.USERNOTIFICATION.OPTIONS.EXTERNALSENDER'
    }
];
