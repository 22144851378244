"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.AppModule = void 0;
const core_interfaces_1 = require("./core/model/core.interfaces");
const reducers_1 = require("./core/reducers");
const ɵ0 = (store) => store.select(reducers_1.getCapabilities).pipe(core_interfaces_1.isDefined());
exports.ɵ0 = ɵ0;
class AppModule {
    ngDoBootstrap() { }
}
exports.AppModule = AppModule;
