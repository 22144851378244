"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompareViewsToolbarComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
class CompareViewsToolbarComponent {
    constructor() {
        this.compareViews = new core_1.EventEmitter();
        this.selectEmail = new core_1.EventEmitter();
        this.switchField = new forms_1.FormControl(false);
    }
    set selectedEmail(selectedEmail) {
        if (selectedEmail) {
            this.selectedEmailOwner = selectedEmail.owner;
            this.turnSwitchOff(selectedEmail.type);
        }
    }
    get deliveredMessagesRecipients() {
        return !!this.deliveredMessage && Object.keys(this.deliveredMessage);
    }
    ngOnInit() {
        this.subscription = this.switchField.valueChanges.subscribe(value => this.compareViews.emit(value));
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
    turnSwitchOff(selectedEmailType) {
        if (selectedEmailType === 'receipt' && this.switchField.value) {
            this.switchField.setValue(false);
        }
    }
}
exports.CompareViewsToolbarComponent = CompareViewsToolbarComponent;
