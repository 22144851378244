"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoDrivesComponent = void 0;
const core_1 = require("@angular/core");
class NoDrivesComponent {
    constructor() {
        this.onCreateBackup = new core_1.EventEmitter();
    }
    createBackup() {
        this.onCreateBackup.emit();
    }
}
exports.NoDrivesComponent = NoDrivesComponent;
