"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgPolicyDomainsStepComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const domain_already_inlist_validator_1 = require("../../../validators/domain-already-inlist.validator");
const block_mimecast_domain_validator_1 = require("app-new/swg/policies/validators/block-mimecast-domain-validator");
const domain_validator_1 = require("app-new/components/validators/domain/domain.validator");
class SwgPolicyDomainsStepComponent {
    constructor(fb, domainAlreadyInListService, swgService) {
        this.fb = fb;
        this.domainAlreadyInListService = domainAlreadyInListService;
        this.swgService = swgService;
        this.updatePolicyDomains = new core_1.EventEmitter();
        this.openBulkUploadDomains = new core_1.EventEmitter();
        this.domainList = [];
        this.columns = ['value', 'action', 'delete-column'];
        this.maxRows = 10000;
    }
    ngOnInit() {
        this.domainsPage = this.fb.group({
            action: ['block', forms_1.Validators.required],
            value: [
                '',
                [domain_validator_1.apiDomainOrUrlValidator, block_mimecast_domain_validator_1.blockMimecastDomain],
                domain_already_inlist_validator_1.DomainAlreadyInListValidator.createValidator(this.domainAlreadyInListService)
            ],
            type: ['domain']
        });
        if (this.swgService.domains5KLimit()) {
            this.maxRows = 5000;
        }
    }
    isValid() {
        return true;
    }
    isUrlFieldEmpty() {
        return this.domainsPage.value.value.trim().length === 0;
    }
    updateStore(domainList) {
        this.updatePolicyDomains.emit(domainList);
        this.domainsPage.controls['value'].updateValueAndValidity();
        return false;
    }
    addDomain() {
        const enteredValue = this.domainsPage.value.value;
        const urlOrDomain = Object.assign(Object.assign({}, domain_validator_1.checkIfUrlOrDomain(enteredValue)), { action: this.domainsPage.value.action });
        const domainList = [...this.domains, urlOrDomain];
        this.domainsPage.patchValue({
            action: this.domainsPage.value.action,
            value: '',
            type: 'domain'
        });
        this.domainsPage.controls['value'].markAsPristine();
        this.updateStore(domainList);
    }
    onDeleteRow(row) {
        const domainList = this.domains.filter(x => x !== row);
        this.updateStore(domainList);
    }
    openBulkUploadDomainsAction() {
        this.openBulkUploadDomains.emit();
    }
}
exports.SwgPolicyDomainsStepComponent = SwgPolicyDomainsStepComponent;
