<div class="mc-detailed-list">
  <div *ngIf="infoBoxMessage && !notificationDetailsDismissForever">
    <mc-info-box
      [message]="infoBoxMessage"
      [learnMoreUrl]="infoBoxLearnMoreUrl"
      (dismissForever)="dismissForever.emit()"
    >
    </mc-info-box>
  </div>

  <div *ngIf="messageStatus === 'bounced'">
    <mc-bounce-properties [queueInfo]="queueInfo"></mc-bounce-properties>
  </div>

  <div *ngIf="messageStatus === 'deferred'">
    <mc-connect-properties [queueInfo]="queueInfo"></mc-connect-properties>
  </div>

  <div *ngIf="messageStatus === 'rejected'">
    <mc-rejection-properties [queueInfo]="queueInfo"></mc-rejection-properties>
  </div>

  <div *ngIf="showMessageProperties()">
    <mc-message-properties
      [queueInfo]="queueInfo"
      [selectedMessageInfo]="selectedMessageInfo"
      [messageStatus]="messageStatus"
    >
    </mc-message-properties>
  </div>

  <div *ngIf="showSpamDetailsInfo()">
    <hr />
    <mc-spam-details-info
      [spamInfo]="spamInfo"
    >
    </mc-spam-details-info>
  </div>

  <div *ngIf="showTripletInformation()">
    <hr />
    <mc-triplet-information [queueInfo]="queueInfo"></mc-triplet-information>
  </div>

  <div *ngIf="showQueueInformation()">
    <hr />
    <mc-queue-information [queueInfo]="queueInfo"></mc-queue-information>
  </div>

  <div *ngIf="showRemoteServerInfo()">
    <hr />
    <mc-remote-server-info [queueInfo]="queueInfo" [messageStatus]="messageStatus">
    </mc-remote-server-info>
  </div>

  <div *ngIf="showMtaServerInfo()">
    <hr />
    <mc-mta-server-info [queueInfo]="queueInfo"></mc-mta-server-info>
  </div>

  <div *ngIf="showDeliveryFailureNoticeInfo()">
    <hr />
    <mc-delivery-failure-notice-info [notices]="notices"></mc-delivery-failure-notice-info>
  </div>
</div>
