"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgPolicyNavigateToListPageAction = exports.SwgPolicyNavigateToEditPageAction = exports.SwgDetailDisableConfirmModelClose = exports.SwgDetailDisableConfirmModelOpen = exports.SwgDetailDeleteConfirmModelClose = exports.SwgDetailDeleteConfirmModelOpen = exports.SwgPolicyUnchangedDuplicateWarnClose = exports.SwgPolicyUnchangedDuplicateWarnOpen = exports.SwgDomainsCsvUploaderClose = exports.SwgDomainsCsvUploaderOpen = exports.SwgDetailEditSidebarClose = exports.SwgDetailEditSidebarOpen = exports.SwgSidebarActionsEnum = void 0;
var SwgSidebarActionsEnum;
(function (SwgSidebarActionsEnum) {
    SwgSidebarActionsEnum["SWG_POLICY_NAVIGATE_TO_EDIT_PAGE"] = "[Policy] Swg detail navigate to edit page";
    SwgSidebarActionsEnum["SWG_POLICY_NAVIGATE_TO_LIST_PAGE"] = "[Policy] Swg detail navigate to list page";
    SwgSidebarActionsEnum["SWG_POLICY_EDIT_SIDEBAR_OPEN"] = "[Policy] Swg detail sidebar open";
    SwgSidebarActionsEnum["SWG_POLICY_EDIT_SIDEBAR_CLOSE"] = "[Policy] Swg detail close sidebar";
    SwgSidebarActionsEnum["SWG_POLICY_DOMAINS_CSV_UPLOADER_OPEN"] = "[Policy] Swg domains cvs uploader open";
    SwgSidebarActionsEnum["SWG_POLICY_DOMAINS_CSV_UPLOADER_CLOSE"] = "[Policy] Swg domains cvs uploader close";
    SwgSidebarActionsEnum["SWG_POLICY_DELETE_CONFIRM_MODEL_OPEN"] = "[Policy] Swg delete Policy Confirm Model open";
    SwgSidebarActionsEnum["SWG_POLICY_DELETE_CONFIRM_MODEL_CLOSE"] = "[Policy] Swg delete Policy Confirm Model close";
    SwgSidebarActionsEnum["SWG_POLICY_DISABLE_CONFIRM_MODEL_OPEN"] = "[Policy] Swg disable Policy Confirm Model open";
    SwgSidebarActionsEnum["SWG_POLICY_DISABLE_CONFIRM_MODEL_CLOSE"] = "[Policy] Swg disable Policy Confirm Model close";
    SwgSidebarActionsEnum["SWG_POLICY_UNCHANGED_DUPLICATE_WARN_OPEN"] = "[Policy] Swg unchanged duplicate Warn Modal Open";
    SwgSidebarActionsEnum["SWG_POLICY_UNCHANGED_DUPLICATE_WARN_CLOSE"] = "[Policy] Swg unchanged duplicate Warn Modal Close";
})(SwgSidebarActionsEnum = exports.SwgSidebarActionsEnum || (exports.SwgSidebarActionsEnum = {}));
class SwgDetailEditSidebarOpen {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgSidebarActionsEnum.SWG_POLICY_EDIT_SIDEBAR_OPEN;
    }
}
exports.SwgDetailEditSidebarOpen = SwgDetailEditSidebarOpen;
class SwgDetailEditSidebarClose {
    constructor() {
        this.type = SwgSidebarActionsEnum.SWG_POLICY_EDIT_SIDEBAR_CLOSE;
    }
}
exports.SwgDetailEditSidebarClose = SwgDetailEditSidebarClose;
class SwgDomainsCsvUploaderOpen {
    constructor() {
        this.type = SwgSidebarActionsEnum.SWG_POLICY_DOMAINS_CSV_UPLOADER_OPEN;
    }
}
exports.SwgDomainsCsvUploaderOpen = SwgDomainsCsvUploaderOpen;
class SwgDomainsCsvUploaderClose {
    constructor() {
        this.type = SwgSidebarActionsEnum.SWG_POLICY_DOMAINS_CSV_UPLOADER_CLOSE;
    }
}
exports.SwgDomainsCsvUploaderClose = SwgDomainsCsvUploaderClose;
class SwgPolicyUnchangedDuplicateWarnOpen {
    constructor() {
        this.type = SwgSidebarActionsEnum.SWG_POLICY_UNCHANGED_DUPLICATE_WARN_OPEN;
    }
}
exports.SwgPolicyUnchangedDuplicateWarnOpen = SwgPolicyUnchangedDuplicateWarnOpen;
class SwgPolicyUnchangedDuplicateWarnClose {
    constructor() {
        this.type = SwgSidebarActionsEnum.SWG_POLICY_UNCHANGED_DUPLICATE_WARN_CLOSE;
    }
}
exports.SwgPolicyUnchangedDuplicateWarnClose = SwgPolicyUnchangedDuplicateWarnClose;
class SwgDetailDeleteConfirmModelOpen {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgSidebarActionsEnum.SWG_POLICY_DELETE_CONFIRM_MODEL_OPEN;
    }
}
exports.SwgDetailDeleteConfirmModelOpen = SwgDetailDeleteConfirmModelOpen;
class SwgDetailDeleteConfirmModelClose {
    constructor() {
        this.type = SwgSidebarActionsEnum.SWG_POLICY_DELETE_CONFIRM_MODEL_CLOSE;
    }
}
exports.SwgDetailDeleteConfirmModelClose = SwgDetailDeleteConfirmModelClose;
class SwgDetailDisableConfirmModelOpen {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgSidebarActionsEnum.SWG_POLICY_DISABLE_CONFIRM_MODEL_OPEN;
    }
}
exports.SwgDetailDisableConfirmModelOpen = SwgDetailDisableConfirmModelOpen;
class SwgDetailDisableConfirmModelClose {
    constructor() {
        this.type = SwgSidebarActionsEnum.SWG_POLICY_DISABLE_CONFIRM_MODEL_CLOSE;
    }
}
exports.SwgDetailDisableConfirmModelClose = SwgDetailDisableConfirmModelClose;
class SwgPolicyNavigateToEditPageAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgSidebarActionsEnum.SWG_POLICY_NAVIGATE_TO_EDIT_PAGE;
    }
}
exports.SwgPolicyNavigateToEditPageAction = SwgPolicyNavigateToEditPageAction;
class SwgPolicyNavigateToListPageAction {
    constructor() {
        this.type = SwgSidebarActionsEnum.SWG_POLICY_NAVIGATE_TO_LIST_PAGE;
    }
}
exports.SwgPolicyNavigateToListPageAction = SwgPolicyNavigateToListPageAction;
