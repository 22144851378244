<mc-wizard-step-header [label]="'$I18N_BI_POLiCY_SETTINGS_STEP_DESC'"></mc-wizard-step-header>
<div class="mc-bi-policy-settings-step">
  <form novalidate [formGroup]='biPolicySettingsForm' class="mc-new-domains-settings-step">
    <div>
      <span class="text-bold" translate="$I18N_BI_POLICY_SETTINGS_STEP_ENTER_TEXT"></span>
      <mc-enable-switch [longDesc]="'$I18N_BI_POLICY_SETTINGS_STEP_ENTER_TEXT_DESC'"
                        formControlName='enterText'></mc-enable-switch>
    </div>
    <div *ngIf="biPolicySettingsForm.get('enterText').value">
      <span class="text-bold" translate="$I18N_BI_POLiCY_SETTINGS_STEP_TYPE_FROM_DEVICE_CLIPBOARD"></span>
      <mc-enable-switch [longDesc]="'$I18N_BI_POLiCY_SETTINGS_STEP_TYPE_FROM_DEVICE_CLIPBOARD_DESC'"
                        formControlName='clipboardTransferIn'></mc-enable-switch>
    </div>
    <div>
      <span class="text-bold" translate="$I18N_BI_POLiCY_SETTINGS_STEP_TYPE_TO_DEVICE_CLIPBOARD"></span>
      <mc-enable-switch [longDesc]="'$I18N_BI_POLiCY_SETTINGS_STEP_TYPE_TO_DEVICE_CLIPBOARD_DESC'"
                        formControlName='clipboardTransferOut'></mc-enable-switch>
    </div>
  </form>
</div>
