<div class="mc-supervision-wizard-step-container">
  <mc-wizard-step-header
    class="mc-supervision-wizard-step-header"
    label="$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.DETAILS_STEP.STEP_DESCRIPTION"
  ></mc-wizard-step-header>
  <form
    [formGroup]="form"
    class="form-horizontal"
    mcDefaultLabelClass="col-sm-3"
    mcDefaultControlContainerClass="col-sm-9"
  >
    <mc-text-field
      label="{{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.DETAILS_STEP.NAME' | translate }}"
      placeholder="{{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.DETAILS_STEP.NAME' | translate }}"
      formControlName="name"
      required
      errorPrefix="$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.VALIDATION.NAME"
    ></mc-text-field>

    <mc-text-area-field
      label="{{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.DETAILS_STEP.DESCRIPTION' | translate }}"
      placeholder="{{
        '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.DETAILS_STEP.DESCRIPTION' | translate
      }}"
      formControlName="description"
      required
      errorPrefix="$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.VALIDATION.DESCRIPTION"
    ></mc-text-area-field>

    <div class="form-group">
      <mc-field
        label="{{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.DETAILS_STEP.TYPE' | translate }}"
        [helpPopoverContent]="queueTypePopover"
      >
        <div class="mc-queue-types">
          <div
            class="panel-half-padding-bottom"
            *ngIf="isEditMode && form.value.qtype === QueueType.REVIEW"
          >
            <label class="mc-queue-type-update">
              <input
                type="hidden"
                name="qtype"
                formControlName="qtype"
                [value]="QueueType.REVIEW"
              />
              {{
                '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.DETAILS_STEP.TYPE_REVIEW_QUEUE' | translate
              }}
            </label>
            <p class="mc-queue-info-text">
              <i class="fas fa-info-circle"></i>
              <span>
                {{
                  '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.DETAILS_STEP.TYPE_UPDATE_INFO' | translate
                }}
              </span>
            </p>
          </div>
          <div
            class="panel-half-padding-bottom"
            *ngIf="isEditMode && form.value.qtype === QueueType.ESCALATION"
          >
            <label class="mc-queue-type-update">
              <input
                type="hidden"
                name="qtype"
                formControlName="qtype"
                [value]="QueueType.ESCALATION"
              />
              {{
                '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.DETAILS_STEP.TYPE_ESCALATION_QUEUE'
                  | translate
              }}
            </label>
            <p class="mc-queue-info-text">
              <i class="fas fa-info-circle"></i>
              <span>
                {{
                  '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.DETAILS_STEP.TYPE_UPDATE_INFO' | translate
                }}
              </span>
            </p>
          </div>
          <div class="radio panel-half-padding-bottom" *ngIf="!isEditMode">
            <label class="text-bold">
              <input type="radio" name="qtype" formControlName="qtype" [value]="QueueType.REVIEW" />
              {{
                '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.DETAILS_STEP.TYPE_REVIEW_QUEUE' | translate
              }}
            </label>
          </div>

          <div class="radio panel-half-padding-bottom" *ngIf="!isEditMode">
            <label class="text-bold">
              <input
                type="radio"
                name="qtype"
                formControlName="qtype"
                [value]="QueueType.ESCALATION"
              />
              {{
                '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.DETAILS_STEP.TYPE_ESCALATION_QUEUE'
                  | translate
              }}
            </label>
          </div>
        </div>
      </mc-field>
    </div>

    <div
      class="form-group"
      *ngIf="!loading && escalationQueues && form.value.qtype === QueueType.REVIEW"
      formGroupName="queues"
    >
      <mc-field
        label="{{
          '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.DETAILS_STEP.ESCALATION_QUEUES' | translate
        }}"
        helpPopoverContent="{{
          '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.DETAILS_STEP.ESCALATION_QUEUES_POPOVER'
            | translate
        }}"
      >
        <div
          [className]="
            escalationQueues.length >= 5
              ? 'mc-escalation-queues-scrollable'
              : 'mc-escalation-queues'
          "
        >
          <div class="checkbox panel-half-padding-bottom" *ngFor="let queue of escalationQueues">
            <label class="text-bold">
              <input
                type="checkbox"
                [formControlName]="queue.id"
                (change)="handleSelected($event, queue.id)"
              />
              {{ queue.name }}
            </label>
          </div>
        </div>
      </mc-field>
    </div>

    <div
      class="form-group"
      *ngIf="!loading && getSelectedQueues().length > 1 && form.value.qtype === QueueType.REVIEW"
    >
      <mc-field
        label="{{
          '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.DETAILS_STEP.DEFAULT_ESCALATION_QUEUE' | translate
        }}"
      >
        <mc-select
          formControlName="defaultEscalationQueue"
          name="defaultEscalationQueue"
          [options]="defaultEscalationQueueOptions"
          [defaultValue]="defaultEscalationQueueOptions[0].value"
        >
        </mc-select>
      </mc-field>
    </div>
  </form>
</div>

<ng-template #queueTypePopover>
  <p>
    <strong>Review queue</strong>
    {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.DETAILS_STEP.TYPE_POPOVER_START' | translate }}
  </p>
  <p>
    <strong>Escalation queue</strong>
    {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.DETAILS_STEP.TYPE_POPOVER_END' | translate }}
  </p>
</ng-template>
