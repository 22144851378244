"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasErrors = exports.getUserProfileEmail = exports.isLoading = exports.getURLCategories = exports.reducer = exports.initialState = void 0;
const logs_side_panel_actions_1 = require("../actions/logs-side-panel.actions");
exports.initialState = {
    categories: {
        securityCategories: [],
        productivityCategories: []
    },
    isLoading: false,
    hasErrors: false,
    userEmailAddress: null
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case logs_side_panel_actions_1.URL_REPORT_GET_CATEGORIES_ACTION:
            return Object.assign(Object.assign({}, state), { isLoading: true });
        case logs_side_panel_actions_1.URL_ACTIVITY_REPORT_GET_CATEGORIES_SUCCESS_ACTION:
            return Object.assign(Object.assign({}, state), { categories: Object.assign(Object.assign({}, state.categories), { securityCategories: [...action.payload.securityCategories], productivityCategories: [...action.payload.productivityCategories] }), isLoading: false });
        case logs_side_panel_actions_1.URL_ACTIVITY_REPORT_GET_CATEGORIES_FAILURE_ACTION:
            return Object.assign(Object.assign({}, state), { isLoading: false, hasErrors: true });
        case logs_side_panel_actions_1.URL_ACTIVITY_REPORT_GET_PROFILE_EMAIL_SUCCESS_ACTION:
            return Object.assign(Object.assign({}, state), { userEmailAddress: action.payload });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getURLCategories = (state) => (state ? Object.assign({}, state.categories) : {});
exports.getURLCategories = getURLCategories;
const isLoading = (state) => state.isLoading;
exports.isLoading = isLoading;
const getUserProfileEmail = (state) => state.userEmailAddress;
exports.getUserProfileEmail = getUserProfileEmail;
const hasErrors = (state) => state.hasErrors;
exports.hasErrors = hasErrors;
