"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./pagination-directive"); //pagination.directive
// html and css 
require("./pagination.less");
require("./pagination.tpl.html");
/**
 * Created by pdesai on 14/03/2017.
 */
(function () {
    'use strict';
    angular.module('pagination', ['pagination.directive']);
}());
