"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditPolicyStatusAction = exports.EditDefinitionPolicyAction = exports.EditPolicySuccessAction = exports.EditPolicyAction = exports.UpdateCreatePolicyPageInEditMode = exports.OpenCreatePolicyPageInEditMode = exports.EDIT_POLICY_STATUS = exports.EDIT_POLICY_SUCCESS = exports.EDIT_POLICY = exports.EDIT_POLICY_DEFINITION = exports.UPDATE_EDIT_POLICY_WIZARD = exports.OPEN_EDIT_POLICY_WIZARD = void 0;
exports.OPEN_EDIT_POLICY_WIZARD = '[Services / Url protection / Policies] Open Policy Wizard in edit mode';
exports.UPDATE_EDIT_POLICY_WIZARD = '[Services / Url protection / Policies] Update Policy Wizard in edit mode';
exports.EDIT_POLICY_DEFINITION = '[Services / Url protection / Policies] Edit Policy Definition';
exports.EDIT_POLICY = '[Services / Url protection / Policies] Edit Policy Details and Rules';
exports.EDIT_POLICY_SUCCESS = '[Services / Url protection / Policies] Edit policy - SUCCESS';
exports.EDIT_POLICY_STATUS = '[Services / Url protection / Policies] Edit Policy Status';
class OpenCreatePolicyPageInEditMode {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.OPEN_EDIT_POLICY_WIZARD;
    }
}
exports.OpenCreatePolicyPageInEditMode = OpenCreatePolicyPageInEditMode;
class UpdateCreatePolicyPageInEditMode {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.UPDATE_EDIT_POLICY_WIZARD;
    }
}
exports.UpdateCreatePolicyPageInEditMode = UpdateCreatePolicyPageInEditMode;
class EditPolicyAction {
    constructor() {
        this.type = exports.EDIT_POLICY;
    }
}
exports.EditPolicyAction = EditPolicyAction;
class EditPolicySuccessAction {
    constructor() {
        this.type = exports.EDIT_POLICY_SUCCESS;
    }
}
exports.EditPolicySuccessAction = EditPolicySuccessAction;
class EditDefinitionPolicyAction {
    constructor() {
        this.type = exports.EDIT_POLICY_DEFINITION;
    }
}
exports.EditDefinitionPolicyAction = EditDefinitionPolicyAction;
class EditPolicyStatusAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.EDIT_POLICY_STATUS;
    }
}
exports.EditPolicyStatusAction = EditPolicyStatusAction;
