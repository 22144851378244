<div class="panel panel-default mc-tr-panel">
  <div class="panel-heading">
    {{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.TOP_REVIEWERS.PERFORMANCE' | translate }}
  </div>
  <div class="partial-data-msg" *ngIf="topReviewerPerformances.hasAssociatedFailedQueues">
    <i class="fas fa-info-circle"></i>
    <span>
      {{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.TOP_REVIEWERS.PARTIAL_DATA_MSG' | translate }}
    </span>
  </div>
  <table class="table" *ngIf="hasReviewers(); else nodata">
    <thead>
      <tr>
        <th class="name-col header-col">
          {{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.TOP_REVIEWERS.REVIEWER' | translate }}
        </th>
        <th class="total-count-col header-col text-center" colspan="2">
          {{
            '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.TOP_REVIEWERS.PROCESSED_PAST_7_DAYS' | translate
          }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let reviewerPerformance of topReviewerPerformances.reviewerPerformances">
        <td class="ellipsis-col" title="{{ reviewerEmail(reviewerPerformance) }}">
          {{ reviewerEmail(reviewerPerformance) }}
        </td>
        <td class="text-right">
          {{ reviewerPerformance.processedCount | number | mcZeroToDash }}
        </td>
        <td>
          <span
            [className]="
              isReviewQueueReviewer() ? 'rq-total-bar-indicator' : 'eq-total-bar-indicator'
            "
            [ngStyle]="{ 'width.%': calculateIndicatorWidth(reviewerPerformance) }"
          >
          </span>
        </td>
      </tr>
      <tr class="totals-row">
        <td>
          {{ '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.TOP_REVIEWERS.TOTAL' | translate }}
        </td>
        <td class="text-right">
          {{ topReviewerPerformances.totalProcessedCount | number }}
        </td>
        <td></td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #nodata>
  <mc-empty-results
    [keyTitle]="'$I18N_ARCHIVE_SUPERVISION_DASHBOARD.TOP_REVIEWERS.NO_DATA.NO_PERFORMANCE_DATA'"
    class="no-data"
  >
    <mc-empty-body>
      <div class="panel-half-padding-bottom">
        <ng-container
          *ngIf="isReviewQueueReviewer(); then reviewQueueAction; else escalationQueueAction"
        ></ng-container>
        <span class="link" (click)="openTab()">
          <ng-container
            *ngIf="isReviewQueueReviewer(); then reviewQueueLinkText; else escalationQueueLinkText"
          ></ng-container></span
        >{{
          '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.TOP_REVIEWERS.NO_DATA.SUFFIX_ACTION_TEXT' | translate
        }}
      </div>
    </mc-empty-body>
  </mc-empty-results>
</ng-template>

<ng-template #reviewQueueAction>{{
  '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.TOP_REVIEWERS.NO_DATA.PREFIX_ACTION_TEXT_REVIEW_QUEUE'
    | translate
}}</ng-template>
<ng-template #escalationQueueAction>{{
  '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.TOP_REVIEWERS.NO_DATA.PREFIX_ACTION_TEXT_ESCALATION_QUEUE'
    | translate
}}</ng-template>

<ng-template #reviewQueueLinkText>{{
  '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.TOP_REVIEWERS.NO_DATA.REVIEW_QUEUE_LINK_TEXT' | translate
}}</ng-template>
<ng-template #escalationQueueLinkText>{{
  '$I18N_ARCHIVE_SUPERVISION_DASHBOARD.TOP_REVIEWERS.NO_DATA.ESCALATION_QUEUE_LINK_TEXT' | translate
}}</ng-template>
