<div class="panel-padding-bottom">
  <ng-container *ngIf="progressMessage==='progress'">
    <span class="mc-menu-icon mc-file-remediation-green fas fa-clock"></span>
    <span
      class="text-bold">{{'$18N_SWG_REMEDIATION_DEVICE_SUMMARY.PROGRESS_' + incidentSummary?.removeFromDevice | uppercase | translate}}</span>
    {{incidentSummary?.fileRemediationExpiryTime | mcDate}}
  </ng-container>
  <ng-container *ngIf="progressMessage==='expire'">
    <span class="mc-menu-icon mc-file-remediation-green fas fa-exclamation-triangle"></span>
    <span
      class="text-bold">{{'$18N_SWG_REMEDIATION_DEVICE_SUMMARY.EXPIRE_' + incidentSummary?.removeFromDevice| uppercase | translate}}</span>
    {{incidentSummary?.fileRemediationExpiryTime | mcDate}}
  </ng-container>
  <ng-container *ngIf="progressMessage==='cancel'">
    <span class="mc-menu-icon mc-file-remediation-cancel fas fa-info-circle"></span>
    <span
      class="text-bold">{{'$18N_SWG_REMEDIATION_DEVICE_SUMMARY.CANCEL_' + incidentSummary?.removeFromDevice | uppercase | translate}}</span>
    {{incidentSummary?.fileRemediationCancelled | mcDate}}

  </ng-container>
</div>
<mc-table
  tableId="swg/remediation/device-summary"
  [isLoading]="isLoading$ | async"
  [data]="tableData$ | async"
  [columns]="columns"
  translatePrefix="$18N_SWG_REMEDIATION_DEVICE_SUMMARY.TABLE"
>
  <button class="mc-table-actions btn btn-secondary" *ngIf="canCancel"
          (click)="onOpenCancelRemediationModal()">
    {{ '$18N_SWG_REMEDIATION_DEVICE_SUMMARY.CANCEL_REMOVAL' | translate }}
  </button>

  <mc-filters (paginatorChange)="onPaginationChange($event)">
    <mc-filter-search
      (search)="onSearchIncidents($event)"
      (clear)="onSearchClear()"
      [options]="filterSearchOptions"
    >
    </mc-filter-search>
    <mc-filters-bundle-column-values
      [sections]="searchFilters"
      (filtersChange)="onBundleFilterChange($event)"
    >
    </mc-filters-bundle-column-values>
  </mc-filters>
  <mc-column-date key="remediatedOn"></mc-column-date>
  <mc-column key="remediationResult">
    <mc-body-cell *mcBodyCellDef="let row">
      <div class="mc-status-text-container">
        {{ '$18N_SWG_REMEDIATION_DEVICE_SUMMARY.TABLE.FILTERS.COLUMN_VALUE_FILTER_FIELD.STATUS_FILTERS.' + row.remediationResult | uppercase | translate }}
      </div>
    </mc-body-cell>
  </mc-column>
  <mc-column-actions key="right-column" mcShowColumnConfig>
  </mc-column-actions>
  <mc-empty-results keyTitle="$18N_SWG_REMEDIATION_DEVICE_SUMMARY.TABLE.NO_DATA"></mc-empty-results>
</mc-table>
