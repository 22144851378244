"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgEndpointsListImplService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const table_store_1 = require("@mimecast-ui/table-store");
const endpoints_list_models_1 = require("../models/endpoints-list.models");
class SwgEndpointsListImplService {
    constructor(endpointListApiService, endpointsListDataMappingService, swgService) {
        this.endpointListApiService = endpointListApiService;
        this.endpointsListDataMappingService = endpointsListDataMappingService;
        this.swgService = swgService;
    }
    getData(payload, pagination = table_store_1.defaultPaginationOption) {
        const config = Object.assign(Object.assign({}, payload), { meta: { pagination } });
        return this.endpointListApiService.getEndpointsList(config).pipe(operators_1.map((response) => {
            const endpoints = this.swgService.hasDeviceBasedPoliciesSwitchEnabled()
                ? response.first.endpoints
                : this.mapEndpoints(response.first.endpoints);
            return {
                hasErrors: response.hasErrors,
                data: endpoints,
                failures: response.fail,
                meta: response.meta
            };
        }), operators_1.catchError((response) => {
            return rxjs_1.of({
                hasErrors: response.hasErrors,
                data: response.first.logs,
                failures: response.fail,
                meta: response.meta
            });
        }));
    }
    filter(query, pagination) {
        return this.getData(query, pagination);
    }
    openItem() {
        return rxjs_1.of([]);
    }
    mapEndpoints(endpoints) {
        if (endpoints.length) {
            for (const endpoint of endpoints) {
                if (!!endpoint.protectedDeviceDetail &&
                    !!endpoint.protectedDeviceDetail.osEdition &&
                    endpoint.protectedDeviceDetail.osEdition.toLowerCase().includes(endpoints_list_models_1.DEVICE.WINDOWS)) {
                    endpoint.os = this.endpointsListDataMappingService.mapDeviceOS(endpoint.protectedDeviceDetail.osEdition);
                }
            }
        }
        return endpoints;
    }
}
exports.SwgEndpointsListImplService = SwgEndpointsListImplService;
