"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InputDropdownComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const operators_1 = require("rxjs/operators");
class InputDropdownComponent {
    constructor(_fb) {
        this._fb = _fb;
        this.isRequired = false;
        this.valueChanges = new core_1.EventEmitter();
    }
    ngOnInit() {
        this.formFiles = this._fb.group({
            docs: ['', [forms_1.Validators.minLength(5), forms_1.Validators.maxLength(150)]],
            type: ['optional']
        });
        this.formFiles.valueChanges
            .pipe(operators_1.debounceTime(300))
            .subscribe((newFormValue) => this.onFormValueChanges(newFormValue));
        this.onFormValueChanges(this.formFiles.value);
    }
    onFormValueChanges(newFormValue) {
        this.valueChanges.emit(newFormValue);
    }
}
exports.InputDropdownComponent = InputDropdownComponent;
