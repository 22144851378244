"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TopHeaderRecipientComponent = void 0;
const core_1 = require("@angular/core");
class TopHeaderRecipientComponent {
    constructor() {
        this.permitForRecipient = new core_1.EventEmitter();
        this.blockForRecipient = new core_1.EventEmitter();
    }
}
exports.TopHeaderRecipientComponent = TopHeaderRecipientComponent;
