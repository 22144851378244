"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./containers/drives/drives");
require("./containers/snapshot/snapshot");
angular
    .module('sync-recover.onedrive', [
    'sync-recover.onedrive.drive',
    'sync-recover.onedrive.snapshot'
])
    .controller('OnedriveController', function () { });
