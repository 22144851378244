<mc-api-integration-cortex-wizard
  [cortexIntegrationState]="cortexState$ | async"
  [availableProviders]="availableProviders$ | async"
  [selectedProvider]="selectedProvider$ | async"
  [cortexState]="cortexState"
  [cortexConfiguration]="cortexConfiguration$ | async"
  [cortexSpywareSettings]="cortexSpywareSettings$ | async"
  [cortexWildfireSettings]="cortexWildfireSettings$ | async"
  [cortexCreated]="cortexCreated$"
  [cortexUpdated]="cortexUpdated$"
  [cortexFresh]="cortexFresh$ | async"
  [cortexIntegrationVersion1]="cortexIntegrationVersion1$ | async"
  [isBusy]="isBusy$ | async"
  [isChanged]="isChanged$ | async"
  [emails]="emails$ | async"
  [isReady]="isReady$"
  [groupsList$]="groupsList$"
  [hasErrorCapabilityAndUrl]="hasErrorCapabilityAndUrl"
  (onProviderSelected)="providerSelected($event)"
  (onDeleteEmail)="deleteEmail($event)"
  (onDeleteGroup)="deleteGroup($event)"
  (onAddGroup)="addGroup($event)"
  (onVerifyAndAddEmail)="verifyAndAddEmail($event)"
  (onUpdateSpywareSettings)="updateSpywareSettings($event)"
  (onUpdateWildfireSettings)="updateWildfireSettings($event)"
  (onCreateCortexIntegrationVersion1)="createCortexIntegrationVersion1()"
  (onUpdateCortexIntegrationVersion1)="updateCortexIntegrationVersion1()"
  (onAuthoriseCortexIntegrationVersion1)="authoriseCortexIntegrationVersion1()"
  (OnIntegrationStatusUpdated)="integrationStatusUpdated($event)"
  (onIntegrationCompleted)="complete()"
  (onCancel)="cancel()"
>
</mc-api-integration-cortex-wizard>
