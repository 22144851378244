"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IntegrationService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const common_1 = require("../common");
class IntegrationService {
    constructor(http) {
        this.http = http;
    }
    getAllIntegrations(hasError) {
        const request = hasError ? { hasError } : undefined;
        return this.http.post('/api/integration/get-integration', request).pipe(common_1.allResult());
    }
    getIntegration(id, isExternalId) {
        if (isExternalId) {
            return this.http
                .post('/api/integration/get-integration', {
                externalId: { externalId: id, type: 'CORTEX' }
            })
                .pipe(common_1.firstResult());
        }
        return this.http
            .post('/api/integration/get-integration', { id })
            .pipe(common_1.firstResult());
    }
    enableIntegration(id) {
        return this.http
            .post('/api/integration/enable-integration', { id })
            .pipe(operators_1.map(() => {
            const RESPONSE = {
                success: true,
                id
            };
            return RESPONSE;
        }), operators_1.catchError(() => {
            const RESPONSE = {
                success: false,
                id
            };
            return rxjs_1.of(RESPONSE);
        }));
    }
    disableIntegration(id) {
        return this.http
            .post('/api/integration/disable-integration', { id })
            .pipe(operators_1.map((response) => response.first), operators_1.catchError(() => {
            const RESPONSE = {
                success: false,
                id
            };
            return rxjs_1.of(RESPONSE);
        }));
    }
    deleteIntegration(id) {
        return this.http
            .post('/api/integration/delete-integration', { id })
            .pipe(common_1.firstResult());
    }
}
exports.IntegrationService = IntegrationService;
