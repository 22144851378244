"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GatewayDisclaimerStepComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const operators_1 = require("rxjs/operators");
class GatewayDisclaimerStepComponent {
    constructor(fb) {
        this.fb = fb;
        this.delay = 200;
        this.onApplicationAcceptTerms = new core_1.EventEmitter();
        this.onSubmitted = new core_1.EventEmitter();
        this.form = this.fb.group({
            acceptTerms: new forms_1.FormControl({ value: false, disabled: false })
        });
    }
    ngOnInit() {
        this.formSubscription = this.form.valueChanges.pipe(operators_1.debounceTime(this.delay)).subscribe(() => {
            this.acceptTerms();
        });
    }
    acceptTerms() {
        this.onApplicationAcceptTerms.emit(this.form.value.acceptTerms);
    }
    submit() {
        if (this.isValid()) {
            this.onSubmitted.emit();
        }
    }
    isValid() {
        return this.form.value.acceptTerms;
    }
    ngOnDestroy() {
        this.formSubscription.unsubscribe();
    }
}
exports.GatewayDisclaimerStepComponent = GatewayDisclaimerStepComponent;
