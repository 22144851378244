"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DownloadFileService = void 0;
class DownloadFileService {
    downLoadContentAsFile(fileName, content) {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(content));
        element.setAttribute('download', fileName);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }
}
exports.DownloadFileService = DownloadFileService;
