"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UrlConfirmationModalComponent = exports.CONFIRMATION_DIALOG_TYPES = void 0;
var CONFIRMATION_DIALOG_TYPES;
(function (CONFIRMATION_DIALOG_TYPES) {
    CONFIRMATION_DIALOG_TYPES["DELETE"] = "DELETE";
    CONFIRMATION_DIALOG_TYPES["OVERRIDE"] = "OVERRIDE";
})(CONFIRMATION_DIALOG_TYPES = exports.CONFIRMATION_DIALOG_TYPES || (exports.CONFIRMATION_DIALOG_TYPES = {}));
class UrlConfirmationModalComponent {
    constructor(overlayRef, data, translate) {
        this.overlayRef = overlayRef;
        this.translate = translate;
        this.hasError = false;
        this.data = data;
    }
    ngOnInit() {
        // set title and button text
        this.setModalTexts();
    }
    setModalTexts() {
        switch (this.data.type) {
            case CONFIRMATION_DIALOG_TYPES.DELETE:
                this.title = this.translate.instant('$I18N_SERVICES_URL_PROTECTION_MANAGED_URL_DELETE_CONFIRMATION_TITLE');
                this.buttonText = this.translate.instant('$I18N_SERVICES_URL_PROTECTION_MANAGED_URL_DELETE_CONFIRMATION_ACTION_BTN');
                break;
            case CONFIRMATION_DIALOG_TYPES.OVERRIDE:
                this.title = this.translate.instant('$I18N_SERVICES_URL_PROTECTION_MANAGED_URL_UPDATE_URL_OVERRIDE_TEXT');
                this.buttonText = this.translate.instant('$I18N_SERVICES_URL_PROTECTION_MANAGED_URL_OVERRIDE_BUTTON');
                if (this.data.errors) {
                    if (this.data.errors.length > 0) {
                        this.hasError = true;
                    }
                    if (this.data.errors.length > 1) {
                        this.title = this.translate.instant('$I18N_SERVICES_URL_PROTECTION_MANAGED_URL_UPDATE_URL_MULTI_OVERRIDE_TITLE');
                    }
                }
                break;
        }
    }
    onDelete() {
        this.overlayRef.close(true);
    }
    onCancel() {
        this.overlayRef.close(false);
    }
}
exports.UrlConfirmationModalComponent = UrlConfirmationModalComponent;
