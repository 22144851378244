<div class="modal-header" *ngIf="!config.hideHeader">
  <button
    *ngIf="!config.hideTopCloseButton"
    type="button"
    class="close"
    (click)="overlayRef.close()"
  >
    <i class="icon-cancel"></i>
  </button>
  <h4 class="no-margin">{{ config.title | translate: config.titleParams }}</h4>
</div>

<div class="modal-body" *ngIf="!config.hideBody">
  <p [innerHTML]="config.content | translate: config.contentParams"></p>
</div>

<div class="modal-footer">
  <button
    *ngFor="let button of config.buttons"
    type="button"
    class="btn btn-{{ button.type }}"
    (click)="overlayRef.close(button.id)"
  >
    {{ button.title | translate }}
  </button>
</div>
