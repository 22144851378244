"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Overview = exports.DashboardStatistics = void 0;
class DashboardStatistics {
    constructor(overview, topReviewQueues, topEscalationQueues, topReviewQueuePerformances, topEscalationQueuePerformances) {
        this.overview = overview;
        this.topReviewQueues = topReviewQueues;
        this.topEscalationQueues = topEscalationQueues;
        this.topReviewQueuePerformances = topReviewQueuePerformances;
        this.topEscalationQueuePerformances = topEscalationQueuePerformances;
    }
}
exports.DashboardStatistics = DashboardStatistics;
class Overview {
    constructor(reviewAllPendingCount, escalationAllPendingCount, pendingPercentageForAllReviewQueues, pendingPercentageForAllEscalationQueues, reviewAllNonCompliantCount, escalationAllNonCompliantCount, nonCompliantPercentageForAllReviewQueues, nonCompliantPercentageForAllEscalationQueues) {
        this.reviewAllPendingCount = reviewAllPendingCount;
        this.escalationAllPendingCount = escalationAllPendingCount;
        this.pendingPercentageForAllReviewQueues = pendingPercentageForAllReviewQueues;
        this.pendingPercentageForAllEscalationQueues = pendingPercentageForAllEscalationQueues;
        this.reviewAllNonCompliantCount = reviewAllNonCompliantCount;
        this.escalationAllNonCompliantCount = escalationAllNonCompliantCount;
        this.nonCompliantPercentageForAllReviewQueues = nonCompliantPercentageForAllReviewQueues;
        this.nonCompliantPercentageForAllEscalationQueues = nonCompliantPercentageForAllEscalationQueues;
    }
}
exports.Overview = Overview;
