"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgEndpointsPageComponent = void 0;
const endpointsInstallActions = require("../actions/install.actions");
const endpointsSettingsActions = require("../actions/settings.actions");
const reducers = require("../reducers");
const audit_actions_1 = require("app-new/core/actions/audit.actions");
const endpoints_main_static_values_1 = require("app-new/swg/endpoints/containers/endpoints-main.static-values");
class SwgEndpointsPageComponent {
    constructor(store, capabilitiesService, swgService) {
        this.store = store;
        this.capabilitiesService = capabilitiesService;
        this.swgService = swgService;
    }
    ngOnInit() {
        this.getAuthKey(false);
        this.getTamperProtectedPassword(false);
        this.getUninstallPassword(false);
        this.store.dispatch(new endpointsSettingsActions.SwgEndpointsSettingsGetSettingsAction());
        // Install Auth Key
        this.authKey$ = this.store.select(reducers.getEndpointsAuth);
        this.isLoading$ = this.store.select(reducers.isEndpointsAuthKeyLoading);
        this.isDeleting$ = this.store.select(reducers.isEndpointsAuthKeyDeleting);
        this.isLoadingError$ = this.store.select(reducers.getEndpointsAuthKeyLoadingError);
        this.isDownloadBinaryDisabled$ = this.store.select(reducers.isDownloadBinaryDisabled);
        // Settings
        this.settings$ = this.store.select(reducers.getEndpointSettings);
        this.updateSettingsError$ = this.store.select(reducers.getEndpointSettingsUpdateError);
        this.isEndpointSettingsUpdating$ = this.store.select(reducers.isEndpointSettingsUpdating);
        this.tamperProtectedPassword$ = this.store.select(reducers.getTamperProtectedPassword);
        this.uninstallPassword$ = this.store.select(reducers.getUninstallPassword);
        this.isPasswordLoading$ = this.store.select(reducers.isPasswordLoading);
        this.isEditable$ = this.capabilitiesService.hasCapability('Permission.SWG_ENDPOINT_SETTINGS_EDIT');
        this.hasUninstallPasswordEnabled = this.swgService.hasUninstallPasswordEnabled();
        this.userRole = this.swgService.getUserProfile().role;
        this.hasPurgePermission$ = this.capabilitiesService.hasCapability('Permission.SWG_ENDPOINT_SETTINGS_PURGE/DELETE');
        this.hasAutoEnrolmentSwitchEnabled = this.swgService.hasDeviceAutoEnrollmentSwitchEnabled();
        this.hasDirectIpProtectionSwitchEnabled = this.swgService.hasDirectIpProtectionSwitchEnabled();
        this.hasAllowInternalIpsSwitchEnabled = this.swgService.hasAllowInternalIpsSwitchEnabled();
        this.hasDirectIpProtectionModeSwitchEnabled = this.swgService.hasDirectIpProtectionModeSwitchEnabled();
        this.hasTUIDAttributeSwitchEnabled = this.swgService.hasTuidAttributeSwitchEnabled();
        this.hasSilentUpdateSwitchEnabled = this.swgService.hasSilentUpdateSwitchEnabled();
    }
    getAuthKey(isCreateAuthKey) {
        this.store.dispatch(new endpointsInstallActions.SwgEndpointsInstallGetAuthKeyAction(isCreateAuthKey));
    }
    downloadAuthKey() {
        this.store.dispatch(new endpointsInstallActions.SwgEndpointsInstallAuthKeyDownloadAction());
    }
    downloadBinary(platformCode) {
        this.store.dispatch(new endpointsInstallActions.SwgEndpointsInstallBinaryDownloadAction(platformCode));
    }
    openDeleteKeyModal() {
        this.store.dispatch(new endpointsInstallActions.SwgEndpointsInstallOpenDeleteAuthKeyModalAction());
    }
    copyAgentAuthKey() {
        this.store.dispatch(new audit_actions_1.AuditEventAction({
            auditType: 'msa_adjustment',
            eventInfo: endpoints_main_static_values_1.AGENT_AUTHENTICATION_KEY_COPIED
        }));
    }
    copyTamperProtectedPassword() {
        this.store.dispatch(new audit_actions_1.AuditEventAction({
            auditType: 'msa_adjustment',
            eventInfo: endpoints_main_static_values_1.TAMPER_PROTECTION_PASSWORD_COPIED
        }));
    }
    copyUninstallPassword() {
        this.store.dispatch(new audit_actions_1.AuditEventAction({
            auditType: 'msa_adjustment',
            eventInfo: endpoints_main_static_values_1.UNINSTALL_PASSWORD_COPIED
        }));
    }
    viewTamperProtectedPassword() {
        this.store.dispatch(new audit_actions_1.AuditEventAction({
            auditType: 'msa_adjustment',
            eventInfo: endpoints_main_static_values_1.TAMPER_PROTECTION_PASSWORD_VIEWED
        }));
    }
    viewUninstallPassword() {
        this.store.dispatch(new audit_actions_1.AuditEventAction({
            auditType: 'msa_adjustment',
            eventInfo: endpoints_main_static_values_1.UNINSTALL_PASSWORD_VIEWED
        }));
    }
    settingsChanged(settings) {
        this.store.dispatch(new endpointsSettingsActions.SwgEndpointsSettingsUpdateSettingsAction(settings));
    }
    getTamperProtectedPassword(isGeneratePassword) {
        this.store.dispatch(new endpointsSettingsActions.SwgEndpointsSettingsGetTamperProtectedPasswordAction(isGeneratePassword));
    }
    getUninstallPassword(isGeneratePassword) {
        if (isGeneratePassword) {
            this.store.dispatch(new endpointsSettingsActions.SwgEndpointsSettingsOpenUninstallGeneratePasswordModalAction());
        }
        else {
            this.store.dispatch(new endpointsSettingsActions.SwgEndpointsSettingsGetUninstallPasswordAction(false));
        }
    }
    openEnableUninstallPasswordConfirmModal(settings) {
        this.store.dispatch(new endpointsSettingsActions.SwgEndpointsSettingsOpenEnableUninstallPasswordConfirmModalAction(settings));
    }
    openDirectIPConfirmModal(settings) {
        this.store.dispatch(new endpointsSettingsActions.SwgEndpointsSettingsOpenDirectIpModalAction(settings));
    }
    openAllowLocalIpsConfirmModal(settings) {
        this.store.dispatch(new endpointsSettingsActions.SwgEndpointsSettingsOpenAllowLocalIPsModalAction(settings));
    }
    openTUIDAttributeConfirmModal(settings) {
        this.store.dispatch(new endpointsSettingsActions.SwgEndpointsSettingsOpenTUIDAttributeModalAction(settings));
    }
}
exports.SwgEndpointsPageComponent = SwgEndpointsPageComponent;
