"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HeldMessagesReleaseLogsTabComponent = void 0;
const actions = require("../../actions");
const reducers = require("../../reducers");
class HeldMessagesReleaseLogsTabComponent {
    constructor(store) {
        this.store = store;
    }
    ngOnInit() {
        this.items$ = this.store.select(reducers.getReleaseLogsItems);
        this.totalCount$ = this.store.select(reducers.getReleaseLogsTotalCount);
        this.selectedRow$ = this.store.select(reducers.getReleaseLogsSelectedRow);
        this.isLoading$ = this.store.select(reducers.isReleaseLogsLoading);
        this.metadata$ = this.store.select(reducers.getReleaseLogsMetadata);
        this.store.dispatch(new actions.ReleaseLogRequestAction({ search: {} }));
    }
    search(search) {
        this.store.dispatch(new actions.ReleaseLogRequestAction({ search }));
    }
    dateFilterChanged(range) {
        this.store.dispatch(new actions.ReleaseLogRequestAction({ search: Object.assign({}, range.range) }));
    }
    columnFilterChanged(filter) {
        this.store.dispatch(new actions.ReleaseLogRequestAction({ search: filter }));
    }
    paginatorChanged(page) {
        this.store.dispatch(new actions.ReleaseLogPaginationAction(page));
    }
    rowClicked(message) {
        this.store.dispatch(new actions.ReleaseLogSelectRowAction(message));
    }
    resultsExported(tableColumns) {
        this.store.dispatch(new actions.ReleaseLogExportAction(tableColumns));
    }
}
exports.HeldMessagesReleaseLogsTabComponent = HeldMessagesReleaseLogsTabComponent;
