"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RemediationGetLogsFailureAction = exports.RemediationGetLogsSuccessAction = exports.RemediationGetLogsAction = exports.GET_LOGS_FAILURE = exports.GET_LOGS_SUCCESS = exports.GET_LOGS = void 0;
exports.GET_LOGS = '[Remediation] Retrieve logs';
exports.GET_LOGS_SUCCESS = '[Remediation] Retrieve logs success';
exports.GET_LOGS_FAILURE = '[Remediation] Retrieve logs failure';
class RemediationGetLogsAction {
    constructor() {
        this.type = exports.GET_LOGS;
    }
}
exports.RemediationGetLogsAction = RemediationGetLogsAction;
class RemediationGetLogsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_LOGS_SUCCESS;
    }
}
exports.RemediationGetLogsSuccessAction = RemediationGetLogsSuccessAction;
class RemediationGetLogsFailureAction {
    constructor() {
        this.type = exports.GET_LOGS_FAILURE;
    }
}
exports.RemediationGetLogsFailureAction = RemediationGetLogsFailureAction;
