"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const static_1 = require("@angular/upgrade/static");
const list_component_1 = require("./list/list.component");
const details_component_1 = require("./detail/details.component");
angular
    .module('sync-recover.exchange.tasks', [])
    .controller('ExchangeTaskListController', function () { })
    .directive('mcSyncRecoverExchangeTaskList', static_1.downgradeComponent({
    component: list_component_1.ListComponent
}))
    .controller('ExchangeTaskDetailsController', function () { })
    .directive('mcSyncRecoverExchangeTaskDetails', static_1.downgradeComponent({
    component: details_component_1.DetailsComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider
            .state('sync-recover-exchange-task-list', {
            url: '/sync-recover/exchange/tasks',
            data: {
                capabilities: 'Development.Only',
                isFixedLayout: false,
                checkProgress: false,
                tabTitle: 'Exchange Task list',
                breadcrumbs: ['Exchange Task List']
            },
            views: {
                main: {
                    template: '<mc-sync-recover-exchange-task-list></mc-sync-recover-exchange-task-list>',
                    controller: 'ExchangeTaskListController'
                }
            }
        })
            .state('sync-recover-exchange-task-details', {
            url: '/sync-recover/exchange/task/details/:taskId',
            data: {
                capabilities: 'Development.Only',
                isFixedLayout: false,
                checkProgress: false,
                tabTitle: 'Exchange Task list',
                breadcrumbs: ['Exchange Task List']
            },
            views: {
                main: {
                    template: '<mc-sync-recover-exchange-task-details></mc-sync-recover-exchange-task-details>',
                    controller: 'ExchangeTaskDetailsController'
                }
            }
        });
    }
]);
