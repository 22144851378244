"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.monitorDomainIsProcessing = exports.blockSenderIsProcessing = exports.permitSenderIsProcessing = exports.logDetailsIsLoading = exports.logDetailsIsVisible = exports.getLogDetails = exports.reducer = exports.initialState = void 0;
const logDetailsActions = require("../../actions/log-details.actions");
exports.initialState = {
    logDetails: {},
    isVisible: false,
    isLoading: false,
    permitSenderIsProcessing: false,
    blockSenderIsProcessing: false,
    monitorDomainIsProcessing: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case logDetailsActions.GET_LOG_DETAILS:
            return Object.assign(Object.assign({}, state), { isVisible: true, isLoading: true });
        case logDetailsActions.GET_LOG_DETAILS_SUCCESS:
            return Object.assign(Object.assign({}, state), { logDetails: action.logDetails, isLoading: false });
        case logDetailsActions.GET_LOG_DETAILS_FAIL:
            return Object.assign(Object.assign({}, state), { isLoading: false });
        case logDetailsActions.HIDE_LOG_DETAILS:
            return Object.assign(Object.assign({}, state), { isVisible: false });
        case logDetailsActions.PERMIT_SENDER:
            return Object.assign(Object.assign({}, state), { permitSenderIsProcessing: true });
        case logDetailsActions.PERMIT_SENDER_SUCCESS:
            return Object.assign(Object.assign({}, state), { permitSenderIsProcessing: false });
        case logDetailsActions.PERMIT_SENDER_FAIL:
            return Object.assign(Object.assign({}, state), { permitSenderIsProcessing: false });
        case logDetailsActions.BLOCK_SENDER:
            return Object.assign(Object.assign({}, state), { blockSenderIsProcessing: true });
        case logDetailsActions.BLOCK_SENDER_SUCCESS:
            return Object.assign(Object.assign({}, state), { blockSenderIsProcessing: false });
        case logDetailsActions.BLOCK_SENDER_FAIL:
            return Object.assign(Object.assign({}, state), { blockSenderIsProcessing: false });
        case logDetailsActions.MONITOR_DOMAIN:
            return Object.assign(Object.assign({}, state), { monitorDomainIsProcessing: true });
        case logDetailsActions.MONITOR_DOMAIN_SUCCESS:
            return Object.assign(Object.assign({}, state), { monitorDomainIsProcessing: false });
        case logDetailsActions.MONITOR_DOMAIN_FAIL:
            return Object.assign(Object.assign({}, state), { monitorDomainIsProcessing: false });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getLogDetails = (state) => state.logDetails;
exports.getLogDetails = getLogDetails;
const logDetailsIsVisible = (state) => state.isVisible;
exports.logDetailsIsVisible = logDetailsIsVisible;
const logDetailsIsLoading = (state) => state.isLoading;
exports.logDetailsIsLoading = logDetailsIsLoading;
const permitSenderIsProcessing = (state) => state.permitSenderIsProcessing;
exports.permitSenderIsProcessing = permitSenderIsProcessing;
const blockSenderIsProcessing = (state) => state.blockSenderIsProcessing;
exports.blockSenderIsProcessing = blockSenderIsProcessing;
const monitorDomainIsProcessing = (state) => state.monitorDomainIsProcessing;
exports.monitorDomainIsProcessing = monitorDomainIsProcessing;
