"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./mailboxes-list.controller");
require("./mailboxes-list.less");
require("./mailboxes-list.tpl.html");
(function () {
    'use strict';
    angular
        .module('services.services.backup.mailboxes-list', [
        'services.services.backup.mailboxes-list.controller'
    ])
        .config([
        '$stateProvider',
        function ($stateProvider) {
            const breadcrumbs = [
                '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_SERVICES',
                '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ASIDE_MENU_TITLE',
                '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ARCHIVE_MAILBOXES_MENU_LABEL'
            ];
            $stateProvider.state('mailboxes-list', {
                url: '/sync-recover/exchange/mailboxes',
                data: {
                    breadcrumbs,
                    tabId: 'sync-and-recover-tab',
                    asideTitle: '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ASIDE_MENU_TITLE',
                    tabTitle: '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ASIDE_MENU_TITLE',
                    tabHide: false,
                    tabReload: false
                },
                views: {
                    main: {
                        templateUrl: 'services/services/backup/mailboxes/mailboxes-list/mailboxes-list.tpl.html'
                    },
                    aside: {
                        templateUrl: 'services/services/backup/aside/aside.tpl.html'
                    }
                },
                onEnter: [
                    'windowService',
                    function (windowService) {
                        if (windowService.inIframe()) {
                            breadcrumbs.length = 0;
                            breadcrumbs.push('$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_SERVICES', '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ASIDE_MENU_TITLE');
                        }
                    }
                ]
            });
        }
    ]);
})();
