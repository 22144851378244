"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CyberGraphSidePanelViewDetailsComponent = void 0;
const cybergraph_policies_model_1 = require("../../models/cybergraph-policies.model");
const statics = require("../steps/cybergraph-policy-summary-step/cybergraph-policy-summary-step.component.static-values");
const cybergraph_side_panel_view_details_component_helper_1 = require("./cybergraph-side-panel-view-details.component.helper");
const cybergraph_policy_summary_step_component_helper_1 = require("../steps/cybergraph-policy-summary-step/cybergraph-policy-summary-step.component.helper");
const cybergraph_policies_list_table_static_values_1 = require("../cybergraph-policies-list-table/cybergraph-policies-list-table.static-values");
class CyberGraphSidePanelViewDetailsComponent {
    constructor(translateService, userInfoApiService, featureFlagService, cdr) {
        this.translateService = translateService;
        this.userInfoApiService = userInfoApiService;
        this.featureFlagService = featureFlagService;
        this.cdr = cdr;
        this.showMoreLessTriggerValue = statics.SHOW_MORE_LESS_TRIGGER_VALUE;
        this.trackerEnabled = false;
        this.helper = new cybergraph_policy_summary_step_component_helper_1.CyberGraphPolicySummaryStepComponentHelper(this.translateService);
        this.sidePanelHelper = new cybergraph_side_panel_view_details_component_helper_1.CyberGraphPolicySidePanelComponentHelper(this.translateService);
        this.featureFlagService.isReady().then(() => {
            this.userInfoApiService.getAccount().subscribe(account => {
                const trackingFlagEnabled = this.featureFlagService.getBooleanFlag('rollout-cyg-21-image-tracker-ui', false);
                this.trackerEnabled = !account.cybergraphV2Enabled || trackingFlagEnabled;
                this.cdr.detectChanges();
            });
        });
    }
    getEnabledDisabledLabel(value) {
        return this.translate(value
            ? statics.CyberGraphReportingEnumLabel.ENABLED
            : statics.CyberGraphReportingEnumLabel.DISABLED);
    }
    getCyberGraphReportingStatusEnumLabel(value) {
        return this.translate(value === cybergraph_policies_model_1.CyberGraphReportingStatusEnum.ON
            ? statics.CyberGraphReportingEnumLabel.ENABLED
            : statics.CyberGraphReportingEnumLabel.DISABLED);
    }
    getCyberGraphTrackersEnumLabel(value) {
        return this.translate(value === cybergraph_policies_model_1.CyberGraphTrackersEnum.ON
            ? statics.CyberGraphTrackerEnumLabel.ENABLED
            : statics.CyberGraphTrackerEnumLabel.DISABLED);
    }
    getCyberGraphDynamicBannerEnumLabel(value) {
        if (value === cybergraph_policies_model_1.CyberGraphDynamicBannersEnum.ON) {
            return this.translate(statics.CyberGraphDynamicBannerEnumLabel.ENABLED);
        }
        else if (value === cybergraph_policies_model_1.CyberGraphDynamicBannersEnum.LEARNING) {
            return this.translate(statics.CyberGraphDynamicBannerEnumLabel.LEARNING);
        }
        return this.translate(statics.CyberGraphDynamicBannerEnumLabel.DISABLED);
    }
    getCyberGraphDynamicBannerDesc(value) {
        if (value === cybergraph_policies_model_1.CyberGraphDynamicBannersEnum.ON) {
            return this.translate(statics.CyberGraphDynamicBannerDesc.ENABLED);
        }
        else if (value === cybergraph_policies_model_1.CyberGraphDynamicBannersEnum.LEARNING) {
            return this.translate(statics.CyberGraphDynamicBannerDesc.LEARNING);
        }
        return this.translate(statics.CyberGraphDynamicBannerDesc.DISABLED);
    }
    getCyberGraphTrackersDesc(value) {
        return this.translate(value === cybergraph_policies_model_1.CyberGraphTrackersEnum.ON
            ? statics.CyberGraphTrackerDesc.ENABLED
            : statics.CyberGraphTrackerDesc.DISABLED);
    }
    displayStatusIcon(status) {
        if (status === true) {
            return `${cybergraph_policies_list_table_static_values_1.icons.circleCheck} ${cybergraph_policies_list_table_static_values_1.icons.succesColor}`;
        }
        else {
            return `${cybergraph_policies_list_table_static_values_1.icons.circleCross} ${cybergraph_policies_list_table_static_values_1.icons.mutedColor}`;
        }
    }
    translate(value) {
        return this.translateService.instant(value);
    }
}
exports.CyberGraphSidePanelViewDetailsComponent = CyberGraphSidePanelViewDetailsComponent;
