"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BiPolicyEffects = void 0;
const effects_1 = require("@ngrx/effects");
const swgPolicyreducers = require("app-new/swg/policies/reducers");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const notification_actions_1 = require("../../../../../components/notification/actions/notification.actions");
const tableStore = require("@mimecast-ui/table-store");
const actions = require("../../actions/policy.actions");
const policy_actions_1 = require("../../actions/policy.actions");
const sidebar = require("../../actions/sidebar.actions");
const models_1 = require("../../../../../swg/policies/models");
const policy_actions_2 = require("../../../../../swg/policies/actions/policy.actions");
const sidebar_actions_1 = require("../../../../../swg/policies/actions/sidebar.actions");
class BiPolicyEffects {
    constructor(actions$, store, stateService, biPolicyApiService, translationService) {
        this.actions$ = actions$;
        this.store = store;
        this.stateService = stateService;
        this.biPolicyApiService = biPolicyApiService;
        this.translationService = translationService;
        this.deletePolicy$ = this.actions$.pipe(effects_1.ofType(policy_actions_1.BiPolicyActionsEnums.BI_POLICY_DELETE), operators_1.map((action) => action.payload), operators_1.switchMap((data) => {
            return this.biPolicyApiService.deleteBIPolicy(data.id).pipe(operators_1.mergeMap(() => {
                return [
                    new notification_actions_1.NotificationShowAction({
                        type: 'success',
                        config: {
                            msg: this.translationService.instant('$I18N_SWG_CREATE_POLICY_POLICY_DELETED')
                        }
                    }),
                    new actions.BiDeletePolicySuccess(),
                    new sidebar.BiDetailDeleteConfirmModelClose(),
                    new policy_actions_2.SwgDeletePolicySuccess(),
                    new sidebar_actions_1.SwgDetailDeleteConfirmModelClose(),
                    new tableStore.LoadAction({ tableId: 'BiPolicyList' })
                ];
            }), operators_1.catchError(error => rxjs_1.merge(rxjs_1.of(this.getNotificationAction(error)), rxjs_1.of(new actions.BiDeletePolicyFailure()), rxjs_1.of(new sidebar.BiDetailDeleteConfirmModelClose()))));
        }));
        this.createBiPolicy$ = this.actions$.pipe(effects_1.ofType(policy_actions_1.BiPolicyActionsEnums.BI_POLICY_SAVE), operators_1.withLatestFrom(this.store.select(swgPolicyreducers.getSwgPolicy), this.store.select(swgPolicyreducers.getWizardFlowType)), operators_1.switchMap(([, swgPolicy, flowType]) => {
            return this.biPolicyApiService.createPolicy(swgPolicy).pipe(operators_1.mergeMap(() => {
                let message = '';
                if (flowType === models_1.SwgPolicyWizardFlowType.DUPLIATE) {
                    message = '$I18N_SWG_CREATE_POLICY_POLICY_DUPLICATED';
                }
                else if (flowType === models_1.SwgPolicyWizardFlowType.CREATE) {
                    message = '$I18N_SWG_CREATE_POLICY_POLICY_CREATED';
                }
                else if (flowType === models_1.SwgPolicyWizardFlowType.EDIT) {
                    message = '$I18N_SWG_CREATE_POLICY_POLICY_EDITED';
                }
                this.stateService.$state.go('bi-policy-list');
                return [
                    new notification_actions_1.NotificationShowAction({
                        type: 'success',
                        config: {
                            msg: message
                        }
                    }),
                    new policy_actions_1.BiSavePolicySuccess()
                ];
            }), operators_1.catchError(error => rxjs_1.merge(rxjs_1.of(this.getNotificationAction(error)), rxjs_1.of(new policy_actions_2.SwgCreatePolicyFailAction()))));
        }));
    }
    getNotificationAction(error) {
        const msg = error.fail
            ? error.fail[0].errors[0].message
            : this.translationService.instant('$I18N_SWG_CREATE_POLICY_SERVER_ERROR');
        return new notification_actions_1.NotificationShowAction({
            type: 'error',
            config: {
                msg
            }
        });
    }
}
__decorate([
    effects_1.Effect()
], BiPolicyEffects.prototype, "deletePolicy$", void 0);
__decorate([
    effects_1.Effect()
], BiPolicyEffects.prototype, "createBiPolicy$", void 0);
exports.BiPolicyEffects = BiPolicyEffects;
