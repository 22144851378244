"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomDomainBlock = void 0;
class CustomDomainBlock {
    constructor(definitionId, policyId, name, description, action, notify, configurationId, lastUpdated, enabled) {
        this.definitionId = definitionId;
        this.policyId = policyId;
        this.name = name;
        this.description = description;
        this.action = action;
        this.notify = notify;
        this.configurationId = configurationId;
        this.lastUpdated = lastUpdated;
        this.enabled = enabled;
    }
}
exports.CustomDomainBlock = CustomDomainBlock;
