<div *ngIf="settings.enableUninstallPassword">
  <mc-modal-header
    headerTitle="{{ '$I18N_ENDPOINT_SETTINGS.MODAL.SETTINGS.ENABLE_UNINSTALL.HEADER' | translate }}">
  </mc-modal-header>
  <mc-modal-body>
    <p *mcCapabilities="'!Temporary.Permission.swg.latest.mac.msa'">
      {{ '$I18N_ENDPOINT_SETTINGS.MODAL.SETTINGS.ENABLE_UNINSTALL.BODY_CONTENT' | translate }}
    </p>
    <p *mcCapabilities="'Temporary.Permission.swg.latest.mac.msa'">
      {{ '$I18N_ENDPOINT_SETTINGS.MODAL.SETTINGS.ENABLE_UNINSTALL.BODY_CONTENT_MAC_WIN' | translate }}
    </p>
  </mc-modal-body>
</div>
<div *ngIf="!settings.enableUninstallPassword">
  <mc-modal-header
    headerTitle="{{ '$I18N_ENDPOINT_SETTINGS.MODAL.SETTINGS.DISABLE_UNINSTALL.HEADER' | translate }}">
  </mc-modal-header>
  <mc-modal-body>
    <p *mcCapabilities="'!Temporary.Permission.swg.latest.mac.msa'">
      {{ '$I18N_ENDPOINT_SETTINGS.MODAL.SETTINGS.DISABLE_UNINSTALL.BODY_CONTENT' | translate }}
    </p>
    <p *mcCapabilities="'Temporary.Permission.swg.latest.mac.msa'">
      {{ '$I18N_ENDPOINT_SETTINGS.MODAL.SETTINGS.DISABLE_UNINSTALL.BODY_CONTENT_MAC_WIN' | translate }}
    </p>
  </mc-modal-body>
</div>
<mc-modal-footer>
  <button type="button" class="btn btn-secondary" (click)="onClose()">
    {{ '$I18N_ENDPOINT_SETTINGS.MODAL.SETTINGS.BTN_CANCEL' | translate }}
  </button>
  <mc-live-button
    overrideClasses="btn-primary"
    [label]="( settings.enableUninstallPassword ?
      '$I18N_ENDPOINT_SETTINGS.MODAL.SETTINGS.ENABLE_UNINSTALL.BTN_OK' : '$I18N_ENDPOINT_SETTINGS.MODAL.SETTINGS.DISABLE_UNINSTALL.BTN_OK') | translate"
    (mcClick)="onUpdateSetting()">
  </mc-live-button>
</mc-modal-footer>
