"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Item = void 0;
const node_1 = require("./node");
class Item extends node_1.Node {
    constructor(modified, size, id, name, lastChangeId, lastChangeType, parentId, deleted) {
        super(id, name, lastChangeId, lastChangeType, modified, parentId, deleted);
        this.size = size;
    }
    getSize() {
        return this.size;
    }
    download() {
        throw new Error('Method not implemented.');
    }
}
exports.Item = Item;
