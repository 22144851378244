
<mc-table
  tableId="swg/devices-list-table"
  class="mc-swg-endpoints-list"
  [isLoading]="isTableInManualMode? isDeviceListTableLoading: isLoading$ | async"
  [data]="tableDataCopy"
  [columns]="getColumns()"
  translatePrefix="$I18N_DEVICES_LIST.TABLE"
  [showHighlightedRow]="false"
>

  <button class="mc-table-actions btn btn-primary panel-half-margin-right"
          (click)="addDevices()"> {{ '$I18N_DEVICES_LIST.TABLE.ACTIONS.ADD' | translate }}
  </button>

  <button *ngIf="displayType !== deviceListDisplayType.TARGET"
          class="mc-table-actions btn btn-secondary panel-half-margin-right"
          (click)="clearAddedDevices()"> {{ '$I18N_DEVICES_LIST.TABLE.ACTIONS.REMOVE' | translate }}
  </button>



  <mc-filters
    #mcFiltersComponent
    (paginatorChange)="onPaginationChange($event)"
    [metadata]="isTableInManualMode ? devicesListManualPagingData : metaData$ | async"
    [showPagination]="true"
    [defaultPageSize]="defaultSizeOfPage"
  >
    <mc-filter-search
      #filterSearchComponent
      (search)="onSearch($event)"
      (clear)="onSearchClear()"
      [placeholder]="'$I18N_DEVICES_LIST.TABLE.SEARCH.PLACEHOLDER' | translate"
    >
    </mc-filter-search>

    <div
         class="mc-table-actions panel-margin-left panel-half-margin-top mc-device-count">
      <span *ngIf="displayType !== deviceListDisplayType.TARGET">{{ '$I18N_DEVICES_LIST.TABLE.ACTIONS.DEVICE_COUNT' | translate }}</span>
      <span *ngIf="displayType !== deviceListDisplayType.TARGET" class="text-bold mc-status-green">
        {{deviceCount}}
      </span>
    </div>

    <mc-filters-bundle-column-values-modal
      [sections]="tableFilterValues"
      (filtersChange)="onBundleFilterChange($event)"
    >
    </mc-filters-bundle-column-values-modal>

  </mc-filters>

  <mc-column key="check-row">
    <mc-header-cell *mcHeaderCellDef="let row">
      <label for="headerRowCheckbox">
        <input
          id="headerRowCheckbox"
          type="checkbox"
          (click)="onSelectAll($event)"
          [checked]="isSelectedAll"
          [disabled]="tableDataCopy && tableDataCopy.length === 0"
        />
      </label>

    </mc-header-cell>
    <mc-body-cell *mcBodyCellDef="let row">
      <label for="rowCheckbox_{{row.id}}">
        <input
          id="rowCheckbox_{{row.id}}"
          type="checkbox"
          (click)="onSelectingSingleItem({ event: $event, device: row })"
          [checked]="row.selected"
        />
      </label>

    </mc-body-cell>
  </mc-column>

  <mc-column key="actions">
    <mc-body-cell *mcBodyCellDef="let row">
        <span class="text-bold mc-status-green">
          {{ row.action }}
        </span>
    </mc-body-cell>
  </mc-column>

  <mc-column key="os">
    <mc-body-cell *mcBodyCellDef="let row">
        <span>
          {{ row.os }}
        </span>
    </mc-body-cell>
  </mc-column>

  <mc-column key="status">
    <mc-body-cell *mcBodyCellDef="let row">
        <span >
          {{ row.status | mcSwgEndpointsListMapStatusText | translate }}
        </span>
    </mc-body-cell>
  </mc-column>

  <mc-column-actions
    key="action"
    [columnsToStartHidden]="columnsToStartHidden"
    [columnsAlwaysVisible]="getAlwaysVisibleColumns()"
    [columnsToIgnore]="columnsToIgnore"
    mcShowColumnConfig
  >

  </mc-column-actions>

  <mc-empty-results keyTitle="$I18N_DEVICES_LIST.TABLE.NO_DATA">
    <mc-empty-body>

    </mc-empty-body>
  </mc-empty-results>
</mc-table>

