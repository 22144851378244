"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BiSidebarEffects = void 0;
const effects_1 = require("@ngrx/effects");
const operators_1 = require("rxjs/operators");
const sidebar = require("../../actions/sidebar.actions");
const sidebar_actions_1 = require("../../actions/sidebar.actions");
const bi_policy_sidebar_container_component_1 = require("../../containers/sidebar/bi-policy-sidebar-container.component");
const rxjs_1 = require("rxjs");
const bi_policy_delete_confirmation_component_1 = require("../../components/sidebar/delete/bi-policy-delete-confirmation.component");
class BiSidebarEffects {
    constructor(actions$, modalService, asideService) {
        this.actions$ = actions$;
        this.modalService = modalService;
        this.asideService = asideService;
        this.openSidePanel$ = this.actions$.pipe(effects_1.ofType(sidebar_actions_1.BiPolicyListSidebarActionsEnum.BI_POLICY_LIST_SIDEBAR_OPEN), operators_1.map((action) => action.payload), operators_1.tap(() => {
            // @ts-ignore
            this.sidepanelInstance = this.asideService.open(bi_policy_sidebar_container_component_1.BiPolicySideBarContainerComponent, {
                position: 'right',
                size: 'md',
                hasBackdrop: true
            });
        }));
        this.closeSidePanel$ = this.actions$.pipe(effects_1.ofType(sidebar_actions_1.BiPolicyListSidebarActionsEnum.BI_POLICY_LIST_SIDEBAR_CLOSE), operators_1.filter(() => !!this.sidepanelInstance), operators_1.tap(() => {
            this.sidepanelInstance.close();
            this.sidepanelInstance = undefined;
        }));
        this.openBiPolicyDeleteAlert = this.actions$.pipe(effects_1.ofType(sidebar_actions_1.BiPolicyListSidebarActionsEnum.BI_POLICY_DELETE_CONFIRM_MODEL_OPEN), operators_1.map((action) => action.payload), operators_1.switchMap(() => {
            this.deleteAlertModalInstance = this.modalService.open(bi_policy_delete_confirmation_component_1.BiPolicyDeleteConfirmationComponent, {
                size: 'md',
                hasBackdrop: true
            });
            return rxjs_1.of(new sidebar.BiPolicyListSidebarClose());
        }));
        this.closeBiPolicyDeleteAlert = this.actions$.pipe(effects_1.ofType(sidebar_actions_1.BiPolicyListSidebarActionsEnum.BI_POLICY_DELETE_CONFIRM_MODEL_CLOSE), operators_1.filter(() => !!this.deleteAlertModalInstance), operators_1.tap(() => {
            this.deleteAlertModalInstance.close();
            this.deleteAlertModalInstance = undefined;
        }));
    }
}
__decorate([
    effects_1.Effect({ dispatch: false })
], BiSidebarEffects.prototype, "openSidePanel$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], BiSidebarEffects.prototype, "closeSidePanel$", void 0);
__decorate([
    effects_1.Effect()
], BiSidebarEffects.prototype, "openBiPolicyDeleteAlert", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], BiSidebarEffects.prototype, "closeBiPolicyDeleteAlert", void 0);
exports.BiSidebarEffects = BiSidebarEffects;
