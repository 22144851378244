'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const _ = require("lodash");
angular.module('services.services.backup.archive-task-detail.controller', [])
    .controller('ArchiveTaskDetailController', ['$scope', '$state', '$translate', '$aside', 'backupService', 'adconCommonsService', 'adconBackupService', '$stateParams', function ($scope, $state, $translate, $aside, backupService, adconCommonsService, adconBackupService, $stateParams) {
        const self = this;
        let paginationActualPage = 1;
        const paginationLabelDict = {
            start: paginationActualPage,
            end: paginationActualPage
        };
        self.taskStatusOptions = adconBackupService.CONSTANTS.TASK_STATUS_OPTIONS;
        self.serverType = backupService.constants.SERVER_TYPE;
        self.paginationLabel = '';
        self.paginationNextPageResults = false;
        self.paginationPreviousPageResults = false;
        self.pageSize = {};
        self.mailboxesList = [];
        self.pageSizeOptions = angular.copy(adconCommonsService.getDropdownOptions().pageSizeOptions);
        self.filtersOptions = {};
        self.hasPermission = adconBackupService.CONSTANTS.HAS_PERMISSIONS;
        self.filtersOptions[adconCommonsService.CONSTANTS.ALL] = {
            type: adconCommonsService.CONSTANTS.DROPDOWN_ITEM_TYPES.ITEM,
            key: '',
            value: $translate.instant('$I18N_ADMINISTRATION_COMMONS_FILTER_OPTION_ALL'),
            active: true,
            order: 0
        };
        self.filtersOptions[backupService.constants.LAST_STATUS.ERROR] = {
            type: adconCommonsService.CONSTANTS.DROPDOWN_ITEM_TYPES.ITEM,
            key: backupService.constants.LAST_STATUS.ERROR,
            value: self.taskStatusOptions[backupService.constants.LAST_STATUS.ERROR].text,
            order: 1
        };
        self.filtersOptions[backupService.constants.LAST_STATUS.IN_PROGRESS] = {
            type: adconCommonsService.CONSTANTS.DROPDOWN_ITEM_TYPES.ITEM,
            key: backupService.constants.LAST_STATUS.IN_PROGRESS,
            value: self.taskStatusOptions[backupService.constants.LAST_STATUS.IN_PROGRESS].text,
            order: 2
        };
        self.filtersOptions[backupService.constants.LAST_STATUS.SUCCESS] = {
            type: adconCommonsService.CONSTANTS.DROPDOWN_ITEM_TYPES.ITEM,
            key: backupService.constants.LAST_STATUS.SUCCESS,
            value: self.taskStatusOptions[backupService.constants.LAST_STATUS.SUCCESS].text,
            order: 3
        };
        self.filtersOptions[backupService.constants.LAST_STATUS.PENDING] = {
            type: adconCommonsService.CONSTANTS.DROPDOWN_ITEM_TYPES.ITEM,
            key: backupService.constants.LAST_STATUS.PENDING,
            value: self.taskStatusOptions[backupService.constants.LAST_STATUS.PENDING].text,
            order: 4
        };
        self.filtersOptions[backupService.constants.LAST_STATUS.WARNING] = {
            type: adconCommonsService.CONSTANTS.DROPDOWN_ITEM_TYPES.ITEM,
            key: backupService.constants.LAST_STATUS.WARNING,
            value: self.taskStatusOptions[backupService.constants.LAST_STATUS.WARNING].text,
            order: 5
        };
        self.getMailboxesList = getMailboxesList;
        self.gotoMailboxDetails = gotoMailboxDetails;
        self.pageSizeOptionsUpdate = pageSizeOptionsUpdate;
        self.pagefilterOptionsUpdate = pagefilterOptionsUpdate;
        self.paginationNextOnChange = paginationNextOnChange;
        self.paginationPreviousOnChange = paginationPreviousOnChange;
        self.fetchArchiveTask = fetchArchiveTask;
        self.getStatusStyleAndMessage = getStatusStyleAndMessage;
        activate();
        function activate() {
            if (!$stateParams.taskId) {
                $state.go('archive-task-list');
            }
            self.isDataLoading = true;
            self.fetchArchiveTask().then(function () {
                self.payload = {
                    data: [{
                            backupTaskId: self.archiveTaskDetail.id
                        }],
                    meta: {
                        pagination: {
                            recordStart: 0,
                            pageSize: self.pageSize
                        }
                    }
                };
                $scope.$watch(function () {
                    return (self.payload);
                }, function (oldValue, newValue) {
                    isPayloadUpdated(oldValue, newValue);
                    self.getMailboxesList();
                }, true);
                self.pageSizeOptionsUpdate(adconCommonsService.CONSTANTS.PAGE_SIZES_KEYS.I50);
                self.pagefilterOptionsUpdate(adconCommonsService.CONSTANTS.ALL);
            });
        }
        function getMailboxesList() {
            const payload = angular.copy(self.payload);
            const pageSize = payload.meta.pagination.pageSize;
            self.isDataLoading = true;
            backupService.getMailboxSummary(payload).then(function (response) {
                if (!response.err) {
                    self.mailboxesList = response.all;
                    if (response.meta && response.meta().pagination) {
                        paginationLabelDict.start = paginationActualPage === 1 ? paginationActualPage : (pageSize * (paginationActualPage - 1)) + 1;
                        paginationLabelDict.end = self.mailboxesList.length !== pageSize ? (pageSize * (paginationActualPage - 1)) + self.mailboxesList.length : pageSize * paginationActualPage;
                        self.paginationLabel = self.mailboxesList.length > 0 ? $translate.instant('$I18N_ADMINISTRATION_COMMONS_PAGINATION_SEPARATOR', {
                            start: paginationLabelDict.start,
                            end: paginationLabelDict.end
                        }) : '';
                        self.paginationNextPageResults = response.meta().pagination.next;
                        self.paginationPreviousPageResults = response.meta().pagination.previous;
                    }
                }
                else {
                    backupService.getError().showErrorNotification(response);
                }
                self.isDataLoading = false;
            }, function () {
                self.isDataLoading = false;
                backupService.getError().showErrorNotification();
            });
        }
        function getMoreResults(pageToken) {
            if (!self.isDataLoading && pageToken) {
                self.payload.meta.pagination.pageToken = pageToken;
            }
        }
        function pageSizeOptionsUpdate(id) {
            self.payload.meta.pagination.pageSize = self.pageSizeOptions[id].key;
            adconCommonsService.setDropdownActiveItem(self.pageSizeOptions, id);
            self.selectedPageSizeOptions = self.pageSizeOptions[id].value;
        }
        function pagefilterOptionsUpdate(id) {
            if (adconCommonsService.CONSTANTS.ALL === id) {
                if (self.payload.data[0].status) {
                    delete self.payload.data[0].status;
                }
            }
            else {
                self.payload.data[0].status = self.filtersOptions[id].key;
            }
            adconCommonsService.setDropdownActiveItem(self.filtersOptions, id);
            self.selectedPageFilterOptions = self.filtersOptions[id].value;
        }
        function isPayloadUpdated(oldPayload, newPayload) {
            if (!(_.isEqual(oldPayload.data[0], newPayload.data[0])) || !(_.isEqual(oldPayload.meta.pagination.pageSize, newPayload.meta.pagination.pageSize))) {
                delete self.payload.meta.pagination.pageToken;
                paginationActualPage = 1;
            }
        }
        function paginationNextOnChange() {
            paginationActualPage++;
            getMoreResults(self.paginationNextPageResults);
        }
        function paginationPreviousOnChange() {
            paginationActualPage--;
            getMoreResults(self.paginationPreviousPageResults);
        }
        function gotoMailboxDetails(account) {
            if (self.hasPermission.read) {
                $state.go('backup-mailboxdetails', {
                    mailboxId: account.mailbox,
                    taskId: account.backupTaskSettings.id
                });
            }
        }
        $scope.tab.onRefresh = self.refresh = function () {
            self.mailboxesList = [];
            getMailboxesList();
        };
        function fetchArchiveTask() {
            return backupService.getBackupTask($stateParams.taskId).then(function (archiveTaskResponse) {
                if (!archiveTaskResponse.err) {
                    self.archiveTaskDetail = archiveTaskResponse.first;
                }
                else {
                    backupService.getError().showErrorNotification(archiveTaskResponse);
                    $state.go('archive-task-list');
                }
            }, function () {
                backupService.getError().showErrorNotification();
                $state.go('archive-task-list');
            }, function () {
                backupService.getError().showErrorNotification();
                $state.go('archive-task-list');
            });
        }
        function getStatusStyleAndMessage(archiveTask) {
            return archiveTask ? adconBackupService.getStatusStyleAndMessage(archiveTask.detailedStatus, archiveTask.status) : '';
        }
    }]);
