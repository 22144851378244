"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CrowdstrikeEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const actions = require("app-new/api-applications/actions");
const reducers = require("app-new/api-applications/reducers");
const notification_actions_1 = require("app-new/components/notification/actions/notification.actions");
class CrowdstrikeEffects {
    constructor(actions$, stateService, crowdstrikeApiService, cortexApiService, translateService, store) {
        this.actions$ = actions$;
        this.stateService = stateService;
        this.crowdstrikeApiService = crowdstrikeApiService;
        this.cortexApiService = cortexApiService;
        this.translateService = translateService;
        this.store = store;
        this.createCrowdstrikeIntegration$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationCrowdstrikeActionTypes.CreateCrowdstrikeIntegration), operators_1.withLatestFrom(this.store.select(reducers.getIntegrationCrowdstrikeIntegration), this.store.select(reducers.getIntegrationCrowdstrikeSendClientIdAndSecretInUpdateCall)), operators_1.switchMap(([, config, sendClientIdAndSeceret]) => {
            const request = this.crowdstrikeApiService.buildUpdateRequest(config, sendClientIdAndSeceret);
            return this.crowdstrikeApiService.createCrowdstrikeIntegration(request).pipe(operators_1.switchMap((response) => [
                new notification_actions_1.NotificationShowAction({
                    type: 'success',
                    config: {
                        msg: this.translateService.instant('$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.SUCCESSES.CREATE')
                    }
                }),
                new actions.CancelCrowdstrikeIntegrationWidgetAction(),
                new actions.CreateCrowdstrikeIntegrationSuccessAction(response)
            ]), operators_1.catchError(() => rxjs_1.of(new notification_actions_1.NotificationShowAction({
                type: 'error',
                config: {
                    msg: this.translateService.instant('$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.ERRORS.CREATE')
                }
            }), new actions.CancelCrowdstrikeIntegrationWidgetAction(), new actions.GetAllIntegrationsAction())));
        }));
        this.startVerifyClientIdAndClientSecret$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationCrowdstrikeActionTypes.StartVerifyClientIdAndClientSecret), operators_1.switchMap((action) => {
            if (action.payload.clientId && action.payload.clientSecret) {
                return this.crowdstrikeApiService
                    .verifyClientIdAndClientSecretAction(action.payload.clientId, action.payload.clientSecret, action.payload.baseUrl)
                    .pipe(operators_1.map(() => new actions.StartVerifyClientIdAndClientSecretSuccessAction({
                    clientId: action.payload.clientId,
                    clientSecret: action.payload.clientSecret,
                    baseUrl: action.payload.baseUrl
                })), operators_1.catchError(error => {
                    if (error &&
                        error.firstFail.errors[0].code ===
                            'err_api_integration_crowdstrike_ioc_management_write_permission') {
                        return rxjs_1.of(new actions.StartVerifyClientIdAndClientSecretWritePermissionFailAction());
                    }
                    if (error &&
                        error.firstFail.errors[0].code ===
                            'err_api_integration_crowdstrike_ioc_management_read_permission') {
                        return rxjs_1.of(new actions.StartVerifyClientIdAndClientSecretReadPermissionFailAction());
                    }
                    return rxjs_1.of(new actions.StartVerifyClientIdAndClientSecretFailAction());
                }));
            }
            else {
                return [new actions.StartVerifyClientIdAndClientSecretFailAction()];
            }
        }));
        this.startVerifyClientIdAndClientSecretSuccess$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationCrowdstrikeActionTypes.StartVerifyClientIdAndClientSecretSuccess), operators_1.switchMap(() => {
            return [
                new notification_actions_1.NotificationShowAction({
                    type: 'success',
                    config: {
                        msg: this.translateService.instant('$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.ACTIVATION.VERIFY_CLIENT_ID_AND_CLIENT_SECRET_SUCCESS_NOTIFICATION')
                    }
                })
            ];
        }));
        this.startVerifyClientIdAndClientSecretFail$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationCrowdstrikeActionTypes.StartVerifyClientIdAndClientSecretFail), operators_1.switchMap(() => {
            return [
                new notification_actions_1.NotificationShowAction({
                    type: 'error',
                    config: {
                        msg: this.translateService.instant('$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.ACTIVATION.VERIFY_CLIENT_ID_AND_CLIENT_SECRET_FAIL_NOTIFICATION')
                    }
                })
            ];
        }));
        this.startVerifyClientIdAndClientSecretReadPermissionFail$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationCrowdstrikeActionTypes.StartVerifyClientIdAndClientSecretReadPermissionFail), operators_1.switchMap(() => {
            return [
                new notification_actions_1.NotificationShowAction({
                    type: 'error',
                    config: {
                        msg: this.translateService.instant('$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.ACTIVATION.VERIFY_CLIENT_ID_AND_CLIENT_SECRET_READ_FAIL_NOTIFICATION')
                    }
                })
            ];
        }));
        this.startVerifyClientIdAndClientSecretWritePermissionFail$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationCrowdstrikeActionTypes
            .StartVerifyClientIdAndClientSecretWritePermissionFail), operators_1.switchMap(() => {
            return [
                new notification_actions_1.NotificationShowAction({
                    type: 'error',
                    config: {
                        msg: this.translateService.instant('$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.ACTIVATION.VERIFY_CLIENT_ID_AND_CLIENT_SECRET_WRITE_FAIL_NOTIFICATION')
                    }
                })
            ];
        }));
        this.closeCrowdstrikeIntegrationWizard$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationCrowdstrikeActionTypes.CancelCrowdstrikeIntegrationWidget), operators_1.tap(() => {
            this.stateService.$state.go('api-applications-list', { displayTab: 'integrations' });
        }));
        this.openCrowstrikeActivityLogPage$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationCrowdstrikeActionTypes.OpenCrowdstrikeActivityLogPage), operators_1.tap((action) => {
            this.stateService.$state.go('api-integration-crowdstrike-activity', { id: action.payload });
        }));
        this.getCrowdstrikeActivityLogs$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationCrowdstrikeActionTypes.GetCrowdstrikeActivityLogs), operators_1.switchMap((action) => {
            return this.crowdstrikeApiService.getCrowdstrikeActivityLogs(action.payload).pipe(operators_1.switchMap((response) => [
                new actions.GetCrowdstrikeActivityLogsCompleteAction(response)
            ]), operators_1.catchError(() => rxjs_1.of(new notification_actions_1.NotificationShowAction({
                type: 'error',
                config: {
                    msg: this.translateService.instant('$I18N_CROWDSTRIKE_ACTIVITY_LOGS_TABLE.NOTIFICATIONS.ERROR')
                }
            }), new actions.GetCrowdstrikeActivityLogsCompleteAction([]))));
        }));
        this.updateCrowdstrikeIntegration$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationCrowdstrikeActionTypes.UpdateCrowdstrikeIntegration), operators_1.withLatestFrom(this.store.select(reducers.getIntegrationCrowdstrikeIntegration), this.store.select(reducers.getIntegrationCrowdstrikeSendClientIdAndSecretInUpdateCall)), operators_1.switchMap(([, config, sendClientIdAndSeceret]) => {
            const request = this.crowdstrikeApiService.buildUpdateRequest(config, sendClientIdAndSeceret);
            return this.crowdstrikeApiService.updateCrowdstrikeIntegration(request).pipe(operators_1.switchMap((response) => [
                new notification_actions_1.NotificationShowAction({
                    type: 'success',
                    config: {
                        msg: this.translateService.instant('$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.SUCCESSES.UPDATE')
                    }
                }),
                new actions.CancelCrowdstrikeIntegrationWidgetAction(),
                new actions.UpdateCrowdstrikeIntegrationSuccessAction(response)
            ]), operators_1.catchError(() => rxjs_1.of(new notification_actions_1.NotificationShowAction({
                type: 'error',
                config: {
                    msg: this.translateService.instant('$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.ERRORS.UPDATE')
                }
            }), new actions.CancelCrowdstrikeIntegrationWidgetAction(), new actions.GetAllIntegrationsAction())));
        }));
        this.verifyAndAddEmailCrowdstrikeIntegrationNotifications$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationCrowdstrikeActionTypes.VerifyAndAddEmailCrowdstrikeIntegrationNotifications), operators_1.switchMap((action) => {
            return this.cortexApiService.isInternalEmail(action.payload).pipe(operators_1.map((response) => {
                if (response) {
                    return new actions.VerifyAndAddEmailCrowdstrikeIntegrationNotificationsSuccessAction(action.payload);
                }
                return new actions.VerifyAndAddEmailCrowdstrikeIntegrationNotificationsFailAction();
            }), operators_1.catchError(() => rxjs_1.of(new actions.VerifyAndAddEmailCrowdstrikeIntegrationNotificationsFailAction())));
        }));
        this.verifyAndAddEmailCrowdstrikeIntegrationNotificationsFail$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationCrowdstrikeActionTypes
            .VerifyAndAddEmailCrowdstrikeIntegrationNotificationsFail), operators_1.switchMap(() => {
            return [
                new notification_actions_1.NotificationShowAction({
                    type: 'error',
                    config: {
                        msg: this.translateService.instant('$I18N_API_INTEGRATION_CROWDSTRIKE_WIZARD.STEPS.NOTIFICATIONS.API_ERROR.EXTERNAL_EMAIL')
                    }
                })
            ];
        }));
    }
}
__decorate([
    effects_1.Effect()
], CrowdstrikeEffects.prototype, "createCrowdstrikeIntegration$", void 0);
__decorate([
    effects_1.Effect()
], CrowdstrikeEffects.prototype, "startVerifyClientIdAndClientSecret$", void 0);
__decorate([
    effects_1.Effect()
], CrowdstrikeEffects.prototype, "startVerifyClientIdAndClientSecretSuccess$", void 0);
__decorate([
    effects_1.Effect()
], CrowdstrikeEffects.prototype, "startVerifyClientIdAndClientSecretFail$", void 0);
__decorate([
    effects_1.Effect()
], CrowdstrikeEffects.prototype, "startVerifyClientIdAndClientSecretReadPermissionFail$", void 0);
__decorate([
    effects_1.Effect()
], CrowdstrikeEffects.prototype, "startVerifyClientIdAndClientSecretWritePermissionFail$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], CrowdstrikeEffects.prototype, "closeCrowdstrikeIntegrationWizard$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], CrowdstrikeEffects.prototype, "openCrowstrikeActivityLogPage$", void 0);
__decorate([
    effects_1.Effect()
], CrowdstrikeEffects.prototype, "getCrowdstrikeActivityLogs$", void 0);
__decorate([
    effects_1.Effect()
], CrowdstrikeEffects.prototype, "updateCrowdstrikeIntegration$", void 0);
__decorate([
    effects_1.Effect()
], CrowdstrikeEffects.prototype, "verifyAndAddEmailCrowdstrikeIntegrationNotifications$", void 0);
__decorate([
    effects_1.Effect()
], CrowdstrikeEffects.prototype, "verifyAndAddEmailCrowdstrikeIntegrationNotificationsFail$", void 0);
exports.CrowdstrikeEffects = CrowdstrikeEffects;
