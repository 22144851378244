<div>
  <mc-collapsable-panel header="$I18N_MESSAGE_CENTER_MESSAGE_INFO_REMOTE_SERVER_DETAILS.TITLE" [isExpanded]="true">
    <div *ngIf="isDataSetOne; else archivedRemoteServerDetails">
      <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_REMOTE_SERVER_DETAILS.LABEL_REMOTE_SERVER"
                      [value]="recipientInfo?.txInfo?.remoteServer"
                      [showSecondValue]="true"
                      [secondValue]="selectedDeliveredMessage?.txInfo?.remoteServer">
      </mc-value-field>
      <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_REMOTE_SERVER_DETAILS.LABEL_REMOTE_IP"
                      [value]="recipientInfo?.txInfo?.remoteIp"
                      [showSecondValue]="true"
                      [secondValue]="selectedDeliveredMessage?.txInfo?.remoteIp">
      </mc-value-field>
      <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_REMOTE_SERVER_DETAILS.LABEL_REMOTE_GREETING"
                      [value]="recipientInfo?.txInfo?.remoteServerGreeting"
                      [showSecondValue]="true"
                      [secondValue]="selectedDeliveredMessage?.txInfo?.remoteServerGreeting">
      </mc-value-field>
      <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_REMOTE_SERVER_DETAILS.LABEL_RECEIPT_ACKNOWLEDGEMENT"
                      [value]="recipientInfo?.txInfo?.remoteServerReceiptAck"
                      [showSecondValue]="true"
                      [secondValue]="selectedDeliveredMessage?.txInfo?.remoteServerReceiptAck">
      </mc-value-field>
    </div>
    <ng-template #archivedRemoteServerDetails>
      <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_REMOTE_SERVER_DETAILS.LABEL_REMOTE_SERVER"
                      [value]="recipientInfo?.recipientMetaInfo?.remoteHost"
                      [showSecondValue]="true"
                      [secondValue]="selectedDeliveredMessage?.deliveryMetaInfo?.remoteHost">
      </mc-value-field>
      <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_REMOTE_SERVER_DETAILS.LABEL_REMOTE_IP"
                      [value]="recipientInfo?.recipientMetaInfo?.remoteIp"
                      [showSecondValue]="true"
                      [secondValue]="selectedDeliveredMessage?.deliveryMetaInfo?.remoteIp">
      </mc-value-field>
      <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_REMOTE_SERVER_DETAILS.LABEL_REMOTE_GREETING"
                      [value]="recipientInfo?.recipientMetaInfo?.remoteServerGreeting"
                      [showSecondValue]="true"
                      [secondValue]="selectedDeliveredMessage?.deliveryMetaInfo?.remoteServerGreeting">
      </mc-value-field>
      <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_REMOTE_SERVER_DETAILS.LABEL_RECEIPT_ACKNOWLEDGEMENT"
                      [value]="recipientInfo?.recipientMetaInfo?.receiptAcknowledgement"
                      [showSecondValue]="true"
                      [secondValue]="selectedDeliveredMessage?.deliveryMetaInfo?.receiptAcknowledgement">
      </mc-value-field>
    </ng-template>
  </mc-collapsable-panel>
</div>
