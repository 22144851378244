"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChangeConfirmationModalComponent = void 0;
const model_1 = require("../../model");
class ChangeConfirmationModalComponent {
    constructor(overlayRef, translateService, overlayData) {
        this.overlayRef = overlayRef;
        this.translateService = translateService;
        this.overlayData = overlayData;
        this.changeType = this.overlayData.changeType;
        if (this.overlayData.provider) {
            this.selectedProvider = this.translateService.instant(`$I18N_CONNECTORS.PROVIDERS.${this.overlayData.provider.type.toUpperCase()}`);
        }
        if (this.overlayData.product) {
            this.originalProduct = this.overlayData.product.name;
        }
        this.messageTranslationKey =
            this.overlayData.changeType === model_1.ChangeType.PRODUCT
                ? model_1.ChangeType.PRODUCT.toUpperCase()
                : model_1.ChangeType.PROVIDER.toUpperCase();
    }
    close(confirmed) {
        this.overlayRef.close(confirmed);
    }
}
exports.ChangeConfirmationModalComponent = ChangeConfirmationModalComponent;
