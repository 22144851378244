"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
/**
 * Created by pdesai on 17/02/2017.
 */
(function () {
    'use strict';
    angular.module('audit-logs.service', [])
        .factory('auditLogsService', function () {
        return {
            getColumns,
            getColumnsForExport
        };
        function getColumns() {
            return [
                {
                    key: 'user',
                    value: '$I18N_ADMINISTRATION_AUDIT_LOGS_TABLE_USER_LABEL',
                    logKey: 'user'
                },
                {
                    key: 'category',
                    value: '$I18N_ADMINISTRATION_AUDIT_LOGS_TABLE_CATEGORY_LABEL',
                    logKey: 'category'
                },
                {
                    key: 'audittype',
                    value: '$I18N_ADMINISTRATION_AUDIT_LOGS_TABLE_TYPE_LABEL',
                    logKey: 'auditType'
                },
                {
                    key: 'details',
                    value: '$I18N_ADMINISTRATION_AUDIT_LOGS_TABLE_DETAILS_LABEL',
                    logKey: 'eventInfo'
                },
                {
                    key: 'datetime',
                    value: '$I18N_ADMINISTRATION_COMMONS_TABLE_HEADER_LABEL_DATETIME',
                    logKey: 'eventTime'
                }
            ];
        }
        function getColumnsForExport() {
            return [
                {
                    key: 'user',
                    value: '$I18N_ADMINISTRATION_AUDIT_LOGS_TABLE_USER_LABEL',
                    exportOrder: 0
                },
                {
                    key: 'details',
                    value: '$I18N_ADMINISTRATION_AUDIT_LOGS_TABLE_DETAILS_LABEL',
                    exportOrder: 3
                },
                {
                    key: 'category',
                    value: '$I18N_ADMINISTRATION_AUDIT_LOGS_TABLE_CATEGORY_LABEL',
                    exportOrder: 1
                },
                {
                    key: 'datetime',
                    value: '$I18N_ADMINISTRATION_COMMONS_TABLE_HEADER_LABEL_DATETIME',
                    exportOrder: 4
                },
                {
                    key: 'audittype',
                    value: '$I18N_ADMINISTRATION_AUDIT_LOGS_TABLE_TYPE_LABEL',
                    exportOrder: 2
                }
            ];
        }
    });
}());
