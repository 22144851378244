"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InfiniteScrollDirective = void 0;
const core_1 = require("@angular/core");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
class InfiniteScrollDirective {
    constructor(elm, rendrer) {
        this.elm = elm;
        this.rendrer = rendrer;
        this.onScrollDownPageChange = new core_1.EventEmitter();
        this.onScrollUpPageChange = new core_1.EventEmitter();
    }
    set pageChangeScrollPercentage(percentage) {
        this.percentage = !percentage || percentage > 99 ? 99 : percentage;
    }
    ngOnChanges(changes) {
        if (changes.isDataLoading && changes.isDataLoading.currentValue === false) {
            this.rendrer.setStyle(this.elm.nativeElement, 'overflow-y', 'auto');
            if (this.hotAreaElementTop && this.hotAreaElementTop.nativeElement.clientHeight > 0) {
                this.elm.nativeElement.scrollTop = this.hotAreaElementTop.nativeElement.clientHeight;
            }
        }
        if (changes.isDataLoading && changes.isDataLoading.currentValue === true) {
            this.rendrer.setStyle(this.elm.nativeElement, 'overflow-y', 'hidden');
        }
    }
    ngAfterViewInit() {
        this.registerScrollEvent();
        this.streamScrollEvents();
        this.emitScrollOutOfBound();
    }
    registerScrollEvent() {
        this.scrollEvent$ = rxjs_1.fromEvent(this.elm.nativeElement, 'scroll');
    }
    streamScrollEvents() {
        this.scrollEventStream$ = this.scrollEvent$.pipe(operators_1.map((scrollEvent) => {
            return {
                clientHeight: scrollEvent.target.clientHeight,
                scrollHeight: scrollEvent.target.scrollHeight,
                scrollTop: scrollEvent.target.scrollTop
            };
        }), operators_1.pairwise());
        this.scrollDownStream$ = this.scrollEventStream$.pipe(operators_1.filter((scrollPositions) => {
            return (scrollPositions[0].scrollTop < scrollPositions[1].scrollTop &&
                this.hasScrolledDownToPageChangePercentage(scrollPositions[1]));
        }));
        this.scrollUpStream$ = this.scrollEventStream$.pipe(operators_1.filter((scrollPositions) => {
            return (scrollPositions[0].scrollTop > scrollPositions[1].scrollTop &&
                this.hasScrolledUpToPageChangePercentage(scrollPositions[1]));
        }));
    }
    emitScrollOutOfBound() {
        this.scrollDownStream$.pipe(operators_1.exhaustMap(val => val)).subscribe(() => {
            this.onScrollDownPageChange.emit(true);
        });
        this.scrollUpStream$.pipe(operators_1.exhaustMap(val => val)).subscribe(() => {
            this.onScrollUpPageChange.emit(true);
        });
    }
    hasScrolledDownToPageChangePercentage(currentPosition) {
        let safeZone = (this.percentage * currentPosition.scrollHeight) / 100;
        const hotArea = currentPosition.scrollHeight - safeZone;
        if (this.hotAreaElementBottom) {
            safeZone =
                hotArea > this.hotAreaElementBottom.nativeElement.clientHeight / 2
                    ? currentPosition.scrollHeight - this.hotAreaElementBottom.nativeElement.clientHeight / 2
                    : safeZone;
        }
        return currentPosition.scrollTop + currentPosition.clientHeight > safeZone;
    }
    hasScrolledUpToPageChangePercentage(currentPosition) {
        const safeZone = (this.percentage * currentPosition.scrollHeight) / 100;
        const hotArea = this.hotAreaElementTop && this.hotAreaElementTop.nativeElement.clientHeight
            ? this.hotAreaElementTop.nativeElement.clientHeight / 4
            : (currentPosition.scrollHeight - safeZone) / 2;
        return currentPosition.scrollTop < hotArea || currentPosition.scrollTop === 0;
    }
}
exports.InfiniteScrollDirective = InfiniteScrollDirective;
