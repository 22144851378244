"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./legacy-controller"); //legacy.controller
require("app/components/frame/frame"); //frame
require("mimecast-web-components/src/app/services/classic-adcon-helper/classic-adcon-helper-service"); //classic-adcon-helper.service
// html and css
require("./legacy.less");
require("./legacy.tpl.html");
/**
 * DEPRECATED TO BE DELETED
 */
angular.module('legacy', ['legacy.controller', 'frame', 'classic-adcon-helper.service'])
    .config(['$stateProvider', function () { }]);
