"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const _ = require("lodash");
require("mimecast-web-components/src/app/components/tree/tree");
require("mimecast-web-components/src/app/services/archive/folder/archive-folder"); //api.archive.folder
const create_snapshot_component_1 = require("../../../../../../app-new/sync-recover/exchange/containers/asides/create-snapshot/create-snapshot.component"); //files
(function () {
    'use strict';
    /* ======================================================== *\

   = Backup Mailboxes Details- controller

   \* ======================================================== */
    angular.module('services.services.backup.mailbox-details.controller', ['api.archive.folder', 'files'])
        .controller('MailBoxDetailsController', ['$scope', '$sanitize', 'backupService', 'windowService', 'archiveFolderService', 'userService', 'emailViewService', '$state', '$stateParams', '$aside', '$q', 'growl', '$translate', '$timeout', 'mailboxDetailsSerivce', 'mailboxesGuardService', 'adconBackupService', 'adconCommonsService', 'asideService', function ($scope, $sanitize, backupService, windowService, archiveFolderService, userService, emailViewService, $state, $stateParams, $aside, $q, growl, $translate, $timeout, mailboxDetailsSerivce, mailboxesGuardService, adconBackupService, adconCommonsService, asideService) {
            let userData;
            const self = this;
            let paginationActualPage = 1;
            const rootFolderPath = '\\';
            const paginationLabelDict = {
                start: paginationActualPage,
                end: paginationActualPage
            };
            self.inIframe = windowService.inIframe();
            self.mailboxLastActive = $stateParams.lastActive ? $stateParams.lastActive : undefined;
            self.mailboxId = $stateParams.mailboxId;
            self.synchronizingTask = mailboxesGuardService.backupTask;
            self.accountIsEmpty = false;
            self.isLoadingExtraColumn = true;
            self.isLoadingCenterColumn = true;
            self.payload = {
                data: [{}],
                meta: {
                    pagination: {
                        pageSize: self.pageSize
                    }
                }
            };
            self.mailboxData = [];
            self.arrRowClicked = [];
            self.viewTypes = archiveFolderService.ARCHIVE_VIEW_TYPE;
            self.contentTypes = archiveFolderService.FOLDER_CONTENT_TYPE;
            self.activeTabViewType = self.viewTypes.LIVE;
            self.selectedContentType = self.contentTypes.MESSAGE;
            self.pageSizeOptions = angular.copy(adconCommonsService.getDropdownOptions().pageSizeOptions);
            self.hasPermission = adconBackupService.CONSTANTS.HAS_PERMISSIONS;
            self.getMailboxData = getMailboxData;
            self.updateSearch = updateSearch;
            self.clearSearch = clearSearch;
            self.activateTab = activateTab;
            self.pageSizeOptionsUpdate = pageSizeOptionsUpdate;
            self.paginationNextOnChange = paginationNextOnChange;
            self.paginationPreviousOnChange = paginationPreviousOnChange;
            self.openRestore = openRestore;
            self.openExport = openExport;
            self.enableExport = enableExport;
            self.enableRestore = enableRestore;
            self.disableItemSearch = disableItemSearch;
            self.onMessageSelect = onMessageSelect;
            self.onCalendarSelect = onCalendarSelect;
            self.onContactSelect = onContactSelect;
            self.onTaskSelect = onTaskSelect;
            self.onNotesSelect = onNotesSelect;
            self.openEmailView = function (emailID, row) {
                viewOpener(emailViewService.openEmailView(emailID, 'mailbox', self.activeTabViewType), row);
            };
            self.openContactView = function (contact, row) {
                viewOpener(emailViewService.openContactView(contact, self.payload.data[0].mailbox, 'mailbox', self.activeTabViewType), row);
            };
            self.openCalendarView = function (calendar, row) {
                viewOpener(emailViewService.openCalendarView(calendar, self.payload.data[0].mailbox, 'mailbox', self.activeTabViewType), row);
            };
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            self.openTaskView = function (task, row) {
                //viewOpener(emailViewService.openCalendarView(calendar, self.payload.data[0].mailbox, 'mailbox', self.activeTabViewType), row);
            };
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            self.openNoteView = function (note, row) {
                //viewOpener(emailViewService.openCalendarView(calendar, self.payload.data[0].mailbox, 'mailbox', self.activeTabViewType), row);
            };
            self.isSnapshotInTimeDisabled = isSnapshotInTimeDisabled;
            self.openSnapshotInTime = openSnapshotInTime;
            self.hasExportRestorePermission = adconBackupService.CONSTANTS.HAS_PERMISSIONS.exportAndRestore;
            $scope.$watch(function () {
                return (self.payload);
            }, function (oldValue, newValue) {
                isPayloadUpdated(oldValue, newValue);
                self.getMailboxData();
            }, true);
            /* -------------------------------------------------------- *\
             - interface
             \* -------------------------------------------------------- */
            self.findMailboxFolders = findMailboxFolders;
            self.clearSearchExtraColumn = clearSearchExtraColumn;
            /* -------------------------------------------------------- *\
             - initialisation
             \* -------------------------------------------------------- */
            self.checkedLiveFolders = [];
            self.checkedLiveCalendars = [];
            self.checkedLiveContacts = [];
            self.checkedLiveTasks = [];
            self.checkedLiveNotes = [];
            self.checkedArchiveFolders = [];
            self.checkedArchiveCalendars = [];
            self.checkedArchiveContacts = [];
            self.checkedArchiveTasks = [];
            self.checkedArchiveNotes = [];
            self.liveMessagesPromise = null;
            userService.getProfile().then(function (response) {
                userData = response.first;
            });
            activate();
            /* -------------------------------------------------------- *\
             - implementation
             \* -------------------------------------------------------- */
            function activate() {
                if (!$stateParams.mailboxId) {
                    $state.go('mailboxes-list');
                }
                pageSizeOptionsUpdate(adconCommonsService.CONSTANTS.PAGE_SIZES_KEYS.I50);
                activateTab(self.viewTypes.LIVE);
                self.payload.data[0].mailbox = self.mailboxId;
                userService.getProfile(self.mailboxId).then(function (response) {
                    if (!response.err) {
                        self.user = response.first;
                    }
                    else {
                        userService.getError().showErrorNotification(response);
                    }
                }, function () {
                    userService.getError().showErrorNotification();
                });
                self.liveMessagesPromise = mailboxDetailsSerivce.getMailFoldersAndDoTransformation(self.mailboxId, self.contentTypes.MESSAGE, self.viewTypes.LIVE, 2);
                self.liveCalendarPromise = mailboxDetailsSerivce.findFoldersAndDoTransformation(self.mailboxId, self.contentTypes.CALENDAR, self.viewTypes.LIVE, '');
                self.liveContactPromise = mailboxDetailsSerivce.findFoldersAndDoTransformation(self.mailboxId, self.contentTypes.CONTACT, self.viewTypes.LIVE, '');
                self.liveTaskPromise = mailboxDetailsSerivce.findFoldersAndDoTransformation(self.mailboxId, self.contentTypes.TASK, self.viewTypes.LIVE, '');
                self.liveNotesPromise = mailboxDetailsSerivce.findFoldersAndDoTransformation(self.mailboxId, self.contentTypes.NOTES, self.viewTypes.LIVE, '');
                self.archiveMessagesPromise = mailboxDetailsSerivce.getMailFoldersAndDoTransformation(self.mailboxId, self.contentTypes.MESSAGE, self.viewTypes.ARCHIVE, 2);
                self.archiveCalendarPromise = mailboxDetailsSerivce.findFoldersAndDoTransformation(self.mailboxId, self.contentTypes.CALENDAR, self.viewTypes.ARCHIVE, '');
                self.archiveContactPromise = mailboxDetailsSerivce.findFoldersAndDoTransformation(self.mailboxId, self.contentTypes.CONTACT, self.viewTypes.ARCHIVE, '');
                self.archiveTaskPromise = mailboxDetailsSerivce.findFoldersAndDoTransformation(self.mailboxId, self.contentTypes.TASK, self.viewTypes.ARCHIVE, '');
                self.archiveNotesPromise = mailboxDetailsSerivce.findFoldersAndDoTransformation(self.mailboxId, self.contentTypes.NOTES, self.viewTypes.ARCHIVE, '');
                self.livePromisesResolved = $q.all([self.liveMessagesPromise, self.liveCalendarPromise, self.liveContactPromise, self.liveTaskPromise, self.liveNotesPromise]).then(function (data) {
                    self.liveBaseNode = self.selectedNode = data[0][0] || data[1][0] || data[2][0];
                    self.loadSelectedFolderContents(self.selectedNode, self.activeViewTab, self.selectedPageSizeOptionsKey, '');
                    return self.liveBaseNode;
                });
                self.archivePromisesResolved = $q.all([self.archiveMessagesPromise, self.archiveCalendarPromise, self.archiveContactPromise, self.archiveTaskPromise, self.archiveNotesPromise]).then(function (data) {
                    self.archiveBaseNode = data[0][0] || data[1][0] || data[2][0];
                    return self.archiveBaseNode;
                });
                $q.all([self.livePromisesResolved, self.archivePromisesResolved]).then(function (data) {
                    self.isLoadingExtraColumn = false;
                    self.isLoadingCenterColumn = false;
                    if (!(data[0] || data[1])) {
                        self.accountIsEmpty = true;
                    }
                });
            }
            function activateTab(view) {
                self.activeTabViewType = view;
                clearSearch();
                delete self.payload.data[0].folderId;
                if (view === self.viewTypes.LIVE) {
                    self.payload.data[0].viewType = self.viewTypes.LIVE;
                    self.selectedNode = self.liveBaseNode;
                }
                if (view === self.viewTypes.ARCHIVE) {
                    self.payload.data[0].viewType = self.viewTypes.ARCHIVE;
                    self.selectedNode = self.archiveBaseNode;
                    self.initArchiveView = true;
                }
                self.isLoadingExtraColumn = false;
                self.isLoadingCenterColumn = false;
            }
            function traverseAndPickSelected(includeTasksAndNotes) {
                let calendars;
                let contacts;
                let folders;
                if (self.activeTabViewType === self.viewTypes.LIVE) {
                    calendars = self.getLiveCalendars ? self.getLiveCalendars() : [];
                    contacts = self.getLiveContacts ? self.getLiveContacts() : [];
                    folders = self.getLiveFolders ? self.getLiveFolders() : [];
                    if (includeTasksAndNotes === true) {
                        const tasks = self.getLiveTasks ? self.getLiveTasks() : [];
                        const notes = self.getLiveNotes ? self.getLiveNotes() : [];
                        return folders.concat(contacts, calendars, tasks, notes);
                    }
                    return folders.concat(contacts, calendars);
                }
                calendars = self.getArchiveCalendars();
                contacts = self.getArchiveContacts();
                folders = self.getArchiveFolders();
                if (includeTasksAndNotes === true) {
                    const tasks = self.getArchiveTasks();
                    const notes = self.getArchiveNotes();
                    return folders.concat(contacts, calendars, tasks, notes);
                }
                return folders.concat(contacts, calendars);
            }
            function hasTasksOrNotesSelected() {
                if (self.activeTabViewType === self.viewTypes.LIVE) {
                    return (self.getLiveTasks && !!self.getLiveTasks().length) || (self.getLiveNotes && !!self.getLiveNotes().length);
                }
                return !!self.getArchiveTasks().length || !!self.getArchiveNotes().length;
            }
            function findMailboxFolders(type) {
                if (type == self.viewTypes.LIVE) {
                    if (self.searchLiveFolders)
                        self.searchLiveFolders(self.livefolderQuery);
                    if (self.searchLiveCalendars)
                        self.searchLiveCalendars(self.livefolderQuery);
                    if (self.searchLiveContacts)
                        self.searchLiveContacts(self.livefolderQuery);
                    if (self.searchLiveTasks)
                        self.searchLiveTasks(self.livefolderQuery);
                    if (self.searchLiveNotes)
                        self.searchLiveNotes(self.livefolderQuery);
                }
                else {
                    self.searchArchiveFolders(self.archivefolderQuery);
                    self.searchArchiveCalendars(self.archivefolderQuery);
                    self.searchArchiveContacts(self.archivefolderQuery);
                    self.searchArchiveTasks(self.archivefolderQuery);
                    self.searchArchiveNotes(self.archivefolderQuery);
                }
            }
            function clearSearchExtraColumn() {
                if (self.activeTabViewType === self.viewTypes.LIVE) {
                    if (self.resetLiveFolders)
                        self.resetLiveFolders();
                    if (self.resetLiveCalendars)
                        self.resetLiveCalendars();
                    if (self.resetLiveContacts)
                        self.resetLiveContacts();
                    if (self.resetLiveTasks)
                        self.resetLiveTasks();
                    if (self.resetLiveNotes)
                        self.resetLiveNotes();
                }
                else {
                    self.resetArchiveFolders();
                    self.resetArchiveCalendars();
                    self.resetArchiveContacts();
                    self.resetArchiveTasks();
                    self.resetArchiveNotes();
                }
            }
            function onMessageSelect(item) {
                self.selectedContentType = self.contentTypes.MESSAGE;
                contentSelector(item);
            }
            function onCalendarSelect(item) {
                self.selectedContentType = self.contentTypes.CALENDAR;
                contentSelector(item);
            }
            function onContactSelect(item) {
                self.selectedContentType = self.contentTypes.CONTACT;
                contentSelector(item);
            }
            function onTaskSelect(item) {
                self.selectedContentType = self.contentTypes.TASK;
                contentSelector(item);
            }
            function onNotesSelect(item) {
                self.selectedContentType = self.contentTypes.NOTES;
                contentSelector(item);
            }
            function getMailboxData() {
                if (!self.payload.data[0].folderId) {
                    self.mailboxData = [];
                    return;
                }
                const payload = angular.copy(self.payload);
                const pageSize = payload.meta.pagination.pageSize;
                let serviceToBeUse;
                self.isLoadingExtraColumn = true;
                self.isLoadingCenterColumn = true;
                switch (self.selectedContentType) {
                    case self.contentTypes.MESSAGE:
                        if (payload.data[0].query) {
                            payload.data[0].query =
                                '<?xml version="1.0" encoding="UTF-8"?>' +
                                    '<XMLQuery trace="muse">' +
                                    '   <metadata query-type="emailarchive" archive="true" active="false" sort-on="receiveddate" sort-by="desc" page-size="10">' +
                                    '       <mailboxes>' +
                                    '           <mailbox include-delegates="false" include-aliases="false" exchange-folder-id="' + self.payload.data[0].folderId + '">' + self.payload.data[0].mailbox + '</mailbox>' +
                                    '       </mailboxes>' +
                                    '       <return-fields>' +
                                    '           <return-field>size</return-field>' +
                                    '           <return-field>attachmentcount</return-field>' +
                                    '           <return-field>subject</return-field>' +
                                    '           <return-field>receiveddate</return-field>' +
                                    '           <return-field>displayfrom</return-field>' +
                                    '           <return-field>displayto</return-field>' +
                                    '           <return-field>id</return-field>' +
                                    '       </return-fields>' +
                                    '   </metadata>' +
                                    '   <muse>' +
                                    '       <text>(' + $sanitize(payload.data[0].query) + ')</text>' +
                                    '   </muse>' +
                                    '</XMLQuery>';
                            payload.data[0].type = 'mailbox_recovery';
                            payload.data[0].admin = true;
                            serviceToBeUse = archiveFolderService.findMailboxFoldersEmails;
                        }
                        else {
                            serviceToBeUse = archiveFolderService.getMailboxFolderEmails;
                        }
                        break;
                    case self.contentTypes.CALENDAR:
                        if (payload.data[0].query) {
                            payload.data[0].query = $sanitize(payload.data[0].query);
                            serviceToBeUse = archiveFolderService.findMailboxCalendarItems;
                        }
                        else {
                            serviceToBeUse = archiveFolderService.getMailboxFolderCalendar;
                        }
                        break;
                    case self.contentTypes.CONTACT:
                        if (payload.data[0].query) {
                            payload.data[0].query = $sanitize(payload.data[0].query);
                            serviceToBeUse = archiveFolderService.findMailboxContacts;
                        }
                        else {
                            serviceToBeUse = archiveFolderService.getMailboxFolderContacts;
                        }
                        break;
                    case self.contentTypes.TASK:
                        if (payload.data[0].query) {
                            payload.data[0].query = $sanitize(payload.data[0].query);
                            serviceToBeUse = archiveFolderService.findMailboxTasks;
                        }
                        else {
                            serviceToBeUse = archiveFolderService.getMailboxFolderTasks;
                        }
                        break;
                    case self.contentTypes.NOTES:
                        if (payload.data[0].query) {
                            payload.data[0].query = $sanitize(payload.data[0].query);
                            serviceToBeUse = archiveFolderService.findMailboxNotes;
                        }
                        else {
                            serviceToBeUse = archiveFolderService.getMailboxFolderNotes;
                        }
                        break;
                }
                if (serviceToBeUse) {
                    serviceToBeUse(payload).then(function (response) {
                        if (!response.err) {
                            switch (self.selectedContentType) {
                                case self.contentTypes.MESSAGE:
                                    if (payload.data[0].query) {
                                        self.mailboxData = response.first.items.map(function (email) {
                                            const formattedEmail = email;
                                            formattedEmail.dateReceived = email.receiveddate;
                                            formattedEmail.from = {};
                                            formattedEmail.from.emailAddress = email.displayfrom;
                                            return formattedEmail;
                                        });
                                    }
                                    else {
                                        self.mailboxData = response.first.emails;
                                    }
                                    break;
                                case self.contentTypes.CONTACT:
                                    self.mailboxData = response.first.contacts;
                                    break;
                                case self.contentTypes.CALENDAR:
                                    self.mailboxData = response.first.calendarItems;
                                    break;
                                case self.contentTypes.TASK:
                                    self.mailboxData = response.first.taskItems;
                                    break;
                                case self.contentTypes.NOTES:
                                    self.mailboxData = response.first.noteItems;
                                    break;
                            }
                            if (response.meta && response.meta().pagination) {
                                paginationLabelDict.start = paginationActualPage === 1 ? paginationActualPage : (pageSize * (paginationActualPage - 1)) + 1;
                                paginationLabelDict.end = self.mailboxData.length !== pageSize ? (pageSize * (paginationActualPage - 1)) + self.mailboxData.length : pageSize * paginationActualPage;
                                self.paginationLabel = self.mailboxData.length > 0 ? $translate.instant('$I18N_ADMINISTRATION_COMMONS_PAGINATION_SEPARATOR', {
                                    start: paginationLabelDict.start,
                                    end: paginationLabelDict.end
                                }) : '';
                                self.paginationNextPageResults = response.meta().pagination.next;
                                self.paginationPreviousPageResults = response.meta().pagination.previous;
                            }
                        }
                        else {
                            archiveFolderService.getError().showErrorNotification(response);
                        }
                        self.isLoadingCenterColumn = false;
                    }, function () {
                        archiveFolderService.getError().showErrorNotification();
                    });
                }
                self.isLoadingExtraColumn = false;
            }
            function updateSearch() {
                self.payload.data[0].query = self.searchTerm;
            }
            function clearSearch() {
                delete self.payload.data[0].query;
            }
            function isPayloadUpdated(oldPayload, newPayload) {
                if (!(_.isEqual(oldPayload.data[0], newPayload.data[0])) || !(_.isEqual(oldPayload.meta.pagination.pageSize, newPayload.meta.pagination.pageSize))) {
                    delete self.payload.meta.pagination.pageToken;
                    paginationActualPage = 1;
                }
            }
            function getMoreResults(pageToken) {
                self.payload.meta.pagination.pageToken = pageToken;
            }
            function paginationNextOnChange() {
                self.isLoadingCenterColumn = true;
                paginationActualPage++;
                getMoreResults(self.paginationNextPageResults);
            }
            function paginationPreviousOnChange() {
                self.isLoadingCenterColumn = true;
                paginationActualPage--;
                getMoreResults(self.paginationPreviousPageResults);
            }
            function pageSizeOptionsUpdate(id) {
                self.payload.meta.pagination.pageSize = self.pageSizeOptions[id].key;
                adconCommonsService.setDropdownActiveItem(self.pageSizeOptions, id);
                self.selectedPageSizeOptions = self.pageSizeOptions[id].value;
            }
            function openRestore() {
                const asideInstance = $aside.open({
                    templateUrl: 'services/services/backup/mailboxes/mailbox-details/slides/mailbox-email-restore.tpl.html',
                    placement: 'right',
                    size: 'lg',
                    backdrop: true,
                    windowClass: 'hidden-xs',
                    backdropClass: 'hidden-xs',
                    controller: angular.noop,
                    resolve: {
                        mailbox() {
                            return self.mailboxId;
                        },
                        viewType() {
                            return self.activeTabViewType;
                        },
                        folderPaths() {
                            return traverseAndPickSelected(true);
                        },
                        dismissFunction() {
                            return function () {
                                asideInstance.close();
                            };
                        },
                        userEmailAddress() {
                            return userData.emailAddress;
                        },
                        snapshotId() {
                            return undefined;
                        }
                    }
                });
            }
            function openExport() {
                const asideInstance = $aside.open({
                    templateUrl: 'services/services/backup/mailboxes/mailbox-details/slides/mailbox-email-export.tpl.html',
                    placement: 'right',
                    size: 'lg',
                    backdrop: true,
                    windowClass: 'hidden-xs',
                    backdropClass: 'hidden-xs',
                    controller: angular.noop,
                    resolve: {
                        mailbox() {
                            return self.mailboxId;
                        },
                        viewType() {
                            return self.activeTabViewType;
                        },
                        folderPaths() {
                            return traverseAndPickSelected(false);
                        },
                        dismissFunction() {
                            return function () {
                                asideInstance.close();
                            };
                        },
                        userEmailAddress() {
                            return userData.emailAddress;
                        },
                        snapshotId() {
                            return undefined;
                        },
                        hasUnsupported() {
                            return hasTasksOrNotesSelected();
                        }
                    }
                });
            }
            function enableExport() {
                if (!adconBackupService.CONSTANTS.HAS_PERMISSIONS.exportAndRestore || self.isLoadingExtraColumn || self.isLoadingCenterColumn) {
                    return false;
                }
                if (self.activeTabViewType === self.viewTypes.LIVE) {
                    return !!self.checkedLiveFolders.length || !!self.checkedLiveCalendars.length || !!self.checkedLiveContacts.length;
                }
                return !!self.checkedArchiveFolders.length || !!self.checkedArchiveCalendars.length || !!self.checkedArchiveContacts.length;
            }
            function enableRestore() {
                if (!adconBackupService.CONSTANTS.HAS_PERMISSIONS.exportAndRestore || self.isLoadingExtraColumn || self.isLoadingCenterColumn) {
                    return false;
                }
                if (self.activeTabViewType === self.viewTypes.LIVE) {
                    return !!self.checkedLiveFolders.length || !!self.checkedLiveCalendars.length || !!self.checkedLiveContacts.length || !!self.checkedLiveTasks.length || !!self.checkedLiveNotes.length;
                }
                return !!self.checkedArchiveFolders.length || !!self.checkedArchiveCalendars.length || !!self.checkedArchiveContacts.length || !!self.checkedArchiveTasks.length || !!self.checkedArchiveNotes.length;
            }
            function disableItemSearch() {
                return !self.hasPermission.contentView
                    || !self.payload.data[0].folderId
                    || (!self.mailboxData.length && (!self.payload.data[0].query || !self.payload.data[0].query.length));
            }
            function viewOpener(service, row) {
                self.isLoadingCenterColumn = true;
                self.arrRowClicked[row] = true;
                service.result.then(function () {
                    self.arrRowClicked = [];
                }, function () {
                    self.arrRowClicked = [];
                });
                service.opened.then(function () {
                    self.isLoadingCenterColumn = false;
                }, function () {
                    self.isLoadingCenterColumn = false;
                });
            }
            function contentSelector(item) {
                clearSearch();
                self.arrRowClicked = [];
                if (item.fullPath !== rootFolderPath) {
                    self.payload.data[0].folderId = item.id;
                }
                else {
                    delete self.payload.data[0].folderId;
                    self.mailboxData = [];
                    self.paginationLabel = '';
                }
            }
            $scope.tab.onRefresh = self.refresh = function () {
                self.mailboxData = [];
                getMailboxData();
            };
            function openSnapshotInTime() {
                asideService.open(create_snapshot_component_1.CreateSnapshotComponent, { position: 'right',
                    size: 'md',
                    hasBackdrop: true,
                    data: {
                        mailbox: self.mailboxId,
                        user: userData.emailAddress,
                        initialReplicationDate: $stateParams.initialReplicationDate
                    }
                });
            }
            function isSnapshotInTimeDisabled() {
                return !adconBackupService.CONSTANTS.HAS_PERMISSIONS.exportAndRestore;
            }
        }]);
}());
