"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DetailViewAsideComponent = void 0;
const reducers = require("../../reducers");
const asideAction = require("../../actions/aside.action");
const attribute_factory_1 = require("../../factories/attribute.factory");
class DetailViewAsideComponent {
    constructor(overlayRef, store, capabilitiesService) {
        this.overlayRef = overlayRef;
        this.store = store;
        this.capabilitiesService = capabilitiesService;
        this.mode = 'VIEW';
        this.PREFIX_KEY = '$18N_ATTRIBUTES_DIRECTORIES_LIST.ASIDE.PROPERTIES';
    }
    ngOnInit() {
        this.pagination$ = this.store.select(reducers.getAsidePagination);
        this.attributeDetails$ = this.store.select(reducers.getAttributeDetails);
        this.isLoadingAttributeDetails$ = this.store.select(reducers.isLoadingAttributeDetails);
        this.hasGenericError$ = this.store.select(reducers.hasGenericErrorOnAttributeLoad);
        this.attributeDetailsSubscription = this.attributeDetails$.subscribe((attribute) => {
            this.attributeData = attribute_factory_1.ATTRIBUTE_FACTORY.ATTRIBUTE(Object.assign({}, attribute));
        });
        this.userCapabilitiesSubscription = this.capabilitiesService.capabilities.subscribe((caps) => {
            const editPermission = 'Permission.ATTRIBUTES_EDIT';
            this.isEditable = caps[editPermission] && caps[editPermission].enabled;
        });
    }
    closeAside() {
        this.overlayRef.close();
    }
    showAttribute(attribute) {
        this.store.dispatch(new asideAction.GetAttributeDetails(attribute.token));
    }
    toggleMode() {
        this.mode = this.mode === 'EDIT' ? 'VIEW' : 'EDIT';
    }
    isEditMode() {
        return this.mode === 'EDIT';
    }
    updateFormData(obj) {
        if (obj) {
            this.isInvalid = obj.isInvalid;
            this.modifiedAttribute = obj.attribute;
            if (this.attributeData.id) {
                this.modifiedAttribute['id'] = this.attributeData.id;
            }
        }
    }
    saveAttribute() {
        this.store.dispatch(new asideAction.SaveAttribute(this.modifiedAttribute));
    }
    cancel() {
        if (this.attributeData.id !== null) {
            this.toggleMode();
        }
        else {
            this.closeAside();
        }
    }
    deleteAttribute() {
        this.store.dispatch(new asideAction.ConfirmDeleteAttribute(this.attributeData.id));
    }
    ngOnDestroy() {
        this.attributeDetailsSubscription.unsubscribe();
        this.userCapabilitiesSubscription.unsubscribe();
    }
}
exports.DetailViewAsideComponent = DetailViewAsideComponent;
