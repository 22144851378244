"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiIntegrationCortexActivationStepComponent = void 0;
const core_1 = require("@angular/core");
class ApiIntegrationCortexActivationStepComponent {
    constructor() {
        this.onSubmitted = new core_1.EventEmitter();
    }
    submit() {
        if (this.isValid()) {
            this.onSubmitted.emit();
        }
    }
    isValid() {
        return true;
    }
}
exports.ApiIntegrationCortexActivationStepComponent = ApiIntegrationCortexActivationStepComponent;
