<div *ngIf="settings.ldapMailAttribute === tuidAttributes.USER_PRINCIPAL_NAME">
  <mc-modal-header
    headerTitle="{{ '$I18N_ENDPOINT_SETTINGS.MODAL.SETTINGS.TUID_USER_PRINCIPAL_NAME.HEADER' | translate }}">
  </mc-modal-header>
  <mc-modal-body>
    <p>
      {{ '$I18N_ENDPOINT_SETTINGS.MODAL.SETTINGS.TUID_USER_PRINCIPAL_NAME.BODY_CONTENT' | translate }}
    </p>
  </mc-modal-body>
</div>
<div *ngIf="settings.ldapMailAttribute === tuidAttributes.MAIL">
  <mc-modal-header
    headerTitle="{{ '$I18N_ENDPOINT_SETTINGS.MODAL.SETTINGS.TUID_MAIL.HEADER' | translate }}">
  </mc-modal-header>
  <mc-modal-body>
    <p>
      {{ '$I18N_ENDPOINT_SETTINGS.MODAL.SETTINGS.TUID_MAIL.BODY_CONTENT' | translate }}
    </p>
  </mc-modal-body>
</div>
<mc-modal-footer>
  <button type="button" class="btn btn-secondary" (click)="onCancel()">
    {{ '$I18N_ENDPOINT_SETTINGS.MODAL.SETTINGS.BTN_CANCEL' | translate }}
  </button>
  <mc-live-button
    overrideClasses="btn-primary"
    [label]="'$I18N_ENDPOINT_SETTINGS.MODAL.SETTINGS.TUID_MAIL.BTN_OK'  | translate"
    (mcClick)="onUpdateSetting()">
  </mc-live-button>
</mc-modal-footer>
