'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
/**
 * Created by iaggarwal on 03/03/2017.
 */
angular.module('mc-dropdown-simple-label-row.directive', [])
    .directive('mcDropdownSimpleLabelRow', [function () {
        return {
            restrict: 'AE',
            templateUrl: 'components/mc-dropdown/pre-defined-rows/mc-dropdown-simple-label-row/mc-dropdown-simple-label-row.tpl.html',
            transclude: true,
            replace: true,
            require: ['^mcDropdown'],
            scope: {
                displayValue: '@displayValue',
                displayIconClasses: '@displayIconClasses',
                isDropdownOpenOnClick: '@isDropdownOpenOnClick',
                isHeaderRow: '@isHeaderRow'
            },
            link($scope, $element, $attr, controller) {
                const mcDropdownCtrl = controller[0];
                $scope.applyIsOpenOnClick = applyIsOpenOnClick;
                function applyIsOpenOnClick() {
                    mcDropdownCtrl.isOpen = $scope.isDropdownOpenOnClick === 'true';
                }
            },
            controller: ['$scope', function ($scope) {
                    const self = this;
                    self.displayValue = $scope.displayValue;
                    self.displayIconClasses = $scope.displayIconClasses;
                    self.isHeaderRow = $scope.isHeaderRow === 'true';
                }],
            controllerAs: 'mcDropdownSimpleLabelRowCtrl'
        };
    }]);
