"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateSwgPolicyWizardComponent = void 0;
const core_1 = require("@angular/core");
const policy_1 = require("../../models/policy");
const clp_1 = require("../../models/clp");
class CreateSwgPolicyWizardComponent {
    constructor(policyService, translateService) {
        this.policyService = policyService;
        this.translateService = translateService;
        this.currentStep = 1;
        this.ngInitDone = false;
        this.hasAppPolicyRemoveAllowEnabled = false;
        this.updatePolicyDescTypeWiz = new core_1.EventEmitter();
        this.updatePolicyDomainsWiz = new core_1.EventEmitter();
        this.updateLoggingLevelWiz = new core_1.EventEmitter();
        this.updateAffectedUsersWiz = new core_1.EventEmitter();
        this.updateSafeSearchSettingsWiz = new core_1.EventEmitter();
        this.updateNewDomainsSettingsWiz = new core_1.EventEmitter();
        this.updateProxySettingsWiz = new core_1.EventEmitter();
        this.updateWebApplicationRuleWiz = new core_1.EventEmitter();
        this.updateWebCategorySecurityRulesWiz = new core_1.EventEmitter();
        this.updateWebCategoryOtherRulesWiz = new core_1.EventEmitter();
        this.updateProtectedApplicationsWiz = new core_1.EventEmitter();
        this.updateTtpManagedUrlSettingsWiz = new core_1.EventEmitter();
        this.updateTtpChameleonSettingsWiz = new core_1.EventEmitter();
        this.updateClpSettingsWiz = new core_1.EventEmitter();
        this.updateSelectedAppWiz = new core_1.EventEmitter();
        this.openAppliesToSidePanelWiz = new core_1.EventEmitter();
        this.openBulkUploadDomainsWiz = new core_1.EventEmitter();
        this.createPolicyEvent = new core_1.EventEmitter();
        this.resetWizard = new core_1.EventEmitter();
        this.selectPolicyType = new core_1.EventEmitter();
        this.everyOneAffectedUsersWiz = new core_1.EventEmitter();
        this.removeAffectedUsersWiz = new core_1.EventEmitter();
        this.updatePolicyOverrideStatusWiz = new core_1.EventEmitter();
        this.clearAffectedUsersOtherThanWiz = new core_1.EventEmitter();
        this.enableAvScanWarning = {
            status: 'info',
            msg: this.translateService.instant('$I18N_SWG_WEB_APPLICATION_CONTROL_AV_SCAN_ENABLE_WARNING'),
            boxed: false
        };
    }
    ngOnInit() {
        this.ngInitDone = true;
    }
    goToStep(event) {
        this.currentStep = event;
    }
    cancel() {
        this.resetWizard.emit(true);
    }
    enterPressedEvent() {
        this.wizard.nextStep();
    }
    isEditFlow() {
        return this.wizardFlowType !== policy_1.SwgPolicyWizardFlowType.CREATE;
    }
    getPageTitle() {
        if (policy_1.SwgPolicyWizardFlowType.CREATE === this.wizardFlowType) {
            return '$I18N_SWG_CREATE_POLICY_CREATE_A_POLICY';
        }
        else if (policy_1.SwgPolicyWizardFlowType.EDIT === this.wizardFlowType) {
            return '$I18N_SWG_CREATE_POLICY_EDIT_A_POLICY';
        }
        else {
            return 'Duplicate Policy';
        }
    }
    getPageSubTitle(edit) {
        return edit ? '$I18N_SWG_EDIT_POLICY_DESC' : '';
    }
    getSecuritySections(webCategoryRulesSections, userRuleSections) {
        return this.policyService.getCategorySectionsForDisplay(webCategoryRulesSections, userRuleSections, 'SECURITY');
    }
    getNonSecuritySections(webCategoryRulesSections, userRuleSections) {
        return this.policyService.getCategorySectionsForDisplay(webCategoryRulesSections, userRuleSections, 'NON-SECURITY');
    }
    getAppSecSections(protectedApplications, policyProtectedApplications) {
        return this.policyService.getAppSectionsForDisplay(protectedApplications, policyProtectedApplications);
    }
    getControllableApplications(apps) {
        if (this.hasApplicationPolicyControllableEnabled) {
            return apps.filter((app) => app.isControllable);
        }
        return apps;
    }
    isStepValid() {
        if (this.currentStep === 1) {
            return this.swgPolicyStepPage.isValid();
        }
        if (policy_1.SwgPolicyType.CLOUD_PROTECT_POLICY === this.policy.policyDesc.policyType) {
            if (this.currentStep === 2) {
                return !!this.policy.clpPolicySettings.provider;
            }
            if (this.currentStep === 3) {
                return !!this.policy.clpPolicySettings.connectorId;
            }
            if (this.currentStep === 4) {
                return this.policy.clpPolicySettings.action !== clp_1.ClpPolicyActions.NOT_SELECTED;
            }
        }
        if ((this.currentStep === 3 &&
            [policy_1.SwgPolicyType.WEB_BLOCK_ALLOW_LIST, policy_1.SwgPolicyType.WEB_LOGGING].indexOf(this.policy.policyDesc.policyType) >= 0) ||
            (this.currentStep === 4 &&
                [policy_1.SwgPolicyType.WEB_TTP_POLICY, policy_1.SwgPolicyType.WEB_CATEGORY_FILTERING].indexOf(this.policy.policyDesc.policyType) >= 0) ||
            (this.currentStep === 5 &&
                policy_1.SwgPolicyType.WEB_ADVANCED_SECURITY === this.policy.policyDesc.policyType) ||
            (this.currentStep === 6 &&
                policy_1.SwgPolicyType.CLOUD_PROTECT_POLICY === this.policy.policyDesc.policyType)) {
            return this.swgPolicyAffectedUsers.isValid();
        }
        return true;
    }
    hasDescription(policy) {
        return !!policy.policyDesc.description.length;
    }
    hasValidAppliesTo(policy) {
        return (policy.everyOne ||
            policy.affectedUsers.filter(target => target.condition !== policy_1.SwgAffectedUsersEnum.EVERYONE)
                .length > 0);
    }
    isValidPolicy(policy) {
        return this.hasDescription(policy) && this.hasValidAppliesTo(policy);
    }
    updateProxySettings(proxySettings) {
        if (!proxySettings.avScan) {
            proxySettings.biEnabled = false;
        }
        this.updateProxySettingsWiz.emit(proxySettings);
    }
}
exports.CreateSwgPolicyWizardComponent = CreateSwgPolicyWizardComponent;
