'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const moment = require("moment");
const _ = require("lodash");
const operators_1 = require("rxjs/operators");
const model_1 = require("../../../app-new/core/model");
angular.module('dashboard-commons.service', ['core.service'])
    .factory('dashboardCommonsService', ['$filter', '$translate', '$sanitize', 'DASHBOARD', 'growl', 'CoreService', function ($filter, $translate, $sanitize, DASHBOARD, growl, coreService) {
        return {
            getDataForAllHours,
            getDataForAllTenMins,
            getHours,
            getNavigationConfig,
            getButtonConfig,
            getDataForAllDays,
            getUrlCountPerGroup,
            getMcChartsTheme,
            getUrlCountsPerCategory,
            getCategoriesAndSeries,
            getCurrentMonthDateRange,
            sortArrayByCleanAndMalicious,
            isDataSetEmpty,
            isYaxisEmpty,
            mergeDataSetsByDate,
            getCustomTooltip,
            getAttachmentProtectWrappedTooltip,
            getTruncatedLabel
        };
        let dateFormatErrorPrompted = false;
        function getDataForAllHours(allData, startDate, endDate) {
            const hours = getHours(startDate, endDate);
            const newDataPoints = [];
            angular.forEach(hours, function (hour) {
                let formattedHour;
                try {
                    formattedHour = $filter('mcUtc')(moment(hour)).format(DASHBOARD.API_DATE_FORMAT);
                }
                catch (err) {
                    growl.error($translate.instant(err.message));
                }
                const data = ($filter('filter')(allData, { date: formattedHour }));
                let total = 0;
                if (angular.isDefined(data) && data.length > 0) {
                    total = data[0].count;
                }
                newDataPoints.push([hour, total]);
            });
            return newDataPoints;
        }
        function getHours(startDate, endDate) {
            const hours = [];
            //floor to hour
            // @ts-ignore
            startDate = parseInt((startDate / 10000000), 10) * 10000000;
            try {
                startDate = $filter('mcUtc')(moment(startDate)).startOf('hour');
            }
            catch (err) {
                if (!dateFormatErrorPrompted) {
                    growl.error($translate.instant(err.message));
                    dateFormatErrorPrompted = true;
                }
            }
            while (startDate <= endDate) {
                hours.push(startDate.valueOf());
                startDate = moment(startDate).add(1, 'hour');
            }
            //we do not need the last hour in localtimeZone
            hours.pop();
            return hours;
        }
        function getTenMins(startDate, endDate) {
            const mins = [];
            const startDateInMs = moment(startDate).valueOf();
            // @ts-ignore
            startDate = parseInt((startDateInMs / 1000000), 10) * 1000000;
            try {
                startDate = $filter('mcUtc')(moment(startDate)).startOf('hour');
                endDate = moment(endDate);
            }
            catch (err) {
                if (!dateFormatErrorPrompted) {
                    growl.error($translate.instant(err.message));
                    dateFormatErrorPrompted = true;
                }
            }
            while ((startDate <= endDate)) {
                //ignore all time points earlier than the initial start time
                if ((startDate.valueOf() >= startDateInMs)) {
                    mins.push(startDate.valueOf());
                }
                startDate = moment(startDate).add(10, 'minute');
            }
            //we do not need the last ten mins
            mins.pop();
            return mins;
        }
        function getDataForAllTenMins(allData, startDate, endDate) {
            const tenMins = getTenMins(startDate, endDate);
            const newDataPoints = [];
            angular.forEach(tenMins, function (min) {
                let formattedHour;
                try {
                    formattedHour = $filter('mcUtc')(moment(min)).format(DASHBOARD.API_DATE_FORMAT);
                }
                catch (err) {
                    if (!dateFormatErrorPrompted) {
                        growl.error($translate.instant(err.message));
                        dateFormatErrorPrompted = true;
                    }
                }
                const data = ($filter('filter')(allData, { date: formattedHour }));
                let total = 0;
                if (angular.isDefined(data) && data.length > 0) {
                    total = data[0].count;
                }
                newDataPoints.push([min, total]);
            });
            return newDataPoints;
        }
        function getDataForAllDays(from, to, dayCounts) {
            const dataSet = [];
            let startDate = $filter('mcUtc')(moment(from)).startOf('day');
            const endDate = $filter('mcUtc')(moment(to)).startOf('day');
            while (startDate <= endDate) {
                dataSet.push([startDate.valueOf(), 0]);
                startDate = moment(startDate).add(1, 'd');
            }
            _.forOwn(dayCounts, function (value, key) {
                _.forEach(dataSet, function (item) {
                    if (moment(key).startOf('day').utc().format() === moment(item[0]).startOf('day').utc().format()) {
                        item[1] = value;
                    }
                });
            });
            return dataSet;
        }
        function getUrlCountPerGroup(dayCounts) {
            let totalCount = 0;
            _.forOwn(dayCounts, function (value) {
                totalCount += value;
            });
            return totalCount;
        }
        function getUrlCountsPerCategory(attributeCounts) {
            const dataSet = [];
            _.forOwn(attributeCounts, function (value, key) {
                dataSet.push({
                    name: (key === '') ? $translate.instant('$I18N_COMMON_EXPORT_CHART_UNKNOWN_LABEL') : key,
                    y: value
                });
            });
            return _.sortBy(dataSet, 'y').reverse();
        }
        function getCategoriesAndSeries(attributeCounts, isUrl) {
            const dataSets = {
                categories: [],
                series: []
            };
            _.forOwn(attributeCounts, function (value, key) {
                if (isUrl) {
                    if (key) {
                        dataSets.categories.push(extractDomainFromUrl(key));
                    }
                }
                else {
                    if (key === '<>' || key === '') {
                        dataSets.categories.push($translate.instant('$I18N_COMMON_EXPORT_CHART_UNKNOWN_LABEL'));
                    }
                    else {
                        dataSets.categories.push(key);
                    }
                }
                dataSets.series.push(value);
            });
            return dataSets;
        }
        function getCurrentMonthDateRange() {
            const currentDate = new Date();
            const monthAgoFromCurrentDate = new Date();
            monthAgoFromCurrentDate.setMonth(currentDate.getMonth() - 1);
            return {
                from: $filter('mcUtc')(moment(monthAgoFromCurrentDate)).format(DASHBOARD.API_DATE_FORMAT),
                to: $filter('mcUtc')(moment(currentDate)).format(DASHBOARD.API_DATE_FORMAT)
            };
        }
        function getNavigationConfig(objCustomConfigButtonOptions) {
            const objConfigButtonOptions = {
                height: 24,
                y: 6,
                theme: {
                    fill: 'white',
                    stroke: '#E6E6E6',
                    r: 2,
                    states: {
                        hover: {
                            fill: '#ecf0f1',
                            stroke: '#E6E6E6'
                        },
                        select: {
                            fill: '#ecf0f1',
                            stroke: '#E6E6E6'
                        }
                    }
                }
            };
            if (objCustomConfigButtonOptions) {
                angular.merge(objConfigButtonOptions, objCustomConfigButtonOptions);
            }
            return {
                buttonOptions: objConfigButtonOptions
            };
        }
        function getButtonConfig() {
            const menuItems = [
                'printChart',
                'downloadPNG',
                'downloadJPEG',
                'downloadSVG'
            ];
            // Unsupported Browsers: IE11 and Edge <= v18
            const unsupportedBrowser = navigator.userAgent.match(/(?:\b(MS)?IE\s+|\bTrident\/7\.0;.*\s+rv:|\bEdge\/)(\d+)/) !== null;
            let fipsMode = false;
            coreService
                .getCapsOverride()
                .pipe(operators_1.first(), model_1.isDefined())
                .subscribe((caps) => {
                var _a;
                fipsMode = (_a = caps['fipsMode']) === null || _a === void 0 ? void 0 : _a.enabled;
            });
            if (!(fipsMode && unsupportedBrowser)) {
                menuItems.splice(3, 0, 'downloadPDF');
            }
            return {
                contextButton: {
                    // symbol: 'url(../assets/images/adcon_dash_more5.png)',
                    menuItems
                }
            };
        }
        function getMcChartsTheme() {
            return {
                defaultColours: {
                    inbound: {
                        primary: '#004ba0',
                        alt: '#004BA0'
                    },
                    outbound: {
                        primary: '#63a4ff',
                        alt: '#63a4ff'
                    },
                    internal: {
                        primary: '#ffa000',
                        alt: '#ffa000'
                    }
                },
                colorCharts: {
                    inbound: [
                        '#004ba0',
                        '#1a5daa',
                        '#336fb3',
                        '#4d81bd',
                        '#6693c6',
                        '#80a5d0',
                        '#99b7d9',
                        '#b3c9e3',
                        '#ccdbec',
                        '#e6edf6'
                    ],
                    outbound: [
                        '#63a4ff',
                        '#73adff',
                        '#82b6ff',
                        '#92bfff',
                        '#a1c8ff',
                        '#b1d2ff',
                        '#c1dbff',
                        '#d0e4ff',
                        '#e0edff',
                        '#eff6ff'
                    ],
                    internal: [
                        '#ffa000',
                        '#ffaa1a',
                        '#ffb333',
                        '#ffbd4d',
                        '#ffc666',
                        '#ffd080',
                        '#ffd999',
                        '#ffe3b3',
                        '#ffeccc',
                        '#fff6e6'
                    ]
                }
            };
        }
        function extractDomainFromUrl(url) {
            return url.match(DASHBOARD.UI_DOMAIN_URL_REGEX) ? url.match(DASHBOARD.UI_DOMAIN_URL_REGEX)[0] : url;
        }
        function sortArrayByCleanAndMalicious(array) {
            const cleans = [];
            const malicious = [];
            _.forEach(array, function (item, index) {
                if (index % 2 === 0) {
                    cleans.push(item);
                }
                else {
                    malicious.push(item);
                }
            });
            return cleans.concat(malicious);
        }
        function isDataSetEmpty(dataSet) {
            return dataSet ? (dataSet.data ? (dataSet.data.length === 0) : true) : true;
        }
        function isYaxisEmpty(yAxis) {
            return yAxis ? (yAxis === 0) : true;
        }
        function mergeDataSetsByDate(targetDataSet, originDataSet) {
            const target = angular.copy(targetDataSet);
            const origin = angular.copy(originDataSet);
            _.each(target, function (value, key) {
                if (origin[key]) {
                    target[key] += origin[key];
                }
            });
            return _.isEmpty(target) ? origin : target;
        }
        function getCustomTooltip(config, route) {
            const routeType = {
                Inbound: 0,
                Outbound: 1,
                Journal: 2
            };
            return $sanitize(route + '<br/>' + config.series[0].name + ': <b>' + config.series[0].data[routeType[route]].y + '</b><br/>' + config.series[1].name + ': <b>' + config.series[1].data[routeType[route]].y + '</b>');
        }
        function getAttachmentProtectWrappedTooltip(key) {
            return "<div class='highcharts-tooltip-wrap'><span>" + key.x + '</span> <br/> <b>' + key.y + ' ' + $translate.instant('$I18N_ADMINISTRATION_MENU_ATTACHMENT_PROTECTION_CHART_ATTACHMENT_COUNT_TOOLTIP') + ' </b> </div>';
        }
        function getTruncatedLabel(key) {
            return (!_.isEmpty(key) ? ((key.length > 50) ? (key.substring(0, 50) + '...') : key) : '');
        }
    }]);
