"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UrlProtectionPolicySummaryStepComponentHelper = void 0;
const url_protection_inbound_settings_step_models_1 = require("../url-protection-inbound-settings-step/url-protection-inbound-settings-step.models");
const url_protection_inbound_settings_step_component_static_values_1 = require("../url-protection-inbound-settings-step/url-protection-inbound-settings-step.component.static-values");
const url_protection_outbound_settings_step_models_1 = require("../url-protection-outbound-settings-step/url-protection-outbound-settings-step.models");
const url_protection_journal_settings_step_models_1 = require("../url-protection-journal-settings-step/url-protection-journal-settings-step.models");
const url_protection_policy_details_step_static_values_1 = require("../url-protection-policy-details-step/url-protection-policy-details-step.static-values");
const policy_wizard_models_1 = require("../../../models/policy-wizard.models");
const url_protection_outbound_settings_step_component_static_values_1 = require("../url-protection-outbound-settings-step/url-protection-outbound-settings-step.component.static-values");
const url_protection_journal_settings_step_component_static_values_1 = require("../url-protection-journal-settings-step/url-protection-journal-settings-step.component.static-values");
const url_protection_applies_to_settings_step_models_1 = require("../url-protection-applies-to-settings-step/url-protection-applies-to-settings-step.models");
const url_protection_applies_to_settings_step_component_static_values_1 = require("../url-protection-applies-to-settings-step/url-protection-applies-to-settings-step.component.static-values");
class UrlProtectionPolicySummaryStepComponentHelper {
    constructor(translateService) {
        this.translateService = translateService;
    }
    // Details step
    getBypassApplicationLabel(detailsStep) {
        const key = Object.keys(policy_wizard_models_1.BypassApplicationOptions).find(enumKey => {
            return policy_wizard_models_1.BypassApplicationOptions[enumKey] === detailsStep.bypassPolicyApplication;
        });
        return this.translate(url_protection_policy_details_step_static_values_1.bypassApplicationOptions.find(option => option.id === policy_wizard_models_1.BypassApplicationOptions[key]).label);
    }
    getDurationRange(detailsStep, startEnd) {
        if (detailsStep.ulrProtectionPolicyDuration &&
            detailsStep.ulrProtectionPolicyDuration.id === policy_wizard_models_1.DurationDetailOption.USER_DEFINED) {
            return detailsStep.ulrProtectionPolicyDuration.range[startEnd];
        }
        return undefined;
    }
    isEndDateRangePresent(detailsStep) {
        const policyDuration = detailsStep.ulrProtectionPolicyDuration;
        return policyDuration && policyDuration.range && policyDuration.range.end !== null;
    }
    isContinuousDuration(detailsStep) {
        return (!!detailsStep.ulrProtectionPolicyDuration &&
            detailsStep.ulrProtectionPolicyDuration.id === policy_wizard_models_1.DurationDetailOption.CONTINUOUS);
    }
    // Inbound step
    getNotificationUrlFormatLabel(inboundStep) {
        return this.getSelectItemLabelForEnum(inboundStep, url_protection_inbound_settings_step_models_1.NotificationUrlFormatOptions, url_protection_inbound_settings_step_component_static_values_1.notificationUrlFormatValues, 'notificationUrlFormat');
    }
    getInboundRewriteModeLabel(inboundStep) {
        return this.getSelectItemLabelForEnum(inboundStep, url_protection_inbound_settings_step_models_1.RewriteModeOptions, url_protection_inbound_settings_step_component_static_values_1.rewriteModeValues, 'urlRewriteMode');
    }
    getInboundUrlCategoryScanningLabel(inboundStep) {
        return this.getSelectItemLabelForEnum(inboundStep, url_protection_inbound_settings_step_models_1.UrlCategoryScanningOptions, url_protection_inbound_settings_step_component_static_values_1.urlCategoryScanningValues, 'urlCategoryScanningMode');
    }
    getInboundMaliciousDetectActionLabel(inboundStep) {
        return this.getSelectItemLabelForEnum(inboundStep, url_protection_inbound_settings_step_models_1.MaliciousUrlDetectionActionOptions, url_protection_inbound_settings_step_component_static_values_1.maliciousUrlDetectionActionValues, 'maliciousUrlDetectionAction');
    }
    getInboundMessageSubjectProtectionLabel(inboundStep) {
        return this.getSelectItemLabelForEnum(inboundStep, url_protection_inbound_settings_step_models_1.MessageSubjectProtectionOptions, url_protection_inbound_settings_step_component_static_values_1.messageSubjectProtectionValues, 'messageSubjectProtection');
    }
    getInboundFileProtocolUrlHandelingLabel(inboundStep) {
        return this.getSelectItemLabelForEnum(inboundStep, url_protection_inbound_settings_step_models_1.FileProtocolUrlHandlingOptions, url_protection_inbound_settings_step_component_static_values_1.fileProtocolUrlHandlingValues, 'fileProtocolUrlHandling');
    }
    getUrlFileDownloadLabel(inboundStep) {
        return this.getSelectItemLabelForEnum(inboundStep, url_protection_inbound_settings_step_models_1.UrlFileDownloadOptions, url_protection_inbound_settings_step_component_static_values_1.urlFileDownloadValues, 'urlFileDownload');
    }
    getSimilarUrlDetection(inboundStep) {
        return this.getSelectItemLabelForEnum(inboundStep, url_protection_inbound_settings_step_models_1.SimilarUrlDetectionOptions, url_protection_inbound_settings_step_component_static_values_1.similarUrlDetectionValues, 'similarUrlDetection');
    }
    // Outbound step
    getOutboundUrlScanningModeLabel(outboundStep) {
        return this.getSelectItemLabelForEnum(outboundStep, url_protection_outbound_settings_step_models_1.UrlScanningModeOptions, url_protection_outbound_settings_step_component_static_values_1.urlScanningModeValues, 'urlScanningMode');
    }
    getUrlCategoryScanningModeLabel(outboundStep) {
        return this.getSelectItemLabelForEnum(outboundStep, url_protection_outbound_settings_step_models_1.UrlCategoryScanningModeOptions, url_protection_outbound_settings_step_component_static_values_1.urlCategoryScanningModeValues, 'urlCategoryScanningMode');
    }
    getGatewayActionLabel(outboundStep) {
        return this.getSelectItemLabelForEnum(outboundStep, url_protection_outbound_settings_step_models_1.GatewayActionOptions, url_protection_outbound_settings_step_component_static_values_1.gatewayActionValues, 'gatewayAction');
    }
    getGatewayFallbackActionLabel(outboundStep) {
        return this.getSelectItemLabelForEnum(outboundStep, url_protection_outbound_settings_step_models_1.GatewayFallbackActionOptions, url_protection_outbound_settings_step_component_static_values_1.gatewayFallbackActionValues, 'gatewayFallbackAction');
    }
    getSenderMailboxActionLabel(outboundStep) {
        return this.getSelectItemLabelForEnum(outboundStep, url_protection_outbound_settings_step_models_1.SenderMailboxActionOptions, url_protection_outbound_settings_step_component_static_values_1.senderMailboxActionValues, 'senderMailboxAction');
    }
    getSenderMailboxFallbackActionLabel(outboundStep) {
        return this.getSelectItemLabelForEnum(outboundStep, url_protection_outbound_settings_step_models_1.SenderMailboxFallbackActionOptions, url_protection_outbound_settings_step_component_static_values_1.senderMailboxFallbackActionValues, 'senderMailboxFallbackAction');
    }
    getOutboundUrlFileDownloadLabel(value) {
        let label = '';
        if (value === url_protection_outbound_settings_step_models_1.UrlFileDownloadOptions.CHECK) {
            label =
                '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.URL_FILE_DOWNLOAD.VALUES.CHECK';
        }
        else {
            label =
                '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.URL_FILE_DOWNLOAD.VALUES.NO_ACTION';
        }
        return this.translate(label);
    }
    // Journal step
    getJournalUrlScanningModeLabel(journalStep) {
        return this.getSelectItemLabelForEnum(journalStep, url_protection_journal_settings_step_models_1.UrlScanningModeOptions, url_protection_journal_settings_step_component_static_values_1.urlScanningModeValues, 'urlScanningMode');
    }
    getJournalUrlCategoryScanningModeLabel(journalStep) {
        return this.getSelectItemLabelForEnum(journalStep, url_protection_journal_settings_step_models_1.UrlCategoryScanningModeOptions, url_protection_journal_settings_step_component_static_values_1.urlCategoryScanningModeValues, 'urlCategoryScanningMode');
    }
    getJournalSenderMailboxActionLabel(journalStep) {
        return this.getSelectItemLabelForEnum(journalStep, url_protection_journal_settings_step_models_1.SenderMailboxActionOptions, url_protection_journal_settings_step_component_static_values_1.senderMailboxActionValues, 'senderMailboxAction');
    }
    getJournalSenderMailboxFallbackActionLabel(journalStep) {
        return this.getSelectItemLabelForEnum(journalStep, url_protection_journal_settings_step_models_1.SenderMailboxFallbackActionOptions, url_protection_journal_settings_step_component_static_values_1.senderMailboxFallbackActionValues, 'senderMailboxFallbackAction');
    }
    getJournalUrlFileDownloadLabel(value) {
        let label = '';
        if (value === url_protection_journal_settings_step_models_1.UrlFileDownloadOptions.CHECK) {
            label =
                '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.URL_FILE_DOWNLOAD.VALUES.CHECK';
        }
        else {
            label =
                '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.URL_FILE_DOWNLOAD.VALUES.NO_ACTION';
        }
        return this.translate(label);
    }
    // User Awareness
    getUserAwarenessPageSetOptionLabel(userAwarenessStep, config) {
        const option = config.pageSetOptions.find(pageSetOption => pageSetOption.value === userAwarenessStep.pageSetOption);
        return option ? option.label : '';
    }
    // Applies To
    getAddressesBasedOnLabel(appliesToStep) {
        return this.getSelectItemLabelForEnum(appliesToStep, url_protection_applies_to_settings_step_models_1.AddressesBasedOnOptions, url_protection_applies_to_settings_step_component_static_values_1.addressesBasedOnOptions, 'addressesBasedOn');
    }
    getAppliesFromToLabel(appliesToStep, fieldName, isAppliesFrom = true) {
        const label = this.getSelectItemLabelForEnum(appliesToStep, url_protection_applies_to_settings_step_models_1.AppliesFromToOptions, url_protection_applies_to_settings_step_component_static_values_1.appliesFromToOptions, fieldName);
        let value = '';
        const selectedOption = appliesToStep[fieldName];
        const isAddressField = selectedOption === url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.EMAIL_ADDRESS;
        const isDomainField = selectedOption === url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.DOMAIN;
        const isAttributesField = selectedOption === url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.ADDRESS_ATTRIBUTE;
        const isAddressGroupField = selectedOption === url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.ADDRESS_GROUP;
        if (isAddressField) {
            value = this.getAppliesFromToAddressValue(isAppliesFrom, appliesToStep);
        }
        else if (isDomainField) {
            value = this.getAppliesFromToDomainValue(isAppliesFrom, appliesToStep);
        }
        else if (isAttributesField) {
            value = this.getAppliesFromToAttributeLabel(isAppliesFrom, appliesToStep);
        }
        else if (isAddressGroupField) {
            value = this.getAppliesFromToAddressGroupDescriptionValue(isAppliesFrom, appliesToStep);
            value = `\n${value}`;
        }
        if (value) {
            value = `: ${value}`;
        }
        return label + value;
    }
    getAppliesFromToAddressValue(isAppliesFrom, step) {
        return isAppliesFrom ? step.appliesFromAddress : step.appliesToAddress;
    }
    getAppliesFromToDomainValue(isAppliesFrom, step) {
        return isAppliesFrom ? step.appliesFromDomain : step.appliesToDomain;
    }
    getAppliesFromToAttributeLabel(isAppliesFrom, step) {
        return isAppliesFrom ? step.fromWhereAttribute.label : step.toWhereAttribute.label;
    }
    getAppliesFromToAddressGroupDescriptionValue(isAppliesFrom, step) {
        return isAppliesFrom
            ? step.appliesFromAddressGroup && step.appliesFromAddressGroup.description
            : step.appliesToAddressGroup && step.appliesToAddressGroup.description;
    }
    showAppliesToAddresAttributeDetails(applies) {
        return applies === url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.ADDRESS_ATTRIBUTE;
    }
    getSelectItemLabelForEnum(step, enumValue, selectItemOptions, stepField) {
        const key = Object.keys(enumValue).find(enumKey => {
            return enumValue[enumKey] === step[stepField];
        });
        const option = selectItemOptions.find(selectItemOption => {
            return selectItemOption.value === enumValue[key];
        });
        return option ? this.translate(option.label) : '';
    }
    translate(value) {
        return this.translateService.instant(value);
    }
}
exports.UrlProtectionPolicySummaryStepComponentHelper = UrlProtectionPolicySummaryStepComponentHelper;
