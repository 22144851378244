"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const static_1 = require("@angular/upgrade/static");
const list_component_1 = require("./list/list.component");
angular
    .module('sync-recover.exchange.restore', [])
    .controller('ExchangeRestoreController', function () { })
    .directive('mcSyncRecoverExchangeRestoreList', static_1.downgradeComponent({
    component: list_component_1.ListComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider.state('sync-recover-exchange-restore-list', {
            url: '/sync-recover/exchange/restores',
            data: {
                capabilities: 'Development.Only',
                isFixedLayout: false,
                checkProgress: false,
                tabTitle: '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_EXPORT_AND_RESTORE_QUEUE_TITLE',
                breadcrumbs: ['$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_EXPORT_AND_RESTORE_QUEUE_TITLE']
            },
            views: {
                main: {
                    template: '<mc-sync-recover-exchange-restore-list></mc-sync-recover-exchange-restore-list>',
                    controller: 'ExchangeRestoreController'
                }
            }
        });
    }
]);
