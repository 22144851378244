"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
// html and css 
require("./access-denied.less");
require("./access-denied.tpl.html");
(function () {
    'use strict';
    /* ======================================================== *\

      = access is denied!
      = craig

   \* ======================================================== */
    /* -------------------------------------------------------- *\
      - configuration
   \* -------------------------------------------------------- */
    angular
        .module('access-denied', [])
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('access-denied', {
                url: '/access-denied',
                data: {
                    isFixedLayout: true,
                    trackProgress: true,
                    backgroundClass: 'mc-template-center-container',
                    breadcrumbs: ['$I18N_ACCESS_DENIED_TAB_TITLE'],
                    asideTitle: '$I18N_ACCESS_DENIED_TAB_TITLE',
                    tabId: 'access-denied',
                    tabTitle: '$I18N_ACCESS_DENIED_TAB_TITLE',
                    tabHide: false,
                    tabReload: 'true'
                },
                views: {
                    'main': {
                        templateUrl: 'access-denied/access-denied.tpl.html'
                    }
                }
            });
        }]);
}());
