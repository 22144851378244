"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TopQueues = void 0;
class TopQueues {
    constructor(all, valid, invalid, queueType, totalCount, newInLastSevenDays, pendingCount, pendingPercentage) {
        this.all = all;
        this.valid = valid;
        this.invalid = invalid;
        this.queueType = queueType;
        this.totalCount = totalCount;
        this.newInLastSevenDays = newInLastSevenDays;
        this.pendingCount = pendingCount;
        this.pendingPercentage = pendingPercentage;
    }
}
exports.TopQueues = TopQueues;
