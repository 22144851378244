"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BiPolicySettingsStepComponent = void 0;
const core_1 = require("@angular/core");
const operators_1 = require("rxjs/operators");
class BiPolicySettingsStepComponent {
    constructor(fb) {
        this.fb = fb;
        this.updateSettings = new core_1.EventEmitter();
    }
    ngOnInit() {
        this.biPolicySettingsForm = this.fb.group({
            clipboardTransferOut: this.settings.clipboardTransferOut,
            clipboardTransferIn: this.settings.clipboardTransferIn,
            sessionReadOnly: this.settings.sessionReadOnly,
            enterText: this.settings.enterText,
            defaultPolicy: this.settings.defaultPolicy
        });
        this.subscription = this.biPolicySettingsForm.valueChanges
            .pipe(operators_1.debounceTime(200))
            .subscribe(() => {
            this.update();
        });
    }
    update() {
        if (!this.biPolicySettingsForm.get('enterText').value &&
            this.biPolicySettingsForm.get('clipboardTransferIn').value) {
            this.biPolicySettingsForm.patchValue({ clipboardTransferIn: false });
        }
        this.updateSettings.emit(this.biPolicySettingsForm.value);
    }
    isValid() {
        return true;
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
exports.BiPolicySettingsStepComponent = BiPolicySettingsStepComponent;
