"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeviceGroupTargetSidebarContainerComponent = void 0;
const user_location_sidebar_actions_1 = require("../../../../components/user-location-selector/actions/user-location-sidebar.actions");
const policy_actions_1 = require("../../../policies/actions/policy.actions");
const device_groups_models_1 = require("../../models/device-groups.models");
class DeviceGroupTargetSidebarContainerComponent {
    constructor(store, overlayData, swgDevicesListApiService) {
        this.store = store;
        this.overlayData = overlayData;
        this.swgDevicesListApiService = swgDevicesListApiService;
        this.deviceListDisplayType = device_groups_models_1.DeviceListDisplayType;
        this.showDeviceList = this.overlayData.condition === 'web_device';
        this.addDevicesSubscription = this.swgDevicesListApiService.addDevicesInGroupSubject.subscribe((devices) => {
            this.store.dispatch(new policy_actions_1.SwgUpdateAffectedDevices(devices));
        });
    }
    getTitle() {
        return this.showDeviceList
            ? '$I18N_DEVICE_GROUPS_LIST.SIDEBAR.SELECT_DEVICES_HEADER'
            : '$I18N_DEVICE_GROUPS_LIST.SIDEBAR.SELECT_DEVICE_GROUPS_HEADER';
    }
    addDeviceGroupsToTargets(deviceGroups) {
        this.store.dispatch(new policy_actions_1.SwgUpdateAffectedDeviceGroups(deviceGroups));
    }
    close() {
        this.store.dispatch(new user_location_sidebar_actions_1.UserLocationAppliesToClose());
    }
    ngOnDestroy() {
        if (this.removeDevicesSubscription) {
            this.removeDevicesSubscription.unsubscribe();
        }
        if (this.addDevicesSubscription) {
            this.addDevicesSubscription.unsubscribe();
        }
    }
}
exports.DeviceGroupTargetSidebarContainerComponent = DeviceGroupTargetSidebarContainerComponent;
