"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogDetailsComponent = void 0;
const models_1 = require("../../models");
const LogsHelper_1 = require("../common/LogsHelper");
const log_list_actions_1 = require("../../actions/log-list.actions");
class LogDetailsComponent {
    constructor(translate, capabilitiesService, store) {
        this.translate = translate;
        this.capabilitiesService = capabilitiesService;
        this.store = store;
        this.scannerTypes = models_1.ScanDetailTypes;
        this.advancedSimilarityChecksTypes = models_1.AdvancedSimilarityChecks;
        this.avScanningTypes = models_1.AvScanning;
        this.machineLearningTypes = models_1.MachineLearning;
        this.dangerousFileExtTypes = models_1.DangerousFileExt;
        this.customManagedUrlsTypes = models_1.CustomerManagedUrls;
        this.htmlContentChecksTypes = models_1.HtmlContentChecks;
        this.urlReputationScanTypes = models_1.UrlReputationScan;
        this.sandboxingTypes = models_1.Sandboxing;
        this.advancedPhishingTypes = models_1.AdvancedPhishing;
        this.logsHelper = new LogsHelper_1.LogsHelper(this.translate);
    }
    ngOnInit() {
        this.capabilitiesService
            .hasCapability('Temporary.Services.UrlProtection.ScanDetails')
            .subscribe(hasScanDetailsPermission => (this.scanDetailsPermission = hasScanDetailsPermission));
        this.capabilitiesService
            .hasCapability('Temporary.Services.URL.Reporting.Enabled')
            .subscribe(hasScanReportPermission => (this.scanReportPermission = hasScanReportPermission));
    }
    setEmailParts(parts) {
        return parts.length ? parts.join(', ') : '';
    }
    displayScanResultLabel(selectedRow) {
        return this.logsHelper.getScanResultLabel(selectedRow);
    }
    displayScanResultIcon(selectedRow) {
        return this.logsHelper.getScanResultIconClass(selectedRow.scanResult);
    }
    displayRouteLabel(route) {
        return this.logsHelper.getRouteLabel(route);
    }
    checkScanDetails(tagData, key) {
        var _a;
        if (this.scanReportPermission) {
            if (tagData &&
                tagData.hasOwnProperty(key) &&
                key === this.scannerTypes.CUSTOMERMANAGEDURLS &&
                (((_a = this.rowData.tagMap) === null || _a === void 0 ? void 0 : _a.CustomerManagedUrls) ? this.rowData.tagMap.CustomerManagedUrls[this.customManagedUrlsTypes.BLOCKLISTED] ||
                    this.rowData.tagMap.CustomerManagedUrls[this.customManagedUrlsTypes.ALLOWLISTED]
                    : false)) {
                this.store.dispatch(new log_list_actions_1.ShowMangedURLBannerForCheckTabAction(this.rowData.tagMap.CustomerManagedUrls[this.customManagedUrlsTypes.MANAGEDURLENTRY][0]));
            }
        }
        else {
            return false;
        }
        if (this.scanDetailsPermission) {
            return tagData && tagData.hasOwnProperty(key);
        }
        else {
            return false;
        }
    }
    getScanDetailsText(rowData) {
        if (rowData.url && rowData.similarDomain && rowData.heuristicDomainSource) {
            return this.translate.instant('$I18N_SERVICES_URL_PROTECTION_LOGS_DETAILS.SCAN_DETAILS_TEXT', {
                url: rowData.url,
                similarDomain: rowData.similarDomain,
                domainSource: rowData.heuristicDomainSource
            });
        }
        return null;
    }
    getCredentialTheftEvidence(rowData) {
        if (rowData.advancedPhishingResult) {
            return rowData.advancedPhishingResult['CredentialTheftEvidence'];
        }
        return null;
    }
    hasNoScanDetailsButTheftEvidence(rowData) {
        if (this.getScanDetailsText(rowData) || !this.getCredentialTheftEvidence(rowData)) {
            return '-';
        }
        return '';
    }
    urlClick(urlLog) {
        this.store.dispatch(new log_list_actions_1.UrlProtectionBrowserIsolationAction(urlLog));
    }
}
exports.LogDetailsComponent = LogDetailsComponent;
