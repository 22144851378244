"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReleaseLogAsideComponent = void 0;
const actions = require("../../actions");
const reducers = require("../../reducers");
class ReleaseLogAsideComponent {
    constructor(store) {
        this.store = store;
    }
    ngOnInit() {
        this.releaseLog$ = this.store.select(reducers.getReleaseLogsSelectedRow);
        this.end$ = this.store.select(reducers.getDetailPageTotal);
        this.total$ = this.store.select(reducers.getDetailPageTotal);
        this.selectedRowIndex$ = this.store.select(reducers.getSelectedRowIndex);
        this.selectedRowIndex$.subscribe(data => {
            this.currentItemIndex = data;
        });
        this.isPaginationLoading$ = this.store.select(reducers.isReleaseLogsLoading);
    }
    close() {
        this.store.dispatch(new actions.ReleaseLogCloseDetailAction());
    }
    onPaginate(currentItemIndex) {
        this.store.dispatch(new actions.ReleaseLogAsidePaginationAction(currentItemIndex));
    }
}
exports.ReleaseLogAsideComponent = ReleaseLogAsideComponent;
