<mc-layout-detail-tabs keyTitle="{{ '$18N_SYNC_RECOVER_EXCHANGE_MAILBOX_DETAIL.TITLE' | translate:{mailboxUserName: getName(), mailboxUserMail: getMail() } }}"
  keyDescription="" kbUrl="http://www.mimecast.com/" showFavourite="true">
  <mc-extra-container>

    <button class="btn btn-primary" (click)="openExport()">Open Export</button>
    <button class="btn btn-primary" (click)="openRestore()">Open Restore</button>

  </mc-extra-container>

  <mc-tab-group [initialActiveTabIndex]="0">
    <mc-tab name="Last Sync">
      <!-- <mc-sync-recover-exchange-tree [folders]="folders$ | async" [contents]="emails$ | async" (onNodeClicked)="getLastSyncFolders($event)"></mc-sync-recover-exchange-tree> -->
    </mc-tab>
    <mc-tab name="Archive">
      WIP
    </mc-tab>
    <mc-tab name="Deleted Files">
      WIP
    </mc-tab>
    <mc-tab name="Overview">
      WIP
    </mc-tab>
  </mc-tab-group>
</mc-layout-detail-tabs>
