'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const _ = require("lodash");
/**
 * Created by psambandam on 15/02/16.
 */
document.createElement('mc-recent-activity');
angular.module('recent-activity.directive', [])
    .directive('mcRecentActivity', ['recentActivityService', function (recentActivityService) {
        return {
            restrict: 'E',
            templateUrl: 'components/recent-activity/recent-activity.tpl.html',
            scope: {
                mcTitle: '@',
                activityStats: '&',
                errorMessage: '@'
            },
            link($scope) {
                $scope.stats = [];
                $scope.isLoading = true;
                $scope.hasError = false;
                if (angular.isDefined($scope.errorMessage) && !_.isEmpty($scope.errorMessage)) {
                    $scope.hasError = true;
                }
                $scope.activityStats().then(function (stats) {
                    angular.forEach(stats, function (item) {
                        const stat = recentActivityService.stats[item.description];
                        $scope.stats[stat.order] = angular.extend(item, stat);
                    });
                    $scope.isLoading = false;
                });
            }
        };
    }]);
