"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgEndpointsSettingsGeneratePasswordModalComponent = void 0;
const endpoints_models_1 = require("../../../models/endpoints.models");
const settings_actions_1 = require("../../../actions/settings.actions");
class SwgEndpointsSettingsGeneratePasswordModalComponent {
    constructor(store, overlayRef, passwordType) {
        this.store = store;
        this.overlayRef = overlayRef;
        this.passwordType = passwordType;
    }
    onClose() {
        this.overlayRef.close();
    }
    onGeneratePassword() {
        if (this.passwordType === endpoints_models_1.SwgSettingsPasswordType.ANTI_TAMPER) {
            this.store.dispatch(new settings_actions_1.SwgEndpointsSettingsGetTamperProtectedPasswordAction(true));
        }
        else {
            this.store.dispatch(new settings_actions_1.SwgEndpointsSettingsGetUninstallPasswordAction(true));
        }
    }
}
exports.SwgEndpointsSettingsGeneratePasswordModalComponent = SwgEndpointsSettingsGeneratePasswordModalComponent;
