"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaginationSet = exports.PaginationSetPreviousAndNext = exports.PaginationSetSelectedRow = exports.PAGINATION_SET = exports.PAGINATION_SET_PREV_AND_NEXT = exports.PAGINATION_SET_SELECTED_ROW = void 0;
exports.PAGINATION_SET_SELECTED_ROW = '[Remediation] set selected row';
exports.PAGINATION_SET_PREV_AND_NEXT = '[Remediation] set previous and next row';
exports.PAGINATION_SET = '[Remediation] set';
class PaginationSetSelectedRow {
    constructor(row) {
        this.row = row;
        this.type = exports.PAGINATION_SET_SELECTED_ROW;
    }
}
exports.PaginationSetSelectedRow = PaginationSetSelectedRow;
class PaginationSetPreviousAndNext {
    constructor(row) {
        this.row = row;
        this.type = exports.PAGINATION_SET_PREV_AND_NEXT;
    }
}
exports.PaginationSetPreviousAndNext = PaginationSetPreviousAndNext;
class PaginationSet {
    constructor(pagination) {
        this.pagination = pagination;
        this.type = exports.PAGINATION_SET;
    }
}
exports.PaginationSet = PaginationSet;
