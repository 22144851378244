"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const url_page_component_1 = require("./containers/url-page.component");
angular
    .module('services.managed-urls-new', [])
    .controller('ManagedUrlsPageComponent', [
    '$stateParams',
    '$scope',
    function ($stateParams, $scope) {
        $scope.stateParams = $stateParams;
    }
])
    .directive('mcManagedUrls', static_1.downgradeComponent({
    component: url_page_component_1.ManagedUrlsPageComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider.state('url-protection-managed-urls', {
            url: '/services/url-protection/managed-urls',
            data: {
                tabTitle: '$I18N_SERVICES_URL_PROTECTION.MANAGED_URLS.TAB_TITLE',
                isFixedLayout: false,
                checkProgress: false,
                tabReload: false,
                capabilities: `
          (Temporary.Services.UrlProtection.ManagedUrls || Temporary.Services.UrlProtection.ManagedUrls.Redirect) &&
          (Permission.SERVICES_TTP_URL_PROTECT_READ || Permission.SERVICES_TTP_URL_PROTECT_EDIT)`,
                breadcrumbs: [
                    {
                        label: '$I18N_SERVICES_BREADCRUMBS_FIRST_LEVEL',
                        stateName: '',
                        stateParams: { tabName: 'overview' }
                    },
                    {
                        label: '$I18N_SERVICES_URL_PROTECTION.BREADCRUMBS.SECOND_LEVEL',
                        stateName: '',
                        stateParams: { tabName: 'overview' }
                    }
                ]
            },
            views: {
                main: {
                    template: '<mc-managed-urls></mc-managed-urls>',
                    controller: 'ManagedUrlsPageComponent'
                }
            }
        });
    }
]);
