"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducer = exports.initialState = void 0;
const widget_urls_scan_day_action_1 = require("../actions/widget-urls-scan-day/widget-urls-scan-day.action");
exports.initialState = {
    loading: false,
    hasError: false,
    widgetScansPerDay: {
        routing: []
    }
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case widget_urls_scan_day_action_1.GET_WIDGET_DATA_ATTEMPT:
            return Object.assign(Object.assign({}, state), { loading: true, hasError: false });
        case widget_urls_scan_day_action_1.GET_WIDGET_DATA_FAIL:
            return Object.assign(Object.assign({}, state), { loading: false, hasError: true });
        case widget_urls_scan_day_action_1.GET_WIDGET_DATA_SUCCESS:
            return Object.assign(Object.assign({}, state), { loading: false, hasError: false });
        default:
            return Object.assign({}, state);
    }
}
exports.reducer = reducer;
