"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OktaIntegrationService = void 0;
const common_1 = require("app-new/api-applications/services/common");
const BASE_URL = '/api/integration/scim/okta';
class OktaIntegrationService {
    constructor(http) {
        this.http = http;
    }
    createOktaIntegration(integration) {
        return this.http
            .post(`${BASE_URL}/create-integration`, integration)
            .pipe(common_1.firstResult());
    }
    updateOktaIntegration(integration) {
        return this.http
            .post(`${BASE_URL}/update-integration`, integration)
            .pipe(common_1.firstResult());
    }
    getOktaIntegration(id) {
        return this.http
            .post(`${BASE_URL}/get-integration`, { id })
            .pipe(common_1.firstResult());
    }
    deleteOktaIntegration(integration) {
        return this.http
            .post('/api/integration/delete-integration', integration)
            .pipe(common_1.firstResult());
    }
    buildRequest(configuration, rotateApiToken) {
        return {
            id: configuration.id,
            enabled: configuration.enabled,
            rootFolder: configuration.rootFolder,
            name: configuration.name,
            description: configuration.description,
            contactAddresses: configuration.contactAddresses,
            cell: configuration.cell,
            folders: configuration.folderList,
            rotateToken: rotateApiToken
        };
    }
}
exports.OktaIntegrationService = OktaIntegrationService;
