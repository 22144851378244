"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecallEffects = void 0;
const actions = require("../actions/recall.action");
const effects_1 = require("@ngrx/effects");
const operators_1 = require("rxjs/operators");
const confirmation_modal_component_1 = require("../components/confirmation-modal/confirmation-modal.component");
const notification_actions_1 = require("app-new/components/notification/actions/notification.actions");
class RecallEffects {
    constructor(actions$, secureMessagingService, modalService, translateService) {
        this.actions$ = actions$;
        this.secureMessagingService = secureMessagingService;
        this.modalService = modalService;
        this.translateService = translateService;
        this.openConfirmationModal$ = this.actions$.pipe(effects_1.ofType(actions.RECALL), operators_1.tap((action) => {
            this.confirmationModalInstance = this.modalService.open(confirmation_modal_component_1.ConfirmationModalComponent, {
                size: 'md',
                hasBackdrop: true,
                disableClose: true,
                data: { recall: action.payload }
            });
        }));
        this.confirmRecall$ = this.actions$.pipe(effects_1.ofType(actions.RECALL_YES), operators_1.map((action) => {
            this.confirmationModalInstance.close();
            this.confirmationModalInstance = undefined;
            return new actions.RecallSuccessAction(action.payload);
        }));
        this.cancelRecall$ = this.actions$.pipe(effects_1.ofType(actions.RECALL_NO), operators_1.tap(() => {
            this.confirmationModalInstance.close();
            this.confirmationModalInstance = undefined;
        }));
        this.recall$ = this.actions$.pipe(effects_1.ofType(actions.RECALL_SUCCESS), operators_1.map((action) => action.payload), operators_1.switchMap(request => {
            return this.secureMessagingService.recall(request).pipe(operators_1.mergeMap(() => {
                return [
                    new notification_actions_1.NotificationShowAction({
                        type: 'success',
                        config: {
                            msg: this.translateService.instant('$I18N_SECURE_MESSAGING_MESSAGES.SUCCESS')
                        }
                    })
                ];
            }), operators_1.catchError(() => [
                new notification_actions_1.NotificationShowAction({
                    type: 'error',
                    config: {
                        msg: this.translateService.instant('$I18N_SECURE_MESSAGING_MESSAGES.FAILURE')
                    }
                })
            ]));
        }));
    }
}
__decorate([
    effects_1.Effect({ dispatch: false })
], RecallEffects.prototype, "openConfirmationModal$", void 0);
__decorate([
    effects_1.Effect()
], RecallEffects.prototype, "confirmRecall$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], RecallEffects.prototype, "cancelRecall$", void 0);
__decorate([
    effects_1.Effect()
], RecallEffects.prototype, "recall$", void 0);
exports.RecallEffects = RecallEffects;
