"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateFormComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const operators_1 = require("rxjs/operators");
const validators_1 = require("../../validators/validators");
class TemplateFormComponent {
    constructor(fb) {
        this.fb = fb;
        this.templateId = 0;
        this.showPage = new core_1.EventEmitter();
        this.valueChanges = new core_1.EventEmitter();
        this.form = this.fb.group({
            id: '',
            templateName: ['', [forms_1.Validators.required, validators_1.validateContainsWords, forms_1.Validators.maxLength(150)]],
            userTemplate: this.fb.group({
                title: ['', [validators_1.validateContainsWords, forms_1.Validators.maxLength(150)]],
                subtitle: ['', [validators_1.validateContainsWords, forms_1.Validators.maxLength(150)]]
            }),
            safeLinkIdentified: this.fb.group({
                title: ['', [validators_1.validateContainsWords, forms_1.Validators.maxLength(150)]],
                subtitle: ['', [validators_1.validateContainsWords, forms_1.Validators.maxLength(150)]]
            }),
            safeLinkMisidentified: this.fb.group({
                title: ['', [validators_1.validateContainsWords, forms_1.Validators.maxLength(150)]],
                subtitle: ['', [validators_1.validateContainsWords, forms_1.Validators.maxLength(150)]]
            }),
            harmfulLinkIndentified: this.fb.group({
                title: ['', [validators_1.validateContainsWords, forms_1.Validators.maxLength(150)]],
                subtitle: ['', [validators_1.validateContainsWords, forms_1.Validators.maxLength(150)]]
            }),
            harmfulLinkMisindentifiedWarning: this.fb.group({
                title: ['', [validators_1.validateContainsWords, forms_1.Validators.maxLength(150)]],
                subtitle: ['', [validators_1.validateContainsWords, forms_1.Validators.maxLength(150)]]
            }),
            harmfulLinkMisindentifiedBlock: this.fb.group({
                title: ['', [validators_1.validateContainsWords, forms_1.Validators.maxLength(150)]],
                subtitle: ['', [validators_1.validateContainsWords, forms_1.Validators.maxLength(150)]]
            }),
            safetyTipType: ['mimecastSafetyTips', [forms_1.Validators.required]],
            safetyTips: [[], [forms_1.Validators.nullValidator]]
        }, { validator: validators_1.validateTipType });
    }
    set value(template) {
        if (template && this.form) {
            this.form.patchValue(template, { emitEvent: true });
        }
    }
    get value() {
        return this.form && this.form.value;
    }
    ngOnInit() {
        this.form.valueChanges
            .pipe(operators_1.debounceTime(300))
            .subscribe((newFormValue) => this.onFormValueChanges(newFormValue));
        this.onFormValueChanges(this.form.value);
    }
    onFormValueChanges(newFormValue) {
        this.valueChanges.emit(newFormValue);
    }
}
exports.TemplateFormComponent = TemplateFormComponent;
