"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IncidentSummaryEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const incidentSummaryAction = require("../../actions/incidents/incident-summary.action");
const incident_summary_action_1 = require("../../actions/incidents/incident-summary.action");
const remediate_messages_action_1 = require("../../actions/remediate-messages/remediate-messages.action");
const reducers = require("../../reducers");
const notification_actions_1 = require("../../../components/notification/actions/notification.actions");
class IncidentSummaryEffects {
    constructor(actions$, remediationApiService, notificationService, translateService, store) {
        this.actions$ = actions$;
        this.remediationApiService = remediationApiService;
        this.notificationService = notificationService;
        this.translateService = translateService;
        this.store = store;
        this.incidentSummary = this.actions$.pipe(effects_1.ofType(incidentSummaryAction.REMEDIATION_INCIDENT_SUMMARY_GET), operators_1.switchMap((action) => {
            const config = {
                id: action.payload.id
            };
            return this.remediationApiService.incidentSummary(config).pipe(operators_1.map((response) => {
                return new incidentSummaryAction.RemediationIncidentSummarySuccessAction(response);
            }), operators_1.catchError(() => this.failAction(new incidentSummaryAction.RemediationIncidentSummaryFailureAction())));
        }));
        this.cancelDeviceRemediation = this.actions$.pipe(effects_1.ofType(remediate_messages_action_1.CANCEL_DEVICE_REMEDIATION), operators_1.withLatestFrom(this.store.select(reducers.incidentSummary)), operators_1.switchMap(([, incidentSummary]) => {
            return this.remediationApiService.cancelDeviceRemediation(incidentSummary.code).pipe(operators_1.mergeMap(() => {
                return [
                    new remediate_messages_action_1.CloseCancelDeviceRemediatonPopup(),
                    new incident_summary_action_1.RemediationIncidentSummaryGetAction({ id: incidentSummary.id })
                ];
            }), operators_1.catchError(error => rxjs_1.merge(rxjs_1.of(this.getNotificationAction(error)), rxjs_1.of(new remediate_messages_action_1.CloseCancelDeviceRemediatonPopup()))));
        }));
        this.incidentSummarySuccess = this.actions$.pipe(effects_1.ofType(incidentSummaryAction.REMEDIATION_INCIDENT_SUMMARY_SUCCESS), operators_1.switchMap(() => {
            return rxjs_1.of(new incidentSummaryAction.RemediationIncidentSummaryShowResultsAction());
        }));
        this.incidentFailureSummary = this.actions$.pipe(effects_1.ofType(incidentSummaryAction.GET_INCIDENT_FAILURE_SUMMARY), operators_1.switchMap((action) => {
            return this.remediationApiService.getIncidentFailureSummary(action.payload).pipe(operators_1.map((response) => new incidentSummaryAction.GetIncidentFailureSummarySuccessAction(response)), operators_1.catchError(() => this.failAction(new incidentSummaryAction.GetIncidentFailureSummaryFailureAction())));
        }));
    }
    getNotificationAction(error) {
        const msg = error.fail
            ? error.fail[0].errors[0].message
            : this.translateService.instant('$I18N_REMEDIATION.ERRORS.GENERIC');
        return new notification_actions_1.NotificationShowAction({
            type: 'error',
            config: {
                msg
            }
        });
    }
    failAction(action) {
        return rxjs_1.of(action).pipe(operators_1.tap(() => {
            this.notificationService.error({
                msg: this.translateService.instant('$I18N_REMEDIATION.ERRORS.GENERIC')
            });
        }));
    }
}
__decorate([
    effects_1.Effect()
], IncidentSummaryEffects.prototype, "incidentSummary", void 0);
__decorate([
    effects_1.Effect()
], IncidentSummaryEffects.prototype, "cancelDeviceRemediation", void 0);
__decorate([
    effects_1.Effect()
], IncidentSummaryEffects.prototype, "incidentSummarySuccess", void 0);
__decorate([
    effects_1.Effect()
], IncidentSummaryEffects.prototype, "incidentFailureSummary", void 0);
exports.IncidentSummaryEffects = IncidentSummaryEffects;
