"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
// html and css 
require("./roles-member-list.tpl.html");
(function () {
    'use strict';
    angular.module('account.roles-member-list', [])
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('roles-list', {
                url: '/services/account/roles-list',
                data: {
                    capabilities: 'Permission.ROLES_READ',
                    tabId: 'roles-list-tabid',
                    tabTitle: '$I18N_ACCOUNT_ROLES_LIST_TAB_TITLE',
                    tabHide: false,
                    tabReload: 'true'
                },
                views: {
                    'main': {
                        templateUrl: 'services/account/roles/roles-member-list/roles-member-list.tpl.html'
                    }
                }
            });
        }]);
}());
