"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppliesToOptionsEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const applies_to_locations_actions_1 = require("../actions/applies-to-locations.actions");
const applies_to_users_actions_1 = require("../actions/applies-to-users.actions");
class AppliesToOptionsEffects {
    constructor(actions$, store, swgPolicyService) {
        this.actions$ = actions$;
        this.store = store;
        this.swgPolicyService = swgPolicyService;
        this.loadAppliesToLocations$ = this.actions$.pipe(effects_1.ofType(applies_to_locations_actions_1.LOAD_APPLIES_TO_LOCATIONS), operators_1.switchMap((action) => {
            return this.swgPolicyService.getLocations(action.payload).pipe(operators_1.map((response) => {
                return new applies_to_locations_actions_1.LoadAppliesToLocationsSuccess(this.swgPolicyService.mapAppliesToResponse(response));
            }), operators_1.catchError(() => rxjs_1.of(new applies_to_locations_actions_1.LoadAppliesToLocationsFailure())));
        }));
        this.loadAppliesToUsers$ = this.actions$.pipe(effects_1.ofType(applies_to_users_actions_1.LOAD_APPLIES_TO_USERS), operators_1.switchMap((action) => {
            return this.swgPolicyService.fetchUsers(action.payload).pipe(operators_1.map((response) => {
                return new applies_to_users_actions_1.LoadAppliesToUsersSuccess(this.swgPolicyService.mapAppliesToResponse(response));
            }), operators_1.catchError(() => rxjs_1.of(new applies_to_users_actions_1.LoadAppliesToUsersFailure())));
        }));
        this.appliesToLocationsPagination$ = this.actions$.pipe(effects_1.ofType(applies_to_locations_actions_1.LOAD_APPLIES_TO_LOCATIONS_PAGINATION), operators_1.switchMap((action) => {
            return this.swgPolicyService.getLocations(action.payload).pipe(operators_1.map((response) => {
                return new applies_to_locations_actions_1.LoadAppliesToLocationsSuccess(this.swgPolicyService.mapAppliesToResponse(response));
            }), operators_1.catchError(() => rxjs_1.of(new applies_to_locations_actions_1.LoadAppliesToLocationsFailure())));
        }));
        this.appliesToUsersPagination$ = this.actions$.pipe(effects_1.ofType(applies_to_users_actions_1.LOAD_APPLIES_TO_USERS_PAGINATION), operators_1.switchMap((action) => {
            return this.swgPolicyService.fetchUsers(action.payload).pipe(operators_1.map((response) => {
                return new applies_to_users_actions_1.LoadAppliesToUsersSuccess(this.swgPolicyService.mapAppliesToResponse(response));
            }), operators_1.catchError(() => rxjs_1.of(new applies_to_users_actions_1.LoadAppliesToUsersFailure())));
        }));
    }
}
__decorate([
    effects_1.Effect()
], AppliesToOptionsEffects.prototype, "loadAppliesToLocations$", void 0);
__decorate([
    effects_1.Effect()
], AppliesToOptionsEffects.prototype, "loadAppliesToUsers$", void 0);
__decorate([
    effects_1.Effect()
], AppliesToOptionsEffects.prototype, "appliesToLocationsPagination$", void 0);
__decorate([
    effects_1.Effect()
], AppliesToOptionsEffects.prototype, "appliesToUsersPagination$", void 0);
exports.AppliesToOptionsEffects = AppliesToOptionsEffects;
