"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegionProviderPipe = void 0;
class RegionProviderPipe {
    constructor(translateService) {
        this.translateService = translateService;
    }
    transform(input) {
        switch (input) {
            case 'GLOBAL':
                return this.translateService.instant('$I18N_WILDFIRE_INTEGRATION_REGION_PROVIDER_PIPE.' + input);
            case 'EU':
                return this.translateService.instant('$I18N_WILDFIRE_INTEGRATION_REGION_PROVIDER_PIPE.' + input);
            case 'JP':
                return this.translateService.instant('$I18N_WILDFIRE_INTEGRATION_REGION_PROVIDER_PIPE.' + input);
            case 'SG':
                return this.translateService.instant('$I18N_WILDFIRE_INTEGRATION_REGION_PROVIDER_PIPE.' + input);
            case 'UK':
                return this.translateService.instant('$I18N_WILDFIRE_INTEGRATION_REGION_PROVIDER_PIPE.' + input);
            default:
            case 'unknown':
                return this.translateService.instant('$I18N_WILDFIRE_INTEGRATION_REGION_PROVIDER_PIPE.OTHER');
        }
    }
}
exports.RegionProviderPipe = RegionProviderPipe;
