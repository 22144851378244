"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiIntegrationOktaSummaryStepComponent = void 0;
const core_1 = require("@angular/core");
const folder_helper_1 = require("app-new/api-applications/utils/folder-helper");
const operators_1 = require("rxjs/operators");
class ApiIntegrationOktaSummaryStepComponent {
    constructor(fb) {
        this.fb = fb;
        this.onSwitchOktaIntegrationStatus = new core_1.EventEmitter();
        this.onSwitchRotateToken = new core_1.EventEmitter();
        this.form = this.fb.group({
            integrationStatus: true,
            rotate: false
        });
    }
    ngOnInit() {
        if (!!this.oktaIntegration) {
            this.form.patchValue({
                integrationStatus: this.oktaIntegration.enabled,
                rotate: this.rotateApiToken
            });
        }
        this.formSubscription = this.form.valueChanges.pipe(operators_1.debounceTime(200)).subscribe(() => {
            this.update();
        });
    }
    update() {
        this.onSwitchOktaIntegrationStatus.emit(this.form.value.integrationStatus);
        if (this.hasCreatedIntegration) {
            this.onSwitchRotateToken.emit(this.form.value.rotate);
        }
    }
    get description() {
        return folder_helper_1.default.createDescription(this.oktaIntegration.folderList, this.oktaIntegration.rootFolder);
    }
    oktaEmailList() {
        if (this.oktaIntegration) {
            return this.oktaIntegration.contactAddresses;
        }
        return [];
    }
    isValid() {
        return true;
    }
    get header() {
        return this.hasCreatedIntegration
            ? '$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.SUMMARY.HEADER_EDIT'
            : '$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.SUMMARY.HEADER';
    }
}
exports.ApiIntegrationOktaSummaryStepComponent = ApiIntegrationOktaSummaryStepComponent;
