"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SaveRequestBeforePasswordAction = exports.SaveSecondFactorAction = exports.SaveMimeAuthEnabledAction = exports.SaveCloudAuthenticationAction = exports.SaveAlternateDomainSuffixAction = exports.SaveCasServerAction = exports.SaveDomainAction = exports.SAVE_REQUEST_SECOND_FACTOR_FIRST = exports.SAVE_SECOND_FACTOR_OPTION = exports.SAVE_MIME_AUTH_ENABLED = exports.SAVE_CLOUD_AUTHENTICATION = exports.SAVE_ALTERNATE_DOMAIN_SUFFIX = exports.SAVE_CAS_SERVER = exports.SAVE_DOMAIN = void 0;
exports.SAVE_DOMAIN = '[IdentityPolicyWizard AuthenticationMethodsStep] Save Domain';
exports.SAVE_CAS_SERVER = '[IdentityPolicyWizard AuthenticationMethodsStep] Save Cas Server';
exports.SAVE_ALTERNATE_DOMAIN_SUFFIX = '[IdentityPolicyWizard AuthenticationMethodsStep] Save Alternate Domain Suffix';
exports.SAVE_CLOUD_AUTHENTICATION = '[IdentityPolicyWizard AuthenticationMethodsStep] Save Cloud Authentication';
exports.SAVE_MIME_AUTH_ENABLED = '[IdentityPolicyWizard AuthenticationMethodsStep] Save MimeAuth Enabled';
exports.SAVE_SECOND_FACTOR_OPTION = '[IdentityPolicyWizard AuthenticationMethodsStep] Save Second Factor';
exports.SAVE_REQUEST_SECOND_FACTOR_FIRST = '[IdentityPolicyWizard AuthenticationMethodsStep] Save Request Second Factor First';
class SaveDomainAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SAVE_DOMAIN;
    }
}
exports.SaveDomainAction = SaveDomainAction;
class SaveCasServerAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SAVE_CAS_SERVER;
    }
}
exports.SaveCasServerAction = SaveCasServerAction;
class SaveAlternateDomainSuffixAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SAVE_ALTERNATE_DOMAIN_SUFFIX;
    }
}
exports.SaveAlternateDomainSuffixAction = SaveAlternateDomainSuffixAction;
class SaveCloudAuthenticationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SAVE_CLOUD_AUTHENTICATION;
    }
}
exports.SaveCloudAuthenticationAction = SaveCloudAuthenticationAction;
class SaveMimeAuthEnabledAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SAVE_MIME_AUTH_ENABLED;
    }
}
exports.SaveMimeAuthEnabledAction = SaveMimeAuthEnabledAction;
class SaveSecondFactorAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SAVE_SECOND_FACTOR_OPTION;
    }
}
exports.SaveSecondFactorAction = SaveSecondFactorAction;
class SaveRequestBeforePasswordAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SAVE_REQUEST_SECOND_FACTOR_FIRST;
    }
}
exports.SaveRequestBeforePasswordAction = SaveRequestBeforePasswordAction;
