"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileUploadHelperService = void 0;
const operators_1 = require("rxjs/operators");
class FileUploadHelperService {
    constructor(http) {
        this.http = http;
        this.API_ENDPOINTS = {
            uploadChunk: '/api/file/upload-chunk',
            commitChunks: '/api/file/commit-chunks',
            confirmChunks: '/api/file/confirm-chunks'
        };
    }
    uploadChunk(fileSha256, chunk) {
        return this.http.post(this.API_ENDPOINTS.uploadChunk, chunk.data, {
            headers: {
                'x-mc-arg': JSON.stringify({
                    data: [
                        {
                            chunkSize: chunk.size,
                            chunkSha256: chunk.sha256,
                            fileSha256
                        }
                    ]
                }),
                'content-type': 'application/octet-stream'
            }
        });
    }
    commitChunks(summary) {
        return this.http
            .post(this.API_ENDPOINTS.commitChunks, {
            data: [summary]
        })
            .pipe(operators_1.map((response) => {
            return response.first.id;
        }));
    }
    confirmChunks(summary) {
        return this.http.post(this.API_ENDPOINTS.confirmChunks, {
            data: [summary]
        });
    }
    splitFileIntoChunks(file, maxChunkSize) {
        const chunkBounds = [];
        for (let i = 0; i < file.size; i += maxChunkSize) {
            chunkBounds.push([i, Math.min(i + maxChunkSize, file.size)]);
        }
        return Promise.all(chunkBounds.map(([lower, upper]) => {
            return new Promise((res, rej) => {
                const chunkReader = new FileReader();
                chunkReader.onload = (e) => {
                    const data = e.target.result;
                    const chunk = {
                        data,
                        size: upper - lower
                    };
                    res(chunk);
                };
                chunkReader.onerror = rej;
                chunkReader.readAsArrayBuffer(file.slice(lower, upper));
            });
        }));
    }
}
exports.FileUploadHelperService = FileUploadHelperService;
