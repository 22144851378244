"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.analysis = exports.summary = void 0;
exports.summary = {
    scanResult: 'virus',
    scanDetails: 'Triggered by .....',
    sha256Hash: '9M64qXhjTXMxeNTgWxB8qcqC7vwpKAEdEVKMz9h',
    sha1Hash: '9M64qXhjTXMxeNTgWxB8qcqC7vwpKAEdEVKMz9h',
    md5Hash: '9M64qXhjTXMxeNTgWxB8qcqC7vwpKAEdEVKMz9h',
    firstDetection: '21 Mar 2018 - 12:00',
    latestDetection: '21 Mar 2018 - 14:00',
    mimecastProduct: 'Mimecast Targeted Threat Protection',
    policy: 'Policy_scan_malware'
};
// @ts-ignore
exports.analysis = {
    verdict: 'virus',
    evidence: {
        evidence: 
        // eslint-disable-next-line max-len
        'Stream "a3abeb44-7bbd-4a70-8a4a-b2d35d2a4fe5.ace/EICAR virus test files_offset_61" true type is "EICAR virus test files", which is a "EICAR" file.',
        stream: 'a3abeb44-7bbd-4a70-8a4a-b2d35d2a4fe5.ace/EICAR virus test files_offset_61'
    }
};
