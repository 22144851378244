"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueueReviewerPerformanceBuilder = void 0;
const queue_reviewers_1 = require("../queue-reviewers");
class QueueReviewerPerformanceBuilder {
    static build(reviewerformanceData) {
        const { displayableName, emailAddress } = reviewerformanceData['reviewer'];
        const reviewer = new queue_reviewers_1.Reviewer(displayableName, emailAddress);
        const processedCount = reviewerformanceData['processedCount'];
        return new queue_reviewers_1.QueueReviewerPerformance(reviewer, processedCount);
    }
}
exports.QueueReviewerPerformanceBuilder = QueueReviewerPerformanceBuilder;
