
    <div class="panel-padding-top">
      <mc-swg-threat-events-table
        [threatEvents]="threatEvents$ | async"
        [isLoading]="isLoading$ | async"
        [metaData]="metaData$ | async"
        [graphData]="graphData$ | async"
        [isGraphDataLoading]="isGraphDataLoading$ | async"
        (dateRangeChange)="loadData($event)"
        (onPaginationChange)="changePage($event)"
        (onRowClicked)="openEvent($event.id)"
      ></mc-swg-threat-events-table>
    </div>
  