"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SidePanelEffects = void 0;
const effects_1 = require("@ngrx/effects");
const components_1 = require("@mimecast-ui/components");
const operators_1 = require("rxjs/operators");
const definition_aside_component_1 = require("../../containers/aside/definition-aside.component");
const listActions = require("../../actions/definition.actions");
const rxjs_1 = require("rxjs");
const notification_actions_1 = require("app-new/components/notification/actions/notification.actions");
class SidePanelEffects {
    constructor(actions$, definitionDetailsService, asideService, translateService) {
        this.actions$ = actions$;
        this.definitionDetailsService = definitionDetailsService;
        this.asideService = asideService;
        this.translateService = translateService;
        this.openSidePanel$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(listActions.OPEN_DEFINITION_DETAILS_SIDE_PANEL), operators_1.switchMap(() => {
                this.sidePanelInstance = this.asideService.open(definition_aside_component_1.DefinitionAsideComponent, this.sidePanelConfig);
                return this.sidePanelInstance.afterClose().pipe(operators_1.first());
            }), operators_1.catchError(() => {
                return rxjs_1.of(this.failedToLoadDefinitionDetailsNotification);
            }));
        }, { dispatch: false });
        this.getDefinitionDetails$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(listActions.GET_DEFINITION_DETAILS), operators_1.switchMap((action) => {
                return this.definitionDetailsService.getDefinitionDetails(action.payload).pipe(operators_1.map((response) => {
                    return new listActions.GetDefinitionDetailsSuccessAction(response.first);
                }), operators_1.catchError(() => {
                    return rxjs_1.of(this.failedToLoadDefinitionDetailsNotification);
                }));
            }));
        });
        this.getDuplicateDefinitionDetails$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(listActions.GET_DUPLICATE_DEFINITION_DETAILS), operators_1.switchMap((action) => {
                return this.definitionDetailsService.getDefinitionDetails(action.payload).pipe(operators_1.map((response) => {
                    return new listActions.GetDuplicateDefinitionDetailsSuccessAction(response.first);
                }), operators_1.catchError(() => {
                    return rxjs_1.of(this.failedToLoadDefinitionDetailsNotification);
                }));
            }));
        });
        this.closeSidePanel$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(listActions.CLOSE_DEFINITION_DETAILS_SIDE_PANEL), operators_1.tap(() => {
                if (this.sidePanelInstance) {
                    this.sidePanelInstance.close();
                }
            }));
        }, { dispatch: false });
        this.failedToLoadDefinitionDetailsNotification = new notification_actions_1.NotificationShowAction({
            type: 'error',
            config: {
                msg: this.translateService.instant('$I18N_SERVICES_AP_DEFINITION_SIDE_PANEL.NOTIFICATIONS.FAIL_DETAILS_LOAD')
            }
        });
    }
    get sidePanelConfig() {
        const sidePanelConfig = new components_1.AsideConfig();
        sidePanelConfig.position = 'right';
        sidePanelConfig.size = 'custom';
        sidePanelConfig.hasBackdrop = true;
        sidePanelConfig.minWidth = 620;
        sidePanelConfig.resizable = true;
        return sidePanelConfig;
    }
}
exports.SidePanelEffects = SidePanelEffects;
