"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SFCWidgetLayoutComponent = void 0;
const core_1 = require("@angular/core");
const widget_layout_static_value_1 = require("./widget-layout.static-value");
class SFCWidgetLayoutComponent {
    constructor() {
        this.exportFormats = widget_layout_static_value_1.exportFormats;
        this.onExportClick = new core_1.EventEmitter();
    }
    onExport(format) {
        this.onExportClick.emit(format);
    }
}
exports.SFCWidgetLayoutComponent = SFCWidgetLayoutComponent;
