"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.steps = void 0;
const details_component_1 = require("./details/details.component");
const settings_component_1 = require("./settings/settings.component");
const lexicon_component_1 = require("./lexicon/lexicon.component");
const queue_component_1 = require("./queue/queue.component");
const summary_component_1 = require("./summary/summary.component");
exports.steps = [
    details_component_1.RuleDetailsComponent,
    settings_component_1.RuleSettingsComponent,
    lexicon_component_1.RuleLexiconComponent,
    queue_component_1.RuleQueueComponent,
    summary_component_1.RuleSummaryComponent
];
__exportStar(require("./details/details.component"), exports);
__exportStar(require("./settings/settings.component"), exports);
__exportStar(require("./lexicon/lexicon.component"), exports);
__exportStar(require("./queue/queue.component"), exports);
__exportStar(require("./summary/summary.component"), exports);
