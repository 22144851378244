"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgExceptionsSidebarModalEffects = void 0;
const effects_1 = require("@ngrx/effects");
const operators_1 = require("rxjs/operators");
const swg_exceptions_sidebar_modal_actions_1 = require("../actions/swg-exceptions-sidebar-modal.actions");
const swg_exceptions_delete_confirmation_component_1 = require("../containers/delete/swg-exceptions-delete-confirmation.component");
const swg_exceptions_edit_sidebar_page_component_1 = require("../containers/edit/swg-exceptions-edit-sidebar-page.component");
class SwgExceptionsSidebarModalEffects {
    constructor(actions$, modalService, asideService) {
        this.actions$ = actions$;
        this.modalService = modalService;
        this.asideService = asideService;
        this.openSidePanel$ = this.actions$.pipe(effects_1.ofType(swg_exceptions_sidebar_modal_actions_1.SwgExceptionsSidebarModalActions.SWG_EXCEPTIONS_SIDEBAR_OPEN), operators_1.map((action) => action.payload), operators_1.tap(() => {
            this.sidePanelInstance = this.asideService.open(swg_exceptions_edit_sidebar_page_component_1.SwgExceptionsEditSidebarPageComponent, {
                position: 'right',
                size: 'md',
                hasBackdrop: true
            });
        }));
        this.closeSidePanel$ = this.actions$.pipe(effects_1.ofType(swg_exceptions_sidebar_modal_actions_1.SwgExceptionsSidebarModalActions.SWG_EXCEPTIONS_SIDEBAR_CLOSE), operators_1.filter(() => !!this.sidePanelInstance), operators_1.tap(() => {
            this.sidePanelInstance.close();
            this.sidePanelInstance = undefined;
        }));
        this.openExceptionDeleteModal$ = this.actions$.pipe(effects_1.ofType(swg_exceptions_sidebar_modal_actions_1.SwgExceptionsSidebarModalActions.SWG_EXCEPTIONS_DELETE_CONFIRM_MODAL_OPEN), operators_1.map((action) => action.payload), operators_1.tap(() => {
            this.deleteModalInstance = this.modalService.open(swg_exceptions_delete_confirmation_component_1.SwgExceptionsDeleteConfirmationComponent, {
                size: 'md',
                hasBackdrop: true
            });
        }));
        this.closeExceptionDeleteModal$ = this.actions$.pipe(effects_1.ofType(swg_exceptions_sidebar_modal_actions_1.SwgExceptionsSidebarModalActions.SWG_EXCEPTIONS_DELETE_CONFIRM_MODAL_CLOSE), operators_1.filter(() => !!this.deleteModalInstance), operators_1.tap(() => {
            this.deleteModalInstance.close();
            this.deleteModalInstance = undefined;
        }));
    }
}
__decorate([
    effects_1.Effect({ dispatch: false })
], SwgExceptionsSidebarModalEffects.prototype, "openSidePanel$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], SwgExceptionsSidebarModalEffects.prototype, "closeSidePanel$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], SwgExceptionsSidebarModalEffects.prototype, "openExceptionDeleteModal$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], SwgExceptionsSidebarModalEffects.prototype, "closeExceptionDeleteModal$", void 0);
exports.SwgExceptionsSidebarModalEffects = SwgExceptionsSidebarModalEffects;
