"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.swgActivityReportDateRangePickerConfig = exports.reasonFilters = exports.eventFilters = exports.discoveryMethodFilters = exports.actionFilters = exports.columnsAlwaysVisible = exports.columnsToStartHidden = exports.columnsList = exports.filterSearchOptions = void 0;
const moment = require("moment");
exports.filterSearchOptions = [
    {
        label: '$18N_SWG_REPORTING.TABLE.ALL',
        value: 'all'
    },
    {
        label: '$18N_SWG_REPORTING.TABLE.COLUMNS.USERNAME',
        value: 'username'
    },
    {
        label: '$18N_SWG_REPORTING.TABLE.COLUMNS.DEVICENAME',
        value: 'deviceName'
    },
    {
        label: '$18N_SWG_REPORTING.TABLE.COLUMNS.SOURCEIP',
        value: 'sourceIp'
    },
    {
        label: '$18N_SWG_REPORTING.TABLE.COLUMNS.PUBLICIP',
        value: 'publicIp'
    },
    {
        label: '$18N_SWG_REPORTING.TABLE.COLUMNS.PRIVATEIP',
        value: 'privateIp'
    },
    {
        label: '$18N_SWG_REPORTING.TABLE.COLUMNS.DESTINATIONDOMAIN',
        value: 'domainUrl'
    },
    {
        label: '$18N_SWG_REPORTING.TABLE.COLUMNS.POLICYNAME',
        value: 'policyName'
    }
];
exports.columnsList = [
    'username',
    'discoveryMethod',
    'deviceName',
    'publicIp',
    'privateIp',
    'request',
    'sourceIp',
    'category',
    'appName',
    'appCategory',
    'action',
    'requestEvent',
    'requestType',
    'actionReason',
    'policyName',
    'dateTime',
    'columnActions'
];
exports.columnsToStartHidden = [
    'discoveryMethod',
    'requestType',
    'privateIp',
    'deviceName',
    'appCategory'
];
exports.columnsAlwaysVisible = ['username', 'request', 'action', 'dateTime'];
exports.actionFilters = [
    'accepted',
    'allow',
    'block',
    'inspect',
    'noresponse',
    'unfiltered',
    'warning',
    'isolate',
    'ipblock',
    'ipmonitoring'
];
exports.discoveryMethodFilters = [
    'authenticated',
    'domainUser',
    'localUser',
    'multipleUsers',
    'networkProtectionOnly',
    'noLoggedInUser',
    'anonymous',
    'supervisedUser',
    'unknownDomainUser'
];
exports.eventFilters = ['dns', 'http', 'https', 'risk', 'ip'];
exports.reasonFilters = [
    'avInfected',
    'avUnscannable',
    'appControl',
    'urlFiltering',
    'categoryFiltering',
    'certificateRevoked',
    'connectionFailed',
    'defaultAllow',
    'exception',
    'newlyObservedDomains',
    'extendedProxy',
    'noanswer',
    'none',
    'nxdomain',
    'operational',
    'protocolProtection',
    'riskAccepted',
    'safeSearch',
    'servfail',
    'suspicious',
    'ttpAdvSimCheck',
    'ttpManagedUrls',
    'directipprotection'
];
exports.swgActivityReportDateRangePickerConfig = {
    allowCustomRange: false,
    minDate: moment().subtract(90, 'day'),
    maxDate: moment(),
    dateRanges: [
        {
            id: '24_HOURS',
            label: '$18N_SWG_REPORTING.TABLE.FILTERS.DATE_RANGE.DROPDOWN_VALUES.24_HOURS',
            rangeFn() {
                return {
                    start: moment()
                        .utc()
                        .subtract(24, 'hours'),
                    end: moment().utc()
                };
            }
        },
        {
            id: '48_HOURS',
            label: '$18N_SWG_REPORTING.TABLE.FILTERS.DATE_RANGE.DROPDOWN_VALUES.48_HOURS',
            rangeFn() {
                return {
                    start: moment()
                        .utc()
                        .subtract(48, 'hours'),
                    end: moment().utc()
                };
            }
        },
        {
            id: '7_DAYS',
            label: '$18N_SWG_REPORTING.TABLE.FILTERS.DATE_RANGE.DROPDOWN_VALUES.7_DAYS',
            rangeFn() {
                return {
                    start: moment()
                        .utc()
                        .subtract(7, 'days'),
                    end: moment().utc()
                };
            }
        },
        {
            id: '14_DAYS',
            label: '$18N_SWG_REPORTING.TABLE.FILTERS.DATE_RANGE.DROPDOWN_VALUES.14_DAYS',
            rangeFn() {
                return {
                    start: moment()
                        .utc()
                        .subtract(14, 'days'),
                    end: moment().utc()
                };
            }
        },
        {
            id: '30_DAYS',
            label: '$18N_SWG_REPORTING.TABLE.FILTERS.DATE_RANGE.DROPDOWN_VALUES.30_DAYS',
            rangeFn() {
                return {
                    start: moment()
                        .utc()
                        .subtract(30, 'days'),
                    end: moment().utc()
                };
            }
        },
        {
            id: '60_DAYS',
            label: '$18N_SWG_REPORTING.TABLE.FILTERS.DATE_RANGE.DROPDOWN_VALUES.60_DAYS',
            rangeFn() {
                return {
                    start: moment()
                        .utc()
                        .subtract(60, 'days'),
                    end: moment().utc()
                };
            }
        },
        {
            id: '$I18N_TABLE_FILTER_PLUGIN_DATE_RANGE.DROPDOWN_VALUES.ALL_TIME',
            label: '$18N_SWG_REPORTING.TABLE.FILTERS.DATE_RANGE.DROPDOWN_VALUES.90_DAYS',
            rangeFn() {
                return {
                    start: moment()
                        .utc()
                        .subtract(90, 'days'),
                    end: moment().utc()
                };
            }
        },
        {
            id: 'CUSTOM',
            label: '$18N_SWG_REPORTING.TABLE.FILTERS.DATE_RANGE.DROPDOWN_VALUES.CUSTOM_DATE_RANGE'
        }
    ],
    initialDateRange: 0
};
