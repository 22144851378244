"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RuleListEffect = void 0;
const rxjs_1 = require("rxjs");
const effects_1 = require("@ngrx/effects");
const operators_1 = require("rxjs/operators");
const rule_list_actions_1 = require("../actions/rule.list.actions");
const rule_actions_1 = require("../actions/rule.actions");
const rule_factory_1 = require("../factories/rule.factory");
const table_store_1 = require("@mimecast-ui/table-store");
const notification_actions_1 = require("../../../components/notification/actions/notification.actions");
const TABLE_ID = 'SupervisionRulesList';
class RuleListEffect {
    constructor(actions$, stateService, rulesService, ruleService, translateService, tableStore) {
        this.actions$ = actions$;
        this.stateService = stateService;
        this.rulesService = rulesService;
        this.ruleService = ruleService;
        this.translateService = translateService;
        this.tableStore = tableStore;
        this.showCreateRuleWizard$ = this.actions$.pipe(effects_1.ofType(rule_list_actions_1.RuleListActions.OPEN_CREATE_NEW_RULE), operators_1.tap(() => this.stateService.$state.go('archive-supervision-create-wizard-rule')));
        this.showEditRuleWizard$ = this.actions$.pipe(effects_1.ofType(rule_list_actions_1.RuleListActions.OPEN_EDIT_RULE), operators_1.map((action) => action.ruleId), operators_1.switchMap(ruleId => {
            return this.rulesService.getRule(ruleId).pipe(operators_1.map(response => {
                if (response.hasErrors) {
                    return { error: response.fail[0], rDetails: undefined };
                }
                const ruleDetails = rule_factory_1.RuleFactory.BUILD_RULE(response.first);
                return { error: undefined, rDetails: ruleDetails };
            }));
        }), operators_1.switchMap((result) => {
            this.stateService.$state.go('archive-supervision-update-wizard-rule', {
                ruleDetails: Object.assign({}, result.rDetails)
            });
            if (result.error) {
                return rxjs_1.of(new rule_actions_1.GetActionFail(result.error));
            }
            else {
                return rxjs_1.EMPTY;
            }
        }));
        this.delete$ = this.actions$.pipe(effects_1.ofType(rule_list_actions_1.RuleListActions.DELETE_RULE), operators_1.switchMap((action) => {
            return this.ruleService.deleteRule(action.payload).pipe(operators_1.map(response => {
                if (response.hasErrors) {
                    return new rule_list_actions_1.DeleteRuleActionFail({
                        message: this.getNotificationMessage('DELETE_FAILURE'),
                        failure: response.fail[0]
                    });
                }
                return new rule_list_actions_1.DeleteRuleActionSuccess(this.getNotificationMessage('DELETE_SUCCESS'));
            }), operators_1.catchError(response => {
                return rxjs_1.of(new rule_list_actions_1.DeleteRuleActionFail({
                    message: this.getNotificationMessage('DELETE_FAILURE'),
                    failure: response.fail[0]
                }));
            }));
        }));
        this.deleteSuccess$ = this.actions$.pipe(effects_1.ofType(rule_list_actions_1.RuleListActions.DELETE_RULE_SUCCESS), operators_1.map((action) => action.payload), operators_1.withLatestFrom(this.tableStore.select(table_store_1.tableReducer.getTableData(TABLE_ID)), this.tableStore.select(table_store_1.tableReducer.getCurrentFilters(TABLE_ID)), this.tableStore.select(table_store_1.tableReducer.getCurrentPagination(TABLE_ID))), operators_1.switchMap(([payload, tableData, currentFilters, currentPagination]) => {
            return [
                new notification_actions_1.NotificationShowSuccessAction(payload),
                new table_store_1.LoadAction({
                    tableId: TABLE_ID,
                    pagination: {
                        pageToken: currentPagination.pageToken,
                        pageSize: currentPagination.pageSize,
                        pageNumber: currentPagination.pageNumber
                    },
                    query: tableData.length === 1 ? undefined : currentFilters
                })
            ];
        }));
        this.deleteFail$ = this.actions$.pipe(effects_1.ofType(rule_list_actions_1.RuleListActions.DELETE_RULE_FAIL), operators_1.switchMap((action) => {
            if (!action.payload.failure || !action.payload.failure.errors) {
                return [new notification_actions_1.NotificationShowFailAction(action.payload.message)];
            }
            return action.payload.failure.errors.map(error => new notification_actions_1.NotificationShowFailAction(this.translateService.instant(this.getNotificationMessage(error.code))));
        }));
    }
    getNotificationMessage(notificationkey) {
        const fullKey = `$I18N_ARCHIVE_SUPERVISION_LIST_RULES_TAB.NOTIFICATION.${notificationkey.toUpperCase()}`;
        return this.translateService.instant(fullKey);
    }
}
__decorate([
    effects_1.Effect({ dispatch: false })
], RuleListEffect.prototype, "showCreateRuleWizard$", void 0);
__decorate([
    effects_1.Effect()
], RuleListEffect.prototype, "showEditRuleWizard$", void 0);
__decorate([
    effects_1.Effect()
], RuleListEffect.prototype, "delete$", void 0);
__decorate([
    effects_1.Effect()
], RuleListEffect.prototype, "deleteSuccess$", void 0);
__decorate([
    effects_1.Effect()
], RuleListEffect.prototype, "deleteFail$", void 0);
exports.RuleListEffect = RuleListEffect;
