"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MepDeleteConfirmationModalComponent = void 0;
const reducers = require("../../../reducers");
const policy_actions_1 = require("../../../actions/policy.actions");
class MepDeleteConfirmationModalComponent {
    constructor(store$) {
        this.store$ = store$;
    }
    ngOnInit() {
        this.policy$ = this.store$.select(reducers.getMepPolicy);
    }
    onDelete(policy) {
        this.store$.dispatch(new policy_actions_1.MepDeletePolicy(policy.id));
    }
    onCancel() {
        this.store$.dispatch(new policy_actions_1.MepDetailDeleteConfirmModalClose());
    }
}
exports.MepDeleteConfirmationModalComponent = MepDeleteConfirmationModalComponent;
