"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BulkDeliveryTabComponent = void 0;
const actions = require("../../actions/bulk-delivery.action");
const reducers = require("../../reducers");
class BulkDeliveryTabComponent {
    constructor(store) {
        this.store = store;
    }
    ngOnInit() {
        this.items$ = this.store.select(reducers.getBulkDeliveryItems);
        this.totalCount$ = this.store.select(reducers.getBulkDeliveryTotalCount);
        this.selectedRow$ = this.store.select(reducers.getBulkDeliverySelectedRow);
        this.isLoading$ = this.store.select(reducers.isBulkDeliveryLoading);
        this.metadata$ = this.store.select(reducers.getBulkDeliveryMetadata);
        this.store.dispatch(new actions.BulkDeliveryRequestAction({ search: {} }));
    }
    search(search) {
        this.store.dispatch(new actions.BulkDeliveryRequestAction({ search }));
    }
    dateFilterChanged(range) {
        this.store.dispatch(new actions.BulkDeliveryRequestAction({ search: Object.assign({}, range.range) }));
    }
    columnFilterChanged(filter) {
        this.store.dispatch(new actions.BulkDeliveryRequestAction({ search: filter }));
    }
    paginatorChanged(page) {
        this.store.dispatch(new actions.BulkDeliveryPaginationAction(page));
    }
    rowClicked(message) {
        this.store.dispatch(new actions.BulkDeliverySelectRowAction(message));
    }
    messagesRejected(messages) {
        if (messages.length > 0) {
            this.store.dispatch(new actions.BulkDeliveryRejectEmailsAction(messages));
        }
    }
    messagesRetried(messages) {
        if (messages.length > 0) {
            this.store.dispatch(new actions.BulkDeliveryRetryEmailsAction(messages));
        }
    }
    routeRecalculated(messages) {
        if (messages.length > 0) {
            this.store.dispatch(new actions.BulkRecalculateRouteAction(messages));
        }
    }
    messagesEarlyBounced(messages) {
        if (messages.length > 0) {
            this.store.dispatch(new actions.BulkDeliveryEarlyBounceEmailsAction(messages));
        }
    }
    exportResults(tableColumns) {
        this.store.dispatch(new actions.BulkDeliveryExportAction(tableColumns));
    }
}
exports.BulkDeliveryTabComponent = BulkDeliveryTabComponent;
