"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const angular = require("angular");
(function () {
    'use strict';
    angular.module('services.services.backup.mailbox-details.navigationlinks', [])
        .directive('mcMailboxNavigationLinks', ['archiveFolderService', '$q', '$translate', 'growl', 'mailboxDetailsSerivce', 'adconBackupService', function (archiveFolderService, $q, $translate, growl, mailboxDetailsSerivce, adconBackupService) {
            return {
                restrict: 'EA',
                templateUrl: 'services/services/backup/mailboxes/mailbox-details/mailbox-navigation-links/mailbox-navigation-link.tpl.html',
                scope: {
                    label: '@',
                    view: '@',
                    type: '=',
                    mailbox: '=',
                    rootNodes: '=',
                    selectedNode: '=',
                    reset: '=',
                    search: '=',
                    onSelect: '&',
                    checkedNodes: '=',
                    getCheckedNodesWithExpandFlag: '='
                },
                link($scope) {
                    const contentTypes = archiveFolderService.FOLDER_CONTENT_TYPE;
                    let navigationLinksDisplayFormat;
                    const FOLDER_FETCH_DEPTH = 1;
                    $scope.hasPermission = adconBackupService.CONSTANTS.HAS_PERMISSIONS;
                    activate();
                    function activate() {
                        if (!$scope.mailbox) {
                            return;
                        }
                        switch ($scope.type) {
                            case contentTypes.CALENDAR:
                                $scope.noResultsMessage = $translate.instant('$I18N_BACKUP_RESTORE_NO_CALENDARS_FOUND');
                                break;
                            case contentTypes.CONTACT:
                                $scope.noResultsMessage = $translate.instant('$I18N_BACKUP_RESTORE_NO_CONTACTS_FOUND');
                                break;
                            case contentTypes.TASK:
                                $scope.noResultsMessage = $translate.instant('$I18N_BACKUP_RESTORE_NO_TASKS_FOUND');
                                break;
                            case contentTypes.NOTES:
                                $scope.noResultsMessage = $translate.instant('$I18N_BACKUP_RESTORE_NO_NOTES_FOUND');
                                break;
                            default:
                                $scope.noResultsMessage = $translate.instant('$I18N_BACKUP_RESTORE_NO_FOLDERS_FOUND');
                        }
                        displayTree();
                    }
                    function displayTree() {
                        navigationLinksDisplayFormat = 'Tree';
                        // @ts-ignore
                        self.expandedFolders = [];
                        $scope.checkedNodes = [];
                        $scope.rootNodes.then(function (data) {
                            if (data && data[0] && data[0].contentType === 'MESSAGE') {
                                if (data[0].nodes.length == 0) {
                                    $scope.foldersTree = [];
                                    return;
                                }
                            }
                            $scope.foldersTree = data;
                        }, function () { });
                    }
                    $scope.reset = function () {
                        displayTree();
                    };
                    $scope.search = function (term) {
                        $scope.broadcastedText = term;
                        displayList();
                    };
                    function displayList() {
                        navigationLinksDisplayFormat = 'List';
                        // @ts-ignore
                        self.expandedFolders = [];
                        $scope.checkedNodes = [];
                        mailboxDetailsSerivce.findFoldersAndDoTransformation($scope.mailbox, $scope.type, $scope.view, $scope.broadcastedText).then(function (data) {
                            $scope.foldersTree = data;
                        }, function () { });
                    }
                    // converts folder to tree format. depth controls the level to which folders go down
                    // Initially we need 2 level of folder when tree is first show, otherwise it is 1
                    $scope.onFolderExpand = function (node) {
                        const childNodesPromise = $q.defer();
                        archiveFolderService.getMailboxFolders({
                            mailbox: $scope.mailbox,
                            depth: FOLDER_FETCH_DEPTH,
                            viewType: $scope.view,
                            contentType: contentTypes.MESSAGE,
                            parentId: node.id
                        }).then(function (data) {
                            if (!data.err) {
                                const childNodes = data.first.folders.map(function (folder) {
                                    return mailboxDetailsSerivce.transformFolderToNode(folder, FOLDER_FETCH_DEPTH); // filter out children nodes
                                });
                                childNodesPromise.resolve(childNodes);
                            }
                            else {
                                archiveFolderService.getError().showErrorNotification(data);
                            }
                        }, function () {
                            growl.error('$I18N_BACKUP_RESTORE_MAILBOXES_FOLDER_EXPAND_ERROR', { ttl: 5000 });
                        });
                        return childNodesPromise.promise;
                    };
                    $scope.onFolderSelect = function (node) {
                        $scope.onSelect({ node });
                    };
                    $scope.onExpandEvent = function (node) {
                        if (!node.collapse) {
                            // @ts-ignore
                            if (self.expandedFolders.indexOf(node.fullPath) < 0) {
                                // @ts-ignore
                                self.expandedFolders.push(node.fullPath);
                            }
                        }
                    };
                    function markExpanded(folder) {
                        //Calendars and contacts are not backuped or restored recursively. so expand is false.
                        if ($scope.type === contentTypes.CALENDAR || $scope.type === contentTypes.CONTACT || $scope.type === contentTypes.TASK || $scope.type === contentTypes.NOTES) {
                            return false;
                        }
                        else {
                            if (navigationLinksDisplayFormat === 'Tree') {
                                // @ts-ignore
                                return !_.includes(self.expandedFolders, folder.fullPath);
                            }
                            else {
                                //If the navigationLinkDisplayType is list, we dont need to expand.
                                return false;
                            }
                        }
                    }
                    $scope.getCheckedNodesWithExpandFlag = function () {
                        return _.chain($scope.checkedNodes).filter(function (node) {
                            return typeof node.fullPath != 'undefined';
                        }).map(function (folder) {
                            return {
                                path: folder.fullPath,
                                expand: markExpanded(folder)
                            };
                        }).value();
                    };
                }
            };
        }]);
})();
