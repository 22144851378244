"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppsApiService = void 0;
const operators_1 = require("rxjs/operators");
class AppsApiService {
    constructor(http) {
        this.http = http;
    }
    getAppsList(request) {
        const config = {};
        if (request && request.pagination) {
            config['meta'] = {
                pagination: request.pagination
            };
        }
        return this.http.post('/api/identity/get-apps', config);
    }
    getAppDetails(id) {
        return this.http
            .post('/api/identity/get-app', { id })
            .pipe(operators_1.map((response) => response.first));
    }
    createApp() {
        return this.http
            .post('/api/identity/create-app', {})
            .pipe(operators_1.map((response) => response.first));
    }
    deleteApp(apps) {
        return this.http
            .post('/api/identity/delete-app', { data: apps })
            .pipe(operators_1.map((response) => response.first));
    }
    updateApp(updatePayload) {
        return this.http
            .post('/api/identity/update-app', updatePayload)
            .pipe(operators_1.map((response) => response.first));
    }
}
exports.AppsApiService = AppsApiService;
