<mc-exchange-sync-and-recover-page>
  <mc-exchage-snapshot-detail [rootFolder]="rootFolder$ | async"
                              [folders]="folders$ | async"
                              [foldersLoading]="foldersLoading$ | async"
                              [foldersMetadata]="foldersMetadata$ | async"
                              [currentNode]="currentNode"
                              [currentParentFolder]="currentParentFolder$ | async"
                              [contents]="contents$ | async"
                              [contentsLoading]="contentsLoading$ | async"
                              [isContentOpening]="isContentOpening$ | async"
                              [contentsInitialState]="contentsInitialState$ | async"
                              [selectedFoldersCart]="selectedFoldersCart$ | async"
                              [contentsMetadata]="contentsMetadata$ | async"
                              [searchingFolders]="searchingFolders$ | async"
                              [searchingContents]="searchingContents$ | async"
                              [contentsPageNumber]="contentsPageNumber$ | async"
                              [snapshotSummary]="snapshot"
                              [hasContentViewPermission]="hasContentViewPermission$ | async"
                              [hasExportAndRestorePermission]="hasExportAndRestorePermission$ | async"
                              [userProfile]="userProfile$ | async"
                              [contentType]="contentType"
                              [nodeStack]="nodeStack"
                              (onGetRootFolders)="dispatchRootFolder($event)"
                              (onGetFolders)="dispatchFolders($event)"
                              (onSearchFolders)="searchFolders($event)"
                              (onGetNextFolders)="dispatchNextFolders($event)"
                              (onGetPreviousFolders)="dispatchPreviousFolders($event)"
                              (onGetContent)="dispatchContents($event)"
                              (onSearchContents)="searchContents($event)"
                              (onGetNextContents)="dispatchNextContents($event)"
                              (onGetPreviousContents)="dispatchPreviousContents($event)"
                              (onFolderToggleSelect)="updateFoldersCart($event)"
                              (onTabSelect)="initializeSelectedTab($event)"
                              (onNodeClicked)="getFoldersAndContents($event)"
                              (onOpenExport)="openExport($event)"
                              (onOpenRestore)="openRestore($event)"
                              (onPushNodeToTrace)="pushNodeToTrace($event)"
                              (onItemClicked)="openItem($event)"
                              (onInvalidSnapshot)="dispatchLoadSnapshotQueue()">
  </mc-exchage-snapshot-detail>
</mc-exchange-sync-and-recover-page>
