"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransmissionComponentsComponent = void 0;
class TransmissionComponentsComponent {
    constructor() {
        this.columns = ['fileType', 'fileName', 'hash', 'txSize'];
        this.columnsArchived = ['type', 'name', 'hash', 'size'];
    }
    set selectedEmail(val) {
        this._selectedEmail = val;
        this.selectDataSet();
    }
    get selectedEmail() {
        return this._selectedEmail;
    }
    selectDataSet() {
        this.components = null;
        if (this.selectedEmail && this.recipientInfo && this.selectedDeliveredMessage) {
            if (this.selectedEmail.type === 'receipt') {
                // API sends the data in txInfo.transmissionComponents OR recipientMetaData.components/deliveryMetaData.components
                if (this.recipientInfo.txInfo && this.recipientInfo.txInfo.transmissionComponents) {
                    this.components = this.recipientInfo.txInfo.transmissionComponents;
                    this.isDataSetOne = true;
                }
                else if (this.recipientInfo.recipientMetaInfo &&
                    this.recipientInfo.recipientMetaInfo.components) {
                    this.components = this.recipientInfo.recipientMetaInfo.components;
                    this.isDataSetOne = false;
                }
            }
            else if (this.selectedEmail.type === 'delivery') {
                if (this.selectedDeliveredMessage.txInfo &&
                    this.selectedDeliveredMessage.txInfo.transmissionComponents) {
                    this.components = this.selectedDeliveredMessage.txInfo.transmissionComponents;
                    this.isDataSetOne = true;
                }
                else if (this.selectedDeliveredMessage.deliveryMetaInfo &&
                    this.selectedDeliveredMessage.deliveryMetaInfo.components) {
                    this.components = this.selectedDeliveredMessage.deliveryMetaInfo.components;
                    this.isDataSetOne = false;
                }
            }
        }
    }
}
exports.TransmissionComponentsComponent = TransmissionComponentsComponent;
