"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateContainsEmails = exports.EmailComposeFormComponent = void 0;
const forms_1 = require("@angular/forms");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
class EmailComposeFormComponent {
    constructor(fb, service) {
        this.fb = fb;
        this.service = service;
        this.destroy = false;
    }
    get value() {
        return this.form.value;
    }
    set value(email) {
        this.form.patchValue(email);
        this.service.init(email.attachments);
    }
    ngOnInit() {
        this.form = this.fb.group({
            to: ['', [validateContainsEmails, forms_1.Validators.required]],
            cc: ['', [validateContainsEmails]],
            bcc: ['', [validateContainsEmails]],
            subject: ['', [forms_1.Validators.required]],
            textBody: '',
            attachments: []
        });
        this.oldAttachments$ = this.service.oldAttachments$;
        this.newAttachments$ = this.service.newAttachments$;
        // keep form data in sync with the files uploaded and old file that might be removed
        rxjs_1.combineLatest(this.service.newAttachments$, this.service.oldAttachments$)
            .pipe(operators_1.tap(() => this.form.get('attachments').patchValue(this.service.allAttachments)), operators_1.takeWhile(() => !this.destroy))
            .subscribe();
    }
    writeValue(value) {
        const email = Object.assign({}, value);
        this.value = email;
    }
    registerOnChange(fn) {
        this.onChanges = fn;
        // @ts-ignore
        this.form.valueChanges.pipe(operators_1.takeWhile(() => !this.destroy)).subscribe(fn);
    }
    registerOnTouched(fn) {
        this.onTouch = fn;
    }
    ngOnDestroy() {
        this.destroy = true;
    }
    /**
     * this component is valid if the form is valid and there is no files been uploaded
     *
     * @param c
     */
    validate() {
        return rxjs_1.combineLatest(this.form.statusChanges, this.service.filesUploadingCount$).pipe(operators_1.map(
        // @ts-ignore
        ([, fileCount]) => (this.form.valid && fileCount === 0 ? null : { emailValid: false })), operators_1.first());
    }
    removeOld(attachment) {
        this.service.removeOld(attachment);
    }
    removeNew(attachment) {
        this.service.removeNew(attachment);
    }
    upload(files) {
        files.forEach(file => this.service.upload(file));
    }
}
exports.EmailComposeFormComponent = EmailComposeFormComponent;
function validateContainsEmails(control) {
    const isValid = control.value && control.value.match(/@.*?\./);
    return isValid || !control.value ? null : { containsNoEmails: true };
}
exports.validateContainsEmails = validateContainsEmails;
