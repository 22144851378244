<div class="sync-recover-overview-doughnut-chart-panel">
  <mc-layout-wrapper-panel-simple [defaultTitle]="title">
    <div class="chart-container">
      <mc-chart>
        <mc-doughnut-chart
          class="mc-amchart"
          [data]="chartData"
          [showLegend]="true"
          [chartWidth]="240"
          [totalCountFontSize]="32"
          [chartAlign]="'center'"
          [chartHorizontalCenter]="'middle'"
          [addStroke]="true"
          [chartPaddingLeft]="65"
        >
        </mc-doughnut-chart>
      </mc-chart>
    </div>
  </mc-layout-wrapper-panel-simple>
</div>
