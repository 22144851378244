"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrackingSearchByIdComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const components_1 = require("@mimecast-ui/components");
const date_range_config_1 = require("../../../common/components/table-search-configs/date-range-config");
const utils_1 = require("../../../common/utils");
class TrackingSearchByIdComponent {
    constructor(formBuilder) {
        this.formBuilder = formBuilder;
        this.dateRangePickerConfig = date_range_config_1.DateRangeConfig.config;
        this.search = new core_1.EventEmitter();
    }
    static formValidator(formToValidate) {
        return formToValidate.value.messageId ? null : { invalid: true };
    }
    ngOnInit() {
        this.formGroup = this.formBuilder.group({
            messageId: ['', [components_1.minLengthTrimmed(3), forms_1.Validators.maxLength(997)]],
            dateRangePicker: [date_range_config_1.DateRangeConfig.config.dateRanges[1], []]
        }, {
            validator: TrackingSearchByIdComponent.formValidator
        });
    }
    doSearch() {
        const newMessageId = this.getMessageIdWithBrackets(this.formGroup.value.messageId);
        this.search.emit({
            messageId: newMessageId,
            end: this.formGroup.value.dateRangePicker.rangeFn().end.format(),
            start: this.formGroup.value.dateRangePicker.rangeFn().start.format()
        });
        this.formGroup.get('messageId').setValue(newMessageId);
    }
    getMessageIdWithBrackets(currentMessageId) {
        const trimmed = utils_1.stripNonPrintableChars(currentMessageId.trim());
        let newMessageId = trimmed.startsWith('<') ? trimmed : `<${trimmed}`;
        newMessageId += newMessageId.endsWith('>') ? '' : '>';
        return newMessageId;
    }
}
exports.TrackingSearchByIdComponent = TrackingSearchByIdComponent;
