"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducer = exports.initialState = void 0;
const check_urls_actions_1 = require("../../actions/check-urls.actions");
exports.initialState = {
    loading: false,
    hasError: false,
    scanDetails: {
        aggressive: '-',
        moderate: '-',
        relaxed: '-'
    }
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case check_urls_actions_1.CHECK_URL_ATTEMPT:
            return Object.assign(Object.assign({}, state), { loading: true, hasError: false });
        case check_urls_actions_1.CHECK_URL_FAIL:
            return Object.assign(Object.assign({}, state), { loading: false, hasError: true });
        case check_urls_actions_1.CHECK_URL_SUCCESS:
            return Object.assign(Object.assign(Object.assign({}, state), action.payload), { loading: false, hasError: false });
        case check_urls_actions_1.CHECK_URL_CLEAR:
            return Object.assign({}, exports.initialState);
        default:
            return Object.assign({}, state);
    }
}
exports.reducer = reducer;
