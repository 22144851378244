"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SidePanelEffects = void 0;
const effects_1 = require("@ngrx/effects");
const components_1 = require("@mimecast-ui/components");
const operators_1 = require("rxjs/operators");
const createActions = require("../../actions/url-create.actions");
const updateActions = require("../../actions/url-update.actions");
const url_aside_component_1 = require("../../containers/aside/url-aside.component");
const managed_urls_reducer_1 = require("../../reducers/managed-urls.reducer");
const reducers = require("../../reducers/index");
class SidePanelEffects {
    constructor(actions$, asideService, store) {
        this.actions$ = actions$;
        this.asideService = asideService;
        this.store = store;
        this.openManagedUrlSidePanel$ = this.actions$.pipe(effects_1.ofType(createActions.OPEN_ADD_NEW_MANAGED_URL_SIDE_PANEL), operators_1.filter(() => !this.sidePanelInstance), operators_1.switchMap(() => {
            this.sidePanelInstance = this.asideService.open(url_aside_component_1.UrlAsideComponent, this.sidePanelConfig);
            return this.sidePanelInstance.beforeClose().pipe(operators_1.tap(() => (this.sidePanelInstance = undefined)), operators_1.map(() => new createActions.ClearAddUrlState()));
        }));
        this.closeSidePanel$ = this.actions$.pipe(effects_1.ofType(createActions.CLOSE_MANAGED_URL_SIDE_PENAL), operators_1.tap(() => {
            if (this.sidePanelInstance) {
                this.sidePanelInstance.close();
            }
        }));
        this.openEditManagedUrlSidePanel$ = this.actions$.pipe(effects_1.ofType(updateActions.OPEN_MANAGED_URL_DETAILS), operators_1.withLatestFrom(this.store.select(reducers.getManagedUrlState)), operators_1.switchMap(([action]) => {
            if (action['isEditAllowed']) {
                this.sidePanelConfig.data.isEditAllowed = action['isEditAllowed'];
            }
            this.sidePanelInstance = this.asideService.open(url_aside_component_1.UrlAsideComponent, this.sidePanelConfig);
            return this.sidePanelInstance.beforeClose().pipe(operators_1.map(() => {
                this.sidePanelInstance = undefined;
                return new updateActions.SelectedRow(managed_urls_reducer_1.initialState.selectedRow);
            }));
        }));
    }
    get sidePanelConfig() {
        const sidePanelConfig = new components_1.AsideConfig();
        sidePanelConfig.position = 'right';
        sidePanelConfig.size = 'custom';
        sidePanelConfig.hasBackdrop = true;
        sidePanelConfig.minWidth = 620;
        sidePanelConfig.data = {};
        sidePanelConfig.resizable = true;
        return sidePanelConfig;
    }
}
__decorate([
    effects_1.Effect()
], SidePanelEffects.prototype, "openManagedUrlSidePanel$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], SidePanelEffects.prototype, "closeSidePanel$", void 0);
__decorate([
    effects_1.Effect()
], SidePanelEffects.prototype, "openEditManagedUrlSidePanel$", void 0);
exports.SidePanelEffects = SidePanelEffects;
