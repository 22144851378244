<mc-layout-aside-extra-container
  keyTitle="{{ application.applicationName }}"
  [isLoading]="!isGatewayApplication(application) ? false : isLoading"
  showClose="true"
  (closeAside)="closeClicked()"
>
  <mc-extra-container *mcCapabilities="'Permission.API_APPLICATIONS_EDIT'">
    <div class="mc-container-actions-area">
      <div class="mc-container-buttons">
        <mc-live-button
          typeClass="mc-btn btn btn-primary"
          (mcClick)="editClicked()"
          content="{{ '$I18N_COMMON_BTN_EDIT' | translate }}"
        >
        </mc-live-button>
        <mc-live-button *ngIf="isGatewayApplication(application); else getKeys"
                        typeClass="mc-btn btn btn-secondary"
                        (mcClick)="getCredentialsClicked()"
                        content="{{ '$I18N_API_GATEWAY_ASIDE_VIEW.BUTTONS.MANAGE_GATEWAY_APPLICATION_CREDENTIALS' | translate }}"
        >
        </mc-live-button>
        <ng-template #getKeys>
          <mc-live-button
            typeClass="mc-btn btn btn-secondary"
            [mcDisabled]="application?.corsEnabled || !active"
            (mcClick)="genKeysClicked()"
            content="{{ '$I18N_API_GATEWAY_ASIDE_VIEW.BUTTONS.GENKEY' | translate }}"
          >
          </mc-live-button>
        </ng-template>
        <mc-live-button
          typeClass="mc-btn btn btn-secondary"
          (mcClick)="deleteClicked()"
          content="{{ '$I18N_COMMON_BTN_DELETE' | translate }}"
        >
        </mc-live-button>
      </div>

      <div *ngIf="!isGatewayApplication(application)" class="mc-container-switch">
        <form novalidate [formGroup]="appEnabledForm">
          <mc-enable-switch formControlName="appEnabled"></mc-enable-switch>
        </form>
      </div>
    </div>
  </mc-extra-container>

  <mc-body-container>
    <section class="mc-detail-section">
      <div class="panel-body no-padding-top">
        <div class="mc-detailed-list">
          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_GATEWAY_ASIDE_VIEW.FIELDS.APPLICATION_NAME' | translate }}
            </div>
            <div class="mc-detailed-list-column">{{ application.applicationName }}</div>
          </div>
          <ng-container *ngIf="!isGatewayApplication(application)">
            <div class="row mc-detailed-list-row">
              <div class="col-xs-4 mc-detailed-list-label">
                {{ '$I18N_API_GATEWAY_ASIDE_VIEW.FIELDS.APPLICATION_ID' | translate }}
              </div>
              <div class="mc-detailed-list-column">
                {{ application.appId }}
                <a
                  ngxClipboard
                  class="pull-right cursor-pointer text-normal"
                  [cbContent]="application.appId"
                  (cbOnSuccess)="
                  clipboardSuccess('$I18N_API_GATEWAY_ASIDE_VIEW.ACTIONS.APP_ID_TO_CLIPBOARD')
                "
                >
                  <em class="far fa-copy"></em>
                </a>
              </div>
            </div>
            <div class="row mc-detailed-list-row">
              <div class="col-xs-4 mc-detailed-list-label">
                {{ '$I18N_API_GATEWAY_ASIDE_VIEW.FIELDS.APPLICATION_KEY' | translate }}
              </div>
              <div class="mc-detailed-list-column">
                <div *ngIf="!!appKey; else getKeyAndDisableCBButton">
                  <span>{{showKey ? appKey : '******' }}</span>
                  <a
                    ngxClipboard
                    class="pull-right cursor-pointer text-normal"
                    [cbContent]="appKey"
                    (cbOnSuccess)="
                    clipboardSuccess('$I18N_API_GATEWAY_ASIDE_VIEW.ACTIONS.APP_KEY_TO_CLIPBOARD')
                  "
                  >
                    <em class="far fa-copy"></em>
                  </a>
                  <a
                    class="pull-right cursor-pointer panel-half-padding-right text-normal"
                    (click)="toggleKeyVisibilityClicked()"
                  >
                    <em [className]="showKey ? 'far fa-eye-slash' : 'far fa-eye'"></em>
                  </a>
                </div>
                <ng-template #getKeyAndDisableCBButton>
                  <span>******</span>
                  <em class="far fa-copy pull-right text-muted"></em>
                  <a
                    class="pull-right cursor-pointer panel-half-padding-right text-normal"
                    (click)="getKeyClicked()"
                  >
                    <em [className]="gettingKey ? 'far fa-spinner fa-spin' : 'far fa-eye'"></em>
                  </a>
                </ng-template>
              </div>
            </div>
            <div class="row mc-detailed-list-row">
              <div class="col-xs-4 mc-detailed-list-label">
                {{ '$I18N_API_GATEWAY_ASIDE_VIEW.FIELDS.SERVICE_APPLICATION' | translate }}
              </div>
              <div class="mc-detailed-list-column">
                {{ application.service | mcServiceApplication }}
              </div>
            </div>
          </ng-container>
          <div class="row mc-detailed-list-row" *ngIf=isGatewayApplication(application)>
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_GATEWAY_ASIDE_VIEW.FIELDS.APPLICATION_ROLE' | translate }}
            </div>
            <div class="mc-detailed-list-column">
              {{ role$ | async }}
            </div>
          </div>
          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_GATEWAY_ASIDE_VIEW.FIELDS.CATEGORY' | translate }}
            </div>
            <div class="mc-detailed-list-column">
              {{ application.category | mcApplicationCategory }}
            </div>
          </div>
          <ng-container *ngIf="isGatewayApplication(application)">
            <div *mcCapabilities="'Temporary.Services.ApiApplications.Apigee.Mimecast.All.Products'" class="row mc-detailed-list-row">
              <div class="col-xs-4 mc-detailed-list-label">
                {{ '$I18N_API_GATEWAY_ASIDE_VIEW.FIELDS.PRODUCTS' | translate }}
              </div>
              <div class="mc-detailed-list-column">
                {{ allProducts }}
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="showClientId">
            <div class="row mc-detailed-list-row">
              <div class="col-xs-4 mc-detailed-list-label">
                {{ '$I18N_API_GATEWAY_ASIDE_VIEW.FIELDS.CLIENT_ID' | translate }}
              </div>
              <div class="mc-detailed-list-column">
                {{ clientId | async }}
              </div>
              <a
              ngxClipboard
              class="pull-right cursor-pointer text-normal"
              [cbContent]="clientId | async"
              (cbOnSuccess)="clipboardSuccess('$I18N_API_GATEWAY_ASIDE_VIEW.ACTIONS.CLIENT_ID_TO_CLIPBOARD')"
              >
                <em class="far fa-copy" aria-hidden="true"></em>
              </a>
            </div>
          </ng-container>
          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_GATEWAY_ASIDE_VIEW.FIELDS.DESCRIPTION' | translate }}
            </div>
            <div class="mc-detailed-list-column">
              <mc-text-block-display [textblock]="application.description"></mc-text-block-display>
            </div>
          </div>
          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_GATEWAY_ASIDE_VIEW.FIELDS.TECHNICAL_POINT_OF_CONTACT' | translate }}
            </div>
            <div class="mc-detailed-list-column">{{ application.developerName }}</div>
          </div>
          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_GATEWAY_ASIDE_VIEW.FIELDS.EMAIL' | translate }}
            </div>
            <div class="mc-detailed-list-column">{{ application.developerEmail }}</div>
          </div>
        </div>
      </div>
    </section>
  </mc-body-container>
</mc-layout-aside-extra-container>
