'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const operators_1 = require("rxjs/operators");
const model_1 = require("../../../app-new/core/model");
/**
 * Created by psambandam on 23/02/16.
 */
angular.module('chartconfig.service', ['core.service'])
    .factory('chartconfigService', ['$filter', 'dashboardCommonsService', 'CoreService', function ($filter, dashboardCommonsService, coreService) {
        let fipsMode = false;
        coreService
            .getCapsOverride()
            .pipe(operators_1.first(), model_1.isDefined())
            .subscribe((caps) => {
            var _a;
            fipsMode = (_a = caps['fipsMode']) === null || _a === void 0 ? void 0 : _a.enabled;
        });
        const defaultExporting = {
            buttons: dashboardCommonsService.getButtonConfig(),
            fallbackToExportServer: !fipsMode,
            error() {
                alert('An error has occurred exporting the chart');
            },
            chartOptions: {
                subtitle: {
                    text: ''
                }
            }
        };
        return {
            getEmailTrafficConfig,
            getEmailQueueChartConfig,
            getRejectionChartConfig,
            getEmailQueueConfig,
            getTotalEmailTrafficConfig
        };
        function getEmailTrafficConfig() {
            return {
                chart: {
                    className: 'panel-half-padding-top',
                    type: 'spline',
                    zoomType: 'x',
                    resetZoomButton: {
                        position: {
                            x: -30,
                            y: -30
                        },
                        theme: {
                            fill: 'white',
                            stroke: '#E6E6E6',
                            r: 2,
                            states: {
                                hover: {
                                    fill: '#ecf0f1',
                                    stroke: '#E6E6E6'
                                }
                            }
                        }
                    }
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: '',
                    style: {
                        display: 'none'
                    }
                },
                subtitle: {
                    text: $filter('translate')('$I18N_COMMON_CLICK_DRAG_ZOOM_TEXTS'),
                    style: {}
                },
                xAxis: {
                    type: 'datetime',
                    labels: {
                        overflow: 'justify'
                    }
                },
                yAxis: {
                    title: {
                        text: $filter('translate')('$I18N_COMMON_EMAIL_TRAFFIC_Y_LABEL')
                    },
                    minorGridLineWidth: 0,
                    gridLineWidth: 0,
                    alternateGridColor: null
                },
                tooltip: {
                    valueSuffix: $filter('translate')('$I18N_COMMON_EMAIL_TRAFFIC_TOOLTIP_LABEL')
                },
                plotOptions: {
                    spline: {
                        lineWidth: 1,
                        color: '#004ba0',
                        states: {
                            hover: {
                                lineWidth: 2
                            }
                        },
                        marker: {
                            enabled: false
                        }
                    }
                },
                legend: {
                    itemStyle: {
                        color: '#707070',
                        fontWeight: 'normal'
                    }
                },
                series: [{
                        name: $filter('translate')('$I18N_COMMON_EMAIL_TRAFFIC_INBOUND_LABEL'),
                        showInLegend: true,
                        color: '#004ba0'
                    },
                    {
                        name: $filter('translate')('$I18N_COMMON_EMAIL_TRAFFIC_INTERNAL_LABEL'),
                        showInLegend: true,
                        color: '#63a4ff'
                    },
                    {
                        name: $filter('translate')('$I18N_COMMON_EMAIL_TRAFFIC_OUTBOUND_LABEL'),
                        showInLegend: true,
                        color: '#ffa000'
                    }],
                exporting: defaultExporting,
                navigation: dashboardCommonsService.getNavigationConfig()
            };
        }
        function getEmailQueueChartConfig() {
            return {
                chart: {
                    className: 'panel-half-padding-top',
                    type: 'spline'
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: '',
                    style: {
                        display: 'none'
                    }
                },
                subtitle: {
                    text: '',
                    style: {
                        display: 'none'
                    }
                },
                xAxis: {
                    type: 'datetime',
                    labels: {
                        overflow: 'justify'
                    }
                },
                yAxis: {
                    title: {
                        text: $filter('translate')('$I18N_EMAIL_QUEUE_Y_LABEL')
                    },
                    minorGridLineWidth: 0,
                    gridLineWidth: 0,
                    alternateGridColor: null
                },
                tooltip: {
                    valueSuffix: $filter('translate')('$I18N_EMAIL_QUEUE_TOOLTIP_LABEL')
                },
                plotOptions: {
                    spline: {
                        lineWidth: 1,
                        color: '#004ba0',
                        states: {
                            hover: {
                                lineWidth: 2
                            }
                        },
                        marker: {
                            enabled: false
                        }
                    }
                },
                series: [{
                        name: $filter('translate')('$I18N_COMMON_EMAIL_TRAFFIC_INBOUND_LABEL'),
                        showInLegend: false,
                    }],
                exporting: defaultExporting,
                navigation: dashboardCommonsService.getNavigationConfig()
            };
        }
        function getRejectionChartConfig(rejections) {
            return {
                chart: {
                    className: 'panel-half-padding-bottom',
                    type: 'area'
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: '',
                    style: {
                        display: 'none'
                    }
                },
                subtitle: {
                    text: '',
                    style: {
                        display: 'none'
                    }
                },
                xAxis: {
                    type: 'datetime',
                    minorTickLength: 0,
                    tickLength: 0,
                    labels: {
                        overflow: 'justify'
                    }
                },
                yAxis: {
                    title: {
                        text: ''
                    },
                    minorGridLineWidth: 0,
                    gridLineWidth: 0,
                    alternateGridColor: null,
                    labels: {
                        overflow: 'justify'
                    }
                },
                tooltip: {
                    valueSuffix: $filter('translate')('$I18N_REJECTION_TOOLTIP_COUNT_LABEL')
                },
                plotOptions: {
                    area: {
                        lineWidth: 1,
                        color: '#004ba0',
                        fillColor: '#f0f0f1',
                        states: {
                            hover: {
                                lineWidth: 2
                            }
                        },
                        marker: {
                            enabled: false
                        },
                    }
                },
                series: [{
                        name: $filter('translate')('$I18N_REJECTION_TOOLTIP_LABEL'),
                        showInLegend: false,
                        data: rejections
                    }],
                exporting: {
                    enabled: false
                }
            };
        }
        function getEmailQueueConfig(queueDate) {
            const queueStats = [];
            angular.forEach(queueDate, function (data) {
                queueStats.push({ date: data.date, count: data.count });
            });
            return { queueStats };
        }
        function getTotalEmailTrafficConfig(emailStats) {
            const inboundCount = [];
            const outboundCount = [];
            const internalCount = [];
            angular.forEach(emailStats, function (data) {
                inboundCount.push({ date: data.date, count: data.inboundCount });
                outboundCount.push({ date: data.date, count: data.outboundCount });
                internalCount.push({ date: data.date, count: data.internalCount });
            });
            return { inboundCount, outboundCount, internalCount };
        }
    }]);
