"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BiPolicyCreatePageComponent = void 0;
const policy_actions_1 = require("../../actions/policy.actions");
const policy_actions_2 = require("../../../../../swg/policies/actions/policy.actions");
const swgReducers = require("app-new/swg/policies/reducers");
class BiPolicyCreatePageComponent {
    constructor(store, swgService, capabilitiesService) {
        this.store = store;
        this.swgService = swgService;
        this.capabilitiesService = capabilitiesService;
    }
    ngOnInit() {
        // this.policy$ = this.store.select(reducers.getBiPolicy);
        this.swgPolicy$ = this.store.select(swgReducers.getSwgPolicy);
        this.apiIsProcessing$ = this.store.select(swgReducers.getApiIsProcessing);
        this.wizardFlowType$ = this.store.select(swgReducers.getWizardFlowType);
        this.loadingForEdit$ = this.store.select(swgReducers.getLoadingForEdit);
        this.isBiEmailOnlyCustomer = this.swgService.hasBiForEmailOnly();
        this.hasBiPolicyEditPermission$ = this.capabilitiesService.hasCapability('Permission.BI_CONFIGURE_POLICIES_EDIT');
    }
    updateSettingsInStore($event) {
        this.store.dispatch(new policy_actions_2.BiSavePolicyUpdateSettings($event));
    }
    updateBiPolicyDetailsStep($event) {
        this.store.dispatch(new policy_actions_2.BiUpdatePolicyDetailsStep($event));
    }
    createPolicy() {
        this.store.dispatch(new policy_actions_1.BiSavePolicy());
    }
    removeAffectedUsers($event) {
        this.store.dispatch(new policy_actions_2.SwgRemoveAffectedUsersStep($event));
    }
}
exports.BiPolicyCreatePageComponent = BiPolicyCreatePageComponent;
