"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Calendar = void 0;
const contents_actions_1 = require("app-new/components/tree/actions/contents.actions");
const content_type_1 = require("app-new/sync-recover/exchange/containers/snapshot/detail/models/content-type/content-type");
class Calendar extends content_type_1.ContentType {
    constructor() {
        super(['organiser', 'attendees', 'subject', 'start', 'end']);
        this.CONTENT_TYPE = 'CALENDAR';
    }
    getLoadContentsAction(payload) {
        return new contents_actions_1.LoadFolderCalendarsAction(payload);
    }
    getContentType() {
        return this.CONTENT_TYPE;
    }
    getSearchContentsAction(payload) {
        return new contents_actions_1.LoadSearchFolderCalendarsAction(payload);
    }
}
exports.Calendar = Calendar;
