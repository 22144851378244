"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const create_cybergraph_policy_page_component_1 = require("./containers/create-cybergraph-policy-page/create-cybergraph-policy-page.component");
const cybergraph_policies_page_component_1 = require("./containers/cybergraph-policies-page.component");
angular
    .module('cyberGraphPolicy', [])
    .directive('mcCyberGraphPoliciesPage', static_1.downgradeComponent({
    component: cybergraph_policies_page_component_1.CyberGraphPoliciesPageComponent
}))
    .directive('mcCreateCyberGraphPolicyPage', static_1.downgradeComponent({
    component: create_cybergraph_policy_page_component_1.CreateCyberGraphPolicyPageComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        const wizardBreadcrumbs = [
            { label: '$I18N_SERVICES_BREADCRUMBS_FIRST_LEVEL' },
            { label: '$I18N_SERVICES_CYBERGRAPH.BREADCRUMBS.SECOND_LEVEL' },
            { label: '$I18N_SERVICES_CYBERGRAPH.BREADCRUMBS.THIRD_LEVEL_POLICIES' }
        ];
        $stateProvider
            .state('cybergraph-policies', {
            url: '/services/cybergraph/policies',
            data: {
                tabTitle: '$I18N_SERVICES_CYBERGRAPH.POLICIES.TAB_TITLE',
                tabId: 'CyberGraph Policy',
                isFixedLayout: false,
                checkProgress: false,
                tabReload: true,
                capabilities: 'Temporary.CyberGraph.CreatePolicy && Permission.CYBERGRAPH_EDIT',
                breadcrumbs: [
                    {
                        label: '$I18N_SERVICES_CYBERGRAPH.BREADCRUMBS.THIRD_LEVEL_POLICIES',
                        stateName: '',
                        stateParams: { tabName: 'overview' }
                    }
                ]
            },
            views: {
                main: {
                    template: '<mc-cyber-graph-policies-page></mc-cyber-graph-policies-page>'
                }
            }
        })
            .state('create-cybergraph-policy', {
            params: { isEditFlow: false },
            url: '/services/cybergraph/create-a-policy',
            data: {
                tabTitle: '$I18N_SERVICES_CYBERGRAPH.CREATE_POLICY.TAB_TITLE',
                isFixedLayout: false,
                checkProgress: false,
                capabilities: 'Temporary.CyberGraph.CreatePolicy && Permission.CYBERGRAPH_EDIT',
                breadcrumbs: wizardBreadcrumbs,
                tabId: 'CyberGraph Policy'
            },
            views: {
                main: {
                    template: '<mc-create-cyber-graph-policy-page></mc-create-cyber-graph-policy-page>'
                }
            },
            onEnter: [
                '$stateParams',
                function ($stateParams) {
                    wizardBreadcrumbs[wizardBreadcrumbs.length - 1] = {
                        label: !$stateParams.isEditFlow
                            ? '$I18N_SERVICES_CYBERGRAPH.CREATE_POLICY.BREADCRUMBS.THIRD_LEVEL_CREATE_POLICY'
                            : '$I18N_SERVICES_CYBERGRAPH.BREADCRUMBS.THIRD_LEVEL_EDIT_A_POLICY'
                    };
                }
            ]
        });
    }
]);
