"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgDownloadEndpointsListFailureAction = exports.SwgDownloadEndpointsListSuccessAction = exports.SwgExportEndpointsListAction = exports.SwgExportEndpointsListActions = void 0;
var SwgExportEndpointsListActions;
(function (SwgExportEndpointsListActions) {
    SwgExportEndpointsListActions["EXPORT_ENDPOINTS_LIST"] = "[SwgEndpointList] Export Endpoints List";
    SwgExportEndpointsListActions["DOWNLOAD_ENDPOINTS_LIST_SUCCESS"] = "[SwgEndpointList] Download Endpoints List Success";
    SwgExportEndpointsListActions["DOWNLOAD_ENDPOINTS_LIST_FAILURE"] = "[SwgEndpointList] Download Endpoints List Failure";
})(SwgExportEndpointsListActions = exports.SwgExportEndpointsListActions || (exports.SwgExportEndpointsListActions = {}));
class SwgExportEndpointsListAction {
    constructor(tableColumns) {
        this.tableColumns = tableColumns;
        this.type = SwgExportEndpointsListActions.EXPORT_ENDPOINTS_LIST;
    }
}
exports.SwgExportEndpointsListAction = SwgExportEndpointsListAction;
class SwgDownloadEndpointsListSuccessAction {
    constructor() {
        this.type = SwgExportEndpointsListActions.DOWNLOAD_ENDPOINTS_LIST_SUCCESS;
    }
}
exports.SwgDownloadEndpointsListSuccessAction = SwgDownloadEndpointsListSuccessAction;
class SwgDownloadEndpointsListFailureAction {
    constructor() {
        this.type = SwgExportEndpointsListActions.DOWNLOAD_ENDPOINTS_LIST_FAILURE;
    }
}
exports.SwgDownloadEndpointsListFailureAction = SwgDownloadEndpointsListFailureAction;
