<mc-view-policy
  [policyDetails]="policyDetails$ | async"
  [loading]="policyLoading$ | async"
  (onLoadDetails)="loadDetails($event)"
  (onEdit)="edit()"
  (onClose)="close()"
  (showDelete)="showDelete()"
  (onEnableDisable)="enableDisable($event)"
  [paginationInfo]="paginationInfo$ | async"
></mc-view-policy>
