"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CloseSaveApiApplicationAction = exports.UpdateApiApplicationSuccessAction = exports.UpdateApiApplicationAction = exports.CreateApiApplicationSuccessAction = exports.CreateApiApplicationAction = exports.InputApiApplicationStatusAction = exports.InputApiApplicationSettingsAction = exports.InputApiApplicationDetailsAction = exports.ApiGatewaySaveActionTypes = void 0;
var ApiGatewaySaveActionTypes;
(function (ApiGatewaySaveActionTypes) {
    ApiGatewaySaveActionTypes["InputApiApplicationDetails"] = "[ApiApplication/ApiGateway/Save] Input Api Application Details";
    ApiGatewaySaveActionTypes["InputApiApplicationSettings"] = "[ApiApplication/ApiGateway/Save] Input Api Application Settings";
    ApiGatewaySaveActionTypes["InputApiApplicationStatus"] = "[ApiApplication/ApiGateway/Save] Input Api Application Status";
    ApiGatewaySaveActionTypes["CreateApiApplication"] = "[ApiApplication/ApiGateway/Save] Create Api Application";
    ApiGatewaySaveActionTypes["CreateApiApplicationSuccess"] = "[ApiApplication/ApiGateway/Save] Create Api Application Success";
    ApiGatewaySaveActionTypes["UpdateApiApplication"] = "[ApiApplication/ApiGateway/Save] Update Api Application";
    ApiGatewaySaveActionTypes["UpdateApiApplicationSuccess"] = "[ApiApplication/ApiGateway/Save] Update Api Application Success";
    ApiGatewaySaveActionTypes["CloseSaveApiApplication"] = "[ApiApplication/ApiGateway/Save] Close Save Api Application";
})(ApiGatewaySaveActionTypes = exports.ApiGatewaySaveActionTypes || (exports.ApiGatewaySaveActionTypes = {}));
class InputApiApplicationDetailsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewaySaveActionTypes.InputApiApplicationDetails;
    }
}
exports.InputApiApplicationDetailsAction = InputApiApplicationDetailsAction;
class InputApiApplicationSettingsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewaySaveActionTypes.InputApiApplicationSettings;
    }
}
exports.InputApiApplicationSettingsAction = InputApiApplicationSettingsAction;
class InputApiApplicationStatusAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewaySaveActionTypes.InputApiApplicationStatus;
    }
}
exports.InputApiApplicationStatusAction = InputApiApplicationStatusAction;
class CreateApiApplicationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewaySaveActionTypes.CreateApiApplication;
    }
}
exports.CreateApiApplicationAction = CreateApiApplicationAction;
class CreateApiApplicationSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewaySaveActionTypes.CreateApiApplicationSuccess;
    }
}
exports.CreateApiApplicationSuccessAction = CreateApiApplicationSuccessAction;
class UpdateApiApplicationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewaySaveActionTypes.UpdateApiApplication;
    }
}
exports.UpdateApiApplicationAction = UpdateApiApplicationAction;
class UpdateApiApplicationSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewaySaveActionTypes.UpdateApiApplicationSuccess;
    }
}
exports.UpdateApiApplicationSuccessAction = UpdateApiApplicationSuccessAction;
class CloseSaveApiApplicationAction {
    constructor() {
        this.type = ApiGatewaySaveActionTypes.CloseSaveApiApplication;
    }
}
exports.CloseSaveApiApplicationAction = CloseSaveApiApplicationAction;
