<section class="mc-mep-policy-details-step">
  <mc-wizard-step-header [label]="isDefaultPolicy() ?
        '$I18N_MEP_POLICY_DETAILS_STEP.DEFAULT_POLICY_DETAILS_STEP_DESC' :
        '$I18N_MEP_POLICY_DETAILS_STEP.STEP_DESC' | translate"></mc-wizard-step-header>
  <form
    class="form-horizontal"
    mcDefaultLabelClass="col-sm-3"
    mcDefaultControlContainerClass="col-sm-9"
    [formGroup]="mepPolicyDetails"
  >
    <div class="panel-padding-bottom mc-mep-policy-name">
      <mc-text-field
        label="$I18N_MEP_POLICY_DETAILS_STEP.NAME_FIELD.LABEL"
        errorPrefix="$I18N_MEP_POLICY_DETAILS_STEP.NAME_FIELD.VALIDATION_ERROR"
        formControlName="name"
        required
        [spellCheck]="true"
        [disabled]="isDefaultPolicy()"
        [setFocus]="true"
      ></mc-text-field>
    </div>

    <div class="panel-padding-bottom mc-mep-policy-description">
      <mc-text-area-field
        label="$I18N_MEP_POLICY_DETAILS_STEP.DESCRIPTION_FIELD.LABEL"
        errorPrefix="$I18N_MEP_POLICY_DETAILS_STEP.DESCRIPTION_FIELD.VALIDATION_ERROR"
        formControlName="description"
        [spellCheck]="true"
      ></mc-text-area-field>
    </div>

    <div class="panel-padding-bottom mc-mep-policy-action">

      <label class="col-sm-3 action-title control-label">
        {{ '$I18N_MEP_POLICY_DETAILS_STEP.ACTIONS.LABEL' | translate }}
      </label>
      <div class="col-sm-9">

        <div
          class="row panel-half-margin-bottom panel-half-margin-top"
        >
          <div class="col-sm-1 radio-btn-container">
            <input
              type="radio"
              formControlName="action"
              id="actionHold"
              data-test="actionHold"
              [value]="actions[0]"
            >
          </div>
          <div class="col-sm-11">
            <label for="actionHold" class="action-label">
              <strong>
                {{ '$I18N_MEP_POLICY_DETAILS_STEP.ACTIONS.HOLD.LABEL' | translate }}
              </strong>
              <br>
              <span>
                {{ '$I18N_MEP_POLICY_DETAILS_STEP.ACTIONS.HOLD.DESCRIPTION' | translate }}
              </span>
            </label>
          </div>
        </div>

        <div class="row panel-half-margin-bottom panel-half-margin-top">
          <div class="col-sm-1 radio-btn-container">
            <input
              type="radio"
              formControlName="action"
              id="actionNoAction"
              data-test="actionNoAction"
              [value]="actions[1]"
            >
          </div>
          <div class="col-sm-11">
            <label for="actionNoAction" class="action-label">
              <strong>
                {{ '$I18N_MEP_POLICY_DETAILS_STEP.ACTIONS.NO_ACTION.LABEL' | translate }}
              </strong>
              <br>
              <span>
                {{ '$I18N_MEP_POLICY_DETAILS_STEP.ACTIONS.NO_ACTION.DESCRIPTION' | translate }}
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="panel-padding-bottom mc-mep-policy-auto-release" *ngIf="!isNoActionSelected()">
      <mc-mep-form-select
        [label]="'$I18N_MEP_POLICY_DETAILS_STEP.AUTO_RELEASE.LABEL'"
        [placeholder]="'$I18N_MEP_POLICY_DETAILS_STEP.AUTO_RELEASE.PLACEHOLDER'"
        formControlName="autoReleaseTimeoutMinutes"
        [options]="autoReleaseOptions"
        [useTranslation]="false"
      >
        <mc-mep-tooltip tooltipTitle="autoRelease"></mc-mep-tooltip>
      </mc-mep-form-select>
    </div>

    <div class="panel-padding-bottom mc-mep-policy-matching-level">
      <label class="col-sm-3 matching-level-title control-label">
        <span id="matchLevelLabel">
          {{ '$I18N_MEP_POLICY_DETAILS_STEP.MATCHING_LEVEL.LABEL' | translate }}
        </span>
        <mc-mep-tooltip tooltipTitle="matchLevel"></mc-mep-tooltip>
      </label>
      <div class="col-sm-9">
        <div class="row panel-half-margin-bottom panel-half-margin-top">
          <div class="col-sm-1 radio-btn-container">
            <input
              type="radio"
              formControlName="matchLevel"
              id="levelLow"
              data-test="levelLow"
              [value]="levels[0]"
            >
          </div>
          <div class="col-sm-11">
            <label for="levelLow">
              <strong>
                {{ '$I18N_MEP_POLICY_DETAILS_STEP.MATCHING_LEVEL.LEVELS.LOW' | translate }}
              </strong>
            </label>
          </div>
        </div>
        <div class="row panel-half-margin-bottom">
          <div class="col-sm-1 radio-btn-container">
            <input
              type="radio"
              formControlName="matchLevel"
              id="levelHigh"
              data-test="levelHigh"
              [value]="levels[1]"
            >
          </div>
          <div class="col-sm-11">
            <label for="levelHigh">
              <strong>
                {{ '$I18N_MEP_POLICY_DETAILS_STEP.MATCHING_LEVEL.LEVELS.HIGH' | translate }}
              </strong>
            </label>
          </div>
        </div>
      </div>
    </div>
  </form>
</section>
