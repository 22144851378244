"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListComponent = void 0;
const lexicon_service_1 = require("../../services/lexicon.service");
const rule_service_1 = require("../../services/rule.service");
const queue_service_1 = require("../wizard/services/queue.service");
class ListComponent {
    constructor(capabilitiesService, tabService, store, stateService) {
        this.capabilitiesService = capabilitiesService;
        this.tabService = tabService;
        this.store = store;
        this.stateService = stateService;
        this.callerWizardId = this.stateService.$state.params.callerWizardId;
        this.wizardToTabIndex = {
            [lexicon_service_1.LexiconService.WizardId]: 1,
            [queue_service_1.QueueService.WizardId]: 2,
            [rule_service_1.RuleService.WizardId]: 3
        };
        const tab = this.tabService.getSelectedTab();
        tab.onRefresh = () => {
            this.dashboardStats.getStatistics();
            this.lexiconList.dispatchFilterAction();
            this.queueList.dispatchFilterAction();
            this.ruleList.dispatchFilterAction();
        };
    }
    get preRuleCreationCriteriaMet() {
        return this.queuesFound;
    }
    get selectedTabIndex() {
        const index = this.wizardToTabIndex[this.callerWizardId];
        return index ? index : 0;
    }
    ngOnInit() {
        this.hasMonthlyScheduleCapability$ = this.capabilitiesService.hasCapability('Temporary.Monthly.Rule.Schedule');
        this.hasSupervisionDeleteCapability$ = this.capabilitiesService.hasCapability('Temporary.Supervision.Delete');
        this.hasEditPermission$ = this.capabilitiesService.hasCapability('Permission.SUPERVISION_EDIT');
        this.queuesDataSubscription = this.queueList.tableData$.subscribe((data) => {
            this.queuesFound = data && data.length > 0;
            if (data) {
                this.queuesDataSubscription.unsubscribe();
            }
        });
    }
    ngOnDestroy() {
        if (this.queuesDataSubscription) {
            this.queuesDataSubscription.unsubscribe();
        }
        if (this.overviewEnabledSubs) {
            this.overviewEnabledSubs.unsubscribe();
        }
    }
}
exports.ListComponent = ListComponent;
