"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgActivityReportEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const swg_activity_report_actions_1 = require("../actions/swg-activity-report.actions");
class SwgActivityReportEffects {
    constructor(actions$, notificationService, translateService, swgActivityReportApiService) {
        this.actions$ = actions$;
        this.notificationService = notificationService;
        this.translateService = translateService;
        this.swgActivityReportApiService = swgActivityReportApiService;
        this.getCategories$ = this.actions$.pipe(effects_1.ofType(swg_activity_report_actions_1.SwgActivityReportActions.SWG_ACTIVITY_REPORT_GET_CATEGORIES_ACTION), operators_1.switchMap(() => {
            return this.swgActivityReportApiService.getCategoriesWithDeprecated().pipe(operators_1.map((response) => {
                return new swg_activity_report_actions_1.SwgActivityReportGetCategoriesSuccessAction(response);
            }), operators_1.catchError(() => this.swgActivityReportFailAction('$I18N_ACTIVITY_REPORT.NOTIFICATION.ERRORS.GET_CATEGORIES')));
        }));
        this.getApplications$ = this.actions$.pipe(effects_1.ofType(swg_activity_report_actions_1.SwgActivityReportActions.SWG_ACTIVITY_REPORT_GET_APPLICATIONS_ACTION), operators_1.switchMap(() => {
            return this.swgActivityReportApiService.getApplicationsWithDeprecated().pipe(operators_1.map((response) => {
                return new swg_activity_report_actions_1.SwgActivityReportGetApplicationsSuccessAction(response);
            }), operators_1.catchError(() => this.swgActivityReportApplicationFailAction('$I18N_ACTIVITY_REPORT.NOTIFICATION.ERRORS.GET_CATEGORIES')));
        }));
    }
    swgActivityReportFailAction(errorMessage) {
        return rxjs_1.of(new swg_activity_report_actions_1.SwgActivityReportGetCategoriesFailureAction()).pipe(operators_1.tap(() => {
            this.notificationService.error({
                msg: this.translateService.instant(errorMessage)
            });
        }));
    }
    swgActivityReportApplicationFailAction(errorMessage) {
        return rxjs_1.of(new swg_activity_report_actions_1.SwgActivityReportGetApplicationsFailureAction()).pipe(operators_1.tap(() => {
            this.notificationService.error({
                msg: this.translateService.instant(errorMessage)
            });
        }));
    }
}
__decorate([
    effects_1.Effect()
], SwgActivityReportEffects.prototype, "getCategories$", void 0);
__decorate([
    effects_1.Effect()
], SwgActivityReportEffects.prototype, "getApplications$", void 0);
exports.SwgActivityReportEffects = SwgActivityReportEffects;
