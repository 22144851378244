"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KeyGenState = void 0;
var KeyGenState;
(function (KeyGenState) {
    KeyGenState[KeyGenState["account"] = 1] = "account";
    KeyGenState[KeyGenState["authentication"] = 2] = "authentication";
    KeyGenState[KeyGenState["verification"] = 3] = "verification";
    KeyGenState[KeyGenState["summary"] = 4] = "summary";
})(KeyGenState = exports.KeyGenState || (exports.KeyGenState = {}));
