"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiCrowdstrikeIntegrationActivityLogsAsideViewComponent = void 0;
const core_1 = require("@angular/core");
class ApiCrowdstrikeIntegrationActivityLogsAsideViewComponent {
    constructor() {
        this.onCloseClicked = new core_1.EventEmitter();
    }
}
exports.ApiCrowdstrikeIntegrationActivityLogsAsideViewComponent = ApiCrowdstrikeIntegrationActivityLogsAsideViewComponent;
