"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.DashboardSettingsFactory = void 0;
const dashboard_settings_builder_1 = require("../model/supervision/dashboard/settings/dashboard-settings.builder");
const queue_type_enum_1 = require("../types/queue-type.enum");
const ɵ0 = (settings) => {
    const builder = new dashboard_settings_builder_1.DashboardSettingsBuilder()
        .withModifiedBy(settings['modifiedBy'])
        .withModified(settings['modified'] && new Date(settings['modified']))
        .withQueueType(settings['queueType'] === 'review' ? queue_type_enum_1.QueueType.REVIEW : queue_type_enum_1.QueueType.ESCALATION)
        .withQueues(settings['queues']);
    const percentages = settings['pendingPercentages'];
    if (percentages !== undefined) {
        builder.withPendingPercentages([
            percentages['greenToYellow'] || 0,
            percentages['yellowToRed'] || 0
        ]);
    }
    const ages = settings['oldestQueueMessages'];
    if (ages !== undefined) {
        builder.withOldestQueueMessages([ages['greenToYellow'] || 0, ages['yellowToRed'] || 0]);
    }
    return builder.build();
};
exports.ɵ0 = ɵ0;
exports.DashboardSettingsFactory = {
    BUILD_SETTINGS: ɵ0
};
