"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApPolicyEffects = void 0;
const effects_1 = require("@ngrx/effects");
const policy_actions_1 = require("../../actions/policy.actions");
const operators_1 = require("rxjs/operators");
const actions = require("../../actions/policy.actions");
const rxjs_1 = require("rxjs");
const notification_actions_1 = require("../../../../../components/notification/actions/notification.actions");
const cancel_policy_modal_component_1 = require("../../components/cancel-policy-modal/cancel-policy-modal.component");
const tableStore = require("@mimecast-ui/table-store");
class ApPolicyEffects {
    constructor(store, stateService, appolicyApiService, translationService, modalService, apCreatePolicyService, actions$) {
        this.store = store;
        this.stateService = stateService;
        this.appolicyApiService = appolicyApiService;
        this.translationService = translationService;
        this.modalService = modalService;
        this.apCreatePolicyService = apCreatePolicyService;
        this.actions$ = actions$;
        this.goToCreatePage$ = this.actions$.pipe(effects_1.ofType(policy_actions_1.ApPolicyCreateTypesEnum.NAVIGATE_TO_CREATE_ATTACHMENT_PROTECTION_POLICY), operators_1.tap(() => {
            this.stateService.$state.go('attachment-protection-policy', {
                isEditFlow: false
            });
        }));
        this.goToEditPage$ = this.actions$.pipe(effects_1.ofType(policy_actions_1.ApPolicyCreateTypesEnum.NAVIGATE_TO_EDIT_ATTACHMENT_PROTECTION_POLICY), operators_1.tap(() => {
            this.stateService.$state.go('attachment-protection-policy', {
                isEditFlow: true
            });
        }));
        this.goToListPage$ = this.actions$.pipe(effects_1.ofType(policy_actions_1.ApPolicyCreateTypesEnum.NAVIGATE_TO_ATTACHMENT_PROTECTION_LIST_PAGE), operators_1.tap(() => {
            this.stateService.$state.go('attachment-protection-policy-list');
        }));
        this.getAttributesList$ = this.actions$.pipe(effects_1.ofType(policy_actions_1.ApPolicyCreateTypesEnum.AP_ATTRIBUTE_APPLIES_TO_STEP), operators_1.switchMap(() => {
            return this.apCreatePolicyService.getApAttributes().pipe(operators_1.map(attributeList => this.apCreatePolicyService.createAtrributesList(attributeList)), operators_1.map((attributeOptions) => new policy_actions_1.ApPopulateAttributes(attributeOptions)), operators_1.catchError(error => rxjs_1.of(this.getNotificationAction(error))));
        }));
        this.showPolicyCancelModal$ = this.actions$.pipe(effects_1.ofType(actions.ApPolicyCreateTypesEnum.AP_CANCEL_CONFIRMATION_MODAL_OPEN), operators_1.map((action) => action.payload), operators_1.switchMap((cancelSource) => {
            this.cancelPolicyModalInstance = this.modalService.open(cancel_policy_modal_component_1.ApCancelPolicyModalComponent, {
                size: 'md',
                hasBackdrop: true,
                data: cancelSource
            });
            return rxjs_1.EMPTY;
        }));
        this.showPolicyList$ = this.actions$.pipe(effects_1.ofType(actions.ApPolicyCreateTypesEnum.CANCEL_AP_CONFIRMATION_MODAL_YES_RESPONSE), operators_1.filter(() => !!this.cancelPolicyModalInstance), operators_1.switchMap(() => {
            this.cancelPolicyModalInstance.close();
            this.cancelPolicyModalInstance = undefined;
            this.store.dispatch(new actions.ApClearPolicyAction());
            this.stateService.$state.go('attachment-protection-policy-list');
            return rxjs_1.EMPTY;
        }));
        this.closeApPolicyCloseModal$ = this.actions$.pipe(effects_1.ofType(actions.ApPolicyCreateTypesEnum.CANCEL_AP_CONFIRMATION_MODAL_NO_RESPONSE), operators_1.filter(() => !!this.cancelPolicyModalInstance), operators_1.switchMap(() => {
            this.cancelPolicyModalInstance.close();
            this.cancelPolicyModalInstance = undefined;
            return rxjs_1.EMPTY;
        }));
        this.createApPolicy$ = this.actions$.pipe(effects_1.ofType(actions.ApPolicyCreateTypesEnum.ATTACHMENT_PROTECTION_CREATE_POLICY), operators_1.map((action) => action.payload), operators_1.switchMap(apPolicy => {
            return this.appolicyApiService.createPolicy(apPolicy).pipe(operators_1.mergeMap(() => {
                let message = '';
                message = '$I18N_AP_CREATE_POLICY_POLICY_CREATED';
                this.stateService.$state.go('attachment-protection-policy-list');
                return [
                    new notification_actions_1.NotificationShowAction({
                        type: 'success',
                        config: {
                            msg: message
                        }
                    }),
                    new actions.CreateApSuccessAction()
                ];
            }), operators_1.catchError(error => rxjs_1.merge(rxjs_1.of(this.getNotificationAction(error)), rxjs_1.of(new actions.CreateApFailAction()))));
        }));
        this.deletePolicy$ = this.actions$.pipe(effects_1.ofType(actions.ApPolicyCreateTypesEnum.AP_POLICY_DELETE), operators_1.map((action) => action.payload), operators_1.switchMap((data) => {
            return this.apCreatePolicyService.deleteApPolicy(data).pipe(operators_1.mergeMap(() => {
                return [
                    new notification_actions_1.NotificationShowAction({
                        type: 'success',
                        config: {
                            msg: this.translationService.instant('$I18N_AP_CREATE_POLICY_POLICY_DELETED')
                        }
                    }),
                    new actions.ApDeletePolicySuccess(),
                    new actions.ApDeleteModalCloseAction(),
                    new tableStore.LoadAction({ tableId: 'ApPolicyList' })
                ];
            }), operators_1.catchError(error => rxjs_1.merge(rxjs_1.of(this.getNotificationAction(error)), rxjs_1.of(new actions.ApDeletePolicyFailure()))));
        }));
    }
    getNotificationAction(error) {
        const msg = error.fail
            ? error.fail[0].errors[0].message
            : this.translationService.instant('$I18N_AP_CREATE_POLICY_SERVER_ERROR');
        return new notification_actions_1.NotificationShowAction({
            type: 'error',
            config: {
                msg
            }
        });
    }
}
__decorate([
    effects_1.Effect({ dispatch: false })
], ApPolicyEffects.prototype, "goToCreatePage$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], ApPolicyEffects.prototype, "goToEditPage$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], ApPolicyEffects.prototype, "goToListPage$", void 0);
__decorate([
    effects_1.Effect()
], ApPolicyEffects.prototype, "getAttributesList$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], ApPolicyEffects.prototype, "showPolicyCancelModal$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], ApPolicyEffects.prototype, "showPolicyList$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], ApPolicyEffects.prototype, "closeApPolicyCloseModal$", void 0);
__decorate([
    effects_1.Effect()
], ApPolicyEffects.prototype, "createApPolicy$", void 0);
__decorate([
    effects_1.Effect()
], ApPolicyEffects.prototype, "deletePolicy$", void 0);
exports.ApPolicyEffects = ApPolicyEffects;
