"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgCsvUploaderContainerComponent = void 0;
const file_parse_actions_1 = require("../../actions/file-parse.actions");
const policyActions = require("../../actions/policy.actions");
const sidebar = require("../../actions/sidebar.actions");
const reducers = require("../../reducers");
class SwgCsvUploaderContainerComponent {
    constructor(store) {
        this.store = store;
    }
    ngOnInit() {
        this.content$ = this.store.select(reducers.getContents);
        this.existingDomains$ = this.store.select(reducers.getDomains);
        this.isProcessing$ = this.store.select(reducers.isProcessing);
        this.fileName$ = this.store.select(reducers.getFileName);
        this.hasError$ = this.store.select(reducers.gethasError);
    }
    onReadFile(event) {
        this.store.dispatch(new file_parse_actions_1.SwgPolicyParseFile(event));
    }
    onUpdateDomains(event) {
        this.store.dispatch(new sidebar.SwgDomainsCsvUploaderClose());
        this.store.dispatch(new policyActions.SwgAppendDomainsStep(event));
        this.store.dispatch(new file_parse_actions_1.SwgPolicyParseFileReset());
    }
    onCancel() {
        this.store.dispatch(new sidebar.SwgDomainsCsvUploaderClose());
        this.store.dispatch(new file_parse_actions_1.SwgPolicyParseFileReset());
    }
}
exports.SwgCsvUploaderContainerComponent = SwgCsvUploaderContainerComponent;
