<mc-layout-detail-wizard
  keyTitle="{{
    ('$I18N_API_GATEWAY_APPLICATIONS_KEYGEN_WIZARD.TITLE' | translate) + application.applicationName
  }}"
>
  <mc-wizard (changeStep)="changeStep()" [ngClass]="{ 'wizard-busy': isBusy }">
    <mc-wizard-step label="$I18N_API_GATEWAY_APPLICATIONS_KEYGEN_WIZARD.ACCOUNT_STEP.TITLE">
      <mc-api-gateway-keygen-account-step
        *ngIf="!isBusy"
        [application]="application"
        [isError]="isError"
        [errorMessage]="errorMessage"
        [account]="account"
        (onAccountUpdated)="updateAccount($event)"
        (onSubmitted)="nextStep()"
      >
      </mc-api-gateway-keygen-account-step>
      <div class="spinner-wrapper">
        <mc-spinner *ngIf="isBusy"></mc-spinner>
      </div>
    </mc-wizard-step>

    <mc-wizard-step label="$I18N_API_GATEWAY_APPLICATIONS_KEYGEN_WIZARD.AUTHENTICATION_STEP.TITLE">
      <mc-api-gateway-keygen-authentication-step
        *ngIf="!isBusy"
        [application]="application"
        [isError]="isError"
        [errorMessage]="errorMessage"
        [account]="account"
        [authenticationTypes]="availableAuthenticationTypes"
        (onAuthenticationUpdated)="updateAuthentication($event)"
        (onSubmitted)="nextStep()"
      >
      </mc-api-gateway-keygen-authentication-step>
      <div class="spinner-wrapper">
        <mc-spinner *ngIf="isBusy"></mc-spinner>
      </div>
    </mc-wizard-step>

    <mc-wizard-step label="$I18N_API_GATEWAY_APPLICATIONS_KEYGEN_WIZARD.VERIFICATION_STEP.TITLE">
      <mc-api-gateway-keygen-verification-step
        *ngIf="!isBusy"
        [application]="application"
        [isError]="isError"
        [errorMessage]="errorMessage"
        [account]="account"
        [verificationNotification]="verificationNotification"
        (onVerificationUpdated)="updateVerification($event)"
        (onSubmitted)="nextStep()"
      >
      </mc-api-gateway-keygen-verification-step>
      <div class="spinner-wrapper">
        <mc-spinner *ngIf="isBusy"></mc-spinner>
      </div>
    </mc-wizard-step>

    <mc-wizard-step label="$I18N_API_GATEWAY_APPLICATIONS_KEYGEN_WIZARD.SUMMARY_STEP.TITLE">
      <mc-api-gateway-keygen-summary-step
        *ngIf="!isBusy"
        [application]="application"
        [apiKeys]="apiKeys"
        (onCopiedToClipboard)="copiedToClipbard($event)"
        (onComplete)="complete($event)"
        [isVerificationApiError]="isVerificationApiError"
        [errorMessageVerificationApi]="errorMessageVerificationApi"
      >
      </mc-api-gateway-keygen-summary-step>
      <div class="spinner-wrapper">
        <mc-spinner *ngIf="isBusy"></mc-spinner>
      </div>
    </mc-wizard-step>
  </mc-wizard>

  <mc-footer-container>
    <div class="pull-right no-select mc-button-footer">
      <button *ngIf="!isLastStep" class="btn" (click)="cancel()">
        {{ '$I18N_API_GATEWAY_APPLICATIONS_KEYGEN_WIZARD.ACTIONS.CANCEL' | translate }}
      </button>
      <button
        *ngIf="hasPreviousStep && !isLastStep"
        class="btn btn-secondary panel-half-margin-right"
        [disabled]="isBusy || wizard.currentStep !== KeyGenState.authentication"
        (click)="previousStep()"
      >
        {{ '$I18N_API_GATEWAY_APPLICATIONS_KEYGEN_WIZARD.ACTIONS.BACK' | translate }}
      </button>
      <button
        *ngIf="hasNextStep"
        class="btn btn-primary"
        [disabled]="!isStepValid || isBusy"
        (click)="nextStep()"
      >
        {{ '$I18N_API_GATEWAY_APPLICATIONS_KEYGEN_WIZARD.ACTIONS.NEXT' | translate }}
      </button>
      <mc-live-button
        *ngIf="isLastStep"
        typeClass="mc-btn btn btn-primary primary"
        content="{{ '$I18N_API_GATEWAY_APPLICATIONS_KEYGEN_WIZARD.ACTIONS.CLOSE' | translate }}"
        (click)="complete()"
      >
      </mc-live-button>
    </div>
  </mc-footer-container>
</mc-layout-detail-wizard>
