"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationEffects = void 0;
const effects_1 = require("@ngrx/effects");
const operators_1 = require("rxjs/operators");
const notificationAction = require("../../actions/notification.actions");
class NotificationEffects {
    constructor(actions$, ts, notificationService) {
        this.actions$ = actions$;
        this.ts = ts;
        this.notificationService = notificationService;
        this.notificationShow$ = this.actions$.pipe(effects_1.ofType(notificationAction.NOTIFICATION_SHOW), operators_1.tap((action) => {
            const config = Object.assign(Object.assign({}, action.payload.config), { msg: action.payload.config.msg && this.ts.instant(action.payload.config.msg) });
            this.notificationService[action.payload.type](config);
        }));
    }
}
__decorate([
    effects_1.Effect({ dispatch: false })
], NotificationEffects.prototype, "notificationShow$", void 0);
exports.NotificationEffects = NotificationEffects;
