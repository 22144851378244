"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMetadata = exports.getRequestPage = exports.getPagination = exports.getSelectedRow = exports.getAcceptedEmailRequestPayload = exports.externalTableSearchCount = exports.getAcceptedEmailList = exports.isTableLoading = exports.reducer = exports.initialState = void 0;
const actions = require("../../actions");
const commonActions = require("../../../common/actions");
exports.initialState = {
    acceptedEmailList: [],
    externalTableSearchCount: 0,
    getAcceptedEmailRequest: {},
    isLoading: false,
    paginatorMetadata: { next: undefined, previous: undefined, pageSize: 50, totalRows: 0 },
    requestedPage: undefined,
    selectedPageSize: 50,
    selectedRow: undefined
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.GET_ACCEPTED_EMAIL_REQUEST:
            return Object.assign(Object.assign({}, state), { acceptedEmailList: [], externalTableSearchCount: 0, getAcceptedEmailRequest: mergeGetAcceptedEmailRequest(state.getAcceptedEmailRequest, action.payload), isLoading: true, paginatorMetadata: {
                    pageSize: state.selectedPageSize,
                    next: undefined,
                    previous: undefined,
                    totalRows: 0
                }, requestedPage: undefined, selectedRow: undefined });
        case actions.GET_ACCEPTED_EMAIL_PAGINATION:
            return Object.assign(Object.assign({}, state), { isLoading: true, paginatorMetadata: Object.assign(Object.assign({}, state.paginatorMetadata), { pageSize: action.payload.pageSize, next: undefined, previous: undefined }), requestedPage: action.payload, selectedPageSize: action.payload.pageSize || state.selectedPageSize });
        case actions.GET_ACCEPTED_EMAIL_REFRESH:
            return Object.assign(Object.assign({}, state), { isLoading: true });
        case actions.GET_ACCEPTED_EMAIL_SUCCESS:
            return Object.assign(Object.assign({}, state), { externalTableSearchCount: action.payload.pagingMetadata.totalRows || 0, acceptedEmailList: [...action.payload.acceptedEmails], isLoading: false, paginatorMetadata: Object.assign({}, action.payload.pagingMetadata), requestedPage: undefined });
        case actions.GET_ACCEPTED_EMAIL_FAIL:
        case actions.GET_ACCEPTED_EMAIL_PAGINATION_STOP_LOADING:
            return Object.assign(Object.assign({}, state), { isLoading: false });
        case actions.GET_ACCEPTED_EMAIL_SELECT_ROW:
            return Object.assign(Object.assign({}, state), { selectedRow: action.payload });
        case commonActions.HIGHLIGHT_SELECTED_ROW:
            return Object.assign(Object.assign({}, state), { selectedRow: action.row });
        case commonActions.CLEAR_SELECTED_ROW:
            return Object.assign(Object.assign({}, state), { selectedRow: undefined });
        case actions.GET_ACCEPTED_EMAIL_RESET_STATE:
            return Object.assign({}, exports.initialState);
        default:
            return state;
    }
}
exports.reducer = reducer;
function mergeGetAcceptedEmailRequest(prev, next) {
    const merged = {
        start: next.start || prev.start,
        end: next.end || prev.end
    };
    if (!!next.filterBy) {
        if (next.filterBy.length > 0) {
            merged.filterBy = [...next.filterBy];
        }
    }
    else if (!!prev.filterBy) {
        merged.filterBy = [...prev.filterBy];
    }
    if (!!next.searchBy) {
        if (!!next.searchBy.fieldName && !!next.searchBy.value) {
            merged.searchBy = Object.assign({}, next.searchBy);
        }
    }
    else if (!!prev.searchBy) {
        merged.searchBy = Object.assign({}, prev.searchBy);
    }
    return merged;
}
const isTableLoading = (state) => state.isLoading;
exports.isTableLoading = isTableLoading;
const getAcceptedEmailList = (state) => {
    return [...state.acceptedEmailList];
};
exports.getAcceptedEmailList = getAcceptedEmailList;
const externalTableSearchCount = (state) => {
    return state.externalTableSearchCount;
};
exports.externalTableSearchCount = externalTableSearchCount;
const getAcceptedEmailRequestPayload = (state) => {
    return Object.assign(Object.assign(Object.assign({}, state.getAcceptedEmailRequest), (!state.getAcceptedEmailRequest.start
        ? { start: new Date(new Date().setDate(new Date().getDate() - 1)) }
        : {})), (!state.getAcceptedEmailRequest.end ? { end: new Date() } : {}));
};
exports.getAcceptedEmailRequestPayload = getAcceptedEmailRequestPayload;
const getSelectedRow = (state) => {
    return state.selectedRow;
};
exports.getSelectedRow = getSelectedRow;
const getPagination = (state) => {
    return Object.assign({}, state.paginatorMetadata);
};
exports.getPagination = getPagination;
const getRequestPage = (state) => {
    if (!!state.requestedPage) {
        return Object.assign({}, state.requestedPage);
    }
    else {
        return {
            pageNumber: 0,
            pageSize: state.selectedPageSize,
            pageToken: undefined
        };
    }
};
exports.getRequestPage = getRequestPage;
const getMetadata = (state) => {
    return {
        pagination: Object.assign({}, state.paginatorMetadata)
    };
};
exports.getMetadata = getMetadata;
