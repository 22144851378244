"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DnsSetupComponent = void 0;
const DnsConstants = require("../dns-constants");
const coreReducers = require("../../../core/reducers");
class DnsSetupComponent {
    constructor(dnsService, coreStore) {
        this.dnsService = dnsService;
        this.coreStore = coreStore;
        this.kbLink = DnsConstants.DNS_SETUP_KB_LINK;
    }
    ngOnInit() {
        this.servers$ = this.dnsService.getDnsServerIps();
        this.capsOverride$ = this.coreStore.select(coreReducers.getCapsOverride);
    }
    redirectToDns() {
        this.capsOverride$.subscribe(capsOverride => {
            let swgDnsConfigUrl = DnsConstants.DNS_SETUP_REDIRECT_LINK;
            const swgDnsCaps = capsOverride['swg.dns.config.url'];
            if ((swgDnsCaps === null || swgDnsCaps === void 0 ? void 0 : swgDnsCaps.enabled) === true) {
                swgDnsConfigUrl = swgDnsCaps.value;
            }
            window.open(swgDnsConfigUrl);
        });
    }
}
exports.DnsSetupComponent = DnsSetupComponent;
