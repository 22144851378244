<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <mc-text-field label="$I18N_REMEDIATION_SEARCH_MESSAGES_BY_ID.MESSAGE_ID_LABEL"
      placeholder="$I18N_REMEDIATION_SEARCH_MESSAGES_BY_ID.MESSAGE_ID_PLACEHOLDER"
      errorPrefix="$I18N_REMEDIATION_SEARCH_MESSAGES_BY_ID.ERRORS"
      formControlName="messageId"
      required>
  </mc-text-field>

  <mc-date-range-picker *ngIf="isIdSearchDateRangeEnabled"
                        label="$I18N_REMEDIATION_SEARCH_MESSAGES_BY_ID.DATE_RANGE_LABEL"
                        [config]="dateRangeConfig"
                        formControlName="dateRange">
  </mc-date-range-picker>

  <button class="btn btn-primary" [disabled]="form.invalid || isSearchInProgress || !isRemediationEnabled" type="submit">
    {{ "$I18N_REMEDIATION_SEARCH_MESSAGES_BY_ID.SUBMIT_BUTTON" | translate }}
  </button>
</form>
