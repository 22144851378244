"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewPolicyDetailsComponent = void 0;
class ViewPolicyDetailsComponent {
    getPolicyActionType() {
        if (this.policyDetails.action === 'redirect') {
            return this.policyDetails.redirectToType === 'user' ? 'REDIRECT_USER' : 'REDIRECT_GROUP';
        }
        return this.policyDetails.action.toUpperCase();
    }
}
exports.ViewPolicyDetailsComponent = ViewPolicyDetailsComponent;
