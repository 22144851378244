"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AsideWrapperService = void 0;
const operators_1 = require("rxjs/operators");
const components_1 = require("@mimecast-ui/components");
/*
  The AsideWrapperService is needed to close all opened asides when navigating to a new route
 */
class AsideWrapperService extends components_1.AsideService {
    constructor() {
        super(...arguments);
        this.refs = [];
    }
    open(componentRef, config) {
        const ref = super.open(componentRef, config);
        this.refs.push(ref);
        ref['afterClosedCallback'].pipe(operators_1.take(1)).subscribe(() => {
            this.refs = this.refs.filter(curRef => curRef !== ref);
        });
        return ref;
    }
    /*
     Dismiss all open modals when navigating to new route.
     This function is called outside by administration-controller.ts
     */
    dismissAllModals() {
        this.refs.forEach(ref => ref === null || ref === void 0 ? void 0 : ref.close());
        this.refs = [];
    }
}
exports.AsideWrapperService = AsideWrapperService;
