"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SecondFactorOptionPipe = void 0;
class SecondFactorOptionPipe {
    constructor(translateService) {
        this.translateService = translateService;
    }
    transform(value) {
        if (value) {
            return this.translateService.instant('$I18N_IDENTITY_PIPES.SECOND_FACTOR_OPTION.' + value);
        }
        return '';
    }
}
exports.SecondFactorOptionPipe = SecondFactorOptionPipe;
