"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DecodeUrlFailAction = exports.DecodeUrlSuccessAction = exports.DecodeUrlAttemptAction = exports.DECODE_URL_FAIL = exports.DECODE_URL_SUCCESS = exports.DECODE_URL_ATTEMPT = void 0;
exports.DECODE_URL_ATTEMPT = '[Services / Url protection / Url Tools / Decode Urls] Decode Url attempt';
exports.DECODE_URL_SUCCESS = '[Services / Url protection / Url Tools / Decode Urls] Decode Url success';
exports.DECODE_URL_FAIL = '[Services / Url protection / Url Tools / Decode Urls] Decode Url fail';
class DecodeUrlAttemptAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.DECODE_URL_ATTEMPT;
    }
}
exports.DecodeUrlAttemptAction = DecodeUrlAttemptAction;
class DecodeUrlSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.DECODE_URL_SUCCESS;
    }
}
exports.DecodeUrlSuccessAction = DecodeUrlSuccessAction;
class DecodeUrlFailAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.DECODE_URL_FAIL;
    }
}
exports.DecodeUrlFailAction = DecodeUrlFailAction;
