<div class="scroll-layout">

  <div data-role="page">
    <div id="header">
      <mc-header title="{{defaultTitle | translate}}" subtitle="{{defaultSubTitle | translate}}"></mc-header>
      <div class="mc-header-container">
        <a class="btn btn-primary"
          href="#/awareness/setup"
          role="button"
          translate="$I18N_AWARENESS_LIST_COMPONENT_BTN"
          data-test="create-page-set-btn">
        </a>
      </div>
    </div>

    <div class="panel panel-default">
      <div class="panel-heading">
        <mc-search placeholder="Search" (search)="search.emit($event)"></mc-search>
      </div>

      <mc-spinner *ngIf="isLoading"></mc-spinner>

      <table *ngIf="!isLoading" class="table table-striped" data-test="page-sets-table">
        <thead>
        <tr>
          <th data-test="page-sets-table-header">Page Set Name <a (click)="toggleSort.emit()">
            <i class="fas fa-sort"></i></a>
          </th>
          <th data-test="page-sets-table-header" translate="$I18N_AWARENESS_LIST_COMPONENT_TABLE_TH2"></th>
          <th data-test="page-sets-table-header" translate="$I18N_AWARENESS_LIST_COMPONENT_TABLE_TH3"></th>
          <th data-test="page-sets-table-header" translate="$I18N_AWARENESS_LIST_COMPONENT_TABLE_TH4"></th>
          <th></th>
        </tr>
        </thead>
        <tbody>
          <tr *ngFor="let template of listContent; trackBy: trackByTemplateId">
            <td><a href="#/awareness/setup/{{template.id}}">{{template.name}}</a></td>
            <td data-test="CP_TICK" *ngIf="template.customTitles; else nothing1"><i class="far fa-check text-success"></i></td>
            <ng-template #nothing1>
              <td data-test="CP_NO_TICK">-</td>
            </ng-template>
            <td data-test="CT_TICK" *ngIf="template.customTips; else nothing2"><i class="far fa-check text-success"></i></td>
            <ng-template #nothing2>
              <td data-test="CT_NO_TICK">-</td>
            </ng-template>
            <td data-test="DT_TICK" *ngIf="template.mimecastTips; else nothing3"><i class="far fa-check text-success"></i></td>
            <ng-template #nothing3>
              <td data-test="DT_NO_TICK">-</td>
            </ng-template>
            <td>
              <div class="btn-group">
                <span type="button"
                  class="btn dropdown-toggle"
                  data-toggle="dropdown"
                  data-test="row-meatball"
                  aria-haspopup="true"
                  aria-expanded="false">
                          ...</span>
                <ul class="dropdown-menu dropdown-menu-right" data-test="row-dropdown">
                  <li><a href="#/awareness/setup/{{template.id}}" data-test="edit-option">Edit</a></li>
                  <li><a (click)="deleteTemplate.emit(template)" data-test="delete-option">Delete</a></li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
