"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FolderEffects = void 0;
const effects_1 = require("@ngrx/effects");
const operators_1 = require("rxjs/operators");
const treeFolderActions = require("../actions/folders.actions");
class FolderEffects {
    constructor(actions$, treeService) {
        this.actions$ = actions$;
        this.treeService = treeService;
        /**
         * Get the mailbox root folder
         */
        this.getMailboxRootFolder$ = this.actions$.pipe(effects_1.ofType(treeFolderActions.LOAD_EXCHANGE_ROOT_FOLDER), operators_1.map((action) => action.payload), operators_1.switchMap((payload) => {
            return this.treeService
                .getMailboxFoldersPerLevel(payload.contentType, payload.mailbox, payload.viewType, payload.parentId, payload.snapshotId, false)
                .pipe(operators_1.map((response) => {
                return new treeFolderActions.LoadSuccessExchangeRootFolder(response.first.folders);
            }));
        }));
        /**
         * Get the mailbox folders
         */
        this.getMailboxFolders$ = this.actions$.pipe(effects_1.ofType(treeFolderActions.LOAD_EXCHANGE_FOLDERS), operators_1.map((action) => action.payload), operators_1.switchMap((payload) => {
            return this.treeService
                .getMailboxFoldersPerLevel(payload.contentType, payload.mailbox, payload.viewType, payload.parentId, payload.snapshotId, true, payload.meta)
                .pipe(operators_1.map((response) => {
                return new treeFolderActions.LoadSuccessExchangeFoldersAction(response.first.fullPath, response.first.parentId, response.first.contentType, this.filterByContentType(response.first.folders, payload.contentType), response.meta);
            }));
        }));
        /**
         * Find folders based on the query
         */
        this.findMailboxFolders$ = this.actions$.pipe(effects_1.ofType(treeFolderActions.FIND_EXCHANGE_FOLDERS), operators_1.map((action) => action.payload), operators_1.switchMap((payload) => {
            return this.treeService
                .findMailboxFolders(payload.contentType, 1, payload.mailbox, payload.viewType, payload.parentId, payload.snapshotId, payload.meta, payload.query)
                .pipe(operators_1.map((response) => {
                return new treeFolderActions.FindSuccessExchangeFoldersAction(response.first.folders, response.meta);
            }));
        }));
        this.getOneDriveRootFolder$ = this.actions$.pipe(effects_1.ofType(treeFolderActions.LOAD_ONE_DRIVE_ROOT_FOLDER), operators_1.map((action) => action.payload), operators_1.switchMap((payload) => {
            return this.treeService
                .getOneDriveFolders(payload.owner, payload.depth, payload.viewType, payload.query, payload.parentId)
                .pipe(operators_1.map((response) => {
                return new treeFolderActions.LoadSuccessOneDriveRootFolder(response.first.folders);
            }));
        }));
        /**
         * Get folders from a drive
         */
        this.getOneDriveFolders$ = this.actions$.pipe(effects_1.ofType(treeFolderActions.LOAD_ONE_DRIVE_FOLDERS), operators_1.map((action) => action.payload), operators_1.switchMap((payload) => {
            return this.treeService
                .getOneDriveFolders(payload.owner, payload.depth, payload.viewType, payload.query, payload.parentId)
                .pipe(operators_1.map((response) => {
                return new treeFolderActions.LoadSuccessOneDriveFoldersAction(response.first.fullPath, response.first.parentId, response.first.contentType, response.first.folders, response.meta);
            }));
        }));
    }
    filterByContentType(folders, contentType) {
        return folders.filter(folder => folder['contentType'] === contentType);
    }
}
__decorate([
    effects_1.Effect()
], FolderEffects.prototype, "getMailboxRootFolder$", void 0);
__decorate([
    effects_1.Effect()
], FolderEffects.prototype, "getMailboxFolders$", void 0);
__decorate([
    effects_1.Effect()
], FolderEffects.prototype, "findMailboxFolders$", void 0);
__decorate([
    effects_1.Effect()
], FolderEffects.prototype, "getOneDriveRootFolder$", void 0);
__decorate([
    effects_1.Effect()
], FolderEffects.prototype, "getOneDriveFolders$", void 0);
exports.FolderEffects = FolderEffects;
