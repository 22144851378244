"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectorsEffect = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const actions_1 = require("../actions");
class ConnectorsEffect {
    constructor(actions$, translateService, apiService) {
        this.actions$ = actions$;
        this.translateService = translateService;
        this.apiService = apiService;
        this.getOneDriveConnectors$ = this.actions$.pipe(effects_1.ofType(actions_1.ConnectorActions.LOAD_ONEDRIVE_CONNECTORS), operators_1.switchMap(() => {
            return this.apiService.getOneDriveConnectors().pipe(operators_1.map((response) => {
                if (response.hasErrors) {
                    return new actions_1.LoadOneDriveConnectorsActionFail({
                        message: this.getNotificationMessage('CREATE_FAILURE'),
                        failure: response.fail[0]
                    });
                }
                return new actions_1.LoadOneDriveConnectorsActionSuccess(response.first.connectors);
            }), operators_1.catchError(response => {
                return rxjs_1.of(new actions_1.LoadOneDriveConnectorsActionFail({
                    message: this.getNotificationMessage('CREATE_FAILURE'),
                    failure: response.fail[0]
                }));
            }));
        }));
    }
    getNotificationMessage(notificationkey) {
        const fullKey = `$I18N_CLOUD_CONNECTORS.NOTIFICATION.${notificationkey}`;
        return this.translateService.instant(fullKey);
    }
}
__decorate([
    effects_1.Effect()
], ConnectorsEffect.prototype, "getOneDriveConnectors$", void 0);
exports.ConnectorsEffect = ConnectorsEffect;
