<div class="mc-cyber-graph-policy-block">
  <mc-wizard-step-header label="$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.SUMMARY.STEP_TITLE">
  </mc-wizard-step-header>


  <!-- Policy settings -->
  <div class="mc-summary-step-section">
    <div class="mc-summary-step-section-header">
      <div class="mc-summary-step-section-header-title">
        {{'$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.SUMMARY.LABELS.POLICY_STATUS' | translate }}</div>
    </div>
    <span>
      {{'$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.SUMMARY.POLICY_STATUS_INFO_DESC' | translate}}
    </span>
    <form class="form-horizontal" mcDefaultLabelClass="col-sm-3" mcDefaultControlContainerClass="col-sm-9"
    [formGroup]="cyberGraphPolicySummaryForm">
      <div class="mc-summary-step-first-step-item">
          <mc-switch formControlName="policyStatusEnabled" role="switch" showLabel="true"></mc-switch>
      </div>
    </form>
  </div>

  <!-- DETAILS step -->
  <div class="mc-summary-step-section" *ngIf="detailsStep">
    <div class="mc-summary-step-section-header">
      <div class="mc-summary-step-section-header-title">
        {{'$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.SUMMARY.LABELS.DETAILS_SECTION' | translate }}</div>
      <div class="mc-summary-step-section-header-edit-step" (click)="editStep(statics.DETAILS_STEP_NUMBER)">
        {{'$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.SUMMARY.LABELS.EDIT' | translate }}</div>
    </div>
    <div class="mc-detailed-list mc-summary-step-first-step-item">
      <mc-value-field label="$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.LABELS.POLICY_NAME"
        value="{{ detailsStep.name }}">
      </mc-value-field>
    </div>
    <div class="mc-detailed-list">
      <mc-value-field
        label="$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.LABELS.POLICY_DESCRIPTION"
        value="{{ detailsStep.description }}">
      </mc-value-field>
      <mc-value-field label="$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.LABELS.POLICY_OVERRIDE"
      [value]="getEnabledDisabledLabel(detailsStep.policyOverride)"></mc-value-field>
      <mc-value-field label="$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.LABELS.CYBERGRAPH_STATUS"
        [value]="getCyberGraphDynamicBannerEnumLabel(detailsStep.codebreakerStatus)"></mc-value-field>
      <mc-value-field *ngIf="trackerEnabled" label="$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.LABELS.SILENCER_STATUS"
                      [value]="getCyberGraphTrackersEnumLabel(detailsStep.silencerStatus)"></mc-value-field>
      <mc-value-field label="$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.DETAILS.LABELS.REPORTING_STATUS"
        [value]="getEnabledDisabledLabel(detailsStep.reportingStatus)"></mc-value-field>
    </div>
  </div>
  <!-- Applies to -->
  <div class="mc-summary-step-section" *ngIf="(policyAppliesToSetting$ | async)">
    <div class="mc-summary-step-section-header">
      <div class="mc-summary-step-section-header-title">
        {{'$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.SUMMARY.LABELS.APPLIES_TO_SECTION' | translate }}</div>
      <div class="mc-summary-step-section-header-edit-step" (click)="editStep(2)">
        {{'$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.SUMMARY.LABELS.EDIT' | translate }}</div>
    </div>
    <div class="mc-detailed-list">
      <div class="mc-summary-step-sub-section" *ngFor="let rule of appliesToStep; index as i;">
        <div class="mc-summary-step-sub-section-header">
          {{ '$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.SECTION_TITLE' | translate }}
          <span *ngIf="appliesToStep.length > 1">{{ i+1 }}</span>
        </div>
        <div class="mc-summary-step-sub-section-row">
          <mc-value-field label="$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.APPLIES.LABEL_FROM"
            [value]="helper.getAppliesFromToLabel(rule, 'appliesFrom')"></mc-value-field>
          <mc-value-field label="$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.APPLIES.LABEL_TO"
            [value]="helper.getAppliesFromToLabel(rule, 'appliesTo', false)"></mc-value-field>
          <mc-value-list
            listLabel="{{ '$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.IP_RANGE_TITLE' | translate }}"
            [listItems]="rule.ipRanges"
            [showMoreLessTriggerValue] = "showMoreLessTriggerValue">
          </mc-value-list>
          </div>
      </div>
    </div>
  </div>
</div>
