"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.getSelectedRow = exports.canUpdateListForListAction = exports.reducers = void 0;
const listReducer = require("./list.reducer");
const store_1 = require("@ngrx/store");
exports.reducers = {
    list: listReducer.reducer
};
const getRejectedState = store_1.createFeatureSelector('rejected');
const ɵ0 = (state) => state.list;
exports.ɵ0 = ɵ0;
const getListState = store_1.createSelector(getRejectedState, ɵ0);
exports.canUpdateListForListAction = store_1.createSelector(getListState, listReducer.canUpdateList);
exports.getSelectedRow = store_1.createSelector(getListState, listReducer.selectedRow);
