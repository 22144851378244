"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoadQueueConfigDetailsFailureAction = exports.LoadQueueConfigDetailsSuccessAction = exports.LoadQueueConfigDetailsAction = exports.DeleteQueueActionSuccess = exports.DeleteQueueActionFail = exports.DeleteQueueAction = exports.CloseQueueDetailsSidepanelAction = exports.OpenQueueDetailsSidepanelAction = exports.OpenEditQueueAction = exports.CreateNewQueueAction = exports.QueueListActions = void 0;
var QueueListActions;
(function (QueueListActions) {
    QueueListActions["CREATE_NEW_QUEUE"] = "[SupervisionQueueList] Create New Queue";
    QueueListActions["OPEN_EDIT_QUEUE"] = "[SupervisionQueueList] Open Edit Queue";
    QueueListActions["OPEN_QUEUE_DETAILS_SIDEPANEL"] = "[SupervisionQueueList] Open Queue Details Sidepanel";
    QueueListActions["CLOSE_QUEUE_DETAILS_SIDEPANEL"] = "[SupervisionQueueList] Close Queue Details Sidepanel";
    QueueListActions["DELETE_QUEUE"] = "[SupervisionQueueList] Delete Queue";
    QueueListActions["DELETE_QUEUE_FAIL"] = "[SupervisionQueueList] Delete Queue Fail";
    QueueListActions["DELETE_QUEUE_SUCCESS"] = "[SupervisionQueueList] Delete Queue Success";
    QueueListActions["LOAD_QUEUE_CONFIG_DETAILS"] = "[SupervisionQueueList] Load Queue Config Details";
    QueueListActions["LOAD_QUEUE_CONFIG_DETAILS_FAILURE"] = "[SupervisionQueueList] Load Queue Config Details Fail";
    QueueListActions["LOAD_QUEUE_CONFIG_DETAILS_SUCCESS"] = "[SupervisionQueueList] Load Queue Config Details Success";
})(QueueListActions = exports.QueueListActions || (exports.QueueListActions = {}));
class CreateNewQueueAction {
    constructor() {
        this.type = QueueListActions.CREATE_NEW_QUEUE;
    }
}
exports.CreateNewQueueAction = CreateNewQueueAction;
class OpenEditQueueAction {
    constructor(queueId) {
        this.queueId = queueId;
        this.type = QueueListActions.OPEN_EDIT_QUEUE;
    }
}
exports.OpenEditQueueAction = OpenEditQueueAction;
class OpenQueueDetailsSidepanelAction {
    constructor(tableContent) {
        this.tableContent = tableContent;
        this.type = QueueListActions.OPEN_QUEUE_DETAILS_SIDEPANEL;
    }
}
exports.OpenQueueDetailsSidepanelAction = OpenQueueDetailsSidepanelAction;
class CloseQueueDetailsSidepanelAction {
    constructor() {
        this.type = QueueListActions.CLOSE_QUEUE_DETAILS_SIDEPANEL;
    }
}
exports.CloseQueueDetailsSidepanelAction = CloseQueueDetailsSidepanelAction;
class DeleteQueueAction {
    constructor(payload) {
        this.payload = payload;
        this.type = QueueListActions.DELETE_QUEUE;
    }
}
exports.DeleteQueueAction = DeleteQueueAction;
class DeleteQueueActionFail {
    constructor(payload) {
        this.payload = payload;
        this.type = QueueListActions.DELETE_QUEUE_FAIL;
    }
}
exports.DeleteQueueActionFail = DeleteQueueActionFail;
class DeleteQueueActionSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = QueueListActions.DELETE_QUEUE_SUCCESS;
    }
}
exports.DeleteQueueActionSuccess = DeleteQueueActionSuccess;
class LoadQueueConfigDetailsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = QueueListActions.LOAD_QUEUE_CONFIG_DETAILS;
    }
}
exports.LoadQueueConfigDetailsAction = LoadQueueConfigDetailsAction;
class LoadQueueConfigDetailsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = QueueListActions.LOAD_QUEUE_CONFIG_DETAILS_SUCCESS;
    }
}
exports.LoadQueueConfigDetailsSuccessAction = LoadQueueConfigDetailsSuccessAction;
class LoadQueueConfigDetailsFailureAction {
    constructor(payload) {
        this.payload = payload;
        this.type = QueueListActions.LOAD_QUEUE_CONFIG_DETAILS_FAILURE;
    }
}
exports.LoadQueueConfigDetailsFailureAction = LoadQueueConfigDetailsFailureAction;
