<mc-table
  tableId="remediation/incident-list"
  [isLoading]="isLoading$ | async"
  [data]="tableData$ | async"
  [columns]="selectColumnList()"
  (rowClick)="openItem($event)"
  translatePrefix="$I18N_REMEDIATION_INCIDENT_TAB">

  <mc-filters
    (paginatorChange)="onPaginationChange($event)"
    [metadata]="metaData$ | async"
    [pageSizeOptions]="[50,100,300]"
    [showPagination]="true"
    [externalTableSearchCount]="externalTableSearchCount$ | async">

    <mc-filter-search
      (search)="onSearch($event)"
      (clear)="onSearchClear()"
      [options]="selectFilterSearchOptions()">
    </mc-filter-search>

    <mc-filters-bundle-date-range
      (filtersChange)="onDateRangeFilterChange($event)">
    </mc-filters-bundle-date-range>

    <mc-filters-bundle-column-values
      [sections]="searchFilters"
      [columns]="1"
      (filtersChange)="onBundleFilterChange($event)">
    </mc-filters-bundle-column-values>

  </mc-filters>

  <button class="mc-table-actions btn btn-secondary" (click)="onExportClicked()">
    {{ '$I18N_REMEDIATION_INCIDENT_TAB.ACTIONS.EXPORT' | translate }}
  </button>

  <mc-column key="type">
    <mc-body-cell *mcBodyCellDef="let row">{{ getTranslatedTypeName(row) }}</mc-body-cell>
  </mc-column>

  <mc-column-date key="create"></mc-column-date>

  <mc-column-date key="modified"></mc-column-date>

  <mc-column-actions key="action" mcShowColumnConfig>
    <mc-row-actions *mcRowActions="let row">
      <li mcRowAction="{{option.label}}" (click)="option.action(row)" *ngFor="let option of dropdownOptions"></li>
    </mc-row-actions>
  </mc-column-actions>

  <mc-column key="identified">
    <mc-body-cell *mcBodyCellDef="let row">{{ row.identified || noValuePlaceHolder }}</mc-body-cell>
  </mc-column>

  <mc-column key="successful">
    <mc-body-cell *mcBodyCellDef="let row">{{ row.successful || noValuePlaceHolder }}</mc-body-cell>
  </mc-column>

  <mc-column key="failed">
    <mc-body-cell *mcBodyCellDef="let row">{{ row.failed || noValuePlaceHolder }}</mc-body-cell>
  </mc-column>

  <mc-column key="restored">
    <mc-body-cell *mcBodyCellDef="let row">{{ row.restored || noValuePlaceHolder }}</mc-body-cell>
  </mc-column>

  <mc-empty-results keyTitle="$I18N_REMEDIATION_INCIDENT_TAB.NO_DATA"></mc-empty-results>

</mc-table>
