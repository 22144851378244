"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BulkDeliveryService = void 0;
const operators_1 = require("rxjs/operators");
const moment = require("moment");
const components_1 = require("@mimecast-ui/components");
class BulkDeliveryService {
    constructor(http) {
        this.http = http;
    }
    getItems(request, pagination) {
        return this.http
            .post('/api/gateway/find-delivery-messages', this.transformToServerRequest(request, pagination))
            .pipe(operators_1.map(response => [response.first, response.meta]), operators_1.map(([first, meta]) => [
            first.messages,
            Object.assign({}, meta['pagination'])
        ]), operators_1.map(([bulkMessages, page]) => {
            return {
                items: this.transformToUIModel(bulkMessages),
                page
            };
        }));
    }
    transformToServerRequest(request, pagination) {
        const { searchBy, start, end } = request, remainder = __rest(request, ["searchBy", "start", "end"]);
        const apiRequest = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, remainder), { type: 'bulk_delivery' }), (!!searchBy ? { searchBy: searchBy[0] } : {})), (!!start ? { start: moment(request.start).format(components_1.API_DATE_FORMAT) } : {})), (!!end ? { end: moment(request.end).format(components_1.API_DATE_FORMAT) } : {}));
        if (!!pagination) {
            // @ts-ignore: api interceptor wants like this :(
            apiRequest.meta = { pagination };
        }
        return apiRequest;
    }
    transformToUIModel(bulkMessages) {
        return bulkMessages.map(message => {
            const { nextAttempt, created, lockedUntil } = message, remainder = __rest(message, ["nextAttempt", "created", "lockedUntil"]);
            return Object.assign(Object.assign({}, remainder), { nextAttempt: nextAttempt ? nextAttempt : undefined, created: created ? created : undefined, lockedUntil: lockedUntil ? lockedUntil : undefined });
        });
    }
    rejectEmails(emails) {
        return this.http
            .post('/api/message-finder/reject-queued-email', emails.map(email => ({ id: email.id })))
            .pipe(operators_1.map(response => response.all));
    }
    retryEmails(emails) {
        return this.http
            .post('/api/message-finder/retry-delivery', emails.map(email => ({ id: email.id })))
            .pipe(operators_1.map(response => response.all));
    }
    recalculateRoute(emails) {
        return this.http
            .post('/api/message-finder/recalculate-delivery-route', emails.map(email => ({ id: email.id })))
            .pipe(operators_1.map(response => response));
    }
    earlyBounceEmails(emails) {
        return this.http
            .post('/api/message-finder/bounce-queued-email', emails.map(email => ({ id: email.id })))
            .pipe(operators_1.map(response => response.all));
    }
}
exports.BulkDeliveryService = BulkDeliveryService;
