<div class="mc-status-text-container status" data-test="enabledStatus">
    <span [ngClass]="enabled ? 'status-enabled' : 'status-disabled'" *ngIf="showIcon">
        <i
            class="fas"
            [ngClass]="enabled ?
                enabledIcon || this.defaultIcons.enabled :
                disabledIcon || this.defaultIcons.disabled"
            aria-hidden="true"
            data-test="enabledStatusIcon"
        ></i>
    </span>
    <span
        [ngClass]="showColouredText ? (enabled ? 'status-enabled' : 'status-disabled') : 'plain'"
        data-test="enabledStatusText"
    >{{ (enabled ?
        enabledText || this.defaultStrings.enabled :
        disabledText || this.defaultStrings.disabled
    ) }}</span>
</div>
