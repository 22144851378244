"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrowserIsolationDetectorComponent = void 0;
const core_1 = require("@angular/core");
class BrowserIsolationDetectorComponent {
    constructor(accountPackageService, windowService) {
        this.accountPackageService = accountPackageService;
        this.windowService = windowService;
        this.urlClick = new core_1.EventEmitter();
    }
    ngOnInit() {
        this.initBrowserIsolation();
    }
    ngOnChanges(changes) {
        const change = changes['logLink'].currentValue;
        if (change) {
            this.setUrl(change.url);
        }
    }
    initBrowserIsolation() {
        // check if browser isolation package is enabled
        if (this.accountPackageService.hasBrowserIsolationEnabled()) {
            // check if device is not IE or any kind of mobile
            if (!this.windowService.isIe && !this.windowService.isMobile) {
                this.setBrowserIsolationLinkState(true);
            }
            else {
                this.setBrowserIsolationLinkState(false);
            }
        }
        this.setUrl(this.logLink.url);
    }
    // listen for click event and do redirection ...
    urlRedirect(ev) {
        // if its not BI ready, prevent user from clicking the link / text
        if (!this.isBrowserIsolationLink) {
            return;
        }
        // prevent click to open side panel
        ev.stopPropagation();
        this.urlClick.emit(this.logLink);
    }
    // check if is proper BI link or a plain text
    setBrowserIsolationLinkState(state) {
        this.isBrowserIsolationLink = state;
    }
    setUrl(url) {
        this.browserIsolationLink = url;
    }
}
exports.BrowserIsolationDetectorComponent = BrowserIsolationDetectorComponent;
