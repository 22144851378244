'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const _ = require("lodash");
/**
 * Created by psambandam on 08/12/2016.
 */
angular.module('account.package-info.service', []).factory('packageInfoService', [
    function () {
        const ADCON_BUNDLES = [
            { id: 'D1', tooltip: '$I18N_PACKAGE_INFO_BUNDLE_D1', visibility: true },
            { id: 'S0', tooltip: '$I18N_PACKAGE_INFO_BUNDLE_S0', visibility: true },
            { id: 'S1', tooltip: '$I18N_PACKAGE_INFO_BUNDLE_S1', visibility: true },
            { id: 'S2', tooltip: '$I18N_PACKAGE_INFO_BUNDLE_S2', visibility: true },
            { id: 'M2', tooltip: '$I18N_PACKAGE_INFO_BUNDLE_M2', visibility: true },
            { id: 'M2A', tooltip: '$I18N_PACKAGE_INFO_BUNDLE_M2A', visibility: true },
            { id: 'C1', tooltip: '$I18N_PACKAGE_INFO_BUNDLE_C1', visibility: true },
            { id: 'A1', tooltip: '$I18N_PACKAGE_INFO_BUNDLE_A1', visibility: true },
            { id: 'M0', tooltip: '$I18N_PACKAGE_INFO_BUNDLE_M0', visibility: false },
            { id: 'M0A', tooltip: '$I18N_PACKAGE_INFO_BUNDLE_M0A', visibility: false },
            { id: 'M1', tooltip: '$I18N_PACKAGE_INFO_BUNDLE_M1', visibility: false },
            { id: 'M1A', tooltip: '$I18N_PACKAGE_INFO_BUNDLE_M1A', visibility: false }
        ];
        const PACKAGE_TOOLTIPS = {
            'Advanced Disclaimers (Site)': '$I18N_PACKAGE_INFO_TOOLTIP_ADVANCED_DISCLAIMERS_SITE',
            'Advanced MTA (Prime)': '$I18N_PACKAGE_INFO_TOOLTIP_ADVANCED_MTA_PRIME',
            'Advanced MTA (Site)': '$I18N_PACKAGE_INFO_TOOLTIP_ADVANCED_MTA_SITE',
            'Attachment Management (Site)': '$I18N_PACKAGE_INFO_TOOLTIP_ATTACHMENT_MANAGEMENT_SITE',
            'Auto Responders (Site)': '$I18N_PACKAGE_INFO_TOOLTIP_AUTO_RESPONDERS_SITE',
            'Content Control and Data Leak Prevention (Pro)': '$I18N_PACKAGE_INFO_TOOLTIP_CONTENT_CONTROL_AND_DATA_LEAK_PREVENTION_PRO',
            'Content Control and Data Leak Prevention (Site)': '$I18N_PACKAGE_INFO_TOOLTIP_CONTENT_CONTROL_AND_DATA_LEAK_PREVENTION_SITE',
            'Email Continuity (Prime)': '$I18N_PACKAGE_INFO_TOOLTIP_EMAIL_CONTINUITY_PRIME',
            'Email Continuity (Pro)': '$I18N_PACKAGE_INFO_TOOLTIP_EMAIL_CONTINUITY_PRO',
            'Email Continuity (Site)': '$I18N_PACKAGE_INFO_TOOLTIP_EMAIL_CONTINUITY_SITE',
            'Email Encryption and Privacy (Site)': '$I18N_PACKAGE_INFO_TOOLTIP_EMAIL_ENCRYPTION_AND_PRIVACY_SITE',
            'Message Recovery Service (Site)': '$I18N_PACKAGE_INFO_TOOLTIP_MESSAGE_RECOVERY_SERVICE_SITE',
            'Metadata Track and Trace (Site)': '$I18N_PACKAGE_INFO_TOOLTIP_METADATA_TRACK_AND_TRACE_SITE',
            'Stationery 1.0 (Site)': '$I18N_PACKAGE_INFO_TOOLTIP_STATIONERY_1_0_SITE',
            'Cloud Archive for Files (Site)': '$I18N_PACKAGE_INFO_TOOLTIP_CLOUD_ARCHIVE_FOR_FILES_SITE',
            'Cloud Archive for Lync IM (Site)': '$I18N_PACKAGE_INFO_TOOLTIP_CLOUD_ARCHIVE_FOR_LYNC_IM_SITE',
            'Exchange Managed Folders (Site)': '$I18N_PACKAGE_INFO_TOOLTIP_EXCHANGE_MANAGED_FOLDERS_SITE',
            'Exchange Storage Management (Site)': '$I18N_PACKAGE_INFO_TOOLTIP_EXCHANGE_STORAGE_MANAGEMENT_SITE',
            'eDiscovery and Litigation Support (Pro)': '$I18N_PACKAGE_INFO_TOOLTIP_EDISCOVERY_AND_LITIGATION_SUPPORT_PRO',
            'eDiscovery and Litigation Support (Site)': '$I18N_PACKAGE_INFO_TOOLTIP_EDISCOVERY_AND_LITIGATION_SUPPORT_SITE',
            'Exchange Mailbox and Folder Tools (Pro)': '$I18N_PACKAGE_INFO_TOOLTIP_EXCHANGE_MAILBOX_AND_FOLDER_TOOLS_PRO',
            'DNS Gateway': '$I18N_PACKAGE_INFO_TOOLTIP_DNS_GATEWAY',
            'Large File Send (Prime)': '$I18N_PACKAGE_INFO_TOOLTIP_LARGE_FILE_SEND_PRIME',
            'Large File Send (Pro)': '$I18N_PACKAGE_INFO_TOOLTIP_LARGE_FILE_SEND_PRO',
            'Mobile Apps (Pro)': '$I18N_PACKAGE_INFO_TOOLTIP_MOBILE_APPS_PRO',
            'Exchange Sync': '$I18N_PACKAGE_INFO_TOOLTIP_EXCHANGE_SYNC',
            'Exchange Sync and Recover': '$I18N_PACKAGE_INFO_TOOLTIP_EXCHANGE_SYNC_AND_RECOVER',
            'E2E Migration': '$I18N_PACKAGE_INFO_TOOLTIP_E2E_MIGRATION',
            'Mailbox Permission Sync': '$I18N_PACKAGE_INFO_TOOLTIP_MAILBOX_PERMISSION_SYNC',
            'Partner Managed Migration': '$I18N_PACKAGE_INFO_TOOLTIP_PARTNER_MANAGED_MIGRATION',
            'Mimecast Mobile Pro (Pro)': '$I18N_PACKAGE_INFO_TOOLTIP_MIMECAST_MOBILE_PRO_PRO',
            'Cloud Archive Connectors (Site)': '$I18N_PACKAGE_INFO_TOOLTIP_CLOUD_ARCHIVE_CONNECTORS_SITE',
            'Email Encryption and Privacy (Pro)': '$I18N_PACKAGE_INFO_TOOLTIP_EMAIL_ENCRYPTION_AND_PRIVACY_PRO',
            'Server Apps (Site)': '$I18N_PACKAGE_INFO_TOOLTIP_SERVER_APPS_SITE',
            'Threat Remediation': '$I18N_PACKAGE_INFO_TOOLTIP_THREAT_REMEDIATION',
            'Attachment Protection (Pro)': '$I18N_ATTACHMENT_PROTECTION_PRO',
            'Attachment Protection (Site)': '$I18N_ATTACHMENT_PROTECTION_SITE',
            'Continuity Event Monitor': '$I18N_CONTINUITY_EVENT_MONITOR',
            'Impersonation Protection': '$I18N_IMPERSONATION_PROTECTION',
            'Internal Email Protect': '$I18N_INTERNAL_EMAIL_PROTECT',
            'Message Recovery Service - User': '$I18N_MESSAGE_RECOVERY_SERVICE_USER',
            'SMS Notifications': '$I18N_SMS_NOTIFICATIONS',
            'Secure Messaging': '$I18N_SECURE_MESSAGING',
            'Secure Messaging Lite': '$I18N_SECURE_MESSAGING_LITE',
            'URL Protection (Site)': '$I18N_URL_PROTECTION_SITE',
            'Legacy Archive Data Management': '$I18N_LEGACY_ARCHIVE_DATA_MANAGEMENT',
            'Supervision': '$I18N_PACKAGE_INFO_TOOLTIP_SUPERVISION',
            'App Settings': '$I18N_PACKAGE_INFO_TOOLTIP_APPLICATION_SETTINGS',
            'Email Incident Response': '$I18N_PACKAGE_INFO_TOOLTIP_EMAIL_INCIDENT_RESPONSE',
            'Congestion Mitigation': '$I18N_PACKAGE_INFO_TOOLTIP_CONGESTION_MITIGATION',
            'Misaddressed Email Protection': '$I18N_PACKAGE_INFO_TOOLTIP_MISADDRESSED_EMAIL_PROTECTION',
            'Mimecast DMARC Analyzer': '$I18N_PACKAGE_INFO_TOOLTIP_MIMECAST_DMARC_ANALYZER',
            'Brand Exploit Protect': '$I18N_PACKAGE_INFO_TOOLTIP_BRAND_EXPLOIT_PROTECT',
            'CyberGraph': '$I18N_PACKAGE_INFO_TOOLTIP_CYBER_GRAPH',
            'Custom Domain Block': '$I18N_PACKAGE_INFO_TOOLTIP_CUSTOM_DOMAIN_BLOCK',
            'BYO: Threat Intelligence': '$I18N_PACKAGE_INFO_TOOLTIP_BYOTI',
            'Browser Isolation for Email': '$I18N_PACKAGE_INFO_TOOLTIP_BROWSER_ISOLATION_EMAIL',
            'Browser Isolation for Web': '$I18N_PACKAGE_INFO_TOOLTIP_BROWSER_ISOLATION_WEB',
            'Business Email Compromise': '$I18N_PACKAGE_INFO_TOOLTIP_BUSINESS_EMAIL_COMPROMISE',
            'Governance for Microsoft Teams': '$I18N_PACKAGE_INFO_TOOLTIP_GOVERNANCE_FOR_MS_TEAMS',
            'Case Review': '$I18N_PACKAGE_INFO_TOOLTIP_CASE_REVIEW',
            'Reviewer': '$I18N_PACKAGE_INFO_TOOLTIP_REVIEWER',
            Branding: '$I18N_BRANDING',
            'Calendar Sync 1.0': '$I18N_CALENDAR_SYNC_1_0',
            'Desktop Apps - Mac (Pro)': '$I18N_DESKTOP_APPS_-_MAC_PRO',
            'Desktop Apps - Outlook (Pro)': '$I18N_DESKTOP_APPS_-_OUTLOOK_PRO',
            'Enhanced Logging': '$I18N_ENHANCED_LOGGING',
            'Granular Retention Policies': '$I18N_GRANULAR_RETENTION_POLICIES',
            'Journal Services': '$I18N_JOURNAL_SERVICES',
            'Large File Send (Site)': '$I18N_LARGE_FILE_SEND_SITE',
            'Advanced Account Administration': '$I18N_ADVANCED_ACCOUNT_ADMINISTRATION',
            'Federated Account Administration': '$I18N_FEDERATED_ACCOUNT_ADMINISTRATION',
            'Mailbox Recovery': '$I18N_MAILBOX_RECOVERY',
            'eDiscovery Review': '$I18N_EDISCOVERY_REVIEW',
            'Privacy Pack': '$I18N_PACKAGE_INFO_TOOLTIP_PACKAGE_PACK',
            Broadcast: '$I18N_BROADCAST',
            'Mimecast Personal Portal Beta': '$I18N_MIMECAST_PERSONAL_PORTAL_BETA',
            'Web Security - Full': '$I18N_PACKAGE_INFO_TOOLTIP_WEB_SECURITY_FULL',
            'Web Security - Network Only': '$I18N_PACKAGE_INFO_TOOLTIP_WEB_SECURITY_NETWORK_ONLY',
            'Awareness Training': '$I18N_PACKAGE_INFO_TOOLTIP_AWARENESS_TRAINING'
        };
        return {
            getFilteredBundles,
            isLegacyPackage,
            isStandalonePackage,
            CONSTANTS: {
                NON_BUNDLED_PACKAGES: ['Standalone', 'Beta', 'Legacy'],
                LEGACY_PACKAGE: 'Legacy',
                STANDALONE_PACKAGE: 'Standalone',
                PLATFORM_PACKAGE_GROUP: 'Mimecast Platform',
                PLATFORM_BASIC_PACKAGE_GROUP: 'Mimecast Basic Platform',
                ADCON_BUNDLES,
                PACKAGE_TOOLTIPS,
                COLUMN_HIGHLIGHT_COLOR: 'rgba(191,225,192,0.5)',
                PACKAGE_INFO_PERMISSIONS: {
                    EDIT: 'Permission.PACKAGE_INFO_EDIT',
                    READ: 'Permission.PACKAGE_INFO_READ'
                },
                PACKAGE_SUB_ORDER: {
                    Site: 1,
                    Prime: 2,
                    Pro: 3
                }
            }
        };
        function isLegacyPackage(pkg) {
            return pkg.groupName && _.isEqual(pkg.groupName, this.CONSTANTS.LEGACY_PACKAGE);
        }
        function isStandalonePackage(pkg) {
            return pkg.groupName && _.isEqual(pkg.groupName, this.CONSTANTS.STANDALONE_PACKAGE);
        }
        function getFilteredBundles(bundles, prefix) {
            const bundleIds = ADCON_BUNDLES.map((bundle) => bundle.id);
            const filteredBundles = [...bundleIds];
            angular.forEach(bundles, function (bundle) {
                if (_.startsWith(bundle.name, prefix)) {
                    bundle.name = _.trim(bundle.name.replace(prefix, ''));
                    const tempItem = _.find(ADCON_BUNDLES, { id: bundle.name });
                    bundle.visibility = tempItem && tempItem.visibility ? tempItem.visibility : false;
                    bundle.isChecked = angular.copy(bundle.visibility);
                    bundle.tooltip = tempItem && tempItem.tooltip ? tempItem.tooltip : '';
                    const tempIndex = bundleIds.indexOf(bundle.name);
                    if (tempIndex > -1) {
                        filteredBundles.splice(tempIndex, 1, bundle);
                    }
                }
            });
            _.remove(filteredBundles, function (bundle) {
                return bundleIds.indexOf(bundle) > -1;
            });
            return filteredBundles;
        }
    }
]);
