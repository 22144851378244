"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UrlProtectionPageComponent = void 0;
const url_protection_page_static_values_1 = require("./url-protection-page.static-values");
const reducers = require("../reducers/index");
const widgetActions = require("../actions/widget-urls-scan-day/widget-urls-scan-day.action");
const urls_scan_graph_static_values_1 = require("../components/urls-scan-graph/urls-scan-graph.static-values");
class UrlProtectionPageComponent {
    constructor(store, accountPackageService, capsService) {
        this.store = store;
        this.accountPackageService = accountPackageService;
        this.capsService = capsService;
        this.urlPageTabs = url_protection_page_static_values_1.urlProtectionTabs;
        this.canViewOverviewPageCapExpression = 'Permission.POLICIES_READ || Permission.SERVICES_TTP_URL_PROTECT_READ';
        this.canViewOverviewPage = false;
        this.notSupportedEIPSeries = [];
        this.widgetUrlScanDayData$ = this.store.select(reducers.getWidgetUrlsPerDayState);
        this.iepPackagesEnabled = this.accountPackageService.hasInternalEmailProtectAccountPackage();
    }
    ngOnInit() {
        this.canViewOverviewPageSubcription = this.capsService
            .evalCapabilitiesExpression(this.canViewOverviewPageCapExpression)
            .subscribe(canView => {
            if (canView) {
                this.canViewOverviewPage = canView;
                this.store.dispatch(new widgetActions.GetWidgetData());
            }
        });
        if (!this.iepPackagesEnabled) {
            this.notSupportedEIPSeries = [
                urls_scan_graph_static_values_1.yValueKeys.outbound,
                urls_scan_graph_static_values_1.yValueKeys.maliciousOutbound,
                urls_scan_graph_static_values_1.yValueKeys.journal,
                urls_scan_graph_static_values_1.yValueKeys.maliciousJournal
            ];
        }
    }
    ngOnDestroy() {
        this.canViewOverviewPageSubcription.unsubscribe();
    }
}
exports.UrlProtectionPageComponent = UrlProtectionPageComponent;
