"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueueConfigsTabComponent = void 0;
const core_1 = require("@angular/core");
const queue_list_actions_1 = require("app-new/archive/supervision/actions/queue.list.actions");
const queue_details_actions_1 = require("app-new/archive/supervision/actions/queue.details.actions");
const queue_configs_service_1 = require("../../services/queue-configs.service");
const rxjs_1 = require("rxjs");
const table_store_1 = require("@mimecast-ui/table-store");
const queue_configs_tab_static_values_1 = require("./queue-configs-tab.static-values");
const queueListActions = require("../../../../../supervision/actions/queue.list.actions");
const selectors = require("./../../../../../supervision/reducers/index");
const operators_1 = require("rxjs/operators");
const lodash_1 = require("lodash");
const dashboard_settings_1 = require("../../../../../supervision/model/supervision/dashboard/settings/dashboard-settings");
const rule_usage_notification_modal_component_1 = require("../../../../../supervision/components/rule-usage-notification-modal/rule-usage-notification-modal.component");
const delete_confirmation_modal_component_1 = require("../../../../../supervision/components/delete-confirmation-modal/delete-confirmation-modal.component");
class QueueConfigsTabComponent extends table_store_1.TableBaseComponent {
    constructor(store, modalService) {
        super(store, queue_configs_service_1.QueueConfigsService.SUPERVISION_QUEUE_CONFIG_LIST);
        this.store = store;
        this.modalService = modalService;
        this.sections = queue_configs_tab_static_values_1.tableFilterSections;
        this.columns = queue_configs_tab_static_values_1.tableColumns;
        this.deleteSubject$ = new rxjs_1.Subject();
        this.onClickView = new core_1.EventEmitter();
        this.onClickCreate = new core_1.EventEmitter();
        this.onClickEdit = new core_1.EventEmitter();
        this.searchByFields = this.getSearchByFields();
    }
    getSearchByFields() {
        const prefix = '$I18N_ARCHIVE_SUPERVISION_LIST_QUEUES_TAB.FILTERS.SEARCH_BY_FILTER_FIELD';
        const filterByOptions = ['all', 'createdBy', 'name'];
        return filterByOptions.map((filterByOption) => {
            return { value: filterByOption, label: [prefix, filterByOption.toUpperCase()].join('.') };
        });
    }
    getStatus(status) {
        return queue_configs_service_1.QueueConfigsService.STATUS_MAP[status];
    }
    getType(type) {
        return queue_configs_service_1.QueueConfigsService.QUEUE_TYPE_MAP[type];
    }
    getNumberOfLabels(labels) {
        return (labels.length && labels.length.toString()) || '-';
    }
    dispatchFilterAction(filteringOption = this.currentFilters, pagination = this.currentPagination) {
        super.dispatchFilterAction(filteringOption, pagination);
    }
    openCreate() {
        this.store.dispatch(new queue_list_actions_1.CreateNewQueueAction());
    }
    openItem(item) {
        this.store.dispatch(new queue_details_actions_1.GetQueueDetailsAction(item));
        this.store.dispatch(new queue_details_actions_1.UpdateQueueDetailsPaginationInfoAction(item, this.tableContent));
        this.store.dispatch(new queue_list_actions_1.OpenQueueDetailsSidepanelAction(this.tableContent));
        this.dispatchSetSelectedRow(item);
    }
    editItem(item) {
        const selectedQueue = item;
        this.store.dispatch(new queue_list_actions_1.OpenEditQueueAction(selectedQueue.id));
        this.dispatchSetSelectedRow(item);
    }
    ngOnInit() {
        super.ngOnInit();
        this.tableDataSubscription = this.tableData$.subscribe(data => (this.tableContent = data));
    }
    ngOnDestroy() {
        super.ngOnDestroy();
        if (this.tableDataSubscription) {
            this.tableDataSubscription.unsubscribe();
        }
    }
    deleteQueueConfig(selectedQueue) {
        this.store.dispatch(new queueListActions.LoadQueueConfigDetailsAction(selectedQueue.id));
        rxjs_1.combineLatest([
            this.store.select(selectors.getQueueListDetails),
            this.store.select(selectors.isLoadingQueueListDetails)
        ])
            .pipe(operators_1.takeUntil(this.deleteSubject$))
            .subscribe(([queueConfigDetails, isLoadingQueueDetails]) => {
            if (!isLoadingQueueDetails) {
                if (queueConfigDetails) {
                    if (lodash_1.isEmpty(queueConfigDetails.associatedRules)) {
                        this.confirmDeletion(queueConfigDetails);
                    }
                    else {
                        this.ruleUsageNotification(queueConfigDetails.name, queueConfigDetails.associatedRules);
                    }
                }
                this.deleteSubject$.next(true);
            }
        });
    }
    ruleUsageNotification(lexiconName, associatedRules) {
        this.modalService.open(rule_usage_notification_modal_component_1.RuleUsageNotificationModalComponent, {
            size: 'lg',
            hasBackdrop: true,
            disableClose: true,
            data: {
                entityName: lexiconName,
                associatedRules,
                entityDeletionType: dashboard_settings_1.DeletionType.QUEUE
            }
        });
    }
    confirmDeletion(selectedQueue) {
        const modal = this.modalService.open(delete_confirmation_modal_component_1.DeleteConfirmationModalComponent, {
            size: 'lg',
            hasBackdrop: true,
            disableClose: true,
            data: {
                queueType: selectedQueue.type,
                entityName: selectedQueue.name,
                entityDeletionType: dashboard_settings_1.DeletionType.QUEUE,
                escalationQueues: selectedQueue.escalationQueues,
                associatedReviewQueues: selectedQueue.reviewQueues
            }
        });
        modal.afterClose().subscribe(confirmed => {
            if (confirmed) {
                this.store.dispatch(new queueListActions.DeleteQueueAction({ id: selectedQueue.id }));
            }
        });
    }
}
exports.QueueConfigsTabComponent = QueueConfigsTabComponent;
