"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DirectorySyncOptionsStepComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const operators_1 = require("rxjs/operators");
class DirectorySyncOptionsStepComponent {
    constructor(fb, translateService) {
        this.fb = fb;
        this.translateService = translateService;
        this.delay = 200;
        this.onIntegrationDetailsUpdated = new core_1.EventEmitter();
        this.NO_SPACE_REGEX = /^\S*$/;
        this.deleteUsersKbLink = 'https://community.mimecast.com/s/article/Directory-Synchronization-Maximum-Synchronization-Deletions-and-Deleted-Users';
        this.emailDomainsFilterKbLink = 'https://community.mimecast.com/s/article/Advanced-Account-Administration-Console-1364652848';
        this.maxSyncDeletionKbLink = 'https://community.mimecast.com/s/article/Directory-Synchronization-Maximum-Synchronization-Deletions-and-Deleted-Users';
    }
    ngOnInit() {
        this.form = this.fb.group({
            acknowledgeDisabledAccounts: this.integration.acknowledgeDisabledAccounts || false,
            emailDomainsFilter: this.integration.emailDomainsFilter || false,
            domains: [
                this.integration.domains || '',
                [forms_1.Validators.required, forms_1.Validators.pattern(this.NO_SPACE_REGEX)]
            ],
            includeContacts: this.getIncludeContactsValue(),
            maxSyncDeletion: this.integration.maxSyncDeletion || 'unlink_10',
            deleteUsers: this.integration.deleteUsers || false,
            includeGuestAccounts: this.integration.includeGuestAccounts || false
        });
        this.update();
        this.formSubscription = this.form.valueChanges.pipe(operators_1.debounceTime(this.delay)).subscribe(() => {
            this.update();
        });
    }
    ngOnChanges(changes) {
        if (!!changes.integration.previousValue &&
            changes.integration.currentValue.type !== changes.integration.previousValue.type) {
            this.form.patchValue({
                acknowledgeDisabledAccounts: false,
                emailDomainsFilter: false,
                domains: '',
                includeContacts: this.getIncludeContactsValue(),
                maxSyncDeletion: 'unlink_10',
                deleteUsers: false,
                includeGuestAccounts: false
            });
        }
    }
    get maxSyncDeletionOptions() {
        return [
            {
                value: 'unlink_0',
                label: '0'
            },
            {
                value: 'unlink_5',
                label: '5'
            },
            {
                value: 'unlink_10',
                label: '10'
            },
            {
                value: 'unlink_20',
                label: '20'
            },
            {
                value: 'unlink_50',
                label: '50'
            },
            {
                value: 'unlink_100',
                label: '100'
            },
            {
                value: 'unlink_300',
                label: '300'
            },
            {
                value: 'unlink_500',
                label: '500'
            },
            {
                value: 'unlink_1000',
                label: '1000'
            },
            {
                value: 'unlink_2000',
                label: '2000'
            },
            {
                value: 'unlink_5000',
                label: '5000'
            },
            {
                value: 'unlink_10000',
                label: '10000'
            },
            {
                value: 'unlink_50000',
                label: '50000'
            },
            {
                value: 'unlink_100000',
                label: '100000'
            },
            {
                value: 'unlink_unlimited',
                label: this.translateService.instant('$I18N_DIRECTORY_SYNC_WIZARD.STEPS.OPTIONS.MAXIMUM_SYNC_DELETIONS_UNLIMITED')
            }
        ];
    }
    isEmailDomainsFilterOn() {
        return this.form.value.emailDomainsFilter;
    }
    update() {
        this.onIntegrationDetailsUpdated.emit(this.form.getRawValue());
    }
    isAzureIntegration() {
        return this.integration.type === 'azure_standard' || this.integration.type === 'azure_gcc';
    }
    isGsuiteIntegration() {
        return this.integration.type === 'google_directory';
    }
    isMSEIntegration() {
        return this.integration.type === 'ad_push';
    }
    isLdapDominoIntegration() {
        return this.integration.type === 'domino';
    }
    isValid() {
        if ((this.form.value.emailDomainsFilter && this.form.value.domains === '') ||
            (this.form.value.emailDomainsFilter && !this.form.value.domains.match(this.NO_SPACE_REGEX))) {
            return false;
        }
        return true;
    }
    getIncludeContactsValue() {
        // update to nullish coalesce: this.integration.includeContacts ?? true;
        return this.integration.includeContacts !== null && this.integration.includeContacts !== void 0
            ? this.integration.includeContacts
            : true;
    }
    ngOnDestroy() {
        this.formSubscription.unsubscribe();
    }
}
exports.DirectorySyncOptionsStepComponent = DirectorySyncOptionsStepComponent;
