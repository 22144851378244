"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgEndpointsListSidebarDetailsComponent = void 0;
const endpoints_list_models_1 = require("../../../models/endpoints-list.models");
class SwgEndpointsListSidebarDetailsComponent {
    constructor(datePipeService, translateService) {
        this.datePipeService = datePipeService;
        this.translateService = translateService;
        this.dateFormat = 'dd/MM/yyyy';
        this.timezoneOffset = '';
        this.msaAgentVersion = endpoints_list_models_1.MSA_AGENT_VERSION;
        this.deviceType = endpoints_list_models_1.DEVICE;
        this.infoBoxNotification = {
            status: 'info',
            msg: '',
            boxed: true
        };
        this.currentDeviceType = '';
        this.timezoneOffset = this.datePipeService.timeZoneOffset;
    }
    ngOnChanges() {
        this.findCurrentDeviceType(this.row.os.toLowerCase());
        this.showHideMSAStatusSection();
    }
    showHideMSAStatusSection() {
        let notificationMessage = '$I18N_ENDPOINTS_LIST.SIDEBAR.DETAILS_TAB.MSA_STATUS_SECTION_NOTIFICATION_NODATA';
        this.showMSAStatusSectionImages = false;
        if (this.currentDeviceType !== this.deviceType.IOS) {
            if (this.currentDeviceType === this.deviceType.WINDOWS &&
                this.isMSAVersionLessThanRequired(true, this.row.version)) {
                notificationMessage =
                    '$I18N_ENDPOINTS_LIST.SIDEBAR.DETAILS_TAB.MSA_STATUS_SECTION_NOTIFICATION_WINDOWS';
            }
            else if (this.currentDeviceType === this.deviceType.MAC &&
                this.isMSAVersionLessThanRequired(false, this.row.version)) {
                notificationMessage =
                    '$I18N_ENDPOINTS_LIST.SIDEBAR.DETAILS_TAB.MSA_STATUS_SECTION_NOTIFICATION_MAC';
            }
            else if (this.row.protectedDeviceDetail.dnsServerStatus !== undefined) {
                this.showMSAStatusSectionImages = true;
            }
            this.infoBoxNotification.msg = this.translateService.instant(notificationMessage);
        }
    }
    findCurrentDeviceType(os) {
        if (os.includes(this.deviceType.WINDOWS) || os.includes(this.deviceType.WINDOWS_SERVER)) {
            this.currentDeviceType = this.deviceType.WINDOWS;
        }
        else if (os.includes(this.deviceType.MAC)) {
            this.currentDeviceType = this.deviceType.MAC;
        }
        else if (os.includes(this.deviceType.IOS)) {
            this.currentDeviceType = this.deviceType.IOS;
        }
        else {
            this.currentDeviceType = this.deviceType.ANDROID;
        }
    }
    isMSAVersionLessThanRequired(isWindowsDevice, currentVersion) {
        let status = 0;
        if (isWindowsDevice) {
            status = this.compareVersions(this.msaAgentVersion.WINDOWS, currentVersion);
        }
        else {
            status = this.compareVersions(this.msaAgentVersion.MAC, currentVersion);
        }
        return status === -1;
    }
    compareVersions(requiredVersion, currentVersion) {
        return currentVersion.localeCompare(requiredVersion, undefined, {
            numeric: true,
            sensitivity: 'base'
        });
    }
}
exports.SwgEndpointsListSidebarDetailsComponent = SwgEndpointsListSidebarDetailsComponent;
