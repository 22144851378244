"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TopQueuesPanelComponent = void 0;
const _ = require("lodash");
const Colors = require("./colors");
const queue_type_enum_1 = require("app-new/archive/supervision/types/queue-type.enum");
const queue_service_1 = require("app-new/archive/supervision/containers/wizard/services/queue.service");
class TopQueuesPanelComponent {
    constructor(stateService) {
        this.stateService = stateService;
    }
    set data(queues) {
        this.queues = queues;
        if (queues && queues.valid.length) {
            this.maxTotalCount = this.queues.valid[0].totalCount;
        }
    }
    set dashboardSettings(settings) {
        this._dashboardSettings = settings;
    }
    hasQueues() {
        return this.queues.all ? this.queues.all.length > 0 : false;
    }
    isReviewQueue() {
        return this.queues.queueType === queue_type_enum_1.QueueType.REVIEW;
    }
    pendingPercentageColor(percent) {
        if (this._dashboardSettings) {
            if (_.inRange(percent, 0, this._dashboardSettings.pendingPercentages[0] + 1)) {
                return Colors.GREEN;
            }
            else if (_.inRange(percent, this._dashboardSettings.pendingPercentages[0], this._dashboardSettings.pendingPercentages[1] + 1)) {
                return Colors.ORANGE;
            }
            else {
                return Colors.RED;
            }
        }
        return Colors.GREY;
    }
    oldestPendingColor(hours) {
        const days = Math.round(hours / 24);
        if (this._dashboardSettings) {
            if (_.inRange(days, 0, this._dashboardSettings.oldestQueueMessages[0] + 1)) {
                return Colors.GREEN;
            }
            else if (_.inRange(days, this._dashboardSettings.oldestQueueMessages[0], this._dashboardSettings.oldestQueueMessages[1] + 1)) {
                return Colors.ORANGE;
            }
            else {
                return Colors.RED;
            }
        }
        return Colors.GREY;
    }
    pendingPercentageFormatted(percent) {
        return percent === 0 ? '-' : `${percent}%`;
    }
    oldestPendingFormattedDuration(oldestPendingHours) {
        if (oldestPendingHours === 0) {
            return '-';
        }
        else if (oldestPendingHours < 24) {
            const hourFormat = oldestPendingHours === 1 ? 'hour' : 'hours';
            return `${oldestPendingHours} ${hourFormat}`;
        }
        else {
            const days = Math.round(oldestPendingHours / 24);
            const dayFormat = days === 1 ? 'day' : 'days';
            return `${days} ${dayFormat}`;
        }
    }
    calculateIndicatorWidth(queueDetails) {
        const percentageWidth = (queueDetails.totalCount / this.maxTotalCount) * 100;
        const shouldBeMinWidth = queueDetails.totalCount > 0 && percentageWidth < 1;
        return shouldBeMinWidth ? 1 : percentageWidth;
    }
    openTab() {
        this.stateService.$state.go('archive-supervision-list', {
            callerWizardId: queue_service_1.QueueService.WizardId
        });
    }
}
exports.TopQueuesPanelComponent = TopQueuesPanelComponent;
