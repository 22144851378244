"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IncidentMessagesComponent = void 0;
const table_store_1 = require("@mimecast-ui/table-store");
const exportActions = require("../../../actions/export/export.actions");
const messageDetailsAction = require("../../../actions/message-details/message-details.actions");
const asidePaginationAction = require("../../../actions/pagination/pagination.actions");
const remediateMessagesAction = require("../../../actions/remediate-messages/remediate-messages.action");
const export_1 = require("../../../models/export");
const reducers = require("../../../reducers");
const constants_1 = require("../../../utils/constants");
const operators_1 = require("rxjs/operators");
class IncidentMessagesComponent extends table_store_1.TableBaseComponent {
    constructor(store, stateService, exportService, capabilitiesService, translateService) {
        super(store, 'RemediationSingleIncident');
        this.store = store;
        this.stateService = stateService;
        this.exportService = exportService;
        this.capabilitiesService = capabilitiesService;
        this.translateService = translateService;
        this.emptyValueDecorator = constants_1.emptyValueDecorator;
        this.isEmailReadable = true;
        this.columnList = ['from', 'to', 'subject', 'received', 'action'];
        this.TYPE_NOTIFY_ONLY = 'notify_only';
        this.TYPE_MANUAL = 'manual';
        this.TYPE_AUTOMATIC = 'automatic';
        this.TYPE_MEP_CLAWBACK = 'mep_clawback';
        this.initialPageSize = 50;
    }
    ngOnInit() {
        this.dispatchFilterAction({ id: this.stateService.$state.params.incidentId }, { pageSize: this.initialPageSize, pageNumber: 0, pageToken: undefined });
        this.incidentSummary$ = this.store.select(reducers.incidentSummary);
        this.dropdownOptions = [
            {
                label: '$I18N_REMEDIATION_INCIDENT_VIEWER.VIEW_DETAILS',
                action: (row) => this.onRowClick(row)
            }
        ];
        this.tableDataSubscription = this.tableData$.subscribe((messages) => {
            this.messages = messages;
        });
        this.selectedRow$ = this.store.select(reducers.getSelectedRow);
        this.selectedRowSubscription = this.selectedRow$.subscribe((row) => {
            if (row) {
                const currentIndex = this.messages.map(message => message.id).indexOf(row.id);
                const pagination = {
                    currentIndex,
                    prevRow: currentIndex > 0 ? this.messages[currentIndex - 1] : undefined,
                    nextRow: currentIndex < this.messages.length - 1 ? this.messages[currentIndex + 1] : undefined,
                    pageSize: this.messages.length
                };
                this.store.dispatch(new asidePaginationAction.PaginationSet(pagination));
            }
        });
        this.userCapabilitiesSubscription = this.capabilitiesService.capabilities.subscribe((caps) => {
            const emailContentReadPermission = 'Permission.ACCEPTED_EMAIL_CONTENT_VIEW';
            this.isEmailReadable =
                caps[emailContentReadPermission] && caps[emailContentReadPermission].enabled;
            const editPermissionCapability = 'Permission.THREAT_REMEDIATION_EDIT';
            this.isEditable = caps[editPermissionCapability] && caps[editPermissionCapability].enabled;
        });
        this.incidentSummary$
            .pipe(operators_1.first(response => null !== response))
            .subscribe((response) => {
            this.isRestored = !!response.searchCriteria.restoreCode;
            if (this.isRestored) {
                const msg = this.translateService.instant('$I18N_REMEDIATION_INCIDENT_VIEWER.MESSAGES_ALREADY_RESTORED');
                this.restoredInfoMessage = {
                    status: 'info',
                    msg
                };
            }
        });
    }
    ngOnDestroy() {
        this.selectedRowSubscription.unsubscribe();
        this.tableDataSubscription.unsubscribe();
        this.userCapabilitiesSubscription.unsubscribe();
    }
    onRowClick(row) {
        if (this.isEmailReadable) {
            // @ts-ignore
            this.store.dispatch(new messageDetailsAction.GetMessageDetailsAction(row));
        }
        else {
            this.store.dispatch(new messageDetailsAction.GetMessageDetailsNoEmailReadPermissionAction());
        }
    }
    onActionClicked(type) {
        this.store.dispatch(new remediateMessagesAction.RemediateIncidentMessagesOpenModalAction(type));
    }
    onExportClicked() {
        const columnMapParams = {
            // columns: copy columnList array but remove last item which is the actions column
            columns: this.columnList.slice(0, this.columnList.length - 1),
            translationKey: '$I18N_REMEDIATION_INCIDENT_VIEWER.COLUMNS'
        };
        const columnConfig = {
            columnsToExport: this.exportService.mapExportedColumnsItem(columnMapParams),
            endPoint: export_1.ExportsEndPoints.incidentEmails,
            id: this.stateService.$state.params.incidentId
        };
        this.store.dispatch(new exportActions.OpenPanelForExportAction(columnConfig));
    }
}
exports.IncidentMessagesComponent = IncidentMessagesComponent;
