"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProviderIconComponent = void 0;
const connector_1 = require("../../model/connector");
class ProviderIconComponent {
    constructor() {
        this.ProviderType = connector_1.ProviderType;
    }
    isProviderOfType(type) {
        return this.providerType === type;
    }
}
exports.ProviderIconComponent = ProviderIconComponent;
