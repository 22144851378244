<mc-layout-aside-extra-container
  keyTitle="{{ '$I18N_ARCHIVE_IM_SYNC_TASK.UPDATE_TITLE' | translate }}"
  showClose="true"
  [isLoading]="isLoading"
  (closeAside)="cancel.emit()"
>
  <mc-extra-container>
    <div class="mc-container-buttons-area">
      <button
        class="btn btn-primary"
        (click)="updateImSyncTask()"
        [disabled]="isEditDisabled()"
      >
        {{ '$I18N_ARCHIVE_IM_SYNC_TASK.CONTROLS.SAVE' | translate }}
      </button>
      <button class="btn btn-secondary" (click)="cancel.emit()">
        {{ '$I18N_ARCHIVE_IM_SYNC_TASK.CONTROLS.CANCEL' | translate }}
      </button>
    </div>
  </mc-extra-container>
  <mc-body-container>
      <form [formGroup]="form" class="form-horizontal" mcHorizontal>
        <mc-text-field
          label="{{ '$I18N_ARCHIVE_IM_SYNC_TASK.FIELD_NAME' | translate }}"
          placeholder="{{'$I18N_ARCHIVE_IM_SYNC_TASK.FIELD_NAME' | translate}}"
          errorPrefix="$I18N_ARCHIVE_IM_SYNC_TASK.VALIDATION.NAME"
          formControlName="name"
          name="name"
          required
        >
      </mc-text-field>

        <mc-text-area-field
          label="{{ '$I18N_ARCHIVE_IM_SYNC_TASK.FIELD_DESCRIPTION' | translate }}"
          placeholder="{{'$I18N_ARCHIVE_IM_SYNC_TASK.FIELD_DESCRIPTION' | translate}}"
          formControlName="description"
          name="description"
          errorPrefix="$I18N_ARCHIVE_IM_SYNC_TASK.VALIDATION.DESCRIPTION"
        >
        </mc-text-area-field>
      </form>
  </mc-body-container>
</mc-layout-aside-extra-container>
