"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectReviewersComponent = void 0;
const core_1 = require("@angular/core");
class SelectReviewersComponent {
    constructor(overlayRef, fb) {
        this.overlayRef = overlayRef;
        this.fb = fb;
        this.placeholder = '$I18N_ADMINISTRATION_COMMONS_FILTER_SEARCHBOX_PLACEHOLDER';
        this.searchQueryChanged = new core_1.EventEmitter();
        this.selectReviewers = new core_1.EventEmitter();
        this.noReviewersFound = false;
        this.tempSelectedReviewers = [];
        this.selectionChanged = false;
        this.form = this.fb.group({});
    }
    ngOnChanges(changes) {
        if (changes.selectedReviewers && changes.selectedReviewers.currentValue) {
            changes.selectedReviewers.currentValue.forEach((selectedReviewer) => (this.tempSelectedReviewers = [...this.tempSelectedReviewers, selectedReviewer]));
        }
        if (changes.reviewers && changes.reviewers.currentValue) {
            this.form = this.getReviewersForm();
            this.noReviewersFound = changes.reviewers.currentValue.length === 0;
        }
    }
    ngOnInit() {
        this.searchReviewer.nativeElement.focus();
    }
    onClose() {
        this.overlayRef.close();
    }
    selectReviewersButton() {
        this.onClose();
        this.selectReviewers.emit(this.getSelectedReviewers());
    }
    get sortedReviewers() {
        return this.reviewers
            ? this.reviewers
                .slice()
                .sort((a, b) => this.getReviewerName(a).localeCompare(this.getReviewerName(b)))
            : [];
    }
    getReviewersForm() {
        const group = {};
        if (this.reviewers) {
            this.reviewers.forEach(reviewer => {
                group[reviewer.id] = [this.isReviewerSelected(reviewer)];
            });
        }
        return this.fb.group(group);
    }
    getSelectedReviewers() {
        return this.tempSelectedReviewers;
    }
    getReviewerName(reviewer) {
        if (!reviewer) {
            return '';
        }
        if (reviewer.name && reviewer.emailAddress && reviewer.name !== reviewer.emailAddress) {
            return `${reviewer.name} [${reviewer.emailAddress}]`;
        }
        else {
            return reviewer.emailAddress;
        }
    }
    isReviewerSelected(reviewer) {
        if (!this.tempSelectedReviewers) {
            return false;
        }
        return this.tempSelectedReviewers.some(r => r.id === reviewer.id);
    }
    onSearchChange(event) {
        const searchQuery = event.target.value;
        this.searchQueryChanged.emit(searchQuery);
    }
    onSelectReviewer(event, reviewer) {
        this.selectionChanged = true;
        if (event && event.target && event.target.checked === true) {
            this.tempSelectedReviewers = [...this.tempSelectedReviewers, reviewer];
        }
        else {
            this.tempSelectedReviewers = this.tempSelectedReviewers.filter(r => r.id !== reviewer.id);
        }
    }
    get hasChanges() {
        return this.selectionChanged;
    }
}
exports.SelectReviewersComponent = SelectReviewersComponent;
