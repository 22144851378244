"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreatePolicyPageComponent = void 0;
const createAction = require("../../actions/policies-create.actions");
const reducers = require("../../reducers/index");
class CreatePolicyPageComponent {
    constructor(store) {
        this.store = store;
    }
    ngOnInit() {
        this.isLoading$ = this.store.select(reducers.getLoadingState);
        this.wizardFlowTypeSubscription = this.store
            .select(reducers.getWizardFlowType)
            .subscribe((wft) => {
            this.wizardFlowType = wft;
        });
    }
    onCreateNewPolicy() {
        this.store.dispatch(new createAction.OpenCreatePolicyPage());
    }
    ngOnDestroy() {
        if (this.wizardFlowTypeSubscription) {
            this.wizardFlowTypeSubscription.unsubscribe();
        }
    }
}
exports.CreatePolicyPageComponent = CreatePolicyPageComponent;
