"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateEffects = void 0;
const effects_1 = require("@ngrx/effects");
const operators_1 = require("rxjs/operators");
const actions = require("../../actions");
const okta_actions_1 = require("app-new/api-applications/actions/integration/okta.actions");
class CreateEffects {
    constructor(actions$, stateService) {
        this.actions$ = actions$;
        this.stateService = stateService;
        this.startIntegrationCreateCortex$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationCreateActionTypes.StartIntegrationCreateCortex), operators_1.tap(() => {
            this.stateService.$state.go('api-cortex-integration-new');
        }));
        this.startIntegrationWildfire$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationCreateActionTypes.StartIntegrationWildfire), operators_1.tap(() => {
            this.stateService.$state.go('api-integration-wildfire');
        }));
        this.startIntegrationCrowdstrike$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationCreateActionTypes.StartIntegrationCrowdstrike), operators_1.tap(() => {
            this.stateService.$state.go('api-integration-crowdstrike');
        }));
        this.startIntegrationOkta$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationCreateActionTypes.StartIntegrationOkta), operators_1.tap(() => {
            this.stateService.$state.go('api-integration-okta');
        }), operators_1.switchMap(() => {
            return [new okta_actions_1.SetDefaultOktaFolderAction()];
        }));
        this.startIntegrationOktaEvidenceBasedControl$ = this.actions$.pipe(effects_1.ofType(actions.IntegrationCreateActionTypes.StartIntegrationOktaEvidenceBasedControl), operators_1.tap(() => {
            this.stateService.$state.go('api-integration-okta-evidence-based-control');
        }));
    }
}
__decorate([
    effects_1.Effect({ dispatch: false })
], CreateEffects.prototype, "startIntegrationCreateCortex$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], CreateEffects.prototype, "startIntegrationWildfire$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], CreateEffects.prototype, "startIntegrationCrowdstrike$", void 0);
__decorate([
    effects_1.Effect()
], CreateEffects.prototype, "startIntegrationOkta$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], CreateEffects.prototype, "startIntegrationOktaEvidenceBasedControl$", void 0);
exports.CreateEffects = CreateEffects;
