"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttachmentProtectionSidePanelViewDetailsComponent = void 0;
class AttachmentProtectionSidePanelViewDetailsComponent {
    constructor(translateService, apStripAndLinkService) {
        this.translateService = translateService;
        this.apStripAndLinkService = apStripAndLinkService;
    }
    ngOnInit() {
        this.hasStripAndLinkEncAttachments$ = this.apStripAndLinkService.isEncAttachmentsSwitchEnabled();
    }
    displayStripAndLinkEncAttachments(action) {
        return this.apStripAndLinkService.isValidConfigType(action);
    }
    translate(value) {
        return this.translateService.instant(value);
    }
    getEnabledDisabledLabel(value) {
        return this.translate(value
            ? '$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.DETAILS.VALUES.ENABLED'
            : '$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.DETAILS.VALUES.DISABLED');
    }
    getDefinitionValueType(value) {
        return this.translate(value
            ? '$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.DETAILS.VALUES.' +
                value.toUpperCase()
            : '-');
    }
    getTranscribeWorksheet(value) {
        return this.translate(value
            ? '$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.DETAILS.VALUES.FIRST_WORKSHEET'
            : '$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.DETAILS.VALUES.ALL_WORKSHEETS');
    }
}
exports.AttachmentProtectionSidePanelViewDetailsComponent = AttachmentProtectionSidePanelViewDetailsComponent;
