"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DetailsComponent = void 0;
const core_1 = require("@angular/core");
class DetailsComponent {
    constructor(capabilitiesService) {
        this.capabilitiesService = capabilitiesService;
        this.notificationDetailsDismissForever = false;
        this.dismissForever = new core_1.EventEmitter();
    }
    ngOnInit() {
        this.capabilitiesService
            .hasCapability('Temporary.MessageCenter.SpamScore')
            .subscribe(isActive => {
            if (isActive === true) {
                this.showSpamDetails = true;
            }
            else {
                this.showSpamDetails = false;
            }
        });
    }
    showTripletInformation() {
        return this.validStatuses(['deferred', 'rejected']);
    }
    showMessageProperties() {
        return this.validStatuses([
            'held',
            'delivery',
            'bulk_delivery',
            'processing',
            'bulk_processing'
        ]);
    }
    showQueueInformation() {
        return this.validStatuses(['delivery', 'processing', 'bulk_delivery', 'bulk_processing']);
    }
    showRemoteServerInfo() {
        return this.validStatuses(['bounced', 'deferred', 'rejected']);
    }
    showMtaServerInfo() {
        return this.validStatuses(['deferred', 'rejected']);
    }
    showDeliveryFailureNoticeInfo() {
        return this.validStatuses(['bounced']);
    }
    showSpamDetailsInfo() {
        if (!!this.showSpamDetails) {
            return this.validStatuses(['held', 'bounced', 'deferred', 'rejected']);
        }
        else {
            return false;
        }
    }
    validStatuses(statuses) {
        return statuses.indexOf(this.messageStatus) !== -1;
    }
    ngOnChanges() {
        switch (this.messageStatus) {
            case 'bounced':
                this.infoBoxMessage = '$I18N_MESSAGE_CENTER_MESSAGE_INFO_DETAILS.BOUNCED_INFO_BOX_MESSAGE';
                this.infoBoxLearnMoreUrl = 'https://community.mimecast.com/docs/DOC-1348';
                break;
            case 'deferred':
                this.infoBoxMessage = '$I18N_MESSAGE_CENTER_MESSAGE_INFO_DETAILS.DEFERRED_INFO_BOX_MESSAGE';
                this.infoBoxLearnMoreUrl = 'https://community.mimecast.com/docs/DOC-3342';
                break;
            case 'rejected':
                this.infoBoxMessage = '$I18N_MESSAGE_CENTER_MESSAGE_INFO_DETAILS.REJECTED_INFO_BOX_MESSAGE';
                this.infoBoxLearnMoreUrl = 'https://community.mimecast.com/docs/DOC-1344';
                break;
            default:
                this.infoBoxMessage = '';
                this.infoBoxLearnMoreUrl = '';
                break;
        }
    }
}
exports.DetailsComponent = DetailsComponent;
