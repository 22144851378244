"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MepSidebarEnableSwitchComponent = void 0;
const core_1 = require("@angular/core");
const policy_actions_1 = require("../../../actions/policy.actions");
const operators_1 = require("rxjs/operators");
class MepSidebarEnableSwitchComponent {
    constructor(fb, store) {
        this.fb = fb;
        this.store = store;
        this.policyStatusChange = new core_1.EventEmitter();
    }
    ngOnInit() {
        this.enabledForm = this.fb.group({
            isEnabled: this.status
        });
        this.enabledFormSubscription = this.enabledForm.valueChanges
            .pipe(operators_1.debounceTime(300))
            .subscribe(data => {
            this.update(data.isEnabled);
        });
    }
    update(isEnabled) {
        this.store.dispatch(new policy_actions_1.MepUpdateEnabledStatus(isEnabled));
        this.policyStatusChange.emit();
    }
    ngOnDestroy() {
        this.enabledFormSubscription.unsubscribe();
    }
}
exports.MepSidebarEnableSwitchComponent = MepSidebarEnableSwitchComponent;
