"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrackingMainTableComponent = void 0;
const core_1 = require("@angular/core");
const main_table_static_values_1 = require("./main-table.static-values");
class TrackingMainTableComponent {
    constructor(emailStatusPipe, emailRoutePipe, capabilitiesService, spamDetectionPipe) {
        this.emailStatusPipe = emailStatusPipe;
        this.emailRoutePipe = emailRoutePipe;
        this.capabilitiesService = capabilitiesService;
        this.spamDetectionPipe = spamDetectionPipe;
        /**
         * Variables
         */
        this.columns = [...main_table_static_values_1.tableColumns];
        this.exports = [...main_table_static_values_1.exportColumns];
        this.sections = main_table_static_values_1.tableSectionFilters;
        this.columnsToStartHidden = main_table_static_values_1.tableColumnsStartHidden;
        this.columnsAlwaysVisible = main_table_static_values_1.columnsAlwaysVisible;
        this.rowData = [];
        /**
         * Outputs
         */
        this.reportAs = new core_1.EventEmitter();
        this.filtersChange = new core_1.EventEmitter();
        this.export = new core_1.EventEmitter();
        this.paginatorChange = new core_1.EventEmitter();
        this.rowClick = new core_1.EventEmitter();
        this.capabilitiesService
            .hasCapability('Temporary.MessageCenter.SpamScore')
            .subscribe(isActive => {
            if (isActive === false) {
                const filteredColumns = this.columns.filter(value => value !== 'spamScore' && value !== 'detectionLevel');
                this.columns = filteredColumns;
                const filteredExports = this.exports.filter(value => value !== 'spamScore' && value !== 'spamDetection');
                this.exports = filteredExports;
            }
        });
    }
    /**
     * Inputs
     */
    set rows(data) {
        this.rowData = (data || []).map(row => {
            return Object.assign(Object.assign({}, row), { status: this.emailStatusPipe.transform(row.status), route: this.emailRoutePipe.transform(row.route), detectionLevel: this.spamDetectionPipe.transform(row.detectionLevel) });
        });
    }
    get rows() {
        return this.rowData;
    }
    hasStatusAction(row) {
        return (!!row &&
            !!row.status &&
            ['accepted', 'archived', 'bounced', 'held'].indexOf(row.status.toLowerCase()) >= 0);
    }
    exportResults() {
        this.export.emit(this.exports);
    }
}
exports.TrackingMainTableComponent = TrackingMainTableComponent;
