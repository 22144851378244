"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyncTaskChartColors = exports.SyncTaskChartSegmentTypes = void 0;
var SyncTaskChartSegmentTypes;
(function (SyncTaskChartSegmentTypes) {
    SyncTaskChartSegmentTypes["ENABLED"] = "enabled";
    SyncTaskChartSegmentTypes["WARNING"] = "warning";
    SyncTaskChartSegmentTypes["FAILED"] = "failed";
    SyncTaskChartSegmentTypes["DISABLED"] = "disabled";
})(SyncTaskChartSegmentTypes = exports.SyncTaskChartSegmentTypes || (exports.SyncTaskChartSegmentTypes = {}));
exports.SyncTaskChartColors = {
    [SyncTaskChartSegmentTypes.ENABLED]: '#50B450',
    [SyncTaskChartSegmentTypes.WARNING]: '#FFCC00',
    [SyncTaskChartSegmentTypes.FAILED]: '#D63636',
    [SyncTaskChartSegmentTypes.DISABLED]: '#999999'
};
