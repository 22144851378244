"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./page-wizard-directive"); //layout.page-wizard.directive
require("app/components/layout/page-wizard-step/page-wizard-step"); //layout.page-wizard-step
// html and css 
require("./page-wizard.less");
require("./page-wizard.tpl.html");
(function () {
    'use strict';
    /* ======================================================== *\

     = layout / page wizard

     \* ======================================================== */
    angular.module('layout.page-wizard', ['layout.page-wizard.directive', 'layout.page-wizard-step']);
}());
