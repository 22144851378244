<form [formGroup]="switchForm" class="mc-enable-switch">
  <div class="panel-half-padding-bottom mc-enable-label" *ngIf="longDesc">
    {{ longDesc | translate }}
  </div>
  <div [ngClass]="[compact ? 'no-bottom-spacing' : '', contentDivClass]">
    <label [ngClass]="labelClass" *ngIf="label">
      {{ label | translate }}
    </label>
    <div [ngClass]="switchDivClass">
      <mc-switch
        formControlName="switch"
        [isDisabled]="isDisabled"
        [showLabel]="true"
        [onStateLabel]="onStateLabel"
        [offStateLabel]="offStateLabel"
        tabindex="0"
        role="switch"
        [attr.aria-checked]="this.switchForm.get('switch').value"
        (keyup.enter)="this.writeValue(!this.switchForm.get('switch').value)"
        (keyup.space)="this.writeValue(!this.switchForm.get('switch').value)"
      >
      </mc-switch>
    </div>
  </div>
</form>
