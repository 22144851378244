"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgBlockPageSettingsComponent = void 0;
const core_1 = require("@angular/core");
const operators_1 = require("rxjs/operators");
const DEBOUNCE_TIME_MS = 200;
class SwgBlockPageSettingsComponent {
    constructor(fb, stateService) {
        this.fb = fb;
        this.stateService = stateService;
        this.saveSettings = new core_1.EventEmitter();
    }
    ngOnChanges(changes) {
        const change = changes['settings'];
        if (change && !change.isFirstChange()) {
            this.blockPageSettingsForm.patchValue(this.settings);
        }
    }
    ngOnInit() {
        this.blockPageSettingsForm = this.fb.group({
            customTitle: [{ value: this.settings.customTitle, disabled: !this.settings.customEnabled }],
            customBody: [{ value: this.settings.customBody, disabled: !this.settings.customEnabled }],
            customEnabled: this.settings.customEnabled,
            customAwarenessTipsEnabled: this.settings.customAwarenessTipsEnabled,
            customAwarenessFooter: this.settings.customAwarenessFooter
        });
        this.blockPageSettingsFormSubscription = this.blockPageSettingsForm.valueChanges
            .pipe(operators_1.debounceTime(DEBOUNCE_TIME_MS))
            .subscribe(settings => {
            this.enableDisableFields(settings);
        });
    }
    enableDisableFields(settings) {
        if (settings.customEnabled && this.blockPageSettingsForm.get('customTitle').disabled) {
            this.blockPageSettingsForm.controls['customTitle'].enable();
            this.blockPageSettingsForm.controls['customBody'].enable();
        }
        if (!settings.customEnabled && !this.blockPageSettingsForm.get('customTitle').disabled) {
            this.blockPageSettingsForm.patchValue({
                customTitle: '',
                customBody: '',
                customEnabled: settings.customEnabled,
                customAwarenessTipsEnabled: false,
                customAwarenessFooter: ''
            });
            this.blockPageSettingsForm.controls['customTitle'].disable();
            this.blockPageSettingsForm.controls['customBody'].disable();
        }
        if (settings.customAwarenessTipsEnabled && settings.customTitle) {
            this.blockPageSettingsForm.patchValue({
                customTitle: ''
            });
        }
    }
    save() {
        const settings = {
            customEnabled: this.blockPageSettingsForm.get('customEnabled').value,
            customTitle: this.blockPageSettingsForm.get('customEnabled').value
                ? this.blockPageSettingsForm.get('customTitle').value
                : '',
            customBody: this.blockPageSettingsForm.get('customEnabled').value
                ? this.blockPageSettingsForm.get('customBody').value
                : '',
            customAwarenessFooter: this.blockPageSettingsForm.get('customEnabled').value
                ? this.blockPageSettingsForm.get('customBody').value
                : '',
            customAwarenessTipsEnabled: this.blockPageSettingsForm.get('customAwarenessTipsEnabled').value
        };
        this.saveSettings.emit(settings);
    }
    cancel() {
        this.stateService.$state.go('secure-web-gateway-policy-list');
    }
    ngOnDestroy() {
        this.blockPageSettingsFormSubscription.unsubscribe();
    }
}
exports.SwgBlockPageSettingsComponent = SwgBlockPageSettingsComponent;
