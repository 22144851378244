"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const swg_locations_list_component_1 = require("./container/list/swg-locations-list.component");
angular
    .module('swgLocationsList', [])
    .directive('mcSwgLocationsList', static_1.downgradeComponent({
    component: swg_locations_list_component_1.SwgLocationsListComponent
}))
    .config([
    '$stateProvider',
    ($stateProvider) => {
        $stateProvider.state('secure-web-gateway-locations-list', {
            url: '/secure-web-gateway/locations-list',
            data: {
                capabilities: 'Permission.SWG_CONFIGURE_SITES_READ',
                isFixedLayout: false,
                checkProgress: false,
                tabReload: true,
                tabTitle: 'Secure Locations'
            },
            views: {
                main: {
                    template: '<mc-swg-locations-list></mc-swg-locations-list>'
                }
            }
        });
    }
]);
