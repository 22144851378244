"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OktaApiModalComponent = void 0;
const okta_actions_1 = require("app-new/api-applications/actions/integration/okta.actions");
class OktaApiModalComponent {
    constructor(overlayRef, store$, data) {
        this.overlayRef = overlayRef;
        this.store$ = store$;
        this.data = data;
        this.oktaLink = 'https://community.mimecast.com/s/article/Okta-Directory-SCIM';
        this.eyeValue = 'fa-eye-slash';
        this.inputType = 'password';
        this.tokenCopied = false;
    }
    copyToClipboardSuccess() {
        this.tokenCopied = true;
    }
    toggleEye() {
        if (this.eyeValue === 'fa-eye-slash') {
            this.tokenCopied = true;
            this.eyeValue = 'fa-eye';
            this.inputType = 'text';
        }
        else {
            this.eyeValue = 'fa-eye-slash';
            this.inputType = 'password';
        }
    }
    getEyeValue() {
        return `fas ${this.eyeValue}`;
    }
    isDisabled() {
        return !this.tokenCopied;
    }
    close() {
        this.overlayRef.close(false);
        this.store$.dispatch(new okta_actions_1.RemoveOktaAccessTokenAction());
    }
    getHeaderTitle() {
        return this.data.rotateToken
            ? '$I18N_API_APPLICATIONS_OKTA_MODAL.TITLE_UPDATE'
            : '$I18N_API_APPLICATIONS_OKTA_MODAL.TITLE';
    }
    getMessageLine1() {
        return this.data.rotateToken
            ? '$I18N_API_APPLICATIONS_OKTA_MODAL.MESSAGE_LINE_01_UPDATE'
            : '$I18N_API_APPLICATIONS_OKTA_MODAL.MESSAGE_LINE_01';
    }
    getMessageLine2() {
        return this.data.rotateToken
            ? '$I18N_API_APPLICATIONS_OKTA_MODAL.MESSAGE_LINE_02_UPDATE'
            : '$I18N_API_APPLICATIONS_OKTA_MODAL.MESSAGE_LINE_02';
    }
}
exports.OktaApiModalComponent = OktaApiModalComponent;
