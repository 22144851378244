"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IntegrationProviderPipe = void 0;
class IntegrationProviderPipe {
    constructor(translateService) {
        this.translateService = translateService;
    }
    transform(input) {
        switch (input) {
            case 'cortex':
                return this.translateService.instant('$I18N_API_APPLICATIONS_INTEGRATION_PROVIDER_PIPE.' + input.toUpperCase());
            case 'wildfire':
                return this.translateService.instant('$I18N_API_APPLICATIONS_INTEGRATION_PROVIDER_PIPE.' + input.toUpperCase());
            case 'crowdstrike':
                return this.translateService.instant('$I18N_API_APPLICATIONS_INTEGRATION_PROVIDER_PIPE.' + input.toUpperCase());
            case 'scim_okta':
                return this.translateService.instant('$I18N_API_APPLICATIONS_INTEGRATION_PROVIDER_PIPE.' + input.toUpperCase());
            case 'okta_evidence_based_control':
                return this.translateService.instant('$I18N_API_APPLICATIONS_INTEGRATION_PROVIDER_PIPE.' + input.toUpperCase());
            case 'unknown':
            default:
                return this.translateService.instant('$I18N_API_APPLICATIONS_INTEGRATION_PROVIDER_PIPE.OTHER');
        }
    }
}
exports.IntegrationProviderPipe = IntegrationProviderPipe;
