"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SessionHandlerModule = void 0;
const ngx_cookie_service_1 = require("ngx-cookie-service");
const session_handler_service_1 = require("./session-handler.service");
class SessionHandlerModule {
    static forRoot() {
        return {
            ngModule: SessionHandlerModule,
            providers: [session_handler_service_1.SessionHandlerService, ngx_cookie_service_1.CookieService]
        };
    }
}
exports.SessionHandlerModule = SessionHandlerModule;
