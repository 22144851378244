"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiemChannelWizardContainerComponent = void 0;
const reducers = require("../../../reducers");
const channelActions = require("../../../actions/siem-channels/channel.actions");
class SiemChannelWizardContainerComponent {
    constructor(store, stateService) {
        this.store = store;
        this.stateService = stateService;
    }
    ngOnInit() {
        this.channel$ = this.store.select(reducers.getSiemChannel);
        this.wizardFlowType$ = this.store.select(reducers.getWizardWorkflowType);
        this.metadataProducts$ = this.store.select(reducers.getMetadataProducts);
        this.metadataProductsByChannelType$ = this.store.select(reducers.getMetadataProductsByChannelType);
        this.apiIsProcessing$ = this.store.select(reducers.isApiProcessingInProgress);
    }
    onCreateNewChannel() {
        this.store.dispatch(new channelActions.SiemCreateChannel());
    }
    onEditChannel() { }
    updateChannelDescrInStore(channelDescr) {
        this.store.dispatch(new channelActions.SiemUpdateChannelDescription(channelDescr));
        this.store.dispatch(new channelActions.SiemLoadMetadataProductsByChannelType());
    }
    updateChannelProductsInStore(products) {
        this.store.dispatch(new channelActions.SiemUpdateChannelProducts(products));
    }
    updateChannelProductEventsInStore(product) {
        this.store.dispatch(new channelActions.SiemUpdateChannelProductEvents(product));
    }
    cancelWizard() {
        this.stateService.$state.go('api-applications-list', { displayTab: 'event-channels' });
    }
}
exports.SiemChannelWizardContainerComponent = SiemChannelWizardContainerComponent;
