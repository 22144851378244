"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.getFailure = exports.getTotalAllowance = exports.getTotalUsage = exports.getIsUsageLoading = exports.getListState = exports.getDrivesState = exports.reducers = void 0;
const fromList = require("../list/reducers/list.reducer");
const store_1 = require("@ngrx/store");
exports.reducers = {
    list: fromList.reducer
};
exports.getDrivesState = store_1.createFeatureSelector('sync-recover-onedrive-drives');
const ɵ0 = (state) => state.list;
exports.ɵ0 = ɵ0;
exports.getListState = store_1.createSelector(exports.getDrivesState, ɵ0);
exports.getIsUsageLoading = store_1.createSelector(exports.getListState, fromList.getIsUsageLoading);
exports.getTotalUsage = store_1.createSelector(exports.getListState, fromList.getTotalUsage);
exports.getTotalAllowance = store_1.createSelector(exports.getListState, fromList.getTotalAllowance);
exports.getFailure = store_1.createSelector(exports.getListState, fromList.getFailure);
