"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ManagedUrlsListImplService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const table_store_1 = require("@mimecast-ui/table-store");
class ManagedUrlsListImplService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    static getDataFromResponse(response) {
        return {
            data: response.all,
            meta: response.meta,
            hasErrors: response.hasErrors,
            failures: response.fail
        };
    }
    getData(payload, pagination = table_store_1.defaultPaginationOption) {
        return this.httpClient
            .post('/api/ttp/url/get-all-managed-urls', Object.assign(Object.assign(Object.assign({}, this.transformToSearchServerRequest(payload)), this.transformToFilterServerRequest(payload)), { sortByUrl: true, meta: { pagination } }))
            .pipe(operators_1.map((response) => {
            return ManagedUrlsListImplService.getDataFromResponse(response);
        }), operators_1.catchError((error) => {
            return rxjs_1.of(ManagedUrlsListImplService.getDataFromResponse(error.error));
        }));
    }
    filter(query, pagination) {
        return this.getData(query, pagination);
    }
    transformToSearchServerRequest(data) {
        if (data && data.searchBy) {
            switch (data.searchBy.fieldName) {
                case 'all':
                    return {
                        domainOrComment: data.searchBy.value,
                        exactMatch: false,
                        domainOrUrl: data.searchBy.value
                    };
                case 'domainOrComments':
                    return {
                        domainOrComment: data.searchBy.value
                    };
                case 'onlyDomain':
                    return {
                        exactMatch: false,
                        domainOrUrl: data.searchBy.value
                    };
                case 'url':
                    return {
                        exactMatch: true,
                        domainOrUrl: data.searchBy.value
                    };
            }
        }
        return {
            exactMatch: false,
            domainOrUrl: ''
        };
    }
    transformToFilterServerRequest(data) {
        if (data && data.filterBy) {
            return {
                filterBy: [
                    {
                        fieldName: 'overridetype',
                        value: data.filterBy.length < 2 ? data.filterBy[0] : 'all'
                    }
                ]
            };
        }
        return {};
    }
}
exports.ManagedUrlsListImplService = ManagedUrlsListImplService;
