"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ1 = exports.ɵ0 = exports.getDashboardStatistics = exports.reducer = exports.initialState = void 0;
const statistics_actions_1 = require("../actions/statistics.actions");
const store_1 = require("@ngrx/store");
const dashboard_statistics_builder_1 = require("../model/statistics/builders/dashboard-statistics.builder");
exports.initialState = {
    statistics: null,
    loading: false // Is this required?
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case statistics_actions_1.StatisticsActions.GET_DASHBOARD_STATISTICS:
            return Object.assign(Object.assign({}, state), { loading: true });
        case statistics_actions_1.StatisticsActions.GET_DASHBOARD_STATISTICS_SUCCESS:
            return Object.assign(Object.assign({}, state), { loading: false, statistics: action.payload });
        case statistics_actions_1.StatisticsActions.GET_DASHBOARD_STATISTICS_FAIL:
            return Object.assign(Object.assign({}, state), { loading: false, failure: action.payload });
        default:
            return Object.assign({}, state);
    }
}
exports.reducer = reducer;
const getSupervisionStatistics = (state) => state && state.statistics;
const ɵ0 = getSupervisionStatistics;
exports.ɵ0 = ɵ0;
const ɵ1 = supervisionStatisticsData => {
    return supervisionStatisticsData && dashboard_statistics_builder_1.DashboardStatisticsBuilder.build(supervisionStatisticsData);
};
exports.ɵ1 = ɵ1;
exports.getDashboardStatistics = store_1.createSelector(getSupervisionStatistics, ɵ1);
