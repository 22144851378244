"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.ATTRIBUTE_FACTORY = void 0;
const attribute_builder_1 = require("../model/attribute.builder");
const ɵ0 = (attribute) => new attribute_builder_1.AttributeBuilder()
    .withId(attribute['id'])
    .withPrompt(attribute['prompt'])
    .withPromptGroup(attribute['promptGroup'])
    .withPromptType(attribute['promptType'])
    .withPromptOrder(attribute['promptOrder'])
    .withPromptOptions(attribute['promptOptions'])
    .withAllowedInTableViews(attribute['allowedInTableViews'])
    .build();
exports.ɵ0 = ɵ0;
exports.ATTRIBUTE_FACTORY = {
    ATTRIBUTE: ɵ0
};
