"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteGroupCortexIntegrationNotificationsAction = exports.AddGroupCortexIntegrationNotificationsAction = exports.UpdateCortexIntegrationWildfireSettingsAction = exports.UpdateCortexIntegrationSpywareSettingsAction = exports.VerifyAndAddEmailCortexIntegrationNotificationsFailAction = exports.VerifyAndAddEmailCortexIntegrationNotificationsSuccessAction = exports.VerifyAndAddEmailCortexIntegrationNotificationsAction = exports.DeleteEmailCortexIntegrationNotificationsAction = exports.CloseCreateIntegrationAction = exports.CancelCreateIntegrationAction = exports.GetWildfireIntegrationCompleteAction = exports.GetIntegrationVersion1FailAction = exports.GetCortexIntegrationVersion1CompleteAction = exports.GetIntegrationAction = exports.AuthoriseCortexIntegrationCompleteAction = exports.AuthoriseCortexIntegrationVersion1Action = exports.AuthoriseCortexIntegrationAction = exports.UpdateCortexIntegrationCompleteVersion1Action = exports.CreateCortexIntegrationCompleteVersion1Action = exports.UpdateCortexIntegrationVersion1Action = exports.CreateCortexIntegrationVersion1Action = exports.UpdateCortexIntegrationNotificationsAction = exports.InstantiateCortexIntegrationAction = exports.SelectIntegrationProviderAction = exports.IntegrationCortexActionTypes = void 0;
var IntegrationCortexActionTypes;
(function (IntegrationCortexActionTypes) {
    IntegrationCortexActionTypes["SelectIntegrationProvider"] = "[ApiApplication/Integration/Create] Select Integration Provider";
    IntegrationCortexActionTypes["InstantiateCortexIntegration"] = "[ApiApplication/Integration/Create] Configure Reentrant Integration";
    IntegrationCortexActionTypes["UpdateCortexIntegrationSpywareSettings"] = "[ApiApplication/Integration/Create/Cortex] Update Cortex Integration Spyware Settings";
    IntegrationCortexActionTypes["UpdateCortexIntegrationWildfireSettings"] = "[ApiApplication/Integration/Create/Cortex] Update Cortex Integration Wildfire Settings";
    IntegrationCortexActionTypes["UpdateCortexIntegrationNotifications"] = "[ApiApplication/Integration/Create/Cortex] Update Cortex Integration Notifications";
    IntegrationCortexActionTypes["DeleteEmailCortexIntegrationNotifications"] = "[ApiApplication/Integration/Create/Cortex] Delete email from   Cortex Integration Notifications";
    IntegrationCortexActionTypes["VerifyAndAddEmailCortexIntegrationNotifications"] = "[ApiApplication/Integration/Create/Cortex] Add email to Cortex   Integration Notifications";
    IntegrationCortexActionTypes["VerifyAndAddEmailCortexIntegrationNotificationsSuccess"] = "[ApiApplication/Integration/Create/Cortex] Add email to Cortex   Integration Notifications Success";
    IntegrationCortexActionTypes["VerifyAndAddEmailCortexIntegrationNotificationsFail"] = "[ApiApplication/Integration/Create/Cortex] Add email to Cortex Integration Notifications Fail";
    IntegrationCortexActionTypes["AddGroupCortexIntegrationNotifications"] = "[ApiApplication/Integration/Create/Cortex] Add Group";
    IntegrationCortexActionTypes["DeleteGroupCortexIntegrationNotifications"] = "[ApiApplication/Integration/Create/Cortex] Delete Group";
    IntegrationCortexActionTypes["CreateCortexIntegrationVersion1Complete"] = "[ApiApplication/Integration/Create/Cortex] Create Cortex Integration Complete v1";
    IntegrationCortexActionTypes["CreateCortexIntegrationVersion1"] = "[ApiApplication/Integration/Create/Cortex] Create Cortex Integration v1";
    IntegrationCortexActionTypes["UpdateCortexIntegrationVersion1"] = "[ApiApplication/Integration/Create/Cortex] Update Cortex Integration v1";
    IntegrationCortexActionTypes["UpdateCortexIntegrationCompleteVersion1"] = "[ApiApplication/Integration/Create/Cortex] Update Cortex Integration Complete v1";
    IntegrationCortexActionTypes["AuthoriseCortexIntegration"] = "[ApiApplication/Integration/Create/Cortex] Authorise Cortex Integration";
    IntegrationCortexActionTypes["AuthoriseCortexIntegrationVersion1"] = "[ApiApplication/Integration/Create/Cortex] Authorise Cortex Integration v1";
    IntegrationCortexActionTypes["AuthoriseCortexIntegrationComplete"] = "[ApiApplication/Integration/Create/Cortex] Authorise Cortex Integration Complete";
    IntegrationCortexActionTypes["GetIntegration"] = "[ApiApplication/Integration/Create] Get Integration";
    IntegrationCortexActionTypes["GetCortexIntegrationCompleteVersion1"] = "[ApiApplication/Integration/Create] Get Cortex Integration v1 Complete";
    IntegrationCortexActionTypes["GetWildfireIntegrationComplete"] = "[ApiApplication/Integration/Create] Get Wildfire Integration Complete";
    IntegrationCortexActionTypes["GetIntegrationVersion1Fail"] = "[ApiApplication/Integration/Create] Get Integration v1 Fail";
    IntegrationCortexActionTypes["CancelCreateIntegration"] = "[ApiApplication/Integration/Create] Cancel Create Integration";
    IntegrationCortexActionTypes["CloseCreateIntegration"] = "[ApiApplication/Integration/Create] Close Create Integration";
})(IntegrationCortexActionTypes = exports.IntegrationCortexActionTypes || (exports.IntegrationCortexActionTypes = {}));
class SelectIntegrationProviderAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationCortexActionTypes.SelectIntegrationProvider;
    }
}
exports.SelectIntegrationProviderAction = SelectIntegrationProviderAction;
class InstantiateCortexIntegrationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationCortexActionTypes.InstantiateCortexIntegration;
    }
}
exports.InstantiateCortexIntegrationAction = InstantiateCortexIntegrationAction;
class UpdateCortexIntegrationNotificationsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationCortexActionTypes.UpdateCortexIntegrationNotifications;
    }
}
exports.UpdateCortexIntegrationNotificationsAction = UpdateCortexIntegrationNotificationsAction;
class CreateCortexIntegrationVersion1Action {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationCortexActionTypes.CreateCortexIntegrationVersion1;
    }
}
exports.CreateCortexIntegrationVersion1Action = CreateCortexIntegrationVersion1Action;
class UpdateCortexIntegrationVersion1Action {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationCortexActionTypes.UpdateCortexIntegrationVersion1;
    }
}
exports.UpdateCortexIntegrationVersion1Action = UpdateCortexIntegrationVersion1Action;
class CreateCortexIntegrationCompleteVersion1Action {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationCortexActionTypes.CreateCortexIntegrationVersion1Complete;
    }
}
exports.CreateCortexIntegrationCompleteVersion1Action = CreateCortexIntegrationCompleteVersion1Action;
class UpdateCortexIntegrationCompleteVersion1Action {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationCortexActionTypes.UpdateCortexIntegrationCompleteVersion1;
    }
}
exports.UpdateCortexIntegrationCompleteVersion1Action = UpdateCortexIntegrationCompleteVersion1Action;
class AuthoriseCortexIntegrationAction {
    constructor() {
        this.type = IntegrationCortexActionTypes.AuthoriseCortexIntegration;
    }
}
exports.AuthoriseCortexIntegrationAction = AuthoriseCortexIntegrationAction;
class AuthoriseCortexIntegrationVersion1Action {
    constructor() {
        this.type = IntegrationCortexActionTypes.AuthoriseCortexIntegrationVersion1;
    }
}
exports.AuthoriseCortexIntegrationVersion1Action = AuthoriseCortexIntegrationVersion1Action;
class AuthoriseCortexIntegrationCompleteAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationCortexActionTypes.AuthoriseCortexIntegrationComplete;
    }
}
exports.AuthoriseCortexIntegrationCompleteAction = AuthoriseCortexIntegrationCompleteAction;
class GetIntegrationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationCortexActionTypes.GetIntegration;
    }
}
exports.GetIntegrationAction = GetIntegrationAction;
class GetCortexIntegrationVersion1CompleteAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationCortexActionTypes.GetCortexIntegrationCompleteVersion1;
    }
}
exports.GetCortexIntegrationVersion1CompleteAction = GetCortexIntegrationVersion1CompleteAction;
class GetIntegrationVersion1FailAction {
    constructor() {
        this.type = IntegrationCortexActionTypes.GetIntegrationVersion1Fail;
    }
}
exports.GetIntegrationVersion1FailAction = GetIntegrationVersion1FailAction;
class GetWildfireIntegrationCompleteAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationCortexActionTypes.GetWildfireIntegrationComplete;
    }
}
exports.GetWildfireIntegrationCompleteAction = GetWildfireIntegrationCompleteAction;
class CancelCreateIntegrationAction {
    constructor() {
        this.type = IntegrationCortexActionTypes.CancelCreateIntegration;
    }
}
exports.CancelCreateIntegrationAction = CancelCreateIntegrationAction;
class CloseCreateIntegrationAction {
    constructor() {
        this.type = IntegrationCortexActionTypes.CloseCreateIntegration;
    }
}
exports.CloseCreateIntegrationAction = CloseCreateIntegrationAction;
class DeleteEmailCortexIntegrationNotificationsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationCortexActionTypes.DeleteEmailCortexIntegrationNotifications;
    }
}
exports.DeleteEmailCortexIntegrationNotificationsAction = DeleteEmailCortexIntegrationNotificationsAction;
class VerifyAndAddEmailCortexIntegrationNotificationsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationCortexActionTypes.VerifyAndAddEmailCortexIntegrationNotifications;
    }
}
exports.VerifyAndAddEmailCortexIntegrationNotificationsAction = VerifyAndAddEmailCortexIntegrationNotificationsAction;
class VerifyAndAddEmailCortexIntegrationNotificationsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationCortexActionTypes.VerifyAndAddEmailCortexIntegrationNotificationsSuccess;
    }
}
exports.VerifyAndAddEmailCortexIntegrationNotificationsSuccessAction = VerifyAndAddEmailCortexIntegrationNotificationsSuccessAction;
class VerifyAndAddEmailCortexIntegrationNotificationsFailAction {
    constructor() {
        this.type = IntegrationCortexActionTypes.VerifyAndAddEmailCortexIntegrationNotificationsFail;
    }
}
exports.VerifyAndAddEmailCortexIntegrationNotificationsFailAction = VerifyAndAddEmailCortexIntegrationNotificationsFailAction;
class UpdateCortexIntegrationSpywareSettingsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationCortexActionTypes.UpdateCortexIntegrationSpywareSettings;
    }
}
exports.UpdateCortexIntegrationSpywareSettingsAction = UpdateCortexIntegrationSpywareSettingsAction;
class UpdateCortexIntegrationWildfireSettingsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationCortexActionTypes.UpdateCortexIntegrationWildfireSettings;
    }
}
exports.UpdateCortexIntegrationWildfireSettingsAction = UpdateCortexIntegrationWildfireSettingsAction;
class AddGroupCortexIntegrationNotificationsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationCortexActionTypes.AddGroupCortexIntegrationNotifications;
    }
}
exports.AddGroupCortexIntegrationNotificationsAction = AddGroupCortexIntegrationNotificationsAction;
class DeleteGroupCortexIntegrationNotificationsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationCortexActionTypes.DeleteGroupCortexIntegrationNotifications;
    }
}
exports.DeleteGroupCortexIntegrationNotificationsAction = DeleteGroupCortexIntegrationNotificationsAction;
