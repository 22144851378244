"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PoliciesService = void 0;
const policy_wizard_models_1 = require("../models/policy-wizard.models");
const components_1 = require("@mimecast-ui/components");
const create_policies_reducer_1 = require("../reducers/create-policies.reducer");
const url_protection_applies_to_settings_step_models_1 = require("../components/steps/url-protection-applies-to-settings-step/url-protection-applies-to-settings-step.models");
const moment = require("moment");
const url_protection_inbound_settings_step_models_1 = require("../components/steps/url-protection-inbound-settings-step/url-protection-inbound-settings-step.models");
const outbound = require("../components/steps/url-protection-outbound-settings-step/url-protection-outbound-settings-step.models");
const journal = require("../components/steps/url-protection-journal-settings-step/url-protection-journal-settings-step.models");
class PoliciesService {
    constructor(httpClient, accountPackageService) {
        this.httpClient = httpClient;
        this.accountPackageService = accountPackageService;
    }
    getUserAwarenessTemplates() {
        return this.httpClient.post('/api/ttp/userawareness/get-template', '');
    }
    getAddressAttributes() {
        const config = {
            meta: { pagination: { pageSize: 5000 } }
        };
        return this.httpClient.post('/api/attribute/find-attribute-types', config);
    }
    createUrlPolicyDefinition(request) {
        return this.httpClient.post('/api/policy/urlprotect/create-definition-policy', request);
    }
    createUrlPolicyWithBypass(request) {
        return this.httpClient.post('/api/policy/urlprotect/create-policy', request);
    }
    editUrlPolicyDetails(request) {
        return this.httpClient.post('/api/policy/urlprotect/update-policy', request);
    }
    editPolicyDefinitionRules(request) {
        return this.httpClient.post('/api/policy/urlprotect/update-policy-definition-rules', request);
    }
    editPolicyRules(request) {
        return this.httpClient.post('/api/policy/urlprotect/update-rules-policy', request);
    }
    userAwarenessTemplatesResponseToItems(response) {
        return response.all.map(item => {
            return {
                value: item.id,
                label: item.name
            };
        });
    }
    getPolicies(request) {
        return this.httpClient.post('/api/ttp/url/get-all-managed-urls', request);
    }
    appliesToAddressAttributeResponseToItems(response) {
        return response.all[0].attributeTypes.map(item => {
            return {
                value: item.id,
                label: item.prompt
            };
        });
    }
    getFromToDate(date) {
        let start = null;
        let end = null;
        if (date.id === 'USER_DEFINED') {
            start = date.range.start;
            end = date.range.end;
        }
        return {
            fromDate: start && start.format(components_1.API_DATE_FORMAT),
            toDate: end && end.format(components_1.API_DATE_FORMAT)
        };
    }
    addJournalStepDataToRequestData(requestData, journalData, isAAAorFAA) {
        const data = {
            journalInternalSender: journalData.internalSender,
            journalFileSandboxed: journalData.urlFileDownload === journal.UrlFileDownloadOptions.CHECK,
            journalAttachmentRewriteText: journalData.attachmentPartText,
            journalBlockDangerousExtension: journalData.blockUrlWithDangerousFileExtensions,
            journalAttachmentRewriteHtml: journalData.attachmentPartHTML,
            journalScanMode: journalData.urlScanningMode,
            journalCategoryScanMode: journalData.urlCategoryScanningMode,
            journalAttachmentRewriteCalendar: journalData.attachmentPartCalendar,
            // Checkbox in Journal section 'Other file types' represent Journal 'Scan URLs in Attachments' in database.
            journalScanLinkAttachments: journalData.attachmentPartOther,
            checkJournal: journalData.checkInternalEmail,
            journalInternalRecipient: journalData.internalRecipients,
            journalCheckAgainstCustomExternalDomains: journalData.domainToCheckAgainstCustomMonitoredExternalDomains,
            journalCheckAgainstMimecastExternalDomains: journalData.domainToCheckAgainstMimecastMonitoredExternalDomains,
            journalCheckAgainstInternalDomains: journalData.domainToCheckAgainstYourInternalDomains,
            journalMailFallbackAction: journalData.senderMailboxFallbackAction,
            journalMailboxAction: journalData.senderMailboxAction,
            journalNotifyEnabled: journalData.internalSender || journalData.internalRecipients
        };
        if (!isAAAorFAA) {
            const journalGroupId = journalData.administratorsSelectedGroup && journalData.administratorsSelectedGroup.id;
            data.journalGroupId = journalGroupId;
            data.journalNotifyEnabled =
                !!journalGroupId || journalData.internalSender || journalData.internalRecipients;
        }
        return Object.assign(Object.assign({}, requestData), data);
    }
    addUserAwarenessStepDataToRequestData(requestData, userAwarenessData) {
        return Object.assign(Object.assign({}, requestData), {
            userAwareness: userAwarenessData.showUserAwarenessChallenges,
            dynamicAdjustment: userAwarenessData.dynamicAdjustment,
            userAwarenessPageSetId: userAwarenessData.pageSetOption,
            challengePercentage: userAwarenessData.challengePercentage
        });
    }
    addOutboundStepDataToRequestData(requestData, outboundData, isAAAorFAA) {
        const data = {
            outboundCheckAgainstMimecastExternalDomains: outboundData.domainToCheckAgainstMimecastMonitoredExternalDomains,
            outboundMailboxAction: outboundData.senderMailboxAction,
            outboundInternalSender: outboundData.users,
            outboundScanMode: outboundData.urlScanningMode,
            outboundMailFallbackAction: outboundData.senderMailboxFallbackAction,
            outboundAttachmentRewriteHtml: outboundData.attachmentPartHTML,
            outboundAttachmentRewriteText: outboundData.attachmentPartText,
            outboundAttachmentRewriteCalendar: outboundData.attachmentPartCalendar,
            // Checkbox in Outbound section 'Other file types' represent Outbound 'Scan URLs in Attachments' in database.
            outboundScanLinkAttachments: outboundData.attachmentPartOther,
            outboundFileSandboxed: outboundData.urlFileDownload === outbound.UrlFileDownloadOptions.CHECK,
            checkOutbound: outboundData.checkOutboundEmail,
            outboundGatewayAction: outboundData.gatewayAction,
            outboundBlockDangerousExtension: outboundData.blockUrlWithDangerousFileExtensions,
            outboundCategoryScanMode: outboundData.urlCategoryScanningMode,
            outboundGatewayFallbackAction: outboundData.gatewayFallbackAction,
            outboundCheckAgainstInternalDomains: outboundData.domainToCheckAgainstYourInternalDomains,
            outboundCheckAgainstCustomExternalDomains: outboundData.domainToCheckAgainstCustomMonitoredExternalDomains,
            outboundNotifyEnabled: outboundData.users
        };
        if (!isAAAorFAA) {
            const outboundGroupId = outboundData.administratorsSelectedGroup && outboundData.administratorsSelectedGroup.id;
            data.outboundGroupId = outboundGroupId;
            data.outboundNotifyEnabled = !!outboundGroupId || outboundData.users;
        }
        return Object.assign(Object.assign({}, requestData), data);
    }
    addInboundStepDataToRequestData(requestData, inboundData, isAAAorFAA) {
        const data = {
            fileHandlingAction: inboundData.fileProtocolUrlHandling,
            fileDownloadAction: inboundData.urlFileDownload,
            subjectProtection: inboundData.messageSubjectProtection,
            action: inboundData.maliciousUrlDetectionAction,
            scanLinkAttachments: inboundData.scanUrlInAttachment,
            displayDestinationDomain: inboundData.displayUrlDestinationDomain,
            urlFormatNotification: inboundData.notificationUrlFormat,
            urlDetectionAction: inboundData.similarUrlDetection,
            rewriteMode: inboundData.urlRewriteMode,
            browserIsolation: inboundData.browserIsolation,
            ignoreSignedMessages: inboundData.ignoreSignedMessage,
            checkInbound: inboundData.checkInboundEmail,
            createHtml: inboundData.createMissingHtmlBody,
            blockDangerousExtension: inboundData.blockUrlsWithDangerousFileExtensions,
            attachmentRewriteHtml: inboundData.attachmentPartHtml,
            attachmentRewriteText: inboundData.attachmentPartText,
            attachmentRewriteCalendar: inboundData.attachmentPartCalendar,
            checkAgainstCustomExternalDomains: inboundData.customMonitoringExternalDomain,
            categoryScanMode: inboundData.urlCategoryScanningMode,
            checkAgainstInternalDomains: inboundData.internalDomains,
            checkAgainstMimecastExternalDomains: inboundData.mimecastMonitoringExternalDomain,
            stripExternalSource: inboundData.stripExternalSourceMode,
            doNotForceSecure: !inboundData.forceSecureConnection
        };
        if (!isAAAorFAA) {
            const inboundGroupId = inboundData.administratorsSelectedGroup && inboundData.administratorsSelectedGroup.id;
            data.groupId = inboundGroupId;
            data.notifyEnabled = !!inboundGroupId;
        }
        return Object.assign(Object.assign({}, requestData), data);
    }
    mapToPolicyDefinitionRulesRequest(policy) {
        const { inboundStep, outboundStep, journalStep, detailsStep, appliesToStep, userAwarenessStep, summaryStep } = policy;
        const inboundData = inboundStep.data;
        const outboundData = outboundStep.data;
        const journalData = journalStep.data;
        const detailsData = detailsStep.data;
        const appliesToData = appliesToStep.data;
        const userAwarenessData = userAwarenessStep.data;
        const summaryData = summaryStep.data;
        const isAAAorFAA = this.accountPackageService.isAAAOrFAAUser();
        let requestData = Object.assign({ checkInbound: inboundData.checkInboundEmail, userAwareness: userAwarenessData.showUserAwarenessChallenges }, this.mapDetailsAndAppliesToData(detailsData, appliesToData, summaryData));
        if (inboundData.checkInboundEmail) {
            requestData = this.addInboundStepDataToRequestData(requestData, inboundData, isAAAorFAA);
            if (inboundData.urlCategoryScanningMode !== url_protection_inbound_settings_step_models_1.UrlCategoryScanningOptions.AGGRESSIVE) {
                requestData.fileHandlingAction = url_protection_inbound_settings_step_models_1.FileProtocolUrlHandlingOptions.TAKE_NO_ACTIONS;
            }
        }
        if (this.accountPackageService.hasInternalEmailProtectAccountPackage() || isAAAorFAA) {
            requestData.checkOutbound = outboundData.checkOutboundEmail;
            requestData.checkJournal = journalData.checkInternalEmail;
            if (outboundData.checkOutboundEmail) {
                requestData = this.addOutboundStepDataToRequestData(requestData, outboundData, isAAAorFAA);
            }
            if (journalData.checkInternalEmail) {
                requestData = this.addJournalStepDataToRequestData(requestData, journalData, isAAAorFAA);
            }
        }
        else {
            requestData.checkOutbound = false;
            requestData.checkJournal = false;
        }
        if (userAwarenessData.showUserAwarenessChallenges) {
            requestData = this.addUserAwarenessStepDataToRequestData(requestData, userAwarenessData);
        }
        if (detailsStep.data.id) {
            requestData.policy.id = detailsStep.data.id;
        }
        return requestData;
    }
    mapToBypassPolicyRequest(policy) {
        const { detailsStep, appliesToStep, summaryStep } = policy;
        const detailsData = detailsStep.data;
        const appliesToData = appliesToStep.data;
        const summaryData = summaryStep.data;
        return this.mapDetailsAndAppliesToData(detailsData, appliesToData, summaryData);
    }
    mapDetailsToData(detailsData, summaryData) {
        const policy = Object.assign(Object.assign({ name: detailsData.name, description: detailsData.description }, this.getFromToDate(detailsData.ulrProtectionPolicyDuration)), { override: detailsData.policyOverride, enforced: detailsData.policyEnforcement, enabled: summaryData.policyStatusEnabled });
        if (detailsData.bypassPolicy) {
            policy.policyOptions = detailsData.bypassPolicyApplication;
        }
        if (detailsData.id) {
            policy.id = detailsData.id;
        }
        return policy;
    }
    mapRulesToData(appliesToData) {
        return (appliesToData.length &&
            appliesToData.map(rule => {
                const mappedRule = {
                    id: rule.id,
                    bidirectional: rule.bidirectional,
                    fromPart: rule.addressesBasedOn,
                    from: {
                        type: rule.appliesFrom
                    },
                    to: {
                        type: rule.appliesTo
                    },
                    conditions: {
                        sourceIPs: rule.ipRanges
                    }
                };
                if (rule.appliesFrom === url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.EMAIL_ADDRESS) {
                    mappedRule.from['emailAddress'] = rule.appliesFromAddress;
                }
                if (rule.appliesFrom === url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.DOMAIN) {
                    mappedRule.from['emailDomain'] = rule.appliesFromDomain;
                }
                if (rule.appliesFrom === url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.ADDRESS_GROUP) {
                    mappedRule.from['groupId'] =
                        rule.appliesFromAddressGroup && rule.appliesFromAddressGroup.id;
                }
                if (rule.appliesFrom === url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.ADDRESS_ATTRIBUTE) {
                    mappedRule.from['attribute'] = this.getAttribute(rule.appliesFrom, rule.fromWhereAttribute, rule.appliesFromIsEqualTo);
                }
                if (rule.appliesTo === url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.EMAIL_ADDRESS) {
                    mappedRule.to['emailAddress'] = rule.appliesToAddress;
                }
                if (rule.appliesTo === url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.DOMAIN) {
                    mappedRule.to['emailDomain'] = rule.appliesToDomain;
                }
                if (rule.appliesTo === url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.ADDRESS_GROUP) {
                    mappedRule.to['groupId'] = rule.appliesToAddressGroup && rule.appliesToAddressGroup.id;
                }
                if (rule.appliesTo === url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.ADDRESS_ATTRIBUTE) {
                    mappedRule.to['attribute'] = this.getAttribute(rule.appliesTo, rule.toWhereAttribute, rule.appliesToIsEqualTo);
                }
                if (rule.id) {
                    mappedRule['id'] = rule.id;
                }
                return mappedRule;
            }));
    }
    mapDetailsAndAppliesToData(detailsData, appliesToData, summaryData) {
        const policy = this.mapDetailsToData(detailsData, summaryData);
        return {
            policy,
            rules: this.mapRulesToData(appliesToData)
        };
    }
    getAttribute(type, attribute, isEqualTo) {
        if (type === url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.ADDRESS_ATTRIBUTE) {
            return {
                id: attribute.value,
                name: attribute.label,
                value: isEqualTo
            };
        }
        return undefined;
    }
    mapToWizardState(policyData, options) {
        const inboundData = options.isBypass
            ? create_policies_reducer_1.initialWizardState.inboundStep.data
            : this.mapInboundRequestToState(policyData);
        const outboundData = options.isBypass
            ? create_policies_reducer_1.initialWizardState.outboundStep.data
            : this.mapOutboundRequestToState(policyData);
        const journalData = options.isBypass
            ? create_policies_reducer_1.initialWizardState.journalStep.data
            : this.mapJournalRequestToState(policyData);
        const userAwarenessData = options.isBypass
            ? create_policies_reducer_1.initialWizardState.userAwarenessStep.data
            : this.mapUserAwarenessRequestToState(policyData);
        return {
            isLoading: false,
            detailsStep: {
                data: this.mapDetailsRequestToState(policyData.policy)
            },
            inboundStep: {
                data: inboundData
            },
            outboundStep: {
                data: outboundData
            },
            journalStep: {
                data: journalData
            },
            userAwarenessStep: {
                data: userAwarenessData
            },
            appliesToStep: {
                data: this.mapAppliesToRequestToState(policyData)
            },
            summaryStep: {
                data: {
                    policyStatusEnabled: policyData.policy.enabled
                }
            },
            wizardFlowType: options.wizardFlowType
        };
    }
    mapPolicyDurationToState(details) {
        let rangeMap = {
            id: policy_wizard_models_1.DurationDetailOption.CONTINUOUS,
            label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.DURATION_VALUES.CONTINUOUS'
        };
        if (details.fromDate) {
            const startDate = moment(details.fromDate);
            const endDate = details.toDate ? moment(details.toDate) : null;
            rangeMap = {
                id: policy_wizard_models_1.DurationDetailOption.USER_DEFINED,
                range: {
                    start: startDate,
                    end: endDate
                },
                rangeFn: () => {
                    return {
                        start: startDate,
                        end: endDate
                    };
                }
            };
        }
        if (!details.toDate && details.fromDate) {
            rangeMap.disableEndDate = {
                disable: true,
                valueLabel: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.DURATION_VALUES.CONTINUOUS'
            };
        }
        return rangeMap;
    }
    mapDetailsRequestToState(details) {
        return {
            id: details.id,
            name: details.name,
            description: details.description,
            bypassPolicy: !!details.policyOptions,
            bypassPolicyApplication: details.policyOptions || policy_wizard_models_1.BypassApplicationOptions.DISABLE_URL_PROTECT,
            policyOverride: details.override,
            policyEnforcement: details.enforced,
            ulrProtectionPolicyDuration: this.mapPolicyDurationToState(details)
        };
    }
    mapInboundRequestToState(policy) {
        return {
            checkInboundEmail: policy.checkInbound,
            urlRewriteMode: policy.rewriteMode,
            urlCategoryScanningMode: policy.categoryScanMode,
            maliciousUrlDetectionAction: policy.action,
            browserIsolation: policy.browserIsolation,
            messageSubjectProtection: policy.subjectProtection,
            createMissingHtmlBody: policy.createHtml,
            forceSecureConnection: !policy.doNotForceSecure,
            ignoreSignedMessage: policy.ignoreSignedMessages,
            displayUrlDestinationDomain: policy.displayDestinationDomain,
            stripExternalSourceMode: policy.stripExternalSource,
            fileProtocolUrlHandling: policy.fileHandlingAction,
            blockUrlsWithDangerousFileExtensions: policy.blockDangerousExtension,
            rewriteUrlsInAttachments: policy.attachmentRewriteHtml ||
                policy.attachmentRewriteText ||
                policy.attachmentRewriteCalendar,
            attachmentPartHtml: policy.attachmentRewriteHtml,
            attachmentPartText: policy.attachmentRewriteText,
            attachmentPartCalendar: policy.attachmentRewriteCalendar,
            urlFileDownload: policy.fileDownloadAction,
            scanUrlInAttachment: policy.scanLinkAttachments,
            checkForSimilarUrls: policy.checkAgainstCustomExternalDomains ||
                policy.checkAgainstInternalDomains ||
                policy.checkAgainstMimecastExternalDomains,
            internalDomains: policy.checkAgainstInternalDomains,
            mimecastMonitoringExternalDomain: policy.checkAgainstMimecastExternalDomains,
            customMonitoringExternalDomain: policy.checkAgainstCustomExternalDomains,
            stripExternalSource: undefined,
            similarUrlDetection: policy.urlDetectionAction,
            notificationUrlFormat: policy.urlFormatNotification,
            administratorsSelectedGroup: {
                id: policy.groupId,
                description: policy.notifyGroupDescription
            }
        };
    }
    mapOutboundRequestToState(policy) {
        return {
            checkOutboundEmail: policy.checkOutbound,
            urlScanningMode: policy.outboundScanMode,
            urlCategoryScanningMode: policy.outboundCategoryScanMode,
            gatewayAction: policy.outboundGatewayAction,
            gatewayFallbackAction: policy.outboundGatewayFallbackAction,
            senderMailboxAction: policy.outboundMailboxAction,
            senderMailboxFallbackAction: policy.outboundMailFallbackAction,
            blockUrlWithDangerousFileExtensions: policy.outboundBlockDangerousExtension,
            urlFileDownload: policy.outboundFileSandboxed
                ? outbound.UrlFileDownloadOptions.CHECK
                : outbound.UrlFileDownloadOptions.NO_ACTION,
            scanUrlInAttachments: policy.outboundAttachmentRewriteHtml ||
                policy.outboundAttachmentRewriteText ||
                policy.outboundAttachmentRewriteCalendar ||
                policy.outboundScanLinkAttachments,
            attachmentPartHTML: policy.outboundAttachmentRewriteHtml,
            attachmentPartText: policy.outboundAttachmentRewriteText,
            attachmentPartCalendar: policy.outboundAttachmentRewriteCalendar,
            // Checkbox in Outbound section 'Other file types' represent Outbound 'Scan URLs in Attachments' in database.
            attachmentPartOther: policy.outboundScanLinkAttachments,
            checkForSimilarUrls: policy.outboundCheckAgainstInternalDomains ||
                policy.outboundCheckAgainstMimecastExternalDomains ||
                policy.outboundCheckAgainstCustomExternalDomains,
            domainToCheckAgainstYourInternalDomains: policy.outboundCheckAgainstInternalDomains,
            domainToCheckAgainstMimecastMonitoredExternalDomains: policy.outboundCheckAgainstMimecastExternalDomains,
            domainToCheckAgainstCustomMonitoredExternalDomains: policy.outboundCheckAgainstCustomExternalDomains,
            users: policy.outboundInternalSender,
            administratorsSelectedGroup: {
                id: policy.outboundGroupId,
                description: policy.outboundNotifyGroupDescription
            }
        };
    }
    mapJournalRequestToState(policy) {
        return {
            checkInternalEmail: policy.checkJournal,
            urlScanningMode: policy.journalScanMode,
            urlCategoryScanningMode: policy.journalCategoryScanMode,
            senderMailboxAction: policy.journalMailboxAction,
            senderMailboxFallbackAction: policy.journalMailFallbackAction,
            blockUrlWithDangerousFileExtensions: policy.journalBlockDangerousExtension,
            urlFileDownload: policy.journalFileSandboxed
                ? journal.UrlFileDownloadOptions.CHECK
                : journal.UrlFileDownloadOptions.NO_ACTION,
            scanUrlInAttachments: policy.journalAttachmentRewriteHtml ||
                policy.journalAttachmentRewriteText ||
                policy.journalAttachmentRewriteCalendar ||
                policy.journalScanLinkAttachments,
            attachmentPartHTML: policy.journalAttachmentRewriteHtml,
            attachmentPartText: policy.journalAttachmentRewriteText,
            attachmentPartCalendar: policy.journalAttachmentRewriteCalendar,
            // Checkbox in Journal section 'Other file types' represent Journal 'Scan URLs in Attachments' in database.
            attachmentPartOther: policy.journalScanLinkAttachments,
            checkForSimilarUrls: policy.journalCheckAgainstInternalDomains ||
                policy.journalCheckAgainstMimecastExternalDomains ||
                policy.journalCheckAgainstCustomExternalDomains,
            domainToCheckAgainstYourInternalDomains: policy.journalCheckAgainstInternalDomains,
            domainToCheckAgainstMimecastMonitoredExternalDomains: policy.journalCheckAgainstMimecastExternalDomains,
            domainToCheckAgainstCustomMonitoredExternalDomains: policy.journalCheckAgainstCustomExternalDomains,
            internalSender: policy.journalInternalSender,
            internalRecipients: policy.journalInternalRecipient,
            administratorsSelectedGroup: {
                id: policy.journalGroupId,
                description: policy.journalNotifyGroupDescription
            }
        };
    }
    mapUserAwarenessRequestToState(policy) {
        return {
            showUserAwarenessChallenges: policy.userAwareness,
            challengePercentage: policy.challengePercentage,
            dynamicAdjustment: policy.dynamicAdjustment,
            customPageSet: !!policy.userAwarenessPageSetId,
            pageSetOption: policy.userAwarenessPageSetId
        };
    }
    mapAppliesFromToFields(policyTargetRequest, appliesFrom = true) {
        const storeData = {};
        storeData[appliesFrom ? 'appliesFrom' : 'appliesTo'] = policyTargetRequest.type;
        if (policyTargetRequest.attribute) {
            if (appliesFrom) {
                storeData['fromWhereAttribute'] = {
                    value: policyTargetRequest.attribute.id,
                    label: policyTargetRequest.attribute.name,
                    isSelected: true
                };
                storeData['appliesFromIsEqualTo'] = policyTargetRequest.attribute.value;
            }
            else {
                storeData['toWhereAttribute'] = {
                    value: policyTargetRequest.attribute.id,
                    label: policyTargetRequest.attribute.name,
                    isSelected: true
                };
                storeData['appliesToIsEqualTo'] = policyTargetRequest.attribute.value;
            }
        }
        if (policyTargetRequest.emailAddress) {
            if (appliesFrom) {
                storeData['appliesFromAddress'] = policyTargetRequest.emailAddress;
            }
            else {
                storeData['appliesToAddress'] = policyTargetRequest.emailAddress;
            }
        }
        if (policyTargetRequest.emailDomain) {
            if (appliesFrom) {
                storeData['appliesFromDomain'] = policyTargetRequest.emailDomain;
            }
            else {
                storeData['appliesToDomain'] = policyTargetRequest.emailDomain;
            }
        }
        if (policyTargetRequest.group) {
            if (appliesFrom) {
                storeData['appliesFromAddressGroup'] = {
                    id: policyTargetRequest.group.id,
                    description: policyTargetRequest.group.description
                };
            }
            else {
                storeData['appliesToAddressGroup'] = {
                    id: policyTargetRequest.group.id,
                    description: policyTargetRequest.group.description
                };
            }
        }
        return Object.assign({}, storeData);
    }
    mapAppliesToRequestToState(policy) {
        return policy.rules.map(rule => {
            return Object.assign(Object.assign(Object.assign({ id: rule.id, addressesBasedOn: rule.fromPart }, this.mapAppliesFromToFields(rule.from)), this.mapAppliesFromToFields(rule.to, false)), { bidirectional: rule.bidirectional, ipRanges: rule.conditions && rule.conditions.sourceIPs });
        });
    }
}
exports.PoliciesService = PoliciesService;
