"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.getMetadata = exports.getRequestPage = exports.getPagination = exports.isTableLoading = exports.getSelectedRow = exports.getAcceptedEmailRequestPayload = exports.externalTableSearchCount = exports.getAcceptedEmailList = exports.reducers = void 0;
const store_1 = require("@ngrx/store");
const acceptedEmailReducer = require("./get-accepted-email/get-accepted-email.reducer");
exports.reducers = {
    acceptedEmail: acceptedEmailReducer.reducer
};
const getAcceptedEmailState = store_1.createFeatureSelector('accepted-email');
const ɵ0 = (state) => state.acceptedEmail;
exports.ɵ0 = ɵ0;
const getGetAcceptedEmailState = store_1.createSelector(getAcceptedEmailState, ɵ0);
exports.getAcceptedEmailList = store_1.createSelector(getGetAcceptedEmailState, acceptedEmailReducer.getAcceptedEmailList);
exports.externalTableSearchCount = store_1.createSelector(getGetAcceptedEmailState, acceptedEmailReducer.externalTableSearchCount);
exports.getAcceptedEmailRequestPayload = store_1.createSelector(getGetAcceptedEmailState, acceptedEmailReducer.getAcceptedEmailRequestPayload);
exports.getSelectedRow = store_1.createSelector(getGetAcceptedEmailState, acceptedEmailReducer.getSelectedRow);
exports.isTableLoading = store_1.createSelector(getGetAcceptedEmailState, acceptedEmailReducer.isTableLoading);
exports.getPagination = store_1.createSelector(getGetAcceptedEmailState, acceptedEmailReducer.getPagination);
exports.getRequestPage = store_1.createSelector(getGetAcceptedEmailState, acceptedEmailReducer.getRequestPage);
exports.getMetadata = store_1.createSelector(getGetAcceptedEmailState, acceptedEmailReducer.getMetadata);
