"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.menuList = void 0;
// Any changes to this file should be reflected in
// https://gitlab.devuk.mimecast.lan:2443/coreui/mimecast-admin-console/-/blob/master/src/main/client/app/navbar/services/services-menu.tpl.html
// https://mimecast.jira.com/browse/WF-4246
exports.menuList = [
    {
        'label': '$I18N_NAVBAR_MENU_ITEM_ACCOUNT',
        'class': 'mc-icon-adcon-icon-mega-account_2',
        'children': [
            {
                'label': '$I18N_ADMINISTRATION_MENU_ACCOUNT_ITEM_ACCOUNT_SETTINGS',
                'caps': 'Permission.SETTINGS_READ',
                'route': '#/l/settings'
            },
            {
                'label': '$I18N_ADMINISTRATION_MENU_ACCOUNT_ITEM_AUDIT_LOGS',
                'caps': 'Permission.LOGS_READ',
                'route': '#/audit-logs'
            },
            {
                'label': '$I18N_ADMINISTRATION_MENU_ACCOUNT_ITEM_AAA_HIERARCHY',
                'caps': 'Permission.AAA_HIERARCHY_READ',
                'route': '#/l/aaahierarchy'
            },
            {
                'label': '$I18N_ADMINISTRATION_MENU_ACCOUNT_ITEM_FAA_HIERARCHY',
                'caps': 'Permission.FAA_HIERARCHY_READ',
                'route': '#/l/faahierarchy'
            },
            {
                'label': '$I18N_ADMINISTRATION_MENU_ACCOUNT_IP_CHECKER',
                'caps': 'Permission.SETTINGS_READ',
                'route': '#/account/ip-checker'
            },
            {
                'label': '$I18N_ADMINISTRATION_MENU_ACCOUNT_ITEM_ROLES',
                'caps': 'Permission.ROLES_READ && !New.Account.Roles',
                'route': '#/l/roles'
            },
            {
                'label': '$I18N_ADMINISTRATION_MENU_ACCOUNT_ITEM_ROLES',
                'caps': 'Permission.ROLES_READ && New.Account.Roles',
                'route': '#/roles-list'
            },
            {
                'label': '$I18N_ADMINISTRATION_MENU_ACCOUNT_ITEM_PACKAGE_INFO',
                'caps': 'Permission.PACKAGE_INFO_READ && !New.Package.Info',
                'route': '#/l/packageinfo'
            },
            {
                'label': '$I18N_ADMINISTRATION_MENU_ACCOUNT_ITEM_PACKAGE_INFO',
                'caps': 'Permission.PACKAGE_INFO_READ && New.Package.Info',
                'route': '#/account-package-info'
            },
            {
                'label': '$I18N_ADMINISTRATION_MENU_ACCOUNT_ITEM_SERVICE_DELIVERY',
                'caps': 'Permission.SERVICE_DELIVERY_READ',
                'route': '#/l/servicedelivery'
            },
            {
                'label': '$I18N_ADMINISTRATION_MENU_ACCOUNT_ITEM_ARCHIVE_VIEWER',
                'caps': 'Permission.ARCHIVE_VIEWER_READ && Permission.ARCHIVE_VIEWER_CUSTOMER_CONTENT_ACCESS',
                'route': '#/archive/viewer'
            }
        ],
    },
    {
        'label': '$I18N_MEGA_MENU_GROUP_ARCHIVE_LABEL',
        'class': 'mc-icon-adcon-icon-mega-archive',
        'children': [
            {
                'label': '$I18N_MEGA_MENU_ARCHIVE_ARCHIVE_SEARCH_LABEL',
                'caps': 'Permission.SEARCH_READ',
                'route': '#/l/search'
            },
            {
                'label': '$I18N_MEGA_MENU_ARCHIVE_COMPLIANCE_REVIEW_LABEL',
                'caps': 'Permission.COMPLIANCE_REVIEW_STREAM_READ',
                'route': '#/l/compliancereview'
            },
            {
                'label': '$I18N_MEGA_MENU_ARCHIVE_DISCOVERY_CASES_LABEL',
                'caps': 'Permission.CASES_READ',
                'route': '#/l/cases'
            },
            {
                'label': '$I18N_MEGA_MENU_ARCHIVE_EXPORTS_LABEL',
                'caps': 'Permission.EXPORTS_READ',
                'route': '#/l/exports'
            },
            {
                'label': '$I18N_MEGA_MENU_ARCHIVE_LITIGATION_HOLDS_LABEL',
                'caps': 'Permission.HOLDS_READ',
                'route': '#/l/holds'
            },
            {
                'label': '$I18N_MEGA_MENU_ARCHIVE_RETENTION_ADJUSTMENTS_LABEL',
                'caps': 'Permission.RETENTION_READ',
                'route': '#/l/retention'
            },
            {
                'label': '$I18N_MEGA_MENU_ARCHIVE_SAVED_SEARCHES_LABEL',
                'caps': 'Permission.SAVED_SEARCHES_READ',
                'route': '#/l/savedsearches'
            },
            {
                'label': '$I18N_MEGA_MENU_ARCHIVE_SEARCH_LOGS_LABEL',
                'caps': 'Permission.SEARCH_LOGS_READ',
                'route': '#/l/searchlogs'
            },
            {
                'label': '$I18N_MEGA_MENU_ARCHIVE_SMART_TAGS_LABEL',
                'caps': 'Permission.SMART_TAGS_READ',
                'route': '#/l/smarttags'
            },
            {
                'label': '$I18N_MEGA_MENU_ARCHIVE_STORAGE_SEGMENTS_LABEL',
                'caps': 'Permission.STORAGE_SEGMENTS_READ && !Temporary.Remove.Storage.Segment',
                'route': '#/l/storagesegments'
            },
            {
                'label': '$I18N_MEGA_MENU_ARCHIVE_SUPERVISION_LABEL',
                'caps': 'Permission.SUPERVISION_READ',
                'route': '#/archive/supervision'
            },
            {
                'label': '$I18N_MEGA_MENU_ARCHIVE_VIEW_LOGS_LABEL',
                'caps': 'Permission.VIEW_LOGS_READ',
                'route': '#/l/viewlogs'
            }
        ],
    },
    {
        'label': '$I18N_MEGA_MENU_GROUP_DIRECTORIES_LABEL',
        'class': 'mc-icon-adcon-icon-mega-directories',
        'children': [
            {
                'label': '$I18N_MEGA_MENU_DIRECTORIES_ATTRIBUTES_LABEL',
                'caps': 'Permission.ATTRIBUTES_READ && !Temporary.NewAttributesEnabled',
                'route': '#/l/attributes'
            },
            {
                'label': '$I18N_MEGA_MENU_DIRECTORIES_ATTRIBUTES_LABEL',
                'caps': 'Permission.ATTRIBUTES_READ && Temporary.NewAttributesEnabled',
                'route': '#/directories/attributes'
            },
            {
                'label': '$I18N_MEGA_MENU_DIRECTORIES_DIRECTORY_GROUPS_LABEL',
                'caps': 'Permission.AD_GROUPS_READ',
                'route': '#/l/adgroups'
            },
            {
                'label': '$I18N_MEGA_MENU_DIRECTORIES_EXTERNAL_DIRECTORIES_LABEL',
                'caps': 'Permission.EXTERNAL_READ',
                'route': '#/l/external'
            },
            {
                'label': '$I18N_MEGA_MENU_DIRECTORIES_IMPORTS_LABEL',
                'caps': 'Permission.IMPORT_READ',
                'route': '#/l/import'
            },
            {
                'label': '$I18N_MEGA_MENU_DIRECTORIES_INTERNAL_DIRECTORIES_LABEL',
                'caps': 'Permission.INTERNAL_READ',
                'route': '#/l/internal'
            },
            {
                'label': '$I18N_MEGA_MENU_DIRECTORIES_PROFILE_GROUPS_LABEL',
                'caps': 'Permission.GROUPS_READ',
                'route': '#/l/groups'
            },
            {
                'label': '$I18N_MEGA_MENU_DIRECTORIES_EAM_IMPORT_LABEL',
                'caps': 'Permission.EAM_IMPORT_READ',
                'route': '#/l/aaaimport'
            },
            {
                'label': '$I18N_MEGA_MENU_DIRECTORIES_EAM_INTERNAL_DIRECTORIES_LABEL',
                'caps': 'Permission.EAM_INTERNAL_READ',
                'route': '#/l/aaainternal'
            }
        ]
    },
    {
        'label': '$I18N_MEGA_MENU_GROUP_GATEWAY_LABEL',
        'class': 'mc-icon-adcon-icon-mega-gateway',
        'children': [
            {
                'label': '$I18N_MEGA_MENU_GATEWAY_ACCEPTED_EMAIL_LABEL',
                'caps': 'Permission.ACCEPTED_EMAIL_READ && Temporary.OldAcceptedEmailEnabled',
                'route': '#/l/acceptedemail'
            },
            {
                'label': '$I18N_MEGA_MENU_GATEWAY_AUTHORIZED_OUTBOUNDS_LABEL',
                'caps': 'Permission.OUTBOUND_READ',
                'route': '#/l/outbound'
            },
            {
                'label': '$I18N_MEGA_MENU_GATEWAY_MANAGED_SENDERS_LABEL',
                'caps': 'Permission.MANAGED_SENDERS_READ',
                'route': '#/l/managesenders'
            },
            {
                'label': '$I18N_MEGA_MENU_GATEWAY_POLICIES_LABEL',
                'caps': 'Permission.POLICIES_READ',
                'route': '#/l/policies'
            },
            {
                'label': '$I18N_MEGA_MENU_GATEWAY_TRACKING_LABEL',
                'caps': 'Temporary.OldTrackingEnabled && Permission.TRACKING_READ',
                'route': '#/l/trackandtrace'
            },
            {
                'label': '$I18N_MEGA_MENU_GATEWAY_AUTHORIZED_OUTBOUNDS_LABEL',
                'caps': 'Permission.EAM_OUTBOUND_READ',
                'route': '#/l/aaaoutbound'
            },
            {
                'label': '$I18N_MEGA_MENU_CUSTOM_DOMAIN_BLOCK_LABEL',
                'caps': 'Temporary.Custom.Domain.Block && Custom.Domain.Block && (Permission.CUSTOM_DOMAIN_BLOCK_UPLOAD || Permission.CUSTOM_DOMAIN_BLOCK_DELETE)',
                'route': '#/gateway/custom-domain-block'
            }
        ]
    },
    {
        'label': '$I18N_MEGA_MENU_GROUP_MESSAGE_CENTER_LABEL',
        'class': 'mc-icon-adcon-mega-messagecenter',
        'children': [
            {
                'label': '$I18N_MEGA_MENU_GROUP_TRACKING_LABEL',
                'caps': 'Permission.TRACKING_READ && !(AccountType.EnterpriseMaster || AccountType.EnterpriseGroup || AccountType.PartnerMaster || AccountType.PartnerGroup)',
                'route': '#/message-center/message-tracking'
            },
            {
                'label': '$I18N_MEGA_MENU_GROUP_MESSAGE_CENTER_ACCEPTED_EMAIL_LABEL',
                'caps': 'Temporary.Services.MessageCenter.AcceptedEmail && Permission.ACCEPTED_EMAIL_READ',
                'route': '#/message-center/accepted-email'
            },
            {
                'label': '$I18N_MEGA_MENU_GROUP_MESSAGE_CENTER_HELD_MESSAGES.LABEL',
                'caps': 'Temporary.Services.MessageCenter.HeldMessages && Permission.HELD_READ || Permission.HELD_SUMMARY_READ',
                'route': '#/message-center/held-messages'
            },
            {
                'label': '$I18N_MEGA_MENU_GROUP_MESSAGE_CENTER_REJECTED_AND_DEFERRED.LABEL',
                'caps': 'Temporary.NewRejectionsEnabled && ((Permission.MONITORING_REJECTIONS_READ && !AccountType.EnterpriseGroup) || Permission.MONITORING_CONNECTIONS_READ)',
                'route': '#/message-center/rejected-and-deferred'
            },
            {
                'label': '$I18N_MEGA_MENU_GROUP_MESSAGE_CENTER_BOUNCED_LABEL',
                'caps': 'Temporary.NewBouncedEnabled && Permission.BOUNCES_READ',
                'route': '#/message-center/bounces'
            },
            {
                'label': '$I18N_MEGA_MENU_DELIVERY_MESSAGE_HELD_MESSAGES.LABEL',
                'caps': 'Temporary.Services.MessageCenter.MessageDelivery\n                && Permission.DELIVERY_READ\n                && !AccountType.EnterpriseMaster\n                && !AccountType.PartnerMaster\n                && !AccountType.EnterpriseGroup\n                && !AccountType.PartnerGroup',
                'route': '#/message-center/message-delivery'
            },
            {
                'label': '$I18N_MESSAGE_CENTER_MESSAGE_PROCESSING_MESSAGES.MEGA_MENU.LABEL',
                'caps': 'Temporary.Services.MessageCenter.Processing\n                && Permission.PROCESSING_READ\n                && !AccountType.EnterpriseMaster\n                && !AccountType.PartnerMaster\n                && !AccountType.EnterpriseGroup\n                && !AccountType.PartnerGroup',
                'route': '#/message-center/message-processing'
            }
        ],
    },
    {
        'label': '$I18N_MEGA_MENU_GROUP_MONITORING_LABEL',
        'class': 'mc-icon-adcon-icon-mega-monitoring',
        'children': [
            {
                'label': '$I18N_MEGA_MENU_MONITORING_ATTACHMENTS',
                'caps': 'Permission.ATTACHMENT_READ',
                'route': '#/l/attachments'
            },
            {
                'label': '$I18N_MEGA_MENU_MONITORING_ATTACHMENT_PROTECTION',
                'caps': 'Permission.MONITORING_TTP_ATTACHMENT_PROTECT_READ',
                'route': '#/l/targetedthreatprotection-attachmentprotect-monitoring'
            },
            {
                'label': '$I18N_MEGA_MENU_MONITORING_BOUNCES',
                'caps': 'Temporary.OldBouncesEnabled && Permission.BOUNCES_READ',
                'route': '#/l/bounces'
            },
            {
                'label': '$I18N_MEGA_MENU_MONITORING_BROADCASTS',
                'caps': 'Permission.BROADCAST_READ',
                'route': '#/l/queuebroadcast'
            },
            {
                'label': '$I18N_MEGA_MENU_MONITORING_BULK_DELIVERY',
                'caps': 'Permission.BULK_DELIVERY_READ',
                'route': '#/l/bulkdelivery'
            },
            {
                'label': '$I18N_MEGA_MENU_MONITORING_BULK_PROCESSING',
                'caps': 'Permission.BULK_PROCESSING_READ',
                'route': '#/l/bulkprocessing'
            },
            {
                'label': '$I18N_MEGA_MENU_MONITORING_CONNECTIONS',
                'caps': 'Temporary.OldConnectionsEnabled && Permission.MONITORING_CONNECTIONS_READ',
                'route': '#/l/connections'
            },
            {
                'label': '$I18N_MEGA_MENU_MONITORING_DATA_LEAK_PREVENTION',
                'caps': 'Permission.DLP_LOGS_READ && Temporary.Monitoring.DataLeakPrevention',
                'route': '#/services/monitoring/data-leak-prevention'
            },
            {
                'label': '$I18N_MEGA_MENU_MONITORING_DELIVERY',
                'caps': 'Permission.DELIVERY_READ',
                'route': '#/l/delivery'
            },
            {
                'label': '$I18N_MEGA_MENU_MONITORING_HELD',
                'caps': '!Temporary.Held.Redirect && Permission.HELD_READ',
                'route': '#/l/held'
            },
            {
                'label': '$I18N_MEGA_MENU_MONITORING_HELD_SUMMARY',
                'caps': '!Temporary.HeldSummary.Redirect && Permission.HELD_SUMMARY_READ',
                'route': '#/l/heldsummary'
            },
            {
                'label': '$I18N_MEGA_MENU_MONITORING_IMPERSONATION_PROTECTION',
                'caps': 'Permission.IMPERSONATION_PROTECTION_LOGS_READ && Temporary.Monitoring.ImpersonationTtp && !Temporary.TTP.IP.Logs.Chameleon',
                'route': '#/impersonation/logs'
            },
            {
                'label': '$I18N_MEGA_MENU_MONITORING_IMPERSONATION_PROTECTION',
                'caps': 'Permission.IMPERSONATION_PROTECTION_LOGS_READ && Temporary.Monitoring.ImpersonationTtp && Temporary.TTP.IP.Logs.Chameleon',
                'route': '#/ttp/impersonation/logs'
            },
            {
                'label': '$I18N_MEGA_MENU_MONITORING_PROCESSING',
                'caps': 'Permission.PROCESSING_READ',
                'route': '#/l/processing'
            },
            {
                'label': '$I18N_MEGA_MENU_MONITORING_REJECTIONS',
                'caps': 'Temporary.OldRejectionsEnabled && Permission.MONITORING_REJECTIONS_READ && !AccountType.EnterpriseGroup',
                'route': '#/l/rejections'
            },
            {
                'label': '$I18N_MEGA_MENU_MONITORING_SECURE_MESSAGING',
                'caps': 'Permission.SECURE_MESSAGING_LOGS_READ && Temporary.Monitoring.SecureMessaging && !Temporary.HideOldSecureMessaging',
                'route': '#/services/monitoring/secure-messaging-list'
            },
            {
                'label': '$I18N_MEGA_MENU_MONITORING_SYSTEM',
                'caps': '!Temporary.SystemMessages.Redirect && Permission.SYSTEM_READ',
                'route': '#/l/system'
            },
            {
                'label': '$I18N_MEGA_MENU_MONITORING_URL_PROTECTION',
                'caps': 'Permission.MONITORING_TTP_URL_PROTECT_READ',
                'route': '#/l/targetedthreatprotection-urlprotect-monitoring'
            }
        ],
    },
    {
        'label': '$I18N_MEGA_MENU_GROUP_REPORTING_LABEL',
        'class': '',
        'children': [
            {
                'label': '$I18N_MEGA_MENU_REPORTING_ACCOUNT_ASSESSMENT',
                'caps': 'Permission.PDF_REPORTS_READ && Temporary.Reports.AccountAssessment',
                'route': '#/l/accountassessment'
            },
            {
                'label': '$I18N_MEGA_MENU_REPORTING_CSV_DATA',
                'caps': 'Permission.CSV_DATA_READ',
                'route': '#/l/csvdata'
            },
            {
                'label': '$I18N_MEGA_MENU_REPORTING_DETAILED_ACTIVITY',
                'caps': 'Permission.CUSTOM_READ',
                'route': '#/l/detailedactivity'
            },
            {
                'label': '$I18N_MEGA_MENU_REPORTING_OVERVIEW',
                'caps': 'Permission.BETA_REPORTS_READ',
                'route': '#/l/overview'
            },
            {
                'label': '$I18N_MEGA_MENU_REPORTING_PDF_REPORTS',
                'caps': 'Permission.PDF_REPORTS_READ',
                'route': '#/l/pdfreports'
            }
        ],
    },
    {
        'label': '$I18N_MEGA_MENU_GROUP_SERVICES_LABEL',
        'class': 'mc-icon-adcon-icon-mega-services',
        'children': [
            {
                'label': '$I18N_MEGA_MENU_SERVICES_APPLICATIONS_LABEL',
                'caps': 'Permission.OUTLOOK_READ && !(AccountType.EnterpriseGroup || AccountType.PartnerGroup)',
                'route': '#/l/applications'
            },
            {
                'label': '$I18N_MEGA_MENU_SERVICES_API_APPLICATIONS_LABEL',
                'caps': 'Permission.API_APPLICATIONS_READ',
                'route': '#/api-applications/list'
            },
            {
                'label': '$I18N_MEGA_MENU_SERVICES_APPLICATION_SETTINGS_LABEL',
                'caps': 'Permission.OUTLOOK_READ && Temporary.Services.ApplicationSettings && !(AccountType.EnterpriseGroup || AccountType.PartnerGroup)',
                'route': '#/services/applicationsettings'
            },
            {
                'label': '$I18N_MEGA_MENU_SERVICES_ATTACHMENT_PROTECTION_LABEL',
                'caps': 'Permission.SERVICES_TTP_ATTACHMENT_PROTECT_READ && Temporary.Services.TTP.AttachmentProtection',
                'route': '#/services/services/attachment-protection'
            },
            {
                'label': '$I18N_MEGA_MENU_SERVICES_ATTACHMENT_PROTECTION_LABEL',
                'caps': 'Permission.SERVICES_TTP_ATTACHMENT_PROTECT_READ && !Temporary.Services.TTP.AttachmentProtection',
                'route': '#/l/targetedthreatprotection-attachmentprotect'
            },
            {
                'label': '$I18N_MEGA_MENU_SERVICES_BROWSER_ISOLATION',
                'caps': 'Temporary.BrowserIsolation && Permission.BI_CONFIGURE_POLICIES_READ && (Security.Web.BrowserIsolation || Security.Ttp.BrowserIsolation)',
                'route': '#/bi/policy-list'
            },
            {
                'label': '$I18N_MEGA_MENU_SERVICES_CONNECTORS_LABEL',
                'caps': 'Temporary.Services.Connectors.Enabled && (Permission.CONNECTORS_READ || Permission.SERVER_CONNECTIONS_READ)',
                'route': '#/services/connectors'
            },
            {
                'label': '$I18N_MEGA_MENU_SERVICES_CONTINUITY_LABEL',
                'caps': 'Permission.CONTINUITY_READ',
                'route': '#/l/continuity'
            },
            {
                'label': '$I18N_MEGA_MENU_SERVICES_DIRECTORY_SYNCHRONIZATION_LABEL',
                'caps': 'Permission.DIRECTORY_SYNC_READ && !Temporary.Services.Directory.Synchronization',
                'route': '#/l/directorysync'
            },
            {
                'label': '$I18N_MEGA_MENU_SERVICES_DIRECTORY_SYNCHRONIZATION_LABEL',
                'caps': '(Permission.DIRECTORY_SYNC_READ || Permission.EAM_DIRECTORY_SYNC_READ) && Temporary.Services.Directory.Synchronization',
                'route': '#/services/directory-sync'
            },
            {
                'label': '$I18N_MEGA_MENU_SERVICES_DIRECTORY_SYNCHRONIZATION_LABEL',
                'caps': 'Permission.EAM_DIRECTORY_SYNC_READ && !Temporary.Services.Directory.Synchronization',
                'route': '#/l/aaadirectorysync'
            },
            {
                'label': '$I18N_MEGA_MENU_SERVICES_EXCHANGE_SERVICES_LABEL',
                'caps': 'Permission.EXCHANGE_SERVICES_READ',
                'route': '#/l/exchangeservices'
            },
            {
                'label': '$I18N_MEGA_MENU_SERVICES_DATA_SYNC_AND_RECOVERY_LABEL',
                'caps': '(Sync.Mailbox.ArchiveAndRecover && Permission.DATA_SYNC_AND_RECOVER_READ) || (Sync.Mailbox.Archive && Permission.DATA_SYNC_READ)',
                'route': '#/sync-recover/exchange/tasks'
            },
            {
                'label': '$I18N_MEGA_MENU_SERVICES_FILE_ARCHIVE_LABEL',
                'caps': 'Permission.FILE_ARCHIVING_READ',
                'route': '#/l/filearchive'
            },
            {
                'label': '$I18N_MEGA_MENU_SERVICES_IM_ARCHIVE_LABEL',
                'caps': 'Permission.IM_ARCHIVE_READ',
                'route': '#/l/imarchive'
            },
            {
                'label': '$I18N_MEGA_MENU_SERVICES_IM_SYNC_LABEL',
                'caps': 'Permission.INSTANT_MESSAGING_READ',
                'route': '#/services/im-sync'
            },
            {
                'label': '$I18N_MEGA_MENU_SERVICES_JOURNALING_LABEL',
                'caps': 'Permission.JOURNALING_READ',
                'route': '#/l/journaling'
            },
            {
                'label': '$I18N_MEGA_MENU_SERVICES_JOURNALING_LABEL',
                'caps': 'Permission.EAM_JOURNALING_READ',
                'route': '#/l/aaajournaling'
            },
            {
                'label': '$I18N_MEGA_MENU_SERVICES_LARGE_FILE_SEND_LABEL',
                'caps': 'Permission.LARGE_FILE_SEND_READ',
                'route': '#/l/largefilesend'
            },
            {
                'label': '$I18N_MEGA_MENU_SERVICES_MISADDRESSED_EMAIL_PROTECT_LABEL',
                'caps': 'Temporary.Services.MEP',
                'route': '#/mep/policy-list'
            },
            {
                'label': '$I18N_MEGA_MENU_MONITORING_SECURE_MESSAGING',
                'caps': 'Permission.SERVICE_SECURE_MESSAGING_READ && Temporary.Service.Secure.Messaging',
                'route': '#/services/secure-messaging'
            },
            {
                'label': '$I18N_MEGA_MENU_SERVICES_SERVER_CONNECTIONS_LABEL',
                'caps': 'Services.ServerConnections && Permission.SERVER_CONNECTIONS_READ',
                'route': '#/services/services/server-connection'
            },
            {
                'label': '$I18N_MEGA_MENU_SERVICES_SMS_DASHBOARD_SUB_LABEL',
                'caps': 'Permission.SMS_DASHBOARD_READ',
                'route': '#/l/smsdashboard'
            },
            {
                'label': '$I18N_MEGA_MENU_SERVICES_SYNCHRONIZATION_ENGINE_SITES_LABEL',
                'caps': 'Permission.EXCHANGE_SITES_READ',
                'route': '#/l/synchronizationenginesites'
            },
            {
                'label': '$I18N_MEGA_MENU_SERVICES_URL_PROTECTION_LABEL',
                'caps': 'Permission.SERVICES_TTP_URL_PROTECT_READ && !Temporary.Services.TTP.URLProtection',
                'route': '#/l/targetedthreatprotection-urlprotect'
            },
            {
                'label': '$I18N_MEGA_MENU_SERVICES_URL_PROTECTION_LABEL',
                'caps': 'Permission.SERVICES_TTP_URL_PROTECT_READ && Temporary.Services.TTP.URLProtection && Temporary.Services.UrlProtection.New',
                'route': '#/url-protection-new'
            },
            {
                'label': '$I18N_MEGA_MENU_SERVICES_URL_PROTECTION_LABEL',
                'caps': 'Permission.SERVICES_TTP_URL_PROTECT_READ && Temporary.Services.TTP.URLProtection',
                'route': '#/services/services/url-protection'
            },
            {
                'label': '$I18N_MEGA_MENU_SERVICES_REMEDIATION_LABEL',
                'caps': 'Permission.THREAT_REMEDIATION_READ',
                'route': '#/remediation'
            },
            {
                'label': '$I18N_MEGA_MENU_SERVICES_THREAT_INTEL_LABEL',
                'caps': 'Permission.THREAT_INTELLIGENCE_READ && Threat.Intel.Dashboard && EarlyAccess.Threat.Intel.Dashboard',
                'route': '#/threat-intel'
            }
        ],
    },
    {
        'label': '$I18N_MEGA_MENU_GROUP_STATIONERY_LABEL',
        'class': '',
        'children': [
            {
                'label': '$I18N_MEGA_MENU_STATIONERY_ACTIONS_LABEL',
                'caps': 'Permission.ACTIONS_READ',
                'route': '#/l/actions'
            },
            {
                'label': '$I18N_MEGA_MENU_STATIONERY_BRANDING_LABEL',
                'caps': 'Permission.BRANDING_READ',
                'route': '#/l/branding'
            },
            {
                'label': '$I18N_MEGA_MENU_STATIONERY_BROADCASTS_LABEL',
                'caps': 'Permission.BROADCASTS_READ',
                'route': '#/l/broadcasts'
            },
            {
                'label': '$I18N_MEGA_MENU_STATIONERY_IMAGES_LABEL',
                'caps': 'Permission.IMAGES_READ',
                'route': '#/l/images'
            },
            {
                'label': '$I18N_MEGA_MENU_STATIONERY_LAYOUTS_LABEL',
                'caps': 'Permission.LAYOUTS_READ',
                'route': '#/l/layouts'
            },
            {
                'label': '$I18N_MEGA_MENU_STATIONERY_MICRO_SITES_LABEL',
                'caps': 'Permission.MICRO_SITES_READ',
                'route': '#/l/microsites'
            },
            {
                'label': '$I18N_MEGA_MENU_STATIONERY_PERFORMANCE_LABEL',
                'caps': 'Permission.PERFORMANCE_READ',
                'route': '#/l/performance'
            },
            {
                'label': '$I18N_MEGA_MENU_STATIONERY_REPORTS_LABEL',
                'caps': 'Permission.REPORTS_READ',
                'route': '#/l/reports'
            },
            {
                'label': '$I18N_MEGA_MENU_STATIONERY_TEMPLATES_LABEL',
                'caps': 'Permission.TEMPLATES_READ',
                'route': '#/l/templates'
            }
        ],
    },
    {
        'label': '$I18N_MEGA_MENU_GROUP_SECURE_WEB_GATEWAY_LABEL',
        'class': 'mc-icon-adcon-icon-mega-web-gateway',
        'children': [
            {
                'label': '$I18N_MEGA_MENU_GROUP_SECURE_WEB_GATEWAY_TRY_WEBSECURITY',
                'caps': 'Temporary.Swg.Trial && Web.Security.Lead && (Policy.AutoAllow || Archive.Search) && !Internet.Security.DNS && !Internet.Security.DNS.Lite',
                'route': '#/trail-for-new-swg-customers'
            }
        ],
    },
    {
        'label': '$I18N_MEGA_MENU_GROUP_SECURE_WEB_GATEWAY_LABEL',
        'class': 'mc-icon-adcon-icon-mega-web-gateway',
        'children': [
            {
                'label': '$I18N_MEGA_MENU_GROUP_SECURE_WEB_GATEWAY_ACTIVITY_REPORT',
                'caps': 'Permission.SWG_ACTIVITY_REPORT_READ',
                'route': '#/secure-web-gateway/activity-report'
            },
            {
                'label': '$I18N_MEGA_MENU_GROUP_SECURE_WEB_GATEWAY_ENDPOINT_SETTINGS',
                'caps': 'Permission.SWG_ENDPOINT_SETTINGS_READ',
                'route': '#/secure-web-gateway/endpoints'
            },
            {
                'label': '$I18N_MEGA_MENU_GROUP_SECURE_WEB_GATEWAY_APPLICATIONS',
                'caps': 'Temporary.Permission.swg.reports.application.visibility && Permission.SWG_CONFIGURE_POLICIES_READ',
                'route': '#/secure-web-gateway-applications'
            },
            {
                'label': '$I18N_MEGA_MENU_GROUP_SECURE_WEB_GATEWAY_DNS_SETUP',
                'caps': 'Permission.SWG_CERTIFICATE_DNS_SETUP_READ',
                'route': '#/secure-web-gateway-dns'
            },
            {
                'label': '$I18N_MEGA_MENU_GROUP_SECURE_WEB_GATEWAY_DASHBOARD',
                'caps': 'Permission.SWG_DASHBOARD_READ',
                'route': '#/secure-web-gateway-dashboard'
            },
            {
                'label': '$I18N_MEGA_MENU_GROUP_SECURE_WEB_GATEWAY_URL_CHECKER',
                'caps': 'Permission.SWG_URL_CLASSIFCATION_REPORT_READ',
                'route': '#/secure-web-gateway/url-check-report'
            },
            {
                'label': '$I18N_MEGA_MENU_GROUP_SECURE_WEB_GATEWAY_BYPASS_HOSTS',
                'caps': 'Permission.SWG_BYPASS_HOSTS_READ',
                'route': '#/secure-web-gateway/exceptions-list'
            },
            {
                'label': '$I18N_MEGA_MENU_GROUP_SECURE_WEB_GATEWAY_CONFIGURE_SITE',
                'caps': 'Permission.SWG_CONFIGURE_SITES_READ',
                'route': '#/secure-web-gateway/locations-list'
            },
            {
                'label': '$I18N_MEGA_MENU_GROUP_SECURE_WEB_GATEWAY_CONFIGURE_POLICIES',
                'caps': 'Permission.SWG_CONFIGURE_POLICIES_READ',
                'route': '#/secure-web-gateway/policy-list'
            },
            {
                'label': '$I18N_MEGA_MENU_GROUP_SECURE_WEB_GATEWAY_ENDPOINTS_LIST',
                'caps': 'Permission.SWG_ENDPOINT_LIST_READ',
                'route': '#/secure-web-gateway/endpoints-list'
            },
            {
                'label': '$I18N_MEGA_MENU_GROUP_SECURE_WEB_GATEWAY_MALWARE_REPORT',
                'caps': 'Permission.SWG_SECURITY_REPORT_LIST_READ',
                'route': '#/secure-web-gateway/malware-report'
            },
            {
                'label': '$I18N_MEGA_MENU_GROUP_SECURE_WEB_GATEWAY_SETUP_GUIDE',
                'caps': 'Permission.SWG_DASHBOARD_READ && Temporary.Swg.SetUp',
                'route': '#/dummy-setup'
            }
        ],
    },
    {
        'label': '$I18N_MEGA_MENU_GROUP_IDENTITY_LABEL',
        'class': 'mc-icon-adcon-icon-mega-identity',
        'children': [
            {
                'label': '$I18N_MEGA_MENU_GROUP_IDENTITY_DASHBOARD_LABEL',
                'caps': 'Permission.ID_MANAGEMENT_READ ',
                'route': '#/identity-dashboard'
            }
        ],
    }
];
