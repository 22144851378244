"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReleaseLogDetailComponent = void 0;
const core_1 = require("@angular/core");
class ReleaseLogDetailComponent {
    constructor(capabilitiesService) {
        this.capabilitiesService = capabilitiesService;
        this.onClosed = new core_1.EventEmitter();
        this.onPagination = new core_1.EventEmitter();
        this.start = 0;
        this.separator = ' - ';
    }
    ngOnInit() {
        this.start = this.currentItemIndex;
        this.capabilitiesService
            .hasCapability('Temporary.MessageCenter.SpamScore')
            .subscribe(isActive => {
            if (isActive === true) {
                this.showSpamDetails = true;
            }
            else {
                this.showSpamDetails = false;
            }
        });
    }
    closeClicked() {
        this.onClosed.emit();
    }
    onPaginate(paginatorData) {
        // Move the index forward or backward based on directions
        this.currentItemIndex =
            paginatorData.direction === 1 ? (this.currentItemIndex += 1) : (this.currentItemIndex -= 1);
        this.start = this.currentItemIndex;
        // Emit event to signal aside component that pagination is required
        this.onPagination.emit(this.currentItemIndex);
    }
}
exports.ReleaseLogDetailComponent = ReleaseLogDetailComponent;
