"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InitializeSettingsAction = exports.GetInformationAction = exports.INITIALIZE_SETTINGS = exports.GET_INFORMATION = void 0;
exports.GET_INFORMATION = '[GOOGLEANALYTICS] Get information';
exports.INITIALIZE_SETTINGS = '[GOOGLEANALYTICS] Initialize setting';
class GetInformationAction {
    constructor() {
        this.type = exports.GET_INFORMATION;
    }
}
exports.GetInformationAction = GetInformationAction;
class InitializeSettingsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.INITIALIZE_SETTINGS;
    }
}
exports.InitializeSettingsAction = InitializeSettingsAction;
