"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupervisionGetGroupResetStore = exports.SupervisionGetGroupFail = exports.SupervisionGetGroupNotSet = exports.SupervisionGetGroupSuccess = exports.SupervisionGetGroup = exports.GET_GROUP_RESET_STORE = exports.GET_GROUP_NOT_SET = exports.GET_GROUP_FAIL = exports.GET_GROUP_SUCCESS = exports.GET_GROUP = void 0;
exports.GET_GROUP = '[Supervision] Retrieve group';
exports.GET_GROUP_SUCCESS = '[Supervision] Retrieve group success';
exports.GET_GROUP_FAIL = '[Supervision] Retrieve group fail';
exports.GET_GROUP_NOT_SET = '[Supervision] Retrieve group not set';
exports.GET_GROUP_RESET_STORE = '[Supervision] Retrieve group reset store';
class SupervisionGetGroup {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_GROUP;
    }
}
exports.SupervisionGetGroup = SupervisionGetGroup;
class SupervisionGetGroupSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_GROUP_SUCCESS;
    }
}
exports.SupervisionGetGroupSuccess = SupervisionGetGroupSuccess;
class SupervisionGetGroupNotSet {
    constructor() {
        this.type = exports.GET_GROUP_NOT_SET;
    }
}
exports.SupervisionGetGroupNotSet = SupervisionGetGroupNotSet;
class SupervisionGetGroupFail {
    constructor() {
        this.type = exports.GET_GROUP_FAIL;
    }
}
exports.SupervisionGetGroupFail = SupervisionGetGroupFail;
class SupervisionGetGroupResetStore {
    constructor() {
        this.type = exports.GET_GROUP_RESET_STORE;
    }
}
exports.SupervisionGetGroupResetStore = SupervisionGetGroupResetStore;
