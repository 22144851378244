"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isLoadingLexiconDetails = exports.getLexiconDetails = exports.getLexiconDetailsPagination = exports.reducer = exports.initialState = void 0;
const lexiconListActions = require("../actions/lexicon.list.actions");
exports.initialState = {
    lexiconDetails: null,
    isDeletingLexicon: false,
    isLoadingLexiconDetails: false,
    detailsPaginationInfo: undefined
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case lexiconListActions.LexiconListActions.SET_LEXICON_DETAILS_PAGINATOR_DATA:
            return Object.assign(Object.assign({}, state), { detailsPaginationInfo: {
                    previousEntity: action.previousLexicon,
                    currentEntity: action.currentLexicon,
                    nextEntity: action.nextLexicon,
                    currentEntityIndex: action.currentLexiconIndex,
                    totalNumberEntities: action.totalNumberLexicons
                } });
        case lexiconListActions.LexiconListActions.DELETE_LEXICON:
            return Object.assign(Object.assign({}, state), { isDeletingLexicon: true });
        case lexiconListActions.LexiconListActions.DELETE_LEXICON_SUCCESS:
            return Object.assign(Object.assign({}, state), { isDeletingLexicon: false });
        case lexiconListActions.LexiconListActions.DELETE_LEXICON_FAIL:
            return Object.assign(Object.assign({}, state), { isDeletingLexicon: false });
        case lexiconListActions.LexiconListActions.LOAD_LEXICON_DETAILS:
            return Object.assign(Object.assign({}, state), { isLoadingLexiconDetails: true, lexiconDetails: null });
        case lexiconListActions.LexiconListActions.LOAD_LEXICON_DETAILS_SUCCESS:
            return Object.assign(Object.assign({}, state), { isLoadingLexiconDetails: false, lexiconDetails: action.payload });
        case lexiconListActions.LexiconListActions.LOAD_LEXICON_DETAILS_FAILURE:
            return Object.assign(Object.assign({}, state), { isLoadingLexiconDetails: false, lexiconDetails: null });
        default:
            return Object.assign({}, state);
    }
}
exports.reducer = reducer;
const getLexiconDetailsPagination = (state) => state && state.detailsPaginationInfo;
exports.getLexiconDetailsPagination = getLexiconDetailsPagination;
const getLexiconDetails = (state) => state && state.lexiconDetails;
exports.getLexiconDetails = getLexiconDetails;
const isLoadingLexiconDetails = (state) => state && state.isLoadingLexiconDetails;
exports.isLoadingLexiconDetails = isLoadingLexiconDetails;
