"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchOktaGroupsComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
class SearchOktaGroupsComponent {
    constructor(fb) {
        this.fb = fb;
        this.onSearch = new core_1.EventEmitter();
        this.onSelect = new core_1.EventEmitter();
        this.onChange = () => { };
        this.onTouch = () => { };
    }
    ngOnInit() {
        this.form = this.fb.group({
            searchInput: ['', [forms_1.Validators.required, forms_1.Validators.minLength(3)]]
        });
    }
    writeValue(obj) {
        this.value = obj;
        this.onChange(obj);
    }
    registerOnChange(fn) {
        this.onChange = fn;
    }
    registerOnTouched(fn) {
        this.onTouch = fn;
    }
    selectItem(item) {
        var _a, _b;
        this.isPopoverVisible = false;
        (_b = (_a = this.form) === null || _a === void 0 ? void 0 : _a.controls['searchInput']) === null || _b === void 0 ? void 0 : _b.setValue('', { emitEvent: false });
        this.writeValue(item);
    }
    search() {
        this.isPopoverVisible = true;
        this.onSearch.emit(this.form.controls['searchInput'].value.trim());
    }
    removeSelected() {
        this.writeValue(null);
    }
}
exports.SearchOktaGroupsComponent = SearchOktaGroupsComponent;
