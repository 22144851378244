"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReviewerBuilder = void 0;
const queue_reviewer_1 = require("./queue-reviewer");
class ReviewerBuilder {
    withEmailAddress(emailAddress) {
        this.emailAddress = emailAddress;
        return this;
    }
    withAddressType(addressType) {
        this.addressType = addressType;
        return this;
    }
    withDomain(domain) {
        this.domain = domain;
        return this;
    }
    withName(name) {
        this.name = name;
        return this;
    }
    withAlias(alias) {
        this.alias = alias;
        return this;
    }
    withId(id) {
        this.id = id;
        return this;
    }
    withSource(source) {
        this.source = source;
        return this;
    }
    build() {
        return new queue_reviewer_1.Reviewer(this.emailAddress, this.addressType, this.domain, this.name, this.alias, this.id, this.source);
    }
}
exports.ReviewerBuilder = ReviewerBuilder;
