<div
  class="panel panel-default panel-padding-top panel-padding-bottom panel-padding-left panel-padding-right">
  <form [formGroup]="form" (ngSubmit)="onSubmit()" class="mc-flex-form">
    <div class="mc-field-group">
      <mc-text-field formControlName="from"
                     label="$I18N_MESSAGE_CENTER_PURGE_MESSAGES_FORM.FROM_LABEL"
                     placeholder="$I18N_MESSAGE_CENTER_PURGE_MESSAGES_FORM.FROM_TO_PLACEHOLDER"
                     errorPrefix="$I18N_MESSAGE_CENTER_PURGE_MESSAGES_FORM.ERRORS">
      </mc-text-field>
    </div>

    <div class="mc-field-group">
      <mc-text-field formControlName="to"
                     label="$I18N_MESSAGE_CENTER_PURGE_MESSAGES_FORM.TO_LABEL"
                     placeholder="$I18N_MESSAGE_CENTER_PURGE_MESSAGES_FORM.FROM_TO_PLACEHOLDER"
                     errorPrefix="$I18N_MESSAGE_CENTER_PURGE_MESSAGES_FORM.ERRORS">
      </mc-text-field>
    </div>


    <div class="mc-field-group">
      <mc-text-field formControlName="subject"
                     label="$I18N_MESSAGE_CENTER_PURGE_MESSAGES_FORM.SUBJECT_LABEL"
                     errorPrefix="$I18N_MESSAGE_CENTER_PURGE_MESSAGES_FORM.ERRORS">
      </mc-text-field>
    </div>

    <div class="mc-field-group">
      <mc-date-range-picker formControlName="dateRange"
                            label="$I18N_MESSAGE_CENTER_PURGE_MESSAGES_FORM.DATE_RANGE_LABEL"
                            [config]="dateRangeConfig"
                            [horizontal]="true"
                            errorPrefix="$I18N_MESSAGE_CENTER_PURGE_MESSAGES_FORM.ERRORS"
                            [hideTime]="true"></mc-date-range-picker>
    </div>

    <div class="mc-field-group">
      <mc-field label="$I18N_MESSAGE_CENTER_PURGE_MESSAGES_FORM.DELIVERY_ATTEMPTS_LABEL">
        <div class="mc-field-container">
          <label class="purge-form-checkbox">
            <input type="checkbox" name="includeAttempts" formControlName="includeAttempts">
            {{ '$I18N_MESSAGE_CENTER_PURGE_MESSAGES_FORM.INCLUDE_ATTEMPTS' | translate }}
          </label>
          <mc-select formControlName="attemptOperator"
                     [options]="attemptOperators" class="purge-form-select"></mc-select>
          <mc-select formControlName="attemptCount"
                     [options]="attemptCounts" class="purge-form-select"></mc-select>
        </div>
      </mc-field>
    </div>

    <div class="mc-field-group">
      <mc-field class="mc-field-container"
                label="$I18N_MESSAGE_CENTER_PURGE_MESSAGES_FORM.MESSAGE_PRIORITY_LABEL">

        <div class="mc-field-container">
          <label class="mc-flex-50 purge-form-checkbox">
            <input
              type="checkbox"
              name="priorityLow"
              formControlName="priorityLow"
            />
            {{ '$I18N_MESSAGE_CENTER_PURGE_MESSAGES_FORM.PRIORITY_LOW_LABEL' | translate }}
          </label>

          <label class="mc-flex-50 purge-form-checkbox">
            <input
              type="checkbox"
              name="priorityNotApplied"
              formControlName="priorityNotApplied"
            />
            {{ '$I18N_MESSAGE_CENTER_PURGE_MESSAGES_FORM.PRIORITY_NOT_APPLIED_LABEL' | translate }}
          </label>

        </div>
      </mc-field>
    </div>

    <div class="full-width">
      <div class="pull-right">
        <button
          type='button'
          class="btn btn-secondary no-border panel-margin-left"
          (click)="resetForm()"
        >{{ '$I18N_MESSAGE_CENTER_PURGE_MESSAGES_FORM.CLEAR_BUTTON' | translate }}</button>
        <button
          type="submit"
          class="btn btn-primary panel-margin-left"
          [disabled]="form.invalid">{{ "$I18N_MESSAGE_CENTER_PURGE_MESSAGES_FORM.SUBMIT_BUTTON" | translate }}</button>
      </div>
    </div>
  </form>

</div>
