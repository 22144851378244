"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserChallengePageComponent = void 0;
const template_previews_1 = require("../template-previews");
const user_challenge_page_bottom_items_1 = require("./user-challenge-page.bottom-items");
class UserChallengePageComponent extends template_previews_1.TemplatePreviewsDirective {
    constructor(ts) {
        super(ts);
        this.ts = ts;
        this.defaultTitle = '$I18N_AWARENESS_TEMPLATE_SETUP_USER_CHALLENGE_PAGE_DEFAULT_TITLE';
        this.defaultSubTitle = '$I18N_AWARENESS_TEMPLATE_SETUP_USER_CHALLENGE_PAGE_DEFAULT_SUB_TITLE';
        this.itemsToShowAtTheBottom = user_challenge_page_bottom_items_1.itemsToShowAtTheBottom;
    }
}
exports.UserChallengePageComponent = UserChallengePageComponent;
