"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectedRow = exports.canUpdateList = exports.hasGenericError = exports.isTableLoading = exports.getBounces = exports.reducer = exports.initialState = void 0;
const listActions = require("../../actions/list.action");
const commonActions = require("../../../common/actions");
exports.initialState = {
    bounces: [],
    isLoading: false,
    genericError: false,
    updateList: false,
    selectedRow: undefined
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case listActions.GET_BOUNCED_MESSAGE_INFO:
            return Object.assign(Object.assign({}, state), { selectedRow: action.row });
        case commonActions.HIGHLIGHT_SELECTED_ROW:
            return Object.assign(Object.assign({}, state), { selectedRow: action.row });
        case commonActions.CLEAR_SELECTED_ROW:
            return Object.assign(Object.assign({}, state), { selectedRow: undefined });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getBounces = (state) => state.bounces;
exports.getBounces = getBounces;
const isTableLoading = (state) => state.isLoading;
exports.isTableLoading = isTableLoading;
const hasGenericError = (state) => state.genericError;
exports.hasGenericError = hasGenericError;
const canUpdateList = (state) => state.updateList;
exports.canUpdateList = canUpdateList;
const selectedRow = (state) => state.selectedRow;
exports.selectedRow = selectedRow;
