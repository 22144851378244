<mc-modal-header
  headerTitle="{{ getHeaderTitle() }}">
</mc-modal-header>
<mc-modal-body>
  <p *ngIf="data.type === GENERATE">{{ '$I18N_API_APPLICATIONS_OKTA_CONFIRMATION_MODAL.GENERATE_MESSAGE' | translate }}</p>
  <p *ngIf="data.type === DELETE">
    <span>
      {{ '$I18N_API_APPLICATIONS_OKTA_CONFIRMATION_MODAL.DELETE_MESSAGE2' | translate }}
      <a href="{{ kbLink }}" target="_blank" rel="noopener noreferrer">
      {{ '$I18N_API_APPLICATIONS_OKTA_CONFIRMATION_MODAL.IC_LINK' | translate }}
        <span class="fas fa-external-link-alt"></span>
      </a>
      {{ '$I18N_API_APPLICATIONS_OKTA_CONFIRMATION_MODAL.DELETE_MESSAGE3' | translate }}
    </span>
  </p>
</mc-modal-body>
<mc-modal-footer>
  <button type="button" class="btn btn-text" (click)="cancel()">
    {{ '$I18N_API_APPLICATIONS_OKTA_CONFIRMATION_MODAL.CANCEL_BUTTON' | translate }}
  </button>

  <button *ngIf="data.type === GENERATE" type="button" class="btn btn-primary" (click)="generate()">
    {{ '$I18N_API_APPLICATIONS_OKTA_CONFIRMATION_MODAL.GENERATE_BUTTON' | translate }}
  </button>

  <button *ngIf="data.type === DELETE" type="button" class="btn btn-danger" (click)="delete()">
    {{ '$I18N_API_APPLICATIONS_OKTA_CONFIRMATION_MODAL.DELETE_BUTTON' | translate }}
  </button>
</mc-modal-footer>
