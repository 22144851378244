"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const create_policy_page_component_1 = require("./containers/create-swg-policy/create-policy-page.component");
const swg_list_container_component_1 = require("./containers/list/swg-list-container.component");
angular
    .module('swgPolicy', [])
    .directive('mcSwgCreatePolicy', static_1.downgradeComponent({
    component: create_policy_page_component_1.CreatePolicyComponent
}))
    .directive('mcSwgPolicyList', static_1.downgradeComponent({
    component: swg_list_container_component_1.SwgListContainerComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider
            .state('secure-web-gateway-policy', {
            url: '/secure-web-gateway/policy',
            data: {
                capabilities: 'Permission.SWG_CONFIGURE_POLICIES_READ',
                isFixedLayout: false,
                breadcrumbs: [
                    {
                        label: '$I18N_MEGA_MENU_GROUP_SECURE_WEB_GATEWAY_LABEL'
                    },
                    {
                        label: '$I18N_MEGA_MENU_GROUP_SECURE_WEB_GATEWAY_CONFIGURE_POLICIES'
                    }
                ],
                checkProgress: false,
                tabId: 'Swg-policies',
                tabTitle: '$I18N_MEGA_MENU_GROUP_SECURE_WEB_GATEWAY_TAB_LABEL'
            },
            views: {
                main: {
                    template: '<mc-swg-create-policy></mc-swg-create-policy>'
                }
            }
        })
            .state('secure-web-gateway-policy-list', {
            url: '/secure-web-gateway/policy-list',
            params: {
                showNotification: ''
            },
            data: {
                capabilities: 'Permission.SWG_CONFIGURE_POLICIES_READ',
                isFixedLayout: false,
                checkProgress: false,
                tabReload: true,
                tabId: 'Swg-policies',
                tabTitle: '$I18N_MEGA_MENU_GROUP_SECURE_WEB_GATEWAY_TAB_LABEL'
            },
            views: {
                main: {
                    template: '<mc-swg-policy-list></mc-swg-policy-list>'
                }
            }
        });
    }
]);
