"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDashboardSettingsSetStatus = exports.getDashboardSettings = exports.getDashboardSettingsSelectedQueueType = exports.getDashboardSettingsLoading = exports.reducer = exports.initialState = void 0;
const dashboard_settings_actions_1 = require("../actions/dashboard-settings.actions");
exports.initialState = {
    selectedQueueType: null,
    settingsData: null,
    loading: false,
    settingsUpdated: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case dashboard_settings_actions_1.DashboardSettingsActions.GET_DASHBOARD_SETTINGS:
            return Object.assign(Object.assign({}, state), { selectedQueueType: action.queueType, loading: true, settingsUpdated: false });
        case dashboard_settings_actions_1.DashboardSettingsActions.GET_DASHBOARD_SETTINGS_SUCCESS:
            if (action.payload.queueType === 'review') {
                return Object.assign(Object.assign({}, state), { loading: false, settingsData: action.payload });
            }
            return Object.assign(Object.assign({}, state), { loading: false, settingsData: action.payload });
        case dashboard_settings_actions_1.DashboardSettingsActions.GET_DASHBOARD_SETTINGS_FAIL:
            return Object.assign(Object.assign({}, state), { loading: false, failure: action.payload });
        case dashboard_settings_actions_1.DashboardSettingsActions.SET_DASHBOARD_SETTINGS:
            return Object.assign(Object.assign({}, state), { settingsUpdated: false });
        case dashboard_settings_actions_1.DashboardSettingsActions.SET_DASHBOARD_SETTINGS_SUCCESS:
            return Object.assign(Object.assign({}, state), { settingsUpdated: true });
        case dashboard_settings_actions_1.DashboardSettingsActions.SET_DASHBOARD_SETTINGS_FAIL:
            return Object.assign(Object.assign({}, state), { settingsUpdated: false });
        default:
            return Object.assign({}, state);
    }
}
exports.reducer = reducer;
const getDashboardSettingsLoading = (state) => state && state.loading;
exports.getDashboardSettingsLoading = getDashboardSettingsLoading;
const getDashboardSettingsSelectedQueueType = (state) => state && state.selectedQueueType;
exports.getDashboardSettingsSelectedQueueType = getDashboardSettingsSelectedQueueType;
const getDashboardSettings = (state) => state && state.settingsData;
exports.getDashboardSettings = getDashboardSettings;
const getDashboardSettingsSetStatus = (state) => state && state.settingsUpdated;
exports.getDashboardSettingsSetStatus = getDashboardSettingsSetStatus;
