<mc-layout-modal-simple 
  keyTitle="$I18N_TRACK_AND_TRACE_MAIN_PAGE_SEARCH_REASON.TITLE"
  keyDescription="$I18N_TRACK_AND_TRACE_MAIN_PAGE_SEARCH_REASON.TEXT_DESCRIPTION">
  <mc-body-container>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <input class="form-control"
        #searchReasonInput
        type="text"
        [placeholder]="'$I18N_TRACK_AND_TRACE_MAIN_PAGE_SEARCH_REASON.INPUT_PLACEHOLDER' | translate"
        formControlName="searchReason">
      <button type="submit" hidden="true"></button>
    </form>
  </mc-body-container>
  <mc-footer-container>
    <button type="button" class="btn btn-secondary" (click)="close()">
      {{ '$I18N_TRACK_AND_TRACE_MAIN_PAGE_SEARCH_REASON.CANCEL_BUTTON' | translate }}
    </button>
    <button type="button" [disabled]="!form.valid" class="btn btn-primary" (click)="onSubmit()">
      {{ '$I18N_TRACK_AND_TRACE_MAIN_PAGE_SEARCH_REASON.SUBMIT_BUTTON' | translate }}
    </button>
  </mc-footer-container>
</mc-layout-modal-simple>
