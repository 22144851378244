<!-- DETAILS -->
<mc-collapsable-panel data-unit-test="details-panel"
  header="$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.SUMMARY.LABELS.DETAILS_SECTION" [isExpanded]="true">
  <div class="mc-aside-panel-section">
     <div class="mc-detailed-list mc-aside-panel-sub-section mc-aside-panel-first-item">
      <mc-value-field label="$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.DETAILS.LABELS.DEFINITION_NAME"
        [value]="selectedRow?.description">
      </mc-value-field>
    </div>
  </div> 
</mc-collapsable-panel>
<hr class="mc-group-separator" />

<!-- INBOUND -->
  <mc-collapsable-panel data-unit-test="inbound-panel" 
    header="$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.SUMMARY.LABELS.INBOUND_SECTION" [isExpanded]="true">
    <div class="mc-aside-panel-section">
      <div class="mc-detailed-list mc-aside-panel-first-item">
        <mc-value-field label="$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.DETAILS.LABELS.ENABLE_INBOUND"
          [value]="getEnabledDisabledLabel(definitionDetails?.checkInbound)">
        </mc-value-field>
      </div>
      <ng-container *ngIf="definitionDetails?.checkInbound">
        <div class="mc-detailed-list">
          <mc-value-field label="$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.DETAILS.LABELS.DELIVERY_OPTIONS"
          [value]="getDefinitionValueType(definitionDetails?.action)">
          </mc-value-field>
          <ng-container *ngIf="definitionDetails?.action !== 'sandbox'">
            <mc-value-field label="$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.DETAILS.LABELS.IGNORE_SIGNED_MSG"
            [value]="getEnabledDisabledLabel(definitionDetails?.ignoreSignedMessages)">
            </mc-value-field>
        </ng-container>
        <ng-container *ngIf="(definitionDetails?.action === 'dynamic' || definitionDetails?.action === 'sandbox')">
            <mc-value-field label="$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.DETAILS.LABELS.SANDBOX_FALLBACK_ACTION"
            [value]="getDefinitionValueType(definitionDetails?.preEmptiveFailureAction)" data-unit-test="fallback-action-panel">
            </mc-value-field>
        </ng-container>
        <mc-value-field label="$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.DETAILS.LABELS.RELEASE_INTERNAL_ATTACHMENT"
        [value]="getEnabledDisabledLabel(definitionDetails?.internalForward)">
        </mc-value-field>
        <ng-container *ngIf="(displayStripAndLinkEncAttachments(definitionDetails?.action)) && (hasStripAndLinkEncAttachments$ | async)">
            <mc-value-field label="$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.DETAILS.LABELS.STRIP_AND_LINK_ENC_ATTACHMENTS"
                            [value]="getEnabledDisabledLabel(definitionDetails?.sandboxEncryptionPortalEnabled)" data-unit-test="strip-and-link-enc-attachments-value">
            </mc-value-field>
        </ng-container>
        </div>
        <div class="mc-detailed-list" *ngIf="(definitionDetails?.action === 'dynamic' || definitionDetails?.action === 'sandbox')">
          <div class="mc-aside-panel-section-header">
          {{ '$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.SUBSECTIONS.LABELS.BOUNCE_REVIEW' | translate }}
          </div>
          <mc-value-field label="$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.DETAILS.LABELS.ENABLE_NOTIFICATIONS"
          [value]="getEnabledDisabledLabel(definitionDetails?.notifyOnMalicious)">
          </mc-value-field>
          <ng-container *ngIf="definitionDetails?.notifyOnMalicious">
          <ng-container *ngIf="!isAAAOrFAAUser">
              <mc-value-field label="$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.DETAILS.LABELS.ADMINISTRATORS"
              [value]="definitionDetails?.notifyGroup?.description">
              </mc-value-field>
          </ng-container>
          <mc-value-field label="$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.DETAILS.LABELS.INTERNAL_SENDER"
          [value]="getEnabledDisabledLabel(definitionDetails?.notifyInternalSender)">
          </mc-value-field>
          <mc-value-field label="$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.DETAILS.LABELS.INTERNAL_RECIPIENT"
          [value]="getEnabledDisabledLabel(definitionDetails?.notifyInternalRecipient)">
          </mc-value-field>
          <mc-value-field label="$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.DETAILS.LABELS.EXTERNAL_SENDER"
          [value]="getEnabledDisabledLabel(definitionDetails?.notifyExternalSender)">
          </mc-value-field>
          </ng-container>
        </div>
        <div class="mc-detailed-list" *ngIf="definitionDetails?.action === 'safedoc_and_link'">
          <div class="mc-aside-panel-section-header">
          {{ '$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.SUBSECTIONS.LABELS.ADMIN_UNSAFE_FILES' | translate }}
          </div>
          <mc-value-field label="$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.DETAILS.LABELS.ADMIN_NOTIFICATION"
          [value]="getEnabledDisabledLabel(definitionDetails?.notifyOnMalicious)">
          </mc-value-field>
          <ng-container *ngIf="definitionDetails?.notifyOnMalicious && !isAAAOrFAAUser">
          <mc-value-field label="$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.DETAILS.LABELS.ADMIN_REVIEW"
              [value]="definitionDetails?.notifyGroup?.description">
          </mc-value-field>
          </ng-container>
        </div>
        <div class="mc-detailed-list" *ngIf="definitionDetails?.action !== 'sandbox'">
          <div class="mc-aside-panel-section-header">
          {{ '$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.SUBSECTIONS.LABELS.DOCUMENT_TRANSCRIPTION' | translate }}
          </div>
          <mc-value-field label="$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.DETAILS.LABELS.TRANSCRIBED_DOCUMENT_FORMAT"
          [value]="getDefinitionValueType(definitionDetails?.safeDocFormat)">
          </mc-value-field>
          <mc-value-field label="$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.DETAILS.LABELS.TRANSCRIBED_SPREADSHEET_FORMAT"
          [value]="getDefinitionValueType(definitionDetails?.safeSpreadsheetFormat)">
          </mc-value-field>
          <ng-container *ngIf="definitionDetails?.safeSpreadsheetFormat === 'csv'">
              <mc-value-field label="$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.DETAILS.LABELS.SPREADSHEET_WORKSHEET"
          [value]="getTranscribeWorksheet(definitionDetails?.firstSheetOnly)">
          </mc-value-field>
          </ng-container>
        </div>      
      </ng-container>
    </div> 
  </mc-collapsable-panel>
  <hr class="mc-group-separator" />

<ng-container *ngIf="(hasIEPPackage || isAAAOrFAAUser)">
<!-- OUTBOUND -->
  <mc-collapsable-panel data-unit-test="outbound-panel" [isExpanded]="true"
    header="$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.SUMMARY.LABELS.OUTBOUND_SECTION">
    <div class="mc-aside-panel-section">
      <div class="mc-detailed-list mc-aside-panel-first-item">
        <mc-value-field label="$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.DETAILS.LABELS.ENABLE_OUTBOUND"
          [value]="getEnabledDisabledLabel(definitionDetails?.checkOutbound)">
        </mc-value-field>
      </div>
      <ng-container *ngIf="definitionDetails?.checkOutbound">
        <div class="mc-detailed-list">
            <mc-value-field label="$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.DETAILS.LABELS.GATEWAY_ACTION"
            [value]="getDefinitionValueType(definitionDetails?.outboundAction)">
            </mc-value-field>
            <mc-value-field label="$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.DETAILS.LABELS.GATEWAY_FALLBACK_ACTION"
            [value]="getDefinitionValueType(definitionDetails?.outboundFallbackAction)">
            </mc-value-field>
            <mc-value-field label="$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.DETAILS.LABELS.MAILBOX_ACTION"
            [value]="getDefinitionValueType(definitionDetails?.outboundRemediationAction)">
            </mc-value-field>
            <mc-value-field label="$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.DETAILS.LABELS.MAILBOX_FALLBACK_ACTION"
            [value]="getDefinitionValueType(definitionDetails?.outboundFallbackRemediationAction)">
            </mc-value-field>
        </div>
        <div class="mc-detailed-list">
            <div class="mc-aside-panel-section-header">
            {{ '$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.SUBSECTIONS.LABELS.NOTIFICATIONS' | translate }}
            </div>
            <mc-value-field label="$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.DETAILS.LABELS.ENABLE_NOTIFICATIONS"
            [value]="getEnabledDisabledLabel(definitionDetails?.outboundNotifyOnMalicious)">
            </mc-value-field>
          <ng-container *ngIf="definitionDetails?.outboundNotifyOnMalicious">
            <ng-container *ngIf="!isAAAOrFAAUser">
              <mc-value-field label="$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.DETAILS.LABELS.NOTIFY_GROUP"
              [value]="definitionDetails?.outboundNotifyGroup?.description">
              </mc-value-field>
            </ng-container>
            <mc-value-field label="$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.DETAILS.LABELS.INTERNAL_SENDER"
            [value]="getEnabledDisabledLabel(definitionDetails?.outboundNotifyInternalSender)">
            </mc-value-field>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </mc-collapsable-panel>
  <hr class="mc-group-separator" />


  <!-- JOURNAL -->
  <mc-collapsable-panel data-unit-test="journal-panel" [isExpanded]="true"
    header="$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.SUMMARY.LABELS.JOURNAL_SECTION">
    <div class="mc-aside-panel-section">
      <div class="mc-detailed-list mc-aside-panel-first-item">
        <mc-value-field label="$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.DETAILS.LABELS.ENABLE_JOURNAL"
          [value]="getEnabledDisabledLabel(definitionDetails?.checkJournal)">
        </mc-value-field>
      </div>
      <ng-container *ngIf="definitionDetails?.checkJournal">
        <div class="mc-detailed-list">
            <mc-value-field label="$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.DETAILS.LABELS.MAILBOX_ACTION"
            [value]="getDefinitionValueType(definitionDetails?.journalRemediationAction)">
            </mc-value-field>
            <mc-value-field label="$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.DETAILS.LABELS.MAILBOX_FALLBACK_ACTION"
            [value]="getDefinitionValueType(definitionDetails?.journalFallbackRemediationAction)">
            </mc-value-field>
        </div>
        <div class="mc-detailed-list">
            <div class="mc-aside-panel-section-header">
            {{ '$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.SUBSECTIONS.LABELS.NOTIFICATIONS' | translate }}
            </div>
            <mc-value-field label="$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.DETAILS.LABELS.ENABLE_NOTIFICATIONS"
            [value]="getEnabledDisabledLabel(definitionDetails?.journalNotifyOnMalicious)">
            </mc-value-field>
          <ng-container *ngIf="definitionDetails?.journalNotifyOnMalicious">
            <ng-container *ngIf="!isAAAOrFAAUser">
              <mc-value-field label="$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.DETAILS.LABELS.NOTIFY_GROUP"
              [value]="definitionDetails?.journalNotifyGroup?.description">
              </mc-value-field>
            </ng-container>
            <mc-value-field label="$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.DETAILS.LABELS.INTERNAL_SENDER"
            [value]="getEnabledDisabledLabel(definitionDetails?.journalNotifyInternalSender)">
            </mc-value-field>
            <mc-value-field label="$I18N_ATTACHMENT_PROTECTION_DEFINITION_WIZARD.STEPS.DETAILS.LABELS.INTERNAL_RECIPIENT"
            [value]="getEnabledDisabledLabel(definitionDetails?.journalNotifyInternalRecipient)">
            </mc-value-field>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </mc-collapsable-panel>
</ng-container>
