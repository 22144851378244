"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LexiconDetailsBuilder = void 0;
const lexicon_base_builder_1 = require("./lexicon-base.builder");
const lexicon_details_1 = require("./lexicon-details");
const lexicon_search_params_1 = require("./lexicon-search-params");
class LexiconDetailsBuilder extends lexicon_base_builder_1.LexiconBaseBuilder {
    withWords(words) {
        this.words = words;
        return this;
    }
    withPhrases(phrases) {
        this.phrases = phrases;
        return this;
    }
    withQuery(query) {
        this.query = query;
        return this;
    }
    withRuleIds(rules) {
        this.ruleIds = rules;
        return this;
    }
    withSearchCriteriaVersionsNumber(numberOfVersions) {
        this.numberOfVersions = numberOfVersions ? numberOfVersions : 1;
        return this;
    }
    withSearchType(searchType) {
        this.searchType = searchType;
        return this;
    }
    build() {
        return new lexicon_details_1.LexiconDetails(this.id, this.name, this.description, this.createdBy, this.updatedBy, this.created, this.updated, this.status, this.ruleIds, new lexicon_search_params_1.LexiconSearchParameters(this.words, this.phrases, this.query, this.searchType), this.numberOfVersions, this.searchType);
    }
}
exports.LexiconDetailsBuilder = LexiconDetailsBuilder;
