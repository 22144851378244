"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiIntegrationWildfireRemediationStepComponent = void 0;
const core_1 = require("@angular/core");
const operators_1 = require("rxjs/operators");
class ApiIntegrationWildfireRemediationStepComponent {
    constructor(fb, wildfireService) {
        this.fb = fb;
        this.wildfireService = wildfireService;
        this.hasInternalEmailProtectPackage = false;
        this.onSwitchRemediation = new core_1.EventEmitter();
        this.form = this.fb.group({
            remediationRequired: false
        });
    }
    ngOnInit() {
        this.hasInternalEmailProtectPackage = this.wildfireService.hasInternalEmailProtectAccountPackage();
        this.form.patchValue({
            remediationRequired: this.remediationRequired
        });
        this.formSubscription = this.form.valueChanges.pipe(operators_1.debounceTime(200)).subscribe(() => {
            this.update();
        });
    }
    update() {
        this.onSwitchRemediation.emit(this.form.value.remediationRequired);
    }
    isValid() {
        return true;
    }
}
exports.ApiIntegrationWildfireRemediationStepComponent = ApiIntegrationWildfireRemediationStepComponent;
