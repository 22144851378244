"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentType = void 0;
class ContentType {
    constructor(columns) {
        this.columns = columns;
    }
    getSearchContentsAction(payload) {
        return this.getLoadContentsAction(payload);
    }
    getColumns() {
        return this.columns;
    }
}
exports.ContentType = ContentType;
