<mc-table
  [isLoading]="isLoading"
  [columns]="columns"
  [data]="tableData"
  translatePrefix="$I18N_CONNECTORS_TABLE"
  (rowClick)="onRowClick($event)"
  [highlightedRow]="selectedRow$ | async"
>
  <button
    *mcCapabilities="'Permission.CONNECTORS_EDIT || Permission.SERVER_CONNECTIONS_EDIT'"
    class="mc-table-actions btn btn-primary mc-create-button"
    (click)="this.onCreateConnector.emit()"
  >
    {{ '$I18N_CONNECTORS_TABLE.CREATE_CONNECTOR' | translate }}
  </button>
  <mc-filters
    [metadata]="metaData"
    [showPagination]="true"
    (paginatorChange)="onPaginationChange.emit($event)"
  >
    <mc-filter-bundle-filter-by-and-search-by
      placeholder="$I18N_CONNECTORS_TABLE.FILTERS.SEARCH_FIELD_PLACEHOLDER"
      [searchByFields]="searchByFields"
      [minLengthForSearchField]="minLengthForSearchField"
      [maxLengthForSearchField]="maxLengthForSearchField"
      (filtersChange)="onFilterChange.emit($event)"
    >
    </mc-filter-bundle-filter-by-and-search-by>
  </mc-filters>
  <mc-column key="name">
    <mc-body-cell *mcBodyCellDef="let row">{{ row.name }}</mc-body-cell>
  </mc-column>
  <mc-column key="description">
    <mc-body-cell *mcBodyCellDef="let row">{{ row.description || ('$I18N_CONNECTORS_TABLE.DESCRIPTION_NOT_PROVIDED' | translate) }}</mc-body-cell>
  </mc-column>
  <mc-column key="provider">
    <mc-body-cell *mcBodyCellDef="let row">
      <mc-provider-icon [providerType]="row.provider"></mc-provider-icon>
      {{ '$I18N_CONNECTORS.PROVIDERS.' + row.provider | uppercase | translate }}
    </mc-body-cell>
  </mc-column>
  <mc-column key="created">
    <mc-body-cell *mcBodyCellDef="let row">{{
      row.created | date: dateFormat
    }}</mc-body-cell>
  </mc-column>
  <mc-column key="product">
    <mc-body-cell *mcBodyCellDef="let row">
      {{ row.product?.name }}
    </mc-body-cell>
  </mc-column>
  <mc-column key="status">
    <mc-body-cell *mcBodyCellDef="let row">
      <mc-connector-status [connectorStatus]="row.status"></mc-connector-status>
      {{ '$I18N_CONNECTORS_TABLE.CONNECTION_STATUS.' + row.status | uppercase | translate }}
    </mc-body-cell>
  </mc-column>
  <mc-column-actions key="right-column" mcShowColumnConfig [columnsAlwaysVisible]="columnsAlwaysVisible">
    <ng-container>
      <mc-row-actions *mcRowActions="let row">
        <li
          mcRowAction="$I18N_CONNECTORS_TABLE.ACTIONS.EDIT"
          (click)="editConnector(row)"
          *mcCapabilities="'Permission.CONNECTORS_EDIT || Permission.SERVER_CONNECTIONS_EDIT'"
        ></li>
        <li
          mcRowAction="$I18N_CONNECTORS_TABLE.ACTIONS.DELETE"
          (click)="openDeleteConfirmationModal(row)"
          *mcCapabilities="'Permission.CONNECTORS_EDIT || Permission.SERVER_CONNECTIONS_EDIT'"
         ></li>
        <li class="divider" 
        *mcCapabilities="'Permission.CONNECTORS_EDIT || Permission.SERVER_CONNECTIONS_EDIT'"
        ></li>
        <li
          mcRowAction="$I18N_CONNECTORS_TABLE.ACTIONS.VIEW_DETAILS"
          (click)="onRowClick(row)"
        ></li>
       </mc-row-actions>
    </ng-container>
  </mc-column-actions>
  <mc-empty-results
    keyTitle="{{ '$I18N_CONNECTORS_TABLE.NO_CONNECTORS' | translate }}"
    iconClass="far fa-window-maximize"
  >
  </mc-empty-results>
</mc-table>
