<mc-collapsable-panel header="$I18N_MESSAGE_CENTER_MESSAGE_INFO_REMOTE_SERVER_INFO.TITLE" [isExpanded]="true">
  <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_REMOTE_SERVER_INFO.LABEL_REMOTE_SERVER_NAME"
                  value="{{queueInfo.remoteServerName | mcNoDataAvailableDisplay}}">
  </mc-value-field>
  <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_REMOTE_SERVER_INFO.LABEL_REMOTE_IP_ADDRESS"
                  value="{{queueInfo.remoteIp | mcNoDataAvailableDisplay}}">
  </mc-value-field>
  <mc-value-field *ngIf="messageStatus === 'bounced'"
                  label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_REMOTE_SERVER_INFO.LABEL_BOUNCED_INFORMATION"
                  [value]="queueInfo.info">
  </mc-value-field>
  <mc-value-field *ngIf="showRemoteServerGreeting()"
                  label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_REMOTE_SERVER_INFO.LABEL_BOUNCED_SERVER_GREETING_EHLO"
                  [value]="queueInfo.remoteServerGreeting">
  </mc-value-field>
</mc-collapsable-panel>
