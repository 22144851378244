"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportMessageModalEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const notification_actions_1 = require("app-new/components/notification/actions/notification.actions");
const actions = require("../../actions");
const report_message_modal_1 = require("../../components/report-message-modal");
class ReportMessageModalEffects {
    constructor(actions$, apiService, modalService, capabilitiesService) {
        this.actions$ = actions$;
        this.apiService = apiService;
        this.modalService = modalService;
        this.capabilitiesService = capabilitiesService;
        this.reportMessageWithComment$ = this.actions$.pipe(effects_1.ofType(actions.REPORT_MESSAGE), operators_1.switchMap((action) => {
            return this.capabilitiesService
                .evalCapabilitiesExpression('Gateway.SpamPhishing.OneButtonExperience && Turbo.Basic')
                .pipe(operators_1.switchMap((hasCapabilities) => {
                return this.sendReport(hasCapabilities, action).pipe(operators_1.map((response) => {
                    if (!!response && response.success) {
                        return new notification_actions_1.NotificationShowSuccessAction('$I18N_MESSAGE_CENTER_REPORT_MESSAGE_MODAL_EFFECTS.SUCCESS');
                    }
                    return new notification_actions_1.NotificationShowFailAction('$I18N_MESSAGE_CENTER_REPORT_MESSAGE_MODAL_EFFECTS.FAIL');
                }), operators_1.catchError(() => rxjs_1.of(new notification_actions_1.NotificationShowFailAction('$I18N_MESSAGE_CENTER_REPORT_MESSAGE_MODAL_EFFECTS.FAIL'))));
            }));
        }));
        this.openReportAsModal$ = this.actions$.pipe(effects_1.ofType(actions.REPORT_MESSAGE_OPEN_MODAL), operators_1.switchMap((action) => {
            return this.modalService
                .open(report_message_modal_1.ReportMessageModalComponent, {
                size: 'md',
                hasBackdrop: true,
                data: action.reportMessageType
            })
                .afterClose()
                .pipe(operators_1.filter((report) => !!report && report.submitted), operators_1.map((report) => {
                return new actions.ReportMessageAction(action.reportMessageType, action.id, report.comment);
            }));
        }));
    }
    sendReport(hasTurboCap, action) {
        if (hasTurboCap === true) {
            return this.apiService.turboReport({
                id: action.id,
                type: action.reportMessageType,
                comment: action.comment
            });
        }
        else {
            return this.apiService.reportMessage({
                id: action.id,
                type: action.reportMessageType,
                comment: action.comment
            });
        }
    }
}
__decorate([
    effects_1.Effect()
], ReportMessageModalEffects.prototype, "reportMessageWithComment$", void 0);
__decorate([
    effects_1.Effect()
], ReportMessageModalEffects.prototype, "openReportAsModal$", void 0);
exports.ReportMessageModalEffects = ReportMessageModalEffects;
