"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RuleConfigBuilder = void 0;
const rule_config_1 = require("./rule-config");
class RuleConfigBuilder {
    withId(id) {
        this.id = id;
        return this;
    }
    withName(name) {
        this.name = name;
        return this;
    }
    withDescription(description) {
        this.description = description;
        return this;
    }
    withSamplingPercentage(samplingPercentage) {
        this.samplingPercentage = samplingPercentage;
        return this;
    }
    withReviewQueueId(reviewQueueId) {
        this.reviewQueueId = reviewQueueId;
        return this;
    }
    withScheduleType(scheduleType) {
        this.scheduleType = scheduleType;
        return this;
    }
    withMessageRoute(messageRoute) {
        this.messageRoute = messageRoute;
        return this;
    }
    withLexiconId(lexiconId) {
        this.lexiconId = lexiconId;
        return this;
    }
    withSearchGroupId(searchGroupId) {
        this.searchGroupId = searchGroupId;
        return this;
    }
    withState(state) {
        this.state = state;
        return this;
    }
    fromRule(rule) {
        this.withId(rule.id);
        this.withName(rule.name);
        this.withDescription(rule.description);
        this.withMessageRoute(rule.messageRoute);
        this.withScheduleType(rule.scheduleType);
        if (rule.lexicon) {
            this.withLexiconId(rule.lexicon.id);
        }
        this.withSamplingPercentage(rule.samplingPercentage);
        this.withSearchGroupId(rule.searchGroup.id);
        this.withState(rule.state);
        this.withReviewQueueId(rule.reviewQueue.id);
        return this;
    }
    build() {
        return new rule_config_1.RuleConfig(this.name, this.description, this.messageRoute, this.scheduleType, this.lexiconId, this.samplingPercentage, this.searchGroupId, this.state, this.reviewQueueId, this.id);
    }
}
exports.RuleConfigBuilder = RuleConfigBuilder;
