"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImpersonationLogActionPipe = void 0;
class ImpersonationLogActionPipe {
    constructor(translateService) {
        this.translateService = translateService;
    }
    transform(action) {
        switch (action) {
            case 'none':
                return this.translateService.instant('$I18N_TTP_IMPERSONATION_LOG_ACTION_NONE');
            case 'hold':
                return this.translateService.instant('$I18N_TTP_IMPERSONATION_LOG_ACTION_HOLD');
            case 'bounce':
                return this.translateService.instant('$I18N_TTP_IMPERSONATION_LOG_ACTION_BOUNCE');
            default:
                return '';
        }
    }
}
exports.ImpersonationLogActionPipe = ImpersonationLogActionPipe;
