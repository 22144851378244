'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
/* eslint-disable @typescript-eslint/no-var-requires */
const Highcharts = require('highcharts');
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
require('highcharts/modules/offline-exporting')(Highcharts);
/**
 * Created by cfeudo on 10/01/2016.
 */
document.createElement('mc-highcharts');
angular.module('mc-highcharts.directive', [])
    .directive('mcHighcharts', ['$translate', function ($translate) {
        return {
            restrict: 'EA',
            template: '<div class="dashboard-loading-icon"><spinner></spinner></div>',
            scope: {
                config: '&'
            },
            link(scope, element) {
                Highcharts.setOptions({
                    global: {
                        useUTC: false,
                    },
                    lang: {
                        noData: $translate.instant('$I18N_COMMON_DASHBOARD_NO_DATA_TO_SHOW_LABEL'),
                        thousandsSep: ',',
                        printChart: $translate.instant('$I18N_COMMON_EXPORT_CHART_PRINT_CHART_LABEL'),
                        downloadPNG: $translate.instant('$I18N_COMMON_EXPORT_CHART_PNG_IMAGE_CHART_LABEL'),
                        downloadJPEG: $translate.instant('$I18N_COMMON_EXPORT_CHART_JPEG_IMAGE_LABEL'),
                        downloadSVG: $translate.instant('$I18N_COMMON_EXPORT_CHART_SVG_VECTOR_LABEL')
                    },
                    exporting: {
                        libURL: 'highcharts/lib'
                    }
                });
                scope.config().then(function (data) {
                    data.series.map(arr => {
                        if (arr.data.length > 0) {
                            Highcharts.chart(element[0], data);
                        }
                        else {
                            data = Object.assign(Object.assign({}, data), { plotOptions: {
                                    pie: {
                                        borderWidth: 0
                                    }
                                } });
                            Highcharts.chart(element[0], data);
                        }
                    });
                });
            }
        };
    }]);
