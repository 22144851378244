"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiApplicationFailureAction = exports.ApiApplicationShowNewFeaturesModalAction = exports.ApiApplicationActionTypes = void 0;
var ApiApplicationActionTypes;
(function (ApiApplicationActionTypes) {
    ApiApplicationActionTypes["ApiApplicationShowNewFeaturesModal"] = "[ApiApplication/General] Show New Features Modal";
    ApiApplicationActionTypes["ApiApplicationFailure"] = "[ApiApplication/General] Api Application Failure";
})(ApiApplicationActionTypes = exports.ApiApplicationActionTypes || (exports.ApiApplicationActionTypes = {}));
class ApiApplicationShowNewFeaturesModalAction {
    constructor() {
        this.type = ApiApplicationActionTypes.ApiApplicationShowNewFeaturesModal;
    }
}
exports.ApiApplicationShowNewFeaturesModalAction = ApiApplicationShowNewFeaturesModalAction;
class ApiApplicationFailureAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiApplicationActionTypes.ApiApplicationFailure;
    }
}
exports.ApiApplicationFailureAction = ApiApplicationFailureAction;
