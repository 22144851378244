<mc-table
  tableId="message-center/bulk-delivery-table"
  [isLoading]="isLoading"
  [highlightedRow]="selectedRow"
  [columns]="columns"
  [data]="rowData"
  (rowClick)="clickRow($event)"
  translatePrefix="$I18N_MESSAGE_CENTER_BULK_DELIVERY_TABLE.TABLE"
>
  <mc-action-list-dropdown-button
    [disabled]="!isSelectedRow()"
    class="mc-table-actions mc-button-margin-right"
    buttonClass="btn-primary"
    label="$I18N_MESSAGE_CENTER_BULK_DELIVERY_TABLE.ACTIONS.RETRY"
    (defaultAction)="retryMessages()"
  >
    <mc-dropdown-item
      label="$I18N_MESSAGE_CENTER_BULK_DELIVERY_TABLE.ACTIONS.RETRY"
      (click)="retryMessages()"
    >
    </mc-dropdown-item>
    <mc-dropdown-item
      label="$I18N_MESSAGE_CENTER_BULK_DELIVERY_TABLE.ACTIONS.RECALCULATE_ROUTE"
      (click)="recalculateRoute()"
    >
    </mc-dropdown-item>
  </mc-action-list-dropdown-button>

  <mc-action-list-dropdown-button
    [disabled]="!isSelectedRow()"
    class="mc-table-actions mc-button-margin-right"
    buttonClass="btn-primary"
    label="$I18N_MESSAGE_CENTER_BULK_DELIVERY_TABLE.ACTIONS.REJECT"
    (defaultAction)="rejectMessages()"
  >
    <mc-dropdown-item
      label="$I18N_MESSAGE_CENTER_BULK_DELIVERY_TABLE.ACTIONS.REJECT"
      (click)="rejectMessages()"
    >
    </mc-dropdown-item>

    <mc-dropdown-item
      label="$I18N_MESSAGE_CENTER_BULK_DELIVERY_TABLE.ACTIONS.EARLY_BOUNCE"
      (click)="earlyBounceMessages()"
    >
    </mc-dropdown-item>
  </mc-action-list-dropdown-button>

  <button
    class="mc-table-actions btn btn-secondary mc-button-margin-right"
    (click)="exportResults()"
  >
    {{ '$I18N_MESSAGE_CENTER_BULK_DELIVERY_TABLE.ACTIONS.EXPORT' | translate }}
  </button>

  <mc-filters
    [externalTableSearchCount]="externalTableSearchCount"
    [metadata]="metadata"
    [showPagination]="true"
    [pageSizeOptions]="[25, 50, 100]"
    (paginatorChange)="changePaginator($event)"
  >
    <mc-filter-column-select-and-search
      [sections]="searchSections"
      [placeholder]="
        '$I18N_MESSAGE_CENTER_BULK_DELIVERY_TABLE.TABLE.COLUMN_VALUE_SINGLE_SELECT_FILTER_FIELD.PLACEHOLDER'
          | translate
      "
      (filtersChange)="changeSearchFilter($event)"
    >
    </mc-filter-column-select-and-search>

    <div class="mc-filters-right ">
      <mc-filters-bundle-date-range
        [config]="dateRangePickerConfig"
        (filtersChange)="changeDateFilter($event)"
        [useCurrentMoment]="true"
      >
      </mc-filters-bundle-date-range>

      <mc-filters-bundle-column-values
        [sections]="filterSections"
        (filtersChange)="changeColumnFilter($event)"
      >
      </mc-filters-bundle-column-values>
    </div>
  </mc-filters>

  <mc-empty-results
    keyTitle="$I18N_MESSAGE_CENTER_BULK_DELIVERY_TABLE.TABLE.EMPTY_RESULTS"
    iconClass="mc-icon-adcon-icon-stable-no-results-2"
  >
  </mc-empty-results>

  <mc-column-select key="select" (selectChange)="selectRows($event)"></mc-column-select>

  <mc-column-email key="fromHeader" emailKey="emailAddress"></mc-column-email>
  <mc-column-email key="to" emailKey="emailAddress"></mc-column-email>

  <mc-column-date key="created"></mc-column-date>

  <mc-column-email key="fromEnv" emailKey="emailAddress"></mc-column-email>

  <mc-column key="attachment">
    <mc-header-cell *mcHeaderCellDef>
      <i class="far fa-paperclip" aria-hidden="true"></i>
    </mc-header-cell>
    <mc-body-cell *mcBodyCellDef="let row">
      <i *ngIf="row.attachment" class="far fa-paperclip" aria-hidden="true"></i>
    </mc-body-cell>
  </mc-column>

  <mc-column-size key="size"></mc-column-size>
  <mc-column-date key="nextAttempt"></mc-column-date>

  <mc-column-actions
    key="right-column"
    mcShowColumnConfig
    [columnsAlwaysVisible]="columnsAlwaysVisible"
    [columnsToStartHidden]="columnsToStartHidden"
    [columnsToIgnore]="columnsToIgnore"
  >
    <mc-row-actions *mcRowActions="let row">
      <li
        mcRowAction="$I18N_MESSAGE_CENTER_BULK_DELIVERY_TABLE.ACTIONS.VIEW_DETAILS"
        (click)="clickRow(row)"
      ></li>
      <li mcRowActionSeparator></li>
      <li
        mcRowAction="$I18N_MESSAGE_CENTER_BULK_DELIVERY_TABLE.ACTIONS.RETRY"
        (click)="retryMessages([row])"
      ></li>
      <li
        mcRowAction="$I18N_MESSAGE_CENTER_BULK_DELIVERY_TABLE.ACTIONS.RECALCULATE_ROUTE"
        (click)="recalculateRoute([row])"
      ></li>
      <li mcRowActionSeparator></li>
      <li
        mcRowAction="$I18N_MESSAGE_CENTER_BULK_DELIVERY_TABLE.ACTIONS.REJECT"
        (click)="rejectMessages([row])"
      ></li>
      <li
        mcRowAction="$I18N_MESSAGE_CENTER_BULK_DELIVERY_TABLE.ACTIONS.EARLY_BOUNCE"
        (click)="earlyBounceMessages([row])"
      ></li>
    </mc-row-actions>
  </mc-column-actions>
</mc-table>
