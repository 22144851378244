"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./archive-task-list.controller");
require("./archive-task-list.service");
require("./archive-task-list.tpl.html");
require("./less/archive-task-list.less");
(function () {
    'use strict';
    angular
        .module('services.services.backup.archive-task-list', [
        'services.services.backup.archive-task-list.controller',
        'services.services.backup.archive-task-list.service'
    ])
        .config([
        '$stateProvider',
        function ($stateProvider) {
            const breadcrumbs = ['$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ARCHIVE_TASK_MENU_LABEL'];
            $stateProvider.state('archive-task-list', {
                url: '/sync-recover/exchange/tasks',
                data: {
                    breadcrumbs,
                    tabId: 'sync-and-recover-tab',
                    tabTitle: '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ASIDE_MENU_TITLE',
                    asideTitle: '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ASIDE_MENU_TITLE',
                    tabHide: false,
                    tabReload: false
                },
                views: {
                    main: {
                        templateUrl: 'services/services/backup/archive-task/archive-task-list/archive-task-list.tpl.html'
                    },
                    aside: {
                        templateUrl: 'services/services/backup/aside/aside.tpl.html'
                    }
                },
                onEnter: [
                    'windowService',
                    function (windowService) {
                        if (windowService.inIframe()) {
                            breadcrumbs.length = 0;
                        }
                    }
                ]
            });
        }
    ]);
})();
