"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TabsNavComponent = void 0;
class TabsNavComponent {
    constructor(stateService) {
        this.stateService = stateService;
        this.tabs = [];
        this.isChildPage = false;
    }
    ngOnInit() {
        var _a, _b;
        this.activeTab = this.tabs.find(({ link, children }) => link === this.stateService.$state.current.name || (children === null || children === void 0 ? void 0 : children.some(child => child === this.stateService.$state.current.name)));
        this.isChildPage = (_b = (_a = this.activeTab) === null || _a === void 0 ? void 0 : _a.children) === null || _b === void 0 ? void 0 : _b.some(child => child === this.stateService.$state.current.name);
    }
    goTo(fragment) {
        this.stateService.$state.go(fragment);
    }
    trackByTabLink(index, tab) {
        return tab.link;
    }
}
exports.TabsNavComponent = TabsNavComponent;
