<mc-layout-detail-wizard [keyTitle]="wizardTitle">
  <mc-wizard #wizard (changeStep)="onStepChange($event)">
    <mc-wizard-step
      label="{{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.DETAILS_STEP.STEP_TITLE' | translate }}"
    >
      <mc-archive-supervision-wizard-queue-details
        [escalationQueues]="escalationQueues$ | async"
        [loading]="loading$ | async"
        [name]="name"
        [description]="description"
        [type]="type"
        [selectedEscalationQueues]="selectedEscalationQueues"
        [defaultEscalationQueue]="defaultEscalationQueue"
        [isEditMode]="isEditMode"
      >
      </mc-archive-supervision-wizard-queue-details>
    </mc-wizard-step>
    <mc-wizard-step
      label="{{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.SETTINGS_STEP.STEP_TITLE' | translate }}"
    >
      <mc-archive-supervision-wizard-queue-settings
        [wizardData]="wizardData$ | async"
        [loading]="loading$ | async"
        [selectedReviewers]="selectedReviewers$ | async"
        [allReviewers]="allReviewers$ | async"
        [allowPrinting]="allowPrinting"
        [allowForwarding]="allowForwarding"
        [labels]="selectedLabels"
      >
      </mc-archive-supervision-wizard-queue-settings>
    </mc-wizard-step>
    <mc-wizard-step
      *ngIf="!isEditMode"
      label="{{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.SUMMARY_STEP.STEP_TITLE' | translate }}"
    >
      <mc-archive-supervision-wizard-queue-summary [wizardData]="wizardData$ | async">
      </mc-archive-supervision-wizard-queue-summary>
    </mc-wizard-step>
  </mc-wizard>

  <mc-footer-container>
    <div class="pull-right no-select mc-button-footer">
      <button class="btn mc-create-policy-cancel" (click)="onCancel()">
        {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.CONTROLS.CANCEL' | translate }}
      </button>

      <ng-container *ngIf="wizard">
        <button
          class="btn btn-secondary"
          *ngIf="(wizard.hasPreviousStep$ | async)"
          (click)="onPrevious()"
        >
          {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.CONTROLS.PREVIOUS' | translate }}
        </button>
        <button
          class="btn btn-primary"
          [disabled]="(wizard.isLastStep$ | async) || !isCurrentStepValid"
          *ngIf="(wizard.hasNextStep$ | async)"
          (click)="onNext()"
        >
          {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.CONTROLS.NEXT' | translate }}
        </button>
        <button
          *ngIf="(wizard.isLastStep$ | async) || isEditMode"
          [disabled]="
            !(hasEditPermission$ | async) || (isEditMode && !(isCurrentStepValid$ | async))
          "
          class="btn btn-primary"
          (click)="onSave($event)"
        >
          {{ getSaveButtonText() }}
        </button>
      </ng-container>
    </div>
  </mc-footer-container>
</mc-layout-detail-wizard>
