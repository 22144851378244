'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
angular.module('favourite.controller', [])
    .controller('FavouriteCtrl', ['$translate', 'navbarFavouriteService', '$rootScope',
    function ($translate, navbarFavouriteService, $rootScope) {
        const self = this;
        const strClassIconActive = 'fas fa-star active';
        const strClassIconInactive = 'fa-star';
        const strClassIconWaiting = 'fa-circle-notch fa-spin';
        const addTooltip = $translate.instant('$I18N_ADMINISTRATION_ADD_TO_FAVOURITES');
        const removeTooltip = $translate.instant('$I18N_ADMINISTRATION_REMOVE_FROM_FAVOURITES');
        self.toggleFavorite = toggleFavorite;
        $rootScope.$on('megaMenuRender', function () {
            activate();
        });
        function activate() {
            navbarFavouriteService.isFavouriteState().then(function (match) {
                self.favoriteCSS = match ? strClassIconActive : strClassIconInactive;
                self.tooltip = match ? removeTooltip : addTooltip;
            });
        }
        activate();
        function toggleFavorite() {
            self.favoriteCSS = strClassIconWaiting;
            navbarFavouriteService.toggleFavourite().then(function () {
                activate();
            });
        }
    }]);
