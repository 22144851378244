"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AwarenessListComponent = void 0;
const core_1 = require("@angular/core");
class AwarenessListComponent {
    constructor() {
        this.defaultTitle = '$I18N_AWARENESS_LIST_COMPONENT_TITLE';
        this.defaultSubTitle = '$I18N_AWARENESS_LIST_COMPONENT_SUBTITLE';
        this.search = new core_1.EventEmitter();
        this.toggleSort = new core_1.EventEmitter();
        this.deleteTemplate = new core_1.EventEmitter();
    }
    trackByTemplateId(template) {
        return template.id;
    }
}
exports.AwarenessListComponent = AwarenessListComponent;
