"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
(function () {
    'use strict';
    document.createElement('mc-archive-task-actions');
    angular.module('archiveTaskActions.directive', [])
        .directive('mcArchiveTaskActions', ['archiveTaskListService', 'modalActionService', '$state', 'adconBackupService', 'capabilitiesService', 'backupService', function (archiveTaskListService, modalActionService, $state, adconBackupService, capabilitiesService, backupService) {
            return {
                restrict: 'EA',
                templateUrl: 'services/services/backup/components/archive-task-actions/archive-task-actions.tpl.html',
                scope: {
                    archiveTask: '<',
                    updated: '&?'
                },
                link($scope) {
                    $scope.hasPermission = adconBackupService.CONSTANTS.HAS_PERMISSIONS;
                    $scope.STATUS = backupService.constants.STATUS;
                    $scope.showPauseAlert = function (id) {
                        modalActionService.openActionModal(archiveTaskListService.getPauseJson(id, archiveTaskListService.pauseBackupTask)).then(function () {
                            $scope.updated();
                        });
                    };
                    $scope.showDeleteAlert = function (id, name) {
                        modalActionService.openActionModal(archiveTaskListService.getDeleteConfirmationJson(id, name, archiveTaskListService.deleteArchiveTaskAndCloseModal)).then(function (response) {
                            if (response) {
                                $state.go('archive-task-list');
                            }
                        });
                    };
                    $scope.showResumeAlert = function (id) {
                        modalActionService.openActionModal(archiveTaskListService.getResumeJson(id, archiveTaskListService.resumeBackupTask)).then(function () {
                            $scope.updated();
                        });
                    };
                }
            };
        }]);
})();
