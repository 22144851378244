"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CyberGraphPolicyEffects = void 0;
const effects_1 = require("@ngrx/effects");
const actions = require("../actions/cybergraph-policies-create.actions");
const editActions = require("../actions/cybergraph-policies-edit.actions");
const listActions = require("../actions/cybergraph-policies-list.actions");
const operators_1 = require("rxjs/operators");
const rxjs_1 = require("rxjs");
const reducers = require("../reducers");
const notification_actions_1 = require("app-new/components/notification/actions/notification.actions");
class CyberGraphPolicyEffects {
    constructor(stateService, actions$, cyberGraphPoliciesService, notificationService, translateService, store) {
        this.stateService = stateService;
        this.actions$ = actions$;
        this.cyberGraphPoliciesService = cyberGraphPoliciesService;
        this.notificationService = notificationService;
        this.translateService = translateService;
        this.store = store;
        this.goToCreatePage$ = this.actions$.pipe(effects_1.ofType(actions.CYBERGRAPH_OPEN_CREATE_POLICY_PAGE), operators_1.tap(() => {
            this.store.dispatch(new actions.ResetPolicyWizardAction());
            this.stateService.$state.go('create-cybergraph-policy', { isEditFlow: false });
        }));
        this.goToPoliciesPage$ = this.actions$.pipe(effects_1.ofType(actions.CYBERGRAPH_OPEN_POLICIES_PAGE), operators_1.tap(() => {
            this.stateService.$state.go('cybergraph-policies');
        }));
        this.goToEditPage$ = this.actions$.pipe(effects_1.ofType(editActions.CYBERGRAPH_OPEN_EDIT_POLICY_WIZARD), operators_1.switchMap((action) => {
            const policyData = action.payload;
            if (policyData) {
                this.stateService.$state.go('create-cybergraph-policy', {
                    isEditFlow: true
                });
            }
            return rxjs_1.of(new editActions.UpdateCreatePolicyPageInEditMode(this.cyberGraphPoliciesService.mapToWizardState(policyData)));
        }));
        this.getAddressAttributeOptions$ = this.actions$.pipe(effects_1.ofType(actions.GET_ADDRESS_ATTRIBUTE_OPTIONS), operators_1.switchMap(() => {
            return this.cyberGraphPoliciesService.getAddressAttributes().pipe(operators_1.map((response) => {
                return new actions.GetAddressAttributeOptionsSuccessAction(this.cyberGraphPoliciesService.appliesToAddressAttributeResponseToItems(response));
            }), operators_1.catchError(error => rxjs_1.of(this.getNotificationAction(error))));
        }));
        this.createPolicy$ = this.actions$.pipe(effects_1.ofType(actions.CYBERGRAPH_CREATE_POLICY), operators_1.withLatestFrom(this.store.select(reducers.getCreateCyberGraphPolicy)), operators_1.switchMap(([, state]) => {
            return this.cyberGraphPoliciesService
                .createCyberGraphPolicyDefinition(this.cyberGraphPoliciesService.mapToPolicyDefinitionRequest(state))
                .pipe(operators_1.mergeMap(() => this.successActions()), operators_1.catchError(() => this.errorAction()));
        }));
        this.createPolicySuccess$ = this.actions$.pipe(effects_1.ofType(actions.CYBERGRAPH_CREATE_POLICY_SUCCESS), operators_1.map(() => new actions.OpenPoliciesPage()));
        this.editPolicy$ = this.actions$.pipe(effects_1.ofType(editActions.CYBERGRAPH_EDIT_POLICY), operators_1.withLatestFrom(this.store.select(reducers.getCreateCyberGraphPolicy)), operators_1.switchMap(([, state]) => {
            const policyObservable = this.cyberGraphPoliciesService.editCyberGraphPolicyDetails(this.cyberGraphPoliciesService.mapDetailsToData(state.detailsStep.data, state.summaryStep.data));
            const definitionObservable = this.cyberGraphPoliciesService.editCyberGraphPolicyDefinition(this.cyberGraphPoliciesService.mapToPolicyDefinitionRequest(state));
            const rulesObservable = this.cyberGraphPoliciesService.editCyberGraphPolicyRules({
                settingsId: state.detailsStep.data.id,
                rules: this.cyberGraphPoliciesService.mapRulesToData(state.appliesToStep.data)
            });
            return rxjs_1.forkJoin([policyObservable, rulesObservable, definitionObservable]).pipe(operators_1.mergeMap(() => this.editSuccessActions()), operators_1.catchError(() => this.editErrorAction()));
        }));
        this.togglePolicyEnabled$ = this.actions$.pipe(effects_1.ofType(editActions.CYBERGRAPH_TOGGLE_POLICY_ENABLED), operators_1.switchMap((action) => {
            return this.cyberGraphPoliciesService
                .editCyberGraphPolicyDetails({
                id: action.payload.id,
                enabled: action.payload.status
            })
                .pipe(operators_1.mergeMap(() => [
                ...this.editSuccessActions(),
                new listActions.UpdatePolicyDetailsEnableStatus(action.payload)
            ]), operators_1.catchError(() => this.editErrorAction()));
        }));
        this.editPolicySuccess$ = this.actions$.pipe(effects_1.ofType(editActions.CYBERGRAPH_EDIT_POLICY_SUCCESS), operators_1.map(() => new actions.OpenPoliciesPage()));
    }
    successActions() {
        return [
            new actions.CreatePolicySuccessAction(),
            this.notificationService.newNotification({
                type: 'success',
                config: {
                    msg: '$I18N_SERVICES_CYBERGRAPH.CREATE_SUCCESS_MSG'
                }
            })
        ];
    }
    errorAction() {
        return [
            this.notificationService.newNotification({
                type: 'error',
                config: {
                    msg: '$I18N_SERVICES_CYBERGRAPH.CREATE_FAIL_MSG'
                }
            }),
            new actions.OpenPoliciesPage()
        ];
    }
    getNotificationAction(error) {
        const msg = error.fail
            ? error.fail[0].errors[0].message
            : this.translateService.instant('$I18N_CYBERGRAPH_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.GET_ADDRESS_ATTRIBUTES_SERVER_ERROR');
        return new notification_actions_1.NotificationShowAction({
            type: 'error',
            config: {
                msg
            }
        });
    }
    editSuccessActions() {
        return [
            new editActions.EditPolicySuccessAction(),
            this.notificationService.newNotification({
                type: 'success',
                config: {
                    msg: '$I18N_SERVICES_CYBERGRAPH.EDIT_SUCCESS_MSG'
                }
            })
        ];
    }
    editErrorAction() {
        return [
            this.notificationService.newNotification({
                type: 'error',
                config: {
                    msg: '$I18N_SERVICES_CYBERGRAPH.EDIT_FAIL_MSG'
                }
            }),
            new actions.OpenPoliciesPage()
        ];
    }
}
__decorate([
    effects_1.Effect({ dispatch: false })
], CyberGraphPolicyEffects.prototype, "goToCreatePage$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], CyberGraphPolicyEffects.prototype, "goToPoliciesPage$", void 0);
__decorate([
    effects_1.Effect()
], CyberGraphPolicyEffects.prototype, "goToEditPage$", void 0);
__decorate([
    effects_1.Effect()
], CyberGraphPolicyEffects.prototype, "getAddressAttributeOptions$", void 0);
__decorate([
    effects_1.Effect()
], CyberGraphPolicyEffects.prototype, "createPolicy$", void 0);
__decorate([
    effects_1.Effect()
], CyberGraphPolicyEffects.prototype, "createPolicySuccess$", void 0);
__decorate([
    effects_1.Effect()
], CyberGraphPolicyEffects.prototype, "editPolicy$", void 0);
__decorate([
    effects_1.Effect()
], CyberGraphPolicyEffects.prototype, "togglePolicyEnabled$", void 0);
__decorate([
    effects_1.Effect()
], CyberGraphPolicyEffects.prototype, "editPolicySuccess$", void 0);
exports.CyberGraphPolicyEffects = CyberGraphPolicyEffects;
