"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./server-connection-list.controller"); //services.services.server-connections-list.controller
require("./server-connection-list-guard.service");
// html and css
require("./server-connection-list.tpl.html");
(function () {
    'use strict';
    /* ======================================================== *\

   = ServerConnections - List

   \* ======================================================== */
    angular.module('services.services.server-connections-list', ['services.services.server-connections-list.controller', 'services.services.server-connections-list-guard'])
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('server-connections', {
                url: '/services/services/server-connection',
                data: {
                    capabilities: 'Services.ServerConnections && Permission.SERVER_CONNECTIONS_READ',
                    tabId: 'server-connection-tabid',
                    tabHide: false,
                    tabReload: 'true',
                    tabTitle: '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_MENU_LABEL'
                },
                views: {
                    'main': {
                        templateUrl: 'services/services/server-connection/server-connection-list/server-connection-list.tpl.html',
                        resolve: {
                            serverConnections: ['serverConnectionListGuard', function (serverConnectionListGuard) {
                                    return serverConnectionListGuard.getServerConnections();
                                }]
                        }
                    }
                }
            });
        }]);
}());
