"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sumByQueueReviewerProcessedCount = exports.sumByPendingCount = exports.sumByNewInLastSevenDays = exports.sumByTotalCounts = exports.computePercentage = exports.sum = void 0;
const sum = (...numbers) => numbers.reduce((previous, current) => previous + current);
exports.sum = sum;
const computePercentage = (dividend, divisor) => {
    return Math.round((dividend / divisor) * 100);
};
exports.computePercentage = computePercentage;
const sumByTotalCounts = (acc, queue) => acc + queue.totalCount;
exports.sumByTotalCounts = sumByTotalCounts;
const sumByNewInLastSevenDays = (acc, queue) => acc + queue.newInLastSevenDays;
exports.sumByNewInLastSevenDays = sumByNewInLastSevenDays;
const sumByPendingCount = (acc, queue) => acc + queue.pendingCount;
exports.sumByPendingCount = sumByPendingCount;
const sumByQueueReviewerProcessedCount = (acc, reviewerPerformance) => acc + reviewerPerformance.processedCount;
exports.sumByQueueReviewerProcessedCount = sumByQueueReviewerProcessedCount;
