"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgPolicyDeleteConfirmationComponent = void 0;
const policyActions = require("../../../actions/policy.actions");
const sidebar = require("../../../actions/sidebar.actions");
const policy_1 = require("../../../models/policy");
const reducers = require("../../../reducers");
const policy_actions_1 = require("../../../../../services/browser-isolation/policies/actions/policy.actions");
class SwgPolicyDeleteConfirmationComponent {
    constructor(store) {
        this.store = store;
    }
    ngOnInit() {
        this.policy$ = this.store.select(reducers.getSwgPolicy);
        this.isDeleting$ = this.store.select(reducers.getApiIsProcessingDelete);
    }
    onDelete(policyDescription) {
        if (policyDescription.policyType === policy_1.SwgPolicyType.BROWSER_ISOLATION) {
            this.store.dispatch(new policy_actions_1.BiDeletePolicy(policyDescription));
        }
        else {
            this.store.dispatch(new policyActions.SwgDeletePolicy(policyDescription));
        }
    }
    onCancel() {
        this.store.dispatch(new sidebar.SwgDetailDeleteConfirmModelClose());
    }
}
exports.SwgPolicyDeleteConfirmationComponent = SwgPolicyDeleteConfirmationComponent;
