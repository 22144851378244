<mc-layout-aside-extra-container
  *ngIf="!isCreated"
  keyTitle="$I18N_SYNC_RECOVER.COMMON.CREATE_SNAPSHOT.TITLE"
  showClose="true"
  extendClass="mc-sync-recover-exchange-mailbox-snapshot-dialog"
  [isLoading]="false"
  (closeAside)="onClickClose($event)">

  <mc-extra-container>

    <strong class="mc-container-subtitle">{{ createSnapshotPayload.mailbox }}</strong>

    <div class="mc-container-description">{{ '$I18N_SYNC_RECOVER.EXCHANGE.CREATE_SNAPSHOT.DESCRIPTION' | translate }}</div>

    <div class="mc-container-buttons-area">
      <mc-live-button typeClass="mc-btn btn btn-primary" (click)="onClickCreate()" [mcDisabled]="!createSnapshotForm.valid" [isLoading]="isRequesting"
        content="{{ '$I18N_SYNC_RECOVER.COMMON.CREATE_SNAPSHOT.BUTTONS.CREATE' | translate}}"></mc-live-button>
      <button class="mc-btn btn btn-secondary" (click)="onClickClose($event)">{{ '$I18N_SYNC_RECOVER.COMMON.CREATE_SNAPSHOT.BUTTONS.CANCEL' | translate }}</button>
    </div>
    <mc-inline-notification class="mc-create-snapshot-error-container" *ngIf="hasCreateError" [notification]="inlineNotificationConfigError"></mc-inline-notification>
  </mc-extra-container>

  <mc-body-container>

    <!--
    TODO: WE NEED TO STYLE THIS
    -->
    <form [formGroup]="createSnapshotForm" [mcHorizontal]="true" mcErrorPrefix="$I18N_SYNC_RECOVER.COMMON.CREATE_SNAPSHOT.FORM.ERROR.CODE"
      class="mc-create-snapshot-form">
      <div formGroupName="dateTime">
        <mc-date-picker #datepicker label="Date" class="mc-date-time-field col-sm-11" controlContainerClass="col-sm-8" labelClass="col-sm-4 no-padding-left"
          formControlName="date" required [startDate]="startDate" [minDate]="minDate" [maxDate]="maxDate">
        </mc-date-picker>
        <mc-time-picker label="Time" class="mc-date-time-field col-sm-11" controlContainerClass="col-sm-8" labelClass="col-sm-4 no-padding-left"
          required formControlName="time" [horizontal]="horizontal">
        </mc-time-picker>
      </div>
      <mc-errors class="col-sm-7 col-sm-offset-4 mc-date-time-group-error" [errors]="dateTimeGroup.errors" *ngIf="!dateTimeGroup.valid"
        formPrefix="$I18N_SYNC_RECOVER.COMMON.CREATE_SNAPSHOT.FORM.ERROR.CODE"></mc-errors>
      <mc-inline-notification class="mc-field-notification" [notification]="inlineNotificationConfig"></mc-inline-notification>


      <div class="ut-notification-email mc-notification-email">
        <div *ngIf="notificationEmailControls.length > 0">
          <div formArrayName="notificationEmail" class="mc-notification-email-field-container" *ngFor="let control of notificationEmailControls.controls; let i = index;">
            <div [formGroupName]="i">
              <mc-text-field placeholder="$I18N_SYNC_RECOVER.COMMON.CREATE_SNAPSHOT.FORM.FIELDS.EMAIL_NOTIFICATION.PLACEHOLDER" formControlName="email"
                label="$I18N_SYNC_RECOVER.COMMON.CREATE_SNAPSHOT.FORM.FIELDS.EMAIL_NOTIFICATION.LABEL" controlContainerClass="col-sm-8"
                labelClass="col-sm-4 no-padding-left" class="col-sm-11"></mc-text-field>

              <i class="far fa-trash-alt fa-lg trash-icon" (click)="deleteEmailField(i)"></i>
            </div>
          </div>
          <mc-errors class="col-sm-7 col-sm-offset-4 mc-notification-email-group-error" [errors]="notificationEmailControls.errors"
            *ngIf="!notificationEmailControls.valid" formPrefix="$I18N_SYNC_RECOVER.COMMON.CREATE_SNAPSHOT.FORM.ERROR.CODE"></mc-errors>
          <div class="col-sm-7 col-sm-offset-4 no-padding">
            <button class="btn btn-secondary mc-add-email-buttons" [disabled]="isThereAnEmptyEmailField || !notificationEmailControls.valid"
              (click)="addEmailField()">
              {{'$I18N_SYNC_RECOVER.COMMON.CREATE_SNAPSHOT.BUTTONS.ADD_ANOTHER_EMAIL' | translate }}
            </button>
          </div>
        </div>
        <div class="row" *ngIf="notificationEmailControls.length === 0">
          <div class="col-sm-11">
            <label class="control-label col-sm-4">
              <span>{{'$I18N_SYNC_RECOVER.COMMON.CREATE_SNAPSHOT.FORM.FIELDS.EMAIL_NOTIFICATION.LABEL' | translate}}</span>
            </label>
            <div class="col-sm-8">
              <button class="btn btn-secondary mc-add-email-buttons" (click)="addEmailField(overlayData.user)">
                {{ '$I18N_SYNC_RECOVER.COMMON.CREATE_SNAPSHOT.BUTTONS.ADD_EMAIL_FOR_NOTIFICATION' | translate }}
              </button>
            </div>

          </div>
        </div>
      </div>

    </form>
  </mc-body-container>
</mc-layout-aside-extra-container>

<!--
    TODO: Check animation or a live button - Check the best solution
    <mc-layout-aside-simple *ngIf="isCreateClicked && snapShotDetails?.id"
-->
<mc-layout-aside-simple *ngIf="isCreated" keyTitle="$I18N_SYNC_RECOVER.COMMON.CREATE_SNAPSHOT.TITLE"
  showClose="true" [isLoading]="false" (closeAside)="onClickClose($event)">
  <mc-body-container>

    <mc-loader-panel-container keyTitle="{{ '$I18N_SYNC_RECOVER.COMMON.CREATE_SNAPSHOT.WAITING.TITLE' | translate:{snapshotId: snapShotDetails.displayId} }}"
      keyDescription="$I18N_SYNC_RECOVER.COMMON.CREATE_SNAPSHOT.WAITING.DESCRIPTION">
      <mc-loader-panel-container-body-container>
        {{ '$I18N_SYNC_RECOVER.COMMON.CREATE_SNAPSHOT.WAITING.SUGGESTION' | translate }}
        <a class="mc-link" (click)="onClickGoToSnapshotQueue($event)">{{ '$I18N_SYNC_RECOVER.COMMON.CREATE_SNAPSHOT.WAITING.LINK' | translate }}</a>.
      </mc-loader-panel-container-body-container>
    </mc-loader-panel-container>

  </mc-body-container>
</mc-layout-aside-simple>
