<form [formGroup]="formToken">

  <!-- Token ID search -->
  <div class="row">
    <div class="col-xs-11 panel-half-margin-top">
      <mc-text-field label="$I18N_ARCHIVE_VIEWER_SEARCH_TOKEN_ID" formControlName="token"
                     required
                     errorPrefix="$I18N_ARCHIVE_VIEWER_SEARCH_TOKEN_ID" data-test="search_by_token">
        <span translate="$I18N_ARCHIVE_VIEWER_SEARCH_TOKEN_ID"></span>
      </mc-text-field>
    </div>
    <div class="col-xs-1 pull-right panel-double-margin-top">
      <mc-live-button typeClass="btn btn-primary"
                      content="{{ '$I18N_ARCHIVE_VIEWER_SEARCH_BTN' | translate}}"
                      type="submit" (mcClick)="formTokenSearch()"
                      [mcDisabled]="!(reasonValid && tokenValid)"
      ></mc-live-button>
    </div>
  </div>

</form>
