<div class="mc-directory-sync-wizard-container">
  <mc-wizard-step-header [label]="'$I18N_DIRECTORY_SYNC_WIZARD.STEPS.TEST.HEADER'"></mc-wizard-step-header>

  <div class="mc-directory-sync-wizard-sub-header">
    {{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.TEST.SUBHEADER' | translate }}
  </div>

  <mc-directory-sync-integration-test [info]="integration"
                                      [testFromWizard]="true"
                                      (isCreateIntegrationButtonEnabled)="createIntegrationButtonEnabled($event)">
  </mc-directory-sync-integration-test>
</div>
