"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const operators_1 = require("rxjs/operators");
const core_interfaces_1 = require("../../../app-new/core/model/core.interfaces");
angular
    .module('switchAccountInterceptor.service', ['core.service'])
    .factory('switchAccountInterceptor', [
    'CoreService',
    function (coreService) {
        const sessionInjector = {
            request(config) {
                if (isApi(config.url)) {
                    config.data = loadConfigData(config);
                }
                return config;
            }
        };
        function loadConfigData(config) {
            const data = config.data || {};
            const meta = (data && data.meta) || {};
            coreService
                .getContextualAccountSession()
                .pipe(operators_1.first(), core_interfaces_1.isDefined())
                .subscribe(session => {
                meta.accountCode = session.accountcode;
            });
            data.meta = meta;
            return data;
        }
        function isApi(url) {
            return url.toLowerCase().indexOf('/api/') !== -1;
        }
        return sessionInjector;
    }
])
    .config([
    '$httpProvider',
    function ($httpProvider) {
        $httpProvider.interceptors.push('switchAccountInterceptor');
    }
]);
