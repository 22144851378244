"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DriveBrowserComponent = void 0;
const components_1 = require("@mimecast-ui/components");
const contentActions = require("app-new/components/tree/actions/contents.actions");
const foldersActions = require("app-new/components/tree/actions/folders.actions");
const folder_1 = require("app-new/components/tree/model/folder");
const treeReducers = require("app-new/components/tree/reducers");
const status_1 = require("../../../common/models/status");
const status_factory_1 = require("../../../common/factories/status.factory");
const view_type_1 = require("../../models/view-type");
class DriveBrowserComponent {
    constructor(stateService, treeStore) {
        this.stateService = stateService;
        this.treeStore = treeStore;
        this.viewType = 'live';
        this.contentColumns = ['name', 'modified', 'size', 'options'];
        this.deletedColumns = ['name', 'fullPath', 'modified', 'size', 'options'];
        this.nodeStack = [];
        this.sizeConfig = {
            precision: 0,
            smallestUnit: components_1.UnitEnum.KB
        };
        this.statusMessageMap = {
            MAILBOX_NOT_FOUND: '$I18N_ONEDRIVE_DRIVE_BROWSER.STATUS_MESSAGES.ERROR.MAILBOX_NOT_FOUND',
            MAILBOX_ALREADY_SYNCING_IN_A_DIFFERENT_TASK: '$I18N_ONEDRIVE_DRIVE_BROWSER.STATUS_MESSAGES.ERROR.MAILBOX_ALREADY_SYNCING_IN_A_DIFFERENT_TASK',
            ERROR_PERFORMING_BACKUP: '$I18N_ONEDRIVE_DRIVE_BROWSER.STATUS_MESSAGES.ERROR.ERROR_PERFORMING_BACKUP',
            ERROR_ARCHIVING_MESSAGE_CONTENT: '$I18N_ONEDRIVE_DRIVE_BROWSER.STATUS_MESSAGES.ERROR.ERROR_ARCHIVING_MESSAGE_CONTENT',
            NO_ADDRESSES_TO_BACKUP: '$I18N_ONEDRIVE_DRIVE_BROWSER.STATUS_MESSAGES.ERROR.NO_ADDRESSES_TO_BACKUP',
            BACKUP_DEFINITION_INTEGRITY_ERROR: '$I18N_ONEDRIVE_DRIVE_BROWSER.STATUS_MESSAGES.ERROR.BACKUP_DEFINITION_INTEGRITY_ERROR',
            AUTHENTICATION_FAILURE: '$I18N_ONEDRIVE_DRIVE_BROWSER.STATUS_MESSAGES.ERROR.AUTHENTICATION_FAILURE',
            ERROR_PROCESSING_METADATA: '$I18N_ONEDRIVE_DRIVE_BROWSER.STATUS_MESSAGES.ERROR.ERROR_PROCESSING_METADATA'
        };
        this.driveSummary = this.stateService.$state.params.drive;
    }
    ngOnInit() {
        this.driveContents$ = this.treeStore.select(treeReducers.getContents);
        this.driveContentsLoading$ = this.treeStore.select(treeReducers.contentsIsLoading);
        this.driveRootFolder$ = this.treeStore.select(treeReducers.getRootFolder);
        this.contentVisibilityStatus = !(this.driveSummary.lastStatus === status_1.STATUS_TYPES.pending ||
            this.driveSummary.lastStatus === status_1.STATUS_TYPES.in_progress);
    }
    nodeClicked(node) {
        if (node instanceof folder_1.Folder) {
            this.dispatchDriveContents(node);
            this.pushNodeToTrace(node);
        }
    }
    dispatchDriveContents(node) {
        const PAYLOAD = {
            owner: this.getMail(),
            viewType: this.viewType
        };
        if (node && node.getId()) {
            PAYLOAD.parentId = node.getId();
        }
        this.treeStore.dispatch(new contentActions.LoadOneDriveFilesAction(PAYLOAD));
    }
    dispatchDriveRootFolder() {
        this.treeStore.dispatch(new foldersActions.LoadOneDriveRootFolder({
            owner: this.getMail(),
            viewType: this.viewType
        }));
    }
    pushNodeToTrace(node) {
        this.nodeStack = [...this.nodeStack, node];
    }
    getMail() {
        return this.driveSummary && this.driveSummary.mailbox;
    }
    getLastStatus() {
        return (this.driveSummary &&
            status_factory_1.StatusFactory[this.driveSummary.lastStatus](this.driveSummary.lastStatus === 'error' && this.driveSummary.statusMessage
                ? { statusMessage: this.statusMessageMap[this.driveSummary.statusMessage] }
                : undefined));
    }
    getTaskName() {
        return (this.driveSummary &&
            this.driveSummary.backupTaskSettings &&
            this.driveSummary.backupTaskSettings.description);
    }
    getName() {
        return this.driveSummary && this.driveSummary.displayName;
    }
    getDrive() {
        return this.driveSummary && this.driveSummary.mailbox;
    }
    getFirstSync() {
        return this.driveSummary && this.driveSummary.firstStarted;
    }
    getLastSync() {
        return this.driveSummary && this.driveSummary.lastActive;
    }
    getQuotaUsed() {
        return this.driveSummary && this.driveSummary.storageUsed;
    }
    initializeSelectedTab(tab) {
        switch (tab.name) {
            case '$I18N_ONEDRIVE_DRIVE_BROWSER.TABS.LASTSYNC.LABEL': {
                this.viewType = view_type_1.ViewType.LAST_SYNC;
                break;
            }
            case '$I18N_ONEDRIVE_DRIVE_BROWSER.TABS.ARCHIVE.LABEL': {
                this.viewType = view_type_1.ViewType.ARCHIVE;
                break;
            }
            case '$I18N_ONEDRIVE_DRIVE_BROWSER.TABS.DELETED_FILES.LABEL': {
                this.viewType = view_type_1.ViewType.DELETED;
                break;
            }
        }
        this.dispatchCleanupActions();
        this.dispatchDriveContents();
        this.dispatchDriveRootFolder();
    }
    dispatchCleanupActions() {
        this.treeStore.dispatch(new contentActions.CleanStore());
        this.nodeStack = [];
    }
    getDriveShowContent() {
        return this.contentVisibilityStatus;
    }
    getDriveShowTab() {
        return this.contentVisibilityStatus ? 0 : 3;
    }
    getDriveEmptyResultMessage() {
        let messageContent;
        switch (this.driveSummary.lastStatus) {
            case status_1.STATUS_TYPES.pending:
                messageContent = {
                    title: '$I18N_ONEDRIVE_DRIVE_BROWSER.EMPTY_RESULTS.PENDING.TITLE',
                    description: '$I18N_ONEDRIVE_DRIVE_BROWSER.EMPTY_RESULTS.PENDING.DESCRIPTION'
                };
                break;
            case status_1.STATUS_TYPES.in_progress:
                messageContent = {
                    title: '$I18N_ONEDRIVE_DRIVE_BROWSER.EMPTY_RESULTS.IN_PROGRESS.TITLE',
                    description: '$I18N_ONEDRIVE_DRIVE_BROWSER.EMPTY_RESULTS.IN_PROGRESS.DESCRIPTION'
                };
                break;
        }
        return messageContent;
    }
}
exports.DriveBrowserComponent = DriveBrowserComponent;
