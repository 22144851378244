<mc-layout-dashboard-simple keyTitle="$I18N_SWG_DASHBOARD_TITLE">
  <mc-help-container>
    <mc-help-link url="https://community.mimecast.com/docs/DOC-3183"></mc-help-link>
  </mc-help-container>

  <mc-body-container>
    <div class="row">
      <div *ngIf="showNotification" class="col-xs-6 panel-half-padding-right">
        <mc-notifications-feed [notifications]="notifications | async"> </mc-notifications-feed>
      </div>
      <div [ngClass]="showNotification ? 'col-xs-6 panel-half-padding-left' : 'col-xs-12'">
        <div class="mc-list-panel">
          <div class="mc-list-panel-heading">
            {{ '$I18N_SWG_DASHBOARD_ACTIVITY_CHART' | translate }}
          </div>
          <div class="row">
            <div class="col-xs-12">
              <div class="row">
                <div class="col-xs-12">
                  <div class="small-toolbar border-bottom no-padding-right">

                    <div>
                  <span *ngIf="!isActivityChartLoading; else loadingTotalRequest">
                    {{ activityChartTotalRequests | number }}
                  </span>
                  <span>
                        {{ '$I18N_SWG_DASHBOARD_TOTAL_REQUESTS_7_DAYS' | translate }}
                  </span>
                      <ng-template #loadingTotalRequest>
                        <mc-spinner></mc-spinner>
                      </ng-template>
                      <div class="pull-right">
                        <mc-list-dropdown-button
                          disableCaret="true"
                          inline="true"
                          align="center"
                          class="mc-chart-controls mc-chart-download-button"
                        >
                          <mc-dropdown-item
                            [label]="format.label"
                            (click)="exportChart('activityChart', format.type, 'activity_chart')"
                            *ngFor="let format of exportFormats"
                          ></mc-dropdown-item>
                        </mc-list-dropdown-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12">
                  <div>
                    <mc-chart class="mc-top-ten-chart" *ngIf="!isActivityChartLoading; else loadingActivityChart" >
                      <mc-multi-line-graph-chart
                        #activityChart
                        class="mc-amchart"
                        [config]="activityChartConfig"
                        [chartData]="activityChartData">
                      </mc-multi-line-graph-chart>
                    </mc-chart>
                    <ng-template #loadingActivityChart>
                      <mc-spinner class="mc-chart-widget-spinner"></mc-spinner>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-6 panel-half-padding-right">
        <div class="mc-list-panel">
          <div class="mc-list-panel-heading">
            {{ '$I18N_SWG_DASHBOARD_TOP_10_DOMAINS' | translate }}
          </div>
          <div class="small-toolbar border-bottom no-padding-right">
            <span *ngIf="!isTopDomainsChartLoading; else loadingTopDomainsTotalRequest">
                    {{ topDomainsChartTotalRequests | number }}
                  </span>
            <span>
                        {{ '$I18N_SWG_DASHBOARD_TOTAL_REQUESTS_7_DAYS' | translate }}
                  </span>
            <ng-template #loadingTopDomainsTotalRequest>
              <mc-spinner></mc-spinner>
            </ng-template>

            <div class="pull-right">
              <mc-list-dropdown-button
                disableCaret="true"
                inline="true"
                align="center"
                class="mc-chart-controls mc-chart-download-button"
              >
                <mc-dropdown-item
                  [label]="format.label"
                  (click)="exportChart('topDomainsChart', format.type, 'top10_domains_chart')"
                  *ngFor="let format of exportFormats"
                ></mc-dropdown-item>
              </mc-list-dropdown-button>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12">
              <mc-chart class="mc-top-ten-chart" *ngIf="!isTopDomainsChartLoading; else loadingTopDomainsChart" >
                <mc-bar-chart #topDomainsChart [config]="topDomainsChartConfig" [chartData]="topDomainsChartData" class="mc-amchart"></mc-bar-chart>
              </mc-chart>
              <ng-template #loadingTopDomainsChart>
                <mc-spinner class="mc-chart-widget-spinner"></mc-spinner>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-6 panel-half-padding-left">
        <div class="mc-list-panel">
          <div class="mc-list-panel-heading">
            {{ '$I18N_SWG_DASHBOARD_TOP_10_CATEGORIES' | translate }}
          </div>
          <div class="small-toolbar border-bottom no-padding-right">
            <span *ngIf="!isTopCategoriesChartLoading; else loadingTopCategoriesTotalRequest">
                    {{ topCategoriesChartTotalRequests | number }}
                  </span>
            <span>
                        {{ '$I18N_SWG_DASHBOARD_TOTAL_REQUESTS_7_DAYS' | translate }}
                  </span>
            <ng-template #loadingTopCategoriesTotalRequest>
              <mc-spinner></mc-spinner>
            </ng-template>

            <mc-list-dropdown-button
              disableCaret="true"
              inline="true"
              align="center"
              class="mc-chart-controls mc-chart-download-button"
            >
              <mc-dropdown-item
                [label]="format.label"
                (click)="exportChart('topCategoriesChart', format.type, 'top10_Categories_chart')"
                *ngFor="let format of exportFormats"
              ></mc-dropdown-item>
            </mc-list-dropdown-button>
          </div>
          <div class="row">
            <div class="col-xs-12">
              <mc-chart class="mc-top-ten-chart" *ngIf="!isTopCategoriesChartLoading; else loadingTopCategoriesChart" >
                <mc-bar-chart #topCategoriesChart [config]="topCategoriesChartConfig" [chartData]="topCategoriesChartData" class="mc-amchart"></mc-bar-chart>
              </mc-chart>
              <ng-template #loadingTopCategoriesChart>
                <mc-spinner class="mc-chart-widget-spinner"></mc-spinner>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-6 panel-half-padding-right">
        <div class="mc-list-panel">
          <div class="mc-list-panel-heading">
            {{ '$I18N_SWG_DASHBOARD_TOP_10_BLOCKED_DOMAINS' | translate }}
          </div>
          <div class="small-toolbar border-bottom no-padding-right">
            <span *ngIf="!isTopBlockedDomainsChartLoading; else loadingTopBlockedDomainsTotalRequest">
                    {{ topBlockedDomainsChartTotalRequests | number }}
                  </span>
            <span>
                        {{ '$I18N_SWG_DASHBOARD_TOTAL_REQUESTS_7_DAYS' | translate }}
                  </span>
            <ng-template #loadingTopBlockedDomainsTotalRequest>
              <mc-spinner></mc-spinner>
            </ng-template>

            <mc-list-dropdown-button
              disableCaret="true"
              inline="true"
              align="center"
              class="mc-chart-controls mc-chart-download-button"
            >
              <mc-dropdown-item
                [label]="format.label"
                (click)="exportChart('topBlockedDomainsChart', format.type, 'top10_Blocked_domains_chart')"
                *ngFor="let format of exportFormats"
              ></mc-dropdown-item>
            </mc-list-dropdown-button>
          </div>
          <div class="row">
            <div class="col-xs-12">
              <mc-chart class="mc-top-ten-chart" *ngIf="!isTopBlockedDomainsChartLoading; else loadingTopBlockedDomainsChart" >
                <mc-bar-chart #topBlockedDomainsChart [config]="topBlockedDomainsChartConfig" [chartData]="topBlockedDomainsChartData" class="mc-amchart"></mc-bar-chart>
              </mc-chart>
              <ng-template #loadingTopBlockedDomainsChart>
                <mc-spinner class="mc-chart-widget-spinner"></mc-spinner>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-6 panel-half-padding-left">
        <div class="mc-list-panel">
          <div class="mc-list-panel-heading">
            {{ '$I18N_SWG_DASHBOARD_TOP_10_BLOCKED_CATEGORIES' | translate }}
          </div>
          <div class="small-toolbar border-bottom no-padding-right">
            <span *ngIf="!isTopBlockedCategoriesChartLoading; else loadingTopBlockedCategoriesTotalRequest">
                    {{ topBlockedCategoriesChartTotalRequests | number }}
                  </span>
            <span>
                        {{ '$I18N_SWG_DASHBOARD_TOTAL_REQUESTS_7_DAYS' | translate }}
                  </span>
            <ng-template #loadingTopBlockedCategoriesTotalRequest>
              <mc-spinner></mc-spinner>
            </ng-template>

            <mc-list-dropdown-button
              disableCaret="true"
              inline="true"
              align="center"
              class="mc-chart-controls mc-chart-download-button"
            >
              <mc-dropdown-item
                [label]="format.label"
                (click)="exportChart('topBlockedCategoriesChart', format.type, 'top10_Blocked_Categories_chart')"
                *ngFor="let format of exportFormats"
              ></mc-dropdown-item>
            </mc-list-dropdown-button>
          </div>
          <div class="row">
            <div class="col-xs-12">
              <mc-chart class="mc-top-ten-chart" *ngIf="!isTopBlockedCategoriesChartLoading; else loadingTopBlockedCategoriesChart" >
                <mc-bar-chart #topBlockedCategoriesChart [config]="topBlockedCategoriesChartConfig" [chartData]="topBlockedCategoriesChartData" class="mc-amchart"></mc-bar-chart>
              </mc-chart>
              <ng-template #loadingTopBlockedCategoriesChart>
                <mc-spinner class="mc-chart-widget-spinner"></mc-spinner>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mc-body-container>
</mc-layout-dashboard-simple>
