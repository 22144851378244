"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IntegrationsEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const actions = require("../../actions");
const notification_actions_1 = require("../../../components/notification/actions/notification.actions");
const directory_sync_aside_1 = require("../../components/directory-sync-aside");
const core = require("../../../core/reducers");
const reducers = require("../../reducers");
const ui = require("../../../core/actions/ui.actions");
const confirmation_modal_1 = require("../../modals/confirmation-modal");
const sync_report_modal_1 = require("../../modals/sync-report-modal");
const common_1 = require("../../common/common");
const mse_message_modal_1 = require("../../modals/mse-message-modal");
const asideConfig = {
    minWidth: 620,
    hasBackdrop: true,
    resizable: true,
    disableClose: true
};
class IntegrationsEffects {
    constructor(actions$, translateService, directorySyncService, stateService, store, asideService, modalService, archiveApiService, connectorsApiService, windowService) {
        this.actions$ = actions$;
        this.translateService = translateService;
        this.directorySyncService = directorySyncService;
        this.stateService = stateService;
        this.store = store;
        this.asideService = asideService;
        this.modalService = modalService;
        this.archiveApiService = archiveApiService;
        this.connectorsApiService = connectorsApiService;
        this.windowService = windowService;
        this.getAllDirectorySyncIntegrations$ = this.actions$.pipe(effects_1.ofType(actions.GET_ALL_DIRECTORY_INTEGRATIONS), operators_1.switchMap((action) => {
            return this.directorySyncService.getIntegrations(action.pagination, action.filters).pipe(operators_1.switchMap((response) => [
                new actions.GetAllDirectorySyncIntegrationsSuccessAction(response),
                new actions.GetSyncStatusAction()
            ]), operators_1.catchError(() => rxjs_1.of(new actions.DirectorySyncIntegrationsFailureAction('$I18N_DIRECTORY_SYNC_PAGE.NOTIFICATIONS.ERRORS.INTEGRATIONS'), new actions.GetSyncStatusAction())));
        }));
        this.generalFailure$ = this.actions$.pipe(effects_1.ofType(actions.DIRECTORY_INTEGRATIONS_FAILURE), operators_1.switchMap((action) => {
            return [
                new notification_actions_1.NotificationShowAction({
                    type: 'error',
                    config: { msg: this.translateService.instant(action.payload) }
                })
            ];
        }));
        this.cancelCreateIntegrationWizard$ = this.actions$.pipe(effects_1.ofType(actions.CANCEL_CREATE_DIRECTORY_INTEGRATION_WIZARD), operators_1.tap(() => {
            this.stateService.$state.go('directory-sync');
        }));
        this.openAside$ = this.actions$.pipe(effects_1.ofType(actions.PAGE_INTEGRATIONS_OPEN_ASIDE), operators_1.filter(() => !this.asideInstance), operators_1.withLatestFrom(this.store.select(core.getAdminPreferences)), operators_1.tap(([action, adminPrefs]) => {
            this.asideInstance = this.asideService.open(directory_sync_aside_1.DirectorySyncAsideComponent, Object.assign(Object.assign(Object.assign(Object.assign({}, asideConfig), { position: 'right', size: 'lg' }), (!!adminPrefs && !!adminPrefs.uiAsidePanelWidth
                ? { width: adminPrefs.uiAsidePanelWidth }
                : {})), { data: {
                    integration: action.aside.integration,
                    pageSize: action.aside.pageSize,
                    mseSites: action.aside.mseSites
                } }));
        }), operators_1.tap(() => {
            this.resizeSubscription = this.asideInstance.onResized().subscribe((width) => {
                this.store.dispatch(new ui.RightAsidePanelResizedAction(width));
            });
        }), operators_1.tap(() => {
            this.asideInstance.beforeClose().subscribe(() => {
                this.resizeSubscription.unsubscribe();
                this.asideInstance = undefined;
                this.store.dispatch(new actions.PageIntegrationsCloseAsideAction());
                this.directorySyncService.cancelTestConnection();
            });
        }));
        this.createIntegration$ = this.actions$.pipe(effects_1.ofType(actions.CREATE_INTEGRATION), operators_1.switchMap((action) => {
            return this.directorySyncService.createIntegration(action.payload).pipe(operators_1.switchMap(() => {
                const result = [];
                const successNotification = new notification_actions_1.NotificationShowAction({
                    type: 'success',
                    config: {
                        msg: this.translateService.instant('$I18N_DIRECTORY_SYNC_WIZARD.SUCCESSES.CREATE')
                    }
                });
                if (action.isOnboardingRedirectEnabled) {
                    result.push(successNotification, new actions.RedirectToOnboardingAction());
                }
                else {
                    result.push(successNotification, new actions.CancelCreateDirectoryIntegrationWizardAction());
                }
                return result;
            }), operators_1.catchError(error => rxjs_1.of(new notification_actions_1.NotificationShowAction({
                type: 'error',
                config: {
                    msg: this.getNotificationMessage(error)
                }
            }))));
        }));
        this.deleteIntegration$ = this.actions$.pipe(effects_1.ofType(actions.DELETE_INTEGRATION), operators_1.withLatestFrom(this.store.select(reducers.getFilters)), operators_1.switchMap(([action, filters]) => {
            return this.directorySyncService.deleteIntegration(action.payload).pipe(operators_1.switchMap(() => [
                new notification_actions_1.NotificationShowAction({
                    type: 'success',
                    config: {
                        msg: this.translateService.instant('$I18N_DIRECTORY_SYNC_WIZARD.SUCCESSES.DELETE')
                    }
                }),
                new actions.GetAllDirectorySyncIntegrationsAction({
                    pageSize: action.pageSize
                }, filters)
            ]), operators_1.catchError(error => rxjs_1.of(new notification_actions_1.NotificationShowAction({
                type: 'error',
                config: {
                    msg: this.deleteIntegrationFailMessage(error)
                }
            }))));
        }));
        this.updateIntegration$ = this.actions$.pipe(effects_1.ofType(actions.UPDATE_INTEGRATION), operators_1.switchMap((action) => {
            return this.directorySyncService.updateIntegration(action.integration).pipe(operators_1.switchMap(() => [
                new notification_actions_1.NotificationShowAction({
                    type: 'success',
                    config: {
                        msg: this.translateService.instant('$I18N_DIRECTORY_SYNC_WIZARD.SUCCESSES.UPDATE')
                    }
                }),
                new actions.CancelCreateDirectoryIntegrationWizardAction()
            ]), operators_1.catchError(error => rxjs_1.of(new notification_actions_1.NotificationShowAction({
                type: 'error',
                config: {
                    msg: this.getNotificationMessage(error)
                }
            }))));
        }));
        this.updateIntegrationStatus$ = this.actions$.pipe(effects_1.ofType(actions.UPDATE_INTEGRATION_STATUS), operators_1.withLatestFrom(this.store.select(reducers.getFilters)), operators_1.switchMap(([action, filters]) => {
            return this.directorySyncService.updateIntegration(action.integration).pipe(operators_1.switchMap(() => [
                new notification_actions_1.NotificationShowAction({
                    type: 'success',
                    config: {
                        msg: this.translateService.instant('$I18N_DIRECTORY_SYNC_WIZARD.SUCCESSES.UPDATE')
                    }
                }),
                new actions.GetAllDirectorySyncIntegrationsAction({
                    pageSize: action.pageSize
                }, filters),
                new actions.UpdateIntegrationStatusSuccessAction(Object.assign(Object.assign({}, action.integration), { domains: action.integration.domains[0] }))
            ]), operators_1.catchError(() => rxjs_1.of(new notification_actions_1.NotificationShowAction({
                type: 'error',
                config: {
                    msg: this.translateService.instant('$I18N_DIRECTORY_SYNC_WIZARD.ERRORS.UPDATE')
                }
            }))));
        }));
        this.syncAllIntegrations$ = this.actions$.pipe(effects_1.ofType(actions.SYNC_ALL_INTEGRATIONS), operators_1.switchMap(() => {
            return this.directorySyncService.syncAllIntegrations().pipe(operators_1.switchMap((response) => [
                this.syncAllMessage(response),
                new actions.SyncAllIntegrationsSuccessAction(response),
                new actions.GetSyncStatusAction()
            ]), operators_1.catchError(() => rxjs_1.of(new notification_actions_1.NotificationShowAction({
                type: 'error',
                config: {
                    msg: this.translateService.instant('$I18N_DIRECTORY_SYNC_TABLE.SYNC_ALL_INTEGRATIONS_FAILURE')
                }
            }), new actions.SyncAllIntegrationsFailureAction(), new actions.GetSyncStatusAction())));
        }));
        this.showConfirmationModal$ = this.actions$.pipe(effects_1.ofType(actions.SHOW_CONFIRMATION_MODAL), operators_1.tap((action) => {
            if (!(this.modalInstance && this.modalInstance.getBackdropElement())) {
                this.modalInstance = this.modalService.open(confirmation_modal_1.ConfirmationModalComponent, {
                    size: 'lg',
                    hasBackdrop: true,
                    disableClose: true,
                    data: Object.assign({}, action.data)
                });
            }
        }));
        this.searchDirectory$ = this.actions$.pipe(effects_1.ofType(actions.SEARCH_DIRECTORY), operators_1.switchMap((action) => {
            return this.directorySyncService.searchDirectory(action.payload).pipe(operators_1.map((response) => new actions.SearchDirectorySuccessAction(response.results)), operators_1.catchError(() => rxjs_1.of(new notification_actions_1.NotificationShowAction({
                type: 'error',
                config: {
                    msg: this.translateService.instant('$I18N_DIRECTORY_SYNC_ASIDE_TAB_DIRECTORY_BROWSER.SEARCH_API_FAILURE')
                }
            }), new actions.SearchDirectoryFailAction())));
        }));
        this.synchronizationReport$ = this.actions$.pipe(effects_1.ofType(actions.SYNCHRONIZATION_REPORT), operators_1.switchMap((action) => {
            return this.directorySyncService.synchronizationReport().pipe(operators_1.switchMap((response) => {
                if (!response || !response.reportSummary) {
                    if (action.downloadReport) {
                        throw new Error();
                    }
                    else {
                        return [new actions.SynchronizationReportAvailableAction(false)];
                    }
                }
                if (action.downloadReport) {
                    return [
                        new actions.ShowSynchronizationReportModalAction(response),
                        new actions.SynchronizationReportSuccessAction()
                    ];
                }
                return [new actions.SynchronizationReportAvailableAction(true)];
            }), operators_1.catchError(() => rxjs_1.of(new notification_actions_1.NotificationShowAction({
                type: 'error',
                config: {
                    msg: this.translateService.instant('$I18N_DIRECTORY_SYNC_TABLE.SYNC_REPORT_API_FAILURE')
                }
            }), new actions.SynchronizationReportFailAction())));
        }));
        this.showSynchronization$ = this.actions$.pipe(effects_1.ofType(actions.SHOW_SYNCHRONIZATION_REPORT_MODAL), operators_1.tap((action) => {
            if (!(this.reportModalInstance && this.reportModalInstance.getBackdropElement())) {
                this.reportModalInstance = this.modalService.open(sync_report_modal_1.SyncReportModalComponent, {
                    size: 'lg',
                    hasBackdrop: true,
                    disableClose: true,
                    data: Object.assign({}, action.report)
                });
            }
        }));
        this.synchronizationFullReport$ = this.actions$.pipe(effects_1.ofType(actions.SYNCHRONIZATION_FULL_REPORT), operators_1.tap((action) => {
            const getFileApiUrl = `/api/file/get-file/${action.id}?forceDownload=true`;
            this.archiveApiService.triggerDownload(getFileApiUrl);
        }), operators_1.map(() => new notification_actions_1.NotificationShowSuccessAction('$I18N_EXPORT_API_DOWNLOAD_WILL_START_SHORTLY')));
        this.openWizard$ = this.actions$.pipe(effects_1.ofType(actions.OPEN_WIZARD), operators_1.switchMap((action) => {
            return this.directorySyncService.getMSESites().pipe(operators_1.switchMap((response) => {
                const params = {
                    isEditMode: action.editMode,
                    mseSites: response
                };
                if (action.integration) {
                    params.integration = this.getEditIntegrationValues(action.integration);
                }
                this.stateService.$state.go('directory-sync-wizard', params, {
                    reload: true,
                    inherit: false,
                    notify: true
                });
                return rxjs_1.EMPTY;
            }));
        }));
        this.getSyncStatus$ = this.actions$.pipe(effects_1.ofType(actions.GET_SYNC_STATUS), operators_1.switchMap(() => {
            return this.directorySyncService.getSyncStatus().pipe(operators_1.map((response) => new actions.GetSyncStatusSuccessAction(response)), operators_1.catchError(() => rxjs_1.of(new actions.GetSyncStatusFailAction())));
        }));
        this.openSidePanel$ = this.actions$.pipe(effects_1.ofType(actions.OPEN_ASIDE), operators_1.switchMap((action) => {
            const params = {
                integration: action.aside.integration,
                pageSize: action.aside.pageSize
            };
            if (action.aside.integration.serverType !== 'ad_push') {
                return [new actions.PageIntegrationsOpenAsideAction(params)];
            }
            return this.directorySyncService
                .getMSESites()
                .pipe(operators_1.map((response) => new actions.PageIntegrationsOpenAsideAction(Object.assign(Object.assign({}, params), { mseSites: response }))));
        }));
        this.showMseMessageModal$ = this.actions$.pipe(effects_1.ofType(actions.SHOW_MSE_MESSAGE_MODAL), operators_1.tap((action) => {
            this.modalService.open(mse_message_modal_1.MseMessageModalComponent, {
                size: 'lg',
                hasBackdrop: true,
                disableClose: true,
                data: { message: action.message }
            });
        }));
        this.getProducts$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(actions.GET_PRODUCTS), operators_1.switchMap(() => {
                return this.connectorsApiService.getProducts().pipe(operators_1.map((response) => {
                    if (response.hasErrors) {
                        return new actions.GetProductsActionFail();
                    }
                    return new actions.GetProductsActionSuccess(response.first.products);
                }), operators_1.catchError(() => {
                    return rxjs_1.of(new actions.GetProductsActionFail());
                }));
            }));
        });
        this.getConnectors$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(actions.GET_CONNECTORS), operators_1.withLatestFrom(this.store.select(reducers.getWizardIntegration)), operators_1.switchMap(([action, integration]) => {
                return this.directorySyncService
                    .getAzureHecateConnectors(action.payload, integration.type)
                    .pipe(operators_1.map((response) => {
                    if (response.hasErrors) {
                        return new actions.GetConnectorsActionFail();
                    }
                    return new actions.GetConnectorsActionSuccess(response.first.connectors);
                }), operators_1.catchError(() => {
                    return rxjs_1.of(new actions.GetConnectorsActionFail());
                }));
            }));
        });
        this.getProviders$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(actions.GET_PROVIDERS), operators_1.switchMap((action) => {
                return this.connectorsApiService.getProviders(action.payload).pipe(operators_1.map((response) => {
                    if (response.hasErrors) {
                        return new actions.GetProvidersActionFail();
                    }
                    return new actions.GetProvidersActionSuccess(response.first.providers);
                }), operators_1.catchError(() => {
                    return rxjs_1.of(new actions.GetProvidersActionFail());
                }));
            }));
        });
        this.getAzureHecateConsentConfig$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(actions.GET_CONSENT_CONFIG), operators_1.switchMap((action) => {
                return this.connectorsApiService.getConsentConfig(action.payload).pipe(operators_1.map((response) => {
                    if (response.hasErrors) {
                        return new actions.GetConsentConfigActionFail();
                    }
                    return new actions.GetConsentConfigActionSuccess(response.first);
                }), operators_1.catchError(() => {
                    return rxjs_1.of(new actions.GetConsentConfigActionFail(), new notification_actions_1.NotificationShowAction({
                        type: 'error',
                        config: {
                            msg: this.translateService.instant('$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.AZURE.STD.ERR_AZURE_HECATE_CONSENT_REQUEST')
                        }
                    }));
                }));
            }));
        });
        this.createConnector$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(actions.CREATE_CONNECTOR), operators_1.withLatestFrom(this.store.select(reducers.getWizardIntegration)), operators_1.switchMap(([action, integration]) => {
                return this.connectorsApiService.createConnector(action.payload).pipe(operators_1.map((response) => {
                    if (response.hasErrors) {
                        throw new Error();
                    }
                    if (action.isEditMode) {
                        return new actions.UpdateIntegrationAction(this.buildUpdateAzureHecatePayload(response.first.id, integration));
                    }
                    else {
                        return new actions.CreateIntegrationAction(this.buildCreateAzureHecatePayload(response.first.id, integration), false);
                    }
                }), operators_1.catchError(() => {
                    return rxjs_1.of(new notification_actions_1.NotificationShowAction({
                        type: 'error',
                        config: {
                            msg: this.translateService.instant('$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.AZURE.STD.ERR_CREATE_CONNECTOR')
                        }
                    }));
                }));
            }));
        });
        this.redirectToOnboarding$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(actions.REDIRECT_TO_ONBOARDING), operators_1.tap(() => {
                const url = this.directorySyncService.getOnboardingRedirectUrl();
                this.windowService.replaceParentLocation(url);
            }));
        }, { dispatch: false });
    }
    buildCreateAzureHecatePayload(id, integration) {
        return {
            description: integration.name,
            serverType: 'office_365',
            acknowledgeDisabledAccounts: integration.acknowledgeDisabledAccounts,
            domains: integration.emailDomainsFilter ? [integration.domains] : [],
            info: integration.description,
            deleteUsers: integration.deleteUsers,
            maxUnlink: integration.maxSyncDeletion,
            enabled: integration.status,
            syncContacts: integration.includeContacts,
            office365Settings: {
                serverSubtype: integration.type === 'azure_standard' ? 'standard' : 'gov',
                syncGuestUsers: integration.includeGuestAccounts,
                connectorId: id
            }
        };
    }
    buildUpdateAzureHecatePayload(id, integration) {
        const result = {
            description: integration.name,
            serverType: 'office_365',
            acknowledgeDisabledAccounts: integration.acknowledgeDisabledAccounts,
            domains: integration.emailDomainsFilter ? [integration.domains] : [],
            info: integration.description,
            deleteUsers: integration.deleteUsers,
            maxUnlink: integration.maxSyncDeletion,
            enabled: integration.status,
            syncContacts: integration.includeContacts,
            office365Settings: {
                serverSubtype: integration.type === 'azure_standard' ? 'standard' : 'gov',
                syncGuestUsers: integration.includeGuestAccounts,
                connectorId: id
            }
        };
        if (integration.id) {
            result.id = integration.id;
        }
        if (integration.lastOutcome) {
            result.status = integration.lastOutcome;
        }
        return result;
    }
    getNotificationMessage(error) {
        const transKey = '$I18N_DIRECTORY_SYNC_WIZARD.ERRORS';
        const errCode = error.fail[0].errors[0].code.toUpperCase();
        let msg = '$I18N_DIRECTORY_SYNC_WIZARD.ERRORS.ERR_VALIDATION_GENERIC';
        common_1.validTranslationKeys.forEach(key => {
            if (errCode === key) {
                msg = `${transKey}.${errCode}`;
            }
        });
        return this.translateService.instant(msg);
    }
    deleteIntegrationFailMessage(error) {
        const manualSyncInProgressErrCode = 'err_directory_sync_already_in_progress';
        const isManualSyncInProgress = error.fail[0].errors[0].code === manualSyncInProgressErrCode;
        const msgGeneric = '$I18N_DIRECTORY_SYNC_WIZARD.ERRORS.DELETE';
        const msgManualSyncInProgress = '$I18N_DIRECTORY_SYNC_WIZARD.ERRORS.DELETE_MANUAL_SYNC_IN_PROGRESS';
        return this.translateService.instant(isManualSyncInProgress ? msgManualSyncInProgress : msgGeneric);
    }
    getEditIntegrationValues(payload) {
        const integration = {
            id: payload.id,
            name: payload.description,
            type: payload.serverType,
            acknowledgeDisabledAccounts: payload.acknowledgeDisabledAccounts,
            domains: payload.domains,
            emailDomainsFilter: !!payload.domains,
            description: payload.info,
            deleteUsers: payload.deleteUsers,
            maxSyncDeletion: payload.maxUnlink,
            status: payload.enabled
        };
        if (payload.ldapSettings) {
            integration.host = payload.ldapSettings.hostname;
            integration.password = payload.ldapSettings.password;
            integration.rootDn = payload.ldapSettings.rootDn;
            integration.encryptionMode = payload.ldapSettings.encryptionMode;
            integration.port = payload.ldapSettings.port;
            integration.userDn = payload.ldapSettings.userDn;
            integration.includeContacts = payload.syncContacts;
            integration.alternateHost = payload.ldapSettings.alternateHostname;
            integration.encryptionConn = payload.ldapSettings.encryptionMode === 'none' ? false : true;
            if (payload.ldapSettings.hasOwnProperty('foreignDirectorySync')) {
                integration.foreignDirectorySync = payload.ldapSettings.foreignDirectorySync;
            }
        }
        if (payload.office365Settings) {
            integration.appID = payload.office365Settings.clientId;
            integration.type =
                payload.office365Settings.serverSubtype === 'standard' ? 'azure_standard' : 'azure_gcc';
            integration.tenantDomain = payload.office365Settings.tenantDomain;
            integration.key = payload.office365Settings.key;
            integration.includeContacts = payload.syncContacts;
            integration.includeGuestAccounts = payload.office365Settings.syncGuestUsers;
            integration.connector = payload.office365Settings.connectorId;
        }
        if (payload.googleDirectorySettings) {
            integration.email = payload.googleDirectorySettings.user;
            integration.json = payload.googleDirectorySettings.key;
        }
        if (payload.adPushSettings) {
            integration.mseSiteId = payload.adPushSettings.mseSiteId;
            integration.host = payload.adPushSettings.hostname;
            integration.password = payload.adPushSettings.password;
            integration.rootDn = payload.adPushSettings.rootDn;
            integration.userDn = payload.adPushSettings.userDn;
        }
        return integration;
    }
    syncAllMessage(response) {
        const status = `${response.mseStatus}`.toUpperCase();
        switch (status) {
            case 'MSE_SYNC_REQUESTED':
                return this.mseToastMessage(status);
            case 'MSE_SYNC_SERVER_OFFLINE':
            case 'MSE_SYNC_SERVER_UPGRADE':
            case 'MSE_SYNC_SERVER_INACTIVE':
                return this.mseModalMessage(status);
            default:
                return this.mseToastMessage('SYNC_ALL_INTEGRATIONS_SUCCESS');
        }
    }
    mseToastMessage(message) {
        return new notification_actions_1.NotificationShowAction({
            type: 'success',
            config: {
                msg: this.translateService.instant(`$I18N_DIRECTORY_SYNC_TABLE.${message}`)
            }
        });
    }
    mseModalMessage(message) {
        return new actions.ShowMseMessageModalAction(message);
    }
}
__decorate([
    effects_1.Effect()
], IntegrationsEffects.prototype, "getAllDirectorySyncIntegrations$", void 0);
__decorate([
    effects_1.Effect()
], IntegrationsEffects.prototype, "generalFailure$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], IntegrationsEffects.prototype, "cancelCreateIntegrationWizard$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], IntegrationsEffects.prototype, "openAside$", void 0);
__decorate([
    effects_1.Effect()
], IntegrationsEffects.prototype, "createIntegration$", void 0);
__decorate([
    effects_1.Effect()
], IntegrationsEffects.prototype, "deleteIntegration$", void 0);
__decorate([
    effects_1.Effect()
], IntegrationsEffects.prototype, "updateIntegration$", void 0);
__decorate([
    effects_1.Effect()
], IntegrationsEffects.prototype, "updateIntegrationStatus$", void 0);
__decorate([
    effects_1.Effect()
], IntegrationsEffects.prototype, "syncAllIntegrations$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], IntegrationsEffects.prototype, "showConfirmationModal$", void 0);
__decorate([
    effects_1.Effect()
], IntegrationsEffects.prototype, "searchDirectory$", void 0);
__decorate([
    effects_1.Effect()
], IntegrationsEffects.prototype, "synchronizationReport$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], IntegrationsEffects.prototype, "showSynchronization$", void 0);
__decorate([
    effects_1.Effect()
], IntegrationsEffects.prototype, "synchronizationFullReport$", void 0);
__decorate([
    effects_1.Effect()
], IntegrationsEffects.prototype, "openWizard$", void 0);
__decorate([
    effects_1.Effect()
], IntegrationsEffects.prototype, "getSyncStatus$", void 0);
__decorate([
    effects_1.Effect()
], IntegrationsEffects.prototype, "openSidePanel$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], IntegrationsEffects.prototype, "showMseMessageModal$", void 0);
exports.IntegrationsEffects = IntegrationsEffects;
