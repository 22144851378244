<mc-collapsable-panel header="$I18N_SWG_THREAT_EVENT_STATIC_ANALYSIS.TITLE" [isExpanded]="true" class="threat-event-static-analysis">
  <div class="mc-detailed-list mc-detailed-list-two-columns" *ngIf="threatEvent.staticAnalysis">
    <div class="mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_SWG_THREAT_EVENT_STATIC_ANALYSIS.THREAT_TECHNIQUES' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        <span *ngFor="let threatTechnique of threatEvent.staticAnalysis.threatTechniques"
              class="badge">
          {{ threatTechnique }}
        </span>
      </div>
    </div>

    <div class="mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_SWG_THREAT_EVENT_STATIC_ANALYSIS.DETAILS' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        <pre class="mc-flex-scroll-vertical">{{ threatEvent.staticAnalysis.details }}</pre>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!threatEvent.staticAnalysis">
    {{ '$I18N_SWG_THREAT_EVENT_STATIC_ANALYSIS.NO_DATA' | translate }}
  </ng-container>
</mc-collapsable-panel>
