<mc-modal-header
  headerTitle="{{ '$I18N_DIRECTORY_SYNC_SYNC_REPORT_MODAL.TITLE' | translate }}">
</mc-modal-header>

<mc-modal-body>
  <p>{{getHeader()}}</p>
  <p class="mc-report-summary" [innerHTML]="report()"></p>
</mc-modal-body>

<mc-modal-footer>
  <button type="button" class="btn btn-text" (click)="close()">
    {{ '$I18N_DIRECTORY_SYNC_SYNC_REPORT_MODAL.CLOSE_BUTTON' | translate }}
  </button>
  <button type="button" class="btn btn-primary" (click)="download()">
    <i class="icon-download" aria-hidden="true"></i>
    {{ '$I18N_DIRECTORY_SYNC_SYNC_REPORT_MODAL.DOWNLOAD_BUTTON' | translate }}
  </button>
</mc-modal-footer>
