"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CancelConfirmationModalComponent = void 0;
const wizardActions = require("app-new/custom-domain-block/actions/wizard.actions");
class CancelConfirmationModalComponent {
    constructor(store, data) {
        this.store = store;
        this.data = data;
    }
    getModalTitle() {
        return this.data.title;
    }
    onYes() {
        this.store.dispatch(new wizardActions.CancelYesAction(this.data.wizardId));
    }
    onNo() {
        this.store.dispatch(new wizardActions.CancelNoAction());
    }
}
exports.CancelConfirmationModalComponent = CancelConfirmationModalComponent;
