"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailHtmlPanelComponent = void 0;
const rxjs_1 = require("rxjs");
class EmailHtmlPanelComponent {
    constructor(service) {
        this.service = service;
        this.htmlWrapper$ = new rxjs_1.Observable(null);
        this.isLoading$ = new rxjs_1.BehaviorSubject(false);
        this.inlineNotification = {
            msg: 'For your security, images aren’t automatically displayed.',
            status: 'warning',
            boxed: true,
            actions: [
                {
                    type: 'button',
                    label: 'Display Images',
                    callback: () => this.displayImages()
                }
            ]
        };
    }
    ngOnChanges() {
        this.htmlWrapper$ = this.service.getProcessedHtml(this.html, this.attachments, this.imageApiUrl);
    }
    displayImages() {
        this.isLoading$.next(true);
        this.service.displayImages().subscribe(() => this.isLoading$.next(false));
    }
    getPrintableHtml() {
        return this.service.getPrintableHtml();
    }
}
exports.EmailHtmlPanelComponent = EmailHtmlPanelComponent;
