<mc-layout-aside-extra-container keyTitle="$I18N_SWG_CREATE_POLICY_POLICY_LOCATION_SEARCH"
                                 showClose="true" [isLoading]="false" (closeAside)="close()">

  <mc-extra-container>

    <mc-paginator
      class="pull-right"
      *ngIf="(pagination$ | async)"
      [start]="(pagination$ | async).startIndex"
      [end]="(pagination$ | async).endIndex"
      [isLoading]="apiIsProcessing$ | async"
      [separator]="separator"
      [previousToken]="(pagination$ | async).prevToken"
      [nextToken]="(pagination$ | async).nextToken"
      (paginate)="onPaginate($event)">
    </mc-paginator>

    <form class="mc-filters-bundle-column-values-form"
          [formGroup]="locationsForm">

      <mc-table-filter-field-search
        [placeHolder]="placeholder"
        formControlName="searchQuery">
      </mc-table-filter-field-search>
    </form>

  </mc-extra-container>
  <mc-body-container>
    <div *ngIf="!(apiIsProcessing$ | async)">
      <div class="mc-aside-sub-header">{{'$I18N_SWG_CREATE_POLICY_POLICY_LOCATIONS' | translate}}</div>
      <div class="mc-aside-content-container">
        <div *ngFor="let row of locations$ | async">
          <div class=" panel-half-padding-top" (click)="selectLocation(row)">
            <span class="mc-target-select-item" [ngClass]="{'mc-target-selected':row.id === _selectedLocation.id}">
              {{row.description}}&nbsp;({{row.ipRange}}) </span></div>
        </div>
        <div *ngIf="!(locations$ | async).length">
          {{'$I18N_SWG_CREATE_POLICY_AFFECTED_USERS_NO_LOCATIONS_FOUND' | translate}}
        </div>
      </div>
    </div>
    <mc-spinner *ngIf="apiIsProcessing$ | async" class="no-content-wrapper spinner-wrapper"></mc-spinner>
  </mc-body-container>
</mc-layout-aside-extra-container>

