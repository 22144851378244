<div class="report-downloader">
  <mc-report-downloader-wizard
    dateLabel="{{ '$I18N_ARCHIVE_SUPERVISION_REPORT_TAB.DATE_LABEL' | translate }}"
    typeLabel="{{ '$I18N_ARCHIVE_SUPERVISION_REPORT_TAB.REPORT_TYPE_LABEL' | translate }}"
    downloadBtnLabel="{{ '$I18N_ARCHIVE_SUPERVISION_REPORT_TAB.DOWNLOAD_LABEL' | translate }}"
    [dateRanges]="dateRanges"
    [defaultDateRange]="dateRanges[0]"
    [reportTypes]="reportTypes"
    [defaultType]="reportTypes[0]"
    (reportSelection)="downloadReport($event)"
    [dateRangeMaxInterval]="dateRangeMaxInterval"
    errorPrefix="$I18N_ARCHIVE_SUPERVISION_REPORT_TAB.VALIDATION.REPORT"
    [downloading]="downloading$ | async"
    reportAvailabilityMessage="{{
      '$I18N_ARCHIVE_SUPERVISION_REPORT_TAB.REPORT_AVAILABILITY_INFO_MESSAGE' | translate
    }}"
  ></mc-report-downloader-wizard>
</div>
