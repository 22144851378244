"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageStatusComponent = void 0;
const rxjs_1 = require("rxjs");
class MessageStatusComponent {
    constructor() {
        this.recipients = [];
        this.filteredRecipients = [];
        this.recipientsToDisplay = new rxjs_1.BehaviorSubject([]);
        this.recipientsColumns = ['email', 'status'];
    }
    ngOnInit() {
        if (this.messageDetails && this.messageDetails.to) {
            this.messageDetails.to.forEach(recipient => this.recipients.push({
                email: recipient.emailAddress,
                status: this.messageDetails.ccmRecipientsStatus.filter(ccmRecipient => ccmRecipient.emailAddress === recipient.emailAddress)[0].unread
                    ? 'SENT'
                    : 'READ'
            }));
        }
        if (this.messageDetails && this.messageDetails.cc) {
            this.messageDetails.cc.forEach(recipient => this.recipients.push({
                email: recipient.emailAddress,
                status: this.messageDetails.ccmRecipientsStatus.filter(ccmRecipient => ccmRecipient.emailAddress === recipient.emailAddress)[0].unread
                    ? 'SENT'
                    : 'READ'
            }));
        }
        this.recipientsToDisplay.next(this.recipients);
    }
    onSearchRecipient(filter) {
        this.filteredRecipients.length = 0;
        this.filteredRecipients = this.recipients.filter(recipient => {
            return (recipient.email.toLowerCase().indexOf(filter.search.toLowerCase()) !== -1 ||
                recipient.status.toLowerCase().indexOf(filter.search.toLowerCase()) !== -1);
        });
        this.recipientsToDisplay.next(this.filteredRecipients);
    }
    clearSearch() {
        this.filteredRecipients.length = 0;
        this.recipientsToDisplay.next(this.recipients);
    }
}
exports.MessageStatusComponent = MessageStatusComponent;
