"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IntegrationFailureAction = exports.DisableIntegrationSuccessAction = exports.GetIntegrationDescriptionAction = exports.DisableIntegrationFailAction = exports.DisableIntegrationAction = exports.EnableIntegrationFailAction = exports.EnableIntegrationSuccessAction = exports.EnableIntegrationAction = exports.OpenEditIntegrationCompletedAction = exports.OpenEditIntegrationAction = exports.CloseViewIntegrationAction = exports.OpenViewIntegrationAction = exports.OpenCreateIntegrationCompletedAction = exports.OpenCreateIntegrationAction = exports.PageIntegrationsCompletedAction = exports.PageIntegrationsAction = exports.GetAllIntegrationsSuccessAction = exports.GetCortexIntegrationByExternalIdAndTypeAction = exports.GetAllIntegrationsAction = exports.IntegrationListActionTypes = void 0;
var IntegrationListActionTypes;
(function (IntegrationListActionTypes) {
    IntegrationListActionTypes["GetAllIntegrations"] = "[ApiApplication/Integration/List] Get All Api Integrations";
    IntegrationListActionTypes["GetCortexIntegrationByExternalIdAndType"] = "[ApiApplication/Integration/Get] Get Api Integration by External Id and Type";
    IntegrationListActionTypes["GetAllIntegrationsSuccess"] = "[ApiApplication/Integration/List] Get All Api Integrations Success";
    IntegrationListActionTypes["PageIntegrations"] = "[ApiApplication/Integration/List] Page Api Integrations";
    IntegrationListActionTypes["PageIntegrationsCompleted"] = "[ApiApplication/Integration/List] Page Api Integrations Completed";
    IntegrationListActionTypes["OpenCreateIntegration"] = "[ApiApplication/Integration/List] Open Create Integration";
    IntegrationListActionTypes["OpenCreateIntegrationCompleted"] = "[ApiApplication/Integration/List] Open Create Integration Completed";
    IntegrationListActionTypes["OpenViewIntegration"] = "[ApiApplication/Integration/List] Open View Integration";
    IntegrationListActionTypes["CloseViewIntegration"] = "[ApiApplication/Integration/List] Close View Integration";
    IntegrationListActionTypes["OpenEditIntegration"] = "[ApiApplication/Integration/List] Open Edit Integration";
    IntegrationListActionTypes["OpenEditIntegrationCompleted"] = "[ApiApplication/Integration/List] Open Edit Integration Completed";
    IntegrationListActionTypes["EnableIntegration"] = "[ApiApplication/Integration/List] Enable Integration";
    IntegrationListActionTypes["EnableIntegrationSuccess"] = "[ApiApplication/Integration/List] Enable Integration Success";
    IntegrationListActionTypes["EnableIntegrationFail"] = "[ApiApplication/Integration/List] Enable Integration Fail";
    IntegrationListActionTypes["DisableIntegrationFail"] = "[ApiApplication/Integration/List] Disable Integration Fail";
    IntegrationListActionTypes["DisableIntegration"] = "[ApiApplication/Integration/List] Disable Integration";
    IntegrationListActionTypes["DisableIntegrationSuccess"] = "[ApiApplication/Integration/List] Disable Integration Success";
    IntegrationListActionTypes["GetIntegrationDescription"] = "[ApiApplication/Integration/List] Get Integration Description";
    IntegrationListActionTypes["IntegrationFailure"] = "[ApiApplication/Integration/List] Integration Failure";
})(IntegrationListActionTypes = exports.IntegrationListActionTypes || (exports.IntegrationListActionTypes = {}));
class GetAllIntegrationsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationListActionTypes.GetAllIntegrations;
    }
}
exports.GetAllIntegrationsAction = GetAllIntegrationsAction;
class GetCortexIntegrationByExternalIdAndTypeAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationListActionTypes.GetCortexIntegrationByExternalIdAndType;
    }
}
exports.GetCortexIntegrationByExternalIdAndTypeAction = GetCortexIntegrationByExternalIdAndTypeAction;
class GetAllIntegrationsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationListActionTypes.GetAllIntegrationsSuccess;
    }
}
exports.GetAllIntegrationsSuccessAction = GetAllIntegrationsSuccessAction;
class PageIntegrationsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationListActionTypes.PageIntegrations;
    }
}
exports.PageIntegrationsAction = PageIntegrationsAction;
class PageIntegrationsCompletedAction {
    constructor() {
        this.type = IntegrationListActionTypes.PageIntegrationsCompleted;
    }
}
exports.PageIntegrationsCompletedAction = PageIntegrationsCompletedAction;
class OpenCreateIntegrationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationListActionTypes.OpenCreateIntegration;
    }
}
exports.OpenCreateIntegrationAction = OpenCreateIntegrationAction;
class OpenCreateIntegrationCompletedAction {
    constructor() {
        this.type = IntegrationListActionTypes.OpenCreateIntegrationCompleted;
    }
}
exports.OpenCreateIntegrationCompletedAction = OpenCreateIntegrationCompletedAction;
class OpenViewIntegrationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationListActionTypes.OpenViewIntegration;
    }
}
exports.OpenViewIntegrationAction = OpenViewIntegrationAction;
class CloseViewIntegrationAction {
    constructor() {
        this.type = IntegrationListActionTypes.CloseViewIntegration;
    }
}
exports.CloseViewIntegrationAction = CloseViewIntegrationAction;
class OpenEditIntegrationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationListActionTypes.OpenEditIntegration;
    }
}
exports.OpenEditIntegrationAction = OpenEditIntegrationAction;
class OpenEditIntegrationCompletedAction {
    constructor() {
        this.type = IntegrationListActionTypes.OpenEditIntegrationCompleted;
    }
}
exports.OpenEditIntegrationCompletedAction = OpenEditIntegrationCompletedAction;
class EnableIntegrationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationListActionTypes.EnableIntegration;
    }
}
exports.EnableIntegrationAction = EnableIntegrationAction;
class EnableIntegrationSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationListActionTypes.EnableIntegrationSuccess;
    }
}
exports.EnableIntegrationSuccessAction = EnableIntegrationSuccessAction;
class EnableIntegrationFailAction {
    constructor() {
        this.type = IntegrationListActionTypes.EnableIntegrationFail;
    }
}
exports.EnableIntegrationFailAction = EnableIntegrationFailAction;
class DisableIntegrationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationListActionTypes.DisableIntegration;
    }
}
exports.DisableIntegrationAction = DisableIntegrationAction;
class DisableIntegrationFailAction {
    constructor() {
        this.type = IntegrationListActionTypes.DisableIntegrationFail;
    }
}
exports.DisableIntegrationFailAction = DisableIntegrationFailAction;
class GetIntegrationDescriptionAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationListActionTypes.GetIntegrationDescription;
    }
}
exports.GetIntegrationDescriptionAction = GetIntegrationDescriptionAction;
class DisableIntegrationSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationListActionTypes.DisableIntegrationSuccess;
    }
}
exports.DisableIntegrationSuccessAction = DisableIntegrationSuccessAction;
class IntegrationFailureAction {
    constructor() {
        this.type = IntegrationListActionTypes.IntegrationFailure;
    }
}
exports.IntegrationFailureAction = IntegrationFailureAction;
