<mc-layout-list-tabs
  keyTitle="$I18N_SUPERVISION_LISTS.TITLE"
  keyDescription="$I18N_SUPERVISION_LISTS.DESCRIPTION"
  extendClass="mc-list-archive-viewer"
>
  <mc-extra-container> </mc-extra-container>

  <mc-tab-group [initialActiveTabIndex]="selectedTabIndex">
    <mc-tab name="Overview" data-test="mc-archive-supervision-dashboard-tab">
      <div class="panel-body no-padding-bottom">
        <mc-dashboard></mc-dashboard>
      </div>
    </mc-tab>

    <mc-tab name="Lexicon" data-test="mc-archive-supervision-lexicon-tab">
      <div class="panel-body no-padding-bottom">
        <mc-archive-supervision-list-tab-lexicon
          [hasEditPermission]="hasEditPermission$ | async"
          [hasSupervisionDeleteCapability]="hasSupervisionDeleteCapability$ | async"
        ></mc-archive-supervision-list-tab-lexicon>
      </div>
    </mc-tab>

    <mc-tab name="Queues" data-test="mc-archive-supervision-lexicon-tab">
      <div class="panel-body no-padding-bottom">
        <mc-archive-supervision-list-tab-queues
          [hasEditPermission]="hasEditPermission$ | async"
          [hasSupervisionDeleteCapability]="hasSupervisionDeleteCapability$ | async"
        ></mc-archive-supervision-list-tab-queues>
      </div>
    </mc-tab>

    <mc-tab name="Rules" data-test="mc-archive-supervision-lexicon-tab">
      <div class="panel-body no-padding-bottom">
        <mc-archive-supervision-list-tab-rules
          [preRuleCreationCriteriaMet]="preRuleCreationCriteriaMet"
          [hasEditPermission]="hasEditPermission$ | async"
          [hasMonthlyScheduleCapability]="hasMonthlyScheduleCapability$ | async"
          [hasSupervisionDeleteCapability]="hasSupervisionDeleteCapability$ | async"
        ></mc-archive-supervision-list-tab-rules>
      </div>
    </mc-tab>

    <mc-tab name="Reports">
      <div class="panel-body no-padding-bottom">
        <mc-report-downloader></mc-report-downloader>
      </div>
    </mc-tab>
  </mc-tab-group>
</mc-layout-list-tabs>
