<mc-layout-aside-extra-pagination-container
  keyTitle="$I18N_SERVICES_AP_DEFINITION_SIDE_PANEL.LABELS.DEFINITION_DETAILS_TITLE" [isLoading]="false"
  [showClose]="true" [hasNextInView]="hasNextInView$ | async" [hasPreviousInView]="hasPreviousInView$ | async"
  [indexInView]="indexInView$ | async" [currenPagination]="currentPagination$ | async"
  [filtersMetaData]="filtersMetaData$ | async" [isLoading]="false" (goToNextPage)="getNext()" (goToPrevPage)="getPrev()"
  (closeAside)="onClose()">

  <div row-actions>
    <div class="mc-header-row">
      <span>{{ selectedRow?.description }}</span>
    </div>
    <ng-container *mcCapabilities="canModifyPolicy" data-unit-test="definition-controls">
      <button class="btn btn-primary panel-half-margin-right"[disabled]="disabled" (click)="onEdit(selectedRow)">
        {{ '$I18N_SERVICES_AP_DEFINITION_SIDE_PANEL.LABELS.EDIT' | translate }}
      </button>
      <button class="btn btn-default panel-half-margin-right" [disabled]="disabled" (click)="onDuplicate(selectedRow)">
        {{ '$I18N_SERVICES_AP_DEFINITION_SIDE_PANEL.LABELS.DUPLICATE' | translate }}
      </button>
      <button class="btn btn-default panel-half-margin-right"[disabled]="disabled" (click)="onDelete(selectedRow)">
        {{ '$I18N_SERVICES_AP_DEFINITION_SIDE_PANEL.LABELS.DELETE' | translate }}
      </button>
    </ng-container>
  </div>
  <mc-body-container>
    <mc-attachment-protection-side-panel-view-details
      [isAAAOrFAAUser]="isAAAOrFAAUser"
      [hasIEPPackage]="hasIEPPackage"
      [definitionDetails]="definitionDetails"
      [hasBrowserIsolation]="hasBrowserIsolation"
      data-unit-test="definition-side-panel-details"
      [selectedRow]="selectedRow">
    </mc-attachment-protection-side-panel-view-details>
  </mc-body-container>
</mc-layout-aside-extra-pagination-container>
