"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./search-logs-controller");
require("./search-logs.less");
require("./search-logs.tpl.html");
require("./slides/search-logs-details.tpl.html");
(function () {
    'use strict';
    angular
        .module('services.services.backup.search-logs', [
        'services.services.backup.search-logs.controller'
    ])
        .config([
        '$stateProvider',
        function ($stateProvider) {
            const breadcrumbs = [
                '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_SERVICES',
                '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ASIDE_MENU_TITLE',
                '$I18N_ADMINISTRATION_MENU_BACKUP_SEARCH_LOGS_TITLE'
            ];
            $stateProvider.state('sync-recovery-search-logs', {
                url: '/sync-recover/exchange/logs',
                data: {
                    capabilities: 'Permission.DATA_SYNC_READ || Permission.DATA_SYNC_AND_RECOVER_READ',
                    breadcrumbs,
                    asideTitle: '$I18N_ADMINISTRATION_MENU_BACKUP_SEARCH_LOGS_TITLE',
                    tabId: 'sync-and-recover-tab',
                    tabTitle: '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ASIDE_MENU_TITLE',
                    tabHide: false,
                    tabReload: false
                },
                views: {
                    main: {
                        templateUrl: 'services/services/backup/search-logs/search-logs.tpl.html'
                    },
                    aside: {
                        templateUrl: 'services/services/backup/aside/aside.tpl.html'
                    }
                },
                onEnter: [
                    'windowService',
                    function (windowService) {
                        if (windowService.inIframe()) {
                            breadcrumbs.length = 0;
                            breadcrumbs.push('$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_SERVICES', '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ASIDE_MENU_TITLE');
                        }
                    }
                ]
            });
        }
    ]);
})();
