"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiIntegrationWildfireWizardComponent = void 0;
const core_1 = require("@angular/core");
class ApiIntegrationWildfireWizardComponent {
    constructor(store) {
        this.store = store;
        this.onCancel = new core_1.EventEmitter();
        this.onCreateWildfireIntegration = new core_1.EventEmitter();
        this.onUpdateWildfireIntegration = new core_1.EventEmitter();
        this.onSwitchWildfireIntegrationStatus = new core_1.EventEmitter();
        this.onSwitchAttachmentIndicator = new core_1.EventEmitter();
        this.onSwitchRemediation = new core_1.EventEmitter();
        this.onSwitchAcceptSharingInfo = new core_1.EventEmitter();
        this.onSwitchMaliciousAttachmentIndicator = new core_1.EventEmitter();
        this.onAddGroup = new core_1.EventEmitter();
        this.onDeleteGroup = new core_1.EventEmitter();
        this.onVerifyAndAddEmail = new core_1.EventEmitter();
        this.onDeleteEmail = new core_1.EventEmitter();
        this.onVerifyApiAndRegion = new core_1.EventEmitter();
        this.onRegionChange = new core_1.EventEmitter();
        this.hasNextStep = true;
        this.hasPreviousStep = false;
        this.DISCLAIMER_STEP = 1;
        this.ACTIVATION_STEP = 2;
        this.INDICATORS_STEP = 3;
        this.REMEDIATION_STEP = 4;
        this.NOTIFICATIONS_STEP = 5;
        this.SUMMARY_STEP = 6;
    }
    ngAfterViewInit() {
        if (!!this.wizard) {
            this.wizard.hasNextStep$.subscribe(value => (this.hasNextStep = value));
            this.wizard.hasPreviousStep$.subscribe(value => (this.hasPreviousStep = value));
            if (this.wildfirePrerequisitesError) {
                this.gotoStep(this.SUMMARY_STEP);
            }
            else {
                this.gotoStep(this.DISCLAIMER_STEP);
            }
        }
    }
    ngOnChanges(changes) {
        const prerequisiteErrorChange = changes['wildfirePrerequisitesError'];
        if (prerequisiteErrorChange && prerequisiteErrorChange.currentValue) {
            this.gotoStep(this.SUMMARY_STEP);
        }
    }
    wizardTitle() {
        return `$I18N_API_INTEGRATION_WILDFIRE_WIZARD.TITLE_${this.hasCreatedIntegration ? 'EDIT' : 'CREATE'}`;
    }
    cancel() {
        this.onCancel.emit();
    }
    nextStep() {
        if (!!this.wizard) {
            this.wizard.nextStep();
        }
    }
    changeStep() {
        // you have to listen to this event or the underlying wizard doesn't work :(
    }
    gotoStep(step) {
        if (!!this.wizard) {
            this.wizard.showStep(step);
        }
    }
    showPrevious() {
        if (this.wildfirePrerequisitesError) {
            return false;
        }
        return this.hasPreviousStep;
    }
    gotoPreviousStep() {
        if (!!this.wizard) {
            this.wizard.previousStep();
        }
    }
    showNext() {
        return this.hasNextStep && this.wizard.currentStep !== this.SUMMARY_STEP;
    }
    gotoNextStep() {
        if (!!this.wizard) {
            this.wizard.nextStep();
        }
    }
    showCreate() {
        if (!!this.wizard) {
            return this.wizard.currentStep === this.SUMMARY_STEP && !this.hasCreatedIntegration;
        }
    }
    create() {
        this.onCreateWildfireIntegration.emit();
    }
    showUpdate() {
        if (!!this.wizard) {
            return this.wizard.currentStep === this.SUMMARY_STEP && this.hasCreatedIntegration;
        }
    }
    update() {
        this.onUpdateWildfireIntegration.emit();
    }
    changeRegion(newRegion) {
        this.onRegionChange.emit(newRegion);
    }
    switchWildfireIntegrationStatus(status) {
        this.onSwitchWildfireIntegrationStatus.emit(status);
    }
    switchAttachmentIndicator(enabledIndicator) {
        this.onSwitchAttachmentIndicator.emit(enabledIndicator);
    }
    switchMaliciousAttachmentIndicator(enabledIndicator) {
        this.onSwitchMaliciousAttachmentIndicator.emit(enabledIndicator);
    }
    verifyApiAndRegion(apiAndRegionConfig) {
        this.onVerifyApiAndRegion.emit(apiAndRegionConfig);
    }
    switchRemediation(remediationRequired) {
        this.onSwitchRemediation.emit(remediationRequired);
    }
    switchAcceptSharingInfo(acceptSharingInfo) {
        this.onSwitchAcceptSharingInfo.emit(acceptSharingInfo);
    }
    addGroup(group) {
        this.onAddGroup.emit(group);
    }
    deleteGroup(group) {
        this.onDeleteGroup.emit(group);
    }
    verifyAndAddEmail(email) {
        this.onVerifyAndAddEmail.emit(email);
    }
    deleteEmail(email) {
        this.onDeleteEmail.emit(email);
    }
    isStepValid() {
        if (!this.isBusy && !!this.wizard) {
            switch (this.wizard.currentStep) {
                case this.DISCLAIMER_STEP:
                    return this.disclaimerStep.isValid();
                case this.ACTIVATION_STEP:
                    return this.activationStep.isValid();
                case this.INDICATORS_STEP:
                    return this.indicatorsStep.isValid();
                case this.REMEDIATION_STEP:
                    return this.remediationStep.isValid();
                case this.NOTIFICATIONS_STEP:
                    return this.notificationsStep.isValid();
                case this.SUMMARY_STEP:
                    return this.summaryStep.isValid();
                default:
                    return true;
            }
        }
        return false;
    }
}
exports.ApiIntegrationWildfireWizardComponent = ApiIntegrationWildfireWizardComponent;
