"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgLocationEditSidebarComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const components_1 = require("@mimecast-ui/components");
const swg_locations_list_static_values_1 = require("app-new/swg/locations/swg-locations-list.static-values");
class SwgLocationEditSidebarComponent {
    constructor(fb) {
        this.fb = fb;
        this.edit = new core_1.EventEmitter();
        this.cancel = new core_1.EventEmitter();
        this.keyTitle = '$I18N_SWG_LOCATIONS_LIST.ADD_A_LOCATION';
        this.permissionTypeOptions = swg_locations_list_static_values_1.tzKeys;
    }
    ngOnInit() {
        this.locationEditForm = this.fb.group({
            description: ['', [forms_1.Validators.required, forms_1.Validators.maxLength(60)]],
            ipRange: ['', [forms_1.Validators.required, components_1.CIDRValidator]],
            timeZone: ['', forms_1.Validators.required],
            id: ['']
        });
        if (this.editFlow) {
            this.keyTitle = '$I18N_SWG_LOCATIONS_LIST.EDIT_A_LOCATION';
            this.locationEditForm.patchValue(this.swgLocation);
        }
    }
    editLocation() {
        this.edit.emit(this.locationEditForm.value);
    }
    closeSidebar() {
        this.cancel.emit();
    }
    showButtonText() {
        return this.editFlow ? '$I18N_COMMON_BTN_SAVE' : '$I18N_COMMON_BTN_ADD';
    }
}
exports.SwgLocationEditSidebarComponent = SwgLocationEditSidebarComponent;
