"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UploadButtonComponent = void 0;
const core_1 = require("@angular/core");
class UploadButtonComponent {
    constructor() {
        this.isDisabled = false;
        this.label = '$I18N_UPLOAD_BUTTON_LABEL';
        this.files = new core_1.EventEmitter();
    }
    set disabled(value) {
        this.isDisabled = value === true || value === '';
    }
    changedFiles($event) {
        this.files.emit(Array.from($event.target.files));
    }
}
exports.UploadButtonComponent = UploadButtonComponent;
