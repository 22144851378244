"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tableSectionFilters = exports.tableColumnsToIgnore = exports.tableColumnsStartHidden = exports.tableColumnsAlwaysVisible = exports.exportColumns = exports.tableColumns = exports.tableSelectColumn = void 0;
exports.tableSelectColumn = 'select';
exports.tableColumns = [
    'select',
    'policyInfo',
    'spamScore',
    'detectionLevel',
    'heldGroup',
    'fromEnv',
    'route',
    'fromHdr',
    'attachments',
    'to',
    'size',
    'subject',
    'heldSince',
    'right-column'
];
exports.exportColumns = [
    'policyInfo',
    'spamScore',
    'spamDetection',
    'heldGroup',
    'fromEnv',
    'route',
    'fromHdr',
    'attachments',
    'to',
    'size',
    'subject',
    'heldSince'
];
exports.tableColumnsAlwaysVisible = ['policyInfo', 'to'];
exports.tableColumnsStartHidden = [
    'fromEnv',
    'route',
    'size',
    'messageEvent',
    'spamScore',
    'detectionLevel'
];
exports.tableColumnsToIgnore = ['select'];
exports.tableSectionFilters = [
    {
        name: 'heldGroup',
        filters: ['administrator', 'user', 'moderator']
    },
    {
        name: 'route',
        filters: ['internal', 'inbound', 'outbound']
    },
    {
        name: 'attachments',
        filters: ['true', 'false']
    }
];
