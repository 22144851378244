"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApEditPolicyEffects = void 0;
const effects_1 = require("@ngrx/effects");
const policy_actions_1 = require("../../actions/policy.actions");
const actions = require("../../actions/policy.actions");
const operators_1 = require("rxjs/operators");
const reducers = require("../../reducers");
const models_1 = require("../../models");
const rxjs_1 = require("rxjs");
const notification_actions_1 = require("../../../../../components/notification/actions/notification.actions");
class ApEditPolicyEffects {
    constructor(store, translationService, stateService, apEditPolicyService, apCreatePolicyService, actions$) {
        this.store = store;
        this.translationService = translationService;
        this.stateService = stateService;
        this.apEditPolicyService = apEditPolicyService;
        this.apCreatePolicyService = apCreatePolicyService;
        this.actions$ = actions$;
        this.updateApWizardFlow$ = this.actions$.pipe(effects_1.ofType(policy_actions_1.ApPolicyCreateTypesEnum.NAVIGATE_TO_ATTACHMENT_PROTECTION_POLICY_WIZARD), operators_1.withLatestFrom(this.store.select(reducers.getEditPolicyId)), operators_1.switchMap(([action, editPolicyId]) => {
            if (action.payload === models_1.ApPolicyWizardFlowType.EDIT) {
                return this.apCreatePolicyService.getPolicyDetails(editPolicyId).pipe(operators_1.mergeMap((response) => {
                    const policyDetailsStep = this.apEditPolicyService.mapApiResponseToDetailsSteps(response);
                    const policyInboundStep = this.apEditPolicyService.mapApiResponseToInboundStep(response);
                    const policyInboundGroup = this.apEditPolicyService.mapApiResponseToInboundGroup(response.notifyGroup);
                    const policyJournalGroup = this.apEditPolicyService.mapApiResponseToJournalGroup(response.journalNotifyGroup);
                    const policyJournalStep = this.apEditPolicyService.mapApiResponseToJournalStep(response);
                    const policyOutboundGroup = this.apEditPolicyService.mapApiResponseToOutboundGroup(response.outboundNotifyGroup);
                    const policyOutboundStep = this.apEditPolicyService.mapApiResponseToOutboundStep(response);
                    const ipAddressAppliesTo = this.apEditPolicyService.mapApiResponseToIpAddress(response);
                    const policyAppliesToStep = this.apEditPolicyService.mapApiResponseToAppliesToSteps(response);
                    return [
                        new policy_actions_1.ApUpdatePolicyDetailsStep(policyDetailsStep),
                        new policy_actions_1.ApUpdatePolicyInboundStep(policyInboundStep),
                        new policy_actions_1.AdministratorSelectGroup(policyInboundGroup),
                        new policy_actions_1.ApUpdatePolicyJournalStep(policyJournalStep),
                        new policy_actions_1.OutboundSelectGroup(policyOutboundGroup),
                        new policy_actions_1.ApUpdatePolicyOutboundStep(policyOutboundStep),
                        new policy_actions_1.ApUpdatePolicyAppliesToStep(policyAppliesToStep),
                        new policy_actions_1.PopulateIpAddressAction(ipAddressAppliesTo),
                        new policy_actions_1.JournalSelectGroup(policyJournalGroup),
                        new policy_actions_1.ApPolicyEditWizardFlowType()
                    ];
                }));
            }
            return rxjs_1.EMPTY;
        }));
        this.updateApPolicy$ = this.actions$.pipe(effects_1.ofType(actions.ApPolicyCreateTypesEnum.ATTACHMENT_PROTECTION_UPDATE_POLICY), operators_1.withLatestFrom(this.store.select(reducers.getEditPolicyId)), operators_1.switchMap(([action, policyId]) => {
            return this.apCreatePolicyService.updatePolicy(action.payload, policyId).pipe(operators_1.mergeMap(() => {
                let message = '';
                message = '$I18N_AP_CREATE_POLICY_POLICY_UPDATED';
                this.stateService.$state.go('attachment-protection-policy-list');
                return [
                    new notification_actions_1.NotificationShowAction({
                        type: 'success',
                        config: {
                            msg: message
                        }
                    }),
                    new actions.UpdateApSuccessAction()
                ];
            }), operators_1.catchError(error => rxjs_1.merge(rxjs_1.of(this.getNotificationAction(error)), rxjs_1.of(new actions.UpdateApFailAction()))));
        }));
    }
    getNotificationAction(error) {
        const msg = error.fail
            ? error.fail[0].errors[0].message
            : this.translationService.instant('$I18N_AP_CREATE_POLICY_SERVER_ERROR');
        return new notification_actions_1.NotificationShowAction({
            type: 'error',
            config: {
                msg
            }
        });
    }
}
__decorate([
    effects_1.Effect()
], ApEditPolicyEffects.prototype, "updateApWizardFlow$", void 0);
__decorate([
    effects_1.Effect()
], ApEditPolicyEffects.prototype, "updateApPolicy$", void 0);
exports.ApEditPolicyEffects = ApEditPolicyEffects;
