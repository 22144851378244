"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isCortexAuthorised = exports.getGroups = exports.getCortexIntegrationVersion1 = exports.isCortexFresh = exports.isCortexUpdated = exports.isCortexCreated = exports.getCortexUpdateRequestVersion1Data = exports.getCortexCreateRequestVersion1Data = exports.getCortexEmails = exports.getCortexSpywareSettings = exports.getCortexWildfireSettings = exports.getCortexConfiguration = exports.isCortexInstantiated = exports.getCortexState = exports.getAvailableProviders = exports.getSelectedProvider = exports.getConfiguredProviders = exports.isChanged = exports.isBusy = exports.reducer = exports.initialState = void 0;
const actions = require("../../actions");
exports.initialState = {
    isBusy: false,
    isChanged: false,
    allProviders: ['cortex'],
    configuredProviders: [],
    selectedProvider: 'unknown',
    cortexState: 'new',
    cortexConfiguration: '',
    cortexSpywareSettings: getInitialSetting('spyware', 'medium'),
    cortexWildfireSettings: getInitialSetting('wildfire', 'high'),
    cortexIntegrationVersion1: undefined,
    isFresh: false,
    cortexAuthorised: false,
    emails: [],
    groups: []
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.IntegrationListActionTypes.OpenCreateIntegration:
            return Object.assign(Object.assign({}, exports.initialState), { cortexState: 'new', configuredProviders: action.payload.configuredProviders });
        case actions.IntegrationListActionTypes.OpenEditIntegration:
            if (state.cortexIntegrationVersion1) {
                return Object.assign(Object.assign({}, state), { cortexState: 'edit', cortexSpywareSettings: resolveSetting(state.cortexIntegrationVersion1.triggers, 'spyware'), cortexWildfireSettings: resolveSetting(state.cortexIntegrationVersion1.triggers, 'wildfire'), emails: state.cortexIntegrationVersion1.notificationAddresses, groups: Object.keys(state.cortexIntegrationVersion1.notificationGroups).map(function (key) {
                        return {
                            description: state.cortexIntegrationVersion1.notificationGroups[key],
                            id: key
                        };
                    }) });
            }
            return Object.assign({}, state);
        case actions.IntegrationCortexActionTypes.SelectIntegrationProvider:
            return Object.assign(Object.assign({}, state), { selectedProvider: action.payload.provider });
        case actions.IntegrationCortexActionTypes.InstantiateCortexIntegration:
            return Object.assign(Object.assign({}, state), { cortexState: 'initiate', selectedProvider: action.payload.provider, cortexConfiguration: action.payload.config });
        case actions.IntegrationListActionTypes.GetAllIntegrations:
        case actions.IntegrationCortexActionTypes.CreateCortexIntegrationVersion1:
        case actions.IntegrationCortexActionTypes.UpdateCortexIntegrationVersion1:
            return Object.assign(Object.assign({}, state), { isChanged: false, isBusy: true });
        case actions.IntegrationListActionTypes.GetAllIntegrationsSuccess:
            return Object.assign(Object.assign({}, state), { isBusy: false, configuredProviders: (action.payload.integrations || []).map(integration => integration.integrationType) });
        case actions.IntegrationCortexActionTypes.CreateCortexIntegrationVersion1Complete:
            return Object.assign(Object.assign({}, state), { isBusy: false, isChanged: false, cortexState: 'created', isFresh: true, cortexIntegrationVersion1: action.payload, cortexSpywareSettings: resolveSetting(action.payload.triggers, 'spyware'), cortexWildfireSettings: resolveSetting(action.payload.triggers, 'wildfire') });
        case actions.IntegrationCortexActionTypes.UpdateCortexIntegrationCompleteVersion1:
            return Object.assign(Object.assign({}, state), { isBusy: false, isChanged: false, cortexState: 'updated', isFresh: false, cortexIntegrationVersion1: action.payload, cortexSpywareSettings: resolveSetting(action.payload.triggers, 'spyware'), cortexWildfireSettings: resolveSetting(action.payload.triggers, 'wildfire'), emails: action.payload.notificationAddresses, groups: Object.keys(action.payload.notificationGroups).map(function (key) {
                    return { description: action.payload.notificationGroups[key], id: key };
                }) });
        case actions.IntegrationCortexActionTypes.AuthoriseCortexIntegrationComplete:
            return Object.assign(Object.assign({}, state), { isBusy: true, cortexState: 'authorise', selectedProvider: action.payload.provider, cortexConfiguration: action.payload.config });
        case actions.IntegrationCortexActionTypes.GetCortexIntegrationCompleteVersion1:
            return Object.assign(Object.assign({}, state), { isBusy: false, isFresh: false, cortexState: 'edit', cortexIntegrationVersion1: action.payload, cortexSpywareSettings: resolveSetting(action.payload.triggers, 'spyware'), cortexWildfireSettings: resolveSetting(action.payload.triggers, 'wildfire'), emails: action.payload.notificationAddresses, groups: Object.keys(action.payload.notificationGroups).map(function (key) {
                    return { description: action.payload.notificationGroups[key], id: key };
                }) });
        case actions.IntegrationCortexActionTypes.DeleteEmailCortexIntegrationNotifications:
            return Object.assign(Object.assign({}, state), { isChanged: true, emails: state.emails.filter((email) => email !== action.payload.email) });
        case actions.IntegrationCortexActionTypes
            .VerifyAndAddEmailCortexIntegrationNotificationsSuccess:
            return Object.assign(Object.assign({}, state), { isChanged: true, emails: [action.payload.email].concat(state.emails) });
        case actions.IntegrationCortexActionTypes.AddGroupCortexIntegrationNotifications:
            return Object.assign(Object.assign({}, state), { isChanged: true, groups: [action.payload].concat(state.groups) });
        case actions.IntegrationCortexActionTypes.DeleteGroupCortexIntegrationNotifications:
            return Object.assign(Object.assign({}, state), { isChanged: true, groups: state.groups.filter((group) => group.description !== action.payload.description) });
        case actions.IntegrationCortexActionTypes.UpdateCortexIntegrationSpywareSettings:
            return Object.assign(Object.assign({}, state), { isChanged: true, cortexSpywareSettings: {
                    config: 'spyware',
                    type: 'panw.threat',
                    fields: {
                        subtype: 'spyware',
                        severity: action.payload.severity
                    },
                    action: action.payload.action,
                    enabled: action.payload.enabled,
                    disableUser: action.payload.disableUser
                } });
        case actions.IntegrationCortexActionTypes.UpdateCortexIntegrationWildfireSettings:
            return Object.assign(Object.assign({}, state), { isChanged: true, cortexWildfireSettings: {
                    config: 'wildfire',
                    type: 'panw.threat',
                    fields: {
                        subtype: 'wildfire',
                        severity: action.payload.severity
                    },
                    action: action.payload.action,
                    enabled: action.payload.enabled,
                    disableUser: action.payload.disableUser
                } });
        case actions.IntegrationCortexActionTypes.CancelCreateIntegration:
        case actions.IntegrationCortexActionTypes.CloseCreateIntegration:
            return exports.initialState;
        default:
            return state;
    }
}
exports.reducer = reducer;
const isBusy = (state) => state.isBusy;
exports.isBusy = isBusy;
const isChanged = (state) => state.isChanged;
exports.isChanged = isChanged;
const getConfiguredProviders = (state) => state.configuredProviders;
exports.getConfiguredProviders = getConfiguredProviders;
const getSelectedProvider = (state) => state.selectedProvider;
exports.getSelectedProvider = getSelectedProvider;
const getAvailableProviders = (state) => state.allProviders.filter(item => state.configuredProviders.indexOf(item) === -1);
exports.getAvailableProviders = getAvailableProviders;
const getCortexState = (state) => state.cortexState;
exports.getCortexState = getCortexState;
const isCortexInstantiated = (state) => state.cortexState === 'initiate';
exports.isCortexInstantiated = isCortexInstantiated;
const getCortexConfiguration = (state) => state.cortexConfiguration;
exports.getCortexConfiguration = getCortexConfiguration;
const getCortexWildfireSettings = (state) => state.cortexWildfireSettings;
exports.getCortexWildfireSettings = getCortexWildfireSettings;
const getCortexSpywareSettings = (state) => state.cortexSpywareSettings;
exports.getCortexSpywareSettings = getCortexSpywareSettings;
const getCortexEmails = (state) => state.emails;
exports.getCortexEmails = getCortexEmails;
const getCortexCreateRequestVersion1Data = (state) => ({
    config: state.cortexConfiguration,
    cortexSpywareSettings: state.cortexSpywareSettings,
    cortexWildfireSettings: state.cortexWildfireSettings
});
exports.getCortexCreateRequestVersion1Data = getCortexCreateRequestVersion1Data;
const getCortexUpdateRequestVersion1Data = (state) => ({
    cortex: state.cortexIntegrationVersion1,
    cortexSpywareSettings: state.cortexSpywareSettings,
    cortexWildfireSettings: state.cortexWildfireSettings,
    notifications: {
        notificationAddresses: state.emails,
        notificationGroups: !!state.groups && state.groups.map(object => object.id)
    }
});
exports.getCortexUpdateRequestVersion1Data = getCortexUpdateRequestVersion1Data;
const isCortexCreated = (state) => state.cortexState === 'created';
exports.isCortexCreated = isCortexCreated;
const isCortexUpdated = (state) => state.cortexState === 'updated';
exports.isCortexUpdated = isCortexUpdated;
const isCortexFresh = (state) => state.isFresh;
exports.isCortexFresh = isCortexFresh;
const getCortexIntegrationVersion1 = (state) => state.cortexIntegrationVersion1;
exports.getCortexIntegrationVersion1 = getCortexIntegrationVersion1;
const getGroups = (state) => state.groups;
exports.getGroups = getGroups;
const isCortexAuthorised = (state) => state.cortexState === 'authorise';
exports.isCortexAuthorised = isCortexAuthorised;
function resolveSetting(triggers, subtype) {
    const splitSetting = triggers.find(setting => setting.fields.subtype === subtype);
    return {
        config: splitSetting.fields.subtype,
        type: 'panw.threat',
        fields: {
            subtype: splitSetting.fields.subtype,
            severity: splitSetting.fields.severity
        },
        action: splitSetting.action,
        enabled: splitSetting.enabled,
        disableUser: splitSetting.disableUser
    };
}
function getInitialSetting(setting, initialSeverity) {
    return {
        config: setting,
        type: 'panw.threat',
        fields: {
            subtype: setting,
            severity: initialSeverity
        },
        action: 'NOTIFY',
        enabled: true,
        disableUser: false
    };
}
