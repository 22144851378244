"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
(function () {
    'use strict';
    angular.module('audit-log-view.controller', [])
        .controller('AuditLogViewCtrl', ['$scope', 'auditLogsService', '$filter', 'auditService', '$translate', function ($scope, auditLogsService, $filter, auditService, $translate) {
            const self = this;
            const { log } = $scope.$parent.$resolve;
            self.log = angular.copy(log);
            self.log.eventTime = $filter('mcStandardDate')(self.log.eventTime);
            self.log.category = $translate.instant(auditService.constants.CATEGORIES[self.log.category].name);
            self.columns = auditLogsService.getColumns();
        }]);
}());
