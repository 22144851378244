"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiemChannelProductsStepComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const operators_1 = require("rxjs/operators");
const siem_channels_models_1 = require("../../../../../models/siem-channels.models");
class SiemChannelProductsStepComponent {
    constructor() {
        this.channelType = siem_channels_models_1.ChannelType;
        this.productsAvailable = [];
        this.productsEnabledCount = 0;
        this.updateChannelProducts = new core_1.EventEmitter();
    }
    set metadataProductsByChannelType(_products) {
        if (_products) {
            this.productsAvailable = _products;
            this.createFormWithControls(_products);
        }
    }
    registerValueChanges() {
        this.channelProductsPageSubscription = this.channelProductsPage.valueChanges
            .pipe(operators_1.debounceTime(200))
            .subscribe(() => {
            this.updateStore();
        });
    }
    createFormWithControls(products) {
        const group = {};
        products.forEach((product) => {
            const matchedProduct = this.channelProducts.find(p => p.name === product.name);
            group[product.name] = new forms_1.FormControl(matchedProduct ? matchedProduct.enabled : false);
        });
        this.channelProductsPage = new forms_1.FormGroup(group);
        this.registerValueChanges();
    }
    updateStore() {
        const productsEnabled = [];
        this.productsEnabledCount = 0;
        const selectedProducts = Object.entries(this.channelProductsPage.value);
        for (const [key, value] of selectedProducts) {
            if (value) {
                let product = this.channelProducts.find(p => p.name === key);
                if (!product) {
                    product = this.productsAvailable.find(p => p.name === key);
                }
                productsEnabled.push(Object.assign(Object.assign({}, product), { enabled: value ? true : false }));
            }
        }
        this.productsEnabledCount = productsEnabled.length;
        this.updateChannelProducts.emit(productsEnabled);
    }
    isValid() {
        return !!this.productsEnabledCount;
    }
    ngOnDestroy() {
        this.channelProductsPageSubscription.unsubscribe();
    }
}
exports.SiemChannelProductsStepComponent = SiemChannelProductsStepComponent;
