"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OktaCellProviderPipe = void 0;
class OktaCellProviderPipe {
    constructor(translateService) {
        this.translateService = translateService;
    }
    transform(input) {
        switch (input) {
            case 'US_CELL_1':
                return this.translateService.instant('$I18N_OKTA_INTEGRATION_CELL_PROVIDER_PIPE.' + input);
            case 'US_CELL_2':
                return this.translateService.instant('$I18N_OKTA_INTEGRATION_CELL_PROVIDER_PIPE.' + input);
            case 'US_CELL_3':
                return this.translateService.instant('$I18N_OKTA_INTEGRATION_CELL_PROVIDER_PIPE.' + input);
            case 'US_CELL_4':
                return this.translateService.instant('$I18N_OKTA_INTEGRATION_CELL_PROVIDER_PIPE.' + input);
            case 'US_CELL_5':
                return this.translateService.instant('$I18N_OKTA_INTEGRATION_CELL_PROVIDER_PIPE.' + input);
            case 'US_CELL_6':
                return this.translateService.instant('$I18N_OKTA_INTEGRATION_CELL_PROVIDER_PIPE.' + input);
            case 'US_CELL_7':
                return this.translateService.instant('$I18N_OKTA_INTEGRATION_CELL_PROVIDER_PIPE.' + input);
            case 'APAC_CELL_1':
                return this.translateService.instant('$I18N_OKTA_INTEGRATION_CELL_PROVIDER_PIPE.' + input);
            case 'US_CELL_10':
                return this.translateService.instant('$I18N_OKTA_INTEGRATION_CELL_PROVIDER_PIPE.' + input);
            case 'US_CELL_11':
                return this.translateService.instant('$I18N_OKTA_INTEGRATION_CELL_PROVIDER_PIPE.' + input);
            case 'US_CELL_12':
                return this.translateService.instant('$I18N_OKTA_INTEGRATION_CELL_PROVIDER_PIPE.' + input);
            case 'EMEA_CELL_1':
                return this.translateService.instant('$I18N_OKTA_INTEGRATION_CELL_PROVIDER_PIPE.' + input);
            case 'EMEA_CELL_2':
                return this.translateService.instant('$I18N_OKTA_INTEGRATION_CELL_PROVIDER_PIPE.' + input);
            case 'PREVIEW_CELL_1':
                return this.translateService.instant('$I18N_OKTA_INTEGRATION_CELL_PROVIDER_PIPE.' + input);
            case 'PREVIEW_CELL_2':
                return this.translateService.instant('$I18N_OKTA_INTEGRATION_CELL_PROVIDER_PIPE.' + input);
            case 'PREVIEW_CELL_3':
                return this.translateService.instant('$I18N_OKTA_INTEGRATION_CELL_PROVIDER_PIPE.' + input);
            default:
                return this.translateService.instant('$I18N_OKTA_INTEGRATION_CELL_PROVIDER_PIPE.OTHER');
        }
    }
}
exports.OktaCellProviderPipe = OktaCellProviderPipe;
