"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgEndpointsListApiService = void 0;
const operators_1 = require("rxjs/operators");
class SwgEndpointsListApiService {
    constructor(http) {
        this.http = http;
    }
    getEndpointsList(config) {
        return this.http.post('/api/swg/get-endpoints', config);
    }
    getFile() {
        const fileUrl = '/api/swg/export-endpoints?forceDownload=true';
        return this.http
            .post('/download/get-file-link', { url: fileUrl })
            .pipe(operators_1.map((response) => response.body));
    }
    purgeEndpoints(data) {
        return this.http
            .post('/api/swg/delete-endpoints', {
            data: [
                {
                    idList: data
                }
            ]
        })
            .pipe(operators_1.map(response => {
            return response.first;
        }));
    }
}
exports.SwgEndpointsListApiService = SwgEndpointsListApiService;
