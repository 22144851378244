"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./containers/mailbox/mailbox");
require("./containers/snapshot/snapshot");
require("./containers/tasks/tasks");
require("./containers/export/export");
require("./containers/restore/restore");
angular
    .module('sync-recover.exchange', [
    'sync-recover.exchange.mailbox',
    'sync-recover.exchange.tasks',
    'sync-recover.exchange.snapshot',
    'sync-recover.exchange.restore',
    'sync-recover.exchange.export'
])
    .controller('ExchangeController', function () { });
