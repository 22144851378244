"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApPolicySummaryStepComponent = void 0;
const core_1 = require("@angular/core");
const reducers = require("../../../reducers");
const operators_1 = require("rxjs/operators");
class ApPolicySummaryStepComponent {
    constructor(fb, store) {
        this.fb = fb;
        this.store = store;
        this.updateApSummaryType = new core_1.EventEmitter();
    }
    ngOnInit() {
        this.summaryApPolicyDetails$ = this.store.select(reducers.getApPolicyDesc);
        this.summaryApPolicyInboundSetting$ = this.store.select(reducers.getApPolicyInbound);
        this.summaryApPolicyJournalSetting$ = this.store.select(reducers.getApPolicyJournal);
        this.summaryApPolicyAppliesToSetting$ = this.store.select(reducers.getPolicyAppliesTo);
        this.summaryApPolicyOutboundSetting$ = this.store.select(reducers.getApPolicyOutbound);
        this.apSummaryStatusForm = this.fb.group({
            PolicyRuleStatus: ['']
        });
        this.apSummaryStatusForm.patchValue(this.policySummaryPolicyStatus);
        this.apPolicySummaryFormSubscription = this.apSummaryStatusForm.valueChanges
            .pipe(operators_1.debounceTime(200))
            .subscribe(() => {
            this.updateApSummaryType.emit(this.apSummaryStatusForm.value);
        });
    }
    ngOnDestroy() {
        this.apPolicySummaryFormSubscription.unsubscribe();
    }
}
exports.ApPolicySummaryStepComponent = ApPolicySummaryStepComponent;
