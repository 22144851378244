"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListComponent = void 0;
const core_1 = require("@angular/core");
const table_store_1 = require("@mimecast-ui/table-store");
const models_1 = require("../../models/models");
const policyActions = require("../../actions/policy.actions");
const tableConfig = require("./list.static-values");
const sidebar_actions_1 = require("../../actions/sidebar.actions");
class ListComponent extends table_store_1.TableBaseComponent {
    constructor(store, stateService, capabilitiesService) {
        super(store, 'MepPolicyList');
        this.store = store;
        this.stateService = stateService;
        this.capabilitiesService = capabilitiesService;
        this.rowClick = new core_1.EventEmitter();
        this.searchBy = {};
        this.filters = {};
        this.actions = tableConfig.actions;
        this.columns = tableConfig.columns;
        this.columnsAlwaysVisible = tableConfig.columnsAlwaysVisible;
        this.filterTypes = tableConfig.filterSearchOptions;
        this.searchOptionsSearchValue = '';
        this.affectedUsers = models_1.MepAffectedUsersEnum;
        this.hasMepPolicyEditPermission$ = this.capabilitiesService.hasCapability('Permission.MEP_EDIT');
    }
    ngOnInit() {
        super.ngOnInit();
    }
    onSearchPolicies(query) {
        this.searchBy = { searchBy: { fieldName: query.filterBy, value: query.search } };
        this.runFilters();
    }
    onSearchClear() {
        this.searchBy = {};
        this.runFilters();
    }
    onRowClick(row) {
        super.onRowClick(row);
        this.store.dispatch(new sidebar_actions_1.MepPolicyListSidebarOpen(row));
    }
    goToCreatePolicy() {
        this.store.dispatch(new policyActions.MepCreatePolicy());
        this.stateService.$state.go('mep-policy-create');
    }
    editPolicy(row) {
        this.store.dispatch(new policyActions.MepPolicyLoadForEditing(row.id));
        this.store.dispatch(new policyActions.MepPolicyGoToEditPage());
    }
    deletePolicy(row) {
        this.store.dispatch(new policyActions.MepDetailDeleteConfirmModalOpen(row));
    }
    isDefault(row) {
        return !!row.defaultPolicy;
    }
    isPolicyEnabled(row) {
        return row.enabled;
    }
    getCreateTime(row) {
        return row.createTime;
    }
    getLastModified(row) {
        return row.lastUpdated;
    }
    runFilters() {
        const payload = Object.assign(Object.assign({}, this.searchBy), this.filterTypes);
        this.onFilterChange(payload);
    }
}
exports.ListComponent = ListComponent;
