"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiGatewayNewCredentialsConfirmationModalComponent = void 0;
const reducers = require("../../../../reducers");
const actions = require("../../../../actions");
class ApiGatewayNewCredentialsConfirmationModalComponent {
    constructor(overlayRef, store, application) {
        this.overlayRef = overlayRef;
        this.store = store;
        this.application = application;
    }
    ngOnInit() {
        this.credentialsDisplayed$ = this.store.select(reducers.isModalShowingCredentials);
    }
    generateApplicationKeys() {
        this.store.dispatch(new actions.GetNewCredentialsAction(this.application));
        this.credentialsDisplayed$.subscribe(areCredentialsDisplayed => {
            if (areCredentialsDisplayed) {
                this.cancel();
            }
        });
    }
    cancel() {
        this.overlayRef.close();
    }
}
exports.ApiGatewayNewCredentialsConfirmationModalComponent = ApiGatewayNewCredentialsConfirmationModalComponent;
