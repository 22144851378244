"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SFCDetectionTimelineComponent = void 0;
const detection_timeline_static_values_1 = require("./detection-timeline.static-values");
class SFCDetectionTimelineComponent {
    constructor() {
        this.CHART_NAME = 'detections_timeline';
        this.multilineChartConfiguration = null;
    }
    exportChart(format) {
        this.multiLineGraphChartComponent.exportChart(format, this.CHART_NAME);
    }
    ngOnInit() {
        this.multilineChartConfiguration = detection_timeline_static_values_1.createConfig(this.data);
    }
    ngOnChanges(changes) {
        if (changes.hasOwnProperty('chartData')) {
            this.rerunChart();
        }
    }
    rerunChart() {
        this.multilineChartConfiguration = null;
        setTimeout(() => {
            this.multilineChartConfiguration = detection_timeline_static_values_1.createConfig(this.data);
        }, 100);
    }
}
exports.SFCDetectionTimelineComponent = SFCDetectionTimelineComponent;
