"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UrlProtectionPolicyDetailsStepComponent = void 0;
const forms_1 = require("@angular/forms");
const url_protection_policy_details_step_static_values_1 = require("./url-protection-policy-details-step.static-values");
const actions = require("../../../actions/policies-create.actions");
const operators_1 = require("rxjs/operators");
const policies_create_actions_1 = require("../../../actions/policies-create.actions");
const policy_wizard_models_1 = require("../../../models/policy-wizard.models");
class UrlProtectionPolicyDetailsStepComponent {
    constructor(fb, store, translateService) {
        this.fb = fb;
        this.store = store;
        this.translateService = translateService;
        this.bypassApplicationOptions = url_protection_policy_details_step_static_values_1.bypassApplicationOptions;
        this.durationOptions = url_protection_policy_details_step_static_values_1.ulrProtectionPolicyDuration;
        this.urlProtectionPolicyDetailsForm = this.fb.group({
            name: ['', forms_1.Validators.required],
            description: [''],
            bypassPolicy: [false, forms_1.Validators.required],
            bypassPolicyApplication: [url_protection_policy_details_step_static_values_1.bypassApplicationOptions[0].id],
            policyOverride: [false],
            policyEnforcement: [false],
            ulrProtectionPolicyDuration: [this.durationOptions.dateRanges[0]]
        });
        this.bypassPolicyValueChangeSubscription = this.urlProtectionPolicyDetailsForm
            .get('bypassPolicy')
            .valueChanges.subscribe(value => {
            this.bypassUrlProtection(value);
        });
        this.formValueChangeSubscription = this.urlProtectionPolicyDetailsForm.valueChanges
            .pipe(operators_1.debounceTime(300))
            .subscribe(data => this.saveStoreData(data));
    }
    ngOnInit() {
        this.saveStoreData(this.urlProtectionPolicyDetailsForm.value);
    }
    saveStoreData(data) {
        this.store.dispatch(new policies_create_actions_1.SaveDetailsStepDataAction(data));
    }
    isBypass() {
        return this.urlProtectionPolicyDetailsForm.get('bypassPolicy').value;
    }
    bypassUrlProtection(value) {
        this.store.dispatch(new actions.BypassUrlProtectionPolicy(value));
    }
    isValid() {
        const nameValue = this.urlProtectionPolicyDetailsForm.get('name').value;
        return (!!nameValue &&
            nameValue.trim().length > 0 &&
            this.urlProtectionPolicyDetailsForm.status === 'VALID');
    }
    ngOnChanges(changes) {
        const policyDetailsChange = changes['policyDetailsData'];
        const wizardFlowTypeChange = changes['wizardFlowType'];
        this.setPolicyDurationOptions(policyDetailsChange);
        this.setPolicyNameCopy(wizardFlowTypeChange);
    }
    setPolicyDurationOptions(policyDetailsChange) {
        if (!policyDetailsChange) {
            return;
        }
        if (policyDetailsChange.currentValue &&
            policyDetailsChange.previousValue !== policyDetailsChange.currentValue) {
            if (policyDetailsChange.currentValue['ulrProtectionPolicyDuration'].id ===
                policy_wizard_models_1.DurationDetailOption.USER_DEFINED) {
                this.updateChangeForPolicyDetails(policyDetailsChange);
            }
            this.urlProtectionPolicyDetailsForm.patchValue(policyDetailsChange.currentValue);
        }
    }
    updateChangeForPolicyDetails(policyDetailsChange) {
        while (this.durationOptions.dateRanges.length > 3) {
            this.durationOptions.dateRanges.pop();
        }
        this.durationOptions.dateRanges.push(Object.assign({}, policyDetailsChange.currentValue['ulrProtectionPolicyDuration']));
        this.durationOptions.initialDateRange = 3;
    }
    setPolicyNameCopy(wizardFlowTypeChange) {
        if (this.wizardFlowType !== policy_wizard_models_1.PolicyWizardFlowType.DUPLICATE) {
            return;
        }
        if (wizardFlowTypeChange && wizardFlowTypeChange.currentValue) {
            const nameValue = this.urlProtectionPolicyDetailsForm.get('name').value;
            this.urlProtectionPolicyDetailsForm
                .get('name')
                .setValue(`${this.translateService.instant('$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.POLICY_NAME_COPY')} ${nameValue}`);
        }
    }
    ngOnDestroy() {
        this.bypassPolicyValueChangeSubscription.unsubscribe();
        this.formValueChangeSubscription.unsubscribe();
    }
}
exports.UrlProtectionPolicyDetailsStepComponent = UrlProtectionPolicyDetailsStepComponent;
