"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
(function () {
    'use strict';
    /* ======================================================== *\

   = ServerConnections Edit - Service

   \* ======================================================== */
    angular.module('services.services.server-connections-edit.service', [])
        .factory('serverConnectionEditService', ['$aside', 'serverConnectionService', 'capabilitiesService', function ($aside, serverConnectionService, capabilitiesService) {
            /* -------------------------------------------------------- *\
       - interface
       \* -------------------------------------------------------- */
            return {
                hasServerConnectionEditPermission,
                errorMap: {
                    'ews': {
                        'err_server_connection_http_failure': {
                            message: '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_TEST_HTTP_FAILURE_MESSAGE',
                            title: '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_TEST_HTTP_FAILURE_TITLE'
                        },
                        'err_server_connection_io_failure': {
                            message: '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_TEST_IO_FAILURE_MESSAGE',
                            title: '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_TEST_IO_FAILURE_TITLE'
                        },
                        'err_server_connection_mailbox_not_found': {
                            message: '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_TEST_MAILBOX_FAILURE_MESSAGE',
                            title: '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_TEST_MAILBOX_FAILURE_TITLE'
                        },
                        'err_server_connection_authentication_failure': {
                            message: '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_TEST_AUTH_FAILURE_MESSAGE_EWS',
                            title: '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_TEST_AUTH_FAILURE_TITLE'
                        },
                        'err_server_connection_impersonation_rights': {
                            message: '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_TEST_MAILBOX_IMPERSONATION_FAILURE_MESSAGE',
                            title: '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_TEST_MAILBOX_IMPERSONATION_FAILURE_TITLE'
                        },
                        'err_server_connection_unexpected_error': {
                            message: '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_TEST_UNEXPECTED_FAILURE_MESSAGE',
                            title: '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_TEST_FAILURE_MESSAGE_LABEL'
                        },
                        'err_server_connection_invalid_ews_url': {
                            message: '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_TEST_INVALID_EWS_URL_FAILURE_MESSAGE',
                            title: '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_TEST_HTTP_FAILURE_TITLE'
                        },
                        'err_server_connection_private_ews_url': {
                            message: '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_TEST_PRIVATE_EWS_URL_FAILURE_MESSAGE',
                            title: '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_TEST_HTTP_FAILURE_TITLE'
                        },
                        'err_server_connection_ews_url_dns_failure': {
                            message: '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_TEST_EWS_URL_DNS_FAILURE_MESSAGE',
                            title: '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_TEST_HTTP_FAILURE_TITLE'
                        }
                    },
                    'office_365': {
                        'err_server_connection_http_failure': {
                            message: '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_TEST_HTTP_FAILURE_MESSAGE',
                            title: '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_TEST_HTTP_FAILURE_TITLE'
                        },
                        'err_server_connection_io_failure': {
                            message: '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_TEST_IO_FAILURE_MESSAGE',
                            title: '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_TEST_IO_FAILURE_TITLE'
                        },
                        'err_server_connection_mailbox_not_found': {
                            message: '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_TEST_MAILBOX_FAILURE_MESSAGE',
                            title: '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_TEST_MAILBOX_FAILURE_TITLE'
                        },
                        'err_server_connection_authentication_failure': {
                            message: '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_TEST_AUTH_FAILURE_MESSAGE_O365',
                            title: '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_TEST_AUTH_FAILURE_TITLE'
                        },
                        'err_server_connection_impersonation_rights': {
                            message: '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_TEST_MAILBOX_IMPERSONATION_FAILURE_MESSAGE',
                            title: '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_TEST_MAILBOX_IMPERSONATION_FAILURE_TITLE'
                        },
                        'err_server_connection_unexpected_error': {
                            message: '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_TEST_UNEXPECTED_FAILURE_MESSAGE',
                            title: '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_TEST_FAILURE_MESSAGE_LABEL'
                        },
                    }
                }
            };
            /* -------------------------------------------------------- *\
       - implementation
       \* -------------------------------------------------------- */
            function hasServerConnectionEditPermission() {
                return capabilitiesService.evalCapabilitiesExpression('Permission.SERVER_CONNECTIONS_EDIT');
            }
        }]);
}());
