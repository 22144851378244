<mc-modal-header
    headerTitle="{{ '$I18N_MEP_DELETE_CONFIRMATION_MODAL.TITLE' | translate }}"
></mc-modal-header>

<mc-modal-body *ngIf="policy$ | async; let row">
    <p data-test="mepDeleteConfirmationBody">
        {{ '$I18N_MEP_DELETE_CONFIRMATION_MODAL.BODY.START' | translate }}
        <strong data-test="mepDeleteConfirmationPolicyName">{{ row.name }}</strong>
        {{ '$I18N_MEP_DELETE_CONFIRMATION_MODAL.BODY.END' | translate }}
    </p>
</mc-modal-body>

<mc-modal-footer *ngIf="policy$ | async; let row">
    <div data-test="mepDeleteConfirmationFooterBtns">
        <button
            type="button"
            class="btn btn-secondary"
            data-test="mepDeleteConfirmationCancelBtn"
            (click)="onCancel()">
            {{ '$I18N_COMMON_BTN_CANCEL' | translate }}
        </button>
        <mc-live-button
            overrideClasses="btn btn-danger"
            [label]="'$I18N_COMMON_BTN_DELETE' | translate"
            [isLoading]="isDeleting$ | async"
            (mcClick)="onDelete(row)">
        </mc-live-button>
    </div>
</mc-modal-footer>
