<mc-layout-wrapper-panel-simple [defaultTitle]="title">
  <div class="no-data-container" *ngIf="!data || data === null || data.value === 0">
    <div class="no-data-content">{{ noDataLabel }}</div>
  </div>
  <div class="chart-container" *ngIf="data && data !== null && data.value !== 0">
    <mc-chart>
      <mc-doughnut-chart [data]="data" class="mc-amchart"> </mc-doughnut-chart>
    </mc-chart>
  </div>
</mc-layout-wrapper-panel-simple>
