"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LayoutAsideDirective = void 0;
const core_1 = require("@angular/core");
const layout_base_component_1 = require("./layout-base.component");
class LayoutAsideDirective extends layout_base_component_1.LayoutBaseDirective {
    constructor() {
        super(...arguments);
        this.isLoading = true;
        this.showClose = false;
        this.closeAside = new core_1.EventEmitter();
    }
    // eslint-disable-next-line @angular-eslint/no-input-rename
    set valueShowClose(value) {
        if (typeof value === 'boolean') {
            this.showClose = value;
        }
        else {
            this.showClose = value === 'true';
        }
    }
}
exports.LayoutAsideDirective = LayoutAsideDirective;
