"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewQueueDetailsComponent = void 0;
const core_1 = require("@angular/core");
class ViewQueueDetailsComponent {
    constructor() {
        this.loading = false;
        this.hasEditPermissions = false;
        this.onLoadDetails = new core_1.EventEmitter();
        this.onEdit = new core_1.EventEmitter();
        this.onClose = new core_1.EventEmitter();
        this.onDelete = new core_1.EventEmitter();
    }
    close() {
        this.onClose.emit();
    }
    edit() {
        this.close();
        this.onEdit.emit(this.paginationInfo.currentEntity);
    }
    loadQueueDetails(queue) {
        this.onLoadDetails.emit(queue);
    }
    deleteQueueConfig(selectedQueueConfig) {
        this.close();
        this.onDelete.emit(selectedQueueConfig);
    }
}
exports.ViewQueueDetailsComponent = ViewQueueDetailsComponent;
