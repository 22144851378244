"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MepLogAdminActionFailure = exports.MepLogAdminActionSuccess = exports.MepLogAdminAction = exports.MepLogsOpenExportSidebar = exports.MepLogsActionsEnums = void 0;
var MepLogsActionsEnums;
(function (MepLogsActionsEnums) {
    MepLogsActionsEnums["MEP_LOGS_OPEN_EXPORT_SIDEBAR"] = "[MEP Logs] MEP Logs Open Export Sidebar";
    MepLogsActionsEnums["MEP_LOG_ADMIN_LOG_ACTION"] = "[MEP Logs] MEP Logs Admin Action";
    MepLogsActionsEnums["MEP_LOG_ADMIN_LOG_ACTION_SUCCESS"] = "[MEP Logs] MEP Logs Admin Action Success";
    MepLogsActionsEnums["MEP_LOG_ADMIN_LOG_ACTION_FAILURE"] = "[MEP Logs] MEP Logs Admin Action Failure";
})(MepLogsActionsEnums = exports.MepLogsActionsEnums || (exports.MepLogsActionsEnums = {}));
class MepLogsOpenExportSidebar {
    constructor(tableColumns) {
        this.tableColumns = tableColumns;
        this.type = MepLogsActionsEnums.MEP_LOGS_OPEN_EXPORT_SIDEBAR;
    }
}
exports.MepLogsOpenExportSidebar = MepLogsOpenExportSidebar;
class MepLogAdminAction {
    constructor(payload) {
        this.payload = payload;
        this.type = MepLogsActionsEnums.MEP_LOG_ADMIN_LOG_ACTION;
    }
}
exports.MepLogAdminAction = MepLogAdminAction;
class MepLogAdminActionSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = MepLogsActionsEnums.MEP_LOG_ADMIN_LOG_ACTION_SUCCESS;
    }
}
exports.MepLogAdminActionSuccess = MepLogAdminActionSuccess;
class MepLogAdminActionFailure {
    constructor() {
        this.type = MepLogsActionsEnums.MEP_LOG_ADMIN_LOG_ACTION_FAILURE;
    }
}
exports.MepLogAdminActionFailure = MepLogAdminActionFailure;
