"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BypassApplicationOptions = exports.DurationDetailOption = exports.PolicyWizardFlowType = void 0;
var PolicyWizardFlowType;
(function (PolicyWizardFlowType) {
    PolicyWizardFlowType["CREATE"] = "create";
    PolicyWizardFlowType["DUPLICATE"] = "duplicate";
    PolicyWizardFlowType["EDIT"] = "edit";
})(PolicyWizardFlowType = exports.PolicyWizardFlowType || (exports.PolicyWizardFlowType = {}));
var DurationDetailOption;
(function (DurationDetailOption) {
    DurationDetailOption["CONTINUOUS"] = "continuous";
    DurationDetailOption["DATE_RANGE"] = "CUSTOM";
    DurationDetailOption["USER_DEFINED"] = "USER_DEFINED";
})(DurationDetailOption = exports.DurationDetailOption || (exports.DurationDetailOption = {}));
var BypassApplicationOptions;
(function (BypassApplicationOptions) {
    BypassApplicationOptions["DISABLE_URL_PROTECT"] = "disable_url_protect";
    BypassApplicationOptions["TAKE_NO_ACTION"] = "no_action";
})(BypassApplicationOptions = exports.BypassApplicationOptions || (exports.BypassApplicationOptions = {}));
