"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DoughnutChartComponent = void 0;
const charts_1 = require("@mimecast-ui/charts");
const charts_2 = require("@amcharts/amcharts4/charts");
const core_1 = require("@amcharts/amcharts4/core");
class DoughnutChartComponent extends charts_1.BaseChartComponent {
    constructor(zone, hostElement) {
        super(zone, hostElement, charts_2.PieChart);
        this.zone = zone;
        this.hostElement = hostElement;
    }
    createChartSeries(data) {
        if (!data) {
            return [];
        }
        const colorSet = new core_1.ColorSet();
        if (data.colors) {
            colorSet.list = data.colors.map(function (col) {
                return core_1.color(col);
            });
        }
        return data.series.map(serie => {
            const pieSerie = new charts_2.PieSeries();
            pieSerie.data = serie.categories;
            pieSerie.dataFields.value = 'value';
            pieSerie.dataFields.category = 'name';
            if (colorSet.list.length > 0) {
                pieSerie.colors = colorSet;
            }
            pieSerie.labels.template.disabled = true;
            pieSerie.ticks.template.disabled = true;
            pieSerie.tooltip.disabled = true;
            pieSerie.slices.template.states.getKey('active').properties.shiftRadius = 0;
            pieSerie.slices.template.states.getKey('hover').properties.scale = 1;
            pieSerie.legendSettings.valueText = '{undefined}';
            pieSerie.legendSettings.labelText = '{text}:\n{value} ({value.percent.formatNumber("#.")}%)';
            return pieSerie;
        });
    }
    createChartLegend() {
        const legend = new charts_2.Legend();
        legend.id = 'chart-legend';
        legend.position = 'right';
        legend.align = 'left';
        legend.labels.template.valign = 'middle';
        legend.labels.template.align = 'left';
        legend.clickable = false;
        legend.contextMenuDisabled = true;
        legend.interactionsEnabled = false;
        legend.width = 150;
        legend.labels.template.truncate = false;
        legend.labels.template.wrap = false;
        legend.itemContainers.template.margin(0, 0, 0, 0);
        legend.itemContainers.template.padding(10, 0, 0, 0);
        this.setupLegendMarkers(legend);
        return legend;
    }
    setupLegendMarkers(legend) {
        legend.useDefaultMarker = true;
        const marker = legend.markers.template.children.getIndex(0);
        marker.cornerRadius(12, 12, 12, 12);
        marker.width = 12;
        marker.height = 12;
        marker.strokeWidth = 0;
        marker.strokeOpacity = 1;
        marker.padding(0, 0, 0, 0);
        marker.margin(0, 0, 0, 0);
        marker.valign = 'top';
        marker.align = 'left';
    }
    getChartLabels(value, label) {
        const totalCount = new core_1.Label();
        totalCount.text =
            '[font-size:23px line-height:30px]' + this.chart.numberFormatter.format(value) + '[/]';
        totalCount.horizontalCenter = 'middle';
        totalCount.verticalCenter = 'middle';
        totalCount.paddingTop = -15;
        totalCount.fontWeight = 'bold';
        totalCount.fontSize = '15';
        const totalLabel = new core_1.Label();
        totalLabel.text = '[font-size:14px line-height:14px]' + label + '[/]';
        totalLabel.horizontalCenter = 'middle';
        totalLabel.verticalCenter = 'middle';
        totalLabel.paddingTop = 25;
        totalLabel.fontWeight = 'bold';
        return [totalCount, totalLabel];
    }
    setupChart(data) {
        if (data) {
            this.chart.innerRadius = core_1.percent(98);
            this.chart.chartAndLegendContainer.horizontalCenter = 'middle';
            this.chart.chartAndLegendContainer.align = 'center';
            this.chart.chartAndLegendContainer.contentAlign = 'center';
            this.chart.chartContainer.width = 150;
            this.chart.align = 'left';
            this.chart.horizontalCenter = 'left';
            this.chart.series.pushAll(this.createChartSeries(data));
            this.chart.legend = this.createChartLegend();
            this.chart.seriesContainer.children.pushAll(this.getChartLabels(data.value, data.text));
        }
    }
    ngAfterViewInit() {
        super.ngAfterViewInit();
        this.setupChart(this.data);
    }
}
exports.DoughnutChartComponent = DoughnutChartComponent;
