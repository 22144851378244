"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailPreviewComponent = void 0;
const rxjs_1 = require("rxjs");
const messageDetailsAction = require("../../../actions/message-details/message-details.actions");
const reducers = require("../../../reducers");
class EmailPreviewComponent {
    constructor(store, emailBodyService, translate) {
        this.store = store;
        this.emailBodyService = emailBodyService;
        this.translate = translate;
        this.showHtml$ = new rxjs_1.BehaviorSubject(true);
        this.displayImagesNotification = true;
        this.isLoadingMessageWithImages$ = new rxjs_1.BehaviorSubject(false);
        this.message$ = this.store.select(reducers.getMessageDetails);
        this.htmlBody$ = new rxjs_1.BehaviorSubject('');
        this.showEmbeddedImages = false;
        this.options = [
            { value: true, label: this.translate.instant('$I18N_REMEDIATION_EMAIL_PREVIEW.DISPLAY_HTML') },
            {
                value: false,
                label: this.translate.instant('$I18N_REMEDIATION_EMAIL_PREVIEW.DISPLAY_PLAIN_TEXT')
            }
        ];
    }
    ngOnDestroy() {
        if (this.messageSubscription) {
            this.messageSubscription.unsubscribe();
        }
        if (this.emailBodySubscription) {
            this.emailBodySubscription.unsubscribe();
        }
    }
    ngOnInit() {
        this.displayImages = {
            label: 'Display Images',
            type: 'button',
            callback: () => {
                this.loadMessageWithImages();
            }
        };
        this.imagesNotification = {
            status: 'warning',
            msg: this.translate.instant('$I18N_REMEDIATION_EMAIL_PREVIEW.DISPLAY_IMAGES_ALERT'),
            actions: [this.displayImages],
            boxed: true
        };
        this.messageSubscription = this.message$.subscribe((message) => {
            this.displayMessage = message;
            if (this.isLoadingMessageWithImages$.getValue()) {
                if (message.htmlBody) {
                    const attachments = message.attachments || [];
                    this.emailBodyService.getProcessedHtml(message.htmlBody, 
                    // @ts-ignore
                    attachments, '/ttp/remediation/get-file');
                    this.emailBodySubscription = this.emailBodyService.displayImages().subscribe(htmlBody => {
                        this.htmlBody$.next(htmlBody);
                        this.messageSubscription.unsubscribe();
                        this.isLoadingMessageWithImages$.next(false);
                    });
                }
                else {
                    this.htmlBody$.next(this.translate.instant('$I18N_REMEDIATION_EMAIL_PREVIEW.NO_IMAGES'));
                    this.messageSubscription.unsubscribe();
                    this.isLoadingMessageWithImages$.next(false);
                }
            }
            else {
                this.htmlBody$.next(message.htmlBody);
            }
        });
    }
    selectChange(event) {
        this.showHtml$.next(event);
    }
    loadMessageWithImages() {
        const stripImages = false;
        this.displayImagesNotification = false;
        this.showEmbeddedImages = true;
        this.isLoadingMessageWithImages$.next(true);
        this.store.dispatch(
        // @ts-ignore
        new messageDetailsAction.GetMessageDetailsWithImagesAction(this.displayMessage, stripImages));
    }
}
exports.EmailPreviewComponent = EmailPreviewComponent;
