"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LexiconBaseBuilder = void 0;
class LexiconBaseBuilder {
    withId(id) {
        this.id = id;
        return this;
    }
    withName(name) {
        this.name = name;
        return this;
    }
    withDescription(description) {
        this.description = description;
        return this;
    }
    withCreatedBy(createdBy) {
        this.createdBy = createdBy;
        return this;
    }
    withUpdatedBy(updatedBy) {
        this.updatedBy = updatedBy;
        return this;
    }
    withCreated(created) {
        this.created = created;
        return this;
    }
    withUpdatedDate(updated) {
        this.updated = updated;
        return this;
    }
    withStatus(status) {
        this.status = status;
        return this;
    }
    withSearchType(searchType) {
        this.searchType = searchType;
        return this;
    }
    withAssociatedRules(associatedRules) {
        this.associatedRules = associatedRules;
        return this;
    }
}
exports.LexiconBaseBuilder = LexiconBaseBuilder;
