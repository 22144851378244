"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FieldName = exports.AppliesFromToOptions = exports.AddressesBasedOnOptions = void 0;
var AddressesBasedOnOptions;
(function (AddressesBasedOnOptions) {
    AddressesBasedOnOptions["ENVELOPE"] = "envelope_from";
    AddressesBasedOnOptions["HEADER"] = "header_from";
    AddressesBasedOnOptions["ENVELOPE_HEADER"] = "both";
})(AddressesBasedOnOptions = exports.AddressesBasedOnOptions || (exports.AddressesBasedOnOptions = {}));
var AppliesFromToOptions;
(function (AppliesFromToOptions) {
    AppliesFromToOptions["ALL"] = "everyone";
    AppliesFromToOptions["DOMAIN"] = "email_domain";
    AppliesFromToOptions["ADDRESS_GROUP"] = "profile_group";
    AppliesFromToOptions["EMAIL_ADDRESS"] = "individual_email_address";
})(AppliesFromToOptions = exports.AppliesFromToOptions || (exports.AppliesFromToOptions = {}));
var FieldName;
(function (FieldName) {
    FieldName["APPLIES_FROM_ADDRESS_GROUP"] = "appliesFromAddressGroup";
    FieldName["APPLIES_TO_ADDRESS_GROUP"] = "appliesToAddressGroup";
    FieldName["APPLIES_TO"] = "appliesTo";
    FieldName["APPLIES_FROM"] = "appliesFrom";
    FieldName["IP_RANGE"] = "ipRange";
})(FieldName = exports.FieldName || (exports.FieldName = {}));
