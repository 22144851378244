
    <mc-cortex-integration-aside-view
      [integration]="integration"
      [cortexIntegrationVersion1]="cortexIntegrationVersion1$ | async"
      [enabled]="enabled$ | async"
      (onEnableClicked)="enable()"
      (onDisableClicked)="disable()"
      (onEditClicked)="edit()"
      (onCloseClicked)="close()"
    >
    </mc-cortex-integration-aside-view>
  