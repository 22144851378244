"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CyberGraphAppliesToSettingsStepComponent = void 0;
const forms_1 = require("@angular/forms");
const domain_validator_1 = require("app-new/components/validators/domain/domain.validator");
const rxjs_1 = require("rxjs");
const cybergraph_policies_create_actions_1 = require("../../../actions/cybergraph-policies-create.actions");
const cybergraph_applies_to_settings_step_component_static_values_1 = require("./cybergraph-applies-to-settings-step.component.static-values");
const cybergraph_applies_to_settings_step_models_1 = require("./cybergraph-applies-to-settings-step.models");
const group_selector_component_1 = require("app-new/components/group-selector/group-selector.component");
const url_protection_applies_to_settings_step_models_1 = require("app-new/services/url-protection/policies/components/steps/url-protection-applies-to-settings-step/url-protection-applies-to-settings-step.models");
const ipv4_validator_1 = require("app-new/services/common/policies/validators/ipv4-validator");
class CyberGraphAppliesToSettingsStepComponent {
    constructor(fb, store, asideService) {
        this.fb = fb;
        this.store = store;
        this.asideService = asideService;
        this.initOfFromToAttributesFinished = false;
        this.fieldName = cybergraph_applies_to_settings_step_models_1.FieldName;
        this.noFromToAddressAttributesOption = cybergraph_applies_to_settings_step_component_static_values_1.noFromToAddressAttributesOption;
        this.addressesBasedOnOptions = cybergraph_applies_to_settings_step_component_static_values_1.addressesBasedOnOptions;
        this.appliesFromToOptions = cybergraph_applies_to_settings_step_component_static_values_1.appliesFromToOptions;
        this.ipRanges = [[]];
        this.editRuleIds = [];
        this.appliesFromToAddresssValidators = [
            forms_1.Validators.required,
            forms_1.Validators.email,
            forms_1.Validators.maxLength(128)
        ];
        this.appliesFromToDomainValidators = [forms_1.Validators.required, domain_validator_1.domainValidator];
        this.emailAddressFieldType = cybergraph_applies_to_settings_step_component_static_values_1.AdditionalFieldsType.emailAddress;
        this.domainFieldType = cybergraph_applies_to_settings_step_component_static_values_1.AdditionalFieldsType.domain;
        this.groupFieldType = cybergraph_applies_to_settings_step_component_static_values_1.AdditionalFieldsType.addressGroup;
        this.cyberGraphPolicyAppliesToSettingsForm = this.fb.group({
            rules: this.fb.array([this.createNewRule()])
        });
        this.rules = this.cyberGraphPolicyAppliesToSettingsForm.get('rules');
        this.rulesValueChangeSubscription = rxjs_1.combineLatest([
            this.rules.valueChanges,
            this.cyberGraphPolicyAppliesToSettingsForm.statusChanges
        ]).subscribe(([data]) => {
            if (Array.isArray(data)) {
                this.storeStepData(data);
            }
        });
    }
    onAppliesFromToSelectChange(emailFromSelected, index, isFromControl = true) {
        const addressControlName = isFromControl ? 'appliesFromAddress' : 'appliesToAddress';
        const domainControlName = isFromControl ? 'appliesFromDomain' : 'appliesToDomain';
        const emailControl = this.rules.at(index).get(addressControlName);
        const domainControl = this.rules.at(index).get(domainControlName);
        if (emailFromSelected === cybergraph_applies_to_settings_step_models_1.AppliesFromToOptions.EMAIL_ADDRESS) {
            emailControl.setValidators(this.appliesFromToAddresssValidators);
        }
        else {
            emailControl.clearValidators();
            emailControl.patchValue('');
        }
        if (emailFromSelected === cybergraph_applies_to_settings_step_models_1.AppliesFromToOptions.DOMAIN) {
            domainControl.setValidators(this.appliesFromToDomainValidators);
        }
        else {
            domainControl.clearValidators();
            domainControl.patchValue('');
        }
        emailControl.updateValueAndValidity();
        domainControl.updateValueAndValidity();
    }
    ngOnInit() {
        this.storeStepData(this.rules.value);
    }
    ngOnChanges(changes) {
        const change = changes['policyAppliesToData'];
        if (change && change.currentValue) {
            this.updateStepWithPassedData(change.currentValue);
        }
    }
    ngOnDestroy() {
        this.rulesValueChangeSubscription.unsubscribe();
    }
    storeStepData(data) {
        if (this.cyberGraphPolicyAppliesToSettingsForm.invalid) {
            return;
        }
        this.store.dispatch(new cybergraph_policies_create_actions_1.SaveAppliesToStepDataAction(data.map((item, index) => {
            const md = {
                addressesBasedOn: url_protection_applies_to_settings_step_models_1.AddressesBasedOnOptions.ENVELOPE,
                appliesFrom: item.appliesFrom,
                fromWhereAttribute: this.getAttributeById(item.fromWhereAttribute),
                appliesFromAddress: item.appliesFromAddress,
                appliesFromDomain: item.appliesFromDomain,
                appliesFromAddressGroup: item.appliesFromAddressGroup,
                appliesTo: item.appliesTo,
                toWhereAttribute: this.getAttributeById(item.toWhereAttribute),
                appliesToAddress: item.appliesToAddress,
                appliesToDomain: item.appliesToDomain,
                appliesToAddressGroup: item.appliesToAddressGroup,
                bidirectional: true,
                ipRanges: this.ipRanges[index] ? [...this.ipRanges[index]] : []
            };
            if (this.editRuleIds[index]) {
                md.id = this.editRuleIds[index];
            }
            return md;
        })));
    }
    getAttributeById(id) {
        if (id === '' || !this.fromToWhereAttributesExist()) {
            return cybergraph_applies_to_settings_step_component_static_values_1.noFromToAddressAttributesOption[0];
        }
        const item = this.fromToWhereAttributes.find(option => {
            return option.value === id;
        });
        return item ? item : cybergraph_applies_to_settings_step_component_static_values_1.noFromToAddressAttributesOption[0];
    }
    fromToWhereAttributesExist() {
        return this.fromToWhereAttributes && this.fromToWhereAttributes.length > 0;
    }
    createNewRule() {
        return this.fb.group({
            appliesFrom: [this.appliesFromToOptions[0].value],
            fromWhereAttribute: [this.getFromToAttribute()],
            appliesFromAddress: [''],
            appliesFromDomain: [''],
            appliesFromAddressGroup: undefined,
            appliesTo: [this.appliesFromToOptions[0].value],
            toWhereAttribute: [this.getFromToAttribute()],
            appliesToAddress: [''],
            appliesToDomain: [''],
            appliesToAddressGroup: undefined,
            ipRange: ['', ipv4_validator_1.IPV4AndSubnetMaskValidator]
        });
    }
    showAppliesAdditionalField(control, controlType) {
        if (!control.value) {
            return;
        }
        const controls = [
            cybergraph_applies_to_settings_step_models_1.AppliesFromToOptions.DOMAIN,
            cybergraph_applies_to_settings_step_models_1.AppliesFromToOptions.ADDRESS_GROUP,
            cybergraph_applies_to_settings_step_models_1.AppliesFromToOptions.EMAIL_ADDRESS
        ];
        return control.value === controls[controlType];
    }
    getFromToAttribute() {
        return this.fromToWhereAttributesExist()
            ? this.fromToWhereAttributes[0].value
            : this.noFromToAddressAttributesOption[0].value;
    }
    addNewRange(control, groupIndex) {
        if (!control.value) {
            return;
        }
        const value = control.value.trim();
        if (value) {
            this.ipRanges[groupIndex].push(control.value);
            this.storeStepData(this.rules.value);
        }
        control.reset();
    }
    removeRange(groupIndex, index) {
        this.ipRanges[groupIndex].splice(index, 1);
        this.storeStepData(this.rules.value);
    }
    addNewRule() {
        this.ipRanges.push([]);
        this.rules.push(this.createNewRule());
    }
    removeRule(index) {
        this.rules.removeAt(index);
        this.ipRanges.splice(index, 1);
    }
    openSidebar(fieldName, index) {
        const asideConfig = {};
        const aside = this.asideService.open(group_selector_component_1.GroupSelectorComponent, asideConfig);
        aside.beforeClose().subscribe(selectedGroup => {
            if (selectedGroup) {
                this.patchRuleAddressGroup(index, fieldName, selectedGroup);
            }
        });
    }
    onDeleteGroup(fieldName, index) {
        this.patchRuleAddressGroup(index, fieldName, null);
    }
    patchRuleAddressGroup(ruleIndex, ruleFieldName, patchValue) {
        const rule = this.rules.at(ruleIndex);
        rule.get(ruleFieldName).patchValue(patchValue);
        this.rules.setControl(ruleIndex, rule);
    }
    isValid() {
        return this.cyberGraphPolicyAppliesToSettingsForm.status === 'VALID';
    }
    updateStepWithPassedData(rules) {
        if (!rules) {
            return;
        }
        this.ipRanges = [[]];
        this.editRuleIds = [];
        rules.forEach((rule, index) => {
            let toFormValue = Object.assign({}, rule);
            if (toFormValue.fromWhereAttribute) {
                toFormValue = Object.assign(Object.assign({}, toFormValue), { fromWhereAttribute: toFormValue.fromWhereAttribute.value });
            }
            if (toFormValue.toWhereAttribute) {
                toFormValue = Object.assign(Object.assign({}, toFormValue), { toWhereAttribute: toFormValue.toWhereAttribute.value });
            }
            if (index === 0) {
                this.rules.at(index).patchValue(toFormValue);
                if (rule.ipRanges) {
                    this.ipRanges[index].push(...rule.ipRanges);
                }
            }
            else {
                this.addNewRule();
                this.rules.at(index).patchValue(toFormValue);
                if (rule.ipRanges) {
                    this.ipRanges[index].push(...rule.ipRanges);
                }
            }
            this.editRuleIds.push(rule.id);
            this.rules.updateValueAndValidity();
        });
        this.cyberGraphPolicyAppliesToSettingsForm.updateValueAndValidity();
    }
}
exports.CyberGraphAppliesToSettingsStepComponent = CyberGraphAppliesToSettingsStepComponent;
