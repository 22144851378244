"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetDashboardStatisticsFailAction = exports.GetDashboardStatisticsSuccessAction = exports.GetDashboardStatisticsAction = exports.StatisticsActions = void 0;
var StatisticsActions;
(function (StatisticsActions) {
    StatisticsActions["GET_DASHBOARD_STATISTICS"] = "[SupervisionStatistics] Get Dashboard Statistics";
    StatisticsActions["GET_DASHBOARD_STATISTICS_SUCCESS"] = "[SupervisionStatistics] Get Dashboard Statistics Success";
    StatisticsActions["GET_DASHBOARD_STATISTICS_FAIL"] = "[SupervisionStatistics] Get Dashboard Statistics Fail";
})(StatisticsActions = exports.StatisticsActions || (exports.StatisticsActions = {}));
class GetDashboardStatisticsAction {
    constructor() {
        this.type = StatisticsActions.GET_DASHBOARD_STATISTICS;
    }
}
exports.GetDashboardStatisticsAction = GetDashboardStatisticsAction;
class GetDashboardStatisticsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = StatisticsActions.GET_DASHBOARD_STATISTICS_SUCCESS;
    }
}
exports.GetDashboardStatisticsSuccessAction = GetDashboardStatisticsSuccessAction;
class GetDashboardStatisticsFailAction {
    constructor(payload) {
        this.payload = payload;
        this.type = StatisticsActions.GET_DASHBOARD_STATISTICS_FAIL;
    }
}
exports.GetDashboardStatisticsFailAction = GetDashboardStatisticsFailAction;
