"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const static_1 = require("@angular/upgrade/static");
const logs_page_container_component_1 = require("./containers/logs-page/logs-page-container.component");
angular
    .module('ttp.impersonation.logs', [])
    .controller('LogsController', function () { })
    .directive('mcTtpImpersonationLogs', static_1.downgradeComponent({
    component: logs_page_container_component_1.LogsPageContainerComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider.state('ttp-impersonation-logs', {
            url: '/ttp/impersonation/logs',
            data: {
                isFixedLayout: false,
                checkProgress: false,
                tabReload: false,
                tabTitle: 'TTP Impersonation Logs'
            },
            views: {
                main: {
                    template: '<mc-ttp-impersonation-logs></mc-ttp-impersonation-logs>',
                    controller: 'LogsController'
                }
            }
        });
    }
]);
