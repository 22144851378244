"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShowIsCreatingDomains = exports.ClearAddDomainsState = exports.CloseAside = exports.CreateDomainsFailure = exports.CreateDomainsSuccess = exports.CreateDomains = exports.RemoveValidDomain = exports.RemoveInvalidDomain = exports.ShowDomainsSettings = exports.ShowDomainsValidation = exports.SHOW_IS_CREATING_DOMAINS = exports.CLEAR_ADD_DOMAINS_STATE = exports.CLOSE_ASIDE = exports.CREATE_DOMAINS_FAILURE = exports.CREATE_DOMAINS_SUCCESS = exports.CREATE_DOMAINS = exports.REMOVE_VALID_DOMAIN = exports.REMOVE_INVALID_DOMAIN = exports.SHOW_DOMAINS_SETTINGS = exports.SHOW_DOMAINS_VALIDATION = void 0;
exports.SHOW_DOMAINS_VALIDATION = '[External Domain List] SHOW_DOMAINS_VALIDATION';
exports.SHOW_DOMAINS_SETTINGS = '[External Domain List] SHOW_DOMAINS_SETTINGS';
exports.REMOVE_INVALID_DOMAIN = '[External Domain List] REMOVE_INVALID_DOMAIN';
exports.REMOVE_VALID_DOMAIN = '[External Domain List] REMOVE_VALID_DOMAIN';
exports.CREATE_DOMAINS = '[External Domain List] CREATE_DOMAINS';
exports.CREATE_DOMAINS_SUCCESS = '[External Domain List] CREATE_DOMAINS_SUCCESS';
exports.CREATE_DOMAINS_FAILURE = '[External Domain List] CREATE_DOMAINS_FAILURE';
exports.CLOSE_ASIDE = '[External Domain List] CLOSE_ASIDE';
exports.CLEAR_ADD_DOMAINS_STATE = '[External Domain List] CLEAR_ADD_DOMAINS_STATE';
exports.SHOW_IS_CREATING_DOMAINS = '[External Domain List] SHOW_IS_CREATING_DOMAINS';
class ShowDomainsValidation {
    constructor(formValues) {
        this.formValues = formValues;
        this.type = exports.SHOW_DOMAINS_VALIDATION;
    }
}
exports.ShowDomainsValidation = ShowDomainsValidation;
class ShowDomainsSettings {
    constructor() {
        this.type = exports.SHOW_DOMAINS_SETTINGS;
    }
}
exports.ShowDomainsSettings = ShowDomainsSettings;
class RemoveInvalidDomain {
    constructor(domainName) {
        this.domainName = domainName;
        this.type = exports.REMOVE_INVALID_DOMAIN;
    }
}
exports.RemoveInvalidDomain = RemoveInvalidDomain;
class RemoveValidDomain {
    constructor(domainName) {
        this.domainName = domainName;
        this.type = exports.REMOVE_VALID_DOMAIN;
    }
}
exports.RemoveValidDomain = RemoveValidDomain;
class CreateDomains {
    constructor() {
        this.type = exports.CREATE_DOMAINS;
    }
}
exports.CreateDomains = CreateDomains;
class CreateDomainsSuccess {
    constructor(createdDomains) {
        this.createdDomains = createdDomains;
        this.type = exports.CREATE_DOMAINS_SUCCESS;
    }
}
exports.CreateDomainsSuccess = CreateDomainsSuccess;
class CreateDomainsFailure {
    constructor() {
        this.type = exports.CREATE_DOMAINS_FAILURE;
    }
}
exports.CreateDomainsFailure = CreateDomainsFailure;
class CloseAside {
    constructor() {
        this.type = exports.CLOSE_ASIDE;
    }
}
exports.CloseAside = CloseAside;
class ClearAddDomainsState {
    constructor() {
        this.type = exports.CLEAR_ADD_DOMAINS_STATE;
    }
}
exports.ClearAddDomainsState = ClearAddDomainsState;
class ShowIsCreatingDomains {
    constructor() {
        this.type = exports.SHOW_IS_CREATING_DOMAINS;
    }
}
exports.ShowIsCreatingDomains = ShowIsCreatingDomains;
