'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("mimecast-web-components/src/app/services/archive/archive-service"); //api.archive.service
angular.module('email.download.button.directive', ['api.archive.service'])
    .directive('mcEmailDownload', ['apiArchiveService', function (apiArchiveService) {
        return {
            link($scope, $element, $attr) {
                const attachment = $scope.$eval($attr.mcEmailDownload);
                const downloadContentType = $scope.$eval($attr.mcDownloadContentType);
                const mailbox = $scope.$eval($attr.mcDownloadUriMailbox);
                const getDownloadPromise = {
                    MESSAGE() {
                        return apiArchiveService.getEmailDownloadUri(attachment.id);
                    },
                    CALENDAR() {
                        return apiArchiveService.getCalendarDownloadUri(attachment.id, mailbox);
                    },
                    CONTACT() {
                        return apiArchiveService.getContactDownloadUri(attachment.id, mailbox);
                    }
                };
                function createIFrame(attachmentParam) {
                    const iframe = document.createElement('iframe');
                    iframe.style.display = 'none';
                    angular.element($element).parent().append(iframe);
                    iframe.src = attachmentParam.url;
                }
                $element.on('mousedown', function () {
                    getDownloadPromise[downloadContentType]().then(function (response) {
                        if (!response.err) {
                            attachment.url = response.data;
                            createIFrame(attachment);
                        }
                        else {
                            apiArchiveService.getError().showErrorNotification(response);
                        }
                    }, function () {
                        apiArchiveService.getError().showErrorNotification();
                    });
                });
            }
        };
    }]);
