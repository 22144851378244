"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSelectedEmail = exports.isLoading = exports.reducer = exports.initialState = void 0;
const email = require("../actions/email.action");
exports.initialState = {
    results: [],
    isLoading: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case email.EMAIL_LOAD: {
            return Object.assign(Object.assign({}, state), { error: undefined, isLoading: true });
        }
        case email.EMAIL_SUCCESS: {
            return Object.assign(Object.assign({}, state), { selectedEmail: action.payload, isLoading: false });
        }
        case email.EMAIL_LOAD_FAIL: {
            return Object.assign(Object.assign({}, state), { isLoading: false });
        }
        default:
            return state;
    }
}
exports.reducer = reducer;
/**
 * Selectors
 */
const isLoading = (state) => state.isLoading;
exports.isLoading = isLoading;
const getSelectedEmail = (state) => state.selectedEmail;
exports.getSelectedEmail = getSelectedEmail;
