"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogsPageConfig = void 0;
class LogsPageConfig {
    constructor(configData) {
        this.configData = configData;
        this.exportFilter = { filters: '' };
        if (configData) {
            this.columns = this.getColumns();
            this.columnsAlwaysVisible = this.getColumnsAlwaysVisible();
            this.filterSections = this.getFilterSections();
            this.exportColumns = this.getExportColumns();
            this.dateRangeConfig = this.getDateRangeConfig();
            this.exportSetup = this.getExportSetup();
        }
    }
    getColumns() {
        const columns = [];
        if (this.configData.tableColumns) {
            columns.push(...Object.keys(this.configData.tableColumns));
        }
        return columns;
    }
    getColumnsAlwaysVisible() {
        return this.configData.tableColumnsAlwaysVisible
            ? this.configData.tableColumnsAlwaysVisible
            : [];
    }
    getFilterSections() {
        const filterSections = [];
        if (this.configData.filterSections) {
            Object.keys(this.configData.filterSections).forEach(name => {
                const section = {
                    name,
                    filters: Object.keys(this.configData.filterSections[name].filters)
                };
                filterSections.push(section);
            });
        }
        return filterSections;
    }
    getExportColumns() {
        const exportColumns = [];
        if (this.configData.tableColumns) {
            const tableColumns = this.configData.tableColumns;
            Object.keys(tableColumns).forEach(name => {
                if (tableColumns[name].exportValue) {
                    exportColumns.splice(tableColumns[name].exportDisplayOrder, 0, {
                        name,
                        exportValue: tableColumns[name].exportValue
                    });
                }
            });
        }
        return exportColumns;
    }
    getDateRangeConfig() {
        return this.configData.dateRangeConfig;
    }
    getExportSetup() {
        return this.configData.exportSetup;
    }
    setupExportFilter(tableSearchFilter) {
        if (tableSearchFilter.searchQuery) {
            this.exportFilter.search = `${tableSearchFilter.searchQuery}:all`;
        }
        if (tableSearchFilter.from) {
            this.exportFilter.fromdate = new Date(tableSearchFilter.from).getTime().toString();
            this.exportFilter.todate = new Date(tableSearchFilter.to).getTime().toString();
        }
        this.setupFilter(tableSearchFilter);
    }
    setupFilter(tableSearchFilter) {
        this.exportFilter.filters = '';
        if (tableSearchFilter.columnValues && Object.keys(tableSearchFilter.columnValues).length) {
            const filterGroups = Object.keys(tableSearchFilter.columnValues);
            filterGroups.forEach(filterGroupName => {
                this.exportFilter.filters += this.exportFilter.filters !== '' ? '~' : '';
                this.exportFilter.filters += `${this.configData.filterSections[filterGroupName].exportPrefix}:`;
                let identifiers = '';
                tableSearchFilter.columnValues[filterGroupName].forEach(filter => {
                    identifiers += identifiers !== '' ? ',' : '';
                    identifiers += this.configData.filterSections[filterGroupName].filters[filter]
                        .exportValue;
                });
                this.exportFilter.filters += identifiers;
            });
        }
    }
}
exports.LogsPageConfig = LogsPageConfig;
