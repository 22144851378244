"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.appliesToOptions = exports.appliesFromOptions = exports.defaultValueAttributes = exports.addressesBasedOnOptions = void 0;
const models_1 = require("../../../models");
exports.addressesBasedOnOptions = [
    {
        value: models_1.AddressBasedOption.ENVELOPEFROMADDRESSES,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_APPLIES_TO_STEP_LABEL.ADDRESS_BASED_ON_FIELD.OPTIONS.ENVELOPE_FROM_ADDRESS'
    },
    {
        value: models_1.AddressBasedOption.HEADERFROMADDRESSES,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_APPLIES_TO_STEP_LABEL.ADDRESS_BASED_ON_FIELD.OPTIONS.HEADER_FROM_ADDRESS'
    },
    {
        value: models_1.AddressBasedOption.ENVELOPEANDHEADERADDRESSES,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_APPLIES_TO_STEP_LABEL.ADDRESS_BASED_ON_FIELD.OPTIONS.ENVELOPE_AND_HEADER_FROM_ADDRESS'
    }
];
exports.defaultValueAttributes = [
    {
        value: models_1.AttributeDefaultOption.DEFAULT,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_APPLIES_TO_STEP_LABEL.FROM.OPTIONS.EVERYONE'
    }
];
exports.appliesFromOptions = [
    {
        value: models_1.AppliesFrom.EVERYONE,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_APPLIES_TO_STEP_LABEL.FROM.OPTIONS.EVERYONE'
    },
    {
        value: models_1.AppliesFrom.ALLINTERNAL,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_APPLIES_TO_STEP_LABEL.FROM.OPTIONS.ALL_INTERNAL_ADDRESSES'
    },
    {
        value: models_1.AppliesFrom.ALLEXTERNAL,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_APPLIES_TO_STEP_LABEL.FROM.OPTIONS.ALL_EXTERNAL_ADDRESSES'
    },
    {
        value: models_1.AppliesFrom.DOMAIN,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_APPLIES_TO_STEP_LABEL.FROM.OPTIONS.DOMAIN'
    },
    {
        value: models_1.AppliesFrom.ADDRESSGROUP,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_APPLIES_TO_STEP_LABEL.FROM.OPTIONS.ADDRESS_GROUP'
    },
    {
        value: models_1.AppliesFrom.ADDRESSATTRIBUTE,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_APPLIES_TO_STEP_LABEL.FROM.OPTIONS.ADDRESS_ATTRIBUTE'
    },
    {
        value: models_1.AppliesFrom.EMAILADDRESS,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_APPLIES_TO_STEP_LABEL.FROM.OPTIONS.EMAIL_ADDRESSES'
    }
];
exports.appliesToOptions = [
    {
        value: models_1.AppliesTo.EVERYONE,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_APPLIES_TO_STEP_LABEL.TO.OPTIONS.EVERYONE'
    },
    {
        value: models_1.AppliesTo.ALLINTERNAL,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_APPLIES_TO_STEP_LABEL.TO.OPTIONS.ALL_INTERNAL_ADDRESSES'
    },
    {
        value: models_1.AppliesTo.ALLEXTERNAL,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_APPLIES_TO_STEP_LABEL.TO.OPTIONS.ALL_EXTERNAL_ADDRESSES'
    },
    {
        value: models_1.AppliesTo.DOMAIN,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_APPLIES_TO_STEP_LABEL.TO.OPTIONS.DOMAIN'
    },
    {
        value: models_1.AppliesTo.ADDRESSGROUP,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_APPLIES_TO_STEP_LABEL.TO.OPTIONS.ADDRESS_GROUP'
    },
    {
        value: models_1.AppliesTo.ADDRESSATTRIBUTE,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_APPLIES_TO_STEP_LABEL.TO.OPTIONS.ADDRESS_ATTRIBUTE'
    },
    {
        value: models_1.AppliesTo.EMAILADDRESS,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_APPLIES_TO_STEP_LABEL.TO.OPTIONS.EMAIL_ADDRESSES'
    }
];
