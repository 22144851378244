"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateCyberGraphPolicyWizardComponent = void 0;
const cybergraph_policy_wizard_models_1 = require("../../models/cybergraph-policy-wizard.models");
const actions = require("../../actions/cybergraph-policies-create.actions");
const editActions = require("../../actions/cybergraph-policies-edit.actions");
const reducers = require("../../reducers");
const operators_1 = require("rxjs/operators");
class CreateCyberGraphPolicyWizardComponent {
    constructor(store) {
        this.store = store;
        this.SUMMARY_STEP = 3;
        this.currentStep = 1;
        this.isEditMode = false;
        this.store.dispatch(new actions.GetAddressAttributeOptionsAction());
        this.fromToWhereAttributes$ = this.store.select(reducers.getFromToWhereAttributes);
    }
    getPageTitle() {
        if (cybergraph_policy_wizard_models_1.PolicyWizardFlowType.DUPLICATE === this.wizardFlowType) {
            return '$I18N_CYBERGRAPH_POLICY_WIZARD.PAGE_TITLE.DUPLICATE_POLICY';
        }
        else if (cybergraph_policy_wizard_models_1.PolicyWizardFlowType.EDIT === this.wizardFlowType) {
            return '$I18N_CYBERGRAPH_POLICY_WIZARD.PAGE_TITLE.EDIT_POLICY';
        }
        else {
            return '$I18N_CYBERGRAPH_POLICY_WIZARD.PAGE_TITLE.CREATE_POLICY';
        }
    }
    loadPolicyData() {
        this.detailsStepData$ = this.store.select(reducers.getDetailsStepData).pipe(operators_1.first());
        this.appliesToStepData$ = this.store.select(reducers.getAppliesToStepData).pipe(operators_1.first());
    }
    isStepValid() {
        if (this.currentStep === 1) {
            return this.cyberGraphPolicyDetailsStepPage.isValid();
        }
        if (this.currentStep === 2) {
            return this.cyberGraphAppliesToSettingsStepComponent.isValid();
        }
        return true;
    }
    goToStep(event) {
        this.currentStep = event;
    }
    editStep(stepNumber) {
        this.wizard.showStep(stepNumber);
    }
    ngOnChanges(changes) {
        const change = changes['wizardFlowType'];
        if (change && change.previousValue !== change.currentValue) {
            if (change.currentValue) {
                this.isEditMode = cybergraph_policy_wizard_models_1.PolicyWizardFlowType.EDIT === this.wizardFlowType;
                this.loadPolicyData();
            }
        }
    }
    createPolicy() {
        this.store.dispatch(new actions.CreatePolicyAction());
    }
    editPolicy() {
        this.store.dispatch(new editActions.EditPolicyAction());
    }
    cancel() {
        this.store.dispatch(new actions.OpenPoliciesPage());
    }
}
exports.CreateCyberGraphPolicyWizardComponent = CreateCyberGraphPolicyWizardComponent;
