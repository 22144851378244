"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ2 = exports.ɵ1 = exports.ɵ0 = exports.getUsersPagination = exports.getUsersLoading = exports.getUsers = exports.getLocationsPagination = exports.getLocationsLoading = exports.getLocations = exports.getChannels = exports.getTeams = exports.getChannelsLoading = exports.getChannelsError = exports.getTeamsError = exports.getTeamsLoading = exports.getClpChannelsState = exports.getAppliesToUsersState = exports.getAppliesToLocationsState = exports.getSelectUsersLocations = exports.reducers = void 0;
const appliesToLocations = require("./locations/applies-to-locations.reducer");
const appliesToUsers = require("./users/applies-to-users.reducer");
const clpChannels = require("./channels/clp-channels.reducer");
const store_1 = require("@ngrx/store");
exports.reducers = {
    appliesToLocations: appliesToLocations.reducer,
    appliesToUsers: appliesToUsers.reducer,
    clpChannels: clpChannels.reducer
};
exports.getSelectUsersLocations = store_1.createFeatureSelector('selectUsersLocations');
const ɵ0 = state => state.appliesToLocations;
exports.ɵ0 = ɵ0;
exports.getAppliesToLocationsState = store_1.createSelector(exports.getSelectUsersLocations, ɵ0);
const ɵ1 = state => state.appliesToUsers;
exports.ɵ1 = ɵ1;
exports.getAppliesToUsersState = store_1.createSelector(exports.getSelectUsersLocations, ɵ1);
const ɵ2 = state => state.clpChannels;
exports.ɵ2 = ɵ2;
exports.getClpChannelsState = store_1.createSelector(exports.getSelectUsersLocations, ɵ2);
exports.getTeamsLoading = store_1.createSelector(exports.getClpChannelsState, clpChannels.getTeamsLoading);
exports.getTeamsError = store_1.createSelector(exports.getClpChannelsState, clpChannels.getTeamsError);
exports.getChannelsError = store_1.createSelector(exports.getClpChannelsState, clpChannels.getChannelsError);
exports.getChannelsLoading = store_1.createSelector(exports.getClpChannelsState, clpChannels.getChannelsLoading);
exports.getTeams = store_1.createSelector(exports.getClpChannelsState, clpChannels.getTeams);
exports.getChannels = store_1.createSelector(exports.getClpChannelsState, clpChannels.getChannels);
exports.getLocations = store_1.createSelector(exports.getAppliesToLocationsState, appliesToLocations.getLocations);
exports.getLocationsLoading = store_1.createSelector(exports.getAppliesToLocationsState, appliesToLocations.apiIsProcessing);
exports.getLocationsPagination = store_1.createSelector(exports.getAppliesToLocationsState, appliesToLocations.getLocationsPagination);
exports.getUsers = store_1.createSelector(exports.getAppliesToUsersState, appliesToUsers.getUsers);
exports.getUsersLoading = store_1.createSelector(exports.getAppliesToUsersState, appliesToUsers.apiIsProcessing);
exports.getUsersPagination = store_1.createSelector(exports.getAppliesToUsersState, appliesToUsers.getUsersPagination);
