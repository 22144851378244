"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ3 = exports.ɵ2 = exports.ɵ1 = exports.ɵ0 = exports.IPV4_IPV6_VALIDATOR = void 0;
const IPV4_REGEX = /(^(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])){3}$)/;
// eslint-disable-next-line max-len
const IPV6_REGEX = /^s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:)))(%.+)?s*(\/([0-9]|[1-9][0-9]|1[0-1][0-9]|12[0-8]))?$/;
const SUBNET_REGEX = /(^(([1-9]|[1-2]\d|3[0-2]))$)/;
const IPV6_SUBNET_REGEX = /(^(([0-9]|[1-9][0-9]|1[0-1][0-9]|12[0-8]))$)/;
const isValidIPV4Address = (ipv4) => IPV4_REGEX.test(ipv4);
const ɵ0 = isValidIPV4Address;
exports.ɵ0 = ɵ0;
const isValidSubnetMask = (subnet) => SUBNET_REGEX.test(subnet);
const ɵ1 = isValidSubnetMask;
exports.ɵ1 = ɵ1;
const isValidIPV6Address = (ipv6) => IPV6_REGEX.test(ipv6);
const ɵ2 = isValidIPV6Address;
exports.ɵ2 = ɵ2;
const isValidIPV6SubnetMask = (subnet) => IPV6_SUBNET_REGEX.test(subnet);
const ɵ3 = isValidIPV6SubnetMask;
exports.ɵ3 = ɵ3;
/* Validator */
const IPV4_IPV6_VALIDATOR = (control) => {
    // control field is empty; no validation required
    if (!control.value) {
        return null;
    }
    const ipAddress = control.value;
    const invalidObject = { IP_INVALID: { value: ipAddress } };
    const ipParts = ipAddress.split('/');
    if (ipParts.length === 1) {
        // ip has only ip address part; no subnet
        const test = isValidIPV4Address(ipParts[0]);
        const testIPV6 = isValidIPV6Address(ipParts[0]);
        return test ? null : testIPV6 ? null : invalidObject;
    }
    else if (ipParts.length > 2) {
        // since split is using '/', we don't want users entering more than one
        return invalidObject;
    }
    else {
        const test = isValidIPV4Address(ipParts[0]) && isValidSubnetMask(ipParts[1]);
        const testIPV6 = isValidIPV6Address(ipParts[0]) && isValidIPV6SubnetMask(ipParts[1]);
        return test ? null : testIPV6 ? null : invalidObject;
    }
};
exports.IPV4_IPV6_VALIDATOR = IPV4_IPV6_VALIDATOR;
