"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteBackupModalComponent = void 0;
class DeleteBackupModalComponent {
    constructor(overlayRef, overlayData) {
        this.overlayRef = overlayRef;
        this.overlayData = overlayData;
    }
    close(confirmed) {
        this.overlayRef.close(confirmed);
    }
}
exports.DeleteBackupModalComponent = DeleteBackupModalComponent;
