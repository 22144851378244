<mc-layout-aside-extra-container
  keyTitle="{{ keyTitle | translate }}"
  showClose="true"
  [isLoading]="false"
  (closeAside)="closeSidebar()"
>
  <mc-extra-container *mcCapabilities="'Permission.SWG_CONFIGURE_SITES_EDIT'">
    <mc-live-button
      overrideClasses="btn btn-primary"
      [isLoading]="isApiProcessing"
      [label]="showButtonText() | translate"
      class="mc-live-button"
      [mcDisabled]="locationEditForm.invalid"
      (mcClick)="editLocation()"
    >
    </mc-live-button>

    <button class="btn btn-secondary panel-half-margin-right" (click)="closeSidebar()">
      {{ '$I18N_COMMON_BTN_CANCEL' | translate }}
    </button>
  </mc-extra-container>

  <mc-body-container>
    <div>
      <form
        [formGroup]="locationEditForm"
        class="form-horizontal"
        mcDefaultLabelClass="col-sm-4"
        mcDefaultControlContainerClass="col-sm-8"
      >
        <mc-text-field
          label="$I18N_SWG_LOCATIONS_LIST.LOCATION_NAME"
          errorPrefix="$I18N_SWG_LOCATIONS_LIST.ERRORS.DESC"
          formControlName="description"
          required
          [setFocus]="true"
        >
        </mc-text-field>
        <mc-text-field
          label="$I18N_SWG_LOCATIONS_LIST.IPRANGE_ADDRESS"
          errorPrefix="$I18N_SWG_LOCATIONS_LIST.ERRORS.CIDR"
          formControlName="ipRange"
          required
        >
        </mc-text-field>
        <mc-field label="$I18N_SWG_LOCATIONS_LIST.TIMEZONE" required>
          <mc-select formControlName="timeZone" [options]="permissionTypeOptions"> </mc-select>
        </mc-field>
      </form>
    </div>
  </mc-body-container>
</mc-layout-aside-extra-container>
