"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
angular.module('services.services.backup.archive-task-edit.guard', [])
    .factory('archiveTaskEditGuard', [
    '$q',
    'backupService',
    function ($q, backupService) {
        const self = this;
        this.getBackupTask = (taskId) => {
            return backupService.getBackupTask(taskId).then(function (response) {
                if (!response.first.serverConnectionSettings.id) {
                    backupService.getError().showErrorNotification();
                    return $q.reject();
                }
                self.backupTask = response.first;
                return response.first;
            }, function (error) {
                backupService.getError().showErrorNotification();
                return $q.reject(error);
            }, function () {
                backupService.getError().showErrorNotification();
            });
        };
        this.reset = () => {
            self.backupTask = null;
        };
        return self;
    }
]);
