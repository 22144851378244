"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateMockPipe = void 0;
class DateMockPipe {
    constructor(translateService) {
        this.translateService = translateService;
    }
    transform(date) {
        return date.toString();
    }
}
exports.DateMockPipe = DateMockPipe;
