"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MepFormSelectComponent = void 0;
const core_1 = require("@angular/core");
const components_1 = require("@mimecast-ui/components");
class MepFormSelectComponent extends components_1.InputFieldBaseComponent {
    constructor() {
        super(...arguments);
        this.options = [];
        this.useTranslation = true;
        this.selectChange = new core_1.EventEmitter();
    }
    set required(value) {
        if (value !== undefined) {
            this.isRequired = value === true || value === '';
        }
        else {
            this.isRequired = true;
        }
    }
    ngOnInit() {
        this.subscription = this.field.valueChanges.subscribe((newValue) => {
            if (!!newValue) {
                this.placeholder = null;
            }
            else {
                this.selectChange.emit(newValue);
            }
        });
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
exports.MepFormSelectComponent = MepFormSelectComponent;
