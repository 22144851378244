"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateValidator = void 0;
const common_1 = require("@angular/common");
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const utilities_1 = require("app-new/components/form-validators/date/utilities");
const moment = require("moment");
class DateValidator {
    // TODO: This will need refactor to handle the format passed to it. For now its hard bound to just one format
    static format(c) {
        if (utilities_1.isEmptyInputValue(c.value)) {
            return null;
        }
        return DateValidator.dateValidityChecker(c.value) ? null : { dateInvalid: true };
    }
    static dateValidityChecker(value) {
        return moment(value, 'DD/MM/YYYY h:mm:ss').isValid();
    }
    static dateFormat(c) {
        if (utilities_1.isEmptyInputValue(c.value)) {
            return null;
        }
        return DateValidator.dateValidityChecker(c.value.day + '/' + c.value.month + '/' + c.value.year + ' 00:00:00')
            ? null
            : { dateFormatInvalid: true };
    }
    static valid(c) {
        if (utilities_1.isEmptyInputValue(c.value)) {
            return null;
        }
        const controlDate = new Date(c.value);
        if (controlDate instanceof Date && !isNaN(controlDate.getDate())) {
            return null;
        }
        else {
            return { dateInvalid: true };
        }
    }
    static boundary(lowerDateLimit, upperDateLimit, displayDateFormat = 'dd MMM y'
    // eslint-disable-next-line @typescript-eslint/ban-types
    ) {
        let tempDate = moment(lowerDateLimit);
        const startDate = new Date(tempDate.year(), tempDate.month(), tempDate.date(), tempDate.hours(), tempDate.second() > 0 ? tempDate.minute() + 1 : tempDate.minute());
        tempDate = moment(upperDateLimit);
        const endDate = new Date(tempDate.year(), tempDate.month(), tempDate.date(), tempDate.hours(), tempDate.minute());
        const datePipe = new common_1.DatePipe('en');
        return (c) => {
            if (core_1.isDevMode() && (!lowerDateLimit || !upperDateLimit)) {
                throw new Error('Invalid parameters');
            }
            if (utilities_1.isEmptyInputValue(c.value) ||
                DateValidator.dateFormat(new forms_1.FormControl(c.value.date)) ||
                utilities_1.isEmptyInputValue(c.value.time)) {
                return null;
            }
            const date = c.value.date;
            const time = c.value.time.split(':');
            const controlDate = new Date(date.year, date.month - 1, date.day, time[0], time[1]);
            if (lowerDateLimit.toDateString() === upperDateLimit.toDateString()) {
                return controlDate.toDateString() !== lowerDateLimit.toDateString()
                    ? {
                        boundaryConvergeAndDateOutOfBoundary: {
                            date: datePipe.transform(lowerDateLimit.toDateString(), displayDateFormat)
                        }
                    }
                    : null;
            }
            else {
                return controlDate < startDate || controlDate > endDate
                    ? {
                        dateOutOfBoundary: {
                            lowerDateLimit: datePipe.transform(startDate.toString(), displayDateFormat),
                            upperDateLimit: datePipe.transform(endDate.toString(), displayDateFormat)
                        }
                    }
                    : null;
            }
        };
    }
}
exports.DateValidator = DateValidator;
