"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProviderComponent = void 0;
const core_1 = require("@angular/core");
const connector_1 = require("../../../../model/connector");
class ProviderComponent {
    constructor() {
        this._state = 'unselected';
        this.onProviderSelected = new core_1.EventEmitter();
    }
    get state() {
        return this._state;
    }
    set state(value) {
        if (value !== this._state) {
            this.changeContainerClass(this._state, value);
            this._state = value;
        }
    }
    ngOnInit() {
        this.title = this.getProviderTitle(this.provider.type);
    }
    ngAfterViewInit() {
        if (!!this.container) {
            this.container.nativeElement.classList.add(this._state);
        }
    }
    selected() {
        if (this._state !== 'disabled') {
            this.onProviderSelected.emit();
        }
    }
    changeContainerClass(previous, current) {
        if (!!this.container) {
            this.container.nativeElement.classList.remove(previous);
            this.container.nativeElement.classList.add(current);
        }
    }
    getProviderTitle(type) {
        switch (type) {
            case connector_1.ProviderType.ONE_DRIVE:
                return 'OneDrive';
            case connector_1.ProviderType.MS_TEAMS:
                return 'Microsoft Teams';
            case connector_1.ProviderType.MS_TEAMS_GCC:
                return 'Microsoft Teams GCC HIGH';
            case connector_1.ProviderType.BOX:
                return 'Box';
            case connector_1.ProviderType.OFFICE_365:
                return 'Microsoft O365';
            case connector_1.ProviderType.GOOGLE_WORKSPACE:
                return 'Google Workspace';
            case connector_1.ProviderType.AZURE_STANDARD:
                return 'Microsoft Azure - Standard';
            case connector_1.ProviderType.AZURE_GCC:
                return 'Microsoft Azure - GCC HIGH';
            case connector_1.ProviderType.OFFICE_365_GCC:
                return 'Microsoft O365 GCC HIGH';
            default:
                return '';
        }
    }
}
exports.ProviderComponent = ProviderComponent;
