<mc-layout-detail-wizard
  [keyTitle]="title"
  [keyDescription]="description"
  extendClass="panel-padding-bottom"
  *ngIf="!loadingForEdit"
>
  <mc-wizard
    (changeStep)="goToStep($event)"
    [isEditView]="isEditFlow()"
  >
    <mc-wizard-step label="$I18N_MEP_POLICY_CREATE.DETAILS">
      <mc-mep-policy-details-step
        [policy]="mepPolicy"
        [isEditFlow]="isEditFlow()"
        (isFormValid)="setStepValidity('details', $event)"
      ></mc-mep-policy-details-step>
    </mc-wizard-step>
    <mc-wizard-step label="$I18N_MEP_POLICY_CREATE.APPLIESFROM">
      <mc-mep-affected-users-step
        [affectedUsers]="mepPolicy.rules"
        [everyone]="mepPolicy.everyone"
        [isEditFlow]="isEditFlow()"
        (removeAffectedUsers)="removeAffectedUsersWiz.emit($event)"
        (openAppliesToSidePanel)="openAppliesToSidePanelWiz.emit($event)"
        (everyOneAffectedUsers)="everyOneAffectedUsersWiz.emit($event)"
      ></mc-mep-affected-users-step>
    </mc-wizard-step>
    <mc-wizard-step label="$I18N_MEP_POLICY_CREATE.SUMMARY">
      <mc-wizard-step-header
        [label]="isEditFlow() ? '$I18N_MEP_EDIT_POLICY_SUMMARY_HEADER' : '$I18N_MEP_POLICY_DETAILS_STEP.CREATE' | translate"
        [compact]="true"
      ></mc-wizard-step-header>
      <mc-mep-summary
        [policy]="mepPolicy"
        [isWizard]="true"
        [isEditFlow]="isEditFlow()"
      ></mc-mep-summary>
    </mc-wizard-step>
  </mc-wizard>

  <mc-footer-container>
    <div class="pull-right no-select mc-button-footer">
      <button
        class="btn"
        (click)="cancel()"
      >
        {{ '$I18N_COMMON_BTN_CANCEL' | translate }}
      </button>
      <button
        class="btn btn-secondary"
        *ngIf="hasPreviousStep()"
        (click)="wizard.previousStep()"
      >
        {{ '$I18N_COMMON_BTN_PREVIOUS' | translate }}
      </button>
      <button
        class="btn btn-primary"
        [disabled]="!isStepValid(currentStep)"
        *ngIf="hasNextStep()"
        (click)="wizard.nextStep()"
      >
        {{ '$I18N_COMMON_BTN_NEXT' | translate }}
      </button>
      <ng-container *ngIf="hasMepPolicyEditPermission && isEditFlow()">
        <mc-live-button
          overrideClasses="btn-primary"
          [mcDisabled]="!isValidPolicy() || !isStepValid(currentStep)"
          [label]="'$I18N_SWG_CREATE_POLICY_SAVE_AND_CLOSE' | translate"
          [isLoading]="apiIsProcessing"
          (mcClick)="onEditSaveClicked()"
        ></mc-live-button>
      </ng-container>
      <ng-container *ngIf="hasMepPolicyEditPermission && !isEditFlow()">
        <mc-live-button
          *ngIf="wizard && !hasNextStep()"
          overrideClasses="btn-primary"
          [mcDisabled]="!isValidPolicy()"
          [label]="'$I18N_MEP_POLICY_CREATE.CREATE_POLICY' | translate"
          (mcClick)="onSaveBtnClick()"
        ></mc-live-button>
      </ng-container>
    </div>
  </mc-footer-container>
</mc-layout-detail-wizard>
