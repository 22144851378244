"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PolicyApiService = void 0;
const operators_1 = require("rxjs/operators");
class PolicyApiService {
    constructor(http) {
        this.http = http;
    }
    getPolicies(request) {
        const config = {};
        if (request && request.pagination) {
            config['meta'] = {
                pagination: request.pagination
            };
        }
        return this.http.post('/api/policy/identity/get-policies', config);
    }
    createPolicy(request) {
        return this.http
            .post('/api/policy/identity/create-policy', request)
            .pipe(operators_1.map((response) => response.first));
    }
    updatePolicy(request) {
        return this.http
            .post('/api/policy/identity/update-policy', request)
            .pipe(operators_1.map((response) => response.first));
    }
    getPolicy(id) {
        return this.http
            .post('/api/policy/identity/get-policy', {
            id
        })
            .pipe(operators_1.map((response) => response.first));
    }
    deletePolicy(id) {
        return this.http
            .post('/api/policy/identity/delete-policy', { id })
            .pipe(operators_1.map(res => res.first));
    }
}
exports.PolicyApiService = PolicyApiService;
