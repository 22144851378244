"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ3 = exports.ɵ2 = exports.ɵ1 = exports.ɵ0 = exports.dateRangePickerConfig = exports.getRange = void 0;
const moment = require("moment");
function getRange(days) {
    return {
        start: moment().subtract(days, 'days'),
        end: moment()
    };
}
exports.getRange = getRange;
const ɵ0 = () => getRange(1), ɵ1 = () => getRange(2), ɵ2 = () => getRange(7), ɵ3 = () => getRange(30);
exports.ɵ0 = ɵ0;
exports.ɵ1 = ɵ1;
exports.ɵ2 = ɵ2;
exports.ɵ3 = ɵ3;
const predefinedDateRangesConfigs = [
    {
        id: 'PAST_24_HOURS',
        label: '$I18N_DATE_RANGE_CONFIG_LABEL_PAST_24_HOURS',
        rangeFn: ɵ0,
        range: getRange(1)
    },
    {
        id: 'PAST_48_HOURS',
        label: '$I18N_DATE_RANGE_CONFIG_LABEL_PAST_48_HOURS',
        rangeFn: ɵ1,
        range: getRange(2)
    },
    {
        id: 'PAST_7_DAYS',
        label: '$I18N_DATE_RANGE_CONFIG_LABEL_PAST_7_DAYS',
        rangeFn: ɵ2,
        range: getRange(7)
    },
    {
        id: 'PAST_30_DAYS',
        label: '$I18N_DATE_RANGE_CONFIG_LABEL_PAST_30_DAYS',
        rangeFn: ɵ3,
        range: getRange(30)
    },
    {
        id: 'CUSTOM',
        label: '$I18N_DATE_RANGE_CONFIG_LABEL_CUSTOM_RANGE'
    }
];
exports.dateRangePickerConfig = {
    allowCustomRange: false,
    minDate: moment()
        .startOf('day')
        .subtract(10, 'years'),
    maxDate: moment().endOf('day'),
    dateRanges: predefinedDateRangesConfigs
};
