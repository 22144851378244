"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgEndpointsSettingsAllowLocalIpsModalComponent = void 0;
const settings_actions_1 = require("../../../../actions/settings.actions");
class SwgEndpointsSettingsAllowLocalIpsModalComponent {
    constructor(store, overlayRef, settings) {
        this.store = store;
        this.overlayRef = overlayRef;
        this.settings = settings;
    }
    onCancel() {
        this.store.dispatch(new settings_actions_1.SwgEndpointsSettingsCancelAllowLocalIPsModalAction(this.settings));
        this.overlayRef.close();
    }
    onUpdateSetting() {
        this.store.dispatch(new settings_actions_1.SwgEndpointsSettingsUpdateSettingsAction(this.settings));
    }
}
exports.SwgEndpointsSettingsAllowLocalIpsModalComponent = SwgEndpointsSettingsAllowLocalIpsModalComponent;
