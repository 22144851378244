<mc-layout-detail-wizard     
    *ngIf="!((isLoadingConnectors$ | async) 
        || (isCreatingBackup$ | async) 
        || (isUpdatingBackup$ | async)
        || (isLoadingBackup$ | async)); else spinner"
    [keyTitle]="wizardTitle">
    <mc-wizard #wizard (changeStep)="onStepChange($event)">
        <mc-wizard-step label="{{ '$I18N_ONEDRIVE_BACKUP_WIZARD.STEPS.DETAILS.TITLE' }}">
            <mc-onedrive-backup-wizard-details
                [name]="name"
                [group]="group"
                [connector]="connector"
                [isEditMode]="isEditMode"
            >
            </mc-onedrive-backup-wizard-details>
        </mc-wizard-step>
        <mc-wizard-step label="{{ '$I18N_ONEDRIVE_BACKUP_WIZARD.STEPS.EXCLUDED_FILES.TITLE' }}">
            <mc-onedrive-backup-wizard-excluded-files
                [excludedExtensions]="excludedExtensions"
            >
            </mc-onedrive-backup-wizard-excluded-files>
        </mc-wizard-step>
        <mc-wizard-step label="{{ '$I18N_ONEDRIVE_BACKUP_WIZARD.STEPS.SUMMARY.TITLE' }}">
            <mc-onedrive-backup-wizard-summary
                [wizardData]="wizardData$ | async"
                [isEditMode]="isEditMode"
            ></mc-onedrive-backup-wizard-summary>
        </mc-wizard-step>
    </mc-wizard>
    <mc-footer-container>
        <div class="pull-right no-select mc-button-footer">
            <button 
                data-test="onedrive-backup-cancel-button"
                class="mc-backup-wizard-cancel"
                (click)="onCancel()">
            {{ '$I18N_ONEDRIVE_BACKUP_WIZARD.CONTROLS.CANCEL' | translate }}
            </button>
    
            <ng-container *ngIf="wizard">
                <button
                    *ngIf="(wizard.hasPreviousStep$ | async)"
                    class="btn btn-secondary"
                    data-test="onedrive-backup-previous-button"
                    (click)="onPrevious()"
                >
                    {{ '$I18N_ONEDRIVE_BACKUP_WIZARD.CONTROLS.PREVIOUS' | translate }}
                </button> 
                <button
                    *ngIf="(wizard.hasNextStep$ | async)"     
                    class="btn btn-primary"       
                    data-test="onedrive-backup-next-button"
                    [disabled]="((wizard.isLastStep$ | async) || 
                                ((wizard.currentStep === 1) && !(isCurrentStepValid$ | async)) ||
                                ((wizard.currentStep === 2) && !(isCurrentStepValid$ | async)))"
                    (click)="onNext()"
                >
                    {{ '$I18N_ONEDRIVE_BACKUP_WIZARD.CONTROLS.NEXT' | translate }}
                </button>
                <button
                    *ngIf="(wizard.isLastStep$ | async) || isEditMode"
                    data-test="onedrive-backup-save-button"
                    class="btn btn-primary"
                    [disabled]="(isEditMode && !hasEditModeChanges) || (!(isCurrentStepValid$ | async) && !(wizard.isLastStep$ | async))"
                    (click)="onSave($event)"
                >
                    {{ getSaveButtonText() }}
                </button>
            </ng-container>
        </div>
    </mc-footer-container>
</mc-layout-detail-wizard>
<ng-template #spinner>
    <div class="spinner-wrapper">
      <mc-spinner></mc-spinner>
    </div>
</ng-template>