'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./archive-task-edit-summary.controller"); //archive-task-edit-summary.controller
// html and css
require("./archive-task-edit-summary.tpl.html");
/**
 * Created by iaggarwal on 19/05/2017.
 */
angular.module('archive-task-edit-summary', ['archive-task-edit-summary.controller'])
    .config(['$stateProvider', function ($stateProvider) {
        $stateProvider
            .state('archive-task-summary', {
            url: '/sync-recover/exchange/task/create/summary',
            data: {
                breadcrumbs: [
                    '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_SERVICES',
                    '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ASIDE_MENU_TITLE',
                    '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ARCHIVE_TASK_MENU_LABEL',
                    '$I18N_BACKUP_RESTORE_ARCHIVE_TASK_BREADCRUMBS_LABEL_NEW'
                ],
                tabId: 'sync-and-recover-tab',
                tabTitle: '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ASIDE_MENU_TITLE',
                asideTitle: '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ASIDE_MENU_TITLE',
                tabHide: false,
                tabReload: false
            },
            params: {
                'archiveTaskResponse': {}
            },
            views: {
                'main': {
                    templateUrl: 'services/services/backup/archive-task/archive-task-edit/archive-task-edit-summary/archive-task-edit-summary.tpl.html',
                    controller: 'ArchiveTaskEditSummaryController as archiveTaskEditSummaryCtrl'
                },
                'aside': {
                    templateUrl: 'services/services/backup/aside/aside.tpl.html'
                }
            }
        });
    }]);
