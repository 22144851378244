"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.getManagedUrlLoading = exports.getManagedUrls = exports.getManagedUrlsRequestPage = exports.getManagedUrlsSearch = exports.getManagedUrlData = exports.getManagedUrlState = exports.getServiceState = exports.reducers = void 0;
const store_1 = require("@ngrx/store");
const fromManagedUrls = require("./managed-urls.reducer");
exports.reducers = {
    managedUrls: fromManagedUrls.managedUrlReducer
};
exports.getServiceState = store_1.createFeatureSelector('services');
const ɵ0 = (state) => state.managedUrls;
exports.ɵ0 = ɵ0;
exports.getManagedUrlState = store_1.createSelector(exports.getServiceState, ɵ0);
exports.getManagedUrlData = store_1.createSelector(exports.getManagedUrlState, fromManagedUrls.getManagedUrlData);
exports.getManagedUrlsSearch = store_1.createSelector(exports.getManagedUrlState, fromManagedUrls.getSearchRequest);
exports.getManagedUrlsRequestPage = store_1.createSelector(exports.getManagedUrlState, fromManagedUrls.getRequestPage);
exports.getManagedUrls = store_1.createSelector(exports.getManagedUrlState, fromManagedUrls.getManagedUrls);
exports.getManagedUrlLoading = store_1.createSelector(exports.getManagedUrlState, fromManagedUrls.getManagedUrlLoading);
