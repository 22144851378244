<mc-table tableId="/ttp-attachment-definition/definition-list" [isLoading]="isLoading$ | async" [columns]="columns"
	data-test="cy-definitions-table" [data]="tableData$ | async" (rowClick)="clickRow($event)"
	[highlightedRow]="selectedRow" translatePrefix="$I18N_SERVICES_DEFINITION_LIST_TABLE.TABLE">

	<div *ngIf="canModifyPolicy" class="mc-table-actions">
		<button data-unit-test="unit-add-policy-btn" class=" btn btn-primary panel-half-margin-right"
			(click)="createDefinition()">
			{{ '$I18N_AP_CREATE_NEW_DEFINITION' | translate }}
		</button>
		<button *ngIf="!isAAAOrFAAUser" class=" btn btn-primary " (click)="openTrustedSender()">
			{{ '$I18N_AP_VIEW_TRUSTED_SENDERS' | translate }}
		</button>
	</div>
	<mc-filters [externalTableSearchCount]="externalTableSearchCount" [metadata]="metaData$ | async"
		[showPagination]="true" (paginatorChange)="changePaginator($event)">

		<mc-filters-bundle-column-values-search-only (filtersChange)="onSearchDescription($event)"
			placeholder="$I18N_AP_DEFINITION_LIST.PLACEHOLDER.SEARCH">
		</mc-filters-bundle-column-values-search-only>
	</mc-filters>

	<mc-empty-results keyTitle="$I18N_SERVICES_URL_PROTECTION_POLICIES_TABLE.TABLE.EMPTY_RESULTS"
		iconClass="mc-icon-adcon-icon-stable-no-results-2">
	</mc-empty-results>
	 
	<mc-column key="description">
		<mc-body-cell *mcBodyCellDef="let row">{{ row.description }}</mc-body-cell>
	</mc-column> 

	<mc-column key="action">
		<mc-body-cell *mcBodyCellDef="let row">
			<div class="mc-status-text-container">
				<span>{{ getDefinitionConfigurationType(row.action,row.checkInbound) | uppercase | translate }}</span>
			</div>
		</mc-body-cell>
	</mc-column>
	<mc-column key="checkOutbound">
		<mc-body-cell *mcBodyCellDef="let row">
			<div class="mc-status-text-container">
				<span *ngIf="row.checkOutbound" class="fas fa-check mc-ok-color"></span>
			</div>
		</mc-body-cell>
	</mc-column>

	<mc-column key="checkInbound">
		<mc-body-cell *mcBodyCellDef="let row">
			<div class="mc-status-text-container">
				<span *ngIf="row.checkInbound" class="fas fa-check mc-ok-color"></span>
			</div>
		</mc-body-cell>
	</mc-column>

	<mc-column key="checkJournal">
		<mc-body-cell *mcBodyCellDef="let row">
			<div class="mc-status-text-container">
				<span *ngIf="row.checkJournal" class="glyphicon glyphicon-ok mc-ok-color"></span>
			</div>
		</mc-body-cell>
	</mc-column>

	<mc-column key="safeDocFormat">
		<mc-body-cell *mcBodyCellDef="let row" class="text-capitalize">
			<div class="mc-status-text-container">
				<span *ngIf="row.safeDocFormat === 'original' && row.action!== 'sandbox'">Original Format</span>
				<span class="text-capitalize" *ngIf="row.action === 'sandbox' ">N/A</span>
				<span class="text-capitalize" *ngIf="row.action!== 'sandbox' && row.safeDocFormat !== 'original'">{{
					row.safeDocFormat | uppercase | translate }}</span>
			</div>
		</mc-body-cell>
	</mc-column>

	<mc-column key="safeSpreadsheetFormat">
		<mc-body-cell *mcBodyCellDef="let row" class="text-capitalize">
			<div class="mc-status-text-container">
				<span *ngIf="row.safeSpreadsheetFormat === 'html_zip'">{{htmlExtMulti}}</span>
				<span *ngIf="row.safeSpreadsheetFormat === 'html'">{{htmlExt}} </span>
				<span *ngIf="row.safeSpreadsheetFormat === 'original' && row.action!== 'sandbox'">Original Format</span>
				<span *ngIf="row.action === 'sandbox'">N/A</span>
				<span *ngIf="row.action!== 'sandbox' && row.safeSpreadsheetFormat !== 'html_zip' && row.safeSpreadsheetFormat !== 'original' &&
                    row.safeSpreadsheetFormat !== 'html'">
					{{ row.safeSpreadsheetFormat | uppercase | translate }}</span>
			</div>
		</mc-body-cell>
	</mc-column>

	<mc-column key="preEmptiveFailureAction">
		<mc-body-cell *mcBodyCellDef="let row">
			<div class="mc-status-text-container">
				<span
					*ngIf="row.preEmptiveFailureAction === 'adminhold' && row.action !== 'safedoc_and_link' && row.action !== 'safedoc_only'">{{adminAction}}</span>
				<span *ngIf="row.action === 'safedoc_and_link' || row.action === 'safedoc_only'">N/A</span>
				<span class="text-capitalize"
					*ngIf="row.action !== 'safedoc_and_link' && row.preEmptiveFailureAction !== 'adminhold'">{{
					row.preEmptiveFailureAction}}</span>


			</div>
		</mc-body-cell>
	</mc-column>

	<mc-column key="notifyOnMalicious">
		<mc-body-cell *mcBodyCellDef="let row">
			<div class="mc-status-text-container">
				<span *ngIf="(row.action === 'safedoc_only' && !row.notifyOnMalicious)">N/A</span>
				<span *ngIf="row.notifyOnMalicious" class="fas fa-check mc-ok-color"></span>
			</div>
		</mc-body-cell>
	</mc-column>

	<mc-column-actions key="dropdown-column" mcShowColumnConfig [columnsAlwaysVisible]="columnsAlwaysVisible">
		<mc-row-actions *mcRowActions="let row">
			<ng-container *ngIf="canModifyPolicy; else details">
				<li data-test="cy-edit-meatball-btn" mcRowAction="$I18N_COMMON_BTN_EDIT"
					data-unit-test="unit-edit-action-btn" (click)="editDefinition(row)">
				</li>
				<li data-test="cy-duplicate-meatball-btn" mcRowAction="$I18N_COMMON_BTN_DUPLICATE"
					data-unit-test="unit-duplicate-action-btn" (click)="duplicateDefinition(row)">
				</li>
				<li data-test="cy-delete-meatball-btn" mcRowAction="$I18N_COMMON_BTN_DELETE"
					data-unit-test="unit-delete-action-btn" (click)="deleteDefinition(row)">
				</li>
			</ng-container>
			<ng-template #details>
				<li mcRowAction="$I18N_COMMON_BTN_VIEWS_DETAILS" data-test="cy-details-meatball-btn"
					data-unit-test="unit-details-action-btn" (click)="clickRow(row)">
				</li>
			</ng-template>
		</mc-row-actions>
	</mc-column-actions>
</mc-table>