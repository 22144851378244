"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgLocationCrudEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const notification_actions_1 = require("app-new/components/notification/actions/notification.actions");
const swg_location_crud_actions_1 = require("app-new/swg/locations/actions/swg-location-crud.actions");
const swg_location_sidebar_actions_1 = require("app-new/swg/locations/actions/swg-location-sidebar.actions");
const tableStore = require("@mimecast-ui/table-store");
const swg_errors_1 = require("app-new/swg/common/swg-errors");
class SwgLocationCrudEffects {
    constructor(actions$, swgLocationService, notificationService) {
        this.actions$ = actions$;
        this.swgLocationService = swgLocationService;
        this.notificationService = notificationService;
        this.deleteLocations = this.actions$.pipe(effects_1.ofType(swg_location_crud_actions_1.SwgLocationCrudTypes.SWG_LOCATION_DELETE), operators_1.map((action) => action.payload), operators_1.switchMap((id) => {
            return this.swgLocationService.deleteEgressIp(id).pipe(operators_1.mergeMap(() => {
                return [
                    new notification_actions_1.NotificationShowSuccessAction('$I18N_SWG_LOCATIONS_LIST.LOCATION_DELETED'),
                    new swg_location_crud_actions_1.SwgLocationDeleteSuccessAction(),
                    new swg_location_sidebar_actions_1.SwgLocationsDeleteSidebarCloseAction(),
                    new tableStore.LoadAction({ tableId: 'SwgLocationsListTable' })
                ];
            }), operators_1.catchError(error => rxjs_1.merge(rxjs_1.of(this.notificationService.showFirstNotificationError(error.firstFail, swg_errors_1.swgCommonErrors)), rxjs_1.of(new swg_location_crud_actions_1.SwgLocationDeleteFailureAction()), rxjs_1.of(new swg_location_sidebar_actions_1.SwgLocationsDeleteSidebarCloseAction()))));
        }));
        this.updateEditLocations = this.actions$.pipe(effects_1.ofType(swg_location_crud_actions_1.SwgLocationCrudTypes.SWG_LOCATION_UPDATE), operators_1.map((action) => action.payload), operators_1.switchMap((webIpRange) => {
            return this.swgLocationService.updateEgressIp(webIpRange).pipe(operators_1.mergeMap(() => {
                return [
                    new notification_actions_1.NotificationShowSuccessAction(webIpRange.id
                        ? '$I18N_SWG_LOCATIONS_LIST.LOCATION_UPDATED'
                        : '$I18N_SWG_LOCATIONS_LIST.LOCATION_ADDED'),
                    new swg_location_crud_actions_1.SwgLocationUpdateSuccessAction(),
                    new swg_location_sidebar_actions_1.SwgLocationsEditAddSidebarCloseAction(),
                    new tableStore.LoadAction({ tableId: 'SwgLocationsListTable' })
                ];
            }), operators_1.catchError(error => rxjs_1.merge(rxjs_1.of(this.notificationService.showFirstNotificationError(error.firstFail, swg_errors_1.swgCommonErrors)), rxjs_1.of(new swg_location_crud_actions_1.SwgLocationUpdateFailureAction()))));
        }));
    }
}
__decorate([
    effects_1.Effect()
], SwgLocationCrudEffects.prototype, "deleteLocations", void 0);
__decorate([
    effects_1.Effect()
], SwgLocationCrudEffects.prototype, "updateEditLocations", void 0);
exports.SwgLocationCrudEffects = SwgLocationCrudEffects;
