"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditDomainComponent = void 0;
const core_1 = require("@angular/core");
const chameleon_domain_validator_1 = require("../../validators/chameleon-domain/chameleon-domain.validator");
const max_length_validator_1 = require("../../validators/max-length/max-length.validator");
class EditDomainComponent {
    constructor(fb) {
        this.fb = fb;
        this.save = new core_1.EventEmitter();
        this.cancel = new core_1.EventEmitter();
        this.delete = new core_1.EventEmitter();
        this.closeAside = new core_1.EventEmitter();
        this.form = this.fb.group({
            domain: ['', [chameleon_domain_validator_1.ChameleonDomainValidator]],
            comment: ['', [max_length_validator_1.MaxLengthValidator(50)]]
        });
    }
    ngOnInit() {
        this.form.patchValue({
            domain: this.selectedRow.domainName,
            comment: this.selectedRow.comment
        });
    }
    onSave() {
        this.save.emit({
            domainName: this.form.value.domain,
            comment: this.form.value.comment,
            id: this.selectedRow.id
        });
    }
    onDelete() {
        this.delete.emit(this.selectedRow);
    }
    onClose() {
        this.closeAside.emit();
    }
}
exports.EditDomainComponent = EditDomainComponent;
