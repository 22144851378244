<mc-table
  tableId="api-integration/siem-channels"
  [isLoading]="isLoading$ | async"
  [data]="dataInView"
  [columns]="columnList"
  [sortableColumns]="sortableColumns"
  (sort)="onSort($event)"
  translatePrefix="$I18N_SIEM_CHANNELS_LIST_TABLE"
  (rowClick)="rowClick.emit($event)"
>
  <button
    class="mc-table-actions btn btn-primary panel-half-margin-right"
    *ngIf="hasEditPermission"
    [disabled]="metadataProducts.length === 0"
    (click)="goToCreateChannel()"
  >
    {{ '$I18N_SIEM_CHANNELS_LIST_TABLE.ACTIONS.CREATE' | translate }}
  </button>
  <mc-filters [showPagination]="false">
  </mc-filters>
  <mc-column key="name">
    <mc-body-cell *mcBodyCellDef="let row">
      <div class="mc-status-text-container">
        <span>{{ row.name}}</span>
      </div>
    </mc-body-cell>
  </mc-column>
  <mc-column key="description">
    <mc-body-cell *mcBodyCellDef="let row">
      <div class="mc-status-text-container">
        <span>{{ row.description }}</span>
      </div>
    </mc-body-cell>
  </mc-column>
  <mc-column key="type">
    <mc-body-cell *mcBodyCellDef="let row">
      <div class="mc-status-text-container">
        <span>{{ row.type }}</span>
      </div>
    </mc-body-cell>
  </mc-column>
  <mc-column-date key="lastModified"></mc-column-date>


  <mc-column-actions key="right-column"
                     [columnsAlwaysVisible]="columnsAlwaysVisible"
                     mcShowColumnConfig>
    <mc-row-actions *mcRowActions="let row">
      <li mcRowAction="$I18N_SIEM_CHANNELS_LIST_TABLE.ACTIONS.VIEW" (click)="rowClick.emit(row)"></li>
      <li mcRowActionSeparator></li>
      <li mcRowAction="$I18N_SIEM_CHANNELS_LIST_TABLE.ACTIONS.COPY_IDENTIFIER"
          ngxClipboard
          [cbContent]="row.uniqueId"
          (cbOnSuccess)="copyIdentifierToClipboardSuccess()">
      </li>
      <li
        mcRowAction="$I18N_SIEM_CHANNELS_LIST_TABLE.ACTIONS.DELETE"
        *ngIf="hasDeletePermission"
        (click)="deleteChannel.emit(row)"
      ></li>
      <li mcRowAction="$I18N_SIEM_CHANNELS_LIST_TABLE.ACTIONS.EDIT"
          [ngClass]="{'mc-li-disabled': metadataProducts.length === 0}"
          *ngIf="hasEditPermission"
          (click)="goToEditChannel(row)"></li>
    </mc-row-actions>
  </mc-column-actions>
  <mc-empty-results
    iconClass="far fa-list-alt"
    keyTitle="$I18N_SIEM_CHANNELS_LIST_TABLE.NO_DATA.TITLE"
  >
  </mc-empty-results>
</mc-table>
