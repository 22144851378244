"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiIntegrationCortexWildfireSettingsStepComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const operators_1 = require("rxjs/operators");
class ApiIntegrationCortexWildfireSettingsStepComponent {
    constructor(fb, translateService) {
        this.fb = fb;
        this.translateService = translateService;
        this.onUpdateWildfireSettings = new core_1.EventEmitter();
        this.form = this.fb.group({
            wildfireSeverity: ['high', [forms_1.Validators.required]],
            action: 'NOTIFY',
            enabled: true,
            disableUser: false
        });
    }
    get wildfireSeverities() {
        return [
            {
                value: 'high',
                label: this.translateService.instant('$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.WILDFIRE_SETTINGS.SEVERITIES_OPTIONS.HIGH')
            },
            {
                value: 'critical',
                label: this.translateService.instant('$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.WILDFIRE_SETTINGS.SEVERITIES_OPTIONS.CRITICAL')
            }
        ];
    }
    ngOnInit() {
        this.formSetup(this.cortexWildfireSettings);
        this.formSubscription = this.form.valueChanges.pipe(operators_1.debounceTime(200)).subscribe(() => {
            this.update();
        });
    }
    ngOnChanges(changes) {
        const change = changes['cortexWildfireSettings'];
        if (change && JSON.stringify(change.previousValue) !== JSON.stringify(change.currentValue)) {
            if (change.currentValue) {
                this.formSetup(change.currentValue);
            }
        }
    }
    formSetup(cortexWildfireSettings) {
        this.showConfiguration = !!cortexWildfireSettings ? cortexWildfireSettings.enabled : true;
        this.showDisableUser = !!cortexWildfireSettings
            ? cortexWildfireSettings.action === 'BLOCK_OUTBOUND' ||
                cortexWildfireSettings.action === 'BLOCK_ALL'
            : false;
        this.form.patchValue({
            wildfireSeverity: !!cortexWildfireSettings ? cortexWildfireSettings.fields.severity : 'high',
            action: !!cortexWildfireSettings ? cortexWildfireSettings.action : 'NOTIFY',
            enabled: this.showConfiguration,
            disableUser: cortexWildfireSettings ? cortexWildfireSettings.disableUser : false
        });
    }
    update() {
        this.showDisableUser =
            this.form.value.action === 'BLOCK_OUTBOUND' || this.form.value.action === 'BLOCK_ALL';
        this.showConfiguration = this.form.value.enabled;
        this.onUpdateWildfireSettings.emit({
            severity: this.form.value.wildfireSeverity,
            action: this.form.value.action,
            enabled: this.form.value.enabled,
            disableUser: this.form.value.disableUser
        });
    }
    isValid() {
        return this.form.status === 'VALID';
    }
    ngOnDestroy() {
        this.formSubscription.unsubscribe();
    }
}
exports.ApiIntegrationCortexWildfireSettingsStepComponent = ApiIntegrationCortexWildfireSettingsStepComponent;
