<mc-modal-header
        headerTitle="{{ '$I18N_IDENTITY_APP_UPDATE_CANCEL.TITLE' | translate }}"></mc-modal-header>
<mc-modal-footer>
    <button type="button" class="btn btn-secondary mc-app-update-no-cancel" (click)="onNoResponse()">
        {{ '$I18N_COMMON_TEXT_NO' | translate }}
    </button>

    <button type="button" class="btn btn-primary mc-app-update-yes-cancel" (click)="onYesResponse()">
        {{ '$I18N_COMMON_TEXT_YES' | translate }}
    </button>
</mc-modal-footer>
