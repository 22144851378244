"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
(function () {
    'use strict';
    /* ======================================================== *\

     = group-selector - service

     \* ======================================================== */
    angular.module('backup-restore-group-selector.service', [])
        .factory('groupSelectorService', ['$aside', function ($aside) {
            let asideInstance;
            /* -------------------------------------------------------- *\
             - interface
             \* -------------------------------------------------------- */
            return {
                openGroupSelector
            };
            /* -------------------------------------------------------- *\
             - implementation
             \* -------------------------------------------------------- */
            // == openGroupSelector ========
            function openGroupSelector() {
                asideInstance = $aside.open({
                    templateUrl: 'services/services/backup/components/group-selector/group-selector.tpl.html',
                    placement: 'right',
                    size: 'lg',
                    backdrop: true,
                    windowClass: 'hidden-xs',
                    backdropClass: 'hidden-xs'
                });
                return asideInstance.result;
            }
        }]);
}());
