"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.getDetailPageTotal = exports.getMessageProcessingTotal = exports.getMessageProcessingMetadata = exports.getMessageProcessingRequestPage = exports.getMessageProcessingPagination = exports.getMessageProcessingSelectedRow = exports.getMessageProcessingSearch = exports.getMessageProcessingTotalCount = exports.getMessageProcessingItems = exports.isDeliveryMessagesLoading = exports.MessageProcessingReducers = void 0;
const store_1 = require("@ngrx/store");
const fromMessageProcessing = require("./message-processing.reducer");
exports.MessageProcessingReducers = {
    MessageProcessing: fromMessageProcessing.reducer
};
const messageProcessingState = store_1.createFeatureSelector('message-processing');
const ɵ0 = (state) => state.MessageProcessing;
exports.ɵ0 = ɵ0;
const getMessageProcessingState = store_1.createSelector(messageProcessingState, ɵ0);
exports.isDeliveryMessagesLoading = store_1.createSelector(getMessageProcessingState, fromMessageProcessing.isLoading);
exports.getMessageProcessingItems = store_1.createSelector(getMessageProcessingState, fromMessageProcessing.getItems);
exports.getMessageProcessingTotalCount = store_1.createSelector(getMessageProcessingState, fromMessageProcessing.getTotalCount);
exports.getMessageProcessingSearch = store_1.createSelector(getMessageProcessingState, fromMessageProcessing.getSearchRequest);
exports.getMessageProcessingSelectedRow = store_1.createSelector(getMessageProcessingState, fromMessageProcessing.getSelectedRow);
exports.getMessageProcessingPagination = store_1.createSelector(getMessageProcessingState, fromMessageProcessing.getPagination);
exports.getMessageProcessingRequestPage = store_1.createSelector(getMessageProcessingState, fromMessageProcessing.getRequestPage);
exports.getMessageProcessingMetadata = store_1.createSelector(getMessageProcessingState, fromMessageProcessing.getMetadata);
exports.getMessageProcessingTotal = store_1.createSelector(getMessageProcessingState, fromMessageProcessing.getDetailPageTotal);
exports.getDetailPageTotal = store_1.createSelector(getMessageProcessingState, fromMessageProcessing.getDetailPageTotal);
