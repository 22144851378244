"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoStatisticsComponent = void 0;
const queue_service_1 = require("app-new/archive/supervision/containers/wizard/services/queue.service");
const lexicon_service_1 = require("app-new/archive/supervision/services/lexicon.service");
const rule_service_1 = require("app-new/archive/supervision/services/rule.service");
class NoStatisticsComponent {
    constructor(stateService) {
        this.stateService = stateService;
    }
    openTab(tab) {
        let wizardId;
        switch (tab) {
            case 'lexicon':
                wizardId = lexicon_service_1.LexiconService.WizardId;
                break;
            case 'queue':
                wizardId = queue_service_1.QueueService.WizardId;
                break;
            case 'rule':
                wizardId = rule_service_1.RuleService.WizardId;
                break;
            default:
                wizardId = lexicon_service_1.LexiconService.WizardId;
        }
        this.stateService.$state.go('archive-supervision-list', {
            callerWizardId: wizardId
        });
    }
}
exports.NoStatisticsComponent = NoStatisticsComponent;
