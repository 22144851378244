"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckUrlClearAction = exports.CheckUrlFailAction = exports.CheckUrlSuccessAction = exports.CheckUrlAttemptAction = exports.CHECK_URL_CLEAR = exports.CHECK_URL_FAIL = exports.CHECK_URL_SUCCESS = exports.CHECK_URL_ATTEMPT = void 0;
exports.CHECK_URL_ATTEMPT = '[Services / Url protection / Url Tools / Check Urls] Check Url';
exports.CHECK_URL_SUCCESS = '[Services / Url protection / Url Tools / Check Urls] Check Url success';
exports.CHECK_URL_FAIL = '[Services / Url protection / Url Tools / Check Urls] Check Url fail';
exports.CHECK_URL_CLEAR = '[Services / Url protection / Url Tools / Check Urls] Check Url clear';
class CheckUrlAttemptAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.CHECK_URL_ATTEMPT;
    }
}
exports.CheckUrlAttemptAction = CheckUrlAttemptAction;
class CheckUrlSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.CHECK_URL_SUCCESS;
    }
}
exports.CheckUrlSuccessAction = CheckUrlSuccessAction;
class CheckUrlFailAction {
    constructor() {
        this.type = exports.CHECK_URL_FAIL;
    }
}
exports.CheckUrlFailAction = CheckUrlFailAction;
class CheckUrlClearAction {
    constructor() {
        this.type = exports.CHECK_URL_CLEAR;
    }
}
exports.CheckUrlClearAction = CheckUrlClearAction;
