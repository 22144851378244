"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClpDataEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const cfp_action_1 = require("../actions/cfp.action");
class ClpDataEffects {
    constructor(actions$, notificationService, translateService, swgClpService) {
        this.actions$ = actions$;
        this.notificationService = notificationService;
        this.translateService = translateService;
        this.swgClpService = swgClpService;
        this.$loadVerdictsByApp$ = this.actions$.pipe(effects_1.ofType(cfp_action_1.SwgCfpDataEnum.VERDICTS_BY_APP), operators_1.switchMap((action) => {
            return this.swgClpService.getVerdictsByApp(action.dateRange).pipe(operators_1.map((response) => {
                return new cfp_action_1.SwgCfpVerdictsByAppSuccessAction(response);
            }), operators_1.catchError(() => this.failAction('$I18N_SWG_APPLICATIONS_DASHBOARD.NO_DATA_ERROR')));
        }));
        this.$loadVerdictsByDate$ = this.actions$.pipe(effects_1.ofType(cfp_action_1.SwgCfpDataEnum.VERDICTS_BY_DATE), operators_1.switchMap((action) => {
            return this.swgClpService.getVerdictsByDate(action.dateRange).pipe(operators_1.map((response) => {
                return new cfp_action_1.SwgCfpVerdictsByDateSuccessAction(response);
            }), operators_1.catchError(() => this.failAction('$I18N_SWG_APPLICATIONS_DASHBOARD.NO_DATA_ERROR')));
        }));
        this.$loadScansByFileType$ = this.actions$.pipe(effects_1.ofType(cfp_action_1.SwgCfpDataEnum.SCANS_BY_FILETYPES), operators_1.switchMap((action) => {
            return this.swgClpService.getScansByFileType(action.dateRange).pipe(operators_1.map((response) => {
                return new cfp_action_1.SwgCfpScansByFileTypesSuccessAction(response);
            }), operators_1.catchError(() => this.failAction('$I18N_SWG_APPLICATIONS_DASHBOARD.NO_DATA_ERROR')));
        }));
    }
    failAction(errorMessage) {
        return rxjs_1.of(new cfp_action_1.SwgCfpVerdictsByAppFailureAction()).pipe(operators_1.tap(() => {
            this.notificationService.error({
                msg: this.translateService.instant(errorMessage)
            });
        }));
    }
}
__decorate([
    effects_1.Effect()
], ClpDataEffects.prototype, "$loadVerdictsByApp$", void 0);
__decorate([
    effects_1.Effect()
], ClpDataEffects.prototype, "$loadVerdictsByDate$", void 0);
__decorate([
    effects_1.Effect()
], ClpDataEffects.prototype, "$loadScansByFileType$", void 0);
exports.ClpDataEffects = ClpDataEffects;
