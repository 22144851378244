"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
(function () {
    'use strict';
    /* ======================================================== *\

     = layout / side simple / directive
     = jcarradinha

     \* ======================================================== */
    document.createElement('mc-layout-side-simple');
    angular.module('layout.side-simple.directive', [])
        .directive('mcLayoutSideSimple', ['$window', function ($window) {
            return {
                restrict: 'E',
                templateUrl: 'components/layout/side-simple/side-simple.tpl.html',
                scope: {
                    mainContainerClass: '@mainContainerClass',
                    mainInlineNotification: '=mainInlineNotification',
                    headerTitle: '@headerTitle',
                    headerDescription: '@headerDescription',
                    headerCloseButtonVisibility: '@headerCloseButtonVisibility',
                    headerOnCloseAction: '&headerOnCloseAction',
                    bottomDescription: '@bottomDescription',
                    whenScrolledMethod: '=?'
                },
                transclude: {
                    upperContainer: '?upperContainer',
                    mainContainer: 'mainContainer',
                    bottomContainer: '?bottomContainer'
                },
                link(scope, element, attrs, controller, transcludeFn) {
                    $window.parent.postMessage({ action: 'AsideModalOpened' }, window.location.origin);
                    scope.whenScrolled = function () {
                        if (scope.whenScrolledMethod) {
                            return scope.whenScrolledMethod();
                        }
                    };
                    scope.$on('$destroy', () => {
                        $window.parent.postMessage({ action: 'AsideModalClosed' }, window.location.origin);
                    });
                    scope.bottomContainerPresent = transcludeFn.isSlotFilled('bottomContainer');
                    scope.upperContainerPresent = transcludeFn.isSlotFilled('upperContainer');
                }
            };
        }]);
}());
