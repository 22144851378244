"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allResult = exports.firstResult = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const firstResult = () => rxjs_1.pipe(operators_1.map((response) => {
    return response.first;
}));
exports.firstResult = firstResult;
const allResult = () => rxjs_1.pipe(operators_1.map((response) => {
    return response.all;
}));
exports.allResult = allResult;
