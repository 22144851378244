<div class="authentication-policies" [ngClass]="{ 'mc-identity-list-loading': !(initialLoadingComplete$ | async) }">
  <mc-spinner *ngIf="!(initialLoadingComplete$ | async)"></mc-spinner>

  <div *ngIf="initialLoadingComplete$ | async">
    <mc-table *ngIf="!(isLoadResponseEmpty$ | async)"
              [isLoading]="isLoading$ | async"
              [data]="tableData$ | async"
              [columns]="columnList"
              (rowClick)="openPolicyDetails($event)"
              [highlightedRow]="selectedPolicy$ | async"
              translatePrefix="$I18N_IDENTITY_POLICY_LIST">

      <mc-filters (paginatorChange)="onPaginationChange($event)" [metadata]="metaData$ | async"></mc-filters>

      <button
        mc-caps="Permission.ID_MANAGEMENT_EDIT"
        class="mc-table-actions btn btn-primary mc-policy-create"
        (click)="createNewPolicy()"
        translate="$I18N_IDENTITY_POLICY_LIST.BUTTONS.CREATE_NEW_POLICY"
      ></button>

      <mc-column key="name">
        <mc-body-cell *mcBodyCellDef="let row">
            <span>{{row.name}}</span>
        </mc-body-cell>
      </mc-column>

      <mc-column key="appliedTo">
        <mc-body-cell *mcBodyCellDef="let row">
          <mc-identity-policy-groups [groups]="row.groups"></mc-identity-policy-groups>
        </mc-body-cell>
      </mc-column>

      <mc-column-date key="lastUpdated"></mc-column-date>

      <mc-column-actions key="policyActions" mc-caps="Permission.ID_MANAGEMENT_EDIT" mcShowColumnConfig>
        <mc-row-actions *mcRowActions="let row">
          <li mcRowAction="$I18N_IDENTITY_POLICY_LIST.ACTIONS.VIEW" (click)="openPolicyDetails(row)"></li>
          <li mcRowAction="$I18N_IDENTITY_POLICY_LIST.ACTIONS.EDIT" (click)="editPolicyClick(row)"></li>
          <li mcRowAction="$I18N_IDENTITY_POLICY_LIST.ACTIONS.DELETE" (click)="deletePolicyClick(row)"></li>
        </mc-row-actions>
      </mc-column-actions>
    </mc-table>

    <mc-identity-empty-policy-list *ngIf="isLoadResponseEmpty$ | async"
                                   (createPolicy)="createNewPolicy()"></mc-identity-empty-policy-list>
  </div>
</div>
