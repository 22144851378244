<div class="panel panel-default">
    <form [formGroup]="form" (keyup)="handleKeyUp($event)">
        <div class="row">
                <div class="col-sm-3">
                    <mc-text-field
                        label="{{'$I18N_SECURE_MESSAGING_SEARCH_BOX.FROM' | translate }}"
                        formControlName="from"
                        data-test="from"
                        errorPrefix="$I18N_SECURE_MESSAGING_SEARCH_BOX.VALIDATION"
                        placeholder="{{'$I18N_SECURE_MESSAGING_SEARCH_BOX.EMAIL_OR_DOMAIN' | translate}}"
                        >
                    </mc-text-field>
                </div>
                <div class="col-sm-3">
                    <mc-text-field
                        label="{{'$I18N_SECURE_MESSAGING_SEARCH_BOX.TO' | translate }}"
                        formControlName="to"
                        data-test="to"
                        errorPrefix="$I18N_SECURE_MESSAGING_SEARCH_BOX.VALIDATION"
                        placeholder="{{'$I18N_SECURE_MESSAGING_SEARCH_BOX.EMAILS_OR_DOMAINS' | translate}}"
                        >
                    </mc-text-field>
                </div>
                <div class="col-sm-3">
                    <mc-text-field
                        label="{{'$I18N_SECURE_MESSAGING_SEARCH_BOX.SUBJECT' | translate }}"
                        errorPrefix="$I18N_SECURE_MESSAGING_SEARCH_BOX.VALIDATION"
                        data-test="subject"
                        formControlName="subject">
                    </mc-text-field>
                </div>
                <div class="col-sm-3">
                    <mc-date-range-picker
                    label="{{'$I18N_SECURE_MESSAGING_SEARCH_BOX.DATE_RANGE' | translate }}"
                    formControlName="dateRange"
                    [config]="dateRangePickerConfig"
                    [horizontal]="'horizontal'"
                    >
                    </mc-date-range-picker>                    
                </div>
            </div>
        </form>    
    <div class="row">
        <div class="col-sm-12 text-right">
            <button class="btn btn-default" (click)="clear()">{{"$I18N_SECURE_MESSAGING_SEARCH_BOX.CLEAR_ALL" | translate }}</button>
            <button
                class="btn btn-primary"
                (click)="onSubmit()"
                [disabled]="form.invalid"
                >{{"$I18N_SECURE_MESSAGING_SEARCH_BOX.SEARCH" | translate }}</button>
        </div>
    </div>
</div>