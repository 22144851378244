<mc-layout-list-extra-container>
  <mc-help-container>
    <mc-help-link url="https://community.mimecast.com/s/">
    </mc-help-link>
  </mc-help-container>

  <mc-body-container>
    <mc-policies-table [hasIEPPackage]="hasIEPPackage" [isAAAOrFAAUser]="isAAAOrFAAUser" [selectedRow]="selectedRow$ | async"
      [externalTableSearchCount]="totalCount$ | async" [isLoading]="isLoading$ | async" [metadata]="metadata$ | async"
      (onSearchFilterChanged)="search($event)" (onCreateNewPolicy)="createNewPolicy()" (onPolicyEdit)="editPolicy($event)"
      (onPolicyDuplicate)="duplicatePolicy($event)" (onPolicyDelete)="deletePolicy($event)" (onRowClicked)="rowClicked()">
    </mc-policies-table>
  </mc-body-container>
</mc-layout-list-extra-container>
