"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DirectorySyncLdapCommonSettingsStepComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const common_1 = require("../../../../../common/common");
class DirectorySyncLdapCommonSettingsStepComponent {
    constructor(fb, translateService) {
        this.fb = fb;
        this.translateService = translateService;
        this.delay = 200;
        this.onIntegrationDetailsUpdated = new core_1.EventEmitter();
        this.hostTooltipKbLink = common_1.HOST_TOOLTIP_KB_LINK;
        this.ldapADUserDnTooltipKbLink = 'https://community.mimecast.com/s/article/Enabling-LDAP-Directory-Synchronization-for-Active-Directory-1818773651';
        this.ldapDominoUserDnTooltipKbLink = 'https://community.mimecast.com/s/article/Enable-LDAP-Directory-Sync-for-Domino-Directory-1523098585';
    }
    ngOnInit() {
        this.form = this.fb.group({
            host: '',
            alternateHost: '',
            encryptionConn: undefined,
            encryptionMode: 'strict',
            port: 636,
            userDn: new forms_1.FormControl(''),
            password: '',
            rootDn: '',
            foreignDirectorySync: undefined
        });
    }
    get encryptionModeOptions() {
        return [
            {
                value: 'strict',
                label: this.translateService.instant('$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.ENCRYPTION_MODE_OPTIONS.STRICT')
            },
            {
                value: 'relaxed',
                label: this.translateService.instant('$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.ENCRYPTION_MODE_OPTIONS.RELAXED')
            }
        ];
    }
    update() {
        this.onIntegrationDetailsUpdated.emit(this.form.getRawValue());
        if (this.isEditMode &&
            this.form.value.encryptionConn &&
            this.form.value.encryptionMode === 'none') {
            this.onIntegrationDetailsUpdated.emit({ encryptionMode: 'strict' });
        }
    }
    integrationType() {
        return this.integration.type === 'active_directory'
            ? 'On-Premises Active Directory (LDAP)'
            : 'Domino Directory (LDAP)';
    }
    getEncryptionConnValue() {
        // update to nullish coalesce: this.integration.encryptionConn ?? true;
        return this.integration.encryptionConn !== null && this.integration.encryptionConn !== void 0
            ? this.integration.encryptionConn
            : true;
    }
    isEncryptionConnectionEnabled() {
        return this.form.value.encryptionConn;
    }
    isLdapADIntegration() {
        return this.integration.type === 'active_directory';
    }
    isLdapDominoIntegration() {
        return this.integration.type === 'domino';
    }
}
exports.DirectorySyncLdapCommonSettingsStepComponent = DirectorySyncLdapCommonSettingsStepComponent;
