"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefinitionDetailsService = void 0;
class DefinitionDetailsService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    getDefinitionDetails(payload) {
        return this.httpClient.post('/api/policy/attachmentprotect/get-definition', {
            id: payload.id
        });
    }
}
exports.DefinitionDetailsService = DefinitionDetailsService;
