"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PurgeMessagesConfirmPurgeFail = exports.PurgeMessagesConfirmPurgeSuccess = exports.PurgeMessagesConfirmPurge = exports.PurgeMessagesPurgeCancelAction = exports.PurgeMessagesSearchCancelAction = exports.PurgeMessagesCountFailAction = exports.PurgeMessagesCountSuccessAction = exports.PurgeMessagesCountRequestAction = exports.PURGE_MESSAGES_PURGE_CANCEL = exports.PURGE_MESSAGES_CONFIRM_PURGE_FAIL = exports.PURGE_MESSAGES_CONFIRM_PURGE_SUCCESS = exports.PURGE_MESSAGES_CONFIRM_PURGE = exports.PURGE_MESSAGES_SEARCH_CANCEL = exports.PURGE_MESSAGES_COUNT_FAIL = exports.PURGE_MESSAGES_COUNT_SUCCESS = exports.PURGE_MESSAGES_COUNT_REQUEST = void 0;
exports.PURGE_MESSAGES_COUNT_REQUEST = '[Message Center / Purge Messages ] Request purge messages count';
exports.PURGE_MESSAGES_COUNT_SUCCESS = '[Message Center / Purge Messages ] Purge messages count Success';
exports.PURGE_MESSAGES_COUNT_FAIL = '[Message Center / Purge Messages ] Purge messages count Fail';
exports.PURGE_MESSAGES_SEARCH_CANCEL = '[Message Center / Purge Messages ] Purge messages search cancel';
exports.PURGE_MESSAGES_CONFIRM_PURGE = '[Message Center / Purge Messages ] Purge messages confirm purge';
exports.PURGE_MESSAGES_CONFIRM_PURGE_SUCCESS = '[Message Center / Purge Messages ] Purge messages confirm purge success';
exports.PURGE_MESSAGES_CONFIRM_PURGE_FAIL = '[Message Center / Purge Messages ] Purge messages confirm purge fail';
exports.PURGE_MESSAGES_PURGE_CANCEL = '[Message Center / Purge Messages ] Purge messages purge cancel';
class PurgeMessagesCountRequestAction {
    constructor(formValue) {
        this.formValue = formValue;
        this.type = exports.PURGE_MESSAGES_COUNT_REQUEST;
    }
}
exports.PurgeMessagesCountRequestAction = PurgeMessagesCountRequestAction;
class PurgeMessagesCountSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.PURGE_MESSAGES_COUNT_SUCCESS;
    }
}
exports.PurgeMessagesCountSuccessAction = PurgeMessagesCountSuccessAction;
class PurgeMessagesCountFailAction {
    constructor() {
        this.type = exports.PURGE_MESSAGES_COUNT_FAIL;
    }
}
exports.PurgeMessagesCountFailAction = PurgeMessagesCountFailAction;
class PurgeMessagesSearchCancelAction {
    constructor() {
        this.type = exports.PURGE_MESSAGES_SEARCH_CANCEL;
    }
}
exports.PurgeMessagesSearchCancelAction = PurgeMessagesSearchCancelAction;
class PurgeMessagesPurgeCancelAction {
    constructor() {
        this.type = exports.PURGE_MESSAGES_PURGE_CANCEL;
    }
}
exports.PurgeMessagesPurgeCancelAction = PurgeMessagesPurgeCancelAction;
class PurgeMessagesConfirmPurge {
    constructor(reason) {
        this.reason = reason;
        this.type = exports.PURGE_MESSAGES_CONFIRM_PURGE;
    }
}
exports.PurgeMessagesConfirmPurge = PurgeMessagesConfirmPurge;
class PurgeMessagesConfirmPurgeSuccess {
    constructor() {
        this.type = exports.PURGE_MESSAGES_CONFIRM_PURGE_SUCCESS;
    }
}
exports.PurgeMessagesConfirmPurgeSuccess = PurgeMessagesConfirmPurgeSuccess;
class PurgeMessagesConfirmPurgeFail {
    constructor() {
        this.type = exports.PURGE_MESSAGES_CONFIRM_PURGE_FAIL;
    }
}
exports.PurgeMessagesConfirmPurgeFail = PurgeMessagesConfirmPurgeFail;
