"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailBodyComponent = void 0;
const core_1 = require("@angular/core");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
class EmailBodyComponent {
    constructor(translate) {
        this.translate = translate;
        this.showHtml = new core_1.EventEmitter();
        this.loadContentRequest = new core_1.EventEmitter();
        this.showHtml$ = new rxjs_1.BehaviorSubject(true);
        this.askForEmailViewConfirmation = false;
        this.contentAdministratorInlineNotification = {
            msg: this.translate.instant('$I18N_EMAIL_BODY_NOTIFICATION_TITLE'),
            boxed: true,
            status: 'info',
            actions: [
                {
                    label: this.translate.instant('$I18N_EMAIL_BODY_NOTIFICATION_BUTTON_LABEL'),
                    type: 'button',
                    callback: () => {
                        this.loadContentRequest.emit();
                    }
                }
            ]
        };
    }
    ngOnChanges() {
        this.askForEmailViewConfirmation = !(this.contentAdministratorView === 'Content' || this.forceLoadContent);
    }
    viewHtml(showHtmlFlag) {
        this.showHtml$.next(showHtmlFlag);
        this.showHtml.emit(showHtmlFlag);
    }
    getPrintableContent() {
        return this.showHtml$.pipe(operators_1.switchMap(isShowingHtml => {
            if (isShowingHtml && this.emailHtmlPanelComponent !== undefined) {
                return this.emailHtmlPanelComponent
                    .getPrintableHtml()
                    .pipe(operators_1.map(html => ({ content: html, isHtml: true })));
            }
            else {
                return rxjs_1.of({ content: this.text, isHtml: false });
            }
        }), operators_1.first());
    }
}
exports.EmailBodyComponent = EmailBodyComponent;
