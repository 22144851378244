"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyncAndRecoverOverviewApiService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const i0 = require("@angular/core");
const i1 = require("@angular/common/http");
class SyncAndRecoverOverviewApiService {
    constructor(http) {
        this.http = http;
        this.baseUrl = '/api/';
    }
    loadChartData() {
        return this.http.post(`${this.baseUrl}backup/get-stats`, {}).pipe(operators_1.map((response) => response), operators_1.catchError((response) => rxjs_1.of(response)));
    }
}
exports.SyncAndRecoverOverviewApiService = SyncAndRecoverOverviewApiService;
SyncAndRecoverOverviewApiService.ɵprov = i0.ɵɵdefineInjectable({ factory: function SyncAndRecoverOverviewApiService_Factory() { return new SyncAndRecoverOverviewApiService(i0.ɵɵinject(i1.HttpClient)); }, token: SyncAndRecoverOverviewApiService, providedIn: "root" });
