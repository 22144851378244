<div class="mc-api-integration-step-block">
  <mc-wizard-step-header
    compact="true"
    label="{{ header | translate }}"
  >
  </mc-wizard-step-header>

  <div class="mc-detailed-list mc-api-integration-summary-block">
    <div class="row mc-detailed-list-row panel-padding-bottom">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.SUMMARY.INTEGRATION.NAME' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{ '$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.SUMMARY.INTEGRATION.VALUE' | translate }}
      </div>
    </div>

    <div class="row mc-detailed-list-row panel-padding-bottom">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.SUMMARY.CLIENT_ID' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ integration?.clientId }}</div>
    </div>

    <div class="row mc-detailed-list-row panel-padding-bottom">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.SUMMARY.BASE_URL' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ integration?.baseUrl }}</div>
    </div>

    <ng-container *ngFor="let event of integration?.events">
      <div class="row mc-detailed-list-row panel-padding-bottom">
        <div class="col-xs-4 mc-detailed-list-label">
          {{ '$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.SUMMARY.EVENTS.' + event.name | uppercase | translate }}
        </div>
        <div class="mc-detailed-list-column">{{ event.group.name }}</div>
      </div>
    </ng-container>

    <div class="row mc-detailed-list-row panel-padding-bottom">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.SUMMARY.NOTIFICATION.LABEL' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        <ng-container *ngIf="!!notificationGroupsList.length">
          <label class="mc-detailed-list-label">
            {{ '$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.SUMMARY.NOTIFICATION.GROUPS' | translate }}
          </label>
          <ul
            class="column"
            data-columns="1"
          >
            <li *ngFor="let group of notificationGroupsList">{{group}}</li>
          </ul>
        </ng-container>

        <ng-container *ngIf="!!notificationAddresses.length">
          <label class="mc-detailed-list-label">
            {{ '$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.SUMMARY.NOTIFICATION.USERS' | translate }}
          </label>

          <ul
            class="column"
            data-columns="1"
          >
            <li *ngFor="let email of notificationAddresses">{{email}}</li>
          </ul>
        </ng-container>
      </div>
    </div>

    <div class="mc-detailed-list mc-api-integration-summary-block">
      <form
        novalidate
        [formGroup]="form"
      >
        <mc-enable-switch
          [label]="'$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.SUMMARY.STATUS'"
          formControlName="enabled"
          name="enabled"
          contentDivClass="row mc-detailed-list-row panel-padding-top"
          labelClass="col-xs-4 mc-detailed-list-label"
          switchDivClass="mc-detailed-list-column"
        >
        </mc-enable-switch>
      </form>
    </div>
  </div>
</div>
