"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogsListTableComponent = void 0;
const core_1 = require("@angular/core");
const moment = require("moment");
const logs_list_table_component_static_values_1 = require("./logs-list-table.component.static-values");
const date_range_config_1 = require("../../../../../message-center/common/components/table-search-configs/date-range-config");
const table_store_1 = require("@mimecast-ui/table-store");
const effects_1 = require("@ngrx/effects");
const logs_side_panel_actions_1 = require("../../actions/logs-side-panel.actions");
const url_override_types_enum_1 = require("../../../managed-urls/common/enums/url-override-types.enum");
const url_create_types_enum_1 = require("../../../managed-urls/common/enums/url-create-types.enum");
const url_create_actions_1 = require("../../../managed-urls/actions/url-create.actions");
const log_list_actions_1 = require("../../actions/log-list.actions");
const LogsHelper_1 = require("../common/LogsHelper");
class LogsListTableComponent extends table_store_1.TableBaseComponent {
    constructor(tableStoreStore, translate, selectedRowActionsSubj, capsService, accountPackageService, tabService, windowService) {
        super(tableStoreStore, 'urlProtectionLogsList');
        this.translate = translate;
        this.selectedRowActionsSubj = selectedRowActionsSubj;
        this.capsService = capsService;
        this.accountPackageService = accountPackageService;
        this.tabService = tabService;
        this.windowService = windowService;
        this.canCreateEditAndDeleteCapExpression = 'Permission.POLICIES_EDIT || Permission.SERVICES_TTP_URL_PROTECT_EDIT';
        this.canCheckUrlExpression = 'Permission.SERVICES_TTP_URL_PROTECT_READ || Permission.SERVICES_TTP_URL_PROTECT_EDIT';
        this.canEdit = false;
        this.canCheckUrl = false;
        this.columns = [...logs_list_table_component_static_values_1.tableColumns];
        this.columnsToStartHidden = [...logs_list_table_component_static_values_1.tableColumnsStartHidden];
        this.columnsAlwaysVisible = [...logs_list_table_component_static_values_1.tableColumnsAlwaysVisible];
        this.columnsToIgnore = logs_list_table_component_static_values_1.tableColumnsToIgnore;
        this.selectedRows = [];
        this.exportColumns = [...logs_list_table_component_static_values_1.exportColumns];
        this.searchSections = logs_list_table_component_static_values_1.tableSectionSearch;
        this.dateRangePickerConfig = date_range_config_1.DateRangeConfig.config;
        this.filterSections = [...logs_list_table_component_static_values_1.tableSectionFilters];
        this.filterDateRange = {
            start: moment()
                .subtract(1, 'day')
                .format(),
            end: moment().format()
        };
        this.onPaginatorChanged = new core_1.EventEmitter();
        this.onRowClicked = new core_1.EventEmitter();
        this.onLogsExported = new core_1.EventEmitter();
        this.logsHelper = new LogsHelper_1.LogsHelper(this.translate);
        this.setupSubscriptions();
        const tab = this.tabService.getSelectedTab();
        tab.onRefresh = () => {
            const payload = Object.assign(Object.assign({}, this.currentFilters), { to: moment().format() });
            this.onFilterChange(payload);
        };
    }
    ngOnInit() {
        this.onFilterChange(Object.assign(Object.assign({}, this.currentFilters), { from: this.filterDateRange.start, to: this.filterDateRange.end }));
        this.updateFilters();
        // set is browser isolation package enabled flag
        this.setBrowserIsolationFlag();
    }
    updateFilters() {
        const filtersConfig = this.extendedFilter ? logs_list_table_component_static_values_1.tableSectionFiltersExtended : logs_list_table_component_static_values_1.tableSectionFilters;
        if (this.browserIsolationExtendedFilter) {
            filtersConfig[0].filters.push(...logs_list_table_component_static_values_1.tableSectionFiltersBrowserIsolationExtended);
        }
        this.filterSections = filtersConfig.map(section => {
            return Object.assign(Object.assign({}, section), { displayName: this.translate.instant(this.setFilterTranslation(section.displayName, '', true)), filters: section.filters.map(fitem => {
                    return Object.assign(Object.assign({}, fitem), { displayName: this.translate.instant(this.setFilterTranslation(section.displayName, fitem.displayName)) });
                }) });
        });
    }
    setBrowserIsolationFlag() {
        if (this.accountPackageService.hasBrowserIsolationEnabled()) {
            if (!this.windowService.isIe && !this.windowService.isMobile) {
                this.isBrowserIsolationEnabled = true;
                return;
            }
        }
        this.isBrowserIsolationEnabled = false;
    }
    onDateFilterChange(date) {
        if (!!date.range) {
            this.updateDataRangeTime(date);
            this.onFilterChange(Object.assign(Object.assign({}, this.currentFilters), { from: this.filterDateRange.start, to: this.filterDateRange.end }));
        }
    }
    setFilterTranslation(titleKey, itemKey, isTitle = false) {
        const prefix = '$I18N_SERVICES_URL_PROTECTION_LOGS_TABLE.TABLE.FILTERS.COLUMN_VALUE_FILTER_FIELD.';
        if (isTitle) {
            return prefix + titleKey;
        }
        else {
            return prefix + titleKey + '_FILTERS.' + itemKey;
        }
    }
    toFilterBy(filter) {
        const keys = Object.keys(filter);
        const filterBy = [];
        keys.forEach(key => filterBy.push(...this.toFilters(key, filter[key])));
        return { filterBy };
    }
    changeColumnFilter(filter) {
        this.onFilterChange(Object.assign(Object.assign({}, this.currentFilters), this.toFilterBy(filter)));
    }
    urlClick(urlLog) {
        this.store.dispatch(new log_list_actions_1.UrlProtectionBrowserIsolationAction(urlLog));
    }
    toFilters(fieldName, filters) {
        return !!filters && filters.length > 0
            ? filters.map(value => ({
                fieldName: this.transformToFilterKey(fieldName),
                value
            }))
            : [];
    }
    transformToFilterKey(key) {
        const filtersMap = {
            action: 'actions',
            routing: 'route',
            adminoverride: 'admin_override',
            userawareness: 'user_awareness',
            scanresult: 'scan_result',
            useroverride: 'user_action'
        };
        return filtersMap[key];
    }
    setupSubscriptions() {
        this.capsCreateEditDeleteSubscription = this.capsService
            .evalCapabilitiesExpression(this.canCreateEditAndDeleteCapExpression)
            .subscribe(canEdit => {
            if (canEdit) {
                this.canEdit = canEdit;
            }
        });
        this.capsCheckUrlSubscription = this.capsService
            .evalCapabilitiesExpression(this.canCheckUrlExpression)
            .subscribe(canCheckUrl => {
            if (canCheckUrl) {
                this.canCheckUrl = canCheckUrl;
            }
        });
        this.tableDataSubscription = this.tableData$.subscribe((data) => {
            this.tableData = data;
        });
        this.tableMetaDataSubscription = this.metaData$.subscribe(data => {
            if (data && data.pagination) {
                this.metadata = {
                    pagination: Object.assign(Object.assign({}, data.pagination), { totalRows: data.pagination.totalCount })
                };
            }
        });
        // on sidenav close, clear table store selected row
        this.selectedRowSubscription = this.selectedRowActionsSubj
            .pipe(effects_1.ofType(logs_side_panel_actions_1.CLEAR_SIDE_PANEL_STATE))
            .subscribe(() => {
            this.dispatchClearSelectedRow();
        });
    }
    changePaginator(pagination) {
        this.onPaginatorChanged.emit(pagination);
        super.onPaginationChange(pagination);
    }
    changeSearchFilter(filter) {
        this.onFilterChange(Object.assign(Object.assign({}, this.currentFilters), { searchBy: {
                fieldName: filter.selectValue,
                value: filter.searchQuery
            } }));
    }
    displayAdminOverrideIcon(selectedRow) {
        const key = selectedRow.adminOverride.toLocaleLowerCase();
        const map = {
            allow: 'fas fa-check',
            block: 'fas fa-times',
            'n/a': 'n-a-char'
        };
        return map[key];
    }
    displayAdminOverrideLabel(selectedRow) {
        const key = selectedRow.adminOverride.toLocaleLowerCase();
        const map = {
            allow: this.translate.instant('$I18N_SERVICES_URL_PROTECTION_LOGS_TABLE.TABLE.SHARED_LABELS.ALLOW'),
            block: this.translate.instant('$I18N_SERVICES_URL_PROTECTION_LOGS_TABLE.TABLE.SHARED_LABELS.BLOCK'),
            'n/a': ''
        };
        return map[key];
    }
    displayUserOverrideIcon(selectedRow) {
        const key = selectedRow.userOverride.toLocaleLowerCase();
        const map = {
            continue: 'fas fa-check',
            none: 'fas fa-times'
        };
        return map[key];
    }
    displayUserOverrideLabel(selectedRow) {
        const key = selectedRow.userOverride.toLocaleLowerCase();
        const map = {
            continue: this.translate.instant('$I18N_SERVICES_URL_PROTECTION_LOGS_TABLE.TABLE.SHARED_LABELS.CONTINUE'),
            none: this.translate.instant('$I18N_SERVICES_URL_PROTECTION_LOGS_TABLE.TABLE.SHARED_LABELS.NONE')
        };
        return map[key];
    }
    displayUserAwarenessIcon(selectedRow) {
        const key = selectedRow.userAwarenessAction.toLocaleLowerCase();
        const map = {
            continue: 'fas fa-check',
            exit: 'fas fa-times',
            'n/a': 'n-a-char'
        };
        return map[key];
    }
    displayUserAwarenessLabel(selectedRow) {
        const key = selectedRow.userAwarenessAction.toLocaleLowerCase();
        const map = {
            continue: this.translate.instant('$I18N_SERVICES_URL_PROTECTION_LOGS_TABLE.TABLE.SHARED_LABELS.CONTINUE'),
            exit: this.translate.instant('$I18N_SERVICES_URL_PROTECTION_LOGS_TABLE.TABLE.SHARED_LABELS.EXIT'),
            'n/a': ''
        };
        return map[key];
    }
    displayRouteLabel(route) {
        return this.logsHelper.getRouteLabel(route);
    }
    displayScanResultIcon(selectedRow) {
        return this.logsHelper.getScanResultIconClass(selectedRow.scanResult);
    }
    displayScanResultLabel(selectedRow) {
        return this.logsHelper.getScanResultLabel(selectedRow);
    }
    allowUrl(urlLog) {
        const payload = {
            action: url_override_types_enum_1.UrlOverrideTypes.PERMIT,
            url: urlLog.url
        };
        this.store.dispatch(new url_create_actions_1.CreateManagedUrlAction([payload], url_create_types_enum_1.UrlCreateTypes.ALLOW));
    }
    checkUrl(urlLog) {
        this.store.dispatch(new log_list_actions_1.UrlProtectionOpenCheckUrlModal(urlLog.url));
    }
    blockUrl(urlLog) {
        const payload = {
            action: url_override_types_enum_1.UrlOverrideTypes.BLOCK,
            url: urlLog.url
        };
        this.store.dispatch(new url_create_actions_1.CreateManagedUrlAction([payload], url_create_types_enum_1.UrlCreateTypes.BLOCK));
    }
    ngOnDestroy() {
        this.tableDataSubscription.unsubscribe();
        this.selectedRowSubscription.unsubscribe();
        this.tableMetaDataSubscription.unsubscribe();
        this.capsCreateEditDeleteSubscription.unsubscribe();
        this.capsCheckUrlSubscription.unsubscribe();
        super.ngOnDestroy();
    }
    clickRow(row) {
        this.onRowClicked.emit(row);
        this.onRowClick(row);
    }
    exportResults() {
        this.onLogsExported.emit(this.exportColumns);
    }
    updateDataRangeTime(date) {
        if (date.id !== 'USER_DEFINED') {
            let start = date.range.start;
            let end = date.range.end;
            const currentTime = moment();
            const time = {
                hour: currentTime.get('hour'),
                minute: currentTime.get('minute'),
                second: currentTime.get('second')
            };
            start = start.set(time);
            end = end.set(time);
            date.range.start = moment(start);
            date.range.end = moment(end);
        }
        this.filterDateRange.start = date.range.start.format();
        this.filterDateRange.end = date.range.end.format();
    }
}
exports.LogsListTableComponent = LogsListTableComponent;
