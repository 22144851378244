<div class="mc-aside-scrollable">
  <div class="mc-recipients-container">
    <mc-filter-search
      (search)="onSearchRecipient($event)"
      (clear)="clearSearch()"
      [placeholder]="translatePrefix + '.SEARCH_PLACEHOLDER'"
      *ngIf="recipients.length"
    ></mc-filter-search>

    <mc-table
      *ngIf="(recipientsToDisplay | async).length; else emptyResultsMessage ? noDataNotification : noData"
      [columns]="recipientsColumns"
      [isLoading]="false"
      [data]="recipientsToDisplay | async"
      [translatePrefix]="translatePrefix">
      <mc-column key="status">
        <mc-body-cell *mcBodyCellDef="let row">
          <span class="mc-status-{{row.status.toLowerCase()}}">
            {{ translatePrefix + '.STATUS.' + row.status | uppercase | translate }}
            <span *ngIf="showFailureCode(row.status, row.failureCode)">
              ({{ '$I18N_REMEDIATION.FAILURE_TYPE.' + row.failureCode | uppercase | translate }})
            </span>
          </span>
        </mc-body-cell>
      </mc-column>
    </mc-table>
  </div>
</div>


<ng-template #noData>
  <div class="mc-no-data">{{ translatePrefix + '.NO_ENTRIES' | translate }}</div>
</ng-template>

<ng-template #noDataNotification>
  <mc-inline-notification [notification]="emptyResultsMessage"></mc-inline-notification>
</ng-template>
