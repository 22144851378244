"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgPolicyAddAllowedCategories = exports.SwgPolicyClearCategorySections = exports.SwgPolicyLoadCategorySectionsFailure = exports.SwgPolicyLoadCategorySectionsSuccess = exports.SwgPolicyLoadCategorySections = exports.SWG_POLICY_ADD_ALLOWED_CATEGORIES = exports.SWG_POLICY_CLEAR_CATEGORY_SECTIONS = exports.SWG_POLICY_LOAD_CATEGORY_SECTIONS_FAILURE = exports.SWG_POLICY_LOAD_CATEGORY_SECTIONS_SUCCESS = exports.SWG_POLICY_LOAD_CATEGORY_SECTIONS = void 0;
exports.SWG_POLICY_LOAD_CATEGORY_SECTIONS = '[Policy] Swg policy load categories sections';
exports.SWG_POLICY_LOAD_CATEGORY_SECTIONS_SUCCESS = '[Policy] Swg policy load categories sections success';
exports.SWG_POLICY_LOAD_CATEGORY_SECTIONS_FAILURE = '[Policy] Swg policy load categories sections failure';
exports.SWG_POLICY_CLEAR_CATEGORY_SECTIONS = '[Policy] Swg policy clear categories sections';
exports.SWG_POLICY_ADD_ALLOWED_CATEGORIES = '[Policy] Swg policy add allowed categories';
class SwgPolicyLoadCategorySections {
    constructor() {
        this.type = exports.SWG_POLICY_LOAD_CATEGORY_SECTIONS;
    }
}
exports.SwgPolicyLoadCategorySections = SwgPolicyLoadCategorySections;
class SwgPolicyLoadCategorySectionsSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SWG_POLICY_LOAD_CATEGORY_SECTIONS_SUCCESS;
    }
}
exports.SwgPolicyLoadCategorySectionsSuccess = SwgPolicyLoadCategorySectionsSuccess;
class SwgPolicyLoadCategorySectionsFailure {
    constructor() {
        this.type = exports.SWG_POLICY_LOAD_CATEGORY_SECTIONS_FAILURE;
    }
}
exports.SwgPolicyLoadCategorySectionsFailure = SwgPolicyLoadCategorySectionsFailure;
class SwgPolicyClearCategorySections {
    constructor() {
        this.type = exports.SWG_POLICY_CLEAR_CATEGORY_SECTIONS;
    }
}
exports.SwgPolicyClearCategorySections = SwgPolicyClearCategorySections;
class SwgPolicyAddAllowedCategories {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SWG_POLICY_ADD_ALLOWED_CATEGORIES;
    }
}
exports.SwgPolicyAddAllowedCategories = SwgPolicyAddAllowedCategories;
