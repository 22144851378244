"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UrlProtectionOpenCheckUrlModal = exports.UrlProtectionBrowserIsolationFailAction = exports.UrlProtectionBrowserIsolationSuccessAction = exports.ShowMangedURLBannerForCheckTabAction = exports.UrlProtectionBrowserIsolationAction = exports.UrlProtectionLogsExportAction = exports.UrlProtectionLogsPaginationAction = exports.UrlProtectionLogsFailAction = exports.UrlProtectionLogsSuccessAction = exports.UrlProtectionLogsRequestAction = exports.URL_PROTECTION_OPEN_CHECK_URL_MODAL = exports.URL_PROTECTION_BROWSER_ISOLATION_LINK_FAIL = exports.URL_PROTECTION_MANAGED_URL_CHECK_BANNER = exports.URL_PROTECTION_BROWSER_ISOLATION_LINK_SUCCESS = exports.URL_PROTECTION_BROWSER_ISOLATION_LINK_REQUEST = exports.URL_PROTECTION_LOGS_EXPORT = exports.URL_PROTECTION_LOGS_REFRESH = exports.URL_PROTECTION_LOGS_PAGINATION = exports.URL_PROTECTION_LOGS_FAIL = exports.URL_PROTECTION_LOGS_SUCCESS = exports.URL_PROTECTION_LOGS_REQUEST = void 0;
exports.URL_PROTECTION_LOGS_REQUEST = '[Services / Url protection] Request Get Url Protection Logs';
exports.URL_PROTECTION_LOGS_SUCCESS = '[Services / Url protection] Get Url Protection Logs Success';
exports.URL_PROTECTION_LOGS_FAIL = '[Services / Url protection] Get Url Protection Logs Fail';
exports.URL_PROTECTION_LOGS_PAGINATION = '[Services / Url protection] Url Protection Logs Pagination';
exports.URL_PROTECTION_LOGS_REFRESH = '[Services /  Url protection]  Url Protection Logs Refresh';
exports.URL_PROTECTION_LOGS_EXPORT = '[Services / Url protection] Export Logs';
exports.URL_PROTECTION_BROWSER_ISOLATION_LINK_REQUEST = '[Services / Url protection] Browser isolation link clicked';
exports.URL_PROTECTION_BROWSER_ISOLATION_LINK_SUCCESS = '[Services / Url protection] Browser isolation request success';
exports.URL_PROTECTION_MANAGED_URL_CHECK_BANNER = '[Services / Url protection] Url Protection Logs Managed URL banner';
exports.URL_PROTECTION_BROWSER_ISOLATION_LINK_FAIL = '[Services / Url protection] Browser isolation request fail';
exports.URL_PROTECTION_OPEN_CHECK_URL_MODAL = '[Services / Url protection] Open Check Url Modal';
class UrlProtectionLogsRequestAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.URL_PROTECTION_LOGS_REQUEST;
    }
}
exports.UrlProtectionLogsRequestAction = UrlProtectionLogsRequestAction;
class UrlProtectionLogsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.URL_PROTECTION_LOGS_SUCCESS;
    }
}
exports.UrlProtectionLogsSuccessAction = UrlProtectionLogsSuccessAction;
class UrlProtectionLogsFailAction {
    constructor() {
        this.type = exports.URL_PROTECTION_LOGS_FAIL;
    }
}
exports.UrlProtectionLogsFailAction = UrlProtectionLogsFailAction;
class UrlProtectionLogsPaginationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.URL_PROTECTION_LOGS_PAGINATION;
    }
}
exports.UrlProtectionLogsPaginationAction = UrlProtectionLogsPaginationAction;
class UrlProtectionLogsExportAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.URL_PROTECTION_LOGS_EXPORT;
    }
}
exports.UrlProtectionLogsExportAction = UrlProtectionLogsExportAction;
class UrlProtectionBrowserIsolationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.URL_PROTECTION_BROWSER_ISOLATION_LINK_REQUEST;
    }
}
exports.UrlProtectionBrowserIsolationAction = UrlProtectionBrowserIsolationAction;
class ShowMangedURLBannerForCheckTabAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.URL_PROTECTION_MANAGED_URL_CHECK_BANNER;
    }
}
exports.ShowMangedURLBannerForCheckTabAction = ShowMangedURLBannerForCheckTabAction;
class UrlProtectionBrowserIsolationSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.URL_PROTECTION_BROWSER_ISOLATION_LINK_SUCCESS;
    }
}
exports.UrlProtectionBrowserIsolationSuccessAction = UrlProtectionBrowserIsolationSuccessAction;
class UrlProtectionBrowserIsolationFailAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.URL_PROTECTION_BROWSER_ISOLATION_LINK_FAIL;
    }
}
exports.UrlProtectionBrowserIsolationFailAction = UrlProtectionBrowserIsolationFailAction;
class UrlProtectionOpenCheckUrlModal {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.URL_PROTECTION_OPEN_CHECK_URL_MODAL;
    }
}
exports.UrlProtectionOpenCheckUrlModal = UrlProtectionOpenCheckUrlModal;
