"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogsListImplService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const table_store_1 = require("@mimecast-ui/table-store");
class LogsListImplService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    static getDataFromResponse(response) {
        return {
            data: response.all[0].clickLogs,
            meta: response.meta,
            hasErrors: response.hasErrors,
            failures: response.fail
        };
    }
    getData(payload, pagination = table_store_1.defaultPaginationOption) {
        return this.httpClient
            .post('/api/ttp/url/get-logs', Object.assign(Object.assign(Object.assign({}, payload), this.handleAdminOverrideFilterRequest(payload)), { meta: { pagination } }))
            .pipe(operators_1.map((response) => {
            return LogsListImplService.getDataFromResponse(response);
        }), operators_1.catchError((error) => {
            return rxjs_1.of(LogsListImplService.getDataFromResponse(error.error));
        }));
    }
    filter(query, pagination) {
        return this.getData(query, pagination);
    }
    handleAdminOverrideFilterRequest(data) {
        if ('filterBy' in data &&
            data.filterBy.filter(f => f.fieldName === 'admin_override').length > 1) {
            return {
                filterBy: data.filterBy.filter(f => f.fieldName !== 'admin_override')
            };
        }
        return data;
    }
}
exports.LogsListImplService = LogsListImplService;
