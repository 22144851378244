"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MepLogsApiService = void 0;
const operators_1 = require("rxjs/operators");
class MepLogsApiService {
    constructor(http$) {
        this.http$ = http$;
    }
    getMepLogs(config) {
        return this.http$.post('/api/mep/find-active-views', config);
    }
    getMepLogDetails(id) {
        return this.http$
            .post('/api/mep/get-active-view', { id })
            .pipe(operators_1.map((res) => res));
    }
    dropMessage(id) {
        return this.http$
            .post('/api/mep/drop-message', { id })
            .pipe(operators_1.map((res) => res));
    }
    releaseMessage(id) {
        return this.http$
            .post('/api/mep/release-message', { id })
            .pipe(operators_1.map((res) => res));
    }
}
exports.MepLogsApiService = MepLogsApiService;
