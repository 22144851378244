"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PolicyWizardSummaryComponent = void 0;
const step_base_component_1 = require("app-new/custom-domain-block/containers/wizard/step.base.component");
const custom_domain_block_service_1 = require("app-new/custom-domain-block/services/custom-domain-block.service");
class PolicyWizardSummaryComponent extends step_base_component_1.StepBaseDirective {
    constructor(store, fb) {
        super(store, fb, 4, custom_domain_block_service_1.CustomDomainBlockService.WIZARD_ID);
        this.store = store;
        this.fb = fb;
        this.enabled = true;
        this.form = this.fb.group({
            enabled: true
        });
        this.form.patchValue({ enabled: true });
    }
    ngOnInit() {
        super.ngOnInit();
        this.form.patchValue({ enabled: this.enabled });
    }
    ngOnChanges(changes) {
        if (changes && changes['wizardData'].currentValue) {
            this.stepsData = this.wizardData.steps;
        }
    }
    getStepById(id) {
        return (this.stepsData && this.stepsData[id]) || {};
    }
    getStepDataById(id) {
        const step = this.getStepById(id);
        return (step && step.data) || {};
    }
    getActionLabel(action) {
        if (action === 'drop') {
            return '$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.ACTIONS_STEP.ACTION.DROP';
        }
        else if (action === 'redirect_user') {
            return '$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.ACTIONS_STEP.ACTION.REDIRECT_USER';
        }
        else {
            return '$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.ACTIONS_STEP.ACTION.REDIRECT_GROUP';
        }
    }
}
exports.PolicyWizardSummaryComponent = PolicyWizardSummaryComponent;
