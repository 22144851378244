<div class="mc-services-connector-wizard-step-container">
  <mc-wizard-step-header label="$I18N_CREATE_CONNECTOR_WIZARD_GWS_CUSTOMER_INFO_STEP.TITLE">
    <div>
      {{ '$I18N_CREATE_CONNECTOR_WIZARD_GWS_CUSTOMER_INFO_STEP.CONTENT.IDENTIFY_ACCOUNT_PART_1' | translate }}
      <a target="_blank" href="{{ googleAdminPageLink }}" rel="noopener noreferrer">
        {{ '$I18N_CREATE_CONNECTOR_WIZARD_GWS_INSTRUCTION_STEP.LABEL.GOOGLE_ADMIN_CONSOLE' | translate }}
      </a>
      {{ '$I18N_CREATE_CONNECTOR_WIZARD_GWS_CUSTOMER_INFO_STEP.CONTENT.IDENTIFY_ACCOUNT_PART_2' | translate }}
    </div>
  </mc-wizard-step-header>

  <div>
    <form novalidate [formGroup]="form" class="form-horizontal"
          mcDefaultLabelClass="col-sm-3"
          mcDefaultControlContainerClass="col-sm-9">
      <mc-text-field label="{{ '$I18N_CREATE_CONNECTOR_WIZARD_GWS_CUSTOMER_INFO_STEP.FIELD.CUSTOMER_ID' }}"
                     required formControlName="customerId" maxLength="30"
                     errorPrefix="{{ '$I18N_CREATE_CONNECTOR_WIZARD_GWS_CUSTOMER_INFO_STEP.VALIDATION.CUSTOMER_ID' }}">
      </mc-text-field>
      <mc-text-field label="{{ '$I18N_CREATE_CONNECTOR_WIZARD_GWS_CUSTOMER_INFO_STEP.FIELD.MAILBOX' }}"
                     required formControlName="mailbox" maxLength="256"
                     errorPrefix="{{ '$I18N_CREATE_CONNECTOR_WIZARD_GWS_CUSTOMER_INFO_STEP.VALIDATION.MAILBOX' }}"
                     helpPopoverContent="{{ '$I18N_CREATE_CONNECTOR_WIZARD_GWS_CUSTOMER_INFO_STEP.CONTENT.MAILBOX_POP_OVER' | translate }}">
      </mc-text-field>
    </form>
  </div>
</div>
