"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducer = exports.selectedRowFullDataInitialState = void 0;
const policies_list_actions_1 = require("../actions/policies-list.actions");
exports.selectedRowFullDataInitialState = {
    selectedRowFullData: undefined,
    policyStatusChanged: false
};
function reducer(state = exports.selectedRowFullDataInitialState, action) {
    switch (action.type) {
        case policies_list_actions_1.GET_POLICY_DETAILS_SUCCESS:
            return Object.assign(Object.assign({}, state), { selectedRowFullData: action.payload });
        case policies_list_actions_1.CLEAR_POLICY_DETAILS:
            return Object.assign(Object.assign({}, state), { selectedRowFullData: undefined });
        case policies_list_actions_1.POLICY_STATUS_CHANGE:
            return Object.assign(Object.assign({}, state), { policyStatusChanged: action.payload });
        default:
            return Object.assign({}, state);
    }
}
exports.reducer = reducer;
