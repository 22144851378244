<mc-layout-dashboard-simple
  keyTitle="$I18N_SWG_BLOCK_PAGE_TITLE"
  keyDescription="$I18N_SWG_BLOCK_PAGE_DESC"
>
  <mc-help-container>
    <mc-help-link url="https://community.mimecast.com/docs/DOC-3482"></mc-help-link>
  </mc-help-container>

  <mc-extra-container>
    <div class="panel-padding-bottom panel-padding-top">
      <mc-live-button
        overrideClasses="btn-primary"
        [label]="'$I18N_SWG_CREATE_POLICY_SAVE_AND_CLOSE' | translate"
        [isLoading]="isSaving"
        [mcDisabled]="
          blockPageSettingsForm.controls['customTitle'].invalid ||
          blockPageSettingsForm.controls['customBody'].invalid
        "
        *ngIf="hasEditPermission"
        (mcClick)="save()"
      >
      </mc-live-button>
      <a
        class="btn btn-secondary panel-half-margin-right"
        (click)="cancel()"
        translate="$I18N_COMMON_BTN_CANCEL"
      ></a>
    </div>
  </mc-extra-container>
  <mc-body-container>
    <div class="row bordered mc-block-page-container">
      <div class="col-xs-3">
        <form
          class="mc-block-page-settings-form-horizontal"
          mcDefaultLabelClass="panel-padding-bottom"
          mcDefaultControlContainerClass=""
          [formGroup]="blockPageSettingsForm"
        >
          <div class="mc-custom-switch">
            <div class="heading-l panel-half-padding-bottom">
              {{ '$I18N_SWG_BLOCK_PAGE_BLOCKED_SITE_CUSTOMIZE' | translate }}
            </div>
            <mc-enable-switch formControlName="customEnabled"></mc-enable-switch>
          </div>
          <div class="mc-custom-switch" *ngIf="hasAWBlockPageSwitchEnabled && blockPageSettingsForm.get('customEnabled').value">
            <div class="heading-l panel-half-padding-bottom">
              {{ '$I18N_SWG_AW_CUSTOM_PAGE_WEB_SECURITY_TIPS' | translate }}
            </div>
            <mc-enable-switch formControlName="customAwarenessTipsEnabled"></mc-enable-switch>
          </div>
          <div *ngIf="!hasAWBlockPageSwitchEnabled || !blockPageSettingsForm.get('customAwarenessTipsEnabled').value" class="panel-padding-bottom">
            <mc-text-field
              errorPrefix="$I18N_SWG_BLOCK_PAGE_SETTINGS_VALIDATION_TITLE"
              label="{{ '$I18N_SWG_BLOCK_PAGE_BLOCKED_SITE_TITLE_LABEL' | translate }}"
              maxlength="300"
              placeholder="{{ '$I18N_SWG_BLOCK_PAGE_SETTINGS_TITLE_PLACEHOLDER' | translate }}"
              formControlName="customTitle"
            ></mc-text-field>
          </div>

          <div class="panel-padding-bottom">
            <mc-text-area-field
              errorPrefix="$I18N_SWG_BLOCK_PAGE_SETTINGS_VALIDATION_BODY"
              label="{{ '$I18N_SWG_AW_BLOCK_PAGE_BLOCKED_SITE_FOOTER_LABEL' | translate }}"
              maxlength="1000"
              placeholder="{{ '$I18N_SWG_BLOCK_PAGE_SETTINGS_BODY_PLACEHOLDER' | translate }}"
              formControlName="customBody"

            ></mc-text-area-field>
          </div>
        </form>

        <div>{{ '$I18N_SWG_BLOCK_PAGE_SETTINGS_INFO' | translate }}</div>
      </div>

      <div class="col-xs-9">
        <div *ngIf="!hasAWBlockPageSwitchEnabled || !blockPageSettingsForm.get('customAwarenessTipsEnabled').value" class="mc-list-panel">
          <mc-customized-page [updatedSettings]="blockPageSettingsForm.value"></mc-customized-page>
        </div>
        <div *ngIf="hasAWBlockPageSwitchEnabled && blockPageSettingsForm.get('customAwarenessTipsEnabled').value" class="mc-list-panel">
          <mc-aw-customized-page [updatedSettings]="blockPageSettingsForm.value"></mc-aw-customized-page>
        </div>
      </div>
    </div>
  </mc-body-container>
</mc-layout-dashboard-simple>
