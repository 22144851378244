"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListComponent = void 0;
const components_1 = require("@mimecast-ui/components");
const table_store_1 = require("@mimecast-ui/table-store");
const components_2 = require("@mimecast-ui/components");
const status_factory_service_1 = require("app-new/sync-recover/services/status.factory.service");
const fromDrive = require("../reducers");
const list_actions_1 = require("./actions/list.actions");
const onedrive_list_impl_service_1 = require("./services/onedrive-list.impl.service");
class ListComponent extends table_store_1.TableBaseComponent {
    constructor(store, capabilitiesManager, tabService) {
        super(store, onedrive_list_impl_service_1.OnedriveListImpl.ONE_DRIVE_DRIVE_LIST_STORE_ID);
        this.store = store;
        this.capabilitiesManager = capabilitiesManager;
        this.tabService = tabService;
        this.title = '$I18N_SYNC_RECOVER_ONE_DRIVE_LIST_TITLE';
        this.description = '$I18N_SYNC_RECOVER_ONE_DRIVE_LIST_DESCRIPTION';
        this.columnList = [
            'displayName',
            'mailbox',
            'storageUsed',
            'firstStarted',
            'lastActive',
            'lastStatus',
            'syncedBy'
        ];
        this.sections = [
            {
                name: 'status',
                filters: ['error', 'in_progress', 'pending', 'success', 'warning']
            }
        ];
        this.sizePosition = components_2.TextAlign.LEFT;
        this.storageUsedUnit = components_1.UnitEnum.KB;
        const tab = this.tabService.getSelectedTab();
        tab.onRefresh = () => {
            this.dispatchFilterAction();
            this.store.dispatch(new list_actions_1.LoadAction());
        };
        this.capabilitiesManager
            .hasOnedriveReadPermission()
            .subscribe((hasOnedriveReadPermission) => {
            this.hasOnedriveReadPermission = hasOnedriveReadPermission;
        });
        this.totalUsage$ = this.store.select(fromDrive.getTotalUsage);
        this.totalAllowance$ = this.store.select(fromDrive.getTotalAllowance);
        this.isUsageLoading$ = this.store.select(fromDrive.getIsUsageLoading);
    }
    ngOnInit() {
        super.ngOnInit();
        this.store.dispatch(new list_actions_1.LoadAction());
    }
    openItem(row) {
        if (this.hasOnedriveReadPermission) {
            super.openItem(row);
        }
    }
    getStatusByRow(row) {
        return status_factory_service_1.StatusFactory[row.lastStatus]();
    }
}
exports.ListComponent = ListComponent;
