"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDetailsPaginationInfo = exports.getQueueDetails = exports.getFailure = exports.getLoading = exports.reducer = void 0;
const queue_details_actions_1 = require("./../actions/queue.details.actions");
const initialState = {
    loading: false
};
function reducer(state = initialState, action) {
    if (action === undefined || action === null) {
        return state;
    }
    switch (action.type) {
        case queue_details_actions_1.QueueDetailsActions.GET_QUEUE_DETAILS:
            return Object.assign(Object.assign({}, state), { loading: true });
        case queue_details_actions_1.QueueDetailsActions.GET_QUEUE_DETAILS_FAIL:
            return Object.assign(Object.assign({}, state), { loading: false, failure: action.failure ? action.failure : undefined });
        case queue_details_actions_1.QueueDetailsActions.GET_QUEUE_DETAILS_SUCCESS:
            return Object.assign(Object.assign({}, state), { loading: false, queueDetails: action.queueDetails ? action.queueDetails : undefined });
        case queue_details_actions_1.QueueDetailsActions.SET_QUEUE_DETAILS_PAGINATION_INFO:
            return Object.assign(Object.assign({}, state), { queueDetailsPaginationInfo: {
                    previousEntity: action.previousQueue ? action.previousQueue : undefined,
                    currentEntity: action.currentQueue ? action.currentQueue : undefined,
                    nextEntity: action.nextQueue ? action.nextQueue : undefined,
                    currentEntityIndex: action.currentQueueIndex,
                    totalNumberEntities: action.totalNumberQueues
                } });
        case queue_details_actions_1.QueueDetailsActions.CLEAN_QUEUE_DETAILS:
            return Object.assign(Object.assign({}, state), { queueDetails: undefined, queueDetailsPaginationInfo: undefined });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getLoading = (state) => state && state.loading;
exports.getLoading = getLoading;
const getFailure = (state) => state && state.failure;
exports.getFailure = getFailure;
const getQueueDetails = (state) => state && state.queueDetails;
exports.getQueueDetails = getQueueDetails;
const getDetailsPaginationInfo = (state) => state && state.queueDetailsPaginationInfo;
exports.getDetailsPaginationInfo = getDetailsPaginationInfo;
