"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./page-wizard-step-directive"); //layout.page-wizard-step.directive
// html and css 
require("./page-wizard-step.less");
require("./page-wizard-step.tpl.html");
(function () {
    'use strict';
    angular.module('layout.page-wizard-step', ['layout.page-wizard-step.directive']);
}());
