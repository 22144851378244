"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MepPolicyDetailsStepComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const operators_1 = require("rxjs/operators");
const mep_policy_validator_1 = require("../../../../validators/mep-policy.validator");
const models_1 = require("../../../../models/models");
const policy_actions_1 = require("../../../../actions/policy.actions");
class MepPolicyDetailsStepComponent {
    constructor(fb, store, translate$) {
        this.fb = fb;
        this.store = store;
        this.translate$ = translate$;
        this.isFormValid = new core_1.EventEmitter();
        this.isHoldActionEnabled = false;
        this.minNameLength = 3;
        this.minDescLength = 3;
        this.actions = ['hold', 'no_action'];
        this.levels = ['low', 'high'];
        this.autoReleaseOptions = [
            {
                value: models_1.MepPolicyAutoReleaseTimeout.FIFTEEN_MINUTES,
                label: this.translate$.instant('$I18N_MEP_POLICY_DETAILS_STEP.AUTO_RELEASE.VALUES.15_MIN')
            },
            {
                value: models_1.MepPolicyAutoReleaseTimeout.THIRTY_MINUTES,
                label: this.translate$.instant('$I18N_MEP_POLICY_DETAILS_STEP.AUTO_RELEASE.VALUES.30_MIN')
            },
            {
                value: models_1.MepPolicyAutoReleaseTimeout.ONE_HOUR,
                label: this.translate$.instant('$I18N_MEP_POLICY_DETAILS_STEP.AUTO_RELEASE.VALUES.60_MIN')
            },
            {
                value: models_1.MepPolicyAutoReleaseTimeout.TWO_HOUR,
                label: this.translate$.instant('$I18N_MEP_POLICY_DETAILS_STEP.AUTO_RELEASE.VALUES.120_MIN')
            }
        ];
    }
    ngOnInit() {
        if (![15, 30, 60, 120].includes(this.policy.autoReleaseTimeoutMinutes)) {
            this.autoReleaseOptions.unshift({
                value: this.policy.autoReleaseTimeoutMinutes,
                label: '-'
            });
        }
        this.mepPolicyDetails = this.fb.group({
            name: [
                this.policy.name,
                [
                    forms_1.Validators.required,
                    mep_policy_validator_1.mepPolicyNameValidator({
                        min: 3,
                        max: 60
                    })
                ]
            ],
            description: [
                this.policy.description,
                mep_policy_validator_1.mepPolicyDescriptionValidator({
                    min: 3,
                    max: 1024
                })
            ],
            action: [this.policy.action, forms_1.Validators.required],
            autoReleaseTimeoutMinutes: [this.policy.autoReleaseTimeoutMinutes],
            matchLevel: [this.policy.matchLevel, forms_1.Validators.required]
        });
        // Using debounce time for checking form validity doesn't work well with automation tests.
        this.mepPolicyDetails.valueChanges.subscribe(() => {
            this.isFormValid.emit(this.checkFormValidity());
        });
        this.mepPolicyDetails.valueChanges
            .pipe(operators_1.debounceTime(200))
            .subscribe((formValues) => {
            this.updateStore(formValues);
        });
    }
    updateStore(formValues) {
        this.store.dispatch(new policy_actions_1.MepPolicyDetailsUpdate({
            name: formValues['name'],
            description: formValues['description'] && formValues['description'].trim(),
            action: formValues['action'],
            autoReleaseTimeoutMinutes: formValues['autoReleaseTimeoutMinutes'],
            matchLevel: formValues['matchLevel'],
            policyType: models_1.MepPolicyType.MISDIRECTED_EMAIL_POLICY
        }));
    }
    isDefaultPolicy() {
        return false;
    }
    checkFormValidity() {
        return this.mepPolicyDetails.valid;
    }
    isNoActionSelected() {
        return this.mepPolicyDetails.value['action'] === 'no_action';
    }
}
exports.MepPolicyDetailsStepComponent = MepPolicyDetailsStepComponent;
