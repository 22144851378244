<mc-table
  tableId="message-center/message-accepted-email/main-table"
  [isLoading]="isLoading"
  [highlightedRow]="selectedRow"
  [columns]="columns"
  [data]="rows"
  (rowClick)="rowClick.emit($event)"
  translatePrefix="$I18N_MESSAGE_CENTER_ACCEPTED_EMAIL_MAIN_TABLE.TABLE"
>
  <button
    class="mc-table-actions btn btn-secondary mc-button-margin-right"
    (click)="exportEmails()"
  >
    {{ '$I18N_MESSAGE_CENTER_ACCEPTED_EMAIL_MAIN_TABLE.TABLE.EXPORT_BUTTON' | translate }}
  </button>

  <button
    class="mc-table-actions btn btn-secondary"
    (click)="forwardEmails()"
    [disabled]="!canForwardEmails()"
    *mcCapabilities="'Permission.ACCEPTED_EMAIL_CONTENT_VIEW'"
  >
    {{ '$I18N_MESSAGE_CENTER_ACCEPTED_EMAIL_MAIN_TABLE.TABLE.FORWARD_BUTTON' | translate }}
  </button>

  <mc-filters
    [externalTableSearchCount]="externalTableSearchCount"
    [metadata]="metadata"
    [showPagination]="true"
    (paginatorChange)="onPaginationChange($event)"
  >
    <mc-filter-column-select-and-search
      [sections]="searchSections"
      [placeholder]="
        '$I18N_MESSAGE_CENTER_ACCEPTED_EMAIL_MAIN_TABLE.TABLE.COLUMN_VALUE_SINGLE_SELECT_FILTER_FIELD.PLACEHOLDER'
          | translate
      "
      (filtersChange)="onSearchFilterChange($event)"
    >
    </mc-filter-column-select-and-search>
    <div class="mc-accepted-email-filters-right">
      <mc-filters-bundle-date-range
        [config]="dateRangePickerConfig"
        (filtersChange)="onDateFilterChange($event)"
      >
      </mc-filters-bundle-date-range>
      <mc-filters-bundle-column-values
        [sections]="filterSections"
        (filtersChange)="onColumnFilterChanged($event)"
      >
      </mc-filters-bundle-column-values>
    </div>
  </mc-filters>

  <mc-empty-results
    keyTitle="$I18N_MESSAGE_CENTER_ACCEPTED_EMAIL_MAIN_TABLE.TABLE.EMPTY_RESULTS_TITLE"
    iconClass="mc-icon-adcon-icon-stable-no-results-2"
  >
  </mc-empty-results>

  <mc-column-select key="select" (selectChange)="rowSelected($event)"> </mc-column-select>

  <mc-column-email key="fromEnv" emailKey="emailAddress"></mc-column-email>
  <mc-column-email key="fromHdr" emailKey="emailAddress"></mc-column-email>
  <mc-column-email key="to" emailKey="emailAddress"></mc-column-email>
  <mc-column-date key="sent"></mc-column-date>

  <mc-column key="messageInfo">
    <mc-body-cell *mcBodyCellDef="let row">
      <mc-table-row-email-status [status]="row.messageInfo"></mc-table-row-email-status>
    </mc-body-cell>
  </mc-column>

  <mc-column key="spamScore">
      <mc-body-cell *mcBodyCellDef="let row">{{row.spamScore >=0 ?  row.spamScore : '-' }}</mc-body-cell>
  </mc-column>

  <mc-column key="detectionLevel">
    <mc-body-cell *mcBodyCellDef="let row">{{row.detectionLevel  || '-' }}</mc-body-cell>
  </mc-column>

  <mc-column key="attachments">
    <mc-header-cell *mcHeaderCellDef>
      <i class="far fa-paperclip" aria-hidden="true"></i>
    </mc-header-cell>
    <mc-body-cell *mcBodyCellDef="let row">
      <i *ngIf="row.attachments" class="far fa-paperclip" aria-hidden="true"></i>
    </mc-body-cell>
  </mc-column>

  <mc-column-size key="size"></mc-column-size>

  <mc-column-actions
    key="right-column"
    mcShowColumnConfig
    [columnsAlwaysVisible]="columnsAlwaysVisible"
    [columnsToStartHidden]="columnsToStartHidden"
    [columnsToIgnore]="columnsToIgnore"
  >
    <mc-row-actions *mcRowActions="let row">
      <li
        mcRowAction="$I18N_MESSAGE_CENTER_ACCEPTED_EMAIL_MAIN_TABLE.MEATBALLS.VIEW_DETAILS"
        (click)="rowClick.emit(row)"
      ></li>
      <ng-container *ngIf="canSubmitReport">
        <li mcRowActionSeparator></li>
        <li
          mcRowActionHeader="$I18N_MESSAGE_CENTER_ACCEPTED_EMAIL_MAIN_TABLE.MEATBALLS.REPORT_AS"
        ></li>
        <li
          mcRowAction="$I18N_MESSAGE_CENTER_ACCEPTED_EMAIL_MAIN_TABLE.MEATBALLS.SPAM"
          (click)="reportEmail({ reportType: 'spam', id: row.id })"
        ></li>
        <li
          mcRowAction="$I18N_MESSAGE_CENTER_ACCEPTED_EMAIL_MAIN_TABLE.MEATBALLS.MALWARE"
          (click)="reportEmail({ reportType: 'malware', id: row.id })"
        ></li>
        <li
          mcRowAction="$I18N_MESSAGE_CENTER_ACCEPTED_EMAIL_MAIN_TABLE.MEATBALLS.PHISHING"
          (click)="reportEmail({ reportType: 'phishing', id: row.id })"
        ></li>
      </ng-container>
    </mc-row-actions>
  </mc-column-actions>
</mc-table>
