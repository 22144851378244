"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tableColumnsAlwaysVisible = exports.tableColumns = exports.tableSelectColumn = void 0;
exports.tableSelectColumn = 'select';
exports.tableColumns = [
    'name',
    'description',
    'action',
    'notify',
    'configurationId',
    'lastUpdated',
    'status',
    'right-column'
];
exports.tableColumnsAlwaysVisible = ['name'];
