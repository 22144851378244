"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResetCountAction = exports.ErrorAction = exports.BackPageAction = exports.NewPageAction = exports.ShowLegacyAdconFailModalAction = exports.LoadTabDetailsAction = exports.LOAD_TAB_DETAILS = exports.SHOW_LEGACY_ADCON_FAIL_MODAL = exports.RESET_COUNT = exports.ERROR = exports.BACK_PAGE = exports.NEW_PAGE = void 0;
exports.NEW_PAGE = '[Legacy Page] New Page';
exports.BACK_PAGE = '[Legacy Page] Back Page';
exports.ERROR = '[Legacy Page] Error';
exports.RESET_COUNT = '[Legacy Page] Reset Count';
exports.SHOW_LEGACY_ADCON_FAIL_MODAL = '[Legacy Page] Show Legacy Adcon Fail Modal';
exports.LOAD_TAB_DETAILS = '[Legacy Page] Load Tab Details';
class LoadTabDetailsAction {
    constructor(tabId) {
        this.tabId = tabId;
        this.type = exports.LOAD_TAB_DETAILS;
    }
}
exports.LoadTabDetailsAction = LoadTabDetailsAction;
class ShowLegacyAdconFailModalAction {
    constructor() {
        this.type = exports.SHOW_LEGACY_ADCON_FAIL_MODAL;
    }
}
exports.ShowLegacyAdconFailModalAction = ShowLegacyAdconFailModalAction;
class NewPageAction {
    constructor(tabId) {
        this.tabId = tabId;
        this.type = exports.NEW_PAGE;
    }
}
exports.NewPageAction = NewPageAction;
class BackPageAction {
    constructor(tabId, currentPage) {
        this.tabId = tabId;
        this.currentPage = currentPage;
        this.type = exports.BACK_PAGE;
    }
}
exports.BackPageAction = BackPageAction;
class ErrorAction {
    constructor() {
        this.type = exports.ERROR;
    }
}
exports.ErrorAction = ErrorAction;
class ResetCountAction {
    constructor(tabId) {
        this.tabId = tabId;
        this.type = exports.RESET_COUNT;
    }
}
exports.ResetCountAction = ResetCountAction;
