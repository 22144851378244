<mc-table tableId="services/managed-urls" [isLoading]="isLoading$ | async" [highlightedRow]="selectedRow"
  data-test="cy-managed-urls-table" [columns]="columns" (rowClick)="clickRow($event)" [data]="tableData$ | async"
  translatePrefix="$I18N_SERVICES_URL_PROTECTION_MANAGED_URLS_TABLE.TABLE">
  <button class="mc-table-actions btn btn-primary mc-button-margin-right" data-test="cy-add-urls-btn"
    data-unit-test="unit-add-url-btn" (click)="addNewManagedUrl()"
    *mcCapabilities="canCreateEditAndDeleteCapExpression">
    {{'$I18N_SERVICES_URL_PROTECTION.ADD_NEW_MANAGED_URL' | translate }}
  </button>
  <mc-filters [externalTableSearchCount]="externalTableSearchCount" [metadata]="metadata" [showPagination]="true"
    (paginatorChange)="changePaginator($event)">
    <mc-filter-column-select-and-search [sections]="searchSections" [placeholder]="
        '$I18N_SERVICES_URL_PROTECTION_MANAGED_URLS_TABLE.TABLE.COLUMN_VALUE_SINGLE_SELECT_FILTER_FIELD.PLACEHOLDER'
          | translate
      " (filtersChange)="changeSearchFilter($event)" [hasAllOption]="hasAllOpt">
    </mc-filter-column-select-and-search>
    <div class="mc-filters-right">
      <mc-filters-bundle-column-values class="managed-urls-filter-container" [sections]="filterSections"
        (filtersChange)="changeColumnFilter($event)">
      </mc-filters-bundle-column-values>
    </div>
  </mc-filters>

  <mc-empty-results keyTitle="$I18N_SERVICES_URL_PROTECTION_MANAGED_URLS_TABLE.TABLE.EMPTY_RESULTS"
    iconClass="mc-icon-adcon-icon-stable-no-results-2">
  </mc-empty-results>

  <mc-column key="url">
    <mc-body-cell *mcBodyCellDef="let row">{{row.scheme}}://{{ row.domain }}<span
        *ngIf="row.path">{{row.path}}</span><span *ngIf="row.port && row.port !== -1">:{{row.port}}</span><span
        *ngIf="row.queryString">?{{row.queryString}}</span>
    </mc-body-cell>
  </mc-column>

  <mc-column key="matchType">
    <mc-body-cell *mcBodyCellDef="let row" class="capitalize">{{ row.matchType }}</mc-body-cell>
  </mc-column>

  <mc-column key="comment">
    <mc-body-cell *mcBodyCellDef="let row">{{ row.comment }}</mc-body-cell>
  </mc-column>

  <mc-column key="overrideType">
    <mc-body-cell *mcBodyCellDef="let row">
      {{row.action | mcOverrideTypeDisplay}}
    </mc-body-cell>
  </mc-column>

  <mc-column key="urlRewriting">
    <mc-body-cell *mcBodyCellDef="let row">
      <i [ngClass]="displayRewritingIcon(row)"></i>
      {{row.disableRewrite | mcDisableRewriteDisplay:row}}
    </mc-body-cell>
  </mc-column>

  <mc-column key="userAwareness">
    <mc-body-cell *mcBodyCellDef="let row">
      <i [ngClass]="displayAwarenessIcon(row)"></i>
      {{row.disableUserAwareness | mcUserAwarenessDisplay:row}}
    </mc-body-cell>
  </mc-column>

  <mc-column-actions key="right-column" mcShowColumnConfig [columnsAlwaysVisible]="columnsAlwaysVisible">
    <mc-row-actions *mcRowActions="let row">
      <ng-container *ngIf="canEdit; else details">
        <li data-test="cy-edit-meatball-btn" mcRowAction="$I18N_SERVICES_URL_PROTECTION_MANAGED_URLS_TABLE.ACTIONS.EDIT"
          data-unit-test="unit-edit-action-btn" (click)="openSideInEditMode(row)"></li>
        <li data-test="cy-delete-meatball-btn"
          mcRowAction="$I18N_SERVICES_URL_PROTECTION_MANAGED_URLS_TABLE.ACTIONS.REMOVE"
          data-unit-test="unit-delete-action-btn" (click)="removeManagedUrl(row)">
        </li>
      </ng-container>
      <ng-template #details>
        <li mcRowAction="$I18N_SERVICES_URL_PROTECTION_MANAGED_URLS_TABLE.ACTIONS.DETAILS"
          data-test="cy-details-meatball-btn" data-unit-test="unit-details-action-btn" (click)="clickRow(row)"></li>
      </ng-template>
    </mc-row-actions>
  </mc-column-actions>
</mc-table>