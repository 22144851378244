"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MigrationFailed = exports.MigrationComplete = exports.GetConnectorMigrationStatusFailure = exports.DisplayToastToMigrateConnectors = exports.GetConnectorMigrationStatusSuccess = exports.GetConnectorMigrationStatus = exports.StartMigrationAction = exports.ConnectorsMigrationActions = void 0;
var ConnectorsMigrationActions;
(function (ConnectorsMigrationActions) {
    ConnectorsMigrationActions["START_MIGRATION"] = "[ConnectorsMigration] Start Migration";
    ConnectorsMigrationActions["GET_CONNECTOR_MIGRATION_STATUS"] = "[ConnectorsMigration] Get Connectors for migration";
    ConnectorsMigrationActions["GET_CONNECTOR_MIGRATION_STATUS_SUCCESS"] = "[ConnectorsMigration] Success: Get Connectors for migration";
    ConnectorsMigrationActions["GET_CONNECTOR_MIGRATION_STATUS_FAILURE"] = "[ConnectorsMigration] Failure: Get Connectors for migration";
    ConnectorsMigrationActions["DISPLAY_TOAST_TO_MIGRATE"] = "[ConnectorsMigration] Display toast to migrate";
    ConnectorsMigrationActions["MIGRATION_COMPLETE"] = "[ConnectorsMigration] Migration Complete";
    ConnectorsMigrationActions["MIGRATION_FAILED"] = "[ConnectorsMigration] Migration Failed";
})(ConnectorsMigrationActions = exports.ConnectorsMigrationActions || (exports.ConnectorsMigrationActions = {}));
class StartMigrationAction {
    constructor(connector, migrationGroup) {
        this.connector = connector;
        this.migrationGroup = migrationGroup;
        this.type = ConnectorsMigrationActions.START_MIGRATION;
    }
}
exports.StartMigrationAction = StartMigrationAction;
class GetConnectorMigrationStatus {
    constructor() {
        this.type = ConnectorsMigrationActions.GET_CONNECTOR_MIGRATION_STATUS;
    }
}
exports.GetConnectorMigrationStatus = GetConnectorMigrationStatus;
class GetConnectorMigrationStatusSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = ConnectorsMigrationActions.GET_CONNECTOR_MIGRATION_STATUS_SUCCESS;
    }
}
exports.GetConnectorMigrationStatusSuccess = GetConnectorMigrationStatusSuccess;
class DisplayToastToMigrateConnectors {
    constructor() {
        this.type = ConnectorsMigrationActions.DISPLAY_TOAST_TO_MIGRATE;
    }
}
exports.DisplayToastToMigrateConnectors = DisplayToastToMigrateConnectors;
class GetConnectorMigrationStatusFailure {
    constructor() {
        this.type = ConnectorsMigrationActions.GET_CONNECTOR_MIGRATION_STATUS_FAILURE;
    }
}
exports.GetConnectorMigrationStatusFailure = GetConnectorMigrationStatusFailure;
class MigrationComplete {
    constructor() {
        this.type = ConnectorsMigrationActions.MIGRATION_COMPLETE;
    }
}
exports.MigrationComplete = MigrationComplete;
class MigrationFailed {
    constructor() {
        this.type = ConnectorsMigrationActions.MIGRATION_FAILED;
    }
}
exports.MigrationFailed = MigrationFailed;
