"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
(function () {
    'use strict';
    angular.module('services.services.backup-aside.controller', []).controller('BackupAsideController', [
        '$scope',
        'backupService',
        function ($scope, backupService) {
            const self = this;
            /* -------------------------------------------------------- *\
             - interface
             \* -------------------------------------------------------- */
            self.asideActions = {
                closeAsidePanel: $scope.$close
            };
            /* -------------------------------------------------------- *\
             - initialisation
             \* -------------------------------------------------------- */
            const items = [];
            items.push({
                label: '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ARCHIVE_TASK_MENU_LABEL',
                goTo: 'archive-task-list',
                cap: backupService.constants.SYNC_PERMISSIONS.DATA_SYNC_AND_RECOVER_READ +
                    ' || ' +
                    backupService.constants.SYNC_PERMISSIONS.DATA_SYNC_READ
            });
            items.push({
                label: '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ARCHIVE_MAILBOXES_MENU_LABEL',
                goTo: 'mailboxes-list',
                separator: true,
                cap: backupService.constants.SYNC_PERMISSIONS.DATA_SYNC_AND_RECOVER_READ +
                    ' || ' +
                    backupService.constants.SYNC_PERMISSIONS.DATA_SYNC_READ
            });
            items.push({
                label: '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_EXPORT_AND_RESTORE_MENU_LABEL',
                goTo: 'backup-exports-and-restore',
                separator: true,
                cap: backupService.constants.SYNC_PERMISSIONS.SYNC_MAILBOX_ARCHIVE_AND_RECOVER +
                    ' && ' +
                    backupService.constants.SYNC_PERMISSIONS.DATA_SYNC_AND_RECOVER_READ
            });
            items.push({
                label: '$I18N_SYNC_RECOVER_EXCHANGE_SIT_LIST_TITLE',
                goTo: 'sync-recover-exchange-sit-list',
                separator: true,
                cap: backupService.constants.SYNC_PERMISSIONS.SYNC_MAILBOX_ARCHIVE_AND_RECOVER +
                    ' && ' +
                    backupService.constants.SYNC_PERMISSIONS.DATA_SYNC_AND_RECOVER_READ
            });
            items.push({
                label: '$I18N_ADMINISTRATION_MENU_BACKUP_SEARCH_LOGS_TITLE',
                goTo: 'sync-recovery-search-logs',
                separator: true,
                cap: 'Permission.DATA_SYNC_READ || Permission.DATA_SYNC_AND_RECOVER_READ'
            });
            items.push({
                label: 'Synced Drives',
                goTo: 'onedrive-list',
                separator: true,
                cap: 'Development.Only && Temporary.Services.OneDrive'
            });
            self.asideItems = items;
        }
    ]);
})();
