"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isEmptyInputValue = exports.getParsedDate = exports.prependZero = void 0;
function prependZero(value) {
    return value <= 9 ? '0' + value : value;
}
exports.prependZero = prependZero;
function getParsedDate(date) {
    const dateSplit = date.split('/');
    const yearTimeSplit = dateSplit[2].split(/\s/);
    const day = parseInt(dateSplit[0], 10);
    const month = parseInt(dateSplit[1], 10) - 1;
    const year = parseInt(yearTimeSplit[0], 10);
    const time = (yearTimeSplit[1] || '').split(':').map((val) => {
        return parseInt(val, 10);
    });
    return new Date(year, month, day, time[0], time[1], time[2]);
}
exports.getParsedDate = getParsedDate;
function isEmptyInputValue(value) {
    // we don't check for string here so it also works with arrays
    return value == null || value.length === 0;
}
exports.isEmptyInputValue = isEmptyInputValue;
