"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgPolicyListImplService = void 0;
const table_store_1 = require("@mimecast-ui/table-store");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const models_1 = require("../models");
const policy_1 = require("../models/policy");
class SwgPolicyListImplService {
    constructor(http) {
        this.http = http;
    }
    getData(payload, pagination = table_store_1.defaultPaginationOption) {
        // @ts-ignore
        return this.http
            .post('/api/policy/swg/get-policies-with-targets', Object.assign(Object.assign({}, payload), { meta: { pagination } }))
            .pipe(operators_1.map((response) => {
            const RETURN_DATA = {
                hasErrors: response.hasErrors,
                data: this.mapApiResponse(response.first.definitions),
                failures: response.fail,
                meta: response.meta
            };
            return RETURN_DATA;
        }), operators_1.catchError((response) => {
            const RETURN_DATA = {
                hasErrors: response.hasErrors,
                data: response.all,
                failures: response.fail,
                meta: response.meta
            };
            return rxjs_1.of(RETURN_DATA);
        }));
    }
    filter(query, pagination) {
        const payload = {
            searchField: 'description',
            query: query && query.policyName ? query.policyName : null
        };
        return this.getData(payload, pagination);
    }
    openItem() {
        return rxjs_1.of([]);
    }
    mapApiResponse(data) {
        return data.map((policyPayload) => {
            // @ts-ignore
            const swgPolicy = {
                id: policyPayload.policyType + '---' + policyPayload.id,
                policyDesc: {
                    id: policyPayload.id,
                    description: policyPayload.description,
                    policyType: policyPayload.policyType
                },
                affectedUsers: []
            };
            let policies;
            if (policyPayload.policyType === models_1.SwgPolicyType.CLOUD_PROTECT_POLICY) {
                policies = policyPayload.policyRules.map(rule => rule.policyRule);
                swgPolicy.lastModified = policyPayload.lastUpdate;
                swgPolicy.policyDesc.enabled = true;
                swgPolicy.clpDefaultPolicy = swgPolicy.policyDesc.description.indexOf('(Default)') !== -1;
                swgPolicy.policyDesc.override = false;
            }
            else {
                policies = policyPayload.policies;
                if (policies && policies.length > 0) {
                    swgPolicy.lastModified = policies[0].policy.lastUpdated;
                    swgPolicy.policyDesc.enabled = policies[0].policy.enabled;
                    swgPolicy.policyDesc.override = policies[0].policy.override;
                }
            }
            if (policies && policies.length > 0) {
                const affectedUsers = [];
                const isMulti = policies.length > 1;
                let locationCount = 0;
                let groupCount = 0;
                let userCount = 0;
                let deviceCount = 0;
                let deviceGroupCount = 0;
                for (const policy of policies) {
                    const p = policy.policy ? policy.policy : policy;
                    const policyType = policyPayload.policyType !== models_1.SwgPolicyType.CLOUD_PROTECT_POLICY ? p.from : p.to;
                    if (policy.policy &&
                        policy.policy.conditions &&
                        policy.policy.conditions.sourceIPs &&
                        policy.locations &&
                        policy.locations[0]) {
                        locationCount++;
                        if (!isMulti) {
                            affectedUsers.push({
                                type: policy_1.SwgAffectedUsersEnum.LOCATION,
                                description: policy.locations[0].location,
                                ipRange: policy.locations[0].ip
                            });
                        }
                    }
                    else if (policyType.type === 'individual_email_address') {
                        userCount++;
                        if (!isMulti) {
                            affectedUsers.push({
                                type: policy_1.SwgAffectedUsersEnum.USER,
                                emailAddress: policyType.emailAddress
                            });
                        }
                    }
                    else if (policyType.type === 'profile_group') {
                        groupCount++;
                        if (!isMulti) {
                            affectedUsers.push({
                                type: policy_1.SwgAffectedUsersEnum.GROUP,
                                description: policyType.group.description
                            });
                        }
                    }
                    else if (policyType.type === policy_1.SwgAffectedUsersEnum.WEB_DEVICE) {
                        deviceCount++;
                        if (!isMulti) {
                            affectedUsers.push({
                                type: policy_1.SwgAffectedUsersEnum.WEB_DEVICE,
                                device: policyType.device
                            });
                        }
                    }
                    else if (policyType.type === policy_1.SwgAffectedUsersEnum.WEB_DEVICE_GROUP) {
                        deviceGroupCount++;
                        if (!isMulti) {
                            affectedUsers.push({
                                type: policy_1.SwgAffectedUsersEnum.WEB_DEVICE_GROUP,
                                deviceGroup: policyType.deviceGroup
                            });
                        }
                    }
                    else if (policyType.type === 'everyone') {
                        affectedUsers.push({
                            type: policy_1.SwgAffectedUsersEnum.EVERYONE
                        });
                        swgPolicy.everyOne = true;
                    }
                }
                const messageArray = [];
                if (isMulti && locationCount > 0) {
                    messageArray.push('Locations (' + locationCount + ')');
                }
                if (isMulti && groupCount > 0) {
                    messageArray.push('Groups (' + groupCount + ')');
                }
                if (isMulti && userCount > 0) {
                    messageArray.push('Users (' + userCount + ')');
                }
                if (isMulti && deviceCount > 0) {
                    messageArray.push(`Devices (${deviceCount})`);
                }
                if (isMulti && deviceGroupCount > 0) {
                    messageArray.push(`Device Groups (${deviceGroupCount})`);
                }
                if (messageArray.length > 0) {
                    affectedUsers.push({
                        type: policy_1.SwgAffectedUsersEnum.MULTI,
                        description: messageArray.join(', ')
                    });
                }
                swgPolicy.affectedUsers = affectedUsers;
            }
            return swgPolicy;
        });
    }
}
exports.SwgPolicyListImplService = SwgPolicyListImplService;
