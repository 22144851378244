"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueueTypePipe = void 0;
class QueueTypePipe {
    constructor(translateService) {
        this.translateService = translateService;
    }
    transform(value) {
        if (value) {
            return this.translateService.instant('$I18N_ARCHIVE_SUPERVISION_PIPES.QUEUE_TYPE.' + value.toUpperCase());
        }
        return this.translateService.instant('$I18N_ARCHIVE_SUPERVISION_PIPES.QUEUE_TYPE.UNKNOWN');
    }
}
exports.QueueTypePipe = QueueTypePipe;
