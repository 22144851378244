"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.getActivityChartBlockedRequests = exports.getActivityChartAllowedRequests = exports.getTopBlockedCategoriesChartTotalRequests = exports.getTopBlockedCategoriesChartLoading = exports.getTopBlockedCategoriesChartData = exports.getTopBlockedDomainsChartTotalRequests = exports.getTopBlockedDomainsChartLoading = exports.getTopBlockedDomainsChartData = exports.getTopCategoriesChartTotalRequests = exports.getTopCategoriesChartLoading = exports.getTopCategoriesChartData = exports.getTopDomainsChartTotalRequests = exports.getTopDomainsChartLoading = exports.getTopDomainsChartData = exports.getActivityChartTotalRequests = exports.getActivityChartLoading = exports.getActivityChartData = exports.reducers = void 0;
const store_1 = require("@ngrx/store");
const dashboardReducer = require("./swg-dashboard.reducer");
exports.reducers = {
    swgDashboardV2: dashboardReducer.reducer
};
const getDashboardState = store_1.createFeatureSelector('swgNewDashboard');
const ɵ0 = state => state.swgDashboardV2;
exports.ɵ0 = ɵ0;
const getActivityChartState = store_1.createSelector(getDashboardState, ɵ0);
exports.getActivityChartData = store_1.createSelector(getActivityChartState, dashboardReducer.getActivityChartData);
exports.getActivityChartLoading = store_1.createSelector(getActivityChartState, dashboardReducer.isActivityChartLoading);
exports.getActivityChartTotalRequests = store_1.createSelector(getActivityChartState, dashboardReducer.getActivityChartTotalRequests);
exports.getTopDomainsChartData = store_1.createSelector(getActivityChartState, dashboardReducer.getTopDomainsChartData);
exports.getTopDomainsChartLoading = store_1.createSelector(getActivityChartState, dashboardReducer.isTopDomainsChartLoading);
exports.getTopDomainsChartTotalRequests = store_1.createSelector(getActivityChartState, dashboardReducer.getTopDomainsChartTotalRequests);
exports.getTopCategoriesChartData = store_1.createSelector(getActivityChartState, dashboardReducer.getTopCategoriesChartData);
exports.getTopCategoriesChartLoading = store_1.createSelector(getActivityChartState, dashboardReducer.isTopCategoriesChartLoading);
exports.getTopCategoriesChartTotalRequests = store_1.createSelector(getActivityChartState, dashboardReducer.getTopCategoriesChartTotalRequests);
exports.getTopBlockedDomainsChartData = store_1.createSelector(getActivityChartState, dashboardReducer.getTopBlockedDomainsChartData);
exports.getTopBlockedDomainsChartLoading = store_1.createSelector(getActivityChartState, dashboardReducer.isTopBlockedDomainsChartLoading);
exports.getTopBlockedDomainsChartTotalRequests = store_1.createSelector(getActivityChartState, dashboardReducer.getTopBlockedDomainsChartTotalRequests);
exports.getTopBlockedCategoriesChartData = store_1.createSelector(getActivityChartState, dashboardReducer.getTopBlockedCategoriesChartData);
exports.getTopBlockedCategoriesChartLoading = store_1.createSelector(getActivityChartState, dashboardReducer.isTopBlockedCategoriesChartLoading);
exports.getTopBlockedCategoriesChartTotalRequests = store_1.createSelector(getActivityChartState, dashboardReducer.getTopBlockedCategoriesChartTotalRequests);
exports.getActivityChartAllowedRequests = store_1.createSelector(getActivityChartState, dashboardReducer.getActivityChartAllowedRequests);
exports.getActivityChartBlockedRequests = store_1.createSelector(getActivityChartState, dashboardReducer.getActivityChartBlockedRequests);
