"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMetadataForPaging = exports.getGatewayPagingMetadata = exports.getLegacyPagingMetadata = exports.isApplicationActive = exports.getNewCredentialsDisplayed = exports.getGatewayTotalCount = exports.getLegacyTotalCount = exports.getSelectedRow = exports.getApiGatewayApplications = exports.getLegacyApplications = exports.getApplications = exports.isEmpty = exports.isLoading = exports.reducer = exports.initialState = void 0;
const actions = require("../../actions");
exports.initialState = {
    isLoading: false,
    isEmpty: true,
    applications: [],
    paginatorMetadata: {
        pageToken: undefined,
        pageSize: 50,
        pageNumber: 0
    },
    legacyApplications: [],
    gatewayApplications: []
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.ApiGatewayListActionTypes.GetAllApiApplications:
            return Object.assign(Object.assign({}, state), { isLoading: true, isEmpty: true, applications: [], selectedRow: undefined });
        case actions.ApiGatewayListActionTypes.GetAllApiApplicationsSuccess:
            return Object.assign(Object.assign({}, state), { isLoading: false, isEmpty: !action.payload.length, applications: [...action.payload].sort(compareApps), paginatorMetadata: Object.assign(Object.assign({}, state.paginatorMetadata), { pageNumber: 0, pageToken: undefined }), legacyApplications: [...action.payload]
                    .sort(compareApps)
                    .filter(application => application.usage !== 'gateway'), gatewayApplications: [...action.payload]
                    .sort(compareApps)
                    .filter(application => application.usage === 'gateway') });
        case actions.ApiGatewayListActionTypes.PageApiApplications:
            return Object.assign(Object.assign({}, state), { isLoading: true, paginatorMetadata: Object.assign(Object.assign({}, state.paginatorMetadata), action.payload) });
        case actions.ApiGatewayListActionTypes.PageApiApplicationsCompleted:
            return Object.assign(Object.assign({}, state), { isLoading: false });
        case actions.ApiGatewayListActionTypes.SearchLegacyApiApplications:
            return Object.assign(Object.assign({}, state), { searchByLegacy: action.payload, paginatorMetadata: Object.assign(Object.assign({}, state.paginatorMetadata), { pageNumber: 0, pageToken: undefined }) });
        case actions.ApiGatewayListActionTypes.SearchGatewayApiApplications:
            return Object.assign(Object.assign({}, state), { searchByGateway: action.payload, paginatorMetadata: Object.assign(Object.assign({}, state.paginatorMetadata), { pageNumber: 0, pageToken: undefined }) });
        case actions.ApiGatewayListActionTypes.GetNewCredentials:
            return Object.assign(Object.assign({}, state), { newCredentialsDisplayed: false });
        case actions.ApiGatewayListActionTypes.GetNewCredentialsSuccess:
            return Object.assign(Object.assign({}, state), { newCredentialsDisplayed: true });
        default:
            return state;
    }
}
exports.reducer = reducer;
const isLoading = (state) => state.isLoading;
exports.isLoading = isLoading;
const isEmpty = (state) => state.isEmpty;
exports.isEmpty = isEmpty;
const getApplications = (state, type) => {
    const stateApplications = type === 'legacy'
        ? state.legacyApplications
        : type === 'gateway'
            ? state.gatewayApplications
            : state.applications;
    const searchBy = type === 'legacy' ? state.searchByLegacy : state.searchByGateway;
    return getCurrentPage(searchApplications(stateApplications, searchBy, type), state.paginatorMetadata);
};
exports.getApplications = getApplications;
const getLegacyApplications = (state) => getCurrentPage(searchApplications(state.legacyApplications, state.searchByLegacy, 'legacy'), state.paginatorMetadata);
exports.getLegacyApplications = getLegacyApplications;
const getApiGatewayApplications = (state) => getCurrentPage(searchApplications(state.gatewayApplications, state.searchByGateway, 'gateway'), state.paginatorMetadata);
exports.getApiGatewayApplications = getApiGatewayApplications;
const getSelectedRow = (state) => state.selectedRow;
exports.getSelectedRow = getSelectedRow;
const getLegacyTotalCount = (state) => state.legacyApplications.length;
exports.getLegacyTotalCount = getLegacyTotalCount;
const getGatewayTotalCount = (state) => state.gatewayApplications.length;
exports.getGatewayTotalCount = getGatewayTotalCount;
const getNewCredentialsDisplayed = (state) => state.newCredentialsDisplayed;
exports.getNewCredentialsDisplayed = getNewCredentialsDisplayed;
const isApplicationActive = (id) => (state) => {
    const application = state.applications.find(app => app.id === id);
    return !!application && application.active;
};
exports.isApplicationActive = isApplicationActive;
const getLegacyPagingMetadata = (state) => {
    return exports.getMetadataForPaging(state, 'legacy');
};
exports.getLegacyPagingMetadata = getLegacyPagingMetadata;
const getGatewayPagingMetadata = (state) => {
    return exports.getMetadataForPaging(state, 'gateway');
};
exports.getGatewayPagingMetadata = getGatewayPagingMetadata;
const getMetadataForPaging = (state, type) => {
    const stateApplications = type === 'legacy'
        ? state.legacyApplications
        : type === 'gateway'
            ? state.gatewayApplications
            : state.applications;
    const searchBy = type === 'legacy' ? state.searchByLegacy : state.searchByGateway;
    const applications = searchApplications(stateApplications, searchBy, type);
    const numberOfPages = Math.ceil(applications.length / state.paginatorMetadata.pageSize);
    const currentPageNumber = state.paginatorMetadata.pageNumber;
    const isLastPage = currentPageNumber + 1 >= numberOfPages;
    const metadata = {
        pagination: {
            previous: currentPageNumber > 0 ? 'previous' : undefined,
            next: !isLastPage ? 'next' : undefined,
            pageSize: exports.getApplications(state, type).length,
            totalRows: applications.length
        }
    };
    return metadata;
};
exports.getMetadataForPaging = getMetadataForPaging;
function searchApplications(applications, searchBy, type) {
    if (!!searchBy && !!searchBy.searchQuery) {
        let allFields = ['applicationName', 'appId', 'developerName'];
        if (!!type && type === 'gateway') {
            allFields = allFields.filter(field => field !== 'appId');
        }
        switch (searchBy.selectValue) {
            case 'all':
                return applications.filter(filterOn(allFields, searchBy.searchQuery));
            case 'applicationName':
                return applications.filter(filterOn(['applicationName'], searchBy.searchQuery));
            case 'appId':
                return applications.filter(filterOn(['appId'], searchBy.searchQuery));
            case 'developerName':
                return applications.filter(filterOn(['developerName'], searchBy.searchQuery));
        }
    }
    else {
        return applications;
    }
}
function getCurrentPage(applications, pagination) {
    const first = pagination.pageNumber * pagination.pageSize;
    const last = first + pagination.pageSize;
    return applications.slice(first, last);
}
function filterOn(fields, value) {
    return (app) => {
        return !!fields.find(field => app[field].toLowerCase().indexOf(value.toLowerCase()) > -1);
    };
}
function compareApps(a, b) {
    return a.applicationName > b.applicationName ? 1 : b.applicationName > a.applicationName ? -1 : 0;
}
