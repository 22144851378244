"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgDomainsCsvUploaderComponent = void 0;
const core_1 = require("@angular/core");
class SwgDomainsCsvUploaderComponent {
    constructor(downLoadFileService, swgService) {
        this.downLoadFileService = downLoadFileService;
        this.swgService = swgService;
        this.readFile = new core_1.EventEmitter();
        this.updateDomains = new core_1.EventEmitter();
        this.cancel = new core_1.EventEmitter();
        this.maxRows = 10000;
    }
    ngOnInit() {
        if (this.swgService.domains5KLimit()) {
            this.maxRows = 5000;
        }
    }
    downloadFormatFile() {
        this.downLoadFileService.downLoadContentAsFile('format.csv', 'domain/url,Allow/Block --remove this line');
    }
    uploadFile() {
        if (this.content.length <= this.maxRows - this.existingDomains.length) {
            this.updateDomains.emit(this.content);
        }
    }
    fileChange(event) {
        // @ts-ignore
        const fileList = event.target.files;
        if (fileList.length > 0) {
            this.readFile.emit(fileList[0]);
        }
    }
    onCancel() {
        this.cancel.emit();
    }
}
exports.SwgDomainsCsvUploaderComponent = SwgDomainsCsvUploaderComponent;
