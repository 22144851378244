"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiIntegrationEmailComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const validator_service_1 = require("app-new/components/form-validators/email/validator.service");
require("./email.component.scss");
class ApiIntegrationEmailComponent {
    constructor(formBuilder, asideService) {
        this.formBuilder = formBuilder;
        this.asideService = asideService;
        this.onDeleteEmail = new core_1.EventEmitter();
        this.onVerifyAndAddEmail = new core_1.EventEmitter();
        this.maxNumberOfEmails = 5;
        this.isAddEmailBtnDisabled = false;
    }
    ngOnChanges(changes) {
        /* first check if emails object object is in the changes object because
          onNgChanges triggers often, on every single change made to any part of the component. If triggered
          becase there is a change to ony of those lists, then check the limit */
        if (changes.emails && changes.emails.currentValue) {
            this.isAddEmailBtnDisabled = changes.emails.currentValue.length >= this.maxNumberOfEmails;
        }
    }
    ngOnInit() {
        this.form = this.formBuilder.group({
            email: new forms_1.FormControl('', validator_service_1.EmailValidator.validAndNotRequired)
        });
    }
    addEmail() {
        const email = this.form.value.email.toLocaleString().toLowerCase();
        if (!this.emails || this.emails.indexOf(email) === -1) {
            this.onVerifyAndAddEmail.emit(email);
            this.form.get('email').setValue('');
        }
    }
    deleteEmail(email) {
        this.onDeleteEmail.emit(email);
    }
}
exports.ApiIntegrationEmailComponent = ApiIntegrationEmailComponent;
