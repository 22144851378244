"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DownloadExportApiAction = exports.GetFileIdExportApiFailAction = exports.GetFileIdExportApiAction = exports.ExportApiRequestActionTypes = void 0;
var ExportApiRequestActionTypes;
(function (ExportApiRequestActionTypes) {
    ExportApiRequestActionTypes["GetId"] = "[Components - Export Api] Get Export Id";
    ExportApiRequestActionTypes["GetIdFailure"] = "[Components - Export Api] Get Export Id Failure";
    ExportApiRequestActionTypes["Download"] = "[Components - Export Api] Download the Export";
})(ExportApiRequestActionTypes = exports.ExportApiRequestActionTypes || (exports.ExportApiRequestActionTypes = {}));
class GetFileIdExportApiAction {
    constructor(apiExportUrl, search, columnsToExport, fileFormat) {
        this.apiExportUrl = apiExportUrl;
        this.search = search;
        this.columnsToExport = columnsToExport;
        this.fileFormat = fileFormat;
        this.type = ExportApiRequestActionTypes.GetId;
    }
}
exports.GetFileIdExportApiAction = GetFileIdExportApiAction;
class GetFileIdExportApiFailAction {
    constructor() {
        this.type = ExportApiRequestActionTypes.GetIdFailure;
    }
}
exports.GetFileIdExportApiFailAction = GetFileIdExportApiFailAction;
class DownloadExportApiAction {
    constructor(id) {
        this.id = id;
        this.type = ExportApiRequestActionTypes.Download;
    }
}
exports.DownloadExportApiAction = DownloadExportApiAction;
