"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportEffects = void 0;
const effects_1 = require("@ngrx/effects");
const exportAction = require("../actions/export.action");
const operators_1 = require("rxjs/operators");
const api_1 = require("../../../components/export/api");
class ExportEffects {
    constructor(actions$, rejectedAndDeferredService) {
        this.actions$ = actions$;
        this.rejectedAndDeferredService = rejectedAndDeferredService;
        this.exportRejected$ = this.actions$.pipe(effects_1.ofType(exportAction.EXPORT_REJECTED), operators_1.map((action) => {
            const newPayload = this.rejectedAndDeferredService.transformToServerRequest(action.filter);
            return new api_1.OpenSidePanelExportApiAction('/api/gateway/export-rejections', action.payload, newPayload, '$I18N_REJECTED_DEFERRED');
        }));
        this.exportDeferred$ = this.actions$.pipe(effects_1.ofType(exportAction.EXPORT_DEFERRED), operators_1.map((action) => {
            const newPayload = this.rejectedAndDeferredService.transformToServerRequest(action.filter);
            return new api_1.OpenSidePanelExportApiAction('/api/gateway/export-connection-attempts', action.payload, newPayload, '$I18N_REJECTED_DEFERRED');
        }));
    }
}
__decorate([
    effects_1.Effect()
], ExportEffects.prototype, "exportRejected$", void 0);
__decorate([
    effects_1.Effect()
], ExportEffects.prototype, "exportDeferred$", void 0);
exports.ExportEffects = ExportEffects;
