"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogsHelper = void 0;
class LogsHelper {
    constructor(translate) {
        this.translate = translate;
    }
    getScanResultIconClass(key) {
        const map = {
            clean: 'fas fa-check-circle',
            malicious: 'fas fa-exclamation-circle',
            suspicious: 'fas fa-exclamation-triangle',
            unknown: 'fas fa-check-circle',
            scanner_timeout: 'fas fa-info-circle',
            invalid_url: 'fas fa-info-circle',
            file_detected: 'fas fa-exclamation-triangle'
        };
        return map[key.toLocaleLowerCase()];
    }
    getScanResultLabel(selectedRow) {
        const key = selectedRow.scanResult.toLocaleLowerCase();
        const map = {
            clean: this.translate.instant('$I18N_SERVICES_URL_PROTECTION_LOGS_TABLE.TABLE.SCAN_RESULT.CLEAN'),
            malicious: this.translate.instant('$I18N_SERVICES_URL_PROTECTION_LOGS_TABLE.TABLE.SCAN_RESULT.MALICIUS'),
            suspicious: this.translate.instant('$I18N_SERVICES_URL_PROTECTION_LOGS_TABLE.TABLE.SCAN_RESULT.SUSPICIOUS'),
            unknown: this.translate.instant('$I18N_SERVICES_URL_PROTECTION_LOGS_TABLE.TABLE.SCAN_RESULT.CLEAN'),
            scanner_timeout: this.translate.instant('$I18N_SERVICES_URL_PROTECTION_LOGS_TABLE.TABLE.SCAN_RESULT.SCANNER_TIMEOUT'),
            invalid_url: this.translate.instant('$I18N_SERVICES_URL_PROTECTION_LOGS_TABLE.TABLE.SCAN_RESULT.INVALID_URL'),
            file_detected: this.translate.instant('$I18N_SERVICES_URL_PROTECTION_LOGS_TABLE.TABLE.SCAN_RESULT.FILE_DETECTED')
        };
        return map[key];
    }
    getRouteLabel(route) {
        if (route === 'internal') {
            return 'journal';
        }
        return route;
    }
}
exports.LogsHelper = LogsHelper;
