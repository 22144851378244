"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgEndpointsListDataMappingService = void 0;
class SwgEndpointsListDataMappingService {
    mapDeviceOS(osEdition) {
        const versionNumberRegExp = /[\d|.+]+|\w\d/; // Used to get the version number (e.g. 7, 8.1, 10, 2016, 2016R2, 2019).
        if (this.isWindowsServer(osEdition)) {
            const matchedString = osEdition.match(new RegExp(/Server|/.source + versionNumberRegExp.source, 'gi'));
            const numWords = 2; // For most server OSs, there will be two array elements (e.g. 'Server' and '2019').
            const isR2Edition = matchedString.length === numWords + 1; // There will be a third array element ('R2') if the OS is an R2 edition.
            return (matchedString.slice(0, numWords).join(' ') + (isR2Edition ? matchedString[numWords] : ''));
        }
        else {
            const maxWordLength = 3; // For non-server OSs, shorten all words to a maximum of 3 characters.
            return osEdition
                .match(new RegExp(/Windows|Ent|Pro|OEM|Ult|/.source + versionNumberRegExp.source, 'gi'))
                .map(str => {
                return typeof str === 'string' ? str.substring(0, maxWordLength) : str;
            })
                .slice(0, maxWordLength)
                .join(' ');
        }
    }
    isWindowsServer(osEdition) {
        return osEdition.toLowerCase().includes('server');
    }
    hasMultiUsersSupport(osEdition) {
        const matchedOsEdition = osEdition.match(new RegExp(/server|virtual desktops/, 'gi'));
        return matchedOsEdition !== null && matchedOsEdition.length > 0;
    }
}
exports.SwgEndpointsListDataMappingService = SwgEndpointsListDataMappingService;
