"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetWidgetDataFail = exports.GetWidgetDataSuccess = exports.GetWidgetData = exports.GET_WIDGET_DATA_FAIL = exports.GET_WIDGET_DATA_SUCCESS = exports.GET_WIDGET_DATA_ATTEMPT = void 0;
exports.GET_WIDGET_DATA_ATTEMPT = '[Services / Url protection] Get URLs scanned per day data';
exports.GET_WIDGET_DATA_SUCCESS = '[Services / Url protection] Get URLs scanned per day data success';
exports.GET_WIDGET_DATA_FAIL = '[Services / Url protection] Get URLs scanned per day data fail';
class GetWidgetData {
    constructor() {
        this.type = exports.GET_WIDGET_DATA_ATTEMPT;
    }
}
exports.GetWidgetData = GetWidgetData;
class GetWidgetDataSuccess {
    constructor() {
        this.type = exports.GET_WIDGET_DATA_SUCCESS;
    }
}
exports.GetWidgetDataSuccess = GetWidgetDataSuccess;
class GetWidgetDataFail {
    constructor() {
        this.type = exports.GET_WIDGET_DATA_FAIL;
    }
}
exports.GetWidgetDataFail = GetWidgetDataFail;
