"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgEndpointsListSidebarServerUsersComponent = void 0;
const sidebar_server_users_static_values_1 = require("./sidebar-server-users.static-values");
class SwgEndpointsListSidebarServerUsersComponent {
    constructor() {
        this.isTableScrollable = false;
        this.columns = [...sidebar_server_users_static_values_1.columns];
        this.sortableColumns = [...sidebar_server_users_static_values_1.sortableColumns];
        this.searchFilters = sidebar_server_users_static_values_1.searchFilters;
        this.dataInView = [];
        this.searchBy = '';
        this.filterBy = [];
    }
    ngOnChanges() {
        this.resetView();
        if (this.row.userDetails !== undefined && this.row.userDetails.length) {
            this.isTableScrollable = this.row.userDetails.length > 10;
            this.setDataInView();
        }
    }
    resetView() {
        this.dataInView = [];
        this.searchBy = '';
        this.filterBy = [];
        this.isTableScrollable = false;
        if (this.tableFilterComponent.form) {
            this.tableFilterComponent.setFilterValue(sidebar_server_users_static_values_1.searchFilters);
        }
        if (this.tableSearchFilterComponent.searchForm) {
            this.tableSearchFilterComponent.onClearClicked();
        }
    }
    setDataInView() {
        if (this.row.userDetails !== undefined && this.row.userDetails.length) {
            this.dataInView = this.row.userDetails.filter(x => this.applyFilter(x));
        }
    }
    onSort(sortData) {
        this.dataInView.sort((user1, user2) => {
            const status = user1[sortData.columnName] > user2[sortData.columnName];
            return sortData.sortDirection === 'asc' ? (status ? 1 : -1) : status ? -1 : 1;
        });
    }
    onSearch(filters) {
        this.searchBy = filters.search;
        this.setDataInView();
    }
    onSearchClear() {
        this.searchBy = '';
        this.setDataInView();
    }
    onBundleFilterChange(filters) {
        if (Object.keys(filters).length) {
            this.filterBy = filters.filterBy;
        }
        else {
            this.filterBy = [];
        }
        this.setDataInView();
    }
    applyFilter(serverUser) {
        let searchByPass = true;
        let filterByPass = true;
        if (this.searchBy) {
            searchByPass = serverUser.username.indexOf(this.searchBy.toLowerCase()) > -1;
        }
        if (searchByPass && this.filterBy.length > 0) {
            filterByPass = this.filterBy.indexOf(serverUser.status) > -1;
        }
        return searchByPass && filterByPass;
    }
    resetSearchFilters() {
        this.searchBy = '';
        this.filterBy = [];
    }
}
exports.SwgEndpointsListSidebarServerUsersComponent = SwgEndpointsListSidebarServerUsersComponent;
