"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgBlockPageService = void 0;
class SwgBlockPageService {
    constructor(swgPolicyApiService) {
        this.swgPolicyApiService = swgPolicyApiService;
    }
    saveBlockPageSettings(settings) {
        return this.swgPolicyApiService.saveBlockPageSettings(settings);
    }
    getBlockPageSettings() {
        return this.swgPolicyApiService.getBlockPageSettings();
    }
}
exports.SwgBlockPageService = SwgBlockPageService;
