"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DirectorySyncMseSettingsStepComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const validators_1 = require("../../../../../../api-applications/validators");
const directory_sync_integration_interface_1 = require("../../../../../models/directory-sync-integration.interface");
const common_1 = require("../../../../../common/common");
const operators_1 = require("rxjs/operators");
class DirectorySyncMseSettingsStepComponent {
    constructor(fb, translateService) {
        this.fb = fb;
        this.translateService = translateService;
        this.delay = 200;
        this.onIntegrationDetailsUpdated = new core_1.EventEmitter();
        this.mseSites = [];
        this.rootDNRegexValidator = forms_1.Validators.pattern(common_1.ROOT_DN_REGEX);
    }
    ngOnInit() {
        const replicateDiffDomain = (this.diffDomain = this.getReplicateDiffDomain());
        this.form = this.fb.group({
            mseSiteId: [this.integration.mseSiteId || '', [forms_1.Validators.required]],
            replicateDiffDomain,
            host: [this.integration.host || '', common_1.getRequiredNotEmptyValidators(replicateDiffDomain)],
            userDn: [this.integration.userDn || '', this.getUserValidators(replicateDiffDomain)],
            password: [
                this.isEditMode && !this.integration.hasServerTypeUpdatedOnEdit ? directory_sync_integration_interface_1.HiddenValues.key : '',
                common_1.getRequiredNotEmptyValidators(replicateDiffDomain)
            ],
            rootDn: [this.integration.rootDn || '', this.getRootDNValidators(replicateDiffDomain)]
        });
        this.update();
        this.formSubscription = this.form.valueChanges
            .pipe(operators_1.debounceTime(this.delay))
            .subscribe(settings => {
            if (this.diffDomain !== settings.replicateDiffDomain) {
                this.diffDomain = settings.replicateDiffDomain;
                this.updateDomain(settings.replicateDiffDomain);
                this.updateValidators(settings.replicateDiffDomain);
            }
            this.update();
        });
    }
    get mseSitesOptions() {
        return [
            {
                value: '',
                label: this.translateService.instant('$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.SELECT_SYNC_ENGINE_SITE')
            },
            ...this.mseSites.map(site => ({ value: site.id, label: site.description }))
        ];
    }
    update() {
        this.onIntegrationDetailsUpdated.emit(this.form.getRawValue());
    }
    updateDomain(replicateDiffDomain) {
        if (!replicateDiffDomain) {
            this.form.patchValue({
                host: '',
                userDn: '',
                password: '',
                rootDn: ''
            });
        }
    }
    updateValidators(replicateDiffDomain) {
        this.form.controls.host.setValidators(common_1.getRequiredNotEmptyValidators(replicateDiffDomain));
        this.form.controls.userDn.setValidators(this.getUserValidators(replicateDiffDomain));
        this.form.controls.password.setValidators(common_1.getRequiredNotEmptyValidators(replicateDiffDomain));
        this.form.controls.rootDn.setValidators(this.getRootDNValidators(replicateDiffDomain));
        // you need to run updateValueAndValidity() for each of the fields that validators where updated
        this.form.controls.host.updateValueAndValidity();
        this.form.controls.userDn.updateValueAndValidity();
        this.form.controls.password.updateValueAndValidity();
        this.form.controls.rootDn.updateValueAndValidity();
    }
    getUserValidators(replicateDiffDomain) {
        return replicateDiffDomain ? [forms_1.Validators.required, validators_1.validateNotEmpty] : [validators_1.validateNotEmpty];
    }
    getRootDNValidators(replicateDiffDomain) {
        return replicateDiffDomain
            ? [forms_1.Validators.required, this.rootDNRegexValidator]
            : [this.rootDNRegexValidator];
    }
    integrationType() {
        return this.translateService.instant('$I18N_DIRECTORY_SYNC_WIZARD.STEPS.DETAILS.TYPE_OPTIONS.AD_PUSH');
    }
    getReplicateDiffDomain() {
        return !!(this.integration.host && this.integration.userDn && this.integration.rootDn);
    }
    isReplicateDiffDomainEnabled() {
        return this.form.value.replicateDiffDomain;
    }
    isValid() {
        return this.form.status === 'VALID';
    }
    ngOnDestroy() {
        this.formSubscription.unsubscribe();
    }
}
exports.DirectorySyncMseSettingsStepComponent = DirectorySyncMseSettingsStepComponent;
