<div class="mc-aside-scrollable" *ngIf="messageDetails">
    <div class="mc-recipients-container">
      <mc-filter-search
        (search)="onSearchRecipient($event)"
        (clear)="clearSearch()"
        placeholder="$I18N_SECURE_MESSAGING_VIEW_DETAILS.MESSAGE_STATUS.SEARCH_PLACEHOLDER"
        *ngIf="recipients.length"
      ></mc-filter-search>
  
      <mc-table
        *ngIf="(recipientsToDisplay | async).length; else noDataNotification"
        [columns]="recipientsColumns"
        [isLoading]="false"
        [data]="recipientsToDisplay | async"
        [showHighlightedRow]="false"        
        translatePrefix="$I18N_SECURE_MESSAGING_VIEW_DETAILS.MESSAGE_STATUS">
        <mc-column key="status">
          <mc-body-cell *mcBodyCellDef="let row"><span
            [class.text-success]="row.status === 'READ'">
            {{ '$I18N_SECURE_MESSAGING_VIEW_DETAILS.MESSAGE_STATUS.' + row.status | uppercase | translate }}
          </span>
          </mc-body-cell>
        </mc-column>
      </mc-table>
    </div>
  </div>
  
  <ng-template #noDataNotification>
    <mc-inline-notification [notification]="{
      msg: '$I18N_SECURE_MESSAGING_VIEW_DETAILS.MESSAGE_STATUS.NO_ENTRIES' | translate,
      status: 'info',
      boxed: true
    }"></mc-inline-notification>
  </ng-template>