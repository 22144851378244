"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IdentitySessionTimeoutStepComponent = void 0;
const forms_1 = require("@angular/forms");
const policy_1 = require("../../../models/policy");
const stepActions = require("../../../actions/policy-wizard/session-timeout-step.actions");
const wizardActions = require("../../../actions/policy-wizard/wizard.actions");
const operators_1 = require("rxjs/operators");
const policy_wizard_1 = require("../../../reducers/policy-wizard");
class IdentitySessionTimeoutStepComponent {
    constructor(formBuilder, store) {
        this.formBuilder = formBuilder;
        this.store = store;
        this.sessionTimeoutOptions = [
            {
                value: policy_1.ExpirationTimeType.MINUTES,
                label: '$I18N_IDENTITY_CREATE_POLICY_SESSION_TIMEOUT_STEP.ELEMENTS.TIMEOUT.SELECT.MINUTES'
            },
            {
                value: policy_1.ExpirationTimeType.HOURS,
                label: '$I18N_IDENTITY_CREATE_POLICY_SESSION_TIMEOUT_STEP.ELEMENTS.TIMEOUT.SELECT.HOURS'
            },
            {
                value: policy_1.ExpirationTimeType.DAYS,
                label: '$I18N_IDENTITY_CREATE_POLICY_SESSION_TIMEOUT_STEP.ELEMENTS.TIMEOUT.SELECT.DAYS'
            }
        ];
    }
    ngOnInit() {
        this.policySessionTimeoutForm = this.formBuilder.group({
            sessionTimeout: [1, [forms_1.Validators.required, forms_1.Validators.min(1)]],
            sessionTimeoutType: [this.sessionTimeoutOptions[2].value, []]
        });
        this.sessionTimeoutSubscription = this.policySessionTimeoutForm.valueChanges.subscribe(value => {
            this.store.dispatch(new stepActions.SaveSessionTimeoutAction({
                timeoutValue: value.sessionTimeout,
                timeoutType: value.sessionTimeoutType
            }));
        });
        this.formStatusSubscription = this.policySessionTimeoutForm.statusChanges.subscribe(() => {
            this.dispatchValid();
        });
        this.store
            .select(policy_wizard_1.getSessionTimeoutStepState)
            .pipe(operators_1.first())
            .subscribe(state => {
            this.policySessionTimeoutForm.patchValue({
                sessionTimeout: state.expirationTime.expirationTimeValue,
                sessionTimeoutType: state.expirationTime.expirationTimeType
            }, { emitEvent: false });
            this.dispatchValid();
        });
    }
    dispatchValid() {
        this.store.dispatch(new wizardActions.UpdateIsValidAction(4, this.policySessionTimeoutForm.valid));
    }
    get timeoutFormControl() {
        return this.policySessionTimeoutForm.get('sessionTimeout');
    }
    ngOnDestroy() {
        this.sessionTimeoutSubscription.unsubscribe();
        this.formStatusSubscription.unsubscribe();
    }
}
exports.IdentitySessionTimeoutStepComponent = IdentitySessionTimeoutStepComponent;
