"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiemChannelEffects = void 0;
const effects_1 = require("@ngrx/effects");
const reducers = require("app-new/api-applications/reducers");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const channelActions = require("../../actions/siem-channels/channel.actions");
const channel_actions_1 = require("../../actions/siem-channels/channel.actions");
const notification_actions_1 = require("../../../components/notification/actions/notification.actions");
class SiemChannelEffects {
    constructor(actions$, store, siemChannelApiService, translationService, siemService, stateService) {
        this.actions$ = actions$;
        this.store = store;
        this.siemChannelApiService = siemChannelApiService;
        this.translationService = translationService;
        this.siemService = siemService;
        this.stateService = stateService;
        this.loadChannelDetails$ = this.actions$.pipe(effects_1.ofType(channel_actions_1.SiemChannelActionsEnums.SIEM_LOAD_CHANNEL_DETAILS), operators_1.withLatestFrom(this.store.select(reducers.getMetadataProducts)), operators_1.switchMap(([action, metadataProducts]) => {
            return this.siemService
                .getChannelDetails(action.payload.type, action.payload.events, metadataProducts)
                .pipe(operators_1.map((data) => {
                return new channelActions.SiemLoadChannelDetailsSuccess(data);
            }), operators_1.catchError(error => rxjs_1.merge(rxjs_1.of(this.getNotificationAction(error)), rxjs_1.of(new channelActions.SiemLoadChannelDetailsFailure()))));
        }));
        this.loadProductsFromMetadata$ = this.actions$.pipe(effects_1.ofType(channel_actions_1.SiemChannelActionsEnums.SIEM_LOAD_PRODUCTS_FROM_METADATA), operators_1.switchMap(() => {
            return this.siemService.getMetadataProducts().pipe(operators_1.map((products) => {
                return new channelActions.SiemLoadProductsFromMetadataSuccess(products);
            }), operators_1.catchError(error => rxjs_1.merge(rxjs_1.of(this.getNotificationAction(error)), rxjs_1.of(new channelActions.SiemLoadProductsFromMetadataFailure()))));
        }));
        this.loadProductsByChannelType$ = this.actions$.pipe(effects_1.ofType(channel_actions_1.SiemChannelActionsEnums.SIEM_LOAD_METADATA_PRODUCTS_BY_CHANNELTYPE), operators_1.withLatestFrom(this.store.select(reducers.getMetadataProducts), this.store.select(reducers.getSiemChannel)), operators_1.switchMap(([, metadataProducts, siemChannel]) => {
            return this.siemService
                .filterProductGroupsByChannelType(siemChannel.type, metadataProducts)
                .pipe(operators_1.map((data) => {
                return new channelActions.SiemLoadMetadataProductsByChannelTypeSuccess(data);
            }), operators_1.catchError(error => rxjs_1.merge(rxjs_1.of(this.getNotificationAction(error)))));
        }));
        this.loadPolicyToEdit$ = this.actions$.pipe(effects_1.ofType(channel_actions_1.SiemChannelActionsEnums.SIEM_LOAD_CHANNEL_TO_EDIT), operators_1.map((action) => action.payload), operators_1.mergeMap(channel => [
            new channelActions.SiemClearChannel(),
            new channelActions.SiemLoadChannelDetails(channel),
            new channelActions.SiemLoadMetadataProductsByChannelType()
        ]));
        this.navigateToWizard = this.actions$.pipe(effects_1.ofType(channel_actions_1.SiemChannelActionsEnums.SIEM_CHANNEL_NAVIGATE_TO_WIZARD), operators_1.tap(() => {
            this.stateService.$state.go('siem-channel-wizard');
        }));
        this.createChannel$ = this.actions$.pipe(effects_1.ofType(channel_actions_1.SiemChannelActionsEnums.SIEM_CREATE_CHANNEL), operators_1.withLatestFrom(this.store.select(reducers.getSiemChannel)), operators_1.switchMap(([, siemChannel]) => {
            return this.siemService.createChannel(siemChannel).pipe(operators_1.mergeMap(() => {
                const msg = siemChannel.id
                    ? '$I18N_SIEM_CHANNEL_WIZARD.CHANNEL_EDIT_SUCCESS'
                    : '$I18N_SIEM_CHANNEL_WIZARD.CHANNEL_CREATE_SUCCESS';
                this.stateService.$state.go('api-applications-list', { displayTab: 'event-channels' });
                return [
                    new notification_actions_1.NotificationShowAction({
                        type: 'success',
                        config: {
                            msg
                        }
                    }),
                    new channelActions.SiemCreateChannelSuccess()
                ];
            }), operators_1.catchError(error => rxjs_1.merge(rxjs_1.of(new notification_actions_1.NotificationShowFailAction(error)), rxjs_1.of(new channelActions.SiemCreateChannelFailure()))));
        }));
    }
    getNotificationAction(error) {
        const msg = error.fail
            ? error.fail[0].errors[0].message
            : this.translationService.instant('$I18N_SIEM_CHANNELS_LIST_TABLE.SERVER_ERROR');
        return new notification_actions_1.NotificationShowAction({
            type: 'error',
            config: {
                msg
            }
        });
    }
}
__decorate([
    effects_1.Effect()
], SiemChannelEffects.prototype, "loadChannelDetails$", void 0);
__decorate([
    effects_1.Effect()
], SiemChannelEffects.prototype, "loadProductsFromMetadata$", void 0);
__decorate([
    effects_1.Effect()
], SiemChannelEffects.prototype, "loadProductsByChannelType$", void 0);
__decorate([
    effects_1.Effect()
], SiemChannelEffects.prototype, "loadPolicyToEdit$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], SiemChannelEffects.prototype, "navigateToWizard", void 0);
__decorate([
    effects_1.Effect()
], SiemChannelEffects.prototype, "createChannel$", void 0);
exports.SiemChannelEffects = SiemChannelEffects;
