"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CapabilitiesModule = void 0;
const capabilities_interfaces_1 = require("./capabilities.interfaces");
const capabilities_service_1 = require("./capabilities.service");
class CapabilitiesModule {
    static forRoot(config) {
        return {
            ngModule: CapabilitiesModule,
            providers: [
                capabilities_service_1.CapabilitiesService,
                {
                    provide: capabilities_interfaces_1.CAPABILITIES,
                    useFactory: config.useFactory,
                    deps: config.deps
                }
            ]
        };
    }
}
exports.CapabilitiesModule = CapabilitiesModule;
