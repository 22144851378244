"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Status = exports.STATUS_TYPES = void 0;
exports.STATUS_TYPES = {
    created: 'created',
    in_progress: 'in_progress',
    completed: 'completed',
    error: 'error',
    success: 'success',
    warning: 'warning',
    pending: 'pending'
};
class Status {
    constructor(key, displayClasses, tooltip, displayValue, icon, statusMessage) {
        this.key = key;
        this.displayClasses = displayClasses;
        this.tooltip = tooltip;
        this.displayValue = displayValue;
        this.icon = icon;
        this.statusMessage = statusMessage;
        this.validateMandatoryFields();
    }
    validateMandatoryFields() {
        if (!this.key) {
            throw new Error('Key is mandatory for status');
        }
        if (!this.displayValue) {
            throw new Error('Display value is mandatory for status');
        }
    }
}
exports.Status = Status;
