<div class="select-notification-group-panel">
    <div class="panel-default">
      <div class="panel-heading header">
        {{'$I18N_API_INTEGRATION_OKTA_GROUP_SELECTOR.TITLE' | translate}}
        <em class="far fa-times pull-right close-panel" (click)="close()"></em>
      </div>

      <div class="panel-body row">
        <div class="col-sm-12"><p>{{'$I18N_API_INTEGRATION_OKTA_GROUP_SELECTOR.DESCRIPTION' | translate }}
          <strong translate="$I18N_API_INTEGRATION_OKTA_GROUP_SELECTOR.CREATE_NEW_FOLDER"></strong>.</p>
        </div>
      </div>

      <div class="panel-body row">
        <div class="col-sm-12">
          <mc-okta-tree-view #oktaTree
            *ngIf="(oktaFolders$ | async)"
            [nodes]="oktaGroups"
            (selection)="onItemSelected($event)"
            (createNewFolder)="onCreateNewFolder($event)"
            (cancelAddingFolder)="onCancelAddNewFolder()"
            (createPlaceholderFolder)="onCreatePlacholderFolder($event)"
            [addButtonLabel]="'$I18N_API_INTEGRATION_OKTA_GROUP_SELECTOR.BUTTONS.CREATE' | translate"
            [cancelButtonLabel]="'$I18N_API_INTEGRATION_OKTA_GROUP_SELECTOR.BUTTONS.CANCEL' | translate"
            [options]="options"
            [loadingMessage]="loadingMessage"
            [showSpinner]="showSpinner"
          >
          </mc-okta-tree-view>
        </div>
      </div>
    </div>
  </div>
  <ng-template #panelSpinner class="parent">
    <mc-spinner
      class="no-content-wrapper spinner-wrapper"
      *ngIf= "!(oktaFolders$ | async);
               else noResultsError"
    ></mc-spinner>
  </ng-template>
  <ng-template #noResultsError class="parent">
    <div class="no-content-wrapper no-results-wrapper">
      <div class="panel-body text-center">{{ '$I18N_API_INTEGRATION_OKTA_GROUP_SELECTOR.NO_RESULTS' | translate}}</div>
    </div>
  </ng-template>
