"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiIntegrationWildfireIndicatorsStepComponent = void 0;
const core_1 = require("@angular/core");
const operators_1 = require("rxjs/operators");
class ApiIntegrationWildfireIndicatorsStepComponent {
    constructor(fb) {
        this.fb = fb;
        this.onSwitchAttachmentIndicator = new core_1.EventEmitter();
        this.onSwitchMaliciousAttachmentIndicator = new core_1.EventEmitter();
        this.form = this.fb.group({
            attachmentEnabled: true,
            maliciousAttachmentEnabled: true
        });
    }
    ngOnInit() {
        if (!!this.indicators) {
            const isMaliciousAttachmenEnabled = this.indicators.MALICIOUS_ATTACHMENT
                ? this.indicators.MALICIOUS_ATTACHMENT.enabled
                : false;
            this.form.patchValue({
                attachmentEnabled: this.indicators.ATTACHMENT.enabled,
                maliciousAttachmentEnabled: isMaliciousAttachmenEnabled
            });
        }
        this.formSubscription = this.form.valueChanges.pipe(operators_1.debounceTime(200)).subscribe(() => {
            this.update();
        });
    }
    update() {
        this.onSwitchAttachmentIndicator.emit(this.form.value.attachmentEnabled);
        this.onSwitchMaliciousAttachmentIndicator.emit(this.form.value.maliciousAttachmentEnabled);
    }
    isValid() {
        return this.form.value.attachmentEnabled || this.form.value.maliciousAttachmentEnabled;
    }
}
exports.ApiIntegrationWildfireIndicatorsStepComponent = ApiIntegrationWildfireIndicatorsStepComponent;
