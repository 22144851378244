"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchEffects = exports.SEARCH_SCHEDULER = exports.SEARCH_DELAY_TIME = void 0;
const core_1 = require("@angular/core");
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const coreReducers = require("app-new/core/reducers");
const commonActions = require("../../../common/actions");
const search = require("../../actions/search.actions");
const reducers = require("../../reducers");
exports.SEARCH_DELAY_TIME = new core_1.InjectionToken('Search Delay Time');
exports.SEARCH_SCHEDULER = new core_1.InjectionToken('Search Scheduler');
class SearchEffects {
    constructor(actions$, trackingSearchService, store$, notificationService, translation, scheduler, delayTime) {
        this.actions$ = actions$;
        this.trackingSearchService = trackingSearchService;
        this.store$ = store$;
        this.notificationService = notificationService;
        this.translation = translation;
        this.scheduler = scheduler;
        this.delayTime = delayTime;
        this.grantSearchPermission$ = this.actions$.pipe(effects_1.ofType(search.REQUEST_SEARCH_PERMISSION, search.REQUEST_SEARCH_FILTERS_PERMISSION, search.REQUEST_SEARCH_REASON_PERMISSION), operators_1.withLatestFrom(this.store$.select(coreReducers.getAccount)), operators_1.map(([, account]) => account), operators_1.withLatestFrom(this.store$.select(reducers.getTrackingSearchPayload)), operators_1.map(([account, currentSearchPayloadState]) => {
            // if the user has provided a search reason = permission granted to search!
            if (!account.searchReason || currentSearchPayloadState.searchReason) {
                return new search.SearchPermissionGrantedAction();
            }
            return new search.SearchPermissionRevokedAction();
        }));
        this.loadMessages$ = this.actions$.pipe(effects_1.ofType(search.SEARCH_PERMISSION_GRANTED), operators_1.withLatestFrom(this.store$.select(reducers.getTrackingSearchPayload)), operators_1.switchMap(([, currentSearchPayloadState]) => {
            return this.trackingSearchService.getMessages(currentSearchPayloadState).pipe(operators_1.map((response) => new search.SearchSuccessAction(response)), operators_1.catchError(() => {
                this.notificationService.error({
                    msg: this.translation.instant('$I18N_TRACK_AND_TRACE_TRACKING_MAIN_TABLE.TABLE.ERROR.RETRIEVE_RESULTS_NOTIFICATION')
                });
                return rxjs_1.of(new search.SearchFailAction());
            }));
        }));
        this.paginationStopLoading$ = this.actions$.pipe(effects_1.ofType(search.SEARCH_PAGINATION), operators_1.delay(this.delayTime || 1000, this.scheduler || rxjs_1.asyncScheduler), operators_1.map(() => new search.SearchPaginationStopLoadingAction()));
        this.selectedRow$ = this.actions$.pipe(effects_1.ofType(search.SEARCH_SELECT_ROW), operators_1.withLatestFrom(this.store$.select(reducers.getSearchList)), operators_1.switchMap(([action, list]) => {
            if (!!action.payload) {
                return rxjs_1.of(new commonActions.ShowMessageInfoAction(action.payload, list, false));
            }
            else {
                return rxjs_1.EMPTY;
            }
        }));
    }
}
__decorate([
    effects_1.Effect()
], SearchEffects.prototype, "grantSearchPermission$", void 0);
__decorate([
    effects_1.Effect()
], SearchEffects.prototype, "loadMessages$", void 0);
__decorate([
    effects_1.Effect()
], SearchEffects.prototype, "paginationStopLoading$", void 0);
__decorate([
    effects_1.Effect()
], SearchEffects.prototype, "selectedRow$", void 0);
exports.SearchEffects = SearchEffects;
