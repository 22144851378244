"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReleaseLogsTableComponent = void 0;
const core_1 = require("@angular/core");
const date_range_config_held_1 = require("./date-range-config-held");
const release_logs_table_static_values_1 = require("./release-logs-table.static-values");
class ReleaseLogsTableComponent {
    constructor(emailRoutePipe, releaseStatusPipe, capabilitiesService, spamDetectionPipe) {
        this.emailRoutePipe = emailRoutePipe;
        this.releaseStatusPipe = releaseStatusPipe;
        this.capabilitiesService = capabilitiesService;
        this.spamDetectionPipe = spamDetectionPipe;
        this.columns = [...release_logs_table_static_values_1.tableColumns];
        this.exports = [...release_logs_table_static_values_1.exportColumns];
        this.filterSections = release_logs_table_static_values_1.tableSectionFilters;
        this.columnsToStartHidden = [...release_logs_table_static_values_1.tableColumnsStartHidden];
        this.columnsAlwaysVisible = [...release_logs_table_static_values_1.tableColumnsAlwaysVisible];
        this.searchSections = release_logs_table_static_values_1.tableSectionSearch;
        this.dateRangePickerConfig = date_range_config_held_1.DateRangeHeldConfig.config;
        this.isContentAdmin = false;
        this.rowData = [];
        this.onSearchFilterChanged = new core_1.EventEmitter();
        this.onDateFilterChanged = new core_1.EventEmitter();
        this.onColumnFilterChanged = new core_1.EventEmitter();
        this.onPaginatorChanged = new core_1.EventEmitter();
        this.onRowClicked = new core_1.EventEmitter();
        this.onReleaseLogsExported = new core_1.EventEmitter();
    }
    set rows(data) {
        this.rowData = (data || []).map(msg => {
            return Object.assign(Object.assign({}, msg), { route: this.emailRoutePipe.transform(msg.route), status: this.releaseStatusPipe.transform(msg.status), detectionLevel: this.spamDetectionPipe.transform(msg.detectionLevel) });
        });
    }
    get rows() {
        return this.rowData;
    }
    ngOnInit() {
        this.capabilitiesService
            .hasCapability('Temporary.MessageCenter.SpamScore')
            .subscribe(isActive => {
            if (isActive === false) {
                const columnsFiltered = this.columns.filter(value => value !== 'spamScore' && value !== 'detectionLevel');
                this.columns = columnsFiltered;
                const exportsFiltered = this.exports.filter(value => value !== 'spamScore' && value !== 'spamDetection');
                this.exports = exportsFiltered;
            }
        });
    }
    clickRow(message) {
        this.onRowClicked.emit(message);
    }
    exportLogs() {
        this.onReleaseLogsExported.emit(this.exports);
    }
    changePaginator(pagination) {
        this.onPaginatorChanged.emit(pagination);
    }
    changeSearchFilter(filter) {
        this.onSearchFilterChanged.emit(this.toSearchBy(filter));
    }
    toSearchBy(filters) {
        if (!!filters.searchQuery) {
            return {
                searchBy: [{ fieldName: filters.selectValue, value: filters.searchQuery }]
            };
        }
        else {
            return { searchBy: [] };
        }
    }
    changeDateFilter(filter) {
        this.onDateFilterChanged.emit(filter);
    }
    changeColumnFilter(filter) {
        this.onColumnFilterChanged.emit(this.toFilterBy(filter));
    }
    toFilterBy(filter) {
        const filterby = [];
        filterby.push(...this.toFilters('status', filter.status));
        // filterby.push(...this.toFilters('heldGroup', filter.heldGroup));
        filterby.push(...this.toFilters('route', filter.route));
        return { filterBy: filterby };
    }
    toFilters(fieldName, filters) {
        if (!!filters && filters.length > 0) {
            return filters.map(value => {
                return { fieldName, value };
            });
        }
        else {
            return [];
        }
    }
}
exports.ReleaseLogsTableComponent = ReleaseLogsTableComponent;
