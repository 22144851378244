"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiemChannelListSidebarClose = exports.SiemChannelListSidebarOpen = exports.SiemChannelListSidebarActionsEnum = void 0;
var SiemChannelListSidebarActionsEnum;
(function (SiemChannelListSidebarActionsEnum) {
    SiemChannelListSidebarActionsEnum["SIEM_CHANNEL_LIST_SIDEBAR_OPEN"] = "[SIEM] SIEM Channel List sidebar open";
    SiemChannelListSidebarActionsEnum["SIEM_CHANNEL_LIST_SIDEBAR_CLOSE"] = "[SIEM] SIEM Channel List sidebar close";
})(SiemChannelListSidebarActionsEnum = exports.SiemChannelListSidebarActionsEnum || (exports.SiemChannelListSidebarActionsEnum = {}));
class SiemChannelListSidebarOpen {
    constructor() {
        this.type = SiemChannelListSidebarActionsEnum.SIEM_CHANNEL_LIST_SIDEBAR_OPEN;
    }
}
exports.SiemChannelListSidebarOpen = SiemChannelListSidebarOpen;
class SiemChannelListSidebarClose {
    constructor() {
        this.type = SiemChannelListSidebarActionsEnum.SIEM_CHANNEL_LIST_SIDEBAR_CLOSE;
    }
}
exports.SiemChannelListSidebarClose = SiemChannelListSidebarClose;
