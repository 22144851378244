"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeliveryLoadFailAction = exports.DeliverySuccessAction = exports.DeliveryLoadAction = exports.EmailLoadFailAction = exports.EmailLoadSuccessAction = exports.EmailLoadAction = exports.DELIVERY_LOAD_FAIL = exports.DELIVERY_SUCCESS = exports.DELIVERY_LOAD = exports.EMAIL_LOAD_FAIL = exports.EMAIL_SUCCESS = exports.EMAIL_LOAD = void 0;
exports.EMAIL_LOAD = '[Archive Viewer Email] Email Load';
exports.EMAIL_SUCCESS = '[Archive Viewer Email] Email Success';
exports.EMAIL_LOAD_FAIL = '[Archive Viewer Email] Email Load Fail';
exports.DELIVERY_LOAD = '[Archive Viewer Email] Delivery Load';
exports.DELIVERY_SUCCESS = '[Archive Viewer Email] Delivery Success';
exports.DELIVERY_LOAD_FAIL = '[Archive Viewer Email] Delivery Load Fail';
class EmailLoadAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.EMAIL_LOAD;
    }
}
exports.EmailLoadAction = EmailLoadAction;
class EmailLoadSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.EMAIL_SUCCESS;
    }
}
exports.EmailLoadSuccessAction = EmailLoadSuccessAction;
class EmailLoadFailAction {
    constructor() {
        this.type = exports.EMAIL_LOAD_FAIL;
    }
}
exports.EmailLoadFailAction = EmailLoadFailAction;
class DeliveryLoadAction {
    constructor(id) {
        this.id = id;
        this.type = exports.DELIVERY_LOAD;
    }
}
exports.DeliveryLoadAction = DeliveryLoadAction;
class DeliverySuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.DELIVERY_SUCCESS;
    }
}
exports.DeliverySuccessAction = DeliverySuccessAction;
class DeliveryLoadFailAction {
    constructor() {
        this.type = exports.DELIVERY_LOAD_FAIL;
    }
}
exports.DeliveryLoadFailAction = DeliveryLoadFailAction;
