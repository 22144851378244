"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListComponent = void 0;
const table_store_1 = require("@mimecast-ui/table-store");
class ListComponent extends table_store_1.TableBaseComponent {
    constructor(store) {
        super(store, 'ExchangeMailboxList');
        this.store = store;
        this.columnList = [
            'displayName',
            'mailbox',
            'syncedBy',
            'firstStarted',
            'lastActive',
            'lastStatus'
        ];
    }
}
exports.ListComponent = ListComponent;
