"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetDeferredBlockForRecipientAction = exports.GetDeferredPermitForRecipientAction = exports.GetRejectedBlockForRecipientAction = exports.GetRejectedPermitForRecipientAction = exports.GetRejectedFailureAction = exports.GetRejectedSuccessAction = exports.GetRejectedAction = exports.GetDeferredAction = exports.GetRejectedActionInfo = exports.GetDeferredActionInfo = exports.BLOCK_FOR_RECIPIENT_DEFERRED = exports.PERMIT_FOR_RECIPIENT_DEFERRED = exports.BLOCK_FOR_RECIPIENT_REJECTED = exports.PERMIT_FOR_RECIPIENT_REJECTED = exports.GET_DEFERRED_INFO = exports.GET_REJECTED_INFO = exports.GET_REJECTED_FAILURE = exports.GET_REJECTED_SUCCESS = exports.GET_REJECTED = exports.GET_DEFERRED = void 0;
exports.GET_DEFERRED = '[Rejected and Deferred] Retrieve deferred messages';
exports.GET_REJECTED = '[Rejected and Deferred] Retrieve rejected messages';
exports.GET_REJECTED_SUCCESS = '[Rejected and Deferred] Retrieve rejected messages success';
exports.GET_REJECTED_FAILURE = '[Rejected and Deferred] Retrieve rejected messages failure';
exports.GET_REJECTED_INFO = '[Rejected and Deferred] Retrieve rejected message info';
exports.GET_DEFERRED_INFO = '[Rejected and Deferred] Retrieve deferred message info';
exports.PERMIT_FOR_RECIPIENT_REJECTED = '[Rejected and Deferred] Permit rejected message for recipient';
exports.BLOCK_FOR_RECIPIENT_REJECTED = '[Rejected and Deferred] Block rejected message for recipient';
exports.PERMIT_FOR_RECIPIENT_DEFERRED = '[Rejected and Deferred] Permit deferred message for recipient';
exports.BLOCK_FOR_RECIPIENT_DEFERRED = '[Rejected and Deferred] Block deferred message for recipient';
class GetDeferredActionInfo {
    constructor(row) {
        this.row = row;
        this.type = exports.GET_DEFERRED_INFO;
    }
}
exports.GetDeferredActionInfo = GetDeferredActionInfo;
class GetRejectedActionInfo {
    constructor(row) {
        this.row = row;
        this.type = exports.GET_REJECTED_INFO;
    }
}
exports.GetRejectedActionInfo = GetRejectedActionInfo;
class GetDeferredAction {
    constructor() {
        this.type = exports.GET_DEFERRED;
    }
}
exports.GetDeferredAction = GetDeferredAction;
class GetRejectedAction {
    constructor() {
        this.type = exports.GET_REJECTED;
    }
}
exports.GetRejectedAction = GetRejectedAction;
class GetRejectedSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_REJECTED_SUCCESS;
    }
}
exports.GetRejectedSuccessAction = GetRejectedSuccessAction;
class GetRejectedFailureAction {
    constructor() {
        this.type = exports.GET_REJECTED_FAILURE;
    }
}
exports.GetRejectedFailureAction = GetRejectedFailureAction;
class GetRejectedPermitForRecipientAction {
    constructor(row) {
        this.row = row;
        this.type = exports.PERMIT_FOR_RECIPIENT_REJECTED;
    }
}
exports.GetRejectedPermitForRecipientAction = GetRejectedPermitForRecipientAction;
class GetRejectedBlockForRecipientAction {
    constructor(row) {
        this.row = row;
        this.type = exports.BLOCK_FOR_RECIPIENT_REJECTED;
    }
}
exports.GetRejectedBlockForRecipientAction = GetRejectedBlockForRecipientAction;
class GetDeferredPermitForRecipientAction {
    constructor(row) {
        this.row = row;
        this.type = exports.PERMIT_FOR_RECIPIENT_DEFERRED;
    }
}
exports.GetDeferredPermitForRecipientAction = GetDeferredPermitForRecipientAction;
class GetDeferredBlockForRecipientAction {
    constructor(row) {
        this.row = row;
        this.type = exports.BLOCK_FOR_RECIPIENT_DEFERRED;
    }
}
exports.GetDeferredBlockForRecipientAction = GetDeferredBlockForRecipientAction;
