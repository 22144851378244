"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SnapShotListImpl = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const table_store_1 = require("@mimecast-ui/table-store");
class SnapShotListImpl {
    constructor(http, injector) {
        this.http = http;
        this.injector = injector;
    }
    get $state() {
        return this.injector.get('$state');
    }
    getData(payload, pagination = table_store_1.defaultPaginationOption) {
        return this.http
            .post('/api/backup/get-snapshot', Object.assign(Object.assign({}, payload), { meta: { pagination } }))
            .pipe(operators_1.map((response) => {
            const RETURN_DATA = {
                hasErrors: response.hasErrors,
                data: response.all,
                failures: response.fail,
                meta: response.meta
            };
            return RETURN_DATA;
        }), operators_1.catchError((response) => {
            const RETURN_DATA = {
                hasErrors: response.hasErrors,
                data: response.all,
                failures: response.fail,
                meta: response.meta
            };
            return rxjs_1.of(RETURN_DATA);
        }));
    }
    filter(query, pagination) {
        const filterPayload = {};
        if (query) {
            if (query.status) {
                filterPayload.status = query.status;
            }
            if (query.searchQuery) {
                filterPayload.query = query.searchQuery;
            }
        }
        return this.getData(filterPayload, pagination);
    }
    openItem(row) {
        return rxjs_1.of(this.$state.go('sync-recover-exchange-snapshot-detail', {
            snapshotId: row.displayId,
            snapshot: row
        }));
    }
    createSnapshot(payload) {
        return this.http.post('/api/backup/create-snapshot', payload);
    }
    loadList() {
        return rxjs_1.of(this.$state.go('sync-recover-exchange-sit-list'));
    }
}
exports.SnapShotListImpl = SnapShotListImpl;
SnapShotListImpl.EXCHANGE_MAILBOX_SIT_LIST_STORE_ID = 'ExchangeSnapshotList';
