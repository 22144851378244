"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpinnerComponent = void 0;
class SpinnerComponent {
    constructor(windowService) {
        this.windowService = windowService;
        this.isOld = windowService.isOld;
    }
}
exports.SpinnerComponent = SpinnerComponent;
