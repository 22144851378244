"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueueConfigBaseBuilder = void 0;
class QueueConfigBaseBuilder {
    withId(id) {
        this.id = id;
        return this.self();
    }
    withName(name) {
        this.name = name;
        return this.self();
    }
    withCreatedBy(createdBy) {
        this.createdBy = createdBy;
        return this.self();
    }
    withUpdatedBy(updatedBy) {
        this.updatedBy = updatedBy;
        return this.self();
    }
    withCreated(created) {
        this.created = created;
        return this.self();
    }
    withUpdated(updated) {
        this.updated = updated;
        return this.self();
    }
    withDescription(description) {
        this.description = description;
        return this.self();
    }
    withType(type) {
        this.type = type;
        return this.self();
    }
    withPrintAllowed(printAllowed) {
        this.printAllowed = printAllowed;
        return this.self();
    }
    withForwardAllowed(forwardAllowed) {
        this.forwardAllowed = forwardAllowed;
        return this.self();
    }
    withStatus(status) {
        this.status = status;
        return this.self();
    }
    withLabels(labels) {
        this.labels = labels;
        return this.self();
    }
    withReviewerCount(reviewerCount) {
        this.reviewerCount = reviewerCount;
        return this.self();
    }
}
exports.QueueConfigBaseBuilder = QueueConfigBaseBuilder;
