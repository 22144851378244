"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DownloadButtonComponent = void 0;
class DownloadButtonComponent {
    constructor() {
        this.label = 'Download';
    }
    triggerDownload() {
        if (!this.downLoadUrl) {
            throw new Error('mc-download-button require file download Url');
        }
        this.downloadLink.nativeElement.click();
    }
}
exports.DownloadButtonComponent = DownloadButtonComponent;
