'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const _ = require("lodash");
const mc_nav_menu_list_1 = require("./mc-nav-menu.list");
/*
  Expose valid menu items based on caps and permission to construct a GEN2 menu i.e., mc-nav-menu;
  TODO: fa icons support, highlight menu items on redirect
*/
angular.module('mcnavmenu.service', [])
    .factory('mcnavmenuService', ['$translate', 'capabilitiesService', function ($translate, capabilitiesService) {
        return {
            getValidMenuItems,
            getMenuHomeLink,
            getMenuHomeTitle,
        };
        function filterMenu(items = []) {
            const validMenuItems = [];
            _.forEach(items, function (item) {
                const validMenuItem = {};
                const children = [];
                if (!!item.children) {
                    _.forEach(item.children, function (child) {
                        if (child.caps && hasCap(child.caps)) {
                            child.label = child.label && $translate.instant(child.label);
                            children.push(child);
                        }
                    });
                    // To keep this menu consistent with the mega menu
                    // first level menu item is not added if it doesn't have atleast one second level menu item
                    if (children.length > 0) {
                        validMenuItem.label = item.label && $translate.instant(item.label);
                        validMenuItem.class = item.class;
                        validMenuItem.children = children;
                        // Setting route same as of its first children
                        validMenuItem.route = children[0].route || '';
                        validMenuItems.push(validMenuItem);
                    }
                }
            });
            return validMenuItems;
        }
        function hasCap(cap) {
            return (capabilitiesService.areCapabilitiesLoaded() && angular.isDefined(cap) && capabilitiesService.evalCapabilitiesExpression(cap));
        }
        function getValidMenuItems() {
            return filterMenu(mc_nav_menu_list_1.menuList);
        }
        function getMenuHomeLink() {
            return '#/administration-dashboard';
        }
        function getMenuHomeTitle() {
            return $translate.instant('$I18N_ADMINISTRATION_MENU_ACCOUNT_ITEM_DASHBOARD');
        }
    }]);
