"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getIsCreatingDomains = exports.getValidDomainsandComment = exports.getInvalidDomains = exports.getValidDomains = exports.getShowDomainValidation = exports.reducer = exports.initialState = void 0;
const actions = require("../../actions/add-domains.actions");
exports.initialState = {
    showDomainValidation: false,
    validDomains: [],
    inValidDomains: [],
    comment: '',
    creatingDomains: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.SHOW_DOMAINS_VALIDATION: {
            return Object.assign(Object.assign({}, state), { showDomainValidation: true, validDomains: action.formValues.validDomains, inValidDomains: action.formValues.inValidDomains, comment: action.formValues.comment });
        }
        case actions.SHOW_DOMAINS_SETTINGS: {
            return Object.assign(Object.assign({}, state), { showDomainValidation: false });
        }
        case actions.REMOVE_INVALID_DOMAIN: {
            const updatedInvalidDomains = [...state.inValidDomains].filter(item => item !== action.domainName);
            return Object.assign(Object.assign({}, state), { inValidDomains: updatedInvalidDomains });
        }
        case actions.REMOVE_VALID_DOMAIN: {
            const updateValidDomains = [...state.validDomains].filter(item => item !== action.domainName);
            return Object.assign(Object.assign({}, state), { validDomains: updateValidDomains });
        }
        case actions.CREATE_DOMAINS: {
            return Object.assign(Object.assign({}, state), { creatingDomains: true });
        }
        case actions.CREATE_DOMAINS_SUCCESS: {
            return Object.assign(Object.assign({}, state), { creatingDomains: false });
        }
        case actions.CREATE_DOMAINS_FAILURE: {
            return Object.assign(Object.assign({}, state), { creatingDomains: false });
        }
        case actions.CLEAR_ADD_DOMAINS_STATE: {
            return Object.assign(Object.assign({}, state), { showDomainValidation: false, validDomains: [], inValidDomains: [] });
        }
        default:
            return state;
    }
}
exports.reducer = reducer;
const getShowDomainValidation = (state) => state.showDomainValidation;
exports.getShowDomainValidation = getShowDomainValidation;
const getValidDomains = (state) => state.validDomains;
exports.getValidDomains = getValidDomains;
const getInvalidDomains = (state) => state.inValidDomains;
exports.getInvalidDomains = getInvalidDomains;
const getValidDomainsandComment = (state) => {
    return { domainName: state.validDomains, comment: state.comment };
};
exports.getValidDomainsandComment = getValidDomainsandComment;
const getIsCreatingDomains = (state) => state.creatingDomains;
exports.getIsCreatingDomains = getIsCreatingDomains;
