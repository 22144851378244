"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UrlProtectionDashboardService = void 0;
const operators_1 = require("rxjs/operators");
const moment = require("moment");
class UrlProtectionDashboardService {
    constructor(http) {
        this.http = http;
    }
    getWidgetScanPerDayData() {
        const payload = {
            countBy: 'day',
            from: moment()
                .subtract(30, 'days')
                .startOf('day')
                .format(),
            sortBy: 'attribute',
            sortOrder: 'desc',
            to: moment()
                .endOf('day')
                .format()
        };
        return this.http
            .post('/api/ttp/url/get-counts', payload)
            .pipe(operators_1.map((response) => response.first));
    }
}
exports.UrlProtectionDashboardService = UrlProtectionDashboardService;
