"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ6 = exports.ɵ5 = exports.ɵ4 = exports.ɵ3 = exports.ɵ2 = exports.ɵ1 = exports.ɵ0 = exports.reducer = exports.initialWizardState = void 0;
const policies_create_actions_1 = require("../actions/policies-create.actions");
const policy_wizard_models_1 = require("../models/policy-wizard.models");
const policies_edit_actions_1 = require("../actions/policies-edit.actions");
const url_protection_policy_details_step_static_values_1 = require("../components/steps/url-protection-policy-details-step/url-protection-policy-details-step.static-values");
const inbound = require("../components/steps/url-protection-inbound-settings-step/url-protection-inbound-settings-step.component.static-values");
const outbound = require("../components/steps/url-protection-outbound-settings-step/url-protection-outbound-settings-step.component.static-values");
const journal = require("../components/steps/url-protection-journal-settings-step/url-protection-journal-settings-step.component.static-values");
const ɵ0 = {
    name: '',
    description: '',
    bypassPolicy: false,
    bypassPolicyApplication: url_protection_policy_details_step_static_values_1.bypassApplicationOptions[0].id,
    policyOverride: false,
    ulrProtectionPolicyDuration: url_protection_policy_details_step_static_values_1.ulrProtectionPolicyDuration.dateRanges[0]
}, ɵ1 = {
    checkInboundEmail: true,
    urlRewriteMode: inbound.rewriteModeValues[1].value,
    urlCategoryScanningMode: inbound.urlCategoryScanningValues[1].value,
    maliciousUrlDetectionAction: inbound.maliciousUrlDetectionActionValues[0].value,
    browserIsolation: true,
    messageSubjectProtection: inbound.messageSubjectProtectionValues[0].value,
    createMissingHtmlBody: true,
    forceSecureConnection: true,
    ignoreSignedMessage: false,
    displayUrlDestinationDomain: true,
    stripExternalSourceMode: false,
    fileProtocolUrlHandling: inbound.fileProtocolUrlHandlingValues[1].value,
    blockUrlsWithDangerousFileExtensions: true,
    rewriteUrlsInAttachments: true,
    attachmentPartHtml: true,
    attachmentPartText: true,
    attachmentPartCalendar: true,
    urlFileDownload: inbound.urlFileDownloadValues[0].value,
    scanUrlInAttachment: true,
    checkForSimilarUrls: true,
    internalDomains: true,
    mimecastMonitoringExternalDomain: true,
    customMonitoringExternalDomain: true,
    stripExternalSource: false,
    similarUrlDetection: inbound.similarUrlDetectionValues[0].value,
    notificationUrlFormat: inbound.notificationUrlFormatValues[0].value,
    administratorsSelectedGroup: undefined
}, ɵ2 = {
    checkOutboundEmail: true,
    urlScanningMode: outbound.urlScanningModeValues[2].value,
    urlCategoryScanningMode: outbound.urlCategoryScanningModeValues[2].value,
    gatewayAction: outbound.gatewayActionValues[1].value,
    gatewayFallbackAction: outbound.gatewayFallbackActionValues[1].value,
    senderMailboxAction: outbound.senderMailboxActionValues[1].value,
    senderMailboxFallbackAction: outbound.senderMailboxFallbackActionValues[1].value,
    blockUrlWithDangerousFileExtensions: true,
    urlFileDownload: outbound.urlFileDownloadValues[0].value,
    scanUrlInAttachments: false,
    attachmentPartHTML: false,
    attachmentPartText: false,
    attachmentPartCalendar: false,
    attachmentPartOther: false,
    checkForSimilarUrls: true,
    domainToCheckAgainstYourInternalDomains: true,
    domainToCheckAgainstMimecastMonitoredExternalDomains: true,
    domainToCheckAgainstCustomMonitoredExternalDomains: true,
    users: false,
    administratorsSelectedGroup: undefined
}, ɵ3 = {
    checkInternalEmail: true,
    urlScanningMode: journal.urlScanningModeValues[2].value,
    urlCategoryScanningMode: journal.urlCategoryScanningModeValues[2].value,
    senderMailboxAction: journal.senderMailboxActionValues[1].value,
    senderMailboxFallbackAction: journal.senderMailboxFallbackActionValues[1].value,
    blockUrlWithDangerousFileExtensions: true,
    urlFileDownload: journal.urlFileDownloadValues[0].value,
    scanUrlInAttachments: false,
    attachmentPartHTML: false,
    attachmentPartText: false,
    attachmentPartCalendar: false,
    attachmentPartOther: false,
    checkForSimilarUrls: false,
    domainToCheckAgainstYourInternalDomains: true,
    domainToCheckAgainstMimecastMonitoredExternalDomains: true,
    domainToCheckAgainstCustomMonitoredExternalDomains: true,
    internalSender: false,
    internalRecipients: false,
    administratorsSelectedGroup: undefined
}, ɵ4 = {
    showUserAwarenessChallenges: true,
    challengePercentage: 5,
    dynamicAdjustment: true,
    customPageSet: false,
    pageSetOption: undefined
}, ɵ5 = undefined, ɵ6 = {
    policyStatusEnabled: true
};
exports.ɵ0 = ɵ0;
exports.ɵ1 = ɵ1;
exports.ɵ2 = ɵ2;
exports.ɵ3 = ɵ3;
exports.ɵ4 = ɵ4;
exports.ɵ5 = ɵ5;
exports.ɵ6 = ɵ6;
exports.initialWizardState = {
    wizardFlowType: policy_wizard_models_1.PolicyWizardFlowType.CREATE,
    isLoading: false,
    detailsStep: {
        data: ɵ0
    },
    inboundStep: {
        data: ɵ1
    },
    outboundStep: {
        data: ɵ2
    },
    journalStep: {
        data: ɵ3
    },
    userAwarenessStep: {
        data: ɵ4,
        stepConfiguration: {
            pageSetOptions: []
        }
    },
    appliesToStep: {
        data: ɵ5,
        stepConfiguration: {
            fromToWhereAttributes: []
        }
    },
    summaryStep: {
        data: ɵ6
    }
};
function reducer(state = exports.initialWizardState, action) {
    switch (action.type) {
        case policies_create_actions_1.BYPASS_URL_PROTECTION_POLICY:
            return Object.assign(Object.assign({}, state), { detailsStep: Object.assign(Object.assign({}, state.detailsStep), { data: Object.assign(Object.assign({}, state.detailsStep.data), { bypassPolicy: action.payload }) }) });
        case policies_create_actions_1.ADD_INBOUND_ADMINISTRATOR_GROUP:
            return Object.assign(Object.assign({}, state), { inboundStep: Object.assign(Object.assign({}, state.inboundStep), { data: Object.assign(Object.assign({}, state.inboundStep.data), { administratorsSelectedGroup: action.payload }) }) });
        case policies_create_actions_1.REMOVE_INBOUND_ADMINISTRATOR_GROUP:
            return Object.assign(Object.assign({}, state), { inboundStep: Object.assign(Object.assign({}, state.inboundStep), { data: Object.assign(Object.assign({}, state.inboundStep.data), { administratorsSelectedGroup: undefined }) }) });
        case policies_create_actions_1.ADD_OUTBOUND_ADMINISTRATOR_GROUP:
            return Object.assign(Object.assign({}, state), { outboundStep: Object.assign(Object.assign({}, state.outboundStep), { data: Object.assign(Object.assign({}, state.outboundStep.data), { administratorsSelectedGroup: action.payload }) }) });
        case policies_create_actions_1.REMOVE_OUTBOUND_ADMINISTRATOR_GROUP:
            return Object.assign(Object.assign({}, state), { outboundStep: Object.assign(Object.assign({}, state.outboundStep), { data: Object.assign(Object.assign({}, state.outboundStep.data), { administratorsSelectedGroup: undefined }) }) });
        case policies_create_actions_1.ADD_JOURNAL_ADMINISTRATOR_GROUP:
            return Object.assign(Object.assign({}, state), { journalStep: Object.assign(Object.assign({}, state.journalStep), { data: Object.assign(Object.assign({}, state.journalStep.data), { administratorsSelectedGroup: action.payload }) }) });
        case policies_create_actions_1.REMOVE_JOURNAL_ADMINISTRATOR_GROUP:
            return Object.assign(Object.assign({}, state), { journalStep: Object.assign(Object.assign({}, state.journalStep), { data: Object.assign(Object.assign({}, state.journalStep.data), { administratorsSelectedGroup: undefined }) }) });
        case policies_create_actions_1.SET_POLICY_STATUS_ENABLED:
            return Object.assign(Object.assign({}, state), { summaryStep: {
                    data: {
                        policyStatusEnabled: action.payload
                    }
                } });
        case policies_create_actions_1.GET_CUSTOM_PAGE_SET_OPTIONS_SUCCESS:
            return Object.assign(Object.assign({}, state), { userAwarenessStep: Object.assign(Object.assign({}, state.userAwarenessStep), { stepConfiguration: {
                        pageSetOptions: action.payload
                    } }) });
        case policies_create_actions_1.GET_CUSTOM_PAGE_SET_OPTIONS_FAIL:
            return Object.assign(Object.assign({}, state), { userAwarenessStep: {
                    data: Object.assign(Object.assign({}, state.userAwarenessStep.data), { customPageSet: false }),
                    stepConfiguration: {
                        pageSetOptions: undefined
                    }
                } });
        case policies_create_actions_1.SAVE_DETAILS_STEP_DATA:
            return Object.assign(Object.assign({}, state), { detailsStep: {
                    data: Object.assign(Object.assign({}, state.detailsStep.data), action.payload)
                } });
        case policies_create_actions_1.SAVE_INBOUND_STEP_DATA:
            return Object.assign(Object.assign({}, state), { inboundStep: {
                    data: Object.assign(Object.assign({}, action.payload), { administratorsSelectedGroup: state.inboundStep.data.administratorsSelectedGroup })
                } });
        case policies_create_actions_1.SAVE_OUTBOUND_STEP_DATA:
            return Object.assign(Object.assign({}, state), { outboundStep: {
                    data: Object.assign(Object.assign({}, action.payload), { administratorsSelectedGroup: state.outboundStep.data.administratorsSelectedGroup })
                } });
        case policies_create_actions_1.SAVE_JOURNAL_STEP_DATA:
            return Object.assign(Object.assign({}, state), { journalStep: {
                    data: Object.assign(Object.assign({}, action.payload), { administratorsSelectedGroup: state.journalStep.data.administratorsSelectedGroup })
                } });
        case policies_create_actions_1.SAVE_APPLIES_TO_STEP_DATA:
            return Object.assign(Object.assign({}, state), { appliesToStep: {
                    data: action.payload,
                    stepConfiguration: Object.assign({}, state.appliesToStep.stepConfiguration)
                } });
        case policies_create_actions_1.SAVE_USER_AWARENESS_STEP_DATA:
            return Object.assign(Object.assign({}, state), { userAwarenessStep: {
                    data: action.payload,
                    stepConfiguration: Object.assign({}, state.userAwarenessStep.stepConfiguration)
                } });
        case policies_create_actions_1.GET_ADDRESS_ATTRIBUTE_OPTIONS_SUCCESS:
            return Object.assign(Object.assign({}, state), { appliesToStep: {
                    data: [...state.appliesToStep.data],
                    stepConfiguration: {
                        fromToWhereAttributes: action.payload
                    }
                } });
        case policies_create_actions_1.CREATE_POLICY_SUCCESS:
            return Object.assign({}, exports.initialWizardState);
        case policies_create_actions_1.CREATE_POLICY_FAIL:
            return Object.assign(Object.assign({}, state), { isLoading: false });
        case policies_edit_actions_1.EDIT_POLICY_DEFINITION:
            return Object.assign(Object.assign({}, state), { isLoading: true });
        case policies_create_actions_1.CREATE_POLICY:
            return Object.assign(Object.assign({}, state), { isLoading: true });
        case policies_edit_actions_1.EDIT_POLICY:
            return Object.assign(Object.assign({}, state), { isLoading: true });
        case policies_edit_actions_1.UPDATE_EDIT_POLICY_WIZARD:
            return Object.assign(Object.assign({}, action.payload), { userAwarenessStep: {
                    data: Object.assign({}, action.payload.userAwarenessStep.data),
                    stepConfiguration: Object.assign({}, state.userAwarenessStep.stepConfiguration)
                }, appliesToStep: Object.assign(Object.assign({}, state.appliesToStep), { data: [...action.payload.appliesToStep.data] }) });
        case policies_create_actions_1.RESET_POLICY_WIZARD:
            return Object.assign({}, exports.initialWizardState);
        case policies_create_actions_1.SET_POLICY_WIZARD_FLOW_TYPE:
            return Object.assign(Object.assign({}, state), { wizardFlowType: action.payload });
        default:
            return Object.assign({}, state);
    }
}
exports.reducer = reducer;
