"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CancelDeviceRemediation = exports.CloseCancelDeviceRemediatonPopup = exports.OpenCancelDeviceRemediatonPopup = exports.RemediateMessagesFailureAction = exports.RemediateMessagesSuccessAction = exports.RemediateMessagesAction = exports.RemediateIncidentMessagesOpenModalAction = exports.RemediateSearchMessagesOpenModalAction = exports.SetSelectedTableRows = exports.CANCEL_DEVICE_REMEDIATION = exports.CLOSE_CANCEL_DEVICE_REMEDIATION_POPUP = exports.OPEN_CANCEL_DEVICE_REMEDIATION_POPUP = exports.REMEDIATION_SELECTED_TABLE_ROWS = exports.REMEDIATE_MESSAGES_FAILURE = exports.REMEDIATE_MESSAGES_SUCCESS = exports.REMEDIATE_MESSAGES = exports.REMEDIATE_INCIDENT_MESSAGES_OPEN_MODAL = exports.REMEDIATE_SEARCH_MESSAGES_OPEN_MODAL = void 0;
exports.REMEDIATE_SEARCH_MESSAGES_OPEN_MODAL = '[Remediation] Remediate Messages (from Search) - Open Modal Dialog';
exports.REMEDIATE_INCIDENT_MESSAGES_OPEN_MODAL = '[Remediation] Remediate Messages (from Incident detail) - Open Modal Dialog';
exports.REMEDIATE_MESSAGES = '[Remediation] Remediate Messages';
exports.REMEDIATE_MESSAGES_SUCCESS = '[Remediation] Remediate Messages Success';
exports.REMEDIATE_MESSAGES_FAILURE = '[Remediation] Remediate Messages Failure';
exports.REMEDIATION_SELECTED_TABLE_ROWS = '[Remediation] Selected Rows From Table';
exports.OPEN_CANCEL_DEVICE_REMEDIATION_POPUP = '[Remediation] Open Cancel Device Remediation Popup';
exports.CLOSE_CANCEL_DEVICE_REMEDIATION_POPUP = '[Remediation] Close Cancel Device Remediation Popup';
exports.CANCEL_DEVICE_REMEDIATION = '[Remediation] Cancel Device Remediation';
class SetSelectedTableRows {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.REMEDIATION_SELECTED_TABLE_ROWS;
    }
}
exports.SetSelectedTableRows = SetSelectedTableRows;
class RemediateSearchMessagesOpenModalAction {
    constructor(incidentType = 'remediate') {
        this.incidentType = incidentType;
        this.type = exports.REMEDIATE_SEARCH_MESSAGES_OPEN_MODAL;
    }
}
exports.RemediateSearchMessagesOpenModalAction = RemediateSearchMessagesOpenModalAction;
class RemediateIncidentMessagesOpenModalAction {
    constructor(incidentType = 'remediate') {
        this.incidentType = incidentType;
        this.type = exports.REMEDIATE_INCIDENT_MESSAGES_OPEN_MODAL;
    }
}
exports.RemediateIncidentMessagesOpenModalAction = RemediateIncidentMessagesOpenModalAction;
class RemediateMessagesAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.REMEDIATE_MESSAGES;
    }
}
exports.RemediateMessagesAction = RemediateMessagesAction;
class RemediateMessagesSuccessAction {
    constructor(payload, searchTabPermission) {
        this.payload = payload;
        this.searchTabPermission = searchTabPermission;
        this.type = exports.REMEDIATE_MESSAGES_SUCCESS;
    }
}
exports.RemediateMessagesSuccessAction = RemediateMessagesSuccessAction;
class RemediateMessagesFailureAction {
    constructor() {
        this.type = exports.REMEDIATE_MESSAGES_FAILURE;
    }
}
exports.RemediateMessagesFailureAction = RemediateMessagesFailureAction;
class OpenCancelDeviceRemediatonPopup {
    constructor() {
        this.type = exports.OPEN_CANCEL_DEVICE_REMEDIATION_POPUP;
    }
}
exports.OpenCancelDeviceRemediatonPopup = OpenCancelDeviceRemediatonPopup;
class CloseCancelDeviceRemediatonPopup {
    constructor() {
        this.type = exports.CLOSE_CANCEL_DEVICE_REMEDIATION_POPUP;
    }
}
exports.CloseCancelDeviceRemediatonPopup = CloseCancelDeviceRemediatonPopup;
class CancelDeviceRemediation {
    constructor() {
        this.type = exports.CANCEL_DEVICE_REMEDIATION;
    }
}
exports.CancelDeviceRemediation = CancelDeviceRemediation;
