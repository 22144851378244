"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ6 = exports.ɵ5 = exports.ɵ4 = exports.ɵ3 = exports.ɵ2 = exports.ɵ1 = exports.ɵ0 = exports.isLoading = exports.isRetryEnabled = exports.isReportSubmissionSuccess = exports.isButtonEnabled = exports.isProcessing = exports.getError = exports.getUploadedImageBase64 = exports.getFileName = exports.getWidgetUrlsPerDayLoadingState = exports.getWidgetUrlsPerDayState = exports.getReportState = exports.getReportSubmitState = exports.getUrlFileParseState = exports.getUrlProtectionState = exports.urlReportSubmitReducer = exports.urlFileParseReducer = exports.urlProtectionReducer = void 0;
const store_1 = require("@ngrx/store");
const urlProtection = require("./url-protection-dashboard.reducer");
const urlReportSubmit = require("./url-report-submit.reducer");
const urlFileParse = require("./url-parse-file.reducer");
exports.urlProtectionReducer = {
    urlProtection: urlProtection.reducer
};
exports.urlFileParseReducer = {
    urlFileParse: urlFileParse.reducer
};
exports.urlReportSubmitReducer = {
    urlReportSubmit: urlReportSubmit.reducer
};
exports.getUrlProtectionState = store_1.createFeatureSelector('urlProtection');
exports.getUrlFileParseState = store_1.createFeatureSelector('urlFileParse');
exports.getReportSubmitState = store_1.createFeatureSelector('urlReportSubmit');
const ɵ0 = (state) => state.urlReportSubmit;
exports.ɵ0 = ɵ0;
exports.getReportState = store_1.createSelector(exports.getReportSubmitState, ɵ0);
const ɵ1 = (state) => state.urlProtection.widgetScansPerDay;
exports.ɵ1 = ɵ1;
exports.getWidgetUrlsPerDayState = store_1.createSelector(exports.getUrlProtectionState, ɵ1);
const ɵ2 = (state) => state.urlProtection.loading;
exports.ɵ2 = ɵ2;
exports.getWidgetUrlsPerDayLoadingState = store_1.createSelector(exports.getUrlProtectionState, ɵ2);
const ɵ3 = (state) => state.urlFileParse.file;
exports.ɵ3 = ɵ3;
exports.getFileName = store_1.createSelector(exports.getUrlFileParseState, ɵ3);
const ɵ4 = (state) => state.urlFileParse.imageUploaded;
exports.ɵ4 = ɵ4;
exports.getUploadedImageBase64 = store_1.createSelector(exports.getUrlFileParseState, ɵ4);
const ɵ5 = (state) => state.urlFileParse.hasError;
exports.ɵ5 = ɵ5;
exports.getError = store_1.createSelector(exports.getUrlFileParseState, ɵ5);
const ɵ6 = (state) => state.urlFileParse.isProcessing;
exports.ɵ6 = ɵ6;
exports.isProcessing = store_1.createSelector(exports.getUrlFileParseState, ɵ6);
exports.isButtonEnabled = store_1.createSelector(exports.getReportState, urlReportSubmit.isButtonEnabled);
exports.isReportSubmissionSuccess = store_1.createSelector(exports.getReportState, urlReportSubmit.isReportSubmissionSuccess);
exports.isRetryEnabled = store_1.createSelector(exports.getReportState, urlReportSubmit.isRetryEnabled);
exports.isLoading = store_1.createSelector(exports.getReportState, urlReportSubmit.isLoading);
