"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateDefinitionComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const group_selector_component_1 = require("app-new/components/group-selector/group-selector.component");
const actions = require("../../actions/create-definition.actions");
const listAction = require("../../actions/definition.actions");
const reducers = require("../../reducers");
const create_definition_component_static_values_1 = require("./create-definition.component.static-values");
const operators_1 = require("rxjs/operators");
const confirmation_modal_component_1 = require("../confirmation-modal/confirmation-modal.component");
const alert_modal_component_1 = require("../alert-modal/alert-modal.component");
const validators_1 = require("app-new/api-applications/validators");
const definitions_model_1 = require("../../models/definitions.model");
class CreateDefinitionComponent {
    constructor(fb, store, asideService, accountPackageService, modalService, apStripAndLinkService) {
        this.fb = fb;
        this.store = store;
        this.asideService = asideService;
        this.accountPackageService = accountPackageService;
        this.modalService = modalService;
        this.apStripAndLinkService = apStripAndLinkService;
        this.configurationOptions = create_definition_component_static_values_1.configurationOptions;
        this.documentFormatOptions = create_definition_component_static_values_1.documentFormatOptions;
        this.SpreadsheetsFormatOptions = create_definition_component_static_values_1.SpreadsheetsFormatOptions;
        this.SpreadsheetsWorksheetsOptions = create_definition_component_static_values_1.SpreadsheetsWorksheetsOptions;
        this.UserNotificationOptions = create_definition_component_static_values_1.UserNotificationOptions;
        this.preEmptiveFailureActionOptions = create_definition_component_static_values_1.PreEmptiveFailureActionOptions;
        this.gatewayActionOptions = create_definition_component_static_values_1.GatewayActionOptions;
        this.gatewayFallbackActionOptions = create_definition_component_static_values_1.GatewayFallbackActionOptions;
        this.userMailboxOptions = create_definition_component_static_values_1.UserMailboxOptions;
        this.userMailboxFallbackActionOptions = create_definition_component_static_values_1.UserMailboxFallbackActionOptions;
        this.ignoreSignedMessagesModalOpened = false;
        this.updateApDefinition = new core_1.EventEmitter();
        this.hasIEPPackage = this.accountPackageService.hasInternalEmailProtectAccountPackage();
        this.isAAAOrFAAUser = this.accountPackageService.isAAAOrFAAUser();
        this.isMessageRecoveryService = this.accountPackageService.isMessageRecoveryService();
        this.isCAEUser = this.accountPackageService.isCAEUser();
        if (this.isAAAOrFAAUser || (!this.isMessageRecoveryService && !this.isCAEUser)) {
            this.configurationOptions = create_definition_component_static_values_1.configurationOptions.filter((res) => {
                if (res.value === 'sandbox') {
                    return res;
                }
                return false;
            });
        }
        this.apDefinitionForm = this.fb.group({
            description: ['', [forms_1.Validators.required, forms_1.Validators.maxLength(100), validators_1.validateNotEmpty]],
            checkInbound: [true],
            notifyGroup: [null],
            internalForward: [true],
            preEmptiveFailureAction: [this.preEmptiveFailureActionOptions[0].value],
            sandboxEncryptionPortalEnabled: [false],
            ignoreSignedMessages: [false],
            safeDocFormat: [this.documentFormatOptions[0].value],
            action: this.hasIEPPackage || this.isMessageRecoveryService || this.isCAEUser
                ? [this.configurationOptions[3].value]
                : [this.configurationOptions[0].value],
            safeSpreadsheetFormat: [this.SpreadsheetsFormatOptions[0].value],
            firstSheetOnly: [this.SpreadsheetsWorksheetsOptions[0].value],
            notifyOnMalicious: [false],
            notifyExternalRecipient: [false],
            notifyInternalSender: [true],
            notifyInternalRecipient: [true],
            notifyExternalSender: [false],
            checkOutbound: [false],
            outboundFallbackRemediationAction: ['none'],
            outboundRemediationAction: ['none'],
            outboundFallbackAction: ['hold'],
            outboundAction: ['hold'],
            outboundNotifyOnMalicious: [false],
            journalNotifyOnMalicious: [false],
            outboundNotifyGroup: [null],
            outboundNotifyInternalSender: [false],
            checkJournal: [false],
            journalFallbackRemediationAction: ['none'],
            journalRemediationAction: ['none'],
            journalNotifyGroup: [null],
            journalNotifyInternalSender: [false],
            journalNotifyInternalRecipient: [false]
        });
    }
    ngOnInit() {
        this.store.dispatch(new actions.GetServerConnection());
        this.loadDefinitionData();
        this.addedGroupsList$ = this.store.select(reducers.getAdministratorGroups);
        this.addedGroupsListSubscription = this.addedGroupsList$.subscribe(group => {
            this.selectGroupsLength = group;
        });
        this.addedOutboundGroupsList$ = this.store.select(reducers.getOutboundAdministratorGroup);
        this.addedOutboundGroupsListSubscription = this.addedOutboundGroupsList$.subscribe(group => {
            this.selectGroupsLengthOutbound = group;
        });
        this.addedJournalGroupsList$ = this.store.select(reducers.getJournalAdministratorGroup);
        this.addedJournalGroupsListSubscription = this.addedJournalGroupsList$.subscribe(group => {
            this.selectGroupsLengthJournal = group;
        });
        this.apDefinitionForm.get('action').valueChanges.subscribe(value => {
            if (value === definitions_model_1.ConfigurationDetailOption.DYNAMICCONFIGURATION ||
                value === definitions_model_1.ConfigurationDetailOption.PREEXEMPTIVE) {
                this.apDefinitionForm.patchValue({
                    notifyOnMalicious: true
                });
            }
            else {
                this.store.dispatch(new actions.RemoveAdministratorGroupAction());
                this.apDefinitionForm.patchValue({
                    notifyOnMalicious: false
                });
            }
        });
        this.apDefinitionForm.get('notifyOnMalicious').valueChanges.subscribe(notifyOnMalicious => {
            if (!notifyOnMalicious) {
                this.store.dispatch(new actions.RemoveAdministratorGroupAction());
                this.apDefinitionForm.patchValue({
                    notifyInternalSender: false,
                    notifyInternalRecipient: false,
                    notifyExternalSender: false
                });
            }
        });
        this.apDefinitionForm
            .get('outboundNotifyOnMalicious')
            .valueChanges.subscribe(outboundNotifyOnMalicious => {
            if (!outboundNotifyOnMalicious) {
                this.store.dispatch(new actions.RemoveOutboundGroupAction());
                this.apDefinitionForm.patchValue({
                    outboundNotifyInternalSender: false
                });
            }
        });
        this.apDefinitionForm
            .get('journalNotifyOnMalicious')
            .valueChanges.subscribe(journalNotifyOnMalicious => {
            if (!journalNotifyOnMalicious) {
                this.store.dispatch(new actions.RemoveJournalGroupAction());
                this.apDefinitionForm.patchValue({
                    journalNotifyInternalSender: false,
                    journalNotifyInternalRecipient: false
                });
            }
        });
        this.hasStripAndLinkEncAttachments$ = this.apStripAndLinkService.isEncAttachmentsSwitchEnabled();
    }
    loadDefinitionData() {
        this.store
            .select(reducers.getSelectedRowFullData)
            .pipe(operators_1.filter(val => !!val), operators_1.take(1))
            .subscribe(definitionDetails => {
            this.definitionDetails = definitionDetails;
            this.store.dispatch(new actions.UpdateDefinitonId(this.definitionDetails.id));
            this.selectGroupsLength = this.definitionDetails.notifyGroup;
            this.selectGroupsLengthOutbound = this.definitionDetails.outboundNotifyGroup;
            this.selectGroupsLengthJournal = this.definitionDetails.journalNotifyGroup;
            this.apDefinitionForm.patchValue({
                description: this.definitionDetails.description,
                checkInbound: this.definitionDetails.checkInbound,
                internalForward: this.definitionDetails.internalForward,
                preEmptiveFailureAction: this.definitionDetails.preEmptiveFailureAction,
                safeDocFormat: this.definitionDetails.safeDocFormat,
                action: this.definitionDetails.action,
                ignoreSignedMessages: this.definitionDetails.ignoreSignedMessages,
                sandboxEncryptionPortalEnabled: this.definitionDetails.sandboxEncryptionPortalEnabled,
                safeSpreadsheetFormat: this.definitionDetails.safeSpreadsheetFormat,
                firstSheetOnly: this.definitionDetails.firstSheetOnly,
                notifyOnMalicious: this.definitionDetails.notifyOnMalicious,
                notifyExternalRecipient: this.definitionDetails.notifyExternalRecipient,
                notifyInternalSender: this.definitionDetails.notifyInternalSender,
                notifyInternalRecipient: this.definitionDetails.notifyInternalRecipient,
                notifyExternalSender: this.definitionDetails.notifyExternalSender,
                checkOutbound: this.definitionDetails.checkOutbound,
                outboundFallbackRemediationAction: this.definitionDetails
                    .outboundFallbackRemediationAction,
                outboundRemediationAction: this.definitionDetails.outboundRemediationAction,
                outboundFallbackAction: this.definitionDetails.outboundFallbackAction,
                outboundAction: this.definitionDetails.outboundAction,
                outboundNotifyOnMalicious: this.definitionDetails.outboundNotifyOnMalicious,
                journalNotifyOnMalicious: this.definitionDetails.journalNotifyOnMalicious,
                outboundNotifyInternalSender: this.definitionDetails.outboundNotifyInternalSender,
                checkJournal: this.definitionDetails.checkJournal,
                journalFallbackRemediationAction: this.definitionDetails.journalFallbackRemediationAction,
                journalRemediationAction: this.definitionDetails.journalRemediationAction,
                journalNotifyInternalSender: this.definitionDetails.journalNotifyInternalSender,
                journalNotifyInternalRecipient: this.definitionDetails.journalNotifyInternalRecipient
            });
            if (this.definitionDetails.action !== 'sandbox' && !this.isMessageRecoveryService) {
                this.enableNotificationValuesCheck('NON_SUPPORTED_OPTION_CHECK');
            }
            this.store.dispatch(new actions.AdministratorSelectGroup(this.definitionDetails.notifyGroup));
            this.store.dispatch(new actions.OutboundSelectGroup(this.definitionDetails.outboundNotifyGroup));
            this.store.dispatch(new actions.JournalSelectGroup(this.definitionDetails.journalNotifyGroup));
        });
    }
    noWhitespaceValidator(control) {
        const noWhitespace = (control.value || '').trim().length !== (control.value || '').length;
        const isValid = !noWhitespace;
        return isValid ? null : { whitespace: true };
    }
    goBackToDefinition() {
        this.store.dispatch(new actions.ApDefinitionNavigateToListPageAction());
        this.store.dispatch(new listAction.ClearDefinitionDetailsAction());
        this.store.dispatch(new listAction.ClearDuplicateDetailsAction());
    }
    isInboundCheck() {
        if (this.apDefinitionForm.value.checkInbound === false) {
            this.store.dispatch(new actions.RemoveAdministratorGroupAction(undefined));
            this.apDefinitionForm.patchValue({
                internalForward: true,
                preEmptiveFailureAction: this.preEmptiveFailureActionOptions[0].value,
                ignoreSignedMessages: false,
                sandboxEncryptionPortalEnabled: false,
                safeDocFormat: this.documentFormatOptions[0].value,
                action: this.hasIEPPackage
                    ? this.configurationOptions[3].value
                    : this.configurationOptions[0].value,
                safeSpreadsheetFormat: this.SpreadsheetsFormatOptions[0].value,
                firstSheetOnly: this.SpreadsheetsWorksheetsOptions[0].value,
                notifyOnMalicious: false,
                notifyInternalSender: false,
                notifyInternalRecipient: false,
                notifyExternalSender: false
            });
            return this.apDefinitionForm.get('checkInbound').value;
        }
        return this.apDefinitionForm.get('checkInbound').value;
    }
    onChangeValue(event) {
        if (event.target.checked) {
            this.handleIgnoreSignedMessagesModal();
        }
    }
    handleIgnoreSignedMessagesModal() {
        this.modalService
            .open(confirmation_modal_component_1.ConfirmationModalComponent, {
            data: {
                type: 'SIGNED_MESSAGES'
            }
        })
            .afterClose()
            .pipe(operators_1.take(1))
            .subscribe(res => {
            if (!res) {
                this.apDefinitionForm.get('ignoreSignedMessages').patchValue(false);
            }
            this.ignoreSignedMessagesModalOpened = true;
        });
    }
    enableNotificationValuesCheck(value) {
        this.modalService.open(alert_modal_component_1.AlertModalComponent, {
            data: {
                type: value
            }
        });
    }
    checkInboundOutbounJournal() {
        this.modalService.open(alert_modal_component_1.AlertModalComponent, {
            data: {
                type: 'CHECK_OPTIONS'
            }
        });
    }
    isOutboundCheck() {
        if (this.apDefinitionForm.value.checkOutbound === false) {
            this.store.dispatch(new actions.RemoveOutboundGroupAction(undefined));
            this.apDefinitionForm.patchValue({
                outboundFallbackRemediationAction: 'none',
                outboundRemediationAction: 'none',
                outboundFallbackAction: 'hold',
                outboundAction: 'hold',
                outboundNotifyOnMalicious: false,
                outboundNotifyInternalSender: false
            });
            return this.apDefinitionForm.get('checkOutbound').value;
        }
        return this.apDefinitionForm.get('checkOutbound').value;
    }
    isJournalCheck() {
        if (this.apDefinitionForm.value.checkJournal === false) {
            this.store.dispatch(new actions.RemoveJournalGroupAction(undefined));
            this.apDefinitionForm.patchValue({
                journalFallbackRemediationAction: 'none',
                journalRemediationAction: 'none',
                journalNotifyGroup: null,
                journalNotifyOnMalicious: false,
                journalNotifyInternalSender: false,
                journalNotifyInternalRecipient: false
            });
            return this.apDefinitionForm.get('checkJournal').value;
        }
        return this.apDefinitionForm.get('checkJournal').value;
    }
    openSidebar(type) {
        if (type === 'inbound') {
            const asideConfig = {};
            const aside = this.asideService.open(group_selector_component_1.GroupSelectorComponent, asideConfig);
            aside.beforeClose().subscribe(selectedGroup => {
                if (selectedGroup) {
                    this.store.dispatch(new actions.AdministratorSelectGroup(selectedGroup));
                }
            });
        }
        else if (type === 'outbound') {
            const asideConfig = {};
            const aside = this.asideService.open(group_selector_component_1.GroupSelectorComponent, asideConfig);
            aside.beforeClose().subscribe(selectedGroup => {
                if (selectedGroup) {
                    this.store.dispatch(new actions.OutboundSelectGroup(selectedGroup));
                }
            });
        }
        else if (type === 'journal') {
            const asideConfig = {};
            const aside = this.asideService.open(group_selector_component_1.GroupSelectorComponent, asideConfig);
            aside.beforeClose().subscribe(selectedGroup => {
                if (selectedGroup) {
                    this.store.dispatch(new actions.JournalSelectGroup(selectedGroup));
                }
            });
        }
    }
    onDeleteGroup(row, type) {
        if (type === 'inbound') {
            this.store.dispatch(new actions.RemoveAdministratorGroupAction(row));
        }
        else if (type === 'outbound') {
            this.store.dispatch(new actions.RemoveOutboundGroupAction(row));
        }
        else if (type === 'journal') {
            this.store.dispatch(new actions.RemoveJournalGroupAction(row));
        }
    }
    updateStore() {
        this.updateApDefinition.emit(this.apDefinitionForm.value);
    }
    ngOnDestroy() {
        this.addedGroupsListSubscription.unsubscribe();
        if (this.serverSuscription) {
            this.serverSuscription.unsubscribe();
        }
    }
    createDefinition(type) {
        if (!this.apDefinitionForm.valid) {
            return;
        }
        if (this.apDefinitionForm.value.checkInbound === false &&
            this.apDefinitionForm.value.checkOutbound === false &&
            this.apDefinitionForm.value.checkJournal === false) {
            this.checkInboundOutbounJournal();
            return;
        }
        if (!this.displayStripAndLinkEncAttachments(this.apDefinitionForm.value.action)) {
            // Stripping and linking encrypted attachments should only be enabled for pre-emptive sandbox or dynamic configuration.
            this.apDefinitionForm.patchValue({
                sandboxEncryptionPortalEnabled: false
            });
        }
        if (this.apDefinitionForm.value.action === 'safedoc_and_link' &&
            this.apDefinitionForm.value.notifyOnMalicious === true &&
            this.selectGroupsLength === undefined) {
            this.enableNotificationValuesCheck('ADMIN_REVIEW_GROUP');
            return;
        }
        if (this.apDefinitionForm.value.action !== 'safedoc_only' &&
            this.apDefinitionForm.value.action !== 'safedoc_and_link' &&
            this.apDefinitionForm.value.checkInbound === true &&
            this.apDefinitionForm.value.notifyOnMalicious === true &&
            this.apDefinitionForm.value.notifyInternalSender === false &&
            this.apDefinitionForm.value.notifyExternalSender === false &&
            this.apDefinitionForm.value.notifyInternalRecipient === false &&
            this.selectGroupsLength === undefined) {
            this.enableNotificationValuesCheck('INBOUND_CHECK');
            return;
        }
        if (this.apDefinitionForm.value.checkOutbound === true &&
            this.apDefinitionForm.value.outboundNotifyOnMalicious === true &&
            this.apDefinitionForm.value.outboundNotifyInternalSender === false &&
            this.selectGroupsLengthOutbound === undefined) {
            this.enableNotificationValuesCheck('OUTBOUND_CHECK');
            return;
        }
        if (this.apDefinitionForm.value.checkJournal === true &&
            this.apDefinitionForm.value.journalNotifyOnMalicious === true &&
            this.apDefinitionForm.value.journalNotifyInternalSender === false &&
            this.apDefinitionForm.value.journalNotifyInternalRecipient === false &&
            this.selectGroupsLengthJournal === undefined) {
            this.enableNotificationValuesCheck('JOURNAL_CHECK');
            return;
        }
        if (this.apDefinitionForm.value.notifyOnMalicious === false) {
            this.apDefinitionForm.patchValue({
                notifyInternalSender: false,
                notifyInternalRecipient: false,
                notifyExternalSender: false
            });
        }
        if (this.apDefinitionForm.value.action === definitions_model_1.ConfigurationDetailOption.PREEXEMPTIVE) {
            this.apDefinitionForm.patchValue({
                ignoreSignedMessages: false,
                safeDocFormat: definitions_model_1.SpreadsheetsFormatOption.ORIGINALFORMAT,
                safeSpreadsheetFormat: definitions_model_1.SpreadsheetsFormatOption.ORIGINALFORMAT
            });
        }
        if (this.apDefinitionForm.value.checkOutbound === false) {
            this.apDefinitionForm.patchValue({
                outboundAction: definitions_model_1.GatewayActions.NONE,
                outboundFallbackAction: definitions_model_1.GatewayActions.NONE
            });
        }
        if ((this.apDefinitionForm.value.journalRemediationAction !== 'none' ||
            this.apDefinitionForm.value.outboundRemediationAction !== 'none') &&
            this.hasIEPPackage) {
            this.serverSuscription = this.store
                .select(reducers.getServerConnection)
                .pipe(operators_1.first())
                .subscribe(data => {
                if (data && data.length) {
                    this.saveDefinition(type);
                }
                else {
                    return this.enableNotificationValuesCheck('SERVER_CONNECTION_CHECK');
                }
            });
        }
        else {
            this.saveDefinition(type);
        }
    }
    saveDefinition(type) {
        if (type === 'save_only') {
            this.store.dispatch(new actions.CreateDefinitionSaveAction(this.apDefinitionForm.value));
        }
        else {
            this.store.dispatch(new actions.CreateDefinitionSaveAndExitAction(this.apDefinitionForm.value));
            this.store.dispatch(new listAction.ClearDefinitionDetailsAction());
        }
    }
    displayStripAndLinkEncAttachments(action) {
        return this.apStripAndLinkService.isValidConfigType(action);
    }
}
exports.CreateDefinitionComponent = CreateDefinitionComponent;
