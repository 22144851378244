"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tableFilterSections = exports.tableColumns = void 0;
exports.tableColumns = [
    'name',
    'type',
    'status',
    'createdBy',
    'created',
    'updated',
    'reviewerCount',
    'labels',
    'printAllowed',
    'forwardAllowed',
    'columnActions'
];
exports.tableFilterSections = [
    {
        name: 'status',
        filters: ['active', 'created', 'error', 'full']
    },
    {
        name: 'type',
        filters: ['escalation', 'review']
    }
];
