<mc-layout-aside-extra-container
  keyTitle="{{
    isEditing
      ? '$I18N_SWG_EXCEPTIONS_LIST.SIDEBAR.EDIT.HEADER'
      : '$I18N_SWG_EXCEPTIONS_LIST.SIDEBAR.ADD.HEADER'
  }}"
  showClose="true"
  [isLoading]="false"
  (closeAside)="close()"
>
  <mc-extra-container *mcCapabilities="'Permission.SWG_BYPASS_HOSTS_EDIT'">
    <mc-live-button
      overrideClasses="btn btn-primary"
      [isLoading]="isApiProcessing"
      [label]="showButtonText() | translate"
      class="mc-live-button"
      [mcDisabled]="form.invalid"
      (mcClick)="saveException()"
    >
    </mc-live-button>
    <button class="btn btn-secondary panel-half-margin-right" (click)="close()">
      {{ '$I18N_COMMON_BTN_CANCEL' | translate }}
    </button>
  </mc-extra-container>
  <mc-body-container>
    <mc-inline-notification
      [notification]="internalDomainsOnlyNotificationConfig"
    ></mc-inline-notification>
    <form
      class="form-horizontal"
      mcDefaultLabelClass="col-sm-4"
      mcDefaultControlContainerClass="col-sm-8"
      [formGroup]="form"
    >
      <mc-text-field
        label="$I18N_SWG_EXCEPTIONS_LIST.SIDEBAR.ADD.LABELS.NAME"
        formControlName="description"
        errorPrefix="$I18N_SWG_EXCEPTIONS_LIST.SIDEBAR.ERRORS.DESC"
        required
        [setFocus]="true"
      ></mc-text-field>
      <mc-field label="$I18N_SWG_EXCEPTIONS_LIST.SIDEBAR.ADD.LABELS.TYPE">
        <mc-select
          formControlName="type"
          [options]="options"
          (selectChange)="onSelectChange($event)"
        ></mc-select>
      </mc-field>
      <div *ngIf="form.get('type').value === 'host'">
        <mc-text-field
          label="$I18N_SWG_EXCEPTIONS_LIST.SIDEBAR.ADD.LABELS.DOMAIN"
          formControlName="host"
          errorPrefix="$I18N_SWG_EXCEPTIONS_LIST.SIDEBAR.ERRORS.DOMAIN"
        ></mc-text-field>
      </div>
      <div *ngIf="form.get('type').value === 'ipRange'">
        <mc-text-field
          label="$I18N_SWG_EXCEPTIONS_LIST.SIDEBAR.ADD.LABELS.IP_RANGE"
          formControlName="ipRange"
          errorPrefix="$I18N_SWG_EXCEPTIONS_LIST.SIDEBAR.ERRORS.IP"
        ></mc-text-field>
      </div>
      <div class="col-sm-4"></div>
      <div class="col-sm-8 text-muted no-margin small">
        <span class="text-danger">*</span>
        <span>
          {{ '$I18N_SWG_EXCEPTIONS_LIST.SIDEBAR.ADD.LABELS.MANDATORY' | translate }}
        </span>
      </div>
    </form>
  </mc-body-container>
</mc-layout-aside-extra-container>
