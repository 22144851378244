<div class="mc-api-gateway-step-block">
  <mc-wizard-step-header [label]="pageTitle">
    {{ pageDescription | translate }}
  </mc-wizard-step-header>

  <form
    class="form-horizontal"
    mcDefaultLabelClass="col-sm-3"
    mcDefaultControlContainerClass="col-sm-9"
    [formGroup]="form"
    (ngSubmit)="submit()"
  >
    <div class="row form-group" *ngIf="isCreating">
      <mc-field
        errorPrefix="$I18N_API_GATEWAY_V3_WIZARD.VALIDATION.FIELD"
        label="$I18N_API_GATEWAY_V3_WIZARD.FIELDS.APPLICATION_NAME"
        required
        [helpPopoverContent]="'$I18N_API_GATEWAY_V3_WIZARD.TOOLTIPS.APPLICATION_NAME' | translate"
      >
        <input
          class="form-control application-name-input"
          formControlName="applicationName"
          [readonly]="applicationNameDisabled"
          aria-label="$I18N_API_GATEWAY_V3_WIZARD.FIELDS.APPLICATION_NAME"
        />
      </mc-field>
    </div>

    <div class="row form-group" *ngIf="isEditing">
      <div class="form-group mc-field">
        <label class="control-label col-sm-3">
          <span translate="$I18N_API_GATEWAY_V3_WIZARD.FIELDS.APPLICATION_NAME"></span>
        </label>
        <div class="col-sm-9 panel-half-padding-top">
          <span>{{ applicationDetails.applicationName }}</span>
        </div>
      </div>
    </div>

    <div *mcCapabilities="'Temporary.Services.ApiApplications.Apigee.Mimecast.All.Categories'"
         class="row form-group mc-categories">
      <mc-form-select
        [label]="'$I18N_API_GATEWAY_V3_WIZARD.FIELDS.CATEGORY'"
        [placeholder]="'$I18N_API_GATEWAY_V3_WIZARD.FIELDS.CATEGORY_PLACEHOLDER'"
        formControlName="category"
        [options]="categories"
        [useTranslation]="true"
      >
      </mc-form-select>
    </div>

    <div *mcCapabilities="'Temporary.Services.ApiApplications.Apigee.Mimecast.All.Products'" class="row form-group">
      <mc-field
        errorPrefix="$I18N_API_GATEWAY_V3_WIZARD.VALIDATION.FIELD"
        label="$I18N_API_GATEWAY_V3_WIZARD.FIELDS.PRODUCTS"
        required
        [helpPopoverContent]=productPopover
      >
        <mc-dropdown-autocomplete-checkboxes
          label="$I18N_API_GATEWAY_V3_WIZARD.FIELDS.PRODUCTS_PLACEHOLDER"
          [optionItems]="products$ | async"
          (onApplySelected)=collectProductIds($event)
          formControlName="gatewayAppProducts"
        >
        </mc-dropdown-autocomplete-checkboxes>
      </mc-field>
    </div>

    <div class="row form-group">
      <mc-form-select
        [label]="'$I18N_API_GATEWAY_V3_WIZARD.FIELDS.APPLICATION_ROLE'"
        [placeholder]="'$I18N_API_GATEWAY_V3_WIZARD.FIELDS.APPLICATION_ROLES_PLACEHOLDER'"
        formControlName="roleId"
        [options]="roles$ | async"
        [useTranslation]="true"
        [shouldTrimOptions]="true"
        [helpPopoverContent]="'$I18N_API_GATEWAY_V3_WIZARD.TOOLTIPS.APPLICATION_ROLE' | translate"
      >
      </mc-form-select>
    </div>

    <div class="row form-group panel-half-padding-top">
      <mc-text-area-field
        errorPrefix="$I18N_API_GATEWAY_V3_WIZARD.VALIDATION.FIELD"
        label="$I18N_API_GATEWAY_V3_WIZARD.FIELDS.DESCRIPTION"
        formControlName="description"
        required
      >
      </mc-text-area-field>
    </div>
  </form>

  <ng-template #productPopover>
    {{ '$I18N_API_GATEWAY_V3_WIZARD.TOOLTIPS.PRODUCTS' | translate }}
    <a [attr.href]="productsKbUrl" target="_blank">
      <u><span>{{ '$I18N_API_GATEWAY_V3_WIZARD.TOOLTIPS.PRODUCTS_LINK' | translate }}</span></u>
    </a>
  </ng-template>
</div>
