"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SystemMessagesService = void 0;
const operators_1 = require("rxjs/operators");
const moment = require("moment");
const components_1 = require("@mimecast-ui/components");
class SystemMessagesService {
    constructor(http) {
        this.http = http;
        this.searchFieldsMap = {
            all: 'all',
            fromAddress: 'fromenv',
            toAddress: 'toenv'
        };
    }
    getItems(request, pagination) {
        return this.http
            .post('/api/gateway/find-system-messages', this.transformToServerRequest(request, pagination))
            .pipe(operators_1.map(response => [response.first, response.meta]), operators_1.map(([first, meta]) => [
            first.messages,
            Object.assign({}, meta['pagination'])
        ]), operators_1.map(([systemMessages, page]) => {
            return {
                items: this.transformToUIModel(systemMessages),
                page
            };
        }));
    }
    transformToServerRequest(request, pagination) {
        const { searchBy, start, end } = request, remainder = __rest(request, ["searchBy", "start", "end"]);
        const apiRequest = Object.assign(Object.assign(Object.assign(Object.assign({}, remainder), (!!searchBy
            ? {
                searchBy: Object.assign(Object.assign({}, searchBy[0]), { fieldName: this.searchFieldsMap[searchBy[0].fieldName] })
            }
            : {})), (!!start ? { start: moment(request.start).format(components_1.API_DATE_FORMAT) } : {})), (!!end ? { end: moment(request.end).format(components_1.API_DATE_FORMAT) } : {}));
        if (!!pagination) {
            // @ts-ignore: api interceptor wants like this :(
            apiRequest.meta = { pagination };
        }
        return apiRequest;
    }
    transformToUIModel(systemMessages) {
        return systemMessages.map(message => {
            const { nextAttempt, lockedUntil, typeDescription, reason } = message, remainder = __rest(message, ["nextAttempt", "lockedUntil", "typeDescription", "reason"]);
            return Object.assign(Object.assign({}, remainder), { type: typeDescription, info: reason, nextAttempt,
                lockedUntil });
        });
    }
    rejectEmails(emails) {
        return this.http
            .post('/api/message-finder/reject-queued-email', emails.map(email => ({ id: email.id })))
            .pipe(operators_1.map(response => response.all));
    }
    retryEmails(emails) {
        return this.http
            .post('/api/message-finder/retry-delivery', emails.map(email => ({ id: email.id })))
            .pipe(operators_1.map(response => response.all));
    }
    earlyBounceEmails(emails) {
        return this.http
            .post('/api/message-finder/bounce-queued-email', emails.map(email => ({ id: email.id })))
            .pipe(operators_1.map(response => response.all));
    }
    transformToExportServerRequest(columns) {
        return columns.map(col => {
            let renamedCol = '';
            switch (col) {
                case 'from':
                    renamedCol = 'fromEnv';
                    break;
                case 'routing':
                    renamedCol = 'route';
                    break;
                case 'messageType':
                    renamedCol = 'typeDescription';
                    break;
                case 'info':
                    renamedCol = 'reason';
                    break;
                case 'created':
                    renamedCol = 'createtime';
                    break;
                default:
                    renamedCol = col;
            }
            return renamedCol;
        });
    }
}
exports.SystemMessagesService = SystemMessagesService;
