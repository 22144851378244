"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportsService = void 0;
const operators_1 = require("rxjs/operators");
class ReportsService {
    constructor(http) {
        this.http = http;
        this.fileNameRegex = /.*filename=\"(.+)\"/g;
    }
    static getApiErrorTranslateKey(apiErrorCode) {
        switch (apiErrorCode.toUpperCase()) {
            case 'ERR_SUPERVISION_REPORT_INVALID_START_DATE':
                return '$I18N_ARCHIVE_SUPERVISION_REPORT_TAB.NOTIFICATION.ERR_SUPERVISION_REPORT_INVALID_START_DATE';
            case 'ERR_SUPERVISION_REPORT_INVALID_END_DATE':
                return '$I18N_ARCHIVE_SUPERVISION_REPORT_TAB.NOTIFICATION.ERR_SUPERVISION_REPORT_INVALID_END_DATE';
            case 'ERR_SUPERVISION_REPORT_INVALID_DATE_RANGE':
                return '$I18N_ARCHIVE_SUPERVISION_REPORT_TAB.NOTIFICATION.ERR_SUPERVISION_REPORT_INVALID_DATE_RANGE';
            case 'ERR_SUPERVISION_REPORT_EXCEED_MAXIMUM_DATE_RANGE':
                return '$I18N_ARCHIVE_SUPERVISION_REPORT_TAB.NOTIFICATION.ERR_SUPERVISION_REPORT_EXCEED_MAXIMUM_DATE_RANGE';
            case 'ERR_SUPERVISION_REPORT_EXCEED_SIXTY_MAX_DATE_RANGE':
                return '$I18N_ARCHIVE_SUPERVISION_REPORT_TAB.NOTIFICATION.ERR_SUPERVISION_REPORT_EXCEED_SIXTY_MAX_DATE_RANGE';
            case 'ERR_SUPERVISION_REPORT_EXCEED_NINETY_MAX_DATE_RANGE':
                return '$I18N_ARCHIVE_SUPERVISION_REPORT_TAB.NOTIFICATION.ERR_SUPERVISION_REPORT_EXCEED_NINETY_MAX_DATE_RANGE';
            case 'ERR_SUPERVISION_REPORT_FAILED_RETRIEVE':
                return '$I18N_ARCHIVE_SUPERVISION_REPORT_TAB.NOTIFICATION.ERR_SUPERVISION_REPORT_FAILED_RETRIEVE';
            case 'INVALID_PAYLOAD':
                return '$I18N_ARCHIVE_SUPERVISION_REPORT_TAB.NOTIFICATION.INVALID_PAYLOAD';
            default:
                return '$I18N_ARCHIVE_SUPERVISION_REPORT_TAB.NOTIFICATION.FAILURE';
        }
    }
    getRequestData(reportType, startDate, endDate) {
        const reqHeaders = {
            'x-mc-arg': JSON.stringify({
                data: [
                    {
                        type: reportType,
                        start: startDate,
                        end: endDate
                    }
                ]
            })
        };
        const options = {
            headers: reqHeaders,
            observe: 'response',
            responseType: 'text'
        };
        return options;
    }
    getReportContent(reportType, startDate, endDate) {
        const options = this.getRequestData(reportType, startDate, endDate);
        return this.http.get('/api/supervision/config/download-report', options).pipe(operators_1.map((httpResponse) => {
            const content = httpResponse.body;
            try {
                const mcResponse = JSON.parse(content);
                if (mcResponse && mcResponse.fail && mcResponse.fail[0]) {
                    return Object.assign(Object.assign({}, mcResponse), { hasErrors: true });
                }
            }
            catch (ex) {
                // ignore as it is not a McResponse
            }
            const dispHeader = httpResponse.headers && httpResponse.headers.get('content-disposition');
            const report_filename = dispHeader &&
                dispHeader.replace(this.fileNameRegex, (match, filename) => filename);
            const response = {
                hasErrors: false,
                first: { filename: report_filename, content }
            };
            return response;
        }));
    }
}
exports.ReportsService = ReportsService;
