"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UrlProtectionJournalSettingsStepComponent = void 0;
const url_protection_journal_settings_step_component_static_values_1 = require("./url-protection-journal-settings-step.component.static-values");
const reducers = require("../../../reducers");
const actions = require("../../../actions/policies-create.actions");
const group_selector_component_1 = require("app-new/components/group-selector/group-selector.component");
const operators_1 = require("rxjs/operators");
const policies_create_actions_1 = require("../../../actions/policies-create.actions");
class UrlProtectionJournalSettingsStepComponent {
    constructor(fb, asideService, store) {
        this.fb = fb;
        this.asideService = asideService;
        this.store = store;
        this.urlScanningModeValues = url_protection_journal_settings_step_component_static_values_1.urlScanningModeValues;
        this.urlCategoryScanningModeValues = url_protection_journal_settings_step_component_static_values_1.urlCategoryScanningModeValues;
        this.senderMailboxActionValues = url_protection_journal_settings_step_component_static_values_1.senderMailboxActionValues;
        this.senderMailboxFallbackActionValues = url_protection_journal_settings_step_component_static_values_1.senderMailboxFallbackActionValues;
        this.urlFileDownloadValues = url_protection_journal_settings_step_component_static_values_1.urlFileDownloadValues;
        this.selectedGroupDescription$ = this.store.select(reducers.getJournalAdministratorsSelectedGroupDescription);
        this.urlProtectionPolicyJournalSettingsForm = this.fb.group({
            checkInternalEmail: [true],
            urlScanningMode: [this.urlScanningModeValues[2].value],
            urlCategoryScanningMode: [this.urlCategoryScanningModeValues[2].value],
            senderMailboxAction: [this.senderMailboxActionValues[1].value],
            senderMailboxFallbackAction: [this.senderMailboxFallbackActionValues[1].value],
            blockUrlWithDangerousFileExtensions: [true],
            urlFileDownload: [this.urlFileDownloadValues[0].value],
            scanUrlInAttachments: [false],
            attachmentPartHTML: [false],
            attachmentPartText: [false],
            attachmentPartCalendar: [false],
            attachmentPartOther: [false],
            checkForSimilarUrls: [false],
            domainToCheckAgainstYourInternalDomains: [false],
            domainToCheckAgainstMimecastMonitoredExternalDomains: [false],
            domainToCheckAgainstCustomMonitoredExternalDomains: [false],
            internalSender: [false],
            internalRecipients: [false]
        });
        this.startSubscriptions();
    }
    ngOnInit() {
        this.saveStoreData(this.urlProtectionPolicyJournalSettingsForm.value);
    }
    ngOnChanges(changes) {
        const change = changes['policyJournalData'];
        if (change && change.previousValue !== change.currentValue) {
            if (change.currentValue) {
                this.urlProtectionPolicyJournalSettingsForm.patchValue(change.currentValue);
            }
        }
    }
    ngOnDestroy() {
        this.formValueChangeSubscription.unsubscribe();
        this.scanUrlsInAttachmentsValueSubscription.unsubscribe();
        this.checkForSimilarUrlsValueSubscription.unsubscribe();
    }
    startSubscriptions() {
        this.formValueChangeSubscription = this.urlProtectionPolicyJournalSettingsForm.valueChanges
            .pipe(operators_1.debounceTime(300))
            .subscribe(data => this.saveStoreData(data));
        this.scanUrlsInAttachmentsValueSubscription = this.urlProtectionPolicyJournalSettingsForm
            .get(url_protection_journal_settings_step_component_static_values_1.formControlNames.SCAN_URLS_IN_ATTACHMENTS)
            .valueChanges.subscribe(value => {
            url_protection_journal_settings_step_component_static_values_1.formControlsToUpdateOnScan.forEach((control) => {
                this.urlProtectionPolicyJournalSettingsForm.get(control).patchValue(value);
            });
        });
        this.checkForSimilarUrlsValueSubscription = this.urlProtectionPolicyJournalSettingsForm
            .get(url_protection_journal_settings_step_component_static_values_1.formControlNames.CHECK_FOR_SIMILAR_URLS)
            .valueChanges.subscribe(value => {
            url_protection_journal_settings_step_component_static_values_1.formControlsToUpdateOnCheckForSimilarUrls.forEach((control) => {
                this.urlProtectionPolicyJournalSettingsForm.get(control).patchValue(value);
            });
        });
    }
    saveStoreData(data) {
        this.store.dispatch(new policies_create_actions_1.SaveJournalStepDataAction(data));
    }
    checkInternalEmail() {
        return this.urlProtectionPolicyJournalSettingsForm.get('checkInternalEmail').value;
    }
    checkForSimilarUrls() {
        return this.urlProtectionPolicyJournalSettingsForm.get('checkForSimilarUrls').value;
    }
    checkScanUrlsInAttachment() {
        return this.urlProtectionPolicyJournalSettingsForm.get('scanUrlInAttachments').value;
    }
    openSidebar() {
        const asideConfig = {};
        const aside = this.asideService.open(group_selector_component_1.GroupSelectorComponent, asideConfig);
        aside.beforeClose().subscribe(selectedGroup => {
            if (selectedGroup) {
                this.store.dispatch(new actions.JournalAdministratorSelectGroup(selectedGroup));
            }
        });
    }
    onDeleteGroup() {
        this.store.dispatch(new actions.RemoveJournalAdministratorGroupAction());
    }
}
exports.UrlProtectionJournalSettingsStepComponent = UrlProtectionJournalSettingsStepComponent;
