"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchMessagesListComponent = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const exportActions = require("../../actions/export/export.actions");
const messageDetailsAction = require("../../actions/message-details/message-details.actions");
const asidePaginationAction = require("../../actions/pagination/pagination.actions");
const remediateMessagesAction = require("../../actions/remediate-messages/remediate-messages.action");
const searchAction = require("../../actions/search/search.action");
const export_1 = require("../../models/export");
const reducers = require("../../reducers");
const constants_1 = require("../../utils/constants");
const search_messages_static_values_1 = require("./search-messages.static-values");
class SearchMessagesListComponent {
    constructor(store, exportService, capabilitiesService) {
        this.store = store;
        this.exportService = exportService;
        this.capabilitiesService = capabilitiesService;
        this.externalTableSearchCount = 0;
        this.columnsToIgnore = search_messages_static_values_1.tableColumnsToIgnore;
        this.emptyValueDecorator = constants_1.emptyValueDecorator;
        this.isEmailReadable = false;
        this.externalTableSearchCount$ = new rxjs_1.BehaviorSubject(this.externalTableSearchCount);
        this.tableSelectedRows = [];
    }
    ngOnInit() {
        this.messages$ = this.store.select(reducers.searchMessages);
        this.selectedRow$ = this.store.select(reducers.getSelectedRow);
        this.selectedRowsToRemediate$ = this.store.select(reducers.selectedRowsSearchTable);
        this.selectedRowSubscription = this.selectedRow$.subscribe((row) => {
            if (row) {
                const currentIndex = this.searchResults.map(message => message.id).indexOf(row.id);
                const pagination = {
                    currentIndex,
                    prevRow: currentIndex > 0 ? this.searchResults[currentIndex - 1] : undefined,
                    nextRow: currentIndex < this.searchResults.length - 1
                        ? this.searchResults[currentIndex + 1]
                        : undefined,
                    pageSize: this.searchResults.length
                };
                this.store.dispatch(new asidePaginationAction.PaginationSet(pagination));
            }
        });
        this.messagesSubscription = this.messages$.subscribe(messages => (this.searchResults = messages));
        this.isSearchTableLoading$ = this.store.select(reducers.isSearchTableLoading);
        this.searchLoadingError$ = this.store.select(reducers.searchLoadingError);
        this.filtersMetadata$ = this.store.select(reducers.getSearchFilterMetadata);
        this.dropdownOptions = [
            {
                label: '$I18N_REMEDIATION_SEARCH_LIST.VIEW_DETAILS',
                action: (row) => this.onRowClick(row)
            }
        ];
        this.userCapabilitiesSubscription = this.capabilitiesService
            .hasCapability('Permission.ACCEPTED_EMAIL_CONTENT_VIEW')
            .pipe(operators_1.first())
            .subscribe((hasCap) => (this.isEmailReadable = hasCap));
        this.filtersMetadataSubscription = this.filtersMetadata$.subscribe(metadata => {
            if (metadata && !metadata.pagination.previous) {
                this.externalTableSearchCount$.next((this.externalTableSearchCount += 1));
            }
        });
        this.selectedRowsToRemediateSubscription = this.selectedRowsToRemediate$.subscribe(selectedRows => {
            this.tableSelectedRows = selectedRows;
            if (selectedRows && selectedRows.length === 0) {
                this.store.dispatch(new searchAction.RemediationRefreshSearch());
                if (this.rowSelectionComponent && this.rowSelectionComponent.selection) {
                    this.rowSelectionComponent.selection.clear();
                }
            }
        });
    }
    selectRows() {
        const data = this.rowSelectionComponent.selection.selected;
        this.store.dispatch(new remediateMessagesAction.SetSelectedTableRows(data));
    }
    getColumns() {
        return search_messages_static_values_1.COLUMNS;
    }
    ngOnDestroy() {
        this.selectedRowSubscription.unsubscribe();
        this.messagesSubscription.unsubscribe();
        this.filtersMetadataSubscription.unsubscribe();
    }
    onRowClick(row) {
        if (this.isEmailReadable) {
            // @ts-ignore
            this.store.dispatch(new messageDetailsAction.GetMessageDetailsAction(row));
        }
        else {
            this.store.dispatch(new messageDetailsAction.GetMessageDetailsNoEmailReadPermissionAction());
        }
    }
    onPaginatorChange(paginatorOutput) {
        this.store.dispatch(new searchAction.RemediationSearchPaginationAction(paginatorOutput));
    }
    openRemediateAllModal() {
        this.store.dispatch(new remediateMessagesAction.RemediateSearchMessagesOpenModalAction());
    }
    onExportClicked() {
        const columnMapParams = {
            // columns: copy columnList array but remove last item which is the actions column
            columns: search_messages_static_values_1.EXPORT_COLUMNS,
            translationKey: '$I18N_REMEDIATION_SEARCH_LIST.COLUMNS'
        };
        const columnConfig = {
            columnsToExport: this.exportService.mapExportedColumnsItem(columnMapParams),
            endPoint: export_1.ExportsEndPoints.search
        };
        this.store.dispatch(new exportActions.OpenPanelForExportAction(columnConfig));
    }
}
exports.SearchMessagesListComponent = SearchMessagesListComponent;
