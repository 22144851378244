"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgGetActivityChartBlockedRequestAction = exports.SwgGetActivityChartAllowedRequestAction = exports.SwgGetActivityChartTotalRequestAction = exports.SwgLoadTopBlockedCategoriesChartFailureAction = exports.SwgLoadTopBlockedCategoriesChartSuccessAction = exports.SwgLoadTopBlockedCategoriesChart = exports.SwgLoadTopBlockedDomainsChartFailureAction = exports.SwgLoadTopBlockedDomainsChartSuccessAction = exports.SwgLoadTopBlockedDomainsChart = exports.SwgLoadTopCategoriesChartFailureAction = exports.SwgLoadTopCategoriesChartSuccessAction = exports.SwgLoadTopCategoriesChart = exports.SwgLoadTopDomainsChartFailureAction = exports.SwgLoadTopDomainsChartSuccessAction = exports.SwgLoadTopDomainsChart = exports.SwgLoadActivityChartFailureAction = exports.SwgLoadActivityChartSuccessAction = exports.SwgLoadActivityChart = exports.SwgDashboardActionsEnum = void 0;
var SwgDashboardActionsEnum;
(function (SwgDashboardActionsEnum) {
    SwgDashboardActionsEnum["LOAD_ACTIVITYCHART"] = "[SwgNewDashboard] Load Activity Chart";
    SwgDashboardActionsEnum["LOAD_ACTIVITYCHART_SUCCESS"] = "[SwgNewDashboard] Load Activity Chart Success";
    SwgDashboardActionsEnum["LOAD_ACTIVITYCHART_FAILURE"] = "[SwgNewDashboard] Load Activity Chart Failure";
    SwgDashboardActionsEnum["LOAD_TOPDOMAINSCHART"] = "[SwgNewDashboard] Load Top Domains Chart";
    SwgDashboardActionsEnum["LOAD_TOPDOMAINSCHART_SUCCESS"] = "[SwgNewDashboard] Load Top Domains Chart Success";
    SwgDashboardActionsEnum["LOAD_TOPDOMAINSCHART_FAILURE"] = "[SwgNewDashboard] Load Top Domains Chart Failure";
    SwgDashboardActionsEnum["LOAD_TOPCATEGORIESCHART"] = "[SwgNewDashboard] Load Top Categories Chart";
    SwgDashboardActionsEnum["LOAD_TOPCATEGORIESCHART_SUCCESS"] = "[SwgNewDashboard] Load Top Categories Chart Success";
    SwgDashboardActionsEnum["LOAD_TOPCATEGORIESCHART_FAILURE"] = "[SwgNewDashboard] Load Top Categories Chart Failure";
    SwgDashboardActionsEnum["LOAD_TOPBLOCKEDDOMAINSCHART"] = "[SwgNewDashboard] Load Top Blocked Domains Chart";
    SwgDashboardActionsEnum["LOAD_TOPBLOCKEDDOMAINSCHART_SUCCESS"] = "[SwgNewDashboard] Load Top Blocked Domains Chart Success";
    SwgDashboardActionsEnum["LOAD_TOPBLOCKEDDOMAINSCHART_FAILURE"] = "[SwgNewDashboard] Load Top Blocked Domains Chart Failure";
    SwgDashboardActionsEnum["LOAD_TOPBLOCKEDCATEGORIESCHART"] = "[SwgNewDashboard] Load Top Blocked Categories Chart";
    SwgDashboardActionsEnum["LOAD_TOPBLOCKEDCATEGORIESCHART_SUCCESS"] = "[SwgNewDashboard] Load Top Blocked Categories Chart Success";
    SwgDashboardActionsEnum["LOAD_TOPBLOCKEDCATEGORIESCHART_FAILURE"] = "[SwgNewDashboard] Load Top Blocked Categories Chart Failure";
    SwgDashboardActionsEnum["GET_ACTIVITYCHART_TOTAL_REQUESTS"] = "[SwgNewDashboard] Get Activity Chart Total Requests";
    SwgDashboardActionsEnum["GET_ACTIVITYCHART_ALLOWED_REQUESTS"] = "[SwgNewDashboard] Get Activity Chart Allowed Requests";
    SwgDashboardActionsEnum["GET_ACTIVITYCHART_BLOCKED_REQUESTS"] = "[SwgNewDashboard] Get Activity Chart Blocked Requests";
})(SwgDashboardActionsEnum = exports.SwgDashboardActionsEnum || (exports.SwgDashboardActionsEnum = {}));
class SwgLoadActivityChart {
    constructor() {
        this.type = SwgDashboardActionsEnum.LOAD_ACTIVITYCHART;
    }
}
exports.SwgLoadActivityChart = SwgLoadActivityChart;
class SwgLoadActivityChartSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgDashboardActionsEnum.LOAD_ACTIVITYCHART_SUCCESS;
    }
}
exports.SwgLoadActivityChartSuccessAction = SwgLoadActivityChartSuccessAction;
class SwgLoadActivityChartFailureAction {
    constructor() {
        this.type = SwgDashboardActionsEnum.LOAD_ACTIVITYCHART_FAILURE;
    }
}
exports.SwgLoadActivityChartFailureAction = SwgLoadActivityChartFailureAction;
class SwgLoadTopDomainsChart {
    constructor() {
        this.type = SwgDashboardActionsEnum.LOAD_TOPDOMAINSCHART;
    }
}
exports.SwgLoadTopDomainsChart = SwgLoadTopDomainsChart;
class SwgLoadTopDomainsChartSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgDashboardActionsEnum.LOAD_TOPDOMAINSCHART_SUCCESS;
    }
}
exports.SwgLoadTopDomainsChartSuccessAction = SwgLoadTopDomainsChartSuccessAction;
class SwgLoadTopDomainsChartFailureAction {
    constructor() {
        this.type = SwgDashboardActionsEnum.LOAD_TOPDOMAINSCHART_FAILURE;
    }
}
exports.SwgLoadTopDomainsChartFailureAction = SwgLoadTopDomainsChartFailureAction;
class SwgLoadTopCategoriesChart {
    constructor() {
        this.type = SwgDashboardActionsEnum.LOAD_TOPCATEGORIESCHART;
    }
}
exports.SwgLoadTopCategoriesChart = SwgLoadTopCategoriesChart;
class SwgLoadTopCategoriesChartSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgDashboardActionsEnum.LOAD_TOPCATEGORIESCHART_SUCCESS;
    }
}
exports.SwgLoadTopCategoriesChartSuccessAction = SwgLoadTopCategoriesChartSuccessAction;
class SwgLoadTopCategoriesChartFailureAction {
    constructor() {
        this.type = SwgDashboardActionsEnum.LOAD_TOPCATEGORIESCHART_FAILURE;
    }
}
exports.SwgLoadTopCategoriesChartFailureAction = SwgLoadTopCategoriesChartFailureAction;
class SwgLoadTopBlockedDomainsChart {
    constructor() {
        this.type = SwgDashboardActionsEnum.LOAD_TOPBLOCKEDDOMAINSCHART;
    }
}
exports.SwgLoadTopBlockedDomainsChart = SwgLoadTopBlockedDomainsChart;
class SwgLoadTopBlockedDomainsChartSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgDashboardActionsEnum.LOAD_TOPBLOCKEDDOMAINSCHART_SUCCESS;
    }
}
exports.SwgLoadTopBlockedDomainsChartSuccessAction = SwgLoadTopBlockedDomainsChartSuccessAction;
class SwgLoadTopBlockedDomainsChartFailureAction {
    constructor() {
        this.type = SwgDashboardActionsEnum.LOAD_TOPBLOCKEDDOMAINSCHART_FAILURE;
    }
}
exports.SwgLoadTopBlockedDomainsChartFailureAction = SwgLoadTopBlockedDomainsChartFailureAction;
class SwgLoadTopBlockedCategoriesChart {
    constructor() {
        this.type = SwgDashboardActionsEnum.LOAD_TOPBLOCKEDCATEGORIESCHART;
    }
}
exports.SwgLoadTopBlockedCategoriesChart = SwgLoadTopBlockedCategoriesChart;
class SwgLoadTopBlockedCategoriesChartSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgDashboardActionsEnum.LOAD_TOPBLOCKEDCATEGORIESCHART_SUCCESS;
    }
}
exports.SwgLoadTopBlockedCategoriesChartSuccessAction = SwgLoadTopBlockedCategoriesChartSuccessAction;
class SwgLoadTopBlockedCategoriesChartFailureAction {
    constructor() {
        this.type = SwgDashboardActionsEnum.LOAD_TOPBLOCKEDCATEGORIESCHART_FAILURE;
    }
}
exports.SwgLoadTopBlockedCategoriesChartFailureAction = SwgLoadTopBlockedCategoriesChartFailureAction;
class SwgGetActivityChartTotalRequestAction {
    constructor() {
        this.type = SwgDashboardActionsEnum.GET_ACTIVITYCHART_TOTAL_REQUESTS;
    }
}
exports.SwgGetActivityChartTotalRequestAction = SwgGetActivityChartTotalRequestAction;
class SwgGetActivityChartAllowedRequestAction {
    constructor() {
        this.type = SwgDashboardActionsEnum.GET_ACTIVITYCHART_ALLOWED_REQUESTS;
    }
}
exports.SwgGetActivityChartAllowedRequestAction = SwgGetActivityChartAllowedRequestAction;
class SwgGetActivityChartBlockedRequestAction {
    constructor() {
        this.type = SwgDashboardActionsEnum.GET_ACTIVITYCHART_BLOCKED_REQUESTS;
    }
}
exports.SwgGetActivityChartBlockedRequestAction = SwgGetActivityChartBlockedRequestAction;
