"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApPolicyJournalSettingsStepComponent = void 0;
const core_1 = require("@angular/core");
const ap_policy_journal_settings_static_values_1 = require("./ap-policy-journal-settings-static-values");
const group_selector_component_1 = require("../../../../../../components/group-selector/group-selector.component");
const actions = require("../../../actions/policy.actions");
const operators_1 = require("rxjs/operators");
const reducers = require("../../../reducers");
class ApPolicyJournalSettingsStepComponent {
    constructor(fb, asideService, store) {
        this.fb = fb;
        this.asideService = asideService;
        this.store = store;
        this.UserMailboxActionOptions = ap_policy_journal_settings_static_values_1.UserMailboxActionOptions;
        this.UserMailboxFallbackActionOptions = ap_policy_journal_settings_static_values_1.UserMailboxFallbackActionOptions;
        this.updateApPolicyJournalType = new core_1.EventEmitter();
        this.apJournalSettingsForm = this.fb.group({
            enableJournalCheck: [''],
            userMailboxAction: [''],
            userMailboxFallbackAction: [''],
            internalSenderJournal: [''],
            internalRecipientJournal: ['']
        });
    }
    openSidebar() {
        const asideConfig = {};
        const aside = this.asideService.open(group_selector_component_1.GroupSelectorComponent, asideConfig);
        aside.beforeClose().subscribe(selectedGroup => {
            if (selectedGroup) {
                this.store.dispatch(new actions.JournalSelectGroup(selectedGroup));
            }
        });
    }
    ngOnInit() {
        this.apJournalSettingsForm.patchValue(this.policyJournal);
        this.apJournalSettingsFormSubscription = this.apJournalSettingsForm.valueChanges
            .pipe(operators_1.debounceTime(200))
            .subscribe(() => {
            this.updateJournalStore();
        });
        this.addedJournalGroup$ = this.store.select(reducers.getJournalAdministratorGroup);
        this.addedJournalGroupsSubscription = this.addedJournalGroup$.subscribe(group => {
            this.selectGroupJournal = group;
        });
    }
    updateJournalStore() {
        this.updateApPolicyJournalType.emit(this.apJournalSettingsForm.value);
    }
    onDeleteGroup(row) {
        this.store.dispatch(new actions.RemoveJournalGroupAction(row));
    }
    ngOnDestroy() {
        this.addedJournalGroupsSubscription.unsubscribe();
        this.apJournalSettingsFormSubscription.unsubscribe();
    }
}
exports.ApPolicyJournalSettingsStepComponent = ApPolicyJournalSettingsStepComponent;
