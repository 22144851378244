"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewLexiconComponent = void 0;
const core_1 = require("@angular/core");
const lexicon_1 = require("app-new/archive/supervision/model/lexicon/lexicon");
class ViewLexiconComponent {
    constructor(overlayRef) {
        this.overlayRef = overlayRef;
        this.onLoadDetails = new core_1.EventEmitter();
        this.onEdit = new core_1.EventEmitter();
        this.onDelete = new core_1.EventEmitter();
        this.lexiconSearchType = lexicon_1.LexiconSearchType;
    }
    ngOnInit() { }
    onClose() {
        this.overlayRef.close();
    }
    edit() {
        this.onClose();
        this.onEdit.emit(this.paginationInfo.currentEntity);
    }
    deleteLexicon(selectedLexicon) {
        this.onClose();
        this.onDelete.emit(selectedLexicon);
    }
    loadLexiconDetails(event) {
        const lexicon = event.token;
        this.onLoadDetails.emit(lexicon);
    }
    get words() {
        return this.lexicon.searchParams.words.slice().sort((a, b) => a.localeCompare(b));
    }
    get phrases() {
        return this.lexicon.searchParams.phrases.slice().sort((a, b) => a.localeCompare(b));
    }
    get associatedRules() {
        return this.lexicon.associatedRules.slice().sort((a, b) => a.name.localeCompare(b.name));
    }
    canViewWordsAndPhrases() {
        return this.lexicon.searchType === this.lexiconSearchType.BASIC;
    }
    canViewQuery() {
        return this.lexicon.searchType === this.lexiconSearchType.ADVANCED;
    }
}
exports.ViewLexiconComponent = ViewLexiconComponent;
