<mc-modal-header
  headerTitle="{{ getHeaderTitle() }}">
</mc-modal-header>

<mc-modal-body *ngIf="data.type === 'delete'">
  <p>{{ '$I18N_DIRECTORY_SYNC_CONFIRMATION_MODAL.DELETE_MESSAGE' | translate }}</p>
</mc-modal-body>

<mc-modal-body *ngIf="data.type === 'onboarding_redirect'">
  <p data-test="onboarding-description">{{ '$I18N_DIRECTORY_SYNC_CONFIRMATION_MODAL.ONBOARDING_REDIRECT_DESCRIPTION' | translate }}</p>
</mc-modal-body>

<mc-modal-footer *ngIf="data.type === 'onboarding_redirect'; else footer_buttons">
  <button data-test="close-onboarding-button" type="button" class="btn btn-text" (click)="close()">
    {{ '$I18N_DIRECTORY_SYNC_CONFIRMATION_MODAL.GO_TO_ONBOARDING' | translate }}
  </button>
  <button data-test="stay-onboarding-button" type="button" class="btn btn-primary" (click)="stay()">
    {{ '$I18N_DIRECTORY_SYNC_CONFIRMATION_MODAL.CONTINUE_INTEGRATION' | translate }}
  </button>
</mc-modal-footer>

<ng-template #footer_buttons>
  <mc-modal-footer>
    <button data-test="close-button" type="button" class="btn btn-text" (click)="close()">
      {{ '$I18N_DIRECTORY_SYNC_CONFIRMATION_MODAL.YES_BUTTON' | translate }}
    </button>
    <button data-test="stay-button" type="button" class="btn btn-primary" (click)="stay()">
      {{ '$I18N_DIRECTORY_SYNC_CONFIRMATION_MODAL.NO_BUTTON' | translate }}
    </button>
  </mc-modal-footer>
</ng-template>