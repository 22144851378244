"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const treeContentActions = require("../actions/contents.actions");
class ContentEffects {
    constructor(actions$, treeService) {
        this.actions$ = actions$;
        this.treeService = treeService;
        /**
         * Get the emails from a mailbox folder
         */
        this.getMailboxFolderContentEmails$ = this.actions$.pipe(effects_1.ofType(treeContentActions.LOAD_EXCHANGE_FOLDER_EMAILS), operators_1.map((action) => action.payload), operators_1.switchMap((payload) => {
            return this.treeService
                .getMailboxContentEmails(payload.folderId, payload.mailbox, payload.viewType, payload.snapshotId, payload.meta)
                .pipe(operators_1.map((response) => {
                return new treeContentActions.LoadSuccessFolderEmailsAction(payload.folderId, response.first.emails, response.meta);
            }));
        }));
        /**
         * Search the emails from a mailbox folder
         */
        this.searchMailboxFolderContentEmails$ = this.actions$.pipe(effects_1.ofType(treeContentActions.LOAD_EXCHANGE_SEARCH_FOLDER_EMAILS), operators_1.map((action) => action.payload), operators_1.switchMap((payload) => {
            return this.treeService
                .searchMailboxFolderEmails(payload.admin, payload.timeZone, payload.type, payload.query, payload.mailbox, payload.folderId, payload.viewType, payload.snapshotId, payload.pageNumber, payload.meta)
                .pipe(operators_1.map((response) => {
                return new treeContentActions.LoadSuccessSearchFolderEmailsAction(response.first.items, response.meta, payload.pageNumber);
            }), operators_1.catchError(() => {
                return rxjs_1.of(new treeContentActions.LoadFailureSearchFolderEmailsAction());
            }));
        }));
        /**
         * Get the contacts from a mailbox
         */
        this.getMailboxFolderContentContacts$ = this.actions$.pipe(effects_1.ofType(treeContentActions.LOAD_EXCHANGE_FOLDER_CONTACTS), operators_1.map((action) => action.payload), operators_1.switchMap((payload) => {
            return this.treeService
                .getMailboxContentContacts(payload.folderId, payload.mailbox, payload.viewType, payload.snapshotId, payload.meta)
                .pipe(operators_1.map((response) => {
                return new treeContentActions.LoadSuccessFolderContactsAction(payload.folderId, response.first.contacts, response.meta);
            }));
        }));
        /**
         * Search the contacts from a mailbox folder
         */
        this.searchMailboxFolderContentContacts$ = this.actions$.pipe(effects_1.ofType(treeContentActions.LOAD_EXCHANGE_SEARCH_FOLDER_CONTACTS), operators_1.map((action) => action.payload), operators_1.switchMap((payload) => {
            return this.treeService
                .searchMailboxFolderContacts(payload.query, payload.mailbox, payload.folderId, payload.viewType, payload.snapshotId, payload.meta)
                .pipe(operators_1.map((response) => {
                return new treeContentActions.LoadSuccessSearchFolderContactsAction(payload.folderId, response.first.contacts, response.meta);
            }), operators_1.catchError(() => {
                return rxjs_1.of(new treeContentActions.LoadFailureSearchFolderContactsAction());
            }));
        }));
        /**
         * Get the Calendars from a mailbox
         */
        this.getMailboxFolderContentCalendars$ = this.actions$.pipe(effects_1.ofType(treeContentActions.LOAD_EXCHANGE_FOLDER_CALENDARS), operators_1.map((action) => action.payload), operators_1.switchMap((payload) => {
            return this.treeService
                .getMailboxContentCalendars(payload.folderId, payload.mailbox, payload.viewType, payload.snapshotId, payload.meta)
                .pipe(operators_1.map((response) => {
                return new treeContentActions.LoadSuccessFolderCalendarsAction(payload.folderId, response.first.calendarItems, response.meta);
            }));
        }));
        /**
         * Search the calendars from a mailbox folder
         */
        this.searchMailboxFolderContentCalendars$ = this.actions$.pipe(effects_1.ofType(treeContentActions.LOAD_EXCHANGE_SEARCH_FOLDER_CALENDARS), operators_1.map((action) => action.payload), operators_1.switchMap((payload) => {
            return this.treeService
                .searchMailboxFolderCalendars(payload.query, payload.mailbox, payload.folderId, payload.viewType, payload.snapshotId, payload.meta)
                .pipe(operators_1.map((response) => {
                return new treeContentActions.LoadSuccessSearchFolderCalendarsAction(payload.folderId, response.first.calendarItems, response.meta);
            }), operators_1.catchError(() => {
                return rxjs_1.of(new treeContentActions.LoadFailureSearchFolderCalendarsAction());
            }));
        }));
        /**
         * Get files from folder in a drive
         */
        this.getOneDriveFolderContents$ = this.actions$.pipe(effects_1.ofType(treeContentActions.LOAD_ONE_DRIVE_FILES), operators_1.map((action) => action.payload), operators_1.switchMap((payload) => {
            return this.treeService
                .getOneDriveFoldersFiles(payload.owner, payload.parentId, payload.viewType, payload.sortBy, payload.sortOrder)
                .pipe(operators_1.map((response) => {
                return new treeContentActions.LoadSuccessOneDriveFilesAction(response.first.folders, response.first.files, response.meta);
            }), operators_1.catchError(() => {
                return rxjs_1.of(new treeContentActions.LoadFailureSearchFolderContactsAction());
            }));
        }));
    }
}
__decorate([
    effects_1.Effect()
], ContentEffects.prototype, "getMailboxFolderContentEmails$", void 0);
__decorate([
    effects_1.Effect()
], ContentEffects.prototype, "searchMailboxFolderContentEmails$", void 0);
__decorate([
    effects_1.Effect()
], ContentEffects.prototype, "getMailboxFolderContentContacts$", void 0);
__decorate([
    effects_1.Effect()
], ContentEffects.prototype, "searchMailboxFolderContentContacts$", void 0);
__decorate([
    effects_1.Effect()
], ContentEffects.prototype, "getMailboxFolderContentCalendars$", void 0);
__decorate([
    effects_1.Effect()
], ContentEffects.prototype, "searchMailboxFolderContentCalendars$", void 0);
__decorate([
    effects_1.Effect()
], ContentEffects.prototype, "getOneDriveFolderContents$", void 0);
exports.ContentEffects = ContentEffects;
