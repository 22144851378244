"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessagesActionModalComponent = void 0;
const forms_1 = require("@angular/forms");
const remediateMessagesAction = require("../../actions/remediate-messages/remediate-messages.action");
const reducers = require("../../reducers");
class MessagesActionModalComponent {
    constructor(fb, swgService, store, translate, overlayRef, overlayData) {
        this.fb = fb;
        this.swgService = swgService;
        this.store = store;
        this.translate = translate;
        this.overlayRef = overlayRef;
        this.overlayData = overlayData;
        this.maxCount = 5000;
        this.showMsaRemediateOptions = false;
        this.removeFromOptions = [
            {
                value: 'recipient',
                label: this.translate.instant('$I18N_MESSAGES_MODAL_ACTION.MESSAGE_RECIPIENTS_DEVICES')
            },
            {
                value: 'all',
                label: this.translate.instant('$I18N_MESSAGES_MODAL_ACTION.ALL_DEVICES_WITH_MSA')
            }
        ];
        this.attemptDuration = [
            {
                value: '1',
                label: this.translate.instant('$I18N_MESSAGES_MODAL_ACTION.1_DAY')
            },
            {
                value: '2',
                label: this.translate.instant('$I18N_MESSAGES_MODAL_ACTION.2_DAYS')
            },
            {
                value: '3',
                label: this.translate.instant('$I18N_MESSAGES_MODAL_ACTION.3_DAYS')
            },
            {
                value: '4',
                label: this.translate.instant('$I18N_MESSAGES_MODAL_ACTION.4_DAYS')
            },
            {
                value: '5',
                label: this.translate.instant('$I18N_MESSAGES_MODAL_ACTION.5_DAYS')
            },
            {
                value: '7',
                label: this.translate.instant('$I18N_MESSAGES_MODAL_ACTION.1_WEEK')
            },
            {
                value: '14',
                label: this.translate.instant('$I18N_MESSAGES_MODAL_ACTION.2_WEEKS')
            }
        ];
    }
    ngOnInit() {
        this.showMsaRemediateOptions =
            this.swgService.hasMSAEnabled() &&
                !!this.overlayData.searchCriteria.fileHash &&
                this.overlayData.searchByAttachmentSha &&
                this.overlayData.deviceSettings.enableFileRemediation;
        this.form = this.fb.group({
            reason: [null, [forms_1.Validators.required, forms_1.Validators.maxLength(256)]],
            removeDownloadedAttachments: [false],
            removeFrom: [this.removeFromOptions[0].value],
            daysToExpire: [this.attemptDuration[0].value]
        });
        this.isRemediateMessagesLoading$ = this.store.select(reducers.isRemediateMessagesLoading);
        this.count =
            this.overlayData.count < this.maxCount ? this.overlayData.count : `${this.maxCount}+`;
        this.type = this.overlayData.type;
    }
    createIncident() {
        const createIncidentRequestPayload = {
            idList: this.overlayData.idList,
            searchCriteria: this.overlayData.searchCriteria,
            reason: this.form.value.reason,
            type: this.overlayData.type
        };
        let fileRemediation;
        if (this.showMsaRemediateOptions && this.form.value.removeDownloadedAttachments) {
            fileRemediation = {
                daysToExpire: this.form.value.daysToExpire,
                removeFrom: this.form.value.removeFrom
            };
        }
        this.store.dispatch(new remediateMessagesAction.RemediateMessagesAction(Object.assign(Object.assign({}, createIncidentRequestPayload), { fileRemediation })));
    }
    close() {
        this.overlayRef.close();
    }
}
exports.MessagesActionModalComponent = MessagesActionModalComponent;
