"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ1 = exports.ɵ0 = exports.getLoadingByFileTypes = exports.getScanCountByFileType = exports.getLoadingByApps = exports.getVerdictsDistributedByCloudApps = exports.getVerdictsByDatesLoading = exports.getVerdictsByDates = exports.isApiUpdatingEdit = exports.isChartLoading = exports.isTopProfilesLoading = exports.getTopProfiles = exports.getMonitoredAppsCount = exports.getNonSanctionedAppsCount = exports.getSanctionedAppsCount = exports.getTopCategories = exports.getTopBlockedApplications = exports.getAllBlockedApplications = exports.getTopMonitoredApplications = exports.getTopNonSanctionedApplications = exports.getTopSanctionedApplications = exports.getApplicationStatus = exports.getTopApplicationsLoading = exports.isApplicationCategorySetDataLoading = exports.getApplicationCategoriesSet = exports.getAllApplications = exports.getTopApplications = exports.reducers = void 0;
const store_1 = require("@ngrx/store");
const topApplications = require("./top-applications.reducer");
const clpData = require("./swg-clp-data.reducer");
const threatEvents = require("../threat-event/reducers/swg-threat-events.reducer");
const threatEventDetails = require("../threat-event/reducers/swg-threat-events.reducer");
exports.reducers = {
    topApplications: topApplications.reducer,
    clpData: clpData.reducer,
    threatEvents: threatEvents.reducer,
    threatEventDetails: threatEventDetails.reducer
};
const getTopApplicationsState = store_1.createFeatureSelector('swgTopApplications');
const ɵ0 = state => state.topApplications;
exports.ɵ0 = ɵ0;
const getTopApplicationsListState = store_1.createSelector(getTopApplicationsState, ɵ0);
exports.getTopApplications = store_1.createSelector(getTopApplicationsListState, topApplications.getTopApplications);
exports.getAllApplications = store_1.createSelector(getTopApplicationsListState, topApplications.getAllApplications);
exports.getApplicationCategoriesSet = store_1.createSelector(getTopApplicationsListState, topApplications.applicationCategoriesSet);
exports.isApplicationCategorySetDataLoading = store_1.createSelector(getTopApplicationsListState, topApplications.isApplicationCategorySetDataLoading);
exports.getTopApplicationsLoading = store_1.createSelector(getTopApplicationsListState, topApplications.isTopApplicationsLoading);
exports.getApplicationStatus = store_1.createSelector(getTopApplicationsListState, topApplications.getApplicationStatus);
exports.getTopSanctionedApplications = store_1.createSelector(getTopApplicationsListState, topApplications.getTopSanctionedApplications);
exports.getTopNonSanctionedApplications = store_1.createSelector(getTopApplicationsListState, topApplications.getTopNonSanctionedApplications);
exports.getTopMonitoredApplications = store_1.createSelector(getTopApplicationsListState, topApplications.getTopMonitoredApplications);
exports.getAllBlockedApplications = store_1.createSelector(getTopApplicationsListState, topApplications.getAllBlockedApplications);
exports.getTopBlockedApplications = store_1.createSelector(getTopApplicationsListState, topApplications.getTopBlockedApplications);
exports.getTopCategories = store_1.createSelector(getTopApplicationsListState, topApplications.getTopCategories);
exports.getSanctionedAppsCount = store_1.createSelector(getTopApplicationsListState, topApplications.getSanctionedAppsCount);
exports.getNonSanctionedAppsCount = store_1.createSelector(getTopApplicationsListState, topApplications.getNonSanctionedAppsCount);
exports.getMonitoredAppsCount = store_1.createSelector(getTopApplicationsListState, topApplications.getMonitoredAppsCount);
// top profiles
exports.getTopProfiles = store_1.createSelector(getTopApplicationsListState, topApplications.getTopProfiles);
exports.isTopProfilesLoading = store_1.createSelector(getTopApplicationsListState, topApplications.isTopProfilesLoading);
exports.isChartLoading = store_1.createSelector(getTopApplicationsListState, topApplications.isChartLoading);
exports.isApiUpdatingEdit = store_1.createSelector(getTopApplicationsListState, topApplications.isApiUpdatingEdit);
const ɵ1 = state => state.clpData;
exports.ɵ1 = ɵ1;
const getClpDataState = store_1.createSelector(getTopApplicationsState, ɵ1);
exports.getVerdictsByDates = store_1.createSelector(getClpDataState, clpData.getVerdictsByDates);
exports.getVerdictsByDatesLoading = store_1.createSelector(getClpDataState, clpData.getVerdictsByDatesLoading);
exports.getVerdictsDistributedByCloudApps = store_1.createSelector(getClpDataState, clpData.getVerdictsDistributedByCloudApps);
exports.getLoadingByApps = store_1.createSelector(getClpDataState, clpData.getLoadingByApps);
exports.getScanCountByFileType = store_1.createSelector(getClpDataState, clpData.getScanCountByFileType);
exports.getLoadingByFileTypes = store_1.createSelector(getClpDataState, clpData.getLoadingByFileTypes);
