"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ21 = exports.ɵ20 = exports.ɵ19 = exports.ɵ18 = exports.ɵ17 = exports.ɵ16 = exports.ɵ15 = exports.ɵ14 = exports.ɵ13 = exports.ɵ12 = exports.ɵ11 = exports.ɵ10 = exports.ɵ9 = exports.ɵ8 = exports.ɵ7 = exports.ɵ6 = exports.ɵ5 = exports.ɵ4 = exports.ɵ3 = exports.ɵ2 = exports.ɵ1 = exports.ɵ0 = exports.getLoadingState = exports.getSummaryStepData = exports.rulesContainEveryoneVale = exports.getAppliesToStepData = exports.getUserAwarenessStepConfig = exports.getUserAwarenessStepData = exports.getJournalStepData = exports.getOutboundStepData = exports.getInboundStepData = exports.getDetailsStepData = exports.getUserAwarenessCustomPageSet = exports.getUserAwarenessCustomPageSetOptions = exports.getJournalAdministratorsSelectedGroupDescription = exports.getOutboundAdministratorsSelectedGroupDescription = exports.getInboundAdministratorsSelectedGroupDescription = exports.getFromToWhereAttributes = exports.getBypassUrlProtection = exports.getWizardFlowType = exports.getCreatePolicy = exports.getPolicyFeatureState = exports.reducers = exports.getPolicyStatusChangeAction = exports.getSelectedRowFullData = exports.getUrlProtectionPolicySelectedRowFullData = exports.getPolicyData = exports.selectedRowFullDataReducer = void 0;
const store_1 = require("@ngrx/store");
const url_protection_applies_to_settings_step_models_1 = require("../components/steps/url-protection-applies-to-settings-step/url-protection-applies-to-settings-step.models");
const policiesWizard = require("./create-policies.reducer");
const listReducer = require("./policies.reducer");
exports.selectedRowFullDataReducer = {
    urlProtectionPolicySelectedRowFullData: listReducer.reducer
};
exports.getPolicyData = store_1.createFeatureSelector('urlProtectionPolicySelectedRowFullData');
const ɵ0 = state => state.urlProtectionPolicySelectedRowFullData;
exports.ɵ0 = ɵ0;
exports.getUrlProtectionPolicySelectedRowFullData = store_1.createSelector(exports.getPolicyData, ɵ0);
const ɵ1 = state => state.selectedRowFullData;
exports.ɵ1 = ɵ1;
exports.getSelectedRowFullData = store_1.createSelector(exports.getUrlProtectionPolicySelectedRowFullData, ɵ1);
const ɵ2 = state => state.policyStatusChanged;
exports.ɵ2 = ɵ2;
exports.getPolicyStatusChangeAction = store_1.createSelector(exports.getUrlProtectionPolicySelectedRowFullData, ɵ2);
exports.reducers = {
    urlProtectionCreatePolicyWizard: policiesWizard.reducer
};
exports.getPolicyFeatureState = store_1.createFeatureSelector('urlProtectionCreatePolicyWizard');
const ɵ3 = state => state.urlProtectionCreatePolicyWizard;
exports.ɵ3 = ɵ3;
exports.getCreatePolicy = store_1.createSelector(exports.getPolicyFeatureState, ɵ3);
const ɵ4 = state => state.urlProtectionCreatePolicyWizard.wizardFlowType;
exports.ɵ4 = ɵ4;
exports.getWizardFlowType = store_1.createSelector(exports.getPolicyFeatureState, ɵ4);
const ɵ5 = state => state.detailsStep.data.bypassPolicy;
exports.ɵ5 = ɵ5;
exports.getBypassUrlProtection = store_1.createSelector(exports.getCreatePolicy, ɵ5);
const ɵ6 = state => state.appliesToStep.stepConfiguration;
exports.ɵ6 = ɵ6;
exports.getFromToWhereAttributes = store_1.createSelector(exports.getCreatePolicy, ɵ6);
const ɵ7 = state => state.inboundStep.data.administratorsSelectedGroup &&
    state.inboundStep.data.administratorsSelectedGroup.description;
exports.ɵ7 = ɵ7;
exports.getInboundAdministratorsSelectedGroupDescription = store_1.createSelector(exports.getCreatePolicy, ɵ7);
const ɵ8 = state => state.outboundStep.data.administratorsSelectedGroup &&
    state.outboundStep.data.administratorsSelectedGroup.description;
exports.ɵ8 = ɵ8;
exports.getOutboundAdministratorsSelectedGroupDescription = store_1.createSelector(exports.getCreatePolicy, ɵ8);
const ɵ9 = state => state.journalStep.data.administratorsSelectedGroup &&
    state.journalStep.data.administratorsSelectedGroup.description;
exports.ɵ9 = ɵ9;
exports.getJournalAdministratorsSelectedGroupDescription = store_1.createSelector(exports.getCreatePolicy, ɵ9);
const ɵ10 = state => state.userAwarenessStep.stepConfiguration.pageSetOptions;
exports.ɵ10 = ɵ10;
exports.getUserAwarenessCustomPageSetOptions = store_1.createSelector(exports.getCreatePolicy, ɵ10);
const ɵ11 = state => state.userAwarenessStep.data.customPageSet;
exports.ɵ11 = ɵ11;
exports.getUserAwarenessCustomPageSet = store_1.createSelector(exports.getCreatePolicy, ɵ11);
const ɵ12 = state => state.detailsStep.data;
exports.ɵ12 = ɵ12;
exports.getDetailsStepData = store_1.createSelector(exports.getCreatePolicy, ɵ12);
const ɵ13 = state => state.inboundStep.data;
exports.ɵ13 = ɵ13;
exports.getInboundStepData = store_1.createSelector(exports.getCreatePolicy, ɵ13);
const ɵ14 = state => state.outboundStep.data;
exports.ɵ14 = ɵ14;
exports.getOutboundStepData = store_1.createSelector(exports.getCreatePolicy, ɵ14);
const ɵ15 = state => state.journalStep.data;
exports.ɵ15 = ɵ15;
exports.getJournalStepData = store_1.createSelector(exports.getCreatePolicy, ɵ15);
const ɵ16 = state => state.userAwarenessStep.data;
exports.ɵ16 = ɵ16;
exports.getUserAwarenessStepData = store_1.createSelector(exports.getCreatePolicy, ɵ16);
const ɵ17 = state => state.userAwarenessStep.stepConfiguration;
exports.ɵ17 = ɵ17;
exports.getUserAwarenessStepConfig = store_1.createSelector(exports.getCreatePolicy, ɵ17);
const ɵ18 = state => state.appliesToStep.data;
exports.ɵ18 = ɵ18;
exports.getAppliesToStepData = store_1.createSelector(exports.getCreatePolicy, ɵ18);
const ɵ19 = state => state.appliesToStep.data &&
    !!state.appliesToStep.data.find(rule => rule.appliesTo === url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.ALL && rule.appliesFrom === url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.ALL);
exports.ɵ19 = ɵ19;
exports.rulesContainEveryoneVale = store_1.createSelector(exports.getCreatePolicy, ɵ19);
const ɵ20 = state => state.summaryStep.data;
exports.ɵ20 = ɵ20;
exports.getSummaryStepData = store_1.createSelector(exports.getCreatePolicy, ɵ20);
const ɵ21 = state => state.isLoading;
exports.ɵ21 = ɵ21;
exports.getLoadingState = store_1.createSelector(exports.getCreatePolicy, ɵ21);
