"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttributesService = void 0;
const operators_1 = require("rxjs/operators");
const BASE_URL = '/api/attribute';
class AttributesService {
    constructor(http) {
        this.http = http;
    }
    getAttributes(config) {
        return this.http.post(`${BASE_URL}/find-attribute-types`, config);
    }
    getAttributeDetails(config) {
        return this.http
            .post(`${BASE_URL}/get-attribute-type`, config)
            .pipe(operators_1.map((response) => response.first));
    }
    updateAttribute(attribute) {
        return this.http
            .post(`${BASE_URL}/update-attribute-type`, attribute)
            .pipe(operators_1.map((response) => response.first));
    }
    createAttribute(attribute) {
        delete attribute.id;
        return this.http
            .post(`${BASE_URL}/create-attribute-type`, attribute)
            .pipe(operators_1.map((response) => response.first));
    }
    deleteAttribute(id) {
        return this.http
            .post(`${BASE_URL}/delete-attribute-type`, { id })
            .pipe(operators_1.map((response) => response.first));
    }
}
exports.AttributesService = AttributesService;
