"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ5 = exports.ɵ4 = exports.ɵ3 = exports.ɵ2 = exports.ɵ1 = exports.ɵ0 = exports.selectPaginatorData = exports.getPreviousAndNextSelectedRow = exports.selectIsGraphDataLoading = exports.selectGraphData = exports.selectPagingMetadata = exports.selectIsLoading = exports.selectThreatEvents = exports.reducer = exports.initialState = void 0;
const swg_threat_events_actions_1 = require("../actions/swg-threat-events.actions");
const store_1 = require("@ngrx/store");
const swg_threat_event_details_reducer_1 = require("./swg-threat-event-details.reducer");
const moment = require("moment");
exports.initialState = {
    threatEvents: [],
    graphData: [],
    isLoading: false,
    isGraphLoading: false,
    paginatorMetadata: {
        pageToken: undefined,
        pageSize: 20,
        pageNumber: 0
    }
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case swg_threat_events_actions_1.SwgLoadThreatEventsActionsEnum.LOAD_THREAT_EVENTS:
            return Object.assign(Object.assign({}, state), { isLoading: true, threatEvents: [] });
        case swg_threat_events_actions_1.SwgLoadThreatEventsActionsEnum.LOAD_THREAT_EVENTS_SUCCESS:
            return Object.assign(Object.assign({}, state), { isLoading: false, threatEvents: action.payload
                    .slice()
                    .sort((a, b) => moment(b.timestamp).unix() - moment(a.timestamp).unix()), paginatorMetadata: Object.assign(Object.assign({}, state.paginatorMetadata), { pageNumber: 0, pageToken: undefined }) });
        case swg_threat_events_actions_1.SwgLoadThreatEventsActionsEnum.LOAD_THREAT_EVENTS_FAILURE:
            return Object.assign(Object.assign({}, state), { isLoading: false, threatEvents: [] });
        case swg_threat_events_actions_1.SwgLoadThreatEventsActionsEnum.UPDATE_THREAT_EVENT:
            const index = state.threatEvents.findIndex(t => t.id === action.payload.id);
            const updatedThreatEvent = {};
            Object.keys(state.threatEvents[index]).forEach(key => (updatedThreatEvent[key] = action.payload[key]));
            return Object.assign(Object.assign({}, state), { threatEvents: [
                    ...state.threatEvents.slice(0, index),
                    updatedThreatEvent,
                    ...state.threatEvents.slice(index + 1, state.threatEvents.length)
                ] });
        case swg_threat_events_actions_1.SwgLoadThreatEventsActionsEnum.LOAD_THREAT_EVENTS_GRAPH:
            return Object.assign(Object.assign({}, state), { isGraphLoading: true, graphData: [] });
        case swg_threat_events_actions_1.SwgLoadThreatEventsActionsEnum.LOAD_THREAT_EVENTS_GRAPH_SUCCESS:
            return Object.assign(Object.assign({}, state), { isGraphLoading: false, graphData: action.payload });
        case swg_threat_events_actions_1.SwgLoadThreatEventsActionsEnum.LOAD_THREAT_EVENTS_GRAPH_FAILURE:
            return Object.assign(Object.assign({}, state), { isGraphLoading: false, graphData: [] });
        case swg_threat_events_actions_1.SwgLoadThreatEventsActionsEnum.PAGINATE_THREAT_EVENTS:
            return Object.assign(Object.assign({}, state), { isLoading: true, paginatorMetadata: Object.assign(Object.assign({}, state.paginatorMetadata), action.payload) });
        case swg_threat_events_actions_1.SwgLoadThreatEventsActionsEnum.PAGINATE_THREAT_EVENTS_COMPLETED:
            return Object.assign(Object.assign({}, state), { isLoading: false });
        default:
            return state;
    }
}
exports.reducer = reducer;
function getCurrentPage(events, pagination) {
    const first = pagination.pageNumber * pagination.pageSize;
    const last = first + pagination.pageSize;
    return events.slice(first, last);
}
const getThreatEvents = (state) => getCurrentPage(state.threatEvents, state.paginatorMetadata);
const ɵ0 = getThreatEvents;
exports.ɵ0 = ɵ0;
const selectThreatEventsState = store_1.createFeatureSelector('swgThreatEvents');
const ɵ1 = state => getThreatEvents(state);
exports.ɵ1 = ɵ1;
exports.selectThreatEvents = store_1.createSelector(selectThreatEventsState, ɵ1);
const ɵ2 = state => state.isLoading;
exports.ɵ2 = ɵ2;
exports.selectIsLoading = store_1.createSelector(selectThreatEventsState, ɵ2);
const ɵ3 = (state) => {
    const numberOfPages = Math.ceil(state.threatEvents.length / state.paginatorMetadata.pageSize);
    const currentPageNumber = state.paginatorMetadata.pageNumber;
    const isLastPage = currentPageNumber + 1 >= numberOfPages;
    const metadata = {
        pagination: {
            previous: currentPageNumber > 0 ? 'previous' : undefined,
            next: !isLastPage ? 'next' : undefined,
            pageSize: getThreatEvents(state).length,
            totalRows: state.threatEvents.length
        }
    };
    return metadata;
};
exports.ɵ3 = ɵ3;
exports.selectPagingMetadata = store_1.createSelector(selectThreatEventsState, ɵ3);
const ɵ4 = (state) => state.graphData;
exports.ɵ4 = ɵ4;
exports.selectGraphData = store_1.createSelector(selectThreatEventsState, ɵ4);
const ɵ5 = (state) => state.isGraphLoading;
exports.ɵ5 = ɵ5;
exports.selectIsGraphDataLoading = store_1.createSelector(selectThreatEventsState, ɵ5);
const getPreviousAndNextSelectedRow = (listState, detailsState) => {
    const list = listState.threatEvents;
    const selectedRow = detailsState.opened || { id: '' };
    const index = selectedRow && list.findIndex(row => row.id === selectedRow.id);
    const result = {
        currentItemIndex: index + 1,
        pageSize: list.length,
        previous: index >= 0 ? list[index - 1] : undefined,
        next: index >= 0 ? list[index + 1] : undefined
    };
    return result;
};
exports.getPreviousAndNextSelectedRow = getPreviousAndNextSelectedRow;
exports.selectPaginatorData = store_1.createSelector([selectThreatEventsState, swg_threat_event_details_reducer_1.selectThreatEventDetailsState], exports.getPreviousAndNextSelectedRow);
