"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
angular.module('services.services.server-connections-edit-guard', [])
    .factory('serverConnectionEditGuard', ['$q', 'serverConnectionService', function ($q, serverConnectionService) {
        const self = this;
        self.serverConnectionResponse = null;
        self.getServerConnection = (serverConnectionID) => {
            return serverConnectionService.getServerConnection(serverConnectionID).then((response) => {
                self.serverConnectionResponse = response.first;
                return response.first;
            }, (error) => {
                // @ts-ignore
                serverConnectionService.getError().showErrorNotification(response);
                return $q.reject(error);
            }, function () {
                serverConnectionService.getError().showErrorNotification();
            });
        };
        self.reset = () => {
            self.serverConnectionResponse = null;
            return self.serverConnectionResponse;
        };
        return self;
    }]);
