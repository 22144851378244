<div class="mc-url-protection-policy-user-awareness-block">
  <mc-wizard-step-header label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.USER_AWARENESS_SETTINGS.STEP_TITLE">
    <span>{{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.USER_AWARENESS_SETTINGS.STEP_DESCRIPTION' | translate}}</span>
  </mc-wizard-step-header>

  <form class="form-horizontal" mcDefaultLabelClass="col-sm-3" mcDefaultControlContainerClass="col-sm-9"
    [formGroup]="showUserAwarenessChallengesForm">
    <mc-field class="mc-custom-label" data-cy-test="show-userAwareness-challenges"
      label="{{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.USER_AWARENESS_SETTINGS.LABELS.SHOW' | translate}}">
      <mc-switch formControlName="showUserAwarenessChallenges" role="switch" showLabel="true">
      </mc-switch>
    </mc-field>
  </form>

  <div *ngIf="showUserAwarenessForm()">
    <div class="mc-detailed-list-label slider-custom-label form-group row" data-unit-test="challenge-percentage" data-cy-test="challenge-percentage">
      <mc-tooltip class="control-label col-sm-3" placement="top"
        popoverContent="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.USER_AWARENESS_SETTINGS.TOOLTIPS.PERCENTAGE' | translate}}"
        labelText="{{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.USER_AWARENESS_SETTINGS.LABELS.PERCENTAGE' | translate}}">
      </mc-tooltip>
      <div class="col-sm-9 mc-detailed-list-column mc-custom-slider">
        <ngx-slider
          [(value)]="challengePercentage"
          [options]="challengePercentageOptions"
          (valueChange)="onChallengePercentageValueChange()"></ngx-slider>
      </div>
    </div>
    <form class="form-horizontal" mcDefaultLabelClass="col-sm-3" mcDefaultControlContainerClass="col-sm-9"
      [formGroup]="urlProtectionPolicyUserAwarenessForm">
      <mc-field class="mc-custom-label" data-unit-test="dynamic-adjustment" data-cy-test="dynamic-adjustment"
        label="{{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.USER_AWARENESS_SETTINGS.LABELS.DYNAMIC_ADJUSTMENT' | translate}}"
        popoverPlacement="top"
        helpPopoverContent="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.USER_AWARENESS_SETTINGS.TOOLTIPS.DYNAMIC_ADJUSTMENT' | translate }}">
        <mc-switch formControlName="dynamicAdjustment" role="switch" showLabel="true">
        </mc-switch>
      </mc-field>
      <mc-field *ngIf="!isAAAOrFAAUser" class="mc-custom-label" data-unit-test="custom-page-set"
        label="{{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.USER_AWARENESS_SETTINGS.LABELS.CUSTOM_PAGE_SET' | translate}}"
        popoverPlacement="top"
        [helpPopoverContent]="customPageSetTooltipValue()">
        <mc-switch formControlName="customPageSet" role="switch" showLabel="true" [isDisabled]="pageSetOptions.length === 0">
        </mc-switch>
      </mc-field>

      <ng-container *ngIf="showCustomPageSet()">
        <mc-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.USER_AWARENESS_SETTINGS.LABELS.PAGE_SET"
          data-unit-test="page-set-options" data-cy-test="page-set-options">
          <mc-select formControlName="pageSetOption" [defaultValue]="pageSetEditValue" [options]="pageSetOptions" class="row-element">
          </mc-select>
        </mc-field>
      </ng-container>
    </form>
  </div>
</div>
