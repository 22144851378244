"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomDomainBlockService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const table_store_1 = require("@mimecast-ui/table-store");
const custom_domain_block_factory_1 = require("../factory/custom-domain-block.factory");
class CustomDomainBlockService {
    constructor(http) {
        this.http = http;
    }
    getData(payload, pagination = table_store_1.defaultPaginationOption) {
        return this.http
            .post('/api/policy/custom-domain-block/find-policies', Object.assign(Object.assign({}, payload), { meta: { pagination } }))
            .pipe(operators_1.map((response) => {
            let RETURN_DATA;
            if (response.hasErrors) {
                RETURN_DATA = {
                    hasErrors: response.hasErrors,
                    data: response.all,
                    failures: response.fail,
                    meta: response.meta
                };
            }
            else {
                RETURN_DATA = {
                    hasErrors: response.hasErrors,
                    data: response.first.items.map((customDomainBlock) => custom_domain_block_factory_1.CustomDomainBlockFactory.BUILD_CUSTOM_DOMAIN_BLOCK(customDomainBlock)),
                    failures: response.fail,
                    meta: response.meta
                };
            }
            return RETURN_DATA;
        }), operators_1.catchError((response) => {
            const RETURN_DATA = {
                hasErrors: response.hasErrors,
                data: response.all,
                failures: response.fail,
                meta: response.meta
            };
            return rxjs_1.of(RETURN_DATA);
        }));
    }
    filter(filter, pagination) {
        return this.getData(filter, pagination);
    }
    getPolicyDetails(id) {
        return this.http.post('/api/policy/custom-domain-block/get-policy', { data: [{ id }] }).pipe(operators_1.map((response) => {
            return response;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    createPolicy(policy) {
        return this.http.post('/api/policy/custom-domain-block/create-policy', { data: [policy] }).pipe(operators_1.map((response) => {
            return response;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    updatePolicy(policy) {
        return this.http.post('/api/policy/custom-domain-block/update-policy', { data: [policy] }).pipe(operators_1.map((response) => {
            return response;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    deletePolicy(id) {
        return this.http.post('/api/policy/custom-domain-block/delete-policy', { data: [{ id }] }).pipe(operators_1.map((response) => {
            return response;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
}
exports.CustomDomainBlockService = CustomDomainBlockService;
CustomDomainBlockService.CUSTOM_DOMAIN_BLOCK_TABLE = 'custom-domain-block-table';
CustomDomainBlockService.WIZARD_ID = 'custom-domain-block-wizard';
