"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildApiPayload = exports.populateWizard = exports.resetWizard = exports.reducers = void 0;
const wizardActions = require("../../actions/policy-wizard/wizard.actions");
const policy_1 = require("../../models/policy");
exports.reducers = [resetWizard, populateWizard, buildApiPayload];
function resetWizard(reducer) {
    return function resetReducer(state, action) {
        switch (action.type) {
            case wizardActions.RESET:
                state = undefined;
                break;
        }
        return reducer(state, action);
    };
}
exports.resetWizard = resetWizard;
function populateWizard(reducer) {
    return function populateWizardReducer(state, action) {
        switch (action.type) {
            case wizardActions.LOAD_POLICY_SUCCESS:
                const policy = action.payload;
                let ipAddresses = [];
                let ipAddressesDisplayText = '';
                if (policy.policyDetails.locationSettings.permission !== policy_1.LocationPermission.NONE) {
                    ipAddresses = policy.policyDetails.locationSettings.ipValue.map(value => ({ ip: value }));
                    ipAddressesDisplayText = policy.policyDetails.locationSettings.ipValue.join(', ');
                }
                let appliedToEveryone = true;
                let groups = [];
                let groupsDisplayText = '';
                if (policy.groups) {
                    appliedToEveryone = false;
                    // @ts-ignore
                    groups = policy.groups;
                    // @ts-ignore
                    groupsDisplayText = policy.groups.map(folder => folder.description).join(', ');
                }
                state = Object.assign(Object.assign({}, state), { detailsStep: {
                        name: policy.name,
                        description: policy.description
                    }, authenticationMethodsStep: {
                        domain: policy.policyDetails.delegatedAuthOption,
                        cloudAuthentication: policy.policyDetails.cloudPasswordOption,
                        casServer: policy.policyDetails.ewsSettings
                            ? policy.policyDetails.ewsSettings.ewsCasEndpoint
                            : '',
                        alternateDomainSuffix: policy.policyDetails.ewsSettings
                            ? policy.policyDetails.ewsSettings.ewsDomainOverride
                            : '',
                        mimeAuthEnabled: policy.policyDetails.secondFactorOption === policy_1.SecondFactorOption.MIMEAUTH,
                        secondFactorOption: policy.policyDetails.secondFactorOption,
                        requestSecondFactorFirst: policy.policyDetails.secondFactorFirst
                    }, ipAddressesStep: {
                        permissionType: policy.policyDetails.locationSettings.permission,
                        ipAddresses,
                        ipAddressesDisplayText
                    }, sessionTimeoutStep: {
                        expirationTime: {
                            expirationTimeValue: policy.policyDetails.expirationTime.expirationTimeValue,
                            expirationTimeType: policy.policyDetails.expirationTime.expirationTimeType
                        }
                    }, appliesToStep: {
                        appliedToEveryone,
                        groups,
                        groupsDisplayText
                    } });
                break;
        }
        return reducer(state, action);
    };
}
exports.populateWizard = populateWizard;
function buildApiPayload(reducer) {
    return function buildApiPayloadReducer(state, action) {
        switch (action.type) {
            case wizardActions.CREATE:
                action.request = buildBasePayload();
                break;
            case wizardActions.UPDATE:
                action.request = Object.assign(Object.assign({}, buildBasePayload()), { id: state.wizard.policy.id });
                break;
        }
        function buildBasePayload() {
            let ewsSettings = null;
            let groups = null;
            const locationSettings = {
                permission: state.ipAddressesStep.permissionType
            };
            if (locationSettings.permission !== policy_1.LocationPermission.NONE) {
                locationSettings.ipValue = state.ipAddressesStep.ipAddresses.map((ip) => ip.ip);
            }
            if (state.authenticationMethodsStep.domain === policy_1.DelegatedAuthOption.EWS) {
                if (state.authenticationMethodsStep.alternateDomainSuffix.length > 0) {
                    ewsSettings = {
                        ewsCasEndpoint: state.authenticationMethodsStep.casServer,
                        ewsDomainOverride: state.authenticationMethodsStep.alternateDomainSuffix
                    };
                }
                else {
                    ewsSettings = {
                        ewsCasEndpoint: state.authenticationMethodsStep.casServer
                    };
                }
            }
            if (!state.appliesToStep.appliedToEveryone) {
                groups = state.appliesToStep.groups.map((group) => group.id);
            }
            const payload = {
                name: state.detailsStep.name,
                description: state.detailsStep.description,
                groups,
                policyDetails: {
                    delegatedAuthOption: state.authenticationMethodsStep.domain,
                    ewsSettings,
                    cloudPasswordOption: state.authenticationMethodsStep.cloudAuthentication,
                    secondFactorOption: state.authenticationMethodsStep.mimeAuthEnabled
                        ? policy_1.SecondFactorOption.MIMEAUTH
                        : state.authenticationMethodsStep.secondFactorOption,
                    secondFactorFirst: state.authenticationMethodsStep.mimeAuthEnabled ||
                        state.authenticationMethodsStep.secondFactorOption === policy_1.SecondFactorOption.NONE
                        ? false
                        : state.authenticationMethodsStep.requestSecondFactorFirst,
                    locationSettings,
                    expirationTime: {
                        expirationTimeValue: state.sessionTimeoutStep.expirationTime.expirationTimeValue,
                        expirationTimeType: state.sessionTimeoutStep.expirationTime.expirationTimeType
                    }
                }
            };
            return payload;
        }
        return reducer(state, action);
    };
}
exports.buildApiPayload = buildApiPayload;
