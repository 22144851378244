"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SettingsWidgetComponent = void 0;
const reducers = require("../../reducers");
class SettingsWidgetComponent {
    constructor(tabs, store, translateService) {
        this.tabs = tabs;
        this.store = store;
        this.translateService = translateService;
    }
    ngOnInit() {
        this.settings$ = this.store.select(reducers.getSettings);
        this.settingsSubscription = this.settings$.subscribe(settings => {
            this.status = settings.enabled
                ? '$I18N_REMEDIATION.SETTINGS.REMEDIATION_ENABLED'
                : '$I18N_REMEDIATION.SETTINGS.REMEDIATION_DISABLED';
            this.mode = `$I18N_REMEDIATION.SETTINGS.${settings.mode.toUpperCase()}`;
        });
        this.isLoaded$ = this.store.select(reducers.areSettingsLoaded);
        this.hasGenericError$ = this.store.select(reducers.hasGenericError);
        this.inlineNotificationConfigError = {
            status: 'error',
            msg: this.translateService.instant('$I18N_REMEDIATION.ERRORS.GENERIC')
        };
    }
    ngOnDestroy() {
        this.settingsSubscription.unsubscribe();
    }
    openSettings() {
        if (!this.hasSearchTabEnabled) {
            this.tabs.tabClicked(3);
        }
        else {
            this.tabs.tabClicked(4);
        }
    }
}
exports.SettingsWidgetComponent = SettingsWidgetComponent;
