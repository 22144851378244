"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgThreatEventDetailsContainerComponent = void 0;
const swg_threat_event_details_reducer_1 = require("../../reducers/swg-threat-event-details.reducer");
const swg_threat_events_reducer_1 = require("../../reducers/swg-threat-events.reducer");
const swg_threat_event_details_actions_1 = require("../../actions/swg-threat-event-details.actions");
const operators_1 = require("rxjs/operators");
class SwgThreatEventDetailsContainerComponent {
    constructor(store, swgService, capsService) {
        this.store = store;
        this.swgService = swgService;
        this.capsService = capsService;
    }
    ngOnInit() {
        this.threatEvent$ = this.store.select(swg_threat_event_details_reducer_1.selectOpened);
        this.isLoading$ = this.store.select(swg_threat_event_details_reducer_1.selectOpenedIsLoading);
        this.paginatorData$ = this.store.select(swg_threat_events_reducer_1.selectPaginatorData);
        this.hasEditPermissions$ = this.capsService.evalCapabilitiesExpression('Permission.CLOUD_FILE_PROTECT_POLICIES_EDIT');
    }
    closeAside() {
        this.store.dispatch(new swg_threat_event_details_actions_1.SwgThreatEventsSidebarCloseAction());
    }
    navigate(event) {
        this.store.dispatch(new swg_threat_event_details_actions_1.SwgLoadThreatEventDetailsAction(event.token.id));
    }
    manualQuarantine(eventId) {
        this.store.dispatch(new swg_threat_event_details_actions_1.SwgThreatEventsManualQuarantineConfirmationAction(eventId));
    }
    release(eventId) {
        this.store.dispatch(new swg_threat_event_details_actions_1.SwgThreatEventsReleaseConfirmationAction(eventId));
    }
    download() {
        this.store.dispatch(new swg_threat_event_details_actions_1.SwgDownloadThreatEventModalOpen());
    }
    reportFalsePositive(eventId) {
        this.store.dispatch(new swg_threat_event_details_actions_1.SwgThreatEventsReportFalsePositiveConfirmationAction(eventId));
    }
    hasActionButtonsEnabled() {
        return this.hasEditPermissions$.pipe(operators_1.map(enabled => enabled && this.swgService.hasCLPBlockAndQuarantineActionEnabled()));
    }
}
exports.SwgThreatEventDetailsContainerComponent = SwgThreatEventDetailsContainerComponent;
