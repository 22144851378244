<div class="panel-body">
    <mc-chart class="mc-group-held-messages" *ngIf="!(isLoading$ | async); else loadingChart">
        <mc-pie-chart 
            class="mc-amchart" 
            [chartData$]="chartData$"
            (clickedPie)="clickedPie($event)"
        ></mc-pie-chart>
    </mc-chart>
</div>

<ng-template #loadingChart class="parent">
    <div class="mc-with-spinner">
        <mc-spinner></mc-spinner>
    </div>
</ng-template>
      