<div class="mc-search-box">
  <form
    (ngSubmit)="search()"
    class="search-form" novalidate>
      <div class="rows">
        <div class="col-xs-12 mc-form-fixed mc-form-height-fixed" >
         
          <div class="col-xs-3">

            <label class="control-label">{{'$I18N_ACCOUNT_IP_CHECKER_SEARCH.LABEL.TARGET' | translate}}</label> 
            <mc-dropdown-autocomplete-checkboxes
              label="$I18N_ACCOUNT_IP_CHECKER_SEARCH.LABEL.ALL" 
              [optionItems]="ipList"
              (onApplySelected)=collectIPrange($event)>
            </mc-dropdown-autocomplete-checkboxes>      

          </div>
      
          <div class="col-xs-2">

            <label class="control-label">{{'$I18N_ACCOUNT_IP_CHECKER_SEARCH.LABEL.PORT' | translate}}</label>
            <mc-dropdown-autocomplete-checkboxes
              label="$I18N_ACCOUNT_IP_CHECKER_SEARCH.LABEL.ALL" 
              [optionItems]="portRange"
              (onApplySelected)=onSelectedValue($event)>

            </mc-dropdown-autocomplete-checkboxes>    

          </div>

          <div class="col-xs-7" class="mc-submit-button">
            <button type="submit" class="btn btn-primary">
              {{'$I18N_ACCOUNT_IP_CHECKER_SEARCH.LABEL.SUBMIT' | translate}}
            </button>
          </div>
        </div>
      </div>
  </form>
</div>
