"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LexiconSummaryComponent = void 0;
const lexicon_service_1 = require("../../../../../services/lexicon.service");
const lexicon_1 = require("app-new/archive/supervision/model/lexicon/lexicon");
class LexiconSummaryComponent {
    ngOnChanges(changes) {
        if (changes && changes['wizardData'].currentValue) {
            this.stepsData = this.wizardData.steps;
        }
    }
    getStepById(id) {
        return (this.stepsData && this.stepsData[id]) || {};
    }
    getStepDataById(id) {
        const step = this.getStepById(id);
        return (step && step.data) || {};
    }
    getWords() {
        const words = lexicon_service_1.LexiconService.splitWords(this.getStepDataById(2)['words']);
        return words ? words.sort((a, b) => a.localeCompare(b)) : [];
    }
    getPhrases() {
        const phrases = lexicon_service_1.LexiconService.splitPhrases(this.getStepDataById(2)['phrases']);
        return phrases ? phrases.sort((a, b) => a.localeCompare(b)) : [];
    }
    isBasicSearchType() {
        return this.getStepDataById(2).searchType === lexicon_1.LexiconSearchType.BASIC;
    }
    isAdvancedSearchType() {
        return this.getStepDataById(2).searchType === lexicon_1.LexiconSearchType.ADVANCED;
    }
}
exports.LexiconSummaryComponent = LexiconSummaryComponent;
