<form class="form-horizontal" mcDefaultLabelClass="col-sm-4" mcDefaultControlContainerClass="col-sm-8" [formGroup]="form">
    <div class="form-group mc-field">
        <label class="control-label col-sm-4">
            {{'$I18N_API_INTEGRATION_NOTIFICATION_COMPONENT.NOTIFICATIONS.OPTIONS_VALUES.GROUPS' | translate }}
        </label>
        <div class="col-sm-8 no-padding-top">
            <div class="col-sm-12 no-padding-left">
                <div class="selected-group no-margin-top" *ngFor="let group of groupsList">
                    <span class="groups-list">
                        <div class="groups-name">
                        {{ group.description | translate }}
                        </div>
                        <i class="far fa-trash-alt"
                        aria-hidden="true"
                        (click)="deleteGroup(group)"
                        ></i>
                    </span>
                    <hr class="groups-separator" />
                </div>

                <button type="button" class="btn btn-default panel-half-margin-top" (click)="openSidebar()" [disabled] = "isAddGroupBtnDisabled">
                    {{ '$I18N_API_INTEGRATION_NOTIFICATION_COMPONENT.NOTIFICATIONS.GROUPS_SELECT_LABEL' | translate }}
                    <span>{{ '$I18N_API_INTEGRATION_NOTIFICATION_COMPONENT.NOTIFICATIONS.MAX_NUMBER_OF_EMAILS_AND_GROUPS' | translate }}</span>
                </button>
            </div>
        </div>
    </div>

    <mc-api-integration-email [emails]="emails" label="$I18N_API_INTEGRATION_NOTIFICATION_COMPONENT.NOTIFICATIONS.OPTIONS_VALUES.USERS"
    (onDeleteEmail)="deleteEmail($event)" (onVerifyAndAddEmail)="addEmail($event)"
    btnExtraInfo="$I18N_API_INTEGRATION_NOTIFICATION_COMPONENT.NOTIFICATIONS.MAX_NUMBER_OF_EMAILS_AND_GROUPS"></mc-api-integration-email>
</form>

