"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiemChannelSummaryStepComponent = void 0;
class SiemChannelSummaryStepComponent {
    getPageTitle() {
        if (this.channel.id !== undefined && this.channel.id) {
            return '$I18N_SIEM_CHANNEL_WIZARD.STEPS.SUMMARY.EDIT_TITLE';
        }
        else {
            return '$I18N_SIEM_CHANNEL_WIZARD.STEPS.SUMMARY.CREATE_TITLE';
        }
    }
}
exports.SiemChannelSummaryStepComponent = SiemChannelSummaryStepComponent;
