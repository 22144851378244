"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_CONSENT_CONFIG_SUCCESS = exports.GET_CONSENT_CONFIG = exports.GET_PROVIDERS_FAILURE = exports.GET_PROVIDERS_SUCCESS = exports.GET_PROVIDERS = exports.GET_CONNECTORS_FAILURE = exports.GET_CONNECTORS_SUCCESS = exports.GET_CONNECTORS = exports.GET_PRODUCTS_FAILURE = exports.GET_PRODUCTS_SUCCESS = exports.GET_PRODUCTS = exports.DISPLAY_TOAST_WHEN_GROUP_FAILED = exports.SHOW_MSE_MESSAGE_MODAL = exports.OPEN_ASIDE = exports.GET_SYNC_STATUS_FAIL = exports.GET_SYNC_STATUS_SUCCESS = exports.GET_SYNC_STATUS = exports.OPEN_WIZARD = exports.SYNCHRONIZATION_REPORT_AVAILABLE = exports.SYNCHRONIZATION_FULL_REPORT = exports.SHOW_SYNCHRONIZATION_REPORT_MODAL = exports.SYNCHRONIZATION_REPORT_FAIL = exports.SYNCHRONIZATION_REPORT_SUCCESS = exports.SYNCHRONIZATION_REPORT = exports.SAVE_SEARCH_FILTERS = exports.SEARCH_DIRECTORY_FAIL = exports.CLEAR_SEARCH_DIRECTORY = exports.SEARCH_DIRECTORY_SUCCESS = exports.SEARCH_DIRECTORY = exports.SHOW_CONFIRMATION_MODAL = exports.SYNC_ALL_INTEGRATIONS_FAILURE = exports.SYNC_ALL_INTEGRATIONS_SUCCESS = exports.SYNC_ALL_INTEGRATIONS = exports.UPDATE_INTEGRATION_STATUS_SUCCESS = exports.UPDATE_INTEGRATION_STATUS = exports.UPDATE_INTEGRATION = exports.DELETE_INTEGRATION = exports.CREATE_INTEGRATION = exports.SAVE_INTEGRATION_DETAILS = exports.CANCEL_CREATE_DIRECTORY_INTEGRATION_WIZARD = exports.PAGE_INTEGRATIONS_CLOSE_ASIDE = exports.PAGE_INTEGRATIONS_OPEN_ASIDE = exports.PAGE_INTEGRATIONS_COMPLETE = exports.PAGE_INTEGRATIONS = exports.DIRECTORY_INTEGRATIONS_FAILURE = exports.GET_ALL_DIRECTORY_INTEGRATIONS_SUCCESS = exports.GET_ALL_DIRECTORY_INTEGRATIONS = exports.CLEAR_DIRECTORY_CONNECTION = exports.CLOSE_DIRECTORY_CONNECTION = exports.DIRECTORY_CONNECTION = void 0;
exports.GetProvidersActionFail = exports.GetProvidersActionSuccess = exports.GetProvidersAction = exports.GetConnectorsActionFail = exports.GetConnectorsActionSuccess = exports.GetConnectorsAction = exports.GetProductsActionFail = exports.GetProductsActionSuccess = exports.GetProductsAction = exports.DisplayToastWhenGroupsFailedAction = exports.ShowMseMessageModalAction = exports.OpenAsideAction = exports.GetSyncStatusFailAction = exports.GetSyncStatusSuccessAction = exports.GetSyncStatusAction = exports.OpenWizardAction = exports.SynchronizationReportAvailableAction = exports.SynchronizationFullReportAction = exports.ShowSynchronizationReportModalAction = exports.SynchronizationReportFailAction = exports.SynchronizationReportSuccessAction = exports.SynchronizationReportAction = exports.SaveSearchFiltersAction = exports.SearchDirectoryFailAction = exports.ClearSearchDirectoryResultsAction = exports.SearchDirectorySuccessAction = exports.SearchDirectoryAction = exports.ShowConfirmationModalAction = exports.SyncAllIntegrationsFailureAction = exports.SyncAllIntegrationsSuccessAction = exports.SyncAllIntegrationsAction = exports.PageIntegrationsCloseAsideAction = exports.UpdateIntegrationStatusSuccessAction = exports.PageIntegrationsOpenAsideAction = exports.UpdateIntegrationStatusAction = exports.UpdateIntegrationAction = exports.DeleteIntegrationAction = exports.CreateIntegrationAction = exports.SaveIntegrationDetailsAction = exports.RedirectToOnboardingAction = exports.CancelCreateDirectoryIntegrationWizardAction = exports.DirectorySyncIntegrationsFailureAction = exports.GetAllDirectorySyncIntegrationsSuccessAction = exports.GetAllDirectorySyncIntegrationsAction = exports.ClearDirectoryConnectionAction = exports.CloseDirectoryConnectionAction = exports.DirectoryConnectionAction = exports.REDIRECT_TO_ONBOARDING = exports.CREATE_CONNECTOR = exports.GET_CONSENT_CONFIG_FAIL = void 0;
exports.CreateConnectorAction = exports.GetConsentConfigActionFail = exports.GetConsentConfigActionSuccess = exports.GetConsentConfigAction = void 0;
exports.DIRECTORY_CONNECTION = '[Directory Sync] Directory Connection';
exports.CLOSE_DIRECTORY_CONNECTION = '[Directory Sync] Close Directory Connection';
exports.CLEAR_DIRECTORY_CONNECTION = '[Directory Sync] Clear Directory Connection';
exports.GET_ALL_DIRECTORY_INTEGRATIONS = '[Directory Sync/Integrations/List] Get All Directory Sync Integrations';
exports.GET_ALL_DIRECTORY_INTEGRATIONS_SUCCESS = '[Directory Sync/Integrations/List] Get All Directory Sync Integrations Success';
exports.DIRECTORY_INTEGRATIONS_FAILURE = '[Directory Sync/Integrations/List] Directory Sync Integrations Failure';
exports.PAGE_INTEGRATIONS = '[Directory Sync/Integrations/List] Page Directory Sync Integrations';
exports.PAGE_INTEGRATIONS_COMPLETE = '[Directory Sync/Integrations/List] Page Directory Sync Integrations Complete';
exports.PAGE_INTEGRATIONS_OPEN_ASIDE = '[Directory Sync/Integrations/List] Page Directory Sync Integrations Open Aside';
exports.PAGE_INTEGRATIONS_CLOSE_ASIDE = '[Directory Sync/Integrations/List] Page Directory Sync Integrations Close Aside';
exports.CANCEL_CREATE_DIRECTORY_INTEGRATION_WIZARD = '[Directory Sync/Wizard] Cancel Create Directory Integration Wizard';
exports.SAVE_INTEGRATION_DETAILS = '[Directory Sync/Wizard] Save Directory Integration Details';
exports.CREATE_INTEGRATION = '[Directory Sync/Wizard] Create Directory Integration';
exports.DELETE_INTEGRATION = '[Directory Sync/Delete] Delete Directory Integration';
exports.UPDATE_INTEGRATION = '[Directory Sync/Update] Update Directory Integration';
exports.UPDATE_INTEGRATION_STATUS = '[Directory Sync/Update] Update Directory Integration Status';
exports.UPDATE_INTEGRATION_STATUS_SUCCESS = '[Directory Sync/Update] Update Integration Status Success';
exports.SYNC_ALL_INTEGRATIONS = '[Directory Sync/Sync] Sync All Integrations';
exports.SYNC_ALL_INTEGRATIONS_SUCCESS = '[Directory Sync/Sync] Sync All Integrations Success';
exports.SYNC_ALL_INTEGRATIONS_FAILURE = '[Directory Sync/Sync] Sync All Integrations Failure';
exports.SHOW_CONFIRMATION_MODAL = '[Directory Sync/Modal] Show Confirmation Modal';
exports.SEARCH_DIRECTORY = '[Directory Sync/Search] Search Directory';
exports.SEARCH_DIRECTORY_SUCCESS = '[Directory Sync/Search] Search Directory Success';
exports.CLEAR_SEARCH_DIRECTORY = '[Directory Sync/Search] Clear Search Directory';
exports.SEARCH_DIRECTORY_FAIL = '[Directory Sync/Search] Search Directory Fail';
exports.SAVE_SEARCH_FILTERS = '[Directory Sync/Save Search] Save Search Filters';
exports.SYNCHRONIZATION_REPORT = '[Directory Sync/Synchronization] Sync Report';
exports.SYNCHRONIZATION_REPORT_SUCCESS = '[Directory Sync/Synchronization] Sync Report Success';
exports.SYNCHRONIZATION_REPORT_FAIL = '[Directory Sync/Synchronization] Sync Report Fail';
exports.SHOW_SYNCHRONIZATION_REPORT_MODAL = '[Directory Sync/Synchronization] Show Sync Report Modal';
exports.SYNCHRONIZATION_FULL_REPORT = '[Directory Sync/Synchronization] Sync Full Report';
exports.SYNCHRONIZATION_REPORT_AVAILABLE = '[Directory Sync/Synchronization] Sync Report Available';
exports.OPEN_WIZARD = '[Directory Sync/Wizard] Open Wizard';
exports.GET_SYNC_STATUS = '[Directory Sync/Status] Get Sync Status';
exports.GET_SYNC_STATUS_SUCCESS = '[Directory Sync/Status] Get Sync Status Success';
exports.GET_SYNC_STATUS_FAIL = '[Directory Sync/Status] Get Sync Status Fail';
exports.OPEN_ASIDE = '[Directory Sync/Integrations/List] Page Directory Sync Open Aside';
exports.SHOW_MSE_MESSAGE_MODAL = '[Directory Sync/MSE] Show MSE Message Modal';
exports.DISPLAY_TOAST_WHEN_GROUP_FAILED = '[Directory Sync/Get AAA Group] Display Toast When Group Fail';
exports.GET_PRODUCTS = '[Directory Sync/Wizard - Azure Hecate] Get Products';
exports.GET_PRODUCTS_SUCCESS = '[Directory Sync/Wizard - Azure Hecate] Get Products Success';
exports.GET_PRODUCTS_FAILURE = '[Directory Sync/Wizard - Azure Hecate] Get Products Failure';
exports.GET_CONNECTORS = '[Directory Sync/Wizard - Azure Hecate] Get Connectors';
exports.GET_CONNECTORS_SUCCESS = '[Directory Sync/Wizard - Azure Hecate] Get Connectors Success';
exports.GET_CONNECTORS_FAILURE = '[Directory Sync/Wizard - Azure Hecate] Get Connectors Failure';
exports.GET_PROVIDERS = '[Directory Sync/Wizard - Azure Hecate] Get Providers';
exports.GET_PROVIDERS_SUCCESS = '[Directory Sync/Wizard - Azure Hecate] Get Providers Success';
exports.GET_PROVIDERS_FAILURE = '[Directory Sync/Wizard - Azure Hecate] Get Providers Failure';
exports.GET_CONSENT_CONFIG = '[Directory Sync/Wizard - Azure Hecate] Get Consent Config';
exports.GET_CONSENT_CONFIG_SUCCESS = '[Directory Sync/Wizard - Azure Hecate] Get Consent Config Success';
exports.GET_CONSENT_CONFIG_FAIL = '[Directory Sync/Wizard - Azure Hecate] Get Consent Config Failure';
exports.CREATE_CONNECTOR = '[Directory Sync/Wizard - Azure Hecate] Create Connector';
exports.REDIRECT_TO_ONBOARDING = '[Directory Sync/Wizard - Redirect to Onboarding';
class DirectoryConnectionAction {
    constructor(data) {
        this.data = data;
        this.type = exports.DIRECTORY_CONNECTION;
    }
}
exports.DirectoryConnectionAction = DirectoryConnectionAction;
class CloseDirectoryConnectionAction {
    constructor() {
        this.type = exports.CLOSE_DIRECTORY_CONNECTION;
    }
}
exports.CloseDirectoryConnectionAction = CloseDirectoryConnectionAction;
class ClearDirectoryConnectionAction {
    constructor() {
        this.type = exports.CLEAR_DIRECTORY_CONNECTION;
    }
}
exports.ClearDirectoryConnectionAction = ClearDirectoryConnectionAction;
class GetAllDirectorySyncIntegrationsAction {
    constructor(pagination, filters) {
        this.pagination = pagination;
        this.filters = filters;
        this.type = exports.GET_ALL_DIRECTORY_INTEGRATIONS;
    }
}
exports.GetAllDirectorySyncIntegrationsAction = GetAllDirectorySyncIntegrationsAction;
class GetAllDirectorySyncIntegrationsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_ALL_DIRECTORY_INTEGRATIONS_SUCCESS;
    }
}
exports.GetAllDirectorySyncIntegrationsSuccessAction = GetAllDirectorySyncIntegrationsSuccessAction;
class DirectorySyncIntegrationsFailureAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.DIRECTORY_INTEGRATIONS_FAILURE;
    }
}
exports.DirectorySyncIntegrationsFailureAction = DirectorySyncIntegrationsFailureAction;
class CancelCreateDirectoryIntegrationWizardAction {
    constructor() {
        this.type = exports.CANCEL_CREATE_DIRECTORY_INTEGRATION_WIZARD;
    }
}
exports.CancelCreateDirectoryIntegrationWizardAction = CancelCreateDirectoryIntegrationWizardAction;
class RedirectToOnboardingAction {
    constructor() {
        this.type = exports.REDIRECT_TO_ONBOARDING;
    }
}
exports.RedirectToOnboardingAction = RedirectToOnboardingAction;
class SaveIntegrationDetailsAction {
    constructor(payload, step, serverType, serverSubType) {
        this.payload = payload;
        this.step = step;
        this.serverType = serverType;
        this.serverSubType = serverSubType;
        this.type = exports.SAVE_INTEGRATION_DETAILS;
    }
}
exports.SaveIntegrationDetailsAction = SaveIntegrationDetailsAction;
class CreateIntegrationAction {
    constructor(payload, isOnboardingRedirectEnabled) {
        this.payload = payload;
        this.isOnboardingRedirectEnabled = isOnboardingRedirectEnabled;
        this.type = exports.CREATE_INTEGRATION;
    }
}
exports.CreateIntegrationAction = CreateIntegrationAction;
class DeleteIntegrationAction {
    constructor(payload, pageSize) {
        this.payload = payload;
        this.pageSize = pageSize;
        this.type = exports.DELETE_INTEGRATION;
    }
}
exports.DeleteIntegrationAction = DeleteIntegrationAction;
class UpdateIntegrationAction {
    constructor(integration) {
        this.integration = integration;
        this.type = exports.UPDATE_INTEGRATION;
    }
}
exports.UpdateIntegrationAction = UpdateIntegrationAction;
class UpdateIntegrationStatusAction {
    constructor(integration, pageSize) {
        this.integration = integration;
        this.pageSize = pageSize;
        this.type = exports.UPDATE_INTEGRATION_STATUS;
    }
}
exports.UpdateIntegrationStatusAction = UpdateIntegrationStatusAction;
class PageIntegrationsOpenAsideAction {
    constructor(aside) {
        this.aside = aside;
        this.type = exports.PAGE_INTEGRATIONS_OPEN_ASIDE;
    }
}
exports.PageIntegrationsOpenAsideAction = PageIntegrationsOpenAsideAction;
class UpdateIntegrationStatusSuccessAction {
    constructor(integration) {
        this.integration = integration;
        this.type = exports.UPDATE_INTEGRATION_STATUS_SUCCESS;
    }
}
exports.UpdateIntegrationStatusSuccessAction = UpdateIntegrationStatusSuccessAction;
class PageIntegrationsCloseAsideAction {
    constructor() {
        this.type = exports.PAGE_INTEGRATIONS_CLOSE_ASIDE;
    }
}
exports.PageIntegrationsCloseAsideAction = PageIntegrationsCloseAsideAction;
class SyncAllIntegrationsAction {
    constructor() {
        this.type = exports.SYNC_ALL_INTEGRATIONS;
    }
}
exports.SyncAllIntegrationsAction = SyncAllIntegrationsAction;
class SyncAllIntegrationsSuccessAction {
    constructor(data) {
        this.data = data;
        this.type = exports.SYNC_ALL_INTEGRATIONS_SUCCESS;
    }
}
exports.SyncAllIntegrationsSuccessAction = SyncAllIntegrationsSuccessAction;
class SyncAllIntegrationsFailureAction {
    constructor() {
        this.type = exports.SYNC_ALL_INTEGRATIONS_FAILURE;
    }
}
exports.SyncAllIntegrationsFailureAction = SyncAllIntegrationsFailureAction;
class ShowConfirmationModalAction {
    constructor(data) {
        this.data = data;
        this.type = exports.SHOW_CONFIRMATION_MODAL;
    }
}
exports.ShowConfirmationModalAction = ShowConfirmationModalAction;
class SearchDirectoryAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SEARCH_DIRECTORY;
    }
}
exports.SearchDirectoryAction = SearchDirectoryAction;
class SearchDirectorySuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SEARCH_DIRECTORY_SUCCESS;
    }
}
exports.SearchDirectorySuccessAction = SearchDirectorySuccessAction;
class ClearSearchDirectoryResultsAction {
    constructor() {
        this.type = exports.CLEAR_SEARCH_DIRECTORY;
    }
}
exports.ClearSearchDirectoryResultsAction = ClearSearchDirectoryResultsAction;
class SearchDirectoryFailAction {
    constructor() {
        this.type = exports.SEARCH_DIRECTORY_FAIL;
    }
}
exports.SearchDirectoryFailAction = SearchDirectoryFailAction;
class SaveSearchFiltersAction {
    constructor(filters, pagination) {
        this.filters = filters;
        this.pagination = pagination;
        this.type = exports.SAVE_SEARCH_FILTERS;
    }
}
exports.SaveSearchFiltersAction = SaveSearchFiltersAction;
class SynchronizationReportAction {
    constructor(downloadReport) {
        this.downloadReport = downloadReport;
        this.type = exports.SYNCHRONIZATION_REPORT;
    }
}
exports.SynchronizationReportAction = SynchronizationReportAction;
class SynchronizationReportSuccessAction {
    constructor() {
        this.type = exports.SYNCHRONIZATION_REPORT_SUCCESS;
    }
}
exports.SynchronizationReportSuccessAction = SynchronizationReportSuccessAction;
class SynchronizationReportFailAction {
    constructor() {
        this.type = exports.SYNCHRONIZATION_REPORT_FAIL;
    }
}
exports.SynchronizationReportFailAction = SynchronizationReportFailAction;
class ShowSynchronizationReportModalAction {
    constructor(report) {
        this.report = report;
        this.type = exports.SHOW_SYNCHRONIZATION_REPORT_MODAL;
    }
}
exports.ShowSynchronizationReportModalAction = ShowSynchronizationReportModalAction;
class SynchronizationFullReportAction {
    constructor(id) {
        this.id = id;
        this.type = exports.SYNCHRONIZATION_FULL_REPORT;
    }
}
exports.SynchronizationFullReportAction = SynchronizationFullReportAction;
class SynchronizationReportAvailableAction {
    constructor(isAvailable) {
        this.isAvailable = isAvailable;
        this.type = exports.SYNCHRONIZATION_REPORT_AVAILABLE;
    }
}
exports.SynchronizationReportAvailableAction = SynchronizationReportAvailableAction;
class OpenWizardAction {
    constructor(editMode, integration) {
        this.editMode = editMode;
        this.integration = integration;
        this.type = exports.OPEN_WIZARD;
    }
}
exports.OpenWizardAction = OpenWizardAction;
class GetSyncStatusAction {
    constructor() {
        this.type = exports.GET_SYNC_STATUS;
    }
}
exports.GetSyncStatusAction = GetSyncStatusAction;
class GetSyncStatusSuccessAction {
    constructor(response) {
        this.response = response;
        this.type = exports.GET_SYNC_STATUS_SUCCESS;
    }
}
exports.GetSyncStatusSuccessAction = GetSyncStatusSuccessAction;
class GetSyncStatusFailAction {
    constructor() {
        this.type = exports.GET_SYNC_STATUS_FAIL;
    }
}
exports.GetSyncStatusFailAction = GetSyncStatusFailAction;
class OpenAsideAction {
    constructor(aside) {
        this.aside = aside;
        this.type = exports.OPEN_ASIDE;
    }
}
exports.OpenAsideAction = OpenAsideAction;
class ShowMseMessageModalAction {
    constructor(message) {
        this.message = message;
        this.type = exports.SHOW_MSE_MESSAGE_MODAL;
    }
}
exports.ShowMseMessageModalAction = ShowMseMessageModalAction;
class DisplayToastWhenGroupsFailedAction {
    constructor() {
        this.type = exports.DISPLAY_TOAST_WHEN_GROUP_FAILED;
    }
}
exports.DisplayToastWhenGroupsFailedAction = DisplayToastWhenGroupsFailedAction;
class GetProductsAction {
    constructor() {
        this.type = exports.GET_PRODUCTS;
    }
}
exports.GetProductsAction = GetProductsAction;
class GetProductsActionSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_PRODUCTS_SUCCESS;
    }
}
exports.GetProductsActionSuccess = GetProductsActionSuccess;
class GetProductsActionFail {
    constructor() {
        this.type = exports.GET_PRODUCTS_FAILURE;
    }
}
exports.GetProductsActionFail = GetProductsActionFail;
class GetConnectorsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_CONNECTORS;
    }
}
exports.GetConnectorsAction = GetConnectorsAction;
class GetConnectorsActionSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_CONNECTORS_SUCCESS;
    }
}
exports.GetConnectorsActionSuccess = GetConnectorsActionSuccess;
class GetConnectorsActionFail {
    constructor() {
        this.type = exports.GET_CONNECTORS_FAILURE;
    }
}
exports.GetConnectorsActionFail = GetConnectorsActionFail;
class GetProvidersAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_PROVIDERS;
    }
}
exports.GetProvidersAction = GetProvidersAction;
class GetProvidersActionSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_PROVIDERS_SUCCESS;
    }
}
exports.GetProvidersActionSuccess = GetProvidersActionSuccess;
class GetProvidersActionFail {
    constructor() {
        this.type = exports.GET_PROVIDERS_FAILURE;
    }
}
exports.GetProvidersActionFail = GetProvidersActionFail;
class GetConsentConfigAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_CONSENT_CONFIG;
    }
}
exports.GetConsentConfigAction = GetConsentConfigAction;
class GetConsentConfigActionSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_CONSENT_CONFIG_SUCCESS;
    }
}
exports.GetConsentConfigActionSuccess = GetConsentConfigActionSuccess;
class GetConsentConfigActionFail {
    constructor() {
        this.type = exports.GET_CONSENT_CONFIG_FAIL;
    }
}
exports.GetConsentConfigActionFail = GetConsentConfigActionFail;
class CreateConnectorAction {
    constructor(payload, isEditMode) {
        this.payload = payload;
        this.isEditMode = isEditMode;
        this.type = exports.CREATE_CONNECTOR;
    }
}
exports.CreateConnectorAction = CreateConnectorAction;
