"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgCategoryGroupIndicatorComponent = void 0;
const core_1 = require("@angular/core");
class SwgCategoryGroupIndicatorComponent {
    constructor(fb) {
        this.fb = fb;
        this.allowAll = new core_1.EventEmitter();
        this.blockAll = new core_1.EventEmitter();
        this.custom = new core_1.EventEmitter();
        this._disabled = false;
        this.groupForm = this.fb.group({
            block: 'all',
            disabled: this._disabled
        });
    }
    set disabled(value) {
        this._disabled = value;
        if (this.groupForm) {
            if (this._disabled) {
                this.groupForm.disable();
            }
            else {
                this.groupForm.enable();
            }
        }
    }
    ngOnChanges(changes) {
        const change = changes['status'];
        if (change && !change.isFirstChange()) {
            this.update();
        }
    }
    update() {
        this.groupForm.patchValue({ block: this.status });
    }
}
exports.SwgCategoryGroupIndicatorComponent = SwgCategoryGroupIndicatorComponent;
