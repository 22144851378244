<mc-wizard-step-header [label]="'$I18N_SWG_CREATE_POLICY_CLP_APP_NOTIFICATIONS_TITLE'">
  <div>{{'$I18N_SWG_CREATE_POLICY_CLP_APP_NOTIFICATIONS_DESC' | translate}}</div>
</mc-wizard-step-header>
<div class="mc-safe-search-settings-step">
  <form novalidate [formGroup]='clpNotifySettingsForm' class="mc-safe-search-settings-form">
    <mc-enable-switch [label]="'$I18N_SWG_CREATE_POLICY_CLP_APP_NOTIFY_FILE_OWNER'"
                      formControlName='notify_file_owner'></mc-enable-switch>
    <mc-enable-switch [label]="'$I18N_SWG_CREATE_POLICY_CLP_APP_NOTIFY_ADMINISTRATOR'"
                      formControlName='notify_administrator'></mc-enable-switch>
  </form>

  <div [hidden]="!clpNotifySettingsForm.value.notify_administrator">

    <div class="panel-padding-top">
      <span class="mc-sub-heading">{{'$I18N_SWG_CREATE_POLICY_AFFECTED_USERS_SELECT_CHANNELS_LABEL' | translate}}</span>
      <div *ngIf="clpSettings?.notificationList?.length > 0" class="mc-selected-container">
        <div class="text-muted">
          <div *ngFor="let item of clpSettings.notificationList">
            <div *ngIf="item.condition === 'clp_notifications_channel'">
              <div class="mc-selected-trash-container">
                {{ item.teamsChannel.channelDisplayName }}
                <i
                  class="far fa-trash-alt mc-selected-trash-icon"
                  aria-hidden="true"
                  (click)="removeUser(item)"
                ></i>
              </div>
              <hr class="mc-selected-hr"/>
            </div>
          </div>
        </div>
      </div>
      <div>
        <button
          type="submit"
          class="btn btn-default panel-half-margin-top"
          (click)="openSidebar('clp_notifications_channel')"
        >
          {{ '$I18N_SWG_CREATE_POLICY_AFFECTED_USERS_SELECT_CHANNELS' | translate }}
        </button>
      </div>
    </div>

    <div class="panel-padding-top">
      <span class="mc-sub-heading">{{'$I18N_SWG_CREATE_POLICY_AFFECTED_USERS_GROUPS' | translate}}</span>
      <div *ngIf="clpSettings?.notificationList?.length > 0" class="mc-selected-container">
        <div class="text-muted">
          <div *ngFor="let item of clpSettings.notificationList">
            <div *ngIf="item.condition === 'clp_notifications_group'">
              <div class="mc-selected-trash-container">
                {{ item.payload['description'] }}
                <i
                  class="far fa-trash-alt mc-selected-trash-icon"
                  aria-hidden="true"
                  (click)="removeUser(item)"
                ></i>
              </div>
              <hr class="mc-selected-hr"/>
            </div>
          </div>
        </div>
      </div>
      <div>
        <button
          type="submit"
          class="btn btn-default panel-half-margin-top"
          (click)="openSidebar('clp_notifications_group')"
        >
          {{ '$I18N_SWG_CREATE_POLICY_AFFECTED_USERS_SELECT_GROUPS' | translate }}
        </button>
      </div>
    </div>


  </div>


</div>
