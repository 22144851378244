"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StartIntegrationOktaEvidenceBasedControlAction = exports.StartIntegrationOktaAction = exports.StartIntegrationCrowdstrikeAction = exports.StartIntegrationWildfireAction = exports.StartIntegrationCreateCortexAction = exports.IntegrationCreateActionTypes = void 0;
var IntegrationCreateActionTypes;
(function (IntegrationCreateActionTypes) {
    IntegrationCreateActionTypes["StartIntegrationCreateCortex"] = "[ApiApplication/Integration/Cortex/Create/Start] Start Create Cortex Integration";
    IntegrationCreateActionTypes["StartIntegrationWildfire"] = "[ApiApplication/Integration/Wildfire/Create/Start] Start Wildfire Integration";
    IntegrationCreateActionTypes["StartIntegrationCrowdstrike"] = "[ApiApplication/Integration/Crowdstrike/Create/Start] Start Crowdstrike Integration";
    IntegrationCreateActionTypes["StartIntegrationOkta"] = "[ApiApplication/Integration/Okta/Create/Start] Start Okta Integration";
    IntegrationCreateActionTypes["StartIntegrationOktaEvidenceBasedControl"] = "[ApiApplication/Integration/Okta/Create/Start] Start Okta Evidence Based Control Integration";
})(IntegrationCreateActionTypes = exports.IntegrationCreateActionTypes || (exports.IntegrationCreateActionTypes = {}));
class StartIntegrationCreateCortexAction {
    constructor() {
        this.type = IntegrationCreateActionTypes.StartIntegrationCreateCortex;
    }
}
exports.StartIntegrationCreateCortexAction = StartIntegrationCreateCortexAction;
class StartIntegrationWildfireAction {
    constructor(payload) {
        this.payload = payload;
        this.type = IntegrationCreateActionTypes.StartIntegrationWildfire;
    }
}
exports.StartIntegrationWildfireAction = StartIntegrationWildfireAction;
class StartIntegrationCrowdstrikeAction {
    constructor() {
        this.type = IntegrationCreateActionTypes.StartIntegrationCrowdstrike;
    }
}
exports.StartIntegrationCrowdstrikeAction = StartIntegrationCrowdstrikeAction;
class StartIntegrationOktaAction {
    constructor() {
        this.type = IntegrationCreateActionTypes.StartIntegrationOkta;
    }
}
exports.StartIntegrationOktaAction = StartIntegrationOktaAction;
class StartIntegrationOktaEvidenceBasedControlAction {
    constructor() {
        this.type = IntegrationCreateActionTypes.StartIntegrationOktaEvidenceBasedControl;
    }
}
exports.StartIntegrationOktaEvidenceBasedControlAction = StartIntegrationOktaEvidenceBasedControlAction;
