"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tableColumnsToIgnore = exports.COLUMN_LIST = exports.FILTER_SEARCH_OPTIONS = void 0;
exports.FILTER_SEARCH_OPTIONS = [
    {
        label: '$I18N_REMEDIATION_INCIDENT_TAB.ALL',
        value: 'all'
    },
    {
        label: '$I18N_REMEDIATION_INCIDENT_TAB.COLUMNS.CODE',
        value: 'code'
    },
    {
        label: '$I18N_REMEDIATION_INCIDENT_TAB.COLUMNS.REASON',
        value: 'reason'
    }
];
exports.COLUMN_LIST = [
    'code',
    'type',
    'create',
    'modified',
    'reason',
    'identified',
    'successful',
    'failed',
    'restored',
    'action'
];
exports.tableColumnsToIgnore = ['hash_message_id'];
