<div class="mc-api-application-step-block">
  <mc-wizard-step-header [label]="'$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.WILDFIRE_SETTINGS.HEADER'">
  </mc-wizard-step-header>

  <form
    class="form-horizontal"
    mcDefaultLabelClass="col-sm-3"
    mcDefaultControlContainerClass="col-sm-9"
    [formGroup]="form"
  >
      <mc-field
          label="{{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.WILDFIRE_SETTINGS.STATUS_LABEL' | translate }}"
          helpPopoverContent="{{
            '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.WILDFIRE_SETTINGS.STATUS_POPOVER' | translate
          }}"
          >
          <mc-enable-switch formControlName="enabled"></mc-enable-switch>
      </mc-field>
    <div *ngIf="showConfiguration">
      <mc-field
        label="{{
          '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.WILDFIRE_SETTINGS.SEVERITY_LABEL' | translate
        }}"
        helpPopoverContent="{{
          '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.WILDFIRE_SETTINGS.SEVERITY_POPOVER' | translate
        }}"
      >
          <mc-select formControlName="wildfireSeverity" [options]="wildfireSeverities"></mc-select>
      </mc-field>
      <mc-field
        label="{{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.WILDFIRE_SETTINGS.ACTIONS_LABEL' | translate }}"
        helpPopoverContent="{{
          '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.WILDFIRE_SETTINGS.ACTIONS_POPOVER' | translate
        }}"
      >
        <div class="col-sm-9">
            <div class="radio panel-half-padding-bottom">
              <label class="text-bold">
                  <input
                    type="radio"
                    name="action"
                    value="NOTIFY"
                    formControlName="action"
                  />
                  <div>
                    {{
                    '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.WILDFIRE_SETTINGS.ACTION_VALUES.NOTIFY_ONLY'
                    | translate
                    }}
                  </div>
              </label>
            </div>
            <div class="radio panel-half-padding-bottom">
              <label class="text-bold">
                  <input
                    type="radio"
                    name="action"
                    value="BLOCK_OUTBOUND"
                    formControlName="action"
                  />
                  <div>
                    {{
                    '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.WILDFIRE_SETTINGS.ACTION_VALUES.BLOCK_OUTBOUND'
                    | translate
                    }}
                  </div>
              </label>
            </div>
            <div class="radio panel-half-padding-bottom">
              <label class="text-bold">
                  <input
                    type="radio"
                    name="action"
                    value="BLOCK_ALL"
                    formControlName="action"
                  />
                  <div>
                    {{
                    '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.WILDFIRE_SETTINGS.ACTION_VALUES.BLOCK_ALL'
                    | translate
                    }}
                  </div>
              </label>
            </div>
        </div>
      </mc-field>
      <mc-field
          *ngIf="showDisableUser"
          label="{{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.WILDFIRE_SETTINGS.DISABLE_USER_LABEL' | translate }}"
          helpPopoverContent="{{
            '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.WILDFIRE_SETTINGS.DISABLE_USER_POPOVER' | translate
          }}"
          >
          <mc-enable-switch
              onStateLabel="{{
                '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.WILDFIRE_SETTINGS.DISABLE_USER_ENABLED' | translate
              }}"
              offStateLabel="{{
                '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.WILDFIRE_SETTINGS.DISABLE_USER_DISABLED' | translate
              }}"
              formControlName="disableUser"
          ></mc-enable-switch>
      </mc-field>
    </div>
  </form>
</div>