"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchAction = exports.ToggleOrderAction = exports.DeleteFailAction = exports.DeleteAction = exports.LoadFailAction = exports.LoadSuccessAction = exports.LoadAction = exports.TOGGLE_ORDER = exports.SEARCH = exports.DELETE_FAIL = exports.DELETE = exports.LOAD_FAIL = exports.LOAD_SUCCESS = exports.LOAD = void 0;
exports.LOAD = '[Awareness Template List] Load';
exports.LOAD_SUCCESS = '[Awareness Template List] Load Success';
exports.LOAD_FAIL = '[Awareness Template List] Load Fail';
exports.DELETE = '[Awareness Template List] Delete';
exports.DELETE_FAIL = '[Awareness Template List] Delete Fail';
exports.SEARCH = '[Awareness Template List] Search';
exports.TOGGLE_ORDER = '[Awareness Template List] Toggle Order';
/**
 * Load List Actions
 */
class LoadAction {
    constructor() {
        this.type = exports.LOAD;
    }
}
exports.LoadAction = LoadAction;
class LoadSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOAD_SUCCESS;
    }
}
exports.LoadSuccessAction = LoadSuccessAction;
class LoadFailAction {
    constructor() {
        this.type = exports.LOAD_FAIL;
    }
}
exports.LoadFailAction = LoadFailAction;
/**
 * Delete List Item Actions
 */
class DeleteAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.DELETE;
    }
}
exports.DeleteAction = DeleteAction;
class DeleteFailAction {
    constructor() {
        this.type = exports.DELETE_FAIL;
    }
}
exports.DeleteFailAction = DeleteFailAction;
/**
 * Order List Actions
 */
class ToggleOrderAction {
    constructor() {
        this.type = exports.TOGGLE_ORDER;
    }
}
exports.ToggleOrderAction = ToggleOrderAction;
/**
 * Search List Actions
 */
class SearchAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SEARCH;
    }
}
exports.SearchAction = SearchAction;
