<mc-table
  [isLoading]="isLoading"
  [columns]="columns"
  [data]="tableData"
  [highlightedRow]="selectedImTask"
  (rowClick)="viewSelectedTask($event)"
  translatePrefix="$I18N_SERVICES_IM_SYNC_COMPONENTS_IM_SYNC_TABLE"
>
  <button
    *mcCapabilities="'Permission.INSTANT_MESSAGING_EDIT'"
    class="mc-table-actions btn btn-primary mc-create-button"
    (click)="this.onCreateTask.emit()"
  >
    {{ '$I18N_SERVICES_IM_SYNC_COMPONENTS_IM_SYNC_TABLE.CREATE_TASK' | translate }}
  </button>
  <mc-filters
    [metadata]="metaData"
    [showPagination]="true"
    (paginatorChange)="onPaginationChange.emit($event)"
  >
    <mc-filter-bundle-filter-by-and-search-by
      (filtersChange)="onFilterChange.emit($event)"
      placeholder="$I18N_SERVICES_IM_SYNC_COMPONENTS_IM_SYNC_TABLE.FILTERS.SEARCH_FIELD_PLACEHOLDER"
      [sections]="sections"
      [searchByFields]="searchByFields"
      [minLengthForSearchField]="minLengthForSearchField"
      [maxLengthForSearchField]="maxLengthForSearchField"
    >
    </mc-filter-bundle-filter-by-and-search-by>
  </mc-filters>
  <mc-column key="name">
    <mc-body-cell *mcBodyCellDef="let row">{{ row.name }}</mc-body-cell>
  </mc-column>
  <mc-column key="source">
    <mc-body-cell *mcBodyCellDef="let row">
      {{ getSourceTranslationKey(row.source) | uppercase | translate }}
    </mc-body-cell>
  </mc-column>
  <mc-column key="createdBy">
    <mc-body-cell *mcBodyCellDef="let row">{{ row.createdBy }}</mc-body-cell>
  </mc-column>
  <mc-column key="created">
    <mc-body-cell *mcBodyCellDef="let row">{{
      row.created | date: dateFormat
    }}</mc-body-cell>
  </mc-column>
  <mc-column key="lastExecutionStart">
    <mc-body-cell *mcBodyCellDef="let row">{{
      row.executionSummary.lastExecutionStart | date: dateFormat
    }}</mc-body-cell>
  </mc-column>
  <mc-column key="state">
    <mc-body-cell *mcBodyCellDef="let row">
      <mc-result-status-indicator [status]="row.executionSummary.executionState" [isSelected]="selectedImTask && selectedImTask.id === row.id"></mc-result-status-indicator>
    </mc-body-cell>
  </mc-column>
  <mc-column key="taskType">
    <mc-body-cell *mcBodyCellDef="let row">
      {{ '$I18N_SERVICES_IM_SYNC_COMPONENTS_IM_SYNC_TABLE.TYPE.' + row.taskType | uppercase | translate }}
    </mc-body-cell>
  </mc-column>
  <mc-column key="status">
    <mc-body-cell data-test="task-status" *mcBodyCellDef="let row">
      {{ '$I18N_SERVICES_IM_SYNC_COMPONENTS_IM_SYNC_TABLE.TASK_STATUS.' + row.status | uppercase | translate }}
    </mc-body-cell>
  </mc-column>
  <mc-column-actions key="right-column" mcShowColumnConfig
                     [columnsAlwaysVisible]="columnsAlwaysVisible"
                     [columnsToStartHidden]="columnsStartHidden">
    <mc-row-actions *mcRowActions="let row">
      <li
        mcRowAction="$I18N_COMMON_BTN_VIEW"
        (click)="viewSelectedTask(row)"
      ></li>
      <ng-container *mcCapabilities="'Permission.INSTANT_MESSAGING_EDIT'">
        <ng-container *ngIf="!isTaskLegacy(row.taskType)">
        <li
          data-test="edit-meatball-action"
          mcRowAction="$I18N_COMMON_BTN_EDIT"
          (click)="updateSelectedTask(row)"
        ></li>
        <li
          data-test="update-meatball-action"
          mcRowAction="{{ '$I18N_SERVICES_IM_SYNC_COMPONENTS_IM_SYNC_TABLE.STATUS.' + row.status | uppercase  | translate }}"
          (click)="updateSelectedTaskStatus(row)"
        ></li>
      </ng-container>
      </ng-container>
    </mc-row-actions>
  </mc-column-actions>
  <mc-empty-results
    keyTitle="{{ '$I18N_SERVICES_IM_SYNC_COMPONENTS_IM_SYNC_TABLE.NO_TASKS' | translate }}"
    iconClass="far fa-window-maximize"
  >
  </mc-empty-results>
</mc-table>
