<div class="mc-detailed-list mc-sidepanel-endpoints-details"
     [class.mc-detailed-list-two-columns]="true">
  <div *ngIf="deviceGroup">
    <mc-collapsable-panel header="$I18N_DEVICE_GROUPS_LIST.SIDEBAR.FIELD_LABELS.DETAILS_LABEL" [isExpanded]="true">
      <mc-value-field label="$I18N_DEVICE_GROUPS_LIST.SIDEBAR.FIELD_LABELS.GROUP_NAME"
                      [value]="deviceGroup.name">
      </mc-value-field>
      <mc-value-field label="$I18N_DEVICE_GROUPS_LIST.SIDEBAR.FIELD_LABELS.DESCRIPTION"
                      [value]="deviceGroup.description">
      </mc-value-field>
      <mc-value-field label="$I18N_DEVICE_GROUPS_LIST.SIDEBAR.FIELD_LABELS.DEVICE_COUNT"
                      [value]="deviceGroup.deviceCount">
      </mc-value-field>
      <mc-value-field label="$I18N_DEVICE_GROUPS_LIST.SIDEBAR.FIELD_LABELS.LAST_UPDATED"
                      [value]="deviceGroup.lastUpdated">
      </mc-value-field>
      <mc-value-field label="$I18N_DEVICE_GROUPS_LIST.SIDEBAR.FIELD_LABELS.CREATED_ON"
                      [value]="deviceGroup.createTime">
      </mc-value-field>
    </mc-collapsable-panel>
  </div>

</div>
<hr>
<div>
  <mc-collapsable-panel header="$I18N_DEVICE_GROUPS_LIST.SIDEBAR.FIELD_LABELS.DEVICE_LIST_LABEL" [isExpanded]="true">
    <div class="mc-side-panel-device-list-table">
      <mc-swg-device-list-view
        [deviceGroup]="deviceGroup">
      </mc-swg-device-list-view>
    </div>
  </mc-collapsable-panel>
</div>
<hr>
