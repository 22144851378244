"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteConfirmationModalComponent = void 0;
const queue_type_enum_1 = require("../../types/queue-type.enum");
const dashboard_settings_1 = require("../../model/supervision/dashboard/settings/dashboard-settings");
class DeleteConfirmationModalComponent {
    constructor(overlayRef, overlayData) {
        this.overlayRef = overlayRef;
        this.overlayData = overlayData;
        this.escalationQueues = [];
        this.associatedReviewQueues = [];
        this.entityName = this.overlayData.entityName;
        if (this.overlayData.entityDeletionType === dashboard_settings_1.DeletionType.QUEUE) {
            this.queueType = this.overlayData.queueType;
            this.escalationQueues = this.overlayData.escalationQueues;
            this.associatedReviewQueues = this.overlayData.associatedReviewQueues;
            this.associatedQueueType = this.setAssociatedQueueType(this.queueType);
        }
    }
    close(confirmed) {
        this.overlayRef.close(confirmed);
    }
    setAssociatedQueueType(selectedQueueType) {
        return selectedQueueType === queue_type_enum_1.QueueType.REVIEW ? queue_type_enum_1.QueueType.ESCALATION : queue_type_enum_1.QueueType.REVIEW;
    }
}
exports.DeleteConfirmationModalComponent = DeleteConfirmationModalComponent;
