<div class="mc-directory-sync-wizard-container">
  <mc-wizard-step-header [label]="'$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.HEADER' | translate: { integrationType: integrationType() }"></mc-wizard-step-header>
    <form
      class="form-horizontal"
      mcDefaultLabelClass="col-sm-3"
      mcDefaultControlContainerClass="col-sm-6"
      [formGroup]="form"
    >
      <mc-text-field formControlName="appID"
                     label="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.APP_ID"
                     placeholder="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.APP_ID"
                     popoverPlacement="top"
                     [helpPopoverContent]="appIdTooltip"
                     errorPrefix="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.APP_ID_ERROR"
                     required
      >
      </mc-text-field>

      <mc-field label="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.KEY"
                popoverPlacement="top"
                [helpPopoverContent]="keyTooltip"
                errorPrefix="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.KEY_ERROR"
                required
      >
        <input class="form-control"
               type="password"
               placeholder="{{'$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.KEY' | translate}}"
               formControlName="key"
               name="key"
               aria-label="key"
        />
      </mc-field>

      <mc-text-field formControlName="tenantDomain"
                     label="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.TENANT_DOMAIN"
                     placeholder="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.TENANT_DOMAIN"
                     popoverPlacement="top"
                     helpPopoverContent="{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.TENANT_DOMAIN_TOOLTIP' | translate }}"
                     errorPrefix="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.TENANT_DOMAIN_ERROR"
                     required
      >
      </mc-text-field>
    </form>
</div>

<ng-template #appIdTooltip>
  <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.APP_ID_TOOLTIP_1' | translate }}</span>
  <a href="{{ appIdAndKeyTooltipKbLink }}" target="_blank" rel="noopener noreferrer">
    <u><span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.APP_ID_TOOLTIP_2' | translate }}</span></u>
  </a>
  <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.APP_ID_TOOLTIP_3' | translate }}</span>
</ng-template>

<ng-template #keyTooltip>
  <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.KEY_TOOLTIP_1' | translate }}</span>
  <a href="{{ appIdAndKeyTooltipKbLink }}" target="_blank" rel="noopener noreferrer">
    <u><span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.KEY_TOOLTIP_2' | translate }}</span></u>
  </a>
  <span>{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.KEY_TOOLTIP_3' | translate }}</span>
</ng-template>
