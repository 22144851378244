"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DnsService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
class DnsService {
    constructor(dnsApiService) {
        this.dnsApiService = dnsApiService;
    }
    getCertificateDownloadUrl() {
        // @ts-ignore
        return this.dnsApiService.getCertificateDownloadUrl().pipe(operators_1.catchError(() => {
            return rxjs_1.of('');
        }));
    }
    getDnsServerIps() {
        // @ts-ignore
        return this.dnsApiService.getDnsServerIps().pipe(operators_1.map((dnsIp) => {
            return dnsIp.ipAddresses;
        }), operators_1.catchError(() => {
            return rxjs_1.of([]);
        }));
    }
}
exports.DnsService = DnsService;
