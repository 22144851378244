<div class="mc-detailed-list">
  <mc-wizard-step-header
    class="mc-supervision-wizard-step-header"
    label="$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.SUMMARY_STEP.DESCRIPTION"
  >
  </mc-wizard-step-header>
  <p>{{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.SUMMARY_STEP.STEP_TITLE' | translate }}</p>
  <div class="row mc-detailed-list-row">
    <div class="col-xs-2 mc-detailed-list-label">
      {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.DETAILS_STEP.NAME' | translate }}
    </div>
    <div class="mc-detailed-list-column">{{ getStepDataById(1).name }}</div>
  </div>
  <div class="row mc-detailed-list-row">
    <div class="col-xs-2 mc-detailed-list-label">
      {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.DETAILS_STEP.STEP_DESCRIPTION' | translate }}
    </div>
    <div class="mc-detailed-list-column">{{ getStepDataById(1).description }}</div>
  </div>
  <div class="row mc-detailed-list-row">
    <div class="col-xs-2 mc-detailed-list-label">
      {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.DETAILS_STEP.TYPE' | translate }}
    </div>
    <div *ngIf="getStepDataById(1).qtype === QueueType.REVIEW">
      <div class="mc-detailed-list-column">
        {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.DETAILS_STEP.TYPE_REVIEW_QUEUE' | translate }}
      </div>
    </div>
    <div *ngIf="getStepDataById(1).qtype === QueueType.ESCALATION">
      <div class="mc-detailed-list-column">
        {{
          '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.DETAILS_STEP.TYPE_ESCALATION_QUEUE' | translate
        }}
      </div>
    </div>
  </div>
  <div *ngIf="getStepDataById(1).qtype === QueueType.REVIEW" class="row mc-detailed-list-row">
    <div class="col-xs-2 mc-detailed-list-label">
      {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.DETAILS_STEP.TYPE_ESCALATION_QUEUE' | translate }}
    </div>
    <div class="col-xs-9 col-xs-offset-0">
      <div *ngFor="let queue of getStepDataById(1).escalationQueues" data-test="escalationQueue">
        {{ queue.name }} {{ isDefault(queue) }}
      </div>
    </div>
  </div>
  <p>{{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.SETTINGS_STEP.STEP_TITLE' | translate }}</p>
  <div *ngIf="getStepDataById(1).qtype === QueueType.ESCALATION" class="row mc-detailed-list-row">
    <div class="col-xs-2 mc-detailed-list-label">
      {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.SETTINGS_STEP.ALLOW_PRINTING' | translate }}
    </div>
    <div class="mc-detailed-list-column">
      {{ formatEnabled(getStepDataById(2).allowPrinting) }}
    </div>
  </div>
  <div class="row mc-detailed-list-row">
    <div class="col-xs-2 mc-detailed-list-label">
      {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.SETTINGS_STEP.ALLOW_FORWARDING' | translate }}
    </div>
    <div class="mc-detailed-list-column">
      {{ formatEnabled(getStepDataById(2).allowForwarding) }}
    </div>
  </div>
  <div class="row mc-detailed-list-row">
    <div class="col-xs-2 mc-detailed-list-label">
      {{
        (getStepDataById(1).qtype === QueueType.ESCALATION
          ? '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.SETTINGS_STEP.ESCALATION_MANAGERS'
          : '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.SETTINGS_STEP.REVIEWERS') | translate
      }}
    </div>
    <div class="col-xs-9 col-xs-offset-0">
      <div *ngFor="let reviewer of getStepDataById(2).reviewers">
        {{ getReviewerName(reviewer.reviewer) }}
      </div>
    </div>
  </div>
  <div class="row mc-detailed-list-row">
    <div class="col-xs-2 mc-detailed-list-label">
      {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.SETTINGS_STEP.LABELS' | translate }}
    </div>
    <div class="col-xs-9 col-xs-offset-0">
      <div *ngFor="let label of getStepDataById(2).labels">{{ label.name }}</div>
    </div>
  </div>
</div>
