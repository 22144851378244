"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectorsTableComponent = void 0;
const core_1 = require("@angular/core");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const delete_connector_modal_component_1 = require("../delete-connector-modal/delete-connector-modal.component");
const tableConfig = require("./connectors-table-config");
const store_1 = require("@ngrx/store");
const connector_actions_1 = require("../../actions/connector.actions");
const reducers = require("../../reducers");
class ConnectorsTableComponent {
    constructor(modalService, stateService, store, connectorsStore) {
        this.modalService = modalService;
        this.stateService = stateService;
        this.store = store;
        this.connectorsStore = connectorsStore;
        this.onPaginationChange = new core_1.EventEmitter();
        this.onFilterChange = new core_1.EventEmitter();
        this.onCreateConnector = new core_1.EventEmitter();
        this.onDeleteConnector = new core_1.EventEmitter();
        this._metaData = {};
        this.dateFormat = 'dd MMM yyyy - HH:mm';
        this.destroy$ = new rxjs_1.Subject();
        this.columns = tableConfig.columns;
        this.searchByFields = tableConfig.searchByFields;
        this.columnsAlwaysVisible = tableConfig.columnsAlwaysVisible;
        this.maxLengthForSearchField = tableConfig.maxLengthForSearchField;
        this.minLengthForSearchField = tableConfig.minLengthForSearchField;
    }
    set metaData(metaData) {
        if (metaData) {
            this._metaData = Object.assign(Object.assign({}, metaData), { pagination: Object.assign(Object.assign({}, metaData.pagination), { totalRows: metaData.pagination.totalCount }) });
        }
    }
    get metaData() {
        return this._metaData;
    }
    ngOnInit() {
        this.selectedRow$ = this.connectorsStore.pipe(store_1.select(reducers.selectedRow));
        this.selectedRowSubscription = this.selectedRow$
            .pipe(operators_1.takeUntil(this.destroy$))
            .subscribe((selectedRow) => {
            if (selectedRow) {
                const currentIndex = this.tableData
                    .map(connector => connector.id)
                    .indexOf(selectedRow.id);
                const pagination = {
                    currentIndex,
                    prevRow: currentIndex > 0 ? this.tableData[currentIndex - 1] : undefined,
                    nextRow: currentIndex < this.tableData.length - 1
                        ? this.tableData[currentIndex + 1]
                        : undefined,
                    pageSize: this.tableData.length
                };
                this.store.dispatch(new connector_actions_1.SetAsidePagination(pagination));
            }
        });
    }
    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
    openDeleteConfirmationModal(connector) {
        this.store.dispatch(new connector_actions_1.GetConnectorUsageAction(connector.id));
        const data = {
            connectorName: connector.name,
            provider: connector.provider
        };
        const modalConfig = {};
        modalConfig.size = 'md';
        modalConfig.hasBackdrop = true;
        modalConfig.disableClose = true;
        modalConfig.data = data;
        this.modalService
            .open(delete_connector_modal_component_1.DeleteConnectorModalComponent, modalConfig)
            .afterClose()
            .pipe(operators_1.takeUntil(this.destroy$))
            .subscribe((confirmed) => {
            if (confirmed) {
                this.deleteConnector(connector);
            }
        });
    }
    deleteConnector(connector) {
        this.onDeleteConnector.emit(connector.id);
    }
    editConnector(connector) {
        this.stateService.$state.go('services-edit-connector-page', Object.assign({}, connector));
    }
    onRowClick(row) {
        this.store.dispatch(new connector_actions_1.GetConnectorDetails(row));
    }
}
exports.ConnectorsTableComponent = ConnectorsTableComponent;
