"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UrlProtectionAppliesToSettingsStepComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const domain_validator_1 = require("app-new/components/validators/domain/domain.validator");
const policies_create_actions_1 = require("../../../actions/policies-create.actions");
const url_protection_applies_to_settings_step_component_static_values_1 = require("./url-protection-applies-to-settings-step.component.static-values");
const url_protection_applies_to_settings_step_models_1 = require("./url-protection-applies-to-settings-step.models");
const group_selector_component_1 = require("app-new/components/group-selector/group-selector.component");
const ipv4_validator_1 = require("../../../../../common/policies/validators/ipv4-validator");
class UrlProtectionAppliesToSettingsStepComponent {
    constructor(fb, store, asideService) {
        this.fb = fb;
        this.store = store;
        this.asideService = asideService;
        this.fromToWhereAttributesSelected = new core_1.EventEmitter();
        this.whereAttributesSet = false;
        this.whereAttributes = url_protection_applies_to_settings_step_component_static_values_1.noFromToAddressAttributesOption;
        this.fieldName = url_protection_applies_to_settings_step_models_1.FieldName;
        this.addressesBasedOnOptions = url_protection_applies_to_settings_step_component_static_values_1.addressesBasedOnOptions;
        this.appliesFromToOptions = {
            from: url_protection_applies_to_settings_step_component_static_values_1.appliesFromToOptions,
            to: url_protection_applies_to_settings_step_component_static_values_1.appliesFromToOptions
        };
        this.ipRanges = [[]];
        this.editRuleIds = [];
        this.appliesFromToAddresssValidators = [
            forms_1.Validators.required,
            forms_1.Validators.email,
            forms_1.Validators.maxLength(128)
        ];
        this.appliesFromToDomainValidators = [forms_1.Validators.required, domain_validator_1.domainValidator];
        this.emailAddressFieldType = url_protection_applies_to_settings_step_component_static_values_1.AdditionalFieldsType.emailAddress;
        this.domainFieldType = url_protection_applies_to_settings_step_component_static_values_1.AdditionalFieldsType.domain;
        this.attributesFieldType = url_protection_applies_to_settings_step_component_static_values_1.AdditionalFieldsType.addressAttribute;
        this.groupFieldType = url_protection_applies_to_settings_step_component_static_values_1.AdditionalFieldsType.addressGroup;
        this.urlProtectionPolicyAppliesToSettingsForm = this.fb.group({
            rules: this.fb.array([this.createNewRule()])
        });
        this.rules = this.urlProtectionPolicyAppliesToSettingsForm.get('rules');
        this.rulesValueChangeSubscription = this.rules.valueChanges.subscribe(data => {
            if (Array.isArray(data)) {
                this.handleAddressAttributeSelection(data);
                this.storeStepData(data);
            }
        });
    }
    ngOnInit() {
        this.storeStepData(this.rules.value);
    }
    ngOnChanges(changes) {
        const appliesToDataChange = changes[url_protection_applies_to_settings_step_models_1.ApplieToStepSimpleChangeSelectors.POLICY_APPLIES_TO_DATA];
        const whereAttributesChange = changes[url_protection_applies_to_settings_step_models_1.ApplieToStepSimpleChangeSelectors.WHERE_ATTRIBUTES];
        const isAAAorFAAUserChange = changes[url_protection_applies_to_settings_step_models_1.ApplieToStepSimpleChangeSelectors.IS_AAA_OR_FAA_USER];
        if (!this.appliesToDataForUpdate && appliesToDataChange && appliesToDataChange.currentValue) {
            this.appliesToDataForUpdate = appliesToDataChange.currentValue;
        }
        this.setAndUpdateAppliesFromToControl(isAAAorFAAUserChange);
        this.setAndUpdateWhereAttributes(whereAttributesChange);
        if (appliesToDataChange && appliesToDataChange.currentValue) {
            this.updateStepWithPassedData(appliesToDataChange.currentValue);
        }
    }
    ngOnDestroy() {
        this.rulesValueChangeSubscription.unsubscribe();
    }
    handleAddressAttributeSelection(data) {
        const isAddressAttributeSelected = data.find(ruleData => {
            const appliesFromHasAddressAttributesSelected = ruleData.appliesFrom === url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.ADDRESS_ATTRIBUTE;
            const appliesToHasAddressAttributesSelected = ruleData.appliesTo === url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.ADDRESS_ATTRIBUTE;
            return appliesFromHasAddressAttributesSelected || appliesToHasAddressAttributesSelected;
        });
        this.fromToWhereAttributesSelected.emit(!!isAddressAttributeSelected);
    }
    onAppliesFromToSelectChange(emailFromSelected, index, isFromControl = true) {
        let addressControlName = 'appliesToAddress';
        let domainControlName = 'appliesToDomain';
        let isEqualToControlName = 'appliesToIsEqualTo';
        let addressGroupControlName = 'appliesToAddressGroup';
        if (isFromControl) {
            addressControlName = 'appliesFromAddress';
            domainControlName = 'appliesFromDomain';
            isEqualToControlName = 'appliesFromIsEqualTo';
            addressGroupControlName = 'appliesFromAddressGroup';
        }
        const rule = this.rules.at(index);
        if (!rule) {
            return;
        }
        const emailControl = rule.get(addressControlName);
        const domainControl = rule.get(domainControlName);
        const isEqualToControl = rule.get(isEqualToControlName);
        const addressGroupControl = rule.get(addressGroupControlName);
        if (emailFromSelected === url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.EMAIL_ADDRESS) {
            emailControl.setValidators(this.appliesFromToAddresssValidators);
        }
        else {
            emailControl.clearValidators();
            emailControl.patchValue('');
        }
        if (emailFromSelected === url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.DOMAIN) {
            domainControl.setValidators(this.appliesFromToDomainValidators);
        }
        else {
            domainControl.clearValidators();
            domainControl.patchValue('');
        }
        if (emailFromSelected === url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.ADDRESS_ATTRIBUTE) {
            isEqualToControl.setValidators([forms_1.Validators.required]);
            if (this.fromToWhereAttributes && this.fromToWhereAttributes.length === 0) {
                isEqualToControl.disable();
            }
        }
        else {
            isEqualToControl.enable();
            isEqualToControl.clearValidators();
            isEqualToControl.patchValue('');
        }
        if (emailFromSelected === url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.ADDRESS_GROUP) {
            addressGroupControl.setValidators([forms_1.Validators.required]);
        }
        else {
            addressGroupControl.clearValidators();
            addressGroupControl.patchValue(null);
        }
        emailControl.updateValueAndValidity();
        domainControl.updateValueAndValidity();
        isEqualToControl.updateValueAndValidity();
        addressGroupControl.updateValueAndValidity();
    }
    storeStepData(data) {
        if (this.urlProtectionPolicyAppliesToSettingsForm.invalid) {
            return;
        }
        const mappedData = data.map((item, index) => {
            const md = {
                addressesBasedOn: item.addressesBasedOn,
                appliesFrom: item.appliesFrom,
                fromWhereAttribute: this.getAttributeById(item.fromWhereAttribute, this.whereAttributes),
                appliesFromIsEqualTo: item.appliesFromIsEqualTo,
                appliesFromDependency: item.appliesFromDependency,
                appliesFromAddress: item.appliesFromAddress,
                appliesFromDomain: item.appliesFromDomain,
                appliesFromAddressGroup: item.appliesFromAddressGroup,
                appliesTo: item.appliesTo,
                toWhereAttribute: this.getAttributeById(item.toWhereAttribute, this.whereAttributes),
                appliesToIsEqualTo: item.appliesToIsEqualTo,
                appliesToAddress: item.appliesToAddress,
                appliesToDomain: item.appliesToDomain,
                appliesToAddressGroup: item.appliesToAddressGroup,
                appliesToDependency: item.appliesToDependency,
                bidirectional: item.bidirectional,
                ipRanges: this.ipRanges[index] ? [...this.ipRanges[index]] : []
            };
            if (this.editRuleIds[index]) {
                md.id = this.editRuleIds[index];
            }
            return md;
        });
        this.store.dispatch(new policies_create_actions_1.SaveAppliesToStepDataAction(mappedData));
    }
    getAttributeById(id, attributes) {
        if (id === '') {
            return url_protection_applies_to_settings_step_component_static_values_1.noFromToAddressAttributesOption[0];
        }
        const item = attributes.find(option => {
            return option.value === id;
        });
        return item ? item : url_protection_applies_to_settings_step_component_static_values_1.noFromToAddressAttributesOption[0];
    }
    showDependecy() {
        return false; // TODO: Everyone, All Internal and All external addresses don't need this
        // when next options are implemented, this will have real logic
    }
    createNewRule() {
        return this.fb.group({
            addressesBasedOn: [this.addressesBasedOnOptions[0].value],
            appliesFrom: [this.appliesFromToOptions.from[0].value],
            fromWhereAttribute: [
                this.whereAttributes[0].value || url_protection_applies_to_settings_step_component_static_values_1.noFromToAddressAttributesOption[0].value
            ],
            appliesFromIsEqualTo: [''],
            appliesFromDependency: [''],
            appliesFromAddress: [''],
            appliesFromDomain: [''],
            appliesFromAddressGroup: undefined,
            appliesTo: [this.appliesFromToOptions.to[0].value],
            toWhereAttribute: [this.whereAttributes[0].value || url_protection_applies_to_settings_step_component_static_values_1.noFromToAddressAttributesOption[0].value],
            appliesToIsEqualTo: [''],
            appliesToDependency: [''],
            appliesToAddress: [''],
            appliesToDomain: [''],
            appliesToAddressGroup: undefined,
            bidirectional: false,
            ipRange: ['', ipv4_validator_1.IPV4AndSubnetMaskValidator]
        });
    }
    showAppliesAdditionalField(control, controlType) {
        if (!control.value) {
            return;
        }
        const controls = [
            url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.DOMAIN,
            url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.ADDRESS_GROUP,
            url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.ADDRESS_ATTRIBUTE,
            url_protection_applies_to_settings_step_models_1.AppliesFromToOptions.EMAIL_ADDRESS
        ];
        return control.value === controls[controlType];
    }
    addNewRange(control, groupIndex) {
        if (!control.value) {
            return;
        }
        const value = control.value.trim();
        if (value) {
            this.ipRanges[groupIndex].push(control.value);
            this.storeStepData(this.rules.value);
        }
        control.reset();
    }
    removeRange(groupIndex, index) {
        this.ipRanges[groupIndex].splice(index, 1);
        this.storeStepData(this.rules.value);
    }
    addNewRule() {
        this.ipRanges.push([]);
        this.rules.push(this.createNewRule());
    }
    removeRule(index) {
        this.rules.removeAt(index);
        this.ipRanges.splice(index, 1);
    }
    openSidebar(fieldName, index) {
        const asideConfig = {};
        const aside = this.asideService.open(group_selector_component_1.GroupSelectorComponent, asideConfig);
        aside.beforeClose().subscribe(selectedGroup => {
            if (selectedGroup) {
                this.patchRuleAddressGroup(index, fieldName, selectedGroup);
            }
        });
    }
    onDeleteGroup(fieldName, index) {
        this.patchRuleAddressGroup(index, fieldName, null);
    }
    patchRuleAddressGroup(ruleIndex, ruleFieldName, patchValue) {
        const rule = this.rules.at(ruleIndex);
        rule.get(ruleFieldName).patchValue(patchValue);
        this.rules.setControl(ruleIndex, rule);
    }
    isValid() {
        return this.urlProtectionPolicyAppliesToSettingsForm.status === 'VALID';
    }
    updateStepWithPassedData(rules) {
        if (!rules) {
            return;
        }
        this.ipRanges = [[]];
        this.editRuleIds = [];
        rules.forEach((rule, index) => {
            let toFormValue = Object.assign({}, rule);
            if (toFormValue.fromWhereAttribute) {
                toFormValue = Object.assign(Object.assign({}, toFormValue), { fromWhereAttribute: toFormValue.fromWhereAttribute.value });
            }
            if (toFormValue.toWhereAttribute) {
                toFormValue = Object.assign(Object.assign({}, toFormValue), { toWhereAttribute: toFormValue.toWhereAttribute.value });
            }
            const ipRanges = rule.ipRanges && rule.ipRanges.length ? [...rule.ipRanges] : [];
            this.rules.setControl(index, this.createNewRule());
            this.rules.at(index).patchValue(toFormValue);
            if (this.ipRanges[index]) {
                this.ipRanges[index].push(...ipRanges);
            }
            else {
                this.ipRanges.push([...ipRanges]);
            }
            this.editRuleIds.push(rule.id);
            this.rules.updateValueAndValidity();
        });
        this.urlProtectionPolicyAppliesToSettingsForm.updateValueAndValidity();
    }
    setAppliesToOptions() {
        if (this.isAAAorFAAUser) {
            this.appliesFromToOptions = {
                from: url_protection_applies_to_settings_step_component_static_values_1.ffaUserAppliesFromOptions,
                to: url_protection_applies_to_settings_step_component_static_values_1.ffaUserAppliesToOptions
            };
        }
    }
    setAndUpdateAppliesFromToControl(isAAAorFAAUserChange) {
        if (isAAAorFAAUserChange &&
            isAAAorFAAUserChange.currentValue &&
            isAAAorFAAUserChange.firstChange) {
            this.setAppliesToOptions();
            this.rules.setControl(0, this.createNewRule());
            this.updateStepWithPassedData(this.appliesToDataForUpdate);
        }
    }
    setAndUpdateWhereAttributes(whereAttributesChange) {
        if (!this.whereAttributesSet &&
            whereAttributesChange.currentValue &&
            whereAttributesChange.currentValue.length) {
            this.whereAttributes = whereAttributesChange.currentValue;
            this.rules.setControl(0, this.createNewRule());
            this.whereAttributesSet = true;
            this.updateStepWithPassedData(this.appliesToDataForUpdate);
        }
    }
}
exports.UrlProtectionAppliesToSettingsStepComponent = UrlProtectionAppliesToSettingsStepComponent;
