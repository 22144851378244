"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isLoadingQueueConfigDetails = exports.getQueueConfigDetails = exports.reducer = exports.initialState = void 0;
const queueListActions = require("../actions/queue.list.actions");
exports.initialState = {
    queueDeleted: false,
    isDeletingQueue: false,
    queueConfigDetails: null,
    isLoadingQueueConfigDetails: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case queueListActions.QueueListActions.DELETE_QUEUE:
            return Object.assign(Object.assign({}, state), { isDeletingQueue: true, queueDeleted: false });
        case queueListActions.QueueListActions.DELETE_QUEUE_SUCCESS:
            return Object.assign(Object.assign({}, state), { isDeletingQueue: false, queueDeleted: true });
        case queueListActions.QueueListActions.DELETE_QUEUE_FAIL:
            return Object.assign(Object.assign({}, state), { isDeletingQueue: false, queueDeleted: false });
        case queueListActions.QueueListActions.LOAD_QUEUE_CONFIG_DETAILS:
            return Object.assign(Object.assign({}, state), { isLoadingQueueConfigDetails: true, queueConfigDetails: null });
        case queueListActions.QueueListActions.LOAD_QUEUE_CONFIG_DETAILS_SUCCESS:
            return Object.assign(Object.assign({}, state), { isLoadingQueueConfigDetails: false, queueConfigDetails: action.payload });
        case queueListActions.QueueListActions.LOAD_QUEUE_CONFIG_DETAILS_FAILURE:
            return Object.assign(Object.assign({}, state), { isLoadingQueueConfigDetails: false, queueConfigDetails: null });
        default:
            return Object.assign({}, state);
    }
}
exports.reducer = reducer;
const getQueueConfigDetails = (state) => state && state.queueConfigDetails;
exports.getQueueConfigDetails = getQueueConfigDetails;
const isLoadingQueueConfigDetails = (state) => state && state.isLoadingQueueConfigDetails;
exports.isLoadingQueueConfigDetails = isLoadingQueueConfigDetails;
