"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UrlProtectionOutboundSettingsStepComponent = void 0;
const url_protection_outbound_settings_step_component_static_values_1 = require("./url-protection-outbound-settings-step.component.static-values");
const group_selector_component_1 = require("app-new/components/group-selector/group-selector.component");
const actions = require("../../../actions/policies-create.actions");
const reducers = require("../../../reducers");
const policies_create_actions_1 = require("../../../actions/policies-create.actions");
const operators_1 = require("rxjs/operators");
class UrlProtectionOutboundSettingsStepComponent {
    constructor(fb, asideService, store) {
        this.fb = fb;
        this.asideService = asideService;
        this.store = store;
        this.urlScanningModeValues = url_protection_outbound_settings_step_component_static_values_1.urlScanningModeValues;
        this.urlCategoryScanningModeValues = url_protection_outbound_settings_step_component_static_values_1.urlCategoryScanningModeValues;
        this.gatewayActionValues = url_protection_outbound_settings_step_component_static_values_1.gatewayActionValues;
        this.gatewayFallbackActionValues = url_protection_outbound_settings_step_component_static_values_1.gatewayFallbackActionValues;
        this.senderMailboxActionValues = url_protection_outbound_settings_step_component_static_values_1.senderMailboxActionValues;
        this.senderMailboxFallbackActionValues = url_protection_outbound_settings_step_component_static_values_1.senderMailboxFallbackActionValues;
        this.urlFileDownloadValues = url_protection_outbound_settings_step_component_static_values_1.urlFileDownloadValues;
        this.selectedGroupDescription$ = this.store.select(reducers.getOutboundAdministratorsSelectedGroupDescription);
        this.urlProtectionPolicyOutboundSettingsForm = this.fb.group({
            checkOutboundEmail: [true],
            urlScanningMode: [this.urlScanningModeValues[2].value],
            urlCategoryScanningMode: [this.urlCategoryScanningModeValues[2].value],
            gatewayAction: [this.gatewayActionValues[1].value],
            gatewayFallbackAction: [this.gatewayFallbackActionValues[1].value],
            senderMailboxAction: [this.senderMailboxActionValues[1].value],
            senderMailboxFallbackAction: [this.senderMailboxFallbackActionValues[1].value],
            blockUrlWithDangerousFileExtensions: [true],
            urlFileDownload: [this.urlFileDownloadValues[0].value],
            scanUrlInAttachments: [false],
            attachmentPartHTML: [false],
            attachmentPartText: [false],
            attachmentPartCalendar: [false],
            attachmentPartOther: [false],
            checkForSimilarUrls: [false],
            domainToCheckAgainstYourInternalDomains: [false],
            domainToCheckAgainstMimecastMonitoredExternalDomains: [false],
            domainToCheckAgainstCustomMonitoredExternalDomains: [false],
            users: [false]
        });
        this.startSubscriptions();
    }
    ngOnInit() {
        this.saveStoreData(this.urlProtectionPolicyOutboundSettingsForm.value);
    }
    ngOnChanges(changes) {
        const change = changes['policyOutboundData'];
        if (change && change.previousValue !== change.currentValue) {
            if (change.currentValue) {
                this.urlProtectionPolicyOutboundSettingsForm.patchValue(change.currentValue);
            }
        }
    }
    ngOnDestroy() {
        this.formValueChangeSubscription.unsubscribe();
        this.scanUrlInAttachmentsValueSubscription.unsubscribe();
        this.checkForSimilarUrlsValueSubscription.unsubscribe();
    }
    startSubscriptions() {
        this.formValueChangeSubscription = this.urlProtectionPolicyOutboundSettingsForm.valueChanges
            .pipe(operators_1.debounceTime(300))
            .subscribe(data => this.saveStoreData(data));
        this.scanUrlInAttachmentsValueSubscription = this.urlProtectionPolicyOutboundSettingsForm
            .get(url_protection_outbound_settings_step_component_static_values_1.formControlNames.SCAN_URLS_IN_ATTACHMENTS)
            .valueChanges.subscribe(value => {
            url_protection_outbound_settings_step_component_static_values_1.formControlsToUpdateOnScanUrlsInAttachments.forEach((control) => {
                this.urlProtectionPolicyOutboundSettingsForm.get(control).patchValue(value);
            });
        });
        this.checkForSimilarUrlsValueSubscription = this.urlProtectionPolicyOutboundSettingsForm
            .get(url_protection_outbound_settings_step_component_static_values_1.formControlNames.CHECK_FOR_SIMILAR_URLS)
            .valueChanges.subscribe(value => {
            url_protection_outbound_settings_step_component_static_values_1.formControlsToUpdateOnCheckForSimilarUrls.forEach((control) => {
                this.urlProtectionPolicyOutboundSettingsForm.get(control).patchValue(value);
            });
        });
    }
    saveStoreData(data) {
        this.store.dispatch(new policies_create_actions_1.SaveOutboundStepDataAction(data));
    }
    checkOutboundEmail() {
        return this.urlProtectionPolicyOutboundSettingsForm.get('checkOutboundEmail').value;
    }
    checkForSimilarUrls() {
        return this.urlProtectionPolicyOutboundSettingsForm.get('checkForSimilarUrls').value;
    }
    isScanUrlInAttachments() {
        return this.urlProtectionPolicyOutboundSettingsForm.get('scanUrlInAttachments').value;
    }
    openSidebar() {
        const asideConfig = {};
        const aside = this.asideService.open(group_selector_component_1.GroupSelectorComponent, asideConfig);
        aside.beforeClose().subscribe(selectedGroup => {
            if (selectedGroup) {
                this.store.dispatch(new actions.OutboundAdministratorSelectGroup(selectedGroup));
            }
        });
    }
    onDeleteGroup() {
        this.store.dispatch(new actions.RemoveOutboundAdministratorGroupAction());
    }
}
exports.UrlProtectionOutboundSettingsStepComponent = UrlProtectionOutboundSettingsStepComponent;
