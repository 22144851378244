"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiWildfireIntegrationAsideComponent = void 0;
const actions = require("app-new/api-applications/actions");
const reducers = require("app-new/api-applications/reducers");
class ApiWildfireIntegrationAsideComponent {
    constructor(store, integration) {
        this.store = store;
        this.integration = integration;
    }
    ngOnInit() {
        this.enabled$ = this.store.select(reducers.isIntegrationListApplicationEnabled(this.integration.id));
        this.wildfireIntegration$ = this.store.select(reducers.getIntegrationWildfireIntegration);
    }
    enable() {
        this.store.dispatch(new actions.EnableIntegrationAction({ integration: this.integration }));
    }
    disable() {
        this.store.dispatch(new actions.DisableIntegrationAction({ integration: this.integration }));
    }
    edit() {
        this.store.dispatch(new actions.StartIntegrationWildfireAction({ runChecks: false }));
    }
    close() {
        this.store.dispatch(new actions.CloseViewIntegrationAction());
    }
}
exports.ApiWildfireIntegrationAsideComponent = ApiWildfireIntegrationAsideComponent;
