"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IdentityDashboardPageComponent = void 0;
const dashboard_page_static_values_1 = require("./dashboard-page.static-values");
class IdentityDashboardPageComponent {
    constructor(tabChangeService, stateService) {
        this.tabChangeService = tabChangeService;
        this.stateService = stateService;
        this.identityDashboardTabs = dashboard_page_static_values_1.identityDashboardTabs;
    }
    onTabChange(event) {
        this.tabChangeService.setTabHistory(event);
        this.container.scrollTop = 0;
    }
    ngAfterViewInit() {
        this.container = document.getElementsByClassName('mc-layout-list-extra-container')[0];
        this.tabChangeService.setTabs(this.tabGroup);
        const position = this.identityDashboardTabs
            .map(val => val.url)
            .indexOf(this.stateService.$state.params.tabName);
        if (position !== -1) {
            this.tabGroup.tabClicked(position);
        }
    }
}
exports.IdentityDashboardPageComponent = IdentityDashboardPageComponent;
