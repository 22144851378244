"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OK_CANCEL_BUTTON_SET = exports.OK_BUTTON_SET = exports.CANCEL_BUTTON = exports.OK_BUTTON = void 0;
exports.OK_BUTTON = {
    id: 'OK',
    title: '$I18N_MODAL_ACTION_OK',
    type: 'primary'
};
exports.CANCEL_BUTTON = {
    id: 'CANCEL',
    title: '$I18N_MODAL_ACTION_CANCEL',
    type: 'default'
};
exports.OK_BUTTON_SET = [exports.OK_BUTTON];
exports.OK_CANCEL_BUTTON_SET = [exports.OK_BUTTON, exports.CANCEL_BUTTON];
