<div *ngIf="(purgeMessagesCount$ | async) === 0">
  <div class="mc-wem-title">
    {{ '$I18N_MESSAGE_CENTER_PURGE_MESSAGES_CONFIRM_MODAL.NO_MESSAGES_TITLE' | translate }}
  </div>
  <div class="text-normal panel-margin-top">
    {{'$I18N_MESSAGE_CENTER_PURGE_MESSAGES_CONFIRM_MODAL.NO_MESSAGES_SUBTITLE' | translate }}
  </div>
  <mc-modal-footer class="no-padding-bottom no-padding-right">
    <button class="btn btn-primary" (click)="close()">{{
      '$I18N_MESSAGE_CENTER_PURGE_MESSAGES_CONFIRM_MODAL.OK' | translate
      }}</button>
  </mc-modal-footer>
</div>

<div *ngIf="(purgeMessagesCount$ | async) > 0">
  <div class="mc-wem-title">
    {{ '$I18N_MESSAGE_CENTER_PURGE_MESSAGES_CONFIRM_MODAL.PURGE_MESSAGES_TITLE' | translate: { count: (purgeMessagesCount$ | async) } }}
  </div>

  <div class="warning-panel">
    <div class="ribbon">
      <span class="icon fas fa-exclamation-circle"></span>
    </div>
    <div class="content">
      <div class="message">{{ '$I18N_MESSAGE_CENTER_PURGE_MESSAGES_CONFIRM_MODAL.PURGE_WARNING' | translate }}</div>
    </div>
  </div>

  <form [formGroup]="form" (ngSubmit)="onPurge()">
    <mc-text-area-field formControlName="reason" required
                        label="$I18N_MESSAGE_CENTER_PURGE_MESSAGES_CONFIRM_MODAL.REASON_LABEL"
                        rows="5"
                        errorPrefix="$I18N_MESSAGE_CENTER_PURGE_MESSAGES_CONFIRM_MODAL.ERRORS">
    </mc-text-area-field>

    <mc-modal-footer class="no-padding-bottom no-padding-right">
      <button type="button" class="btn btn-secondary no-border" (click)="close()">
        {{'$I18N_MESSAGE_CENTER_PURGE_MESSAGES_CONFIRM_MODAL.CANCEL_BUTTON_LABEL' | translate}}
      </button>
      <button type="submit" class="btn btn-danger" [disabled]="form.invalid">
        <span>{{'$I18N_MESSAGE_CENTER_PURGE_MESSAGES_CONFIRM_MODAL.PURGE_BUTTON_LABEL' | translate}}</span>
      </button>
    </mc-modal-footer>
  </form>
</div>
