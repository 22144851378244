"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportEffects = void 0;
const effects_1 = require("@ngrx/effects");
const exportAction = require("../../actions/export.action");
const operators_1 = require("rxjs/operators");
const api_1 = require("../../../../components/export/api");
class ExportEffects {
    constructor(actions$, bouncesService) {
        this.actions$ = actions$;
        this.bouncesService = bouncesService;
        this.export$ = this.actions$.pipe(effects_1.ofType(exportAction.EXPORT), operators_1.map((action) => {
            const newPayload = this.bouncesService.transformToServerRequest(action.filter);
            return new api_1.OpenSidePanelExportApiAction('/api/gateway/export-bounces', action.payload, newPayload, '$18N_BOUNCES_LIST');
        }));
    }
}
__decorate([
    effects_1.Effect()
], ExportEffects.prototype, "export$", void 0);
exports.ExportEffects = ExportEffects;
