"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getApiApplication = exports.getApiApplicationNameDisabled = exports.getMode = exports.getStatus = exports.getSettings = exports.getDetails = exports.getIdentifier = exports.reducer = exports.initialState = void 0;
const actions = require("../../actions");
exports.initialState = {
    applicationNameDisabled: false,
    identifier: {
        id: '',
        applicationType: 'api'
    },
    details: {
        applicationName: '',
        description: '',
        category: null,
        service: false
    },
    settings: {
        developerEmail: '',
        developerName: '',
        newsSubscription: false
    },
    status: {
        active: true
    },
    mode: 'CREATE'
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.ApiGatewayListActionTypes.OpenCreateApiApplication:
            return exports.initialState;
        case actions.ApiGatewayListActionTypes.OpenEditApiApplication:
            return {
                applicationNameDisabled: false,
                identifier: {
                    id: action.payload.id,
                    applicationType: action.payload.applicationType
                },
                details: {
                    applicationName: action.payload.applicationName,
                    description: action.payload.description,
                    category: action.payload.category,
                    service: action.payload.service
                },
                settings: {
                    developerEmail: action.payload.developerEmail,
                    developerName: action.payload.developerName,
                    newsSubscription: action.payload.newsSubscription
                },
                status: {
                    active: action.payload.active
                },
                mode: 'EDIT'
            };
        case actions.ApiGatewaySaveActionTypes.InputApiApplicationDetails:
            return Object.assign(Object.assign({}, state), { details: action.payload });
        case actions.ApiGatewaySaveActionTypes.InputApiApplicationSettings:
            return Object.assign(Object.assign({}, state), { settings: action.payload });
        case actions.ApiGatewaySaveActionTypes.InputApiApplicationStatus:
            return Object.assign(Object.assign({}, state), { status: action.payload });
        case actions.ApiGatewaySaveActionTypes.CloseSaveApiApplication:
            return exports.initialState;
        case actions.ApiTechPartnerListActionTypes.OpenApiApplication:
            return Object.assign(Object.assign({}, state), { applicationNameDisabled: true, details: Object.assign(Object.assign({}, state.details), { applicationName: action.payload.apiName, category: action.payload.category, service: action.payload.service }) });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getIdentifier = (state) => state.identifier;
exports.getIdentifier = getIdentifier;
const getDetails = (state) => state.details;
exports.getDetails = getDetails;
const getSettings = (state) => state.settings;
exports.getSettings = getSettings;
const getStatus = (state) => state.status;
exports.getStatus = getStatus;
const getMode = (state) => state.mode;
exports.getMode = getMode;
const getApiApplicationNameDisabled = (state) => state.applicationNameDisabled;
exports.getApiApplicationNameDisabled = getApiApplicationNameDisabled;
const getApiApplication = (state) => {
    return {
        id: state.identifier.id,
        applicationType: state.identifier.applicationType,
        applicationName: state.details.applicationName,
        description: state.details.description,
        category: state.details.category,
        service: state.details.service,
        developerEmail: state.settings.developerEmail,
        developerName: state.settings.developerName,
        newsSubscription: state.settings.newsSubscription,
        active: state.status.active
    };
};
exports.getApiApplication = getApiApplication;
