"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LegacyPageEffects = void 0;
const effects_1 = require("@ngrx/effects");
const operators_1 = require("rxjs/operators");
const actions = require("../actions");
const reducers = require("../reducers");
class LegacyPageEffects {
    constructor(actions$, store, tabService, actionModalService, legacyTabConfigService, windowService) {
        this.actions$ = actions$;
        this.store = store;
        this.tabService = tabService;
        this.actionModalService = actionModalService;
        this.legacyTabConfigService = legacyTabConfigService;
        this.windowService = windowService;
        /*
         * if the back button is call in first Adcon3.5 page  meaning before that page is an adcon4 page,
         * we had to jump all the fake history we had created
         */
        this.backPageOfFirstPage$ = this.actions$.pipe(effects_1.ofType(actions.BACK_PAGE), operators_1.filter((action) => action.currentPage === 'first-adcon3.5-page'), operators_1.flatMap(() => this.store.select(reducers.getHistoryCount(this.tabService.getSelectedTab().id)).pipe(operators_1.first())), operators_1.map(historyCount => {
            this.windowService.history.go(-historyCount);
            return new actions.ResetCountAction(this.tabService.getSelectedTab().id);
        }));
        this.errorAction$ = this.actions$.pipe(effects_1.ofType(actions.ERROR), operators_1.map(() => this.tabService.getSelectedTab()), operators_1.tap(tab => {
            this.actionModalService
                .open({
                title: tab.title,
                content: '$I18N_ERROR_MODAL_BODY_TEXT',
                contentParams: { tabTitleText: tab.title }
            })
                .pipe(operators_1.first(), operators_1.tap(() => tab.close()));
        }));
        this.legacyAdconTimeout$ = this.actions$.pipe(effects_1.ofType(actions.SHOW_LEGACY_ADCON_FAIL_MODAL), operators_1.tap(() => this.actionModalService.open({
            title: '$I18N_LEGACY_DOWNTIME_ALERT_TITLE',
            content: '$I18N_LEGACY_DOWNTIME_ALERT_CONTENT',
            hideTopCloseButton: true,
            buttons: [
                {
                    id: 'LOGOUT',
                    title: '$I18N_LEGACY_DOWNTIME_ALERT_BTN_LABEL',
                    type: 'primary'
                }
            ]
        })));
        this.initializeLegacyTab$ = this.actions$.pipe(effects_1.ofType(actions.LOAD_TAB_DETAILS), operators_1.map(() => this.tabService.getSelectedTab()), operators_1.tap(tab => this.legacyTabConfigService.setTabTitleAndBreadcrumbs(tab)));
    }
}
__decorate([
    effects_1.Effect()
], LegacyPageEffects.prototype, "backPageOfFirstPage$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], LegacyPageEffects.prototype, "errorAction$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], LegacyPageEffects.prototype, "legacyAdconTimeout$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], LegacyPageEffects.prototype, "initializeLegacyTab$", void 0);
exports.LegacyPageEffects = LegacyPageEffects;
