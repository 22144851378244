"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LayoutAsideExtraPaginationContainerComponent = void 0;
const core_1 = require("@angular/core");
const base_1 = require("../base");
class LayoutAsideExtraPaginationContainerComponent extends base_1.LayoutAsideDirective {
    constructor() {
        super(...arguments);
        this.goToNextPage = new core_1.EventEmitter();
        this.goToPrevPage = new core_1.EventEmitter();
    }
    onPaginate(index) {
        if (index.direction > 0) {
            this.goToNextPage.emit();
        }
        else {
            this.goToPrevPage.emit();
        }
    }
}
exports.LayoutAsideExtraPaginationContainerComponent = LayoutAsideExtraPaginationContainerComponent;
