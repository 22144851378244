<div class="mc-siem-channel-details-step">
  <mc-wizard-step-header label="$I18N_SIEM_CHANNEL_WIZARD.STEPS.DETAILS.HEADER">
    {{ '$I18N_SIEM_CHANNEL_WIZARD.STEPS.DETAILS.SUB_TITLE' | translate }}
  </mc-wizard-step-header>

  <div>
    <form
      class="form-horizontal"
      mcDefaultLabelClass="col-sm-3"
      mcDefaultControlContainerClass="col-sm-9"
      [formGroup]="channelPage"
    >
      <div class="panel-padding-bottom mc-channel-name">
        <mc-text-field
          errorPrefix="$I18N_SIEM_CHANNEL_WIZARD.STEPS.DETAILS.ERROR"
          label="$I18N_SIEM_CHANNEL_WIZARD.STEPS.DETAILS.NAME"
          formControlName="name"
          [setFocus]="true"
          [maxLength]="100"
          placeholder="$I18N_SIEM_CHANNEL_WIZARD.STEPS.DETAILS.NAME_PLACEHOLDER"
          required
        ></mc-text-field>
      </div>
      <div class="mc-channel-name">
        <mc-text-area-field
          errorPrefix="$I18N_SIEM_CHANNEL_WIZARD.STEPS.DETAILS.ERROR"
          label="$I18N_SIEM_CHANNEL_WIZARD.STEPS.DETAILS.DESCRIPTION"
          formControlName="description"
          [setFocus]="false"
          [maxLength]="300"
          placeholder="$I18N_SIEM_CHANNEL_WIZARD.STEPS.DETAILS.DESCRIPTION_PLACEHOLDER"
        ></mc-text-area-field>
      </div>
      <div class="panel-padding-bottom mc-channel-type">
        <mc-field
          label="{{'$I18N_SIEM_CHANNEL_WIZARD.STEPS.DETAILS.TYPE' | translate}}"
          required
        >
          <div>
            <div *ngFor="let availableType of availableChannelTypes" class="radio panel-half-padding-bottom">
              <label class="text-bold ">
                <input
                  type="radio"
                  name="type"
                  value="{{availableType}}"
                  formControlName="type"
                />
                {{ availableType }}
              </label>
            </div>
          </div>
        </mc-field>
      </div>
    </form>
  </div>

</div>
