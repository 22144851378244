<div class="mc-url-protection-policy-block">
  <mc-wizard-step-header label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.SUMMARY.STEP_TITLE">
  </mc-wizard-step-header>

  <!-- Policy status -->
  <div class="mc-summary-step-section">
    <div class="mc-summary-step-section-header">
      <div class="mc-summary-step-section-header-title">
        {{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.SUMMARY.LABELS.POLICY_STATUS' | translate }}</div>
    </div>
    <form class="form-horizontal" mcDefaultLabelClass="col-sm-3" mcDefaultControlContainerClass="col-sm-9"
      [formGroup]="urlProtectionPolicySummaryForm">
      <div class="mc-summary-step-first-step-item">
        <mc-field class="mc-custom-switch-form-field">
          <mc-switch formControlName="policyStatusEnabled" role="switch" showLabel="true"></mc-switch>
        </mc-field>
      </div>
    </form>
  </div>

  <!-- DETAILS step -->
  <div class="mc-summary-step-section" *ngIf="(policyDetailsSetting$ | async)" data-unit-test="details">
    <div class="mc-summary-step-section-header">
      <div class="mc-summary-step-section-header-title">
        {{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.SUMMARY.LABELS.DETAILS_SECTION' | translate }}</div>
      <div class="mc-summary-step-section-header-edit-step" (click)="editStep(statics.DETAILS_STEP_NUMBER)">
        {{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.SUMMARY.LABELS.EDIT' | translate }}</div>
    </div>
    <div class="mc-detailed-list mc-summary-step-first-step-item">
      <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.POLICY_NAME"
        value="{{ detailsStep.name }}">
      </mc-value-field>
    </div>
    <div class="mc-detailed-list">
      <mc-value-field *ngIf="showDescription()"
        label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.POLICY_DESCRIPTION"
        value="{{ detailsStep.description }}">
      </mc-value-field>
      <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.BYPASS_POLICY"
        [value]="getEnabledDisabledLabel(isBypass())"></mc-value-field>
      <mc-value-field *ngIf="isBypass()"
        label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.BYPASS_APPLICATION"
        [value]="helper.getBypassApplicationLabel(detailsStep)"></mc-value-field>
      <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.POLICY_OVERRIDE"
        [value]="getEnabledDisabledLabel(detailsStep.policyOverride)"></mc-value-field>
      <mc-value-field *ngIf="isAAAOrFAAUser"
        data-unit-test="policy-enforcement"
        label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.POLICY_ENFORCEMENT"
        [value]="getEnabledDisabledLabel(detailsStep.policyEnforcement)"></mc-value-field>
      <mc-value-field
        label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.POLICY_DURATION"
        data-unit-test="date-range-continuous"
        *ngIf="helper.isContinuousDuration(detailsStep); else dateRange"
        value="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.DURATION_VALUES.CONTINUOUS' | translate}}">
      </mc-value-field>
      <ng-template #dateRange>
        <mc-value-field
          label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.POLICY_DURATION"
          data-unit-test="date-range"
          value="{{ '$I18N_SERVICES_URL_PROTECTION_POLICIES_SIDE_PANEL.LABELS.ROW_LABELS.FROM' | translate }}:
          {{helper.getDurationRange(detailsStep, 'start') | mcDatePlusWeekday}}">
        </mc-value-field>
        <mc-value-field
          *ngIf="helper.isEndDateRangePresent(detailsStep, 'end'); else continuous"
          value="{{ '$I18N_SERVICES_URL_PROTECTION_POLICIES_SIDE_PANEL.LABELS.ROW_LABELS.TO' | translate}}:
          {{ helper.getDurationRange(detailsStep, 'end') | mcDatePlusWeekday}}">
        </mc-value-field>
        <ng-template #continuous>
          <mc-value-field
            value="{{ '$I18N_SERVICES_URL_PROTECTION_POLICIES_SIDE_PANEL.LABELS.ROW_LABELS.TO' | translate}}:
            {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.DURATION_VALUES.CONTINUOUS' | translate}}">
          </mc-value-field>
        </ng-template>
      </ng-template>
    </div>
  </div>
  <!-- INBOUND step-->
  <div
    class="mc-summary-step-section"
    *ngIf="(policyInboundSetting$ | async) && !isBypass()"
    data-unit-test="inbound">
    <div class="mc-summary-step-section-header">
      <div class="mc-summary-step-section-header-title">
        {{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.SUMMARY.LABELS.INBOUND_SECTION' | translate }}</div>
      <div class="mc-summary-step-section-header-edit-step" (click)="editStep(statics.INBOUND_STEP_NUMBER)">
        {{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.SUMMARY.LABELS.EDIT' | translate }}</div>
    </div>
    <div class="mc-detailed-list mc-summary-step-first-step-item">
      <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.CHECK_INBOUND_EMAIL"
        [value]="getEnabledDisabledLabel(inboundStep.checkInboundEmail)"></mc-value-field>
    </div>
    <div
      class="mc-detailed-list"
      *ngIf="inboundStep.checkInboundEmail"
      data-unit-test="inbound-toggle-section"
      >
      <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.REWRITE_MODE.LABEL"
        [value]="helper.getInboundRewriteModeLabel(inboundStep)"></mc-value-field>
      <mc-value-field
        label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.URL_CATEGORY_SCANNING.LABEL"
        [value]="helper.getInboundUrlCategoryScanningLabel(inboundStep)"></mc-value-field>
      <mc-value-field
        label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.MALICIOUS_URL_DETECTION_ACTION.LABEL"
        [value]="helper.getInboundMaliciousDetectActionLabel(inboundStep)"></mc-value-field>
      <mc-value-field *ngIf=showInboundBrowserIsolation()
        data-unit-test="inbound-browser-isolation"
        label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.BROWSER_ISOLATION"
        [value]="getEnabledDisabledLabel(inboundStep.browserIsolation)"></mc-value-field>
      <mc-value-field
        label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.MESSAGE_SUBJECT_PROTECTION.LABEL"
        [value]="helper.getInboundMessageSubjectProtectionLabel(inboundStep)"></mc-value-field>
      <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.CREATE_MISSING_HTML_BODY"
        [value]="getEnabledDisabledLabel(inboundStep.createMissingHtmlBody)"></mc-value-field>
      <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.FORCE_SECURE_CONNECTION"
        [value]="getEnabledDisabledLabel(inboundStep.forceSecureConnection)"></mc-value-field>
      <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.IGNORE_SIGNED_MESSAGES"
        [value]="getEnabledDisabledLabel(inboundStep.ignoreSignedMessage)"></mc-value-field>
      <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.DISPLAY_URL_DOMAIN"
        [value]="getEnabledDisabledLabel(inboundStep.displayUrlDestinationDomain)"></mc-value-field>
      <mc-value-field
        *ngIf="showFileProtocolUrlHandling"
        data-unit-test="file-protocol-url-handling"
        label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.FILE_PROTOCOL_URL_HANDLING.LABEL"
        [value]="helper.getInboundFileProtocolUrlHandelingLabel(inboundStep)"></mc-value-field>
      <mc-value-field
        label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.STRIP_EXTERNAL_SOURCE_MODE"
        [value]="getEnabledDisabledLabel(inboundStep.stripExternalSourceMode)"></mc-value-field>
      <div class="mc-summary-step-sub-section">
        <div class="mc-summary-step-sub-section-header">
          {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.SECTION_TITLES.URL_AND_ATTACHMENTS' | translate }}
        </div>
        <div class="mc-summary-step-sub-section-row">
          <mc-value-field
            label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.BLOCK_URL_WITH_DANGEROUS_FILE"
            [value]="getEnabledDisabledLabel(inboundStep.blockUrlsWithDangerousFileExtensions)"></mc-value-field>
          <mc-value-field
            label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.REWRITE_URLS_IN_ATTACHMENTS"
            [value]="getEnabledDisabledLabel(inboundStep.rewriteUrlsInAttachments)"></mc-value-field>
          <mc-value-list *ngIf="inboundStep.rewriteUrlsInAttachments"
            data-unit-test="attachment-parts-to-rewrite"
            listLabel="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.ATTACHMENT_PARTS_TO_REWRITE' | translate }}">
            <div
              *ngIf="inboundStep.attachmentPartHtml"
              data-unit-test="inbound-attachment-part-html">
              {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.HTML' | translate }}</div>
            <div
              *ngIf="inboundStep.attachmentPartText"
              data-unit-test="inbound-attachment-part-text">
              {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.TEXT' | translate }}</div>
            <div
              *ngIf="inboundStep.attachmentPartCalendar"
              data-unit-test="inbound-attachment-part-calendar">
              {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.CALENDAR' | translate }}</div>
          </mc-value-list>
          <mc-value-field
            label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.URL_FILE_DOWNLOAD.LABEL"
            [value]="helper.getUrlFileDownloadLabel(inboundStep)"></mc-value-field>
          <mc-value-field
            label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.SCAN_URL_IN_ATTACHMENT"
            [value]="getEnabledDisabledLabel(inboundStep.scanUrlInAttachment)"></mc-value-field>
        </div>
      </div>
      <div class="mc-summary-step-sub-section">
        <div class="mc-summary-step-sub-section-header">
          {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.SECTION_TITLES.ADV_SIMILARITY_CHECKS' | translate }}
        </div>
        <div class="mc-summary-step-sub-section-row">
          <mc-value-field
            label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.CHECK_FOR_SIMILAR_URLS"
            [value]="getEnabledDisabledLabel(inboundStep.checkForSimilarUrls)"></mc-value-field>
          <ng-container *ngIf="inboundStep.checkForSimilarUrls">
            <mc-value-list
              data-unit-test="inbound-check-for-similar-urls"
              listLabel="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.DOMAIN_TO_CHECK_AGAIN' | translate }}">
              <div
                *ngIf="inboundStep.internalDomains"
                data-unit-test="inbound-your-internal-domains">
                {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.YOUR_INTERNAL_DOMAINS' | translate }}
              </div>
              <div
                *ngIf="inboundStep.mimecastMonitoringExternalDomain"
                data-unit-test="inbound-mimecast-monitoring-external-domain">
                {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.MIMECAST_MONITORED_EXTERNAL_DOMAINS' | translate }}
              </div>
              <div
                *ngIf="inboundStep.customMonitoringExternalDomain"
                data-unit-test="inbound-custom-monitoring-external-domain">
                {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.CUSTOM_MONITORED_EXTERNAL_DOMAINS' | translate }}
              </div>
            </mc-value-list>
            <mc-value-field
              label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.SIMILAR_URL_DETECTION.LABEL"
              [value]="helper.getSimilarUrlDetection(inboundStep)"></mc-value-field>
          </ng-container>
        </div>
      </div>
      <div
        data-unit-test="inbound-notifications"
        class="mc-summary-step-sub-section"
        *ngIf="!isAAAOrFAAUser">
        <div class="mc-summary-step-sub-section-header">
          {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.SECTION_TITLES.NOTIFICATIONS' | translate }}
        </div>
        <div class="mc-summary-step-sub-section-row">
          <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.ADMINISTRATORS"
            [value]="inboundStep.administratorsSelectedGroup && inboundStep.administratorsSelectedGroup.description">
          </mc-value-field>
          <mc-value-field
            label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.NOTIFICATION_URL_FORMAT.LABEL"
            [value]="helper.getNotificationUrlFormatLabel(inboundStep)"></mc-value-field>
        </div>
      </div>
    </div>
  </div>

  <!-- Outbound -->
  <div
    data-unit-test="outbound"
    class="mc-summary-step-section"
    *ngIf="(policyOutboundSetting$ | async) && !isBypass() && (hasIEPPackage || isAAAOrFAAUser)">
    <div class="mc-summary-step-section-header">
      <div class="mc-summary-step-section-header-title">
        {{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.SUMMARY.LABELS.OUTBOUND_SECTION' | translate }}</div>
      <div class="mc-summary-step-section-header-edit-step" (click)="editStep(statics.OUTBOUND_STEP_NUMBER)">
        {{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.SUMMARY.LABELS.EDIT' | translate }}</div>
    </div>
    <div class="mc-detailed-list mc-summary-step-first-step-item">
      <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.CHECK_OUTBOUND_EMAIL"
        [value]="getEnabledDisabledLabel(outboundStep.checkOutboundEmail)"></mc-value-field>
    </div>
    <div class="mc-detailed-list"
      *ngIf="outboundStep.checkOutboundEmail"
      data-unit-test="outbound-toggle-section"
    >
      <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.URL_SCANNING_MODE.LABEL"
        [value]="helper.getOutboundUrlScanningModeLabel(outboundStep)"></mc-value-field>
      <mc-value-field
        label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.URL_CATEGORY_SCANNING_MODE.LABEL"
        [value]="helper.getUrlCategoryScanningModeLabel(outboundStep)"></mc-value-field>
      <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.GATEWAY_ACTION.LABEL"
        [value]="helper.getGatewayActionLabel(outboundStep)"></mc-value-field>
      <mc-value-field
        label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.GATEWAY_FALLBACK_ACTION.LABEL"
        [value]="helper.getGatewayFallbackActionLabel(outboundStep)"></mc-value-field>
      <mc-value-field
        label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.SENDER_MAILBOX_ACTION.LABEL"
        [value]="helper.getSenderMailboxActionLabel(outboundStep)"></mc-value-field>
      <mc-value-field
        label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.SENDER_MAILBOX_FALLBACK_ACTION.LABEL"
        [value]="helper.getSenderMailboxFallbackActionLabel(outboundStep)"></mc-value-field>
      <div class="mc-summary-step-sub-section">
        <div class="mc-summary-step-sub-section-header">
          {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.SECTION_TITLES.URL_AND_ATTACHMENTS' | translate }}
        </div>
        <div class="mc-summary-step-sub-section-row">
          <mc-value-field
            label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.BLOCK_URLS_WITH_DANGEROUS_FILE_EXTENSIONS"
            [value]="getEnabledDisabledLabel(outboundStep.blockUrlWithDangerousFileExtensions)"></mc-value-field>
          <mc-value-field
            label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.URL_FILE_DOWNLOAD.LABEL"
            [value]="helper.getOutboundUrlFileDownloadLabel(outboundStep.urlFileDownload)"></mc-value-field>
          <mc-value-field
            label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.SCAN_URLS_IN_ATTACHMENTS"
            [value]="getEnabledDisabledLabel(outboundStep.scanUrlInAttachments)"></mc-value-field>
          <mc-value-list
            data-unit-test="outbound-scan-url-in-attachment"
            *ngIf="outboundStep.scanUrlInAttachments"
            listLabel="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.ATTACHMENT_PARTS' | translate }}">
            <div
              *ngIf="outboundStep.attachmentPartHTML"
              data-unit-test="outbound-attachment-part-html"
            >
              {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.HTML' | translate }}</div>
            <div
              *ngIf="outboundStep.attachmentPartText"
              data-unit-test="outbound-attachment-part-text"
            >
              {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.TEXT' | translate }}</div>
            <div
              *ngIf="outboundStep.attachmentPartCalendar"
              data-unit-test="outbound-attachment-part-calendar"
            >
              {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.CALENDAR' | translate }}</div>
            <div
              *ngIf="outboundStep.attachmentPartOther"
              data-unit-test="outbound-attachment-part-other"
            >
              {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.OTHER' | translate }}</div>
          </mc-value-list>
        </div>
      </div>
      <div class="mc-summary-step-sub-section">
        <div class="mc-summary-step-sub-section-header">
          {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.SECTION_TITLES.ADV_SIMILARITY_CHECKS' | translate }}
        </div>
        <div class="mc-summary-step-sub-section-row">
          <mc-value-field
            label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.CHECK_FOR_SIMILAR_URLS"
            [value]="getEnabledDisabledLabel(outboundStep.checkForSimilarUrls)"></mc-value-field>
          <mc-value-list
            *ngIf="outboundStep.checkForSimilarUrls"
            data-unit-test="outbound-check-for-similar-urls"
            listLabel="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.DOMAINS_TO_CHECK_AGAINST' | translate }}">
            <div
              *ngIf="outboundStep.domainToCheckAgainstYourInternalDomains"
              data-unit-test="outbound-domain-to-check-against-your-internal-domains"
            >
              {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.YOUR_INTERNAL_DOMAINS' | translate }}
            </div>
            <div
              *ngIf="outboundStep.domainToCheckAgainstMimecastMonitoredExternalDomains"
              data-unit-test="outbound-domain-to-check-against-mimecast-monitored-external-domains"
            >
              {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.MIMECAST_MONITORED_EXTERNAL_DOMAINS' | translate }}
            </div>
            <div
              *ngIf="outboundStep.domainToCheckAgainstCustomMonitoredExternalDomains"
              data-unit-test="outbound-domain-to-check-against-custom-monitored-external-domains"
            >
              {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.CUSTOM_MONITORED_EXTERNAL_DOMAINS' | translate }}
            </div>
          </mc-value-list>
        </div>
      </div>
      <div class="mc-summary-step-sub-section">
        <div class="mc-summary-step-sub-section-header">
          {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.SECTION_TITLES.NOTIFICATIONS' | translate }}
        </div>
        <div class="mc-summary-step-sub-section-row">
          <mc-value-field
            *ngIf="!isAAAOrFAAUser"
            data-unit-test="outbound-administrators"
            label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.ADMINISTRATORS"
            [value]="outboundStep.administratorsSelectedGroup && outboundStep.administratorsSelectedGroup.description">
          </mc-value-field>
          <mc-value-list
            listLabel="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.USERS' | translate }}">
            <div *ngIf="outboundStep.users">
              {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.INTERNAL_SENDERS' | translate }}
            </div>
          </mc-value-list>
        </div>
      </div>
    </div>
  </div>

  <!-- Journal -->
  <div
    class="mc-summary-step-section"
    *ngIf="(policyJournalSetting$ | async) && !isBypass() && (hasIEPPackage || isAAAOrFAAUser)"
    data-unit-test="journal">
    <div class="mc-summary-step-section-header">
      <div class="mc-summary-step-section-header-title">
        {{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.SUMMARY.LABELS.JOURNAL_SECTION' | translate }}</div>
      <div class="mc-summary-step-section-header-edit-step" (click)="editStep(statics.JOURNAL_STEP_NUMBER)">
        {{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.SUMMARY.LABELS.EDIT' | translate }}</div>
    </div>
    <div class="mc-detailed-list mc-summary-step-first-step-item">
      <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.CHECK_INTERNAL_EMAIL"
        [value]="getEnabledDisabledLabel(journalStep.checkInternalEmail)"></mc-value-field>
    </div>
    <div
      class="mc-detailed-list"
      *ngIf="journalStep.checkInternalEmail"
      data-unit-test="journal-toggle-section">
      <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.URL_SCANNING_MODE.LABEL"
        [value]="helper.getJournalUrlScanningModeLabel(journalStep)"></mc-value-field>
      <mc-value-field
        label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.URL_CATEGORY_SCANNING_MODE.LABEL"
        [value]="helper.getJournalUrlCategoryScanningModeLabel(journalStep)"></mc-value-field>
      <mc-value-field
        label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.SENDER_MAILBOX_ACTION.LABEL"
        [value]="helper.getJournalSenderMailboxActionLabel(journalStep)"></mc-value-field>
      <mc-value-field
        label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.SENDER_MAILBOX_FALLBACK_ACTION.LABEL"
        [value]="helper.getJournalSenderMailboxFallbackActionLabel(journalStep)"></mc-value-field>
      <div class="mc-summary-step-sub-section">
        <div class="mc-summary-step-sub-section-header">
          {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.SECTION_TITLES.URL_AND_ATTACHMENTS' | translate }}
        </div>
        <div class="mc-summary-step-sub-section-row">
          <mc-value-field
            label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.BLOCK_URLS_WITH_DANGEROUS_FILE_EXTENSIONS"
            [value]="getEnabledDisabledLabel(journalStep.blockUrlWithDangerousFileExtensions)"></mc-value-field>
          <mc-value-field
            label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.URL_FILE_DOWNLOAD.LABEL"
            [value]="helper.getJournalUrlFileDownloadLabel(journalStep.urlFileDownload)"></mc-value-field>
          <mc-value-field
            label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.SCAN_URLS_IN_ATTACHMENTS"
            [value]="getEnabledDisabledLabel(journalStep.scanUrlInAttachments)"></mc-value-field>
          <mc-value-list
            *ngIf="journalStep.scanUrlInAttachments"
            data-unit-test="journal-scan-url-in-attachment"
            listLabel="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.ATTACHMENT_PARTS' | translate }}">
            <div *ngIf="journalStep.attachmentPartHTML" data-unit-test="journal-attachment-part-html">
              {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.HTML' | translate }}</div>
            <div *ngIf="journalStep.attachmentPartText" data-unit-test="journal-attachment-part-text">
              {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.TEXT' | translate }}</div>
            <div *ngIf="journalStep.attachmentPartCalendar" data-unit-test="journal-attachment-part-calendar">
              {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.CALENDAR' | translate }}</div>
            <div *ngIf="journalStep.attachmentPartOther" data-unit-test="journal-attachment-part-other">
              {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.OTHER' | translate }}</div>
          </mc-value-list>
        </div>
      </div>
      <div class="mc-summary-step-sub-section">
        <div class="mc-summary-step-sub-section-header">
          {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.SECTION_TITLES.ADV_SIMILARITY_CHECKS' | translate }}
        </div>
        <div class="mc-summary-step-sub-section-row">
          <mc-value-field
            label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.CHECK_FOR_SIMILAR_URLS"
            [value]="getEnabledDisabledLabel(journalStep.checkForSimilarUrls)"></mc-value-field>
          <mc-value-list
            *ngIf="journalStep.checkForSimilarUrls"
            data-unit-test="journal-check-for-similar-urls"
            listLabel="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.DOMAINS_TO_CHECK_AGAINST' | translate }}">
            <div *ngIf="journalStep.domainToCheckAgainstYourInternalDomains"
              data-unit-test="journal-domain-to-check-against-your-internal-domains"
            >
              {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.YOUR_INTERNAL_DOMAINS' | translate }}
            </div>
            <div *ngIf="journalStep.domainToCheckAgainstMimecastMonitoredExternalDomains"
              data-unit-test="journal-domain-to-check-against-mimecast-monitored-external-domains"
            >
              {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.MIMECAST_MONITORED_EXTERNAL_DOMAINS' | translate }}
            </div>
            <div *ngIf="journalStep.domainToCheckAgainstCustomMonitoredExternalDomains"
              data-unit-test="journal-domain-to-check-against-custom-monitored-external-domains"
            >
              {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.CUSTOM_MONITORED_EXTERNAL_DOMAINS' | translate }}
            </div>
          </mc-value-list>
        </div>
      </div>
      <div class="mc-summary-step-sub-section">
        <div class="mc-summary-step-sub-section-header">
          {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.SECTION_TITLES.NOTIFICATIONS' | translate }}
        </div>
        <div class="mc-summary-step-sub-section-row">
          <mc-value-field
            *ngIf="!isAAAOrFAAUser"
            data-unit-test="journal-administrators"
            label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.ADMINISTRATORS"
            [value]="journalStep.administratorsSelectedGroup && journalStep.administratorsSelectedGroup.description">
          </mc-value-field>
          <mc-value-list
            listLabel="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.USERS' | translate }}">
            <div *ngIf="journalStep.internalSender" data-unit-test="journal-internal-sender">
              {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.INTERNAL_SENDERS' | translate }}
            </div>
            <div *ngIf="journalStep.internalRecipients" data-unit-test="journal-internal-recipients">
              {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.INTERNAL_RECIPIENTS' | translate }}
            </div>
          </mc-value-list>
        </div>
      </div>
    </div>
  </div>

  <!-- User Awareness -->
  <div
    class="mc-summary-step-section"
    *ngIf="(policyUserAwarenessSetting$ | async) && !isBypass()"
    data-unit-test="awareness">
    <div class="mc-summary-step-section-header">
      <div class="mc-summary-step-section-header-title">
        {{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.SUMMARY.LABELS.USER_AWARENESS_SECTION' | translate }}</div>
      <div class="mc-summary-step-section-header-edit-step" (click)="goToUserAwarenessStep()">
        {{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.SUMMARY.LABELS.EDIT' | translate }}</div>
    </div>
    <div class="mc-detailed-list mc-summary-step-first-step-item">
      <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.USER_AWARENESS_SETTINGS.LABELS.SHOW"
        [value]="getEnabledDisabledLabel(userAwarenessStep.showUserAwarenessChallenges)"></mc-value-field>
    </div>
    <div
      class="mc-detailed-list"
      *ngIf="userAwarenessStep.showUserAwarenessChallenges"
      data-unit-test="awareness-toggle-section">
      <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.USER_AWARENESS_SETTINGS.LABELS.PERCENTAGE"
        [value]="userAwarenessStep.challengePercentage"></mc-value-field>
      <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.USER_AWARENESS_SETTINGS.LABELS.DYNAMIC_ADJUSTMENT"
        [value]="getEnabledDisabledLabel(userAwarenessStep.dynamicAdjustment)"></mc-value-field>
      <mc-value-field *ngIf="!isAAAOrFAAUser" data-unit-test="awareness-custom-set" 
        label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.USER_AWARENESS_SETTINGS.LABELS.CUSTOM_PAGE_SET"
        [value]="getEnabledDisabledLabel(userAwarenessStep.customPageSet)"></mc-value-field>
      <mc-value-field *ngIf="userAwarenessStep.customPageSet"
        label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.USER_AWARENESS_SETTINGS.LABELS.PAGE_SET"
        [value]="helper.getUserAwarenessPageSetOptionLabel(userAwarenessStep, userAwarenessStepPageSetOptions)">
      </mc-value-field>
    </div>
  </div>

  <!-- Applies to -->
  <div
    class="mc-summary-step-section"
      *ngIf="(policyAppliesToSetting$ | async)"
      data-unit-test="applies-to">
    <div class="mc-summary-step-section-header">
      <div class="mc-summary-step-section-header-title">
        {{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.SUMMARY.LABELS.APPLIES_TO_SECTION' | translate }}</div>
      <div class="mc-summary-step-section-header-edit-step" (click)="goToAppliesStep()">
        {{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.SUMMARY.LABELS.EDIT' | translate }}</div>
    </div>
    <div class="mc-detailed-list">
      <div class="mc-summary-step-sub-section" *ngFor="let rule of appliesToStep; index as i;">
        <div class="mc-summary-step-sub-section-header">
          {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.SECTION_TITLE' | translate }}
          <span *ngIf="appliesToStep.length > 1">{{ i+1 }}</span>
        </div>
        <div class="mc-summary-step-sub-section-row">
          <mc-value-field
            label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.ADDRESSES_BASED_ON.LABEL"
            [value]="helper.getAddressesBasedOnLabel(rule)"></mc-value-field>
          <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.APPLIES.LABEL_FROM"
            [value]="helper.getAppliesFromToLabel(rule, 'appliesFrom')"></mc-value-field>
          <ng-container *ngIf="helper.showAppliesToAddresAttributeDetails(rule.appliesFrom)">
            <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.IS_EQUAL_TO"
              [value]="rule.appliesFromIsEqualTo"></mc-value-field>
          </ng-container>
          <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.APPLIES.LABEL_TO"
            [value]="helper.getAppliesFromToLabel(rule, 'appliesTo', false)"></mc-value-field>
          <ng-container *ngIf="helper.showAppliesToAddresAttributeDetails(rule.appliesTo)">
            <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.IS_EQUAL_TO"
              [value]="rule.appliesToIsEqualTo"></mc-value-field>
          </ng-container>
          <mc-value-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.LABELS.BIDIRECTIONAL"
            [value]="getEnabledDisabledLabel(rule.bidirectional)"></mc-value-field>
          <mc-value-list
            listLabel="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.APPLIES_TO_SETTINGS.IP_RANGE_TITLE' | translate }}"
            [listItems]="rule.ipRanges"
            [showMoreLessTriggerValue] = "showMoreLessTriggerValue">
          </mc-value-list>
        </div>
      </div>
    </div>
  </div>
</div>
