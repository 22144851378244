"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createConfig = void 0;
const am4core = require("@amcharts/amcharts4/core");
const am4charts = require("@amcharts/amcharts4/charts");
const TOTAL_COLOR = '#134783';
const MALICIOUS_COLOR = '#d32f2f';
const CHART_LABEL_FORMAT = 'dd MMM';
const createConfig = (data) => {
    let maxScanned = 0;
    let maxDetected = 0;
    let maliciousExtraMax;
    if (maxDetected !== 0) {
        Object.keys(data).forEach(date => {
            const row = data[date];
            maxScanned = Math.max(row.totalScannedFiles, maxScanned);
            maxDetected = Math.max(row.detectedScannedFiles, maxDetected);
        });
        if (maxScanned / maxDetected >= 5) {
            maliciousExtraMax = maxDetected / 0.2;
        }
    }
    const gradientTotal = new am4core.LinearGradient();
    gradientTotal.addColor(am4core.color(TOTAL_COLOR), 0.4);
    gradientTotal.addColor(am4core.color('#fff'), 0);
    gradientTotal.rotation = 90;
    const gradientMalicious = new am4core.LinearGradient();
    gradientMalicious.addColor(am4core.color(MALICIOUS_COLOR), 0.4);
    gradientMalicious.addColor(am4core.color('#fff'), 0);
    gradientMalicious.rotation = 90;
    const legend = new am4charts.Legend();
    legend.useDefaultMarker = true;
    legend.position = 'bottom';
    const marker = legend.markers.template.children.getIndex(0);
    marker.align = 'center';
    marker.valign = 'middle';
    marker.height = 10;
    marker.width = 10;
    // @ts-ignore
    marker.cornerRadius(5, 5, 5, 5);
    marker.adapter.add('fill', (t, s) => s.dataItem && s.dataItem.dataContext['stroke']);
    return {
        maxZoomLevel: 1,
        showOnInit: true,
        legend,
        series: [
            {
                name: 'Total Files',
                yAxis: 'totalScannedFilesValueAxis',
                type: 'LineSeries',
                tooltipText: 'Scanned: [bold]{valueY}[/]',
                sequencedInterpolation: true,
                dataFields: {
                    valueY: 'totalScannedFiles',
                    dateX: 'date'
                },
                stroke: TOTAL_COLOR,
                strokeWidth: 2,
                tensionX: 0.85,
                tensionY: 0.85,
                fill: gradientTotal,
                fillOpacity: 1,
                tooltip: {
                    getFillFromObject: false,
                    pointerOrientation: 'vertical',
                    background: {
                        fill: am4core.color(TOTAL_COLOR),
                        fillOpacity: 1
                    }
                }
            },
            {
                name: 'Malicious Files',
                yAxis: 'maliciousValueAxis',
                type: 'LineSeries',
                tooltipText: 'Malicious: [bold]{valueY}[/]',
                sequencedInterpolation: true,
                dataFields: {
                    valueY: 'detectedScannedFiles',
                    dateX: 'date'
                },
                stroke: am4core.color(MALICIOUS_COLOR),
                strokeWidth: 2,
                tensionX: 0.85,
                tensionY: 0.85,
                fill: gradientMalicious,
                fillOpacity: 1,
                tooltip: {
                    getFillFromObject: false,
                    pointerOrientation: 'vertical',
                    background: {
                        fill: am4core.color(MALICIOUS_COLOR),
                        fillOpacity: 1
                    }
                }
            }
        ],
        xAxes: [
            {
                dateFormats: {
                    day: CHART_LABEL_FORMAT
                },
                periodChangeDateFormats: {
                    day: CHART_LABEL_FORMAT
                },
                cursorTooltipEnabled: false,
                type: 'DateAxis',
                dataFields: {
                    category: 'date'
                },
                baseInterval: {
                    timeUnit: 'date',
                    count: 1
                }
            }
        ],
        yAxes: [
            {
                id: 'totalScannedFilesValueAxis',
                min: 0,
                type: 'ValueAxis',
                cursorTooltipEnabled: false,
                baseInterval: {
                    timeUnit: 'totalScannedFiles',
                    count: 50
                },
                renderer: {
                    opposite: true,
                    line: {
                        strokeOpacity: 1,
                        strokeWidth: 2,
                        stroke: TOTAL_COLOR
                    }
                }
            },
            {
                id: 'maliciousValueAxis',
                min: 0,
                max: maliciousExtraMax,
                strictMinMax: !!maliciousExtraMax,
                type: 'ValueAxis',
                cursorTooltipEnabled: false,
                baseInterval: {
                    timeUnit: 'detectedScannedFiles',
                    count: 50
                },
                renderer: {
                    line: {
                        strokeOpacity: 1,
                        strokeWidth: 2,
                        stroke: MALICIOUS_COLOR
                    }
                }
            }
        ]
    };
};
exports.createConfig = createConfig;
