"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocationsListExportEffects = void 0;
const effects_1 = require("@ngrx/effects");
const operators_1 = require("rxjs/operators");
const swg_location_edit_sidebar_page_component_1 = require("app-new/swg/locations/container/edit/swg-location-edit-sidebar-page.component");
const swg_location_delete_confirmation_component_1 = require("app-new/swg/locations/container/delete/swg-location-delete-confirmation.component");
const swg_location_sidebar_actions_1 = require("app-new/swg/locations/actions/swg-location-sidebar.actions");
class LocationsListExportEffects {
    constructor(actions$, asideService, modalService) {
        this.actions$ = actions$;
        this.asideService = asideService;
        this.modalService = modalService;
        this.openSidePanel$ = this.actions$.pipe(effects_1.ofType(swg_location_sidebar_actions_1.SwgLocationSidebarTypes.SWG_LOCATION_EDIT_ADD_SIDBAR_OPEN), operators_1.tap(() => {
            this.sidepanelInstance = this.asideService.open(swg_location_edit_sidebar_page_component_1.SwgLocationEditSidebarPageComponent, {
                position: 'right',
                size: 'md',
                hasBackdrop: true
            });
        }));
        this.closeSidePanel$ = this.actions$.pipe(effects_1.ofType(swg_location_sidebar_actions_1.SwgLocationSidebarTypes.SWG_LOCATION_EDIT_ADD_SIDBAR_CLOSE), operators_1.filter(() => !!this.sidepanelInstance), operators_1.tap(() => {
            this.sidepanelInstance.close();
            this.sidepanelInstance = undefined;
        }));
        this.openDeleteModal$ = this.actions$.pipe(effects_1.ofType(swg_location_sidebar_actions_1.SwgLocationSidebarTypes.SWG_LOCATION_DELETE_SIDBAR_OPEN), operators_1.map((action) => action.payload), operators_1.tap(() => {
            this.deleteModalInstance = this.modalService.open(swg_location_delete_confirmation_component_1.SwgLocationDeleteConfirmationComponent, {
                size: 'md',
                hasBackdrop: true
            });
        }));
        this.closeDeleteModal$ = this.actions$.pipe(effects_1.ofType(swg_location_sidebar_actions_1.SwgLocationSidebarTypes.SWG_LOCATION_DELETE_SIDBAR_CLOSE), operators_1.filter(() => !!this.deleteModalInstance), operators_1.tap(() => {
            this.deleteModalInstance.close();
            this.deleteModalInstance = undefined;
        }));
    }
}
__decorate([
    effects_1.Effect({ dispatch: false })
], LocationsListExportEffects.prototype, "openSidePanel$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], LocationsListExportEffects.prototype, "closeSidePanel$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], LocationsListExportEffects.prototype, "openDeleteModal$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], LocationsListExportEffects.prototype, "closeDeleteModal$", void 0);
exports.LocationsListExportEffects = LocationsListExportEffects;
