'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
/**
 * Created by psambandam on 15/02/16.
 */
angular.module('services-status.service', [])
    .factory('serviceStatusService', [function () {
        return {
            getServicestatus
        };
        function getServicestatus(response) {
            const count = response.first.activeServiceCount + response.first.inactiveServiceCount;
            // service not configured
            if (count == 0) {
                return 'SERVICE_NOT_CONFIGURED';
            }
            else if (response.first.activeServiceCount == count) { //all services active
                return 'SERVICE_UP';
                //all services down
            }
            else if (response.first.inactiveServiceCount == count) {
                return 'SERVICE_DOWN';
            }
            else {
                return 'SERVICE_OK';
            }
        }
    }]);
