"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateStatusConfirmationModalComponent = void 0;
const im_task_1 = require("../../model/im-task");
class UpdateStatusConfirmationModalComponent {
    constructor(overlayRef, overlayData) {
        this.overlayRef = overlayRef;
        this.overlayData = overlayData;
        this.updateType = this.getTranslateKey();
    }
    close(confirmed) {
        this.overlayRef.close(confirmed);
    }
    getTranslateKey() {
        return this.overlayData.imTaskStatus === im_task_1.ImTaskStatus.ENABLED
            ? im_task_1.StatusUpdateType.ENABLE
            : im_task_1.StatusUpdateType.DISABLE;
    }
}
exports.UpdateStatusConfirmationModalComponent = UpdateStatusConfirmationModalComponent;
