"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgCustomizedPageComponent = void 0;
class SwgCustomizedPageComponent {
    constructor(translate) {
        this.translate = translate;
    }
    getTitle() {
        return this.updatedSettings.customTitle
            ? this.updatedSettings.customTitle
            : this.translate.instant('$I18N_SWG_BLOCK_PAGE_SETTINGS_TITLE_PLACEHOLDER');
    }
    getBody() {
        return this.updatedSettings.customBody
            ? this.updatedSettings.customBody
            : this.translate.instant('$I18N_SWG_BLOCK_PAGE_SETTINGS_BODY_PLACEHOLDER');
    }
}
exports.SwgCustomizedPageComponent = SwgCustomizedPageComponent;
