"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueueDetails = void 0;
class QueueDetails {
    constructor(name, totalCount, newInLastSevenDays, pendingCount, percentageOfPendingMessages, nonCompliantCount, oldestPendingHours, failed) {
        this.name = name;
        this.totalCount = totalCount;
        this.newInLastSevenDays = newInLastSevenDays;
        this.pendingCount = pendingCount;
        this.percentageOfPendingMessages = percentageOfPendingMessages;
        this.nonCompliantCount = nonCompliantCount;
        this.oldestPendingHours = oldestPendingHours;
        this.failed = failed;
    }
}
exports.QueueDetails = QueueDetails;
