"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MepSidebarHeaderComponent = void 0;
class MepSidebarHeaderComponent {
    ngOnInit() {
        this.policyName = this.policy.name;
    }
    ngOnChanges(changes) {
        if (!changes['policy'].isFirstChange()) {
            this.policyName = changes['policy'].currentValue.name;
        }
    }
}
exports.MepSidebarHeaderComponent = MepSidebarHeaderComponent;
