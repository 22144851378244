"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateMepPolicyComponent = exports.WIZARD_STEPS = void 0;
const core_1 = require("@angular/core");
const models_1 = require("../../models/models");
var WIZARD_STEPS;
(function (WIZARD_STEPS) {
    WIZARD_STEPS[WIZARD_STEPS["POLICY_DETAILS"] = 1] = "POLICY_DETAILS";
    WIZARD_STEPS[WIZARD_STEPS["APPLIES_TO"] = 2] = "APPLIES_TO";
    WIZARD_STEPS[WIZARD_STEPS["SUMMARY"] = 3] = "SUMMARY";
})(WIZARD_STEPS = exports.WIZARD_STEPS || (exports.WIZARD_STEPS = {}));
class CreateMepPolicyComponent {
    constructor(translate$) {
        this.translate$ = translate$;
        this.currentStep = WIZARD_STEPS.POLICY_DETAILS;
        this.createPolicyEvent = new core_1.EventEmitter();
        this.editPolicyEvent = new core_1.EventEmitter();
        this.openAppliesToSidePanelWiz = new core_1.EventEmitter();
        this.everyOneAffectedUsersWiz = new core_1.EventEmitter();
        this.removeAffectedUsersWiz = new core_1.EventEmitter();
        this.resetWizard = new core_1.EventEmitter();
        this.validity = {
            details: false,
            appliesTo: false
        };
    }
    ngOnInit() {
        this.title = this.translate$.instant('$I18N_MEP_POLICY_CREATE.TITLE');
        this.description = this.translate$.instant('$I18N_MEP_POLICY_CREATE.DESCRIPTION');
    }
    ngAfterViewInit() {
        this.wizard = this.wizardComponents.first;
        this.wizardComponents.changes.subscribe((wizardComponents) => {
            this.wizard = wizardComponents.first;
        });
    }
    ngOnChanges(changes) {
        if (changes['loadingForEdit'] &&
            changes['loadingForEdit'].currentValue === false &&
            !changes['loadingForEdit'].isFirstChange()) {
            this.title = this.isEditFlow()
                ? this.translate$.instant('$I18N_MEP_POLICY_EDIT.TITLE')
                : this.translate$.instant('$I18N_MEP_POLICY_CREATE.TITLE');
            this.description = this.isEditFlow()
                ? this.translate$.instant('$I18N_MEP_POLICY_EDIT.DESCRIPTION')
                : this.translate$.instant('$I18N_MEP_POLICY_CREATE.DESCRIPTION');
            // Assume that the information is valid if we're editing the policy.
            this.validity.details = true;
            this.validity.appliesTo = true;
        }
        if (changes['mepPolicy'] &&
            !changes['mepPolicy'].isFirstChange() &&
            changes['mepPolicy'].currentValue !== changes['mepPolicy'].previousValue) {
            // SSW-36369: Ensure that we are running validity checks when changing the details of the policy.
            this.isStepValid(this.currentStep);
        }
    }
    goToStep(event) {
        this.currentStep = event;
    }
    onSaveBtnClick() {
        this.createPolicyEvent.emit(this.mepPolicy);
    }
    onEditSaveClicked() {
        this.editPolicyEvent.emit(this.mepPolicy);
    }
    cancel() {
        this.resetWizard.emit(true);
    }
    isStepValid(stepNumber) {
        if (stepNumber === 1) {
            return this.validity.details;
        }
        else if (stepNumber === 2) {
            this.setStepValidity('appliesTo', this.hasValidAppliesTo());
            return this.validity.appliesTo;
        }
        return true;
    }
    hasValidAppliesTo() {
        return (this.mepPolicy.everyone ||
            this.mepPolicy.rules.filter(rule => rule.condition !== models_1.MepAffectedUsersEnum.EVERYONE).length >
                0);
    }
    getSaveButtonLabel() {
        return this.isEditFlow()
            ? '$I18N_MEP_POLICY_CREATE.SAVE_AND_CLOSE'
            : '$I18N_MEP_POLICY_CREATE.CREATE_POLICY';
    }
    isValidPolicy() {
        return !Object.values(this.validity).some(x => !x);
    }
    isEditFlow() {
        return this.mepPolicy.id !== '';
    }
    setStepValidity(step, isValid) {
        this.validity[step] = isValid;
    }
    hasPreviousStep() {
        return this.wizard
            ? this.wizard.hasPreviousStep$.getValue()
            : this.currentStep !== WIZARD_STEPS.POLICY_DETAILS;
    }
    hasNextStep() {
        return this.wizard
            ? this.wizard.hasNextStep$.getValue()
            : this.currentStep !== WIZARD_STEPS.SUMMARY;
    }
}
exports.CreateMepPolicyComponent = CreateMepPolicyComponent;
