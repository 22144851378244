"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UrlParametersService = void 0;
class UrlParametersService {
    constructor(_location) {
        this._location = _location;
    }
    getUrlParameters() {
        const query = this._location.search.substr(1);
        return this.decodeUrlParameters(query);
    }
    decodeUrlParameters(query) {
        const result = {};
        query.split('&').forEach(function (part) {
            if (!part) {
                return;
            }
            const item = part.split('=');
            let key = item[0];
            const from = key.indexOf('[');
            if (from === -1) {
                result[key] = decodeURIComponent(item[1]);
            }
            else {
                const to = key.indexOf(']');
                const index = key.substring(from + 1, to);
                key = key.substring(0, from);
                if (!result[key]) {
                    result[key] = [];
                }
                if (!index) {
                    result[key].push(item[1]);
                }
                else {
                    result[key][index] = item[1];
                }
            }
        });
        return result;
    }
    toUrl(parameters) {
        const clonedParameters = Object.assign({}, parameters);
        let url = this._location.protocol + this._location.hostname + this._location.port + '?';
        let pos = 0;
        Object.keys(clonedParameters).forEach(function (key) {
            const value = clonedParameters[key];
            if (value != null) {
                if (pos > 0) {
                    url += '&';
                }
                url += key + '=' + encodeURIComponent(value);
            }
            pos++;
        });
        return url;
    }
}
exports.UrlParametersService = UrlParametersService;
