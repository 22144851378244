"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiemChannelWizardComponent = void 0;
const core_1 = require("@angular/core");
const siem_channels_models_1 = require("../../../models/siem-channels.models");
class SiemChannelWizardComponent {
    constructor(changeDetector, siemService) {
        this.changeDetector = changeDetector;
        this.siemService = siemService;
        this.currentStep = 1;
        this.subscribedProducts = [];
        this.hasEditPermission = true;
        this.apiIsProcessing = false;
        this.updateChannelDescrInStore = new core_1.EventEmitter();
        this.updateChannelProductsInStore = new core_1.EventEmitter();
        this.updateProductEventsInStore = new core_1.EventEmitter();
        this.onCancelWizard = new core_1.EventEmitter();
        this.onCreateChannel = new core_1.EventEmitter();
    }
    ngOnChanges(changes) {
        if (changes['channel'] &&
            changes['channel'].firstChange &&
            this.isEditFlow() &&
            this.channel.products) {
            this.subscribedProducts = [];
            this.subscribedProducts = this.siemService
                .deepCopyProducts(this.channel.products)
                .filter(p => p.enabled);
        }
    }
    ngAfterViewInit() {
        this.changeDetector.detectChanges();
    }
    goToStep(event) {
        this.currentStep = event;
    }
    cancel() {
        this.onCancelWizard.emit();
    }
    createChannel() {
        this.onCreateChannel.emit();
    }
    getTitle() {
        if (!this.isEditFlow()) {
            return '$I18N_SIEM_CHANNEL_WIZARD.CREATE_HEADING';
        }
        else {
            return '$I18N_SIEM_CHANNEL_WIZARD.EDIT_HEADING';
        }
    }
    getSubTitle() {
        if (!this.isEditFlow()) {
            return '$I18N_SIEM_CHANNEL_WIZARD.CREATE_SUB_HEADING';
        }
        else {
            return '$I18N_SIEM_CHANNEL_WIZARD.EDIT_SUB_HEADING';
        }
    }
    isEditFlow() {
        return this.wizardWorkflowType !== siem_channels_models_1.SiemChannelWizardFlowType.CREATE;
    }
    isStepValid() {
        if (!!this.wizard) {
            if (this.wizard.currentStep === 1) {
                return this.detailsStep.isValid();
            }
            else if (this.wizard.currentStep === 2) {
                return this.productListStep.isValid();
            }
            else {
                const currentStepLabel = this.wizard.steps[this.currentStep - 1].label;
                return this.productEventsSteps
                    .find(step => step.productAvailable.name === currentStepLabel)
                    .isValid();
            }
        }
        return false;
    }
    isValidChannel(channel) {
        const enabledProducts = channel.products.filter(p => p.enabled);
        return (!!channel.name.trim() &&
            !!enabledProducts.length &&
            this.hasAtLeastOneEventEnabledInProduct(enabledProducts));
    }
    hasAtLeastOneEventEnabledInProduct(products) {
        let flag = true;
        products.filter(p => {
            const eventsEnabled = this.siemService.hasEventsEnabled(p.groups);
            if (!eventsEnabled) {
                flag = false;
            }
        });
        return flag;
    }
    updateChannelProducts(products) {
        this.subscribedProducts = this.siemService.deepCopyProducts(products);
        this.updateChannelProductsInStore.emit(products);
    }
    updateChannelDescr(channelDescription) {
        if (this.channel.type !== channelDescription.type) {
            this.subscribedProducts = [];
        }
        this.updateChannelDescrInStore.emit(channelDescription);
    }
}
exports.SiemChannelWizardComponent = SiemChannelWizardComponent;
