"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
/**
 * Created by pdesai on 14/03/2017.
 */
(function () {
    'use strict';
    document.createElement('mc-pagination');
    angular.module('pagination.directive', [])
        .directive('mcPagination', function () {
        return {
            restrict: 'E',
            templateUrl: 'components/pagination/pagination.tpl.html',
            scope: {
                label: '<',
                nextDisabled: '<',
                nextOnChange: '&',
                previousDisabled: '<',
                previousOnChange: '&'
            },
            controllerAs: 'paginationCtrl',
            bindToController: true,
            controller() { }
        };
    });
}());
