<mc-collapsable-panel header="$I18N_SWG_THREAT_EVENT_MALICIOUS_URLS.TITLE" [isExpanded]="true"
                      class="threat-event-url-scan">
  <ng-container *ngIf="threatEvent.urlScan">
    <p>{{ "$I18N_SWG_THREAT_EVENT_MALICIOUS_URLS.MESSAGE" | translate}}</p>

    <ul class="threat-event-malicious-urls">
      <li *ngFor="let url of getUrls; let i = index"
          class="threat-event-malicious-urls-item">
        <i class="fa fa-exclamation-circle text-danger"></i>
        <i [title]="url.full">
          {{ url.full.length > MAX_LINK_SIZE ? url.domain : url.full }}
        </i>

        <button *ngIf="url.full.length > MAX_LINK_SIZE"
                class="btn btn-link"
                (click)="toggleShowUrl(i)">
          {{ (openedIndex !== i
          ? '$I18N_SWG_THREAT_EVENT_MALICIOUS_URLS.SHOW_FULL'
          : '$I18N_SWG_THREAT_EVENT_MALICIOUS_URLS.HIDE_FULL') | translate }}
        </button>

        <pre *ngIf="url.full.length > MAX_LINK_SIZE" [hidden]="openedIndex !== i">{{ url.full }}</pre>
      </li>
    </ul>
  </ng-container>

  <ng-container *ngIf="!threatEvent.urlScan">
    {{ '$I18N_SWG_THREAT_EVENT_MALICIOUS_URLS.NO_DATA' | translate }}
  </ng-container>
</mc-collapsable-panel>
