<section class="mc-detail-section" *ngIf="!isEditMode()">
  <div class="panel-body no-padding-left no-padding-right">
    <div class="mc-detailed-list">
      <div *ngFor="let key of objectKeys(formGroupConfig)" class="row mc-detailed-list-row">
        <div class="col-xs-4 mc-detailed-list-label">{{getLabelForKey(key) | translate}}</div>
        <div class="mc-detailed-list-column">{{getValue(key) }}</div>
      </div>
    </div>
  </div>
</section>
<section class="mc-detail-section" *ngIf="isEditMode()">
  <div class="panel-body">
    <form [formGroup]="formGroup">
      <div class="row form-group">
        <div *ngFor="let key of objectKeys(formGroupConfig)">
          <div *ngIf="isDropdown(key); else toggleField">
            <mc-field [label]="getLabelForKey(key)" [required]="isRequired(key)"
                      helpPopoverContent="{{ getPopoverTextForKey(key) }}"
                      errorPrefix="$I18N_API_APPLICATIONS_SAVE.VALIDATION.FIELD">
              <mc-select [options]="dropdownValues" [formControlName]="key"
                         [defaultValue]="formGroup.get('promptType').value"
                         errorPrefix="$18N_ATTRIBUTES_DIRECTORIES_FORM.VALIDATION.FIELD">
              </mc-select>
            </mc-field>
          </div>
          <ng-template #toggleField>
            <div *ngIf="isToggle(key) else textField">
              <mc-field [label]="getLabelForKey(key)" [required]="isRequired(key)"
                        helpPopoverContent="{{ getPopoverTextForKey(key) }}"
                        errorPrefix="$I18N_API_APPLICATIONS_SAVE.VALIDATION.FIELD">
                <mc-switch
                  [formControlName]="key"
                  [showLabel]="true"
                  onStateLabel="$18N_ATTRIBUTES_DIRECTORIES_FORM.LABEL.ALLOWEDINTABLEVIEWS_TOGGLE.AVAILABLE"
                  offStateLabel="$18N_ATTRIBUTES_DIRECTORIES_FORM.LABEL.ALLOWEDINTABLEVIEWS_TOGGLE.NOT_AVAILABLE"
                ></mc-switch>
              </mc-field>
            </div>
          </ng-template>
          <ng-template #textField>
              <mc-text-field
                [formControlName]="key"
                [label]="getLabelForKey(key)"
                [required]="isRequired(key)"
                 helpPopoverContent="{{ getPopoverTextForKey(key) }}"
                 errorPrefix="$18N_ATTRIBUTES_DIRECTORIES_FORM.VALIDATION.FIELD">
              </mc-text-field>
          </ng-template>
        </div>
      </div>
    </form>
  </div>
</section>
