"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiGatewaySaveDetailsStepComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const operators_1 = require("rxjs/operators");
const validators_1 = require("app-new/api-applications/validators");
class ApiGatewaySaveDetailsStepComponent {
    constructor(fb, categoryPipe) {
        this.fb = fb;
        this.categoryPipe = categoryPipe;
        this.onApplicationDetailsUpdated = new core_1.EventEmitter();
        this.onSubmitted = new core_1.EventEmitter();
        this.form = this.fb.group({
            applicationName: [
                '',
                [forms_1.Validators.required, forms_1.Validators.minLength(3), forms_1.Validators.maxLength(128), validators_1.validateNotEmpty]
            ],
            category: [null, [forms_1.Validators.required]],
            service: [],
            description: ['', [forms_1.Validators.required, forms_1.Validators.maxLength(768), validators_1.validateNotEmpty]]
        });
    }
    ngOnInit() {
        this.form.patchValue(this.applicationDetails || {});
        this.isTechPartner();
        this.formSubscription = this.form.valueChanges.pipe(operators_1.debounceTime(200)).subscribe(() => {
            this.update();
        });
    }
    get pageTitle() {
        return `$I18N_API_GATEWAY_SAVE_WIZARD.${this.mode}.DETAILS_STEP.HEADER`;
    }
    get pageDescription() {
        return `$I18N_API_GATEWAY_SAVE_WIZARD.${this.mode}.DETAILS_STEP.DESCRIPTION`;
    }
    get categories() {
        return [
            {
                value: 'siem_integration',
                label: this.categoryPipe.transform('siem_integration')
            },
            {
                value: 'xdr_integration',
                label: this.categoryPipe.transform('xdr_integration')
            },
            {
                value: 'msp_ordering_and_provisioning',
                label: this.categoryPipe.transform('msp_ordering_and_provisioning')
            },
            {
                value: 'email_or_archiving',
                label: this.categoryPipe.transform('email_or_archiving')
            },
            {
                value: 'business_intelligence',
                label: this.categoryPipe.transform('business_intelligence')
            },
            {
                value: 'process_automation',
                label: this.categoryPipe.transform('process_automation')
            },
            {
                value: 'soar_integration',
                label: this.categoryPipe.transform('soar_integration')
            },
            {
                value: 'itsm_integration',
                label: this.categoryPipe.transform('itsm_integration')
            },
            {
                value: 'casb_integration',
                label: this.categoryPipe.transform('casb_integration')
            },
            {
                value: 'managed_soc_integration',
                label: this.categoryPipe.transform('managed_soc_integration')
            },
            {
                value: 'digital_risk_platform_integration',
                label: this.categoryPipe.transform('digital_risk_platform_integration')
            },
            {
                value: 'subscription_management_integration',
                label: this.categoryPipe.transform('subscription_management_integration')
            },
            {
                value: 'compliance_integration',
                label: this.categoryPipe.transform('compliance_integration')
            },
            {
                value: 'other',
                label: this.categoryPipe.transform('other')
            }
        ];
    }
    isTechPartner() {
        if (this.applicationDetails.category && this.applicationDetails.service === true) {
            this.form.get('category').disable();
            this.form.get('service').disable();
        }
    }
    get isEditing() {
        return this.mode === 'EDIT';
    }
    get isCreating() {
        return this.mode === 'CREATE';
    }
    submit() {
        if (this.isValid()) {
            this.onSubmitted.emit();
        }
    }
    update() {
        this.onApplicationDetailsUpdated.emit(this.form.getRawValue());
    }
    isValid() {
        return this.form.status === 'VALID';
    }
    ngOnDestroy() {
        this.formSubscription.unsubscribe();
    }
}
exports.ApiGatewaySaveDetailsStepComponent = ApiGatewaySaveDetailsStepComponent;
