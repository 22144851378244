"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ2 = exports.ɵ1 = exports.ɵ0 = exports.dateRangePickerConfig = exports.FilterDateRange = exports.getRange = void 0;
const moment = require("moment");
function getRange(days) {
    // @ts-ignore
    return {
        start: moment()
            .startOf('day')
            .subtract(days, 'days'),
        end: moment().endOf('day')
    };
}
exports.getRange = getRange;
exports.FilterDateRange = {
    start: moment()
        .subtract(1, 'day')
        .format(),
    end: moment().format()
};
const ɵ0 = () => getRange(1), ɵ1 = () => getRange(2), ɵ2 = () => getRange(7);
exports.ɵ0 = ɵ0;
exports.ɵ1 = ɵ1;
exports.ɵ2 = ɵ2;
const predefinedDateRanges = [
    {
        id: 'PAST_24_HOURS',
        label: '$I18N_DATE_RANGE_CONFIG_LABEL_PAST_24_HOURS',
        rangeFn: ɵ0,
        range: getRange(1)
    },
    {
        id: 'PAST_48_HOURS',
        label: '$I18N_DATE_RANGE_CONFIG_LABEL_PAST_48_HOURS',
        rangeFn: ɵ1,
        range: getRange(2)
    },
    {
        id: 'PAST_7_DAYS',
        label: '$I18N_DATE_RANGE_CONFIG_LABEL_PAST_7_DAYS',
        rangeFn: ɵ2,
        range: getRange(7)
    },
    {
        id: 'CUSTOM',
        label: '$I18N_DATE_RANGE_CONFIG_LABEL_CUSTOM_RANGE'
    }
];
// @ts-ignore
exports.dateRangePickerConfig = {
    minDate: moment()
        .startOf('day')
        .subtract(7, 'days'),
    maxDate: moment().endOf('day'),
    allowCustomRange: false,
    dateRanges: predefinedDateRanges
};
