<div class="mc-detailed-list-row header" [ngClass]="{'hide-header': messageStatus === 'held' || messageStatus === 'processing' || messageStatus === 'delivery'}">
  <div class="col-xs-3 mc-panel-top">
    <mc-switch [formControl]="switchField"></mc-switch>
    <span class="switch-label" translate="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TRANSMISSION_DATA.LABEL_COMPARE_VIEWS"></span>
  </div>
  <div class="mc-detailed-list-column column-header" *ngIf="switchField.value">
    <div class="column-header-title" translate="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TRANSMISSION_DATA.LABEL_RECEIVED_VIEW_SENDER"></div>
    <div>{{recipientInfo?.messageInfo?.fromHeader}}</div>
  </div>
  <div class="mc-detailed-list-column column-header" *ngIf="switchField.value">
    <div class="column-header-title" translate="$I18N_MESSAGE_CENTER_MESSAGE_INFO_TRANSMISSION_DATA.LABEL_DELIVERY_VIEW_RECIPIENT"></div>
    <div>
      <ng-container *ngIf="deliveredMessagesRecipients?.length > 1; else onlyOne;">
        <mc-select-searchable [value]="selectedEmailOwner">
          <mc-option *ngFor="let email of deliveredMessagesRecipients" [value]="email" [label]="email" (click)="selectEmail.emit({email: email, isDelivery: true})"></mc-option>
        </mc-select-searchable>
      </ng-container>

      <ng-template #onlyOne>{{selectedEmailOwner}}</ng-template>
    </div>
  </div>
</div>
