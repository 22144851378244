"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SingleSelectDropdownFieldComponent = void 0;
class SingleSelectDropdownFieldComponent {
    constructor() {
        this.filterModel = {};
        this.translatePrefix = '';
    }
    set selectedOption(selectedOption) {
        this.selected = selectedOption;
        this.onChange(this.selected);
        this.onTouched(this.selected);
    }
    get selectedOption() {
        return this.selected;
    }
    get filterLabel() {
        return this.selectedOption && this.getTranslateKey(this.selectedOption);
    }
    ngOnInit() {
        const keys = [
            this.translatePrefix,
            'COLUMN_VALUE_SINGLE_SELECT_FILTER_FIELD',
            this.filterModel.field && this.filterModel.field.toUpperCase()
        ];
        this.fieldTranslationPrefix = keys.filter(n => n).join('.');
        this.headerTranslationKey =
            this.fieldTranslationPrefix +
                '.' +
                (this.filterModel.header && this.filterModel.header.toUpperCase());
        this.dropdownOptions = this.transformToDropdownOptions();
        this.initialValue = this.dropdownOptions[0].value;
    }
    onOptionClick(selectedOption) {
        this.selectedOption = selectedOption;
    }
    clearSelected() {
        this.selectedOption = '';
    }
    writeValue(selected) {
        if (selected) {
            this.selectedOption = selected;
        }
    }
    registerOnChange(fn) {
        this.onChange = fn;
    }
    registerOnTouched(fn) {
        this.onTouched = fn;
    }
    getTranslateKey(key) {
        const keys = [this.fieldTranslationPrefix, 'OPTIONS', key && key.toUpperCase()];
        return keys.filter(n => n).join('.');
    }
    transformToDropdownOptions() {
        const dropdownOptions = this.filterModel.options.map(option => {
            return {
                label: this.getTranslateKey(option),
                value: option
            };
        });
        return dropdownOptions;
    }
}
exports.SingleSelectDropdownFieldComponent = SingleSelectDropdownFieldComponent;
