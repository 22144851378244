<div class="mc-api-application-step-block">
  <mc-wizard-step-header [label]="'$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.ACTIVATION.HEADER'">
  </mc-wizard-step-header>

  <div class="row form-group panel-padding-left">
    <div class="panel-half-padding-bottom">
      <strong>1)</strong>
      {{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.ACTIVATION.STEP_01' | translate }}
    </div>
    <div class="panel-half-padding-bottom">
      <strong>2)</strong>
      {{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.ACTIVATION.STEP_02' | translate }}
    </div>
    <div class="panel-half-padding-bottom">
      <strong>3)</strong>
      {{ '$I18N_API_INTEGRATION_CORTEX_WIZARD.CORTEX.STEPS.ACTIVATION.STEP_03' | translate }}
    </div>
    <div class="panel-padding-top">
      <mc-inline-notification
        [notification]="{
          status: 'info',
          boxed: false,
          msg: 'This is a mandatory external task'
        }"
      >
      </mc-inline-notification>
    </div>
  </div>
</div>
