<div
  class="selected-group"
  *ngFor="let item of data; let i = index"
>
  <span class="groups-list labels">
    <div class="groups-name">{{ item?.name }}</div>
    <div class="pull-right">
      <i
        class="far fa-trash-alt"
        aria-hidden="true"
        [class.disabled]="disableRemoveAction"
        (click)="removeItemOfIndex(i)"
      ></i>
    </div>
  </span>
  <hr class="groups-separator">
</div>
