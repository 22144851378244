"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
(function () {
    'use strict';
    /* ======================================================== *\

     = layout / page-list-filters / directive

     \* ======================================================== */
    document.createElement('mc-layout-page-list-filters');
    angular.module('layout.page-list-filters.directive', [])
        .directive('mcLayoutPageListFilters', [function () {
            return {
                restrict: 'E',
                templateUrl: 'components/layout/page-list-filters/page-list-filters.tpl.html',
                scope: {
                    mainContainerClass: '@',
                    mainResultsCounter: '=',
                    headerMiddleRowFirstCss: '@',
                    headerMiddleRowSecondCss: '@',
                    paginationContainerCss: '@',
                    isLoading: '='
                },
                transclude: {
                    headerTopRowTitleContainer: 'headerTopRowTitleContainer',
                    headerTopRowSideContainer: '?headerTopRowSideContainer',
                    headerMiddleRowFirst: '?headerMiddleRowFirst',
                    headerMiddleRowSecond: '?headerMiddleRowSecond',
                    paginationContainer: '?paginationContainer',
                    filtersContainer: 'filtersContainer',
                    mainContainer: 'mainContainer',
                    emptyContainer: 'emptyContainer'
                },
                link(scope, element, attrs, controller, transcludeFn) {
                    scope.isHeaderTopRowSideContainer = transcludeFn.isSlotFilled('headerTopRowSideContainer');
                    scope.isHeaderMiddleRowFirst = transcludeFn.isSlotFilled('headerMiddleRowFirst');
                    scope.isHeaderMiddleRowSecond = transcludeFn.isSlotFilled('headerMiddleRowSecond');
                    scope.isPaginationContainer = transcludeFn.isSlotFilled('paginationContainer');
                }
            };
        }]);
}());
