"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectUserGroupsComponent = void 0;
const operators_1 = require("rxjs/operators");
const findGroupsAction = require("../../actions/find-groups.actions");
const getGroupAction = require("../../actions/get-group.actions");
const getGroupsAction = require("../../actions/get-groups.actions");
const group_1 = require("../../models/group");
const selectors_1 = require("../../selectors");
class SelectUserGroupsComponent {
    constructor(overlayRef, translate, store, cdr, overlayData) {
        this.overlayRef = overlayRef;
        this.translate = translate;
        this.store = store;
        this.cdr = cdr;
        this.overlayData = overlayData;
        this.isSearchResults = false;
        this.hasPreselectedGroups = false;
        this.onlyLocalGroups = false;
        this.minCharacterSearch = 1;
        this.searchValid = false;
        this.searchErrors = {};
        this.selectedLdapGroups = [];
        this.selectedCloudGroups = [];
        this.sourceType = group_1.SourceType;
        this.onlyLocalGroups = this.overlayData.onlyLocalGroups;
        this.hasPreselectedGroups = this.overlayData.hasPreselectedGroups;
        this.minCharacterSearch = this.overlayData.minCharacters ? this.overlayData.minCharacters : 1;
        this.placeholderActiveDirectoryGroups = this.translate.instant('$I18N_API_INTEGRATION_SHARED.SELECT_USER_GROUPS.FILTER_PLACEHOLDER.ACTIVE_DIRECTORY');
        this.placeholderLocalGroups = this.translate.instant('$I18N_API_INTEGRATION_SHARED.SELECT_USER_GROUPS.FILTER_PLACEHOLDER.LOCAL');
    }
    ngOnInit() {
        this.options = {
            getChildren: this.getChildren.bind(this),
            displayField: 'description',
            childrenField: 'folders',
            useCheckbox: true
        };
        this.isLDAPSearching = false;
        this.isCloudSearching = false;
        this.showLDAPNoSearchResults = false;
        this.showCloudNoSearchResults = false;
        this.showSpinner = true;
        this.activeSource = this.onlyLocalGroups ? group_1.SourceType.CLOUD : group_1.SourceType.LDAP;
        this.searchResultMessage = this.translate.instant('$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SETTINGS_STEP.PANEL.SEARCH_RESULT_MSG');
        this.loadingMessage = this.translate.instant('$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SETTINGS_STEP.PANEL.LOADING_MSG');
        this.store.dispatch(new getGroupsAction.GetGroupsCloud({ depth: 0, id: null, source: 'cloud' }));
        this.store.dispatch(new getGroupsAction.GetGroupsLDAP({ depth: 0, id: null, source: 'ldap' }));
        this.registerGetGroups(true);
        this.isCloudDataLoaded$ = this.store.select(selectors_1.isGetGroupsCloudDataLoaded);
        this.isLDAPDataLoaded$ = this.store.select(selectors_1.isGetGroupsLDAPDataLoaded);
        this.isGetGroupsLoadingError$ = this.store.select(selectors_1.isGetGroupsLoadingError);
        this.isFindGroupsLoadingError$ = this.store.select(selectors_1.isFindGroupsLoadingError);
        this.hasInitialCloudData$ = this.store.select(selectors_1.hasInitialCloudData);
        this.hasInitialLDAPData$ = this.store.select(selectors_1.hasInitialLDAPData);
        this.isLDAPReady$ = this.store.select(selectors_1.isLDAPGroupReady);
        this.isCloudReady$ = this.store.select(selectors_1.isCloudGroupReady);
        this.findGroupsSubscription = this.isFindGroupsLoadingError$.subscribe(value => {
            if (value) {
                if (this.isLDAPSearching) {
                    this.showLDAPNoSearchResults = true;
                    this.isLDAPSearching = false;
                }
                if (this.isCloudSearching) {
                    this.showCloudNoSearchResults = true;
                    this.isCloudSearching = false;
                }
            }
        });
    }
    ngAfterViewInit() {
        this.cdr.reattach();
    }
    ngOnDestroy() {
        if (this.findGroupsSubscription) {
            this.findGroupsSubscription.unsubscribe();
        }
        if (this.foundGroupsSubscription) {
            this.foundGroupsSubscription.unsubscribe();
        }
        if (this.groupsSubscription) {
            this.groupsSubscription.unsubscribe();
        }
        this.cdr.detach();
    }
    // this method is invoked by the tree component. based on
    // getChildren in the options object set in ngOnInit()
    // It wants a promise to be returned.
    getChildren(node) {
        if (node.isExpanded && node.data.hasChildren) {
            const expandedNode = node;
            // get children
            if (expandedNode.data.hasChildren) {
                this.getNodeChildren(expandedNode.data);
            }
            this.group$ = this.store.select(selectors_1.getGroup);
            return this.group$
                .pipe(operators_1.map(group => {
                const currGroup = group[0];
                if (currGroup && expandedNode && currGroup.id === expandedNode.data.id) {
                    // populate our flat array of folder items for lookup
                    this.populateFlatArray(currGroup.folders);
                    return this.mapGroupToTreeNode(currGroup.folders);
                }
                else {
                    return [];
                }
            }), operators_1.take(2))
                .toPromise();
        }
    }
    onTabChange(event) {
        this.activeSource = event === 0 ? group_1.SourceType.LDAP : group_1.SourceType.CLOUD;
    }
    onMultipleItemSelected(items, sourceType) {
        const selectedIds = items.map(item => item.id);
        if (sourceType === group_1.SourceType.LDAP) {
            this.selectedLdapGroups = this.filterFolderItemById(selectedIds);
        }
        else {
            this.selectedCloudGroups = this.filterFolderItemById(selectedIds);
        }
    }
    mapGroupToTreeNode(groups = [], isSearch = false) {
        return groups.map((group) => {
            var _a;
            let children = false;
            if (!isSearch) {
                children = group.folderCount > 0 || !!((_a = group.folders) === null || _a === void 0 ? void 0 : _a.length);
            }
            return Object.assign({ hasChildren: children }, group);
        });
    }
    filterFolderItemById(ids) {
        return this.allGroups.filter((item) => ids.includes(item.id));
    }
    findFolderItemById(id) {
        return this.allGroups.find((item) => item.id === id);
    }
    populateFlatArray(collection = []) {
        collection.forEach((item) => {
            if (!this.findFolderItemById(item.id)) {
                this.allGroups.push(item);
            }
        });
    }
    getNodeChildren(folderItem) {
        const payload = { id: folderItem.id, depth: 1, source: folderItem.source };
        this.store.dispatch(new getGroupAction.GetGroup(payload));
    }
    assignSearchVariables(source) {
        this.isSearchResults = true;
        this.showLDAPNoSearchResults = false;
        this.showCloudNoSearchResults = false;
        if (source === group_1.SourceType.LDAP) {
            this.isLDAPSearching = true;
        }
        else {
            this.isCloudSearching = true;
        }
    }
    populateRespectiveGroups(results = []) {
        if (this.ldapTree) {
            this.manageFindGroupData(group_1.SourceType.LDAP, results);
        }
        if (this.cloudTree) {
            this.manageFindGroupData(group_1.SourceType.LDAP, results);
        }
    }
    manageFindGroupData(source, results = []) {
        if (source === group_1.SourceType.LDAP) {
            if (!!results.length) {
                this.ldapGroups = this.populateSearchResults(results);
                this.hasLDAPSearchResults = true;
            }
            else {
                this.showLDAPNoSearchResults = true;
                this.hasLDAPSearchResults = false;
            }
            this.isLDAPSearching = false;
        }
        else {
            if (!!results.length) {
                this.cloudGroups = this.populateSearchResults(results);
                this.hasCloudSearchResults = true;
            }
            else {
                this.showCloudNoSearchResults = true;
                this.hasCloudSearchResults = false;
            }
            this.isCloudSearching = false;
        }
    }
    onSearch(event) {
        this.searchValid = event.search && event.search.length >= this.minCharacterSearch;
        this.searchErrors = {};
        if (this.searchValid) {
            const selector = this.isLDAPSource() ? selectors_1.findLdapGroups : selectors_1.findCloudGroups;
            this.foundGroups$ = this.store.select(selector);
            this.setTreeRef();
            this.store.dispatch(new findGroupsAction.FindGroupsResetStore());
            this.assignSearchVariables(this.activeSource);
            this.foundGroupsSubscription = this.foundGroups$.subscribe(groups => {
                this.populateRespectiveGroups(groups);
                this.cdr.markForCheck();
            });
            const config = {
                source: this.activeSource,
                query: event.search
            };
            this.store.dispatch(new findGroupsAction.FindGroups(config));
        }
        else {
            this.searchErrors[`${this.minCharacterSearch}MINLENGTH`] = true;
        }
    }
    onClearSearch(event) {
        if (this.isSearchResults) {
            this.isSearchResults = false;
            this.hasLDAPSearchResults = false;
            this.hasCloudSearchResults = false;
            this.store.dispatch(new findGroupsAction.FindGroupsResetStore());
            this.registerGetGroups(true, event);
        }
    }
    close(result, $event) {
        if ($event) {
            $event.preventDefault();
        }
        this.overlayRef.close();
    }
    registerGetGroups(collapse = false, source = 'all') {
        this.groups$ = this.store.select(selectors_1.getAllGroups);
        this.groupsSubscription = this.groups$.subscribe(groups => {
            const canCollapseCloudTree = collapse && this.cloudTree;
            const canCollapseLDAPTree = collapse && this.ldapTree;
            this.showLDAPNoSearchResults = false;
            this.showCloudNoSearchResults = false;
            const { cloudGroups, ldapGroups } = groups;
            if (cloudGroups && ldapGroups) {
                this.allGroups = [...cloudGroups, ...ldapGroups];
                switch (source) {
                    case group_1.SourceType.CLOUD:
                        this.cloudGroups = this.mapGroupToTreeNode(cloudGroups);
                        if (canCollapseCloudTree) {
                            this.cloudTree.treeModel.collapseAll();
                        }
                        break;
                    case group_1.SourceType.LDAP:
                        this.ldapGroups = this.mapGroupToTreeNode(ldapGroups);
                        if (canCollapseLDAPTree) {
                            this.ldapTree.treeModel.collapseAll();
                        }
                        break;
                    default:
                        this.cloudGroups = this.mapGroupToTreeNode(cloudGroups);
                        if (canCollapseCloudTree) {
                            this.cloudTree.treeModel.collapseAll();
                        }
                        this.ldapGroups = this.mapGroupToTreeNode(ldapGroups);
                        if (canCollapseLDAPTree) {
                            this.ldapTree.treeModel.collapseAll();
                        }
                }
                this.cdr.markForCheck();
            }
        });
    }
    populateSearchResults(localGroup) {
        this.populateFlatArray(localGroup);
        return this.mapGroupToTreeNode(localGroup, true);
    }
    setTreeRef() {
        if (!this.ldapTree && this.activeSource === group_1.SourceType.LDAP) {
            this.ldapTree = this.ldapTreeView.getTreeRef();
        }
        if (!this.cloudTree && this.activeSource === group_1.SourceType.CLOUD) {
            this.cloudTree = this.cloudTreeView.getTreeRef();
        }
    }
    addSelection() {
        this.overlayRef.sendResult([...this.selectedLdapGroups, ...this.selectedCloudGroups]);
        this.close();
    }
    isLDAPSource() {
        return this.activeSource === group_1.SourceType.LDAP;
    }
}
exports.SelectUserGroupsComponent = SelectUserGroupsComponent;
