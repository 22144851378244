"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExchangeSyncAndRecoverPageComponent = void 0;
class ExchangeSyncAndRecoverPageComponent {
    constructor(windowService, backupService) {
        this.windowService = windowService;
        this.backupService = backupService;
        this.tabs = [
            {
                label: '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ARCHIVE_TASK_MENU_LABEL',
                link: 'archive-task-list',
                helpLink: 'https://community.mimecast.com/s/knowledge-hub-sync-and-recover',
                cap: this.backupService.constants.SYNC_PERMISSIONS.DATA_SYNC_AND_RECOVER_READ +
                    ' || ' +
                    this.backupService.constants.SYNC_PERMISSIONS.DATA_SYNC_READ,
                children: ['archive-task-detail']
            },
            {
                label: '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ARCHIVE_MAILBOXES_MENU_LABEL',
                link: 'mailboxes-list',
                helpLink: 'https://community.mimecast.com/s/knowledge-hub-sync-and-recover',
                cap: this.backupService.constants.SYNC_PERMISSIONS.DATA_SYNC_AND_RECOVER_READ +
                    ' || ' +
                    this.backupService.constants.SYNC_PERMISSIONS.DATA_SYNC_READ,
                children: ['backup-mailboxdetails']
            },
            {
                label: '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_EXPORT_AND_RESTORE_MENU_LABEL',
                link: 'backup-exports-and-restore',
                helpLink: 'https://community.mimecast.com/s/knowledge-hub-sync-and-recover',
                cap: this.backupService.constants.SYNC_PERMISSIONS.SYNC_MAILBOX_ARCHIVE_AND_RECOVER +
                    ' && ' +
                    this.backupService.constants.SYNC_PERMISSIONS.DATA_SYNC_AND_RECOVER_READ
            },
            {
                label: '$I18N_SYNC_RECOVER_EXCHANGE_SIT_LIST_TITLE',
                link: 'sync-recover-exchange-sit-list',
                helpLink: 'https://community.mimecast.com/s/knowledge-hub-sync-and-recover',
                cap: this.backupService.constants.SYNC_PERMISSIONS.SYNC_MAILBOX_ARCHIVE_AND_RECOVER +
                    ' && ' +
                    this.backupService.constants.SYNC_PERMISSIONS.DATA_SYNC_AND_RECOVER_READ,
                children: ['sync-recover-exchange-snapshot-detail']
            },
            {
                label: '$I18N_ADMINISTRATION_MENU_BACKUP_SEARCH_LOGS_TITLE',
                link: 'sync-recovery-search-logs',
                helpLink: 'https://community.mimecast.com/s/knowledge-hub-sync-and-recover',
                cap: 'Permission.DATA_SYNC_READ || Permission.DATA_SYNC_AND_RECOVER_READ'
            },
            {
                label: 'Synced Drives',
                link: 'onedrive-list',
                cap: 'Development.Only && Temporary.Services.OneDrive',
                hidden: true
            }
        ];
    }
    getMaxHeight(content) {
        return `calc(100vh - ${content.getBoundingClientRect().top}px)`;
    }
}
exports.ExchangeSyncAndRecoverPageComponent = ExchangeSyncAndRecoverPageComponent;
