"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ManagedSenderService = void 0;
const operators_1 = require("rxjs/operators");
class ManagedSenderService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    permitOrBlockerSender(instances) {
        // @ts-ignore
        return this.httpClient
            .post('/api/managedsender/permit-or-block-sender', instances)
            .pipe(operators_1.map(response => response));
    }
}
exports.ManagedSenderService = ManagedSenderService;
