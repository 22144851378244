"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgUrlCheckService = void 0;
class SwgUrlCheckService {
    constructor(apiService) {
        this.apiService = apiService;
    }
    getUrlClassification(domainUrl) {
        return this.apiService.getUrlClassification({ domainUrl });
    }
    reportUrlClassification(data) {
        return this.apiService.reportUrlClassification({
            domainUrl: data.domainUrl,
            comments: data.comments.trim() ? data.comments : '(none)'
        });
    }
}
exports.SwgUrlCheckService = SwgUrlCheckService;
