"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tableFilters = exports.columnsToStartHidden = exports.columnsToIgnore = exports.addActionColumnsAlwaysVisible = exports.columnsAlwaysVisible = exports.addActionColumns = exports.columns = void 0;
exports.columns = [
    'deviceId',
    'deviceName',
    'os',
    'username',
    'privateIp',
    'status',
    'action'
];
exports.addActionColumns = [
    'check-row',
    'deviceId',
    'deviceName',
    'os',
    'username',
    'privateIp',
    'status',
    'actions',
    'action'
];
exports.columnsAlwaysVisible = ['deviceName', 'os', 'status'];
exports.addActionColumnsAlwaysVisible = ['check-row', 'deviceName', 'os', 'status', 'actions'];
exports.columnsToIgnore = ['check-row'];
exports.columnsToStartHidden = ['deviceId', 'username', 'privateIp'];
exports.tableFilters = [
    {
        name: 'status',
        displayName: 'Status',
        filters: [
            {
                name: 'unprotected',
                displayName: 'Unprotected',
                isSelected: false
            },
            {
                name: 'protected',
                displayName: 'Protected',
                isSelected: false
            },
            {
                name: 'disabled',
                displayName: 'Disabled',
                isSelected: false
            }
        ]
    },
    {
        name: 'action',
        displayName: 'Group Membership',
        isSingleSelect: true,
        filters: [
            {
                name: 'added',
                displayName: 'Added',
                isSelected: false
            },
            {
                name: 'ingroup',
                displayName: 'In Group',
                isSelected: false
            },
            {
                name: 'notingroup',
                displayName: 'Not in Group',
                isSelected: false
            }
        ]
    },
    {
        name: 'os',
        displayName: 'Operating System',
        isSingleSelect: true,
        filters: [
            {
                name: 'windows 7',
                displayName: 'Windows 7',
                isSelected: false
            },
            {
                name: 'windows 8.1',
                displayName: 'Windows 8.1',
                isSelected: false
            },
            {
                name: 'windows 10',
                displayName: 'Windows 10',
                isSelected: false
            },
            {
                name: 'windows 11',
                displayName: 'Windows 11',
                isSelected: false
            },
            {
                name: 'windows server',
                displayName: 'Windows Server',
                isSelected: false
            },
            {
                name: 'macos',
                displayName: 'MacOS',
                isSelected: false
            },
            {
                name: 'ios',
                displayName: 'iOS',
                isSelected: false
            }
        ]
    }
];
