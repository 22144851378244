"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
(function () {
    'use strict';
    /* ======================================================= *\

     = layout / list simple / directive

     \* ======================================================= */
    document.createElement('mc-layout-list-simple');
    angular.module('layout.list-simple.directive', [])
        .directive('mcLayoutListSimple', ['$translate', function ($translate) {
            return {
                restrict: 'E',
                templateUrl: 'components/layout/list-simple/list-simple.tpl.html',
                scope: {
                    mainContainerClass: '@mainContainerClass',
                    mainResultsCounter: '=mainResultsCounter',
                    hideHeader: '=hideHeader',
                    headerTitle: '@headerTitle',
                    headerDescription: '@headerDescription',
                    headerFavouriteIsVisible: '=headerFavouriteIsVisible',
                    headerKbLink: '@headerKbLink',
                    headerKbDescKey: '@headerKbDescKey',
                    isLoading: '='
                },
                transclude: {
                    headerSideContainer: '?headerSideContainer',
                    paginationContainer: '?paginationContainer',
                    mainContainer: 'mainContainer',
                    emptyContainer: 'emptyContainer'
                },
                link(scope, element, attrs, controller, transcludeFn) {
                    scope.headerKbTranslatedDesc = $translate.instant(scope.headerKbDescKey);
                    scope.headerSideContainer = transcludeFn.isSlotFilled('headerSideContainer');
                    scope.paginationContainer = transcludeFn.isSlotFilled('paginationContainer');
                }
            };
        }]);
}());
