"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListComponent = void 0;
const kb_urls_1 = require("app-new/core/constants/kb-urls");
const table_store_1 = require("@mimecast-ui/table-store");
const asideAction = require("../../actions/aside.action");
const listAction = require("../../actions/list.action");
const rxjs_1 = require("rxjs");
const reducers = require("../../reducers");
const asidePaginationAction = require("../../actions/pagination.actions");
const list_impl_service_1 = require("../../containers/list/service/list.impl.service");
class ListComponent extends table_store_1.TableBaseComponent {
    constructor(store, capabilitiesService) {
        super(store, list_impl_service_1.ListImplService.STORE_ID);
        this.store = store;
        this.capabilitiesService = capabilitiesService;
        this.PREFIX_KEY = '$18N_ATTRIBUTES_DIRECTORIES_LIST';
        this.KB_URL = kb_urls_1.KBUrls.DIRECTORIES_ATTRIBUTES;
        this.DISABLE_ATTRIBUTE_KEY = 'HIDDEN_ATTRIBUTE';
        this.columnList = ['status', 'prompt', 'promptGroup', 'promptType'];
        this.externalTableSearchCount = 0;
        this.externalTableSearchCount$ = new rxjs_1.BehaviorSubject(this.externalTableSearchCount);
    }
    ngOnInit() {
        super.ngOnInit();
        this.tableDataSubscription = this.tableData$.subscribe((attributes) => {
            this.attributes = attributes;
        });
        this.selectedRow$ = this.store.select(reducers.getSelectedRow);
        this.selectedRowSubscription = this.selectedRow$.subscribe((row) => {
            if (row) {
                const currentIndex = this.attributes.map(attribute => attribute.id).indexOf(row.id);
                const pagination = {
                    currentIndex,
                    prevRow: currentIndex > 0 ? this.attributes[currentIndex - 1] : undefined,
                    nextRow: currentIndex < this.attributes.length - 1
                        ? this.attributes[currentIndex + 1]
                        : undefined,
                    pageSize: this.attributes.length
                };
                this.store.dispatch(new asidePaginationAction.PaginationSet(pagination));
            }
        });
        this.userCapabilitiesSubscription = this.capabilitiesService.capabilities.subscribe((caps) => {
            const editPermission = 'Permission.ATTRIBUTES_EDIT';
            this.isEditable = caps[editPermission] && caps[editPermission].enabled;
            if (this.isEditable) {
                this.columnList.push('column-actions');
            }
        });
        this.listSuccessActionSubscription = this.store
            .select(reducers.canUpdateListForListAction)
            .subscribe((updateList) => {
            this.updatePaginationComponent(updateList);
        });
        this.asideSuccessActionSubscription = this.store
            .select(reducers.canUpdateListForAsideAction)
            .subscribe((updateList) => {
            this.updatePaginationComponent(updateList);
        });
    }
    updatePaginationComponent(updateList) {
        // Let the pagination component know to reset the page number
        if (updateList) {
            this.externalTableSearchCount += 1;
            this.externalTableSearchCount$.next(this.externalTableSearchCount);
        }
    }
    getTranslationKey(key) {
        return `${this.PREFIX_KEY}.PROMPTTYPE.${key.toUpperCase()}`;
    }
    onSearch(filters) {
        const searchPayload = {
            searchBy: !filters.searchQuery ? null : { fieldName: 'all', value: filters.searchQuery }
        };
        this.onFilterChange(searchPayload);
    }
    isDisabledAttribute(val) {
        return val.toUpperCase() === this.DISABLE_ATTRIBUTE_KEY;
    }
    getStatusKey(promptType) {
        return this.isDisabledAttribute(promptType)
            ? '$18N_ATTRIBUTES_DIRECTORIES_LIST.STATUS_COLUMN.DISABLED'
            : '$18N_ATTRIBUTES_DIRECTORIES_LIST.STATUS_COLUMN.ENABLED';
    }
    onRowClick(row) {
        this.store.dispatch(new asideAction.GetAttributeDetails(row));
    }
    onOpenCreateAttribute() {
        this.store.dispatch(new asideAction.OpenCreateAttribute());
    }
    disableAttribute(row) {
        this.store.dispatch(new listAction.HideAttributeAction(row));
    }
    deleteAttribute(row) {
        this.store.dispatch(new listAction.ConfirmDeleteAttribute(row.id));
    }
    ngOnDestroy() {
        this.tableDataSubscription.unsubscribe();
        this.selectedRowSubscription.unsubscribe();
        this.userCapabilitiesSubscription.unsubscribe();
        this.listSuccessActionSubscription.unsubscribe();
        this.asideSuccessActionSubscription.unsubscribe();
        super.ngOnDestroy();
    }
}
exports.ListComponent = ListComponent;
