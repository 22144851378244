"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DirectorySyncDetailsStepComponent = void 0;
const core_1 = require("@angular/core");
const operators_1 = require("rxjs/operators");
const forms_1 = require("@angular/forms");
const validators_1 = require("../../../../../api-applications/validators");
class DirectorySyncDetailsStepComponent {
    constructor(fb, translateService) {
        this.fb = fb;
        this.translateService = translateService;
        this.delay = 200;
        this.onIntegrationDetailsUpdated = new core_1.EventEmitter();
        this.onServerTypeUpdated = new core_1.EventEmitter();
    }
    ngOnInit() {
        this.form = this.fb.group({
            name: [
                this.name || '',
                [forms_1.Validators.minLength(3), forms_1.Validators.required, forms_1.Validators.maxLength(120), validators_1.validateNotEmpty]
            ],
            description: [this.description || '', [forms_1.Validators.maxLength(240)]],
            type: [this.type || '', [forms_1.Validators.required, validators_1.validateNotEmpty]]
        });
        this.update();
        this.formSubscription = this.form.valueChanges.pipe(operators_1.debounceTime(this.delay)).subscribe(() => {
            this.update();
            this.updateServerType();
        });
    }
    get integrationTypeOptions() {
        const integrationTypes = [
            {
                value: '',
                label: this.translateService.instant('$I18N_DIRECTORY_SYNC_WIZARD.STEPS.DETAILS.TYPE_PLACEHOLDER')
            },
            {
                value: 'active_directory',
                label: this.translateService.instant('$I18N_DIRECTORY_SYNC_WIZARD.STEPS.DETAILS.TYPE_OPTIONS.LDAP_ACTIVE_DIRECTORY')
            },
            {
                value: 'domino',
                label: this.translateService.instant('$I18N_DIRECTORY_SYNC_WIZARD.STEPS.DETAILS.TYPE_OPTIONS.LDAP_DOMINO_DIRECTORY')
            },
            {
                value: 'azure_standard',
                label: this.translateService.instant('$I18N_DIRECTORY_SYNC_WIZARD.STEPS.DETAILS.TYPE_OPTIONS.AZURE_STANDARD')
            },
            {
                value: 'azure_gcc',
                label: this.translateService.instant('$I18N_DIRECTORY_SYNC_WIZARD.STEPS.DETAILS.TYPE_OPTIONS.AZURE_GCC')
            },
            {
                value: 'google_directory',
                label: this.translateService.instant('$I18N_DIRECTORY_SYNC_WIZARD.STEPS.DETAILS.TYPE_OPTIONS.GSUITE')
            }
        ];
        if (this.hasMSE) {
            integrationTypes.push({
                value: 'ad_push',
                label: this.translateService.instant('$I18N_DIRECTORY_SYNC_WIZARD.STEPS.DETAILS.TYPE_OPTIONS.AD_PUSH')
            });
        }
        return integrationTypes;
    }
    update() {
        this.onIntegrationDetailsUpdated.emit(this.form.getRawValue());
    }
    updateServerType() {
        this.onServerTypeUpdated.emit(this.form.value.type === '' ? 'active_directory' : this.form.value.type);
    }
    isValid() {
        return this.form.status === 'VALID';
    }
    ngOnDestroy() {
        this.formSubscription.unsubscribe();
    }
}
exports.DirectorySyncDetailsStepComponent = DirectorySyncDetailsStepComponent;
