"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectLexiconComponent = void 0;
const operators_1 = require("rxjs/operators");
const rule_actions_1 = require("app-new/archive/supervision/actions/rule.actions");
const selectors = require("app-new/archive/supervision/reducers/index");
class SelectLexiconComponent {
    constructor(store) {
        this.store = store;
    }
    ngOnInit() {
        this.lexiconLoading$ = this.store.select(selectors.getRuleLoading);
        this.allLexicons$ = this.store.select(selectors.getRuleLexicons);
        this.selectedLexicon$ = this.store.select(selectors.getSelectedRuleLexicon);
        this.matchingLexicons$ = this.allLexicons$;
    }
    ngOnDestroy() {
        this.store.dispatch(new rule_actions_1.CloseSelectLexiconSidePanelAction());
    }
    matchLexicons(query) {
        this.matchingLexicons$ = this.allLexicons$.pipe(operators_1.map(lexicons => {
            if (lexicons) {
                return lexicons.filter(l => l.name.toLocaleLowerCase().includes(query.toLocaleLowerCase()));
            }
            else {
                return [];
            }
        }));
    }
    addLexicon(selectedLexicon) {
        this.store.dispatch(new rule_actions_1.AddSelectedLexiconAction(selectedLexicon));
    }
}
exports.SelectLexiconComponent = SelectLexiconComponent;
