"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const endpoints_main_component_1 = require("./containers/endpoints-main.component");
angular
    .module('swgEndpoints', [])
    .directive('mcSwgEndpoints', static_1.downgradeComponent({
    component: endpoints_main_component_1.SwgEndpointsPageComponent
}))
    .config([
    '$stateProvider',
    ($stateProvider) => {
        const breadcrumbs = [];
        $stateProvider.state('secure-web-gateway-endpoints', {
            url: '/secure-web-gateway/endpoints',
            data: {
                capabilities: 'Permission.SWG_ENDPOINT_SETTINGS_READ',
                isFixedLayout: false,
                checkProgress: false,
                tabReload: true,
                tabTitle: '$I18N_ENDPOINT_SETTINGS.TAB_TITLE',
                tabId: 'swgEndpoints',
                breadcrumbs
            },
            views: {
                main: {
                    template: '<mc-swg-endpoints></mc-swg-endpoints>'
                }
            },
            onEnter: [
                () => {
                    breadcrumbs.length = 0;
                    breadcrumbs.push({
                        label: '$I18N_MEGA_MENU_GROUP_SECURE_WEB_GATEWAY_LABEL',
                        stateName: 'secure-web-gateway-dashboard'
                    });
                    breadcrumbs.push({
                        label: '$I18N_ENDPOINT_SETTINGS.BREADCRUMB'
                    });
                }
            ]
        });
    }
]);
