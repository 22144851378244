"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImSyncDashboardContainerComponent = void 0;
const table_store_1 = require("@mimecast-ui/table-store");
const im_sync_tasks_service_1 = require("../../services/im-sync-tasks.service");
const im_task_1 = require("../../model/im-task");
const im_sync_actions_1 = require("../../actions/im-sync.actions");
const components_1 = require("@mimecast-ui/components");
const view_im_sync_task_container_component_1 = require("../view-im-sync-task/view-im-sync-task.container.component");
const update_im_sync_task_container_component_1 = require("../update-im-sync-task/update-im-sync-task.container.component");
const update_status_confirmation_modal_component_1 = require("../../components/update-status-confirmation-modal/update-status-confirmation-modal.component");
const operators_1 = require("rxjs/operators");
const rxjs_1 = require("rxjs");
class ImSyncDashboardContainerComponent extends table_store_1.TableBaseComponent {
    constructor(store, asideService, modalService) {
        super(store, im_sync_tasks_service_1.IM_SYNC_SERVICE_ID);
        this.asideService = asideService;
        this.modalService = modalService;
        this.RESET_URL = window.location.pathname + '#/services/im-sync';
        this.destroy$ = new rxjs_1.Subject();
        if (window.location.search !== '') {
            sessionStorage.clear();
            window.location.href = this.RESET_URL;
        }
    }
    onCreateImTask() {
        this.store.dispatch(new im_sync_actions_1.OpenCreateNewImSyncAction());
    }
    viewImTask(selectedImTask) {
        this.openAside(view_im_sync_task_container_component_1.ViewImSyncTaskContainerComponent, selectedImTask.id);
    }
    updateImTask(selectedImTask) {
        this.openAside(update_im_sync_task_container_component_1.UpdateImSyncTaskContainerComponent, selectedImTask.id);
    }
    updateConfirmationModal(updateTaskStatusRequest) {
        const modalConfig = {};
        modalConfig.size = 'md';
        modalConfig.hasBackdrop = true;
        modalConfig.disableClose = true;
        modalConfig.data = {
            imTaskStatus: updateTaskStatusRequest.status
        };
        this.modalService
            .open(update_status_confirmation_modal_component_1.UpdateStatusConfirmationModalComponent, modalConfig)
            .afterClose()
            .pipe(operators_1.takeUntil(this.destroy$))
            .subscribe((confirmed) => {
            if (confirmed) {
                this.store.dispatch(new im_sync_actions_1.UpdateTaskStatusAction(updateTaskStatusRequest));
            }
        });
    }
    updateImTaskStatus(selectedImTask) {
        const updateTaskStatusRequest = {
            id: selectedImTask.id,
            status: this.updateStatus(selectedImTask.status)
        };
        this.updateConfirmationModal(updateTaskStatusRequest);
    }
    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
    updateStatus(status) {
        return status === im_task_1.ImTaskStatus.ENABLED ? im_task_1.ImTaskStatus.DISABLED : im_task_1.ImTaskStatus.ENABLED;
    }
    openAside(container, selectedImTaskId) {
        this.store.dispatch(new im_sync_actions_1.LoadImSyncTaskDetailsAction(selectedImTaskId));
        const asideConfig = new components_1.AsideConfig();
        asideConfig.size = 'lg';
        asideConfig.position = 'right';
        this.asideService.open(container, asideConfig);
    }
}
exports.ImSyncDashboardContainerComponent = ImSyncDashboardContainerComponent;
