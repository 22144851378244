<div class="mc-swg-settings">
  <form novalidate [formGroup]="form">
    <div *ngIf="!isEditable" class="mc-read-only-message">
      <mc-inline-notification [notification]="readOnlyMessage"> </mc-inline-notification>
    </div>
    <div>
      <div [ngClass]="!isEditable ? 'mc-sub-heading-not-editable' : 'mc-sub-heading'">
        {{ '$I18N_ENDPOINT_SETTINGS.SUB_HEADERS.SETTINGS.AUTHENTICATION' | translate }}
      </div>
      <div class="mc-description">
        <div class="mc-description-title">
          {{
            '$I18N_ENDPOINT_SETTINGS.DESCRIPTION.SETTINGS.AUTHENTICATION_USER_LOGIN_TITLE'
              | translate
          }}<br />
        </div>
        <div>
          {{ '$I18N_ENDPOINT_SETTINGS.DESCRIPTION.SETTINGS.AUTHENTICATION_USER_LOGIN' | translate
          }}<br />
        </div>
      </div>
      <div class="mc-toggle-container">
        <mc-switch
          [ngClass]="{ 'mc-settings-toggle': !isEditable || isEndpointSettingsUpdating }"
          formControlName="userLogin"
          tabindex="0"
          role="switch"
          [attr.aria-checked]="this.form.get('userLogin').value"
          (keyup.enter)="this.onUserLoginToggle()"
          (keyup.space)="this.onUserLoginToggle()"
        >
        </mc-switch>
        <span class="mc-toggle-label" *ngIf="form.get('userLogin').value">
          {{ '$I18N_ENDPOINT_SETTINGS.LABELS.SETTINGS.TOGGLE_ENABLED' | translate }}
        </span>
        <span class="mc-toggle-label" *ngIf="!form.get('userLogin').value">
          {{ '$I18N_ENDPOINT_SETTINGS.LABELS.SETTINGS.TOGGLE_DISABLED' | translate }}
        </span>
        <span
          class="mc-settings-update-display"
          *ngIf="isEndpointSettingsUpdating && currentControl === 'userLogin'"
        >
          <mc-spinner class="mc-settings-spinner"></mc-spinner>{{ settingOperationMessage }}
        </span>
      </div>
      <div class="mc-description">
        <div class="mc-description-title">
          {{
            '$I18N_ENDPOINT_SETTINGS.DESCRIPTION.SETTINGS.AUTHENTICATION_ENFORCE_USER_LOGIN_TITLE'
              | translate
          }}<br />
        </div>
        <div>
          {{
            '$I18N_ENDPOINT_SETTINGS.DESCRIPTION.SETTINGS.AUTHENTICATION_ENFORCE_USER_LOGIN'
              | translate
          }}<br />
        </div>
      </div>
      <div class="mc-toggle-container">
        <mc-switch
          [ngClass]="{ 'mc-settings-toggle': !isEditable || isEndpointSettingsUpdating }"
          formControlName="enforceUserLogin"
          [isDisabled]="!form.get('userLogin').value"
          [attr.tabindex]="form.get('userLogin').value ? 0 : -1"
          role="switch"
          [attr.aria-checked]="this.form.get('enforceUserLogin').value"
          [attr.aria-disabled]="!form.get('userLogin').value"
          (keyup.enter)="this.onEnforceUserLoginToggle()"
          (keyup.space)="this.onEnforceUserLoginToggle()"
        >
        </mc-switch>
        <span class="mc-toggle-label" *ngIf="form.get('enforceUserLogin').value">
          {{ '$I18N_ENDPOINT_SETTINGS.LABELS.SETTINGS.TOGGLE_ENABLED' | translate }}
        </span>
        <span class="mc-toggle-label" *ngIf="!form.get('enforceUserLogin').value">
          {{ '$I18N_ENDPOINT_SETTINGS.LABELS.SETTINGS.TOGGLE_DISABLED' | translate }}
        </span>
        <span
          class="mc-settings-update-display"
          *ngIf="isEndpointSettingsUpdating && currentControl === 'enforceUserLogin'"
        >
          <mc-spinner class="mc-settings-spinner"></mc-spinner>{{ settingOperationMessage }}
        </span>
      </div>
      <div id="allowUserBindingDiv" *mcCapabilities="'Temporary.Permission.Swg.AllowUserBinding'">
        <div class="mc-description">
          <div class="mc-description-title">
            {{
            '$I18N_ENDPOINT_SETTINGS.DESCRIPTION.SETTINGS.ALLOW_USER_BINDING_TITLE'
              | translate
            }}<br />
          </div>
          <div>
            {{ '$I18N_ENDPOINT_SETTINGS.DESCRIPTION.SETTINGS.ALLOW_USER_BINDING' | translate
            }}<br />
          </div>
        </div>
        <div class="mc-toggle-container">
          <mc-switch
            [ngClass]="{ 'mc-settings-toggle': !isEditable || isEndpointSettingsUpdating }"
            formControlName="allowUserBinding"
            tabindex="0"
            role="switch"
            [attr.aria-checked]="this.form.get('allowUserBinding').value"
            (keyup.enter)="this.onAllowUserBindingToggle()"
            (keyup.space)="this.onAllowUserBindingToggle()"
          >
          </mc-switch>
          <span class="mc-toggle-label" *ngIf="form.get('allowUserBinding').value">
          {{ '$I18N_ENDPOINT_SETTINGS.LABELS.SETTINGS.TOGGLE_ENABLED' | translate }}
        </span>
          <span class="mc-toggle-label" *ngIf="!form.get('allowUserBinding').value">
          {{ '$I18N_ENDPOINT_SETTINGS.LABELS.SETTINGS.TOGGLE_DISABLED' | translate }}
        </span>
          <span
            class="mc-settings-update-display"
            *ngIf="isEndpointSettingsUpdating && currentControl === 'allowUserBinding'"
          >
          <mc-spinner class="mc-settings-spinner"></mc-spinner>{{ settingOperationMessage }}
        </span>
        </div>
      </div>
    </div>
    <div class="mc-section-identification">
      <div class="mc-sub-heading">
        {{ '$I18N_ENDPOINT_SETTINGS.SUB_HEADERS.SETTINGS.IDENTIFICATION' | translate }}
      </div>
      <div class="mc-description">
        <div class="mc-description-title">
          {{ '$I18N_ENDPOINT_SETTINGS.DESCRIPTION.SETTINGS.TRANSPARENT_ID_TITLE' | translate
          }}<br />
        </div>
        <div>
          {{ '$I18N_ENDPOINT_SETTINGS.DESCRIPTION.SETTINGS.TRANSPARENT_ID' | translate }}<br />
        </div>
      </div>
      <div class="mc-toggle-container">
        <mc-switch
          [ngClass]="{ 'mc-settings-toggle': !isEditable || isEndpointSettingsUpdating }"
          formControlName="allowTransparentId"
          tabindex="0"
          role="switch"
          [attr.aria-checked]="this.form.get('allowTransparentId').value"
          (keyup.enter)="this.onAllowTransparentIdToggle()"
          (keyup.space)="this.onAllowTransparentIdToggle()"
        >
        </mc-switch>
        <span class="mc-toggle-label" *ngIf="form.get('allowTransparentId').value">
          {{ '$I18N_ENDPOINT_SETTINGS.LABELS.SETTINGS.TOGGLE_ENABLED' | translate }}
        </span>
        <span class="mc-toggle-label" *ngIf="!form.get('allowTransparentId').value">
          {{ '$I18N_ENDPOINT_SETTINGS.LABELS.SETTINGS.TOGGLE_DISABLED' | translate }}
        </span>
        <span
          class="mc-settings-update-display"
          *ngIf="isEndpointSettingsUpdating && currentControl === 'allowTransparentId'"
        >
          <mc-spinner class="mc-settings-spinner"></mc-spinner>{{ settingOperationMessage }}
        </span>
      </div>
      <div *ngIf="hasTUIDAttributeSwitchEnabled && form.get('allowTransparentId').value === true" class="mc-toggle-container">
        <div class="mc-tuid-attribute-dropdown mc-toggle-container">
          <label class="mc-direct-ip-mode-label">
            {{ '$I18N_ENDPOINT_SETTINGS.DESCRIPTION.SETTINGS.TUID_ATTRIBUTE' | translate }}
          </label>
          <div id="tuidAttributeSelect" class="mc-direct-ip-mode-select">
            <mc-select
              [ngClass]="{ 'mc-settings-toggle': !isEditable || isEndpointSettingsUpdating }"
              [options]="tuidAttributeOptions"
              [defaultValue]="form.get('ldapMailAttribute').value"
              (selectChange)="onTUIDAttributeChange($event)"
            ></mc-select>
          </div>
        </div>
        <span
          class="mc-settings-update-display"
          *ngIf="isEndpointSettingsUpdating && currentControl === 'ldapMailAttribute'">
                <mc-spinner class="mc-settings-spinner"></mc-spinner>{{ settingOperationMessage }}
        </span>
      </div>
    </div>
    <div class="mc-section-update">
      <div class="mc-sub-heading">
        {{ '$I18N_ENDPOINT_SETTINGS.SUB_HEADERS.SETTINGS.UPDATES' | translate }}
      </div>
      <div class="mc-description">
        <div class="mc-description-title">
          {{ '$I18N_ENDPOINT_SETTINGS.DESCRIPTION.SETTINGS.UPDATES_AUTO_UPDATE_TITLE' | translate
          }}<br />
        </div>
        <div>
          {{ '$I18N_ENDPOINT_SETTINGS.DESCRIPTION.SETTINGS.UPDATES_AUTO_UPDATE' | translate }}<br />
        </div>
      </div>
      <div class="mc-toggle-container">
        <mc-switch
          [ngClass]="{ 'mc-settings-toggle': !isEditable || isEndpointSettingsUpdating }"
          formControlName="autoUpdate"
          tabindex="0"
          role="switch"
          [attr.aria-checked]="this.form.get('autoUpdate').value"
          (keyup.enter)="this.onAutoUpdateToggle()"
          (keyup.space)="this.onAutoUpdateToggle()"
        >
        </mc-switch>
        <span class="mc-toggle-label" *ngIf="form.get('autoUpdate').value">
          {{ '$I18N_ENDPOINT_SETTINGS.LABELS.SETTINGS.TOGGLE_ENABLED' | translate }}
        </span>
        <span class="mc-toggle-label" *ngIf="!form.get('autoUpdate').value">
          {{ '$I18N_ENDPOINT_SETTINGS.LABELS.SETTINGS.TOGGLE_DISABLED' | translate }}
        </span>
        <span
          class="mc-settings-update-display"
          *ngIf="isEndpointSettingsUpdating && currentControl === 'autoUpdate'"
        >
          <mc-spinner class="mc-settings-spinner"></mc-spinner>{{ settingOperationMessage }}
        </span>
      </div>
      <div class="mc-silent-update-div mc-toggle-container" *ngIf="hasSilentUpdateSwitchEnabled && form.get('autoUpdate').value === true">
        <mc-checkbox
          id="silentUpdateCheckbox"
          class="mc-description-title mc-silent-update-checkbox"
          [ngClass]="{ 'mc-settings-toggle': !isEditable || isEndpointSettingsUpdating }"
          label="$I18N_ENDPOINT_SETTINGS.DESCRIPTION.SETTINGS.SILENT_UPDATE"
          formControlName="silentUpdate"
          labelClass="checkbox-inline">
        </mc-checkbox>
        <span
          class="mc-settings-update-display"
          *ngIf="isEndpointSettingsUpdating && currentControl === 'silentUpdate'">
            <mc-spinner class="mc-settings-spinner"></mc-spinner>{{ settingOperationMessage }}
        </span>
      </div>
    </div>
    <div  class="mc-section-protection">
      <div *ngIf="hasDeviceAutoEnrollmentSwitchEnabled || hasDirectIpProtectionSwitchEnabled || hasAllowInternalIpsSwitchEnabled || hasDirectIpProtectionModeSwitchEnabled">
        <hr />
        <div class="mc-sub-heading">
          {{ '$I18N_ENDPOINT_SETTINGS.SUB_HEADERS.SETTINGS.PROTECTION' | translate }}
        </div>
      </div>
      <div *mcCapabilities="'Policy.TTP'">
        <div *ngIf="hasDeviceAutoEnrollmentSwitchEnabled">
          <div class="mc-description">
            <div class="mc-description-title">
              {{ '$I18N_ENDPOINT_SETTINGS.DESCRIPTION.SETTINGS.DEVICE_ENROLLMENT_TITLE' | translate
              }}<br />
            </div>
            <div *ngIf="allowEnableAutoEnrolment">
              {{ '$I18N_ENDPOINT_SETTINGS.DESCRIPTION.SETTINGS.DEVICE_ENROLLMENT_TTP_ENABLED' | translate }}<br />
            </div>
            <div *ngIf="!allowEnableAutoEnrolment">
              {{ '$I18N_ENDPOINT_SETTINGS.DESCRIPTION.SETTINGS.DEVICE_ENROLLMENT_TTP_DISABLED' | translate }}
              <a (click)="goToAccountSettings()" class="btn-link cursor-pointer">
                {{ '$I18N_ENDPOINT_SETTINGS.DESCRIPTION.SETTINGS.ACCOUNT_SETTINGS' | translate }}
              </a>
              <br />
            </div>
          </div>
          <div class="mc-toggle-container">
            <mc-switch
              [ngClass]="{ 'mc-settings-toggle': !allowEnableAutoEnrolment || !isEditable || isEndpointSettingsUpdating }"
              formControlName="enableAutoEnrolment"
              tabindex="0"
              role="switch"
              [isDisabled]="!allowEnableAutoEnrolment"
              [attr.aria-checked]="this.form.get('enableAutoEnrolment').value"
              (keyup.enter)="this.onDeviceAutoEnrollmentToggle()"
              (keyup.space)="this.onDeviceAutoEnrollmentToggle()"
            >
            </mc-switch>
            <span class="mc-toggle-label" *ngIf="form.get('enableAutoEnrolment').value">
            {{ '$I18N_ENDPOINT_SETTINGS.LABELS.SETTINGS.TOGGLE_ENABLED' | translate }}
            </span>
            <span class="mc-toggle-label" *ngIf="!form.get('enableAutoEnrolment').value">
            {{ '$I18N_ENDPOINT_SETTINGS.LABELS.SETTINGS.TOGGLE_DISABLED' | translate }}
            </span>
            <span
              class="mc-settings-update-display"
              *ngIf="isEndpointSettingsUpdating && currentControl === 'enableAutoEnrolment'"
            >
            <mc-spinner class="mc-settings-spinner"></mc-spinner>{{ settingOperationMessage }}
           </span>
          </div>
        </div>
      </div>

      <div id="directIPProtection" *ngIf="hasDirectIpProtectionSwitchEnabled || hasDirectIpProtectionModeSwitchEnabled">
        <div class="mc-description">
          <div class="mc-description-title">
            {{ '$I18N_ENDPOINT_SETTINGS.DESCRIPTION.SETTINGS.DIRECT_IP_PROTECTION_TITLE' | translate }}<br />
          </div>
          <div>
            {{ '$I18N_ENDPOINT_SETTINGS.DESCRIPTION.SETTINGS.DIRECT_IP_PROTECTION' | translate }}
            <a (click)="goToExceptions()" class="btn-link cursor-pointer">
              {{ '$I18N_ENDPOINT_SETTINGS.DESCRIPTION.SETTINGS.EXCEPTION_LIST' | translate }}
            </a><br />
          </div>
        </div>
        <div class="mc-toggle-container">
          <div class="mc-toggle-container" *ngIf="hasDirectIpProtectionSwitchEnabled && !hasDirectIpProtectionModeSwitchEnabled">
              <mc-switch
              [ngClass]="{ 'mc-settings-toggle': !isEditable || isEndpointSettingsUpdating }"
              formControlName="directIpEnabled"
              tabindex="0"
              role="switch"
              [attr.aria-checked]="this.form.get('directIpEnabled').value"
              (keyup.enter)="this.onDirectIPToggle()"
              (keyup.space)="this.onDirectIPToggle()"
              >
              </mc-switch>
              <span class="mc-toggle-label" *ngIf="form.get('directIpEnabled').value">
                {{ '$I18N_ENDPOINT_SETTINGS.LABELS.SETTINGS.TOGGLE_ENABLED' | translate }}
                </span>
              <span class="mc-toggle-label" *ngIf="!form.get('directIpEnabled').value">
                {{ '$I18N_ENDPOINT_SETTINGS.LABELS.SETTINGS.TOGGLE_DISABLED' | translate }}
              </span>
          </div>

          <div *ngIf="hasDirectIpProtectionModeSwitchEnabled && !hasDirectIpProtectionSwitchEnabled" class="mc-toggle-container">
              <label class="mc-direct-ip-mode-label">
                {{'$I18N_REMEDIATION_SETTINGS_TAB.LABELS.MODE' | translate}}
              </label>
              <div id="directIPSelect" class="mc-direct-ip-mode-select">
                <mc-select
                  [ngClass]="{ 'mc-settings-toggle': !isEditable || isEndpointSettingsUpdating }"
                  [options]="directIpModeOptions"
                  [defaultValue]="form.get('directIpMode').value"
                  (selectChange)="onDirectIpModeChange($event)"
                ></mc-select>
              </div>
          </div>

          <span
            class="mc-settings-update-display"
            *ngIf="isEndpointSettingsUpdating && (currentControl === 'directIpMode' || currentControl === 'directIpEnabled')"
          >
              <mc-spinner class="mc-settings-spinner"></mc-spinner>{{ settingOperationMessage }}
          </span>
        </div>
      </div>
      <div class="mc-local-ips mc-toggle-container" *ngIf="hasAllowInternalIpsSwitchEnabled">
        <mc-checkbox
          class="mc-description-title"
          [ngClass]="{ 'mc-settings-toggle': !isEditable || isEndpointSettingsUpdating }"
          label="$I18N_ENDPOINT_SETTINGS.DESCRIPTION.SETTINGS.ALLOW_INTERNAL_IPS"
          formControlName="allowInternalIps"
          labelClass="checkbox-inline">
        </mc-checkbox>
        <span
          class="mc-settings-update-display"
          *ngIf="isEndpointSettingsUpdating && currentControl === 'allowInternalIps'">
            <mc-spinner class="mc-settings-spinner"></mc-spinner>{{ settingOperationMessage }}
        </span>
      </div>
    </div>
  </form>
</div>
