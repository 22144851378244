<mc-layout-aside-extra-container
  keyTitle="{{ '$I18N_API_OKTA_EVIDENCE_BASED_CONTROL_INTEGRATION_ASIDE_VIEW.TITLE' | translate }}"
  [isLoading]="false"
  showClose="true"
  (closeAside)="onCloseClicked.emit()"
>
  <mc-extra-container *mcCapabilities="'Permission.API_APPLICATIONS_EDIT'">
    <mc-edit-integration-view
      [integration]="integration"
      [enabled]="enabled"
      (onEditClicked)="onEditClicked.emit()"
      (onDeleteClicked)="onDeleteClicked.emit()"
      (onEnableClicked)="onEnableClicked.emit()"
      (onDisableClicked)="onDisableClicked.emit()"
    >
    </mc-edit-integration-view>
  </mc-extra-container>

  <mc-body-container>
    <section class="mc-detail-section">
      <div class="panel-body no-padding-top">
        <div class="mc-detailed-list">

          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_OKTA_EVIDENCE_BASED_CONTROL_INTEGRATION_ASIDE_VIEW.FIELDS.INTEGRATION' | translate }}
            </div>
            <div class="mc-detailed-list-column">
              {{ integration.integrationType | mcIntegrationProvider }}
            </div>
          </div>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_OKTA_EVIDENCE_BASED_CONTROL_INTEGRATION_ASIDE_VIEW.FIELDS.DESCRIPTION.NAME' | translate }}
            </div>
            <div class="mc-detailed-list-column">
              {{ '$I18N_API_OKTA_EVIDENCE_BASED_CONTROL_INTEGRATION_ASIDE_VIEW.FIELDS.DESCRIPTION.VALUE' | translate }}
            </div>
          </div>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_OKTA_EVIDENCE_BASED_CONTROL_INTEGRATION_ASIDE_VIEW.FIELDS.CLIENT_ID' | translate }}
            </div>
            <div class="mc-detailed-list-column">
              {{ oktaEvidenceBasedControlIntegration?.clientId }}
            </div>
          </div>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_OKTA_EVIDENCE_BASED_CONTROL_INTEGRATION_ASIDE_VIEW.FIELDS.BASE_URL' | translate }}
            </div>
            <div class="mc-detailed-list-column">
              {{ oktaEvidenceBasedControlIntegration?.baseUrl }}
            </div>
          </div>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_OKTA_EVIDENCE_BASED_CONTROL_INTEGRATION_ASIDE_VIEW.FIELDS.URL_PROTECT' | translate }}
            </div>
            <div class="mc-detailed-list-column">{{ urlProtect }}</div>
          </div>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_OKTA_EVIDENCE_BASED_CONTROL_INTEGRATION_ASIDE_VIEW.FIELDS.ATTACHMENT_PROTECT' | translate }}
            </div>
            <div class="mc-detailed-list-column">{{ attachmentProtect }}</div>
          </div>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_OKTA_EVIDENCE_BASED_CONTROL_INTEGRATION_ASIDE_VIEW.FIELDS.DATA_LEAK_PREVENTION' | translate }}
            </div>
            <div class="mc-detailed-list-column">{{ dataLeakPrevention }}</div>
          </div>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_OKTA_EVIDENCE_BASED_CONTROL_INTEGRATION_ASIDE_VIEW.FIELDS.DATE_CREATED' | translate }}
            </div>
            <div class="mc-detailed-list-column">
              {{ integration.created | mcDatePlusWeekday }}
            </div>
          </div>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_OKTA_EVIDENCE_BASED_CONTROL_INTEGRATION_ASIDE_VIEW.FIELDS.NOTIFY' | translate }}
            </div>

            <div class="mc-detailed-list-column">
              <div
                class="mc-detailed-list-row"
                *ngFor="let group of notificationGroups"
              >
                {{ group | translate }}
              </div>

              <div
                class="mc-detailed-list-row"
                *ngFor="let email of oktaEvidenceBasedControlIntegration?.notificationAddresses"
              >
                {{ email }}
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  </mc-body-container>
</mc-layout-aside-extra-container>
