<mc-collapsable-panel header="$I18N_MESSAGE_CENTER_THREAT_INFO_THREAT_ANALYSIS_PROPERTIES.THREAT_ANALYSIS_TITLE" [isExpanded]="true">
  <div class="mc-detailed-list">
    <mc-tree-view-wrapper-panel
        [defaultTitle]="defaultTitle"
        [showTooltip]="showTooltip"
        [popoverContent]="contentText"
        [linkText]="linkText"
        [linkUrl]="linkUrl"
        [isExpanded]="isExpanded"
        (onCollapse)="collapseTree()"
        (onExpand)="expandTree()">
      <div *ngIf="!!processingDetails && !!processingDetails.verdict; else emptyValue">
        <mc-tree-view
            #treeView
            class="mc-spam-verdict-tree"
            [customNodeTemplate]="true"
            [nodes]="nodes"
            [options]="options">
          </mc-tree-view>
      </div>
    </mc-tree-view-wrapper-panel>
  </div>
</mc-collapsable-panel>

<ng-template #emptyValue>
<span>
  <div class="mc-detailed-list-column">-</div>
</span>
</ng-template>

