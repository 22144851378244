"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApPolicyDetailsStepComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const policy_list_model_1 = require("../../../models/policy-list.model");
const moment = require("moment");
const operators_1 = require("rxjs/operators");
class ApPolicyDetailsStepComponent {
    constructor(fb) {
        this.fb = fb;
        this.updateApPolicyDescType = new core_1.EventEmitter();
        this.durationOptions = {
            allowCustomRange: true,
            minDate: moment(),
            maxDate: moment().add(2, 'years'),
            defaultStartDate: moment(),
            defaultEndDate: moment().add(6, 'days'),
            allowFlexibleRange: true,
            dateRanges: [
                {
                    id: policy_list_model_1.DurationDetailOption.PERPETUAL,
                    label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_DETAILS_STEP_LABEL.DURATION.OPTION_PERPETUAL'
                },
                {
                    id: policy_list_model_1.DurationDetailOption.CUSTOMRANGE,
                    label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_DETAILS_STEP_LABEL.DURATION.OPTION_CUSTOMRANGE'
                }
            ]
        };
        this.apPolicyDetailsForm = this.fb.group({
            description: ['', forms_1.Validators.required],
            apDuration: [this.durationOptions.dateRanges[0], []]
        });
    }
    ngOnInit() {
        this.apPolicyDetailsForm.patchValue(this.policyDetails);
        this.apPolicyDetailsFormSubscription = this.apPolicyDetailsForm.valueChanges
            .pipe(operators_1.debounceTime(200))
            .subscribe(() => {
            this.updateStore();
        });
    }
    updateStore() {
        const currentFormValue = this.apPolicyDetailsForm.value;
        if (this.apPolicyDetailsForm.get('apDuration').value.id !== 'perpetual') {
            if (currentFormValue.apDuration.rangeFn) {
                const newFormValue = Object.assign(Object.assign({}, currentFormValue), { description: currentFormValue.description, startDate: currentFormValue.apDuration.rangeFn().start.format(), endDate: currentFormValue.apDuration.rangeFn().end.format(), apDuration: undefined });
                this.updateApPolicyDescType.emit(newFormValue);
            }
        }
        else {
            this.updateApPolicyDescType.emit(this.apPolicyDetailsForm.value);
        }
    }
    isValid() {
        if (this.apPolicyDetailsForm.get('description').value.trim().length <= 0) {
            return false;
        }
        return this.apPolicyDetailsForm.status === 'VALID';
    }
}
exports.ApPolicyDetailsStepComponent = ApPolicyDetailsStepComponent;
