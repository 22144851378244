"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const static_1 = require("@angular/upgrade/static");
const connectors_page_component_1 = require("./containers/connectors-page/connectors-page.component");
const create_connector_page_component_1 = require("./containers/create-connector-page/create-connector-page.component");
const access_providers_1 = require("./components/access-providers");
const migrate_connector_page_component_1 = require("../connectors-migrate/containers/migrate-connector-page/migrate-connector-page.component");
angular
    .module('services.connectors', [])
    .controller('ServicesConnectorsController', [
    '$stateParams',
    '$scope',
    function ($stateParams, $scope) {
        $scope.stateParams = $stateParams;
    }
])
    .directive('mcConnectorsPage', static_1.downgradeComponent({
    component: connectors_page_component_1.ConnectorsPageComponent
}))
    .directive('mcCreateConnectorPage', static_1.downgradeComponent({
    component: create_connector_page_component_1.CreateConnectorPageComponent
}))
    .directive('mcAccessProviderMsCallback', static_1.downgradeComponent({
    component: access_providers_1.AccessProviderMsCallbackComponent
}))
    .directive('mcMigrateConnectorPage', static_1.downgradeComponent({
    component: migrate_connector_page_component_1.MigrateConnectorPageComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider
            .state('services-connectors-page', {
            url: '^/services/connectors',
            data: {
                capabilities: 'Permission.CONNECTORS_READ || Permission.SERVER_CONNECTIONS_READ',
                isFixedLayout: false,
                checkProgress: false,
                tabReload: true,
                tabId: 'services-connector-tab',
                tabTitle: '$I18N_CONNECTORS.TAB_TITLE'
            },
            views: {
                main: {
                    template: '<mc-connectors-page></mc-connectors-page>',
                    controller: 'ServicesConnectorsController'
                },
                serverConnectors: {
                    templateUrl: 'services/services/server-connection/server-connection-list/server-connection-list.tpl.html'
                }
            },
            params: { tab: null }
        })
            .state('services-create-connector-page', {
            url: '^/services/connectors/create',
            data: {
                capabilities: 'Permission.CONNECTORS_EDIT || Permission.SERVER_CONNECTIONS_EDIT',
                tabId: 'services-connector-tab',
                tabTitle: '$I18N_CREATE_CONNECTOR_WIZARD.CREATE_TITLE',
                tabReload: true,
                breadcrumbs: [
                    '$I18N_MEGA_MENU_GROUP_SERVICES_LABEL',
                    '$I18N_MEGA_MENU_SERVICES_CONNECTORS_LABEL',
                    '$I18N_CREATE_CONNECTOR_WIZARD.CREATE_TITLE'
                ]
            },
            views: {
                main: {
                    template: '<mc-create-connector-page></mc-create-connector-page>',
                    controller: 'ServicesConnectorsController'
                }
            }
        })
            .state('services-edit-connector-page', {
            url: '^/services/connectors/edit',
            data: {
                capabilities: 'Permission.CONNECTORS_EDIT || Permission.SERVER_CONNECTIONS_EDIT',
                tabId: 'services-connector-tab',
                tabTitle: '$I18N_CREATE_CONNECTOR_WIZARD.EDIT_TITLE',
                tabReload: true,
                breadcrumbs: [
                    '$I18N_MEGA_MENU_GROUP_SERVICES_LABEL',
                    '$I18N_MEGA_MENU_SERVICES_CONNECTORS_LABEL',
                    '$I18N_CREATE_CONNECTOR_WIZARD.EDIT_TITLE'
                ]
            },
            views: {
                main: {
                    template: '<mc-create-connector-page [edit]="true"></mc-create-connector-page>',
                    controller: 'ServicesConnectorsController'
                }
            },
            params: {
                id: null,
                provider: null,
                product: null,
                name: null,
                description: null
            }
        })
            .state('services-ms-consent', {
            data: {
                tabId: 'services-connector-ms-callback-tab',
                tabTitle: '$I18N_CREATE_CONNECTOR_WIZARD.CREATE_TITLE',
                breadcrumbs: [
                    '$I18N_MEGA_MENU_GROUP_SERVICES_LABEL',
                    '$I18N_MEGA_MENU_SERVICES_CONNECTORS_LABEL',
                    '$I18N_CREATE_CONNECTOR_WIZARD.CREATE_TITLE'
                ]
            },
            views: {
                main: {
                    template: '<mc-access-provider-ms-callback></mc-access-provider-ms-callback>',
                    controller: 'ServicesConnectorsController'
                }
            }
        })
            .state('services-migrate-connector-page', {
            url: '^/services/connectors',
            data: {
                tabId: 'services-connector-tab',
                tabTitle: '$I18N_CONNECTORS_MIGRATE.MIGRATE',
                tabReload: true,
                breadcrumbs: [
                    '$I18N_MEGA_MENU_GROUP_SERVICES_LABEL',
                    '$I18N_MEGA_MENU_SERVICES_CONNECTORS_LABEL',
                    '$I18N_CONNECTORS_MIGRATE.MIGRATE'
                ]
            },
            views: {
                main: {
                    template: '<mc-migrate-connector-page></mc-migrate-connector-page>',
                    controller: 'ServicesConnectorsController'
                }
            },
            params: {
                externalConnectionId: null,
                existingConnector: null,
                name: null,
                description: null
            }
        });
    }
]);
