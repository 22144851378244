"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LexiconTabComponent = void 0;
const core_1 = require("@angular/core");
const table_store_1 = require("@mimecast-ui/table-store");
const lexiconListActions = require("../../../../actions/lexicon.list.actions");
const lexiconActions = require("../../../../actions/lexicon.actions");
const lexicon_service_1 = require("../../services/lexicon.service");
const lexicon_tab_static_values_1 = require("./lexicon-tab.static-values");
const delete_confirmation_modal_component_1 = require("../../../../../supervision/components/delete-confirmation-modal/delete-confirmation-modal.component");
const rule_usage_notification_modal_component_1 = require("../../../../components/rule-usage-notification-modal/rule-usage-notification-modal.component");
const selectors = require("./../../../../../supervision/reducers/index");
const operators_1 = require("rxjs/operators");
const rxjs_1 = require("rxjs");
const lodash_1 = require("lodash");
const dashboard_settings_1 = require("./../../../../../supervision/model/supervision/dashboard/settings/dashboard-settings");
class LexiconTabComponent extends table_store_1.TableBaseComponent {
    constructor(store, modalService) {
        super(store, lexicon_service_1.LexiconService.SUPERVISION_LEXICON_LIST);
        this.store = store;
        this.modalService = modalService;
        this.sections = [];
        this.columns = lexicon_tab_static_values_1.defaultTableColumns;
        this.destroy$ = new rxjs_1.Subject();
        this.deleteSubject$ = new rxjs_1.Subject();
        this.onClickView = new core_1.EventEmitter();
        this.onClickEdit = new core_1.EventEmitter();
        this.searchByFields = this.getSearchByFields();
        this.columns = lexicon_tab_static_values_1.tableColumnsWithLexiconSearchType;
        this.sections = lexicon_tab_static_values_1.tableFilterSections;
    }
    getSearchByFields() {
        const prefix = '$I18N_ARCHIVE_SUPERVISION_LIST_LEXICON_TAB.FILTERS.SEARCH_BY_FILTER_FIELD';
        const filterByOptions = ['all', 'createdBy', 'updatedBy', 'name'];
        return filterByOptions.map((filterByOption) => {
            return { value: filterByOption, label: [prefix, filterByOption.toUpperCase()].join('.') };
        });
    }
    openCreate() {
        this.store.dispatch(new lexiconListActions.OpenCreateNewLexiconAction());
    }
    dispatchFilterAction(filteringOption = this.currentFilters, pagination = this.currentPagination) {
        super.dispatchFilterAction(filteringOption, pagination);
    }
    openItem(item) {
        const selectedLexicon = item;
        this.store.dispatch(new lexiconActions.GetAction(selectedLexicon));
        this.dispatchSetSelectedRow(item);
    }
    editItem(item) {
        const selectedLexicon = item;
        this.store.dispatch(new lexiconListActions.OpenEditLexiconAction(selectedLexicon.id));
        this.dispatchSetSelectedRow(item);
    }
    deleteSelectedLexicon(selectedLexicon) {
        this.store.dispatch(new lexiconListActions.LoadLexiconDetailsAction(selectedLexicon.id));
        rxjs_1.combineLatest([
            this.store.select(selectors.getLexiconListDetails),
            this.store.select(selectors.isLoadingLexiconListDetails)
        ])
            .pipe(operators_1.takeUntil(this.deleteSubject$))
            .subscribe(([lexiconDetails, isLoadingLexiconListDetails]) => {
            if (!isLoadingLexiconListDetails) {
                if (lexiconDetails) {
                    if (lodash_1.isEmpty(lexiconDetails.associatedRules)) {
                        this.confirmDeletion(lexiconDetails);
                    }
                    else {
                        this.ruleUsageNotification(lexiconDetails.name, lexiconDetails.associatedRules);
                    }
                }
                this.deleteSubject$.next(true);
            }
        });
    }
    ruleUsageNotification(lexiconName, associatedRules) {
        this.modalService.open(rule_usage_notification_modal_component_1.RuleUsageNotificationModalComponent, {
            size: 'lg',
            hasBackdrop: true,
            disableClose: true,
            data: {
                entityName: lexiconName,
                associatedRules,
                entityDeletionType: dashboard_settings_1.DeletionType.LEXICON
            }
        });
    }
    confirmDeletion(selectedLexicon) {
        const modal = this.modalService.open(delete_confirmation_modal_component_1.DeleteConfirmationModalComponent, {
            size: 'lg',
            hasBackdrop: true,
            disableClose: true,
            data: {
                entityName: selectedLexicon.name,
                entityDeletionType: dashboard_settings_1.DeletionType.LEXICON
            }
        });
        modal.afterClose().subscribe(confirmed => {
            if (confirmed) {
                this.store.dispatch(new lexiconListActions.DeleteLexiconAction({ id: selectedLexicon.id }));
            }
        });
    }
    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
}
exports.LexiconTabComponent = LexiconTabComponent;
