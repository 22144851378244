'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
document.createElement('mc-search-box-extended');
angular.module('search-box-extended.directive', [])
    .directive('mcSearchBoxExtended', [function () {
        return {
            restrict: 'AE',
            templateUrl: 'components/search-box-extended/search-box-extended.tpl.html',
            scope: {
                placeholder: '@',
                searchFields: '=?',
                ngModel: '=',
                onClearSearch: '&',
                onUpdateSearch: '&',
                onUpdateSearchField: '&',
                isDisabled: '=?'
            }
        };
    }]);
