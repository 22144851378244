"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const moment = require("moment");
const legacy_page_component_1 = require("./container/legacy-page.component");
angular
    .module('legacy-pages', [])
    .directive('mcLegacyPage', static_1.downgradeComponent({
    component: legacy_page_component_1.LegacyPageComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider
            .state('legacy', {
            url: '/l/{uid}',
            data: {
                isFixedLayout: false,
                trackProgress: true,
                tabHide: false
            },
            views: {
                main: {
                    template: '<mc-legacy-page [tab]="tab"></mc-legacy-page>'
                }
            }
        })
            .state('legacy.dashboard_show', {
            url: '/{show}',
            containerState: '',
            breadcrumbs: [],
            parent: 'legacy'
        })
            .state('legacy.dashboard_past24hours', {
            parent: 'legacy',
            containerState: '',
            breadcrumbs: [],
            params: {
                fromDashboard: 'true',
                timeForPast24Hours: moment()
                    .subtract(1, 'days')
                    .format('YYYYMMDDHHmmssSSS')
            }
        })
            .state('legacy.policy_edit_past24hours', {
            parent: 'legacy',
            containerState: '',
            breadcrumbs: [],
            params: {
                auditType: 'policies',
                timeForPast24Hours: moment()
                    .subtract(1, 'days')
                    .format('YYYYMMDDHHmmssSSS')
            }
        })
            .state('legacy.urlprotect-managedurls', {
            parent: 'legacy',
            data: {
                label: 'Managed URLs',
                isMenuItem: false,
                containerState: 'url-protection',
                breadcrumbs: [
                    '$I18N_ADMINISTRATION_MENU_URL_PROTECTION_BREADCRUMBS_LABEL_02',
                    '$I18N_ADMINISTRATION_MENU_URL_PROTECTION_BREADCRUMBS_LABEL_03',
                    '$I18N_ADMINISTRATION_MENU_URL_PROTECTION_BREADCRUMBS_MANAGED_URLS'
                ]
            },
            params: {
                uid: 'targetedthreatprotection-urlprotect-managedurls'
            }
        })
            .state('legacy.urlprotect-urldecoder', {
            parent: 'legacy',
            data: {
                label: 'URL Decoder',
                isMenuItem: false,
                containerState: 'url-protection',
                breadcrumbs: [
                    '$I18N_ADMINISTRATION_MENU_URL_PROTECTION_BREADCRUMBS_LABEL_02',
                    '$I18N_ADMINISTRATION_MENU_URL_PROTECTION_BREADCRUMBS_LABEL_03',
                    '$I18N_ADMINISTRATION_MENU_URL_PROTECTION_BREADCRUMBS_URL_DECODER'
                ]
            },
            params: {
                uid: 'targetedthreatprotection-urlprotect-urldecoder'
            }
        })
            .state('legacy.urlprotect-checkurl', {
            parent: 'legacy',
            data: {
                label: 'Check URL',
                isMenuItem: false,
                containerState: 'url-protection',
                breadcrumbs: [
                    '$I18N_ADMINISTRATION_MENU_URL_PROTECTION_BREADCRUMBS_LABEL_02',
                    '$I18N_ADMINISTRATION_MENU_URL_PROTECTION_BREADCRUMBS_LABEL_03',
                    '$I18N_ADMINISTRATION_MENU_URL_PROTECTION_BREADCRUMBS_CHECK_URL'
                ]
            },
            params: {
                uid: 'targetedthreatprotection-urlprotect-checkurl'
            }
        })
            .state('legacy.attachprotect-view-trusted-senders', {
            parent: 'legacy',
            params: {
                uid: 'managesenders',
                lookupmode: 'true',
                view: 'trust'
            }
        })
            .state('legacy.dashboard-url-protection-policies', {
            parent: 'legacy',
            data: {
                label: 'Policies',
                isMenuItem: false,
                containerState: 'url-protection',
                breadcrumbs: [
                    '$I18N_ADMINISTRATION_MENU_URL_PROTECTION_BREADCRUMBS_LABEL_02',
                    '$I18N_ADMINISTRATION_MENU_URL_PROTECTION_BREADCRUMBS_LABEL_03',
                    '$I18N_ADMINISTRATION_MENU_URL_PROTECTION_BREADCRUMBS_POLICIES'
                ]
            },
            params: {
                uid: 'targetedthreatprotection-urlprotect-policies',
                policytype: '20800'
            }
        })
            .state('legacy.dashboard-url-protection-definitions', {
            parent: 'legacy',
            data: {
                label: 'Definitions',
                isMenuItem: false,
                containerState: 'url-protection',
                breadcrumbs: [
                    '$I18N_ADMINISTRATION_MENU_URL_PROTECTION_BREADCRUMBS_LABEL_02',
                    '$I18N_ADMINISTRATION_MENU_URL_PROTECTION_BREADCRUMBS_LABEL_03',
                    '$I18N_ADMINISTRATION_MENU_URL_PROTECTION_BREADCRUMBS_DEFINITIONS'
                ]
            },
            params: {
                uid: 'targetedthreatprotection-urlprotect-definition',
                policytype: '20800'
            }
        })
            .state('legacy.dashboard-url-protection-logs', {
            parent: 'legacy',
            data: {
                label: 'Logs',
                isMenuItem: false,
                containerState: 'url-protection',
                breadcrumbs: [
                    '$I18N_ADMINISTRATION_MENU_URL_PROTECTION_BREADCRUMBS_LABEL_02',
                    '$I18N_ADMINISTRATION_MENU_URL_PROTECTION_BREADCRUMBS_LABEL_03',
                    '$I18N_ADMINISTRATION_DASHBOARD_URL_PROTECTION_ACTIONS_LOGS'
                ]
            },
            params: {
                uid: 'targetedthreatprotection-urlprotect-monitoring'
            }
        })
            // "Monitoring > URL Protection Logs" page for ui-platform
            // https://mimecast.jira.com/browse/UIP-5121
            .state('legacy.dashboard-url-protection-logs-monitoring', {
            parent: 'legacy',
            data: {
                label: 'Logs',
                isMenuItem: false,
                containerState: 'url-protection',
                breadcrumbs: [
                    '$I18N_ADMINISTRATION_MENU_URL_PROTECTION_LOGS_LABEL_01',
                    '$I18N_ADMINISTRATION_MENU_URL_PROTECTION_LOGS_LABEL_02'
                ]
            },
            params: {
                uid: 'monitoring-targetedthreatprotection-urlprotect-monitoring'
            }
        })
            .state('legacy.dashboard-attached-protection-definitions', {
            parent: 'legacy',
            data: {
                label: 'Definitions',
                isMenuItem: false,
                containerState: 'url-protection',
                breadcrumbs: [
                    '$I18N_ADMINISTRATION_MENU_ATTACHMENT_PROTECTION_BREADCRUMBS_LABEL_01',
                    '$I18N_ADMINISTRATION_MENU_ATTACHMENT_PROTECTION',
                    '$I18N_ADMINISTRATION_MENU_ATTACHMENT_PROTECTION_BREADCRUMBS_DEFINITIONS'
                ]
            },
            params: {
                uid: 'targetedthreatprotection-attachmentprotect-definition',
                policytype: '20820'
            }
        })
            .state('legacy.dashboard-attached-protection-policies', {
            parent: 'legacy',
            data: {
                label: 'Policies',
                tabTitle: '$I18N_ADMINISTRATION_MENU_URL_PROTECTION_BREADCRUMBS_LABEL_03',
                isMenuItem: false,
                containerState: 'url-protection',
                breadcrumbs: [
                    '$I18N_ADMINISTRATION_MENU_ATTACHMENT_PROTECTION_BREADCRUMBS_LABEL_01',
                    '$I18N_ADMINISTRATION_MENU_ATTACHMENT_PROTECTION',
                    '$I18N_ADMINISTRATION_MENU_ATTACHMENT_PROTECTION_BREADCRUMBS_POLICIES'
                ]
            },
            params: {
                uid: 'targetedthreatprotection-attachmentprotect-policies',
                policytype: '20820'
            }
        })
            .state('legacy.faaroles', {
            parent: 'legacy',
            data: {
                label: 'Federated Access',
                tabTitle: '$I18N_ADMINISTRATION_MENU_ACCOUNT_ITEM_FEDERATED_ADMIN_LONG',
                isMenuItem: false,
                containerState: 'roles-list',
                breadcrumbs: [
                    '$I18N_ADMINISTRATION_MENU_ACCOUNT_ITEM_ROLES',
                    '$I18N_ADMINISTRATION_MENU_ACCOUNT_ITEM_FEDERATED_ADMIN_LONG'
                ]
            },
            params: {
                uid: 'faaroles'
            }
        });
    }
]);
