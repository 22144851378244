"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
class SearchComponent {
    constructor(fb) {
        this.fb = fb;
        this.search = new core_1.EventEmitter();
        this.form = fb.group({
            searchName: ['', [forms_1.Validators.minLength(150)]]
        });
    }
    onSubmit() {
        this.search.emit(this.form.value.searchName);
    }
    clearSearchNameAndSubmit() {
        this.form.setValue({ searchName: '' });
        this.onSubmit();
    }
}
exports.SearchComponent = SearchComponent;
