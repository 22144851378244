"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const static_1 = require("@angular/upgrade/static");
const list_component_1 = require("./list/list.component");
const detail_component_1 = require("./detail/detail.component");
angular
    .module('sync-recover.exchange.mailbox', [])
    .controller('ExchangeMailboxController', function () { })
    .directive('mcSyncRecoverExchangeMailboxList', static_1.downgradeComponent({
    component: list_component_1.ListComponent
}))
    .directive('mcExchangeMailboxDetail', static_1.downgradeComponent({
    component: detail_component_1.DetailComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider.state('sync-recover-exchange-mailbox-list', {
            url: '/sync-recover/exchange/mailboxes',
            data: {
                capabilities: 'Development.Only',
                isFixedLayout: false,
                checkProgress: false,
                tabTitle: '$I18N_BACKUP_RESTORE_MAILBOXES_TITLE',
                breadcrumbs: ['$I18N_BACKUP_RESTORE_MAILBOXES_TITLE']
            },
            views: {
                main: {
                    template: '<mc-sync-recover-exchange-mailbox-list></mc-sync-recover-exchange-mailbox-list>',
                    controller: 'ExchangeMailboxController'
                }
            }
        });
        $stateProvider.state('sync-recover-exchange-mailbox-detail', {
            url: '/sync-recover/exchange/mailbox/detail',
            data: {
                capabilities: 'Development.Only',
                params: { mailboxId: null, taskId: null, name: null },
                tabTitle: '$I18N_BACKUP_RESTORE_BREADCRUMBS_MAILBOX_DETAILS',
                breadcrumbs: ['$I18N_BACKUP_RESTORE_BREADCRUMBS_MAILBOX_DETAILS']
            },
            views: {
                main: {
                    template: '<mc-exchange-mailbox-detail></mc-exchange-mailbox-detail>',
                    controller: 'ExchangeMailboxController'
                }
            }
        });
    }
]);
