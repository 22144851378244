"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Attribute = void 0;
const editable_decorator_1 = require("../decorators/editable.decorator");
const required_decorator_1 = require("../decorators/required.decorator");
const min_length_decorator_1 = require("../decorators/min-length.decorator");
const max_length_decorator_1 = require("../decorators/max-length.decorator");
const dropdown_decorator_1 = require("../decorators/dropdown.decorator");
const toggle_decorator_1 = require("../decorators/toggle.decorator");
const pattern_decorator_1 = require("../decorators/pattern.decorator");
class Attribute {
    constructor(id, prompt, promptGroup, promptType, promptOrder, promptOptions, allowedInTableViews) {
        this.id = id;
        this.prompt = prompt;
        this.promptGroup = promptGroup;
        this.promptType = promptType;
        this.promptOrder = promptOrder;
        this.promptOptions = promptOptions;
        this.allowedInTableViews = allowedInTableViews;
    }
}
__decorate([
    editable_decorator_1.Editable(),
    required_decorator_1.Required(),
    min_length_decorator_1.MinLength(1),
    max_length_decorator_1.MaxLength(250)
], Attribute.prototype, "prompt", void 0);
__decorate([
    editable_decorator_1.Editable(),
    required_decorator_1.Required(),
    max_length_decorator_1.MaxLength(250)
], Attribute.prototype, "promptGroup", void 0);
__decorate([
    editable_decorator_1.Editable(),
    required_decorator_1.Required(),
    dropdown_decorator_1.IsDropdown()
], Attribute.prototype, "promptType", void 0);
__decorate([
    editable_decorator_1.Editable(),
    required_decorator_1.Required(),
    pattern_decorator_1.Pattern('^([0-9][0-9]{0,1}|99)$')
], Attribute.prototype, "promptOrder", void 0);
__decorate([
    max_length_decorator_1.MaxLength(1024),
    editable_decorator_1.Editable()
], Attribute.prototype, "promptOptions", void 0);
__decorate([
    editable_decorator_1.Editable(),
    toggle_decorator_1.IsToggle()
], Attribute.prototype, "allowedInTableViews", void 0);
exports.Attribute = Attribute;
