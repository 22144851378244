<div class="row">
  <div class="col-sm-6 recipient-address">
    <h3>
      {{ '$I18N_LOG_SIDEBAR.EXTERNAL_RECIPIENTS.TABLE.HEADINGS.RECIPIENT_ADDRESS' | translate }}
    </h3>
    <p>{{ flaggedRecipient?.email }}</p>
  </div>
  <div class="col-sm-6 recipient-status">
    <h3>
      {{ '$I18N_LOG_SIDEBAR.EXTERNAL_RECIPIENTS.TABLE.HEADINGS.STATUS' | translate }}
    </h3>
    <p>
      <mc-mep-log-status [status]="status"></mc-mep-log-status>
    </p>
  </div>
  <div class="col-sm-12 recipient-held-reason">
    <h3 *ngIf="status==='no_action'">
      {{ '$I18N_LOG_SIDEBAR.EXTERNAL_RECIPIENTS.TABLE.HEADINGS.REASON' | translate }}
    </h3>
    <h3 *ngIf="status!=='no_action'">
      {{ '$I18N_LOG_SIDEBAR.EXTERNAL_RECIPIENTS.TABLE.HEADINGS.HELD_REASON' | translate }}
    </h3>
    <ng-container *ngIf="flaggedRecipient?.similarContactAddress; else nonMisaddressedReason">
      <p data-test="heldReasonWithSimilarContact">
        {{ '$I18N_LOG_SIDEBAR.EXTERNAL_RECIPIENTS.TABLE.HELD_REASONS.SIMILAR_CONTACT' | translate: similarContactDetails }}
      </p>
    </ng-container>
    <ng-template #nonMisaddressedReason>
      <p data-test="heldReasonWithOthersMisAddressed">
        {{ '$I18N_LOG_SIDEBAR.EXTERNAL_RECIPIENTS.TABLE.HELD_REASONS.OTHER_POTENTIALLY_MISADDRESSED_RECIPIENTS' | translate }}
      </p>
    </ng-template>
  </div>
</div>
