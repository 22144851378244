"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SetQueueDetailsPaginationInfoAction = exports.UpdateQueueDetailsPaginationInfoAction = exports.CleanQueueDetailsAction = exports.GetQueueDetailsActionSuccess = exports.GetQueueDetailsActionFail = exports.GetQueueDetailsAction = exports.QueueDetailsActions = void 0;
var QueueDetailsActions;
(function (QueueDetailsActions) {
    QueueDetailsActions["UPDATE_QUEUE_DETAILS_PAGINATION_INFO"] = "[SupervisionQueueDetails] Update Queue Details Pagination Info";
    QueueDetailsActions["SET_QUEUE_DETAILS_PAGINATION_INFO"] = "[SupervisionQueueDetails] Set Queue Details Pagination Info";
    QueueDetailsActions["GET_QUEUE_DETAILS"] = "[SupervisionQueueDetails] Get Queue Details";
    QueueDetailsActions["GET_QUEUE_DETAILS_SUCCESS"] = "[SupervisionQueueDetails] Get Queue Details Success";
    QueueDetailsActions["GET_QUEUE_DETAILS_FAIL"] = "[SupervisionQueueDetails] Get Queue Details Fail";
    QueueDetailsActions["CLEAN_QUEUE_DETAILS"] = "[SupervisionQueueDetails] Clean Queue Details";
})(QueueDetailsActions = exports.QueueDetailsActions || (exports.QueueDetailsActions = {}));
class GetQueueDetailsAction {
    constructor(queue) {
        this.queue = queue;
        this.type = QueueDetailsActions.GET_QUEUE_DETAILS;
    }
}
exports.GetQueueDetailsAction = GetQueueDetailsAction;
class GetQueueDetailsActionFail {
    constructor(failure) {
        this.failure = failure;
        this.type = QueueDetailsActions.GET_QUEUE_DETAILS_FAIL;
    }
}
exports.GetQueueDetailsActionFail = GetQueueDetailsActionFail;
class GetQueueDetailsActionSuccess {
    constructor(queueDetails) {
        this.queueDetails = queueDetails;
        this.type = QueueDetailsActions.GET_QUEUE_DETAILS_SUCCESS;
    }
}
exports.GetQueueDetailsActionSuccess = GetQueueDetailsActionSuccess;
class CleanQueueDetailsAction {
    constructor() {
        this.type = QueueDetailsActions.CLEAN_QUEUE_DETAILS;
    }
}
exports.CleanQueueDetailsAction = CleanQueueDetailsAction;
class UpdateQueueDetailsPaginationInfoAction {
    constructor(currentQueue, listQueues) {
        this.currentQueue = currentQueue;
        this.listQueues = listQueues;
        this.type = QueueDetailsActions.UPDATE_QUEUE_DETAILS_PAGINATION_INFO;
    }
}
exports.UpdateQueueDetailsPaginationInfoAction = UpdateQueueDetailsPaginationInfoAction;
class SetQueueDetailsPaginationInfoAction {
    constructor(previousQueue, currentQueue, nextQueue, currentQueueIndex, totalNumberQueues) {
        this.previousQueue = previousQueue;
        this.currentQueue = currentQueue;
        this.nextQueue = nextQueue;
        this.currentQueueIndex = currentQueueIndex;
        this.totalNumberQueues = totalNumberQueues;
        this.type = QueueDetailsActions.SET_QUEUE_DETAILS_PAGINATION_INFO;
    }
}
exports.SetQueueDetailsPaginationInfoAction = SetQueueDetailsPaginationInfoAction;
