"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageDeliveryEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const actions = require("../actions");
const reducers = require("../reducers");
const api_1 = require("app-new/components/export/api");
const commonActions = require("../../common/actions");
const notification_actions_1 = require("app-new/components/notification/actions/notification.actions");
const message_delivery_notifications_1 = require("./message-delivery.notifications");
class MessageDeliveryEffects {
    constructor(actions$, service, store$) {
        this.actions$ = actions$;
        this.service = service;
        this.store$ = store$;
        this.loadItems$ = this.actions$.pipe(effects_1.ofType(actions.MESSAGE_DELIVERY_REQUEST, actions.MESSAGE_DELIVERY_PAGINATION, actions.MESSAGE_DELIVERY_REFRESH, ...Object.keys(message_delivery_notifications_1.notifications)), operators_1.withLatestFrom(this.store$.select(reducers.getMessageDeliverySearch), this.store$.select(reducers.getMessageDeliveryRequestPage)), operators_1.switchMap(([, request, pagination]) => {
            return this.service.getItems(request, pagination).pipe(operators_1.map(response => new actions.MessageDeliverySuccessAction(response)), operators_1.catchError(() => rxjs_1.of(new actions.MessageDeliveryFailAction())));
        }));
        this.displayRow$ = this.actions$.pipe(effects_1.ofType(actions.MESSAGE_DELIVERY_SELECT_ROW), operators_1.withLatestFrom(this.store$.select(reducers.getMessageDeliveryItems)), operators_1.switchMap(([action, items]) => {
            if (action.payload) {
                return rxjs_1.of(new commonActions.ShowMessageInfoAction(action.payload, items, false));
            }
            return rxjs_1.EMPTY;
        }));
        this.rejectEmails$ = this.actions$.pipe(effects_1.ofType(actions.MESSAGE_DELIVERY_REJECT_EMAILS), operators_1.switchMap((action) => {
            return this.service.rejectEmails(action.payload).pipe(operators_1.map(() => {
                return new actions.MessageDeliveryRejectEmailsSuccessAction(action.payload);
            }), operators_1.catchError(() => rxjs_1.of(new actions.MessageDeliveryRejectEmailsFailAction(action.payload))));
        }));
        this.recalculateRoute$ = this.actions$.pipe(effects_1.ofType(actions.MESSAGE_RECALCULATE_ROUTE), operators_1.switchMap((action) => {
            return this.service.recalculateRoute(action.payload).pipe(operators_1.map(() => new actions.MessageRecalculateRouteSuccessAction(action.payload)), operators_1.catchError(() => rxjs_1.of(new actions.MessageRecalculateRouteFailAction(undefined))));
        }));
        this.retryEmails$ = this.actions$.pipe(effects_1.ofType(actions.MESSAGE_DELIVERY_RETRY_EMAILS), operators_1.switchMap((action) => {
            return this.service.retryEmails(action.payload).pipe(operators_1.map(() => new actions.MessageDeliveryRetryEmailsSuccessAction(action.payload)), operators_1.catchError(error => {
                return this.checkForEmailLockedError(action, error);
            }));
        }));
        this.earlyBounceEmails$ = this.actions$.pipe(effects_1.ofType(actions.MESSAGE_DELIVERY_EARLY_BOUNCE_EMAILS), operators_1.switchMap((action) => {
            return this.service.earlyBounceEmails(action.payload).pipe(operators_1.map(() => new actions.MessageDeliveryEarlyBounceEmailsSuccessAction(action.payload)), operators_1.catchError(() => rxjs_1.of(new actions.MessageDeliveryEarlyBounceEmailsFailAction(action.payload))));
        }));
        this.export$ = this.actions$.pipe(effects_1.ofType(actions.MESSAGE_DELIVERY_EXPORT), operators_1.withLatestFrom(this.store$.select(reducers.getMessageDeliverySearch)), operators_1.map(([action, search]) => {
            const apiSearch = this.service.transformToServerRequest(search);
            return new api_1.OpenSidePanelExportApiAction('/api/gateway/export-delivery-messages', action.payload, apiSearch, '$I18N_MESSAGE_CENTER_MESSAGE_DELIVERY_EXPORT');
        }));
        this.notifications$ = this.actions$.pipe(effects_1.ofType(...Object.keys(message_delivery_notifications_1.notifications)), operators_1.map((action) => {
            const notification = message_delivery_notifications_1.notifications[action.type];
            return new notification_actions_1.NotificationShowAction({
                config: { msg: notification.message },
                type: notification.type
            });
        }));
    }
    checkForEmailLockedError(action, error) {
        let isEmailLockedForDelivery = false;
        error.fail.every((item) => {
            if (item.errors[0].code === 'err_track_and_trace_queue_item_locked') {
                isEmailLockedForDelivery = true;
                return false;
            }
            return true;
        });
        if (isEmailLockedForDelivery) {
            return rxjs_1.of(new actions.MessageDeliveryRetryLockedEmailsFailAction(action.payload));
        }
        return rxjs_1.of(new actions.MessageDeliveryRetryEmailsFailAction(action.payload));
    }
}
__decorate([
    effects_1.Effect()
], MessageDeliveryEffects.prototype, "loadItems$", void 0);
__decorate([
    effects_1.Effect()
], MessageDeliveryEffects.prototype, "displayRow$", void 0);
__decorate([
    effects_1.Effect()
], MessageDeliveryEffects.prototype, "rejectEmails$", void 0);
__decorate([
    effects_1.Effect()
], MessageDeliveryEffects.prototype, "recalculateRoute$", void 0);
__decorate([
    effects_1.Effect()
], MessageDeliveryEffects.prototype, "retryEmails$", void 0);
__decorate([
    effects_1.Effect()
], MessageDeliveryEffects.prototype, "earlyBounceEmails$", void 0);
__decorate([
    effects_1.Effect()
], MessageDeliveryEffects.prototype, "export$", void 0);
__decorate([
    effects_1.Effect()
], MessageDeliveryEffects.prototype, "notifications$", void 0);
exports.MessageDeliveryEffects = MessageDeliveryEffects;
