"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KBUrls = void 0;
/* eslint-disable */
class KBUrls {
}
exports.KBUrls = KBUrls;
KBUrls.ADMIN_CONSOLE = 'https://community.mimecast.com/welcome';
KBUrls.ADMIN_CONSOLE_IV = 'https://community.mimecast.com/docs/DOC-2094';
KBUrls.ADMIN_CONSOLE_IV_PROGRESS = 'https://community.mimecast.com/community/service-updates/blog/2016/04/20/administration-console-4-progress';
KBUrls.MPP = 'https://community.mimecast.com/docs/DOC-1243';
KBUrls.MPP_TROUBLESHOOTING = 'https://community.mimecast.com/docs/DOC-1773';
KBUrls.LOGIN_REVIEW_GUIDANCE = 'https://community.mimecast.com/docs/DOC-1140';
KBUrls.ADMIN_CONSOLE_COMPATIBILITY_MODE = 'https://community.mimecast.com/docs/DOC-1539';
KBUrls.MAXIMUM_RETENTION_USAGE = 'https://community.mimecast.com/docs/DOC-1286';
KBUrls.REPORTING_OVERVIEW = 'https://community.mimecast.com/docs/DOC-1277';
KBUrls.REPORTING_DETAILED_ACTIVITY = 'https://community.mimecast.com/docs/DOC-1280';
KBUrls.REPORTING_PDF_REPORTS = 'https://community.mimecast.com/docs/DOC-1278';
KBUrls.REPORTING_CSV_DATA = 'https://community.mimecast.com/docs/DOC-1279';
KBUrls.MONITORING_ATTACHMENTS = 'https://community.mimecast.com/docs/DOC-1308';
KBUrls.MONITORING_ATTACHMENTS_LINKED = 'https://community.mimecast.com/docs/DOC-1380';
KBUrls.MONITORING_ATTACHMENTS_STRIPPED = 'https://community.mimecast.com/docs/DOC-1376';
KBUrls.MONITORING_REJECTIONS = 'https://community.mimecast.com/docs/DOC-1344';
KBUrls.MONITORING_REJECTIONS_DESC = 'https://community.mimecast.com/docs/DOC-1369';
KBUrls.MONITORING_CONNECTIONS = 'https://community.mimecast.com/docs/DOC-1309';
KBUrls.MONITORING_BOUNCES = 'https://community.mimecast.com/docs/DOC-1348';
KBUrls.MONITORING_PROCESSING = 'https://community.mimecast.com/docs/DOC-1343';
KBUrls.MONITORING_DELIVERY = 'https://community.mimecast.com/docs/DOC-1349';
KBUrls.MONITORING_HELD = 'https://community.mimecast.com/docs/DOC-1341';
KBUrls.MONITORING_HELD_SUMMARY = 'https://community.mimecast.com/docs/DOC-1361';
KBUrls.MONITORING_SYSTEM = 'https://community.mimecast.com/docs/DOC-1363';
KBUrls.MONITORING_URL_CLICK_LOGS = '';
KBUrls.MESSAGE_CENTER_BOUNCES = 'https://community.mimecast.com/docs/DOC-1348';
KBUrls.MESSAGE_CENTER_DEFERRED = 'https://community.mimecast.com/docs/DOC-3342';
KBUrls.MESSAGE_CENTER_REJECTED = 'https://community.mimecast.com/docs/DOC-1344';
KBUrls.STATIONERY_LAYOUTS = 'https://community.mimecast.com/docs/DOC-1436';
KBUrls.STATIONERY_TEMPLATES = 'https://community.mimecast.com/docs/DOC-1215';
KBUrls.STATIONERY_IMAGES = 'https://community.mimecast.com/docs/DOC-1071';
KBUrls.STATIONERY_ACTIONS = 'https://community.mimecast.com/docs/DOC-1064';
KBUrls.STATIONERY_MICRO_SITES = 'https://community.mimecast.com/docs/DOC-1076';
KBUrls.STATIONERY_BROADCASTS = '';
KBUrls.STATIONERY_PERFORMANCE = 'https://community.mimecast.com/docs/DOC-1085';
KBUrls.STATIONERY_REPORTS = 'https://community.mimecast.com/docs/DOC-1085';
KBUrls.STATIONERY_BRANDING = 'https://community.mimecast.com/docs/DOC-1203';
KBUrls.DIRECTORIES_INTERNAL = 'https://community.mimecast.com/docs/DOC-1305';
KBUrls.DIRECTORIES_EXTERNAL = 'https://community.mimecast.com/docs/DOC-1304';
KBUrls.DIRECTORIES_INTERNAL_DELEGATE = 'https://community.mimecast.com/docs/DOC-1481';
KBUrls.DOMAIN_INBOUND_CHECKS = 'https://community.mimecast.com/docs/DOC-1194';
KBUrls.DIRECTORIES_ATTRIBUTES = 'https://community.mimecast.com/docs/DOC-1478';
KBUrls.DIRECTORIES_IMPORTS = 'https://community.mimecast.com/docs/DOC-1303';
KBUrls.DIRECTORIES_PROFILE = 'https://community.mimecast.com/docs/DOC-1289';
KBUrls.DIRECTORIES_AD_GROUPS = 'https://community.mimecast.com/docs/DOC-1288';
KBUrls.DIRECTORIES_CREATE_DNS_RECORDS = 'https://community.mimecast.com/docs/DOC-1306';
KBUrls.ARCHIVE_SEARCH = 'https://community.mimecast.com/docs/DOC-1296';
KBUrls.ARCHIVE_SAVED_SEARCHES = 'https://community.mimecast.com/docs/DOC-1342';
KBUrls.ARCHIVE_SEARCH_LOGS = 'https://community.mimecast.com/docs/DOC-1296';
KBUrls.ARCHIVE_VIEW_LOGS = 'https://community.mimecast.com/docs/DOC-1290';
KBUrls.ARCHIVE_EXPORTS = 'https://community.mimecast.com/docs/DOC-1365';
//MAWC-6459: update the KB link for review streams to live version instead of beta version
KBUrls.MANAGE_REVIEW_STREAMS = 'https://community.mimecast.com/docs/DOC-2589';
KBUrls.ARCHIVE_CASES = 'https://community.mimecast.com/docs/DOC-1368';
KBUrls.ARCHIVE_SMART_TAGS = 'https://community.mimecast.com/docs/DOC-1293';
KBUrls.ARCHIVE_HOLD = 'https://community.mimecast.com/docs/DOC-1350';
KBUrls.ARCHIVE_RETENTION = 'https://community.mimecast.com/docs/DOC-1300';
KBUrls.ARCHIVE_STORAGE_SEGMENTS = 'https://community.mimecast.com/docs/DOC-1291';
KBUrls.SERVICES_CONTINUITY = 'https://community.mimecast.com/docs/DOC-1262';
KBUrls.SERVICES_SMS_CONTINUITY = 'https://community.mimecast.com/docs/DOC-2085';
KBUrls.SERVICES_APPLICATIONS = 'https://community.mimecast.com/docs/DOC-1214';
KBUrls.SERVICES_APPLICATIONS_REGISTERED = 'https://community.mimecast.com/docs/DOC-1558';
//Need to be replace by CID LIK
KBUrls.SERVICES_APPLICATIONS_AUTHPROFILE = 'https://community.mimecast.com/docs/DOC-1176';
KBUrls.SERVICES_DIRECTORY_SYNC = 'https://community.mimecast.com/community/knowledge-base/directory-sync-email-domains';
KBUrls.SERVICES_DIRECTORY_SYNC_SSL_CERTIFICATES = 'https://community.mimecast.com/docs/DOC-1132';
KBUrls.SERVICES_DIRECTORY_LDAP_MSE_VERSION = 'https://community.mimecast.com/docs/DOC-1016';
KBUrls.SERVICES_JOURNAL = 'https://community.mimecast.com/docs/DOC-1354';
KBUrls.SERVICES_EXCHANGE = 'https://community.mimecast.com/community/knowledge-base/mimecast-synchronization-engine-user-services';
KBUrls.SERVICES_EXCHANGE_INGESTION = 'https://community.mimecast.com/docs/DOC-1506';
KBUrls.SERVICES_EXCHANGE_DOWNLOADS = 'https://community.mimecast.com/community/knowledge-base/mimecast-synchronization-engine-user-services';
KBUrls.SERVICES_EXCHANGE_RELEASE_NOTES = 'https://community.mimecast.com/community/service-updates';
KBUrls.SERVICES_FILE_ARCHIVE = 'https://community.mimecast.com/docs/DOC-1287';
KBUrls.SERVICES_FILE_ARCHIVE_DROPBOX = 'https://community.mimecast.com/docs/DOC-1195';
KBUrls.SERVICES_IM_ARCHIVE = 'https://community.mimecast.com/docs/DOC-1195';
KBUrls.SERVICES_LFS = 'https://community.mimecast.com/docs/DOC-1160';
KBUrls.SERVICES_IOWA = '';
KBUrls.MSE_INSTALLER_LINK = 'https://community.mimecast.com/community/knowledge-base/application-downloads';
KBUrls.GATEWAY_POLICIES = 'https://community.mimecast.com/docs/DOC-1339';
KBUrls.GATEWAY_POLICIES_ADDRESS_ALTER = 'https://community.mimecast.com/docs/DOC-1373';
KBUrls.GATEWAY_POLICIES_ATTACHMENT_SET = 'https://community.mimecast.com/docs/DOC-1381';
KBUrls.GATEWAY_POLICIES_AUTO_RESPONSE = 'https://community.mimecast.com/docs/DOC-1386';
KBUrls.GATEWAY_POLICIES_CSS_HTML = 'https://community.mimecast.com/docs/DOC-1228';
KBUrls.GATEWAY_POLICIES_CONTENT_DEFINITIONS = 'https://community.mimecast.com/docs/DOC-1392';
KBUrls.GATEWAY_POLICIES_DELIVERY_ROUTES = 'https://community.mimecast.com/docs/DOC-1397';
KBUrls.GATEWAY_POLICIES_DIGEST_SETS = 'https://community.mimecast.com/docs/DOC-1399';
KBUrls.GATEWAY_POLICIES_DNS_AUTH_INBOUND = 'https://community.mimecast.com/docs/DOC-1400';
KBUrls.GATEWAY_POLICIES_DNS_AUTH_OUTBOUND = 'https://community.mimecast.com/docs/DOC-1400';
KBUrls.GATEWAY_POLICIES_DNS = 'https://community.mimecast.com/docs/DOC-1151';
KBUrls.GATEWAY_POLICIES_DOCUMENT_SERVICES = 'https://community.mimecast.com/docs/DOC-1401';
KBUrls.GATEWAY_POLICIES_FORWARDING_ADDRESS = 'https://community.mimecast.com/docs/DOC-1416';
KBUrls.GATEWAY_POLICIES_IMPERSONATION_PROTECT = 'https://community.mimecast.com/docs/DOC-1908';
KBUrls.GATEWAY_POLICIES_NOTIFICATION_SETS = 'https://community.mimecast.com/docs/DOC-1425';
KBUrls.GATEWAY_POLICIES_REPUTATION_POLICY = 'https://community.mimecast.com/docs/DOC-1428';
KBUrls.GATEWAY_POLICIES_SECURE_DELIVERY = 'https://community.mimecast.com/docs/DOC-1429';
KBUrls.GATEWAY_POLICIES_SECURE_MESSAGING = 'https://community.mimecast.com/docs/DOC-2282';
KBUrls.GATEWAY_POLICIES_SPAM_SCANNING = 'https://community.mimecast.com/docs/DOC-1435';
KBUrls.GATEWAY_POLICIES_SUSPECTED_MALWARE = 'https://community.mimecast.com/docs/DOC-1438';
KBUrls.GATEWAY_ACCEPTED_MAILS = 'https://community.mimecast.com/docs/DOC-1299';
KBUrls.GATEWAY_MANAGED_SENDERS = 'https://community.mimecast.com/docs/DOC-1467';
KBUrls.GATEWAY_OUTBOUND = 'https://community.mimecast.com/docs/DOC-2116';
KBUrls.GATEWAY_TRACK_AND_TRACE = 'https://community.mimecast.com/docs/DOC-1466';
KBUrls.GATEWAY_NOTIFICATIONS_SECURE_MESSAGING = 'https://community.mimecast.com/docs/DOC-1186';
KBUrls.GATEWAY_POLICIES_INBOUND_LOCKOUT = 'https://community.mimecast.com/docs/DOC-1419';
KBUrls.ACCOUNT_DASHBOARD = 'https://community.mimecast.com/docs/DOC-1513';
KBUrls.ACCOUNT_ANNOUNCEMENTS = 'http://community.mimecast.com';
KBUrls.ACCOUNT_ROLES = 'https://community.mimecast.com/docs/DOC-1515';
KBUrls.ACCOUNT_LOGS = 'https://community.mimecast.com/docs/DOC-1514';
KBUrls.ACCOUNT_SETTINGS = 'http://kb.mimecast.com?cid=30';
KBUrls.LARGE_FILE_SEND = 'https://community.mimecast.com/docs/DOC-1160';
KBUrls.BLOCK_SENDER_POLICY_DELETE = 'https://community.mimecast.com/docs/DOC-1645';
KBUrls.TTP_DASHBOARD = 'https://community.mimecast.com/docs/DOC-1027';
KBUrls.TTP_AP_DASHBOARD = 'https://community.mimecast.com/docs/DOC-2202';
KBUrls.TTP_URL_POLICY_DEFINITIONS = 'https://community.mimecast.com/docs/DOC-1442';
KBUrls.TTP_AP_POLICY_DEFINITIONS = 'https://community.mimecast.com/docs/DOC-1440';
KBUrls.TTP_URL_POLICY = 'https://community.mimecast.com/docs/DOC-1442';
KBUrls.TTP_AP_POLICY = 'https://community.mimecast.com/docs/DOC-1440';
KBUrls.SIEVE_SUB_ADDRESS_POLICY = 'https://community.mimecast.com/docs/DOC-1431';
KBUrls.TTP_MANAGED_URLS = 'https://community.mimecast.com/docs/DOC-1029';
//MAWC-6474
KBUrls.SMS_DASHBOARD = 'https://community.mimecast.com/docs/DOC-2052';
KBUrls.LEGACY_UNSUPPORTED_MENU_REMOVAL_LINK = 'https://community.mimecast.com/community/service-updates/blog/2016/09/12/retiring-group-builder-and-duplicated-functionality-from-the-administration-console';
KBUrls.REPORTING_SPAM_MALWARE_PHISHING = 'https://community.mimecast.com/docs/DOC-1605';
KBUrls.API_2_0_PRODUCTS = 'https://developer.services.mimecast.com/apis';
