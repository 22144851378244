<mc-wizard-step-header
  [label]="'$I18N_SWG_CREATE_POLICY_BLOCKED_PAGE_TITLE'"
></mc-wizard-step-header>

<div class="panel-double-padding-bottom mc-block-allow-list-desc-container">
  {{ '$I18N_SWG_CREATE_POLICY_BLOCKED_PAGE_DESC' | translate }}
  <a class="btn-link" (click)="openBulkUploadDomainsAction()">
    {{ '$I18N_SWG_CREATE_POLICY_BLOCKED_PAGE_UPLOAD_A_CSV_FILE' | translate }}.</a
  >
  <p class="no-margin" *ngIf="hasTTP">
    {{ '$I18N_SWG_CREATE_POLICY_BLOCKED_PAGE_ENABLE_WEB_PROXY' | translate }}
  </p>

  <span>
    <span>{{ '$I18N_SWG_CREATE_POLICY_BLOCKED_PAGE_VISIT_PAGE_1' | translate }} </span>
    <a
      href="https://community.mimecast.com/docs/DOC-3187#jive_content_id_Configuring_a_URL_Filtering_Policy"
      target="_blank"
      class="btn-link"
      translate="$I18N_SWG_CREATE_POLICY_BLOCKED_PAGE_VISIT_PAGE_2"
      rel="noopener noreferrer"
    ></a>
    <span>{{ '$I18N_SWG_CREATE_POLICY_BLOCKED_PAGE_VISIT_PAGE_3' | translate }} </span>
  </span>
</div>
<div class="mc-domains-step-container">
  <form class="panel-padding-bottom" [formGroup]="domainsPage">
    <div class="form-group">
      <label class="radio-inline">
        <input
          type="radio"
          name="action"
          id="inlineRadio1"
          value="block"
          formControlName="action"
        />
        {{ '$I18N_SWG_CREATE_POLICY_BLOCKED_PAGE_BLOCK' | translate }}
      </label>
      <label class="radio-inline">
        <input
          type="radio"
          name="action"
          id="inlineRadio2"
          value="allow"
          formControlName="action"
        />
        {{ '$I18N_SWG_CREATE_POLICY_BLOCKED_PAGE_ALLOW' | translate }}
      </label>
    </div>
    <div class="form-group">
      <div class="col-sm-10 no-padding-left">
        <mc-text-field
          class="domain-text-field no-padding-left"
          errorPrefix="$I18N_SWG_CREATE_POLICY_DOMAIN_VALIDATION"
          type="url"
          formControlName="value"
          [setFocus]="true"
        >
        </mc-text-field>
      </div>
      <button
        type="submit"
        [disabled]="domainsPage.invalid || isUrlFieldEmpty() || domains.length >= maxRows"
        class="btn btn-default col-sm-2"
        (click)="addDomain()"
      >
        <span *ngIf="domainsPage.value.action === 'block'"
          >{{ '$I18N_SWG_CREATE_POLICY_BLOCKED_PAGE_BLOCK' | translate }}
        </span>
        <span *ngIf="domainsPage.value.action === 'allow'">{{
          '$I18N_SWG_CREATE_POLICY_BLOCKED_PAGE_ALLOW' | translate
        }}</span>
      </button>
    </div>
  </form>
</div>
<div class="mc-domains-step-container" *ngIf="domains.length > 0">
  <mc-table
    [isLoading]="false"
    [columns]="columns"
    [data]="domains"
    [translatePrefix]="'$I18N_SWG_POLICY_TABLE_TITLE'"
    class="full-width"
  >
    <mc-column-actions key="delete-column">
      <i
        *mcRowActions="let row"
        (click)="onDeleteRow(row)"
        (keyup.enter)="onDeleteRow(row)"
        (keyup.space)="onDeleteRow(row)"
        tabindex="0"
        role="button"
        class="far fa-trash-alt cursor-pointer"
      >
      </i>
    </mc-column-actions>
    <mc-column key="action">
      <mc-body-cell *mcBodyCellDef="let row">
        <span>
          {{ '$I18N_SWG_CREATE_POLICY_BLOCKED_PAGE_ACTION_' + row.action | uppercase | translate }}
        </span>
      </mc-body-cell>
    </mc-column>
  </mc-table>
</div>
