"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditDomainEffects = void 0;
const effects_1 = require("@ngrx/effects");
const table_store_1 = require("@mimecast-ui/table-store");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const addDomainsActions = require("../../actions/add-domains.actions");
const actions = require("../../actions/edit-domain.actions");
const externalDomainListActions = require("../../actions/external-domain-list.actions");
const TABLE_ID = 'monitoredExternalDomain';
class EditDomainEffects {
    constructor(actions$, notificationService, chameleonService, tableStore) {
        this.actions$ = actions$;
        this.notificationService = notificationService;
        this.chameleonService = chameleonService;
        this.tableStore = tableStore;
        this.deleteSingleDomain$ = this.actions$.pipe(effects_1.ofType(actions.DELETE_SINGLE_DOMAIN), operators_1.map((action) => action.domain), operators_1.switchMap((domain) => {
            return this.chameleonService.deleteCustomExternalDomain({ id: domain.id }).pipe(operators_1.mergeMap((deleteResponse) => {
                return [
                    deleteResponse.deleteSuccessIds
                        ? new externalDomainListActions.DeleteSuccess([domain])
                        : new externalDomainListActions.DeleteFail(),
                    new addDomainsActions.CloseAside()
                ];
            }));
        }));
        this.updateDomain$ = this.actions$.pipe(effects_1.ofType(actions.UPDATE_DOMAIN), operators_1.map((action) => action.updatedDomain), operators_1.switchMap((updatedDomain) => {
            return this.chameleonService.updateMonitoredDomain(updatedDomain).pipe(operators_1.map(() => new actions.UpdateDomainSuccess()), operators_1.catchError(() => rxjs_1.of(new actions.UpdateDomainFailure())));
        }));
        this.updateSuccess$ = this.actions$.pipe(effects_1.ofType(actions.UPDATE_DOMAIN_SUCCESS), operators_1.withLatestFrom(this.tableStore.select(table_store_1.tableReducer.getCurrentFilters(TABLE_ID)), this.tableStore.select(table_store_1.tableReducer.getCurrentPagination(TABLE_ID))), operators_1.mergeMap(([, currentFilters, currentPagination]) => {
            return [
                new addDomainsActions.CloseAside(),
                this.notificationService.newNotification({
                    type: 'success',
                    config: { msg: 'Domain Updated' }
                }),
                new table_store_1.LoadAction({
                    tableId: TABLE_ID,
                    pagination: { pageToken: '', pageSize: currentPagination.pageSize, pageNumber: 0 },
                    query: currentFilters
                })
            ];
        }));
        this.updateFail$ = this.actions$.pipe(effects_1.ofType(actions.UPDATE_DOMAIN_FAILURE), operators_1.mergeMap(() => {
            return [
                new addDomainsActions.CloseAside(),
                this.notificationService.newNotification({
                    type: 'error',
                    config: { msg: 'Could not update domain' }
                })
            ];
        }));
    }
}
__decorate([
    effects_1.Effect()
], EditDomainEffects.prototype, "deleteSingleDomain$", void 0);
__decorate([
    effects_1.Effect()
], EditDomainEffects.prototype, "updateDomain$", void 0);
__decorate([
    effects_1.Effect()
], EditDomainEffects.prototype, "updateSuccess$", void 0);
__decorate([
    effects_1.Effect()
], EditDomainEffects.prototype, "updateFail$", void 0);
exports.EditDomainEffects = EditDomainEffects;
