<div>
  <mc-provider-icon providerType="{{ providerType }}"></mc-provider-icon>
  <span class="mc-connector-description">{{ connectorData.connectorName }}</span>
  <span>
    <ng-container *ngIf="connectorData.status === reconnect; else connected">
      <ng-container *ngIf="canMigrate$ | async">
        <button class="btn btn-primary btn-xs" (click)="migrate()">{{ '$I18N_CONNECTOR.MIGRATE' | translate }}</button>
      </ng-container>
    </ng-container>
  </span>
</div>

<ng-template #connected>
  <span><i aria-hidden="true" class="fa fa-check-circle text-success"></i> {{'$I18N_CONNECTOR.COMPLETE' | translate}}</span>
</ng-template>
