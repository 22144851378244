"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CrowdstrikeActivityLogsTableComponent = void 0;
const core_1 = require("@angular/core");
const crowdstrike_activity_logs_table_static_values_1 = require("./crowdstrike-activity-logs-table.static-values");
const rxjs_1 = require("rxjs");
class CrowdstrikeActivityLogsTableComponent {
    constructor() {
        this.DESCRIPTION_PREFIX_KEY = '$I18N_CROWDSTRIKE_ACTIVITY_LOGS_TABLE';
        this.columns = crowdstrike_activity_logs_table_static_values_1.columns;
        this.columnsAlwaysVisible = crowdstrike_activity_logs_table_static_values_1.columnsAlwaysVisible;
        this.columnsStartHidden = crowdstrike_activity_logs_table_static_values_1.columnsStartHidden;
        this.destroy$ = new rxjs_1.Subject();
        this.onViewActivityLog = new core_1.EventEmitter();
    }
    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
    viewActivityLog(activityLog) {
        this.onViewActivityLog.emit(activityLog);
    }
}
exports.CrowdstrikeActivityLogsTableComponent = CrowdstrikeActivityLogsTableComponent;
