"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.exportFormats = void 0;
exports.exportFormats = [
    {
        type: 'png',
        label: 'Download PNG'
    },
    {
        type: 'pdf',
        label: 'Download PDF'
    },
    {
        type: 'svg',
        label: 'Download SVG'
    },
    {
        type: 'jpg',
        label: 'Download JPG'
    },
    {
        type: 'print',
        label: 'Print Chart'
    }
];
