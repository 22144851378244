<mc-table
  tableId="message-center/hold-queue-messages/release-logs-table"
  [isLoading]="isLoading"
  [highlightedRow]="selectedRow"
  [columns]="columns"
  [data]="rows"
  (rowClick)="clickRow($event)"
  translatePrefix="$I18N_MESSAGE_CENTER_RELEASE_LOGS_TABLE.TABLE"
>
  <button class="mc-table-actions btn btn-secondary mc-button-margin-right" (click)="exportLogs()">
    {{ '$I18N_MESSAGE_CENTER_RELEASE_LOGS_TABLE.ACTIONS.EXPORT' | translate }}
  </button>

  <mc-filters
    [externalTableSearchCount]="externalTableSearchCount"
    [metadata]="metadata"
    [showPagination]="true"
    (paginatorChange)="changePaginator($event)"
  >
    <mc-filter-column-select-and-search
      [sections]="searchSections"
      [placeholder]="
        '$I18N_MESSAGE_CENTER_RELEASE_LOGS_TABLE.TABLE.COLUMN_VALUE_SINGLE_SELECT_FILTER_FIELD.PLACEHOLDER'
          | translate
      "
      (filtersChange)="changeSearchFilter($event)"
    >
    </mc-filter-column-select-and-search>
    <div class="mc-release-logs-filters-right">
      <mc-filters-bundle-date-range
        [config]="dateRangePickerConfig"
        (filtersChange)="changeDateFilter($event)"
      >
      </mc-filters-bundle-date-range>
      <mc-filters-bundle-column-values
        [sections]="filterSections"
        (filtersChange)="changeColumnFilter($event)"
      >
      </mc-filters-bundle-column-values>
    </div>
  </mc-filters>

  <mc-empty-results
    keyTitle="$I18N_MESSAGE_CENTER_RELEASE_LOGS_TABLE.TABLE.EMPTY_RESULTS"
    iconClass="mc-icon-adcon-icon-stable-no-results-2"
  >
  </mc-empty-results>

  <mc-column-email key="operator" emailKey="emailAddress"></mc-column-email>
  <mc-column-email key="fromEnv" emailKey="emailAddress"></mc-column-email>
  <mc-column-email key="fromHdr" emailKey="emailAddress"></mc-column-email>
  <mc-column-email key="to" emailKey="emailAddress"></mc-column-email>
  <mc-column-size key="size"></mc-column-size>
  <mc-column-date key="released"></mc-column-date>
  <mc-column key="spamScore">
    <mc-body-cell *mcBodyCellDef="let row">{{row.spamScore >=0 ?  row.spamScore : '-' }}</mc-body-cell>
  </mc-column>
  <mc-column key="detectionLevel">
    <mc-body-cell *mcBodyCellDef="let row">{{row.detectionLevel  || '-' }}</mc-body-cell>
  </mc-column>

  <mc-column-actions
    key="right-column"
    mcShowColumnConfig
    [columnsAlwaysVisible]="columnsAlwaysVisible"
    [columnsToStartHidden]="columnsToStartHidden"
  >
    <mc-row-actions *mcRowActions="let row">
      <li
        mcRowAction="$I18N_MESSAGE_CENTER_RELEASE_LOGS_TABLE.ACTIONS.VIEW_DETAILS"
        (click)="clickRow(row)"
      ></li>
    </mc-row-actions>
  </mc-column-actions>
</mc-table>
