"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiIntegrationOktaComponent = void 0;
const reducers = require("../../../reducers");
const actions = require("../../../actions");
class ApiIntegrationOktaComponent {
    constructor(store) {
        this.store = store;
    }
    ngOnInit() {
        this.isBusy$ = this.store.select(reducers.isIntegrationOktaBusy);
        this.oktaIntegration$ = this.store.select(reducers.getIntegrationOktaIntegration);
        this.hasCreatedIntegration$ = this.store.select(reducers.hasIntegrationOktaCreatedIntegration);
        this.rotateApiToken$ = this.store.select(reducers.getRotateApiToken);
    }
    createOktaIntegration() {
        this.store.dispatch(new actions.CreateOktaIntegrationAction());
    }
    selectOktaCell(selectedOktaCell) {
        this.store.dispatch(new actions.SelectOktaCellAction(selectedOktaCell));
    }
    editOktaIntegrationDetails(details) {
        this.store.dispatch(new actions.EditOktaIntegrationDetailsAction(details));
    }
    switchOktaIntegrationStatus(status) {
        this.store.dispatch(new actions.SwitchOktaIntegrationStatusAction(status));
    }
    switchRotateToken(rotate) {
        this.store.dispatch(new actions.SwitchRotateApiTokenAction(rotate));
    }
    cancel() {
        this.store.dispatch(new actions.CancelOktaIntegrationWidgetAction());
    }
    addEmail(email) {
        this.store.dispatch(new actions.AddEmailOktaIntegrationAction(email));
    }
    deleteEmail(email) {
        this.store.dispatch(new actions.DeleteEmailOktaIntegrationAction(email));
    }
    updateOktaIntegration() {
        this.store.dispatch(new actions.UpdateOktaIntegrationAction());
    }
}
exports.ApiIntegrationOktaComponent = ApiIntegrationOktaComponent;
