"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const static_1 = require("@angular/upgrade/static");
const dashboard_component_1 = require("./pages/dashboard/dashboard.component");
const policy_list_component_1 = require("./pages/policy-list/policy-list.component");
const policy_wizard_component_1 = require("./pages/policy-wizard/policy-wizard.component");
const TAB_ID = 'safe-cloud-tab';
const TEXT_SAFE_CLOUD_TITLE = '$I18N_SAFE_CLOUD.TITLE';
const TEXT_SAFE_CLOUD_THREAT_EVENTS = '$I18N_SAFE_CLOUD.THREAT_EVENTS';
const TEXT_SAFE_CLOUD_DETAILS = '$I18N_SAFE_CLOUD.DETAILS';
const TEXT_SAFE_CLOUD_POLICIES = '$I18N_SAFE_CLOUD.POLICIES';
const TEXT_SAFE_CLOUD_OVERVIEW = '$I18N_SAFE_CLOUD.OVERVIEW';
const TEXT_SAFE_CLOUD_CREATE = '$I18N_SAFE_CLOUD.CREATE';
const TEXT_SAFE_CLOUD_EDIT = '$I18N_SAFE_CLOUD.EDIT';
const PERMISSION_DASHBOARD_READ = 'Permission.CLOUD_FILE_PROTECT_DASHBOARD_READ';
const PERMISSION_POLICIES_READ = 'Permission.CLOUD_FILE_PROTECT_POLICIES_READ';
const PERMISSION_POLICIES_EDIT = 'Permission.CLOUD_FILE_PROTECT_POLICIES_EDIT';
angular
    .module('safe-cloud', [])
    .controller('SafeCloudController', [
    '$stateParams',
    '$scope',
    function ($stateParams, $scope) {
        $scope.stateParams = $stateParams;
    }
])
    .directive('mcSafeCloudDashboard', static_1.downgradeComponent({
    component: dashboard_component_1.DashboardComponent
}))
    .directive('mcSafeCloudPolicyList', static_1.downgradeComponent({
    component: policy_list_component_1.PolicyListComponent
}))
    .directive('mcSafeCloudPolicyWizard', static_1.downgradeComponent({
    component: policy_wizard_component_1.PolicyWizardComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider
            .state('safe-cloud-dashboard-overview', {
            url: '/safe-cloud/dashboard/overview',
            data: {
                capabilities: PERMISSION_DASHBOARD_READ,
                isFixedLayout: false,
                checkProgress: false,
                tabId: TAB_ID,
                tabTitle: TEXT_SAFE_CLOUD_TITLE,
                breadcrumbs: [TEXT_SAFE_CLOUD_TITLE, TEXT_SAFE_CLOUD_OVERVIEW]
            },
            views: {
                main: {
                    template: '<mc-safe-cloud-dashboard [tab]="\'OVERVIEW\'"></mc-safe-cloud-dashboard>',
                    controller: 'SafeCloudController'
                }
            }
        })
            .state('safe-cloud-dashboard-threat-events', {
            url: '/safe-cloud/dashboard/threat-events',
            data: {
                capabilities: PERMISSION_DASHBOARD_READ,
                isFixedLayout: false,
                checkProgress: false,
                tabReload: true,
                tabId: TAB_ID,
                tabTitle: TEXT_SAFE_CLOUD_TITLE,
                breadcrumbs: [TEXT_SAFE_CLOUD_TITLE, TEXT_SAFE_CLOUD_THREAT_EVENTS]
            },
            views: {
                main: {
                    template: '<mc-safe-cloud-dashboard [tab]="\'THREAT_EVENTS\'"></mc-safe-cloud-dashboard>',
                    controller: 'SafeCloudController'
                }
            }
        })
            .state('safe-cloud-dashboard-threat-events-details', {
            url: '/safe-cloud/dashboard/threat-events/{id}',
            data: {
                capabilities: PERMISSION_DASHBOARD_READ,
                isFixedLayout: false,
                checkProgress: false,
                tabId: TAB_ID,
                tabTitle: TEXT_SAFE_CLOUD_TITLE,
                breadcrumbs: [
                    TEXT_SAFE_CLOUD_TITLE,
                    TEXT_SAFE_CLOUD_THREAT_EVENTS,
                    TEXT_SAFE_CLOUD_DETAILS
                ]
            },
            views: {
                main: {
                    template: '<mc-safe-cloud-dashboard [tab]="\'threatEvents\'" [openedEvent]="stateParams.id"></mc-safe-cloud-dashboard>',
                    controller: 'SafeCloudController'
                }
            }
        })
            .state('safe-cloud-policy-list', {
            url: '/safe-cloud/policies',
            data: {
                capabilities: PERMISSION_POLICIES_READ,
                tabId: TAB_ID,
                tabTitle: TEXT_SAFE_CLOUD_POLICIES,
                breadcrumbs: [TEXT_SAFE_CLOUD_TITLE, TEXT_SAFE_CLOUD_POLICIES]
            },
            views: {
                main: {
                    template: '<mc-safe-cloud-policy-list></mc-safe-cloud-policy-list>',
                    controller: 'SafeCloudController'
                }
            }
        })
            .state('safe-cloud-policy-details', {
            url: '/safe-cloud/policies/{id}',
            data: {
                capabilities: PERMISSION_POLICIES_READ,
                tabId: TAB_ID,
                tabTitle: TEXT_SAFE_CLOUD_POLICIES,
                breadcrumbs: [TEXT_SAFE_CLOUD_TITLE, TEXT_SAFE_CLOUD_POLICIES, TEXT_SAFE_CLOUD_DETAILS]
            },
            views: {
                main: {
                    template: '<mc-safe-cloud-policy-list [openedPolicy]="stateParams.id"></mc-safe-cloud-policy-list>',
                    controller: 'SafeCloudController'
                }
            }
        })
            .state('safe-cloud-policy-wizard-create', {
            url: '/safe-cloud/policies/create',
            data: {
                capabilities: PERMISSION_POLICIES_EDIT,
                tabId: TAB_ID,
                tabTitle: TEXT_SAFE_CLOUD_POLICIES,
                breadcrumbs: [TEXT_SAFE_CLOUD_TITLE, TEXT_SAFE_CLOUD_POLICIES, TEXT_SAFE_CLOUD_CREATE]
            },
            views: {
                main: {
                    template: '<mc-safe-cloud-policy-wizard></mc-safe-cloud-policy-wizard>',
                    controller: 'SafeCloudController'
                }
            }
        })
            .state('safe-cloud-policy-wizard-edit', {
            url: '/safe-cloud/policies/{id}/edit',
            data: {
                capabilities: PERMISSION_POLICIES_EDIT,
                tabId: TAB_ID,
                tabTitle: TEXT_SAFE_CLOUD_POLICIES,
                breadcrumbs: [TEXT_SAFE_CLOUD_TITLE, TEXT_SAFE_CLOUD_POLICIES, TEXT_SAFE_CLOUD_EDIT]
            },
            views: {
                main: {
                    template: '<mc-safe-cloud-policy-wizard [openedPolicy]="stateParams.id"></mc-safe-cloud-policy-wizard>',
                    controller: 'SafeCloudController'
                }
            }
        });
    }
]);
