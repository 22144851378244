"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateSafetyTipsComponent = void 0;
const actions = require("../../actions");
const reducers = require("../../reducers");
class TemplateSafetyTipsComponent {
    constructor(store) {
        this.store = store;
    }
    ngOnInit() {
        this.customSafetyTips$ = this.store.select(reducers.getTemplateSafetyTips(this.templateId));
        this.previewTip$ = this.store.select(reducers.getTemplatePreviewTip(this.templateId));
        this.mimecastSafetyTips$ = this.store.select(reducers.getMimecastDefaultSafetyTips);
    }
    createTip() {
        this.store.dispatch(new actions.safetyTips.CreateAction({ templateId: this.templateId }));
    }
    editTip(event) {
        this.store.dispatch(new actions.safetyTips.EditAction({ templateId: this.templateId, index: event.index }));
    }
    deleteTip(event) {
        this.store.dispatch(new actions.safetyTips.DeleteAction({ templateId: this.templateId, index: event.index }));
    }
    saveTip(event) {
        this.store.dispatch(new actions.safetyTips.SaveAction({
            templateId: this.templateId,
            tip: event.tip,
            index: event.index
        }));
    }
    cancelTip() {
        this.store.dispatch(new actions.safetyTips.PreviewAction({ templateId: this.templateId, tip: null }));
    }
    previewTip(event) {
        this.store.dispatch(new actions.safetyTips.PreviewAction({
            templateId: this.templateId,
            index: event.index,
            tip: event.tip,
            isDefaultTip: false
        }));
    }
    previewMimecastTip(event) {
        this.store.dispatch(new actions.safetyTips.PreviewAction({
            templateId: this.templateId,
            index: event.index,
            tip: event.tip,
            isDefaultTip: true
        }));
    }
    previewAll() {
        this.store.dispatch(new actions.safetyTips.PreviewAction({ templateId: this.templateId, tip: null }));
    }
}
exports.TemplateSafetyTipsComponent = TemplateSafetyTipsComponent;
