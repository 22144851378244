"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PolicyAction = exports.GroupType = exports.AdvancedFileTypeEnum = exports.SwgClpPolicyTargetType = exports.SwgClpPolicyScopeType = exports.SwgClpActionEnum = exports.SwgLogLevelEnum = exports.SwgUnScannableEnum = exports.SwgPolicyWizardFlowType = exports.SwgCategorySectionOptionsEnum = exports.SwgCategoryOptionsEnum = exports.SwgAffectedUsersEnum = exports.SwgPolicyType = void 0;
var SwgPolicyType;
(function (SwgPolicyType) {
    SwgPolicyType["WEB_BLOCK_ALLOW_LIST"] = "WEB_BLOCK_ALLOW_LIST";
    SwgPolicyType["WEB_CATEGORY_FILTERING"] = "WEB_CATEGORY_FILTERING";
    SwgPolicyType["WEB_ADVANCED_SECURITY"] = "WEB_ADVANCED_SECURITY";
    SwgPolicyType["WEB_TTP_POLICY"] = "WEB_TTP_POLICY";
    SwgPolicyType["WEB_LOGGING"] = "WEB_LOGGING";
    SwgPolicyType["WEB_APPLICATION_CONTROL"] = "WEB_APPLICATION_CONTROL";
    SwgPolicyType["BROWSER_ISOLATION"] = "BROWSER_ISOLATION";
    SwgPolicyType["CLOUD_PROTECT_POLICY"] = "CLOUD_PROTECT_POLICY";
})(SwgPolicyType = exports.SwgPolicyType || (exports.SwgPolicyType = {}));
var SwgAffectedUsersEnum;
(function (SwgAffectedUsersEnum) {
    SwgAffectedUsersEnum["EVERYONE"] = "everyone";
    SwgAffectedUsersEnum["MULTI"] = "multi";
    SwgAffectedUsersEnum["LOCATION"] = "location";
    SwgAffectedUsersEnum["USER"] = "user";
    SwgAffectedUsersEnum["GROUP"] = "group";
    SwgAffectedUsersEnum["CHANNEL"] = "clp_notifications_channel";
    SwgAffectedUsersEnum["CLP_USER"] = "clp_notifications_user";
    SwgAffectedUsersEnum["CLP_GROUP"] = "clp_notifications_group";
    SwgAffectedUsersEnum["WEB_DEVICE"] = "web_device";
    SwgAffectedUsersEnum["WEB_DEVICE_GROUP"] = "web_device_group";
})(SwgAffectedUsersEnum = exports.SwgAffectedUsersEnum || (exports.SwgAffectedUsersEnum = {}));
var SwgCategoryOptionsEnum;
(function (SwgCategoryOptionsEnum) {
    SwgCategoryOptionsEnum["ALLOW"] = "allow";
    SwgCategoryOptionsEnum["DISALLOW"] = "disallow";
})(SwgCategoryOptionsEnum = exports.SwgCategoryOptionsEnum || (exports.SwgCategoryOptionsEnum = {}));
var SwgCategorySectionOptionsEnum;
(function (SwgCategorySectionOptionsEnum) {
    SwgCategorySectionOptionsEnum["ALLOWALL"] = "allow-all";
    SwgCategorySectionOptionsEnum["DISALLOWALL"] = "disallow-all";
    SwgCategorySectionOptionsEnum["CUSTOM"] = "custom";
})(SwgCategorySectionOptionsEnum = exports.SwgCategorySectionOptionsEnum || (exports.SwgCategorySectionOptionsEnum = {}));
var SwgPolicyWizardFlowType;
(function (SwgPolicyWizardFlowType) {
    SwgPolicyWizardFlowType["CREATE"] = "create";
    SwgPolicyWizardFlowType["DUPLIATE"] = "duplicate";
    SwgPolicyWizardFlowType["EDIT"] = "edit";
})(SwgPolicyWizardFlowType = exports.SwgPolicyWizardFlowType || (exports.SwgPolicyWizardFlowType = {}));
var SwgUnScannableEnum;
(function (SwgUnScannableEnum) {
    SwgUnScannableEnum["ALLOW"] = "allow";
    SwgUnScannableEnum["BLOCK"] = "block";
})(SwgUnScannableEnum = exports.SwgUnScannableEnum || (exports.SwgUnScannableEnum = {}));
var SwgLogLevelEnum;
(function (SwgLogLevelEnum) {
    SwgLogLevelEnum["ALL"] = "all";
    SwgLogLevelEnum["NONE"] = "none";
    SwgLogLevelEnum["SECURITY"] = "security";
})(SwgLogLevelEnum = exports.SwgLogLevelEnum || (exports.SwgLogLevelEnum = {}));
var SwgClpActionEnum;
(function (SwgClpActionEnum) {
    SwgClpActionEnum["DONT_SCAN"] = "DONT_SCAN";
    SwgClpActionEnum["LOG_ONLY"] = "LOG_ONLY";
    SwgClpActionEnum["LOG_AND_REPLY"] = "LOG_AND_REPLY";
})(SwgClpActionEnum = exports.SwgClpActionEnum || (exports.SwgClpActionEnum = {}));
var SwgClpPolicyScopeType;
(function (SwgClpPolicyScopeType) {
    SwgClpPolicyScopeType["SPECIFIC_CONNECTOR"] = "SPECIFIC_CONNECTOR";
    SwgClpPolicyScopeType["SPECIFIC_CLOUD_SERVICE"] = "SPECIFIC_CLOUD_SERVICE";
})(SwgClpPolicyScopeType = exports.SwgClpPolicyScopeType || (exports.SwgClpPolicyScopeType = {}));
var SwgClpPolicyTargetType;
(function (SwgClpPolicyTargetType) {
    SwgClpPolicyTargetType["EVERYONE"] = "EVERYONE";
    SwgClpPolicyTargetType["INDIVIDUAL_EMAIL_ADDRESS"] = "INDIVIDUAL_EMAIL_ADDRESS";
    SwgClpPolicyTargetType["PROFILE_GROUP"] = "PROFILE_GROUP";
    SwgClpPolicyTargetType["ANY_SHARED_DRIVE"] = "ANY_SHARED_DRIVE";
    SwgClpPolicyTargetType["SHARED_DRIVE"] = "SHARED_DRIVE";
})(SwgClpPolicyTargetType = exports.SwgClpPolicyTargetType || (exports.SwgClpPolicyTargetType = {}));
var AdvancedFileTypeEnum;
(function (AdvancedFileTypeEnum) {
    AdvancedFileTypeEnum["ALLOW"] = "ALLOW";
    AdvancedFileTypeEnum["BLOCK"] = "BLOCK";
    AdvancedFileTypeEnum["SCAN"] = "SCAN";
})(AdvancedFileTypeEnum = exports.AdvancedFileTypeEnum || (exports.AdvancedFileTypeEnum = {}));
exports.GroupType = {
    ldap: 'ldap',
    cloud: 'cloud'
};
var PolicyAction;
(function (PolicyAction) {
    PolicyAction["EDIT"] = "edit";
    PolicyAction["DUPLICATE"] = "duplicate";
    PolicyAction["DELETE"] = "delete";
    PolicyAction["DETAILS"] = "details";
})(PolicyAction = exports.PolicyAction || (exports.PolicyAction = {}));
