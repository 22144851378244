"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CyberGraphPolicySummaryStepComponent = void 0;
const core_1 = require("@angular/core");
const reducers = require("../../../reducers");
const actions = require("../../../actions/cybergraph-policies-create.actions");
const statics = require("./cybergraph-policy-summary-step.component.static-values");
const cybergraph_policy_summary_step_component_helper_1 = require("./cybergraph-policy-summary-step.component.helper");
const cybergraph_policies_model_1 = require("../../../models/cybergraph-policies.model");
const formFieldNames = {
    policyStatusEnabled: 'policyStatusEnabled'
};
class CyberGraphPolicySummaryStepComponent {
    constructor(fb, store, translateService, userInfoApiService, featureFlagService, cdr) {
        this.fb = fb;
        this.store = store;
        this.translateService = translateService;
        this.userInfoApiService = userInfoApiService;
        this.featureFlagService = featureFlagService;
        this.cdr = cdr;
        this.statics = statics;
        this.showMoreLessTriggerValue = statics.SHOW_MORE_LESS_TRIGGER_VALUE;
        this.onEditStep = new core_1.EventEmitter();
        this.trackerEnabled = false;
        this.helper = new cybergraph_policy_summary_step_component_helper_1.CyberGraphPolicySummaryStepComponentHelper(this.translateService);
        this.cyberGraphPolicySummaryForm = this.fb.group({
            policyStatusEnabled: [true]
        });
        this.createObservables();
        this.startSubscriptions();
    }
    createObservables() {
        this.policyDetailsSetting$ = this.store.select(reducers.getDetailsStepData);
        this.policyAppliesToConfig$ = this.store.select(reducers.getFromToWhereAttributes);
        this.policyAppliesToSetting$ = this.store.select(reducers.getAppliesToStepData);
    }
    startSubscriptions() {
        this.userInfoApiService.getAccount().subscribe((account) => {
            this.featureFlagService.isReady().then(() => {
                const trackingFlagEnabled = this.featureFlagService.getBooleanFlag('rollout-cyg-21-image-tracker-ui', false);
                this.trackerEnabled = !account.cybergraphV2Enabled || trackingFlagEnabled;
                this.cdr.detectChanges();
            });
        });
        this.policyDetailsSettingSubscription = this.policyDetailsSetting$.subscribe(value => {
            this.detailsStep = Object.assign({}, value);
        });
        this.policyAppliesToConfigSubscription = this.policyAppliesToConfig$.subscribe(value => {
            this.appliesToAddressAttributesOptions = value;
        });
        this.policyAppliesToSettingSubscription = this.policyAppliesToSetting$.subscribe(value => {
            this.appliesToStep = value;
        });
        this.policyStatusValueChangeSubscription = this.cyberGraphPolicySummaryForm
            .get(formFieldNames.policyStatusEnabled)
            .valueChanges.subscribe(value => {
            this.store.dispatch(new actions.SetPolicyStatusEnabled(value));
        });
    }
    getEnabledDisabledLabel(value) {
        return this.translate(value
            ? statics.CyberGraphReportingEnumLabel.ENABLED
            : statics.CyberGraphReportingEnumLabel.DISABLED);
    }
    getCyberGraphReportingStatusEnumLabel(value) {
        return this.translate(value === cybergraph_policies_model_1.CyberGraphReportingStatusEnum.ON
            ? statics.CyberGraphReportingEnumLabel.ENABLED
            : statics.CyberGraphReportingEnumLabel.DISABLED);
    }
    getCyberGraphTrackersEnumLabel(value) {
        return this.translate(value === cybergraph_policies_model_1.CyberGraphTrackersEnum.ON
            ? statics.CyberGraphTrackerEnumLabel.ENABLED
            : statics.CyberGraphTrackerEnumLabel.DISABLED);
    }
    getCyberGraphDynamicBannerEnumLabel(value) {
        if (value === cybergraph_policies_model_1.CyberGraphDynamicBannersEnum.ON) {
            return this.translate(statics.CyberGraphDynamicBannerEnumLabel.ENABLED);
        }
        else if (value === cybergraph_policies_model_1.CyberGraphDynamicBannersEnum.LEARNING) {
            return this.translate(statics.CyberGraphDynamicBannerEnumLabel.LEARNING);
        }
        return this.translate(statics.CyberGraphDynamicBannerEnumLabel.DISABLED);
    }
    editStep(stepNumber) {
        this.onEditStep.emit(stepNumber);
    }
    translate(value) {
        return this.translateService.instant(value);
    }
    ngOnDestroy() {
        this.policyDetailsSettingSubscription.unsubscribe();
        this.policyAppliesToConfigSubscription.unsubscribe();
        this.policyAppliesToSettingSubscription.unsubscribe();
        this.policyStatusValueChangeSubscription.unsubscribe();
    }
}
exports.CyberGraphPolicySummaryStepComponent = CyberGraphPolicySummaryStepComponent;
