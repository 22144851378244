"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiIntegrationWildfireSummaryStepComponent = void 0;
const core_1 = require("@angular/core");
const operators_1 = require("rxjs/operators");
class ApiIntegrationWildfireSummaryStepComponent {
    constructor(fb, translateService) {
        this.fb = fb;
        this.translateService = translateService;
        this.onSwitchWildfireIntegrationStatus = new core_1.EventEmitter();
        this.form = this.fb.group({
            integrationStatus: false
        });
    }
    ngOnInit() {
        if (!!this.wildfireIntegration) {
            this.form.patchValue({
                integrationStatus: this.wildfireIntegration.enabled
            });
        }
        this.formSubscription = this.form.valueChanges.pipe(operators_1.debounceTime(200)).subscribe(() => {
            this.update();
        });
    }
    update() {
        this.onSwitchWildfireIntegrationStatus.emit(this.form.value.integrationStatus);
    }
    isValid() {
        return true;
    }
    wildfireGroupsList() {
        if (this.wildfireIntegration) {
            return Object.values(this.wildfireIntegration.notificationGroups);
        }
        return [];
    }
    wildfireEmailList() {
        if (this.wildfireIntegration) {
            return this.wildfireIntegration.notificationAddresses;
        }
        return [];
    }
    get summaryHeader() {
        if (this.wildfirePrerequisitesError) {
            return this.translateService.instant('$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.SUMMARY.HEADER_ERROR');
        }
        return this.translateService.instant('$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.SUMMARY.HEADER');
    }
    get errorContent() {
        if (this.wildfirePrerequisitesError.toLocaleLowerCase() ===
            'err_api_integration_wildfire_already_exists') {
            return this.translateService.instant('$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.SUMMARY.ALREADY_EXISTS');
        }
        return this.translateService.instant('$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.SUMMARY.PACKAGE_MISSING');
    }
    get remediationRequired() {
        if (this.wildfireIntegration && this.wildfireIntegration.remediationRequired) {
            return this.translateService.instant('$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.SUMMARY.STATIC.YES');
        }
        return this.translateService.instant('$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.SUMMARY.STATIC.NO');
    }
    get submitAttachments() {
        if (this.wildfireIntegration &&
            this.wildfireIntegration.indicators &&
            this.wildfireIntegration.indicators.ATTACHMENT &&
            this.wildfireIntegration.indicators.ATTACHMENT.enabled) {
            return this.translateService.instant('$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.SUMMARY.STATIC.YES');
        }
        return this.translateService.instant('$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.SUMMARY.STATIC.NO');
    }
    get submitMaliciousAttachments() {
        if (this.wildfireIntegration &&
            this.wildfireIntegration.indicators &&
            this.wildfireIntegration.indicators.MALICIOUS_ATTACHMENT &&
            this.wildfireIntegration.indicators.MALICIOUS_ATTACHMENT.enabled) {
            return this.translateService.instant('$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.SUMMARY.STATIC.YES');
        }
        return this.translateService.instant('$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.SUMMARY.STATIC.NO');
    }
}
exports.ApiIntegrationWildfireSummaryStepComponent = ApiIntegrationWildfireSummaryStepComponent;
