"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getIsApiProcessing = exports.getIsEditFlow = exports.getLocationItem = exports.reducer = exports.initialState = void 0;
const swg_location_sidebar_actions_1 = require("app-new/swg/locations/actions/swg-location-sidebar.actions");
const swg_location_crud_actions_1 = require("app-new/swg/locations/actions/swg-location-crud.actions");
exports.initialState = {
    location: {},
    isEditFlow: false,
    isApiProcessing: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case swg_location_sidebar_actions_1.SwgLocationSidebarTypes.SWG_LOCATION_EDIT_ADD_SIDBAR_OPEN:
            return Object.assign(Object.assign({}, state), { location: action.payload.swgLocation, isEditFlow: action.payload.editFlow });
        case swg_location_sidebar_actions_1.SwgLocationSidebarTypes.SWG_LOCATION_DELETE_SIDBAR_OPEN:
            return Object.assign(Object.assign({}, state), { location: action.payload });
        case swg_location_crud_actions_1.SwgLocationCrudTypes.SWG_LOCATION_DELETE:
        case swg_location_crud_actions_1.SwgLocationCrudTypes.SWG_LOCATION_UPDATE:
            return Object.assign(Object.assign({}, state), { isApiProcessing: true });
        case swg_location_crud_actions_1.SwgLocationCrudTypes.SWG_LOCATION_DELETE_SUCCESS:
        case swg_location_crud_actions_1.SwgLocationCrudTypes.SWG_LOCATION_UPDATE_SUCCESS:
            return Object.assign(Object.assign({}, state), { isApiProcessing: false });
        case swg_location_crud_actions_1.SwgLocationCrudTypes.SWG_LOCATION_DELETE_FAILURE:
        case swg_location_crud_actions_1.SwgLocationCrudTypes.SWG_LOCATION_UPDATE_FAILURE:
            return Object.assign(Object.assign({}, state), { isApiProcessing: false });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getLocationItem = (state) => state.location;
exports.getLocationItem = getLocationItem;
const getIsEditFlow = (state) => state.isEditFlow;
exports.getIsEditFlow = getIsEditFlow;
const getIsApiProcessing = (state) => state.isApiProcessing;
exports.getIsApiProcessing = getIsApiProcessing;
