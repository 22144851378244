<mc-layout-aside-extra-container
  keyTitle="{{ '$I18N_SERVICES_IM_SYNC_COMPONENTS_IM_SYNC_TABLE.VIEW_TASK.TITLE' | translate }}" showClose="true"
  [isLoading]="isLoading" (closeAside)="onClose()">

  <mc-extra-container *mcCapabilities="'Permission.INSTANT_MESSAGING_EDIT'" >
    <div class="mc-container-buttons-area">
      <button [disabled]="isTaskLegacy(imTask?.taskType)" (click)="updateImSyncTask()" class="btn btn-primary">
        {{ '$I18N_ARCHIVE_SUPERVISION.LEXICON.CONTROLS.EDIT' | translate }}
      </button>
    </div>
  </mc-extra-container>

  <mc-body-container>
    <mc-collapsable-panel
      header="{{ '$I18N_SERVICES_IM_SYNC_COMPONENTS_IM_SYNC_TABLE.VIEW_TASK.SUMMARY' | translate }}"
      [isExpanded]="true">
      <section class="mc-detail-section">
        <div class="panel-body no-padding-top">
          <div class="mc-detailed-list">
            <div class="row mc-detailed-list-row">
              <div class="col-xs-3 mc-detailed-list-label">
                {{ '$I18N_SERVICES_IM_SYNC_COMPONENTS_IM_SYNC_TABLE.VIEW_TASK.NAME' | translate }}
              </div>
              <div class="mc-detailed-list-column">{{ imTask?.name }}</div>
            </div>

            <div class="row mc-detailed-list-row">
              <div class="col-xs-3 mc-detailed-list-label">
                {{ '$I18N_SERVICES_IM_SYNC_COMPONENTS_IM_SYNC_TABLE.VIEW_TASK.DESCRIPTION' | translate }}
              </div>
              <div class="mc-detailed-list-column">{{ imTask?.description }}</div>
            </div>

            <div class="row mc-detailed-list-row">
              <div class="col-xs-3 mc-detailed-list-label">
                {{ '$I18N_SERVICES_IM_SYNC_COMPONENTS_IM_SYNC_TABLE.VIEW_TASK.SOURCE.LABEL' | translate }}
              </div>
              <div class="mc-detailed-list-column">
                {{ '$I18N_SERVICES_IM_SYNC_COMPONENTS_IM_SYNC_TABLE.VIEW_TASK.SOURCE.' +  imTask?.source | uppercase | translate }}
              </div>
            </div>

            <div class="row mc-detailed-list-row">
              <div class="col-xs-3 mc-detailed-list-label">
                {{ '$I18N_SERVICES_IM_SYNC_COMPONENTS_IM_SYNC_TABLE.VIEW_TASK.CREATED_BY' | translate }}
              </div>
              <div class="mc-detailed-list-column">{{ imTask?.createdBy }}</div>
            </div>

            <div class="row mc-detailed-list-row">
              <div class="col-xs-3 mc-detailed-list-label">
                {{ '$I18N_SERVICES_IM_SYNC_COMPONENTS_IM_SYNC_TABLE.VIEW_TASK.CREATED' | translate }}
              </div>
              <div class="mc-detailed-list-column">{{ imTask?.created  | mcDate }}</div>
            </div>

            <div *ngIf="connectionDetails" data-test="connection-details" class="row mc-detailed-list-row">
              <div class="col-xs-3 mc-detailed-list-label">
                {{ '$I18N_SERVICES_IM_SYNC_COMPONENTS_IM_SYNC_TABLE.VIEW_TASK.APPLICATION_ID' | translate }}
              </div>
              <div class="mc-detailed-list-column">{{ connectionDetails?.msTeamsWTO.applicationId }}</div>
            </div>

            <div class="row mc-detailed-list-row">
              <div class="col-xs-3 mc-detailed-list-label">
                {{ '$I18N_SERVICES_IM_SYNC_COMPONENTS_IM_SYNC_TABLE.VIEW_TASK.TASK_TYPE.LABEL' | translate }}
              </div>
              <div data-test="task-type" class="mc-detailed-list-column">
                {{ '$I18N_SERVICES_IM_SYNC_COMPONENTS_IM_SYNC_TABLE.VIEW_TASK.TASK_TYPE.' +  imTask?.taskType | uppercase | translate }}
              </div>
            </div>

            <div class="row mc-detailed-list-row">
              <div class="col-xs-3 mc-detailed-list-label">
                {{ '$I18N_SERVICES_IM_SYNC_COMPONENTS_IM_SYNC_TABLE.TASK_STATUS.LABEL' | translate }}
              </div>
              <div  data-test="task-status" class="mc-detailed-list-column">
                {{ '$I18N_SERVICES_IM_SYNC_COMPONENTS_IM_SYNC_TABLE.TASK_STATUS.' +  imTask?.status | uppercase | translate }}
              </div>
            </div>
          </div>
        </div>
      </section>
    </mc-collapsable-panel>
    <mc-collapsable-panel
    *ngIf="connectorDetails"
    data-test="connector-details-panel"
    header="{{ '$I18N_SERVICES_IM_SYNC_COMPONENTS_IM_SYNC_TABLE.VIEW_TASK.CONNECTOR_DETAILS' | translate }}"
    [isExpanded]="true">
    <section class="mc-detail-section">
      <div class="panel-body no-padding-top">
        <div class="mc-detailed-list">
          <div class="row mc-detailed-list-row">
            <div class="col-xs-3 mc-detailed-list-label">
              {{ '$I18N_SERVICES_IM_SYNC_COMPONENTS_IM_SYNC_TABLE.VIEW_TASK.NAME' | translate }}
            </div>
            <div class="mc-detailed-list-column">{{ connectorDetails.name }}</div>
          </div>
          <div class="row mc-detailed-list-row">
            <div class="col-xs-3 mc-detailed-list-label">
              {{ '$I18N_SERVICES_IM_SYNC_COMPONENTS_IM_SYNC_TABLE.VIEW_TASK.PRODUCT.LABEL' | translate }}
            </div>
            <div class="mc-detailed-list-column">
              {{ '$I18N_SERVICES_IM_SYNC_COMPONENTS_IM_SYNC_TABLE.VIEW_TASK.PRODUCT.' +  connectorDetails.product | uppercase | translate }}
            </div>
          </div>
          <div class="row mc-detailed-list-row">
            <div class="col-xs-3 mc-detailed-list-label">
              {{ '$I18N_SERVICES_IM_SYNC_COMPONENTS_IM_SYNC_TABLE.VIEW_TASK.TENANT_ID' | translate }}
            </div>
            <div class="mc-detailed-list-column">{{ connectorDetails.serviceAccountId }}</div>
          </div>
        </div>
      </div>
    </section>
  </mc-collapsable-panel>
    <mc-collapsable-panel
      header="{{ '$I18N_SERVICES_IM_SYNC_COMPONENTS_IM_SYNC_TABLE.VIEW_TASK.LAST_SYNC_DETAILS' | translate }}"
      [isExpanded]="true">
      <section class="mc-detail-section">
        <div class="panel-body no-padding-top">
          <div class="mc-detailed-list">
            <div class="row mc-detailed-list-row">
              <div class="col-xs-3 mc-detailed-list-label">
                {{ '$I18N_SERVICES_IM_SYNC_COMPONENTS_IM_SYNC_TABLE.VIEW_TASK.LAST_EXECUTION' | translate }}
              </div>
              <div class="mc-detailed-list-column">{{ executionSummary?.lastExecutionStart  | mcDate }}</div>
            </div>

            <div class="row mc-detailed-list-row">
              <div class="col-xs-3 mc-detailed-list-label">
                {{ '$I18N_SERVICES_IM_SYNC_COMPONENTS_IM_SYNC_TABLE.VIEW_TASK.STATUS' | translate }}
              </div>
              <div class="mc-detailed-list-column">
                <mc-result-status-indicator [status]="executionSummary?.executionState"></mc-result-status-indicator>
              </div>
            </div>

            <div class="row mc-detailed-list-row">
              <div class="col-xs-3 mc-detailed-list-label">
                {{ '$I18N_SERVICES_IM_SYNC_COMPONENTS_IM_SYNC_TABLE.VIEW_TASK.MESSAGES' | translate }}
              </div>
              <div class="mc-detailed-list-column">{{ executionSummary?.lastExecutionDetails?.numberOfMessages }}
              </div>
            </div>

            <div class="row mc-detailed-list-row">
              <div class="col-xs-3 mc-detailed-list-label">
                {{ '$I18N_SERVICES_IM_SYNC_COMPONENTS_IM_SYNC_TABLE.VIEW_TASK.CHANNELS' | translate }}
              </div>
              <div class="mc-detailed-list-column">{{ executionSummary?.lastExecutionDetails?.numberOfChannels }}
              </div>
            </div>

            <div class="row mc-detailed-list-row">
              <div class="col-xs-3 mc-detailed-list-label">
                {{ '$I18N_SERVICES_IM_SYNC_COMPONENTS_IM_SYNC_TABLE.VIEW_TASK.ONE_TO_ONE_CHATS' | translate }}
              </div>
              <div class="mc-detailed-list-column">
                {{ executionSummary?.lastExecutionDetails?.numberOfOneOnOneMessages }}</div>
            </div>

            <div class="row mc-detailed-list-row">
              <div class="col-xs-3 mc-detailed-list-label">
                {{ '$I18N_SERVICES_IM_SYNC_COMPONENTS_IM_SYNC_TABLE.VIEW_TASK.SYNC_TIME' | translate }}
              </div>
              <div data-test="task-sync-time"  class="mc-detailed-list-column">{{ getSyncTime() }}</div>
            </div>
          </div>
        </div>
      </section>
    </mc-collapsable-panel>
  </mc-body-container>
</mc-layout-aside-extra-container>
