<div class="mc-identity-ip-addresses-block mc-identity-policy-steps-container">

    <mc-wizard-step-header class="mc-identity-wizard-step-header"
                           label="$I18N_IDENTITY_CREATE_POLICY_IP_ADDRESSES_STEP.TITLE">
    </mc-wizard-step-header>

    <form class="form-horizontal"
          [formGroup]="ipAddressesForm"
          mcDefaultLabelClass="col-sm-3"
          mcDefaultControlContainerClass="col-sm-9">

        <mc-field label="$I18N_IDENTITY_CREATE_POLICY_IP_ADDRESSES_STEP.ELEMENTS.PERMISSION_TYPE.LABEL" required>
            <mc-select formControlName="permissionType"
                       [options]="permissionTypeOptions">
            </mc-select>
        </mc-field>

        <mc-field *ngIf="showIpFields$ | async"
                  [label]="ipFormControlLabel$ | async"
                  [helpPopoverContent]="ipFormControlPopover$ | async"
                  errorPrefix="$I18N_IDENTITY_CREATE_POLICY_IP_ADDRESSES_STEP.VALIDATION.IP">
            <div class="mp-ip-step-input">
                <input type="text" class="form-control"
                       (input)="onIpChange($event.target.value)"
                       [placeholder]="'$I18N_IDENTITY_CREATE_POLICY_IP_ADDRESSES_STEP.ELEMENTS.IP_LIST.PLACEHOLDER' | translate"
                       formControlName="ip">

                <button type="submit" [disabled]="addButtonDisabled$ | async"
                        class="btn btn-default mc-identity-policy-app-ip"
                        (click)="addIp()">
                    {{ '$I18N_IDENTITY_CREATE_POLICY_IP_ADDRESSES_STEP.ELEMENTS.IP_LIST.ADD_BUTTON' | translate}}
                </button>
            </div>
        </mc-field>
    </form>
</div>

<div class="mc-ip-step-container"
     *ngIf="showIpFields$ | async">
    <ng-container *ngIf="(ipAddresses$ | async).length > 0">
        <mc-table [isLoading]="false"
                  [columns]="columns"
                  [data]="ipAddresses$ | async"
                  translatePrefix="$I18N_IDENTITY_CREATE_POLICY_IP_ADDRESSES_STEP.IP_LIST_PREFIX">
            <mc-column key="delete-column">
                <mc-header-cell class="mp-ip-step-table" *mcHeaderCellDef>
                <span (click)="onDeleteAll()">
                    {{ '$I18N_IDENTITY_CREATE_POLICY_IP_ADDRESSES_STEP.DELETE_ALL_BUTTON' | translate }}
                </span>
                </mc-header-cell>
                <mc-body-cell class="mp-ip-step-table" *mcBodyCellDef="let row">
                    <i (click)="removeIp(row)" class="far fa-trash-alt cursor-pointer"></i>
                </mc-body-cell>
            </mc-column>

        </mc-table>
    </ng-container>
</div>

