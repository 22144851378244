"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BackupEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const notification_actions_1 = require("../../../components/notification/actions/notification.actions");
const actions_1 = require("../actions");
class BackupEffects {
    constructor(actions$, apiService, stateService) {
        this.actions$ = actions$;
        this.apiService = apiService;
        this.stateService = stateService;
        this.loadBackupDetails$ = this.actions$.pipe(effects_1.ofType(actions_1.BackupActions.LOAD_BACKUP_DETAILS), operators_1.switchMap(action => {
            return this.apiService.getBackup(action.backupId).pipe(operators_1.switchMap((response) => {
                if (response.hasErrors) {
                    return [
                        new actions_1.LoadBackupDetailsFailureAction(),
                        new notification_actions_1.NotificationShowFailAction('$I18N_ONEDRIVE_BACKUP_WIZARD.NOTIFICATION.ERR_BACKUP_RETRIEVAL_FAILURE')
                    ];
                }
                const backup = response.first;
                this.stateService.$state.go('onedrive-browser', {
                    backup
                });
                return [new actions_1.LoadBackupDetailsSuccessAction(backup)];
            }), operators_1.catchError(() => {
                return rxjs_1.of(new actions_1.LoadBackupDetailsFailureAction(), new notification_actions_1.NotificationShowFailAction('$I18N_ONEDRIVE_BACKUP_WIZARD.NOTIFICATION.ERR_BACKUP_RETRIEVAL_FAILURE'));
            }));
        }));
        this.createBackup$ = this.actions$.pipe(effects_1.ofType(actions_1.BackupActions.CREATE_BACKUP), operators_1.switchMap(action => {
            return this.apiService.createBackup(action.payload).pipe(operators_1.switchMap((response) => {
                if (response.hasErrors) {
                    return [
                        new actions_1.CreateBackupFailureAction(),
                        new notification_actions_1.NotificationShowFailAction('$I18N_ONEDRIVE_BACKUP_WIZARD.NOTIFICATION.CREATE_FAILURE')
                    ];
                }
                this.stateService.$state.go('one-drive-backups');
                return [
                    new actions_1.CreateBackupSuccessAction(),
                    new notification_actions_1.NotificationShowSuccessAction('$I18N_ONEDRIVE_BACKUP_WIZARD.NOTIFICATION.CREATE_SUCCESS')
                ];
            }));
        }));
        this.updateBackup$ = this.actions$.pipe(effects_1.ofType(actions_1.BackupActions.UPDATE_BACKUP), operators_1.switchMap(action => {
            const { backupId, backup, returnActions } = action;
            return this.apiService.updateBackup(backupId, backup).pipe(operators_1.switchMap((response) => {
                if (response.hasErrors) {
                    const errorKey = this.getUpdateErrorKey(response);
                    return [new actions_1.UpdateBackupFailureAction(), new notification_actions_1.NotificationShowFailAction(errorKey)];
                }
                if (returnActions.length === 0) {
                    this.stateService.$state.go('one-drive-backups');
                }
                return [
                    ...returnActions,
                    new actions_1.UpdateBackupSuccessAction(),
                    new notification_actions_1.NotificationShowSuccessAction('$I18N_ONEDRIVE_BACKUP_WIZARD.NOTIFICATION.UPDATE_SUCCESS')
                ];
            }), operators_1.catchError(() => {
                return rxjs_1.of(new notification_actions_1.NotificationShowFailAction('$I18N_ONEDRIVE_BACKUP_WIZARD.NOTIFICATION.UPDATE_FAILURE'));
            }));
        }));
        this.changeStatus$ = this.actions$.pipe(effects_1.ofType(actions_1.BackupActions.CHANGE_BACKUP_STATUS), operators_1.switchMap(action => {
            return this.apiService.changeBackupStatus(action.payload).pipe(operators_1.switchMap((response) => {
                if (response.hasErrors) {
                    return [
                        new actions_1.ChangeBackupStatusFailureAction(),
                        new notification_actions_1.NotificationShowFailAction('$I18N_ONEDRIVE_BACKUP_LIST.NOTIFICATION.CHANGE_STATUS.FAILURE')
                    ];
                }
                return [
                    new actions_1.ChangeBackupStatusSuccessAction(),
                    new notification_actions_1.NotificationShowSuccessAction(this.getStatusChangeSuccessNotification(action.payload.enable))
                ];
            }), operators_1.catchError(() => {
                return rxjs_1.of(new actions_1.ChangeBackupStatusFailureAction());
            }));
        }));
        this.deleteBackup$ = this.actions$.pipe(effects_1.ofType(actions_1.BackupActions.DELETE_BACKUP), operators_1.switchMap(action => {
            return this.apiService.deleteBackup(action.payload).pipe(operators_1.switchMap((response) => {
                if (response.hasErrors) {
                    return [
                        new actions_1.DeleteBackupFailureAction(),
                        new notification_actions_1.NotificationShowFailAction('$I18N_ONEDRIVE_BACKUPS.DELETE_BACKUP.NOTIFICATION.FAILURE')
                    ];
                }
                this.stateService.$state.go('one-drive-backups');
                return [
                    new actions_1.DeleteBackupSuccessAction(),
                    new notification_actions_1.NotificationShowSuccessAction('$I18N_ONEDRIVE_BACKUPS.DELETE_BACKUP.NOTIFICATION.SUCCESS')
                ];
            }));
        }));
        this.showEditBackupWizard$ = this.actions$.pipe(effects_1.ofType(actions_1.BackupActions.OPEN_EDIT_BACKUP), operators_1.switchMap(action => {
            const { backupId, returnActions } = action;
            return this.apiService.getBackup(backupId).pipe(operators_1.switchMap(response => {
                if (response.hasErrors) {
                    return [
                        new actions_1.OpenEditBackupFailureAction(),
                        new notification_actions_1.NotificationShowFailAction('$I18N_ONEDRIVE_BACKUP_WIZARD.NOTIFICATION.ERR_BACKUP_RETRIEVAL_FAILURE')
                    ];
                }
                const backup = response.first;
                this.stateService.$state.go('onedrive-backup-wizard', {
                    backup,
                    returnActions
                });
                return [new actions_1.OpenEditBackupSuccessAction()];
            }), operators_1.catchError(() => {
                return rxjs_1.of(new notification_actions_1.NotificationShowFailAction('$I18N_ONEDRIVE_BACKUP_WIZARD.NOTIFICATION.ERR_BACKUP_RETRIEVAL_FAILURE'));
            }));
        }));
    }
    getStatusChangeSuccessNotification(enabled) {
        return `$I18N_ONEDRIVE_BACKUP_LIST.NOTIFICATION.CHANGE_STATUS.SUCCESS.${enabled ? 'STATUS_ENABLED' : 'STATUS_DISABLED'}`;
    }
    getUpdateErrorKey(response) {
        const errorKeyPrefix = '$I18N_ONEDRIVE_BACKUP_WIZARD.NOTIFICATION';
        let errorCodeSuffix = 'UPDATE_FAILURE';
        if (response.fail && response.fail[0]) {
            const errors = response.fail[0].errors;
            if (errors && errors[0]) {
                if (errors[0].code === 'err_update_backup_no_changes') {
                    errorCodeSuffix = 'ERR_UPDATE_BACKUP_NO_CHANGES';
                }
            }
        }
        return `${errorKeyPrefix}.${errorCodeSuffix}`;
    }
}
__decorate([
    effects_1.Effect()
], BackupEffects.prototype, "loadBackupDetails$", void 0);
__decorate([
    effects_1.Effect()
], BackupEffects.prototype, "createBackup$", void 0);
__decorate([
    effects_1.Effect()
], BackupEffects.prototype, "updateBackup$", void 0);
__decorate([
    effects_1.Effect()
], BackupEffects.prototype, "changeStatus$", void 0);
__decorate([
    effects_1.Effect()
], BackupEffects.prototype, "deleteBackup$", void 0);
__decorate([
    effects_1.Effect()
], BackupEffects.prototype, "showEditBackupWizard$", void 0);
exports.BackupEffects = BackupEffects;
