"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tableColumnsAlwaysVisible = exports.tableColumnsToIgnore = exports.tableColumnsStartHidden = exports.icons = exports.status = exports.tableSectionSearch = exports.searchOptions = exports.columnList = void 0;
exports.columnList = [
    'description',
    'checkInbound',
    'action',
    'safeDocFormat',
    'safeSpreadsheetFormat',
    'preEmptiveFailureAction',
    'notifyOnMalicious',
    'checkOutbound',
    'checkJournal',
    'dropdown-column'
];
exports.searchOptions = {
    all: 'all',
    description: 'description'
};
exports.tableSectionSearch = {
    field: 'search',
    header: 'header',
    options: [exports.searchOptions.description]
};
exports.status = {
    enabled: 'Enabled',
    disabled: 'Disabled'
};
exports.icons = {
    circleCross: 'fas fa-ban',
    circleCheck: 'fas fa-check-circle',
    check: 'fas fa-check',
    hidden: 'hidden',
    succesColor: 'text-success',
    mutedColor: 'text-muted'
};
exports.tableColumnsStartHidden = [];
exports.tableColumnsToIgnore = ['select'];
exports.tableColumnsAlwaysVisible = ['description'];
