"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.canUpdateList = exports.hasGenericError = exports.isLoadingAttributeDetails = exports.getAttributeDetails = exports.reducer = exports.initialState = void 0;
const asideAction = require("../../actions/aside.action");
exports.initialState = {
    attribute: null,
    isLoading: true,
    genericError: false,
    updateList: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case asideAction.GET_ATTRIBUTE_DETAILS:
            return Object.assign(Object.assign({}, state), { genericError: false, isLoading: true, attribute: Object.assign({}, action.row) });
        case asideAction.GET_ATTRIBUTE_DETAILS_SUCCESS:
            return Object.assign(Object.assign({}, state), { genericError: false, isLoading: false, attribute: Object.assign(Object.assign({}, state.attribute), action.attribute) });
        case asideAction.GET_ATTRIBUTE_DETAILS_FAILURE:
            return Object.assign(Object.assign({}, state), { genericError: true, isLoading: false });
        case asideAction.OPEN_CREATE_ATTRIBUTE:
            return Object.assign(Object.assign({}, state), { genericError: false, isLoading: true });
        case asideAction.SAVE_ATTRIBUTE:
            return Object.assign(Object.assign({}, state), { genericError: false, isLoading: true, updateList: false, attribute: Object.assign({}, state.attribute) });
        case asideAction.SAVE_NEW_ATTRIBUTE_SUCCESS:
            return Object.assign(Object.assign({}, state), { genericError: false, isLoading: false, updateList: true, attribute: Object.assign({}, state.attribute) });
        case asideAction.UPDATE_ATTRIBUTE:
            return Object.assign(Object.assign({}, state), { genericError: false, isLoading: true, updateList: false, attribute: Object.assign(Object.assign({}, state.attribute), action.attribute) });
        case asideAction.SAVE_ATTRIBUTE_SUCCESS:
            return Object.assign(Object.assign({}, state), { genericError: false, isLoading: false, updateList: true, attribute: Object.assign(Object.assign({}, state.attribute), action.attribute) });
        case asideAction.SAVE_ATTRIBUTE_FAILURE:
            return Object.assign(Object.assign({}, state), { genericError: true, isLoading: false, updateList: false });
        case asideAction.DELETE_ATTRIBUTE_CONFIRM:
            return Object.assign(Object.assign({}, state), { genericError: false, isLoading: false, updateList: false, attribute: Object.assign(Object.assign({}, state.attribute), action.attribute) });
        case asideAction.DELETE_ATTRIBUTE:
            return Object.assign(Object.assign({}, state), { genericError: false, isLoading: true, updateList: false, attribute: Object.assign(Object.assign({}, state.attribute), action.attribute) });
        case asideAction.DELETE_ATTRIBUTE_SUCCESS:
            return Object.assign(Object.assign({}, state), { genericError: false, isLoading: false, updateList: true });
        case asideAction.DELETE_ATTRIBUTE_FAILURE:
            return Object.assign(Object.assign({}, state), { genericError: true, isLoading: false, updateList: false });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getAttributeDetails = (state) => state.attribute;
exports.getAttributeDetails = getAttributeDetails;
const isLoadingAttributeDetails = (state) => state.isLoading;
exports.isLoadingAttributeDetails = isLoadingAttributeDetails;
const hasGenericError = (state) => state.genericError;
exports.hasGenericError = hasGenericError;
const canUpdateList = (state) => state.updateList;
exports.canUpdateList = canUpdateList;
