<mc-swg-create-policy-wizard
  [policy]="policy$ | async"
  [wizardFlowType]="wizardFlowType$ | async"
  [apiIsProcessing]="apiIsProcessing$ | async"
  [webCategoryRulesSections]="webCategoryRulesSections$ | async"
  [applications]="applications$ | async"
  [applicationsWithStatus]="applicationsWithStatus$ | async"
  [advancedFileTypes]="advancedFileTypes$ | async"
  [advancedFileTypesLoading]="advancedFileTypesLoading$ | async"
  [loadingForEdit]="loadingForEdit$ | async"
  [hasTTP]="hasTTP$ | async"
  [hasClp]="hasClp"
  [hasSwgHybrid]="(hasSwgHybrid$ | async) && hasSwgHybridCaps"
  [hasBrowserIsolationEnabled]="hasBrowserIsolationEnabled"
  [hasApplicationPolicyControllableEnabled]="hasApplicationPolicyControllableCaps"
  [hasEditPermission]="hasEditPermission$ | async"
  [applicationsLoading]="applicationsLoading$ | async"
  [applicationsWithStatusLoading]="applicationsWithStatusLoading$ | async"
  [categorySectionsLoading]="categorySectionsLoading$ | async"
  [isApplicationCategorySetDataLoading]="isApplicationCategorySetDataLoading$ | async"
  [applicationCategoriesSet]="applicationCategoriesSet$ | async"
  [hasAppPolicyRemoveAllowEnabled]="hasAppPolicyRemoveAllowEnabled"
  [protectedApplications]="protectedApplications$ | async"
  [allConnectors]="allConnectors$ | async"
  [allConnectorsLoading]="allConnectorsLoading$ | async"
  [providers]="providers$ | async"
  [providersLoading]="providersLoading$ | async"
  (updatePolicyDescTypeWiz)="updatePolicyDescType($event)"
  (updatePolicyDomainsWiz)="updatePolicyDomains($event)"
  (updateLoggingLevelWiz)="updateLoggingLevel($event)"
  (updateTtpManagedUrlSettingsWiz)="updateTtpManagedUrlSettings($event)"
  (updateTtpChameleonSettingsWiz)="updateTtpChameleonSettings($event)"
  (updateAffectedUsersWiz)="updateAffectedUsers($event)"
  (removeAffectedUsersWiz)="removeAffectedUsers($event)"
  (everyOneAffectedUsersWiz)="everyOneAffectedUsers($event)"
  (updateSafeSearchSettingsWiz)="updateSafeSearchSettings($event)"
  (updateNewDomainsSettingsWiz)="updateNewDomainsSettings($event)"
  (updateProxySettingsWiz)="updateProxySettings($event)"
  (updateWebCategorySecurityRulesWiz)="updateWebCategorySecurityRules($event)"
  (updateWebApplicationRuleWiz)="updateWebApplicationRule($event)"
  (updateWebCategoryOtherRulesWiz)="updateWebCategoryOtherRules($event)"
  (updateProtectedApplicationsWiz)="updateprotectedApplicationsSettings($event)"
  (updateClpSettingsWiz)="updateClpSettings($event)"
  (openAppliesToSidePanelWiz)="openAppliesToSidePanel($event)"
  (openBulkUploadDomainsWiz)="openBulkUploadDomains()"
  (createPolicyEvent)="createPolicy($event)"
  (resetWizard)="resetWizard()"
  (updatePolicyOverrideStatusWiz)="updatePolicyOverrideStatus($event)"
  (clearAffectedUsersOtherThanWiz)="clearAffectedUsersOtherThan($event)"
></mc-swg-create-policy-wizard>
