"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.KeyGenEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const components_1 = require("@mimecast-ui/components");
const actions = require("../../actions");
const reducers = require("../../reducers");
const models_1 = require("../../models");
class KeyGenEffects {
    constructor(actions$, store, loginService, apiService) {
        this.actions$ = actions$;
        this.store = store;
        this.loginService = loginService;
        this.apiService = apiService;
        this.wizardMoveToDiscovery$ = this.actions$.pipe(effects_1.ofType(actions.ApiGatewayKeyGenActionTypes.GoToNextStep), operators_1.withLatestFrom(this.store.select(reducers.getApiGatewayKeyGenWizardStep)), operators_1.filter(([, step]) => step === models_1.KeyGenState.account), operators_1.withLatestFrom(this.store.select(reducers.getApiGatewayKeyGenAccount)), operators_1.map(([, account]) => new actions.DiscoverAccountAction(account)));
        this.discoverAccountAction$ = this.actions$.pipe(effects_1.ofType(actions.ApiGatewayKeyGenActionTypes.DiscoverAccount), operators_1.switchMap((action) => {
            return this.loginService.discoverAuthentication(action.payload).pipe(operators_1.map((response) => {
                return new actions.AccountDiscoverySuccessAction(this.loginService.getAuthenticationTypes(response.authenticate));
            }), operators_1.catchError(() => rxjs_1.of(new actions.AccountDiscoveryFailureAction('$I18N_API_GATEWAY_APPLICATIONS_KEYGEN_WIZARD.ERRORS.DISCOVERY_FAIL'))));
        }));
        this.wizardMoveToAuthentication$ = this.actions$.pipe(effects_1.ofType(actions.ApiGatewayKeyGenActionTypes.GoToNextStep), operators_1.withLatestFrom(this.store.select(reducers.getApiGatewayKeyGenWizardStep)), operators_1.filter(([, step]) => step === models_1.KeyGenState.authentication), operators_1.withLatestFrom(this.store.select(reducers.getApiGatewayKeyGenAuthentication)), operators_1.map(([, auth]) => new actions.AuthenticateAccountAction(auth)));
        this.authenticateAccountAction$ = this.actions$.pipe(effects_1.ofType(actions.ApiGatewayKeyGenActionTypes.AuthenticateAccount), operators_1.withLatestFrom(this.store.select(reducers.getApiGatewayKeyGenApplication)), operators_1.switchMap(([action, application]) => {
            return this.loginService.login(action.payload).pipe(operators_1.switchMap((response) => {
                const actionsToDispatch = [];
                actionsToDispatch.push(new actions.AuthenticationSuccessAction(response));
                if (application && application.service) {
                    actionsToDispatch.push(new actions.ExtendBindingAction());
                }
                return actionsToDispatch;
            }), operators_1.catchError(error => rxjs_1.of(new actions.AuthenticationErrorAction(!!error.error ? error : { error }))));
        }));
        this.authenticationCodeSentAction$ = this.actions$.pipe(effects_1.ofType(actions.ApiGatewayKeyGenActionTypes.AuthenticationError), operators_1.filter((action) => components_1.api.getFirstErrorCode(action.payload) === 'err_xdk_verification_code_sent'), operators_1.map(action => new actions.VerificationRequiredAction(components_1.api.firstFail(action.payload))));
        this.applicationNotAvailableFailureAction$ = this.actions$.pipe(effects_1.ofType(actions.ApiGatewayKeyGenActionTypes.AuthenticationError), operators_1.filter((action) => components_1.api.getFirstErrorCode(action.payload) === 'err_developer_key'), operators_1.map(() => new actions.AuthenticationFailureAction('$I18N_API_GATEWAY_APPLICATIONS_KEYGEN_WIZARD.ERRORS.APPLICATION_ID_FAIL')));
        this.authenticationFailureAction$ = this.actions$.pipe(effects_1.ofType(actions.ApiGatewayKeyGenActionTypes.AuthenticationError), operators_1.filter((action) => components_1.api.getFirstErrorCode(action.payload) !== 'err_xdk_verification_code_sent' &&
            components_1.api.getFirstErrorCode(action.payload) !== 'err_developer_key'), operators_1.map(() => new actions.AuthenticationFailureAction('$I18N_API_GATEWAY_APPLICATIONS_KEYGEN_WIZARD.ERRORS.AUTHENTICATION_FAIL')));
        this.getApplicationKey$ = this.actions$.pipe(effects_1.ofType(actions.ApiGatewayKeyGenActionTypes.DiscoverAccount), operators_1.withLatestFrom(this.store.select(reducers.getApiGatewayKeyGenApplication)), operators_1.switchMap(([, app]) => {
            return this.apiService
                .getAppKey(app.id)
                .pipe(operators_1.map((response) => new actions.GetApplicationKeySuccessAction(response.appKey)));
        }));
        this.moveToVerifyCodeAction$ = this.actions$.pipe(effects_1.ofType(actions.ApiGatewayKeyGenActionTypes.GoToNextStep), operators_1.withLatestFrom(this.store.select(reducers.getApiGatewayKeyGenWizardStep)), operators_1.filter(([, step]) => step === models_1.KeyGenState.verification), operators_1.withLatestFrom(this.store.select(reducers.getApiGatewayKeyGenVerificationDetails)), operators_1.map(([, verification]) => new actions.VerifyAccountAction(verification)));
        this.verifyCodeAction$ = this.actions$.pipe(effects_1.ofType(actions.ApiGatewayKeyGenActionTypes.VerifyAccount), operators_1.withLatestFrom(this.store.select(reducers.getApiGatewayKeyGenApplication)), operators_1.switchMap(([action, application]) => {
            return this.loginService.submitVerificationCode(action.payload).pipe(operators_1.switchMap((response) => {
                const actionsToDispatch = [];
                actionsToDispatch.push(new actions.AuthenticationSuccessAction(response));
                if (application && application.service) {
                    actionsToDispatch.push(new actions.ExtendBindingAction());
                }
                return actionsToDispatch;
            }), operators_1.catchError(() => rxjs_1.of(new actions.AuthenticationFailureAction('$I18N_API_GATEWAY_APPLICATIONS_KEYGEN_WIZARD.ERRORS.AUTHENTICATION_FAIL'))));
        }));
        this.extendBindingAction$ = this.actions$.pipe(effects_1.ofType(actions.ApiGatewayKeyGenActionTypes.ExtendBinding), operators_1.withLatestFrom(this.store.select(reducers.getApiGatewayKeyGenVerificationDetails)), operators_1.switchMap(([, signing]) => {
            return this.loginService.extendBinding(signing).pipe(operators_1.switchMap(() => {
                return rxjs_1.EMPTY;
            }), operators_1.catchError(() => rxjs_1.of(new actions.VerificationApiFailureAction('$I18N_API_GATEWAY_APPLICATIONS_KEYGEN_WIZARD.ERRORS.VERIFICATION_API_FAIL'))));
        }));
    }
}
__decorate([
    effects_1.Effect()
], KeyGenEffects.prototype, "wizardMoveToDiscovery$", void 0);
__decorate([
    effects_1.Effect()
], KeyGenEffects.prototype, "discoverAccountAction$", void 0);
__decorate([
    effects_1.Effect()
], KeyGenEffects.prototype, "wizardMoveToAuthentication$", void 0);
__decorate([
    effects_1.Effect()
], KeyGenEffects.prototype, "authenticateAccountAction$", void 0);
__decorate([
    effects_1.Effect()
], KeyGenEffects.prototype, "authenticationCodeSentAction$", void 0);
__decorate([
    effects_1.Effect()
], KeyGenEffects.prototype, "applicationNotAvailableFailureAction$", void 0);
__decorate([
    effects_1.Effect()
], KeyGenEffects.prototype, "authenticationFailureAction$", void 0);
__decorate([
    effects_1.Effect()
], KeyGenEffects.prototype, "getApplicationKey$", void 0);
__decorate([
    effects_1.Effect()
], KeyGenEffects.prototype, "moveToVerifyCodeAction$", void 0);
__decorate([
    effects_1.Effect()
], KeyGenEffects.prototype, "verifyCodeAction$", void 0);
__decorate([
    effects_1.Effect()
], KeyGenEffects.prototype, "extendBindingAction$", void 0);
exports.KeyGenEffects = KeyGenEffects;
