"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiWildfireIntegrationAsideViewComponent = void 0;
const core_1 = require("@angular/core");
class ApiWildfireIntegrationAsideViewComponent {
    constructor() {
        this.onCloseClicked = new core_1.EventEmitter();
        this.onEditClicked = new core_1.EventEmitter();
        this.onEnableClicked = new core_1.EventEmitter();
        this.onDisableClicked = new core_1.EventEmitter();
    }
    get wildfireGroupsList() {
        if (this.wildfireIntegration) {
            return Object.values(this.wildfireIntegration.notificationGroups);
        }
        return [];
    }
    get wildfireEmailList() {
        if (this.wildfireIntegration) {
            return this.wildfireIntegration.notificationAddresses;
        }
        return [];
    }
}
exports.ApiWildfireIntegrationAsideViewComponent = ApiWildfireIntegrationAsideViewComponent;
