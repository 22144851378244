"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PendoAnalyticsEffects = void 0;
const effects_1 = require("@ngrx/effects");
const operators_1 = require("rxjs/operators");
const actions = require("../actions");
class PendoAnalyticsEffects {
    constructor(_ngZone, actions$, window, pendoService) {
        this._ngZone = _ngZone;
        this.actions$ = actions$;
        this.window = window;
        this.pendoService = pendoService;
        this.pendoInitializeSettings$ = this.actions$.pipe(effects_1.ofType(actions.pendo.PENDO_INITIALIZE_SETTINGS), operators_1.map((action) => action.payload), operators_1.tap((pendoInfo) => {
            const pendo = this.window && this.window.pendo;
            if (!!pendo && !!pendoInfo) {
                this._ngZone.runOutsideAngular(() => {
                    this.waitForPendo(pendo, Object.assign({}, pendoInfo), 3);
                });
            }
        }));
        this.pendoGetInformation$ = this.actions$.pipe(effects_1.ofType(actions.pendo.PENDO_GET_INFORMATION), operators_1.switchMap(() => this.pendoService
            .getPendoInfo()
            .pipe(operators_1.map((info) => new actions.pendo.PendoInitializeSettingsAction(info)))));
    }
    waitForPendo(pendo, info, retry = 0) {
        if (retry > 0) {
            // check if the isReady function is present - this indicates that the pendo load is complete
            if (!pendo.isReady) {
                setTimeout(() => this.waitForPendo(pendo, info, retry - 1), 1000);
            }
            else {
                this.initialisePendo(pendo, info);
            }
        }
    }
    initialisePendo(pendo, info) {
        // check if pendo has already been initialised with user information
        if (!pendo.isReady()) {
            pendo.initialize(info);
        }
        else {
            pendo.identify(info);
        }
    }
}
__decorate([
    effects_1.Effect({ dispatch: false })
], PendoAnalyticsEffects.prototype, "pendoInitializeSettings$", void 0);
__decorate([
    effects_1.Effect()
], PendoAnalyticsEffects.prototype, "pendoGetInformation$", void 0);
exports.PendoAnalyticsEffects = PendoAnalyticsEffects;
