<mc-tab-group #tabs [initialActiveTabIndex]="tabIndex" (tabChange)="onTabChanged($event)">
  <ng-container *ngIf="hasHeldReadSummaryPermission$ | async">
    <mc-tab name="{{ '$I18N_MESSAGE_CENTER_HELD_MESSAGES_BREADCRUMBS.OVERVIEW' | translate }}">
      <mc-overview-page
        (clickedBarOverview)="switchToHeldQueue($event)"
        (clickedPieOverview)="switchToHeldQueue($event)"
        (clickIconSearchEmitOverview)="switchToHeldQueueFromAutocomplete($event)"
        (clickedTableRowOverview)="switchToHeldQueue($event)"
      ></mc-overview-page>
    </mc-tab>
  </ng-container>
  <ng-container *ngIf="hasHeldReadPermission$ | async">
    <mc-tab name="{{ '$I18N_MESSAGE_CENTER_HELD_MESSAGES_BREADCRUMBS.HELD_QUEUE' | translate }}">
        <mc-held-queue-tab></mc-held-queue-tab>
    </mc-tab>
  </ng-container>
  <ng-container *ngIf="hasHeldReadSummaryPermission$ | async">
    <mc-tab name="{{ '$I18N_MESSAGE_CENTER_HELD_MESSAGES_BREADCRUMBS.RELEASE_LOGS' | translate }}">
      <mc-release-logs-tab></mc-release-logs-tab>
    </mc-tab>
  </ng-container>
</mc-tab-group>
 
