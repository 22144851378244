"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("angular-ui-bootstrap");
require("angular-ui-switch");
require("app/components/mc-checkbox/mc-checkbox");
require("app/services/services/backup/components/radiobutton/mc-radiobutton");
require("app/services/services/backup/components/rbutton/mc-r-button");
require("app/services/services/backup/components/time-exclusions/time-exclusions");
require("mimecast-web-components/src/app/components/date-picker/date-picker");
require("mimecast-web-components/src/app/components/date-range-picker/date-range-picker");
require("mimecast-web-components/src/app/components/form/select/mc-select");
require("mimecast-web-components/src/app/components/tags-input/tags-input");
require("mimecast-web-components/src/app/services/archive/folder/archive-folder");
require("./archive-task-edit-summary/archive-task-edit-summary");
require("./archive-task-edit.controller");
require("./archive-task-edit.service");
require("./archive-task-edit-guard.service");
require("./archive-task-edit.tpl.html");
require("./archive-task-update.tpl.html");
require("./wizard-steps/archive-task-edit-part-01.tpl.html");
require("./wizard-steps/archive-task-edit-part-02.tpl.html");
require("./wizard-steps/archive-task-edit-part-03.tpl.html");
require("./wizard-steps/wizard-step-01.tpl.html");
require("./wizard-steps/wizard-step-02.tpl.html");
require("./wizard-steps/wizard-step-03.tpl.html");
(function () {
    'use strict';
    /* ======================================================== *\

     = Archive task - edit

     \* ======================================================== */
    angular
        .module('services.services.backup.archive-task-edit', [
        'services.services.backup.archive-task-edit.controller',
        'tags.input',
        'uiSwitch',
        'input.advancedDateRangePicker',
        'input.advancedDatePicker',
        'api.archive.folder',
        'predefined-field.time-exclusions',
        'ui.bootstrap.timepicker',
        'predefined-field.rbutton',
        'predefined-field.checkbox',
        'predefined-field.radiobutton',
        'predefined-field.select',
        'services.services.backup.archive-task-edit.service',
        'archive-task-edit-summary',
        'services.services.backup.archive-task-edit.guard'
    ])
        .config([
        '$stateProvider',
        function ($stateProvider) {
            $stateProvider
                .state('archive-task-create', {
                url: '/sync-recover/exchange/task/create',
                data: {
                    breadcrumbs: [
                        '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_SERVICES',
                        '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ASIDE_MENU_TITLE',
                        '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ARCHIVE_TASK_MENU_LABEL',
                        '$I18N_BACKUP_RESTORE_ARCHIVE_TASK_BREADCRUMBS_LABEL_NEW'
                    ],
                    tabId: 'sync-and-recover-tab',
                    tabTitle: '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ASIDE_MENU_TITLE',
                    asideTitle: '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ASIDE_MENU_TITLE',
                    tabHide: false,
                    tabReload: true
                },
                views: {
                    main: {
                        templateUrl: 'services/services/backup/archive-task/archive-task-edit/archive-task-edit.tpl.html',
                        resolve: {
                            archiveTaskByID: [
                                'archiveTaskEditGuard',
                                function (archiveTaskEditGuard) {
                                    archiveTaskEditGuard.reset();
                                    return false;
                                }
                            ],
                            isAside: [
                                function () {
                                    return false;
                                }
                            ]
                        }
                    },
                    aside: {
                        templateUrl: 'services/services/backup/aside/aside.tpl.html'
                    }
                }
            })
                .state('archive-task-update', {
                url: '/sync-recover/exchange/task/edit/:taskId',
                data: {
                    breadcrumbs: [
                        '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_SERVICES',
                        '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ASIDE_MENU_TITLE',
                        '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ARCHIVE_TASK_MENU_LABEL',
                        '$I18N_BACKUP_RESTORE_ARCHIVE_TASK_BREADCRUMBS_LABEL_EDIT'
                    ],
                    tabId: 'sync-and-recover-tab',
                    tabTitle: '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ASIDE_MENU_TITLE',
                    asideTitle: '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ASIDE_MENU_TITLE',
                    tabHide: false,
                    tabReload: true
                },
                views: {
                    main: {
                        templateUrl: 'services/services/backup/archive-task/archive-task-edit/archive-task-update.tpl.html',
                        resolve: {
                            archiveTaskByID: [
                                'archiveTaskEditGuard',
                                '$stateParams',
                                function (archiveTaskEditGuard, $stateParams) {
                                    return archiveTaskEditGuard.getBackupTask($stateParams.taskId);
                                }
                            ]
                        }
                    },
                    aside: {
                        templateUrl: 'services/services/backup/aside/aside.tpl.html'
                    }
                }
            });
        }
    ]);
})();
