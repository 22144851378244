"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeliveryFailureNoticeInfoComponent = void 0;
class DeliveryFailureNoticeInfoComponent {
    getNoticeStatus() {
        if (!!this.notices && this.notices.length > 0) {
            if (this.notices[0].status) {
                return '$I18N_MESSAGE_CENTER_MESSAGE_INFO_DELIVERY_FAILURE_NOTICE_SENT_INFO.DELIVERED';
            }
            else {
                return '$I18N_MESSAGE_CENTER_MESSAGE_INFO_DELIVERY_FAILURE_NOTICE_SENT_INFO.NOT_DELIVERED';
            }
        }
    }
    getNoticeTo() {
        if (!!this.notices && this.notices.length > 0) {
            return this.notices[0].to;
        }
    }
    getNoticeCreated() {
        if (!!this.notices && this.notices.length > 0) {
            return this.notices[0].created;
        }
    }
}
exports.DeliveryFailureNoticeInfoComponent = DeliveryFailureNoticeInfoComponent;
