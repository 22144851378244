"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfirmationModalComponent = void 0;
const actions = require("../../actions");
class ConfirmationModalComponent {
    constructor(overlayRef, data, translateService, store) {
        this.overlayRef = overlayRef;
        this.data = data;
        this.translateService = translateService;
        this.store = store;
        this.modalTypes = {
            close: {
                header: () => this.translateService.instant('$I18N_DIRECTORY_SYNC_CONFIRMATION_MODAL.CLOSE_TITLE'),
                method: () => {
                    this.leave();
                }
            },
            delete: {
                header: () => `${this.translateService.instant('$I18N_DIRECTORY_SYNC_CONFIRMATION_MODAL.DELETE_TITLE')} ${this.data.integrationName}?`,
                method: () => {
                    this.delete();
                }
            },
            cancel: {
                header: () => this.translateService.instant('$I18N_DIRECTORY_SYNC_CONFIRMATION_MODAL.CANCEL_TITLE'),
                method: () => {
                    this.cancel();
                }
            },
            create_cancel: {
                header: () => this.translateService.instant('$I18N_DIRECTORY_SYNC_CONFIRMATION_MODAL.CREATE_CANCEL_TITLE'),
                method: () => {
                    this.cancel();
                }
            },
            onboarding_redirect: {
                header: () => this.translateService.instant('$I18N_DIRECTORY_SYNC_CONFIRMATION_MODAL.ONBOARDING_REDIRECT_TITLE'),
                method: () => {
                    this.redirectToOnboarding();
                }
            }
        };
    }
    leave() {
        this.overlayRef.close();
        if (this.data && this.data.sidePanel) {
            this.data.sidePanel.close();
        }
        this.store.dispatch(new actions.ClearSearchDirectoryResultsAction());
    }
    stay() {
        this.overlayRef.close();
    }
    delete() {
        this.leave();
        this.store.dispatch(new actions.DeleteIntegrationAction({
            id: this.data.integrationID
        }, this.data.pageSize));
    }
    cancel() {
        this.leave();
        this.store.dispatch(new actions.CancelCreateDirectoryIntegrationWizardAction());
    }
    close() {
        if (this.data && this.modalTypes[this.data.type]) {
            this.modalTypes[this.data.type].method();
        }
    }
    getHeaderTitle() {
        return this.data && this.modalTypes[this.data.type]
            ? this.modalTypes[this.data.type].header()
            : '';
    }
    redirectToOnboarding() {
        this.overlayRef.close();
        this.store.dispatch(new actions.RedirectToOnboardingAction());
    }
}
exports.ConfirmationModalComponent = ConfirmationModalComponent;
