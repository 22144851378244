<div class="row mc-detailed-list-row">
  <div class="col-xs-3 mc-detailed-list-label">{{label | translate}}</div>
  <div class="mc-detailed-list-column">
    <ng-container *ngTemplateOutlet="emailsList; context:{$implicit: emails}"></ng-container>
  </div>
  <div class="mc-detailed-list-column" *ngIf="secondEmails">
    <ng-container *ngTemplateOutlet="emailsList; context:{$implicit: secondEmails}"></ng-container>
  </div>
  <ng-template #emailsList let-emails>
    <span *ngIf="!emails || !emails.length">-</span>
    <span data-test="email" *ngFor="let email of emails | slice:0:2">{{email}} </span>
    <span qa-test-show-more *ngIf="emails?.length>2 && !(showMore | async)"><br><a
      (click)="showMore.next(true)" data-test="showMore">Show more</a></span>
    <ng-container *ngIf="showMore | async">
      <span data-test="email" *ngFor="let email of emails | slice:2">{{email}} </span>
      <span><br><a data-test="showLess" (click)="showMore.next(false)">Show less</a></span>
    </ng-container>
  </ng-template>
</div>
