"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OktaGroupSelectorComponent = void 0;
const groupReducers = require("../../../../../components/group-selector/reducers");
const oktaReducers = require("../../../../reducers");
const findGroupsAction = require("../../../../../components/group-selector/actions/find-groups.actions");
const oktaActions = require("../../../../actions");
const uuid_1 = require("uuid");
const getGroupAction = require("../../../../../components/group-selector/actions/get-group.actions");
const operators_1 = require("rxjs/operators");
const okta_interfaces_1 = require("app-new/api-applications/services/integration/okta/okta.interfaces");
class OktaGroupSelectorComponent {
    constructor(overlayRef, translate, groupStore, oktaStore) {
        this.overlayRef = overlayRef;
        this.translate = translate;
        this.groupStore = groupStore;
        this.oktaStore = oktaStore;
        this.oktaGroups = [];
        this.FOLDERS = 'folders';
        this.DELETED_ITEMS = 'deleted items';
        this.OKTA_SCIM_INTEGRATION = okta_interfaces_1.defaults.ROOT_FOLDER_DESCRIPTION;
    }
    ngOnInit() {
        this.options = {
            displayField: 'description',
            hasChildrenField: 'folderCount',
            childrenField: 'folders'
        };
        this.showSpinner = true;
        this.loadingMessage = this.translate.instant('$I18N_API_INTEGRATION_OKTA_GROUP_SELECTOR.LOADING_MSG');
        this.groupStore.dispatch(new findGroupsAction.FindGroups({ source: 'ldap', query: this.OKTA_SCIM_INTEGRATION }));
        this.registerLdapGroups();
    }
    ngOnDestroy() {
        if (this.ldapGroupsSubscription) {
            this.ldapGroupsSubscription.unsubscribe();
        }
        if (this.getGroupsSubscription) {
            this.getGroupsSubscription.unsubscribe();
        }
    }
    registerLdapGroups() {
        this.findGroups$ = this.groupStore.select(groupReducers.findGroups);
        this.ldapGroupsSubscription = this.findGroups$.pipe(operators_1.take(2)).subscribe(groups => {
            if (groups.isLDAPDataLoaded && groups.ldapGroups) {
                if (groups.ldapGroups.length > 0) {
                    const rootFolder = groups.ldapGroups.filter((x) => x.description === this.OKTA_SCIM_INTEGRATION);
                    this.getNodeChildren(rootFolder[0]);
                    this.getGroups$ = this.groupStore.select(groupReducers.getGroup);
                    this.getGroupsSubscription = this.getGroups$.subscribe(children => {
                        if (children.group && children.group[0] && children.group[0][this.FOLDERS]) {
                            this.oktaStore.dispatch(new oktaActions.AddExtractedLdapFoldersAction([
                                ...rootFolder,
                                ...this.transformToOktaFolders(children.group[0].folders)
                            ]));
                        }
                        else {
                            this.oktaStore.dispatch(new oktaActions.AddExtractedLdapFoldersAction(rootFolder));
                        }
                    });
                }
                else {
                    this.oktaStore.dispatch(new oktaActions.AddLdapFolderToOktaAction({
                        id: uuid_1.v4(),
                        description: this.OKTA_SCIM_INTEGRATION,
                        source: 'ldap',
                        parentId: null,
                        userCount: 0,
                        folderCount: 0,
                        showCreateButton: true,
                        isNewFolderEntry: false,
                        folders: null
                    }));
                }
            }
        });
        this.oktaFolders$ = this.oktaStore.select(oktaReducers.getOktaIntegrationFolders);
        this.oktaFolders$.subscribe((folders) => {
            const mainFolders = [Object.assign({}, folders[0])];
            if (mainFolders[0] && mainFolders[0].folders && mainFolders[0].folders.length > 0) {
                mainFolders[0].folders = mainFolders[0].folders.filter((folder) => folder.description.toLowerCase() !== this.DELETED_ITEMS);
                if (mainFolders[0].folders.length === 0) {
                    mainFolders[0].folderCount = 0;
                    delete mainFolders[0].folders;
                }
            }
            this.oktaGroups = mainFolders;
            this.showSpinner = false;
        });
    }
    transformToOktaFolders(ldapGroups) {
        return ldapGroups.map(group => this.transformToOktaFolder(group));
    }
    transformToOktaFolder(folder) {
        return {
            id: folder.id,
            parentId: folder.parentId,
            description: folder.description,
            folderCount: folder.folderCount,
            source: folder.source,
            userCount: folder.userCount,
            showCreateButton: false,
            isNewFolderEntry: false,
            folders: null
        };
    }
    getNodeChildren(folderItem) {
        // get child groups
        this.groupStore.dispatch(new getGroupAction.GetGroup({ source: 'ldap', depth: 1, id: folderItem.id }));
    }
    onCreateNewFolder(newNode) {
        this.oktaStore.dispatch(new oktaActions.AddLdapFolderToOktaAction(newNode));
    }
    onCreatePlacholderFolder(parentId) {
        this.oktaStore.dispatch(new oktaActions.CreatePlaceholderFolderAction(parentId));
    }
    onCancelAddNewFolder() {
        this.oktaStore.dispatch(new oktaActions.CancelAddNewFolderAction());
        this.showSpinner = false;
    }
    onItemSelected(item) {
        this.oktaStore.dispatch(new oktaActions.SelectOktaIntegrationRootFolderAction(item));
        this.close(item);
    }
    close(result, $event) {
        if ($event) {
            $event.preventDefault();
        }
        this.overlayRef.close(result);
    }
}
exports.OktaGroupSelectorComponent = OktaGroupSelectorComponent;
