"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiGatewayDisableConfirmationModalComponent = void 0;
const disable_confirmation_modal_response_1 = require("./disable-confirmation-modal.response");
class ApiGatewayDisableConfirmationModalComponent {
    constructor(overlayRef, apiApp) {
        this.overlayRef = overlayRef;
        this.apiApp = apiApp;
    }
    get appName() {
        return this.apiApp.applicationName;
    }
    disableAnyway() {
        this.overlayRef.close({ state: disable_confirmation_modal_response_1.ApiGatewayDisableConfirmationState.DISABLE });
    }
    deleteNow() {
        this.overlayRef.close({ state: disable_confirmation_modal_response_1.ApiGatewayDisableConfirmationState.DELETE });
    }
    cancel() {
        this.overlayRef.close({ state: disable_confirmation_modal_response_1.ApiGatewayDisableConfirmationState.CANCEL });
    }
}
exports.ApiGatewayDisableConfirmationModalComponent = ApiGatewayDisableConfirmationModalComponent;
