<mc-layout-list-table
  keyTitle="$I18N_TTP_ATTACHMENT_PROTECTION_POLICY_LIST_PAGE_TITLE"
  keyDescription="$I18N_TTP_ATTACHMENT_POLICY_LIST_PAGE_DESC"
>
  <mc-help-container>
    <mc-help-link url="https://community.mimecast.com/docs/DOC-2202"></mc-help-link>
  </mc-help-container>
  <mc-table
    [isLoading]="isLoading$ | async"
    [data]="tableData$ | async"
    [columns]="columnList"
    (rowClick)="viewApPolicyDetails($event)"
    translatePrefix="$I18N_AP_POLICIES_LIST"
  >
    <button
      class="mc-table-actions btn btn-primary panel-half-margin-right"
      (click)="goToCreateNewPolicy()">
      {{ '$I18N_AP_CREATE_POLICY_CREATE_NEW_POLICY' | translate }}
    </button>
    <mc-filters (paginatorChange)="onPaginationChange($event)" [metadata]="metaData$ | async">
      <mc-filter-search
        class="mc-filter-search"
        (search)="onSearchPolicies($event)"
        (clear)="onSearchClear()"
        [placeholder]="'$I18N_ENDPOINTS_LIST_SEARCH_PLACEHOLDER' | translate"
      >
      </mc-filter-search>
    </mc-filters>
    <mc-column key="description">
      <mc-body-cell *mcBodyCellDef="let row">
        <div class="mc-status-text-container">
          <span>{{ row.description }}</span>
        </div>
      </mc-body-cell>
    </mc-column>
    <mc-column key="status">
      <mc-body-cell *mcBodyCellDef="let row">
        <div class="mc-status-text-container">
          <i *ngIf="row.status === 'Disabled'" class="fas fa-ban mc-grey-disabled"></i>
          <i *ngIf="row.status === 'Conflict'" class="fas fa-exclamation-triangle mc-orange"></i>
          <i *ngIf="row.status === 'Enabled'" class="fas fa-check-circle mc-ok-color"></i>
          <span>{{ row.status }}</span>
        </div>
      </mc-body-cell>
    </mc-column>
    <mc-column key="dateRange">
      <mc-body-cell *mcBodyCellDef="let row">
        <div class="mc-status-text-container">
          <span>{{ row.dateRange }}</span>
        </div>
      </mc-body-cell>
    </mc-column>
    <mc-column key="action">
      <mc-body-cell *mcBodyCellDef="let row">
        <div class="mc-status-text-container">
          <span>{{ getPolicyConfigurationType(row.action) | uppercase | translate }}</span>
        </div>
      </mc-body-cell>
    </mc-column>
    <mc-column key="checkOutbound">
      <mc-body-cell *mcBodyCellDef="let row">
        <div class="mc-status-text-container">
          <span *ngIf="row.checkOutbound && row.status !== 'Conflict'" class="fas fa-check mc-ok-color"></span>
          <span *ngIf="row.status === 'Conflict'">-</span>
        </div>
      </mc-body-cell>
    </mc-column>
    <mc-column key="checkInbound">
      <mc-body-cell *mcBodyCellDef="let row">
        <div class="mc-status-text-container">
          <span *ngIf="row.checkInbound && row.status !== 'Conflict'" class="fas fa-check mc-ok-color"></span>
          <span *ngIf="row.status === 'Conflict'">-</span>
        </div>
      </mc-body-cell>
    </mc-column>
    <mc-column key="checkJournal">
      <mc-body-cell *mcBodyCellDef="let row">
        <div class="mc-status-text-container">
          <span *ngIf="row.checkJournal && row.status !== 'Conflict'" class="glyphicon glyphicon-ok mc-ok-color"></span>
          <span *ngIf="row.status === 'Conflict'">-</span>
        </div>
      </mc-body-cell>
    </mc-column>
    <mc-column-actions key="dropdown-column" mcShowColumnConfig>
      <mc-row-actions *mcRowActions="let row">
        <li (click)="viewApPolicyDetails(row)" mcRowAction="$I18N_COMMON_BTN_VIEWDETAILS"></li>
        <li (click)="editDefinition(row)" mcRowAction="$I18N_COMMON_BTN_EDIT"></li>
        <li (click)="deleteDefinition(row)" mcRowAction="$I18N_COMMON_BTN_DELETE"></li>
      </mc-row-actions>
    </mc-column-actions>

    <mc-empty-results keyTitle="$I18N_AP_POLICIES_LIST_NO_DATA">
      <mc-empty-body>
        {{'$I18N_AP_POLICIES_LIST_NO_DATA_TEXT' | translate}}
        <button class="btn btn-link mc-empty-body-link" (click)="goToCreateNewPolicy()">
          {{'$I18N_AP_POLICIES_LIST_NO_DATA_CREATE_BUTTON' | translate}}
        </button>
      </mc-empty-body>
    </mc-empty-results>
  </mc-table>
</mc-layout-list-table>
