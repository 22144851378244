"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiIntegrationCortexNotificationsStepComponent = void 0;
const core_1 = require("@angular/core");
class ApiIntegrationCortexNotificationsStepComponent {
    constructor() {
        this.onDeleteEmail = new core_1.EventEmitter();
        this.onVerifyAndAddEmail = new core_1.EventEmitter();
        this.onDeleteGroup = new core_1.EventEmitter();
        this.onAddGroup = new core_1.EventEmitter();
    }
    deleteGroup(selectedGroup) {
        this.onDeleteGroup.emit(selectedGroup);
    }
    verifyAndAddEmail(email) {
        this.onVerifyAndAddEmail.emit(email);
    }
    deleteEmail(email) {
        this.onDeleteEmail.emit(email);
    }
    addGroup(selectedGroup) {
        this.onAddGroup.emit(selectedGroup);
    }
    isValid() {
        return this.groupsList.length > 0 || this.emails.length > 0;
    }
}
exports.ApiIntegrationCortexNotificationsStepComponent = ApiIntegrationCortexNotificationsStepComponent;
