"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.QueueReviewerFactory = void 0;
const queue_reviewer_builder_1 = require("../model/queue/queue-reviewer.builder");
const ɵ0 = (reviewer) => new queue_reviewer_builder_1.ReviewerBuilder()
    .withEmailAddress(reviewer['emailAddress'])
    .withAddressType(reviewer['addressType'])
    .withDomain(reviewer['domain'])
    .withName(reviewer['name'])
    .withAlias(reviewer['alias'])
    .withId(reviewer['id'])
    .withSource(reviewer['source'])
    .build();
exports.ɵ0 = ɵ0;
exports.QueueReviewerFactory = {
    BUILD_QUEUE_REVIEWER: ɵ0
};
