"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SetRuleDetailsStateAction = exports.SetRuleDetailsPaginationInfoAction = exports.UpdateRuleDetailsPaginationInfoAction = exports.CleanRuleDetailsAction = exports.GetRuleDetailsActionSuccess = exports.GetRuleDetailsActionFail = exports.GetRuleDetailsAction = exports.RuleDetailsActions = void 0;
var RuleDetailsActions;
(function (RuleDetailsActions) {
    RuleDetailsActions["UPDATE_RULE_DETAILS_PAGINATION_INFO"] = "[SupervisionRuleDetails] Update Rule Details Pagination Info";
    RuleDetailsActions["SET_RULE_DETAILS_PAGINATION_INFO"] = "[SupervisionRuleDetails] Set Rule Details Pagination Info";
    RuleDetailsActions["GET_RULE_DETAILS"] = "[SupervisionRuleDetails] Get Rule Details";
    RuleDetailsActions["GET_RULE_DETAILS_SUCCESS"] = "[SupervisionRuleDetails] Get Rule Details Success";
    RuleDetailsActions["GET_RULE_DETAILS_FAIL"] = "[SupervisionRuleDetails] Get Rule Details Fail";
    RuleDetailsActions["CLEAN_RULE_DETAILS"] = "[SupervisionRuleDetails] Clean Rule Details";
    RuleDetailsActions["SET_RULE_DETAILS_STATE"] = "[SupervisionRuleDetails] Update Rule Details State";
})(RuleDetailsActions = exports.RuleDetailsActions || (exports.RuleDetailsActions = {}));
class GetRuleDetailsAction {
    constructor(rule) {
        this.rule = rule;
        this.type = RuleDetailsActions.GET_RULE_DETAILS;
    }
}
exports.GetRuleDetailsAction = GetRuleDetailsAction;
class GetRuleDetailsActionFail {
    constructor(failure) {
        this.failure = failure;
        this.type = RuleDetailsActions.GET_RULE_DETAILS_FAIL;
    }
}
exports.GetRuleDetailsActionFail = GetRuleDetailsActionFail;
class GetRuleDetailsActionSuccess {
    constructor(rule) {
        this.rule = rule;
        this.type = RuleDetailsActions.GET_RULE_DETAILS_SUCCESS;
    }
}
exports.GetRuleDetailsActionSuccess = GetRuleDetailsActionSuccess;
class CleanRuleDetailsAction {
    constructor() {
        this.type = RuleDetailsActions.CLEAN_RULE_DETAILS;
    }
}
exports.CleanRuleDetailsAction = CleanRuleDetailsAction;
class UpdateRuleDetailsPaginationInfoAction {
    constructor(currentRule, listRules) {
        this.currentRule = currentRule;
        this.listRules = listRules;
        this.type = RuleDetailsActions.UPDATE_RULE_DETAILS_PAGINATION_INFO;
    }
}
exports.UpdateRuleDetailsPaginationInfoAction = UpdateRuleDetailsPaginationInfoAction;
class SetRuleDetailsPaginationInfoAction {
    constructor(previousRule, currentRule, nextRule, currentRuleIndex, totalNumberRules) {
        this.previousRule = previousRule;
        this.currentRule = currentRule;
        this.nextRule = nextRule;
        this.currentRuleIndex = currentRuleIndex;
        this.totalNumberRules = totalNumberRules;
        this.type = RuleDetailsActions.SET_RULE_DETAILS_PAGINATION_INFO;
    }
}
exports.SetRuleDetailsPaginationInfoAction = SetRuleDetailsPaginationInfoAction;
class SetRuleDetailsStateAction {
    constructor(ruleId, ruleState) {
        this.ruleId = ruleId;
        this.ruleState = ruleState;
        this.type = RuleDetailsActions.SET_RULE_DETAILS_STATE;
    }
}
exports.SetRuleDetailsStateAction = SetRuleDetailsStateAction;
