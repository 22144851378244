<div class="mc-layout-detail-wizard mc-layout-full-height mc-flex-container mc-flex-column no-padding-bottom"
     [ngClass]="extendClass">
  <div class="mc-flex-scroll-vertical mc-layout-full-height">
    <div class="mc-layout-header">

      <mc-page-header [title]="keyTitle"
                      [subtitle]="keyDescription">
        <div class="mc-flex-container"
             [hidden]="!kbUrl || !mcHelpArea.nativeElement.children.length">

          <div class="mc-flex-shrink mc-layout-header-item mc-helper-item-container"
               *ngIf="kbUrl && !mcHelpArea.nativeElement.children.length"><a target="_blank"
               [href]="kbUrl"
               class="mc-helper-item btn btn-secondary"
               placement="bottom"
               containerClass="mc-tooltip-override mc-layout-header-help"
               [tooltip]="'$I18N_COMPONENTS_LAYOUTS_COMMON.HEADER.HELP' | translate"><i class="fas fa-question-circle"></i></a></div>

          <div #mcHelpAreaHtml
               [hidden]="!mcHelpArea.nativeElement.children.length"
               class="mc-flex-shrink">
            <!-- TODO: Need to integrated UMER Component -->
            <ng-content select="mc-help-container"></ng-content>
          </div>

        </div>

      </mc-page-header>

    </div>

    <div #mcIdExtraContainerArea
         class="mc-layout-extra-container"
         [hidden]="!mcExtraContainerArea.nativeElement.children.length">
      <ng-content select="mc-extra-container"></ng-content>
    </div>

    <div class="mc-layout-body-container">
      <ng-content select="mc-wizard"></ng-content>
      <ng-content select="mc-tab-group"></ng-content>
    </div>

  </div>

  <div #mcLayoutFooterAreaHtml
       class="mc-layout-flex-footer-container full-width"
       [hidden]="!mcLayoutFooterArea.nativeElement.children.length">
    <ng-content select="mc-footer-container"></ng-content>
  </div>

</div>
