<div class="mc-services-connector-wizard-step-container">
  <mc-wizard-step-header label= "{{ (editFlow ? 
    '$I18N_CREATE_CONNECTOR_WIZARD_SUMMARY_STEP.EDIT_DESCRIPTION' : 
    '$I18N_CREATE_CONNECTOR_WIZARD_SUMMARY_STEP.CREATE_DESCRIPTION') 
    | translate }}"> 
  </mc-wizard-step-header>

  <div class="mc-detailed-list">
    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_CREATE_CONNECTOR_WIZARD_SUMMARY_STEP.FIELD_PRODUCT' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ getStepDataById(1).product?.name }}</div>
    </div>

    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_CREATE_CONNECTOR_WIZARD_SUMMARY_STEP.FIELD_PROVIDER' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{ '$I18N_CONNECTORS.PROVIDERS.' + getStepDataById(2).provider?.type | uppercase | translate }}</div>
    </div>

    <ng-container *ngIf="isGwsConnector()">
      <div class="row mc-detailed-list-row">
        <div class="col-xs-3 mc-detailed-list-label">
          {{ '$I18N_CREATE_CONNECTOR_WIZARD_SUMMARY_STEP.FIELD_GOOGLE_CUSTOMER_ID' | translate }}
        </div>
        <div class="mc-detailed-list-column">{{ getStepDataById(6).customerId }}</div>
      </div>

      <div class="row mc-detailed-list-row">
        <div class="col-xs-3 mc-detailed-list-label">
          {{ '$I18N_CREATE_CONNECTOR_WIZARD_SUMMARY_STEP.FIELD_MAILBOX' | translate }}
        </div>
        <div class="mc-detailed-list-column">{{ getStepDataById(6).mailbox }}</div>
      </div>
    </ng-container>

    <ng-container *ngIf="isMsConnector()">
      <div class="row mc-detailed-list-row">
        <div class="col-xs-3 mc-detailed-list-label">
          {{ '$I18N_CREATE_CONNECTOR_WIZARD_SUMMARY_STEP.FIELD_AUTHENTICATION' | translate }}
        </div>
        <div class="mc-detailed-list-column">
          <span class="fas fa-check-circle text-success"></span>
          {{ '$I18N_CREATE_CONNECTOR_WIZARD_SUMMARY_STEP.PERMISSIONS_GRANTED' | translate }}</div>
      </div>
    </ng-container>

    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_CREATE_CONNECTOR_WIZARD_SUMMARY_STEP.FIELD_NAME' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ getStepDataById(4).name }}</div>
    </div>

    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ '$I18N_CREATE_CONNECTOR_WIZARD_SUMMARY_STEP.FIELD_DESCRIPTION' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{ getStepDataById(4).description || ('$I18N_CREATE_CONNECTOR_WIZARD_SUMMARY_STEP.PRODUCT_DESCRIPTION_NOT_PROVIDED' | translate) }}
      </div>
    </div>
  </div>
</div>
