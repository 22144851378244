"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const sync_and_recover_overview_modal_service_1 = require("app-new/sync-recover/sync-and-recover-overview/services/sync-and-recover-overview-modal.service");
require("./archive-task-detail/archive-task-detail"); //services.services.backup.archive-task-detail
require("./archive-task-edit/archive-task-edit"); //services.services.backup.archive-task-edit
require("./archive-task-list/archive-task-list"); //services.services.backup.archive-task-list
(function () {
    'use strict';
    /* ======================================================== *\

     = Archive tasks

     \* ======================================================== */
    angular.module('services.services.backup.archive-task', [
        'services.services.backup.archive-task-detail',
        'services.services.backup.archive-task-edit',
        'services.services.backup.archive-task-list'
    ]).factory('syncAndRecoverOverviewModalService', static_1.downgradeInjectable(sync_and_recover_overview_modal_service_1.SyncAndRecoverOverviewModalService));
}());
