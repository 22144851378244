<div class="mc-device-group-details-step">
  <mc-wizard-step-header label="{{getHeader()}}">
  </mc-wizard-step-header>

  <div>
    <form
      class="form-horizontal"
      mcDefaultLabelClass="col-sm-3"
      mcDefaultControlContainerClass="col-sm-9"
      [formGroup]="deviceGroupPage"
    >
      <div class="panel-padding-bottom mc-group-name">
        <mc-text-field
          errorPrefix="$I18N_DEVICE_GROUPS_LIST.WIZARD.STEPS.DETAILS.ERROR"
          label="$I18N_DEVICE_GROUPS_LIST.WIZARD.STEPS.DETAILS.NAME"
          formControlName="name"
          [setFocus]="true"
          [maxLength]="300"
          required
        ></mc-text-field>
      </div>
      <div class="mc-group-name">
        <mc-text-area-field
          errorPrefix="$I18N_DEVICE_GROUPS_LIST.WIZARD.STEPS.DETAILS.ERROR"
          label="$I18N_DEVICE_GROUPS_LIST.WIZARD.STEPS.DETAILS.DESCRIPTION"
          formControlName="description"
          [setFocus]="false"
          [maxLength]="1000"
        ></mc-text-area-field>
      </div>
    </form>
  </div>

</div>
