<div class="threat-event-panel-icons">
  <div class="threat-event-detail-box">
    <div class="threat-event-detail-box-icon">
      <mc-swg-threat-event-status-icon [actions]="threatEvent.actions"></mc-swg-threat-event-status-icon>
    </div>
    <b>{{ '$I18N_SWG_THREAT_EVENT_SUMMARY.LABELS.STATUS' | translate }}</b>
    <mc-swg-threat-event-status [actions]="threatEvent.actions"></mc-swg-threat-event-status>
  </div>

  <div class="threat-event-detail-box">
    <div class="threat-event-detail-box-icon">
      <i class="fas fa-biohazard" *ngIf="hasMalware()"></i>
      <i class="fas fa-mail-bulk" *ngIf="hasPhishing()"></i>
    </div>
    <b>{{ "$I18N_SWG_THREAT_EVENT_SUMMARY.LABELS.THREAT_TYPE" | translate }}</b>
    <mc-swg-threat-event-type
      [types]="threatEvent.threatTypes"
      [showIcon]="false"
    ></mc-swg-threat-event-type>
  </div>

  <div class="threat-event-detail-box">
    <div class="threat-event-detail-box-icon">
      <i class="fas fa-users"></i>
    </div>
    <b>{{ "$I18N_SWG_THREAT_EVENT_SUMMARY.LABELS.EXPOSED_USERS" | translate }}</b>
    <span>{{ getValueOrNA('cloudInfo.exposedUsers') }}</span>
  </div>
</div>

<mc-collapsable-panel class="threat-event-panel" header="$I18N_SWG_THREAT_EVENT_SUMMARY.TITLE" [isExpanded]="true">
  <mc-inline-notification
    class="threat-event-panel-notification"
    [notification]="notification">
  </mc-inline-notification>

  <div class="mc-detailed-list">
    <mc-value-field label="$I18N_SWG_THREAT_EVENT_SUMMARY.LABELS.FILENAME"
                    [value]="threatEvent.filename">
    </mc-value-field>

    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ "$I18N_SWG_THREAT_EVENT_SUMMARY.LABELS.STATUS" | translate }}
      </div>
      <div class="mc-detailed-list-column">
        <mc-swg-threat-event-status
          [actions]="threatEvent.actions"
          [showIcon]="true"
        ></mc-swg-threat-event-status>
      </div>
    </div>

    <mc-value-field label="$I18N_SWG_THREAT_EVENT_SUMMARY.LABELS.DATETIME"
                    [value]="threatEvent.timestamp | date: dateFormat">
    </mc-value-field>

    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ "$I18N_SWG_THREAT_EVENT_SUMMARY.LABELS.THREAT_TYPE" | translate }}
      </div>
      <div class="mc-detailed-list-column">
        <mc-swg-threat-event-type [types]="threatEvent.threatTypes"></mc-swg-threat-event-type>
      </div>
    </div>

    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ "$I18N_SWG_THREAT_EVENT_SUMMARY.LABELS.CLOUD_APP" | translate }}
      </div>
      <div class="mc-detailed-list-column" *ngIf="threatEvent.apiProvider">
        <mc-provider-icon [providerType]="threatEvent.apiProvider"></mc-provider-icon>
        {{ '$I18N_CONNECTORS.PROVIDERS.' + threatEvent.apiProvider.toUpperCase() | translate }}
      </div>
      <div class="mc-detailed-list-column" *ngIf="!threatEvent.apiProvider">
        {{ '$I18N_SWG_THREAT_EVENT_SUMMARY.NOT_AVAILABLE' | translate }}
      </div>
    </div>

    <mc-value-field label="$I18N_SWG_THREAT_EVENT_SUMMARY.LABELS.GUEST_EXTERNAL"
                    [value]="getValueOrNA('cloudInfo.guestsOrExternals')">
    </mc-value-field>

    <mc-value-field label="$I18N_SWG_THREAT_EVENT_SUMMARY.LABELS.FILE_TYPE"
                    [value]="threatEvent.fileTypes?.join(', ')">
    </mc-value-field>

    <mc-value-field label="$I18N_SWG_THREAT_EVENT_SUMMARY.LABELS.HASH"
                    [value]="threatEvent.hash">
    </mc-value-field>

    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ "$I18N_SWG_THREAT_EVENT_SUMMARY.LABELS.FILE_LOCATION" | translate }}
      </div>
      <div class="mc-detailed-list-column" *ngIf="threatEvent.cloudInfo?.fileLocation">
        <mc-swg-threat-event-file-location [fileLocation]="threatEvent.cloudInfo?.fileLocation"></mc-swg-threat-event-file-location>
      </div>
      <div class="mc-detailed-list-column" *ngIf="!threatEvent.cloudInfo?.fileLocation">-</div>
    </div>

    <mc-value-field label="$I18N_SWG_THREAT_EVENT_SUMMARY.LABELS.FILE_OWNER"
                    [value]="threatEvent.fileOwner">
    </mc-value-field>

    <mc-value-field label="$I18N_SWG_THREAT_EVENT_SUMMARY.LABELS.SHARING_DETAILS"
                    [value]="getValueOrNA('cloudInfo.fileLocation.sharingType')">
    </mc-value-field>
  </div>
</mc-collapsable-panel>
