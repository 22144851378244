<mc-layout-list-tabs keyTitle="$I18N_ARCHIVE_VIEWER_TITLE"
                     keyDescription="$I18N_ARCHIVE_VIEWER_SUB_TITLE"
                     extendClass="mc-list-archive-viewer">
  <mc-extra-container>

    <!-- Reason -->
    <form [formGroup]="formReason" novalidate>
      <div class="row">
        <div class="col-xs-6 col-xs-offset-0">
          <mc-text-field #reason label="$I18N_ARCHIVE_VIEWER_SEARCH_LABEL" formControlName="reason" required
                         placeholder="$I18N_ARCHIVE_VIEWER_SEARCH_REASON" errorPrefix="$I18N_ARCHIVE_VIEWER_SEARCH_LABEL"
                         data-test="Search_Reason"></mc-text-field>
        </div>
      </div>
    </form>
  </mc-extra-container>

  <mc-tab-group>

    <!-- Search by token tab -->
    <mc-tab name="{{'$I18N_ARCHIVE_VIEWER_SEARCH_TAB_TOKEN' | translate }}" data-test="search_by_token">
      <div class="panel-body no-padding-bottom">

        <mc-search-by-token-tab-wrapper
          [formReasonValueChanges]="formReason.valueChanges | async" [reasonValid]="formReason.valid">
        </mc-search-by-token-tab-wrapper>

      </div>
    </mc-tab>

    <!-- Search by content tab -->
    <mc-tab name="{{'$I18N_ARCHIVE_VIEWER_SEARCH_TAB_CONTENT' | translate}}" data-test="search_by_content">
      <div class="panel-body no-padding-bottom">

        <mc-search-by-content-tab-wrapper
          [formReasonValueChanges]="formReason.valueChanges | async" [reasonValid]="formReason.valid">
        </mc-search-by-content-tab-wrapper>

      </div>
    </mc-tab>
  </mc-tab-group>

</mc-layout-list-tabs>
