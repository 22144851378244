"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BulkProcessingExportAction = exports.BulkProcessingRetryEmailsFailAction = exports.BulkProcessingRetryEmailsSuccessAction = exports.BulkProcessingRetryEmailsAction = exports.BulkProcessingEarlyBounceEmailsFailAction = exports.BulkProcessingEarlyBounceEmailsSuccessAction = exports.BulkProcessingEarlyBounceEmailsAction = exports.BulkProcessingRejectEmailsFailAction = exports.BulkProcessingRejectEmailsSuccessAction = exports.BulkProcessingRejectEmailsAction = exports.BulkProcessingFailAction = exports.BulkProcessingSuccessAction = exports.BulkProcessingRequestAction = exports.BulkProcessingPaginationAction = exports.BulkProcessingSelectRowAction = exports.BulkProcessingAsidePaginationAction = exports.BulkProcessingCloseDetailAction = exports.BulkProcessingRefreshAction = exports.BULK_PROCESSING_EXPORT = exports.BULK_PROCESSING_EARLY_BOUNCE_EMAILS_FAIL = exports.BULK_PROCESSING_EARLY_BOUNCE_EMAILS_SUCCESS = exports.BULK_PROCESSING_EARLY_BOUNCE_EMAILS = exports.BULK_PROCESSING_RETRY_EMAILS_FAIL = exports.BULK_PROCESSING_RETRY_EMAILS_SUCCESS = exports.BULK_PROCESSING_RETRY_EMAILS = exports.BULK_PROCESSING_REJECT_EMAILS_FAIL = exports.BULK_PROCESSING_REJECT_EMAILS_SUCCESS = exports.BULK_PROCESSING_REJECT_EMAILS = exports.BULK_PROCESSING_FAIL = exports.BULK_PROCESSING_SUCCESS = exports.BULK_PROCESSING_REQUEST = exports.BULK_PROCESSING_PAGINATION = exports.BULK_PROCESSING_SELECT_ROW = exports.BULK_PROCESSING_ASIDE_PAGINATION = exports.BULK_PROCESSING_CLOSE_DETAIL = exports.BULK_PROCESSING_REFRESH = void 0;
exports.BULK_PROCESSING_REFRESH = '[Message Center /  Bulk Processing]  Bulk Processing Refresh';
exports.BULK_PROCESSING_CLOSE_DETAIL = '[Message Center /  Bulk Processing]  Bulk Processing Close Message Detail';
exports.BULK_PROCESSING_ASIDE_PAGINATION = '[Message Center /  Bulk Processing]  Bulk Processing Aside Pagination';
exports.BULK_PROCESSING_SELECT_ROW = '[Message Center /  Bulk Processing]  Bulk Processing Select Row';
exports.BULK_PROCESSING_PAGINATION = '[Message Center / Bulk Processing] Bulk Processing Pagination';
exports.BULK_PROCESSING_REQUEST = '[Message Center / Bulk Processing] Request Get Bulk Processing';
exports.BULK_PROCESSING_SUCCESS = '[Message Center / Bulk Processing] Get Bulk Processing Success';
exports.BULK_PROCESSING_FAIL = '[Message Center / Bulk Processing] Get Bulk Processing Fail';
exports.BULK_PROCESSING_REJECT_EMAILS = '[Message Center / Bulk Processing] Reject Bulk Processing Emails';
exports.BULK_PROCESSING_REJECT_EMAILS_SUCCESS = '[Message Center / Bulk Processing] Reject Bulk Processing Emails Success';
exports.BULK_PROCESSING_REJECT_EMAILS_FAIL = '[Message Center / Bulk Processing] Reject Bulk Processing Emails Fail';
exports.BULK_PROCESSING_RETRY_EMAILS = '[Message Center / Bulk Processing] Retry Bulk Processing Emails';
exports.BULK_PROCESSING_RETRY_EMAILS_SUCCESS = '[Message Center / Bulk Processing] Retry Bulk Processing Emails Success';
exports.BULK_PROCESSING_RETRY_EMAILS_FAIL = '[Message Center / Bulk Processing] Retry Bulk Processing Emails Fail';
exports.BULK_PROCESSING_EARLY_BOUNCE_EMAILS = '[Message Center / Bulk Processing] Early Bounce Bulk Processing Emails';
exports.BULK_PROCESSING_EARLY_BOUNCE_EMAILS_SUCCESS = '[Message Center / Bulk Processing] Early Bounce Bulk Processing Emails Success';
exports.BULK_PROCESSING_EARLY_BOUNCE_EMAILS_FAIL = '[Message Center / Bulk Processing] Early Bounce Bulk Processing Emails Fail';
exports.BULK_PROCESSING_EXPORT = '[Message Center / Bulk Processing] Export Bulk Processing Emails';
class BulkProcessingRefreshAction {
    constructor() {
        this.type = exports.BULK_PROCESSING_REFRESH;
    }
}
exports.BulkProcessingRefreshAction = BulkProcessingRefreshAction;
class BulkProcessingCloseDetailAction {
    constructor() {
        this.type = exports.BULK_PROCESSING_CLOSE_DETAIL;
    }
}
exports.BulkProcessingCloseDetailAction = BulkProcessingCloseDetailAction;
class BulkProcessingAsidePaginationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.BULK_PROCESSING_ASIDE_PAGINATION;
    }
}
exports.BulkProcessingAsidePaginationAction = BulkProcessingAsidePaginationAction;
class BulkProcessingSelectRowAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.BULK_PROCESSING_SELECT_ROW;
    }
}
exports.BulkProcessingSelectRowAction = BulkProcessingSelectRowAction;
class BulkProcessingPaginationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.BULK_PROCESSING_PAGINATION;
    }
}
exports.BulkProcessingPaginationAction = BulkProcessingPaginationAction;
class BulkProcessingRequestAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.BULK_PROCESSING_REQUEST;
    }
}
exports.BulkProcessingRequestAction = BulkProcessingRequestAction;
class BulkProcessingSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.BULK_PROCESSING_SUCCESS;
    }
}
exports.BulkProcessingSuccessAction = BulkProcessingSuccessAction;
class BulkProcessingFailAction {
    constructor() {
        this.type = exports.BULK_PROCESSING_FAIL;
    }
}
exports.BulkProcessingFailAction = BulkProcessingFailAction;
class BulkProcessingRejectEmailsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.BULK_PROCESSING_REJECT_EMAILS;
    }
}
exports.BulkProcessingRejectEmailsAction = BulkProcessingRejectEmailsAction;
class BulkProcessingRejectEmailsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.BULK_PROCESSING_REJECT_EMAILS_SUCCESS;
    }
}
exports.BulkProcessingRejectEmailsSuccessAction = BulkProcessingRejectEmailsSuccessAction;
class BulkProcessingRejectEmailsFailAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.BULK_PROCESSING_REJECT_EMAILS_FAIL;
    }
}
exports.BulkProcessingRejectEmailsFailAction = BulkProcessingRejectEmailsFailAction;
class BulkProcessingEarlyBounceEmailsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.BULK_PROCESSING_EARLY_BOUNCE_EMAILS;
    }
}
exports.BulkProcessingEarlyBounceEmailsAction = BulkProcessingEarlyBounceEmailsAction;
class BulkProcessingEarlyBounceEmailsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.BULK_PROCESSING_EARLY_BOUNCE_EMAILS_SUCCESS;
    }
}
exports.BulkProcessingEarlyBounceEmailsSuccessAction = BulkProcessingEarlyBounceEmailsSuccessAction;
class BulkProcessingEarlyBounceEmailsFailAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.BULK_PROCESSING_EARLY_BOUNCE_EMAILS_FAIL;
    }
}
exports.BulkProcessingEarlyBounceEmailsFailAction = BulkProcessingEarlyBounceEmailsFailAction;
class BulkProcessingRetryEmailsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.BULK_PROCESSING_RETRY_EMAILS;
    }
}
exports.BulkProcessingRetryEmailsAction = BulkProcessingRetryEmailsAction;
class BulkProcessingRetryEmailsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.BULK_PROCESSING_RETRY_EMAILS_SUCCESS;
    }
}
exports.BulkProcessingRetryEmailsSuccessAction = BulkProcessingRetryEmailsSuccessAction;
class BulkProcessingRetryEmailsFailAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.BULK_PROCESSING_RETRY_EMAILS_FAIL;
    }
}
exports.BulkProcessingRetryEmailsFailAction = BulkProcessingRetryEmailsFailAction;
class BulkProcessingExportAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.BULK_PROCESSING_EXPORT;
    }
}
exports.BulkProcessingExportAction = BulkProcessingExportAction;
