"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchBoxComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const components_1 = require("@mimecast-ui/components");
const moment = require("moment");
class SearchBoxComponent {
    constructor(fb, translateService) {
        this.fb = fb;
        this.translateService = translateService;
        this.onSearch = new core_1.EventEmitter();
        this.dateRangePickerConfig = components_1.dateRangePickerDefaultConfig;
        this.TRANSLATION_KEY = '$I18N_SECURE_MESSAGING_SEARCH_BOX.DATE_RANGE_PICKER.';
        this.dateRangePickerConfig = Object.assign(Object.assign({}, components_1.dateRangePickerDefaultConfig), { dateRanges: [
                {
                    id: '24_HOURS',
                    label: this.translateService.instant(this.TRANSLATION_KEY + 'PAST_24_HOURS'),
                    rangeFn: this.getRangeFunctionWithDifference(1)
                },
                {
                    id: '48_HOURS',
                    label: this.translateService.instant(this.TRANSLATION_KEY + 'PAST_48_HOURS'),
                    rangeFn: this.getRangeFunctionWithDifference(2)
                },
                {
                    id: '7_DAYS',
                    label: this.translateService.instant(this.TRANSLATION_KEY + 'PAST_7_DAYS'),
                    rangeFn: this.getRangeFunctionWithDifference(7)
                },
                {
                    id: '30_DAYS',
                    label: this.translateService.instant(this.TRANSLATION_KEY + 'PAST_30_DAYS'),
                    rangeFn: this.getRangeFunctionWithDifference(30)
                },
                {
                    id: 'CUSTOM',
                    label: this.translateService.instant(this.TRANSLATION_KEY + 'CUSTOM_RANGE')
                }
            ] });
    }
    ngOnInit() {
        this.form = this.fb.group({
            from: ['', forms_1.Validators.minLength(3)],
            to: ['', forms_1.Validators.minLength(3)],
            subject: ['', forms_1.Validators.minLength(3)],
            dateRange: [this.dateRangePickerConfig.dateRanges[1], []]
        });
    }
    onSubmit() {
        const request = {
            dateFrom: this.form
                .get('dateRange')
                .value.rangeFn()
                .start.format(components_1.API_DATE_FORMAT),
            dateTo: this.form
                .get('dateRange')
                .value.rangeFn()
                .end.format(components_1.API_DATE_FORMAT)
        };
        if (this.form.get('from').value) {
            request['from'] = this.form.get('from').value;
        }
        if (this.form.get('to').value) {
            request['to'] = this.form.get('to').value;
        }
        if (this.form.get('subject').value) {
            request['subject'] = this.form.get('subject').value;
        }
        this.onSearch.emit(request);
    }
    clear() {
        this.form.reset();
        this.form.get('dateRange').setValue(this.dateRangePickerConfig.dateRanges[1]);
    }
    handleKeyUp(e) {
        if (e.keyCode === 13 && !this.form.invalid) {
            this.onSubmit();
        }
    }
    getRangeFunctionWithDifference(difference, period = 'days') {
        return () => {
            return {
                start: moment().subtract(difference, period),
                end: moment()
            };
        };
    }
}
exports.SearchBoxComponent = SearchBoxComponent;
