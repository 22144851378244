"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSelectedRow = exports.reducer = exports.initialState = void 0;
const edit_domain_actions_1 = require("../../actions/edit-domain.actions");
exports.initialState = {
    selectedRow: {
        domainName: '',
        comment: '',
        id: ''
    }
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case edit_domain_actions_1.EDIT_DOMAIN: {
            return Object.assign(Object.assign({}, state), { selectedRow: action.payLoad });
        }
        case edit_domain_actions_1.SELECTED_ROW: {
            return Object.assign(Object.assign({}, state), { 
                // @ts-ignore
                selectedRow: action.selectedRow });
        }
        default:
            return state;
    }
}
exports.reducer = reducer;
const getSelectedRow = (state) => state.selectedRow;
exports.getSelectedRow = getSelectedRow;
