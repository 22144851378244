"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DetailsComponent = void 0;
const forms_1 = require("@angular/forms");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const components_1 = require("@mimecast-ui/components");
const select_mailbox_group_component_1 = require("app-new/archive/shared/groups/asides/select-mailbox-group/select-mailbox-group.component");
const reducers_1 = require("../../../../reducers");
const step_base_component_1 = require("../../../step.base.component");
const backup_model_1 = require("../../backup.model");
class DetailsComponent extends step_base_component_1.StepBaseDirective {
    constructor(store, fb, translateService, asideService, cd) {
        super(store, fb, 1, backup_model_1.wizardID);
        this.store = store;
        this.fb = fb;
        this.translateService = translateService;
        this.asideService = asideService;
        this.cd = cd;
        this.translatePrefix = '$I18N_ONEDRIVE_BACKUP_WIZARD.STEPS.DETAILS';
        this.destroy$ = new rxjs_1.Subject();
        this.connectors = [];
        this.selectedGroup = [];
        this.connectorPlaceholder = {
            label: this.translateService.instant(`${this.translatePrefix}.FORM.CONNECTOR_PLACEHOLDER`),
            value: ''
        };
        this.connectors = [this.connectorPlaceholder];
        this.form = this.fb.group({
            name: [
                '',
                [
                    forms_1.Validators.required,
                    this.noWhitespaceValidator,
                    forms_1.Validators.minLength(3),
                    forms_1.Validators.maxLength(100)
                ]
            ],
            group: [null, forms_1.Validators.required],
            connector: ['', forms_1.Validators.required]
        });
    }
    ngOnInit() {
        super.ngOnInit();
        this.store
            .select(reducers_1.getOneDriveConnectors)
            .pipe(operators_1.takeUntil(this.destroy$))
            .subscribe((connectors) => {
            this.connectors = [
                this.connectorPlaceholder,
                ...connectors.map(connector => ({ label: connector.name, value: connector.id }))
            ];
            if (this.connectors.length === 2) {
                this.defaultSelectedConnector = connectors[0].id;
                this.form.get('connector').setValue(this.defaultSelectedConnector);
            }
        });
        if (this.group) {
            this.selectedGroup = [{ id: this.group.id, name: this.group.description }];
        }
        const selectedConnector = this.connector ? this.connector.id : this.defaultSelectedConnector;
        this.defaultSelectedConnector = selectedConnector;
        this.form.patchValue({
            name: this.name,
            group: this.selectedGroup.length === 0 ? null : this.selectedGroup[0],
            connector: selectedConnector
        });
        this.form
            .get('connector')
            .valueChanges.pipe(operators_1.takeUntil(this.destroy$))
            .subscribe(newConnector => {
            this.defaultSelectedConnector = newConnector;
        });
    }
    ngOnDestroy() {
        super.ngOnDestroy();
        this.destroy$.next(true);
        this.destroy$.complete();
    }
    get subTitle() {
        if (this.isEditMode) {
            return '$I18N_ONEDRIVE_BACKUP_WIZARD.STEPS.DETAILS.EDIT_SUBTITLE';
        }
        return '$I18N_ONEDRIVE_BACKUP_WIZARD.STEPS.DETAILS.CREATE_SUBTITLE';
    }
    getStepState() {
        const formValid = this.form.valid;
        if (formValid) {
            const connectorObjForId = this.connectors.find(v => v.value === this.form.get('connector').value);
            return {
                valid: formValid,
                data: Object.assign(Object.assign({}, this.form.value), { connector: connectorObjForId })
            };
        }
        return super.getStepState();
    }
    noWhitespaceValidator(control) {
        const noWhitespace = (control.value || '').trim().length !== (control.value || '').length;
        const isValid = !noWhitespace;
        return isValid ? null : { whitespace: true };
    }
    selectGroup() {
        const asideConfig = new components_1.AsideConfig();
        asideConfig.size = 'md';
        asideConfig.data = { onlyLocalGroups: true, minCharacters: 3 };
        this.aside = this.asideService.open(select_mailbox_group_component_1.SelectMailboxGroupComponent, asideConfig);
        this.aside
            .beforeClose()
            .pipe(operators_1.take(1))
            .subscribe(group => {
            if (group) {
                this.addGroup({ id: group.id, name: group.description });
            }
        });
    }
    get groupHelpText() {
        if (this.selectedGroup.length) {
            return null;
        }
        return this.translateService.instant(`${this.translatePrefix}.FORM.GROUP_HELP`);
    }
    addGroup(group) {
        this.selectedGroup = [group];
        this.onGroupChange();
    }
    removeGroup() {
        this.selectedGroup = [];
        this.onGroupChange();
    }
    onGroupChange() {
        this.form.get('group').setValue(this.selectedGroup.length === 0 ? null : this.selectedGroup[0]);
        this.cd.detectChanges();
    }
}
exports.DetailsComponent = DetailsComponent;
