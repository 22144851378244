<mc-modal-header
  headerTitle="{{
    '$I18N_API_GATEWAY_DELETE_CONFIRMATION_MODAL.HEADER' | translate: { appName: appName }
  }}"
>
</mc-modal-header>

<mc-modal-body>
  <p>{{ '$I18N_API_GATEWAY_DELETE_CONFIRMATION_MODAL.MESSAGE' | translate }}</p>
</mc-modal-body>

<mc-modal-footer>
  <button type="button" class="btn btn-secondary" (click)="close()">
    {{ '$I18N_API_GATEWAY_DELETE_CONFIRMATION_MODAL.CANCEL' | translate }}
  </button>

  <mc-live-button
    typeClass="btn btn-primary"
    content="{{ '$I18N_API_GATEWAY_DELETE_CONFIRMATION_MODAL.DELETE' | translate }}"
    type="submit"
    (mcClick)="delete()"
  >
  </mc-live-button>
</mc-modal-footer>
