"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgEndpointsDeleteKeyModalComponent = void 0;
const endpointsInstallActions = require("../../../actions/install.actions");
class SwgEndpointsDeleteKeyModalComponent {
    constructor(store, overlayRef) {
        this.store = store;
        this.overlayRef = overlayRef;
    }
    onClose() {
        this.overlayRef.close();
    }
    onDelete() {
        this.store.dispatch(new endpointsInstallActions.SwgEndpointsInstallDeleteAuthKeyAction());
    }
}
exports.SwgEndpointsDeleteKeyModalComponent = SwgEndpointsDeleteKeyModalComponent;
