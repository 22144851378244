"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UrlProtectionPolicyUserAwarenessSettingsStepComponent = void 0;
const reducers = require("../../../reducers");
const policies_create_actions_1 = require("../../../actions/policies-create.actions");
const url_protect_policy_awareness_set_step_component_static_values_1 = require("./url-protect-policy-awareness-set-step.component.static-values");
class UrlProtectionPolicyUserAwarenessSettingsStepComponent {
    constructor(fb, store, translateService) {
        this.fb = fb;
        this.store = store;
        this.translateService = translateService;
        this.pageSetOptions = [];
        this.challengePercentage = 5;
        this.challengePercentageOptions = {
            floor: 0,
            ceil: 100,
            maxLimit: 100,
            minLimit: 0,
            showSelectionBar: true,
            animate: false,
            translate: (value) => {
                return value + '%';
            }
        };
        this.initializeForms();
        this.createObservables();
        this.startSubscriptions();
    }
    onChallengePercentageValueChange() {
        this.storeStepData(this.showUserAwarenessChallengesForm.value, this.urlProtectionPolicyUserAwarenessForm.value);
    }
    storeStepData(showUserAwarenessChallenges, urlProtectionPolicyUserAwarenessForm) {
        this.store.dispatch(new policies_create_actions_1.SaveUserAwarenessStepDataAction(Object.assign(Object.assign(Object.assign({}, showUserAwarenessChallenges), urlProtectionPolicyUserAwarenessForm), { challengePercentage: this.challengePercentage })));
    }
    initializeForms() {
        this.showUserAwarenessChallengesForm = this.fb.group({
            showUserAwarenessChallenges: [true]
        });
        this.urlProtectionPolicyUserAwarenessForm = this.fb.group({
            dynamicAdjustment: [true],
            customPageSet: [false],
            pageSetOption: []
        });
    }
    createObservables() {
        this.pageSetOptions$ = this.store.select(reducers.getUserAwarenessCustomPageSetOptions);
        this.customPageSet$ = this.store.select(reducers.getUserAwarenessCustomPageSet);
    }
    startSubscriptions() {
        this.pageSetOptionsStoreSubscription = this.pageSetOptions$.subscribe(value => {
            if (value && value.length) {
                this.pageSetOptions = value;
            }
        });
        this.customPageSetStoreSubscription = this.customPageSet$.subscribe(value => {
            this.urlProtectionPolicyUserAwarenessForm
                .get(url_protect_policy_awareness_set_step_component_static_values_1.formControlNames.CUSTOM_PAGE_SET)
                .patchValue(value);
        });
        this.customPageSetValueChangeSubscription = this.urlProtectionPolicyUserAwarenessForm
            .get(url_protect_policy_awareness_set_step_component_static_values_1.formControlNames.CUSTOM_PAGE_SET)
            .valueChanges.subscribe(value => {
            if (value && !!this.pageSetOptions.length) {
                const currentOptionValue = this.urlProtectionPolicyUserAwarenessForm.get(url_protect_policy_awareness_set_step_component_static_values_1.formControlNames.PAGE_SET_OPTION).value;
                if (currentOptionValue === undefined) {
                    this.patchPageSetOptions(this.pageSetOptions[0].value);
                }
            }
            else {
                this.pageSetEditValue = undefined;
                this.patchPageSetOptions(undefined);
            }
        });
        this.showUserAwarenessChallengesFormSubscription = this.showUserAwarenessChallengesForm.valueChanges.subscribe(value => {
            this.storeStepData(value, this.urlProtectionPolicyUserAwarenessForm.value);
        });
        this.urlProtectionPolicyUserAwarenessFormSubscription = this.urlProtectionPolicyUserAwarenessForm.valueChanges.subscribe(value => {
            this.storeStepData(this.showUserAwarenessChallengesForm.value, value);
        });
    }
    showCustomPageSet() {
        return (this.urlProtectionPolicyUserAwarenessForm.get(url_protect_policy_awareness_set_step_component_static_values_1.formControlNames.CUSTOM_PAGE_SET).value ===
            true && !!this.pageSetOptions.length);
    }
    showUserAwarenessForm() {
        return (this.showUserAwarenessChallengesForm.get(url_protect_policy_awareness_set_step_component_static_values_1.formControlNames.SHOW_USER_AWARENESS_CHALLENGES)
            .value === true);
    }
    patchPageSetOptions(value) {
        this.urlProtectionPolicyUserAwarenessForm
            .get(url_protect_policy_awareness_set_step_component_static_values_1.formControlNames.PAGE_SET_OPTION)
            .patchValue(value);
    }
    customPageSetTooltipValue() {
        const prefix = !!this.pageSetOptions.length
            ? ''
            : `${this.translateService.instant('$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.USER_AWARENESS_SETTINGS.TOOLTIPS.NO_PAGE_SET')} `;
        const tooltipValue = this.translateService.instant('$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.USER_AWARENESS_SETTINGS.TOOLTIPS.CUSTOM_PAGE_SET');
        return `${prefix}${tooltipValue}`;
    }
    ngOnChanges(changes) {
        const change = changes['policyUserAwarenessData'];
        if (change && change.previousValue !== change.currentValue) {
            if (change.currentValue) {
                const data = change.currentValue;
                this.pageSetEditValue = data.pageSetOption;
                this.showUserAwarenessChallengesForm.patchValue({
                    showUserAwarenessChallenges: data.showUserAwarenessChallenges
                });
                this.urlProtectionPolicyUserAwarenessForm.patchValue({
                    dynamicAdjustment: data.dynamicAdjustment,
                    customPageSet: data.customPageSet,
                    pageSetOption: data.pageSetOption
                });
                this.challengePercentage = data.challengePercentage;
            }
        }
    }
    ngOnInit() {
        this.storeStepData(this.showUserAwarenessChallengesForm.value, this.urlProtectionPolicyUserAwarenessForm.value);
    }
    ngOnDestroy() {
        this.customPageSetValueChangeSubscription.unsubscribe();
        this.pageSetOptionsStoreSubscription.unsubscribe();
        this.customPageSetStoreSubscription.unsubscribe();
        this.showUserAwarenessChallengesFormSubscription.unsubscribe();
        this.urlProtectionPolicyUserAwarenessFormSubscription.unsubscribe();
    }
    getFormControlNames() {
        return url_protect_policy_awareness_set_step_component_static_values_1.formControlNames;
    }
}
exports.UrlProtectionPolicyUserAwarenessSettingsStepComponent = UrlProtectionPolicyUserAwarenessSettingsStepComponent;
