"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const _ = require("lodash");
(function () {
    'use strict';
    angular.module('services.services.backup.mailboxes-list.controller', [])
        .controller('mailboxesListController', ['$scope', '$state', '$stateParams', '$translate', 'windowService', 'backupService', 'adconCommonsService', 'adconBackupService',
        function ($scope, $state, $stateParams, $translate, windowService, backupService, adconCommonsService, adconBackupService) {
            const self = this;
            let paginationActualPage = 1;
            const paginationLabelDict = {
                start: paginationActualPage,
                end: paginationActualPage
            };
            self.inIframe = windowService.inIframe();
            self.mailboxStatus = {};
            self.mailboxStatusTooltip = {};
            self.payload = {
                data: [{}],
                meta: {
                    pagination: {
                        pageSize: adconCommonsService.CONSTANTS.PAGE_SIZES_KEYS.I50
                    }
                }
            };
            self.mailboxesList = [];
            self.pageSizeOptions = angular.copy(adconCommonsService.getDropdownOptions().pageSizeOptions);
            self.paginationLabel = '';
            self.paginationNextPageResults = false;
            self.paginationPreviousPageResults = false;
            self.hasPermission = adconBackupService.CONSTANTS.HAS_PERMISSIONS;
            $scope.$watch(function () {
                return (self.payload);
            }, function (oldValue, newValue) {
                isPayloadUpdated(oldValue, newValue);
                getMailboxesList();
            }, true);
            self.pageSizeOptionsUpdate = pageSizeOptionsUpdate;
            self.paginationNextOnChange = paginationNextOnChange;
            self.paginationPreviousOnChange = paginationPreviousOnChange;
            self.gotoMailboxDetails = gotoMailboxDetails;
            activate();
            function activate() {
                self.pageSizeOptionsUpdate(adconCommonsService.CONSTANTS.PAGE_SIZES_KEYS.I50);
                self.mailboxStatus = adconBackupService.CONSTANTS.TASK_STATUS_OPTIONS;
                self.mailboxStatusTooltip[backupService.constants.STATUS_MESSAGE_KEYS.MAILBOX_ALREADY_SYNCING_IN_A_DIFFERENT_TASK] = $translate.instant('$I18N_MAILBOX_STATUS_MSG_ALREADY_SYNCING_IN_A_DIFFERENT_TASK');
                self.mailboxStatusTooltip[backupService.constants.STATUS_MESSAGE_KEYS.MAILBOX_NOT_FOUND] = $translate.instant('$I18N_MAILBOX_STATUS_MSG_NOT_FOUND');
                self.mailboxStatusTooltip[backupService.constants.STATUS_MESSAGE_KEYS.ERROR_ARCHIVING_MESSAGE_CONTENT] = $translate.instant('$I18N_MAILBOX_STATUS_MSG_ERROR_ARCHIVING_MESSAGE_CONTENT');
                self.mailboxStatusTooltip[backupService.constants.STATUS_MESSAGE_KEYS.AUTHENTICATION_FAILURE] = $translate.instant('$I18N_MAILBOX_STATUS_MSG_AUTHENTICATION_FAILURE');
                self.mailboxStatusTooltip[backupService.constants.STATUS_MESSAGE_KEYS.NO_ADDRESSES_TO_BACKUP] = $translate.instant('$I18N_MAILBOX_STATUS_MSG_NO_ADDRESSES_TO_BACKUP');
                self.mailboxStatusTooltip[backupService.constants.STATUS_MESSAGE_KEYS.BACKUP_DEFINITION_INTEGRITY_ERROR] = $translate.instant('$I18N_MAILBOX_STATUS_MSG_BACKUP_DEFINITION_INTEGRITY_ERROR');
                self.mailboxStatusTooltip[backupService.constants.STATUS_MESSAGE_KEYS.ERROR_PERFORMING_BACKUP] = $translate.instant('$I18N_MAILBOX_STATUS_MSG_ERROR_PERFORMING_BACKUP');
                self.mailboxStatusTooltip[backupService.constants.STATUS_MESSAGE_KEYS.MAILBOX_NOT_FOUND_NOT_EXPIRED] = $translate.instant('$I18N_MAILBOX_STATUS_MSG_NOT_FOUND_NOT_EXPIRED');
                self.mailboxStatusTooltip[backupService.constants.STATUS_MESSAGE_KEYS.MAILBOX_NOT_FOUND_EXPIRED] = $translate.instant('$I18N_MAILBOX_STATUS_MSG_NOT_FOUND_EXPIRED');
            }
            function getMailboxesList() {
                const payload = angular.copy(self.payload);
                const pageSize = payload.meta.pagination.pageSize;
                self.isDataLoading = true;
                backupService.getMailboxSummary(payload).then(function (response) {
                    if (!response.err) {
                        self.mailboxesList = response.all;
                        if (response.meta && response.meta().pagination) {
                            paginationLabelDict.start = paginationActualPage === 1 ? paginationActualPage : (pageSize * (paginationActualPage - 1)) + 1;
                            paginationLabelDict.end = self.mailboxesList.length !== pageSize ? (pageSize * (paginationActualPage - 1)) + self.mailboxesList.length : pageSize * paginationActualPage;
                            self.paginationLabel = self.mailboxesList.length > 0 ? $translate.instant('$I18N_ADMINISTRATION_COMMONS_PAGINATION_SEPARATOR', {
                                start: paginationLabelDict.start,
                                end: paginationLabelDict.end
                            }) : '';
                            self.paginationNextPageResults = response.meta().pagination.next;
                            self.paginationPreviousPageResults = response.meta().pagination.previous;
                        }
                    }
                    else {
                        backupService.getError().showErrorNotification(response);
                    }
                    self.isDataLoading = false;
                }, function () {
                    self.isDataLoading = false;
                    backupService.getError().showErrorNotification();
                });
            }
            function pageSizeOptionsUpdate(id) {
                self.payload.meta.pagination.pageSize = self.pageSizeOptions[id].key;
                adconCommonsService.setDropdownActiveItem(self.pageSizeOptions, id);
                self.selectedPageSizeOptions = self.pageSizeOptions[id].value;
            }
            function isPayloadUpdated(oldPayload, newPayload) {
                if (!(_.isEqual(oldPayload.data[0], newPayload.data[0])) || !(_.isEqual(oldPayload.meta.pagination.pageSize, newPayload.meta.pagination.pageSize))) {
                    delete self.payload.meta.pagination.pageToken;
                    paginationActualPage = 1;
                }
            }
            function loadMoreLogs(pageToken) {
                if (!self.isDataLoading && pageToken) {
                    self.payload.meta.pagination.pageToken = pageToken;
                }
            }
            function paginationNextOnChange() {
                paginationActualPage++;
                loadMoreLogs(self.paginationNextPageResults);
            }
            function paginationPreviousOnChange() {
                paginationActualPage--;
                loadMoreLogs(self.paginationPreviousPageResults);
            }
            function gotoMailboxDetails(account) {
                if (self.hasPermission.read) {
                    $state.go('backup-mailboxdetails', {
                        mailboxId: account.mailbox,
                        taskId: account.backupTaskSettings.id,
                        lastActive: account.lastActive,
                        initialReplicationDate: account.firstStarted
                    });
                }
            }
            $scope.tab.onRefresh = self.refresh = function () {
                self.mailboxesList = [];
                getMailboxesList();
            };
        }]);
}());
