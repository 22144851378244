"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListEffects = void 0;
const effects_1 = require("@ngrx/effects");
const listAction = require("../../actions/list.action");
const table_store_1 = require("@mimecast-ui/table-store");
const operators_1 = require("rxjs/operators");
const list_impl_service_1 = require("../../containers/service/list.impl.service");
const rxjs_1 = require("rxjs");
const commonActions = require("../../../common/actions");
class ListEffects {
    constructor(actions$, tableStore) {
        this.actions$ = actions$;
        this.tableStore = tableStore;
        this.loadBouncedMessageInfo$ = this.actions$.pipe(effects_1.ofType(listAction.GET_BOUNCED_MESSAGE_INFO), operators_1.withLatestFrom(this.tableStore.select(table_store_1.tableReducer.getTableData(list_impl_service_1.ListImplService.TABLE_ID))), operators_1.switchMap(([action, list]) => {
            if (action.row) {
                return rxjs_1.of(new commonActions.ShowMessageInfoAction(action.row, list, false));
            }
            else {
                return rxjs_1.EMPTY;
            }
        }));
    }
}
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "loadBouncedMessageInfo$", void 0);
exports.ListEffects = ListEffects;
