"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WizardBaseDirective = void 0;
const selectors = require("app-new/custom-domain-block/reducers/index");
const wizardActions = require("app-new/custom-domain-block/actions/wizard.actions");
class WizardBaseDirective {
    constructor(capabilitiesService, wizardId, store, translateService) {
        this.capabilitiesService = capabilitiesService;
        this.wizardId = wizardId;
        this.store = store;
        this.translateService = translateService;
        this.wizardData = undefined;
        if (!wizardId) {
            throw new Error('wizardId is mandatory');
        }
    }
    ngOnInit() {
        this.hasEditPermission$ = this.capabilitiesService.hasCapability('Permission.SUPERVISION_EDIT');
        this.isCurrentStepValid$ = this.store.select(selectors.isCurrentStepValid(this.wizardId));
        this.wizardData$ = this.store.select(selectors.getWizardById(this.wizardId));
        this.dataSubscription = this.wizardData$.subscribe(data => (this.wizardData = data));
    }
    ngOnDestroy() {
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }
    }
    onCancel() {
        this.store.dispatch(new wizardActions.CancelAction(this.getCancelConfirmationText(), this.wizardId));
    }
    onStepChange(stepNumber) {
        this.store.dispatch(new wizardActions.SetCurrentStepAction(this.wizardId, stepNumber));
    }
    onNext() {
        this.wizard.nextStep();
    }
    onPrevious() {
        this.wizard.previousStep();
    }
    getSaveTextQueue() {
        return this.translateService.instant('$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.QUEUE.CONTROLS.CREATE');
    }
}
exports.WizardBaseDirective = WizardBaseDirective;
