<div class="mc-directory-sync-wizard-container">
  <mc-wizard-step-header [label]="'$I18N_DIRECTORY_SYNC_WIZARD.STEPS.DETAILS.HEADER'"></mc-wizard-step-header>
    <form
      class="form-horizontal"
      mcDefaultLabelClass="col-sm-3"
      mcDefaultControlContainerClass="col-sm-9"
      [formGroup]="form"
    >
      <mc-text-field
        label="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.DETAILS.NAME"
        formControlName="name"
        placeholder="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.DETAILS.NAME"
        errorPrefix="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.DETAILS.NAME_ERROR"
        required
        [maxLength]="120"
      >
      </mc-text-field>

      <mc-text-area-field
        label="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.DETAILS.DESCRIPTION"
        placeholder="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.DETAILS.DESCRIPTION_PLACEHOLDER"
        errorPrefix="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.DETAILS.DESCRIPTION_ERROR"
        formControlName="description"
        [maxLength]="240"
      >
      </mc-text-area-field>

      <mc-field
        label="{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.DETAILS.TYPE' | translate }}"
        errorPrefix="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.DETAILS.TYPE_ERROR"
        useTranslation="true"
        required
      >
        <mc-select
          formControlName="type"
          [options]="integrationTypeOptions"
          [defaultValue]="form.value.type"
        ></mc-select>
      </mc-field>
    </form>
</div>
