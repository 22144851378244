'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const _ = require("lodash");
angular.module('mega.menu.group.directive', [])
    .directive('mcMegaMenuGroup', ['$translate', 'capabilitiesService', '$rootScope', function ($translate, capabilitiesService) {
        return {
            restrict: 'AE',
            require: ['^mcMegaMenu', 'mcMegaMenuGroup'],
            transclude: true,
            template: '<div class="mc-menu-group"><div></div></div>',
            scope: {
                label: '@mcLabel',
                icon: '@mcIcon',
                selected: '@mcSelected',
                highlight: '@mcHighlight',
                itemsPerColumn: '@mcItemsPerColumn'
            },
            link($scope, $element, $attr, cosntrollers, transcludeFn) {
                const megaMenuCtrl = cosntrollers[0];
                const megaMenuGroupCtrl = cosntrollers[1];
                const maxItemsPerColumn = 7;
                const itemsPerColumn = $attr.mcItemsPerColumn && parseInt($scope.itemsPerColumn, 10) || 6;
                const transcludeElement = $element.first();
                const menuGroup = {
                    label: $scope.label && $translate.instant($scope.label),
                    icon: $scope.icon,
                    menuItems: megaMenuGroupCtrl.menuItems,
                    selected: $scope.selected,
                    highlight: $scope.highlight,
                    favorite: false,
                    select(selected) {
                        this.selected = selected;
                        if (selected)
                            $element.css({ display: 'block' });
                        else
                            $element.css({ display: 'none' });
                    },
                    isReady: false
                };
                $translate.onReady(function () {
                    menuGroup.label = $scope.label && $translate.instant($scope.label);
                });
                $scope.menuGroup = menuGroup;
                megaMenuCtrl.addMenuGroup($scope.menuGroup);
                if (menuGroup.selected) {
                    megaMenuCtrl.select($scope.menuGroup);
                }
                (function activate() {
                    const elements = transcludeElement.children();
                    if (elements.length > 0) {
                        // if so remove them from DOM, and destroy their scope
                        for (const el of elements) {
                            const element = angular.element(el);
                            //element.scope().$destroy();
                            element.remove();
                        }
                    }
                    capabilitiesService.getCapabilities().then(function () {
                        transcludeFn(function (clone) {
                            let wrapper = null;
                            let li = null;
                            let columnCount = 0;
                            let numberItemsAfterCaps = 0;
                            let numberOfItemsInGroup = 0;
                            let earlyAccessFlag = 0;
                            let newFlag = 0;
                            let hasnewTrailFlag = 0;
                            const columnDivideFactor = (itemsPerColumn > maxItemsPerColumn) ? maxItemsPerColumn : itemsPerColumn;
                            //remove empty text nodes from clones
                            const items = clone.filter(function () {
                                return (this.nodeType == 1); //Node.TEXT_NODE
                            });
                            transcludeElement.addClass('sub-menu-list-groups');
                            // wrap menu items in li and ul
                            _.forEach(items, function (item) {
                                let capCount = 0;
                                const cap = item.attributes['mc-caps'];
                                const hasTrail = item.attributes['mc-trial'];
                                //if mc-cap is not defined take caps as "true", if mc-cap defined, evaluate caps.
                                if (!angular.isDefined(cap) || (angular.isDefined(cap.value) && capabilitiesService.evalCapabilitiesExpression(cap.value))) {
                                    capCount++;
                                    numberOfItemsInGroup++;
                                    numberItemsAfterCaps++;
                                    if (hasTrail && hasTrail.value) {
                                        hasnewTrailFlag++;
                                    }
                                }
                                if (angular.isDefined(cap)
                                    && capabilitiesService.evalCapabilitiesExpression(cap.value)
                                    && capabilitiesService.evalEarlyAccessCapabilitiesExpression(cap.value) === true) {
                                    earlyAccessFlag++;
                                }
                                if (angular.isDefined(cap)
                                    && capabilitiesService.evalCapabilitiesExpression(cap.value)
                                    && capabilitiesService.evalNewCapabilitiesExpression(cap.value) === true) {
                                    newFlag++;
                                }
                                if (wrapper == null || numberOfItemsInGroup === columnDivideFactor) {
                                    columnCount++;
                                    wrapper = angular.element('<ul class="list-group no-margin three-columns pull-left"/>');
                                    if (capCount === 0) {
                                        wrapper.addClass('ng-hide');
                                    }
                                    numberOfItemsInGroup = 0;
                                    transcludeElement.append(wrapper);
                                }
                                li = angular.element('<li class="popover-search-list-item list-group-item"/>');
                                //even if one item (<li>) is not hidden, column <ul> should be visible.
                                // Hence removing .ng-hide class when cap is available for the next item
                                if (capCount === 0) {
                                    if (li) {
                                        li.addClass('ng-hide');
                                    }
                                }
                                else {
                                    if (wrapper) {
                                        wrapper.removeClass('ng-hide');
                                    }
                                }
                                li.append(item);
                                wrapper.append(li);
                            });
                            $scope.menuGroup.numberItemsAfterCaps = numberItemsAfterCaps;
                            $scope.menuGroup.earlyAccessFlag = earlyAccessFlag;
                            $scope.menuGroup.newFlag = newFlag;
                            if (hasnewTrailFlag > 0) {
                                $scope.menuGroup.highlight = true;
                            }
                            if (columnCount > 2) {
                                transcludeElement.addClass('three-columns');
                            }
                        });
                    });
                })();
            },
            //TODO: i dont this this controller is needed any more
            controller: ['$scope', '$transclude', '$compile', '$element', 'capabilitiesService', function ($scope, $transclude, $compile, $element, controllerCapabilitiesService) {
                    const self = this;
                    self.menuItems = [];
                    self.addMenuItem = addMenuItem;
                    let numberOfVisibleItems = 0;
                    function addMenuItem(item) {
                        self.menuItems.push(item);
                        if (capabilitiesService.areCapabilitiesLoaded() && (!angular.isDefined(item.caps) || (angular.isDefined(item.caps) && controllerCapabilitiesService.evalCapabilitiesExpression(item.caps)))) {
                            numberOfVisibleItems++;
                        }
                        else {
                            capabilitiesService.getCapabilities().then(function () {
                                if (!angular.isDefined(item.caps) || (angular.isDefined(item.caps) && controllerCapabilitiesService.evalCapabilitiesExpression(item.caps))) {
                                    numberOfVisibleItems++;
                                }
                            });
                        }
                        if (numberOfVisibleItems >= $scope.menuGroup.numberItemsAfterCaps) {
                            $scope.menuGroup.isReady = true;
                        }
                    }
                }]
        };
    }]);
