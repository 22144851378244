"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.LexiconFactory = void 0;
const lexicon_builder_1 = require("../model/lexicon/lexicon.builder");
const ɵ0 = (lexicon) => new lexicon_builder_1.LexiconBuilder()
    .withVersions(lexicon['versions'])
    .withId(lexicon['id'])
    .withName(lexicon['name'])
    .withCreatedBy(lexicon['createdBy'])
    .withUpdatedBy(lexicon['updatedBy'])
    .withCreated(lexicon['created'])
    .withUpdatedDate(lexicon['updated'])
    .withStatus(lexicon['status'])
    .withSearchType(lexicon['searchType'])
    .build();
exports.ɵ0 = ɵ0;
exports.LexiconFactory = {
    BUILD_LEXICON: ɵ0
};
