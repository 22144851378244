"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasGenericError = exports.isLDAPDataLoaded = exports.isCloudDataLoaded = exports.findGroups = exports.reducer = exports.initialState = void 0;
const groupsAction = require("../actions/find-groups.actions");
exports.initialState = {
    cloudGroups: null,
    ldapGroups: null,
    isCloudDataLoaded: false,
    isLDAPDataLoaded: false,
    genericError: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case groupsAction.FIND_GROUPS:
            return Object.assign(Object.assign({}, state), { 
                // @ts-ignore
                source: action.payload.source, isCloudDataLoaded: false, isLDAPDataLoaded: false });
        case groupsAction.FIND_GROUPS_CLOUD_SUCCESS:
            // @ts-ignore
            return Object.assign(Object.assign({}, state), { cloudGroups: action.payload, isCloudDataLoaded: true, genericError: false });
        case groupsAction.FIND_GROUPS_LDAP_SUCCESS:
            // @ts-ignore
            return Object.assign(Object.assign({}, state), { ldapGroups: action.payload, isLDAPDataLoaded: true, genericError: false });
        case groupsAction.FIND_GROUPS_FAIL:
            return Object.assign(Object.assign({}, state), { isCloudDataLoaded: false, isLDAPDataLoaded: false, genericError: true });
        case groupsAction.FIND_GROUPS_RESET_STORE:
            state = exports.initialState;
            return Object.assign({}, state);
        default:
            return state;
    }
}
exports.reducer = reducer;
const findGroups = (state) => state;
exports.findGroups = findGroups;
const isCloudDataLoaded = (state) => state.isCloudDataLoaded;
exports.isCloudDataLoaded = isCloudDataLoaded;
const isLDAPDataLoaded = (state) => state.isLDAPDataLoaded;
exports.isLDAPDataLoaded = isLDAPDataLoaded;
const hasGenericError = (state) => state.genericError;
exports.hasGenericError = hasGenericError;
