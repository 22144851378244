"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailStatusDisplayPipe = void 0;
class EmailStatusDisplayPipe {
    constructor(translateService) {
        this.translateService = translateService;
    }
    transform(emailStatus) {
        switch (String(emailStatus).toLowerCase()) {
            case 'accepted':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_EMAIL_STATUS.ACCEPTED');
            case 'archived':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_EMAIL_STATUS.ARCHIVED');
            case 'hard_bounce':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_EMAIL_STATUS.HARD_BOUNCE');
            case 'soft_bounce':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_EMAIL_STATUS.SOFT_BOUNCE');
            case 'bounced':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_EMAIL_STATUS.BOUNCED');
            case 'deferred':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_EMAIL_STATUS.DEFERRED');
            case 'delivered':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_EMAIL_STATUS.DELIVERED');
            case 'pending_delivery':
            case 'bulk_delivery':
            case 'delivery':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_EMAIL_STATUS.DELIVERY');
            case 'held':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_EMAIL_STATUS.HELD');
            case 'processing':
            case 'bulk_processing':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_EMAIL_STATUS.PROCESSING');
            case 'rejected':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_EMAIL_STATUS.REJECTED');
            case 'journal':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_EMAIL_STATUS.JOURNAL');
            default:
                return '';
        }
    }
}
exports.EmailStatusDisplayPipe = EmailStatusDisplayPipe;
