"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessagingCapsService = void 0;
const operators_1 = require("rxjs/operators");
const rxjs_1 = require("rxjs");
const coreReducers = require("../../../core/reducers");
class MessagingCapsService {
    constructor(coreStore) {
        this.coreStore = coreStore;
        rxjs_1.combineLatest([
            this.coreStore.select(coreReducers.getAccount),
            this.coreStore.select(coreReducers.getCapsOverride)
        ])
            .pipe(operators_1.filter(([account, caps]) => !!account && !!caps), operators_1.first())
            .subscribe(([account, caps]) => {
            this._canViewSpamScore = this.hasEarlyAccessCapEnabled(caps['Temporary.MessageCenter.SpamScore'], account);
            this._canViewSpamScoreBeta = this.hasCapEnabledAtAccountLevel(caps['Temporary.MessageCenter.SpamScore.Beta'], account);
        });
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    hasEarlyAccessCapEnabled(cap, account) {
        return !!cap && cap.enabled && cap.earlyAccess;
    }
    hasCapEnabledAtAccountLevel(cap, account) {
        return (!!cap &&
            cap.enabled &&
            (!cap.accountCodes || cap.accountCodes.indexOf(account.accountCode) !== -1));
    }
    canViewSpamScore() {
        return this._canViewSpamScore;
    }
    canViewSpamScoreBeta() {
        return this._canViewSpamScoreBeta;
    }
}
exports.MessagingCapsService = MessagingCapsService;
