"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimeLeftModalService = void 0;
const time_left_modal_component_1 = require("./time-left-modal.component");
class TimeLeftModalService {
    constructor(modalService) {
        this.modalService = modalService;
    }
    open(config) {
        this.modalInstance = this.modalService.open(time_left_modal_component_1.TimeLeftModalComponent, {
            data: config,
            hasBackdrop: true,
            disableClose: true
        });
        return this.modalInstance.afterClose();
    }
    close() {
        if (this.modalInstance) {
            this.modalInstance.close();
        }
    }
}
exports.TimeLeftModalService = TimeLeftModalService;
