"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isCloudReady = exports.isLDAPReady = exports.hasGenericError = exports.hasLDAPData = exports.hasCloudData = exports.isLDAPDataLoaded = exports.isCloudDataLoaded = exports.getAllGroups = exports.reducer = exports.initialState = void 0;
const groupsAction = require("../actions/get-groups.actions");
exports.initialState = {
    cloudGroups: [],
    ldapGroups: [],
    isCloudDataLoaded: false,
    isLDAPDataLoaded: false,
    hasCloudData: false,
    hasLDAPData: false,
    genericError: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case groupsAction.GET_GROUPS_CLOUD:
            return Object.assign(Object.assign({}, state), { isCloudDataLoaded: false, hasCloudData: false, cloudGroups: null, genericError: false });
        case groupsAction.GET_GROUPS_CLOUD_SUCCESS:
            return Object.assign(Object.assign({}, state), { cloudGroups: action.payload, isCloudDataLoaded: true, hasCloudData: action.payload.length !== 0, genericError: false });
        case groupsAction.GET_GROUPS_LDAP:
            return Object.assign(Object.assign({}, state), { isLDAPDataLoaded: false, hasLDAPData: false, ldapGroups: null, genericError: false });
        case groupsAction.GET_GROUPS_LDAP_SUCCESS:
            return Object.assign(Object.assign({}, state), { ldapGroups: action.payload, isLDAPDataLoaded: true, hasLDAPData: action.payload.length !== 0, genericError: false });
        case groupsAction.GET_GROUPS_FAIL:
            return Object.assign(Object.assign({}, state), { isCloudDataLoaded: false, isLDAPDataLoaded: false, hasLDAPData: false, hasCloudData: false, genericError: true });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getAllGroups = (state) => ({
    cloudGroups: state.cloudGroups,
    ldapGroups: state.ldapGroups
});
exports.getAllGroups = getAllGroups;
const isCloudDataLoaded = (state) => state.isCloudDataLoaded;
exports.isCloudDataLoaded = isCloudDataLoaded;
const isLDAPDataLoaded = (state) => state.isLDAPDataLoaded;
exports.isLDAPDataLoaded = isLDAPDataLoaded;
const hasCloudData = (state) => state.hasCloudData;
exports.hasCloudData = hasCloudData;
const hasLDAPData = (state) => state.hasLDAPData;
exports.hasLDAPData = hasLDAPData;
const hasGenericError = (state) => state.genericError;
exports.hasGenericError = hasGenericError;
const isLDAPReady = (state) => state.isLDAPDataLoaded && state.hasLDAPData;
exports.isLDAPReady = isLDAPReady;
const isCloudReady = (state) => state.isCloudDataLoaded && state.hasCloudData;
exports.isCloudReady = isCloudReady;
