<mc-layout-list-table extendClass="mc-create-policy">
  <mc-table
    tableId="mep/policies/list"
    [isLoading]="isLoading$ | async"
    [data]="tableData$ | async"
    [columns]="columns"
    translatePrefix="$18N_MEP_POLICY_LIST.TABLE"
    (rowClick)="onRowClick($event)"
  >
    <button
      *ngIf="hasMepPolicyEditPermission$ | async"
      class="mc-table-actions btn btn-primary panel-half-margin-right"
      (click)="goToCreatePolicy()"
    >
      {{ '$I18N_SWG_CREATE_POLICY_CREATE_NEW_POLICY' | translate }}
    </button>
    <mc-filters
      (paginatorChange)="onPaginationChange($event)"
      [metadata]="metaData$ | async"
    >
      <mc-filter-search
        (search)="onSearchPolicies($event)"
        (clear)="onSearchClear()"
        [options]="filterTypes"
        [searchString]="searchOptionsSearchValue"
      >
      </mc-filter-search>
    </mc-filters>

    <mc-column key="description">
      <mc-body-cell *mcBodyCellDef="let row">
        {{ row.description || '-' }}
      </mc-body-cell>
    </mc-column>

    <mc-column key="status">
      <mc-body-cell *mcBodyCellDef="let row">
        <mc-enabled-status
          [enabled]="isPolicyEnabled(row)"
          [showIcon]="true"
          [showColouredText]="false"
        ></mc-enabled-status>
      </mc-body-cell>
    </mc-column>
    <mc-column key="level">
      <mc-body-cell *mcBodyCellDef="let row">
        {{'$18N_MEP_POLICY_LIST.TABLE.MATCHING_LEVEL.' + row.matchLevel  | uppercase | translate }}
      </mc-body-cell>
    </mc-column>

    <mc-column key="action">
      <mc-body-cell *mcBodyCellDef="let row">
        {{'$18N_MEP_POLICY_LIST.TABLE.ACTIONS.' + row.action  | uppercase | translate }}
      </mc-body-cell>
    </mc-column>

    <mc-column key="autoRelease">
      <mc-body-cell *mcBodyCellDef="let row">
        <ng-container *ngIf="row.action !== actions.noAction; else placeholder">
          {{ row.autoReleaseTimeoutMinutes | mcMinutesToHours }}
        </ng-container>
        <ng-template #placeholder>-</ng-template>
      </mc-body-cell>
    </mc-column>

    <mc-column-date key="lastModified"></mc-column-date>

    <mc-column-actions
      key="right-column"
      mcShowColumnConfig
      [columnsAlwaysVisible]="columnsAlwaysVisible"
    >
      <mc-row-actions *mcRowActions="let row" [offSet]="150">
        <li
          *ngIf="hasMepPolicyEditPermission$ | async"
          mcRowAction="$I18N_COMMON_BTN_EDIT"
          (click)="editPolicy(row)"
        ></li>
        <li
          *ngIf="!isDefault(row) && hasMepPolicyEditPermission$ | async"
          mcRowAction="$I18N_COMMON_BTN_DELETE"
          (click)="deletePolicy(row)"
        ></li>
        <li mcRowActionSeparator></li>
        <li
          mcRowAction="$I18N_COMMON_BTN_VIEWDETAILS"
          (click)="onRowClick(row)"
        ></li>
      </mc-row-actions>
    </mc-column-actions>
    <mc-empty-results keyTitle="$18N_SWG_REMEDIATION_DEVICE_SUMMARY.TABLE.NO_DATA"></mc-empty-results>
  </mc-table>
</mc-layout-list-table>
