"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const actions = require("../actions");
const reducers = require("../reducers");
class TemplateEffects {
    constructor(actions$, service, store, location, tabsCtrl) {
        this.actions$ = actions$;
        this.service = service;
        this.store = store;
        this.location = location;
        this.tabsCtrl = tabsCtrl;
        this.saveTemplate$ = this.actions$.pipe(effects_1.ofType(actions.template.SAVE), operators_1.map((action) => action.payload), operators_1.switchMap((payload) => this.store.select(reducers.getTemplate(payload.id)).pipe(operators_1.first())), operators_1.switchMap((template) => {
            return this.service.saveTemplate(template).pipe(operators_1.map((templateResponse) => {
                return new actions.template.SaveSuccessAction(Object.assign(Object.assign({}, templateResponse), { id: template.id }));
            }));
        }));
        this.loadTemplate$ = this.actions$.pipe(effects_1.ofType(actions.template.LOAD), operators_1.map((action) => action.payload), operators_1.mergeMap((template) => {
            if (template.id === 0) {
                return rxjs_1.of(new actions.template.LoadSuccessAction({ id: 0 }));
            }
            return this.service.getTemplate(template.id).pipe(operators_1.map((templateResponse) => {
                return new actions.template.LoadSuccessAction(templateResponse);
            }));
        }));
        this.saveSuccess$ = this.actions$.pipe(effects_1.ofType(actions.template.SAVE_SUCCESS), operators_1.map((action) => action.payload), operators_1.map(template => {
            this.tabsCtrl.getSelectedTab().close();
            this.location.hash = '#/awareness/list';
            return new actions.template.CleanStoreAction(template);
        }));
        this.cancelTemplate$ = this.actions$.pipe(effects_1.ofType(actions.template.CANCEL), operators_1.map((action) => action.payload), operators_1.map(template => {
            this.tabsCtrl.getSelectedTab().close();
            this.location.hash = '#/awareness/list';
            return new actions.template.CleanStoreAction(template);
        }));
        this.loadMimecastSafetyTips$ = this.actions$.pipe(effects_1.ofType(actions.safetyTips.MIMECAST_SAFETY_TIPS_LOAD), operators_1.withLatestFrom(this.store.select(reducers.getMimecastDefaultSafetyTips).pipe(operators_1.first())), operators_1.switchMap(([, safetyTips]) => {
            if (safetyTips && safetyTips.length > 1) {
                return rxjs_1.of(new actions.safetyTips.MimecastSafetyTipsLoadSuccessAction(safetyTips));
            }
            return this.service.getMimecastDefaultSafetyTips().pipe(operators_1.map((safetyTipsInner) => {
                return new actions.safetyTips.MimecastSafetyTipsLoadSuccessAction(safetyTipsInner);
            }));
        }));
    }
}
__decorate([
    effects_1.Effect()
], TemplateEffects.prototype, "saveTemplate$", void 0);
__decorate([
    effects_1.Effect()
], TemplateEffects.prototype, "loadTemplate$", void 0);
__decorate([
    effects_1.Effect()
], TemplateEffects.prototype, "saveSuccess$", void 0);
__decorate([
    effects_1.Effect()
], TemplateEffects.prototype, "cancelTemplate$", void 0);
__decorate([
    effects_1.Effect()
], TemplateEffects.prototype, "loadMimecastSafetyTips$", void 0);
exports.TemplateEffects = TemplateEffects;
