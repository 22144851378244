"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActionModalService = void 0;
const action_modal_component_1 = require("./action-modal.component");
class ActionModalService {
    constructor(modalService) {
        this.modalService = modalService;
    }
    open(options) {
        return this.modalService.open(action_modal_component_1.ActionModalComponent, { data: options }).afterClose();
    }
}
exports.ActionModalService = ActionModalService;
