'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
/**
 * Created by gvilla on 02/03/2016
 */
angular.module('navbar.history.controller', [])
    .controller('NavBarHistoryController', ['navbarHistoryService', 'navbarService', function (navbarHistoryService, navbarService) {
        const self = this;
        self.objHistoryItems = [];
        self.objHistoryItems = navbarHistoryService.getStateHistory;
        self.clearItemsFromHistoryMenu = navbarHistoryService.clearItemsFromHistoryMenu;
        self.openMenu = navbarService.openMenu;
        // ----- Function exposed to the template ---
    }]);
