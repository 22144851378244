"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const swg_url_check_page_component_1 = require("./container/check/swg-url-check-page.component");
angular
    .module('swgUrlCheckReport', [])
    .directive('mcSwgUrlCheckReport', static_1.downgradeComponent({
    component: swg_url_check_page_component_1.SwgUrlCheckPageComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider.state('secure-web-gateway-url-check-report', {
            url: '/secure-web-gateway/url-check-report',
            data: {
                capabilities: 'Permission.SWG_URL_CLASSIFCATION_REPORT_READ',
                isFixedLayout: false,
                checkProgress: false,
                tabReload: false,
                tabTitle: 'Domain and URL Category Lookup'
            },
            views: {
                main: {
                    template: '<mc-swg-url-check-report></mc-swg-url-check-report>'
                }
            }
        });
    }
]);
