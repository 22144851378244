"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExpirationSettingPipe = void 0;
const policy_1 = require("../models/policy");
class ExpirationSettingPipe {
    constructor(translateService) {
        this.translateService = translateService;
    }
    transform(value) {
        if (value) {
            let expirationTimeType = value.expirationTimeType.valueOf();
            if (value.expirationTimeValue === 1) {
                switch (value.expirationTimeType) {
                    case policy_1.ExpirationTimeType.MINUTES:
                        expirationTimeType = 'minute';
                        break;
                    case policy_1.ExpirationTimeType.HOURS:
                        expirationTimeType = 'hour';
                        break;
                    case policy_1.ExpirationTimeType.DAYS:
                        expirationTimeType = 'day';
                        break;
                }
            }
            const unitString = this.translateService.instant('$I18N_IDENTITY_PIPES.EXPIRATION_TIME.' + expirationTimeType);
            const params = { value: value.expirationTimeValue, unit: unitString };
            return this.translateService.instant('$I18N_IDENTITY_PIPES.EXPIRATION_TIME.FORMAT', params);
        }
        return '';
    }
}
exports.ExpirationSettingPipe = ExpirationSettingPipe;
