"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.additionalExportColumns = exports.columnsToExport = exports.columnsToIgnore = exports.columnsAlwaysVisible = exports.columnsToStartHidden = exports.tableFilters = exports.searchFilters = exports.columns = void 0;
exports.columns = [
    'select',
    'deviceId',
    'deviceName',
    'status',
    'username',
    'discoveryMethod',
    'privateIp',
    'lastSync',
    'os',
    'version',
    'lastUpdate',
    'action'
];
exports.searchFilters = [
    {
        name: 'filterBy',
        filters: ['unprotected', 'protected', 'disabled']
    }
];
exports.tableFilters = [
    {
        name: 'status',
        displayName: 'Status',
        filters: [
            {
                name: 'unprotected',
                displayName: 'Unprotected',
                isSelected: false
            },
            {
                name: 'protected',
                displayName: 'Protected',
                isSelected: false
            },
            {
                name: 'disabled',
                displayName: 'Disabled',
                isSelected: false
            }
        ]
    },
    {
        name: 'os',
        displayName: 'Operating System',
        isSingleSelect: true,
        filters: [
            {
                name: 'windows 7',
                displayName: 'Windows 7',
                isSelected: false
            },
            {
                name: 'windows 8.1',
                displayName: 'Windows 8.1',
                isSelected: false
            },
            {
                name: 'windows 10',
                displayName: 'Windows 10',
                isSelected: false
            },
            {
                name: 'windows 11',
                displayName: 'Windows 11',
                isSelected: false
            },
            {
                name: 'windows server',
                displayName: 'Windows Server',
                isSelected: false
            },
            {
                name: 'macos',
                displayName: 'MacOS',
                isSelected: false
            },
            {
                name: 'ios',
                displayName: 'iOS',
                isSelected: false
            }
        ]
    }
];
exports.columnsToStartHidden = ['deviceId', 'discoveryMethod'];
exports.columnsAlwaysVisible = ['deviceName', 'status', 'version'];
exports.columnsToIgnore = [
    'select',
    'processor',
    'installedRam',
    'edition',
    'installedOn',
    'osBuild',
    'subnetMask',
    'defaultGateway',
    'macAddress',
    'serialNumber'
];
exports.columnsToExport = [
    'deviceId',
    'deviceName',
    'status',
    'username',
    'discoveryMethod',
    'privateIp',
    'lastSync',
    'os',
    'version',
    'lastUpdate'
];
exports.additionalExportColumns = [
    'processor',
    'installedRam',
    'osModel',
    'osEdition',
    'osInstalled',
    'osBuild',
    'subnetMask',
    'defaultGateway',
    'macAddress',
    'serialNumber'
];
