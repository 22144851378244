"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgPolicyApplicationControlStepComponent = void 0;
const core_1 = require("@angular/core");
const models_1 = require("../../../../models/models");
class SwgPolicyApplicationControlStepComponent {
    constructor() {
        this.updateWebApplicationRule = new core_1.EventEmitter();
        this.height = '575px';
    }
    isValid() {
        return true;
    }
    ngOnInit() {
        this.updateApplicationListWithUserSettings();
    }
    updateApplicationListWithUserSettings() {
        this.applicationsCopy = this.applications.map(x => {
            return Object.assign({}, x);
        });
        const userApplicationCodes = this.userWebApplicationRules.map(x => x.applicationCode);
        this.applicationsCopy = this.applicationsCopy.map(rule => {
            if (userApplicationCodes.indexOf(rule.code) > -1) {
                const selectedAction = this.userWebApplicationRules.find(userApplication => userApplication.applicationCode === rule.code).action;
                return Object.assign(Object.assign({}, rule), { action: selectedAction === 'allow' ? models_1.ApplicationStatus.ALLOW : models_1.ApplicationStatus.BLOCK });
            }
            return rule;
        });
    }
    webApplicationRulesStep(data) {
        this.updateWebApplicationRule.emit(data);
    }
}
exports.SwgPolicyApplicationControlStepComponent = SwgPolicyApplicationControlStepComponent;
