<mc-layout-list-extra-container>
  <mc-help-container>
    <mc-help-link url="https://community.mimecast.com/s/article/Targeted-Threat-Protection-Managed-URLs-1936142763">
    </mc-help-link>
  </mc-help-container>

  <mc-body-container>
    <mc-managed-urls-table [selectedRow]="selectedRow$ | async" [externalTableSearchCount]="totalCount$ | async"
      [isLoading]="isLoading$ | async" [metadata]="metadata$ | async" (onPaginatorChanged)="paginatorChanged($event)"
      (onColumnFilterChanged)="columnFilterChanged($event)" (onSearchFilterChanged)="search($event)"
      (onRowClicked)="rowClicked($event)" (onAddNewManagedUrl)="addNewManagedUrl()"
      (onManagedUrlRemove)="removeManagedUrl($event)" (onOpenSideInEdit)="openSideInEditMode($event)">
    </mc-managed-urls-table>
  </mc-body-container>
</mc-layout-list-extra-container>