"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AwarenessTemplateService = void 0;
const operators_1 = require("rxjs/operators");
class AwarenessTemplateService {
    constructor(service) {
        this.service = service;
    }
    saveTemplate(template) {
        const apiTemplate = this.transformToUATemplate(template);
        return this.service.saveTemplate(apiTemplate).pipe(operators_1.map((templateResponse) => {
            return this.transformToTemplate(templateResponse);
        }));
    }
    getTemplate(id) {
        return this.service.getTemplate(id).pipe(operators_1.map((templateResponse) => {
            return this.transformToTemplate(templateResponse);
        }));
    }
    getMimecastDefaultSafetyTips() {
        return this.service.getMimecastDefaultSafetyTips();
    }
    transformToUATemplate(template) {
        const isMimecastOnlyTips = template.safetyTipType === 'mimecastSafetyTips';
        const apiTemplate = {
            id: template.id,
            name: template.templateName.trim(),
            userChallenge: this.transformPage(template.userTemplate),
            harmfulMisidentifiedBlock: this.transformPage(template.harmfulLinkMisindentifiedBlock),
            harmfulIdentified: this.transformPage(template.harmfulLinkIndentified),
            harmfulMisidentifiedWarn: this.transformPage(template.harmfulLinkMisindentifiedWarning),
            safeIdentified: this.transformPage(template.safeLinkIdentified),
            safeMisidentified: this.transformPage(template.safeLinkMisidentified),
            // TODO: add proper code to support the 3 modes of tips,
            useMimecastTips: isMimecastOnlyTips || template.safetyTipType === 'mimecastSafetyTipsAndCustomSafetyTips',
            safetyTips: isMimecastOnlyTips ? [] : template.safetyTips
        };
        return apiTemplate;
    }
    transformPage(origin) {
        const page = {
            title: origin.title,
            subtitle: origin.subtitle,
            custom: !!(origin.title || origin.subtitle)
        };
        return page;
    }
    transformToTemplate(templateResponse) {
        const template = {
            id: templateResponse.id,
            templateName: templateResponse.name,
            userTemplate: templateResponse.userChallenge,
            harmfulLinkMisindentifiedBlock: templateResponse.harmfulMisidentifiedBlock,
            harmfulLinkIndentified: templateResponse.harmfulIdentified,
            harmfulLinkMisindentifiedWarning: templateResponse.harmfulMisidentifiedWarn,
            safeLinkIdentified: templateResponse.safeIdentified,
            safeLinkMisidentified: templateResponse.safeMisidentified,
            safetyTips: templateResponse.safetyTips,
            useMimecastTips: templateResponse.useMimecastTips
        };
        if (template.safetyTips && template.safetyTips.length > 0 && template.useMimecastTips) {
            template.safetyTipType = 'mimecastSafetyTipsAndCustomSafetyTips';
        }
        else if (template.safetyTips && template.safetyTips.length > 0) {
            template.safetyTipType = 'customSafetyTips';
        }
        else {
            template.safetyTipType = 'mimecastSafetyTips';
        }
        return template;
    }
}
exports.AwarenessTemplateService = AwarenessTemplateService;
