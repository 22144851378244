"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewLexiconComponent = void 0;
const lexiconActions = require("app-new/archive/supervision/actions/lexicon.actions");
const selectors = require("app-new/archive/supervision/reducers/index");
const rule_usage_notification_modal_component_1 = require("app-new/archive/supervision/components/rule-usage-notification-modal/rule-usage-notification-modal.component");
const delete_confirmation_modal_component_1 = require("app-new/archive/supervision/components/delete-confirmation-modal/delete-confirmation-modal.component");
const lexiconListActions = require("app-new/archive/supervision/actions/lexicon.list.actions");
const lodash_1 = require("lodash");
const dashboard_settings_1 = require("../../../../supervision/model/supervision/dashboard/settings/dashboard-settings");
class ViewLexiconComponent {
    constructor(store, modalService, capabilitiesService) {
        this.store = store;
        this.modalService = modalService;
        this.capabilitiesService = capabilitiesService;
        this.canDeleteLexicon = 'Temporary.Supervision.Delete && Permission.SUPERVISION_EDIT';
    }
    ngOnInit() {
        this.hasEditPermission$ = this.capabilitiesService.hasCapability('Permission.SUPERVISION_EDIT');
        this.lexiconDetails$ = this.store.select(selectors.getLexiconDetails);
        this.lexiconLoading$ = this.store.select(selectors.getLexiconLoading);
        this.lexiconDetailsPaginationInfo$ = this.store.select(selectors.getLexiconDetailsPagination);
        this.hasDeleteCapability$ = this.capabilitiesService.evalCapabilitiesExpression(this.canDeleteLexicon);
    }
    ngOnDestroy() {
        this.store.dispatch(new lexiconActions.CloseLexiconDetailsAction());
    }
    loadDetails(lexicon) {
        this.store.dispatch(new lexiconActions.GetAction(lexicon));
    }
    edit(lexicon) {
        this.store.dispatch(new lexiconListActions.OpenEditLexiconAction(lexicon.id));
    }
    deleteLexicon(lexicon) {
        if (lodash_1.isEmpty(lexicon.associatedRules)) {
            this.confirmDeletion(lexicon);
        }
        else {
            this.ruleUsageNotification(lexicon.name, lexicon.associatedRules);
        }
    }
    ruleUsageNotification(lexiconName, associatedRules) {
        this.modalService.open(rule_usage_notification_modal_component_1.RuleUsageNotificationModalComponent, {
            size: 'lg',
            hasBackdrop: true,
            disableClose: true,
            data: {
                entityName: lexiconName,
                associatedRules,
                entityDeletionType: dashboard_settings_1.DeletionType.LEXICON
            }
        });
    }
    confirmDeletion(selectedLexicon) {
        const modal = this.modalService.open(delete_confirmation_modal_component_1.DeleteConfirmationModalComponent, {
            size: 'lg',
            hasBackdrop: true,
            disableClose: true,
            data: {
                entityName: selectedLexicon.name,
                entityDeletionType: dashboard_settings_1.DeletionType.LEXICON
            }
        });
        modal.afterClose().subscribe(confirmed => {
            if (confirmed) {
                this.store.dispatch(new lexiconListActions.DeleteLexiconAction({ id: selectedLexicon.id }));
            }
        });
    }
}
exports.ViewLexiconComponent = ViewLexiconComponent;
