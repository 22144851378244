"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PolicyWizardAppliesToComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const step_base_component_1 = require("app-new/custom-domain-block/containers/wizard/step.base.component");
const custom_domain_block_service_1 = require("app-new/custom-domain-block/services/custom-domain-block.service");
class PolicyWizardAppliesToComponent extends step_base_component_1.StepBaseDirective {
    constructor(store, fb, capabilitiesService) {
        super(store, fb, 3, custom_domain_block_service_1.CustomDomainBlockService.WIZARD_ID);
        this.store = store;
        this.fb = fb;
        this.capabilitiesService = capabilitiesService;
        this.appliesTo = 'everyone';
        this.openNotifyToSidePanel = new core_1.EventEmitter();
        this.removeGroup = new core_1.EventEmitter();
        this.appliesToOptions = ['EVERYONE']; // more options to come in V2
        this.form = this.fb.group({
            appliesTo: ['everyone', [forms_1.Validators.required]],
            profileGroup: undefined
        });
        if (capabilitiesService.hasCapability('Temporary.Custom.Domain.Block.V2')) {
            this.appliesToOptions.push('PROFILE_GROUP');
        }
        this.subscription = this.form.get('appliesTo').valueChanges.subscribe((appliesTo) => {
            if (appliesTo && appliesTo === 'profile_group') {
                this.form.get('profileGroup').setValidators(forms_1.Validators.required);
            }
            else {
                this.form.get('profileGroup').setValidators(null);
                this.form.get('profileGroup').setValue(undefined);
            }
            this.form.updateValueAndValidity();
        });
    }
    ngOnInit() {
        super.ngOnInit();
        this.form.patchValue({
            appliesTo: this.appliesTo,
            profileGroup: this.group || this.profileGroup
        });
    }
    hideCurrentGroup() {
        this.form.get('profileGroup').setValue(undefined);
        this.form.updateValueAndValidity();
        this.removeGroup.emit();
    }
    ngOnChanges(changes) {
        if (changes.group && changes.group.currentValue !== changes.group.previousValue) {
            this.form.patchValue({
                profileGroup: changes.group.currentValue
            });
        }
        if (changes.profileGroup &&
            changes.profileGroup.currentValue !== changes.profileGroup.previousValue) {
            this.form.patchValue({
                profileGroup: changes.profileGroup.currentValue
            });
        }
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
exports.PolicyWizardAppliesToComponent = PolicyWizardAppliesToComponent;
