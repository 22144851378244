<mc-modal-header
  headerTitle="{{ '$I18N_SWG_CSV_UPLOADER_TITLE' | translate }}"
  subTitle="{{ '$I18N_SWG_CSV_UPLOADER_SUB_TITLE' | translate }}"
></mc-modal-header>
<mc-modal-body>
  <div>
    <div class="panel-padding-bottom">
      <span class="text-bold"> {{ '$I18N_SWG_CSV_UPLOADER_STEP1' | translate }} </span>
      {{ '$I18N_SWG_CSV_UPLOADER_DOWNLOAD_OUR_CSV_FILE' | translate }}
    </div>
    <button class="btn btn-secondary panel-half-margin-right" (click)="downloadFormatFile()">
      <i class="fas fa-cloud-download-alt cursor-pointer"></i>
      Download
    </button>
    <div class="panel-padding-top panel-padding-bottom">
      <span class="text-bold"> {{ '$I18N_SWG_CSV_UPLOADER_STEP2' | translate }} </span>
      {{ '$I18N_SWG_CSV_UPLOADER_UPLOAD_THE_CSV_FILE' | translate }}
    </div>

    <div class="mc-upload-btn-wrapper">
      <button class="btn btn-secondary" (click)="myfile.click()" [disabled]="existingDomains.length >= maxRows">
        <i class="far fa-paperclip cursor-pointer"></i>
        {{ '$I18N_SWG_CSV_UPLOADER_UPLOAD_A_FILE' | translate }}
      </button>
      {{ fileName.name }}
      <input
        class="mc-file-element"
        (change)="fileChange($event)"
        [disabled]="existingDomains.length >= maxRows"
        type="file"
        name="myfile"
        accept=".csv"
        #myfile
      />
    </div>

    <div class="text-danger" *ngIf="hasError">
      {{ '$I18N_SWG_CSV_UPLOADER_ERROR' | translate }}
    </div>
    <div
      class="text-danger"
      [class.mc-file-parse-error]="hasError"
      *ngIf="content.length > maxRows && existingDomains.length === 0"
    >
      {{ '$I18N_SWG_CSV_UPLOADER_MAX_ROWS' | translate: { maxRows: maxRows } }}<br />
      {{ '$I18N_SWG_CSV_UPLOADER_MAX_ROWS_EXCEEDED' | translate: { rowCount: content.length }
      }}<br />
      {{ '$I18N_SWG_CSV_UPLOADER_REDUCE_MSG' | translate }}
    </div>
    <div
      class="text-danger"
      [class.mc-file-parse-error]="hasError"
      *ngIf="content.length > maxRows - existingDomains.length && existingDomains.length > 0 && existingDomains.length < maxRows"
    >
      {{ '$I18N_SWG_CSV_UPLOADER_MAX_ROWS' | translate: { maxRows: maxRows } }}<br />
      {{ '$I18N_SWG_CSV_UPLOADER_MAX_ROWS_EXCEEDED' | translate: { rowCount: content.length } }}<br />
      {{ '$I18N_SWG_CSV_UPLOADER_EXISTING_ROWS_ENTERED' | translate: { existingRows: existingDomains.length } }}<br />
      {{ '$I18N_SWG_CSV_UPLOADER_REDUCE_MSG2' | translate: { maxCsvRows: maxRows - existingDomains.length } }}
    </div>
    <div
        class="text-danger"
        [class.mc-file-parse-error]="hasError"
        *ngIf="existingDomains.length >= maxRows"
    >
      {{ '$I18N_SWG_CSV_UPLOADER_MAX_ROWS' | translate: { maxRows: maxRows } }}<br />
      {{ '$I18N_SWG_CSV_UPLOADER_MAX_ROWS_ENTERED' | translate }}<br />
      {{ '$I18N_SWG_CSV_UPLOADER_REMOVE_DOMAINS' | translate }}
    </div>
  </div>
</mc-modal-body>
<mc-modal-footer>
  <button type="button" class="btn btn-secondary" (click)="onCancel()">
    {{ '$I18N_COMMON_BTN_CANCEL' | translate }}
  </button>
  <mc-live-button
    overrideClasses="btn-primary"
    [label]="'$I18N_SWG_CSV_UPLOADER_IMPORT' | translate"
    (mcClick)="uploadFile()"
    [mcDisabled]="!content.length || content.length > maxRows - existingDomains.length"
    [isLoading]="isProcessing"
  >
  </mc-live-button>
</mc-modal-footer>
