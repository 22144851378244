"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isPasswordLoading = exports.getUninstallPassword = exports.getTamperProtectedPassword = exports.hasGenericError = exports.isSettingsUpdating = exports.isSettingsLoading = exports.getEndpointSettings = exports.reducer = exports.initialState = void 0;
const settings_actions_1 = require("../actions/settings.actions");
exports.initialState = {
    settings: null,
    tamperProtectedPassword: null,
    uninstallPassword: null,
    isUpdating: false,
    isLoading: false,
    isPasswordLoading: false,
    genericError: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case settings_actions_1.SwgEndpointSettingsActions.GET_SETTINGS:
            return Object.assign(Object.assign({}, state), { isLoading: true, genericError: false });
        case settings_actions_1.SwgEndpointSettingsActions.GET_SETTINGS_SUCCESS:
            return Object.assign(Object.assign({}, state), { settings: action.payload, isLoading: false, genericError: false });
        case settings_actions_1.SwgEndpointSettingsActions.UPDATE_SETTINGS:
            return Object.assign(Object.assign({}, state), { isUpdating: true, genericError: false });
        case settings_actions_1.SwgEndpointSettingsActions.UPDATE_SETTINGS_SUCCESS:
            return Object.assign(Object.assign({}, state), { settings: action.payload, isUpdating: false, genericError: false });
        case settings_actions_1.SwgEndpointSettingsActions.SETTINGS_FAILURE:
            return Object.assign(Object.assign({}, state), { isUpdating: false, isLoading: false, isPasswordLoading: false, genericError: true });
        case settings_actions_1.SwgEndpointSettingsActions.GET_TAMPER_PROTECTED_PASSWORD:
            return Object.assign(Object.assign({}, state), { isPasswordLoading: true, genericError: false });
        case settings_actions_1.SwgEndpointSettingsActions.GET_TAMPER_PROTECTED_PASSWORD_SUCCESS:
            return Object.assign(Object.assign({}, state), { tamperProtectedPassword: action.payload, isPasswordLoading: false, genericError: false });
        case settings_actions_1.SwgEndpointSettingsActions.GET_UNINSTALL_PASSWORD:
            return Object.assign(Object.assign({}, state), { isPasswordLoading: true, genericError: false });
        case settings_actions_1.SwgEndpointSettingsActions.GET_UNINSTALL_PASSWORD_SUCCESS:
            return Object.assign(Object.assign({}, state), { uninstallPassword: action.payload, isPasswordLoading: false, genericError: false });
        case settings_actions_1.SwgEndpointSettingsActions.CLOSE_ENABLE_UNINSTALL_PASSWORD_CONFIRM_MODAL:
            return Object.assign(Object.assign({}, state), { settings: Object.assign(Object.assign({}, action.payload), { enableUninstallPassword: !action.payload.enableUninstallPassword }) });
        case settings_actions_1.SwgEndpointSettingsActions.CANCEL_DIRECT_IP_CONFIRM_MODAL:
            return Object.assign(Object.assign({}, state), { settings: Object.assign(Object.assign({}, action.payload), { directIpEnabled: !action.payload.directIpEnabled, directIpMode: state.settings.directIpMode }) });
        case settings_actions_1.SwgEndpointSettingsActions.CANCEL_ALLOW_LOCAL_IPS_CONFIRM_MODAL:
            return Object.assign(Object.assign({}, state), { settings: Object.assign(Object.assign({}, action.payload), { allowInternalIps: !action.payload.allowInternalIps }) });
        case settings_actions_1.SwgEndpointSettingsActions.CANCEL_TUID_ATTRIBUTE_CONFIRM_MODAL:
            return Object.assign(Object.assign({}, state), { settings: Object.assign(Object.assign({}, action.payload), { ldapMailAttribute: state.settings.ldapMailAttribute }) });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getEndpointSettings = (state) => state.settings;
exports.getEndpointSettings = getEndpointSettings;
const isSettingsLoading = (state) => state.isLoading;
exports.isSettingsLoading = isSettingsLoading;
const isSettingsUpdating = (state) => state.isUpdating;
exports.isSettingsUpdating = isSettingsUpdating;
const hasGenericError = (state) => state.genericError;
exports.hasGenericError = hasGenericError;
const getTamperProtectedPassword = (state) => state.tamperProtectedPassword;
exports.getTamperProtectedPassword = getTamperProtectedPassword;
const getUninstallPassword = (state) => state.uninstallPassword;
exports.getUninstallPassword = getUninstallPassword;
const isPasswordLoading = (state) => state.isPasswordLoading;
exports.isPasswordLoading = isPasswordLoading;
