"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeviceGroupSummaryStepComponent = void 0;
class DeviceGroupSummaryStepComponent {
    getTitle() {
        if (this.isEditMode) {
            return '$I18N_DEVICE_GROUPS_LIST.WIZARD.STEPS.SUMMARY.SUB_TITLE_EDIT';
        }
        else {
            return '$I18N_DEVICE_GROUPS_LIST.WIZARD.STEPS.SUMMARY.SUB_TITLE';
        }
    }
}
exports.DeviceGroupSummaryStepComponent = DeviceGroupSummaryStepComponent;
