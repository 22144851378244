"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiIntegrationCortexSummaryStepComponent = void 0;
const core_1 = require("@angular/core");
class ApiIntegrationCortexSummaryStepComponent {
    constructor(fb) {
        this.fb = fb;
        this.objectKeys = Object.keys;
        this.onIntegrationStatusUpdated = new core_1.EventEmitter();
        this.onGotoStep = new core_1.EventEmitter();
        this.form = this.fb.group({
            integrationStatus: false
        });
    }
    ngOnChanges(changes) {
        const change = changes['cortexIntegrationVersion1'];
        if (change && change.previousValue !== change.currentValue) {
            if (change.currentValue) {
                this.form.patchValue({
                    integrationStatus: this.integrationStatus
                });
            }
        }
    }
    integrationStatus() {
        if (this.cortexIntegrationVersion1) {
            return this.cortexIntegrationVersion1.enabled;
        }
        return !!this.cortexIntegrationVersion1 && this.cortexIntegrationVersion1.enabled;
    }
    spywareSettings() {
        if (this.cortexIntegrationVersion1) {
            return this.resolveSetting(this.cortexIntegrationVersion1.triggers, 'spyware');
        }
        return this.getInitialSetting('spyware', 'medium');
    }
    wildfireSettings() {
        if (this.cortexIntegrationVersion1) {
            return this.resolveSetting(this.cortexIntegrationVersion1.triggers, 'wildfire');
        }
        return this.getInitialSetting('wildfire', 'high');
    }
    cortexEmailList() {
        if (this.cortexIntegrationVersion1) {
            return this.cortexIntegrationVersion1.notificationAddresses;
        }
        return [];
    }
    cortexGroupsList() {
        if (this.cortexIntegrationVersion1) {
            return Object.values(this.cortexIntegrationVersion1.notificationGroups);
        }
        return [];
    }
    resolveSetting(triggers, subtype) {
        const splitSetting = triggers.find(setting => setting.fields.subtype === subtype);
        let returnedSetting = {
            config: '',
            type: 'panw.threat',
            fields: {
                subtype: '',
                severity: ''
            },
            action: '',
            enabled: false,
            disableUser: false
        };
        if (splitSetting) {
            returnedSetting = {
                config: splitSetting.fields.subtype,
                type: 'panw.threat',
                fields: {
                    subtype: splitSetting.fields.subtype,
                    severity: splitSetting.fields.severity
                },
                action: splitSetting.action,
                enabled: splitSetting.enabled,
                disableUser: splitSetting.disableUser
            };
        }
        return returnedSetting;
    }
    getInitialSetting(setting, initialSeverity) {
        return {
            config: setting,
            type: 'panw.threat',
            fields: {
                subtype: setting,
                severity: initialSeverity
            },
            action: 'NOTIFY',
            enabled: true,
            disableUser: false
        };
    }
    getIntegrationIdentifier(name) {
        if (name) {
            return decodeURI(name);
        }
    }
    update() {
        this.onIntegrationStatusUpdated.emit({
            id: this.cortexIntegrationVersion1.id,
            enabled: !this.form.value.integrationStatus
        });
    }
}
exports.ApiIntegrationCortexSummaryStepComponent = ApiIntegrationCortexSummaryStepComponent;
