"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListImplService = void 0;
const table_store_1 = require("@mimecast-ui/table-store");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
class ListImplService {
    constructor(attributesService) {
        this.attributesService = attributesService;
    }
    getData(payload, pagination = table_store_1.defaultPaginationOption) {
        const config = Object.assign(Object.assign({}, payload), { meta: { pagination } });
        return this.attributesService.getAttributes(config).pipe(operators_1.map((response) => {
            return {
                hasErrors: response.hasErrors,
                data: response.first.attributeTypes,
                failures: response.fail,
                meta: response.meta
            };
        }), operators_1.catchError((response) => {
            return rxjs_1.of({
                hasErrors: response.hasErrors,
                data: response.first.attributeTypes,
                failures: response.fail,
                meta: response.meta
            });
        }));
    }
    filter(query, pagination) {
        return this.getData(query, pagination);
    }
    openItem() {
        return rxjs_1.of([]);
    }
}
exports.ListImplService = ListImplService;
ListImplService.STORE_ID = 'AttributesList';
