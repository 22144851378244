"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LexiconContentComponent = void 0;
const core_1 = require("@angular/core");
const lexicon_service_1 = require("../../../../../services/lexicon.service");
const step_base_component_1 = require("app-new/archive/supervision/containers/wizard/step.base.component");
const lexicon_1 = require("app-new/archive/supervision/model/lexicon/lexicon");
const lexicon_query_validators_1 = require("../../validators/lexicon-query-validators");
const lexicon_content_validators_1 = require("../../validators/lexicon-content-validators");
const validators_1 = require("app-new/api-applications/validators");
class LexiconContentComponent extends step_base_component_1.StepBaseDirective {
    constructor(store, fb) {
        super(store, fb, 2, lexicon_service_1.LexiconService.WizardId);
        this.store = store;
        this.fb = fb;
        this.words = '';
        this.phrases = '';
        this.query = '';
        this.searchType = '';
        this.onValidateSuccess = new core_1.EventEmitter();
        this.lexiconSearchType = lexicon_1.LexiconSearchType;
        this.lexiconQueryValidatorsArray = [
            lexicon_query_validators_1.LexiconQueryValidator.validateMaxQuery(500),
            lexicon_query_validators_1.LexiconQueryValidator.validateMinLengthWords(3),
            lexicon_query_validators_1.LexiconQueryValidator.validateMaxLengthWords(100),
            lexicon_query_validators_1.LexiconQueryValidator.validateMaxLengthPhrases(1024),
            validators_1.validateNotEmpty
        ];
        this.form = this.fb.group({
            words: ['', [lexicon_content_validators_1.LexiconContentValidator.validateMaxWords(250)]],
            phrases: ['', [lexicon_content_validators_1.LexiconContentValidator.validateMaxPhrases(250)]],
            searchType: [''],
            query: ['', this.lexiconQueryValidatorsArray]
        });
        this.form.setValidators([
            this.validateWordsOrPhrasesRequired(),
            this.validateQueryRequired(),
            lexicon_content_validators_1.LexiconContentValidator.validateMaxWordsAndPhrases(250, this.isAdvancedSearchType())
        ]);
    }
    set validationError(error) {
        if (error) {
            this.form.get('query').setErrors({ apiError: { apiError: error.validationError } });
        }
        else {
            this.onValidateSuccess.emit();
        }
    }
    validateWordsOrPhrasesRequired() {
        return (group) => {
            const words = group.get('words').value;
            const phrases = group.get('phrases').value;
            const isWordOrPhraseValid = !words && !phrases;
            if (this.form.dirty) {
                return this.isBasicSearchType() && isWordOrPhraseValid
                    ? { wordOrPhrasesRequired: true }
                    : null;
            }
            return null;
        };
    }
    validateQueryRequired() {
        return (group) => {
            const query = group.get('query').value;
            return this.isAdvancedSearchType() && !query ? { queryRequired: true } : null;
        };
    }
    ngOnInit() {
        super.ngOnInit();
        this.form.patchValue({
            words: this.words,
            phrases: this.phrases,
            searchType: this.searchType || lexicon_1.LexiconSearchType.ADVANCED,
            query: this.query
        });
    }
    isAdvancedSearchType() {
        return this.form.value.searchType === lexicon_1.LexiconSearchType.ADVANCED;
    }
    isEditingAdvancedSearch() {
        return this.isEditMode && this.isAdvancedSearchType();
    }
    isBasicSearchType() {
        return this.form.value.searchType === lexicon_1.LexiconSearchType.BASIC;
    }
    isEditingBasicSearch() {
        return this.isEditMode && this.isBasicSearchType();
    }
    disableBasicFields() {
        this.form.controls['query'].enable();
        this.form.controls['words'].disable();
        this.form.controls['phrases'].disable();
    }
    disableAdvancedFields() {
        this.form.controls['query'].disable();
        this.form.controls['words'].enable();
        this.form.controls['phrases'].enable();
    }
}
exports.LexiconContentComponent = LexiconContentComponent;
