"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgMalwareReportComponent = void 0;
const table_store_1 = require("@mimecast-ui/table-store");
const export_actions_1 = require("./actions/export.actions");
const malware_report_list_static_values_1 = require("./malware-report-list.static-values");
const swg_activity_report_static_value_1 = require("../activity-report/component/list/swg-activity-report.static-value");
class SwgMalwareReportComponent extends table_store_1.TableBaseComponent {
    constructor(store, tabService, swgService) {
        super(store, 'SwgMalwareReport');
        this.store = store;
        this.tabService = tabService;
        this.swgService = swgService;
        this.searchFilters = malware_report_list_static_values_1.searchFilters;
        this.dateRangePickerConfig = swg_activity_report_static_value_1.swgActivityReportDateRangePickerConfig;
        this.columnsToStartHidden = [...malware_report_list_static_values_1.columnsToStartHidden];
        this.columnsAlwaysVisible = malware_report_list_static_values_1.columnsAlwaysVisible;
        const tab = this.tabService.getSelectedTab();
        tab.onRefresh = () => {
            this.runFilters();
        };
        this.hasSwgFull = this.swgService.hasWebSecurityAccountPackage();
        this.privateIpInReportSupport = this.swgService.hasPrivateIpReportSupport();
        this.supervisedUserEnabled = this.swgService.hasSupervisedUserEnabled();
        this.columns = malware_report_list_static_values_1.columns;
        this.columnsListForExport = malware_report_list_static_values_1.columns;
        this.filterSearchOptions = malware_report_list_static_values_1.filterSearchOptions;
        this.searchFilters = malware_report_list_static_values_1.searchFilters;
        if (!this.hasSwgFull) {
            this.columns = this.columns.filter(x => ['username', 'discoveryMethod', 'privateIp', 'deviceName'].indexOf(x) === -1);
            this.filterSearchOptions = malware_report_list_static_values_1.filterSearchOptions.filter(x => ['username', 'discoveryMethod', 'privateIp', 'deviceName'].indexOf(x.value) === -1);
            this.searchFilters = this.searchFilters.filter(x => x.name !== 'contextType');
        }
        if (!this.supervisedUserEnabled && this.searchFilters.find(x => x.name === 'contextType')) {
            this.searchFilters.find(x => x.name === 'contextType').categories = this.searchFilters
                .find(x => x.name === 'contextType')
                .categories.filter(x => x.name !== 'supervisedUser');
        }
        if (!this.privateIpInReportSupport) {
            this.columns = this.columns.filter(x => ['deviceName', 'privateIp', 'publicIp'].indexOf(x) === -1);
            this.filterSearchOptions = this.filterSearchOptions.filter((x) => x.value !== 'privateIp' && x.value !== 'publicIp' && x.value !== 'deviceName');
        }
        else {
            this.columns = this.columns.filter(x => ['sourceIp'].indexOf(x) === -1);
            this.filterSearchOptions = this.filterSearchOptions.filter((x) => x.value !== 'sourceIp');
        }
        this.columnsListForExport = [...this.columns].filter(x => x !== 'action');
    }
    ngOnInit() {
        this.filtersDateEntry = this.dateRangePickerConfig.dateRanges[0];
        this.dispatchLoadAction(this.getFilterDate());
    }
    onExportClicked() {
        if (!this.currentFilters) {
            this.filtersDateEntry = this.dateRangePickerConfig.dateRanges[0];
            this.dispatchFilterAction(this.getFilterDate(), {
                pageSize: this.currentPagination.pageSize,
                pageNumber: undefined,
                pageToken: undefined
            });
        }
        this.store.dispatch(new export_actions_1.SwgDownloadMalwareReportListAction(this.columnsListForExport));
    }
    onSearch(filters) {
        this.filterSearch = { searchBy: { fieldName: filters.filterBy, value: filters.search } };
        this.runFilters();
    }
    onSearchClear() {
        this.filterSearch = undefined;
        this.runFilters();
    }
    onDateRangeFilterChange(value) {
        if (!value.range) {
            return;
        }
        this.filtersDateEntry = value;
        this.runFilters();
    }
    getFilterDate() {
        return this.filtersDateEntry.label === 'DATE_PICKER_RANGE_ALL_TIME'
            ? null
            : this.transformDateRangeFilter(this.filtersDateEntry.rangeFn());
    }
    onBundleFilterChange(filters) {
        const contextTypes = [];
        const reasonTypes = [];
        const avCategories = [];
        const securityCategories = [];
        Object.keys(filters).forEach(i => {
            const filter = filters[i];
            if (filter.value) {
                // The API doesn't recognise contextType as a valid reasonType.
                // Therefore, if the filter is contextType, don't add it as a reasonType.
                if (filter.name !== 'contextType') {
                    reasonTypes.push(filter.name);
                }
                if (filter.name === 'securitycategory') {
                    filter.categories.forEach(category => {
                        if (category.value) {
                            securityCategories.push(category.name);
                        }
                    });
                }
                if (filter.name === 'antivirus') {
                    filter.categories.forEach(category => {
                        if (category.value) {
                            avCategories.push(category.name);
                        }
                    });
                }
                if (filter.name === 'contextType') {
                    filter.categories.forEach(category => {
                        if (category.value) {
                            contextTypes.push(category.name);
                        }
                    });
                }
            }
        });
        let data;
        if (reasonTypes.length > 0 || contextTypes.length > 0) {
            data = {};
            data.reasonType = reasonTypes;
            if (securityCategories.length > 0) {
                data.category = securityCategories;
            }
            if (avCategories.length > 0) {
                data.avscanResult = avCategories;
            }
            if (contextTypes.length > 0) {
                data.contextType = contextTypes;
                // If data.reasonType is an empty array, the API won't accept it.
                // Therefore, we will remove data.reasonType in this case.
                if (data.reasonType.length === 0) {
                    data.reasonType = undefined;
                }
            }
        }
        this.filters = { filterBy: data };
        this.runFilters();
    }
    runFilters() {
        const payload = Object.assign(Object.assign(Object.assign({}, this.filterSearch), this.getFilterDate()), this.filters);
        this.onFilterChange(payload);
    }
    transformDateRangeFilter(dateRange) {
        return {
            start: dateRange.start.format(),
            end: dateRange.end.format()
        };
    }
}
exports.SwgMalwareReportComponent = SwgMalwareReportComponent;
