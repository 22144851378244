"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.getDetailsPaginationInfo = exports.getSearchFilterMetadata = exports.getTotalMessagesCount = exports.getMessages = exports.isDetailLoading = exports.isLoading = exports.getSelectedRow = exports.getState = exports.getSearchMessages = exports.reducers = void 0;
const store_1 = require("@ngrx/store");
const searchReducer = require("./search.reducer");
exports.reducers = {
    searchMessages: searchReducer.reducer
};
exports.getSearchMessages = store_1.createFeatureSelector('search-messages');
const ɵ0 = (state) => state.searchMessages;
exports.ɵ0 = ɵ0;
exports.getState = store_1.createSelector(exports.getSearchMessages, ɵ0);
exports.getSelectedRow = store_1.createSelector(exports.getState, searchReducer.getSelectedRow);
exports.isLoading = store_1.createSelector(exports.getState, searchReducer.isLoading);
exports.isDetailLoading = store_1.createSelector(exports.getState, searchReducer.isDetailLoading);
exports.getMessages = store_1.createSelector(exports.getState, searchReducer.searchMessages);
exports.getTotalMessagesCount = store_1.createSelector(exports.getState, searchReducer.getTotalMessagesCount);
exports.getSearchFilterMetadata = store_1.createSelector(exports.getState, searchReducer.getFiltersMetadata);
exports.getDetailsPaginationInfo = store_1.createSelector(exports.getState, searchReducer.getDetailsPaginationInfo);
