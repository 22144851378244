"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.urlProtectionTabs = exports.UrlProtectionTabNames = void 0;
var UrlProtectionTabNames;
(function (UrlProtectionTabNames) {
    UrlProtectionTabNames[UrlProtectionTabNames["overview"] = 0] = "overview";
    UrlProtectionTabNames[UrlProtectionTabNames["policies"] = 1] = "policies";
    UrlProtectionTabNames[UrlProtectionTabNames["urlTools"] = 2] = "urlTools";
    UrlProtectionTabNames[UrlProtectionTabNames["managedUrls"] = 3] = "managedUrls";
    UrlProtectionTabNames[UrlProtectionTabNames["customDomains"] = 4] = "customDomains";
    UrlProtectionTabNames[UrlProtectionTabNames["userAwarenessPage"] = 5] = "userAwarenessPage";
    UrlProtectionTabNames[UrlProtectionTabNames["logs"] = 6] = "logs";
})(UrlProtectionTabNames = exports.UrlProtectionTabNames || (exports.UrlProtectionTabNames = {}));
exports.urlProtectionTabs = [
    {
        url: UrlProtectionTabNames[UrlProtectionTabNames.overview],
        label: '$I18N_URL_PROTECTION_PAGE.TABS.OVERVIEW'
    },
    {
        url: UrlProtectionTabNames[UrlProtectionTabNames.policies],
        label: '$I18N_URL_PROTECTION_PAGE.TABS.POLICIES'
    },
    {
        url: UrlProtectionTabNames[UrlProtectionTabNames.urlTools],
        label: '$I18N_URL_PROTECTION_PAGE.TABS.URL_TOOLS'
    },
    {
        url: UrlProtectionTabNames[UrlProtectionTabNames.managedUrls],
        label: '$I18N_URL_PROTECTION_PAGE.TABS.MANAGED_URLS'
    },
    {
        url: UrlProtectionTabNames[UrlProtectionTabNames.customDomains],
        label: '$I18N_URL_PROTECTION_PAGE.TABS.CUSTOM_DOMAINS'
    },
    {
        url: UrlProtectionTabNames[UrlProtectionTabNames.userAwarenessPage],
        label: '$I18N_URL_PROTECTION_PAGE.TABS.USER_AWARENESS_PAGE'
    },
    {
        url: UrlProtectionTabNames[UrlProtectionTabNames.logs],
        label: '$I18N_URL_PROTECTION_PAGE.TABS.LOGS'
    }
];
