"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchPaginationSuccessAction = exports.SearchPaginationAction = exports.FilterSuccessAction = exports.FilterAction = exports.SetDetailsPaginationInfoAction = exports.CleanSearchAction = exports.CleanMessageAction = exports.CloseSidePaneAction = exports.GetMessageDeatilsSuccessAction = exports.GetMessageDetailsAction = exports.OpenAndGetMessageDetailsAction = exports.SET_DETAILS_PAGINATION_INFO = exports.CLEAN_SEARCH = exports.CLEAN_MESSAGE = exports.CLOSE_SIDE_PANE = exports.OPEN_SIDE_PANE = exports.FILTER_FAILURE = exports.GET_MESSAGE_DETAILS_SUCCESS = exports.GET_MESSAGE_DETAILS = exports.SEARCH_PAGINATION_SUCCESS = exports.SEARCH_PAGINATION = exports.FILTER_SUCCESS = exports.FILTER = void 0;
exports.FILTER = '[services / Secure Messaging] Filter';
exports.FILTER_SUCCESS = '[Tservices / Secure Messaging] Filter Success';
exports.SEARCH_PAGINATION = '[Table Store] Search Pagination';
exports.SEARCH_PAGINATION_SUCCESS = '[Table Store] Search Pagination Success';
exports.GET_MESSAGE_DETAILS = '[ services / Secure Messaging ] get message details';
exports.GET_MESSAGE_DETAILS_SUCCESS = '[ services / Secure Messaging ] get message details success';
exports.FILTER_FAILURE = '[Table Store] Load Fail';
exports.OPEN_SIDE_PANE = '[Services / Secure Messages]  Open Side Pane';
exports.CLOSE_SIDE_PANE = '[Services / Secure Messages] Close side panel';
exports.CLEAN_MESSAGE = '[Services / Secure Messages] Clean Message';
exports.CLEAN_SEARCH = '[Services / Secure Messages] Clean Search';
exports.SET_DETAILS_PAGINATION_INFO = '[Services / Secure Messages] Set details pagination';
class OpenAndGetMessageDetailsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.OPEN_SIDE_PANE;
    }
}
exports.OpenAndGetMessageDetailsAction = OpenAndGetMessageDetailsAction;
class GetMessageDetailsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_MESSAGE_DETAILS;
    }
}
exports.GetMessageDetailsAction = GetMessageDetailsAction;
class GetMessageDeatilsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_MESSAGE_DETAILS_SUCCESS;
    }
}
exports.GetMessageDeatilsSuccessAction = GetMessageDeatilsSuccessAction;
class CloseSidePaneAction {
    constructor() {
        this.type = exports.CLOSE_SIDE_PANE;
    }
}
exports.CloseSidePaneAction = CloseSidePaneAction;
class CleanMessageAction {
    constructor() {
        this.type = exports.CLEAN_MESSAGE;
    }
}
exports.CleanMessageAction = CleanMessageAction;
class CleanSearchAction {
    constructor() {
        this.type = exports.CLEAN_SEARCH;
    }
}
exports.CleanSearchAction = CleanSearchAction;
class SetDetailsPaginationInfoAction {
    constructor(previous, current, next, currentIndex, totalNumber) {
        this.previous = previous;
        this.current = current;
        this.next = next;
        this.currentIndex = currentIndex;
        this.totalNumber = totalNumber;
        this.type = exports.SET_DETAILS_PAGINATION_INFO;
    }
}
exports.SetDetailsPaginationInfoAction = SetDetailsPaginationInfoAction;
class FilterAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.FILTER;
    }
}
exports.FilterAction = FilterAction;
class FilterSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.FILTER_SUCCESS;
    }
}
exports.FilterSuccessAction = FilterSuccessAction;
class SearchPaginationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SEARCH_PAGINATION;
    }
}
exports.SearchPaginationAction = SearchPaginationAction;
class SearchPaginationSuccessAction {
    constructor() {
        this.type = exports.SEARCH_PAGINATION_SUCCESS;
    }
}
exports.SearchPaginationSuccessAction = SearchPaginationSuccessAction;
