'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
angular.module('search-popover.controller', [])
    .controller('SearchPopoverController', [
    function () {
        const self = this;
        // ----- Function exposed to the template ---
        self.selectTab = selectTab;
        self.isActive = isActive;
        /* -------------------------------------------------------- *\
     - initialisation
     \* -------------------------------------------------------- */
        self.tabs = [
            { id: 'search-mimecaster-central', label: 'Search Mimecaster Central', tpl: 'navbar/search/search-popover/tabs/tab-1.tpl.html' },
            { id: 'search-menu-items', label: 'Search Menu Items', tpl: 'navbar/search/search-popover/tabs/tab-2.tpl.html' }
        ];
        self.selected = self.tabs[0];
        /* -------------------------------------------------------- *\
     - implementation
     \* -------------------------------------------------------- */
        function selectTab(tab, $event) {
            $event.stopPropagation();
            return self.selected = tab;
        }
        function isActive(tab) {
            return self.selected == tab ? 'active' : '';
        }
    }
]);
