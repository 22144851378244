"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SettingsEffects = void 0;
const effects_1 = require("@ngrx/effects");
const coreReducers = require("app-new/core/reducers");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const settingsAction = require("../../actions/settings/settings.actions");
const reducers = require("../../reducers");
const remediation_dashboard_static_values_1 = require("app-new/remediation/containers/remediation-dashboard.static-values");
const export_1 = require("app-new/remediation/models/export");
const settings_reducer_1 = require("../../reducers/settings/settings.reducer");
class SettingsEffects {
    constructor(actions$, remediationApiService, notificationService, translation, cookieService, tabChangeService, store$, capabilitiesService) {
        this.actions$ = actions$;
        this.remediationApiService = remediationApiService;
        this.notificationService = notificationService;
        this.translation = translation;
        this.cookieService = cookieService;
        this.tabChangeService = tabChangeService;
        this.store$ = store$;
        this.capabilitiesService = capabilitiesService;
        this.remindMeLaterCookieName = 'mc-rem-not-set-alert';
        this.remindMeLaterDisabledCookieName = 'mc-rem-disabled-alert';
        this.loadSettings$ = this.actions$.pipe(effects_1.ofType(settingsAction.GET_SETTINGS), operators_1.switchMap(() => {
            return this.remediationApiService.getSettings().pipe(operators_1.map((response) => new settingsAction.RemediationGetSettingsSuccess(response)), operators_1.catchError(error => {
                if (error.fail &&
                    error.fail.shift().errors.shift().code === 'err_remediation_settings_not_defined') {
                    return rxjs_1.of(new settingsAction.RemediationSettingsNotSet());
                }
                else {
                    this.notificationService.error({
                        duration: 0,
                        msg: this.translation.instant('$I18N_COMMON_TEXT_LINE_GLOBAL_ERROR_MESSAGE')
                    });
                    return rxjs_1.of(new settingsAction.RemediationGetSettingsFail());
                }
            }));
        }));
        this.loadDeviceSettings$ = this.actions$.pipe(effects_1.ofType(settingsAction.GET_DEVICE_SETTINGS), operators_1.switchMap(() => {
            return this.remediationApiService.getDeviceSettings().pipe(operators_1.map((response) => new settingsAction.RemediationGetDeviceSettingsSuccess(response)), operators_1.catchError(error => {
                if (error.fail &&
                    error.fail.shift().errors.shift().code === 'err_msa_settings_not_init') {
                    return rxjs_1.of(new settingsAction.RemediationDeviceSettingsNotSet());
                }
                else {
                    this.notificationService.error({
                        duration: 0,
                        msg: this.translation.instant('$I18N_COMMON_TEXT_LINE_GLOBAL_ERROR_MESSAGE')
                    });
                    return rxjs_1.of(new settingsAction.RemediationGetDeviceSettingsFail());
                }
            }));
        }));
        this.updateDeviceSettings$ = this.actions$.pipe(effects_1.ofType(settingsAction.UPDATE_DEVICE_SETTINGS), operators_1.switchMap((action) => {
            return this.remediationApiService
                .updateDeviceSettings({
                enableFileRemediation: action.payload.enableFileRemediation,
                enableFileRemediationNotifications: action.payload.enableFileRemediationNotifications
            })
                .pipe(operators_1.map(response => new settingsAction.RemediationUpdateDeviceSettingsSuccess(response)), operators_1.catchError(() => {
                this.notificationService.error({
                    duration: 0,
                    msg: this.translation.instant('$I18N_COMMON_TEXT_LINE_GLOBAL_ERROR_MESSAGE')
                });
                return rxjs_1.of(new settingsAction.RemediationUpdateDeviceSettingsFail());
            }));
        }));
        this.remediationDisabled$ = this.actions$.pipe(effects_1.ofType(settingsAction.SETTINGS_NOT_SET, settingsAction.GET_SETTINGS_SUCCESS), operators_1.withLatestFrom(this.capabilitiesService.hasCapability(remediation_dashboard_static_values_1.IEP_CAPABILITY_NAME), this.capabilitiesService.hasCapability(remediation_dashboard_static_values_1.MEP_CAPABILITY_NAME), this.capabilitiesService.hasCapability(remediation_dashboard_static_values_1.MEP_INTEGRATION_SWITCH_NAME), this.capabilitiesService.hasCapability(remediation_dashboard_static_values_1.HIDE_MEP_CAPABILITY_NAME)), operators_1.tap(([action, isIEPEnabled, isMEPEnabled, isMEPFeatureEnabled, hideMEPSettings]) => {
            // @ts-ignore
            let settings = action.payload;
            if (!settings) {
                settings = settings_reducer_1.initialState.settings;
            }
            if (!settings.enabled && isIEPEnabled) {
                this.remediationDisabled(this.remindMeLaterDisabledCookieName, export_1.RemediationType.IEP);
            }
            if (!settings.mepEnabled && isMEPEnabled && isMEPFeatureEnabled && !hideMEPSettings) {
                this.remediationDisabled(this.remindMeLaterDisabledCookieName, export_1.RemediationType.MEP);
            }
        }));
        // @ts-ignore
        this.updateSettings$ = this.actions$.pipe(effects_1.ofType(settingsAction.UPDATE_SETTINGS), operators_1.withLatestFrom(this.store$.select(coreReducers.getAccount), this.store$.select(reducers.getSettings)), operators_1.switchMap(([action, account, settingsState]) => {
            // @ts-ignore
            const settings = Object.assign(Object.assign({}, settingsState), action.payload);
            // @ts-ignore
            if (action.payload.mode && action.payload.mode === 'not_set') {
                settings.mode = 'notify_only';
            }
            // @ts-ignore
            if (action.payload.notifyGroup && action.payload.notifyGroup.id) {
                // @ts-ignore
                settings.notifyGroup = action.payload.notifyGroup.id;
            }
            else {
                settings.notifyGroup = null;
            }
            // @ts-ignore
            if (action.payload.excludeGroup && action.payload.excludeGroup.id) {
                // @ts-ignore
                settings.excludeGroup = action.payload.excludeGroup.id;
            }
            else {
                settings.excludeGroup = null;
            }
            // @ts-ignore
            settings.meta = {
                accountCode: account.accountCode
            };
            // @ts-ignore
            delete settings.enableFileRemediation;
            // @ts-ignore
            delete settings.enableFileRemediationNotifications;
            let apiRequest;
            if (settings.id) {
                apiRequest = this.remediationApiService.updateSettings(settings);
            }
            else {
                apiRequest = this.remediationApiService.createSettings(settings);
            }
            return apiRequest.pipe(operators_1.map(response => {
                this.notificationService.success({
                    msg: this.translation.instant('$I18N_REMEDIATION_SETTINGS_TAB.NOTIFICATION.SETTINGS_UPDATED_SUCCESS')
                });
                // @ts-ignore
                return new settingsAction.RemediationUpdateSettingsSuccess(response);
            }), operators_1.catchError(() => {
                this.notificationService.error({
                    duration: 0,
                    msg: this.translation.instant('$I18N_COMMON_TEXT_LINE_GLOBAL_ERROR_MESSAGE')
                });
                return rxjs_1.of(new settingsAction.RemediationUpdateSettingsFail());
            }));
        }));
    }
    remediationDisabled(cookieName = this.remindMeLaterCookieName, remediationType) {
        const updateSettings = {
            isAutoClose: true,
            type: 'button',
            label: this.translation.instant('$I18N_REMEDIATION.SETTINGS.UPDATE_SETTINGS'),
            callback: () => {
                return this.tabChangeService.setActiveTab(3, 'settings');
            }
        };
        const remindMeLater = {
            isAutoClose: true,
            type: 'link',
            label: this.translation.instant('$I18N_REMEDIATION.SETTINGS.REMIND_ME_LATER'),
            callback: () => {
                this.cookieService.set(cookieName, 'true');
            }
        };
        if (!this.cookieService.get(cookieName)) {
            let message = `<strong>${this.translation.instant('$I18N_REMEDIATION.SETTINGS.REMEDIATION_IS_DISABLED')}</strong> ${this.translation.instant('$I18N_REMEDIATION.SETTINGS.PROTECT_USERS')}`;
            if (remediationType === export_1.RemediationType.MEP) {
                message = `<strong>${this.translation.instant('$I18N_REMEDIATION.SETTINGS.MEP_REMEDIATION_IS_DISABLED')}</strong> ${this.translation.instant('$I18N_REMEDIATION.SETTINGS.PROTECT_USERS')}`;
            }
            this.notificationService.warning({
                duration: 0,
                msg: message,
                actions: [updateSettings, remindMeLater]
            });
        }
    }
}
__decorate([
    effects_1.Effect()
], SettingsEffects.prototype, "loadSettings$", void 0);
__decorate([
    effects_1.Effect()
], SettingsEffects.prototype, "loadDeviceSettings$", void 0);
__decorate([
    effects_1.Effect()
], SettingsEffects.prototype, "updateDeviceSettings$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], SettingsEffects.prototype, "remediationDisabled$", void 0);
__decorate([
    effects_1.Effect()
], SettingsEffects.prototype, "updateSettings$", void 0);
exports.SettingsEffects = SettingsEffects;
