"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportService = void 0;
class ExportService {
    constructor(translateService) {
        this.translateService = translateService;
    }
    mapExportedColumnsItem(columnMapParams) {
        const { columns, translationKey } = columnMapParams;
        return columns.map((item) => ({
            key: item,
            label: this.translateService.instant(`${translationKey}.${item.toUpperCase()}`)
        }));
    }
    /**
     * injects an iframe in the dom to force a download , the url must have session params to work
     *
     * @param fileUrl
     */
    injectIframeToDownloadFile(fileUrl) {
        const ifrm = document.createElement('iframe');
        ifrm.style.display = 'none';
        document.body.appendChild(ifrm);
        ifrm.src = fileUrl;
    }
}
exports.ExportService = ExportService;
