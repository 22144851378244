"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AsideEffects = void 0;
const detail_view_aside_component_1 = require("../../components/detail-view-aside/detail-view-aside.component");
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const reducers = require("../../reducers");
const asideAction = require("../../actions/aside.action");
const asidePaginationAction = require("../../actions/pagination.actions");
const operators_1 = require("rxjs/operators");
const list_impl_service_1 = require("../../containers/list/service/list.impl.service");
const delete_modal_component_1 = require("../../components/delete-modal/delete-modal.component");
const notification_actions_1 = require("app-new/components/notification/actions/notification.actions");
const table_store_1 = require("@mimecast-ui/table-store");
class AsideEffects {
    constructor(actions$, asideService, attributesService, translateService, store, modalService, tableStore) {
        this.actions$ = actions$;
        this.asideService = asideService;
        this.attributesService = attributesService;
        this.translateService = translateService;
        this.store = store;
        this.modalService = modalService;
        this.tableStore = tableStore;
        this.KEY_DIRECTORIES_LIST = '$18N_ATTRIBUTES_DIRECTORIES_LIST';
        this.loadAttributeDetails$ = this.actions$.pipe(effects_1.ofType(asideAction.GET_ATTRIBUTE_DETAILS), operators_1.switchMap((action) => this.loadAttributeDetails(action)));
        this.createAttribute$ = this.actions$.pipe(effects_1.ofType(asideAction.OPEN_CREATE_ATTRIBUTE), operators_1.switchMap(() => {
            if (!this.asideInstance) {
                this.asideInstance = this.asideService.open(detail_view_aside_component_1.DetailViewAsideComponent, {
                    position: 'right',
                    size: 'md',
                    hasBackdrop: true
                });
                this.asideInstance.componentInstance.toggleMode();
                this.asideInstance.afterClose().subscribe(() => {
                    this.asideInstance = undefined;
                });
            }
            const attribute = {
                id: null,
                prompt: 'New Attribute Prompt',
                promptGroup: 'General Attributes',
                promptType: 'small_text_capture_50_pixels',
                promptOrder: 0,
                promptOptions: '',
                allowedInTableViews: false
            };
            return rxjs_1.of(new asideAction.GetAttributeDetailsSuccess(attribute));
        }));
        // @ts-ignore
        this.saveAttribute$ = this.actions$.pipe(effects_1.ofType(asideAction.SAVE_ATTRIBUTE), operators_1.withLatestFrom(this.store.select(reducers.getAttributeDetails), this.tableStore.select(table_store_1.tableReducer.getCurrentFilters(list_impl_service_1.ListImplService.STORE_ID)), this.tableStore.select(table_store_1.tableReducer.getCurrentPagination(list_impl_service_1.ListImplService.STORE_ID))), operators_1.switchMap(([action, attributeFromStore, currentFilters, currentPagination]) => {
            // @ts-ignore
            const id = action.attribute.id;
            if (!id) {
                // @ts-ignore
                return this.newAttribute(action);
            }
            else {
                // @ts-ignore
                return this.updateAttribute(action, attributeFromStore, currentFilters, currentPagination);
            }
        }));
        this.saveNewAttributeSuccess$ = this.actions$.pipe(effects_1.ofType(asideAction.SAVE_NEW_ATTRIBUTE_SUCCESS), operators_1.withLatestFrom(this.tableStore.select(table_store_1.tableReducer.getCurrentFilters(list_impl_service_1.ListImplService.STORE_ID)), this.tableStore.select(table_store_1.tableReducer.getCurrentPagination(list_impl_service_1.ListImplService.STORE_ID))), operators_1.switchMap(([, currentFilters, currentPagination]) => {
            return [
                new notification_actions_1.NotificationShowAction({
                    type: 'success',
                    config: {
                        msg: this.translateService.instant(`${this.KEY_DIRECTORIES_LIST}.SUCCESS.SAVED`)
                    }
                }),
                new table_store_1.LoadAction({
                    tableId: list_impl_service_1.ListImplService.STORE_ID,
                    pagination: { pageToken: '', pageSize: currentPagination.pageSize, pageNumber: 0 },
                    query: currentFilters
                })
            ];
        }));
        this.confirmDeleteAttribute$ = this.actions$.pipe(effects_1.ofType(asideAction.DELETE_ATTRIBUTE_CONFIRM), operators_1.switchMap((action) => {
            if (this.asideInstance) {
                this.asideInstance.close();
            }
            const modalConfig = {};
            modalConfig.size = 'md';
            modalConfig.hasBackdrop = true;
            modalConfig.disableClose = true;
            modalConfig.data = action.attribute;
            return this.modalService
                .open(delete_modal_component_1.AttributeDeleteModalComponent, modalConfig)
                .afterClose()
                .pipe(operators_1.map((result) => result ? new asideAction.DeleteAttribute(action.attribute) : { type: 'no action' }));
        }));
        this.deleteAttribute$ = this.actions$.pipe(effects_1.ofType(asideAction.DELETE_ATTRIBUTE), operators_1.switchMap((action) => {
            return this.attributesService.deleteAttribute(action.attribute).pipe(operators_1.map(response => {
                return new asideAction.DeleteAttributeSuccess(response);
            }), operators_1.catchError(error => rxjs_1.of(new asideAction.DeleteAttributeFailure(error))));
        }));
        this.deleteAttributeSuccess$ = this.actions$.pipe(effects_1.ofType(asideAction.DELETE_ATTRIBUTE_SUCCESS), operators_1.withLatestFrom(this.tableStore.select(table_store_1.tableReducer.getCurrentFilters(list_impl_service_1.ListImplService.STORE_ID)), this.tableStore.select(table_store_1.tableReducer.getCurrentPagination(list_impl_service_1.ListImplService.STORE_ID))), operators_1.switchMap(([, currentFilters, currentPagination]) => {
            return rxjs_1.of(new table_store_1.LoadAction({
                tableId: list_impl_service_1.ListImplService.STORE_ID,
                pagination: { pageToken: '', pageSize: currentPagination.pageSize, pageNumber: 0 },
                query: currentFilters
            }));
        }));
        this.deleteAttributeFailure$ = this.actions$.pipe(effects_1.ofType(asideAction.DELETE_ATTRIBUTE_FAILURE), operators_1.switchMap(() => {
            return rxjs_1.of(new notification_actions_1.NotificationShowAction({
                type: 'error',
                config: {
                    msg: this.translateService.instant(`${this.KEY_DIRECTORIES_LIST}.ERRORS.DELETE`)
                }
            }));
        }));
    }
    loadAttributeDetails(action) {
        if (!this.asideInstance) {
            this.asideInstance = this.asideService.open(detail_view_aside_component_1.DetailViewAsideComponent, {
                position: 'right',
                size: 'md',
                hasBackdrop: true
            });
            this.asideInstance.beforeClose().subscribe(() => {
                this.store.dispatch(new asidePaginationAction.PaginationSetSelectedRow(null));
            });
            this.asideInstance.afterClose().subscribe(() => {
                this.asideInstance = undefined;
            });
        }
        const config = {
            id: action.row.id
        };
        this.store.dispatch(new asidePaginationAction.PaginationSetSelectedRow(action.row));
        return this.attributesService.getAttributeDetails(config).pipe(operators_1.map((response) => {
            return new asideAction.GetAttributeDetailsSuccess(response);
        }), operators_1.catchError(error => {
            return [
                ...this.errorNotification(error),
                ...[new asideAction.GetAttributeDetailsFailure(error)]
            ];
        }));
    }
    newAttribute(action) {
        return this.attributesService.createAttribute(action.attribute).pipe(operators_1.map((response) => {
            if (this.asideInstance) {
                this.asideInstance.close();
            }
            return new asideAction.SaveNewAttributeSuccess(response);
        }), operators_1.catchError(error => {
            return [...this.errorNotification(error), ...[new asideAction.SaveAttributeFailure(error)]];
        }));
    }
    updateAttribute(action, attributeFromStore, 
    // @ts-ignore
    currentFilters, 
    // @ts-ignore
    currentPagination) {
        const attributeForUpdate = {};
        Object.keys(attributeFromStore).forEach(key => {
            if (attributeFromStore[key] !== action.attribute[key]) {
                attributeForUpdate[key] = action.attribute[key];
            }
        });
        // @ts-ignore
        if (attributeForUpdate && !attributeForUpdate.id) {
            attributeForUpdate['id'] = attributeFromStore.id;
        }
        return this.attributesService.updateAttribute(attributeForUpdate).pipe(operators_1.map((response) => {
            if (this.asideInstance) {
                this.asideInstance.componentInstance.toggleMode();
                this.asideInstance.afterClose().subscribe(() => {
                    this.store.dispatch(new table_store_1.LoadAction({
                        tableId: list_impl_service_1.ListImplService.STORE_ID,
                        pagination: { pageToken: '', pageSize: currentPagination.pageSize, pageNumber: 0 },
                        query: currentFilters
                    }));
                    this.asideInstance = undefined;
                });
            }
            return new asideAction.SaveAttributeSuccess(response);
        }), operators_1.catchError(error => {
            return [...this.errorNotification(error), ...[new asideAction.SaveAttributeFailure(error)]];
        }));
    }
    keyExists(errorCode) {
        if (!this.apiErrors) {
            this.apiErrors = this.translateService.instant(`${this.KEY_DIRECTORIES_LIST}.API_ERRORS`);
        }
        return !!this.apiErrors[errorCode.toUpperCase()];
    }
    errorNotification(error) {
        if (error && error.firstFail && error.firstFail.errors && error.firstFail.errors.length > 0) {
            const errors = [];
            error.firstFail.errors.forEach((err) => {
                // @ts-ignore
                if (this.keyExists(err.code)) {
                    errors.push(new notification_actions_1.NotificationShowAction({
                        type: 'error',
                        config: {
                            msg: this.translateService.instant(
                            // @ts-ignore
                            `${this.KEY_DIRECTORIES_LIST}.API_ERRORS.${err.code.toUpperCase()}`)
                        }
                    }));
                }
            });
            if (errors.length > 0) {
                return errors;
            }
            else {
                return [this.genericErrorNotification()];
            }
        }
        else {
            return [this.genericErrorNotification()];
        }
    }
    genericErrorNotification() {
        return new notification_actions_1.NotificationShowAction({
            type: 'error',
            config: {
                msg: this.translateService.instant('$I18N_COMMON_TEXT_LINE_GLOBAL_ERROR_MESSAGE')
            }
        });
    }
}
__decorate([
    effects_1.Effect()
], AsideEffects.prototype, "loadAttributeDetails$", void 0);
__decorate([
    effects_1.Effect()
], AsideEffects.prototype, "createAttribute$", void 0);
__decorate([
    effects_1.Effect()
], AsideEffects.prototype, "saveAttribute$", void 0);
__decorate([
    effects_1.Effect()
], AsideEffects.prototype, "saveNewAttributeSuccess$", void 0);
__decorate([
    effects_1.Effect()
], AsideEffects.prototype, "confirmDeleteAttribute$", void 0);
__decorate([
    effects_1.Effect()
], AsideEffects.prototype, "deleteAttribute$", void 0);
__decorate([
    effects_1.Effect()
], AsideEffects.prototype, "deleteAttributeSuccess$", void 0);
__decorate([
    effects_1.Effect()
], AsideEffects.prototype, "deleteAttributeFailure$", void 0);
exports.AsideEffects = AsideEffects;
