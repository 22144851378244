<div class="mc-url-protection-policy-block">
  <mc-wizard-step-header label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.STEP_TITLE">
  </mc-wizard-step-header>

  <form class="form-horizontal" mcDefaultLabelClass="col-sm-3" mcDefaultControlContainerClass="col-sm-9"
    [formGroup]="urlProtectionPolicyDetailsForm">

    <mc-text-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.POLICY_NAME"
      errorPrefix="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.VALIDATIONS.POLICY_NAME"
      placeholder="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.PLACEHOLDERS.NAME" formControlName="name"
      data-unit-test="name" data-cy-test="name" [setFocus]="true" required>
    </mc-text-field>

    <mc-text-area-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.POLICY_DESCRIPTION"
      formControlName="description" data-unit-test="description" data-cy-test="description"
      placeholder="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.PLACEHOLDERS.DESCRIPTION">
    </mc-text-area-field>

    <mc-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.BYPASS_POLICY"
      popoverPlacement="top" class="mc-custom-label" data-unit-test="bypassPolicy" data-cy-test="bypassPolicy"
      helpPopoverContent="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.TOOLTIPS.BYPASS' | translate }}">
      <mc-switch formControlName="bypassPolicy" role="switch" showLabel="true" [isDisabled]="isEditMode">
      </mc-switch>
    </mc-field>

    <mc-field *ngIf="isBypass()" data-unit-test="bypassPolicyApplication" data-cy-test="bypassPolicyApplication"
      label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.BYPASS_APPLICATION">
      <div class="radio panel-half-padding-bottom" *ngFor="let option of bypassApplicationOptions">
        <label>
          <input type="radio" name="bypassPolicyApplication" formControlName="bypassPolicyApplication"
            [value]="option.id" />
          {{ option.label | translate }}
        </label>
        <div class="mc-group-selector-desc">
          {{option.description | translate}}</div>
      </div>
    </mc-field>

    <mc-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.POLICY_OVERRIDE"
      popoverPlacement="top" class="mc-custom-label" data-unit-test="policyOverride" data-cy-test="policyOverride"
      helpPopoverContent="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.TOOLTIPS.POLICY_OVERRIDE' | translate }}">
      <mc-switch formControlName="policyOverride" role="switch" showLabel="true">
      </mc-switch>
    </mc-field>

    <mc-field *ngIf="isAAAOrFAAUser"
      label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.POLICY_ENFORCEMENT"
      class="mc-custom-label" data-unit-test="policyEnforcement">
      <mc-switch formControlName="policyEnforcement" role="switch" showLabel="true">
      </mc-switch>
    </mc-field>

    <mc-date-range-picker label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.POLICY_DURATION"
      data-unit-test="duration" formControlName="ulrProtectionPolicyDuration" [config]="durationOptions"
      [useCurrentMoment]="true"
      [helpPopoverContent]="dateRangePopoverContent"
      popoverPlacement="top">
    </mc-date-range-picker>
  </form>

</div>

<ng-template #dateRangePopoverContent>
  <p>{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.DATE_RANGE_PICKER_POPOVER.TITLE' | translate }}</p>
  <p>
    <strong>{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.DURATION_VALUES.CONTINUOUS' | translate }}</strong> - 
    {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.DATE_RANGE_PICKER_POPOVER.CONTINUOUS_DESCRIPTION' | translate }}
  </p>
  <p>
    <strong>{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.DURATION_VALUES.CONTINUOUS_FROM_A_DATE' | translate }}</strong> - 
    {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.DATE_RANGE_PICKER_POPOVER.CONTINUOUS_FROM_A_DATE_DESCRIPTION' | translate }}
  </p>
  <span>
    <strong>{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.DURATION_VALUES.DATE_RANGE' | translate }}</strong> - 
    {{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.DETAILS.LABELS.DATE_RANGE_PICKER_POPOVER.DATE_RANGE_DESCRIPTION' | translate }}
  </span>
</ng-template>