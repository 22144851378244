"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageProcessingEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const actions = require("../actions");
const reducers = require("../reducers/message-processing");
const api_1 = require("app-new/components/export/api");
const commonActions = require("../../common/actions");
const notification_actions_1 = require("app-new/components/notification/actions/notification.actions");
const message_processing_notifications_1 = require("./message-processing.notifications");
class MessageProcessingEffects {
    constructor(actions$, service, store$) {
        this.actions$ = actions$;
        this.service = service;
        this.store$ = store$;
        this.loadProcessingItems$ = this.actions$.pipe(effects_1.ofType(actions.PROCESSING_MESSAGE_REQUEST, actions.PROCESSING_MESSAGE_PAGINATION, actions.PROCESSING_MESSAGE_REFRESH, ...Object.keys(message_processing_notifications_1.notifications)), operators_1.withLatestFrom(this.store$.select(reducers.getMessageProcessingSearch), this.store$.select(reducers.getMessageProcessingRequestPage)), operators_1.switchMap(([, request, pagination]) => {
            return this.service.getItems(request, pagination).pipe(operators_1.map(response => new actions.MessageProcessingSuccessAction(response)), operators_1.catchError(() => rxjs_1.of(new actions.MessageProcessingFailAction())));
        }));
        this.displayRow$ = this.actions$.pipe(effects_1.ofType(actions.PROCESSING_MESSAGE_SELECT_ROW), operators_1.withLatestFrom(this.store$.select(reducers.getMessageProcessingItems)), operators_1.switchMap(([action, items]) => {
            if (action.payload) {
                return rxjs_1.of(new commonActions.ShowMessageInfoAction(action.payload, items, false));
            }
            return rxjs_1.EMPTY;
        }));
        this.rejectEmails$ = this.actions$.pipe(effects_1.ofType(actions.PROCESSING_MESSAGE_REJECT_EMAILS), operators_1.switchMap((action) => {
            return this.service.rejectEmails(action.payload).pipe(operators_1.map(() => new actions.MessageProcessingRejectEmailsSuccessAction(action.payload)), operators_1.catchError(() => rxjs_1.of(new actions.MessageProcessingRejectEmailsFailAction(action.payload))));
        }));
        this.retryEmails$ = this.actions$.pipe(effects_1.ofType(actions.PROCESSING_MESSAGE_RETRY_EMAILS), operators_1.switchMap((action) => {
            return this.service.retryEmails(action.payload).pipe(operators_1.map(() => new actions.MessageProcessingRetryEmailsSuccessAction(action.payload)), operators_1.catchError(() => rxjs_1.of(new actions.MessageProcessingRetryEmailsFailAction(action.payload))));
        }));
        this.earlyBounceEmails$ = this.actions$.pipe(effects_1.ofType(actions.PROCESSING_MESSAGE_EARLY_BOUNCE_EMAILS), operators_1.switchMap((action) => {
            return this.service.earlyBounceEmails(action.payload).pipe(operators_1.map(() => new actions.MessageProcessingEarlyBounceEmailsSuccessAction(action.payload)), operators_1.catchError(() => rxjs_1.of(new actions.MessageProcessingEarlyBounceEmailsFailAction(action.payload))));
        }));
        this.export$ = this.actions$.pipe(effects_1.ofType(actions.PROCESSING_MESSAGE_EXPORT), operators_1.withLatestFrom(this.store$.select(reducers.getMessageProcessingSearch)), operators_1.map(([action, search]) => {
            const apiSearch = this.service.transformToServerRequest(search);
            return new api_1.OpenSidePanelExportApiAction('/api/gateway/export-processing-messages', action.payload, apiSearch, '$I18N_MESSAGE_CENTER_MESSAGE_PROCESSING_EXPORT');
        }));
        this.notifications$ = this.actions$.pipe(effects_1.ofType(...Object.keys(message_processing_notifications_1.notifications)), operators_1.map((action) => {
            const notification = message_processing_notifications_1.notifications[action.type];
            return new notification_actions_1.NotificationShowAction({
                config: { msg: notification.message },
                type: notification.type
            });
        }));
    }
}
__decorate([
    effects_1.Effect()
], MessageProcessingEffects.prototype, "loadProcessingItems$", void 0);
__decorate([
    effects_1.Effect()
], MessageProcessingEffects.prototype, "displayRow$", void 0);
__decorate([
    effects_1.Effect()
], MessageProcessingEffects.prototype, "rejectEmails$", void 0);
__decorate([
    effects_1.Effect()
], MessageProcessingEffects.prototype, "retryEmails$", void 0);
__decorate([
    effects_1.Effect()
], MessageProcessingEffects.prototype, "earlyBounceEmails$", void 0);
__decorate([
    effects_1.Effect()
], MessageProcessingEffects.prototype, "export$", void 0);
__decorate([
    effects_1.Effect()
], MessageProcessingEffects.prototype, "notifications$", void 0);
exports.MessageProcessingEffects = MessageProcessingEffects;
