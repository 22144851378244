"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectionTestType = exports.EncryptionMode = exports.ServerSubType = exports.ServerType = exports.TestStatus = exports.HostType = exports.ACKNOWLEDGE_DISABLED_ACCOUNTS_ON = exports.ENCRYPTION_MODE_NONE = void 0;
exports.ENCRYPTION_MODE_NONE = 'none';
exports.ACKNOWLEDGE_DISABLED_ACCOUNTS_ON = 'on';
var HostType;
(function (HostType) {
    HostType["PRIMARY"] = "primary";
    HostType["ALTERNATE"] = "alternate";
})(HostType = exports.HostType || (exports.HostType = {}));
var TestStatus;
(function (TestStatus) {
    TestStatus["PASS"] = "pass";
    TestStatus["FAIL"] = "fail";
    TestStatus["WARN"] = "warn";
    TestStatus["NONE"] = "none";
})(TestStatus = exports.TestStatus || (exports.TestStatus = {}));
var ServerType;
(function (ServerType) {
    ServerType[ServerType["active_directory"] = 0] = "active_directory";
    ServerType[ServerType["domino"] = 1] = "domino";
    ServerType[ServerType["office_365"] = 2] = "office_365";
    ServerType[ServerType["ad_push"] = 3] = "ad_push";
    ServerType[ServerType["google_directory"] = 4] = "google_directory";
})(ServerType = exports.ServerType || (exports.ServerType = {}));
var ServerSubType;
(function (ServerSubType) {
    ServerSubType[ServerSubType["standard"] = 0] = "standard";
    ServerSubType[ServerSubType["gov"] = 1] = "gov";
})(ServerSubType = exports.ServerSubType || (exports.ServerSubType = {}));
var EncryptionMode;
(function (EncryptionMode) {
    EncryptionMode[EncryptionMode["strict"] = 0] = "strict";
    EncryptionMode[EncryptionMode["relaxed"] = 1] = "relaxed";
})(EncryptionMode = exports.EncryptionMode || (exports.EncryptionMode = {}));
var ConnectionTestType;
(function (ConnectionTestType) {
    ConnectionTestType["VALIDATION"] = "validation";
    ConnectionTestType["CONNECTIVITY"] = "connectivity";
    ConnectionTestType["CERTIFICATE"] = "certificate";
    ConnectionTestType["AUTHENTICATION"] = "authentication";
    ConnectionTestType["EMAIL"] = "email";
})(ConnectionTestType = exports.ConnectionTestType || (exports.ConnectionTestType = {}));
