"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CleanStore = exports.LoadSuccessOneDriveRootFolder = exports.LoadOneDriveRootFolder = exports.LoadSuccessOneDriveFoldersAction = exports.LoadOneDriveFoldersAction = exports.FindSuccessExchangeFoldersAction = exports.FindExchangeFoldersAction = exports.LoadSuccessExchangeContactFoldersAction = exports.LoadExchangeContactFoldersAction = exports.LoadSuccessExchangeCalendarFoldersAction = exports.LoadExchangeCalendarFoldersAction = exports.LoadSuccessExchangeMailFoldersAction = exports.LoadExchangeMailFoldersAction = exports.LoadSuccessExchangeFoldersAction = exports.LoadExchangeFoldersAction = exports.LoadSuccessExchangeRootFolder = exports.LoadExchangeRootFolder = exports.CLEAN_STORE = exports.LOAD_SUCCESS_ONE_DRIVE_ROOT_FOLDER = exports.LOAD_ONE_DRIVE_ROOT_FOLDER = exports.LOAD_SUCCESS_ONE_DRIVE_FOLDERS = exports.LOAD_ONE_DRIVE_FOLDERS = exports.LOAD_SUCCESS_EXCHANGE_CONTACT_FOLDERS = exports.LOAD_EXCHANGE_CONTACT_FOLDERS = exports.LOAD_SUCCESS_EXCHANGE_CALENDAR_FOLDERS = exports.LOAD_EXCHANGE_CALENDAR_FOLDERS = exports.LOAD_SUCCESS_EXCHANGE_MAIL_FOLDERS = exports.LOAD_EXCHANGE_MAIL_FOLDERS = exports.LOAD_SUCCESS_EXCHANGE_FOLDERS = exports.LOAD_EXCHANGE_FOLDERS = exports.LOAD_SUCCESS_EXCHANGE_ROOT_FOLDER = exports.LOAD_EXCHANGE_ROOT_FOLDER = exports.FIND_SUCCESS_EXCHANGE_FOLDERS = exports.FIND_EXCHANGE_FOLDERS = void 0;
const node_factory_1 = require("app-new/components/tree/model/factories/node.factory");
exports.FIND_EXCHANGE_FOLDERS = '[Tree - Folders] Find Exchange Folders';
exports.FIND_SUCCESS_EXCHANGE_FOLDERS = '[Tree - Folders] Exchange Folders successfuly found';
exports.LOAD_EXCHANGE_ROOT_FOLDER = '[Tree - Folders] Load Exchange Root Folder';
exports.LOAD_SUCCESS_EXCHANGE_ROOT_FOLDER = '[Tree - Folders] Exchange Root Folder succesfully loaded';
exports.LOAD_EXCHANGE_FOLDERS = '[Tree - Folders] Load Exchange Folders';
exports.LOAD_SUCCESS_EXCHANGE_FOLDERS = '[Tree - Folders] Exchange Folders successfuly loaded';
exports.LOAD_EXCHANGE_MAIL_FOLDERS = '[Tree - Folders] Load Exchange Mail Folders';
exports.LOAD_SUCCESS_EXCHANGE_MAIL_FOLDERS = '[Tree - Folders] Exchange Mail Folders successfuly loaded';
exports.LOAD_EXCHANGE_CALENDAR_FOLDERS = '[Tree - Folders] Load Exchange Calendar Folders';
exports.LOAD_SUCCESS_EXCHANGE_CALENDAR_FOLDERS = '[Tree - Folders] Exchange Calendar Folders successfuly loaded';
exports.LOAD_EXCHANGE_CONTACT_FOLDERS = '[Tree - Folders] Load Exchange Contact Folders';
exports.LOAD_SUCCESS_EXCHANGE_CONTACT_FOLDERS = '[Tree - Folders] Exchange Contact Folders successfuly loaded';
exports.LOAD_ONE_DRIVE_FOLDERS = '[Tree - Folders] Load One Drive Folders';
exports.LOAD_SUCCESS_ONE_DRIVE_FOLDERS = '[Tree - Folders] One Drive folders successfuly loaded';
exports.LOAD_ONE_DRIVE_ROOT_FOLDER = '[Tree - Folders] Load One Drive Root Folder';
exports.LOAD_SUCCESS_ONE_DRIVE_ROOT_FOLDER = '[Tree - Folders] One Drive Folder succesfully loaded';
exports.CLEAN_STORE = '[Tree - Folders] Clean Store';
class LoadExchangeRootFolder {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOAD_EXCHANGE_ROOT_FOLDER;
    }
}
exports.LoadExchangeRootFolder = LoadExchangeRootFolder;
class LoadSuccessExchangeRootFolder {
    constructor(folders) {
        this.type = exports.LOAD_SUCCESS_EXCHANGE_ROOT_FOLDER;
        this.rootFolder = folders.map(folder => {
            return node_factory_1.NodeFactory.FOLDER(folder);
        })[0];
    }
}
exports.LoadSuccessExchangeRootFolder = LoadSuccessExchangeRootFolder;
class LoadExchangeFoldersAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOAD_EXCHANGE_FOLDERS;
    }
}
exports.LoadExchangeFoldersAction = LoadExchangeFoldersAction;
class LoadSuccessExchangeFoldersAction {
    constructor(folderPath, parentId, contentType, folders, meta) {
        this.folderPath = folderPath;
        this.parentId = parentId;
        this.contentType = contentType;
        this.folders = folders;
        this.meta = meta;
        this.type = exports.LOAD_SUCCESS_EXCHANGE_FOLDERS;
    }
}
exports.LoadSuccessExchangeFoldersAction = LoadSuccessExchangeFoldersAction;
class LoadExchangeMailFoldersAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOAD_EXCHANGE_MAIL_FOLDERS;
    }
}
exports.LoadExchangeMailFoldersAction = LoadExchangeMailFoldersAction;
class LoadSuccessExchangeMailFoldersAction {
    constructor(folders, meta) {
        this.folders = folders;
        this.meta = meta;
        this.type = exports.LOAD_SUCCESS_EXCHANGE_MAIL_FOLDERS;
    }
}
exports.LoadSuccessExchangeMailFoldersAction = LoadSuccessExchangeMailFoldersAction;
class LoadExchangeCalendarFoldersAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOAD_EXCHANGE_CALENDAR_FOLDERS;
    }
}
exports.LoadExchangeCalendarFoldersAction = LoadExchangeCalendarFoldersAction;
class LoadSuccessExchangeCalendarFoldersAction {
    constructor(folders, meta) {
        this.folders = folders;
        this.meta = meta;
        this.type = exports.LOAD_SUCCESS_EXCHANGE_CALENDAR_FOLDERS;
    }
}
exports.LoadSuccessExchangeCalendarFoldersAction = LoadSuccessExchangeCalendarFoldersAction;
class LoadExchangeContactFoldersAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOAD_EXCHANGE_CONTACT_FOLDERS;
    }
}
exports.LoadExchangeContactFoldersAction = LoadExchangeContactFoldersAction;
class LoadSuccessExchangeContactFoldersAction {
    constructor(folders, meta) {
        this.folders = folders;
        this.meta = meta;
        this.type = exports.LOAD_SUCCESS_EXCHANGE_CONTACT_FOLDERS;
    }
}
exports.LoadSuccessExchangeContactFoldersAction = LoadSuccessExchangeContactFoldersAction;
class FindExchangeFoldersAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.FIND_EXCHANGE_FOLDERS;
    }
}
exports.FindExchangeFoldersAction = FindExchangeFoldersAction;
class FindSuccessExchangeFoldersAction {
    constructor(folders, meta) {
        this.folders = folders;
        this.meta = meta;
        this.type = exports.FIND_SUCCESS_EXCHANGE_FOLDERS;
    }
}
exports.FindSuccessExchangeFoldersAction = FindSuccessExchangeFoldersAction;
class LoadOneDriveFoldersAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOAD_ONE_DRIVE_FOLDERS;
    }
}
exports.LoadOneDriveFoldersAction = LoadOneDriveFoldersAction;
class LoadSuccessOneDriveFoldersAction {
    constructor(folderPath, parentId, contentType, folders, meta) {
        this.folderPath = folderPath;
        this.parentId = parentId;
        this.contentType = contentType;
        this.folders = folders;
        this.meta = meta;
        this.type = exports.LOAD_SUCCESS_ONE_DRIVE_FOLDERS;
    }
}
exports.LoadSuccessOneDriveFoldersAction = LoadSuccessOneDriveFoldersAction;
class LoadOneDriveRootFolder {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOAD_ONE_DRIVE_ROOT_FOLDER;
    }
}
exports.LoadOneDriveRootFolder = LoadOneDriveRootFolder;
class LoadSuccessOneDriveRootFolder {
    constructor(folders) {
        this.type = exports.LOAD_SUCCESS_ONE_DRIVE_ROOT_FOLDER;
        if (folders.length > 0) {
            this.rootFolder = folders[0];
        }
    }
}
exports.LoadSuccessOneDriveRootFolder = LoadSuccessOneDriveRootFolder;
class CleanStore {
    constructor() {
        this.type = exports.CLEAN_STORE;
    }
}
exports.CleanStore = CleanStore;
