"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CancelConfirmationModalComponent = void 0;
const wizardActions = require("../../actions/wizard.actions");
class CancelConfirmationModalComponent {
    constructor(store, translateService, overlayData) {
        this.store = store;
        this.translateService = translateService;
        this.overlayData = overlayData;
        this.title = overlayData.title;
        this.wizardId = overlayData.wizardId;
        this.hasConsented = overlayData.hasConsented;
        this.editFlow = overlayData.editFlow;
        if (this.overlayData.provider) {
            this.selectedProvider = this.translateService.instant(`$I18N_CONNECTORS.PROVIDERS.${this.overlayData.provider.type.toUpperCase()}`);
        }
    }
    getModalTitle() {
        return this.title;
    }
    onYes() {
        this.store.dispatch(new wizardActions.CancelYesAction(this.wizardId, this.overlayData));
    }
    onNo() {
        this.store.dispatch(new wizardActions.CancelNoAction());
    }
}
exports.CancelConfirmationModalComponent = CancelConfirmationModalComponent;
