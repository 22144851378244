"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgDevicesListViewImplService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const table_store_1 = require("@mimecast-ui/table-store");
class SwgDevicesListViewImplService {
    constructor(devicesListViewApiService) {
        this.devicesListViewApiService = devicesListViewApiService;
        this.devicesInGroup = [];
    }
    getData(payload, pagination = table_store_1.defaultPaginationOption) {
        const config = Object.assign(Object.assign({}, payload), { meta: { pagination } });
        return this.devicesListViewApiService.getDeviceList(config).pipe(operators_1.map((response) => {
            return {
                hasErrors: response.hasErrors,
                data: response.first.endpointSummaries,
                failures: response.fail,
                meta: Object.assign(Object.assign({}, response.meta), { pagination: Object.assign(Object.assign({}, response.meta.pagination), { totalRows: response.meta.pagination.totalCount }) })
            };
        }), operators_1.catchError((response) => {
            const RETURN_DATA = {
                hasErrors: response.hasErrors,
                data: response.first.logs,
                failures: response.fail,
                meta: Object.assign(Object.assign({}, response.meta), { pagination: Object.assign(Object.assign({}, response.meta.pagination), { totalRows: response.meta.pagination.totalCount }) })
            };
            return rxjs_1.of(RETURN_DATA);
        }));
    }
    filter(query, pagination) {
        return this.getData(query, pagination);
    }
    openItem() {
        return rxjs_1.of([]);
    }
}
exports.SwgDevicesListViewImplService = SwgDevicesListViewImplService;
