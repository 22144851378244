"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgReportUrlSuccessAction = exports.SwgReportUrlFailureAction = exports.SwgReportUrlAction = exports.SwgOpenReportUrlModalAction = exports.SwgUrlReportActions = void 0;
var SwgUrlReportActions;
(function (SwgUrlReportActions) {
    SwgUrlReportActions["OPEN_REPORT_URL_MODAL"] = "[SwgUrlReport] Open Report Url Modal";
    SwgUrlReportActions["REPORT_URL"] = "[SwgUrlReport] Report Url";
    SwgUrlReportActions["REPORT_URL_SUCCESS"] = "[SwgUrlReport] Report url success";
    SwgUrlReportActions["REPORT_URL_FAILURE"] = "[SwgUrlReport] Report url Failure";
})(SwgUrlReportActions = exports.SwgUrlReportActions || (exports.SwgUrlReportActions = {}));
class SwgOpenReportUrlModalAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgUrlReportActions.OPEN_REPORT_URL_MODAL;
    }
}
exports.SwgOpenReportUrlModalAction = SwgOpenReportUrlModalAction;
class SwgReportUrlAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgUrlReportActions.REPORT_URL;
    }
}
exports.SwgReportUrlAction = SwgReportUrlAction;
class SwgReportUrlFailureAction {
    constructor() {
        this.type = SwgUrlReportActions.REPORT_URL_FAILURE;
    }
}
exports.SwgReportUrlFailureAction = SwgReportUrlFailureAction;
class SwgReportUrlSuccessAction {
    constructor() {
        this.type = SwgUrlReportActions.REPORT_URL_SUCCESS;
    }
}
exports.SwgReportUrlSuccessAction = SwgReportUrlSuccessAction;
