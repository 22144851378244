<mc-layout-aside-simple keyTitle="$I18N_MONITORED_EXTERNAL_DOMAIN_ADD_DOMAIN"
                        [isLoading]="false"
                        [showClose]="true"
                        (closeAside)="cancel.emit()">
  <mc-body-container>
    <div>{{"$I18N_MONITORED_DOMAINS_ADDITION_INSTRUCTIONS" | translate}}</div>

    <form [formGroup]="form" class="panel-padding-top">
      <mc-text-area-field
        label="{{'$I18N_MONITORED_DOMAINS_ADDITION.TEXT_FIELD_LABEL.DOMAINS' | translate}}"
        formControlName="domains"
        rows="15"
        required
        placeholder="{{'$I18N_MONITORED_DOMAINS_ADDITION.PLACEHOLDER.DOMAINS' | translate}}"
        errorPrefix="$I18N_MONITORED_DOMAINS_ADDITION">
      </mc-text-area-field>

      <mc-text-field
        label="{{'$I18N_MONITORED_DOMAINS_ADDITION.TEXT_FIELD_LABEL.COMMENTS' | translate}}"
        formControlName="comment"
        placeholder="{{'$I18N_MONITORED_DOMAINS_ADDITION.PLACEHOLDER.COMMENTS' | translate}}"
        errorPrefix="$I18N_MONITORED_DOMAINS_ADDITION_COMMENT">
      </mc-text-field>
    </form>
  </mc-body-container>

  <mc-footer-container>
    <div class="pull-right">
      <button class="btn btn-link text-normal panel-half-margin-right" (click)="cancel.emit()">
        {{ '$I18N_MONITORED_DOMAINS_ADDITION.BUTTON_LABEL.CANCEL' | translate }}
      </button>
      <button
        class="btn btn-primary"
        (click)="onNext()"
        [disabled]="!form.valid"
        data-test="TSBD-SUBMIT"
      >
        {{ '$I18N_MONITORED_DOMAINS_ADDITION.BUTTON_LABEL.NEXT' | translate }}
      </button>
    </div>
  </mc-footer-container>
</mc-layout-aside-simple>
