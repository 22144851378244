"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.autoAllowOptionCondition = exports.autoAllowPolicyTypes = void 0;
var autoAllowPolicyTypes;
(function (autoAllowPolicyTypes) {
    autoAllowPolicyTypes["autoAllow"] = "Auto Allow";
    autoAllowPolicyTypes["spamSpanning"] = "Spam Scanning";
})(autoAllowPolicyTypes = exports.autoAllowPolicyTypes || (exports.autoAllowPolicyTypes = {}));
var autoAllowOptionCondition;
(function (autoAllowOptionCondition) {
    autoAllowOptionCondition["managedSenderInfo"] = "autowhite";
    autoAllowOptionCondition["messageInfoRoute"] = "inbound";
    autoAllowOptionCondition[autoAllowOptionCondition["spamScore"] = 28] = "spamScore";
    autoAllowOptionCondition["messageType"] = "rejectedMessage";
})(autoAllowOptionCondition = exports.autoAllowOptionCondition || (exports.autoAllowOptionCondition = {}));
