"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WINDOW = exports.WindowService = void 0;
const core_1 = require("@angular/core");
const rxjs_1 = require("rxjs");
class WindowService {
    constructor(_window) {
        this._window = _window;
        this.branding = this._window.mimecast && this._window.mimecast.branding;
        this.rootPath = this._window.rootPath;
        this.dirPath = this._window.dirPath;
        this.urlPrefix = this._window.urlPrefix;
        this.appId = this._window['applicationId'];
        this.timeZoneOffset = this._window.timeZoneOffset;
        this.apiVersion = this._window.apiVersion;
        this.appsConfig = this._window.appsConfig;
        this.appMessagesConfig = this._window.appMessagesConfig;
        this.appVersion = this._window.appVersion;
        this.grid = this._window.grid;
        this.disableAdcon3 = this._window.disableAdcon3 || false;
        this.history = _window.history;
        this.document = _window.document;
        this.window = _window;
        this.messages$ = rxjs_1.fromEvent(this._window, 'message');
        this.unload$ = rxjs_1.fromEvent(this._window, this.isIos ? 'pagehide' : 'beforeunload');
        const _navigator = _window.navigator;
        const platform = _navigator.platform;
        const ua = _navigator.userAgent;
        this.isFirefox = ua.indexOf('Firefox') !== -1;
        this.isOpera = ua.indexOf('Opera') !== -1;
        this.isChrome = ua.indexOf('Chrome') !== -1;
        this.isSafari = ua.indexOf('Safari') !== -1 && !this.isChrome;
        this.isWebkit = ua.indexOf('WebKit') !== -1;
        this.isRetina = window.devicePixelRatio > 1;
        this.isIe = ua.indexOf('Trident') > 0 || ua.indexOf('MSIE') > 0;
        this.isIe6 = ua.indexOf('MSIE 6') > 0;
        this.isIe7 = ua.indexOf('MSIE 7') > 0;
        this.isIe8 = ua.indexOf('MSIE 8') > 0;
        this.isIe9 = ua.indexOf('MSIE 9') > 0;
        this.isIe10 = ua.indexOf('MSIE 10') > 0;
        this.isOld = this.isIe6 || this.isIe7 || this.isIe8 || this.isIe9;
        this.isIe11 = ua.indexOf('MSIE') === -1 && ua.indexOf('Trident') > 0;
        this.isIe10up = this.isIe10 || this.isIe11;
        this.isIe9up = this.isIe9 || this.isIe10up;
        this.isWindowsOs = platform.toLowerCase().indexOf('win') !== -1 || this.isIe;
        this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(ua);
        // eslint-disable-next-line max-len
        this.isSmallDevice = /(android).+mobile|blackberry|iemobile|ip(hone|od)|mobile.+firefox|opera m(ob|in)i|phone|symbian|webOS/i.test(ua); // mobile devices, exluded tablets
        this.isAndroid = /Android/i.test(ua);
        this.isIos = /iPhone|iPad|iPod/i.test(ua);
        this.isIpad = /iPad/i.test(ua);
        this.isWindowsPhone = /IEMobile/i.test(ua);
        this.isBlackberry = /BlackBerry/i.test(ua);
        this.isTouch =
            'ontouchstart' in window || _navigator.maxTouchPoints > 0 || _navigator.msMaxTouchPoints > 0;
        this.isLocalStorageAvailable = checkLocalStorageAvailability();
        function checkLocalStorageAvailability() {
            try {
                window.localStorage.setItem('localStorage', 'true');
                window.localStorage.removeItem('localStorage');
                return true;
            }
            catch (exception) {
                return false;
            }
        }
    }
    addEventListener(eventName, fun) {
        this._window.addEventListener(eventName, fun);
    }
    removeEventListener(eventName, fun) {
        this._window.removeEventListener(eventName, fun);
    }
    replaceLocation(url) {
        this._window.location.replace(url);
    }
    open(url, name, specs) {
        return this._window.open(url, name, specs);
    }
    close() {
        this._window.close();
    }
    postMessage(message, targetOrigin) {
        this._window.opener.postMessage(message, targetOrigin);
    }
    postMessageToParent(message, targetOrigin) {
        this._window.parent.postMessage(message, targetOrigin);
    }
    setAdconUrls(adconUrls) {
        if (adconUrls && adconUrls.length > 0) {
            this.adconUrls = adconUrls.indexOf(',') !== -1 ? adconUrls.split(',') : [adconUrls];
            window['adconUrls'] = this.adconUrls;
        }
    }
    inIframe() {
        try {
            return this._window.self !== this._window.top;
        }
        catch (e) {
            return false;
        }
    }
    replaceParentLocation(url) {
        this._window.parent.location.replace(url);
    }
    isSmallScreen() {
        return window.innerWidth <= 1200;
    }
}
exports.WindowService = WindowService;
exports.WINDOW = new core_1.InjectionToken('Window token to inject for easier stubbing in tests', { factory: () => window });
