"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CarouselComponent = void 0;
class CarouselComponent {
    constructor() {
        this.index = 0;
    }
    ngOnInit() {
        if (this.tips && this.tips.length) {
            this.currentTip = this.tips[this.index];
        }
    }
    ngOnChanges() {
        if (this.tips && this.tips.length) {
            this.currentTip = this.tips[this.index];
        }
    }
    next($event) {
        if (this.disabledNavigation) {
            $event.preventDefault();
            this.index = this.index === this.tips.length - 1 ? 0 : this.index + 1;
            this.currentTip = this.tips[this.index];
        }
    }
    previous($event) {
        if (this.disabledNavigation) {
            $event.preventDefault();
            this.index = this.index === 0 ? this.tips.length - 1 : this.index - 1;
            this.currentTip = this.tips[this.index];
        }
    }
}
exports.CarouselComponent = CarouselComponent;
