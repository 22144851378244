"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ1 = exports.ɵ0 = exports.getStepData = exports.isStepValid = exports.getStepById = exports.isCurrentStepValid = exports.getCurrentStepState = exports.getCurrentStep = exports.getWizardById = exports.getWizardState = exports.getOneDriveConnectors = exports.isLoadingConnectors = exports.getConnectorsState = exports.getOneDriveBackupsState = exports.reducers = void 0;
const store_1 = require("@ngrx/store");
const connectorsReducer = require("./connectors.reducer");
const wizardReducer = require("./wizard.reducer");
const backupReducer = require("./backup.reducer");
exports.reducers = {
    connectors: connectorsReducer.reducer,
    backups: backupReducer.reducer,
    wizard: wizardReducer.wizardReducer
};
exports.getOneDriveBackupsState = store_1.createFeatureSelector('one-drive-backups');
const ɵ0 = (state) => state.connectors;
exports.ɵ0 = ɵ0;
exports.getConnectorsState = store_1.createSelector(exports.getOneDriveBackupsState, ɵ0);
exports.isLoadingConnectors = store_1.createSelector(exports.getConnectorsState, connectorsReducer.isLoadingConnectors);
exports.getOneDriveConnectors = store_1.createSelector(exports.getConnectorsState, connectorsReducer.getOneDriveConnectors);
const ɵ1 = (state) => state.wizard;
exports.ɵ1 = ɵ1;
exports.getWizardState = store_1.createSelector(exports.getOneDriveBackupsState, ɵ1);
const getWizardById = (wizardId) => store_1.createSelector(exports.getWizardState, wizardReducer.getWizardById(wizardId));
exports.getWizardById = getWizardById;
const getCurrentStep = (wizardId) => store_1.createSelector(exports.getWizardById(wizardId), wizardReducer.getCurrentStep);
exports.getCurrentStep = getCurrentStep;
const getCurrentStepState = (wizardId) => store_1.createSelector(exports.getWizardById(wizardId), wizardReducer.getCurrentStepState);
exports.getCurrentStepState = getCurrentStepState;
const isCurrentStepValid = (wizardId) => store_1.createSelector(exports.getCurrentStepState(wizardId), wizardReducer.isStepValid);
exports.isCurrentStepValid = isCurrentStepValid;
const getStepById = (wizardId, stepId) => store_1.createSelector(exports.getWizardById(wizardId), wizardReducer.getStepById(stepId));
exports.getStepById = getStepById;
const isStepValid = (wizardId, stepId) => store_1.createSelector(exports.getStepById(wizardId, stepId), wizardReducer.isStepValid);
exports.isStepValid = isStepValid;
const getStepData = (wizardId, stepId) => store_1.createSelector(exports.getStepById(wizardId, stepId), wizardReducer.getStepData);
exports.getStepData = getStepData;
