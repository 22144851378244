"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DirectoryConnectionPanelComponent = void 0;
const confirmation_modal_1 = require("../../modals/confirmation-modal");
const reducers = require("../../reducers");
class DirectoryConnectionPanelComponent {
    constructor(store, overlayRef, modalService, directorySyncService) {
        this.store = store;
        this.overlayRef = overlayRef;
        this.modalService = modalService;
        this.directorySyncService = directorySyncService;
    }
    ngOnInit() {
        this.description$ = this.store.select(reducers.getDescription);
        this.loadData();
        if (this.overlayRef.getBackdropElement()) {
            this.overlayRef
                .getBackdropElement()
                .addEventListener('click', () => this.showConfirmationModal('close'));
        }
    }
    loadData() {
        this.infoSubscription = this.store.select(reducers.getInfo).subscribe(info => {
            this.connectionInfo = info;
        });
    }
    closeAside() {
        this.overlayRef.close();
        this.directorySyncService.cancelTestConnection();
    }
    showConfirmationModal(actionType) {
        const modalConfig = {};
        modalConfig.size = 'lg';
        modalConfig.hasBackdrop = true;
        modalConfig.disableClose = true;
        modalConfig.data = {
            type: actionType,
            sidePanel: this.overlayRef
        };
        this.modalService.open(confirmation_modal_1.ConfirmationModalComponent, modalConfig);
    }
    ngOnDestroy() {
        this.infoSubscription.unsubscribe();
    }
}
exports.DirectoryConnectionPanelComponent = DirectoryConnectionPanelComponent;
