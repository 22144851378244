"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttachmentsComponent = void 0;
const core_1 = require("@angular/core");
class AttachmentsComponent {
    constructor() {
        this.downloadFile = new core_1.EventEmitter();
        this.downloadAllFiles = new core_1.EventEmitter();
        this.showLess = true;
    }
}
exports.AttachmentsComponent = AttachmentsComponent;
