"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("mimecast-web-components/src/app/components/useful-link-list/useful-link-list"); //useful-link-list
/**
 * Created by dgomez on 22/09/2016.
 */
angular.module('mail-selector-aside.controller', ['useful-link-list'])
    .controller('MailSelectorAsideCtrl', ['$scope', 'backupService', function ($scope, backupService) {
        const self = this;
        const { dismissFunction } = $scope.$parent.$resolve;
        self.mailboxes = [];
        self.pagination = {};
        self.selectAndClose = selectAndClose;
        self.getMailboxes = getMailboxes;
        self.loadMoreMailboxes = loadMoreMailboxes;
        self.setQueryAndFreshGet = setQueryAndFreshGet;
        self.clearQueryAndFreshGet = clearQueryAndFreshGet;
        self.isDataLoading = false;
        initialize();
        function initialize() {
            self.getMailboxes(preparePayload());
        }
        function setQueryAndFreshGet() {
            self.mailboxes = [];
            self.getMailboxes(preparePayload());
        }
        function clearQueryAndFreshGet() {
            self.searchTerm = undefined;
            self.setQueryAndFreshGet();
        }
        function getMailboxes(payload) {
            self.isDataLoading = true;
            backupService.getMailboxSummary(payload).then(function (response) {
                if (!response.err) {
                    self.isDataLoading = false;
                    self.mailboxes = self.mailboxes.concat(response.all);
                    self.pagination = response.meta().pagination;
                }
                else {
                    backupService.getError().showErrorNotification(response);
                }
            }, function () {
                backupService.getError().showErrorNotification();
            });
        }
        function preparePayload(pageToken) {
            return { data: [{ query: self.searchTerm }], meta: { 'pagination': { 'pageSize': 15, pageToken } } };
        }
        function loadMoreMailboxes() {
            if (!self.isDataLoading && self.pagination.next) {
                self.isDataLoading = true;
                getMailboxes(preparePayload(self.pagination.next));
            }
        }
        function selectAndClose(item) {
            dismissFunction(item);
        }
    }]);
