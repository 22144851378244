"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AlertModalComponent = void 0;
class AlertModalComponent {
    constructor(accountPackageService, overlayRef, data) {
        this.accountPackageService = accountPackageService;
        this.overlayRef = overlayRef;
        this.url = 'https://community.mimecast.com/s/article/email-security-cloud-gateway-managing-connectors';
        this.data = data;
        this.hasIEPPackage = this.accountPackageService.hasInternalEmailProtectAccountPackage();
        this.isAAAOrFAAUser = this.accountPackageService.isAAAOrFAAUser();
        this.isMessageRecoveryService = this.accountPackageService.isMessageRecoveryService();
    }
    ngOnInit() { }
    onClickOk() {
        this.overlayRef.close(true);
    }
}
exports.AlertModalComponent = AlertModalComponent;
