<mc-collapsable-panel header="$I18N_SWG_THREAT_EVENT_ANALYSIS_DETAILS.TITLE" [isExpanded]="true">
  <div class="mc-detailed-list">
    <div class="row mc-detailed-list-row">
      <div class="col-xs-3 mc-detailed-list-label">
        {{ "$I18N_SWG_THREAT_EVENT_ANALYSIS_DETAILS.LABELS.THREAT_TYPE" | translate }}
      </div>
      <div class="mc-detailed-list-column">
        <mc-swg-threat-event-type [types]="threatEvent.threatTypes"></mc-swg-threat-event-type>
      </div>
    </div>

    <mc-value-field label="$I18N_SWG_THREAT_EVENT_ANALYSIS_DETAILS.LABELS.FILE_TYPE"
                    [value]="threatEvent.fileTypes?.join(', ')">
    </mc-value-field>

    <mc-value-field label="$I18N_SWG_THREAT_EVENT_ANALYSIS_DETAILS.LABELS.DATETIME"
                    [value]="threatEvent.timestamp | date: dateFormat">
    </mc-value-field>

    <mc-value-field label="$I18N_SWG_THREAT_EVENT_ANALYSIS_DETAILS.LABELS.HASH"
                    [value]="threatEvent.hash">
    </mc-value-field>
  </div>
</mc-collapsable-panel>
