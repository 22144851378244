"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPagination = exports.getReason = exports.getQuery = exports.getTokenSliderResults = exports.getTokenResults = exports.getResults = exports.isLoading = exports.reducer = exports.defaultPaginationOption = exports.initialState = void 0;
const searchAction = require("../actions/search.action");
/*
  declares initial values for the various properties of the state object
*/
exports.initialState = {
    results: undefined,
    isLoading: false
};
exports.defaultPaginationOption = {
    pageSize: 5000,
    pageToken: undefined,
    pageNumber: undefined
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case searchAction.TOKEN_SEARCH_SLIDER: {
            return Object.assign(Object.assign({}, state), { isLoading: true, query: action.payload });
        }
        case searchAction.TOKEN_SEARCH_SLIDER_SUCCESS: {
            return Object.assign(Object.assign({}, state), { results: state.results, tokenSliderResult: { value: action.payload }, isLoading: false });
        }
        case searchAction.TOKEN_SEARCH_SLIDER_FAIL: {
            return Object.assign(Object.assign({}, state), { isLoading: false });
        }
        case searchAction.TOKEN_SEARCH: {
            return Object.assign(Object.assign({}, state), { isLoading: true, query: action.payload });
        }
        case searchAction.TOKEN_SEARCH_SUCCESS: {
            return Object.assign(Object.assign({}, state), { results: undefined, tokenResult: { value: action.payload }, isLoading: false });
        }
        case searchAction.TOKEN_SEARCH_FAIL: {
            return Object.assign(Object.assign({}, state), { isLoading: false });
        }
        case searchAction.CONTENT_SEARCH: {
            return Object.assign(Object.assign({}, state), { isLoading: true, query: action.payload });
        }
        case searchAction.CONTENT_SEARCH_SUCCESS: {
            return Object.assign(Object.assign({}, state), { results: action.payload.results, tokenResult: undefined, isLoading: false, meta: action.payload.meta });
        }
        case searchAction.CONTENT_SEARCH_FAIL: {
            return Object.assign(Object.assign({}, state), { isLoading: false });
        }
        default:
            return state;
    }
}
exports.reducer = reducer;
/**
 * Selectors can be applied with the `select` operator which passes the state
 * tree to the provided selector
 */
const isLoading = (state) => state.isLoading;
exports.isLoading = isLoading;
const getResults = (state) => state.results;
exports.getResults = getResults;
const getTokenResults = (state) => state.tokenResult && state.tokenResult.value;
exports.getTokenResults = getTokenResults;
const getTokenSliderResults = (state) => state.tokenSliderResult && state.tokenSliderResult.value;
exports.getTokenSliderResults = getTokenSliderResults;
const getQuery = (state) => state.query;
exports.getQuery = getQuery;
const getReason = (state) => state.query.reason;
exports.getReason = getReason;
// todo: cambiar a getMedaData
const getPagination = (state) => state.meta;
exports.getPagination = getPagination;
