"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiIntegrationOktaEvidenceBasedControlComponent = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const reducers = require("../../../reducers");
const actions = require("../../../actions");
const okta_evidence_based_control_credential_expired_modal_component_1 = require("../../../components/modals/okta-evidence-based-control-credential-expired-modal/okta-evidence-based-control-credential-expired-modal.component");
class ApiIntegrationOktaEvidenceBasedControlComponent {
    constructor(store, modalService) {
        this.store = store;
        this.modalService = modalService;
        this.destroy$ = new rxjs_1.Subject();
    }
    ngOnInit() {
        this.isVerified$ = this.store.select(reducers.isOktaEvidenceBasedControlApiVerified);
        this.isVerificationInProgress$ = this.store.select(reducers.isOktaEvidenceBasedControlApiVerificationInProgress);
        this.integration$ = this.store.select(reducers.getIntegrationOktaEvidenceBasedControlIntegration);
        this.hasCreatedIntegration$ = this.store.select(reducers.hasIntegrationOktaEvidenceBasedControlCreatedIntegration);
        this.userGroups$ = this.store.select(reducers.getIntegrationOktaEvidenceBasedControlUserGroups);
        this.searchOktaGroupResults$ = this.store.select(reducers.getIntegrationOktaEvidenceBasedControlSearchOktaGroupResult);
        this.notificationGroups$ = this.store.select(reducers.getIntegrationOktaEvidenceBasedControlNotificationGroups);
        this.notificationAddresses$ = this.store.select(reducers.getIntegrationOktaEvidenceBasedControlNotificationAddresses);
        this.isLoadingOktaGroups$ = this.store.select(reducers.getIntegrationOktaEvidenceBasedControlSearchOktaGroupsLoading);
        this.isOktaEvidenceBasedControlIntegrationCreatingOrUpdating$ = this.store.select(reducers.isOktaEvidenceBasedControlIntegrationCreatingOrUpdating);
        this.isAuthenticationFailed$ = this.integration$.pipe(operators_1.map(integration => integration === null || integration === void 0 ? void 0 : integration.authenticationFailed));
        this.subscribeToAuthFailures();
    }
    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
    createIntegration() {
        this.store.dispatch(new actions.CreateOktaEvidenceBasedControlIntegrationAction());
    }
    updateIntegration() {
        this.store.dispatch(new actions.UpdateOktaEvidenceBasedControlIntegrationAction());
    }
    searchOktaGroups(searchPrefix) {
        this.store.dispatch(new actions.SearchOktaUserGroupsInOktaEvidenceBasedControlIntegrationAction(searchPrefix));
    }
    updateUserGroups(userGroups) {
        this.store.dispatch(new actions.AddUserGroupsOktaEvidenceBasedControlIntegration(userGroups));
    }
    updateOktaEventGroups(oktaEventGroups) {
        this.store.dispatch(new actions.UpdateEventGroupsInOktaEvidenceBasedControlIntegration(oktaEventGroups));
    }
    verifyAndAddEmail(email) {
        this.store.dispatch(new actions.VerifyAndAddEmailOktaEvidenceBasedControlIntegrationNotificationsAction(email));
    }
    deleteEmail(email) {
        this.store.dispatch(new actions.DeleteEmailOktaEvidenceBasedControlIntegrationNotificationsAction(email));
    }
    updateEnabledStatus(value) {
        this.store.dispatch(new actions.UpdateEnabledStatusOktaEvidenceBasedControlIntegrationAction(value));
    }
    addGroup(selectedGroup) {
        this.store.dispatch(new actions.AddGroupOktaEvidenceBasedControlIntegrationNotificationsAction(selectedGroup));
    }
    deleteGroup(selectedGroup) {
        this.store.dispatch(new actions.DeleteGroupOktaEvidenceBasedControlIntegrationNotificationsAction(selectedGroup));
    }
    cancel() {
        this.store.dispatch(new actions.CancelOktaEvidenceBasedControlIntegrationWizard());
    }
    verifyOktaApiKey(formValues) {
        this.store.dispatch(new actions.VerifyEvidenceBasedControlIntegrationApiKeyAction(formValues));
    }
    subscribeToAuthFailures() {
        this.isAuthenticationFailed$
            .pipe(operators_1.takeUntil(this.destroy$))
            .subscribe(value => value && this.openExportConfirmation());
    }
    openExportConfirmation() {
        this.modalService.open(okta_evidence_based_control_credential_expired_modal_component_1.OktaEvidenceBasedControlCredentialExpiredModalComponent, {
            size: 'md',
            hasBackdrop: true,
            disableClose: true
        });
    }
}
exports.ApiIntegrationOktaEvidenceBasedControlComponent = ApiIntegrationOktaEvidenceBasedControlComponent;
