<div class="mc-layout-modal-simple mc-layout-full-height mc-flex-container mc-flex-column"
     [ngClass]="extendClass">

  <div class="mc-layout-header-modal mc-flex-container">
    <div class="mc-title-item">{{ keyTitle | translate:keyTranslateParams }}</div>
  </div>

  <div class="mc-layout-body-modal mc-flex-scroll-vertical">
    <div class="mc-description-item" *ngIf="keyDescription">{{ keyDescription | translate:keyTranslateParams }}</div>
    <ng-content select="mc-body-container"></ng-content>
  </div>

  <div #mcIdModalFooterArea
       class="mc-layout-footer-container-modal"
       [hidden]="!mcModalFooterArea.nativeElement.children.length">
    <ng-content select="mc-footer-container"></ng-content>
  </div>
</div>
