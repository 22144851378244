"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportWizardComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const moment = require("moment");
class ReportWizardComponent {
    constructor(fb) {
        this.fb = fb;
        this.reportSelection = new core_1.EventEmitter();
        this.form = this.fb.group({
            dateRange: [null, [this.validateDateRange()]],
            reportType: [null, [forms_1.Validators.required]]
        });
    }
    ngOnInit() {
        this.form.patchValue({
            dateRange: this.defaultDateRange,
            reportType: this.defaultType.value
        });
        this.form.markAsPristine();
        this.infoMessage = {
            status: 'info',
            msg: this.reportAvailabilityMessage
        };
    }
    get dateRangePickerConfig() {
        if (this._dateRangeConfig) {
            return this._dateRangeConfig;
        }
        const config = {
            allowCustomRange: true,
            dateRanges: [...this.dateRanges]
        };
        this._dateRangeConfig = config;
        return this._dateRangeConfig;
    }
    validateDateRange() {
        return (control) => {
            const dateRange = control.value;
            if (!dateRange || !dateRange.range) {
                return { invalid_date_range: { max_interval: this.dateRangeMaxInterval } };
            }
            const startInMillis = dateRange.range.start;
            const endInMillis = dateRange.range.end;
            const diffInMillis = endInMillis.diff(startInMillis, 'days');
            const duration = moment.duration(diffInMillis, 'days');
            if (duration.asDays() > this.dateRangeMaxInterval) {
                return { invalid_date_range: { max_interval: this.dateRangeMaxInterval } };
            }
            return null;
        };
    }
    confirmDownload() {
        const selection = {
            type: this.form.get('reportType').value,
            startDate: this.form.get('dateRange').value.range.start.toDate(),
            endDate: this.form.get('dateRange').value.range.end.toDate()
        };
        this.reportSelection.emit(selection);
    }
}
exports.ReportWizardComponent = ReportWizardComponent;
