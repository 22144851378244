"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SystemMessagesExportAction = exports.SystemMessagesRetryEmailsFailAction = exports.SystemMessagesRetryEmailsSuccessAction = exports.SystemMessagesRetryEmailsAction = exports.SystemMessagesEarlyBounceEmailsFailAction = exports.SystemMessagesEarlyBounceEmailsSuccessAction = exports.SystemMessagesEarlyBounceEmailsAction = exports.SystemMessagesRejectEmailsFailAction = exports.SystemMessagesRejectEmailsSuccessAction = exports.SystemMessagesRejectEmailsAction = exports.SystemMessagesFailAction = exports.SystemMessagesSuccessAction = exports.SystemMessagesRequestAction = exports.SystemMessagesPaginationAction = exports.SystemMessagesSelectRowAction = exports.SystemMessagesAsidePaginationAction = exports.SystemMessagesCloseDetailAction = exports.SystemMessagesRefreshAction = exports.SYSTEM_MESSAGES_EXPORT = exports.SYSTEM_MESSAGES_EARLY_BOUNCE_EMAILS_FAIL = exports.SYSTEM_MESSAGES_EARLY_BOUNCE_EMAILS_SUCCESS = exports.SYSTEM_MESSAGES_EARLY_BOUNCE_EMAILS = exports.SYSTEM_MESSAGES_RETRY_EMAILS_FAIL = exports.SYSTEM_MESSAGES_RETRY_EMAILS_SUCCESS = exports.SYSTEM_MESSAGES_RETRY_EMAILS = exports.SYSTEM_MESSAGES_REJECT_EMAILS_FAIL = exports.SYSTEM_MESSAGES_REJECT_EMAILS_SUCCESS = exports.SYSTEM_MESSAGES_REJECT_EMAILS = exports.SYSTEM_MESSAGES_FAIL = exports.SYSTEM_MESSAGES_SUCCESS = exports.SYSTEM_MESSAGES_REQUEST = exports.SYSTEM_MESSAGES_PAGINATION = exports.SYSTEM_MESSAGES_SELECT_ROW = exports.SYSTEM_MESSAGES_ASIDE_PAGINATION = exports.SYSTEM_MESSAGES_CLOSE_DETAIL = exports.SYSTEM_MESSAGES_REFRESH = void 0;
exports.SYSTEM_MESSAGES_REFRESH = '[Message Center /  System Messages]  System Messages Refresh';
exports.SYSTEM_MESSAGES_CLOSE_DETAIL = '[Message Center /  System Messages]  System Messages Close Message Detail';
exports.SYSTEM_MESSAGES_ASIDE_PAGINATION = '[Message Center /  System Messages]  System Messages Aside Pagination';
exports.SYSTEM_MESSAGES_SELECT_ROW = '[Message Center /  System Messages]  System Messages Select Row';
exports.SYSTEM_MESSAGES_PAGINATION = '[Message Center / System Messages] System Messages Pagination';
exports.SYSTEM_MESSAGES_REQUEST = '[Message Center / System Messages] Request Get System Messages';
exports.SYSTEM_MESSAGES_SUCCESS = '[Message Center / System Messages] Get System Messages Success';
exports.SYSTEM_MESSAGES_FAIL = '[Message Center / System Messages] Get System Messages Fail';
exports.SYSTEM_MESSAGES_REJECT_EMAILS = '[Message Center / System Messages] Reject System Messages Emails';
exports.SYSTEM_MESSAGES_REJECT_EMAILS_SUCCESS = '[Message Center / System Messages] Reject System Messages Emails Success';
exports.SYSTEM_MESSAGES_REJECT_EMAILS_FAIL = '[Message Center / System Messages] Reject System Messages Emails Fail';
exports.SYSTEM_MESSAGES_RETRY_EMAILS = '[Message Center / System Messages] Retry System Messages Emails';
exports.SYSTEM_MESSAGES_RETRY_EMAILS_SUCCESS = '[Message Center / System Messages] Retry System Messages Emails Success';
exports.SYSTEM_MESSAGES_RETRY_EMAILS_FAIL = '[Message Center / System Messages] Retry System Messages Emails Fail';
exports.SYSTEM_MESSAGES_EARLY_BOUNCE_EMAILS = '[Message Center / System Messages] Early Bounce System Messages Emails';
exports.SYSTEM_MESSAGES_EARLY_BOUNCE_EMAILS_SUCCESS = '[Message Center / System Messages] Early Bounce System Messages Emails Success';
exports.SYSTEM_MESSAGES_EARLY_BOUNCE_EMAILS_FAIL = '[Message Center / System Messages] Early Bounce System Messages Emails Fail';
exports.SYSTEM_MESSAGES_EXPORT = '[Message Center / System Messages] Export System Messages Email';
class SystemMessagesRefreshAction {
    constructor() {
        this.type = exports.SYSTEM_MESSAGES_REFRESH;
    }
}
exports.SystemMessagesRefreshAction = SystemMessagesRefreshAction;
class SystemMessagesCloseDetailAction {
    constructor() {
        this.type = exports.SYSTEM_MESSAGES_CLOSE_DETAIL;
    }
}
exports.SystemMessagesCloseDetailAction = SystemMessagesCloseDetailAction;
class SystemMessagesAsidePaginationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SYSTEM_MESSAGES_ASIDE_PAGINATION;
    }
}
exports.SystemMessagesAsidePaginationAction = SystemMessagesAsidePaginationAction;
class SystemMessagesSelectRowAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SYSTEM_MESSAGES_SELECT_ROW;
    }
}
exports.SystemMessagesSelectRowAction = SystemMessagesSelectRowAction;
class SystemMessagesPaginationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SYSTEM_MESSAGES_PAGINATION;
    }
}
exports.SystemMessagesPaginationAction = SystemMessagesPaginationAction;
class SystemMessagesRequestAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SYSTEM_MESSAGES_REQUEST;
    }
}
exports.SystemMessagesRequestAction = SystemMessagesRequestAction;
class SystemMessagesSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SYSTEM_MESSAGES_SUCCESS;
    }
}
exports.SystemMessagesSuccessAction = SystemMessagesSuccessAction;
class SystemMessagesFailAction {
    constructor() {
        this.type = exports.SYSTEM_MESSAGES_FAIL;
    }
}
exports.SystemMessagesFailAction = SystemMessagesFailAction;
class SystemMessagesRejectEmailsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SYSTEM_MESSAGES_REJECT_EMAILS;
    }
}
exports.SystemMessagesRejectEmailsAction = SystemMessagesRejectEmailsAction;
class SystemMessagesRejectEmailsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SYSTEM_MESSAGES_REJECT_EMAILS_SUCCESS;
    }
}
exports.SystemMessagesRejectEmailsSuccessAction = SystemMessagesRejectEmailsSuccessAction;
class SystemMessagesRejectEmailsFailAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SYSTEM_MESSAGES_REJECT_EMAILS_FAIL;
    }
}
exports.SystemMessagesRejectEmailsFailAction = SystemMessagesRejectEmailsFailAction;
class SystemMessagesEarlyBounceEmailsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SYSTEM_MESSAGES_EARLY_BOUNCE_EMAILS;
    }
}
exports.SystemMessagesEarlyBounceEmailsAction = SystemMessagesEarlyBounceEmailsAction;
class SystemMessagesEarlyBounceEmailsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SYSTEM_MESSAGES_EARLY_BOUNCE_EMAILS_SUCCESS;
    }
}
exports.SystemMessagesEarlyBounceEmailsSuccessAction = SystemMessagesEarlyBounceEmailsSuccessAction;
class SystemMessagesEarlyBounceEmailsFailAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SYSTEM_MESSAGES_EARLY_BOUNCE_EMAILS_FAIL;
    }
}
exports.SystemMessagesEarlyBounceEmailsFailAction = SystemMessagesEarlyBounceEmailsFailAction;
class SystemMessagesRetryEmailsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SYSTEM_MESSAGES_RETRY_EMAILS;
    }
}
exports.SystemMessagesRetryEmailsAction = SystemMessagesRetryEmailsAction;
class SystemMessagesRetryEmailsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SYSTEM_MESSAGES_RETRY_EMAILS_SUCCESS;
    }
}
exports.SystemMessagesRetryEmailsSuccessAction = SystemMessagesRetryEmailsSuccessAction;
class SystemMessagesRetryEmailsFailAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SYSTEM_MESSAGES_RETRY_EMAILS_FAIL;
    }
}
exports.SystemMessagesRetryEmailsFailAction = SystemMessagesRetryEmailsFailAction;
class SystemMessagesExportAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SYSTEM_MESSAGES_EXPORT;
    }
}
exports.SystemMessagesExportAction = SystemMessagesExportAction;
