"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TreeViewWrapperPanelComponent = void 0;
const core_1 = require("@angular/core");
class TreeViewWrapperPanelComponent {
    constructor() {
        this.defaultTitle = '';
        this.linkText = '';
        this.linkUrl = '';
        this.popoverContent = '';
        this.isExpanded = true;
        this.onCollapse = new core_1.EventEmitter();
        this.onExpand = new core_1.EventEmitter();
    }
    collapse() {
        this.onCollapse.emit();
    }
    expand() {
        this.onExpand.emit();
    }
}
exports.TreeViewWrapperPanelComponent = TreeViewWrapperPanelComponent;
