"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchMessagesByIdComponent = void 0;
const forms_1 = require("@angular/forms");
const search_messages_base_component_1 = require("../search-messages-base.component");
const moment = require("moment");
const date_ranges_1 = require("../date-range-config/date-ranges");
class SearchMessagesByIdComponent extends search_messages_base_component_1.SearchMessagesBaseDirective {
    constructor(fb, featureFlagService) {
        super(fb);
        this.featureFlagService = featureFlagService;
        this.dateRangeConfig = date_ranges_1.dateRangePickerConfig;
        this.form = this.fb.group({
            messageId: [null, [forms_1.Validators.required]],
            dateRange: date_ranges_1.dateRangePickerConfig.dateRanges[1]
        });
        this.featureFlagService.isReady().then(() => {
            const isDateRangeRestrictionEnabled = featureFlagService.getBooleanFlag('Threat.Remediation.Date.Range.Restriction');
            if (isDateRangeRestrictionEnabled) {
                this.dateRangeConfig = Object.assign(Object.assign({}, date_ranges_1.dateRangePickerConfig), { minDate: moment('2023/07/29', 'YYYY/MM/DD') });
            }
        });
    }
    onSubmit() {
        let messageId = this.form.get('messageId').value.trim();
        if (this.isIdSearchDateRangeEnabled) {
            messageId = this.formatMessageId(messageId);
        }
        this.form.get('messageId').setValue(messageId);
        super.onSubmit();
    }
    formatMessageId(messageId) {
        let formattedMessageId = (messageId || '').replace(/[\x00-\x09\x0B-\x0C\x0E-\x1F\x7F-\x9F]/g, '');
        if (!formattedMessageId.startsWith('<')) {
            formattedMessageId = `<${formattedMessageId}`;
        }
        if (!messageId.endsWith('>')) {
            formattedMessageId = `${formattedMessageId}>`;
        }
        return formattedMessageId;
    }
}
exports.SearchMessagesByIdComponent = SearchMessagesByIdComponent;
