<mc-domains-settings [hidden]="showDomainValidation$ | async"
                     (showValidations)="onShowDomainValidation($event)"
                     (cancel)="onCloseAside()">
</mc-domains-settings>

<mc-domains-validations-step [hidden]="!(showDomainValidation$ | async)"
                             [validDomains]="validDomains$ | async"
                             [inValidDomains]="invalidDomains$ | async"
                             (showDomainsSettings)="onShowDomainsSettings()"
                             (removeInvalidDomain)="onRemoveInvalidDomain($event)"
                             (removeValidDomain)="onRemoveValidDomain($event)"
                             (createDomains)="onCreateDomains()"
                             (cancel)="onCloseAside()"
                             [isCreatingDomains]="isCreatingDomains$ | async">
</mc-domains-validations-step>
