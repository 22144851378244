"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgBlockPageSettingsPageComponent = void 0;
const reducers = require("../reducers");
const settings_action_1 = require("../actions/settings.action");
class SwgBlockPageSettingsPageComponent {
    constructor(store, swgPolicyService, swgService) {
        this.store = store;
        this.swgPolicyService = swgPolicyService;
        this.swgService = swgService;
    }
    ngOnInit() {
        this.store.dispatch(new settings_action_1.SwgBlockPageSettingsGetAction());
        this.settings$ = this.store.select(reducers.getSwgBlockPageDetails);
        this.error$ = this.store.select(reducers.getSwgBlockPageError);
        this.isLoading$ = this.store.select(reducers.getSwgBlockPageLoading);
        this.isSaving$ = this.store.select(reducers.getSwgBlockPageSaving);
        this.hasEditPermission$ = this.swgPolicyService.hasPolicyEditPermission();
        this.hasAWBlockPageSwitchEnabled = this.swgService.hasAwBlockPageSwitchEnabled();
    }
    saveSettings(event) {
        this.store.dispatch(new settings_action_1.SwgBlockPageSettingsSaveAction(event));
    }
}
exports.SwgBlockPageSettingsPageComponent = SwgBlockPageSettingsPageComponent;
