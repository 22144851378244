"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CortexBooleanPipe = void 0;
class CortexBooleanPipe {
    constructor(translateService) {
        this.translateService = translateService;
    }
    transform(input) {
        if (input) {
            return this.translateService.instant('$I18N_API_INTEGRATION_CORTEX_BOOLEAN.ENABLED');
        }
        return this.translateService.instant('$I18N_API_INTEGRATION_CORTEX_BOOLEAN.DISABLED');
    }
}
exports.CortexBooleanPipe = CortexBooleanPipe;
