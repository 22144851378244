<mc-layout-list-simple
  keyTitle="$I18N_SWG_URL_REPORT_TITLE"
  keyDescription="$I18N_SWG_URL_REPORT_TITL_DESC"
>
  <mc-help-container>
    <mc-help-link url="https://community.mimecast.com/docs/DOC-3193"></mc-help-link>
  </mc-help-container>
  <mc-body-container>
    <div class="mc-swg-classification-checker">
      <div class="row">
        <form [formGroup]="classificationForm" class="mc-classification-form">
          <div class="mc-classification-form-field">
            <mc-text-field
              class="domain-text-field no-padding-left"
              errorPrefix="$I18N_SWG_URL_REPORT_VALIDATION"
              type="url"
              formControlName="texturl"
              [setFocus]="true"
            >
            </mc-text-field>
          </div>
          <mc-live-button
            overrideClasses="btn-primary"
            [mcDisabled]="classificationForm.invalid || textUrlIsEmpty()"
            [label]="'$I18N_COMMON_BTN_SUBMIT' | translate"
            [isLoading]="isApiProcessing"
            (mcClick)="checkDomain()"
          >
          </mc-live-button>
        </form>
      </div>

      <div class="row" *ngIf="!isApiProcessing && !isInitialRequest">
        <div class="col-xs-4">
          <mc-page-header title="$I18N_SWG_URL_REPORT_DOMAIN_CATEGORIES"></mc-page-header>
          <div *ngIf="swgClassifications">
            <div class="mc-classification-results">
              {{ swgClassifications.domainCategories?.length }}
              {{ '$I18N_SWG_URL_REPORT_FOUND' | translate }}
            </div>
            <div *ngIf="swgClassifications.domainCategories?.length === 0">
              {{ '$I18N_SWG_URL_REPORT_NOT_FOUND' | translate }}
            </div>
            <div
              *ngFor="let domainCat of swgClassifications.domainCategories"
              class="mc-classification-report-row"
            >
              <strong>{{ '$I18N_SWG_URL_REPORT_CATEGORY' | translate }} </strong> :&nbsp;{{
                '$I18N_SWG_HUMANREADBALE_CATEGORY.' + domainCat | translate
              }}
              <a
                class="mc-classification-report-link"
                tabindex="0"
                role="button"
                (click)="report(domainCat)"
                (keyup.enter)="report(domainCat)"
                (keyup.space)="report(domainCat)"
              >
                Report</a
              >
            </div>
          </div>
        </div>
        <div class="col-xs-4">
          <mc-page-header title="$I18N_SWG_URL_REPORT_URL_CATEGORIES"></mc-page-header>
          <div *ngIf="swgClassifications">
            <div class="mc-classification-results">
              {{ swgClassifications.urlCategories.length }} found
            </div>
            <div *ngIf="swgClassifications.urlCategories.length === 0">
              {{ '$I18N_SWG_URL_REPORT_NOT_FOUND' | translate }}
            </div>
            <div
              *ngFor="let urlCat of swgClassifications.urlCategories"
              class="mc-classification-report-row"
            >
              <strong>{{ '$I18N_SWG_URL_REPORT_CATEGORY' | translate }} </strong> :&nbsp;{{
                '$I18N_SWG_HUMANREADBALE_CATEGORY.' + urlCat | translate
              }}
              <a
                class="mc-classification-report-link"
                tabindex="0"
                role="button"
                (click)="report(urlCat)"
                (keyup.enter)="report(urlCat)"
                (keyup.space)="report(urlCat)"
              >
                Report</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </mc-body-container>
</mc-layout-list-simple>
