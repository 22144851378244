"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgExceptionsSidebarComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const components_1 = require("@mimecast-ui/components");
const domain_validator_1 = require("app-new/components/validators/domain/domain.validator");
const ip_1 = require("../../../../components/validators/ip");
class SwgExceptionsSidebarComponent {
    constructor(fb, translate) {
        this.fb = fb;
        this.translate = translate;
        this.save = new core_1.EventEmitter();
        this.cancel = new core_1.EventEmitter();
        this.internalDomainsOnlyNotificationConfig = {
            status: 'info',
            msg: this.translate.instant('$I18N_SWG_EXCEPTIONS_LIST.SIDEBAR.INTERNAL_DOMAINS_ONLY_NOTIFICATION'),
            boxed: true
        };
        this.options = [
            {
                value: null,
                label: 'Select type'
            },
            {
                value: 'host',
                label: 'Domain'
            },
            {
                value: 'ipRange',
                label: 'IP range'
            }
        ];
    }
    ngOnInit() {
        this.form = this.fb.group({
            description: ['', [forms_1.Validators.required, forms_1.Validators.maxLength(60)]],
            type: [null],
            host: ['', [forms_1.Validators.required, domain_validator_1.looseDomainValidator]],
            ipRange: [
                '',
                [forms_1.Validators.required, this.hasIPV6SwitchEnabled ? ip_1.IPV4_IPV6_VALIDATOR : components_1.IPValidator]
            ],
            url: [''],
            id: ['']
        });
        if (this.isEditing) {
            const exceptionType = !!this.exception.host ? 'host' : 'ipRange';
            this.form.patchValue(Object.assign(Object.assign({}, this.exception), { type: exceptionType }));
        }
    }
    onSelectChange(value) {
        // set validation dynamically
        const hostControl = this.form.get('host');
        const ipRangeControl = this.form.get('ipRange');
        hostControl.clearValidators();
        ipRangeControl.clearValidators();
        hostControl.updateValueAndValidity();
        ipRangeControl.updateValueAndValidity();
        if (value === 'host') {
            hostControl.setValidators([forms_1.Validators.required, domain_validator_1.looseDomainValidator]);
            hostControl.updateValueAndValidity();
        }
        if (value === 'ipRange') {
            ipRangeControl.setValidators([
                forms_1.Validators.required,
                this.hasIPV6SwitchEnabled ? ip_1.IPV4_IPV6_VALIDATOR : components_1.IPValidator
            ]);
            ipRangeControl.updateValueAndValidity();
        }
    }
    saveException() {
        // this is necessary because the api complains if empty values are passed
        const payload = {
            description: this.form.get('description').value,
            id: this.form.get('id').value
        };
        const type = this.form.get('type').value;
        if (type === 'host') {
            payload['host'] = this.form.get('host').value;
        }
        else {
            payload['ipRange'] = this.form.get('ipRange').value;
        }
        this.save.emit(payload);
    }
    close() {
        this.cancel.emit();
    }
    showButtonText() {
        return this.isEditing ? '$I18N_COMMON_BTN_SAVE' : '$I18N_COMMON_BTN_ADD';
    }
}
exports.SwgExceptionsSidebarComponent = SwgExceptionsSidebarComponent;
