<mc-ttp-ip-log-details [logDetails]="logDetails$ | async"
                       [logDetailsIsLoading]="logDetailsIsLoading$ | async"
                       [previousNextRow]="previousNextRow$ | async"
                       [permitSenderIsProcessing]="permitSenderIsProcessing$ | async"
                       [blockSenderIsProcessing]="blockSenderIsProcessing$ | async"
                       [monitorDomainIsProcessing]="monitorDomainIsProcessing$ | async"
                       (closeAside)="closeAside()"
                       (selectRow)="selectRow($event)"
                       (permitSender)="permitSender($event)"
                       (blockSender)="blockSender($event)"
                       (monitorDomain)="monitorDomain($event)">
</mc-ttp-ip-log-details>
