"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.getIsApiProcessing = exports.getIsEditing = exports.getExceptionItem = exports.reducers = void 0;
const store_1 = require("@ngrx/store");
const exceptions = require("./swg-exceptions.reducer");
exports.reducers = {
    exceptions: exceptions.reducer
};
const getSwgExceptions = store_1.createFeatureSelector('swgExceptions');
const ɵ0 = state => state.exceptions;
exports.ɵ0 = ɵ0;
const getSwgExceptionsState = store_1.createSelector(getSwgExceptions, ɵ0);
exports.getExceptionItem = store_1.createSelector(getSwgExceptionsState, exceptions.getExceptionItem);
exports.getIsEditing = store_1.createSelector(getSwgExceptionsState, exceptions.isEditing);
exports.getIsApiProcessing = store_1.createSelector(getSwgExceptionsState, exceptions.isApiProcessing);
