<div class="mc-detail-view-wrapper">

  <div class="mc-detail-view-column mc-left-column">

    <div class="mc-detail-view-filters">
      <mc-filters-bundle-column-values-search-only (filtersChange)="onSearchFolders.emit($event)"
        placeholder="$18N_SYNC_RECOVER_EXCHANGE_TREE_COMPONENT.FILTERS.SEARCH_FIELD_TREE_LEFT.PLACEHOLDER">
      </mc-filters-bundle-column-values-search-only>
    </div>

    <div class="mc-detail-view-header">
      <div class="mc-detail-view-cell">
        <mc-tree-breadcrumbs *ngIf="!searchingFolders" [nodeStack]="nodeStack" [rootFolder]="rootFolder"
          [rootFolderName]="'$18N_SYNC_RECOVER_EXCHANGE_TREE_COMPONENT.ROOT_FOLDER_TITLE' | translate"
          (onFolderClicked)="browseFolder($event)"></mc-tree-breadcrumbs>
        <mc-search-folder-navigator *ngIf="searchFoldersAvailable()" [currentFolder]="currentFolder" [currentParentFolder]="currentParentFolder"
          (onGoLevelUp)="onNodeClicked.emit($event)"></mc-search-folder-navigator>
      </div>
    </div>

    <div #folderContainer class="mc-detail-view-body" mcInfiniteScroll [isDataLoading]="foldersLoading" [hotAreaElementTop]="foldersHotAreaElementTop"
      [hotAreaElementBottom]="foldersHotAreaElementBottom" [pageChangeScrollPercentage]="scrollPercentage" (onScrollDownPageChange)="getNextFolders.emit()"
      (onScrollUpPageChange)="getPreviousFolders.emit()">

      <div *ngIf="foldersLoading || !isFirstFolderPage" #folderHotAreaElementTop class="mc-detail-view-hot-area">
      </div>
      <mc-loader-full-container [isLoading]="foldersLoading">
        <div class="mc-carriage" [ngStyle]="{'min-height.px': carriageFolderMinHeight}">
          <!-- MESSAGES //-->
          <div class="mc-detail-view-row-group" *ngIf="showCategory()">
            <!-- TODO: Dani - Check this behaviour with me
          <div class="mc-detail-view-cell mc-detail-view-checkbox">
            <input type="checkbox" [checked]="row.isSelected()" (click)="toggleSelection(row)" />
          </div>
           //-->
            <div class="mc-detail-view-cell" (click)="onCategoryClicked('MESSAGE')">
              {{ '$18N_SYNC_RECOVER_EXCHANGE_TREE_COMPONENT.GROUPS.MAILS' | translate }}
            </div>
          </div>

          <div *ngIf="isContentType('MESSAGE')">
            <div class="mc-detail-view-row-folder" *ngFor="let row of folders" [class.mc-detail-view-row-highlighted]="row.isSelected()"
              placement="top" container="body" containerClass="mc-tooltip-override mc-tree-view-item" [delay]="1500" #tooltipMessages="bs-tooltip"
              [tooltip]="row.getFullPath()">
              <div class="mc-detail-view-cell mc-detail-view-checkbox">
                <input [hidden]="!exportable" type="checkbox" [checked]="row.isSelected()" (click)="toggleSelection(row)" />
              </div>
              <div class="mc-detail-view-cell mc-detail-view-icon" (click)="tooltipMessages.hide()">
                <i class="far fa-folder" aria-hidden="true"></i>
              </div>
              <div class="mc-detail-view-cell" (click)="showFolderContents(row)">
                {{ row.getName() }}
              </div>
            </div>
          </div>

          <!-- CALENDAR //-->
          <div class="mc-detail-view-row-group" *ngIf="showCategory()">
            <div class="mc-detail-view-cell" (click)="onCategoryClicked('CALENDAR')">
              {{ '$18N_SYNC_RECOVER_EXCHANGE_TREE_COMPONENT.GROUPS.CALENDARS' | translate }}
            </div>
          </div>

          <div *ngIf="isContentType('CALENDAR')">
            <div class="mc-detail-view-row-folder" *ngFor="let row of folders" [class.mc-detail-view-row-highlighted]="row.isSelected()"
              placement="top" container="body" containerClass="mc-tooltip-override mc-tree-view-item" [delay]="1500" [tooltip]="row.getFullPath()">
              <div class="mc-detail-view-cell mc-detail-view-checkbox">
                <input [hidden]="!exportable" type="checkbox" [checked]="row.isSelected()" (click)="toggleSelection(row)" />
              </div>
              <div class="mc-detail-view-cell mc-detail-view-icon">
                <i class="far fa-folder" aria-hidden="true"></i>
              </div>
              <div class="mc-detail-view-cell" (click)="showFolderContents(row)">
                {{ row.getName() }}
              </div>
            </div>
          </div>

          <!-- CONTACT //-->
          <div class="mc-detail-view-row-group" *ngIf="showCategory()">
            <div class="mc-detail-view-cell" (click)="onCategoryClicked('CONTACT')">
              {{ '$18N_SYNC_RECOVER_EXCHANGE_TREE_COMPONENT.GROUPS.CONTACTS' | translate }}
            </div>
          </div>
          <div *ngIf="isContentType('CONTACT')">
            <div class="mc-detail-view-row-folder" *ngFor="let row of folders" [class.mc-detail-view-row-highlighted]="row.isSelected()"
              placement="top" container="body" containerClass="mc-tooltip-override mc-tree-view-item" [delay]="1500" [tooltip]="row.getFullPath()">
              <div class="mc-detail-view-cell mc-detail-view-checkbox">
                <input [hidden]="!exportable" type="checkbox" [checked]="row.isSelected()" (click)="toggleSelection(row)" />
              </div>
              <div class="mc-detail-view-cell mc-detail-view-icon">
                <i class="far fa-folder" aria-hidden="true"></i>
              </div>
              <div class="mc-detail-view-cell" (click)="showFolderContents(row)">
                {{ row.getName() }}
              </div>
            </div>
          </div>


          <!-- TODO: Dani - Implement the logic  -->
          <mc-empty-results *ngIf="foldersIsEmpty()" [keyTitle]="'$18N_SYNC_RECOVER_EXCHANGE_TREE_COMPONENT.EMPTY.RESULTS.FOLDERS.TITLE' | translate"
            iconClass="far fa-folder-open">
            <mc-empty-body>
              {{ '$18N_SYNC_RECOVER_EXCHANGE_TREE_COMPONENT.EMPTY.RESULTS.FOLDERS.DESCRIPTION' | translate }}
            </mc-empty-body>

          </mc-empty-results>
          <!-- TODO: Dani - Implement the logic  -->
          <mc-empty-results *ngIf="searchFoldersIsEmpty()" [keyTitle]="'$18N_SYNC_RECOVER_EXCHANGE_TREE_COMPONENT.EMPTY.SEARCH.TITLE' | translate"
            iconClass="far fa-search">
            <mc-empty-body>
              {{ '$18N_SYNC_RECOVER_EXCHANGE_TREE_COMPONENT.EMPTY.SEARCH.DESCRIPTION' | translate }}
            </mc-empty-body>

          </mc-empty-results>
        </div>
      </mc-loader-full-container>

      <div #folderHotAreaElementBottom class="mc-detail-view-hot-area">
      </div>
    </div>

  </div>

  <div class="mc-detail-view-column mc-right-column">

    <div class="mc-detail-view-filters">
      <mc-filters-bundle-column-values-search-only (filtersChange)="filter($event)" [disabled]="!contentViewable"
        placeholder="$18N_SYNC_RECOVER_EXCHANGE_TREE_COMPONENT.FILTERS.SEARCH_FIELD_TREE_RIGHT.PLACEHOLDER">
      </mc-filters-bundle-column-values-search-only>
    </div>

    <!-- MESSAGE //-->
    <div class="mc-detail-view-header" *ngIf="isContentType('MESSAGE')">

      <div class="mc-detail-view-row">

        <div class="mc-detail-view-cell mc-column-email">
          {{ '$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_DETAIL.COLUMNS.FROM' | translate }}
        </div>

        <div class="mc-detail-view-cell mc-column-subject">
          {{ '$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_DETAIL.COLUMNS.SUBJECT' | translate }}
        </div>

        <div class="mc-detail-view-cell mc-column-datereceived">
          {{ '$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_DETAIL.COLUMNS.DATERECEIVED' | translate }}
        </div>

        <div class="mc-detail-view-cell mc-column-size">
          {{ '$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_DETAIL.COLUMNS.SIZE' | translate }}
        </div>

      </div>

    </div>

    <!-- CALENDAR //-->
    <div class="mc-detail-view-header" *ngIf="isContentType('CALENDAR')">

      <div class="mc-detail-view-row">

        <div class="mc-detail-view-cell mc-column-organiser">
          {{ '$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_DETAIL.COLUMNS.ORGANISER' | translate }}
        </div>

        <div class="mc-detail-view-cell mc-column-attendees">
          {{ '$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_DETAIL.COLUMNS.ATTENDEES' | translate }}
        </div>

        <div class="mc-detail-view-cell mc-column-subject">
          {{ '$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_DETAIL.COLUMNS.SUBJECT' | translate }}
        </div>

        <div class="mc-detail-view-cell mc-column-start">
          {{ '$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_DETAIL.COLUMNS.START' | translate }}
        </div>

        <div class="mc-detail-view-cell mc-column-end">
          {{ '$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_DETAIL.COLUMNS.END' | translate }}
        </div>

      </div>

    </div>

    <!-- CONTACT //-->
    <div class="mc-detail-view-header" *ngIf="isContentType('CONTACT')">

      <div class="mc-detail-view-row">

        <div class="mc-detail-view-cell mc-column-name">
          {{ '$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_DETAIL.COLUMNS.NAME' | translate }}
        </div>

        <div class="mc-detail-view-cell mc-column-company">
          {{ '$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_DETAIL.COLUMNS.COMPANY' | translate }}
        </div>

        <div class="mc-detail-view-cell mc-column-email">
          {{ '$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_DETAIL.COLUMNS.EMAILADDRESS' | translate }}
        </div>

        <div class="mc-detail-view-cell mc-column-modified">
          {{ '$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_DETAIL.COLUMNS.MODIFIED' | translate }}
        </div>

      </div>

    </div>


    <div #contentContainer class="mc-detail-view-body" [class.mc-body-clickable]="contentViewable" mcInfiniteScroll [hotAreaElementTop]="contentsHotAreaElementTop"
      [hotAreaElementBottom]="contentsHotAreaElementBottom" [isDataLoading]="contentsLoading" [pageChangeScrollPercentage]="scrollPercentage"
      (onScrollDownPageChange)="getNextContents.emit()" (onScrollUpPageChange)="getPreviousContents.emit()">

      <div *ngIf="contentsLoading || !isFirstContentPage" #contentHotAreaElementTop class="mc-detail-view-hot-area">
      </div>

      <mc-loader-full-container [isLoading]="contentsLoading">
        <div class="mc-carriage" [ngStyle]="{'min-height.px': carriageContentMinHeight}">
          <div *ngIf="showCategoryContents('MESSAGE')">

            <div class="mc-detail-view-row" *ngFor="let row of contents; let i = index" (click)="toggleItemSelection(row, i)"
              [class.mc-detail-view-row-highlighted]="contentViewable && itemClickedIndex === i">

              <div class="mc-detail-view-cell mc-column-email">{{ row.getFrom() && (row.getFrom().emailAddress || row.getFrom() ) }}</div>

              <div class="mc-detail-view-cell mc-column-subject">{{ row.getSubject() }}</div>

              <div class="mc-detail-view-cell mc-column-datereceived">{{ row.getDateReceived() | mcDate }}</div>

              <div class="mc-detail-view-cell mc-column-size">{{ row.getSize() | mcToBytes }}</div>

            </div>

            <mc-empty-results *ngIf="contentIsEmpty()" [keyTitle]="'$18N_SYNC_RECOVER_EXCHANGE_TREE_COMPONENT.EMPTY.RESULTS.MAILS.TITLE' | translate"
              iconClass="far fa-hdd">
              <mc-empty-body>
                {{ '$18N_SYNC_RECOVER_EXCHANGE_TREE_COMPONENT.EMPTY.RESULTS.MAILS.DESCRIPTION' | translate }}
              </mc-empty-body>
            </mc-empty-results>

          </div>

          <div *ngIf="showCategoryContents('CALENDAR')">

            <div class="mc-detail-view-row" *ngFor="let row of contents; let i = index" (click)="toggleItemSelection(row, i)"
              [class.mc-detail-view-row-highlighted]="contentViewable && itemClickedIndex === i">

              <div class="mc-detail-view-cell  mc-column-organiser">{{ row.getOrganiser() && row.getOrganiser().emailAddress }}</div>

              <div class="mc-detail-view-cell mc-column-attendees">{{ showCalendarAtendeeEmail(row)}}
                <div *ngIf="isRecipientsNotEmpty(row)" class="mc-column-attendees-more">
                  {{ '$18N_SYNC_RECOVER_EXCHANGE_TREE_COMPONENT.COLUMNS.CALENDAR.ATENDEES' | translate:{ numberAtendees: row.getRecipients().length - 1 } }}</div>
              </div>

              <div class="mc-detail-view-cell mc-column-subject">{{ row.getSubject() }}</div>

              <div class="mc-detail-view-cell mc-column-start">{{ row.getStart() | mcDate }}</div>

              <div class="mc-detail-view-cell mc-column-end">{{ row.getEnd() | mcDate }}</div>

            </div>

            <mc-empty-results *ngIf="contentIsEmpty()" [keyTitle]="'$18N_SYNC_RECOVER_EXCHANGE_TREE_COMPONENT.EMPTY.RESULTS.CALENDARS.TITLE' | translate"
              iconClass="far fa-hdd">
              <mc-empty-body>
                {{ '$18N_SYNC_RECOVER_EXCHANGE_TREE_COMPONENT.EMPTY.RESULTS.CALENDARS.DESCRIPTION' | translate }}
              </mc-empty-body>

            </mc-empty-results>

          </div>

          <div *ngIf="showCategoryContents('CONTACT')">

            <div class="mc-detail-view-row" *ngFor="let row of contents; let i = index" (click)="toggleItemSelection(row, i)"
              [class.mc-detail-view-row-highlighted]="contentViewable && itemClickedIndex === i">

              <div class="mc-detail-view-cell mc-column-name">{{ row.getName() }}</div>

              <div class="mc-detail-view-cell mc-column-company">{{ row.getCompany() }}</div>

              <div class="mc-detail-view-cell mc-column-email">{{ row.getEmailAddress() }}</div>

              <div class="mc-detail-view-cell mc-column-modified">{{ row.getModified() | mcDate }}</div>

            </div>

            <mc-empty-results *ngIf="contentIsEmpty()" [keyTitle]="'$18N_SYNC_RECOVER_EXCHANGE_TREE_COMPONENT.EMPTY.RESULTS.CONTACTS.TITLE' | translate"
              iconClass="far fa-hdd">
              <mc-empty-body>
                {{ '$18N_SYNC_RECOVER_EXCHANGE_TREE_COMPONENT.EMPTY.RESULTS.CONTACTS.DESCRIPTION' | translate }}
              </mc-empty-body>

            </mc-empty-results>

          </div>

          <mc-empty-results *ngIf="contentsInitialState" [keyTitle]="'$18N_SYNC_RECOVER_EXCHANGE_TREE_COMPONENT.EMPTY.FIRSTTIME.TITLE' | translate"
            iconClass="far fa-folder-open">
          </mc-empty-results>

          <mc-empty-results *ngIf="searchContentsIsEmpty()" [keyTitle]="'$18N_SYNC_RECOVER_EXCHANGE_TREE_COMPONENT.EMPTY.SEARCH.TITLE' | translate"
            iconClass="far fa-search">
            <mc-empty-body>
              {{ '$18N_SYNC_RECOVER_EXCHANGE_TREE_COMPONENT.EMPTY.SEARCH.DESCRIPTION' | translate }}
            </mc-empty-body>

          </mc-empty-results>
        </div>

      </mc-loader-full-container>
      <div #contentHotAreaElementBottom class="mc-detail-view-hot-area">
      </div>
    </div>
  </div>

</div>
