"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListImplService = void 0;
const table_store_1 = require("@mimecast-ui/table-store");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
class ListImplService {
    constructor(bouncesService) {
        this.bouncesService = bouncesService;
    }
    getData(payload, pagination = table_store_1.defaultPaginationOption) {
        const config = Object.assign(Object.assign({}, payload), { meta: { pagination } });
        return this.bouncesService.getBounces(config).pipe(operators_1.map((response) => {
            return {
                hasErrors: response.hasErrors,
                data: this.checkIfEmptyFromHdr(response.first.bounces),
                failures: response.fail,
                meta: response.meta
            };
        }), operators_1.catchError((response) => {
            return rxjs_1.of({
                hasErrors: response.hasErrors,
                data: this.checkIfEmptyFromHdr(response.first.bounces),
                failures: response.fail,
                meta: response.meta
            });
        }));
    }
    checkIfEmptyFromHdr(bouncesEmails) {
        return bouncesEmails.map((email) => {
            return Object.assign(Object.assign({}, email), { fromHdr: this.getFromEnvIfEmptyFromHdr(email) });
        });
    }
    getFromEnvIfEmptyFromHdr(email) {
        return email.fromHdr && email.fromHdr.emailAddress ? email.fromHdr : Object.assign({}, email.fromEnv);
    }
    filter(query, pagination) {
        return this.getData(query, pagination);
    }
    openItem() {
        return rxjs_1.of([]);
    }
}
exports.ListImplService = ListImplService;
ListImplService.TABLE_ID = 'BouncesList';
