"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogDetailsComponent = void 0;
const core_1 = require("@angular/core");
class LogDetailsComponent {
    constructor() {
        this.selectRow = new core_1.EventEmitter();
        this.closeAside = new core_1.EventEmitter();
        this.permitSender = new core_1.EventEmitter();
        this.blockSender = new core_1.EventEmitter();
        this.monitorDomain = new core_1.EventEmitter();
    }
}
exports.LogDetailsComponent = LogDetailsComponent;
