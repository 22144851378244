'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const _ = require("lodash");
require("app/components/mega-menu/mega-menu-service"); //mega.menu.service
require("mimecast-web-components/src/app/services/session-handler/session-handler"); //session-handler
angular.module('legacy.controller', ['mega.menu.service', 'session-handler'])
    .controller('LegacyController', ['$scope', '$stateParams', 'megaMenuService', 'navbarService', 'sessionHandlerService', '$state', 'adconSession', '$window', '$rootScope', 'modalErrorReportService', '$interval', '$q', 'modalActionService', 'administrationService', 'classicAdconHelperService',
    function ($scope, $stateParams, megaMenuService, navbarService, sessionHandlerService, $state, adconSession, $window, $rootScope, modalErrorReportService, $interval, $q, modalActionService, administrationService, classicAdconHelperService) {
        $scope.options = {
            url: adconSession.url + '/mimecast/admin',
        };
        const closeDefer = $q.defer();
        let timeoutPromise = $q.defer();
        $scope.options.params = {};
        angular.forEach($state.params, function (paramValue, paramKey) {
            if (angular.isDefined(paramKey) && angular.isDefined(paramValue)) {
                $scope.options.params[paramKey] = paramValue;
            }
        });
        if (!$window.location.origin) {
            $window.location.origin = $window.location.protocol + '//' + $window.location.hostname + ($window.location.port ? ':' + $window.location.port : '');
        }
        $scope.options.params.SysAccountSessionId = adconSession.SysAccountSessionId;
        $scope.options.params.accountCode = adconSession.accountCode;
        let historyCount = 0;
        timeoutPromise = $interval(function () {
            modalActionService.openActionModal(classicAdconHelperService.getLegacyLogoutConfirmation(logout)).then(function () { });
        }, 60000, 1);
        function logout() {
            const frameReady = $q.defer();
            const cancel = $scope.$watch('frame', function (frame) {
                if (frame) {
                    cancel();
                    frameReady.resolve(frame);
                    $rootScope.frame = frame;
                }
                else {
                    frameReady.reject();
                }
            });
            administrationService.logout(frameReady.promise);
        }
        /**
         * Handles message sent from adcon3.5 when a page is loaded
         */
        const unregisterNewPageListener = $rootScope.$on('adcon3.5-new-page', function () {
            $interval.cancel(timeoutPromise);
            if (!$scope.tab.selected)
                return;
            historyCount += 2;
            /*
            * we do +2 because in other to controll the back button in adcon3.5 we had to add a fake page in to history, so is
            * 1 for the current page and 1 for the fake page added using history.push
            */
        });
        /**
         * Handles message sent from adcon3.5 when back button is press
         */
        const unregisterBackPageListener = $rootScope.$on('adcon3.5-back-page', function (event, data) {
            if (!$scope.tab.selected)
                return;
            historyCount--;
            /*
            * if the back button is call in first Adcon3.5 page  meaning before that page is an adcon4 page,
            * we had to jump all the fake history we had created
            */
            if (data === 'first-adcon3.5-page') {
                history.go(-historyCount);
                historyCount = 0;
            }
        });
        /*
        * Report error that happened in classic adcon.
        * */
        const classicAdconUnhandledErrorListener = $rootScope.$on('adcon-action-error', function () {
            if (!$scope.tab.selected)
                return;
            modalErrorReportService.openErrorModal($scope.tabsCtrl);
            modalErrorReportService.closeLegacyErrorTab();
        });
        const closeTabListener = $rootScope.$on('closetab', function () {
            if (!$scope.tab.selected)
                return;
            closeDefer.resolve('previous');
        });
        $scope.$on('$destroy', function () {
            unregisterNewPageListener();
            unregisterBackPageListener();
            classicAdconUnhandledErrorListener();
            closeTabListener();
        });
        if ($scope.tab) {
            $scope.tab.onRefresh = function () {
                $scope.frame.postMessage('reload', null, adconSession.url);
            };
            $scope.tab.onSelect = function () {
                /*
                * when an adcon3.5 tab is reselected we send a message to adcon3.5 so a new fake history is push to disable
                * the forward button because is not supported in adcon3.5
                * */
                historyCount = 0;
                $scope.frame.postMessage('start-page', null, adconSession.url);
            };
            $scope.tab.onClose = function () {
                $scope.frame.postMessage('close', null, adconSession.url);
                if (!$scope.tab.selected)
                    return;
                return closeDefer.promise;
            };
        }
        navbarService.findMenuItemByState($state.current, $stateParams).then(function (menu) {
            $scope.tab.title = ((menu.item.label) ? menu.item.label : '');
            $scope.tab.breadcrumbs = getLegacyBreadCrumbs(menu);
        }, function () {
            $scope.tab.close();
            $state.go('administration-dashboard');
        });
        function getLegacyBreadCrumbs(menu) {
            return (!_.isEmpty(menu.breadcrumbs)) ? menu.breadcrumbs : ((menu.item.state.data
                && menu.item.state.data.breadcrumbs ? menu.item.state.data.breadcrumbs : []));
        }
    }]);
