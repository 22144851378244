"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserMailboxFallbackAction = exports.UserMailboxAction = exports.GatewayFallbackActions = exports.GatewayActions = exports.SpreadsheetsWorksheetsOption = exports.SpreadsheetsFormatOption = exports.DocumentFormatOption = exports.PreEmptiveFailureActionOption = exports.ConfigurationDetailOption = exports.UserNotificationOption = exports.ApConfigurationType = exports.CONFIRMATION_DIALOG_TYPES = void 0;
var CONFIRMATION_DIALOG_TYPES;
(function (CONFIRMATION_DIALOG_TYPES) {
    CONFIRMATION_DIALOG_TYPES["DELETE"] = "DELETE";
    CONFIRMATION_DIALOG_TYPES["CANCEL"] = "CANCEL";
    CONFIRMATION_DIALOG_TYPES["OVERRIDE"] = "OVERRIDE";
    CONFIRMATION_DIALOG_TYPES["SIGNED_MESSAGES"] = "SIGNED_MESSAGES";
    CONFIRMATION_DIALOG_TYPES["FROM_TO_EVERYONE"] = "FROM_TO_EVERYONE";
})(CONFIRMATION_DIALOG_TYPES = exports.CONFIRMATION_DIALOG_TYPES || (exports.CONFIRMATION_DIALOG_TYPES = {}));
var ApConfigurationType;
(function (ApConfigurationType) {
    ApConfigurationType["SAFEDOC_ONLY"] = "safedoc_only";
    ApConfigurationType["SANDBOX"] = "sandbox";
    ApConfigurationType["DYNAMIC"] = "dynamic";
    ApConfigurationType["SAFEDOC_AND_LINK"] = "safedoc_and_link";
})(ApConfigurationType = exports.ApConfigurationType || (exports.ApConfigurationType = {}));
var UserNotificationOption;
(function (UserNotificationOption) {
    UserNotificationOption["INTERNALRECIPIENT"] = "Internal Recipient";
    UserNotificationOption["EXTERNALRECIPIENT"] = "External Recipient";
    UserNotificationOption["INTERNALSENDER"] = "Internal Sender";
})(UserNotificationOption = exports.UserNotificationOption || (exports.UserNotificationOption = {}));
var ConfigurationDetailOption;
(function (ConfigurationDetailOption) {
    ConfigurationDetailOption["SAFEFILE"] = "safedoc_only";
    ConfigurationDetailOption["SAFEFILEONDEMAND"] = "safedoc_and_link";
    ConfigurationDetailOption["PREEXEMPTIVE"] = "sandbox";
    ConfigurationDetailOption["DYNAMICCONFIGURATION"] = "dynamic";
})(ConfigurationDetailOption = exports.ConfigurationDetailOption || (exports.ConfigurationDetailOption = {}));
var PreEmptiveFailureActionOption;
(function (PreEmptiveFailureActionOption) {
    PreEmptiveFailureActionOption["BOUNCE"] = "bounce";
    PreEmptiveFailureActionOption["ADMINHOLD"] = "adminhold";
})(PreEmptiveFailureActionOption = exports.PreEmptiveFailureActionOption || (exports.PreEmptiveFailureActionOption = {}));
var DocumentFormatOption;
(function (DocumentFormatOption) {
    DocumentFormatOption["PDF"] = "pdf";
    DocumentFormatOption["TIFF"] = "tiff";
    DocumentFormatOption["ORIGINALFORMAT"] = "original";
})(DocumentFormatOption = exports.DocumentFormatOption || (exports.DocumentFormatOption = {}));
var SpreadsheetsFormatOption;
(function (SpreadsheetsFormatOption) {
    SpreadsheetsFormatOption["PDF"] = "pdf";
    SpreadsheetsFormatOption["TIFF"] = "tiff";
    SpreadsheetsFormatOption["ORIGINALFORMAT"] = "original";
    SpreadsheetsFormatOption["HTML"] = "html";
    SpreadsheetsFormatOption["HTMLMULTITAB"] = "html_zip";
    SpreadsheetsFormatOption["CSV"] = "csv";
})(SpreadsheetsFormatOption = exports.SpreadsheetsFormatOption || (exports.SpreadsheetsFormatOption = {}));
var SpreadsheetsWorksheetsOption;
(function (SpreadsheetsWorksheetsOption) {
    SpreadsheetsWorksheetsOption["TRANSCRIBEALLWORKSHEETS"] = "all_work_sheets";
    SpreadsheetsWorksheetsOption["TRANSCRIBEFIRSTWORKSHEET"] = "first_sheet_only";
})(SpreadsheetsWorksheetsOption = exports.SpreadsheetsWorksheetsOption || (exports.SpreadsheetsWorksheetsOption = {}));
var GatewayActions;
(function (GatewayActions) {
    GatewayActions["HOLD"] = "hold";
    GatewayActions["BOUNCE"] = "bounce";
    GatewayActions["NONE"] = "none";
})(GatewayActions = exports.GatewayActions || (exports.GatewayActions = {}));
var GatewayFallbackActions;
(function (GatewayFallbackActions) {
    GatewayFallbackActions["HOLD"] = "hold";
    GatewayFallbackActions["BOUNCE"] = "bounce";
    GatewayFallbackActions["NONE"] = "none";
})(GatewayFallbackActions = exports.GatewayFallbackActions || (exports.GatewayFallbackActions = {}));
var UserMailboxAction;
(function (UserMailboxAction) {
    UserMailboxAction["NONE"] = "none";
    UserMailboxAction["REMOVEMESSAGE"] = "remove_email";
    UserMailboxAction["REMOVEATTACHMENT"] = "remove_attachment";
})(UserMailboxAction = exports.UserMailboxAction || (exports.UserMailboxAction = {}));
var UserMailboxFallbackAction;
(function (UserMailboxFallbackAction) {
    UserMailboxFallbackAction["NONE"] = "none";
    UserMailboxFallbackAction["REMOVEMESSAGE"] = "remove_email";
    UserMailboxFallbackAction["REMOVEATTACHMENT"] = "remove_attachment";
})(UserMailboxFallbackAction = exports.UserMailboxFallbackAction || (exports.UserMailboxFallbackAction = {}));
