"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfirmationModalComponent = void 0;
class ConfirmationModalComponent {
    constructor(overlayRef, data) {
        this.overlayRef = overlayRef;
        this.data = data;
    }
    ngOnInit() { }
    onEnable() {
        this.overlayRef.close(true);
    }
    onCancel() {
        this.overlayRef.close(false);
    }
}
exports.ConfirmationModalComponent = ConfirmationModalComponent;
