<mc-wizard-step-header [label]="'$I18N_SWG_CREATE_POLICY_WEB_ADVANCED_SECURITY_CONFIGURE_DNS_PROXY'"></mc-wizard-step-header>
<div class="mc-dns-proxy-settings-step">
  <form novalidate [formGroup]='proxySettingsForm' class="mc-dns-proxy-settings-form">
    <div class="text-bold">{{'$I18N_SWG_CREATE_POLICY_WEB_ADVANCED_SECURITY_DNS_PROXY_STEP' | translate}}</div>
    <mc-enable-switch [longDesc]="'$I18N_SWG_CREATE_POLICY_WEB_ADVANCED_SECURITY_DNS_PROXIES_DESC'" formControlName='enabled'></mc-enable-switch>
    <div *ngIf="proxySettingsForm.get('enabled').value">
      <div class="text-bold">{{'$I18N_SWG_CREATE_POLICY_WEB_ADVANCED_SECURITY_AV_SCAN' | translate}}</div>
      <mc-enable-switch [longDesc]="'$I18N_SWG_CREATE_POLICY_WEB_ADVANCED_SECURITY_AV_SCAN_DESC'" formControlName='avScan'></mc-enable-switch>
      <div class="row form-group mc-policy-unscannable-content" *ngIf="proxySettingsForm.get('avScan').value">
        <div *ngIf="hasBI && proxySettingsForm.get('enabled').value && proxySettingsForm.get('avScan').value">
          <div class="text-bold">{{'$I18N_SWG_CREATE_POLICY_WEB_ADVANCED_SECURITY_BI' | translate}}</div>
          <p class="no-margin panel-half-padding-bottom">
            <span>{{ '$I18N_SWG_CREATE_POLICY_WEB_ADVANCED_SECURITY_BI_DESC' | translate }} </span>
          </p>
          <mc-enable-switch [longDesc]="''" formControlName='biEnabled'></mc-enable-switch>
        </div>
        <div class="text-bold">{{'$I18N_SWG_CREATE_POLICY_WEB_ADVANCED_SECURITY_UNSCANNABLE' | translate}}</div>
        <div class="panel-half-padding-bottom">{{'$I18N_SWG_CREATE_POLICY_WEB_ADVANCED_SECURITY_UNSCANNABLE_DESC' | translate}}</div>
        <label>
          <input type="radio" name="blockUnscannable" value="block"
                 formControlName="blockUnscannable">

          <span class="mc-swg-policy-choices"> {{'$I18N_SWG_CREATE_POLICY_WEB_ADVANCED_SECURITY_ACTION_BLOCK' | translate }} </span>
        </label>
        <label>
          <input type="radio" name="blockUnscannable" value="allow"
                 formControlName="blockUnscannable">
          <span class="mc-swg-policy-choices">{{'$I18N_SWG_CREATE_POLICY_WEB_ADVANCED_SECURITY_ACTION_ALLOW' | translate }} </span>
        </label>
        <div class="mc-proxy-extension-section" *ngIf="proxySettingsForm.get('avScan').value && hasExtendedProxy && !protectedApplicationsLoading">
          <mc-inline-notification [notification]="proxyExtNotification"></mc-inline-notification>
          <mc-swg-policy-category-filter-step
            [expanded]="true"
            [disabled]="false"
            (updateWebCategoryRules)="updateWebCategoryRules.emit($event)"
            [webCategoryRuleSections]="webCategoryRuleSections"
            [hideFilterSearch]=true
            [hideWizardHeader]=true
          ></mc-swg-policy-category-filter-step>
        </div>

      </div>

    </div>

  </form>
</div>
