"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditDomainPageComponent = void 0;
const edit_domain_actions_1 = require("app-new/ttp/chameleon/monitored-external-domain/actions/edit-domain.actions");
const add_domains_actions_1 = require("../../../actions/add-domains.actions");
const fromChameleonState = require("../../../reducers");
class EditDomainPageComponent {
    constructor(store) {
        this.store = store;
        this.selectedRow$ = this.store.select(fromChameleonState.getSelectedRow);
    }
    onSave(formValues) {
        this.store.dispatch(new edit_domain_actions_1.UpdateDomain(formValues));
    }
    onCancel() {
        this.store.dispatch(new add_domains_actions_1.CloseAside());
    }
    onDelete(domain) {
        this.store.dispatch(new edit_domain_actions_1.DeleteSingleDomain(domain));
    }
    onCloseAside() {
        this.store.dispatch(new add_domains_actions_1.CloseAside());
    }
}
exports.EditDomainPageComponent = EditDomainPageComponent;
