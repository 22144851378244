"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgDeviceGroupDeleteConfirmationModalClose = exports.SwgDeviceGroupDeleteConfirmationModalOpen = exports.SwgDeviceGroupDeleteFailure = exports.SwgDeviceGroupDeleteSuccess = exports.SwgDeviceGroupDelete = exports.SwgDeviceGroupSidebarClose = exports.SwgDeviceGroupSidebarOpen = exports.SwgDeviceGroupsSidebarActionsEnum = void 0;
var SwgDeviceGroupsSidebarActionsEnum;
(function (SwgDeviceGroupsSidebarActionsEnum) {
    SwgDeviceGroupsSidebarActionsEnum["SWG_DEVICE_GROUP_SIDEBAR_OPEN"] = "[SwgDeviceGroups] Swg Device Group sidebar open";
    SwgDeviceGroupsSidebarActionsEnum["SWG_DEVICE_GROUP_SIDEBAR_CLOSE"] = "[SwgDeviceGroups] Swg Device Group sidebar close";
    SwgDeviceGroupsSidebarActionsEnum["SWG_DEVICE_GROUP_DELETE"] = "[SwgDeviceGroups] Swg Device Group delete";
    SwgDeviceGroupsSidebarActionsEnum["SWG_DEVICE_GROUP_DELETE_SUCCESS"] = "[SwgDeviceGroups] Swg Device Group delete success";
    SwgDeviceGroupsSidebarActionsEnum["SWG_DEVICE_GROUP_DELETE_FAILURE"] = "[SwgDeviceGroups] Swg Device Group delete failure";
    SwgDeviceGroupsSidebarActionsEnum["SWG_DEVICE_GROUP_DELETE_CONFIRMATION_MODAL_OPEN"] = "[SwgDeviceGroups] Swg Device Group delete confirmation modal open";
    SwgDeviceGroupsSidebarActionsEnum["SWG_DEVICE_GROUP_DELETE_CONFIRMATION_MODAL_CLOSE"] = "[SwgDeviceGroups] Swg Device Group delete confirmation modal close";
})(SwgDeviceGroupsSidebarActionsEnum = exports.SwgDeviceGroupsSidebarActionsEnum || (exports.SwgDeviceGroupsSidebarActionsEnum = {}));
class SwgDeviceGroupSidebarOpen {
    constructor() {
        this.type = SwgDeviceGroupsSidebarActionsEnum.SWG_DEVICE_GROUP_SIDEBAR_OPEN;
    }
}
exports.SwgDeviceGroupSidebarOpen = SwgDeviceGroupSidebarOpen;
class SwgDeviceGroupSidebarClose {
    constructor() {
        this.type = SwgDeviceGroupsSidebarActionsEnum.SWG_DEVICE_GROUP_SIDEBAR_CLOSE;
    }
}
exports.SwgDeviceGroupSidebarClose = SwgDeviceGroupSidebarClose;
class SwgDeviceGroupDelete {
    constructor(id) {
        this.id = id;
        this.type = SwgDeviceGroupsSidebarActionsEnum.SWG_DEVICE_GROUP_DELETE;
    }
}
exports.SwgDeviceGroupDelete = SwgDeviceGroupDelete;
class SwgDeviceGroupDeleteSuccess {
    constructor() {
        this.type = SwgDeviceGroupsSidebarActionsEnum.SWG_DEVICE_GROUP_DELETE_SUCCESS;
    }
}
exports.SwgDeviceGroupDeleteSuccess = SwgDeviceGroupDeleteSuccess;
class SwgDeviceGroupDeleteFailure {
    constructor() {
        this.type = SwgDeviceGroupsSidebarActionsEnum.SWG_DEVICE_GROUP_DELETE_FAILURE;
    }
}
exports.SwgDeviceGroupDeleteFailure = SwgDeviceGroupDeleteFailure;
class SwgDeviceGroupDeleteConfirmationModalOpen {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgDeviceGroupsSidebarActionsEnum.SWG_DEVICE_GROUP_DELETE_CONFIRMATION_MODAL_OPEN;
    }
}
exports.SwgDeviceGroupDeleteConfirmationModalOpen = SwgDeviceGroupDeleteConfirmationModalOpen;
class SwgDeviceGroupDeleteConfirmationModalClose {
    constructor() {
        this.type = SwgDeviceGroupsSidebarActionsEnum.SWG_DEVICE_GROUP_DELETE_CONFIRMATION_MODAL_CLOSE;
    }
}
exports.SwgDeviceGroupDeleteConfirmationModalClose = SwgDeviceGroupDeleteConfirmationModalClose;
