"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgUpdateExceptionFailureAction = exports.SwgUpdateExceptionSuccessAction = exports.SwgDeleteExceptionAction = exports.SwgUpdateExceptionAction = exports.SwgAddExceptionAction = exports.SwgExceptionsActions = void 0;
var SwgExceptionsActions;
(function (SwgExceptionsActions) {
    SwgExceptionsActions["SWG_ADD_EXCEPTION"] = "[SwgExceptions] Add Exception";
    SwgExceptionsActions["SWG_UPDATE_EXCEPTION"] = "[SwgExceptions] Edit Exception";
    SwgExceptionsActions["SWG_DELETE_EXCEPTION"] = "[SwgExceptions] Delete Exception";
    SwgExceptionsActions["SWG_UPDATE_EXCEPTION_SUCCESS"] = "[SwgExceptions] Update Exception Success";
    SwgExceptionsActions["SWG_UPDATE_EXCEPTION_FAILURE"] = "[SwgExceptions] Update Exception Failure";
})(SwgExceptionsActions = exports.SwgExceptionsActions || (exports.SwgExceptionsActions = {}));
class SwgAddExceptionAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgExceptionsActions.SWG_ADD_EXCEPTION;
    }
}
exports.SwgAddExceptionAction = SwgAddExceptionAction;
class SwgUpdateExceptionAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgExceptionsActions.SWG_UPDATE_EXCEPTION;
    }
}
exports.SwgUpdateExceptionAction = SwgUpdateExceptionAction;
class SwgDeleteExceptionAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgExceptionsActions.SWG_DELETE_EXCEPTION;
    }
}
exports.SwgDeleteExceptionAction = SwgDeleteExceptionAction;
class SwgUpdateExceptionSuccessAction {
    constructor() {
        this.type = SwgExceptionsActions.SWG_UPDATE_EXCEPTION_SUCCESS;
    }
}
exports.SwgUpdateExceptionSuccessAction = SwgUpdateExceptionSuccessAction;
class SwgUpdateExceptionFailureAction {
    constructor() {
        this.type = SwgExceptionsActions.SWG_UPDATE_EXCEPTION_FAILURE;
    }
}
exports.SwgUpdateExceptionFailureAction = SwgUpdateExceptionFailureAction;
