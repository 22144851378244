"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IdentityDeleteAppModalComponent = void 0;
const reducers_1 = require("../../reducers");
const appActions = require("../../actions/apps/apps.actions");
class IdentityDeleteAppModalComponent {
    constructor(store) {
        this.store = store;
    }
    ngOnInit() {
        this.app$ = this.store.select(reducers_1.getSelectedApp);
        this.isDeleting$ = this.store.select(reducers_1.getIsDeleteAppApiProcessing);
    }
    onDelete(app) {
        this.store.dispatch(new appActions.IdentityDeleteAppAction(app));
    }
    onCancel() {
        this.store.dispatch(new appActions.IdentityDeleteModalCloseAction());
    }
}
exports.IdentityDeleteAppModalComponent = IdentityDeleteAppModalComponent;
