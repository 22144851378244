"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasGenericError = exports.isTableLoading = exports.getLogs = exports.reducer = exports.initialState = void 0;
const logAction = require("../../actions/logs/logs.action");
exports.initialState = {
    logs: [],
    isLoading: false,
    genericError: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case logAction.GET_LOGS:
            return Object.assign(Object.assign({}, state), { genericError: false, isLoading: true });
        case logAction.GET_LOGS_SUCCESS:
            return Object.assign(Object.assign({}, state), { logs: action.payload, genericError: false, isLoading: false });
        case logAction.GET_LOGS_FAILURE:
            return Object.assign(Object.assign({}, state), { genericError: true, isLoading: false });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getLogs = (state) => {
    return state.logs.slice(0, 5);
};
exports.getLogs = getLogs;
const isTableLoading = (state) => state.isLoading;
exports.isTableLoading = isTableLoading;
const hasGenericError = (state) => state.genericError;
exports.hasGenericError = hasGenericError;
