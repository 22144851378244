"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./time-exclusions-directive"); //predefined-field.time-exclusions.duration.directive
// html and css 
require("./time-exclusions.less");
require("./time-exclusions.tpl.html");
/**
 * Created by asyed.
 */
(function () {
    'use strict';
    angular.module('predefined-field.time-exclusions', ['predefined-field.time-exclusions.duration.directive']);
})();
