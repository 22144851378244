"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgEndpointsListSidebarContainerComponent = void 0;
const table_store_1 = require("@mimecast-ui/table-store");
const sidebar_actions_1 = require("../../actions/sidebar.actions");
const endpoints_list_models_1 = require("../../models/endpoints-list.models");
class SwgEndpointsListSidebarContainerComponent extends table_store_1.TableRowDetailBaseComponent {
    constructor(store, dataMappingService) {
        super(store, 'SwgEndpointsListTable');
        this.store = store;
        this.dataMappingService = dataMappingService;
        this.deviceOS = endpoints_list_models_1.DEVICE;
        this.selectedRowSubscription = this.selectedRow$.subscribe((row) => {
            this.hasMultiUsersSupport = false;
            if (!!row.protectedDeviceDetail.osEdition) {
                this.hasMultiUsersSupport = this.dataMappingService.hasMultiUsersSupport(row.protectedDeviceDetail.osEdition);
            }
        });
    }
    close() {
        this.store.dispatch(new sidebar_actions_1.SwgEndpointsListSidebarClose());
    }
    ngOnDestroy() {
        if (this.selectedRowSubscription) {
            this.selectedRowSubscription.unsubscribe();
        }
    }
}
exports.SwgEndpointsListSidebarContainerComponent = SwgEndpointsListSidebarContainerComponent;
