"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const static_1 = require("@angular/upgrade/static");
require("app/services/services/onedrive/aside/aside");
const containers_1 = require("./containers");
const drive_browser_component_1 = require("./components/drive-browser/drive-browser.component");
angular
    .module('onedrive.drives', ['onedrive-aside'])
    .controller('OnedriveDrivesController', function () { })
    .directive('mcOnedriveDrives', static_1.downgradeComponent({
    component: containers_1.OneDriveDrivesPageComponent
}))
    .directive('mcOnedriveDriveBrowser', static_1.downgradeComponent({
    component: drive_browser_component_1.DriveBrowserComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        const breadcrumbs = [];
        $stateProvider
            .state('one-drive-list', {
            url: '/onedrive/drives',
            data: {
                capabilities: 'Development.Only && Temporary.Services.OneDrive',
                asideTitle: '$I18N_ONE_DRIVE.SUB_MENU_TITLE',
                isFixedLayout: false,
                checkProgress: false,
                tabId: 'one-drive',
                tabTitle: '$I18N_ONE_DRIVE.TAB_TITLE',
                breadcrumbs: ['$I18N_ONEDRIVE_LIST.TITLE'],
                tabReload: false
            },
            views: {
                main: {
                    template: '<mc-onedrive-drives></mc-onedrive-drives>',
                    controller: 'OnedriveDrivesController'
                },
                aside: {
                    templateUrl: 'services/services/onedrive/aside/aside.tpl.html'
                }
            }
        })
            .state('one-drive-browser', {
            url: '/onedrive/drive',
            params: { drive: null },
            data: {
                capabilities: 'Development.Only && (Permission.ONEDRIVE_SYNC_READ || Permission.ONEDRIVE_SYNC_AND_RECOVER_READ)',
                asideTitle: '$I18N_ONE_DRIVE.SUB_MENU_TITLE',
                isFixedLayout: false,
                checkProgress: false,
                tabId: 'one-drive-browser',
                tabTitle: '$I18N_ONEDRIVE_DRIVE_BROWSER.TAB_TITLE',
                breadcrumbs,
                tabReload: false
            },
            views: {
                main: {
                    template: '<mc-onedrive-drive-browser></mc-onedrive-drive-browser>',
                    controller: 'OnedriveDrivesController'
                },
                aside: {
                    templateUrl: 'services/services/onedrive/aside/aside.tpl.html'
                }
            },
            onEnter: [
                '$state',
                '$stateParams',
                function ($state, $stateParams) {
                    breadcrumbs.length = 0;
                    breadcrumbs.push('$I18N_MEGA_MENU_GROUP_SERVICES_LABEL');
                    breadcrumbs.push('$I18N_ONE_DRIVE.TAB_TITLE');
                    breadcrumbs.push('$I18N_ONEDRIVE_LIST.TITLE');
                    breadcrumbs.push($stateParams.drive.displayName);
                    if (!$stateParams.drive) {
                        $state.go('one-drive-list', $stateParams);
                    }
                }
            ]
        });
    }
]);
