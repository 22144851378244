"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MepPolicySidebarContainerComponent = void 0;
const reducers = require("../../reducers");
const sidebarActions = require("../../actions/sidebar.actions");
class MepPolicySidebarContainerComponent {
    constructor($state, store) {
        this.$state = $state;
        this.store = store;
    }
    ngOnInit() {
        this.policy$ = this.store.select(reducers.getMepPolicy);
        this.apiSavingStatus$ = this.store.select(reducers.getApiSavingStatus);
        this.loadingForEdit$ = this.store.select(reducers.getLoadingForEdit);
    }
    onPolicyStatusChange(policy) {
        if (policy.enabled) {
            this.store.dispatch(new sidebarActions.MepPolicyListDisablePolicy(policy));
        }
        else {
            this.store.dispatch(new sidebarActions.MepPolicyListEnablePolicy(policy));
        }
    }
    close() {
        this.store.dispatch(new sidebarActions.MepPolicyListSidebarClose());
    }
}
exports.MepPolicySidebarContainerComponent = MepPolicySidebarContainerComponent;
