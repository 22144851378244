"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgPolicyEffects = void 0;
const effects_1 = require("@ngrx/effects");
const policy_actions_1 = require("app-new/swg/policies/actions/policy.actions");
const policy_1 = require("app-new/swg/policies/models/policy");
const reducers = require("app-new/swg/policies/reducers");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const notification_actions_1 = require("../../../../components/notification/actions/notification.actions");
const tableStore = require("@mimecast-ui/table-store");
const actions = require("../../actions/policy.actions");
const policy_actions_2 = require("../../actions/policy.actions");
const sidebar = require("../../actions/sidebar.actions");
const sidebar_actions_1 = require("../../actions/sidebar.actions");
class SwgPolicyEffects {
    constructor(actions$, store, swgPolicyService, connectorsService, translationService, stateService) {
        this.actions$ = actions$;
        this.store = store;
        this.swgPolicyService = swgPolicyService;
        this.connectorsService = connectorsService;
        this.translationService = translationService;
        this.stateService = stateService;
        // @ts-ignore
        this.createSwgPolicy$ = this.actions$.pipe(effects_1.ofType(policy_actions_1.SwgPolicyCreateTypesEnum.SWG_CREATE_POLICY), operators_1.withLatestFrom(this.store.select(reducers.getWizardFlowType), this.store.select(reducers.getSwgPolicyCopy), this.store.select(reducers.getForceDuplicate)), operators_1.map(([action, flowType, policyCopy, forceDuplicate]) => [
            // @ts-ignore
            action.payload,
            flowType,
            policyCopy,
            forceDuplicate
        ]), operators_1.switchMap(([swgPolicy, flowType, policyCopy, forceDuplicate]) => {
            if (!forceDuplicate &&
                flowType === policy_1.SwgPolicyWizardFlowType.DUPLIATE &&
                this.swgPolicyService.checkIfEqual(swgPolicy, policyCopy)) {
                return rxjs_1.of(new sidebar.SwgPolicyUnchangedDuplicateWarnOpen());
            }
            return this.swgPolicyService.createPolicy(swgPolicy, flowType).pipe(operators_1.mergeMap(() => {
                let message = '';
                if (flowType === policy_1.SwgPolicyWizardFlowType.DUPLIATE) {
                    message = '$I18N_SWG_CREATE_POLICY_POLICY_DUPLICATED';
                }
                else if (flowType === policy_1.SwgPolicyWizardFlowType.CREATE) {
                    message = '$I18N_SWG_CREATE_POLICY_POLICY_CREATED';
                }
                else if (flowType === policy_1.SwgPolicyWizardFlowType.EDIT) {
                    message = '$I18N_SWG_CREATE_POLICY_POLICY_EDITED';
                }
                this.stateService.$state.go('secure-web-gateway-policy-list');
                return [
                    new notification_actions_1.NotificationShowAction({
                        type: 'success',
                        config: {
                            msg: message
                        }
                    }),
                    new actions.SwgCreatePolicySuccessAction()
                ];
            }), operators_1.catchError(error => rxjs_1.merge(rxjs_1.of(this.getNotificationAction(error)), rxjs_1.of(new actions.SwgCreatePolicyFailAction()))));
        }));
        this.loadPolicyToEdit$ = this.actions$.pipe(effects_1.ofType(policy_actions_1.SwgPolicyCreateTypesEnum.SWG_LOAD_POLICY_TO_EDIT), operators_1.map((action) => action.payload), operators_1.switchMap(policyDesc => this.swgPolicyService.getPolicyDetails(policyDesc).pipe(operators_1.map(res => new policy_actions_2.SwgLoadPolicyToProcess(res)), operators_1.catchError(error => rxjs_1.merge(rxjs_1.of(this.getNotificationAction(error)), rxjs_1.of(new actions.SwgLoadPolicyToEditFailure()))))));
        this.showDuplicateAppliesToWarning = this.actions$.pipe(effects_1.ofType(policy_actions_1.SwgPolicyCreateTypesEnum.SWG_UPDATE_AFFECTED_USERS_STEP, policy_actions_1.SwgPolicyCreateTypesEnum.SWG_UPDATE_CLP_NOTIFICATION_LIST_STEP, policy_actions_1.SwgPolicyCreateTypesEnum.SWG_UPDATE_AFFECTED_DEVICES, policy_actions_1.SwgPolicyCreateTypesEnum.SWG_UPDATE_AFFECTED_DEVICE_GROUPS), operators_1.withLatestFrom(this.store.select(reducers.getShowDuplicateWarning)), operators_1.map(([, warning]) => warning), 
        // @ts-ignore
        operators_1.filter(warning => warning), operators_1.mergeMap(() => [
            new notification_actions_1.NotificationShowAction({
                type: 'info',
                config: {
                    msg: '$I18N_SWG_CREATE_POLICY_AFFECTED_USERS_DUPLICATE_ADDED'
                }
            }),
            new policy_actions_1.SwgClearAffectedUsersWarningStep()
        ]));
        this.showAddedAppliesToNotification = this.actions$.pipe(effects_1.ofType(policy_actions_1.SwgPolicyCreateTypesEnum.SWG_UPDATE_AFFECTED_USERS_STEP), operators_1.withLatestFrom(this.store.select(reducers.getShowAffectedUserAddedNotification)), operators_1.map(([action, show]) => {
            // @ts-ignore
            return { payload: action.payload, showNotification: show };
        }), 
        // @ts-ignore
        operators_1.filter(show => show.showNotification), operators_1.mergeMap(action => {
            return [
                new notification_actions_1.NotificationShowAction({
                    type: 'info',
                    config: {
                        msg: this.swgPolicyService.getTargetAddedNotificationMessage(
                        // @ts-ignore
                        action.payload.payload, 
                        // @ts-ignore
                        action.payload.condition)
                    }
                }),
                new policy_actions_1.SwgClearAffectedUsersWarningStep()
            ];
        }));
        this.showClpAddedAppliesToNotification = this.actions$.pipe(effects_1.ofType(policy_actions_1.SwgPolicyCreateTypesEnum.SWG_UPDATE_CLP_NOTIFICATION_LIST_STEP), operators_1.withLatestFrom(this.store.select(reducers.getShowAffectedUserAddedNotification)), operators_1.map(([action, show]) => {
            // @ts-ignore
            return { payload: action.payload, showNotification: show };
        }), operators_1.filter(show => show.showNotification), operators_1.mergeMap(action => {
            return [
                new notification_actions_1.NotificationShowAction({
                    type: 'info',
                    config: {
                        msg: this.swgPolicyService.getTargetAddedNotificationMessage(
                        // @ts-ignore
                        action.payload.payload || action.payload.teamsChannel, 
                        // @ts-ignore
                        action.payload.condition)
                    }
                }),
                new policy_actions_1.SwgClearAffectedUsersWarningStep()
            ];
        }));
        this.loadPolicyToEditSuccess$ = this.actions$.pipe(effects_1.ofType(policy_actions_1.SwgPolicyCreateTypesEnum.SWG_LOAD_POLICY_TO_PROCESS), operators_1.withLatestFrom(this.store.select(reducers.getPolicyDesc)), operators_1.switchMap(([action, policyDesc]) => {
            // @ts-ignore
            const policy = action.payload;
            if (policyDesc.policyType === policy_1.SwgPolicyType.CLOUD_PROTECT_POLICY &&
                policy.scope.type === policy_1.SwgClpPolicyScopeType.SPECIFIC_CONNECTOR) {
                return this.connectorsService.findConnectors({}).pipe(operators_1.map((connectorResponse) => {
                    const policyConnector = connectorResponse.first.connectors.filter((c) => c.id === policy.scope.id);
                    if (!policyConnector.length) {
                        return [action, policyDesc, null];
                    }
                    return [action, policyDesc, policyConnector[0]];
                }));
            }
            return rxjs_1.of([action, policyDesc, null]);
        }), operators_1.map(([action, policyDesc, connector]) => {
            // @ts-ignore
            return this.swgPolicyService.createUIModelFromAPI(action.payload, policyDesc, connector);
        }), operators_1.map((response) => {
            return new actions.SwgLoadPolicyToEditSuccess(response);
        }), operators_1.catchError(error => {
            return rxjs_1.merge(rxjs_1.of(this.getNotificationAction(error)), rxjs_1.of(new actions.SwgLoadPolicyToEditFailure()));
        }));
        this.updatePolicyName = this.actions$.pipe(effects_1.ofType(sidebar_actions_1.SwgSidebarActionsEnum.SWG_POLICY_NAVIGATE_TO_EDIT_PAGE), operators_1.filter((action) => action.payload === policy_1.SwgPolicyWizardFlowType.DUPLIATE), operators_1.map(() => {
            return new policy_actions_2.SwgUpdatePolicyDescription();
        }));
        this.disablePolicy$ = this.actions$.pipe(effects_1.ofType(policy_actions_1.SwgPolicyCreateTypesEnum.SWG_POLICY_DISABLE), operators_1.map((action) => action.payload), operators_1.switchMap((data) => {
            if (data &&
                data.policyDesc &&
                data.policyDesc.policyType === policy_1.SwgPolicyType.WEB_CATEGORY_FILTERING) {
                data = Object.assign(Object.assign({}, data), { webCategoryOtherRules: [] });
            }
            return this.swgPolicyService.createPolicy(data, policy_1.SwgPolicyWizardFlowType.EDIT).pipe(operators_1.mergeMap(() => {
                return [
                    new notification_actions_1.NotificationShowAction({
                        type: 'success',
                        config: {
                            msg: this.translationService.instant(data.policyDesc.enabled
                                ? '$I18N_SWG_POLICIES_ENABLED'
                                : '$I18N_SWG_POLICIES_DISABLED')
                        }
                    }),
                    new actions.SwgDeletePolicySuccess(),
                    new sidebar.SwgDetailDisableConfirmModelClose(),
                    new tableStore.LoadAction({ tableId: 'SwgPolicyList' })
                ];
            }), operators_1.catchError(error => rxjs_1.merge(rxjs_1.of(this.getNotificationAction(error)), rxjs_1.of(new actions.SwgDeletePolicyFailure()), rxjs_1.of(new sidebar.SwgDetailDisableConfirmModelClose()))));
        }));
        this.deletePolicy$ = this.actions$.pipe(effects_1.ofType(policy_actions_1.SwgPolicyCreateTypesEnum.SWG_POLICY_DELETE), operators_1.map((action) => action.payload), operators_1.switchMap((data) => {
            return this.swgPolicyService.deletePolicy(data).pipe(operators_1.mergeMap(() => {
                return [
                    new notification_actions_1.NotificationShowAction({
                        type: 'success',
                        config: {
                            msg: this.translationService.instant('$I18N_SWG_CREATE_POLICY_POLICY_DELETED')
                        }
                    }),
                    new actions.SwgDeletePolicySuccess(),
                    new sidebar.SwgDetailDeleteConfirmModelClose(),
                    new tableStore.LoadAction({ tableId: 'SwgPolicyList' })
                ];
            }), operators_1.catchError(error => rxjs_1.merge(rxjs_1.of(this.getNotificationAction(error)), rxjs_1.of(new actions.SwgDeletePolicyFailure()))));
        }));
        this.CLPPolicyCheckForConnector$ = this.actions$.pipe(effects_1.ofType(policy_actions_1.SwgPolicyCreateTypesEnum.CLP_POLICY_CHECK_FOR_CONNECTOR), operators_1.withLatestFrom(this.store.select(reducers.getAllConnectors)), operators_1.switchMap(([action, connectors]) => {
            const policyDesc = action.payload;
            return this.swgPolicyService.getPolicyDetails(policyDesc).pipe(operators_1.mergeMap((res) => {
                // @ts-ignore
                if (res.scope.type === policy_1.SwgClpPolicyScopeType.SPECIFIC_CONNECTOR) {
                    // @ts-ignore
                    const policyConnector = connectors.filter((c) => c.id === res.scope.id);
                    if (!policyConnector.length) {
                        this.showErrorNotification('$I18N_SWG_CLP_POLICY_NO_CONNECTOR_ERROR');
                        return [new policy_actions_2.SwgLoadPolicyToProcess(res)];
                    }
                }
                switch (action.policyAction) {
                    case policy_1.PolicyAction.EDIT: {
                        return [
                            new policy_actions_2.SwgLoadPolicyToProcess(res),
                            new sidebar_actions_1.SwgPolicyNavigateToEditPageAction(policy_1.SwgPolicyWizardFlowType.EDIT)
                        ];
                    }
                    case policy_1.PolicyAction.DUPLICATE: {
                        return [
                            new policy_actions_2.SwgLoadPolicyToProcess(res),
                            new sidebar_actions_1.SwgPolicyNavigateToEditPageAction(policy_1.SwgPolicyWizardFlowType.DUPLIATE)
                        ];
                    }
                    case policy_1.PolicyAction.DETAILS: {
                        return [new policy_actions_2.SwgLoadPolicyToProcess(res)];
                    }
                }
            }), operators_1.catchError(error => rxjs_1.merge(rxjs_1.of(this.getNotificationAction(error)), rxjs_1.of(new actions.CLPPolicyCheckForConnectorFailure()))));
        }));
        this.showDeviceAddedToNotification = this.actions$.pipe(effects_1.ofType(policy_actions_1.SwgPolicyCreateTypesEnum.SWG_UPDATE_AFFECTED_DEVICES), operators_1.withLatestFrom(this.store.select(reducers.getShowAffectedUserAddedNotification)), operators_1.map(([action, show]) => {
            // @ts-ignore
            return { payload: action.payload, showNotification: show };
        }), 
        // @ts-ignore
        operators_1.filter(show => show.showNotification), operators_1.mergeMap(action => {
            let msg = '$I18N_SWG_POLICY_DEVICES_ALREADY_ADDED';
            if (action.payload.length === 1) {
                msg = '$I18N_SWG_POLICY_DEVICE_ADDED';
            }
            if (action.payload.length > 1) {
                msg = '$I18N_SWG_POLICY_DEVICES_ADDED';
            }
            return [
                new notification_actions_1.NotificationShowAction({
                    type: 'info',
                    config: {
                        msg
                    }
                }),
                new policy_actions_1.SwgClearAffectedUsersWarningStep()
            ];
        }));
        this.showDeviceGroupAddedToNotification = this.actions$.pipe(effects_1.ofType(policy_actions_1.SwgPolicyCreateTypesEnum.SWG_UPDATE_AFFECTED_DEVICE_GROUPS), operators_1.withLatestFrom(this.store.select(reducers.getShowAffectedUserAddedNotification)), operators_1.map(([action, show]) => {
            // @ts-ignore
            return { payload: action.payload, showNotification: show };
        }), 
        // @ts-ignore
        operators_1.filter(show => show.showNotification), operators_1.mergeMap(action => {
            let msg = '$I18N_SWG_POLICY_DEVICE_GROUP_ALREADY_ADDED';
            if (action.payload.length === 1) {
                msg = '$I18N_SWG_POLICY_DEVICE_GROUP_ADDED';
            }
            if (action.payload.length > 1) {
                msg = '$I18N_SWG_POLICY_DEVICE_GROUPS_ADDED';
            }
            return [
                new notification_actions_1.NotificationShowAction({
                    type: 'info',
                    config: {
                        msg
                    }
                }),
                new policy_actions_1.SwgClearAffectedUsersWarningStep()
            ];
        }));
    }
    getNotificationAction(error) {
        const msg = error.fail
            ? error.fail[0].errors[0].message
            : this.translationService.instant('$I18N_SWG_CREATE_POLICY_SERVER_ERROR');
        return new notification_actions_1.NotificationShowAction({
            type: 'error',
            config: {
                msg
            }
        });
    }
    showErrorNotification(msg) {
        this.store.dispatch(new notification_actions_1.NotificationShowAction({
            type: 'error',
            config: {
                msg
            }
        }));
    }
}
__decorate([
    effects_1.Effect()
], SwgPolicyEffects.prototype, "createSwgPolicy$", void 0);
__decorate([
    effects_1.Effect()
], SwgPolicyEffects.prototype, "loadPolicyToEdit$", void 0);
__decorate([
    effects_1.Effect()
], SwgPolicyEffects.prototype, "showDuplicateAppliesToWarning", void 0);
__decorate([
    effects_1.Effect()
], SwgPolicyEffects.prototype, "showAddedAppliesToNotification", void 0);
__decorate([
    effects_1.Effect()
], SwgPolicyEffects.prototype, "showClpAddedAppliesToNotification", void 0);
__decorate([
    effects_1.Effect()
], SwgPolicyEffects.prototype, "loadPolicyToEditSuccess$", void 0);
__decorate([
    effects_1.Effect()
], SwgPolicyEffects.prototype, "updatePolicyName", void 0);
__decorate([
    effects_1.Effect()
], SwgPolicyEffects.prototype, "disablePolicy$", void 0);
__decorate([
    effects_1.Effect()
], SwgPolicyEffects.prototype, "deletePolicy$", void 0);
__decorate([
    effects_1.Effect()
], SwgPolicyEffects.prototype, "CLPPolicyCheckForConnector$", void 0);
__decorate([
    effects_1.Effect()
], SwgPolicyEffects.prototype, "showDeviceAddedToNotification", void 0);
__decorate([
    effects_1.Effect()
], SwgPolicyEffects.prototype, "showDeviceGroupAddedToNotification", void 0);
exports.SwgPolicyEffects = SwgPolicyEffects;
