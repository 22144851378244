"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const lexicon_component_1 = require("./containers/wizard/lexicon/lexicon.component");
const list_component_1 = require("./containers/list/list.component");
const queue_component_1 = require("./containers/wizard/queue/queue.component");
const rule_component_1 = require("./containers/wizard/rule/rule.component");
const static_1 = require("@angular/upgrade/static");
angular
    .module('archive.supervision', [])
    .controller('ArchiveSupervisionController', [
    '$stateParams',
    '$scope',
    function ($stateParams, $scope) {
        $scope.stateParams = $stateParams;
    }
])
    .directive('mcArchiveSupervisionList', static_1.downgradeComponent({
    component: list_component_1.ListComponent
}))
    .directive('mcArchiveSupervisionWizardLexicon', static_1.downgradeComponent({
    component: lexicon_component_1.LexiconComponent
}))
    .directive('mcArchiveSupervisionWizardQueue', static_1.downgradeComponent({
    component: queue_component_1.QueueComponent
}))
    .directive('mcArchiveSupervisionWizardRule', static_1.downgradeComponent({
    component: rule_component_1.RuleComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider
            .state('archive-supervision-list', {
            url: '^/archive/supervision',
            data: {
                capabilities: 'Permission.SUPERVISION_READ',
                isFixedLayout: false,
                checkProgress: false,
                tabReload: true,
                tabId: 'archive-supervision-list-tab',
                tabTitle: '$18N_ARCHIVE_SUPERVISION_LIST.TAB_TITLE'
                // breadcrumbs: [
                //   '$18N_ARCHIVE_SUPERVISION_LIST.BREADCRUMBS.1',
                //   '$18N_ARCHIVE_SUPERVISION_LIST.BREADCRUMBS.2'
                // ],
            },
            views: {
                main: {
                    template: '<mc-archive-supervision-list></mc-archive-supervision-list>',
                    controller: 'ArchiveSupervisionController'
                }
            },
            params: { callerWizardId: undefined }
        })
            .state('archive-supervision-create-wizard-lexicon', {
            data: {
                tabId: 'archive-supervision-list-tab',
                tabTitle: '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.TAB_CREATE_TITLE',
                tabReload: false,
                breadcrumbs: [
                    '$I18N_MEGA_MENU_GROUP_ARCHIVE_LABEL',
                    '$I18N_MEGA_MENU_ARCHIVE_SUPERVISION_LABEL',
                    '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.TAB_CREATE_TITLE'
                ]
            },
            views: {
                main: {
                    template: '<mc-archive-supervision-wizard-lexicon></mc-archive-supervision-wizard-lexicon>',
                    controller: 'ArchiveSupervisionController'
                }
            }
        })
            .state('archive-supervision-update-wizard-lexicon', {
            data: {
                tabId: 'archive-supervision-list-tab',
                tabTitle: '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.TAB_UPDATE_TITLE',
                tabReload: false,
                breadcrumbs: [
                    '$I18N_MEGA_MENU_GROUP_ARCHIVE_LABEL',
                    '$I18N_MEGA_MENU_ARCHIVE_SUPERVISION_LABEL',
                    '$I18N_ARCHIVE_SUPERVISION_WIZARD.LEXICON.TAB_UPDATE_TITLE'
                ]
            },
            views: {
                main: {
                    template: '<mc-archive-supervision-wizard-lexicon></mc-archive-supervision-wizard-lexicon>',
                    controller: 'ArchiveSupervisionController'
                }
            },
            params: { lexiconDetails: undefined }
        })
            .state('archive-supervision-wizard-queue', {
            data: {
                tabId: 'archive-supervision-list-tab',
                tabTitle: '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.TAB_TITLE',
                tabReload: false,
                breadcrumbs: [
                    '$I18N_MEGA_MENU_GROUP_ARCHIVE_LABEL',
                    '$I18N_MEGA_MENU_ARCHIVE_SUPERVISION_LABEL',
                    '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.TAB_CREATE_TITLE'
                ]
            },
            views: {
                main: {
                    template: '<mc-archive-supervision-wizard-queue></mc-archive-supervision-wizard-queue>',
                    controller: 'ArchiveSupervisionController'
                }
            }
        })
            .state('archive-supervision-update-wizard-queue', {
            data: {
                tabId: 'archive-supervision-list-tab',
                tabTitle: '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.TAB_UPDATE_TITLE',
                tabReload: false,
                breadcrumbs: [
                    '$I18N_MEGA_MENU_GROUP_ARCHIVE_LABEL',
                    '$I18N_MEGA_MENU_ARCHIVE_SUPERVISION_LABEL',
                    '$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.TAB_UPDATE_TITLE'
                ]
            },
            views: {
                main: {
                    template: '<mc-archive-supervision-wizard-queue></mc-archive-supervision-wizard-queue>',
                    controller: 'ArchiveSupervisionController'
                }
            },
            params: { queueDetails: undefined }
        })
            .state('archive-supervision-create-wizard-rule', {
            data: {
                tabId: 'archive-supervision-list-tab',
                tabTitle: '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.TAB_CREATE_TITLE',
                tabReload: false,
                breadcrumbs: [
                    '$I18N_MEGA_MENU_GROUP_ARCHIVE_LABEL',
                    '$I18N_MEGA_MENU_ARCHIVE_SUPERVISION_LABEL',
                    '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.TAB_CREATE_TITLE'
                ]
            },
            views: {
                main: {
                    template: '<mc-archive-supervision-wizard-rule></mc-archive-supervision-wizard-rule>',
                    controller: 'ArchiveSupervisionController'
                }
            }
        })
            .state('archive-supervision-update-wizard-rule', {
            data: {
                tabId: 'archive-supervision-list-tab',
                tabTitle: '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.TAB_UPDATE_TITLE',
                tabReload: false,
                breadcrumbs: [
                    '$I18N_MEGA_MENU_GROUP_ARCHIVE_LABEL',
                    '$I18N_MEGA_MENU_ARCHIVE_SUPERVISION_LABEL',
                    '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.TAB_UPDATE_TITLE'
                ]
            },
            views: {
                main: {
                    template: '<mc-archive-supervision-wizard-rule></mc-archive-supervision-wizard-rule>',
                    controller: 'ArchiveSupervisionController'
                }
            },
            params: { ruleDetails: undefined }
        });
    }
]);
