"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("mimecast-web-components/src/app/components/nice-scroll/jquery.nicescroll.js");
angular.module('data-leak-prevention-log-detail.controller', [])
    .controller('DataLeakPreventionLogDetailCtrl', ['$scope', function ($scope) {
        const self = this;
        const { dlpLogIndex, dlpLogsService } = $scope.$parent.$resolve;
        self.index = dlpLogIndex;
        self.dlpLogsService = dlpLogsService;
        self.dlpLog = dlpLogsService.getLogs()[self.index];
    }]);
