<mc-api-integration-okta-wizard
  [isBusy]="isBusy$ | async"
  [oktaIntegration]="oktaIntegration$ | async"
  [hasCreatedIntegration]="hasCreatedIntegration$ | async"
  [rotateApiToken]="rotateApiToken$ | async"
  (onCreateOktaIntegration)="createOktaIntegration()"
  (onSwitchRotateToken)="switchRotateToken($event)"
  (onCancel)="cancel()"
  (onUpdateOktaIntegration)="updateOktaIntegration()"
  (onEditOktaIntegrationDetails)="editOktaIntegrationDetails($event)"
  (onSwitchOktaIntegrationStatus)="switchOktaIntegrationStatus($event)"
  (onAddEmail)="addEmail($event)"
  (onDeleteEmail)="deleteEmail($event)"
  (onOktaCellSelect)="selectOktaCell($event)"
>
</mc-api-integration-okta-wizard>
