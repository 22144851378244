"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetBouncedMessageInfo = exports.ExportData = exports.GET_BOUNCED_MESSAGE_INFO = exports.EXPORT_DATA = void 0;
exports.EXPORT_DATA = '[Bounce] Export data';
exports.GET_BOUNCED_MESSAGE_INFO = '[Bounce] get';
class ExportData {
    constructor() {
        this.type = exports.EXPORT_DATA;
    }
}
exports.ExportData = ExportData;
class GetBouncedMessageInfo {
    constructor(row) {
        this.row = row;
        this.type = exports.GET_BOUNCED_MESSAGE_INFO;
    }
}
exports.GetBouncedMessageInfo = GetBouncedMessageInfo;
