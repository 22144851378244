<mc-device-groups-wizard
 [deviceGroup]="deviceGroup$ | async"
 [deviceGroupWizardWizardFlowType]="deviceGroupWizardFlowType$ | async"
 [devicesInGroup]="devicesInGroup$ | async"
 [devicesListManualPagingData]="deviceListTableManualPagingMetadata$ | async"
 [isDeviceListTableLoading]="isDeviceListTableLoading$ | async"
 [deviceCount]="deviceCount$ | async"
 [apiIsProcessing]="apiProcessingInProgress$ | async"
 [loadingForEdit]="loadingForEdit$ | async"
 (updateDeviceGroupDescr)="updateDeviceGroupDescr($event)"
 (filterDevicesInGroup)="filterDevicesInGroup($event)"
 (onPageChanged)="onPageChanged($event)"
 (onCreateDeviceGroup)="onCreateDeviceGroup()"
  (onCancelWizard)="onCancelWizard()">

</mc-device-groups-wizard>
