"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateContainsWords = exports.validateTipType = void 0;
function validateTipType(control) {
    if (control.get('safetyTips') == null && control.get('safetyTipType') == null) {
        return null;
    }
    let isValid = true;
    const customTips = control.get('safetyTips').value;
    if (control.get('safetyTipType').value !== 'mimecastSafetyTips') {
        isValid = customTips && customTips.length > 0;
    }
    return isValid ? null : { customTipsRequired: true };
}
exports.validateTipType = validateTipType;
function validateContainsWords(control) {
    const isValid = control.value && control.value.match(/\s?\w+\s?/);
    return isValid || !control.value ? null : { containsNoWords: true };
}
exports.validateContainsWords = validateContainsWords;
