"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageDetailsBase = void 0;
class MessageDetailsBase {
    getScanResultStatus(value) {
        switch (value.verdict) {
            default:
                return 'SAFE';
            case 'infected':
                return 'UNSAFE';
        }
    }
    getScanResultIcon(value) {
        switch (value.verdict) {
            default:
                return 'fas fa-check-circle text-success';
            case 'infected':
                return 'fas fa-exclamation-circle text-danger';
        }
    }
}
exports.MessageDetailsBase = MessageDetailsBase;
