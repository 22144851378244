"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReversePipe = void 0;
class ReversePipe {
    transform(arrayToBeProcess) {
        if (!arrayToBeProcess) {
            return null;
        }
        const internalArray = arrayToBeProcess.slice();
        return internalArray.reverse();
    }
}
exports.ReversePipe = ReversePipe;
