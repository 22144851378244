<div class="settings-panel" *ngIf="isLoaded$ | async; else spinner">
  <form #settings class="settings-panel-form" *ngIf="isEditable; else readOnlySettings"
        novalidate [formGroup]="form">
    <ng-container *ngIf="isIEPCapabilityEnabled">
      <div>
        <div class="mc-large-heading">{{'$I18N_REMEDIATION_SETTINGS_TAB.IEP_SETTINGS' | translate}}</div>
        <div class="mc-header-description panel-padding-bottom">{{'$I18N_REMEDIATION_SETTINGS_TAB.IEP_SETTINGS_DESC' | translate}}</div>
      </div>
      <div class="row form-group status">
        <label class="col-sm-3 status-label">
          {{'$I18N_REMEDIATION_SETTINGS_TAB.LABELS.STATUS' | translate}}
        </label>
        <div class="col-sm-9">
          <mc-switch formControlName="enable" [showLabel]="true"></mc-switch>
        </div>
      </div>
      <ng-container *ngIf="this.form.get('enable').value">
      <div class="row form-group panel-padding-top">
        <label class="col-sm-3 mode" (click)="pop.show()" role="button">
          {{'$I18N_REMEDIATION_SETTINGS_TAB.LABELS.MODE' | translate}}
          <span class="text-danger">*</span>
          <span class="far fa-question-circle mc-popover-icon" [popover]="modePopover" placement="top" container="body"
                #pop="bs-popover" [outsideClick]="true"
                containerClass="mc-field-help-popover"></span>
        </label>
        <div class="col-sm-9 test-iep-mode">
          <mc-select
            [options]="options"
            [defaultValue]="form.get('mode').value"
            (selectChange)="onSelectChange($event)">
          </mc-select>
        </div>
      </div>
      <div class="row notification-group-wrapper">
        <label class="col-sm-3 notification-group">
          {{'$I18N_REMEDIATION_SETTINGS_TAB.LABELS.NOTIFICATION_GROUP' | translate}}
          <span class="text-danger">*</span>
        </label>
        <div class="col-sm-9">
          <button class="btn btn-secondary select-notification"
                  (click)="onOpenGroupSelection('notify')">
            {{'$I18N_REMEDIATION_SETTINGS_TAB.LABELS.SELECT_BUTTON' | translate}}
          </button>
          <div class="selected-group"
               [class.selected-group-default]="!hasSelectedNotification">
               <span>
                 <div>
                   {{(settingsFormGroups$ | async).notifyGroup.description | translate }}
                 </div>
                 <i *ngIf="hasSelectedNotification"
                    class="far fa-trash-alt"
                    aria-hidden="true"
                    (click)="deleteGroup('notify')"
                 ></i>
               </span>
            <hr>
          </div>
        </div>
      </div>
      <div *ngIf="form.get('mode').value === options[2].value" class="row">
        <label class="col-sm-3"></label>
        <div class="col-sm-9 cb-wrapper">
          <mc-checkbox
            label="{{'$I18N_REMEDIATION_SETTINGS_TAB.GROUPS.NOTIFY_INTERNAL_CB' | translate}}"
            formControlName="notifyInternalRecipients"
            labelClass="checkbox-inline"></mc-checkbox>
        </div>
      </div>
      <div class="row form-group exclusion-group-wrapper"
           [class.exclusion-group-expanded]="form.get('mode').value === options[2].value">
        <label class="col-sm-3 exclusion-group">
          {{'$I18N_REMEDIATION_SETTINGS_TAB.LABELS.EXCLUSION_GROUP' | translate}}
        </label>
        <div class="col-sm-9">
          <button class="btn btn-secondary select-notification"
                  (click)="onOpenGroupSelection('exclude')">
            {{'$I18N_REMEDIATION_SETTINGS_TAB.LABELS.SELECT_BUTTON' | translate}}
          </button>
          <div class="selected-group"
               [class.selected-group-default]="!hasExcludedNotification">
               <span>
                 <div>{{(settingsFormGroups$ | async).excludeGroup.description | translate }}</div>
                 <i *ngIf="hasExcludedNotification"
                    class="far fa-trash-alt"
                    aria-hidden="true"
                    (click)="deleteGroup('exclude')"
                 ></i>
               </span>
            <hr>
          </div>
        </div>
      </div>
      </ng-container>

      <ng-container *ngIf="hasMsaEnabled && hasDeviceSettingsInDB$ | async">
        <div class="mc-large-heading panel-padding-top">
          {{'$I18N_REMEDIATION_SETTINGS_TAB.DEVICE_SETTINGS' | translate}}
        </div>
        <div class="mc-header-description panel-padding-bottom">
          {{'$I18N_REMEDIATION_SETTINGS_TAB.DEVICE_SETTINGS_DESC' | translate}}
        </div>
        <ng-container *ngIf="swgEndpointSettingsEdit">
          <div class="row">
            <label class="col-sm-3 status-label">
              {{'$I18N_REMEDIATION_SETTINGS_TAB.SAVED_ATTACHMENT_REMEDIATION' | translate}}
            </label>
            <div class="col-sm-9">
              <mc-switch formControlName="enableFileRemediation" [showLabel]="true" [isDisabled]="!form.get('enable').value"></mc-switch>
            </div>
          </div>
          <div class="row" *ngIf="this.form.get('enableFileRemediation').value">
            <label class="col-sm-3 notify-recipients">
              {{'$I18N_REMEDIATION_SETTINGS_TAB.NOTIFICATIONS' | translate}}
            </label>
            <div class="col-sm-9 cb-wrapper">
              <mc-checkbox
                label="{{ '$I18N_REMEDIATION_SETTINGS_TAB.NOTIFY_DEVICE_USERS' | translate }}"
                formControlName="enableFileRemediationNotifications"
                labelClass="checkbox-inline"></mc-checkbox>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!swgEndpointSettingsEdit && swgEndpointSettingsRead">
          <div class="row form-group panel-padding-top">
            <label class="col-sm-3 status-label">
              {{'$I18N_REMEDIATION_SETTINGS_TAB.SAVED_ATTACHMENT_REMEDIATION' | translate}}
            </label>
            <div class="col-sm-9">
              {{ ((deviceSettings$ | async).enableFileRemediation ?
              '$I18N_REMEDIATION_SETTINGS_TAB.LABELS.ENABLED' :
              '$I18N_REMEDIATION_SETTINGS_TAB.LABELS.DISABLED') | translate }}
            </div>
          </div>

          <div class="row form-group">
            <label class="col-sm-3 status-label">
              {{'$I18N_REMEDIATION_SETTINGS_TAB.NOTIFICATIONS' | translate}}
            </label>
            <div class="col-sm-9">
              {{ ((deviceSettings$ | async).enableFileRemediationNotifications ?
              '$I18N_REMEDIATION_SETTINGS_TAB.LABELS.ENABLED' :
              '$I18N_REMEDIATION_SETTINGS_TAB.LABELS.DISABLED') | translate }}
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="isMEPCapabilitySwitchEnabled">
      <div>
        <div class="mc-large-heading {{ isIEPCapabilityEnabled ? 'panel-padding-top' : '' }}">
          {{'$I18N_REMEDIATION_SETTINGS_TAB.MEP_SETTINGS' | translate}}
        </div>
        <div class="mc-header-description panel-padding-bottom">{{'$I18N_REMEDIATION_SETTINGS_TAB.MEP_SETTINGS_DESC' | translate}}</div>
      </div>
      <div class="row form-group status {{ !this.form.get('mepEnable').value ? 'panel-padding-bottom' : '' }}">
        <label class="col-sm-3 status-label">
          {{'$I18N_REMEDIATION_SETTINGS_TAB.LABELS.STATUS' | translate}}
        </label>
        <div class="col-sm-9">
          <mc-switch formControlName="mepEnable" [showLabel]="true"></mc-switch>
        </div>
      </div>
      <div class="row" *ngIf="this.form.get('mepEnable').value">
        <label class="col-sm-3 notify-recipients">
          {{'$I18N_REMEDIATION_SETTINGS_TAB.NOTIFICATIONS' | translate}}
        </label>
        <div class="col-sm-9 cb-wrapper">
          <mc-checkbox
            label="{{'$I18N_REMEDIATION_SETTINGS_TAB.GROUPS.NOTIFY_INTERNAL_CB' | translate}}"
            formControlName="mepNotifyInternalRecipients"
            labelClass="checkbox-inline"></mc-checkbox>
        </div>
      </div>
    </ng-container>
  </form>
  <div class="panel-double-padding-bottom"></div>
</div>
<div class="fixed-footer" *ngIf="isEditable">
  <hr>
  <div class="pull-right button-footer">
    <button
      type="reset"
      class="btn btn-default"
      [disabled]="!form.dirty"
      (click)="onCancel()">
      {{'$I18N_REMEDIATION_SETTINGS_TAB.LABELS.CANCEL_BUTTON' | translate}}
    </button>
    <button
      type="button"
      class="btn btn-primary"
      [disabled]="!canSave"
      (click)="openSaveModal($event)">{{'$I18N_REMEDIATION_SETTINGS_TAB.LABELS.SAVE_BUTTON' | translate}}
    </button>
  </div>
</div>

<ng-template #modePopover>
  <div>
    <p>{{ '$I18N_REMEDIATION_SETTINGS_TAB.POPOVER.INFO' | translate}}</p>
    <p><strong>{{ '$I18N_REMEDIATION_SETTINGS_TAB.SELECT.NOTIFY_ONLY' | translate }}</strong>
      - {{ '$I18N_REMEDIATION_SETTINGS_TAB.POPOVER.NOTIFY_DESCRIPTION' | translate }}</p>
    <p class="no-margin-bottom"><strong>{{ '$I18N_REMEDIATION_SETTINGS_TAB.SELECT.AUTOMATIC' | translate }}</strong>
      - {{
      '$I18N_REMEDIATION_SETTINGS_TAB.POPOVER.AUTOMATIC_DESCRIPTION' | translate}}</p>
  </div>
</ng-template>

<ng-template #spinner>
  <div class="with-spinner">
    <mc-spinner></mc-spinner>
  </div>
</ng-template>

<ng-template #readOnlySettings>
  <div class="settings-panel-form">
    <mc-inline-notification class="read-only-message" [notification]="readOnlyMessage"></mc-inline-notification>
    <div class="mc-detailed-list">
      <ng-container *ngIf="isIEPCapabilityEnabled">
        <ng-container *ngTemplateOutlet="iepReadOnlySettings"></ng-container>
        <ng-container *ngIf=" (swgEndpointSettingsEdit || swgEndpointSettingsRead) && hasDeviceSettingsInDB$ | async">
          <ng-container *ngTemplateOutlet="agentReadOnlySettings"></ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="isMEPCapabilitySwitchEnabled">
        <ng-container *ngTemplateOutlet="mepReadOnlySettings"></ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #iepReadOnlySettings>
  <div class="mc-large-heading panel-padding-bottom">{{'$I18N_REMEDIATION_SETTINGS_TAB.IEP_SETTINGS' | translate}}</div>
  <div class="row mc-detailed-list-row">
    <div
      class="col-xs-3 mc-detailed-list-label">{{'$I18N_REMEDIATION_SETTINGS_TAB.LABELS.STATUS' | translate}}</div>
    <div class="mc-detailed-list-column">
      {{ (form.get('enable').value ?
      '$I18N_REMEDIATION_SETTINGS_TAB.LABELS.ENABLED' :
      '$I18N_REMEDIATION_SETTINGS_TAB.LABELS.DISABLED') | translate }}
    </div>
  </div>
  <div class="row mc-detailed-list-row">
    <div class="col-xs-3 mc-detailed-list-label">{{'$I18N_REMEDIATION_SETTINGS_TAB.LABELS.MODE' | translate}}</div>
    <div class="mc-detailed-list-column">{{ (getModeName(form.get('mode'))).label | translate }}</div>
  </div>
  <div class="row mc-detailed-list-row">
    <div
      class="col-xs-3 mc-detailed-list-label">{{'$I18N_REMEDIATION_SETTINGS_TAB.LABELS.NOTIFICATION_GROUP' | translate}}</div>
    <div
      class="mc-detailed-list-column">{{ (settingsFormGroups$ | async).notifyGroup.description | translate }}</div>
  </div>
  <div class="row mc-detailed-list-row" *ngIf="form.get('mode').value === options[2].value">
    <div
      class="col-xs-3 mc-detailed-list-label">{{'$I18N_REMEDIATION_SETTINGS_TAB.GROUPS.NOTIFY_INTERNAL_CB' | translate}}</div>
    <div class="mc-detailed-list-column">{{ (form.get('notifyInternalRecipients').value ?
      '$I18N_REMEDIATION_SETTINGS_TAB.LABELS.ENABLED' :
      '$I18N_REMEDIATION_SETTINGS_TAB.LABELS.DISABLED') | translate }}
    </div>
  </div>
  <div class="row mc-detailed-list-row">
    <div
      class="col-xs-3 mc-detailed-list-label">{{'$I18N_REMEDIATION_SETTINGS_TAB.LABELS.EXCLUSION_GROUP' | translate}}</div>
    <div
      class="mc-detailed-list-column">{{ (settingsFormGroups$ | async).excludeGroup.description | translate }}</div>
  </div>
</ng-template>

<ng-template #agentReadOnlySettings>
  <hr>
  <div class="mc-large-heading panel-padding-top panel-padding-bottom">
    {{'$I18N_REMEDIATION_SETTINGS_TAB.DEVICE_SETTINGS' | translate}}
  </div>
  <div class="row mc-detailed-list-row">
    <div
      class="col-xs-3 mc-detailed-list-label">{{'$I18N_REMEDIATION_SETTINGS_TAB.SAVED_ATTACHMENT_REMEDIATION' | translate}}</div>
    <div class="mc-detailed-list-column">
      {{ ((deviceSettings$ | async).enableFileRemediation ?
      '$I18N_REMEDIATION_SETTINGS_TAB.LABELS.ENABLED' :
      '$I18N_REMEDIATION_SETTINGS_TAB.LABELS.DISABLED') | translate }}
    </div>
  </div>
  <div class="row mc-detailed-list-row">
    <div
      class="col-xs-3 mc-detailed-list-label">{{ '$I18N_REMEDIATION_SETTINGS_TAB.NOTIFY_DEVICE_USERS' | translate }}</div>
    <div class="mc-detailed-list-column">
      {{ ((deviceSettings$ | async).enableFileRemediationNotifications ?
      '$I18N_REMEDIATION_SETTINGS_TAB.LABELS.ENABLED' :
      '$I18N_REMEDIATION_SETTINGS_TAB.LABELS.DISABLED') | translate }}
    </div>
  </div>
</ng-template>

<ng-template #mepReadOnlySettings>
  <hr *ngIf="isIEPCapabilityEnabled">
  <div class="mc-large-heading panel-padding-bottom {{ isIEPCapabilityEnabled ? 'panel-padding-top' : '' }}">
    {{'$I18N_REMEDIATION_SETTINGS_TAB.MEP_SETTINGS' | translate}}
  </div>
  <div class="row mc-detailed-list-row">
    <div class="col-xs-3 mc-detailed-list-label">
      {{'$I18N_REMEDIATION_SETTINGS_TAB.LABELS.STATUS' | translate}}
    </div>
    <div class="mc-detailed-list-column">
      {{ (form.get('mepEnable').value ?
      '$I18N_REMEDIATION_SETTINGS_TAB.LABELS.ENABLED' :
      '$I18N_REMEDIATION_SETTINGS_TAB.LABELS.DISABLED') | translate }}
    </div>
  </div>
  <div class="row mc-detailed-list-row">
    <div class="col-xs-3 mc-detailed-list-label">
      {{'$I18N_REMEDIATION_SETTINGS_TAB.GROUPS.NOTIFY_INTERNAL_CB' | translate}}
    </div>
    <div class="mc-detailed-list-column">{{ (form.get('mepNotifyInternalRecipients').value ?
      '$I18N_REMEDIATION_SETTINGS_TAB.LABELS.ENABLED' :
      '$I18N_REMEDIATION_SETTINGS_TAB.LABELS.DISABLED') | translate }}
    </div>
  </div>
</ng-template>
