"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ2 = exports.ɵ1 = exports.ɵ0 = exports.getTotalMessagesCount = exports.getDetailsPaginationInfo = exports.isDetailLoading = exports.isLoading = exports.getSelectedRow = exports.getFiltersMetadata = exports.searchMessages = exports.reducer = exports.initialState = void 0;
const actions = require("../actions");
exports.initialState = {
    selectedRow: undefined,
    isLoading: false,
    isDetailLoading: false,
    detailsPaginationInfo: undefined,
    messages: [],
    totalMessagesCount: undefined,
    paginatorMetadata: {
        pageToken: undefined,
        pageSize: 50,
        pageNumber: 0
    }
};
const getCurrentPageMaxRecord = (state) => {
    if ((state.paginatorMetadata.pageNumber + 1) * state.paginatorMetadata.pageSize >
        state.totalMessagesCount) {
        return state.totalMessagesCount;
    }
    return (state.paginatorMetadata.pageNumber + 1) * state.paginatorMetadata.pageSize;
};
const ɵ0 = getCurrentPageMaxRecord;
exports.ɵ0 = ɵ0;
const getCurrentPageMinRecord = (state) => state.paginatorMetadata.pageNumber * state.paginatorMetadata.pageSize;
const ɵ1 = getCurrentPageMinRecord;
exports.ɵ1 = ɵ1;
const getCurrentItemIndex = (state, index) => state.paginatorMetadata.pageNumber * state.paginatorMetadata.pageSize + index;
const ɵ2 = getCurrentItemIndex;
exports.ɵ2 = ɵ2;
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.GET_MESSAGE_DETAILS:
            return Object.assign(Object.assign({}, state), { isDetailLoading: true });
        case actions.GET_MESSAGE_DETAILS_SUCCESS:
            return Object.assign(Object.assign({}, state), { selectedRow: action.payload, isDetailLoading: false });
        case actions.CLEAN_MESSAGE:
            return Object.assign(Object.assign({}, state), { selectedRow: undefined });
        case actions.SET_DETAILS_PAGINATION_INFO:
            return Object.assign(Object.assign({}, state), { detailsPaginationInfo: Object.assign(Object.assign({}, state.detailsPaginationInfo), { previousEntity: action.previous &&
                        getCurrentItemIndex(state, action.currentIndex) > getCurrentPageMinRecord(state)
                        ? action.previous
                        : undefined, currentEntity: action.current ? action.current : undefined, nextEntity: action.next && action.currentIndex < getCurrentPageMaxRecord(state) - 1
                        ? action.next
                        : undefined, currentEntityIndex: getCurrentItemIndex(state, action.currentIndex), totalNumberEntities: getCurrentPageMaxRecord(state) }) });
        case actions.CLEAN_SEARCH:
            return Object.assign(Object.assign({}, state), { isLoading: false, messages: [] });
        case actions.FILTER:
            return Object.assign(Object.assign({}, state), { isLoading: true, messages: [] });
        case actions.FILTER_SUCCESS:
            return Object.assign(Object.assign({}, state), { messages: action.payload, paginatorMetadata: Object.assign(Object.assign({}, state.paginatorMetadata), { pageNumber: 0, pageToken: undefined }), isLoading: false, isDetailLoading: false, totalMessagesCount: action.payload.length, detailsPaginationInfo: Object.assign(Object.assign({}, state.detailsPaginationInfo), { totalNumberEntities: action.payload.length }) });
        case actions.SEARCH_PAGINATION:
            return Object.assign(Object.assign({}, state), { isLoading: true, paginatorMetadata: Object.assign(Object.assign({}, state.paginatorMetadata), action.payload) });
        case actions.SEARCH_PAGINATION_SUCCESS:
            return Object.assign(Object.assign({}, state), { isLoading: false });
        default:
            return state;
    }
}
exports.reducer = reducer;
const searchMessages = (state) => {
    const firstElementIndex = state.paginatorMetadata.pageNumber * state.paginatorMetadata.pageSize;
    const lastElementIndex = firstElementIndex + state.paginatorMetadata.pageSize;
    return state.messages.slice(firstElementIndex, lastElementIndex);
};
exports.searchMessages = searchMessages;
const getFiltersMetadata = (state) => {
    const numberOfPages = Math.ceil(state.messages.length / state.paginatorMetadata.pageSize);
    const currentPageNumber = state.paginatorMetadata.pageNumber;
    const isLastPage = currentPageNumber + 1 >= numberOfPages;
    return {
        pagination: {
            previous: currentPageNumber > 0 ? 'previous' : undefined,
            next: !isLastPage ? 'next' : undefined,
            pageSize: exports.searchMessages(state).length,
            totalRows: state.messages.length
        }
    };
};
exports.getFiltersMetadata = getFiltersMetadata;
const getSelectedRow = (state) => {
    return state.selectedRow;
};
exports.getSelectedRow = getSelectedRow;
const isLoading = (state) => {
    return state.isLoading;
};
exports.isLoading = isLoading;
const isDetailLoading = (state) => {
    return state.isDetailLoading;
};
exports.isDetailLoading = isDetailLoading;
const getDetailsPaginationInfo = (state) => {
    return state.detailsPaginationInfo;
};
exports.getDetailsPaginationInfo = getDetailsPaginationInfo;
const getTotalMessagesCount = (state) => {
    return state.totalMessagesCount;
};
exports.getTotalMessagesCount = getTotalMessagesCount;
