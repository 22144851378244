"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducer = exports.initialState = void 0;
const ruleListActions = require("../actions/rule.list.actions");
exports.initialState = {
    isDeletingRule: false,
    ruleDeleted: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case ruleListActions.RuleListActions.DELETE_RULE:
            return Object.assign(Object.assign({}, state), { isDeletingRule: true, ruleDeleted: false });
        case ruleListActions.RuleListActions.DELETE_RULE_SUCCESS:
            return Object.assign(Object.assign({}, state), { isDeletingRule: false, ruleDeleted: true });
        case ruleListActions.RuleListActions.DELETE_RULE_FAIL:
            return Object.assign(Object.assign({}, state), { isDeletingRule: false, ruleDeleted: false });
        default:
            return Object.assign({}, state);
    }
}
exports.reducer = reducer;
