<div *ngIf="messageDetails">
  <div class="mc-detailed-list">
    <mc-collapsable-panel
      header="{{ '$I18N_SECURE_MESSAGING_VIEW_DETAILS.DETAILS.GENERAL_INFORMATION' | translate }}"
      [isExpanded]="true"
      >
      <mc-simple-message-detail
        data-test="recalled"
        [label]="'$I18N_SECURE_MESSAGING_VIEW_DETAILS.DETAILS.RECALLED' | translate "
        [icon]="messageDetails.ccmRecipientsStatus[0].recalled"
        [hideIcon]="!messageDetails.ccmRecipientsStatus[0].recalled"
        ></mc-simple-message-detail>
      <mc-simple-message-detail
        data-test="emailAddress"
        [label]="'$I18N_SECURE_MESSAGING_VIEW_DETAILS.DETAILS.FROM' | translate "
        [value]="messageDetails.from.emailAddress"
        ></mc-simple-message-detail>
      <mc-simple-message-detail *ngIf="extractEmails(messageDetails.to)"
        data-test="to"
        [isEmail]="true"
        [label]="'$I18N_SECURE_MESSAGING_VIEW_DETAILS.DETAILS.TO' | translate "
        [value]="extractEmails(messageDetails.to)"
        ></mc-simple-message-detail>
      <mc-simple-message-detail  *ngIf="extractEmails(messageDetails.cc)"
        data-test="cc"
        [isEmail]="true"
        [label]="'$I18N_SECURE_MESSAGING_VIEW_DETAILS.DETAILS.CC' | translate "
        [value]="extractEmails(messageDetails.cc)"
        ></mc-simple-message-detail>
      <mc-simple-message-detail
        data-test="subject"
        [label]="'$I18N_SECURE_MESSAGING_VIEW_DETAILS.DETAILS.SUBJECT' | translate "
        [value]="messageDetails.subject"
        ></mc-simple-message-detail>
      <mc-simple-message-detail
        data-test="received"
        [label]="'$I18N_SECURE_MESSAGING_VIEW_DETAILS.DETAILS.DATE_TIME' | translate "
        [value]="messageDetails.received | mcDate"
        ></mc-simple-message-detail>
      <mc-simple-message-detail
        data-test="expiryDate"
        [label]="'$I18N_SECURE_MESSAGING_VIEW_DETAILS.DETAILS.EXPIRY_DATE' | translate "
        [value]="( !messageDetails.ccmRecipientsStatus[0].recalled && messageDetails.ccmRecipientsStatus[0].expiry) ? ( messageDetails.ccmRecipientsStatus[0].expiry | mcDate) :
        ('$I18N_SECURE_MESSAGING_VIEW_DETAILS.VALUES.NONE' | translate)"
        ></mc-simple-message-detail>
    </mc-collapsable-panel>
    <hr>
    <mc-collapsable-panel
      header="{{ '$I18N_SECURE_MESSAGING_VIEW_DETAILS.DETAILS.RECIPIENT_PERMISSIONS' | translate }}"
      [isExpanded]="true">
      <mc-simple-message-detail
      data-test="print"
      [label]="'$I18N_SECURE_MESSAGING_VIEW_DETAILS.DETAILS.PRINT' | translate "
      [icon]="messageDetails.messageSettings.print"
      ></mc-simple-message-detail>
      <mc-simple-message-detail
      data-test="forward"
      [label]="'$I18N_SECURE_MESSAGING_VIEW_DETAILS.DETAILS.FORWARD' | translate "
      [icon]="messageDetails.messageSettings.forward"
      ></mc-simple-message-detail>
      <mc-simple-message-detail
      data-test="download"
      [label]="'$I18N_SECURE_MESSAGING_VIEW_DETAILS.DETAILS.DOWNLOAD' | translate "
      [icon]="messageDetails.messageSettings.download"
      ></mc-simple-message-detail>
      <mc-simple-message-detail
      data-test="reply"
      [label]="'$I18N_SECURE_MESSAGING_VIEW_DETAILS.DETAILS.REPLY' | translate "
      [icon]="messageDetails.messageSettings.reply"
      ></mc-simple-message-detail>
      <mc-simple-message-detail
      data-test="replyAll"
      [label]="'$I18N_SECURE_MESSAGING_VIEW_DETAILS.DETAILS.REPLY_ALL' | translate "
      [icon]="messageDetails.messageSettings.replyAll"
      ></mc-simple-message-detail>
      <mc-simple-message-detail
      data-test="notifyOnRead"
      [label]="'$I18N_SECURE_MESSAGING_VIEW_DETAILS.DETAILS.NOTIFY_ON_READ' | translate "
      [icon]="messageDetails.messageSettings.notifyOnRead"
      ></mc-simple-message-detail>
    </mc-collapsable-panel>
  </div>
</div>

