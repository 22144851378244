"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditPolicySuccessAction = exports.EditPolicyAction = exports.TogglePolicyEnabledAction = exports.UpdateCreatePolicyPageInEditMode = exports.OpenCreatePolicyPageInEditMode = exports.CYBERGRAPH_TOGGLE_POLICY_ENABLED = exports.CYBERGRAPH_EDIT_POLICY_SUCCESS = exports.CYBERGRAPH_EDIT_POLICY = exports.CYBERGRAPH_EDIT_POLICY_DEFINITION = exports.CYBERGRAPH_UPDATE_EDIT_POLICY_WIZARD = exports.CYBERGRAPH_OPEN_EDIT_POLICY_WIZARD = void 0;
exports.CYBERGRAPH_OPEN_EDIT_POLICY_WIZARD = '[Services / CyberGraph / Policies] Open Policy Wizard in edit mode';
exports.CYBERGRAPH_UPDATE_EDIT_POLICY_WIZARD = '[Services / CyberGraph / Policies] Update Policy Wizard in edit mode';
exports.CYBERGRAPH_EDIT_POLICY_DEFINITION = '[Services / CyberGraph / Policies] Edit Policy Definition';
exports.CYBERGRAPH_EDIT_POLICY = '[Services / CyberGraph / Policies] Edit Policy Details and Rules';
exports.CYBERGRAPH_EDIT_POLICY_SUCCESS = '[Services / CyberGraph / Policies] Edit policy - SUCCESS';
exports.CYBERGRAPH_TOGGLE_POLICY_ENABLED = '[Services / CyberGraph / Policies] Toggle policy enabled';
class OpenCreatePolicyPageInEditMode {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.CYBERGRAPH_OPEN_EDIT_POLICY_WIZARD;
    }
}
exports.OpenCreatePolicyPageInEditMode = OpenCreatePolicyPageInEditMode;
class UpdateCreatePolicyPageInEditMode {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.CYBERGRAPH_UPDATE_EDIT_POLICY_WIZARD;
    }
}
exports.UpdateCreatePolicyPageInEditMode = UpdateCreatePolicyPageInEditMode;
class TogglePolicyEnabledAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.CYBERGRAPH_TOGGLE_POLICY_ENABLED;
    }
}
exports.TogglePolicyEnabledAction = TogglePolicyEnabledAction;
class EditPolicyAction {
    constructor() {
        this.type = exports.CYBERGRAPH_EDIT_POLICY;
    }
}
exports.EditPolicyAction = EditPolicyAction;
class EditPolicySuccessAction {
    constructor() {
        this.type = exports.CYBERGRAPH_EDIT_POLICY_SUCCESS;
    }
}
exports.EditPolicySuccessAction = EditPolicySuccessAction;
