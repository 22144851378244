"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyncAndRecoverOverviewModalComponent = void 0;
const store_1 = require("@ngrx/store");
const operators_1 = require("rxjs/operators");
const sync_and_recover_overview_action_1 = require("../../actions/sync-and-recover-overview.action");
const selectors_1 = require("../../selectors");
class SyncAndRecoverOverviewModalComponent {
    constructor(store) {
        this.store = store;
    }
    ngOnInit() {
        this.store.dispatch(new sync_and_recover_overview_action_1.SyncAndRecoverOverviewLoadChartData());
        this.healthScore$ = this.store.select(selectors_1.getHealthScore);
        this.syncTaskData$ = this.store.select(selectors_1.getSyncTaskData);
        this.mailboxStatus$ = this.store.select(selectors_1.getMailboxStatus);
        this.isLoadingChartData$ = this.store.select(selectors_1.isLoadingChartData);
        this.showCharts$ = this.healthScore$.pipe(operators_1.map(data => data && (!!data.score || data.score === 0)));
        this.isChartLoadedSuccess$ = this.store
            .pipe(store_1.select(selectors_1.isChartLoadedFailed))
            .pipe(operators_1.map(status => !status));
    }
    close() {
        this.store.dispatch(new sync_and_recover_overview_action_1.SyncAndRecoverOverviewCloseModal());
    }
}
exports.SyncAndRecoverOverviewModalComponent = SyncAndRecoverOverviewModalComponent;
