"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CertificateAuthorityComponent = void 0;
const dns_constants_1 = require("../dns-constants");
const coreReducers = require("../../../core/reducers");
class CertificateAuthorityComponent {
    constructor(dnsService, coreStore) {
        this.dnsService = dnsService;
        this.coreStore = coreStore;
        this.kbLink = dns_constants_1.CERTIFICATE_AUTHORITY_KB_LINK;
    }
    ngOnInit() {
        this.coreStore.select(coreReducers.getConfigExtended).subscribe(configExtended => {
            this.hashValue = configExtended['swg.certificate.hash'].value;
        });
        this.certificateDownloadUrl$ = this.dnsService.getCertificateDownloadUrl();
    }
}
exports.CertificateAuthorityComponent = CertificateAuthorityComponent;
