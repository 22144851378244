<mc-layout-detail-wizard [keyTitle]="wizardTitle">

  <mc-wizard #wizard (changeStep)="onStepChange($event)" class="wizard-spinner">
    <mc-wizard-step
      label="{{ '$I18N_CREATE_CONNECTOR_WIZARD.PRODUCT_SELECTION_STEP_TITLE'}}">
      <mc-connector-wizard-product-selection-step [editFlow]="editFlow">
      </mc-connector-wizard-product-selection-step>
    </mc-wizard-step>

    <mc-wizard-step
      label="{{ '$I18N_CREATE_CONNECTOR_WIZARD.PROVIDER_SELECTION_STEP_TITLE'}}">
      <mc-connector-wizard-provider-selection-step [editFlow]="editFlow"
        [selectedProduct]="getSelectedProduct()" [isGwsProviderEnabled]="isGwsProviderEnabled$ | async">
      </mc-connector-wizard-provider-selection-step>
    </mc-wizard-step>

    <ng-container *ngIf="isGwsProviderSelected() | async">
      <mc-wizard-step label="{{ '$I18N_CREATE_CONNECTOR_WIZARD.GWS_INSTRUCTION_STEP_TITLE'}}">
        <mc-gws-provider-instruction-step></mc-gws-provider-instruction-step>
      </mc-wizard-step>
      <mc-wizard-step label="{{ '$I18N_CREATE_CONNECTOR_WIZARD.GWS_CUSTOMER_INFO_STEP_TITLE'}}">
        <mc-gws-provider-customer-info-step [isNextButtonClicked]="isNextButtonClicked() | async"
                                            [provider]="getProvider()">
        </mc-gws-provider-customer-info-step>
      </mc-wizard-step>
      <mc-wizard-step label="{{ '$I18N_CREATE_CONNECTOR_WIZARD.GWS_DOMAIN_DELEGATION_STEP_TITLE'}}">
        <mc-gws-provider-domain-delegation-step [isNextButtonClicked]="isNextButtonClicked() | async"
                                                (navigate)="navigate($event)">
        </mc-gws-provider-domain-delegation-step>
      </mc-wizard-step>
    </ng-container>
    <ng-container *ngIf="isMsProviderSelected() | async">
      <mc-wizard-step label="{{ '$I18N_CREATE_CONNECTOR_WIZARD.CONSENT_STEP_TITLE'}}">
          <mc-connector-wizard-consent-step [editFlow]="editFlow"
                                            [provider]="getProvider()">
          </mc-connector-wizard-consent-step>
      </mc-wizard-step>
    </ng-container>

    <mc-wizard-step
      label="{{ '$I18N_CREATE_CONNECTOR_WIZARD.DETAILS_STEP_TITLE'}}">
      <mc-connector-wizard-details-step [editFlow]="editFlow"></mc-connector-wizard-details-step>
    </mc-wizard-step>

    <mc-wizard-step
      label="{{ '$I18N_CREATE_CONNECTOR_WIZARD.SUMMARY_STEP_TITLE'}}">
      <mc-connector-wizard-summary-step
        [wizardData]="wizardData$ | async" [editFlow]="editFlow">
      </mc-connector-wizard-summary-step>
    </mc-wizard-step>
  </mc-wizard>

  <mc-footer-container>
    <div class="pull-right no-select mc-button-footer">
      <button class="btn cancel-btn" (click)="onCancel('cloud')">
        {{ '$I18N_CREATE_CONNECTOR_WIZARD.CONTROLS.CANCEL' | translate }}
      </button>

      <ng-container *ngIf="wizard">
        <button
          class="btn btn-secondary"
          *ngIf="(wizard.hasPreviousStep$ | async)"
         (click)="onPrevious()"
        >
        {{ '$I18N_CREATE_CONNECTOR_WIZARD.CONTROLS.PREVIOUS' | translate }}
        </button>

        <button
          class="btn"
          [class.btn-primary]="editFlow !== true"
          [class.btn-secondary]="editFlow === true"
          *ngIf="(wizard.hasNextStep$ | async)"
          [disabled]="(wizard.isLastStep$ | async) || !(isCurrentStepValid$ | async)"
          (click)="onNext()"
          data-test="next-btn"
        >
          {{ '$I18N_CREATE_CONNECTOR_WIZARD.CONTROLS.NEXT' | translate }}
        </button>
        <button
          *ngIf="(wizard.isLastStep$ | async) || editFlow === true"
          class="btn btn-primary"
          (click)="onSave($event)"
          data-test="save-btn"
          [disabled]="!(wizard.isLastStep$ | async) && (disableCreateButton || !(isCurrentStepValid$ | async))"
        >
          {{ (editFlow !== true ? '$I18N_CREATE_CONNECTOR_WIZARD.CONTROLS.CREATE' : '$I18N_CREATE_CONNECTOR_WIZARD.CONTROLS.UPDATE') | translate }}
        </button>
      </ng-container>
    </div>
  </mc-footer-container>

</mc-layout-detail-wizard>
