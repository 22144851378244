"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OneDriveDrivesPageComponent = void 0;
const table_store_1 = require("@mimecast-ui/table-store");
const onedrive_list_impl_service_1 = require("../../services/onedrive-list.impl.service");
class OneDriveDrivesPageComponent extends table_store_1.TableBaseComponent {
    constructor(store, capabilitiesManager, tabService) {
        super(store, onedrive_list_impl_service_1.OnedriveListImpl.ONE_DRIVE_DRIVES_LIST_STORE_ID);
        this.store = store;
        this.capabilitiesManager = capabilitiesManager;
        this.tabService = tabService;
        const tab = this.tabService.getSelectedTab();
        tab.onRefresh = () => {
            this.dispatchFilterAction();
        };
        this.capabilitiesManager
            .hasOnedriveReadPermission()
            .subscribe((hasOnedriveReadPermission) => {
            this.hasOnedriveReadPermission = hasOnedriveReadPermission;
        });
    }
    openItem(row) {
        if (this.hasOnedriveReadPermission) {
            super.openItem(row);
        }
    }
    createBackup() { }
}
exports.OneDriveDrivesPageComponent = OneDriveDrivesPageComponent;
