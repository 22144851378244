"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationsPipe = void 0;
class NotificationsPipe {
    transform(arrayToBeProcess, filter) {
        if (filter.key === '') {
            return arrayToBeProcess;
        }
        return (arrayToBeProcess = arrayToBeProcess.filter(message => message.noticeType === filter.key));
    }
}
exports.NotificationsPipe = NotificationsPipe;
