"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BiDetailDeleteConfirmModelClose = exports.BiDetailDeleteConfirmModelOpen = exports.BiPolicyListSidebarClose = exports.BiPolicyListSidebarOpen = exports.BiPolicyListSidebarActionsEnum = void 0;
var BiPolicyListSidebarActionsEnum;
(function (BiPolicyListSidebarActionsEnum) {
    BiPolicyListSidebarActionsEnum["BI_POLICY_LIST_SIDEBAR_OPEN"] = "[BI] BI Policy List sidebar open";
    BiPolicyListSidebarActionsEnum["BI_POLICY_LIST_SIDEBAR_CLOSE"] = "[BI] BI Policy List sidebar close";
    BiPolicyListSidebarActionsEnum["BI_POLICY_DELETE_CONFIRM_MODEL_OPEN"] = "[BI] BI delete Policy Confirm Model open";
    BiPolicyListSidebarActionsEnum["BI_POLICY_DELETE_CONFIRM_MODEL_CLOSE"] = "[BI] BI delete Policy Confirm Model close";
})(BiPolicyListSidebarActionsEnum = exports.BiPolicyListSidebarActionsEnum || (exports.BiPolicyListSidebarActionsEnum = {}));
class BiPolicyListSidebarOpen {
    constructor(payload) {
        this.payload = payload;
        this.type = BiPolicyListSidebarActionsEnum.BI_POLICY_LIST_SIDEBAR_OPEN;
    }
}
exports.BiPolicyListSidebarOpen = BiPolicyListSidebarOpen;
class BiPolicyListSidebarClose {
    constructor() {
        this.type = BiPolicyListSidebarActionsEnum.BI_POLICY_LIST_SIDEBAR_CLOSE;
    }
}
exports.BiPolicyListSidebarClose = BiPolicyListSidebarClose;
class BiDetailDeleteConfirmModelOpen {
    constructor(payload) {
        this.payload = payload;
        this.type = BiPolicyListSidebarActionsEnum.BI_POLICY_DELETE_CONFIRM_MODEL_OPEN;
    }
}
exports.BiDetailDeleteConfirmModelOpen = BiDetailDeleteConfirmModelOpen;
class BiDetailDeleteConfirmModelClose {
    constructor() {
        this.type = BiPolicyListSidebarActionsEnum.BI_POLICY_DELETE_CONFIRM_MODEL_CLOSE;
    }
}
exports.BiDetailDeleteConfirmModelClose = BiDetailDeleteConfirmModelClose;
