"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportApiComponent = void 0;
const core_1 = require("@angular/core");
class ExportApiComponent {
    constructor(fb, checkboxFormService) {
        this.fb = fb;
        this.checkboxFormService = checkboxFormService;
        this.options = [
            { value: 'xlsx', label: 'xlsx' },
            { value: 'csv', label: 'csv' }
        ];
        this.download = new core_1.EventEmitter();
        this.close = new core_1.EventEmitter();
    }
    ngOnInit() {
        this.form = this.fb.group({
            columnsToExport: this.fb.group(this.checkboxFormService.getFormValueAsObject(this.tableColumns)),
            fileFormat: [this.options[0].value]
        });
        this.subscription = this.form.valueChanges.subscribe(() => this.onFormChange());
        this.onFormChange();
    }
    onFormChange() {
        this.transformedFormValue = {
            columnsToExport: this.checkboxFormService.getFormValueAsArray(this.tableColumns, this.form.value.columnsToExport),
            fileFormat: this.form.value.fileFormat
        };
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
    getTranslateKey(columnName) {
        return this.checkboxFormService.getTranslateKey({ translatePrefix: `${this.translatePrefix}.TABLE` }, columnName);
    }
}
exports.ExportApiComponent = ExportApiComponent;
