"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HeldMessagesHeldQueueTabComponent = void 0;
const actions = require("../../actions");
const reducers = require("../../reducers");
const commonActions = require("../../../common/actions");
class HeldMessagesHeldQueueTabComponent {
    constructor(store) {
        this.store = store;
    }
    ngOnInit() {
        this.items$ = this.store.select(reducers.getHoldQueueItems);
        this.totalCount$ = this.store.select(reducers.getHoldQueueTotalCount);
        this.selectedRow$ = this.store.select(reducers.getHoldQueueSelectedRow);
        this.isLoading$ = this.store.select(reducers.isHoldQueueLoading);
        this.metadata$ = this.store.select(reducers.getHoldQueueMetadata);
        this.store.dispatch(new actions.HeldQueueRequestAction({ search: {} }));
    }
    search(search) {
        this.store.dispatch(new actions.HeldQueueRequestAction({ search: this.toSearchRequest(search) }));
    }
    toSearchRequest(search) {
        const request = Object.assign(Object.assign(Object.assign({}, (!!search.start ? { start: new Date(search.start) } : {})), (!!search.end ? { end: new Date(search.end) } : {})), { searchBy: [] });
        if (!!search.policyInfo) {
            request.searchBy.push({ fieldName: 'policyInfo', value: search.policyInfo });
        }
        if (!!search.recipient) {
            request.searchBy.push({ fieldName: 'to', value: search.recipient });
        }
        if (!!search.sender) {
            request.searchBy.push({ fieldName: 'from', value: search.sender });
        }
        if (!!search.subject) {
            request.searchBy.push({ fieldName: 'subject', value: search.subject });
        }
        return request;
    }
    filterChanged(filter) {
        this.store.dispatch(new actions.HeldQueueRequestAction({ search: filter }));
    }
    paginatorChanged(page) {
        this.store.dispatch(new actions.HeldQueuePaginationAction(page));
    }
    rowClicked(message) {
        const rowMessageType = Object.assign(Object.assign({}, message), { messageType: 'rejectedMessage' });
        this.store.dispatch(new actions.HeldQueueSelectRowAction(rowMessageType));
    }
    messagesReleased(messages) {
        this.store.dispatch(new actions.HeldQueueReleaseEmailsAction(messages));
    }
    messagesReleasedToSandbox(messages) {
        this.store.dispatch(new actions.HeldQueueReleaseEmailsToSandboxAction(messages));
    }
    permittedForRecipient(messages) {
        this.store.dispatch(new actions.HeldQueuePermitSendersAction(messages));
    }
    messagesRejected(messages) {
        this.store.dispatch(new actions.HeldQueueRejectEmailsAction(messages));
    }
    messagesRejectedWithNotification(messages) {
        this.store.dispatch(new actions.HeldQueueRejectEmailsWithNotificationAction(messages));
    }
    messagesRejectedWithNarrative(messages) {
        this.store.dispatch(new actions.HeldQueueRejectNarrativeEmailsOpenModalAction({ messages }));
    }
    blockedForRecipient(messages) {
        this.store.dispatch(new actions.HeldQueueBlockSendersAction(messages));
    }
    messagesReportedAsSpam(messages) {
        this.store.dispatch(new actions.HeldQueueReportEmailsOpenModalAction({ messages, reportAs: 'spam' }));
    }
    messagesReportedAsMalware(messages) {
        this.store.dispatch(new actions.HeldQueueReportEmailsOpenModalAction({ messages, reportAs: 'malware' }));
    }
    messagesReportedAsPhishing(messages) {
        this.store.dispatch(new actions.HeldQueueReportEmailsOpenModalAction({ messages, reportAs: 'phishing' }));
    }
    onReportMessage({ reportType, id }) {
        this.store.dispatch(new commonActions.ReportMessageOpenModalAction(reportType, id));
    }
    resultsExported(tableColumns) {
        this.store.dispatch(new actions.HeldQueueExportAction(tableColumns));
    }
}
exports.HeldMessagesHeldQueueTabComponent = HeldMessagesHeldQueueTabComponent;
