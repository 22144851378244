"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueueStatusPipe = void 0;
class QueueStatusPipe {
    constructor(translateService) {
        this.translateService = translateService;
    }
    transform(value) {
        if (value) {
            return this.translateService.instant('$I18N_ARCHIVE_SUPERVISION_PIPES.QUEUE_STATUS.' + value.toUpperCase());
        }
        return this.translateService.instant('$I18N_ARCHIVE_SUPERVISION_PIPES.QUEUE_STATUS.UNKNOWN');
    }
}
exports.QueueStatusPipe = QueueStatusPipe;
