"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetAcceptedEmailEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const commonActions = require("../../../common/actions");
const actions = require("../../actions");
const reducers = require("../../reducers");
class GetAcceptedEmailEffects {
    constructor(actions$, getAcceptedEmailService, store$) {
        this.actions$ = actions$;
        this.getAcceptedEmailService = getAcceptedEmailService;
        this.store$ = store$;
        this.loadMessages$ = this.actions$.pipe(effects_1.ofType(actions.GET_ACCEPTED_EMAIL_REQUEST, actions.GET_ACCEPTED_EMAIL_PAGINATION, actions.GET_ACCEPTED_EMAIL_REFRESH), operators_1.withLatestFrom(this.store$.select(reducers.getAcceptedEmailRequestPayload), this.store$.select(reducers.getRequestPage)), operators_1.switchMap(([, request, pagination]) => {
            return this.getAcceptedEmailService.getMessages(request, pagination).pipe(operators_1.map(response => new actions.GetAcceptedEmailSuccessAction(response)), operators_1.catchError(() => rxjs_1.of(new actions.GetAcceptedEmailFailAction())));
        }));
        this.selectedRow$ = this.actions$.pipe(effects_1.ofType(actions.GET_ACCEPTED_EMAIL_SELECT_ROW), operators_1.withLatestFrom(this.store$.select(reducers.getAcceptedEmailList)), operators_1.switchMap(([action, list]) => {
            if (action.payload) {
                return rxjs_1.of(new commonActions.ShowMessageInfoAction(action.payload, list, false));
            }
            return rxjs_1.EMPTY;
        }));
    }
}
__decorate([
    effects_1.Effect()
], GetAcceptedEmailEffects.prototype, "loadMessages$", void 0);
__decorate([
    effects_1.Effect()
], GetAcceptedEmailEffects.prototype, "selectedRow$", void 0);
exports.GetAcceptedEmailEffects = GetAcceptedEmailEffects;
