"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgDevicesListComponent = void 0;
const core_1 = require("@angular/core");
const table_store_1 = require("@mimecast-ui/table-store");
const devices_list_static_values_1 = require("./devices-list.static-values");
const device_groups_models_1 = require("../../../models/device-groups.models");
class SwgDevicesListComponent extends table_store_1.TableBaseComponent {
    constructor(store, swgDevicesListApiService) {
        super(store, 'SwgDevicesListTable');
        this.store = store;
        this.swgDevicesListApiService = swgDevicesListApiService;
        this.isSidePanelView = true;
        this.displayType = device_groups_models_1.DeviceListDisplayType.READ;
        this.devicesInGroup = [];
        this.filterDevicesInGroup = new core_1.EventEmitter();
        this.onPageChanged = new core_1.EventEmitter();
        this.addActionColumnsAlwaysVisible = devices_list_static_values_1.addActionColumnsAlwaysVisible;
        this.addActionColumns = devices_list_static_values_1.addActionColumns;
        this.columnsToStartHidden = devices_list_static_values_1.columnsToStartHidden;
        this.columnsToIgnore = devices_list_static_values_1.columnsToIgnore;
        this.tableFilterValues = devices_list_static_values_1.tableFilters;
        this.deviceListDisplayType = device_groups_models_1.DeviceListDisplayType;
        this.isSelectedAll = false;
        this.isTableInManualMode = false;
        this.tableFilters = { filterBy: [] };
        this.tableSearch = { searchBy: [] };
        this.defaultSizeOfPage = 50;
    }
    ngOnInit() {
        if (this.deviceGroup && this.deviceGroup.id) {
            this.loadPayload = {
                groupId: this.deviceGroup.id
            };
            this.runFilterSearch();
        }
        else {
            super.ngOnInit();
        }
        this.setupSubscription();
        this.setFilters();
        this.setColumns();
    }
    setFilters() {
        if (this.displayType === device_groups_models_1.DeviceListDisplayType.CREATE) {
            this.tableFilterValues = this.tableFilterValues.map(x => {
                if (x.name === 'action') {
                    return Object.assign(Object.assign({}, x), { filters: x.filters.filter(f => f.name === 'added') });
                }
                return x;
            });
        }
        if (this.displayType === device_groups_models_1.DeviceListDisplayType.TARGET) {
            this.tableFilterValues = this.tableFilterValues.filter(x => x.name !== 'action');
        }
    }
    setColumns() {
        if (this.displayType === device_groups_models_1.DeviceListDisplayType.TARGET) {
            this.addActionColumns = this.addActionColumns.filter(c => c !== 'actions');
            this.addActionColumnsAlwaysVisible = this.addActionColumnsAlwaysVisible.filter(c => c !== 'actions');
        }
    }
    setupSubscription() {
        this.tableDataSubscription = this.tableData$.subscribe((data) => {
            if (data) {
                this.tableDataCopy = data.map(x => {
                    const matchedDevice = this.devicesInGroup.find(device => device.id === x.id);
                    if (matchedDevice) {
                        return Object.assign(Object.assign({}, x), { action: matchedDevice.action });
                    }
                    return x;
                });
            }
        });
    }
    ngOnChanges() {
        if (this.isTableInManualMode && this.devicesInGroup) {
            this.tableDataCopy = this.devicesInGroup.map(x => x);
        }
    }
    getColumns() {
        return this.addActionColumns;
    }
    getAlwaysVisibleColumns() {
        return this.addActionColumnsAlwaysVisible;
    }
    onSearch(filters) {
        this.tableSearch.searchBy = this.tableSearch.searchBy.filter(f => f.fieldName !== 'deviceName');
        this.tableSearch.searchBy = [
            ...this.tableSearch.searchBy,
            { fieldName: 'deviceName', value: filters.search }
        ];
        if (this.isTableInManualMode) {
            this.setAndRunManualFilterSearch();
        }
        else {
            this.runFilterSearch();
        }
    }
    onSearchClear() {
        this.tableSearch.searchBy = this.tableSearch.searchBy.filter(f => f.fieldName !== 'deviceName');
        if (this.isTableInManualMode) {
            this.manualTableFilters = this.manualTableFilters.filter(x => x.fieldName !== 'deviceName');
            this.filterDevicesInGroup.emit(this.manualTableFilters);
        }
        else {
            this.runFilterSearch();
        }
    }
    runFilterSearch() {
        const search = this.tableSearch.searchBy.length ? this.tableSearch : undefined;
        const filters = this.tableFilters.filterBy.length ? this.tableFilters : undefined;
        this.onFilterChange(Object.assign(Object.assign(Object.assign({}, this.loadPayload), search), filters));
    }
    onPaginationChange(pagination) {
        if (this.isTableInManualMode) {
            this.onPageChanged.emit(pagination);
            if (this.currentPagination.pageSize !== pagination.pageSize) {
                this.currentPagination = Object.assign(Object.assign({}, this.currentPagination), { pageSize: pagination.pageSize, pageSizeChanged: true });
                this.defaultSizeOfPage = pagination.pageSize;
            }
        }
        else {
            super.onPaginationChange(pagination);
            this.onPageChanged.emit(pagination);
        }
    }
    onSelectAll(event) {
        this.isSelectedAll = event.target.checked;
        this.toggleAll(event.target.checked);
    }
    toggleAll(selected) {
        if (this.tableDataCopy && this.tableDataCopy.length) {
            this.tableDataCopy = this.tableDataCopy.map(x => {
                return Object.assign(Object.assign({}, x), { selected });
            });
        }
    }
    onSelectingSingleItem(data) {
        data.event.stopPropagation();
        this.tableDataCopy = this.tableDataCopy.map(x => {
            if (x.id === data.device.id) {
                return Object.assign(Object.assign({}, x), { selected: data.event.target.checked });
            }
            return x;
        });
    }
    addDevices() {
        if (this.tableDataCopy && this.tableDataCopy.length) {
            const devices = [];
            this.tableDataCopy = this.tableDataCopy.map(x => {
                if (x.selected) {
                    if (x.action !== device_groups_models_1.DeviceAction.INGROUP && x.action !== device_groups_models_1.DeviceAction.ADDED) {
                        devices.push(Object.assign(Object.assign({}, x), { action: device_groups_models_1.DeviceAction.ADDED, selected: false }));
                    }
                    return Object.assign(Object.assign({}, x), { action: x.action !== device_groups_models_1.DeviceAction.INGROUP ? device_groups_models_1.DeviceAction.ADDED : x.action, selected: false });
                }
                return x;
            });
            this.isSelectedAll = false;
            this.swgDevicesListApiService.addDevicesInGroupSubject.next(devices);
        }
    }
    clearAddedDevices() {
        if (this.tableDataCopy && this.tableDataCopy.length) {
            const devices = [];
            this.tableDataCopy = this.tableDataCopy.map(x => {
                if (x.selected) {
                    if (this.isDeviceActionChanged(x)) {
                        devices.push(Object.assign(Object.assign({}, x), { action: device_groups_models_1.DeviceAction.NONE, selected: false }));
                    }
                    return Object.assign(Object.assign({}, x), { action: x.action === device_groups_models_1.DeviceAction.INGROUP || x.action === device_groups_models_1.DeviceAction.ADDED
                            ? device_groups_models_1.DeviceAction.NONE
                            : x.action, selected: false });
                }
                return x;
            });
            this.swgDevicesListApiService.removeDevicesFromGroupSubject.next(devices);
            if (this.isSelectedAll && this.isTableInManualMode) {
                this.setAndRunManualFilterSearch();
            }
            this.isSelectedAll = false;
        }
    }
    isDeviceActionChanged(device) {
        return (device.action !== device_groups_models_1.DeviceAction.NONE &&
            (device.action === device_groups_models_1.DeviceAction.INGROUP || device.action === device_groups_models_1.DeviceAction.ADDED));
    }
    onBundleFilterChange(filters) {
        this.tableFilters = { filterBy: [] };
        this.tableSearch.searchBy = this.tableSearch.searchBy.filter(f => f.fieldName !== 'os');
        let isPendingActionFilterApplied = false;
        if (filters.status) {
            this.tableFilters.filterBy = filters.status.map((val) => ({
                fieldName: 'status',
                value: val
            }));
        }
        if (filters.os) {
            filters.os.forEach((os) => {
                this.tableSearch.searchBy = [...this.tableSearch.searchBy, { fieldName: 'os', value: os }];
            });
        }
        if (filters.action) {
            filters.action.forEach((action) => {
                if (action === device_groups_models_1.DeviceAction.ADDED.toLowerCase()) {
                    isPendingActionFilterApplied = true;
                    this.tableFilters.filterBy.push({ fieldName: 'action', value: action });
                }
                this.tableFilters.filterBy.push({
                    fieldName: 'ingroup',
                    value: action === 'ingroup'
                });
            });
        }
        if (isPendingActionFilterApplied) {
            this.setAndRunManualFilterSearch();
        }
        else if (this.isTableInManualMode) {
            this.clearManualFiltersAndRunFilterSearch();
        }
        else {
            this.runFilterSearch();
        }
    }
    setAndRunManualFilterSearch() {
        this.isTableInManualMode = true;
        this.manualTableFilters = this.tableFilters.filterBy.filter(x => x.fieldName !== 'ingroup');
        if (this.tableSearch && this.tableSearch.searchBy) {
            this.tableSearch.searchBy.forEach(search => {
                this.manualTableFilters.push(search);
            });
        }
        this.filterDevicesInGroup.emit(this.manualTableFilters);
    }
    clearManualFiltersAndRunFilterSearch() {
        this.isTableInManualMode = false;
        this.manualTableFilters = [];
        this.runFilterSearch();
        this.filterDevicesInGroup.emit(this.manualTableFilters);
    }
    ngOnDestroy() {
        if (this.tableDataSubscription) {
            this.tableDataSubscription.unsubscribe();
        }
        super.ngOnDestroy();
    }
}
exports.SwgDevicesListComponent = SwgDevicesListComponent;
