"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./mc-dropdown-directive"); //mc-dropdown.directive
// html and css 
require("./mc-dropdown-header-footer.tpl.html");
require("./mc-dropdown-row.tpl.html");
require("./mc-dropdown.less");
require("./mc-dropdown.tpl.html");
require("./pre-defined-rows/mc-dropdown-multiselect-row/mc-dropdown-multiselect-row.tpl.html");
require("./pre-defined-rows/mc-dropdown-simple-label-row/mc-dropdown-simple-label-row.tpl.html");
/**
 * Created by iaggarwal on 03/03/2017.
 */
angular.module('mc-dropdown', ['mc-dropdown.directive']);
