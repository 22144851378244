"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IdentityAppDetailsAsideComponent = void 0;
const rxjs_1 = require("rxjs");
const reducers_1 = require("../../reducers");
const operators_1 = require("rxjs/operators");
const app_aside_1 = require("../../models/apps/app-aside");
const appDetailsActions = require("../../actions/apps/app-details.actions");
class IdentityAppDetailsAsideComponent {
    constructor(store, overlayRef) {
        this.store = store;
        this.overlayRef = overlayRef;
        this.isEditMode$ = new rxjs_1.BehaviorSubject(false);
        this.isDetailsFormValid$ = new rxjs_1.BehaviorSubject(false);
    }
    ngOnInit() {
        this.isLoading$ = this.store.select(reducers_1.getAppDetailsIsLoading);
        this.isUpdateAppApiProcessing$ = this.store.select(reducers_1.getUpdateAppApiProcessing);
        this.isSaveBtnDisabled$ = this.store.select(reducers_1.getIsUpdateDisabled);
        this.selectedApp$ = this.store.select(reducers_1.getSelectedAppFromTable).pipe(operators_1.shareReplay(1));
        this.panelMode$ = this.store.select(reducers_1.getAppDetailsAsidePanelMode);
        this.panelModeSubscription = this.panelMode$.pipe(operators_1.filter(mode => !!mode)).subscribe(mode => {
            if (mode === app_aside_1.AppDetailsAsideMode.VIEW) {
                this.isEditMode$.next(false);
            }
            else if (mode === app_aside_1.AppDetailsAsideMode.EDIT) {
                this.isEditMode$.next(true);
            }
        });
        this.setUpdatedApp();
    }
    ngOnDestroy() {
        this.panelModeSubscription.unsubscribe();
    }
    close() {
        this.overlayRef.close();
    }
    onEdit() {
        this.store.dispatch(new appDetailsActions.IdentitySwitchAppAsideToEditAction());
    }
    updateApp() {
        this.store.dispatch(new appDetailsActions.IdentityUpdateAppDetailsAction(this.updatedApp));
    }
    onUpdate(app) {
        this.updatedApp = app;
    }
    onValid(isDetailsFormValid) {
        this.isDetailsFormValid$.next(isDetailsFormValid);
    }
    cancel() {
        this.store.dispatch(new appDetailsActions.IdentityAppCancelModelOpenAction());
    }
    setUpdatedApp() {
        this.selectedApp$.pipe(operators_1.first(app => !!app)).subscribe(app => {
            this.updatedApp = app;
        });
    }
}
exports.IdentityAppDetailsAsideComponent = IdentityAppDetailsAsideComponent;
