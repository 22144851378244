<mc-layout-detail-tabs
  keyTitle="{{ '$18N_SYNC_RECOVER_ONEDRIVE_DRIVES_DETAIL.HEADER.TITLE' | translate:{ driveUserName: getName(), driveUserMail: getMail() } }}"
  keyDescription="$18N_SYNC_RECOVER_ONEDRIVE_DRIVES_DETAIL.HEADER.DESCRIPTION"
  extendClass="mc-sync-recover-onedrive-drives-detail" kbUrl="https://community.mimecast.com/docs/DOC-2704">

  <mc-extra-container>

    <div class="mc-btn-area">

      <button class="mc-btn-item btn btn-primary"
        [disabled]="!hasExportAndRestorePermission()">{{ '$18N_SYNC_RECOVER_ONEDRIVE_DRIVES_DETAIL.HEADER.BUTTONS.EXPORT' | translate }}</button>
      <button class="mc-btn-item btn btn-primary"
        [disabled]="!hasExportAndRestorePermission()">{{ '$18N_SYNC_RECOVER_ONEDRIVE_DRIVES_DETAIL.HEADER.BUTTONS.RESTORE' | translate }}</button>
      <button class="mc-btn-item btn btn-white"
        [disabled]="!hasExportAndRestorePermission()">{{ '$18N_SYNC_RECOVER_ONEDRIVE_DRIVES_DETAIL.HEADER.BUTTONS.CREATE_SNAPSHOT' | translate }}</button>

    </div>
  </mc-extra-container>

  <mc-tab-group [initialActiveTabIndex]="getDriveShowTab()" (tabChange)="initializeSelectedTab($event)">

    <mc-tab name="$18N_SYNC_RECOVER_ONEDRIVE_DRIVES_DETAIL.TABS.LASTSYNC.LABEL">

      <mc-sync-recover-onedrive-tree *ngIf="getDriveShowContent()" [contents]="driveContents$ | async"
        [columns]="contentColumns" [loadingContents]="driveContentsLoading$ | async" [nodeStack]="nodeStack"
        [rootFolder]="driveRootFolder$ | async" [viewType]="viewType" (onNodeClicked)="nodeClicked($event)">
      </mc-sync-recover-onedrive-tree>

      <mc-empty-results *ngIf="!getDriveShowContent()" iconClass="far fa-hdd" keyTitle="{{ getDriveEmptyResultMessage().title }}">
        <mc-empty-body>{{ getDriveEmptyResultMessage().description | translate }}</mc-empty-body>
      </mc-empty-results>

    </mc-tab>

    <mc-tab name="$18N_SYNC_RECOVER_ONEDRIVE_DRIVES_DETAIL.TABS.ARCHIVE.LABEL">

      <mc-sync-recover-onedrive-tree *ngIf="getDriveShowContent()" [contents]="driveContents$ | async"
        [columns]="contentColumns" [loadingContents]="driveContentsLoading$ | async" [nodeStack]="nodeStack"
        [rootFolder]="driveRootFolder$ | async" [viewType]="viewType" (onNodeClicked)="nodeClicked($event)">
      </mc-sync-recover-onedrive-tree>

      <mc-empty-results *ngIf="!getDriveShowContent()" iconClass="far fa-hdd" keyTitle="{{ getDriveEmptyResultMessage().title }}">
        <mc-empty-body>{{ getDriveEmptyResultMessage().description | translate }}</mc-empty-body>
      </mc-empty-results>

    </mc-tab>

    <mc-tab name="$18N_SYNC_RECOVER_ONEDRIVE_DRIVES_DETAIL.TABS.DELETED_FILES.LABEL">

      <mc-sync-recover-onedrive-tree *ngIf="getDriveShowContent()" [contents]="driveContents$ | async"
        [columns]="deletedColumns" [loadingContents]="driveContentsLoading$ | async" [nodeStack]="nodeStack"
        [rootFolder]="driveRootFolder$ | async" [viewType]="viewType" (onNodeClicked)="nodeClicked($event)">
      </mc-sync-recover-onedrive-tree>

      <mc-empty-results *ngIf="!getDriveShowContent()" iconClass="far fa-hdd" keyTitle="{{ getDriveEmptyResultMessage().title }}">
        <mc-empty-body>{{ getDriveEmptyResultMessage().description | translate }}</mc-empty-body>
      </mc-empty-results>

    </mc-tab>

    <mc-tab name="$18N_SYNC_RECOVER_ONEDRIVE_DRIVES_DETAIL.TABS.SUMMARY.LABEL">

      <div class="panel-body">

        <div class="mc-detailed-list">

          <div class="row mc-detailed-list-row">
            <div class="col-xs-2 mc-detailed-list-label">{{ '$18N_SYNC_RECOVER_ONEDRIVE_DRIVES_DETAIL.TABS.SUMMARY.CONTENT.LAST_RESULT' | translate }}</div>
            <div class="mc-detailed-list-column">
              <strong [ngClass]="getLastStatus().displayClasses">{{ getLastStatus().displayValue | translate }}</strong>
              <span *ngIf="getLastStatus().statusMessage"> - {{ getLastStatus().statusMessage | translate }}</span>
            </div>
          </div>
          <div class="row mc-detailed-list-row">
            <div class="col-xs-2 mc-detailed-list-label">{{ '$18N_SYNC_RECOVER_ONEDRIVE_DRIVES_DETAIL.TABS.SUMMARY.CONTENT.TASK_NAME' | translate }}</div>
            <div class="mc-detailed-list-column"> {{ getTaskName() }} </div>
          </div>
          <div class="row mc-detailed-list-row">
            <div class="col-xs-2 mc-detailed-list-label">{{ '$18N_SYNC_RECOVER_ONEDRIVE_DRIVES_DETAIL.TABS.SUMMARY.CONTENT.NAME' | translate }}</div>
            <div class="mc-detailed-list-column"> {{ getName() }} </div>
          </div>
          <div class="row mc-detailed-list-row">
            <div class="col-xs-2 mc-detailed-list-label">{{ '$18N_SYNC_RECOVER_ONEDRIVE_DRIVES_DETAIL.TABS.SUMMARY.CONTENT.DRIVE' | translate }}</div>
            <div class="mc-detailed-list-column"> {{ getDrive() }} </div>
          </div>
          <div class="row mc-detailed-list-row">
            <div class="col-xs-2 mc-detailed-list-label">{{ '$18N_SYNC_RECOVER_ONEDRIVE_DRIVES_DETAIL.TABS.SUMMARY.CONTENT.FIRST_SYNC' | translate }}</div>
            <div class="mc-detailed-list-column"> {{ getFirstSync() | mcDate }} </div>
          </div>
          <div class="row mc-detailed-list-row">
            <div class="col-xs-2 mc-detailed-list-label">{{ '$18N_SYNC_RECOVER_ONEDRIVE_DRIVES_DETAIL.TABS.SUMMARY.CONTENT.LAST_SYNC' | translate }}</div>
            <div class="mc-detailed-list-column"> {{ getLastSync() | mcDate }} </div>
          </div>
          <div class="row mc-detailed-list-row">
            <div class="col-xs-2 mc-detailed-list-label">{{ '$18N_SYNC_RECOVER_ONEDRIVE_DRIVES_DETAIL.TABS.SUMMARY.CONTENT.QUOTA_USED' | translate }}</div>
            <div class="mc-detailed-list-column"> {{ getQuotaUsed() | mcToBytes: sizeConfig }} </div>
          </div>

        </div>

      </div>

    </mc-tab>

  </mc-tab-group>

  <mc-layout-detail-tabs>
