"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageDetailsComponent = void 0;
const messageDetailsAction = require("../../actions/message-details/message-details.actions");
const reducers = require("../../reducers");
const message_details_base_1 = require("./message-details.base");
class MessageDetailsComponent extends message_details_base_1.MessageDetailsBase {
    constructor(fb, store, overlayRef, remediationApiService, exportService, translate) {
        super();
        this.fb = fb;
        this.store = store;
        this.overlayRef = overlayRef;
        this.remediationApiService = remediationApiService;
        this.exportService = exportService;
        this.translate = translate;
        this.summary = {};
        this.recipientsCC = '';
        this.recipientsTo = '';
        this.recipientsBcc = '';
        this.showMoreTo = '';
        this.showMoreCC = '';
        this.showMoreBcc = '';
        this.attachmentsColumns = ['filename', 'scan_result', 'size', 'action'];
        this.form = this.fb.group({});
    }
    ngOnInit() {
        this.statusNotUpdatedNotification = {
            msg: `<strong>${this.translate.instant('$I18N_REMEDIATION.NO_STATUS')}</strong
> ${this.translate.instant('$I18N_REMEDIATION.CHECK_LATTER')}`,
            status: 'info',
            boxed: true
        };
        this.inlineNotificationConfigError = {
            status: 'error',
            msg: this.translate.instant('$I18N_REMEDIATION.ERRORS.GENERIC')
        };
        this.pagination$ = this.store.select(reducers.getAsidePagination);
        this.messageDetails$ = this.store.select(reducers.getMessageDetails);
        this.displayableThreatDetails$ = this.store.select(reducers.getDisplayableThreatDetails);
        this.hasGenericError$ = this.store.select(reducers.hasGenericErrorOnMessageLoad);
        this.isLoadingMessageDetails$ = this.store.select(reducers.isLoadingMessageDetails);
        this.messageDetailsSubscription = this.messageDetails$.subscribe(message => {
            if (!message) {
                return;
            }
            this.recipientsCC = '';
            this.recipientsTo = '';
            this.showMoreTo = '';
            this.showMoreCC = '';
            this.recipientsBcc = '';
            this.summary = message;
            this.setRecipients(message);
            this.contentUrls = message.contentUrls || [];
        });
    }
    ngOnDestroy() {
        this.messageDetailsSubscription.unsubscribe();
    }
    closeAside() {
        this.overlayRef.close();
    }
    showMessage(message) {
        this.store.dispatch(new messageDetailsAction.GetMessageDetailsAction(message.token));
    }
    downloadAttachment(file) {
        this.remediationApiService.getFile(file.id).subscribe(fileLink => {
            this.exportService.injectIframeToDownloadFile(fileLink);
        });
    }
    setRecipients(message) {
        if (message.to && message.to instanceof Array) {
            [this.recipientsTo, this.showMoreTo] = this.splitEmails(message.to);
        }
        if (message.cc && message.cc instanceof Array) {
            [this.recipientsCC, this.showMoreCC] = this.splitEmails(message.cc);
        }
        if (message.bcc && message.bcc instanceof Array) {
            [this.recipientsBcc, this.showMoreBcc] = this.splitEmails(message.bcc);
        }
    }
    splitEmails(emails) {
        let visibleEmails = '';
        let showMoreEmails = '';
        for (const email of emails) {
            if (visibleEmails.length + email.length < 65) {
                visibleEmails += `${email}, `;
            }
            else {
                showMoreEmails += `${email}, `;
            }
        }
        showMoreEmails = showMoreEmails.slice(0, -2);
        if (showMoreEmails.length === 0) {
            visibleEmails = visibleEmails.slice(0, -2);
        }
        return [visibleEmails, showMoreEmails];
    }
}
exports.MessageDetailsComponent = MessageDetailsComponent;
