<mc-layout-aside-extra-container
  keyTitle="{{ '$I18N_API_CORTEX_INTEGRATION_ASIDE_VIEW.TITLE' | translate }}"
  [isLoading]="false"
  showClose="true"
  (closeAside)="onCloseClicked.emit()"
>
  <mc-extra-container *mcCapabilities="'Permission.API_APPLICATIONS_EDIT'">
    <mc-edit-integration-view
      [integration]="integration"
      [enabled]="enabled"
      (onEditClicked)="onEditClicked.emit()"
      (onEnableClicked)="onEnableClicked.emit()"
      (onDisableClicked)="onDisableClicked.emit()"
    >
    </mc-edit-integration-view>
  </mc-extra-container>

  <mc-body-container>
    <section class="mc-detail-section">
      <div class="panel-body no-padding-top">
        <div class="mc-detailed-list">
          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_CORTEX_INTEGRATION_ASIDE_VIEW.FIELDS.INTEGRATION' | translate }}
            </div>
            <div class="mc-detailed-list-column">
              {{ integration.integrationType | mcIntegrationProvider }}
            </div>
          </div>

          <mc-cortex-aside-settings
            *ngIf="integration.integrationType === 'cortex'"
            [integrationDescription]="integration.description"
            [integrationDescriptionKey]="integration.descriptionKey"
            [cortexIntegrationVersion1]="cortexIntegrationVersion1"
          >
          </mc-cortex-aside-settings>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_CORTEX_INTEGRATION_ASIDE_VIEW.FIELDS.CREATED' | translate }}
            </div>
            <div class="mc-detailed-list-column">{{ integration.created | mcDatePlusWeekday }}</div>
          </div>
        </div>
      </div>
    </section>
  </mc-body-container>
</mc-layout-aside-extra-container>
