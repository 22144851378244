"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImSyncTableComponent = void 0;
const core_1 = require("@angular/core");
const tableConfig = require("./im-sync-table-config");
const im_task_1 = require("../../model/im-task");
const im_sync_table_config_1 = require("./im-sync-table-config");
class ImSyncTableComponent {
    constructor() {
        this.viewImTask = new core_1.EventEmitter();
        this.onPaginationChange = new core_1.EventEmitter();
        this.onFilterChange = new core_1.EventEmitter();
        this.onCreateTask = new core_1.EventEmitter();
        this.updateImTask = new core_1.EventEmitter();
        this.updateImTaskStatus = new core_1.EventEmitter();
        this._metaData = {};
        this.dateFormat = 'dd MMM yyyy - HH:mm';
        this.columns = tableConfig.columns;
        this.columnsStartHidden = tableConfig.columnsStartHidden;
        this.searchByFields = tableConfig.searchByFields;
        this.sections = tableConfig.sections;
        this.sourceTranslateLookup = im_sync_table_config_1.sourceTranslateLookup;
        this.columnsAlwaysVisible = tableConfig.columnsAlwaysVisible;
        this.maxLengthForSearchField = tableConfig.maxLengthForSearchField;
        this.minLengthForSearchField = tableConfig.minLengthForSearchField;
    }
    set metaData(metaData) {
        if (metaData) {
            this._metaData = Object.assign(Object.assign({}, metaData), { pagination: Object.assign(Object.assign({}, metaData.pagination), { totalRows: metaData.pagination.totalCount }) });
        }
    }
    get metaData() {
        return this._metaData;
    }
    updateSelectedTaskStatus(selectedImTask) {
        this.selectedImTask = selectedImTask;
        this.updateImTaskStatus.emit(selectedImTask);
    }
    getSourceTranslationKey(source) {
        return this.sourceTranslateLookup[source]
            ? '$I18N_SERVICES_IM_SYNC_COMPONENTS_IM_SYNC_TABLE.SOURCE.' +
                this.sourceTranslateLookup[source]
            : source;
    }
    isTaskLegacy(taskType) {
        return taskType === im_task_1.ImTaskType.LEGACY_INGESTION;
    }
    viewSelectedTask(selectedImTask) {
        this.selectedImTask = selectedImTask;
        this.viewImTask.emit(selectedImTask);
    }
    updateSelectedTask(selectedImTask) {
        this.selectedImTask = selectedImTask;
        this.updateImTask.emit(selectedImTask);
    }
}
exports.ImSyncTableComponent = ImSyncTableComponent;
