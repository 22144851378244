"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ1 = exports.ɵ0 = exports.IPV4AndSubnetMaskValidator = void 0;
const IPV4_REGEX = new RegExp([
    '(?!(10\\.|172\\.(1[6-9]|2[0-9]|3[0-1])\\.|192\\.168))',
    '(^(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])(\\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])){3}$)'
].join(''));
const SUBNET_REGEX = /(^(([1-9]|[1-2]\d|3[0-2]))$)/;
const isValidIPV4Address = (ipv4) => IPV4_REGEX.test(ipv4);
const ɵ0 = isValidIPV4Address;
exports.ɵ0 = ɵ0;
const isValidSubnetMask = (subnet) => SUBNET_REGEX.test(subnet);
const ɵ1 = isValidSubnetMask;
exports.ɵ1 = ɵ1;
/* Validator */
const IPV4AndSubnetMaskValidator = (control) => {
    // control field is empty; no validation required
    if (!control.value) {
        return null;
    }
    const ipAddress = control.value;
    const invalidObject = { IP_INVALID: { value: ipAddress } };
    const ipParts = ipAddress.split('/');
    const ip = isValidIPV4Address(ipParts[0]) && isValidSubnetMask(ipParts[1]);
    if (ip && ipParts.length === 2) {
        return null;
    }
    return invalidObject;
};
exports.IPV4AndSubnetMaskValidator = IPV4AndSubnetMaskValidator;
