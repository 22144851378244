"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailsFieldComponent = void 0;
const rxjs_1 = require("rxjs");
class EmailsFieldComponent {
    constructor() {
        this.showMore = new rxjs_1.BehaviorSubject(false);
    }
}
exports.EmailsFieldComponent = EmailsFieldComponent;
