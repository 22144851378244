"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiApplicationsListComponent = void 0;
const operators_1 = require("rxjs/operators");
const coreReducers = require("app-new/core/reducers");
const applications_table_static_values_1 = require("../../components/api-gateway/tables/applications-table/applications-table.static-values");
const actions = require("../../actions");
const reducers = require("../../reducers");
class ApiApplicationsListComponent {
    constructor(capabilitiesService, store, stateService, tabService, modalService) {
        this.capabilitiesService = capabilitiesService;
        this.store = store;
        this.stateService = stateService;
        this.tabService = tabService;
        this.modalService = modalService;
        this.apiLegacyColumns = applications_table_static_values_1.apiLegacyColumns;
        this.apiGatewayV2Columns = applications_table_static_values_1.apiGatewayV2Columns;
        this.searchByColumnsLegacy = applications_table_static_values_1.searchByColumnsLegacy;
        this.searchByColumnsApiGateway2_0 = applications_table_static_values_1.searchByColumnsApiGateway2_0;
        this.apiTabs = [];
        this.notificationShown = false;
        const tab = this.tabService.getSelectedTab();
        tab.onRefresh = () => {
            this.store.dispatch(new actions.GetAllApiApplicationsAction());
            this.getAllIntegrationsBasedOnCapability();
        };
    }
    ngOnInit() {
        this.isApplicationsLoading$ = this.store.select(reducers.isApiGatewayListLoading);
        this.selectedApplicationsRow$ = this.store.select(reducers.getApiGatewayListSelectedRow);
        this.legacyApplications$ = this.store.select(reducers.getFilteredOutLegacyListApplications);
        this.gatewayApplications$ = this.store.select(reducers.getFilteredOutApiGatewayListApplications);
        this.pagingLegacyApplicationsMetadata$ = this.store.select(reducers.getLegacyListPagingMetadata);
        this.pagingGatewayApplicationsMetadata$ = this.store.select(reducers.getGatewayListPagingMetadata);
        this.totalLegacyApplicationsCount$ = this.store.select(reducers.getLegacyListTotalCount);
        this.totalGatewayApplicationsCount$ = this.store.select(reducers.getGatewayListTotalCount);
        this.isOktaIntegrationSwitchEnabled$ = this.capabilitiesService.hasCapability('Temporary.Services.ApiApplications.Okta.Integration');
        this.isAnomaliIntegrationSwitchEnbled$ = this.capabilitiesService.hasCapability('Temporary.Services.ApiApplications.Anomali.Integration');
        this.isMimecastApiV3SwitchEnabled$ = this.capabilitiesService.hasCapability('Temporary.Services.ApiApplications.MimecastApiV3.Integration');
        this.apiTabs.push('$I18N_API_APPLICATIONS_LIST.API_GATEWAY_TAB_HEADER');
        this.isMimecastApiV3SwitchEnabled$
            .pipe(operators_1.first())
            .subscribe(isEnabled => isEnabled
            ? this.apiTabs.push('$I18N_API_APPLICATIONS_LIST.API_GATEWAY_2.0_TAB_HEADER')
            : null);
        this.isOktaEvidenceBasedControlIntegrationEnabled$ = this.capabilitiesService.hasCapability('Temporary.Services.ApiApplications.OktaEvidenceBasedControl.Integration');
        this.integrations$ = this.store.select(reducers.getIntegrationListApplications);
        this.isIntegrationsLoading$ = this.store.select(reducers.isIntegrationListLoading);
        this.selectedIntegrationsRow$ = this.store.select(reducers.getIntegrationListSelectedRow);
        this.totalIntegrationsCount$ = this.store.select(reducers.getIntegrationListTotalCount);
        this.pagingIntegrationsMetadata$ = this.store.select(reducers.getIntegrationListPagingMetadata);
        this.oktaIntegrationAccessToken$ = this.store.select(reducers.getOktaIntegrationAccessToken);
        this.rotateToken$ = this.store.select(reducers.getRotateApiToken);
        this.capabilitiesService
            .hasCapability('Temporary.Services.ApiApplications.TechPartners')
            .subscribe(isActive => {
            if (isActive) {
                if (this.stateService.$state.params.displayTab) {
                    if (this.stateService.$state.params.displayTab === 'applications') {
                        this.displayTab = 1;
                    }
                    else if (this.stateService.$state.params.displayTab === 'integrations') {
                        this.displayTab = 3;
                    }
                    else if (this.stateService.$state.params.displayTab === 'event-channels') {
                        this.displayTab = 4;
                    }
                }
                else {
                    this.displayTab = 0;
                }
            }
            else {
                if (this.stateService.$state.params.displayTab === 'event-channels') {
                    this.displayTab = 3;
                }
                else {
                    this.displayTab =
                        (this.stateService.$state.params.displayTab || 'applications') === 'applications'
                            ? 0
                            : 1;
                }
            }
        });
        this.configuredProvidersSubscription = this.store
            .select(reducers.getIntegrationListApplications)
            .pipe(operators_1.tap((integrations) => {
            this.configuredProviders = !!integrations
                ? integrations.map(integration => integration.integrationType)
                : [];
        }))
            .subscribe();
        this.store
            .select(coreReducers.getAdminPreferences)
            .pipe(operators_1.first(), operators_1.filter((prefs) => {
            return !(prefs &&
                prefs.apiApplicationsPreferences &&
                prefs.apiApplicationsPreferences.newFeaturesNotificationViewed);
        }), operators_1.tap(() => {
            if (!this.notificationShown) {
                this.store.dispatch(new actions.ApiApplicationShowNewFeaturesModalAction());
                this.notificationShown = true;
            }
        }))
            .subscribe();
        this.store.dispatch(new actions.GetAllApiApplicationsAction());
        this.getAllIntegrationsBasedOnCapability();
        if (this.stateService.$state.params.showApiModal === 'true') {
            this.showApiModal = true;
        }
    }
    getAllIntegrationsBasedOnCapability() {
        this.capabilitiesService
            .hasCapability('Temporary.Services.ApiApplications.Integration.OnDemandFailRequests')
            .subscribe(isActive => {
            if (isActive === true && this.stateService.$state.params.hasError === 'get-integrations') {
                this.store.dispatch(new actions.GetAllIntegrationsAction({ hasError: 'get-integrations' }));
            }
            else {
                this.store.dispatch(new actions.GetAllIntegrationsAction());
            }
        });
    }
    searchApplicationsForLegacy(search) {
        this.store.dispatch(new actions.SearchLegacyApiApplicationsAction(search));
    }
    searchApplicationsForGateway(search) {
        this.store.dispatch(new actions.SearchGatewayApiApplicationsAction(search));
    }
    changeApplicationsPage(page) {
        this.store.dispatch(new actions.PageApiApplicationsAction(page));
    }
    viewApplication(app) {
        this.store.dispatch(new actions.OpenViewApiApplicationAction(app));
    }
    createApplication() {
        this.store.dispatch(new actions.OpenCreateApiApplicationAction());
    }
    createApplicationForGateway() {
        this.store.dispatch(new actions.OpenCreateApiV3ApplicationAction());
    }
    enableApplication(app) {
        this.store.dispatch(new actions.EnableApiApplicationAction(app));
    }
    disableApplication(app) {
        this.store.dispatch(new actions.ConfirmDisableApiApplicationAction(app));
    }
    editApplication(app) {
        this.store
            .select(reducers.getApiGatewayV3Roles)
            .pipe(operators_1.first())
            .toPromise()
            .then((roles) => {
            if (app.usage === 'gateway') {
                if (roles.length) {
                    this.store.dispatch(new actions.OpenEditApiApplicationV3Action(app));
                }
                else {
                    this.modalService.open({
                        content: '$I18N_API_APPLICATIONS_LIST.NOTIFICATIONS.ERRORS.API2.0_ERROR',
                        contentParams: {
                            url: 'https://community.mimecast.com/s/article/Email-Security-Cloud-Gateway-Managing-Administrator-Roles'
                        },
                        hideHeader: true
                    });
                }
            }
            else {
                this.store.dispatch(new actions.OpenEditApiApplicationAction(app));
            }
        });
    }
    deleteApplication(app) {
        this.store.dispatch(new actions.ConfirmDeleteApiApplicationAction(app));
    }
    generateApplicationKeys(app) {
        this.store.dispatch(new actions.OpenKeyGenAction(app));
    }
    getApplicationCredentials(app) {
        this.store.dispatch(new actions.GetCredentialsAction(app));
    }
    changeIntegrationsPage(page) {
        this.store.dispatch(new actions.PageIntegrationsAction(page));
    }
    viewIntegration(integration) {
        this.store.dispatch(new actions.OpenViewIntegrationAction({ integration }));
    }
    createIntegration() {
        this.store.dispatch(new actions.OpenCreateIntegrationAction({ configuredProviders: this.configuredProviders }));
    }
    enableIntegration(integration) {
        this.store.dispatch(new actions.EnableIntegrationAction({ integration }));
    }
    disableIntegration(integration) {
        this.store.dispatch(new actions.DisableIntegrationAction({ integration }));
    }
    editIntegration(integration) {
        this.store.dispatch(new actions.OpenEditIntegrationAction({ integration }));
    }
    clearQueryParams() {
        this.store.dispatch(new actions.CancelOktaIntegrationWidgetAction());
    }
    openCrowdstrikeActivityLogPage(integrationId) {
        this.store.dispatch(new actions.OpenCrowdstrikeActivityLogPageAction(integrationId));
    }
    ngOnDestroy() {
        this.configuredProvidersSubscription.unsubscribe();
    }
}
exports.ApiApplicationsListComponent = ApiApplicationsListComponent;
