"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportComponent = void 0;
const forms_1 = require("@angular/forms");
const exportActions = require("../actions/export.actions");
class ExportComponent {
    constructor(
    // @ts-ignore
    store, overlayRef, logsPageConfig, formBuilder) {
        this.store = store;
        this.overlayRef = overlayRef;
        this.logsPageConfig = logsPageConfig;
        this.formBuilder = formBuilder;
        this.fileFormats = [
            { value: 'csv', label: 'CSV' },
            { value: 'xls', label: 'XLS' }
        ];
    }
    static formValidator(formGroup) {
        return Object.keys(formGroup.value.columns).find(column => formGroup.value.columns[column])
            ? null
            : { valid: false };
    }
    ngOnInit() {
        this.setupFormGroup();
    }
    setupFormGroup() {
        const formGroupFields = {
            format: this.fileFormats[0].value
        };
        if (this.logsPageConfig) {
            if (!(this.logsPageConfig.exportSetup.action &&
                this.logsPageConfig.exportSetup.action === 'download')) {
                formGroupFields['email'] = [null, [forms_1.Validators.required, forms_1.Validators.email]];
            }
            const columns = {};
            this.logsPageConfig.exportColumns.forEach((column) => {
                columns[column.exportValue] = false;
                formGroupFields['columns'] = this.formBuilder.group(columns);
            });
        }
        this.formGroup = this.formBuilder.group(formGroupFields, {
            validator: ExportComponent.formValidator
        });
    }
    close() {
        this.overlayRef.close();
    }
    fileFormatChange(selection) {
        this.formGroup.get('format').setValue(selection);
    }
    getColumnLabel(name) {
        return `${this.logsPageConfig.exportSetup.columnNamePrefix}.${name.toUpperCase()}`;
    }
    getActionLabel() {
        return this.logsPageConfig.exportSetup.action &&
            this.logsPageConfig.exportSetup.action === 'download'
            ? '$I18N_EXPORT_LEGACY.BUTTON_LABEL.DOWNLOAD'
            : '$I18N_EXPORT_LEGACY.BUTTON_LABEL.EXPORT';
    }
    doAction() {
        if (!this.logsPageConfig.exportSetup.action ||
            this.logsPageConfig.exportSetup.action === 'email') {
            this.doExportEmail();
        }
    }
    doExportEmail() {
        const exportPayloadData = {
            exportuid: this.logsPageConfig.exportSetup.exportUid,
            format: this.formGroup.get('format').value,
            recipient: this.formGroup.get('email').value,
            columns: this.getColumns(),
            filters: this.logsPageConfig.exportFilter.filters
        };
        if (this.logsPageConfig.exportFilter.fromdate) {
            exportPayloadData.fromdate = this.logsPageConfig.exportFilter.fromdate;
            exportPayloadData.todate = this.logsPageConfig.exportFilter.todate;
        }
        if (this.logsPageConfig.exportFilter.search) {
            exportPayloadData.search = this.logsPageConfig.exportFilter.search;
        }
        this.store.dispatch(new exportActions.ExportEmailAction(exportPayloadData));
    }
    getColumns() {
        return Object.keys(this.formGroup.value.columns)
            .filter(column => this.formGroup.value.columns[column])
            .join('~');
    }
}
exports.ExportComponent = ExportComponent;
