<mc-layout-aside-extra-pagination-container
  keyTitle="$I18N_DEVICE_GROUPS_LIST.SIDEBAR.HEADER"
  showClose="true"
  [hasNextInView]="hasNextInView$ | async"
  [hasPreviousInView]="hasPreviousInView$ | async"
  [indexInView]="indexInView$ | async"
  [currenPagination]="currentPagination$ | async"
  [filtersMetaData]="filtersMetaData$ | async"
  [isLoading]="false"
  (goToNextPage)="getNext()"
  (goToPrevPage)="getPrev()"
  (closeAside)="close()"
>
  <div row-actions>
    <div class="mc-aside-header">
      <button *ngIf="hasEditPermission$ | async" class="btn btn-primary panel-half-margin-right" (click)="onEditClick()">
        {{'$I18N_DEVICE_GROUPS_LIST.SIDEBAR.EDIT_ACTION' | translate}}
      </button>
      <button  *ngIf="hasEditPermission$ | async" class="btn btn-secondary panel-half-margin-right" (click)="onDeleteClick()">
        {{'$I18N_DEVICE_GROUPS_LIST.SIDEBAR.DELETE_ACTION' | translate}}
      </button>
    </div>
  </div>

  <mc-body-container class ="mc-sidebar-body-container">
    <mc-swg-device-groups-sidepanel [deviceGroup]="selectedRow$ | async"></mc-swg-device-groups-sidepanel>
  </mc-body-container>
</mc-layout-aside-extra-pagination-container>
