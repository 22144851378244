<div class="panel-body">
  <mc-chart class="mc-top-ten-held" *ngIf="!(isLoading$ | async); else loadingChart">
    <mc-bar-chart [config]="chartConfiguration" [chartData]="chartData" class="mc-amchart"></mc-bar-chart>
  </mc-chart>
</div>

<ng-template #loadingChart class="parent">
  <div class="mc-with-spinner">
    <mc-spinner></mc-spinner>
  </div>
</ng-template>
