"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AcceptedEmailMainTableComponent = void 0;
const core_1 = require("@angular/core");
const date_range_config_1 = require("../../../../common/components/table-search-configs/date-range-config");
const main_table_static_values_1 = require("./main-table.static-values");
class AcceptedEmailMainTableComponent {
    constructor(capabilitiesService, emailRoutePipe, spamDetectionPipe) {
        this.capabilitiesService = capabilitiesService;
        this.emailRoutePipe = emailRoutePipe;
        this.spamDetectionPipe = spamDetectionPipe;
        /**
         * Variables
         */
        this.columns = [...main_table_static_values_1.tableColumns];
        this.exports = [...main_table_static_values_1.exportColumns];
        this.filterSections = main_table_static_values_1.tableSectionFilters;
        this.searchSections = main_table_static_values_1.tableSectionSearch;
        this.dateRangePickerConfig = date_range_config_1.DateRangeConfig.config;
        this.columnsToStartHidden = [...main_table_static_values_1.tableColumnsStartHidden];
        this.columnsAlwaysVisible = [...main_table_static_values_1.tableColumnsAlwaysVisible];
        this.columnsToIgnore = main_table_static_values_1.tableColumnsToIgnore;
        this.selectedRows = [];
        this.isContentAdmin = false;
        this.canSubmitReport = false;
        this.dataRows = [];
        /**
         * Outputs
         */
        this.filtersChange = new core_1.EventEmitter();
        this.paginatorChange = new core_1.EventEmitter();
        this.rowClick = new core_1.EventEmitter();
        this.reportMessage = new core_1.EventEmitter();
        this.export = new core_1.EventEmitter();
        this.forward = new core_1.EventEmitter();
    }
    /**
     * Inputs
     */
    get rows() {
        return this.dataRows;
    }
    set rows(rows) {
        this.dataRows = rows.map(row => {
            return Object.assign(Object.assign({}, row), { route: this.emailRoutePipe.transform(row.route), detectionLevel: this.spamDetectionPipe.transform(row.detectionLevel) });
        });
    }
    ngOnInit() {
        this.capabilitiesService.ifCapability('Permission.ACCEPTED_EMAIL_CONTENT_VIEW', () => {
            this.isContentAdmin = true;
            this.columns.unshift(main_table_static_values_1.tableSelectColumn);
            this.columnsToIgnore.unshift(main_table_static_values_1.tableSelectColumn);
        });
        this.capabilitiesService.ifCapability('!Permission.SERVICE_DELIVERY_DENY_REPORT_AS_SUBMISSIONS', () => {
            this.canSubmitReport = true;
        });
        this.capabilitiesService
            .hasCapability('Temporary.MessageCenter.SpamScore')
            .subscribe(isActive => {
            if (isActive === false) {
                const columnsFiltered = this.columns.filter(value => value !== 'spamScore' && value !== 'detectionLevel');
                this.columns = columnsFiltered;
                const exportsFiltered = this.exports.filter(value => value !== 'spamScore' && value !== 'spamDetection');
                this.exports = exportsFiltered;
            }
        });
    }
    onColumnFilterChanged(filters) {
        this.filtersChange.emit(this.toMessageFilter(filters));
    }
    toMessageFilter(filters) {
        const filterby = [];
        filterby.push(...this.toFiltersBy('attachments', filters.attachments));
        filterby.push(...this.toFiltersBy('route', filters.route));
        filterby.push(...this.toFiltersBy('messageInfo', filters.messageInfo));
        return { filterBy: filterby };
    }
    toFiltersBy(fieldName, filters) {
        if (!!filters && filters.length > 0) {
            return filters.map(value => {
                return { fieldName, value };
            });
        }
        else {
            return [];
        }
    }
    onDateFilterChange(filters) {
        if (!!filters.range) {
            this.filtersChange.emit(this.toDateRange(filters));
        }
    }
    toDateRange(filters) {
        return Object.assign(Object.assign({}, (!!filters.range.start ? { start: filters.range.start } : {})), (!!filters.range.end ? { end: filters.range.end } : {}));
    }
    onSearchFilterChange(filters) {
        this.filtersChange.emit(this.toSearchBy(filters));
    }
    toSearchBy(filters) {
        return {
            searchBy: { fieldName: filters.selectValue, value: filters.searchQuery }
        };
    }
    onPaginationChange(pagination) {
        this.paginatorChange.emit(pagination);
    }
    exportEmails() {
        this.export.emit(this.exports);
    }
    forwardEmails() {
        this.forward.emit(this.selectedRows);
    }
    canForwardEmails() {
        return this.isContentAdmin && this.selectedRows.length > 0;
    }
    rowSelected(rows) {
        this.selectedRows = rows;
    }
    reportEmail(report) {
        this.reportMessage.emit(report);
    }
}
exports.AcceptedEmailMainTableComponent = AcceptedEmailMainTableComponent;
