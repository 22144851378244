"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgEndpointsSettingsComponent = void 0;
const core_1 = require("@angular/core");
const endpoints_models_1 = require("../../models/endpoints.models");
class SwgEndpointsSettingsComponent {
    constructor(fb, translateService, stateService) {
        this.fb = fb;
        this.translateService = translateService;
        this.stateService = stateService;
        this.settingsChanged = new core_1.EventEmitter();
        this.openDirectIPConfirmModal = new core_1.EventEmitter();
        this.openAllowLocalIpsConfirmModal = new core_1.EventEmitter();
        this.openTUIDAttributeConfirmModal = new core_1.EventEmitter();
        this.settingOperationMessage = 'Settings Updating';
        this.form = this.fb.group({
            userLogin: [false],
            enforceUserLogin: [false],
            allowTransparentId: [false],
            autoUpdate: [false],
            tamperProtectPassword: '',
            tamperProtectPreviousPassword: '',
            uninstallPassword: '',
            enableUninstallPassword: [false],
            enableAutoEnrolment: [false],
            directIpEnabled: [false],
            allowInternalIps: [true],
            directIpMode: '',
            allowUserBinding: [false],
            ldapMailAttribute: '',
            silentUpdate: [false]
        });
    }
    set settings(settings) {
        this.settingsPrevious = settings;
        this.currentControl = '';
        this.form.markAsPristine();
        if (settings &&
            settings.allowEnableAutoEnrolment !== undefined &&
            this.allowEnableAutoEnrolment === undefined) {
            this.allowEnableAutoEnrolment = settings.allowEnableAutoEnrolment;
        }
        if (settings && !this.form.dirty) {
            this.setFormValues(settings);
        }
    }
    set updateSettingsError(error) {
        if (error) {
            this.setFormValues(this.settingsPrevious);
        }
    }
    get directIpModeOptions() {
        return [
            {
                value: endpoints_models_1.SwgDirectIPProtectionMode.DISABLED,
                label: this.translateService.instant('$I18N_ENDPOINT_SETTINGS.DIRECT_IP_MODES.DISABLED')
            },
            {
                value: endpoints_models_1.SwgDirectIPProtectionMode.ENABLED,
                label: this.translateService.instant('$I18N_ENDPOINT_SETTINGS.DIRECT_IP_MODES.ENABLED')
            },
            {
                value: endpoints_models_1.SwgDirectIPProtectionMode.MONITORING,
                label: this.translateService.instant('$I18N_ENDPOINT_SETTINGS.DIRECT_IP_MODES.MONITORING')
            }
        ];
    }
    get tuidAttributeOptions() {
        return [
            {
                value: endpoints_models_1.SwgTUIDAttributeOption.MAIL,
                label: this.translateService.instant('$I18N_ENDPOINT_SETTINGS.TUID_ATTRIBUTE_OPTIONS.MAIL')
            },
            {
                value: endpoints_models_1.SwgTUIDAttributeOption.USER_PRINCIPAL_NAME,
                label: this.translateService.instant('$I18N_ENDPOINT_SETTINGS.TUID_ATTRIBUTE_OPTIONS.USER_PRINCIPAL_NAME')
            }
        ];
    }
    ngOnInit() {
        this.formValuesChangeSubscription = this.form.valueChanges.subscribe((settings) => {
            if (this.settingsPrevious !== undefined &&
                this.settingsPrevious.allowInternalIps === settings.allowInternalIps) {
                this.form.controls.allowInternalIps.markAsPristine();
            }
            if (this.settingsPrevious !== undefined &&
                this.settingsPrevious.silentUpdate === settings.silentUpdate) {
                this.form.controls.silentUpdate.markAsPristine();
            }
            // we are being optimistic about the settings value changes
            if (this.form.dirty) {
                for (const control in this.form.controls) {
                    if (this.form.controls[control].dirty) {
                        this.currentControl = control;
                        break;
                    }
                }
                // If User-Level mode is enabled AND Enforce User-Level Mode is enabled AND THEN
                // User-Level mode becomes disabled THEN ALSO disable Enforce User-Level Mode
                if (this.form.controls.userLogin.dirty && !settings.userLogin) {
                    settings.enforceUserLogin = false;
                    this.form.markAsPristine();
                    this.setFormValues(settings);
                }
                if (this.hasDirectIpProtectionSwitchEnabled && this.form.controls.directIpEnabled.dirty) {
                    this.form.markAsPristine();
                    delete settings.directIpMode;
                    this.openDirectIPConfirmModal.emit(settings);
                }
                else if (this.hasDirectIpProtectionModeSwitchEnabled &&
                    this.form.controls.directIpMode.dirty) {
                    this.form.markAsPristine();
                    delete settings.directIpEnabled;
                    this.openDirectIPConfirmModal.emit(settings);
                }
                else if (this.hasTUIDAttributeSwitchEnabled &&
                    this.form.controls.ldapMailAttribute.dirty) {
                    this.form.markAsPristine();
                    this.openTUIDAttributeConfirmModal.emit(settings);
                }
                else if (this.hasTUIDAttributeSwitchEnabled &&
                    this.form.controls.allowTransparentId.dirty &&
                    !settings.allowTransparentId) {
                    this.settingsChanged.emit(Object.assign(Object.assign({}, settings), { ldapMailAttribute: endpoints_models_1.SwgTUIDAttributeOption.MAIL }));
                }
                else if (this.hasSilentUpdateSwitchEnabled &&
                    this.form.controls.autoUpdate.dirty &&
                    !settings.autoUpdate) {
                    this.settingsChanged.emit(Object.assign(Object.assign({}, settings), { silentUpdate: false }));
                }
                else if (this.form.controls.allowInternalIps.dirty && !settings.allowInternalIps) {
                    this.form.markAsPristine();
                    this.openAllowLocalIpsConfirmModal.emit(settings);
                }
                else {
                    this.settingsChanged.emit(settings);
                }
            }
        });
        this.readOnlyMessage = {
            msg: this.translateService.instant('$I18N_ENDPOINT_SETTINGS.NOTIFICATION.READ_ONLY'),
            status: 'info'
        };
    }
    ngOnDestroy() {
        this.formValuesChangeSubscription.unsubscribe();
    }
    setFormValues(settings) {
        this.form.patchValue({
            userLogin: settings.userLogin,
            enforceUserLogin: settings.enforceUserLogin,
            allowTransparentId: settings.allowTransparentId,
            autoUpdate: settings.autoUpdate,
            enableUninstallPassword: settings.enableUninstallPassword === undefined ? false : settings.enableUninstallPassword,
            enableAutoEnrolment: settings.enableAutoEnrolment,
            directIpEnabled: settings.directIpEnabled === undefined ? false : settings.directIpEnabled,
            allowInternalIps: settings.allowInternalIps,
            directIpMode: settings.directIpMode === undefined
                ? endpoints_models_1.SwgDirectIPProtectionMode.DISABLED
                : settings.directIpMode,
            allowUserBinding: !!settings.allowUserBinding,
            ldapMailAttribute: settings.ldapMailAttribute === undefined
                ? endpoints_models_1.SwgTUIDAttributeOption.MAIL
                : settings.ldapMailAttribute,
            silentUpdate: settings.silentUpdate === undefined ? false : settings.silentUpdate
        });
    }
    onUserLoginToggle() {
        this.form.patchValue({
            userLogin: !this.form.get('userLogin').value
        });
    }
    onEnforceUserLoginToggle() {
        // Before changing the value of this switch, we need to check that the user login switch is enabled
        // If user login is not enabled, then this switch will be disabled and the value should not change
        this.form.patchValue({
            enforceUserLogin: this.form.get('userLogin').value
                ? !this.form.get('enforceUserLogin').value
                : this.form.get('enforceUserLogin').value
        });
    }
    onAllowTransparentIdToggle() {
        this.form.patchValue({
            allowTransparentId: !this.form.get('allowTransparentId').value
        });
    }
    onAutoUpdateToggle() {
        this.form.patchValue({
            autoUpdate: !this.form.get('autoUpdate').value
        });
    }
    onDeviceAutoEnrollmentToggle() {
        this.form.patchValue({
            enableAutoEnrolment: !this.form.get('enableAutoEnrolment').value
        });
    }
    onDirectIPToggle() {
        this.form.patchValue({
            directIpEnabled: !this.form.get('directIpEnabled').value
        });
    }
    goToAccountSettings() {
        this.stateService.$state.go('legacy', { uid: 'settings' });
    }
    goToExceptions() {
        this.stateService.$state.go('secure-web-gateway-exceptions-list');
    }
    onDirectIpModeChange(mode) {
        this.form.controls.directIpMode.markAsDirty();
        this.form.get('directIpMode').setValue(mode);
    }
    onAllowUserBindingToggle() {
        this.form.patchValue({
            allowUserBinding: !this.form.get('allowUserBinding').value
        });
    }
    onTUIDAttributeChange(attribute) {
        this.form.controls.ldapMailAttribute.markAsDirty();
        this.form.get('ldapMailAttribute').setValue(attribute);
    }
}
exports.SwgEndpointsSettingsComponent = SwgEndpointsSettingsComponent;
