"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSelectedForwardingMessage = exports.getPreviousAndNextSelectedRow = exports.getMessageList = exports.isSidePanelLoading = exports.getSelectedEmail = exports.getSelectedDeliveryMessage = exports.getSelectedMessage = exports.getForceLoadContent = exports.getMessageInfo = exports.reducer = exports.initialState = void 0;
const actions = require("../actions");
exports.initialState = {
    messageInfo: {},
    messageList: [],
    forceLoadContent: false,
    isSidePanelLoading: false,
    selectedEmail: { owner: undefined, type: 'receipt' },
    showHtml: true
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.SELECT_EMAIL:
            return Object.assign(Object.assign({}, state), { selectedEmail: {
                    owner: action.owner,
                    type: action.transmissionType
                } });
        case actions.SHOW_MESSAGE_INFO:
            return Object.assign(Object.assign({}, state), { messageList: action.list });
        case actions.GET_MESSAGE_INFO:
            return Object.assign(Object.assign({}, state), { isSidePanelLoading: true });
        case actions.GET_MESSAGE_INFO_SUCCESS:
            return Object.assign(Object.assign({}, state), { messageInfo: action.messageInfo, isSidePanelLoading: false, forceLoadContent: action.forceLoadContent, selectedEmail: action.messageInfo.recipientInfo && {
                    owner: action.messageInfo.status !== 'bounced'
                        ? action.messageInfo.recipientInfo.messageInfo.fromHeader
                        : action.messageInfo.queueInfo.to,
                    type: action.messageInfo.status !== 'bounced' ? 'receipt' : 'delivery'
                } });
        case actions.GET_MESSAGE_INFO_FAIL:
            return Object.assign(Object.assign({}, state), { messageInfo: {}, isSidePanelLoading: false });
        case actions.COMPARE_VIEWS:
            return Object.assign(Object.assign({}, state), { selectedEmail: {
                    owner: action.on &&
                        state.selectedEmail.type === 'receipt' &&
                        state.messageInfo.deliveredMessage
                        ? Object.keys(state.messageInfo.deliveredMessage)[0]
                        : state.selectedEmail.owner,
                    type: action.on && state.selectedEmail.type === 'receipt'
                        ? 'delivery'
                        : state.selectedEmail.type
                } });
        case actions.SHOW_HTML_UPDATE:
            return Object.assign(Object.assign({}, state), { showHtml: action.showHtml$ });
        case actions.SHOW_HTML_UPDATE_FAIL:
            return Object.assign({}, state);
        // @ts-ignore
        case actions.RESET_STATE:
            return Object.assign({}, exports.initialState);
        default:
            return state;
    }
}
exports.reducer = reducer;
const getMessageInfo = (state) => {
    return state.messageInfo;
};
exports.getMessageInfo = getMessageInfo;
const getForceLoadContent = (state) => {
    return state.forceLoadContent;
};
exports.getForceLoadContent = getForceLoadContent;
const getSelectedMessage = (state) => {
    const selectMessageType = state.selectedEmail && state.selectedEmail.type;
    const selectedMessage = selectMessageType === 'receipt'
        ? state.messageInfo && state.messageInfo.recipientInfo
        : state.messageInfo &&
            state.messageInfo.deliveredMessage &&
            state.selectedEmail &&
            state.selectedEmail.owner &&
            state.messageInfo.deliveredMessage[state.selectedEmail.owner];
    return selectedMessage || {};
};
exports.getSelectedMessage = getSelectedMessage;
const getSelectedDeliveryMessage = (state) => {
    return (state.messageInfo &&
        state.messageInfo.deliveredMessage &&
        state.messageInfo.deliveredMessage[state.selectedEmail.owner]);
};
exports.getSelectedDeliveryMessage = getSelectedDeliveryMessage;
const getSelectedEmail = (state) => {
    return state.selectedEmail;
};
exports.getSelectedEmail = getSelectedEmail;
const isSidePanelLoading = (state) => {
    return state.isSidePanelLoading;
};
exports.isSidePanelLoading = isSidePanelLoading;
const getMessageList = (state) => {
    return state.messageList;
};
exports.getMessageList = getMessageList;
const getPreviousAndNextSelectedRow = (state) => {
    const list = exports.getMessageList(state);
    const selectedRow = exports.getMessageInfo(state) || { id: '' };
    const index = selectedRow && list.findIndex(row => row.id === selectedRow.id);
    const result = {
        currentItemIndex: index + 1,
        pageSize: list.length,
        previous: index >= 0 ? list[index - 1] : undefined,
        next: index >= 0 ? list[index + 1] : undefined
    };
    return result;
};
exports.getPreviousAndNextSelectedRow = getPreviousAndNextSelectedRow;
const getSelectedForwardingMessage = (state) => {
    const message = exports.getSelectedMessage(state);
    // @ts-ignore: bruno's code...
    const messageInfo = message.messageInfo;
    const size = 
    // @ts-ignore: it's either a delivery...
    (message.deliveryMetaInfo && message.deliveryMetaInfo.transmissionSize) ||
        // @ts-ignore: ...or a receipt
        (message.recipientMetaInfo && message.recipientMetaInfo.transmissionSize) ||
        // @ts-ignore: ...or default to transmission size
        (message.txInfo && message.txInfo.actualSize);
    return { id: state.messageInfo.id, subject: messageInfo.subject, size: size || 0 };
};
exports.getSelectedForwardingMessage = getSelectedForwardingMessage;
