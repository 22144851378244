"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducer = exports.initialState = void 0;
const actions = require("../actions/file-parse.actions");
exports.initialState = {
    isProcessing: false,
    imageUploaded: '',
    file: [{ name: '' }],
    hasError: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.URL_POLICY_PARSE_FILE:
            return Object.assign(Object.assign({}, state), { file: action.payload, isProcessing: true });
        case actions.URL_POLICY_PARSE_FILE_SUCCESS:
            return Object.assign(Object.assign({}, state), { hasError: false, isProcessing: false });
        case actions.URL_POLICY_PARSE_FILE_FAILURE:
            return Object.assign(Object.assign({}, state), { hasError: true, isProcessing: false });
        case actions.URL_POLICY_PARSE_FILE_RESET:
            return Object.assign({}, exports.initialState);
        case actions.URL_POLICY_UPLOADED_IMAGE:
            return Object.assign(Object.assign({}, state), { imageUploaded: action.payload });
        default:
            return state;
    }
}
exports.reducer = reducer;
