"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormSelectComponent = void 0;
const core_1 = require("@angular/core");
const components_1 = require("@mimecast-ui/components");
class FormSelectComponent extends components_1.InputFieldBaseComponent {
    constructor(windowService) {
        super();
        this.windowService = windowService;
        this.isRequired = true;
        this.selectOptions = [];
        this.useTranslation = true;
        this.shouldTrimOptions = false;
        this.selectChange = new core_1.EventEmitter();
        this.maxOptionLength = 100;
        this.maxOptionLengthSmallScreen = 30;
    }
    set options(selectedItem) {
        this.selectOptions = selectedItem;
        if (this.shouldTrimOptions) {
            this.trimmedOptions = [...selectedItem];
            this.trimOptions();
        }
    }
    ngOnInit() {
        this.subscription = this.field.valueChanges.subscribe((newValue) => {
            if (!!newValue) {
                this.placeholder = null;
            }
            else {
                this.selectChange.emit(newValue);
            }
        });
        if (this.shouldTrimOptions) {
            this.onResize();
        }
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
    toggle($event) {
        this.helpPopover.toggle();
        if (!!$event) {
            $event.preventDefault();
        }
    }
    trim(length) {
        var _a;
        return (_a = this.trimmedOptions) === null || _a === void 0 ? void 0 : _a.map((option, index) => {
            // Trims options if it's beyond maximum allowed characters and suffix with ... once.
            if (option.label.length >= length || option.label.endsWith('...')) {
                option = Object.assign({}, this.selectOptions[index]);
                if (option.label.length > length) {
                    option.label = option.label.slice(0, length - 3) + '...';
                }
            }
            return option;
        });
    }
    trimOptions() {
        if (this.windowService.isSmallScreen()) {
            this.trimmedOptions = this.trim(this.maxOptionLengthSmallScreen);
        }
        else {
            this.trimmedOptions = this.trim(this.maxOptionLength);
        }
    }
    onResize() {
        this.trimOptions();
    }
}
exports.FormSelectComponent = FormSelectComponent;
