"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArchiveViewerSliderComponent = void 0;
const core_1 = require("@angular/core");
const reducers = require("../../reducers");
class ArchiveViewerSliderComponent {
    constructor(store, overlayRef, overlayData, archiveViewerApiService, _fb, locationService) {
        this.store = store;
        this.overlayRef = overlayRef;
        this.overlayData = overlayData;
        this.archiveViewerApiService = archiveViewerApiService;
        this._fb = _fb;
        this.locationService = locationService;
        this.tokenSelected = '';
        this.key = '';
        this.partialXmlTag = '';
        this.selectedPartial = new core_1.EventEmitter();
        this.subscribeCallBackTokenSliderResult = store.subscribe(state => {
            if (state.archiveViewer.search.tokenSliderResult) {
                this.key = state.archiveViewer.search.tokenSliderResult.value.id;
            }
        });
        this.archiveViewerSlider = this._fb.group({});
        this.archivePartial = this._fb.group({
            partialTokens: ['']
        });
        // archivePartial dropdown download
        this.subscriptionArchivePartial = this.archivePartial.valueChanges.subscribe(data => {
            this.tokenSelected = data.partialTokens;
            this.subscriptionStore = store.subscribe(state => {
                // give me the id based on data (token)
                if (state.archiveViewer.search.tokenSliderResult.value) {
                    this.deliveryComponentsArray =
                        state.archiveViewer.search.tokenSliderResult.value.deliveryComponents;
                    this.messageComponentsArray =
                        state.archiveViewer.search.tokenSliderResult.value.messageComponents;
                }
            });
            // find out based on the token user selected, which is the id
            // deliveryComponents: ED_DV2-6_CDEV1A65_20180103_0_49163292 -> it will request to 'get-delivery-detail' (getDeliveryDetail)
            // @ts-ignore
            this.deliveryComponentsArray.find(x => {
                if (x.token === this.tokenSelected) {
                    this.subscriptionArchiveViewerApiService = archiveViewerApiService
                        .getDeliveryDetail(x.id)
                        .subscribe((response) => {
                        this.partialXmlTag = response;
                    });
                }
            });
            // download DT_* (not parse. Security issues)
            // messageComponents: DT_DV2-6_CDEV1A65_20180103_99ad1e0ad3e6023c1fe65857a7f2ab54_731 -> request 'get-file/id' (getDownloadXml)
            // @ts-ignore
            this.messageComponentsArray.find(x => {
                if (x.token === this.tokenSelected) {
                    this.subscriptionCreateIFrameCallback = archiveViewerApiService
                        .getDownloadXml(x.id)
                        .subscribe((response) => {
                        archiveViewerApiService.createIFrame(this.locationService.origin + response.body);
                    });
                }
            });
        });
    }
    downloadXmlComponent() {
        this.subscriptionDownloadXmlComponentGetDownloadAllXml = this.archiveViewerApiService
            // @ts-ignore
            .getDownloadAllXml(this.key)
            .subscribe((response) => {
            this.archiveViewerApiService.createIFrame(this.locationService.origin + response.body);
        });
    }
    ngOnInit() {
        this.tokenResults$ = this.store.select(reducers.getTokenResults);
        this.selectedEmail$ = this.store.select(reducers.getSelectedEmail);
        this.sliderToggle$ = this.store.select(reducers.getTokenSliderResults);
        this.isLoading$ = this.store.select(reducers.isLoading);
    }
    ngOnDestroy() {
        if (this.subscriptionArchivePartial) {
            this.subscriptionArchivePartial.unsubscribe();
        }
        if (this.subscribeCallBackTokenSliderResult) {
            this.subscribeCallBackTokenSliderResult.unsubscribe();
        }
        if (this.subscriptionStore) {
            this.subscriptionStore.unsubscribe();
        }
        if (this.subscriptionArchiveViewerApiService) {
            this.subscriptionArchiveViewerApiService.unsubscribe();
        }
        if (this.subscriptionDownloadXmlComponentGetDownloadXml) {
            this.subscriptionDownloadXmlComponentGetDownloadXml.unsubscribe();
        }
        if (this.subscriptionDownloadXmlComponentGetDownloadAllXml) {
            this.subscriptionDownloadXmlComponentGetDownloadAllXml.unsubscribe();
        }
        if (this.subscriptionCreateIFrameCallback) {
            this.subscriptionCreateIFrameCallback.unsubscribe();
        }
    }
    close() {
        this.overlayRef.close();
    }
}
exports.ArchiveViewerSliderComponent = ArchiveViewerSliderComponent;
