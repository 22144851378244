"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GatewayDetailsStepComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const operators_1 = require("rxjs/operators");
const validators_1 = require("app-new/api-applications/validators");
const reducers_1 = require("../../../../../../reducers");
const kb_urls_1 = require("../../../../../../../core/constants/kb-urls");
class GatewayDetailsStepComponent {
    constructor(fb, store, capabilitiesService, categoryPipe) {
        this.fb = fb;
        this.store = store;
        this.capabilitiesService = capabilitiesService;
        this.categoryPipe = categoryPipe;
        this.productsKbUrl = kb_urls_1.KBUrls.API_2_0_PRODUCTS;
        this.delay = 200;
        this.onApplicationDetailsUpdated = new core_1.EventEmitter();
        this.onSubmitted = new core_1.EventEmitter();
        this.capabilitiesService
            .hasCapability('Temporary.Services.ApiApplications.Apigee.Mimecast.All.Categories')
            .subscribe(allowed => {
            if (allowed === true) {
                this.showCategories = true;
            }
            else {
                this.showCategories = false;
            }
        });
        this.capabilitiesService
            .hasCapability('Temporary.Services.ApiApplications.Apigee.Mimecast.All.Products')
            .subscribe(allowed => {
            if (allowed === true) {
                this.showProducts = true;
            }
            else {
                this.showProducts = false;
            }
        });
        this.form = this.fb.group(Object.assign(Object.assign(Object.assign({ applicationName: [
                '',
                [
                    forms_1.Validators.required,
                    forms_1.Validators.minLength(3),
                    forms_1.Validators.maxLength(60),
                    forms_1.Validators.pattern(/^[a-zA-Z0-9]+[a-zA-Z0-9 _.\-$]+$/),
                    validators_1.validateNotEmpty
                ]
            ], roleId: [null, [forms_1.Validators.required]] }, (this.showProducts && { gatewayAppProducts: [this.fb.array([]), [forms_1.Validators.required]] })), { description: ['', [forms_1.Validators.required, forms_1.Validators.maxLength(1024), validators_1.validateNotEmpty]] }), (this.showCategories && { category: [null, [forms_1.Validators.required]] })));
    }
    ngOnInit() {
        this.roles$ = this.store.select(reducers_1.getApiGatewayV3Roles);
        this.products$ = this.store.select(reducers_1.getApiGatewayV3Products);
        this.categories = this.getCategories();
        this.form.patchValue(this.applicationDetails || {});
        this.formSubscription = this.form.valueChanges.pipe(operators_1.debounceTime(this.delay)).subscribe(() => {
            this.update();
        });
    }
    ngAfterViewInit() {
        this.products$.subscribe((selectItems) => {
            this.preSelectProducts(selectItems);
        });
    }
    preSelectProducts(selectItems) {
        selectItems.map((selectItem) => {
            if (this.applicationDetails.products) {
                this.applicationDetails.products.forEach(product => {
                    if (product.id === selectItem.value) {
                        selectItem.isSelected = true;
                    }
                });
            }
        });
    }
    get pageTitle() {
        return `$I18N_API_GATEWAY_V3_WIZARD.${this.mode}.DETAILS_STEP.HEADER`;
    }
    getCategories() {
        return [
            {
                value: 'siem_integration',
                label: this.categoryPipe.transform('siem_integration')
            },
            {
                value: 'xdr_integration',
                label: this.categoryPipe.transform('xdr_integration')
            },
            {
                value: 'msp_ordering_and_provisioning',
                label: this.categoryPipe.transform('msp_ordering_and_provisioning')
            },
            {
                value: 'email_or_archiving',
                label: this.categoryPipe.transform('email_or_archiving')
            },
            {
                value: 'business_intelligence',
                label: this.categoryPipe.transform('business_intelligence')
            },
            {
                value: 'process_automation',
                label: this.categoryPipe.transform('process_automation')
            },
            {
                value: 'soar_integration',
                label: this.categoryPipe.transform('soar_integration')
            },
            {
                value: 'itsm_integration',
                label: this.categoryPipe.transform('itsm_integration')
            },
            {
                value: 'casb_integration',
                label: this.categoryPipe.transform('casb_integration')
            },
            {
                value: 'managed_soc_integration',
                label: this.categoryPipe.transform('managed_soc_integration')
            },
            {
                value: 'digital_risk_platform_integration',
                label: this.categoryPipe.transform('digital_risk_platform_integration')
            },
            {
                value: 'subscription_management_integration',
                label: this.categoryPipe.transform('subscription_management_integration')
            },
            {
                value: 'compliance_integration',
                label: this.categoryPipe.transform('compliance_integration')
            },
            {
                value: 'other',
                label: this.categoryPipe.transform('other')
            }
        ];
    }
    get pageDescription() {
        return `$I18N_API_GATEWAY_V3_WIZARD.${this.mode}.DETAILS_STEP.DESCRIPTION`;
    }
    get isEditing() {
        return this.mode === 'EDIT';
    }
    get isCreating() {
        return this.mode === 'CREATE';
    }
    submit() {
        if (this.isValid()) {
            this.onSubmitted.emit();
        }
    }
    collectProductIds(productIds) {
        this.form.controls['gatewayAppProducts'].setValue(productIds, {
            emitEvent: false,
            onlySelf: true
        });
    }
    update() {
        this.onApplicationDetailsUpdated.emit(this.form.getRawValue());
    }
    isValid() {
        return this.form.status === 'VALID';
    }
    ngOnDestroy() {
        this.formSubscription.unsubscribe();
    }
}
exports.GatewayDetailsStepComponent = GatewayDetailsStepComponent;
