<mc-layout-aside-extra-container
  keyTitle="{{ '$I18N_ARCHIVE_SUPERVISION_QUEUE_DETAILS.TITLE' | translate }}"
  showClose="true"
  [isLoading]="false"
  (closeAside)="close()"
>
  <mc-extra-container>
    <div class="mc-container-buttons-area">
      <button class="btn btn-primary" [disabled]="!hasEditPermissions || loading" (click)="edit()">
        {{ '$I18N_ARCHIVE_SUPERVISION_QUEUE_DETAILS.CONTROLS.EDIT' | translate }}
      </button>
      <button class="btn btn-secondary" (click)="close()">
        {{ '$I18N_ARCHIVE_SUPERVISION_QUEUE_DETAILS.CONTROLS.CANCEL' | translate }}
      </button>
      <button data-test="delete-button" *ngIf="hasDeleteCapability" class="btn btn-secondary" (click)="deleteQueueConfig(queueDetails)">
        {{ '$I18N_ARCHIVE_SUPERVISION_QUEUE_DETAILS.CONTROLS.DELETE' | translate }}
      </button>
      <mc-paginator
        [isLoading]="loading"
        [previousToken]="paginationInfo && paginationInfo.previousEntity"
        [nextToken]="paginationInfo && paginationInfo.nextEntity"
        (paginate)="loadQueueDetails($event.token)"
      >
      </mc-paginator>
    </div>
  </mc-extra-container>
  <mc-body-container>
    <mc-loader-full-container
      [isLoading]="loading"
      class="mc-layout-loader-full-container-aside mc-flex-scroll-vertical"
    >
      <div *ngIf="loading" style="height: 100px;"></div>
    </mc-loader-full-container>

    <div *ngIf="!loading && queueDetails !== undefined">
      <mc-view-queue-config-details [queueDetails]="queueDetails"></mc-view-queue-config-details>
    </div>
  </mc-body-container>
</mc-layout-aside-extra-container>
