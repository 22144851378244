"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const static_1 = require("@angular/upgrade/static");
const list_1 = require("./container/list");
angular
    .module('app-settings', [])
    .controller('ApplicationSettingsController', [
    '$stateParams',
    '$scope',
    function ($stateParams, $scope) {
        $scope.stateParams = $stateParams;
    }
])
    .directive('mcAppSettingsList', static_1.downgradeComponent({
    component: list_1.ApplicationSettingsListComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider.state('application-settings-list', {
            url: '/services/applicationsettings',
            data: {
                capabilities: 'Permission.OUTLOOK_READ && Temporary.Services.ApplicationSettings',
                isFixedLayout: false,
                checkProgress: false,
                tabReload: true,
                tabTitle: '$I18N_APPLICATION_SETTINGS.TAB_TITLE',
                tabId: 'app-settings',
                breadcrumbs: []
            },
            views: {
                main: {
                    template: '<mc-app-settings-list></mc-app-settings-list>',
                    controller: 'ApplicationSettingsController'
                }
            },
            params: { displayTab: 'applicationSettings' }
        });
    }
]);
