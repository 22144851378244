"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.isFindGroupsLoadingError = exports.isFindGroupsLDAPDataLoaded = exports.isFindGroupsCloudDataLoaded = exports.findGroups = void 0;
const store_1 = require("@ngrx/store");
const findGroupsReducer = require("../reducers/find-groups.reducer");
const archive_groups_1 = require("./archive-groups");
const ɵ0 = (state) => state.findGroups;
exports.ɵ0 = ɵ0;
const getSupervisionFindGroupsState = store_1.createSelector(archive_groups_1.getArchiveGroupsState, ɵ0);
exports.findGroups = store_1.createSelector(getSupervisionFindGroupsState, findGroupsReducer.findGroups);
exports.isFindGroupsCloudDataLoaded = store_1.createSelector(getSupervisionFindGroupsState, findGroupsReducer.isCloudDataLoaded);
exports.isFindGroupsLDAPDataLoaded = store_1.createSelector(getSupervisionFindGroupsState, findGroupsReducer.isLDAPDataLoaded);
exports.isFindGroupsLoadingError = store_1.createSelector(getSupervisionFindGroupsState, findGroupsReducer.hasGenericError);
