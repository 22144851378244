"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActionConfirmationComponent = void 0;
const core_1 = require("@angular/core");
class ActionConfirmationComponent {
    constructor() {
        this.onConfirmed = new core_1.EventEmitter();
    }
    onYes() {
        this.onConfirmed.emit(true);
    }
    onNo() {
        this.onConfirmed.emit(false);
    }
}
exports.ActionConfirmationComponent = ActionConfirmationComponent;
