<mc-modal-header [headerTitle]="getModalTitle()"></mc-modal-header>

<mc-modal-body *ngIf="hasConsented && editFlow !== true">
    <div class="revoke-permissions-header">
      <span class="fas fa-exclamation-triangle text-warning"></span>
      {{ '$I18N_CANCEL_CONFIRMATION_MODAL.REVOKE_PERMISSIONS.HEADER' | translate }}
    </div>
    <div>
      <p class="help-text">
        {{ '$I18N_CANCEL_CONFIRMATION_MODAL.REVOKE_PERMISSIONS.MESSAGE' | translate:{ provider: selectedProvider } }}
      </p>
    </div>
  </mc-modal-body>

<mc-modal-footer>
    <button type="button" class="btn btn-secondary " (click)="onNo()">
        {{ '$I18N_CANCEL_CONFIRMATION_MODAL.NO' | translate }}
    </button>

    <button type="button" class="btn btn-danger" (click)="onYes()">
        {{ '$I18N_CANCEL_CONFIRMATION_MODAL.YES' | translate }}
    </button>
</mc-modal-footer>
