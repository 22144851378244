"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PendoInitializeSettingsAction = exports.PendoGetInformationAction = exports.PENDO_INITIALIZE_SETTINGS = exports.PENDO_GET_INFORMATION = void 0;
exports.PENDO_GET_INFORMATION = '[PENDO] Get information';
exports.PENDO_INITIALIZE_SETTINGS = '[PENDO] Initialize setting';
class PendoGetInformationAction {
    constructor() {
        this.type = exports.PENDO_GET_INFORMATION;
    }
}
exports.PendoGetInformationAction = PendoGetInformationAction;
class PendoInitializeSettingsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.PENDO_INITIALIZE_SETTINGS;
    }
}
exports.PendoInitializeSettingsAction = PendoInitializeSettingsAction;
