"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IdentityAppsListComponent = void 0;
const table_store_1 = require("@mimecast-ui/table-store");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const appsActions = require("../../actions/apps/apps.actions");
const reducers_1 = require("../../reducers");
const notification_actions_1 = require("../../../components/notification/actions/notification.actions");
const app_aside_1 = require("../../models/apps/app-aside");
const dashboard_page_static_values_1 = require("../../containers/dashboard/dashboard-page.static-values");
class IdentityAppsListComponent extends table_store_1.TableBaseComponent {
    constructor(store, translateService) {
        super(store, 'IdentityAppsList');
        this.store = store;
        this.translateService = translateService;
        this.columnList = ['name', 'appsActions'];
        this.initialLoadingComplete$ = new rxjs_1.BehaviorSubject(false);
        this.isAppListEmpty$ = new rxjs_1.BehaviorSubject(false);
    }
    ngOnInit() {
        super.ngOnInit();
        this.selectedApp$ = this.store.select(reducers_1.getSelectedApp);
        this.isAddAppDisabled$ = this.store.select(reducers_1.getIsAddAppDisabled);
        this.isLoadResponseEmpty$.subscribe(isEmpty => {
            this.isAppListEmpty$.next(isEmpty);
        });
        this.loadingSubscription = this.isLoading$.subscribe((val) => {
            if (val === false) {
                this.store
                    .select(reducers_1.getAddedApp)
                    .pipe(operators_1.first())
                    .subscribe((addedApp) => {
                    if (addedApp !== undefined && addedApp !== null) {
                        const notification = new notification_actions_1.NotificationShowAction({
                            type: 'success',
                            config: {
                                msg: this.translateService.instant('$I18N_IDENTITY_CONTAINER.APPS_LIST.APP_ADDED_MESSAGE'),
                                duration: dashboard_page_static_values_1.identityWaitingPeriod
                            }
                        });
                        this.store.dispatch(notification);
                        this.tableStoreSubscription = this.tableData$
                            .pipe(operators_1.first(tableRows => !!tableRows), operators_1.delay(dashboard_page_static_values_1.identityWaitingPeriod))
                            .subscribe((tableRows) => {
                            const toBeHighlightedRow = tableRows.find(row => addedApp.id === row.id);
                            if (toBeHighlightedRow) {
                                const asidePanelPayload = {
                                    selectedApp: toBeHighlightedRow,
                                    mode: app_aside_1.AppDetailsAsideMode.EDIT
                                };
                                this.store.dispatch(new appsActions.IdentityViewAppAsideAction(asidePanelPayload));
                            }
                        });
                    }
                });
                if (!this.initialLoadingComplete$.value) {
                    this.initialLoadingComplete$.next(true);
                }
            }
        });
        this.isAddAppApiProcessing$ = this.store.select(reducers_1.getIsAddAppApiProcessing);
    }
    ngOnDestroy() {
        if (this.loadingSubscription) {
            this.loadingSubscription.unsubscribe();
        }
        if (this.tableStoreSubscription) {
            this.tableStoreSubscription.unsubscribe();
        }
    }
    addNewApp() {
        this.store.dispatch(new appsActions.IdentityAddAppAction());
    }
    viewAppDetails(app) {
        const viewPayload = {
            selectedApp: app,
            mode: app_aside_1.AppDetailsAsideMode.VIEW
        };
        this.store.dispatch(new appsActions.IdentityViewAppAsideAction(viewPayload));
    }
    editAppDetails(app) {
        const editPayload = {
            selectedApp: app,
            mode: app_aside_1.AppDetailsAsideMode.EDIT
        };
        this.store.dispatch(new appsActions.IdentityViewAppAsideAction(editPayload));
    }
    deleteApp(app) {
        this.store.dispatch(new appsActions.IdentityDeleteModalOpenAction(app));
    }
}
exports.IdentityAppsListComponent = IdentityAppsListComponent;
