"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ5 = exports.ɵ4 = exports.ɵ3 = exports.ɵ2 = exports.ɵ1 = exports.ɵ0 = exports.getFoldersCart = exports.getContentsPageNumber = exports.getContentsMetadata = exports.contentsIsSearching = exports.contentsIsInitialState = exports.contentsIsLoading = exports.getContents = exports.getRootFolder = exports.getParentFolder = exports.getFoldersMetadata = exports.foldersIsSearching = exports.foldersIsLoading = exports.getFolders = exports.reducers = void 0;
const store_1 = require("@ngrx/store");
const fromFolders = require("./folder.reducer");
const fromContents = require("./contents.reducer");
const fromFoldersCart = require("./folders-cart.reducer");
const node_factory_1 = require("app-new/components/tree/model/factories/node.factory");
exports.reducers = {
    folders: fromFolders.folderReducer,
    rootFolder: fromFolders.rootFolderReducer,
    contents: fromContents.folderContentReducer,
    foldersCart: fromFoldersCart.foldersCartReducer
};
const TREE_STATE = store_1.createFeatureSelector('treeState');
const ɵ0 = (state) => state.folders;
exports.ɵ0 = ɵ0;
const getFoldersState = store_1.createSelector(TREE_STATE, ɵ0);
const ɵ1 = (state) => state.rootFolder;
exports.ɵ1 = ɵ1;
const getRootFolderState = store_1.createSelector(TREE_STATE, ɵ1);
const ɵ2 = (state) => state.contents;
exports.ɵ2 = ɵ2;
const getContentsState = store_1.createSelector(TREE_STATE, ɵ2);
const ɵ3 = (state) => state.foldersCart;
exports.ɵ3 = ɵ3;
const getFoldersCartState = store_1.createSelector(TREE_STATE, ɵ3);
const ɵ4 = (state) => createFolderObjects(mapSelectedFolders(state.folders.folders, state.foldersCart.selectedFolders));
exports.ɵ4 = ɵ4;
exports.getFolders = store_1.createSelector(TREE_STATE, ɵ4);
exports.foldersIsLoading = store_1.createSelector(getFoldersState, fromFolders.isLoading);
exports.foldersIsSearching = store_1.createSelector(getFoldersState, fromFolders.isSearching);
exports.getFoldersMetadata = store_1.createSelector(getFoldersState, fromFolders.getMetadata);
exports.getParentFolder = store_1.createSelector(getFoldersState, fromFolders.getParentFolder);
exports.getRootFolder = store_1.createSelector(getRootFolderState, fromFolders.getRootFolder);
exports.getContents = store_1.createSelector(getContentsState, fromContents.getContents);
exports.contentsIsLoading = store_1.createSelector(getContentsState, fromContents.isLoading);
const ɵ5 = (state) => state.contents.initialState ||
    (state.folders.folders.length !== 0 &&
        isRootFolder(state.folders.folders[0], state.rootFolder.rootFolder));
exports.ɵ5 = ɵ5;
exports.contentsIsInitialState = store_1.createSelector(TREE_STATE, ɵ5);
exports.contentsIsSearching = store_1.createSelector(getContentsState, fromContents.isSearching);
exports.getContentsMetadata = store_1.createSelector(getContentsState, fromContents.getMetadata);
exports.getContentsPageNumber = store_1.createSelector(getContentsState, fromContents.getPageNumber);
exports.getFoldersCart = store_1.createSelector(getFoldersCartState, fromFoldersCart.getFolders);
function createFolderObjects(folders) {
    return folders.map(folder => {
        return node_factory_1.NodeFactory.FOLDER(folder);
    });
}
function mapSelectedFolders(folders, selectedFolders) {
    return folders.map((folder) => findFolderIndex(selectedFolders, folder) !== -1 ? Object.assign(Object.assign({}, folder), { selected: true }) : Object.assign({}, folder));
}
function findFolderIndex(stateFolders, folder) {
    return stateFolders
        .map((stateFolder) => stateFolder['id'] === folder['id'])
        .indexOf(true);
}
function isRootFolder(folder, rootFolder) {
    return folder['parentId'] === rootFolder['id'];
}
