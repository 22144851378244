<mc-layout-aside-extra-pagination-container [class.aside-safari]="isSafariBrowser" keyTitle="$I18N_SERVICES_URL_PROTECTION_LOGS_DETAILS.ASIDE_TITLE"
  [isLoading]="false" [showClose]="true" [hasNextInView]="hasNextInView$ | async"
  [hasPreviousInView]="hasPreviousInView$ | async" [indexInView]="indexInView$ | async"
  [currenPagination]="currentPagination$ | async" [filtersMetaData]="filtersMetaData$ | async" [isLoading]="false"
  (goToNextPage)="getNext()" (goToPrevPage)="getPrev()" (closeAside)="onClose()">

  <div row-actions>
    <div class="mc-header-row">
      <span>{{ selectedRow?.url }}</span>
    </div>
    <button class="btn btn-primary panel-half-margin-right"
            data-test="cy-log-report-btn"
            *mcCapabilities="canShowUrlReportingButton"
            (click)="showUrlReportingButton()"
            [disabled] ="!reportButtonEnabled">
      {{'$I18N_SERVICES_URL_PROTECTION_LOGS_DETAILS.REPORT_BUTTON' | translate}}
    </button>
    <button class="btn btn-default panel-half-margin-right"
      *mcCapabilities="canCreateEditAndDeleteCapExpression"
      data-test="cy-log-allow-btn"
      data-unit-test="unit-allow-url-action-btn"
      (click)="allowUrl(selectedRow)">
      {{'$I18N_SERVICES_URL_PROTECTION_LOGS_DETAILS.LOG_ALLOW_BUTTON' | translate}}
    </button>
    <button class="btn btn-default panel-half-margin-right"
      *mcCapabilities="canCreateEditAndDeleteCapExpression"
      data-test="cy-log-block-btn"
      data-unit-test="unit-block-url-action-btn"
      (click)="blockUrl(selectedRow)">
      {{'$I18N_SERVICES_URL_PROTECTION_LOGS_DETAILS.LOG_BLOCK_BUTTON' | translate}}
    </button>
  </div>
  <mc-body-container [ngClass]="{'no-padding': canCheckUrl}">
    <ng-container *ngIf="canCheckUrl; else details">
      <mc-layout-aside-tabs [hasTabs]="true" [isLoading]="false" class="custom-tabs" [class.tabs-safari]="isSafariBrowser">
        <mc-tab-group
          themeLight="true"
          resetActiveTabToFirst="true"
        >
          <mc-tab
            name="$I18N_SERVICES_URL_PROTECTION_LOGS_DETAILS.TAB_LABEL_DETAILS"
          >
            <mc-log-details [rowData]="selectedRow"></mc-log-details>
          </mc-tab>

          <mc-tab
            name="$I18N_SERVICES_URL_PROTECTION_LOGS_DETAILS.TAB_LABEL_CHECK_URLS"
          >
            <mc-log-check-url [rowData]="selectedRow"></mc-log-check-url>
          </mc-tab>
        </mc-tab-group>
      </mc-layout-aside-tabs>
    </ng-container>
  </mc-body-container>
</mc-layout-aside-extra-pagination-container>
<ng-template #details>
  <mc-log-details [rowData]="selectedRow"></mc-log-details>
</ng-template>
