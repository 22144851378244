"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TopQueuesBuilder = void 0;
const _ = require("lodash");
const top_queues_1 = require("../top-queues");
const builder_utils_1 = require("./builder-utils");
class TopQueuesBuilder {
    static build(topQueues, type) {
        const totalCount = TopQueuesBuilder.totalCountForTopQueues(topQueues);
        const newInLastSevenDays = TopQueuesBuilder.newInLastSevenDaysForTopQueues(topQueues);
        const pendingCount = TopQueuesBuilder.pendingCountForTopQueues(topQueues);
        const pendingPercentage = TopQueuesBuilder.pendingPercentageForTopQueues(pendingCount, totalCount);
        const partitioned = _.partition(topQueues, queue => !queue.failed);
        const validQueues = partitioned[0];
        const invalidQueues = partitioned[1];
        return new top_queues_1.TopQueues(topQueues, validQueues, invalidQueues, type, totalCount, newInLastSevenDays, pendingCount, pendingPercentage);
    }
    static totalCountForTopQueues(topQueues) {
        return topQueues.reduce(builder_utils_1.sumByTotalCounts, 0);
    }
    static newInLastSevenDaysForTopQueues(topQueues) {
        return topQueues.reduce(builder_utils_1.sumByNewInLastSevenDays, 0);
    }
    static pendingCountForTopQueues(topQueues) {
        return topQueues.reduce(builder_utils_1.sumByPendingCount, 0);
    }
    static pendingPercentageForTopQueues(pendingCount, totalCount) {
        return totalCount > 0 ? builder_utils_1.computePercentage(pendingCount, totalCount) : 0;
    }
}
exports.TopQueuesBuilder = TopQueuesBuilder;
