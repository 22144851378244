"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PolicyWizardComponent = void 0;
const actions = require("app-new/custom-domain-block/actions/configurations-table.action");
const wizard_base_component_1 = require("../wizard-base.component");
const custom_domain_block_service_1 = require("app-new/custom-domain-block/services/custom-domain-block.service");
const user_location_sidebar_actions_1 = require("app-new/components/user-location-selector/actions/user-location-sidebar.actions");
const reducers_1 = require("app-new/custom-domain-block/reducers");
class PolicyWizardComponent extends wizard_base_component_1.WizardBaseDirective {
    constructor(capabilitiesService, store, translateService, stateService) {
        super(capabilitiesService, custom_domain_block_service_1.CustomDomainBlockService.WIZARD_ID, store, translateService);
        this.capabilitiesService = capabilitiesService;
        this.store = store;
        this.translateService = translateService;
        this.stateService = stateService;
        this.policyDetails = this.stateService.$state.params.policyDetails;
        this.wipedRemovedRole = false;
        this.notificationsGroups$ = this.store.select(reducers_1.getNotifyGroups);
        this.profileGroups$ = this.store.select(reducers_1.getProfileGroup);
        this.redirectToUser$ = this.store.select(reducers_1.getRedirectEmail);
        this.redirectToGroup$ = this.store.select(reducers_1.getRedirectGroup);
    }
    get isEditMode() {
        return this.policyDetails !== undefined && this.policyDetails !== null;
    }
    get name() {
        return this.isEditMode && this.policyDetails.name ? this.policyDetails.name : '';
    }
    get description() {
        return this.isEditMode && this.policyDetails.description ? this.policyDetails.description : '';
    }
    get action() {
        if (!this.isEditMode || !this.policyDetails.action) {
            return '';
        }
        if (this.policyDetails.action === 'redirect') {
            return this.policyDetails.redirectToType === 'user' ? 'redirect_user' : 'redirect_group';
        }
        return this.policyDetails.action;
    }
    get redirectToUser() {
        return (this.isEditMode && this.policyDetails.redirectToUser) || undefined;
    }
    get redirectToGroup() {
        return ((this.isEditMode &&
            !this.wipedRemovedRole &&
            this.policyDetails.redirectToGroupId && {
            id: this.policyDetails.redirectToGroupId,
            description: this.policyDetails.redirectToGroupDescription
        }) ||
            undefined);
    }
    get notify() {
        return this.isEditMode && this.policyDetails.notify ? true : false;
    }
    get appliesTo() {
        return this.isEditMode && this.policyDetails.appliesTo && this.policyDetails.appliesTo.type
            ? this.policyDetails.appliesTo.type
            : 'everyone';
    }
    get group() {
        return this.isEditMode && this.policyDetails.appliesTo && this.policyDetails.appliesTo.group;
    }
    get notifyGroups() {
        return this.isEditMode && this.policyDetails.notifyGroupId
            ? [
                {
                    id: this.policyDetails.notifyGroupId,
                    description: this.policyDetails.notifyGroupDescription
                }
            ]
            : [];
    }
    get enabled() {
        return this.isEditMode ? !!this.policyDetails.enabled : true;
    }
    get wizardTitle() {
        if (!this.isEditMode) {
            return this.translateService.instant('$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.TAB_CREATE_TITLE');
        }
        return this.translateService.instant('$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.TAB_UPDATE_TITLE');
    }
    onSave() {
        if (!this.isEditMode) {
            this.store.dispatch(new actions.CreatePolicyAction(this.getPolicyConfig()));
        }
        else {
            this.store.dispatch(new actions.UpdatePolicyAction(this.getUpdatePolicyConfig()));
        }
    }
    getCancelConfirmationText() {
        if (!this.isEditMode) {
            return this.translateService.instant('$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.CANCEL_CREATE_CONFIRMATION');
        }
        return this.translateService.instant('$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.CANCEL_UPDATE_CONFIRMATION');
    }
    getSaveButtonText() {
        if (!this.isEditMode) {
            return this.translateService.instant('$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.CONTROLS.CREATE');
        }
        return this.translateService.instant('$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.CONTROLS.UPDATE');
    }
    openNotifyToSidePanel(value) {
        this.store.dispatch(new user_location_sidebar_actions_1.UserLocationAppliesToClose());
        this.store.dispatch(new user_location_sidebar_actions_1.UserLocationAppliesToOpen(value));
    }
    removeGroup() {
        this.store.dispatch(new actions.RemoveNotifyGroupAction());
    }
    removeProfileGroup() {
        this.store.dispatch(new actions.RemoveProfileGroupAction());
    }
    removeRedirectEmail() {
        this.store.dispatch(new actions.RemoveRedirectEmailAction());
    }
    removeRedirectGroup() {
        this.wipedRemovedRole = true;
        this.store.dispatch(new actions.RemoveRedirectGroupAction());
    }
    getRedirectType(data) {
        if (data['action'] === 'redirect_user' || data['action'] === 'redirect_group') {
            return 'redirect';
        }
        return data['action'];
    }
    getPolicyConfig() {
        const newPolicy = {
            definition: {
                name: this.wizardData.steps[1] &&
                    this.wizardData.steps[1].data['name'] &&
                    this.wizardData.steps[1].data['name'].trim(),
                description: this.wizardData.steps[1] &&
                    this.wizardData.steps[1].data['description'] &&
                    this.wizardData.steps[1].data['description'].trim(),
                action: this.getRedirectType(this.wizardData.steps[2] && this.wizardData.steps[2].data),
                notify: this.wizardData.steps[2] && this.wizardData.steps[2].data['notify']
            },
            policy: {
                description: this.wizardData.steps[1] &&
                    this.wizardData.steps[1].data['description'] &&
                    this.wizardData.steps[1].data['description'].trim(),
                enabled: (this.wizardData.steps[4] && this.wizardData.steps[4].data['enabled']) || false,
                from: {
                    type: 'EVERYONE'
                },
                to: {
                    type: this.wizardData.steps[3] && this.wizardData.steps[3].data['appliesTo']
                }
            }
        };
        if (this.wizardData.steps[2] &&
            this.wizardData.steps[2].data['notify'] &&
            this.wizardData.steps[2].data['notifyGroups']) {
            newPolicy.definition.notifyGroup = this.wizardData.steps[2].data['notifyGroups'][0].id;
        }
        if (this.wizardData.steps[3] && this.wizardData.steps[3].data['profileGroup']) {
            newPolicy.policy.to = Object.assign(Object.assign({}, newPolicy.policy.to), { groupId: this.wizardData.steps[3].data['profileGroup'].id });
        }
        if (this.wizardData.steps[2] &&
            this.wizardData.steps[2].data['action'] &&
            this.wizardData.steps[2].data['action'] === 'redirect_user' &&
            this.wizardData.steps[2].data['redirectToUser']) {
            newPolicy.definition.redirectToType = 'user';
            newPolicy.definition.redirectToUser = this.wizardData.steps[2].data['redirectToUser'];
        }
        if (this.wizardData.steps[2] &&
            this.wizardData.steps[2].data['action'] &&
            this.wizardData.steps[2].data['action'] === 'redirect_group' &&
            this.wizardData.steps[2].data['redirectToGroup']) {
            newPolicy.definition.redirectToType = 'group';
            newPolicy.definition.redirectToGroup = this.wizardData.steps[2].data['redirectToGroup'].id;
        }
        return newPolicy;
    }
    getUpdatePolicyConfig() {
        const newPolicy = {
            definitionId: this.policyDetails.definitionId,
            definition: {
                name: this.wizardData.steps[1] &&
                    this.wizardData.steps[1].data['name'] &&
                    this.wizardData.steps[1].data['name'].trim(),
                description: this.wizardData.steps[1] &&
                    this.wizardData.steps[1].data['description'] &&
                    this.wizardData.steps[1].data['description'].trim(),
                action: this.getRedirectType(this.wizardData.steps[2] && this.wizardData.steps[2].data),
                notify: this.wizardData.steps[2] && this.wizardData.steps[2].data['notify']
            },
            id: this.policyDetails.policyId,
            policy: {
                description: this.wizardData.steps[1] &&
                    this.wizardData.steps[1].data['description'] &&
                    this.wizardData.steps[1].data['description'].trim(),
                enabled: (this.wizardData.steps[4] && this.wizardData.steps[4].data['enabled']) || false,
                from: {
                    type: 'EVERYONE'
                },
                to: {
                    type: this.wizardData.steps[3] && this.wizardData.steps[3].data['appliesTo']
                }
            }
        };
        if (this.wizardData.steps[2] &&
            this.wizardData.steps[2].data['notify'] &&
            this.wizardData.steps[2].data['notifyGroups']) {
            newPolicy.definition.notifyGroup = this.wizardData.steps[2].data['notifyGroups'][0].id;
        }
        if (this.wizardData.steps[3] && this.wizardData.steps[3].data['profileGroup']) {
            newPolicy.policy.to = Object.assign(Object.assign({}, newPolicy.policy.to), { groupId: this.wizardData.steps[3].data['profileGroup'].id });
        }
        if (this.wizardData.steps[2] &&
            this.wizardData.steps[2].data['action'] &&
            this.wizardData.steps[2].data['action'] === 'redirect_user' &&
            this.wizardData.steps[2].data['redirectToUser']) {
            newPolicy.definition.redirectToType = 'user';
            newPolicy.definition.redirectToUser = this.wizardData.steps[2].data['redirectToUser'];
        }
        if (this.wizardData.steps[2] &&
            this.wizardData.steps[2].data['action'] &&
            this.wizardData.steps[2].data['action'] === 'redirect_group' &&
            this.wizardData.steps[2].data['redirectToGroup']) {
            newPolicy.definition.redirectToType = 'group';
            newPolicy.definition.redirectToGroup = this.wizardData.steps[2].data['redirectToGroup'].id;
        }
        return newPolicy;
    }
    ngOnDestroy() {
        this.removeGroup();
        this.removeProfileGroup();
        this.removeRedirectEmail();
    }
}
exports.PolicyWizardComponent = PolicyWizardComponent;
