"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const incident_component_1 = require("./incident.component");
angular
    .module('remediation.incident-view', [])
    .controller('RemediationIncidentController', [
    '$stateParams',
    '$scope',
    function ($stateParams, $scope) {
        $scope.stateParams = $stateParams;
    }
])
    .directive('mcRemediationIncident', static_1.downgradeComponent({
    component: incident_component_1.RemediationIncidentComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        const breadcrumbs = [];
        $stateProvider.state('remediation-incident', {
            url: '/remediation/incident/:incidentCode/:incidentId{separate:[//]*}:showMsaDeviceSummary',
            data: {
                isFixedLayout: false,
                checkProgress: false,
                tabReload: true,
                capabilities: 'Permission.THREAT_REMEDIATION_READ',
                tabTitle: '$I18N_REMEDIATION_CONTAINER.BREADCRUMB.APP_NAME',
                breadcrumbs,
                tabId: 'remediationDashboard'
            },
            views: {
                main: {
                    template: '<mc-remediation-incident></mc-remediation-incident>',
                    controller: 'RemediationIncidentController'
                }
            },
            onEnter: [
                '$stateParams',
                ($stateParams) => {
                    breadcrumbs.length = 0;
                    breadcrumbs.push('$I18N_NAVBAR_MENU_ITEM_SERVICES');
                    breadcrumbs.push({
                        label: '$I18N_REMEDIATION_CONTAINER.BREADCRUMB.APP_NAME',
                        stateName: 'remediation'
                    });
                    breadcrumbs.push({
                        label: '$I18N_REMEDIATION_CONTAINER.TABS.INCIDENTS',
                        stateName: 'remediation',
                        stateParams: { tabName: 'incidents' }
                    });
                    breadcrumbs.push({
                        label: '$I18N_REMEDIATION_PAGES.INCIDENT_VIEW.PAGE_TITLE',
                        values: { incidentCode: $stateParams.incidentCode }
                    });
                }
            ]
        });
    }
]);
