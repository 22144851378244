"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateDomainsEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const actions = require("../../actions/add-domains.actions");
const reducers = require("../../reducers/index");
const table_store_1 = require("@mimecast-ui/table-store");
const TABLE_ID = 'monitoredExternalDomain';
class CreateDomainsEffects {
    constructor(actions$, chameleonService, store, notificationService, tableStore) {
        this.actions$ = actions$;
        this.chameleonService = chameleonService;
        this.store = store;
        this.notificationService = notificationService;
        this.tableStore = tableStore;
        this.create$ = this.actions$.pipe(effects_1.ofType(actions.CREATE_DOMAINS), operators_1.withLatestFrom(this.store.select(reducers.getValidDomainsandComment)), operators_1.switchMap(([, validDomainsAndComment]) => {
            return this.chameleonService.createMonitoredExternalDomains(validDomainsAndComment).pipe(operators_1.map((createResponse) => {
                return createResponse.createdDomains.length
                    ? new actions.CreateDomainsSuccess(createResponse.createdDomains)
                    : new actions.CreateDomainsFailure();
            }), operators_1.catchError(() => rxjs_1.of(new actions.CreateDomainsFailure())));
        }));
        this.createSuccess$ = this.actions$.pipe(effects_1.ofType(actions.CREATE_DOMAINS_SUCCESS), operators_1.map((action) => action.createdDomains), operators_1.withLatestFrom(this.tableStore.select(table_store_1.tableReducer.getCurrentFilters(TABLE_ID)), this.tableStore.select(table_store_1.tableReducer.getCurrentPagination(TABLE_ID))), operators_1.mergeMap(([createdDomains, currentFilters, currentPagination]) => {
            return [
                new actions.CloseAside(),
                this.notificationService.newNotification({
                    type: 'success',
                    config: { msg: CreateDomainsEffects.getCreateSuccessNotificationText(createdDomains) }
                }),
                new table_store_1.LoadAction({
                    tableId: TABLE_ID,
                    pagination: { pageToken: '', pageSize: currentPagination.pageSize, pageNumber: 0 },
                    query: currentFilters
                })
            ];
        }));
        this.createFail$ = this.actions$.pipe(effects_1.ofType(actions.CREATE_DOMAINS_FAILURE), operators_1.mergeMap(() => {
            return [
                new actions.CloseAside(),
                this.notificationService.newNotification({
                    type: 'error',
                    config: {
                        msg: "Couldn't create domain"
                    }
                })
            ];
        }));
        this.showIsCreatingDomains$ = this.actions$.pipe(effects_1.ofType(actions.SHOW_IS_CREATING_DOMAINS), operators_1.withLatestFrom(this.store.select(reducers.getIsCreatingDomains)), operators_1.switchMap(([, isCreatingDomains]) => {
            if (isCreatingDomains) {
                return rxjs_1.of(this.notificationService.newNotification({
                    type: 'info',
                    config: { msg: 'Adding the domains... This might take a minute.' }
                }));
            }
            else {
                return rxjs_1.EMPTY;
            }
        }));
    }
    static getCreateSuccessNotificationText(domains) {
        return domains.length === 1
            ? `${domains[0].domainName} added.`
            : `${domains.length} domains added.`;
    }
}
__decorate([
    effects_1.Effect()
], CreateDomainsEffects.prototype, "create$", void 0);
__decorate([
    effects_1.Effect()
], CreateDomainsEffects.prototype, "createSuccess$", void 0);
__decorate([
    effects_1.Effect()
], CreateDomainsEffects.prototype, "createFail$", void 0);
__decorate([
    effects_1.Effect()
], CreateDomainsEffects.prototype, "showIsCreatingDomains$", void 0);
exports.CreateDomainsEffects = CreateDomainsEffects;
