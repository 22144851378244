<div class="mc-directory-sync-wizard-container">
  <mc-wizard-step-header [label]="'$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.HEADER' | translate: { integrationType: integrationType() }"></mc-wizard-step-header>
    <form
      class="form-horizontal"
      mcDefaultLabelClass="col-sm-4"
      mcDefaultControlContainerClass="col-sm-6"
      [formGroup]="form"
    >
      <mc-field label="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.SYNC_ENGINE_SITE"
                popoverPlacement="top"
                required
                helpPopoverContent="{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.SYNC_ENGINE_SITE_TOOLTIP' | translate }}"
                errorPrefix="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.SYNC_ENGINE_SITE_ERROR">
        <mc-select formControlName="mseSiteId"
                   [options]="mseSitesOptions"
                   [defaultValue]="form.value?.mseSiteId"
                   [extendClass]="isEditMode && !integration.hasServerTypeUpdatedOnEdit ? 'disabled' : ''"
        >
        </mc-select>
      </mc-field>

      <mc-field label="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.REPLICATE_DIFF_DOMAIN"
                popoverPlacement="top"
                class="mc-custom-label"
                helpPopoverContent="{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.REPLICATE_DIFF_DOMAIN_TOOLTIP' | translate }}">
        <mc-switch formControlName="replicateDiffDomain" role="switch" showLabel="true"></mc-switch>
      </mc-field>

      <mc-text-field *ngIf="isReplicateDiffDomainEnabled()" formControlName="host"
                     label="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.HOST"
                     placeholder="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.HOST"
                     popoverPlacement="top"
                     helpPopoverContent="{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.MSE_HOST_TOOLTIP' | translate }}"
                     errorPrefix="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.HOST_ERROR"
                     required
      >
      </mc-text-field>

      <mc-text-field *ngIf="isReplicateDiffDomainEnabled()" formControlName="userDn"
                     label="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.MSE_USER"
                     placeholder="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.MSE_USER"
                     popoverPlacement="top"
                     helpPopoverContent="{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.MSE_USER_TOOLTIP' | translate}}"
                     errorPrefix="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.MSE_USER_ERROR"
                     required
      >
      </mc-text-field>

      <mc-field *ngIf="isReplicateDiffDomainEnabled()" label="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.MSE_PASSWORD"
        popoverPlacement="top"
        helpPopoverContent="{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.MSE_PASSWORD_TOOLTIP' | translate }}"
        errorPrefix="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.PASSWORD_ERROR"
        required
      >
        <input class="form-control"
          type="password"
          name="password"
          placeholder="{{'$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.MSE_PASSWORD' | translate}}"
          formControlName="password"
          aria-label="password"
        />
      </mc-field>

      <mc-text-field *ngIf="isReplicateDiffDomainEnabled()" formControlName="rootDn"
                     label="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.ROOT_DN"
                     placeholder="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.ROOT_DN"
                     popoverPlacement="top"
                     helpPopoverContent="{{ '$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.MSE_ROOT_DN_TOOLTIP' | translate }}"
                     errorPrefix="$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.ROOT_DN_ERROR"
                     required
      >
      </mc-text-field>

    </form>
</div>
