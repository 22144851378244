<mc-inline-notification *ngIf="isRestored" class="mc-restored-inline-msg" [notification]="restoredInfoMessage"></mc-inline-notification>

<mc-table
  [isLoading]="isLoading$ | async"
  [data]="tableData$ | async"
  [columns]="columnList"
  [highlightedRow]="selectedRow$ | async"
  (rowClick)="onRowClick($event)"
  translatePrefix="$I18N_REMEDIATION_INCIDENT_VIEWER">

  <button *ngIf="isEditable && (incidentSummary$ | async)?.type === TYPE_NOTIFY_ONLY"
          class="mc-table-actions btn btn-primary"
          (click)="onActionClicked('remediate')">
    {{ '$I18N_REMEDIATION_INCIDENT_VIEWER.ACTIONS.REMEDIATE' | translate }}
  </button>

  <button *ngIf="isEditable && ((incidentSummary$ | async)?.type === TYPE_MANUAL || (incidentSummary$ | async)?.type === TYPE_AUTOMATIC || (incidentSummary$ | async)?.type === TYPE_MEP_CLAWBACK)"
          [disabled]="isRestored"
          class="mc-table-actions btn btn-primary"
          (click)="onActionClicked('restore')">
    {{ '$I18N_REMEDIATION_INCIDENT_VIEWER.ACTIONS.RESTORE' | translate }}
  </button>

  <button class="mc-table-actions btn btn-secondary" (click)="onExportClicked()">
    {{ '$I18N_REMEDIATION_INCIDENT_VIEWER.ACTIONS.EXPORT' | translate }}
  </button>

  <mc-filters
    (paginatorChange)="onPaginationChange($event)"
    [metadata]="metaData$ | async"
    [pageSizeOptions]="[50,100,300]"
    [showPagination]="true">
  </mc-filters>

  <mc-column key="from">
    <mc-body-cell *mcBodyCellDef="let row">{{ row.from || emptyValueDecorator }}</mc-body-cell>
  </mc-column>

  <mc-column-date key="received"></mc-column-date>

  <mc-column-actions key="action" mcShowColumnConfig>
    <mc-row-actions *mcRowActions="let row">
      <li mcRowAction="{{option.label}}" (click)="option.action(row)" *ngFor="let option of dropdownOptions"></li>
    </mc-row-actions>
  </mc-column-actions>

  <mc-empty-results keyTitle="$I18N_REMEDIATION_INCIDENT_VIEWER.NO_DATA"></mc-empty-results>

</mc-table>
