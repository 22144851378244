"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DropdownAutocompleteCheckboxesComponent = void 0;
const core_1 = require("@angular/core");
class DropdownAutocompleteCheckboxesComponent {
    constructor() {
        this.optionItems = [];
        this.onApplySelected = new core_1.EventEmitter();
        this.valuesToReturn = [];
        this.originalSections = [];
        this.allSelected = false;
        this.onChange = () => { };
        this.onTouch = () => { };
    }
    ngOnInit() {
        this.defaultLabel = this.label;
        this.deepCopyOptions();
    }
    ngOnChanges(changes) {
        if (changes.optionItems.currentValue.length > 0 &&
            this.originalSections.length === 0 &&
            !changes.optionItems.firstChange) {
            this.deepCopyOptions();
            this.updateLabel();
            if (this.label !== this.defaultLabel) {
                this.onApplySelected.emit(this.valuesToReturn);
            }
        }
    }
    applyValues() {
        this.valuesToReturn = [];
        this.updateLabel();
        this.onApplySelected.emit(this.valuesToReturn);
        this.dropdown.close();
    }
    updateLabel() {
        const separator = ', ';
        let newLabel = '';
        this.originalSections.forEach((optionItems) => {
            if (optionItems.isSelected === true) {
                this.valuesToReturn.push(optionItems.value);
                newLabel += optionItems.label + separator;
            }
        });
        if (this.valuesToReturn.length > 0) {
            this.label = newLabel.substr(0, newLabel.length - 2);
        }
        else {
            this.label = this.defaultLabel;
        }
    }
    selectUnselectAll(selected, $event) {
        this.optionItems.filter(item => (item.isSelected = selected));
        this.originalSections.filter(item => (item.isSelected = selected));
        this.allSelected = selected;
        this.deepCopyOptions();
        $event.stopPropagation(); // avoid dropdown window close
    }
    writeValue(obj) {
        this.onChange(obj);
    }
    registerOnChange(fn) {
        this.onChange = fn;
    }
    registerOnTouched(fn) {
        this.onTouch = fn;
    }
    onChecked(optionLabel, event) {
        if (event.target.checked) {
            if (this.optionItems.every(obj => obj.isSelected === true)) {
                this.allSelected = true;
            }
            if (this.originalSections.length === 0) {
                this.deepCopyOptions();
            }
            this.originalSections.find(thisOption => thisOption.label === optionLabel).isSelected = true;
        }
        else {
            this.allSelected = false;
            this.originalSections.find(thisOption => thisOption.label === optionLabel).isSelected = false;
        }
    }
    updateSelections(target) {
        if (this.originalSections.length > 0) {
            const allowedUpdatesFrom = ['SPAN', 'I', 'BUTTON'];
            if (target !== null && allowedUpdatesFrom.indexOf(target) !== -1) {
                this.allSelected = true;
                this.originalSections.forEach(thisOption => {
                    if (this.valuesToReturn.indexOf(thisOption.value) === -1) {
                        thisOption.isSelected = false;
                        this.allSelected = false;
                    }
                    else {
                        thisOption.isSelected = true;
                    }
                });
                this.optionItems = this.originalSections.map(filter => {
                    return Object.assign({}, filter);
                });
                this.searchValue = '';
            }
        }
    }
    searchFilterValue() {
        if (this.originalSections.length === 0) {
            this.deepCopyOptions();
        }
        this.optionItems = this.originalSections.map(filter => {
            return Object.assign({}, filter);
        });
        if (this.searchValue !== undefined || this.searchValue !== '') {
            this.optionItems = this.optionItems.filter(sectionFilter => {
                return sectionFilter.label.toUpperCase().includes(this.searchValue.toUpperCase());
            });
        }
        // reset the scroller to top
        const scroller = this.vScroller.filter((element, index) => index === 0);
        scroller[0].scrollToIndex(0);
    }
    showSearch() {
        return true;
        // enable search-box if more than 10 options
        // return this.originalSections.length > 10;
    }
    deepCopyOptions() {
        this.allSelected = true;
        this.optionItems.forEach((optionItem) => {
            if (optionItem.isSelected === undefined) {
                optionItem.isSelected = false;
            }
            if (optionItem.isSelected === false) {
                this.allSelected = false;
            }
        });
        this.originalSections = this.optionItems.map(filter => {
            return Object.assign({}, filter);
        });
    }
}
exports.DropdownAutocompleteCheckboxesComponent = DropdownAutocompleteCheckboxesComponent;
