"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IncidentsWidgetComponent = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const incident_1 = require("../../models/incident");
const reducers = require("../../reducers");
const constants_1 = require("../../utils/constants");
const incidents_widget_static_values_1 = require("./incidents-widget.static-values");
class IncidentsWidgetComponent {
    constructor(tabs, translateService, store, stateService) {
        this.tabs = tabs;
        this.translateService = translateService;
        this.store = store;
        this.stateService = stateService;
        this.columns = incidents_widget_static_values_1.COLUMNS;
        this.noValuePlaceHolder = constants_1.emptyValueDecorator;
    }
    ngOnInit() {
        this.incidents$ = this.store.select(reducers.getIncidentsPreview);
        this.getIncidentsLoading$ = this.store.select(reducers.getIncidentsLoading);
        this.getIncidentsLoadingError$ = this.store.select(reducers.getIncidentsLoadingError);
        this.incidentStats$ = this.store.select(reducers.getIncidentStats);
        this.areIncidentStatsLoading$ = this.store.select(reducers.areStatsLoading);
        this.incidentStatsLoadingError$ = this.store.select(reducers.getIncidentStatsLoadingError);
        this.isLoadingData$ = rxjs_1.combineLatest(this.getIncidentsLoading$, this.areIncidentStatsLoading$).pipe(operators_1.map(([incidents, stats]) => incidents || stats));
        this.hasError$ = rxjs_1.combineLatest(this.getIncidentsLoadingError$, this.incidentStatsLoadingError$).pipe(operators_1.map(([incidents, stats]) => incidents || stats));
        this.inlineNotificationConfigError = {
            status: 'error',
            msg: this.translateService.instant('$I18N_REMEDIATION.ERRORS.GENERIC')
        };
    }
    getTranslatedTypeName(incidentSummary) {
        const translatedKey = incident_1.IncidentsTypesDictionary[incidentSummary.type];
        const translationRoot = '$I18N_REMEDIATION_INCIDENT_WIDGET.INCIDENT_TYPE.';
        let translatedStr = this.translateService.instant(translationRoot + translatedKey);
        if (incidentSummary.removeFromDevice) {
            translatedStr =
                translatedStr +
                    this.translateService.instant('$I18N_REMEDIATION_INCIDENT_TAB.INCIDENT_TYPE.DEVICE');
        }
        return translatedStr;
    }
    openIncidentsTab() {
        if (!this.hasSearchTabEnabled) {
            this.tabs.tabClicked(1);
        }
        else {
            this.tabs.tabClicked(2);
        }
    }
    onRowClicked(row) {
        this.stateService.$state.go('remediation-incident', {
            incidentCode: row.code,
            incidentId: row.id,
            showMsaDeviceSummary: row.removeFromDevice
        });
    }
}
exports.IncidentsWidgetComponent = IncidentsWidgetComponent;
