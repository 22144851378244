"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PurgeMessagesModalEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const purgeActions = require("../actions/purge-messages.action");
const purge_messages_search_modal_component_1 = require("../components/purge-messages-search-modal/purge-messages-search-modal.component");
const purge_messages_confirm_modal_component_1 = require("../components/purge-messages-confirm-modal/purge-messages-confirm-modal.component");
class PurgeMessagesModalEffects {
    constructor(actions$, modalService, store) {
        this.actions$ = actions$;
        this.modalService = modalService;
        this.store = store;
        this.CANCEL_MODAL_ACTION = 'cancel';
        this.searchWaitingExperienceOpen = this.actions$.pipe(effects_1.ofType(purgeActions.PURGE_MESSAGES_COUNT_REQUEST), operators_1.tap(() => {
            this.setupLoadingModal();
        }));
        this.searchWaitingExperienceClose = this.actions$.pipe(effects_1.ofType(purgeActions.PURGE_MESSAGES_COUNT_SUCCESS, purgeActions.PURGE_MESSAGES_COUNT_FAIL), operators_1.tap(() => {
            if (this.loadingModalInstance) {
                this.loadingModalInstance.close();
            }
        }));
        this.confirmPurgeMessagesOpen = this.actions$.pipe(effects_1.ofType(purgeActions.PURGE_MESSAGES_COUNT_SUCCESS), operators_1.tap((action) => {
            this.setupConfirmModal(action.payload.count);
        }));
        this.confirmPurgeMessagesClose = this.actions$.pipe(effects_1.ofType(purgeActions.PURGE_MESSAGES_PURGE_CANCEL), operators_1.tap(() => {
            if (this.confirmModalInstance) {
                this.confirmModalInstance.close();
            }
        }));
    }
    setupConfirmModal(count) {
        this.confirmModalInstance = this.modalService.open(purge_messages_confirm_modal_component_1.PurgeMessagesConfirmModalComponent, {
            lightBackdrop: true,
            size: count > 0 ? 'lg' : 'md',
            hasBackdrop: true
        });
        rxjs_1.merge(this.confirmModalInstance.afterClose().pipe(operators_1.filter((confirm) => !!confirm && confirm.submitted), operators_1.map((confirm) => new purgeActions.PurgeMessagesConfirmPurge(confirm.reason))), this.confirmModalInstance.afterClose().pipe(operators_1.filter((result) => !!result && result === this.CANCEL_MODAL_ACTION), operators_1.map(() => new purgeActions.PurgeMessagesPurgeCancelAction()))).subscribe(action => {
            this.store.dispatch(action);
            this.confirmModalInstance = undefined;
        });
    }
    setupLoadingModal() {
        this.loadingModalInstance = this.modalService.open(purge_messages_search_modal_component_1.PurgeMessagesSearchModalComponent, {
            lightBackdrop: true,
            size: 'md',
            hasBackdrop: true
        });
        this.loadingModalInstance.afterClose().subscribe(result => {
            if (result === this.CANCEL_MODAL_ACTION) {
                this.store.dispatch(new purgeActions.PurgeMessagesSearchCancelAction());
            }
            this.loadingModalInstance = undefined;
        });
    }
}
__decorate([
    effects_1.Effect({ dispatch: false })
], PurgeMessagesModalEffects.prototype, "searchWaitingExperienceOpen", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], PurgeMessagesModalEffects.prototype, "searchWaitingExperienceClose", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], PurgeMessagesModalEffects.prototype, "confirmPurgeMessagesOpen", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], PurgeMessagesModalEffects.prototype, "confirmPurgeMessagesClose", void 0);
exports.PurgeMessagesModalEffects = PurgeMessagesModalEffects;
