"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.getAzureStandardIntegrationsToMigrate = exports.getMigratingConnector = exports.getConnectorsToMigrate = exports.getMigrationStatus = exports.getState = exports.getConnectorsState = exports.reducers = void 0;
const connectorsMigrationReducers = require("./connectors-migration.reducer");
const store_1 = require("@ngrx/store");
exports.reducers = {
    connectorsMigration: connectorsMigrationReducers.reducer
};
exports.getConnectorsState = store_1.createFeatureSelector('connectorsMigration');
const ɵ0 = (state) => state.connectorsMigration;
exports.ɵ0 = ɵ0;
exports.getState = store_1.createSelector(exports.getConnectorsState, ɵ0);
exports.getMigrationStatus = store_1.createSelector(exports.getState, connectorsMigrationReducers.getMigrationStatus);
exports.getConnectorsToMigrate = store_1.createSelector(exports.getState, connectorsMigrationReducers.getConnectorsToMigrate);
exports.getMigratingConnector = store_1.createSelector(exports.getState, connectorsMigrationReducers.getMigratingConnector);
exports.getAzureStandardIntegrationsToMigrate = store_1.createSelector(exports.getState, connectorsMigrationReducers.getAzureStandardIntegrationsToMigrate);
