"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RemediationApiService = void 0;
const moment = require("moment");
const operators_1 = require("rxjs/operators");
class RemediationApiService {
    constructor(http) {
        this.http = http;
        this.defaultFindGroupsPageSize = 1000;
    }
    forceStartDate(config) {
        if (!config || (config && !config.start)) {
            return Object.assign(Object.assign({}, config), { start: moment()
                    .subtract(15, 'year')
                    .startOf('day')
                    .format() });
        }
        return config;
    }
    getSettings() {
        return this.http
            .post('/api/ttp/remediation/get-settings', undefined)
            .pipe(operators_1.map((response) => response.first));
    }
    updateSettings(settings) {
        return this.http
            .post('/api/ttp/remediation/update-settings', settings)
            .pipe(operators_1.map(response => response.first));
    }
    getDeviceSettings() {
        return this.http
            .post('/api/swg/get-web-endpoint-settings', undefined)
            .pipe(operators_1.map((response) => response.first));
    }
    updateDeviceSettings(settings) {
        return this.http
            .post('/api/swg/update-web-endpoint-settings', settings)
            .pipe(operators_1.map(response => response.first));
    }
    createSettings(settings) {
        return this.http
            .post('/api/ttp/remediation/create-settings', settings)
            .pipe(operators_1.map(response => response.first));
    }
    findGroups(config) {
        // set default number of return items from search
        const requestPayload = Object.assign(Object.assign({}, config), { meta: {
                pagination: {
                    pageSize: this.defaultFindGroupsPageSize
                }
            } });
        return this.http.post('/api/directory/find-groups', requestPayload).pipe(operators_1.map((response) => response.first), operators_1.map((response) => response.folders));
    }
    getGroup(config) {
        return this.http.post('/api/directory/get-group', config).pipe(operators_1.map((response) => response.all), operators_1.map((response) => response));
    }
    findIncidents(request, forceStartDate = true) {
        if (forceStartDate) {
            request = this.forceStartDate(request);
        }
        return this.http.post('/api/ttp/remediation/find-incidents', request);
    }
    getIncidentStats() {
        return this.http.post('/api/ttp/remediation/get-incident-stats', undefined).pipe(operators_1.map((response) => response.first), operators_1.map((response) => response.incidentCount));
    }
    findLogs(config, forceStartDate = true) {
        if (forceStartDate) {
            config = this.forceStartDate(config);
        }
        return this.http.post('/api/ttp/remediation/find-logs', config);
    }
    search(config) {
        return this.http.post('/api/ttp/remediation/search', config).pipe(operators_1.map((response) => response.first), operators_1.map((response) => response.messages));
    }
    createIncident(config) {
        return this.http
            .post('/api/ttp/remediation/create-incident', config)
            .pipe(operators_1.map((response) => response.first));
    }
    incidentMessages(config) {
        return this.http.post('/api/ttp/remediation/get-incident-emails', config);
    }
    incidentSummary(config) {
        return this.http.post('/api/ttp/remediation/get-incident', config).pipe(operators_1.map((response) => response.first), operators_1.map((response) => response));
    }
    incidentRemediate(config) {
        return this.http.post('/api/ttp/remediation/remediate', config).pipe(operators_1.map((response) => {
            return response.meta.status === 200;
        }));
    }
    cancelDeviceRemediation(incidentCode) {
        return this.http
            .post('/api/swg/cancel-file-remediation', {
            incidentId: incidentCode
        })
            .pipe(operators_1.map((response) => {
            return response.meta.status === 200;
        }));
    }
    getMessageDetails(config) {
        return this.http
            .post('/api/ttp/remediation/get-message-detail', config)
            .pipe(operators_1.map((response) => response.first));
    }
    getFile(id) {
        const fileUrl = `/api/ttp/remediation/get-file/${id}?forceDownload=true`;
        return this.http
            .post('/download/get-file-link', { url: fileUrl })
            .pipe(operators_1.map((response) => response.body));
    }
    getExportData(config, forceStartDate = true) {
        if (forceStartDate) {
            config = this.forceStartDate(config);
        }
        return this.http
            .post(`/api/ttp/remediation/${config.endPoint}`, config)
            .pipe(operators_1.map((response) => response.first));
    }
    getIncidentFailureSummary(config) {
        return this.http
            .post('/api/ttp/remediation/get-incident-failure-summary', config)
            .pipe(operators_1.map((response) => response.first), operators_1.map((response) => response));
    }
}
exports.RemediationApiService = RemediationApiService;
