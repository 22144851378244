<div class="mc-held-queue-search-box">
  <form
    [formGroup]="formGroup"
    (ngSubmit)="search()"
    class="search-form"
    mcErrorPrefix="$I18N_MESSAGE_CENTER_HELD_QUEUE_SEARCH.ERROR."
    novalidate
  >
    <div class="panel-padding-bottom">
      <div class="row">
        <div class="col-xs-12 mc-form-fixed mc-form-height-fixed" formGroupName="heldQueueSearch">
          <div class="col-xs-2">
            <mc-text-field
              label="$I18N_MESSAGE_CENTER_HELD_QUEUE_SEARCH.LABEL_POLICY_INFO"
              class="mc-field-margin-right no-padding-horizontal"
              formControlName="policyInfo"
              errorPrefix="POLICYINFO."
              data-test="held_reason"
            >
            </mc-text-field>
          </div>
          <div class="col-xs-2">
            <mc-text-field
              label="$I18N_MESSAGE_CENTER_HELD_QUEUE_SEARCH.LABEL_SENDER"
              class="mc-field-margin-right no-padding-horizontal"
              placeholder="$I18N_MESSAGE_CENTER_HELD_QUEUE_SEARCH.PLACEHOLDER_EMAIL_DOMAIN"
              formControlName="sender"
              errorPrefix="SENDER."
              data-test="held_sender"
            >
            </mc-text-field>
          </div>
          <div class="col-xs-2">
            <mc-text-field
              label="$I18N_MESSAGE_CENTER_HELD_QUEUE_SEARCH.LABEL_RECIPIENT"
              class="mc-field-margin-right no-padding-horizontal"
              placeholder="$I18N_MESSAGE_CENTER_HELD_QUEUE_SEARCH.PLACEHOLDER_EMAIL_DOMAIN"
              formControlName="recipient"
              errorPrefix="RECIPIENT."
              data-test="held_recipient"
            >
            </mc-text-field>
          </div>
          <div class="col-xs-3">
            <mc-text-field
              label="$I18N_MESSAGE_CENTER_HELD_QUEUE_SEARCH.LABEL_SUBJECT"
              class="mc-field-margin-right no-padding-horizontal"
              formControlName="subject"
              errorPrefix="SUBJECT."
              data-test="held_subject"
            >
            </mc-text-field>
          </div>
          <div class="col-xs-3">
            <mc-date-range-picker
              label="$I18N_MESSAGE_CENTER_HELD_QUEUE_SEARCH.LABEL_DATE_RANGE"
              class="col-xs-5ths mc-field-margin-right no-padding-horizontal"
              formControlName="dateRange"
              [config]="dateRangeConfig"
              [horizontal]="true"
              [useCurrentMoment]="true"
            >
            </mc-date-range-picker>
          </div>
        </div>
      </div>

      <div class="col-xs-12 mc-form-padding-fixed">
        <button type="submit" class="btn btn-primary pull-right" [disabled]="!formGroup.valid">
          {{ '$I18N_MESSAGE_CENTER_HELD_QUEUE_SEARCH.SUBMIT_LABEL' | translate }}
        </button>
        <button (click)="clear()" class="btn btn-secondary pull-right clear-button">
          {{ '$I18N_MESSAGE_CENTER_HELD_QUEUE_SEARCH.CLEAR_LABEL' | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
