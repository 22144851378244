"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgUrlClassificationCheckComponent = void 0;
const core_1 = require("@angular/core");
const domain_validator_1 = require("app-new/components/validators/domain/domain.validator");
class SwgUrlClassificationCheckComponent {
    constructor(fb) {
        this.fb = fb;
        this.checkClassification = new core_1.EventEmitter();
        this.reportClassification = new core_1.EventEmitter();
    }
    ngOnInit() {
        this.classificationForm = this.fb.group({
            texturl: ['', domain_validator_1.apiDomainOrUrlAndQueryValidator]
        });
    }
    checkDomain() {
        const val = this.classificationForm.get('texturl').value.trim();
        this.checkClassification.emit(val);
    }
    report(category) {
        this.reportClassification.emit(category);
    }
    textUrlIsEmpty() {
        return this.classificationForm.get('texturl').value.length === 0;
    }
}
exports.SwgUrlClassificationCheckComponent = SwgUrlClassificationCheckComponent;
