"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HeldQueueSearchComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const date_range_config_1 = require("../../../../common/components/table-search-configs/date-range-config");
const utils_1 = require("../../../../common/utils");
class HeldQueueSearchComponent {
    constructor(heldQueueService) {
        this.heldQueueService = heldQueueService;
        this.dateRangeConfig = date_range_config_1.DateRangeConfig.config;
        this.onSearch = new core_1.EventEmitter();
        const formBuilder = new forms_1.FormBuilder();
        this.formGroup = formBuilder.group({
            heldQueueSearch: formBuilder.group({
                policyInfo: [null, [forms_1.Validators.maxLength(200)]],
                sender: [null, [forms_1.Validators.minLength(3), forms_1.Validators.maxLength(200)]],
                recipient: [null, [forms_1.Validators.minLength(3), forms_1.Validators.maxLength(200)]],
                subject: [null, [forms_1.Validators.minLength(3), forms_1.Validators.maxLength(200)]],
                dateRange: [date_range_config_1.DateRangeConfig.config.dateRanges[1], []]
            })
        }, { validator: HeldQueueSearchComponent.formValidator });
        this.heldQueueSearchSubscription = this.heldQueueService.heldQueueSearchByReasonValues$.subscribe((heldReason) => {
            this.formGroup.reset({
                heldQueueSearch: {
                    policyInfo: heldReason,
                    sender: null,
                    recipient: null,
                    subject: null,
                    dateRange: date_range_config_1.DateRangeConfig.config.dateRanges[3]
                }
            });
        });
        this.heldQueueSearchSubscription = this.heldQueueService.heldQueueSearchByGroupValues$.subscribe(() => {
            this.formGroup.reset({
                heldQueueSearch: {
                    policyInfo: null,
                    sender: null,
                    recipient: null,
                    subject: null,
                    dateRange: date_range_config_1.DateRangeConfig.config.dateRanges[3]
                }
            });
        });
    }
    static formValidator(formGroup) {
        return formGroup.status === 'VALID' ? null : { invalid: true };
    }
    search() {
        if (this.formGroup.valid) {
            this.onSearch.emit(this.toHoldQueueSearch(this.formGroup));
        }
    }
    clear() {
        this.formGroup.reset({ dateRange: date_range_config_1.DateRangeConfig.config.dateRanges[1] });
    }
    toHoldQueueSearch(form) {
        const search = form.value.heldQueueSearch;
        return Object.assign(Object.assign(Object.assign(Object.assign({ start: form.value.heldQueueSearch.dateRange.rangeFn().start.format(), end: form.value.heldQueueSearch.dateRange.rangeFn().end.format() }, (!!search.policyInfo ? { policyInfo: utils_1.stripNonPrintableChars(search.policyInfo) } : {})), (!!search.sender ? { sender: utils_1.stripNonPrintableChars(search.sender) } : {})), (!!search.recipient ? { recipient: utils_1.stripNonPrintableChars(search.recipient) } : {})), (!!search.subject ? { subject: utils_1.stripNonPrintableChars(search.subject) } : {}));
    }
    ngOnDestroy() {
        this.heldQueueSearchSubscription.unsubscribe();
    }
}
exports.HeldQueueSearchComponent = HeldQueueSearchComponent;
