"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationsEffects = void 0;
const effects_1 = require("@ngrx/effects");
const operators_1 = require("rxjs/operators");
const notification_actions_1 = require("../../components/notification/actions/notification.actions");
const notifications_1 = require("./notifications");
class NotificationsEffects {
    constructor(actions$) {
        this.actions$ = actions$;
        this.notifications$ = this.actions$.pipe(effects_1.ofType(...Object.keys(notifications_1.notifications)), operators_1.map((action) => {
            const notification = notifications_1.notifications[action.type];
            return new notification_actions_1.NotificationShowAction({
                config: { msg: notification.message },
                type: notification.type
            });
        }));
    }
}
__decorate([
    effects_1.Effect()
], NotificationsEffects.prototype, "notifications$", void 0);
exports.NotificationsEffects = NotificationsEffects;
