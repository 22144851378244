"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectorMigrationModalComponent = void 0;
class ConnectorMigrationModalComponent {
    constructor(translateService, overlayRef, data // public store: Store<reducers.DirectorySyncState>
    ) {
        this.translateService = translateService;
        this.overlayRef = overlayRef;
        this.data = data;
    }
    learnMore(url) {
        window.open(url, '_blank');
        this.overlayRef.close();
    }
    migrateNow(url) {
        this.overlayRef.close();
        if (this.data && this.data.sidePanel) {
            this.data.sidePanel.close();
        }
        window.open(url, '_self');
    }
    get headerTitle() {
        return ('⚠️  ' +
            this.translateService.instant('$I18N_DIRECTORY_SYNC_CONNECTOR_MIGRATION_MODAL.MIGRATE_TITLE'));
    }
    get message() {
        return this.translateService.instant('$I18N_DIRECTORY_SYNC_CONNECTOR_MIGRATION_MODAL.MIGRATE_MESSAGE');
    }
    get learnMoreButtonText() {
        return this.translateService.instant('$I18N_DIRECTORY_SYNC_CONNECTOR_MIGRATION_MODAL.LEARN_MORE_BUTTON');
    }
    get migrateNowButtonText() {
        return this.translateService.instant('$I18N_DIRECTORY_SYNC_CONNECTOR_MIGRATION_MODAL.MIGRATE_NOW_BUTTON');
    }
}
exports.ConnectorMigrationModalComponent = ConnectorMigrationModalComponent;
