
    <span *ngIf="hasMalware()">
      <i class="fas fa-biohazard text-danger" *ngIf="showIcon"></i>
      {{ '$I18N_SWG_THREAT_EVENT_TYPE.MALWARE' | translate }}
    </span>
    <span *ngIf="hasMalware() && hasPhishing()">+</span>
    <span *ngIf="hasPhishing()">
      <i class="fas fa-mail-bulk text-danger" *ngIf="showIcon"></i>
      {{ '$I18N_SWG_THREAT_EVENT_TYPE.PHISHING' | translate }}
    </span>
  