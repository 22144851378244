"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdvancedPhishing = exports.Sandboxing = exports.CustomerManagedUrls = exports.MachineLearning = exports.UrlReputationScan = exports.HtmlContentChecks = exports.DangerousFileExt = exports.AvScanning = exports.AdvancedSimilarityChecks = exports.SafeOrMalicious = exports.ScanDetailTypes = void 0;
var ScanDetailTypes;
(function (ScanDetailTypes) {
    ScanDetailTypes["ADVANCEDSIMILARITYCHECKS"] = "AdvancedSimilarityChecks";
    ScanDetailTypes["AVSCANNING"] = "AvScanning";
    ScanDetailTypes["MACHINELEARNING"] = "MachineLearning";
    ScanDetailTypes["DANGEROUSFILEEXTENSION"] = "DangerousFileExt";
    ScanDetailTypes["HTMLCONTENTCHECKS"] = "HtmlContentChecks";
    ScanDetailTypes["URLREPUTATIONSCAN"] = "UrlReputationScan";
    ScanDetailTypes["CUSTOMERMANAGEDURLS"] = "CustomerManagedUrls";
    ScanDetailTypes["SANDBOXING"] = "Sandboxing";
    ScanDetailTypes["ADVANCEDPHISHING"] = "AdvancedPhishing";
})(ScanDetailTypes = exports.ScanDetailTypes || (exports.ScanDetailTypes = {}));
var SafeOrMalicious;
(function (SafeOrMalicious) {
    SafeOrMalicious["SAFE"] = "Safe";
    SafeOrMalicious["MALICIOUS"] = "MALICIOUS";
})(SafeOrMalicious = exports.SafeOrMalicious || (exports.SafeOrMalicious = {}));
var AdvancedSimilarityChecks;
(function (AdvancedSimilarityChecks) {
    AdvancedSimilarityChecks["SIMILARDOMAINMATCH"] = "SimilarDomainMatch";
})(AdvancedSimilarityChecks = exports.AdvancedSimilarityChecks || (exports.AdvancedSimilarityChecks = {}));
var AvScanning;
(function (AvScanning) {
    AvScanning["AVSIGNATURENAME"] = "ContentCheck:AvSignatureName";
    AvScanning["AVSIGNATUREFILEEXTENSION"] = "ContentCheck:AvSignatureFileExts";
    AvScanning["AVSIGNATURECATEGORYTRIGGER"] = "ScannerInfo:CategoryTrigger";
})(AvScanning = exports.AvScanning || (exports.AvScanning = {}));
var DangerousFileExt;
(function (DangerousFileExt) {
    DangerousFileExt["DANGEROUSFILEMIMEDOWNLOAD"] = "ContentCheck:DangerousMimetypesUrlFileDownload";
    DangerousFileExt["DANGEROUSFILEDOWNLOAD"] = "ContentCheck:DangerousExtsUrlFileDownload";
    DangerousFileExt["BLOCKEDEXTENSIONS"] = "ContentCheck:ContentScannersBlocked";
})(DangerousFileExt = exports.DangerousFileExt || (exports.DangerousFileExt = {}));
var HtmlContentChecks;
(function (HtmlContentChecks) {
    HtmlContentChecks["INNEREXECUTABLE"] = "InnerExecutable";
})(HtmlContentChecks = exports.HtmlContentChecks || (exports.HtmlContentChecks = {}));
var UrlReputationScan;
(function (UrlReputationScan) {
    UrlReputationScan["TYPE"] = "Type";
    UrlReputationScan["URLBlOCK"] = "UrlBlock";
    UrlReputationScan["URL"] = "Url";
})(UrlReputationScan = exports.UrlReputationScan || (exports.UrlReputationScan = {}));
var MachineLearning;
(function (MachineLearning) {
    MachineLearning["ORIGNALURL"] = "Url";
    MachineLearning["TYPE"] = "Type";
    MachineLearning["BLOCKURL"] = "UrlBlock";
})(MachineLearning = exports.MachineLearning || (exports.MachineLearning = {}));
var CustomerManagedUrls;
(function (CustomerManagedUrls) {
    CustomerManagedUrls["BLOCKLISTED"] = "Blocklisted";
    CustomerManagedUrls["MANAGEDURLENTRY"] = "ManagedUrlEntry";
    CustomerManagedUrls["ALLOWLISTED"] = "Allowlisted";
})(CustomerManagedUrls = exports.CustomerManagedUrls || (exports.CustomerManagedUrls = {}));
var Sandboxing;
(function (Sandboxing) {
    Sandboxing["AVSIGNATURENAME"] = "ContentCheck:AvSignatureName";
    Sandboxing["MOREINFORMATION"] = "ContentCheck:FilescanFindingMoreInfo";
    Sandboxing["FILEEXTENSION"] = "ContentCheck:AvSignatureFileExts";
})(Sandboxing = exports.Sandboxing || (exports.Sandboxing = {}));
var AdvancedPhishing;
(function (AdvancedPhishing) {
    AdvancedPhishing["CREDENTIALTHEFTEVIDENCE"] = "CredentialTheftEvidence";
    AdvancedPhishing["CREDENTIALTHEFTTAGS"] = "CredentialTheftTags";
    AdvancedPhishing["CREDENTIALTHEFTBRANDS"] = "CredentialTheftBrands";
})(AdvancedPhishing = exports.AdvancedPhishing || (exports.AdvancedPhishing = {}));
