"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.mepLogPageDateRangePickerConfig = exports.replaceMilliSeconds = exports.formatSearchBy = exports.defaultSearchQuery = exports.exportOptions = exports.searchFilters = exports.filterSearchOptions = exports.filterKeyValues = exports.columnsAlwaysVisible = exports.columns = exports.MEP_LOG_TABLE_ID = exports.MAXIMUM_DATE_RANGE_IN_DAYS = void 0;
const moment = require("moment");
const computeRange = (numUnits, units) => {
    return function () {
        return {
            start: moment()
                .utc()
                .subtract(numUnits, units),
            end: moment().utc()
        };
    };
};
const ɵ0 = computeRange;
exports.ɵ0 = ɵ0;
exports.MAXIMUM_DATE_RANGE_IN_DAYS = 30;
exports.MEP_LOG_TABLE_ID = 'MepLogListTable';
exports.columns = [
    'fromAddress',
    'to',
    'pmr',
    'subject',
    'status',
    'createTime',
    'right-column'
];
exports.columnsAlwaysVisible = ['fromAddress', 'pmr'];
exports.filterKeyValues = {
    all: 'all',
    from_address: 'fromAddress',
    to_address: 'toAddress',
    flagged_to_address: 'flaggedToAddress',
    message_id: 'messageId',
    subject: 'subject'
};
exports.filterSearchOptions = [
    {
        label: '$18N_MEP_POLICY_LIST.TABLE.ALL',
        value: exports.filterKeyValues.all
    },
    {
        label: '$18N_MEP_LOG_LIST.TABLE.COLUMNS.FROMADDRESS',
        value: exports.filterKeyValues.from_address
    },
    {
        label: '$18N_MEP_LOG_LIST.TABLE.COLUMNS.TO',
        value: exports.filterKeyValues.to_address
    },
    {
        label: '$18N_MEP_LOG_LIST.TABLE.COLUMNS.SUBJECT',
        value: 'subject'
    },
    {
        label: '$18N_MEP_LOG_LIST.TABLE.COLUMNS.PMR',
        value: exports.filterKeyValues.flagged_to_address
    },
    {
        label: '$18N_MEP_LOG_LIST.TABLE.COLUMNS.MESSAGE_ID',
        value: exports.filterKeyValues.message_id
    }
];
exports.searchFilters = {
    name: 'status',
    filters: [
        'held',
        'no_action',
        'released_by_user',
        'dropped_by_user',
        'released_by_admin',
        'dropped_by_admin',
        'auto_released'
    ]
};
exports.exportOptions = [
    'from',
    'to',
    'potentially_misaddressed_recipients',
    'subject',
    'status',
    'date_time'
];
exports.defaultSearchQuery = {
    from: replaceMilliSeconds(moment().subtract(1, 'days')),
    to: replaceMilliSeconds(moment())
};
function formatSearchBy(filter) {
    const { fieldName, value } = filter;
    return { fieldName: exports.filterKeyValues[fieldName], value };
}
exports.formatSearchBy = formatSearchBy;
// SSW-35134: Removing milliseconds from the 'from' and 'to' timestamps as this causes invalid payload errors.
function replaceMilliSeconds(date) {
    return date.toISOString().replace(/\.(\d{3})Z$/g, 'Z');
}
exports.replaceMilliSeconds = replaceMilliSeconds;
exports.mepLogPageDateRangePickerConfig = {
    allowCustomRange: false,
    minDate: moment().subtract(exports.MAXIMUM_DATE_RANGE_IN_DAYS, 'days'),
    maxDate: moment(),
    dateRanges: [
        {
            id: '24_HOURS',
            label: '$I18N_MEP_LOG_LIST.DATE_RANGES.24_HOURS',
            rangeFn: computeRange(24, 'hours')
        },
        {
            id: '48_HOURS',
            label: '$I18N_MEP_LOG_LIST.DATE_RANGES.48_HOURS',
            rangeFn: computeRange(48, 'hours')
        },
        {
            id: '7_DAYS',
            label: '$I18N_MEP_LOG_LIST.DATE_RANGES.7_DAYS',
            rangeFn: computeRange(7, 'days')
        },
        {
            id: '14_DAYS',
            label: '$I18N_MEP_LOG_LIST.DATE_RANGES.14_DAYS',
            rangeFn: computeRange(14, 'days')
        },
        {
            id: '30_DAYS',
            label: '$I18N_MEP_LOG_LIST.DATE_RANGES.30_DAYS',
            rangeFn: computeRange(30, 'days')
        },
        {
            id: 'CUSTOM',
            label: '$I18N_MEP_LOG_LIST.DATE_RANGES.CUSTOM'
        }
    ],
    initialDateRange: 0
};
