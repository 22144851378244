
  <mc-layout-list-extra-container
    keyTitle=" {{ '$I18N_ENDPOINTS_LIST.PAGE_HEADER'  | translate}} "
    keyDescription="{{ hasDeviceBasedPoliciesSwitchEnabled ? '$I18N_ENDPOINTS_LIST.TABS.PAGE_DESCRIPTION' : '' }}"
  >
    <mc-help-container>
      <mc-help-link url="https://community.mimecast.com/docs/DOC-3186"></mc-help-link>
    </mc-help-container>

    <mc-body-container>
      <mc-tab-group *ngIf="hasDeviceBasedPoliciesSwitchEnabled" #tabs [initialActiveTabIndex]="displayTab">
        <div class="panel-padding-top">
          <mc-tab
            name="{{ '$I18N_ENDPOINTS_LIST.TABS.PROTECTED_DEVICES_TAB_TITLE' | translate }}"
          >
            <mc-swg-endpoints-list
              #endpointsList
              [hasEndpointsDetailsEnabled]="hasEndpointsDetailsEnabled"
              [hasPurgePermission]="hasPurgePermission$ | async"
              [hasDeviceBasedPoliciesSwitchEnabled]="hasDeviceBasedPoliciesSwitchEnabled"
              (purgeEndpointsList)="purgeEndpoints($event)"
              (tableRowClicked)="openSidepanel()"
            ></mc-swg-endpoints-list>
          </mc-tab>

          <mc-tab name="{{ '$I18N_ENDPOINTS_LIST.TABS.DEVICES_GROUPS_TAB_TITLE' | translate }}">
            <mc-swg-device-groups-list
              [hasEditPermission]="hasEditPermission$ | async"
              (tableRowClicked)="openDeviceGroupSidePanel()"
              (createNewDeviceGroup)="onCreateNewDeviceGroup()"
            (onDeleteDeviceGroup)="onDeleteDeviceGroup($event)"
              (onEditDeviceGroup)="onEditDeviceGroup($event)">

            </mc-swg-device-groups-list>
          </mc-tab>

        </div>
      </mc-tab-group>
      <div *ngIf="!hasDeviceBasedPoliciesSwitchEnabled">
        <mc-swg-endpoints-list
          #endpointsList
          [hasEndpointsDetailsEnabled]="hasEndpointsDetailsEnabled"
          [hasPurgePermission]="hasPurgePermission$ | async"
          [hasDeviceBasedPoliciesSwitchEnabled]="hasDeviceBasedPoliciesSwitchEnabled"
          (purgeEndpointsList)="purgeEndpoints($event)"
          (tableRowClicked)="openSidepanel()"
        ></mc-swg-endpoints-list>
      </div>
    </mc-body-container>
  </mc-layout-list-extra-container>



