"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LegacyTabConfigService = void 0;
const angular = require("angular");
class LegacyTabConfigService {
    setTabTitleAndBreadcrumbs(tab) {
        const injector = angular.element(document).injector();
        const $state = injector.get('$state');
        const $stateParams = injector.get('$stateParams');
        const navbarService = injector.get('navbarService');
        navbarService.findMenuItemByState($state.current, $stateParams).then(function (menu) {
            tab.title = menu.item.label ? menu.item.label : '';
            tab.breadcrumbs = getLegacyBreadCrumbs(menu);
        }, function () {
            tab.close();
            $state.go('administration-dashboard');
        });
        function getLegacyBreadCrumbs(menu) {
            return menu.breadcrumbs && menu.breadcrumbs.length
                ? menu.breadcrumbs
                : menu.item.state.data && menu.item.state.data.breadcrumbs
                    ? menu.item.state.data.breadcrumbs
                    : [];
        }
    }
}
exports.LegacyTabConfigService = LegacyTabConfigService;
