<mc-layout-list-extra-container
  keyTitle="$I18N_CONNECTORS_PAGE.TITLE"
  keyDescription="$I18N_CONNECTORS_PAGE.DESCRIPTION"
>
  <mc-body-container>
    <ng-container *ngIf="(migrationResolved$ | async) && ((migrationInProgress$ | async) || (isServerConnectorsDisabled$ | async)); else connectors">
      <mc-tab-group
        #tabGroup
        themeLight="true"
      >
        <mc-tab *ngIf="migrationInProgress$ | async" name="{{ '$I18N_CONNECTORS_PAGE.MIGRATE' | translate }}">
          <mc-tab-container>
            <mc-connectors-migration></mc-connectors-migration>
          </mc-tab-container>
        </mc-tab>
        <mc-tab name="{{ '$I18N_CONNECTORS_PAGE.CONNECTORS' | translate }}">
          <mc-tab-container>
            <ng-container *ngTemplateOutlet="connectors"></ng-container>
          </mc-tab-container>
        </mc-tab>
        <mc-tab *ngIf="(isServerConnectorsDisabled$ | async)" name="{{'$I18N_CONNECTORS_PAGE.EWS_CONNECTORS' | translate}}">
          <mc-server-connectors></mc-server-connectors>
        </mc-tab>
      </mc-tab-group>
    </ng-container>
  </mc-body-container>
</mc-layout-list-extra-container>

<ng-template #connectors>
  <ng-container
    *ngIf="(tableData$ | async)?.length || (currentFilters$ | async); else noConnectors"
  >
    <mc-connectors-table
      [tableData]="tableData$ | async"
      [metaData]="metaData$ | async"
      [isLoading]="isLoading$ | async"
      (onPaginationChange)="onPaginationChange($event)"
      (onFilterChange)="onFilterChange($event)"
      (onCreateConnector)="onCreateConnector()"
      (onDeleteConnector)="onDeleteConnector($event)"
    >
    </mc-connectors-table>
  </ng-container>
</ng-template>
<ng-template #noConnectors>
  <mc-no-connectors
    *ngIf="(tableData$ | async)?.length === 0 && !(currentFilters$ | async)"
    (onCreateConnector)="onCreateConnector()"
  ></mc-no-connectors>
</ng-template>
