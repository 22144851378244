"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectorsApiService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
class ConnectorsApiService {
    constructor(http) {
        this.http = http;
    }
    getOneDriveConnectors() {
        return this.http
            .post('/api/connectors/find-connectors', {
            meta: { pagination: { pageSize: 50 } },
            data: [
                {
                    filterBy: [
                        {
                            value: 'one_drive',
                            fieldName: 'app'
                        }
                    ]
                }
            ]
        })
            .pipe(operators_1.map((response) => {
            return response;
        }), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
}
exports.ConnectorsApiService = ConnectorsApiService;
