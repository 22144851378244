"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiApplicationFailureAction = exports.GetNewCredentialsSuccessAction = exports.GetNewCredentialsAction = exports.GetCredentialsSuccessOnCreateAction = exports.GetCredentialsSuccessAction = exports.GetCredentialsAction = exports.OpenKeyGenCompletedAction = exports.OpenKeyGenAction = exports.DeleteApiApplicationSuccessAction = exports.DeleteApiApplicationAction = exports.ConfirmDisableApiApplicationAction = exports.ConfirmDeleteApiApplicationAction = exports.DisableApiApplicationSuccessAction = exports.DisableApiApplicationAction = exports.EnableApiApplicationSuccessAction = exports.EnableApiApplicationAction = exports.OpenEditApiApplicationV3CompletedAction = exports.OpenEditApiApplicationV3Action = exports.OpenEditApiApplicationCompletedAction = exports.OpenEditApiApplicationAction = exports.CloseViewApiApplicationAction = exports.OpenViewApiApplicationAction = exports.OpenCreateApiApplicationCompletedAction = exports.OpenCreateApiV3ApplicationAction = exports.OpenCreateApiApplicationAction = exports.PageApiApplicationsCompletedAction = exports.PageApiApplicationsAction = exports.FilterApiApplicationsAction = exports.SearchGatewayApiApplicationsAction = exports.SearchLegacyApiApplicationsAction = exports.GetAllApiApplicationsSuccessAction = exports.GetAllApiApplicationsAction = exports.ApiGatewayListActionTypes = void 0;
var ApiGatewayListActionTypes;
(function (ApiGatewayListActionTypes) {
    ApiGatewayListActionTypes["GetAllApiApplications"] = "[ApiApplication/ApiGateway/List] Get All Api Applications";
    ApiGatewayListActionTypes["GetAllApiApplicationsSuccess"] = "[ApiApplication/ApiGateway/List] Get All Api Applications Success";
    ApiGatewayListActionTypes["SearchLegacyApiApplications"] = "[ApiApplication/ApiGateway/List] Search Legacy Api Applications";
    ApiGatewayListActionTypes["SearchGatewayApiApplications"] = "[ApiApplication/ApiGateway/List] Search Gateway Api Applications";
    ApiGatewayListActionTypes["FilterApiApplications"] = "[ApiApplication/ApiGateway/List] Filter Api Applications";
    ApiGatewayListActionTypes["PageApiApplications"] = "[ApiApplication/ApiGateway/List] Page Api Applications";
    ApiGatewayListActionTypes["PageApiApplicationsCompleted"] = "[ApiApplication/ApiGateway/List] Page Api Applications Completed";
    ApiGatewayListActionTypes["OpenCreateApiApplication"] = "[ApiApplication/ApiGateway/List] Open Create Api Application";
    ApiGatewayListActionTypes["OpenCreateApiApplicationCompleted"] = "[ApiApplication/ApiGateway/List] Open Create Api Application Completed";
    ApiGatewayListActionTypes["OpenCreateApiV3Application"] = "[ApiApplication/ApiGatewayV3/List] Open Create Api V3 Application";
    ApiGatewayListActionTypes["OpenViewApiApplication"] = "[ApiApplication/ApiGateway/List] Open View Api Application";
    ApiGatewayListActionTypes["CloseViewApiApplication"] = "[ApiApplication/ApiGateway/List] Close View Api Application";
    ApiGatewayListActionTypes["OpenEditApiApplication"] = "[ApiApplication/ApiGateway/List] Open Edit Api Application";
    ApiGatewayListActionTypes["OpenEditApiApplicationCompleted"] = "[ApiApplication/ApiGateway/List] Open Edit Api Application Completed";
    ApiGatewayListActionTypes["OpenEditApiApplicationV3"] = "[ApiApplication/ApiGateway/List] Open Edit Api Application V3";
    ApiGatewayListActionTypes["OpenEditApiApplicationV3Completed"] = "[ApiApplication/ApiGateway/List] Open Edit Api Application V3 Completed";
    ApiGatewayListActionTypes["EnableApiApplication"] = "[ApiApplication/ApiGateway/List] Enable Api Application";
    ApiGatewayListActionTypes["EnableApiApplicationSuccess"] = "[ApiApplication/ApiGateway/List] Enable Api Application Success";
    ApiGatewayListActionTypes["DisableApiApplication"] = "[ApiApplication/ApiGateway/List] Disable Api Application";
    ApiGatewayListActionTypes["DisableApiApplicationSuccess"] = "[ApiApplication/ApiGateway/List] Disable Api Application Success";
    ApiGatewayListActionTypes["ConfirmDeleteApiApplication"] = "[ApiApplication/ApiGateway/List] Confirm Delete Api Application";
    ApiGatewayListActionTypes["ConfirmDisableApiApplication"] = "[ApiApplication/ApiGateway/List] Confirm Disable Api Application";
    ApiGatewayListActionTypes["DeleteApiApplication"] = "[ApiApplication/ApiGateway/List] Delete Api Application";
    ApiGatewayListActionTypes["DeleteApiApplicationSuccess"] = "[ApiApplication/ApiGateway/List] Delete Api Application Success";
    ApiGatewayListActionTypes["OpenKeyGen"] = "[ApiApplication/ApiGateway/List] Open KeyGen";
    ApiGatewayListActionTypes["OpenKeyGenCompleted"] = "[ApiApplication/ApiGateway/List] Open KeyGen Completed";
    ApiGatewayListActionTypes["GetCredentials"] = "[ApiApplication/ApiGateway/List] Open Get Credentials";
    ApiGatewayListActionTypes["GetCredentialsSuccess"] = "[ApiApplication/ApiGateway/List] Open Get Credentials Completed";
    ApiGatewayListActionTypes["GetNewCredentials"] = "[ApiApplication/ApiGateway/List] Open New Credentials";
    ApiGatewayListActionTypes["GetNewCredentialsSuccess"] = "[ApiApplication/ApiGateway/List] Open New Credentials Completed";
    ApiGatewayListActionTypes["ApiApplicationFailure"] = "[ApiApplication/ApiGateway/List] Api Application Failure";
    ApiGatewayListActionTypes["GetCredentialsSuccessOnCreate"] = "[ApiApplication/ApiGateway/List] Open Get Credentials after creation of application";
})(ApiGatewayListActionTypes = exports.ApiGatewayListActionTypes || (exports.ApiGatewayListActionTypes = {}));
class GetAllApiApplicationsAction {
    constructor() {
        this.type = ApiGatewayListActionTypes.GetAllApiApplications;
    }
}
exports.GetAllApiApplicationsAction = GetAllApiApplicationsAction;
class GetAllApiApplicationsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayListActionTypes.GetAllApiApplicationsSuccess;
    }
}
exports.GetAllApiApplicationsSuccessAction = GetAllApiApplicationsSuccessAction;
class SearchLegacyApiApplicationsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayListActionTypes.SearchLegacyApiApplications;
    }
}
exports.SearchLegacyApiApplicationsAction = SearchLegacyApiApplicationsAction;
class SearchGatewayApiApplicationsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayListActionTypes.SearchGatewayApiApplications;
    }
}
exports.SearchGatewayApiApplicationsAction = SearchGatewayApiApplicationsAction;
class FilterApiApplicationsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayListActionTypes.FilterApiApplications;
    }
}
exports.FilterApiApplicationsAction = FilterApiApplicationsAction;
class PageApiApplicationsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayListActionTypes.PageApiApplications;
    }
}
exports.PageApiApplicationsAction = PageApiApplicationsAction;
class PageApiApplicationsCompletedAction {
    constructor() {
        this.type = ApiGatewayListActionTypes.PageApiApplicationsCompleted;
    }
}
exports.PageApiApplicationsCompletedAction = PageApiApplicationsCompletedAction;
class OpenCreateApiApplicationAction {
    constructor() {
        this.type = ApiGatewayListActionTypes.OpenCreateApiApplication;
    }
}
exports.OpenCreateApiApplicationAction = OpenCreateApiApplicationAction;
class OpenCreateApiV3ApplicationAction {
    constructor() {
        this.type = ApiGatewayListActionTypes.OpenCreateApiV3Application;
    }
}
exports.OpenCreateApiV3ApplicationAction = OpenCreateApiV3ApplicationAction;
class OpenCreateApiApplicationCompletedAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayListActionTypes.OpenCreateApiApplicationCompleted;
    }
}
exports.OpenCreateApiApplicationCompletedAction = OpenCreateApiApplicationCompletedAction;
class OpenViewApiApplicationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayListActionTypes.OpenViewApiApplication;
    }
}
exports.OpenViewApiApplicationAction = OpenViewApiApplicationAction;
class CloseViewApiApplicationAction {
    constructor() {
        this.type = ApiGatewayListActionTypes.CloseViewApiApplication;
    }
}
exports.CloseViewApiApplicationAction = CloseViewApiApplicationAction;
class OpenEditApiApplicationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayListActionTypes.OpenEditApiApplication;
    }
}
exports.OpenEditApiApplicationAction = OpenEditApiApplicationAction;
class OpenEditApiApplicationCompletedAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayListActionTypes.OpenEditApiApplicationCompleted;
    }
}
exports.OpenEditApiApplicationCompletedAction = OpenEditApiApplicationCompletedAction;
class OpenEditApiApplicationV3Action {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayListActionTypes.OpenEditApiApplicationV3;
    }
}
exports.OpenEditApiApplicationV3Action = OpenEditApiApplicationV3Action;
class OpenEditApiApplicationV3CompletedAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayListActionTypes.OpenEditApiApplicationV3Completed;
    }
}
exports.OpenEditApiApplicationV3CompletedAction = OpenEditApiApplicationV3CompletedAction;
class EnableApiApplicationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayListActionTypes.EnableApiApplication;
    }
}
exports.EnableApiApplicationAction = EnableApiApplicationAction;
class EnableApiApplicationSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayListActionTypes.EnableApiApplicationSuccess;
    }
}
exports.EnableApiApplicationSuccessAction = EnableApiApplicationSuccessAction;
class DisableApiApplicationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayListActionTypes.DisableApiApplication;
    }
}
exports.DisableApiApplicationAction = DisableApiApplicationAction;
class DisableApiApplicationSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayListActionTypes.DisableApiApplicationSuccess;
    }
}
exports.DisableApiApplicationSuccessAction = DisableApiApplicationSuccessAction;
class ConfirmDeleteApiApplicationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayListActionTypes.ConfirmDeleteApiApplication;
    }
}
exports.ConfirmDeleteApiApplicationAction = ConfirmDeleteApiApplicationAction;
class ConfirmDisableApiApplicationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayListActionTypes.ConfirmDisableApiApplication;
    }
}
exports.ConfirmDisableApiApplicationAction = ConfirmDisableApiApplicationAction;
class DeleteApiApplicationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayListActionTypes.DeleteApiApplication;
    }
}
exports.DeleteApiApplicationAction = DeleteApiApplicationAction;
class DeleteApiApplicationSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayListActionTypes.DeleteApiApplicationSuccess;
    }
}
exports.DeleteApiApplicationSuccessAction = DeleteApiApplicationSuccessAction;
class OpenKeyGenAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayListActionTypes.OpenKeyGen;
    }
}
exports.OpenKeyGenAction = OpenKeyGenAction;
class OpenKeyGenCompletedAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayListActionTypes.OpenKeyGenCompleted;
    }
}
exports.OpenKeyGenCompletedAction = OpenKeyGenCompletedAction;
class GetCredentialsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayListActionTypes.GetCredentials;
    }
}
exports.GetCredentialsAction = GetCredentialsAction;
class GetCredentialsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayListActionTypes.GetCredentialsSuccess;
    }
}
exports.GetCredentialsSuccessAction = GetCredentialsSuccessAction;
class GetCredentialsSuccessOnCreateAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayListActionTypes.GetCredentialsSuccessOnCreate;
    }
}
exports.GetCredentialsSuccessOnCreateAction = GetCredentialsSuccessOnCreateAction;
class GetNewCredentialsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayListActionTypes.GetNewCredentials;
    }
}
exports.GetNewCredentialsAction = GetNewCredentialsAction;
class GetNewCredentialsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayListActionTypes.GetNewCredentialsSuccess;
    }
}
exports.GetNewCredentialsSuccessAction = GetNewCredentialsSuccessAction;
class ApiApplicationFailureAction {
    constructor() {
        this.type = ApiGatewayListActionTypes.ApiApplicationFailure;
    }
}
exports.ApiApplicationFailureAction = ApiApplicationFailureAction;
