'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./secure-messaging-list.controller"); //services.monitoring.secure-messaging.list.controller
require("app/services/monitoring/monitoring.service"); //monitoring.service
require("app/components/log-detail-navigator/log-detail-navigator");
// html and css
require("./secure-messaging-list.less");
require("./secure-messaging-list.tpl.html");
require("./slides/secure-messaging-log-detail.tpl.html");
angular.module('services.monitoring.secure-messaging.list', [
    'services.monitoring.secure-messaging.list.controller',
    'monitoring.service',
    'components.log-detail-navigator'
])
    .config(['$stateProvider', function ($stateProvider) {
        $stateProvider
            .state('secure-messaging-list', {
            url: '/services/monitoring/secure-messaging-list',
            data: {
                tabId: 'secure-messaging-list-tabid',
                tabTitle: '$I18N_ADMINISTRATION_MENU_SECURE_MESSAGING_LIST_MENU_TAB',
                tabHide: false,
                tabReload: false
            },
            views: {
                'main': {
                    templateUrl: 'services/monitoring/secure-messaging/secure-messaging-list/secure-messaging-list.tpl.html'
                }
            }
        });
    }]);
