"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MepLogSidebarAssociatedPolicyComponent = void 0;
class MepLogSidebarAssociatedPolicyComponent {
    constructor() {
        this.associatedPolicyDetails = {};
    }
    set name(value) {
        this.associatedPolicyDetails.name = value;
    }
    set description(value) {
        this.associatedPolicyDetails.description = value;
    }
}
exports.MepLogSidebarAssociatedPolicyComponent = MepLogSidebarAssociatedPolicyComponent;
