"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SetRuleStateSuccessAction = exports.SetRuleStateFailAction = exports.SetRuleStateAction = exports.CloseSelectQueueSidePanelAction = exports.ClearSelectedQueueAction = exports.AddSelectedQueueAction = exports.GetQueuesFailAction = exports.GetQueuesSuccessAction = exports.GetQueuesAction = exports.CloseSelectLexiconSidePanelAction = exports.ClearSelectedLexiconAction = exports.AddSelectedLexiconAction = exports.GetLexiconsFailAction = exports.GetLexiconsSuccessAction = exports.GetLexiconsAction = exports.UpdateActionFail = exports.UpdateActionSuccess = exports.UpdateAction = exports.GetActionFail = exports.CreateActionSuccess = exports.CreateActionFail = exports.CreateAction = exports.RuleWizardActions = void 0;
var RuleWizardActions;
(function (RuleWizardActions) {
    RuleWizardActions["CREATE"] = "[SupervisionRuleWizard] Create";
    RuleWizardActions["CREATE_SUCCESS"] = "[SupervisionRuleWizard] Create Success";
    RuleWizardActions["CREATE_FAIL"] = "[SupervisionRuleWizard] Create Fail";
    RuleWizardActions["GET_FAIL"] = "[SupervisionRuleWizard] Get Fail";
    RuleWizardActions["UPDATE"] = "[SupervisionRuleWizard] Update";
    RuleWizardActions["UPDATE_SUCCESS"] = "[SupervisionRuleWizard] Update Success";
    RuleWizardActions["UPDATE_FAIL"] = "[SupervisionRuleWizard] Update Fail";
    RuleWizardActions["GET_LEXICONS"] = "[SupervisionRuleWizard] Get Lexicons";
    RuleWizardActions["GET_LEXICONS_SUCCESS"] = "[SupervisionRuleWizard] Get Lexicons Success";
    RuleWizardActions["GET_LEXICONS_FAIL"] = "[SupervisionRuleWizard] Get Lexicons Fail";
    RuleWizardActions["ADD_SELECTED_LEXICON"] = "[SupervisionRuleWizard] Add Selected Lexicon";
    RuleWizardActions["CLEAR_SELECTED_LEXICON"] = "[SupervisionRuleWizard] Clear Selected Lexicon";
    RuleWizardActions["CLOSE_SELECT_LEXICON_PANEL"] = "[SupervisionRuleWizard] Close Select Lexicon Side Panel";
    RuleWizardActions["GET_QUEUES"] = "[SupervisionRuleWizard] Get Queues";
    RuleWizardActions["GET_QUEUES_SUCCESS"] = "[SupervisionRuleWizard] Get Queues Success";
    RuleWizardActions["GET_QUEUES_FAIL"] = "[SupervisionRuleWizard] Get Queues Fail";
    RuleWizardActions["ADD_SELECTED_QUEUE"] = "[SupervisionRuleWizard] Add Selected Queue";
    RuleWizardActions["CLEAR_SELECTED_QUEUE"] = "[SupervisionRuleWizard] Clear Selected Queue";
    RuleWizardActions["CLOSE_SELECT_QUEUE_PANEL"] = "[SupervisionRuleWizard] Close Select Queue Side Panel";
    RuleWizardActions["SET_STATE"] = "[SupervisionRuleWizard] Set Rule State";
    RuleWizardActions["SET_STATE_FAIL"] = "[SupervisionRuleWizard] Set Rule State Fail";
    RuleWizardActions["SET_STATE_SUCCESS"] = "[SupervisionRuleWizard] Set Rule State Success";
})(RuleWizardActions = exports.RuleWizardActions || (exports.RuleWizardActions = {}));
class CreateAction {
    constructor(paypload) {
        this.paypload = paypload;
        this.type = RuleWizardActions.CREATE;
    }
}
exports.CreateAction = CreateAction;
class CreateActionFail {
    constructor(message, failure) {
        this.message = message;
        this.failure = failure;
        this.type = RuleWizardActions.CREATE_FAIL;
    }
}
exports.CreateActionFail = CreateActionFail;
class CreateActionSuccess {
    constructor(paypload) {
        this.paypload = paypload;
        this.type = RuleWizardActions.CREATE_SUCCESS;
    }
}
exports.CreateActionSuccess = CreateActionSuccess;
class GetActionFail {
    constructor(payload) {
        this.payload = payload;
        this.type = RuleWizardActions.GET_FAIL;
    }
}
exports.GetActionFail = GetActionFail;
class UpdateAction {
    constructor(ruleId, rule) {
        this.ruleId = ruleId;
        this.rule = rule;
        this.type = RuleWizardActions.UPDATE;
    }
}
exports.UpdateAction = UpdateAction;
class UpdateActionSuccess {
    constructor(message) {
        this.message = message;
        this.type = RuleWizardActions.UPDATE_SUCCESS;
    }
}
exports.UpdateActionSuccess = UpdateActionSuccess;
class UpdateActionFail {
    constructor(message, failure) {
        this.message = message;
        this.failure = failure;
        this.type = RuleWizardActions.UPDATE_FAIL;
    }
}
exports.UpdateActionFail = UpdateActionFail;
class GetLexiconsAction {
    constructor() {
        this.type = RuleWizardActions.GET_LEXICONS;
    }
}
exports.GetLexiconsAction = GetLexiconsAction;
class GetLexiconsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = RuleWizardActions.GET_LEXICONS_SUCCESS;
    }
}
exports.GetLexiconsSuccessAction = GetLexiconsSuccessAction;
class GetLexiconsFailAction {
    constructor(payload) {
        this.payload = payload;
        this.type = RuleWizardActions.GET_LEXICONS_FAIL;
    }
}
exports.GetLexiconsFailAction = GetLexiconsFailAction;
class AddSelectedLexiconAction {
    constructor(selectedLexicon) {
        this.selectedLexicon = selectedLexicon;
        this.type = RuleWizardActions.ADD_SELECTED_LEXICON;
    }
}
exports.AddSelectedLexiconAction = AddSelectedLexiconAction;
class ClearSelectedLexiconAction {
    constructor() {
        this.type = RuleWizardActions.CLEAR_SELECTED_LEXICON;
    }
}
exports.ClearSelectedLexiconAction = ClearSelectedLexiconAction;
class CloseSelectLexiconSidePanelAction {
    constructor() {
        this.type = RuleWizardActions.CLOSE_SELECT_LEXICON_PANEL;
    }
}
exports.CloseSelectLexiconSidePanelAction = CloseSelectLexiconSidePanelAction;
class GetQueuesAction {
    constructor(filterBy) {
        this.filterBy = filterBy;
        this.type = RuleWizardActions.GET_QUEUES;
    }
}
exports.GetQueuesAction = GetQueuesAction;
class GetQueuesSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = RuleWizardActions.GET_QUEUES_SUCCESS;
    }
}
exports.GetQueuesSuccessAction = GetQueuesSuccessAction;
class GetQueuesFailAction {
    constructor(payload) {
        this.payload = payload;
        this.type = RuleWizardActions.GET_QUEUES_FAIL;
    }
}
exports.GetQueuesFailAction = GetQueuesFailAction;
class AddSelectedQueueAction {
    constructor(selectedQueue) {
        this.selectedQueue = selectedQueue;
        this.type = RuleWizardActions.ADD_SELECTED_QUEUE;
    }
}
exports.AddSelectedQueueAction = AddSelectedQueueAction;
class ClearSelectedQueueAction {
    constructor() {
        this.type = RuleWizardActions.CLEAR_SELECTED_QUEUE;
    }
}
exports.ClearSelectedQueueAction = ClearSelectedQueueAction;
class CloseSelectQueueSidePanelAction {
    constructor() {
        this.type = RuleWizardActions.CLOSE_SELECT_QUEUE_PANEL;
    }
}
exports.CloseSelectQueueSidePanelAction = CloseSelectQueueSidePanelAction;
class SetRuleStateAction {
    constructor(ruleId, state) {
        this.ruleId = ruleId;
        this.state = state;
        this.type = RuleWizardActions.SET_STATE;
    }
}
exports.SetRuleStateAction = SetRuleStateAction;
class SetRuleStateFailAction {
    constructor(message, failure) {
        this.message = message;
        this.failure = failure;
        this.type = RuleWizardActions.SET_STATE_FAIL;
    }
}
exports.SetRuleStateFailAction = SetRuleStateFailAction;
class SetRuleStateSuccessAction {
    constructor(message) {
        this.message = message;
        this.type = RuleWizardActions.SET_STATE_SUCCESS;
    }
}
exports.SetRuleStateSuccessAction = SetRuleStateSuccessAction;
