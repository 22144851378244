"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportEmailFailAction = exports.ExportEmailSuccessAction = exports.ExportEmailAction = exports.ExportCancelAction = exports.ExportExportAction = exports.ExportShowAction = exports.EXPORT_EMAIL_FAIL = exports.EXPORT_EMAIL_SUCCESS = exports.EXPORT_EMAIL = exports.EXPORT_CANCEL = exports.EXPORT_EXPORT = exports.EXPORT_SHOW = void 0;
exports.EXPORT_SHOW = '[EXPORT] Show';
exports.EXPORT_EXPORT = '[EXPORT] Export';
exports.EXPORT_CANCEL = '[EXPORT] Cancel';
exports.EXPORT_EMAIL = '[EXPORT] Email';
exports.EXPORT_EMAIL_SUCCESS = '[EXPORT] Email Success';
exports.EXPORT_EMAIL_FAIL = '[EXPORT] Email Fail';
class ExportShowAction {
    constructor(logsPageConfig) {
        this.logsPageConfig = logsPageConfig;
        this.type = exports.EXPORT_SHOW;
    }
}
exports.ExportShowAction = ExportShowAction;
class ExportExportAction {
    constructor() {
        this.type = exports.EXPORT_EXPORT;
    }
}
exports.ExportExportAction = ExportExportAction;
class ExportCancelAction {
    constructor() {
        this.type = exports.EXPORT_CANCEL;
    }
}
exports.ExportCancelAction = ExportCancelAction;
class ExportEmailAction {
    constructor(exportPayloadData) {
        this.exportPayloadData = exportPayloadData;
        this.type = exports.EXPORT_EMAIL;
    }
}
exports.ExportEmailAction = ExportEmailAction;
class ExportEmailSuccessAction {
    constructor() {
        this.type = exports.EXPORT_EMAIL_SUCCESS;
    }
}
exports.ExportEmailSuccessAction = ExportEmailSuccessAction;
class ExportEmailFailAction {
    constructor() {
        this.type = exports.EXPORT_EMAIL_FAIL;
    }
}
exports.ExportEmailFailAction = ExportEmailFailAction;
