<mc-layout-list-table
  keyTitle="$18N_BI_POLICY_LIST.TITLE"
  extendClass="mc-create-policy"
  keyDescription="$18N_BI_POLICY_LIST.DESCRIPTION"
>
<mc-help-container>
  <mc-help-link url="https://community.mimecast.com/s/article/Browser-Isolation-Creating-a-Policy"></mc-help-link>
</mc-help-container>
<mc-table
  tableId="bi/policies/list"
  [isLoading]="isLoading$ | async"
  [data]="tableData$ | async"
  [columns]="columns"
  translatePrefix="$18N_BI_POLICY_LIST.TABLE"
  (rowClick)="onRowClick($event)"
>
  <button
    class="mc-table-actions btn btn-primary panel-half-margin-right"
    (click)="goToCreatePolicy()"
  >
    {{ '$I18N_SWG_CREATE_POLICY_CREATE_NEW_POLICY' | translate }}
  </button>
  <mc-filters (paginatorChange)="onPaginationChange($event)">
    <mc-filter-search
      (search)="onSearchIncidents($event)"
      (clear)="onSearchClear()"
    >
    </mc-filter-search>
  </mc-filters>
  <mc-column key="name">
    <mc-body-cell *mcBodyCellDef="let row">
      <div class="mc-status-text-container">
        <span>{{ row.policyDesc.description }}</span>
      </div>
    </mc-body-cell>
  </mc-column>
  <mc-column-date key="lastModified"></mc-column-date>
  <mc-column key="appliesTo">
    <mc-body-cell *mcBodyCellDef="let row">
      <div class="mc-status-text-container">
          <span *ngIf="row.affectedUsers[0].type === affectedUsers.EVERYONE">{{
            '$I18N_SWG_CREATE_POLICY_AFFECTED_USERS_EVERYONE' | translate
            }}</span>
        <span *ngIf="row.affectedUsers[0].type === affectedUsers.LOCATION">{{
          row.affectedUsers[0].description + ' (' + row.affectedUsers[0].ipRange + ')'
          }}</span>
        <span *ngIf="row.affectedUsers[0].type === affectedUsers.USER">{{
          row.affectedUsers[0].emailAddress
          }}</span>
        <span *ngIf="row.affectedUsers[0].type === affectedUsers.GROUP">{{
          row.affectedUsers[0].description
          }}</span>
        <span *ngIf="row.affectedUsers[0].type === affectedUsers.MULTI">{{
          row.affectedUsers[0].description
          }}</span>
      </div>
    </mc-body-cell>
  </mc-column>
  <mc-column-actions key="right-column" mcShowColumnConfig>
    <mc-row-actions *mcRowActions="let row">
      <li mcRowAction="$I18N_COMMON_BTN_EDIT" (click)="editPolicy(row)"></li>
      <li *ngIf="!row.defaultPolicy && hasBiPolicyEditPermission$ | async"
        mcRowAction="$I18N_COMMON_BTN_DELETE"
        (click)="deletePolicy(row)"
      ></li>
    </mc-row-actions>
  </mc-column-actions>
  <mc-empty-results keyTitle="$18N_SWG_REMEDIATION_DEVICE_SUMMARY.TABLE.NO_DATA"></mc-empty-results>
</mc-table>
</mc-layout-list-table>
