"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DomainsValidationStepComponent = void 0;
const core_1 = require("@angular/core");
class DomainsValidationStepComponent {
    constructor() {
        this.showDomainsSettings = new core_1.EventEmitter();
        this.createDomains = new core_1.EventEmitter();
        this.removeInvalidDomain = new core_1.EventEmitter();
        this.removeValidDomain = new core_1.EventEmitter();
        this.cancel = new core_1.EventEmitter();
        this.validDomains = [];
        this.inValidDomains = [];
    }
}
exports.DomainsValidationStepComponent = DomainsValidationStepComponent;
