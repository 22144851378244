<mc-modal-header
  headerTitle="{{title}}">
</mc-modal-header>

<mc-modal-body>
  <div data-test="purgeProtectedDevicesModalBody">
    <p>{{message}}</p>
  </div>
</mc-modal-body>

<mc-modal-footer>

  <button type="button" class="btn btn-secondary" (click)="onClose()">
    {{ '$I18N_ENDPOINTS_LIST.PURGE_MODAL.CANCEL_BUTTON' | translate }}
  </button>

  <button type="button" class="btn btn-primary" (click)="onDelete()">
    {{ '$I18N_ENDPOINTS_LIST.PURGE_MODAL.PURGE_BUTTON' | translate }}
  </button>

</mc-modal-footer>
