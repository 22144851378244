"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReleaseStatusDisplayPipe = void 0;
class ReleaseStatusDisplayPipe {
    constructor(translateService) {
        this.translateService = translateService;
    }
    transform(heldGroup = '') {
        switch (heldGroup.toLowerCase()) {
            case 'released':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_RELEASE_STATUS.RELEASED');
            case 'rejected':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_RELEASE_STATUS.REJECTED');
            default:
                return '';
        }
    }
}
exports.ReleaseStatusDisplayPipe = ReleaseStatusDisplayPipe;
