<mc-collapsable-panel data-test="TMIMR-ALL" header="$I18N_MESSAGE_CENTER_MESSAGE_INFO_RETENTION.TITLE" [isExpanded]="true">
  <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_RETENTION.LABEL_CURRENT_PURGE_DATE"
                  [value]="retentionInfo.currentPurgeDate | mcDatePlusWeekday">
  </mc-value-field>
  <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_RETENTION.LABEL_PURGE_BASED_ON"
                  [value]="retentionInfo.purgeBasedOn">
  </mc-value-field>
  <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_RETENTION.LABEL_LITIGATION_HOLD"
                  [value]="litigationHold | translate">
  </mc-value-field>
  <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_RETENTION.LABEL_ORIGINAL_PURGE_DATE"
                  [value]="retentionInfo.originalPurgeDate| mcDatePlusWeekday">
  </mc-value-field>

  <div data-test="TMIMR-STORAGE_GRID_TOKENS-TABLE-CONTAINER"
       *ngIf="retentionInfo.audits.length"
       class="panel-padding-top">
    <div translate="$I18N_MESSAGE_CENTER_MESSAGE_INFO_RETENTION.STORAGE_GRID_TOKENS_TITLE"
         class="panel-half-padding-bottom tracking-collapsible-panel-subtitle">
    </div>
    <mc-table data-test="TMIMR-STORAGE_GRID_TOKENS-TABLE"
              [columns]="auditsColumns"
              [data]="retentionInfo.audits"
              [isLoading]="false">
      <mc-column key="type">
        <mc-header-cell *mcHeaderCellDef>{{'$I18N_MESSAGE_CENTER_MESSAGE_INFO_RETENTION.LABEL_TYPE' | translate}}</mc-header-cell>
      </mc-column>
      <mc-column key="token">
        <mc-header-cell *mcHeaderCellDef>{{'$I18N_MESSAGE_CENTER_MESSAGE_INFO_RETENTION.LABEL_TOKEN' | translate}}</mc-header-cell>
      </mc-column>
      <mc-column key="address">
        <mc-header-cell *mcHeaderCellDef>{{'$I18N_MESSAGE_CENTER_MESSAGE_INFO_RETENTION.LABEL_ADDRESS' | translate}}</mc-header-cell>
      </mc-column>
    </mc-table>
  </div>

  <div data-test="TMIMR-LITIGATION-HOLD-TABLE-CONTAINER"
       *ngIf="litigationHold === '$I18N_MESSAGE_CENTER_MESSAGE_INFO_RETENTION.VALUE_ACTIVE'"
       class="panel-padding-top">
    <div translate="$I18N_MESSAGE_CENTER_MESSAGE_INFO_RETENTION.LITIGATION_HOLD_INFORMATION_TITLE"
         class="panel-half-padding-bottom tracking-collapsible-panel-subtitle">
    </div>
    <mc-table data-test="TMIMR-LITIGATION-HOLD-TABLE"
              [columns]="litigationHoldInfoColumns"
              [data]="retentionInfo.litigationHoldInfo"
              [isLoading]="false">
      <mc-column key="legalHoldDescription">
        <mc-header-cell *mcHeaderCellDef>{{'$I18N_MESSAGE_CENTER_MESSAGE_INFO_RETENTION.LABEL_NAME' | translate}}</mc-header-cell>
      </mc-column>
      <mc-column key="expiryDate">
        <mc-header-cell *mcHeaderCellDef>{{'$I18N_MESSAGE_CENTER_MESSAGE_INFO_RETENTION.LABEL_EXPIRES' | translate}}</mc-header-cell>
        <mc-body-cell *mcBodyCellDef="let row">{{ row.expiryDate | mcDatePlusWeekday }}</mc-body-cell>
      </mc-column>
    </mc-table>
  </div>

  <div data-test="TMIMR-SMART-TAGS-TABLE-CONTAINER"
       *ngIf="retentionInfo?.smartTags?.length"
       class="panel-padding-top">
    <div translate="$I18N_MESSAGE_CENTER_MESSAGE_INFO_RETENTION.ASSIGNED_TO_SMART_TAGS_TITLE"
         class="panel-half-padding-bottom tracking-collapsible-panel-subtitle">
    </div>
    <mc-table data-test="TMIMR-SMART-TAGS-TABLE"
              [columns]="smartTagsColumns"
              [data]="retentionInfo.smartTags"
              [isLoading]="false">
      <mc-column key="description">
        <mc-header-cell *mcHeaderCellDef>{{'$I18N_MESSAGE_CENTER_MESSAGE_INFO_RETENTION.LABEL_NAME' | translate}}</mc-header-cell>
      </mc-column>
    </mc-table>
  </div>
</mc-collapsable-panel>
