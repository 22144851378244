"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HttpLoaderFactory = exports.TranslateConfigModule = void 0;
const http_loader_1 = require("@ngx-translate/http-loader");
class TranslateConfigModule {
    constructor(parentModule, translate) {
        if (parentModule) {
            throw new Error('TranslateConfigModule is already loaded. Import it in the TranslateModule only');
        }
        translate.setDefaultLang('en');
        translate.use('en');
    }
}
exports.TranslateConfigModule = TranslateConfigModule;
function HttpLoaderFactory(http, windowService) {
    // @ts-ignore Build number is a global variable that comes from Jenkins and we replace it with webpack
    const buildNumber = typeof BUILD_NUMBER !== 'undefined' ? BUILD_NUMBER : 99999; // Using magic number as a visible fallback
    return new http_loader_1.TranslateHttpLoader(http, `${windowService.rootPath}/resources/languages/`, `.json?ver=${windowService.appVersion}&b=${buildNumber}`);
}
exports.HttpLoaderFactory = HttpLoaderFactory;
