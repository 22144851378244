"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResultStatusComponent = void 0;
class ResultStatusComponent {
    constructor() {
        this.statusConfig = {
            unknown: {
                iconClass: 'indicator-circle',
                iconColorClass: 'icon-mc-color-genius-blue',
                isSelectedIconColorClass: 'icon-mc-color-white',
                translateKey: 'unknown'
            },
            success: {
                iconClass: 'indicator-circle',
                iconColorClass: 'icon-mc-color-go-green',
                isSelectedIconColorClass: 'icon-mc-color-white',
                translateKey: 'success'
            },
            warning: {
                iconClass: 'indicator-circle',
                iconColorClass: 'icon-mc-color-pure-gold',
                isSelectedIconColorClass: 'icon-mc-color-white',
                translateKey: 'warning'
            },
            fail: {
                iconClass: 'indicator-circle',
                iconColorClass: 'icon-mc-color-stanley-red',
                isSelectedIconColorClass: 'icon-mc-color-white',
                translateKey: 'fail'
            }
        };
    }
}
exports.ResultStatusComponent = ResultStatusComponent;
