"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./ttp-impersonation-list.controller"); //services.services.monitoring.ttp-impersonation.controller
require("./slides/ttp-impersonation-details.controller"); //services.services.monitoring.slides.ttp-impersonation-details.controller
(function () {
    'use strict';
    angular.module('services.services.monitoring.ttp-impersonation', [
        'services.services.monitoring.ttp-impersonation.controller',
        'services.services.monitoring.slides.ttp-impersonation-details.controller'
    ])
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('impersonation-logs', {
                url: '/services/services/monitoring/ttp-impersonation',
                data: {
                    tabId: 'monitoring',
                    tabTitle: '$I18N_TTP_IMPERSONATION_TAB_TITLE',
                    tabHide: false,
                    tabReload: false
                },
                views: {
                    'main': {
                        templateUrl: 'services/monitoring/ttp-impersonation/ttp-impersonation-list/ttp-impersonation-list.tpl.html'
                    }
                }
            });
        }]);
}());
