"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MinutesToHoursPipe = void 0;
class MinutesToHoursPipe {
    constructor(translateService) {
        this.translateService = translateService;
    }
    transform(minutes) {
        if (!!minutes) {
            const hours = Math.floor(minutes / 60);
            const minutesLeft = minutes % 60;
            const hrs = hours ? `${hours}` : null;
            const mms = minutesLeft ? `${minutesLeft}` : null;
            let formattedTime = '';
            if (!!hrs) {
                formattedTime =
                    hrs === '1'
                        ? `${hrs} ${this.getTranslateText('hour')}`
                        : `${hrs} ${this.getTranslateText('hours')}`;
            }
            if (!!mms) {
                const formattedMinutes = mms === '1'
                    ? `${mms} ${this.getTranslateText('minute')}`
                    : `${mms} ${this.getTranslateText('minutes')}`;
                formattedTime = `${formattedTime} ${formattedMinutes}`;
            }
            return formattedTime.trim();
        }
        else {
            return `0 ${this.getTranslateText('minutes')}`;
        }
    }
    getTranslateText(key) {
        return this.translateService.instant(`$18N_MEP_POLICY.COMMON.${key.toUpperCase()}`);
    }
}
exports.MinutesToHoursPipe = MinutesToHoursPipe;
