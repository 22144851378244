<mc-layout-detail-wizard [keyTitle]="wizardTitle">
  <mc-wizard #wizard (changeStep)="onStepChange($event)">
    <mc-wizard-step
      label="{{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.DETAILS_STEP.TITLE' | translate }}"
    >
      <mc-archive-supervision-wizard-rule-details [name]="name" [description]="description">
      </mc-archive-supervision-wizard-rule-details>
    </mc-wizard-step>
    <mc-wizard-step
      label="{{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SETTINGS_STEP.TITLE' | translate }}"
    >
      <mc-archive-supervision-wizard-rule-settings
        [searchGroup]="searchGroup"
        [messageRoute]="messageRoute"
        [scheduleType]="scheduleType"
        [samplingPercentage]="samplingPercentage"
        [isEditMode]="isEditMode"
        [isRuleEnabled]="isRuleEnabled"
        [hasMonthlyScheduleCapability]="hasMonthlyScheduleCapability$ | async"
      >
      </mc-archive-supervision-wizard-rule-settings>
    </mc-wizard-step>
    <mc-wizard-step
      label="{{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.LEXICON_STEP.TITLE' | translate }}"
    >
      <mc-archive-supervision-wizard-rule-lexicon
        [selectedLexicon]="selectedLexicon$ | async"
        (onGetLexicons)="getLexicons()"
      >
      </mc-archive-supervision-wizard-rule-lexicon>
    </mc-wizard-step>
    <mc-wizard-step
      label="{{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.QUEUE_STEP.TITLE' | translate }}"
    >
      <mc-archive-supervision-wizard-rule-queue
        [selectedQueue]="selectedQueue$ | async"
        (onGetQueues)="getQueues()"
      >
      </mc-archive-supervision-wizard-rule-queue>
    </mc-wizard-step>
    <mc-wizard-step
      *ngIf="!isEditMode"
      label="{{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SUMMARY_STEP.TITLE' | translate }}"
    >
      <mc-archive-supervision-wizard-rule-summary [hasMonthlyScheduleCapability]="hasMonthlyScheduleCapability$ | async"
        [wizardData]="wizardData$ | async">
      </mc-archive-supervision-wizard-rule-summary>
    </mc-wizard-step>
  </mc-wizard>
  <mc-footer-container>
    <div class="pull-right no-select mc-button-footer">
      <button class="btn mc-create-policy-cancel" (click)="onCancel()">
        {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.CONTROLS.CANCEL' | translate }}
      </button>

      <ng-container *ngIf="wizard">
        <button
          class="btn btn-secondary"
          *ngIf="wizard.hasPreviousStep$ | async"
          (click)="onPrevious()"
        >
          {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.CONTROLS.PREVIOUS' | translate }}
        </button>
        <button
          class="btn btn-primary"
          [disabled]="(wizard.isLastStep$ | async) || !(isCurrentStepValid$ | async)"
          *ngIf="wizard.hasNextStep$ | async"
          (click)="onNext()"
        >
          {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.CONTROLS.NEXT' | translate }}
        </button>
        <button
          *ngIf="(wizard.isLastStep$ | async) || isEditMode"
          [disabled]="
            !(hasEditPermission$ | async) || (isEditMode && !(isCurrentStepValid$ | async))
          "
          class="btn btn-primary"
          (click)="onSave($event)"
        >
          {{ getSaveButtonText() }}
        </button>
      </ng-container>
    </div>
  </mc-footer-container>
</mc-layout-detail-wizard>
