<mc-layout-list-extra-container
keyTitle="$I18N_TTP_ATTACHMENT_PROTECTION_POLICY_LIST_PAGE_TITLE"
  keyDescription="$I18N_TTP_ATTACHMENT_POLICY_LIST_PAGE_DESC">
  
  <mc-help-container>
    <mc-help-link url="{{apLink}}"></mc-help-link>
  </mc-help-container> 
    <mc-body-container>
      <mc-definition-list
        keyTitle="$I18N_AP_POLICIES_LIST_PAGE_TITLE"
        keyDescription="$I18N_AP_POLICIES_LIST_PAGE_DESC"
        [hasIEPPackage]="hasIEPPackage" [isAAAOrFAAUser]="isAAAOrFAAUser" 
        [externalTableSearchCount]="totalCount$ | async"
        [selectedRow]="selectedRow$ | async"
        (onDefinitionDelete)="deleteDefinition($event)"
        [isLoading]="isLoading$ | async" [metadata]="metadata$ | async"
        (onRowClicked)="rowClicked()">
      </mc-definition-list>
    </mc-body-container>
  </mc-layout-list-extra-container>
  