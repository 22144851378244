"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InlineErrorNotificationComponent = void 0;
const core_1 = require("@angular/core");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
class InlineErrorNotificationComponent {
    constructor(featureFlagService, coreService) {
        this.featureFlagService = featureFlagService;
        this.coreService = coreService;
        this.onReload = new core_1.EventEmitter();
    }
    ngOnInit() {
        const ready$ = rxjs_1.from(this.featureFlagService.isReady());
        this.supportLink$ = ready$.pipe(operators_1.switchMap(() => this.coreService.getAccountSwitchSession().pipe(operators_1.take(1))), operators_1.map(switchedSession => {
            const hasConfigEnabled = this.featureFlagService.getBooleanFlag('rollout-zendesk-contact-support');
            const encodedAccountCode = switchedSession && switchedSession.accountcode
                ? `?ac=${encodeURIComponent(btoa(switchedSession.accountcode))}`
                : '';
            return hasConfigEnabled
                ? `/apps#/sso/support${encodedAccountCode}`
                : '/apps#sso/mcentral?goto=/s/contactsupport';
        }));
    }
    reload() {
        this.onReload.emit();
    }
}
exports.InlineErrorNotificationComponent = InlineErrorNotificationComponent;
