"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LayoutListTableComponent = void 0;
const base_1 = require("../base");
class LayoutListTableComponent extends base_1.LayoutListDirective {
    constructor() {
        super(...arguments);
        this.isDataLoaded = true;
    }
    // eslint-disable-next-line @angular-eslint/no-input-rename
    set valueIsDataLoaded(value) {
        if (typeof value === 'boolean') {
            this.isDataLoaded = value;
        }
        else {
            this.isDataLoaded = value === 'true';
        }
    }
}
exports.LayoutListTableComponent = LayoutListTableComponent;
