"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationService = void 0;
const notificationAction = require("../actions/notification.actions");
class NotificationService {
    newNotification(payload) {
        return new notificationAction.NotificationShowAction(payload);
    }
    showFirstNotificationError(fail, mappings, defaultMessage = '$I18N_COMMON_TEXT_LINE_GLOBAL_ERROR_MESSAGE') {
        let message;
        if (fail.errors && fail.errors[0]) {
            message = mappings[fail.errors[0].code];
        }
        return new notificationAction.NotificationShowFailAction(message || defaultMessage);
    }
}
exports.NotificationService = NotificationService;
