  <div class="no-connector">
    <div class="message">
      <span class="fas fa-exclamation-circle text-danger"></span>
      {{ '$I18N_NO_CONNECTOR.MESSAGE' | translate }}</div>
    <div>
      <p class="help-text">
        {{ '$I18N_NO_CONNECTOR.PLEASE' | translate }}
        <a data-test="create-connector"
          (click)="createConnector()">{{ '$I18N_NO_CONNECTOR.CREATE_CONNECTOR' | translate }}</a>.
      </p>
    </div>
  </div>
