"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoConnectorsComponent = void 0;
const core_1 = require("@angular/core");
class NoConnectorsComponent {
    constructor() {
        this.onCreateConnector = new core_1.EventEmitter();
    }
    createConnector() {
        this.onCreateConnector.emit();
    }
}
exports.NoConnectorsComponent = NoConnectorsComponent;
