"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.minLengthForSearchField = exports.maxLengthForSearchField = exports.searchByFields = exports.tableSectionFilters = exports.columnsStartHidden = exports.columnsAlwaysVisible = exports.columns = void 0;
exports.columns = [
    'group',
    'account',
    'description',
    'serverType',
    'enabled',
    'lastSync',
    'status',
    'action'
];
exports.columnsAlwaysVisible = ['group', 'account', 'description'];
exports.columnsStartHidden = [];
exports.tableSectionFilters = [
    {
        name: 'group',
        displayName: '$I18N_AAA_DIRECTORY_SYNC_TABLE.COLUMNS.GROUP',
        filters: []
    },
    {
        name: 'types',
        displayName: '$I18N_AAA_DIRECTORY_SYNC_TABLE.COLUMNS.SERVERTYPE',
        filters: [
            {
                name: 'active_directory',
                displayName: '$I18N_AAA_DIRECTORY_SYNC_TABLE.ACTIVE_DIRECTORY'
            },
            {
                name: 'domino',
                displayName: '$I18N_AAA_DIRECTORY_SYNC_TABLE.DOMINO'
            },
            {
                name: 'office_365',
                displayName: '$I18N_AAA_DIRECTORY_SYNC_TABLE.AZURE_STD'
            },
            {
                name: 'office_365_gcc',
                displayName: '$I18N_AAA_DIRECTORY_SYNC_TABLE.AZURE_GCC'
            },
            {
                name: 'ad_push',
                displayName: '$I18N_AAA_DIRECTORY_SYNC_TABLE.ACTIVE_DIRECTORY_SE'
            },
            {
                name: 'google_directory',
                displayName: '$I18N_AAA_DIRECTORY_SYNC_TABLE.GOOGLE_DIRECTORY'
            }
        ]
    },
    {
        name: 'enabled',
        displayName: '$I18N_AAA_DIRECTORY_SYNC_TABLE.COLUMNS.ENABLED',
        filters: [
            {
                name: 'enabled',
                displayName: '$I18N_AAA_DIRECTORY_SYNC_TABLE.ENABLE'
            },
            {
                name: 'disabled',
                displayName: '$I18N_AAA_DIRECTORY_SYNC_TABLE.DISABLE'
            }
        ]
    },
    {
        name: 'lastSyncOutcomes',
        displayName: '$I18N_AAA_DIRECTORY_SYNC_TABLE.COLUMNS.STATUS',
        filters: [
            {
                name: 'ok',
                displayName: '$I18N_AAA_DIRECTORY_SYNC_TABLE.SUCCESS'
            },
            {
                name: 'error',
                displayName: '$I18N_AAA_DIRECTORY_SYNC_TABLE.FAIL'
            },
            {
                name: 'not_active',
                displayName: '$I18N_AAA_DIRECTORY_SYNC_TABLE.NOT_ACTIVE'
            }
        ]
    }
];
const searchByPrefix = '$I18N_AAA_DIRECTORY_SYNC_TABLE.FILTERS.SEARCH_BY_FILTER_FIELD';
exports.searchByFields = [
    {
        label: `${searchByPrefix}.BOTH`,
        value: 'all'
    },
    {
        label: `${searchByPrefix}.ACCOUNT`,
        value: 'account'
    },
    {
        label: `${searchByPrefix}.NAME`,
        value: 'name'
    }
];
exports.maxLengthForSearchField = 256;
exports.minLengthForSearchField = 3;
