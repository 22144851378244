"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterSearchOptions = exports.columnsAlwaysVisible = exports.actions = exports.columns = void 0;
exports.columns = [
    'name',
    'description',
    'action',
    'autoRelease',
    'level',
    'status',
    'lastModified',
    'right-column'
];
exports.actions = {
    hold: 'hold',
    noAction: 'no_action'
};
exports.columnsAlwaysVisible = ['name', 'description'];
exports.filterSearchOptions = [
    {
        label: '$18N_MEP_POLICY_LIST.TABLE.ALL',
        value: 'all'
    },
    {
        label: '$18N_MEP_POLICY_LIST.TABLE.COLUMNS.NAME',
        value: 'name'
    },
    {
        label: '$18N_MEP_POLICY_LIST.TABLE.COLUMNS.DESCRIPTION',
        value: 'description'
    }
];
