<form
  [formGroup]="form"
  mcHorizontal
>

  <div class="row">
    <div class="col-md-4 mc-detailed-list-label">
      <label
        for="search-text-input"
        class="field-label"
      >
        {{'$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.EVENTS_AND_OKTA_GROUPS.SEARCH_OKTA_GROUPS.LABEL' | translate}}
      </label>
    </div>

    <div class="col-md-8 mc-detailed-list-column">
      <div class="search-container">
        <input
          id="search-text-input"
          type="text"
          class="form-control"
          data-text="searchInput"
          formControlName="searchInput"
          name="searchInput"
          [placeholder]="'$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.EVENTS_AND_OKTA_GROUPS.SEARCH_OKTA_GROUPS.PLACEHOLDER' | translate"
        >
        <span
          class="far fa-search search-icon"
          aria-hidden="true"
        ></span>

        <button
          class="btn btn-secondary"
          [disabled]="!form.valid || isLoadingOktaGroups"
          (click)="search()"
        >
          {{ '$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.BUTTONS.SEARCH' | translate }}
        </button>
      </div>

      <ng-container *ngIf="!isLoadingOktaGroups">
        <div class="popover-wrapper">
          <div
            class="popover-content"
            [class.popover-content-visible]="isPopoverVisible"
          >
            <ng-container *ngIf="isPopoverVisible">
              <ng-container *ngTemplateOutlet="listSearchResults"></ng-container>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <div class="group-list">
        <mc-removable-list
          *ngIf="!!value?.name"
          [data]="[value]"
          (removeItem)="removeSelected()"
        ></mc-removable-list>
      </div>
    </div>
  </div>
</form>

<ng-template #listSearchResults>
  <span
    class="search-results"
    *ngIf="!searchResults?.length"
    (click)="(isPopoverVisible = false)"
  >
    {{ '$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.EVENTS_AND_OKTA_GROUPS.SEARCH_OKTA_GROUPS.NO_RESULTS' | translate }}
  </span>
  <ng-template
    ngFor
    let-result
    [ngForOf]="searchResults"
  >
    <span
      class="search-results"
      (click)="selectItem(result)"
    >
      {{result.name}}
    </span>
  </ng-template>
</ng-template>
