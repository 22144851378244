"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OverviewBuilder = void 0;
const dashboard_statistics_1 = require("../dashboard-statistics");
const builder_utils_1 = require("./builder-utils");
class OverviewBuilder {
    static build(overviewData) {
        const reviewAllPendingCount = overviewData['reviewPendingCount'];
        const escalationAllPendingCount = overviewData['escalationPendingCount'];
        const pendingPercentageForAllReviewQueues = OverviewBuilder.getPendingPercentageForAllReviewQueues(reviewAllPendingCount, escalationAllPendingCount);
        const pendingPercentageForAllEscalationQueues = OverviewBuilder.getPendingPercentageForAllEscalationQueues(escalationAllPendingCount, reviewAllPendingCount);
        const reviewAllNonCompliantCount = overviewData['reviewNonCompliantCount'];
        const escalationAllNonCompliantCount = overviewData['escalationNonCompliantCount'];
        const nonCompliantPercentageForAllReviewQueues = OverviewBuilder.getNonCompliantPercentageForAllReviewQueues(reviewAllNonCompliantCount, escalationAllNonCompliantCount);
        const nonCompliantPercentageForAllEscalationQueues = OverviewBuilder.getNonCompliantPercentageForAllEscalationQueues(escalationAllNonCompliantCount, reviewAllNonCompliantCount);
        return new dashboard_statistics_1.Overview(reviewAllPendingCount, escalationAllPendingCount, pendingPercentageForAllReviewQueues, pendingPercentageForAllEscalationQueues, reviewAllNonCompliantCount, escalationAllNonCompliantCount, nonCompliantPercentageForAllReviewQueues, nonCompliantPercentageForAllEscalationQueues);
    }
    static getPendingPercentageForAllReviewQueues(reviewAllPendingCount, escalationAllPendingCount) {
        const dividend = reviewAllPendingCount;
        const divisor = builder_utils_1.sum(reviewAllPendingCount, escalationAllPendingCount);
        return divisor > 0 ? builder_utils_1.computePercentage(dividend, divisor) : 0;
    }
    static getPendingPercentageForAllEscalationQueues(escalationAllPendingCount, reviewAllPendingCount) {
        const dividend = escalationAllPendingCount;
        const divisor = builder_utils_1.sum(reviewAllPendingCount, escalationAllPendingCount);
        return divisor > 0 ? builder_utils_1.computePercentage(dividend, divisor) : 0;
    }
    static getNonCompliantPercentageForAllReviewQueues(reviewAllNonCompliantCount, escalationAllNonCompliantCount) {
        const dividend = reviewAllNonCompliantCount;
        const divisor = builder_utils_1.sum(reviewAllNonCompliantCount, escalationAllNonCompliantCount);
        return divisor > 0 ? builder_utils_1.computePercentage(dividend, divisor) : 0;
    }
    static getNonCompliantPercentageForAllEscalationQueues(escalationAllNonCompliantCount, reviewAllNonCompliantCount) {
        const dividend = escalationAllNonCompliantCount;
        const divisor = builder_utils_1.sum(reviewAllNonCompliantCount, escalationAllNonCompliantCount);
        return divisor > 0 ? builder_utils_1.computePercentage(dividend, divisor) : 0;
    }
}
exports.OverviewBuilder = OverviewBuilder;
