"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForwardPanelService = void 0;
const forward_panel_component_1 = require("./forward-panel.component");
class ForwardPanelService {
    constructor(asideService) {
        this.asideService = asideService;
    }
    open() {
        this.currentModalInstance = this.asideService.open(forward_panel_component_1.ForwardPanelComponent, {
            position: 'right',
            size: 'lg',
            hasBackdrop: true
        });
        return this.currentModalInstance.afterClose();
    }
    close(message) {
        if (this.currentModalInstance) {
            this.currentModalInstance.close(message);
        }
        this.currentModalInstance = null;
    }
}
exports.ForwardPanelService = ForwardPanelService;
