"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgThreatDetectionsComponent = void 0;
const model_1 = require("../../../../../services/connectors/model");
class SwgThreatDetectionsComponent {
    constructor() {
        this.msTeams = model_1.ProviderType.MS_TEAMS;
        this.box = model_1.ProviderType.BOX;
        this.oneDrive = model_1.ProviderType.ONE_DRIVE;
        this.supportedProviders = [this.msTeams];
    }
    isSupported(provider) {
        return this.supportedProviders.indexOf(provider) >= 0;
    }
    showAppCounts() {
        let showAppCounts = false;
        this.supportedProviders.forEach(provider => {
            if (this.verdictsDistributedByCloudApps[provider]) {
                showAppCounts = true;
            }
        });
        return showAppCounts;
    }
    getAllScannedFiles() {
        if (this.verdictsDistributedByCloudApps !== null) {
            return (this.getTotalScannedFiles(this.verdictsDistributedByCloudApps[this.msTeams]) +
                this.getTotalScannedFiles(this.verdictsDistributedByCloudApps[this.box]) +
                this.getTotalScannedFiles(this.verdictsDistributedByCloudApps[this.oneDrive]));
        }
        return 0;
    }
    getAllDetectedFiles() {
        if (this.verdictsDistributedByCloudApps !== null) {
            return (this.getDetectedScannedFiles(this.verdictsDistributedByCloudApps[this.msTeams]) +
                this.getDetectedScannedFiles(this.verdictsDistributedByCloudApps[this.box]) +
                this.getDetectedScannedFiles(this.verdictsDistributedByCloudApps[this.oneDrive]));
        }
        return 0;
    }
    getTotalScannedFiles(app) {
        if (app) {
            return app.totalScannedFiles;
        }
        return 0;
    }
    getDetectedScannedFiles(app) {
        if (app) {
            return app.detectedScannedFiles;
        }
        return 0;
    }
}
exports.SwgThreatDetectionsComponent = SwgThreatDetectionsComponent;
