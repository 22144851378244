"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCategorySectionsLoading = exports.getWebCategoryRulesSections = exports.reducer = exports.initialState = void 0;
const categories = require("../../actions/categories.actions");
exports.initialState = {
    webCategoryRulesSections: [],
    categorySectionsLoading: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case categories.SWG_POLICY_LOAD_CATEGORY_SECTIONS:
            return Object.assign(Object.assign({}, state), { categorySectionsLoading: true });
        case categories.SWG_POLICY_LOAD_CATEGORY_SECTIONS_SUCCESS:
            return Object.assign(Object.assign({}, state), { webCategoryRulesSections: action.payload, categorySectionsLoading: false });
        case categories.SWG_POLICY_LOAD_CATEGORY_SECTIONS_FAILURE:
            return Object.assign(Object.assign({}, state), { categorySectionsLoading: false });
        case categories.SWG_POLICY_CLEAR_CATEGORY_SECTIONS:
            return Object.assign({}, exports.initialState);
        default:
            return state;
    }
}
exports.reducer = reducer;
const getWebCategoryRulesSections = (state) => state.webCategoryRulesSections;
exports.getWebCategoryRulesSections = getWebCategoryRulesSections;
const getCategorySectionsLoading = (state) => state.categorySectionsLoading;
exports.getCategorySectionsLoading = getCategorySectionsLoading;
