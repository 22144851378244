"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IdentityShowEditPolicyWizardAction = exports.IdentityDeleteModalCloseAction = exports.IdentityDeletePolicyFailureAction = exports.IdentityDeletePolicySuccessAction = exports.IdentityDeletePolicyAction = exports.IdentityDeleteModalOpenAction = exports.IdentityShowCreatePolicyWizardAction = exports.IdentityClosePolicyAction = exports.IdentityOpenPolicyAction = exports.IdentityClearNotificationAction = exports.IdentitySaveNotificationAction = exports.CLEAR_NOTIFICATION = exports.SAVE_NOTIFICATION = exports.DELETE_CONFIRMATION_MODAL_CLOSE = exports.DELETE_POLICY_FAILURE = exports.DELETE_POLICY_SUCCESS = exports.DELETE_POLICY = exports.DELETE_CONFIRMATION_MODAL_OPEN = exports.SHOW_EDIT_POLICY_WIZARD = exports.SHOW_CREATE_POLICY_WIZARD = exports.CLOSE_POLICY = exports.OPEN_POLICY = void 0;
exports.OPEN_POLICY = '[IdentityPolicies]  Open Policy';
exports.CLOSE_POLICY = '[IdentityPolicies]  Close Policy';
exports.SHOW_CREATE_POLICY_WIZARD = '[IdentityPolicies] Show Create Policy Wizard';
exports.SHOW_EDIT_POLICY_WIZARD = '[IdentityPolicies] Show Edit Policy Wizard';
exports.DELETE_CONFIRMATION_MODAL_OPEN = '[IdentityPolicies] Show Policy Deletion Confirmation';
exports.DELETE_POLICY = '[IdentityPolicies] Delete Policy';
exports.DELETE_POLICY_SUCCESS = '[IdentityPolicies] Delete Policy Success';
exports.DELETE_POLICY_FAILURE = '[IdentityPolicies] Delete Policy Failure';
exports.DELETE_CONFIRMATION_MODAL_CLOSE = '[IdentityPolicies] Delete Confirmation Modal Close';
exports.SAVE_NOTIFICATION = '[IdentityPolicies] Save Notification';
exports.CLEAR_NOTIFICATION = '[IdentityPolicies] Clear Notification';
class IdentitySaveNotificationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SAVE_NOTIFICATION;
    }
}
exports.IdentitySaveNotificationAction = IdentitySaveNotificationAction;
class IdentityClearNotificationAction {
    constructor() {
        this.type = exports.CLEAR_NOTIFICATION;
    }
}
exports.IdentityClearNotificationAction = IdentityClearNotificationAction;
class IdentityOpenPolicyAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.OPEN_POLICY;
    }
}
exports.IdentityOpenPolicyAction = IdentityOpenPolicyAction;
class IdentityClosePolicyAction {
    constructor() {
        this.type = exports.CLOSE_POLICY;
    }
}
exports.IdentityClosePolicyAction = IdentityClosePolicyAction;
class IdentityShowCreatePolicyWizardAction {
    constructor() {
        this.type = exports.SHOW_CREATE_POLICY_WIZARD;
    }
}
exports.IdentityShowCreatePolicyWizardAction = IdentityShowCreatePolicyWizardAction;
class IdentityDeleteModalOpenAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.DELETE_CONFIRMATION_MODAL_OPEN;
    }
}
exports.IdentityDeleteModalOpenAction = IdentityDeleteModalOpenAction;
class IdentityDeletePolicyAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.DELETE_POLICY;
    }
}
exports.IdentityDeletePolicyAction = IdentityDeletePolicyAction;
class IdentityDeletePolicySuccessAction {
    constructor() {
        this.type = exports.DELETE_POLICY_SUCCESS;
    }
}
exports.IdentityDeletePolicySuccessAction = IdentityDeletePolicySuccessAction;
class IdentityDeletePolicyFailureAction {
    constructor() {
        this.type = exports.DELETE_POLICY_FAILURE;
    }
}
exports.IdentityDeletePolicyFailureAction = IdentityDeletePolicyFailureAction;
class IdentityDeleteModalCloseAction {
    constructor() {
        this.type = exports.DELETE_CONFIRMATION_MODAL_CLOSE;
    }
}
exports.IdentityDeleteModalCloseAction = IdentityDeleteModalCloseAction;
class IdentityShowEditPolicyWizardAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SHOW_EDIT_POLICY_WIZARD;
    }
}
exports.IdentityShowEditPolicyWizardAction = IdentityShowEditPolicyWizardAction;
