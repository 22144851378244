<mc-layout-list-extra-container
  keyTitle="$I18N_MESSAGE_CENTER_ACCEPTED_EMAIL_MAIN_CONTAINER.HEADER.TITLE"
  keyDescription="$I18N_MESSAGE_CENTER_ACCEPTED_EMAIL_MAIN_CONTAINER.HEADER.SUB_TITLE">
  <mc-help-container>
    <mc-help-link url="https://community.mimecast.com/docs/DOC-3322"></mc-help-link>
  </mc-help-container>

  <mc-body-container>
    <mc-accepted-email-main-table
      [rows]="acceptedEmailList$ | async"
      [externalTableSearchCount]="externalTableSearchCount$ | async"
      [selectedRow]="selectedRow$ | async"
      [isLoading]="isLoading$ | async"
      [metadata]="metadata$ | async"
      (rowClick)="onRowClick($event)"
      (reportMessage)="onReportMessage($event)"
      (filtersChange)="onFiltersChange($event)"
      (paginatorChange)="onPaginatorChange($event)"
      (forward)="onForward($event)"
      (export)="onExport($event)">
    </mc-accepted-email-main-table>
  </mc-body-container>
</mc-layout-list-extra-container>
