"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RemediationGetIncidentStatsFailureAction = exports.RemediationGetIncidentStatsSuccessAction = exports.RemediationGetIncidentStatsAction = exports.GET_INCIDENTS_STATS_FAILURE = exports.GET_INCIDENTS_STATS_SUCCESS = exports.GET_INCIDENTS_STATS = void 0;
exports.GET_INCIDENTS_STATS = '[Remediation] Retrieve incidents stats';
exports.GET_INCIDENTS_STATS_SUCCESS = '[Remediation] Retrieve incidents stats success';
exports.GET_INCIDENTS_STATS_FAILURE = '[Remediation] Retrieve incidents stats failure';
class RemediationGetIncidentStatsAction {
    constructor() {
        this.type = exports.GET_INCIDENTS_STATS;
    }
}
exports.RemediationGetIncidentStatsAction = RemediationGetIncidentStatsAction;
class RemediationGetIncidentStatsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_INCIDENTS_STATS_SUCCESS;
    }
}
exports.RemediationGetIncidentStatsSuccessAction = RemediationGetIncidentStatsSuccessAction;
class RemediationGetIncidentStatsFailureAction {
    constructor() {
        this.type = exports.GET_INCIDENTS_STATS_FAILURE;
    }
}
exports.RemediationGetIncidentStatsFailureAction = RemediationGetIncidentStatsFailureAction;
