<div class="mc-api-integration-step-block">
  <mc-wizard-step-header [label]="'$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.ACTIVATION.HEADER'">
  </mc-wizard-step-header>

  <div class="mc-detailed-list mc-api-integration-wildfire-activation-block">
    <ol class="list-group no-margin panel-padding-bottom panel-padding-left col-sm-12">
      <li>
        {{ '$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.ACTIVATION.STEP_1' | translate }}
        <a [href]="wildfireLink">{{ '$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.ACTIVATION.STEP_1_LINK' | translate }}</a>.
      </li>
      <li>
        {{ '$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.ACTIVATION.STEP_2' | translate }}
      </li>
      <li>
        {{ '$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.ACTIVATION.STEP_3' | translate }}
      </li>
    </ol>
    <form
      class="form-horizontal"
      mcDefaultLabelClass="col-sm-2"
      mcDefaultControlContainerClass="col-sm-6"
      [formGroup]="form"
    >
      <mc-field
        label="{{
          '$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.ACTIVATION.REGION' | translate
        }}"
      >
          <mc-select formControlName="regionField" [options]="regionOptions" [defaultValue]="form.value.regionField"></mc-select>
      </mc-field>

      <mc-field
          label="{{ '$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.ACTIVATION.API_KEY' | translate }}"
        >
        <input
            class="form-control"
            type="text"
            name="apiKeyField"
            formControlName="apiKeyField"
            aria-label="apiKeyField"
        />
      </mc-field>

      <div class="col-sm-offset-2 verify-btn-container">
        <button *ngIf="!isVerified()" [disabled]="isDisabled()" (click)="verify()" type="button" class="btn btn-primary">
          {{ verifyTextButton() }}
        </button>

        <button *ngIf="isVerified()" [disabled]="isVerified()" type="button" class="btn btn-white">
          {{ '$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.ACTIVATION.VERIFIED' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
