"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgThreatEventStatusIconComponent = void 0;
const clp_1 = require("../../../../policies/models/clp");
const swg_threat_event_status_component_1 = require("../threat-event-status/swg-threat-event-status.component");
class SwgThreatEventStatusIconComponent {
    getIconClass() {
        const policyAction = swg_threat_event_status_component_1.SwgThreatEventStatusComponent.getPolicyAction(this.actions);
        switch (policyAction) {
            case clp_1.ClpPolicyActions.LOG_AND_REPLY:
            case clp_1.ClpPolicyActions.RELEASED:
                return 'fas fa-exclamation-triangle text-warning';
            case clp_1.ClpPolicyActions.BLOCK_MALICIOUS:
            case clp_1.ClpPolicyActions.MANUALLY_QUARANTINED:
                return 'fas fa-shield-check text-success';
            case clp_1.ClpPolicyActions.LOG_ONLY:
            default:
                return 'fas fa-exclamation-circle text-danger';
        }
    }
}
exports.SwgThreatEventStatusIconComponent = SwgThreatEventStatusIconComponent;
