"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimeLeftModalComponent = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
class TimeLeftModalComponent {
    constructor(overlayRef, config) {
        this.overlayRef = overlayRef;
        this.config = config;
        this.lastStayLoginTime = window.localStorage.getItem('stayLogin')
            ? parseInt(window.localStorage.getItem('stayLogin'), 10)
            : 0;
    }
    ngOnInit() {
        this.timeLeft$ = this.initTimerCountDown$();
    }
    initTimerCountDown$(start = this.config.timeToLogout / 1000, everySecond$ = rxjs_1.timer(0, 1000)) {
        return everySecond$.pipe(operators_1.map(i => start - i), operators_1.tap(i => i === 0 && this.logoutCheck()), operators_1.takeUntil(this.overlayRef.afterClose()), operators_1.takeWhile(i => i >= 0));
    }
    cancel() {
        this.overlayRef.close('cancel');
        const time = new Date().getTime().toString();
        window.localStorage.setItem('stayLogin', time);
        this.lastStayLoginTime = parseInt(time, 10);
    }
    logout() {
        this.overlayRef.close('logout');
    }
    logoutCheck() {
        // call logout only if stay login button is not clicked in any tab (i.e, when the value in stayLogin localstorage remains same)
        const currentStayLoginTime = window.localStorage.getItem('stayLogin')
            ? parseInt(window.localStorage.getItem('stayLogin'), 10)
            : 0;
        if (this.lastStayLoginTime === 0 || this.lastStayLoginTime === currentStayLoginTime) {
            this.logout();
        }
    }
}
exports.TimeLeftModalComponent = TimeLeftModalComponent;
