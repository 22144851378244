<div class="panel panel-default">
  <div class="panel-heading mc-bg-light">{{ '$I18N_REMEDIATION_CONTAINER.TABS.SETTINGS' | translate}}</div>
  <div class="panel-body" *ngIf="isLoaded$ | async; else spinner">
    <div class="row">
      <div class="col-sm-4 text-right helvetica-neue-bold-fix">
        <strong>{{'$I18N_REMEDIATION.SETTINGS.STATUS' | translate}}</strong>
      </div>
      <div class="col-sm-8">
        <span [class.text-success]="(settings$ | async).enabled"
              [class.text-warning]="!(settings$ | async).enabled"
        >{{status | translate}}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4 text-right helvetica-neue-bold-fix">
        <strong>{{ '$I18N_REMEDIATION.SETTINGS.MODE' | translate}}</strong>
      </div>
      <div class="col-sm-8">
        {{mode | translate}}
      </div>
    </div>
    <a class="btn btn-link pull-right no-padding"
       (click)="openSettings()">{{ '$I18N_REMEDIATION.SETTINGS.ALL_SETTINGS' | translate}}</a>
  </div>
</div>

<ng-template #spinner>
  <div class="panel-body with-spinner" *ngIf="!(hasGenericError$ | async); else somethingWentTerriblyWrong">
    <mc-spinner></mc-spinner>
  </div>
</ng-template>

<ng-template #somethingWentTerriblyWrong>
  <div class="panel-body spinner-wrapper">
    <mc-inline-notification [notification]="inlineNotificationConfigError"></mc-inline-notification>
  </div>
</ng-template>
