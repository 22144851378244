<mc-wizard-step-header
  compact="true"
  label="$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.EVENTS_AND_OKTA_GROUPS.HEADER"
>
</mc-wizard-step-header>

<p>
  {{ "$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.EVENTS_AND_OKTA_GROUPS.SUBHEADER" | translate }}
</p>

<form [formGroup]="form">
  <mc-okta-ebc-event-switch
    [fieldLabel]="'$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.EVENTS_AND_OKTA_GROUPS.FORM.DATA_LEAK_PREVENTION'"
    [isLoadingOktaGroups]="isLoadingOktaGroups"
    [searchOktaGroupResults]="searchOktaGroupResults"
    (onSearchOktaGroups)="onSearchOktaGroups.emit($event)"
    formControlName="dataLeakPrevention"
    name="dataLeakPrevention"
  ></mc-okta-ebc-event-switch>

  <mc-okta-ebc-event-switch
    [fieldLabel]="'$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.EVENTS_AND_OKTA_GROUPS.FORM.ATTACHMENT_PROTECT'"
    [isLoadingOktaGroups]="isLoadingOktaGroups"
    [searchOktaGroupResults]="searchOktaGroupResults"
    (onSearchOktaGroups)="onSearchOktaGroups.emit($event)"
    formControlName="attachmentProtect"
    name="attachmentProtect"
  ></mc-okta-ebc-event-switch>

  <mc-okta-ebc-event-switch
    [fieldLabel]="'$I18N_API_INTEGRATION_OKTA_EVIDENCE_BASED_CONTROL_WIZARD.STEPS.EVENTS_AND_OKTA_GROUPS.FORM.URL_PROTECT'"
    [isLoadingOktaGroups]="isLoadingOktaGroups"
    [searchOktaGroupResults]="searchOktaGroupResults"
    (onSearchOktaGroups)="onSearchOktaGroups.emit($event)"
    formControlName="urlProtect"
    name="urlProtect"
  ></mc-okta-ebc-event-switch>
</form>
