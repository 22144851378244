"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiIntegrationCrowdstrikeComponent = void 0;
const reducers = require("app-new/api-applications/reducers");
const actions = require("app-new/api-applications/actions");
const operators_1 = require("rxjs/operators");
class ApiIntegrationCrowdstrikeComponent {
    constructor(store, modalService, translateService) {
        this.store = store;
        this.modalService = modalService;
        this.translateService = translateService;
        this.cancelModalTitle = '$I18N_API_APPLICATIONS.MODALS.CANCEL_MODAL.CONTENT.INITIATE_MODE';
    }
    ngOnInit() {
        this.crowdstrikeIntegration$ = this.store.select(reducers.getIntegrationCrowdstrikeIntegration);
        this.isBusy$ = this.store.select(reducers.isIntegrationCrowdstrikeBusy);
        this.isClientIdAndClientSecretVerified$ = this.store.select(reducers.isIntegrationCrowdstrikeClientIdAndClientSecretVerified);
        this.hasCreatedIntegration$ = this.store.select(reducers.hasIntegrationCrowdstrikeCreatedIntegration);
        this.isVerifyApiKeyButtonDisabled$ = this.store.select(reducers.isIntegrationCrowdstrikeVerifyApiKeyButtonDisabled);
        this.hasCreatedIntegration$.subscribe(hasIntegration => {
            if (hasIntegration === true) {
                this.cancelModalTitle = '$I18N_API_APPLICATIONS.MODALS.CANCEL_MODAL.CONTENT.EDIT_MODE';
            }
        });
    }
    verifyClientIdAndClientSecret(clientIdAndClientSecretConfig) {
        this.store.dispatch(new actions.StartVerifyClientIdAndClientSecretAction(clientIdAndClientSecretConfig));
    }
    switchCrowdstrikeIntegrationStatus(status) {
        this.store.dispatch(new actions.SwitchCrowdstrikeIntegrationStatusAction(status));
    }
    switchMaliciousHashesEnabled(submitMaliciousHashesEnabled) {
        this.store.dispatch(new actions.SwitchSubmitMaliciousHashesAction(submitMaliciousHashesEnabled));
    }
    switchPullMaliciousHashesEnabled(submitPullMaliciousHashesEnabled) {
        this.store.dispatch(new actions.SwitchPullMaliciousHashesAction(submitPullMaliciousHashesEnabled));
    }
    iocOutboundActionSelected(action) {
        this.store.dispatch(new actions.SelectIocOutboundActionAction(action));
    }
    switchMaliciosUrls(maliciousUrlsEnabeld) {
        this.store.dispatch(new actions.SwitchMaliciousUrlsAction(maliciousUrlsEnabeld));
    }
    iocOutboundUrlActionSelected(iocOutboundUrlAction) {
        this.store.dispatch(new actions.UpdateIocOutboundUrlActionAction(iocOutboundUrlAction));
    }
    iocOutboundSeveritySelected(iocOutboundSevirity) {
        this.store.dispatch(new actions.UpdateIocOutboundSeverityAction(iocOutboundSevirity));
    }
    iocOutboundExpirationSelected(iocOutboundExpiration) {
        this.store.dispatch(new actions.UpdateIocOutboundExpirationAction(iocOutboundExpiration));
    }
    switchRemediationRequired(remediationEnabled) {
        this.store.dispatch(new actions.SwitchCrowdstrikeRemediationRequiredAction(remediationEnabled));
    }
    createCrowdstrikeIntegration() {
        this.store.dispatch(new actions.CreateCrowdstrikeIntegrationAction());
    }
    cancel() {
        this.modalService
            .open({
            content: this.cancelModalTitle,
            hideHeader: true,
            buttons: [
                {
                    title: this.translateService.instant('$I18N_API_APPLICATIONS.MODALS.CANCEL_MODAL.BUTTONS.NO'),
                    type: 'default',
                    id: 'no'
                },
                {
                    title: this.translateService.instant('$I18N_API_APPLICATIONS.MODALS.CANCEL_MODAL.BUTTONS.YES'),
                    type: 'primary',
                    id: 'yes'
                }
            ]
        })
            .pipe(operators_1.first(), operators_1.filter((result) => result === 'yes'), operators_1.tap(() => this.store.dispatch(new actions.CancelCrowdstrikeIntegrationWidgetAction())))
            .subscribe();
    }
    updateCrowdstrikeIntegration() {
        this.store.dispatch(new actions.UpdateCrowdstrikeIntegrationAction());
    }
    verifyAndAddEmail(email) {
        this.store.dispatch(new actions.VerifyAndAddEmailCrowdstrikeIntegrationNotificationsAction(email));
    }
    deleteEmail(email) {
        this.store.dispatch(new actions.DeleteEmailCrowdstrikeIntegrationNotificationsAction(email));
    }
    addGroup(selectedGroup) {
        this.store.dispatch(new actions.AddGroupCrowdstrikeIntegrationNotificationsAction(selectedGroup));
    }
    deleteGroup(selectedGroup) {
        this.store.dispatch(new actions.DeleteGroupCrowdstrikeIntegrationNotificationsAction(selectedGroup));
    }
}
exports.ApiIntegrationCrowdstrikeComponent = ApiIntegrationCrowdstrikeComponent;
