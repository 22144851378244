"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./server-connection-edit.controller"); //services.services.server-connections-edit.controller
require("./server-connection-edit.service"); //services.services.server-connections-edit.service
require("./server-connection-edit-guard.service");
// html and css
require("./server-connection-edit-form.tpl.html");
require("./server-connection-edit-summary.tpl.html");
require("./server-connection-edit.tpl.html");
require("./server-connection-test-connection-form.tpl.html");
(function () {
    'use strict';
    /* ======================================================== *\

   = ServerConnections - Edit

   \* ======================================================== */
    angular.module('services.services.server-connections-edit', ['services.services.server-connections-edit.controller', 'services.services.server-connections-edit.service', 'services.services.server-connections-edit-guard'])
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('server-connections-create', {
                url: '/services/services/server-connection/create',
                parent: 'server-connections',
                data: {
                    capabilities: 'Services.ServerConnections && Permission.SERVER_CONNECTIONS_READ',
                    breadcrumbs: [
                        '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_BREADCRUMBS_LABEL_04'
                    ],
                    tabId: 'server-connection-tabid',
                    tabTitle: '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_MENU_LABEL',
                    tabHide: false,
                    tabReload: false
                },
                views: {
                    'main': {
                        templateUrl: 'services/services/server-connection/server-connection-edit/server-connection-edit.tpl.html',
                        controller: angular.noop,
                        resolve: {
                            serverConnectionResponse: ['serverConnectionEditGuard', function (serverConnectionEditGuard) {
                                    return serverConnectionEditGuard.reset();
                                }],
                            isAside: [function () {
                                    return false;
                                }]
                        }
                    }
                }
            })
                .state('server-connections-update', {
                url: '/services/services/server-connection/update/:id',
                parent: 'server-connections',
                data: {
                    capabilities: 'Services.ServerConnections && Permission.SERVER_CONNECTIONS_READ',
                    breadcrumbs: [
                        '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_BREADCRUMBS_LABEL_05'
                    ],
                    tabId: 'server-connection-tabid',
                    tabTitle: '$I18N_BACKUP_RESTORE_SERVER_CONNECTION_MENU_LABEL',
                    tabHide: false,
                    tabReload: false
                },
                views: {
                    'main': {
                        templateProvider: ['serverConnectionEditService', '$templateFactory', function (serverConnectionEditService, $templateFactory) {
                                return (serverConnectionEditService.hasServerConnectionEditPermission())
                                    ? $templateFactory.fromUrl('services/services/server-connection/server-connection-edit/server-connection-edit.tpl.html')
                                    : $templateFactory.fromUrl('services/services/server-connection/server-connection-edit/server-connection-edit-summary.tpl.html');
                            }],
                        resolve: {
                            serverConnectionResponse: ['serverConnectionEditGuard', '$stateParams', function (serverConnectionEditGuard, $stateParams) {
                                    return serverConnectionEditGuard.getServerConnection($stateParams.id);
                                }]
                        }
                    }
                }
            });
        }]);
}());
