<mc-wizard-step-header *ngIf="!hideWizardHeader"
  [label]="title"
></mc-wizard-step-header>
<div class="mc-category-filter-step-container">
  <div *ngIf="!hideFilterSearch" class="panel-padding-bottom">
    <mc-filter-search (search)="onSearch($event)" (clear)="onSearchClear()"></mc-filter-search>
  </div>

  <form novalidate [formGroup]="webCategoryFilterPolicyForm">
    <div *ngFor="let ruleGroup of userRuleSections">
      <div class="row panel-padding-top">
        <div class="col-xs-6" (click)="toggleSection(ruleGroup.key)">
          <small
            class="mc-collapsable-panel-caret fas"
            [ngClass]="sectionToggle[ruleGroup.key] ? 'fa-caret-down' : 'fa-caret-right'"
          ></small>
          <span class="mc-category-filter-select-label text-bold"
            >{{ '$I18N_SWG_HUMANREADBALE_CATEGORY.' + ruleGroup.key | uppercase | translate }}
          </span>

          <span *ngIf="!searching">
            {{ ruleGroup.rules.length }} {{ '$I18N_SWG_WEB_CATEGORY_POLICY_ITEMS' | translate }}
          </span>
          <span *ngIf="searching">
            {{ foundCount[ruleGroup.key] }} {{ '$I18N_SWG_WEB_CATEGORY_POLICY_OF' | translate }}
            {{ ruleGroup.rules.length }}
            {{ '$I18N_SWG_WEB_CATEGORY_POLICY_ITEMS_VISIBLE' | translate }}
          </span>
        </div>
        <div class="col-xs-6">
          <mc-category-group-indicator
            [disabled]="_disabled"
            [status]="groupStatus[ruleGroup.key]"
            (blockAll)="allowAllInGroup(ruleGroup.key)"
            (allowAll)="blockAllInGroup(ruleGroup.key)"
            (custom)="showSection(ruleGroup.key)"
          ></mc-category-group-indicator>
        </div>
      </div>

      <div
        [ngClass]="sectionToggle[ruleGroup.key] ? 'mc-show' : 'mc-hide'"
        class="panel-padding-left"
      >
        <div class="row panel-padding-left">
          <div
            *ngFor="let rule of ruleGroup.rules"
            class="col-xs-6"
            [ngClass]="showThisCategory(rule) ? 'mc-show' : 'mc-hide'"
          >
            <label class="checkbox-inline mc-category-checkbox" for="checkbox1_{{ rule.name }}">
              <input
                id="checkbox1_{{ rule.name }}"
                type="checkbox"
                formControlName="{{ rule.name }}"
              />
              {{ rule.translatedCategoryName }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
