<mc-layout-detail-wizard [keyTitle]="wizardTitle">
  <mc-wizard #wizard (changeStep)="onStepChange($event)">
    <mc-wizard-step
      label="{{ '$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.DETAILS_STEP.TITLE' | translate}}"
    >
      <mc-custom-domain-block-wizard-details [name]="name" [description]="description" [wizardData]="wizardData$ | async">
      </mc-custom-domain-block-wizard-details>
    </mc-wizard-step>
    <mc-wizard-step
      label="{{ '$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.ACTIONS_STEP.TITLE' | translate}}"
    >
      <mc-custom-domain-block-wizard-actions
        [action]="action"
        [notify]="notify"
        [notifyGroups]="notifyGroups"
        [notificationsGroups]="notificationsGroups$ | async"
        [redirectToUser]="(redirectToUser$ | async) || redirectToUser"
        [redirectToGroup]="(redirectToGroup$ | async) || redirectToGroup"
        (removeGroup)="removeGroup()"
        (removeRedirectEmail)="removeRedirectEmail()"
        (removeRedirectGroup)="removeRedirectGroup()"
        (openNotifyToSidePanel)="openNotifyToSidePanel($event)">
      </mc-custom-domain-block-wizard-actions>
    </mc-wizard-step>
    <mc-wizard-step
      label="{{ '$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.APPLIES_TO_STEP.TITLE' | translate}}"
    >
      <mc-custom-domain-block-wizard-applies-to
        [appliesTo]="appliesTo"
        [group]="group"
        [profileGroup]="profileGroups$ | async"
        (removeGroup)="removeProfileGroup()"
        (openNotifyToSidePanel)="openNotifyToSidePanel($event)">
      </mc-custom-domain-block-wizard-applies-to>
    </mc-wizard-step>
    <mc-wizard-step
      label="{{ '$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.SUMMARY_STEP.TITLE' | translate}}"
    >
      <mc-custom-domain-block-wizard-summary [wizardData]="wizardData$ | async" [enabled]="enabled">
      </mc-custom-domain-block-wizard-summary>
    </mc-wizard-step>
  </mc-wizard>
  <mc-footer-container>
    <div class="pull-right no-select mc-button-footer">
      <button class="btn btn-link mc-create-policy-cancel" (click)="onCancel()">
        {{ '$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.CONTROLS.CANCEL' | translate }}
      </button>

      <ng-container *ngIf="wizard">
        <button
          class="btn btn-secondary"
          *ngIf="(wizard.hasPreviousStep$ | async)"
          (click)="onPrevious()"
        >
          {{ '$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.CONTROLS.PREVIOUS' | translate }}
        </button>
        <button
          class="btn btn-primary"
          [disabled]="(wizard.isLastStep$ | async) || !(isCurrentStepValid$ | async)"
          *ngIf="(wizard.hasNextStep$ | async)"
          (click)="onNext()"
        >
          {{ '$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.CONTROLS.NEXT' | translate }}
        </button>
        <button
          *ngIf="(wizard.isLastStep$ | async)"
          class="btn btn-primary"
          (click)="onSave($event)"
        >
          {{ getSaveButtonText() }}
        </button>
      </ng-container>
    </div>
  </mc-footer-container>
</mc-layout-detail-wizard>
