"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnabledStatusComponent = void 0;
class EnabledStatusComponent {
    constructor(translate$) {
        this.translate$ = translate$;
        this.defaultStrings = {
            enabled: 'Enabled',
            disabled: 'Disabled'
        };
        this.defaultIcons = {
            enabled: 'fa-check-circle',
            disabled: 'fa-ban'
        };
    }
    ngOnInit() {
        // Default to showing coloured text.
        if (typeof this.showColouredText === 'undefined') {
            this.showColouredText = true;
        }
        this.defaultStrings.enabled = this.translate$.instant('$I18N_COMMON_TEXT_ENABLED');
        this.defaultStrings.disabled = this.translate$.instant('$I18N_COMMON_TEXT_DISABLED');
    }
}
exports.EnabledStatusComponent = EnabledStatusComponent;
