<mc-modal-header
    [headerTitle]="('$I18N_SECURE_MESSAGING_RECALL_MODAL.CONFIGURATION_' + (getNumberOfMessages() === 1 ? 'SINGULAR' : 'PLURAL'))| translate:{total: getNumberOfMessages()} ">
</mc-modal-header>
<mc-modal-body>
    {{ ('$I18N_SECURE_MESSAGING_RECALL_MODAL.CONFIGURATION_SUBMESSAGE_'  + (getNumberOfMessages() === 1 ? 'SINGULAR' : 'PLURAL') ) | translate }}
</mc-modal-body>
<mc-modal-footer>
    <button type="button" class="btn btn-secondary" (click)="cancel()">
        {{ '$I18N_SECURE_MESSAGING_RECALL_MODAL.CANCEL' | translate }}
    </button>

    <button type="button" class="btn btn-primary" (click)="confirm()">
        {{ '$I18N_SECURE_MESSAGING_RECALL_MODAL.RECALL' | translate }}
    </button>
</mc-modal-footer>