"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckUrlModalComponent = void 0;
const reducers = require("../../reducers/index");
const logs_check_url_actions_1 = require("../../actions/logs-check-url.actions");
class CheckUrlModalComponent {
    constructor(overlayRef, data, store) {
        this.overlayRef = overlayRef;
        this.store = store;
        this.relaxed$ = this.store.select(reducers.getCheckUrlsScanRelaxedResults);
        this.moderate$ = this.store.select(reducers.getCheckUrlsScanModerateResults);
        this.aggressive$ = this.store.select(reducers.getCheckUrlsScanAggressiveResults);
        this.isLoading$ = this.store.select(reducers.getCheckUrlsLoadingState);
        this.url = data.url;
        this.store.dispatch(new logs_check_url_actions_1.CheckUrlAttemptAction(this.url));
    }
    onDone() {
        this.overlayRef.close();
    }
}
exports.CheckUrlModalComponent = CheckUrlModalComponent;
