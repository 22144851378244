"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoadNotificationChannelsClear = exports.LoadNotificationChannelsFailure = exports.LoadNotificationChannelsSuccess = exports.LoadNotificationChannels = exports.LoadNotificationTeamsClear = exports.LoadNotificationTeamsFailure = exports.LoadNotificationTeamsSuccess = exports.LoadNotificationTeams = exports.LOAD_NOTIFICATION_CHANNELS_CLEAR = exports.LOAD_NOTIFICATION_CHANNELS_FAILURE = exports.LOAD_NOTIFICATION_CHANNELS_SUCCESS = exports.LOAD_NOTIFICATION_CHANNELS = exports.LOAD_NOTIFICATION_TEAMS_CLEAR = exports.LOAD_NOTIFICATION_TEAMS_FAILURE = exports.LOAD_NOTIFICATION_TEAMS_SUCCESS = exports.LOAD_NOTIFICATION_TEAMS = void 0;
exports.LOAD_NOTIFICATION_TEAMS = '[Applies_to] load notification Teams';
exports.LOAD_NOTIFICATION_TEAMS_SUCCESS = '[Applies_to] load notification Teams  success';
exports.LOAD_NOTIFICATION_TEAMS_FAILURE = '[Applies_to] load notification Teams failure';
exports.LOAD_NOTIFICATION_TEAMS_CLEAR = '[Applies_to] load notification Teams clear';
exports.LOAD_NOTIFICATION_CHANNELS = '[Applies_to] load notification channels';
exports.LOAD_NOTIFICATION_CHANNELS_SUCCESS = '[Applies_to] load notification channels success';
exports.LOAD_NOTIFICATION_CHANNELS_FAILURE = '[Applies_to] load notification channels failure';
exports.LOAD_NOTIFICATION_CHANNELS_CLEAR = '[Applies_to] load notification channels clear';
class LoadNotificationTeams {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOAD_NOTIFICATION_TEAMS;
    }
}
exports.LoadNotificationTeams = LoadNotificationTeams;
class LoadNotificationTeamsSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOAD_NOTIFICATION_TEAMS_SUCCESS;
    }
}
exports.LoadNotificationTeamsSuccess = LoadNotificationTeamsSuccess;
class LoadNotificationTeamsFailure {
    constructor() {
        this.type = exports.LOAD_NOTIFICATION_TEAMS_FAILURE;
    }
}
exports.LoadNotificationTeamsFailure = LoadNotificationTeamsFailure;
class LoadNotificationTeamsClear {
    constructor() {
        this.type = exports.LOAD_NOTIFICATION_TEAMS_CLEAR;
    }
}
exports.LoadNotificationTeamsClear = LoadNotificationTeamsClear;
class LoadNotificationChannels {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOAD_NOTIFICATION_CHANNELS;
    }
}
exports.LoadNotificationChannels = LoadNotificationChannels;
class LoadNotificationChannelsSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOAD_NOTIFICATION_CHANNELS_SUCCESS;
    }
}
exports.LoadNotificationChannelsSuccess = LoadNotificationChannelsSuccess;
class LoadNotificationChannelsFailure {
    constructor() {
        this.type = exports.LOAD_NOTIFICATION_CHANNELS_FAILURE;
    }
}
exports.LoadNotificationChannelsFailure = LoadNotificationChannelsFailure;
class LoadNotificationChannelsClear {
    constructor() {
        this.type = exports.LOAD_NOTIFICATION_CHANNELS_CLEAR;
    }
}
exports.LoadNotificationChannelsClear = LoadNotificationChannelsClear;
