"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const core_1 = require("./services/core");
const legacy_adcon_service_1 = require("./services/legacy-adcon/legacy-adcon.service");
const preferences_modal_service_1 = require("./containers/preferences/preferences-modal.service");
const components_1 = require("@mimecast-ui/components");
const components_2 = require("@mimecast-ui/components");
const user_info_api_service_1 = require("./services/user-info-api/user-info-api.service");
const capabilities_service_1 = require("../components/capabilities/capabilities.service");
const translate_source_service_1 = require("../components/translate-config/translate-source.service");
angular
    .module('core.service', [])
    .factory('CoreService', static_1.downgradeInjectable(core_1.CoreService))
    .factory('PreferencesModalService', static_1.downgradeInjectable(preferences_modal_service_1.PreferencesModalService))
    .factory('LegacyAdconService', static_1.downgradeInjectable(legacy_adcon_service_1.LegacyAdconService))
    .factory('ModalService', static_1.downgradeInjectable(components_1.ModalService))
    .factory('UserInfoApiService', static_1.downgradeInjectable(user_info_api_service_1.UserInfoApiService))
    .factory('FeedbackModalService', static_1.downgradeInjectable(components_2.FeedbackModalService))
    .factory('UserCapabilitiesService', static_1.downgradeInjectable(capabilities_service_1.CapabilitiesService))
    .factory('TranslateSourceService', static_1.downgradeInjectable(translate_source_service_1.TranslateSourceService))
    .factory('sharedLoader', [
    'TranslateSourceService',
    (translateSourceService) => {
        return (options) => {
            return translateSourceService.getLanguageData(options.lang);
        };
    }
]);
