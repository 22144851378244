"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.integrationOktaEvidenceBasedControlState = exports.integrationOktaState = exports.integrationCrowdstrikeState = exports.integrationWildfireState = exports.integrationCreateState = exports.integrationListState = void 0;
const integrationListState = require("./list.reducers");
exports.integrationListState = integrationListState;
const integrationCreateState = require("./create.reducers");
exports.integrationCreateState = integrationCreateState;
const integrationWildfireState = require("./wildfire.reducers");
exports.integrationWildfireState = integrationWildfireState;
const integrationCrowdstrikeState = require("./crowdstrike.reducers");
exports.integrationCrowdstrikeState = integrationCrowdstrikeState;
const integrationOktaState = require("./okta.reducers");
exports.integrationOktaState = integrationOktaState;
const integrationOktaEvidenceBasedControlState = require("./okta-evidence-based-control.reducers");
exports.integrationOktaEvidenceBasedControlState = integrationOktaEvidenceBasedControlState;
