"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const create_definition_component_1 = require("./components/create-definition/create-definition.component");
const definition_page_component_1 = require("./containers/definition-page.component");
angular
    .module('attachmentProtectionDefinition', [])
    .directive('mcDefinitionPage', static_1.downgradeComponent({
    component: definition_page_component_1.DefinitionPageComponent
}))
    .directive('mcAppCreateDefinition', static_1.downgradeComponent({
    component: create_definition_component_1.CreateDefinitionComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider.state('attachment-protection-definition-list', {
            url: '/ttp-attachment-definition/definition-list',
            data: {
                capabilities: 'Permission.DEFINITION_READ' &&
                    'Temporary.Services.AttachmentProtection.DefinitionList',
                isFixedLayout: false,
                breadcrumbs: [
                    {
                        label: '$I18N_MEGA_MENU_GROUP_ATTACHMENT_PROTECTION_CONFIGURE_SERVICES'
                    },
                    {
                        label: '$I18N_MEGA_MENU_GROUP_ATTACHMENT_PROTECTION_CONFIGURE_ATTACHMENT_PROTECT'
                    },
                    {
                        label: '$I18N_ADMINISTRATION_MENU_ATTACHMENT_PROTECTION_ACTIONS_DEFINITIONS'
                    }
                ],
                checkProgress: false,
                tabId: 'Attachment Protect Definition',
                tabReload: true,
                tabTitle: '$I18N_MEGA_MENU_SERVICES_TTP_ATTACHMENT_PROTECTION_DEFINITIONS'
            },
            views: {
                main: {
                    template: '<mc-definition-page></mc-definition-page>'
                }
            }
        });
        $stateProvider.state('attachment-protection-create-definition', {
            url: '/ttp-attachment-definition/create-definition',
            data: {
                capabilities: 'Permission.POLICIES_EDIT',
                isFixedLayout: false,
                breadcrumbs: [
                    {
                        label: '$I18N_MEGA_MENU_GROUP_ATTACHMENT_PROTECTION_CONFIGURE_SERVICES'
                    },
                    {
                        label: '$I18N_MEGA_MENU_GROUP_ATTACHMENT_PROTECTION_CONFIGURE_ATTACHMENT_PROTECT'
                    },
                    {
                        label: '$I18N_ADMINISTRATION_MENU_ATTACHMENT_PROTECTION_ACTIONS_DEFINITIONS'
                    }
                ],
                checkProgress: false,
                tabId: 'Attachment Protect Definition',
                tabTitle: '$I18N_MEGA_MENU_SERVICES_TTP_ATTACHMENT_PROTECTION_POLICIES'
            },
            views: {
                main: {
                    template: '<mc-app-create-definition></mc-app-create-definition>'
                }
            }
        });
    }
]);
