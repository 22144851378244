"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MepPolicyEnableConfirmModalClose = exports.MepPolicyEnableConfirmModalOpen = exports.MepPolicyListDisablePolicyFailure = exports.MepPolicyListEnablePolicyFailure = exports.MepPolicyListDisablePolicySuccess = exports.MepPolicyListEnablePolicySuccess = exports.MepPolicyListDisablePolicy = exports.MepPolicyListEnablePolicy = exports.MepPolicyListSidebarClose = exports.MepPolicyListSidebarOpen = exports.MepPolicyListSidebarActionsEnum = void 0;
var MepPolicyListSidebarActionsEnum;
(function (MepPolicyListSidebarActionsEnum) {
    MepPolicyListSidebarActionsEnum["MEP_POLICY_LIST_SIDEBAR_OPEN"] = "[MEP] MEP Policy List Sidebar Open";
    MepPolicyListSidebarActionsEnum["MEP_POLICY_LIST_SIDEBAR_CLOSE"] = "[MEP] MEP Policy List Sidebar Close";
    MepPolicyListSidebarActionsEnum["MEP_POLICY_LIST_SIDEBAR_ENABLE_POLICY"] = "[MEP] MEP Policy List Enable Policy";
    MepPolicyListSidebarActionsEnum["MEP_POLICY_LIST_SIDEBAR_DISABLE_POLICY"] = "[MEP] MEP Policy List Disable Policy";
    MepPolicyListSidebarActionsEnum["MEP_POLICY_LIST_SIDEBAR_ENABLE_POLICY_SUCCESS"] = "[MEP] MEP Policy List Enable Policy Success";
    MepPolicyListSidebarActionsEnum["MEP_POLICY_LIST_SIDEBAR_DISABLE_POLICY_SUCCESS"] = "[MEP] MEP Policy List Disable Policy Success";
    MepPolicyListSidebarActionsEnum["MEP_POLICY_LIST_SIDEBAR_ENABLE_POLICY_FAILURE"] = "[MEP] MEP Policy List Enable Policy Failure";
    MepPolicyListSidebarActionsEnum["MEP_POLICY_LIST_SIDEBAR_DISABLE_POLICY_FAILURE"] = "[MEP] MEP Policy List Disable Policy Failure";
    MepPolicyListSidebarActionsEnum["MEP_POLICY_ENABLE_CONFIRM_MODAL_OPEN"] = "[MEP Policy] MEP Enable Policy Confirm Modal Open";
    MepPolicyListSidebarActionsEnum["MEP_POLICY_ENABLE_CONFIRM_MODAL_CLOSE"] = "[MEP Policy] MEP Enable Policy Confirm Modal Close";
})(MepPolicyListSidebarActionsEnum = exports.MepPolicyListSidebarActionsEnum || (exports.MepPolicyListSidebarActionsEnum = {}));
class MepPolicyListSidebarOpen {
    constructor(payload) {
        this.payload = payload;
        this.type = MepPolicyListSidebarActionsEnum.MEP_POLICY_LIST_SIDEBAR_OPEN;
    }
}
exports.MepPolicyListSidebarOpen = MepPolicyListSidebarOpen;
class MepPolicyListSidebarClose {
    constructor() {
        this.type = MepPolicyListSidebarActionsEnum.MEP_POLICY_LIST_SIDEBAR_CLOSE;
    }
}
exports.MepPolicyListSidebarClose = MepPolicyListSidebarClose;
class MepPolicyListEnablePolicy {
    constructor(payload) {
        this.payload = payload;
        this.type = MepPolicyListSidebarActionsEnum.MEP_POLICY_LIST_SIDEBAR_ENABLE_POLICY;
    }
}
exports.MepPolicyListEnablePolicy = MepPolicyListEnablePolicy;
class MepPolicyListDisablePolicy {
    constructor(payload) {
        this.payload = payload;
        this.type = MepPolicyListSidebarActionsEnum.MEP_POLICY_LIST_SIDEBAR_DISABLE_POLICY;
    }
}
exports.MepPolicyListDisablePolicy = MepPolicyListDisablePolicy;
class MepPolicyListEnablePolicySuccess {
    constructor() {
        this.type = MepPolicyListSidebarActionsEnum.MEP_POLICY_LIST_SIDEBAR_ENABLE_POLICY_SUCCESS;
    }
}
exports.MepPolicyListEnablePolicySuccess = MepPolicyListEnablePolicySuccess;
class MepPolicyListDisablePolicySuccess {
    constructor() {
        this.type = MepPolicyListSidebarActionsEnum.MEP_POLICY_LIST_SIDEBAR_DISABLE_POLICY_SUCCESS;
    }
}
exports.MepPolicyListDisablePolicySuccess = MepPolicyListDisablePolicySuccess;
class MepPolicyListEnablePolicyFailure {
    constructor() {
        this.type = MepPolicyListSidebarActionsEnum.MEP_POLICY_LIST_SIDEBAR_DISABLE_POLICY_FAILURE;
    }
}
exports.MepPolicyListEnablePolicyFailure = MepPolicyListEnablePolicyFailure;
class MepPolicyListDisablePolicyFailure {
    constructor() {
        this.type = MepPolicyListSidebarActionsEnum.MEP_POLICY_LIST_SIDEBAR_DISABLE_POLICY_FAILURE;
    }
}
exports.MepPolicyListDisablePolicyFailure = MepPolicyListDisablePolicyFailure;
class MepPolicyEnableConfirmModalOpen {
    constructor(payload) {
        this.payload = payload;
        this.type = MepPolicyListSidebarActionsEnum.MEP_POLICY_ENABLE_CONFIRM_MODAL_OPEN;
    }
}
exports.MepPolicyEnableConfirmModalOpen = MepPolicyEnableConfirmModalOpen;
class MepPolicyEnableConfirmModalClose {
    constructor(payload) {
        this.payload = payload;
        this.type = MepPolicyListSidebarActionsEnum.MEP_POLICY_ENABLE_CONFIRM_MODAL_CLOSE;
    }
}
exports.MepPolicyEnableConfirmModalClose = MepPolicyEnableConfirmModalClose;
