"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImpersonationLogIdentifierPipe = void 0;
class ImpersonationLogIdentifierPipe {
    constructor(translateService) {
        this.translateService = translateService;
    }
    transform(value) {
        if (value instanceof Array) {
            return value.map(identifier => this.getTranslation(identifier));
        }
        else {
            return this.getTranslation(value);
        }
    }
    getTranslation(identifier) {
        switch (identifier) {
            case 'similar_internal_domain':
                return this.translateService.instant('$I18N_TTP_IMPERSONATION_LOG_IDENTIFIER_SIMILAR_INTERNAL_DOMAIN');
            case 'newly_observed_domain':
                return this.translateService.instant('$I18N_TTP_IMPERSONATION_LOG_IDENTIFIER_NEWLY_OBSERVED_DOMAIN');
            case 'internal_user_name':
                return this.translateService.instant('$I18N_TTP_IMPERSONATION_LOG_IDENTIFIER_INTERNAL_USER_NAME');
            case 'custom_name_list':
                return this.translateService.instant('$I18N_TTP_IMPERSONATION_LOG_IDENTIFIER_CUSTOM_NAME_LIST');
            case 'reply_address_mismatch':
                return this.translateService.instant('$I18N_TTP_IMPERSONATION_LOG_IDENTIFIER_REPLY-TO_ADDRESS_MISMATCH');
            case 'targeted_threat_dictionary':
                return this.translateService.instant('$I18N_TTP_IMPERSONATION_LOG_IDENTIFIER_TARGETED_THREAT_DICTIONARY');
            case 'similar_monitored_external_domain':
                return this.translateService.instant('$I18N_TTP_IMPERSONATION_LOG_IDENTIFIER_SIMILAR_MONITORED_EXTERNAL_DOMAIN');
            case 'custom_external_domain':
                return this.translateService.instant('$I18N_TTP_IMPERSONATION_LOG_IDENTIFIER_CUSTOM_EXTERNAL_DOMAIN');
            case 'mimecast_external_domain':
                return this.translateService.instant('$I18N_TTP_IMPERSONATION_LOG_IDENTIFIER_MIMECAST_EXTERNAL_DOMAIN');
            case 'advanced_custom_external_domain':
                return this.translateService.instant('$I18N_TTP_IMPERSONATION_LOG_IDENTIFIER_ADVANCED_CUSTOM_EXTERNAL_DOMAIN');
            case 'advanced_mimecast_external_domain':
                return this.translateService.instant('$I18N_TTP_IMPERSONATION_LOG_IDENTIFIER_ADVANCED_MIMECAST_EXTERNAL_DOMAIN');
            case 'advanced_similar_internal_domain':
                return this.translateService.instant('$I18N_TTP_IMPERSONATION_LOG_IDENTIFIER_ADVANCED_SIMILAR_INTERNAL_DOMAIN');
            default:
                return '';
        }
    }
}
exports.ImpersonationLogIdentifierPipe = ImpersonationLogIdentifierPipe;
