"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgExceptionsEditSidebarPageComponent = void 0;
const operators_1 = require("rxjs/operators");
const reducers = require("../../reducers");
const swg_exceptions_actions_1 = require("../../actions/swg-exceptions.actions");
const swg_exceptions_sidebar_modal_actions_1 = require("../../actions/swg-exceptions-sidebar-modal.actions");
class SwgExceptionsEditSidebarPageComponent {
    constructor(store, swgService) {
        this.store = store;
        this.swgService = swgService;
        this.exception$ = this.store.select(reducers.getExceptionItem);
        this.isApiProcessing$ = this.store.select(reducers.getIsApiProcessing);
        this.isEditing$ = this.store.select(reducers.getIsEditing);
        this.isEditing$.pipe(operators_1.first()).subscribe(value => {
            this.isEditing = value;
        });
        this.hasIPV6SwitchEnabled = this.swgService.hasIPV6Enabled();
    }
    closeSidePanel() {
        this.store.dispatch(new swg_exceptions_sidebar_modal_actions_1.SwgExceptionsSidebarCloseAction());
    }
    saveItem(exception) {
        if (this.isEditing) {
            this.store.dispatch(new swg_exceptions_actions_1.SwgUpdateExceptionAction(exception));
        }
        else {
            this.store.dispatch(new swg_exceptions_actions_1.SwgAddExceptionAction(exception));
        }
    }
}
exports.SwgExceptionsEditSidebarPageComponent = SwgExceptionsEditSidebarPageComponent;
