<mc-modal-header data-test="modal-header"
  headerTitle="{{'$I18N_ARCHIVE_SUPERVISION_LIST_RULES_TAB.RULE_DELETE_NOTIFICATION.TITLE' | translate:{ ruleName: ruleName } }}">
</mc-modal-header>

<mc-modal-body>
  <p *ngIf="lexiconName; else noLexicon" data-test="queue-with-lexicon-notification-message">
    {{'$I18N_ARCHIVE_SUPERVISION_LIST_RULES_TAB.RULE_DELETE_NOTIFICATION.QUEUE_AND_LEXICON_MESSAGE' | translate }}
  </p>
  <div class="linked-lexicon-and-queue">
    <p *ngIf="lexiconName" data-test="linked-lexicon"
      [innerHTML]="'$I18N_ARCHIVE_SUPERVISION_LIST_RULES_TAB.RULE_DELETE_NOTIFICATION.LINKED_LEXICON' | translate:{ lexiconName: lexiconName }">

    </p>
    <p data-test="linked-queue"
      [innerHTML]="'$I18N_ARCHIVE_SUPERVISION_LIST_RULES_TAB.RULE_DELETE_NOTIFICATION.LINKED_QUEUE' | translate:{ queueName: queueName }">
    </p>
  </div>
  <p class="notification-action" data-test="notification-action">
    {{'$I18N_ARCHIVE_SUPERVISION_LIST_RULES_TAB.RULE_DELETE_NOTIFICATION.ACTION' | translate}}
  </p>
</mc-modal-body>

<mc-modal-footer>
  <button data-test="cancel-button" type="button" class="btn btn-secondary" (click)="close(false)">
    {{ '$I18N_COMMON_BTN_CANCEL' | translate }}
  </button>

  <button data-test="delete-button" type="button" class="btn btn-danger" (click)="close(true)">
    {{ '$I18N_COMMON_BTN_DELETE' | translate }}
  </button>
</mc-modal-footer>


<ng-template #noLexicon>
  <p data-test="queue-only-notification-message">
  {{'$I18N_ARCHIVE_SUPERVISION_LIST_RULES_TAB.RULE_DELETE_NOTIFICATION.QUEUE_MESSAGE' | translate }}
  </p>
</ng-template>