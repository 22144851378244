"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserAwarenessDisplayPipe = void 0;
class UserAwarenessDisplayPipe {
    constructor(translate) {
        this.translate = translate;
    }
    transform(input, args) {
        // check for n/a state
        if (args.action === 'block') {
            return '-';
        }
        // check for disabled state
        if (input === true) {
            return this.translate.instant('$I18N_SERVICES_URL_PROTECTION.DISABLED');
        }
        if (input === false) {
            return this.translate.instant('$I18N_SERVICES_URL_PROTECTION.ENABLED');
        }
    }
}
exports.UserAwarenessDisplayPipe = UserAwarenessDisplayPipe;
