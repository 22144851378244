"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PolicyAsideComponent = void 0;
const core_1 = require("@angular/core");
const table_store_1 = require("@mimecast-ui/table-store");
const policy_wizard_models_1 = require("../../models/policy-wizard.models");
const listActions = require("../../actions/policies-list.actions");
const reducer = require("../../reducers/index");
const createActions = require("../../actions/policies-create.actions");
const editActions = require("../../actions/policies-edit.actions");
const operators_1 = require("rxjs/operators");
class PolicyAsideComponent extends table_store_1.TableRowDetailBaseComponent {
    constructor(store, detailsStore, fb, accountPackageService) {
        super(store, 'policiesListTable');
        this.detailsStore = detailsStore;
        this.fb = fb;
        this.accountPackageService = accountPackageService;
        this.canModifyPolicy = 'Permission.POLICIES_EDIT';
        this.closeAside = new core_1.EventEmitter();
        this.detailsPolicyForm = this.fb.group({
            enableDisablePolicy: [true]
        });
        this.hasIEPPackage = this.accountPackageService.hasInternalEmailProtectAccountPackage();
        this.isAAAOrFAAUser = this.accountPackageService.isAAAOrFAAUser();
        this.hasBrowserIsolation = this.accountPackageService.hasBrowserIsolationEnabled();
    }
    ngOnInit() {
        this.startSubsciptions();
    }
    startSubsciptions() {
        this.rowSubscription = this.selectedRow$.subscribe(row => {
            if (!row) {
                return;
            }
            this.selectedRow = row;
            this.detailsPolicyForm
                .get('enableDisablePolicy')
                .patchValue(row.status, { emitEvent: false });
        });
        this.indexInViewSubscribtion = this.indexInView$.subscribe(() => {
            if (!this.selectedRow.id) {
                return;
            }
            this.store.dispatch(new listActions.ClearPolicyDetailsAction());
            this.store.dispatch(new listActions.GetPolicyDetailsAction({ id: this.selectedRow.id }));
        });
        this.policyDetailsFullData$ = this.detailsStore.select(reducer.getSelectedRowFullData);
        this.policyDetailsFullDataSubscription = this.policyDetailsFullData$.subscribe(value => {
            if (value) {
                this.policyDetails = value;
            }
        });
        this.enableDisablePolicyValueChangeSubscription = this.detailsPolicyForm
            .get('enableDisablePolicy')
            .valueChanges.pipe(operators_1.debounceTime(500))
            .subscribe(value => {
            this.updateEditPolicyStatus(value);
        });
    }
    updateEditPolicyStatus(value) {
        this.store.dispatch(new editActions.EditPolicyStatusAction({
            id: this.policyDetails.policy.id,
            enabled: value
        }));
        this.detailsStore.dispatch(new listActions.PolicyStatusChangeAction(true));
    }
    onDuplicate() {
        this.store.dispatch(new createActions.SetPolicyWizardFlowTypeAction(policy_wizard_models_1.PolicyWizardFlowType.DUPLICATE));
        this.store.dispatch(new editActions.OpenCreatePolicyPageInEditMode(this.policyDetails));
        this.store.dispatch(new listActions.ClosePolicyDetailsSidePanelAction());
    }
    onEdit() {
        this.store.dispatch(new createActions.SetPolicyWizardFlowTypeAction(policy_wizard_models_1.PolicyWizardFlowType.EDIT));
        this.store.dispatch(new editActions.OpenCreatePolicyPageInEditMode(this.policyDetails));
        this.store.dispatch(new listActions.ClosePolicyDetailsSidePanelAction());
    }
    onDelete(policy) {
        this.store.dispatch(new listActions.PolicyDeleteAttemptAction(policy.id));
        this.store.dispatch(new listActions.ClosePolicyDetailsSidePanelAction());
    }
    onClose() {
        this.store.dispatch(new listActions.ClosePolicyDetailsSidePanelAction());
    }
    ngOnDestroy() {
        this.rowSubscription.unsubscribe();
        this.enableDisablePolicyValueChangeSubscription.unsubscribe();
        this.policyDetailsFullDataSubscription.unsubscribe();
        this.indexInViewSubscribtion.unsubscribe();
    }
}
exports.PolicyAsideComponent = PolicyAsideComponent;
