<mc-layout-detail-tabs
  keyTitle="$I18N_CERTIFICATE_AUTHORITY_PAGE_HEADER">
  <mc-help-container>
    <mc-help-link url="https://community.mimecast.com/docs/DOC-2955"></mc-help-link>
  </mc-help-container>
  <mc-tab-group>
    <mc-tab name="$I18N_CERTIFICATE_AUTHORITY_CERTIFICATE_TAB">
      <mc-certificate-authority></mc-certificate-authority>
    </mc-tab>
    <mc-tab name="$I18N_CERTIFICATE_AUTHORITY_DNS_TAB">
      <mc-dns-setup></mc-dns-setup>
    </mc-tab>
  </mc-tab-group>
</mc-layout-detail-tabs>

