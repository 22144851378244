"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOktaIntegationFolders = exports.getOktaAccessToken = exports.getRotateApiToken = exports.hasCreatedIntegration = exports.getOktaIntegration = exports.isBusy = exports.reducer = exports.initialState = void 0;
const actions = require("app-new/api-applications/actions");
const folder_helper_1 = require("app-new/api-applications/utils/folder-helper");
const uuid_1 = require("uuid");
const emptyOktaIntegration = {
    id: '',
    enabled: false,
    name: '',
    description: '',
    rootFolder: '',
    rootFolderDescription: '',
    contactAddresses: [],
    folderList: [],
    cell: '',
    accessToken: ''
};
exports.initialState = {
    isBusy: false,
    createdIntegration: false,
    oktaIntegration: {
        id: '',
        enabled: false,
        name: '',
        description: '',
        rootFolder: '',
        rootFolderDescription: '',
        contactAddresses: [],
        folderList: [],
        cell: '',
        accessToken: ''
    },
    rotateApiToken: false,
    oktaIntegrationFolders: []
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.IntegrationOktaActionTypes.CreateOktaIntegration:
            return Object.assign(Object.assign({}, state), { createdIntegration: false, oktaIntegration: {
                    id: 'test-id',
                    name: state.oktaIntegration.name,
                    description: state.oktaIntegration.description,
                    enabled: state.oktaIntegration.enabled,
                    rootFolder: state.oktaIntegration.rootFolder,
                    rootFolderDescription: state.oktaIntegration.rootFolderDescription,
                    contactAddresses: state.oktaIntegration.contactAddresses,
                    cell: state.oktaIntegration.cell,
                    folderList: state.oktaIntegration.folderList,
                    accessToken: state.oktaIntegration.accessToken
                }, rotateApiToken: false, oktaIntegrationFolders: [] });
        case actions.IntegrationOktaActionTypes.CreateOktaIntegrationSuccess:
        case actions.IntegrationOktaActionTypes.UpdateOktaIntegrationSuccess:
            return Object.assign(Object.assign({}, state), { oktaIntegration: action.payload });
        case actions.IntegrationOktaActionTypes.CancelOktaIntegrationWidget:
            return Object.assign(Object.assign({}, state), { oktaIntegration: Object.assign({}, emptyOktaIntegration), oktaIntegrationFolders: [], createdIntegration: false });
        case actions.IntegrationOktaActionTypes.SelectOktaIntegrationRootFolder:
            return Object.assign(Object.assign({}, state), { oktaIntegration: Object.assign(Object.assign({}, state.oktaIntegration), { rootFolder: action.payload.id, rootFolderDescription: action.payload.description, folderList: folder_helper_1.default.createFolderStructureForRequest(state.oktaIntegrationFolders[0], action.payload.id, action.payload.description) }) });
        case actions.IntegrationOktaActionTypes.EditOktaIntegrationDetails:
            return Object.assign(Object.assign({}, state), { oktaIntegration: Object.assign(Object.assign({}, state.oktaIntegration), { name: action.payload.name, description: action.payload.description }) });
        case actions.IntegrationOktaActionTypes.GetOktaIntegrationComplete:
            return Object.assign(Object.assign({}, state), { oktaIntegration: {
                    id: action.payload.id,
                    enabled: action.payload.enabled,
                    name: action.payload.name,
                    description: action.payload.description,
                    rootFolder: action.payload.rootFolderId,
                    rootFolderDescription: action.payload.rootFolderDescription,
                    contactAddresses: action.payload.contactAddresses,
                    cell: action.payload.cell,
                    folderList: folder_helper_1.default.createFolderStructureFromResponse(action.payload.folderList),
                    accessToken: action.payload.accessToken
                }, createdIntegration: true, rotateApiToken: false });
        case actions.IntegrationOktaActionTypes.AddEmailOktaIntegrationContacts:
            return Object.assign(Object.assign({}, state), { oktaIntegration: Object.assign(Object.assign({}, state.oktaIntegration), { contactAddresses: state.oktaIntegration.contactAddresses
                        ? [action.payload].concat(state.oktaIntegration.contactAddresses)
                        : [action.payload] }) });
        case actions.IntegrationOktaActionTypes.SelectOktaCell:
            return Object.assign(Object.assign({}, state), { oktaIntegration: Object.assign(Object.assign({}, state.oktaIntegration), { cell: action.payload }) });
        case actions.IntegrationOktaActionTypes.SwitchOktaIntegrationStatus:
            return Object.assign(Object.assign({}, state), { oktaIntegration: Object.assign(Object.assign({}, state.oktaIntegration), { enabled: action.payload }) });
        case actions.IntegrationOktaActionTypes.DeleteEmailOktaIntegrationContacts:
            return Object.assign(Object.assign({}, state), { oktaIntegration: Object.assign(Object.assign({}, state.oktaIntegration), { contactAddresses: state.oktaIntegration.contactAddresses.filter((email) => email !== action.payload) }) });
        case actions.IntegrationOktaActionTypes.RemoveOktaAccessToken:
            return Object.assign(Object.assign({}, state), { oktaIntegration: Object.assign({}, emptyOktaIntegration) });
        case actions.IntegrationOktaActionTypes.AddExtractedLdapFolders:
            return Object.assign(Object.assign({}, state), { oktaIntegrationFolders: [
                    folder_helper_1.default.arrayToTree(folder_helper_1.default.uniqueFolderList(state.oktaIntegrationFolders, action.payload))
                ] });
        case actions.IntegrationOktaActionTypes.AddLdapFolderToOkta:
            const newFolder = Object.assign({}, action.payload);
            newFolder.isNewFolderEntry = false;
            newFolder.folders = null;
            const removedPlaceholder = state.oktaIntegrationFolders.length > 0
                ? folder_helper_1.default.flatTree(state.oktaIntegrationFolders[0]).filter(f => f.description !== '' || !f.isNewFolderEntry)
                : [];
            const newTreeStructure = folder_helper_1.default.arrayToTree([...removedPlaceholder, newFolder]);
            return Object.assign(Object.assign({}, state), { oktaIntegrationFolders: [newTreeStructure] });
        case actions.IntegrationOktaActionTypes.CancelAddNewFolder:
            const onlyCreatedFolders = folder_helper_1.default.flatTree(state.oktaIntegrationFolders[0]).filter(f => f.description !== '' || !f.isNewFolderEntry);
            return Object.assign(Object.assign({}, state), { oktaIntegrationFolders: [folder_helper_1.default.arrayToTree(onlyCreatedFolders)] });
        case actions.IntegrationOktaActionTypes.CreatePlaceholderFolder:
            const placeholder = {
                id: uuid_1.v4(),
                parentId: action.payload,
                description: '',
                folderCount: 0,
                source: 'ldap',
                userCount: 0,
                showCreateButton: false,
                isNewFolderEntry: true,
                folders: []
            };
            const [root, ...rest] = folder_helper_1.default.flatTree(state.oktaIntegrationFolders[0]).filter(f => f.description !== '' || !f.isNewFolderEntry);
            const newTree = folder_helper_1.default.arrayToTree([root, placeholder, ...rest]);
            return Object.assign(Object.assign({}, state), { oktaIntegrationFolders: [newTree] });
        case actions.IntegrationOktaActionTypes.SwitchRotateApiToken:
            return Object.assign(Object.assign({}, state), { rotateApiToken: action.payload });
        case actions.IntegrationOktaActionTypes.DeleteOktaIntegrationSuccess:
            return Object.assign({}, exports.initialState);
        default:
            return state;
    }
}
exports.reducer = reducer;
const isBusy = (state) => state.isBusy;
exports.isBusy = isBusy;
const getOktaIntegration = (state) => state.oktaIntegration;
exports.getOktaIntegration = getOktaIntegration;
const hasCreatedIntegration = (state) => state.createdIntegration;
exports.hasCreatedIntegration = hasCreatedIntegration;
const getRotateApiToken = (state) => state.rotateApiToken;
exports.getRotateApiToken = getRotateApiToken;
const getOktaAccessToken = (state) => state.oktaIntegration.accessToken;
exports.getOktaAccessToken = getOktaAccessToken;
const getOktaIntegationFolders = (state) => state.oktaIntegrationFolders;
exports.getOktaIntegationFolders = getOktaIntegationFolders;
