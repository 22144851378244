"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SidePanelEffects = void 0;
const effects_1 = require("@ngrx/effects");
const components_1 = require("@mimecast-ui/components");
const operators_1 = require("rxjs/operators");
const add_domains_actions_1 = require("../../actions/add-domains.actions");
const edit_domain_actions_1 = require("../../actions/edit-domain.actions");
const external_domain_list_actions_1 = require("../../actions/external-domain-list.actions");
const add_domains_component_1 = require("../../containers/aside/add-domains/add-domains.component");
const edit_domain_page_component_1 = require("../../containers/aside/edit-domain/edit-domain-page.component");
const edit_domain_reducer_1 = require("../../reducers/edit-domain/edit-domain.reducer");
class SidePanelEffects {
    constructor(actions$, asideService) {
        this.actions$ = actions$;
        this.asideService = asideService;
        this.openAddDomainSidePanel$ = this.actions$.pipe(effects_1.ofType(external_domain_list_actions_1.ADD_DOMAINS), operators_1.filter(() => !this.sidePanelInstance), operators_1.switchMap(() => {
            this.sidePanelInstance = this.asideService.open(add_domains_component_1.AddDomainsComponent, this.sidePanelConfig);
            return this.sidePanelInstance.beforeClose().pipe(operators_1.tap(() => (this.sidePanelInstance = undefined)), operators_1.mergeMap(() => [new add_domains_actions_1.ClearAddDomainsState(), new add_domains_actions_1.ShowIsCreatingDomains()]));
        }));
        this.closeSidePanel$ = this.actions$.pipe(effects_1.ofType(add_domains_actions_1.CLOSE_ASIDE), operators_1.tap(() => {
            if (this.sidePanelInstance) {
                this.sidePanelInstance.close();
            }
        }));
        this.openEditDomainSidePanel$ = this.actions$.pipe(effects_1.ofType(edit_domain_actions_1.EDIT_DOMAIN), operators_1.filter(() => !this.sidePanelInstance), operators_1.switchMap(() => {
            const sidePanelConfig = new components_1.AsideConfig();
            sidePanelConfig.position = 'right';
            sidePanelConfig.size = 'md';
            sidePanelConfig.hasBackdrop = true;
            this.sidePanelInstance = this.asideService.open(edit_domain_page_component_1.EditDomainPageComponent, sidePanelConfig);
            return this.sidePanelInstance.beforeClose().pipe(operators_1.map(() => {
                this.sidePanelInstance = undefined;
                return new edit_domain_actions_1.SelectedRow(edit_domain_reducer_1.initialState.selectedRow);
            }));
        }));
    }
    get sidePanelConfig() {
        const sidePanelConfig = new components_1.AsideConfig();
        sidePanelConfig.position = 'right';
        sidePanelConfig.size = 'md';
        sidePanelConfig.hasBackdrop = true;
        return sidePanelConfig;
    }
}
__decorate([
    effects_1.Effect()
], SidePanelEffects.prototype, "openAddDomainSidePanel$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], SidePanelEffects.prototype, "closeSidePanel$", void 0);
__decorate([
    effects_1.Effect()
], SidePanelEffects.prototype, "openEditDomainSidePanel$", void 0);
exports.SidePanelEffects = SidePanelEffects;
