"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StateService = void 0;
const angular = require("angular");
class StateService {
    get $state() {
        if (!this.cache) {
            const injector = angular.element(document).injector();
            this.cache = injector.get('$state');
        }
        return this.cache;
    }
}
exports.StateService = StateService;
