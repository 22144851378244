"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CapabilitiesService = void 0;
const operators_1 = require("rxjs/operators");
const core_interfaces_1 = require("../../core/model/core.interfaces");
class CapabilitiesService {
    constructor(capabilities) {
        this.capabilities = capabilities;
    }
    /**
     * evaluates the boolean capabilities expresion examples
     * if capsExpression = "Archive.Search" returns true if the Archive Search cap is enabled
     * if capsExpression = "Gateway.OnHoldItems.Personal && !Archive.Search" returns true
     * if Gateway.OnHoldItems.Personal is enabled but not Archive.Search
     * if capsExpression = "Gateway.OnHoldItems.Personal || Archive.Search" returns true
     * if Gateway.OnHoldItems.Personal is enabled or Archive.Search is enabled
     *
     * @param capsExpression
     * @returns true or false depending on the expression
     */
    evalCapabilitiesExpression(capsExpression) {
        return this.capabilities.pipe(core_interfaces_1.isDefined(), operators_1.first(), operators_1.map(capabilities => {
            let expression = capsExpression.replace(/([\w\.]+)/g, (complete_match, capName) => {
                return this.hasCap(capName, capabilities) ? '1' : '0';
            });
            // for security remove anything is not 0 1 & | ! ()
            expression = expression.replace(/([^10&|!() ])/g, '');
            // eslint-disable-next-line
            return !!eval(expression);
        }));
    }
    areCapabilitiesLoaded() {
        let capsLoaded = false;
        this.capabilities.pipe(operators_1.first()).subscribe(() => (capsLoaded = true));
        return capsLoaded;
    }
    hasCapability(capName) {
        return this.capabilities.pipe(core_interfaces_1.isDefined(), operators_1.first(), operators_1.map((capabilities) => this.hasCap(capName, capabilities)));
    }
    ifCapability(caps, thenTap) {
        this.evalCapabilitiesExpression(caps)
            .pipe(operators_1.first(), operators_1.filter(val => val), operators_1.tap(() => thenTap()))
            .subscribe();
    }
    hasCap(capName, capabilities) {
        return (capabilities[capName] && capabilities[capName].enabled) || false;
    }
    hasAdditionalValues(capName, capabilities) {
        return (capabilities[capName] && capabilities[capName].additionalValues) || [];
    }
    hasEarlyAccessCap(capName, capabilities) {
        return (capabilities[capName] && capabilities[capName].earlyAccess) || false;
    }
    hasNewCap(capName, capabilities) {
        // @ts-ignore
        return (capabilities[capName] && capabilities[capName].new) || false;
    }
    hasAccountCodeCap(capName, capabilities, accountCode) {
        // @ts-ignore
        return ((capabilities[capName] &&
            capabilities[capName].enabled &&
            (!capabilities[capName].accountCodes ||
                capabilities[capName].accountCodes.indexOf(accountCode) !== -1)) ||
            false);
    }
    hasEarlyAccessCapability(capName) {
        return this.capabilities.pipe(core_interfaces_1.isDefined(), operators_1.first(), operators_1.map((capabilities) => this.hasEarlyAccessCap(capName, capabilities)));
    }
    hasAdditionalValuesCapability(capName) {
        return this.capabilities.pipe(core_interfaces_1.isDefined(), operators_1.first(), operators_1.map((capabilities) => this.hasAdditionalValues(capName, capabilities)));
    }
    hasNewCapability(capName) {
        return this.capabilities.pipe(core_interfaces_1.isDefined(), operators_1.first(), operators_1.map((capabilities) => this.hasNewCap(capName, capabilities)));
    }
    hasAccountCodeCapability(capName, accountCode) {
        return this.capabilities.pipe(core_interfaces_1.isDefined(), operators_1.first(), operators_1.map((capabilities) => this.hasAccountCodeCap(capName, capabilities, accountCode)));
    }
    /**
     * given a caps expression like "Archive.Search" or "Gateway.OnHoldItems.Personal && !Archive.Search"
     * returns true if any of the caps in the expression has earlyAccess regarless of any && ||
     * or ! which are ignored
     *
     * @param capsExpression
     * @returns
     */
    evalEarlyAccessCapabilitiesExpression(capsExpression) {
        return this.capabilities.pipe(core_interfaces_1.isDefined(), operators_1.first(), operators_1.map(capabilities => {
            let hasEarlyAccess = false;
            // @ts-ignore
            capsExpression.replace(/([\w\.]+)/g, (complete_match, capName) => {
                if (!hasEarlyAccess) {
                    hasEarlyAccess = this.hasEarlyAccessCap(capName, capabilities);
                }
            });
            return hasEarlyAccess;
        }));
    }
    evalNewCapabilitiesExpression(capsExpression) {
        return this.capabilities.pipe(core_interfaces_1.isDefined(), operators_1.first(), operators_1.map(capabilities => {
            let hasNew = false;
            // @ts-ignore
            capsExpression.replace(/([\w\.]+)/g, (complete_match, capName) => {
                if (!hasNew) {
                    hasNew = this.hasNewCap(capName, capabilities); // difference
                }
            });
            return hasNew;
        }));
    }
}
exports.CapabilitiesService = CapabilitiesService;
