"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfirmationModalComponent = void 0;
class ConfirmationModalComponent {
    constructor(overlayRef, translateService) {
        this.overlayRef = overlayRef;
        this.translateService = translateService;
        this.title = '';
    }
    ngOnInit() {
        this.createTitle();
    }
    createTitle() {
        const textDelete = this.translateService.instant('$I18N_MONITORED_EXTERNAL_DOMAINS_CONFIRMATION_MODAL.BUTTON_LABEL.DELETE');
        if (this.numberOfBulkItemsToDelete > 1) {
            const textDomains = this.translateService.instant('$I18N_MONITORED_EXTERNAL_DOMAINS_CONFIRMATION_MODAL.TEXT.DOMAINS');
            this.title = `${textDelete} ${this.numberOfBulkItemsToDelete} ${textDomains}?`;
        }
        else {
            this.title = `${textDelete} ${this.domainNameOfSingleItem}?`;
        }
    }
    onDelete() {
        this.overlayRef.close(true);
    }
    onCancel() {
        this.overlayRef.close(false);
    }
}
exports.ConfirmationModalComponent = ConfirmationModalComponent;
