"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserMailboxFallbackActionOptions = exports.UserMailboxOptions = exports.GatewayFallbackActionOptions = exports.GatewayActionOptions = exports.UserNotificationOptions = exports.SpreadsheetsWorksheetsOptions = exports.SpreadsheetsFormatOptions = exports.documentFormatOptions = exports.PreEmptiveFailureActionOptions = exports.configurationOptions = void 0;
const definitions_model_1 = require("../../models/definitions.model");
exports.configurationOptions = [
    {
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_DETAILS_STEP_LABEL.CONFIGURATION.OPTIONS.HEADEROPTION2',
        value: definitions_model_1.ConfigurationDetailOption.SAFEFILEONDEMAND
    },
    {
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_DETAILS_STEP_LABEL.CONFIGURATION.OPTIONS.HEADEROPTION1',
        value: definitions_model_1.ConfigurationDetailOption.SAFEFILE
    },
    {
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_DETAILS_STEP_LABEL.CONFIGURATION.OPTIONS.HEADEROPTION3',
        value: definitions_model_1.ConfigurationDetailOption.PREEXEMPTIVE
    },
    {
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_DETAILS_STEP_LABEL.CONFIGURATION.OPTIONS.HEADEROPTION4',
        value: definitions_model_1.ConfigurationDetailOption.DYNAMICCONFIGURATION
    }
];
exports.PreEmptiveFailureActionOptions = [
    {
        value: definitions_model_1.PreEmptiveFailureActionOption.ADMINHOLD,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.PREEMPTIVE.OPTIONS.ADMIN_HOLD'
    },
    {
        value: definitions_model_1.PreEmptiveFailureActionOption.BOUNCE,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.PREEMPTIVE.OPTIONS.BOUNCE'
    }
];
exports.documentFormatOptions = [
    {
        value: definitions_model_1.DocumentFormatOption.ORIGINALFORMAT,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.DOCUMENTFORMAT.OPTIONS.ORIGINALFORMAT'
    },
    {
        value: definitions_model_1.DocumentFormatOption.PDF,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.DOCUMENTFORMAT.OPTIONS.PDF'
    },
    {
        value: definitions_model_1.DocumentFormatOption.TIFF,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.DOCUMENTFORMAT.OPTIONS.TIFF'
    }
];
exports.SpreadsheetsFormatOptions = [
    {
        value: definitions_model_1.SpreadsheetsFormatOption.ORIGINALFORMAT,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.SPREADSHEETFORMAT.OPTIONS.ORIGINALFORMAT'
    },
    {
        value: definitions_model_1.SpreadsheetsFormatOption.PDF,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.SPREADSHEETFORMAT.OPTIONS.PDF'
    },
    {
        value: definitions_model_1.SpreadsheetsFormatOption.TIFF,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.SPREADSHEETFORMAT.OPTIONS.TIFF'
    },
    {
        value: definitions_model_1.SpreadsheetsFormatOption.CSV,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.SPREADSHEETFORMAT.OPTIONS.CSV'
    },
    {
        value: definitions_model_1.SpreadsheetsFormatOption.HTML,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.SPREADSHEETFORMAT.OPTIONS.HTML'
    },
    {
        value: definitions_model_1.SpreadsheetsFormatOption.HTMLMULTITAB,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.SPREADSHEETFORMAT.OPTIONS.HTMLMULTITAB'
    }
];
exports.SpreadsheetsWorksheetsOptions = [
    {
        value: true,
        label: '$I18N_SERVICES_CREATE_DEFINITION.INBOUND_SETTINGS.DOCUMENTS_TRANSCRIPTION.WORKSHEETS.OPTIONS.TRANSCRIBE_FIRST_WORKSHEET_ONLY'
    },
    {
        value: false,
        label: '$I18N_SERVICES_CREATE_DEFINITION.INBOUND_SETTINGS.DOCUMENTS_TRANSCRIPTION.WORKSHEETS.OPTIONS.TRANSCRIBE_ALL_WORKSHEETS'
    }
];
exports.UserNotificationOptions = [
    {
        value: definitions_model_1.UserNotificationOption.INTERNALSENDER,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.USERNOTIFICATION.OPTIONS.INTERNALSENDER'
    },
    {
        value: definitions_model_1.UserNotificationOption.INTERNALRECIPIENT,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.USERNOTIFICATION.OPTIONS.INTERNALRECIPIENT'
    },
    {
        value: definitions_model_1.UserNotificationOption.EXTERNALRECIPIENT,
        label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_INBOUND_SETTINGS_STEP_LABEL.USERNOTIFICATION.OPTIONS.EXTERNALSENDER'
    }
];
exports.GatewayActionOptions = [
    {
        value: definitions_model_1.GatewayActions.HOLD,
        label: '$I18N_SERVICES_CREATE_DEFINITION.OUTBOUND_SETTINGS.GATEWAY_ACTION.OPTIONS.HOLD'
    },
    {
        value: definitions_model_1.GatewayActions.BOUNCE,
        label: '$I18N_SERVICES_CREATE_DEFINITION.OUTBOUND_SETTINGS.GATEWAY_ACTION.OPTIONS.BOUNCE'
    },
    {
        value: definitions_model_1.GatewayActions.NONE,
        label: '$I18N_SERVICES_CREATE_DEFINITION.OUTBOUND_SETTINGS.GATEWAY_ACTION.OPTIONS.NONE'
    }
];
exports.GatewayFallbackActionOptions = [
    {
        value: definitions_model_1.GatewayFallbackActions.HOLD,
        label: '$I18N_SERVICES_CREATE_DEFINITION.OUTBOUND_SETTINGS.GATEWAY_FALLBACK_ACTION.OPTIONS.HOLD'
    },
    {
        value: definitions_model_1.GatewayFallbackActions.BOUNCE,
        label: '$I18N_SERVICES_CREATE_DEFINITION.OUTBOUND_SETTINGS.GATEWAY_FALLBACK_ACTION.OPTIONS.BOUNCE'
    },
    {
        value: definitions_model_1.GatewayFallbackActions.NONE,
        label: '$I18N_SERVICES_CREATE_DEFINITION.OUTBOUND_SETTINGS.GATEWAY_FALLBACK_ACTION.OPTIONS.NONE'
    }
];
exports.UserMailboxOptions = [
    {
        value: definitions_model_1.UserMailboxAction.NONE,
        label: '$I18N_SERVICES_CREATE_DEFINITION.OUTBOUND_SETTINGS.USER_MAILBOX_ACTION.OPTIONS.NONE'
    },
    {
        value: definitions_model_1.UserMailboxAction.REMOVEMESSAGE,
        label: '$I18N_SERVICES_CREATE_DEFINITION.OUTBOUND_SETTINGS.USER_MAILBOX_ACTION.OPTIONS.REMOVE_MESSAGE'
    },
    {
        value: definitions_model_1.UserMailboxAction.REMOVEATTACHMENT,
        label: '$I18N_SERVICES_CREATE_DEFINITION.OUTBOUND_SETTINGS.USER_MAILBOX_ACTION.OPTIONS.REMOVE_ATTACHMENT'
    }
];
exports.UserMailboxFallbackActionOptions = [
    {
        value: definitions_model_1.UserMailboxFallbackAction.NONE,
        label: '$I18N_SERVICES_CREATE_DEFINITION.OUTBOUND_SETTINGS.USER_MAILBOX_FALLBACK_ACTION.OPTIONS.NONE'
    },
    {
        value: definitions_model_1.UserMailboxFallbackAction.REMOVEMESSAGE,
        label: '$I18N_SERVICES_CREATE_DEFINITION.OUTBOUND_SETTINGS.USER_MAILBOX_FALLBACK_ACTION.OPTIONS.REMOVE_MESSAGE'
    },
    {
        value: definitions_model_1.UserMailboxFallbackAction.REMOVEATTACHMENT,
        label: '$I18N_SERVICES_CREATE_DEFINITION.OUTBOUND_SETTINGS.USER_MAILBOX_FALLBACK_ACTION.OPTIONS.REMOVE_ATTACHMENT'
    }
];
