"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgDetectionTimelineComponent = void 0;
const am4core = require("@amcharts/amcharts4/core");
const am4charts = require("@amcharts/amcharts4/charts");
class SwgDetectionTimelineComponent {
    constructor() {
        this.multilineChartConfiguration = null;
        this.chartLabelFormat = 'dd MMM';
        this.TOTAL_COLOR = '#134783';
        this.MALICIOUS_COLOR = '#d32f2f';
    }
    exportChart(type, fileName) {
        this.multiLineGraphChartComponent.exportChart(type, fileName);
    }
    ngOnInit() {
        this.configChart();
    }
    ngOnChanges(changes) {
        if (changes.hasOwnProperty('chartData')) {
            this.rerunChart();
        }
    }
    rerunChart() {
        this.multilineChartConfiguration = null;
        setTimeout(() => {
            this.configChart();
        }, 100);
    }
    configChart() {
        let maxScanned = 0;
        let maxDetected = 0;
        let maliciousExtraMax;
        if (maxDetected !== 0) {
            Object.keys(this.chartData).forEach(date => {
                const row = this.chartData[date];
                maxScanned = Math.max(row.totalScannedFiles, maxScanned);
                maxDetected = Math.max(row.detectedScannedFiles, maxDetected);
            });
            if (maxScanned / maxDetected >= 5) {
                maliciousExtraMax = maxDetected / 0.2;
            }
        }
        const gradientTotal = new am4core.LinearGradient();
        gradientTotal.addColor(am4core.color(this.TOTAL_COLOR), 0.4);
        gradientTotal.addColor(am4core.color('#fff'), 0);
        gradientTotal.rotation = 90;
        const gradientMalicious = new am4core.LinearGradient();
        gradientMalicious.addColor(am4core.color(this.MALICIOUS_COLOR), 0.4);
        gradientMalicious.addColor(am4core.color('#fff'), 0);
        gradientMalicious.rotation = 90;
        const legend = new am4charts.Legend();
        legend.useDefaultMarker = true;
        legend.position = 'bottom';
        const marker = legend.markers.template.children.getIndex(0);
        marker.align = 'center';
        marker.valign = 'middle';
        marker.height = 10;
        marker.width = 10;
        // @ts-ignore
        marker.cornerRadius(5, 5, 5, 5);
        marker.adapter.add('fill', (t, s) => s.dataItem && s.dataItem.dataContext['stroke']);
        this.multilineChartConfiguration = {
            maxZoomLevel: 1,
            showOnInit: true,
            legend,
            series: [
                {
                    name: 'Total Files',
                    yAxis: 'totalScannedFilesValueAxis',
                    type: 'LineSeries',
                    tooltipText: 'Scanned: [bold]{valueY}[/]',
                    sequencedInterpolation: true,
                    dataFields: {
                        valueY: 'totalScannedFiles',
                        dateX: 'date'
                    },
                    stroke: this.TOTAL_COLOR,
                    strokeWidth: 2,
                    tensionX: 0.85,
                    tensionY: 0.85,
                    fill: gradientTotal,
                    fillOpacity: 1,
                    tooltip: {
                        getFillFromObject: false,
                        pointerOrientation: 'vertical',
                        background: {
                            fill: am4core.color(this.TOTAL_COLOR),
                            fillOpacity: 1
                        }
                    }
                },
                {
                    name: 'Malicious Files',
                    yAxis: 'maliciousValueAxis',
                    type: 'LineSeries',
                    tooltipText: 'Malicious: [bold]{valueY}[/]',
                    // tooltipText: `{dateX}: [bold]{valueY} ${this.translate.instant('$I18N_MALICIOUS_FILES_CHART.REJECTED')}[/]`,
                    sequencedInterpolation: true,
                    dataFields: {
                        valueY: 'detectedScannedFiles',
                        dateX: 'date'
                    },
                    stroke: am4core.color(this.MALICIOUS_COLOR),
                    strokeWidth: 2,
                    tensionX: 0.85,
                    tensionY: 0.85,
                    fill: gradientMalicious,
                    fillOpacity: 1,
                    tooltip: {
                        getFillFromObject: false,
                        pointerOrientation: 'vertical',
                        background: {
                            fill: am4core.color(this.MALICIOUS_COLOR),
                            fillOpacity: 1
                        }
                    }
                }
            ],
            xAxes: [
                {
                    dateFormats: {
                        day: this.chartLabelFormat
                    },
                    periodChangeDateFormats: {
                        day: this.chartLabelFormat
                    },
                    cursorTooltipEnabled: false,
                    type: 'DateAxis',
                    dataFields: {
                        category: 'date'
                    },
                    baseInterval: {
                        timeUnit: 'date',
                        count: 1
                    }
                }
            ],
            yAxes: [
                {
                    id: 'totalScannedFilesValueAxis',
                    min: 0,
                    type: 'ValueAxis',
                    cursorTooltipEnabled: false,
                    baseInterval: {
                        timeUnit: 'totalScannedFiles',
                        count: 50
                    },
                    renderer: {
                        opposite: true,
                        line: {
                            strokeOpacity: 1,
                            strokeWidth: 2,
                            stroke: this.TOTAL_COLOR
                        }
                    }
                },
                {
                    id: 'maliciousValueAxis',
                    min: 0,
                    max: maliciousExtraMax,
                    strictMinMax: !!maliciousExtraMax,
                    type: 'ValueAxis',
                    cursorTooltipEnabled: false,
                    baseInterval: {
                        timeUnit: 'detectedScannedFiles',
                        count: 50
                    },
                    renderer: {
                        line: {
                            strokeOpacity: 1,
                            strokeWidth: 2,
                            stroke: this.MALICIOUS_COLOR
                        }
                    }
                }
            ]
        };
    }
}
exports.SwgDetectionTimelineComponent = SwgDetectionTimelineComponent;
