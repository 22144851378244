<mc-layout-modal-simple keyTitle="{{'$I18N_SERVICES_URL_PROTECTION_LOGS_URL_CHECK.MODAL_TITLE' | translate}}">
  <mc-body-container>
    <span>{{ url }}</span>
    <mc-check-url-scan-results
        [relaxed]="relaxed$ | async"
        [moderate]="moderate$ | async"
        [aggressive]="aggressive$ | async"
        [isLoading]="isLoading$ | async"
        [isModal]="true"></mc-check-url-scan-results>
  </mc-body-container>
  <mc-footer-container>
    <button type="button" class="btn btn-primary" (click)="onDone()">
      {{'$I18N_SERVICES_URL_PROTECTION_LOGS_URL_CHECK.MODAL_BUTTON' | translate}}
    </button>
  </mc-footer-container>
</mc-layout-modal-simple>