"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailApiService = void 0;
const operators_1 = require("rxjs/operators");
class EmailApiService {
    constructor(http) {
        this.http = http;
    }
    send(email) {
        return this.http
            .post('/api/email/send-email', email)
            .pipe(operators_1.map((response) => response.first));
    }
}
exports.EmailApiService = EmailApiService;
