"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isLoading = exports.isReportSubmissionSuccess = exports.isRetryEnabled = exports.isButtonEnabled = exports.reducer = exports.initialState = void 0;
const actions = require("../actions/report-submit-modal.action");
exports.initialState = {
    id: '',
    categoryCode: '',
    comment: '',
    uploadScreenshot: '',
    reportTo: '',
    safeOrMalicious: null,
    retryButton: false,
    isLoading: false,
    reportButtonEnabled: false,
    reportSubmissionSuccess: false,
    ScanResponse: {
        relaxed: { results: [{ screenshot: '' }] },
        aggressive: { results: [{ screenshot: '' }] },
        moderate: { results: [{ screenshot: '' }] }
    }
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.URL_SCAN_DETAILS_REPORT_SUBMIT:
            return Object.assign(Object.assign({}, state), { id: action.payload.id, reportButtonEnabled: true, isLoading: true, safeOrMalicious: action.payload.isMalicious, categoryCode: action.payload.categoryCode, comment: action.payload.comment });
        case actions.URL_SCAN_DETAILS_REPORT_SUBMIT_SUCCESS:
            return Object.assign(Object.assign({}, state), { isLoading: false, reportSubmissionSuccess: true });
        case actions.URL_SCAN_DETAILS_REPORT_SUBMIT_FAILURE:
            return Object.assign(Object.assign({}, state), { isLoading: false, reportSubmissionSuccess: false, retryButton: false });
        case actions.URL_SCAN_DETAILS_REPORT_SUBMIT_RESET:
            return Object.assign({}, exports.initialState);
        case actions.URL_SCAN_DETAILS_REPORT_SUBMIT_BUTTON_STATE:
            return Object.assign(Object.assign({}, state), { reportButtonEnabled: false, isLoading: false });
        case actions.URL_SCAN_DETAILS_REPORT_SUBMIT_RETRY_ENABLED:
            return Object.assign(Object.assign({}, state), { retryButton: action.payload, isLoading: false });
        case actions.URL_REPORT_SUCCESSFUL_RESET:
            return Object.assign(Object.assign({}, state), { reportSubmissionSuccess: false });
        default:
            return state;
    }
}
exports.reducer = reducer;
const isButtonEnabled = (state) => state.reportButtonEnabled;
exports.isButtonEnabled = isButtonEnabled;
const isRetryEnabled = (state) => state.retryButton;
exports.isRetryEnabled = isRetryEnabled;
const isReportSubmissionSuccess = (state) => state.reportSubmissionSuccess;
exports.isReportSubmissionSuccess = isReportSubmissionSuccess;
const isLoading = (state) => state.isLoading;
exports.isLoading = isLoading;
