<mc-layout-detail-wizard keyTitle="{{ wizardTitle() | translate }}">
  <mc-help-container>
    <mc-help-link url="https://community.mimecast.com/s/article/WildFire-Integration"></mc-help-link>
  </mc-help-container>

  <mc-wizard (changeStep)="changeStep()" [ngClass]="{ 'wizard-busy': isBusy }">
    <mc-wizard-step
      label="{{ '$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.DISCLAIMER.TITLE' | translate }}"
    >
      <mc-api-integration-wildfire-disclaimer-step [hidden]="isBusy"
       (onSwitchAcceptSharingInfo) = "switchAcceptSharingInfo($event)"
      >
      </mc-api-integration-wildfire-disclaimer-step>

      <div class="spinner-wrapper" *ngIf="isBusy">
        <mc-spinner></mc-spinner>
      </div>
    </mc-wizard-step>
    <mc-wizard-step
      label="{{ '$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.ACTIVATION.TITLE' | translate }}"
    >
      <mc-api-integration-wildfire-activation-step
        [region]="wildfireIntegration?.region"
        [apiKey]="wildfireIntegration?.apiKey"
        [isApiAndRegionVerified]="isApiAndRegionVerified"
        [isVerifyApiKeyButtonDisabled]="isVerifyApiKeyButtonDisabled"
        (onVerifyApiAndRegion)="verifyApiAndRegion($event)"
        (onRegionChange)="changeRegion($event)"
        [hidden]="isBusy"
      >
      </mc-api-integration-wildfire-activation-step>

      <div class="spinner-wrapper" *ngIf="isBusy">
        <mc-spinner></mc-spinner>
      </div>
    </mc-wizard-step>

    <mc-wizard-step
      label="{{ '$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.INDICATORS.TITLE' | translate }}"
    >
      <mc-api-integration-wildfire-indicators-step
        [indicators]="wildfireIntegration?.indicators"
        [hidden]="isBusy"
        (onSwitchAttachmentIndicator)="switchAttachmentIndicator($event)"
        (onSwitchMaliciousAttachmentIndicator)="switchMaliciousAttachmentIndicator($event)"
      >
      </mc-api-integration-wildfire-indicators-step>

      <div class="spinner-wrapper" *ngIf="isBusy">
        <mc-spinner></mc-spinner>
      </div>
    </mc-wizard-step>

    <mc-wizard-step
      label="{{ '$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.REMEDIATION.TITLE' | translate }}"
    >
      <mc-api-integration-wildfire-remediation-step
        [remediationRequired]="wildfireIntegration?.remediationRequired"
        [hidden]="isBusy"
        (onSwitchRemediation)="switchRemediation($event)"
      >
      </mc-api-integration-wildfire-remediation-step>

      <div class="spinner-wrapper" *ngIf="isBusy">
        <mc-spinner></mc-spinner>
      </div>
    </mc-wizard-step>

    <mc-wizard-step
      label="{{ '$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.NOTIFICATIONS.TITLE' | translate }}"
    >
      <mc-api-integration-wildfire-notifications-step
        [emails]="wildfireIntegration?.notificationAddresses"
        [groupsMap]="wildfireIntegration?.notificationGroups"
        [hidden]="isBusy"
        (onAddGroup)="addGroup($event)"
        (onDeleteGroup)="deleteGroup($event)"
        (onDeleteEmail)="deleteEmail($event)"
        (onVerifyAndAddEmail)="verifyAndAddEmail($event)"
      >
      </mc-api-integration-wildfire-notifications-step>

      <div class="spinner-wrapper" *ngIf="isBusy">
        <mc-spinner></mc-spinner>
      </div>
    </mc-wizard-step>

    <mc-wizard-step
      label="{{ '$I18N_API_INTEGRATION_WILDFIRE_WIZARD.STEPS.SUMMARY.TITLE' | translate }}"
    >
      <mc-api-integration-wildfire-summary-step
        [wildfireIntegration]="wildfireIntegration"
        [wildfirePrerequisitesError]="wildfirePrerequisitesError"
        [hidden]="isBusy"
        (onSwitchWildfireIntegrationStatus)="switchWildfireIntegrationStatus($event)"
      >
      </mc-api-integration-wildfire-summary-step>

      <div class="spinner-wrapper" *ngIf="isBusy">
        <mc-spinner></mc-spinner>
      </div>
    </mc-wizard-step>
  </mc-wizard>

  <mc-footer-container>
    <div class="pull-right no-select mc-button-footer">
      <button class="btn" (click)="cancel()">
        {{ '$I18N_API_INTEGRATION_WILDFIRE_WIZARD.BUTTONS.CANCEL' | translate }}
      </button>
      <button
        *ngIf="showPrevious()"
        class="btn btn-secondary panel-half-margin-right"
        [disabled]="isBusy"
        (click)="gotoPreviousStep()"
      >
        {{ '$I18N_API_INTEGRATION_WILDFIRE_WIZARD.BUTTONS.PREVIOUS' | translate }}
      </button>
      <button
        *ngIf="showNext()"
        class="btn btn-primary"
        [disabled]="isBusy || !isStepValid()"
        (click)="gotoNextStep()"
      >
        {{ '$I18N_API_INTEGRATION_WILDFIRE_WIZARD.BUTTONS.NEXT' | translate }}
      </button>
      <button
        *ngIf="showCreate()"
        class="btn btn-primary"
        [disabled]="isBusy || !isStepValid()"
        (click)="create()"
      >
        {{ '$I18N_API_INTEGRATION_WILDFIRE_WIZARD.BUTTONS.FINISH' | translate }}
      </button>
      <button
        *ngIf="showUpdate()"
        class="btn btn-primary"
        [disabled]="isBusy || !isStepValid()"
        (click)="update()"
      >
        {{ '$I18N_API_INTEGRATION_WILDFIRE_WIZARD.BUTTONS.SAVE_AND_CLOSE' | translate }}
      </button>

    </div>
  </mc-footer-container>
</mc-layout-detail-wizard>
