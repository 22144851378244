"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActivationStepComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const rxjs_1 = require("rxjs");
const validators_1 = require("../../../../../../validators");
class ActivationStepComponent {
    constructor(fb) {
        this.fb = fb;
        this.isVerificationInProgress = false;
        this.isVerified = false;
        this.isAuthenticationFailed = false;
        this.onVerifyOktaApiKey = new core_1.EventEmitter();
        this.destroy$ = new rxjs_1.Subject();
        this.form = this.fb.group({
            clientId: ['', [forms_1.Validators.required]],
            privateKey: ['', [forms_1.Validators.required]],
            baseUrl: ['', [forms_1.Validators.required, validators_1.validateUrl]]
        });
    }
    get isDisabled() {
        if (this.isAuthenticationFailed) {
            return false;
        }
        return (this.form.invalid ||
            (this.form.pristine && this.isVerified) ||
            this.isVerificationInProgress ||
            this.form.pristine);
    }
    get isValid() {
        if (this.isAuthenticationFailed) {
            return false;
        }
        return this.form.valid && !this.isVerificationInProgress && this.isVerified;
    }
    ngOnInit() {
        this.patchFormValues(this.integration);
    }
    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
    verifyOktaApiKey() {
        this.onVerifyOktaApiKey.emit(Object.assign(Object.assign({}, this.credentials), { privateKey: this.form.get('privateKey').value, baseUrl: this.form.get('baseUrl').value, clientId: this.form.get('clientId').value }));
        this.form.markAsPristine();
    }
    patchFormValues(integration) {
        if (!!integration) {
            ['clientId', 'baseUrl'].forEach(field => this.form.get(field).setValue(integration[field], { emitEvent: false }));
            this.credentials = { id: integration.id };
        }
    }
}
exports.ActivationStepComponent = ActivationStepComponent;
