<mc-modal-header headerTitle="{{ '$I18N_ONEDRIVE_BACKUPS.DELETE_BACKUP.HEADER' | translate :{backup:overlayData.backupString} }}"> </mc-modal-header>

<mc-modal-footer>
  <button type="button" class="btn btn-secondary" (click)="close(false)">
    {{ '$I18N_ONEDRIVE_BACKUPS.DELETE_BACKUP.CANCEL' | translate }}
  </button>

  <button type="button" class="btn btn-danger" (click)="close(true)">
    <i class="far fa-trash-alt" aria-hidden="true"></i>
    {{ '$I18N_ONEDRIVE_BACKUPS.DELETE_BACKUP.CONFIRM' | translate }}
  </button>
</mc-modal-footer>