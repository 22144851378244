"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GroupSelectorModule = void 0;
/**
 * TODO: Remove this module and replace its usage with a generic component for selecting groups.
 * This tech debt - the duplicated code and lack of tests - is a conscious tradeoff made after discussion with leadership.
 * The redundant naming ('Identity' prefix) is intentional to avoid accidental imports once the refactor is delivered.
 *
 * For more details see: https://mimecast.slack.com/archives/G0B450JMR/p1530465697000016
 */
class GroupSelectorModule {
}
exports.GroupSelectorModule = GroupSelectorModule;
