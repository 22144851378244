"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GroupedHeldMessagesChartComponent = void 0;
const core_1 = require("@angular/core");
class GroupedHeldMessagesChartComponent {
    constructor(translate) {
        this.translate = translate;
        this.clickedPieGroup = new core_1.EventEmitter();
    }
    clickedPie(heldGroupData) {
        // Emit event to inform Layout component that a pie slice was clicked
        this.clickedPieGroup.emit(heldGroupData);
    }
}
exports.GroupedHeldMessagesChartComponent = GroupedHeldMessagesChartComponent;
