'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const moment = require("moment");
document.createElement('mc-date-picker-inline');
angular.module('date.picker.inline.directive', [])
    .directive('mcDatePickerInline', function () {
    return {
        restrict: 'AE',
        require: 'ngModel',
        templateUrl: 'components/date-picker-inline/date-picker-inline-directive.tpl.html',
        scope: {
            options: '=mcOptions',
            ngModel: '='
        },
        link($scope, $element, $attr, ngModelCtrl) {
            let date = $scope.ngModel;
            const options = { todayHighlight: true };
            if (date) {
                date = moment(date);
                options.defaultViewDate = { year: date.year(), month: date.month(), day: date.date() };
            }
            $element.datepicker(options).on('changeDate', function (event) {
                ngModelCtrl.$setViewValue(event.date);
            });
        }
    };
});
