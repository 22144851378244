'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
angular.module('navbar.controller', [])
    .controller('NavbarController', ['$scope', '$rootScope', '$window', '$timeout',
    'sessionHandlerService', '$state', 'navbarService', 'modalChangePasswordService', 'modalFeedbackService',
    'APP_CONSTANTS', 'userService', 'megaMenuService', 'administrationService',
    '$q', 'applicationSwitcherService', '$translate', 'modalAccountSupportService', 'PreferencesModalService',
    '$location', 'FeedbackModalService', 'LegacyAdconService', 'capabilitiesService', '$http', 'UserInfoApiService', function ($scope, $rootScope, $window, $timeout, sessionHandlerService, $state, navbarService, modalChangePasswordService, modalFeedbackService, APP_CONSTANTS, userService, megaMenuService, administrationService, $q, applicationSwitcherService, $translate, modalAccountSupportService, preferencesModalService, $location, feedbackModalService, legacyAdconService, capabilitiesService, $http, userInfoApiService) {
        const self = this;
        /* -------------------------------------------------------- *\
     - interface
  \* -------------------------------------------------------- */
        const requiredUserCapabilities = [
            { name: 'Monitoring.Bulk', enabled: true },
            { name: 'Archive.Stubbing', enabled: true },
            { name: 'Archive.eDiscovery.LitigationHold', enabled: true },
            { name: 'Common.Update', enabled: true }
        ];
        const appVersion = 'MC navbar-controller';
        self.translate = translateText;
        self.showTooltip = [false, false, false]; // History, Favourites, App Switcher
        self.enableTooltip = [true, true, true]; // History, Favourites, App Switcher
        self.branding = $window.mimecast && $window.mimecast.branding;
        self.optionsActions = navbarService.getOptionActions;
        self.getAccountsMenuItems = navbarService.getAccountMenuItems;
        self.openMenu = navbarService.openMenu;
        self.hasCap = navbarService.hasCap;
        self.areMenusReady = navbarService.areMenusReady;
        modalChangePasswordService.setChangePasswordCallBackFn(sessionHandlerService.logOut);
        self.openChangeCloudPassword = modalChangePasswordService.openChangePasswordModal;
        self.openFeedback = modalFeedbackService.openFeedBackModal;
        self.callMenuItemFunction = callMenuItemFunction;
        self.openAccountSupportModal = modalAccountSupportService.openAccountSupportModal;
        self.feedbackForApp = $translate.instant(APP_CONSTANTS.APP_NAME);
        self.sendFeedbackToEmail = APP_CONSTANTS.FEEDBACK_EMAIL;
        self.togglePopUp = togglePopUp;
        self.hidePopUp = hidePopUp;
        self.noop = noop;
        self.openPreferences = openPreferences;
        self.gotoFaaRoles = gotoFaaRoles;
        self.checkSdOrFaa = checkSdOrFaa;
        self.hasExtraMenuItem = hasExtraMenuItem;
        // Improved Navigation
        self.setImprovedNavigation = setImprovedNavigation;
        self.currentAdminPreferences = null;
        userInfoApiService.getAdminPreferences().subscribe((data) => {
            if (data && data.preferences) {
                self.isImprovedNavigationEnabled = data.preferences.improvedNavigation || false;
                self.currentAdminPreferences = data.preferences;
            }
        });
        self.supportSelected = null;
        self.optionSelected = null;
        self.popOverCtrl = null;
        self.tabsHidden = false;
        self.getCapabilities = getCapabilities;
        self.getServiceMenuVisibility = navbarService.getServiceMenuVisibility;
        self.getServiceDeliveryMenuVisibility = navbarService.getServiceDeliveryMenuVisibility;
        self.evaluateVisibilityOfThisNavbarItem = evaluateVisibilityOfThisNavbarItem;
        self.getAAAAccountDetails = getAAAAccountDetails;
        self.here = applicationSwitcherService.constants.APP_KEYS.ADCON;
        self.appSwitcherIcon = applicationSwitcherService.constants.DEFAULT_APPLICATION_SWITCHER_ICON;
        self.preSwitchCallback = () => {
            legacyAdconService.createTempSwitchAccountCookie();
        };
        self.brandingTextColor =
            capabilitiesService.hasCapability('Temporary.Branding.Text.Color') &&
                this.branding.branded === true
                ? { 'color': this.branding.headerMenuTextColour }
                : '';
        self.brandingInvertedColor =
            capabilitiesService.hasCapability('Temporary.Branding.Text.Color') &&
                this.branding.branded === true
                ? {
                    'color': this.branding.headerMenuBackColour,
                    'background-color': this.branding.headerMenuTextColour
                }
                : '';
        /* -------------------------------------------------------- *\
       - initialisation
    \* -------------------------------------------------------- */
        navbarService.getNavbarMenuReadyPromise().then(function () {
            self.isAccessGranted = navbarService.isAccessGranted;
            self.user = navbarService.getUserData();
            self.user.name = self.user && (self.user.name === 'na' || self.user.name === '') ? undefined : self.user.name;
            self.user.role = self.user && (self.user.role === 'na' || self.user.role === '') ? undefined : self.user.role;
            // @ts-ignore
            self.user = Object.assign({}, self.user);
            self.sendFeedbackFromAddress = self.user.emailAddress;
            self.aaaAccountData = navbarService.getAaaAccountDetails();
            self.user.adminRole = getAdminRole();
            self.user.userAvatarIcon = navbarService.setUserAvatarIcon();
        });
        /* -------------------------------------------------------- *\
       - implementations
    \* -------------------------------------------------------- */
        function translateText($I18N_key) {
            return $translate.instant($I18N_key);
        }
        // -- togglePopUp --------
        function togglePopUp($event) {
            $event.stopPropagation();
            const img = angular.element('#user');
            $timeout(function () {
                if (img) {
                    img.click();
                }
            });
        }
        function hidePopUp($event) {
            $event.stopPropagation();
            const img = angular.element('#user');
            $timeout(function () {
                if (img) {
                    img.popover('hide');
                }
            });
        }
        function noop($event) {
            $event.stopPropagation();
        }
        function missingArgument() {
            throw new Error('navbar-controller - callMenuItemFunction, parameter missing');
        }
        function callMenuItemFunction(item = missingArgument()) {
            if (item.key === 'submittingTechnicalInformation') {
                const modal = feedbackModalService.open(requiredUserCapabilities, appVersion);
                modal.result().subscribe((feedbackResponse) => {
                    const data = [{
                            htmlBody: { content: feedbackResponse.message },
                            subject: feedbackResponse.caseReference,
                            to: [{ emailAddress: 'consolefeedback@mimecast.com' }]
                        }];
                    navbarService.requestFeedback(data);
                    modal.close();
                });
            }
            else if (!!item.link) {
                $window.open(item.link, '_blank');
            }
            else {
                if (item.fn) {
                    $scope.$eval(item.fn);
                }
            }
        }
        function getCapabilities() {
            if (capabilitiesService.areCapabilitiesLoaded()) {
                self.tabsHidden = capabilitiesService.hasCapability('Temporary.Hide.Tabs');
                return capabilitiesService.hasCapability('Temporary.BullHorn');
            }
            else {
                capabilitiesService.getCapabilities().then(function () {
                    self.tabsHidden = capabilitiesService.hasCapability('Temporary.Hide.Tabs');
                    return capabilitiesService.hasCapability('Temporary.BullHorn');
                });
            }
        }
        function evaluateVisibilityOfThisNavbarItem(itemLabel) {
            let isItemVisible = true;
            switch (itemLabel) {
                case '$I18N_NAVBAR_MENU_ITEM_ADMINISTRATION':
                    isItemVisible = self.getServiceMenuVisibility();
                    break;
                case 'NAVBAR_SEARCH_PLACEHOLDER':
                    isItemVisible = self.getServiceMenuVisibility();
                    break;
                case '$I18N_NAVBAR_MIMECASTER_CENTRAL_SEARCH_PLACEHOLDER':
                    isItemVisible = self.getServiceMenuVisibility();
                    break;
                case '$I18N_ADMINISTRATION_HISTORY_MENU_TEXT_1':
                    isItemVisible = self.getServiceMenuVisibility();
                    break;
                case '$I18N_MENU_TOP_FAVOURITE_TITLE':
                    isItemVisible = self.getServiceMenuVisibility();
                    break;
                case '$I18N_MODAL_WELCOME_WIZARD_APP_MENU_TITLE':
                    isItemVisible = applicationSwitcherService.getAvailableApplications().length > 0;
                    break;
                case 'icon':
                    isItemVisible = this.getCapabilities();
                    break;
                case 'openActions':
                    isItemVisible = self.optionsActions().length > 0;
                    break;
            }
            return self.isAccessGranted && isItemVisible;
        }
        function getAAAAccountDetails() {
            return self.aaaAccountData && self.aaaAccountData.detail ? self.aaaAccountData.detail : undefined;
        }
        function getAdminRole() {
            return self.aaaAccountData && self.aaaAccountData.delegateAccess ? $translate.instant(self.aaaAccountData.delegateAccess) : self.user.role || '';
        }
        function openPreferences() {
            preferencesModalService.open();
        }
        function gotoFaaRoles() {
            // { inherit: false } prevents legacy tab being reused.
            // ie. if an existing legacy tab was open, then a new legacy tab would reuse the id
            $state.go('legacy.faaroles', undefined, { inherit: false });
        }
        function checkSdOrFaa() {
            if (capabilitiesService.hasCapability('Permission.SERVICE_DELIVERY_READ')) {
                return true;
            }
            else {
                if (capabilitiesService.hasCapability('FederatedAdmin.Access') &&
                    (capabilitiesService.hasCapability('AccountType.EnterpriseMaster') ||
                        capabilitiesService.hasCapability('AccountType.EnterpriseGroup') ||
                        capabilitiesService.hasCapability('AccountType.EnterpriseMailProcessing') ||
                        capabilitiesService.hasCapability('AccountType.PartnerMaster') ||
                        capabilitiesService.hasCapability('AccountType.PartnerGroup') ||
                        capabilitiesService.hasCapability('AccountType.PartnerMailProcessing'))) {
                    return true;
                }
            }
            return false;
        }
        /* Check for SD or FAA account - it has extra menu item in the navbar */
        function hasExtraMenuItem() {
            if (capabilitiesService.areCapabilitiesLoaded()) {
                return self.checkSdOrFaa();
            }
            else {
                capabilitiesService.getCapabilities().then(function () {
                    return self.checkSdOrFaa();
                });
            }
            return false;
        }
        // set improvedNavigation in admin preferences
        function setImprovedNavigation() {
            self.isImprovedNavigationEnabled = !(self.currentAdminPreferences && !!self.currentAdminPreferences.improvedNavigation);
            $http.post('/api/admin/set-preferences', {
                preferences: Object.assign(Object.assign({}, self.currentAdminPreferences), { improvedNavigation: self.isImprovedNavigationEnabled })
            }).then(function () {
                $window.location.reload();
            });
        }
    }]);
