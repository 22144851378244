"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MepLogListImplService = void 0;
const table_store_1 = require("@mimecast-ui/table-store");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const list_static_values_1 = require("../logs/components/list/list.static-values");
class MepLogListImplService {
    constructor(http, logsApiService) {
        this.http = http;
        this.logsApiService = logsApiService;
        this.switchToTab = new rxjs_1.Subject();
        this.switchToTab$ = this.switchToTab.asObservable();
    }
    getData(payload, pagination = table_store_1.defaultPaginationOption) {
        let searchQuery = {};
        if (!payload) {
            searchQuery = list_static_values_1.defaultSearchQuery;
        }
        else if (!payload.from && !payload.to) {
            searchQuery = Object.assign(Object.assign({}, payload), list_static_values_1.defaultSearchQuery);
        }
        else {
            searchQuery = payload;
        }
        const config = Object.assign(Object.assign({}, searchQuery), { meta: {
                pagination
            } });
        return this.logsApiService.getMepLogs(config).pipe(operators_1.map((response) => {
            return {
                hasErrors: response.hasErrors,
                data: this.mapLogData(response.first),
                failures: response.fail,
                meta: response.meta
            };
        }));
    }
    filter(searchQuery, pagination) {
        return this.getData(searchQuery, pagination);
    }
    openItem() {
        return null;
    }
    goToTab(tabName) {
        this.switchToTab.next(tabName);
    }
    clearTabState() {
        this.switchToTab.next(null);
    }
    mapLogData(logs) {
        return logs.mepActiveViews.map(logItem => {
            return {
                id: logItem.id,
                to: logItem.toAddress,
                fromAddress: logItem.fromAddress,
                toAddress: logItem.toAddress,
                flaggedToAddress: logItem.flaggedToAddress,
                toAddressCount: logItem.toAddressCount,
                flaggedToAddressCount: logItem.flaggedToAddressCount,
                subject: logItem.subject,
                status: logItem.status,
                createTime: logItem.createTime,
                messageId: logItem.messageId
            };
        });
    }
    formatRecipients(recipient, recipientCount) {
        if (recipientCount > 1) {
            return `${recipient} +${recipientCount - 1}`;
        }
        else {
            return recipient;
        }
    }
}
exports.MepLogListImplService = MepLogListImplService;
