"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OktaEvidenceBasedControlCredentialExpiredModalComponent = void 0;
class OktaEvidenceBasedControlCredentialExpiredModalComponent {
    constructor(overlayRef) {
        this.overlayRef = overlayRef;
    }
    close(confirmed) {
        this.overlayRef.close(confirmed);
    }
}
exports.OktaEvidenceBasedControlCredentialExpiredModalComponent = OktaEvidenceBasedControlCredentialExpiredModalComponent;
