"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgDeviceGroupsListApiService = void 0;
const operators_1 = require("rxjs/operators");
class SwgDeviceGroupsListApiService {
    constructor(http) {
        this.http = http;
    }
    getDeviceGroupsList(config) {
        return this.http.post('/api/swg/get-device-groups', config);
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getDevicesInGroup(groupId) {
        // ignore: this is a temp api call, will use correct one once it is ready
        const config = {
            data: [{}],
            meta: { pagination: { pageSize: 50 } }
        };
        return this.http.post('/api/swg/get-endpoints', config);
    }
    createDeviceGroup(deviceGroup) {
        if (deviceGroup.id) {
            const group = {
                name: deviceGroup.name,
                id: deviceGroup.id,
                description: deviceGroup.description,
                devicesToAdd: deviceGroup.devicesToAdd,
                devicesToRemove: deviceGroup.devicesToRemove
            };
            return this.http.post('/api/swg/update-device-group', { data: [group] });
        }
        else {
            const group = {
                name: deviceGroup.name,
                description: deviceGroup.description,
                deviceIds: deviceGroup.devicesToAdd
            };
            return this.http.post('/api/swg/create-device-group', { data: [group] });
        }
    }
    deleteDeviceGroup(id) {
        return this.http
            .post('/api/swg/delete-device-group', { id })
            .pipe(operators_1.map(res => res.first));
    }
}
exports.SwgDeviceGroupsListApiService = SwgDeviceGroupsListApiService;
