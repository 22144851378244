<div class="mc-api-application-step-block">
    <mc-wizard-step-header [label]="'$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.SETTINGS.HEADER'">
    </mc-wizard-step-header>
    <form
        class="form-horizontal"
        mcDefaultLabelClass="col-sm-2"
        mcDefaultControlContainerClass="col-sm-6"
        [formGroup]="form"
        >
    <div class="mc-detailed-list mc-api-integration-okta-settings-block">

            <div class="col-sm-8 mc-api-integration-okta-settings-folder-description">
                {{'$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.SETTINGS.' + (isEditMode ? 'SELECTED_DIRECTORY_LOCATION' : 'DIRECTORY_LOCATION') | translate }}
                <div class="mc-api-integration-okta-settings-description">
                    {{'$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.SETTINGS.DIRECTORY_LOCATION_DESCRIPTION' | translate }}
                </div>
            </div>

            <div class="col-sm-8 no-padding-bottom mc-api-integration-okta-settings-folder-selector">
                <div class="col-sm-12 no-padding-left">
                    <div class="selected-folder no-margin-top no-margin-bottom">
                        <span class="folder-list">
                            <div class="folder-name" data-test="selected-folder">
                            {{ description | translate }}
                            </div>
                        </span>
                        <hr class="groups-separator no-margin-bottom no-margin-top" />
                    </div>

                    <button *ngIf="!isEditMode" type="button" class="btn btn-default panel-half-margin-top"
                            (click)="openSidebar()" data-test="select-directory-location">
                        {{ '$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.SETTINGS.DIRECTORY_SELECT_LABEL' | translate }}
                    </button>
                </div>
            </div>

            <div class="col-sm-8 mc-api-integration-okta-settings-cell">
                {{'$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.SETTINGS.SELECT_YOUR_OKTA_CELL' | translate }}
                <div class="mc-api-integration-okta-settings-cell-description">
                    {{'$I18N_API_INTEGRATION_OKTA_WIZARD.STEPS.SETTINGS.SELECT_YOUR_OKTA_CELL_DESCRIPTION' | translate }}
                </div>

                <mc-field>
                    <mc-select [options]="cellOptions" [defaultValue]="form.value.oktaCell" formControlName="oktaCell" ></mc-select>
                </mc-field>
            </div>

    </div>
    </form>
</div>
