"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApEditPolicyService = void 0;
const policy_list_model_1 = require("../models/policy-list.model");
const moment = require("moment");
class ApEditPolicyService {
    mapApiResponseToInboundStep(inboundPolicyDetails) {
        return {
            action: inboundPolicyDetails.action,
            internalForward: inboundPolicyDetails.internalForward,
            removeActiveContent: inboundPolicyDetails.removeActiveContent,
            ignoreSignedMessages: inboundPolicyDetails.ignoreSignedMessages,
            preEmptiveFailureAction: inboundPolicyDetails.preEmptiveFailureAction,
            safeDocFormat: inboundPolicyDetails.safeDocFormat,
            safeSpreadsheetFormat: inboundPolicyDetails.safeSpreadsheetFormat,
            notifyInternalSender: inboundPolicyDetails.notifyInternalSender,
            notifyInternalRecipient: inboundPolicyDetails.notifyInternalRecipient,
            notifyExternalSender: inboundPolicyDetails.notifyExternalSender
        };
    }
    mapApiResponseToInboundGroup(apInboundGroup) {
        return apInboundGroup || null;
    }
    mapApiResponseToJournalStep(journalPolicyDetails) {
        return {
            enableJournalCheck: journalPolicyDetails.checkJournal,
            userMailboxAction: journalPolicyDetails.journalRemediationAction,
            userMailboxFallbackAction: journalPolicyDetails.journalFallbackRemediationAction,
            internalSenderJournal: journalPolicyDetails.journalNotifyInternalSender,
            internalRecipientJournal: journalPolicyDetails.journalNotifyInternalRecipient
        };
    }
    mapApiResponseToJournalGroup(apJournalGroup) {
        return apJournalGroup ? apJournalGroup : null;
    }
    mapApiResponseToIpAddress(data) {
        const arrayIpAddress = [];
        if (data.policies[0].policy.conditions.sourceIPs) {
            data.policies[0].policy.conditions.sourceIPs.forEach(ipAddress => {
                arrayIpAddress.push({ apIpRange: ipAddress });
            });
        }
        return arrayIpAddress;
    }
    mapApiResponseToAppliesToSteps(appliesToDeatils) {
        const policyRuleArray = [];
        for (const policyRule of appliesToDeatils.policies) {
            policyRuleArray.push({
                apAddressesBasedOn: appliesToDeatils.policies[policyRule].policy.fromPart,
                apAppliesFrom: appliesToDeatils.policies[policyRule].policy.from.type,
                apAppliesTo: appliesToDeatils.policies[policyRule].policy.to.type,
                apEmailFrom: appliesToDeatils.policies[policyRule].policy.from.type === 'individual_email_address'
                    ? appliesToDeatils.policies[policyRule].policy.from.emailAddress
                    : '',
                apEmailTo: appliesToDeatils.policies[policyRule].policy.to.type === 'individual_email_address'
                    ? appliesToDeatils.policies[policyRule].policy.to.emailAddress
                    : '',
                apDomainFrom: appliesToDeatils.policies[policyRule].policy.from.type === 'email_domain'
                    ? appliesToDeatils.policies[policyRule].policy.from.emailDomain
                    : '',
                apDomainTo: appliesToDeatils.policies[policyRule].policy.to.type === 'email_domain'
                    ? appliesToDeatils.policies[policyRule].policy.to.emailDomain
                    : '',
                apGroupFrom: appliesToDeatils.policies[policyRule].policy.from.type === 'profile_group'
                    ? appliesToDeatils.policies[policyRule].policy.from.group
                    : '',
                apGroupTo: appliesToDeatils.policies[policyRule].policy.to.type === 'profile_group'
                    ? appliesToDeatils.policies[policyRule].policy.to.group
                    : '',
                apAttributeOptionFrom: appliesToDeatils.policies[policyRule].policy.from.type === 'address_attribute_value'
                    ? appliesToDeatils.policies[policyRule].policy.from.attribute
                    : '',
                apAttributeOptionTo: appliesToDeatils.policies[policyRule].policy.to.type === 'address_attribute_value'
                    ? appliesToDeatils.policies[policyRule].policy.to.attribute
                    : ''
            });
        }
        return {
            override: appliesToDeatils.policies[0] ? appliesToDeatils.policies[0].policy.override : false,
            attachmentPolicyRule: policyRuleArray,
            apIpRange: '',
            apBidirectional: appliesToDeatils.policies[0]
                ? appliesToDeatils.policies[0].policy.bidirectional
                : false
        };
    }
    mapApiResponseToDetailsSteps(data) {
        const durationOptions = {
            allowCustomRange: true,
            minDate: moment().startOf('day'),
            maxDate: moment()
                .endOf('day')
                .add(2, 'years'),
            dateRanges: [
                {
                    id: policy_list_model_1.DurationDetailOption.PERPETUAL,
                    label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_DETAILS_STEP_LABEL.DURATION.OPTION_PERPETUAL'
                },
                {
                    id: policy_list_model_1.DurationDetailOption.CUSTOMRANGE,
                    label: '$I18N_AP_CREATE_POLICY_STEPS.POLICY_DETAILS_STEP_LABEL.DURATION.OPTION_CUSTOMRANGE'
                }
            ]
        };
        return {
            id: data ? data.id : undefined,
            description: data.description,
            apDuration: durationOptions.dateRanges[0],
            startDate: data.policies[0].policy.fromDate,
            endDate: data.policies[0].policy.toDate
        };
    }
    mapApiResponseToOutboundStep(outboundPolicyDetails) {
        return {
            enableOutboundCheck: outboundPolicyDetails.checkOutbound,
            gatewayAction: outboundPolicyDetails.outboundAction,
            gatewayFallbackAction: outboundPolicyDetails.outboundFallbackAction,
            internalSenderOutbound: outboundPolicyDetails.outboundNotifyInternalSender,
            senderMailboxAction: outboundPolicyDetails.outboundRemediationAction,
            senderMailboxFallbackAction: outboundPolicyDetails.outboundFallbackRemediationAction
        };
    }
    mapApiResponseToOutboundGroup(apOutboundGroup) {
        return apOutboundGroup ? apOutboundGroup : null;
    }
}
exports.ApEditPolicyService = ApEditPolicyService;
