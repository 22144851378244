"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const endpoints_list_container_component_1 = require("app-new/swg/endpoints-list/container/endpoints-list.container.component");
const device_groups_wizard_container_component_1 = require("./container/device-groups-wizard/device-groups-wizard.container.component");
angular
    .module('swgEndpointsList', [])
    .controller('SwgEndpointsListController', [
    '$stateParams',
    '$scope',
    function ($stateParams, $scope) {
        $scope.stateParams = $stateParams;
    }
])
    .directive('mcSwgEndpointsListContainer', static_1.downgradeComponent({
    component: endpoints_list_container_component_1.SwgEndpointsListContainerComponent
}))
    .directive('mcDeviceGroupsWizardContainer', static_1.downgradeComponent({
    component: device_groups_wizard_container_component_1.DeviceGroupsWizardContainerComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider
            .state('secure-web-gateway-endpoints-list', {
            url: '/secure-web-gateway/endpoints-list',
            params: {
                displayTab: 'endpoints-list'
            },
            data: {
                capabilities: 'Permission.SWG_ENDPOINT_LIST_READ',
                isFixedLayout: false,
                checkProgress: false,
                tabReload: true,
                tabTitle: '$I18N_ENDPOINTS_LIST.TAB_TITLE',
                tabId: 'swgEndpointsList',
                breadcrumbs: [
                    {
                        label: '$I18N_MEGA_MENU_GROUP_SECURE_WEB_GATEWAY_LABEL',
                        stateName: 'secure-web-gateway-dashboard'
                    },
                    '$I18N_MEGA_MENU_GROUP_SECURE_WEB_GATEWAY_ENDPOINTS_LIST'
                ]
            },
            views: {
                main: {
                    template: '<mc-swg-endpoints-list-container></mc-swg-endpoints-list-container>',
                    controller: 'SwgEndpointsListController'
                }
            }
        })
            .state('device-group-wizard', {
            url: '/secure-web-gateway/device-groups',
            data: {
                capabilities: 'Permission.SWG_ENDPOINT_LIST_EDIT && Temporary.Permission.Swg.DeviceBasedPolicies',
                isFixedLayout: false,
                checkProgress: false,
                tabTitle: '$I18N_ENDPOINTS_LIST.TAB_TITLE',
                tabId: 'swgEndpointsList',
                breadcrumbs: [
                    {
                        label: '$I18N_MEGA_MENU_GROUP_SECURE_WEB_GATEWAY_LABEL',
                        stateName: 'secure-web-gateway-dashboard'
                    },
                    '$I18N_MEGA_MENU_GROUP_SECURE_WEB_GATEWAY_DEVICE_GROUPS'
                ]
            },
            views: {
                main: {
                    controller: 'SwgEndpointsListController',
                    template: '<mc-device-groups-wizard-container></mc-device-groups-wizard-container>'
                }
            }
        });
    }
]);
