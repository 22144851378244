"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateSafetyTipsContainerComponent = void 0;
const core_1 = require("@angular/core");
const template_reducer_1 = require("../../reducers/template.reducer");
class TemplateSafetyTipsContainerComponent {
    constructor() {
        this.delete = new core_1.EventEmitter();
        this.edit = new core_1.EventEmitter();
        this.create = new core_1.EventEmitter();
        this.previewCustomeTip = new core_1.EventEmitter();
        this.previewMimecastTip = new core_1.EventEmitter();
        this.previewAll = new core_1.EventEmitter();
        this.save = new core_1.EventEmitter();
        this.cancel = new core_1.EventEmitter();
        this.showCustom = false;
        this.showDefault = true;
    }
    set safetyTipType(safetyTipType) {
        this._safetyTipType = safetyTipType;
        switch (safetyTipType) {
            case 'customSafetyTips': {
                this.showCustom = true;
                this.showDefault = false;
                break;
            }
            case 'mimecastSafetyTipsAndCustomSafetyTips': {
                this.showCustom = true;
                this.showDefault = true;
                break;
            }
            default: {
                this.showCustom = false;
                this.showDefault = true;
                this.cancel.emit({ index: this.customeSafetyTipsSelectedIndex, tip: null });
            }
        }
    }
    get mimecastSafetyTipsSelectedIndex() {
        return this.previewTip && this.previewTip.isDefaultTip ? this.previewTip.index : null;
    }
    get customeSafetyTipsSelectedIndex() {
        return this.previewTip && !this.previewTip.isDefaultTip ? this.previewTip.index : null;
    }
    get creatingTip() {
        return this.previewTip && this.previewTip.status === template_reducer_1.TipStatus.CREATING;
    }
    get editingTip() {
        return this.previewTip && this.previewTip.status === template_reducer_1.TipStatus.EDITING;
    }
    get block() {
        return this.previewTip && !!this.previewTip.status;
    }
}
exports.TemplateSafetyTipsContainerComponent = TemplateSafetyTipsContainerComponent;
