"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageProcessingTabComponent = void 0;
const actions = require("../../actions/message-processing.action");
const reducers = require("../../reducers/message-processing");
class MessageProcessingTabComponent {
    constructor(store) {
        this.store = store;
    }
    ngOnInit() {
        this.items$ = this.store.select(reducers.getMessageProcessingItems);
        this.totalCount$ = this.store.select(reducers.getMessageProcessingTotalCount);
        this.selectedRow$ = this.store.select(reducers.getMessageProcessingSelectedRow);
        this.isLoading$ = this.store.select(reducers.isDeliveryMessagesLoading);
        this.metadata$ = this.store.select(reducers.getMessageProcessingMetadata);
        this.store.dispatch(new actions.MessageProcessingRequestAction({ search: {} }));
    }
    search(search) {
        this.store.dispatch(new actions.MessageProcessingRequestAction({ search }));
    }
    dateFilterChanged(range) {
        this.store.dispatch(new actions.MessageProcessingRequestAction({ search: Object.assign({}, range.range) }));
    }
    columnFilterChanged(filter) {
        this.store.dispatch(new actions.MessageProcessingRequestAction({ search: filter }));
    }
    paginatorChanged(page) {
        this.store.dispatch(new actions.MessageProcessingPaginationAction(page));
    }
    rowClicked(message) {
        this.store.dispatch(new actions.MessageProcessingSelectRowAction(message));
    }
    messagesRejected(messages) {
        if (messages.length > 0) {
            this.store.dispatch(new actions.MessageProcessingRejectEmailsAction(messages));
        }
    }
    messagesRetried(messages) {
        if (messages.length > 0) {
            this.store.dispatch(new actions.MessageProcessingRetryEmailsAction(messages));
        }
    }
    routeRecalculated(messages) {
        if (messages.length > 0) {
            // @ts-ignore
            this.store.dispatch(new actions.MessageProcessingRecalculateRouteAction(messages));
        }
    }
    messagesEarlyBounced(messages) {
        if (messages.length > 0) {
            this.store.dispatch(new actions.MessageProcessingEarlyBounceEmailsAction(messages));
        }
    }
    exportResults(tableColumns) {
        this.store.dispatch(new actions.MessageProcessingExportAction(tableColumns));
    }
}
exports.MessageProcessingTabComponent = MessageProcessingTabComponent;
