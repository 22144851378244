"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OktaConfirmationModalComponent = void 0;
const okta_actions_1 = require("app-new/api-applications/actions/integration/okta.actions");
class OktaConfirmationModalComponent {
    constructor(overlayRef, store$, translateService, data) {
        this.overlayRef = overlayRef;
        this.store$ = store$;
        this.translateService = translateService;
        this.data = data;
        this.kbLink = 'https://community.mimecast.com/s/article/Managing-Recipient-Validation-714740027';
        this.GENERATE = 'generate';
        this.DELETE = 'delete';
    }
    generate() {
        this.overlayRef.close(false);
        this.store$.dispatch(new okta_actions_1.UpdateOktaIntegrationAction());
    }
    cancel() {
        this.overlayRef.close(false);
        this.store$.dispatch(new okta_actions_1.CancelOktaIntegrationWidgetAction());
    }
    delete() {
        this.overlayRef.close(false);
        this.store$.dispatch(new okta_actions_1.DeleteOktaIntegrationAction());
    }
    getHeaderTitle() {
        return this.data.type === this.GENERATE
            ? this.translateService.instant('$I18N_API_APPLICATIONS_OKTA_CONFIRMATION_MODAL.GENERATE_TITLE')
            : `${this.translateService.instant('$I18N_API_APPLICATIONS_OKTA_CONFIRMATION_MODAL.DELETE_TITLE')} ${this.data.integrationName}?`;
    }
}
exports.OktaConfirmationModalComponent = OktaConfirmationModalComponent;
