"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./favourites-controller"); //navbar.favourites.controller
require("./favourites-service"); //navbar.favourites.service
// html and css 
require("./favourites.tpl.html");
/**
 * Created by cfeudo on 13/11/2015.
 */
angular.module('navbar.favourites', ['navbar.favourites.controller', 'navbar.favourites.service']);
