<div class="mc-detailed-list mc-directory-browser">
  <form
    class="form-horizontal"
    mcDefaultLabelClass="col-sm-3"
    mcDefaultControlContainerClass="col-sm-9"
    [formGroup]="form"
  >

    <div class="mc-name-field-optional">
      <mc-field label="$I18N_DIRECTORY_SYNC_ASIDE_TAB_DIRECTORY_BROWSER.NAME">
        <input class="form-control"
               type="text"
               [readonly]="true"
               name="name"
               formControlName="name"
               aria-label="name"
        />
      </mc-field>
    </div>

    <mc-text-field formControlName="rootDn"
                   label="$I18N_DIRECTORY_SYNC_ASIDE_TAB_DIRECTORY_BROWSER.SEARCH_DN"
                   popoverPlacement="top"
                   helpPopoverContent="{{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_DIRECTORY_BROWSER.SEARCH_DN_TOOLTIP' | translate }}"
                   errorPrefix="$I18N_DIRECTORY_SYNC_ASIDE_TAB_DIRECTORY_BROWSER.SEARCH_DN_ERROR"
                   required
    >
    </mc-text-field>

    <mc-text-field formControlName="directoryFilter"
                   label="$I18N_DIRECTORY_SYNC_ASIDE_TAB_DIRECTORY_BROWSER.DIRECTORY_FILTER"
                   popoverPlacement="top"
                   helpPopoverContent="{{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_DIRECTORY_BROWSER.DIRECTORY_FILTER_TOOLTIP' | translate }}"
                   errorPrefix="$I18N_DIRECTORY_SYNC_ASIDE_TAB_DIRECTORY_BROWSER.DIRECTORY_FILTER_ERROR"
                   required
    >
    </mc-text-field>

    <div class="mc-return-attr-field-optional">
      <mc-text-field formControlName="returnAttributes"
                     label="$I18N_DIRECTORY_SYNC_ASIDE_TAB_DIRECTORY_BROWSER.RETURN_ATTRIBUTES"
                     popoverPlacement="top"
                     helpPopoverContent="{{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_DIRECTORY_BROWSER.RETURN_ATTRIBUTES_TOOLTIP' | translate }}"
      >
      </mc-text-field>
    </div>

    <mc-field
      label="{{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_DIRECTORY_BROWSER.QUERY_SCOPE' | translate }}"
      useTranslation="true"
      popoverPlacement="top"
      [helpPopoverContent]="queryScopeTooltip"
      required
    >
      <mc-select
        formControlName="queryScope"
        [options]="queryScopeOptions"
        [defaultValue]="form.value.queryScope"
      ></mc-select>
    </mc-field>

    <mc-live-button
            typeClass="btn mc-btn btn-secondary mc-search-submit"
            type="submit"
            [mcDisabled]="form.invalid"
            (mcClick)="searchDirectory()"
            content="{{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_DIRECTORY_BROWSER.SEARCH_BTN' | translate }}">
    </mc-live-button>
  </form>
</div>

<div class="mc-detailed-list mc-directory-results">

<div class="mc-directory-results-scroll">
  <ng-container *ngFor="let results of searchResults$ | async; let last = last">
    <ng-container *ngFor="let result of getResults(results)">
      <div class="row mc-detailed-list-row">
        <div class="col-xs-4 mc-detailed-list-label">{{ result.key }}</div>
        <div *ngIf="!checkSearchableItem(result.value)" class="mc-detailed-list-column">{{ result.value }}</div>
        <div
          *ngIf="checkSearchableItem(result.value)"
          class="mc-detailed-list-column mc-search-item"
          (click)="updateSearchDirectory(result.value)">{{ result.value }}</div>
      </div>
    </ng-container>
    <hr *ngIf="(searchResults$ | async).length > 1 && !last" class="text-muted mc-divider">
  </ng-container>

  <div *ngIf="isLoading$ | async" class="spinner-wrapper">
    <mc-spinner ></mc-spinner>
  </div>

  <div *ngIf="!(searchResults$ | async).length && !(isLoading$ | async)" class="mc-no-data">
    <mc-empty-results
      iconClass="far fa-list-alt"
      keyTitle="$I18N_DIRECTORY_SYNC_ASIDE_TAB_DIRECTORY_BROWSER.NO_RESULTS"
    >
    </mc-empty-results>
  </div>
  </div>
</div>

<ng-template #queryScopeTooltip>
  <span>{{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_DIRECTORY_BROWSER.QUERY_SCOPE_TOOLTIP_1' | translate }}</span>
  <span><strong>{{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_DIRECTORY_BROWSER.QUERY_SCOPE_TOOLTIP_2' | translate }}</strong></span>
  <span>{{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_DIRECTORY_BROWSER.QUERY_SCOPE_TOOLTIP_3' | translate }}</span><br>
  <span><strong>{{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_DIRECTORY_BROWSER.QUERY_SCOPE_TOOLTIP_4' | translate }}</strong></span>
  <span>{{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_DIRECTORY_BROWSER.QUERY_SCOPE_TOOLTIP_5' | translate }}</span><br>
  <span><strong>{{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_DIRECTORY_BROWSER.QUERY_SCOPE_TOOLTIP_6' | translate }}</strong></span>
  <span>{{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_DIRECTORY_BROWSER.QUERY_SCOPE_TOOLTIP_7' | translate }}</span>
</ng-template>
