"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReleaseLogAsidePaginationAction = exports.ReleaseLogExportAction = exports.ReleaseLogResetStateAction = exports.ReleaseLogPaginationAction = exports.ReleaseLogRefreshAction = exports.ReleaseLogCloseDetailAction = exports.ReleaseLogSelectRowAsideAction = exports.ReleaseLogSelectRowAction = exports.ReleaseLogFailAction = exports.ReleaseLogSuccessAction = exports.ReleaseLogRequestAction = exports.RELEASE_LOG_ASIDE_PAGINATION = exports.RELEASE_LOG_EXPORT = exports.RELEASE_LOG_RESET_STATE = exports.RELEASE_LOG_PAGINATION = exports.RELEASE_LOG_REFRESH = exports.RELEASE_LOG_CLOSE_DETAIL = exports.RELEASE_LOG_SELECT_ROW_ASIDE = exports.RELEASE_LOG_SELECT_ROW = exports.RELEASE_LOG_FAIL = exports.RELEASE_LOG_SUCCESS = exports.RELEASE_LOG_REQUEST = void 0;
exports.RELEASE_LOG_REQUEST = '[Message Center / Release Log] Request Get Release Logs';
exports.RELEASE_LOG_SUCCESS = '[Message Center / Release Log] Get Release Log Success';
exports.RELEASE_LOG_FAIL = '[Message Center / Release Log] Get Release Log Fail';
exports.RELEASE_LOG_SELECT_ROW = '[Message Center / Release Log] Release Log Select Row';
exports.RELEASE_LOG_SELECT_ROW_ASIDE = '[Message Center / Release Log] Release Log Display Select Row Aside';
exports.RELEASE_LOG_CLOSE_DETAIL = '[Message Center / Release Log] Release Close Detail';
exports.RELEASE_LOG_REFRESH = '[Message Center / Release Log] Release Log Refresh';
exports.RELEASE_LOG_PAGINATION = '[Message Center / Release Log] Release Log Pagination';
exports.RELEASE_LOG_RESET_STATE = '[Message Center / Release Log] Reset State';
exports.RELEASE_LOG_EXPORT = '[Message Center / Release Log] Export Release Logs';
exports.RELEASE_LOG_ASIDE_PAGINATION = '[Message Center / Release Log] Release Log Aside Pagination';
class ReleaseLogRequestAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.RELEASE_LOG_REQUEST;
    }
}
exports.ReleaseLogRequestAction = ReleaseLogRequestAction;
class ReleaseLogSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.RELEASE_LOG_SUCCESS;
    }
}
exports.ReleaseLogSuccessAction = ReleaseLogSuccessAction;
class ReleaseLogFailAction {
    constructor() {
        this.type = exports.RELEASE_LOG_FAIL;
    }
}
exports.ReleaseLogFailAction = ReleaseLogFailAction;
class ReleaseLogSelectRowAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.RELEASE_LOG_SELECT_ROW;
    }
}
exports.ReleaseLogSelectRowAction = ReleaseLogSelectRowAction;
class ReleaseLogSelectRowAsideAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.RELEASE_LOG_SELECT_ROW_ASIDE;
    }
}
exports.ReleaseLogSelectRowAsideAction = ReleaseLogSelectRowAsideAction;
class ReleaseLogCloseDetailAction {
    constructor() {
        this.type = exports.RELEASE_LOG_CLOSE_DETAIL;
    }
}
exports.ReleaseLogCloseDetailAction = ReleaseLogCloseDetailAction;
class ReleaseLogRefreshAction {
    constructor() {
        this.type = exports.RELEASE_LOG_REFRESH;
    }
}
exports.ReleaseLogRefreshAction = ReleaseLogRefreshAction;
class ReleaseLogPaginationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.RELEASE_LOG_PAGINATION;
    }
}
exports.ReleaseLogPaginationAction = ReleaseLogPaginationAction;
class ReleaseLogResetStateAction {
    constructor() {
        this.type = exports.RELEASE_LOG_RESET_STATE;
    }
}
exports.ReleaseLogResetStateAction = ReleaseLogResetStateAction;
class ReleaseLogExportAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.RELEASE_LOG_EXPORT;
    }
}
exports.ReleaseLogExportAction = ReleaseLogExportAction;
class ReleaseLogAsidePaginationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.RELEASE_LOG_ASIDE_PAGINATION;
    }
}
exports.ReleaseLogAsidePaginationAction = ReleaseLogAsidePaginationAction;
