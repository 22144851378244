"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupervisionGetGroupsFail = exports.SupervisionGetGroupsNotSet = exports.SupervisionGetGroupsLDAPSuccess = exports.SupervisionGetGroupsCloudSuccess = exports.SupervisionGetGroupsLDAP = exports.SupervisionGetGroupsCloud = exports.GET_GROUPS_NOT_SET = exports.GET_GROUPS_FAIL = exports.GET_GROUPS_LDAP_SUCCESS = exports.GET_GROUPS_CLOUD_SUCCESS = exports.GET_GROUPS_LDAP = exports.GET_GROUPS_CLOUD = void 0;
exports.GET_GROUPS_CLOUD = '[Supervision] Retrieve cloud group';
exports.GET_GROUPS_LDAP = '[Supervision] Retrieve ldap group';
exports.GET_GROUPS_CLOUD_SUCCESS = '[Supervision] Retrieve cloud group success';
exports.GET_GROUPS_LDAP_SUCCESS = '[Supervision] Retrieve ldap group success';
exports.GET_GROUPS_FAIL = '[Supervision] Retrieve group fail';
exports.GET_GROUPS_NOT_SET = '[Supervision] Retrieve group not set';
class SupervisionGetGroupsCloud {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_GROUPS_CLOUD;
    }
}
exports.SupervisionGetGroupsCloud = SupervisionGetGroupsCloud;
class SupervisionGetGroupsLDAP {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_GROUPS_LDAP;
    }
}
exports.SupervisionGetGroupsLDAP = SupervisionGetGroupsLDAP;
class SupervisionGetGroupsCloudSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_GROUPS_CLOUD_SUCCESS;
    }
}
exports.SupervisionGetGroupsCloudSuccess = SupervisionGetGroupsCloudSuccess;
class SupervisionGetGroupsLDAPSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_GROUPS_LDAP_SUCCESS;
    }
}
exports.SupervisionGetGroupsLDAPSuccess = SupervisionGetGroupsLDAPSuccess;
class SupervisionGetGroupsNotSet {
    constructor() {
        this.type = exports.GET_GROUPS_NOT_SET;
    }
}
exports.SupervisionGetGroupsNotSet = SupervisionGetGroupsNotSet;
class SupervisionGetGroupsFail {
    constructor() {
        this.type = exports.GET_GROUPS_FAIL;
    }
}
exports.SupervisionGetGroupsFail = SupervisionGetGroupsFail;
