"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IdentityIpAddressesStepComponent = void 0;
const stepActions = require("../../../actions/policy-wizard/ip-addresses-step.actions");
const wizardActions = require("../../../actions/policy-wizard/wizard.actions");
const forms_1 = require("@angular/forms");
const rxjs_1 = require("rxjs");
const policy_wizard_1 = require("../../../reducers/policy-wizard");
const components_1 = require("@mimecast-ui/components");
const policy_1 = require("../../../models/policy");
const operators_1 = require("rxjs/operators");
class IdentityIpAddressesStepComponent {
    constructor(formBuilder, store, translateService) {
        this.formBuilder = formBuilder;
        this.store = store;
        this.translateService = translateService;
        this.addButtonDisabled$ = new rxjs_1.BehaviorSubject(true);
        this.permissionTypeOptions = [
            {
                value: policy_1.LocationPermission['NONE'],
                label: '$I18N_IDENTITY_CREATE_POLICY_IP_ADDRESSES_STEP.ELEMENTS.PERMISSION_TYPE.SELECT.NO_IP'
            },
            {
                value: policy_1.LocationPermission['IP_ALLOW_LOGIN'],
                label: '$I18N_IDENTITY_CREATE_POLICY_IP_ADDRESSES_STEP.ELEMENTS.PERMISSION_TYPE.SELECT.ALLOW_LOGIN'
            },
            {
                value: policy_1.LocationPermission['IP_NO_2FA'],
                label: '$I18N_IDENTITY_CREATE_POLICY_IP_ADDRESSES_STEP.ELEMENTS.PERMISSION_TYPE.SELECT.NO_2FA'
            }
        ];
        this.columns = ['ip', 'delete-column'];
        this.ipAddressesCount = 0;
        this.ipFormControlLabel$ = new rxjs_1.BehaviorSubject('');
        this.ipFormControlPopover$ = new rxjs_1.BehaviorSubject('');
        this.showIpFields$ = new rxjs_1.BehaviorSubject(false);
    }
    ngOnInit() {
        this.ipAddressesForm = this.formBuilder.group({
            permissionType: [this.permissionTypeOptions[0].value, forms_1.Validators.required]
        });
        this.ipAddressesForm.addControl('ip', new forms_1.FormControl('', {
            validators: components_1.IPValidator,
            updateOn: 'blur'
        }));
        this.permissionTypeSubscription = this.permissionTypeFormControl.valueChanges.subscribe(value => {
            this.store.dispatch(new stepActions.SavePermissionTypeAction(value));
            this.updateIpFieldLabel();
            this.updateStepValidity();
            this.updateShowIpFields();
        });
        this.ipAddresses$ = this.store.select(policy_wizard_1.getIpAddresses);
        this.ipAddressesSubscription = this.ipAddresses$.subscribe(ipAddresses => {
            this.ipAddressesCount = ipAddresses.length;
            this.updateStepValidity();
        });
        this.store
            .select(policy_wizard_1.getIpAddressesStepState)
            .pipe(operators_1.first())
            .subscribe(state => {
            this.ipAddressesForm.patchValue({
                permissionType: state.permissionType
            }, { emitEvent: false });
            this.updateShowIpFields();
            this.updateIpFieldLabel();
        });
    }
    onIpChange(value) {
        this.addButtonDisabled$.next(!value || value.length === 0);
        this.ipAddressFormControl.setErrors({});
    }
    ngAfterViewInit() { }
    updateIpFieldLabel() {
        if (this.permissionTypeFormControl.value === this.permissionTypeOptions[1].value) {
            this.ipFormControlLabel$.next('$I18N_IDENTITY_CREATE_POLICY_IP_ADDRESSES_STEP.ELEMENTS.IP_LIST.LABEL_PERMITTED');
            this.ipFormControlPopover$.next(this.translateService.instant('$I18N_IDENTITY_CREATE_POLICY_IP_ADDRESSES_STEP.ELEMENTS.IP_LIST.POPOVER_PERMITTED'));
        }
        else if (this.permissionTypeFormControl.value === this.permissionTypeOptions[2].value) {
            this.ipFormControlLabel$.next('$I18N_IDENTITY_CREATE_POLICY_IP_ADDRESSES_STEP.ELEMENTS.IP_LIST.LABEL_TRUSTED');
            this.ipFormControlPopover$.next(this.translateService.instant('$I18N_IDENTITY_CREATE_POLICY_IP_ADDRESSES_STEP.ELEMENTS.IP_LIST.POPOVER_TRUSTED'));
        }
    }
    updateStepValidity() {
        const permissionType = this.ipAddressesForm.get('permissionType').value;
        const isValid = permissionType === policy_1.LocationPermission.NONE || this.ipAddressesCount > 0;
        this.store.dispatch(new wizardActions.UpdateIsValidAction(3, isValid));
    }
    updateShowIpFields() {
        this.showIpFields$.next(this.permissionTypeFormControl.value === this.permissionTypeOptions[1].value ||
            this.permissionTypeFormControl.value === this.permissionTypeOptions[2].value);
    }
    addIp() {
        if (this.ipAddressFormControl.valid) {
            this.store.dispatch(new stepActions.AddIpAddressAction({ ip: this.ipAddressFormControl.value }));
            this.ipAddressFormControl.patchValue('');
            this.addButtonDisabled$.next(true);
            this.ipAddressFormControl.markAsPristine();
        }
    }
    removeIp(row) {
        this.store.dispatch(new stepActions.RemoveIpAddressAction(row));
    }
    onDeleteAll() {
        this.store.dispatch(new stepActions.ClearIpAddressesAction());
    }
    get ipAddressFormControl() {
        return this.ipAddressesForm.get('ip');
    }
    get permissionTypeFormControl() {
        return this.ipAddressesForm.get('permissionType');
    }
    ngOnDestroy() {
        this.permissionTypeSubscription.unsubscribe();
        this.ipAddressesSubscription.unsubscribe();
    }
}
exports.IdentityIpAddressesStepComponent = IdentityIpAddressesStepComponent;
