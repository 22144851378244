"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UrlProtectionLogsEffects = void 0;
const effects_1 = require("@ngrx/effects");
const listActions = require("../actions/log-list.actions");
const checkUrlActions = require("../actions/logs-check-url.actions");
const table_store_1 = require("@mimecast-ui/table-store");
const api_1 = require("app-new/components/export/api");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const sidePanelActions = require("../actions/logs-side-panel.actions");
const lodash_1 = require("lodash");
const check_url_dialog_component_1 = require("../components/check-url-dialog/check-url-dialog.component");
const TABLE_ID = 'urlProtectionLogsList';
class UrlProtectionLogsEffects {
    constructor(actions$, tableStore, accountPackageService, checkUrlsService, urlReportApiService, notificationService, translate, modalService) {
        this.actions$ = actions$;
        this.tableStore = tableStore;
        this.accountPackageService = accountPackageService;
        this.checkUrlsService = checkUrlsService;
        this.urlReportApiService = urlReportApiService;
        this.notificationService = notificationService;
        this.translate = translate;
        this.modalService = modalService;
        this.export$ = this.actions$.pipe(effects_1.ofType(listActions.URL_PROTECTION_LOGS_EXPORT), operators_1.withLatestFrom(this.tableStore.select(table_store_1.tableReducer.getCurrentFilters(TABLE_ID))), operators_1.map(([action, currentFilters]) => {
            return new api_1.OpenSidePanelExportApiAction('/api/ttp/url/export-logs', action.payload, currentFilters, '$I18N_SERVICES_URL_PROTECTION_LOGS_EXPORT');
        }));
        this.getUrlCategories$ = this.actions$.pipe(effects_1.ofType(sidePanelActions.URL_REPORT_GET_CATEGORIES_ACTION), operators_1.switchMap(() => {
            return this.urlReportApiService.getCategories().pipe(operators_1.map((response) => {
                return new sidePanelActions.UrlActivityReportGetCategoriesSuccessAction(response);
            }), operators_1.catchError(() => this.urlActivityReportFailAction('$SCAN_DETAILS_REPORT_MODAL.NOTIFICATION.ERRORS.GET_CATEGORIES')));
        }));
        this.getProfileEmail$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(sidePanelActions.URL_REPORT_GET_PROFILE_EMAIL_ACTION), operators_1.switchMap(() => {
                return this.urlReportApiService.getProfile().pipe(operators_1.map((response) => {
                    return new sidePanelActions.UrlActivityReportGetProfileEmailSuccessAction(response.emailAddress);
                }), operators_1.catchError(() => this.urlActivityReportFailAction('$SCAN_DETAILS_REPORT_MODAL.NOTIFICATION.ERRORS.GET_PROFILE')));
            }));
        });
        this.browserIsolation$ = this.actions$.pipe(effects_1.ofType(listActions.URL_PROTECTION_BROWSER_ISOLATION_LINK_REQUEST), operators_1.mergeMap((action) => {
            return this.accountPackageService.browserIsolationRedirect(action.payload.url).pipe(operators_1.map((response) => {
                return new listActions.UrlProtectionBrowserIsolationSuccessAction(response.url);
            }), operators_1.catchError((err) => {
                return rxjs_1.of(new listActions.UrlProtectionBrowserIsolationFailAction(err.firstFail.errors[0]));
            }));
        }));
        this.browserIsolationSuccess$ = this.actions$.pipe(effects_1.ofType(listActions.URL_PROTECTION_BROWSER_ISOLATION_LINK_SUCCESS), operators_1.tap((response) => {
            window.open(response.payload);
        }));
        this.browserIsolationFail$ = this.actions$.pipe(effects_1.ofType(listActions.URL_PROTECTION_BROWSER_ISOLATION_LINK_FAIL), operators_1.map((action) => {
            return this.notificationService.newNotification({
                type: 'error',
                config: {
                    msg: this.generateErrorMessage(action.payload)
                }
            });
        }));
        this.checkUrl$ = this.actions$.pipe(effects_1.ofType(checkUrlActions.CHECK_URL_ATTEMPT), operators_1.switchMap(action => {
            return this.checkUrlsService.checkUrl(action['payload']).pipe(operators_1.map(response => {
                const { first, fail } = response;
                if (lodash_1.isArray(fail) && fail.length) {
                    return new checkUrlActions.CheckUrlFailAction();
                }
                return new checkUrlActions.CheckUrlSuccessAction(first);
            }), operators_1.catchError(() => {
                return rxjs_1.of(new checkUrlActions.CheckUrlFailAction());
            }));
        }));
        this.checkUrlFail$ = this.actions$.pipe(effects_1.ofType(checkUrlActions.CHECK_URL_FAIL), operators_1.mergeMap(() => {
            return [
                new sidePanelActions.CloseLogsSidePanelAction(),
                this.notificationService.newNotification({
                    type: 'error',
                    config: {
                        msg: this.translate.instant('$I18N_SERVICES_URL_PROTECTION_MANAGED_URL_UPDATE_SCAN_DETAILS_ERROR_MSG')
                    }
                })
            ];
        }));
        this.openCheckUrlModal$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(listActions.URL_PROTECTION_OPEN_CHECK_URL_MODAL), operators_1.switchMap((action) => {
                const url = action.payload;
                return this.modalService
                    .open(check_url_dialog_component_1.CheckUrlModalComponent, {
                    data: {
                        type: 'Check Url',
                        url
                    }
                })
                    .afterClose()
                    .pipe(operators_1.map(() => new checkUrlActions.CheckUrlClearAction()));
            }));
        }, { dispatch: false });
    }
    urlActivityReportFailAction(errorMessage) {
        return rxjs_1.of(new sidePanelActions.UrlActivityReportGetCategoriesFailureAction()).pipe(operators_1.tap(() => {
            return this.notificationService.newNotification({
                type: 'error',
                config: {
                    msg: errorMessage
                }
            });
        }));
    }
    generateErrorMessage(err) {
        if (err.field) {
            return this.translate.instant('$I18N_SERVICES_URL_PROTECTION_LOGS_TABLE.BROWSER_ISOLATION_ERROR_MSG_URL_NOT_SENT');
        }
        if (!err.field) {
            return (err.message ||
                this.translate.instant('$I18N_SERVICES_URL_PROTECTION_LOGS_TABLE.BROWSER_ISOLATION_ERROR_MSG_GENERIC'));
        }
    }
}
__decorate([
    effects_1.Effect()
], UrlProtectionLogsEffects.prototype, "export$", void 0);
__decorate([
    effects_1.Effect()
], UrlProtectionLogsEffects.prototype, "getUrlCategories$", void 0);
__decorate([
    effects_1.Effect()
], UrlProtectionLogsEffects.prototype, "browserIsolation$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], UrlProtectionLogsEffects.prototype, "browserIsolationSuccess$", void 0);
__decorate([
    effects_1.Effect()
], UrlProtectionLogsEffects.prototype, "browserIsolationFail$", void 0);
__decorate([
    effects_1.Effect()
], UrlProtectionLogsEffects.prototype, "checkUrl$", void 0);
__decorate([
    effects_1.Effect()
], UrlProtectionLogsEffects.prototype, "checkUrlFail$", void 0);
exports.UrlProtectionLogsEffects = UrlProtectionLogsEffects;
