"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnableComponent = void 0;
class EnableComponent {
    constructor(fb, translateService) {
        this.fb = fb;
        this.translateService = translateService;
        this.onStateLabel = this.translateService.instant('$I18N_COMMON_TEXT_ENABLED');
        this.offStateLabel = this.translateService.instant('$I18N_COMMON_TEXT_DISABLED');
        this.isDisabled = false;
        this.value = false;
        // When this is set to true, the 15-pixel spacing at the bottom of the component will be removed.
        // This allows the switch to fit more comfortably in interfaces such as SWG policy summary pages.
        this.compact = false;
        this.labelClass = 'col-sm-2 mc-enable-switch-label';
        this.switchDivClass = 'col-sm-10';
        this.contentDivClass = 'row form-group';
    }
    ngOnInit() {
        this.switchForm = this.fb.group({ switch: false });
        this.writeValue(this.value);
    }
    registerOnChange(fn) {
        this.subscription = this.switchForm.get('switch').valueChanges.subscribe(fn);
    }
    registerOnTouched() { }
    setDisabledState() { }
    writeValue(obj) {
        this.switchForm.patchValue({ switch: obj });
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
exports.EnableComponent = EnableComponent;
