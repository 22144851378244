"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CongratulationsComponent = void 0;
const moment = require("moment");
const operators_1 = require("rxjs/operators");
class CongratulationsComponent {
    constructor(capabilitiesService, translate, cookieService, stateService, overlayRef) {
        this.capabilitiesService = capabilitiesService;
        this.translate = translate;
        this.cookieService = cookieService;
        this.stateService = stateService;
        this.overlayRef = overlayRef;
    }
    ngOnInit() {
        this.capabilitiesService
            .hasCapability('DirSync.Azure.Standard.Hecate')
            .pipe(operators_1.take(1))
            .subscribe(isActive => {
            switch (isActive) {
                case true: {
                    this.congratulationsHeader = this.translate.instant('$I18N_CONGRATULATIONS.HECATE_HEADER');
                    break;
                }
                case false: {
                    this.congratulationsHeader = this.translate.instant('$I18N_CONGRATULATIONS.NON_HECATE_HEADER');
                    break;
                }
            }
        });
    }
    completeMigration() {
        this.cookieService.set('migrationStatusH23', 'complete', moment()
            .add(1, 'year')
            .toDate(), '/', '', true);
        this.stateService.$state.go('services-connectors-page', { tab: 'cloud' }, { reload: true, inherit: false, notify: true });
        this.overlayRef.close();
    }
}
exports.CongratulationsComponent = CongratulationsComponent;
