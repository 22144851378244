"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SidePanelExportApiEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const side_panel_actions_1 = require("../../actions/side-panel.actions");
const export_api_page_component_1 = require("../../containers/export-api-page.component");
class SidePanelExportApiEffects {
    constructor(actions$, asideService) {
        this.actions$ = actions$;
        this.asideService = asideService;
        this.openCloseSidePanel$ = this.actions$.pipe(effects_1.ofType(side_panel_actions_1.ExportApiSidePanelActionTypes.OpenSidePanel), operators_1.exhaustMap((action) => {
            const sidePanelInstance = this.openSidePanel(action);
            const afterClose$ = sidePanelInstance.afterClose();
            const closeAction$ = this.getCloseActionObservable();
            return rxjs_1.merge(afterClose$, closeAction$).pipe(operators_1.first(), operators_1.tap(() => sidePanelInstance.close()), operators_1.map(() => new side_panel_actions_1.AfterCloseSidePanelExportApiAction()));
        }));
    }
    getCloseActionObservable() {
        return this.actions$.pipe(effects_1.ofType(side_panel_actions_1.ExportApiSidePanelActionTypes.CloseSidePanel), operators_1.first());
    }
    openSidePanel(action) {
        return this.asideService.open(export_api_page_component_1.ExportApiPageComponent, {
            position: 'right',
            size: 'md',
            hasBackdrop: true,
            data: action
        });
    }
}
__decorate([
    effects_1.Effect()
], SidePanelExportApiEffects.prototype, "openCloseSidePanel$", void 0);
exports.SidePanelExportApiEffects = SidePanelExportApiEffects;
