"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OneDriveBackupsPageComponent = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const lodash_1 = require("lodash");
const reducers_1 = require("../../reducers");
const actions_1 = require("../../actions");
const create_connector_modal_component_1 = require("../../components/create-connector-modal/create-connector-modal.component");
const table_store_1 = require("@mimecast-ui/table-store");
const backup_list_impl_service_1 = require("../../services/backup-list.impl.service");
const backup_selector_1 = require("../../selectors/backup.selector");
const delete_backup_modal_component_1 = require("../../components/delete-backup-modal/delete-backup-modal.component");
class OneDriveBackupsPageComponent extends table_store_1.TableBaseComponent {
    constructor(store, modalService, stateService, capabilitiesManagerService) {
        super(store, backup_list_impl_service_1.BackupListImpl.ONE_DRIVE_BACKUP_LIST_STORE_ID);
        this.store = store;
        this.modalService = modalService;
        this.stateService = stateService;
        this.capabilitiesManagerService = capabilitiesManagerService;
        this.destroy$ = new rxjs_1.Subject();
    }
    ngOnInit() {
        super.ngOnInit();
        this.hasBackupEditPermission$ = this.capabilitiesManagerService.hasOnedriveBackupEditPermission();
        this.hasBackupReadPermission$ = this.capabilitiesManagerService.hasOnedriveBackupReadPermission();
        this.isLoadingConnectors$ = this.store.select(reducers_1.isLoadingConnectors);
        this.errorLoadingTable$ = this.store.select(backup_selector_1.hasErrorLoadingBackupsTable);
        const connnectors$ = this.store.select(reducers_1.getOneDriveConnectors);
        const statusUpdated$ = this.store.select(backup_selector_1.hasStatusUpdated);
        this.isLoadingConnectors$ = this.store.select(reducers_1.isLoadingConnectors);
        this.isUpdatingStatus$ = this.store.select(backup_selector_1.isUpdatingStatus);
        this.isDeletingBackup$ = this.store.select(backup_selector_1.isDeletingBackup);
        this.openingEditBackup$ = this.store.select(backup_selector_1.openingEditBackup);
        this.isLoadingBackup$ = this.store.select(backup_selector_1.isLoadingBackup);
        const backupDeleted$ = this.store.select(backup_selector_1.hasBackupDeleted);
        this.isUpdatingStatus$
            .pipe(operators_1.withLatestFrom(statusUpdated$), operators_1.takeUntil(this.destroy$))
            .subscribe(([updating, updated]) => {
            if (!updating && updated) {
                this.dispatchLoadAction();
            }
        });
        this.isDeletingBackup$
            .pipe(operators_1.withLatestFrom(backupDeleted$), operators_1.takeUntil(this.destroy$))
            .subscribe(([deleting, deleted]) => {
            if (!deleting && deleted) {
                this.dispatchLoadAction();
            }
        });
        this.isLoadingConnectors$
            .pipe(operators_1.skip(1), operators_1.withLatestFrom(connnectors$), operators_1.takeUntil(this.destroy$))
            .subscribe(([isLoading, connectors]) => {
            if (!isLoading) {
                if (lodash_1.isEmpty(connectors)) {
                    this.openCreateConnectorModal();
                }
                else {
                    this.hasExistingConnector = true;
                }
            }
        });
        this.store.dispatch(new actions_1.LoadOneDriveConnectorsAction());
    }
    ngOnDestroy() {
        super.ngOnDestroy();
        this.destroy$.next(true);
        this.destroy$.complete();
    }
    reloadTable() {
        this.dispatchLoadAction();
    }
    createBackup() {
        this.stateService.$state.go('onedrive-backup-wizard');
    }
    openCreateConnectorModal() {
        const modalConfig = {};
        modalConfig.size = 'md';
        modalConfig.hasBackdrop = true;
        modalConfig.disableClose = true;
        this.modalService
            .open(create_connector_modal_component_1.CreateConnectorModalComponent, modalConfig)
            .afterClose()
            .pipe(operators_1.takeUntil(this.destroy$))
            .subscribe((confirmed) => {
            if (confirmed) {
                this.store.dispatch(new actions_1.OpenCreateConnectorWizardAction());
            }
        });
    }
    changeStatus(data) {
        this.store.dispatch(new actions_1.ChangeBackupStatusAction(data));
    }
    editBackup(data) {
        this.store.dispatch(new actions_1.OpenEditBackupAction(data.id));
    }
    deleteBackup(data) {
        const modalConfig = {};
        modalConfig.size = 'md';
        modalConfig.hasBackdrop = true;
        modalConfig.disableClose = true;
        modalConfig.data = { backupString: data.name };
        this.modalService
            .open(delete_backup_modal_component_1.DeleteBackupModalComponent, modalConfig)
            .afterClose()
            .pipe(operators_1.takeUntil(this.destroy$))
            .subscribe((confirmed) => {
            if (confirmed) {
                this.store.dispatch(new actions_1.DeleteBackupAction(data.id));
            }
        });
    }
}
exports.OneDriveBackupsPageComponent = OneDriveBackupsPageComponent;
