<mc-create-mep-policy
    [mepPolicy]="policy$ | async"
    [loadingForEdit]="loadingForEdit$ | async"
    [hasMepPolicyEditPermission]="hasMepPolicyEditPermission$ | async"
    (createPolicyEvent)="createMepPolicy($event)"
    (openAppliesToSidePanelWiz)="openAppliesToSidePanel($event)"
    (everyOneAffectedUsersWiz)="everyOneAffectedUsers($event)"
    (removeAffectedUsersWiz)="removeAffectedUsers($event)"
    (editPolicyEvent)="editMepPolicySettings($event)"
    (resetWizard)="resetWizard()"
></mc-create-mep-policy>
