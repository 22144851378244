"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const static_1 = require("@angular/upgrade/static");
const directory_sync_page_component_1 = require("./containers/directory-sync-page/directory-sync-page.component");
const directory_sync_wizard_1 = require("./components/directory-sync-wizard");
angular
    .module('services.directory-sync', [])
    .controller('DirectorySync', [
    '$stateParams',
    '$scope',
    function ($stateParams, $scope) {
        $scope.stateParams = $stateParams;
    }
])
    .directive('mcDirectorySyncPage', static_1.downgradeComponent({
    component: directory_sync_page_component_1.DirectorySyncPageComponent
}))
    .directive('mcDirectorySyncWizard', static_1.downgradeComponent({
    component: directory_sync_wizard_1.DirectorySyncWizardComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        const createTitle = '$I18N_DIRECTORY_SYNC_WIZARD.CREATE_TITLE';
        const editTitle = '$I18N_DIRECTORY_SYNC_WIZARD.EDIT_TITLE';
        const wizardBreadcrumbs = [
            '$I18N_NAVBAR_MENU_ITEM_SERVICES',
            '$I18N_DIRECTORY_SYNC_PAGE.TITLE',
            createTitle
        ];
        $stateProvider
            .state('directory-sync', {
            url: '/services/directory-sync',
            data: {
                isFixedLayout: false,
                checkProgress: false,
                capabilities: '(Permission.DIRECTORY_SYNC_READ || Permission.EAM_DIRECTORY_SYNC_READ) && Temporary.Services.Directory.Synchronization',
                tabId: 'directory-sync-page',
                tabReload: true,
                tabTitle: '$I18N_DIRECTORY_SYNC_PAGE.TITLE'
            },
            views: {
                main: {
                    template: '<mc-directory-sync-page></mc-directory-sync-page>',
                    controller: 'DirectorySync'
                }
            }
        })
            .state('directory-sync-wizard', {
            params: { isEditMode: null, mseSites: null, integration: null },
            url: '/services/directory-sync/wizard',
            data: {
                capabilities: 'Permission.DIRECTORY_SYNC_READ && Temporary.Services.Directory.Synchronization',
                breadcrumbs: wizardBreadcrumbs,
                tabReload: false,
                tabId: 'directory-sync-page'
            },
            views: {
                main: {
                    template: '<mc-directory-sync-wizard></mc-directory-sync-wizard>',
                    controller: 'DirectorySync'
                }
            },
            onEnter: [
                '$stateParams',
                function ($stateParams) {
                    if ($stateParams.isEditMode) {
                        wizardBreadcrumbs[wizardBreadcrumbs.length - 1] = editTitle;
                        this.data.tabTitle = editTitle;
                    }
                    else {
                        wizardBreadcrumbs[wizardBreadcrumbs.length - 1] = createTitle;
                        this.data.tabTitle = createTitle;
                    }
                }
            ]
        });
    }
]);
