"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaginationHeldReasonActionFail = exports.PaginationHeldReasonActionSuccess = exports.PaginationHeldReasonAction = exports.PAGINATION_HELD_REASON_FAIL = exports.PAGINATION_HELD_REASON_SUCCESS = exports.PAGINATION_HELD_REASON = void 0;
exports.PAGINATION_HELD_REASON = '[Rejected and Deferred] Set Held Queue Pagination';
exports.PAGINATION_HELD_REASON_SUCCESS = '[Rejected and Deferred] Set Held Queue Pagination Success';
exports.PAGINATION_HELD_REASON_FAIL = '[Rejected and Deferred] Set Held Queue Pagination Fail';
class PaginationHeldReasonAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.PAGINATION_HELD_REASON;
    }
}
exports.PaginationHeldReasonAction = PaginationHeldReasonAction;
class PaginationHeldReasonActionSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.PAGINATION_HELD_REASON_SUCCESS;
    }
}
exports.PaginationHeldReasonActionSuccess = PaginationHeldReasonActionSuccess;
class PaginationHeldReasonActionFail {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.PAGINATION_HELD_REASON_FAIL;
    }
}
exports.PaginationHeldReasonActionFail = PaginationHeldReasonActionFail;
