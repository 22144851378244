"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CrowdstrikeIntegrationService = void 0;
const operators_1 = require("rxjs/operators");
const common_1 = require("app-new/api-applications/services/common");
const BASE_URL = '/api/integration/crowdstrike';
class CrowdstrikeIntegrationService {
    constructor(http, coreService) {
        this.http = http;
        this.coreService = coreService;
    }
    createCrowdstrikeIntegration(integration) {
        return this.http
            .post(`${BASE_URL}/create-integration`, integration)
            .pipe(common_1.firstResult());
    }
    updateCrowdstrikeIntegration(integration) {
        return this.http
            .post(`${BASE_URL}/update-integration`, integration)
            .pipe(common_1.firstResult());
    }
    getCrowdstrikeIntegration(id) {
        return this.http
            .post(`${BASE_URL}/get-integration`, { id })
            .pipe(common_1.firstResult());
    }
    getCrowdstrikeActivityLogs(integrationId) {
        return this.http
            .post(`${BASE_URL}/activity-log`, { integrationId })
            .pipe(common_1.firstResult())
            .pipe(operators_1.map(({ activityLogs }) => activityLogs));
    }
    verifyClientIdAndClientSecretAction(clientId, clientSecret, baseUrl) {
        return this.http
            .post(`${BASE_URL}/verify`, { clientId, clientSecret, baseUrl })
            .pipe(common_1.firstResult());
    }
    buildUpdateRequest(configuration, sendClientSecrets) {
        let submitMaliciousUrlOptions = null;
        if (configuration.submitMaliciousUrls) {
            submitMaliciousUrlOptions = {
                iocOutboundExpiration: configuration.iocOutboundExpiration,
                iocOutboundSeverity: configuration.iocOutboundSeverity,
                iocOutboundUrlAction: configuration.iocOutboundUrlAction
            };
        }
        if (sendClientSecrets) {
            return {
                id: configuration.id,
                clientId: configuration.clientId,
                clientSecret: configuration.clientSecret,
                baseUrl: configuration.baseUrl,
                enabled: configuration.enabled,
                submitMaliciousHashes: configuration.submitMaliciousHashes,
                pullMaliciousHashes: configuration.pullMaliciousHashes,
                remediationRequired: configuration.remediationRequired,
                notificationGroups: Object.keys(configuration.notificationGroups),
                notificationAddresses: configuration.notificationAddresses,
                iocOutboundAction: configuration.iocOutboundAction,
                submitMaliciousUrls: configuration.submitMaliciousUrls,
                submitMaliciousUrlOptions
            };
        }
        return {
            id: configuration.id,
            enabled: configuration.enabled,
            submitMaliciousHashes: configuration.submitMaliciousHashes,
            pullMaliciousHashes: configuration.pullMaliciousHashes,
            remediationRequired: configuration.remediationRequired,
            notificationGroups: Object.keys(configuration.notificationGroups),
            notificationAddresses: configuration.notificationAddresses,
            iocOutboundAction: configuration.iocOutboundAction,
            submitMaliciousUrls: configuration.submitMaliciousUrls,
            submitMaliciousUrlOptions
        };
    }
    hasRequiredPackage(paskageId) {
        let hasPackage;
        this.coreService
            .getAccount()
            .pipe(operators_1.first())
            .subscribe(account => {
            hasPackage = !!account.packages.filter(pkg => pkg.indexOf(paskageId) !== -1)[0];
        });
        return hasPackage;
    }
}
exports.CrowdstrikeIntegrationService = CrowdstrikeIntegrationService;
