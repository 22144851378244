"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HarmfulLinkMisidentifiedPageComponent = void 0;
const template_previews_1 = require("../template-previews");
class HarmfulLinkMisidentifiedPageComponent extends template_previews_1.TemplatePreviewsDirective {
    constructor(ts) {
        super(ts);
        this.ts = ts;
        this.defaultTitle = '$I18N_AWARENESS_TEMPLATE_SETUP_HARMFUL_LINK_MISIDENTIFIED_PAGE_DEFAULT_TITLE';
        this.defaultSubTitle = '$I18N_AWARENESS_TEMPLATE_SETUP_HARMFUL_LINK_MISIDENTIFIED_PAGE_DEFAULT_SUB_TITLE';
    }
    ngOnInit() {
        this.tips = [
            {
                icon: 'mc-icon-fraud',
                openingStatement: this.ts.instant('$I18N_AWARENESS_TEMPLATE_SETUP_HARMFUL_LINK_MISIDENTIFIED_PAGE_OPENING_STATEMENT'),
                secondaryStatement: this.ts.instant('$I18N_AWARENESS_TEMPLATE_SETUP_HARMFUL_LINK_MISIDENTIFIED_PAGE_SECONDARY_STATEMENT')
            }
        ];
    }
}
exports.HarmfulLinkMisidentifiedPageComponent = HarmfulLinkMisidentifiedPageComponent;
