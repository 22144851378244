"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IdentityPolicySummaryStepComponent = void 0;
const policy_wizard_1 = require("../../../reducers/policy-wizard");
const policy_1 = require("../../../models/policy");
class IdentityPolicySummaryStepComponent {
    constructor(translateService, store) {
        this.translateService = translateService;
        this.store = store;
        this.noIpBasedPermissions = policy_1.LocationPermission.NONE;
        this.permittedIpsPermissionType = policy_1.LocationPermission.IP_ALLOW_LOGIN;
        this.trustedIpsPermissionType = policy_1.LocationPermission.IP_NO_2FA;
        this.ewsBasicOption = policy_1.DelegatedAuthOption.EWS;
        this.secondFactorNone = policy_1.SecondFactorOption.NONE;
    }
    ngOnInit() {
        this.detailsStep$ = this.store.select(policy_wizard_1.getDetailsStepState);
        this.authenticationMethodsStep$ = this.store.select(policy_wizard_1.getAuthenticationMethodsStepState);
        this.ipAddressesStep$ = this.store.select(policy_wizard_1.getIpAddressesStepState);
        this.sessionTimeoutStep$ = this.store.select(policy_wizard_1.getSessionTimeoutStepState);
        this.appliesToStep$ = this.store.select(policy_wizard_1.getAppliesToStepState);
    }
    formatEnabled(enabled) {
        if (enabled) {
            return this.translateService.instant('$I18N_IDENTITY_POLICY_WIZARD_SUMMARY_STEP.ENABLED');
        }
        return this.translateService.instant('$I18N_IDENTITY_POLICY_WIZARD_SUMMARY_STEP.DISABLED');
    }
}
exports.IdentityPolicySummaryStepComponent = IdentityPolicySummaryStepComponent;
