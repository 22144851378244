"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomDomainBlockBaseBuilder = void 0;
class CustomDomainBlockBaseBuilder {
    withDefinitionId(id) {
        this.definitionId = id;
        return this.self();
    }
    withPolicyId(id) {
        this.policyId = id;
        return this.self();
    }
    withName(name) {
        this.name = name;
        return this.self();
    }
    withDescription(description) {
        this.description = description;
        return this.self();
    }
    withAction(action) {
        this.action = action;
        return this.self();
    }
    withNotify(notify) {
        this.notify = notify;
        return this.self();
    }
    withConfigurationId(configurationId) {
        this.configurationId = configurationId;
        return this.self();
    }
    withLastUpdated(lastUpdated) {
        this.lastUpdated = lastUpdated;
        return this.self();
    }
    withEnabled(enabled) {
        this.enabled = enabled;
        return this.self();
    }
}
exports.CustomDomainBlockBaseBuilder = CustomDomainBlockBaseBuilder;
