"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventsAndOktaGroupsStepComponent = void 0;
const core_1 = require("@angular/core");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const okta_evidence_based_control_model_1 = require("../../../../../../models/okta-evidence-based-control.model");
class EventsAndOktaGroupsStepComponent {
    constructor(fb) {
        this.fb = fb;
        this.onSearchOktaGroups = new core_1.EventEmitter();
        this.onUpdateOktaEventGroups = new core_1.EventEmitter();
        this.oktaEventTypes = okta_evidence_based_control_model_1.OktaEventTypes;
        this.destroy$ = new rxjs_1.Subject();
    }
    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
    ngOnInit() {
        this.form = this.fb.group({
            [okta_evidence_based_control_model_1.OktaEventFormNames[okta_evidence_based_control_model_1.OktaEventTypes.DATA_LEAK_PREVENTION]]: [
                { name: okta_evidence_based_control_model_1.OktaEventTypes.DATA_LEAK_PREVENTION, enabled: false, group: {} }
            ],
            [okta_evidence_based_control_model_1.OktaEventFormNames[okta_evidence_based_control_model_1.OktaEventTypes.ATTACHMENT_PROTECT]]: [
                { name: okta_evidence_based_control_model_1.OktaEventTypes.ATTACHMENT_PROTECT, enabled: false, group: {} }
            ],
            [okta_evidence_based_control_model_1.OktaEventFormNames[okta_evidence_based_control_model_1.OktaEventTypes.URL_PROTECT]]: [
                { name: okta_evidence_based_control_model_1.OktaEventTypes.URL_PROTECT, enabled: false, group: {} }
            ]
        });
        this.patchFormValues(this.integration);
        this.subscribeToFromChanges();
    }
    get isValid() {
        return this._isOktaEventsValid;
    }
    updateOktaEventGroups(newOktaGroup) {
        this.onUpdateOktaEventGroups.emit(newOktaGroup);
    }
    patchFormValues(integration) {
        if (!!integration) {
            const { events } = integration;
            Object.entries(okta_evidence_based_control_model_1.OktaEventFormNames).forEach(([key, value]) => {
                const eventDetails = events.find(e => e.name === key);
                if (!!eventDetails) {
                    this.form.get(value).setValue(eventDetails, { emitEvent: false });
                }
            });
            this._isOktaEventsValid = true;
        }
    }
    subscribeToFromChanges() {
        this.form.valueChanges
            .pipe(operators_1.takeUntil(this.destroy$))
            .subscribe((formValues) => {
            const eventsEnabled = Object.entries(formValues)
                .map(([, value]) => value)
                .filter(data => data.enabled);
            this._isOktaEventsValid =
                !!eventsEnabled.length && eventsEnabled.every(data => { var _a; return (_a = data.group) === null || _a === void 0 ? void 0 : _a.id; });
            if (this._isOktaEventsValid) {
                this.updateOktaEventGroups(eventsEnabled);
            }
        });
    }
}
exports.EventsAndOktaGroupsStepComponent = EventsAndOktaGroupsStepComponent;
