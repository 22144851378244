"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SPFDetailsDisplayPipe = void 0;
class SPFDetailsDisplayPipe {
    constructor(translateService) {
        this.translateService = translateService;
    }
    transform(spfInfo = '') {
        if (!spfInfo.toLowerCase().startsWith('spf_')) {
            spfInfo = 'spf_' + spfInfo;
        }
        switch (spfInfo.toLowerCase()) {
            case 'spf_unrecognized':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_SPF.UNRECOGNIZED');
            case 'spf_error':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_SPF.SPF_ERROR');
            case 'spf_unknown':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_SPF.SPF_UNKNOWN');
            case 'spf_allow':
                return `<span class="positiveProperty">${this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_SPF.SPF_ALLOW')}</span>`;
            case 'spf_fail':
                return `<span class="negativeProperty">${this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_SPF.SPF_FAIL')}</span>`;
            case 'spf_soft_fail':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_SPF.SPF_SOFT_FAIL');
            case 'spf_neutral':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_SPF.SPF_NEUTRAL');
            case 'spf_dnsfail':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_SPF.SPF_DNSFAIL');
            case 'spf_dns_fail':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_SPF.SPF_DNS_FAIL');
            case 'spf_permfail':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_SPF.SPF_PERMFAIL');
            case 'spf_perm_fail':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_SPF.SPF_PERM_FAIL');
            case 'spf_na':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_SPF.SPF_NA');
            default:
                return '';
        }
    }
}
exports.SPFDetailsDisplayPipe = SPFDetailsDisplayPipe;
