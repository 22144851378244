<div class="mc-tree-breadcrumbs"
    [class.show-dropdown]="nodeStack.length > 1"
    [class.show-dropdown-more-results]="nodeStack.length > 2">

  <div class="mc-tree-breadcrumbs-item is-dropdown">
    <mc-list-dropdown-button inline="true" disableCaret="true">
      <mc-dropdown-item [label]="node.getName()" (click)="onNodeClicked(node)" *ngFor="let node of reverseOrder(nodeStack)"></mc-dropdown-item>
      <mc-dropdown-item [label]="rootFolderName" (click)="onNodeClicked(rootFolder)"></mc-dropdown-item>
    </mc-list-dropdown-button>
  </div>

  <div class="mc-tree-breadcrumbs-item is-parent" *ngIf="!(nodeStack.length > 1)" container="body" placement="top" tooltip="{{rootFolderName}}"
    (click)="onNodeClicked(rootFolder)">{{rootFolderName}}</div>
  <div class="mc-tree-breadcrumbs-item is-folder" *ngFor="let node of nodeStack" container="body" placement="top" tooltip="{{node.getName()}}"
    (click)="onNodeClicked(node)">{{node.getName()}}</div>

</div>