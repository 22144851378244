"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogBaseBuilder = void 0;
class LogBaseBuilder {
    withId(id) {
        this.id = id;
        return this.self();
    }
    withSenderAddress(senderAddress) {
        this.senderAddress = senderAddress;
        return this.self();
    }
    withRecipientAddress(recipientAddress) {
        this.recipientAddress = recipientAddress;
        return this.self();
    }
    withSubject(subject) {
        this.subject = subject;
        return this.self();
    }
    withDateSent(dateSent) {
        this.dateSent = dateSent;
        return this.self();
    }
    withStatus(status) {
        this.status = status;
        return this.self();
    }
    withExpiry(expiry) {
        this.expiry = expiry;
        return this.self();
    }
}
exports.LogBaseBuilder = LogBaseBuilder;
