"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CapabilitiesManagerService = void 0;
/* TODO: Ishan - This should be an abstract class, and every module
 * should have its own capability manager service
 */
class CapabilitiesManagerService {
    constructor(capabilitiesService) {
        this.capabilitiesService = capabilitiesService;
    }
    hasExchangeEditPermission() {
        return this.capabilitiesService.evalCapabilitiesExpression(CapabilitiesManagerService.SYNC_PERMISSIONS.EXCHANGE.SYNC_EDIT +
            '||' +
            CapabilitiesManagerService.SYNC_PERMISSIONS.EXCHANGE.SYNC_AND_RECOVER_EDIT);
    }
    hasExchangeSyncAndRecoverReadPermission() {
        return this.capabilitiesService.evalCapabilitiesExpression(CapabilitiesManagerService.SYNC_PERMISSIONS.EXCHANGE.SYNC_MAILBOX_ARCHIVE_AND_RECOVER +
            '&&' +
            CapabilitiesManagerService.SYNC_PERMISSIONS.EXCHANGE.SYNC_AND_RECOVER_READ);
    }
    hasExchangeReadPermission() {
        return this.capabilitiesService.evalCapabilitiesExpression(CapabilitiesManagerService.SYNC_PERMISSIONS.EXCHANGE.SYNC_READ +
            '||' +
            CapabilitiesManagerService.SYNC_PERMISSIONS.EXCHANGE.SYNC_AND_RECOVER_READ);
    }
    hasExchangeContentViewPermission() {
        return this.capabilitiesService.evalCapabilitiesExpression(CapabilitiesManagerService.SYNC_PERMISSIONS.EXCHANGE.SYNC_SYNC_CONTENT_VIEW +
            '||' +
            CapabilitiesManagerService.SYNC_PERMISSIONS.EXCHANGE.SYNC_AND_RECOVER_SYNC_CONTENT_VIEW);
    }
    hasExchangeExportAndRestorePermission() {
        return this.capabilitiesService.hasCapability(CapabilitiesManagerService.SYNC_PERMISSIONS.EXCHANGE.SYNC_AND_RECOVER_EXPORT_AND_RECOVER);
    }
    hasExchangeDataSyncAndRecoverPermission() {
        return this.capabilitiesService.hasCapability(CapabilitiesManagerService.SYNC_PERMISSIONS.EXCHANGE.SYNC_MAILBOX_ARCHIVE_AND_RECOVER);
    }
    // One drive
    hasOnedriveEditPermission() {
        return this.capabilitiesService.evalCapabilitiesExpression(CapabilitiesManagerService.SYNC_PERMISSIONS.ONEDRIVE.SYNC_EDIT +
            '||' +
            CapabilitiesManagerService.SYNC_PERMISSIONS.ONEDRIVE.SYNC_AND_RECOVER_EDIT);
    }
    hasOnedriveReadPermission() {
        return this.capabilitiesService.evalCapabilitiesExpression(CapabilitiesManagerService.SYNC_PERMISSIONS.ONEDRIVE.SYNC_READ +
            '||' +
            CapabilitiesManagerService.SYNC_PERMISSIONS.ONEDRIVE.SYNC_AND_RECOVER_READ);
    }
    hasOnedriveContentViewPermission() {
        return this.capabilitiesService.evalCapabilitiesExpression(CapabilitiesManagerService.SYNC_PERMISSIONS.ONEDRIVE.SYNC_SYNC_CONTENT_VIEW +
            '||' +
            CapabilitiesManagerService.SYNC_PERMISSIONS.ONEDRIVE.SYNC_AND_RECOVER_SYNC_CONTENT_VIEW);
    }
    hasOnedriveExportAndRestorePermission() {
        return this.capabilitiesService.hasCapability(CapabilitiesManagerService.SYNC_PERMISSIONS.ONEDRIVE.SYNC_AND_RECOVER_EXPORT_AND_RECOVER);
    }
    isDevelopmentOnly() {
        return this.capabilitiesService.evalCapabilitiesExpression(CapabilitiesManagerService.SYNC_PERMISSIONS.DEV_ONLY);
    }
}
exports.CapabilitiesManagerService = CapabilitiesManagerService;
CapabilitiesManagerService.SYNC_PERMISSIONS = {
    EXCHANGE: {
        SYNC_EDIT: 'Permission.DATA_SYNC_EDIT',
        SYNC_READ: 'Permission.DATA_SYNC_READ',
        SYNC_SYNC_CONTENT_VIEW: 'Permission.DATA_SYNC_SYNC_CONTENT_VIEW',
        SYNC_AND_RECOVER_READ: 'Permission.DATA_SYNC_AND_RECOVER_READ',
        SYNC_AND_RECOVER_EDIT: 'Permission.DATA_SYNC_AND_RECOVER_EDIT',
        SYNC_AND_RECOVER_SYNC_CONTENT_VIEW: 'Permission.DATA_SYNC_AND_RECOVER_SYNC_CONTENT_VIEW',
        SYNC_AND_RECOVER_EXPORT_AND_RECOVER: 'Permission.DATA_SYNC_AND_RECOVER_EXPORT_AND_RECOVER',
        SYNC_MAILBOX_ARCHIVE_AND_RECOVER: 'Sync.Mailbox.ArchiveAndRecover',
        SYNC_MAILBOX_ARCHIVE: 'Sync.Mailbox.Archive'
    },
    ONEDRIVE: {
        SYNC_READ: 'Permission.ONEDRIVE_SYNC_READ',
        SYNC_EDIT: 'Permission.ONEDRIVE_SYNC_EDIT',
        SYNC_SYNC_CONTENT_VIEW: 'Permission.ONEDRIVE_SYNC_SYNC_CONTENT_VIEW',
        SYNC_AND_RECOVER_READ: 'Permission.ONEDRIVE_SYNC_AND_RECOVER_READ',
        SYNC_AND_RECOVER_EDIT: 'Permission.ONEDRIVE_SYNC_AND_RECOVER_EDIT',
        SYNC_AND_RECOVER_SYNC_CONTENT_VIEW: 'Permission.ONEDRIVE_SYNC_AND_RECOVER_SYNC_CONTENT_VIEW',
        SYNC_AND_RECOVER_EXPORT_AND_RECOVER: 'Permission.ONEDRIVE_SYNC_AND_RECOVER_EXPORT_AND_RECOVER'
    },
    DEV_ONLY: 'Development.Only'
};
