<mc-layout-aside-extra-container
  keyTitle="$I18N_CLP_NOTIFICATION_CHANNEL.TITLE"
  showClose="true"
  [isLoading]="false"
  (closeAside)="close()">
  >
  <mc-body-container>

    <div>

      <div class="mc-channel-search-box">
        <div class="mc-label">{{ "$I18N_CLP_NOTIFICATION_CHANNEL.TEAMS_LABEL" | translate }}</div>
        <mc-filter-search
          (search)="searchTeams($event)"
          (clear)="clearTeams()"
          [placeholder]="'$I18N_CLP_NOTIFICATION_CHANNEL.TEAMS_PLACEHOLDER'"
        ></mc-filter-search>
      </div>

      <div class="mc-team-wrapper">
        <div class="mc-team-selector">
          <div *ngIf="!(teamsLoading$ | async) && teams$ | async as teams; else loadingSpinner">
            <div class="mc-no-results-message"*ngIf="teamsError$ | async">
              {{'$I18N_CLP_NOTIFICATION_CHANNEL.RESULTS_NOT_FOUND' | translate}}
            </div>
            <div *ngFor="let team of teams" (click)="chooseTeam(team)" class="mc-team"
                 [ngClass]="{'mc-selected-team': selectedTeam === team}">
              <div class="mc-searched-item">
                {{ team.displayName  }}
              </div>
            </div>
          </div>

        </div>
      </div>

      <div *ngIf="hasTeamSelected()">
        <div class="mc-channel-search-box" *ngIf="hasTeamSelected()">
          <div class="mc-label">{{ "$I18N_CLP_NOTIFICATION_CHANNEL.CHANNELS_LABEL" | translate }}</div>
          <mc-filter-search
            (search)="searchChannels($event)"
            (clear)="clearChannels()"
            [placeholder]="'$I18N_CLP_NOTIFICATION_CHANNEL.CHANNELS_PLACEHOLDER'"
          ></mc-filter-search>
        </div>

        <div class="mc-team-wrapper">
          <div class="mc-team-selector">
            <div *ngIf="!(channelsLoading$ | async) && channels$ | async as channels; else loadingSpinner">
              <div class="mc-no-results-message"*ngIf="channelsError$ | async">
                {{'$I18N_CLP_NOTIFICATION_CHANNEL.RESULTS_NOT_FOUND' | translate}}
              </div>
              <div *ngFor="let channel of channels" (click)="chooseChannel(channel)" class="mc-team"
                   [ngClass]="{'mc-selected-team': selectedChannel=== channel}">
                <div class="mc-searched-item">
                  {{ channel.displayName  }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <button class="btn btn-primary panel-half-margin-right mc-channel-add-button" (click)="submit()"
                [disabled]="!hasChannelSelected()">
          {{ '$I18N_COMMON_BTN_ADD' | translate }}
        </button>
      </div>
    </div>
    <ng-template #loadingSpinner>
      <mc-spinner class="mc-team-search-spinner"></mc-spinner>
    </ng-template>
  </mc-body-container>
</mc-layout-aside-extra-container>
