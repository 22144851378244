"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgAwCustomizedPageComponent = void 0;
class SwgAwCustomizedPageComponent {
    constructor(translate) {
        this.translate = translate;
    }
    getFooterText() {
        return this.updatedSettings.customBody
            ? this.updatedSettings.customBody
            : this.translate.instant('$I18N_SWG_BLOCK_PAGE_SETTINGS_BODY_PLACEHOLDER');
    }
}
exports.SwgAwCustomizedPageComponent = SwgAwCustomizedPageComponent;
