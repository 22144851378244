<div class="mc-identity-apps-list-container"
     [ngClass]="{ 'mc-identity-list-loading': !(initialLoadingComplete$ | async) }">
  <mc-spinner *ngIf="!(initialLoadingComplete$ | async)"></mc-spinner>

  <div *ngIf="initialLoadingComplete$ | async">

    <mc-table *ngIf="!(isLoadResponseEmpty$ | async)"
              [isLoading]="isLoading$ | async"
              [data]="tableData$ | async"
              [columns]="columnList"
              (rowClick)="viewAppDetails($event)"
              [highlightedRow]="selectedApp$ | async"
              translatePrefix="$I18N_IDENTITY_APPS_LIST">
      <mc-filters (paginatorChange)="onPaginationChange($event)"
                  [metadata]="metaData$ | async"></mc-filters>

      <mc-live-button class="mc-table-actions mc-app-create"
                      overrideClasses="btn-primary"
                      mc-caps="Permission.ID_MANAGEMENT_EDIT"
                      [label]="'$I18N_IDENTITY_APPS_LIST.BUTTONS.ADD_NEW_APP' | translate"
                      [isLoading]="isAddAppApiProcessing$ | async"
                      [mcDisabled]="isAddAppDisabled$ | async"
                      (mcClick)="addNewApp()">
      </mc-live-button>

      <mc-column key="name">
        <mc-body-cell *mcBodyCellDef="let row">
          <span>{{row.name}}</span>
        </mc-body-cell>
      </mc-column>

      <mc-column-actions key="appsActions"
                         mc-caps="Permission.ID_MANAGEMENT_EDIT"
                         mcShowColumnConfig>
        <mc-row-actions *mcRowActions="let row">
          <li mcRowAction="$I18N_IDENTITY_APPS_LIST.ACTIONS.VIEW"
              (click)="viewAppDetails(row)"></li>
          <li mcRowAction="$I18N_IDENTITY_APPS_LIST.ACTIONS.EDIT"
              (click)="editAppDetails(row)"></li>
          <li mcRowAction="$I18N_IDENTITY_APPS_LIST.ACTIONS.DELETE"
              (click)="deleteApp(row)"></li>
        </mc-row-actions>
      </mc-column-actions>
    </mc-table>

        <mc-identity-apps-empty-placeholder *ngIf="isAppListEmpty$ | async" (add)="addNewApp()"></mc-identity-apps-empty-placeholder>
    </div>
</div>
