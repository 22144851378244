"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const moment = require("moment");
const _ = require("lodash");
require("./slides/data-leak-prevention-log-detail.controller"); //data-leak-prevention-log-detail.controller
require("mimecast-web-components/src/app/components/nice-scroll/jquery.nicescroll.js");
const data_leak_prevention_log_service_1 = require("./model/data-leak-prevention-log-service");
angular.module('services.monitoring.dlp.list.controller', [
    'data-leak-prevention-log-detail.controller'
])
    .controller('DataLeakPreventionCtrl', ['$scope', '$translate', '$aside', 'dlpService', 'adconCommonsService', 'exportLogsAsideService', 'monitoringService', function ($scope, $translate, $aside, dlpService, adconCommonsService, exportLogsAsideService, monitoringService) {
        const self = this;
        let paginationActualPage = 1;
        const paginationLabelDict = {
            start: paginationActualPage,
            end: paginationActualPage
        };
        let filterModelPrevious = {};
        let logsService;
        self.filterModel = {
            dropdownLabel: '',
            areAllSelected: false,
            options: {}
        };
        self.filterCommonDropdownTypes = adconCommonsService.CONSTANTS.DROPDOWN_ITEM_TYPES;
        self.payload = {
            data: [{}],
            meta: {
                pagination: {
                    pageSize: self.pageSize
                }
            }
        };
        self.selectedData = {};
        self.pageSize = {};
        self.dlpLogs = [];
        self.nextPageResult = {};
        self.pageSizeOptions = angular.copy(adconCommonsService.getDropdownOptions().pageSizeOptions);
        self.dateRanges = angular.copy(adconCommonsService.getDropdownOptions().dateRanges);
        self.maxEndDate = new Date();
        self.minStartDate = (moment(self.maxEndDate).subtract(30, 'day')).toDate();
        self.filtersOptions = {
            LABEL: {
                type: adconCommonsService.CONSTANTS.DROPDOWN_ITEM_TYPES.LABEL,
                value: $translate.instant('$I18N_ADMINISTRATION_MENU_DATA_LEAK_PREVENTION_LIST_ROUTING_OPTIONS_SELECT_ROUTE')
            },
            ROUTE_INBOUND: {
                type: adconCommonsService.CONSTANTS.DROPDOWN_ITEM_TYPES.ITEM,
                key: dlpService.constants.ROUTE.INBOUND,
                group: dlpService.constants.API_REQUEST_FILTER_FIELDS.ROUTE,
                value: $translate.instant('$I18N_ADMINISTRATION_MENU_DATA_LEAK_PREVENTION_LIST_ROUTING_OPTIONS_INBOUND')
            },
            ROUTE_INTERNAL: {
                type: adconCommonsService.CONSTANTS.DROPDOWN_ITEM_TYPES.ITEM,
                key: dlpService.constants.ROUTE.INTERNAL,
                group: dlpService.constants.API_REQUEST_FILTER_FIELDS.ROUTE,
                value: $translate.instant('$I18N_ADMINISTRATION_MENU_DATA_LEAK_PREVENTION_LIST_ROUTING_OPTIONS_INTERNAL')
            },
            ROUTE_OUTBOUND: {
                type: adconCommonsService.CONSTANTS.DROPDOWN_ITEM_TYPES.ITEM,
                key: dlpService.constants.ROUTE.OUTBOUND,
                group: dlpService.constants.API_REQUEST_FILTER_FIELDS.ROUTE,
                value: $translate.instant('$I18N_ADMINISTRATION_MENU_DATA_LEAK_PREVENTION_LIST_ROUTING_OPTIONS_OUTBOUND')
            },
            LABEL02: {
                type: adconCommonsService.CONSTANTS.DROPDOWN_ITEM_TYPES.LABEL,
                value: $translate.instant('$I18N_ADMINISTRATION_MENU_DATA_LEAK_PREVENTION_LIST_ACTION_OPTIONS_SELECT_ACTION'),
            },
            ACTION_BOUNCE: {
                type: adconCommonsService.CONSTANTS.DROPDOWN_ITEM_TYPES.ITEM,
                key: dlpService.constants.ACTION.BOUNCE,
                group: dlpService.constants.API_REQUEST_FILTER_FIELDS.ACTION,
                value: $translate.instant('$I18N_ADMINISTRATION_MENU_DATA_LEAK_PREVENTION_LIST_ACTION_OPTIONS_BOUNCE')
            },
            ACTION_CONTENT_EXPIRE: {
                type: adconCommonsService.CONSTANTS.DROPDOWN_ITEM_TYPES.ITEM,
                key: dlpService.constants.ACTION.CONTENT_EXPIRE,
                group: dlpService.constants.API_REQUEST_FILTER_FIELDS.ACTION,
                value: $translate.instant('$I18N_ADMINISTRATION_MENU_DATA_LEAK_PREVENTION_LIST_ACTION_OPTIONS_CONTENT_EXPIRE')
            },
            ACTION_DELETE: {
                type: adconCommonsService.CONSTANTS.DROPDOWN_ITEM_TYPES.ITEM,
                key: dlpService.constants.ACTION.DELETE,
                group: dlpService.constants.API_REQUEST_FILTER_FIELDS.ACTION,
                value: $translate.instant('$I18N_ADMINISTRATION_MENU_DATA_LEAK_PREVENTION_LIST_ACTION_OPTIONS_DELETE')
            },
            ACTION_HOLD: {
                type: adconCommonsService.CONSTANTS.DROPDOWN_ITEM_TYPES.ITEM,
                key: dlpService.constants.ACTION.HOLD,
                group: dlpService.constants.API_REQUEST_FILTER_FIELDS.ACTION,
                value: $translate.instant('$I18N_ADMINISTRATION_MENU_DATA_LEAK_PREVENTION_LIST_ACTION_OPTIONS_HOLD')
            },
            ACTION_META_EXPIRE: {
                type: adconCommonsService.CONSTANTS.DROPDOWN_ITEM_TYPES.ITEM,
                key: dlpService.constants.ACTION.META_EXPIRE,
                group: dlpService.constants.API_REQUEST_FILTER_FIELDS.ACTION,
                value: $translate.instant('$I18N_ADMINISTRATION_MENU_DATA_LEAK_PREVENTION_LIST_ACTION_OPTIONS_META_EXPIRE')
            },
            ACTION_NOTIFICATION: {
                type: adconCommonsService.CONSTANTS.DROPDOWN_ITEM_TYPES.ITEM,
                key: dlpService.constants.ACTION.NOTIFICATION,
                group: dlpService.constants.API_REQUEST_FILTER_FIELDS.ACTION,
                value: $translate.instant('$I18N_ADMINISTRATION_MENU_DATA_LEAK_PREVENTION_LIST_ACTION_OPTIONS_NOTIFICATION')
            },
            ACTION_REMOVE_EMAIL: {
                type: adconCommonsService.CONSTANTS.DROPDOWN_ITEM_TYPES.ITEM,
                key: dlpService.constants.ACTION.REMOVE_EMAIL,
                group: dlpService.constants.API_REQUEST_FILTER_FIELDS.ACTION,
                value: $translate.instant('$I18N_ADMINISTRATION_MENU_DATA_LEAK_PREVENTION_LIST_ACTION_OPTIONS_REMOVE_EMAIL')
            },
            SECURE_MESSAGING: {
                type: adconCommonsService.CONSTANTS.DROPDOWN_ITEM_TYPES.ITEM,
                key: dlpService.constants.ACTION.SECURE_MESSAGING,
                group: dlpService.constants.API_REQUEST_FILTER_FIELDS.ACTION,
                value: $translate.instant('$I18N_ADMINISTRATION_MENU_DATA_LEAK_PREVENTION_LIST_ACTION_OPTIONS_SECURE_MESSAGING')
            },
            SMART_FOLDER: {
                type: adconCommonsService.CONSTANTS.DROPDOWN_ITEM_TYPES.ITEM,
                key: dlpService.constants.ACTION.SMART_FOLDER,
                group: dlpService.constants.API_REQUEST_FILTER_FIELDS.ACTION,
                value: $translate.instant('$I18N_ADMINISTRATION_MENU_DATA_LEAK_PREVENTION_LIST_ACTION_OPTIONS_SMART_FOLDER')
            }
        };
        self.searchFields = {
            ALL: {
                type: adconCommonsService.CONSTANTS.DROPDOWN_ITEM_TYPES.ITEM,
                key: dlpService.constants.SEARCH_FIELD.ALL,
                value: $translate.instant('$I18N_ADMINISTRATION_MENU_DATA_LEAK_PREVENTION_LIST_ACTION_OPTIONS_ALL'),
                active: true
            },
            LABEL: {
                type: adconCommonsService.CONSTANTS.DROPDOWN_ITEM_TYPES.LABEL,
                value: $translate.instant('$I18N_ADMINISTRATION_COMMONS_FILTER_OPTIONS_TITLE')
            },
            SENDER_ADDRESS: {
                type: adconCommonsService.CONSTANTS.DROPDOWN_ITEM_TYPES.ITEM,
                key: dlpService.constants.SEARCH_FIELD.SENDER_ADDRESS,
                value: $translate.instant('$I18N_ADMINISTRATION_COMMONS_TABLE_HEADER_LABEL_FROM')
            },
            RECIPIENT_ADDRESS: {
                type: adconCommonsService.CONSTANTS.DROPDOWN_ITEM_TYPES.ITEM,
                key: dlpService.constants.SEARCH_FIELD.RECIPIENT_ADDRESS,
                value: $translate.instant('$I18N_ADMINISTRATION_COMMONS_TABLE_HEADER_LABEL_TO')
            },
            SUBJECT: {
                type: adconCommonsService.CONSTANTS.DROPDOWN_ITEM_TYPES.ITEM,
                key: dlpService.constants.SEARCH_FIELD.SUBJECT,
                value: $translate.instant('$I18N_ADMINISTRATION_MENU_DATA_LEAK_PREVENTION_LIST_TABLE_SUBJECT_HEADER')
            },
            POLICY: {
                type: adconCommonsService.CONSTANTS.DROPDOWN_ITEM_TYPES.ITEM,
                key: dlpService.constants.SEARCH_FIELD.POLICY,
                value: $translate.instant('$I18N_ADMINISTRATION_MENU_DATA_LEAK_PREVENTION_LIST_TABLE_POLICY_HEADER')
            }
        };
        self.columns = [
            {
                key: 'from',
                value: '$I18N_ADMINISTRATION_COMMONS_TABLE_HEADER_LABEL_FROM',
                exportOrder: 0
            }, {
                key: 'policy',
                value: '$I18N_ADMINISTRATION_MENU_DATA_LEAK_PREVENTION_LIST_TABLE_POLICY_HEADER',
                exportOrder: 4
            }, {
                key: 'to',
                value: '$I18N_ADMINISTRATION_COMMONS_TABLE_HEADER_LABEL_TO',
                exportOrder: 1
            }, {
                key: 'action',
                value: '$I18N_ADMINISTRATION_MENU_DATA_LEAK_PREVENTION_LIST_TABLE_ACTION_HEADER',
                exportOrder: 5
            }, {
                key: 'subject',
                value: '$I18N_ADMINISTRATION_MENU_DATA_LEAK_PREVENTION_LIST_TABLE_SUBJECT_HEADER',
                exportOrder: 2
            }, {
                key: 'datetime',
                value: '$I18N_ADMINISTRATION_COMMONS_TABLE_HEADER_LABEL_DATETIME',
                exportOrder: 6
            }, {
                key: 'routing',
                value: '$I18N_ADMINISTRATION_MENU_DATA_LEAK_PREVENTION_LIST_TABLE_ROUTING_HEADER',
                exportOrder: 3
            }
        ];
        self.paginationLabel = '';
        self.paginationNextPageResults = false;
        self.paginationPreviousPageResults = false;
        $scope.$watch(function () {
            return (self.payload);
        }, function (oldValue, newValue) {
            isPayloadUpdated(oldValue, newValue);
            self.getDLPLogs();
        }, true);
        $scope.tab.onRefresh = self.refresh = function () {
            logsService.setLogs([]);
            getDLPLogs();
        };
        self.getDLPLogs = getDLPLogs;
        self.pageSizeOptionsUpdate = pageSizeOptionsUpdate;
        self.getMoreResults = getMoreResults;
        self.updateSearch = updateSearch;
        self.clearSearch = clearSearch;
        self.exportColumns = exportColumns;
        self.openDetailsAside = openDetailsAside;
        self.filterModelController = filterModelController;
        self.filterBulkModelUpdate = filterBulkModelUpdate;
        self.applyFilter = applyFilter;
        self.cancelFilter = cancelFilter;
        self.paginationNextOnChange = paginationNextOnChange;
        self.paginationPreviousOnChange = paginationPreviousOnChange;
        self.showEmptyContainerMessage = showEmptyContainerMessage;
        self.getHighlightedRow = getHighlightedRow;
        activate();
        function activate() {
            self.payload.data[0].from = self.dateRanges[adconCommonsService.CONSTANTS.DATE_RANGES_KEYS.TODAY].dates.from;
            self.payload.data[0].to = self.dateRanges[adconCommonsService.CONSTANTS.DATE_RANGES_KEYS.TODAY].dates.to;
            pageSizeOptionsUpdate(adconCommonsService.CONSTANTS.PAGE_SIZES_KEYS.I50);
            monitoringService.filterOperations.updateFilterLabel(self.filterModel);
            filterModelPrevious = angular.copy(self.filterModel);
        }
        function getDLPLogs() {
            const payload = angular.copy(self.payload);
            const pageSize = payload.meta.pagination.pageSize;
            payload.data[0].from = moment(self.payload.data[0].from).format(adconCommonsService.CONSTANTS.API_DATE_FORMAT);
            payload.data[0].to = moment(self.payload.data[0].to).format(adconCommonsService.CONSTANTS.API_DATE_FORMAT);
            self.isDataLoading = true;
            dlpService.getLogs(payload).then(function (response) {
                if (!response.err) {
                    self.dlpLogs = adconCommonsService.humanizeActionFilters(response.first.dlpLogs, self.filtersOptions, dlpService.constants.API_RESPONSE_FILTER_FIELDS.ACTION);
                    self.dlpLogs = adconCommonsService.humanizeActionFilters(response.first.dlpLogs, self.filtersOptions, dlpService.constants.API_RESPONSE_FILTER_FIELDS.ROUTE);
                    if (response.meta && response.meta().pagination) {
                        paginationLabelDict.start = paginationActualPage === 1 ? paginationActualPage : (pageSize * (paginationActualPage - 1)) + 1;
                        paginationLabelDict.end = self.dlpLogs.length !== pageSize ? (pageSize * (paginationActualPage - 1)) + self.dlpLogs.length : pageSize * paginationActualPage;
                        self.paginationLabel = self.dlpLogs.length > 0 ? $translate.instant('$I18N_ADMINISTRATION_COMMONS_PAGINATION_SEPARATOR', {
                            start: paginationLabelDict.start,
                            end: paginationLabelDict.end
                        }) : '';
                        self.paginationNextPageResults = response.meta().pagination.next;
                        self.paginationPreviousPageResults = response.meta().pagination.previous;
                    }
                    logsService = new data_leak_prevention_log_service_1.DataLeakPreventionLogService(self.dlpLogs, []);
                }
                else {
                    dlpService.getError().showErrorNotification(response);
                }
                self.isDataLoading = false;
            }, function () {
                self.isDataLoading = false;
                dlpService.getError().showErrorNotification();
            });
        }
        function getMoreResults(pageToken) {
            if (!self.isDataLoading && pageToken) {
                self.payload.meta.pagination.pageToken = pageToken;
            }
        }
        function pageSizeOptionsUpdate(id) {
            self.payload.meta.pagination.pageSize = self.pageSizeOptions[id].key;
            adconCommonsService.setDropdownActiveItem(self.pageSizeOptions, id);
            self.selectedPageSizeOptions = self.pageSizeOptions[id].value;
        }
        function updateSearch() {
            self.payload.data[0].query = self.searchTerm;
        }
        function clearSearch() {
            delete self.payload.data[0].query;
        }
        function isPayloadUpdated(oldPayload, newPayload) {
            if (!(_.isEqual(oldPayload.data[0], newPayload.data[0])) || !(_.isEqual(oldPayload.meta.pagination.pageSize, newPayload.meta.pagination.pageSize))) {
                delete self.payload.meta.pagination.pageToken;
                paginationActualPage = 1;
            }
        }
        function exportColumns() {
            const uid = dlpService.constants.EXPORT_UID;
            const from = moment(self.payload.data[0].from).valueOf();
            const to = moment(self.payload.data[0].to).valueOf();
            const columns = self.columns;
            const filters = self.filterModel.options;
            const query = self.payload.data[0].query && self.payload.data[0].query + ':' + self.payload.data[0].searchField;
            const filterExportMappings = dlpService.constants.EXPORT_REQUEST_FILTER_PARAMETER_FIELDS;
            exportLogsAsideService.openExportAside(uid, from, to, columns, filters, query, filterExportMappings);
        }
        function openDetailsAside(row) {
            logsService.highlightRow(row);
            prepareAndSetNiceScroll();
            if (!self.asideInstance) {
                self.asideInstance = $aside.open({
                    templateUrl: 'services/monitoring/data-leak-prevention/data-leak-prevention-list/slides/data-leak-prevention-log-detail.tpl.html',
                    placement: 'right',
                    size: 'lg',
                    backdrop: true,
                    windowClass: 'hidden-xs',
                    backdropClass: 'hidden-xs',
                    controller: angular.noop,
                    resolve: {
                        dlpLogIndex() {
                            return row;
                        },
                        dlpLogsService() {
                            return logsService;
                        }
                    }
                }).result.finally(function () {
                    logsService.setArrRowClicked([]);
                    self.asideInstance = undefined;
                });
            }
        }
        function paginationNextOnChange() {
            paginationActualPage++;
            getMoreResults(self.paginationNextPageResults);
        }
        function paginationPreviousOnChange() {
            paginationActualPage--;
            getMoreResults(self.paginationPreviousPageResults);
        }
        function filterModelController(id, value) {
            monitoringService.filterOperations.filterModelController(self.filterModel, id, value);
        }
        function filterBulkModelUpdate() {
            monitoringService.filterOperations.filterBulkModelUpdate(self.filterModel, self.filtersOptions);
        }
        function applyFilter() {
            monitoringService.filterOperations.applyFilter(self.payload.data[0], self.filterModel, self.filtersOptions);
            filterModelPrevious = angular.copy(self.filterModel);
        }
        function cancelFilter() {
            self.filterModel = angular.copy(filterModelPrevious);
        }
        function showEmptyContainerMessage() {
            return self.payload.data[0].query ? '$I18N_ADMINISTRATION_MENU_DATA_LEAK_PREVENTION_RESULTS_EMPTY_TITLE' : '$I18N_ADMINISTRATION_MENU_DATA_LEAK_PREVENTION_LIST_EMPTY_TITLE';
        }
        function getHighlightedRow(row) {
            return logsService.getHighlightedRow(row);
        }
        function prepareAndSetNiceScroll() {
            const niceScroll = {
                // @ts-ignore
                niceScroll: $('.mc-data-leak-prevention-list').getNiceScroll(0),
                rowHeight: $('.mc-js-dlp-log-row-id').eq(0).outerHeight(true)
            };
            logsService.setNiceScroll(niceScroll);
        }
    }]);
