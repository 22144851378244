"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChameleonDomainValidator = exports.isValidDomain = void 0;
const DOMAIN_REGEXP = /^((?=[a-zA-Z0-9-]{1,63}\.)(xn--)?[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*\.)+[a-zA-Z]{2,63}$/;
const isValidDomain = (domain) => DOMAIN_REGEXP.test(domain.trim());
exports.isValidDomain = isValidDomain;
const ChameleonDomainValidator = (control) => {
    return exports.isValidDomain(control.value) ? null : { INVALID_DOMAIN: { value: control.value } };
};
exports.ChameleonDomainValidator = ChameleonDomainValidator;
