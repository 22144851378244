"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgDeviceGroupsListComponent = void 0;
const core_1 = require("@angular/core");
const table_store_1 = require("@mimecast-ui/table-store");
const device_groups_list_static_values_1 = require("./device-groups-list.static-values");
const device_groups_models_1 = require("../../../models/device-groups.models");
class SwgDeviceGroupsListComponent extends table_store_1.TableBaseComponent {
    constructor(store) {
        super(store, 'SwgDeviceGroupsListTable');
        this.store = store;
        this.displayType = device_groups_models_1.DeviceListDisplayType.READ;
        this.tableRowClicked = new core_1.EventEmitter();
        this.createNewDeviceGroup = new core_1.EventEmitter();
        this.onDeleteDeviceGroup = new core_1.EventEmitter();
        this.onEditDeviceGroup = new core_1.EventEmitter();
        this.addDeviceGroupsToTargets = new core_1.EventEmitter();
        this.dateFormat = 'dd MMM yyyy';
        this.timezoneOffset = '';
        this.columnsAlwaysVisible = device_groups_list_static_values_1.columnsAlwaysVisible;
        this.columns = device_groups_list_static_values_1.columns;
        this.actionColumns = device_groups_list_static_values_1.actionColumns;
        this.columnsToIgnore = device_groups_list_static_values_1.columnsToIgnore;
        this.deviceGroupDisplayType = device_groups_models_1.DeviceListDisplayType;
        this.isSelectedAll = false;
    }
    ngOnInit() {
        super.ngOnInit();
        this.setupSubscription();
    }
    setupSubscription() {
        this.tableDataSubscription = this.tableData$.subscribe((data) => {
            if (data) {
                this.tableDataCopy = data.map(x => x);
            }
        });
    }
    getColumns() {
        return this.displayType === this.deviceGroupDisplayType.TARGET
            ? this.actionColumns
            : this.columns;
    }
    onSelectAll(event) {
        this.isSelectedAll = event.target.checked;
        this.toggleAll(event.target.checked);
    }
    toggleAll(selected) {
        if (this.tableDataCopy && this.tableDataCopy.length) {
            this.tableDataCopy = this.tableDataCopy.map(x => {
                return Object.assign(Object.assign({}, x), { selected });
            });
        }
    }
    onSelectingSingleItem(data) {
        data.event.stopPropagation();
        this.tableDataCopy = this.tableDataCopy.map(x => {
            if (x.id === data.deviceGroup.id) {
                return Object.assign(Object.assign({}, x), { selected: data.event.target.checked });
            }
            return x;
        });
    }
    addDeviceGroups() {
        if (this.tableDataCopy && this.tableDataCopy.length) {
            const deviceGroups = [];
            this.tableDataCopy = this.tableDataCopy.map(x => {
                if (x.selected) {
                    deviceGroups.push(x);
                    return Object.assign(Object.assign({}, x), { selected: false });
                }
                return x;
            });
            this.isSelectedAll = false;
            this.addDeviceGroupsToTargets.emit(deviceGroups);
        }
    }
    onSearch(filters) {
        this.filterSearch = { searchBy: { fieldName: 'name', value: filters.search } };
        this.runFilterSearch();
    }
    onSearchClear() {
        this.filterSearch = undefined;
        this.runFilterSearch();
    }
    runFilterSearch() {
        const searchPayload = Object.assign(Object.assign({}, this.filterSearch), this.filtersStatus);
        this.onFilterChange(searchPayload);
    }
    onEditClick(group) {
        if (this.displayType !== this.deviceGroupDisplayType.TARGET) {
            this.onEditDeviceGroup.emit(group);
        }
    }
    onDeleteClick(group) {
        if (this.displayType !== this.deviceGroupDisplayType.TARGET) {
            this.onDeleteDeviceGroup.emit(group);
        }
    }
    onRowClick(row) {
        if (this.displayType !== this.deviceGroupDisplayType.TARGET) {
            super.onRowClick(row);
            this.tableRowClicked.emit(row.id);
        }
    }
    onCreateDeviceGroup() {
        if (this.displayType !== this.deviceGroupDisplayType.TARGET) {
            this.createNewDeviceGroup.emit();
        }
    }
    ngOnDestroy() {
        if (this.tableDataSubscription) {
            this.tableDataSubscription.unsubscribe();
        }
        super.ngOnDestroy();
    }
}
exports.SwgDeviceGroupsListComponent = SwgDeviceGroupsListComponent;
