"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ1 = exports.ɵ0 = exports.QueueConfigFactory = void 0;
const queue_config_builder_1 = require("../model/queue/queue-config.builder");
const queue_config_details_builder_1 = require("../model/queue/queue-config-details.builder");
const ɵ0 = (queueConfig) => new queue_config_builder_1.QueueConfigBuilder()
    .withId(queueConfig['id'])
    .withName(queueConfig['name'])
    .withCreatedBy(queueConfig['createdBy'])
    .withUpdatedBy(queueConfig['updatedBy'])
    .withCreated(queueConfig['created'])
    .withUpdated(queueConfig['updated'])
    .withDescription(queueConfig['description'])
    .withType(queueConfig['type'])
    .withPrintAllowed(queueConfig['printAllowed'])
    .withForwardAllowed(queueConfig['forwardAllowed'])
    .withStatus(queueConfig['status'])
    .withLabels(queueConfig['labels'])
    .withReviewerCount(queueConfig['reviewerCount'])
    .build(), ɵ1 = (queueConfig) => new queue_config_details_builder_1.QueueConfigDetailsBuilder()
    .withReviewers(queueConfig['reviewers'])
    .withAssociatedReviewQueues(queueConfig['reviewQueues'])
    .withAssociatedRules(queueConfig['associatedRules'])
    .withEscalationQueues(queueConfig['escalationQueues'])
    .withDefaultEscalationQueue(queueConfig['defaultEscalationQueue'])
    .withId(queueConfig['id'])
    .withName(queueConfig['name'])
    .withCreatedBy(queueConfig['createdBy'])
    .withUpdatedBy(queueConfig['updatedBy'])
    .withCreated(queueConfig['created'])
    .withUpdated(queueConfig['updated'])
    .withDescription(queueConfig['description'])
    .withType(queueConfig['type'])
    .withPrintAllowed(queueConfig['printAllowed'])
    .withForwardAllowed(queueConfig['forwardAllowed'])
    .withStatus(queueConfig['status'])
    .withLabels(queueConfig['labels'])
    .withReviewerCount(queueConfig['reviewerCount'])
    .build();
exports.ɵ0 = ɵ0;
exports.ɵ1 = ɵ1;
exports.QueueConfigFactory = {
    BUILD_QUEUE_CONFIG: ɵ0,
    BUILD_QUEUE_CONFIG_DETAILS: ɵ1
};
