<mc-modal-header headerTitle="{{ ('$I18N_ATTRIBUTES_DELETE_MODAL.HEADER' | translate)  }}"></mc-modal-header>

<mc-modal-body>
  <p>{{ '$I18N_ATTRIBUTES_DELETE_MODAL.MESSAGE' | translate }}</p>
</mc-modal-body>

<mc-modal-footer>

  <button type="button" class="btn btn-secondary" (click)="onClose()">
    {{ '$I18N_ATTRIBUTES_DELETE_MODAL.CANCEL' | translate }}
  </button>

  <mc-live-button overrideClasses="btn-danger" [label]="'$I18N_ATTRIBUTES_DELETE_MODAL.DELETE' | translate" (mcClick)="onDelete()">
  </mc-live-button>

</mc-modal-footer>
