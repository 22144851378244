"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectQueueComponent = void 0;
const core_1 = require("@angular/core");
class SelectQueueComponent {
    constructor(overlayRef) {
        this.overlayRef = overlayRef;
        this.searchQueryChanged = new core_1.EventEmitter();
        this.queueSelected = new core_1.EventEmitter();
        this.noQueuesFound = false;
    }
    ngOnInit() {
        this.searchQueue.nativeElement.focus();
    }
    ngOnChanges(changes) {
        if (changes.queues && changes.queues.currentValue) {
            this.noQueuesFound = changes.queues.currentValue.length === 0;
        }
    }
    onSearchChange(event) {
        const searchQuery = event.target.value;
        this.searchQueryChanged.emit(searchQuery);
    }
    onClose() {
        this.overlayRef.close();
    }
    selectQueue(queue) {
        this.onClose();
        this.queueSelected.emit(queue);
    }
    isQueueSelected(queue) {
        if (!this.selectedQueue) {
            return false;
        }
        return this.selectedQueue.id === queue.id;
    }
    get sortedQueues() {
        return this.queues ? this.queues.slice().sort((a, b) => a.name.localeCompare(b.name)) : [];
    }
}
exports.SelectQueueComponent = SelectQueueComponent;
