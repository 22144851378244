"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tableSectionSearch = exports.tableSectionFilters = exports.tableColumnsToIgnore = exports.tableColumnsStartHidden = exports.tableColumnsAlwaysVisible = exports.exportColumns = exports.tableColumns = exports.tableSelectColumn = void 0;
exports.tableSelectColumn = 'select';
exports.tableColumns = [
    'fromEnv',
    'fromHdr',
    'to',
    'subject',
    'sent',
    'senderIP',
    'attachments',
    'size',
    'route',
    'messageInfo',
    'spamScore',
    'detectionLevel',
    'messageEvent',
    'right-column'
];
exports.exportColumns = [
    'fromEnv',
    'fromHdr',
    'to',
    'subject',
    'sent',
    'senderIP',
    'attachments',
    'size',
    'route',
    'messageInfo',
    'spamScore',
    'spamDetection',
    'messageEvent'
];
exports.tableColumnsAlwaysVisible = ['to', 'sent', 'messageInfo'];
exports.tableColumnsStartHidden = [
    'fromEnv',
    'senderIP',
    'attachments',
    'size',
    'spamScore',
    'detectionLevel',
    'messageEvent'
];
exports.tableColumnsToIgnore = [];
exports.tableSectionFilters = [
    {
        name: 'messageInfo',
        filters: ['delivered', 'pending_delivery', 'hard_bounce', 'soft_bounce', 'journal']
    },
    {
        name: 'route',
        filters: ['inbound', 'internal', 'outbound']
    },
    {
        name: 'attachments',
        filters: ['true', 'false']
    }
];
exports.tableSectionSearch = {
    field: 'search',
    header: 'header',
    options: ['from', 'to', 'subject', 'senderIP']
};
