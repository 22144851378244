"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RuleDetailsEffect = void 0;
const rule_details_actions_1 = require("../actions/rule.details.actions");
const table_store_1 = require("@mimecast-ui/table-store");
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const rules_service_1 = require("../containers/list/services/rules.service");
const notification_actions_1 = require("app-new/components/notification/actions/notification.actions");
class RuleDetailsEffect {
    constructor(actions$, ruleService, translateService) {
        this.actions$ = actions$;
        this.ruleService = ruleService;
        this.translateService = translateService;
        this.getRule$ = this.actions$.pipe(effects_1.ofType(rule_details_actions_1.RuleDetailsActions.GET_RULE_DETAILS), operators_1.map((action) => action.rule), operators_1.switchMap((rule) => {
            return this.ruleService.getRule(rule.id).pipe(operators_1.map(response => {
                if (response.hasErrors) {
                    return new rule_details_actions_1.GetRuleDetailsActionFail(response.fail[0]);
                }
                const ruleDetails = response.first;
                return new rule_details_actions_1.GetRuleDetailsActionSuccess(ruleDetails);
            }), operators_1.catchError(response => {
                return rxjs_1.of(new rule_details_actions_1.GetRuleDetailsActionFail(response.fail[0]));
            }));
        }));
        this.getRuleFail$ = this.actions$.pipe(effects_1.ofType(rule_details_actions_1.RuleDetailsActions.GET_RULE_DETAILS_FAIL), operators_1.map((action) => action.failure), operators_1.map((payload) => payload.errors), operators_1.switchMap((errors) => {
            if (!errors) {
                return rxjs_1.EMPTY;
            }
            return errors.map(error => new notification_actions_1.NotificationShowFailAction(this.translateService.instant(error.code)));
        }));
        this.updatePaginationInfo$ = this.actions$.pipe(effects_1.ofType(rule_details_actions_1.RuleDetailsActions.UPDATE_RULE_DETAILS_PAGINATION_INFO), operators_1.switchMap((action) => {
            return [
                this.getPaginationInfo(action.currentRule, action.listRules),
                new table_store_1.SetSelectedRowAction({
                    tableId: rules_service_1.RulesService.SUPERVISION_RULES_LIST,
                    item: action.currentRule
                })
            ];
        }));
    }
    getPaginationInfo(currentRule, tableData) {
        const currentRuleIndex = tableData.findIndex(value => value.id === currentRule.id);
        const previousRule = currentRuleIndex !== undefined && currentRuleIndex > 0
            ? tableData[currentRuleIndex - 1]
            : undefined;
        const nextRule = currentRuleIndex !== undefined && currentRuleIndex < tableData.length
            ? tableData[currentRuleIndex + 1]
            : undefined;
        return new rule_details_actions_1.SetRuleDetailsPaginationInfoAction(previousRule, currentRule, nextRule, currentRuleIndex, tableData.length);
    }
}
__decorate([
    effects_1.Effect()
], RuleDetailsEffect.prototype, "getRule$", void 0);
__decorate([
    effects_1.Effect()
], RuleDetailsEffect.prototype, "getRuleFail$", void 0);
__decorate([
    effects_1.Effect()
], RuleDetailsEffect.prototype, "updatePaginationInfo$", void 0);
exports.RuleDetailsEffect = RuleDetailsEffect;
