<div class="mc-url-protection-policy-block">
    <mc-wizard-step-header label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.STEP_TITLE">
    </mc-wizard-step-header>


    <form class="form-horizontal" mcDefaultLabelClass="col-sm-3" mcDefaultControlContainerClass="col-sm-9"
      [formGroup]="urlProtectionPolicyJournalSettingsForm">
      <mc-field class="mc-custom-switch-form-field"
        label="{{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.CHECK_INTERNAL_EMAIL' | translate}}"
        class="mc-custom-label"
        data-unit-test="check-internal-email-control"
        data-cy-test="check-internal-email-control">
        <mc-switch formControlName="checkInternalEmail" role="switch" showLabel="true"></mc-switch>
      </mc-field>

      <ng-container *ngIf="checkInternalEmail()">
        <mc-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.URL_SCANNING_MODE.LABEL"
          popoverPlacement="top"
          data-unit-test="url-scanning-mode-control"
          data-cy-test="url-scanning-mode-control"
          helpPopoverContent="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.TOOLTIPS.URL_SCANNING_MODE' | translate }}">
          <mc-select formControlName="urlScanningMode" [options]="urlScanningModeValues" [useTranslation]="true">
          </mc-select>
        </mc-field>
        <mc-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.URL_CATEGORY_SCANNING_MODE.LABEL"
          popoverPlacement="top"
          data-unit-test="url-category-scanning-mode-control"
          data-cy-test="url-category-scanning-mode-control"
          helpPopoverContent="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.TOOLTIPS.URL_CATEGORY_SCANNING_MODE' | translate }}">
          <mc-select formControlName="urlCategoryScanningMode" [options]="urlCategoryScanningModeValues" [useTranslation]="true">
          </mc-select>
        </mc-field>
        <mc-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.SENDER_MAILBOX_ACTION.LABEL"
          popoverPlacement="top"
          data-unit-test="sender-mailbox-control"
          data-cy-test="sender-mailbox-control"
          helpPopoverContent="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.TOOLTIPS.SENDER_MAILBOX_ACTION' | translate }}">
          <mc-select formControlName="senderMailboxAction" [options]="senderMailboxActionValues" [useTranslation]="true">
          </mc-select>
        </mc-field>
        <mc-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.SENDER_MAILBOX_FALLBACK_ACTION.LABEL"
          popoverPlacement="top"
          data-unit-test="sender-mailbox-fallback-action-control"
          data-cy-test="sender-mailbox-fallback-action-control"
          helpPopoverContent="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.TOOLTIPS.SENDER_MAILBOX_FALLBACK_ACTION' | translate }}">
          <mc-select formControlName="senderMailboxFallbackAction" [options]="senderMailboxFallbackActionValues" [useTranslation]="true">
          </mc-select>
        </mc-field>
        <mc-sub-section
          title="{{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.SECTION_TITLES.URL_AND_ATTACHMENTS' | translate}}">
          <mc-field class="mc-custom-switch-form-field"
            label="{{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.BLOCK_URLS_WITH_DANGEROUS_FILE_EXTENSIONS' | translate}}"
            popoverPlacement="top"
            class="mc-custom-label"
            data-unit-test="block-url-with-dangerous-file-extensions-control"
            data-cy-test="block-url-with-dangerous-file-extensions-control"
            helpPopoverContent="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.TOOLTIPS.BLOCK_URLS_WITH_DANGEROUS_FILE_EXTENSIONS' | translate }}">
            <mc-switch formControlName="blockUrlWithDangerousFileExtensions" role="switch" showLabel="true"></mc-switch>
          </mc-field>
          <mc-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.URL_FILE_DOWNLOAD.LABEL"
            popoverPlacement="top"
            data-unit-test="url-file-download-control"
            data-cy-test="url-file-download-control"
            helpPopoverContent="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.TOOLTIPS.URL_FILE_DOWNLOAD' | translate }}">
            <mc-select formControlName="urlFileDownload" [options]="urlFileDownloadValues" [useTranslation]="true">
            </mc-select>
          </mc-field>
          <mc-field class="mc-custom-switch-form-field"
            label="{{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.SCAN_URLS_IN_ATTACHMENTS' | translate}}"
            popoverPlacement="top"
            class="mc-custom-label"
            data-unit-test="scan-url-in-attachments-control"
            data-cy-test="scan-url-in-attachments-control"
            helpPopoverContent="{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.TOOLTIPS.SCAN_URLS_IN_ATTACHMENTS' | translate }}">
            <mc-switch formControlName="scanUrlInAttachments" role="switch" showLabel="true"></mc-switch>
          </mc-field>
          <ng-container *ngIf="checkScanUrlsInAttachment()">
            <mc-field class="mc-custom-switch-form-field"
              data-unit-test="scan-url-in-attachments-controls"
              data-cy-test="scan-url-in-attachments-controls"
              label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.ATTACHMENT_PARTS">
              <mc-checkbox label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.HTML"
                formControlName="attachmentPartHTML">
              </mc-checkbox>
              <mc-checkbox label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.TEXT"
                formControlName="attachmentPartText">
              </mc-checkbox>
              <mc-checkbox label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.CALENDAR"
                formControlName="attachmentPartCalendar">
              </mc-checkbox>
              <mc-checkbox label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.OTHER"
                formControlName="attachmentPartOther">
              </mc-checkbox>
            </mc-field>
          </ng-container>
        </mc-sub-section>
        <mc-sub-section
          title="{{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.SECTION_TITLES.ADV_SIMILARITY_CHECKS' | translate}}"
          description="{{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.SECTION_DESCRIPTION.ADV_SIMILARITY_CHECKS' | translate}}">
          <mc-field class="mc-custom-switch-form-field"
            class="mc-custom-label"
            data-unit-test="check-for-similar-urls-control"
            data-cy-test="check-for-similar-urls-control"
            label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.CHECK_FOR_SIMILAR_URLS">
            <mc-switch formControlName="checkForSimilarUrls" role="switch" showLabel="true"></mc-switch>
          </mc-field>
          <ng-container *ngIf="checkForSimilarUrls()">
            <mc-field class="mc-custom-switch-form-field"
              data-unit-test="domains-to-check-against-control"
              data-cy-test="domains-to-check-against-control"
              label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.DOMAINS_TO_CHECK_AGAINST">
              <mc-checkbox label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.YOUR_INTERNAL_DOMAINS"
                formControlName="domainToCheckAgainstYourInternalDomains">
              </mc-checkbox>
              <mc-checkbox label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.MIMECAST_MONITORED_EXTERNAL_DOMAINS"
                formControlName="domainToCheckAgainstMimecastMonitoredExternalDomains">
              </mc-checkbox>
              <mc-checkbox label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.CUSTOM_MONITORED_EXTERNAL_DOMAINS"
                formControlName="domainToCheckAgainstCustomMonitoredExternalDomains">
              </mc-checkbox>
            </mc-field>
          </ng-container>
        </mc-sub-section>
        <mc-sub-section
          title="{{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.SECTION_TITLES.NOTIFICATIONS' | translate}}"
          description="{{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.SECTION_DESCRIPTION.NOTIFICATIONS' | translate}}">
          <ng-container *ngIf="!isAAAOrFAAUser">
            <div
              class="form-group mc-field"
              data-unit-test="administration-control"
            >
              <label class="control-label col-sm-3">
                <span class="mc-label-wrapper">
                  <span>{{ '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.ADMINISTRATORS' | translate }}</span>
                </span>
              </label>
              <div class="col-sm-9">
                <button
                  type="button"
                  (click)="openSidebar()"
                  class="btn btn-secondary">{{'$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.SELECT_GROUP' | translate}}</button>
                  <ng-container *ngIf="selectedGroupDescription$ | async">
                    <div class="mc-selected-group" data-unit-test="selected-group">
                      <div>{{ selectedGroupDescription$ | async }}
                      </div>
                      <div class="mc-delete-group">
                        <i class="far fa-trash-alt" aria-hidden="true" (click)="onDeleteGroup()"></i>
                      </div>
                    </div>
                    <hr class="mc-group-separator"/>
                  </ng-container>
              </div>
            </div>
          </ng-container>
          <mc-field label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.USERS">
            <mc-checkbox label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.INTERNAL_SENDERS"
              formControlName="internalSender"
              data-unit-test="internal-sender">
            </mc-checkbox>
            <mc-checkbox label="$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.JOURNAL_SETTINGS.LABELS.INTERNAL_RECIPIENTS"
              formControlName="internalRecipients"
              data-unit-test="internal-recipients">
            </mc-checkbox>
          </mc-field>
        </mc-sub-section>
      </ng-container>
    </form>
  </div>
