"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgPolicyClpAppConnectorStepComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const operators_1 = require("rxjs/operators");
const models_1 = require("../../../../models");
class SwgPolicyClpAppConnectorStepComponent {
    constructor(fb, stateService) {
        this.fb = fb;
        this.stateService = stateService;
        this.defaultPolicy = false;
        this.updateClpSettings = new core_1.EventEmitter();
    }
    ngOnInit() {
        this.connectorSelectorPage = this.fb.group({
            connectorId: [
                {
                    value: this.clpSettings.connectorId,
                    disabled: this.isDisabled()
                },
                forms_1.Validators.required
            ]
        });
        this.connectorSelectorPageSubscription = this.connectorSelectorPage.valueChanges
            .pipe(operators_1.debounceTime(200))
            .subscribe(() => {
            this.updateLogSettings();
        });
    }
    getApplicableConnectors() {
        return this.allConnectors.filter(connector => connector.provider === this.clpSettings.provider);
    }
    getSelectedConnector(connectorId) {
        return this.getApplicableConnectors().find(x => x.id === connectorId);
    }
    createConnector() {
        this.stateService.$state.go('services-create-connector-page');
    }
    updateLogSettings() {
        const connecterId = this.connectorSelectorPage.controls['connectorId'].value;
        const selectedConnector = this.getSelectedConnector(connecterId);
        this.updateClpSettings.emit(Object.assign(Object.assign({}, this.clpSettings), { connectorId: connecterId, connectorName: selectedConnector && selectedConnector.name }));
        return false;
    }
    getLabel() {
        return this.isDisabled()
            ? '$I18N_SWG_EDIT_POLICY_CLP_APP_CONNECTOR_TITLE'
            : '$I18N_SWG_CREATE_POLICY_CLP_APP_CONNECTOR_TITLE';
    }
    isDisabled() {
        return this.defaultPolicy || this.wizardFlowType === models_1.SwgPolicyWizardFlowType.EDIT;
    }
    isValid() {
        return true;
    }
}
exports.SwgPolicyClpAppConnectorStepComponent = SwgPolicyClpAppConnectorStepComponent;
