"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducer = exports.selectedRowFullDataInitialState = void 0;
const definition_actions_1 = require("../actions/definition.actions");
exports.selectedRowFullDataInitialState = {
    selectedRowFullData: undefined,
    definitionStatusChanged: false,
    isDuplicateData: false
};
function reducer(state = exports.selectedRowFullDataInitialState, action) {
    switch (action.type) {
        case definition_actions_1.GET_DEFINITION_DETAILS_SUCCESS:
            return Object.assign(Object.assign({}, state), { selectedRowFullData: action.payload });
        case definition_actions_1.GET_DUPLICATE_DEFINITION_DETAILS_SUCCESS:
            return Object.assign(Object.assign({}, state), { isDuplicateData: true, selectedRowFullData: Object.assign(Object.assign({}, action.payload), { id: '', description: `Copy of ${action.payload.description}` }) });
        case definition_actions_1.CLEAR_DEFINITION_DETAILS:
            return Object.assign(Object.assign({}, state), { selectedRowFullData: undefined });
        case definition_actions_1.CLEAR_DUPLICATE_DATA:
            return Object.assign(Object.assign({}, state), { isDuplicateData: false });
        case definition_actions_1.DEFINITION_STATUS_CHANGE:
            return Object.assign(Object.assign({}, state), { definitionStatusChanged: action.payload });
        default:
            return Object.assign({}, state);
    }
}
exports.reducer = reducer;
