"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getApiResponsePending = exports.getPolicy = exports.canContinue = exports.getIsLoading = exports.getMode = exports.reducer = exports.initialState = void 0;
const actions = require("../../actions/policy-wizard/wizard.actions");
const policy_wizard_models_1 = require("../../models/policy-wizard.models");
exports.initialState = {
    mode: policy_wizard_models_1.WizardMode.CREATE,
    isLoading: false,
    canContinue: false,
    policy: {
        name: '',
        description: '',
        groups: [],
        policyDetails: null
    },
    apiResponsePending: false,
    currentStep: 1,
    wizardStepValid: {}
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.UPDATE_CURRENT_STEP:
            return Object.assign(Object.assign({}, state), { currentStep: action.payload, canContinue: state.wizardStepValid[action.payload] });
        case actions.UPDATE_IS_VALID:
            const wizardStepValid = Object.assign({}, state.wizardStepValid);
            wizardStepValid[action.step] = action.valid;
            return Object.assign(Object.assign({}, state), { wizardStepValid: Object.assign({}, wizardStepValid), canContinue: action.step === state.currentStep ? action.valid : state.canContinue });
        case actions.CREATE:
            return Object.assign(Object.assign({}, state), { apiResponsePending: true });
        case actions.CREATE_SUCCESS:
            return Object.assign(Object.assign({}, state), { apiResponsePending: false });
        case actions.CREATE_FAIL:
            return Object.assign(Object.assign({}, state), { apiResponsePending: false });
        case actions.UPDATE:
            return Object.assign(Object.assign({}, state), { apiResponsePending: true });
        case actions.UPDATE_SUCCESS:
            return Object.assign(Object.assign({}, state), { apiResponsePending: false });
        case actions.UPDATE_FAIL:
            return Object.assign(Object.assign({}, state), { apiResponsePending: false });
        case actions.LOAD_POLICY:
            return Object.assign(Object.assign({}, state), { mode: policy_wizard_models_1.WizardMode.EDIT, isLoading: true });
        case actions.LOAD_POLICY_SUCCESS:
            return Object.assign(Object.assign({}, state), { policy: action.payload, isLoading: false });
        case actions.LOAD_POLICY_FAIL:
            return Object.assign(Object.assign({}, state), { isLoading: false });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getMode = (state) => state.mode;
exports.getMode = getMode;
const getIsLoading = (state) => state.isLoading;
exports.getIsLoading = getIsLoading;
const canContinue = (state) => state.canContinue;
exports.canContinue = canContinue;
const getPolicy = (state) => state.policy;
exports.getPolicy = getPolicy;
const getApiResponsePending = (state) => state.apiResponsePending;
exports.getApiResponsePending = getApiResponsePending;
