"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getExpirationTime = exports.reducer = exports.initialState = void 0;
const actions = require("../../actions/policy-wizard/session-timeout-step.actions");
const policy_1 = require("../../models/policy");
exports.initialState = {
    expirationTime: {
        expirationTimeValue: 1,
        expirationTimeType: policy_1.ExpirationTimeType.DAYS
    }
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.SAVE_SESSION_TIMEOUT_ACTION:
            return Object.assign(Object.assign({}, state), { expirationTime: {
                    expirationTimeValue: action.payload.timeoutValue,
                    expirationTimeType: action.payload.timeoutType
                } });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getExpirationTime = (state) => state.expirationTime;
exports.getExpirationTime = getExpirationTime;
