<div class="title"><mc-spinner></mc-spinner>{{ 'I18N_WAITING_EXPERIENCE_MODAL.TITLE' | translate }}</div>
<div class="subtitle">{{ 'I18N_WAITING_EXPERIENCE_MODAL.SUBTITLE' | translate }}</div>
<ul [ngClass]="!searchWithoutHash.searchWithoutHashPermission ? 'search-points' : 'mc-search-points'">
  <li >{{( !searchWithoutHash.searchWithoutHashPermission ? 'I18N_WAITING_EXPERIENCE_MODAL.SEARCH_POINTS.ACCEPT_EMAIL_MESSAGES' :
    'I18N_WAITING_EXPERIENCE_MODAL.SEARCH_POINTS.ACCEPT_EMAIL') | translate }}</li>
  <li>{{ 'I18N_WAITING_EXPERIENCE_MODAL.SEARCH_POINTS.ARCHIVE_SEARCH' | translate }}</li>
  <li *ngIf="!searchWithoutHash.searchWithoutHashPermission">{{ 'I18N_WAITING_EXPERIENCE_MODAL.SEARCH_POINTS.DEFERRED' | translate }}</li>
  <li >{{ 'I18N_WAITING_EXPERIENCE_MODAL.SEARCH_POINTS.ON_HOLD' | translate }}</li>
  <li *ngIf="!searchWithoutHash.searchWithoutHashPermission">{{ 'I18N_WAITING_EXPERIENCE_MODAL.SEARCH_POINTS.PENDING_DELIVERY' | translate }}</li>
</ul>
