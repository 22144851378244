<mc-layout-detail-tabs
  keyTitle="$I18N_ENDPOINT_SETTINGS.PAGE_HEADER"
  keyDescription="$I18N_ENDPOINT_SETTINGS.PAGE_HEADER_SUB_TEXT"
  extendClass="mc-layout-detail-tabs">

  <mc-help-container>
    <mc-help-link url="https://community.mimecast.com/docs/DOC-3185"></mc-help-link>
  </mc-help-container>
  <mc-tab-group>
    <mc-tab name="$I18N_ENDPOINT_SETTINGS.TABS.INSTALL_TAB">
      <mc-swg-endpoints-install
        [authKey]="(authKey$ | async)"
        [isLoading]="isLoading$ | async"
        [isDeleting]="isDeleting$ | async"
        [isEditable]="isEditable$ | async"
        [isDownloadBinaryDisabled]="isDownloadBinaryDisabled$ | async"
        [userRole]="userRole"
        [hasPurgePermission]="hasPurgePermission$ | async"
        (getAuthKey)="getAuthKey($event)"
        (downloadAuthKey)="downloadAuthKey()"
        (downloadBinary)="downloadBinary($event)"
        (openDeleteKeyModal)="openDeleteKeyModal()"
        (copyAgentAuthKey)="copyAgentAuthKey()"
      ></mc-swg-endpoints-install>
    </mc-tab>
    <mc-tab name="$I18N_ENDPOINT_SETTINGS.TABS.SETTINGS_TAB">
      <mc-swg-endpoints-settings
        [settings]="settings$ | async"
        [updateSettingsError]="updateSettingsError$ | async"
        [isEndpointSettingsUpdating]="isEndpointSettingsUpdating$ | async"
        [isEditable]="isEditable$ | async"
        [hasDeviceAutoEnrollmentSwitchEnabled]="hasAutoEnrolmentSwitchEnabled"
        [hasDirectIpProtectionSwitchEnabled] = "hasDirectIpProtectionSwitchEnabled"
        [hasAllowInternalIpsSwitchEnabled]="hasAllowInternalIpsSwitchEnabled"
        [hasDirectIpProtectionModeSwitchEnabled] = "hasDirectIpProtectionModeSwitchEnabled"
        [hasTUIDAttributeSwitchEnabled]="hasTUIDAttributeSwitchEnabled"
        [hasSilentUpdateSwitchEnabled]="hasSilentUpdateSwitchEnabled"
        (settingsChanged)="settingsChanged($event)"
        (openDirectIPConfirmModal) = "openDirectIPConfirmModal($event)"
        (openAllowLocalIpsConfirmModal)="openAllowLocalIpsConfirmModal($event)"
        (openTUIDAttributeConfirmModal)="openTUIDAttributeConfirmModal($event)"
      ></mc-swg-endpoints-settings>
    </mc-tab>
    <mc-tab name="$I18N_ENDPOINT_SETTINGS.TABS.PASSWORDS_TAB">
      <mc-swg-endpoints-passwords
        [settings]="settings$ | async"
        [updateSettingsError]="updateSettingsError$ | async"
        [isEndpointSettingsUpdating]="isEndpointSettingsUpdating$ | async"
        [isEditable]="isEditable$ | async"
        [isPasswordLoading]="isPasswordLoading$ | async"
        [tamperProtectedPassword]="tamperProtectedPassword$ | async"
        [uninstallPassword]="uninstallPassword$ | async"
        [hasUninstallPasswordEnabled]="hasUninstallPasswordEnabled"
        (settingsChanged)="settingsChanged($event)"
        (getTamperProtectedPassword)="getTamperProtectedPassword($event)"
        (getUninstallPassword)="getUninstallPassword($event)"
        (copyTamperProtectedPassword)="copyTamperProtectedPassword()"
        (copyUninstallPassword)="copyUninstallPassword()"
        (viewTamperProtectedPassword)="viewTamperProtectedPassword()"
        (viewUninstallPassword)="viewUninstallPassword()"
        (openUninstallPwdConfirmModal)="openEnableUninstallPasswordConfirmModal($event)"
      ></mc-swg-endpoints-passwords>
    </mc-tab>
  </mc-tab-group>
</mc-layout-detail-tabs>
