"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CyberGraphPoliciesListEffects = void 0;
const effects_1 = require("@ngrx/effects");
const operators_1 = require("rxjs/operators");
const listActions = require("../actions/cybergraph-policies-list.actions");
const editActions = require("../actions/cybergraph-policies-edit.actions");
const confirmation_modal_component_1 = require("../components/confirmation-modal/confirmation-modal.component");
const rxjs_1 = require("rxjs");
const table_store_1 = require("@mimecast-ui/table-store");
const TABLE_ID = 'cyberGraphPoliciesListTable';
class CyberGraphPoliciesListEffects {
    constructor(modalService, actions$, tableStore, cyberGraphPoliciesListImplService, cyberGraphPolicyDetailsService, notificationService, translateService) {
        this.modalService = modalService;
        this.actions$ = actions$;
        this.tableStore = tableStore;
        this.cyberGraphPoliciesListImplService = cyberGraphPoliciesListImplService;
        this.cyberGraphPolicyDetailsService = cyberGraphPolicyDetailsService;
        this.notificationService = notificationService;
        this.translateService = translateService;
        this.deletePolicyAttempt$ = this.actions$.pipe(effects_1.ofType(listActions.CYBERGRAPH_POLICY_DELETE_ATTEMPT), operators_1.switchMap((action) => {
            return this.modalService
                .open(confirmation_modal_component_1.ConfirmationModalComponent, {
                data: {
                    type: 'DELETE',
                    policy: action.payload.id
                }
            })
                .afterClose()
                .pipe(operators_1.map((result) => {
                return result
                    ? new listActions.PolicyDeleteAction(action.payload)
                    : new listActions.PolicyDeleteCancelAction();
            }));
        }));
        this.deletePolicy$ = this.actions$.pipe(effects_1.ofType(listActions.CYBERGRAPH_POLICY_DELETE), operators_1.switchMap((action) => {
            return this.cyberGraphPoliciesListImplService.deletePolicy({ id: action.payload.id }).pipe(operators_1.map((payload) => {
                return payload
                    ? new listActions.PolicyDeleteSucessAction()
                    : new listActions.PolicyDeleteFailAction();
            }), operators_1.catchError(() => rxjs_1.of(new listActions.PolicyDeleteFailAction())));
        }));
        this.deletePolicySuccess$ = this.actions$.pipe(effects_1.ofType(listActions.CYBERGRAPH_POLICY_DELETE_SUCCESS), operators_1.withLatestFrom(this.tableStore.select(table_store_1.tableReducer.getCurrentFilters(TABLE_ID)), this.tableStore.select(table_store_1.tableReducer.getCurrentPagination(TABLE_ID))), operators_1.mergeMap(([, currentFilters, currentPagination]) => {
            return [
                this.notificationService.newNotification({
                    type: 'success',
                    config: {
                        msg: this.translateService.instant('$I18N_SERVICES_CYBERGRAPH.DELETE_POLICY')
                    }
                }),
                new table_store_1.LoadAction({
                    tableId: TABLE_ID,
                    pagination: { pageToken: '', pageSize: currentPagination.pageSize, pageNumber: 0 },
                    query: currentFilters
                })
            ];
        }));
        this.editPolicySuccess$ = this.actions$.pipe(effects_1.ofType(editActions.CYBERGRAPH_EDIT_POLICY_SUCCESS), operators_1.withLatestFrom(this.tableStore.select(table_store_1.tableReducer.getCurrentFilters(TABLE_ID)), this.tableStore.select(table_store_1.tableReducer.getCurrentPagination(TABLE_ID))), operators_1.mergeMap(([, currentFilters, currentPagination]) => {
            return [
                new table_store_1.LoadAction({
                    tableId: TABLE_ID,
                    pagination: { pageToken: '', pageSize: currentPagination.pageSize, pageNumber: 0 },
                    query: currentFilters
                })
            ];
        }));
        this.editPolicyFromListView$ = this.actions$.pipe(effects_1.ofType(listActions.CYBERGRAPH_EDIT_POLICY_FROM_LIST_VIEW), operators_1.switchMap((action) => {
            return this.cyberGraphPolicyDetailsService.getPolicyDetails(action.payload).pipe(operators_1.mergeMap((response) => [
                new editActions.OpenCreatePolicyPageInEditMode(response.first),
                new listActions.GetPolicyDetailsSuccessAction(response.first)
            ]), operators_1.catchError(() => {
                return [
                    this.notificationService.newNotification({
                        type: 'error',
                        config: {
                            msg: '$I18N_SERVICES_CYBERGRAPH.EDIT_FAIL_MSG'
                        }
                    })
                ];
            }));
        }));
        this.deletePolicyFail$ = this.actions$.pipe(effects_1.ofType(listActions.CYBERGRAPH_POLICY_DELETE_FAIL), operators_1.switchMap(() => {
            return [
                this.notificationService.newNotification({
                    type: 'error',
                    config: {
                        msg: this.translateService.instant('$I18N_SERVICES_CYBERGRAPH.DELETE_POLICY_ERROR_MSG')
                    }
                })
            ];
        }));
    }
}
__decorate([
    effects_1.Effect()
], CyberGraphPoliciesListEffects.prototype, "deletePolicyAttempt$", void 0);
__decorate([
    effects_1.Effect()
], CyberGraphPoliciesListEffects.prototype, "deletePolicy$", void 0);
__decorate([
    effects_1.Effect()
], CyberGraphPoliciesListEffects.prototype, "deletePolicySuccess$", void 0);
__decorate([
    effects_1.Effect()
], CyberGraphPoliciesListEffects.prototype, "editPolicySuccess$", void 0);
__decorate([
    effects_1.Effect()
], CyberGraphPoliciesListEffects.prototype, "editPolicyFromListView$", void 0);
__decorate([
    effects_1.Effect()
], CyberGraphPoliciesListEffects.prototype, "deletePolicyFail$", void 0);
exports.CyberGraphPoliciesListEffects = CyberGraphPoliciesListEffects;
