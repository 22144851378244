"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DecodeUrlWidgetComponent = void 0;
const core_1 = require("@angular/core");
const domain_validator_1 = require("app-new/components/validators/domain/domain.validator");
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const decodeActions = require("../../actions/decode-url.actions");
class DecodeUrlWidgetComponent {
    constructor(fb, decodedUrlActionSubj) {
        this.fb = fb;
        this.decodedUrlActionSubj = decodedUrlActionSubj;
        this.url = new core_1.EventEmitter();
        this.allowUrl = new core_1.EventEmitter();
        this.blockUrl = new core_1.EventEmitter();
        this.hasControls = false;
        this.decodedUrlState = new rxjs_1.Subscription();
        this.form = this.fb.group({
            rawUrlField: ['', [domain_validator_1.managedUrlValidator]],
            decodedUrlField: ['']
        });
        this.setupSubscriptions();
    }
    setupSubscriptions() {
        this.decodedUrlState = this.decodedUrlActionSubj
            .pipe(effects_1.ofType(decodeActions.DECODE_URL_SUCCESS))
            .subscribe(action => {
            this.form.controls['decodedUrlField'].setValue(action['payload']['url']);
        });
    }
    ngOnDestroy() {
        this.decodedUrlState.unsubscribe();
    }
    get rawUrlField() {
        return this.form.value.rawUrlField;
    }
    ngOnInit() {
        this.initialFormValue = this.form.value;
    }
    clear() {
        this.form.reset(this.initialFormValue);
        this.form.markAsPristine();
        this.form.markAsUntouched();
        this.form.controls['decodedUrlField'].disable();
    }
    decode() {
        this.url.emit(this.form.value.rawUrlField);
        this.form.controls['decodedUrlField'].enable();
    }
    allow() {
        this.allowUrl.emit(this.urlDecoded);
    }
    block() {
        this.blockUrl.emit(this.urlDecoded);
    }
    preventNewRow(e) {
        e.preventDefault();
    }
}
exports.DecodeUrlWidgetComponent = DecodeUrlWidgetComponent;
