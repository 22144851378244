"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducer = exports.selectedRowFullDataInitialState = void 0;
const cybergraph_policies_list_actions_1 = require("../actions/cybergraph-policies-list.actions");
exports.selectedRowFullDataInitialState = {
    selectedRowFullData: undefined
};
function reducer(state = exports.selectedRowFullDataInitialState, action) {
    switch (action.type) {
        case cybergraph_policies_list_actions_1.CYBERGRAPH_GET_POLICY_DETAILS_SUCCESS:
            return Object.assign(Object.assign({}, state), { selectedRowFullData: action.payload });
        case cybergraph_policies_list_actions_1.CYBERGRAPH_CLEAR_POLICY_DETAILS:
            return Object.assign(Object.assign({}, state), { selectedRowFullData: undefined });
        case cybergraph_policies_list_actions_1.CYBERGRAPH_UPDATE_POLICY_DETAILS_ENABLE_STATUS:
            if (action.payload.id === state.selectedRowFullData.policy.id) {
                return Object.assign(Object.assign({}, state), { selectedRowFullData: Object.assign(Object.assign({}, state.selectedRowFullData), { policy: Object.assign(Object.assign({}, state.selectedRowFullData.policy), { enabled: action.payload.status }) }) });
            }
            return Object.assign({}, state);
        default:
            return Object.assign({}, state);
    }
}
exports.reducer = reducer;
