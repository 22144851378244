<div class="threat-event-analysis">
  <div class="threat-event-analysis-section">
    <mc-swg-threat-event-analysis-details [threatEvent]="threatEvent"></mc-swg-threat-event-analysis-details>
  </div>
  <div class="threat-event-analysis-section">
    <mc-swg-threat-event-antivirus [threatEvent]="threatEvent"></mc-swg-threat-event-antivirus>
  </div>
  <div class="threat-event-analysis-section">
    <mc-swg-threat-event-static-analysis [threatEvent]="threatEvent"></mc-swg-threat-event-static-analysis>
  </div>
  <div class="threat-event-analysis-section">
    <mc-swg-threat-event-dynamic-analysis [threatEvent]="threatEvent"></mc-swg-threat-event-dynamic-analysis>
  </div>
  <div class="threat-event-analysis-section">
    <mc-swg-threat-event-malicious-urls [threatEvent]="threatEvent"></mc-swg-threat-event-malicious-urls>
  </div>
</div>
