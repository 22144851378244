"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.RuleFactory = void 0;
const rule_builder_1 = require("../model/rule/rule.builder");
const ɵ0 = (rule) => new rule_builder_1.RuleBuilder()
    .withId(rule['id'])
    .withName(rule['name'])
    .withCreatedBy(rule['createdBy'])
    .withUpdatedBy(rule['updatedBy'])
    .withCreated(rule['created'])
    .withUpdated(rule['updated'])
    .withDescription(rule['description'])
    .withSamplingPercentage(rule['samplingPercentage'])
    .withEnabled(rule['enabled'])
    .withReviewQueue(rule['reviewQueue'])
    .withScheduleType(rule['scheduleType'])
    .withMessageRoute(rule['messageRoute'])
    .withLexicon(rule['lexicon'])
    .withSearchGroup(rule['searchGroup'])
    .withLastExecuted(rule['lastExecuted'])
    .withStatus(rule['status'])
    .withState(rule['state'])
    .build();
exports.ɵ0 = ɵ0;
exports.RuleFactory = {
    BUILD_RULE: ɵ0
};
