"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DirectorySyncAzureHecateSettingsStepComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const validators_1 = require("../../../../../../api-applications/validators");
const model_1 = require("../../../../../../services/connectors/model");
const reducers = require("../../../../../reducers");
const actions = require("../../../../../actions");
class DirectorySyncAzureHecateSettingsStepComponent {
    constructor(fb, windowService, store, translateService, featureFlagService, coreService) {
        this.fb = fb;
        this.windowService = windowService;
        this.store = store;
        this.translateService = translateService;
        this.featureFlagService = featureFlagService;
        this.coreService = coreService;
        this.delay = 200;
        this.destroy$ = new rxjs_1.Subject();
        this.permissionGranted$ = new rxjs_1.BehaviorSubject(false);
        this.permissionNotGranted$ = new rxjs_1.BehaviorSubject(false);
        this.createConnectorFlowInitialized = false;
        this.onIntegrationDetailsUpdated = new core_1.EventEmitter();
        this.updateAuthToken = new core_1.EventEmitter();
        this.form = this.fb.group({
            connectorSelection: [''],
            connector: [''],
            connectorName: [
                '',
                [forms_1.Validators.minLength(3), forms_1.Validators.maxLength(60), forms_1.Validators.required, validators_1.validateNotEmpty]
            ],
            connectorDescription: ['', forms_1.Validators.maxLength(200)]
        });
    }
    ngOnInit() {
        const ready$ = rxjs_1.from(this.featureFlagService.isReady());
        this.supportLink$ = ready$.pipe(operators_1.switchMap(() => this.coreService.getAccountSwitchSession().pipe(operators_1.take(1))), operators_1.map(switchedSession => {
            const hasConfigEnabled = this.featureFlagService.getBooleanFlag('rollout-zendesk-contact-support');
            const encodedAccountCode = switchedSession && switchedSession.accountcode
                ? `?ac=${encodeURIComponent(btoa(switchedSession.accountcode))}`
                : '';
            return hasConfigEnabled
                ? `/apps#/sso/support${encodedAccountCode}`
                : '/apps#sso/mcentral?goto=/s/contactsupport';
        }));
        this.formSubscription = this.form.valueChanges.pipe(operators_1.debounceTime(this.delay)).subscribe(() => {
            this.update();
        });
        this.authCallbackHandler = this.handleAuthCallback.bind(this);
        this.windowService.addEventListener('message', this.authCallbackHandler);
        this.store
            .select(reducers.consentConfig)
            .pipe(operators_1.skip(1), operators_1.takeUntil(this.destroy$))
            .subscribe(config => {
            if (config) {
                this.handleConsentConfig(config);
            }
        });
        this.getProviders();
    }
    ngOnChanges(changes) {
        this.hasConnectors = this.connectors.length > 0;
        if (!!changes.connectors) {
            this.patchForm();
        }
        else if (!this.createConnectorFlowInitialized) {
            this.patchForm();
            this.createConnectorFlowInitialized = true;
        }
        if (!!changes &&
            !!changes.integration &&
            !!changes.integration.previousValue &&
            changes.integration.currentValue.type !== changes.integration.previousValue.type) {
            this.resetConsentState();
            this.getProviders();
        }
    }
    getProviders() {
        this.store.select(reducers.dirSyncProviders).subscribe(providers => {
            if (!!providers && providers.length) {
                providers.forEach((provider) => {
                    if (this.integration.type === provider.type) {
                        this.dirSyncProviderId = provider.id;
                    }
                });
            }
        });
    }
    patchForm() {
        this.form.patchValue({
            connectorSelection: this.hasConnectors ? 'existing' : 'new',
            connector: this.integration.connector || ''
        });
    }
    update() {
        this.onIntegrationDetailsUpdated.emit(this.isExistingConnector()
            ? { connector: this.form.value.connector, connectorName: '' }
            : { connectorName: this.form.value.connectorName, connector: '' });
    }
    integrationType() {
        return ('Microsoft Azure - ' + (this.integration.type === 'azure_standard' ? 'Standard' : 'GCC HIGH'));
    }
    isValid() {
        if (this.isExistingConnector() && this.form.value.connector !== '') {
            return true;
        }
        return (this.isNewConnector() && this.isConnectorNameValid() && this.permissionGranted$.getValue());
    }
    isExistingConnector() {
        return this.form.value.connectorSelection === 'existing';
    }
    isNewConnector() {
        return this.form.value.connectorSelection === 'new';
    }
    isConnectorNameValid() {
        return this.form.value.connectorName !== '' && this.form.value.connectorName.length > 2;
    }
    get connectorTypeOptions() {
        return [
            {
                value: '',
                label: 'None'
            },
            ...this.connectors.map(connector => ({ value: connector.id, label: connector.name }))
        ];
    }
    loadConsentConfig() {
        this.dispatchConsentConfig();
        this.resetConsentState();
        // Need to open OAuth flow window immediately in response to
        // user action. Else subsequent programmatic opening would be
        // vulnerable to browser popup blockers.
        this.openOAuthWindow();
    }
    dispatchConsentConfig() {
        const crypto = window.crypto;
        const array = new Uint32Array(1);
        this.providedState = `${crypto.getRandomValues(array)}`;
        const request = {
            id: this.dirSyncProviderId,
            domain: window.location.origin,
            path: '/administration/app',
            stateParameters: {
                provider: 'ms',
                clientState: this.providedState
            }
        };
        this.store.dispatch(new actions.GetConsentConfigAction(request));
    }
    resetConsentState() {
        this.permissionGranted$.next(false);
        this.permissionNotGranted$.next(false);
        this.errorCode = undefined;
    }
    openOAuthWindow() {
        const x = screen.width / 2 - 500 / 2;
        const y = screen.height / 2 - 800 / 2;
        const name = this.translateService.instant('$I18N_DIRECTORY_SYNC_WIZARD.STEPS.SETTINGS.AZURE.STD.SIGN_IN');
        const specs = `width=500,height=800,left=${x},top=${y}`;
        this.authWindow = this.windowService.open('', name, specs);
    }
    handleConsentConfig(config) {
        if (config.redirectUrl) {
            this.authWindow.location.href = config.redirectUrl;
        }
        else {
            this.permissionNotGranted$.next(true);
            this.errorCode = model_1.ConsentErrorCode.CONSENT_CONFIG;
            // Close on api error response.
            this.authWindow.close();
        }
    }
    handleAuthCallback(event) {
        if (!this.isValidMessage(event)) {
            return;
        }
        const { authToken, errorCode } = event.data;
        this.permissionGranted$.next(!!authToken);
        this.permissionNotGranted$.next(!!errorCode);
        this.errorCode = errorCode;
        if (authToken) {
            this.updateAuthToken.emit(authToken);
        }
    }
    isValidMessage(event) {
        const { state } = event.data;
        return event.origin === window.location.origin && state && state === this.providedState;
    }
    isTechnicalSupportError() {
        return (this.errorCode &&
            (this.errorCode === model_1.ConsentErrorCode.CONSENT_CONFIG ||
                this.errorCode === model_1.ConsentErrorCode.UNKNOWN));
    }
    ngOnDestroy() {
        this.formSubscription.unsubscribe();
        this.windowService.removeEventListener('message', this.authCallbackHandler);
        this.destroy$.next(true);
        this.destroy$.complete();
    }
}
exports.DirectorySyncAzureHecateSettingsStepComponent = DirectorySyncAzureHecateSettingsStepComponent;
