
    <div class="accordion">
      <div class="accordion-group">
        <div class="accordion-heading" [class.accordion-opened]="active" (click)="onClickHeader()">
          <p
            class="accordion-toggle no-selection"
            data-toggle="collapse"
            data-parent="#accordion"
            href="#collapseTwo"
          >
            {{ title }}
            <span
              *ngIf="error"
              class="accordion-error text-danger fas fa-exclamation-circle pull-right"
            ></span>
          </p>
          <p class="collapse mc-accordion-subtitle" [class.active]="active">
            {{ subtitle }}
          </p>
        </div>
        <div id="collapseOne" class="accordion-body collapse" [class.active]="active">
          <p class="accordion-inner">
            <ng-content></ng-content>
          </p>
        </div>
      </div>
    </div>
  