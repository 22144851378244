"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getProvidersLoading = exports.getProviders = exports.getConnectorsLoading = exports.getConnectors = exports.reducer = exports.initialState = void 0;
const connectors = require("../../actions/connectors.actions");
exports.initialState = {
    providers: [],
    providersLoading: false,
    connectors: [],
    connectorsLoading: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case connectors.SWG_POLICY_LOAD_CONNECTORS_SECTIONS_SUCCESS:
            return Object.assign(Object.assign({}, state), { connectorsLoading: false, connectors: action.payload || [] });
        case connectors.SWG_POLICY_LOAD_CONNECTORS_SECTIONS_FAILURE:
            return Object.assign(Object.assign({}, state), { connectorsLoading: false });
        case connectors.SWG_POLICY_LOAD_CONNECTORS_SECTIONS:
            return Object.assign(Object.assign({}, exports.initialState), { connectorsLoading: true });
        case connectors.SWG_POLICY_LOAD_PROVIDERS_SECTIONS_SUCCESS:
            return Object.assign(Object.assign({}, state), { providersLoading: false, providers: action.payload });
        case connectors.SWG_POLICY_LOAD_PROVIDERS_SECTIONS_FAILURE:
            return Object.assign(Object.assign({}, state), { providersLoading: false });
        case connectors.SWG_POLICY_LOAD_PROVIDERS_SECTIONS:
            return Object.assign(Object.assign({}, exports.initialState), { providersLoading: true });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getConnectors = (state) => state.connectors;
exports.getConnectors = getConnectors;
const getConnectorsLoading = (state) => state.connectorsLoading;
exports.getConnectorsLoading = getConnectorsLoading;
const getProviders = (state) => state.providers;
exports.getProviders = getProviders;
const getProvidersLoading = (state) => state.providersLoading;
exports.getProvidersLoading = getProvidersLoading;
