<mc-loader-panel-container
  keyTitle="{{ title }}"
  keyDescription="{{ description }}"
  [withPadding]="true">
  <mc-loader-panel-container-body-container *ngIf="hasSources">
    <ul class="mc-activity-wait-overlay-list">
      <ng-container *ngFor="let source of sources">
        <li class="mc-activity-wait-overlay-list-item" *mcCapabilities="source.cap || 'Permission.DASHBOARD_READ'">{{ source.key | translate }}</li>
      </ng-container>
    </ul>
  </mc-loader-panel-container-body-container>
</mc-loader-panel-container>
