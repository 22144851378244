"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFolders = exports.foldersCartReducer = exports.INITIAL_FOLDERS_CART_STATE = void 0;
const foldersCartActions = require("../actions/folders-cart.actions");
exports.INITIAL_FOLDERS_CART_STATE = { selectedFolders: [] };
function foldersCartReducer(state = exports.INITIAL_FOLDERS_CART_STATE, action) {
    switch (action.type) {
        case foldersCartActions.PUSH_FOLDER:
            return Object.assign(Object.assign({}, state), { selectedFolders: [...state.selectedFolders, action.folder] });
        case foldersCartActions.REMOVE_FOLDER:
            return Object.assign(Object.assign({}, state), { selectedFolders: state.selectedFolders.filter(folder => folder.getId() !== action.folder.getId()) });
        case foldersCartActions.CLEAN_STORE:
            return exports.INITIAL_FOLDERS_CART_STATE;
        default:
            return state;
    }
}
exports.foldersCartReducer = foldersCartReducer;
const getFolders = (state) => state.selectedFolders;
exports.getFolders = getFolders;
