"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgPolicyClpAppActionsStepComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const operators_1 = require("rxjs/operators");
const clp_1 = require("../../../../models/clp");
class SwgPolicyClpAppActionsStepComponent {
    constructor(fb, swgService) {
        this.fb = fb;
        this.swgService = swgService;
        this.updateClpSettings = new core_1.EventEmitter();
        this.ClpPolicyActions = clp_1.ClpPolicyActions;
    }
    ngOnInit() {
        this.clpActionsForm = this.fb.group({
            action: [this.clpSettings.action, forms_1.Validators.required]
        });
        this.clpActionsFormSubscription = this.clpActionsForm.valueChanges
            .pipe(operators_1.debounceTime(200))
            .subscribe(() => {
            this.updateActionSettings();
        });
    }
    isValid() {
        return true;
    }
    hasBlockAndQuarantineAction() {
        return this.swgService.hasCLPBlockAndQuarantineActionEnabled();
    }
    updateActionSettings() {
        const action = this.clpActionsForm.controls['action'].value;
        this.updateClpSettings.emit(Object.assign(Object.assign({}, this.clpSettings), { action }));
        return false;
    }
    ngOnDestroy() {
        this.clpActionsFormSubscription.unsubscribe();
    }
}
exports.SwgPolicyClpAppActionsStepComponent = SwgPolicyClpAppActionsStepComponent;
