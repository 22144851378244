<mc-layout-aside-extra-container
  keyTitle="$18N_BI_POLICY_LIST.SIDEBAR.POLICY_DETAILS"
  showClose="true"
  [isLoading]="false"
  (closeAside)="close.emit()"
>
  <mc-extra-container>
    <div>
      <button class="btn btn-primary panel-half-margin-right" (click)="editPolicy.emit()">
        {{ '$I18N_COMMON_BTN_EDIT' | translate }}
      </button>
      <button
        class="btn btn-secondary panel-half-margin-right"
        (click)="deletePolicy.emit(policy)"
      >
        {{ '$I18N_COMMON_BTN_DELETE' | translate }}
      </button>
    </div>
  </mc-extra-container>
  <mc-body-container>
    <div>
      <div class="mc-detailed-list mc-swg-policy-summary-block">
        <div class="row mc-detailed-list-row no-padding-top">
          <div class="col-xs-3 mc-detailed-list-label">
            {{ '$18N_BI_POLICY_LIST.SIDEBAR.NAME' | translate }}
          </div>
          <div class="mc-detailed-list-column">{{ policy.policyDesc.description }}</div>
        </div>

        <div class="row mc-detailed-list-row">
          <div class="col-xs-3 mc-detailed-list-label">
            {{ '$18N_BI_POLICY_LIST.SIDEBAR.DESCRIPTION' | translate }}
          </div>
          <div class="mc-detailed-list-column">
            {{ policy.policyDesc.description}}
          </div>
        </div>

        <div class="row mc-detailed-list-row">
          <div class="col-xs-3 mc-detailed-list-label">
            {{ '$18N_BI_POLICY_LIST.SIDEBAR.ENTER_TEXT' | translate }}
          </div>
          <div class="mc-detailed-list-column">
            {{ !policy.settings.sessionReadOnly}}
          </div>
        </div>
        <div class="row mc-detailed-list-row">
          <div class="col-xs-3 mc-detailed-list-label">
            {{ '$18N_BI_POLICY_LIST.SIDEBAR.PASTE_TEXT_IN' | translate }}
          </div>
          <div class="mc-detailed-list-column">
            {{ policy.settings.clipboardTransferIn}}
          </div>
        </div>
        <div class="row mc-detailed-list-row">
          <div class="col-xs-3 mc-detailed-list-label">
            {{ '$18N_BI_POLICY_LIST.SIDEBAR.COPY_TEXT_OUT' | translate }}
          </div>
          <div class="mc-detailed-list-column">
            {{ policy.settings.clipboardTransferOut}}
          </div>
        </div>
        <div class="row mc-detailed-list-row">
          <div class="col-xs-3 mc-detailed-list-label">
            {{ '$18N_BI_POLICY_LIST.SIDEBAR.APPLIES_TO' | translate }}
          </div>
          <div *ngFor="let affectedUser of policy.affectedUsers">
            <div>
              {{ affectedUser.description }}
            </div>
          </div>
        </div>

      </div>
    </div>
  </mc-body-container>
</mc-layout-aside-extra-container>
