"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DirectorySyncPageComponent = void 0;
const operators_1 = require("rxjs/operators");
const reducers = require("../../reducers");
const actions = require("../../actions");
class DirectorySyncPageComponent {
    constructor(store, tabService, capabilitiesService) {
        this.store = store;
        this.tabService = tabService;
        this.capabilitiesService = capabilitiesService;
        this.dirSyncKbLink = 'https://community.mimecast.com/s/article/Directory-Synchronization';
    }
    ngOnInit() {
        this.store.dispatch(new actions.GetAllDirectorySyncIntegrationsAction());
        this.store.dispatch(new actions.SynchronizationReportAction());
        this.filtersSubscription = this.store.select(reducers.getFilters).subscribe(tableFilters => {
            this.filters = tableFilters;
        });
        this.paginationSubscription = this.store
            .select(reducers.getPagination)
            .subscribe(tablePagination => {
            this.pagination = tablePagination;
        });
        const tab = this.tabService.getSelectedTab();
        tab.onRefresh = () => {
            this.store.dispatch(new actions.GetAllDirectorySyncIntegrationsAction(this.pagination, this.filters));
        };
        this.capabilitiesService
            .hasCapability('Permission.EAM_DIRECTORY_SYNC_READ')
            .pipe(operators_1.first())
            .subscribe(enabled => {
            this.isAAAAccount = enabled;
        });
    }
    getUrlBasedOnAccountType() {
        return this.isAAAAccount ? '#/l/aaadirectorysync' : '#/l/directorysync';
    }
    ngOnDestroy() {
        this.filtersSubscription.unsubscribe();
        this.paginationSubscription.unsubscribe();
    }
}
exports.DirectorySyncPageComponent = DirectorySyncPageComponent;
