<div class="mc-detailed-list" *mcCapabilities="'Permission.EAM_DIRECTORY_SYNC_READ'">
  <div class="row mc-detailed-list-row">
    <div class="col-xs-4 mc-detailed-list-label">
      {{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_SUMMARY.GROUP' | translate }}
    </div>
    <div class="mc-detailed-list-column">{{ integration?.group }}</div>
  </div>
</div>

<div class="mc-detailed-list" *mcCapabilities="'Permission.EAM_DIRECTORY_SYNC_READ'">
  <div class="row mc-detailed-list-row">
    <div class="col-xs-4 mc-detailed-list-label">
      {{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_SUMMARY.ACCOUNT' | translate }}
    </div>
    <div class="mc-detailed-list-column">{{ integration?.account }}</div>
  </div>
</div>

<div class="mc-detailed-list">
  <div class="row mc-detailed-list-row">
    <div class="col-xs-4 mc-detailed-list-label">
      {{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_SUMMARY.NAME' | translate }}
    </div>
    <div class="mc-detailed-list-column">{{ integration.description }}</div>
  </div>
</div>

<div class="mc-detailed-list">
  <div class="row mc-detailed-list-row">
    <div class="col-xs-4 mc-detailed-list-label">
      {{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_SUMMARY.DESCRIPTION' | translate }}
    </div>
    <div class="mc-detailed-list-column">{{ integration?.info || '-' }}</div>
  </div>
</div>

<div class="mc-detailed-list">
  <div class="row mc-detailed-list-row">
    <div class="col-xs-4 mc-detailed-list-label">
      {{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_SUMMARY.TYPE' | translate }}
    </div>
    <div class="mc-detailed-list-column">{{ serverType | translate }}</div>
  </div>
</div>

<div class="mc-detailed-list">
  <div class="row mc-detailed-list-row">
    <div class="col-xs-4 mc-detailed-list-label">
      {{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_SUMMARY.LAST_SYNC' | translate }}
    </div>
    <div class="mc-detailed-list-column">{{ (integration?.lastSync | mcDate) || '-' }}</div>
  </div>
</div>

<div *ngIf="isLdapDominoIntegration()" class="mc-detailed-list">
  <div class="row mc-detailed-list-row">
    <div class="col-xs-4 mc-detailed-list-label">
      {{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_SUMMARY.DOMINO_FOREIGN_DIRECTORY_SYNCHRONIZATION' | translate }}
    </div>
    <div class="mc-detailed-list-column">
      {{ (integration?.ldapSettings?.foreignDirectorySync ? '$I18N_DIRECTORY_SYNC_TABLE.ENABLE' : '$I18N_DIRECTORY_SYNC_TABLE.DISABLE') | translate }}
    </div>
  </div>
</div>

<div class="mc-detailed-list">
  <div class="row mc-detailed-list-row">
    <div class="col-xs-4 mc-detailed-list-label">
      {{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_SUMMARY.STATUS' | translate }}
    </div>
    <div class="mc-detailed-list-column mc-integration-status">
      <span *ngIf="integration?.enabled" class="mc-integration-status--enable">
        <i class="fas fa-check-circle" aria-hidden="true"></i>{{ '$I18N_DIRECTORY_SYNC_TABLE.ENABLE' | translate }}
      </span>
      <span *ngIf="!integration?.enabled" class="mc-integration-status--disable">
        <i class="fas fa-times-circle" aria-hidden="true"></i>{{ '$I18N_DIRECTORY_SYNC_TABLE.DISABLE' | translate }}
      </span>
    </div>
  </div>
</div>

<div *ngIf="isAzureStandardHecateMigratedIntegration() || isAzureGCCHecateMigratedIntegration()" class="mc-detailed-list">
  <div class="row mc-detailed-list-row">
    <div class="col-xs-4 mc-detailed-list-label">
      {{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_SUMMARY.CONNECTOR_NAME' | translate }}
    </div>
    <div class="mc-detailed-list-column">{{ integration?.office365Settings?.connectorName || '-' }}</div>
  </div>
</div>

<ng-container *ngIf="isLdapIntegration()">
  <div class="mc-detailed-list">
    <div class="row mc-detailed-list-row">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_SUMMARY.HOST' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ integration?.ldapSettings?.hostname }}</div>
    </div>
  </div>

  <div class="mc-detailed-list">
    <div class="row mc-detailed-list-row">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_SUMMARY.ALTERNATE_HOST' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ integration?.ldapSettings?.alternateHostname || '-' }}</div>
    </div>
  </div>

  <div class="mc-detailed-list">
    <div class="row mc-detailed-list-row">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_SUMMARY.ENCRYPTION_CONNECTION' | translate }}
      </div>
      <div class="mc-detailed-list-column">
        {{ (hasEncryption ? '$I18N_DIRECTORY_SYNC_TABLE.ENABLE' : '$I18N_DIRECTORY_SYNC_TABLE.DISABLE') | translate }}
      </div>
    </div>
  </div>

  <div class="mc-detailed-list">
    <div class="row mc-detailed-list-row">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_SUMMARY.ENCRYPTION_MODE' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ integration?.ldapSettings?.encryptionMode | titlecase }}</div>
    </div>
  </div>

  <div class="mc-detailed-list">
    <div class="row mc-detailed-list-row">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_SUMMARY.PORT' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ integration?.ldapSettings?.port }}</div>
    </div>
  </div>

  <div class="mc-detailed-list">
    <div class="row mc-detailed-list-row">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_SUMMARY.USER_DN' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ integration?.ldapSettings?.userDn }}</div>
    </div>
  </div>

  <div class="mc-detailed-list">
    <div class="row mc-detailed-list-row">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_SUMMARY.ROOT_DN' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ integration?.ldapSettings?.rootDn }}</div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="(isAzureStandardIntegration() && !isAzureStandardHecateMigratedIntegration()) || (isAzureGCCIntegration() && !isAzureGCCHecateMigratedIntegration())">
  <div class="mc-detailed-list">
    <div class="row mc-detailed-list-row">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_SUMMARY.APP_ID' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ integration?.office365Settings?.clientId  || '-' }}</div>
    </div>
  </div>

  <div class="mc-detailed-list">
    <div class="row mc-detailed-list-row">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_SUMMARY.TENANT_DOMAIN' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ integration?.office365Settings?.tenantDomain  || '-' }}</div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="isGsuiteIntegration()">
  <div class="mc-detailed-list">
    <div class="row mc-detailed-list-row">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_SUMMARY.EMAIL' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ integration?.googleDirectorySettings?.user }}</div>
    </div>
  </div>

  <div class="mc-detailed-list">
    <div class="row mc-detailed-list-row">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_SUMMARY.SERVICE_ACCOUNT_JSON' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_SUMMARY.SERVICE_ACCOUNT_JSON_PROVIDED' | translate }}</div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="isMSEIntegration()">
  <div class="mc-detailed-list">
    <div class="row mc-detailed-list-row">
      <div class="col-xs-4 mc-detailed-list-label">
        {{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_SUMMARY.SYNC_ENGINE_SITE' | translate }}
      </div>
      <div class="mc-detailed-list-column">{{ mseSiteDescription }}</div>
    </div>
  </div>

  <ng-container *ngIf="isReplicateDiffDomain()">
    <div class="mc-detailed-list">
      <div class="row mc-detailed-list-row">
        <div class="col-xs-4 mc-detailed-list-label">
          {{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_SUMMARY.HOST' | translate }}
        </div>
        <div class="mc-detailed-list-column">{{ integration?.adPushSettings?.hostname }}</div>
      </div>
    </div>

    <div class="mc-detailed-list">
      <div class="row mc-detailed-list-row">
        <div class="col-xs-4 mc-detailed-list-label">
          {{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_SUMMARY.MSE_USER' | translate }}
        </div>
        <div class="mc-detailed-list-column">{{ integration?.adPushSettings?.userDn }}</div>
      </div>
    </div>

    <div class="mc-detailed-list">
      <div class="row mc-detailed-list-row">
        <div class="col-xs-4 mc-detailed-list-label">
          {{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_SUMMARY.ROOT_DN' | translate }}
        </div>
        <div class="mc-detailed-list-column">{{ integration?.adPushSettings?.rootDn }}</div>
      </div>
    </div>
  </ng-container>
</ng-container>

<div class="mc-detailed-list">
  <div class="row mc-detailed-list-row">
    <div class="col-xs-4 mc-detailed-list-label">
      {{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_SUMMARY.DISABLED_ACCOUNTS_ACKNOWLEDGEMENT' | translate }}
    </div>
    <div class="mc-detailed-list-column">
      {{(integration?.acknowledgeDisabledAccounts ? '$I18N_DIRECTORY_SYNC_TABLE.ENABLE' : '$I18N_DIRECTORY_SYNC_TABLE.DISABLE') | translate}}
    </div>
  </div>
</div>

<div class="mc-detailed-list">
  <div class="row mc-detailed-list-row">
    <div class="col-xs-4 mc-detailed-list-label">
      {{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_SUMMARY.EMAIL_DOMAINS_FILTER' | translate }}
    </div>
    <div class="mc-detailed-list-column">
      {{(integration?.domains ? '$I18N_DIRECTORY_SYNC_TABLE.ENABLE' : '$I18N_DIRECTORY_SYNC_TABLE.DISABLE') | translate}}
    </div>
  </div>
</div>

<div class="mc-detailed-list" *ngIf="integration?.domains">
  <div class="row mc-detailed-list-row">
    <div class="col-xs-4 mc-detailed-list-label">
      {{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_SUMMARY.DOMAINS' | translate }}
    </div>
    <div class="mc-detailed-list-column">{{ integration?.domains || '-' }}</div>
  </div>
</div>

<div *ngIf="!isGsuiteIntegration() && !isMSEIntegration() && !isLdapDominoIntegration()" class="mc-detailed-list">
  <div class="row mc-detailed-list-row">
    <div class="col-xs-4 mc-detailed-list-label">
      {{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_SUMMARY.INCLUDE_CONTACTS' | translate }}
    </div>
    <div class="mc-detailed-list-column">
      {{(integration?.syncContacts ? '$I18N_DIRECTORY_SYNC_TABLE.ENABLE' : '$I18N_DIRECTORY_SYNC_TABLE.DISABLE') | translate}}
    </div>
  </div>
</div>

<div *ngIf="isAzureIntegration()" class="mc-detailed-list">
  <div class="row mc-detailed-list-row">
    <div class="col-xs-4 mc-detailed-list-label">
      {{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_SUMMARY.INCLUDE_GUEST_ACCOUNTS' | translate }}
    </div>
    <div class="mc-detailed-list-column">
      {{ (integration?.office365Settings?.syncGuestUsers ? '$I18N_DIRECTORY_SYNC_TABLE.ENABLE' : '$I18N_DIRECTORY_SYNC_TABLE.DISABLE') | translate }}
    </div>
  </div>
</div>

<div class="mc-detailed-list">
  <div class="row mc-detailed-list-row">
    <div class="col-xs-4 mc-detailed-list-label">
      {{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_SUMMARY.MAXIMUM_UNLINK_SYNC' | translate }}
    </div>
    <div class="mc-detailed-list-column">{{ integration?.maxUnlink?.split('_').pop() | titlecase }}</div>
  </div>
</div>

<div class="mc-detailed-list">
  <div class="row mc-detailed-list-row">
    <div class="col-xs-4 mc-detailed-list-label">
      {{ '$I18N_DIRECTORY_SYNC_ASIDE_TAB_SUMMARY.DELETE_USERS' | translate }}
    </div>
    <div class="mc-detailed-list-column">
      {{(integration?.deleteUsers ? '$I18N_DIRECTORY_SYNC_TABLE.ENABLE' : '$I18N_DIRECTORY_SYNC_TABLE.DISABLE') | translate}}
    </div>
  </div>
</div>
