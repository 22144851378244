"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSyncReportAvailable = exports.isLoading = exports.reducer = exports.initialState = void 0;
const actions = require("../actions");
exports.initialState = {
    isLoading: false,
    isSyncReportAvailable: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.SYNCHRONIZATION_REPORT:
            return Object.assign(Object.assign({}, state), { isLoading: true });
        case actions.SYNCHRONIZATION_REPORT_AVAILABLE:
            return Object.assign(Object.assign({}, state), { isLoading: false, isSyncReportAvailable: action.isAvailable });
        case actions.SYNCHRONIZATION_REPORT_SUCCESS:
            return Object.assign(Object.assign({}, state), { isLoading: false, isSyncReportAvailable: true });
        case actions.SYNCHRONIZATION_REPORT_FAIL:
            return exports.initialState;
        default:
            return state;
    }
}
exports.reducer = reducer;
const isLoading = (state) => state.isLoading;
exports.isLoading = isLoading;
const isSyncReportAvailable = (state) => state.isSyncReportAvailable;
exports.isSyncReportAvailable = isSyncReportAvailable;
