"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
/**
 * Created by psambandam on 08/09/2016.
 */
(function () {
    'use strict';
    /* ======================================================== *\

     = Archive task edit - Service

     \* ======================================================== */
    angular.module('services.services.backup.archive-task-edit.service', [])
        .factory('archiveTaskEditService', ['$translate', function ($translate) {
            const TIME_DURATION_ENUM = {
                'all_days': $translate.instant('$I18N_TIMEDURATION_ALL_DAYS'),
                'monday': $translate.instant('$I18N_TIMEDURATION_MONDAY'),
                'tuesday': $translate.instant('$I18N_TIMEDURATION_TUESDAY'),
                'wednesday': $translate.instant('$I18N_TIMEDURATION_WEDNESDAY'),
                'thursday': $translate.instant('$I18N_TIMEDURATION_THURSDAY'),
                'friday': $translate.instant('$I18N_TIMEDURATION_FRIDAY'),
                'saturday': $translate.instant('$I18N_TIMEDURATION_SATURDAY'),
                'sunday': $translate.instant('$I18N_TIMEDURATION_SUNDAY')
            };
            const MAILBOX_CONTENT_MAPPING = {
                MAILS: {
                    label: '$I18N_BACKUP_RESTORE_ARCHIVE_TASK_SUMMARY_MAIL_LABEL'
                },
                CONTACTS: {
                    label: '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ARCHIVE_TASK_CONTACTS_LABEL'
                },
                CALENDARS: {
                    label: '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ARCHIVE_TASK_CALENDARS_LABEL'
                },
                TASKS: {
                    label: '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ARCHIVE_TASK_TASKS_LABEL'
                },
                NOTES: {
                    label: '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_ARCHIVE_TASK_NOTES_LABEL'
                }
            };
            return {
                CONSTANTS: {
                    TIME_DURATION_ENUM,
                    MAILBOX_CONTENT_MAPPING
                }
            };
        }]);
}());
