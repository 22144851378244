"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("app/services/services/backup/components/email-download/email-download-button"); //email.download.button
require("./email-view.controller"); //email.view.controller
require("./email-view.service"); //email.view.service
require("app/services/services/backup/components/thumbnails/thumbnails"); //thumbnails
require("./contact-detail/contact-detail"); //contact.view
require("mimecast-web-components/src/app/components/list-email-addresses/list-email-addresses"); //list.email.addresses.directive
require("mimecast-web-components/src/app/components/dock-layout/dock-layout"); //dock.layout
// html and css 
require("./email-view.tpl.html");
require("./less/email-view.less");
require("./tabs/preview_calendar_header.tpl.html");
require("./tabs/preview_header.tpl.html");
require("./tabs/preview_html.tpl.html");
require("./tabs/preview_plain.tpl.html");
angular.module('email.view', ['email.download.button', 'email.view.controller', 'email.view.service', 'thumbnails', 'contact.view', 'list.email.addresses', 'dock.layout']);
