'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
angular.module('mailboxStatusMessageTranslator.filter', [])
    .filter('mailboxStatusMessageTranslator', ['$translate', function ($translate) {
        return function (statusMessage) {
            if (!statusMessage) {
                return '';
            }
            const statusMessageMap = {
                MAILBOX_ALREADY_SYNCING_IN_A_DIFFERENT_TASK: '$I18N_MAILBOX_STATUS_MSG_ALREADY_SYNCING_IN_A_DIFFERENT_TASK',
                MAILBOX_NOT_FOUND: '$I18N_MAILBOX_STATUS_MSG_NOT_FOUND',
                ERROR_ARCHIVING_MESSAGE_CONTENT: '$I18N_MAILBOX_STATUS_MSG_ERROR_ARCHIVING_MESSAGE_CONTENT',
                AUTHENTICATION_FAILURE: '$I18N_MAILBOX_STATUS_MSG_AUTHENTICATION_FAILURE',
                NO_ADDRESSES_TO_BACKUP: '$I18N_MAILBOX_STATUS_MSG_NO_ADDRESSES_TO_BACKUP',
                BACKUP_DEFINITION_INTEGRITY_ERROR: '$I18N_MAILBOX_STATUS_MSG_BACKUP_DEFINITION_INTEGRITY_ERROR',
                ERROR_PERFORMING_BACKUP: '$I18N_MAILBOX_STATUS_MSG_ERROR_PERFORMING_BACKUP',
                MAILBOX_NOT_FOUND_NOT_EXPIRED: '$I18N_MAILBOX_STATUS_MSG_NOT_FOUND_NOT_EXPIRED',
                MAILBOX_NOT_FOUND_EXPIRED: '$I18N_MAILBOX_STATUS_MSG_NOT_FOUND_EXPIRED'
            };
            let translationKey = statusMessageMap[statusMessage];
            if (!translationKey) {
                translationKey = translationKey['ERROR_PERFORMING_BACKUP'];
            }
            return $translate.instant(translationKey);
        };
    }]);
