"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgThreatEventsEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const swg_threat_events_actions_1 = require("../actions/swg-threat-events.actions");
const notification_actions_1 = require("../../../../components/notification/actions/notification.actions");
class SwgThreatEventsEffects {
    constructor(actions$, service, graphService, translationService) {
        this.actions$ = actions$;
        this.service = service;
        this.graphService = graphService;
        this.translationService = translationService;
        this.getThreatEvents$ = this.actions$.pipe(effects_1.ofType(swg_threat_events_actions_1.SwgLoadThreatEventsActionsEnum.LOAD_THREAT_EVENTS), operators_1.switchMap((action) => {
            return this.service.getThreatEvents(action.payload).pipe(operators_1.map(threatEvents => new swg_threat_events_actions_1.SwgLoadThreatEventsSuccessAction(threatEvents)), operators_1.catchError(error => rxjs_1.merge(rxjs_1.of(this.getNotificationAction(error)), rxjs_1.of(new swg_threat_events_actions_1.SwgLoadThreatEventsFailureAction()))));
        }));
        this.getThreatEventsGraph$ = this.actions$.pipe(effects_1.ofType(swg_threat_events_actions_1.SwgLoadThreatEventsActionsEnum.LOAD_THREAT_EVENTS_GRAPH), operators_1.switchMap((action) => {
            return this.graphService.getVerdictsByDate(action.payload).pipe(operators_1.map(graphData => new swg_threat_events_actions_1.SwgLoadThreatEventsGraphSuccessAction(graphData)), operators_1.catchError(error => rxjs_1.merge(rxjs_1.of(this.getNotificationAction(error)), rxjs_1.of(new swg_threat_events_actions_1.SwgLoadThreatEventsGraphFailureAction()))));
        }));
        this.paginateEvents$ = this.actions$.pipe(effects_1.ofType(swg_threat_events_actions_1.SwgLoadThreatEventsActionsEnum.PAGINATE_THREAT_EVENTS), operators_1.delay(500, rxjs_1.asyncScheduler), operators_1.map(() => new swg_threat_events_actions_1.SwgPaginateThreatEventsCompletedAction()));
    }
    getNotificationAction(error) {
        const msg = error.fail
            ? error.fail[0].errors[0].message
            : this.translationService.instant('$I18N_SWG_THREAT_EVENTS_TABLE.SERVER_ERROR');
        return new notification_actions_1.NotificationShowAction({
            type: 'error',
            config: {
                msg
            }
        });
    }
}
__decorate([
    effects_1.Effect()
], SwgThreatEventsEffects.prototype, "getThreatEvents$", void 0);
__decorate([
    effects_1.Effect()
], SwgThreatEventsEffects.prototype, "getThreatEventsGraph$", void 0);
__decorate([
    effects_1.Effect()
], SwgThreatEventsEffects.prototype, "paginateEvents$", void 0);
exports.SwgThreatEventsEffects = SwgThreatEventsEffects;
