<mc-layout-list-extra-container
  keyTitle="$I18N_SWG_APPLICATION_CONTROL_TITLE"
  keyDescription="$I18N_SWG_APPLICATION_CONTROL_DESC">
  <mc-help-container>
    <mc-help-link url="https://community.mimecast.com/docs/DOC-3640"></mc-help-link>
  </mc-help-container>
  <mc-body-container>
    <mc-tab-group #tabs (tabChanged)="tabClicked($event)">
      <mc-tab name="{{ '$I18N_SWG_APPLICATION_CONTROL_OVERVIEW_TAB' | translate }}">
        <mc-swg-applications-dashboard
          [hasSwgFull]="hasSwgFull"
          [topSanctionedApps] = "topSanctionedApps$ | async"
          [topNonSanctionedApps] = "topNonSanctionedApps$ | async"
          [topMonitoredApps] = "topMonitoredApps$ | async"
          [topBlockedApps] = "topBlockedApps$ | async"
          [topCategories] = "topCategories$ | async"
          [sanctionedAppsCount] = "sanctionedAppsCount$ | async"
          [nonSanctionedAppsCount] = "nonSanctionedAppsCount$ | async"
          [monitoredAppsCount] = "monitoredAppsCount$ | async"
          [verdictsByDates] = "verdictsByDates$ | async"
          [verdictsByDatesLoading] = "verdictsByDatesLoading$ | async"
          [verdictsDistributedByCloudApps] = "verdictsDistributedByCloudApps$ | async"
          [verdictsDistributedByCloudAppsLoading] = "verdictsDistributedByCloudAppsLoading$ | async"
          [scanCountByFileType] = "scanCountByFileType$ | async"
          [scanCountByFileTypeLoading] = "scanCountByFileTypeLoading$ | async"
          [topProfiles]= "topProfiles$ | async"
          [topProfilesLoading] = "topProfilesLoading$ | async"
          [isChartDataLoading] = "isChartDataLoading$ | async"
          [clpIntegrationEnabled]="clpIntegrationEnabled && hasClp"
          (dateRangeChange)= "dateRangeChange($event)"
          (onDashboardWidgetClick)="onDashboardWidgetClick($event)">
        </mc-swg-applications-dashboard>
      </mc-tab>
      <mc-tab name="{{ '$I18N_SWG_APPLICATION_CONTROL_LIST_TAB' | translate }}">
        <mc-swg-applications-sanction
          [applications]="allApplications$ | async"
          [isApplicationCategorySetDataLoading]="isApplicationCategorySetDataLoading$ | async"
          [applicationCategoriesSet]="applicationCategoriesSet$ | async"
          (webApplicationSanction)="webApplicationSanction($event)"
          [loading]="isChartDataLoading$ | async"
          (dateRangeChangeSanction)= "dateRangeChange($event)"
          [apiUpdatingEdit]="isApiUpdatingEdit$ | async"
          [hasEditPermission]="hasEditPermission$ | async"
          (onShowNotificationSanction)="showNotification($event)">
        </mc-swg-applications-sanction>
      </mc-tab>
      <mc-tab name="{{ '$I18N_SWG_APPLICATION_CONTROL_EVENTS_TAB' | translate }}" *ngIf="clpIntegrationEnabled && hasClp">
        <mc-swg-threat-events></mc-swg-threat-events>
      </mc-tab>
    </mc-tab-group>
  </mc-body-container>
</mc-layout-list-extra-container>



