'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./select-date-picker.controller"); //select-date-picker.controller
require("./select-date-picker-directive"); //select-date-picker.directive
require("app/components/date-range-modal/date-range-modal"); //date.range.modal
// html and css 
require("./select-date-picker.tpl.html");
angular.module('select-date-picker', [
    'select-date-picker.controller',
    'select-date-picker.directive',
    'date.range.modal'
]);
