"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getProtectedApplicationsLoaded = exports.getProtectedApplicationsLoading = exports.getProtectedApplications = exports.getApplicationsLoaded = exports.getApplicationsLoading = exports.getApplications = exports.reducer = exports.initialState = void 0;
const applications = require("../../actions/applications.actions");
exports.initialState = {
    applications: [],
    applicationsLoading: false,
    applicationsLoaded: false,
    protectedApplications: [],
    protectedApplicationsLoading: false,
    protectedApplicationsLoaded: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case applications.SWG_POLICY_LOAD_APPLICATIONS:
            return Object.assign(Object.assign({}, state), { applicationsLoading: true });
        case applications.SWG_POLICY_LOAD_APPLICATIONS_SUCCESS:
            return Object.assign(Object.assign({}, state), { applications: action.payload, applicationsLoading: false, applicationsLoaded: true });
        case applications.SWG_POLICY_LOAD_APPLICATIONS_FAILURE:
            return Object.assign(Object.assign({}, state), { applicationsLoading: false });
        case applications.SWG_POLICY_CLEAR_APPLICATIONS:
            return Object.assign({}, exports.initialState);
        case applications.SWG_POLICY_LOAD_PROTECTED_APPLICATIONS:
            return Object.assign(Object.assign({}, state), { protectedApplicationsLoading: true });
        case applications.SWG_POLICY_LOAD_PROTECTED_APPLICATIONS_SUCCESS:
            return Object.assign(Object.assign({}, state), { protectedApplications: action.payload, protectedApplicationsLoading: false, protectedApplicationsLoaded: true });
        case applications.SWG_POLICY_LOAD_PROTECTED_APPLICATIONS_FAILURE:
            return Object.assign(Object.assign({}, state), { protectedApplicationsLoading: false });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getApplications = (state) => state.applications;
exports.getApplications = getApplications;
const getApplicationsLoading = (state) => state.applicationsLoading;
exports.getApplicationsLoading = getApplicationsLoading;
const getApplicationsLoaded = (state) => state.applicationsLoaded;
exports.getApplicationsLoaded = getApplicationsLoaded;
const getProtectedApplications = (state) => state.protectedApplications;
exports.getProtectedApplications = getProtectedApplications;
const getProtectedApplicationsLoading = (state) => state.protectedApplicationsLoading;
exports.getProtectedApplicationsLoading = getProtectedApplicationsLoading;
const getProtectedApplicationsLoaded = (state) => state.protectedApplicationsLoaded;
exports.getProtectedApplicationsLoaded = getProtectedApplicationsLoaded;
