<div class="mc-mep-internal-recipients-container bordered" *ngIf="hasInternalRecipientData(); else noInternalRecipients">
    <mc-table
            [isLoading]="false"
            [columns]="columns"
            [data]="tableData"
            [translatePrefix]="'$I18N_LOG_SIDEBAR.INTERNAL_RECIPIENTS'"
            [showHighlightedRow]="false"
            class="full-width"
    >
        <mc-column key="internalAddress">
            <mc-body-cell *mcBodyCellDef="let row">{{ row.internalAddress }}</mc-body-cell>
        </mc-column>
    </mc-table>
</div>
<ng-template #noInternalRecipients>
    <div class="no-internal-recipients">
        <mc-inline-notification [notification]="{
            status: 'info',
            boxed: true,
            msg: '$I18N_LOG_SIDEBAR.INTERNAL_RECIPIENTS.NO_DATA' | translate
        }"></mc-inline-notification>
    </div>
</ng-template>
