"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCheckUrlsScanResponse = exports.isReportModalButtonEnabled = exports.getManagedURL = exports.getScanDetailsReportId = exports.getCheckUrlsScanDetails = exports.getScanDetailsViewState = exports.getCheckUrlsLoadingState = exports.getCheckUrlsScanAggressiveResults = exports.getCheckUrlsScanModerateResults = exports.getCheckUrlsScanRelaxedResults = exports.getMetadata = exports.getRequestPage = exports.getPagination = exports.getSelectedRow = exports.getSearchRequest = exports.getTotalCount = exports.getItems = exports.isLoading = exports.reducer = exports.initialState = void 0;
const actions = require("../actions/log-list.actions");
const checkUrlsActions = require("../actions/logs-check-url.actions");
const sidePanelActions = require("../actions/logs-side-panel.actions");
const moment = require("moment");
exports.initialState = {
    items: [],
    totalCount: 0,
    searchRequest: {},
    isLoading: false,
    paginatorMetadata: { next: undefined, previous: undefined, pageSize: 50, totalRows: 0 },
    requestedPage: undefined,
    selectedPageSize: 50,
    selectedRow: undefined,
    showScanDetails: false,
    reportModalEnable: false,
    scannedDetails: {
        id: '',
        scanDetails: {
            aggressive: '-',
            moderate: '-',
            relaxed: '-'
        },
        scanDetails2: {
            relaxed: {
                scanResult: '',
                tagMaps: {}
            },
            moderate: {
                scanResult: '',
                tagMaps: {}
            },
            aggressive: {
                scanResult: '',
                tagMaps: {}
            }
        }
    },
    managedEntryURL: '',
    scanDetailsIsLoadign: false,
    scanDetailsHasError: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.URL_PROTECTION_LOGS_REQUEST:
            return Object.assign(Object.assign({}, state), { items: [], totalCount: 0, searchRequest: mergeSearchRequest(state.searchRequest, action.payload.search), isLoading: true, requestedPage: action.payload.page, selectedRow: undefined });
        case actions.URL_PROTECTION_LOGS_SUCCESS:
            return Object.assign(Object.assign({}, state), { items: action.payload.items, totalCount: (action.payload.page && action.payload.page['totalCount']) || 0, isLoading: false, paginatorMetadata: Object.assign(Object.assign({}, action.payload.page), { totalRows: (action.payload.page && action.payload.page['totalCount']) || 0 }) });
        case actions.URL_PROTECTION_LOGS_FAIL:
            return Object.assign(Object.assign({}, state), { isLoading: false });
        case actions.URL_PROTECTION_LOGS_PAGINATION:
            return Object.assign(Object.assign({}, state), { isLoading: true, paginatorMetadata: Object.assign(Object.assign({}, state.paginatorMetadata), { pageSize: action.payload.pageSize, next: undefined, previous: undefined }), requestedPage: action.payload, selectedPageSize: action.payload.pageSize || state.selectedPageSize });
        case sidePanelActions.OPEN_LOGS_SIDE_PANEL:
            return Object.assign({}, state);
        case sidePanelActions.CLOSE_LOGS_SIDE_PANEL:
        case sidePanelActions.CLEAR_SIDE_PANEL_STATE:
            return Object.assign(Object.assign({}, state), exports.initialState);
        case actions.URL_PROTECTION_BROWSER_ISOLATION_LINK_REQUEST:
            return Object.assign(Object.assign({}, state), { isLoading: true, selectedRow: action.payload });
        case actions.URL_PROTECTION_MANAGED_URL_CHECK_BANNER:
            return Object.assign(Object.assign({}, state), { managedEntryURL: action.payload });
        case actions.URL_PROTECTION_BROWSER_ISOLATION_LINK_SUCCESS:
            return Object.assign(Object.assign({}, state), { isLoading: false });
        case actions.URL_PROTECTION_BROWSER_ISOLATION_LINK_FAIL:
            return Object.assign(Object.assign({}, state), { isLoading: false });
        case checkUrlsActions.CHECK_URL_ATTEMPT:
            return Object.assign(Object.assign({}, state), { scanDetailsIsLoadign: true, showScanDetails: false, scanDetailsHasError: false });
        case checkUrlsActions.CHECK_URL_FAIL:
            return Object.assign(Object.assign({}, state), { scanDetailsIsLoadign: false, reportModalEnable: false, showScanDetails: false, scanDetailsHasError: true });
        case checkUrlsActions.CHECK_URL_SUCCESS:
            return Object.assign(Object.assign({}, state), { reportModalEnable: true, scannedDetails: Object.assign(Object.assign({}, action.payload), { id: action.payload.id, scanDetails: action.payload.scanDetails, scanDetails2: action.payload.scanDetails2, scanResponses: action.payload.scanResponses }), scanDetailsIsLoadign: false, showScanDetails: true, scanDetailsHasError: false });
        default:
            return state;
    }
}
exports.reducer = reducer;
function mergeSearchRequest(prev, next) {
    const merged = {
        start: next.start || prev.start,
        end: next.end || prev.end
    };
    if (!!next.filterBy) {
        if (next.filterBy.length > 0) {
            merged.filterBy = [...next.filterBy];
        }
    }
    else if (!!prev.filterBy) {
        merged.filterBy = [...prev.filterBy];
    }
    if (!!next.searchBy) {
        if (next.searchBy.length > 0) {
            merged.searchBy = [...next.searchBy];
        }
    }
    else if (!!prev.searchBy) {
        merged.searchBy = [...prev.searchBy];
    }
    return merged;
}
const isLoading = (state) => (state && state.isLoading) || false;
exports.isLoading = isLoading;
const getItems = (state) => [...state.items];
exports.getItems = getItems;
const getTotalCount = (state) => state.totalCount;
exports.getTotalCount = getTotalCount;
const getSearchRequest = (state) => {
    return Object.assign(Object.assign(Object.assign({}, state.searchRequest), (!state.searchRequest.start
        ? {
            start: moment().subtract(2, 'days')
        }
        : {})), (!state.searchRequest.end
        ? {
            end: moment()
        }
        : {}));
};
exports.getSearchRequest = getSearchRequest;
const getSelectedRow = (state) => {
    return (state && state.selectedRow) || exports.initialState.selectedRow;
};
exports.getSelectedRow = getSelectedRow;
const getPagination = (state) => (state ? Object.assign({}, state.paginatorMetadata) : {});
exports.getPagination = getPagination;
const getRequestPage = (state) => {
    if (!!state.requestedPage) {
        return Object.assign({}, state.requestedPage);
    }
    else {
        return {
            pageNumber: 0,
            pageSize: state.selectedPageSize,
            pageToken: undefined
        };
    }
};
exports.getRequestPage = getRequestPage;
const getMetadata = (state) => ({
    pagination: state ? Object.assign({}, state.paginatorMetadata) : Object.assign({}, exports.initialState.paginatorMetadata)
});
exports.getMetadata = getMetadata;
const getCheckUrlsScanRelaxedResults = (state) => state
    ? state.scannedDetails.scanDetails.relaxed
    : exports.initialState.scannedDetails.scanDetails.relaxed;
exports.getCheckUrlsScanRelaxedResults = getCheckUrlsScanRelaxedResults;
const getCheckUrlsScanModerateResults = (state) => state
    ? state.scannedDetails.scanDetails.moderate
    : exports.initialState.scannedDetails.scanDetails.moderate;
exports.getCheckUrlsScanModerateResults = getCheckUrlsScanModerateResults;
const getCheckUrlsScanAggressiveResults = (state) => state
    ? state.scannedDetails.scanDetails.aggressive
    : exports.initialState.scannedDetails.scanDetails.aggressive;
exports.getCheckUrlsScanAggressiveResults = getCheckUrlsScanAggressiveResults;
const getCheckUrlsLoadingState = (state) => state ? state.scanDetailsIsLoadign : exports.initialState.scanDetailsIsLoadign;
exports.getCheckUrlsLoadingState = getCheckUrlsLoadingState;
const getScanDetailsViewState = (state) => state ? state.showScanDetails : exports.initialState.showScanDetails;
exports.getScanDetailsViewState = getScanDetailsViewState;
const getCheckUrlsScanDetails = (state) => state ? state.scannedDetails.scanDetails2 : exports.initialState.scannedDetails.scanDetails2;
exports.getCheckUrlsScanDetails = getCheckUrlsScanDetails;
const getScanDetailsReportId = (state) => state ? state.scannedDetails.id : exports.initialState.scannedDetails.id;
exports.getScanDetailsReportId = getScanDetailsReportId;
const getManagedURL = (state) => (state ? state.managedEntryURL : null);
exports.getManagedURL = getManagedURL;
const isReportModalButtonEnabled = (state) => state ? state.reportModalEnable : exports.initialState.reportModalEnable;
exports.isReportModalButtonEnabled = isReportModalButtonEnabled;
const getCheckUrlsScanResponse = (state) => state ? state.scannedDetails.scanResponses : exports.initialState.scannedDetails.scanResponses;
exports.getCheckUrlsScanResponse = getCheckUrlsScanResponse;
