"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TopReviewerPerformancesBuilder = void 0;
const top_reviewer_performances_1 = require("../top-reviewer-performances");
const builder_utils_1 = require("./builder-utils");
class TopReviewerPerformancesBuilder {
    static build(reviewerPerformances, type, hasAssociatedFailedQueues) {
        const totalProcessedCount = TopReviewerPerformancesBuilder.totalProcessedCountForReviewerPerformances(reviewerPerformances);
        return new top_reviewer_performances_1.TopReviewerPerformances(reviewerPerformances, totalProcessedCount, type, hasAssociatedFailedQueues);
    }
    static totalProcessedCountForReviewerPerformances(reviewerPerformances) {
        return reviewerPerformances.reduce(builder_utils_1.sumByQueueReviewerProcessedCount, 0);
    }
}
exports.TopReviewerPerformancesBuilder = TopReviewerPerformancesBuilder;
