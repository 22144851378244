"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MepPolicyEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const notification_actions_1 = require("../../../../../components/notification/actions/notification.actions");
const policyActions = require("../../actions/policy.actions");
const reducers = require("../../reducers/");
const models_1 = require("../../models/models");
const sidebar_actions_1 = require("../../actions/sidebar.actions");
const tableStore = require("@mimecast-ui/table-store");
const { MEP_POLICY_SAVE, MEP_POLICY_LOAD_FOR_EDITING, MEP_POLICY_GO_TO_EDIT_PAGE, MEP_POLICY_EDIT_POLICY, MEP_POLICY_EDIT_POLICY_FAILURE, MEP_POLICY_SAVE_STATUS, MEP_POLICY_SAVE_FAILURE } = policyActions.MepPolicyActionsEnums;
class MepPolicyEffects {
    constructor(actions$, api$, mepPolicyService, store$, translate$, state$) {
        this.actions$ = actions$;
        this.api$ = api$;
        this.mepPolicyService = mepPolicyService;
        this.store$ = store$;
        this.translate$ = translate$;
        this.state$ = state$;
        this.createPolicy$ = this.actions$.pipe(effects_1.ofType(MEP_POLICY_SAVE), operators_1.map((action) => action.payload), operators_1.switchMap(policy => {
            const apiPayload = this.mepPolicyService.createAPIPayLoad(Object.assign({}, policy));
            return this.api$.createPolicy(apiPayload).pipe(operators_1.mergeMap(() => {
                this.state$.$state.go('misaddressed-email-protect', { displayTab: 'policies' });
                return [
                    new notification_actions_1.NotificationShowSuccessAction(this.translate$.instant('$I18N_MEP.NOTIFICATIONS.CREATE.SUCCESS')),
                    new policyActions.MepSavePolicySuccess()
                ];
            }), operators_1.catchError(() => rxjs_1.merge(rxjs_1.of(new notification_actions_1.NotificationShowFailAction(this.translate$.instant('$I18N_MEP.NOTIFICATIONS.CREATE.ERROR'))), rxjs_1.of(new policyActions.MepSavePolicyFailure()))));
        }));
        this.savePolicyStatus$ = this.actions$.pipe(effects_1.ofType(MEP_POLICY_SAVE_STATUS), operators_1.withLatestFrom(this.store$.select(reducers.getMepPolicy)), operators_1.switchMap(([, policy]) => {
            // @ts-ignore
            return this.api$
                .updatePolicySettings({
                data: [
                    {
                        settingsId: policy.id,
                        name: policy.name,
                        description: policy.description,
                        enabled: policy.enabled
                    }
                ]
            })
                .pipe(operators_1.mergeMap(response => {
                const message = response.enabled
                    ? '$I18N_MEP.NOTIFICATIONS.ENABLE.SUCCESS'
                    : '$I18N_MEP.NOTIFICATIONS.DISABLE.SUCCESS';
                return [
                    new notification_actions_1.NotificationShowSuccessAction(this.translate$.instant(message)),
                    new policyActions.MepSaveStatusPolicySuccess(response['lastUpdated']),
                    new tableStore.LoadAction({ tableId: 'MepPolicyList' }),
                    new sidebar_actions_1.MepPolicyEnableConfirmModalClose(false),
                    new sidebar_actions_1.MepPolicyListSidebarClose()
                ];
            }), operators_1.catchError(() => rxjs_1.merge(rxjs_1.of(new notification_actions_1.NotificationShowFailAction(this.translate$.instant('$I18N_MEP.NOTIFICATIONS.CREATE.ERROR'))), rxjs_1.of(new policyActions.MepSaveStatusPolicyFailure()), rxjs_1.of(new sidebar_actions_1.MepPolicyEnableConfirmModalClose(false)))));
        }));
        this.goToEditPage$ = this.actions$.pipe(effects_1.ofType(MEP_POLICY_GO_TO_EDIT_PAGE), operators_1.tap(() => {
            this.state$.$state.go('mep-policy-create');
        }));
        this.goToPolicyListPage$ = this.actions$.pipe(effects_1.ofType(MEP_POLICY_SAVE_FAILURE, MEP_POLICY_EDIT_POLICY_FAILURE), operators_1.tap(() => {
            this.state$.$state.go('misaddressed-email-protect', { displayTab: 'policies' });
        }));
        this.loadPolicyForEditing$ = this.actions$.pipe(effects_1.ofType(MEP_POLICY_LOAD_FOR_EDITING), operators_1.map((action) => action.id), operators_1.switchMap(id => this.api$.getPolicy(id).pipe(operators_1.mergeMap(res => {
            const mepPolicy = this.mepPolicyService.createUIModel(res);
            return [new policyActions.MepPolicyOpenForEditing(mepPolicy)];
        }), operators_1.catchError(() => {
            return rxjs_1.merge(rxjs_1.of(new notification_actions_1.NotificationShowFailAction(this.translate$.instant('$I18N_MEP.NOTIFICATIONS.LOAD.ERROR'))), rxjs_1.of(new policyActions.MepPolicyLoadForEditingFailure()));
        }))));
        this.showDuplicateAppliesToWarning = this.actions$.pipe(effects_1.ofType(policyActions.MepPolicyActionsEnums.MEP_UPDATE_AFFECTED_USERS_STEP), operators_1.withLatestFrom(this.store$.select(reducers.getShowDuplicateWarning)), operators_1.map(([, warning]) => warning), 
        // @ts-ignore
        operators_1.filter(warning => warning), operators_1.mergeMap(() => [
            new notification_actions_1.NotificationShowAction({
                type: 'success',
                config: {
                    msg: '$I18N_SWG_CREATE_POLICY_AFFECTED_USERS_DUPLICATE_ADDED'
                }
            }),
            new policyActions.MepClearAffectedUsersWarning()
        ]));
        this.showAddedAppliesToNotification = this.actions$.pipe(effects_1.ofType(policyActions.MepPolicyActionsEnums.MEP_UPDATE_AFFECTED_USERS_STEP), operators_1.withLatestFrom(this.store$.select(reducers.getShowAffectedUserAddedNotification)), operators_1.map(([action, show]) => {
            // @ts-ignore
            return { payload: action.payload, showNotification: show };
        }), 
        // @ts-ignore
        operators_1.filter(show => show.showNotification), operators_1.mergeMap(action => {
            let notificationMessage = '';
            if (action.payload.condition === models_1.MepAffectedUsersEnum.PROFILE_GROUP) {
                notificationMessage =
                    action.payload.payload.description +
                        this.translate$.instant('$I18N_MEP_CREATE_POLICY_RULES_NOTIFICATION_MESSAGE');
            }
            else if (action.payload.condition === models_1.MepAffectedUsersEnum.INDIVIDUAL_EMAIL_ADDRESS) {
                notificationMessage =
                    action.payload.payload.emailAddress +
                        this.translate$.instant('$I18N_MEP_CREATE_POLICY_RULES_NOTIFICATION_MESSAGE');
            }
            return [
                new notification_actions_1.NotificationShowAction({
                    type: 'success',
                    config: {
                        msg: notificationMessage
                    }
                }),
                new policyActions.MepClearAffectedUsersNotificaiton()
            ];
        }));
        this.updatePolicy$ = this.actions$.pipe(effects_1.ofType(MEP_POLICY_EDIT_POLICY), operators_1.withLatestFrom(this.store$.select(reducers.getMepPolicy)), operators_1.switchMap(([, policy]) => {
            // eslint-disable-next-line no-new-wrappers
            const emptyString = new String('');
            // @ts-ignore
            const apiPolicyPayload = this.mepPolicyService.createAPIPayLoad(policy);
            const updateDetails$ = rxjs_1.forkJoin([
                this.api$.updatePolicyDefinition({
                    data: [
                        {
                            settingsId: policy.id,
                            action: policy.action,
                            matchLevel: policy.matchLevel,
                            autoReleaseTimeoutMinutes: policy.autoReleaseTimeoutMinutes
                        }
                    ]
                }),
                this.api$.updatePolicyRules({
                    data: [
                        {
                            settingsId: policy.id,
                            rules: this.formatRulesPayload(apiPolicyPayload.rules)
                        }
                    ]
                }),
                this.api$.updatePolicySettings({
                    data: [
                        {
                            settingsId: policy.id,
                            name: policy.name,
                            description: policy.description || emptyString,
                            enabled: policy.enabled
                        }
                    ]
                })
            ]);
            return updateDetails$.pipe(operators_1.mergeMap(() => {
                this.state$.$state.go('misaddressed-email-protect', { displayTab: 'policies' });
                return [
                    new notification_actions_1.NotificationShowSuccessAction(this.translate$.instant('$I18N_MEP.NOTIFICATIONS.UPDATE.SUCCESS')),
                    new policyActions.MepEditPolicySuccess()
                ];
            }), operators_1.catchError(() => rxjs_1.merge(rxjs_1.of(new notification_actions_1.NotificationShowFailAction(this.translate$.instant('$I18N_MEP.NOTIFICATIONS.UPDATE.ERROR'))), rxjs_1.of(new policyActions.MepEditPolicyFailure()))));
        }));
    }
    formatRulesPayload(rules) {
        return rules.map(rule => {
            const ruleId = rule.id;
            delete rule.id;
            return Object.assign(Object.assign({}, rule), { ruleId });
        });
    }
}
__decorate([
    effects_1.Effect()
], MepPolicyEffects.prototype, "createPolicy$", void 0);
__decorate([
    effects_1.Effect()
], MepPolicyEffects.prototype, "savePolicyStatus$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], MepPolicyEffects.prototype, "goToEditPage$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], MepPolicyEffects.prototype, "goToPolicyListPage$", void 0);
__decorate([
    effects_1.Effect()
], MepPolicyEffects.prototype, "loadPolicyForEditing$", void 0);
__decorate([
    effects_1.Effect()
], MepPolicyEffects.prototype, "showDuplicateAppliesToWarning", void 0);
__decorate([
    effects_1.Effect()
], MepPolicyEffects.prototype, "showAddedAppliesToNotification", void 0);
__decorate([
    effects_1.Effect()
], MepPolicyEffects.prototype, "updatePolicy$", void 0);
exports.MepPolicyEffects = MepPolicyEffects;
