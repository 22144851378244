"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChameleonApiService = void 0;
class ChameleonApiService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    deleteCustomExternalDomain(monitoredDomainDeletePayload) {
        return this.httpClient.post('/api/ttp/url/monitoreddomain/delete-monitored-domain', monitoredDomainDeletePayload);
    }
    createMonitoredExternalDomains(monitoredDomainCreationPayload) {
        return this.httpClient.post('/api/ttp/url/monitoreddomain/create-monitored-domain', monitoredDomainCreationPayload);
    }
    updateMonitoredDomain(updateMonitoredDomainPayload) {
        return this.httpClient.post('/api/ttp/url/monitoreddomain/update-monitored-domain', updateMonitoredDomainPayload);
    }
}
exports.ChameleonApiService = ChameleonApiService;
