"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PoliciesEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const policiesActions = require("../actions/policies.actions");
const policyDetailsActions = require("../actions/policy-details.actions");
const delete_policy_modal_component_1 = require("../components/delete-policy-modal/delete-policy-modal.component");
const tableStore = require("@mimecast-ui/table-store");
const notification_actions_1 = require("../../components/notification/actions/notification.actions");
const wizardActions = require("../actions/policy-wizard/wizard.actions");
class PoliciesEffects {
    constructor(actions$, policiesListApiService, stateService, modalService, translationService) {
        this.actions$ = actions$;
        this.policiesListApiService = policiesListApiService;
        this.stateService = stateService;
        this.modalService = modalService;
        this.translationService = translationService;
        this.openPolicy$ = this.actions$.pipe(effects_1.ofType(policiesActions.OPEN_POLICY), operators_1.map((action) => new policyDetailsActions.IdentityOpenPolicyDetailsSidebarAction(action.payload)));
        this.showCreatePolicyWizard$ = this.actions$.pipe(effects_1.ofType(policiesActions.SHOW_CREATE_POLICY_WIZARD), operators_1.map(() => {
            this.stateService.$state.go('identity-create-policy-page', { isEditFlow: false });
            return new wizardActions.ResetAction();
        }));
        this.showEditPolicyWizard$ = this.actions$.pipe(effects_1.ofType(policiesActions.SHOW_EDIT_POLICY_WIZARD), operators_1.map((action) => action.payload), operators_1.switchMap(policyId => {
            this.stateService.$state.go('identity-create-policy-page', { isEditFlow: true });
            return [new wizardActions.ResetAction(), new wizardActions.LoadPolicyAction(policyId)];
        }));
        this.showPolicyDeletionModal$ = this.actions$.pipe(effects_1.ofType(policiesActions.DELETE_CONFIRMATION_MODAL_OPEN), operators_1.map((action) => action.payload), operators_1.switchMap(() => {
            const modalPanel = this.modalService.open(delete_policy_modal_component_1.IdentityDeletePolicyModalComponent, {
                size: 'md',
                hasBackdrop: true
            });
            this.deletePolicyModalInstance = modalPanel;
            return rxjs_1.EMPTY;
        }));
        this.deletePolicy$ = this.actions$.pipe(effects_1.ofType(policiesActions.DELETE_POLICY), operators_1.map((action) => action.payload), operators_1.switchMap((data) => {
            return this.policiesListApiService.deletePolicy(data).pipe(operators_1.mergeMap(() => {
                return [
                    new notification_actions_1.NotificationShowAction({
                        type: 'success',
                        config: {
                            msg: this.translationService.instant('I18N_IDENTITY_POLICY_DELETED')
                        }
                    }),
                    new policiesActions.IdentityDeletePolicySuccessAction(),
                    new policiesActions.IdentityDeleteModalCloseAction(),
                    new tableStore.LoadAction({ tableId: 'IdentityPolicyList' })
                ];
            }), operators_1.catchError(fail => rxjs_1.merge(rxjs_1.of(new notification_actions_1.NotificationShowAction({
                type: 'error',
                config: {
                    msg: fail[0].errors[0].message
                }
            })), rxjs_1.of(new policiesActions.IdentityDeleteModalCloseAction()), rxjs_1.of(new policiesActions.IdentityDeletePolicyFailureAction()))));
        }));
        this.closeIdentityPolicyDeleteModal$ = this.actions$.pipe(effects_1.ofType(policiesActions.DELETE_CONFIRMATION_MODAL_CLOSE), operators_1.filter(() => !!this.deletePolicyModalInstance), operators_1.switchMap(() => {
            this.deletePolicyModalInstance.close();
            this.deletePolicyModalInstance = undefined;
            return rxjs_1.EMPTY;
        }));
    }
}
__decorate([
    effects_1.Effect()
], PoliciesEffects.prototype, "openPolicy$", void 0);
__decorate([
    effects_1.Effect()
], PoliciesEffects.prototype, "showCreatePolicyWizard$", void 0);
__decorate([
    effects_1.Effect()
], PoliciesEffects.prototype, "showEditPolicyWizard$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], PoliciesEffects.prototype, "showPolicyDeletionModal$", void 0);
__decorate([
    effects_1.Effect()
], PoliciesEffects.prototype, "deletePolicy$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], PoliciesEffects.prototype, "closeIdentityPolicyDeleteModal$", void 0);
exports.PoliciesEffects = PoliciesEffects;
