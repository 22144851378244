
    <ng-container *ngIf="htmlWrapper$ | async as htmlWrapper">
      <div *ngIf="htmlWrapper.hasImages && !htmlWrapper.isDisplayingImages; else htmlWithImages">
        <div>
          <mc-inline-notification [notification]="inlineNotification"></mc-inline-notification>
        </div>
        <div class="mc-panel-body" [innerHtml]="htmlWrapper.content"></div>
      </div>
      <ng-template #htmlWithImages>
        <div *ngIf="isLoading$ | async; else htmlLoaded">
          <mc-spinner></mc-spinner>
        </div>
        <ng-template #htmlLoaded>
          <div>
            <div class="mc-panel-body" [innerHtml]="htmlWrapper.content"></div>
          </div>
        </ng-template>
      </ng-template>
    </ng-container>
  