"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgDevicesListContainerComponent = void 0;
const reducers = require("../../reducers");
class SwgDevicesListContainerComponent {
    constructor(store) {
        this.store = store;
    }
    ngOnInit() {
        this.$groupId = this.store.select(reducers.getGroupId);
    }
}
exports.SwgDevicesListContainerComponent = SwgDevicesListContainerComponent;
