"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PolicyCancelModalSource = exports.CloudPasswordOption = exports.LocationPermission = exports.SecondFactorOption = exports.DelegatedAuthOption = exports.ExpirationTimeType = void 0;
var ExpirationTimeType;
(function (ExpirationTimeType) {
    ExpirationTimeType["MINUTES"] = "minutes";
    ExpirationTimeType["HOURS"] = "hours";
    ExpirationTimeType["DAYS"] = "days";
})(ExpirationTimeType = exports.ExpirationTimeType || (exports.ExpirationTimeType = {}));
var DelegatedAuthOption;
(function (DelegatedAuthOption) {
    DelegatedAuthOption["LDAP"] = "ldap";
    DelegatedAuthOption["EWS"] = "ews_basic";
    DelegatedAuthOption["OFFICE_365_BASIC"] = "office_365_basic";
})(DelegatedAuthOption = exports.DelegatedAuthOption || (exports.DelegatedAuthOption = {}));
var SecondFactorOption;
(function (SecondFactorOption) {
    SecondFactorOption["NONE"] = "none";
    SecondFactorOption["SMS"] = "sms";
    SecondFactorOption["TOTP"] = "totp";
    SecondFactorOption["MIMEAUTH"] = "mimeauth";
})(SecondFactorOption = exports.SecondFactorOption || (exports.SecondFactorOption = {}));
var LocationPermission;
(function (LocationPermission) {
    LocationPermission["NONE"] = "none";
    LocationPermission["IP_ALLOW_LOGIN"] = "ip_allow_login";
    LocationPermission["IP_NO_2FA"] = "ip_no_2fa";
    LocationPermission["COUNTRY"] = "country";
    LocationPermission["COUNTRY_CITY"] = "country_city";
})(LocationPermission = exports.LocationPermission || (exports.LocationPermission = {}));
var CloudPasswordOption;
(function (CloudPasswordOption) {
    CloudPasswordOption["ALWAYS_ALLOW"] = "always_allow";
    CloudPasswordOption["ALLOW_IN_CON"] = "allow_in_con";
    CloudPasswordOption["NEVER_ALLOW"] = "never_allow";
})(CloudPasswordOption = exports.CloudPasswordOption || (exports.CloudPasswordOption = {}));
var PolicyCancelModalSource;
(function (PolicyCancelModalSource) {
    PolicyCancelModalSource[PolicyCancelModalSource["CREATE_POLICY"] = 0] = "CREATE_POLICY";
    PolicyCancelModalSource[PolicyCancelModalSource["EDIT_POLICY"] = 1] = "EDIT_POLICY";
})(PolicyCancelModalSource = exports.PolicyCancelModalSource || (exports.PolicyCancelModalSource = {}));
