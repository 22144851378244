"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HeldOverviewPageComponent = void 0;
const core_1 = require("@angular/core");
const summaryByReasonAction = require("../../actions/summary-by-reason.action");
const summaryByGroupAction = require("../../actions/summary-by-group.action");
const heldQueueAction = require("../../actions/held-queue.actions");
const moment = require("moment");
const reducers = require("../../reducers");
const utils_1 = require("../../../common/utils");
const rxjs_1 = require("rxjs");
class HeldOverviewPageComponent {
    constructor(store, messagingCapsService) {
        this.store = store;
        this.messagingCapsService = messagingCapsService;
        this.columns = ['reason', 'count'];
        this.separator = ' - ';
        this.pageIterator = 0;
        this.rowPerPage = 20;
        this.initialPayload = {
            start: 0,
            pageSize: this.rowPerPage,
            prevPage: 0,
            nextPage: {}
        };
        this.pageIndex = 0;
        this.defaultTitle = '$I18N_MESSAGE_CENTER_HELD_MESSAGES_OVERVIEW.HEADER.TITLE';
        this.topTenTitle = '$I18N_MESSAGE_CENTER_HELD_MESSAGES_OVERVIEW.TOP_TEN';
        this.GroupHeldMessagesTitle = '$I18N_MESSAGE_CENTER_HELD_MESSAGES_OVERVIEW.GROUP_HELD';
        this.layoutWrapperInputClass = 'mc-overview-wrapper-input';
        this.clickedBarOverview = new core_1.EventEmitter();
        this.clickedPieOverview = new core_1.EventEmitter();
        this.clickedTableRowOverview = new core_1.EventEmitter();
        this.clickIconSearchEmitOverview = new core_1.EventEmitter();
    }
    ngOnInit() {
        this.isSummaryByReasonLoading$ = this.store.select(reducers.isSummaryByReasonLoading);
        this.summaryByReasonData$ = this.store.select(reducers.getSummaryByReasonResults);
        this.topTenHeldReasons$ = this.store.select(reducers.getTopTenHeldSummaryByReasons);
        this.options$ = this.store.select(reducers.getSelectedSummaryDetailResultsByKey);
        this.isSummaryByGroupLoading$ = this.store.select(reducers.isSummaryByGroupLoading);
        this.summaryByGroupData$ = this.store.select(reducers.getSummaryByGroupResults);
        this.canViewSpamScore = this.messagingCapsService.canViewSpamScore();
        // pagination
        this.isPaginationLoading$ = this.store.select(reducers.getPaginationLoading);
        this.paginatorData$ = this.store.select(reducers.getPaginationData);
        this.paginatorLimits(0, this.initialPayload);
        this.store.dispatch(new summaryByReasonAction.SummaryByReasonRequestAction());
        this.store.dispatch(new summaryByGroupAction.SummaryByGroupRequestAction());
    }
    clickedTableRow(heldMessage) {
        // Emit event to inform Tabs Page component that a row field was clicked
        this.store.dispatch(new heldQueueAction.HeldQueueRequestAction({
            search: {
                start: moment()
                    .subtract(30, 'days')
                    .toDate(),
                end: moment().toDate(),
                searchBy: [{ fieldName: 'policyInfo', value: heldMessage.policyInfo }],
                filterBy: []
            }
        }));
        this.clickedTableRowOverview.emit({ field: 'heldReason', value: heldMessage.policyInfo });
    }
    clickedBar(heldReasonData) {
        // Emit event to inform Tabs Page component that a bar was clicked
        this.clickedBarOverview.emit(heldReasonData);
    }
    clickedPie(heldGroupData) {
        // Emit event to inform Tabs Page component that a pie slice was clicked
        this.clickedPieOverview.emit(heldGroupData);
    }
    clickIconSearchEmit(data) {
        this.clickIconSearchEmitOverview.emit(utils_1.stripNonPrintableChars(data));
    }
    updatePaginationLimits(payload) {
        return (this.updatePaginationLimitsSubscription = this.paginatorData$.subscribe(data => {
            if (data) {
                data.start = payload.start + 1;
                data.pageSize = payload.pageSize;
                data.nextPage = payload.nextPage;
                if (payload.start === 0) {
                    data.prevPage = undefined;
                    if (data.total > data.pageSize) {
                        data.nextPage = {};
                    }
                    if (payload.nextPage === undefined) {
                        data.nextPage = undefined;
                    }
                }
                else {
                    data.prevPage = {};
                }
                this.paginatorDataPaginated$ = rxjs_1.of(data);
            }
        }));
    }
    paginatorLimits(pageIndex, initialPayload) {
        return (this.paginatorLimitsSubscription = this.summaryByReasonData$.subscribe(data => {
            if (data && data.length > 0) {
                this.dataLength = data.length;
                const absPageIndex = Math.abs(pageIndex);
                const payload = {
                    start: absPageIndex * this.rowPerPage,
                    pageSize: !!initialPayload
                        ? initialPayload.pageSize
                        : (absPageIndex + 1) * this.rowPerPage,
                    nextPage: !!initialPayload && initialPayload.nextPage === undefined ? undefined : {}
                };
                if (payload.pageSize > data.length) {
                    payload.pageSize = data.length;
                    payload.nextPage = undefined;
                }
                this.updatePaginationLimits(payload);
                this.summaryByReasonDataPaginated$ = rxjs_1.of(data.slice(absPageIndex * this.rowPerPage, (absPageIndex + 1) * this.rowPerPage));
            }
        }));
    }
    onPaginate(paginatorData) {
        this.pageIndex =
            paginatorData.direction === 1
                ? (this.pageIterator = this.pageIterator + 1)
                : (this.pageIterator = this.pageIterator - 1);
        if (this.pageIndex < Math.ceil(this.dataLength / this.rowPerPage)) {
            if (Math.ceil(this.dataLength / this.rowPerPage) - this.pageIndex > 1) {
                this.paginatorLimits(this.pageIndex);
            }
            else {
                const initialPayload = { nextPage: undefined, pageSize: this.dataLength };
                this.paginatorLimits(this.pageIndex, initialPayload);
            }
        }
        else {
            this.pageIterator = this.pageIterator - 1;
            return false;
        }
    }
    ngOnDestroy() {
        if (this.paginatorLimitsSubscription) {
            this.paginatorLimitsSubscription.unsubscribe();
        }
        if (this.updatePaginationLimitsSubscription) {
            this.updatePaginationLimitsSubscription.unsubscribe();
        }
    }
}
exports.HeldOverviewPageComponent = HeldOverviewPageComponent;
