<div >
  <div class="row">
    <div class="col-md-12 panel-margin-bottom">
      <button type="button" class="btn btn-primary create" (click)="create.emit()" *ngIf="showCustom"
              [disabled]="block">Create Tip
      </button>
      <button type="button" class="btn btn-default preview-all" (click)="previewAll.emit()"
              [disabled]="!previewTip?.tip || block">Preview All
      </button>
    </div>
  </div>
  <div class="row">
    <mc-template-add-safety-tip class="col-md-12 panel-margin-top panel-margin-bottom"
                                *ngIf="creatingTip"
                                [tipToEdit]="previewTip?.tip"
                                (save)="save.emit($event)" (valueChanges)="previewCustomeTip.emit($event)"
                                (cancel)="cancel.emit($event)">
    </mc-template-add-safety-tip>
  </div>
  <mc-template-safety-tips-list label="Custom Tips"
                                *ngIf="showCustom"
                                [safetyTips]="customSafetyTips"
                                [selectedTipIndex]="customeSafetyTipsSelectedIndex"
                                [showTipForm]="editingTip"
                                [block]="block"
                                (edit)="edit.emit($event)"
                                (delete)="delete.emit($event)"
                                (preview)="previewCustomeTip.emit($event)"
                                (save)="save.emit($event)"
                                (cancel)="cancel.emit($event)">
  </mc-template-safety-tips-list>
  <mc-template-safety-tips-list label="Default Tips"
                                *ngIf="showDefault"
                                [safetyTips]="mimecastSafetyTips"
                                [selectedTipIndex]="mimecastSafetyTipsSelectedIndex"
                                [block]="block"
                                (preview)="previewMimecastTip.emit($event)">
  </mc-template-safety-tips-list>
</div>
