"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.getSystemDetailPageTotal = exports.getSystemMessagesTotal = exports.getSystemMessagesMetadata = exports.getSystemMessagesRequestPage = exports.getSystemMessagesPagination = exports.getSystemMessagesSelectedRow = exports.getSystemMessagesSearch = exports.getSystemMessagesTotalCount = exports.getSystemMessagesItems = exports.isSystemMessagesLoading = exports.SystemMessagesReducers = void 0;
const store_1 = require("@ngrx/store");
const systemMessages = require("./system-messages.reducer");
exports.SystemMessagesReducers = {
    systemMessages: systemMessages.reducer
};
const systemMessagesState = store_1.createFeatureSelector('system-messages');
const ɵ0 = (state) => state.systemMessages;
exports.ɵ0 = ɵ0;
const getSystemMessagesState = store_1.createSelector(systemMessagesState, ɵ0);
exports.isSystemMessagesLoading = store_1.createSelector(getSystemMessagesState, systemMessages.isLoading);
exports.getSystemMessagesItems = store_1.createSelector(getSystemMessagesState, systemMessages.getItems);
exports.getSystemMessagesTotalCount = store_1.createSelector(getSystemMessagesState, systemMessages.getTotalCount);
exports.getSystemMessagesSearch = store_1.createSelector(getSystemMessagesState, systemMessages.getSearchRequest);
exports.getSystemMessagesSelectedRow = store_1.createSelector(getSystemMessagesState, systemMessages.getSelectedRow);
exports.getSystemMessagesPagination = store_1.createSelector(getSystemMessagesState, systemMessages.getPagination);
exports.getSystemMessagesRequestPage = store_1.createSelector(getSystemMessagesState, systemMessages.getRequestPage);
exports.getSystemMessagesMetadata = store_1.createSelector(getSystemMessagesState, systemMessages.getMetadata);
exports.getSystemMessagesTotal = store_1.createSelector(getSystemMessagesState, systemMessages.getSystemDetailPageTotal);
exports.getSystemDetailPageTotal = store_1.createSelector(getSystemMessagesState, systemMessages.getSystemDetailPageTotal);
