"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HeaderComponent = void 0;
const core_1 = require("@angular/core");
class HeaderComponent {
    constructor() {
        this.copiedToClipboard = new core_1.EventEmitter();
    }
    copyToClipboardSuccess() {
        this.copiedToClipboard.emit();
    }
    unescapeHeader(html = '') {
        if (!html) {
            return;
        }
        return unescape(html
            .replace('<pre>', '')
            .replace('</pre>', '')
            .replace(/&quot;/g, '"')
            .replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>'));
    }
}
exports.HeaderComponent = HeaderComponent;
