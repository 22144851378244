<div class="row" *ngIf="safetyTips && safetyTips.length > 0">
  <div class="col-xs-12">
    <h5>{{label | translate }}</h5>
    <ul class="list-group">
      <li class="list-group-item"
          *ngFor="let tip of safetyTips; let i = index; let o = odd;"
          [class.active]="!showTipForm && selectedTipIndex === i"
          [class.edit-mode]="showTipForm && selectedTipIndex === i"
          (click)="safetyTipClick($event, i, tip)">
        <div class="row" *ngIf="showTipForm && selectedTipIndex === i ; else elseBlock">
          <mc-template-add-safety-tip class="col-md-12 no-margin no-padding"
                                      [selectedTipIndex]="selectedTipIndex"
                                      [tipToEdit]="tip"
                                      (save)="save.emit($event)"
                                      (cancel)="cancel.emit($event)"
                                      (valueChanges)="preview.emit($event)">
          </mc-template-add-safety-tip>
        </div>
        <ng-template #elseBlock>
          <div class="row">
            <span class="col-xs-11 no-padding-right">{{tip.openingStatement}}</span>
            <div #btn
                 class="btn-group pull-right col-xs-1 no-padding-left"
                 *ngIf="preview.observers.length && (edit.observers.length || delete.observers.length)">
                <span id="{{safetyTipMenuButtonId}}"
                      [attr.disabled]="block || undefined"
                      class="btn dropdown-toggle far fa-ellipsis-h no-padding"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                </span>
                <ul class="dropdown-menu">
                  <li *ngIf="preview.observers.length > 0">
                    <a (click)="!block && preview.emit({index:i,tip:tip})">Preview</a>
                  </li>
                  <li *ngIf="edit.observers.length > 0">
                    <a (click)="!block && edit.emit({index:i,tip:tip})">Edit</a>
                  </li>
                  <li *ngIf="delete.observers.length > 0">
                    <a (click)="!block && delete.emit({index:i,tip:tip})">Delete</a>
                  </li>
                </ul>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>
  </div>
</div>
