'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const _ = require("lodash");
angular.module('services.services.url-protection.controller', [])
    .controller('UrlProtectionController', ['$scope', 'classicAdconHelperService', '$translate', '$state', 'ttpUrlService', 'urlProtectionChartsService', 'dashboardCommonsService', 'urlProtectionChartDataFormatterService', 'capabilitiesService', 'CoreService', function ($scope, classicAdconHelperService, $translate, $state, ttpUrlService, urlProtectionChartsService, dashboardCommonsService, urlProtectionChartDataFormatterService, capabilitiesService, coreService) {
        const self = this;
        self.clickedURLsTabs = [
            {
                id: 'most-clicked-url',
                label: '$I18N_ADMINISTRATION_DASHBOARD_TAB_MOST_CLICKED_URLS',
                tpl: ''
            },
            {
                id: 'search-menu-items',
                label: '$I18N_ADMINISTRATION_DASHBOARD_TAB_TOP_USERS_BY_CLICK',
                tpl: ''
            }
        ];
        self.clickedURLsTabSelected = self.clickedURLsTabs[0];
        self.urlClickCountsPerDayPromise = {};
        self.urlClickCountsPerCategoryPromise = {};
        self.urlClickTopUrlsPromise = {};
        self.urlClickTopUsersPromise = {};
        self.userAwarenessCountsPromise = {};
        self.topUserAwarenessByUserPromise = {};
        self.userAwarenessCleanLinks = {};
        self.userAwarenessMaliciousLinks = {};
        self.topUserAwarenessUsers = {};
        self.urlTypes = [{
                key: ttpUrlService.constants.URL_TYPES.CLEAN,
                value: $translate.instant('$I18N_ADMINISTRATION_DASHBOARD_SELECT_CLEAN_LABEL')
            }, {
                key: ttpUrlService.constants.URL_TYPES.MALICIOUS,
                value: $translate.instant('$I18N_ADMINISTRATION_DASHBOARD_SELECT_MALICIOUS_LABEL')
            }];
        self.urlScannedPerDayChartSwitcher = {
            dataSets: [{
                    series: ttpUrlService.constants.ROUTE.ALL,
                    label: '$I18N_ADMINISTRATION_DASHBOARD_ALL_LABEL',
                    active: true
                }, {
                    series: ttpUrlService.constants.ROUTE.INBOUND,
                    label: '$I18N_ADMINISTRATION_DASHBOARD_INBOUND_LABEL'
                }, {
                    series: ttpUrlService.constants.ROUTE.OUTBOUND,
                    label: '$I18N_ADMINISTRATION_DASHBOARD_OUTBOUND_LABEL'
                }, {
                    series: ttpUrlService.constants.ROUTE.INTERNAL,
                    label: '$I18N_ADMINISTRATION_DASHBOARD_INTERNAL_LABEL'
                }]
        };
        self.mostClickedUrlsChartSwitcher = {
            dataSets: [{
                    series: ttpUrlService.constants.ROUTE.INBOUND,
                    label: '$I18N_ADMINISTRATION_DASHBOARD_INBOUND_LABEL',
                    active: true,
                    urlType: self.urlTypes[0]
                }]
        };
        self.topUsersByClickChartSwitcher = {
            dataSets: [{
                    series: ttpUrlService.constants.ROUTE.INBOUND,
                    label: '$I18N_ADMINISTRATION_DASHBOARD_INBOUND_LABEL',
                    active: true,
                    urlType: self.urlTypes[0]
                }]
        };
        self.urlsByCategoryChartSwitcher = {
            dataSets: [{
                    series: ttpUrlService.constants.ROUTE.INBOUND,
                    label: '$I18N_ADMINISTRATION_DASHBOARD_INBOUND_LABEL',
                    active: true,
                    urlType: self.urlTypes[0]
                }, {
                    series: ttpUrlService.constants.ROUTE.OUTBOUND,
                    label: '$I18N_ADMINISTRATION_DASHBOARD_OUTBOUND_LABEL',
                    urlType: self.urlTypes[0]
                }, {
                    series: ttpUrlService.constants.ROUTE.INTERNAL,
                    label: '$I18N_ADMINISTRATION_DASHBOARD_INTERNAL_LABEL',
                    urlType: self.urlTypes[0]
                }]
        };
        self.selectClickedURLsTab = selectClickedURLsTab;
        self.clickedURLsTabIsActive = clickedURLsTabIsActive;
        self.getUrlScannedPerDay = getUrlScannedPerDay;
        self.getUrlScannedPerType = getUrlScannedPerType;
        self.getUrlScannedPerCategory = getUrlScannedPerCategory;
        self.getMostClickedUrls = getMostClickedUrls;
        self.getTopUsersByClick = getTopUsersByClick;
        self.dataSetTypeIsClean = dataSetTypeIsClean;
        self.getUserAwarenessCleanLinks = getUserAwarenessCleanLinks;
        self.getUserAwarenessMaliciousLinks = getUserAwarenessMaliciousLinks;
        self.getTopUserAwarenessUsers = getTopUserAwarenessUsers;
        self.openLegacyManagedUrls = openLegacyManagedUrls;
        self.openNewManagedUrls = openNewManagedUrls;
        self.openUrlTools = openUrlTools;
        self.openNewTargetedThreatProtectionUrlprotectMonitoring = openNewTargetedThreatProtectionUrlprotectMonitoring;
        self.openLegacyPolicies = openLegacyPolicies;
        self.openPolicies = openPolicies;
        self.openLegacyDefinitions = openLegacyDefinitions;
        self.openUserAwarenessTemplates = openUserAwarenessTemplates;
        self.openMonitoredExternalDomain = openMonitoredExternalDomain;
        self.openLegacyTargetedThreatProtectionUrlprotectMonitoring = openLegacyTargetedThreatProtectionUrlprotectMonitoring;
        self.getUrlTypeName = getUrlTypeName;
        self.isDevelopmentOnly = isDevelopmentOnly;
        self.activate = activate;
        self.activate();
        self.hasUserMigratedPolicies = false;
        coreService
            .getMigratedPolicyInfo()
            .subscribe((migratedPolicy) => {
            self.hasUserMigratedPolicies = migratedPolicy;
        });
        function activate() {
            self.currentDateRange = dashboardCommonsService.getCurrentMonthDateRange();
            self.urlClickCountsPerDayPromise = ttpUrlService.getCounts(preparePayload({
                countBy: ttpUrlService.constants.COUNT_BY.DAY,
                sortBy: ttpUrlService.constants.SORT_BY.ATTRIBUTE
            }));
            self.urlClickCountsPerCategoryPromise = ttpUrlService.getCounts(preparePayload({
                countBy: ttpUrlService.constants.COUNT_BY.CATEGORY
            }));
            self.urlClickTopUrlsPromise = ttpUrlService.getCounts(preparePayload({
                countBy: ttpUrlService.constants.COUNT_BY.URL
            }));
            self.urlClickTopUsersPromise = ttpUrlService.getCounts(preparePayload({
                countBy: ttpUrlService.constants.COUNT_BY.USER
            }));
            self.userAwarenessCountsPromise = ttpUrlService.getUserAwarenessCounts(preparePayload());
            self.topUserAwarenessByUserPromise = ttpUrlService.getTopUserAwarenessUsers(preparePayload({
                scanResult: ttpUrlService.constants.URL_TYPES.MALICIOUS
            }));
            self.getTopUserAwarenessUsers();
            // check and set dev.only capability
            self.isDevelopmentOnly();
        }
        /**
         * Returns a line chart configuration for count url's per day
         *
         * @param activeSeries ( INBOUND / OUTBOUND / JOURNAL )
         * @returns the chart configuration with the requested series
         */
        function getUrlScannedPerDay(activeSeries) {
            const urlScannedPerDayConfig = urlProtectionChartsService.getUrlScannedPerDayConfig();
            return self.urlClickCountsPerDayPromise.then(function (response) {
                if (!response.err) {
                    const series = {
                        inbound() {
                            return [
                                {
                                    data: dashboardCommonsService.getDataForAllDays(self.currentDateRange.from, self.currentDateRange.to, dashboardCommonsService.mergeDataSetsByDate(response.first.clickCountsPerDay.routing[0].scansPerDay[0].dayCounts, response.first.clickCountsPerDay.routing[0].scansPerDay[1].dayCounts)),
                                    name: $translate.instant('$I18N_ADMINISTRATION_DASHBOARD_LEGEND_INBOUND_CLEAN'),
                                    showInLegend: true,
                                    color: dashboardCommonsService.getMcChartsTheme().defaultColours.inbound.primary
                                }, {
                                    data: dashboardCommonsService.getDataForAllDays(self.currentDateRange.from, self.currentDateRange.to, response.first.clickCountsPerDay.routing[0].scansPerDay[1].dayCounts),
                                    name: $translate.instant('$I18N_ADMINISTRATION_DASHBOARD_LEGEND_INBOUND_MALICIOUS'),
                                    showInLegend: true,
                                    color: dashboardCommonsService.getMcChartsTheme().defaultColours.inbound.alt,
                                    lineWidth: 2,
                                    dashStyle: 'dot'
                                }
                            ];
                        },
                        outbound() {
                            return [
                                {
                                    data: dashboardCommonsService.getDataForAllDays(self.currentDateRange.from, self.currentDateRange.to, dashboardCommonsService.mergeDataSetsByDate(response.first.clickCountsPerDay.routing[1].scansPerDay[0].dayCounts, response.first.clickCountsPerDay.routing[1].scansPerDay[1].dayCounts)),
                                    name: $translate.instant('$I18N_ADMINISTRATION_DASHBOARD_LEGEND_OUTBOUND_CLEAN'),
                                    showInLegend: true,
                                    color: dashboardCommonsService.getMcChartsTheme().defaultColours.outbound.primary
                                }, {
                                    data: dashboardCommonsService.getDataForAllDays(self.currentDateRange.from, self.currentDateRange.to, response.first.clickCountsPerDay.routing[1].scansPerDay[1].dayCounts),
                                    name: $translate.instant('$I18N_ADMINISTRATION_DASHBOARD_LEGEND_OUTBOUND_MALICIOUS'),
                                    showInLegend: true,
                                    color: dashboardCommonsService.getMcChartsTheme().defaultColours.outbound.alt,
                                    lineWidth: 2,
                                    dashStyle: 'dot'
                                }
                            ];
                        },
                        internal() {
                            return [
                                {
                                    data: dashboardCommonsService.getDataForAllDays(self.currentDateRange.from, self.currentDateRange.to, dashboardCommonsService.mergeDataSetsByDate(response.first.clickCountsPerDay.routing[2].scansPerDay[0].dayCounts, response.first.clickCountsPerDay.routing[2].scansPerDay[1].dayCounts)),
                                    name: $translate.instant('$I18N_ADMINISTRATION_DASHBOARD_LEGEND_INTERNAL_CLEAN'),
                                    showInLegend: true,
                                    color: dashboardCommonsService.getMcChartsTheme().defaultColours.internal.primary
                                }, {
                                    data: dashboardCommonsService.getDataForAllDays(self.currentDateRange.from, self.currentDateRange.to, response.first.clickCountsPerDay.routing[2].scansPerDay[1].dayCounts),
                                    name: $translate.instant('$I18N_ADMINISTRATION_DASHBOARD_LEGEND_INTERNAL_MALICIOUS'),
                                    showInLegend: true,
                                    color: dashboardCommonsService.getMcChartsTheme().defaultColours.internal.alt,
                                    lineWidth: 2,
                                    dashStyle: 'dot'
                                }
                            ];
                        },
                        all() {
                            return dashboardCommonsService.sortArrayByCleanAndMalicious(this.inbound().concat(this.outbound()).concat(this.internal()));
                        }
                    };
                    urlScannedPerDayConfig.series = series[activeSeries]();
                    return urlScannedPerDayConfig;
                }
                else {
                    ttpUrlService.getError().showErrorNotification(response);
                }
            }, function () {
                ttpUrlService.getError().showErrorNotification();
            });
        }
        /**
         * Returns a bar chart configuration for count url's per type
         *
         * @returns the chart configuration with the requested series
         */
        function getUrlScannedPerType() {
            const urlScannedPerTypeConfig = urlProtectionChartsService.getUrlsScannedPerCleanMaliciousConfig();
            return self.urlClickCountsPerDayPromise.then(function (response) {
                if (!response.err) {
                    const urlsScanned = urlProtectionChartDataFormatterService.groupUrlClickCountsPerDay(response);
                    urlScannedPerTypeConfig.series[0].data = [{
                            y: urlsScanned.inboundClean + urlsScanned.inboundMalicious,
                            color: dashboardCommonsService.getMcChartsTheme().defaultColours.inbound.primary
                        }, {
                            y: urlsScanned.outboundClean + urlsScanned.outboundMalicious,
                            color: dashboardCommonsService.getMcChartsTheme().defaultColours.outbound.primary
                        }, {
                            y: urlsScanned.internalClean + urlsScanned.internalMalicious,
                            color: dashboardCommonsService.getMcChartsTheme().defaultColours.internal.primary
                        }];
                    urlScannedPerTypeConfig.series[1].data = [{
                            y: urlsScanned.inboundMalicious,
                            color: dashboardCommonsService.getMcChartsTheme().defaultColours.inbound.alt
                        }, {
                            y: urlsScanned.outboundMalicious,
                            color: dashboardCommonsService.getMcChartsTheme().defaultColours.outbound.alt
                        }, {
                            y: urlsScanned.internalMalicious,
                            color: dashboardCommonsService.getMcChartsTheme().defaultColours.internal.alt
                        }];
                    urlScannedPerTypeConfig.tooltip.formatter = function () {
                        return dashboardCommonsService.getCustomTooltip(urlScannedPerTypeConfig, this.x);
                    };
                    return urlScannedPerTypeConfig;
                }
                else {
                    ttpUrlService.getError().showErrorNotification(response);
                }
            }, function () {
                ttpUrlService.getError().showErrorNotification();
            });
        }
        /**
         * Returns a doughnut chart configuration for count url's per category
         *
         * @param activeSeries
         * @returns the chart configuration with the requested series
         */
        function getUrlScannedPerCategory(activeSeries) {
            self.urlScannedPerCategoryConfig = urlProtectionChartsService.getDoughnutChartConfig();
            return self.urlClickCountsPerCategoryPromise.then(function (response) {
                if (!response.err) {
                    const series = {
                        inbound: [{
                                name: $translate.instant('$I18N_ADMINISTRATION_DASHBOARD_INBOUND_LABEL'),
                                data: dashboardCommonsService.getUrlCountsPerCategory(Object.assign(Object.assign({}, response.first.clickCountsPerCategory.routing[0].scansPerAttribute[0].attributeCounts), response.first.clickCountsPerCategory.routing[0].scansPerAttribute[1].attributeCounts))
                            }],
                        outbound: [{
                                name: $translate.instant('$I18N_ADMINISTRATION_DASHBOARD_OUTBOUND_LABEL'),
                                data: dashboardCommonsService.getUrlCountsPerCategory(Object.assign(Object.assign({}, response.first.clickCountsPerCategory.routing[1].scansPerAttribute[0].attributeCounts), response.first.clickCountsPerCategory.routing[1].scansPerAttribute[1].attributeCounts))
                            }],
                        internal: [{
                                name: $translate.instant('$I18N_ADMINISTRATION_DASHBOARD_INTERNAL_LABEL'),
                                data: dashboardCommonsService.getUrlCountsPerCategory(Object.assign(Object.assign({}, response.first.clickCountsPerCategory.routing[2].scansPerAttribute[0].attributeCounts), response.first.clickCountsPerCategory.routing[2].scansPerAttribute[1].attributeCounts))
                            }]
                    };
                    self.urlScannedPerCategoryConfig.colors = dashboardCommonsService.getMcChartsTheme().colorCharts[activeSeries];
                    self.urlScannedPerCategoryConfig.title.text = series[activeSeries][0].name + '<br/>' + "URL's";
                    if (dashboardCommonsService.isDataSetEmpty(series[activeSeries][0])) {
                        self.urlScannedPerCategoryConfig.title = '';
                    }
                    self.urlScannedPerCategoryConfig.series = series[activeSeries];
                    return self.urlScannedPerCategoryConfig;
                }
                else {
                    ttpUrlService.getError().showErrorNotification(response);
                }
            }, function () {
                ttpUrlService.getError().showErrorNotification();
            });
        }
        /**
         * Returns an horizontal bar chart configuration for get the most clicked url's
         *
         * @param activeSeries ( INBOUND / OUTBOUND / JOURNAL )
         * @param urlType ( CLEAN / MALICIOUS )
         * @returns the chart configuration with the requested series
         */
        function getMostClickedUrls(activeSeries, urlType) {
            const mostClickedUrlsConfig = urlProtectionChartsService.getMostClickedUrlsAndTopUsersPerClick();
            return self.urlClickTopUrlsPromise.then(function (response) {
                if (!response.err) {
                    const urlClickPerUrl = urlProtectionChartDataFormatterService.groupUrlClickCountsPerUrl(response);
                    const chartConfigs = {
                        inbound: {
                            clean: {
                                xAxis: {
                                    categories: urlClickPerUrl.inboundClean.categories.slice(0, 10)
                                },
                                series: [{
                                        data: urlClickPerUrl.inboundClean.series.slice(0, 10),
                                        showInLegend: false
                                    }]
                            },
                            malicious: {
                                xAxis: {
                                    categories: urlClickPerUrl.inboundMalicious.categories.slice(0, 10)
                                },
                                series: [{
                                        data: urlClickPerUrl.inboundMalicious.series.slice(0, 10),
                                        showInLegend: false
                                    }]
                            }
                        },
                        outbound: {
                            clean: {
                                xAxis: {
                                    categories: urlClickPerUrl.outboundClean.categories.slice(0, 10)
                                },
                                series: [{
                                        data: urlClickPerUrl.outboundClean.series.slice(0, 10),
                                        showInLegend: false
                                    }]
                            },
                            malicious: {
                                xAxis: {
                                    categories: urlClickPerUrl.outboundMalicious.categories.slice(0, 10)
                                },
                                series: [{
                                        data: urlClickPerUrl.outboundMalicious.series.slice(0, 10),
                                        showInLegend: false
                                    }]
                            }
                        },
                        internal: {
                            clean: {
                                xAxis: {
                                    categories: urlClickPerUrl.internalClean.categories.slice(0, 10)
                                },
                                series: [{
                                        data: urlClickPerUrl.internalClean.series.slice(0, 10),
                                        showInLegend: false
                                    }]
                            },
                            malicious: {
                                xAxis: {
                                    categories: urlClickPerUrl.internalMalicious.categories.slice(0, 10)
                                },
                                series: [{
                                        data: urlClickPerUrl.internalMalicious.series.slice(0, 10),
                                        showInLegend: false
                                    }]
                            }
                        }
                    };
                    mostClickedUrlsConfig.plotOptions.bar.colors = dashboardCommonsService.getMcChartsTheme().colorCharts[activeSeries];
                    return _.merge(mostClickedUrlsConfig, chartConfigs[activeSeries][urlType.key]);
                }
                else {
                    ttpUrlService.getError().showErrorNotification(response);
                }
            }, function () {
                ttpUrlService.getError().showErrorNotification();
            });
        }
        /**
         * Returns an horizontal bar chart configuration for get the top users by click
         *
         * @param activeSeries ( INBOUND / OUTBOUND / JOURNAL )
         * @param urlType ( CLEAN / MALICIOUS )
         * @returns the chart configuration with the requested series
         */
        function getTopUsersByClick(activeSeries, urlType) {
            const topUsersByClickConfig = urlProtectionChartsService.getMostClickedUrlsAndTopUsersPerClick();
            return self.urlClickTopUsersPromise.then(function (response) {
                if (!response.err) {
                    const topUsersPerClick = urlProtectionChartDataFormatterService.groupUrlClickCountsPerUser(response);
                    const chartConfigs = {
                        inbound: {
                            clean: {
                                xAxis: {
                                    categories: topUsersPerClick.inboundClean.categories.slice(0, 10)
                                },
                                series: [{
                                        data: topUsersPerClick.inboundClean.series.slice(0, 10),
                                        showInLegend: false
                                    }]
                            },
                            malicious: {
                                xAxis: {
                                    categories: topUsersPerClick.inboundMalicious.categories.slice(0, 10)
                                },
                                series: [{
                                        data: topUsersPerClick.inboundMalicious.series.slice(0, 10),
                                        showInLegend: false
                                    }]
                            }
                        },
                        outbound: {
                            clean: {
                                xAxis: {
                                    categories: topUsersPerClick.outboundClean.categories.slice(0, 10)
                                },
                                series: [{
                                        data: topUsersPerClick.outboundClean.series.slice(0, 10),
                                        showInLegend: false
                                    }]
                            },
                            malicious: {
                                xAxis: {
                                    categories: topUsersPerClick.outboundMalicious.categories.slice(0, 10)
                                },
                                series: [{
                                        data: topUsersPerClick.outboundMalicious.series.slice(0, 10),
                                        showInLegend: false
                                    }]
                            }
                        },
                        internal: {
                            clean: {
                                xAxis: {
                                    categories: topUsersPerClick.internalClean.categories.slice(0, 10)
                                },
                                series: [{
                                        data: topUsersPerClick.internalClean.series.slice(0, 10),
                                        showInLegend: false
                                    }]
                            },
                            malicious: {
                                xAxis: {
                                    categories: topUsersPerClick.internalMalicious.categories.slice(0, 10)
                                },
                                series: [{
                                        data: topUsersPerClick.internalMalicious.series.slice(0, 10),
                                        showInLegend: false
                                    }]
                            }
                        }
                    };
                    topUsersByClickConfig.plotOptions.bar.colors = dashboardCommonsService.getMcChartsTheme().colorCharts[activeSeries];
                    return Object.assign(Object.assign({}, topUsersByClickConfig), chartConfigs[activeSeries][urlType.key]);
                }
                else {
                    ttpUrlService.getError().showErrorNotification(response);
                }
            }, function () {
                ttpUrlService.getError().showErrorNotification();
            });
        }
        /**
         * Returns a doughnut chart configuration with the user awareness links data
         *
         * @returns the chart configuration with the requested series
         */
        function getUserAwarenessCleanLinks() {
            const userAwarenessCleanLinksConfig = urlProtectionChartsService.getDoughnutChartConfig();
            return self.userAwarenessCountsPromise.then(function (response) {
                if (!response.err) {
                    userAwarenessCleanLinksConfig.series = [{
                            name: $translate.instant('$I18N_ADMINISTRATION_DASHBOARD_CLEAN_LABEL'),
                            data: filterPointsPerType(dashboardCommonsService.getUrlCountsPerCategory(response.first)).clean
                        }];
                    userAwarenessCleanLinksConfig.title.text = $translate.instant('$I18N_ADMINISTRATION_DASHBOARD_CLEAN_LABEL') + '<br/>' + $translate.instant('$I18N_ADMINISTRATION_DASHBOARD_LINKS_LABEL');
                    if (dashboardCommonsService.isYaxisEmpty(userAwarenessCleanLinksConfig.series[0].data[0].y) && dashboardCommonsService.isYaxisEmpty(userAwarenessCleanLinksConfig.series[0].data[1].y)) {
                        userAwarenessCleanLinksConfig.title.text = $translate.instant('$I18N_COMMON_DASHBOARD_NO_DATA_TO_SHOW_LABEL');
                        userAwarenessCleanLinksConfig.title.style = {
                            color: '#60606a',
                            fontSize: '12px',
                            fontWeight: 'bold'
                        };
                    }
                    return userAwarenessCleanLinksConfig;
                }
                else {
                    ttpUrlService.getError().showErrorNotification(response);
                }
            }, function () {
                ttpUrlService.getError().showErrorNotification();
            });
        }
        function getUserAwarenessMaliciousLinks() {
            const userAwarenessMaliciousLinksConfig = urlProtectionChartsService.getDoughnutChartConfig();
            return self.userAwarenessCountsPromise.then(function (response) {
                if (!response.err) {
                    userAwarenessMaliciousLinksConfig.series = [{
                            name: $translate.instant('$I18N_ADMINISTRATION_DASHBOARD_MALICIOUS_LABEL'),
                            data: filterPointsPerType(dashboardCommonsService.getUrlCountsPerCategory(response.first)).malicious
                        }];
                    userAwarenessMaliciousLinksConfig.title.text = $translate.instant('$I18N_ADMINISTRATION_DASHBOARD_MALICIOUS_LABEL') + '<br/>' + $translate.instant('$I18N_ADMINISTRATION_DASHBOARD_LINKS_LABEL');
                    if (dashboardCommonsService.isYaxisEmpty(userAwarenessMaliciousLinksConfig.series[0].data[0].y) && dashboardCommonsService.isYaxisEmpty(userAwarenessMaliciousLinksConfig.series[0].data[1].y)) {
                        userAwarenessMaliciousLinksConfig.title.text = $translate.instant('$I18N_COMMON_DASHBOARD_NO_DATA_TO_SHOW_LABEL');
                        userAwarenessMaliciousLinksConfig.title.style = {
                            color: '#60606a',
                            fontSize: '12px',
                            fontWeight: 'bold'
                        };
                    }
                    return userAwarenessMaliciousLinksConfig;
                }
                else {
                    ttpUrlService.getError().showErrorNotification(response);
                }
            }, function () {
                ttpUrlService.getError().showErrorNotification();
            });
        }
        function getTopUserAwarenessUsers() {
            self.topUserAwarenessByUserPromise.then(function (response) {
                if (!response.err) {
                    let topUserAwarenessLimit = 0;
                    const userCounts = response.first.userCounts;
                    self.topUserAwarenessUsers = {};
                    _.forOwn(userCounts, function (value, key) {
                        if (topUserAwarenessLimit <= 9) {
                            _.assign(self.topUserAwarenessUsers, _.pick(userCounts, key));
                        }
                        topUserAwarenessLimit++;
                    });
                }
                else {
                    ttpUrlService.getError().showErrorNotification(response);
                }
            }, function () {
                ttpUrlService.getError().showErrorNotification();
            });
        }
        /**
         * Merges a given payload with a default one
         *
         * @param payload, contains the payload for request data to the url protect endpoint
         * @returns a payload with custom and default properties
         */
        function preparePayload(payload) {
            const defaultPayload = {
                from: self.currentDateRange.from,
                to: self.currentDateRange.to,
                sortBy: ttpUrlService.constants.SORT_BY.COUNT,
                sortOrder: ttpUrlService.constants.SORT_ORDER.DESC
            };
            if (payload) {
                return _.merge(defaultPayload, payload);
            }
            return defaultPayload;
        }
        function selectClickedURLsTab(tab) {
            return self.clickedURLsTabSelected = tab;
        }
        function clickedURLsTabIsActive(tab) {
            return self.clickedURLsTabSelected == tab ? 'active' : '';
        }
        function dataSetTypeIsClean(urlType) {
            return (urlType === null || urlType === void 0 ? void 0 : urlType.key) === ttpUrlService.constants.URL_TYPES.CLEAN;
        }
        function filterPointsPerType(points) {
            const filteredItems = {
                clean: [],
                malicious: []
            };
            _.forEach(points, function (item) {
                if (item.name.toLowerCase().includes(ttpUrlService.constants.URL_TYPES.CLEAN)) {
                    filteredItems.clean.push(item);
                }
                else {
                    filteredItems.malicious.push(Object.assign(Object.assign({}, item), { color: dashboardCommonsService.getMcChartsTheme().defaultColours.outbound.primary }));
                }
            });
            if (filteredItems.clean.length > 0) {
                filteredItems.clean[0].color = dashboardCommonsService.getMcChartsTheme().defaultColours.inbound.primary;
                filteredItems.clean[1].color = dashboardCommonsService.getMcChartsTheme().defaultColours.outbound.primary;
            }
            if (filteredItems.malicious.length > 0) {
                filteredItems.malicious[0].color = dashboardCommonsService.getMcChartsTheme().defaultColours.inbound.primary;
                filteredItems.malicious[1].color = dashboardCommonsService.getMcChartsTheme().defaultColours.outbound.primary;
            }
            return filteredItems;
        }
        function getUrlTypeName(urlType) {
            return urlType === ttpUrlService.constants.URL_TYPES.MALICIOUS ? $translate.instant('$I18N_ADMINISTRATION_DASHBOARD_MALICIOUS_LABEL') : $translate.instant('$I18N_ADMINISTRATION_DASHBOARD_CLEAN_LABEL');
        }
        // check if for Dev.only flag
        function isDevelopmentOnly() {
            if (capabilitiesService.areCapabilitiesLoaded()) {
                return capabilitiesService.hasCapability('Development.Only');
            }
            // if they are not loaded for some reason ... load them and check for dev flag
            capabilitiesService.getCapabilities().then(function () {
                return capabilitiesService.hasCapability('Development.Only');
            });
        }
        function openLegacyManagedUrls() {
            $state.go('legacy.urlprotect-managedurls');
        }
        function openNewManagedUrls() {
            $state.go('url-protection-managed-urls');
        }
        function openUrlTools() {
            $state.go('urlprotect-url-tools');
        }
        function openNewTargetedThreatProtectionUrlprotectMonitoring() {
            $state.go('dashboard-url-protection-logs');
        }
        function openLegacyPolicies() {
            $state.go('legacy.dashboard-url-protection-policies');
        }
        function openPolicies() {
            $state.go('url-protection-policies');
        }
        function openLegacyDefinitions() {
            $state.go('legacy.dashboard-url-protection-definitions');
        }
        function openUserAwarenessTemplates() {
            $state.go('awareness-list');
        }
        function openMonitoredExternalDomain() {
            $state.go('custom-monitored-external-domains');
        }
        function openLegacyTargetedThreatProtectionUrlprotectMonitoring() {
            $state.go('legacy.dashboard-url-protection-logs');
        }
    }]);
