"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("mimecast-web-components/src/app/components/tree/tree"); //files
require("./group-selector.controller"); //backup-restore-group-selector.controller
require("./group-selector.service"); //backup-restore-group-selector.service
// html and css 
require("./group-selector.tpl.html");
(function () {
    'use strict';
    /* ======================================================== *\

      = group-selector

   \* ======================================================== */
    angular.module('backup-restore-group-selector', ['files', 'backup-restore-group-selector.controller', 'backup-restore-group-selector.service']);
}());
