"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewRuleModule = void 0;
const view_rule_component_1 = require("./view-rule/view-rule.component");
const view_rule_details_component_1 = require("./view-rule-details/view-rule-details.component");
const simple_detail_component_1 = require("./view-rule-details/simple-detail/simple-detail.component");
const DECLARATIONS = [view_rule_component_1.ViewRuleComponent, view_rule_details_component_1.ViewRuleDetailsComponent, simple_detail_component_1.SimpleDetailComponent];
class ViewRuleModule {
}
exports.ViewRuleModule = ViewRuleModule;
