"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RuleDeleteNotificationModalComponent = void 0;
class RuleDeleteNotificationModalComponent {
    constructor(overlayRef, overlayData) {
        this.overlayRef = overlayRef;
        this.overlayData = overlayData;
        this.ruleName = this.overlayData.ruleName;
        this.queueName = this.overlayData.queueName;
        this.lexiconName = this.overlayData.lexiconName;
    }
    close(confirmed) {
        this.overlayRef.close(confirmed);
    }
}
exports.RuleDeleteNotificationModalComponent = RuleDeleteNotificationModalComponent;
