"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const static_1 = require("@angular/upgrade/static");
require("app/services/services/onedrive/aside/aside");
const containers_1 = require("./containers");
const backup_component_1 = require("./wizard/backup/backup.component");
const backup_browser_component_1 = require("./components/backup-browser/backup-browser.component");
angular
    .module('onedrive.backups', ['onedrive-aside'])
    .controller('OnedriveBackupsController', function () { })
    .directive('mcOnedriveBackups', static_1.downgradeComponent({
    component: containers_1.OneDriveBackupsPageComponent
}))
    .directive('mcOnedriveWizardBackup', static_1.downgradeComponent({
    component: backup_component_1.BackupWizardComponent
}))
    .directive('mcOnedriveBackupBrowser', static_1.downgradeComponent({ component: backup_browser_component_1.BackupBrowserComponent }))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        const breadcrumbs = [];
        $stateProvider
            .state('one-drive-backups', {
            url: '/onedrive/backups',
            data: {
                capabilities: 'OneDrive.Backup && Permission.ONEDRIVE_BACKUP_READ && Temporary.Services.OneDrive',
                asideTitle: '$I18N_ONE_DRIVE.SUB_MENU_TITLE',
                isFixedLayout: false,
                checkProgress: false,
                tabId: 'one-drive',
                tabTitle: '$I18N_ONE_DRIVE.TAB_TITLE',
                breadcrumbs: ['$I18N_ONEDRIVE_BACKUPS.TITLE'],
                tabReload: true
            },
            views: {
                main: {
                    template: '<mc-onedrive-backups></mc-onedrive-backups>',
                    controller: 'OnedriveBackupsController'
                },
                aside: {
                    templateUrl: 'services/services/onedrive/aside/aside.tpl.html'
                }
            }
        })
            .state('onedrive-backup-wizard', {
            data: {
                capabilities: 'OneDrive.Backup && Permission.ONEDRIVE_BACKUP_READ && Temporary.Services.OneDrive',
                tabId: 'one-drive',
                tabTitle: '$I18N_ONE_DRIVE.SUB_MENU_TITLE',
                breadcrumbs,
                tabReload: false
            },
            views: {
                main: {
                    template: '<mc-onedrive-wizard-backup></mc-onedrive-wizard-backup>',
                    controller: 'OnedriveBackupsController'
                }
            },
            params: { backup: undefined, returnActions: [] },
            onEnter: [
                '$state',
                '$stateParams',
                function ($state, $stateParams) {
                    breadcrumbs.length = 0;
                    breadcrumbs.push('$I18N_ONEDRIVE_BACKUPS.SERVICES_TITLE');
                    breadcrumbs.push('$I18N_ONE_DRIVE.SUB_MENU_TITLE');
                    breadcrumbs.push('$I18N_ONEDRIVE_BACKUPS.TITLE');
                    if (!$stateParams.backup) {
                        breadcrumbs.push('$I18N_ONEDRIVE_BACKUP_WIZARD.CREATE_TITLE');
                    }
                    else {
                        breadcrumbs.push('$I18N_ONEDRIVE_BACKUP_WIZARD.EDIT_TITLE');
                    }
                }
            ]
        })
            .state('onedrive-browser', {
            url: '/onedrive/backup',
            params: { backup: null },
            data: {
                capabilities: 'OneDrive.Backup && Permission.ONEDRIVE_BACKUP_READ && Temporary.Services.OneDrive',
                asideTitle: '$I18N_ONE_DRIVE.SUB_MENU_TITLE',
                isFixedLayout: false,
                checkProgress: false,
                tabId: 'one-drive',
                tabTitle: '$I18N_ONE_DRIVE.TAB_TITLE',
                breadcrumbs,
                tabReload: false
            },
            views: {
                main: {
                    template: '<mc-onedrive-backup-browser></mc-onedrive-backup-browser>',
                    controller: 'OnedriveBackupsController'
                },
                aside: {
                    templateUrl: 'services/services/onedrive/aside/aside.tpl.html'
                }
            },
            onEnter: [
                '$state',
                '$stateParams',
                function ($state, $stateParams) {
                    if (!$stateParams.backup) {
                        $state.go('one-drive-backups');
                    }
                    breadcrumbs.length = 0;
                    breadcrumbs.push('$I18N_ONEDRIVE_BACKUPS.SERVICES_TITLE');
                    breadcrumbs.push('$I18N_ONE_DRIVE.SUB_MENU_TITLE');
                    breadcrumbs.push('$I18N_ONEDRIVE_BACKUPS.TITLE');
                    breadcrumbs.push($stateParams.backup.name);
                }
            ]
        });
    }
]);
