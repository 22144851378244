"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NewFeaturesModalComponent = void 0;
class NewFeaturesModalComponent {
    constructor(overlayRef) {
        this.overlayRef = overlayRef;
    }
    close() {
        this.overlayRef.close(false);
    }
}
exports.NewFeaturesModalComponent = NewFeaturesModalComponent;
