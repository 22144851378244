"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttributeDeleteModalComponent = void 0;
class AttributeDeleteModalComponent {
    constructor(store, overlayRef) {
        this.store = store;
        this.overlayRef = overlayRef;
    }
    onClose() {
        this.overlayRef.close(false);
    }
    onDelete() {
        this.overlayRef.close(true);
    }
}
exports.AttributeDeleteModalComponent = AttributeDeleteModalComponent;
