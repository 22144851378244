"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgLocationsDeleteSidebarCloseAction = exports.SwgLocationsDeleteSidebarOpenAction = exports.SwgLocationsEditAddSidebarCloseAction = exports.SwgLocationsEditAddSidebarOpenAction = exports.SwgLocationSidebarTypes = void 0;
var SwgLocationSidebarTypes;
(function (SwgLocationSidebarTypes) {
    SwgLocationSidebarTypes["SWG_LOCATION_EDIT_ADD_SIDBAR_OPEN"] = "[SwgLocationSidebar] Locations Edit Sidebar Open";
    SwgLocationSidebarTypes["SWG_LOCATION_EDIT_ADD_SIDBAR_CLOSE"] = "[SwgLocationSidebar] Locations Edit Sidebar Close";
    SwgLocationSidebarTypes["SWG_LOCATION_DELETE_SIDBAR_OPEN"] = "[SwgLocationSidebar] Locations Delete Sidebar Open";
    SwgLocationSidebarTypes["SWG_LOCATION_DELETE_SIDBAR_CLOSE"] = "[SwgLocationSidebar] Locations Delete Sidebar Close";
})(SwgLocationSidebarTypes = exports.SwgLocationSidebarTypes || (exports.SwgLocationSidebarTypes = {}));
class SwgLocationsEditAddSidebarOpenAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgLocationSidebarTypes.SWG_LOCATION_EDIT_ADD_SIDBAR_OPEN;
    }
}
exports.SwgLocationsEditAddSidebarOpenAction = SwgLocationsEditAddSidebarOpenAction;
class SwgLocationsEditAddSidebarCloseAction {
    constructor() {
        this.type = SwgLocationSidebarTypes.SWG_LOCATION_EDIT_ADD_SIDBAR_CLOSE;
    }
}
exports.SwgLocationsEditAddSidebarCloseAction = SwgLocationsEditAddSidebarCloseAction;
class SwgLocationsDeleteSidebarOpenAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgLocationSidebarTypes.SWG_LOCATION_DELETE_SIDBAR_OPEN;
    }
}
exports.SwgLocationsDeleteSidebarOpenAction = SwgLocationsDeleteSidebarOpenAction;
class SwgLocationsDeleteSidebarCloseAction {
    constructor() {
        this.type = SwgLocationSidebarTypes.SWG_LOCATION_DELETE_SIDBAR_CLOSE;
    }
}
exports.SwgLocationsDeleteSidebarCloseAction = SwgLocationsDeleteSidebarCloseAction;
