"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tableSectionSearch = exports.tableColumnsStartHidden = exports.tableColumnsToIgnore = exports.tableSectionFilters = exports.tableColumnsAlwaysVisible = exports.exportColumns = exports.tableColumns = exports.tableSelectColumn = void 0;
exports.tableSelectColumn = 'select';
exports.tableColumns = [
    'select',
    'fromEnv',
    'to',
    'routing',
    'messageType',
    'info',
    'created',
    'attempts',
    'right-column'
];
exports.exportColumns = [
    'from',
    'to',
    'routing',
    'messageType',
    'info',
    'created',
    'attempts'
];
exports.tableColumnsAlwaysVisible = [
    'from',
    'to',
    'routing',
    'messageType',
    'info',
    'created',
    'attempts'
];
exports.tableSectionFilters = [
    {
        name: 'route',
        filters: ['inbound', 'outbound', 'internal']
    }
];
exports.tableColumnsToIgnore = ['select'];
exports.tableColumnsStartHidden = [];
exports.tableSectionSearch = {
    field: 'search',
    header: 'header',
    options: ['fromAddress', 'toAddress']
};
