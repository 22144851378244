"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getClientId = exports.getProducts = exports.getRoles = exports.getApiApplication = exports.getApiApplicationNameDisabled = exports.getMode = exports.getStatus = exports.getSettings = exports.getDetails = exports.getAcceptTerms = exports.getIdentifier = exports.reducer = exports.initialState = void 0;
const actions = require("../../actions");
exports.initialState = {
    applicationNameDisabled: false,
    acceptTerms: false,
    identifier: {
        id: '',
        applicationType: 'api'
    },
    details: {
        applicationName: '',
        description: '',
        category: null,
        service: false,
        usage: null,
        roleId: null,
        gatewayAppProducts: null,
        products: null
    },
    settings: {
        developerEmail: '',
        developerName: '',
        newsSubscription: false
    },
    status: {
        active: true
    },
    mode: 'CREATE',
    roles: [],
    products: [],
    clientId: ''
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.ApiGatewayListActionTypes.OpenCreateApiApplication:
            return exports.initialState;
        case actions.ApiGatewayListActionTypes.OpenEditApiApplicationV3:
            return {
                applicationNameDisabled: false,
                acceptTerms: false,
                identifier: {
                    id: action.payload.id,
                    applicationType: action.payload.applicationType
                },
                details: {
                    applicationName: action.payload.applicationName,
                    description: action.payload.description,
                    category: action.payload.category,
                    service: action.payload.service,
                    usage: action.payload.usage,
                    roleId: action.payload.roleId,
                    gatewayAppProducts: action.payload.gatewayAppProducts,
                    products: action.payload.products
                },
                settings: {
                    developerEmail: action.payload.developerEmail,
                    developerName: action.payload.developerName,
                    newsSubscription: action.payload.newsSubscription
                },
                status: {
                    active: action.payload.active
                },
                mode: 'EDIT',
                roles: [],
                products: [],
                clientId: ''
            };
        case actions.GatewayActionTypes.InputGatewayApplicationAcceptTerms:
            return Object.assign(Object.assign({}, state), { acceptTerms: action.payload });
        case actions.GatewayActionTypes.InputGatewayApplicationDetails:
            return Object.assign(Object.assign({}, state), { details: action.payload });
        case actions.GatewayActionTypes.InputGatewayApplicationSettings:
            return Object.assign(Object.assign({}, state), { settings: action.payload });
        case actions.GatewayActionTypes.InputGatewayApplicationStatus:
            return Object.assign(Object.assign({}, state), { status: action.payload });
        case actions.GatewayActionTypes.CloseGatewayApplication:
            return exports.initialState;
        case actions.ApiTechPartnerListActionTypes.OpenApiApplication:
            return Object.assign(Object.assign({}, state), { applicationNameDisabled: true, acceptTerms: false, details: Object.assign(Object.assign({}, state.details), { applicationName: action.payload.apiName, category: action.payload.category, service: action.payload.service }) });
        case actions.GatewayActionTypes.GetGatewayApplicationRoleListSuccess:
            return Object.assign(Object.assign({}, state), { roles: action.data.roles });
        case actions.GatewayActionTypes.GetGatewayApplicationProductListSuccess:
            return Object.assign(Object.assign({}, state), { products: action.data.products });
        case actions.ApiGatewayListActionTypes.GetCredentialsSuccess:
            return Object.assign(Object.assign({}, state), { clientId: action.payload.clientId });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getIdentifier = (state) => state.identifier;
exports.getIdentifier = getIdentifier;
const getAcceptTerms = (state) => state.acceptTerms;
exports.getAcceptTerms = getAcceptTerms;
const getDetails = (state) => state.details;
exports.getDetails = getDetails;
const getSettings = (state) => state.settings;
exports.getSettings = getSettings;
const getStatus = (state) => state.status;
exports.getStatus = getStatus;
const getMode = (state) => state.mode;
exports.getMode = getMode;
const getApiApplicationNameDisabled = (state) => state.applicationNameDisabled;
exports.getApiApplicationNameDisabled = getApiApplicationNameDisabled;
const getApiApplication = (state) => {
    return {
        id: state.identifier.id,
        acceptTerms: state.acceptTerms,
        applicationType: state.identifier.applicationType,
        applicationName: state.details.applicationName,
        description: state.details.description,
        category: state.details.category,
        service: state.details.service,
        usage: state.details.usage,
        roleId: state.details.roleId,
        developerEmail: state.settings.developerEmail,
        developerName: state.settings.developerName,
        newsSubscription: state.settings.newsSubscription,
        active: state.status.active,
        gatewayAppProducts: state.details.gatewayAppProducts,
        products: state.details.products
    };
};
exports.getApiApplication = getApiApplication;
const getRoles = (state) => {
    return state.roles.map(role => ({ value: role.id, label: role.roleName }));
};
exports.getRoles = getRoles;
const getProducts = (state) => {
    return state.products.map(product => ({ value: product.id, label: product.name }));
};
exports.getProducts = getProducts;
const getClientId = (state) => {
    return state.clientId;
};
exports.getClientId = getClientId;
