"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgPolicyTtpChameleonStepComponent = void 0;
const core_1 = require("@angular/core");
const operators_1 = require("rxjs/operators");
class SwgPolicyTtpChameleonStepComponent {
    constructor(fb, stateService) {
        this.fb = fb;
        this.stateService = stateService;
        this.updateTtpChameleonSettings = new core_1.EventEmitter();
        this.chamelonSettingsLevelOptions = [
            { key: 'warn', value: 'Warn' },
            { key: 'block', value: 'Block' }
        ];
    }
    ngOnInit() {
        this.ttpChameleonSettingsForm = this.fb.group({
            chameleonEnabled: this.ttpChameleonSettings.chameleonEnabled,
            chameleonMode: this.ttpChameleonSettings.chameleonMode
        });
        this.ttpFormSubscription = this.ttpChameleonSettingsForm.valueChanges
            .pipe(operators_1.debounceTime(200))
            .subscribe(() => {
            this.updateStore();
        });
    }
    updateStore() {
        this.updateTtpChameleonSettings.emit(this.ttpChameleonSettingsForm.value);
    }
    isValid() {
        return true;
    }
    goToChameleonPage() {
        this.stateService.$state.go('custom-monitored-external-domains');
    }
    ngOnDestroy() {
        this.ttpFormSubscription.unsubscribe();
    }
}
exports.SwgPolicyTtpChameleonStepComponent = SwgPolicyTtpChameleonStepComponent;
