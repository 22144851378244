"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
/**
 * Created by dgomez on 21/09/2016.
 */
angular.module('export-download-aside.service', [])
    .factory('exportDownloadAsideService', ['$aside', function ($aside) {
        return {
            openExportDownloadAside
        };
        function openExportDownloadAside(recoveryTaskId, downloadLinks, mailbox) {
            const asideInstance = $aside.open({
                templateUrl: 'services/services/backup/components/export-download-aside/export-download-aside.tpl.html',
                placement: 'right',
                size: 'lg',
                backdrop: true,
                windowClass: 'hidden-xs',
                backdropClass: 'hidden-xs',
                controller: angular.noop,
                resolve: {
                    dismissFunction() {
                        return function (selectedEmail) {
                            asideInstance.close(selectedEmail);
                        };
                    },
                    downloadLinks() {
                        return downloadLinks;
                    },
                    recoveryTaskId() {
                        return recoveryTaskId;
                    },
                    mailbox() {
                        return mailbox;
                    }
                }
            });
            return asideInstance.result;
        }
    }]);
