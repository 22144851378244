"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PolicyWizardEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const actions = require("../../actions/policy-wizard/wizard.actions");
const notification_actions_1 = require("../../../components/notification/actions/notification.actions");
const policies_actions_1 = require("../../actions/policies.actions");
const cancel_policy_modal_component_1 = require("../../components/cancel-policy-modal/cancel-policy-modal.component");
class PolicyWizardEffects {
    constructor(actions$, policyApiService, stateService, modalService, translateService, store) {
        this.actions$ = actions$;
        this.policyApiService = policyApiService;
        this.stateService = stateService;
        this.modalService = modalService;
        this.translateService = translateService;
        this.store = store;
        this.loadPolicy$ = this.actions$.pipe(effects_1.ofType(actions.LOAD_POLICY), operators_1.switchMap((action) => {
            return this.policyApiService.getPolicy(action.payload).pipe(operators_1.map((response) => {
                return new actions.LoadPolicySuccessAction(response);
            }), operators_1.catchError(response => {
                return rxjs_1.of(new actions.LoadPolicyFailAction(response.fail[0].errors[0].message));
            }));
        }));
        this.loadPolicyFail$ = this.actions$.pipe(effects_1.ofType(actions.LOAD_POLICY_FAIL), operators_1.switchMap((action) => {
            const notificationFailAction = new notification_actions_1.NotificationShowFailAction(action.payload);
            this.store.dispatch(new policies_actions_1.IdentitySaveNotificationAction(notificationFailAction));
            this.stateService.$state.go('identity-dashboard', { tabName: 'authenticationPolicies' });
            return rxjs_1.EMPTY;
        }));
        this.create$ = this.actions$.pipe(effects_1.ofType(actions.CREATE), operators_1.switchMap((action) => {
            return this.policyApiService.createPolicy(action.request).pipe(operators_1.map(() => {
                return new actions.CreateSuccessAction();
            }), operators_1.catchError(response => {
                return rxjs_1.of(new actions.CreateFailAction(response.fail[0].errors[0].message));
            }));
        }));
        this.createSuccess = this.actions$.pipe(effects_1.ofType(actions.CREATE_SUCCESS), operators_1.switchMap(() => {
            const action = new notification_actions_1.NotificationShowSuccessAction(this.translateService.instant('$I18N_IDENTITY_CONTAINER.POLICIES_LIST.POLICY_CREATED_MESSAGE'));
            this.store.dispatch(new policies_actions_1.IdentitySaveNotificationAction(action));
            this.stateService.$state.go('identity-dashboard', { tabName: 'authenticationPolicies' });
            return rxjs_1.EMPTY;
        }));
        this.createFail = this.actions$.pipe(effects_1.ofType(actions.CREATE_FAIL), operators_1.map((action) => new notification_actions_1.NotificationShowFailAction(action.payload)));
        this.update$ = this.actions$.pipe(effects_1.ofType(actions.UPDATE), operators_1.switchMap((action) => {
            return this.policyApiService.updatePolicy(action.request).pipe(operators_1.map(() => {
                return new actions.UpdateSuccessAction();
            }), operators_1.catchError(response => {
                return rxjs_1.of(new actions.UpdateFailAction(response.fail[0].errors[0].message));
            }));
        }));
        this.updateSuccess$ = this.actions$.pipe(effects_1.ofType(actions.UPDATE_SUCCESS), operators_1.switchMap(() => {
            const action = new notification_actions_1.NotificationShowSuccessAction(this.translateService.instant('$I18N_IDENTITY_CONTAINER.POLICIES_LIST.POLICY_UPDATED_MESSAGE'));
            this.store.dispatch(new policies_actions_1.IdentitySaveNotificationAction(action));
            this.stateService.$state.go('identity-dashboard', { tabName: 'authenticationPolicies' });
            return rxjs_1.EMPTY;
        }));
        this.updateFail$ = this.actions$.pipe(effects_1.ofType(actions.UPDATE_FAIL), operators_1.map((action) => new notification_actions_1.NotificationShowFailAction(action.payload)));
        this.showPolicyCancelModal$ = this.actions$.pipe(effects_1.ofType(actions.CANCEL_CONFIRMATION_MODAL_OPEN), operators_1.map((action) => action.payload), operators_1.switchMap((cancelSource) => {
            this.cancelPolicyModalInstance = this.modalService.open(cancel_policy_modal_component_1.IdentityCancelPolicyModalComponent, {
                size: 'md',
                hasBackdrop: true,
                data: cancelSource
            });
            return rxjs_1.EMPTY;
        }));
        this.closeIdentityPolicyCloseModal$ = this.actions$.pipe(effects_1.ofType(actions.CANCEL_CONFIRMATION_MODAL_NO_RESPONSE), operators_1.filter(() => !!this.cancelPolicyModalInstance), operators_1.switchMap(() => {
            this.cancelPolicyModalInstance.close();
            this.cancelPolicyModalInstance = undefined;
            return rxjs_1.EMPTY;
        }));
        this.showPolicyList$ = this.actions$.pipe(effects_1.ofType(actions.CANCEL_CONFIRMATION_MODAL_YES_RESPONSE), operators_1.filter(() => !!this.cancelPolicyModalInstance), operators_1.switchMap(() => {
            this.cancelPolicyModalInstance.close();
            this.cancelPolicyModalInstance = undefined;
            this.stateService.$state.go('identity-dashboard', { tabName: 'authenticationPolicies' });
            return rxjs_1.EMPTY;
        }));
    }
}
__decorate([
    effects_1.Effect()
], PolicyWizardEffects.prototype, "loadPolicy$", void 0);
__decorate([
    effects_1.Effect()
], PolicyWizardEffects.prototype, "loadPolicyFail$", void 0);
__decorate([
    effects_1.Effect()
], PolicyWizardEffects.prototype, "create$", void 0);
__decorate([
    effects_1.Effect()
], PolicyWizardEffects.prototype, "createSuccess", void 0);
__decorate([
    effects_1.Effect()
], PolicyWizardEffects.prototype, "createFail", void 0);
__decorate([
    effects_1.Effect()
], PolicyWizardEffects.prototype, "update$", void 0);
__decorate([
    effects_1.Effect()
], PolicyWizardEffects.prototype, "updateSuccess$", void 0);
__decorate([
    effects_1.Effect()
], PolicyWizardEffects.prototype, "updateFail$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], PolicyWizardEffects.prototype, "showPolicyCancelModal$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], PolicyWizardEffects.prototype, "closeIdentityPolicyCloseModal$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], PolicyWizardEffects.prototype, "showPolicyList$", void 0);
exports.PolicyWizardEffects = PolicyWizardEffects;
