"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonitorDomainFailAction = exports.MonitorDomainSuccessAction = exports.MonitorDomainAction = exports.BlockSenderFailAction = exports.BlockSenderSuccessAction = exports.BlockSenderAction = exports.PermitSenderFailAction = exports.PermitSenderSuccessAction = exports.PermitSenderAction = exports.HideLogDetailsAction = exports.GetLogDetailsFailAction = exports.GetLogDetailsSuccessAction = exports.GetLogDetailsAction = exports.MONITOR_DOMAIN_FAIL = exports.MONITOR_DOMAIN_SUCCESS = exports.MONITOR_DOMAIN = exports.BLOCK_SENDER_FAIL = exports.BLOCK_SENDER_SUCCESS = exports.BLOCK_SENDER = exports.PERMIT_SENDER_FAIL = exports.PERMIT_SENDER_SUCCESS = exports.PERMIT_SENDER = exports.HIDE_LOG_DETAILS = exports.GET_LOG_DETAILS_FAIL = exports.GET_LOG_DETAILS_SUCCESS = exports.GET_LOG_DETAILS = void 0;
exports.GET_LOG_DETAILS = '[TTP IP] Get Log Details';
exports.GET_LOG_DETAILS_SUCCESS = '[TTP IP] Get Log Details Success';
exports.GET_LOG_DETAILS_FAIL = '[TTP IP] Get Log Details Fail';
exports.HIDE_LOG_DETAILS = '[TTP IP] Hide Log Details';
exports.PERMIT_SENDER = '[TTP IP] Permit Sender';
exports.PERMIT_SENDER_SUCCESS = '[TTP IP] Permit Sender Success';
exports.PERMIT_SENDER_FAIL = '[TTP IP] Permit Sender Fail';
exports.BLOCK_SENDER = '[TTP IP] Block Sender';
exports.BLOCK_SENDER_SUCCESS = '[TTP IP] Block Sender Success';
exports.BLOCK_SENDER_FAIL = '[TTP IP] Block Sender Fail';
exports.MONITOR_DOMAIN = '[TTP IP] Monitor Domain';
exports.MONITOR_DOMAIN_SUCCESS = '[TTP IP] Monitor Domain Success';
exports.MONITOR_DOMAIN_FAIL = '[TTP IP] Monitor Domain Fail';
class GetLogDetailsAction {
    constructor(id) {
        this.id = id;
        this.type = exports.GET_LOG_DETAILS;
    }
}
exports.GetLogDetailsAction = GetLogDetailsAction;
class GetLogDetailsSuccessAction {
    constructor(logDetails) {
        this.logDetails = logDetails;
        this.type = exports.GET_LOG_DETAILS_SUCCESS;
    }
}
exports.GetLogDetailsSuccessAction = GetLogDetailsSuccessAction;
class GetLogDetailsFailAction {
    constructor() {
        this.type = exports.GET_LOG_DETAILS_FAIL;
    }
}
exports.GetLogDetailsFailAction = GetLogDetailsFailAction;
class HideLogDetailsAction {
    constructor() {
        this.type = exports.HIDE_LOG_DETAILS;
    }
}
exports.HideLogDetailsAction = HideLogDetailsAction;
class PermitSenderAction {
    constructor(instance) {
        this.instance = instance;
        this.type = exports.PERMIT_SENDER;
    }
}
exports.PermitSenderAction = PermitSenderAction;
class PermitSenderSuccessAction {
    constructor() {
        this.type = exports.PERMIT_SENDER_SUCCESS;
    }
}
exports.PermitSenderSuccessAction = PermitSenderSuccessAction;
class PermitSenderFailAction {
    constructor() {
        this.type = exports.PERMIT_SENDER_FAIL;
    }
}
exports.PermitSenderFailAction = PermitSenderFailAction;
class BlockSenderAction {
    constructor(instance) {
        this.instance = instance;
        this.type = exports.BLOCK_SENDER;
    }
}
exports.BlockSenderAction = BlockSenderAction;
class BlockSenderSuccessAction {
    constructor() {
        this.type = exports.BLOCK_SENDER_SUCCESS;
    }
}
exports.BlockSenderSuccessAction = BlockSenderSuccessAction;
class BlockSenderFailAction {
    constructor() {
        this.type = exports.BLOCK_SENDER_FAIL;
    }
}
exports.BlockSenderFailAction = BlockSenderFailAction;
class MonitorDomainAction {
    constructor(sender) {
        this.sender = sender;
        this.type = exports.MONITOR_DOMAIN;
    }
}
exports.MonitorDomainAction = MonitorDomainAction;
class MonitorDomainSuccessAction {
    constructor() {
        this.type = exports.MONITOR_DOMAIN_SUCCESS;
    }
}
exports.MonitorDomainSuccessAction = MonitorDomainSuccessAction;
class MonitorDomainFailAction {
    constructor() {
        this.type = exports.MONITOR_DOMAIN_FAIL;
    }
}
exports.MonitorDomainFailAction = MonitorDomainFailAction;
