"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThreatAnalysisComponent = void 0;
class ThreatAnalysisComponent {
    constructor(translationService) {
        this.translationService = translationService;
        this.defaultTitle = '$I18N_MESSAGE_CENTER_THREAT_INFO_THREAT_ANALYSIS_PROPERTIES.HEADER.TITLE';
        this.contentText = '$I18N_MESSAGE_CENTER_THREAT_INFO_THREAT_ANALYSIS_PROPERTIES.TOOLTIP.CONTENT';
        this.linkText = '$I18N_MESSAGE_CENTER_THREAT_INFO_THREAT_ANALYSIS_PROPERTIES.TOOLTIP.LINK';
        this.linkUrl = 'https://community.mimecast.com/s/article/Message-Insight-Spam-Scanning';
        this.showTooltip = true;
        this.isExpanded = true;
    }
    ngOnInit() {
        this.options = {
            displayField: 'name',
            childrenField: 'children',
            isExpandedField: 'expanded',
            animateExpand: true,
            animateSpeed: ThreatAnalysisComponent.ANIMATE_SPEED
        };
    }
    ngAfterViewInit() {
        setTimeout(() => this.expandTree(), ThreatAnalysisComponent.TREE_INIT_TIMEOUT);
    }
    ngOnChanges(changes) {
        const data = changes.processingDetails.currentValue &&
            changes.processingDetails.currentValue.verdict &&
            changes.processingDetails.currentValue.verdict.categories;
        if (!data) {
            this.nodes = [];
        }
        else {
            // Re-map the received data to a structure that is compatible with the custom tree-view
            const processedData = data.map(({ subcategories, name, risk }) => ({
                name: this.getTranslatedCategoryName(name),
                risk: this.getTranslatedRiskLabel(risk),
                // eslint-disable-next-line no-shadow,@typescript-eslint/no-shadow
                children: subcategories.map(({ augmentations, name, risk }) => ({
                    name: this.getTranslatedCategoryName(name),
                    risk: this.getTranslatedRiskLabel(risk),
                    // eslint-disable-next-line no-shadow, @typescript-eslint/no-shadow
                    children: augmentations.map(({ name, risk }) => ({
                        name: this.getTranslatedCategoryName(name),
                        risk: this.getTranslatedRiskLabel(risk),
                        expanded: true
                    })),
                    expanded: true
                })),
                expanded: true
            }));
            this.nodes = processedData;
        }
    }
    collapseTree() {
        if (!!this.treeViewRef) {
            this.treeViewRef.getTreeRef().treeModel.collapseAll();
            this.isExpanded = false;
        }
    }
    expandTree() {
        if (!!this.treeViewRef) {
            this.treeViewRef.getTreeRef().treeModel.expandAll();
            this.isExpanded = true;
        }
    }
    getTranslatedCategoryName(keySuffix) {
        const translatedCategoryName = keySuffix
            ? this.translationService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_THREAT_ANALYSIS_PROPERTIES.CATEGORY.' +
                keySuffix.toUpperCase())
            : 'Unknown';
        return translatedCategoryName.includes('$I18N') ? keySuffix : translatedCategoryName;
    }
    getTranslatedRiskLabel(riskKey) {
        const translatedRiskLabel = riskKey
            ? this.translationService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_THREAT_ANALYSIS_PROPERTIES.RISK.' +
                riskKey.toUpperCase())
            : 'Unknown Risk';
        return translatedRiskLabel.includes('$I18N') ? riskKey : translatedRiskLabel;
    }
}
exports.ThreatAnalysisComponent = ThreatAnalysisComponent;
ThreatAnalysisComponent.ANIMATE_SPEED = 500;
ThreatAnalysisComponent.TREE_INIT_TIMEOUT = 500;
