<mc-layout-aside-extra-container keyTitle="$I18N_MONITORED_EXTERNAL_DOMAIN_EDIT_DOMAIN"
                                 [isLoading]="false"
                                 [showClose]="true"
                                 (closeAside)="onClose()">

  <mc-extra-container>
    <button class="btn btn-primary panel-half-margin-right" (click)="onSave()" [disabled]="!form.valid" *mcCapabilities="'Permission.POLICIES_EDIT'">
      {{'$I18N_MONITORED_DOMAINS_EDIT_DOMAIN.BUTTON_LABEL.SAVE' | translate}}
    </button>
    <button class="btn btn-secondary panel-half-margin-right" (click) = "cancel.emit()">
      {{'$I18N_MONITORED_DOMAINS_EDIT_DOMAIN.BUTTON_LABEL.CANCEL' | translate}}
    </button>
    <button class="btn btn-secondary" (click) = "onDelete()" *mcCapabilities="'Permission.POLICIES_EDIT'">
      {{'$I18N_MONITORED_DOMAINS_EDIT_DOMAIN.BUTTON_LABEL.DELETE' | translate}}
    </button>
  </mc-extra-container>

  <mc-body-container>
    <form [formGroup]="form">
      <mc-text-field
        label="{{'$I18N_MONITORED_DOMAINS_EDIT_DOMAIN.LABEL.DOMAIN' | translate}}"
        formControlName="domain"
        placeholder="{{'$I18N_MONITORED_DOMAINS_EDIT_DOMAIN.PLACEHOLDER.DOMAIN' | translate}}"
        errorPrefix="$I18N_MONITORED_DOMAINS_EDIT_DOMAIN">
      </mc-text-field>

      <mc-text-field
        label="{{'$I18N_MONITORED_DOMAINS_EDIT_DOMAIN.LABEL.COMMENT' | translate}}"
        formControlName="comment"
        placeholder="{{'$I18N_MONITORED_DOMAINS_EDIT_DOMAIN.PLACEHOLDER.COMMENT' | translate}}"
        errorPrefix="$I18N_MONITORED_DOMAINS_ADDITION_COMMENT">
      </mc-text-field>
    </form>

  </mc-body-container>
</mc-layout-aside-extra-container>
