'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const operators_1 = require("rxjs/operators");
const model_1 = require("../../../../../app-new/core/model");
angular.module('attachment-protection-charts.service', ['core.service'])
    .factory('attachmentProtectionChartsService', ['$translate', 'dashboardCommonsService', 'CoreService', function ($translate, dashboardCommonsService, coreService) {
        let fipsMode = false;
        coreService
            .getCapsOverride()
            .pipe(operators_1.first(), model_1.isDefined())
            .subscribe((caps) => {
            var _a;
            fipsMode = (_a = caps['fipsMode']) === null || _a === void 0 ? void 0 : _a.enabled;
        });
        const defaultExporting = {
            buttons: dashboardCommonsService.getButtonConfig(),
            fallbackToExportServer: !fipsMode,
            error() {
                alert('An error has occurred exporting the chart');
            },
            chartOptions: {
                subtitle: {
                    text: ''
                }
            }
        };
        return {
            getAttachmentsPerDayConfig,
            getAttachmentsPerCleanMaliciousConfig,
            getDoughnutChartConfig,
            getMostUnsafeRecipientsAndFilesConfig
        };
        function getAttachmentsPerDayConfig() {
            return {
                chart: {
                    className: 'panel-half-padding-top',
                    type: 'spline',
                    zoomType: 'x',
                    marginRight: 35,
                    resetZoomButton: {
                        position: {
                            x: -30,
                            y: -30
                        },
                        theme: {
                            fill: 'white',
                            stroke: '#E6E6E6',
                            r: 2,
                            states: {
                                hover: {
                                    fill: '#ecf0f1',
                                    stroke: '#E6E6E6'
                                }
                            }
                        }
                    }
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: '',
                    style: {
                        display: 'none'
                    }
                },
                subtitle: {
                    text: $translate.instant('$I18N_COMMON_CLICK_DRAG_ZOOM_TEXTS'),
                    style: {}
                },
                xAxis: {
                    type: 'datetime',
                    labels: {
                        overflow: 'justify'
                    }
                },
                yAxis: {
                    title: {
                        text: $translate.instant('$I18N_ADMINISTRATION_MENU_ATTACHMENT_PROTECTION_CHART_ATTACHMENT_COUNT_PER_DAY_SIDE_TITLE')
                    },
                    minorGridLineWidth: 0,
                    gridLineWidth: 0,
                    alternateGridColor: null
                },
                tooltip: {
                    valueSuffix: $translate.instant('$I18N_ADMINISTRATION_MENU_ATTACHMENT_PROTECTION_CHART_ATTACHMENT_COUNT_TOOLTIP')
                },
                plotOptions: {
                    spline: {
                        lineWidth: 1,
                        color: '#1976D2',
                        states: {
                            hover: {
                                lineWidth: 3
                            }
                        },
                        marker: {
                            enabled: false
                        }
                    }
                },
                legend: {
                    align: 'center',
                    width: 600,
                    itemWidth: 200,
                    itemStyle: {
                        color: '#707070',
                        fontWeight: 'normal',
                        width: 180
                    }
                },
                series: [],
                exporting: defaultExporting,
                navigation: dashboardCommonsService.getNavigationConfig()
            };
        }
        function getAttachmentsPerCleanMaliciousConfig() {
            return {
                chart: {
                    className: 'panel-half-padding-top',
                    type: 'column',
                    marginRight: 35
                },
                credits: {
                    enabled: false
                },
                title: {
                    style: {
                        display: 'none'
                    }
                },
                xAxis: {
                    categories: [$translate.instant('$I18N_ADMINISTRATION_DASHBOARD_INBOUND_LABEL'), $translate.instant('$I18N_ADMINISTRATION_DASHBOARD_OUTBOUND_LABEL'), $translate.instant('$I18N_ADMINISTRATION_DASHBOARD_INTERNAL_LABEL')]
                },
                yAxis: {
                    min: 0,
                    stackLabels: {
                        enabled: false
                    },
                    minorGridLineWidth: 0,
                    gridLineWidth: 0,
                    alternateGridColor: null,
                    title: {
                        style: {
                            display: 'none'
                        }
                    }
                },
                tooltip: {
                    valueSuffix: $translate.instant('$I18N_ADMINISTRATION_MENU_ATTACHMENT_PROTECTION_CHART_ATTACHMENT_COUNT_TOOLTIP')
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        dataLabels: {
                            enabled: false
                        }
                    }
                },
                legend: {
                    itemStyle: {
                        color: '#707070',
                        fontWeight: 'normal'
                    }
                },
                series: [{
                        name: $translate.instant('$I18N_ADMINISTRATION_MENU_ATTACHMENT_PROTECTION_CHART_ATTACHMENT_COUNT_TOTAL_LEGEND'),
                        pointWidth: 24,
                        showInLegend: false
                    }, {
                        name: $translate.instant('$I18N_ADMINISTRATION_MENU_ATTACHMENT_PROTECTION_CHART_ATTACHMENT_COUNT_UNSAFE_LEGEND'),
                        pointWidth: 24,
                        showInLegend: false
                    }],
                exporting: defaultExporting,
                navigation: dashboardCommonsService.getNavigationConfig()
            };
        }
        function getDoughnutChartConfig() {
            return {
                chart: {
                    spacingTop: 1,
                    spacingBottom: 0,
                    type: 'pie'
                },
                credits: {
                    enabled: false
                },
                title: {
                    align: 'center',
                    verticalAlign: 'middle',
                    style: {
                        color: '#999'
                    },
                    y: -2
                },
                plotOptions: {
                    pie: {
                        size: '100%',
                        innerSize: '83.7%',
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: false
                        },
                        states: {
                            hover: {
                                enabled: false
                            }
                        },
                        slicedOffset: 0
                    }
                },
                series: [],
                exporting: defaultExporting,
                navigation: dashboardCommonsService.getNavigationConfig()
            };
        }
        function getMostUnsafeRecipientsAndFilesConfig() {
            return {
                chart: {
                    className: 'panel-half-padding-top attachment-protection-top-senders',
                    type: 'bar',
                    marginRight: 35
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: '',
                    style: {
                        display: 'none'
                    }
                },
                subtitle: {
                    style: {}
                },
                xAxis: {
                    title: {
                        text: null
                    },
                    labels: {
                        formatter() {
                            return dashboardCommonsService.getTruncatedLabel(this.value);
                        }
                    },
                    categories: []
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: '',
                        align: 'high'
                    },
                    minorGridLineWidth: 0,
                    gridLineWidth: 0,
                    alternateGridColor: null
                },
                tooltip: {
                    useHTML: true,
                    formatter() {
                        return dashboardCommonsService.getAttachmentProtectWrappedTooltip(this);
                    }
                },
                plotOptions: {
                    bar: {
                        dataLabels: {
                            enabled: true
                        },
                        colorByPoint: true
                    },
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                },
                series: [],
                exporting: defaultExporting,
                navigation: dashboardCommonsService.getNavigationConfig({ y: -10 })
            };
        }
    }]);
