'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const moment = require("moment");
const _ = require("lodash");
/**
 * Created by gvilla on 02/03/2016.
 */
angular.module('navbar.history.service', [])
    .factory('navbarHistoryService', ['navbarService', 'userService', 'growl', '$q', '$translate', '$state', 'capabilitiesService', function (navbarService, userService, growl, $q, $translate, $state, capabilitiesService) {
        let stateHistoryCache = [];
        let adconHistoryPreferences = [];
        const keyAdconHistory = userService.constants.PREFERENCE_KEYS.ADCON_HISTORY;
        const adconHistoryPromise = userService.getPreferencesByKey(keyAdconHistory).then(function (responsePreference) {
            if (responsePreference.err) {
                growl.error($translate.instant('$I18N_ERR_USER_PREFERENCE_FETCH_HISTORY'));
            }
            else {
                if (responsePreference.length === 0) {
                    return responsePreference;
                }
                if (capabilitiesService.areCapabilitiesLoaded()) {
                    return populateHistoryMenuBasedOnCaps(responsePreference);
                }
                else {
                    return capabilitiesService.getCapabilities().then(function () {
                        return populateHistoryMenuBasedOnCaps(responsePreference);
                    });
                }
            }
        });
        function populateHistoryMenuBasedOnCaps(responsePreference) {
            let promiseResolveCounter = 0;
            const deferred = $q.defer();
            const dirtyCheckCopy = angular.copy(responsePreference);
            _.forEach(responsePreference, function (responsePreferenceInstance) {
                if ($state.get(responsePreferenceInstance.name)
                    && responsePreferenceInstance.name
                    && angular.isDefined(responsePreferenceInstance.params)
                    && responsePreferenceInstance.name !== 'home') {
                    navbarService.findMenuItemByState(responsePreferenceInstance, responsePreferenceInstance.params).then(function (responseMenu) {
                        promiseResolveCounter++;
                        if (responseMenu.item.label) {
                            responseMenu.item.date = responsePreferenceInstance.date;
                            responseMenu.item.state.name = responsePreferenceInstance.name;
                            responseMenu.item.state.params = responsePreferenceInstance.params ? responsePreferenceInstance.params : {};
                            responseMenu.item.isActive = responseMenu.item.caps ? navbarService.hasCap(responseMenu.item.caps) : true;
                            stateHistoryCache.push(responseMenu.item);
                            adconHistoryPreferences.push(responsePreferenceInstance);
                        }
                        if (promiseResolveCounter === dirtyCheckCopy.length) {
                            deferred.resolve(stateHistoryCache);
                        }
                    });
                }
                else {
                    _.remove(dirtyCheckCopy, responsePreferenceInstance);
                }
            });
            if (dirtyCheckCopy.length !== responsePreference.length) {
                if (dirtyCheckCopy.length === 0) {
                    deferred.resolve(stateHistoryCache);
                }
                userService.setPreferencesByKey(keyAdconHistory, dirtyCheckCopy);
            }
            return deferred.promise;
        }
        function addToStateHistory(state, stateParams) {
            if (state.name == 'redirect-to-service-monitor')
                return; // need a better solution
            adconHistoryPromise.then(function () {
                const stateDetails = {
                    name: state.name,
                    date: moment(new Date()).locale('en').format('YYYY-MM-DDTHH:mm:ssZZ'),
                    parent: state.parent,
                    params: stateParams && stateParams.uid ? { uid: stateParams.uid } : {}
                };
                _.remove(adconHistoryPreferences, function (adconHistoryPreferencesInstance) {
                    return navbarService.areTheseStateSame(adconHistoryPreferencesInstance, stateDetails);
                });
                adconHistoryPreferences.push(stateDetails);
                adconHistoryPreferences = _.takeRight(adconHistoryPreferences, 10);
                userService.setPreferencesByKey(keyAdconHistory, adconHistoryPreferences).then(function (response) {
                    if (!response.err) {
                        navbarService.findMenuItemByState(state, stateParams).then(function (responseMenu) {
                            responseMenu.item.date = stateDetails.date;
                            _.remove(stateHistoryCache, function (stateHistoryCacheInstance) {
                                return navbarService.areTheseStateSame(stateHistoryCacheInstance.state, responseMenu.item.state);
                            });
                            responseMenu.item.isActive = !(angular.isDefined(responseMenu.item.caps) && !capabilitiesService.evalCapabilitiesExpression(responseMenu.item.caps));
                            stateHistoryCache.push(responseMenu.item);
                            stateHistoryCache = _.takeRight(stateHistoryCache, 10);
                        });
                    }
                    else {
                        // @ts-ignore:TS2345
                        _.remove(adconHistoryPreferences, stateDetails);
                        growl.error($translate.instant('$I18N_ERR_USER_PREFERENCE_SAVE_HISTORY'));
                    }
                });
            });
        }
        function getStateHistory() {
            return stateHistoryCache;
        }
        function clearItemsFromHistoryMenu() {
            adconHistoryPreferences = [];
            userService.setPreferencesByKey(keyAdconHistory, adconHistoryPreferences).then(function (response) {
                if (response.err) {
                    growl.error($translate.instant('$I18N_ERR_USER_PREFERENCE_SAVE_HISTORY'));
                }
                else {
                    stateHistoryCache = [];
                }
            });
        }
        return {
            addToStateHistory,
            getStateHistory,
            clearItemsFromHistoryMenu
        };
    }]);
