"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getIsApiProcessing = exports.reducer = exports.initialState = void 0;
const url_report_action_1 = require("../actions/url-report.action");
exports.initialState = {
    isApiProcessing: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case url_report_action_1.SwgUrlReportActions.REPORT_URL:
            return Object.assign(Object.assign({}, state), { isApiProcessing: true });
        case url_report_action_1.SwgUrlReportActions.REPORT_URL_SUCCESS:
            return Object.assign(Object.assign({}, state), { isApiProcessing: false });
        case url_report_action_1.SwgUrlReportActions.REPORT_URL_FAILURE:
            return Object.assign(Object.assign({}, state), { isApiProcessing: false });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getIsApiProcessing = (state) => state.isApiProcessing;
exports.getIsApiProcessing = getIsApiProcessing;
