"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IncidentStatsEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const incidentStatsAction = require("../../actions/incident-stats/incident-stats.action");
const incidentsStatsAction = require("../../actions/incident-stats/incident-stats.action");
class IncidentStatsEffects {
    constructor(actions$, remediationApiService, translateService, notificationService) {
        this.actions$ = actions$;
        this.remediationApiService = remediationApiService;
        this.translateService = translateService;
        this.notificationService = notificationService;
        this.getIncidentsStats$ = this.actions$.pipe(effects_1.ofType(incidentStatsAction.GET_INCIDENTS_STATS), operators_1.switchMap(() => {
            return this.remediationApiService.getIncidentStats().pipe(operators_1.map((response) => new incidentStatsAction.RemediationGetIncidentStatsSuccessAction(response)), operators_1.catchError(() => this.incidentStatsFailAction()));
        }));
    }
    incidentStatsFailAction() {
        return rxjs_1.of(new incidentsStatsAction.RemediationGetIncidentStatsFailureAction()).pipe(operators_1.tap(() => {
            this.notificationService.error({
                msg: this.translateService.instant('$I18N_REMEDIATION.ERRORS.GENERIC')
            });
        }));
    }
}
__decorate([
    effects_1.Effect()
], IncidentStatsEffects.prototype, "getIncidentsStats$", void 0);
exports.IncidentStatsEffects = IncidentStatsEffects;
