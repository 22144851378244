<mc-swg-activity-report
  [hasTTPURLPolicySupport]="hasTTPURLPolicySupport$ | async"
  [hasPrivateIpSupport]="hasPrivateIpSupport"
  [hasSupervisedUserSupport]="supervisedUserEnabled"
  [hasHybridProxySupport]="(hasSwgHybrid$ | async) && hasHybridProxySupport"
  [hasSwgFull]="hasSwgFull"
  [hasBrowserIsolationEnabled]="hasBrowserIsolationEnabled"
  [categories]="categories$ | async"
  [hasApplicationVisibility]="hasApplicationVisibility"
  [applications]="applications$ | async"
  [params] = "params"
  [hasDirectIPFiltersEnabled]="hasDirectIpFiltersSwitchEnabled"
  [hasDirectIpProtectionModeSwitchEnabled]="hasDirectIpProtectionModeSwitchEnabled"
  (onShowNotification)="showNotification($event)"
></mc-swg-activity-report>
