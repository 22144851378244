<mc-layout-detail-tabs *ngIf="!!snapshotSummary"
  keyTitle="{{ '$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_DETAIL.HEADER.TITLE' | translate:{mailboxUserName: getName(), mailboxUserMail: getMail()} }}"
  keyDescription="$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_DETAIL.HEADER.DESCRIPTION"
  [extendClass]="'mc-sync-recover-exchange-snapshot-detail ' + getActiveTabClass(activeTab)"
  kbUrl="https://community.mimecast.com/docs/DOC-2704">

  <mc-extra-container>

    <div class="mc-btn-area">
      <button class="mc-btn-item btn btn-primary" (click)="openExport()" [disabled]="isExportAndRestoreDisabled(selectedFoldersCart)">{{
        '$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_DETAIL.HEADER.BUTTONS.EXPORT' | translate }}</button>
      <button class="mc-btn-item btn btn-primary" (click)="openRestore()" [disabled]="isExportAndRestoreDisabled(selectedFoldersCart)">{{
        '$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_DETAIL.HEADER.BUTTONS.RESTORE' | translate }}</button>

    </div>

  </mc-extra-container>

  <mc-tab-group *ngIf="!!snapshotSummary" [initialActiveTabIndex]="getSnapshotShowTab()" (tabChange)="initializeSelectedTab($event)">

    <mc-tab [name]="TABS_KEYS.LAST_SYNC">

      <mc-sync-recover-exchange-tree *ngIf="getSnapshotShowContent()" [folders]="folders" [foldersLoading]="foldersLoading"
        [contents]="contents" [contentsLoading]="contentsLoading || isContentOpening" [contentsInitialState]="contentsInitialState"
        [rootFolder]="rootFolder" [columns]="columns" [nodeStack]="nodeStack" [searchingFolders]="searchingFolders"
        [searchingContents]="searchingContents" [currentFolder]="currentNode" [currentParentFolder]="currentParentFolder"
        [contentType]="contentType && contentType.getContentType()" [isFirstContentPage]="isFirstContentPage"
        [isFirstFolderPage]="isFirstFolderPage" [exportable]="hasExportAndRestorePermission" [contentViewable]="hasContentViewPermission"
        (onNodeClicked)="getFoldersAndContents($event)" (onItemClicked)="openItem($event)" (onFolderToggleSelect)="onFolderToggleSelect.emit($event)"
        (onSearchFolders)="searchFolders($event)" (onSearchContents)="searchContents($event)" (pushNodeToTrace)="onPushNodeToTrace.emit($event)"
        (getNextFolders)="dispatchNextFolders()" (getPreviousFolders)="dispatchPreviousFolders()" (getNextContents)="dispatchNextContents()"
        (getPreviousContents)="dispatchPreviousContents()"></mc-sync-recover-exchange-tree>

      <mc-empty-results *ngIf="!getSnapshotShowContent()" iconClass="far fa-hdd" keyTitle="{{ getSnapshotEmptyResultMessage().title }}">

        <mc-empty-body>{{ getSnapshotEmptyResultMessage().description | translate }}</mc-empty-body>

      </mc-empty-results>

    </mc-tab>

    <mc-tab [name]="TABS_KEYS.ARCHIVED">

      <mc-sync-recover-exchange-tree *ngIf="getSnapshotShowContent()" [folders]="folders" [foldersLoading]="foldersLoading"
        [contents]="contents" [contentsLoading]="contentsLoading || isContentOpening" [contentsInitialState]="contentsInitialState"
        [rootFolder]="rootFolder" [columns]="columns" [nodeStack]="nodeStack" [searchingFolders]="searchingFolders"
        [searchingContents]="searchingContents" [currentFolder]="currentNode" [currentParentFolder]="currentParentFolder"
        [contentType]="contentType && contentType.getContentType()" [isFirstContentPage]="isFirstContentPage"
        [isFirstFolderPage]="isFirstFolderPage" [exportable]="hasExportAndRestorePermission" [contentViewable]="hasContentViewPermission"
        (onNodeClicked)="getFoldersAndContents($event)" (onItemClicked)="openItem($event)" (onFolderToggleSelect)="onFolderToggleSelect.emit($event)"
        (onSearchFolders)="searchFolders($event)" (onSearchContents)="searchContents($event)" (pushNodeToTrace)="onPushNodeToTrace.emit($event)"
        (getNextFolders)="dispatchNextFolders()" (getPreviousFolders)="dispatchPreviousFolders()" (getNextContents)="dispatchNextContents()"
        (getPreviousContents)="dispatchPreviousContents()"></mc-sync-recover-exchange-tree>

      <mc-empty-results *ngIf="!getSnapshotShowContent()" iconClass="far fa-hdd" keyTitle="{{ getSnapshotEmptyResultMessage().title }}">

        <mc-empty-body>{{ getSnapshotEmptyResultMessage().description | translate }}</mc-empty-body>

      </mc-empty-results>

    </mc-tab>

    <mc-tab [name]="TABS_KEYS.SUMMARY">

      <div class="panel-body">

        <div class="mc-detailed-list">

          <div class="row mc-detailed-list-row">
            <div class="col-xs-2 mc-detailed-list-label">{{
              '$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_DETAIL.TABS.SUMMARY.CONTENT.NAME' | translate }}</div>
            <div class="mc-detailed-list-column">{{ getName() }}</div>
          </div>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-2 mc-detailed-list-label">{{
              '$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_DETAIL.TABS.SUMMARY.CONTENT.MAILBOX' | translate }}</div>
            <div class="mc-detailed-list-column">{{ getMail() }}</div>
          </div>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-2 mc-detailed-list-label">{{
              '$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_DETAIL.TABS.SUMMARY.CONTENT.STATUS' | translate }}</div>
              <div class="mc-detailed-list-column">
                <div [ngClass]="getSnapshotStatus().displayClasses">
                  <strong>{{ getSnapshotStatus().displayValue | translate:{number: getPhaseProgress()} }}</strong>
                </div>
                <div *ngIf="isCompletedWithErrors" class="completed-with-errors">
                  <p>
                   <i class="fas fa-exclamation-triangle text-warning" aria-hidden="true"></i>
                    {{ '$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_DETAIL.TABS.SUMMARY.COMPLETED_WITH_ERRORS.MESSAGE_PREFIX' | translate }}
                    <a 
                     href="https://community.mimecast.com/s/article/Sync-Recover-Overview-1873891898" 
                     rel="noopener noreferrer" 
                     target="_blank"
                     >{{ '$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_DETAIL.TABS.SUMMARY.COMPLETED_WITH_ERRORS.MESSAGE_SUFFIX' | translate }}</a>.
                  </p>
                </div>
              </div>
          </div>

          <div class="row mc-detailed-list-row" *ngIf="getSnapshotStatusMessage()">
            <div class="col-xs-2 mc-detailed-list-label">{{
              '$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_DETAIL.TABS.SUMMARY.CONTENT.REASON' | translate }}</div>
            <div class="mc-detailed-list-column">{{ getSnapshotStatusMessage() | translate}}</div>
          </div>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-2 mc-detailed-list-label">{{
              '$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_DETAIL.TABS.SUMMARY.CONTENT.RECOVERYID' | translate }}</div>
            <div class="mc-detailed-list-column">{{ getSnapshotRecoveryId() }}</div>
          </div>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-2 mc-detailed-list-label">{{
              '$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_DETAIL.TABS.SUMMARY.CONTENT.SNAPSHOTDATE' | translate }}</div>
            <div class="mc-detailed-list-column">{{ getSnapshotDate() | mcDate }}</div>
          </div>

        </div>

      </div>

    </mc-tab>

  </mc-tab-group>

</mc-layout-detail-tabs>
