"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExtendBindingAction = exports.CloseKeyGenWizardAction = exports.CancelKeyGenWizardAction = exports.GoToPreviousStepAction = exports.GoToNextStepAction = exports.VerificationApiFailureAction = exports.VerificationFailureAction = exports.VerifyAccountAction = exports.InputVerificationAction = exports.GetApplicationKeySuccessAction = exports.VerificationRequiredAction = exports.AuthenticationFailureAction = exports.AuthenticationSuccessAction = exports.AuthenticationErrorAction = exports.AuthenticateAccountAction = exports.InputAuthenticationAction = exports.AccountDiscoveryFailureAction = exports.AccountDiscoverySuccessAction = exports.DiscoverAccountAction = exports.InputAccountAction = exports.ApiGatewayKeyGenActionTypes = void 0;
var ApiGatewayKeyGenActionTypes;
(function (ApiGatewayKeyGenActionTypes) {
    ApiGatewayKeyGenActionTypes["InputAccount"] = "[ApiApplication/ApiGateway/KeyGen] Input Account Details";
    ApiGatewayKeyGenActionTypes["DiscoverAccount"] = "[ApiApplication/ApiGateway/KeyGen] Discover Account";
    ApiGatewayKeyGenActionTypes["AccountDiscoverySuccess"] = "[ApiApplication/ApiGateway/KeyGen] Account Discovery Success";
    ApiGatewayKeyGenActionTypes["AccountDiscoveryFailure"] = "[ApiApplication/ApiGateway/KeyGen] Account Discovery Failure";
    ApiGatewayKeyGenActionTypes["InputAuthentication"] = "[ApiApplication/ApiGateway/KeyGen] Input Authentication Details";
    ApiGatewayKeyGenActionTypes["AuthenticateAccount"] = "[ApiApplication/ApiGateway/KeyGen] Authenticate Account";
    ApiGatewayKeyGenActionTypes["AuthenticationError"] = "[ApiApplication/ApiGateway/KeyGen] Authentication Error";
    ApiGatewayKeyGenActionTypes["AuthenticationSuccess"] = "[ApiApplication/ApiGateway/KeyGen] Authentication Success";
    ApiGatewayKeyGenActionTypes["AuthenticationFailure"] = "[ApiApplication/ApiGateway/KeyGen] Authentication Failure";
    ApiGatewayKeyGenActionTypes["VerificationRequired"] = "[ApiApplication/ApiGateway/KeyGen] Verification Required";
    ApiGatewayKeyGenActionTypes["GetApplicationKeySuccess"] = "[ApiApplication/ApiGateway/KeyGen] Get Application Key Success";
    ApiGatewayKeyGenActionTypes["InputVerification"] = "[ApiApplication/ApiGateway/KeyGen] Input Verification Details";
    ApiGatewayKeyGenActionTypes["VerifyAccount"] = "[ApiApplication/ApiGateway/KeyGen] Verify Account";
    ApiGatewayKeyGenActionTypes["VerificationFailure"] = "[ApiApplication/ApiGateway/KeyGen] Verification Failure";
    ApiGatewayKeyGenActionTypes["VerificationApiFailure"] = "[ApiApplication/ApiGateway/KeyGen] Verification API Failure";
    ApiGatewayKeyGenActionTypes["GoToNextStep"] = "[ApiApplication/ApiGateway/KeyGen] Go To Next Step";
    ApiGatewayKeyGenActionTypes["GoToPreviousStep"] = "[ApiApplication/ApiGateway/KeyGen] Go To Previous Step";
    ApiGatewayKeyGenActionTypes["CloseKeyGenWizard"] = "[ApiApplication/ApiGateway/KeyGen] Close KeyGen Wizard";
    ApiGatewayKeyGenActionTypes["CancelKeyGenWizard"] = "[ApiApplication/ApiGateway/KeyGen] Cancel KeyGen Wizard";
    ApiGatewayKeyGenActionTypes["ExtendBinding"] = "[ApiApplication/ApiGateway/KeyGen] Extend Binding";
})(ApiGatewayKeyGenActionTypes = exports.ApiGatewayKeyGenActionTypes || (exports.ApiGatewayKeyGenActionTypes = {}));
class InputAccountAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayKeyGenActionTypes.InputAccount;
    }
}
exports.InputAccountAction = InputAccountAction;
class DiscoverAccountAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayKeyGenActionTypes.DiscoverAccount;
    }
}
exports.DiscoverAccountAction = DiscoverAccountAction;
class AccountDiscoverySuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayKeyGenActionTypes.AccountDiscoverySuccess;
    }
}
exports.AccountDiscoverySuccessAction = AccountDiscoverySuccessAction;
class AccountDiscoveryFailureAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayKeyGenActionTypes.AccountDiscoveryFailure;
    }
}
exports.AccountDiscoveryFailureAction = AccountDiscoveryFailureAction;
class InputAuthenticationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayKeyGenActionTypes.InputAuthentication;
    }
}
exports.InputAuthenticationAction = InputAuthenticationAction;
class AuthenticateAccountAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayKeyGenActionTypes.AuthenticateAccount;
    }
}
exports.AuthenticateAccountAction = AuthenticateAccountAction;
class AuthenticationErrorAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayKeyGenActionTypes.AuthenticationError;
    }
}
exports.AuthenticationErrorAction = AuthenticationErrorAction;
class AuthenticationSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayKeyGenActionTypes.AuthenticationSuccess;
    }
}
exports.AuthenticationSuccessAction = AuthenticationSuccessAction;
class AuthenticationFailureAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayKeyGenActionTypes.AuthenticationFailure;
    }
}
exports.AuthenticationFailureAction = AuthenticationFailureAction;
class VerificationRequiredAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayKeyGenActionTypes.VerificationRequired;
    }
}
exports.VerificationRequiredAction = VerificationRequiredAction;
class GetApplicationKeySuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayKeyGenActionTypes.GetApplicationKeySuccess;
    }
}
exports.GetApplicationKeySuccessAction = GetApplicationKeySuccessAction;
class InputVerificationAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayKeyGenActionTypes.InputVerification;
    }
}
exports.InputVerificationAction = InputVerificationAction;
class VerifyAccountAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayKeyGenActionTypes.VerifyAccount;
    }
}
exports.VerifyAccountAction = VerifyAccountAction;
class VerificationFailureAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayKeyGenActionTypes.VerificationFailure;
    }
}
exports.VerificationFailureAction = VerificationFailureAction;
class VerificationApiFailureAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApiGatewayKeyGenActionTypes.VerificationApiFailure;
    }
}
exports.VerificationApiFailureAction = VerificationApiFailureAction;
class GoToNextStepAction {
    constructor() {
        this.type = ApiGatewayKeyGenActionTypes.GoToNextStep;
    }
}
exports.GoToNextStepAction = GoToNextStepAction;
class GoToPreviousStepAction {
    constructor() {
        this.type = ApiGatewayKeyGenActionTypes.GoToPreviousStep;
    }
}
exports.GoToPreviousStepAction = GoToPreviousStepAction;
class CancelKeyGenWizardAction {
    constructor() {
        this.type = ApiGatewayKeyGenActionTypes.CancelKeyGenWizard;
    }
}
exports.CancelKeyGenWizardAction = CancelKeyGenWizardAction;
class CloseKeyGenWizardAction {
    constructor() {
        this.type = ApiGatewayKeyGenActionTypes.CloseKeyGenWizard;
    }
}
exports.CloseKeyGenWizardAction = CloseKeyGenWizardAction;
class ExtendBindingAction {
    constructor() {
        this.type = ApiGatewayKeyGenActionTypes.ExtendBinding;
    }
}
exports.ExtendBindingAction = ExtendBindingAction;
