"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgThreatEventFileLocationComponent = void 0;
class SwgThreatEventFileLocationComponent {
    constructor(translate) {
        this.translate = translate;
        this.SUPPORTED_FIELDS = [
            'channelId',
            'channelName',
            'chatId',
            'driveId',
            'fileName',
            'messageId',
            'path',
            'teamId',
            'teamName',
            'tenantId'
        ];
    }
    ngOnInit() {
        this.rows = [];
        Object.keys(this.fileLocation).forEach(field => {
            if (this.SUPPORTED_FIELDS.indexOf(field) === -1 || !this.fileLocation[field]) {
                return;
            }
            this.rows.push({
                label: this.translate.instant('$I18N_SWG_THREAT_EVENT_FILE_LOCATION.' + field.toUpperCase()),
                value: this.fileLocation[field]
            });
        });
    }
}
exports.SwgThreatEventFileLocationComponent = SwgThreatEventFileLocationComponent;
