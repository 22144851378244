"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const ip_checker_page_component_1 = require("./containers/ip-checker-page.component");
angular
    .module('account-ipchecker', [])
    .controller('IpCheckerController', [
    '$stateParams',
    '$scope',
    function ($stateParams, $scope) {
        $scope.stateParams = $stateParams;
    }
])
    .directive('mcIpChecker', static_1.downgradeComponent({
    component: ip_checker_page_component_1.IpCheckerPageComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider.state('ip-checker', {
            url: '/account/ip-checker',
            data: {
                isFixedLayout: false,
                checkProgress: false,
                capabilities: 'Temporary.Account.IpChecker && Permission.SETTINGS_READ'
            },
            views: {
                main: {
                    template: '<mc-ip-checker></mc-ip-checker>',
                    controller: 'IpCheckerController'
                }
            }
        });
    }
]);
