"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getParentFolder = exports.getRootFolder = exports.getMetadata = exports.isSearching = exports.isLoading = exports.getFolders = exports.rootFolderReducer = exports.INITIAL_ROOT_FOLDER_STATE = exports.folderReducer = exports.INITIAL_FOLDER_STATE = void 0;
const FolderActions = require("../actions/folders.actions");
const node_factory_1 = require("app-new/components/tree/model/factories/node.factory");
exports.INITIAL_FOLDER_STATE = {
    fullPath: undefined,
    parentId: undefined,
    contentType: undefined,
    folders: [],
    meta: {},
    loading: false,
    searching: false
};
function folderReducer(state = exports.INITIAL_FOLDER_STATE, action) {
    switch (action.type) {
        case FolderActions.LOAD_EXCHANGE_FOLDERS:
        case FolderActions.LOAD_ONE_DRIVE_FOLDERS:
        case FolderActions.FIND_EXCHANGE_FOLDERS:
            return Object.assign(Object.assign({}, state), { loading: true });
        case FolderActions.LOAD_SUCCESS_EXCHANGE_FOLDERS:
        case FolderActions.LOAD_SUCCESS_ONE_DRIVE_FOLDERS:
            return Object.assign(Object.assign({}, state), { fullPath: action.folderPath, parentId: action.parentId, contentType: action.contentType, folders: action.folders, meta: action.meta, loading: false });
        case FolderActions.FIND_SUCCESS_EXCHANGE_FOLDERS:
            return Object.assign(Object.assign({}, state), { folders: action.folders, meta: action.meta, loading: false, searching: true });
        case FolderActions.CLEAN_STORE:
            return exports.INITIAL_FOLDER_STATE;
        default:
            return state;
    }
}
exports.folderReducer = folderReducer;
exports.INITIAL_ROOT_FOLDER_STATE = { rootFolder: null, loading: false };
function rootFolderReducer(state = exports.INITIAL_ROOT_FOLDER_STATE, action) {
    switch (action.type) {
        case FolderActions.LOAD_EXCHANGE_ROOT_FOLDER:
        case FolderActions.LOAD_ONE_DRIVE_ROOT_FOLDER:
            return Object.assign(Object.assign({}, state), { loading: true });
        case FolderActions.LOAD_SUCCESS_EXCHANGE_ROOT_FOLDER:
        case FolderActions.LOAD_SUCCESS_ONE_DRIVE_ROOT_FOLDER:
            return Object.assign(Object.assign({}, state), { rootFolder: action.rootFolder, loading: false });
        case FolderActions.CLEAN_STORE:
            return exports.INITIAL_ROOT_FOLDER_STATE;
        default:
            return state;
    }
}
exports.rootFolderReducer = rootFolderReducer;
const getFolders = (state) => createFolderObjects(state.folders);
exports.getFolders = getFolders;
const isLoading = (state) => state.loading;
exports.isLoading = isLoading;
const isSearching = (state) => state.searching;
exports.isSearching = isSearching;
const getMetadata = (state) => state.meta;
exports.getMetadata = getMetadata;
const getRootFolder = (state) => state.rootFolder;
exports.getRootFolder = getRootFolder;
const getParentFolder = (state) => createParentFolder(state.parentId, state.fullPath, state.contentType);
exports.getParentFolder = getParentFolder;
function createFolderObjects(folders) {
    return folders
        .map(folder => {
        return node_factory_1.NodeFactory.FOLDER(folder);
    })
        .sort((folderA, folderB) => {
        if (folderA.getContentType() < folderB.getContentType()) {
            return 1;
        }
        if (folderA.getContentType() > folderB.getContentType()) {
            return -1;
        }
        return 0;
    });
}
function createParentFolder(parentId, folderPath, contentType) {
    if (parentId) {
        const parentName = folderPath.split('\\').filter(name => name !== '');
        return node_factory_1.NodeFactory.FOLDER({
            id: parentId,
            name: parentName && parentName.length > 1
                ? parentName[parentName.length - 2]
                : parentName[parentName.length - 1],
            contentType
        });
    }
}
