"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiemChannelListImplService = void 0;
const rxjs_1 = require("rxjs");
const siem_channels_models_1 = require("../../models/siem-channels.models");
const operators_1 = require("rxjs/operators");
class SiemChannelListImplService {
    constructor(http, siemChannelApiService) {
        this.http = http;
        this.siemChannelApiService = siemChannelApiService;
    }
    getData() {
        return this.http.post('/api/siem/get-all-channels', null).pipe(operators_1.map((response) => {
            const RETURN_DATA = {
                hasErrors: response.hasErrors,
                data: this.mapAPIResponse(response.first.channels),
                failures: response.fail,
                meta: response.meta
            };
            return RETURN_DATA;
        }), operators_1.catchError((response) => {
            const RETURN_DATA = {
                hasErrors: response.hasErrors,
                data: response.all,
                failures: response.fail,
                meta: response.meta
            };
            return rxjs_1.of(RETURN_DATA);
        }));
    }
    filter() {
        return this.getData();
    }
    openItem() {
        return rxjs_1.of([]);
    }
    mapAPIResponse(siemChannels) {
        const data = siemChannels.map((channel) => {
            return Object.assign(Object.assign({}, channel), { type: channel.type === siem_channels_models_1.ChannelType.SIEM ? siem_channels_models_1.ChannelType.SIEM : siem_channels_models_1.ChannelType.THREAT, events: channel.events.map((event) => event.eventId) });
        });
        return data;
    }
}
exports.SiemChannelListImplService = SiemChannelListImplService;
