"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CapabilitiesDirective = exports.CapabilitiesContext = void 0;
class CapabilitiesContext {
    constructor() {
        this.$implicit = null;
        this.mcCapabilities = null;
    }
}
exports.CapabilitiesContext = CapabilitiesContext;
class CapabilitiesDirective {
    constructor(service, _viewContainer, templateRef) {
        this.service = service;
        this._viewContainer = _viewContainer;
        this._context = new CapabilitiesContext();
        this._thenTemplateRef = null;
        this._elseTemplateRef = null;
        this._thenViewRef = null;
        this._elseViewRef = null;
        this._thenTemplateRef = templateRef;
    }
    set mcCapabilities(condition) {
        let value;
        if (typeof condition === 'string') {
            this.service.evalCapabilitiesExpression(condition).subscribe(v => (value = v));
        }
        else {
            value = condition;
        }
        this._context.$implicit = this._context.mcCapabilities = value;
        this._updateView();
    }
    set mcCapabilitiesThen(templateRef) {
        this._thenTemplateRef = templateRef;
        this._thenViewRef = null; // clear previous view if any.
        this._updateView();
    }
    set mcCapabilitiesElse(templateRef) {
        this._elseTemplateRef = templateRef;
        this._elseViewRef = null; // clear previous view if any.
        this._updateView();
    }
    _updateView() {
        if (this._context.$implicit) {
            if (!this._thenViewRef) {
                this._viewContainer.clear();
                this._elseViewRef = null;
                if (this._thenTemplateRef) {
                    this._thenViewRef = this._viewContainer.createEmbeddedView(this._thenTemplateRef, this._context);
                }
            }
        }
        else {
            if (!this._elseViewRef) {
                this._viewContainer.clear();
                this._thenViewRef = null;
                if (this._elseTemplateRef) {
                    this._elseViewRef = this._viewContainer.createEmbeddedView(this._elseTemplateRef, this._context);
                }
            }
        }
    }
}
exports.CapabilitiesDirective = CapabilitiesDirective;
