"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiemChannelService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const i0 = require("@angular/core");
const i1 = require("./siem-channel-api.service");
const i2 = require("../../../core/services/core/core.service");
const i3 = require("../../../components/capabilities/capabilities.service");
class SiemChannelService {
    constructor(siemApiService, coreService, capabilitiesService) {
        this.siemApiService = siemApiService;
        this.coreService = coreService;
        this.capabilitiesService = capabilitiesService;
    }
    getChannelDetails(channelType, events, metadataProducts) {
        const products = this.mapEventsInMetadataProducts(events, metadataProducts);
        const filteredProducts = this.filterGroupsInProductsByChannelType(channelType, products).map(p => (Object.assign(Object.assign({}, p), { enabled: this.hasEventsEnabled(p.groups) })));
        return rxjs_1.of(filteredProducts);
    }
    hasEventsEnabled(groups) {
        const eventEnabledGroups = groups.filter(group => !!group.events.filter(e => e.enabled).length);
        return !!eventEnabledGroups.length;
    }
    mapEventsInMetadataProducts(channelEvents, metaDataProducts) {
        return metaDataProducts.map(product => (Object.assign(Object.assign({}, product), { groups: product.groups.map(group => (Object.assign(Object.assign({}, group), { events: group.events.map(event => (Object.assign(Object.assign({}, event), { enabled: channelEvents.includes(event.id) }))) }))) })));
    }
    deepCopyProducts(products) {
        return products.map(product => (Object.assign(Object.assign({}, product), { groups: product.groups.map(group => (Object.assign(Object.assign({}, group), { events: group.events.map(event => (Object.assign({}, event))) }))) })));
    }
    filterProductGroupsByChannelType(channelType, metadataProducts) {
        const products = this.deepCopyProducts(metadataProducts);
        return rxjs_1.of(this.filterGroupsInProductsByChannelType(channelType, products));
    }
    filterGroupsInProductsByChannelType(channelType, products) {
        return products.filter(product => {
            product.groups = product.groups.filter(group => group.type === channelType);
            if (product.groups.length) {
                return product;
            }
        });
    }
    createChannel(siemChannel) {
        const eventsSet = new Set();
        siemChannel.products.map(p => {
            p.groups.map(g => {
                g.events.map(e => {
                    if (e.enabled) {
                        eventsSet.add(e.id);
                    }
                });
            });
        });
        const channel = {
            name: siemChannel.name,
            description: siemChannel.description,
            type: siemChannel.type.toUpperCase(),
            events: Array.from(eventsSet.values()).map(event => ({ eventId: event }))
        };
        if (siemChannel.id) {
            return this.siemApiService.updateChannel(Object.assign(Object.assign({}, channel), { id: siemChannel.id }));
        }
        else {
            return this.siemApiService.createChannel(channel);
        }
    }
    getMetadataProducts() {
        return this.siemApiService.getProductsFromMetadataJSON().pipe(operators_1.withLatestFrom(this.coreService.getAccount().pipe(operators_1.first())), operators_1.switchMap(([products, account]) => {
            let _data = products.map((product) => (Object.assign(Object.assign({}, product), { enabled: false })));
            _data = _data.filter((product) => {
                let pkgs = [];
                if (product.packages) {
                    pkgs = account.packages.filter(p => {
                        const _package = p.toLowerCase();
                        return !!product.packages.filter((pkg) => _package.includes(pkg.toLowerCase())).length;
                    });
                }
                return !!pkgs.length;
            });
            return rxjs_1.of(_data);
        }), operators_1.catchError(error => rxjs_1.throwError(error)));
    }
    hasEditPermission() {
        return this.capabilitiesService.hasCapability('Permission.SIEM_CHANNEL_EDIT');
    }
    hasDeletePermission() {
        return this.capabilitiesService.hasCapability('Permission.SIEM_CHANNEL_PURGE/DELETE');
    }
}
exports.SiemChannelService = SiemChannelService;
SiemChannelService.ɵprov = i0.ɵɵdefineInjectable({ factory: function SiemChannelService_Factory() { return new SiemChannelService(i0.ɵɵinject(i1.SiemChannelApiService), i0.ɵɵinject(i2.CoreService), i0.ɵɵinject(i3.CapabilitiesService)); }, token: SiemChannelService, providedIn: "root" });
