"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./list-simple-directive"); //layout.list-simple.directive
// html and css 
require("./list-simple.less");
require("./list-simple.tpl.html");
(function () {
    'use strict';
    /* ======================================================== *\

     = layout / list simple

     \* ======================================================== */
    angular.module('layout.list-simple', ['layout.list-simple.directive']);
}());
