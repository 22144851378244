"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
angular.module('progress-tracker.service', []).factory('progressTrackerService', [
    'adconCommonsService',
    'connectReminderDialogService',
    'connectCompleteDialogService',
    'mcCookiesService',
    'sessionHandlerService',
    'modalMaxRetentionService',
    '$injector',
    'FeatureFlagService',
    'capabilitiesService',
    'applicationSwitcherService',
    function (adconCommonsService, connectReminderDialogService, connectCompleteDialogService, mcCookiesService, sessionHandlerService, modalMaxRetentionService, $injector, featureFlagService, capabilitiesService, applicationSwitcherService) {
        const sessionId = sessionHandlerService.getSessionId();
        let displayingReminderDialog = false;
        const CONSTANTS = {
            TYPEOFCHECK: {
                LEGACY: 'legacy'
            }
        };
        const TRIGGERS = {
            LOGIN: {
                state: 'administration-dashboard',
                component: 'login',
                cookiesKey: 'adcon_connectProgressTrackerLogin',
                callBackDismiss: snoozeDialogLogin,
                callBackClose: handleConnectAction
            },
            SERVICESDIRECTORYSYNCHRONIZATION: {
                state: 'directorysync',
                component: 'sync-directory',
                cookiesKey: 'adcon_connectProgressTrackerDirectorySync',
                callBackDismiss: snoozeDialogDirectorySync,
                callBackClose: handleConnectAction
            },
            DIRECTORYDIRECTORYINTERNAL: {
                state: 'internal',
                component: 'validate-domain',
                cookiesKey: 'adcon_connectProgressTrackerDirectoryInternal',
                callBackDismiss: snoozeDialogDirectoryInternal,
                callBackClose: handleConnectAction
            }
        };
        return {
            CONSTANTS,
            statusLogin,
            statusLegacyChecker,
            snoozeDialogAll
        };
        function statusLegacyChecker(toState, toParams) {
            if (toState.name === CONSTANTS.TYPEOFCHECK.LEGACY) {
                switch (toParams.uid) {
                    case TRIGGERS.DIRECTORYDIRECTORYINTERNAL.state:
                        openConnectDialog(TRIGGERS.DIRECTORYDIRECTORYINTERNAL);
                        break;
                    case TRIGGERS.SERVICESDIRECTORYSYNCHRONIZATION.state:
                        openConnectDialog(TRIGGERS.SERVICESDIRECTORYSYNCHRONIZATION);
                        break;
                }
            }
        }
        function statusLogin() {
            openConnectDialog(TRIGGERS.LOGIN);
        }
        function openConnectDialog(item) {
            featureFlagService.isReady().then(() => {
                const isConnectEOLEnabled = featureFlagService.getBooleanFlag('rollout-connect-eol');
                if (capabilitiesService.evalCapabilitiesExpression('Connect.Progress.Tracker') &&
                    !isConnectEOLEnabled) {
                    if (!displayingReminderDialog) {
                        if (mcCookiesService.get(item.cookiesKey) !== sessionId &&
                            applicationSwitcherService.getPreviousApp() !==
                                applicationSwitcherService.constants.APP_KEYS.CONNECT) {
                            displayingReminderDialog = !!connectReminderDialogService
                                .show(item.component, null, item.callBackDismiss, item.callBackClose)
                                .finally(() => {
                                displayingReminderDialog = false;
                                displayMaxRetention(item);
                            });
                        }
                        else {
                            displayMaxRetention(item);
                        }
                    }
                }
                else {
                    displayMaxRetention(item);
                }
            });
        }
        function openConnectApp(location) {
            return applicationSwitcherService.applicationSwitcherOnReady(() => {
                const connectInstance = applicationSwitcherService.getAppByKey(applicationSwitcherService.constants.APP_KEYS.CONNECT);
                connectInstance.open('_blank', location);
            });
        }
        function handleConnectAction(result, state) {
            if (result === 'goto') {
                return openConnectApp(!!state ? state.replace('#/', '') : undefined);
            }
            else if (result === 'complete') {
                return connectCompleteDialogService.show();
            }
            else {
                return openConnectApp();
            }
        }
        function displayMaxRetention(item) {
            if (item === TRIGGERS.LOGIN) {
                modalMaxRetentionService.openMaxRetentionModal();
            }
        }
        function snoozeDialog(item) {
            mcCookiesService.put(item.cookiesKey, sessionId);
        }
        function snoozeDialogLogin() {
            snoozeDialog(TRIGGERS.LOGIN);
        }
        function snoozeDialogDirectorySync() {
            snoozeDialog(TRIGGERS.SERVICESDIRECTORYSYNCHRONIZATION);
        }
        function snoozeDialogDirectoryInternal() {
            snoozeDialog(TRIGGERS.DIRECTORYDIRECTORYINTERNAL);
        }
        function snoozeDialogAll() {
            snoozeDialog(TRIGGERS.LOGIN);
            snoozeDialog(TRIGGERS.SERVICESDIRECTORYSYNCHRONIZATION);
            snoozeDialog(TRIGGERS.DIRECTORYDIRECTORYINTERNAL);
        }
    }
]);
