"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetAcceptedEmailService = void 0;
const operators_1 = require("rxjs/operators");
const moment = require("moment");
const components_1 = require("@mimecast-ui/components");
class GetAcceptedEmailService {
    constructor(http, ts) {
        this.http = http;
        this.ts = ts;
    }
    getMessages(request, pagination) {
        return this.http
            .post('/api/gateway/get-accepted-messages', this.transformToServerRequest(request, pagination))
            .pipe(operators_1.map((response) => [
            response.first,
            response.meta
        ]), operators_1.map(([first, meta]) => [
            first.acceptedEmails,
            Object.assign(Object.assign({}, meta.pagination), { totalRows: (!!meta.pagination && meta.pagination.totalCount) || 0 })
        ]), operators_1.map(([acceptedEmail, page]) => [
            this.mapToFrontEndFormat(acceptedEmail),
            page
        ]), operators_1.map(([acceptedEmail, page]) => {
            return {
                acceptedEmails: acceptedEmail.sort(this.sortAcceptedEmails),
                pagingMetadata: page
            };
        }));
    }
    mapToFrontEndFormat(acceptedEmail) {
        return acceptedEmail.map(email => {
            const { sent, received, messageEvent, subject } = email, remainder = __rest(email, ["sent", "received", "messageEvent", "subject"]);
            return Object.assign(Object.assign(Object.assign(Object.assign({}, remainder), (!!sent ? { sent } : {})), (!!received ? { received } : {})), { subject: !!subject
                    ? subject
                    : this.ts.instant('$I18N_MESSAGE_CENTER_SUBJECT_NOT_AVAILABLE'), messageEvent: messageEvent.replace(/^(Message\sHold\sApplied\s-\s)/, ''), size: email.size || 0 });
        });
    }
    sortAcceptedEmails(a, b) {
        const adate = a.sent || a.received || new Date(0);
        const bdate = b.sent || b.received || new Date(0);
        return adate > bdate ? -1 : adate < bdate ? 1 : 0;
    }
    transformToServerRequest(appRequest, pagination) {
        const { start, end } = appRequest, remainder = __rest(appRequest, ["start", "end"]);
        const apiRequest = Object.assign(Object.assign(Object.assign({}, remainder), (!!start ? { start: moment(appRequest.start).format(components_1.API_DATE_FORMAT) } : {})), (!!end ? { end: moment(appRequest.end).format(components_1.API_DATE_FORMAT) } : {}));
        if (!!pagination) {
            // @ts-ignore: api interceptor wants like this :(
            apiRequest.meta = { pagination };
        }
        return apiRequest;
    }
}
exports.GetAcceptedEmailService = GetAcceptedEmailService;
