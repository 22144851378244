"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgEndpointsSettingsEnableUninstallPasswordModalComponent = void 0;
const settings_actions_1 = require("../../../actions/settings.actions");
class SwgEndpointsSettingsEnableUninstallPasswordModalComponent {
    constructor(store, overlayRef, settings) {
        this.store = store;
        this.overlayRef = overlayRef;
        this.settings = settings;
    }
    onClose() {
        this.store.dispatch(new settings_actions_1.SwgEndpointsSettingsCloseEnableUninstallPasswordConfirmModalAction(this.settings));
        this.overlayRef.close();
    }
    onUpdateSetting() {
        this.store.dispatch(new settings_actions_1.SwgEndpointsSettingsUpdateSettingsAction(this.settings));
    }
}
exports.SwgEndpointsSettingsEnableUninstallPasswordModalComponent = SwgEndpointsSettingsEnableUninstallPasswordModalComponent;
