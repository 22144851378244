<mc-modal-header
  headerTitle="{{header | translate}}"></mc-modal-header>
<mc-modal-footer  *ngIf="policy$ | async;">
  <button type="button" class="btn btn-secondary" (click)="onCancel()">
    {{ '$I18N_COMMON_BTN_CANCEL' | translate }}
  </button>

  <mc-live-button
    overrideClasses="btn-primary"
    [label]="actionButton | translate"
    [isLoading]="updating$ | async"
    (mcClick)="onDisable()">
  </mc-live-button>
</mc-modal-footer>
