"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapFilters = exports.getRequiredNotEmptyValidators = exports.getPasswordValidation = exports.HOST_TOOLTIP_KB_LINK = exports.ROOT_DN_REGEX = exports.getServerTypeKey = exports.validTranslationKeys = exports.validGSuiteJSONKeys = exports.lastOutcome = exports.serverTypeIcons = exports.serverTypeKeys = void 0;
const test_connection_interface_1 = require("../models/test-connection.interface");
const forms_1 = require("@angular/forms");
const directory_sync_integration_interface_1 = require("../models/directory-sync-integration.interface");
const validators_1 = require("../../api-applications/validators");
exports.serverTypeKeys = {
    active_directory: '$I18N_DIRECTORY_SYNC_TABLE.ACTIVE_DIRECTORY',
    domino: '$I18N_DIRECTORY_SYNC_TABLE.DOMINO',
    office_365: {
        standard: '$I18N_DIRECTORY_SYNC_TABLE.AZURE_STD',
        gov: '$I18N_DIRECTORY_SYNC_TABLE.AZURE_GCC'
    },
    ad_push: '$I18N_DIRECTORY_SYNC_TABLE.ACTIVE_DIRECTORY_SE',
    google_directory: '$I18N_DIRECTORY_SYNC_TABLE.GOOGLE_DIRECTORY'
};
exports.serverTypeIcons = {
    active_directory: 'mc-ldap-icon',
    domino: 'mc-domino-icon',
    office_365: 'mc-azure-icon',
    ad_push: 'mc-ldap-icon',
    google_directory: 'mc-google-icon'
};
exports.lastOutcome = {
    ok: {
        key: '$I18N_DIRECTORY_SYNC_TABLE.SUCCESS',
        icon: 'fa-check-circle'
    },
    error: {
        key: '$I18N_DIRECTORY_SYNC_TABLE.FAIL',
        icon: 'fa-times-circle'
    },
    not_active: {
        key: '$I18N_DIRECTORY_SYNC_TABLE.NOT_ACTIVE',
        icon: 'fa-exclamation-triangle'
    }
};
exports.validGSuiteJSONKeys = [
    'type',
    'project_id',
    'private_key_id',
    'private_key',
    'client_email',
    'client_id',
    'auth_uri',
    'token_uri',
    'auth_provider_x509_cert_url',
    'client_x509_cert_url'
];
exports.validTranslationKeys = [
    'ERR_DIRECTORY_STRICT_ENCRYPTION_MODE_REQUIRED',
    'ERR_DIRECTORY_GOOGLE_INVALID_KEY',
    'ERR_DIRECTORY_INVALID_SERVER_TYPE',
    'ERR_DIRECTORY_SYNC_INVALID_DOMAIN',
    'ERR_DIRECTORY_MSE_SITE_VERSION_INVALID',
    'ERR_DIRECTORY_CONNECTION_CREATION_FAILED',
    'ERR_DIRECTORY_CONNECTIONS_NOT_FOUND',
    'ERR_DIRECTORY_CONNECTION_UPDATE_FAILED',
    'ERR_DIRECTORY_ILLEGAL_CHARACTER',
    'ERR_DIRECTORY_PASSWORD_NOT_ENCODED',
    'ERR_FIELD_ROOTDN_NOT_PRESENT',
    'ERR_FIELD_ROOTDN_INVALID_FORMAT',
    'ERR_INVALID_HOSTNAME_OR_IP_ADDRESS',
    'ERR_VALIDATION_INVALID_EMAIL_ADDRESS'
];
function getServerTypeKey(integration) {
    if (integration.serverType === 'office_365' && integration.office365Settings) {
        return test_connection_interface_1.ServerSubType[integration.office365Settings.serverSubtype] === test_connection_interface_1.ServerSubType.gov
            ? exports.serverTypeKeys.office_365.gov
            : exports.serverTypeKeys.office_365.standard;
    }
    return exports.serverTypeKeys[integration.serverType];
}
exports.getServerTypeKey = getServerTypeKey;
// eslint-disable-next-line max-len
exports.ROOT_DN_REGEX = /^(?:[A-Za-z][\w-]*|\d+(?:\.\d+)*)=(?:#(?:[\dA-Fa-f]{2})+|(?:[^,=\+<>#;\\"]|\\[,=\+<>#;\\"]|\\[\dA-Fa-f]{2})*|"(?:[^\\"]|\\[,=\+<>#;\\"]|\\[\dA-Fa-f]{2})*")(?:\+(?:[A-Za-z][\w-]*|\d+(?:\.\d+)*)=(?:#(?:[\dA-Fa-f]{2})+|(?:[^,=\+<>#;\\"]|\\[,=\+<>#;\\"]|\\[\dA-Fa-f]{2})*|"(?:[^\\"]|\\[,=\+<>#;\\"]|\\[\dA-Fa-f]{2})*"))*(?:,(?:[A-Za-z][\w-]*|\d+(?:\.\d+)*)=(?:#(?:[\dA-Fa-f]{2})+|(?:[^,=\+<>#;\\"]|\\[,=\+<>#;\\"]|\\[\dA-Fa-f]{2})*|"(?:[^\\"]|\\[,=\+<>#;\\"]|\\[\dA-Fa-f]{2})*")(?:\+(?:[A-Za-z][\w-]*|\d+(?:\.\d+)*)=(?:#(?:[\dA-Fa-f]{2})+|(?:[^,=\+<>#;\\"]|\\[,=\+<>#;\\"]|\\[\dA-Fa-f]{2})*|"(?:[^\\"]|\\[,=\+<>#;\\"]|\\[\dA-Fa-f]{2})*"))*)*$/;
exports.HOST_TOOLTIP_KB_LINK = '/apps#sso/mcentral?goto=/s/article/Mimecast-Data-Centers-and-URLs-61190061';
function getPasswordValidation(isEditMode, hasServerTypeUpdatedOnEdit) {
    return [
        isEditMode && !hasServerTypeUpdatedOnEdit ? directory_sync_integration_interface_1.HiddenValues.key : '',
        getRequiredNotEmptyValidators(!isEditMode)
    ];
}
exports.getPasswordValidation = getPasswordValidation;
function getRequiredNotEmptyValidators(isRequired) {
    return isRequired ? [forms_1.Validators.required, validators_1.validateNotEmpty] : [validators_1.validateNotEmpty];
}
exports.getRequiredNotEmptyValidators = getRequiredNotEmptyValidators;
function mapFilters(fieldName, filters) {
    if (!!filters) {
        return filters.map(value => ({ fieldName, value }));
    }
    return [];
}
exports.mapFilters = mapFilters;
