"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PendoPackagesInformationProvider = void 0;
const operators_1 = require("rxjs/operators");
const base_pendo_information_provider_1 = require("../../base-pendo-information.provider");
class PendoPackagesInformationProvider extends base_pendo_information_provider_1.BasePendoInformationProvider {
    constructor(packages) {
        super();
        this.packages = packages;
    }
    getInformation() {
        return this.packages.getPackageCodes().pipe(operators_1.map(packageCodes => this.mapPackages(packageCodes)), operators_1.map(packageMap => ({
            account: Object.assign({}, packageMap)
        })));
    }
    mapPackages(packages) {
        return Object.keys(PendoPackagesInformationProvider.PENDO_PACKAGE_CODES).reduce((pmap, pcode) => {
            pmap[PendoPackagesInformationProvider.PENDO_PACKAGE_CODES[pcode]] = packages.includes(pcode);
            return pmap;
        }, {});
    }
}
exports.PendoPackagesInformationProvider = PendoPackagesInformationProvider;
PendoPackagesInformationProvider.PENDO_PACKAGE_CODES = {
    '1033': 'PKG_MimecastPlatform',
    '1081': 'PKG_MimecastBasicPlatform',
    '1039': 'PKG_SecureEmailGateway',
    '1001': 'PKG_AdvancedDisclaimers_Site',
    '1002': 'PKG_AdvancedMTA_Site',
    '1046': 'PKG_AdvancedMTA_Prime',
    '1004': 'PKG_AttachmentManagement_Site',
    '1056': 'PKG_AttachmentProtection_Site',
    '1059': 'PKG_AttachmentProtection_Pro',
    '1005': 'PKG_AutoResponders_Site',
    '1013': 'PKG_ContentControlAndDataLeakPrevention_Site',
    '1015': 'PKG_ContentControlAndDataLeakPrevention_Pro',
    '1063': 'PKG_ContinuityEventMonitor',
    '1020': 'PKG_EmailContinuity_Site',
    '1021': 'PKG_EmailContinuity_Prime',
    '1022': 'PKG_EmailContinuity_Pro',
    '1023': 'PKG_EmailEncryptionAndPrivacy_Site',
    '1060': 'PKG_ImpersonationProtection',
    '1064': 'PKG_InternalEmailProtect',
    '1031': 'PKG_MessageRecoveryService_Site',
    '1058': 'PKG_MessageRecoveryService_User',
    '1032': 'PKG_MetadataTrackAndTrace_Site',
    '1052': 'PKG_SecureMessaging',
    '1062': 'PKG_SecureMessaging_Lite',
    '1054': 'PKG_SMSNotifications',
    '1042': 'PKG_Stationery1_0_Site',
    '1075': 'PKG_ThreatRemediation',
    '1043': 'PKG_URLProtection_Site',
    '1007': 'PKG_CloudArchiveForEmail',
    '1066': 'PKG_CaseReview',
    '1008': 'PKG_CloudArchiveForFiles_Site',
    '1009': 'PKG_CloudArchiveForLyncIM_Site',
    '1017': 'PKG_EDiscoveryAndLitigationSupport_Site',
    '1018': 'PKG_EDiscoveryAndLitigationSupport_Pro',
    '1027': 'PKG_ExchangeManagedFolders_Site',
    '1026': 'PKG_ExchangeStorageManagement',
    '1044': 'PKG_LegacyArchiveDataManagement',
    '1079': 'PKG_Supervision',
    '1003': 'PKG_Branding',
    '1050': 'PKG_CalendarSync1_0',
    '1051': 'PKG_DesktopApps_Mac_Pro',
    '1016': 'PKG_DesktopApps_Outlook_Pro',
    '1061': 'PKG_EnhancedLogging',
    '1025': 'PKG_ExchangeMailboxAndFolderTools_Pro',
    '1069': 'PKG_ExchangeSync',
    '1065': 'PKG_ExchangeSyncAndRecover',
    '1057': 'PKG_GranularRetentionPolicies',
    '1053': 'PKG_JournalServices',
    '1028': 'PKG_LargeFileSend_Site',
    '1029': 'PKG_LargeFileSend_Prime',
    '1030': 'PKG_LargeFileSend_Pro',
    '1036': 'PKG_MobileApps_Pro',
    '1000': 'PKG_AdvancedAccountAdministration',
    '1038': 'PKG_FederatedAccountAdministration',
    '1067': 'PKG_ComplianceReview',
    '1083': 'PKG_E2EMigration',
    '1068': 'PKG_PrivacyPack',
    '1070': 'PKG_MailboxPermissionSync',
    '1055': 'PKG_MimecastMobilePro_Pro',
    '1082': 'PKG_PartnerManagedMigration',
    '1077': 'PKG_ThreatIntelligence',
    '1045': 'PKG_Broadcast',
    '1006': 'PKG_CloudArchiveConnectors_Site',
    '1024': 'PKG_EmailEncryptionAndPrivacy_Pro',
    '1': 'PKG_MimecastPersonalPortalBeta',
    '1040': 'PKG_ServerApps_Site',
    '1072': 'PKG_WebSecurity_Full',
    '1080': 'PKG_WebSecurity_NetworkOnly',
    '1078': 'PKG_AwarenessTraining',
    '1084': 'PKG_CloudProtect',
    '1095': 'PKG_CyberGraph'
};
