"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DashboardStatisticsBuilder = void 0;
const dashboard_statistics_1 = require("../dashboard-statistics");
const overview_builder_1 = require("./overview.builder");
const queue_details_builder_1 = require("./queue-details.builder");
const queue_reviewer_builder_1 = require("./queue-reviewer.builder");
const top_queues_builder_1 = require("./top-queues.builder");
const top_reviewer_performances_builder_1 = require("./top-reviewer-performances.builder");
const queue_type_enum_1 = require("app-new/archive/supervision/types/queue-type.enum");
class DashboardStatisticsBuilder {
    static build(statisticData) {
        const overview = overview_builder_1.OverviewBuilder.build(statisticData['overview']);
        const reviewQueues = statisticData['reviewQueueDetails'].map(queueDetails => queue_details_builder_1.QueueDetailsBuilder.build(queueDetails));
        const topReviewQueues = top_queues_builder_1.TopQueuesBuilder.build(reviewQueues, queue_type_enum_1.QueueType.REVIEW);
        const escalationQueues = statisticData['escalationQueueDetails'].map(queueDetails => queue_details_builder_1.QueueDetailsBuilder.build(queueDetails));
        const topEscalationQueues = top_queues_builder_1.TopQueuesBuilder.build(escalationQueues, queue_type_enum_1.QueueType.ESCALATION);
        const reviewQueuePerformers = statisticData['reviewerPerformance'].map(reviewerPerformance => queue_reviewer_builder_1.QueueReviewerPerformanceBuilder.build(reviewerPerformance));
        const topReviewQueuePerformances = top_reviewer_performances_builder_1.TopReviewerPerformancesBuilder.build(reviewQueuePerformers, queue_type_enum_1.QueueType.REVIEW, topReviewQueues.invalid.length > 0);
        const escalationQueuePerformers = statisticData['escalationManagerPerformance'].map(reviewerPerformance => queue_reviewer_builder_1.QueueReviewerPerformanceBuilder.build(reviewerPerformance));
        const topEscalationQueuePerformances = top_reviewer_performances_builder_1.TopReviewerPerformancesBuilder.build(escalationQueuePerformers, queue_type_enum_1.QueueType.ESCALATION, topEscalationQueues.invalid.length > 0);
        return new dashboard_statistics_1.DashboardStatistics(overview, topReviewQueues, topEscalationQueues, topReviewQueuePerformances, topEscalationQueuePerformances);
    }
}
exports.DashboardStatisticsBuilder = DashboardStatisticsBuilder;
