"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tableFilterSections = exports.tableColumnsWithLexiconSearchType = exports.defaultTableColumns = void 0;
exports.defaultTableColumns = [
    'name',
    'createdBy',
    'created',
    'updated',
    'updatedBy',
    'versions',
    'columnActions'
];
exports.tableColumnsWithLexiconSearchType = [
    'name',
    'createdBy',
    'created',
    'updated',
    'updatedBy',
    'versions',
    'searchType',
    'columnActions'
];
exports.tableFilterSections = [
    {
        name: 'searchType',
        filters: ['basic', 'advanced']
    }
];
