"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.URLPolicyParseFileFailure = exports.URLPolicyParseFileSuccess = exports.URLPolicyParseFileReset = exports.URLReportImageUploadedByUser = exports.URLPolicyParseFile = exports.URL_POLICY_UPLOADED_IMAGE = exports.URL_POLICY_PARSE_FILE_FAILURE = exports.URL_POLICY_PARSE_FILE_SUCCESS = exports.URL_POLICY_PARSE_FILE_RESET = exports.URL_POLICY_PARSE_FILE = void 0;
exports.URL_POLICY_PARSE_FILE = '[Policy] URL policy parse file';
exports.URL_POLICY_PARSE_FILE_RESET = '[Policy] URL policy parse file reset';
exports.URL_POLICY_PARSE_FILE_SUCCESS = '[Policy] URL policy parse file success';
exports.URL_POLICY_PARSE_FILE_FAILURE = '[Policy] URL policy parse file failure';
exports.URL_POLICY_UPLOADED_IMAGE = '[Policy] URL report uploaded image base64';
class URLPolicyParseFile {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.URL_POLICY_PARSE_FILE;
    }
}
exports.URLPolicyParseFile = URLPolicyParseFile;
class URLReportImageUploadedByUser {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.URL_POLICY_UPLOADED_IMAGE;
    }
}
exports.URLReportImageUploadedByUser = URLReportImageUploadedByUser;
class URLPolicyParseFileReset {
    constructor() {
        this.type = exports.URL_POLICY_PARSE_FILE_RESET;
    }
}
exports.URLPolicyParseFileReset = URLPolicyParseFileReset;
class URLPolicyParseFileSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.URL_POLICY_PARSE_FILE_SUCCESS;
    }
}
exports.URLPolicyParseFileSuccess = URLPolicyParseFileSuccess;
class URLPolicyParseFileFailure {
    constructor() {
        this.type = exports.URL_POLICY_PARSE_FILE_FAILURE;
    }
}
exports.URLPolicyParseFileFailure = URLPolicyParseFileFailure;
