"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateRangeHeldConfig = void 0;
const moment = require("moment");
const date_range_config_1 = require("app-new/message-center/common/components/table-search-configs/date-range-config");
class DateRangeHeldConfig extends date_range_config_1.DateRangeConfig {
    static get config() {
        return {
            allowCustomRange: false,
            minDate: moment().subtract(90, 'day'),
            maxDate: moment(),
            initialDateRange: 3,
            dateRanges: [
                {
                    id: 'PAST_24_HOURS',
                    label: '$I18N_MESSAGE_CENTER_TABLE_DATE_RANGE.LABEL_PAST_24_HOURS',
                    rangeFn: this.getRangeFunctionWithDayDifference(1)
                },
                {
                    id: 'PAST_48_HOURS',
                    label: '$I18N_MESSAGE_CENTER_TABLE_DATE_RANGE.LABEL_PAST_48_HOURS',
                    rangeFn: this.getRangeFunctionWithDayDifference(2)
                },
                {
                    id: 'PAST_7_DAYS',
                    label: '$I18N_MESSAGE_CENTER_TABLE_DATE_RANGE.LABEL_PAST_7_DAYS',
                    rangeFn: this.getRangeFunctionWithDayDifference(7)
                },
                {
                    id: 'PAST_30_DAYS',
                    label: '$I18N_MESSAGE_CENTER_TABLE_DATE_RANGE.LABEL_PAST_30_DAYS',
                    rangeFn: this.getRangeFunctionWithDayDifference(30)
                },
                {
                    id: 'PAST_60_DAYS',
                    label: '$I18N_MESSAGE_CENTER_TABLE_DATE_RANGE.LABEL_PAST_60_DAYS',
                    rangeFn: this.getRangeFunctionWithDayDifference(60)
                },
                {
                    id: 'PAST_90_DAYS',
                    label: '$I18N_MESSAGE_CENTER_TABLE_DATE_RANGE.LABEL_PAST_90_DAYS',
                    rangeFn: this.getRangeFunctionWithDayDifference(90)
                },
                {
                    id: 'CUSTOM',
                    label: '$I18N_MESSAGE_CENTER_TABLE_DATE_RANGE.LABEL_CUSTOM_RANGE'
                }
            ]
        };
    }
}
exports.DateRangeHeldConfig = DateRangeHeldConfig;
