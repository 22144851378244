<mc-siem-channel-wizard
  [channel]="channel$ | async"
  [wizardWorkflowType]="wizardFlowType$ | async"
  [metadataProducts]="metadataProducts$ | async"
  [metadataProductsByChannelType]="metadataProductsByChannelType$ | async"
  [apiIsProcessing]="apiIsProcessing$ | async"
  (updateChannelDescrInStore)="updateChannelDescrInStore($event)"
  (updateChannelProductsInStore)="updateChannelProductsInStore($event)"
  (updateProductEventsInStore)="updateChannelProductEventsInStore($event)"
  (onCancelWizard)="cancelWizard()"
  (onCreateChannel)="onCreateNewChannel()"
>

</mc-siem-channel-wizard>
