"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BackupStatusComponent = void 0;
const models_1 = require("../../models");
class BackupStatusComponent {
    constructor() {
        this.backupStatus = models_1.BackupStatus;
    }
}
exports.BackupStatusComponent = BackupStatusComponent;
