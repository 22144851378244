"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IntegrationTableComponent = void 0;
const core_1 = require("@angular/core");
const okta_api_modal_1 = require("app-new/api-applications/components/modals/okta-api-modal");
const integration_table_static_values_1 = require("./integration-table.static-values");
const rxjs_1 = require("rxjs");
class IntegrationTableComponent {
    constructor(translateService, modalService) {
        this.translateService = translateService;
        this.modalService = modalService;
        this.DESCRIPTION_PREFIX_KEY = '$I18N_API_INTEGRATION.DESCRIPTIONS';
        this.columns = integration_table_static_values_1.columns;
        this.columnsAlwaysVisible = integration_table_static_values_1.columnsAlwaysVisible;
        this.columnsStartHidden = integration_table_static_values_1.columnsStartHidden;
        this.oktaLastActivityColumn = integration_table_static_values_1.oktaOnlyColumn;
        this.destroy$ = new rxjs_1.Subject();
        this.onPageChanged = new core_1.EventEmitter();
        this.onViewIntegration = new core_1.EventEmitter();
        this.onCreateIntegration = new core_1.EventEmitter();
        this.onEditIntegration = new core_1.EventEmitter();
        this.onEnableIntegration = new core_1.EventEmitter();
        this.onDisableIntegration = new core_1.EventEmitter();
        this.onViewActivityLogs = new core_1.EventEmitter();
        this.onClearQueryParams = new core_1.EventEmitter();
        this.SCIM_OKTA = 'scim_okta';
        this.OKTA_EVIDENCE_BASED_CONTROL = 'okta_evidence_based_control';
    }
    ngOnInit() {
        if (this.showApiModal && this.oktaIntegrationAccessToken) {
            this.showUpdateModal();
        }
        else if (this.showApiModal) {
            this.clearQueryParams();
        }
        if (this.isOktaIntegrationEnabled && integration_table_static_values_1.columns.indexOf(integration_table_static_values_1.oktaOnlyColumn) === -1) {
            integration_table_static_values_1.columns.splice(integration_table_static_values_1.columns.length - 1, 0, integration_table_static_values_1.oktaOnlyColumn);
        }
    }
    showUpdateModal() {
        const modalConfig = {};
        modalConfig.size = 'lg';
        modalConfig.hasBackdrop = true;
        modalConfig.disableClose = true;
        modalConfig.data = {
            accessToken: this.oktaIntegrationAccessToken,
            rotateToken: this.rotateToken
        };
        this.modalService.open(okta_api_modal_1.OktaApiModalComponent, modalConfig);
    }
    changePage(page) {
        this.onPageChanged.emit(page);
    }
    viewIntegration(integration) {
        this.onViewIntegration.emit(integration);
    }
    createIntegration() {
        this.onCreateIntegration.emit();
    }
    editIntegration(integration) {
        this.onEditIntegration.emit(integration);
    }
    enableIntegration(integration) {
        this.onEnableIntegration.emit(integration);
    }
    disableIntegration(integration) {
        this.onDisableIntegration.emit(integration);
    }
    clearQueryParams() {
        this.onClearQueryParams.emit();
    }
    openCrowdstrikeActivityLogPage(integration) {
        if (integration && integration.integrationType === 'crowdstrike') {
            this.onViewActivityLogs.emit(integration.id);
        }
    }
    getIntegrationDescription(integration) {
        if (integration.integrationType === 'cortex') {
            if (integration.descriptionKeys) {
                return this.translateService.instant(`${this.DESCRIPTION_PREFIX_KEY}.${integration.descriptionKeys.toUpperCase()}`);
            }
            return integration.description;
        }
        else if (integration.integrationType === 'crowdstrike') {
            if (integration.descriptionKeys) {
                return this.translateService.instant(`${this.DESCRIPTION_PREFIX_KEY}.${integration.descriptionKeys.toUpperCase()}`);
            }
            else if (integration.description) {
                return integration.description;
            }
            return this.translateService.instant('$I18N_API_INTEGRATION_LIST_TABLE.CROWDSTRIKE.DESCRIPTION');
        }
        else if (integration.integrationType === this.SCIM_OKTA) {
            return integration.description;
        }
        else if (integration.integrationType === this.OKTA_EVIDENCE_BASED_CONTROL) {
            return this.translateService.instant('$I18N_API_INTEGRATION_LIST_TABLE.OKTA_EVIDENCE_BASED_CONTROL.DESCRIPTION');
        }
        return this.translateService.instant('$I18N_API_INTEGRATION_LIST_TABLE.WILDFIRE.DESCRIPTION');
    }
    getIntegrationIdentifier(identifier) {
        if (identifier) {
            return decodeURI(identifier);
        }
    }
    isOktaIntegrationAndAwaitingFirstRun(integration) {
        return (integration.integrationType === this.SCIM_OKTA && integration.enabled && !integration.activity);
    }
    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
}
exports.IntegrationTableComponent = IntegrationTableComponent;
