"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetURLReportGetProfileEmailAction = exports.UrlActivityReportGetProfileEmailFailureAction = exports.UrlActivityReportGetProfileEmailSuccessAction = exports.ClearSidePanelState = exports.CloseLogsSidePanelAction = exports.HideScreenshotZoomModalAction = exports.GetURLReportGetCategoriesAction = exports.UrlActivityReportGetCategoriesFailureAction = exports.UrlActivityReportGetCategoriesSuccessAction = exports.ShowUrlReportingModal = exports.ShowScreenshotZoomModalAction = exports.OpenLogsSidePanelAction = exports.URL_ACTIVITY_REPORT_GET_PROFILE_EMAIL_FAILURE_ACTION = exports.URL_ACTIVITY_REPORT_GET_PROFILE_EMAIL_SUCCESS_ACTION = exports.URL_REPORT_GET_PROFILE_EMAIL_ACTION = exports.URL_ACTIVITY_REPORT_GET_CATEGORIES_FAILURE_ACTION = exports.URL_ACTIVITY_REPORT_GET_CATEGORIES_SUCCESS_ACTION = exports.URL_PROTECTION_SCREENSHOT_CLOSE = exports.URL_REPORT_GET_CATEGORIES_ACTION = exports.URL_PROTECTION_REPORT_URL_OPEN = exports.URL_PROTECTION_SCREENSHOT_OPEN = exports.CLEAR_SIDE_PANEL_STATE = exports.CLOSE_LOGS_SIDE_PANEL = exports.OPEN_LOGS_SIDE_PANEL = void 0;
exports.OPEN_LOGS_SIDE_PANEL = '[Services / Url protection / Logs] Open side panel';
exports.CLOSE_LOGS_SIDE_PANEL = '[Services / Url protection / Logs] Close side panel';
exports.CLEAR_SIDE_PANEL_STATE = '[Services / Url protection / Logs] Clear side panel state';
exports.URL_PROTECTION_SCREENSHOT_OPEN = '[Services / Url protection] Open  Screenshot Modal';
exports.URL_PROTECTION_REPORT_URL_OPEN = '[Services / Url protection] Open  URL Report Modal';
exports.URL_REPORT_GET_CATEGORIES_ACTION = '[Services / Url protection] Get Url Categories';
exports.URL_PROTECTION_SCREENSHOT_CLOSE = '[Services / Url protection] Close  Screenshot Modal';
exports.URL_ACTIVITY_REPORT_GET_CATEGORIES_SUCCESS_ACTION = ' [Services / Url protection] Get Url Categories Success';
exports.URL_ACTIVITY_REPORT_GET_CATEGORIES_FAILURE_ACTION = ' [Services / Url protection] Get Url Categories Failure';
exports.URL_REPORT_GET_PROFILE_EMAIL_ACTION = '[Services / Url protection] Get User Profile Email';
exports.URL_ACTIVITY_REPORT_GET_PROFILE_EMAIL_SUCCESS_ACTION = ' [Services / Url protection] Get User Profile Email Success';
exports.URL_ACTIVITY_REPORT_GET_PROFILE_EMAIL_FAILURE_ACTION = ' [Services / Url protection] Get User Profile Email Failure';
class OpenLogsSidePanelAction {
    constructor() {
        this.type = exports.OPEN_LOGS_SIDE_PANEL;
    }
}
exports.OpenLogsSidePanelAction = OpenLogsSidePanelAction;
class ShowScreenshotZoomModalAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.URL_PROTECTION_SCREENSHOT_OPEN;
    }
}
exports.ShowScreenshotZoomModalAction = ShowScreenshotZoomModalAction;
class ShowUrlReportingModal {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.URL_PROTECTION_REPORT_URL_OPEN;
    }
}
exports.ShowUrlReportingModal = ShowUrlReportingModal;
class UrlActivityReportGetCategoriesSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.URL_ACTIVITY_REPORT_GET_CATEGORIES_SUCCESS_ACTION;
    }
}
exports.UrlActivityReportGetCategoriesSuccessAction = UrlActivityReportGetCategoriesSuccessAction;
class UrlActivityReportGetCategoriesFailureAction {
    constructor() {
        this.type = exports.URL_ACTIVITY_REPORT_GET_CATEGORIES_FAILURE_ACTION;
    }
}
exports.UrlActivityReportGetCategoriesFailureAction = UrlActivityReportGetCategoriesFailureAction;
class GetURLReportGetCategoriesAction {
    constructor() {
        this.type = exports.URL_REPORT_GET_CATEGORIES_ACTION;
    }
}
exports.GetURLReportGetCategoriesAction = GetURLReportGetCategoriesAction;
class HideScreenshotZoomModalAction {
    constructor() {
        this.type = exports.URL_PROTECTION_SCREENSHOT_CLOSE;
    }
}
exports.HideScreenshotZoomModalAction = HideScreenshotZoomModalAction;
class CloseLogsSidePanelAction {
    constructor() {
        this.type = exports.CLOSE_LOGS_SIDE_PANEL;
    }
}
exports.CloseLogsSidePanelAction = CloseLogsSidePanelAction;
class ClearSidePanelState {
    constructor() {
        this.type = exports.CLEAR_SIDE_PANEL_STATE;
    }
}
exports.ClearSidePanelState = ClearSidePanelState;
class UrlActivityReportGetProfileEmailSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.URL_ACTIVITY_REPORT_GET_PROFILE_EMAIL_SUCCESS_ACTION;
    }
}
exports.UrlActivityReportGetProfileEmailSuccessAction = UrlActivityReportGetProfileEmailSuccessAction;
class UrlActivityReportGetProfileEmailFailureAction {
    constructor() {
        this.type = exports.URL_ACTIVITY_REPORT_GET_PROFILE_EMAIL_FAILURE_ACTION;
    }
}
exports.UrlActivityReportGetProfileEmailFailureAction = UrlActivityReportGetProfileEmailFailureAction;
class GetURLReportGetProfileEmailAction {
    constructor() {
        this.type = exports.URL_REPORT_GET_PROFILE_EMAIL_ACTION;
    }
}
exports.GetURLReportGetProfileEmailAction = GetURLReportGetProfileEmailAction;
