"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const archive_viewer_search_page_component_1 = require("./containers/archive-viewer-search-page/archive-viewer-search-page.component");
angular
    .module('archive-viewer', [])
    .controller('ArchiveViewerController', [
    '$stateParams',
    '$scope',
    function ($stateParams, $scope) {
        $scope.stateParams = $stateParams;
    }
])
    .directive('mcArchiveViewer', static_1.downgradeComponent({
    component: archive_viewer_search_page_component_1.ArchiveViewerSearchPageComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider.state('archive-viewer', {
            url: '/archive/viewer',
            data: {
                isFixedLayout: false,
                checkProgress: false,
                tabTitle: 'Archive Viewer',
                capabilities: 'Permission.ARCHIVE_VIEWER_READ && Permission.ARCHIVE_VIEWER_CUSTOMER_CONTENT_ACCESS',
                breadcrumbs: []
            },
            views: {
                main: {
                    template: '<mc-archive-viewer ' +
                        'style="height:100%;width:100%;position:absolute;overflow:scroll;">' +
                        '</mc-archive-viewer>',
                    controller: 'ArchiveViewerController'
                }
            }
        });
    }
]);
