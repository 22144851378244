"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Contact = void 0;
const item_1 = require("app-new/components/tree/model/item");
class Contact extends item_1.Item {
    constructor(emailAddress, created, company, modified, size, id, fullName, lastChangeType, parentId) {
        super(modified, size, id, fullName, null, lastChangeType, parentId);
        this.emailAddress = emailAddress;
        this.created = created;
        this.company = company;
    }
    getEmailAddress() {
        return this.emailAddress;
    }
    getCreated() {
        return this.created;
    }
    getCompany() {
        return this.company;
    }
}
exports.Contact = Contact;
