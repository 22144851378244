"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckUrlScanResultsComponent = void 0;
class CheckUrlScanResultsComponent {
    constructor() {
        this.isDetails = false;
        this.isModal = false;
    }
    setScanResultIcon(result) {
        let iconClass;
        switch (result) {
            case 'Clean':
                iconClass = 'fa-check-circle';
                break;
            case '-':
                iconClass = 'hidden';
                break;
            default:
                iconClass = 'fa-times-circle';
        }
        return iconClass;
    }
}
exports.CheckUrlScanResultsComponent = CheckUrlScanResultsComponent;
