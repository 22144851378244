"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const _ = require("lodash");
const operators_1 = require("rxjs/operators");
const core_interfaces_1 = require("../../core/model/core.interfaces");
const capabilities_service_1 = require("./capabilities.service");
/* eslint-disable */
angular
    .module('old-capabilities-override.service', [])
    .factory('newCapabilitiesService', static_1.downgradeInjectable(capabilities_service_1.CapabilitiesService))
    .factory('capabilitiesService', [
    'newCapabilitiesService',
    '$rootScope',
    '$window',
    function (newCapabilitiesService, $rootScope, $window) {
        const service = {
            setSelectedUserAndInit: function () { },
            initialize: function () { },
            getCapabilities: getCapabilities,
            areCapabilitiesLoaded: areCapabilitiesLoaded,
            hasCapability: hasCapability,
            evalCapabilitiesExpression: evalCapabilitiesExpression,
            hasEarlyAccessCapability: hasEarlyAccessCapability,
            evalEarlyAccessCapabilitiesExpression: evalEarlyAccessCapabilitiesExpression,
            evalNewCapabilitiesExpression: evalNewCapabilitiesExpression,
            hasAccountCodeCapability: hasAccountCodeCapability
        };
        activate();
        return service;
        function getCapabilities() {
            return newCapabilitiesService.capabilities.pipe(core_interfaces_1.isDefined(), operators_1.first()).toPromise();
        }
        function areCapabilitiesLoaded() {
            return newCapabilitiesService.areCapabilitiesLoaded();
        }
        function hasCapability(cap) {
            let result = false;
            newCapabilitiesService.hasCapability(cap).subscribe((exist) => (result = exist));
            return result;
        }
        function hasEarlyAccessCapability(cap) {
            let result = false;
            newCapabilitiesService
                .hasEarlyAccessCapability(cap)
                .subscribe((exist) => (result = exist));
            return result;
        }
        function evalCapabilitiesExpression(expression) {
            let result = false;
            newCapabilitiesService
                .evalCapabilitiesExpression(expression)
                .subscribe((exist) => (result = exist));
            return result;
        }
        function evalEarlyAccessCapabilitiesExpression(expression) {
            let result = false;
            newCapabilitiesService
                .evalEarlyAccessCapabilitiesExpression(expression)
                .subscribe((exist) => (result = exist));
            return result;
        }
        function evalNewCapabilitiesExpression(expression) {
            let result = false;
            newCapabilitiesService
                .evalNewCapabilitiesExpression(expression)
                .subscribe((exist) => (result = exist));
            return result;
        }
        function hasAccountCodeCapability(capName, accountCode) {
            let result = false;
            newCapabilitiesService
                .hasAccountCodeCapability(capName, accountCode)
                .subscribe((exist) => (result = exist));
            return result;
        }
        function activate() {
            // @ts-ignore
            function hasCapabilities(state) {
                if (state.data && state.data.capabilities) {
                    return state.data.capabilities;
                }
                if (state && state.views) {
                    var view = _.find(state.views, function (view) {
                        if (view.data && view.data.capabilities) {
                            return !!view.data.capabilities;
                        }
                    });
                    return view && view.data && view.data.capabilities;
                }
            }
            $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
                var capabilities = hasCapabilities(toState);
                if (capabilities) {
                    newCapabilitiesService.evalCapabilitiesExpression(capabilities).subscribe(ok => {
                        if (!ok) {
                            event.preventDefault();
                            console.warn('Forbidden Access to :' + toState.url);
                            $window.location.hash = '';
                        }
                    });
                }
            });
        }
    }
]);
