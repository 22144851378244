"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProviderSelectionStepComponent = void 0;
const forms_1 = require("@angular/forms");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const store_1 = require("@ngrx/store");
const step_base_component_1 = require("../../../step.base.component");
const connector_wizard_service_1 = require("../../../../../services/connector-wizard.service");
const change_confirmation_modal_component_1 = require("../../../../change-confirmation-modal/change-confirmation-modal.component");
const model_1 = require("../../../../../model");
const connector_actions_1 = require("../../../../../actions/connector.actions");
const reducers_1 = require("../../../../../reducers");
const index_1 = require("rxjs/index");
class ProviderSelectionStepComponent extends step_base_component_1.StepBaseDirective {
    constructor(store, fb, modalService, stateService) {
        super(store, fb, model_1.ConnectorStep.PROVIDER, connector_wizard_service_1.ConnectorWizardService.WizardId);
        this.store = store;
        this.fb = fb;
        this.modalService = modalService;
        this.stateService = stateService;
        this.errorType = model_1.ErrorType.PROVIDERS;
        this.destroy$ = new rxjs_1.Subject();
        this.form = this.fb.group({
            provider: [null, forms_1.Validators.required]
        });
        this.isLoadingProviders$ = this.store.select(reducers_1.isLoadingProviders);
        this.form.controls['provider'].valueChanges
            .pipe(operators_1.takeUntil(this.destroy$))
            .subscribe(provider => {
            this.currentSelection = provider;
            this.previousSelection = this.form.value['provider'];
        });
        this.store
            .pipe(operators_1.takeUntil(this.destroy$), store_1.select(reducers_1.getStepData(connector_wizard_service_1.ConnectorWizardService.WizardId, model_1.ConnectorStep.CONSENT)), operators_1.filter((data) => data), operators_1.map(stepData => !!stepData.authToken))
            .subscribe(consent => (this.hasConsented = consent));
        this.store.pipe(operators_1.takeUntil(this.destroy$), store_1.select(reducers_1.getFormResets)).subscribe(formResets => {
            if (formResets.provider) {
                this.form.reset();
            }
        });
    }
    set selectedProduct(product) {
        if (product) {
            this.product = product;
            this.loadProviders();
        }
    }
    ngOnInit() {
        super.ngOnInit();
        if (this.editFlow !== true) {
            this.providers$ = this.store.select(reducers_1.getProviders);
            this.providers$
                .pipe(operators_1.takeUntil(this.destroy$), operators_1.map(providers => this.isGwsProviderEnabled
                ? providers
                : providers.filter(provider => model_1.ProviderType.GOOGLE_WORKSPACE !== provider.type)))
                .subscribe(providers => {
                if (providers && providers.length === 1) {
                    this.form.controls['provider'].setValue(providers[0]);
                }
            });
        }
        else {
            const provider = {
                type: this.stateService.$state.params.provider
            };
            this.providers$ = index_1.of([provider]);
            this.form.controls['provider'].setValue(provider);
            this.form.controls['provider'].disable();
        }
    }
    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
    loadProviders() {
        this.store.dispatch(new connector_actions_1.LoadProvidersAction(this.product.id));
    }
    onProviderSelect() {
        if (this.hasConsented) {
            this.openChangeConfirmationModal();
        }
    }
    openChangeConfirmationModal() {
        const data = {
            changeType: model_1.ChangeType.PROVIDER,
            provider: this.previousSelection
        };
        const modalConfig = {};
        modalConfig.size = 'md';
        modalConfig.hasBackdrop = true;
        modalConfig.disableClose = true;
        modalConfig.data = data;
        this.modalService
            .open(change_confirmation_modal_component_1.ChangeConfirmationModalComponent, modalConfig)
            .afterClose()
            .pipe(operators_1.takeUntil(this.destroy$))
            .subscribe((changeConfirmed) => {
            if (changeConfirmed) {
                this.store.dispatch(new connector_actions_1.ProviderSelectionUpdatedAction({ wizardId: this.wizardId }));
                this.form.controls['provider'].setValue(this.currentSelection);
            }
            else {
                this.form.controls['provider'].setValue(this.previousSelection);
            }
        });
    }
}
exports.ProviderSelectionStepComponent = ProviderSelectionStepComponent;
