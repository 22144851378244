"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteConfirmationModalComponent = void 0;
const actions = require("app-new/custom-domain-block/actions/configurations-table.action");
class DeleteConfirmationModalComponent {
    constructor(store, data) {
        this.store = store;
        this.data = data;
    }
    getPolicyName() {
        return this.data.policy.name;
    }
    confirm() {
        this.store.dispatch(new actions.DeleteYesAction(this.data.policy.definitionId));
    }
    cancel() {
        this.store.dispatch(new actions.DeleteNoAction());
    }
}
exports.DeleteConfirmationModalComponent = DeleteConfirmationModalComponent;
