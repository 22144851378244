"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogsService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const table_store_1 = require("@mimecast-ui/table-store");
const logDetailsActions = require("../../actions/log-details.actions");
const log_details_container_component_1 = require("../../containers/log-details/log-details-container.component");
class LogsService {
    constructor(httpClient, asideService, store) {
        this.httpClient = httpClient;
        this.asideService = asideService;
        this.store = store;
    }
    static getReturnDataFromApiResponse(response) {
        return {
            data: response.first.impersonationLogs,
            meta: response.meta,
            hasErrors: response.hasErrors,
            failures: response.fail
        };
    }
    static getDateRangeFromQuery(query) {
        return {
            from: query.from,
            to: query.to
        };
    }
    static getQueryAndSearchFieldFromQuery(query) {
        return query.searchQuery
            ? {
                searchField: 'all',
                query: query.searchQuery
            }
            : {};
    }
    static getIdentifiersFromQuery(query) {
        if (query.columnValues && query.columnValues.identifiers) {
            let identifiers = [...query.columnValues.identifiers];
            if (identifiers.indexOf('similar_internal_domain') !== -1) {
                identifiers = [...identifiers, ...['advanced_similar_internal_domain']];
            }
            if (query.columnValues.identifiers.indexOf('similar_monitored_external_domain') !== -1) {
                identifiers = [
                    ...identifiers,
                    ...[
                        'custom_external_domain',
                        'mimecast_external_domain',
                        'advanced_custom_external_domain',
                        'advanced_mimecast_external_domain'
                    ]
                ];
                identifiers.splice(identifiers.indexOf('similar_monitored_external_domain'), 1);
            }
            return { identifiers };
        }
        else {
            return {};
        }
    }
    static getActionsFromQuery(query) {
        return query.columnValues && query.columnValues.actions
            ? { actions: query.columnValues.actions }
            : {};
    }
    static getPayloadFromQuery(query) {
        let payload = {};
        payload = Object.assign(Object.assign({}, payload), LogsService.getDateRangeFromQuery(query));
        payload = Object.assign(Object.assign({}, payload), LogsService.getQueryAndSearchFieldFromQuery(query));
        payload = Object.assign(Object.assign({}, payload), LogsService.getIdentifiersFromQuery(query));
        payload = Object.assign(Object.assign({}, payload), LogsService.getActionsFromQuery(query));
        return payload;
    }
    getData(payload, pagination = table_store_1.defaultPaginationOption) {
        return this.httpClient
            .post('/api/ttp/impersonation/get-logs', Object.assign(Object.assign({}, payload), { meta: { pagination } }))
            .pipe(operators_1.map((response) => {
            return LogsService.getReturnDataFromApiResponse(response);
        }), operators_1.catchError((response) => {
            return rxjs_1.of(LogsService.getReturnDataFromApiResponse(response));
        }));
    }
    filter(query, pagination) {
        return this.getData(LogsService.getPayloadFromQuery(query), pagination);
    }
    openItem() {
        this.asideInstance = this.asideService.open(log_details_container_component_1.LogDetailsContainerComponent, {
            position: 'right',
            size: 'md',
            hasBackdrop: true
        });
        // @ts-ignore
        return this.asideInstance.afterClose().pipe(operators_1.map(() => {
            this.asideInstance = undefined;
            this.store.dispatch(new logDetailsActions.HideLogDetailsAction());
        }));
    }
}
exports.LogsService = LogsService;
