"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewDetailsComponent = void 0;
const selectors = require("../../reducers/index");
const actions = require("../../actions");
const actions_1 = require("../../actions");
const table_store_1 = require("@mimecast-ui/table-store");
const services_1 = require("../../services");
class ViewDetailsComponent {
    constructor(store) {
        this.store = store;
    }
    ngOnInit() {
        this.messageDetails$ = this.store.select(selectors.getSelectedRow);
        this.messageLoading$ = this.store.select(selectors.isDetailLoading);
        this.paginationInfo$ = this.store.select(selectors.getDetailsPaginationInfo);
        this.subscription = this.messageDetails$.subscribe(messageDetails => (this.messageDetails = messageDetails));
    }
    ngOnDestroy() {
        this.store.dispatch(new actions.CleanMessageAction());
        this.store.dispatch(new table_store_1.ClearSelectedRowAction({
            tableId: services_1.SecureMessagingService.LOGS_TABLE
        }));
        this.subscription.unsubscribe();
    }
    loadDetails(message) {
        this.store.dispatch(new actions.GetMessageDetailsAction(message));
    }
    onRecall(messageId) {
        this.store.dispatch(new actions_1.RecallAction({ smIDs: [messageId] }));
        this.close();
    }
    close() {
        this.store.dispatch(new actions.CloseSidePaneAction());
        this.store.dispatch(new actions.CleanMessageAction());
    }
}
exports.ViewDetailsComponent = ViewDetailsComponent;
