"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.getApiProcessingInProgress = exports.getApiSavingStatus = exports.getLoadingForEdit = exports.getShowAffectedUserAddedNotification = exports.getShowDuplicateWarning = exports.getMepPolicy = exports.getCreatePolicy = exports.getMepPolicyState = exports.reducers = void 0;
const store_1 = require("@ngrx/store");
const mepPolicy = require("../reducers/mep-policy.reducer");
exports.reducers = {
    createPolicy: mepPolicy.reducer
};
exports.getMepPolicyState = store_1.createFeatureSelector('mepPolicies');
const ɵ0 = state => state.createPolicy;
exports.ɵ0 = ɵ0;
exports.getCreatePolicy = store_1.createSelector(exports.getMepPolicyState, ɵ0);
exports.getMepPolicy = store_1.createSelector(exports.getCreatePolicy, mepPolicy.getMepPolicy);
exports.getShowDuplicateWarning = store_1.createSelector(exports.getCreatePolicy, mepPolicy.getShowDuplicateWarning);
exports.getShowAffectedUserAddedNotification = store_1.createSelector(exports.getCreatePolicy, mepPolicy.getShowAffectedUserAddedNotification);
exports.getLoadingForEdit = store_1.createSelector(exports.getCreatePolicy, mepPolicy.getLoadingForEdit);
exports.getApiSavingStatus = store_1.createSelector(exports.getCreatePolicy, mepPolicy.getApiSavingStatus);
exports.getApiProcessingInProgress = store_1.createSelector(exports.getCreatePolicy, mepPolicy.getApiProcessingInProgress);
