"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiApplicationEffects = void 0;
const effects_1 = require("@ngrx/effects");
const operators_1 = require("rxjs/operators");
const user_actions_1 = require("app-new/core/actions/user.actions");
const notification_actions_1 = require("app-new/components/notification/actions/notification.actions");
const new_features_modal_1 = require("app-new/api-applications/components/modals/new-features-modal");
const actions = require("../actions");
class ApiApplicationEffects {
    constructor(actions$, modalService, translateService) {
        this.actions$ = actions$;
        this.modalService = modalService;
        this.translateService = translateService;
        this.generalFailure$ = this.actions$.pipe(effects_1.ofType(actions.ApiApplicationActionTypes.ApiApplicationFailure), operators_1.switchMap((action) => {
            return [
                new notification_actions_1.NotificationShowAction({
                    type: 'error',
                    config: { msg: this.translateService.instant(action.payload) }
                })
            ];
        }));
        this.displayNewFeaturesModal$ = this.actions$.pipe(effects_1.ofType(actions.ApiApplicationActionTypes.ApiApplicationShowNewFeaturesModal), operators_1.switchMap(() => this.modalService
            .open(new_features_modal_1.NewFeaturesModalComponent, {
            size: 'md',
            hasBackdrop: true,
            disableClose: true
        })
            .afterClose()
            .pipe(operators_1.map(() => new user_actions_1.PreferencesSaveAction({
            apiApplicationsPreferences: { newFeaturesNotificationViewed: true }
        })))));
    }
}
__decorate([
    effects_1.Effect()
], ApiApplicationEffects.prototype, "generalFailure$", void 0);
__decorate([
    effects_1.Effect()
], ApiApplicationEffects.prototype, "displayNewFeaturesModal$", void 0);
exports.ApiApplicationEffects = ApiApplicationEffects;
