"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RemediationTabChangeService = void 0;
const rxjs_1 = require("rxjs");
class RemediationTabChangeService {
    constructor(capabilitiesService) {
        this.capabilitiesService = capabilitiesService;
    }
    getBlockTabClicksObservable() {
        return this.blockTabClicks;
    }
    createNewTabsChangeSubject() {
        this.$tabsChanged = new rxjs_1.BehaviorSubject(null);
        this.tabsChanged = this.$tabsChanged.asObservable();
    }
    createNewBlockTabsSubject() {
        this.$blockTabClicks = new rxjs_1.BehaviorSubject(null);
        this.blockTabClicks = this.$blockTabClicks.asObservable();
    }
    setTabs(tabs) {
        this.tabs = tabs;
        this.currentTabIndex = this.tabs.activeTabIndex;
    }
    setActiveTab(index, name = '') {
        if (name) {
            this.tabs.contentTabs.forEach((item, idx) => {
                if (item.name.toLowerCase().includes(name)) {
                    this.tabs.tabClicked(idx);
                }
            });
        }
        else {
            this.tabs.tabClicked(index);
        }
    }
    setTabHistory(tabIndex) {
        this.previousTabIndex = this.currentTabIndex;
        this.currentTabIndex = tabIndex;
        const tabs = {
            previous: this.previousTabIndex,
            current: this.currentTabIndex
        };
        this.$tabsChanged.next(tabs);
    }
    hasSearchEnabled() {
        return this.capabilitiesService.hasCapability('Permission.SEARCH_READ');
    }
    swapTabIndexes() {
        const tmp = this.previousTabIndex;
        this.previousTabIndex = this.currentTabIndex;
        this.currentTabIndex = tmp;
        return {
            previous: this.previousTabIndex,
            current: this.currentTabIndex
        };
    }
    setBlockTabs(isFormDirty = false) {
        this.$blockTabClicks.next({ isFormDirty });
    }
}
exports.RemediationTabChangeService = RemediationTabChangeService;
