"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgPolicyParseFileFailure = exports.SwgPolicyParseFileSuccess = exports.SwgPolicyParseFileReset = exports.SwgPolicyParseFile = exports.SWG_POLICY_PARSE_FILE_FAILURE = exports.SWG_POLICY_PARSE_FILE_SUCCESS = exports.SWG_POLICY_PARSE_FILE_RESET = exports.SWG_POLICY_PARSE_FILE = void 0;
exports.SWG_POLICY_PARSE_FILE = '[Policy] Swg policy parse file';
exports.SWG_POLICY_PARSE_FILE_RESET = '[Policy] Swg policy parse file reset';
exports.SWG_POLICY_PARSE_FILE_SUCCESS = '[Policy] Swg policy parse file success';
exports.SWG_POLICY_PARSE_FILE_FAILURE = '[Policy] Swg policy parse file failure';
class SwgPolicyParseFile {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SWG_POLICY_PARSE_FILE;
    }
}
exports.SwgPolicyParseFile = SwgPolicyParseFile;
class SwgPolicyParseFileReset {
    constructor() {
        this.type = exports.SWG_POLICY_PARSE_FILE_RESET;
    }
}
exports.SwgPolicyParseFileReset = SwgPolicyParseFileReset;
class SwgPolicyParseFileSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.SWG_POLICY_PARSE_FILE_SUCCESS;
    }
}
exports.SwgPolicyParseFileSuccess = SwgPolicyParseFileSuccess;
class SwgPolicyParseFileFailure {
    constructor() {
        this.type = exports.SWG_POLICY_PARSE_FILE_FAILURE;
    }
}
exports.SwgPolicyParseFileFailure = SwgPolicyParseFileFailure;
