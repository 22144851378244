"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRequestSecondFactorFirst = exports.getSecondFactorOption = exports.getMimeAuthEnabled = exports.getCloudAuthentication = exports.getAlternateDomainSuffix = exports.getCasServer = exports.getDomain = exports.reducer = exports.initialState = void 0;
const actions = require("../../actions/policy-wizard/authentication-methods-step.actions");
const policy_1 = require("../../models/policy");
exports.initialState = {
    domain: null,
    cloudAuthentication: policy_1.CloudPasswordOption.NEVER_ALLOW,
    casServer: '',
    alternateDomainSuffix: '',
    mimeAuthEnabled: true,
    secondFactorOption: policy_1.SecondFactorOption.SMS,
    requestSecondFactorFirst: true
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.SAVE_DOMAIN:
            return Object.assign(Object.assign({}, state), { domain: action.payload });
        case actions.SAVE_CAS_SERVER:
            return Object.assign(Object.assign({}, state), { casServer: action.payload });
        case actions.SAVE_ALTERNATE_DOMAIN_SUFFIX:
            return Object.assign(Object.assign({}, state), { alternateDomainSuffix: action.payload });
        case actions.SAVE_CLOUD_AUTHENTICATION:
            return Object.assign(Object.assign({}, state), { cloudAuthentication: action.payload });
        case actions.SAVE_MIME_AUTH_ENABLED:
            return Object.assign(Object.assign({}, state), { mimeAuthEnabled: action.payload });
        case actions.SAVE_SECOND_FACTOR_OPTION:
            return Object.assign(Object.assign({}, state), { secondFactorOption: action.payload });
        case actions.SAVE_REQUEST_SECOND_FACTOR_FIRST:
            return Object.assign(Object.assign({}, state), { requestSecondFactorFirst: action.payload });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getDomain = (state) => state.domain;
exports.getDomain = getDomain;
const getCasServer = (state) => state.casServer;
exports.getCasServer = getCasServer;
const getAlternateDomainSuffix = (state) => state.alternateDomainSuffix;
exports.getAlternateDomainSuffix = getAlternateDomainSuffix;
const getCloudAuthentication = (state) => state.cloudAuthentication;
exports.getCloudAuthentication = getCloudAuthentication;
const getMimeAuthEnabled = (state) => state.mimeAuthEnabled;
exports.getMimeAuthEnabled = getMimeAuthEnabled;
const getSecondFactorOption = (state) => state.secondFactorOption;
exports.getSecondFactorOption = getSecondFactorOption;
const getRequestSecondFactorFirst = (state) => state.requestSecondFactorFirst;
exports.getRequestSecondFactorFirst = getRequestSecondFactorFirst;
