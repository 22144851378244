<mc-layout-list-extra-container
  keyTitle="$I18N_URL_PROTECTION_PAGE.TITLE"
  keyDescription="$I18N_URL_PROTECTION_PAGE.DESCRIPTION"
>
<mc-body-container>
  <mc-tab-group>
    <mc-tab name="{{ urlPageTabs[0].label | translate }}" data-test="overview-tab" *ngIf="canViewOverviewPage">
      <div class="row">
        <div data-unit-test="urls-scan-day-line" class="mc-widget-container col-xs-12 col-lg-8 col-xl-8">
          <mc-url-scan-graph [theme]="'gen2-light'" [chartSeriesToHide]="notSupportedEIPSeries"></mc-url-scan-graph>
        </div>
        <div data-unit-test="urls-scan-days-bar" class="mc-widget-container col-xs-12 col-lg-4 col-xl-4"></div>
      </div>
      <div class="row">
        <div data-unit-test="url-clicks" class="mc-widget-container col-xs-12 col-lg-8 col-xl-8"></div>
        <div data-unit-test="urls-by-category" class="mc-widget-container col-xs-12 col-lg-4 col-xl-4">
          <mc-urls-category>
            <div pie-chart></div>
            <div table></div>
          </mc-urls-category>
        </div>
      </div>
      <div class="row">
        <div data-unit-test="user-awareness-pie" class="mc-widget-container col-xs-12 col-lg-6 col-xl-6"></div>
        <div data-unit-test="user-awareness-bar" class="mc-widget-container col-xs-12 col-lg-6 col-xl-6"></div>
      </div>
    </mc-tab>
    <mc-tab name="{{ urlPageTabs[1].label | translate }}">

    </mc-tab>
    <mc-tab name="{{ urlPageTabs[2].label | translate }}" class="mc-no-title">
      <mc-url-tools-page></mc-url-tools-page>
    </mc-tab>
    <mc-tab name="{{ urlPageTabs[3].label | translate }}" class="mc-no-title">
      <mc-managed-urls-page></mc-managed-urls-page>
    </mc-tab>
    <mc-tab name="{{ urlPageTabs[4].label | translate }}">
    </mc-tab>
    <mc-tab name="{{ urlPageTabs[5].label | translate }}">
    </mc-tab>
    <mc-tab name="{{ urlPageTabs[6].label | translate }}" class="mc-no-title">
      <mc-logs-page></mc-logs-page>
    </mc-tab>
  </mc-tab-group>
</mc-body-container>
</mc-layout-list-extra-container>
