"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IncidentTabComponent = void 0;
const table_store_1 = require("@mimecast-ui/table-store");
const forms_1 = require("@angular/forms");
const incident_tab_static_value_1 = require("./incident-tab.static-value");
const components_1 = require("@mimecast-ui/components");
const incident_1 = require("../../models/incident");
const export_1 = require("../../models/export");
const exportActions = require("../../actions/export/export.actions");
const constants_1 = require("../../utils/constants");
const rxjs_1 = require("rxjs");
const reducers_1 = require("../../reducers");
const operators_1 = require("rxjs/operators");
class IncidentTabComponent extends table_store_1.TableBaseComponent {
    constructor(store, translateService, exportService) {
        super(store, 'RemediationIncidents');
        this.store = store;
        this.translateService = translateService;
        this.exportService = exportService;
        this.externalTableSearchCount = 0;
        this.noValuePlaceHolder = constants_1.emptyValueDecorator;
        this.externalTableSearchCount$ = new rxjs_1.BehaviorSubject(this.externalTableSearchCount);
        const formBuilder = new forms_1.FormBuilder();
        this.formGroup = formBuilder.group({
            dateRangePicker: [components_1.defaultDateRangePickerConfig.dateRanges[0], []]
        });
        this.dropdownOptions = [
            {
                label: '$I18N_REMEDIATION_INCIDENT_TAB.VIEW_DETAILS',
                action: (row) => this.openItem(row)
            }
        ];
    }
    ngOnInit() {
        super.ngOnInit();
        this.isLoading$ = rxjs_1.combineLatest(this.store.select(reducers_1.getIncidentsLoading), this.isLoading$).pipe(operators_1.map(([refresh, reload]) => refresh || reload));
        this.searchFilters = [
            {
                name: 'filterBy',
                filters: this.incidentTypeFilterOptions
            }
        ];
    }
    selectColumnList() {
        return incident_tab_static_value_1.COLUMN_LIST;
    }
    selectFilterSearchOptions() {
        return incident_tab_static_value_1.FILTER_SEARCH_OPTIONS;
    }
    getTranslatedTypeName(incidentSummary) {
        const translatedKey = incident_1.IncidentsTypesDictionary[incidentSummary.type];
        const translationRoot = '$I18N_REMEDIATION_INCIDENT_TAB.INCIDENT_TYPE.';
        let translatedStr = this.translateService.instant(translationRoot + translatedKey);
        if (incidentSummary.removeFromDevice) {
            translatedStr =
                translatedStr +
                    this.translateService.instant('$I18N_REMEDIATION_INCIDENT_TAB.INCIDENT_TYPE.DEVICE');
        }
        return translatedStr;
    }
    onSearch(filters) {
        this.filterSearch = { searchBy: { fieldName: filters.filterBy, value: filters.search } };
        this.runFilterSearchAndResetPaginator();
    }
    onSearchClear() {
        this.filterSearch = undefined;
        this.runFilterSearchAndResetPaginator();
    }
    onBundleFilterChange(filters) {
        this.filtersType = {
            filterBy: filters.filterBy &&
                filters.filterBy.map((val) => ({ fieldName: 'type', value: val }))
        };
        this.runFilterSearchAndResetPaginator();
    }
    onDateRangeFilterChange(value) {
        if (!value.range) {
            return;
        }
        this.filtersDateRange = {
            start: value.range.start.format(),
            end: value.range.end.format()
        };
        this.runFilterSearchAndResetPaginator();
    }
    onExportClicked() {
        const columnMapParams = {
            // columns: copy columnList array but remove last item which is the actions column
            columns: incident_tab_static_value_1.COLUMN_LIST.slice(0, incident_tab_static_value_1.COLUMN_LIST.length - 1),
            translationKey: '$I18N_REMEDIATION_INCIDENT_TAB.COLUMNS'
        };
        const columnConfig = {
            columnsToExport: this.exportService.mapExportedColumnsItem(columnMapParams),
            endPoint: export_1.ExportsEndPoints.incidents,
            filters: this.currentFilters
        };
        this.store.dispatch(new exportActions.OpenPanelForExportAction(columnConfig));
    }
    runFilterSearchAndResetPaginator() {
        const searchPayload = Object.assign(Object.assign(Object.assign({}, this.filterSearch), this.filtersDateRange), this.filtersType);
        this.onFilterChange(searchPayload);
        this.externalTableSearchCount$.next(++this.externalTableSearchCount);
    }
}
exports.IncidentTabComponent = IncidentTabComponent;
