"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const static_1 = require("@angular/upgrade/static");
const im_sync_dashboard_container_component_1 = require("./containers/im-sync-dashboard/im-sync-dashboard.container.component");
const im_sync_task_component_1 = require("./wizard/im-sync-task/im-sync-task.component");
angular
    .module('services.im-sync', [])
    .controller('ServicesImSyncController', [
    '$stateParams',
    '$scope',
    function ($stateParams, $scope) {
        $scope.stateParams = $stateParams;
    }
])
    .directive('mcServicesImSyncTasks', static_1.downgradeComponent({
    component: im_sync_dashboard_container_component_1.ImSyncDashboardContainerComponent
}))
    .directive('mcServicesWizardImSyncTask', static_1.downgradeComponent({
    component: im_sync_task_component_1.ImSyncTaskComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider
            .state('services-im-sync', {
            url: '^/services/im-sync',
            data: {
                capabilities: 'Permission.INSTANT_MESSAGING_READ',
                isFixedLayout: false,
                checkProgress: false,
                tabReload: true,
                tabId: 'services-im-sync-tab',
                tabTitle: '$18N_SERVICES_IM_SYNC.TAB_TITLE'
            },
            views: {
                main: {
                    template: '<mc-services-im-sync-tasks></mc-services-im-sync-tasks>',
                    controller: 'ServicesImSyncController'
                }
            },
            params: { callerWizardId: undefined }
        })
            .state('services-im-sync-create-wizard-im-task', {
            params: {
                admin_consent: null,
                tenantId: null,
                feature: null,
                imSyncTaskDetails: undefined
            },
            data: {
                capabilities: 'Permission.INSTANT_MESSAGING_EDIT',
                tabId: 'services-im-sync-tab',
                tabTitle: '$I18N_ARCHIVE_IM_SYNC_WIZARD.IM_SYNC_TASK.TAB_CREATE_TITLE',
                tabReload: false,
                breadcrumbs: [
                    '$I18N_MEGA_MENU_GROUP_SERVICES_LABEL',
                    '$I18N_MEGA_MENU_SERVICES_IM_SYNC_LABEL',
                    '$I18N_ARCHIVE_IM_SYNC_WIZARD.IM_SYNC_TASK.TAB_CREATE_TITLE'
                ]
            },
            views: {
                main: {
                    template: '<mc-services-wizard-im-sync-task></mc-services-wizard-im-sync-task>',
                    controller: 'ServicesImSyncController'
                }
            }
        });
    }
]);
