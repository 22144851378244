"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CyberGraphPolicyDetailsService = void 0;
class CyberGraphPolicyDetailsService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    getPolicyDetails(payload) {
        return this.httpClient.post('/api/policy/cybergraph/get-policy', {
            id: payload.id
        });
    }
}
exports.CyberGraphPolicyDetailsService = CyberGraphPolicyDetailsService;
