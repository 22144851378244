<mc-layout-list-table
  keyTitle="$18N_BOUNCES_LIST.TITLE"
  keyDescription="$18N_BOUNCES_LIST.DESCRIPTION"
  [isDataLoaded]="true"
>
  <mc-help-container>
    <mc-help-link url="{{ KB_URL }}"></mc-help-link>
  </mc-help-container>

  <mc-table
    [data]="bounces"
    [columns]="columnList"
    [isLoading]="isLoading$ | async"
    (rowClick)="onRowClick($event)"
    [highlightedRow]="selectedRow$ | async"
    translatePrefix="$18N_BOUNCES_LIST.TABLE"
  >
    <button class="mc-table-actions btn btn-secondary" (click)="onExport()">
      {{ '$18N_BOUNCES_LIST.EXPORT_DATA' | translate }}
    </button>

    <mc-filters
      [metadata]="metaData$ | async"
      (paginatorChange)="onPaginationChange($event)"
      [externalTableSearchCount]="externalTableSearchCount$ | async"
    >
      <mc-filter-column-select-and-search
        [sections]="searchSections"
        [placeholder]="'$18N_BOUNCES_LIST.TABLE.FILTERS.SEARCH_FIELD.PLACEHOLDER' | translate"
        (filtersChange)="onSearch($event)"
      >
      </mc-filter-column-select-and-search>

      <div class="mcc-bounces-filters-right">
        <mc-filters-bundle-date-range
          [config]="dateRangePickerConfig"
          (filtersChange)="onDateFilterChange($event)"
        >
        </mc-filters-bundle-date-range>
        <mc-filters-bundle-column-values
          [sections]="filterSections"
          (filtersChange)="onColumnFilterChanged($event)"
        >
        </mc-filters-bundle-column-values>
      </div>
    </mc-filters>

    <mc-column-email key="fromEnv" emailKey="emailAddress"></mc-column-email>
    <mc-column-email key="fromHdr" emailKey="emailAddress"></mc-column-email>

    <mc-column-size key="size"></mc-column-size>

    <mc-column key="hasAttachment">
      <mc-header-cell *mcHeaderCellDef>
        <i class="far fa-paperclip" aria-hidden="true"></i>
      </mc-header-cell>
      <mc-body-cell *mcBodyCellDef="let row">
        <i *ngIf="row.hasAttachment" class="far fa-paperclip" aria-hidden="true"></i>
      </mc-body-cell>
    </mc-column>

    <mc-column-date key="deliveryAttempt"></mc-column-date>

    <mc-column key="spamScore">
      <mc-body-cell *mcBodyCellDef="let row">{{row.spamScore >=0 ?  row.spamScore : '-' }}</mc-body-cell>
    </mc-column>
  
    <mc-column key="detectionLevel">
      <mc-body-cell *mcBodyCellDef="let row">{{row.detectionLevel  || '-' }}</mc-body-cell>
    </mc-column>

    <mc-column-actions
      key="right-column"
      mcShowColumnConfig
      [columnsAlwaysVisible]="columnsAlwaysVisible"
      [columnsToStartHidden]="columnsToStartHidden"
      [columnsToIgnore]="columnsToIgnore"
    >
      <mc-row-actions *mcRowActions="let row">
        <li mcRowAction="$18N_BOUNCES_LIST.MEATBALLS.VIEW_DETAILS" (click)="onRowClick(row)"></li>
        <ng-container *ngIf="canSubmitReport">
          <li mcRowActionSeparator></li>
          <li mcRowActionHeader="$18N_BOUNCES_LIST.MEATBALLS.REPORT_AS"></li>
          <li
            mcRowAction="$18N_BOUNCES_LIST.MEATBALLS.SPAM"
            (click)="reportAs({ reportType: 'spam', id: row.id })"
          ></li>
          <li
            mcRowAction="$18N_BOUNCES_LIST.MEATBALLS.MALWARE"
            (click)="reportAs({ reportType: 'malware', id: row.id })"
          ></li>
          <li
            mcRowAction="$18N_BOUNCES_LIST.MEATBALLS.PHISHING"
            (click)="reportAs({ reportType: 'phishing', id: row.id })"
          ></li>
        </ng-container>
      </mc-row-actions>
    </mc-column-actions>

    <mc-empty-results keyTitle="$18N_BOUNCES_LIST.TABLE.ERRORS.NO_TABLE_DATA"></mc-empty-results>
  </mc-table>
</mc-layout-list-table>
