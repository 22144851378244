"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DashboardStatisticsComponent = void 0;
const core_1 = require("@angular/core");
const queue_type_enum_1 = require("app-new/archive/supervision/types/queue-type.enum");
class DashboardStatisticsComponent {
    constructor(translateService) {
        this.translateService = translateService;
        this.onOpenSettings = new core_1.EventEmitter();
        this.totalLabel = this.translateService.instant('$I18N_ARCHIVE_SUPERVISION_DASHBOARD.ALL_QUEUES.CHART.TOTAL');
        this.revQueuesLabel = this.translateService.instant('$I18N_ARCHIVE_SUPERVISION_DASHBOARD.ALL_QUEUES.CHART.REVIEW_QUEUES');
        this.escQueuesLabel = this.translateService.instant('$I18N_ARCHIVE_SUPERVISION_DASHBOARD.ALL_QUEUES.CHART.ESCALATION_QUEUES');
    }
    get getPendingOverviewData() {
        const overview = this.statistics ? this.statistics.overview : undefined;
        return this.getOverviewData('pending_messages', !overview
            ? undefined
            : {
                review: overview.reviewAllPendingCount,
                escalation: overview.escalationAllPendingCount
            });
    }
    get getNoncompliantOverviewData() {
        const overview = this.statistics ? this.statistics.overview : undefined;
        return this.getOverviewData('noncompliant_messages', !overview
            ? undefined
            : {
                review: overview.reviewAllNonCompliantCount,
                escalation: overview.escalationAllNonCompliantCount
            });
    }
    openReviewSettings() {
        this.onOpenSettings.emit(queue_type_enum_1.QueueType.REVIEW);
    }
    openEscalationSettings() {
        this.onOpenSettings.emit(queue_type_enum_1.QueueType.ESCALATION);
    }
    getOverviewData(serieName, values) {
        const data = {
            value: 0,
            text: this.totalLabel,
            series: [],
            colors: ['#004ba0', '#63a4ff']
        };
        return !values
            ? data
            : Object.assign(Object.assign({}, data), { value: values.review + values.escalation, series: [
                    {
                        name: serieName,
                        categories: [
                            {
                                name: 'reviewQueues',
                                text: this.revQueuesLabel,
                                value: values.review
                            },
                            {
                                name: 'escalationQueues',
                                text: this.escQueuesLabel,
                                value: values.escalation
                            }
                        ]
                    }
                ] });
    }
}
exports.DashboardStatisticsComponent = DashboardStatisticsComponent;
