<mc-wizard-step-header
  [label]="hasAppPolicyRemoveAllowEnabled ? '$I18N_SWG_WEB_APPLICATION_CONTROL_NO_ALLOW_TITLE' : '$I18N_SWG_WEB_APPLICATION_CONTROL_TITLE'">
</mc-wizard-step-header>
<div class="panel-double-padding-bottom mc-block-allow-list-desc-container">
  <div class="panel-padding-bottom" *ngIf="hasAppPolicyRemoveAllowEnabled"> {{ '$I18N_SWG_WEB_APPLICATION_CONTROL_NO_ALLOW_DESC' | translate }} </div>
  <div class="panel-padding-bottom" *ngIf="!hasAppPolicyRemoveAllowEnabled"> {{ '$I18N_SWG_WEB_APPLICATION_CONTROL_DESC' | translate }} </div>
  <mc-swg-application-table
    *ngIf="!isApplicationCategorySetDataLoading"
    [applications]="applicationsCopy"
    [applicationCategoriesSet]="applicationCategoriesSet"
    [apiUpdatingEdit]="false"
    [height]="'calc(100vh - 300px)'"
    [hasAppPolicyRemoveAllowEnabled]="hasAppPolicyRemoveAllowEnabled"
    (webApplicationRules)="webApplicationRulesStep($event)"></mc-swg-application-table>
</div>
