"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MepAdminActionSuccess = exports.MepAdminLogActions = exports.MessageApiStatus = exports.MepLogStatus = void 0;
var MepLogStatus;
(function (MepLogStatus) {
    MepLogStatus["NO_ACTION"] = "no_action";
    MepLogStatus["HELD"] = "held";
    MepLogStatus["RELEASED_BY_USER"] = "released_by_user";
    MepLogStatus["DROPPED_BY_USER"] = "dropped_by_user";
    MepLogStatus["RELEASED_BY_ADMIN"] = "released_by_admin";
    MepLogStatus["DROPPED_BY_ADMIN"] = "dropped_by_admin";
    MepLogStatus["AUTO_RELEASED"] = "auto_released";
})(MepLogStatus = exports.MepLogStatus || (exports.MepLogStatus = {}));
var MessageApiStatus;
(function (MessageApiStatus) {
    MessageApiStatus["DROPPED"] = "dropped";
    MessageApiStatus["RELEASED"] = "released";
    MessageApiStatus["ALREADY_PROCESSED"] = "already-processed";
})(MessageApiStatus = exports.MessageApiStatus || (exports.MessageApiStatus = {}));
var MepAdminLogActions;
(function (MepAdminLogActions) {
    MepAdminLogActions["DROP"] = "DROP";
    MepAdminLogActions["RELEASE"] = "RELEASE";
})(MepAdminLogActions = exports.MepAdminLogActions || (exports.MepAdminLogActions = {}));
class MepAdminActionSuccess {
}
exports.MepAdminActionSuccess = MepAdminActionSuccess;
