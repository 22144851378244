<div class="panel-padding-top">
  <mc-message-delivery-table
    [rows]="items$ | async"
    [selectedRow]="selectedRow$ | async"
    [externalTableSearchCount]="totalCount$ | async"
    [selectedRow]="selectedRow$ | async"
    [isLoading]="isLoading$ | async"
    [metadata]="metadata$ | async"
    (onPaginatorChanged)="paginatorChanged($event)"
    (onSearchFilterChanged)="search($event)"
    (onDateFilterChanged)="dateFilterChanged($event)"
    (onColumnFilterChanged)="columnFilterChanged($event)"
    (onRowClicked)="rowClicked($event)"
    (onMessagesRejected)="messagesRejected($event)"
    (onMessagesRetried)="messagesRetried($event)"
    (onRouteRecalculated)="routeRecalculated($event)"
    (onMessagesEarlyBounced)="messagesEarlyBounced($event)"
    (onMessagesExported)="exportResults($event)"
  >
  </mc-message-delivery-table>
</div>
