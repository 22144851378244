"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMepLogState = exports.reducers = void 0;
const store_1 = require("@ngrx/store");
const mepLog = require("./logs/mep-logs.reducer");
const mepLogSidebar = require("./sidebar/mep-log-sidebar.reducer");
exports.reducers = {
    mepLog: mepLog.reducer,
    mepLogSidebar: mepLogSidebar.reducer
};
exports.getMepLogState = store_1.createFeatureSelector('mepLogs');
