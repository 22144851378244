"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./mailboxes-details.controller"); //services.services.backup.mailbox-details.controller
require("./slides/mailbox-email-export-controller"); //backuprestore-mailbox-details.slides-export.controller
require("./slides/mailbox-email-restore-controller"); //backuprestore-mailbox-details.slides-restore.controller
require("app/services/services/backup/components/mail-selector-aside/mail-selector-aside.service"); //mail-selector-aside.service
require("./slides/mailbox-email-export-restore.service"); //backuprestore-mailbox-details.slides-export-restore.service
require("./mailbox-navigation-links/mailbox-navigation-link"); //services.services.backup.mailbox-details.navigationlinks
require("./slides/snapshot-in-time/snapshot-in-time-controller");
const components_1 = require("@mimecast-ui/components");
// html and css
require("./mailboxes-details.tpl.html");
require("./mailbox-navigation-links/mailbox-navigation-link.tpl.html");
require("./slides/mailbox-email-export-restore-success.tpl.html");
require("./slides/mailbox-email-export.tpl.html");
require("./slides/mailbox-email-restore.tpl.html");
require("./slides/snapshot-in-time/snapshot-in-time.tpl.html");
const static_1 = require("@angular/upgrade/static");
(function () {
    'use strict';
    angular.module('services.services.backup.mailbox-details', [
        'services.services.backup.mailbox-details.controller',
        'backuprestore-mailbox-details.slides-export.controller',
        'backuprestore-mailbox-details.slides-restore.controller',
        'mail-selector-aside.service',
        'backuprestore-mailbox-details.slides-export-restore.service',
        'services.services.backup.mailbox-details.navigationlinks',
        'snapshot-in-time.slide.module'
    ])
        .factory('asideService', static_1.downgradeInjectable(components_1.AsideService));
}());
