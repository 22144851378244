<mc-field
  [label]="label"
  required
  >
  <div class="row">
    <mc-provider *ngFor="let provider of configuredProviders"
      class="col-xs-4"
      [provider]="provider"
      [state]="providerState(provider)"
      (onProviderSelected)="selectProvider(provider)"
    >
    </mc-provider>
  </div>
</mc-field>
