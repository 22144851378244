"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MaxLengthValidator = void 0;
const MaxLengthValidator = (maxLength) => {
    return (control) => {
        return control.value.length > maxLength
            ? {
                INVALID_LENGTH: {
                    validationResult: { maxLength, numberOfCharacters: control.value.length }
                }
            }
            : null;
    };
};
exports.MaxLengthValidator = MaxLengthValidator;
