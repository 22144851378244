"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApPolicyListContainerComponent = void 0;
const policy_list_model_1 = require("../../models/policy-list.model");
const policy_actions_1 = require("../../actions/policy.actions");
class ApPolicyListContainerComponent {
    constructor(store) {
        this.store = store;
    }
    onCreateNewPolicy() {
        this.store.dispatch(new policy_actions_1.ApPolicyNavigateToPolicyWizard(policy_list_model_1.ApPolicyWizardFlowType.CREATE));
        this.store.dispatch(new policy_actions_1.ApPolicyCreateWizardFlowType());
    }
}
exports.ApPolicyListContainerComponent = ApPolicyListContainerComponent;
