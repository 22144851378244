<ng-container *ngIf="loadingUsage$ | async; else modalContent">
  <mc-modal-header></mc-modal-header>
  <mc-modal-body>
    <div class="spinner-wrapper">
      <mc-spinner></mc-spinner>
    </div>
  </mc-modal-body>
  <mc-modal-footer></mc-modal-footer>
</ng-container>

<ng-template #modalContent>
  <ng-container *ngIf="(connectorUsage$ | async)?.canBeDeleted; else connectorIsUsed">
    <mc-modal-header data-test="modal-title"
                     headerTitle="{{'$I18N_DELETE_CONNECTOR_MODAL.TITLE' | translate:{ connectorName: connectorName } }}">
    </mc-modal-header>
    <mc-modal-body>
      <div data-test="delete-dir-sync-header" class="message-header">
        <span>{{ connectorNotUsedDescription1() | translate }}</span>
        <a href="{{ deleteDirSyncUnusedKbLink }}" target="_blank" rel="noopener noreferrer">
          <u><span>{{ connectorNotUsedDescription2() | translate }}</span></u>
        </a>
      </div>
    </mc-modal-body>

    <mc-modal-footer>
      <button type="button" class="btn btn-secondary" (click)="close(false)">
        {{ '$I18N_DELETE_CONNECTOR_MODAL.CANCEL' | translate }}
      </button>

      <button type="button" class="btn btn-danger" (click)="close(true)">
        {{ '$I18N_DELETE_CONNECTOR_MODAL.DELETE' | translate }}
      </button>
    </mc-modal-footer>
  </ng-container>
</ng-template>


<ng-template #connectorIsUsed>
  <mc-modal-header data-test="modal-title"
                   headerTitle="{{ connectorIsUsedTitle() | translate:{ connectorName: connectorName } }}">
  </mc-modal-header>
  <mc-modal-body>
    <div data-test="linked-policies-header" class="message-header">
      <span class="fas fa-exclamation-triangle text-warning"></span>
      {{ connectorIsUsedDescription() | translate }}
    </div>
  </mc-modal-body>

  <mc-modal-footer>
    <button type="button" class="btn btn-secondary" (click)="close(false)">
      {{ '$I18N_DELETE_CONNECTOR_MODAL.GOTIT' | translate }}
    </button>
  </mc-modal-footer>
</ng-template>
