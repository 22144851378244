<ng-container>
  <mc-layout-aside-extra-container
    keyTitle="$I18N_DIRECTORY_SYNC_CONNECTION_INFO.ASIDE_TITLE"
    [isLoading]="false"
    showClose="true"
    (closeAside)="closeAside()">
    <mc-extra-container>
      <div class="mc-header-row">
        <span class="mc-description">{{'$I18N_DIRECTORY_SYNC_CONNECTION_INFO.CONNECTION' | translate}} {{description$ | async}}</span>
      </div>
      <button class="btn btn-primary" [disabled]="!integrationTestComponent.isRestartButtonEnabled"
              (click)="integrationTestComponent.retryConnectionTest()"
              data-test="mc-restart-test-button">
        {{ '$I18N_DIRECTORY_SYNC_CONNECTION_INFO.BUTTON.RESTART_TEST' | translate }}
      </button>
    </mc-extra-container>
    <mc-body-container>
      <mc-directory-sync-integration-test [info]="connectionInfo"></mc-directory-sync-integration-test>
    </mc-body-container>
  </mc-layout-aside-extra-container>
</ng-container>
