"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PurgeMessagesFormComponent = void 0;
const core_1 = require("@angular/core");
const components_1 = require("@mimecast-ui/components");
const rxjs_1 = require("rxjs");
const purge_messages_form_static_values_1 = require("./purge-messages-form.static-values");
class PurgeMessagesFormComponent {
    constructor(fb) {
        this.fb = fb;
        this.search = new core_1.EventEmitter();
        this.attemptOperators = purge_messages_form_static_values_1.attemptOperators;
        this.attemptCounts = purge_messages_form_static_values_1.attemptCounts;
        this.dateRangeConfig = purge_messages_form_static_values_1.defaultDateRangeConfig;
        this.form = this.fb.group({
            from: [null, components_1.DomainEmailValidator],
            to: [null, components_1.DomainEmailValidator],
            subject: null,
            dateRange: this.dateRangeConfig,
            includeAttempts: false,
            attemptOperator: { value: purge_messages_form_static_values_1.attemptOperators[0].value, disabled: true },
            attemptCount: { value: 1, disabled: true },
            priorityLow: true,
            priorityNotApplied: false
        });
    }
    ngOnInit() {
        this._includeAttemptSubscription = this.form
            .get('includeAttempts')
            .valueChanges.subscribe(toInclude => {
            if (toInclude) {
                this.form.controls['attemptOperator'].enable();
                this.form.controls['attemptCount'].enable();
            }
            else {
                this.form.controls['attemptOperator'].disable();
                this.form.controls['attemptCount'].disable();
            }
        });
        this._fromToConditionalValidationSubscription = rxjs_1.merge(this.form.get('from').valueChanges, this.form.get('to').valueChanges).subscribe(value => {
            const fromControl = this.form.get('from');
            const toControl = this.form.get('to');
            if (value) {
                if (fromControl.invalid && !fromControl.hasError('DOMAIN_OR_EMAIL_INVALID')) {
                    fromControl.setErrors(null);
                }
                if (toControl.invalid && !toControl.hasError('DOMAIN_OR_EMAIL_INVALID')) {
                    toControl.setErrors(null);
                }
            }
        });
        this._lowPrioritySubscription = this.form
            .get('priorityLow')
            .valueChanges.subscribe(this.toggleCheckbox(this.form.get('priorityNotApplied')));
        this._notAppliedPrioritySubscription = this.form
            .get('priorityNotApplied')
            .valueChanges.subscribe(this.toggleCheckbox(this.form.get('priorityLow')));
        this.form.patchValue({
            dateRange: this.dateRangeConfig.dateRanges[1]
        });
        this.form.markAsPristine();
    }
    ngOnDestroy() {
        this._includeAttemptSubscription.unsubscribe();
        this._fromToConditionalValidationSubscription.unsubscribe();
        this._lowPrioritySubscription.unsubscribe();
        this._notAppliedPrioritySubscription.unsubscribe();
    }
    isFromAndToValid() {
        const fromControl = this.form.controls['from'];
        const toControl = this.form.controls['to'];
        if (!fromControl.value && !toControl.value) {
            fromControl.setErrors({
                FromAndToAddressAreEmpty: true
            });
            toControl.setErrors({});
            fromControl.markAsTouched();
            toControl.markAsTouched();
            return false;
        }
        return true;
    }
    toggleCheckbox(checkboxControl) {
        return (value) => {
            if (value) {
                checkboxControl.setValue(!value);
            }
        };
    }
    onSubmit() {
        if (this.isFromAndToValid()) {
            this.search.emit(this.form.value);
        }
    }
    resetForm() {
        this.form.reset({
            dateRange: this.dateRangeConfig.dateRanges[1],
            attemptOperator: purge_messages_form_static_values_1.attemptOperators[0].value,
            attemptCount: 1,
            priorityLow: true,
            priorityNotApplied: false
        });
        this.form.markAsPristine();
    }
}
exports.PurgeMessagesFormComponent = PurgeMessagesFormComponent;
