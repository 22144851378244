"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toData = exports.ofName = void 0;
const operators_1 = require("rxjs/operators");
function ofName(...allowedNames) {
    return operators_1.filter((message) => allowedNames.some(name => name === message.name));
}
exports.ofName = ofName;
function toData() {
    return operators_1.map((message) => message.data);
}
exports.toData = toData;
