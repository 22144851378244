"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgThreatEventStatusComponent = void 0;
const models_1 = require("../../../../models/models");
const clp_1 = require("../../../../policies/models/clp");
class SwgThreatEventStatusComponent {
    static getPolicyAction(actions) {
        if (!actions) {
            return clp_1.ClpPolicyActions.LOG_ONLY;
        }
        if (actions.indexOf(models_1.ThreatEventAction.RELEASED) !== -1) {
            return clp_1.ClpPolicyActions.RELEASED;
        }
        if (actions.indexOf(models_1.ThreatEventAction.MANUAL_QUARANTINE) !== -1) {
            return clp_1.ClpPolicyActions.MANUALLY_QUARANTINED;
        }
        if (actions.indexOf(models_1.ThreatEventAction.QUARANTINED) !== -1) {
            return clp_1.ClpPolicyActions.BLOCK_MALICIOUS;
        }
        if (actions.indexOf(models_1.ThreatEventAction.TEAMS_NOTIFY_PARTICIPANTS) !== -1) {
            return clp_1.ClpPolicyActions.LOG_AND_REPLY;
        }
        return clp_1.ClpPolicyActions.LOG_ONLY;
    }
    getElement() {
        return SwgThreatEventStatusComponent.getPolicyAction(this.actions);
    }
}
exports.SwgThreatEventStatusComponent = SwgThreatEventStatusComponent;
