'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const _ = require("lodash");
/**
 * Created by gvilla on 5/11/2015.
 */
angular.module('navbar.service', [])
    .factory('navbarService', ['$http', 'userHandlerService', 'megaMenuService', '$q', '$state', '$translate',
    'capabilitiesService', 'accountService', 'userService', 'administrationService', '$window', function ($http, userHandlerService, megaMenuService, $q, $state, $translate, capabilitiesService, accountService, userService, administrationService, $window) {
        let optionsActions = [];
        const aaaAccount = {};
        const changeCloudPassword = { key: 'usersetting', label: '$I18N_NAVBAR_USER_POPOVER_CHANGE_CLOUD_PASSWORD',
            icon: 'icon-lock', fn: 'navbarCtrl.openChangeCloudPassword()', visible: true };
        let userData = {};
        let isServiceMenuVisible = true;
        let isNavBarReady = false;
        const navbarMenuDefer = $q.defer();
        let isAccessGranted = true;
        administrationService.getVerifiedAccess().then(activateVerifiedAccess, activateDeniedAccess);
        /* -------------------------------------------------------- *\
         - interface
        \* -------------------------------------------------------- */
        return {
            getOptionActions,
            getUserAndAddchangeCloudPassword,
            findMenuItemByState,
            openMenu,
            hasCap,
            searchMenus,
            getServiceMenuVisibility,
            getServiceDeliveryMenuVisibility,
            hideServiceMenu,
            getAAAacountType,
            getDelegateAccess,
            hideNavbarMenus: hideNavbarMenusAndUserOptions,
            getAaaAccountDetails,
            getNavbarMenuReadyPromise,
            areMenusReady,
            areTheseStateSame,
            getUserData,
            isAccessGranted,
            requestFeedback,
            setUserAvatarIcon,
            getData,
            isFaaOrAaaUser,
            isMailProcessing
        };
        /* -------------------------------------------------------- *\
         - implementation
         \* -------------------------------------------------------- */
        function requestFeedback(feedbackResponse) {
            $http.post('/api/email/send-email', feedbackResponse);
        }
        // -- getOptionActions --
        function getOptionActions() {
            return optionsActions;
        }
        // -- getUserAndAddchangeCloudPassword --
        function getUserAndAddchangeCloudPassword() {
            return userHandlerService.getUser().then(function (data) {
                userData = data;
                if ((userData.localPassword && userData.changePassword) && optionsActions.indexOf(changeCloudPassword)) {
                    optionsActions.splice(1, 0, changeCloudPassword);
                }
                return userData;
            });
        }
        // -- hasCap --
        function hasCap(cap) {
            return (angular.isDefined(cap) && capabilitiesService.evalCapabilitiesExpression(cap));
        }
        // -- findMenuItemByState --
        function findMenuItemByState(state, stateParams) {
            const defer = $q.defer();
            const menuItemDetails = {
                item: {},
                breadcrumbs: []
            };
            const itemState = angular.copy($state.get(state.name));
            if (itemState) {
                itemState.params = angular.copy(stateParams);
                megaMenuService.onReady(function () {
                    if (angular.isDefined(itemState.data.isMenuItem) && !itemState.data.isMenuItem) {
                        menuItemDetails.item = {
                            name: itemState.name,
                            label: itemState.data.label,
                            state: itemState,
                            params: itemState.params && itemState.params.uid ? { uid: itemState.params.uid } : {},
                            icon: megaMenuService.findSearchMenuByState(itemState.data.containerState, undefined).group.icon,
                            breadcrumbs: itemState.data.breadcrumbs
                        };
                        defer.resolve(menuItemDetails);
                    }
                    else {
                        const menu = megaMenuService.findSearchMenuByState(itemState.parent || itemState.name, (itemState.params && itemState.params.uid) ? { uid: itemState.params.uid } : undefined);
                        if (!menu) {
                            const allStates = $state.get();
                            const matchedState = _.find(allStates, function (stateParam) {
                                return (stateParam.parent === itemState.name
                                    && stateParam.params
                                    && stateParam.params.uid && stateParam.params.uid === itemState.params.uid);
                            });
                            if (matchedState) {
                                menuItemDetails.item = {
                                    name: matchedState.name,
                                    label: matchedState.data.label,
                                    state: matchedState,
                                    params: matchedState.params && matchedState.params.uid ? { uid: matchedState.params.uid } : {},
                                    icon: megaMenuService.findSearchMenuByState(matchedState.data.containerState, undefined).group.icon
                                };
                                menuItemDetails.breadcrumbs = matchedState.data.breadcrumbs;
                                defer.resolve(menuItemDetails);
                            }
                            else {
                                defer.reject();
                            }
                        }
                        else {
                            menuItemDetails.item = angular.copy(menu.item);
                            if (itemState.data && itemState.data.tabTitle && !menuItemDetails.item.label) {
                                menuItemDetails.item.label = $translate.instant(itemState.data.tabTitle);
                            }
                            menuItemDetails.breadcrumbs = [menu.group.label, menuItemDetails.item.label, menu.item.subLabel];
                            menuItemDetails.item.icon = menu.group.icon;
                            menuItemDetails.item.groupName = menu.group.label;
                            if (menuItemDetails.breadcrumbs[0] === 'Message Center') {
                                menuItemDetails.breadcrumbs[0] = '';
                                menuItemDetails.breadcrumbs[1] = '';
                            }
                            // I don't like to have to do this but it seems to be the only way to not break Favorites
                            // if using isMenuItem = false and custom breadcrumbs.
                            if (menuItemDetails.breadcrumbs[1] ===
                                $translate.instant('$I18N_MEGA_MENU_GROUP_SECURE_WEB_GATEWAY_ENDPOINT_SETTINGS') ||
                                menuItemDetails.breadcrumbs[1] ===
                                    $translate.instant('$I18N_MEGA_MENU_GROUP_SECURE_WEB_GATEWAY_ENDPOINTS_LIST')) {
                                menuItemDetails.breadcrumbs[0] = '';
                                menuItemDetails.breadcrumbs[1] = '';
                            }
                            // @ts-ignore
                            defer.resolve(menuItemDetails);
                        }
                    }
                });
            }
            else {
                defer.reject();
            }
            return defer.promise;
        }
        // -- openMenu --------
        function openMenu(menuItem) {
            if (!angular.isDefined(menuItem.isActive) || (menuItem.isActive === true)) {
                $state.go(menuItem.state.name, menuItem.state.params);
            }
        }
        function searchMenus(searchString) {
            searchString = searchString.toLowerCase();
            return megaMenuService.searchMenu(searchString);
        }
        function evaluateMenuItemCaps(menuObject) {
            const copyMenuObject = angular.copy(menuObject);
            _.forEach(menuObject, function (menuItem) {
                if (menuItem && menuItem.caps && !hasCap(menuItem.caps)) {
                    _.remove(copyMenuObject, menuItem);
                }
            });
            return copyMenuObject;
        }
        function hideServiceMenu() {
            isServiceMenuVisible = false;
        }
        function getServiceMenuVisibility() {
            return isServiceMenuVisible;
        }
        function getServiceDeliveryMenuVisibility() {
            return capabilitiesService.hasCapability('Permission.SERVICE_DELIVERY_READ');
        }
        function getAAAacountType() {
            return capabilitiesService.hasCapability('Permission.DASHBOARD_READ') && accountService.getAccount().then(function (accountResponse) {
                if (!accountResponse.err) {
                    return angular.isDefined(accountResponse) && (accountService.getAdvancedAccountAdminTypes().indexOf(accountResponse.first.type.key) !== -1) ? {
                        label: accountResponse.first.type.label,
                        accountRole: accountResponse.first.type.accountRole,
                        accountCode: accountResponse.first.accountCode
                    } : undefined;
                }
                else {
                    accountService.getError().showErrorNotification(accountResponse);
                }
            }, function (error) {
                accountService.getError().showErrorNotification();
                return error;
            });
        }
        function getDelegateAccess(accountCode) {
            return userHandlerService.getDelegateAccess(accountCode);
        }
        function hideNavbarMenusAndUserOptions() {
            hideServiceMenu();
            hideUserOptions();
        }
        function hideUserOptions() {
            optionsActions = [];
        }
        function activateVerifiedAccess() {
            function prepareNavbarMenus() {
                _.forEach(navbarMenus, function (value, key) {
                    if (!_.isEmpty(value)) {
                        navbarMenus[key] = evaluateMenuItemCaps(value);
                    }
                });
                optionsActions = navbarMenus._optionsActions;
                $q.all([getUserAndAddchangeCloudPassword(), getAAAacountType()]).then(function (responses) {
                    const aaaTypeResponse = responses[1];
                    if (angular.isDefined(aaaTypeResponse)) {
                        return getDelegateAccess(aaaTypeResponse.accountCode).then(function (delegateResponse) {
                            aaaAccount.detail = aaaTypeResponse;
                            aaaAccount.delegateAccess = delegateResponse;
                            navbarMenuDefer.resolve();
                            isNavBarReady = true;
                        }, function () {
                            navbarMenuDefer.resolve();
                            isNavBarReady = true;
                        });
                    }
                    else {
                        isNavBarReady = true;
                        navbarMenuDefer.resolve();
                    }
                }, function () {
                    isNavBarReady = true;
                    navbarMenuDefer.resolve();
                });
            }
            isAccessGranted = true;
            const navbarMenus = {
                _optionsActions: [
                    { key: 'accountSupport', label: '$I18N_NAVBAR_USER_POPOVER_ACCOUNT_SUPPORT',
                        icon: 'mc-icon-account-support-details fa-fw', fn: 'navbarCtrl.openAccountSupportModal()', visible: true },
                    { key: 'preferences', caps: 'Temporary.EarlyAccess', label: 'Preferences',
                        icon: 'fas fa-cog fa-fw', fn: 'navbarCtrl.openPreferences()', visible: true },
                    { key: 'improvedNavigation', caps: 'Temp.ImprovedNavigation.UI.Switch', label: '$I18N_NAVBAR_IMPROVED_NAVIGATION',
                        icon: 'fas fa-sparkles', visible: true },
                    { key: 'contactingSupport', label: '$I18N_NAVBAR_CONTACTING_SUPPORT',
                        link: `${$window.location.origin}/apps#/sso/mcentral?goto=%2Fs%2Fcontactsupport`,
                        icon: 'far fa-life-ring fa-fw', visible: true },
                    { key: 'askingAQuestion', label: '$I18N_NAVBAR_ASKING_A_QUESTION',
                        link: 'https://community.mimecast.com/community/community-forums',
                        icon: 'far fa-exclamation fa-fw', visible: true },
                    { key: 'submittingTechnicalInformation', label: '$I18N_NAVBAR_SUBMITTING_TECHNICAL_INFORMATION',
                        icon: 'far fa-share-square fa-fw', fn: 'navbarCtrl.openFeedback()', visible: true }
                ]
            };
            if (capabilitiesService.areCapabilitiesLoaded()) {
                prepareNavbarMenus();
            }
            else {
                capabilitiesService.getCapabilities().then(function () {
                    prepareNavbarMenus();
                });
            }
        }
        function activateDeniedAccess() {
            isAccessGranted = false;
            userService.getProfile().then(function (responseProfile) {
                userData = responseProfile.first;
                isNavBarReady = true;
                navbarMenuDefer.resolve();
            });
        }
        function getAaaAccountDetails() {
            return aaaAccount;
        }
        function getNavbarMenuReadyPromise() {
            isNavBarReady = true;
            return navbarMenuDefer.promise;
        }
        function areMenusReady() {
            return isNavBarReady;
        }
        function areTheseStateSame(state1, state2) {
            return (state1.params && state1.params.uid && state2.params && state2.params.uid)
                ? state1.params.uid === state2.params.uid : state1.name === state2.name;
        }
        function getUserData() {
            return userData;
        }
        // To set default or custom user avatar icon
        function setUserAvatarIcon() {
            let userAvatar = 'default';
            const defaultAvatar = 'iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAYAAAA5ZDbSAAAD9GlDQ1BJQ0MgcHJvZmlsZQAAeNqNlM9vFGUYxz';
            if (!!userData.base64Avatar && !userData.base64Avatar.includes(defaultAvatar)) {
                userAvatar = `data:${userData.avatarMimeType};base64,${userData.base64Avatar}`;
            }
            return userAvatar;
        }
        function getData() {
            return megaMenuService.getData();
        }
        function isFaaOrAaaUser() {
            if (capabilitiesService.hasCapability('FederatedAdmin.Access') &&
                (capabilitiesService.hasCapability('AccountType.EnterpriseMaster') ||
                    capabilitiesService.hasCapability('AccountType.EnterpriseGroup') ||
                    capabilitiesService.hasCapability('AccountType.PartnerMaster') ||
                    capabilitiesService.hasCapability('AccountType.PartnerGroup'))) {
                return true;
            }
            else {
                return false;
            }
        }
        function isMailProcessing() {
            if (capabilitiesService.hasCapability('FederatedAdmin.Access') && capabilitiesService.hasCapability('AccountType.EnterpriseMailProcessing') || capabilitiesService.hasCapability('AccountType.PartnerMailProcessing')) {
                return true;
            }
            else {
                return false;
            }
        }
    }]);
