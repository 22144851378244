"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("app/highcharts/highcharts"); // mc-highcharts
require("./dashboard-controller"); // dashboards.administration.controller
require("./aside/aside"); // dashboards-administration-aside
require("./maximum-retention-filter/maximum-retention-filter"); // dashboards.administration.maximum-retention-filter
require("app/dashboards/utils/dashboard-commons"); // dashboard-commons
(function () {
    'use strict';
    angular
        .module('dashboards.administration', [
        'mc-highcharts',
        'dashboards.administration.controller',
        'dashboards-administration-aside',
        'dashboards.administration.maximum-retention-filter',
        'dashboard-commons'
    ])
        .constant('DASHBOARD', {
        API_DATE_FORMAT: 'YYYY-MM-DDTHH:mm:ssZZ',
        RELOAD_INTERVAL: 600000,
        UI_DOMAIN_URL_REGEX: /(https{0,1}:\/\/(.+?\/))/
    })
        .config([
        '$stateProvider',
        function ($stateProvider) {
            const data = {
                isFixedLayout: true,
                trackProgress: true,
                backgroundClass: 'mc-template-center-container',
                asideTitle: '$I18N_ADMINISTRATION_MENU_SELECT_DASHBOARD',
                tabId: 'main',
                tabTitle: '$I18N_ADMINISTRATION_MENU_ACCOUNT_ITEM_ADMINISTRATION_DASHBOARD',
                tabHide: false,
                tabReload: 'true',
                hideBreadcrumbs: false
            };
            $stateProvider.state('administration-dashboard', {
                url: '/administration-dashboard',
                data,
                views: {
                    main: {
                        templateUrl: 'dashboards/administration/dashboard.tpl.html',
                    },
                    aside: {
                        templateUrl: 'dashboards/administration/aside/aside.tpl.html'
                    }
                },
                onEnter: [
                    'userService',
                    'progressTrackerService',
                    'windowService',
                    function (userService, progressTrackerService, windowService) {
                        if (windowService.inIframe()) {
                            data.hideBreadcrumbs = true;
                        }
                        userService.getProfile().then(function (userData) {
                            if (!userData.err) {
                                const firstUserData = userData.first;
                                userService
                                    .getPreferences(firstUserData.emailAddress)
                                    .then(function () {
                                    progressTrackerService.statusLogin();
                                });
                            }
                            else {
                                progressTrackerService.statusLogin();
                            }
                        }, function () { });
                    }
                ]
            });
        }
    ]);
})();
