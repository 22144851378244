<mc-layout-detail-tabs
        keyTitle="$I18N_MEP.TITLE"
        keyDescription="$I18N_MEP.DESCRIPTION"
        extendClass="mc-layout-detail-tabs">

  <mc-help-container>
    <mc-help-link url="https://community.mimecast.com/"></mc-help-link>
  </mc-help-container>
  <mc-tab-group [initialActiveTabIndex]="displayTab" #tabGroup>
    <mc-tab name="{{ '$I18N_MEP.TABS.LOGS_TAB' | translate }}" *ngIf="isLogPermissionAssigned$ | async" >
      <mc-mep-logs-list></mc-mep-logs-list>
    </mc-tab>
    <mc-tab name="{{ '$I18N_MEP.TABS.POLICIES_TAB' | translate }}">
      <mc-mep-policy-list
        (rowClick)="onRowClick($event)"
      ></mc-mep-policy-list>
    </mc-tab>
  </mc-tab-group>
</mc-layout-detail-tabs>
