"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mockTableFilters = exports.baseUrl = exports.QueueConfig = void 0;
class QueueConfig {
    constructor(id, name, createdBy, updatedBy, created, updated, description, type, printAllowed, forwardAllowed, status, labels, reviewerCount) {
        this.id = id;
        this.name = name;
        this.createdBy = createdBy;
        this.updatedBy = updatedBy;
        this.created = created;
        this.updated = updated;
        this.description = description;
        this.type = type;
        this.printAllowed = printAllowed;
        this.forwardAllowed = forwardAllowed;
        this.status = status;
        this.labels = labels;
        this.reviewerCount = reviewerCount;
    }
}
exports.QueueConfig = QueueConfig;
exports.baseUrl = '/api/supervision/config/';
exports.mockTableFilters = {
    filterBy: [
        {
            fieldName: 'queue_type',
            value: 'escalation'
        }
    ]
};
