"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgDashboardComponent = void 0;
const swg_dashboard_component_static_values_1 = require("./swg-dashboard.component.static-values");
class SwgDashboardComponent {
    constructor(swgService) {
        this.swgService = swgService;
        this.activityChartConfig = swg_dashboard_component_static_values_1.ActivityChartConfiguration;
        this.topDomainsChartConfig = swg_dashboard_component_static_values_1.TopDomainsChartConfiguration;
        this.topCategoriesChartConfig = swg_dashboard_component_static_values_1.TopCategoriesChartConfiguration;
        this.topBlockedDomainsChartConfig = swg_dashboard_component_static_values_1.TopBlockedDomainsChartConfiguration;
        this.topBlockedCategoriesChartConfig = swg_dashboard_component_static_values_1.TopBlockedCategoriesChartConfiguration;
        this.exportFormats = swg_dashboard_component_static_values_1.ExportFormats;
    }
    ngOnInit() {
        this.notifications = this.swgService.getDashboardNotifications();
    }
    exportChart(chart, type, fileName) {
        switch (chart) {
            case 'activityChart':
                // @ts-ignore
                this.activityChart.exportChart(type, fileName);
                break;
            case 'topDomainsChart':
                // @ts-ignore
                this.topDomainsChart.exportChart(type, fileName);
                break;
            case 'topCategoriesChart':
                // @ts-ignore
                this.topCategoriesChart.exportChart(type, fileName);
                break;
            case 'topBlockedDomainsChart':
                // @ts-ignore
                this.topBlockedDomainsChart.exportChart(type, fileName);
                break;
            case 'topBlockedCategoriesChart':
                // @ts-ignore
                this.topBlockedCategoriesChart.exportChart(type, fileName);
                break;
        }
    }
}
exports.SwgDashboardComponent = SwgDashboardComponent;
