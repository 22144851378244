"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TreeComponent = void 0;
const core_1 = require("@angular/core");
const node_factory_1 = require("app-new/components/tree/model/factories/node.factory");
class TreeComponent {
    constructor() {
        this.nodeStack = [];
        // TODO: iaggarwal - Remove this once folders are exportable
        this.exportable = false;
        this.contentViewable = false;
        this.onNodeClicked = new core_1.EventEmitter();
        this.onFolderToggleSelect = new core_1.EventEmitter();
        this.onItemClicked = new core_1.EventEmitter();
        this.onSearchFolders = new core_1.EventEmitter();
        this.onSearchContents = new core_1.EventEmitter();
        this.pushNodeToTrace = new core_1.EventEmitter();
        this.getNextFolders = new core_1.EventEmitter();
        this.getPreviousFolders = new core_1.EventEmitter();
        this.getNextContents = new core_1.EventEmitter();
        this.getPreviousContents = new core_1.EventEmitter();
        this.scrollPercentage = 99;
    }
    ngAfterViewInit() {
        this.carriageContentMinHeight = this.contentContainer.nativeElement.clientHeight;
        this.carriageFolderMinHeight = this.folderContainer.nativeElement.clientHeight;
    }
    showFolderContents(folder) {
        this.currentFolder = folder;
        this.contentType = this.currentFolder.getContentType();
        this.onNodeClicked.emit(folder);
        this.pushNodeToTrace.emit(folder);
    }
    toggleSelection(folder) {
        this.onFolderToggleSelect.emit(folder);
    }
    showCalendarAtendeeEmail(calendar) {
        return calendar &&
            calendar.getRecipients() &&
            calendar.getRecipients().length > 0 &&
            calendar.getRecipients()[0]['emailAddress']
            ? calendar.getRecipients()[0]['emailAddress']
            : '';
    }
    nodeStackIsEmpty() {
        return this.nodeStack.length === 0;
    }
    contentIsEmpty() {
        return this.contents.length === 0 && !this.searchingContents && !this.contentsInitialState;
    }
    onCategoryClicked(contentType) {
        this.contentType = contentType;
        this.onNodeClicked.emit(node_factory_1.NodeFactory.FOLDER({ id: this.rootFolder.getId(), contentType }));
    }
    isContentType(contentType) {
        return this.contentType === contentType;
    }
    toggleItemSelection(item, index) {
        this.itemClickedIndex = index;
        this.onItemClicked.emit(item);
    }
    searchFoldersAvailable() {
        return this.searchingFolders && this.currentFolder;
    }
    searchFoldersIsEmpty() {
        return this.folders.length === 0 && this.searchingFolders;
    }
    searchContentsIsEmpty() {
        return this.contents.length === 0 && this.searchingContents;
    }
    browseFolder(folder) {
        this.currentFolder = folder;
        this.contentType = folder.getContentType();
        this.onNodeClicked.emit(folder);
    }
    foldersIsEmpty() {
        return this.folders.length === 0 && !this.searchingFolders;
    }
    showCategory() {
        return this.nodeStackIsEmpty() && !this.searchingFolders && !this.foldersIsEmpty();
    }
    showCategoryContents(contentType) {
        return this.isContentType(contentType);
    }
    filter(query) {
        if (query.searchQuery) {
            this.onSearchContents.emit(query);
        }
        else {
            this.browseFolder(this.currentFolder);
        }
    }
    isRecipientsNotEmpty(row) {
        return row && row.getRecipients() && row.getRecipients().length > 1;
    }
}
exports.TreeComponent = TreeComponent;
