"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OktaEvidenceBasedControlWizardModule = void 0;
const okta_evidence_based_control_wizard_component_1 = require("./okta-evidence-based-control-wizard.component");
const activation_step_component_1 = require("./steps/activation/activation-step.component");
const user_groups_step_component_1 = require("./steps/user-groups/user-groups-step.component");
const events_and_okta_groups_step_component_1 = require("./steps/events-and-okta-groups/events-and-okta-groups-step.component");
const notifications_step_component_1 = require("./steps/notifications/notifications-step.component");
const summary_step_component_1 = require("./steps/summary/summary-step.component");
const COMPONENTS = [
    okta_evidence_based_control_wizard_component_1.OktaEvidenceBasedControlWizardComponent,
    activation_step_component_1.ActivationStepComponent,
    user_groups_step_component_1.UserGroupsStepComponent,
    events_and_okta_groups_step_component_1.EventsAndOktaGroupsStepComponent,
    notifications_step_component_1.NotificationsStepComponent,
    summary_step_component_1.SummaryStepComponent
];
class OktaEvidenceBasedControlWizardModule {
}
exports.OktaEvidenceBasedControlWizardModule = OktaEvidenceBasedControlWizardModule;
