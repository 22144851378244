"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const containers_1 = require("./containers");
const policy_component_1 = require("./containers/wizard/policy/policy.component");
angular
    .module('custom-domain-block', [])
    .controller('CustomDomainBlockController', [
    '$stateParams',
    '$scope',
    function ($stateParams, $scope) {
        $scope.stateParams = $stateParams;
    }
])
    .directive('mcCustomDomainBlock', static_1.downgradeComponent({
    component: containers_1.CustomDomainBlockPageComponent
}))
    .directive('mcWizardPolicy', static_1.downgradeComponent({
    component: policy_component_1.PolicyWizardComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider
            .state('custom-domain-block', {
            tabId: 'custom-domain-block',
            url: '/gateway/custom-domain-block',
            data: {
                isFixedLayout: false,
                tabId: 'custom-domain-block-policy-wizard',
                checkProgress: false,
                capabilities: 'Temporary.Custom.Domain.Block && Custom.Domain.Block' +
                    ' && (Permission.CUSTOM_DOMAIN_BLOCK_UPLOAD || Permission.CUSTOM_DOMAIN_BLOCK_DELETE )',
                tabReload: true
            },
            views: {
                main: {
                    template: '<mc-custom-domain-block></mc-custom-domain-block>',
                    controller: 'CustomDomainBlockController'
                }
            }
        })
            .state('custom-domain-block-policy-wizard', {
            url: '/gateway/custom-domain-block/create-policy',
            data: {
                tabId: 'custom-domain-block-policy-wizard',
                tabTitle: '$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.TAB_CREATE_TITLE',
                tabReload: false
            },
            views: {
                main: {
                    template: '<mc-wizard-policy></mc-wizard-policy>',
                    controller: 'CustomDomainBlockController'
                }
            },
            params: { policyDetails: undefined }
        });
    }
]);
