"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GroupsApiService = void 0;
const operators_1 = require("rxjs/operators");
class GroupsApiService {
    constructor(http) {
        this.http = http;
        this.defaultFindGroupsPageSize = 1000;
    }
    findGroups(config) {
        // set default number of return items from search
        const requestPayload = Object.assign(Object.assign({}, config), { meta: {
                pagination: {
                    pageSize: this.defaultFindGroupsPageSize
                }
            } });
        return this.http.post('/api/directory/find-groups', requestPayload).pipe(operators_1.map((response) => response.first), operators_1.map((response) => response.folders));
    }
    getGroup(config) {
        return this.http.post('/api/directory/get-group', config).pipe(operators_1.map((response) => response.all), operators_1.map((response) => response));
    }
}
exports.GroupsApiService = GroupsApiService;
