"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CyberGraphPolicyDetailsStepComponent = void 0;
const forms_1 = require("@angular/forms");
const operators_1 = require("rxjs/operators");
const cybergraph_policies_create_actions_1 = require("../../../actions/cybergraph-policies-create.actions");
const cybergraph_policies_model_1 = require("../../../models/cybergraph-policies.model");
class CyberGraphPolicyDetailsStepComponent {
    constructor(fb, store, userInfoApiService, featureFlagService, cdr) {
        this.fb = fb;
        this.store = store;
        this.userInfoApiService = userInfoApiService;
        this.featureFlagService = featureFlagService;
        this.cdr = cdr;
        this.trackerEnabled = false;
        this.cyberGraphPolicyDetailsForm = this.fb.group({
            name: ['', forms_1.Validators.required],
            description: [''],
            policyOverride: [false],
            codebreakerStatus: cybergraph_policies_model_1.CyberGraphDynamicBannersEnum.LEARNING,
            silencerStatus: cybergraph_policies_model_1.CyberGraphTrackersEnum.OFF,
            reportingStatus: false
        });
        this.dynamicBannerModeChangeSubscription = this.cyberGraphPolicyDetailsForm
            .get('codebreakerStatus')
            .valueChanges.subscribe(value => {
            if (value === cybergraph_policies_model_1.CyberGraphDynamicBannersEnum.ON) {
                this.cyberGraphPolicyDetailsForm.get('reportingStatus').setValue(true);
                return;
            }
            this.cyberGraphPolicyDetailsForm.get('reportingStatus').setValue(false);
        });
        this.formValueChangeSubscription = this.cyberGraphPolicyDetailsForm.valueChanges
            .pipe(operators_1.debounceTime(300))
            .subscribe(data => this.saveStoreData(data));
        this.featureFlagService.isReady().then(() => {
            this.userInfoApiService.getAccount().subscribe((account) => {
                const trackingFlagEnabled = this.featureFlagService.getBooleanFlag('rollout-cyg-21-image-tracker-ui', false);
                this.trackerEnabled = !account.cybergraphV2Enabled || trackingFlagEnabled;
                this.cdr.detectChanges();
            });
        });
    }
    ngOnInit() {
        this.saveStoreData(this.cyberGraphPolicyDetailsForm.value);
    }
    saveStoreData(data) {
        this.store.dispatch(new cybergraph_policies_create_actions_1.SaveDetailsStepDataAction(data));
    }
    reportingSwitchAvailable() {
        return (this.cyberGraphPolicyDetailsForm.get('codebreakerStatus').value ===
            cybergraph_policies_model_1.CyberGraphDynamicBannersEnum.ON);
    }
    isValid() {
        const nameValue = this.cyberGraphPolicyDetailsForm.get('name').value;
        return (!!nameValue &&
            nameValue.trim().length > 0 &&
            this.cyberGraphPolicyDetailsForm.status === 'VALID');
    }
    ngOnChanges(changes) {
        const change = changes['policyDetailsData'];
        if (change && change.previousValue !== change.currentValue) {
            if (change.currentValue) {
                this.cyberGraphPolicyDetailsForm.patchValue(change.currentValue);
            }
        }
    }
    ngOnDestroy() {
        this.formValueChangeSubscription.unsubscribe();
        this.dynamicBannerModeChangeSubscription.unsubscribe();
    }
}
exports.CyberGraphPolicyDetailsStepComponent = CyberGraphPolicyDetailsStepComponent;
