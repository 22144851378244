<div class="mc-card">
  <div class="image-container" (click)="partnerClick.emit()" [ngClass] = "clickable ? 'clickable' : 'nonclickable'">
    <img src="{{ image }}" width="230" height="100" alt="{{ title | translate }}" />
  </div>

  <ng-container *ngIf="useTranslation; else withoutTranslation">
    <span class="title">{{ title | translate }}</span>
    <div class="description">{{ description | translate }}</div>
    <div class="buttons-container">
      <button
        *ngIf="buttonText && displayButton"
        class="btn btn-default"
        (click)="partnerClick.emit()"
      >
        {{ buttonText | translate }}
      </button>
      <mc-list-dropdown-button
        *ngIf="optionsList.length > 0"
        disableCaret="true"
        class="more-dropdown"
      >
        <mc-dropdown-item
          [label]="option.label"
          (click)="optionClick.emit(option)"
          *ngFor="let option of optionsList"
        ></mc-dropdown-item>
      </mc-list-dropdown-button>
    </div>
  </ng-container>

  <ng-template #withoutTranslation>
    <span class="title">{{ title }}</span>
    <div class="description">{{ description }}</div>
    <div class="buttons-container">
      <button
        *ngIf="buttonText && displayButton"
        class="btn btn-default"
        (click)="partnerClick.emit()"
      >
        {{ buttonText }}
      </button>
      <mc-list-dropdown-button
        *ngIf="optionsList.length > 0"
        disableCaret="true"
        class="more-dropdown"
      >
        <mc-dropdown-item
          [label]="option.label"
          (click)="optionClick.emit(option)"
          *ngFor="let option of optionsList"
        ></mc-dropdown-item>
      </mc-list-dropdown-button>
    </div>
  </ng-template>
</div>
