<div class="row mc-detailed-list-row no-padding-top">
  <label class="col-sm-4 mc-detailed-list-label mc-sidebar-label">
    {{ '$18N_MEP_POLICY_LIST.SIDEBAR.DETAILS.NAME' | translate }}
  </label>
  <div
    class="col-sm-8 no-padding-left mc-detailed-list-column"
    data-test="mepPolicySidebarPolicyDetailsName"
  >
    {{ policy.name }}
  </div>
</div>
<div class="row mc-detailed-list-row no-padding-top">
  <label class="col-sm-4 mc-detailed-list-label mc-sidebar-label">
    {{ '$18N_MEP_POLICY_LIST.SIDEBAR.DETAILS.DESCRIPTION' | translate }}
  </label>
  <div
    class="col-sm-8 no-padding-left mc-detailed-list-column"
    data-test="mepPolicySidebarPolicyDetailsDescription"
  >
    {{ policy.description || '-' }}
  </div>
</div>
<div
  class="row mc-detailed-list-row no-padding-top"
  *ngIf="!isSummaryPage"
>
  <label class="col-sm-4 mc-detailed-list-label mc-sidebar-label">
    {{ '$18N_MEP_POLICY_LIST.SIDEBAR.DETAILS.STATUS' | translate }}
  </label>
  <div
    class="col-sm-8 no-padding-left mc-detailed-list-column"
    data-test="mepPolicySidebarPolicyDetailsEnabledStatus"
  >
    <mc-enabled-status
      [enabled]="policy.enabled"
      [showIcon]="true"
      [showColouredText]="false"
    ></mc-enabled-status>
  </div>
</div>
<div
  class="row mc-detailed-list-row no-padding-top"
  *ngIf="!isSummaryPage"
>
  <label class="col-sm-4 mc-detailed-list-label mc-sidebar-label">
    {{ '$18N_MEP_POLICY_LIST.SIDEBAR.DETAILS.CREATED_ON' | translate }}
  </label>
  <div
    class="col-sm-8 no-padding-left mc-detailed-list-column"
    data-test="mepPolicySidebarPolicyDetailsCreatedOn"
  >
    {{ policy.createTime | mcDate }}
  </div>
</div>
<div
  class="row mc-detailed-list-row no-padding-top"
  *ngIf="!isSummaryPage"
>
  <label class="col-sm-4 mc-detailed-list-label mc-sidebar-label">
    {{ '$18N_MEP_POLICY_LIST.SIDEBAR.DETAILS.LAST_MODIFIED' | translate }}
  </label>
  <div
    class="col-sm-8 no-padding-left mc-detailed-list-column"
    data-test="mepPolicySidebarPolicyDetailsLastModified"
  >
    {{ policy.lastUpdated | mcDate }}
  </div>
</div>
<div class="row mc-detailed-list-row no-padding-top">
  <label class="col-sm-4  mc-detailed-list-label mc-sidebar-label">
    {{ '$18N_MEP_POLICY_LIST.SIDEBAR.DETAILS.ACTION' | translate }}
  </label>
  <div class="col-sm-8 no-padding-left mc-detailed-list-column">
    {{'$18N_MEP_POLICY_LIST.TABLE.ACTIONS.' + policy.action  | uppercase | translate }}
  </div>
</div>
<div
  class="row mc-detailed-list-row no-padding-top"
  data-test="mepPolicySidebarPolicyDetailsAutoRelease"
  *ngIf="policy.action !== noAction"
>
  <label class="col-sm-4 text-right mc-sidebar-label">
    {{ '$18N_MEP_POLICY_LIST.SIDEBAR.DETAILS.AUTO_RELEASE' | translate }}
    <mc-mep-tooltip
    tooltipTitle="autoRelease"></mc-mep-tooltip>
  </label>
  <div class="col-sm-8 no-padding-left mc-detailed-list-column" data-test="mepPolicySidebarPolicyDetailsAutoRelease">
    {{ (policy.autoReleaseTimeoutMinutes | mcMinutesToHours) || '-' }}
  </div>
</div>
<div class="row mc-detailed-list-row no-padding-top">
  <label class="col-sm-4 text-right mc-sidebar-label">
    {{ '$18N_MEP_POLICY_LIST.SIDEBAR.DETAILS.MATCHING_LEVEL' | translate }}
    <mc-mep-tooltip
      tooltipTitle="matchLevel"
      [inSidebar]="true"
    ></mc-mep-tooltip>
  </label>
  <div
    class="col-sm-8 no-padding-left mc-detailed-list-column"
    data-test="mepPolicySidebarPolicyDetailsMatchingLevel"
  >
    {{'$18N_MEP_POLICY_LIST.TABLE.MATCHING_LEVEL.' + policy.matchLevel  | uppercase | translate }}
  </div>
</div>
