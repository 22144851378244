"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogDetailsEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const notificationAction = require("../../../../../components/notification/actions/notification.actions");
const actions = require("../../actions/log-details.actions");
const logDetailsActions = require("../../actions/log-details.actions");
class LogDetailsEffects {
    constructor(actions$, store, logDetailsService, managedSenderService, chameleonService) {
        this.actions$ = actions$;
        this.store = store;
        this.logDetailsService = logDetailsService;
        this.managedSenderService = managedSenderService;
        this.chameleonService = chameleonService;
        this.getLogDetails$ = this.actions$.pipe(effects_1.ofType(logDetailsActions.GET_LOG_DETAILS), operators_1.switchMap((action) => {
            return this.logDetailsService.getLogDetails(action.id).pipe(operators_1.map(response => new actions.GetLogDetailsSuccessAction(response)), operators_1.catchError(() => {
                return rxjs_1.of(new actions.GetLogDetailsFailAction());
            }));
        }));
        this.permitSender$ = this.actions$.pipe(effects_1.ofType(logDetailsActions.PERMIT_SENDER), operators_1.switchMap((action) => {
            const instances = [
                {
                    sender: action.instance.sender,
                    action: 'permit',
                    to: action.instance.to
                }
            ];
            return this.managedSenderService.permitOrBlockerSender(instances).pipe(operators_1.mergeMap(() => [
                new actions.PermitSenderSuccessAction(),
                new notificationAction.NotificationShowAction({
                    type: 'success',
                    config: { msg: 'Sender permitted.' }
                })
            ]), operators_1.catchError(() => rxjs_1.merge(rxjs_1.of(new actions.PermitSenderFailAction()), rxjs_1.of(new notificationAction.NotificationShowAction({
                type: 'error',
                config: { msg: "Couldn't permit the sender. Please try again later." }
            })))));
        }));
        this.blockSender$ = this.actions$.pipe(effects_1.ofType(logDetailsActions.BLOCK_SENDER), operators_1.switchMap((action) => {
            const instances = [
                {
                    sender: action.instance.sender,
                    action: 'block',
                    to: action.instance.to
                }
            ];
            return this.managedSenderService.permitOrBlockerSender(instances).pipe(operators_1.mergeMap(() => [
                new actions.BlockSenderSuccessAction(),
                new notificationAction.NotificationShowAction({
                    type: 'success',
                    config: { msg: 'Sender blocked.' }
                })
            ]), operators_1.catchError(() => rxjs_1.merge(rxjs_1.of(new actions.BlockSenderFailAction()), rxjs_1.of(new notificationAction.NotificationShowAction({
                type: 'error',
                config: { msg: "Couldn't block the sender. Please try again later." }
            })))));
        }));
        this.monitorDomain$ = this.actions$.pipe(effects_1.ofType(logDetailsActions.MONITOR_DOMAIN), operators_1.switchMap((action) => {
            const payload = {
                domainName: [LogDetailsEffects.getDomainFromEmail(action.sender)],
                comment: 'TTP IP Logs'
            };
            return this.chameleonService.createMonitoredExternalDomains(payload).pipe(operators_1.mergeMap(() => [
                new actions.MonitorDomainSuccessAction(),
                new notificationAction.NotificationShowAction({
                    type: 'success',
                    config: { msg: `Domain ${payload.domainName[0]} monitored.` }
                })
            ]), operators_1.catchError(() => rxjs_1.merge(rxjs_1.of(new actions.MonitorDomainFailAction()), rxjs_1.of(new notificationAction.NotificationShowAction({
                type: 'error',
                config: {
                    msg: `Couldn't monitor domain ${payload.domainName[0]}. Please try again later.`
                }
            })))));
        }));
    }
    static getDomainFromEmail(email) {
        return email.substring(email.lastIndexOf('@') + 1);
    }
}
__decorate([
    effects_1.Effect()
], LogDetailsEffects.prototype, "getLogDetails$", void 0);
__decorate([
    effects_1.Effect()
], LogDetailsEffects.prototype, "permitSender$", void 0);
__decorate([
    effects_1.Effect()
], LogDetailsEffects.prototype, "blockSender$", void 0);
__decorate([
    effects_1.Effect()
], LogDetailsEffects.prototype, "monitorDomain$", void 0);
exports.LogDetailsEffects = LogDetailsEffects;
