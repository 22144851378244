"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgPolicyProxySettingsStepComponent = void 0;
const core_1 = require("@angular/core");
const operators_1 = require("rxjs/operators");
class SwgPolicyProxySettingsStepComponent {
    constructor(fb, translateService, stateService) {
        this.fb = fb;
        this.translateService = translateService;
        this.stateService = stateService;
        this.hasExtendedProxy = false;
        this.hasBI = false;
        this.protectedApplicationsLoading = false;
        this.updateProxySettings = new core_1.EventEmitter();
        this.updateWebCategoryRules = new core_1.EventEmitter();
        this.columns = ['extension', 'mimeType', 'action', 'delete-column'];
        this.proxyExtNotification = {
            status: 'info',
            msg: this.translateService.instant('$I18N_SWG_CREATE_POLICY_WEB_ADVANCED_SECURITY_PROXY_EXT'),
            boxed: false
        };
    }
    ngOnInit() {
        this.proxySettingsForm = this.fb.group({
            enabled: this.proxySettings.enabled,
            avScan: this.proxySettings.avScan,
            biEnabled: this.proxySettings.biEnabled,
            blockUnscannable: this.proxySettings.blockUnscannable
        });
        this.avScanSubscription = this.proxySettingsForm.controls['avScan'].valueChanges.subscribe(val => {
            if (this.hasBI && val && !this.proxySettings.biEnabled) {
                this.proxySettingsForm.patchValue({ biEnabled: true });
            }
        });
        this.subscription = this.proxySettingsForm.valueChanges
            .pipe(operators_1.debounceTime(200))
            .subscribe(() => {
            this.updateSettings();
        });
    }
    updateSettings() {
        const pSettings = this.proxySettingsForm.value;
        this.updateProxySettings.emit(pSettings);
        if (!pSettings.enabled && pSettings.avScan) {
            this.proxySettingsForm.patchValue({ avScan: false });
        }
        // WS-1943: If AV Scanning is disabled, reset the apps selected in Proxy Extensions.
        if (!pSettings.avScan) {
            this.updateWebCategoryRules.emit([]);
        }
    }
    isValid() {
        return true;
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
        if (this.avScanSubscription) {
            this.avScanSubscription.unsubscribe();
        }
    }
}
exports.SwgPolicyProxySettingsStepComponent = SwgPolicyProxySettingsStepComponent;
