"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgEndpointMapOsVersion = exports.SwgEndpointsListMapOsIconPipe = exports.SwgEndpointsListMapOsVersionPipe = exports.SwgEndpointsListMapOsPipe = void 0;
const endpoints_list_models_1 = require("../models/endpoints-list.models");
class SwgEndpointsListMapOsPipe {
    constructor(translateService) {
        this.translateService = translateService;
    }
    transform(row) {
        return this.mapOs(row);
    }
    mapOs(os) {
        let osCode = '$I18N_ENDPOINTS_LIST.TABLE.TABLE_CELL.PLATFORM.UNKNOWN';
        if (!os) {
            return osCode;
        }
        const lowerCasedOs = os.toLowerCase();
        if (lowerCasedOs.indexOf('win') >= 0) {
            osCode = '$I18N_ENDPOINTS_LIST.TABLE.TABLE_CELL.PLATFORM.WIN';
            if (lowerCasedOs.indexOf('nt') >= 0) {
                osCode = '$I18N_ENDPOINTS_LIST.TABLE.TABLE_CELL.PLATFORM.WIN_NT';
            }
            if (lowerCasedOs.indexOf('server') >= 0) {
                osCode = '$I18N_ENDPOINTS_LIST.TABLE.TABLE_CELL.PLATFORM.SERVER';
            }
        }
        else if (lowerCasedOs.indexOf('mac') >= 0) {
            osCode = '$I18N_ENDPOINTS_LIST.TABLE.TABLE_CELL.PLATFORM.MAC';
        }
        else if (lowerCasedOs.indexOf('ios') >= 0) {
            osCode = '$I18N_ENDPOINTS_LIST.TABLE.TABLE_CELL.PLATFORM.IOS';
        }
        else if (lowerCasedOs.indexOf('android') >= 0) {
            osCode = '$I18N_ENDPOINTS_LIST.TABLE.TABLE_CELL.PLATFORM.ANDROID';
        }
        return this.translateService.instant(osCode);
    }
}
exports.SwgEndpointsListMapOsPipe = SwgEndpointsListMapOsPipe;
class SwgEndpointsListMapOsVersionPipe {
    // Helper function for getting the OS version number
    getOsVersion(osStr) {
        const osVersionMatch = osStr ? osStr.match(/\d+/g) : null;
        return osVersionMatch ? osVersionMatch.slice(0, 2).join('.') : null;
    }
    transform(row) {
        return this.mapOsVersion(row);
    }
    mapOsVersion(row) {
        return this.getOsVersion(row.osVersion || row.os);
    }
}
exports.SwgEndpointsListMapOsVersionPipe = SwgEndpointsListMapOsVersionPipe;
class SwgEndpointsListMapOsIconPipe {
    transform(os) {
        if (!os) {
            return 'fa-question';
        }
        const lowerCasedOs = os.toLowerCase();
        if (lowerCasedOs.indexOf('ios') >= 0) {
            return 'mc-icon-ios';
        }
        else if (lowerCasedOs.indexOf('mac') >= 0) {
            return 'mc-icon-macos';
        }
        else if (lowerCasedOs.indexOf('android') >= 0) {
            return 'mc-icon-android';
        }
        else if (lowerCasedOs.indexOf('win') >= 0 || lowerCasedOs.indexOf('server') >= 0) {
            return 'mc-icon-windows';
        }
        else {
            return 'fa-question';
        }
    }
}
exports.SwgEndpointsListMapOsIconPipe = SwgEndpointsListMapOsIconPipe;
class SwgEndpointMapOsVersion {
    getOsVersion(osStr) {
        const osVersionMatch = osStr ? osStr.match(/\d+/g) : null;
        if (osStr.includes(endpoints_list_models_1.DEVICE.WINDOWS_SERVER_2016_2019)) {
            return endpoints_list_models_1.DEVICE.WINDOWS_SERVER_2016_2019;
        }
        else if (osStr.toLowerCase().includes(endpoints_list_models_1.DEVICE.WINDOW8)) {
            return '8.1';
        }
        else if (osStr.toLowerCase().includes(endpoints_list_models_1.DEVICE.WINDOWS_SERVER_R2)) {
            return `${osVersionMatch[0]} ${endpoints_list_models_1.DEVICE.WINDOWS_SERVER_R2.toUpperCase()}`;
        }
        else if (osStr.toLowerCase().includes(endpoints_list_models_1.DEVICE.WINDOWS)) {
            return osVersionMatch[0];
        }
        else if (osVersionMatch) {
            return osVersionMatch.slice(0, 2).join('.');
        }
        else {
            return null;
        }
    }
    transform(row) {
        return this.mapOsVersion(row);
    }
    mapOsVersion(row) {
        return this.getOsVersion(row.osVersion || row.os);
    }
}
exports.SwgEndpointMapOsVersion = SwgEndpointMapOsVersion;
