"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfirmationModalComponent = void 0;
const fromChameleonState = require("../../reducers/index");
class ConfirmationModalComponent {
    constructor(store) {
        this.store = store;
        this.selectedItemsSubscription = this.store
            .select(fromChameleonState.getAllSelectedItems)
            .subscribe((selectedItems) => {
            this.numberOfBulkItemsToDelete =
                selectedItems.length > 1 ? selectedItems.length : this.numberOfBulkItemsToDelete;
            this.domainNameOfSingleItem =
                selectedItems.length === 1 ? selectedItems[0].domainName : this.domainNameOfSingleItem;
        });
    }
    ngOnDestroy() {
        this.selectedItemsSubscription.unsubscribe();
    }
}
exports.ConfirmationModalComponent = ConfirmationModalComponent;
