"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMigratingConnector = exports.getAzureStandardIntegrationsToMigrate = exports.getConnectorsToMigrate = exports.getMigrationStatus = exports.reducer = exports.MigrationReducers = exports.initialState = void 0;
const connectors_migration_actions_1 = require("../actions/connectors-migration.actions");
const connector_interface_1 = require("../models/connector.interface");
const wizard_actions_1 = require("../../connectors/actions/wizard.actions");
exports.initialState = {
    connectorsToMigrate: null,
    azureStandardIntegrationsToMigrate: null,
    migratingConnector: null,
    connectorsMigrationStatus: []
};
class MigrationReducers {
    static getConnectorStatusSuccess(state, payload) {
        let connectorsToMigrate;
        let azureStandardIntegrationsToMigrate;
        try {
            connectorsToMigrate = payload
                .filter((group) => group.productName !== 'Directory Synchronization')
                .flatMap((group) => group.connectors)
                .filter((connector) => connector.status === connector_interface_1.MigrationStatus.RECONNECT)
                .length;
            azureStandardIntegrationsToMigrate = payload
                .filter((group) => group.productName === 'Directory Synchronization')
                .flatMap((group) => group.connectors)
                .filter((connector) => connector.status === connector_interface_1.MigrationStatus.RECONNECT)
                .length;
        }
        catch (err) {
            connectorsToMigrate = 0;
            azureStandardIntegrationsToMigrate = 0;
        }
        const sortedByProductName = [
            ...payload
        ].sort((productA, productB) => productA.productName < productB.productName ? -1 : 1);
        return Object.assign(Object.assign({}, state), { connectorsMigrationStatus: sortedByProductName, connectorsToMigrate,
            azureStandardIntegrationsToMigrate });
    }
    static startMigration(state, connector, product) {
        return Object.assign(Object.assign({}, state), { migratingConnector: Object.assign(Object.assign(Object.assign({}, connector), product), { type: connector.connectorType
                    .toLowerCase()
                    .replace(' ', '_')
                    .replace('onedrive', 'one_drive') }) });
    }
    static migrationReset(state) {
        return Object.assign(Object.assign({}, state), { migratingConnector: null });
    }
}
exports.MigrationReducers = MigrationReducers;
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case connectors_migration_actions_1.ConnectorsMigrationActions.GET_CONNECTOR_MIGRATION_STATUS_SUCCESS: {
            return MigrationReducers.getConnectorStatusSuccess(state, action.payload);
        }
        case connectors_migration_actions_1.ConnectorsMigrationActions.START_MIGRATION: {
            return MigrationReducers.startMigration(state, action.connector, action.migrationGroup);
        }
        case connectors_migration_actions_1.ConnectorsMigrationActions.MIGRATION_COMPLETE: {
            return MigrationReducers.migrationReset(state);
        }
        case connectors_migration_actions_1.ConnectorsMigrationActions.MIGRATION_FAILED: {
            return MigrationReducers.migrationReset(state);
        }
        case wizard_actions_1.WizardActions.CANCEL_YES: {
            return MigrationReducers.migrationReset(state);
        }
        default: {
            return state;
        }
    }
}
exports.reducer = reducer;
const getMigrationStatus = (state) => state && state.connectorsMigrationStatus;
exports.getMigrationStatus = getMigrationStatus;
const getConnectorsToMigrate = (state) => state && state.connectorsToMigrate;
exports.getConnectorsToMigrate = getConnectorsToMigrate;
const getAzureStandardIntegrationsToMigrate = (state) => state && state.azureStandardIntegrationsToMigrate;
exports.getAzureStandardIntegrationsToMigrate = getAzureStandardIntegrationsToMigrate;
const getMigratingConnector = (state) => state && state.migratingConnector;
exports.getMigratingConnector = getMigratingConnector;
