"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ2 = exports.ɵ1 = exports.ɵ0 = exports.canUpdateListForListAction = exports.getSelectedRow = exports.getAsidePagination = exports.canUpdateListForAsideAction = exports.hasGenericErrorOnAttributeLoad = exports.isLoadingAttributeDetails = exports.getAttributeDetails = exports.reducers = void 0;
const listReducer = require("./list/list.reducer");
const asideReducer = require("./aside/aside.reducer");
const paginationReducer = require("./pagination/pagination.reducer");
const store_1 = require("@ngrx/store");
// Reducers
exports.reducers = {
    list: listReducer.reducer,
    aside: asideReducer.reducer,
    asidePagination: paginationReducer.reducer
};
// Selectors
const getAttributeState = store_1.createFeatureSelector('attributes');
const ɵ0 = (state) => state.aside;
exports.ɵ0 = ɵ0;
const getAsideState = store_1.createSelector(getAttributeState, ɵ0);
exports.getAttributeDetails = store_1.createSelector(getAsideState, asideReducer.getAttributeDetails);
exports.isLoadingAttributeDetails = store_1.createSelector(getAsideState, asideReducer.isLoadingAttributeDetails);
exports.hasGenericErrorOnAttributeLoad = store_1.createSelector(getAsideState, asideReducer.hasGenericError);
exports.canUpdateListForAsideAction = store_1.createSelector(getAsideState, asideReducer.canUpdateList);
const ɵ1 = (state) => state.asidePagination;
exports.ɵ1 = ɵ1;
const asidePaginationState = store_1.createSelector(getAttributeState, ɵ1);
exports.getAsidePagination = store_1.createSelector(asidePaginationState, paginationReducer.getPagination);
exports.getSelectedRow = store_1.createSelector(asidePaginationState, paginationReducer.selectedRow);
const ɵ2 = (state) => state.list;
exports.ɵ2 = ɵ2;
const getListState = store_1.createSelector(getAttributeState, ɵ2);
exports.canUpdateListForListAction = store_1.createSelector(getListState, listReducer.canUpdateList);
