"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgUpdateApplicationStatusFailure = exports.SwgUpdateApplicationStatusSuccess = exports.SwgUpdateApplicationStatus = exports.SwgLoadTopProfilesFailureAction = exports.SwgLoadTopProfilesSuccessAction = exports.SwgLoadTopProfilesAction = exports.SwgLoadTopBlockedApplicationsFailureAction = exports.SwgLoadTopBlockedApplicationsSuccessAction = exports.SwgLoadTopBlockedApplicationsAction = exports.SwgLoadSanctionedApplicationSuccessAction = exports.SwgLoadApplicationStatusFailureAction = exports.SwgLoadApplicationStatusSuccessAction = exports.SwgLoadApplicationStatusAction = exports.SwgLoadTopApplicationsFailureAction = exports.SwgLoadTopApplicationsSuccessAction = exports.SwgLoadTopApplicationsAction = exports.SwgLoadChartDataSuccessAction = exports.SwgGetApplicationChartInfoAction = exports.SwgLoadTopApplicationsActionsEnum = void 0;
var SwgLoadTopApplicationsActionsEnum;
(function (SwgLoadTopApplicationsActionsEnum) {
    SwgLoadTopApplicationsActionsEnum["UPDATE_APPLICATION_STATUS"] = "[SwgTopApplications] Update Applications Status";
    SwgLoadTopApplicationsActionsEnum["UPDATE_APPLICATION_STATUS_SUCCESS"] = "[SwgTopApplications] Update Applications Status Failure";
    SwgLoadTopApplicationsActionsEnum["UPDATE_APPLICATION_STATUS_FAILURE"] = "[SwgTopApplications] Update Applications Status Success";
    SwgLoadTopApplicationsActionsEnum["LOAD_TOP_APPLICATIONS"] = "[SwgTopApplications] Load Top Applications";
    SwgLoadTopApplicationsActionsEnum["LOAD_TOP_APPLICATIONS_SUCCESS"] = "[SwgTopApplications] Load Top Applications Success";
    SwgLoadTopApplicationsActionsEnum["LOAD_TOP_APPLICATIONS_FAILURE"] = "[SwgTopApplications] Load Top Applications Failure";
    SwgLoadTopApplicationsActionsEnum["LOAD_APPLICATION_STATUS"] = "[SwgApplicationStatus] Load Application Status";
    SwgLoadTopApplicationsActionsEnum["LOAD_APPLICATION_STATUS_SUCCESS"] = "[SwgApplicationStatus] Load Application Status Success";
    SwgLoadTopApplicationsActionsEnum["LOAD_APPLICATION_STATUS_FAILURE"] = "[SwgApplicationStatus] Load Application Status Failure";
    SwgLoadTopApplicationsActionsEnum["LOAD_SANCTIONED_APPLICATION_SUCCESS"] = "[SwgTopApplications] Load Sanctioned Application Success";
    SwgLoadTopApplicationsActionsEnum["LOAD_TOP_BLOCKED_APPLICATIONS"] = "[SwgTopApplications] Load Top Blocked Applications";
    SwgLoadTopApplicationsActionsEnum["LOAD_TOP_BLOCKED_APPLICATIONS_SUCCESS"] = "[SwgTopApplications] Load Top Blocked Applications Success";
    SwgLoadTopApplicationsActionsEnum["LOAD_TOP_BLOCKED_APPLICATIONS_FAILURE"] = "[SwgTopApplications] Load Top Blocked Applications Failure";
    SwgLoadTopApplicationsActionsEnum["LOAD_TOP_PROFILES"] = "[SwgTopApplications] Load Top Profiles";
    SwgLoadTopApplicationsActionsEnum["LOAD_TOP_PROFILES_SUCCESS"] = "[SwgTopApplications] Load Top Profiles Success";
    SwgLoadTopApplicationsActionsEnum["LOAD_TOP_PROFILES_FAILURE"] = "[SwgTopApplications] Load Top Profiles Failure";
    SwgLoadTopApplicationsActionsEnum["LOAD_CHART_DATA_SUCCESS"] = "[SwgTopApplications] Load Chart Data Success";
    SwgLoadTopApplicationsActionsEnum["GET_CHART_INFO"] = "[SwgTopApplications] Get Chart Info";
})(SwgLoadTopApplicationsActionsEnum = exports.SwgLoadTopApplicationsActionsEnum || (exports.SwgLoadTopApplicationsActionsEnum = {}));
class SwgGetApplicationChartInfoAction {
    constructor(dateRange, correlationId) {
        this.dateRange = dateRange;
        this.correlationId = correlationId;
        this.type = SwgLoadTopApplicationsActionsEnum.GET_CHART_INFO;
    }
}
exports.SwgGetApplicationChartInfoAction = SwgGetApplicationChartInfoAction;
class SwgLoadChartDataSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgLoadTopApplicationsActionsEnum.LOAD_CHART_DATA_SUCCESS;
    }
}
exports.SwgLoadChartDataSuccessAction = SwgLoadChartDataSuccessAction;
class SwgLoadTopApplicationsAction {
    constructor(dateRange, correlationId) {
        this.dateRange = dateRange;
        this.correlationId = correlationId;
        this.type = SwgLoadTopApplicationsActionsEnum.LOAD_TOP_APPLICATIONS;
    }
}
exports.SwgLoadTopApplicationsAction = SwgLoadTopApplicationsAction;
class SwgLoadTopApplicationsSuccessAction {
    constructor(payload, correlationId) {
        this.payload = payload;
        this.correlationId = correlationId;
        this.type = SwgLoadTopApplicationsActionsEnum.LOAD_TOP_APPLICATIONS_SUCCESS;
    }
}
exports.SwgLoadTopApplicationsSuccessAction = SwgLoadTopApplicationsSuccessAction;
class SwgLoadTopApplicationsFailureAction {
    constructor(correlationId) {
        this.correlationId = correlationId;
        this.type = SwgLoadTopApplicationsActionsEnum.LOAD_TOP_APPLICATIONS_FAILURE;
    }
}
exports.SwgLoadTopApplicationsFailureAction = SwgLoadTopApplicationsFailureAction;
class SwgLoadApplicationStatusAction {
    constructor(correlationId) {
        this.correlationId = correlationId;
        this.type = SwgLoadTopApplicationsActionsEnum.LOAD_APPLICATION_STATUS;
    }
}
exports.SwgLoadApplicationStatusAction = SwgLoadApplicationStatusAction;
class SwgLoadApplicationStatusSuccessAction {
    constructor(payload, correlationId) {
        this.payload = payload;
        this.correlationId = correlationId;
        this.type = SwgLoadTopApplicationsActionsEnum.LOAD_APPLICATION_STATUS_SUCCESS;
    }
}
exports.SwgLoadApplicationStatusSuccessAction = SwgLoadApplicationStatusSuccessAction;
class SwgLoadApplicationStatusFailureAction {
    constructor(correlationId) {
        this.correlationId = correlationId;
        this.type = SwgLoadTopApplicationsActionsEnum.LOAD_APPLICATION_STATUS_FAILURE;
    }
}
exports.SwgLoadApplicationStatusFailureAction = SwgLoadApplicationStatusFailureAction;
class SwgLoadSanctionedApplicationSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgLoadTopApplicationsActionsEnum.LOAD_SANCTIONED_APPLICATION_SUCCESS;
    }
}
exports.SwgLoadSanctionedApplicationSuccessAction = SwgLoadSanctionedApplicationSuccessAction;
class SwgLoadTopBlockedApplicationsAction {
    constructor(dateRange, correlationId) {
        this.dateRange = dateRange;
        this.correlationId = correlationId;
        this.type = SwgLoadTopApplicationsActionsEnum.LOAD_TOP_BLOCKED_APPLICATIONS;
    }
}
exports.SwgLoadTopBlockedApplicationsAction = SwgLoadTopBlockedApplicationsAction;
class SwgLoadTopBlockedApplicationsSuccessAction {
    constructor(payload, correlationId) {
        this.payload = payload;
        this.correlationId = correlationId;
        this.type = SwgLoadTopApplicationsActionsEnum.LOAD_TOP_BLOCKED_APPLICATIONS_SUCCESS;
    }
}
exports.SwgLoadTopBlockedApplicationsSuccessAction = SwgLoadTopBlockedApplicationsSuccessAction;
class SwgLoadTopBlockedApplicationsFailureAction {
    constructor(correlationId) {
        this.correlationId = correlationId;
        this.type = SwgLoadTopApplicationsActionsEnum.LOAD_TOP_BLOCKED_APPLICATIONS_FAILURE;
    }
}
exports.SwgLoadTopBlockedApplicationsFailureAction = SwgLoadTopBlockedApplicationsFailureAction;
class SwgLoadTopProfilesAction {
    constructor(dateRange) {
        this.dateRange = dateRange;
        this.type = SwgLoadTopApplicationsActionsEnum.LOAD_TOP_PROFILES;
    }
}
exports.SwgLoadTopProfilesAction = SwgLoadTopProfilesAction;
class SwgLoadTopProfilesSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgLoadTopApplicationsActionsEnum.LOAD_TOP_PROFILES_SUCCESS;
    }
}
exports.SwgLoadTopProfilesSuccessAction = SwgLoadTopProfilesSuccessAction;
class SwgLoadTopProfilesFailureAction {
    constructor() {
        this.type = SwgLoadTopApplicationsActionsEnum.LOAD_TOP_PROFILES_FAILURE;
    }
}
exports.SwgLoadTopProfilesFailureAction = SwgLoadTopProfilesFailureAction;
class SwgUpdateApplicationStatus {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgLoadTopApplicationsActionsEnum.UPDATE_APPLICATION_STATUS;
    }
}
exports.SwgUpdateApplicationStatus = SwgUpdateApplicationStatus;
class SwgUpdateApplicationStatusSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgLoadTopApplicationsActionsEnum.UPDATE_APPLICATION_STATUS_SUCCESS;
    }
}
exports.SwgUpdateApplicationStatusSuccess = SwgUpdateApplicationStatusSuccess;
class SwgUpdateApplicationStatusFailure {
    constructor() {
        this.type = SwgLoadTopApplicationsActionsEnum.UPDATE_APPLICATION_STATUS_FAILURE;
    }
}
exports.SwgUpdateApplicationStatusFailure = SwgUpdateApplicationStatusFailure;
