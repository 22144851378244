<mc-table tableId="url-protection/logs" [isLoading]="isLoading$ | async" [highlightedRow]="selectedRows"
  [columns]="columns" [data]="tableData$ | async" (rowClick)="clickRow($event)"
  translatePrefix="$I18N_SERVICES_URL_PROTECTION_LOGS_TABLE.TABLE">

  <button
    class="mc-table-actions btn btn-secondary mc-button-margin-right"
    (click)="exportResults()"
  >
    {{ '$I18N_SERVICES_URL_PROTECTION_LOGS_TABLE.ACTIONS.EXPORT' | translate }}
  </button>

  <mc-filters [showPagination]="true" [metadata]="metadata" (paginatorChange)="changePaginator($event)">
    <mc-filter-column-select-and-search [sections]="searchSections" [placeholder]="
        '$I18N_SERVICES_URL_PROTECTION_LOGS_TABLE.TABLE.COLUMN_VALUE_SINGLE_SELECT_FILTER_FIELD.PLACEHOLDER'
          | translate
      " (filtersChange)="changeSearchFilter($event)">
    </mc-filter-column-select-and-search>

    <div class="mc-filters-right">
      <mc-filters-bundle-date-range [config]="dateRangePickerConfig" [useCurrentMoment]="true"
        (filtersChange)="onDateFilterChange($event)">
      </mc-filters-bundle-date-range>
      <ng-container *ngIf="filterSections">
        <mc-filters-bundle-column-values-modal data-test="cy-filter-logs" [sections]="filterSections"
          (filtersChange)="changeColumnFilter($event)">
        </mc-filters-bundle-column-values-modal>
      </ng-container>
    </div>
  </mc-filters>

  <mc-empty-results keyTitle="$I18N_SERVICES_URL_PROTECTION_LOGS_TABLE.TABLE.EMPTY_RESULTS"
    iconClass="mc-icon-adcon-icon-stable-no-results-2">
  </mc-empty-results>

  <mc-column key="fromUserEmailAddress">
    <mc-body-cell *mcBodyCellDef="let row">{{ row.fromUserEmailAddress }}</mc-body-cell>
  </mc-column>

  <mc-column key="userEmailAddress">
    <mc-body-cell *mcBodyCellDef="let row">{{ row.userEmailAddress }}</mc-body-cell>
  </mc-column>

  <mc-column key="subject">
    <mc-body-cell *mcBodyCellDef="let row">{{ row.subject }}</mc-body-cell>
  </mc-column>

  <mc-column key="ttpDefinition">
    <mc-body-cell *mcBodyCellDef="let row">{{ row.ttpDefinition }}</mc-body-cell>
  </mc-column>

  <mc-column key="url">
    <mc-body-cell *mcBodyCellDef="let row">
      <mc-browser-isolation-detector [logLink]="row" (urlClick)="urlClick($event)"></mc-browser-isolation-detector>
    </mc-body-cell>
  </mc-column>

  <mc-column-date key="date"></mc-column-date>

  <mc-column key="scanResult">
    <mc-body-cell *mcBodyCellDef="let row">
      <i [ngClass]="displayScanResultIcon(row)"></i>
      {{ displayScanResultLabel(row) }}
    </mc-body-cell>
  </mc-column>

  <mc-column key="actions">
    <mc-body-cell *mcBodyCellDef="let row">{{ row.actions }}</mc-body-cell>
  </mc-column>

  <mc-column key="adminOverride">
    <mc-body-cell *mcBodyCellDef="let row">
      <i [ngClass]="displayAdminOverrideIcon(row)"></i>
      {{ displayAdminOverrideLabel(row) }}
    </mc-body-cell>
  </mc-column>

  <mc-column key="userOverride">
    <mc-body-cell *mcBodyCellDef="let row">
      <i [ngClass]="displayUserOverrideIcon(row)"></i>
      {{ displayUserOverrideLabel(row) }}
    </mc-body-cell>
  </mc-column>

  <mc-column key="userAwarenessAction">
    <mc-body-cell *mcBodyCellDef="let row">
      <i [ngClass]="displayUserAwarenessIcon(row)"></i>
      {{ displayUserAwarenessLabel(row) }}
    </mc-body-cell>
  </mc-column>

  <mc-column key="route">
    <mc-body-cell *mcBodyCellDef="let row">{{ displayRouteLabel(row.route) }}</mc-body-cell>
  </mc-column>

  <mc-column-actions key="right-column" mcShowColumnConfig [columnsAlwaysVisible]="columnsAlwaysVisible"
    [columnsToStartHidden]="columnsToStartHidden" [columnsToIgnore]="columnsToIgnore">

    <mc-row-actions *mcRowActions="let row">
      <ng-container *ngIf="canEdit">
        <li data-test="cy-allow-meatball-btn" mcRowAction="$I18N_SERVICES_URL_PROTECTION_LOGS_DETAILS.LOG_ALLOW_BUTTON"
          data-unit-test="unit-allow-url-action-btn" (click)="allowUrl(row)"></li>
        <li data-test="cy-block-meatball-btn"
          mcRowAction="$I18N_SERVICES_URL_PROTECTION_LOGS_DETAILS.LOG_BLOCK_BUTTON"
          data-unit-test="unit-block-url-action-btn" (click)="blockUrl(row)">
        </li>
      </ng-container>
      <ng-container *ngIf="canCheckUrl">
        <li data-test="cy-check-rul-meatball-btn"
          mcRowAction="$I18N_SERVICES_URL_PROTECTION_LOGS_URL_CHECK.MEATBALL_BUTTON"
          (click)="checkUrl(row)"></li>
      </ng-container>
      <ng-container *ngIf="canCheckUrl || canEdit">
        <li mcRowActionSeparator></li>
      </ng-container>
      <ng-container *ngIf="isBrowserIsolationEnabled">
        <li data-test="cy-logs-open-url" mcRowAction="$I18N_SERVICES_URL_PROTECTION_LOGS_DETAILS.LOG_OPEN_LINK_BUTTON"
        data-unit-test="unit-open-link-action-btn" (click)="urlClick(row)"></li>
      </ng-container>
      <ng-container *ngIf="!canEdit">
        <li mcRowAction="$I18N_SERVICES_URL_PROTECTION_LOGS_DETAILS.ACTIONS.DETAILS"
          data-test="cy-details-meatball-btn" data-unit-test="unit-details-action-btn" (click)="clickRow(row)"></li>
      </ng-container>
    </mc-row-actions>

  </mc-column-actions>
</mc-table>