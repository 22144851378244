<mc-ap-create-policy-wizard
  (resetWizard)="resetWizard()"
  [policy]="policy$ | async"
  [attributeList]="attributeOptions$"
  [wizardFlowType]="wizardFlowType$ | async"
  (updateTypApDetailseWizard)= "updateApPolicyDescType($event)"
  (updateSummaryWizard)="updateApSummaryType($event)"
  (updateTypeApInboundWizard)= "updateApPolicyInboundType($event)"
  (updateTypeApJournalWizard)= "updateApPolicyJournalType($event)"
  (updateTypeApOutboundWizard)= "updateApPolicyOutboundType($event)"
  (updateTypeApAppliesToWizard)= "updateApPolicyAppliesType($event)"
  (createApPolicyEvent)= "createApPolicy($event)"
  (updateApPolicyEvent)= "updateApPolicy($event)"
></mc-ap-create-policy-wizard>
