"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("angular-ui-bootstrap"); //ui.bootstrap.modal
require("./date-range-modal-controller"); //date.range.modal.controller
angular.module('date.range.modal.service', ['ui.bootstrap.modal', 'date.range.modal.controller'])
    .factory('dateRangeModalService', ['$http', '$q', '$uibModal', function ($http, $q, $uibModal) {
        const self = this;
        self.modalParamaters = {};
        self.open = (startDate, endDate, showTimePicker, minStartDate, maxEndDate) => {
            self.modalParamaters = {
                startDate,
                endDate,
                showTimePicker,
                minStartDate,
                maxEndDate
            };
            const modalInstance = $uibModal.open({
                templateUrl: 'components/date-range-modal/date-range-modal.tpl.html',
                windowClass: 'hidden-xs',
                backdropClass: 'hidden-xs',
                size: 'lg'
            });
            return modalInstance.result;
        };
        return self;
    }]);
