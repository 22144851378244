"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BiPolicyDeleteConfirmationComponent = void 0;
const sidebar = require("../../../actions/sidebar.actions");
const reducers = require("../../../reducers");
const policyActions = require("../../../actions/policy.actions");
class BiPolicyDeleteConfirmationComponent {
    constructor(store) {
        this.store = store;
    }
    ngOnInit() {
        this.policy$ = this.store.select(reducers.getBiPolicy);
        this.isDeleting$ = this.store.select(reducers.getApiIsProcessingDelete);
    }
    onDelete(data) {
        this.store.dispatch(new policyActions.BiDeletePolicy(data));
    }
    onCancel() {
        this.store.dispatch(new sidebar.BiDetailDeleteConfirmModelClose());
    }
}
exports.BiPolicyDeleteConfirmationComponent = BiPolicyDeleteConfirmationComponent;
