<div class="mc-customized-block">
  <div class="mc-top-banner"></div>
  <div class="mc-content-body col-md-offset-2 col-md-8">
    <div class="mc-block-badge mc-faded">
      <mc-feedback-badge
        status="error"
        text="$I18N_SWG_BLOCK_PAGE_BLOCKED_SITE_TITLE"
      ></mc-feedback-badge>
    </div>
    <h2 class="font-weight-normal panel-double-padding-top mc-breakword">{{ getTitle() }}</h2>
    <p class="panel-padding-top mc-faded">{{ '$I18N_SWG_BLOCK_PAGE_BLOCKED_SITE' | translate }}</p>
    <p class="panel-padding-bottom mc-faded">
      {{ '$I18N_SWG_BLOCK_PAGE_BLOCKED_SITE_REASON' | translate }}
    </p>
    <p class="mc-breakword" [innerHTML]="getBody() | mcLinkify"></p>
  </div>
  <div class="mc-bottom-banner"></div>
</div>
