"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPagingMetadata = exports.getAAAGroups = exports.getPagination = exports.getTotalCount = exports.getFilters = exports.getSelectedRow = exports.getIntegrations = exports.isEmpty = exports.isLoading = exports.reducer = exports.initialState = void 0;
const actions = require("../actions");
exports.initialState = {
    isLoading: false,
    isEmpty: true,
    integrations: [],
    paginatorMetadata: {
        pageToken: undefined,
        pageSize: 50,
        next: undefined,
        previous: undefined,
        totalCount: undefined
    },
    filters: {},
    pagination: {
        pageToken: undefined,
        pageSize: 50,
        pageNumber: undefined
    },
    groups: []
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.GET_ALL_DIRECTORY_INTEGRATIONS:
            return Object.assign(Object.assign({}, state), { isLoading: true, isEmpty: true, integrations: [], selectedRow: undefined, groups: [] });
        case actions.GET_ALL_DIRECTORY_INTEGRATIONS_SUCCESS:
            return Object.assign(Object.assign({}, state), { isLoading: false, isEmpty: !action.payload.data.length, integrations: [...action.payload.data], paginatorMetadata: Object.assign({}, action.payload.meta.pagination), groups: removeDuplicateGroups(action.payload.data) });
        case actions.DIRECTORY_INTEGRATIONS_FAILURE:
            return Object.assign(Object.assign({}, state), { isLoading: false, groups: [{ failToRetrieveGroups: true }] });
        case actions.PAGE_INTEGRATIONS_OPEN_ASIDE:
            return Object.assign(Object.assign({}, state), { selectedRow: action.aside.integration });
        case actions.UPDATE_INTEGRATION_STATUS_SUCCESS:
            return Object.assign(Object.assign({}, state), { selectedRow: action.integration });
        case actions.PAGE_INTEGRATIONS_CLOSE_ASIDE:
            return Object.assign(Object.assign({}, state), { selectedRow: undefined });
        case actions.SYNC_ALL_INTEGRATIONS:
        case actions.SYNC_ALL_INTEGRATIONS_SUCCESS:
        case actions.SYNC_ALL_INTEGRATIONS_FAILURE:
            return Object.assign({}, state);
        case actions.SAVE_SEARCH_FILTERS:
            return Object.assign(Object.assign({}, state), { filters: action.filters, pagination: action.pagination });
        default:
            return state;
    }
}
exports.reducer = reducer;
const isLoading = (state) => state.isLoading;
exports.isLoading = isLoading;
const isEmpty = (state) => state.isEmpty;
exports.isEmpty = isEmpty;
const getIntegrations = (state) => state.integrations;
exports.getIntegrations = getIntegrations;
const getSelectedRow = (state) => state.selectedRow;
exports.getSelectedRow = getSelectedRow;
const getFilters = (state) => state.filters;
exports.getFilters = getFilters;
const getTotalCount = (state) => state.integrations.length;
exports.getTotalCount = getTotalCount;
const getPagination = (state) => state.pagination;
exports.getPagination = getPagination;
const getAAAGroups = (state) => state.groups;
exports.getAAAGroups = getAAAGroups;
const getPagingMetadata = (state) => {
    return {
        pagination: Object.assign(Object.assign({}, state.paginatorMetadata), { totalRows: state.paginatorMetadata.totalCount })
    };
};
exports.getPagingMetadata = getPagingMetadata;
function removeDuplicateGroups(integrations) {
    const groups = [];
    integrations.forEach(integration => groups.push(integration.group === null || !integration.group ? 'N/A' : integration.group));
    return [...new Set(groups)];
}
