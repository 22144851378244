"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetServerConnectionFail = exports.GetServerConnectionSuccess = exports.GetServerConnection = exports.UpdateDefinitonId = exports.CreateDefinitionFailAction = exports.CreateDefinitionSuccessAction = exports.CreateDefinitionSaveAndExitAction = exports.CreateDefinitionSaveAction = exports.RemoveJournalGroupAction = exports.JournalSelectGroup = exports.RemoveOutboundGroupAction = exports.OutboundSelectGroup = exports.RemoveAdministratorGroupAction = exports.AdministratorSelectGroup = exports.SERVER_CONNECTION_FAIL = exports.CREATE_DEFINITION_FAIL = exports.CREATE_DEFINITION_SUCCESS = exports.ApDefinitionCreateTypesEnum = exports.ApDefinitionNavigateToListPageAction = exports.ApdefinitionTypesEnum = exports.GET_SERVER_CONNECTION_SUCCESS = exports.GET_SERVER_CONNECTION = exports.LOAD_ID_DEFINITION_DETAILS = exports.CREATE_DEFINITION_SAVE_AND_EXIT = exports.CREATE_DEFINITION_SAVE = exports.ApDefinitionNavigateToCreateDefinition = exports.ApdefinitionCreateTypesEnum = void 0;
var ApdefinitionCreateTypesEnum;
(function (ApdefinitionCreateTypesEnum) {
    ApdefinitionCreateTypesEnum["NAVIGATE_TO_ATTACHMENT_PROTECTION_CREATE_DEFINITION"] = "[Ap Definition] Navigate to Create Definition";
})(ApdefinitionCreateTypesEnum = exports.ApdefinitionCreateTypesEnum || (exports.ApdefinitionCreateTypesEnum = {}));
class ApDefinitionNavigateToCreateDefinition {
    constructor() {
        this.type = ApdefinitionCreateTypesEnum.NAVIGATE_TO_ATTACHMENT_PROTECTION_CREATE_DEFINITION;
    }
}
exports.ApDefinitionNavigateToCreateDefinition = ApDefinitionNavigateToCreateDefinition;
exports.CREATE_DEFINITION_SAVE = '[Services / Ap Definition / Definition] Create definition Save';
exports.CREATE_DEFINITION_SAVE_AND_EXIT = '[Services / Ap Definition / Definition] Create definition Save and Exit';
exports.LOAD_ID_DEFINITION_DETAILS = '[Ap Definition] Load definitionId to Edit definition';
exports.GET_SERVER_CONNECTION = '[Ap Definition] Get Server Connection';
exports.GET_SERVER_CONNECTION_SUCCESS = '[Ap Definition] Get Server Connection Success';
var ApdefinitionTypesEnum;
(function (ApdefinitionTypesEnum) {
    ApdefinitionTypesEnum["NAVIGATE_TO_ATTACHMENT_PROTECTION_LIST_PAGE"] = "[Ap Definition] Navigate to List Page";
})(ApdefinitionTypesEnum = exports.ApdefinitionTypesEnum || (exports.ApdefinitionTypesEnum = {}));
class ApDefinitionNavigateToListPageAction {
    constructor() {
        this.type = ApdefinitionTypesEnum.NAVIGATE_TO_ATTACHMENT_PROTECTION_LIST_PAGE;
    }
}
exports.ApDefinitionNavigateToListPageAction = ApDefinitionNavigateToListPageAction;
var ApDefinitionCreateTypesEnum;
(function (ApDefinitionCreateTypesEnum) {
    ApDefinitionCreateTypesEnum["ADD_ADMINISTRATOR_GROUP"] = "[AP Policy] Add Group";
    ApDefinitionCreateTypesEnum["REMOVE_ADMINISTRATOR_GROUP"] = "[AP Policy] Remove Group";
    ApDefinitionCreateTypesEnum["ADD_ADMINISTRATOR_OUTBOUND_GROUP"] = "[AP Policy] Add Outbound Group";
    ApDefinitionCreateTypesEnum["REMOVE_ADMINISTRATOR_OUTBOUND_GROUP"] = "[AP Policy] Remove Outbound Group";
    ApDefinitionCreateTypesEnum["ADD_ADMINISTRATOR_JOURNAL_GROUP"] = "[AP Policy] Add Journal Group";
    ApDefinitionCreateTypesEnum["REMOVE_JOURNAL_GROUP"] = "[AP Policy] Remove Journal Group";
})(ApDefinitionCreateTypesEnum = exports.ApDefinitionCreateTypesEnum || (exports.ApDefinitionCreateTypesEnum = {}));
exports.CREATE_DEFINITION_SUCCESS = '[Services / Ap Definition / Definition] Create definition - SUCCESS';
exports.CREATE_DEFINITION_FAIL = '[Services / Ap Definition / Definition] Create definition - FAIL';
exports.SERVER_CONNECTION_FAIL = '[Services / Ap Definition / Definition] Server Connection - FAIL';
class AdministratorSelectGroup {
    constructor(payload) {
        this.payload = payload;
        this.type = ApDefinitionCreateTypesEnum.ADD_ADMINISTRATOR_GROUP;
    }
}
exports.AdministratorSelectGroup = AdministratorSelectGroup;
class RemoveAdministratorGroupAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApDefinitionCreateTypesEnum.REMOVE_ADMINISTRATOR_GROUP;
    }
}
exports.RemoveAdministratorGroupAction = RemoveAdministratorGroupAction;
class OutboundSelectGroup {
    constructor(payload) {
        this.payload = payload;
        this.type = ApDefinitionCreateTypesEnum.ADD_ADMINISTRATOR_OUTBOUND_GROUP;
    }
}
exports.OutboundSelectGroup = OutboundSelectGroup;
class RemoveOutboundGroupAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApDefinitionCreateTypesEnum.REMOVE_ADMINISTRATOR_OUTBOUND_GROUP;
    }
}
exports.RemoveOutboundGroupAction = RemoveOutboundGroupAction;
class JournalSelectGroup {
    constructor(payload) {
        this.payload = payload;
        this.type = ApDefinitionCreateTypesEnum.ADD_ADMINISTRATOR_JOURNAL_GROUP;
    }
}
exports.JournalSelectGroup = JournalSelectGroup;
class RemoveJournalGroupAction {
    constructor(payload) {
        this.payload = payload;
        this.type = ApDefinitionCreateTypesEnum.REMOVE_JOURNAL_GROUP;
    }
}
exports.RemoveJournalGroupAction = RemoveJournalGroupAction;
class CreateDefinitionSaveAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.CREATE_DEFINITION_SAVE;
    }
}
exports.CreateDefinitionSaveAction = CreateDefinitionSaveAction;
class CreateDefinitionSaveAndExitAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.CREATE_DEFINITION_SAVE_AND_EXIT;
    }
}
exports.CreateDefinitionSaveAndExitAction = CreateDefinitionSaveAndExitAction;
class CreateDefinitionSuccessAction {
    constructor() {
        this.type = exports.CREATE_DEFINITION_SUCCESS;
    }
}
exports.CreateDefinitionSuccessAction = CreateDefinitionSuccessAction;
class CreateDefinitionFailAction {
    constructor() {
        this.type = exports.CREATE_DEFINITION_FAIL;
    }
}
exports.CreateDefinitionFailAction = CreateDefinitionFailAction;
class UpdateDefinitonId {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOAD_ID_DEFINITION_DETAILS;
    }
}
exports.UpdateDefinitonId = UpdateDefinitonId;
class GetServerConnection {
    constructor() {
        this.type = exports.GET_SERVER_CONNECTION;
    }
}
exports.GetServerConnection = GetServerConnection;
class GetServerConnectionSuccess {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.GET_SERVER_CONNECTION_SUCCESS;
    }
}
exports.GetServerConnectionSuccess = GetServerConnectionSuccess;
class GetServerConnectionFail {
    constructor() {
        this.type = exports.SERVER_CONNECTION_FAIL;
    }
}
exports.GetServerConnectionFail = GetServerConnectionFail;
