<div class="mc-services-im-sync-wizard-step-container">
  <mc-wizard-step-header
    label="$I18N_ARCHIVE_IM_SYNC_WIZARD.IM_SYNC_TASK.DETAILS_STEP.DESCRIPTION"
  >
  <p>{{ '$I18N_ARCHIVE_IM_SYNC_WIZARD.IM_SYNC_TASK.DETAILS_STEP.EXPLANATION' | translate }}</p>
  </mc-wizard-step-header>


  <form
    [formGroup]="form"
    class="form-horizontal"
    mcDefaultLabelClass="col-sm-3"
    mcDefaultControlContainerClass="col-sm-9"
  >
    <mc-text-field
      label="{{'$I18N_ARCHIVE_IM_SYNC_WIZARD.IM_SYNC_TASK.DETAILS_STEP.FIELD_NAME' | translate}}"
      placeholder="{{'$I18N_ARCHIVE_IM_SYNC_WIZARD.IM_SYNC_TASK.DETAILS_STEP.FIELD_NAME' | translate}}"
      formControlName="name"
      required
      errorPrefix="$I18N_ARCHIVE_IM_SYNC_WIZARD.IM_SYNC_TASK.VALIDATION.NAME"
    ></mc-text-field>

    <mc-text-area-field
      label="{{'$I18N_ARCHIVE_IM_SYNC_WIZARD.IM_SYNC_TASK.DETAILS_STEP.FIELD_DESCRIPTION' | translate}}"
      placeholder="{{'$I18N_ARCHIVE_IM_SYNC_WIZARD.IM_SYNC_TASK.DETAILS_STEP.FIELD_DESCRIPTION' | translate}}"
      formControlName="description"
      errorPrefix="$I18N_ARCHIVE_IM_SYNC_WIZARD.IM_SYNC_TASK.VALIDATION.DESCRIPTION"
    ></mc-text-area-field>

    <mc-field 
      label="{{ '$I18N_ARCHIVE_IM_SYNC_WIZARD.IM_SYNC_TASK.DETAILS_STEP.FIELD_IM_SOURCE' | translate }}"
      useTranslation="true"
      required
    >
      <mc-select
        formControlName="source"
        required
        errorPrefix="$I18N_ARCHIVE_IM_SYNC_WIZARD.IM_SYNC_TASK.VALIDATION.NAME"
        [options]="sourceOptions"
        [defaultValue]="sourceOptions[0].value"
    ></mc-select>
  </mc-field> 
  </form>
</div>
