"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PackagePoliciesApiService = void 0;
const operators_1 = require("rxjs/operators");
class PackagePoliciesApiService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    getPackagePolicies(request) {
        return this.httpClient
            .post('/api/policy/packageref/get-policies-count', request)
            .pipe(operators_1.map(response => response.first));
    }
}
exports.PackagePoliciesApiService = PackagePoliciesApiService;
