"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgDeviceGroupsSidebarContainerComponent = void 0;
const table_store_1 = require("@mimecast-ui/table-store");
const device_groups_sidebar_actions_1 = require("../../actions/device-groups-sidebar.actions");
const device_groups_models_1 = require("../../models/device-groups.models");
const device_groups_list_actions_1 = require("../../actions/device-groups-list.actions");
class SwgDeviceGroupsSidebarContainerComponent extends table_store_1.TableRowDetailBaseComponent {
    constructor(store, capabilitiesService) {
        super(store, 'SwgDeviceGroupsListTable');
        this.store = store;
        this.capabilitiesService = capabilitiesService;
        this.selectedRowSubscription = this.selectedRow$.subscribe((row) => {
            if (row) {
                this.deviceGroup = row;
            }
        });
        this.hasEditPermission$ = this.capabilitiesService.hasCapability('Permission.SWG_ENDPOINT_LIST_EDIT');
    }
    close() {
        this.store.dispatch(new device_groups_sidebar_actions_1.SwgDeviceGroupSidebarClose());
    }
    onEditClick() {
        this.store.dispatch(new device_groups_sidebar_actions_1.SwgDeviceGroupSidebarClose());
        this.store.dispatch(new device_groups_list_actions_1.SwgClearDeviceGroupAction());
        this.store.dispatch(new device_groups_list_actions_1.SwgLoadDeviceGroupToEditAction(this.deviceGroup));
        this.store.dispatch(new device_groups_list_actions_1.SwgNavigateToDeviceGroupWizardAction(device_groups_models_1.DeviceGroupWizardFlowType.EDIT));
    }
    onDeleteClick() {
        this.store.dispatch(new device_groups_sidebar_actions_1.SwgDeviceGroupDeleteConfirmationModalOpen(this.deviceGroup));
    }
    ngOnDestroy() {
        if (this.selectedRowSubscription) {
            this.selectedRowSubscription.unsubscribe();
        }
    }
}
exports.SwgDeviceGroupsSidebarContainerComponent = SwgDeviceGroupsSidebarContainerComponent;
