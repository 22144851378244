"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.getIsApiProcessing = exports.getIsEditFlow = exports.getLocationItem = exports.reducers = void 0;
const store_1 = require("@ngrx/store");
const locationUpdate = require("./swg-location.reducer");
exports.reducers = {
    locationUpdate: locationUpdate.reducer
};
const getSwgLocationUpdateState = store_1.createFeatureSelector('swgLocationUpdate');
const ɵ0 = state => state.locationUpdate;
exports.ɵ0 = ɵ0;
const getSwgLocationState = store_1.createSelector(getSwgLocationUpdateState, ɵ0);
exports.getLocationItem = store_1.createSelector(getSwgLocationState, locationUpdate.getLocationItem);
exports.getIsEditFlow = store_1.createSelector(getSwgLocationState, locationUpdate.getIsEditFlow);
exports.getIsApiProcessing = store_1.createSelector(getSwgLocationState, locationUpdate.getIsApiProcessing);
