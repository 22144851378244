"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApDefinitionEffects = exports.TABLE_ID = void 0;
const effects_1 = require("@ngrx/effects");
const create_definition_actions_1 = require("../actions/create-definition.actions");
const operators_1 = require("rxjs/operators");
const rxjs_1 = require("rxjs");
const table_store_1 = require("@mimecast-ui/table-store");
const listActions = require("../actions/definition.actions");
const confirmation_modal_component_1 = require("../../../url-protection/policies/components/confirmation-modal/confirmation-modal.component");
const definitions_model_1 = require("../models/definitions.model");
exports.TABLE_ID = 'definitionsListTable';
class ApDefinitionEffects {
    constructor(stateService, actions$, modalService, tableStore, definitionListImplService, notificationService, translateService) {
        this.stateService = stateService;
        this.actions$ = actions$;
        this.modalService = modalService;
        this.tableStore = tableStore;
        this.definitionListImplService = definitionListImplService;
        this.notificationService = notificationService;
        this.translateService = translateService;
        this.goToListPage$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(create_definition_actions_1.ApdefinitionTypesEnum.NAVIGATE_TO_ATTACHMENT_PROTECTION_LIST_PAGE), operators_1.tap(() => {
                this.stateService.$state.go('attachment-protection-definition-list');
            }));
        }, { dispatch: false });
        this.deleteDefinitionAttempt$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(listActions.DEFINITION_DELETE_ATTEMPT), operators_1.switchMap((action) => {
                return this.modalService
                    .open(confirmation_modal_component_1.ConfirmationModalComponent, {
                    data: {
                        type: definitions_model_1.CONFIRMATION_DIALOG_TYPES.DELETE,
                        definition: action.payload
                    }
                })
                    .afterClose()
                    .pipe(operators_1.map((result) => {
                    return result
                        ? new listActions.DefinitionDeleteAction(action.payload)
                        : new listActions.DefinitionDeleteCancelAction();
                }));
            }));
        });
        this.deleteDefinition$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(listActions.DEFINITION_DELETE), operators_1.switchMap((action) => {
                return this.definitionListImplService.deleteDefinition({ id: action.payload }).pipe(operators_1.map((payload) => {
                    return payload
                        ? new listActions.DefinitionDeleteSucessAction()
                        : new listActions.DefinitionDeleteFailAction('');
                }), operators_1.catchError((error) => { var _a, _b; return rxjs_1.of(new listActions.DefinitionDeleteFailAction((_b = (_a = error === null || error === void 0 ? void 0 : error.firstFail) === null || _a === void 0 ? void 0 : _a.errors[0]) === null || _b === void 0 ? void 0 : _b.message)); }));
            }));
        });
        this.deleteDefinitionSuccess$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(listActions.DEFINITION_DELETE_SUCCESS), operators_1.withLatestFrom(this.tableStore.select(table_store_1.tableReducer.getCurrentFilters(exports.TABLE_ID)), this.tableStore.select(table_store_1.tableReducer.getCurrentPagination(exports.TABLE_ID))), operators_1.mergeMap(([, currentFilters, currentPagination]) => {
                return [
                    this.notificationService.newNotification({
                        type: 'success',
                        config: {
                            msg: this.translateService.instant('$I18N_SERVICES_ATTACHMENT_PROTECTION.DELETE_DEFINITION')
                        }
                    }),
                    new table_store_1.LoadAction({
                        tableId: exports.TABLE_ID,
                        pagination: { pageToken: '', pageSize: currentPagination.pageSize, pageNumber: 0 },
                        query: currentFilters
                    })
                ];
            }));
        });
        this.deleteDefinitionFail$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(listActions.DEFINITION_DELETE_FAIL), operators_1.switchMap((action) => {
                return [
                    this.notificationService.newNotification({
                        type: 'error',
                        config: {
                            msg: action.payload
                        }
                    })
                ];
            }));
        });
    }
}
exports.ApDefinitionEffects = ApDefinitionEffects;
