"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SystemMessagesEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const actions = require("../actions/system-messages.action");
const reducers = require("../reducers/system-messages");
const commonActions = require("../../common/actions");
const notification_actions_1 = require("app-new/components/notification/actions/notification.actions");
const api_1 = require("app-new/components/export/api");
const system_messages_notifications_1 = require("./system-messages.notifications");
class SystemMessagesEffects {
    constructor(actions$, service, store$) {
        this.actions$ = actions$;
        this.service = service;
        this.store$ = store$;
        this.loadItems$ = this.actions$.pipe(effects_1.ofType(actions.SYSTEM_MESSAGES_REQUEST, actions.SYSTEM_MESSAGES_PAGINATION, actions.SYSTEM_MESSAGES_REFRESH, ...Object.keys(system_messages_notifications_1.systemNotifications)), operators_1.withLatestFrom(this.store$.select(reducers.getSystemMessagesSearch), this.store$.select(reducers.getSystemMessagesRequestPage)), operators_1.switchMap(([, request, pagination]) => {
            return this.service.getItems(request, pagination).pipe(operators_1.map(response => new actions.SystemMessagesSuccessAction(response)), operators_1.catchError(() => rxjs_1.of(new actions.SystemMessagesFailAction())));
        }));
        this.displayRow$ = this.actions$.pipe(effects_1.ofType(actions.SYSTEM_MESSAGES_SELECT_ROW), operators_1.withLatestFrom(this.store$.select(reducers.getSystemMessagesItems)), operators_1.switchMap(([action, items]) => {
            if (action.payload) {
                return rxjs_1.of(new commonActions.ShowMessageInfoAction(action.payload, items, false));
            }
            return rxjs_1.EMPTY;
        }));
        this.systemNotifications$ = this.actions$.pipe(effects_1.ofType(...Object.keys(system_messages_notifications_1.systemNotifications)), operators_1.map((action) => {
            const notification = system_messages_notifications_1.systemNotifications[action.type];
            return new notification_actions_1.NotificationShowAction({
                config: { msg: notification.message },
                type: notification.type
            });
        }));
        this.rejectEmails$ = this.actions$.pipe(effects_1.ofType(actions.SYSTEM_MESSAGES_REJECT_EMAILS), operators_1.switchMap((action) => {
            return this.service.rejectEmails(action.payload).pipe(operators_1.map(() => new actions.SystemMessagesRejectEmailsSuccessAction(action.payload)), operators_1.catchError(() => rxjs_1.of(new actions.SystemMessagesRejectEmailsFailAction(action.payload))));
        }));
        this.retryEmails$ = this.actions$.pipe(effects_1.ofType(actions.SYSTEM_MESSAGES_RETRY_EMAILS), operators_1.switchMap((action) => {
            return this.service.retryEmails(action.payload).pipe(operators_1.map(() => new actions.SystemMessagesRetryEmailsSuccessAction(action.payload)), operators_1.catchError(() => rxjs_1.of(new actions.SystemMessagesRetryEmailsFailAction(action.payload))));
        }));
        this.earlyBounceEmails$ = this.actions$.pipe(effects_1.ofType(actions.SYSTEM_MESSAGES_EARLY_BOUNCE_EMAILS), operators_1.switchMap((action) => {
            return this.service.earlyBounceEmails(action.payload).pipe(operators_1.map(() => new actions.SystemMessagesEarlyBounceEmailsSuccessAction(action.payload)), operators_1.catchError(() => rxjs_1.of(new actions.SystemMessagesEarlyBounceEmailsFailAction(action.payload))));
        }));
        this.export$ = this.actions$.pipe(effects_1.ofType(actions.SYSTEM_MESSAGES_EXPORT), operators_1.withLatestFrom(this.store$.select(reducers.getSystemMessagesSearch)), operators_1.map(([action, search]) => {
            const apiSearch = this.service.transformToServerRequest(search);
            const apiPayload = this.service.transformToExportServerRequest(action.payload);
            return new api_1.OpenSidePanelExportApiAction('/api/gateway/export-system-messages', apiPayload, apiSearch, '$I18N_MESSAGE_CENTER_SYSTEM_MESSAGES_EXPORT');
        }));
    }
}
__decorate([
    effects_1.Effect()
], SystemMessagesEffects.prototype, "loadItems$", void 0);
__decorate([
    effects_1.Effect()
], SystemMessagesEffects.prototype, "displayRow$", void 0);
__decorate([
    effects_1.Effect()
], SystemMessagesEffects.prototype, "systemNotifications$", void 0);
__decorate([
    effects_1.Effect()
], SystemMessagesEffects.prototype, "rejectEmails$", void 0);
__decorate([
    effects_1.Effect()
], SystemMessagesEffects.prototype, "retryEmails$", void 0);
__decorate([
    effects_1.Effect()
], SystemMessagesEffects.prototype, "earlyBounceEmails$", void 0);
__decorate([
    effects_1.Effect()
], SystemMessagesEffects.prototype, "export$", void 0);
exports.SystemMessagesEffects = SystemMessagesEffects;
