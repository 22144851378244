"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdConAuthInterceptor = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
class AdConAuthInterceptor {
    /**
     * Constructor
     */
    constructor(sessionService, coreService) {
        this.sessionService = sessionService;
        this.coreService = coreService;
    }
    /**
     * Intercept Function
     */
    intercept(req, next) {
        return next.handle(req).pipe(operators_1.catchError((event) => {
            return this.handleError(req, event);
        }));
    }
    /**
     * Handle Errors
     * Logout if certain conditions are met!
     */
    handleError(newRequest, event) {
        const errorStatus = event.status === 401 || event.status === 403;
        const logoutOnError = this.shouldLogoutOnError(newRequest);
        if (this.getSessionIdOrTempSessionId() && errorStatus && logoutOnError) {
            this.coreService.logout(true);
        }
        return rxjs_1.throwError(event);
    }
    /**
     * Check if the request has logoutOnError header
     * If it has, check if it's true / false
     */
    shouldLogoutOnError(newRequest) {
        if (newRequest.headers.has('logoutOnError')) {
            return newRequest.headers.get('logoutOnError') === 'true';
        }
        return true;
    }
    getSessionIdOrTempSessionId() {
        return this.sessionService.sessionId || this.sessionService.tempSessionId;
    }
}
exports.AdConAuthInterceptor = AdConAuthInterceptor;
