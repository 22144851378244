<div class="row mc-detailed-list-row no-padding-top">
  <div class="mc-sidebar-label mc-detailed-list-label">
    {{ '$I18N_LOG_SIDEBAR.MESSAGE_OVERVIEW.SUBHEADINGS.SENDER' | translate }}
  </div>
  <div
    class="mc-detailed-list-column"
    data-test="mepLogSidebarDetailsSender"
  >
    {{ currentItem.sender }}
  </div>
</div>
<div class="row mc-detailed-list-row no-padding-top">
  <div class="mc-sidebar-label mc-detailed-list-label">
    {{ '$I18N_LOG_SIDEBAR.MESSAGE_OVERVIEW.SUBHEADINGS.RECIPIENTS' | translate }}
  </div>
  <div
    class="mc-detailed-list-column"
    data-test="mepLogSidebarDetailsRecipients"
  >
    <span
      data-test="mepLogSidebarDetailsRecipientEmails"
      *ngFor="let email of currentItem.recipients | slice:0:3; let i = index;"
    >
      {{email}}
      <span *ngIf="!((currentItem.recipients?.length - 1) === i)">&#44;</span>
    </span>
    <span *ngIf="currentItem.recipients?.length > 3 && !(showMore | async)">
      <br>
      <a
        (click)="showMore.next(true)"
        data-test="showMore"
      >
        {{ '$I18N_LOG_SIDEBAR.MESSAGE_OVERVIEW.SHOW_MORE' | translate }}
        <i
          class="fa fa-angle-down"
          aria-hidden="true"
        ></i>
      </a>
    </span>
    <ng-container *ngIf="showMore | async">
      <span
        data-test="email"
        *ngFor="let email of currentItem.recipients | slice:3; let last = last;"
      >
        {{email}}
        <span *ngIf="!last">&#44;</span>
      </span>
      <span>
        <br>
        <a
          data-test="showLess"
          (click)="showMore.next(false)"
        >
          {{ '$I18N_LOG_SIDEBAR.MESSAGE_OVERVIEW.SHOW_LESS' | translate }}
          <i
            class="fa fa-angle-up"
            aria-hidden="true"
          ></i>
        </a>
      </span>
    </ng-container>
  </div>
</div>
<div class="row mc-detailed-list-row no-padding-top">
  <div class="mc-sidebar-label mc-detailed-list-label">
    {{ '$I18N_LOG_SIDEBAR.MESSAGE_OVERVIEW.SUBHEADINGS.SUBJECT' | translate }}
  </div>
  <div
    class="mc-detailed-list-column"
    data-test="mepLogSidebarDetailsSubject"
  >
    {{ currentItem.subject.trim() || '-' }}
  </div>
</div>
<div class="row mc-detailed-list-row no-padding-top">
  <div class="mc-sidebar-label mc-detailed-list-label">
    {{ '$I18N_LOG_SIDEBAR.MESSAGE_OVERVIEW.SUBHEADINGS.MESSAGE_ID' | translate }}
  </div>
  <div
    class="mc-detailed-list-column"
    data-test="mepLogSidebarDetailsMessageId"
  >
    {{ currentItem.messageId }}
  </div>
</div>
<div class="row mc-detailed-list-row no-padding-top">
  <div class="mc-sidebar-label mc-detailed-list-label">
    {{ '$I18N_LOG_SIDEBAR.MESSAGE_OVERVIEW.SUBHEADINGS.DATE_TIME' | translate }}
  </div>
  <div
    class="mc-detailed-list-column"
    data-test="mepLogSidebarDetailsLastModified"
  >
    {{ currentItem.lastModified | mcDate }}
  </div>
</div>
