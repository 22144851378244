<div class="mc-api-gateway-step-block">
  <mc-wizard-step-header [label]="pageTitle">
    {{ pageDescription | translate }}
  </mc-wizard-step-header>

  <form
    class="form-horizontal"
    mcDefaultLabelClass="col-sm-3"
    mcDefaultControlContainerClass="col-sm-9"
    [formGroup]="form"
    (ngSubmit)="submit()"
  >
    <div class="row form-group">
      <mc-text-field
        errorPrefix="$I18N_API_GATEWAY_V3_WIZARD.VALIDATION.FIELD"
        label="$I18N_API_GATEWAY_V3_WIZARD.FIELDS.TECHNICAL_POINT_OF_CONTACT"
        formControlName="developerName"
        required
        [helpPopoverContent]="'$I18N_API_GATEWAY_V3_WIZARD.TOOLTIPS.TPOC_NAME' | translate"
      >
      </mc-text-field>
    </div>

    <div class="row form-group">
      <mc-text-field
        errorPrefix="$I18N_API_GATEWAY_V3_WIZARD.VALIDATION.FIELD"
        label="$I18N_API_GATEWAY_V3_WIZARD.FIELDS.DEVELOPER_EMAIL"
        formControlName="developerEmail"
        required
        [helpPopoverContent]="'$I18N_API_GATEWAY_V3_WIZARD.TOOLTIPS.TPOC_EMAIL' | translate"
      >
      </mc-text-field>
    </div>
  </form>
</div>
