"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.isCloudGroupReady = exports.isLDAPGroupReady = exports.isGetGroupsLoadingError = exports.hasInitialLDAPData = exports.hasInitialCloudData = exports.isGetGroupsLDAPDataLoaded = exports.isGetGroupsCloudDataLoaded = exports.getGroups = void 0;
const store_1 = require("@ngrx/store");
const getGroupsReducer = require("../reducers/get-groups.reducer");
const archive_groups_1 = require("./archive-groups");
const ɵ0 = (state) => state.getGroups;
exports.ɵ0 = ɵ0;
const getSupervisionGetGroupsState = store_1.createSelector(archive_groups_1.getArchiveGroupsState, ɵ0);
exports.getGroups = store_1.createSelector(getSupervisionGetGroupsState, getGroupsReducer.getGroups);
exports.isGetGroupsCloudDataLoaded = store_1.createSelector(getSupervisionGetGroupsState, getGroupsReducer.isCloudDataLoaded);
exports.isGetGroupsLDAPDataLoaded = store_1.createSelector(getSupervisionGetGroupsState, getGroupsReducer.isLDAPDataLoaded);
exports.hasInitialCloudData = store_1.createSelector(getSupervisionGetGroupsState, getGroupsReducer.hasCloudData);
exports.hasInitialLDAPData = store_1.createSelector(getSupervisionGetGroupsState, getGroupsReducer.hasLDAPData);
exports.isGetGroupsLoadingError = store_1.createSelector(getSupervisionGetGroupsState, getGroupsReducer.hasGenericError);
exports.isLDAPGroupReady = store_1.createSelector(getSupervisionGetGroupsState, getGroupsReducer.isLDAPReady);
exports.isCloudGroupReady = store_1.createSelector(getSupervisionGetGroupsState, getGroupsReducer.isCloudReady);
