'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./mimecast-central-service"); //mimecast.central.service
angular.module('navbar.search.service', ['mimecast.central.service'])
    .factory('navbarSearchService', ['$http', '$q', 'mimecastCentralService', 'accountService', function ($http, $q, mimecastCentralService, accountService) {
        return {
            search(query) {
                return mimecastCentralService.searchContent(query);
            },
            getMimecasterCentralSourceString() {
                return '?sr=adcon&searchId=' + getGuId() + '&searchIndex=';
            },
            getData() {
                return accountService.getAccount().then(function (response) {
                    const data = response.first;
                    return data;
                });
            }
        };
        function getGuId() {
            function s4() {
                return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
            }
            return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
        }
    }]);
