"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DKIMDetailsDisplayPipe = void 0;
class DKIMDetailsDisplayPipe {
    constructor(translateService) {
        this.translateService = translateService;
    }
    transform(dkimInfo = '') {
        if (!dkimInfo.toLowerCase().startsWith('dkim_')) {
            dkimInfo = 'dkim_' + dkimInfo;
        }
        switch (dkimInfo.toLowerCase()) {
            case 'dkim_unrecognized':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_DKIM.UNRECOGNIZED');
            case 'dkim_error':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_DKIM.DKIM_ERROR');
            case 'dkim_unknown':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_DKIM.DKIM_UNKNOWN');
            case 'dkim_allow':
                return `<span class="positiveProperty">${this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_DKIM.DKIM_ALLOW')}</span>`;
            case 'dkim_reject':
                return `<span class="negativeProperty">${this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_DKIM.DKIM_REJECT')}</span>`;
            case 'dkim_temp_fail':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_DKIM.DKIM_TEMP_FAIL');
            case 'dkim_na':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_DKIM.DKIM_NA');
            case 'dkim_permfail':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_DKIM.DKIM_PERMFAIL');
            case 'dkim_perm_fail':
                return this.translateService.instant('$I18N_MESSAGE_CENTER_THREAT_INFO_DKIM.DKIM_PERM_FAIL');
            default:
                return '';
        }
    }
}
exports.DKIMDetailsDisplayPipe = DKIMDetailsDisplayPipe;
