"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GatewayWizardComponent = void 0;
const core_1 = require("@angular/core");
const actions = require("../../../../actions/api-gateway");
class GatewayWizardComponent {
    constructor(translateService, store, featureFlagService) {
        this.translateService = translateService;
        this.store = store;
        this.featureFlagService = featureFlagService;
        this.onApplicationAcceptTermsUpdated = new core_1.EventEmitter();
        this.onApplicationDetailsUpdated = new core_1.EventEmitter();
        this.onApplicationSettingsUpdated = new core_1.EventEmitter();
        this.onApplicationStatusUpdated = new core_1.EventEmitter();
        this.onApplicationSaved = new core_1.EventEmitter();
        this.onCancel = new core_1.EventEmitter();
        this.hasNextStep = true;
        this.hasPreviousStep = false;
        this.isLastStep = false;
        this.generateNewKeys = false;
        this.changed = false;
        this.DISCLAIMER_STEP = 1;
        this.DETAILS_STEP = 2;
        this.SETTINGS_STEP = 3;
        this.SUMMARY_STEP = 4;
    }
    ngOnInit() {
        this.store.dispatch(new actions.GetGatewayApplicationRoleListAction());
        this.store.dispatch(new actions.GetGatewayApplicationProductListAction());
    }
    ngAfterViewInit() {
        if (this.isCreating && !!this.wizard) {
            this.wizard.hasNextStep$.subscribe(value => (this.hasNextStep = value));
            this.wizard.hasPreviousStep$.subscribe(value => (this.hasPreviousStep = value));
            this.wizard.isLastStep$.subscribe(value => (this.isLastStep = value));
        }
        this.featureFlagService.isReady().then(() => {
            this.generateNewKeys = this.featureFlagService.getBooleanFlag('Adcon.Services.Applications.2.0.client.secret.Regeneration');
        });
    }
    updateAcceptTerms(acceptTerms) {
        this.changed = true;
        this.onApplicationAcceptTermsUpdated.emit(acceptTerms);
    }
    updateDetails(details) {
        this.changed = true;
        this.onApplicationDetailsUpdated.emit(details);
    }
    updateSettings(settings) {
        this.changed = true;
        this.onApplicationSettingsUpdated.emit(settings);
    }
    updateStatus(status) {
        this.changed = true;
        this.onApplicationStatusUpdated.emit(status);
    }
    save() {
        if (this.isValid() && this.changed) {
            this.onApplicationSaved.emit(this.application);
        }
    }
    cancel() {
        this.onCancel.emit();
    }
    changeStep() {
        // you have to listen to this event or the underlying wizard doesn't work :(
    }
    gotoStep(step) {
        if (!!this.wizard) {
            this.wizard.showStep(step);
        }
    }
    gotoPreviousStep() {
        if (!!this.wizard) {
            this.wizard.previousStep();
        }
    }
    gotoNextStep() {
        if (!!this.wizard) {
            this.wizard.nextStep();
        }
    }
    get isEditing() {
        return this.mode === 'EDIT';
    }
    get isCreating() {
        return this.mode === 'CREATE';
    }
    get pageTitle() {
        if (this.isEditing) {
            return (this.translateService.instant('$I18N_API_GATEWAY_V3_WIZARD.EDIT.TITLE') +
                this.applicationDetails.applicationName);
        }
        return this.translateService.instant('$I18N_API_GATEWAY_V3_WIZARD.CREATE.TITLE');
    }
    get buttonText() {
        if (this.isCreating && this.generateNewKeys) {
            return this.translateService.instant('$I18N_API_GATEWAY_V3_WIZARD.ACTIONS.ADD_AND_GENERATE');
        }
        return this.translateService.instant('$I18N_API_GATEWAY_V3_WIZARD.ACTIONS.ADD');
    }
    isStepValid() {
        if (!this.wizard) {
            return false;
        }
        switch (this.wizard.currentStep) {
            case this.DISCLAIMER_STEP:
                return this.applicationDisclaimerStep.isValid();
            case this.DETAILS_STEP:
                return this.applicationDetailsStep.isValid();
            case this.SETTINGS_STEP:
                return this.applicationSettingsStep.isValid();
            default:
                return true;
        }
    }
    isValid() {
        if (!!this.applicationDisclaimerStep &&
            !!this.applicationDetailsStep &&
            !!this.applicationSettingsStep) {
            return (this.applicationDisclaimerStep.isValid() &&
                this.applicationDetailsStep.isValid() &&
                this.applicationSettingsStep.isValid());
        }
        return false;
    }
}
exports.GatewayWizardComponent = GatewayWizardComponent;
