"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgThreatEventTypeComponent = void 0;
const models_1 = require("../../../../models/models");
class SwgThreatEventTypeComponent {
    constructor() {
        this.showIcon = true;
    }
    hasMalware() {
        return this.types.indexOf(models_1.ThreatEventType.MALWARE) !== -1;
    }
    hasPhishing() {
        return this.types.indexOf(models_1.ThreatEventType.PHISHING) !== -1;
    }
}
exports.SwgThreatEventTypeComponent = SwgThreatEventTypeComponent;
