"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.urlFileDownloadValues = exports.senderMailboxFallbackActionValues = exports.senderMailboxActionValues = exports.gatewayFallbackActionValues = exports.gatewayActionValues = exports.urlCategoryScanningModeValues = exports.urlScanningModeValues = exports.formControlsToUpdateOnCheckForSimilarUrls = exports.formControlsToUpdateOnScanUrlsInAttachments = exports.formControlNames = void 0;
const url_protection_outbound_settings_step_models_1 = require("./url-protection-outbound-settings-step.models");
exports.formControlNames = {
    SCAN_URLS_IN_ATTACHMENTS: 'scanUrlInAttachments',
    ATTACHMENT_PART_HTML: 'attachmentPartHTML',
    ATTACHMENT_PART_TEXT: 'attachmentPartText',
    ATTACHMENT_PART_CALENDAR: 'attachmentPartCalendar',
    ATTACHMENT_PART_OTHER: 'attachmentPartOther',
    CHECK_FOR_SIMILAR_URLS: 'checkForSimilarUrls',
    INTERNAL_DOMAINS: 'domainToCheckAgainstYourInternalDomains',
    MIMECAST_MONITORING_EXTERNAL_DOMAIN: 'domainToCheckAgainstMimecastMonitoredExternalDomains',
    CUSTOM_MONITORING_EXTERNAL_DOMAIN: 'domainToCheckAgainstCustomMonitoredExternalDomains'
};
exports.formControlsToUpdateOnScanUrlsInAttachments = [
    exports.formControlNames.ATTACHMENT_PART_HTML,
    exports.formControlNames.ATTACHMENT_PART_TEXT,
    exports.formControlNames.ATTACHMENT_PART_CALENDAR,
    exports.formControlNames.ATTACHMENT_PART_OTHER
];
exports.formControlsToUpdateOnCheckForSimilarUrls = [
    exports.formControlNames.INTERNAL_DOMAINS,
    exports.formControlNames.MIMECAST_MONITORING_EXTERNAL_DOMAIN,
    exports.formControlNames.CUSTOM_MONITORING_EXTERNAL_DOMAIN
];
exports.urlScanningModeValues = [
    {
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.URL_SCANNING_MODE.VALUES.AGGRESSIVE',
        value: url_protection_outbound_settings_step_models_1.UrlScanningModeOptions.AGGRESSIVE
    },
    {
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.URL_SCANNING_MODE.VALUES.MODERATE',
        value: url_protection_outbound_settings_step_models_1.UrlScanningModeOptions.MODERATE
    },
    {
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.URL_SCANNING_MODE.VALUES.RELAXED',
        value: url_protection_outbound_settings_step_models_1.UrlScanningModeOptions.RELAXED
    }
];
exports.urlCategoryScanningModeValues = [
    {
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.URL_CATEGORY_SCANNING_MODE.VALUES.AGGRESSIVE',
        value: url_protection_outbound_settings_step_models_1.UrlCategoryScanningModeOptions.AGGRESSIVE
    },
    {
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.URL_CATEGORY_SCANNING_MODE.VALUES.MODERATE',
        value: url_protection_outbound_settings_step_models_1.UrlCategoryScanningModeOptions.MODERATE
    },
    {
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.URL_CATEGORY_SCANNING_MODE.VALUES.RELAXED',
        value: url_protection_outbound_settings_step_models_1.UrlCategoryScanningModeOptions.RELAXED
    }
];
exports.gatewayActionValues = [
    {
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.GATEWAY_ACTION.VALUES.BOUNCE',
        value: url_protection_outbound_settings_step_models_1.GatewayActionOptions.BOUNCE
    },
    {
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.GATEWAY_ACTION.VALUES.HOLD',
        value: url_protection_outbound_settings_step_models_1.GatewayActionOptions.HOLD
    },
    {
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.GATEWAY_ACTION.VALUES.NO_ACTION',
        value: url_protection_outbound_settings_step_models_1.GatewayActionOptions.NO_ACTION
    }
];
exports.gatewayFallbackActionValues = [
    {
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.GATEWAY_FALLBACK_ACTION.VALUES.BOUNCE',
        value: url_protection_outbound_settings_step_models_1.GatewayFallbackActionOptions.BOUNCE
    },
    {
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.GATEWAY_FALLBACK_ACTION.VALUES.HOLD',
        value: url_protection_outbound_settings_step_models_1.GatewayFallbackActionOptions.HOLD
    },
    {
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.GATEWAY_FALLBACK_ACTION.VALUES.NO_ACTION',
        value: url_protection_outbound_settings_step_models_1.GatewayFallbackActionOptions.NO_ACTION
    }
];
exports.senderMailboxActionValues = [
    {
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.SENDER_MAILBOX_ACTION.VALUES.REMOVE',
        value: url_protection_outbound_settings_step_models_1.SenderMailboxActionOptions.REMOVE
    },
    {
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.SENDER_MAILBOX_ACTION.VALUES.NO_ACTION',
        value: url_protection_outbound_settings_step_models_1.SenderMailboxActionOptions.NO_ACTION
    }
];
exports.senderMailboxFallbackActionValues = [
    {
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.SENDER_MAILBOX_FALLBACK_ACTION.VALUES.REMOVE',
        value: url_protection_outbound_settings_step_models_1.SenderMailboxFallbackActionOptions.REMOVE
    },
    {
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.SENDER_MAILBOX_FALLBACK_ACTION.VALUES.NO_ACTION',
        value: url_protection_outbound_settings_step_models_1.SenderMailboxFallbackActionOptions.NO_ACTION
    }
];
exports.urlFileDownloadValues = [
    {
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.URL_FILE_DOWNLOAD.VALUES.CHECK',
        value: url_protection_outbound_settings_step_models_1.UrlFileDownloadOptions.CHECK
    },
    {
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.OUTBOUND_SETTINGS.LABELS.URL_FILE_DOWNLOAD.VALUES.NO_ACTION',
        value: url_protection_outbound_settings_step_models_1.UrlFileDownloadOptions.NO_ACTION
    }
];
