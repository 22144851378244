"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.defaultDateRangeConfig = exports.attemptCounts = exports.attemptOperators = void 0;
const moment = require("moment");
exports.attemptOperators = [
    {
        label: '$I18N_MESSAGE_CENTER_PURGE_MESSAGES_FORM.ATTEMPT_OPERATORS.GREATER_OR_EQUAL',
        value: '>='
    },
    {
        label: '$I18N_MESSAGE_CENTER_PURGE_MESSAGES_FORM.ATTEMPT_OPERATORS.EQUAL',
        value: '='
    },
    {
        label: '$I18N_MESSAGE_CENTER_PURGE_MESSAGES_FORM.ATTEMPT_OPERATORS.LOWER_OR_EQUAL',
        value: '<='
    }
];
exports.attemptCounts = Array.from(new Array(30), (val, index) => ({
    label: (index + 1).toLocaleString(undefined, { minimumIntegerDigits: 2 }),
    value: index + 1
}));
const ɵ0 = () => getRange(1);
exports.ɵ0 = ɵ0;
exports.defaultDateRangeConfig = {
    allowCustomRange: true,
    dateRanges: [
        {
            id: 'PAST_24_HOURS',
            label: '$I18N_DATE_RANGE_CONFIG_LABEL_PAST_24_HOURS',
            rangeFn: ɵ0,
            range: getRange(1)
        },
        {
            id: 'PAST_48_HOURS',
            label: '$I18N_DATE_RANGE_CONFIG_LABEL_PAST_48_HOURS',
            rangeFn: () => getRange(2),
            range: getRange(2)
        },
        {
            id: 'PAST_7_DAYS',
            label: '$I18N_DATE_RANGE_CONFIG_LABEL_PAST_7_DAYS',
            rangeFn: () => getRange(7),
            range: getRange(7)
        },
        {
            id: 'CUSTOM',
            label: '$I18N_DATE_RANGE_CONFIG_LABEL_CUSTOM_RANGE'
        }
    ],
    initialDateRange: 1
};
function getRange(days, shift = false) {
    return {
        start: moment()
            .startOf('day')
            .subtract(shift ? days * 2 : days, 'days'),
        end: moment().subtract(shift ? days : 0, 'days')
    };
}
