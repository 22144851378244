"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckUrlsEffects = void 0;
const effects_1 = require("@ngrx/effects");
const actions = require("../../actions/check-urls.actions");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const lodash_1 = require("lodash");
class CheckUrlsEffects {
    constructor(actions$, service) {
        this.actions$ = actions$;
        this.service = service;
        this.checkUrl$ = this.actions$.pipe(effects_1.ofType(actions.CHECK_URL_ATTEMPT), operators_1.switchMap(action => {
            return this.service.checkUrl(action['payload']).pipe(operators_1.map(response => {
                const { first, fail } = response;
                if (lodash_1.isArray(fail) && fail.length) {
                    return new actions.CheckUrlFailAction();
                }
                return new actions.CheckUrlSuccessAction(first);
            }), operators_1.catchError(() => {
                return rxjs_1.of(new actions.CheckUrlFailAction());
            }));
        }));
    }
}
__decorate([
    effects_1.Effect()
], CheckUrlsEffects.prototype, "checkUrl$", void 0);
exports.CheckUrlsEffects = CheckUrlsEffects;
