<div *ngIf="locations.length > 0" class="panel-padding-bottom">
  <div class="mc-affected-targets-title">
    {{ '$I18N_SWG_CREATE_POLICY_POLICY_LOCATIONS' | translate }}
  </div>
  <div *ngFor="let target of locations">
    <div data-test="applies_to_target">
      {{ target.description }}&nbsp;({{ target.ipRange }})
    </div>
  </div>
</div>
<div *ngIf="groups.length > 0" class="panel-padding-bottom">
  <div class="mc-affected-targets-title">
    {{ '$I18N_SWG_CREATE_POLICY_POLICY_GROUPS' | translate }}
  </div>
  <div *ngFor="let target of groups">
    <div data-test="applies_to_target">
      {{ target.description }}
    </div>
  </div>
</div>
<div *ngIf="channels.length > 0" class="panel-padding-bottom">
  <div class="mc-affected-targets-title">
    {{ '$I18N_SWG_CREATE_POLICY_POLICY_CHANNELS' | translate }}
  </div>
  <div *ngFor="let target of channels">
    <div data-test="applies_to_target">
      {{ target.channelDisplayName }}
    </div>
  </div>
</div>
<div *ngIf="users.length > 0" class="panel-padding-bottom">
  <div class="mc-affected-targets-title">
    {{ '$I18N_SWG_CREATE_POLICY_POLICY_USERS' | translate }}
  </div>
  <div *ngFor="let target of users">
    <div data-test="applies_to_target">
      {{ target.emailAddress }}
    </div>
  </div>
</div>
<div *ngIf="devices.length > 0" class="panel-padding-bottom">
  <div class="mc-affected-targets-title">
    {{ '$I18N_DEVICE_GROUPS_LIST.SIDEBAR.DEVICES_HEADER' | translate }}
  </div>
  <div *ngFor="let target of devices" class="panel-padding-top">
    <div data-test="applies_to_target">
      {{ target.description }}
    </div>
  </div>
</div>
<div *ngIf="deviceGroups.length > 0" class="panel-padding-bottom">
  <div class="mc-affected-targets-title">
    {{ '$I18N_DEVICE_GROUPS_LIST.SIDEBAR.HEADER' | translate }}
  </div>
  <div *ngFor="let target of deviceGroups">
    <div data-test="applies_to_target" class="panel-padding-top">
      {{ target.description }}
    </div>
  </div>
</div>
