"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTranslationKey = exports.StatusFactory = exports.STATUS_TYPES = void 0;
const status_service_1 = require("app-new/sync-recover/services/status.service");
exports.STATUS_TYPES = {
    created: 'created',
    in_progress: 'in_progress',
    completed: 'completed',
    error: 'error',
    success: 'success',
    warning: 'warning',
    pending: 'pending',
    completed_with_errors: 'completed_with_errors'
};
exports.StatusFactory = {
    [exports.STATUS_TYPES.created]: (overrideOptions) => new status_service_1.Status(exports.STATUS_TYPES.created, (overrideOptions && overrideOptions.displayClasses) || 'text-gray-40', (overrideOptions && overrideOptions.tooltip) || '', (overrideOptions && overrideOptions.displayValues) || getTranslationKey(exports.STATUS_TYPES.created), (overrideOptions && overrideOptions.icon) || ''),
    [exports.STATUS_TYPES.in_progress]: (overrideOptions) => new status_service_1.Status(exports.STATUS_TYPES.in_progress, (overrideOptions && overrideOptions.displayClasses) || 'text-gray-40', (overrideOptions && overrideOptions.tooltip) || '', (overrideOptions && overrideOptions.displayValues) ||
        getTranslationKey(exports.STATUS_TYPES.in_progress), (overrideOptions && overrideOptions.icon) || ''),
    [exports.STATUS_TYPES.completed]: (overrideOptions) => {
        return new status_service_1.Status(exports.STATUS_TYPES.completed, (overrideOptions && overrideOptions.displayClasses) || 'text-success', (overrideOptions && overrideOptions.tooltip) || '', (overrideOptions && overrideOptions.displayValues) ||
            getTranslationKey(exports.STATUS_TYPES.completed), (overrideOptions && overrideOptions.icon) || '');
    },
    [exports.STATUS_TYPES.success]: (overrideOptions) => {
        return new status_service_1.Status(exports.STATUS_TYPES.success, (overrideOptions && overrideOptions.displayClasses) || 'text-success', (overrideOptions && overrideOptions.tooltip) || '', (overrideOptions && overrideOptions.displayValues) || getTranslationKey(exports.STATUS_TYPES.success), (overrideOptions && overrideOptions.icon) || '');
    },
    [exports.STATUS_TYPES.error]: (overrideOptions) => {
        return new status_service_1.Status(exports.STATUS_TYPES.error, (overrideOptions && overrideOptions.displayClasses) || 'text-danger', (overrideOptions && overrideOptions.tooltip) || '', (overrideOptions && overrideOptions.displayValues) || getTranslationKey(exports.STATUS_TYPES.error), (overrideOptions && overrideOptions.icon) || '', (overrideOptions && overrideOptions.statusMessage) || '');
    },
    [exports.STATUS_TYPES.warning]: (overrideOptions) => {
        return new status_service_1.Status(exports.STATUS_TYPES.warning, (overrideOptions && overrideOptions.displayClasses) || 'text-warning', (overrideOptions && overrideOptions.tooltip) || '', (overrideOptions && overrideOptions.displayValues) || getTranslationKey(exports.STATUS_TYPES.warning), (overrideOptions && overrideOptions.icon) || '');
    },
    [exports.STATUS_TYPES.pending]: (overrideOptions) => {
        return new status_service_1.Status(exports.STATUS_TYPES.pending, (overrideOptions && overrideOptions.displayClasses) || 'text-gray-40', (overrideOptions && overrideOptions.tooltip) || '', (overrideOptions && overrideOptions.displayValues) || getTranslationKey(exports.STATUS_TYPES.pending), (overrideOptions && overrideOptions.icon) || '');
    },
    [exports.STATUS_TYPES.completed_with_errors]: (overrideOptions) => {
        return new status_service_1.Status(exports.STATUS_TYPES.completed_with_errors, (overrideOptions && overrideOptions.displayClasses) || 'text-warning', (overrideOptions && overrideOptions.tooltip) || '', (overrideOptions && overrideOptions.displayValues) ||
            getTranslationKey(exports.STATUS_TYPES.completed_with_errors), (overrideOptions && overrideOptions.icon) || '');
    }
};
function getTranslationKey(status) {
    return ['$I18N_SYNC_RECOVER_STATUS', status && status.toUpperCase()].join('.');
}
exports.getTranslationKey = getTranslationKey;
