"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiGatewayKeyGenAccountStepComponent = void 0;
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const operators_1 = require("rxjs/operators");
class ApiGatewayKeyGenAccountStepComponent {
    constructor(fb) {
        this.fb = fb;
        this.onAccountUpdated = new core_1.EventEmitter();
        this.onSubmitted = new core_1.EventEmitter();
        this.form = this.fb.group({
            emailAddress: ['', [forms_1.Validators.required, forms_1.Validators.email, forms_1.Validators.maxLength(128)]]
        });
    }
    ngOnInit() {
        this.form.patchValue(this.account || {});
        this.formSubscription = this.form.valueChanges.pipe(operators_1.debounceTime(200)).subscribe(() => {
            this.update();
        });
    }
    submit() {
        if (this.isValid()) {
            this.onSubmitted.emit(true);
        }
    }
    update() {
        this.onAccountUpdated.emit(this.form.value);
    }
    isValid() {
        return this.form.status === 'VALID';
    }
    ngOnDestroy() {
        this.formSubscription.unsubscribe();
    }
}
exports.ApiGatewayKeyGenAccountStepComponent = ApiGatewayKeyGenAccountStepComponent;
