<div>
  <mc-collapsable-panel header="$I18N_MESSAGE_CENTER_MESSAGE_INFO_ENVELOPE_DETAILS.TITLE" [isExpanded]="true">
    <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_ENVELOPE_DETAILS.LABEL_FROM_ENVELOPE"
                    [value]="recipientInfo?.messageInfo?.fromEnvelope"
                    [showSecondValue]="true"
                    [secondValue]="selectedDeliveredMessage?.messageInfo?.fromEnvelope">
    </mc-value-field>
    <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_ENVELOPE_DETAILS.LABEL_FROM_HEADER"
                    [value]="recipientInfo?.messageInfo?.fromHeader"
                    [showSecondValue]="true"
                    [secondValue]="selectedDeliveredMessage?.messageInfo?.fromHeader">
    </mc-value-field>
    <mc-emails-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_ENVELOPE_DETAILS.LABEL_FROM_TO"
                     [emails]="recipientInfo?.messageInfo?.to"
                     [secondEmails]="selectedDeliveredMessage?.messageInfo?.to">
    </mc-emails-field>

    <div *ngIf="isDataSetOne; else archivedEnvelopeDetails">
      <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_ENVELOPE_DETAILS.LABEL_TRANSMISSION_EVENT"
                      [value]="recipientInfo?.txInfo?.txEvent"
                      [showSecondValue]="true"
                      [secondValue]="selectedDeliveredMessage?.txInfo?.txEvent">
      </mc-value-field>
      <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_ENVELOPE_DETAILS.LABEL_QUEUE_DETAIL_STATUS"
                      [value]="recipientInfo?.txInfo?.queueDetailStatus"
                      [showSecondValue]="true"
                      [secondValue]="selectedDeliveredMessage?.txInfo?.queueDetailStatus">
      </mc-value-field>
      <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_ENVELOPE_DETAILS.LABEL_PROCESSING_SERVER"
                      [value]="recipientInfo?.txInfo?.processingServer"
                      [showSecondValue]="true"
                      [secondValue]="selectedDeliveredMessage?.txInfo?.processingServer">
      </mc-value-field>

      <mc-value-field *ngIf="messageStatus !== 'accepted'; else acceptedRouteData"
                      label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_ENVELOPE_DETAILS.LABEL_MESSAGE_ROUTE"
                      [value]="queueInfo.route | mcFirstLetterUppercase"
                      [showSecondValue]="true"
                      [secondValue]="queueInfo.route | mcFirstLetterUppercase">
      </mc-value-field>
      <ng-template #acceptedRouteData>
        <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_ENVELOPE_DETAILS.LABEL_MESSAGE_ROUTE"
                        [value]="recipientInfo?.messageInfo?.route | mcNoDataAvailableDisplay | mcFirstLetterUppercase"
                        [showSecondValue]="true"
                        [secondValue]="selectedDeliveredMessage?.messageInfo?.route | mcNoDataAvailableDisplay | mcFirstLetterUppercase">
        </mc-value-field>
      </ng-template>
    </div>
    <ng-template #archivedEnvelopeDetails>
      <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_ENVELOPE_DETAILS.LABEL_TRANSMISSION_EVENT"
                      [value]="recipientInfo?.recipientMetaInfo?.receiptEvent"
                      [showSecondValue]="true"
                      [secondValue]="selectedDeliveredMessage?.deliveryMetaInfo?.deliveryEvent">
      </mc-value-field>
      <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_ENVELOPE_DETAILS.LABEL_PROCESSING_SERVER"
                      [value]="recipientInfo?.recipientMetaInfo?.processingServer"
                      [showSecondValue]="true"
                      [secondValue]="selectedDeliveredMessage?.deliveryMetaInfo?.processingServer">
      </mc-value-field>
      <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_ENVELOPE_DETAILS.LABEL_MESSAGE_ROUTE"
                      [value]="selectedDeliveredMessage?.messageInfo?.route | mcNoDataAvailableDisplay | mcFirstLetterUppercase"
                      [showSecondValue]="true"
                      [secondValue]="selectedDeliveredMessage?.messageInfo?.route | mcNoDataAvailableDisplay | mcFirstLetterUppercase">
      </mc-value-field>
    </ng-template>
  </mc-collapsable-panel>
</div>
