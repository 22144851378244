<mc-layout-list-extra-container keyTitle="My Title"
                                keyDescription="$I18N_SYNC_RECOVER_TASK_DETAILS_DESCRIPTION">
  <mc-extra-container>

  </mc-extra-container>
  <mc-body-container>
    <mc-table [data]="tableData$ | async" [columns]="columnList">

      <button class="mc-table-actions" translate="$I18N_SYNC_RECOVER_TEXT_EDIT"></button>
      <button class="mc-table-actions" translate="$I18N_SYNC_RECOVER_TEXT_DELETE"></button>
      <button class="mc-table-actions" translate="$I18N_SYNC_RECOVER_TEXT_ENABLED"></button>

      <mc-filters [metadata]="metaData$ | async"></mc-filters>
      <mc-column *ngFor="let key of columnList" [key]="key">
        <mc-body-cell *mcBodyCellDef="let row" (click)="openItem(row)">{{ row[key] }}</mc-body-cell>
      </mc-column>

    </mc-table>
  </mc-body-container>
</mc-layout-list-extra-container>
