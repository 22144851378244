<mc-siem-channels-table
  [hasEditPermission]="hasEditPermission$ | async"
  [hasDeletePermission]="hasDeletePermission$ | async"
  [metadataProducts]="metadataProducts$ | async"
  (rowClick)="onViewChannel($event)"
  (createNewChannel)="onCreateNewChannel()"
  (editChannel)="onEditChannel($event)"
  (deleteChannel)="onDeleteChannel($event)"
>

</mc-siem-channels-table>
