"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgPolicyEditSideBarComponent = void 0;
const core_1 = require("@angular/core");
const policy_1 = require("app-new/swg/policies/models/policy");
class SwgPolicyEditSideBarComponent {
    constructor(translateService, fb) {
        this.translateService = translateService;
        this.fb = fb;
        this.deletePolicy = new core_1.EventEmitter();
        this.editPolicy = new core_1.EventEmitter();
        this.duplicatePolicy = new core_1.EventEmitter();
        this.close = new core_1.EventEmitter();
        this.onShowErrorNotification = new core_1.EventEmitter();
        this.onDisablePolicy = new core_1.EventEmitter();
        this.isCLPPolicyWithoutConnector = false;
        this.clpPolicyErrorMessage = this.translateService.instant('$I18N_SWG_CLP_POLICY_NO_CONNECTOR_ERROR');
        this.swgPolicyType = policy_1.SwgPolicyType;
    }
    ngOnInit() {
        this.enabledForm = this.fb.group({
            isEnabled: this.policy && this.policy.policyDesc ? this.policy.policyDesc.enabled : false
        });
        this.enabledFormSubscription = this.enabledForm.valueChanges.subscribe(data => {
            this.onDisablePolicy.emit(Object.assign(Object.assign({}, this.policy.policyDesc), { enabled: !data.isEnabled }));
        });
    }
    showDuplicateOption() {
        if (this.policy.policyDesc.policyType === policy_1.SwgPolicyType.BROWSER_ISOLATION) {
            return false;
        }
        else if (this.policy.policyDesc.policyType === policy_1.SwgPolicyType.CLOUD_PROTECT_POLICY &&
            this.policy.clpPolicySettings) {
            return (this.policy.clpPolicySettings.scope.type !== policy_1.SwgClpPolicyScopeType.SPECIFIC_CLOUD_SERVICE);
        }
        return true;
    }
    canDisablePolicy(polictType) {
        return ([policy_1.SwgPolicyType.BROWSER_ISOLATION, policy_1.SwgPolicyType.CLOUD_PROTECT_POLICY].indexOf(polictType) ===
            -1);
    }
    showDeleteOption() {
        if (this.policy.policyDesc.policyType === policy_1.SwgPolicyType.BROWSER_ISOLATION) {
            return this.hasBiPolicyEditPermission && !this.policy.biSettings.defaultPolicy;
        }
        else if (this.policy.policyDesc.policyType === policy_1.SwgPolicyType.CLOUD_PROTECT_POLICY &&
            this.policy.clpPolicySettings) {
            return (this.policy.clpPolicySettings.scope.type !== policy_1.SwgClpPolicyScopeType.SPECIFIC_CLOUD_SERVICE &&
                this.hasEditPermission);
        }
        else {
            return this.hasEditPermission;
        }
    }
    emitShowErrorEvent() {
        this.onShowErrorNotification.emit(this.clpPolicyErrorMessage);
    }
    onEditClick() {
        if (this.policy.clpPolicyWithoutConnector) {
            this.emitShowErrorEvent();
        }
        else {
            this.editPolicy.emit();
        }
    }
    onDuplicateClick() {
        if (this.policy.clpPolicyWithoutConnector) {
            this.emitShowErrorEvent();
        }
        else {
            this.duplicatePolicy.emit();
        }
    }
    onDeleteClick() {
        this.deletePolicy.emit(this.policy);
    }
}
exports.SwgPolicyEditSideBarComponent = SwgPolicyEditSideBarComponent;
