"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConsentStepComponent = void 0;
const forms_1 = require("@angular/forms");
const model_1 = require("../../../../../model");
const connector_wizard_service_1 = require("../../../../../services/connector-wizard.service");
const step_base_component_1 = require("../../../step.base.component");
class ConsentStepComponent extends step_base_component_1.StepBaseDirective {
    constructor(fb, store) {
        super(store, fb, model_1.ConnectorStep.CONSENT, connector_wizard_service_1.ConnectorWizardService.WizardId);
        this.fb = fb;
        this.store = store;
        this.form = this.fb.group({
            authToken: [null, forms_1.Validators.required]
        });
    }
    update(token) {
        if (token) {
            this.form.controls['authToken'].patchValue(token);
        }
    }
}
exports.ConsentStepComponent = ConsentStepComponent;
