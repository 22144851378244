'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./mega-menu-group-directive"); //mega.menu.group.directive
angular.module('mega.menu.item.directive', ['mega.menu.group.directive'])
    .directive('mcMegaMenuItem', ['$translate', '$q', 'capabilitiesService', '$state', '$timeout', '$rootScope', function ($translate, $q, capabilitiesService, $state, $timeout, $rootScope) {
        return {
            restrict: 'AE',
            require: ['^mcMegaMenu', '^mcMegaMenuGroup'],
            templateUrl: 'components/mega-menu/mega-menu-item.tpl.html',
            scope: {
                id: '@mcId',
                label: '@mcLabel',
                subLabel: '@mcSubLabel',
                description: '@mcDescription',
                link: '=mcLink',
                state: '=mcState',
                newTab: '=mcNewTab',
                caps: '@mcCaps',
                searchKeys: '@mcSearchKeys',
                earlyAccess: '@mcEarlyAccess',
                newFlag: '@mcNewFlag',
                guide: '@mcGuide',
                hideFavourite: '@mcHideFavourite',
                trial: '@mcTrial'
            },
            link($scope, $element, $attr, controllers) {
                const megaMenuCtrl = controllers[0];
                const megaMenuGroupCtrl = controllers[1];
                const menuItem = {
                    id: $scope.id,
                    label: $scope.label,
                    subLabel: $scope.subLabel,
                    description: $scope.description,
                    link: $scope.link,
                    newTab: $scope.newTab,
                    state: $scope.state,
                    caps: $scope.caps,
                    searchKeys: $scope.searchKeys,
                    earlyAccess: $scope.earlyAccess,
                    newFlag: $scope.newFlag,
                    guide: $scope.guide,
                    showFavourite: !$scope.hideFavourite,
                    trial: $scope.trial
                };
                if (capabilitiesService.areCapabilitiesLoaded()) {
                    if (angular.isDefined(menuItem.caps)) {
                        if (!capabilitiesService.evalCapabilitiesExpression(menuItem.caps)) {
                            $element.remove();
                        }
                        if (capabilitiesService.evalEarlyAccessCapabilitiesExpression(menuItem.caps) === true) {
                            $scope.earlyAccess = true;
                        }
                        if (capabilitiesService.evalNewCapabilitiesExpression(menuItem.caps) === true) {
                            $scope.newFlag = true;
                        }
                    }
                }
                else {
                    capabilitiesService.getCapabilities().then(function () {
                        if (angular.isDefined(menuItem.caps) && !capabilitiesService.evalCapabilitiesExpression(menuItem.caps)) {
                            $element.remove();
                        }
                    });
                }
                if (megaMenuCtrl.options) {
                    menuItem.onFavoriteChange = renderFavorite;
                }
                $translate.onReady(function () {
                    menuItem.label = $scope.label && $translate.instant($scope.label);
                    menuItem.subLabel = $scope.subLabel && $translate.instant($scope.subLabel);
                    menuItem.description = $scope.description && $translate.instant($scope.description);
                    menuItem.earlyAccess = $scope.earlyAccess;
                    menuItem.newFlag = $scope.newFlag;
                    if ($scope.searchKeys) {
                        menuItem.searchKeys = $translate.instant($scope.searchKeys);
                    }
                });
                $scope.menuItem = menuItem;
                $scope.onClick = onClick;
                $scope.has = onClick;
                $scope.getStateLink = getStateLink;
                $scope.clickAction = clickAction;
                $scope.favourite = favourite;
                $scope.cancelLatestSelect = cancelLatestSelect;
                let favoriteElement;
                const strClassIconActive = 'fas fa-star active';
                const strClassIconInactive = 'fa-star';
                const strClassIconWaiting = 'fa-circle-notch fa-spin';
                (function activate() {
                    megaMenuGroupCtrl.addMenuItem(menuItem);
                    favoriteElement = $element.find('.mc-favourite-action');
                    if (megaMenuCtrl.options.getFavouritesPromise) {
                        $q.when(megaMenuCtrl.options.getFavouritesPromise(), renderFavorite);
                    }
                    menuItem.stateLink = getStateLink();
                })();
                function renderFavorite() {
                    // http://api.jquery.com/toggleClass/
                    if (megaMenuCtrl.options.isFavourite(menuItem)) {
                        favoriteElement
                            .removeClass(strClassIconInactive)
                            .removeClass(strClassIconWaiting)
                            .addClass(strClassIconActive);
                    }
                    else {
                        favoriteElement
                            .removeClass(strClassIconActive)
                            .removeClass(strClassIconWaiting)
                            .addClass(strClassIconInactive);
                    }
                    if (!menuItem.showFavourite) {
                        favoriteElement
                            .removeClass(strClassIconActive)
                            .removeClass(strClassIconInactive);
                    }
                    $rootScope.$emit('megaMenuRender', {});
                }
                function clickAction($event) {
                    if (menuItem.newTab) {
                        window.open(menuItem.newTab, '_blank');
                    }
                    else if (menuItem.guide || menuItem.trial) {
                        onClick($event);
                    }
                    else if (!menuItem.state && menuItem.link) {
                        window.location = menuItem.link;
                    }
                    else if (!menuItem.state && !menuItem.link) {
                        onClick($event);
                    }
                    else if (menuItem.state) {
                        $state.go(menuItem.state.name, menuItem.state.params);
                    }
                }
                function cancelLatestSelect() {
                    if (angular.isDefined(megaMenuCtrl.menuTimeout)) {
                        $timeout.cancel(megaMenuCtrl.menuTimeout);
                    }
                }
                function favourite($event) {
                    if ($event) {
                        $event.preventDefault();
                        $event.stopPropagation();
                    }
                    favoriteElement
                        .addClass(strClassIconWaiting)
                        .removeClass(strClassIconActive)
                        .removeClass(strClassIconInactive);
                    $q.when(megaMenuCtrl.options.favourite(menuItem));
                }
                function onClick($event) {
                    megaMenuCtrl.onMenuItemClick($event, menuItem);
                }
                function getStateLink() {
                    if (menuItem.state) {
                        const state = menuItem.state;
                        return state && state.name && state.params && state.name + '(' + angular.toJson(state.params) + ')'
                            || state && state.name || state;
                    }
                }
            }
        };
    }]);
