"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteSingleDomain = exports.UpdateDomainFailure = exports.UpdateDomainSuccess = exports.UpdateDomain = exports.SelectedRow = exports.EditDomainActions = exports.DELETE_SINGLE_DOMAIN = exports.UPDATE_DOMAIN_FAILURE = exports.UPDATE_DOMAIN_SUCCESS = exports.UPDATE_DOMAIN = exports.SELECTED_ROW = exports.EDIT_DOMAIN = void 0;
exports.EDIT_DOMAIN = '[External Domain List] EDIT_DOMAIN';
exports.SELECTED_ROW = '[External Domain List] SELECTED_ROW';
exports.UPDATE_DOMAIN = '[External Domain List] UPDATE_DOMAIN';
exports.UPDATE_DOMAIN_SUCCESS = '[External Domain List] UPDATE_DOMAIN_SUCCESS';
exports.UPDATE_DOMAIN_FAILURE = '[External Domain List] UPDATE_DOMAIN_FAILURE';
exports.DELETE_SINGLE_DOMAIN = '[External Domain List] DELETE_SINGLE_DOMAIN';
// @ts-ignore
class EditDomainActions {
    constructor(payLoad) {
        this.payLoad = payLoad;
        this.type = exports.EDIT_DOMAIN;
    }
}
exports.EditDomainActions = EditDomainActions;
class SelectedRow {
    constructor(selectedRow) {
        this.selectedRow = selectedRow;
        this.type = exports.SELECTED_ROW;
    }
}
exports.SelectedRow = SelectedRow;
class UpdateDomain {
    constructor(updatedDomain) {
        this.updatedDomain = updatedDomain;
        this.type = exports.UPDATE_DOMAIN;
    }
}
exports.UpdateDomain = UpdateDomain;
class UpdateDomainSuccess {
    constructor() {
        this.type = exports.UPDATE_DOMAIN_SUCCESS;
    }
}
exports.UpdateDomainSuccess = UpdateDomainSuccess;
class UpdateDomainFailure {
    constructor() {
        this.type = exports.UPDATE_DOMAIN_FAILURE;
    }
}
exports.UpdateDomainFailure = UpdateDomainFailure;
class DeleteSingleDomain {
    constructor(domain) {
        this.domain = domain;
        this.type = exports.DELETE_SINGLE_DOMAIN;
    }
}
exports.DeleteSingleDomain = DeleteSingleDomain;
