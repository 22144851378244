"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PurgeMessagesSearchModalComponent = void 0;
class PurgeMessagesSearchModalComponent {
    constructor(overlayRef) {
        this.overlayRef = overlayRef;
    }
    cancel() {
        this.overlayRef.close('cancel');
    }
}
exports.PurgeMessagesSearchModalComponent = PurgeMessagesSearchModalComponent;
