<mc-archive-viewer
  [isLoading]="isLoading$ | async"
  (tokenSearch)="searchToken($event)"
  (contentSearch)="searchContent($event)">
</mc-archive-viewer>

<mc-download-xml
  [tokenResults]="tokenResults$ | async"
  (selectedPartialDownload)="selectedPartialDownload($event)"
  (selectedDownload)="selectedDownload($event)">
</mc-download-xml>

<!-- Table -->
<div *ngIf="listContent$ | async as result" data-test="results_table">

  <mc-layout-list-table showFavourite="false">
    <mc-table [data]="tableData$ | async"
              [columns]="columnList"
              translatePrefix="$I18N_ARCHIVE_VIEWER"
              (rowClick)="onRowClick($event)"
              [isLoading]="isLoading$ | async">

      <mc-empty-results iconClass="far fa-hdd"
                        keyTitle="$I18N_ARCHIVE_VIEWER_SEARCH.EMPTY_SEARCH.TITLE">
      </mc-empty-results>

      <mc-column-date key="receiveDate"></mc-column-date>

      <!-- pagination -->
      <mc-filters [pageSizeOptions]="[100,200,300,500]"
                  [defaultPageSize]="100"
                  [metadata]="metadata$ | async"
                  (paginatorChange)="onPaginatorChange($event)">
      </mc-filters>

      <mc-column-size key="size"></mc-column-size>

      <mc-column-actions key="right-column" mcShowColumnConfig>
        <mc-row-actions *mcRowActions="let row">
          <li mcRowAction="$I18N_ARCHIVE_VIEWER_DOWNLOAD_ALL" (click)="getMessageInfo('download_all', row.id)"></li>
        </mc-row-actions>
      </mc-column-actions>

    </mc-table>
  </mc-layout-list-table>
</div>
