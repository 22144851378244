"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoadFailAction = exports.LoadSuccessAction = exports.LoadAction = exports.LOAD_FAIL = exports.LOAD_SUCCESS = exports.LOAD = void 0;
exports.LOAD = '[Onedrive drive Usage] Load';
exports.LOAD_SUCCESS = '[Onedrive drive Usage] Load Success';
exports.LOAD_FAIL = '[Onedrive drive Usage] Load Fail';
/**
 * Load List Actions
 */
class LoadAction {
    constructor() {
        this.type = exports.LOAD;
    }
}
exports.LoadAction = LoadAction;
class LoadSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOAD_SUCCESS;
    }
}
exports.LoadSuccessAction = LoadSuccessAction;
class LoadFailAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.LOAD_FAIL;
    }
}
exports.LoadFailAction = LoadFailAction;
