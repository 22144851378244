"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportEffects = void 0;
const effects_1 = require("@ngrx/effects");
const operators_1 = require("rxjs/operators");
const api_1 = require("app-new/components/export/api");
const actions = require("../../actions");
const reducers = require("../../reducers");
class ExportEffects {
    constructor(actions$, store$, getAcceptedEmailService) {
        this.actions$ = actions$;
        this.store$ = store$;
        this.getAcceptedEmailService = getAcceptedEmailService;
        this.export$ = this.actions$.pipe(effects_1.ofType(actions.EXPORT), operators_1.withLatestFrom(this.store$.select(reducers.getAcceptedEmailRequestPayload)), operators_1.map(([action, getPayload]) => {
            const newPayload = this.getAcceptedEmailService.transformToServerRequest(getPayload);
            return new api_1.OpenSidePanelExportApiAction('/api/gateway/export-accepted-messages', action.payload, newPayload, '$I18N_MESSAGE_CENTER_ACCEPTED_EMAIL_MAIN_TABLE');
        }));
    }
}
__decorate([
    effects_1.Effect()
], ExportEffects.prototype, "export$", void 0);
exports.ExportEffects = ExportEffects;
