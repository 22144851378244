"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModalLoadingEffects = void 0;
const effects_1 = require("@ngrx/effects");
const operators_1 = require("rxjs/operators");
const action = require("../../actions");
const activity_wait_overlay_1 = require("../../../../components/activity-wait-overlay");
class ModalLoadingEffects {
    constructor(actions$, modalService) {
        this.actions$ = actions$;
        this.modalService = modalService;
        this.searchSources = [
            { key: '$I18N_MESSAGE_CENTER_ACTIVITY_WAIT_OVERLAY.MESSAGE_SOURCE.ACCEPTED_EMAIL' }
        ];
        this.modalLoadingOpen$ = this.actions$.pipe(effects_1.ofType(action.GET_ACCEPTED_EMAIL_REQUEST), operators_1.tap(() => {
            if (this.loadingModalInstance) {
                this.loadingModalInstance.close();
            }
            this.loadingModalInstance = this.modalService.open(activity_wait_overlay_1.ActivityWaitOverlayComponent, {
                disableClose: true,
                lightBackdrop: true,
                size: 'md',
                hasBackdrop: true,
                data: {
                    title: '$I18N_MESSAGE_CENTER_ACCEPTED_EMAIL_LOADING.TITLE',
                    description: '$I18N_MESSAGE_CENTER_ACCEPTED_EMAIL_LOADING.DESCRIPTION',
                    sources: this.searchSources
                }
            });
        }));
        this.modalLoadingClose$ = this.actions$.pipe(effects_1.ofType(action.GET_ACCEPTED_EMAIL_FAIL, action.GET_ACCEPTED_EMAIL_SUCCESS), operators_1.tap(() => {
            if (this.loadingModalInstance) {
                this.loadingModalInstance.close();
            }
            this.loadingModalInstance = null;
        }));
    }
}
__decorate([
    effects_1.Effect({ dispatch: false })
], ModalLoadingEffects.prototype, "modalLoadingOpen$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], ModalLoadingEffects.prototype, "modalLoadingClose$", void 0);
exports.ModalLoadingEffects = ModalLoadingEffects;
