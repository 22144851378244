"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNotificationAction = exports.getSelectedPolicy = exports.reducer = exports.initialState = void 0;
const policiesActions = require("../actions/policies.actions");
exports.initialState = {
    notificationAction: null,
    isLoading: false,
    selectedPolicy: null,
    deletedPolicy: null,
    apiProcessingDeleteInProgress: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case policiesActions.OPEN_POLICY:
            return Object.assign(Object.assign({}, state), { selectedPolicy: action.payload });
        case policiesActions.CLOSE_POLICY:
            return Object.assign(Object.assign({}, state), { selectedPolicy: null });
        case policiesActions.DELETE_CONFIRMATION_MODAL_OPEN:
            return Object.assign(Object.assign({}, state), { deletedPolicy: action.payload });
        case policiesActions.DELETE_POLICY:
            return Object.assign(Object.assign({}, state), { apiProcessingDeleteInProgress: true });
        case policiesActions.DELETE_POLICY_SUCCESS:
            return Object.assign(Object.assign({}, state), { apiProcessingDeleteInProgress: false });
        case policiesActions.DELETE_POLICY_FAILURE:
            return Object.assign(Object.assign({}, state), { apiProcessingDeleteInProgress: false });
        case policiesActions.SAVE_NOTIFICATION:
            return Object.assign(Object.assign({}, state), { notificationAction: action.payload });
        case policiesActions.CLEAR_NOTIFICATION:
            return Object.assign(Object.assign({}, state), { notificationAction: null });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getSelectedPolicy = (state) => state.selectedPolicy;
exports.getSelectedPolicy = getSelectedPolicy;
const getNotificationAction = (state) => state.notificationAction;
exports.getNotificationAction = getNotificationAction;
