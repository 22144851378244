<mc-modal-header
  headerTitle="{{ getHeaderTitle() | translate }}">
</mc-modal-header>

<mc-modal-body>
  <mc-inline-notification
    [notification]="{
          status: 'warning',
          boxed: true,
          msg: '$I18N_API_APPLICATIONS_OKTA_MODAL.NOTIFICATION' | translate
        }"
  >
  </mc-inline-notification>

  <div class="access-token-field">
    <input
      class="form-control token-input"
      [type]="inputType"
      name="accessTokenField"
      [value]="data.accessToken"
      aria-label="accessTokenField"
      readonly
    />
    <em (click)="toggleEye()" [class]="getEyeValue()"></em>
  </div>

  <button
    class="btn btn-default panel-half-margin-top"
    ngxClipboard
    [cbContent]="data.accessToken"
    (cbOnSuccess)="copyToClipboardSuccess()">
    <em class="fas fa-copy"></em>

    {{ '$I18N_API_APPLICATIONS_OKTA_MODAL.COPY_BUTTON' | translate }}
  </button>

  <p class="no-margin-bottom panel-half-margin-top" data-test="msg-line1">{{ getMessageLine1() | translate }}</p>
  <p class="no-margin" data-test="msg-line2">{{ getMessageLine2() | translate }}</p>
  <span>{{ '$I18N_API_APPLICATIONS_OKTA_MODAL.MESSAGE_LINE_03' | translate }}</span>
  <a
    [href]="oktaLink"
    target="_blank"
    rel="noopener noreferrer"
  >{{ '$I18N_API_APPLICATIONS_OKTA_MODAL.MESSAGE_LINE_03_LINK' | translate }} <em class="fas fa-external-link-alt"></em>.</a>
</mc-modal-body>

<mc-modal-footer>
  <button type="button" class="btn btn-primary" [disabled]="isDisabled()" (click)="close()">
    {{ '$I18N_API_APPLICATIONS_OKTA_MODAL.CONFIRM_BUTTON' | translate }}
  </button>
</mc-modal-footer>
