<mc-layout-detail-wizard
  keyTitle="{{ (getTitle()) | translate }}{{ isEditFlow() ? deviceGroup.name : ''}}"
  keyDescription=""
  extendClass="panel-padding-bottom"
>
  <mc-wizard (changeStep)="goToStep($event)" *ngIf="!isEditFlow()">
    <mc-wizard-step label="$I18N_DEVICE_GROUPS_LIST.WIZARD.STEPS.DETAILS.LABEL">
     <mc-device-group-details-step
       [deviceGroup]="deviceGroup"
       [isEditMode]="false"
       (updateDeviceGroupDescr)="updateDeviceGroupDescr.emit($event)">

     </mc-device-group-details-step>
    </mc-wizard-step>
    <mc-wizard-step label="$I18N_DEVICE_GROUPS_LIST.WIZARD.STEPS.ADD_DEVICES.LABEL">
      <mc-device-group-add-devices-step
        [devicesInGroup]="devicesInGroup"
        [devicesListManualPagingData]="devicesListManualPagingData"
        [isDeviceListTableLoading]="isDeviceListTableLoading"
        [deviceCount]="deviceCount"
        [displayType]="deviceListDisplayType.CREATE"
        (filterDevicesInGroup)="filterDevicesInGroup.emit($event)"
        (onPageChanged)="onPageChanged.emit($event)"
      ></mc-device-group-add-devices-step>
    </mc-wizard-step>
    <mc-wizard-step label="$I18N_DEVICE_GROUPS_LIST.WIZARD.STEPS.SUMMARY.LABEL">
      <mc-device-group-summary-step
      [deviceGroup]="deviceGroup"
      [deviceCount]="deviceCount"
      [isEditMode]="isEditFlow()">
      </mc-device-group-summary-step>
    </mc-wizard-step>
  </mc-wizard>

  <mc-tab-group *ngIf="isEditFlow()" [direction]="'vertical'">
    <mc-tab name="{{ '$I18N_DEVICE_GROUPS_LIST.WIZARD.STEPS.DETAILS.LABEL' | translate }}">
      <mc-spinner *ngIf="loadingForEdit"></mc-spinner>
      <mc-device-group-details-step
        *ngIf="!loadingForEdit"
        [deviceGroup]="deviceGroup"
        [isEditMode]="true"
        (updateDeviceGroupDescr)="updateDeviceGroupDescr.emit($event)">

      </mc-device-group-details-step>
    </mc-tab>
    <mc-tab name="{{ '$I18N_DEVICE_GROUPS_LIST.WIZARD.STEPS.ADD_DEVICES.EDIT_LABEL' | translate }}">
      <mc-device-group-add-devices-step
        *ngIf="!loadingForEdit"
        [deviceGroup]="deviceGroup"
        [devicesInGroup]="devicesInGroup"
        [devicesListManualPagingData]="devicesListManualPagingData"
        [isDeviceListTableLoading]="isDeviceListTableLoading"
        [deviceCount]="deviceCount"
        [displayType]="deviceListDisplayType.EDIT"
        (filterDevicesInGroup)="filterDevicesInGroup.emit($event)"
        (onPageChanged)="onPageChanged.emit($event)"
      ></mc-device-group-add-devices-step>
    </mc-tab>
    <mc-tab name="{{ '$I18N_DEVICE_GROUPS_LIST.WIZARD.STEPS.SUMMARY.LABEL' | translate }}">
      <mc-device-group-summary-step
        *ngIf="!loadingForEdit"
        [deviceGroup]="deviceGroup"
        [deviceCount]="deviceCount"
        [isEditMode]="isEditFlow()">
      </mc-device-group-summary-step>
    </mc-tab>
  </mc-tab-group>


  <mc-footer-container>
    <div class="pull-right no-select mc-button-footer">
      <button class="btn" (click)="cancel()">{{ '$I18N_COMMON_BTN_CANCEL' | translate }}</button>
      <ng-container *ngIf="wizard && !isEditFlow()">
        <button
          class="btn btn-secondary panel-half-margin-right"
          *ngIf="wizard.hasPreviousStep$ | async"
          (click)="wizard.previousStep()"
        >
          {{ '$I18N_COMMON_BTN_PREVIOUS' | translate }}
        </button>
        <button
          class="btn btn-primary"
          [disabled]="!isStepValid()"
          *ngIf="wizard.hasNextStep$ | async"
          (click)="wizard.nextStep()"
        >
          {{ '$I18N_COMMON_BTN_NEXT' | translate }}
        </button>
        <ng-container *ngIf="hasEditPermission">
          <mc-live-button
            *ngIf="wizard.isLastStep$ | async"
            overrideClasses="btn-primary"
            [label]="'$I18N_DEVICE_GROUPS_LIST.WIZARD.CREATE_GROUP_BUTTON' | translate"
            [isLoading]="apiIsProcessing"
            (mcClick)="createDeviceGroup()"
          ></mc-live-button>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="isEditFlow()">
        <ng-container *ngIf="hasEditPermission">
          <button
            *ngIf="!apiIsProcessing"
            class="btn btn-primary"
            [disabled]="!isValidDeviceGroup(deviceGroup)"
            (click)="createDeviceGroup()"
          >
            {{ '$I18N_DEVICE_GROUPS_LIST.WIZARD.SAVE_CLOSE_BUTTON' | translate }}
          </button>
          <mc-live-button
            *ngIf="apiIsProcessing"
            overrideClasses="btn-primary"
            [label]="'$I18N_DEVICE_GROUPS_LIST.WIZARD.SAVE_CLOSE_BUTTON' | translate"
            [isLoading]="apiIsProcessing"
          ></mc-live-button>
        </ng-container>
      </ng-container>
    </div>
  </mc-footer-container>
</mc-layout-detail-wizard>

