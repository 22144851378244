"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClpPolicyActions = void 0;
var ClpPolicyActions;
(function (ClpPolicyActions) {
    ClpPolicyActions["DONT_SCAN"] = "dontScan";
    ClpPolicyActions["LOG_ONLY"] = "logOnly";
    ClpPolicyActions["LOG_AND_REPLY"] = "logAndReply";
    ClpPolicyActions["BLOCK_MALICIOUS"] = "block_malicious";
    ClpPolicyActions["MANUALLY_QUARANTINED"] = "manually_quarantined";
    ClpPolicyActions["RELEASED"] = "released";
    ClpPolicyActions["NOT_SELECTED"] = "NOT_SELECTED";
})(ClpPolicyActions = exports.ClpPolicyActions || (exports.ClpPolicyActions = {}));
