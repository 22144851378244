"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ4 = exports.ɵ3 = exports.ɵ2 = exports.ɵ1 = exports.ɵ0 = exports.getSelectedRowIndex = exports.getDetailPageTotal = exports.getReleaseLogsMetadata = exports.getReleaseLogsRequestPage = exports.getReleaseLogsPagination = exports.getReleaseLogsSelectedRow = exports.getReleaseLogsSearch = exports.getReleaseLogsTotalCount = exports.getReleaseLogsItems = exports.isReleaseLogsLoading = exports.releaseLogsReducers = exports.getHoldQueueMetadata = exports.getHoldQueueRequestPage = exports.getHoldQueuePagination = exports.getHoldQueueSelectedRow = exports.getHoldQueueSearch = exports.getHoldQueueTotalCount = exports.getHoldQueueItems = exports.isHoldQueueLoading = exports.heldQueueReducers = exports.getSummaryByGroupResults = exports.isSummaryByGroupLoading = exports.getPaginationLoading = exports.getPaginationData = exports.getTopTenHeldSummaryByReasons = exports.getSelectedSummaryDetailResultsByKey = exports.getSummaryByReasonResults = exports.isSummaryByReasonLoading = exports.reducers = void 0;
const store_1 = require("@ngrx/store");
const fromSummaryByReason = require("./summary-by-reason.reducer");
const fromHeldQueue = require("./held-queue/held-queue.reducer");
const fromReleaseLogs = require("./release-logs/release-logs.reducer");
const fromSummaryByGroup = require("./summary-by-group.reducer");
const paginationHeldReason = require("./pagination-held-reason.reducer");
exports.reducers = {
    summaryByReason: fromSummaryByReason.reducer,
    summaryByGroup: fromSummaryByGroup.reducer
};
/**
 * Summary By Reason Selectors
 */
const getHeldMessagesSummaryState = store_1.createFeatureSelector('held-messages-summary');
const ɵ0 = (state) => state.summaryByReason;
exports.ɵ0 = ɵ0;
const getSummaryByReasonState = store_1.createSelector(getHeldMessagesSummaryState, ɵ0);
exports.isSummaryByReasonLoading = store_1.createSelector(getSummaryByReasonState, fromSummaryByReason.isLoading);
exports.getSummaryByReasonResults = store_1.createSelector(getSummaryByReasonState, fromSummaryByReason.getSummaryByReasonResults);
exports.getSelectedSummaryDetailResultsByKey = store_1.createSelector(getSummaryByReasonState, fromSummaryByReason.getSelectedSummaryDetailResultsByKey);
exports.getTopTenHeldSummaryByReasons = store_1.createSelector(getSummaryByReasonState, fromSummaryByReason.getTopTenHeldSummaryByReasons);
const getPaginationHeldReasonState = store_1.createFeatureSelector('pagination-held-reason');
const ɵ1 = (state) => state.summaryByReason;
exports.ɵ1 = ɵ1;
const getPaginationHeldReasonEntitiesState = store_1.createSelector(getPaginationHeldReasonState, ɵ1);
exports.getPaginationData = store_1.createSelector(getPaginationHeldReasonEntitiesState, paginationHeldReason.getPaginationData);
exports.getPaginationLoading = store_1.createSelector(getPaginationHeldReasonEntitiesState, paginationHeldReason.getPaginationLoading);
const ɵ2 = (state) => state.summaryByGroup;
exports.ɵ2 = ɵ2;
/**
 * Summary By State Selectors
 */
const getSummaryByGroupState = store_1.createSelector(getHeldMessagesSummaryState, ɵ2);
exports.isSummaryByGroupLoading = store_1.createSelector(getSummaryByGroupState, fromSummaryByGroup.isLoading);
exports.getSummaryByGroupResults = store_1.createSelector(getSummaryByGroupState, fromSummaryByGroup.getSummaryByGroupResults);
exports.heldQueueReducers = {
    heldQueue: fromHeldQueue.reducer
};
/**
 * held queue state selectors
 */
const heldQueueState = store_1.createFeatureSelector('held-queue');
const ɵ3 = (state) => state.heldQueue;
exports.ɵ3 = ɵ3;
const getHeldQueueState = store_1.createSelector(heldQueueState, ɵ3);
exports.isHoldQueueLoading = store_1.createSelector(getHeldQueueState, fromHeldQueue.isLoading);
exports.getHoldQueueItems = store_1.createSelector(getHeldQueueState, fromHeldQueue.getItems);
exports.getHoldQueueTotalCount = store_1.createSelector(getHeldQueueState, fromHeldQueue.getTotalCount);
exports.getHoldQueueSearch = store_1.createSelector(getHeldQueueState, fromHeldQueue.getSearchRequest);
exports.getHoldQueueSelectedRow = store_1.createSelector(getHeldQueueState, fromHeldQueue.getSelectedRow);
exports.getHoldQueuePagination = store_1.createSelector(getHeldQueueState, fromHeldQueue.getPagination);
exports.getHoldQueueRequestPage = store_1.createSelector(getHeldQueueState, fromHeldQueue.getRequestPage);
exports.getHoldQueueMetadata = store_1.createSelector(getHeldQueueState, fromHeldQueue.getMetadata);
exports.releaseLogsReducers = {
    releaseLogs: fromReleaseLogs.reducer
};
/**
 * release logs state
 */
const releaseLogsState = store_1.createFeatureSelector('release-logs');
const ɵ4 = (state) => state.releaseLogs;
exports.ɵ4 = ɵ4;
const getReleaseLogsState = store_1.createSelector(releaseLogsState, ɵ4);
exports.isReleaseLogsLoading = store_1.createSelector(getReleaseLogsState, fromReleaseLogs.isLoading);
exports.getReleaseLogsItems = store_1.createSelector(getReleaseLogsState, fromReleaseLogs.getItems);
exports.getReleaseLogsTotalCount = store_1.createSelector(getReleaseLogsState, fromReleaseLogs.getTotalCount);
exports.getReleaseLogsSearch = store_1.createSelector(getReleaseLogsState, fromReleaseLogs.getSearchRequest);
exports.getReleaseLogsSelectedRow = store_1.createSelector(getReleaseLogsState, fromReleaseLogs.getSelectedRow);
exports.getReleaseLogsPagination = store_1.createSelector(getReleaseLogsState, fromReleaseLogs.getPagination);
exports.getReleaseLogsRequestPage = store_1.createSelector(getReleaseLogsState, fromReleaseLogs.getRequestPage);
exports.getReleaseLogsMetadata = store_1.createSelector(getReleaseLogsState, fromReleaseLogs.getMetadata);
exports.getDetailPageTotal = store_1.createSelector(getReleaseLogsState, fromReleaseLogs.getDetailPageTotal);
exports.getSelectedRowIndex = store_1.createSelector(getReleaseLogsState, fromReleaseLogs.getSelectedRowIndex);
