"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AfterCloseSidePanelExportApiAction = exports.CloseSidePanelExportApiAction = exports.OpenSidePanelExportApiAction = exports.ExportApiSidePanelActionTypes = void 0;
var ExportApiSidePanelActionTypes;
(function (ExportApiSidePanelActionTypes) {
    ExportApiSidePanelActionTypes["OpenSidePanel"] = "[Components - Export Api] Open Side Panel";
    ExportApiSidePanelActionTypes["CloseSidePanel"] = "[Components - Export Api] Close Side Panel";
    ExportApiSidePanelActionTypes["AfterCloseSidePanel"] = "[Components - Export Api] After Close Side Panel";
})(ExportApiSidePanelActionTypes = exports.ExportApiSidePanelActionTypes || (exports.ExportApiSidePanelActionTypes = {}));
class OpenSidePanelExportApiAction {
    constructor(apiExportUrl, tableColumns, searchPayload = {}, translatePrefix = 'EXPORT_API') {
        this.apiExportUrl = apiExportUrl;
        this.tableColumns = tableColumns;
        this.searchPayload = searchPayload;
        this.translatePrefix = translatePrefix;
        this.type = ExportApiSidePanelActionTypes.OpenSidePanel;
    }
}
exports.OpenSidePanelExportApiAction = OpenSidePanelExportApiAction;
class CloseSidePanelExportApiAction {
    constructor() {
        this.type = ExportApiSidePanelActionTypes.CloseSidePanel;
    }
}
exports.CloseSidePanelExportApiAction = CloseSidePanelExportApiAction;
class AfterCloseSidePanelExportApiAction {
    constructor() {
        this.type = ExportApiSidePanelActionTypes.AfterCloseSidePanel;
    }
}
exports.AfterCloseSidePanelExportApiAction = AfterCloseSidePanelExportApiAction;
