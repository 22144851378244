"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueueComponent = void 0;
const queueActions = require("app-new/archive/supervision/actions/queue.actions");
const index_1 = require("app-new/archive/supervision/reducers/index");
const queue_service_1 = require("../services/queue.service");
const queue_type_enum_1 = require("app-new/archive/supervision/types/queue-type.enum");
const wizard_base_component_1 = require("../wizard-base.component");
class QueueComponent extends wizard_base_component_1.WizardBaseDirective {
    constructor(capabilitiesService, store, translateService, stateService) {
        super(capabilitiesService, queue_service_1.QueueService.WizardId, store, translateService);
        this.capabilitiesService = capabilitiesService;
        this.store = store;
        this.translateService = translateService;
        this.stateService = stateService;
        this.queueDetails = this.stateService.$state.params.queueDetails;
        this.isStepValid = false;
    }
    ngOnInit() {
        super.ngOnInit();
        this.loading$ = this.store.select(index_1.getQueueLoading);
        this.selectedReviewers$ = this.store.select(index_1.getSelectedReviewers);
        this.store.dispatch(new queueActions.GetEscalationQueuesAction());
        this.escalationQueues$ = this.store.select(index_1.getEscalationQueues);
        this.store.dispatch(new queueActions.GetReviewersAction());
        if (this.isEditMode) {
            this.reviewersSubscription = this.store.select(index_1.getReviewers).subscribe(reviewers => {
                if (reviewers) {
                    const selectedReviewers = reviewers.filter(reviewer => this.selectedReviewers.indexOf(reviewer.emailAddress) > -1);
                    this.store.dispatch(new queueActions.AddReviewersAction(selectedReviewers));
                    this.reviewersSubscription.unsubscribe();
                }
            });
        }
    }
    ngAfterViewInit() {
        this.stepValid = this.isCurrentStepValid$.subscribe(value => (this.isStepValid = value));
    }
    ngOnDestroy() {
        super.ngOnDestroy();
        if (this.stepValid) {
            this.stepValid.unsubscribe();
        }
    }
    get isEditMode() {
        return this.queueDetails !== undefined && this.queueDetails !== null;
    }
    get name() {
        return this.isEditMode && this.queueDetails.name ? this.queueDetails.name : '';
    }
    get description() {
        return this.isEditMode && this.queueDetails.description ? this.queueDetails.description : '';
    }
    get type() {
        return this.isEditMode && this.queueDetails.type ? this.queueDetails.type : undefined;
    }
    get selectedEscalationQueues() {
        return this.isEditMode && this.queueDetails.escalationQueues
            ? this.queueDetails.escalationQueues
            : [];
    }
    get defaultEscalationQueue() {
        return this.isEditMode && this.queueDetails.defaultEscalationQueue
            ? this.queueDetails.defaultEscalationQueue.id
            : '';
    }
    get allowPrinting() {
        return this.isEditMode && this.queueDetails.printAllowed !== undefined
            ? this.queueDetails.printAllowed
            : true;
    }
    get allowForwarding() {
        return this.isEditMode && this.queueDetails.forwardAllowed !== undefined
            ? this.queueDetails.forwardAllowed
            : true;
    }
    get selectedLabels() {
        return this.isEditMode && this.queueDetails.labels ? this.queueDetails.labels : [];
    }
    get selectedReviewers() {
        return this.isEditMode && this.queueDetails.reviewers ? this.queueDetails.reviewers : [];
    }
    get wizardTitle() {
        if (!this.isEditMode) {
            return this.translateService.instant('$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.TAB_CREATE_TITLE');
        }
        return this.translateService.instant('$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.TAB_UPDATE_TITLE');
    }
    getCancelConfirmationText() {
        if (!this.isEditMode) {
            return this.translateService.instant('$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.CANCEL_SAVE_CONFIRMATION');
        }
        return this.translateService.instant('$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.CANCEL_UPDATE_CONFIRMATION');
    }
    getSaveButtonText() {
        if (!this.isEditMode) {
            return this.translateService.instant('$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.CONTROLS.CREATE');
        }
        return this.translateService.instant('$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.CONTROLS.UPDATE_SAVE');
    }
    onSave() {
        if (!this.isEditMode) {
            // @ts-ignore
            this.store.dispatch(new queueActions.CreateAction(this.getQueueConfigDetails()));
        }
        else {
            this.store.dispatch(
            // @ts-ignore
            new queueActions.UpdateAction(this.queueDetails.id, this.getQueueConfigDetails()));
        }
    }
    getQueueConfigDetails() {
        const newQueue = {
            name: this.wizardData.steps[1] && this.wizardData.steps[1].data['name'],
            description: this.wizardData.steps[1].data['description'],
            queueType: this.wizardData.steps[1].data['qtype'],
            labels: this.wizardData.steps[2].data['labels'].map(label => label.name),
            reviewers: this.wizardData.steps[2].data['reviewers'].map(reviewer => reviewer.reviewer.id)
        };
        newQueue.forwardAllowed = this.wizardData.steps[2].data['allowForwarding'];
        if (this.wizardData.steps[1].data['qtype'] === queue_type_enum_1.QueueType.ESCALATION) {
            newQueue.printAllowed = this.wizardData.steps[2].data['allowPrinting'];
        }
        if (this.wizardData.steps[1].data['qtype'] === queue_type_enum_1.QueueType.REVIEW) {
            newQueue.escalationQueues = this.wizardData.steps[1].data['escalationQueues'].map(queue => queue.id);
            newQueue.defaultEscalationQueue = this.wizardData.steps[1].data['defaultEscalationQueue'];
        }
        return newQueue;
    }
    get isCurrentStepValid() {
        return this.isStepValid;
    }
}
exports.QueueComponent = QueueComponent;
