"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueueSummaryComponent = void 0;
const queue_type_enum_1 = require("app-new/archive/supervision/types/queue-type.enum");
class QueueSummaryComponent {
    constructor(translateService) {
        this.translateService = translateService;
        this.QueueType = queue_type_enum_1.QueueType;
        this.DEFAULT_QUEUE_LABEL = this.translateService.instant('$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.SUMMARY_STEP.DEFAULT_QUEUE');
    }
    ngOnChanges(changes) {
        if (changes && changes['wizardData'].currentValue) {
            this.stepsData = this.wizardData.steps;
        }
    }
    getStepById(id) {
        return (this.stepsData && this.stepsData[id]) || {};
    }
    getStepDataById(id) {
        const step = this.getStepById(id);
        return (step && step.data) || {};
    }
    formatEnabled(enabled) {
        if (enabled) {
            return this.translateService.instant('$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.SETTINGS_STEP.STEP_ENABLE');
        }
        return this.translateService.instant('$I18N_ARCHIVE_SUPERVISION_WIZARD.QUEUE.SETTINGS_STEP.STEP_DISABLE');
    }
    getReviewerName(reviewer) {
        if (!reviewer) {
            return '';
        }
        if (reviewer.name && reviewer.emailAddress && reviewer.name !== reviewer.emailAddress) {
            return `${reviewer.name} [${reviewer.emailAddress}]`;
        }
        else {
            return reviewer.emailAddress;
        }
    }
    isDefault(queue) {
        const defaultEscalationQueueId = this.getStepDataById(1)['defaultEscalationQueue'];
        return queue.id === defaultEscalationQueueId ? this.DEFAULT_QUEUE_LABEL : '';
    }
}
exports.QueueSummaryComponent = QueueSummaryComponent;
