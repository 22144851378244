"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./log-detail-navigator.tpl.html");
document.createElement('mc-log-detail-navigator');
angular.module('components.log-detail-navigator.directive', [])
    .directive('mcLogDetailNavigator', [function () {
        return {
            restrict: 'AE',
            templateUrl: 'components/log-detail-navigator/log-detail-navigator.tpl.html',
            scope: {
                logIndex: '=',
                log: '=',
                logService: '='
            },
            controller: ['$scope', function ($scope) {
                    let logsService;
                    activate();
                    function setIndexBoundaries() {
                        $scope.minIndex = $scope.logIndex <= 0;
                        $scope.maxIndex = $scope.logIndex + 1 >= logsService.getLogs().length;
                    }
                    function activate() {
                        logsService = $scope.logService;
                        setIndexBoundaries();
                    }
                    $scope.getNextLog = function () {
                        $scope.log = logsService.getActiveLog($scope.logIndex += 1);
                        logsService.highlightRow($scope.logIndex);
                        logsService.doScrollDown();
                        setIndexBoundaries();
                    };
                    $scope.getPrevLog = function () {
                        $scope.log = logsService.getActiveLog($scope.logIndex -= 1);
                        logsService.highlightRow($scope.logIndex);
                        logsService.doScrollUp();
                        setIndexBoundaries();
                    };
                }]
        };
    }]);
