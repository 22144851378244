"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPageNumber = exports.getMetadata = exports.isSearching = exports.isInitialState = exports.isLoading = exports.getContents = exports.folderContentReducer = exports.INITIAL_FOLDER_CONTENT_STATE = void 0;
const ContentActions = require("../actions/contents.actions");
exports.INITIAL_FOLDER_CONTENT_STATE = {
    contents: [],
    meta: {},
    loading: false,
    searching: false,
    initialState: true,
    pageNumber: 0
};
function folderContentReducer(state = exports.INITIAL_FOLDER_CONTENT_STATE, action) {
    switch (action.type) {
        case ContentActions.LOAD_EXCHANGE_FOLDER_EMAILS:
        case ContentActions.LOAD_EXCHANGE_SEARCH_FOLDER_EMAILS:
        case ContentActions.LOAD_EXCHANGE_FOLDER_CALENDARS:
        case ContentActions.LOAD_EXCHANGE_FOLDER_CONTACTS:
        case ContentActions.LOAD_EXCHANGE_SEARCH_FOLDER_CALENDARS:
        case ContentActions.LOAD_EXCHANGE_SEARCH_FOLDER_CONTACTS:
        case ContentActions.LOAD_ONE_DRIVE_FILES:
            return Object.assign(Object.assign({}, state), { loading: true, initialState: false, pageNumber: 0 });
        case ContentActions.LOAD_SUCCESS_EXCHANGE_FOLDER_EMAILS:
            return Object.assign(Object.assign({}, state), { contents: action.emails, meta: action.meta, loading: false, searching: false });
        case ContentActions.LOAD_SUCCESS_EXCHANGE_FOLDER_CALENDARS:
            return Object.assign(Object.assign({}, state), { contents: action.calendars, meta: action.meta, loading: false, searching: false });
        case ContentActions.LOAD_SUCCESS_EXCHANGE_SEARCH_FOLDER_CALENDARS:
            return Object.assign(Object.assign({}, state), { contents: action.calendars, meta: action.meta, loading: false, searching: true });
        case ContentActions.LOAD_SUCCESS_EXCHANGE_FOLDER_CONTACTS:
            return Object.assign(Object.assign({}, state), { contents: action.contacts, meta: action.meta, loading: false, searching: false });
        case ContentActions.LOAD_SUCCESS_EXCHANGE_SEARCH_FOLDER_CONTACTS:
            return Object.assign(Object.assign({}, state), { contents: action.contacts, meta: action.meta, loading: false, searching: true });
        case ContentActions.LOAD_SUCCESS_ONE_DRIVE_FILES:
            return Object.assign(Object.assign({}, state), { contents: action.contents, meta: action.meta, loading: false, searching: false });
        case ContentActions.LOAD_SUCCESS_EXCHANGE_SEARCH_FOLDER_EMAILS:
            return Object.assign(Object.assign({}, state), { contents: action.emails, meta: action.meta, loading: false, searching: true, pageNumber: action.pageNumber });
        case ContentActions.LOAD_FAILURE_EXCHANGE_SEARCH_FOLDER_CONTACTS:
        case ContentActions.LOAD_FAILURE_EXCHANGE_SEARCH_FOLDER_CALENDARS:
        case ContentActions.LOAD_FAILURE_EXCHANGE_SEARCH_FOLDER_EMAILS:
        case ContentActions.CLEAN_STORE:
            return exports.INITIAL_FOLDER_CONTENT_STATE;
        default:
            return state;
    }
}
exports.folderContentReducer = folderContentReducer;
const getContents = (state) => state.contents;
exports.getContents = getContents;
const isLoading = (state) => state.loading;
exports.isLoading = isLoading;
const isInitialState = (state) => state.initialState;
exports.isInitialState = isInitialState;
const isSearching = (state) => state.searching;
exports.isSearching = isSearching;
const getMetadata = (state) => state.meta;
exports.getMetadata = getMetadata;
const getPageNumber = (state) => state.pageNumber;
exports.getPageNumber = getPageNumber;
