"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CortexParser = void 0;
class CortexParser {
    constructor(severityPipe, translate) {
        this.severityPipe = severityPipe;
        this.translate = translate;
        this.verified = false;
    }
    assign(cortex) {
        this.cortex = cortex;
        this.verified = !!this.cortex;
        return this.verified;
    }
    get region() {
        return this.verified ? this.cortex.region : '';
    }
    get instanceId() {
        return this.verified ? this.cortex.instanceId : '';
    }
    get lakeSerialNumber() {
        return this.verified ? this.cortex.lakeSerialNumber : '';
    }
    get integrationName() {
        return this.verified ? this.cortex.integrationName : '';
    }
    get notifyAction() {
        return this.verified
            ? this.cortex.notifyOnly
                ? this.translate.instant('$I18N_API_INTEGRATION_CORTEX_SETTINGS.VALUES.ACTIONS_NOTIFY_ONLY')
                : this.translate.instant('$I18N_API_INTEGRATION_CORTEX_SETTINGS.VALUES.ACTIONS_BLOCK_AND_NOTIFY')
            : '';
    }
    get enabled() {
        return this.verified ? this.cortex.enabled : false;
    }
    get spywareSeverity() {
        return this.verified ? this.severityPipe.transform(this.resolveSeverity('spyware')) : '';
    }
    get wildfireSeverity() {
        return this.verified ? this.severityPipe.transform(this.resolveSeverity('wildfire')) : '';
    }
    resolveSeverity(subtype) {
        const trigger = this.cortex.triggers.find(t => {
            return t.type === 'panw.threat' && t.fields.subtype === subtype;
        });
        return !!trigger ? trigger.fields.severity : 'none';
    }
}
exports.CortexParser = CortexParser;
