<mc-layout-aside-extra-container
  keyTitle="{{ '$I18N_API_CROWDSTRIKE_INTEGRATION_ACTIVITY_LOGS_ASIDE_VIEW.TITLE' | translate }}"
  [isLoading]="false"
  showClose="true"
  (closeAside)="onCloseClicked.emit()"
>
  <mc-body-container>
    <section class="mc-detail-section">
      <div class="panel-body no-padding-top">
        <div class="mc-detailed-list">
          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_CROWDSTRIKE_INTEGRATION_ACTIVITY_LOGS_ASIDE_VIEW.FIELDS.FILE_HASH' | translate }}
            </div>
            <div class="mc-detailed-list-column">
              {{ crowdstrikeActivityLog.fileHash }}
            </div>
          </div>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_CROWDSTRIKE_INTEGRATION_ACTIVITY_LOGS_ASIDE_VIEW.FIELDS.INDICATOR_TYPE' | translate }}
            </div>
            <div class="mc-detailed-list-column">
              {{ crowdstrikeActivityLog.indicatorType  }}
            </div>
          </div>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_CROWDSTRIKE_INTEGRATION_ACTIVITY_LOGS_ASIDE_VIEW.FIELDS.DIRECTION' | translate }}
            </div>
            <div class="mc-detailed-list-column">
              {{ crowdstrikeActivityLog.targetedPlatform }}
            </div>
          </div>

          <div class="row mc-detailed-list-row">
            <div class="col-xs-4 mc-detailed-list-label">
              {{ '$I18N_API_CROWDSTRIKE_INTEGRATION_ACTIVITY_LOGS_ASIDE_VIEW.FIELDS.TIMESTAMP' | translate }}
            </div>
            <div class="mc-detailed-list-column">
              {{ crowdstrikeActivityLog.timestamp | date: 'dd MMM yyyy hh:mm' }}
            </div>
          </div>
        </div>
      </div>
    </section>
  </mc-body-container>
</mc-layout-aside-extra-container>
