"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./page-simple-summary-directive"); //layout.page-simple-summary.directive
// html and css 
require("./page-simple-summary.less");
require("./page-simple-summary.tpl.html");
(function () {
    'use strict';
    /* ======================================================== *\

     = layout / page-simple-summary

     \* ======================================================== */
    angular.module('layout.page-simple-summary', ['layout.page-simple-summary.directive']);
}());
