"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getChannelsLoading = exports.getChannelsError = exports.getTeamsError = exports.getTeamsLoading = exports.getChannels = exports.getTeams = exports.reducer = exports.initialState = void 0;
const actions = require("../../actions/clp-channels.actions");
exports.initialState = {
    teams: [],
    channels: [],
    channelsLoading: false,
    teamsLoading: false,
    teamsError: false,
    channelError: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case actions.LOAD_NOTIFICATION_TEAMS:
            return Object.assign(Object.assign({}, state), { teamsLoading: true, teamsError: false, teams: [] });
        case actions.LOAD_NOTIFICATION_TEAMS_SUCCESS:
            return Object.assign(Object.assign({}, state), { teams: action.payload, teamsLoading: false, teamsError: action.payload.length === 0 });
        case actions.LOAD_NOTIFICATION_TEAMS_FAILURE:
            return Object.assign(Object.assign({}, state), { teamsLoading: false, teamsError: true });
        case actions.LOAD_NOTIFICATION_TEAMS_CLEAR:
            return Object.assign(Object.assign({}, state), { teams: [], teamsLoading: false, teamsError: false });
        case actions.LOAD_NOTIFICATION_CHANNELS:
            return Object.assign(Object.assign({}, state), { channelsLoading: true, channelError: false, channels: [] });
        case actions.LOAD_NOTIFICATION_CHANNELS_SUCCESS:
            return Object.assign(Object.assign({}, state), { channels: action.payload, channelsLoading: false, channelError: action.payload.length === 0 });
        case actions.LOAD_NOTIFICATION_CHANNELS_FAILURE:
            return Object.assign(Object.assign({}, state), { channelsLoading: false, channelError: true });
        case actions.LOAD_NOTIFICATION_CHANNELS_CLEAR:
            return Object.assign(Object.assign({}, state), { channels: [], channelError: false });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getTeams = (state) => state.teams;
exports.getTeams = getTeams;
const getChannels = (state) => state.channels;
exports.getChannels = getChannels;
const getTeamsLoading = (state) => state.teamsLoading;
exports.getTeamsLoading = getTeamsLoading;
const getTeamsError = (state) => state.teamsError;
exports.getTeamsError = getTeamsError;
const getChannelsError = (state) => state.channelError;
exports.getChannelsError = getChannelsError;
const getChannelsLoading = (state) => state.channelsLoading;
exports.getChannelsLoading = getChannelsLoading;
