"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BulkDeliveryEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const actions = require("../actions/bulk-delivery.action");
const reducers = require("../reducers");
const api_1 = require("app-new/components/export/api");
const commonActions = require("../../common/actions");
const notification_actions_1 = require("app-new/components/notification/actions/notification.actions");
const bulk_delivery_notifications_1 = require("./bulk-delivery.notifications");
class BulkDeliveryEffects {
    constructor(actions$, service, store$) {
        this.actions$ = actions$;
        this.service = service;
        this.store$ = store$;
        this.loadItems$ = this.actions$.pipe(effects_1.ofType(actions.BULK_DELIVERY_REQUEST, actions.BULK_DELIVERY_PAGINATION, actions.BULK_DELIVERY_REFRESH, ...Object.keys(bulk_delivery_notifications_1.bulkNotifications)), operators_1.withLatestFrom(this.store$.select(reducers.getBulkDeliverySearch), this.store$.select(reducers.getBulkDeliveryRequestPage)), operators_1.switchMap(([, request, pagination]) => {
            return this.service.getItems(request, pagination).pipe(operators_1.map(response => new actions.BulkDeliverySuccessAction(response)), operators_1.catchError(() => rxjs_1.of(new actions.BulkDeliveryFailAction())));
        }));
        this.displayRow$ = this.actions$.pipe(effects_1.ofType(actions.BULK_DELIVERY_SELECT_ROW), operators_1.withLatestFrom(this.store$.select(reducers.getBulkDeliveryItems)), operators_1.switchMap(([action, items]) => {
            if (action.payload) {
                return rxjs_1.of(new commonActions.ShowMessageInfoAction(action.payload, items, false));
            }
            return rxjs_1.EMPTY;
        }));
        this.rejectEmails$ = this.actions$.pipe(effects_1.ofType(actions.BULK_DELIVERY_REJECT_EMAILS), operators_1.switchMap((action) => {
            return this.service.rejectEmails(action.payload).pipe(operators_1.map(() => new actions.BulkDeliveryRejectEmailsSuccessAction(action.payload)), operators_1.catchError(() => rxjs_1.of(new actions.BulkDeliveryRejectEmailsFailAction(action.payload))));
        }));
        this.recalculateRoute$ = this.actions$.pipe(effects_1.ofType(actions.BULK_MESSAGE_RECALCULATE_ROUTE), operators_1.switchMap((action) => {
            return this.service.recalculateRoute(action.payload).pipe(operators_1.map(() => new actions.BulkRecalculateRouteSuccessAction(action.payload)), operators_1.catchError(() => rxjs_1.of(new actions.BulkRecalculateRouteFailAction(undefined))));
        }));
        this.retryEmails$ = this.actions$.pipe(effects_1.ofType(actions.BULK_DELIVERY_RETRY_EMAILS), operators_1.switchMap((action) => {
            return this.service.retryEmails(action.payload).pipe(operators_1.map(() => new actions.BulkDeliveryRetryEmailsSuccessAction(action.payload)), operators_1.catchError(() => rxjs_1.of(new actions.BulkDeliveryRetryEmailsFailAction(action.payload))));
        }));
        this.earlyBounceEmails$ = this.actions$.pipe(effects_1.ofType(actions.BULK_DELIVERY_EARLY_BOUNCE_EMAILS), operators_1.switchMap((action) => {
            return this.service.earlyBounceEmails(action.payload).pipe(operators_1.map(() => new actions.BulkDeliveryEarlyBounceEmailsSuccessAction(action.payload)), operators_1.catchError(() => rxjs_1.of(new actions.BulkDeliveryEarlyBounceEmailsFailAction(action.payload))));
        }));
        this.export$ = this.actions$.pipe(effects_1.ofType(actions.BULK_DELIVERY_EXPORT), operators_1.withLatestFrom(this.store$.select(reducers.getBulkDeliverySearch)), operators_1.map(([action, search]) => {
            const apiSearch = this.service.transformToServerRequest(search);
            return new api_1.OpenSidePanelExportApiAction('/api/gateway/export-delivery-messages', action.payload, apiSearch, '$I18N_MESSAGE_CENTER_BULK_DELIVERY_EXPORT');
        }));
        this.bulkNotifications$ = this.actions$.pipe(effects_1.ofType(...Object.keys(bulk_delivery_notifications_1.bulkNotifications)), operators_1.map((action) => {
            const notification = bulk_delivery_notifications_1.bulkNotifications[action.type];
            return new notification_actions_1.NotificationShowAction({
                config: { msg: notification.message },
                type: notification.type
            });
        }));
    }
}
__decorate([
    effects_1.Effect()
], BulkDeliveryEffects.prototype, "loadItems$", void 0);
__decorate([
    effects_1.Effect()
], BulkDeliveryEffects.prototype, "displayRow$", void 0);
__decorate([
    effects_1.Effect()
], BulkDeliveryEffects.prototype, "rejectEmails$", void 0);
__decorate([
    effects_1.Effect()
], BulkDeliveryEffects.prototype, "recalculateRoute$", void 0);
__decorate([
    effects_1.Effect()
], BulkDeliveryEffects.prototype, "retryEmails$", void 0);
__decorate([
    effects_1.Effect()
], BulkDeliveryEffects.prototype, "earlyBounceEmails$", void 0);
__decorate([
    effects_1.Effect()
], BulkDeliveryEffects.prototype, "export$", void 0);
__decorate([
    effects_1.Effect()
], BulkDeliveryEffects.prototype, "bulkNotifications$", void 0);
exports.BulkDeliveryEffects = BulkDeliveryEffects;
