"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.notificationUrlFormatValues = exports.similarUrlDetectionValues = exports.urlFileDownloadValues = exports.fileProtocolUrlHandlingValues = exports.messageSubjectProtectionValues = exports.maliciousUrlDetectionActionValues = exports.urlCategoryScanningValues = exports.rewriteModeValues = exports.similarUrlDetectionIndexValues = exports.formControlsToUpdateOnCheckForSimilarUrls = exports.formControlsToUpdateOnRewrite = exports.formControlNames = void 0;
const url_protection_inbound_settings_step_models_1 = require("./url-protection-inbound-settings-step.models");
exports.formControlNames = {
    REWRITE_URLS_IN_ATTACHMENTS: 'rewriteUrlsInAttachments',
    ATTACHMENT_PART_HTML: 'attachmentPartHtml',
    ATTACHMENT_PART_TEXT: 'attachmentPartText',
    ATTACHMENT_PART_CALENDAR: 'attachmentPartCalendar',
    CHECK_FOR_SIMILAR_URLS: 'checkForSimilarUrls',
    INTERNAL_DOMAINS: 'internalDomains',
    MIMECAST_MONITORING_EXTERNAL_DOMAIN: 'mimecastMonitoringExternalDomain',
    CUSTOM_MONITORING_EXTERNAL_DOMAIN: 'customMonitoringExternalDomain',
    SIMILAR_URL_DETECTION: 'similarUrlDetection',
    MALICIOUS_URL_DETECTION_ACTION: 'maliciousUrlDetectionAction',
    CHECK_INBOUND_EMAIL: 'checkInboundEmail',
    URL_CATEGORY_SCANING: 'urlCategoryScanningMode',
    IGNORE_SIGNED_MESSAGES: 'ignoreSignedMessage'
};
exports.formControlsToUpdateOnRewrite = [
    exports.formControlNames.ATTACHMENT_PART_HTML,
    exports.formControlNames.ATTACHMENT_PART_TEXT,
    exports.formControlNames.ATTACHMENT_PART_CALENDAR
];
exports.formControlsToUpdateOnCheckForSimilarUrls = [
    exports.formControlNames.INTERNAL_DOMAINS,
    exports.formControlNames.MIMECAST_MONITORING_EXTERNAL_DOMAIN,
    exports.formControlNames.CUSTOM_MONITORING_EXTERNAL_DOMAIN
];
exports.similarUrlDetectionIndexValues = {
    ALLOW: 2,
    BLOCK: 0
};
exports.rewriteModeValues = [
    {
        value: url_protection_inbound_settings_step_models_1.RewriteModeOptions.AGGRESSIVE,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.REWRITE_MODE.VALUES.AGGRESSIVE'
    },
    {
        value: url_protection_inbound_settings_step_models_1.RewriteModeOptions.MODERATE,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.REWRITE_MODE.VALUES.MODERATE'
    },
    {
        value: url_protection_inbound_settings_step_models_1.RewriteModeOptions.RELAXED,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.REWRITE_MODE.VALUES.RELAXED'
    }
];
exports.urlCategoryScanningValues = [
    {
        value: url_protection_inbound_settings_step_models_1.UrlCategoryScanningOptions.AGGRESSIVE,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.URL_CATEGORY_SCANNING.VALUES.AGGRESSIVE'
    },
    {
        value: url_protection_inbound_settings_step_models_1.UrlCategoryScanningOptions.MODERATE,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.URL_CATEGORY_SCANNING.VALUES.MODERATE'
    },
    {
        value: url_protection_inbound_settings_step_models_1.UrlCategoryScanningOptions.RELAXED,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.URL_CATEGORY_SCANNING.VALUES.RELAXED'
    }
];
exports.maliciousUrlDetectionActionValues = [
    {
        value: url_protection_inbound_settings_step_models_1.MaliciousUrlDetectionActionOptions.BLOCK,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.MALICIOUS_URL_DETECTION_ACTION.VALUES.BLOCK'
    },
    {
        value: url_protection_inbound_settings_step_models_1.MaliciousUrlDetectionActionOptions.WARN,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.MALICIOUS_URL_DETECTION_ACTION.VALUES.WARN'
    },
    {
        value: url_protection_inbound_settings_step_models_1.MaliciousUrlDetectionActionOptions.ALLOW,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.MALICIOUS_URL_DETECTION_ACTION.VALUES.ALLOW'
    }
];
exports.messageSubjectProtectionValues = [
    {
        value: url_protection_inbound_settings_step_models_1.MessageSubjectProtectionOptions.REWRITE_AND_SCAN,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.MESSAGE_SUBJECT_PROTECTION.VALUES.REWRITE_AND_SCAN'
    },
    {
        value: url_protection_inbound_settings_step_models_1.MessageSubjectProtectionOptions.REMOVE_URLS,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.MESSAGE_SUBJECT_PROTECTION.VALUES.REMOVE_URLS'
    },
    {
        value: url_protection_inbound_settings_step_models_1.MessageSubjectProtectionOptions.TAKE_NO_ACTIONS,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.MESSAGE_SUBJECT_PROTECTION.VALUES.TAKE_NO_ACTION'
    }
];
exports.fileProtocolUrlHandlingValues = [
    {
        value: url_protection_inbound_settings_step_models_1.FileProtocolUrlHandlingOptions.STRIP_THE_URL_SCHEMA,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.FILE_PROTOCOL_URL_HANDLING.VALUES.STRIP_THE_URL_SCHEME'
    },
    {
        value: url_protection_inbound_settings_step_models_1.FileProtocolUrlHandlingOptions.HOLD_THE_MESSAGE_FOR_REVIEW,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.FILE_PROTOCOL_URL_HANDLING.VALUES.HOLD'
    },
    {
        value: url_protection_inbound_settings_step_models_1.FileProtocolUrlHandlingOptions.TAKE_NO_ACTIONS,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.FILE_PROTOCOL_URL_HANDLING.VALUES.TAKE_NO_ACTION'
    }
];
exports.urlFileDownloadValues = [
    {
        value: url_protection_inbound_settings_step_models_1.UrlFileDownloadOptions.CHECK,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.URL_FILE_DOWNLOAD.VALUES.CHECK'
    },
    {
        value: url_protection_inbound_settings_step_models_1.UrlFileDownloadOptions.BLOCK,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.URL_FILE_DOWNLOAD.VALUES.BLOCK'
    },
    {
        value: url_protection_inbound_settings_step_models_1.UrlFileDownloadOptions.WARN,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.URL_FILE_DOWNLOAD.VALUES.WARN'
    },
    {
        value: url_protection_inbound_settings_step_models_1.UrlFileDownloadOptions.ALLOW,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.URL_FILE_DOWNLOAD.VALUES.ALLOW'
    }
];
exports.similarUrlDetectionValues = [
    {
        value: url_protection_inbound_settings_step_models_1.SimilarUrlDetectionOptions.BLOCK,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.SIMILAR_URL_DETECTION.VALUES.BLOCK'
    },
    {
        value: url_protection_inbound_settings_step_models_1.SimilarUrlDetectionOptions.WARN,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.SIMILAR_URL_DETECTION.VALUES.WARN'
    },
    {
        value: url_protection_inbound_settings_step_models_1.SimilarUrlDetectionOptions.ALLOW,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.SIMILAR_URL_DETECTION.VALUES.ALLOW'
    }
];
exports.notificationUrlFormatValues = [
    {
        value: url_protection_inbound_settings_step_models_1.NotificationUrlFormatOptions.IN_WEB,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.NOTIFICATION_URL_FORMAT.VALUES.IN_PAGE'
    },
    {
        value: url_protection_inbound_settings_step_models_1.NotificationUrlFormatOptions.IN_NOTIFICATION,
        label: '$I18N_URL_PROTECTION_POLICY_WIZARD.STEPS.INBOUND_SETTINGS.LABELS.NOTIFICATION_URL_FORMAT.VALUES.IN_NOTIFICATION'
    }
];
