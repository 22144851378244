<mc-modal-header [headerTitle]="'$I18N_CUSTOM_DOMAIN_BLOCK_VIEW_POLICY.CONTROLS.DELETE_CONFIGURATION' | translate "></mc-modal-header>
<mc-modal-body>
    {{ '$I18N_CUSTOM_DOMAIN_BLOCK_VIEW_POLICY.CONTROLS.ARE_YOU_SURE_DELETE' | translate }} {{getPolicyName()}}?
</mc-modal-body>
<mc-modal-footer>
    <button type="button" class="btn btn-secondary" (click)="cancel()">
        {{ '$I18N_CUSTOM_DOMAIN_BLOCK_VIEW_POLICY.CONTROLS.CANCEL_NO' | translate }}
    </button>

    <button type="button" class="btn btn-danger" (click)="confirm()">
        <i class="far fa-trash-alt"></i> {{ '$I18N_CUSTOM_DOMAIN_BLOCK_VIEW_POLICY.CONTROLS.CANCEL_YES' | translate }}
    </button>
</mc-modal-footer>