"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ0 = exports.isFindGroupsLoadingError = exports.isFindGroupsLDAPDataLoaded = exports.isFindGroupsCloudDataLoaded = exports.findCloudGroups = exports.findLdapGroups = void 0;
const store_1 = require("@ngrx/store");
const findGroupsReducer = require("../reducers/find-groups.reducer");
const select_groups_1 = require("./select-groups");
const ɵ0 = (state) => state.findGroups;
exports.ɵ0 = ɵ0;
const getFindGroupsState = store_1.createSelector(select_groups_1.getArchiveGroupsState, ɵ0);
exports.findLdapGroups = store_1.createSelector(getFindGroupsState, findGroupsReducer.findLdapGroups);
exports.findCloudGroups = store_1.createSelector(getFindGroupsState, findGroupsReducer.findCloudGroups);
exports.isFindGroupsCloudDataLoaded = store_1.createSelector(getFindGroupsState, findGroupsReducer.isCloudDataLoaded);
exports.isFindGroupsLDAPDataLoaded = store_1.createSelector(getFindGroupsState, findGroupsReducer.isLDAPDataLoaded);
exports.isFindGroupsLoadingError = store_1.createSelector(getFindGroupsState, findGroupsReducer.hasGenericError);
