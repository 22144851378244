"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const static_1 = require("@angular/upgrade/static");
const angular = require("angular");
const containers_1 = require("./containers");
angular
    .module('secure-messaging', [])
    .controller('SecureMessagingController', [
    '$stateParams',
    '$scope',
    function ($stateParams, $scope) {
        $scope.stateParams = $stateParams;
    }
])
    .directive('mcSecureMessaging', static_1.downgradeComponent({
    component: containers_1.SecureMessagingPageComponent
}))
    .config([
    '$stateProvider',
    function ($stateProvider) {
        $stateProvider.state('secure-messaging', {
            url: '/services/secure-messaging',
            data: {
                isFixedLayout: false,
                checkProgress: false,
                tabReload: false,
                capabilities: 'Temporary.Service.Secure.Messaging && Permission.SERVICE_SECURE_MESSAGING_READ'
            },
            views: {
                main: {
                    template: '<mc-secure-messaging></mc-secure-messaging>',
                    controller: 'SecureMessagingController'
                }
            }
        });
    }
]);
