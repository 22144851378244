"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LexiconListEffect = void 0;
const effects_1 = require("@ngrx/effects");
const lexicon_list_actions_1 = require("../actions/lexicon.list.actions");
const operators_1 = require("rxjs/operators");
const rxjs_1 = require("rxjs");
const lexicon_details_factory_1 = require("../factories/lexicon-details.factory");
const lexicon_actions_1 = require("../actions/lexicon.actions");
const notification_actions_1 = require("app-new/components/notification/actions/notification.actions");
const table_store_1 = require("@mimecast-ui/table-store");
const TABLE_ID = 'SupervisionLexiconList';
class LexiconListEffect {
    constructor(actions$, stateService, lexiconService, translateService, notificationService, tableStore) {
        this.actions$ = actions$;
        this.stateService = stateService;
        this.lexiconService = lexiconService;
        this.translateService = translateService;
        this.notificationService = notificationService;
        this.tableStore = tableStore;
        this.getLexiconDetails$ = this.actions$.pipe(effects_1.ofType(lexicon_list_actions_1.LexiconListActions.LOAD_LEXICON_DETAILS), operators_1.switchMap((action) => {
            return this.lexiconService.getLexicon(action.payload).pipe(operators_1.map((response) => {
                if (response.hasErrors) {
                    return new lexicon_list_actions_1.LoadLexiconDetailsFailureAction(response.fail[0]);
                }
                return new lexicon_list_actions_1.LoadLexiconDetailsSuccessAction(response.first);
            }), operators_1.catchError(response => {
                return rxjs_1.of(new lexicon_list_actions_1.LoadLexiconDetailsFailureAction(response.fail));
            }));
        }));
        this.showCreateLexiconWizard$ = this.actions$.pipe(effects_1.ofType(lexicon_list_actions_1.LexiconListActions.OPEN_CREATE_NEW_LEXICON), operators_1.switchMap(() => {
            this.stateService.$state.go('archive-supervision-create-wizard-lexicon');
            return rxjs_1.EMPTY;
        }));
        this.showEditLexiconWizard$ = this.actions$.pipe(effects_1.ofType(lexicon_list_actions_1.LexiconListActions.OPEN_EDIT_LEXICON), operators_1.map((action) => action.lexiconId), operators_1.switchMap(lexiconId => {
            return this.lexiconService.getLexicon(lexiconId).pipe(operators_1.map(response => {
                if (response.hasErrors) {
                    return { error: response.fail[0], lDetails: undefined };
                }
                const lexiconDetails = lexicon_details_factory_1.LexiconDetailsFactory.BUILD_LEXICON_DETAILS(response.first);
                return { error: undefined, lDetails: lexiconDetails };
            }));
        }), operators_1.switchMap((result) => {
            this.stateService.$state.go('archive-supervision-update-wizard-lexicon', {
                lexiconDetails: Object.assign({}, result.lDetails)
            });
            if (result.error) {
                return rxjs_1.of(new lexicon_actions_1.GetActionFail(result.error));
            }
            else {
                return rxjs_1.EMPTY;
            }
        }));
        this.delete$ = this.actions$.pipe(effects_1.ofType(lexicon_list_actions_1.LexiconListActions.DELETE_LEXICON), operators_1.switchMap((action) => {
            return this.lexiconService.deleteLexicon(action.payload).pipe(operators_1.map(response => {
                if (response.hasErrors) {
                    return new lexicon_list_actions_1.DeleteLexiconActionFail({
                        message: this.getNotificationMessage('DELETE_FAILURE'),
                        failure: response.fail[0]
                    });
                }
                return new lexicon_list_actions_1.DeleteLexiconActionSuccess(this.getNotificationMessage('DELETE_SUCCESS'));
            }), operators_1.catchError(response => {
                return rxjs_1.of(new lexicon_list_actions_1.DeleteLexiconActionFail({
                    message: this.getNotificationMessage('DELETE_FAILURE'),
                    failure: response.fail[0]
                }));
            }));
        }));
        this.deleteSuccess$ = this.actions$.pipe(effects_1.ofType(lexicon_list_actions_1.LexiconListActions.DELETE_LEXICON_SUCCESS), operators_1.map((action) => action.payload), operators_1.withLatestFrom(this.tableStore.select(table_store_1.tableReducer.getTableData(TABLE_ID)), this.tableStore.select(table_store_1.tableReducer.getCurrentFilters(TABLE_ID)), this.tableStore.select(table_store_1.tableReducer.getCurrentPagination(TABLE_ID))), operators_1.switchMap(([payload, tableData, currentFilters, currentPagination]) => {
            return [
                new notification_actions_1.NotificationShowSuccessAction(payload),
                new table_store_1.LoadAction({
                    tableId: TABLE_ID,
                    pagination: {
                        pageToken: currentPagination.pageToken,
                        pageSize: currentPagination.pageSize,
                        pageNumber: currentPagination.pageNumber
                    },
                    query: tableData.length === 1 ? undefined : currentFilters
                })
            ];
        }));
        this.deleteFail$ = this.actions$.pipe(effects_1.ofType(lexicon_list_actions_1.LexiconListActions.DELETE_LEXICON_FAIL), operators_1.tap((action) => {
            if (!action.payload.failure || !action.payload.failure.errors) {
                this.notificationService.error({
                    msg: action.payload.message,
                    duration: 0
                });
            }
            return action.payload.failure.errors.forEach(error => {
                this.notificationService.error({
                    msg: this.translateService.instant(this.getNotificationMessage(error.code))
                });
            });
        }));
    }
    getNotificationMessage(notificationkey) {
        const fullKey = `$I18N_ARCHIVE_SUPERVISION_LIST_LEXICON_TAB.NOTIFICATION.${notificationkey.toUpperCase()}`;
        return this.translateService.instant(fullKey);
    }
}
__decorate([
    effects_1.Effect()
], LexiconListEffect.prototype, "getLexiconDetails$", void 0);
__decorate([
    effects_1.Effect()
], LexiconListEffect.prototype, "showCreateLexiconWizard$", void 0);
__decorate([
    effects_1.Effect()
], LexiconListEffect.prototype, "showEditLexiconWizard$", void 0);
__decorate([
    effects_1.Effect()
], LexiconListEffect.prototype, "delete$", void 0);
__decorate([
    effects_1.Effect()
], LexiconListEffect.prototype, "deleteSuccess$", void 0);
__decorate([
    effects_1.Effect({ dispatch: false })
], LexiconListEffect.prototype, "deleteFail$", void 0);
exports.LexiconListEffect = LexiconListEffect;
