'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
angular.module('email.view.controller', [])
    .controller('EmailViewController', ['$state', '$sce', 'emailViewService', '$scope', 'archiveFolderService', 'adconBackupService',
    function ($state, $sce, emailViewService, $scope, archiveFolderService, adconBackupService) {
        const self = this;
        const { message, asideInstance, mailbox } = $scope.$parent.$resolve;
        // ----- Function exposed to the template ---
        self.tabs = [];
        self.selectedTab = {};
        self.message = {};
        self.errorMessage = '';
        // TODO: should be using FOLDER_CONTENT_TYPE instead, check if it will break legacy
        self.contentType = archiveFolderService.CONTENT_TYPES;
        self.hasPermission = adconBackupService.CONSTANTS.HAS_PERMISSIONS;
        self.getTabClass = getTabClass;
        self.toTabView = toTabView;
        self.isActive = isActive;
        self.selectTab = selectTab;
        self.showImages = emailViewService.showImages;
        /* -------------------------------------------------------- *\
   - initialisation
   \* -------------------------------------------------------- */
        activate();
        /* -------------------------------------------------------- *\
         - implementation
         \* -------------------------------------------------------- */
        function activate() {
            if (asideInstance) {
                asideInstance.dismiss();
            }
            self.message = message.message;
            self.errorMessage = message.error;
            self.mailbox = mailbox;
            self.tabs = [];
            if (self.message.hasHtmlBody) {
                self.tabs.push({
                    id: 'preview_html',
                    label: '$I18N_MESSAGE_DETAIL_TAB_PREVIEW_HTML',
                    tpl: 'services/services/backup/components/email-view/tabs/preview_html.tpl.html',
                    icon: 'mc-icon-tab-html-message',
                    canPrint: true,
                    printTemplateKey: 'html'
                });
            }
            if (self.message.hasTextBody) {
                self.tabs.push({
                    id: 'preview_text',
                    label: '$I18N_MESSAGE_DETAIL_TAB_PREVIEW_TEXT',
                    tpl: 'services/services/backup/components/email-view/tabs/preview_plain.tpl.html',
                    icon: 'mc-icon-tab-plaintext-message',
                    canPrint: true,
                    printTemplateKey: 'plain'
                });
            }
            if (self.tabs.length) {
                self.selectedTab = self.tabs[0];
            }
        }
        function isActive(tab) {
            return self.selectedTab === tab ? 'active' : 'not-active';
        }
        function selectTab(tab, emailObj) {
            self.selectedTab = tab;
            emailObj.selectedTab = tab;
            emailObj.commentAndTransition = false;
        }
        function getTabClass(tab, selectedItem) {
            const cssClass = [self.selectedTab === tab ? 'active' : 'not-active'];
            if (tab.id === 'preview_html' && !selectedItem.hasHtmlBody) {
                cssClass.push('disabled');
                if (self.selectedTab === tab) {
                    self.selectedTab = self.tabs[1];
                    selectedItem.selectedTab = self.selectedTab;
                }
            }
            if (tab.id === 'preview_text' && !selectedItem.hasTextBody) {
                cssClass.push('disabled');
            }
            return cssClass;
        }
        function toTabView(email) {
            let singleViesStateComputed = '.singleView';
            singleViesStateComputed = $state.current.name.split('.')[0] + singleViesStateComputed;
            $state.go(singleViesStateComputed, { messageId: email.id });
        }
    }]);
