<mc-table
  *ngIf="!(isLoadResponseEmpty$ | async)"
  [data]="tableData$ | async"
  [columns]="columns"
  (rowClick)="openItem($event)"
  [isLoading]="isLoading$ | async"
  translatePrefix="$I18N_ARCHIVE_SUPERVISION_LIST_QUEUES_TAB"
  [showHighlightedRow]="true"
  [highlightedRow]="highlightedRow$ | async"
>
  <button
    [disabled]="!hasEditPermission"
    class="mc-table-actions btn btn-primary mc-create-button"
    (click)="openCreate()"
  >
    {{ '$I18N_ARCHIVE_SUPERVISION_LIST_QUEUES_TAB.BUTTONS.CREATE_NEW' | translate }}
  </button>
  <mc-column-actions key="columnActions" mcShowColumnConfig>
    <mc-row-actions *mcRowActions="let row">
      <li mcRowAction="$I18N_SUPERVISION_LISTS.COMMON_LABELS.VIEW" (click)="openItem(row)"></li>
      <li
        *ngIf="hasEditPermission"
        mcRowAction="$I18N_COMMON_BTN_EDIT"
        (click)="editItem(row)"
      ></li>
      <li
        *ngIf="hasSupervisionDeleteCapability && hasEditPermission"
        mcRowAction="$I18N_COMMON_BTN_DELETE"
       (click)="deleteQueueConfig(row)"
      ></li> 
    </mc-row-actions>
  </mc-column-actions>
  <mc-column-date key="updated"></mc-column-date>
  <mc-column-date key="created"></mc-column-date>
  <mc-column key="status">
    <mc-body-cell *mcBodyCellDef="let row">{{ getStatus(row.status) | translate }}</mc-body-cell>
  </mc-column>
  <mc-column key="type">
    <mc-body-cell *mcBodyCellDef="let row">{{ getType(row.type) | translate }}</mc-body-cell>
  </mc-column>
  <mc-column key="reviewerCount">
    <mc-body-cell *mcBodyCellDef="let row">{{ row.reviewerCount || '-' }}</mc-body-cell>
  </mc-column>
  <mc-column key="labels">
    <mc-body-cell *mcBodyCellDef="let row">{{ getNumberOfLabels(row.labels || []) }}</mc-body-cell>
  </mc-column>
  <mc-filters [metadata]="metaData$ | async" (paginatorChange)="onPaginationChange($event)">
    <mc-filter-bundle-filter-by-and-search-by
      (filtersChange)="onFilterChange($event)"
      placeholder="$I18N_ARCHIVE_SUPERVISION_LIST_QUEUES_TAB.FILTERS.SEARCH_FIELD.PLACEHOLDER"
      [sections]="sections"
      [searchByFields]="searchByFields"
    >
    </mc-filter-bundle-filter-by-and-search-by>
  </mc-filters>
  <mc-empty-results
    iconClass="far fa-hdd"
    keyTitle="$I18N_ARCHIVE_SUPERVISION_LIST_QUEUES_TAB.EMPTY_SEARCH.TITLE"
  ></mc-empty-results>
</mc-table>

<mc-empty-results
  *ngIf="!(isLoading$ | async) && (isLoadResponseEmpty$ | async)"
  keyTitle="$I18N_ARCHIVE_SUPERVISION_LIST_QUEUES_TAB.EMPTY_LIST.TITLE"
  iconClass="far fa-hdd"
>
  <mc-empty-body>
    <div class="panel-half-padding-bottom">
      {{ '$I18N_ARCHIVE_SUPERVISION_LIST_QUEUES_TAB.EMPTY_LIST.DESCRIPTION' | translate }}
    </div>
    <button class="mc-btn-item btn btn-primary" (click)="openCreate()">
      {{ '$I18N_ARCHIVE_SUPERVISION_LIST_QUEUES_TAB.BUTTONS.CREATE_NEW' | translate }}
    </button>
  </mc-empty-body>
</mc-empty-results>
