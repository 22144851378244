"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ɵ1 = exports.ɵ0 = exports.getStepData = exports.isStepValid = exports.getStepById = exports.isCurrentStepValid = exports.getCurrentStepState = exports.getCurrentStep = exports.getWizardById = exports.getWizardState = exports.getDetailsPaginationInfo = exports.getRedirectGroup = exports.getRedirectEmail = exports.getProfileGroup = exports.getNotifyGroups = exports.isLoading = exports.getSelectedRow = exports.getState = exports.getCustomDomainBlock = exports.reducers = void 0;
const store_1 = require("@ngrx/store");
const customDomainBlockReducer = require("./configurations-table.reducer");
const fromConfigurationsTable = require("./configurations-table.reducer");
const wizardReducer = require("./wizard.reducer");
exports.reducers = {
    customDomainBlock: fromConfigurationsTable.reducer,
    customDomainBlockWizard: wizardReducer.reducer
};
exports.getCustomDomainBlock = store_1.createFeatureSelector('custom-domain-block');
const ɵ0 = (state) => state.customDomainBlock;
exports.ɵ0 = ɵ0;
exports.getState = store_1.createSelector(exports.getCustomDomainBlock, ɵ0);
exports.getSelectedRow = store_1.createSelector(exports.getState, customDomainBlockReducer.getSelectedRow);
exports.isLoading = store_1.createSelector(exports.getState, customDomainBlockReducer.isLoading);
exports.getNotifyGroups = store_1.createSelector(exports.getState, customDomainBlockReducer.getNotifyGroups);
exports.getProfileGroup = store_1.createSelector(exports.getState, customDomainBlockReducer.getProfileGroup);
exports.getRedirectEmail = store_1.createSelector(exports.getState, customDomainBlockReducer.getRedirectEmail);
exports.getRedirectGroup = store_1.createSelector(exports.getState, customDomainBlockReducer.getRedirectGroup);
exports.getDetailsPaginationInfo = store_1.createSelector(exports.getState, customDomainBlockReducer.getDetailsPaginationInfo);
const ɵ1 = (state) => state.customDomainBlockWizard;
exports.ɵ1 = ɵ1;
exports.getWizardState = store_1.createSelector(exports.getCustomDomainBlock, ɵ1);
const getWizardById = (wizardId) => store_1.createSelector(exports.getWizardState, wizardReducer.getWizardById(wizardId));
exports.getWizardById = getWizardById;
const getCurrentStep = (wizardId) => store_1.createSelector(exports.getWizardById(wizardId), wizardReducer.getCurrentStep);
exports.getCurrentStep = getCurrentStep;
const getCurrentStepState = (wizardId) => store_1.createSelector(exports.getWizardById(wizardId), wizardReducer.getCurrentStepState);
exports.getCurrentStepState = getCurrentStepState;
const isCurrentStepValid = (wizardId) => store_1.createSelector(exports.getCurrentStepState(wizardId), wizardReducer.isStepValid);
exports.isCurrentStepValid = isCurrentStepValid;
const getStepById = (wizardId, stepId) => store_1.createSelector(exports.getWizardById(wizardId), wizardReducer.getStepById(stepId));
exports.getStepById = getStepById;
const isStepValid = (wizardId, stepId) => store_1.createSelector(exports.getStepById(wizardId, stepId), wizardReducer.isStepValid);
exports.isStepValid = isStepValid;
const getStepData = (wizardId, stepId) => store_1.createSelector(exports.getStepById(wizardId, stepId), wizardReducer.getStepData);
exports.getStepData = getStepData;
