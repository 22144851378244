"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApCancelPolicyModalComponent = void 0;
const policy_list_model_1 = require("../../models/policy-list.model");
const policyWizardActions = require("../../actions/policy.actions");
class ApCancelPolicyModalComponent {
    constructor(store, translateService, cancelSource) {
        this.store = store;
        this.translateService = translateService;
        this.cancelSource = cancelSource;
    }
    getModalTitle() {
        if (this.cancelSource === policy_list_model_1.ApPolicyCancelModalSource.EDIT_POLICY) {
            return this.translateService.instant('$I18N_AP_CANCEL_EDIT_POLICY_CONFIRMATION');
        }
        else {
            return this.translateService.instant('$I18N_AP_CANCEL_CREATE_POLICY_CONFIRMATION');
        }
    }
    onYesResponse() {
        this.store.dispatch(new policyWizardActions.CancelModalYesAction());
    }
    onNoResponse() {
        this.store.dispatch(new policyWizardActions.CancelModalNoAction());
    }
}
exports.ApCancelPolicyModalComponent = ApCancelPolicyModalComponent;
