<div *ngIf="!loading && !noData" class="sfc-graph-layout">
  <div *ngIf="!noData" class="sfc-graph-layout-content">
    <ng-content></ng-content>
  </div>
</div>

<div *ngIf="loading">
  <mc-spinner class="mc-chart-widget-spinner"></mc-spinner>
</div>

<mc-sfc-chart-no-data-message
  *ngIf="noData"
  [message]="noDataMessage"
></mc-sfc-chart-no-data-message>
