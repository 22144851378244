"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgPaginateThreatEventsCompletedAction = exports.SwgPaginateThreatEventsAction = exports.SwgLoadThreatEventsGraphFailureAction = exports.SwgLoadThreatEventsGraphSuccessAction = exports.SwgLoadThreatEventsGraphAction = exports.SwgUpdateThreatEventAction = exports.SwgLoadThreatEventsFailureAction = exports.SwgLoadThreatEventsSuccessAction = exports.SwgLoadThreatEventsAction = exports.SwgLoadThreatEventsActionsEnum = void 0;
var SwgLoadThreatEventsActionsEnum;
(function (SwgLoadThreatEventsActionsEnum) {
    SwgLoadThreatEventsActionsEnum["LOAD_THREAT_EVENTS"] = "[SwgThreatEvents] Load Threat Events";
    SwgLoadThreatEventsActionsEnum["LOAD_THREAT_EVENTS_SUCCESS"] = "[SwgThreatEvents] Load Threat Events Success";
    SwgLoadThreatEventsActionsEnum["LOAD_THREAT_EVENTS_FAILURE"] = "[SwgThreatEvents] Load Threat Events Failure";
    SwgLoadThreatEventsActionsEnum["UPDATE_THREAT_EVENT"] = "[SwgThreatEvents] Update Threat Events";
    SwgLoadThreatEventsActionsEnum["LOAD_THREAT_EVENTS_GRAPH"] = "[SwgThreatEvents] Load Threat Events Graph";
    SwgLoadThreatEventsActionsEnum["LOAD_THREAT_EVENTS_GRAPH_SUCCESS"] = "[SwgThreatEvents] Load Threat Events Graph Success";
    SwgLoadThreatEventsActionsEnum["LOAD_THREAT_EVENTS_GRAPH_FAILURE"] = "[SwgThreatEvents] Load Threat Events Graph Failure";
    SwgLoadThreatEventsActionsEnum["PAGINATE_THREAT_EVENTS"] = "[SwgThreatEvents] Paginate Threat Events";
    SwgLoadThreatEventsActionsEnum["PAGINATE_THREAT_EVENTS_COMPLETED"] = "[SwgThreatEvents] Paginate Threat Events Completed";
})(SwgLoadThreatEventsActionsEnum = exports.SwgLoadThreatEventsActionsEnum || (exports.SwgLoadThreatEventsActionsEnum = {}));
class SwgLoadThreatEventsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgLoadThreatEventsActionsEnum.LOAD_THREAT_EVENTS;
    }
}
exports.SwgLoadThreatEventsAction = SwgLoadThreatEventsAction;
class SwgLoadThreatEventsSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgLoadThreatEventsActionsEnum.LOAD_THREAT_EVENTS_SUCCESS;
    }
}
exports.SwgLoadThreatEventsSuccessAction = SwgLoadThreatEventsSuccessAction;
class SwgLoadThreatEventsFailureAction {
    constructor() {
        this.type = SwgLoadThreatEventsActionsEnum.LOAD_THREAT_EVENTS_FAILURE;
    }
}
exports.SwgLoadThreatEventsFailureAction = SwgLoadThreatEventsFailureAction;
class SwgUpdateThreatEventAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgLoadThreatEventsActionsEnum.UPDATE_THREAT_EVENT;
    }
}
exports.SwgUpdateThreatEventAction = SwgUpdateThreatEventAction;
class SwgLoadThreatEventsGraphAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgLoadThreatEventsActionsEnum.LOAD_THREAT_EVENTS_GRAPH;
    }
}
exports.SwgLoadThreatEventsGraphAction = SwgLoadThreatEventsGraphAction;
class SwgLoadThreatEventsGraphSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgLoadThreatEventsActionsEnum.LOAD_THREAT_EVENTS_GRAPH_SUCCESS;
    }
}
exports.SwgLoadThreatEventsGraphSuccessAction = SwgLoadThreatEventsGraphSuccessAction;
class SwgLoadThreatEventsGraphFailureAction {
    constructor() {
        this.type = SwgLoadThreatEventsActionsEnum.LOAD_THREAT_EVENTS_GRAPH_FAILURE;
    }
}
exports.SwgLoadThreatEventsGraphFailureAction = SwgLoadThreatEventsGraphFailureAction;
class SwgPaginateThreatEventsAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgLoadThreatEventsActionsEnum.PAGINATE_THREAT_EVENTS;
    }
}
exports.SwgPaginateThreatEventsAction = SwgPaginateThreatEventsAction;
class SwgPaginateThreatEventsCompletedAction {
    constructor() {
        this.type = SwgLoadThreatEventsActionsEnum.PAGINATE_THREAT_EVENTS_COMPLETED;
    }
}
exports.SwgPaginateThreatEventsCompletedAction = SwgPaginateThreatEventsCompletedAction;
