"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSummaryByGroupResults = exports.isLoading = exports.reducer = exports.initialState = void 0;
const summaryByGroupAction = require("../actions/summary-by-group.action");
exports.initialState = {
    results: [],
    isLoading: false
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case summaryByGroupAction.SUMMARY_BY_GROUP_REQUEST: {
            return Object.assign(Object.assign({}, state), { error: undefined, isLoading: true });
        }
        case summaryByGroupAction.SUMMARY_BY_GROUP_SUCCESS: {
            return Object.assign(Object.assign({}, state), { results: action.payload.results, isLoading: false });
        }
        case summaryByGroupAction.SUMMARY_BY_GROUP_FAIL: {
            return Object.assign(Object.assign({}, state), { isLoading: false, error: undefined });
        }
        default:
            return state;
    }
}
exports.reducer = reducer;
const isLoading = (state) => state.isLoading;
exports.isLoading = isLoading;
const getSummaryByGroupResults = (state) => state.results;
exports.getSummaryByGroupResults = getSummaryByGroupResults;
