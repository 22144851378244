<mc-collapsable-panel header="$I18N_MESSAGE_CENTER_THREAT_INFO_PROCESSING_DETAILS_PROPERTIES.PROCESSING_DETAILS_TITLE"
  [isExpanded]="true">
  <div class="mc-detailed-list">
    <div class="row mc-detailed-list-row">
      <mc-tooltip class="col-xs-4 mc-detailed-list-label"
        labelText="{{'$I18N_MESSAGE_CENTER_THREAT_INFO_PROCESSING_DETAILS_PROPERTIES.LABEL_GRAYMAIL' | translate}}"
        placement="top"
        popoverContent="{{ '$I18N_MESSAGE_CENTER_THREAT_INFO_PROCESSING_DETAILS_PROPERTIES.POPOVER_GRAYMAIL_INFO' | translate }}">
      </mc-tooltip>
      <span *ngIf="!!processingDetails && (isBoolean(processingDetails.greyEmail)); else emptyValue">
        <div class="mc-detailed-list-column" [innerHTML]="processingDetails.greyEmail | mcGreyMailDetailsDisplayPipe">
        </div>
      </span>
    </div>

    <div class="row mc-detailed-list-row">
      <mc-tooltip class="col-xs-4 mc-detailed-list-label"
        labelText="{{'$I18N_MESSAGE_CENTER_THREAT_INFO_PROCESSING_DETAILS_PROPERTIES.LABEL_MANAGED_SENDER_INFO' | translate}}"
        placement="top"
        popoverContent="{{ '$I18N_MESSAGE_CENTER_THREAT_INFO_PROCESSING_DETAILS_PROPERTIES.POPOVER_MANAGED_SENDER_INFO' | translate }}"
        linkText="{{ '$I18N_MESSAGE_CENTER_THREAT_INFO_PROCESSING_DETAILS_PROPERTIES.POPOVER_MANAGED_SENDER_LINK_TEXT' | translate }}"
        linkUrl="https://community.mimecast.com/docs/DOC-1467">
      </mc-tooltip>
      <span
        *ngIf="!!processingDetails && !!processingDetails.managedSender && !!processingDetails.managedSender.info; else emptyValue">
        <div class="mc-detailed-list-column"
          [innerHTML]="processingDetails.managedSender.info | mcManagedSenderDetailsDisplayPipe"></div>
      </span>
    </div>

    <div class="row mc-detailed-list-row">
      <mc-tooltip class="col-xs-4 mc-detailed-list-label"
        labelText="{{'$I18N_MESSAGE_CENTER_THREAT_INFO_PROCESSING_DETAILS_PROPERTIES.LABEL_PERMITTED_SENDER_INFO' | translate}}"
        placement="top"
        popoverContent="{{ '$I18N_MESSAGE_CENTER_THREAT_INFO_PROCESSING_DETAILS_PROPERTIES.POPOVER_PERMITTED_SENDER_INFO' | translate }}"
        linkText="{{ '$I18N_MESSAGE_CENTER_THREAT_INFO_PROCESSING_DETAILS_PROPERTIES.POPOVER_PERMITTED_SENDER_LINK_TEXT' | translate }}"
        linkUrl="https://community.mimecast.com/docs/DOC-1427">
      </mc-tooltip>
      <span
        *ngIf="!!processingDetails && !!processingDetails.permittedSender && !!processingDetails.permittedSender.info; else emptyValue">
        <div class="mc-detailed-list-column"
          [innerHTML]="processingDetails.permittedSender.info | mcPermittedSenderDetailsDisplayPipe"></div>
      </span>
    </div>

    <div class="row mc-detailed-list-row">
      <mc-tooltip class="col-xs-4 mc-detailed-list-label"
        labelText="{{'$I18N_MESSAGE_CENTER_THREAT_INFO_PROCESSING_DETAILS_PROPERTIES.LABEL_SPF_INFO' | translate}}"
        placement="top"
        popoverContent="{{ '$I18N_MESSAGE_CENTER_THREAT_INFO_PROCESSING_DETAILS_PROPERTIES.POPOVER_SPF_RESULT_INFO' | translate }}">
      </mc-tooltip>
      <span *ngIf="!!processingDetails && !!processingDetails.spf && !!processingDetails.spf.info; else emptyValue">
        <div class="mc-detailed-list-column" [innerHTML]="processingDetails.spf.info | mcSPFDetailsDisplayPipe">
        </div>
      </span>
    </div>

    <div class="row mc-detailed-list-row">
      <mc-tooltip class="col-xs-4 mc-detailed-list-label"
        labelText="{{'$I18N_MESSAGE_CENTER_THREAT_INFO_PROCESSING_DETAILS_PROPERTIES.LABEL_DKIM_INFO' | translate}}"
        placement="top"
        popoverContent="{{ '$I18N_MESSAGE_CENTER_THREAT_INFO_PROCESSING_DETAILS_PROPERTIES.POPOVER_DKIM_RESULT_INFO' | translate }}">
      </mc-tooltip>
      <span *ngIf="!!processingDetails && !!processingDetails.dkim && !!processingDetails.dkim.info; else emptyValue">
        <div class="mc-detailed-list-column" [innerHTML]="processingDetails.dkim.info | mcDKIMDetailsDisplayPipe">
        </div>
      </span>
    </div>

    <div class="row mc-detailed-list-row">
      <mc-tooltip class="col-xs-4 mc-detailed-list-label"
        labelText="{{'$I18N_MESSAGE_CENTER_THREAT_INFO_PROCESSING_DETAILS_PROPERTIES.LABEL_DMARC_INFO' | translate}}"
        placement="top"
        popoverContent="{{ '$I18N_MESSAGE_CENTER_THREAT_INFO_PROCESSING_DETAILS_PROPERTIES.POPOVER_DMARC_RESULT_INFO' | translate }}">
      </mc-tooltip>
      <span *ngIf="!!processingDetails && !!processingDetails.dmarc && !!processingDetails.dmarc.info; else emptyValue">
        <div class="mc-detailed-list-column" [innerHTML]="processingDetails.dmarc.info | mcDMARCDetailsDisplayPipe">
        </div>
      </span>
    </div>

    <div class="row mc-detailed-list-row">
      <mc-tooltip class="col-xs-4 mc-detailed-list-label"
        labelText="{{'$I18N_MESSAGE_CENTER_THREAT_INFO_PROCESSING_DETAILS_PROPERTIES.LABEL_RBL_INFO' | translate}}"
        placement="top"
        popoverContent="{{ '$I18N_MESSAGE_CENTER_THREAT_INFO_PROCESSING_DETAILS_PROPERTIES.POPOVER_RBL_RESULT_INFO' | translate }}">
      </mc-tooltip>
      <span
        *ngIf="!!processingDetails && !!processingDetails.rbl && (isBoolean(processingDetails.rbl.allow)); else emptyValue">
        <div class="mc-detailed-list-column" [innerHTML]="processingDetails.rbl.allow | mcRblDetailsDisplayPipe">
        </div>
      </span>
    </div>
  </div>
</mc-collapsable-panel>

<ng-template #emptyValue>
  <span>
    <div class="mc-detailed-list-column">-</div>
  </span>
</ng-template>