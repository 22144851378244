"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgPolicyApiService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
class SwgPolicyApiService {
    constructor(http) {
        this.http = http;
    }
    createBlockDomainPolicy(payload) {
        if (payload.id) {
            return this.http
                .post('/api/policy/webwhiteurl/update-policy-with-targets', payload)
                .pipe(operators_1.map(res => res.first));
        }
        else {
            return this.http
                .post('/api/policy/webwhiteurl/create-policy-with-targets', payload)
                .pipe(operators_1.map(res => res.first));
        }
    }
    createAdvancedSecurityPolicy(payload) {
        if (payload.id) {
            return this.http
                .post('/api/policy/webadvancedsecurity/update-policy-with-targets', payload)
                .pipe(operators_1.map(res => res.first));
        }
        else {
            return this.http
                .post('/api/policy/webadvancedsecurity/create-policy-with-targets', payload)
                .pipe(operators_1.map(res => res.first));
        }
    }
    createWebLoggingPolicy(payload) {
        if (payload.id) {
            return this.http
                .post('/api/policy/weblogging/update-policy-with-targets', payload)
                .pipe(operators_1.map(res => res.first));
        }
        else {
            return this.http
                .post('/api/policy/weblogging/create-policy-with-targets', payload)
                .pipe(operators_1.map(res => res.first));
        }
    }
    createCategoryFilterPolicy(payload) {
        if (payload.id) {
            return this.http
                .post('/api/policy/webcategoryfilter/update-policy-with-targets', payload)
                .pipe(operators_1.map(res => res.first));
        }
        else {
            return this.http
                .post('/api/policy/webcategoryfilter/create-policy-with-targets', payload)
                .pipe(operators_1.map(res => res.first));
        }
    }
    createTTPPolicy(payload) {
        if (payload.id) {
            return this.http
                .post('/api/policy/webttpurl/update-policy-with-targets', payload)
                .pipe(operators_1.map(res => res.first));
        }
        else {
            return this.http
                .post('/api/policy/webttpurl/create-policy-with-targets', payload)
                .pipe(operators_1.map(res => res.first));
        }
    }
    createWebApplicationControlPolicy(payload) {
        if (payload.id) {
            return this.http
                .post('/api/policy/webapplicationcontrol/update-policy-with-targets', payload)
                .pipe(operators_1.map(res => res.first));
        }
        else {
            return this.http
                .post('/api/policy/webapplicationcontrol/create-policy-with-targets', payload)
                .pipe(operators_1.map(res => res.first));
        }
    }
    createClpPolicy(payload) {
        return this.http.post('/clp/v1/policies/create', payload).pipe(operators_1.map(res => res.first));
    }
    updateClpPolicy(payload) {
        return this.http.post('/clp/v1/policies/update', payload).pipe(operators_1.map(res => res.first));
    }
    deleteClpPolicy(id) {
        return this.http
            .post('/clp/v1/policies/delete', { id })
            .pipe(operators_1.map(res => res.first));
    }
    deleteBlockDomainPolicy(id) {
        return this.http
            .post('/api/policy/webwhiteurl/delete-policy-with-targets', { id })
            .pipe(operators_1.map(res => res.first));
    }
    deleteAdvancedSecurityPolicy(id) {
        return this.http
            .post('/api/policy/webadvancedsecurity/delete-policy-with-targets', { id })
            .pipe(operators_1.map(res => res.first));
    }
    deleteWebloggingPolicy(id) {
        return this.http
            .post('/api/policy/weblogging/delete-policy-with-targets', { id })
            .pipe(operators_1.map(res => res.first));
    }
    deleteCategoryFilterPolicy(id) {
        return this.http
            .post('/api/policy/webcategoryfilter/delete-policy-with-targets', { id })
            .pipe(operators_1.map(res => res.first));
    }
    deleteTtpSettingsPolicy(id) {
        return this.http
            .post('/api/policy/webttpurl/delete-policy-with-targets', { id })
            .pipe(operators_1.map(res => res.first));
    }
    deleteWebapplicationControlPolicy(id) {
        return this.http
            .post('/api/policy/webapplicationcontrol/delete-policy-with-targets', { id })
            .pipe(operators_1.map(res => res.first));
    }
    getBlockDomainPolicy(id) {
        return this.http
            .post('/api/policy/webwhiteurl/get-policy-with-targets', { id })
            .pipe(operators_1.map(res => res.first));
    }
    getAdvancedSecurityPolicy(id) {
        return this.http
            .post('/api/policy/webadvancedsecurity/get-policy-with-targets', { id })
            .pipe(operators_1.map(res => res.first));
    }
    getCategoryFilterPolicy(id) {
        return this.http
            .post('/api/policy/webcategoryfilter/get-policy-with-targets', { id })
            .pipe(operators_1.map(res => res.first));
    }
    getWebloggingPolicy(id) {
        return this.http
            .post('/api/policy/weblogging/get-policy-with-targets', { id })
            .pipe(operators_1.map(res => res.first));
    }
    getTtpSettingsPolicy(id) {
        return this.http
            .post('/api/policy/webttpurl/get-policy-with-targets', { id })
            .pipe(operators_1.map(res => res.first));
    }
    getWebapplicationControlPolicy(id) {
        return this.http
            .post('/api/policy/webapplicationcontrol/get-policy-with-targets', { id })
            .pipe(operators_1.map(res => res.first));
    }
    getBrowserIsolationPolicy(id) {
        return this.http
            .post('/api/policy/browserisolation/get-policy-with-targets', { id })
            .pipe(operators_1.map(res => res.first));
    }
    getCloudProtectPolicy(id) {
        return this.http
            .post('/clp/v1/policies/get-by-id', { id })
            .pipe(operators_1.map(res => res.first));
    }
    getLocations(payload) {
        return this.http.post('/api/swg/get-egress-ips', Object.assign({}, payload));
    }
    findUsers(payload) {
        return this.http.post('/api/user/find-internal-users', Object.assign({}, payload));
    }
    getUsers(payload) {
        return this.http.post('/api/user/get-internal-users', Object.assign({}, payload));
    }
    getAdvancedFileTypes() {
        return this.http.post('/api/policy/webadvancedsecurity/get-filetypes', null).pipe(operators_1.map(response => {
            return response.first;
        }));
    }
    getApplications(includeDeprecated = false) {
        return this.http
            .post('/api/policy/webapplicationcontrol/get-applications', {
            includeDeprecated
        })
            .pipe(operators_1.map((response) => response.first));
    }
    getTopApplications(dateRange) {
        const payload = [
            Object.assign(Object.assign({}, dateRange), { topN: 0, lastAccessed: true })
        ];
        return this.http
            .post('/api/swg/get-top-applications', {
            data: payload
        })
            .pipe(operators_1.map(response => {
            return response.first;
        }));
    }
    getCategorySections() {
        return this.http.post('/api/policy/webcategoryfilter/get-categories', null).pipe(operators_1.map(response => {
            return response.first;
        }));
    }
    getApplicationStatus() {
        return this.http
            .post('/api/policy/webapplicationcontrol/get-application-status', null)
            .pipe(operators_1.map(response => {
            return response.first;
        }));
    }
    updateApplicationStatus(data) {
        return this.http
            .post('/api/policy/webapplicationcontrol/update-application-status', {
            applicationStatus: data
        })
            .pipe(operators_1.map(response => {
            return response.first;
        }));
    }
    findConnectors() {
        return this.http.post('/api/connectors/find-connectors', {}).pipe(operators_1.map(response => response.first), operators_1.catchError((response) => {
            return rxjs_1.of(response);
        }));
    }
    getTopBlockedApplications(dateRange) {
        const payload = [
            Object.assign(Object.assign({}, dateRange), { verdict: 'block', topN: 10 })
        ];
        return this.http
            .post('/api/swg/get-top-applications', { data: payload })
            .pipe(operators_1.map(response => {
            return response.first;
        }));
    }
    getTopProfiles(dateRange) {
        const payload = [
            Object.assign(Object.assign({}, dateRange), { verdict: 'block', topN: 10 })
        ];
        return this.http
            .post('/api/swg/get-top-profiles', { data: payload })
            .pipe(operators_1.map(response => {
            return response.first;
        }));
    }
    getProtectedApplications() {
        return this.http.post('/api/policy/webadvancedsecurity/get-protected-apps', null).pipe(operators_1.map(response => {
            return response.first.applications;
        }));
    }
}
exports.SwgPolicyApiService = SwgPolicyApiService;
