<mc-collapsable-panel header="$I18N_MESSAGE_CENTER_MESSAGE_INFO_REJECTION_PROPERTIES.TITLE" [isExpanded]="true">
  <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_REJECTION_PROPERTIES.LABEL_TYPE"
                  [value]="queueInfo.type">
  </mc-value-field>
  <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_REJECTION_PROPERTIES.LABEL_DESCRIPTION"
                  [value]="queueInfo.reason">
  </mc-value-field>
  <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_REJECTION_PROPERTIES.LABEL_EVENT_TIME"
                  [value]="queueInfo.createTime | mcDatePlusWeekday">
  </mc-value-field>
  <mc-value-field label="$I18N_MESSAGE_CENTER_MESSAGE_INFO_REJECTION_PROPERTIES.LABEL_INFORMATION"
                  [value]="queueInfo.info">
  </mc-value-field>
</mc-collapsable-panel>
