<mc-layout-aside-extra-pagination-container
  keyTitle="$I18N_SERVICES_CYBERGRAPH_POLICIES_SIDE_PANEL.LABELS.POLICY_DETAILS_TITLE" [isLoading]="false"
  [showClose]="true" [hasNextInView]="hasNextInView$ | async" [hasPreviousInView]="hasPreviousInView$ | async"
  [indexInView]="indexInView$ | async" [currenPagination]="currentPagination$ | async"
  [filtersMetaData]="filtersMetaData$ | async" [isLoading]="false" (goToNextPage)="getNext()" (goToPrevPage)="getPrev()"
  (closeAside)="onClose()">

  <div row-actions>
    <div class="mc-header-row">
      <span>{{ selectedRow?.name }}</span>
    </div>
    <button class="btn btn-primary panel-half-margin-right" (click)="onEdit()">
      {{ '$I18N_SERVICES_CYBERGRAPH_POLICIES_SIDE_PANEL.LABELS.EDIT' | translate }}
    </button>
    <button class="btn btn-default panel-half-margin-right" (click)="onDelete()">
      {{ '$I18N_SERVICES_CYBERGRAPH_POLICIES_SIDE_PANEL.LABELS.DELETE' | translate }}
    </button>
    <div class="panel-half-margin-right mc-switch-inline">
      <form [formGroup]="detailsCyberGraphPolicyForm">
        <mc-switch data-unit-test="enable-disable-policy-control" formControlName="enableDisablePolicy" role="switch"
          showLabel="true"></mc-switch>
      </form>
    </div>
  </div>
  <mc-body-container>
    <mc-cyber-graph-side-panel-view-details *ngIf="policyDetailsFullData$ | async"
      [policyDetails]="policyDetails" data-unit-test="policy-side-panel-details">
    </mc-cyber-graph-side-panel-view-details>
  </mc-body-container>
</mc-layout-aside-extra-pagination-container>