"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CyberGraphReportingStatusEnum = exports.CyberGraphTrackersEnum = exports.CyberGraphDynamicBannersEnum = void 0;
var CyberGraphDynamicBannersEnum;
(function (CyberGraphDynamicBannersEnum) {
    CyberGraphDynamicBannersEnum["OFF"] = "off";
    CyberGraphDynamicBannersEnum["ON"] = "on";
    CyberGraphDynamicBannersEnum["LEARNING"] = "learning";
})(CyberGraphDynamicBannersEnum = exports.CyberGraphDynamicBannersEnum || (exports.CyberGraphDynamicBannersEnum = {}));
var CyberGraphTrackersEnum;
(function (CyberGraphTrackersEnum) {
    CyberGraphTrackersEnum["OFF"] = "off";
    CyberGraphTrackersEnum["ON"] = "on";
})(CyberGraphTrackersEnum = exports.CyberGraphTrackersEnum || (exports.CyberGraphTrackersEnum = {}));
var CyberGraphReportingStatusEnum;
(function (CyberGraphReportingStatusEnum) {
    CyberGraphReportingStatusEnum["OFF"] = "off";
    CyberGraphReportingStatusEnum["ON"] = "on";
})(CyberGraphReportingStatusEnum = exports.CyberGraphReportingStatusEnum || (exports.CyberGraphReportingStatusEnum = {}));
