"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TopReviewersPanelComponent = void 0;
const queue_service_1 = require("app-new/archive/supervision/containers/wizard/services/queue.service");
const queue_type_enum_1 = require("app-new/archive/supervision/types/queue-type.enum");
class TopReviewersPanelComponent {
    constructor(stateService) {
        this.stateService = stateService;
    }
    set data(topReviewerPerformances) {
        this.topReviewerPerformances = topReviewerPerformances;
        if (topReviewerPerformances && topReviewerPerformances.reviewerPerformances.length) {
            this.maxProcessedCount = this.topReviewerPerformances.reviewerPerformances[0].processedCount;
        }
    }
    hasReviewers() {
        return this.topReviewerPerformances.reviewerPerformances
            ? this.topReviewerPerformances.reviewerPerformances.length > 0
            : false;
    }
    isReviewQueueReviewer() {
        return this.topReviewerPerformances.queueType === queue_type_enum_1.QueueType.REVIEW;
    }
    calculateIndicatorWidth(reviewerPerformance) {
        const percentageWidth = (reviewerPerformance.processedCount / this.maxProcessedCount) * 100;
        const shouldBeMinWidth = reviewerPerformance.processedCount > 0 && percentageWidth < 1;
        return shouldBeMinWidth ? 1 : percentageWidth;
    }
    openTab() {
        this.stateService.$state
            .go('archive-supervision-list', {
            callerWizardId: queue_service_1.QueueService.WizardId
        })
            .then(() => this.stateService.$state.reload());
    }
    reviewerEmail(reviewerPerformance) {
        return reviewerPerformance.reviewer.emailAddress;
    }
}
exports.TopReviewersPanelComponent = TopReviewersPanelComponent;
