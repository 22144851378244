"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListComponent = void 0;
const table_store_1 = require("@mimecast-ui/table-store");
class ListComponent extends table_store_1.TableBaseComponent {
    constructor(store) {
        super(store, 'ExchangeTaskList');
        this.store = store;
        this.title = '$I18N_SYNC_RECOVER_EXCHANGE_TASK_LIST_TITLE';
        this.description = '$I18N_SYNC_RECOVER_EXCHANGE_TASK_LIST_DESCRIPTION';
        this.columnList = [
            'status',
            'description',
            'mailboxGroup',
            'serverConnectionSettings',
            'created',
            'detailedStatus',
            'status'
        ];
    }
}
exports.ListComponent = ListComponent;
