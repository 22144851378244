<mc-modal-header
        [headerTitle]="getModalTitle()"></mc-modal-header>
<mc-modal-footer>
    <button type="button" class="btn btn-secondary mc-policy-not-cancel" (click)="onNoResponse()">
        {{ '$I18N_COMMON_TEXT_NO' | translate }}
    </button>

    <button type="button" class="btn btn-primary mc-policy-yes-cancel" (click)="onYesResponse()">
        {{ '$I18N_COMMON_TEXT_YES' | translate }}
    </button>
</mc-modal-footer>