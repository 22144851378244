<mc-layout-aside-extra-container
  keyTitle="{{ '$I18N_ARCHIVE_SUPERVISION_SELECT_REVIEWERS.QUEUE.SELECT_REVIEWERS' | translate }}"
  showClose="true"
  [isLoading]="false"
  (closeAside)="onClose()"
>
  <mc-extra-container>
    <div class="mc-container-buttons-area">
      <button class="btn btn-primary" (click)="selectReviewersButton()" [disabled]="!hasChanges">
        {{ '$I18N_ARCHIVE_SUPERVISION_SELECT_REVIEWERS.QUEUE.CONTROLS.CONFIRM' | translate }}
      </button>
    </div>
  </mc-extra-container>
  <mc-body-container>
    <div class="row">
      <div class="col-sm-12">
        <input
          #searchReviewer
          type="text"
          class="form-control"
          (input)="onSearchChange($event)"
          [placeholder]="
            '$I18N_ARCHIVE_SUPERVISION_SELECT_REVIEWERS.QUEUE.SEARCH_PLACEHOLDER' | translate
          "
        />
      </div>

      <div class="col-sm-12">
        <mc-loader-full-container
          [isLoading]="loading"
          class="mc-layout-loader-full-container-aside mc-flex-scroll-vertical"
        >
          <div *ngIf="loading" style="height: 100px;"></div>
        </mc-loader-full-container>
        <form [formGroup]="form" *ngIf="!loading && sortedReviewers">
          <div
            class="checkbox"
            *ngFor="let reviewer of sortedReviewers"
            (change)="onSelectReviewer($event, reviewer)"
          >
            <label
              ><input type="checkbox" [formControlName]="reviewer.id" />{{
                getReviewerName(reviewer)
              }}
            </label>
          </div>
        </form>
        <div *ngIf="noReviewersFound" class="no-reviewers">
          {{ '$I18N_ARCHIVE_SUPERVISION_SELECT_REVIEWERS.QUEUE.NO_REVIEWERS' | translate }}
        </div>
      </div>
    </div>
  </mc-body-container>
</mc-layout-aside-extra-container>
