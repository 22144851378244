<i
    class="fas fa-question-circle"
    aria-hidden="true"
    data-test="mepTooltipIcon"
    [tooltip]="tooltipToShow"
></i>
<ng-template #autoReleaseTooltip>
    <aside id="autoReleaseTooltip" class="auto-release-tooltip" aria-label="Auto Release Tooltip" data-test="autoReleaseTooltip">
        <p>{{ '$I18N_MEP_TOOLTIPS.AUTO_RELEASE.DESCRIPTION' | translate }}</p>
    </aside>
</ng-template>
<ng-template #matchLevelTooltip>
    <aside id="matchLevelTooltip" class="matching-level-tooltip" aria-label="Matching Level Tooltip" data-test="matchLevelTooltip">
        <!-- If this is being viewed in the sidebar, it doesn't make sense to tell the user to select an option. -->
        <!-- I have therefore altered the description slightly if we are viewing the tooltip in the sidebar. -->
        <p *ngIf="!inSidebar; else matchLevelSidebarDescription">{{ '$I18N_MEP_TOOLTIPS.MATCHING_LEVEL.DESCRIPTION' | translate }}</p>
        <ul>
            <li>
                <strong>{{ '$I18N_MEP_TOOLTIPS.MATCHING_LEVEL.LOW.LABEL' | translate }}:</strong>
                {{ '$I18N_MEP_TOOLTIPS.MATCHING_LEVEL.LOW.DESCRIPTION' | translate }}
            </li>
            <li>
                <strong>{{ '$I18N_MEP_TOOLTIPS.MATCHING_LEVEL.HIGH.LABEL' | translate }}:</strong>
                {{ '$I18N_MEP_TOOLTIPS.MATCHING_LEVEL.HIGH.DESCRIPTION' | translate }}
            </li>
        </ul>
    </aside>
</ng-template>
<ng-template #matchLevelSidebarDescription>
    <p>{{ '$I18N_MEP_TOOLTIPS.MATCHING_LEVEL.SIDEBAR_DESCRIPTION' | translate }}</p>
</ng-template>
