"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MepLogSidebarExternalRecipientsListComponent = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const models_1 = require("../../../../models/models");
const logs_actions_1 = require("app-new/services/mep/logs/actions/logs.actions");
class MepLogSidebarExternalRecipientsListComponent {
    constructor(store, mepAdminLogService, capabilitiesService) {
        this.store = store;
        this.mepAdminLogService = mepAdminLogService;
        this.capabilitiesService = capabilitiesService;
        this.mepAdminLogActions = models_1.MepAdminLogActions;
        this.destroy$ = new rxjs_1.Subject();
    }
    set item(value) {
        if (value) {
            this.mapExternalRecipients(value);
        }
    }
    ngOnInit() {
        this.hasMepLogReleaseDropPermission$ = this.capabilitiesService.hasCapability('Permission.MEP_RELEASE/DROP');
    }
    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
    mapExternalRecipients(item) {
        this.id = item.id;
        this.isButtonDisabled = item.status !== models_1.MepLogStatus.HELD;
        this.externalRecipients = item.externalAddresses;
        this.recipientStatus = item.status;
    }
    hasExternalRecipients() {
        return this.externalRecipients && this.externalRecipients.length > 0;
    }
    onAdminAction(action) {
        this.mepAdminLogService
            .openLogActionConfirmationModal(action)
            .pipe(operators_1.takeUntil(this.destroy$))
            .subscribe((confirmed) => {
            if (confirmed) {
                this.store.dispatch(new logs_actions_1.MepLogAdminAction({ action, id: this.id }));
            }
        });
    }
}
exports.MepLogSidebarExternalRecipientsListComponent = MepLogSidebarExternalRecipientsListComponent;
