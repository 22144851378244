<div class="mc-supervision-wizard-step-container">
  <mc-wizard-step-header
    class="mc-supervision-wizard-step-header"
    label="$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SETTINGS_STEP.DESCRIPTION"
  ></mc-wizard-step-header>

  <form
    [formGroup]="form"
    class="form-horizontal"
    mcDefaultLabelClass="col-sm-3"
    mcDefaultControlContainerClass="col-sm-9"
  >
    <div class="form-group">
      <mc-switch-field
        *ngIf="isEditMode"
        label="$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SETTINGS_STEP.RULE_STATE"
        [showLabel]="true"
        formControlName="isRuleEnabled"
        class="mc-supervision-wizard-step-container"
        (mousedown)="openStateChangeConfirmation($event)"
      >
      </mc-switch-field>

      <mc-field
        label="{{
          '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SETTINGS_STEP.FIELD_MONITORING_GROUP' | translate
        }}"
        helpPopoverContent="{{
          '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SETTINGS_STEP.FIELD_MONITORING_GROUP_POPOVER'
            | translate
        }}"
        required
      >
        <input type="hidden" formControlName="searchGroup" />
        <button type="button" class="btn btn-default" (click)="openSidebar()">
          {{ '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SETTINGS_STEP.SELECT_GROUP' | translate }}
        </button>
        <div class="selected-group" *ngIf="selectedGroup">
          <span class="groups-list">
            <div class="groups-name">{{ selectedGroup.description }}</div>
          </span>
        </div>
      </mc-field>
    </div>

    <div class="form-group">
      <mc-field
        label="{{
          '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SETTINGS_STEP.FIELD_MESSAGES' | translate
        }}"
        helpPopoverContent="{{
          '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SETTINGS_STEP.FIELD_MESSAGES_POPOVER' | translate
        }}"
      >
        <div>
          <div class="radio panel-half-padding-bottom" *ngFor="let option of messageRoutes">
            <label class="text-bold">
              <input
                type="radio"
                name="messageRoute"
                formControlName="messageRoute"
                [value]="option.value"
              />
              {{ option.label | translate }}
            </label>
          </div>
        </div>
      </mc-field>
    </div>
    <div class="form-group">
      <mc-field
        label="{{
          '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SETTINGS_STEP.FIELD_SCHEDULE' | translate
        }}"
        helpPopoverContent="{{
          '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SETTINGS_STEP.FIELD_SCHEDULE_POPOVER' | translate
        }}"
      >
        <div>
          <div class="radio panel-half-padding-bottom" *ngFor="let option of scheduleTypes">
            <label class="text-bold">
              <input
                type="radio"
                required
                name="scheduleType"
                formControlName="scheduleType"
                [value]="option.value"
              />
              {{ option.label | translate }}
            </label>
          </div>
        </div>
      </mc-field>
    </div>
    <div class="form-group">
      <mc-field
        label="{{
          '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SETTINGS_STEP.FIELD_SAMPLING' | translate
        }}"
        helpPopoverContent="{{
          '$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SETTINGS_STEP.FIELD_SAMPLING_POPOVER' | translate
        }}"
        required
        errorPrefix="$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.VALIDATION.SAMPLING"
      >
        <div class="sampling-container">
          <input
            type="text"
            formControlName="samplingPercentage"
            name="samplingPercentage"
            maxlength="3"
            class="form-control"
          />
          <label>%</label>
        </div>
      </mc-field>
    </div>
  </form>
</div>
