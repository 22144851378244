'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
require("./chart-data-switcher-directive"); //chart-data-switcher.directive
// html and css 
require("./chart-data-switcher.less");
require("./chart-data-switcher.tpl.html");
angular.module('chart-data-switcher', [
    'chart-data-switcher.directive'
]);
