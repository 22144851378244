"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgBlockPageSettingsUpdateAction = exports.SwgBlockPageSettingsSaveFailureAction = exports.SwgBlockPageSettingsSaveSuccessAction = exports.SwgBlockPageSettingsSaveAction = exports.SwgBlockPageSettingsGetFailureAction = exports.SwgBlockPageSettingsGetSuccessAction = exports.SwgBlockPageSettingsGetAction = exports.SwgBlockPageSettingsEnum = void 0;
var SwgBlockPageSettingsEnum;
(function (SwgBlockPageSettingsEnum) {
    SwgBlockPageSettingsEnum["SWG_BLOCK_PAGE_GET"] = "[SWG-BLOCK-PAGE] get";
    SwgBlockPageSettingsEnum["SWG_BLOCK_PAGE_GET_SUCCESS"] = "[SWG-BLOCK-PAGE] get success";
    SwgBlockPageSettingsEnum["SWG_BLOCK_PAGE_GET_FAILURE"] = "[SWG-BLOCK-PAGE] get failure";
    SwgBlockPageSettingsEnum["SWG_BLOCK_PAGE_SAVE"] = "[SWG-BLOCK-PAGE] Save";
    SwgBlockPageSettingsEnum["SWG_BLOCK_PAGE_SAVE_SUCCESS"] = "[SWG-BLOCK-PAGE] Save success";
    SwgBlockPageSettingsEnum["SWG_BLOCK_PAGE_SAVE_FAILURE"] = "[SWG-BLOCK-PAGE] Save failure";
    SwgBlockPageSettingsEnum["SWG_BLOCK_PAGE_UPDATE"] = "[SWG-BLOCK-PAGE] get Update";
})(SwgBlockPageSettingsEnum = exports.SwgBlockPageSettingsEnum || (exports.SwgBlockPageSettingsEnum = {}));
class SwgBlockPageSettingsGetAction {
    constructor() {
        this.type = SwgBlockPageSettingsEnum.SWG_BLOCK_PAGE_GET;
    }
}
exports.SwgBlockPageSettingsGetAction = SwgBlockPageSettingsGetAction;
class SwgBlockPageSettingsGetSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgBlockPageSettingsEnum.SWG_BLOCK_PAGE_GET_SUCCESS;
    }
}
exports.SwgBlockPageSettingsGetSuccessAction = SwgBlockPageSettingsGetSuccessAction;
class SwgBlockPageSettingsGetFailureAction {
    constructor() {
        this.type = SwgBlockPageSettingsEnum.SWG_BLOCK_PAGE_GET_FAILURE;
    }
}
exports.SwgBlockPageSettingsGetFailureAction = SwgBlockPageSettingsGetFailureAction;
class SwgBlockPageSettingsSaveAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgBlockPageSettingsEnum.SWG_BLOCK_PAGE_SAVE;
    }
}
exports.SwgBlockPageSettingsSaveAction = SwgBlockPageSettingsSaveAction;
class SwgBlockPageSettingsSaveSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgBlockPageSettingsEnum.SWG_BLOCK_PAGE_SAVE_SUCCESS;
    }
}
exports.SwgBlockPageSettingsSaveSuccessAction = SwgBlockPageSettingsSaveSuccessAction;
class SwgBlockPageSettingsSaveFailureAction {
    constructor() {
        this.type = SwgBlockPageSettingsEnum.SWG_BLOCK_PAGE_SAVE_FAILURE;
    }
}
exports.SwgBlockPageSettingsSaveFailureAction = SwgBlockPageSettingsSaveFailureAction;
class SwgBlockPageSettingsUpdateAction {
    constructor(payload) {
        this.payload = payload;
        this.type = SwgBlockPageSettingsEnum.SWG_BLOCK_PAGE_UPDATE;
    }
}
exports.SwgBlockPageSettingsUpdateAction = SwgBlockPageSettingsUpdateAction;
