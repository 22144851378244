<mc-modal-header
        headerTitle="{{ '$I18N_API_APPLICATIONS.MODALS.DELETE_CHANNEL_CONFIRMATION_MODAL.TITLE' | translate }}">
</mc-modal-header>

<mc-modal-body *ngIf="channel$ | async; let row">
    <p data-test="siemDeleteConfirmationBody">
        {{ '$I18N_API_APPLICATIONS.MODALS.DELETE_CHANNEL_CONFIRMATION_MODAL.BODY' | translate }}
        <strong data-test="siemDeleteConfirmationChannelName">{{ row.name }}</strong>?
    </p>
</mc-modal-body>

<mc-modal-footer *ngIf="channel$ | async; let row">
    <div data-test="siemDeleteConfirmationFooterBtns">
        <button
                type="button"
                class="btn btn-secondary"
                data-test="siemDeleteConfirmationCancelBtn"
                (click)="onCancel()">
            {{ '$I18N_COMMON_BTN_CANCEL' | translate }}
        </button>
        <mc-live-button
                overrideClasses="btn btn-danger"
                [label]="'$I18N_COMMON_BTN_DELETE' | translate"
                [isLoading]="isDeleting$ | async"
                (mcClick)="onDelete(row)">
        </mc-live-button>
    </div>
</mc-modal-footer>
