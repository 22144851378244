"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MepPolicyApiService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
class MepPolicyApiService {
    constructor(http$) {
        this.http$ = http$;
        this.latestEnabledState = new rxjs_1.Subject();
    }
    getMepPoliciesList(config) {
        return this.http$.post('/api/policy/mep/get-policies', config);
    }
    deleteMepPolicy(id) {
        return this.http$
            .post('/api/policy/mep/delete-policy', { id })
            .pipe(operators_1.map(res => res.first));
    }
    enableDisableMepPolicy(policy, status) {
        return this.http$
            .post('/api/policy/mep/update-policy-settings', {
            settingsId: policy.id,
            name: policy.name,
            description: policy.description,
            enabled: status === 'enabled'
        })
            .pipe(operators_1.map(res => {
            this.latestEnabledState.next({
                enabled: res.first.enabled,
                lastModified: res.first.lastUpdated
            });
            return res.first;
        }));
    }
    createPolicy(policy) {
        // SSW-35912: Remove empty description fields.
        if (policy.policy.description === '') {
            delete policy.policy.description;
        }
        return this.http$.post('/api/policy/mep/create-policy', policy).pipe(operators_1.map(res => {
            return res.first;
        }));
    }
    getPolicy(id) {
        return this.http$
            .post('/api/policy/mep/get-policy', { id })
            .pipe(operators_1.map(res => {
            const response = res.first;
            const rules = response.rules.map((x, index) => {
                return Object.assign(Object.assign({}, x), { order: index + 'order' });
            });
            return {
                name: response.policy.name,
                description: response.policy.description,
                id: response.policy.id,
                action: response.action,
                matchLevel: response.matchLevel,
                enabled: response.policy.enabled,
                lastUpdated: response.policy.lastUpdated
                    ? response.policy.lastUpdated
                    : response.policy.createTime,
                createTime: response.policy.createTime,
                autoReleaseTimeoutMinutes: response.autoReleaseTimeoutMinutes,
                rules
            };
        }), operators_1.catchError(error => {
            return error;
        }));
    }
    updatePolicyDefinition(payload) {
        return this.http$.post('/api/policy/mep/update-policy-definition', payload).pipe(operators_1.map(res => {
            return res.first;
        }));
    }
    updatePolicyRules(payload) {
        return this.http$.post('/api/policy/mep/update-policy-rules', payload).pipe(operators_1.map(res => {
            return res.first;
        }));
    }
    updatePolicySettings(payload) {
        // SSW-35912: Remove empty description fields.
        if (payload.data[0].description === '') {
            delete payload.data[0].description;
        }
        return this.http$.post('/api/policy/mep/update-policy-settings', payload).pipe(operators_1.map(res => {
            return res.first;
        }));
    }
}
exports.MepPolicyApiService = MepPolicyApiService;
