'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
/**
 * Created by iaggarwal on 03/03/2017.
 */
angular.module('mc-dropdown-multiselect-row.directive', [])
    .directive('mcDropdownMultiselectRow', [function () {
        return {
            restrict: 'AE',
            templateUrl: 'components/mc-dropdown/pre-defined-rows/mc-dropdown-multiselect-row/mc-dropdown-multiselect-row.tpl.html',
            transclude: true,
            replace: true,
            require: ['^mcDropdown'],
            scope: {
                displayValue: '@displayValue',
                isChecked: '=isChecked',
                isHeaderRow: '@isHeaderRow',
                isDropdownOpenOnClick: '@isDropdownOpenOnClick'
            },
            link($scope, $element, $attr, controller) {
                const mcDropdownCtrl = controller[0];
                mcDropdownCtrl.autoClose = 'outsideClick';
                $scope.applyIsOpenOnClick = applyIsOpenOnClick;
                function applyIsOpenOnClick() {
                    mcDropdownCtrl.isOpen = $scope.isDropdownOpenOnClick;
                }
            },
            controller: ['$scope', function ($scope) {
                    const self = this;
                    self.isHeaderRow = typeof $scope.isHeaderRow === 'boolean' ? $scope.isHeaderRow : $scope.isHeaderRow === 'true';
                }],
            controllerAs: 'mcDropdownMultiselectRowCtrl'
        };
    }]);
