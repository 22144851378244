"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgApplicationTableComponent = void 0;
const core_1 = require("@angular/core");
const swg_application_table_static_value_1 = require("./swg-application-table.static-value");
const models_1 = require("../../../models/models");
const swg_application_dashboard_static_value_1 = require("../../../application-control/list/component/dashboard/swg-application-dashboard.static-value");
class SwgApplicationTableComponent {
    constructor(swgApplicationControlService, translateService) {
        this.swgApplicationControlService = swgApplicationControlService;
        this.translateService = translateService;
        this.height = 'calc(100vh - 400px)';
        this.loading = false;
        this.hasEditPermission = true;
        this.apiUpdatingEdit = true;
        this.hasAppPolicyRemoveAllowEnabled = false;
        this.webApplicationRules = new core_1.EventEmitter();
        this.dateRangeChange = new core_1.EventEmitter();
        this.onShowNotification = new core_1.EventEmitter();
        this.columns = swg_application_table_static_value_1.columns;
        this.sortableColumns = ['name', 'category', 'action', 'count', 'lastAccessed', 'sanction'];
        this.dateRangeConfig = swg_application_dashboard_static_value_1.dateRangePickerConfig;
        this.selectedDateRange = this.dateRangeConfig.dateRanges[0];
        this.applicationsInView = [];
        this._applicationStatus = models_1.ApplicationStatus;
        this._applicationSanction = models_1.ApplicationSanction;
        this._sortOrder = { columnName: 'count', sortDirection: 'dsc' };
        this.searchBy = '';
        this.categoryBy = [];
        this.actionsBy = [];
        this.sanctionBy = [];
        this.searchFilters = [];
        this.isSelectedAll = false;
    }
    ngOnInit() {
        this.columns = this.type === 'sanction' ? [...swg_application_table_static_value_1.sanctionListcolumns] : [...swg_application_table_static_value_1.columns];
        if (this.hasEditPermission) {
            this.columns.push('row-actions');
        }
        this.update();
        this.applicationListParamsSubscription = this.swgApplicationControlService.applicationListParams$.subscribe((params) => {
            this.params = params;
            if (params.action === models_1.ParamsAction.FILTER) {
                if (params.dateRange) {
                    this.selectedDateRange = this.dateRangeConfig.dateRanges.find(x => x.id === params.dateRange.id);
                    this.filterDateRange.form.get('dateRanges').setValue(this.selectedDateRange);
                }
                if (params.id !== '') {
                    this.showNotification();
                }
                else {
                    this.selectedDateRange = this.dateRangeConfig.dateRanges[0];
                    this.filterDateRange.form.get('dateRanges').setValue(this.selectedDateRange);
                }
                this.setFilters();
                this.onBundleFilterChange({
                    action: [],
                    applicationcategory: [],
                    status: params.value ? [params.value] : []
                });
            }
        });
        this.setFilters();
    }
    setFilters() {
        this.searchFilters = [];
        this.searchFilters.push({
            name: 'status',
            displayName: 'Status',
            filters: ['sanctioned', 'monitored', 'none'].map(item => ({
                name: item,
                displayName: this.translateService.instant(this.getTranslateKey(item, 'status')),
                isSelected: this.isFilterSelected(item)
            }))
        });
        if (this.type !== 'sanction') {
            const filters = this.hasAppPolicyRemoveAllowEnabled
                ? ['block', 'none']
                : ['allow', 'block', 'none'];
            this.searchFilters.push({
                name: 'action',
                displayName: 'Action',
                filters: filters.map(item => ({
                    name: item,
                    displayName: this.translateService.instant(this.getTranslateKey(item, 'action'))
                }))
            });
        }
        this.searchFilters.push({
            name: 'applicationcategory',
            displayName: 'Application Category',
            filters: this.applicationCategoriesSet.map(item => ({
                name: item,
                displayName: this.translateService.instant(this.getTranslateKey(item, 'category'))
            }))
        });
    }
    getTranslateKey(key, container) {
        const keys = [
            '$18N_SWG_REPORTING',
            'TABLE',
            'FILTERS',
            'COLUMN_VALUE_FILTER_FIELD',
            container && `${container.toUpperCase()}_FILTERS`,
            key && key.toUpperCase()
        ];
        return keys.filter(n => n).join('.');
    }
    isFilterSelected(code) {
        if (this.params && this.params.action === models_1.ParamsAction.FILTER) {
            return this.params.value === code;
        }
        else {
            return false;
        }
    }
    ngOnChanges(changes) {
        const change = changes['applications'];
        if (change && !change.isFirstChange()) {
            this.update();
        }
    }
    update() {
        this.applicationsCopy = this.applications
            .filter(x => !x.deprecated)
            .map(x => {
            return Object.assign(Object.assign({}, x), { lowerCaseName: x.name.toLowerCase(), count: x.count ? x.count : 0, icon: swg_application_table_static_value_1.appIcons[x.code] ? swg_application_table_static_value_1.appIcons[x.code] : 'fa fa-window-maximize', action: x.action ? x.action : models_1.ApplicationStatus.NONE, status: x.status ? x.status : models_1.ApplicationSanction.NONE });
        });
        if (this.type === 'sanction') {
            this.sortData(this._sortOrder);
        }
        else {
            this.sortData({ columnName: 'lowerCaseName', sortDirection: 'asc' });
            this.sortByActionAndName();
        }
    }
    sortByActionAndName() {
        const blockedAlowedApps = this.applicationsCopy.filter(x => x.action === 'none' ? false : true);
        const otherApps = this.applicationsCopy.filter(x => (x.action === 'none' ? true : false));
        this.applicationsCopy = [...blockedAlowedApps, ...otherApps];
        this.updateApplicationsInView();
    }
    sortApps(apps, data) {
        const sortingColumn = data.columnName === 'name' ? 'lowerCaseName' : data.columnName;
        return apps.sort((x, y) => {
            const status = x[sortingColumn] > y[sortingColumn];
            if (data.sortDirection === 'asc') {
                return status ? 1 : -1;
            }
            else {
                return status ? -1 : 1;
            }
        });
    }
    sortData(order) {
        this._sortOrder = order;
        this.applicationsCopy = this.sortApps(this.applicationsCopy, this._sortOrder);
        this.updateApplicationsInView();
    }
    updateApplicationsInView() {
        this.applicationsInView = this.applicationsCopy.filter(x => this.applyFilter(x));
    }
    controlApplication(action) {
        this.applicationsCopy = this.applicationsCopy.map(x => {
            if (x.selected) {
                return Object.assign(Object.assign({}, x), { action, selected: false });
            }
            return x;
        });
        this.isSelectedAll = false;
        this.updateApplicationsInView();
        this.updateBlockStatus();
    }
    controlApplicationByRow(row, action) {
        this.applicationsCopy = this.applicationsCopy.map(x => {
            if (x.code === row.code) {
                return Object.assign(Object.assign({}, x), { action });
            }
            return x;
        });
        this.updateApplicationsInView();
        this.updateBlockStatus();
    }
    sanctionApplication(action) {
        if (this.apiUpdatingEdit) {
            return;
        }
        const modifiedApplications = [];
        this.applicationsCopy = this.applicationsCopy.map(x => {
            if (x.selected) {
                const app = Object.assign(Object.assign({}, x), { status: action, selected: false });
                modifiedApplications.push(app);
                return app;
            }
            return x;
        });
        this.isSelectedAll = false;
        if (modifiedApplications.length > 0) {
            this.updateApplicationsInView();
            this.updateSanctionStatus(modifiedApplications);
        }
    }
    sanctionApplicationByRow(row, action) {
        if (this.apiUpdatingEdit) {
            return;
        }
        let modifiedApplication = {};
        this.applicationsCopy = this.applicationsCopy.map(x => {
            if (x.code === row.code) {
                modifiedApplication = Object.assign(Object.assign({}, x), { status: action });
                return modifiedApplication;
            }
            return x;
        });
        this.updateApplicationsInView();
        this.updateSanctionStatus([modifiedApplication]);
    }
    updateSanctionStatus(modifiedApplications) {
        if (this.apiUpdatingEdit) {
            return;
        }
        const webApplicationRules = [];
        modifiedApplications.forEach(x => {
            if (x.status === models_1.ApplicationSanction.NONE) {
                webApplicationRules.push({ appCode: x.code, id: x.id });
            }
            else {
                webApplicationRules.push({ appCode: x.code, status: x.status, id: x.id });
            }
        });
        this.webApplicationRules.emit(webApplicationRules);
    }
    updateBlockStatus() {
        const outputArray = [];
        this.applicationsCopy.forEach(x => {
            if (x.action === models_1.ApplicationStatus.ALLOW) {
                outputArray.push({ applicationCode: x.code, action: models_1.ApplicationStatus.ALLOW });
            }
            if (x.action === models_1.ApplicationStatus.BLOCK) {
                outputArray.push({ applicationCode: x.code, action: models_1.ApplicationStatus.BLOCK });
            }
        });
        this.webApplicationRules.emit(outputArray);
    }
    applyFilter(application) {
        let catFilterPass = true;
        let textFilterPass = true;
        let actionFilterPass = true;
        let sanctionFilterPass = true;
        if (this.searchBy.length > 0) {
            textFilterPass = application.lowerCaseName.indexOf(this.searchBy.toLowerCase()) > -1;
        }
        if (textFilterPass && this.actionsBy.length > 0) {
            actionFilterPass = this.actionsBy.indexOf(application.action) > -1;
        }
        if (textFilterPass && this.sanctionBy.length > 0) {
            sanctionFilterPass = this.sanctionBy.indexOf(application.status) > -1;
        }
        if (textFilterPass && sanctionFilterPass && actionFilterPass && this.categoryBy.length > 0) {
            catFilterPass = this.intersect(this.categoryBy, application.category).length > 0;
        }
        return textFilterPass && actionFilterPass && sanctionFilterPass && catFilterPass;
    }
    onSelectingSingleItem(data) {
        data.event.stopPropagation();
        this.applicationsCopy = this.applicationsCopy.map(x => {
            if (x.code === data.application.code) {
                x['selected'] = data.event.target.checked;
            }
            return x;
        });
    }
    onSelectAll(event) {
        this.isSelectedAll = event.target.checked;
        this.toggleAll(event.target.checked);
    }
    toggleAll(selected) {
        this.applicationsInView = this.applicationsInView.map(x => {
            x['selected'] = selected;
            return x;
        });
    }
    onSearch(filters) {
        this.searchBy = filters.search;
        this.updateApplicationsInView();
    }
    onSearchClear() {
        this.searchBy = '';
        this.updateApplicationsInView();
    }
    intersect(a, b) {
        const setB = new Set(b);
        return a.filter(x => setB.has(x));
    }
    onBundleFilterChange(filters) {
        this.categoryBy = filters.applicationcategory || [];
        this.actionsBy = filters.action || [];
        this.sanctionBy = filters.status || [];
        this.updateApplicationsInView();
        if (!Object.keys(filters).length) {
            this.resetSelectedFilters();
        }
    }
    resetSelectedFilters() {
        if (this.params && this.params.action === models_1.ParamsAction.FILTER) {
            this.params.value = '';
            this.setFilters();
        }
    }
    onDateRangeFilterChange(value) {
        if (this.selectedDateRange.id === value.id) {
            return;
        }
        this.selectedDateRange = value;
        if (!value.range) {
            return;
        }
        this.dateRangeChange.emit(value);
    }
    showNotification() {
        let msg = '';
        this.translateService
            .get('$18N_SWG_REPORTING.FILTEREDBY_NOTIFICATION', {
            filterName: 'Status',
            filterValue: this.translateService.instant(this.getTranslateKey(this.params.value, 'status'))
        })
            .subscribe((response) => {
            msg = response;
        });
        this.onShowNotification.emit(msg);
    }
    ngOnDestroy() {
        if (this.applicationListParamsSubscription) {
            this.applicationListParamsSubscription.unsubscribe();
        }
    }
}
exports.SwgApplicationTableComponent = SwgApplicationTableComponent;
