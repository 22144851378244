"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServiceApplicationPipe = void 0;
class ServiceApplicationPipe {
    constructor(translateService) {
        this.translateService = translateService;
    }
    transform(input) {
        if (input === true) {
            return this.translateService.instant('$I18N_API_APPLICATIONS_SERVICE_PIPE.TRUE');
        }
        else {
            return this.translateService.instant('$I18N_API_APPLICATIONS_SERVICE_PIPE.FALSE');
        }
    }
}
exports.ServiceApplicationPipe = ServiceApplicationPipe;
