"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Log = void 0;
class Log {
    constructor(id, senderAddress, recipientAddress, subject, dateSent, status, expiry) {
        this.id = id;
        this.senderAddress = senderAddress;
        this.recipientAddress = recipientAddress;
        this.subject = subject;
        this.dateSent = dateSent;
        this.status = status;
        this.expiry = expiry;
    }
}
exports.Log = Log;
