<div class="form-group">
  <label class="col-sm-3 control-label"
    >{{ '$I18N_EMAIL_COMPOSE_ATTACHED_FILES' | translate }} ({{ attachments.length }})</label
  >
  <div class="col-sm-9">
    <mc-upload-button (files)="uploadFiles($event)"></mc-upload-button>
    <div #scroll class="mc-files-scroll">
      <ul class="list-unstyled">
        <li
          *ngFor="let file of attachments"
          class="border-bottom panel-half-padding-bottom panel-half-padding-top"
        >
          <span
            *ngIf="file.progress == null || file.progress === 100; else elseBlock"
            data-test="uploadFinish"
            class="far fa-check text-success"
          >
          </span>
          <ng-template #elseBlock
            ><span data-test="uploadInProgress" class="far fa-spinner fa-pulse  fa-fw"></span
          ></ng-template>
          <span
            >{{ file.filename }}
            <span
              class="far fa-trash-alt pull-right"
              data-test="remove"
              (click)="remove.emit(file)"
            ></span
          ></span>
        </li>
      </ul>
    </div>
  </div>
</div>
