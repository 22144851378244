"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArchiveViewerApiService = void 0;
const operators_1 = require("rxjs/operators");
class ArchiveViewerApiService {
    constructor(http) {
        this.http = http;
    }
    // Search by token
    getTokenDetail(request) {
        return this.http.post('/api/lockbox/get-token-detail', request).pipe(operators_1.map((response) => {
            return response.first;
        }));
    }
    // Search by content
    getSearchContent(request) {
        return this.http
            .post('/api/lockbox/search-content', Object.assign(Object.assign({}, request), { meta: request.meta }))
            .pipe(operators_1.map(response => {
            // const res = Object.assign(response.first, response.meta);
            return response; // return response.first;
        }));
    }
    getDeliveryDetail(id) {
        return this.http
            .post('/api/lockbox/get-delivery-detail', { id })
            .pipe(operators_1.map((response) => {
            return response.first;
        }));
    }
    createIFrame(attachment) {
        const ifrm = document.createElement('iframe');
        ifrm.setAttribute('id', 'ifrm');
        ifrm.style.display = 'none';
        document.body.appendChild(ifrm);
        ifrm.src = attachment;
    }
    prepareDownloadId(id) {
        return id.match(/[^?]*/);
    }
    getDownloadXml(requestId) {
        // Download = /lockbox/get-file/{id}
        return this.http.post('/download/get-file-link', {
            url: '/api/lockbox/get-file/' + this.prepareDownloadId(requestId)
        });
    }
    getDownloadAllXml(requestId) {
        // Download All = /lockbox/get-all/{id}
        return this.http.post('/download/get-file-link', {
            url: '/api/lockbox/get-all/' + this.prepareDownloadId(requestId)
        });
    }
    ngOnDestroy() {
        this.callBackSubscription.unsubscribe();
    }
}
exports.ArchiveViewerApiService = ArchiveViewerApiService;
