'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
document.createElement('mc-favourite');
angular.module('favourite.directive', [])
    .directive('mcFavourite', [function () {
        return {
            restrict: 'AE',
            templateUrl: 'components/favourite/favourite.tpl.html',
            scope: {}
        };
    }]);
