"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COLUMNS = void 0;
exports.COLUMNS = [
    'code',
    'type',
    'modified',
    'identified',
    'successful',
    'failed',
    'restored'
];
