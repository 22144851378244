"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.minLengthForSearchField = exports.maxLengthForSearchField = exports.columnsAlwaysVisible = exports.searchByFields = exports.columns = void 0;
exports.columns = [
    'name',
    'description',
    'product',
    'provider',
    'status',
    'created',
    'right-column'
];
const searchByPrefix = '$I18N_CONNECTORS_TABLE.FILTERS.SEARCH_BY_FILTER_FIELD';
exports.searchByFields = [
    {
        label: `${searchByPrefix}.ALL`,
        value: 'all'
    },
    {
        label: `${searchByPrefix}.NAME`,
        value: 'name'
    },
    {
        label: `${searchByPrefix}.DESCRIPTION`,
        value: 'description'
    }
];
exports.columnsAlwaysVisible = ['name'];
exports.maxLengthForSearchField = 256;
exports.minLengthForSearchField = 3;
