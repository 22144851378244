<div class="mc-custom-domain-block-wizard-step-container">
  <div class="mc-detailed-list">
    <mc-wizard-step-header
      class="mc-supervision-wizard-step-header"
      label="$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.SUMMARY_STEP.DESCRIPTION"
    >
    <h5>{{ '$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.SUMMARY_STEP.SUB_DESCRIPTION' | translate }}</h5>
    </mc-wizard-step-header>
    <div class="mc-detailed-list">
      <div class="row mc-detailed-list-row">
        <div class="col-xs-2 mc-detailed-list-label">
          {{ '$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.SUMMARY_STEP.FIELD_NAME' | translate }}
        </div>
        <div class="mc-detailed-list-column">{{ getStepDataById(1).name?.trim() }}</div>
      </div>

      <div class="row mc-detailed-list-row">
        <div class="col-xs-2 mc-detailed-list-label">
          {{ '$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.SUMMARY_STEP.FIELD_DESCRIPTION' | translate }}
        </div>
        <div class="mc-detailed-list-column">{{ getStepDataById(1).description?.trim() }}</div>
      </div>

      <div class="row mc-detailed-list-row">
        <div class="col-xs-2 mc-detailed-list-label">
          {{ '$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.SUMMARY_STEP.FIELD_ACTION' | translate }}
        </div>
        <div class="mc-detailed-list-column">
          <div class="mc-detailed-list-column-upperValue">
            {{ getActionLabel(getStepDataById(2).action) | translate  }}
          </div>
          <div class="value-content" *ngIf="getStepDataById(2).redirectToUser">{{ getStepDataById(2).redirectToUser }}</div>
          <div class="value-content" *ngIf="getStepDataById(2).redirectToGroup">{{ getStepDataById(2).redirectToGroup?.description }}</div>
        </div>
      </div>
      <div class="row mc-detailed-list-row">
        <div class="col-xs-2 mc-detailed-list-label">
          {{ '$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.SUMMARY_STEP.FIELD_NOTIFY' | translate }}
        </div>
        <div class="mc-detailed-list-column">{{ 
          getStepDataById(2).notify ?   
          ('$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.SUMMARY_STEP.ENABLED' | translate) :
          ('$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.SUMMARY_STEP.DISABLED' | translate)
        }}</div>
      </div>

      <div class="row mc-detailed-list-row notify-groups" *ngIf="getStepDataById(2).notifyGroups?.length">
        <div class="col-xs-2 mc-detailed-list-label">
          {{ '$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.SUMMARY_STEP.FIELD_NOTIFICATIONS' | translate }}
        </div>
        <div class="mc-detailed-list-column">
          <div class="mc-detailed-list-column-upperValue">
            {{ '$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.ACTIONS_STEP.ACTION.GROUP' | translate  }}
          </div>
          <div class="value-content">{{getStepDataById(2).notifyGroups[0].description}}</div>
        </div>
      </div>

      <div class="row mc-detailed-list-row">
        <div class="col-xs-2 mc-detailed-list-label">
          {{ '$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.SUMMARY_STEP.FIELD_APPLIES_TO' | translate }}
        </div>
        <div class="mc-detailed-list-column">
          <div class="mc-detailed-list-column-upperValue">
            {{ '$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.APPLIES_TO_STEP.APPLIES_TO.'
            + getStepDataById(3).appliesTo?.toUpperCase() | translate }}
          </div>
          <div class="value-content" *ngIf="getStepDataById(3).profileGroup">{{getStepDataById(3).profileGroup.description}}</div>
        </div>
      </div>

      <div class="row mc-detailed-list-row status-switch">
        <div class="col-xs-2 mc-detailed-list-label">
          {{ '$I18N_CUSTOM_DOMAIN_BLOCK_WIZARD.POLICY.SUMMARY_STEP.FIELD_STATUS' | translate }}
        </div>
        <form [formGroup]="form">
          <mc-switch-field [showLabel]="false" formControlName="enabled">
          </mc-switch-field>
        </form>
      </div>
    </div>

  </div>
</div>