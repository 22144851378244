"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getIsContentOpening = exports.getHasCreateError = exports.getCreateFail = exports.getCreateSnapshotDetails = exports.getIsRequestingCreate = exports.reducer = exports.initialSate = exports.CreateErrors = void 0;
const actions_1 = require("../actions");
var CreateErrors;
(function (CreateErrors) {
    CreateErrors["ERR_SNAPSHOT_ALREADY_EXISTS"] = "ERR_SNAPSHOT_ALREADY_EXISTS";
    CreateErrors["ERR_SNAPSHOT_CREATION"] = "ERR_SNAPSHOT_CREATION";
})(CreateErrors = exports.CreateErrors || (exports.CreateErrors = {}));
exports.initialSate = {
    create: {
        isRequesting: false,
        fail: undefined,
        hasCreateError: false,
        duplicateSnapshot: false,
        snapshotDetails: {}
    },
    isContentOpening: false
};
function reducer(state = exports.initialSate, action) {
    switch (action.type) {
        case actions_1.fromSnapshot.CREATE:
            return Object.assign(Object.assign({}, state), { create: Object.assign(Object.assign({}, state.create), { isRequesting: true }) });
        case actions_1.fromSnapshot.CREATE_SUCCESS:
            return Object.assign(Object.assign({}, state), { create: Object.assign(Object.assign({}, state.create), { isRequesting: false, snapshotDetails: action.payload, hasCreateError: false, fail: undefined }) });
        case actions_1.fromSnapshot.CREATE_FAIL:
            return Object.assign(Object.assign({}, state), { create: Object.assign(Object.assign({}, state.create), { isRequesting: false, fail: action.payload, hasCreateError: true }) });
        case actions_1.fromSnapshot.OPEN_MESSAGE_CONTENT:
            return Object.assign(Object.assign({}, state), { isContentOpening: true });
        case actions_1.fromSnapshot.OPEN_MESSAGE_CONTENT_SUCCESS:
            return Object.assign(Object.assign({}, state), { isContentOpening: false });
        case actions_1.fromSnapshot.OPEN_CONTACT_CONTENT:
            return Object.assign(Object.assign({}, state), { isContentOpening: true });
        case actions_1.fromSnapshot.OPEN_CONTACT_CONTENT_SUCCESS:
            return Object.assign(Object.assign({}, state), { isContentOpening: false });
        case actions_1.fromSnapshot.OPEN_CALENDAR_CONTENT:
            return Object.assign(Object.assign({}, state), { isContentOpening: true });
        case actions_1.fromSnapshot.OPEN_CALENDAR_CONTENT_SUCCESS:
            return Object.assign(Object.assign({}, state), { isContentOpening: false });
        case actions_1.fromSnapshot.OPEN_EXPORT:
        case actions_1.fromSnapshot.OPEN_EXPORT_SUCCESS:
        case actions_1.fromSnapshot.OPEN_RESTORE:
        case actions_1.fromSnapshot.OPEN_RESTORE_SUCCESS:
            return state;
        case actions_1.fromSnapshot.CLEAR_STORE:
            return Object.assign(Object.assign({}, state), exports.initialSate);
        default:
            return state;
    }
}
exports.reducer = reducer;
const getIsRequestingCreate = (state) => state.create.isRequesting;
exports.getIsRequestingCreate = getIsRequestingCreate;
const getCreateSnapshotDetails = (state) => state.create.snapshotDetails;
exports.getCreateSnapshotDetails = getCreateSnapshotDetails;
const getCreateFail = (state) => state.create.fail;
exports.getCreateFail = getCreateFail;
const getHasCreateError = (state) => state.create.hasCreateError;
exports.getHasCreateError = getHasCreateError;
const getIsContentOpening = (state) => state.isContentOpening;
exports.getIsContentOpening = getIsContentOpening;
