"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEditPolicyId = exports.getPolicy = exports.getPolicyId = exports.isLoading = exports.getDeleteSelectedPolicy = exports.getSelectedPolicy = exports.getWizardFlowType = exports.getOutboundAdministratorGroup = exports.getApPolicyOutbound = exports.getJournalAdministratorGroup = exports.getGroupsDisplayText = exports.getAdministratorGroups = exports.getPolicyAppliesTo = exports.getApPolicy = exports.getApPolicyJournal = exports.getApPolicyInbound = exports.getApAttributes = exports.getApIpAddressDisplayText = exports.getApIpAddressesss = exports.getGroupToIndex = exports.getGroupFromIndex = exports.getAppliesTo = exports.getAppliesFrom = exports.getApPolicyDesc = exports.reducer = exports.initialState = void 0;
const policy_actions_1 = require("../../actions/policy.actions");
const policy_list_model_1 = require("../../models/policy-list.model");
exports.initialState = {
    apPolicy: {
        appolicyDesc: {
            description: '',
            apDuration: { id: 'perpetual', label: 'perpetual' },
            startDate: '',
            endDate: ''
        },
        appolicyInbound: {
            ignoreSignedMessages: false,
            preEmptiveFailureAction: policy_list_model_1.PreEmptiveFailureActionOption.ADMINHOLD,
            internalForward: true,
            removeActiveContent: false,
            action: policy_list_model_1.ApConfigurationType.SANDBOX,
            safeDocFormat: policy_list_model_1.DocumentFormatOption.PDF,
            safeSpreadsheetFormat: policy_list_model_1.SpreadsheetsFormatOption.HTML,
            notifyInternalSender: false,
            notifyInternalRecipient: false,
            notifyExternalSender: false,
            groupsDisplayText: ''
        },
        appolicyJournal: {
            enableJournalCheck: false,
            userMailboxAction: policy_list_model_1.UserMailboxActionOption.NONE,
            userMailboxFallbackAction: policy_list_model_1.UserMailboxFallbackActionOption.NONE,
            internalSenderJournal: false,
            internalRecipientJournal: false
        },
        appolicyOutbound: {
            enableOutboundCheck: false,
            senderMailboxAction: policy_list_model_1.SenderMailboxActionOption.NONE,
            senderMailboxFallbackAction: policy_list_model_1.SenderMailboxFallbackActionOption.NONE,
            gatewayAction: policy_list_model_1.GatewayActionOption.HOLD,
            gatewayFallbackAction: policy_list_model_1.GatewayFallbackActionOption.HOLD,
            internalSenderOutbound: false
        },
        apPolicyAppliesToStep: {
            attachmentPolicyRule: [
                {
                    apAddressesBasedOn: policy_list_model_1.AddressBasedOption.ENVELOPEANDHEADERADDRESSES,
                    apAppliesFrom: policy_list_model_1.AppliesFrom.EVERYONE,
                    apAppliesTo: policy_list_model_1.AppliesTo.EVERYONE,
                    apEmailFrom: '',
                    apEmailTo: '',
                    apDomainFrom: ''
                }
            ],
            override: false,
            apBidirectional: false,
            apIpRange: '',
            ipApAddresses: [],
            ipApAddressDisplayText: ''
        },
        appolicySummary: {
            PolicyRuleStatus: true
        }
    },
    attributesAp: [],
    isLoading: false,
    policyId: '',
    apiProcessingInProgress: false,
    wizardFlowType: policy_list_model_1.ApPolicyWizardFlowType.CREATE
};
function reducer(state = exports.initialState, action) {
    switch (action.type) {
        case policy_actions_1.ApPolicyCreateTypesEnum.NAVIGATE_TO_ATTACHMENT_PROTECTION_POLICY_WIZARD:
            return Object.assign(Object.assign({}, state), { wizardFlowType: action.payload });
        case policy_actions_1.ApPolicyCreateTypesEnum.ATTACHMENT_PROTECTION_CLEAR_POLICY:
            return Object.assign({}, exports.initialState);
        case policy_actions_1.ApPolicyCreateTypesEnum.CREATE_ATTACHMENT_PROTECTION_POLICY_SUCCESS:
            return Object.assign(Object.assign({}, exports.initialState), { apiProcessingInProgress: false });
        case policy_actions_1.ApPolicyCreateTypesEnum.CREATE_ATTACHMENT_PROTECTION_POLICY_FAIL:
            return Object.assign(Object.assign({}, state), { apiProcessingInProgress: false });
        case policy_actions_1.ApPolicyCreateTypesEnum.UPDATE_ATTACHMENT_PROTECTION_POLICY_FAIL:
        case policy_actions_1.ApPolicyCreateTypesEnum.UPDATE_ATTACHMENT_PROTECTION_POLICY_SUCCESS:
            return Object.assign(Object.assign({}, exports.initialState), { apiProcessingInProgress: false });
            return Object.assign(Object.assign({}, state), { apiProcessingInProgress: false });
        case policy_actions_1.ApPolicyCreateTypesEnum.AP_UPDATE_POLICY_DETAILS_STEP:
            return Object.assign(Object.assign({}, state), { apPolicy: Object.assign(Object.assign({}, state.apPolicy), { appolicyDesc: Object.assign(Object.assign({}, state.apPolicy.appolicyDesc), { description: action.payload.description, apDuration: action.payload.apDuration, startDate: action.payload.startDate, endDate: action.payload.endDate }) }) });
        case policy_actions_1.ApPolicyCreateTypesEnum.LOAD_ID_POLICY_DETAILS:
            return Object.assign(Object.assign({}, state), { editPolicyId: action.payload });
        case policy_actions_1.ApPolicyCreateTypesEnum.AP_UPDATE_POLICY_INBOUND_STEP:
            return Object.assign(Object.assign({}, state), { apPolicy: Object.assign(Object.assign({}, state.apPolicy), { appolicyInbound: Object.assign(Object.assign({}, state.apPolicy.appolicyInbound), { action: action.payload.action, internalForward: action.payload.internalForward, removeActiveContent: action.payload.removeActiveContent, ignoreSignedMessages: action.payload.ignoreSignedMessages, preEmptiveFailureAction: action.payload.preEmptiveFailureAction, safeDocFormat: action.payload.safeDocFormat, safeSpreadsheetFormat: action.payload.safeSpreadsheetFormat, notifyInternalSender: action.payload.notifyInternalSender, notifyInternalRecipient: action.payload.notifyInternalRecipient, notifyExternalSender: action.payload.notifyExternalSender }) }) });
        case policy_actions_1.ApPolicyCreateTypesEnum.AP_UPDATE_POLICY_JOURNAL_STEP:
            return Object.assign(Object.assign({}, state), { apPolicy: Object.assign(Object.assign({}, state.apPolicy), { appolicyJournal: Object.assign(Object.assign({}, state.apPolicy.appolicyJournal), { enableJournalCheck: action.payload.enableJournalCheck, userMailboxAction: action.payload.userMailboxAction, userMailboxFallbackAction: action.payload.userMailboxFallbackAction, internalSenderJournal: action.payload.internalSenderJournal, internalRecipientJournal: action.payload.internalRecipientJournal }) }) });
        case policy_actions_1.ApPolicyCreateTypesEnum.AP_UPDATE_POLICY_OUTBOUND_STEP:
            return Object.assign(Object.assign({}, state), { apPolicy: Object.assign(Object.assign({}, state.apPolicy), { appolicyOutbound: Object.assign(Object.assign({}, state.apPolicy.appolicyOutbound), { enableOutboundCheck: action.payload.enableOutboundCheck, gatewayAction: action.payload.gatewayAction, gatewayFallbackAction: action.payload.gatewayFallbackAction, internalSenderOutbound: action.payload.internalSenderOutbound, senderMailboxAction: action.payload.senderMailboxAction, senderMailboxFallbackAction: action.payload.senderMailboxFallbackAction }) }) });
        case policy_actions_1.ApPolicyCreateTypesEnum.AP_UPDATE_POLICY_SUMMARY_STEP:
            return Object.assign(Object.assign({}, state), { apPolicy: Object.assign(Object.assign({}, state.apPolicy), { appolicySummary: Object.assign(Object.assign({}, state.apPolicy.appolicySummary), { PolicyRuleStatus: action.payload.PolicyRuleStatus }) }) });
        case policy_actions_1.ApPolicyCreateTypesEnum.ATTACHMENT_PROTECTION_CREATE_POLICY:
            return Object.assign(Object.assign({}, state), { apiProcessingInProgress: true });
        case policy_actions_1.ApPolicyCreateTypesEnum.ATTACHMENT_PROTECTION_UPDATE_POLICY:
            return Object.assign(Object.assign({}, state), { apiProcessingInProgress: true });
        case policy_actions_1.ApPolicyCreateTypesEnum.UPDATE_POLICY_RULE:
            return Object.assign(Object.assign({}, state), { apPolicy: Object.assign(Object.assign({}, state.apPolicy), { apPolicyAppliesToStep: Object.assign(Object.assign({}, state.apPolicy.apPolicyAppliesToStep), { attachmentPolicyRule: Object.assign(Object.assign({}, state.apPolicy.apPolicyAppliesToStep.attachmentPolicyRule[action.payload]), { apAddressesBasedOn: policy_list_model_1.AddressBasedOption.ENVELOPEANDHEADERADDRESSES, apAppliesFrom: policy_list_model_1.AppliesFrom.EVERYONE, apAppliesTo: policy_list_model_1.AppliesTo.EVERYONE, apEmailFrom: '', apEmailTo: '', apDomainFrom: '', apDomainTo: '' }) }) }) });
        case policy_actions_1.ApPolicyCreateTypesEnum.AP_UPDATE_POLICY_APPLIES_TO_STEP:
            return Object.assign(Object.assign({}, state), { apPolicy: Object.assign(Object.assign({}, state.apPolicy), { apPolicyAppliesToStep: Object.assign(Object.assign({}, state.apPolicy.apPolicyAppliesToStep), { attachmentPolicyRule: [...action.payload.attachmentPolicyRule], override: action.payload.override, apBidirectional: action.payload.apBidirectional, apIpRange: action.payload.apIpRange }) }) });
        case policy_actions_1.ApPolicyCreateTypesEnum.ADD_ADMINISTRATOR_GROUP:
            return Object.assign(Object.assign({}, state), { apPolicy: Object.assign(Object.assign({}, state.apPolicy), { appolicyInbound: Object.assign(Object.assign({}, state.apPolicy.appolicyInbound), { groupsInbound: action.payload }) }) });
        case policy_actions_1.ApPolicyCreateTypesEnum.REMOVE_ADMINISTRATOR_GROUP:
            return Object.assign(Object.assign({}, state), { apPolicy: Object.assign(Object.assign({}, state.apPolicy), { appolicyInbound: Object.assign(Object.assign({}, state.apPolicy.appolicyInbound), { groupsInbound: '' }) }) });
        case policy_actions_1.ApPolicyCreateTypesEnum.ADD_ADMINISTRATOR_JOURNAL_GROUP:
            return Object.assign(Object.assign({}, state), { apPolicy: Object.assign(Object.assign({}, state.apPolicy), { appolicyJournal: Object.assign(Object.assign({}, state.apPolicy.appolicyJournal), { notifyGroupJournal: action.payload }) }) });
        case policy_actions_1.ApPolicyCreateTypesEnum.REMOVE_JOURNAL_GROUP:
            return Object.assign(Object.assign({}, state), { apPolicy: Object.assign(Object.assign({}, state.apPolicy), { appolicyJournal: Object.assign(Object.assign({}, state.apPolicy.appolicyJournal), { notifyGroupJournal: '' }) }) });
        case policy_actions_1.ApPolicyCreateTypesEnum.ADD_ADMINISTRATOR_OUTBOUND_GROUP:
            return Object.assign(Object.assign({}, state), { apPolicy: Object.assign(Object.assign({}, state.apPolicy), { appolicyOutbound: Object.assign(Object.assign({}, state.apPolicy.appolicyOutbound), { notifyGroupOutbound: action.payload }) }) });
        case policy_actions_1.ApPolicyCreateTypesEnum.REMOVE_ADMINISTRATOR_OUTBOUND_GROUP:
            return Object.assign(Object.assign({}, state), { apPolicy: Object.assign(Object.assign({}, state.apPolicy), { appolicyOutbound: Object.assign(Object.assign({}, state.apPolicy.appolicyOutbound), { notifyGroupOutbound: '' }) }) });
        case policy_actions_1.ApPolicyCreateTypesEnum.ADD_APPLIES_TO_GROUP:
            return Object.assign(Object.assign({}, state), { apPolicy: Object.assign(Object.assign({}, state.apPolicy), { apPolicyAppliesToStep: Object.assign(Object.assign({}, state.apPolicy.apPolicyAppliesToStep), { groupsFrom: action.value === 'from'
                            ? action.payload
                            : state.apPolicy.apPolicyAppliesToStep.groupsFrom, indexfrom: action.value === 'from'
                            ? action.index
                            : state.apPolicy.apPolicyAppliesToStep.indexfrom, groupsTo: action.value === 'to'
                            ? action.payload
                            : state.apPolicy.apPolicyAppliesToStep.groupsTo, indexto: action.value === 'to' ? action.index : state.apPolicy.apPolicyAppliesToStep.indexto }) }) });
        case policy_actions_1.ApPolicyCreateTypesEnum.REMOVE_APPLIES_TO_GROUP:
            return Object.assign(Object.assign({}, state), { apPolicy: Object.assign(Object.assign({}, state.apPolicy), { apPolicyAppliesToStep: {
                        groupsFrom: action.value === 'from' ? '' : state.apPolicy.apPolicyAppliesToStep.groupsFrom,
                        groupsTo: action.value === 'to' ? '' : state.apPolicy.apPolicyAppliesToStep.groupsTo
                    } }) });
        case policy_actions_1.ApPolicyCreateTypesEnum.ADD_IP_ADDRESS_AP:
            return Object.assign(Object.assign({}, state), { apPolicy: Object.assign(Object.assign({}, state.apPolicy), { apPolicyAppliesToStep: Object.assign(Object.assign({}, state.apPolicy.apPolicyAppliesToStep), { ipApAddresses: [...state.apPolicy.apPolicyAppliesToStep.ipApAddresses, action.payload], ipAddressesDisplayText: [...state.apPolicy.apPolicyAppliesToStep.ipApAddresses]
                            .map(ipAddress => ipAddress.apIpRange)
                            .join(', ') }) }) });
        case policy_actions_1.ApPolicyCreateTypesEnum.AP_ATTRIBUTE_APPLIES_TO_STEP_POPULATE:
            return Object.assign(Object.assign({}, state), { attributesAp: [...state.attributesAp, action.payload] });
        case policy_actions_1.ApPolicyCreateTypesEnum.CLEAR_IP_ADDRESSES_AP:
            return Object.assign(Object.assign({}, state), { apPolicy: Object.assign(Object.assign({}, state.apPolicy), { apPolicyAppliesToStep: Object.assign(Object.assign({}, state.apPolicy.apPolicyAppliesToStep), { ipApAddresses: [], ipApAddressDisplayText: '' }) }) });
        case policy_actions_1.ApPolicyCreateTypesEnum.REMOVE_IP_ADDRESS_AP:
            const ipAddresses = state.apPolicy.apPolicyAppliesToStep.ipApAddresses.filter(ip => ip.apIpRange !== action.payload.apIpRange);
            return Object.assign(Object.assign({}, state), { apPolicy: Object.assign(Object.assign({}, state.apPolicy), { apPolicyAppliesToStep: Object.assign(Object.assign({}, state.apPolicy.apPolicyAppliesToStep), { ipApAddresses: [...ipAddresses], ipApAddressDisplayText: ipAddresses.map(ipAddress => ipAddress.apIpRange).join(', ') }) }) });
        case policy_actions_1.ApPolicyCreateTypesEnum.OPEN_AP_POLICY_SIDEBAR:
            return Object.assign(Object.assign({}, state), { selectedPolicy: action.payload, isLoading: true, policyId: action.payload.id });
        case policy_actions_1.ApPolicyCreateTypesEnum.CLOSE_AP_POLICY_SIDEBAR:
            return Object.assign(Object.assign({}, state), { selectedPolicy: null });
        case policy_actions_1.ApPolicyCreateTypesEnum.AP_POLICY_DELETE_MODAL_OPEN:
            return Object.assign(Object.assign({}, state), { deletePolicySelected: action.payload, isDeleteLoading: true });
        case policy_actions_1.ApPolicyCreateTypesEnum.POPULATE_IP_ADDRESS_AP:
            return Object.assign(Object.assign({}, state), { apPolicy: Object.assign(Object.assign({}, state.apPolicy), { apPolicyAppliesToStep: Object.assign(Object.assign({}, state.apPolicy.apPolicyAppliesToStep), { ipApAddresses: [...action.payload] }) }) });
        case policy_actions_1.ApPolicyCreateTypesEnum.AP_POLICY_DELETE_MODAL_CLOSE:
            return Object.assign(Object.assign({}, state), { deletePolicySelected: null });
        case policy_actions_1.ApPolicyCreateTypesEnum.LOAD_AP_POLICY_DETAILS:
            return Object.assign(Object.assign({}, state), { isLoading: true });
        case policy_actions_1.ApPolicyCreateTypesEnum.LOAD_AP_POLICY_DETAILS_SUCCESS:
            return Object.assign(Object.assign({}, state), { isLoading: false, policy: action.payload });
        case policy_actions_1.ApPolicyCreateTypesEnum.LOAD_AP_POLICY_DETAILS_FAIL:
            return Object.assign(Object.assign({}, state), { isLoading: false });
        default:
            return state;
    }
}
exports.reducer = reducer;
const getApPolicyDesc = (state) => state.apPolicy.appolicyDesc;
exports.getApPolicyDesc = getApPolicyDesc;
const getAppliesFrom = (state) => state.apPolicy.apPolicyAppliesToStep.groupsFrom;
exports.getAppliesFrom = getAppliesFrom;
const getAppliesTo = (state) => state.apPolicy.apPolicyAppliesToStep.groupsTo;
exports.getAppliesTo = getAppliesTo;
const getGroupFromIndex = (state) => state.apPolicy.apPolicyAppliesToStep.indexfrom;
exports.getGroupFromIndex = getGroupFromIndex;
const getGroupToIndex = (state) => state.apPolicy.apPolicyAppliesToStep.indexto;
exports.getGroupToIndex = getGroupToIndex;
const getApIpAddressesss = (state) => state.apPolicy.apPolicyAppliesToStep.ipApAddresses;
exports.getApIpAddressesss = getApIpAddressesss;
const getApIpAddressDisplayText = (state) => state.apPolicy.apPolicyAppliesToStep.ipApAddressDisplayText;
exports.getApIpAddressDisplayText = getApIpAddressDisplayText;
const getApAttributes = (state) => state.attributesAp;
exports.getApAttributes = getApAttributes;
const getApPolicyInbound = (state) => state.apPolicy.appolicyInbound;
exports.getApPolicyInbound = getApPolicyInbound;
const getApPolicyJournal = (state) => state.apPolicy.appolicyJournal;
exports.getApPolicyJournal = getApPolicyJournal;
const getApPolicy = (state) => state.apPolicy;
exports.getApPolicy = getApPolicy;
const getPolicyAppliesTo = (state) => state.apPolicy.apPolicyAppliesToStep;
exports.getPolicyAppliesTo = getPolicyAppliesTo;
const getAdministratorGroups = (state) => state.apPolicy.appolicyInbound.groupsInbound;
exports.getAdministratorGroups = getAdministratorGroups;
const getGroupsDisplayText = (state) => state.apPolicy.appolicyInbound.groupsDisplayText;
exports.getGroupsDisplayText = getGroupsDisplayText;
const getJournalAdministratorGroup = (state) => state.apPolicy.appolicyJournal.notifyGroupJournal;
exports.getJournalAdministratorGroup = getJournalAdministratorGroup;
const getApPolicyOutbound = (state) => state.apPolicy.appolicyOutbound;
exports.getApPolicyOutbound = getApPolicyOutbound;
const getOutboundAdministratorGroup = (state) => state.apPolicy.appolicyOutbound.notifyGroupOutbound;
exports.getOutboundAdministratorGroup = getOutboundAdministratorGroup;
const getWizardFlowType = (state) => state.wizardFlowType;
exports.getWizardFlowType = getWizardFlowType;
const getSelectedPolicy = (state) => state.selectedPolicy;
exports.getSelectedPolicy = getSelectedPolicy;
const getDeleteSelectedPolicy = (state) => state.deletePolicySelected;
exports.getDeleteSelectedPolicy = getDeleteSelectedPolicy;
const isLoading = (state) => state.isLoading;
exports.isLoading = isLoading;
const getPolicyId = (state) => state.policyId;
exports.getPolicyId = getPolicyId;
const getPolicy = (state) => state.policy;
exports.getPolicy = getPolicy;
const getEditPolicyId = (state) => state.editPolicyId;
exports.getEditPolicyId = getEditPolicyId;
