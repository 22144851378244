"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwgEndpointsApiService = void 0;
const operators_1 = require("rxjs/operators");
class SwgEndpointsApiService {
    constructor(http) {
        this.http = http;
    }
    getEndpointsAuthKey(isCreateAuthKey) {
        let endpoint = 'create-auth-token';
        if (!isCreateAuthKey) {
            endpoint = 'get-auth-token';
        }
        return this.http
            .post(`/api/swg/${endpoint}`, undefined)
            .pipe(operators_1.map((response) => response.first));
    }
    deleteEndpointAuthKey() {
        return this.http
            .post('/api/swg/delete-auth-token', undefined)
            .pipe(operators_1.map((response) => response.first.success === true));
    }
    downloadEndpointAuthKey() {
        const fileUrl = '/api/swg/download-auth-token?forceDownload=true';
        return this.http
            .post('/download/get-file-link', { url: fileUrl })
            .pipe(operators_1.map((response) => response.body));
    }
    downloadEndpointInstallBinary(platform) {
        const fileUrl = `/api/swg/get-binary/${platform}?forceDownload=true`;
        return this.http
            .post('/download/get-file-link', { url: fileUrl })
            .pipe(operators_1.map((response) => response.body));
    }
    getEndpointSettings() {
        return this.http
            .post('/api/swg/get-web-endpoint-settings', undefined)
            .pipe(operators_1.map((response) => response.first));
    }
    updateEndpointSettings(settings) {
        return this.http
            .post('/api/swg/update-web-endpoint-settings', settings)
            .pipe(operators_1.map((response) => response.first));
    }
    getTamperProtectedPassword(isGeneratePassword) {
        let endpoint = 'set-anti-tamper';
        if (!isGeneratePassword) {
            endpoint = 'get-anti-tamper-detail';
        }
        return this.http
            .post(`/api/swg/${endpoint}`, undefined)
            .pipe(operators_1.map((response) => response.first));
    }
    getUninstallPassword(isGeneratePassword) {
        let endpoint = 'set-uninstall-password';
        if (!isGeneratePassword) {
            endpoint = 'get-uninstall-password';
        }
        return this.http
            .post(`/api/swg/${endpoint}`, undefined)
            .pipe(operators_1.map((response) => response.first));
    }
}
exports.SwgEndpointsApiService = SwgEndpointsApiService;
