<div class="mc-identity-policy-details-block mc-identity-policy-steps-container">

  <mc-wizard-step-header class="mc-identity-wizard-step-header"
                         label="$I18N_IDENTITY_POLICY_WIZARD_SUMMARY_STEP.TITLE">
  </mc-wizard-step-header>

  <div class="mc-detailed-list mc-detailed-list-two-columns">
    <h5>{{ '$I18N_IDENTITY_POLICY_WIZARD_SUMMARY_STEP.DETAILS.HEADER' | translate }}</h5>
    <div class="row mc-detailed-list-row">
      <div class="col-sm-4 mc-detailed-list-label">{{'$I18N_IDENTITY_POLICY_WIZARD_SUMMARY_STEP.DETAILS.NAME' | translate}}</div>
      <div class="mc-detailed-list-column">{{ (detailsStep$ | async).name }}</div>
    </div>


    <div class="row mc-detailed-list-row">
      <div class="col-sm-4 mc-detailed-list-label">{{'$I18N_IDENTITY_POLICY_WIZARD_SUMMARY_STEP.DETAILS.DESCRIPTION' | translate}}
      </div>
      <div class="mc-detailed-list-column">{{ (detailsStep$ | async).description }}</div>
    </div>
  </div>


  <div class="mc-detailed-list mc-detailed-list-two-columns">
    <h5>{{ '$I18N_IDENTITY_POLICY_WIZARD_SUMMARY_STEP.AUTHENTICATION_METHODS.HEADER' | translate }}</h5>
    <div class="row mc-detailed-list-row">
      <div class="col-sm-4 mc-detailed-list-label">{{'$I18N_IDENTITY_POLICY_WIZARD_SUMMARY_STEP.AUTHENTICATION_METHODS.DOMAIN' | translate}}</div>
      <div class="mc-detailed-list-column">{{ (authenticationMethodsStep$ | async).domain | mcIdentityDelegatedAuthOption }}</div>
    </div>

    <div class="row mc-detailed-list-row">
      <div class="col-sm-4 mc-detailed-list-label">{{'$I18N_IDENTITY_POLICY_WIZARD_SUMMARY_STEP.AUTHENTICATION_METHODS.CLOUD_AUTHENTICATION' | translate}}</div>
      <div class="mc-detailed-list-column">{{ (authenticationMethodsStep$ | async).cloudAuthentication | mcIdentityCloudPasswordOption }}</div>
    </div>

    <div *ngIf="(authenticationMethodsStep$ | async).domain === ewsBasicOption"
         class="row mc-detailed-list-row">
      <div class="col-sm-4 mc-detailed-list-label">{{'$I18N_IDENTITY_POLICY_WIZARD_SUMMARY_STEP.AUTHENTICATION_METHODS.CAS_SERVER' | translate}}</div>
      <div class="mc-detailed-list-column">{{ (authenticationMethodsStep$ | async).casServer }}</div>
    </div>

    <div *ngIf="(authenticationMethodsStep$ | async).domain === ewsBasicOption"
         class="row mc-detailed-list-row">
      <div class="col-sm-4 mc-detailed-list-label">{{'$I18N_IDENTITY_POLICY_WIZARD_SUMMARY_STEP.AUTHENTICATION_METHODS.ALTERNATE_DOMAIN_SUFFIX' | translate}}</div>
      <div class="mc-detailed-list-column">{{ (authenticationMethodsStep$ | async).alternateDomainSuffix }}</div>
    </div>

    <div class="row mc-detailed-list-row">
      <div class="col-sm-4 mc-detailed-list-label">{{'$I18N_IDENTITY_POLICY_WIZARD_SUMMARY_STEP.AUTHENTICATION_METHODS.MIMEAUTH' | translate}}</div>
      <div class="mc-detailed-list-column">{{ formatEnabled((authenticationMethodsStep$ | async).mimeAuthEnabled) }}</div>
    </div>

    <div *ngIf="!(authenticationMethodsStep$ | async).mimeAuthEnabled" class="row mc-detailed-list-row">
      <div class="col-sm-4 mc-detailed-list-label">{{'$I18N_IDENTITY_POLICY_WIZARD_SUMMARY_STEP.AUTHENTICATION_METHODS.SECOND_FACTOR' | translate}}</div>
      <div class="mc-detailed-list-column">{{ (authenticationMethodsStep$ | async).secondFactorOption | mcIdentitySecondFactorOption }}</div>
    </div>

    <div *ngIf="!(authenticationMethodsStep$ | async).mimeAuthEnabled &&
                (authenticationMethodsStep$ | async).secondFactorOption !== secondFactorNone" class="row mc-detailed-list-row">
      <div class="col-sm-4 mc-detailed-list-label">{{'$I18N_IDENTITY_POLICY_WIZARD_SUMMARY_STEP.AUTHENTICATION_METHODS.REQUEST_BEFORE_PASSWORD' | translate}}</div>
      <div class="mc-detailed-list-column">{{ formatEnabled((authenticationMethodsStep$ | async).requestSecondFactorFirst) }}</div>
    </div>
  </div>


  <div class="mc-detailed-list mc-detailed-list-two-columns">
    <h5>{{ '$I18N_IDENTITY_POLICY_WIZARD_SUMMARY_STEP.IP_ADDRESSES.HEADER' | translate }}</h5>
    <div class="row mc-detailed-list-row">
      <div class="col-sm-4 mc-detailed-list-label">{{'$I18N_IDENTITY_POLICY_WIZARD_SUMMARY_STEP.IP_ADDRESSES.PERMISSION_TYPE' | translate}}</div>
      <div class="mc-detailed-list-column">{{ (ipAddressesStep$ | async).permissionType | mcIdentityLocationPermission }}</div>
    </div>

    <div *ngIf="(ipAddressesStep$ | async).permissionType !== noIpBasedPermissions"
         class="row mc-detailed-list-row">
      <div *ngIf="(ipAddressesStep$ | async).permissionType === permittedIpsPermissionType"
           class="col-sm-4 mc-detailed-list-label">{{'$I18N_IDENTITY_POLICY_WIZARD_SUMMARY_STEP.IP_ADDRESSES.PERMITTED_IPS' | translate}}</div>

      <div *ngIf="(ipAddressesStep$ | async).permissionType === trustedIpsPermissionType"
           class="col-sm-4 mc-detailed-list-label">{{'$I18N_IDENTITY_POLICY_WIZARD_SUMMARY_STEP.IP_ADDRESSES.TRUSTED_IPS' | translate}}</div>

      <div class="mc-detailed-list-column">{{ (ipAddressesStep$ | async).ipAddressesDisplayText }}</div>
    </div>
  </div>


  <div class="mc-detailed-list mc-detailed-list-two-columns">
    <h5>{{ '$I18N_IDENTITY_POLICY_WIZARD_SUMMARY_STEP.SESSION_TIMEOUT.HEADER' | translate }}</h5>
    <div class="row mc-detailed-list-row">
      <div class="col-sm-4 mc-detailed-list-label">{{'$I18N_IDENTITY_POLICY_WIZARD_SUMMARY_STEP.SESSION_TIMEOUT.TIMEOUT' | translate}}</div>
      <div class="mc-detailed-list-column">{{ (sessionTimeoutStep$ | async).expirationTime | mcIdentityExpirationSetting }}</div>
    </div>
  </div>


  <div class="mc-detailed-list mc-detailed-list-two-columns">
    <h5>{{ '$I18N_IDENTITY_POLICY_WIZARD_SUMMARY_STEP.APPLIES_TO.HEADER' | translate }}</h5>
    <div class="row mc-detailed-list-row">
      <div class="col-sm-4 mc-detailed-list-label">{{'$I18N_IDENTITY_POLICY_WIZARD_SUMMARY_STEP.APPLIES_TO.GROUPS' | translate}}</div>
      <div class="mc-detailed-list-column" *ngIf="(appliesToStep$ | async).appliedToEveryone">
        {{ '$I18N_IDENTITY_POLICY_WIZARD_SUMMARY_STEP.APPLIES_TO.EVERYONE' | translate }}</div>
      <div class="mc-detailed-list-column" *ngIf="!(appliesToStep$ | async).appliedToEveryone">{{ (appliesToStep$ | async).groupsDisplayText }}</div>
    </div>
  </div>
</div>

