"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefinitionListImplService = void 0;
const operators_1 = require("rxjs/operators");
const rxjs_1 = require("rxjs");
const table_store_1 = require("@mimecast-ui/table-store");
class DefinitionListImplService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    static getDataFromResponse(response) {
        return {
            data: response.all[0].definitions,
            meta: response.meta,
            hasErrors: response.hasErrors,
            failures: response === null || response === void 0 ? void 0 : response.fail
        };
    }
    getData(payload, pagination = table_store_1.defaultPaginationOption) {
        return this.httpClient
            .post('/api/policy/attachmentprotect/get-definition-list', Object.assign(Object.assign({}, payload), { meta: { pagination } }))
            .pipe(operators_1.map((response) => {
            return DefinitionListImplService.getDataFromResponse(response);
        }), operators_1.catchError((error) => {
            return rxjs_1.of(DefinitionListImplService.getDataFromResponse(error.error));
        }));
    }
    deleteDefinition(id) {
        return this.httpClient.post('/api/policy/attachmentprotect/delete-ttp-definition', id);
    }
    filter(query, pagination) {
        return this.getData(query, pagination);
    }
}
exports.DefinitionListImplService = DefinitionListImplService;
