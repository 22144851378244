"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dateRangePickerConfig = exports.getRange = exports.clpScanCountByFileTypeConfig = exports.blockedAppsChartConfiguration = exports.categoriesChartConfiguration = exports.monitoredAppsChartConfiguration = exports.nonsactionedAppsChartConfiguration = exports.sactionedAppsChartConfiguration = exports.ipTablecolumns = exports.deviceTablecolumns = exports.userTablecolumns = exports.exportFormats = exports.fileTypes = void 0;
const moment = require("moment");
const am4core = require("@amcharts/amcharts4/core");
exports.fileTypes = [
    {
        type: 'archive',
        label: 'Archive'
    },
    {
        type: 'exeOrScript',
        label: 'Executable/Script'
    },
    {
        type: 'image',
        label: 'Image'
    },
    {
        type: 'OfficeFile',
        label: 'Office'
    },
    {
        type: 'pdf',
        label: 'PDF'
    },
    {
        type: 'video',
        label: 'Video'
    }
];
exports.exportFormats = [
    {
        type: 'png',
        label: 'Download PNG'
    },
    {
        type: 'pdf',
        label: 'Download PDF'
    },
    {
        type: 'svg',
        label: 'Download SVG'
    },
    {
        type: 'jpg',
        label: 'Download JPG'
    },
    {
        type: 'print',
        label: 'Print Chart'
    }
];
exports.userTablecolumns = ['name', 'count'];
exports.deviceTablecolumns = ['name', 'count'];
exports.ipTablecolumns = ['name', 'count'];
exports.sactionedAppsChartConfiguration = {
    maxZoomLevel: 1,
    series: [
        {
            type: 'ColumnSeries',
            tooltipY: 0,
            tooltipText: '{categoryY} \nCount: [bold]{valueX}[/]',
            tooltip: {
                getFillFromObject: false,
                background: {
                    fill: '#fff',
                    fillOpacity: 1,
                    stroke: '#f2f2f2',
                    strokeWidth: 2
                },
                label: {
                    fill: '#000'
                }
            },
            columns: {
                fill: '#50B450',
                height: am4core.percent(35),
                strokeOpacity: 0,
                cursorOverStyle: am4core.MouseCursorStyle.pointer
            },
            dataFields: {
                valueX: 'count',
                categoryY: 'name'
            },
            interactionsEnabled: true,
            events: {}
        }
    ],
    xAxes: [
        {
            type: 'ValueAxis',
            maxPrecision: 0,
            min: 0,
            strictMinMax: true,
            cursorTooltipEnabled: false,
            renderer: {
                grid: {
                    disabled: false
                },
                minGridDistance: 50
            },
            numberFormatter: {
                type: 'NumberFormatter',
                numberFormat: '#a',
                bigNumberPrefixes: [
                    { number: 1e3, suffix: 'K' },
                    { number: 1e6, suffix: 'M' },
                    { number: 1e9, suffix: 'B' }
                ]
            }
        }
    ],
    yAxes: [
        {
            type: 'CategoryAxis',
            cursorTooltipEnabled: false,
            renderer: {
                labels: {
                    truncate: true,
                    wrap: true,
                    maxWidth: 150
                },
                grid: {
                    disabled: true,
                    template: {
                        location: 0
                    }
                },
                minGridDistance: 8
            },
            dataFields: {
                category: 'name'
            }
        }
    ]
};
exports.nonsactionedAppsChartConfiguration = {
    maxZoomLevel: 1,
    series: [
        {
            type: 'ColumnSeries',
            tooltipY: 0,
            tooltipText: '{categoryY} \nCount: [bold]{valueX}[/]',
            tooltip: {
                getFillFromObject: false,
                background: {
                    fill: '#fff',
                    fillOpacity: 1,
                    stroke: '#f2f2f2',
                    strokeWidth: 2
                },
                label: {
                    fill: '#000'
                }
            },
            columns: {
                fill: '#004ba0',
                height: am4core.percent(35),
                strokeOpacity: 0,
                cursorOverStyle: am4core.MouseCursorStyle.pointer
            },
            dataFields: {
                valueX: 'count',
                categoryY: 'name'
            },
            interactionsEnabled: true,
            events: {}
        }
    ],
    xAxes: [
        {
            type: 'ValueAxis',
            maxPrecision: 0,
            min: 0,
            strictMinMax: true,
            cursorTooltipEnabled: false,
            renderer: {
                grid: {
                    disabled: false
                },
                minGridDistance: 50
            },
            numberFormatter: {
                type: 'NumberFormatter',
                numberFormat: '#a',
                bigNumberPrefixes: [
                    { number: 1e3, suffix: 'K' },
                    { number: 1e6, suffix: 'M' },
                    { number: 1e9, suffix: 'B' }
                ]
            }
        }
    ],
    yAxes: [
        {
            type: 'CategoryAxis',
            cursorTooltipEnabled: false,
            renderer: {
                labels: {
                    truncate: true,
                    wrap: true,
                    maxWidth: 150
                },
                grid: {
                    disabled: true,
                    template: {
                        location: 0
                    }
                },
                minGridDistance: 8
            },
            dataFields: {
                category: 'name'
            }
        }
    ]
};
exports.monitoredAppsChartConfiguration = {
    maxZoomLevel: 1,
    series: [
        {
            type: 'ColumnSeries',
            tooltipY: 0,
            tooltipText: '{categoryY} \nCount: [bold]{valueX}[/]',
            tooltip: {
                getFillFromObject: false,
                background: {
                    fill: '#fff',
                    fillOpacity: 1,
                    stroke: '#f2f2f2',
                    strokeWidth: 2
                },
                label: {
                    fill: '#000'
                }
            },
            columns: {
                fill: '#FFCC00',
                height: am4core.percent(35),
                strokeOpacity: 0,
                cursorOverStyle: am4core.MouseCursorStyle.pointer
            },
            dataFields: {
                valueX: 'count',
                categoryY: 'name'
            },
            interactionsEnabled: true,
            events: {}
        }
    ],
    xAxes: [
        {
            type: 'ValueAxis',
            maxPrecision: 0,
            min: 0,
            strictMinMax: true,
            cursorTooltipEnabled: false,
            renderer: {
                grid: {
                    disabled: false
                },
                minGridDistance: 50
            },
            numberFormatter: {
                type: 'NumberFormatter',
                numberFormat: '#a',
                bigNumberPrefixes: [
                    { number: 1e3, suffix: 'K' },
                    { number: 1e6, suffix: 'M' },
                    { number: 1e9, suffix: 'B' }
                ]
            }
        }
    ],
    yAxes: [
        {
            type: 'CategoryAxis',
            cursorTooltipEnabled: false,
            renderer: {
                labels: {
                    truncate: true,
                    wrap: true,
                    maxWidth: 150
                },
                grid: {
                    disabled: true,
                    template: {
                        location: 0
                    }
                },
                minGridDistance: 8
            },
            dataFields: {
                category: 'name'
            }
        }
    ]
};
exports.categoriesChartConfiguration = {
    maxZoomLevel: 1,
    series: [
        {
            type: 'ColumnSeries',
            tooltipY: 0,
            tooltipText: '{categoryY} \nCount: [bold]{valueX}[/]',
            tooltip: {
                getFillFromObject: false,
                background: {
                    fill: '#fff',
                    fillOpacity: 1,
                    stroke: '#f2f2f2',
                    strokeWidth: 2
                },
                label: {
                    fill: '#000'
                }
            },
            columns: {
                fill: '#004ba0',
                height: am4core.percent(35),
                strokeOpacity: 0,
                cursorOverStyle: am4core.MouseCursorStyle.pointer
            },
            dataFields: {
                valueX: 'count',
                categoryY: 'name'
            },
            interactionsEnabled: true,
            events: {}
        }
    ],
    xAxes: [
        {
            type: 'ValueAxis',
            maxPrecision: 0,
            min: 0,
            strictMinMax: true,
            cursorTooltipEnabled: false,
            renderer: {
                grid: {
                    disabled: false
                },
                minGridDistance: 50
            },
            numberFormatter: {
                type: 'NumberFormatter',
                numberFormat: '#a',
                bigNumberPrefixes: [
                    { number: 1e3, suffix: 'K' },
                    { number: 1e6, suffix: 'M' },
                    { number: 1e9, suffix: 'B' }
                ]
            }
        }
    ],
    yAxes: [
        {
            type: 'CategoryAxis',
            cursorTooltipEnabled: false,
            renderer: {
                labels: {
                    truncate: true,
                    wrap: true,
                    maxWidth: 150
                },
                grid: {
                    disabled: true,
                    template: {
                        location: 0
                    }
                },
                minGridDistance: 8
            },
            dataFields: {
                category: 'name'
            }
        }
    ]
};
exports.blockedAppsChartConfiguration = {
    maxZoomLevel: 1,
    series: [
        {
            type: 'ColumnSeries',
            tooltipY: 0,
            tooltipText: '{categoryY} \nCount: [bold]{valueX}[/]',
            tooltip: {
                getFillFromObject: false,
                background: {
                    fill: '#fff',
                    fillOpacity: 1,
                    stroke: '#f2f2f2',
                    strokeWidth: 2
                },
                label: {
                    fill: '#000'
                }
            },
            columns: {
                fill: '#004ba0',
                height: am4core.percent(35),
                strokeOpacity: 0,
                cursorOverStyle: am4core.MouseCursorStyle.pointer
            },
            dataFields: {
                valueX: 'count',
                categoryY: 'name'
            },
            interactionsEnabled: true,
            events: {}
        }
    ],
    xAxes: [
        {
            type: 'ValueAxis',
            maxPrecision: 0,
            min: 0,
            strictMinMax: true,
            cursorTooltipEnabled: false,
            renderer: {
                grid: {
                    disabled: false
                },
                minGridDistance: 50
            },
            numberFormatter: {
                type: 'NumberFormatter',
                numberFormat: '#a',
                bigNumberPrefixes: [
                    { number: 1e3, suffix: 'K' },
                    { number: 1e6, suffix: 'M' },
                    { number: 1e9, suffix: 'B' }
                ]
            }
        }
    ],
    yAxes: [
        {
            type: 'CategoryAxis',
            cursorTooltipEnabled: false,
            renderer: {
                labels: {
                    truncate: true,
                    wrap: true,
                    maxWidth: 150
                },
                grid: {
                    disabled: true,
                    template: {
                        location: 0
                    }
                },
                minGridDistance: 8
            },
            dataFields: {
                category: 'name'
            }
        }
    ]
};
exports.clpScanCountByFileTypeConfig = {
    maxZoomLevel: 1,
    depth: 10,
    radius: '85%',
    innerRadius: am4core.percent(60),
    legend: {
        position: 'right',
        scrollable: true,
        useDefaultMarker: true,
        markers: {
            width: 8,
            height: 8
        },
        itemContainers: {
            paddingTop: 10,
            paddingBottom: 10
        }
    },
    series: [
        {
            type: 'PieSeries',
            dataFields: {
                value: 'count',
                category: 'fileType'
            },
            labels: {
                disabled: true
            },
            ticks: {
                disabled: true
            },
            slices: {
                stroke: '#FFFFFF',
                strokeWidth: 0,
                strokeOpacity: 1
            },
            colors: {
                list: ['#63A4FF', '#C48F00', '#AE53D3', '#FA5887', '#56C6D6', '#FFAD42', '#6ABF69']
            }
        }
    ]
};
function getRange(days, shift = false) {
    // @ts-ignore
    return {
        start: moment()
            .startOf('day')
            .subtract(shift ? days * 2 : days, 'days'),
        end: moment().subtract(shift ? days : 0, 'days')
    };
}
exports.getRange = getRange;
// @ts-ignore
exports.dateRangePickerConfig = {
    allowCustomRange: false,
    dateRanges: [
        {
            id: '24_HOURS',
            label: '$18N_SWG_REPORTING.TABLE.FILTERS.DATE_RANGE.DROPDOWN_VALUES.24_HOURS',
            rangeFn() {
                return {
                    start: moment()
                        .utc()
                        .subtract(24, 'hours'),
                    end: moment().utc()
                };
            }
        },
        {
            id: '48_HOURS',
            label: '$18N_SWG_REPORTING.TABLE.FILTERS.DATE_RANGE.DROPDOWN_VALUES.48_HOURS',
            rangeFn() {
                return {
                    start: moment()
                        .utc()
                        .subtract(48, 'hours'),
                    end: moment().utc()
                };
            }
        },
        {
            id: '7_DAYS',
            label: '$18N_SWG_REPORTING.TABLE.FILTERS.DATE_RANGE.DROPDOWN_VALUES.7_DAYS',
            rangeFn() {
                return {
                    start: moment()
                        .utc()
                        .subtract(7, 'days'),
                    end: moment().utc()
                };
            }
        },
        {
            id: '14_DAYS',
            label: '$18N_SWG_REPORTING.TABLE.FILTERS.DATE_RANGE.DROPDOWN_VALUES.14_DAYS',
            rangeFn() {
                return {
                    start: moment()
                        .utc()
                        .subtract(14, 'days'),
                    end: moment().utc()
                };
            }
        },
        {
            id: '30_DAYS',
            label: '$18N_SWG_REPORTING.TABLE.FILTERS.DATE_RANGE.DROPDOWN_VALUES.30_DAYS',
            rangeFn() {
                return {
                    start: moment()
                        .utc()
                        .subtract(30, 'days'),
                    end: moment().utc()
                };
            }
        }
    ],
    initialDateRange: 0
};
