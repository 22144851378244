"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UrlProtectionSidePanelViewDetailsComponent = void 0;
const url_protection_policy_summary_step_component_helper_1 = require("../steps/url-protection-policy-summary-step/url-protection-policy-summary-step.component.helper");
const statics = require("../steps/url-protection-policy-summary-step/url-protection-policy-summary-step.component.static-values");
const url_protection_side_panel_view_details_component_helper_1 = require("./url-protection-side-panel-view-details.component.helper");
const url_protection_inbound_settings_step_models_1 = require("../steps/url-protection-inbound-settings-step/url-protection-inbound-settings-step.models");
class UrlProtectionSidePanelViewDetailsComponent {
    constructor(translateService, datePipeService) {
        this.translateService = translateService;
        this.datePipeService = datePipeService;
        this.showMoreLessTriggerValue = statics.SHOW_MORE_LESS_TRIGGER_VALUE;
        this.helper = new url_protection_policy_summary_step_component_helper_1.UrlProtectionPolicySummaryStepComponentHelper(this.translateService);
        this.sidePanelHelper = new url_protection_side_panel_view_details_component_helper_1.UrlProtectionPolicySidePanelComponentHelper(this.translateService, this.datePipeService);
    }
    ngOnInit() {
        this.inboundRewriteURLsInAttachments = this.sidePanelHelper.checkForRewriteURLsFlag(this.policyDetails);
        this.inboundCheckForSimilarURLs = this.sidePanelHelper.checkForSimilarURLsFlag(this.policyDetails);
        this.outboundScanURLsInAttachments = this.sidePanelHelper.checkForScanURLsFlag(this.policyDetails);
        this.outboundCheckForSimilarURLs = this.sidePanelHelper.checkForOutboundSimilarURLsFlag(this.policyDetails);
        this.journalScanURLsInAttachments = this.sidePanelHelper.checkForJournalScanURLsFlag(this.policyDetails);
        this.journalCheckForSimilarURLs = this.sidePanelHelper.checkForJournalSimilarURLsFlag(this.policyDetails);
    }
    getEnabledDisabledLabel(value) {
        return this.translate(value ? statics.EnabledDisabledLabel.ENABLED : statics.EnabledDisabledLabel.DISABLED);
    }
    translate(value) {
        return this.translateService.instant(value);
    }
    showDescription() {
        return (!!this.policyDetails.policy.description && this.policyDetails.policy.description.length > 0);
    }
    isBypass() {
        return !!this.policyDetails.policy.hasOwnProperty('policyOptions');
    }
    showInboundBrowserIsolation() {
        return (this.policyDetails.action === url_protection_inbound_settings_step_models_1.MaliciousUrlDetectionActionOptions.BLOCK &&
            this.hasBrowserIsolation);
    }
}
exports.UrlProtectionSidePanelViewDetailsComponent = UrlProtectionSidePanelViewDetailsComponent;
