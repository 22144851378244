"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MepPolicyListImplService = void 0;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const table_store_1 = require("@mimecast-ui/table-store");
const models_1 = require("../policies/models/models");
class MepPolicyListImplService {
    constructor(api$) {
        this.api$ = api$;
    }
    getData(payload, pagination = table_store_1.defaultPaginationOption) {
        const config = Object.assign(Object.assign({}, payload), { meta: {
                pagination
            } });
        return this.api$.getMepPoliciesList(config).pipe(operators_1.map((response) => {
            return {
                hasErrors: response.hasErrors,
                data: this.mapPolicyData(response.first.policies),
                failures: response.fail,
                meta: response.meta
            };
        }), operators_1.catchError((response) => {
            return rxjs_1.of({
                hasErrors: response.hasErrors,
                data: response.first,
                failures: response.fail,
                meta: response.meta
            });
        }));
    }
    mapPolicyData(policies) {
        return policies.map(policy => {
            return {
                id: policy.id,
                name: policy.name,
                description: policy.description,
                defaultPolicy: false,
                policyType: models_1.MepPolicyType.MISDIRECTED_EMAIL_POLICY,
                matchLevel: policy.matchLevel,
                enabled: policy.status,
                action: policy.action,
                everyone: true,
                autoReleaseTimeoutMinutes: policy.autoReleaseTimeoutMinutes,
                // If there is no last updated date, use the create time as the last modified timestamp.
                lastModified: policy.lastUpdated ? policy.lastUpdated : policy.createTime,
                createTime: policy.createTime
            };
        });
    }
    filter(filterBy, pagination) {
        return this.getData(filterBy, pagination);
    }
    openItem() {
        return null;
    }
}
exports.MepPolicyListImplService = MepPolicyListImplService;
