"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApCretateDefinitionEffects = void 0;
const effects_1 = require("@ngrx/effects");
const operators_1 = require("rxjs/operators");
const rxjs_1 = require("rxjs");
const actionCreate = require("../actions/create-definition.actions");
const listActions = require("../actions/definition.actions");
const reducers = require("../reducers");
const table_store_1 = require("@mimecast-ui/table-store");
class ApCretateDefinitionEffects {
    constructor(stateService, actions$, notificationService, definitionsService, store) {
        this.stateService = stateService;
        this.actions$ = actions$;
        this.notificationService = notificationService;
        this.definitionsService = definitionsService;
        this.store = store;
        this.goToCreatePage$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(actionCreate.ApdefinitionCreateTypesEnum
                .NAVIGATE_TO_ATTACHMENT_PROTECTION_CREATE_DEFINITION), operators_1.tap(() => {
                this.stateService.$state.go('attachment-protection-create-definition');
            }));
        }, { dispatch: false });
        this.createDefinitionSave$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(actionCreate.CREATE_DEFINITION_SAVE), operators_1.withLatestFrom(this.store.select(reducers.getCreateDefinition)), operators_1.switchMap(([, state]) => {
                if (state.data.id !== '') {
                    return this.definitionsService
                        .updateApDefinition(this.definitionsService.createAPIPayLoad(state.data, state.data.id))
                        .pipe(operators_1.mergeMap((response) => {
                        if (response.hasErrors) {
                            return this.errorUpdateAction();
                        }
                        return this.successUpdateSaveActions();
                    }), operators_1.catchError(() => {
                        return this.errorUpdateAction();
                    }));
                }
                return this.definitionsService
                    .createApDefinition(this.definitionsService.createAPIPayLoad(state.data))
                    .pipe(operators_1.withLatestFrom(this.store.select(reducers.getIsDuplicateData)), operators_1.mergeMap((response) => {
                    const mcResponse = response[0];
                    const isDuplicateData = response[1];
                    if (mcResponse.hasErrors) {
                        return this.errorAction();
                    }
                    if (mcResponse.first.id) {
                        this.store.dispatch(new actionCreate.UpdateDefinitonId(mcResponse.first.id));
                    }
                    if (isDuplicateData) {
                        return this.duplicatesuccessSaveActions();
                    }
                    else {
                        return this.successSaveActions();
                    }
                }), operators_1.catchError(() => {
                    return this.errorAction();
                }));
            }));
        });
        this.createDefinitionSaveAndExit$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(actionCreate.CREATE_DEFINITION_SAVE_AND_EXIT), operators_1.withLatestFrom(this.store.select(reducers.getCreateDefinition)), operators_1.switchMap(([, state]) => {
                if (state.data.id !== '') {
                    return this.definitionsService
                        .updateApDefinition(this.definitionsService.createAPIPayLoad(state.data, state.data.id))
                        .pipe(operators_1.mergeMap((response) => {
                        if (response.hasErrors) {
                            return this.errorUpdateAction();
                        }
                        return this.successUpdateSaveAndExitActions();
                    }), operators_1.catchError(() => {
                        return this.errorUpdateAction();
                    }));
                }
                return this.definitionsService
                    .createApDefinition(this.definitionsService.createAPIPayLoad(state.data))
                    .pipe(operators_1.withLatestFrom(this.store.select(reducers.getIsDuplicateData)), operators_1.mergeMap((response) => {
                    const mcResponse = response[0];
                    const isDuplicateData = response[1];
                    if (mcResponse.hasErrors) {
                        return this.errorAction();
                    }
                    if (mcResponse.first.id) {
                        this.store.dispatch(new actionCreate.UpdateDefinitonId(mcResponse.first.id));
                    }
                    if (isDuplicateData) {
                        return this.duplicateSuccessSaveAndExitActions();
                    }
                    else {
                        return this.successSaveAndExitActions();
                    }
                }), operators_1.catchError(() => {
                    return this.errorAction();
                }));
            }));
        });
        this.getServerConnection$ = effects_1.createEffect(() => {
            return this.actions$.pipe(effects_1.ofType(actionCreate.GET_SERVER_CONNECTION), operators_1.switchMap(() => {
                return this.definitionsService.getServerConnection({}, table_store_1.defaultPaginationOption).pipe(operators_1.map(payload => new actionCreate.GetServerConnectionSuccess(payload)), operators_1.catchError(() => rxjs_1.of(new actionCreate.GetServerConnectionFail())));
            }));
        });
    }
    successSaveActions() {
        return [
            new actionCreate.CreateDefinitionSuccessAction(),
            this.notificationService.newNotification({
                type: 'success',
                config: {
                    msg: '$I18N_SERVICES_DEFINITION.CREATE_SUCCESS_MSG'
                }
            })
        ];
    }
    successSaveAndExitActions() {
        return [
            new actionCreate.ApDefinitionNavigateToListPageAction(),
            this.notificationService.newNotification({
                type: 'success',
                config: {
                    msg: '$I18N_SERVICES_DEFINITION.CREATE_SUCCESS_MSG'
                }
            })
        ];
    }
    successUpdateSaveActions() {
        return [
            new actionCreate.CreateDefinitionSuccessAction(),
            this.notificationService.newNotification({
                type: 'success',
                config: {
                    msg: '$I18N_SERVICES_DEFINITION.UPDATE_SUCCESS_MSG'
                }
            })
        ];
    }
    successUpdateSaveAndExitActions() {
        return [
            new actionCreate.ApDefinitionNavigateToListPageAction(),
            this.notificationService.newNotification({
                type: 'success',
                config: {
                    msg: '$I18N_SERVICES_DEFINITION.UPDATE_SUCCESS_MSG'
                }
            })
        ];
    }
    duplicateSuccessSaveAndExitActions() {
        this.store.dispatch(new listActions.ClearDuplicateDetailsAction());
        return [
            new actionCreate.ApDefinitionNavigateToListPageAction(),
            this.notificationService.newNotification({
                type: 'success',
                config: {
                    msg: '$I18N_SERVICES_DEFINITION.DUPLICATE_SUCCESS_MSG'
                }
            })
        ];
    }
    duplicatesuccessSaveActions() {
        this.store.dispatch(new listActions.ClearDuplicateDetailsAction());
        return [
            this.notificationService.newNotification({
                type: 'success',
                config: {
                    msg: '$I18N_SERVICES_DEFINITION.DUPLICATE_SUCCESS_MSG'
                }
            })
        ];
    }
    errorAction() {
        return [
            new actionCreate.CreateDefinitionFailAction(),
            this.notificationService.newNotification({
                type: 'error',
                config: {
                    msg: '$I18N_SERVICES_DEFINITION.CREATE_FAIL_MSG'
                }
            })
        ];
    }
    errorUpdateAction() {
        return [
            new actionCreate.CreateDefinitionFailAction(),
            this.notificationService.newNotification({
                type: 'error',
                config: {
                    msg: '$I18N_SERVICES_DEFINITION.UPDATE_FAIL_MSG'
                }
            })
        ];
    }
}
exports.ApCretateDefinitionEffects = ApCretateDefinitionEffects;
