"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MepLogSidebarInternalRecipientsListComponent = void 0;
class MepLogSidebarInternalRecipientsListComponent {
    constructor() {
        this.tableData = [];
        this.columns = ['internalAddress'];
    }
    set internalAddresses(value) {
        if (value) {
            this.populateInternalAddressTableData(value);
        }
    }
    populateInternalAddressTableData(internalAddressesData) {
        this.tableData = internalAddressesData.map(internalAddress => {
            return { internalAddress };
        });
    }
    hasInternalRecipientData() {
        return this.tableData && this.tableData.length > 0;
    }
}
exports.MepLogSidebarInternalRecipientsListComponent = MepLogSidebarInternalRecipientsListComponent;
