'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const moment = require("moment");
const _ = require("lodash");
angular.module('services.services.backup.exports-and-restore.controller', [])
    .controller('ExportsAndRestoreController', ['$scope', '$translate', 'windowService', 'backupService', 'adconCommonsService', 'exportDownloadAsideService', 'adconBackupService', function ($scope, $translate, windowService, backupService, adconCommonsService, exportDownloadAsideService, adconBackupService) {
        const self = this;
        let paginationActualPage = 1;
        const paginationLabelDict = {
            start: paginationActualPage,
            end: paginationActualPage
        };
        self.inIframe = windowService.inIframe();
        self.payload = {
            data: [{}],
            meta: {
                pagination: {
                    pageSize: self.pageSize
                }
            }
        };
        self.humanizeStatus = {};
        self.humanizeStatus[backupService.constants.PROGRESS_STATUS.SNAPSHOT_QUEUED] = {
            label: '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_EXPORT_AND_RESTORE_QUEUE_STATUS_SNAPSHOT_QUEUED_LABEL',
            class: 'text-muted'
        };
        self.humanizeStatus[backupService.constants.PROGRESS_STATUS.SNAPSHOT_RUNNING] = {
            label: '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_EXPORT_AND_RESTORE_QUEUE_STATUS_SNAPSHOT_RUNNING_LABEL',
            class: 'text-muted'
        };
        self.humanizeStatus[backupService.constants.PROGRESS_STATUS.EXPORT_QUEUED] = {
            label: '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_EXPORT_AND_RESTORE_QUEUE_STATUS_EXPORT_QUEUED_LABEL',
            class: 'text-muted'
        };
        self.humanizeStatus[backupService.constants.PROGRESS_STATUS.EXPORT_RUNNING] = {
            label: '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_EXPORT_AND_RESTORE_QUEUE_STATUS_EXPORT_RUNNING_LABEL',
            class: 'text-muted'
        };
        self.humanizeStatus[backupService.constants.PROGRESS_STATUS.DONE] = {
            icon: 'far fa-check',
            label: '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_EXPORT_AND_RESTORE_QUEUE_STATUS_COMPLETE_LABEL',
            class: 'text-success'
        };
        self.humanizeStatus[backupService.constants.PROGRESS_STATUS.WARNING] = {
            icon: 'fas fa-exclamation-triangle',
            label: '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_EXPORT_AND_RESTORE_QUEUE_STATUS_WARNING_LABEL',
            class: 'text-warning'
        };
        self.humanizeStatus[backupService.constants.PROGRESS_STATUS.ERROR] = {
            icon: 'far fa-times',
            label: '$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_EXPORT_AND_RESTORE_QUEUE_STATUS_ERROR_LABEL',
            class: 'text-danger'
        };
        self.CONSTANTS = {};
        self.CONSTANTS.RECOVERY_TYPE = backupService.constants.RECOVERY_TYPE;
        self.CONSTANTS.RECOVERY_TYPE_KEYS = backupService.constants.RECOVERY_TYPE_KEYS;
        self.reportDownloadEndpoint = '';
        self.selectedData = {};
        self.pageSize = {};
        self.mailboxItems = [];
        self.nextPageResult = {};
        self.arrRowClicked = [];
        self.taskStatus = taskStatus;
        self.openDownloadAside = openDownloadAside;
        self.getDownloadLabel = getDownloadLabel;
        self.getStatusLabel = getStatusLabel;
        self.timeFromNow = timeFromNow;
        self.pageSizeOptions = angular.copy(adconCommonsService.getDropdownOptions().pageSizeOptions);
        self.paginationLabel = '';
        self.paginationNextPageResults = false;
        self.paginationPreviousPageResults = false;
        self.hasPermission = adconBackupService.CONSTANTS.HAS_PERMISSIONS;
        $scope.$watch(function () {
            return (self.payload);
        }, function (oldValue, newValue) {
            isPayloadUpdated(oldValue, newValue);
            self.getMailboxItems();
        }, true);
        self.getMailboxItems = getMailboxItems;
        self.pageSizeOptionsUpdate = pageSizeOptionsUpdate;
        self.getMoreResults = getMoreResults;
        self.paginationNextOnChange = paginationNextOnChange;
        self.paginationPreviousOnChange = paginationPreviousOnChange;
        activate();
        function activate() {
            self.reportDownloadEndpoint = backupService.downloadReport();
            self.pageSizeOptionsUpdate(adconCommonsService.CONSTANTS.PAGE_SIZES_KEYS.I50);
        }
        function getMailboxItems() {
            const pageSize = self.payload.meta.pagination.pageSize;
            self.isDataLoading = true;
            backupService.getRecoveryTask(self.payload).then(function (response) {
                if (!response.err) {
                    self.mailboxItems = response.all;
                    if (response.meta && response.meta().pagination) {
                        paginationLabelDict.start = paginationActualPage === 1 ? paginationActualPage : (pageSize * (paginationActualPage - 1)) + 1;
                        paginationLabelDict.end = self.mailboxItems.length !== pageSize ? (pageSize * (paginationActualPage - 1)) + self.mailboxItems.length : pageSize * paginationActualPage;
                        self.paginationLabel = self.mailboxItems.length > 0 ? $translate.instant('$I18N_ADMINISTRATION_COMMONS_PAGINATION_SEPARATOR', {
                            start: paginationLabelDict.start,
                            end: paginationLabelDict.end
                        }) : '';
                        self.paginationNextPageResults = response.meta().pagination.next;
                        self.paginationPreviousPageResults = response.meta().pagination.previous;
                    }
                }
                else {
                    backupService.getError().showErrorNotification(response);
                }
                self.isDataLoading = false;
            }, function () {
                self.isDataLoading = false;
                backupService.getError().showErrorNotification();
            });
        }
        function getMoreResults(pageToken) {
            if (!self.isDataLoading && pageToken) {
                self.payload.meta.pagination.pageToken = pageToken;
            }
        }
        function pageSizeOptionsUpdate(id) {
            self.payload.meta.pagination.pageSize = self.pageSizeOptions[id].key;
            self.selectedPageSizeOptions = self.pageSizeOptions[id].value;
        }
        function isPayloadUpdated(oldPayload, newPayload) {
            if (!(_.isEqual(oldPayload.data[0], newPayload.data[0])) || !(_.isEqual(oldPayload.meta.pagination.pageSize, newPayload.meta.pagination.pageSize))) {
                delete self.payload.meta.pagination.pageToken;
                paginationActualPage = 1;
            }
        }
        function paginationNextOnChange() {
            paginationActualPage++;
            getMoreResults(self.paginationNextPageResults);
        }
        function paginationPreviousOnChange() {
            paginationActualPage--;
            getMoreResults(self.paginationPreviousPageResults);
        }
        function openDownloadAside(task) {
            if (taskStatus(task).DOWNLOAD) {
                exportDownloadAsideService.openExportDownloadAside(task.recoveryId, task.exportSettings.downloadTokens, task.mailbox);
            }
        }
        function taskStatus(task) {
            const objStatus = {
                DOWNLOAD: false,
                REPORT: false
            };
            if (task && ((task.phaseProgress && task.phaseProgress === 100 && task.status === backupService.constants.PROGRESS_STATUS.DONE) || task.status === backupService.constants.PROGRESS_STATUS.ERROR || task.status === backupService.constants.PROGRESS_STATUS.WARNING)) {
                objStatus.REPORT = !!task.recoveryId;
                if (task.exportSettings && task.exportSettings.downloadTokens && task.exportSettings.downloadTokens.length > 0 && task.status !== backupService.constants.PROGRESS_STATUS.ERROR) {
                    objStatus.DOWNLOAD = true;
                }
            }
            return objStatus;
        }
        function getDownloadLabel(item) {
            return item && item.exportSettings && item.exportSettings.downloadTokens && item.exportSettings.downloadTokens.length > 1 ? $translate.instant('$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_EXPORT_AND_RESTORE_QUEUE_TABLE_DOWNLOAD_LABEL_WITH_NUMBER', { number: item.exportSettings.downloadTokens.length }) : $translate.instant('$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_EXPORT_AND_RESTORE_QUEUE_TABLE_DOWNLOAD_LABEL');
        }
        function getStatusLabel(item) {
            let objReturn = {};
            if (item.status) {
                objReturn = self.humanizeStatus[item.status];
                objReturn.labelTranslated = item.status === backupService.constants.PROGRESS_STATUS.SNAPSHOT_RUNNING || item.status === backupService.constants.PROGRESS_STATUS.EXPORT_RUNNING ? $translate.instant(objReturn.label, { number: item.phaseProgress }) : $translate.instant(objReturn.label);
            }
            else {
                objReturn = self.humanizeStatus[backupService.constants.PROGRESS_STATUS.ERROR];
                objReturn.labelTranslated = $translate.instant(objReturn.label);
            }
            if (item.statusMessage) {
                objReturn.tooltipTranslated = $translate.instant(backupService.constants.TASK_STATUS_MESSAGE_KEYS[item.statusMessage]);
            }
            else if (item.status === backupService.constants.PROGRESS_STATUS.WARNING) {
                objReturn.tooltipTranslated = $translate.instant(backupService.constants.TASK_STATUS_MESSAGE_KEYS[item.status]);
            }
            return objReturn;
        }
        function timeFromNow(expiry) {
            const now = moment();
            const diff = now.diff(expiry);
            const diffDuration = moment.duration(diff);
            const days = Math.abs(diffDuration.days());
            const hours = Math.abs(diffDuration.hours());
            let durationAsString = '';
            if (days === 0 && hours === 0) {
                durationAsString = $translate.instant('$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_EXPORT_AND_RESTORE_QUEUE_TASK_EXPIRY.DURATION.MINIMUM');
            }
            else {
                if (days > 0) {
                    durationAsString += days === 1 ?
                        $translate.instant('$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_EXPORT_AND_RESTORE_QUEUE_TASK_EXPIRY.DURATION.DAY') :
                        $translate.instant('$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_EXPORT_AND_RESTORE_QUEUE_TASK_EXPIRY.DURATION.DAYS', { days });
                }
                durationAsString += hours === 1 ?
                    $translate.instant('$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_EXPORT_AND_RESTORE_QUEUE_TASK_EXPIRY.DURATION.HOUR') :
                    $translate.instant('$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_EXPORT_AND_RESTORE_QUEUE_TASK_EXPIRY.DURATION.HOURS', { hours });
            }
            return moment(expiry).isAfter(now) ?
                $translate.instant('$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_EXPORT_AND_RESTORE_QUEUE_TASK_EXPIRY.EXPIRES', { duration: durationAsString }) :
                $translate.instant('$I18N_ADMINISTRATION_MENU_BACKUP_RESTORE_EXPORT_AND_RESTORE_QUEUE_TASK_EXPIRY.EXPIRED', { duration: durationAsString });
        }
        $scope.tab.onRefresh = self.refresh = function () {
            self.mailboxItems = [];
            getMailboxItems();
        };
    }]);
