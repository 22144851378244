"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiApplicationsTechPartnersListComponent = void 0;
const core_1 = require("@angular/core");
const tech_partners_list_static_values_1 = require("./tech-partners-list.static-values");
class ApiApplicationsTechPartnersListComponent {
    constructor(translateService) {
        this.translateService = translateService;
        this.crowdstrike = require('../resources/tech-partners/crowdstrike.png');
        this.wildfire = require('../resources/tech-partners/wildfire.png');
        this.cortex = require('../resources/tech-partners/cortex.png');
        this.xsoar = require('../resources/tech-partners/xsoar.png');
        this.splunk = require('../resources/tech-partners/splunk.png');
        this.logrhythm = require('../resources/tech-partners/logrhythm.png');
        this.qradar = require('../resources/tech-partners/qradar.png');
        this.resilient = require('../resources/tech-partners/resilient.png');
        this.servicenow = require('../resources/tech-partners/servicenow.png');
        this.netskopecloudte = require('../resources/tech-partners/netskope.png');
        this.netskopedlp = require('../resources/tech-partners/netskope.png');
        this.sentinel = require('../resources/tech-partners/sentinel.png');
        this.connectwise = require('../resources/tech-partners/connectwise.png');
        this.salesforce = require('../resources/tech-partners/salesforce.png');
        this.insightconnect = require('../resources/tech-partners/insightconnect.png');
        this.insightidr = require('../resources/tech-partners/insightidr.png');
        this.digitalshadows = require('../resources/tech-partners/digitalshadows.png');
        this.fluencysecurity = require('../resources/tech-partners/fluencysecurity.png');
        this.soar = require('../resources/tech-partners/soar.png');
        this.mcafee = require('../resources/tech-partners/mcafee.png');
        this.splunkphantom = require('../resources/tech-partners/splunkphantom.png');
        this.swimlane = require('../resources/tech-partners/swimlane.png');
        this.timetoreply = require('../resources/tech-partners/timetoreply.png');
        this.scim = require('../resources/tech-partners/scim.png');
        this.thetalake = require('../resources/tech-partners/thetalake.png');
        this.arcticWolf = require('../resources/tech-partners/arctic-wolf.png');
        this.anomali = require('../resources/tech-partners/anomali.png');
        this.humio = require('../resources/tech-partners/humio.png');
        this.sumologic = require('../resources/tech-partners/sumologic.png');
        this.secureworks = require('../resources/tech-partners/secureworks.png');
        this.sentinelone = require('../resources/tech-partners/sentinelone.png');
        this.apigateway = require('../resources/tech-partners/mimecastgateway.png');
        this.legacycustom = require('../resources/tech-partners/legacycustom.png');
        this.oktaevidencebasedcontrol = require('../resources/tech-partners/oktaevidencebasedcontrol.png');
        this.exabeam = require('../resources/tech-partners/exabeam.png');
        this.blumira = require('../resources/tech-partners/blumira.png');
        this.techPartnersLink = 'https://www.mimecast.com/tech-connect/tech-partners/';
        this.listOfLogos = {
            splunk: this.splunk,
            insightidr: this.insightidr,
            crowdstrike: this.crowdstrike,
            logrhythm: this.logrhythm,
            qradar: this.qradar,
            wildfire: this.wildfire,
            cortex: this.cortex,
            sentinel: this.sentinel,
            splunkphantom: this.splunkphantom,
            xsoar: this.xsoar,
            servicenow: this.servicenow,
            netskopecloudte: this.netskopecloudte,
            netskopedlp: this.netskopedlp,
            mcafee: this.mcafee,
            insightconnect: this.insightconnect,
            digitalshadows: this.digitalshadows,
            resilient: this.resilient,
            fluencysecurity: this.fluencysecurity,
            swimlane: this.swimlane,
            salesforce: this.salesforce,
            timetoreply: this.timetoreply,
            connectwise: this.connectwise,
            soar: this.soar,
            scim: this.scim,
            thetalake: this.thetalake,
            arcticwolf: this.arcticWolf,
            anomali: this.anomali,
            humio: this.humio,
            sumologic: this.sumologic,
            secureworks: this.secureworks,
            sentinelone: this.sentinelone,
            apigateway: this.apigateway,
            legacycustom: this.legacycustom,
            okta_evidence_based_control: this.oktaevidencebasedcontrol,
            exabeam: this.exabeam,
            blumira: this.blumira
        };
        this.onGenerateKeys = new core_1.EventEmitter();
        this.onCreateIntegration = new core_1.EventEmitter();
        this.onEditIntegration = new core_1.EventEmitter();
    }
    ngOnInit() {
        this.partnersList = [...tech_partners_list_static_values_1.partnersList];
        this.sortPartnersList();
        if (!this.isOktaIntegrationEnabled) {
            this.partnersList.splice(this.partnersList.findIndex(card => card.id === 'scim'), 1);
        }
        if (!this.isAnomaliIntegrationEnabled) {
            this.partnersList.splice(this.partnersList.findIndex(card => card.id === 'anomali'), 1);
        }
        if (!this.isMimecastApiV3Enabled) {
            this.partnersList.splice(this.partnersList.findIndex(card => card.id === 'apigateway'), 1);
        }
        if (!this.isOktaEvidenceBasedControlIntegrationEnabled) {
            this.partnersList.splice(this.partnersList.findIndex(card => card.id === 'okta_evidence_based_control'), 1);
        }
    }
    sortPartnersList() {
        this.partnersList.sort((a, b) => this.getTitle(a.id).localeCompare(this.getTitle(b.id)));
        const apigateway = this.partnersList.find(card => card.id === 'apigateway');
        const legacycustom = this.partnersList.find(card => card.id === 'legacycustom');
        const index1 = this.partnersList.findIndex(card => card === apigateway);
        const index2 = this.partnersList.findIndex(card => card === legacycustom);
        if (index1 !== -1 && index2 !== -1) {
            this.partnersList.splice(index1, 1);
            this.partnersList.splice(index2 > index1 ? index2 - 1 : index2, 1);
            this.partnersList.unshift(apigateway);
            this.partnersList.unshift(legacycustom);
        }
    }
    getTitle(id) {
        return this.translateService
            .instant(('$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.TECH_PARTNERS.' + id + '.TITLE').toUpperCase())
            .toLowerCase();
    }
    getLogo(partner) {
        return this.listOfLogos[partner];
    }
    getButtonText(isInternal, id) {
        if (isInternal) {
            if ((id === 'wildfire' && this.integrationExists('wildfire')) ||
                (id === 'crowdstrike' && this.integrationExists('crowdstrike')) ||
                (id === 'okta_evidence_based_control' &&
                    this.integrationExists('okta_evidence_based_control'))) {
                return this.translateService.instant('$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.CARD_BUTTONS.EDIT_INTEGRATION');
            }
            return this.translateService.instant('$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.CARD_BUTTONS.CREATE_INTEGRATION');
        }
        else {
            return this.translateService.instant('$I18N_API_INTEGRATION_TECH_PARTNERS_SCREEN.CARD_BUTTONS.GENERATE_KEYS');
        }
    }
    integrationExists(id) {
        return this.integrations
            .map((integration) => integration.integrationType)
            .includes(id);
    }
    canCreateApplication(partner) {
        if (partner === 'netskopedlp') {
            return false;
        }
        return true;
    }
    onPartnerClicked(isInternal, id, category, service) {
        if (!isInternal) {
            if (id !== 'netskopedlp') {
                this.onGenerateKeys.emit({ id, category, service });
            }
            else {
                const partner = this.partnersList.find(card => card.id === id);
                const linkOption = partner.optionsList.find(optionList => optionList.type === 'link');
                const win = window.open(linkOption.target, '_blank');
                win.focus();
            }
        }
        else {
            if ((id === 'wildfire' && this.integrationExists('wildfire')) ||
                (id === 'crowdstrike' && this.integrationExists('crowdstrike')) ||
                (id === 'okta_evidence_based_control' &&
                    this.integrationExists('okta_evidence_based_control'))) {
                this.onEditIntegration.emit(this.integrations.find(integration => integration.integrationType === id));
            }
            else {
                this.onCreateIntegration.emit(id);
            }
        }
    }
    onOptionClicked(option) {
        // more options to be added soon
        if (option.type === 'link') {
            const win = window.open(option.target, '_blank');
            win.focus();
        }
    }
}
exports.ApiApplicationsTechPartnersListComponent = ApiApplicationsTechPartnersListComponent;
