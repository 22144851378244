"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const moment = require("moment");
const _ = require("lodash");
(function () {
    'use strict';
    /* ======================================================== *\

     = nav bar / favourites / service
     = cfeudo

     \* ======================================================== */
    angular.module('navbar.favourites.service', [])
        .factory('navbarFavouriteService', ['userService', 'growl', '$filter', 'navbarService', '$q', 'capabilitiesService', '$state', 'megaMenuService', function (userService, growl, $filter, navbarService, $q, capabilitiesService, $state, megaMenuService) {
            const keyAdconFavourites = userService.constants.PREFERENCE_KEYS.ADCON_FAVOURITES;
            const cacheFavouritesDetails = [];
            const cacheFavourites = [];
            const intMaxOfFavourites = 10;
            /* -------------------------------------------------------- *\
             - initialisation
             \* -------------------------------------------------------- */
            const userServicePreferencesPromise = fetchFavouritesFromPreferences();
            let currentState;
            let currentParams;
            /* -------------------------------------------------------- *\
             - interface
             \* -------------------------------------------------------- */
            return {
                getFavourites,
                getFavouritesPromise,
                isFavourite,
                favourite,
                isFavouriteState,
                setCurrentState,
                toggleFavourite,
                getMaxOfFavourites: intMaxOfFavourites
            };
            /* -------------------------------------------------------- *\
             - implementation
             \* -------------------------------------------------------- */
            // -- getFavouritesPromise --
            function getFavourites() {
                return cacheFavouritesDetails;
            }
            // -- getFavouritesPromise --
            function getFavouritesPromise() {
                return userServicePreferencesPromise;
            }
            // -- favourite --
            function favourite(item) {
                return userServicePreferencesPromise.then(function () {
                    const arrFavourites = cacheFavourites;
                    const existingItem = _.remove(arrFavourites, function (arrFavouritesInstance) {
                        return navbarService.areTheseStateSame(arrFavouritesInstance, item.state);
                    });
                    if (arrFavourites.length >= intMaxOfFavourites) {
                        growl.warning($filter('translate')('$I18N_MENU_TOP_FAVOURITE_REACH_LIMIT', { maxFavourites: intMaxOfFavourites }), { ttl: 5000 });
                        item.onFavoriteChange();
                        return undefined;
                    }
                    if (_.isEmpty(existingItem)) {
                        item.date = moment(new Date()).locale('en').format('YYYY-MM-DDTHH:mm:ssZZ');
                        // @ts-ignore
                        item = Object.assign({}, item);
                        arrFavourites.push(processFavourite(item));
                    }
                    return userService.setPreferencesByKey(keyAdconFavourites, arrFavourites).then(function (responseSetPreferences) {
                        if (!responseSetPreferences.err) {
                            if (_.isEmpty(existingItem)) {
                                navbarService.findMenuItemByState(item.state, item.state.params).then(function (responseMenu) {
                                    responseMenu.item.date = item.date;
                                    responseMenu.item.isActive = true;
                                    cacheFavouritesDetails.push(responseMenu.item);
                                    item.onFavoriteChange();
                                });
                            }
                            else {
                                _.remove(cacheFavouritesDetails, function (cacheFavouritesInstanceDetails) {
                                    return navbarService.areTheseStateSame(cacheFavouritesInstanceDetails.state, item.state);
                                });
                                item.onFavoriteChange();
                            }
                        }
                        else {
                            growl.error(userService.getError().getFirstKey(responseSetPreferences), { ttl: 5000 });
                        }
                    }, function () {
                        growl.error(userService.getError().getGenericKey(), { ttl: 5000 });
                    });
                });
            }
            // -- isFavourite --
            function isFavourite(item) {
                return _.some(cacheFavouritesDetails, function (cachedItem) {
                    return (cachedItem.stateLink === item.stateLink);
                });
            }
            function setCurrentState(state, params) {
                currentState = state;
                currentParams = params;
            }
            function isFavouriteState() {
                const deferred = $q.defer();
                userServicePreferencesPromise.then(function (favs) {
                    // @ts-ignore
                    _.some(favs, function (favouriteParam) {
                        if (currentState == favouriteParam.name) {
                            if (currentState != 'legacy') {
                                deferred.resolve(true);
                            }
                            else {
                                if (favouriteParam.params['uid'] == currentParams['uid']) {
                                    deferred.resolve(true);
                                }
                                else {
                                    deferred.resolve(false);
                                }
                            }
                        }
                    });
                    deferred.resolve(false);
                });
                return deferred.promise;
            }
            function toggleFavourite() {
                const item = megaMenuService.findSearchMenuByState(currentState, currentParams);
                return favourite(item.item);
            }
            // -- processFavourite --
            function processFavourite(item) {
                let returnObj;
                if (item && item.state) {
                    returnObj = {
                        'name': item.state.parent || item.state.name,
                        'params': item.state.params && item.state.params.uid ? { uid: item.state.params.uid } : undefined,
                        'parent': item.state.parent,
                        'date': item.date ? item.date : undefined
                    };
                }
                return Object.assign({}, returnObj);
            }
            function fetchFavouritesFromPreferences() {
                return userService.getPreferencesByKey(keyAdconFavourites).then(function (responseGetPreferencesByKey) {
                    if (!responseGetPreferencesByKey.err) {
                        if (responseGetPreferencesByKey.length === 0) {
                            return responseGetPreferencesByKey;
                        }
                        if (capabilitiesService.areCapabilitiesLoaded()) {
                            return populateFavoriteMenuBasedOnCaps(responseGetPreferencesByKey);
                        }
                        else {
                            return capabilitiesService.getCapabilities().then(function () {
                                return populateFavoriteMenuBasedOnCaps(responseGetPreferencesByKey);
                            });
                        }
                    }
                    else {
                        growl.error(userService.getError().getFirstKey(responseGetPreferencesByKey), { ttl: 5000 });
                    }
                }, function () {
                    growl.error(userService.getError().getGenericKey(), { ttl: 5000 });
                });
            }
            function populateFavoriteMenuBasedOnCaps(menuObj) {
                let promiseResolveCounter = 0;
                const deferred = $q.defer();
                const menuObjDirtyCheckCopy = angular.copy(menuObj);
                _.forEach(menuObj, function (menuItemInstance) {
                    if ($state.get(menuItemInstance.name)) {
                        navbarService.findMenuItemByState(menuItemInstance, menuItemInstance.params).then(function (responseFindMenuItemByState) {
                            promiseResolveCounter++;
                            if (responseFindMenuItemByState.item.label) {
                                responseFindMenuItemByState.item.date = menuItemInstance.date;
                                responseFindMenuItemByState.item.state.name = menuItemInstance.name;
                                responseFindMenuItemByState.item.state.params = menuItemInstance.params ? menuItemInstance.params : {};
                                responseFindMenuItemByState.item.isActive = responseFindMenuItemByState.item.caps ? navbarService.hasCap(responseFindMenuItemByState.item.caps) : true;
                                cacheFavouritesDetails.push(responseFindMenuItemByState.item);
                                cacheFavourites.push(menuItemInstance);
                                if (promiseResolveCounter === menuObjDirtyCheckCopy.length) {
                                    deferred.resolve(cacheFavourites);
                                }
                            }
                        });
                    }
                    else {
                        _.remove(menuObjDirtyCheckCopy, menuItemInstance);
                    }
                });
                return deferred.promise;
            }
        }]);
}());
