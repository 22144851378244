<mc-exchange-sync-and-recover-page>
  <mc-layout-list-table [hideHeader]="windowService.inIframe()"
                        keyTitle="$I18N_SYNC_RECOVER_EXCHANGE_SIT_LIST_TITLE"
                        keyDescription="$I18N_SYNC_RECOVER_EXCHANGE_SIT_LIST_DESCRIPTION"
                        kbUrl="https://community.mimecast.com/docs/DOC-2704"
                        extendClass="mc-sync-recover-exchange-snapshots nested-layout"
                        [isDataLoaded]="!(isLoadResponseEmpty$ | async)">


    <mc-table [data]="tableData$ | async"
              [columns]="columnList"
              [isLoading]="isLoading$ | async"
              translatePrefix="$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_LIST"
              (rowClick)="openItem($event)"
              [showHighlightedRow]="false">

      <mc-filters [metadata]="metaData$ | async"
                  (paginatorChange)="onPaginationChange($event)">
        <mc-filter-bundle-column-value-search [filterModel]="filterModel"
                                              (filtersChange)="onFilterChange($event)"
                                              placeholder="$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_LIST.FILTERS.SEARCH_FIELD.PLACEHOLDER">
        </mc-filter-bundle-column-value-search>
      </mc-filters>

      <mc-column-date key="created"></mc-column-date>
      <mc-column-date key="date"></mc-column-date>
      <mc-column-date key="expiry"></mc-column-date>
      <mc-column key="status">
        <mc-body-cell *mcBodyCellDef="let row">
          <div class="mc-status-text-container"
               [ngClass]="getStatusByRow(row).displayClasses">
            <span>{{ getStatusByRow(row).displayValue | translate:{number: row.phaseProgress} }}</span>
          </div>
        </mc-body-cell>
      </mc-column>
      <mc-column key="name">
        <mc-body-cell *mcBodyCellDef="let row">{{row.mailbox.displayableName}}</mc-body-cell>
      </mc-column>
      <mc-column key="mailbox">
        <mc-body-cell *mcBodyCellDef="let row">{{row.mailbox.emailAddress}}</mc-body-cell>
      </mc-column>
      <mc-column-actions key="right-column"
                         mcShowColumnConfig></mc-column-actions>

      <mc-empty-results iconClass="far fa-hdd"
                        keyTitle="$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_LIST.EMPTY_SEARCH.TITLE">
      </mc-empty-results>

    </mc-table>

    <mc-empty-results iconClass="far fa-hdd"
                      keyTitle="$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_LIST.EMPTY_RESULTS.TITLE">

      <mc-empty-body>{{ '$18N_SYNC_RECOVER_EXCHANGE_SNAPSHOT_LIST.EMPTY_RESULTS.DESCRIPTION' | translate }}</mc-empty-body>

    </mc-empty-results>

  </mc-layout-list-table>
</mc-exchange-sync-and-recover-page>
