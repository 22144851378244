"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SeaStarServerService = void 0;
const operators_1 = require("rxjs/operators");
/**
 * This class provides the http calls to Sea-Star-Server api endpoints needed to load Adcon3 URLs
 */
class SeaStarServerService {
    constructor(http) {
        this.http = http;
    }
    getAdcon3Boxes() {
        return this.http.post('/service/get-adcon3-boxes', undefined).pipe(operators_1.map((response) => {
            return response.first;
        }));
    }
}
exports.SeaStarServerService = SeaStarServerService;
