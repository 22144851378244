"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreatePolicyComponent = void 0;
const advancedFileTypes = require("../../actions/advanced-file-types.actions");
const categories = require("../../actions/categories.actions");
const applications = require("../../actions/applications.actions");
const connectors = require("../../actions/connectors.actions");
const policy = require("../../actions/policy.actions");
const sidebar_actions_1 = require("../../actions/sidebar.actions");
const sidebar = require("../../actions/sidebar.actions");
const reducers = require("../../reducers");
const application_actions_1 = require("../../../application-control/actions/application.actions");
const topApplications = require("../../../application-control/reducers");
const uuid = require("uuid/v4");
const moment = require("moment");
const user_location_sidebar_actions_1 = require("../../../../components/user-location-selector/actions/user-location-sidebar.actions");
const clp_channels_actions_1 = require("../../../../components/user-location-selector/actions/clp-channels.actions");
class CreatePolicyComponent {
    constructor(store, capabilitiesService, swgPolicyService, swgService) {
        this.store = store;
        this.capabilitiesService = capabilitiesService;
        this.swgPolicyService = swgPolicyService;
        this.swgService = swgService;
        this.hasBrowserIsolationEnabled = false;
    }
    ngOnInit() {
        this.policy$ = this.store.select(reducers.getSwgPolicy);
        this.wizardFlowType$ = this.store.select(reducers.getWizardFlowType);
        this.apiIsProcessing$ = this.store.select(reducers.getApiIsProcessing);
        this.loadingForEdit$ = this.store.select(reducers.getLoadingForEdit);
        this.webCategoryRulesSections$ = this.store.select(reducers.getWebCategoryRulesSections);
        this.categorySectionsLoading$ = this.store.select(reducers.getCategorySectionLoading);
        this.applications$ = this.store.select(reducers.getApplications);
        this.applicationsWithStatus$ = this.store.select(topApplications.getAllApplications);
        this.applicationCategoriesSet$ = this.store.select(topApplications.getApplicationCategoriesSet);
        this.isApplicationCategorySetDataLoading$ = this.store.select(topApplications.isApplicationCategorySetDataLoading);
        this.applicationsLoading$ = this.store.select(reducers.getApplicationsLoading);
        this.applicationsWithStatusLoading$ = this.store.select(topApplications.isChartLoading);
        this.advancedFileTypes$ = this.store.select(reducers.getAdvancedFileTypes);
        this.advancedFileTypesLoading$ = this.store.select(reducers.getAdvancedFileTypesLoading);
        this.allConnectors$ = this.store.select(reducers.getAllConnectors);
        this.allConnectorsLoading$ = this.store.select(reducers.getAllConnectorsLoading);
        this.providers$ = this.store.select(reducers.getProviders);
        this.providersLoading$ = this.store.select(reducers.getProvidersLoading);
        this.store.dispatch(new categories.SwgPolicyLoadCategorySections());
        this.store.dispatch(new advancedFileTypes.SwgPolicyLoadAdvancedFileTypes());
        this.store.dispatch(new applications.SwgPolicyLoadApplications());
        this.store.dispatch(new applications.SwgPolicyLoadProtectedApplications());
        this.store.dispatch(new application_actions_1.SwgGetApplicationChartInfoAction(this.getRange(7), uuid()));
        this.hasTTP$ = this.capabilitiesService.hasCapability('Temporary.Permission.swg.ttp');
        this.hasClp = this.swgService.hasClpIntegrationEnabled();
        if (this.hasClp) {
            this.store.dispatch(new connectors.SwgPolicyLoadConnectorsSections());
            this.store.dispatch(new connectors.SwgPolicyLoadProvidersSections());
        }
        this.hasSwgHybrid$ = this.capabilitiesService.hasCapability('SWG.Hybrid.Proxy');
        this.hasBrowserIsolationEnabled = this.swgService.hasBrowserIsolationEnabled();
        this.hasSwgHybridCaps = this.swgService.hasHybridProxySupport();
        this.hasApplicationPolicyControllableCaps = this.swgService.hasApplicationPolicyControllableOnlyEnabled();
        this.hasEditPermission$ = this.swgPolicyService.hasPolicyEditPermission();
        this.hasAppPolicyRemoveAllowEnabled = this.swgService.hasAppControlPolicyAllowRemoved();
        this.protectedApplicationsLoading$ = this.store.select(reducers.getProtectedApplicationsLoading);
        this.protectedApplications$ = this.store.select(reducers.getProtectedApplications);
    }
    updatePolicyDescType($event) {
        this.store.dispatch(new policy.SwgUpdatePolicyDetailsStep($event));
    }
    updatePolicyDomains($event) {
        this.store.dispatch(new policy.SwgUpdateDomainsStep($event));
    }
    updateLoggingLevel($event) {
        this.store.dispatch(new policy.SwgUpdateLogDetailsStep($event));
    }
    updateSafeSearchSettings($event) {
        this.store.dispatch(new policy.SwgUpdateSafeSearchSettingsStep($event));
    }
    updateNewDomainsSettings($event) {
        this.store.dispatch(new policy.SwgUpdateNewDomainsSettingsStep($event));
    }
    updateProxySettings($event) {
        this.store.dispatch(new policy.SwgUpdateDNSProxySettingsStep($event));
    }
    updateTtpManagedUrlSettings($event) {
        this.store.dispatch(new policy.SwgTtpManagedUrlSettingsStep($event));
    }
    updateTtpChameleonSettings($event) {
        this.store.dispatch(new policy.SwgTtpChameleonSettingsStep($event));
    }
    updateprotectedApplicationsSettings($event) {
        this.store.dispatch(new policy.SwgUpdateProtectedApplications($event));
    }
    updateWebApplicationRule($event) {
        this.store.dispatch(new policy.SwgUpdateWebApplicationRuleStep($event));
    }
    updateWebCategorySecurityRules($event) {
        this.store.dispatch(new policy.SwgUpdateWebCategorySecurityStep($event));
    }
    updateWebCategoryOtherRules($event) {
        this.store.dispatch(new policy.SwgUpdateWebCategoryOthersStep($event));
    }
    updateAffectedUsers($event) {
        this.store.dispatch(new policy.SwgUpdateAffectedUsersStep($event));
    }
    updateClpSettings($event) {
        this.store.dispatch(new policy.SwgUpdateClpPolicySettingsStep($event));
    }
    removeAffectedUsers($event) {
        if (['clp_notifications_user', 'clp_notifications_channel', 'clp_notifications_group'].indexOf($event.condition) !== -1) {
            this.store.dispatch(new policy.SwgFromClpNotificationListStep($event));
        }
        else {
            this.store.dispatch(new policy.SwgRemoveAffectedUsersStep($event));
        }
    }
    everyOneAffectedUsers($event) {
        this.store.dispatch(new user_location_sidebar_actions_1.UserLocationAppliesToClose());
        this.store.dispatch(new policy.SwgEveryoneAffectedUsersStep($event));
    }
    openAppliesToSidePanel(value) {
        this.store.dispatch(new clp_channels_actions_1.LoadNotificationTeamsClear());
        this.store.dispatch(new clp_channels_actions_1.LoadNotificationChannelsClear());
        this.store.dispatch(new user_location_sidebar_actions_1.UserLocationAppliesToClose());
        this.store.dispatch(new user_location_sidebar_actions_1.UserLocationAppliesToOpen(value));
    }
    openBulkUploadDomains() {
        this.store.dispatch(new sidebar.SwgDomainsCsvUploaderOpen());
    }
    createPolicy($event) {
        this.store.dispatch(new policy.SwgCreatePolicyAction($event));
    }
    resetWizard() {
        this.store.dispatch(new policy.SwgClearPolicy());
        this.store.dispatch(new sidebar_actions_1.SwgPolicyNavigateToListPageAction());
    }
    getRange(days, shift = false) {
        // @ts-ignore
        return {
            start: moment()
                .startOf('day')
                .subtract(shift ? days * 2 : days, 'days')
                .format(),
            end: moment()
                .startOf('day')
                .subtract(shift ? days : 0, 'days')
                .format()
        };
    }
    updatePolicyOverrideStatus(override) {
        this.store.dispatch(new policy.SwgUpdatePolicyOverrideStatus(override));
    }
    clearAffectedUsersOtherThan(targetType) {
        this.store.dispatch(new policy.SwgClearAffectedUsersOtherThan(targetType));
    }
    ngOnDestroy() {
        this.store.dispatch(new categories.SwgPolicyClearCategorySections());
        this.store.dispatch(new advancedFileTypes.SwgPolicyClearAdvancedFileTypes());
    }
}
exports.CreatePolicyComponent = CreatePolicyComponent;
