"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getReportsState = exports.getRuleDetailsPagination = exports.getRuleDetails = exports.getRuleDetailsFailure = exports.getRuleDetailsLoading = exports.getRuleDetailsState = exports.getQueueDetailsPagination = exports.getQueueDetails = exports.getQueueDetailsFailure = exports.getQueueDetailsLoading = exports.getQueueDetailsState = exports.getSelectedReviewers = exports.getReviewers = exports.getQueueLoading = exports.getEscalationQueues = exports.isLoadingQueueListDetails = exports.getQueueListDetails = exports.getQueueListState = exports.isLoadingLexiconListDetails = exports.getLexiconListDetails = exports.getLexiconDetailsPagination = exports.getLexiconListState = exports.getSelectedRuleQueue = exports.getRuleQueues = exports.getSelectedRuleLexicon = exports.getRuleLexicons = exports.getRuleFailure = exports.getRuleLoading = exports.getRuleSaving = exports.getRuleState = exports.getQueueFailure = exports.getQueueSaving = exports.getQueueState = exports.isValidatingSearchQuery = exports.getLexiconValidationError = exports.getLexiconDetails = exports.getLexiconFailure = exports.getLexiconLoading = exports.getLexiconSaving = exports.getLexiconState = exports.getStepData = exports.isStepValid = exports.getStepById = exports.isCurrentStepValid = exports.getCurrentStepState = exports.getCurrentStep = exports.getWizardById = exports.getWizardState = exports.getSupervisionState = exports.reducers = void 0;
exports.ɵ13 = exports.ɵ12 = exports.ɵ11 = exports.ɵ10 = exports.ɵ9 = exports.ɵ8 = exports.ɵ7 = exports.ɵ6 = exports.ɵ5 = exports.ɵ4 = exports.ɵ3 = exports.ɵ2 = exports.ɵ1 = exports.ɵ0 = exports.getDashboardSettingsSuccessfullySaved = exports.getDashboardSettingsSelectedQueueType = exports.getDashboardSettingsLoading = exports.getDashboardSettings = exports.getDashboardSettingsState = exports.getDashboardStatistics = exports.getDashboardLoading = exports.getStatisticState = exports.getReportDownloading = void 0;
const lexiconListReducer = require("./lexicon.list.reducer");
const queueListReducer = require("./queue.list.reducer");
const lexiconReducer = require("./lexicon.reducer");
const queueReducer = require("./queue.reducer");
const ruleReducer = require("./rule.reducer");
const ruleListReducer = require("./rule.list.reducer");
const wizardReducer = require("./wizard.reducer");
const queueDetailsReducer = require("./queue.details.reducer");
const ruleDetailsReducer = require("./rule.details.reducer");
const reportsReducer = require("./reports.reducer");
const statisticsReducer = require("./statistics.reducer");
const settingsReducer = require("./dashboard-settings.reducer");
const store_1 = require("@ngrx/store");
const byTotalCountThenNewInLastSevenDaysThenPendingCountThenOldestPendingHoursThenName = (a, b) => b.totalCount - a.totalCount ||
    b.newInLastSevenDays - a.newInLastSevenDays ||
    b.pendingCount - a.pendingCount ||
    b.oldestPendingHours - a.oldestPendingHours ||
    a.name.localeCompare(b.name);
const ɵ0 = byTotalCountThenNewInLastSevenDaysThenPendingCountThenOldestPendingHoursThenName;
exports.ɵ0 = ɵ0;
exports.reducers = {
    wizard: wizardReducer.reducer,
    lexicon: lexiconReducer.reducer,
    rule: ruleReducer.reducer,
    queue: queueReducer.reducer,
    queueDetails: queueDetailsReducer.reducer,
    ruleDetailsPagination: ruleListReducer.reducer,
    ruleDetails: ruleDetailsReducer.reducer,
    reports: reportsReducer.reducer,
    statistics: statisticsReducer.reducer,
    settings: settingsReducer.reducer,
    lexiconList: lexiconListReducer.reducer,
    queueList: queueListReducer.reducer
};
exports.getSupervisionState = store_1.createFeatureSelector('archive-supervision');
const ɵ1 = (state) => state.wizard;
exports.ɵ1 = ɵ1;
exports.getWizardState = store_1.createSelector(exports.getSupervisionState, ɵ1);
const getWizardById = (wizardId) => store_1.createSelector(exports.getWizardState, wizardReducer.getWizardById(wizardId));
exports.getWizardById = getWizardById;
const getCurrentStep = (wizardId) => store_1.createSelector(exports.getWizardById(wizardId), wizardReducer.getCurrentStep);
exports.getCurrentStep = getCurrentStep;
const getCurrentStepState = (wizardId) => store_1.createSelector(exports.getWizardById(wizardId), wizardReducer.getCurrentStepState);
exports.getCurrentStepState = getCurrentStepState;
const isCurrentStepValid = (wizardId) => store_1.createSelector(exports.getCurrentStepState(wizardId), wizardReducer.isStepValid);
exports.isCurrentStepValid = isCurrentStepValid;
const getStepById = (wizardId, stepId) => store_1.createSelector(exports.getWizardById(wizardId), wizardReducer.getStepById(stepId));
exports.getStepById = getStepById;
const isStepValid = (wizardId, stepId) => store_1.createSelector(exports.getStepById(wizardId, stepId), wizardReducer.isStepValid);
exports.isStepValid = isStepValid;
const getStepData = (wizardId, stepId) => store_1.createSelector(exports.getStepById(wizardId, stepId), wizardReducer.getStepData);
exports.getStepData = getStepData;
const ɵ2 = (state) => state.lexicon;
exports.ɵ2 = ɵ2;
exports.getLexiconState = store_1.createSelector(exports.getSupervisionState, ɵ2);
exports.getLexiconSaving = store_1.createSelector(exports.getLexiconState, lexiconReducer.getSaving);
exports.getLexiconLoading = store_1.createSelector(exports.getLexiconState, lexiconReducer.getLoading);
exports.getLexiconFailure = store_1.createSelector(exports.getLexiconState, lexiconReducer.getFailure);
exports.getLexiconDetails = store_1.createSelector(exports.getLexiconState, lexiconReducer.getLexiconDetails);
exports.getLexiconValidationError = store_1.createSelector(exports.getLexiconState, lexiconReducer.getValidationError);
exports.isValidatingSearchQuery = store_1.createSelector(exports.getLexiconState, lexiconReducer.isValidatingSearchQuery);
const ɵ3 = (state) => state.queue;
exports.ɵ3 = ɵ3;
exports.getQueueState = store_1.createSelector(exports.getSupervisionState, ɵ3);
exports.getQueueSaving = store_1.createSelector(exports.getQueueState, queueReducer.getSaving);
exports.getQueueFailure = store_1.createSelector(exports.getQueueState, queueReducer.getFailure);
const ɵ4 = (state) => state.rule;
exports.ɵ4 = ɵ4;
exports.getRuleState = store_1.createSelector(exports.getSupervisionState, ɵ4);
exports.getRuleSaving = store_1.createSelector(exports.getRuleState, ruleReducer.getSaving);
exports.getRuleLoading = store_1.createSelector(exports.getRuleState, ruleReducer.getLoading);
exports.getRuleFailure = store_1.createSelector(exports.getRuleState, ruleReducer.getFailure);
exports.getRuleLexicons = store_1.createSelector(exports.getRuleState, ruleReducer.getLexicons);
exports.getSelectedRuleLexicon = store_1.createSelector(exports.getRuleState, ruleReducer.getSelectedLexicon);
exports.getRuleQueues = store_1.createSelector(exports.getRuleState, ruleReducer.getQueues);
exports.getSelectedRuleQueue = store_1.createSelector(exports.getRuleState, ruleReducer.getSelectedQueue);
const ɵ5 = (state) => state.lexiconList;
exports.ɵ5 = ɵ5;
exports.getLexiconListState = store_1.createSelector(exports.getSupervisionState, ɵ5);
exports.getLexiconDetailsPagination = store_1.createSelector(exports.getLexiconListState, lexiconListReducer.getLexiconDetailsPagination);
exports.getLexiconListDetails = store_1.createSelector(exports.getLexiconListState, lexiconListReducer.getLexiconDetails);
exports.isLoadingLexiconListDetails = store_1.createSelector(exports.getLexiconListState, lexiconListReducer.isLoadingLexiconDetails);
const ɵ6 = (state) => state.queueList;
exports.ɵ6 = ɵ6;
exports.getQueueListState = store_1.createSelector(exports.getSupervisionState, ɵ6);
exports.getQueueListDetails = store_1.createSelector(exports.getQueueListState, queueListReducer.getQueueConfigDetails);
exports.isLoadingQueueListDetails = store_1.createSelector(exports.getQueueListState, queueListReducer.isLoadingQueueConfigDetails);
exports.getEscalationQueues = store_1.createSelector(exports.getQueueState, queueReducer.getEscalationQueues);
exports.getQueueLoading = store_1.createSelector(exports.getQueueState, queueReducer.getLoading);
exports.getReviewers = store_1.createSelector(exports.getQueueState, queueReducer.getReviewers);
exports.getSelectedReviewers = store_1.createSelector(exports.getQueueState, queueReducer.getSelectedReviewers);
const ɵ7 = (state) => state.queueDetails;
exports.ɵ7 = ɵ7;
exports.getQueueDetailsState = store_1.createSelector(exports.getSupervisionState, ɵ7);
exports.getQueueDetailsLoading = store_1.createSelector(exports.getQueueDetailsState, queueDetailsReducer.getLoading);
exports.getQueueDetailsFailure = store_1.createSelector(exports.getQueueDetailsState, queueDetailsReducer.getFailure);
exports.getQueueDetails = store_1.createSelector(exports.getQueueDetailsState, queueDetailsReducer.getQueueDetails);
exports.getQueueDetailsPagination = store_1.createSelector(exports.getQueueDetailsState, queueDetailsReducer.getDetailsPaginationInfo);
const ɵ8 = (state) => state.ruleDetails;
exports.ɵ8 = ɵ8;
exports.getRuleDetailsState = store_1.createSelector(exports.getSupervisionState, ɵ8);
exports.getRuleDetailsLoading = store_1.createSelector(exports.getRuleDetailsState, ruleDetailsReducer.getLoading);
exports.getRuleDetailsFailure = store_1.createSelector(exports.getRuleDetailsState, ruleDetailsReducer.getFailure);
exports.getRuleDetails = store_1.createSelector(exports.getRuleDetailsState, ruleDetailsReducer.getruleDetails);
exports.getRuleDetailsPagination = store_1.createSelector(exports.getRuleDetailsState, ruleDetailsReducer.getDetailsPaginationInfo);
const ɵ9 = (state) => state.reports;
exports.ɵ9 = ɵ9;
exports.getReportsState = store_1.createSelector(exports.getSupervisionState, ɵ9);
exports.getReportDownloading = store_1.createSelector(exports.getReportsState, reportsReducer.getDownloading);
const ɵ10 = (state) => state.statistics;
exports.ɵ10 = ɵ10;
exports.getStatisticState = store_1.createSelector(exports.getSupervisionState, ɵ10);
const ɵ11 = (stats) => stats && stats.loading;
exports.ɵ11 = ɵ11;
exports.getDashboardLoading = store_1.createSelector(exports.getStatisticState, ɵ11);
const ɵ12 = (stats) => {
    let tempStats = stats;
    if (stats && stats.statistics) {
        const statistics = Object.assign(Object.assign({}, stats.statistics), { reviewQueueDetails: sort(stats.statistics.reviewQueueDetails), escalationQueueDetails: sort(stats.statistics.escalationQueueDetails) });
        tempStats = Object.assign(Object.assign({}, stats), { statistics });
    }
    return statisticsReducer.getDashboardStatistics(tempStats);
};
exports.ɵ12 = ɵ12;
exports.getDashboardStatistics = store_1.createSelector(exports.getStatisticState, ɵ12);
const ɵ13 = (state) => state.settings;
exports.ɵ13 = ɵ13;
exports.getDashboardSettingsState = store_1.createSelector(exports.getSupervisionState, ɵ13);
exports.getDashboardSettings = store_1.createSelector(exports.getDashboardSettingsState, settingsReducer.getDashboardSettings);
exports.getDashboardSettingsLoading = store_1.createSelector(exports.getDashboardSettingsState, settingsReducer.getDashboardSettingsLoading);
exports.getDashboardSettingsSelectedQueueType = store_1.createSelector(exports.getDashboardSettingsState, settingsReducer.getDashboardSettingsSelectedQueueType);
exports.getDashboardSettingsSuccessfullySaved = store_1.createSelector(exports.getDashboardSettingsState, settingsReducer.getDashboardSettingsSetStatus);
function sort(queueDetailsData) {
    return [...queueDetailsData].sort(byTotalCountThenNewInLastSevenDaysThenPendingCountThenOldestPendingHoursThenName);
}
