"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApPolicyCancelModalSource = exports.AppliesTo = exports.AppliesFrom = exports.AddressBasedOption = exports.UserNotificationOption = exports.SpreadsheetsFormatOption = exports.PreEmptiveFailureActionOption = exports.DocumentFormatOption = exports.ConfigurationDetailOption = exports.UserMailboxFallbackActionOption = exports.UserMailboxActionOption = exports.DurationDetailOption = exports.ApPolicyWizardFlowType = exports.AttributeDefaultOption = exports.ApConfigurationType = exports.SenderMailboxFallbackActionOption = exports.GatewayFallbackActionOption = exports.SenderMailboxActionOption = exports.GatewayActionOption = void 0;
var GatewayActionOption;
(function (GatewayActionOption) {
    GatewayActionOption["NONE"] = "none";
    GatewayActionOption["HOLD"] = "hold";
    GatewayActionOption["BOUNCE"] = "bounce";
})(GatewayActionOption = exports.GatewayActionOption || (exports.GatewayActionOption = {}));
var SenderMailboxActionOption;
(function (SenderMailboxActionOption) {
    SenderMailboxActionOption["NONE"] = "none";
    SenderMailboxActionOption["REMOVEMESSAGE"] = "remove_email";
    SenderMailboxActionOption["REMOVEATTACHMENT"] = "remove_attachment";
})(SenderMailboxActionOption = exports.SenderMailboxActionOption || (exports.SenderMailboxActionOption = {}));
var GatewayFallbackActionOption;
(function (GatewayFallbackActionOption) {
    GatewayFallbackActionOption["NONE"] = "none";
    GatewayFallbackActionOption["HOLD"] = "hold";
    GatewayFallbackActionOption["BOUNCE"] = "bounce";
})(GatewayFallbackActionOption = exports.GatewayFallbackActionOption || (exports.GatewayFallbackActionOption = {}));
var SenderMailboxFallbackActionOption;
(function (SenderMailboxFallbackActionOption) {
    SenderMailboxFallbackActionOption["NONE"] = "none";
    SenderMailboxFallbackActionOption["REMOVEMESSAGE"] = "remove_email";
    SenderMailboxFallbackActionOption["REMOVEATTACHMENT"] = "remove_attachment";
})(SenderMailboxFallbackActionOption = exports.SenderMailboxFallbackActionOption || (exports.SenderMailboxFallbackActionOption = {}));
var ApConfigurationType;
(function (ApConfigurationType) {
    ApConfigurationType["SAFEDOC_ONLY"] = "safedoc_only";
    ApConfigurationType["SANDBOX"] = "sandbox";
    ApConfigurationType["DYNAMIC"] = "dynamic";
    ApConfigurationType["SAFEDOC_AND_LINK"] = "safedoc_and_link";
})(ApConfigurationType = exports.ApConfigurationType || (exports.ApConfigurationType = {}));
var AttributeDefaultOption;
(function (AttributeDefaultOption) {
    AttributeDefaultOption["DEFAULT"] = "sandbox";
})(AttributeDefaultOption = exports.AttributeDefaultOption || (exports.AttributeDefaultOption = {}));
var ApPolicyWizardFlowType;
(function (ApPolicyWizardFlowType) {
    ApPolicyWizardFlowType["CREATE"] = "create";
    ApPolicyWizardFlowType["DUPLIATE"] = "duplicate";
    ApPolicyWizardFlowType["EDIT"] = "edit";
})(ApPolicyWizardFlowType = exports.ApPolicyWizardFlowType || (exports.ApPolicyWizardFlowType = {}));
var DurationDetailOption;
(function (DurationDetailOption) {
    DurationDetailOption["PERPETUAL"] = "perpetual";
    DurationDetailOption["CUSTOMRANGE"] = "CUSTOM";
})(DurationDetailOption = exports.DurationDetailOption || (exports.DurationDetailOption = {}));
var UserMailboxActionOption;
(function (UserMailboxActionOption) {
    UserMailboxActionOption["NONE"] = "none";
    UserMailboxActionOption["REMOVEMESSAGE"] = "remove_email";
    UserMailboxActionOption["REMOVEATTACHMENT"] = "remove_attachment";
})(UserMailboxActionOption = exports.UserMailboxActionOption || (exports.UserMailboxActionOption = {}));
var UserMailboxFallbackActionOption;
(function (UserMailboxFallbackActionOption) {
    UserMailboxFallbackActionOption["NONE"] = "none";
    UserMailboxFallbackActionOption["REMOVEMESSAGE"] = "remove_email";
    UserMailboxFallbackActionOption["REMOVEATTACHMENT"] = "remove_attachment";
})(UserMailboxFallbackActionOption = exports.UserMailboxFallbackActionOption || (exports.UserMailboxFallbackActionOption = {}));
var ConfigurationDetailOption;
(function (ConfigurationDetailOption) {
    ConfigurationDetailOption["SAFEFILE"] = "safedoc_only";
    ConfigurationDetailOption["SAFEFILEONDEMAND"] = "safedoc_and_link";
    ConfigurationDetailOption["PREEXEMPTIVE"] = "sandbox";
    ConfigurationDetailOption["DYNAMICCONFIGURATION"] = "dynamic";
})(ConfigurationDetailOption = exports.ConfigurationDetailOption || (exports.ConfigurationDetailOption = {}));
var DocumentFormatOption;
(function (DocumentFormatOption) {
    DocumentFormatOption["PDF"] = "pdf";
    DocumentFormatOption["TIFF"] = "tiff";
    DocumentFormatOption["ORIGINALFORMAT"] = "original";
})(DocumentFormatOption = exports.DocumentFormatOption || (exports.DocumentFormatOption = {}));
var PreEmptiveFailureActionOption;
(function (PreEmptiveFailureActionOption) {
    PreEmptiveFailureActionOption["BOUNCE"] = "bounce";
    PreEmptiveFailureActionOption["ADMINHOLD"] = "adminhold";
})(PreEmptiveFailureActionOption = exports.PreEmptiveFailureActionOption || (exports.PreEmptiveFailureActionOption = {}));
var SpreadsheetsFormatOption;
(function (SpreadsheetsFormatOption) {
    SpreadsheetsFormatOption["PDF"] = "pdf";
    SpreadsheetsFormatOption["TIFF"] = "tiff";
    SpreadsheetsFormatOption["ORIGINALFORMAT"] = "original";
    SpreadsheetsFormatOption["HTML"] = "html";
    SpreadsheetsFormatOption["HTMLMULTITAB"] = "html_zip";
    SpreadsheetsFormatOption["CSV"] = "csv";
})(SpreadsheetsFormatOption = exports.SpreadsheetsFormatOption || (exports.SpreadsheetsFormatOption = {}));
var UserNotificationOption;
(function (UserNotificationOption) {
    UserNotificationOption["INTERNALRECIPIENT"] = "Internal Recipient";
    UserNotificationOption["EXTERNALRECIPIENT"] = "External Recipient";
    UserNotificationOption["INTERNALSENDER"] = "Internal Sender";
})(UserNotificationOption = exports.UserNotificationOption || (exports.UserNotificationOption = {}));
var AddressBasedOption;
(function (AddressBasedOption) {
    AddressBasedOption["ENVELOPEFROMADDRESSES"] = "envelope_from";
    AddressBasedOption["HEADERFROMADDRESSES"] = "header_from";
    AddressBasedOption["ENVELOPEANDHEADERADDRESSES"] = "both";
})(AddressBasedOption = exports.AddressBasedOption || (exports.AddressBasedOption = {}));
var AppliesFrom;
(function (AppliesFrom) {
    AppliesFrom["EVERYONE"] = "everyone";
    AppliesFrom["ALLINTERNAL"] = "internal_addresses";
    AppliesFrom["ALLEXTERNAL"] = "external_addresses";
    AppliesFrom["DOMAIN"] = "email_domain";
    AppliesFrom["ADDRESSGROUP"] = "profile_group";
    AppliesFrom["ADDRESSATTRIBUTE"] = "address_attribute_value";
    AppliesFrom["EMAILADDRESS"] = "individual_email_address";
    AppliesFrom["GROUP"] = "profile_group";
})(AppliesFrom = exports.AppliesFrom || (exports.AppliesFrom = {}));
var AppliesTo;
(function (AppliesTo) {
    AppliesTo["EVERYONE"] = "everyone";
    AppliesTo["ALLINTERNAL"] = "internal_addresses";
    AppliesTo["ALLEXTERNAL"] = "external_addresses";
    AppliesTo["DOMAIN"] = "email_domain";
    AppliesTo["ADDRESSGROUP"] = "profile_group";
    AppliesTo["ADDRESSATTRIBUTE"] = "address_attribute_value";
    AppliesTo["EMAILADDRESS"] = "individual_email_address";
    AppliesTo["GROUP"] = "profile_group";
})(AppliesTo = exports.AppliesTo || (exports.AppliesTo = {}));
var ApPolicyCancelModalSource;
(function (ApPolicyCancelModalSource) {
    ApPolicyCancelModalSource[ApPolicyCancelModalSource["CREATE_POLICY"] = 0] = "CREATE_POLICY";
    ApPolicyCancelModalSource[ApPolicyCancelModalSource["EDIT_POLICY"] = 1] = "EDIT_POLICY";
})(ApPolicyCancelModalSource = exports.ApPolicyCancelModalSource || (exports.ApPolicyCancelModalSource = {}));
