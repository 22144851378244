<mc-layout-dashboard-simple
  keyTitle="$I18N_REMEDIATION_CONTAINER.PAGE_TITLE"
  keyDescription="{{getDesc()}}"
  extendClass=""
  showFavourite="false"
>
  <mc-help-container>
    <mc-help-link url="https://community.mimecast.com/docs/DOC-2981"></mc-help-link>
  </mc-help-container>
  <mc-body-container>
    <mc-tab-group (tabChanged)="hasSearchTab ? onTabChange($event) : ''">
      <mc-tab *ngIf="isIEPCapabilityEnabled$ | async" name="{{ remediationDashboardTabs[0].label | translate }}">
        <div class="tab-container">
          <mc-remediation-overview-tab [hasSearchEnabled] = "isSearchTabDisplayed$ | async"
                                       [isIdSearchDateRangeEnabled]="isIdSearchDateRangeEnabled$ | async">
          </mc-remediation-overview-tab></div>
      </mc-tab>
      <mc-tab *ngIf="isSearchTabDisplayed$ | async" name="{{ remediationDashboardTabs[1].label | translate }}">
        <div class="tab-container">
          <mc-remediation-search-messages-list [hasSearchEnabled]="isSearchEnabled$ | async"
                                               [isIdSearchDateRangeEnabled]="isIdSearchDateRangeEnabled$ | async">
          </mc-remediation-search-messages-list>
        </div>
      </mc-tab>
      <mc-tab name="{{ remediationDashboardTabs[2].label | translate }}">
        <div class="tab-container">
          <mc-incident-tab [incidentTypeFilterOptions]="incidentTypeFilterOptions$ | async">
          </mc-incident-tab>
        </div>
      </mc-tab>
      <mc-tab name="{{ remediationDashboardTabs[3].label | translate }}">
        <div class="tab-container">
          <mc-logs-tab></mc-logs-tab>
        </div>
      </mc-tab>
      <mc-tab name="{{ remediationDashboardTabs[4].label | translate }}">
          <div class="tab-container">
            <mc-remediation-settings-panel
              [isIEPCapabilityEnabled]="isIEPCapabilityEnabled$ | async"
              [isMEPCapabilitySwitchEnabled]="isMEPCapabilitySwitchEnabled$ | async">
            </mc-remediation-settings-panel>
          </div>
      </mc-tab>
    </mc-tab-group>
  </mc-body-container>
</mc-layout-dashboard-simple>
