<mc-layout-detail-wizard
  keyTitle="{{ getPageTitle() }}"
  keyDescription="{{ getPageSubTitle(isEditFlow()) }}"
  extendClass="panel-padding-bottom"
>
  <mc-help-container>
    <mc-help-link url="https://community.mimecast.com/docs/DOC-3187"></mc-help-link>
  </mc-help-container>
  <mc-tab-group *ngIf="isEditFlow()" [direction]="'vertical'">
    <mc-tab name="{{ '$I18N_SWG_CREATE_POLICY_DETAILS' | translate }}">
      <mc-spinner *ngIf="loadingForEdit"></mc-spinner>
      <mc-swg-policy-details-step
        *ngIf="!loadingForEdit"
        (updatePolicyDescType)="updatePolicyDescTypeWiz.emit($event)"
        [policy]="policy"
        [isEditFlow]="isEditFlow()"
        (enterPressedEvent)="enterPressedEvent($event)"
        [hasAppPolicyRemoveAllowEnabled]="hasAppPolicyRemoveAllowEnabled"
      ></mc-swg-policy-details-step>
    </mc-tab>
    <mc-tab
      name="{{ '$I18N_SWG_CREATE_POLICY_DOMAINS' | translate }}"
      *ngIf="policy.policyDesc.policyType === 'WEB_BLOCK_ALLOW_LIST'"
    >
      <mc-swg-policy-domains-step
        *ngIf="!loadingForEdit"
        [hasTTP]="hasTTP"
        (updatePolicyDomains)="updatePolicyDomainsWiz.emit($event)"
        (openBulkUploadDomains)="openBulkUploadDomainsWiz.emit($event)"
        [domains]="policy.domains"
      ></mc-swg-policy-domains-step>
    </mc-tab>

    <mc-tab
      name="{{ '$I18N_SWG_CREATE_POLICY_WEB_LOGGING' | translate }}"
      *ngIf="policy.policyDesc.policyType === 'WEB_LOGGING'"
    >
      <mc-swg-policy-logging-step
        *ngIf="!loadingForEdit"
        [loggingMode]="policy.loggingMode"
        (updateLoggingLevel)="updateLoggingLevelWiz.emit($event)"
      ></mc-swg-policy-logging-step>
    </mc-tab>


    <mc-tab
      name="{{'$I18N_SWG_CREATE_POLICY_WEB_APPLICATION_CONTROL_TITLE' | translate}}"
      *ngIf="policy.policyDesc.policyType === 'WEB_APPLICATION_CONTROL'"
    >
      <mc-spinner *ngIf="loadingForEdit || applicationsWithStatusLoading"></mc-spinner>
      <mc-swg-application-control-step
        *ngIf="!loadingForEdit && !applicationsWithStatusLoading"
        [userWebApplicationRules]="policy.webApplicationRules"
        [applications]="getControllableApplications(applicationsWithStatus)"
        [isApplicationCategorySetDataLoading]="isApplicationCategorySetDataLoading"
        [applicationCategoriesSet]="applicationCategoriesSet"
        [applicationsLoading]="applicationsLoading"
        [hasAppPolicyRemoveAllowEnabled]="hasAppPolicyRemoveAllowEnabled"
        (updateWebApplicationRule)="updateWebApplicationRuleWiz.emit($event)"
      ></mc-swg-application-control-step>
    </mc-tab>

    <mc-tab
      name="{{ '$I18N_SWG_CREATE_POLICY_WEB_ADVANCED_SECURITY_SAFE_SEARCH_STEP' | translate }}"
      *ngIf="policy.policyDesc.policyType === 'WEB_ADVANCED_SECURITY'"
    >
      <mc-swg-policy-safe-search-step
        *ngIf="!loadingForEdit"
        (updateSafeSearchSettings)="updateSafeSearchSettingsWiz.emit($event)"
        [safeSearchSettings]="policy.safeSearchSettings"
      ></mc-swg-policy-safe-search-step>
    </mc-tab>
    <mc-tab
      name="{{ '$I18N_SWG_CREATE_POLICY_WEB_ADVANCED_SECURITY_NEW_DOMAINS_STEP' | translate }}"
      *ngIf="policy.policyDesc.policyType === 'WEB_ADVANCED_SECURITY'"
    >
      <mc-swg-new-domains-step
        *ngIf="!loadingForEdit"
        (updateNewDomainsSettings)="updateNewDomainsSettingsWiz.emit($event)"
        [newDomainsSettings]="policy.newDomainsSettings"
      ></mc-swg-new-domains-step>
    </mc-tab>
    <mc-tab
      name="{{ '$I18N_SWG_CREATE_POLICY_WEB_ADVANCED_SECURITY_DNS_PROXY_STEP' | translate }}"
      *ngIf="policy.policyDesc.policyType === 'WEB_ADVANCED_SECURITY'"
    >
      <mc-swg-proxy-settings-step
        *ngIf="!loadingForEdit && !advancedFileTypesLoading && !categorySectionsLoading"
        (updateProxySettings)="updateProxySettings($event)"
        [proxySettings]="policy.proxySettings"
        [advancedFileTypes]="advancedFileTypes"
        (updateWebCategoryRules)="updateProtectedApplicationsWiz.emit($event)"
        [webCategoryRuleSections]="getAppSecSections(protectedApplications, policy.protectedApplications)"
        [hasExtendedProxy]="hasSwgHybrid"
        [hasBI]="hasBrowserIsolationEnabled"
        [protectedApplicationsLoading]="protectedApplicationsLoading"
      ></mc-swg-proxy-settings-step>
    </mc-tab>

    <mc-tab
      name="{{ '$I18N_SWG_CREATE_POLICY_SECURITY_CATEGORY' | translate }}"
      *ngIf="policy.policyDesc.policyType === 'WEB_CATEGORY_FILTERING'"
    >
      <mc-spinner *ngIf="categorySectionsLoading || loadingForEdit"></mc-spinner>
      <mc-swg-policy-category-filter-step
        *ngIf="!loadingForEdit && !categorySectionsLoading"
        [expanded]="true"
        title="{{'$I18N_SWG_CREATE_POLICY_WEB_CATEGORY_FILTERING_TITLE' | translate }}"
        (updateWebCategoryRules)="updateWebCategorySecurityRulesWiz.emit($event)"
        [usersWebCategoryRules]="policy.webCategorySecurityRules"
        [webCategoryRuleSections]="getSecuritySections(webCategoryRulesSections, policy.webCategorySecurityRules)"
      ></mc-swg-policy-category-filter-step>
    </mc-tab>

    <mc-tab
      name="{{ '$I18N_SWG_CREATE_POLICY_CONTENT_CATEGORY' | translate }}"
      *ngIf="policy.policyDesc.policyType === 'WEB_CATEGORY_FILTERING'"
    >
      <mc-spinner *ngIf="categorySectionsLoading || loadingForEdit"></mc-spinner>
      <mc-swg-policy-category-filter-step
        *ngIf="!loadingForEdit && !categorySectionsLoading"
        [expanded]="false"
        title="{{'$I18N_SWG_CREATE_POLICY_WEB_CATEGORY_FILTERING_TITLE' | translate }}"
        (updateWebCategoryRules)="updateWebCategoryOtherRulesWiz.emit($event)"
        [usersWebCategoryRules]="policy.webCategoryOtherRules"
        [webCategoryRuleSections]="getNonSecuritySections(webCategoryRulesSections, policy.webCategoryOtherRules)"
      ></mc-swg-policy-category-filter-step>
    </mc-tab>

    <mc-tab
      name="{{ '$I18N_SWG_CREATE_POLICY_TTP_POLICY_MANAGED_URLS' | translate }}"
      *ngIf="policy.policyDesc.policyType === 'WEB_TTP_POLICY'"
    >
      <mc-swg-policy-ttp-managed-urls-step
        *ngIf="!loadingForEdit"
        [hasTTP]="hasTTP"
        (updateTtpManagedUrlSettings)="updateTtpManagedUrlSettingsWiz.emit($event)"
        [ttpManagedUrlSettings]="policy.ttpManagedUrlSettings"
      ></mc-swg-policy-ttp-managed-urls-step>
    </mc-tab>
    <mc-tab
      name="{{ '$I18N_SWG_CREATE_POLICY_TTP_POLICY_CHAMELEON' | translate }}"
      *ngIf="policy.policyDesc.policyType === 'WEB_TTP_POLICY'"
    >
      <mc-swg-policy-ttp-chameleon-step
        *ngIf="!loadingForEdit"
        (updateTtpChameleonSettings)="updateTtpChameleonSettingsWiz.emit($event)"
        [ttpChameleonSettings]="policy.ttpChameleonSettings"
      ></mc-swg-policy-ttp-chameleon-step>
    </mc-tab>

    <mc-tab
      name="{{'$I18N_SWG_CREATE_POLICY_CLP_APP_STEP_TITLE' | translate}}"
      *ngIf="!policy.defaultPolicy && policy.policyDesc.policyType === 'CLOUD_PROTECT_POLICY'"
    >
      <mc-clp-policy-select-app-step
        *ngIf="!(providersLoading || loadingForEdit)"
        [clpSettings]="policy.clpPolicySettings"
        [allConnectors]="allConnectors"
        [providers]="providers"
        [providersLoading]="providersLoading"
        [wizardFlowType]="wizardFlowType"
        (updateClpSettings)="updateClpSettingsWiz.emit($event)"
      ></mc-clp-policy-select-app-step>
    </mc-tab>

    <mc-tab
      name="{{'$I18N_SWG_CREATE_POLICY_CLP_APP_CONNECTOR_STEP_TITLE' | translate}}"
      *ngIf="!policy.defaultPolicy && policy.policyDesc.policyType === 'CLOUD_PROTECT_POLICY'"
    >
      <mc-policy-clp-cloud-app-connector-step
        *ngIf="!(allConnectorsLoading || loadingForEdit)"
        [allConnectors]="allConnectors"
        [defaultPolicy]="policy.defaultPolicy"
        [clpSettings]="policy.clpPolicySettings"
        [connectorsLoading]="allConnectorsLoading"
        [wizardFlowType]="wizardFlowType"
        (updateClpSettings)="updateClpSettingsWiz.emit($event)"
      ></mc-policy-clp-cloud-app-connector-step>
    </mc-tab>

    <mc-tab
      name="{{'$I18N_SWG_CREATE_POLICY_CLP_APP_ACTIONS_STEP_TITLE' | translate}}"
      *ngIf="policy.policyDesc.policyType === 'CLOUD_PROTECT_POLICY'"
    >
      <mc-policy-clp-cloud-app-actions-step
        *ngIf="!(allConnectorsLoading || loadingForEdit)"
        [clpSettings]="policy.clpPolicySettings"
        (updateClpSettings)="updateClpSettingsWiz.emit($event)"
      ></mc-policy-clp-cloud-app-actions-step>
    </mc-tab>

    <mc-tab
      name="{{'$I18N_SWG_CREATE_POLICY_CLP_APP_NOTIFICATIONS_STEP_TITLE' | translate}}"
      *ngIf="policy.policyDesc.policyType === 'CLOUD_PROTECT_POLICY'"
    >
      <mc-policy-clp-cloud-app-notifications-step
        *ngIf="!(allConnectorsLoading || loadingForEdit)"
        [clpSettings]="policy.clpPolicySettings"
        (updateClpSettings)="updateClpSettingsWiz.emit($event)"
        (openAppliesToSidePanel)="openAppliesToSidePanelWiz.emit($event)"
        (updateAffectedUsers)="updateAffectedUsersWiz.emit($event)"
        (removeAffectedUsers)="removeAffectedUsersWiz.emit($event)"
      ></mc-policy-clp-cloud-app-notifications-step>
    </mc-tab>

    <mc-tab name="{{ '$I18N_SWG_CREATE_POLICY_AFFECTED_USERS' | translate }}">
      <mc-swg-policy-affected-users-step
        *ngIf="!loadingForEdit"
        (updateAffectedUsers)="updateAffectedUsersWiz.emit($event)"
        (everyOneAffectedUsers)="everyOneAffectedUsersWiz.emit($event)"
        (removeAffectedUsers)="removeAffectedUsersWiz.emit($event)"
        (openAppliesToSidePanel)="openAppliesToSidePanelWiz.emit($event)"
        (updatePolicyOverrideStatus)="updatePolicyOverrideStatusWiz.emit($event)"
        (clearAffectedUsersOtherThan)="clearAffectedUsersOtherThanWiz.emit($event)"
        [isEditFlow]="isEditFlow()"
        [affectedUsers]="policy.affectedUsers"
        [policyDesc]="policy.policyDesc"
        [everyOne]="policy.everyOne"
        [policyOverrideStatus]="policy.policyDesc.override"
      ></mc-swg-policy-affected-users-step>
    </mc-tab>

  </mc-tab-group>

  <mc-wizard (changeStep)="goToStep($event)" *ngIf="!isEditFlow()">
    <mc-wizard-step label="$I18N_SWG_CREATE_POLICY_DETAILS">
      <mc-swg-policy-details-step
        (updatePolicyDescType)="updatePolicyDescTypeWiz.emit($event)"
        [policy]="policy"
        [hasClp]="hasClp"
        [isEditFlow]="isEditFlow()"
        (enterPressedEvent)="enterPressedEvent($event)"
        [hasAppPolicyRemoveAllowEnabled]="hasAppPolicyRemoveAllowEnabled"
      ></mc-swg-policy-details-step>
    </mc-wizard-step>

    <mc-wizard-step
      label="$I18N_SWG_CREATE_POLICY_WEB_APPLICATION_CONTROL_TITLE"
      *ngIf="policy.policyDesc.policyType === 'WEB_APPLICATION_CONTROL'"
    >
      <mc-spinner *ngIf="loadingForEdit || applicationsWithStatusLoading"></mc-spinner>
      <mc-swg-application-control-step
        *ngIf="!applicationsWithStatusLoading"
        [applications]="getControllableApplications(applicationsWithStatus)"
        [isApplicationCategorySetDataLoading]="isApplicationCategorySetDataLoading"
        [applicationCategoriesSet]="applicationCategoriesSet"
        [userWebApplicationRules]="policy.webApplicationRules"
        [hasAppPolicyRemoveAllowEnabled]="hasAppPolicyRemoveAllowEnabled"
        (updateWebApplicationRule)="updateWebApplicationRuleWiz.emit($event)"
      ></mc-swg-application-control-step>
    </mc-wizard-step>

    <mc-wizard-step
      label="{{ '$I18N_SWG_CREATE_POLICY_DOMAINS' | translate }}"
      *ngIf="policy.policyDesc.policyType === 'WEB_BLOCK_ALLOW_LIST'"
    >
      <mc-swg-policy-domains-step
        [hasTTP]="hasTTP"
        (updatePolicyDomains)="updatePolicyDomainsWiz.emit($event)"
        (openBulkUploadDomains)="openBulkUploadDomainsWiz.emit($event)"
        [domains]="policy.domains"
      ></mc-swg-policy-domains-step>
    </mc-wizard-step>

    <mc-wizard-step
      label="{{ '$I18N_SWG_POLICY_WEB_LOGGING_STEP' | translate }}"
      *ngIf="policy.policyDesc.policyType === 'WEB_LOGGING'"
    >
      <mc-swg-policy-logging-step
        [loggingMode]="policy.loggingMode"
        (updateLoggingLevel)="updateLoggingLevelWiz.emit($event)"
      ></mc-swg-policy-logging-step>
    </mc-wizard-step>

    <mc-wizard-step
      label="$I18N_SWG_CREATE_POLICY_WEB_ADVANCED_SECURITY_SAFE_SEARCH_STEP"
      *ngIf="policy.policyDesc.policyType === 'WEB_ADVANCED_SECURITY'"
    >
      <mc-swg-policy-safe-search-step
        (updateSafeSearchSettings)="updateSafeSearchSettingsWiz.emit($event)"
        [safeSearchSettings]="policy.safeSearchSettings"
      ></mc-swg-policy-safe-search-step>
    </mc-wizard-step>
    <mc-wizard-step
      label="$I18N_SWG_CREATE_POLICY_WEB_ADVANCED_SECURITY_NEW_DOMAINS_STEP"
      *ngIf="policy.policyDesc.policyType === 'WEB_ADVANCED_SECURITY'"
    >
      <mc-swg-new-domains-step
        (updateNewDomainsSettings)="updateNewDomainsSettingsWiz.emit($event)"
        [newDomainsSettings]="policy.newDomainsSettings"
      ></mc-swg-new-domains-step>
    </mc-wizard-step>
    <mc-wizard-step
      label="$I18N_SWG_CREATE_POLICY_WEB_ADVANCED_SECURITY_DNS_PROXY_STEP"
      *ngIf="policy.policyDesc.policyType === 'WEB_ADVANCED_SECURITY'"
    >
      <mc-swg-proxy-settings-step
        *ngIf="!loadingForEdit && !advancedFileTypesLoading && !categorySectionsLoading"
        (updateProxySettings)="updateProxySettings($event)"
        [proxySettings]="policy.proxySettings"
        [advancedFileTypes]="advancedFileTypes"
        (updateWebCategoryRules)="updateProtectedApplicationsWiz.emit($event)"
        [webCategoryRuleSections]="getAppSecSections(protectedApplications, policy.protectedApplications)"
        [hasExtendedProxy]="hasSwgHybrid"
        [hasBI]="hasBrowserIsolationEnabled"
        [protectedApplicationsLoading]="protectedApplicationsLoading"
      ></mc-swg-proxy-settings-step>
    </mc-wizard-step>

    <mc-wizard-step
      label="$I18N_SWG_CREATE_POLICY_SECURITY_CATEGORY"
      *ngIf="policy.policyDesc.policyType === 'WEB_CATEGORY_FILTERING'"
    >
      <mc-spinner *ngIf="categorySectionsLoading || loadingForEdit"></mc-spinner>
      <mc-swg-policy-category-filter-step
        *ngIf="!categorySectionsLoading"
        [expanded]="true"
        title="{{'$I18N_SWG_CREATE_POLICY_WEB_CATEGORY_FILTERING_TITLE' | translate }}"
        (updateWebCategoryRules)="updateWebCategorySecurityRulesWiz.emit($event)"
        [usersWebCategoryRules]="policy.webCategorySecurityRules"
        [webCategoryRuleSections]="getSecuritySections(webCategoryRulesSections, policy.webCategorySecurityRules)"
      ></mc-swg-policy-category-filter-step>
    </mc-wizard-step>

    <mc-wizard-step
      label="$I18N_SWG_CREATE_POLICY_CONTENT_CATEGORY"
      *ngIf="policy.policyDesc.policyType === 'WEB_CATEGORY_FILTERING'"
    >
      <mc-spinner *ngIf="categorySectionsLoading || loadingForEdit"></mc-spinner>
      <mc-swg-policy-category-filter-step
        *ngIf="!categorySectionsLoading"
        [expanded]="false"
        title="{{'$I18N_SWG_CREATE_POLICY_WEB_CATEGORY_FILTERING_TITLE' | translate }}"
        (updateWebCategoryRules)="updateWebCategoryOtherRulesWiz.emit($event)"
        [usersWebCategoryRules]="policy.webCategoryOtherRules"
        [webCategoryRuleSections]="getNonSecuritySections(webCategoryRulesSections, policy.webCategoryOtherRules)"
      ></mc-swg-policy-category-filter-step>
    </mc-wizard-step>

    <mc-wizard-step
      label="$I18N_SWG_CREATE_POLICY_TTP_POLICY_MANAGED_URLS"
      *ngIf="policy.policyDesc.policyType === 'WEB_TTP_POLICY'"
    >
      <mc-swg-policy-ttp-managed-urls-step
        [hasTTP]="hasTTP"
        (updateTtpManagedUrlSettings)="updateTtpManagedUrlSettingsWiz.emit($event)"
        [ttpManagedUrlSettings]="policy.ttpManagedUrlSettings"
      ></mc-swg-policy-ttp-managed-urls-step>
    </mc-wizard-step>
    <mc-wizard-step
      label="$I18N_SWG_CREATE_POLICY_TTP_POLICY_CHAMELEON"
      *ngIf="policy.policyDesc.policyType === 'WEB_TTP_POLICY'"
    >
      <mc-swg-policy-ttp-chameleon-step
        (updateTtpChameleonSettings)="updateTtpChameleonSettingsWiz.emit($event)"
        [ttpChameleonSettings]="policy.ttpChameleonSettings"
      ></mc-swg-policy-ttp-chameleon-step>
    </mc-wizard-step>



    <mc-wizard-step
      label="$I18N_SWG_CREATE_POLICY_CLP_APP_STEP_TITLE"
      *ngIf="!policy.defaultPolicy && policy.policyDesc.policyType === 'CLOUD_PROTECT_POLICY'"
    >
      <mc-clp-policy-select-app-step
        *ngIf="!policy.defaultPolicy && !providersLoading"
        [clpSettings]="policy.clpPolicySettings"
        [allConnectors]="allConnectors"
        [providers]="providers"
        [providersLoading]="providersLoading"
        (updateClpSettings)="updateClpSettingsWiz.emit($event)"
      ></mc-clp-policy-select-app-step>
    </mc-wizard-step>


    <mc-wizard-step
      label="$I18N_SWG_CREATE_POLICY_CLP_APP_CONNECTOR_STEP_TITLE"
      *ngIf="policy.policyDesc.policyType === 'CLOUD_PROTECT_POLICY'"
    >
      <mc-policy-clp-cloud-app-connector-step
        *ngIf="!allConnectorsLoading"
        [allConnectors]="allConnectors"
        [clpSettings]="policy.clpPolicySettings"
        [defaultPolicy]="policy.defaultPolicy"
        [connectorsLoading]="allConnectorsLoading"
        (updateClpSettings)="updateClpSettingsWiz.emit($event)"
      ></mc-policy-clp-cloud-app-connector-step>
    </mc-wizard-step>

    <mc-wizard-step
      label="$I18N_SWG_CREATE_POLICY_CLP_APP_ACTIONS_STEP_TITLE"
      *ngIf="policy.policyDesc.policyType === 'CLOUD_PROTECT_POLICY'"
    >
      <mc-policy-clp-cloud-app-actions-step
        [clpSettings]="policy.clpPolicySettings"
        (updateClpSettings)="updateClpSettingsWiz.emit($event)"
      ></mc-policy-clp-cloud-app-actions-step>
    </mc-wizard-step>

    <mc-wizard-step
      label="$I18N_SWG_CREATE_POLICY_CLP_APP_NOTIFICATIONS_STEP_TITLE"
      *ngIf="policy.policyDesc.policyType === 'CLOUD_PROTECT_POLICY'"
    >
      <mc-policy-clp-cloud-app-notifications-step
        [clpSettings]="policy.clpPolicySettings"
        (updateClpSettings)="updateClpSettingsWiz.emit($event)"
        (openAppliesToSidePanel)="openAppliesToSidePanelWiz.emit($event)"
        (updateAffectedUsers)="updateAffectedUsersWiz.emit($event)"
        (removeAffectedUsers)="removeAffectedUsersWiz.emit($event)"

      ></mc-policy-clp-cloud-app-notifications-step>
    </mc-wizard-step>

    <mc-wizard-step label="$I18N_SWG_CREATE_POLICY_AFFECTED_USERS">
      <mc-swg-policy-affected-users-step
        (updateAffectedUsers)="updateAffectedUsersWiz.emit($event)"
        (openAppliesToSidePanel)="openAppliesToSidePanelWiz.emit($event)"
        (removeAffectedUsers)="removeAffectedUsersWiz.emit($event)"
        (everyOneAffectedUsers)="everyOneAffectedUsersWiz.emit($event)"
        (updatePolicyOverrideStatus)="updatePolicyOverrideStatusWiz.emit($event)"
        (clearAffectedUsersOtherThan)="clearAffectedUsersOtherThanWiz.emit($event)"
        [affectedUsers]="policy.affectedUsers"
        [policyDesc]="policy.policyDesc"
        [everyOne]="policy.everyOne"
        [policyOverrideStatus]="policy.policyDesc.override"
      ></mc-swg-policy-affected-users-step>
    </mc-wizard-step>

    <mc-wizard-step label="$I18N_SWG_CREATE_POLICY_POLICY_SUMMARY" *ngIf="!isEditFlow()">
      <mc-swg-policy-create-step
        [policy]="policy"
        [applications]="applications"
        [hasSwgHybridEnabled]="hasSwgHybrid"
        [hasBrowserIsolationEnabled]="hasBrowserIsolationEnabled"
        [hasAppPolicyRemoveAllowEnabled]="hasAppPolicyRemoveAllowEnabled"
        *ngIf="!categorySectionsLoading"
        [webCategoryRulesSections]="webCategoryRulesSections"
        [protectedApplications]="protectedApplications"
      ></mc-swg-policy-create-step>
    </mc-wizard-step>
  </mc-wizard>

  <mc-footer-container>
    <div class="pull-right no-select mc-button-footer">
      <button class="btn" (click)="cancel()">{{ '$I18N_COMMON_BTN_CANCEL' | translate }}</button>
      <ng-container *ngIf="wizard && !isEditFlow()">
        <button
          class="btn btn-secondary panel-half-margin-right"
          *ngIf="wizard.hasPreviousStep$ | async"
          (click)="wizard.previousStep()"
        >
          {{ '$I18N_COMMON_BTN_PREVIOUS' | translate }}
        </button>
        <button
          class="btn btn-primary"
          [disabled]="!isStepValid()"
          *ngIf="wizard.hasNextStep$ | async"
          (click)="wizard.nextStep()"
        >
          {{ '$I18N_COMMON_BTN_NEXT' | translate }}
        </button>
        <ng-container *ngIf="hasEditPermission">
          <mc-live-button
            *ngIf="wizard.isLastStep$ | async"
            overrideClasses="btn-primary"
            [label]="'$I18N_SWG_CREATE_POLICY_ADD_POLICY' | translate"
            [isLoading]="apiIsProcessing"
            (mcClick)="createPolicyEvent.emit(policy)"
          ></mc-live-button>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="isEditFlow()">
        <ng-container *ngIf="hasEditPermission">
          <button
            *ngIf="!apiIsProcessing"
            class="btn btn-primary"
            [disabled]="!isValidPolicy(policy)"
            (click)="createPolicyEvent.emit(policy)"
          >
            {{ '$I18N_SWG_CREATE_POLICY_SAVE_AND_CLOSE' | translate }}
          </button>
          <mc-live-button
            *ngIf="apiIsProcessing"
            overrideClasses="btn-primary"
            [label]="'$I18N_SWG_CREATE_POLICY_SAVE_AND_CLOSE' | translate"
            [isLoading]="apiIsProcessing"
          ></mc-live-button>
        </ng-container>
      </ng-container>
    </div>
  </mc-footer-container>
</mc-layout-detail-wizard>
