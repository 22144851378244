"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiemChannelListContainerComponent = void 0;
const siem_channels_models_1 = require("../../../models/siem-channels.models");
const sidebar = require("../../../actions/siem-channels/sidebar.actions");
const list = require("../../../actions/siem-channels/list.actions");
const channelActions = require("../../../actions/siem-channels/channel.actions");
const reducers = require("../../../reducers");
class SiemChannelListContainerComponent {
    constructor(store, siemService) {
        this.store = store;
        this.siemService = siemService;
    }
    ngOnInit() {
        this.store.dispatch(new channelActions.SiemLoadProductsFromMetadata());
        this.hasEditPermission$ = this.siemService.hasEditPermission();
        this.hasDeletePermission$ = this.siemService.hasDeletePermission();
        this.metadataProducts$ = this.store.select(reducers.getMetadataProducts);
    }
    onCreateNewChannel() {
        this.store.dispatch(new channelActions.SiemClearChannel());
        this.store.dispatch(new channelActions.SiemChannelNavigateToWizardAction(siem_channels_models_1.SiemChannelWizardFlowType.CREATE));
    }
    onViewChannel(row) {
        this.store.dispatch(new channelActions.SiemLoadChannelDetails(row));
        this.store.dispatch(new sidebar.SiemChannelListSidebarOpen());
    }
    onEditChannel(channel) {
        this.store.dispatch(new channelActions.SiemLoadChannelToEdit(channel));
        this.store.dispatch(new channelActions.SiemChannelNavigateToWizardAction(siem_channels_models_1.SiemChannelWizardFlowType.EDIT));
    }
    onDeleteChannel(row) {
        this.store.dispatch(new list.SiemChannelDeleteConfirmationModalOpen(row));
    }
}
exports.SiemChannelListContainerComponent = SiemChannelListContainerComponent;
