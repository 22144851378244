"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RuleSummaryComponent = void 0;
const queue_type_enum_1 = require("app-new/archive/supervision/types/queue-type.enum");
const summaryConfig = require("./summary.static-values");
class RuleSummaryComponent {
    constructor(ruleService, translateService) {
        this.ruleService = ruleService;
        this.translateService = translateService;
        this.QueueType = queue_type_enum_1.QueueType;
        this.scheduleTypes = this.ruleService.getScheduleTypes();
    }
    set hasMonthlyScheduleCapability(hasCapability) {
        if (hasCapability) {
            this.scheduleTypes = summaryConfig.scheduleTypes;
        }
    }
    ngOnChanges(changes) {
        if (changes && changes['wizardData'].currentValue) {
            this.stepsData = this.wizardData.steps;
            if (this.getStepDataById(2) && typeof this.getStepDataById(2).messageRoute === 'string') {
                const messageRoute = this.ruleService
                    .getMessageRoutes()
                    .find(message => message.value === this.getStepDataById(2).messageRoute);
                this.messageRoute = (messageRoute && messageRoute.label) || '';
            }
            if (this.getStepDataById(2) && typeof this.getStepDataById(2).scheduleType === 'string') {
                const scheduleType = this.scheduleTypes.find(message => message.value === this.getStepDataById(2).scheduleType);
                this.scheduleType = (scheduleType && scheduleType.label) || '';
            }
        }
    }
    getStepById(id) {
        return (this.stepsData && this.stepsData[id]) || {};
    }
    getStepDataById(id) {
        const step = this.getStepById(id);
        return (step && step.data) || {};
    }
    getNoLexiconText() {
        return this.translateService.instant('$I18N_ARCHIVE_SUPERVISION_WIZARD.RULE.SUMMARY_STEP.NO_LEXICON_SELECTED');
    }
    get samplingPercentage() {
        return Number(this.getStepDataById(2).samplingPercentage);
    }
}
exports.RuleSummaryComponent = RuleSummaryComponent;
