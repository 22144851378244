"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListEffects = void 0;
const effects_1 = require("@ngrx/effects");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const actions = require("../../actions/external-domain-list.actions");
const external_domain_list_actions_1 = require("../../actions/external-domain-list.actions");
const confirmation_modal_component_1 = require("../../containers/confirmation-modal/confirmation-modal.component");
const fromChameleonState = require("../../reducers/index");
const table_store_1 = require("@mimecast-ui/table-store");
const TABLE_ID = 'monitoredExternalDomain';
class ListEffects {
    constructor(actions$, chameleonStore, notificationService, modalService, chameleonService, tableStore) {
        this.actions$ = actions$;
        this.chameleonStore = chameleonStore;
        this.notificationService = notificationService;
        this.modalService = modalService;
        this.chameleonService = chameleonService;
        this.tableStore = tableStore;
        this.loadDeleteAlert$ = this.actions$.pipe(effects_1.ofType(actions.DELETE_ALERT_OPEN), operators_1.switchMap(() => {
            return this.modalService
                .open(confirmation_modal_component_1.ConfirmationModalComponent)
                .afterClose()
                .pipe(operators_1.map((result) => result ? new actions.BulkDeleteItems() : new actions.DeleteAlertCancel()));
        }));
        this.delete$ = this.actions$.pipe(effects_1.ofType(actions.BULK_DELETE_ITEMS), operators_1.withLatestFrom(this.chameleonStore.select(fromChameleonState.getAllSelectedItems)), operators_1.switchMap(([, selectedItems]) => {
            const domainIdsToDelete = selectedItems.map((item) => {
                return { id: item.id };
            });
            return this.chameleonService.deleteCustomExternalDomain(domainIdsToDelete).pipe(operators_1.map((deleteResponse) => {
                return deleteResponse.deleteSuccessIds
                    ? new actions.DeleteSuccess(selectedItems)
                    : new actions.DeleteFail();
            }), operators_1.catchError(() => rxjs_1.of(new actions.DeleteFail())));
        }));
        this.deleteSuccess$ = this.actions$.pipe(effects_1.ofType(actions.DELETE_SUCCESS), operators_1.withLatestFrom(this.tableStore.select(table_store_1.tableReducer.getCurrentFilters(TABLE_ID)), this.tableStore.select(table_store_1.tableReducer.getCurrentPagination(TABLE_ID))), operators_1.switchMap(([action, currentFilters, currentPagination]) => {
            return [
                this.notificationService.newNotification({
                    type: 'success',
                    // @ts-ignore
                    config: { msg: ListEffects.getDeleteSuccessNotificationText(action.deletedItems) }
                }),
                new table_store_1.LoadAction({
                    tableId: TABLE_ID,
                    pagination: { pageToken: '', pageSize: currentPagination.pageSize, pageNumber: 0 },
                    query: currentFilters
                }),
                new external_domain_list_actions_1.ClearListState()
            ];
        }));
        this.deleteFail$ = this.actions$.pipe(effects_1.ofType(actions.DELETE_FAIL), operators_1.map(() => {
            return this.notificationService.newNotification({
                type: 'error',
                config: { msg: "Couldn't delete domain" }
            });
        }));
    }
    static getDeleteSuccessNotificationText(domains) {
        return domains.length === 1
            ? `${domains[0].domainName} deleted.`
            : `${domains.length} domains deleted.`;
    }
}
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "loadDeleteAlert$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "delete$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "deleteSuccess$", void 0);
__decorate([
    effects_1.Effect()
], ListEffects.prototype, "deleteFail$", void 0);
exports.ListEffects = ListEffects;
