"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MigratedPolicyData = exports.UserInfoLoadAction = exports.UserInfoLoadSuccessAction = exports.ProfileLoadSuccessAction = exports.VerifyAdminAccessLoadSuccessAction = exports.VerifyFederatedAccessLoadSuccessAction = exports.AdminPreferencesLoadSuccessAction = exports.PreferencesLoadSuccessAction = exports.ConfigExtendedLoadSuccessAction = exports.CapsOverrideLoadSuccessAction = exports.CapabilitiesLoadSuccessAction = exports.AccountLoadSuccessAction = exports.PreferencesSaveFailAction = exports.PreferencesSaveSuccessAction = exports.PreferencesSaveAction = exports.PreferencesCancelAction = exports.CapabilitiesReloadAction = exports.MIGRATED_POLICY_DATA = exports.CAPABILITIES_RELOAD = exports.PREFERENCES_CANCEL = exports.PREFERENCES_SAVE_FAIL = exports.PREFERENCES_SAVE_SUCCESS = exports.PREFERENCES_SAVE = exports.ACCOUNT_LOAD_SUCCESS = exports.CONFIG_EXTENDED_LOAD_SUCCESS = exports.CAPS_OVERRIDE_LOAD_SUCCESS = exports.CAPABILITIES_LOAD_SUCCESS = exports.VERIFY_FEDERATED_ACCESS_LOAD_SUCCESS = exports.VERIFY_ADMIN_ACCESS_LOAD_SUCCESS = exports.ADMIN_PREFERENCES_LOAD_SUCCESS = exports.PREFERENCES_LOAD_SUCCESS = exports.PROFILE_LOAD_SUCCESS = exports.USER_INFO_LOAD_SUCCESS = exports.USER_INFO_LOAD = void 0;
exports.USER_INFO_LOAD = '[User] User Info Load';
exports.USER_INFO_LOAD_SUCCESS = '[User] User Info Load Success';
exports.PROFILE_LOAD_SUCCESS = '[User] Profile Load Success';
exports.PREFERENCES_LOAD_SUCCESS = '[User] Preferences Load Success';
exports.ADMIN_PREFERENCES_LOAD_SUCCESS = '[User] Admin Preferences Load Success';
exports.VERIFY_ADMIN_ACCESS_LOAD_SUCCESS = '[User] Verify Admin Access Load Success';
exports.VERIFY_FEDERATED_ACCESS_LOAD_SUCCESS = '[User] Verify Federated Access Load Success';
exports.CAPABILITIES_LOAD_SUCCESS = '[User] Capabilities Load Success';
exports.CAPS_OVERRIDE_LOAD_SUCCESS = '[User] capsOverride Load Success';
exports.CONFIG_EXTENDED_LOAD_SUCCESS = '[User] configExtended Load Success';
exports.ACCOUNT_LOAD_SUCCESS = '[User] Account Load Success';
exports.PREFERENCES_SAVE = '[User] Preferences Save';
exports.PREFERENCES_SAVE_SUCCESS = '[User] Preferences Save Success';
exports.PREFERENCES_SAVE_FAIL = '[User] Preferences Save Fail';
exports.PREFERENCES_CANCEL = '[User] Preferences Cancel';
exports.CAPABILITIES_RELOAD = '[User] Capabilities Reload';
exports.MIGRATED_POLICY_DATA = '[User] Migrated Policy Data';
class CapabilitiesReloadAction {
    constructor() {
        this.type = exports.CAPABILITIES_RELOAD;
    }
}
exports.CapabilitiesReloadAction = CapabilitiesReloadAction;
class PreferencesCancelAction {
    constructor() {
        this.type = exports.PREFERENCES_CANCEL;
    }
}
exports.PreferencesCancelAction = PreferencesCancelAction;
class PreferencesSaveAction {
    constructor(payload, refreshPageOnSuccess = false) {
        this.payload = payload;
        this.refreshPageOnSuccess = refreshPageOnSuccess;
        this.type = exports.PREFERENCES_SAVE;
    }
}
exports.PreferencesSaveAction = PreferencesSaveAction;
class PreferencesSaveSuccessAction {
    constructor(payload, refreshPageOnSuccess = false) {
        this.payload = payload;
        this.refreshPageOnSuccess = refreshPageOnSuccess;
        this.type = exports.PREFERENCES_SAVE_SUCCESS;
    }
}
exports.PreferencesSaveSuccessAction = PreferencesSaveSuccessAction;
class PreferencesSaveFailAction {
    constructor() {
        this.type = exports.PREFERENCES_SAVE_FAIL;
    }
}
exports.PreferencesSaveFailAction = PreferencesSaveFailAction;
class AccountLoadSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.ACCOUNT_LOAD_SUCCESS;
    }
}
exports.AccountLoadSuccessAction = AccountLoadSuccessAction;
class CapabilitiesLoadSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.CAPABILITIES_LOAD_SUCCESS;
    }
}
exports.CapabilitiesLoadSuccessAction = CapabilitiesLoadSuccessAction;
class CapsOverrideLoadSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.CAPS_OVERRIDE_LOAD_SUCCESS;
    }
}
exports.CapsOverrideLoadSuccessAction = CapsOverrideLoadSuccessAction;
class ConfigExtendedLoadSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.CONFIG_EXTENDED_LOAD_SUCCESS;
    }
}
exports.ConfigExtendedLoadSuccessAction = ConfigExtendedLoadSuccessAction;
class PreferencesLoadSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.PREFERENCES_LOAD_SUCCESS;
    }
}
exports.PreferencesLoadSuccessAction = PreferencesLoadSuccessAction;
class AdminPreferencesLoadSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.ADMIN_PREFERENCES_LOAD_SUCCESS;
    }
}
exports.AdminPreferencesLoadSuccessAction = AdminPreferencesLoadSuccessAction;
class VerifyFederatedAccessLoadSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.VERIFY_FEDERATED_ACCESS_LOAD_SUCCESS;
    }
}
exports.VerifyFederatedAccessLoadSuccessAction = VerifyFederatedAccessLoadSuccessAction;
class VerifyAdminAccessLoadSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.VERIFY_ADMIN_ACCESS_LOAD_SUCCESS;
    }
}
exports.VerifyAdminAccessLoadSuccessAction = VerifyAdminAccessLoadSuccessAction;
class ProfileLoadSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.PROFILE_LOAD_SUCCESS;
    }
}
exports.ProfileLoadSuccessAction = ProfileLoadSuccessAction;
class UserInfoLoadSuccessAction {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.USER_INFO_LOAD_SUCCESS;
    }
}
exports.UserInfoLoadSuccessAction = UserInfoLoadSuccessAction;
class UserInfoLoadAction {
    constructor() {
        this.type = exports.USER_INFO_LOAD;
    }
}
exports.UserInfoLoadAction = UserInfoLoadAction;
class MigratedPolicyData {
    constructor(payload) {
        this.payload = payload;
        this.type = exports.MIGRATED_POLICY_DATA;
    }
}
exports.MigratedPolicyData = MigratedPolicyData;
