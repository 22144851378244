<mc-table 
  [showHighlightedRow]="false" 
  [data]="tableData" 
  [columns]="columns" 
  [isLoading]="isLoading"
  translatePrefix="$I18N_ONEDRIVE_LIST" 
  (rowClick)="openItem($event)">
  
  <mc-filters 
    [metadata]="metaData" 
    (paginatorChange)="onPaginationChange.emit($event)">    
    <mc-filter-bundle-column-values-and-search 
      [sections]="sections"
      placeholder="$I18N_ONEDRIVE_LIST.FILTERS.SEARCH_FIELD.PLACEHOLDER" 
      (filtersChange)="onFilterChange.emit($event)">
    </mc-filter-bundle-column-values-and-search>
  </mc-filters>

  <mc-column-date key="firstStarted"></mc-column-date>

  <mc-column-date key="lastActive"></mc-column-date>

  <mc-column-size [textAlign]="sizePosition" precision="0" [smallestUnit]="storageUsedUnit" key="storageUsed">
  </mc-column-size>

  <mc-column key="syncedBy">
    <mc-body-cell *mcBodyCellDef="let row">{{row.backupTaskSettings.description}}</mc-body-cell>
  </mc-column>

  <mc-column key="lastStatus">
    <mc-body-cell *mcBodyCellDef="let row">
      <div class="mc-status-text-container" [ngClass]="getStatusByRow(row).displayClasses">
        <span>{{ getStatusByRow(row).displayValue | translate:{number: row.phaseProgress} }}</span>
      </div>
    </mc-body-cell>
  </mc-column>
</mc-table>
